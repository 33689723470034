import React, { useMemo } from 'react';
import { Box, Card, Heading, Inline, Split, Stack, Text, css } from '@goodhuman-me/components';

import { BudgetStatusProgressBar } from '../../../../components/budget-status-progress-bar';
import { DonutChart } from './donut-chart';

import type { Budget } from '../../../../../../views/budgets/budget';
import { getProgress } from './budgeting-period/budgeting-period';

const styles = {
  ul: css({
    listStyle: 'none',
  }),
};

type BudgetingDistributionProps = Pick<Budget['budget'], 'balance' | 'categories' | 'quoted'>;

function BudgetingDistribution(props: BudgetingDistributionProps) {
  return (
    <Stack gap="$space400">
      <Heading size="xsmall" fontWeight="$semi">
        Overall budget distributions
      </Heading>

      <Stack
        alignItems="center"
        alignSelf={{ initial: 'center', large: 'start' }}
        height="264px"
        justifyContent="center"
        width="264px"
      >
        <DonutChart {...props} />
      </Stack>
    </Stack>
  );
}

type BudgetingBreakdownDetailProps = Budget['budget']['categories'][0] &
  Pick<Budget['overview']['service'], 'startDate' | 'endDate'>;
function BudgetingBreakdownDetail(props: BudgetingBreakdownDetailProps) {
  const {
    actuals,
    meta: { color },
    name,
    status,
    startDate,
    endDate,
  } = props;

  const elapsedTimePercent = useMemo(() => getProgress({ startDate, endDate })?.value ?? 0, [startDate, endDate]);

  return (
    <Box asChild>
      <li>
        <BudgetStatusProgressBar
          width="100%"
          label={
            <Inline alignItems="center" gap="$space50">
              <Box
                height="$size125"
                minWidth="$size125"
                width="$size125"
                backgroundColor={color}
                borderRadius="$full"
              />
              <Text size="xsmall" asChild>
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {name}
                </span>
              </Text>
            </Inline>
          }
          value={actuals.spent.percent}
          projectedValue={elapsedTimePercent}
          status={status}
        />
      </li>
    </Box>
  );
}

type BudgetingBreakdownProps = Pick<Budget['budget'], 'categories'> &
  Pick<Budget['overview']['service'], 'startDate' | 'endDate'>;

function BudgetingBreakdown(props: BudgetingBreakdownProps) {
  const { categories, startDate, endDate } = props;

  return (
    <Stack gap="$space400" height="100%">
      <Heading size="xsmall" fontWeight="$semi">
        Budget breakdown
      </Heading>

      <Stack width="100%" height="100%">
        <Stack gap="$medium" margin="$none" padding="$none" asChild>
          <ul className={styles.ul()}>
            {categories.map((c) => (
              <BudgetingBreakdownDetail key={c.id} {...c} {...{ startDate, endDate }} />
            ))}
          </ul>
        </Stack>
      </Stack>
    </Stack>
  );
}

export type BudgetingSummaryProps = Budget['budget'] & Pick<Budget['overview']['service'], 'startDate' | 'endDate'>;

export function BudgetingSummary(props: BudgetingSummaryProps): JSX.Element {
  return (
    <Card padding="calc($space400 - 1px)">
      <Split fraction="auto-start" gap="$space800" collapseAt="1024px">
        <BudgetingDistribution {...props} />
        <BudgetingBreakdown {...props} />
      </Split>
    </Card>
  );
}
