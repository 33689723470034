import React, { PureComponent } from 'react';
import { SubTitle, Paragraph } from 'common-components/typography';
import { WorkflowTemplateType } from 'utilities/enum-utils';
import { IAddWorkflowTemplateWizard, IWorkflowTemplateStep } from 'interfaces/workflow-interfaces';
import { HyperlinkButton } from 'common-components/buttons';

interface Props {
  addWorkflowTemplateWizard: IAddWorkflowTemplateWizard;
  onOpenPreviewFormModal?: (workflowTemplateValue: IWorkflowTemplateStep) => void;
}

class WorkflowTemplateSummarySection extends PureComponent<Props> {
  render() {
    const { addWorkflowTemplateWizard, onOpenPreviewFormModal } = this.props;

    const isPlatformWorkflowType = addWorkflowTemplateWizard.workflowTemplateType === WorkflowTemplateType.PLATFORM;

    let fileNotHaveAttachment = true;

    return (
      <>
        <SubTitle textClassName="text-weight-regular">Workflow name</SubTitle>
        <Paragraph size="large">{addWorkflowTemplateWizard.name}</Paragraph>

        <SubTitle textClassName="text-weight-regular">Workflow description</SubTitle>
        <Paragraph size="large" className="whitespace-pre-line">
          {addWorkflowTemplateWizard.description}
        </Paragraph>

        <SubTitle textClassName="text-weight-regular">Workflow type</SubTitle>
        <Paragraph size="large">
          {isPlatformWorkflowType ? 'In platform action (Incident reported)' : 'Manually trigger'}
        </Paragraph>

        <SubTitle textClassName="text-weight-regular">Steps</SubTitle>
        <Paragraph size="large">
          {addWorkflowTemplateWizard.steps.length} {addWorkflowTemplateWizard.steps.length > 1 ? 'steps' : 'step'}
          {isPlatformWorkflowType && ' (Including trigger step)'}
        </Paragraph>

        <SubTitle textClassName="text-weight-regular">Attached forms</SubTitle>

        {addWorkflowTemplateWizard.steps.map((step, index) => {
          if (step.attachment || step.formVersionId) {
            fileNotHaveAttachment = false;
            return (
              <Paragraph key={index} size="large" className="m-none">
                {step.attachment ? (
                  step.attachment.name
                ) : (
                  <HyperlinkButton onClick={() => onOpenPreviewFormModal(step)}>{step.formName}</HyperlinkButton>
                )}
              </Paragraph>
            );
          }
        })}

        {fileNotHaveAttachment && <Paragraph>None attached form</Paragraph>}
      </>
    );
  }
}

export default WorkflowTemplateSummarySection;
