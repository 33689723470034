import { Form, Icon, Input, message, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { HyperlinkButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';

interface IActivityRecordNoteAndAttachmentsProps {
  note: { noteContent: string };
  setNote: (note: { noteContent: string }) => void;
  attachments: File[];
  setAttachments: (attachments: File[]) => void;
  isResetCurrentAttachment: boolean;
}

const ActivityRecordNoteAndAttachments = (props: IActivityRecordNoteAndAttachmentsProps) => {
  const { note, setNote, attachments, isResetCurrentAttachment } = props;
  const [removedFiles, setRemoveFiles] = useState<File[]>([]);
  const [isMaxFileSpace, setIsMaxFileSpace] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const maxAttachmentsSize = 5;

  const _onRemoveFile = (removedFile: File) => {
    if (!removedFile) return;

    setRemoveFiles([...removedFiles, removedFile]);

    const { attachments, setAttachments } = props;
    const newSelectedFiles = _.filter(attachments, (file) => {
      return !_.isEqual(file, removedFile);
    });
    setAttachments(newSelectedFiles);
  };

  const _hasExtension = (file) => {
    return new RegExp(
      '(' +
        [
          '.jpeg',
          '.jpg',
          '.png',
          '.gif',
          '.pdf',
          '.txt',
          '.ppt',
          '.pptx',
          '.doc',
          '.docx',
          '.csv',
          '.xls',
          '.xlsx',
          '.xlsm',
        ]
          .join('|')
          .replace(/\./g, '\\.') +
        ')$',
    ).test(file.name.toLowerCase());
  };

  const _validateFile = (file) => {
    const isValidType = _hasExtension(file);
    if (!isValidType) {
      message.error('This file extension is not supported, please choose another format.');
    }

    const isLt2M = file.size / 1024 / 1024 < 25;
    if (!isLt2M) {
      message.error('File must be smaller than 25MB.');
    }

    return false;
  };

  const _validateSingleFile = (file) => {
    const isValidType = _hasExtension(file);
    const isLt2M = file.size / 1024 / 1024 < 25;

    if (isLt2M && isValidType) {
      return true;
    }
    return false;
  };

  const _onChangeFiles = (info: any) => {
    const { attachments, setAttachments } = props;
    const fileList = _.map(info.fileList, (file) => file.originFileObj);
    const newFiles = _.filter(fileList, (file) => {
      return (
        _validateSingleFile(file) && !_.some(removedFiles, ['uid', file.uid]) && !_.some(attachments, ['uid', file.uid])
      );
    });

    const availableSpaceLeft = maxAttachmentsSize - attachments.length;
    if (newFiles.length > availableSpaceLeft) {
      setIsMaxFileSpace(true);
      setRemoveFiles([...removedFiles, ...newFiles]);
      return;
    } else {
      setIsMaxFileSpace(false);
    }

    const amountCurrentFiles = attachments.length;
    if (amountCurrentFiles <= maxAttachmentsSize) {
      const newFilesToAttach = _.take(newFiles, maxAttachmentsSize - amountCurrentFiles);
      const newFilesNotToAttach = _.filter(newFiles, (file) => !_.some(newFilesToAttach, ['uid', file.uid]));
      setAttachments([...attachments, ...newFilesToAttach]);
      setRemoveFiles([...removedFiles, ...newFilesNotToAttach]);
    }
    setFileList(info.fileList);
  };

  useEffect(() => {
    if (isResetCurrentAttachment) {
      setFileList([]);
    }
  }, [isResetCurrentAttachment]);

  return (
    <>
      <div className="flex justify-between">
        <div>
          <Text weight="bold">Details</Text>
        </div>
      </div>
      <div className="mt-medium">
        <Form.Item className={'m-none'}>
          <Input.TextArea
            value={note.noteContent}
            placeholder="Enter note"
            autoSize={{ minRows: 6, maxRows: 6 }}
            onChange={(e) => setNote({ noteContent: e.target.value })}
          />
        </Form.Item>
      </div>

      <div className="mt-12">
        {attachments.length >= maxAttachmentsSize && (
          <Text type="danger" className="block text-color-warning-orange mb-12">
            Maximum number of attachments reached (5)
          </Text>
        )}
        {!_.isEmpty(attachments) &&
          _.map(attachments, (file, index) => (
            <div className="mb-12 mr-small pv-x-small ph-small bg-tertiary rounded-big inline-block" key={index}>
              <Icon type="file" theme="filled" className="text-size-regular text-color-info-blue mr-small" />
              <Text color="info-blue">{file.name}</Text>
              <Icon
                type="close-circle"
                theme="outlined"
                className="text-size-regular text-color-secondary ml-medium"
                onClick={() => _onRemoveFile(file)}
              />
            </div>
          ))}
        {isMaxFileSpace && (
          <div>
            <Text color="red">Files you choose are exceeded the max number of attachments (5 max)</Text>
          </div>
        )}
      </div>
      <div className="mt-12">
        <div className="flex-column">
          <Upload
            multiple
            beforeUpload={_validateFile}
            showUploadList={false}
            onChange={_onChangeFiles}
            fileList={fileList}
            disabled={attachments.length >= 5 ? true : false}
          >
            <HyperlinkButton color={attachments.length >= maxAttachmentsSize ? 'gray' : 'info-blue'}>
              <Icon
                type="paper-clip"
                theme="outlined"
                className={`text-size-x2-large text-color-${
                  attachments.length >= maxAttachmentsSize ? 'gray' : 'info-blue'
                }`}
              />
              Add attachment
            </HyperlinkButton>
          </Upload>
        </div>
      </div>
    </>
  );
};

export default memo(ActivityRecordNoteAndAttachments);
