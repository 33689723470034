import { Modal, Text, TextInput, Stack } from '@good/ui/core';
import { useForm, zodResolver } from '@good/ui/form';
import { notifications } from '@good/ui/notifications';
import { TRPCClientError } from '@trpc/client';
import React from 'react';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';
import { reactTrpc } from 'utilities/react-trpc';
import z from 'zod';

const schema = z.object({
  email: z.string().email(),
});

type Props = { serviceProviderId: string; billingEmail: string; opened: boolean; close: () => void };

export const BillingEmailModal = ({ serviceProviderId, billingEmail, opened, close }: Props) => {
  const dispatch = useDispatch<IRootDispatch>();
  const [loading, setLoading] = React.useState(false);
  const { mutateAsync: updateBillingEmail } = reactTrpc.serviceProvider.subscription.updateBillingEmail.useMutation();

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: { email: billingEmail },
    validate: zodResolver(schema),
  });

  const onSubmit = form.onSubmit(async (values) => {
    try {
      setLoading(true);
      await updateBillingEmail({ billingEmail: values.email, serviceProviderId });
      await dispatch.subscriptionStore.doGetSubscriptionOverview();
      close();
    } catch (e) {
      if (e instanceof TRPCClientError) {
        notifications.show({
          title: 'Failed to update billing email',
          message: e.message,
          color: 'red',
        });
      } else {
        notifications.show({
          title: 'Failed to update billing email',
          message: 'Something went wrong while updating your billing email. Please try again later.',
          color: 'red',
        });
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal
      title='Update your billing email'
      opened={opened}
      onClose={close}
      cancelProps={{
        label: 'Cancel',
        onClick: close,
      }}
      confirmProps={{
        label: 'Update',
        onClick: () => onSubmit(),
        color: 'ocean',
        loading,
      }}
    >
      <form onSubmit={onSubmit}>
        <Stack>
          <TextInput label='Billing email' name='email' key={form.key('email')} {...form.getInputProps('email')} />
          <Text>
            Billing-related communications, such as invoices and reminders, will be sent to this email address.
          </Text>
        </Stack>
      </form>
    </Modal>
  );
};
