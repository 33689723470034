import { Slot } from '@radix-ui/react-slot';
import { filterDOMProps, mergeRefs } from '@react-aria/utils';
import { createContext, forwardRef, useRef } from 'react';

import * as styles from './text.css';
import { twMerge, useContextProps } from '../utils';

import type { HTMLProps, SlotProps } from '../types';
import type { PropsWithChildren, Ref } from 'react';
import type { TextVariants } from './text.css';

export type TextProps = PropsWithChildren<TextVariants & SlotProps & HTMLProps<HTMLElement>>;

export type TextProviderProps = PropsWithChildren<{
  textRef?: Ref<HTMLElement>;
}> &
  TextProps;

export const TextContext = createContext<TextProviderProps>({} as TextProviderProps);

export function TextProvider(props: TextProviderProps) {
  const { children, ...value } = props;
  return <TextContext.Provider value={value}>{children}</TextContext.Provider>;
}

export const Text = forwardRef<HTMLElement, TextProps>(function Text(props, ref) {
  const {
    asChild,
    children,
    className,
    size = 'sm',
    textRef = null,
    ...otherProps
  } = useContextProps<TextProviderProps>(TextContext, props);

  const ElementType = asChild ? Slot : 'span';
  const mergedRef = mergeRefs(textRef, ref) || useRef(null);

  return (
    <ElementType {...filterDOMProps(otherProps)} className={twMerge(styles.text({ size }), className)} ref={mergedRef}>
      {children}
    </ElementType>
  );
});
