import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Truck = forwardRef(function Truck(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2.25h-.052c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.058.434-.074.95-.078 1.545H6.23c-.524 0-.97 0-1.34.036-.395.038-.761.121-1.113.321-.353.2-.612.47-.848.79-.222.298-.451.68-.72 1.13l-.018.03-1.265 2.107-.042.07c-.243.404-.435.723-.535 1.086-.1.362-.1.735-.1 1.207v1.998c0 .445 0 .816.02 1.12.022.317.07.617.19.907a2.75 2.75 0 0 0 1.488 1.489c.29.12.59.167.907.188.123.009.258.014.404.017a3.25 3.25 0 0 0 6.482.004h3.518a3.25 3.25 0 0 0 6.482-.002c.6-.004 1.118-.02 1.553-.078.628-.084 1.195-.27 1.65-.726.456-.455.642-1.022.726-1.65.08-.594.08-1.344.08-2.242V6.948c0-.899 0-1.648-.08-2.242-.084-.628-.27-1.195-.725-1.65-.456-.456-1.023-.642-1.65-.726-.595-.08-1.345-.08-2.243-.08H15ZM10.25 8v-.25H6.265c-.568 0-.94 0-1.23.029-.272.026-.412.073-.518.133-.107.06-.219.156-.382.377-.173.233-.365.552-.657 1.039L2.325 11.25H7c.964 0 1.612-.002 2.095-.066.461-.063.659-.17.789-.3.13-.13.237-.328.3-.79.065-.482.066-1.13.066-2.094Zm1.5 0v.052c0 .899 0 1.648-.08 2.242-.084.628-.27 1.195-.725 1.65-.456.456-1.023.642-1.65.726-.595.08-1.344.08-2.243.08h-5.3c-.002.098-.002.215-.002.358V15c0 .476 0 .796.017 1.043.017.241.046.358.078.435.127.307.37.55.677.677.077.032.194.061.435.078.149.01.323.014.543.016a3.251 3.251 0 0 1 6.001.001h3.998a3.25 3.25 0 0 1 6.002 0c.696-.003 1.2-.014 1.594-.067.461-.062.659-.169.789-.3.13-.13.238-.327.3-.788.064-.483.066-1.131.066-2.095V7c0-.964-.002-1.612-.067-2.095-.061-.461-.169-.659-.299-.789-.13-.13-.328-.237-.79-.3-.482-.064-1.13-.066-2.094-.066h-4c-.964 0-1.611.002-2.095.067-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788-.064.483-.066 1.131-.066 2.095v1ZM6.5 16.75a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5Zm10 0a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
