import i18n from 'integrations/i18n/locales/en/serviceAgreements.json';
import * as z from 'zod';
import { TimeSlot } from './types';

const errorMessages = i18n.silServiceAgreement.scheduleOfSupports.errorMessages;

export const TimeSlotFormSchema = z
  .object({
    startTime: z.string(),
    endTime: z.string(),
    ratio: z.string(),
    supportType: z.string(),
  })
  .superRefine((data, ctx) => {
    if (!data.startTime || !data.endTime) {
      ctx.addIssue({
        code: 'custom',
        message: errorMessages.timeFieldsRequired,
      });
      return;
    }

    if (data.startTime > data.endTime) {
      ctx.addIssue({
        code: 'custom',
        message: errorMessages.startTimeMessage,
      });
    }
  });

const hasOverLappingTimes = (timeSlotA: TimeSlot, timeSlotB: TimeSlot) =>
  (timeSlotA.startTime <= timeSlotB.startTime && timeSlotA.endTime > timeSlotB.startTime) ||
  (timeSlotB.startTime <= timeSlotA.startTime && timeSlotB.endTime > timeSlotA.startTime);

export const validatedTimeSlots = z.array(TimeSlotFormSchema).superRefine((data, context) => {
  for (let i = 0; i < data.length; i++) {
    const reportedErrorIndex: number[] = [];
    const slotA = data[i]!;
    for (let j = 0; j < data.length; j++) {
      if (i === j) continue;
      const slotB = data[j]!;
      if (hasOverLappingTimes(slotA, slotB)) {
        if (!reportedErrorIndex.includes(i)) {
          context.addIssue({
            code: 'custom',
            message: errorMessages.overlappingTimeSlots,
            path: [i],
          });
        }
        reportedErrorIndex.push(i);
      }
    }
  }
});

export const FormSchema = z.object({
  monday: validatedTimeSlots,
  tuesday: validatedTimeSlots,
  wednesday: validatedTimeSlots,
  thursday: validatedTimeSlots,
  friday: validatedTimeSlots,
  saturday: validatedTimeSlots,
  sunday: validatedTimeSlots,
});
