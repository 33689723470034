import React, { Component } from 'react';
import { IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { SubTitle } from 'common-components/typography';
import { LeftNavItem } from 'common-components/navigation/LeftNavItem';

interface IGroupServiceNavigationPanelProps {
  changeSelectedTab: (selectedTab) => void;
  selectedTabKey: typeof state.groupServiceStore.selectedTabKey;
}

interface IGroupServiceNavigationPanelState {}

class GroupServiceNavigationPanel extends Component<
  IGroupServiceNavigationPanelProps,
  IGroupServiceNavigationPanelState
> {
  state = {};

  render() {
    const { selectedTabKey = 'TIMESHEET', changeSelectedTab } = this.props;

    return (
      <div className={'pt-x-large pb-large pr-large'} style={{ width: '200px' }}>
        <div className="pv-small">
          <SubTitle color={'tertiary'}>GROUP SERVICE</SubTitle>
        </div>
        <div className="">
          <div>
            <LeftNavItem
              title={'Overview'}
              value={'OVERVIEW'}
              onClick={changeSelectedTab}
              isSelected={selectedTabKey === 'OVERVIEW'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title={'Sessions'}
              value={'SESSIONS'}
              onClick={changeSelectedTab}
              isSelected={selectedTabKey === 'SESSIONS'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title={'Customers'}
              value={'CUSTOMERS'}
              onClick={changeSelectedTab}
              isSelected={selectedTabKey === 'CUSTOMERS'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title={'Roster'}
              value={'TEAM_MEMBERS'}
              onClick={changeSelectedTab}
              isSelected={selectedTabKey === 'TEAM_MEMBERS'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title={'Activity groups'}
              value={'ACTIVITY_GROUPS'}
              onClick={changeSelectedTab}
              isSelected={selectedTabKey === 'ACTIVITY_GROUPS'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title={'Timesheets'}
              value={'TIMESHEET'}
              onClick={changeSelectedTab}
              isSelected={selectedTabKey === 'TIMESHEET'}
              size={'small'}
            />
          </div>

          <div className="pt-x-large pb-small">
            <SubTitle color={'tertiary'}>CONFIGURATION</SubTitle>
          </div>
          <div>
            <LeftNavItem
              title={'Details'}
              value={'DETAILS'}
              onClick={changeSelectedTab}
              isSelected={selectedTabKey === 'DETAILS'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title={'Settings'}
              value={'SETTINGS'}
              onClick={changeSelectedTab}
              isSelected={selectedTabKey === 'SETTINGS'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title="Payment"
              value={'PRICING'}
              onClick={changeSelectedTab}
              isSelected={selectedTabKey === 'PRICING'}
              size={'small'}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  selectedServiceDetail: state.servicesStore.selectedServiceDetail,
  selectedTabKey: state.groupServiceStore.selectedTabKey,
});

export default connect(mapState, null)(GroupServiceNavigationPanel);
