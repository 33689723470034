import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { workflowSchema } from '../../workflow-template';

import type { PropsWithChildren } from 'react';
import type { Step, Workflow } from '../../workflow-template';

/********************************************************************
 * Reducer
 *******************************************************************/

export type DetailsPayload = { name: string; description: string };
export type BuildPayload = { steps: Step[] };
export type SettingsPayload = { allowFlagging: boolean; notifyOnDone: boolean; notifyOnFlagged: boolean };

type State = Workflow;

type Action =
  | { type: 'addDetails'; payload: DetailsPayload }
  | { type: 'addSteps'; payload: BuildPayload }
  | { type: 'addSettings'; payload: SettingsPayload };

const initialState = workflowSchema.parse({
  id: '',
  version: '',
  details: {
    name: '',
    description: '',
    steps: [
      {
        id: '',
        details: {
          actionType: 'form',
          name: '',
          description: '',
          form: {
            id: '',
            name: '',
            versionId: '',
          },
        },
      },
    ],
  },
  settings: {
    allowFlagging: true,
    notifyOnDone: true,
    notifyOnFlagged: true,
  },
} as Workflow);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'addDetails': {
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload,
        },
      };
    }
    case 'addSteps': {
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload.steps,
        },
      };
    }
    case 'addSettings': {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
}

/********************************************************************
 * Context
 *******************************************************************/

type WorkflowProviderProps = {
  state: State;
  submitBuild: (payload: BuildPayload) => void;
  submitDetails: (payload: DetailsPayload) => void;
  submitSettings: (payload: SettingsPayload) => void;
};

const CreateWorkflowContext = createContext<WorkflowProviderProps>(null);

export type CreateWorkflowProviderProps = PropsWithChildren<{
  workflow?: Workflow;
}>;

export function CreateWorkflowProvider(props: CreateWorkflowProviderProps) {
  let { children, workflow = initialState } = props;
  let [state, dispatch] = useReducer(reducer, workflow);

  const submitBuild = useCallback(
    function submitBuild(payload: BuildPayload) {
      dispatch({ type: 'addSteps', payload });
    },
    [dispatch],
  );

  const submitDetails = useCallback(
    function submitDetails(payload: DetailsPayload) {
      dispatch({ type: 'addDetails', payload });
    },
    [dispatch],
  );

  const submitSettings = useCallback(
    function submitReview(payload: SettingsPayload) {
      dispatch({ type: 'addSettings', payload });
    },
    [dispatch],
  );

  return (
    <CreateWorkflowContext.Provider value={{ state, submitBuild, submitDetails, submitSettings }}>
      {children}
    </CreateWorkflowContext.Provider>
  );
}

export function useCreateWorkflow() {
  let context = useContext(CreateWorkflowContext);
  return context;
}
