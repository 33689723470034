import { IFileValidation } from 'src/interfaces/file-validation-interface';
import { RcFile } from 'antd/lib/upload';
import _ from 'lodash';

export const validateExtension = (file: File, extensionArray?: string[]) => {
  const localExtensionArray = extensionArray ?? ['.png', '.gif', '.jpg', '.bmp', '.jpeg'];
  return new RegExp(`(${localExtensionArray.join('|').replace(/\./g, '\\.')})$`).test(file.name.toLowerCase());
};

class FileUtilities {
  static rcFileValidator = (fileValidation: IFileValidation, file: RcFile): string[] => {
    const result: string[] = [];
    if (fileValidation.fileSize && file.size > fileValidation.fileSize) {
      result.push('File must be smaller than 25MB.');
    }

    const fileExtension = file.name.split('.').pop();
    if (fileValidation.fileTypes && !_.includes(fileValidation.fileTypes, fileExtension)) {
      result.push('This file extension is not supported, please choose another format.');
    }

    return result;
  };

  static getCommonFileExtension = () => [
    'pdf',
    'xls',
    'xlsx',
    'xlsm',
    'ppt',
    'pptx',
    'txt',
    'doc',
    'docx',
    'csv',
    'gif',
    'jpeg',
    'jpg',
    'png',
  ];
}

type DocumentDownloadArgs = { documentName: string; documentUrl: string };

export const downloadFileFromUrl = ({ documentName, documentUrl }: DocumentDownloadArgs) => {
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('href', documentUrl);
  downloadLink.setAttribute('download', documentName);
  downloadLink.target = '_blank';

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export default FileUtilities;
