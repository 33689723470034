import React, { forwardRef } from 'react';

import { Icon } from '../icon';

import type { IconProps } from '../icon';

export const BackIcon = forwardRef<SVGSVGElement, IconProps>(function BackIcon(props, ref) {
  return (
    <Icon {...props} viewBox="0 0 16 16" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68656 3.64645C7.88182 3.84171 7.88182 4.15829 7.68656 4.35355L4.54011 7.5H12.6663C12.9425 7.5 13.1663 7.72386 13.1663 8C13.1663 8.27614 12.9425 8.5 12.6663 8.5H4.54011L7.68656 11.6464C7.88182 11.8417 7.88182 12.1583 7.68656 12.3536C7.4913 12.5488 7.17472 12.5488 6.97945 12.3536L2.97945 8.35355C2.78419 8.15829 2.78419 7.84171 2.97945 7.64645L6.97945 3.64645C7.17472 3.45118 7.4913 3.45118 7.68656 3.64645Z"
        fill="#706F6D"
      />
    </Icon>
  );
});
