import React from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';

interface INoAccessTeamManagementModalProps {
  visible: boolean;
  onClose: () => void;
}

const NoAccessTeamManagementModal: React.FC<INoAccessTeamManagementModalProps> = ({ visible, onClose }) => {
  return (
    <div>
      <ActionModal isOpen={visible} className="ph-large rounded-big" showCloseButton={false} onClose={onClose}>
        <Text weight="bold"> Page cannot be accessed</Text>

        <div className="mt-x-large mb-large">
          <Text>
            Sorry, your workspace permissions do not allow you to access this page. Please contact your workspace&apos;s
            primary owner if you think this is a mistake.
          </Text>
        </div>
        <ActionModalFooter>
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    </div>
  );
};

export default NoAccessTeamManagementModal;
