import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ErrorSVG } from 'assets/UndrawSVG';
import { Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Spinner } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState, dispatch } from 'stores/rematch/root-store';
import { notification, Radio, Row } from 'antd';
import { BookingActionType, EditRecurringMode, ShiftSlotStatus } from 'utilities/enum-utils';
import _ from 'lodash';
import { ShiftClashContent } from 'common-components/shift-clash';
import { IShiftClashConflict } from 'interfaces/service-interfaces';
import CommonUtils from 'utilities/common-utils';
import AvailabilityConflictTimes from 'views/team/details/tabs-panel/availability/components/AvailablilityConflictTimes';
import { timeZone } from 'interfaces/timezone-type';
import ConflictCheckUtils from 'utilities/conflict-check-utils';
import BookingActionSuccessNotificationContent from './BookingActionSuccessNotificationContent';

interface IRecurringBookingConfirmShiftForWorkerActionState {
  step: number;
  canManuallyClose: boolean;
  title: string;
  isLoading: boolean;
  selectedOption: EditRecurringMode;
  shiftClashConflicts: IShiftClashConflict[];
  shiftsOutsideGeneralAvailability: any;
  shiftSlotStatus: ShiftSlotStatus;
  ignoreOutsideAvailabilityShifts: any;
  ignoreShiftClashShiftIds: string[];
}

interface IRecurringBookingConfirmShiftForWorkerActionProps {
  selectedBooking: any;
  doConfirmRecurringShiftForWorker: typeof dispatch.bookingsStore.doConfirmRecurringShiftForWorker;
  onClose: any;
  doCheckAssignWorker: typeof dispatch.bookingsStore.doCheckAssignWorker;
  isOpen: boolean;
  displayTimezone: timeZone;
}

class RecurringBookingConfirmShiftForWorkerActionModel extends Component<
  IRecurringBookingConfirmShiftForWorkerActionProps,
  IRecurringBookingConfirmShiftForWorkerActionState
> {
  state = {
    step: 1,
    canManuallyClose: true,
    title: 'Confirm worker',
    selectedOption: EditRecurringMode.Current,
    isLoading: false,
    shiftClashConflicts: [],
    shiftsOutsideGeneralAvailability: null,
    shiftSlotStatus: ShiftSlotStatus.CONFIRMED,
    ignoreOutsideAvailabilityShifts: [],
    ignoreShiftClashShiftIds: [],
    confirmedShiftConflicts: null,
    pendingShiftConflicts: null,
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _renderView = () => {
    const { step, selectedOption, shiftClashConflicts, shiftsOutsideGeneralAvailability, shiftSlotStatus } = this.state;
    const { selectedBooking, displayTimezone } = this.props;
    const workerName = `${selectedBooking.workerFirstName} ${selectedBooking.workerLastName}`;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <div>
            <Paragraph>
              The booking you are editing is part of a recurring booking series. Please select one of the following
              options for editing this booking.
            </Paragraph>
          </div>
          <Radio.Group value={selectedOption} onChange={this._onChangeOption} className="ml-medium">
            <Radio
              value={EditRecurringMode.Current}
              className={`${selectedOption === EditRecurringMode.Current && 'text-weight-bold'} mb-medium `}
            >
              <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                Confirm {workerName} as the worker for this booking only.
              </div>
            </Radio>
            <br />
            <Radio
              value={EditRecurringMode.CurrentAll}
              className={`${selectedOption === EditRecurringMode.CurrentAll && 'text-weight-bold'} mb-medium `}
            >
              <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                Confirm {workerName} as the worker for this booking and all following bookings that are awaiting
                confirmation.
              </div>
            </Radio>
            {/* KMS-424 : Temporary hide option all upcoming  */}
            {/* <Radio
              value={EditRecurringMode.Upcoming}
              className={`${selectedOption === EditRecurringMode.Upcoming && 'text-weight-bold'} mb-medium `}
            >
              <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                Confirm {workerName} as the worker for this booking and all upcoming bookings that are awaiting
                confirmation.
              </div>
            </Radio> */}
          </Radio.Group>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._checkForConflict} loading={this.state.isLoading}>
              Confirm
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Confirming this booking, won't be long...</Paragraph>
          </div>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Oops something has gone wrong, please try again</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Go Back to Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 5) {
      const totalShiftClashes = CommonUtils.calculateTotalShiftClashes(shiftClashConflicts);

      let description = (
        <Paragraph>
          This team member is assigned to{' '}
          <b>
            {totalShiftClashes} shift{totalShiftClashes > 1 ? 's ' : ' '}
          </b>
          that conflict with this booking time. Be sure to double-check their availability before confirming.
        </Paragraph>
      );
      let firstColumnTitle = 'Booking being assigned';
      let okButtonText = 'Confirm anyway';
      let hasActionButton = false;
      let hasKeepInShift = false;

      switch (selectedOption) {
        case EditRecurringMode.CurrentAll:
        case EditRecurringMode.Upcoming:
          description = (
            <Paragraph>
              This team member is assigned to shifts that conflict with sessions in this recurring series. Choose
              whether you’d like to keep them in the following bookings.
            </Paragraph>
          );
          firstColumnTitle = 'Bookings';
          okButtonText = 'Confirm';
          hasActionButton = true;
          hasKeepInShift = true;
          break;
      }

      return (
        <ShiftClashContent
          shiftClashConflicts={shiftClashConflicts}
          description={description}
          firstColumnTitle={firstColumnTitle}
          okButtonText={okButtonText}
          hasKeepInShift={hasKeepInShift}
          hasActionButton={hasActionButton}
          isLoading={this.state.isLoading}
          onCancel={() => {
            this.setState({ step: 1, title: 'Confirm worker', isLoading: false });
          }}
          onOk={this._onSubmitShiftClash}
        />
      );
    }

    if (this.state.step === 6) {
      return (
        <>
          <Paragraph>
            The new session time conflicts with the team member’s <Text weight="bold">General availability</Text>.
            Please select which shifts (if any) you wish to keep for the team member or continue without selecting any.
          </Paragraph>
          <div className="bg-quaternary pv-x-large ph-12 mt-x-large">
            <AvailabilityConflictTimes
              conflicts={shiftsOutsideGeneralAvailability}
              timezone={displayTimezone}
              onSelectShifts={(shifts) => this.setState({ ignoreOutsideAvailabilityShifts: shifts })}
              shiftSlotStatus={shiftSlotStatus}
            />
          </div>
          <div className="mt-x3-large">
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton
                size="large"
                onClick={() => {
                  this.setState({ step: 1, title: 'Confirm worker', isLoading: false });
                }}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" className="ml-small" onClick={() => this._onAccept()}>
                Continue and assign to selected shifts
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
  };

  private _checkForConflict = async () => {
    const { doCheckAssignWorker, selectedBooking } = this.props;
    const { selectedOption } = this.state;

    this.setState({ isLoading: true });
    try {
      const result: any = await doCheckAssignWorker({
        bookingRequestId: selectedBooking.bookingRequestId,
        bookingId: selectedBooking.bookingId,
        editRecurringMode: this.state.selectedOption,
        workerId: selectedBooking.workerId,
        startDateTime: selectedBooking.startDateTime,
        isConfirmBookings: true,
      });
      if (result && result.data && !_.isEmpty(result.data.shiftClashConflicts)) {
        this.setState({
          shiftClashConflicts: result.data.shiftClashConflicts,
          shiftsOutsideGeneralAvailability: !_.isEmpty(result.data.shiftsOutsideGeneralAvailability)
            ? result.data.shiftsOutsideGeneralAvailability
            : null,
          title: 'Hold on, this team member is already working',
          step: 5,
          isLoading: false,
        });
      } else if (
        result &&
        result.data &&
        !_.isEmpty(result.data.shiftsOutsideGeneralAvailability) &&
        selectedOption === EditRecurringMode.CurrentAll
      ) {
        this.setState({
          step: 6,
          shiftsOutsideGeneralAvailability: result.data.shiftsOutsideGeneralAvailability,
          title: 'Team member unavailable for bookings',
          isLoading: false,
        });
      } else {
        this._onAccept();
      }
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ step: 5 });
    }
  };

  private _checkIsSingleBooking = () => {
    const { selectedOption } = this.state;

    return selectedOption === EditRecurringMode.Current;
  };

  private _onAccept = async (shiftClashIds: string[] = []) => {
    const { doConfirmRecurringShiftForWorker, selectedBooking } = this.props;
    const { ignoreOutsideAvailabilityShifts, ignoreShiftClashShiftIds } = this.state;
    this.setState({ step: 2, canManuallyClose: false });
    try {
      const payload = {
        bookingId: selectedBooking.bookingId,
        bookingRequestId: selectedBooking.bookingRequestId,
        editRecurringMode: this.state.selectedOption,
        isRemovePendingShiftSlots: true,
        ignoreShiftClashShiftIds: !_.isEmpty(ignoreShiftClashShiftIds) ? ignoreShiftClashShiftIds : shiftClashIds,
        ignoreOutsideAvailabilityShiftIds: _.map(
          ignoreOutsideAvailabilityShifts,
          (shift) => shift.supportWorkerAttendanceId,
        ),
      };

      const { confirmedShiftsCount, currentShiftStatus } = await doConfirmRecurringShiftForWorker(payload);
      if (confirmedShiftsCount > 0) {
        const description = (
          <BookingActionSuccessNotificationContent
            action={BookingActionType.CONFIRM}
            currentShiftStatus={currentShiftStatus}
            shiftsCount={confirmedShiftsCount}
            workerName={`${selectedBooking.workerFirstName} ${selectedBooking.workerLastName}`}
          />
        );
        notification.open({
          message: <b>Team member confirmed</b>,
          description,
        });
      }
      this._onCloseModal();
    } catch (e) {
      this._onError();
    }
  };

  private _onSubmitShiftClash = (ignoreShiftClashShiftIds) => {
    const { shiftsOutsideGeneralAvailability, shiftClashConflicts } = this.state;

    const remainedShiftsOutsideGeneralAvailability =
      ConflictCheckUtils.filterOutsideAvailabilityShiftsAfterSelectingShiftClashShiftById(
        shiftClashConflicts,
        ignoreShiftClashShiftIds,
        shiftsOutsideGeneralAvailability,
      );

    if (!_.isEmpty(remainedShiftsOutsideGeneralAvailability) && !this._checkIsSingleBooking()) {
      this.setState({
        step: 6,
        title: 'Team member unavailable for bookings',
        ignoreShiftClashShiftIds,
        shiftsOutsideGeneralAvailability: remainedShiftsOutsideGeneralAvailability,
      });
    } else {
      this._onAccept(ignoreShiftClashShiftIds);
    }
  };

  private _onError = () => {
    this.setState({ step: 4, canManuallyClose: true });
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    this.setState(
      {
        step: 1,
        title: 'Confirm worker',
        isLoading: false,
        confirmedShiftConflicts: null,
        pendingShiftConflicts: null,
      },
      onClose,
    );
  };

  render() {
    let { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width={this.state.step === 5 || this.state.step === 6 ? 'x-large' : 'medium'}
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this._renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doConfirmRecurringShiftForWorker: dispatch.bookingsStore.doConfirmRecurringShiftForWorker,
  doCheckAssignWorker: dispatch.bookingsStore.doCheckAssignWorker,
});

export default connect(mapState, mapDispatch)(RecurringBookingConfirmShiftForWorkerActionModel);
