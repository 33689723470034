import { z } from 'zod';

export const CsvImportFormSchema = z.object({
  jobName: z.string().optional(),
  importType: z.enum(['customers', 'customerNotes', 'customerCareInformation'], {
    required_error: 'Please select an import type.',
    invalid_type_error: 'Invalid import type.',
  }),
  csvFile: z
    .instanceof(File, { message: 'Please upload a CSV file.' })
    .refine((csvFile) => csvFile.size <= 5 * 1024 * 1024, 'File size must be less than 5MB.'),
  validateOnly: z.boolean(),
});
export type CsvImportForm = z.infer<typeof CsvImportFormSchema>;

export type CsvImportType = CsvImportForm['importType'];
export const csvImportTypeLabels: Record<CsvImportType, string> = {
  customers: 'Customers',
  customerNotes: 'Customer notes',
  customerCareInformation: 'Customer care information',
};
