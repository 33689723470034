import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Slash = forwardRef(function Slash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.72 5.66a8.25 8.25 0 0 1 11.62 11.62L6.72 5.66ZM5.66 6.72a8.25 8.25 0 0 0 11.62 11.62L5.66 6.72ZM12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Z"
        fill="currentColor"
      />
    </Icon>
  );
});
