import React from 'react';
import { Component } from 'react';
import { Text } from 'common-components/typography';
import { BookingActionType, ShiftSlotStatus } from 'utilities/enum-utils';

interface IBookingActionSuccessNotificationContentProps {
  currentShiftStatus: string;
  shiftsCount: number;
  action: string;
  workerName: string;
}

class BookingActionSuccessNotificationContent extends Component<IBookingActionSuccessNotificationContentProps, null> {
  private renderAdditionalText = () => {
    const { currentShiftStatus, shiftsCount, action } = this.props;
    const additionalText =
      (action === BookingActionType.CONFIRM && currentShiftStatus === ShiftSlotStatus.CONFIRMED) ||
      (action === BookingActionType.ASSIGN && currentShiftStatus !== ShiftSlotStatus.UNASSIGNED) ? (
        shiftsCount > 1 ? (
          <>
            this and <b>{`${shiftsCount - 1} other`}</b> bookings.
          </>
        ) : (
          <>this booking.</>
        )
      ) : (
        <>
          <b>{`${shiftsCount} other`}</b> bookings.
        </>
      );

    return additionalText;
  };

  render() {
    const { action, workerName } = this.props;
    const additionalText = this.renderAdditionalText();
    return (
      <Text>
        You have successfully {`${action}ed`} <b>{`${workerName}`}</b> as the team member for {additionalText}
      </Text>
    );
  }
}

export default BookingActionSuccessNotificationContent;
