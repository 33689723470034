import { useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment-timezone';

import { IGroupBookingDetails } from 'views/group-services/manage-booking-modal/interfaces/group-booking-interfaces';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { getGroupServiceCustomerRatioFromFormValues } from '../get-group-service-customer-ratio-from-form';
import { EditRatiosSchema } from '../schema';

export type EditBookingCustomerRatioPayload = {
  bookingId: string;
  scheduleId?: string;
  teamMemberCustomerRatio?: string;
  startDateTime?: Date | Moment;
  endDateTime?: Date | Moment;
  isCustomRatio: boolean;
  customRatios?: {
    startDateTime: Date | Moment;
    endDateTime: Date | Moment;
    customTimeId?: string;
    teamMemberCustomerRatio?: string;
    comments?: string;
  }[];
  durationRatios?: {
    duration: number;
    teamMemberCustomerRatio?: string;
    comments?: string;
  }[];
};

export function useSaveRatio() {
  const booking = useSelector(
    (state: IRootState) => state.groupBookingsStore.selectedGroupBookingItem,
  ) as unknown as IGroupBookingDetails;
  const {
    groupBookingsStore: { doEditBookingCustomerRatio },
  } = useDispatch<IRootDispatch>();
  const isSaving = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const saveRatio = useCallback(
    async (
      values: EditRatiosSchema,
      onSuccess?: (payload: EditBookingCustomerRatioPayload) => void,
      onError?: (error: Error) => void,
    ) => {
      if (Object.keys(values).length && !isSaving.current) {
        isSaving.current = true;
        try {
          setIsLoading(true);
          setHasError(false);
          setError(null);

          const ratio = getGroupServiceCustomerRatioFromFormValues(values);

          const payload: EditBookingCustomerRatioPayload = {
            bookingId: booking.bookingId,
            ...{ ...ratio, customRatio: undefined },
            customRatios: ratio.customRatio?.map((customRatio_) => ({
              ...customRatio_,
              comments: '',
              startDateTime: moment.tz(moment(customRatio_.startDateTime).format('YYYY-MM-DD HH:mm'), booking.timezone),
              endDateTime: moment.tz(moment(customRatio_.endDateTime).format('YYYY-MM-DD HH:mm'), booking.timezone),
            })),
          };

          await doEditBookingCustomerRatio(payload);
          onSuccess?.(payload);
        } catch (e) {
          setHasError(true);
          setError(e);
          onError?.(e);
        } finally {
          setIsLoading(false);
          isSaving.current = false;
        }
      }
    },
    [booking],
  );

  return {
    saveRatio,
    isLoading,
    hasError,
    error,
  };
}
