import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Schema } from '../schema';
import fields from '../fields';

const useCancelledByValidation = (form: UseFormReturn<Schema>) => {
  const { watch, setError } = form;

  const cancelledBy = watch(fields.cancelledBy.name);
  const cancelRecurringBookingOption = watch(fields.cancelRecurringBooking.name);

  useEffect(() => {
    if (!cancelledBy && cancelRecurringBookingOption) {
      setError(fields.cancelledBy.name, { message: 'Select cancellation option' });
    }
  }, [cancelledBy, cancelRecurringBookingOption]);
};

export default useCancelledByValidation;
