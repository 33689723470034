import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { message, notification } from 'antd';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { Text } from 'common-components/typography';

import { SingleActionHandler } from './SingleActionHandler';
import { MultiActionHandler } from './MultiActionHandler';

interface IBookingActionsHandlerProps {
  statusList: any;
  onHideSheet: any;
  refreshBookingListing: any;
  isAwaitingBookActions: any;
  bookingsList: any;

  openBulkRemoveTeamMemberModal: (bookingList, status: string) => void;
  openCancelBookingModal: (bookingList, status: string) => void;
  openBulkApproveWarningModal: (missingPaymentMethodBookings, bookingList, bookingErrors) => void;

  doBatchAcceptBookings: typeof dispatch.bookingsStore.doBatchAcceptBookings;
  doBatchAcceptConfirmBookings: typeof dispatch.bookingsStore.doBatchAcceptConfirmBookings;
  doBatchConfirmBookings: typeof dispatch.bookingsStore.doBatchConfirmBookings;
  doBatchRejectBookings: typeof dispatch.bookingsStore.doBatchRejectBookings;
  doBatchApproveBookings: typeof dispatch.bookingsStore.doBatchApproveBookings;
  doBatchSendToFinanceBookings: typeof dispatch.bookingsStore.doBatchSendToFinanceBookings;
}

// This component handles all triggered actions from the

export class BookingActionsHandlerBase extends Component<IBookingActionsHandlerProps> {
  state = {
    showMultiPopover: false,
    isBulkApprovalWarningModalOpen: false,
    missingPaymentMethodBookings: null,
  };

  _onSetPopover = (showPopover) => {
    this.setState({ showMultiPopover: showPopover });
  };

  _onTriggerAction = async ({ status, action }) => {
    this._onSetPopover(false);

    const {
      onHideSheet,
      doBatchApproveBookings,
      doBatchRejectBookings,
      doBatchConfirmBookings,
      doBatchAcceptConfirmBookings,
      doBatchAcceptBookings,
      doBatchSendToFinanceBookings,
      refreshBookingListing,
    } = this.props;

    if (action !== 'REMOVE_TEAM_MEMBER' && action !== 'CANCEL') {
      onHideSheet();
    }
    const dismissAction = message.loading('Executing bulk actions...', 0);
    let doRefreshBookingList = true;

    // Accept Action
    if (action === 'ACCEPT') {
      try {
        await doBatchAcceptBookings({ action, status });

        notification.success({
          message: <Text weight="bold">Bulk actions successfully completed.</Text>,
          description: (
            <Text className="mt-medium">
              Bulk actions for <span className="text-weight-bold">Accept Bookings</span> complete.
            </Text>
          ),
          duration: 3,
        });
      } catch (e) {
        console.error(e);
        notification.error({
          message: <Text weight="bold">Bulk actions failed.</Text>,
          description: (
            <Text className="mt-medium">
              Bulk actions for <span className="text-weight-bold">Accept Bookings</span> complete has encounter an
              error. Please try again.
            </Text>
          ),
        });
      }
      dismissAction();
    }

    if (action === 'ACCEPTCONFIRM') {
      try {
        await doBatchAcceptConfirmBookings({ action, status });

        notification.success({
          message: <Text weight="bold">Bulk actions successfully completed.</Text>,
          description: (
            <Text className="mt-medium">
              Bulk actions for <span className="text-weight-bold">Accept & Confirm Bookings</span> complete.
            </Text>
          ),
        });
      } catch (e) {
        console.error(e);
        notification.error({
          message: <Text weight="bold">Bulk actions failed.</Text>,
          description: (
            <Text className="mt-medium">
              Bulk actions for <span className="text-weight-bold">Accept & Confirm Bookings</span> complete has
              encounter an error. Please try again.
            </Text>
          ),
        });
      }
      dismissAction();
    }

    if (action === 'CONFIRM') {
      try {
        await doBatchConfirmBookings({ action, status });

        notification.success({
          message: <Text weight="bold">Bulk actions successfully completed.</Text>,
          description: (
            <Text className="mt-medium">
              Bulk actions for <span className="text-weight-bold">Confirm Bookings</span> complete.
            </Text>
          ),
        });
      } catch (e) {
        console.error(e);
        notification.error({
          message: <Text weight="bold">Bulk actions failed.</Text>,
          description: (
            <Text className="mt-medium">
              Bulk actions for <span className="text-weight-bold">Confirm Bookings</span> complete has encounter an
              error. Please try again.
            </Text>
          ),
        });
      }
      dismissAction();
    }

    if (action === 'APPROVE') {
      try {
        const result: any = await doBatchSendToFinanceBookings({ action, status });

        if (
          result &&
          (!_.isEmpty(result.response.missingPaymentMethodBookings) || !_.isEmpty(result.response.bookingErrors))
        ) {
          doRefreshBookingList = false;
          this.props.openBulkApproveWarningModal(
            result.response.missingPaymentMethodBookings,
            result.bookingList,
            result.response.bookingErrors,
          );
        } else {
          notification.success({
            message: <Text weight="bold">Bulk actions successfully completed.</Text>,
            description: (
              <Text className="mt-medium">
                Bulk actions for <span className="text-weight-bold">Approve Bookings</span> complete.
              </Text>
            ),
          });
        }
      } catch (e) {
        console.error(e);
        notification.error({
          message: <Text weight="bold">Bulk actions failed.</Text>,
          description: (
            <Text className="mt-medium">
              Bulk actions for <span className="text-weight-bold">Approve Bookings</span> complete has encounter an
              error. Please try again.
            </Text>
          ),
        });
      }
      dismissAction();
    }
    if (action === 'REJECT') {
      try {
        await doBatchRejectBookings({ action, status });

        notification.success({
          message: <Text weight="bold">Bulk actions successfully completed.</Text>,
          description: (
            <Text className="mt-medium">
              Bulk actions for <span className="text-weight-bold">Decline Bookings</span> complete.
            </Text>
          ),
        });
      } catch (e) {
        console.error(e);
        notification.error({
          message: <Text weight="bold">Bulk actions failed.</Text>,
          description: (
            <Text className="mt-medium">
              Bulk actions for <span className="text-weight-bold">Decline Bookings</span> has encounter an error. Please
              try again.
            </Text>
          ),
        });
      }
      dismissAction();
    }
    if (action === 'SEND_FOR_PAYMENT') {
      try {
        await doBatchSendToFinanceBookings({ action, status });

        notification.success({
          message: <Text weight="bold">Bulk actions successfully completed.</Text>,
          description: (
            <Text className="mt-medium">
              Bulk actions for <span className="text-weight-bold">Sending to Payment</span> complete.
            </Text>
          ),
        });
      } catch (e) {
        console.error(e);
        notification.error({
          message: <Text weight="bold">Bulk actions failed.</Text>,
          description: (
            <Text className="mt-medium">
              Bulk actions for <span className="text-weight-bold">Sending to Payment</span> has encounter an error.
              Please try again.
            </Text>
          ),
        });
      }
      dismissAction();
    }

    if (action === 'REMOVE_TEAM_MEMBER') {
      try {
        const filterStatusBookingsCanRemoveTeamMember = _.filter(
          this.props.bookingsList,
          (booking) =>
            booking.status === status &&
            booking.paymentStatus !== 'REQUIRES_APPROVAL' &&
            booking.paymentStatus !== 'READY_FOR_BILLING' &&
            (booking.shiftSlotStatus === 'PENDING' || booking.shiftSlotStatus === 'CONFIRMED'),
        );
        await this.props.openBulkRemoveTeamMemberModal(filterStatusBookingsCanRemoveTeamMember, status);
        doRefreshBookingList = false;
      } catch (e) {
        console.error(e);
      }
      dismissAction();
    }

    if (action === 'CANCEL') {
      try {
        const filterStatusBookings = _.filter(
          this.props.bookingsList,
          (booking) =>
            booking.status === status &&
            booking.paymentStatus !== 'REQUIRES_APPROVAL' &&
            booking.paymentStatus !== 'READY_FOR_BILLING',
        );
        await this.props.openCancelBookingModal(filterStatusBookings, status);
        doRefreshBookingList = false;
      } catch (e) {
        console.error(e);
      }
      dismissAction();
    }

    if (doRefreshBookingList) {
      refreshBookingListing();
    }
  };

  render() {
    const { statusList, bookingsList } = this.props;

    const statusKeys = _.chain(statusList).keys().value();

    const hasMoreThanOneStatus = statusKeys.length > 1;

    const bookingsCanRemoveTeamMember = _.filter(
      bookingsList,
      (b) =>
        (b.shiftSlotStatus === 'PENDING' || b.shiftSlotStatus === 'CONFIRMED') &&
        b.paymentStatus !== 'REQUIRES_APPROVAL' &&
        b.paymentStatus !== 'READY_FOR_BILLING',
    );

    return (
      <div>
        {/*  Only one status selected; use Single Action Handler */}
        {!hasMoreThanOneStatus && (
          <SingleActionHandler
            statusKeys={statusKeys}
            onTriggerAction={this._onTriggerAction}
            bookingsCanRemoveTeamMember={bookingsCanRemoveTeamMember}
          />
        )}

        {/*  Only one status selected; use Multi Actions Handler */}
        {hasMoreThanOneStatus && (
          <MultiActionHandler
            showPopover={this.state.showMultiPopover}
            onSetPopover={this._onSetPopover}
            statusList={statusList}
            onTriggerAction={this._onTriggerAction}
            bookingsCanRemoveTeamMember={bookingsCanRemoveTeamMember}
          />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  isAwaitingBookActions: state.bookingsStore.isAwaitingBookActions,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  // Batch update methods
  doBatchAcceptBookings: dispatch.bookingsStore.doBatchAcceptBookings,
  doBatchAcceptConfirmBookings: dispatch.bookingsStore.doBatchAcceptConfirmBookings,
  doBatchConfirmBookings: dispatch.bookingsStore.doBatchConfirmBookings,
  doBatchRejectBookings: dispatch.bookingsStore.doBatchRejectBookings,
  doBatchApproveBookings: dispatch.bookingsStore.doBatchApproveBookings,
  doBatchSendToFinanceBookings: dispatch.bookingsStore.doBatchSendToFinanceBookings,
});

const BookingActionsHandler = connect(mapState, mapDispatch)(BookingActionsHandlerBase);

export { BookingActionsHandler };
