import { Box, BoxStyleProps, Inline, Text } from '@goodhuman-me/components';
import { Switch, TextField, Tooltip } from 'design-components';
import { IconWarning } from 'design-components/icon';
import { EValidationStatus } from 'design-components/types';
import React, { forwardRef } from 'react';
import { useController } from 'react-hook-form';
import TooltipInfo from './TooltipInfo';

interface IProps extends BoxStyleProps {
  children?: React.ReactNode;
}

const BoxItem: React.FC<IProps> = ({ children, ...props }) => {
  return (
    <Box paddingX="$space200" paddingY="$space150" {...props}>
      {children}
    </Box>
  );
};

const TextFieldItem = forwardRef(function TextFieldItem(props: any) {
  const {
    field: { name, onBlur, onChange, ref: inputRef, value },
    fieldState: { error },
  } = useController(props);

  const { message: errorMessage } = error || {};

  return (
    <BoxItem>
      <TextField
        {...props}
        ref={inputRef}
        borderWidth={0}
        minWidth={150}
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        value={value}
        backgroundColor="transparent"
        padding="$space130 $space150"
        boxShadow={errorMessage ? '0 0 0 2px #C13232 !important' : 'inherit'}
        validations={errorMessage ? [{ status: EValidationStatus.Invalid, message: null }] : []}
        containerStyles={{ input: { outline: 'none' } }}
        suffix={<TooltipInfo isShow={!!errorMessage} message={errorMessage} content={<IconWarning height={20} />} />}
      />
    </BoxItem>
  );
});

const SwitchItem = forwardRef(function SwitchItem(props: any) {
  const {
    field: { name, onBlur, onChange, ref: inputRef, value },
  } = useController(props);

  return (
    <BoxItem minWidth={150}>
      <div style={{ display: 'inline-block' }}>
        <Tooltip
          delay={0}
          autoAlign
          isDisabled={!props.isDisabled}
          placement="top center"
          content={
            <Inline
              justifyContent="center"
              width="max-content"
              backgroundColor="$infoPressed"
              padding="$space75 $space100 !important"
              borderRadius="$small"
            >
              <Text color="white" size="xsmall" fontWeight="$normal">
                Team member must have workspace access to disable app access.
              </Text>
            </Inline>
          }
        >
          <Switch
            aria-label="switch"
            {...props}
            ref={inputRef}
            name={name}
            isSelected={value}
            onBlur={onBlur}
            onChange={onChange}
          />
        </Tooltip>
      </div>
    </BoxItem>
  );
});

export const FormComponents = {
  TextFieldItem,
  SwitchItem,
  BoxItem,
};
