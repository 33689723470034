import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const SignOut = forwardRef(function SignOut(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.25h-.036c-.904 0-1.633 0-2.221.054-.607.055-1.137.171-1.618.449a3.75 3.75 0 0 0-1.372 1.373c-.278.48-.395 1.01-.45 1.617-.053.588-.053 1.318-.053 2.222v10.071c0 .905 0 1.634.053 2.222.055.607.172 1.136.45 1.618a3.75 3.75 0 0 0 1.372 1.372c.481.278 1.011.394 1.618.45.588.053 1.317.053 2.221.053h.072c.904 0 1.633 0 2.222-.054.606-.055 1.136-.17 1.617-.449a3.75 3.75 0 0 0 1.373-1.372c.278-.482.394-1.011.449-1.618.053-.588.053-1.317.053-2.222v-1.035a.75.75 0 0 0-1.5 0v1c0 .948 0 1.61-.047 2.122-.046.502-.13.788-.254 1.003a2.25 2.25 0 0 1-.824.823c-.215.124-.5.209-1.003.254-.512.047-1.173.048-2.122.048-.948 0-1.61-.001-2.122-.048-.502-.045-.788-.13-1.003-.254a2.25 2.25 0 0 1-.823-.823c-.125-.215-.21-.5-.255-1.003-.046-.513-.047-1.174-.047-2.122V7c0-.949 0-1.61.047-2.123.046-.502.13-.787.255-1.002a2.25 2.25 0 0 1 .823-.824c.215-.124.5-.209 1.003-.254C6.39 2.75 7.052 2.75 8 2.75c.949 0 1.61 0 2.122.047.503.045.788.13 1.003.254.342.198.626.482.824.824.124.215.208.5.254 1.002.046.513.047 1.174.047 2.123v1a.75.75 0 0 0 1.5 0V6.965c0-.904 0-1.634-.053-2.222-.055-.606-.171-1.136-.45-1.617a3.75 3.75 0 0 0-1.372-1.373c-.481-.278-1.01-.394-1.617-.45-.589-.052-1.318-.052-2.222-.052H8Zm9.53 6.22a.75.75 0 0 0-1.06 1.06l2.72 2.72H8a.75.75 0 0 0 0 1.5h11.19l-2.72 2.72a.75.75 0 0 0 1.06 1.06l4-4a.75.75 0 0 0 0-1.06l-4-4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
