import { Col } from 'antd';
import React from 'react';
import { Text } from '@good/components';
import { TeamStatus } from 'utilities/enum-utils';
import { TmMgmtBlueDot } from './tm-mgmt-blue-dot';

type TmMgmtBusinessAppColumnParams = {
  hasWorkerAppAccess: boolean;
  status: TeamStatus;
};

export const TmMgmtBusinessAppColumn = ({ status, hasWorkerAppAccess }: TmMgmtBusinessAppColumnParams) => {
  const fontColor = status !== TeamStatus.ENABLED ? 'tertiary' : 'black';

  if (status === TeamStatus.REMOVED) {
    return (
      <Col span={3}>
        <Text className="text-color-secondary">-</Text>
      </Col>
    );
  }

  if (hasWorkerAppAccess) {
    return (
      <Col span={3}>
        <Text className={`text-color-${fontColor}`}>
          <TmMgmtBlueDot /> Yes
        </Text>
      </Col>
    );
  }

  return (
    <Col span={3}>
      <Text className="text-color-secondary">No</Text>
    </Col>
  );
};
