import React from 'react';
import { Row } from '@tanstack/react-table';
import { ChevronDown, ChevronRight, FileAgreement, FolderOpen } from '@good/icons';
import { ServiceAgreementTableData } from '../types';

export const ServiceNameCell = (props: { row: Row<ServiceAgreementTableData> }) => {
  const { row } = props;
  const { name } = row.original;
  return (
    <div
      className="text-size-large"
      style={{
        paddingLeft: `${row.depth * 2}rem`,
      }}
    >
      <div>
        {row.getCanExpand() ? (
          <>
            <button onClick={row.getToggleExpandedHandler()} style={{ outline: 'none' }}>
              {row.getIsExpanded() ? (
                <ChevronDown className="cursor-pointer" />
              ) : (
                <ChevronRight className="cursor-pointer" />
              )}
            </button>
            <>
              <FolderOpen />
              <span className="ml-2">{name}</span>
            </>
          </>
        ) : (
          <span>
            <FileAgreement />
            <span className="ml-2">{name}</span>
          </span>
        )}
        {}
      </div>
    </div>
  );
};
