import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core/lib/esm/components';
import { ISession } from 'interfaces/session-interfaces';

interface IMarkAsNoShowModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot;
  onClose: any;
  doMarkShiftAsNoShow: any;
  session: ISession;
}

interface IMarkAsNoShowState {
  step: number;
  canManuallyClose: boolean;

  refreshShiftSlots: boolean;
}

class MarkAsNoShowModal extends Component<IMarkAsNoShowModalProps, IMarkAsNoShowState> {
  state = { step: 1, canManuallyClose: true, refreshShiftSlots: false };

  private _reset = () => this.setState({ step: 1, refreshShiftSlots: false });

  private _onSave = async () => {
    const { doMarkShiftAsNoShow, session, shiftSlot } = this.props;

    this.setState({ step: 2, canManuallyClose: false });

    const request = {
      serviceId: session.serviceId,
      serviceDateTimeId: session.serviceDateTimeId,
      supportWorkerAttendanceId: shiftSlot.supportWorkerAttendanceId,
    };
    await doMarkShiftAsNoShow(request);

    this.setState({ step: 3, canManuallyClose: true, refreshShiftSlots: true });
  };

  private _onClose = () => {
    const { onClose } = this.props;
    onClose({ targetFlag: 'isMarkAsNoShowOpen' }, this.state.refreshShiftSlots);
    this._reset();
  };

  render() {
    const { shiftSlot } = this.props;

    if (_.isEmpty(shiftSlot)) {
      return <></>;
    }

    const { supportWorkerId } = shiftSlot;

    if (_.isEmpty(supportWorkerId)) {
      return <></>;
    }

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title="Mark as no-show"
        onClose={this._onClose}
        canCloseOutside={this.state.canManuallyClose}
      >
        {this.state.step === 1 && (
          <div>
            <div className="mb-medium line-height-135">
              <Text lineHeight={135}>
                You are about to mark{' '}
                <b>
                  {shiftSlot.firstName} {shiftSlot.lastName}
                </b>{' '}
                as a <b>No Show</b> for this shift. <br />
                <br />
                Are you sure you want to continue?
              </Text>
            </div>

            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onSave}>
                Mark as No Show
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {this.state.step === 2 && (
          <div className="line-height-135 anim-slide-left">
            <div className="flex-column align-center mv-large justify-center">
              <div className="mb-medium">
                <Spinner size={80} />
              </div>
              <div className="text-align-center">
                <Text color="secondary" weight="bold">
                  Marking as no-show...
                </Text>
                <br />
                <Text color="secondary">This won't take long.</Text>
              </div>
            </div>
          </div>
        )}

        {this.state.step === 3 && (
          <div className="anim-fade-in-fast">
            <div className="mb-medium line-height-135">
              <Text lineHeight={135}>
                You have marked{' '}
                <b>
                  {shiftSlot.firstName} {shiftSlot.lastName}
                </b>{' '}
                as <b>No Show</b> for this shift.
              </Text>
            </div>

            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this._onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doMarkShiftAsNoShow: dispatch.groupServiceStore.doMarkShiftAsNoShow,
});

export default connect(mapState, mapDispatch)(MarkAsNoShowModal);
