import { Text } from '@good/components';
import { ChevronLeft, ChevronRight } from '@good/icons';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

type InsightsTablePageControlProperties = {
  setPage: (page: number) => void;
  currentPage: number;
  totalResults: number;
  resultsPerPage: number;
  setResultsPerPage: (resultsPerPage: number) => void;
};

type PageControlButtonProperties = {
  ariaLabel: string;
  onClick: () => void;
  disabled: boolean;
  children: React.ReactNode;
};

const PageControlButton: React.FC<PageControlButtonProperties> = ({ ariaLabel, onClick, disabled, children }) => (
  <button
    className="align-center flex h-8 w-8 justify-center rounded-lg border-base outline-none transition-all enabled:hover:border-[1px] disabled:opacity-25"
    aria-label={ariaLabel}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

type NumberBounds = { lower: number; upper: number };

const onBoundedNumberInput = (
  event: ChangeEvent<HTMLInputElement>,
  setter: (n: number) => void,
  bounds: NumberBounds,
): void => {
  // If given a non-number value for the input event, exit here.
  if (Number.isNaN(Number.parseInt(event.target.value))) {
    return;
  }

  const attemptedValue: number = Number.parseInt(event.target.value);

  // If the user attempts to enter a number above the upper bound, take the last digit instead.
  // i.e. Entering '16' to a field with a max of 10, the user intended 6.
  // Removing this functionality makes the number input field feel clunky to use.
  const value: number = attemptedValue > bounds.upper ? attemptedValue % 10 : attemptedValue;

  if (value < bounds.lower) {
    setter(bounds.lower);
  }
  if (value > bounds.upper) {
    setter(bounds.upper);
  }
  setter(value);
};

export const InsightsTablePageControl: React.FC<InsightsTablePageControlProperties> = ({
  setPage,
  currentPage,
  totalResults,
  resultsPerPage,
  setResultsPerPage,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'insights.table.pageControl' });

  const pages = Math.ceil(totalResults / resultsPerPage);

  const onPageInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    onBoundedNumberInput(event, setPage, { lower: 1, upper: pages });
  const onPerPageInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    onBoundedNumberInput(event, setResultsPerPage, { lower: 1, upper: 10 });

  // We need to be able to expand the page input to account for unusually large numbers.
  // This would only ever reasonably extend into four or five digits in the absolute worst case.
  // This variable is used for switching between a large and a small width for this text input field.
  const isThreeDigitPages = Boolean(Math.floor(currentPage / 100));

  return (
    <div className="align-center text-sm flex flex-row justify-between text-weak">
      <div className="align-center flex flex-row justify-around space-x-2">
        <PageControlButton
          ariaLabel="previous page"
          onClick={() => setPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <ChevronLeft />
        </PageControlButton>

        <Text>{t('pageLabel')}</Text>
        <input
          className={`${
            isThreeDigitPages ? 'w-14' : 'w-10'
          } rounded-lg border-[1px] border-base p-2 text-center font-base transition-all`}
          aria-label="page-input"
          value={currentPage}
          onChange={onPageInputChange}
        />
        <Text>{t('ofHowManyPages', { pages, totalResults })}</Text>

        <PageControlButton
          ariaLabel="next page"
          onClick={() => setPage(currentPage + 1)}
          disabled={currentPage === pages}
        >
          <ChevronRight />
        </PageControlButton>
      </div>
      <div className="align-center flex flex-row space-x-2">
        <input
          className="w-10 rounded-lg border-[1px] border-base p-2 font-base"
          aria-label="results per page"
          value={resultsPerPage}
          onChange={onPerPageInputChange}
        />
        <Text>{t('resultsPerPage')}</Text>
      </div>
    </div>
  );
};
