import React, { useState } from 'react';
import { Icon, Popover, Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import rootStore, { IRootState } from 'stores/rematch/root-store';
import { CustomViewsModalType, FilterType } from 'utilities/enum-utils';
import { ICustomView } from 'interfaces/custom-views-interface';
import { WorkerViewTabPopover } from './TeamViewTabPopover';
import debounce from 'lodash/debounce';
import CustomViewPopover from 'common-components/custom-views/popover/CustomViewPopover';
import CustomViewsModal from 'common-components/custom-views/modals/CustomViewsModal';
import { Button, CloseButton, Group, Input, Stack } from '@good/ui/core';
import FilterSection from 'common-components/filter/FilterSection';
import { Diskette, Loader2Adjustable, Search } from '@good/icons';

const defaultFilterValue = [{ [FilterType.WORKER]: [] }, { [FilterType.SERVICE]: [] }];
const availableFilters = [
  FilterType.WORKER,
  FilterType.QUALIFICATIONS,
  FilterType.RELIGIONS,
  FilterType.GENDER,
  FilterType.SPECIALITIES,
  FilterType.LANGUAGES,
  FilterType.INTEREST,
  FilterType.WORKER_ALERTS,
  FilterType.SERVICE,
  FilterType.USER_LOCATION_BY_STATE,
  FilterType.AVAILABILITY_CHANGE_REQUESTS,
];

export const TeamMemberViewsAndFilters = () => {
  const history = useHistory();
  const [workerViewModalDisplay, setWorkerViewModalDisplay] = useState<{ open: boolean; type: CustomViewsModalType }>({
    open: false,
    type: CustomViewsModalType.CREATE,
  });
  const [showWorkerViewPopover, setShowWorkerViewPopover] = useState(false);
  const [showTabIconPopover, setShowTabIconPopover] = useState<string>();

  const workerPageFilter = useSelector((state: IRootState) => state.teamStore.workerPageFilter);
  const workerViews = useSelector((state: IRootState) => state.teamStore.workerViews);
  const workerListingActiveTab = useSelector((state: IRootState) => state.teamStore.workerListingActiveTab);
  const defaultWorkerViews = useSelector((state: IRootState) => state.teamStore.defaultWorkerViews);
  const displayedWorkerListingTabs = useSelector((state: IRootState) => state.teamStore.displayedWorkerListingTabs);
  const services = useSelector((state: IRootState) => state.servicesStore.services);

  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState(
    workerPageFilter.find((viewFilter) => viewFilter.filter === FilterType.SEARCH)?.values,
  );

  const setWorkerPageFilter = rootStore.dispatch.teamStore.setWorkerPageFilter;
  const setDisplayedWorkerListingTabs = rootStore.dispatch.teamStore.setDisplayedWorkerListingTabs;
  const setWorkerListingActiveTab = rootStore.dispatch.teamStore.setWorkerListingActiveTab;
  const doAddWorkerView = rootStore.dispatch.teamStore.doAddWorkerView;
  const doDeleteWorkerView = rootStore.dispatch.teamStore.doDeleteWorkerView;
  const doUpdateWorkerViewTab = rootStore.dispatch.teamStore.doUpdateWorkerViewTab;
  const doDuplicateWorkerView = rootStore.dispatch.teamStore.doDuplicateWorkerView;

  const generateActiveTabClassName = (activeTab: { customViewId?: string } | null, tab: { customViewId?: string }) =>
    activeTab?.customViewId === tab.customViewId ? `text-color-blue-action` : 'text-color-secondary';

  const changeTab = (selectedTabId: string) => {
    const displayedTabs = [...defaultWorkerViews, ...displayedWorkerListingTabs];
    const activeTab = displayedTabs.find((tab) => tab.customViewId === selectedTabId);
    setWorkerListingActiveTab(activeTab);
  };

  const _onEnterSearchText = (searchTerm: string) => {
    setSearching(true);
    setSearchTerm(searchTerm);
    _debounceSearch(searchTerm);
  };
  const _searchText = (searchTerm: string) => {
    setWorkerPageFilter([
      ...workerPageFilter.filter((viewFilter) => viewFilter.filter !== FilterType.SEARCH),
      { filter: 'search', values: searchTerm, selectionLabel: 'All' },
    ]);
    setSearching(false);
  };
  const _debounceSearch = debounce(_searchText, 500);

  return (
    <Stack gap='xs'>
      <CustomViewsModal
        isOpen={workerViewModalDisplay.open}
        onCloseViewModal={() => {
          setWorkerViewModalDisplay({ type: CustomViewsModalType.CREATE, open: false });
        }}
        onUpdateViewModal={() => {
          setShowWorkerViewPopover(false);
          setWorkerViewModalDisplay({ type: CustomViewsModalType.CREATE, open: true });
        }}
        type={workerViewModalDisplay.type}
        pageViews={workerViews}
        pageFilter={workerPageFilter}
        pageListingActiveTab={workerListingActiveTab}
        defaultViews={defaultWorkerViews}
        defaultFilterValue={defaultFilterValue}
        setPageFilter={setWorkerPageFilter}
        doAddView={doAddWorkerView}
        doDeleteView={doDeleteWorkerView}
        doUpdateViewTab={doUpdateWorkerViewTab}
        doDuplicateView={doDuplicateWorkerView}
      />

      <Tabs
        type='card'
        tabBarExtraContent={
          <>
            <Popover
              content={
                <CustomViewPopover
                  pageViews={workerViews}
                  displayedPageListingTabs={displayedWorkerListingTabs}
                  onCreateNewView={() => {
                    setShowWorkerViewPopover(false);
                    setWorkerViewModalDisplay({ type: CustomViewsModalType.CREATE, open: true });
                  }}
                  onDisplayTab={setShowWorkerViewPopover}
                  setDisplayedPageListingTabs={setDisplayedWorkerListingTabs}
                  setPageListingActiveTab={setWorkerListingActiveTab}
                />
              }
              trigger='click'
              visible={showWorkerViewPopover}
              onVisibleChange={setShowWorkerViewPopover}
            >
              <Button color='blue' variant='subtle' size='sm'>
                + Add view
              </Button>
            </Popover>
            <Button color='blue' variant='subtle' size='sm' onClick={() => history.push('/team/all-views')}>
              All views
            </Button>
          </>
        }
        activeKey={workerListingActiveTab?.customViewId}
        defaultActiveKey='TEAM_MEMBERS'
        animated={true}
        onChange={changeTab}
      >
        {defaultWorkerViews.map((tab) => {
          return (
            <Tabs.TabPane
              tab={<span className={generateActiveTabClassName(workerListingActiveTab, tab)}>{tab.name}</span>}
              key={tab.customViewId}
            />
          );
        })}
        {displayedWorkerListingTabs.map((tab: ICustomView) => {
          const togglePopOver = (value: boolean) => setShowTabIconPopover(value ? tab.customViewId : undefined);

          return (
            <Tabs.TabPane
              tab={
                <span className={generateActiveTabClassName(workerListingActiveTab, tab)}>
                  {tab.isPinned && <Icon type='pushpin' theme='filled' className='mr-small' />}
                  {tab.name}
                  <Popover
                    content={
                      <WorkerViewTabPopover
                        onHidePopOver={togglePopOver}
                        currentTab={tab}
                        onShowWorkerViewModal={(viewModalType?: CustomViewsModalType) => {
                          setShowWorkerViewPopover(false);
                          setWorkerViewModalDisplay({ type: viewModalType ?? CustomViewsModalType.CREATE, open: true });
                        }}
                        doUpdateWorkerViewTab={doUpdateWorkerViewTab}
                      />
                    }
                    visible={showTabIconPopover === tab.customViewId}
                    onVisibleChange={togglePopOver}
                    placement='bottom'
                    trigger='click'
                  >
                    <Icon className='ml-small mr-none' type='caret-down' />
                  </Popover>
                </span>
              }
              key={tab.customViewId}
            />
          );
        })}
      </Tabs>

      <Group gap='sm'>
        <Input
          size='sm'
          placeholder='Search by name'
          leftSection={searching && searchTerm ? <Loader2Adjustable className='animate-spin' /> : <Search />}
          value={searchTerm}
          onChange={(e) => _onEnterSearchText(e.target.value)}
          style={{ width: '250px' }}
          rightSectionPointerEvents='all'
          rightSection={
            <CloseButton
              aria-label='Clear input'
              onClick={() => _onEnterSearchText('')}
              style={{
                display: searchTerm ? undefined : 'none',
              }}
            />
          }
        />

        <FilterSection
          availableFilters={availableFilters}
          filters={workerPageFilter}
          onChangeFilter={setWorkerPageFilter}
          displayTimezone={null}
          displayMoreFilter={true}
          services={services}
          containerClassName='grow'
        />

        <Button
          variant='outline'
          leftSection={<Diskette />}
          onClick={() => {
            const tab = workerListingActiveTab;
            setShowWorkerViewPopover(false);
            if (tab?.isOwner) {
              setWorkerViewModalDisplay({ type: CustomViewsModalType.SAVE_VIEW, open: true });
            } else if (tab?.isDefault) {
              setWorkerViewModalDisplay({ type: CustomViewsModalType.SAVE_DEFAULT_VIEW, open: true });
            } else {
              setWorkerViewModalDisplay({ type: CustomViewsModalType.SAVE_AS_NEW_COPY_FROM_OTHERS, open: true });
            }
          }}
        >
          Save view
        </Button>
      </Group>
    </Stack>
  );
};
