import { Popover, ProgressBar } from '@blueprintjs/core';
import { Avatar, Col, Empty, Icon, Skeleton } from 'antd';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { GridHeader, GridRow } from 'common-components/grids';
import { SubTitle, Text } from 'common-components/typography';
import { IVersionHistoryWorkflowTemplate } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IRootState, state } from 'stores/rematch/root-store';

interface IWorkflowTemplateDetailVersionHistoryListingProps {
  versionHistoryWorkflowTemplates: typeof state.workflowStore.versionHistoryWorkflowTemplates;
  isLoading: boolean;
  onOpenVersionHistoryModal: (versionHistory: IVersionHistoryWorkflowTemplate) => void;
}

const ListEmptyState = () => {
  return (
    <div className="flex-1 bg-white  align-center flex-column">
      <div className="">
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      <Text size="x2-large" color="secondary" weight="bold">
        No version history found.
      </Text>
      <br /> <br />
    </div>
  );
};

class WorkflowTemplateDetailVersionHistoryListing extends PureComponent<IWorkflowTemplateDetailVersionHistoryListingProps> {
  render() {
    const { onOpenVersionHistoryModal, versionHistoryWorkflowTemplates, isLoading } = this.props;

    return (
      <div>
        <Text size="x2-large" className="text-weight-bolder">
          Version history
        </Text>
        <div className="bg-white mt-small">
          <GridHeader bordered containerClassName="border-secondary">
            <Col span={8}>
              <SubTitle containerClassName="line-height-135">Version</SubTitle>
            </Col>
            <Col span={6}>
              <SubTitle containerClassName="line-height-135">Date Created</SubTitle>
            </Col>
            <Col span={7}>
              <SubTitle containerClassName="line-height-135">Created By</SubTitle>
            </Col>
            <Col span={3} className="text-align-center">
              <Icon type="ellipsis" className="text-color-blue-action p-x-small bordered" />
            </Col>
          </GridHeader>
          {isLoading ? (
            <div>
              <div className="pv-large">
                <ProgressBar />
              </div>
              <Skeleton active title={true} paragraph={{ rows: 1 }} />
              <Skeleton active title={true} paragraph={{ rows: 1 }} />
              <Skeleton active title={true} paragraph={{ rows: 1 }} />
            </div>
          ) : !_.isEmpty(versionHistoryWorkflowTemplates) ? (
            <>
              {versionHistoryWorkflowTemplates.map((workflow, index) => (
                <GridRow key={index}>
                  <Col span={8}>
                    <Text className="text-weight-bold" size="regular">
                      {`V${workflow.version}`}
                    </Text>
                  </Col>
                  <Col span={6}>
                    <Text className="text-weight-regular" size="regular">
                      {moment(workflow.createdOn).format('D MMMM YYYY')}
                    </Text>
                  </Col>
                  <Col span={7}>
                    <div className="flex align-center">
                      <Avatar className="mr-small" icon="user" src={workflow.avatar} />
                      <Text className="text-weight-regular">{workflow.displayName}</Text>
                    </div>
                  </Col>
                  <Col span={3} className="text-align-center">
                    <Popover
                      content={
                        <ActionMenu>
                          <ActionMenuItem
                            text="View version history"
                            onClick={() => onOpenVersionHistoryModal(workflow)}
                          />
                        </ActionMenu>
                      }
                      popoverClassName={'mb-medium'}
                      position={'bottom-right'}
                      interactionKind="click"
                    >
                      <Icon type="ellipsis" className="text-color-blue-action p-x-small bordered cursor-pointer" />
                    </Popover>
                  </Col>
                </GridRow>
              ))}
            </>
          ) : (
            <ListEmptyState />
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  versionHistoryWorkflowTemplates: state.workflowStore.versionHistoryWorkflowTemplates,
});

export default connect(mapState, null)(WorkflowTemplateDetailVersionHistoryListing);
