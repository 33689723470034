import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { PrimaryButton } from 'common-components/buttons';
import { Avatar } from 'antd';
import moment from 'moment';
import { TeamStatus } from 'utilities/enum-utils';
interface IViewRemovalReasonModalProps {
  isOpen: boolean;
  worker: any;
  onClose: () => void;
}

class ViewRemovalReasonModal extends Component<IViewRemovalReasonModalProps> {
  private _onCloseModal = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, worker } = this.props;
    return (
      worker && (
        <ActionModal
          title="Removal reason"
          isOpen={isOpen}
          onClose={this._onCloseModal}
          width="medium"
          verticalAlignment="center"
        >
          <div className="mv-medium anim-slide-left">
            <div className="mb-small">
              <FieldLabel text={'USER'} />
            </div>
            <div className={'flex-row align-center'}>
              <Avatar shape={'square'} src={worker.attachmentUrl} className={'mr-medium'} />
              <Text size="x3-large">{worker.firstName + ' ' + worker.lastName}</Text>
              {worker.status === TeamStatus.REMOVED && (
                <div className={'ml-medium'}>
                  <Text size={'regular'} color={'red-dark'}>
                    REMOVED
                  </Text>
                </div>
              )}
            </div>
          </div>
          <div className="mb-x2-small">
            <FieldLabel text={'REASON'} />
          </div>

          <Paragraph>
            {worker.removeReason ? (
              <Text style={{ whiteSpace: 'pre-line' }}>{worker.removeReason}</Text>
            ) : (
              <Text color={'tertiary'}>No reason added</Text>
            )}
          </Paragraph>

          <div className="mb-x2-small">
            <FieldLabel text={'Date of removal'} />
          </div>

          <Paragraph>{moment(worker.removedOn).format('DD/MM/YYYY')}</Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Done
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      )
    );
  }
}
export default connect(null, null)(ViewRemovalReasonModal);
