import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from '../../../../common-components/modal/ActionModal';
import { Paragraph } from '../../../../common-components/typography';
import { GhostButton, PrimaryButton } from '../../../../common-components/buttons';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../stores/rematch/root-store';
import { TransportAttendanceType } from '../../../../utilities/enum-utils';

interface IApproveTransportBookingModalProps {
  isOpen: boolean;
  onClose: () => void;
  transportBooking: any;
  doApproveTransportBooking: typeof dispatch.groupBookingsStore.doApproveTransportBooking;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  attendanceType: TransportAttendanceType;
}

interface IApproveTransportBookingModalState {
  step: number;
  title: string;
  canManuallyClose: boolean;
}

class ApproveTransportBookingModal extends Component<
  IApproveTransportBookingModalProps,
  IApproveTransportBookingModalState
> {
  state = {
    step: 1,
    title: 'Approve customer booking',
    canManuallyClose: true,
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: 'Approve customer booking' }, onClose);
  };

  onSubmit = async () => {
    const { transportBooking, selectedGroupBookingItem, attendanceType } = this.props;
    try {
      this.setState({ canManuallyClose: false });
      await this.props.doApproveTransportBooking({
        bookingId: selectedGroupBookingItem.bookingId,
        transportBookingId: transportBooking.attendanceId,
        attendanceType,
      });
      this.setState({ step: 2, canManuallyClose: true, title: 'Booking approved' });
    } catch (e) {
      this.setState({ canManuallyClose: true });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  renderView = () => {
    const { step } = this.state;
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>Are you sure you want to approve this customers bookings?</Paragraph>
          <Paragraph>
            This will send all line items for this customer to payments where they can be processed.
          </Paragraph>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this.onSubmit}>
              Approve
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>You have successfully approved customers booking.</Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doApproveTransportBooking: dispatch.groupBookingsStore.doApproveTransportBooking,
});

export default connect(mapState, mapDispatch)(ApproveTransportBookingModal);
