import { Tooltip2 } from '@blueprintjs/popover2';
import { Empty, Input, Skeleton, Tabs } from 'antd';
import { IconButton } from 'common-components/buttons';
import { FilterSection } from 'common-components/filter';
import { ItemCountSheet } from 'common-components/Sheets/ItemCountSheet';
import { SubTitle, Text, Title } from 'common-components/typography';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { FilterType, PaymentSources } from 'utilities/enum-utils';
import DeleteBillingLineItemModel from 'views/billings/payments/components/DeleteBillingLineItemModel';
import PayWaivedCustomerRow from 'views/billings/payments/components/details/PayWaivedCustomerRow';
import UnwaiveLineItemActionModel from 'views/billings/payments/components/UnwaiveLineItemActionModel';
import ViewWaivedReasonActionModel from 'views/billings/payments/components/ViewWaivedReasonActionModel';
import { formatFilterQuery, deselectedBillingItems } from './utils';
import { IFilter } from 'interfaces/filter-interfaces';

const { Search } = Input;

type ListPanelProps = {
  paymentsList?: typeof state.billingsStore.paymentsList;
  paymentsFilter?: typeof state.billingsStore.paymentsFilter;
  paymentsSelectedAll?: typeof state.billingsStore.paymentsSelectedAll;
  selectedBillingLineItem?: typeof state.billingsStore.selectedBillingLineItem;
  currentFilterConfig?: typeof state.billingsStore.paymentsFilter;

  history?: History;
  isServiceProviderVCPEnabled: boolean;
  doFetchWaivedPayments: typeof dispatch.billingsStore.doFetchWaivedPayments;
  setPayments?: typeof dispatch.billingsStore.setPayments;
  setPaymentsFilter?: typeof dispatch.billingsStore.setBillingLineItemsFilter;
  setSelectedBillingLineItem?: typeof dispatch.billingsStore.setSelectedBillingLineItem;
  setPaymentsSelectedAll?: typeof dispatch.billingsStore.setPaymentsSelectedAll;
  portalUser: typeof state.authStore.portalUser;
};

type ListPanelState = {
  topHeight: number;
  showFilters: boolean;
  showActionSheet: boolean;
  checkAllIndicator: boolean;
  indeterminateCheck: boolean;
  isLoading: boolean;
  isSearching: boolean;
  searchString: string;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  action: string;
  openAction: boolean;
  openEditAction: boolean;
  filters: IFilter[];
  paymentSourceType: string;
  availableFilters: FilterType[];
};

const PaymentEmptyState = () => (
  <div className='flex-1 bg-white align-center flex-column'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No Payments found.
    </Text>{' '}
    <br /> <br />
    <Text color='secondary'>All payments under this filter will appear here.</Text>
    <Text color='secondary'>Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

class PayListWaivedSection extends Component<ListPanelProps, ListPanelState> {
  state = {
    topHeight: 0,
    showFilters: false,
    showActionSheet: false,
    checkAllIndicator: false,
    indeterminateCheck: false,
    isLoading: false,
    isSearching: false,
    searchString: '',
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    action: '',
    openAction: false,
    openEditAction: false,
    filters: [],
    paymentSourceType: PaymentSources.NDIS,
    availableFilters: [
      FilterType.DATE_RANGE,
      FilterType.CUSTOMER,
      FilterType.PAYMENT_METHODS,
      FilterType.SERVICE,
      FilterType.SERVICE_TYPE,
      FilterType.NDIS_CATEGORIES,
      FilterType.USER_LOCATION_BY_STATE,
      FilterType.PAYMENT_WARNING,
    ],
  };

  getActionModal = () => {
    if (this.state.action === 'UNWAIVE') {
      return UnwaiveLineItemActionModel;
    } else if (this.state.action === 'DELETE') {
      return DeleteBillingLineItemModel;
    } else if (this.state.action === 'VIEW') {
      return ViewWaivedReasonActionModel;
    } else {
      return function EmptyModal() {
        return <></>;
      };
    }
  };

  private _closeActionModal = () => this.setState({ openAction: false });

  private _onActionDeselect = () => {
    const { paymentsList, setPayments } = this.props;

    const newSelectedBookings = deselectedBillingItems(paymentsList);

    this.setState({ showActionSheet: false, indeterminateCheck: false, checkAllIndicator: false });

    setPayments(newSelectedBookings);
  };

  private _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  private _refreshListings = async () => {
    const { doFetchWaivedPayments, paymentsFilter, setPayments } = this.props;
    this.setState({ isLoading: true, checkAllIndicator: false, indeterminateCheck: false, showActionSheet: false });
    this.setState({ isLoading: false, pageTimestamp: new Date(), page: 1 });

    setPayments([]);
    await doFetchWaivedPayments({
      ...paymentsFilter,
      paymentSourceType: this.state.paymentSourceType,
    });
    this.setState({ isLoading: false });
  };

  private _changeTab = (e) => {
    this.props.setPaymentsFilter([]);
    this.setState(
      {
        paymentSourceType: e,
        availableFilters:
          e === PaymentSources.NDIS
            ? [
                FilterType.DATE_RANGE,
                FilterType.CUSTOMER,
                FilterType.PAYMENT_METHODS,
                FilterType.SERVICE,
                FilterType.SERVICE_TYPE,
                FilterType.NDIS_CATEGORIES,
                FilterType.USER_LOCATION_BY_STATE,
                FilterType.PAYMENT_WARNING,
              ]
            : [FilterType.DATE_RANGE, FilterType.CUSTOMER],
      },
      () => this._refreshListings(),
    );
  };

  private _applySearchFilter = (searchStr) => {
    const { paymentsFilter, setPaymentsFilter } = this.props;
    const newPlanPaymentsFilter = _.clone(paymentsFilter);
    const existingSearchIndex = _.findIndex(
      newPlanPaymentsFilter,
      (filter: typeof state.billingsStore.paymentsFilter) => filter.filter === 'search',
    );

    if (existingSearchIndex > -1) {
      if (searchStr === '') {
        newPlanPaymentsFilter.splice(existingSearchIndex, 1);
      } else {
        newPlanPaymentsFilter[existingSearchIndex].values = searchStr;
      }
    } else {
      newPlanPaymentsFilter.push({ filter: 'search', values: searchStr });
    }
    setPaymentsFilter(newPlanPaymentsFilter);
    this.setState({ isSearching: false });
  };

  private _onChangeFilter = (filters: IFilter[]) => {
    this.props.setPaymentsFilter(filters);
  };

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    this._debounceSearch(e.target.value);
  };

  private _openActionModal = async ({ action, additionalData }) => {
    const { paymentsList, setSelectedBillingLineItem } = this.props;
    if (
      action === 'REJECT_LINEITEM' ||
      action === 'EDIT' ||
      action === 'WAIVE' ||
      action === 'UNWAIVE' ||
      action === 'VIEW' ||
      action === 'DELETE'
    ) {
      setSelectedBillingLineItem(additionalData);
    } else if (action === 'PROCESS_ALL') {
      const newSelectedBillingLineItem = [];
      paymentsList.map((payment) =>
        _.map(payment.billingItems, (lineItem) => {
          if (!lineItem.isError) newSelectedBillingLineItem.push(lineItem);
        }),
      );
      await setSelectedBillingLineItem(newSelectedBillingLineItem);
    } else if (action === 'PROCESS') {
      const newSelectedBillingLineItem = [];
      _.map(paymentsList, (payment) =>
        _.map(payment.billingItems, (lineItem) => {
          if (lineItem.selected) newSelectedBillingLineItem.push(lineItem);
        }),
      );
      await setSelectedBillingLineItem(newSelectedBillingLineItem);
    }
    this.setState({ action, openAction: true });
  };

  // ref element for the header; used to determine the height of the header
  _headerElement = null;

  private _searchText = (txt) => {
    this._applySearchFilter(txt);
    this.setState({ isSearching: false, searchString: txt });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  //region Component Lifecycle Methods
  componentDidMount = async () => {
    this._handleHeaderHeight();
    const { doFetchWaivedPayments, currentFilterConfig, paymentsFilter } = this.props;

    const appliedFilters = _.isEmpty(paymentsFilter) ? currentFilterConfig.filters : paymentsFilter;

    this.setState({ isLoading: true });
    await doFetchWaivedPayments({
      ...formatFilterQuery(appliedFilters),
      paymentSourceType: this.state.paymentSourceType,
    });
    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { currentFilterConfig, setPaymentsFilter, doFetchWaivedPayments } = this.props;

    if (prevState.showFilters !== this.state.showFilters) {
      this._handleHeaderHeight();
    }

    if (prevProps.currentFilterConfig.key !== currentFilterConfig.key) {
      this.setState({
        isLoading: true,
        searchString: '',
        checkAllIndicator: false,
        indeterminateCheck: false,
        showActionSheet: false,
        showFilters: false,
      });
      await setPaymentsFilter(currentFilterConfig.filters);
    }

    if (prevProps.paymentsFilter !== this.props.paymentsFilter) {
      this.setState({ isLoading: true, page: 1 });
      await doFetchWaivedPayments({
        ...formatFilterQuery(this.props.paymentsFilter),
        paymentSourceType: this.state.paymentSourceType,
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      });
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = () => {
    const { setPaymentsFilter } = this.props;
    setPaymentsFilter([]);
  };

  render() {
    const { paymentsList, selectedBillingLineItem, currentFilterConfig, paymentsFilter, portalUser, history } =
      this.props;

    const TargetActionModal = this.getActionModal();

    return (
      <div className='bg-white flex-1 width-full flex-column' style={{ position: 'relative' }}>
        <TargetActionModal
          isOpen={this.state.openAction}
          onClose={this._closeActionModal}
          deselect={this._onActionDeselect}
          selectedBillingLineItem={selectedBillingLineItem}
        />

        {/* Do NOT remove this container div. It's required for Safari sticky to work. Why it works, I have no idea.*/}
        <div>
          <div className='booking-header' ref={(com) => (this._headerElement = com)}>
            {/* Header */}
            <div className='flex-row justify-between align-center'>
              <div>
                <Title level={3} className='mv-none' lineHeight={150}>
                  {currentFilterConfig.title}
                </Title>
                <Text color='secondary'>{currentFilterConfig.description}</Text>
              </div>
              <div className='flex-column align-center'>
                <div className='pr-x-small'>
                  <Tooltip2 content='Refresh this view' position='top'>
                    <IconButton
                      color='white'
                      onClick={this._refreshListings}
                      iconColor='black'
                      size='large'
                      icon='reload'
                    />
                  </Tooltip2>
                </div>
              </div>
            </div>

            <Tabs defaultActiveKey={'ALL'} animated={true} onChange={this._changeTab} className={'mt-large'}>
              <Tabs.TabPane tab={PaymentSources.NDIS} key={PaymentSources.NDIS} />
              {this.props.isServiceProviderVCPEnabled && (
                <Tabs.TabPane tab={PaymentSources.VCP} key={PaymentSources.VCP} />
              )}
            </Tabs>

            <div className='flex-row pb-medium align-center justify-between'>
              <div className='mr-x2-large' style={{ minWidth: '300px' }}>
                <Search
                  onChange={this._onEnterSearchText}
                  loading={this.state.isSearching}
                  placeholder='First or last name'
                />
              </div>
              <FilterSection
                availableFilters={this.state.availableFilters}
                filters={paymentsFilter ? paymentsFilter : []}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={portalUser.timezone}
                containerClassName={'mv-small'}
              />
            </div>
          </div>

          <table className='booking-listing'>
            <thead>
              <tr>
                <th className='nowrap check-all' style={{ top: `${this.state.topHeight}px` }} />
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap'>
                  <SubTitle>Customer</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap'>
                  <SubTitle>Line Items (Bookings)</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap text-align-right'>
                  <SubTitle containerClassName='text-align-right'>Total</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap' />
              </tr>
            </thead>

            <tbody>
              {this.state.isLoading && (
                <tr style={{ borderBottom: '0px solid !important' }}>
                  <td colSpan={7}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
                  </td>
                </tr>
              )}

              {!this.state.isLoading && _.isEmpty(paymentsList) && (
                <tr style={{ cursor: 'default' }}>
                  <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                    <PaymentEmptyState />
                  </td>
                </tr>
              )}
              {!this.state.isLoading &&
                _.map(paymentsList, (paymentItem) => (
                  <PayWaivedCustomerRow
                    paymentItem={paymentItem}
                    history={history}
                    key={paymentItem.paymentId}
                    openActionModal={this._openActionModal}
                  />
                ))}
            </tbody>
          </table>
        </div>

        {/* Filler */}
        <div className='flex-1 bg-white'>&nbsp;</div>

        {!this.state.isLoading && !this.state.showActionSheet && paymentsList.length > 0 && (
          <ItemCountSheet itemCount={paymentsList.length} itemLabel='Customer' />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedBillingLineItem: dispatch.billingsStore.setSelectedBillingLineItem,
  setPayments: dispatch.billingsStore.setPayments,
  doFetchWaivedPayments: dispatch.billingsStore.doFetchWaivedPayments,
});

export default connect(mapState, mapDispatch)(PayListWaivedSection);
