import { Icon, Steps } from 'antd';
import { Text } from 'common-components/typography';
import { IWorkflow } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';
import { getActiveStep } from 'views/workflows/utils/workflow-utils';
interface IWorkflowDetailsStepsProps {
  workflowDetail: IWorkflow;
}

const { Step } = Steps;

class WorkflowDetailsSteps extends PureComponent<IWorkflowDetailsStepsProps> {
  render() {
    const { workflowDetail } = this.props;

    const activeStep = workflowDetail && getActiveStep(workflowDetail.steps);

    if (!workflowDetail.steps.length) {
      return <></>;
    }

    return (
      <Steps
        current={activeStep ? activeStep.stepNumber : undefined}
        size="small"
        className="mt-medium bg-quaternary p-medium rounded-big"
        style={{ overflow: 'auto', maxWidth: '100%' }}
      >
        {workflowDetail.steps.map((step, index) => (
          <Step
            style={{ minWidth: '250px', maxWidth: '350px', opacity: step.status === 'PENDING' ? 0.8 : undefined }}
            key={step.stepNumber}
            status={step.status === 'ACTIVE' ? 'process' : step.status === 'APPROVED' ? 'finish' : 'wait'}
            icon={
              <Icon
                type="check-circle"
                className={
                  step.status === 'ACTIVE'
                    ? 'text-color-blue-dark'
                    : step.status === 'PENDING'
                    ? 'text-color-tertiary'
                    : 'text-color-tertiary'
                }
                theme="filled"
              />
            }
            title={
              <Text
                weight="bolder"
                color={step.status === 'ACTIVE' ? 'blue-dark' : step.status === 'PENDING' ? 'tertiary' : undefined}
              >
                Step {index + 1}
              </Text>
            }
            description={
              <Text color={step.status === 'ACTIVE' ? 'blue-dark' : step.status === 'PENDING' ? 'tertiary' : undefined}>
                {step.name}
              </Text>
            }
          />
        ))}
      </Steps>
    );
  }
}

export default WorkflowDetailsSteps;
