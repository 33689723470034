import { createStitches } from '@stitches/react';

import {
  borderRadiusValues,
  borderStyleValues,
  borderWidthValues,
  colorValues,
  fontFamilyValues,
  fontSizeValues,
  fontWeightValues,
  lineHeightValues,
  outlineStyleValues,
  outlineWidthValues,
  shadowValues,
  sizeValues,
  spaceValues,
  textColorValues,
  transitionValues,
} from './tokens';

import type * as Stitches from '@stitches/react';

/********************************************************************
 * Theme
 *******************************************************************/

const { config, css, theme } = createStitches({
  media: {
    initial: '(min-width: 0px)',
    xsmall: '(min-width: 390px)',
    small: '(min-width: 640px)',
    medium: '(min-width: 768px)',
    large: '(min-width: 1024px)',
    xlarge: '(min-width: 1280px)',
    xxlarge: '(min-width: 1440px)',
    xxxlarge: '(min-width: 1600px)',
  },
  theme: {
    borderStyles: borderStyleValues,
    borderWidths: borderWidthValues,
    outlineStyles: outlineStyleValues,
    outlineWidths: outlineWidthValues,
    colors: colorValues,
    fontSizes: fontSizeValues,
    fontWeights: fontWeightValues,
    fonts: fontFamilyValues,
    lineHeights: lineHeightValues,
    radii: borderRadiusValues,
    shadows: shadowValues,
    sizes: sizeValues,
    space: spaceValues,
    transitions: transitionValues,
  },
  utils: {
    marginY: (value: Stitches.ScaleValue<'space'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
    marginX: (value: Stitches.ScaleValue<'space'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
    paddingY: (value: Stitches.ScaleValue<'space'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    paddingX: (value: Stitches.ScaleValue<'space'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
  },
});

export { config, css, theme };

/********************************************************************
 * Keys
 *******************************************************************/

// Background
const backgroundKeys = {
  backgroundColor: 'backgroundColor',
};

// Outline
const outlineKeys = {
  outlineStyle: 'outlineStyle',
  outlineColor: 'outlineColor',
  outlineOffset: 'outlineOffset',
  outlineWidth: 'outlineWidth',
};

// Border
const borderKeys = {
  borderStyle: 'borderStyle',
  borderLeftStyle: 'borderLeftStyle',
  borderTopStyle: 'borderTopStyle',
  borderRightStyle: 'borderRightStyle',
  borderBottomStyle: 'borderBottomStyle',
  borderWidth: 'borderWidth',
  borderLeftWidth: 'borderLeftWidth',
  borderRightWidth: 'borderRightWidth',
  borderTopWidth: 'borderTopWidth',
  borderBottomWidth: 'borderBottomWidth',
  borderColor: 'borderColor',
  borderLeftColor: 'borderLeftColor',
  borderRightColor: 'borderRightColor',
  borderTopColor: 'borderTopColor',
  borderBottomColor: 'borderBottomColor',
  borderRadius: 'borderRadius',
  borderTopLeftRadius: 'borderTopLeftRadius',
  borderTopRightRadius: 'borderTopRightRadius',
  borderBottomLeftRadius: 'borderBottomLeftRadius',
  borderBottomRightRadius: 'borderBottomRightRadius',
};

const shadowKeys = {
  boxShadow: 'boxShadow',
};

// Color
const colorKeys = {
  color: 'color',
};

// Cursor
const cursorKeys = {
  cursor: 'cursor',
};

// Layout
const layoutKeys = {
  alignSelf: 'alignSelf',
  flex: 'flex',
  flexBasis: 'flexBasis',
  flexGrow: 'flexGrow',
  flexShrink: 'flexShrink',
  gridArea: 'gridArea',
  gridColumn: 'gridColumn',
  gridColumnEnd: 'gridColumnEnd',
  gridColumnStart: 'gridColumnStart',
  gridRow: 'gridRow',
  gridRowEnd: 'gridRowEnd',
  gridRowStart: 'gridRowStart',
  justifySelf: 'justifySelf',
  order: 'order',
  overflow: 'overflow',
  transform: 'transform',
};

// Flex
const flexKeys = {
  display: 'display',
  justifyContent: 'justifyContent',
  alignItems: 'alignItems',
  flexDirection: 'flexDirection',
};

// Position
const positionKeys = {
  position: 'position',
  top: 'top',
  bottom: 'bottom',
  left: 'left',
  right: 'right',
  start: 'start',
  end: 'end',
  zIndex: 'zIndex',
};

// Size
const sizeKeys = {
  height: 'height',
  maxHeight: 'maxHeight',
  maxWidth: 'maxWidth',
  minHeight: 'minHeight',
  minWidth: 'minWidth',
  width: 'width',
};

// Space
const gapKeys = {
  gap: 'gap',
};

const marginKeys = {
  margin: 'margin',
  marginBottom: 'marginBottom',
  marginLeft: 'marginLeft',
  marginRight: 'marginRight',
  marginTop: 'marginTop',
  marginY: 'marginY',
  marginX: 'marginX',
};

// Padding
const paddingKeys = {
  padding: 'padding',
  paddingBottom: 'paddingBottom',
  paddingLeft: 'paddingLeft',
  paddingRight: 'paddingRight',
  paddingTop: 'paddingTop',
  paddingY: 'paddingY',
  paddingX: 'paddingX',
};

const textKeys = {
  fontFamily: 'fontFamily',
  fontSize: 'fontSize',
  fontWeight: 'fontWeight',
  textDecoration: 'textDecoration',
  textDecorationColor: 'textDecorationColor',
  lineHeight: 'lineHeight',
  whiteSpace: 'whiteSpace',
};

const iconKeys = {
  width: 'width',
  height: 'height',
};

/********************************************************************
 * Types
 *******************************************************************/

export type CSS = Stitches.CSS<typeof config>;
export type Media = keyof typeof config.media;

/**
 * @example
 * {
 *   margin: "$size100",
 *   padding: {
 *     "@initial": "$size100",
 *     "@medium": "$size200"
 *   }
 * }
 */
type StyleProps<T> = {
  [CSSProperty in keyof T]?:
    | {
        [MediaProperty in Media]?: T[CSSProperty];
      }
    | T[CSSProperty];
};

// Background
type BackgroundCSS = Pick<CSS, 'backgroundColor'>;
// Border
type BorderCSS = Pick<CSS, keyof typeof borderKeys>;

// Outline
type OutlineCSS = Pick<CSS, keyof typeof outlineKeys>;

// Color
type TextColorCSS = { color?: keyof typeof textColorValues };
type ColorCSS = Pick<CSS, keyof typeof colorKeys>;
type CursorCSS = Pick<CSS, keyof typeof cursorKeys>;

type ShadowCSS = Pick<CSS, keyof typeof shadowKeys>;

// Layout
type LayoutCSS = Pick<CSS, keyof typeof layoutKeys>;
// Flex
type FlexCSS = Pick<CSS, keyof typeof flexKeys>;
// Position
type PositionCSS = Pick<CSS, keyof typeof positionKeys>;
// Size
type SizeCSS = Pick<CSS, keyof typeof sizeKeys>;
// Space
type GapCSS = Pick<CSS, keyof typeof gapKeys>;
type MarginCSS = Pick<CSS, keyof typeof marginKeys>;
type PaddingCSS = Pick<CSS, keyof typeof paddingKeys>;
// Icon
type IconCSS = Pick<CSS, keyof typeof iconKeys>;
// Text
type TextCSS = Pick<CSS, keyof typeof textKeys>;

// Handlers
type CommonStyleProps = GapCSS & LayoutCSS & MarginCSS & PositionCSS & SizeCSS & CursorCSS;
export type BaseStyleProps = StyleProps<CommonStyleProps>;

export type BoxStyleProps = StyleProps<CommonStyleProps & BackgroundCSS & BorderCSS & PaddingCSS & FlexCSS & ShadowCSS>;

export type ButtonStyleProps = StyleProps<CommonStyleProps & ColorCSS & BorderCSS & PaddingCSS & BackgroundCSS>;

export type TextStyleProps = StyleProps<CommonStyleProps & BackgroundCSS & TextColorCSS & TextCSS & FlexCSS>;

export type IconStyleProps = StyleProps<CommonStyleProps & IconCSS>;

export type InputStylesProps = StyleProps<BoxStyleProps & OutlineCSS>;

/********************************************************************
 * Handlers
 *******************************************************************/

export const baseStyleProps = {
  ...gapKeys,
  ...layoutKeys,
  ...marginKeys,
  ...positionKeys,
  ...sizeKeys,
};

export const boxStyleProps = {
  ...baseStyleProps,
  ...backgroundKeys,
  ...borderKeys,
  ...flexKeys,
  ...paddingKeys,
  ...shadowKeys,
};

export const textStyleProps = {
  ...backgroundKeys,
  ...baseStyleProps,
  ...colorKeys,
  ...textKeys,
  ...flexKeys,
};

export const buttonStyleProps = {
  ...baseStyleProps,
  ...paddingKeys,
  ...colorKeys,
  ...backgroundKeys,
  ...borderKeys,
};

export const iconStyleProps = {
  ...baseStyleProps,
  ...colorKeys,
  ...borderKeys,
  ...iconKeys,
};

export const inputStyleProps = {
  ...boxStyleProps,
  ...outlineKeys,
};
