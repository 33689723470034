import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import TimeInput from 'common-components/time-input/TimeInput';
import { Text } from 'common-components/typography';
import { EditRatiosSchema } from './schema';

type ITimeColumnProps = {
  index: number;
};

export function TimeColumn({ index }: ITimeColumnProps) {
  const form = useFormContext<EditRatiosSchema>();

  return (
    <>
      <div>
        <Controller
          name={`ratios.${index}.customRatio.startDateTime`}
          control={form.control}
          render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
            <div id={`startDateTime.${index}`}>
              <TimeInput
                size="large"
                value={value}
                onChange={onChange}
                className={error && 'border-red-dark'}
                displayIcon={false}
                containerStyle={{
                  width: 115,
                  height: 40,
                  display: 'block',
                }}
                ref={ref}
              />
            </div>
          )}
        />
      </div>
      <div>
        <Text>to</Text>
      </div>
      <div>
        <Controller
          name={`ratios.${index}.customRatio.endDateTime`}
          control={form.control}
          render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
            <div id={`endDateTime.${index}`}>
              <TimeInput
                size="large"
                onChange={onChange}
                value={value}
                className={error && 'border-red-dark'}
                displayIcon={false}
                containerStyle={{
                  width: 115,
                  height: 40,
                  display: 'block',
                }}
                ref={ref}
              />
            </div>
          )}
        />
      </div>
    </>
  );
}
