import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ClockSlash = forwardRef(function ClockSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <g clipPath="url(#clock-slash_svg__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.47 2.53a.75.75 0 1 1 1.06-1.06l3 3a.75.75 0 1 1-1.06 1.06l-3-3ZM3.53 2.47a.75.75 0 0 0-1.06 0l-1.5 1.5a.75.75 0 0 0 1.06 1.06L3 4.06l1.596 1.596A9.714 9.714 0 0 0 2.25 12c0 5.385 4.365 9.75 9.75 9.75a9.714 9.714 0 0 0 6.344-2.346l2.126 2.126a.75.75 0 0 0 1.06-1.06l-2.636-2.636L6.166 5.106 3.53 2.47ZM3.75 12c0-2.008.717-3.848 1.91-5.28l11.62 11.62A8.25 8.25 0 0 1 3.75 12Zm4.584-7.393a8.25 8.25 0 0 1 11.06 11.059.75.75 0 1 0 1.342.668A9.713 9.713 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75a9.713 9.713 0 0 0-4.334 1.014.75.75 0 1 0 .668 1.343Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clock-slash_svg__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
});
