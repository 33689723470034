import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ToggleOn = forwardRef(function ToggleOn(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.25a8.75 8.75 0 0 0 0 17.5h4a8.75 8.75 0 1 0 0-17.5h-4ZM2.75 12A7.25 7.25 0 0 1 10 4.75h4a7.25 7.25 0 1 1 0 14.5h-4A7.25 7.25 0 0 1 2.75 12Zm8 0a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0ZM14 7.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
