import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Call = forwardRef(function Call(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.462 2.25h.125c.332 0 .59 0 .83.042A2.75 2.75 0 0 1 8.45 3.88c.099.22.161.472.242.794l.014.058.414 1.655.009.036c.14.56.259 1.036.316 1.439.06.428.063.838-.074 1.259-.137.42-.38.75-.682 1.061-.283.292-.659.607-1.102.977l-.029.024-1.612 1.348a13.897 13.897 0 0 0 5.523 5.523l1.348-1.612.024-.029c.37-.443.685-.819.977-1.102.31-.302.64-.545 1.061-.682.42-.137.83-.135 1.26-.074.402.057.878.176 1.438.316l.036.01 1.655.413.058.014c.322.08.573.143.794.242a2.75 2.75 0 0 1 1.588 2.034c.042.238.042.497.042.829v.125c0 .383 0 .686-.066.983-.242 1.084-1.246 1.995-2.348 2.13-.302.038-.562.012-.885-.02l-.047-.004c-2.64-.258-5.015-.917-7.085-1.957-3.074-1.544-5.445-3.915-6.99-6.99-1.039-2.068-1.698-4.444-1.956-7.084L2.37 5.55c-.032-.324-.058-.584-.02-.886.135-1.102 1.046-2.106 2.13-2.348.297-.066.6-.066.983-.066Zm7.396 16.482c1.68.72 3.582 1.196 5.691 1.402.387.038.483.044.604.029.473-.059.963-.504 1.067-.968.026-.117.03-.244.03-.722 0-.42-.003-.537-.019-.629a1.25 1.25 0 0 0-.722-.924c-.085-.038-.197-.069-.605-.17l-1.654-.415c-.607-.151-1.006-.25-1.322-.295-.3-.042-.458-.025-.584.016-.126.04-.265.12-.481.331-.23.223-.494.537-.895 1.017l-1.11 1.328Zm-7.59-7.59 1.328-1.11c.48-.4.795-.665 1.017-.894.21-.217.29-.356.331-.482.041-.126.058-.285.016-.584-.045-.316-.144-.715-.295-1.321L7.25 5.096c-.102-.408-.133-.52-.171-.605a1.25 1.25 0 0 0-.924-.722c-.092-.016-.208-.019-.629-.019-.478 0-.605.004-.721.03-.465.104-.91.595-.969 1.067-.014.121-.009.217.03.604.205 2.11.681 4.01 1.401 5.691Z"
        fill="currentColor"
      />
    </Icon>
  );
});
