import { Col, notification, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import fileDownload from 'js-file-download';
import { Parser } from 'json2csv/dist/json2csv.umd';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { RematchDispatcherAsync } from '@rematch/core';

type ExportDataModalProps = {
  onClose: () => void;
  doFetchData:
    | RematchDispatcherAsync<{ startDate: string | Date; endDate: string | Date }>
    | RematchDispatcherAsync<{ startDate: string | Date; endDate: string | Date }, void, object[]>;
  exportName: string;
  csvName: string;
  additionalDataFields?: { label: string; value: string }[];
};

export const ExportDataModal = ({
  onClose,
  doFetchData,
  exportName,
  csvName,
  additionalDataFields,
}: ExportDataModalProps) => {
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [exportStartDate, setExportStartDate] = useState<Date>(moment().add(-3, 'months').toDate()); // Changed from 6 months to 3 months
  const [exportEndDate, setExportEndDate] = useState<Date>(moment().toDate());
  const [maxEndDate, setMaxEndDate] = useState<Date>(moment().toDate()); // Added maxEndDate state

  const dateRangeChanged = (date: Date) => {
    setExportStartDate(date);
    
    if (exportName !== 'customer') {
      setExportEndDate(moment(date).add(3, 'months').toDate());
      setMaxEndDate(moment(date).add(3, 'months').toDate());
    }
  };

  const onCloseModal = () => {
    setIsExporting(false);
    onClose();
  };

  const onClickExportData = async () => {
    setIsExporting(true);
    try {
      const resp = await doFetchData({
        startDate: moment(exportStartDate).startOf('day').toDate(),
        endDate: moment(exportEndDate).endOf('day').toDate(),
      });

      const parser = new Parser(additionalDataFields.length > 0 ? { fields: additionalDataFields } : {});

      const csvData = await parser.parse(resp);
      // Generate the csv
      fileDownload(csvData, `Export${csvName}${moment().format('YYYYMMDDHHmm')}.csv`);
    } catch {
      notification.error({
        message: `Error while generating the export ${exportName} file`,
        description: `The file generation failed, please try again.`,
      });
    }
    setIsExporting(false);
  };

  return (
    <ActionModal title={`Export ${exportName} `} isOpen onClose={onCloseModal} width='large'>
      <div className='mv-medium anim-slide-left'>
        <Text>
          You are about to export all {exportName} data as a CSV. This file will be available to you as a direct
          download.
        </Text>
        <div className='mt-x-large mb-x-large'>
          <Row type='flex' align='middle' gutter={24}>
            <Col span={12}>
              <div>
                <SubTitle>Start Date</SubTitle>
                <DatePicker
                  className='gh-datepicker-plan-management rounded '
                  calendarClassName='gh-datepicker-calendar'
                  dateFormat='dd/MM/yyyy'
                  onChange={(date: Date) => dateRangeChanged(date)}
                  placeholderText='Input start date'
                  selected={exportStartDate}
                  disabled={isExporting}
                />
              </div>
            </Col>
            <Col span={12}>
              <div>
                <SubTitle>End Date</SubTitle>
                <DatePicker
                  className='gh-datepicker-plan-management rounded '
                  calendarClassName='gh-datepicker-calendar'
                  dateFormat='dd/MM/yyyy'
                  onChange={(date: Date) => setExportEndDate(date)}
                  placeholderText='Input end date'
                  selected={exportEndDate}
                  disabled={isExporting}
                  maxDate={maxEndDate} // Added maxDate prop
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ActionModalFooter>
        <SecondaryButton size='large' className='mr-medium' onClick={onCloseModal} disabled={isExporting}>
          Cancel
        </SecondaryButton>
        <PrimaryButton size='large' onClick={onClickExportData} disabled={isExporting} loading={isExporting}>
          Export
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};
