import React, { Component } from 'react';
import { Checkbox, Col, Form, Icon, InputNumber, Row, Select, Switch } from 'antd';
import _ from 'lodash';
import { Paragraph, Text, Title, SubTitle } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, state } from 'stores/rematch/root-store';
import { FormComponentProps } from 'antd/es/form';
import PermissionUtils from 'utilities/permission-utils';
import { PriceFrenquency, ServiceType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';

const { Option } = Select;

interface IPaymentMethodStepPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
}

interface IPaymentMethodStepPanelState {
  isOpen: boolean;
  isNDISEnabled: boolean;
  isVCPEnabled: boolean;
  showPaymentMethodError: boolean;
  isServiceProviderVCPEnabled: boolean;
}

class PaymentMethodStepPanel extends Component<IPaymentMethodStepPanelProps, IPaymentMethodStepPanelState> {
  state = {
    isOpen: false,
    isNDISEnabled: false,
    isVCPEnabled: false,
    showPaymentMethodError: false,
    isServiceProviderVCPEnabled: false,
  };

  private _onNextStep = async () => {
    const { setNewService, onNextStep, newService, form } = this.props;
    const { isVCPEnabled, isNDISEnabled } = this.state;

    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      const paymentSources = [];
      if (isVCPEnabled) {
        paymentSources.push('VCP');
      }
      if (isNDISEnabled) {
        paymentSources.push('NDIS');
      }

      if (!_.isEmpty(paymentSources)) {
        await setNewService({
          ...newService,
          paymentSources,
          currency: 'AUD',
        });
        onNextStep();
      } else {
        this.setState({ showPaymentMethodError: true });
      }
    }
  };

  async componentDidMount() {
    const { newService } = this.props;
    const hasNDIS = _.find(newService.paymentSources, (source) => source === 'NDIS');
    const hasVCP = _.find(newService.paymentSources, (source) => source === 'VCP');
    if (hasNDIS) this.setState({ isNDISEnabled: true });
    if (hasVCP) this.setState({ isVCPEnabled: true });

    const isVCPEnabled = await PermissionUtils.getIsServiceProviderVCPEnabled();
    if (isVCPEnabled) {
      this.setState({ isServiceProviderVCPEnabled: true });
    } else {
      this.setState({ isServiceProviderVCPEnabled: false });
    }
  }

  render() {
    const { onPreviousStep } = this.props;
    const { isVCPEnabled, isNDISEnabled } = this.state;

    return (
      <div className="anim-slide-left mb-x-large">
        <Title level={2} weight="bolder" className="line-height-100">
          Payment method
        </Title>

        <Row className="mb-large">
          <Paragraph>What payment methods are eligible to be claimed for this service</Paragraph>
          <div>
            <Switch
              className={isNDISEnabled ? 'bg-green-light' : null}
              checked={isNDISEnabled}
              onChange={(value) => this.setState({ isNDISEnabled: value, showPaymentMethodError: false })}
            />
            <Text className="ml-medium">National Disability Insurance Scheme (NDIS)</Text>
          </div>
        </Row>

        {this.state.isServiceProviderVCPEnabled && (
          <div>
            <Switch
              className={isVCPEnabled ? 'bg-green-light' : null}
              checked={isVCPEnabled}
              onChange={(value) => this.setState({ isVCPEnabled: value, showPaymentMethodError: false })}
            />
            <Text className="ml-medium">Vulnerable Children's Program (VCP)</Text>
          </div>
        )}

        {this.state.showPaymentMethodError && (
          <div className="mt-medium">
            <Text color="red">Please enable at least 1 payment method.</Text>
          </div>
        )}
        <div className="flex-row align-center mv-x2-large">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left" className="mr-large">
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this._onNextStep} icon="right" iconPosition="right">
            Next
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export default Form.create<IPaymentMethodStepPanelProps>()(PaymentMethodStepPanel);
