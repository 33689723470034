import { Popover } from '@blueprintjs/core';
import { Icon, Tag } from 'antd';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo, memo, PropsWithChildren } from 'react';
import { ISessionActivityGroupTag } from 'interfaces/session-interfaces';
import { Stack } from '../../../common-components/stack';

interface IActivityGroupsSummaryTag {
  activityGroups: ISessionActivityGroupTag[];
  timezone: string;
}

function ActivityGroupsSummaryTag(props: IActivityGroupsSummaryTag) {
  const { activityGroups, timezone } = props;
  const activityGroupsCount = activityGroups.length;

  const activityGroupPopoverContent = useMemo(() => {
    return (
      <Stack gap="lg" className="p-medium" style={{ maxHeight: '246px', overflowX: 'hidden' }}>
        <Text className="text-weight-bold">
          Assigned to {activityGroupsCount} activity group{activityGroupsCount > 1 ? 's' : ''}
        </Text>

        <Stack gap="md">
          {_.map(activityGroups, (item) => {
            return (
              <div key={item.serviceDateTimeActivityGroupId} className="whitespace-nowrap">
                <ActivityGroupTag>{item.name}</ActivityGroupTag>

                {item.startDateTime && item.endDateTime && timezone && (
                  <Text size="small">
                    {moment.tz(item.startDateTime, timezone).format('ddd, D MMM')} -{' '}
                    {moment.tz(item.endDateTime, timezone).format('ddd, D MMM')} •{' '}
                    {moment.tz(item.startDateTime, timezone).format('h:mm A')} -{' '}
                    {moment.tz(item.endDateTime, timezone).format('h:mm A')}
                  </Text>
                )}
              </div>
            );
          })}
        </Stack>
      </Stack>
    );
  }, []);

  return (
    <Popover content={activityGroupPopoverContent} position="bottom-right" interactionKind="hover">
      <ActivityGroupTag>
        {activityGroupsCount} group{activityGroupsCount > 1 ? 's' : ''}
      </ActivityGroupTag>
    </Popover>
  );
}

export default memo(ActivityGroupsSummaryTag);

type ActivityGroupTagProps = PropsWithChildren<{}>;

function ActivityGroupTag(props: ActivityGroupTagProps) {
  let { children } = props;

  return (
    <Tag
      className="tag-icon bg-tertiary flex-row align-center"
      style={{ display: 'flex', alignItems: 'center', width: 'fit-content', marginTop: 0 }}
    >
      <Icon type="calendar" style={{ fontSize: '14px' }} />
      <Text size="regular">{children}</Text>
    </Tag>
  );
}
