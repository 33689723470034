import { Avatar, Icon, Input } from 'antd';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IActivityGroupUsers } from 'interfaces/user-interfaces';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { ActivityGroupMemberType } from 'utilities/enum-utils';
const { Search } = Input;

interface IActivityGroupRemoveMemberSelectedModalProps {
  serviceId: string;
  activityGroupAddType: ActivityGroupMemberType;
  memberInActivityGroup: IActivityGroupUsers[];
  onInteractWithAddedMember: (addedMember: IActivityGroupUsers[]) => void;
  onClose: () => void;
}

interface IActivityGroupRemoveMemberSelectedModalState {
  users: IActivityGroupUsers[];
  memberAlreadyInActivityGroup: IActivityGroupUsers[];
}

class ActivityGroupRemoveMemberSelectedModal extends Component<
  IActivityGroupRemoveMemberSelectedModalProps,
  IActivityGroupRemoveMemberSelectedModalState
> {
  state = {
    users: [],
    memberAlreadyInActivityGroup: this.props.memberInActivityGroup,
  };

  private _handleRemoveSelectedMember = (selectedMemberKey: string) => {
    const { memberAlreadyInActivityGroup } = this.state;
    const filteredMemberArray = _.filter(memberAlreadyInActivityGroup, (member) => member.userId != selectedMemberKey);
    this.setState({ memberAlreadyInActivityGroup: filteredMemberArray });
    this.props.onInteractWithAddedMember(filteredMemberArray);
  };

  private _renderSelectedUsersCard = (memberAlreadyInActivityGroup: IActivityGroupUsers[], isAvatarSquare: boolean) => {
    return (
      !_.isEmpty(memberAlreadyInActivityGroup) && (
        <div className="mt-12">
          {_.map(memberAlreadyInActivityGroup, (user) => {
            return (
              <div className="flex inline-block" key={user.userId}>
                <div
                  className="flex-row ml-small mb-small bg-white rounded-big align-center ph-small bordered"
                  style={{
                    maxHeight: '40px',
                    minHeight: '40px',
                  }}
                >
                  <div>
                    <Avatar size={28} src={user.attachmentUrl} shape={isAvatarSquare ? 'square' : 'circle'} />
                    <Text weight="regular" className="ml-small">
                      {user.firstName + ' ' + user.lastName}
                    </Text>
                  </div>

                  <Icon
                    className="text-color-black cursor-pointer ml-12"
                    type="close"
                    theme="outlined"
                    // onClick={() => {
                    //     this._handleRemoveSelectedMember(user.userId);
                    // }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )
    );
  };

  // componentDidMount = async () => {

  // };

  // componentWillUnmount(): void {
  //     this.props.setGroupServiceCustomers([]);
  //     this.props.setGroupServiceWorkerList([]);
  //     this.props.setactivityGroupAddedMember([]);
  // }

  render() {
    return (
      <div>
        <div className="mt-small">
          <div className="bg-tertiary rounded p-12">
            <div className="mt-small">
              <Text color="secondary" size="large">
                {this.props.activityGroupAddType === ActivityGroupMemberType.TEAM_MEMBER
                  ? 'Team members '
                  : 'Customers '}{' '}
                ({_.get(this.state.memberAlreadyInActivityGroup, 'length', 0)})
              </Text>
              <div>
                {this._renderSelectedUsersCard(
                  this.state.memberAlreadyInActivityGroup,
                  this.props.activityGroupAddType === ActivityGroupMemberType.TEAM_MEMBER,
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceCustomers: state.customersStore.groupServiceCustomers,
  groupServiceWorkerList: state.servicesStore.groupServiceWorkerList,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetGroupServiceCustomers: dispatch.customersStore.doGetGroupServiceCustomers,
  setGroupServiceCustomers: dispatch.customersStore.setGroupServiceCustomers,
  doFetchGroupServiceTeamMembers: dispatch.servicesStore.doFetchGroupServiceTeamMembers,
  setGroupServiceWorkerList: dispatch.servicesStore.setGroupServiceWorkerList,
});

export default connect(mapState, mapDispatch)(ActivityGroupRemoveMemberSelectedModal);
