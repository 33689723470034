import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { IRootDispatch, IRootState, state, dispatch } from 'stores/rematch/root-store';
import { Paragraph } from 'common-components/typography';
import { Col, Row, notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

interface IRemoveCustomerModalProps {
  isRemoveCustomerModalOpen: boolean;
  closeRemoveCustomerModal: () => void;
  doRemoveCustomerFromGuardian: typeof dispatch.customersStore.doRemoveCustomerFromGuardian;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  tobeRemovedCustomer: any;
}

interface IRemoveCustomerModalState {}

class RemoveCustomerModal extends Component<IRemoveCustomerModalProps, IRemoveCustomerModalState> {
  private _closeModal = () => {
    this.props.closeRemoveCustomerModal();
  };

  private _onRemoveCustomer = async () => {
    try {
      const { doRemoveCustomerFromGuardian, selectedCustomer, tobeRemovedCustomer } = this.props;
      const request = {
        customerUserId: tobeRemovedCustomer.userId,
        guardianUserId: selectedCustomer.userId,
      };

      await doRemoveCustomerFromGuardian(request);

      notification.open({
        message: <span className={'text-weight-bolder'}>Customer removed</span>,
        description: (
          <span>
            You have successfully removed{' '}
            <span className={'text-weight-bolder'}>
              {tobeRemovedCustomer.firstName} {tobeRemovedCustomer.lastName}
            </span>{' '}
            as a managed customer for{' '}
            <span className={'text-weight-bolder'}>
              {selectedCustomer.firstName} {selectedCustomer.lastName}
            </span>{' '}
          </span>
        ),
      });
      this._closeModal();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  render() {
    const { isRemoveCustomerModalOpen } = this.props;
    const title = 'Remove customer';

    return (
      <div>
        <ActionModal isOpen={isRemoveCustomerModalOpen} title={title} onClose={this._closeModal}>
          <Paragraph>
            Are you sure you want to remove this customer from the legal guardians profile.
            <br /> <br />
            The selected legal guardian will no longer be able to manage this customers profile. Ensure you have
            recieved express permission from the customer before you do this.
          </Paragraph>

          <ActionModalFooter className={'mt-large'}>
            <Row type={'flex'} className={'justify-end'}>
              <Col className="mr-medium">
                <SecondaryButton size="large" onClick={this._closeModal}>
                  Cancel
                </SecondaryButton>
              </Col>
              <Col>
                <PrimaryButton onClick={this._onRemoveCustomer} size="large">
                  Remove
                </PrimaryButton>
              </Col>
            </Row>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doRemoveCustomerFromGuardian: dispatch.customersStore.doRemoveCustomerFromGuardian,
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveCustomerModal);
