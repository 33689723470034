import { Inline, Stack, Text } from '@goodhuman-me/components';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Availabilities, MemberAvailabilities } from '../../availability';
import { AvailabilityTab, AvailabilityTabGroup } from './availability-tabs';
import { Announce, Tooltip } from 'design-components';
import { useTranslation } from 'react-i18next';

type AvailabilityCycles = 'fortnightly' | 'weekly';
// at the moment this only returns 2
// but we will eventually support more week cycles so leaving here
const getNumberOfWeeksInCycle = (availabilityCycle: AvailabilityCycles): number => {
  switch (availabilityCycle) {
    case 'fortnightly':
    default:
      return 2;
  }
};

const isNoon = (time) => moment(time).hour() === 12;
const getWeekStartDate = () => moment().startOf('isoWeek').format('DD MMM YYYY');
const getNextWeekStartDate = () => moment().add(1, 'week').startOf('isoWeek').format('DD MMM YYYY');

type AvailabilityBreakdownWeeklyProps = {
  memberAvailabilities: MemberAvailabilities;
};
const AvailabilityBreakdownWeekly = ({ memberAvailabilities }: AvailabilityBreakdownWeeklyProps) => {
  const {
    availabilities,
    availabilityCycle,
    availabilityActiveWeek: activeWeekInCycle,
    availabilityTimezone,
  } = memberAvailabilities;

  const numberOfWeeksInCycle = useMemo<number>(() => getNumberOfWeeksInCycle(availabilityCycle), [availabilityCycle]);

  const [selectedActiveWeek, setSelectedActiveWeek] = useState(1);

  const onlyOneWeekCycleFound = availabilities.every((availability) => availability.weeklyCycle === 1);
  const visibleAvailabilities = availabilities
    .filter((availability) => {
      // show week cycle for all tabs
      if (onlyOneWeekCycleFound) return true;

      return availability.weeklyCycle === selectedActiveWeek;
    })
    .sort((_, b) => {
      // we want sunday (0) to appear last
      if (b.number === 0) {
        return -1;
      }

      return 0;
    });

  return (
    <Stack gap="$medium">
      <AvailabilityTabGroup>
        {[...Array(numberOfWeeksInCycle)].map((_, index) => {
          const weekNumber = index + 1;

          return (
            <AvailabilityTab
              key={`week-${weekNumber}`}
              isSelected={weekNumber === selectedActiveWeek}
              onClick={() => setSelectedActiveWeek(weekNumber)}
            >
              Week {weekNumber}
            </AvailabilityTab>
          );
        })}
      </AvailabilityTabGroup>
      <Inline justifyContent="space-between">
        <Text fontSize="$xsmall" color="$bodyDark1">
          Week starting: {activeWeekInCycle === selectedActiveWeek ? getWeekStartDate() : getNextWeekStartDate()}
        </Text>
        <DisplayTimezoneInfo timezone={availabilityTimezone} />
      </Inline>
      <Stack gap="$medium" paddingBottom="$small">
        {visibleAvailabilities.map((availableTime, index) => (
          <AvailabilityBreakdownDay key={index} availability={availableTime} />
        ))}
      </Stack>
    </Stack>
  );
};

const getAvailabilityTimeRangeList = (availability: Availabilities) => {
  const timeRangeAvailabilities = availability.timeRange.map((time, index) => {
    const formattedStartDate = moment(time.startTime).format('h:mm a');
    const formattedEndDate = `${moment(time.endTime).format('h:mm a')} ${
      isNoon(time.endTime) ? <Text>(noon)</Text> : ''
    }`;
    return (
      <Text key={index} textAlign="right" asChild>
        <p style={{ fontVariantNumeric: 'tabular-nums' }}> {`${formattedStartDate} - ${formattedEndDate}`}</p>
      </Text>
    );
  });

  return <>{timeRangeAvailabilities}</>;
};

const AvailabilityText = ({ availability }: { availability: Availabilities }) => {
  const { t } = useTranslation('', { keyPrefix: 'teamMember.workDetails.generalAvailability.availabilityDay' });
  if (availability.isAllDay) {
    return (
      <Text asChild>
        <p>{t('allDay')}</p>
      </Text>
    );
  }

  if (availability.timeRange.length < 1) {
    return (
      <Text asChild color="$bodyDark1">
        <p>{t('unavailable')}</p>
      </Text>
    );
  }

  return getAvailabilityTimeRangeList(availability);
};

const AvailabilityBreakdownDay = ({ availability }: { availability: Availabilities }) => (
  <Inline justifyContent="space-between">
    <Text fontSize="$small">{moment().day(availability.number).format('dddd')}</Text>
    <Text fontWeight="$semi" fontSize="$small" color="$bodyDark">
      <Stack>
        <AvailabilityText availability={availability} />
      </Stack>
    </Text>
  </Inline>
);

const DisplayTimezoneInfo = ({ timezone }: { timezone: string }) => (
  <Inline gap="$xsmall">
    {/* TODO: Update to design system tooltip */}
    <Tooltip
      delay={0}
      placement="top center"
      content={
        <Stack
          minWidth="max-content"
          backgroundColor="$infoPressed"
          padding="$space150 $space200"
          borderRadius="$small"
          gap="$xxsmall"
        >
          <Text color="white" fontWeight="$semi" fontSize="$medium">
            Team member preferred timezone
          </Text>
          <Text color="white" fontSize="$small">
            {timezone}
          </Text>
          <Text color="white" fontSize="$small">
            UTC {moment.tz(timezone).format('Z')}
          </Text>
        </Stack>
      }
    >
      <Inline alignItems="center">
        <Announce color="$neutralDark1" width="$medium" height="$medium" />
      </Inline>
    </Tooltip>
    <Text fontSize="$xsmall" color="$bodyDark1">
      UTC {moment.tz(timezone).format('Z')}
    </Text>
  </Inline>
);

export default AvailabilityBreakdownWeekly;
