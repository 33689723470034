import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';

import * as styles from './message.css';
import { compose, css, useStyleProps, Positive, Announce, Critical } from '..';

import type { MessageVariants } from './message.css';
import type { HTMLProps, SlotProps, TextStyleProps } from '..';
import type { PropsWithChildren } from 'react';

export type MessageProps = PropsWithChildren<HTMLProps<HTMLLabelElement>> &
  SlotProps &
  TextStyleProps &
  MessageVariants & {
    isPasswordMessage?: boolean;
  };

/**
 * @deprecated
 * @see https://main--6371c62d928a15c9d0425ed3.chromatic.com/?path=/docs/components-status-message--docs
 */
export const Message = forwardRef<HTMLSpanElement, MessageProps>(function Message(props, ref) {
  const { styleProps, ...otherProps } = useStyleProps(props);
  const { children, tone = 'default', isPasswordMessage } = props;

  return (
    <span
      {...filterDOMProps(otherProps)}
      ref={ref}
      className={compose(css(styles.reset, styles.base, styleProps), styles.variants({ tone }))}
    >
      <span className={css(styles.icon)()}>
        {
          {
            default: null,
            normal: <Announce minWidth="$size160" width="$size160" height="$size160" />,
            critical: isPasswordMessage ? (
              <Announce minWidth="$size160" width="$size160" height="$size160" />
            ) : (
              <Critical minWidth="$size160" width="$size160" height="$size160" />
            ),
            positive: <Positive minWidth="$size160" width="$size160" height="$size160" />,
          }[tone as 'default' | 'critical' | 'positive' | 'normal']
        }
      </span>
      {children}
    </span>
  );
});
