import React, { Fragment } from 'react';
import { Text } from '@good/components';

import { Popover } from 'design-components/popover';
import Card from './Card';
import { Divider } from 'design-components';

export type DropDownItem = {
  label: string;
  onClick?: () => void;
  className?: string;
};

type DropdownItemProps = {
  label: string;
  className?: string | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const DropdownItem = ({ label, className, onClick }: DropdownItemProps): JSX.Element => {
  return (
    <button
      onClick={onClick}
      className="pl-medium pr-medium pt-medium pb-medium cursor-pointer hover:bg-gray-light-3 focus:bg-gray-light-3"
      style={{ textAlign: 'left' }}
    >
      <Text className={className}>{label}</Text>
    </button>
  );
};

type DropdownProps = {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  shouldCloseOnInteractOutside?: (element: Element) => boolean;
  items?: DropDownItem[];
};

const Dropdown = ({ children, isOpen, onClose, shouldCloseOnInteractOutside, items }: DropdownProps): JSX.Element => {
  return (
    <Popover
      isOpen={isOpen}
      backgroundColor="transparent"
      onClose={onClose}
      right={-6}
      shouldCloseOnInteractOutside={shouldCloseOnInteractOutside ?? (() => true)}
    >
      <Card width={168} className="overflow-hidden">
        <div className="flex-column flex">
          <input type="text" style={{ opacity: 0, height: 0 }} aria-hidden="true" />
          {items?.map(
            ({ label, onClick, className }, index) =>
              label && (
                <Fragment key={index}>
                  <DropdownItem label={label} className={className} onClick={onClick} />
                  {index !== items.length - 1 && <Divider />}
                </Fragment>
              ),
          )}
          {children}
        </div>
      </Card>
    </Popover>
  );
};

export default Dropdown;
