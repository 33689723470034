import React, { PureComponent } from 'react';
import { IGroupServiceTimesheetShiftSlot } from 'interfaces/service-interfaces';
import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { ActionButton } from 'views/bookings/new-listings/components/details/action-sheets/SingleActionHandler';
import { Popover } from '@blueprintjs/core';

interface ITimesheetBulkActionSheetProps {
  selectedShiftSlots: IGroupServiceTimesheetShiftSlot[];
  onDeselect: () => void;
  actionButtons: React.ReactNode;
}

class TimesheetBulkActionSheet extends PureComponent<ITimesheetBulkActionSheetProps, any> {
  state = { open: true, onOpenPopover: false };

  _animationEnd = () => {
    if (!this.state.open) {
      this.props.onDeselect();
    }
  };

  _onDeselect = () => {
    this.setState({ open: false });
  };

  _closePopover = () => {
    this.setState({ onOpenPopover: false });
  };

  _openPopover = () => {
    this.setState({ onOpenPopover: true });
  };

  render() {
    const { selectedShiftSlots, actionButtons } = this.props;
    const animClass = this.state.open ? 'action-anim-slide-in' : 'action-anim-slide-out';

    const itemCount = selectedShiftSlots.length;

    const popoverContent = (
      <div className="flex-column p-large" style={{ minWidth: '250px', textAlign: 'center' }}>
        <Text weight="bold">Available Actions</Text>
        {actionButtons}
      </div>
    );

    return (
      <div
        className={animClass}
        // style={{ bottom: 0, position: 'fixed', zIndex: 20, width: 'calc(100% - 300px)' }}
        style={{ bottom: 0, position: 'sticky', zIndex: 20 }}
        onAnimationEnd={this._animationEnd}
      >
        <div
          className="bg-blue"
          style={{
            width: '12px',
            height: '12px',
            position: 'absolute',
            top: '-6px',
            left: '18px',
            transform: 'rotate(45deg)',
          }}
        />
        <div className="bg-blue pv-medium ph-large flex-row justify-between align-center">
          <div>
            <Text color="white" weight="bold" className="mr-medium">
              {itemCount} item{itemCount > 1 ? 's' : ''} selected
            </Text>
            <HyperlinkButton color="white" className="dim" onClick={this._onDeselect}>
              Deselect all
            </HyperlinkButton>
          </div>
          <div>
            <Popover isOpen={this.state.onOpenPopover} onClose={this._closePopover} content={popoverContent}>
              <ActionButton onClick={this._openPopover}>Selection actions...</ActionButton>
            </Popover>
          </div>
        </div>
      </div>
    );
  }
}

export default TimesheetBulkActionSheet;
