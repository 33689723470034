import React from 'react';

import { IShiftSlot } from 'interfaces/shift-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import BulkTeamSummary from 'views/group-services/session-details/components/BulkTeamSummary';

interface IBulkApproveLeaveSummary {
  onEditShift: (shiftSlot: IShiftSlot) => void;
  selectedShiftSlots: IShiftSlot[];
  timezone: timeZone;
}

function BulkApproveLeaveSummary(props: IBulkApproveLeaveSummary): JSX.Element {
  const { onEditShift, selectedShiftSlots, timezone } = props;
  const options = {
    title: 'You are approving leave for the following team members.',
    action: {
      label: 'Edit shift times',
      onClick: onEditShift,
    },
  };

  return <BulkTeamSummary selectedShiftSlots={selectedShiftSlots} timezone={timezone} options={options} />;
}

export default BulkApproveLeaveSummary;
