import React, { useEffect, useState } from 'react';

export function useImageLoaded(imageRef: React.RefObject<HTMLImageElement>) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.addEventListener('load', () => {
        setImageLoaded(true);
      });
    }
  }, [imageRef]);

  return imageLoaded;
}
