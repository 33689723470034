import { tv } from 'tailwind-variants';

export const tabs = tv({
  base: ['flex', 'flex-col', 'h-full', 'w-full', 'orientation-[horizontal]', 'border-b-gray-light-2'],
});

export const tabList = tv({
  base: ['flex', 'flex-row', 'justify-start', 'gap-4', 'border-b-2', 'border-b-gray-light-2'],
});

export const tab = tv({
  base: [
    'group',
    'relative',
    'flex',
    'px-4',
    'h-[44px]',
    'mt-2',
    'mb-2',
    'rounded',
    'align-center',
    'cursor-default',
    'text-[14px]',
    'text-weak',
    'font-semibold',
    'outline-none',
    'hover:no-underline',
    'hover:bg-gray-light-2',
    'hover:text-base',
    'focus-visible:shadow-[0_0_0_2px_#206E8C]',
    'focus-visible:text-accent',
    'active:bg-ocean-light-2',
    'active:text-accent',
    'after:content-[""]',
    'after:w-full',
    'after:h-[2px]',
    'after:absolute',
    'after:-bottom-[9px]',
    'after:left-0',
    'aria-selected:text-accent',
    'aria-selected:after:bg-accent',
    'aria-selected:hover:bg-ocean-light-1',
    'aria-selected:active:bg-ocean-light-1',
    'aria-selected:active:shadow-[0_0_0_1.5px_#206E8C]',
  ],
});

export const tabPanel = tv({
  base: ['flex', 'flex-col', 'h-full', 'w-full', 'margin-top-4', 'p-4', 'outline-none'],
});
