import Steps from 'common-components/steps/Steps';
import { PORTAL_COLORS, PORTAL_COMMON_SIZES } from 'interfaces/common-ui-types';
import Animate from 'rc-animate';
import React, { Component, CSSProperties, ReactNode } from 'react';
import { IconButton } from '../buttons';

interface IFullScreenModalProps {
  isOpen: boolean;
  onClose: () => void;
  footerContent?: ReactNode;
  width?: 'small' | 'medium' | 'large' | 'x-large' | 'full' | number;
  hasFooter?: boolean;
  canClose?: boolean;
  canCloseEsc?: boolean;
  fixedHeight?: boolean;
  headerTitle?: any;
  headerStyle?: any;
  bgColor?: string;
  noMarginBottom?: boolean;
  contentPadding?: boolean;
  hasSteps?: boolean;
  currentStep?: number;
  steps?: any;
  stepStyle?: any;
  children?: React.ReactNode;
}

interface IFullScreenModalState {
  canClose?: boolean;
  canCloseEsc?: boolean;
  footerHeight: number;
}

export default class FullScreenScrollableModal extends Component<IFullScreenModalProps, IFullScreenModalState> {
  // width values mapping
  widthValues = {
    small: '640px',
    medium: '768px',
    large: '1024px',
    'x-large': '1152px',
    full: '100%',
  };

  state = {
    canClose: true,
    canCloseEsc: true,
    footerHeight: 0,
  };

  // ref element for the footer; used to determine the height of the footer
  _footerElement = null;

  // height change handler.
  private _handleFooterHeight = () => {
    if (this._footerElement && this._footerElement.offsetHeight !== this.state.footerHeight) {
      this.setState({ footerHeight: this._footerElement.offsetHeight });
    }
  };

  private _handleEscButton = (e) => {
    if (e.keyCode === 27) {
      // handle ESC button
      if (this.state.canClose && this.state.canCloseEsc) {
        // will trigger close only if props.canClose = true
        this.props.onClose();
      }
    }
  };

  componentDidMount() {
    this._handleFooterHeight();
    this.setState({
      canClose: this.props.canClose !== undefined ? this.props.canClose : true,
      canCloseEsc: this.props.canCloseEsc !== undefined ? this.props.canCloseEsc : true,
    });
    document.addEventListener('keydown', this._handleEscButton, false);
  }

  componentDidUpdate() {
    this._handleFooterHeight();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleEscButton, false);
  }

  render() {
    const {
      isOpen,
      children,
      footerContent,
      onClose,
      width = 'medium',
      hasFooter = true,
      canClose = true,
      headerTitle = null,
      bgColor = null,
      noMarginBottom = false,
      contentPadding = true,
      headerStyle,
      hasSteps = false,
      currentStep = 1,
      steps = null,
      stepStyle = null,
    } = this.props;

    let maxWidth: string;

    if (typeof width === 'number') {
      maxWidth = `${width}px`;
    } else {
      maxWidth = this.widthValues[width];
    }

    const contentClassName = 'flex flex-grow justify-center ' + (contentPadding ? 'ph-medium' : '');

    // const height = fixedHeight ? '100vh' : 'auto';

    const mainDivStyle: CSSProperties = {
      overflowY: 'auto',
      marginBottom: noMarginBottom ? 0 : `${this.state.footerHeight}px`,
    };

    return (
      <Animate transitionName='fade' transitionAppear>
        {isOpen && (
          <div
            style={{
              display: 'block',
              position: 'fixed',
              top: 0,
              left: 0,
              backgroundColor: 'white',
              height: '100vh',
              width: '100vw',
              zIndex: 20, // changed from 999 to 20 to support Blueprint Popovers
            }}
            className='lock-body-scroll'
          >
            <div className={`flex-column height-full anim-slide-up ${bgColor && 'bg-' + bgColor}`}>
              {/* Header */}
              <FullScreenModalHeader
                onClick={onClose}
                canClose={canClose}
                headerTitle={headerTitle}
                headerStyle={headerStyle}
                hasSteps={hasSteps}
                steps={steps}
                currentStep={currentStep}
                stepStyle={stepStyle}
              />

              {/*   Content */}
              <div className={contentClassName} style={mainDivStyle}>
                <div style={{ width: '100%', maxWidth }}>{children}</div>
              </div>

              {/* Footer */}
              {hasFooter && (
                <div
                  ref={(com) => (this._footerElement = com)}
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    left: 0,
                    backgroundColor: 'transparent',
                  }}
                >
                  {footerContent}
                </div>
              )}
            </div>
          </div>
        )}
      </Animate>
    );
  }
}

class FullScreenModalHeader extends Component<{
  onClick: () => void;
  canClose: boolean;
  headerTitle: any;
  headerStyle: any;
  hasSteps: boolean;
  steps: any;
  currentStep: number;
  stepStyle: any;
}> {
  render() {
    const { onClick, canClose, headerTitle, headerStyle, hasSteps, steps, currentStep, stepStyle } = this.props;
    const areStepsDisplayed = hasSteps && steps && steps.length > -1;
    const headerDivClassName = headerStyle ? headerStyle : `ph-x4-large ${areStepsDisplayed ? 'pv-large' : 'pt-large'}`;

    return (
      <>
        <div className='flex-row justify-between'>
          <div className={headerDivClassName}>{headerTitle}</div>
          <div className={`text-align-right ph-x4-large ${areStepsDisplayed ? 'pv-large' : 'pt-large'}`}>
            <IconButton
              size='large'
              icon='close'
              shape='circle'
              color='white'
              iconColor='secondary'
              className='hover-bg-secondary text-size-x5-large'
              disabled={!canClose}
              onClick={onClick}
            />
          </div>
        </div>
        {areStepsDisplayed && (
          <div className={stepStyle ? stepStyle : 'ph-x4-large pb-x2-large'}>
            <Steps currentStep={currentStep} steps={steps} />
          </div>
        )}
      </>
    );
  }
}

interface FullScreenModalFooterProps {
  children?: ReactNode;
  align?: 'left' | 'center' | 'right'; // alignment of footer
  backgroundColor?: PORTAL_COLORS; // background color
  paddingSize?: PORTAL_COMMON_SIZES;
  opacity?: number;
  bgTransparency?: number;
}

class FullScreenModalFooter extends Component<FullScreenModalFooterProps> {
  render() {
    const {
      children,
      align = 'right',
      backgroundColor = 'tertiary',
      paddingSize = 'medium',
      bgTransparency = null,
    } = this.props;

    const alignment = `text-align-${align}`;
    const colorCode = backgroundColor === 'tertiary' ? '248, 249, 250' : '255,255,255';
    const style: CSSProperties =
      bgTransparency > 0 ? { backgroundColor: 'rgba(' + colorCode + ',' + bgTransparency + ')' } : {};

    return (
      <div className={`p-${paddingSize} ${!bgTransparency && 'bg-' + backgroundColor} ${alignment}`} style={style}>
        {children}
      </div>
    );
  }
}

export { FullScreenModalFooter };
