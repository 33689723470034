import React from 'react';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { NumberInput, Text } from '@good/ui/core';

type PriceInputProps = {
  style?: Record<string, string>;
  name: FieldPath<FieldValues>;
};

export const ControlledPriceInput = ({ name, style }: PriceInputProps) => {
  const form = useFormContext<FieldValues>()

  return (
    <div style={style}>
      <Controller
        name={name}
        control={form.control}
        render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
          <>
            <NumberInput
              prefix="$"
              value={Number(value)}
              allowDecimal={true}
              onChange={onChange}
              ref={ref}
              fixedDecimalScale
              decimalScale={2}
              min={0}
              style={style}
            />
            <Text c="red">{error?.message}</Text>
          </>
        )}
      />
    </div>
  )
}

