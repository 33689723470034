import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Trophy = forwardRef(function Trophy(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3 2.25H14.7c.594 0 1.09 0 1.493.037.422.038.816.12 1.181.331a2.75 2.75 0 0 1 1.007 1.007c.115.2.192.408.244.625H20A2.75 2.75 0 0 1 22.75 7 4.75 4.75 0 0 1 18 11.75h-.127a4.75 4.75 0 0 1-2.055 1.638c-.474.197-.982.281-1.578.322-.42.029-.91.037-1.49.04v2.5h.583a3.417 3.417 0 0 1 3.417 3.417c0 1.15-.933 2.083-2.083 2.083H9.333a2.083 2.083 0 0 1-2.083-2.083 3.417 3.417 0 0 1 3.417-3.417h.583v-2.5c-.58-.003-1.07-.011-1.49-.04-.596-.04-1.104-.125-1.578-.322a4.75 4.75 0 0 1-2.055-1.638H6A4.75 4.75 0 0 1 1.25 7 2.75 2.75 0 0 1 4 4.25h1.374c.052-.217.13-.425.244-.625a2.75 2.75 0 0 1 1.007-1.007c.365-.21.76-.293 1.18-.331.404-.037.9-.037 1.494-.037Zm2.7 15.5h-1.333a1.917 1.917 0 0 0-1.917 1.917c0 .322.261.583.583.583h5.334a.583.583 0 0 0 .583-.583 1.917 1.917 0 0 0-1.917-1.917H12ZM18.75 6.3l-.001-.55H20c.69 0 1.25.56 1.25 1.25a3.251 3.251 0 0 1-2.668 3.198c.066-.297.105-.613.128-.958.04-.584.04-1.304.04-2.212V6.298ZM5.251 5.75H4c-.69 0-1.25.56-1.25 1.25a3.251 3.251 0 0 0 2.668 3.198 6.381 6.381 0 0 1-.128-.958c-.04-.584-.04-1.304-.04-2.213V6.3l.001-.549Zm2.69-1.97c-.317.03-.467.08-.566.137a1.25 1.25 0 0 0-.458.458c-.057.099-.108.249-.137.566-.03.328-.03.755-.03 1.392V7c0 .942 0 1.611.036 2.138.036.52.103.845.211 1.106a3.25 3.25 0 0 0 1.76 1.759c.26.108.586.175 1.105.21.527.037 1.196.037 2.138.037s1.611 0 2.138-.036c.52-.036.845-.103 1.106-.211a3.25 3.25 0 0 0 1.759-1.76c.108-.26.175-.586.21-1.105.037-.527.037-1.196.037-2.138v-.667c0-.637 0-1.064-.03-1.392-.03-.317-.08-.467-.137-.566a1.25 1.25 0 0 0-.458-.458c-.099-.057-.249-.108-.566-.137-.328-.03-.756-.03-1.392-.03H9.333c-.637 0-1.064 0-1.392.03Z"
        fill="currentColor"
      />
    </Icon>
  );
});
