import React, { forwardRef, useRef } from 'react';
import { mergeProps, mergeRefs } from '@react-aria/utils';
import { useFocusRing, useHover, useTextField } from 'react-aria';

import * as styles from './text-field.css';
import { FieldLabel } from '../field-label';
import { StatusMessage } from '../status-message';
import { twMerge } from '../utils/';

import type { AriaTextFieldProps } from 'react-aria';
import type { FieldLabelProps } from '../field-label';
import type { ForwardedRef, InputHTMLAttributes, RefObject } from 'react';
import type { HTMLProps } from '../types';

type LabelProps = {
  /**
   * Text for the text field's visible label element
   */
  label: FieldLabelProps['children'];
} & Omit<FieldLabelProps, 'children'>;

export type TextFieldProps = HTMLProps<HTMLInputElement | HTMLTextAreaElement> & {
  /**
   * Text for the text field's description element, if any.
   * */
  description?: AriaTextFieldProps['description'];
  /**
   * Text for the text field's error message element.
   */
  errorMessage?: AriaTextFieldProps['errorMessage'];
  /**
   * `ref object` to reference input element.
   * */
  inputRef?: ForwardedRef<HTMLInputElement>;
  /**
   * Controls whether the TextField is disabled.
   * @default false
   */
  isDisabled?: AriaTextFieldProps['isDisabled'];
  /**
   * Controls whether the TextField is readonly.
   * @default false
   */
  isReadOnly?: AriaTextFieldProps['isReadOnly'];
  /**
   * Controls the TextField's validation state.
   */
  validationState?: AriaTextFieldProps['validationState'];
  /**
   * Optional icon at the start of the TextField.
   */
  startIcon?: React.ReactNode;
  /**
   * Optional icon at the end of the TextField.
   */
  endIcon?: React.ReactNode;
} & LabelProps &
  Omit<
    AriaTextFieldProps,
    | 'description'
    | 'errorMessage'
    | 'inputRef'
    | 'isDisabled'
    | 'isReadOnly'
    | 'isRequired'
    | 'validationState'
    | 'startIcon'
    | 'endIcon'
    | 'isClearable'
  >;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const {
    className,
    description,
    errorMessage,
    inputRef,
    isRequired,
    label,
    requirementIndicator,
    validationState,
    startIcon,
    endIcon,
  } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks -- in general yes, but refs won't change
  const mergedRef = mergeRefs(ref, inputRef ?? useRef(null), useRef(null));
  const { focusProps, isFocusVisible, isFocused } = useFocusRing({ ...props, isTextInput: true });
  const { hoverProps, isHovered } = useHover(props);

  const { descriptionProps, errorMessageProps, inputProps, labelProps } = useTextField(
    props,
    mergedRef as RefObject<HTMLInputElement>,
  );

  const mergedInputProps = mergeProps(focusProps, hoverProps, inputProps);
  const isDescription = Boolean(description);
  const isErrorMessage = Boolean(errorMessage);
  const isInvalid = validationState === 'invalid';
  const showErrorMessage = isInvalid && isErrorMessage;
  const showDescription = isDescription && !showErrorMessage;
  const { container, inputContainer, input, icon } = styles.textField();

  return (
    <div className={twMerge(container(), className)}>
      <FieldLabel {...labelProps} requirementIndicator={requirementIndicator} isRequired={isRequired}>
        {label}
      </FieldLabel>

      <div className={inputContainer()}>
        {startIcon && (
          <div data-start className={icon()}>
            <span className="text-weak">{startIcon}</span>
          </div>
        )}
        <input
          {...(mergedInputProps as InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>)}
          ref={mergedRef}
          className={twMerge(input(), startIcon && 'pl-8', endIcon && 'pr-8')}
          data-focus-visible={isFocusVisible}
          data-focused={isFocused}
          data-hovered={isHovered}
        />
        {endIcon && (
          <div data-end className={icon()}>
            <span className="text-weak">{endIcon}</span>
          </div>
        )}
      </div>

      {showDescription && (
        <StatusMessage tone="neutral" {...descriptionProps}>
          {description}
        </StatusMessage>
      )}

      {showErrorMessage && (
        <StatusMessage tone="critical" {...errorMessageProps}>
          {errorMessage}
        </StatusMessage>
      )}
    </div>
  );
});

TextField.displayName = 'TextField';
