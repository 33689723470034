import { createContext, type Ref } from 'react';

type ButtonElement = Ref<HTMLButtonElement>;
export type ButtonProviderProps = { buttonRef?: ButtonElement } & { children?: React.ReactNode };

export const ButtonContext = createContext<ButtonProviderProps>({} as ButtonProviderProps);

export function ButtonProvider(props: ButtonProviderProps) {
  const { children, ...value } = props;
  return <ButtonContext.Provider value={value}>{children}</ButtonContext.Provider>;
}
