import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from '../../../../common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../stores/rematch/root-store';
import { TransportAttendanceType } from '../../../../utilities/enum-utils';
import { Paragraph, Text } from '../../../../common-components/typography';
import OptionsSelectorRow from '../../../bookings/components/OptionsSelectorRow';
import { GhostButton, PrimaryButton, SecondaryButton } from '../../../../common-components/buttons';
import { Col, Form, Radio, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ErrorSVG } from '../../../../assets/UndrawSVG';
import SpinningLoader from '../../../../common-components/loading/SpinningLoader';

interface ICancelTransportBookingModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  booking: any;
  bookingType: TransportAttendanceType;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  doCancelTransportBooking: typeof dispatch.groupBookingsStore.doCancelTransportBooking;
}

interface ICancelTransportBookingModalState {
  title: string;
  step: number;
  canManuallyClose: boolean;
  isCharge: boolean;
  cancelType: any;
  formValues: any;
  isCustomerNoShow: number;
  isChargeError: boolean;
  isBusinessCancel: boolean;
  cancellationReason: string;
  cancellationCode: string;
}

class CancelTransportBookingModal extends Component<
  ICancelTransportBookingModalProps,
  ICancelTransportBookingModalState
> {
  state = {
    title: 'Cancel booking',
    step: 1,
    canManuallyClose: true,
    cancelType: null,
    isCharge: undefined,
    formValues: null,
    isCustomerNoShow: 1,
    isChargeError: false,
    isBusinessCancel: false,
    cancellationReason: '',
    cancellationCode: '',
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;

    // Do any manual clean up; reset to initial state
    this._reset();

    onClose();
  };

  private _reset = () =>
    this.setState({
      step: 1,
      title: 'Cancel Booking',
      canManuallyClose: true,
      cancelType: null,
      isCharge: undefined,
      formValues: null,
      isCustomerNoShow: 1,
      isChargeError: false,
      isBusinessCancel: false,
      cancellationReason: '',
      cancellationCode: '',
    });

  private _setBusinessCancel = () => {
    this.setState({
      title: 'Cancel on behalf of the business',
      cancelType: 'business',
      isCharge: false,
      isBusinessCancel: true,
      step: 2,
    });
  };

  private _setCustomerCancel = () => {
    this.setState({
      title: 'Cancel on behalf of the customer',
      cancelType: 'customer',
      isBusinessCancel: false,
      step: 3,
    });
  };

  private _validateCancellationReason = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('You must add a cancellation reason.');
      } else if (_.trim(value).length < 5) {
        throw Error('Please enter at least 5 characters.');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _checkChargeSelection = (isCharge) => {
    this.setState({ isCharge }, () => {
      this.props.form.validateFieldsAndScroll(async (error, value) => {
        if (!error) {
          this.setState({ cancellationReason: value.reason }, () => {
            this._onCancelBooking();
          });
        }
      });
    });
  };

  private _goToChargeStep = () => {
    this.props.form.validateFieldsAndScroll(async (error, value) => {
      if (!error) {
        this.setState({
          formValues: value,
          cancellationReason: value.reason,
          cancellationCode: value.cancellationReason,
          step: 8,
        });
      }
    });
  };

  private _onCancelBookingChargeCustomer = () => {
    const { isCharge } = this.state;

    if (isCharge === undefined) {
      this.setState({ isChargeError: true });
      return;
    }

    this.props.form.validateFieldsAndScroll(async (error, value) => {
      if (!error) {
        this.setState(
          {
            isCustomerNoShow: value.chargeCustomerCancellationOption,
          },
          () => {
            this._onCancelBooking();
          },
        );
      }
    });
  };

  private _onChangeIsChargeOption = (event) => {
    this.setState({ isCharge: event.target.value, isChargeError: false });
  };

  private _onCancelBooking = async () => {
    const { bookingType, booking, doCancelTransportBooking, selectedGroupBookingItem } = this.props;
    const { isCharge, isBusinessCancel, isCustomerNoShow, cancellationReason, cancellationCode } = this.state;

    try {
      this.setState({ step: 4, canManuallyClose: false });

      await doCancelTransportBooking({
        bookingId: selectedGroupBookingItem.bookingId,
        transportBookingId: booking.attendanceId,
        isBusinessCancel,
        isChargeCancellation: isCharge,
        isCustomerNoShow: isCustomerNoShow === 1,
        cancellationReason,
        cancellationCode,
        attendanceType: bookingType,
      });

      this.setState({ step: 5, canManuallyClose: true, title: 'Transport booking successfully cancelled' });
    } catch (e) {
      this.setState({ step: 6, canManuallyClose: true });
    }
  };

  private _renderView = () => {
    const { form, booking } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { step } = this.state;

    if (step === 1) {
      return (
        <div>
          <div className="anim-slide-left">
            <div className="mb-medium">
              <Paragraph>Please select one of the following options.</Paragraph>
            </div>

            <div className="text-align-left mb-x-large">
              <OptionsSelectorRow
                title={'Cancel on behalf of the business'}
                description={'Select this option if you are cancelling on behalf of the business.'}
                onClick={() => this._setBusinessCancel()}
              />
              <OptionsSelectorRow
                title={'Cancel on behalf of the customer'}
                description={'Select this option if the customer has requested you to cancel this booking.'}
                onClick={() => this._setCustomerCancel()}
              />

              <div className="bordered-top" />
            </div>
          </div>

          <ActionModalFooter>
            <GhostButton size="large" onClick={this._onCloseModal}>
              I've changed my mind
            </GhostButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div>
          <div style={{ minHeight: 317 }}>
            <Paragraph>
              Please indicate the reason for <b>cancelling</b> this booking.
            </Paragraph>
            <Row className="mb-small" type="flex" align="top">
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator('reason', {
                    initialValue: booking.cancellationReason,
                    rules: [{ validator: this._validateCancellationReason }],
                  })(
                    <TextArea
                      placeholder={'Cancellation reason'}
                      autoSize={{ minRows: 4, maxRows: 8 }}
                      className="width-full"
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>

          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={this._reset}>
              I've changed my mind
            </GhostButton>
            <PrimaryButton size="large" onClick={() => this._checkChargeSelection(false)}>
              Confirm
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div>
          <div style={{ minHeight: 317 }}>
            <Paragraph>
              Please select the reason the customer asked you to <b>cancel</b> this booking.
            </Paragraph>

            <div className="">
              <Row className="mb-small" type="flex" align="top">
                <Col span={24}>
                  <Form.Item>
                    {getFieldDecorator('cancellationReason', {
                      initialValue: booking.cancellationCode ? booking.cancellationCode : undefined,
                      rules: [{ required: true, message: 'Please select cancellation reason' }],
                    })(
                      <Select className="width-2/3" size={'large'} placeholder={'Cancellation reason'}>
                        <Select.Option value="NSDH">No show due to health reasons (NSDH)</Select.Option>
                        <Select.Option value="NSDF">No show due to family reasons (NSDF)</Select.Option>
                        <Select.Option value="NSDT">No show due to unavailability of transport (NSDT)</Select.Option>
                        <Select.Option value="NSDO">Other</Select.Option>
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
              </Row>

              {getFieldValue('cancellationReason') === 'NSDO' && (
                <Row className="mb-small" type="flex" align="top">
                  <Col span={24}>
                    <div className={'mb-medium'}>
                      <Text>Please specify the reason</Text>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('reason', {
                        initialValue: booking.cancellationReason,
                        rules: [{ validator: this._validateCancellationReason }],
                      })(
                        <TextArea
                          className="width-full"
                          placeholder={'Cancellation reason'}
                          autoSize={{ minRows: 3, maxRows: 6 }}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={this._reset}>
              Back
            </GhostButton>
            <PrimaryButton size="large" onClick={this._goToChargeStep}>
              Confirm
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div>
          <div style={{ minHeight: 317 }}>
            <SpinningLoader size={150} message={'Loading'} />
          </div>
        </div>
      );
    }

    if (step === 5) {
      return (
        <div>
          <div>
            <div className="mb-medium">
              {this.state.isBusinessCancel ? (
                <Paragraph>
                  You have cancelled{' '}
                  <b>
                    {booking.firstName} {booking.lastName}
                  </b>{' '}
                  transport booking for this session on behalf of the business.
                </Paragraph>
              ) : (
                <Paragraph>
                  You have cancelled{' '}
                  <b>
                    {booking.firstName} {booking.lastName}
                  </b>{' '}
                  transport booking for this session on behalf of the customer{' '}
                  {this.state.isCharge ? '& charged a fee' : ''}.
                </Paragraph>
              )}
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 6) {
      return (
        <div>
          <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
            <div className="pv-medium flex-column justify-center">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium flex-column justify-center ">
              <Paragraph>Oops something has gone wrong, please try again</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Back to booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 8) {
      return (
        <div>
          <div className="anim-slide-left">
            <div className="mb-medium">
              <Paragraph>Please select one of the following options. You can change your mind later.</Paragraph>
            </div>

            <div className=" mb-x-large">
              <Radio
                value={true}
                onChange={this._onChangeIsChargeOption}
                checked={this.state.isCharge}
                className={`${this.state.isCharge === true && 'text-weight-bold'} mb-small width-full`}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Charge a cancellation fee
                </div>
              </Radio>
              {this.state.isCharge === true && (
                <Form.Item className="mt-small">
                  {getFieldDecorator('chargeCustomerCancellationOption', {
                    initialValue: undefined,
                    rules: [{ required: true, message: 'Please select a charge cancellation option' }],
                  })(
                    <Select
                      className="width-2/3"
                      size="large"
                      placeholder={'Select a charge cancellation option'}
                      dropdownMatchSelectWidth={true}
                    >
                      <Select.Option value={1}>Customer was a no show for the booking</Select.Option>
                      <Select.Option value={0}>Customer cancelled ahead of time</Select.Option>
                    </Select>,
                  )}
                </Form.Item>
              )}
              <Radio
                value={false}
                onChange={this._onChangeIsChargeOption}
                checked={this.state.isCharge === false}
                className={`${this.state.isCharge === false && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Do not charge a cancellation fee
                </div>
              </Radio>
              {this.state.isChargeError && <div className="text-color-red-dark">Please select an option</div>}
            </div>
          </div>

          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
              Back
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._onCancelBookingChargeCustomer}>
              Confirm
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="large"
        verticalAlignment="highest"
      >
        {this._renderView()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCancelTransportBooking: dispatch.groupBookingsStore.doCancelTransportBooking,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<ICancelTransportBookingModalProps>()(CancelTransportBookingModal));
