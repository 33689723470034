import React, { useState, useEffect } from 'react';
import { findLast, isEmpty, keys, orderBy } from 'lodash';
import moment from 'moment-timezone';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RcFile } from 'antd/es/upload';
import { FormComponentProps } from 'antd/es/form';
import { Col, Divider, Dropdown, Form, Icon, Menu, Modal, Row } from 'antd';

import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text, Title } from 'common-components/typography';
import { ref, uploadBytesResumable } from 'firebase/storage';
import firebaseApp from 'stores/firebase-app';

import { FormElementType, PropKey } from '../shared/form-enum';
import DiscardChangesModal from './DiscardChangesModal';
import FormEditElementsRendered from './FormEditElementsRendered';
import { IElementValue, IFormContent, IFormElement, ITime } from '../shared/form-interface';
import { AttachmentInfo } from './form-elements/AttachmentInput/AttachmentInput';
import { FORM_STATUS } from 'views/account-management/utils/constants';
import { cloneElement, getCloneLevel, getOriginalId } from '../shared/form-builder-utils';
import { FormType } from 'utilities/enum-utils';

export enum  FormModeType {
  'EDIT',
  'VIEW',
  'ADD'
}

type IEditValueFormProps = {
  isOpen?: boolean;
  canEditForm?: boolean;
  formElements?: IFormContent;
  formId: string;
  elementValues?: IElementValue[];
  mode: FormModeType;
  portalUser: typeof state.authStore.portalUser;
  onClose: () => void;
  onSave: (id, elementValues: IElementValue[], status: string, isSyncCustomerData: boolean) => void;
  getDocumentAndFile: typeof dispatch.formBuilderStore.getDocumentAndFile;
  isEditModal?: boolean;
  timezone?: string;
  customActionName?: string;
  setCurrentForm: typeof dispatch.formBuilderStore.setCurrentForm;
} & FormComponentProps;

enum Feature {
  Team = 'team',
  Customer = 'customer',
  Service = 'group-service',
  Bookings = 'bookings',
  Workflows = 'workflows',
}

enum SubmitButton {
  SAVE_PROGRESS = 'SAVE_PROGRESS',
  COMPLETE_FORM = 'COMPLETE_FORM',
  COMPLETE_AND_SYNC = 'COMPLETE_AND_SYNC',
}

const EditValueFormModal = ({
  isOpen,
  canEditForm = true,
  formElements,
  form,
  elementValues: originalElementValues,
  formId,
  portalUser,
  mode,
  onClose,
  onSave,
  getDocumentAndFile,
  isEditModal = false,
  timezone: timezoneProps,
  customActionName,
  setCurrentForm,
}: IEditValueFormProps) => {
  const { elementsBundleOptional, currentForm, isLoadingFormData } = useSelector(
    (state: IRootState) => state.formBuilderStore,
  );
  const [elementValues, setElementValues] = useState<IElementValue[]>(originalElementValues);
  const [formMode, setFormMode] = useState<FormModeType>(mode);
  const [times, setTimes] = useState<ITime[]>([]);
  const [timezone, setTimezone] = useState<string>(moment.tz.guess());
  const [prevTimezone, setPrevTimezone] = useState<string>('');
  const [buttonLoading, setButtonLoading] = useState<SubmitButton>(null);
  const { title, elements } = formElements;
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const feature = pathname.split('/')[1];

  useEffect(() => {
    if (feature === Feature.Bookings || feature === Feature.Workflows) {
      setTimezone(timezoneProps);
    }
  }, [feature]);

  useEffect(() => {
    if (hasClonedElement()) {
      mapElementValueToClonedElement();
      addCloneElementForFormData();
    }
  }, []);

  const checkElementsError = (elementsError: IFormElement[], fieldsError: string[], elements: IFormElement[]) => {
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (fieldsError.includes(element.id)) {
        elementsError.push(element);
        break;
      }

      if (element?.children) {
        checkElementsError(elementsError, fieldsError, element.children);
      }
    }
  };

  const _doOnSave = (isSyncCustomerData: boolean) => {
    try {
      form.validateFields(async (errors, newValuesFrom) => {
        const latestDataForm = Object.assign({}, ...times);
        let isError = !isEmpty(errors);
        const idsElementError = Object.keys(errors || {}).map((key) => key.replace(',value', '').trim());

        if (isError && elementsBundleOptional.length) {
          isError = idsElementError.some((item) => !elementsBundleOptional.includes(item));
        }

        if (isError) {
          idsElementError.forEach((key) => {
            if (elementsBundleOptional.includes(key)) {
              const fieldName = `${key},value`;

              form.setFieldsValue({
                [fieldName]: form.getFieldValue(fieldName),
              });
            }
          });

          const elementsError: IFormElement[] = [];
          const fieldsError = Object.keys(errors).map((item) => item.substring(0, item.indexOf(',')).trim());

          checkElementsError(elementsError, fieldsError, elements);

          const firstFieldError = document.getElementById(`${elementsError?.[0]?.id},value`);
          firstFieldError?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
        } else {
          setButtonLoading(isSyncCustomerData ? SubmitButton.COMPLETE_AND_SYNC : SubmitButton.COMPLETE_FORM);
          const newValues = await mapFormValueToElementValue({ ...newValuesFrom, ...latestDataForm });
          const sanitizeValue = newValues.filter((value: IElementValue) => value.elementType !== undefined);

          await onSave(formId, sanitizeValue, FORM_STATUS.COMPLETED, isSyncCustomerData);
          setButtonLoading(null);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveProcess = async () => {
    try {
      setButtonLoading(SubmitButton.SAVE_PROGRESS);
      const latestDataForm = Object.assign({}, ...times);
      const newValuesFrom = form.getFieldsValue();
      const newValues = await mapFormValueToElementValue({ ...newValuesFrom, ...latestDataForm });
      const sanitizeValue = newValues.filter((value: IElementValue) => value.elementType !== undefined);

      await onSave(formId, sanitizeValue, FORM_STATUS.IN_PROGRESS, false);
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(null);
    }
  };

  const _onChangeModeToEdit = () => {
    setFormMode('EDIT');
  };

  // This is where values are mapped/saved
  const mapFormValueToElementValue = async (formValues): Promise<IElementValue[]> => {
    if (isEmpty(formValues)) return [];
    const values = await Promise.all(
      keys(formValues)
        .filter((x) => {
          const [id] = x.split(',');
          return formValues[`${id},value`] && formValues[`${id},time`]
            ? x.endsWith(',value')
            : x.endsWith(',value') || x.endsWith(',time');
        })
        .map(async (key) => {
          const [id] = key.split(',');
          const isClonedElement = getCloneLevel(id) > 0;
          const elementId = isClonedElement ? getOriginalId(id) : id;
          const elementType = formValues[`${id},type`];
          const parentElementId = formValues[`${id},parentElementId`];
          let value = formValues[key];
          if (elementType === FormElementType.DATE_TIME) {
            value = [formValues[`${id},value`], formValues[`${id},time`], formValues[`${id},completeTime`]];
          } else if (elementType === FormElementType.FILE_UPLOAD) {
            const attachmentValue = formValues[key];
            const oldDocument: AttachmentInfo[] = (formValues[`${id},oldDocument`] || []) as AttachmentInfo[];
            value = (await _handleFileUpload(attachmentValue, oldDocument)) as AttachmentInfo[];
          }

          const result: IElementValue = {
            elementId,
            elementData: value,
            elementType: elementType,
            ...(elementType === FormElementType.DATE_TIME && {
              timezone: formMode === 'ADD' ? timezone : prevTimezone,
            }),
            parentElementId,
            isClonedElement,
          };
          return result;
        }),
    );
    return values;
  };

  const hasClonedElement = () => elementValues?.some((el) => el.isClonedElement);

  // Sets the elementValues array (tracks form value changes)
  const mapElementValueToClonedElement = () => {
    const newElementValues = orderBy([...elementValues], 'isClonedElement', 'asc');

    for (let i = 0; i < newElementValues.length; ++i) {
      if (!newElementValues[i].isClonedElement) {
        continue;
      }

      for (let j = i - 1; j >= 0; --j) {
        if (getOriginalId(newElementValues[j].elementId) === getOriginalId(newElementValues[i].elementId)) {
          newElementValues[i].elementId = `${newElementValues[j].elementId}-cloned`;
          break;
        }
      }
    }

    setElementValues(newElementValues);
  };

  const addCloneElementForFormData = () => {
    const parentElementCloned = {};
    let listNewElement = [...formElements.elements];
    elementValues
      .filter((el) => el.isClonedElement)
      .forEach((value) => {
        const allElements = getAllElementsWithChilds(listNewElement);
        const element = findLast(allElements, (el) => value.elementId.indexOf(el.id) !== -1);

        if (
          element.parentId &&
          !parentElementCloned[element.parentId] &&
          isCloneParentElement(element.id, allElements)
        ) {
          const parentElement = allElements.find((el) => el.id === element.parentId);
          parentElementCloned[parentElement.id] = true;
          appendElementCloneToOriginalData(parentElement, listNewElement);
        }
      });

    const allElements = getAllElementsWithChilds(listNewElement);
    const newElementValues = orderBy(
      elementValues.map((value) => {
        const parentElementId = allElements.find((el) => el.id === value.elementId)?.parentId ?? null;
        const id = parentElementId ?? value.elementId;
        const cloneLevel = getCloneLevel(id);

        return {
          ...value,
          parentElementId,
          cloneLevel,
        };
      }),
      'cloneLevel',
      'asc',
    );

    listNewElement = listNewElement.filter((el) => {
      const maxCloneLevel =
        findLast(
          newElementValues,
          (elValue) =>
            getOriginalId(elValue.elementId) === getOriginalId(el.id) ||
            getOriginalId(elValue.parentElementId) === getOriginalId(el.id),
        )?.cloneLevel ?? 0;

      return getCloneLevel(el.id) <= maxCloneLevel;
    });

    setCurrentForm({
      ...formElements,
      elements: listNewElement,
    });
  };

  const appendElementCloneToOriginalData = (originalElement: IFormElement, listElement: IFormElement[]) => {
    const elementCloned = cloneElement(originalElement);
    const findHeading = (elementCloned?.children || [])[0]?.type === FormElementType.HEADER;
    if (findHeading) elementCloned?.children.shift();

    const parentElementIndex = listElement.findIndex((item) => item.id === originalElement.id);
    listElement.splice(parentElementIndex + 1, 0, elementCloned);
  };

  const getAllElementsWithChilds = (elements: IFormElement[]) => {
    return elements.reduce((prev, curr) => {
      if (curr.children?.length) {
        prev.push(...curr.children);
      }
      prev.push(curr);

      return prev;
    }, []);
  };

  const isCloneParentElement = (elementId: string, allElements: IFormElement[]) => {
    const elementCloned = allElements.find((el) => el.id === elementId);
    const parentElement = elementCloned?.parentId ? allElements.find((el) => el.id === elementCloned.parentId) : null;
    if (!parentElement) return false;

    const conditionalElement = allElements.find(
      (el) => el.parentId === parentElement.id && el.type === FormElementType.CONDITIONAL,
    );
    if (!conditionalElement) return false;

    const affectElement = conditionalElement.properties.configuration.find(
      (config) => config.key === PropKey.AFFECT_TO_ELEMENT,
    );
    return affectElement.value?.includes(conditionalElement.parentId);
  };

  const _handleFileUpload = async (attachmentValue, oldDocument: AttachmentInfo[]): Promise<AttachmentInfo[]> => {
    const { fileList } = attachmentValue || { fileList: [] };
    let newDocument: AttachmentInfo[] = [];

    if (fileList && fileList.length > 0) {
      const documents = fileList.map((file: RcFile) => file.name);
      const {
        data: { documents: documentsResult },
      } = (await getDocumentAndFile(documents)) as any;

      newDocument = documentsResult.map((doc, index) => {
        const file = fileList[index];
        const result: AttachmentInfo = {
          name: file.name,
          documentId: doc.documentId,
        };
        return result;
      });

      await Promise.all(
        documentsResult.map(async (doc, index) => {
          const file = fileList[index];
          await _uploadFileBase(doc, file);
        }),
      );
    }

    return [...(oldDocument || []), ...newDocument];
  };

  const _uploadFileBase = async (document, file) => {
    const metadata = {
      customMetadata: {
        documentId: document.documentId,
        serviceProviderId: portalUser.serviceProviderId,
        formId: formId,
      },
    };

    try {
      const storageRef = ref(firebaseApp.storage, `${document.uploadBucketUrl}/${file.name}`);
      const uploadFile = uploadBytesResumable(storageRef, file.originFileObj, metadata);

      await uploadFile.on('state_changed');
    } catch (e) {
      console.log(e);
    }
  };

  const _handleCancel = () => {
    // view mode doesn't need confirmation to close
    // no fields are touched -> close
    if (formMode === 'VIEW' || !form.isFieldsTouched()) {
      onClose();
      return;
    }

    setIsDiscardModalOpen(true);
  };

  const _handleProceedDiscardModal = () => {
    onClose();
    setIsDiscardModalOpen(false);
  };

  const _handleCancelDiscardModal = () => {
    setIsDiscardModalOpen(false);
  };

  const onChangeFormElements = (formContent: IFormContent) => {
    setCurrentForm(formContent);
  };

  const checkDisabledSubmitButton = (submitButtons: SubmitButton[]): boolean => {
    return submitButtons.includes(buttonLoading) || isLoadingFormData;
  };

  const viewTitle = (
    <div>
      <span className='mr-12'>{title.formTitle}</span>
      <GhostButton onClick={_onChangeModeToEdit} className='text-underline'>
        Edit..
      </GhostButton>
    </div>
  );

  return (
    <Modal
      centered
      visible={isOpen}
      title={
        <Title level={4} className='mv-none'>
          {formMode === 'VIEW' && canEditForm ? viewTitle : title.formTitle}
        </Title>
      }
      onCancel={_handleCancel}
      className='mv-x2-large form-modal-container'
      footer={
        (formMode === 'EDIT' || formMode === 'ADD') && canEditForm ? (
          <ActionModalFooter>
            <Row type={'flex'} style={{ justifyContent: 'space-between' }}>
              <Col className='mr-12'>
                <GhostButton size='large' onClick={_handleCancel}>
                  Cancel
                </GhostButton>
              </Col>
              <Row type='flex'>
                <Col className='mr-12'>
                  <SecondaryButton
                    size='large'
                    onClick={onSaveProcess}
                    loading={buttonLoading === SubmitButton.SAVE_PROGRESS}
                    disabled={checkDisabledSubmitButton([SubmitButton.COMPLETE_FORM, SubmitButton.COMPLETE_AND_SYNC])}
                  >
                    Save progress
                  </SecondaryButton>
                </Col>
                {currentForm.formType !== FormType.INTAKE && (
                  <Col className='mr-12'>
                    <PrimaryButton
                      size='large'
                      onClick={() => _doOnSave(false)}
                      loading={buttonLoading === SubmitButton.COMPLETE_FORM}
                      disabled={checkDisabledSubmitButton([SubmitButton.SAVE_PROGRESS, SubmitButton.COMPLETE_AND_SYNC])}
                    >
                      Complete form
                    </PrimaryButton>
                  </Col>
                )}
                {currentForm.formType === FormType.INTAKE && (
                  <Col>
                    <Dropdown.Button
                      onClick={() => _doOnSave(false)}
                      overlay={
                        <Menu>
                          <Menu.Item
                            size='large'
                            onClick={() => _doOnSave(true)}
                            disabled={checkDisabledSubmitButton([
                              SubmitButton.SAVE_PROGRESS,
                              SubmitButton.COMPLETE_FORM,
                            ])}
                          >
                            Sync
                          </Menu.Item>
                        </Menu>
                      }
                      icon={
                        <Icon
                          type={
                            buttonLoading === SubmitButton.COMPLETE_AND_SYNC ||
                            buttonLoading === SubmitButton.COMPLETE_FORM
                              ? 'loading'
                              : 'down'
                          }
                        />
                      }
                      disabled={checkDisabledSubmitButton([SubmitButton.SAVE_PROGRESS, SubmitButton.COMPLETE_AND_SYNC])}
                      type='primary'
                      size='large'
                      trigger={['click']}
                    >
                      Complete
                    </Dropdown.Button>
                  </Col>
                )}
              </Row>
            </Row>
          </ActionModalFooter>
        ) : null
      }
      width='100vh'
    >
      <DiscardChangesModal
        isOpen={isDiscardModalOpen}
        onProceed={_handleProceedDiscardModal}
        onCancel={_handleCancelDiscardModal}
        portalClassName='z-index-top' // DO NOT REMOVE. Required for modal on top
      />

      <span
        className={`position-relative ${formMode === 'VIEW' ? 'bg-blue' : 'bg-green'} pv-small ph-12 rounded-big-top`}
        style={{
          left: '-24px',
          top: formMode === 'EDIT' || formMode === 'ADD' ? '-116px' : '-122px',
          height: '35px',
        }}
      >
        <Text size='x2-large' color='white'>
          {formMode === 'VIEW' ? 'View' : 'Edit'} mode
        </Text>
      </span>
      <div
        className='position-relative pr-6'
        style={{ top: '-35px', maxHeight: 'calc(100vh - 360px)', overflow: 'scroll' }}
      >
        {title.formDescription && (
          <>
            <Paragraph className='whitespace-pre-line'>{title.formDescription}</Paragraph>
            <Divider className='divider-large' />
          </>
        )}
        <div className={`${formMode === 'VIEW' && 'disabled'}`}>
          <FormEditElementsRendered
            setPrevTimezone={setPrevTimezone}
            setTimes={setTimes}
            formContent={formElements}
            form={form}
            elementValues={elementValues}
            timezone={prevTimezone || timezone}
            formMode={formMode}
            onChangeFormElements={onChangeFormElements}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  getDocumentAndFile: dispatch.formBuilderStore.getDocumentAndFile,
  setCurrentForm: dispatch.formBuilderStore.setCurrentForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create<IEditValueFormProps>()(EditValueFormModal));
