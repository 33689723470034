import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Avatar, Checkbox, notification, Radio } from 'antd';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IBookingTimeSlot, ISessionCustomer } from 'interfaces/session-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import { TeamMemberCustomerRatio } from 'utilities/enum-utils';

interface IBooking extends ISessionCustomer {
  serviceId: string;
  serviceDateTimeId: string;
  bookingId: string;
  timezone: timeZone;
}

interface IAssignTeamMemberToBookingTimeSlotModalProps {
  isOpen: boolean;
  booking: IBooking;
  selectedTimeSlot: IBookingTimeSlot;
  onClose: () => void;
  groupBookingTimeSlotTeamMembers: typeof state.groupBookingsStore.groupBookingTimeSlotTeamMembers;
  doFetchGroupBookingTimeSlotTeamMembers: typeof dispatch.groupBookingsStore.doFetchGroupBookingTimeSlotTeamMembers;
  assignTeamMemberToBookingTimeSlot: typeof dispatch.groupBookingsStore.assignTeamMemberToBookingTimeSlot;
  unAssignTeamMembersToBookingTimeSlot: typeof dispatch.groupBookingsStore.unAssignTeamMembersToBookingTimeSlot;
  doFetchSessionCustomerDetails: typeof dispatch.groupServiceStore.doFetchSessionCustomerDetails;
}

const AssignTeamMemberToBookingTimeSlotModal = (props: IAssignTeamMemberToBookingTimeSlotModalProps) => {
  const {
    isOpen,
    booking,
    selectedTimeSlot,
    groupBookingTimeSlotTeamMembers,
    onClose,
    assignTeamMemberToBookingTimeSlot,
    unAssignTeamMembersToBookingTimeSlot,
    doFetchSessionCustomerDetails,
    doFetchGroupBookingTimeSlotTeamMembers,
  } = props;
  const { serviceId, serviceDateTimeId, firstName, lastName, timezone } = booking;
  const {
    startDateTime,
    endDateTime,
    teamMemberCustomerRatio,
    attendanceTimeSlotId,
    attendanceId,
    customerUserId,
    supportWorkerId,
    supportWorkerIds,
  } = selectedTimeSlot;

  const [selectedOption, setSelectedOption] = useState<string>(supportWorkerId ?? 'null');
  const [selectedOptions, setSelectedOptions] = useState<string[]>(supportWorkerIds ?? []);
  const [isLoading, setIsLoading] = useState(false);
  const isTwoToOneTeamMemberCustomerRatio = teamMemberCustomerRatio === TeamMemberCustomerRatio.TWO_TO_ONE;

  const handleChangeOption = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleChangeOptions = (values) => {
    let newValues = values;

    if (selectedOptions.includes('null')) {
      newValues = values.filter((value) => value !== 'null');
    } else if (values.includes('null')) {
      newValues = ['null'];
    }

    setSelectedOptions(newValues);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (isTwoToOneTeamMemberCustomerRatio) {
        const supportWorkerIds = selectedOptions;
        if (supportWorkerIds.includes('null') || supportWorkerIds.includes(null)) {
          await unAssignTeamMembersToBookingTimeSlot({
            serviceId,
            serviceDateTimeId,
            attendanceId,
            attendanceTimeSlotId,
          });
        } else {
          await assignTeamMemberToBookingTimeSlot({
            serviceId,
            serviceDateTimeId,
            attendanceId,
            attendanceTimeSlotId,
            customerUserId,
            supportWorkerIds,
          });
        }
      } else {
        const selectedShiftSlot = groupBookingTimeSlotTeamMembers.find(
          (shiftSlot) => shiftSlot.supportWorkerId === selectedOption,
        );
        const supportWorkerId = selectedShiftSlot?.supportWorkerId ?? null;
        await assignTeamMemberToBookingTimeSlot({
          serviceId,
          serviceDateTimeId,
          attendanceId,
          attendanceTimeSlotId,
          customerUserId,
          supportWorkerId,
        });
      }

      await doFetchSessionCustomerDetails({
        serviceId,
        serviceDateTimeId,
      });
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
    }

    setIsLoading(false);
    onClose();
  };

  const renderTeamMember = ({ supportWorkerId, firstName, lastName, attachmentUrl }) => {
    return isTwoToOneTeamMemberCustomerRatio ? (
      <Checkbox
        key={supportWorkerId}
        value={supportWorkerId}
        className={`width-full p-medium ${selectedOptions.includes(supportWorkerId) ? 'bg-info-blue' : ''}`}
        style={{ marginLeft: 0 }}
        disabled={!selectedOptions.includes(supportWorkerId) && selectedOptions.length > 1}
      >
        <Avatar className="ml-large mr-small" size="default" shape="circle" src={attachmentUrl} />
        <Text>
          {firstName} {lastName}
        </Text>
      </Checkbox>
    ) : (
      <Radio
        key={supportWorkerId}
        value={supportWorkerId}
        className={`width-full p-medium ${selectedOption === supportWorkerId ? 'bg-info-blue' : ''}`}
      >
        <Avatar className="ml-large mr-small" size="default" shape="circle" src={attachmentUrl} />
        <Text>
          {firstName} {lastName}
        </Text>
      </Radio>
    );
  };

  const renderSelectTeamMember = () => {
    return isTwoToOneTeamMemberCustomerRatio ? (
      <Checkbox.Group
        className="width-full overflow-auto"
        style={{ maxHeight: '400px', overflowX: 'hidden' }}
        value={selectedOptions}
        onChange={handleChangeOptions}
      >
        {groupBookingTimeSlotTeamMembers.map((shiftSlot) => renderTeamMember(shiftSlot))}
        <Checkbox
          className={`width-full p-medium ${selectedOptions.includes('null') ? 'bg-info-blue' : ''}`}
          value="null"
          style={{ marginLeft: 0 }}
        >
          <Text className="inline-block ml-large">No team member</Text>
        </Checkbox>
      </Checkbox.Group>
    ) : (
      <Radio.Group
        className="width-full overflow-auto"
        style={{ maxHeight: '400px', overflowX: 'hidden' }}
        value={selectedOption}
        onChange={handleChangeOption}
      >
        {groupBookingTimeSlotTeamMembers.map((shiftSlot) => renderTeamMember(shiftSlot))}
        <Radio className={`width-full p-medium ${selectedOption === 'null' ? 'bg-info-blue' : ''}`} value="null">
          <Text className="inline-block ml-large">No team member</Text>
        </Radio>
      </Radio.Group>
    );
  };

  useEffect(() => {
    if (!isOpen) return;

    const fetchTeamMembers = async () => {
      try {
        setIsLoading(true);
        await doFetchGroupBookingTimeSlotTeamMembers({
          serviceId,
          serviceDateTimeId,
          attendanceId,
          attendanceTimeSlotId,
          startDateTime,
          endDateTime,
        });
      } catch (e) {
        notification.error({ message: 'Oops, an error has occurred, please try again.' });
      } finally {
        if (isTwoToOneTeamMemberCustomerRatio) {
          setSelectedOptions(supportWorkerIds ?? []);
        } else {
          setSelectedOption(supportWorkerId ?? 'null');
        }
        setIsLoading(false);
      }
    };

    fetchTeamMembers();
  }, [isOpen]);

  return (
    <ActionModal isOpen={isOpen} title={`Assign team member for ${teamMemberCustomerRatio} ratio`} onClose={onClose}>
      <Paragraph>
        Which team {isTwoToOneTeamMemberCustomerRatio ? 'members' : 'member'} would you like to assign to{' '}
        <b>{`${firstName} ${lastName}’s ${teamMemberCustomerRatio} slot`}</b> from{' '}
        <b>{`${moment.tz(startDateTime, timezone).format('hh:mm A')} to ${moment
          .tz(endDateTime, timezone)
          .format('hh:mm A')}`}</b>
        ?
      </Paragraph>
      <Paragraph>You can only assign team members inside of the session.</Paragraph>
      {isLoading ? <SpinningLoader message="" size={80} /> : renderSelectTeamMember()}
      <ActionModalFooter>
        <SecondaryButton onClick={onClose} size="large" className="mr-medium">
          Cancel
        </SecondaryButton>
        <PrimaryButton onClick={handleSave} size="large" loading={isLoading}>
          Save
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};

const mapState = (state: IRootState) => ({
  groupBookingTimeSlotTeamMembers: state.groupBookingsStore.groupBookingTimeSlotTeamMembers,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupBookingTimeSlotTeamMembers: dispatch.groupBookingsStore.doFetchGroupBookingTimeSlotTeamMembers,
  assignTeamMemberToBookingTimeSlot: dispatch.groupBookingsStore.assignTeamMemberToBookingTimeSlot,
  unAssignTeamMembersToBookingTimeSlot: dispatch.groupBookingsStore.unAssignTeamMembersToBookingTimeSlot,
  doFetchSessionCustomerDetails: dispatch.groupServiceStore.doFetchSessionCustomerDetails,
});

export default connect(mapState, mapDispatch)(AssignTeamMemberToBookingTimeSlotModal);
