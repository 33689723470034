import { Button, Checkbox, Col, Form, Icon, Radio, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { HyperlinkButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { IBillingLineItem } from 'interfaces/booking-interfaces';
import {
  BillingAutoGeneratedType,
  MmmGroup,
  NonFaceToFaceFrequency,
  PaymentSourceType,
  DaysOfWeek,
} from 'utilities/enum-utils';
import { ndisHelper } from 'variables/data-helpers';
import BillingLineItemV2 from 'views/billings/components/BillingLineItemV2';
import NonFaceToFaceMinutesInput from './NonFaceToFaceMinutesInput';
import { DEFAULT_NON_FACE_TO_FACE_MINUTES } from '../../utils/constants';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import clsx from 'clsx';
import { state } from 'src/stores/rematch/root-store';
import moment from 'moment';
import { IServiceAgreement } from 'src/interfaces/customer-interfaces';

const StyledText = styled(Text)`
  font-size: 18px;
`;

interface IAdditionalChargesSectionProps extends FormComponentProps {
  onNonFaceToFaceChange?: (e) => void;
  addAutoChargeLineItem?: () => void;
  updateAutoChargeLineItem?: (item, index) => void;
  removeAutoChargeLineItem?: (index) => void;
  isNonFaceToFace: boolean;
  nonFaceToFaceMinutes?: number;
  isEdit: boolean;
  mmmGroup?: MmmGroup;
  state?: string;
  serviceLineItems?: Array<any>;
  autoChargeLineItems: Array<any>;
  paymentSourceType?: string;
  nonFaceToFaceFrequency?: NonFaceToFaceFrequency;
  newServiceAgreement: typeof state.customersStore.newServiceAgreement;
  selectedServiceAgreement: IServiceAgreement;
  companyData: typeof state.companyStore.companyDataLite;
}

interface IAdditionalChargesSectionState {
  newBillingLineItem: IBillingLineItem;
  lineItems: any[];
  isLoading: boolean;
}

class AdditionalChargesSection extends Component<IAdditionalChargesSectionProps, IAdditionalChargesSectionState> {
  state = {
    newBillingLineItem: null,
    lineItems: this.props.autoChargeLineItems,
    isLoading: false,
  };

  public autoChargeLineItemsValidateRef: any[] = [];

  private _setRef = (ref) => {
    if (ref) {
      this.autoChargeLineItemsValidateRef.push(ref);
    }
  };

  public onValidateNonFaceToFaceMinutes = () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      return form.getFieldValue('nonFaceToFaceInput');
    }
  };

  public onValidateAutoChargeItems = async () => {
    let isFormValid = true;

    await Promise.all(
      _.map(this.autoChargeLineItemsValidateRef, async (validate) => {
        const validated = await validate.onValidate();
        if (!validated) {
          isFormValid = false;
        }
      }),
    );
    return isFormValid;
  };

  private _validateNonFaceToFaceMinutes = (rule, value, callback) => {
    try {
      if (!_.toString(value.nonFaceToFaceMinutes)) {
        throw Error('Please enter a value');
      }

      if (value.nonFaceToFaceMinutes > 9999) {
        throw Error('Value cannot exceed 9999 minutes');
      }

      if (value.nonFaceToFaceMinutes < 1) {
        throw Error('Value must be 1 or greater');
      }
    } catch (e) {
      callback(e);
      return;
    }
    callback();
  };

  private _onEnableSpecifyDays = (e: CheckboxChangeEvent, item: IBillingLineItem, index: number) => {
    const checked = e.target.checked;
    const newAutoChargeLineItem = { ...item, onlyAppliesToSpecificDays: checked };

    if (!checked) {
      newAutoChargeLineItem.specificDaysOfAvailability = [];
    } else {
      let startDate = null;
      if (this.props?.selectedServiceAgreement) {
        startDate = moment.tz(this.props.selectedServiceAgreement.startDate, this.props?.companyData.timezone);
      } else {
        startDate = moment.tz(this.props.newServiceAgreement.startDate, this.props?.companyData.timezone);
      }

      newAutoChargeLineItem.specificDaysOfAvailability = [startDate.format('ddd').toUpperCase()];
    }

    this.props.updateAutoChargeLineItem(newAutoChargeLineItem, index);
  };

  private _onChangeSpecifyDays = (value: string, item: IBillingLineItem, index: number) => {
    const newAutoChargeLineItem = { ...item };
    newAutoChargeLineItem.specificDaysOfAvailability = newAutoChargeLineItem.specificDaysOfAvailability || [];
    const dayIndex = newAutoChargeLineItem.specificDaysOfAvailability?.indexOf(value);

    if (dayIndex > -1) {
      if (newAutoChargeLineItem.specificDaysOfAvailability?.length > 1) {
        newAutoChargeLineItem.specificDaysOfAvailability.splice(dayIndex, 1);
      } else {
        return;
      }
    } else {
      newAutoChargeLineItem.specificDaysOfAvailability.push(value);
    }

    this.props.updateAutoChargeLineItem(newAutoChargeLineItem, index);
  };

  render() {
    const {
      onNonFaceToFaceChange,
      isNonFaceToFace,
      form,
      mmmGroup,
      state,
      autoChargeLineItems,
      serviceLineItems,
      paymentSourceType,
      isEdit,
      nonFaceToFaceMinutes,
      nonFaceToFaceFrequency,
    } = this.props;

    let lineItems = [];
    if (autoChargeLineItems) {
      lineItems = _.map(autoChargeLineItems, (item) => {
        let generatedBy = null;
        if (item.ruleType === 'ABT') {
          generatedBy = BillingAutoGeneratedType.AutoChargedBillingItemsAdditionalCharge;
        }

        return {
          ...item,
          startDateTime: null,
          endDateTime: null,
          supportItemNumberArray: [],
          description: null,
          total: 0,
          paymentMethod: null,
          generatedBy,
          isTravel: false,
          travelDistance: 0,
          mileagePrice: 0,
          isEditing: isEdit,
          paymentSourceType: item.paymentSourceType ? item.paymentSourceType : PaymentSourceType.NDIS,
        };
      });
    }

    const serviceBillingItems = _.map(serviceLineItems, (item) => {
      const supportItem = item.supportItemName
        ? item.supportItemName
        : ndisHelper.getBySupportItemNumber(item.supportItemNumber).SupportItem;
      return { ...item, paymentSourceType: paymentSourceType, supportItem };
    });

    const billingItemDivWidth = isEdit ? '92%' : '100%';

    const { getFieldDecorator, getFieldValue } = form;
    let currentNonFaceToFaceFrequency =
      getFieldValue('nonFaceToFaceInput')?.nonFaceToFaceFrequency || nonFaceToFaceFrequency;

    if (!currentNonFaceToFaceFrequency) {
      currentNonFaceToFaceFrequency =
        nonFaceToFaceMinutes && !nonFaceToFaceFrequency
          ? NonFaceToFaceFrequency.PER_BOOKING
          : NonFaceToFaceFrequency.PER_HOUR;
    }
    return (
      <>
        <div className="mb-x-large">
          {isEdit ? (
            <>
              <StyledText weight="bold">
                Non face-to-face{' '}
                <StyledText weight="regular" color="secondary">
                  (Optional)
                </StyledText>
              </StyledText>
              <Paragraph className="mv-medium">
                You can automatically charge non face-to-face costs for every booking for this service. This will be
                calculated by the relevant line item rate <b>based on the time/location of the session</b>.
              </Paragraph>
              <div className="mb-x-large">
                <Radio.Group value={isNonFaceToFace} onChange={onNonFaceToFaceChange}>
                  <Radio value={false}>Do not charge non face-to-face</Radio>
                  <br />
                  <Radio value={true}>Charge non face-to-face </Radio>
                </Radio.Group>
                {isNonFaceToFace && (
                  <div className="ph-large pv-large bg-tertiary mt-x-small">
                    <SubTitle>MINUTES CHARGED PER {currentNonFaceToFaceFrequency}</SubTitle>
                    <Form.Item className="m-none">
                      {getFieldDecorator('nonFaceToFaceInput', {
                        rules: [{ validator: this._validateNonFaceToFaceMinutes }],
                        initialValue: {
                          nonFaceToFaceMinutes: nonFaceToFaceMinutes || DEFAULT_NON_FACE_TO_FACE_MINUTES,
                          nonFaceToFaceFrequency: currentNonFaceToFaceFrequency,
                        },
                      })(<NonFaceToFaceMinutesInput />)}
                    </Form.Item>
                  </div>
                )}
              </div>
              <StyledText weight="bold">
                Auto-charged line items{' '}
                <StyledText weight="regular" color="secondary">
                  (Optional)
                </StyledText>
              </StyledText>
              <Paragraph className="mv-medium">
                You can automatically charge any additional pre-defined line items. These line items will be
                automatically added to the billings section of the customer’s bookings.
              </Paragraph>
            </>
          ) : (
            <>
              <SubTitle textClassName="mt-large">Non face-to-face costs</SubTitle>
              <Text className="mt-x-small mb-x-large block">
                {isNonFaceToFace ? (
                  <>
                    <b>{nonFaceToFaceMinutes} minutes</b> of{' '}
                  </>
                ) : (
                  <b>No </b>
                )}
                non face-to-face time being charged per {nonFaceToFaceFrequency || NonFaceToFaceFrequency.PER_BOOKING}
              </Text>

              <SubTitle textClassName="mb-large">Auto-Charged Line Items</SubTitle>
            </>
          )}
        </div>

        {(lineItems.length > 0 || !isEdit) && (
          <>
            <Row
              style={isEdit ? { width: '94% ' } : {}}
              gutter={24}
              className="pb-medium mb-medium bordered-bottom border-standard-gray"
            >
              <Col span={11}>
                <SubTitle>Support Item</SubTitle>
              </Col>

              <Col span={4}>
                <SubTitle>Claim Type</SubTitle>
              </Col>

              <Col span={3}>
                <SubTitle>Unit</SubTitle>
              </Col>

              <Col span={3}>
                <SubTitle>Price</SubTitle>
              </Col>
              <Col span={3}>
                <SubTitle>Quantity</SubTitle>
              </Col>
            </Row>

            {lineItems.map((item, index) => {
              const onlyAppliesToSpecificDays =
                item?.onlyAppliesToSpecificDays ?? item?.specificDaysOfAvailability?.length > 0;

              return (
                <>
                  <div className="flex-row justify-between">
                    <div style={{ width: billingItemDivWidth }}>
                      {!this.state.isLoading && (
                        <BillingLineItemV2
                          key={item.bookingBillingLineItemId}
                          index={index}
                          wrappedComponentRef={this._setRef}
                          billingSupportItems={serviceBillingItems}
                          billingLineItem={item}
                          fundedCategories={[]}
                          serviceBillingLineItems={[]}
                          travelDistance={10}
                          showLineItemLabel={false}
                          isRemoving={false}
                          isCancelled={false}
                          cancellationCode={null}
                          cancellationReason={null}
                          isEditPaymentLineItem={true}
                          hasABTLineItems={false}
                          hasPTNLCLineItems={false}
                          isFilterOutTravelItem={false}
                          mmmGroup={mmmGroup}
                          locationState={state}
                          hideWarningFunding={true}
                          isSaveButtonOutside={true}
                          hideManagementMethod={true}
                          hideSelectionOptionLineItemWarning={true}
                          updateBillingItem={this.props.updateAutoChargeLineItem}
                          hideLineItemCost={true}
                          isAutoChargeLineItems={true}
                          hideBottomLineItemName={!isEdit}
                          hideInputLabels={true}
                          disableUnitSelection={true}
                          isFilterOutPTNLCLineItem={true}
                          isUnitPriceDisabled={item.ruleType === 'ABT'}
                        />
                      )}
                    </div>
                    {isEdit && (
                      <div className={'pt-large'}>
                        <HyperlinkButton key={index} onClick={() => this.props.removeAutoChargeLineItem(index)}>
                          Delete
                        </HyperlinkButton>
                      </div>
                    )}
                  </div>
                  <div>
                    {isEdit && (
                      <>
                        <Checkbox
                          checked={onlyAppliesToSpecificDays}
                          onChange={(e) => this._onEnableSpecifyDays(e, item, index)}
                        >
                          Specify days
                        </Checkbox>
                        {onlyAppliesToSpecificDays && (
                          <div className="flex mt-small">
                            {Object.keys(DaysOfWeek).map((key) => {
                              const isActive = item?.specificDaysOfAvailability?.includes(key);

                              return (
                                <Button
                                  key={key}
                                  type="link"
                                  shape="circle"
                                  size="large"
                                  className={clsx(
                                    'mr-12 flex align-center justify-center',
                                    isActive ? 'bg-blue-action' : 'bg-standard-gray',
                                  )}
                                  onClick={() => this._onChangeSpecifyDays(key, item, index)}
                                >
                                  <Text
                                    size="x-small"
                                    color={isActive ? 'white' : 'secondary'}
                                    weight={isActive ? 'bolder' : 'regular'}
                                  >
                                    {DaysOfWeek[key]}
                                  </Text>
                                </Button>
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              );
            })}
          </>
        )}

        {lineItems.length <= 0 && !isEdit && (
          <div className="text-align-center width-full text-color-secondary mv-large">No auto-charge line items.</div>
        )}

        {isEdit && (
          <div className="mt-medium mb-large">
            <HyperlinkButton onClick={this.props.addAutoChargeLineItem}>+ Add a line item</HyperlinkButton>
          </div>
        )}
      </>
    );
  }
}
export default connect()(Form.create<IAdditionalChargesSectionProps>()(AdditionalChargesSection));
