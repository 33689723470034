import { notification, Tabs } from 'antd';
import { IWorkflowListItem } from 'interfaces/workflow-interfaces';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import '../css/workflow-styles.css';
import AddIncidentNoteModal from '../trigger/AddIncidentNoteModal';
import { WORKFLOW_LISTING_VIEW_TABS, WORKFLOW_LISTING_VIEW_TAB_KEY } from '../utils/constants';
import AllWorkflowsItemPanel from './components/tabs-panel/all-workflows/AllWorkflowsItemPanel';
import MyWorkflowsItemPanel from './components/tabs-panel/my-workflows/MyWorkflowsItemPanel';
import { PageHeader } from '@good/ui/templates';

const { TabPane } = Tabs;

interface IWorkflowListingViewProps extends RouteComponentProps {
  portalUser: typeof state.authStore.portalUser;
  setAllWorkflows: typeof dispatch.workflowStore.setAllWorkflows;
  setAllApprovalWorkflows: typeof dispatch.workflowStore.setAllApprovalWorkflows;
  doCheckViewWorkflowDetails: typeof dispatch.workflowStore.doCheckViewWorkflowDetails;
}

interface IWorkflowListingViewState {
  selectedTab: string;
  reloadTab: number;
  openManuallyAddWorkflowModal: boolean;
}

class WorkflowListingView extends Component<IWorkflowListingViewProps, IWorkflowListingViewState> {
  state = {
    selectedTab: WORKFLOW_LISTING_VIEW_TAB_KEY.REQUIRE_YOUR_APPROVAL,
    openManuallyAddWorkflowModal: false,
    reloadTab: new Date().getTime(),
  };

  private _onClickManuallyAddWorkflow = () => {
    this.setState({ openManuallyAddWorkflowModal: true });
  };

  private _onClickManuallyCloseWorkflow = (isReload = false) => {
    this.setState({ openManuallyAddWorkflowModal: false, ...(isReload && { reloadTab: new Date().getTime() }) });
  };

  private _onChangeSelectedTab = (tab) => {
    const { history } = this.props;

    this.setState({ selectedTab: tab });

    const urlParams = new URLSearchParams(history.location.search);
    urlParams.set('selectedTab', tab);
    this.props.history.replace({ search: urlParams.toString() });
  };

  private _goWorkflowDetails = async (workflow: IWorkflowListItem) => {
    const { history, doCheckViewWorkflowDetails } = this.props;

    const response: any = await doCheckViewWorkflowDetails({ workflowId: workflow.workflowId });

    if (response && response.canViewDetail) {
      history.push(`/workflows/details/${workflow.workflowId}`, { backableToList: true });
    } else {
      notification.error({ message: "You don't have permission to view this workflow" });
    }
  };

  componentDidMount() {
    const { history } = this.props;
    const urlParams = new URLSearchParams(history.location.search);
    const selectedTab = urlParams.get('selectedTab');
    if (selectedTab) {
      this.setState({ selectedTab });
    }
  }

  componentWillUnmount() {
    this.props.setAllWorkflows([]);
    this.props.setAllApprovalWorkflows([]);
  }

  render() {
    const { portalUser } = this.props;
    const { selectedTab, openManuallyAddWorkflowModal, reloadTab } = this.state;

    return (
      <div id='content-container'>
        <AddIncidentNoteModal
          isManual={true}
          isOpen={openManuallyAddWorkflowModal}
          onClose={this._onClickManuallyCloseWorkflow}
        />
        <div className='height-full'>
          <PageHeader title='Incidents' subtitle='View and manage your incidents.' />

          <Tabs type='card' activeKey={selectedTab} animated={true} onChange={this._onChangeSelectedTab}>
            {WORKFLOW_LISTING_VIEW_TABS.map((tab) => (
              <TabPane tab={tab.title} key={tab.key} />
            ))}
          </Tabs>

          {selectedTab === WORKFLOW_LISTING_VIEW_TAB_KEY.REQUIRE_YOUR_APPROVAL && (
            <MyWorkflowsItemPanel onClickRow={this._goWorkflowDetails} reloadTime={reloadTab} />
          )}

          {selectedTab === WORKFLOW_LISTING_VIEW_TAB_KEY.ALL_WORKFLOWS && (
            <AllWorkflowsItemPanel onClickRow={this._goWorkflowDetails} reloadTime={reloadTab} />
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAllWorkflows: dispatch.workflowStore.setAllWorkflows,
  setAllApprovalWorkflows: dispatch.workflowStore.setAllApprovalWorkflows,
  doCheckViewWorkflowDetails: dispatch.workflowStore.doCheckViewWorkflowDetails,
});

export default connect(mapState, mapDispatch)(WorkflowListingView);
