import React from 'react';
import SpinningLoader from 'common-components/loading/SpinningLoader';

/**
 * Placeholder Loader until we decide how we wanting loading to
 * look. This should be updated later on down the track when we
 * get further input from design and product.
 */
export const Loader = () => (
  <div className='ph-small'>
    <SpinningLoader size={100} message='Fetching service details' />
  </div>
);
