import { Popover, Tooltip } from '@blueprintjs/core';
import { Col, Icon, Row } from 'antd';
import { notification } from 'antd/es';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { GridHeader, GridRow } from 'common-components/grids';
import { FieldLabel, SubTitle, Text } from 'common-components/typography';
import { IWorkflowAttachment } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import ListingEmptyState from '../../ListingEmptyState';
import LoadingProcess from '../../LoadingProcess';
import AddEditWorkflowAttachmentModal from './components/AddEditWorkflowAttachmentModal';
import DeleteWorkflowAttachmentModal from './components/DeleteWorkflowAttachmentModal';
import { downloadFileFromUrl } from 'utilities/file-utils';

interface Props {
  workflowId: string;
  isDisableAction: boolean;
  activeStepNumber?: number;
  portalUser: typeof state.authStore.portalUser;
  workflowAttachments: typeof state.workflowStore.workflowAttachments;
  setWorkflowAttachments: typeof dispatch.workflowStore.setWorkflowAttachments;
  doDeleteAttachment: typeof dispatch.workflowStore.doDeleteAttachment;
  doGetAttachments: typeof dispatch.workflowStore.doGetAttachments;
  doGetPortalUserDetail: typeof dispatch.authStore.doGetPortalUserDetail;
}

interface State {
  isOpenAddEditWorkflowAttachmentModal: boolean;
  isOpenDeleteWorkflowAttachmentModal: boolean;
  isDeletingAttachment: boolean;
  selectedAttachment?: IWorkflowAttachment;
  isLoading: boolean;
}

class WorkflowAttachmentsPanel extends PureComponent<Props, State> {
  state = {
    isOpenAddEditWorkflowAttachmentModal: false,
    isOpenDeleteWorkflowAttachmentModal: false,
    isDeletingAttachment: false,
    selectedAttachment: undefined,
    isLoading: false,
  };

  private _onOpenAddEditWorkflowAttachmentModal = (attachment?: IWorkflowAttachment) => {
    this.setState({
      selectedAttachment: attachment || undefined,
      isOpenAddEditWorkflowAttachmentModal: true,
    });
  };

  private _onCloseAddEditWorkflowAttachmentModal = () => {
    this.setState({
      selectedAttachment: undefined,
      isOpenAddEditWorkflowAttachmentModal: false,
    });
  };

  private _onOpenDeleteWorkflowAttachmentModal = (attachment: IWorkflowAttachment) => {
    this.setState({ selectedAttachment: attachment, isOpenDeleteWorkflowAttachmentModal: true });
  };

  private _onCloseDeleteWorkflowAttachmentModal = () => {
    this.setState({ selectedAttachment: undefined, isOpenDeleteWorkflowAttachmentModal: false });
  };

  private _getDescriptionAttachment = (attachment: IWorkflowAttachment) => {
    if (attachment.formName)
      return (
        <Text className='text-italic'>
          Linked to form: <Text className='text-weight-bolder'>{attachment.formName}</Text>
        </Text>
      );
    if (attachment.stepNumber)
      return (
        <Text className='text-italic'>
          External form for: <Text className='text-weight-bolder'>Step {attachment.stepNumber}</Text>
        </Text>
      );
    if (attachment.description) {
      return attachment.description;
    }
  };

  private _onDeleteAttachment = async () => {
    const { workflowId, doDeleteAttachment } = this.props;

    this.setState({ isDeletingAttachment: true });

    const response: any = await doDeleteAttachment({
      workflowId,
      workflowAttachmentId: this.state.selectedAttachment.workflowAttachmentId,
    });

    if (response.isDeleted) {
      notification.success({
        message: 'Attachment deleted',
        description: 'You have successfully deleted an attachment from this workflow.',
      });
      this._onRefreshAttachments();
      this._onCloseDeleteWorkflowAttachmentModal();
    } else {
      notification.error({ message: response.meta.message });
    }
    this.setState({ isDeletingAttachment: false });
  };

  private _onDownloadAttachment = (attachment: { url: string; name: string }) => {
    downloadFileFromUrl({
      documentUrl: attachment.url,
      documentName: attachment.name,
    });
  };

  private _onRefreshAttachments = async () => {
    const { workflowId, activeStepNumber, doGetAttachments } = this.props;

    this.setState({ isLoading: true });
    await doGetAttachments({ workflowId, stepNumber: activeStepNumber || 0 });
    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    const { workflowId, activeStepNumber, doGetAttachments, doGetPortalUserDetail } = this.props;

    if (workflowId) {
      this.setState({ isLoading: true });

      await doGetPortalUserDetail({});

      await doGetAttachments({ workflowId, stepNumber: activeStepNumber || 0 });

      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = () => {
    this.props.setWorkflowAttachments([]);
  };

  render() {
    const { workflowAttachments, portalUser, isDisableAction } = this.props;
    const {
      isOpenAddEditWorkflowAttachmentModal,
      isOpenDeleteWorkflowAttachmentModal,
      isDeletingAttachment,
      selectedAttachment,
      isLoading,
    } = this.state;

    return (
      <div className='bg-white p-x-large'>
        <Row type='flex' align='middle' justify='space-between'>
          <div className='flex-column flex-1'>
            <Text size='x3-large' weight='bolder'>
              Attachment
            </Text>
            <Text color='secondary'>View all uploaded forms attached to this workflow and linked forms.</Text>
          </div>
          {!isDisableAction && (
            <PrimaryButton icon='plus' size='large' onClick={() => this._onOpenAddEditWorkflowAttachmentModal()}>
              Add attachment
            </PrimaryButton>
          )}
        </Row>

        <div className='mt-large'>
          <GridHeader bordered containerClassName='border-secondary'>
            <Col span={8}>
              <SubTitle containerClassName='line-height-135'>Form</SubTitle>
            </Col>
            <Col span={8}>
              <SubTitle containerClassName='line-height-135'>Description</SubTitle>
            </Col>
            <Col span={7}>
              <SubTitle containerClassName='line-height-135'>Added by</SubTitle>
            </Col>
            <Col span={1}>
              <FieldLabel text='' />
            </Col>
          </GridHeader>
          {isLoading ? (
            <LoadingProcess />
          ) : workflowAttachments && workflowAttachments.length > 0 ? (
            workflowAttachments.map((attachment) => (
              <GridRow containerClassName='bg-white hover-bg-quaternary' key={attachment.workflowAttachmentId}>
                <Col span={8}>
                  <span title={attachment.name}>
                    <Text
                      color={
                        attachment.documentStatus === 'SCANNING'
                          ? 'secondary'
                          : attachment.documentStatus === 'SCANNED'
                          ? 'green'
                          : 'red'
                      }
                    >
                      {attachment.documentStatus === 'SCANNED' ? (
                        <HyperlinkButton
                          onClick={() => this._onDownloadAttachment(attachment)}
                          className='word-break-all'
                        >
                          <Icon type='download' className='text-color-blue-action mr-small' />
                          {attachment.name}
                        </HyperlinkButton>
                      ) : (
                        <>
                          <Tooltip
                            content={
                              attachment.documentStatus === 'SCANNING'
                                ? 'This attachment is being scanned.'
                                : 'This attachment failed to upload.'
                            }
                          >
                            <Icon
                              type='question-circle'
                              className={`text-size-x-large mr-x-small text-color-${
                                attachment.documentStatus === 'SCANNING' ? 'blue' : 'red'
                              }`}
                            />
                          </Tooltip>
                          {attachment.name}
                        </>
                      )}
                    </Text>
                  </span>
                </Col>
                <Col span={8} className='break-word'>
                  <Text>{this._getDescriptionAttachment(attachment)}</Text>
                </Col>
                <Col span={7}>
                  <Text>{attachment.createdBy}</Text>
                </Col>
                {!attachment.formName &&
                  !attachment.stepNumber &&
                  attachment.userId === portalUser.userId &&
                  !isDisableAction && (
                    <Col span={1} className='p-none'>
                      <Popover
                        content={
                          <ActionMenu>
                            <ActionMenuItem
                              text='Edit'
                              onClick={() => this._onOpenAddEditWorkflowAttachmentModal(attachment)}
                            />
                            <ActionMenuItem
                              text='Delete'
                              textClassName='text-color-red'
                              onClick={() => this._onOpenDeleteWorkflowAttachmentModal(attachment)}
                            />
                          </ActionMenu>
                        }
                        popoverClassName={'mb-medium'}
                        position={'bottom-right'}
                        interactionKind='click'
                      >
                        <Icon type='ellipsis' className='text-color-blue-action p-x-small bordered cursor-pointer' />
                      </Popover>
                    </Col>
                  )}
              </GridRow>
            ))
          ) : (
            <ListingEmptyState title='No attachment found.' description='All attachments will appear here' />
          )}
        </div>
        <AddEditWorkflowAttachmentModal
          isOpen={isOpenAddEditWorkflowAttachmentModal}
          attachment={selectedAttachment}
          workflowId={this.props.workflowId}
          onRefreshAttachments={this._onRefreshAttachments}
          onClose={this._onCloseAddEditWorkflowAttachmentModal}
        />
        <DeleteWorkflowAttachmentModal
          isOpen={isOpenDeleteWorkflowAttachmentModal}
          isDeleting={isDeletingAttachment}
          onClose={this._onCloseDeleteWorkflowAttachmentModal}
          onDelete={this._onDeleteAttachment}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  workflowAttachments: state.workflowStore.workflowAttachments,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setWorkflowAttachments: dispatch.workflowStore.setWorkflowAttachments,
  doDeleteAttachment: dispatch.workflowStore.doDeleteAttachment,
  doGetAttachments: dispatch.workflowStore.doGetAttachments,
  doGetPortalUserDetail: dispatch.authStore.doGetPortalUserDetail,
});

export default connect(mapState, mapDispatch)(WorkflowAttachmentsPanel);
