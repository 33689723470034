import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Glasses = forwardRef(function Glasses(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.624 3.584a.75.75 0 0 1-.208 1.04l-1.22.813c-.447.298-.74.494-.954.667-.202.164-.29.274-.344.377-.055.102-.098.236-.122.495-.026.275-.026.627-.026 1.165v5.119a4.251 4.251 0 0 1 6.934 2.49h2.632a4.251 4.251 0 0 1 6.934-2.49V8.14c0-.537-.001-.89-.027-1.164-.024-.259-.066-.393-.121-.495-.055-.103-.143-.213-.345-.377-.214-.173-.507-.37-.955-.667l-1.218-.813a.75.75 0 0 1 .832-1.248l1.218.812.028.019c.413.275.764.51 1.04.732.292.237.54.494.722.834.182.34.258.688.293 1.064.033.352.033.775.033 1.27V16.5a4.25 4.25 0 0 1-8.434.75h-2.632a4.251 4.251 0 0 1-8.434-.737V8.108c0-.496 0-.919.032-1.271.035-.376.111-.725.293-1.064.182-.34.43-.597.723-.834a14.64 14.64 0 0 1 1.04-.732l.027-.019 1.219-.812a.75.75 0 0 1 1.04.208ZM20.25 16.5a2.75 2.75 0 1 0-5.5 0 2.75 2.75 0 0 0 5.5 0ZM6.5 13.75a2.75 2.75 0 1 0 2.75 2.76v-.02a2.75 2.75 0 0 0-2.75-2.74Z"
        fill="currentColor"
      />
    </Icon>
  );
});
