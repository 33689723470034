import React, { Component } from 'react';

// Panels
import { Col, Form, Input, notification, Row } from 'antd';

import { SubTitle, Text, Title } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { INewMemberData } from 'src/interfaces/account-interfaces';
import { FormComponentProps } from 'antd/es/form';
import _ from 'lodash';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IStartStepPanelProps extends FormComponentProps {
  onStepNext: any;
  onStepPrevious: any;
  newMemberData?: INewMemberData;
  setNewMemberData?: any;
  setFuzzyMatchTeamMember: typeof dispatch.teamStore.setFuzzyMatchTeamMember;
}

interface StartStepPanelState {}

class StartStepPanel extends Component<IStartStepPanelProps, StartStepPanelState> {
  inputChange = (e) => {
    const updatedData = { ...this.props.newMemberData, [e.target.id]: e.target.value };
    this.props.setNewMemberData(updatedData);
  };

  private _onNextStep = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        this.props.setFuzzyMatchTeamMember({});
        this.props.onStepNext();
      } catch (e) {
        notification.error({ message: 'Oops! Something wrong happened, please try again.' });
      }
    }
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';

    try {
      if (_.isEmpty(value)) {
        throw Error('Please add a mobile number.');
      }
      let phoneNumber = parsePhoneNumberFromString(value, region);
      if (!phoneNumber || !phoneNumber.isValid()) {
        throw new Error('Invalid mobile number');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  render() {
    let { newMemberData, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div className="anim-fade-in select-none">
        <Row className="ph-x2-large">
          {/* Left Column */}
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 142px)', overflow: 'auto' }}>
            <div style={{ maxWidth: '80%', minHeight: 'calc(100vh - 262px)' }}>
              {/* <-- ! Left Content Starts here ! --> */}

              {/* Left Title */}
              <Title level={3} weight="regular">
                We'll guide you through adding a team member to your business
              </Title>

              {/* Left Description */}
              <Text size="large">
                The next few screens will cover everything that’s needed to add someone to your business.
                {/*<br />*/}
                {/*<br /> Visit the <HyperlinkButton>Help Center</HyperlinkButton> to learn more.*/}
              </Text>
            </div>

            {/* Left Button */}
            {/*<div*/}
            {/*  className="pv-x2-large width-full text-align-right"*/}
            {/*  style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}*/}
            {/*>*/}
            {/*  <PrimaryButton size="large" onClick={onStepNext}>*/}
            {/*    Next*/}
            {/*  </PrimaryButton>*/}
            {/*</div>*/}
          </Col>

          {/* Right Column */}
          <Col span={18} style={{ minHeight: 'calc(100vh - 142px)', maxWidth: '820px' }} className="pl-large">
            <div style={{ minHeight: 'calc(100vh - 262px)' }}>
              {/* <-- ! Right Content Starts here ! --> */}
              <div className="bg-white p-large bordered border-standard-gray rounded">
                <div className="mb-small">
                  <Text size="x2-large">General Information</Text>
                </div>
                <div className="mb-large">
                  <Text>These details will be used to check if the person is already in your organisation.</Text>
                </div>

                {/* Form starts here */}

                {/* First Name / Last Name */}
                <div className="flex-row flex-wrap pb-large">
                  <div className="pr-medium">
                    <SubTitle weight="regular">FIRST NAME</SubTitle>
                    <Form.Item className="width-full">
                      {getFieldDecorator('firstName', {
                        initialValue: newMemberData.firstName,
                        rules: [{ required: true, message: 'Please add a first name.' }],
                      })(<Input size="large" style={{ width: '248px' }} id="firstName" onChange={this.inputChange} />)}
                    </Form.Item>
                  </div>

                  <div>
                    <SubTitle weight="regular">LAST NAME</SubTitle>
                    <Form.Item className="width-full">
                      {getFieldDecorator('lastName', {
                        initialValue: newMemberData.lastName,
                        rules: [{ required: true, message: 'Please add a last name.' }],
                      })(<Input size="large" style={{ width: '248px' }} id="lastName" onChange={this.inputChange} />)}
                    </Form.Item>
                  </div>
                </div>

                {/* Email */}
                <div className="pb-large">
                  <SubTitle weight="regular">EMAIL ADDRESS</SubTitle>
                  <Form.Item className="width-full">
                    {getFieldDecorator('email', {
                      initialValue: newMemberData.email,
                      rules: [{ required: true, type: 'email', message: 'Please add a valid email.' }],
                    })(<Input size="large" style={{ width: '344px' }} id="email" onChange={this.inputChange} />)}
                  </Form.Item>
                </div>

                {/* Mobile Number */}
                <div className="pb-large">
                  <SubTitle weight="regular">MOBILE NUMBER</SubTitle>
                  <Form.Item className="width-full">
                    {getFieldDecorator('mobileNumber', {
                      initialValue: newMemberData.mobileNumber,
                      rules: [{ validator: this._validateMobileNumber }],
                    })(
                      <Input
                        size="large"
                        addonBefore={'+61(AU)'}
                        style={{ width: '344px' }}
                        id="mobileNumber"
                        onChange={this.inputChange}
                      />,
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>

            {/* Right Button */}
            <div
              className="pv-x2-large width-full text-align-right"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <PrimaryButton size="large" onClick={this._onNextStep}>
                Next
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setFuzzyMatchTeamMember: dispatch.teamStore.setFuzzyMatchTeamMember,
});

export default connect(null, mapDispatch)(Form.create<IStartStepPanelProps>()(StartStepPanel));
