import { createQueryKeys } from '@lukemorales/query-key-factory';

import type { inferQueryKeys } from '@lukemorales/query-key-factory';

export const budgetQueryKeys = createQueryKeys('budgets', {
  budget: (agreementId: string) => agreementId,
  budgets: (filters: unknown) => ({ filters }),
  customerBudget: (customerId: string) => customerId,
});

export type BudgetKeys = inferQueryKeys<typeof budgetQueryKeys>;
