import { forwardRef } from 'react';
import { Illustration } from './illustration';
import type { IllustrationProps } from './illustration';
import type { Ref } from 'react';
export const Shield2 = forwardRef(function Shield2(props: IllustrationProps, ref: Ref<SVGSVGElement>) {
  return (
    <Illustration {...props} ref={ref}>
      <path
        d="M120.32 38.484c-.609 6.958-1.141 14.036-1.868 21.096-.863 8.384-5.115 15.369-9.579 22.23-2.542 3.909-4.927 7.945-7.773 11.621-2.981 3.852-6.304 7.464-9.71 10.954-3.454 3.54-6.953 7.115-10.868 10.109-4.44 3.395-9.172 6.526-14.142 9.064-3.093 1.58-6.507-.283-9.435-1.768-5.502-2.788-9.833-7.106-14.282-11.296-8.865-8.347-16.622-17.624-22.953-27.962-3.364-5.49-6.014-11.491-8.348-17.509-1.549-3.992-2.294-8.381-2.806-12.662-.63-5.227-1.074-10.554-.788-15.795.308-5.635 2.79-10.44 8.448-12.773 8.57-3.536 17.259-6.793 25.74-10.524C47.45 10.853 52.663 7.804 58 5.034c2.425-1.26 4.947-1.568 7.559-.709 4.646 1.529 9.31 3.01 13.911 4.67 2.806 1.011 5.459 2.438 8.261 3.458 2.738.996 5.555 1.797 8.376 2.534 7.031 1.834 13.451 5.039 19.7 8.624.687.395 1.402 1.195 1.587 1.935 1.04 4.192 1.924 8.423 2.927 12.938Zm-3.717-.823c-.679-3.503-1.3-7.157-2.148-10.759-.209-.887-1.006-1.854-1.804-2.341-1.805-1.101-3.743-2.013-5.694-2.842-3.469-1.474-6.944-2.96-10.497-4.21-9.975-3.51-20.02-6.825-29.97-10.397-2.605-.935-4.63-.452-6.963.763-7.07 3.68-14.235 7.184-21.422 10.637-2.409 1.158-5.01 1.918-7.493 2.927-3.445 1.4-6.8 3.044-10.307 4.26-5.635 1.953-9.653 7.08-9.526 12.825.1 4.5.1 9.03.662 13.482.578 4.587 1.143 9.353 2.848 13.585 3.656 9.078 7.764 18.066 14.195 25.603 5.64 6.61 11.73 12.834 17.587 19.262 3.34 3.667 7.234 6.604 11.64 8.836 3.463 1.755 6.928 2.924 10.557.066 2.844-2.242 5.944-4.16 8.91-6.249 7.254-5.111 13.099-11.701 18.642-18.482 3.835-4.69 6.791-10.088 10.303-15.053 5.214-7.373 8.76-15.359 9.444-24.435.433-5.742.689-11.499 1.037-17.479l-.001.001Z"
        fill="currentColor"
      />
      <path
        d="M61.28 110.268c0-7.031-.01-14.062.002-21.093.017-10.036-.012-20.071.091-30.106.07-6.88.531-13.758.449-20.634-.066-5.45-.747-10.889-1.034-16.339-.105-1.97-.033-4.063 1.935-5.286.547-.34 1.48-.51 2.055-.288 11.512 4.446 22.926 9.182 35.032 11.866 2.858.633 5.773 1.013 8.663 1.505 3.124.532 3.968 1.26 3.616 4.392-.441 3.913-1.047 7.862-2.133 11.637-1.921 6.665-3.862 13.374-6.542 19.753-2.463 5.868-5.602 11.518-9.008 16.907-3.142 4.97-6.15 10.172-11.068 13.798-.802.591-1.374 1.526-1.963 2.365-4.11 5.843-10.074 9.774-15.142 14.626-1.177 1.125-2.986 2.291-4.475.997-.916-.796-.94-2.613-1.366-3.973l.887-.127Zm2.11 1.263c.925-.524 1.783-.807 2.363-1.374 4.796-4.691 9.552-9.424 14.288-14.176.663-.665 1.014-1.678 1.736-2.24 6.75-5.248 10.783-12.53 14.819-19.772 2.383-4.274 4.648-8.655 6.476-13.187 1.706-4.23 2.844-8.698 4.081-13.104.896-3.186 1.883-6.405 2.217-9.672.397-3.872.754-4.396-3.362-4.918-8.635-1.096-16.822-3.885-25.011-6.591-5.776-1.91-11.38-4.334-17.705-6.779 1.946 30.77.325 61.05.099 91.815v-.002Z"
        fill="currentColor"
      />
    </Illustration>
  );
});
