import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link } from '@good/components';
import { BreadcrumbItem, Breadcrumbs } from '@goodhuman-me/components';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@good/ui/core';

import { useSilServiceContext } from '../sil-service-provider/sil-service-provider';

export const SilServiceBreadcrumbs = () => {
  const { service, isLoading, isError } = useSilServiceContext();
  const { t } = useTranslation('', { keyPrefix: 'silService.breadcrumbs' });

  const serviceName = (() => {
    if (isLoading) {
      return 'Service';
    }

    if (isError) {
      return '-';
    }

    if (service) {
      return service.serviceName;
    }

    return '-';
  })();

  return (
    <Breadcrumbs>
      <BreadcrumbItem>
        <Link>
          <ReactRouterLink to='/services'>{t('service')}</ReactRouterLink>
        </Link>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <Skeleton visible={isLoading}>
          <span>{serviceName}</span>
        </Skeleton>
      </BreadcrumbItem>
    </Breadcrumbs>
  );
};
