import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ErrorSVG, SuccessSVG } from 'assets/UndrawSVG';
import { Paragraph } from 'common-components/typography';
import { Form, notification } from 'antd';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import BillingLineItemV2 from 'views/billings/components/BillingLineItemV2';
import { PaymentSources } from 'utilities/enum-utils';

type IEditLineItemActionModalProps = {
  isOpen: any;
  onClose: any;
  type?: string;
  selectedBillingLineItem: typeof state.billingsStore.selectedBillingLineItem;
  selectedFundedCategories: typeof state.billingsStore.selectedFundedCategories;
  selectedLineItemMmmData: typeof state.billingsStore.selectedLineItemMmmData;
  doUpdateLineItem: typeof dispatch.billingsStore.doUpdateLineItem;
  serviceAgreementServiceBillingLineItems: any;
  isNonBillable?: boolean;
  portalUser: typeof state.authStore.portalUser;
} & FormComponentProps;

class EditLineItemActionModal extends Component<IEditLineItemActionModalProps, any> {
  private lineItemsRef: any;

  state = {
    isLoading: false,
    isSuccess: false,
    isError: false,
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  private _setRef = (ref) => {
    if (ref) {
      this.lineItemsRef = ref;
    }
  };

  private _onSubmitEdit = () => {
    const { type = 'payment', isNonBillable = false } = this.props;
    const updatedBillingLineItem = this.lineItemsRef.getBillingLineItem();

    // Modify the structure before sending to API
    const newBillingLineItem = {
      ...updatedBillingLineItem,
      additionalCost: Number(updatedBillingLineItem.additionalCost || 0),
      discount: Number(updatedBillingLineItem.discount || 0),
      qty: Number(updatedBillingLineItem.qty),
      total: Number(
        updatedBillingLineItem.gstValue
          ? Number(updatedBillingLineItem.unitPrice) * updatedBillingLineItem.qty
          : updatedBillingLineItem.total,
      ),
      travelDistance: Number(updatedBillingLineItem.travelDistance),
      unitPrice: Number(updatedBillingLineItem.unitPrice),
      billingQty: Number(updatedBillingLineItem.billingQty),
      billingPrice: Number(updatedBillingLineItem.billingPrice),
      billingTotal: Number(
        updatedBillingLineItem.billingGstValue
          ? Number(updatedBillingLineItem.billingPrice) * updatedBillingLineItem.billingQty
          : updatedBillingLineItem.billingTotal,
      ),
      mileagePrice: Number(updatedBillingLineItem.mileagePrice),
      paymentMethod: updatedBillingLineItem.paymentMethod === null ? '' : updatedBillingLineItem.paymentMethod,
      supportType: updatedBillingLineItem.supportType === null ? '' : updatedBillingLineItem.supportType,
      isNonBillable: isNonBillable,
      isAutoGenerated: false,
    };
    if (newBillingLineItem.claimType === 'STD') newBillingLineItem.claimType = '';

    this.lineItemsRef.props.form.validateFieldsAndScroll(async (error) => {
      if (!error) {
        this.setState({ isLoading: true });
        try {
          const payload = { lineItem: newBillingLineItem, type };
          await this.props.doUpdateLineItem(payload);
          notification.success({
            message: 'Line item updated successfully.',
          });
          this.setState({ isLoading: false });
          this._onCloseModal();
        } catch (e) {
          notification.error({
            message: 'Oops, something went wrong. Please try again.',
          });
          this.setState({ isLoading: false });
          throw e;
        }
      }
    });
  };

  render() {
    const {
      isOpen,
      selectedBillingLineItem,
      selectedFundedCategories,
      selectedLineItemMmmData,
      serviceAgreementServiceBillingLineItems,
      portalUser,
    } = this.props;

    return (
      <ActionModal
        title="Edit Line Item"
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="x2-large"
        verticalAlignment="highest"
      >
        {!this.state.isLoading && !this.state.isSuccess && !this.state.isError && (
          <div className="text-align-left mb-large">
            <BillingLineItemV2
              billingSupportItems={[]}
              billingLineItem={{ ...selectedBillingLineItem, isEditing: true }}
              serviceBillingLineItems={[]}
              fundedCategories={selectedFundedCategories}
              travelDistance={10}
              showLineItemLabel={false}
              isRemoving={false}
              isCancelled={false}
              index={0}
              cancellationCode={null}
              cancellationReason={null}
              isEditPaymentLineItem={true}
              wrappedComponentRef={this._setRef}
              hasABTLineItems={false}
              hasPTNLCLineItems={false}
              isFilterOutTravelItem={false}
              mmmGroup={selectedLineItemMmmData.mmmGroup}
              locationState={selectedLineItemMmmData.locationState}
              serviceAgreementItems={serviceAgreementServiceBillingLineItems}
              isLineItemLocked={true}
              timezoneData={{ displayTimezone: portalUser?.timezone }}
              claimTravelData={{
                transportPriceBeforeBooking:
                  selectedBillingLineItem.serviceClaimConfig &&
                  selectedBillingLineItem.paymentSourceType === PaymentSources.NDIS
                    ? selectedBillingLineItem.serviceClaimConfig.ndisClaims &&
                      selectedBillingLineItem.serviceClaimConfig.ndisClaims.transportPriceBeforeBooking
                    : null,
                transportPriceDuringBooking:
                  selectedBillingLineItem.paymentSourceType === PaymentSources.NDIS
                    ? selectedBillingLineItem.serviceClaimConfig &&
                      selectedBillingLineItem.serviceClaimConfig.ndisClaims &&
                      selectedBillingLineItem.serviceClaimConfig.ndisClaims.transportPriceDuringBooking
                    : selectedBillingLineItem.serviceClaimConfig &&
                      selectedBillingLineItem.serviceClaimConfig.vcpClaims &&
                      selectedBillingLineItem.serviceClaimConfig.vcpClaims.transportPriceDuringBooking,
                travelDistanceBeforeBooking: selectedBillingLineItem.travelDistanceBeforeBooking,
                additionalCostBeforeBooking: selectedBillingLineItem.additionalCostBeforeBooking,
                travelDistanceDuringBooking: selectedBillingLineItem.travelDistanceDuringBooking,
                additionalCostDuringBooking: selectedBillingLineItem.additionalCostDuringBooking,
              }}
            />
          </div>
        )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading && this.state.isSuccess && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={SuccessSVG} alt={'Success'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>Line Item updated successfully.</Paragraph>
            </div>
          </div>
        )}

        {!this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <GhostButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this._onSubmitEdit} loading={this.state.isLoading}>
              Save
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {!this.state.isLoading && this.state.isError && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>An error has occured.</Paragraph>
            </div>
          </div>
        )}

        {this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem,
  selectedFundedCategories: state.billingsStore.selectedFundedCategories,
  selectedLineItemMmmData: state.billingsStore.selectedLineItemMmmData,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveBillingLineItem: dispatch.billingsStore.doRemoveBillingLineItem,
  doUpdateLineItem: dispatch.billingsStore.doUpdateLineItem,
});

export default connect(mapState, mapDispatch)(Form.create<IEditLineItemActionModalProps>()(EditLineItemActionModal));
