import { Inline, Stack, Text } from '@goodhuman-me/components';
import { Announce, Link } from 'design-components';
import React from 'react';

interface IProps {
  title: string;
  description: string;
  linkUrl: string;
}

const InfoWorkspacePermission: React.FC<IProps> = ({ title, description, linkUrl }) => {
  return (
    <Stack width="300px" padding="$space200" alignItems="flex-start">
      <Inline alignItems="center">
        <Announce marginRight="$space125" color="$white" width="$size250" height="$size250" />
        <Text fontWeight="bold" fontSize="$font75 !important">
          {title}
        </Text>
      </Inline>
      <Stack paddingLeft="30px" paddingTop="$space100" alignItems="flex-start">
        <Text fontWeight="$normal" fontSize="$font50 !important">
          {description}
        </Text>
        <Link
          href={linkUrl}
          size="small"
          kind="white"
          underline
          fontWeight="bold"
          fontSize="$font75 !important"
          marginTop="$space100 !important"
        >
          Learn more
        </Link>
      </Stack>
    </Stack>
  );
};

export default InfoWorkspacePermission;
