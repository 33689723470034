import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import RadioGroup from 'antd/lib/radio/group';
import clsx from 'clsx';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { Text } from 'common-components/typography';
import { IFilter } from 'interfaces/filter-interfaces';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import CommonUtils from 'utilities/common-utils';
import { BillableDisplayType, FilterType } from 'utilities/enum-utils';

interface IFilterMenuRadioListProps {
  filter: IFilter;
  canRemove: boolean;
  saveNewFilterValue: (filterType, newFilterValue, selectionLabel) => void;
  removeFilter: (filterType: FilterType | string) => void;
}

interface ILocalItem {
  displayText: string;
  value: string;
  isHidden: boolean;
}

const FilterMenuRadioList = ({ filter, canRemove, saveNewFilterValue, removeFilter }: IFilterMenuRadioListProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [localItemList, setLocalItemList] = useState<ILocalItem[]>([]);
  const [localSelectedItem, setLocalSelectedItem] = useState<string>(filter.values);

  const getItemList = (filterType: FilterType | string) => {
    switch (filterType) {
      case FilterType.BOOKING_BILLING:
        return [
          {
            displayText: 'Only show bookings with non-billable items',
            value: BillableDisplayType.NON_BILLABLE,
            isHidden: false,
          },
          {
            displayText: 'Only show bookings with billable items',
            value: BillableDisplayType.BILLABLE,
            isHidden: false,
          },
        ];
      case FilterType.PAID_BILLING:
        return [
          {
            displayText: 'Only show non-billable invoices',
            value: BillableDisplayType.NON_BILLABLE,
            isHidden: false,
          },
          {
            displayText: 'Only show billable invoices',
            value: BillableDisplayType.BILLABLE,
            isHidden: false,
          },
        ];
      case FilterType.BATCH_BILLING:
        return [
          {
            displayText: 'Only show billable batches',
            value: BillableDisplayType.BILLABLE,
            isHidden: false,
          },
          {
            displayText: 'Only show non-billable batches',
            value: BillableDisplayType.NON_BILLABLE,
            isHidden: false,
          },
        ];
      default:
        return;
    }
  };

  const onClearSelectionAction = () => {
    setIsLoading(false);
    setIsSearching(false);
    setLocalItemList(null);
    setLocalSelectedItem(null);
  };

  const onClosePopover = () => {
    setIsSearching(false);
    setLocalSelectedItem('');
  };

  const onApplySelection = () => {
    if (_.isEmpty(localSelectedItem)) {
      onClosePopover();
      return;
    }
    saveNewFilterValue(
      filter.filter,
      localSelectedItem,
      CommonUtils.getFilterText(filter.filter, [localSelectedItem], localItemList),
    );
  };

  const onChangeRadioValue = (event: RadioChangeEvent) => {
    setLocalSelectedItem(event.target.value);
  };

  useEffect(() => {
    const newLocalItemList = getItemList(filter.filter);
    setLocalItemList(newLocalItemList);
    setIsLoading(false);
  }, []);

  const filterSettings = CommonUtils.getFilterSettings(filter.filter);
  const filteredList = localItemList.filter((lineItem) => !lineItem.isHidden);

  return (
    <div style={{ width: '450px' }}>
      <div className="p-medium bordered-bottom border-standard-gray">
        <Text size="x-large" weight="bold">
          {filterSettings.title}
        </Text>
      </div>
      <div style={{ maxHeight: '200px', overflow: 'auto' }} className="bordered-bottom border-standard-gray">
        {isLoading ? (
          <SpinningLoader size={25} message="" />
        ) : filteredList?.length > 0 ? (
          <RadioGroup onChange={onChangeRadioValue} value={localSelectedItem} className="width-full">
            {localItemList.map((item) => {
              const { displayText: formatedLabel } = item;
              return (
                <div
                  className={clsx('hover-bg-tertiary', 'cursor-pointer', item.isHidden && 'hide')}
                  key={item.value.toString()}
                >
                  <Radio value={item.value} className="width-full pv-x-small ph-medium flex align-center">
                    {formatedLabel}
                  </Radio>
                </div>
              );
            })}
          </RadioGroup>
        ) : (
          <div className="pv-x-small ph-medium">No {filterSettings.title} found</div>
        )}
      </div>
      <div className="p-medium">
        <div className="flex-row justify-between">
          <div>
            {canRemove && (
              <GhostButton color="red" onClick={() => removeFilter(filter.filter)}>
                Remove this filter
              </GhostButton>
            )}
          </div>
          <div>
            <SecondaryButton color="blue-action" className="mr-medium bp3-popover-dismiss" onClick={onClosePopover}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              color="blue-action"
              className="bp3-popover-dismiss border-blue-action"
              onClick={onApplySelection}
            >
              Apply
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterMenuRadioList;
