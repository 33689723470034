import { Empty } from 'antd';
import { Text } from 'common-components/typography';
import React, { PureComponent } from 'react';

export default class WorkflowListingEmptyState extends PureComponent {
  render() {
    return (
      <div className="flex-1 bg-white  align-center flex-column">
        <div className="">
          <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
        <Text size="x2-large" color="secondary" weight="bold">
          No incidents found.
        </Text>
        <br /> <br />
        <Text color="secondary">All incidents under this filter will appear here.</Text>
        <Text color="secondary">Try adjusting your filter, or clicking on another tab.</Text>
      </div>
    );
  }
}
