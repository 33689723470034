import { Radio, Col, Row, Input } from 'antd';
import React from 'react';
import { Text, SubTitle } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import { IFormElement } from 'views/form-builder/shared/form-interface';
import {
  convertRulesRequiredOfBundle,
  getRules,
  getValueFromPropertyByKey,
} from 'views/form-builder/shared/form-builder-utils';
import FormItem from 'antd/es/form/FormItem';
import { FormElementType } from 'views/form-builder/shared/form-enum';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';

const RadioGroup = Radio.Group;
interface ISingleChoiceProps {
  options: any[];
  defaultValue: any;
  title: string;
  caption: string;
  isInCreate?: boolean;
  isRequired?: boolean;
}
function SingleChoiceElement({ options, defaultValue, title, caption, isInCreate, isRequired }: ISingleChoiceProps) {
  const fieldTextFormat = (
    <>
      {title}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );

  return (
    <div className="flex-column width-full">
      <SubTitle>{fieldTextFormat}</SubTitle>
      <RadioGroup className="pt-small" value={defaultValue}>
        {options.map((option) => {
          const value = option?.value ?? option;
          const name = option?.name ?? option;

          return (
            <Row type="flex">
              <Col
                span={24}
                className={`p-medium mb-small text-size-x2-large bordered border-secondary rounded 
                  ${defaultValue === value && !isInCreate ? 'bg-blue-action-lightest' : 'bg-white'} `}
              >
                <Radio value={value}>{name}</Radio>
              </Col>
            </Row>
          );
        })}
      </RadioGroup>
      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
}

export default SingleChoiceElement;

interface IEditSingleChoiceElementProps extends FormComponentProps {
  value?: string;
  element: IFormElement;
  onChange?: (value) => void;
}

export const EditSingleChoiceElement = ({ value, element, form, onChange }: IEditSingleChoiceElementProps) => {
  const { elementsBundleOptional } = useSelector((state: IRootState) => state.formBuilderStore);
  const { id, parentId } = element;
  const options = getValueFromPropertyByKey(element.properties.configuration, 'options');
  const caption = getValueFromPropertyByKey(element.properties.appearance, 'captionText');
  let rules = getRules(element, false);
  const { getFieldDecorator } = form;
  const isRequired = getValueFromPropertyByKey(element.properties.general, 'require');
  let title = getValueFromPropertyByKey(element.properties.general, 'fieldTitle');

  if (isRequired) {
    const checkRequiredBundle = !elementsBundleOptional.includes(id);
    rules = convertRulesRequiredOfBundle(checkRequiredBundle, rules);

    title = (
      <>
        {title}
        {checkRequiredBundle && <Text color="red"> *</Text>}
      </>
    );
  }

  return (
    <div className="flex-column width-full">
      <SubTitle>{title}</SubTitle>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'value'] as never, {
          initialValue: value,
          rules: rules,
        })(
          <RadioGroup
            className="pt-small width-full"
            onChange={(e) => (typeof onChange === 'function' ? onChange(e.target.value) : null)}
          >
            {options.map((option) => {
              const value = option?.value ?? option;
              const name = option?.name ?? option;

              return (
                <Row type="flex">
                  <Col
                    span={24}
                    className="p-medium mb-small text-size-x2-large bordered border-secondary rounded bg-white"
                  >
                    <Radio value={value}>{name}</Radio>
                  </Col>
                </Row>
              );
            })}
          </RadioGroup>,
        )}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'type'] as never, {
          initialValue: FormElementType.SINGLE_CHOICE,
        })(<Input type="hidden" />)}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'parentElementId'] as never, {
          initialValue: parentId,
        })(<Input type="hidden" />)}
      </FormItem>
      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
};
