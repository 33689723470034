import React, { Component } from 'react';
import { Col, Form, Icon, Row, Tabs, Checkbox } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatch, state } from 'stores/rematch/root-store';
import NDISSettingsStepPanel from './NDISSettingsStepPanel';
import VCPSettingsStepPanel from './VCPSettingsStepPanel';
const { TabPane } = Tabs;

interface IPaymentSettingsStepPanelProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
}

interface IPaymentSettingsStepPanelState {
  isOpen: boolean;
  step: number;
}

class PaymentSettingsStepPanel extends Component<IPaymentSettingsStepPanelProps, IPaymentSettingsStepPanelState> {
  state = {
    step: 1,
    isOpen: false,
  };

  private _onClickNext = () => {
    const { step } = this.state;
    const { newService, onNextStep } = this.props;
    if (step === 1) {
      const hasVCP = _.find(newService.paymentSources, (source) => source === 'VCP');
      if (hasVCP) {
        this.setState({
          step: 2,
        });
      } else {
        onNextStep();
      }
    } else {
      onNextStep();
    }
  };

  private _onClickPrevious = () => {
    const { step } = this.state;
    const { newService, onPreviousStep } = this.props;
    if (step === 2) {
      const hasNDIS = _.find(newService.paymentSources, (source) => source === 'NDIS');
      if (hasNDIS) {
        this.setState({
          step: 1,
        });
      } else {
        onPreviousStep();
      }
    } else {
      onPreviousStep();
    }
  };

  componentDidMount() {
    const { newService } = this.props;
    const hasNDIS = _.find(newService.paymentSources, (source) => source === 'NDIS');
    const hasVCP = _.find(newService.paymentSources, (source) => source === 'VCP');
    if (hasNDIS) {
      this.setState({
        step: 1,
      });
    } else if (hasVCP) {
      this.setState({
        step: 2,
      });
    }
  }

  render() {
    const { step } = this.state;
    const { onNextStep, onPreviousStep, ...props } = this.props;

    return (
      <>
        {step === 1 && (
          <NDISSettingsStepPanel {...props} onNextStep={this._onClickNext} onPreviousStep={this._onClickPrevious} />
        )}
        {step === 2 && (
          <VCPSettingsStepPanel {...props} onNextStep={this._onClickNext} onPreviousStep={this._onClickPrevious} />
        )}
      </>
    );
  }
}

export default PaymentSettingsStepPanel;
