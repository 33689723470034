import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Form, Heading, StepContainer } from '../components';
import { PrimaryButton } from '../../../../../common-components/buttons';
import { StepProps } from '../create-workflow';
import { WorkflowNameForm } from '../../forms/workflow-name';
import { fetchCheckWorkflowNameExists } from '../services';
import { useCreateWorkflow } from '../create-workflow-provider';

import type { DetailsPayload } from '../create-workflow-provider';

export function WorkflowName(props: StepProps) {
  let { onNext } = props;
  let [isLoading, setLoading] = useState(false);
  let { state, submitDetails } = useCreateWorkflow();
  let formMethods = useForm({
    defaultValues: {
      description: state.details.description,
      name: state.details.name,
      triggerType: state.details.triggerType,
    },
  });
  let { handleSubmit, setError } = formMethods;

  function toggleLoading() {
    setLoading((l) => !l);
  }

  async function handleOnSubmit(data: DetailsPayload) {
    toggleLoading();
    let { name } = data;
    let doesExist = await fetchCheckWorkflowNameExists(name);
    toggleLoading();

    if (doesExist) setError('name', { message: 'Name is already in use.' });
    else {
      submitDetails(data);
      onNext();
    }
  }

  return (
    <>
      <StepContainer>
        <Heading>Workflow details</Heading>

        <FormProvider {...formMethods}>
          <Form id="name-workflow" onSubmit={handleSubmit(handleOnSubmit)}>
            <WorkflowNameForm />
          </Form>
        </FormProvider>
      </StepContainer>

      <div style={{ alignSelf: 'end' }}>
        <PrimaryButton htmlType="submit" form="name-workflow" loading={isLoading}>
          Next
        </PrimaryButton>
      </div>
    </>
  );
}
