import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Icon, Tooltip } from 'antd';
import {
  CustomerSupportWorkerPreference,
  ShiftSlotStatus,
  PublishShiftApplicationAvailability,
  PublishShiftApplicationStatus,
} from 'utilities/enum-utils';

export default class WorkerStatusTag extends Component<{
  shiftSlotStatus: string;
  size?: string;
  preference?: string;
  tag?: string;
  icon?: string;
  color?: string;
  tooltip?: string;
}> {
  render() {
    const { shiftSlotStatus, size, preference } = this.props;

    let tag = '';
    let icon = '';
    let color = '';
    let tooltip = '';
    let isIconOutlined = false;

    const fontSize = size ?? 'large';
    if (preference === CustomerSupportWorkerPreference.BLOCKED) {
      tag = 'Blocked';
      icon = 'close-circle';
      color = 'red';
      tooltip = 'This worker has been blocked by the customer and cannot be selected for bookings';
    } else if (shiftSlotStatus === ShiftSlotStatus.PENDING) {
      tag = 'Awaiting worker confirmation';
      icon = 'clock-circle';
      color = 'blue';
    } else if (shiftSlotStatus === ShiftSlotStatus.CONFIRMED) {
      tag = 'Worker confirmed';
      icon = 'check';
      color = 'green';
    } else if (shiftSlotStatus === PublishShiftApplicationAvailability.AVAILABLE) {
      tag = 'Available';
      icon = 'check-circle';
      color = 'green';
    } else if (shiftSlotStatus === PublishShiftApplicationAvailability.NOT_AVAILABLE) {
      tag = 'Not available';
      icon = 'close-circle';
      color = 'red';
    } else if (shiftSlotStatus === PublishShiftApplicationStatus.APPLIED) {
      tag = 'Applied';
      icon = 'check-circle';
      color = 'indigo';
    } else if (shiftSlotStatus === PublishShiftApplicationStatus.UNSUCCESSFUL) {
      tag = 'Unsuccessful';
      icon = 'close-circle';
      color = 'orange';
    } else if (shiftSlotStatus === PublishShiftApplicationAvailability.OUTSIDE_GENERAL_AVAILABILITY) {
      tag = 'Outside general availability';
      icon = 'question-circle';
      color = 'orange';
    } else if (shiftSlotStatus === PublishShiftApplicationAvailability.UNAVAILABLE_CAN_BE_OVERRIDDEN) {
      tag = 'Unavailable (can override)';
      icon = 'close-circle';
      color = 'red';
      tooltip =
        'This booking occurs during a time where the team member has indicated they are unavailable. You can still assign them to this shift but ensure you have checked with them before doing so';
    } else if (shiftSlotStatus === PublishShiftApplicationAvailability.SHIFT_CLASH) {
      tag = 'Shift clash (can override)';
      icon = 'close-circle';
      color = 'red';
      tooltip =
        'This team member has a shift at a conflicting time, but you can override this conflict and assign the user to this booking if they apply';
      isIconOutlined = true;
    } else if (shiftSlotStatus === PublishShiftApplicationAvailability.UNAVAILABLE) {
      tag = 'Unavailable';
      icon = 'close-circle';
      color = 'red';
      tooltip =
        'This team member is listed as unavailable during this time, but you can override this unavailability and assign the user to this booking if they apply';
    }

    tag = this.props.tag || tag;
    icon = this.props.icon || icon;
    color = this.props.color || color;
    tooltip = this.props.tooltip || tooltip;

    return (
      <Text size={fontSize} color="secondary">
        {tooltip ? (
          <>
            <Tooltip title={tooltip}>
              <div>
                <Icon
                  type={icon}
                  className={`text-color-${color} mr-x-small`}
                  theme={isIconOutlined ? 'outlined' : 'filled'}
                />
                {tag}
              </div>
            </Tooltip>
          </>
        ) : (
          <>
            <Icon type={icon} className={`text-color-${color} mr-x-small`} />
            {tag}
          </>
        )}
      </Text>
    );
  }
}
