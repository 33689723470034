import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FileSlash = forwardRef(function FileSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.53 1.47a.75.75 0 0 0-1.06 1.06l3 3 14 14 3 3a.75.75 0 1 0 1.06-1.06l-2.78-2.78V9.342c0-1.088 0-1.844-.285-2.535-.287-.692-.822-1.226-1.591-1.995l-.101-.1-.485-.486-.101-.1c-.769-.77-1.303-1.305-1.995-1.592-.691-.286-1.447-.286-2.535-.285H7c-1 0-1.876.534-2.357 1.332L2.53 1.47Zm15.72 8.015v7.704L5.781 4.721A1.25 1.25 0 0 1 7 3.75h5.515c.283 0 .526 0 .735.003v.299c0 .899 0 1.648.08 2.243.084.627.27 1.194.726 1.65.455.455 1.022.64 1.65.725.594.08 1.343.08 2.242.08h.299c.002.21.003.452.003.735Zm-.233-2.235c-.174-.332-.5-.673-1.305-1.477l-.485-.485c-.804-.805-1.145-1.131-1.477-1.305V4c0 .964.002 1.612.066 2.095.063.461.17.659.3.789.13.13.328.237.79.3.482.064 1.13.066 2.094.066h.017ZM5.75 15V9a.75.75 0 0 0-1.5 0v6.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.601 1.36.86 2.26.981.867.117 1.97.117 3.337.117H16a.75.75 0 0 0 0-1.5h-5c-1.435 0-2.437-.002-3.192-.103-.734-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191Z"
        fill="currentColor"
      />
    </Icon>
  );
});
