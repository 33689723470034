import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '@good/components';

import { Modal, TextField } from 'design-components';
import { IWorkLeaveType } from 'interfaces/account-interfaces';
import { useMutationUpdateWorkType } from 'stores/hooks/query-hooks/use-mutation-update-work-type';
import { Popover } from '../components/';
import { WorkLeaveTypeSchema } from './schema';
import { WorkTypeCategory } from 'utilities/enum-utils';
import { useMutationCreateWorkType } from 'stores/hooks/query-hooks/use-mutation-create-work-type';
import useValidateDuplicateExternalId from './use-validate-duplicate-external-id';

export const testId = 'edit-work-leave-type-modal-form';

type IEditWorkLeaveTypeModalProps = {
  workLeaveType: IWorkLeaveType | null;
  onClose: () => void;
  isOpen: boolean;
};

const EditWorkLeaveTypeModal = ({
  workLeaveType,
  onClose: onCloseProp,
  isOpen = false,
}: IEditWorkLeaveTypeModalProps): JSX.Element => {
  const type: 'create' | 'edit' = workLeaveType?.workTypeId == null ? 'create' : 'edit';

  const keyPrefix = `accountManagement.workLeaveTypesPage.modal.${type}.${
    workLeaveType?.category ?? WorkTypeCategory.WorkType
  }`;
  const { t, i18n } = useTranslation('', { keyPrefix });

  const createWorkType = useMutationCreateWorkType(workLeaveType?.category);
  const updateWorkType = useMutationUpdateWorkType(workLeaveType?.category);

  const validateDuplicateExternalId = useValidateDuplicateExternalId(workLeaveType?.workTypeId);
  const form = useForm<WorkLeaveTypeSchema>({ mode: 'all' });
  const isWorkType = workLeaveType?.category === WorkTypeCategory.WorkType;
  const hasValidationErrors = Object.keys(form.formState.errors).length > 0;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const resetForm = useCallback(() => {
    form.reset({
      name: workLeaveType?.name ?? '',
      workTypeExternalId: workLeaveType?.workTypeExternalId ?? '',
    });

    if (type === 'create') {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [form, type, workLeaveType?.name, workLeaveType?.workTypeExternalId]);

  useEffect(() => {
    resetForm();
  }, [workLeaveType, resetForm]);

  form.watch(() => {
    if (type === 'create' && form.getFieldState('name').isDirty) {
      setIsButtonDisabled(false);
    }
  });

  const onSubmit = (values: WorkLeaveTypeSchema) => {
    if (workLeaveType != null) {
      type === 'create'
        ? createWorkType.mutate({
            name: values.name,
            workTypeExternalId: values.workTypeExternalId,
            workTypeCategory: workLeaveType.category,
          })
        : updateWorkType.mutate({
            workTypeId: workLeaveType.workTypeId,
            name: values.name,
            workTypeExternalId: values.workTypeExternalId,
          });
    }

    onCloseProp();
  };

  const onClose = () => {
    resetForm();
    onCloseProp();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    void (async () => await form.handleSubmit(onSubmit)(e))();
  };

  return (
    <Modal
      maxWidth={600}
      header={
        <Text className='font-bold' size='md'>
          {t('heading')}
        </Text>
      }
      isOpen={isOpen}
      onClose={onClose}
      isDismissable={false}
      id='edit-work-leave-type-modal'
      bodyStyles={{ overflowY: 'unset', position: 'relative', padding: 16 }}
      footer={
        <form onSubmit={handleSubmit}>
          <div className='p-small'>
            <Button emphasis='quiet' tone='neutral' className='mr-3' onPress={onClose}>
              {t('cancel')}
            </Button>
            <Button emphasis='fill' type='submit' isDisabled={hasValidationErrors || isButtonDisabled}>
              {t('confirm')}
            </Button>
          </div>
        </form>
      }
    >
      <form data-testid={testId}>
        <div className='flex-column flex' style={{ gap: 24 }}>
          {i18n.exists(`${keyPrefix}.support`) && <Text>{t('support')}</Text>}
          <div className='flex-column flex' style={{ gap: 6 }}>
            <div className='flex-column flex'>
              <Text className='font-bold'>{t('name.label')}</Text>
              {i18n.exists(`${keyPrefix}.name.sub`) && <Text className='text-weak'>{t('name.sub')}</Text>}
            </div>
            <Controller
              name='name'
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Name is required',
                },
                maxLength: 256,
              }}
              render={({ field: { onChange, value, onBlur, ref }, fieldState: { error } }) => (
                <div className='flex-column flex gap-1'>
                  <TextField
                    padding={10}
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    ref={ref}
                    borderColor={error ? '$red' : ''}
                    isDisabled={isWorkType && type === 'edit'}
                    aria-label='name'
                  />
                  {error && <Text className='text-critical'>{error.message}</Text>}
                </div>
              )}
            />
          </div>
          <div className='flex-column flex' style={{ gap: 6 }}>
            <div className='flex gap-1'>
              <Text className='font-bold'>{t('externalId.label')}</Text>
              <Text className='text-weak' size='md'>
                <span className='align-text-bottom' style={{ fontSize: 12 }}>
                  {t('externalId.optional')}
                </span>
              </Text>
              <Popover content={t('externalId.popover.content')} />
            </div>
            <Controller
              name='workTypeExternalId'
              control={form.control}
              rules={{
                maxLength: 256,
                validate: {
                  validateDuplicateExternalId,
                },
              }}
              render={({ field: { onChange, value, onBlur, ref }, fieldState: { error } }) => (
                <div className='flex-column flex gap-1'>
                  <TextField
                    padding={10}
                    onChange={onChange}
                    value={value ?? ''}
                    onBlur={onBlur}
                    ref={ref}
                    borderColor={error ? '$red' : ''}
                    aria-label='workTypeExternalId'
                  />
                  {error && <Text className='text-critical'>{error.message}</Text>}
                </div>
              )}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditWorkLeaveTypeModal;
