import { ProgressBar } from '@blueprintjs/core';
import { Col, Icon, Row, Skeleton } from 'antd';
import { GridHeader, GridRow } from 'common-components/grids';
import WorkflowStatusTag from 'common-components/tags/WorkflowsStatusTag';
import { SubTitle, Text } from 'common-components/typography';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { IWorkflowListItem } from 'src/interfaces/workflow-interfaces';
import WorkflowListingEmptyState from './WorkflowListingEmptyState';
import { InfiniteScroll } from 'components';

interface IWorkflowListItemProps {
  isLoading: boolean;
  isLoadingInfiniteScrolling: boolean;
  workflows: IWorkflowListItem[];
  page: number;
  pageSize: number;
  timezone: timeZone;
  onFetchMore(): void;
  onClickRow(workflow: IWorkflowListItem): void;
}

class WorkflowListingItem extends PureComponent<IWorkflowListItemProps> {
  render() {
    const { isLoading, isLoadingInfiniteScrolling, workflows, page, pageSize, timezone, onFetchMore, onClickRow } =
      this.props;

    return (
      <div className='mt-large'>
        <GridHeader bordered containerClassName='border-secondary'>
          <Col span={5}>
            <SubTitle containerClassName='line-height-135'>Date</SubTitle>
          </Col>
          <Col span={6}>
            <SubTitle containerClassName='line-height-135'>Unique ID</SubTitle>
          </Col>
          <Col span={6}>
            <SubTitle containerClassName='line-height-135'>Workflow</SubTitle>
          </Col>
          <Col span={6}>
            <SubTitle containerClassName='line-height-135'>STATUS</SubTitle>
          </Col>
        </GridHeader>

        {isLoading ? (
          <div>
            <div className='pv-large'>
              <ProgressBar />
            </div>
            <Skeleton active title={true} paragraph={{ rows: 1 }} />
            <Skeleton active title={true} paragraph={{ rows: 1 }} />
            <Skeleton active title={true} paragraph={{ rows: 1 }} />
          </div>
        ) : !_.isEmpty(workflows) ? (
          <InfiniteScroll hasMore={workflows.length >= page * pageSize} loadMore={onFetchMore}>
            {workflows.map((workflow, index) => {
              return (
                <GridRow
                  containerClassName={`${
                    workflow.isFlagged ? 'bg-red-lightest' : 'bg-white'
                  } hover-bg-quaternary manage-workflow-listing-row`}
                  key={index}
                  onClick={() => onClickRow(workflow)}
                >
                  <Col span={5}>
                    <Text weight='bolder' size='regular'>
                      {moment.tz(workflow.createdOn, timezone).fromNow()}
                    </Text>
                    <Row>
                      <Text weight='regular' size='regular'>
                        {moment.tz(workflow.createdOn, timezone).format('hh:mm A, D MMMM YYYY')}
                      </Text>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Text>{workflow.referenceId}</Text>
                  </Col>
                  <Col span={6}>
                    <Text size='regular'>{workflow.name}</Text>
                  </Col>
                  <Col span={6}>
                    <Row type='flex' align='middle'>
                      <Row type='flex' align='middle' className='flex-1'>
                        <WorkflowStatusTag status={workflow.status} />

                        {workflow.isFlagged && (
                          <div className='ml-medium'>
                            <Icon type='flag' className='mr-12 text-color-red' theme='filled' />
                            <Text className='text-weight-regular' size='x-small'>
                              Issue flagged
                            </Text>
                          </div>
                        )}
                      </Row>
                    </Row>
                  </Col>
                </GridRow>
              );
            })}
          </InfiniteScroll>
        ) : (
          <WorkflowListingEmptyState />
        )}

        {isLoadingInfiniteScrolling && (
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
        )}
      </div>
    );
  }
}

export default WorkflowListingItem;
