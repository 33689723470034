import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Lifebuoy = forwardRef(function Lifebuoy(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.213 8.153 6.721 5.66A8.214 8.214 0 0 1 12 3.75c2.008 0 3.848.717 5.28 1.91l-2.494 2.493A4.728 4.728 0 0 0 12 7.25a4.729 4.729 0 0 0-2.787.903Zm-1.06 1.06L5.66 6.721A8.214 8.214 0 0 0 3.75 12c0 2.008.717 3.848 1.91 5.28l2.493-2.494A4.728 4.728 0 0 1 7.25 12c0-1.041.335-2.004.903-2.787Zm1.06 6.634L6.721 18.34A8.214 8.214 0 0 0 12 20.25a8.214 8.214 0 0 0 5.28-1.91l-2.494-2.493A4.728 4.728 0 0 1 12 16.75a4.729 4.729 0 0 1-2.787-.903Zm6.634-1.06 2.493 2.492A8.214 8.214 0 0 0 20.25 12a8.214 8.214 0 0 0-1.91-5.28l-2.493 2.493c.568.783.903 1.746.903 2.787a4.728 4.728 0 0 1-.903 2.787ZM12 2.25a9.722 9.722 0 0 0-6.894 2.856A9.722 9.722 0 0 0 2.25 12a9.722 9.722 0 0 0 2.856 6.894A9.722 9.722 0 0 0 12 21.75a9.722 9.722 0 0 0 6.894-2.856A9.722 9.722 0 0 0 21.75 12a9.722 9.722 0 0 0-2.856-6.894A9.722 9.722 0 0 0 12 2.25ZM8.75 12a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
