import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Lightning = forwardRef(function Lightning(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.217 1.282A.75.75 0 0 1 14.75 2v7.25H19a.75.75 0 0 1 .624 1.166l-8 12A.75.75 0 0 1 10.25 22v-7.25H6a.75.75 0 0 1-.624-1.166l8-12a.75.75 0 0 1 .841-.302ZM7.401 13.25h3.6a.75.75 0 0 1 .75.75v5.523l5.848-8.773H14a.75.75 0 0 1-.75-.75V4.477L7.401 13.25Z"
        fill="currentColor"
      />
    </Icon>
  );
});
