import { Empty } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';

const ListingEmptyState = () => {
  return (
    <div className="flex-1 bg-white mt-x2-large align-center flex-column">
      <div className="">
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
      </div>
      <Text size="x2-large" color="secondary" weight="bold">
        No invoices found
      </Text>{' '}
      <br /> <br />
      <Text color="secondary">All your invoices from GoodHuman will appear here.</Text>
    </div>
  );
};

export default ListingEmptyState;
