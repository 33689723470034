import React, { Component } from 'react';
import { Button, Col, Row } from 'antd';

interface GridCollapsibleRowProps {
  bordered?: boolean;
  style?: object;
  containerClassName?: string;
  children?: React.ReactNode;
  [prop: string]: any;
}

class GridCollapsibleRow extends Component<GridCollapsibleRowProps> {
  static defaultProps = {
    containerClassName: '',
  };

  state = {
    isExpand: false,
  };

  _onClickExpand = () => {
    this.setState({ isExpand: !this.state.isExpand });
  };

  render() {
    const { containerClassName } = this.props;
    const { isExpand } = this.state;
    const boxClassName = isExpand ? 'ph-medium shadow-without-top' : 'ph-medium';

    return (
      <div className={`bordered-bottom pv-medium ${containerClassName}`}>
        <div className={boxClassName}>
          <Row gutter={32}>
            {this.props.children}
            <Col span={1}>
              <Button
                icon={isExpand ? 'down' : 'right'}
                type={'link'}
                style={{ float: 'right' }}
                onClick={this._onClickExpand}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default GridCollapsibleRow;
