import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { Col, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FormStatus } from 'utilities/enum-utils';

interface IArchiveActiveFormModalProps {
  statusForm?: string;
  isArchive: boolean;
  isOpen: boolean;
  isInuse?: boolean; //todo: TBD after having the workflow req
  onClose: () => void;
  onArchiveOrActivate: () => void;
}
//todo: implement check is inuse workflow and add 1 content & title related
export const ArchiveActiveFormModal = ({
  statusForm,
  isArchive,
  isOpen,
  isInuse,
  onClose,
  onArchiveOrActivate,
}: IArchiveActiveFormModalProps) => {
  const [inUseWorkflows, setInUseWorkflows] = useState<string[]>(null);

  const title = statusForm === FormStatus.DRAFT ? 'Active Form' : isArchive ? 'Archive Form' : 'Re-active Form';

  useEffect(() => {
    //todo: fetch in use workflows if isArchive && isInuse is true
    // if(isInuse){
    //   const workflows = doGetFormInUseWorkflows({formId});
    //   setInUseWorkflows(workflows);
    // }
  }, [isInuse]);

  return (
    <ActionModal isOpen={isOpen} title={title} onClose={onClose}>
      {statusForm === FormStatus.DRAFT ? (
        <>
          <Paragraph>Are you sure you want to active this form?</Paragraph>
        </>
      ) : isArchive && isInuse && inUseWorkflows !== null ? (
        <>
          <Paragraph>The form form is currently being used by the following workflows</Paragraph>
          <Paragraph>
            {inUseWorkflows.map((workflow) => {
              //todo: TBD after having the workflow req
              return <Text weight="bolder">{workflow}</Text>;
            })}
          </Paragraph>
          <Paragraph>
            If you select to archive this form the following workflows will have the form removed and a new version of
            the workflow will be created.
          </Paragraph>
          <Paragraph>Are you sure you want to do this?</Paragraph>
        </>
      ) : isArchive ? (
        <>
          <Paragraph>Are you sure you want to archive this form?</Paragraph>
          <Paragraph>
            You will no longer be able to attach the form to workflows or other areas of the workspace.
          </Paragraph>
        </>
      ) : (
        <>
          <Paragraph>This form has been archived. Are you sure you want to activate it again?</Paragraph>
        </>
      )}

      <ActionModalFooter className={'mt-large '}>
        <Row type={'flex'} className={'justify-end'}>
          <Col className="mr-medium">
            <SecondaryButton size="large" onClick={onClose}>
              Cancel
            </SecondaryButton>
          </Col>
          <Col>
            <PrimaryButton onClick={onArchiveOrActivate} size="large">
              {statusForm === FormStatus.DRAFT || !isArchive ? 'Activate form' : 'Archive'}
            </PrimaryButton>
          </Col>
        </Row>
      </ActionModalFooter>
    </ActionModal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveActiveFormModal);
