import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const StopwatchSlash = forwardRef(function StopwatchSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 .25a.75.75 0 1 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4ZM3.53 3.47a.75.75 0 0 0-1.06 1.06l2.126 2.126A9.714 9.714 0 0 0 2.25 13c0 5.385 4.365 9.75 9.75 9.75a9.714 9.714 0 0 0 6.344-2.346l2.126 2.126a.75.75 0 0 0 1.06-1.06l-2.636-2.636-6.364-6.364-6.364-6.364L3.53 3.47ZM3.75 13c0-2.008.717-3.848 1.91-5.28l5.81 5.81 5.81 5.81A8.25 8.25 0 0 1 3.75 13Zm4.584-7.393A8.213 8.213 0 0 1 12 4.75c2.278 0 4.34.923 5.834 2.416A8.222 8.222 0 0 1 20.25 13a8.213 8.213 0 0 1-.857 3.666.75.75 0 1 0 1.343.668A9.713 9.713 0 0 0 21.75 13a9.715 9.715 0 0 0-2.346-6.344L21.03 5.03a.75.75 0 1 0-1.06-1.06l-1.626 1.626A9.714 9.714 0 0 0 12 3.25a9.713 9.713 0 0 0-4.334 1.014.75.75 0 1 0 .668 1.343Z"
        fill="currentColor"
      />
    </Icon>
  );
});
