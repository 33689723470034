import { useState, useEffect } from 'react';

import { usePrevious } from 'utilities/hook-utilities';
import { ISessionTimesParsed } from '../types';

const sessionStartTimeToJson = (sessionTimes: ISessionTimesParsed | null) => {
  if (!sessionTimes?.startDateTime || !sessionTimes?.endDateTime) {
    return null;
  }

  return JSON.stringify(sessionTimes.startDateTime);
};

export function useHasTouchedSessionStartTime(sessionTimes: ISessionTimesParsed | null) {
  const [hasTouchedSessionStartTime, setHasTouchedSessionStartTime] = useState(false);
  const sessionStartTimePrev = usePrevious(sessionStartTimeToJson(sessionTimes));

  useEffect(() => {
    if (sessionStartTimePrev && sessionStartTimePrev !== sessionStartTimeToJson(sessionTimes)) {
      setHasTouchedSessionStartTime(true);
    }
  }, [sessionTimes?.startDateTime]);

  return hasTouchedSessionStartTime;
}
