import React, { forwardRef } from 'react';

import { Icon } from '../icon';

import type { IconProps } from '../icon';

export const AustraliaFlag = forwardRef<SVGSVGElement, IconProps>(function AustraliaFlag(props, ref) {
  return (
    <Icon {...props} viewBox="0 0 24 24" ref={ref}>
      <g clipPath="url(#clip0_1773_6186)">
        <path
          d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12C0 12.0028 12 0.00135937 12 0C18.6274 0 24 5.37262 24 12Z"
          fill="#0052B4"
        />
        <path
          d="M11.9678 11.9999H11.9999C11.9999 11.989 11.9999 11.9786 11.9999 11.9678C11.9892 11.9785 11.9785 11.9892 11.9678 11.9999Z"
          fill="#F0F0F0"
        />
        <path
          d="M12 6.26183C12 4.15035 12 2.76674 12 0.000976562H11.998C5.3715 0.00205469 0 5.37416 0 12.0009H6.26086V8.47537L9.78642 12.0009H11.9679C11.9787 11.9902 11.9894 11.9796 12 11.9688C12 11.1604 12 10.439 12 9.78744L8.47444 6.26183H12Z"
          fill="#F0F0F0"
        />
        <path
          d="M6.07124 1.56543C4.19441 2.63413 2.63413 4.19441 1.56543 6.07124V12.0002H4.69588V4.69593V4.69588H12.0002C12.0002 3.70855 12.0002 2.76796 12.0002 1.56543H6.07124Z"
          fill="#D80027"
        />
        <path
          d="M11.9998 10.5246L7.73641 6.26125H6.26074C6.26074 6.2612 6.26074 6.26125 6.26074 6.26125L11.9998 12.0003H11.9998C11.9998 12.0003 11.9998 10.9829 11.9998 10.5246Z"
          fill="#D80027"
        />
        <path
          d="M7.23729 14.0874L7.89588 15.4645L9.38309 15.1208L8.71709 16.4943L9.91305 17.4427L8.42398 17.7783L8.42815 19.3048L7.23729 18.3498L6.04648 19.3048L6.05065 17.7783L4.56152 17.4427L5.75754 16.4943L5.09145 15.1208L6.57874 15.4645L7.23729 14.0874Z"
          fill="#F0F0F0"
        />
        <path
          d="M17.9658 16.6958L18.2951 17.3843L19.0387 17.2125L18.7057 17.8992L19.3037 18.3735L18.5591 18.5413L18.5612 19.3045L17.9658 18.827L17.3704 19.3045L17.3724 18.5413L16.6279 18.3735L17.2259 17.8992L16.8929 17.2125L17.6365 17.3843L17.9658 16.6958Z"
          fill="#F0F0F0"
        />
        <path
          d="M14.9033 9.3916L15.2326 10.0802L15.9762 9.90826L15.6432 10.595L16.2412 11.0693L15.4967 11.2371L15.4987 12.0003L14.9033 11.5228L14.3079 12.0003L14.3099 11.2371L13.5654 11.0693L14.1634 10.595L13.8304 9.90826L14.574 10.0802L14.9033 9.3916Z"
          fill="#F0F0F0"
        />
        <path
          d="M17.9658 5.21777L18.2951 5.90637L19.0387 5.73448L18.7057 6.42124L19.3036 6.89543L18.5591 7.06329L18.5612 7.82651L17.9658 7.34899L17.3704 7.82651L17.3724 7.06329L16.6279 6.89543L17.2259 6.42124L16.8929 5.73448L17.6365 5.90637L17.9658 5.21777Z"
          fill="#F0F0F0"
        />
        <path
          d="M20.6426 8.34814L20.9718 9.03674L21.7155 8.8648L21.3825 9.55157L21.9805 10.0258L21.2359 10.1937L21.238 10.9568L20.6426 10.4794L20.0472 10.9568L20.0492 10.1937L19.3047 10.0258L19.9027 9.55157L19.5697 8.8648L20.3133 9.03674L20.6426 8.34814Z"
          fill="#F0F0F0"
        />
        <path
          d="M18.73 12L18.989 12.7972H19.8272L19.1491 13.2899L19.4082 14.087L18.73 13.5943L18.0519 14.087L18.3109 13.2899L17.6328 12.7972H18.471L18.73 12Z"
          fill="#F0F0F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1773_6186">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});
