import { Text } from 'common-components/typography';
import _ from 'lodash';
import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { PrimaryButton } from 'common-components/buttons';
import { DuplicateItem } from 'views/account-management/add-new-member/panels/duplicate-check-panel/DuplicateItem';
import { LoadingDuplicatesSection } from 'views/account-management/add-new-member/panels/duplicate-check-panel/LoadingDuplicateSection';
import { PotentialDuplicateSection } from 'views/account-management/add-new-member/panels/duplicate-check-panel/PotentialDuplicateSection';
import { EmailConflictSection } from 'views/account-management/add-new-member/panels/duplicate-check-panel/EmailConflictSection';
import { NoDuplicatesSection } from 'views/account-management/add-new-member/panels/duplicate-check-panel/NoDuplicatesSection';
import { INewMemberData } from 'interfaces/account-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import CommonUtils from 'utilities/common-utils';

function DuplicatesListSection(props) {
  return (
    <div>
      {/* Heading */}
      <div style={{ height: '110px', padding: '12px' }}>
        <div className="mb-x-small">
          <Text size="large">
            Potential Duplicates ({props.fuzzyMatchTeamMember ? props.fuzzyMatchTeamMember.length : 0})
          </Text>
        </div>
        <div>
          <Text size="regular" color={'secondary'}>
            The following profile match the data entered on the previous screen.
          </Text>
        </div>
      </div>

      {/* List */}
      <div
        style={{
          height: 'calc(100vh - 554px)',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          overflowY: 'auto',
        }}
      >
        {/* Empty state - no duplicates */}
        {props.fuzzyMatchTeamMember && props.fuzzyMatchTeamMember.length === 0 ? (
          <div className="bg-blue-lightest flex-column" style={{ height: '64px' }}>
            <div className="flex-column flex-1 justify-center align-center">No duplicates</div>
          </div>
        ) : (
          _.map(props.fuzzyMatchTeamMember, (match) => (
            <DuplicateItem
              name={match.firstName + ' ' + match.lastName}
              avatarImage={match.attachmentUrl}
              type={CommonUtils.teamMemberPermissionTag(
                match.ownerRole,
                match.hasPortalAccess,
                match.hasWorkerAppAccess,
              )}
              status={match.status}
            />
          ))
        )}
      </div>
    </div>
  );
}

interface IDuplicateCheckPanelProps {
  onStepNext: any;
  onStepPrevious: any;
  newMemberData?: INewMemberData;
  setNewMemberData?: any;
  doFetchFuzzyMatchTeamMember?: typeof dispatch.teamStore.doFetchFuzzyMatchTeamMember;
  fuzzyMatchTeamMember?: typeof state.teamStore.fuzzyMatchTeamMember;
}

interface IDuplicateCheckPanelState {
  isLoading: boolean;
}

class DuplicateCheckPanel extends Component<IDuplicateCheckPanelProps, IDuplicateCheckPanelState> {
  state = { isLoading: false };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await this.props.doFetchFuzzyMatchTeamMember({
      firstName: this.props.newMemberData.firstName,
      lastName: this.props.newMemberData.lastName,
      email: this.props.newMemberData.email,
      mobile: this.props.newMemberData.mobileNumber,
    });
    this.setState({ isLoading: false });
  };

  render() {
    const { fuzzyMatchTeamMember, onStepNext, onStepPrevious } = this.props;

    const isExistingEmail =
      fuzzyMatchTeamMember &&
      fuzzyMatchTeamMember.length === 1 &&
      fuzzyMatchTeamMember[0].email === this.props.newMemberData.email;

    return (
      <div className="anim-slide-left select-none">
        <Row className="ph-x2-large">
          {/* Left Column */}
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 142px)', overflow: 'auto' }}>
            <div style={{ maxWidth: '100%', minHeight: 'calc(100vh - 262px)' }} className="flex-column flex-1">
              {/* <-- ! Left Content Starts here ! --> */}

              {/* Duplicates List */}
              <div className="bg-white flex-1 flex-column rounded bordered border-standard-gray">
                <DuplicatesListSection fuzzyMatchTeamMember={fuzzyMatchTeamMember} />
              </div>

              {/* Actions Section */}
              <div className="mt-medium bg-white rounded bordered border-standard-gray" style={{ padding: '12px' }}>
                <div className="mb-medium">
                  <div className="mb-x-small">
                    <Text size="x-large">Somebody new?</Text>
                  </div>
                  <Text size="regular" color="secondary">
                    If this person is new to your business and not a duplicate, you can create a profile for them.
                  </Text>
                </div>
                {!isExistingEmail && (
                  <PrimaryButton size="large" onClick={onStepNext}>
                    Create New Team Member
                  </PrimaryButton>
                )}
              </div>
            </div>

            {/* Left Button */}
            <div
              className="pv-x2-large width-full text-align-left"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <PrimaryButton size="large" onClick={onStepPrevious}>
                Back
              </PrimaryButton>
            </div>
          </Col>

          {/* Right Column */}
          <Col span={18} style={{ minHeight: 'calc(100vh - 142px)', maxWidth: '820px' }} className="pl-large">
            <div style={{ minHeight: 'calc(100vh - 182px)' }} className="flex-column">
              {/* <-- ! Right Content Starts here ! --> */}
              <div className="bg-white p-large bordered border-standard-gray rounded flex-column flex-1 align-center justify-center">
                <div className="pb-x5-large">
                  {this.state.isLoading ? (
                    <LoadingDuplicatesSection />
                  ) : isExistingEmail ? (
                    <EmailConflictSection />
                  ) : fuzzyMatchTeamMember && fuzzyMatchTeamMember.length > 0 ? (
                    <PotentialDuplicateSection />
                  ) : (
                    <NoDuplicatesSection onStepPrevious={onStepPrevious} onStepNext={onStepNext} />
                  )}
                </div>
              </div>
            </div>

            {/* Right Button */}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  fuzzyMatchTeamMember: state.teamStore.fuzzyMatchTeamMember,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchFuzzyMatchTeamMember: dispatch.teamStore.doFetchFuzzyMatchTeamMember,
});

export default connect(mapState, mapDispatch)(DuplicateCheckPanel);
