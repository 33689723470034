import clsx from 'clsx';
import React, { HTMLAttributes, PropsWithChildren } from 'react';

type StepContainerProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

export function StepContainer(props: StepContainerProps) {
  let { children, className, ...otherProps } = props;

  return (
    <div className={clsx('p-large rounded shadow-container', className)} {...otherProps}>
      {children}
    </div>
  );
}
