import React from 'react';
import { useTranslation } from 'react-i18next';

import { SideNav, TabContent } from 'views/sil-service/components/side-nav/side-nav';
import { GeneralSettings } from './general-settings';

export const SettingsTab = () => {
  const { t } = useTranslation('', { keyPrefix: 'silService.settingsTabs' });
  const tabs: TabContent[] = [
    { id: 1, title: `${t('general.tabName')}`, content: <GeneralSettings /> },
    { id: 2, title: `${t('Billing')}`, content: 'AMAZING TAB TWO' },
  ];

  return <SideNav tabs={tabs} />;
};
