import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React from 'react';
import { ExportFileConfigType } from '../../utils/account-management-constants';

interface Props {
  isUpdating?: boolean;
  isOpen?: boolean;
  type?: ExportFileConfigType;
  configName?: string;
  hasAskAgainButton?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onClickDoNotAskMe?: () => void;
}

const ConfirmChangeDefaultModal = (props: Props) => {
  const {
    type,
    isOpen,
    isUpdating,
    configName,
    hasAskAgainButton = false,
    onClose,
    onConfirm,
    onClickDoNotAskMe,
  } = props;

  const isAccountSystem = type === ExportFileConfigType.ACCOUNT_SYSTEM;
  const titleName = isAccountSystem ? 'finance' : 'timesheet';

  return (
    <ActionModal
      isOpen={isOpen}
      title={`Change your default ${titleName} export`}
      width={hasAskAgainButton ? 'large' : 580}
      onClose={onClose}
    >
      <Paragraph className="mt-medium">
        Would you like to change your default {titleName} export
        {configName && (
          <span>
            {' '}
            template to <b> {configName}</b>
          </span>
        )}
        ? This will change the default for your entire organisation.
      </Paragraph>
      <ActionModalFooter>
        {hasAskAgainButton && (
          <GhostButton size="large" className="mr-medium" onClick={onClickDoNotAskMe}>
            Don't ask me again
          </GhostButton>
        )}
        <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
          {hasAskAgainButton ? 'No' : 'Cancel'}
        </SecondaryButton>
        <PrimaryButton size="large" loading={isUpdating} onClick={onConfirm}>
          Yes
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};

export default ConfirmChangeDefaultModal;
