import React from 'react';
import { ComboboxData, ComboboxItem, ComboboxParsedItemGroup } from '@good/ui/core';

import { StaticField, StaticFieldProps } from './static-field';

function isComboboxItemArray(data: ComboboxData): data is ComboboxItem[] {
  if (!Array.isArray(data)) {
    return false;
  }

  return data.every((item) => {
    return typeof item === 'object' && 'label' in item && 'value' in item;
  });
}

function isComboboxItemGroupArray(data: ComboboxData): data is ComboboxParsedItemGroup[] {
  if (!Array.isArray(data)) {
    return false;
  }

  const firstItem = data[0];

  if (typeof firstItem === 'object' && 'group' in firstItem) {
    return data.every((item) => {
      return typeof item === 'object' && 'group' in item && 'items' in item && isComboboxItemArray(item.items);
    });
  }

  return false;
}

export const SelectStaticField = (props: StaticFieldProps & { data?: ComboboxData }) => {
  const { data, ...staticFieldProps } = props;

  if (data && isComboboxItemArray(data)) {
    const labelFromItem = data.find(({ value }) => value === staticFieldProps.value)?.label;
    return <StaticField {...staticFieldProps} value={labelFromItem ?? staticFieldProps.value} />;
  }

  if (data && isComboboxItemGroupArray(data)) {
    const allItems: ComboboxItem[] = [];
    for (const { items } of data) {
      allItems.push(...items);
    }
    const labelFromItem = allItems.find(({ value }) => value === staticFieldProps.value)?.label;
    return <StaticField {...staticFieldProps} value={labelFromItem ?? staticFieldProps.value} />;
  }

  return <StaticField {...staticFieldProps} />;
};
