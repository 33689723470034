import React, { Component } from 'react';
import { IRootState, state } from 'stores/rematch/root-store';
import * as H from 'history';
import { Information, Warning } from 'common-components/alerts';
import { Text } from 'common-components/typography';
import moment from 'moment-timezone';
import { HyperlinkButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { GroupServiceSessionStatus } from 'utilities/enum-utils';

interface ISessionOverviewWarningPanelProps {
  history: H.History;
  selectedSession: typeof state.groupServiceStore.selectedSession;
}

class SessionOverviewWarningPanel extends Component<ISessionOverviewWarningPanelProps> {
  render = () => {
    const { selectedSession } = this.props;

    return (
      <>
        {selectedSession.sessionWarnings &&
          selectedSession.sessionWarnings.incidentWarnings &&
          selectedSession.sessionWarnings.incidentWarnings > 0 && (
            <Warning
              content={
                <>
                  <Text weight={'bold'}>Incident(s) reported</Text>
                  <br />
                  <Text>
                    There has been{' '}
                    <b>
                      {selectedSession.sessionWarnings.incidentWarnings} incident
                      {selectedSession.sessionWarnings.incidentWarnings !== 1 && 's'}
                    </b>{' '}
                    reported for this session.
                  </Text>
                  <br />
                  <HyperlinkButton>View notes</HyperlinkButton>
                </>
              }
              className={'mb-large'}
            />
          )}

        {selectedSession.sessionStatus === GroupServiceSessionStatus.SCHEDULED && (
          <>
            {selectedSession.sessionWarnings &&
              selectedSession.sessionWarnings &&
              selectedSession.sessionWarnings.unfilledShiftSlot > 0 && (
                <Warning
                  content={
                    <>
                      <Text weight={'bold'}>Unfilled shifts</Text>
                      <br />
                      <Text>
                        There has been{' '}
                        <b>
                          {selectedSession.sessionWarnings.unfilledShiftSlot} shift
                          {selectedSession.sessionWarnings.unfilledShiftSlot !== 1 && 's'}
                        </b>{' '}
                        that are unfilled for this session.
                      </Text>
                      <br />
                      <HyperlinkButton>View team members</HyperlinkButton>
                    </>
                  }
                  className={'mb-large'}
                />
              )}
            {moment
              .tz(selectedSession.startDateTime, selectedSession.timezone)
              .isBefore(moment.tz(selectedSession.timezone)) && (
              <Warning
                content={
                  <>
                    <Text weight={'bold'}>Session late - not started</Text>
                    <br />
                    <Text>
                      The schedule start time of this session has passed but it has not yet been marked as started.
                    </Text>
                  </>
                }
                className={'mb-large'}
              />
            )}
          </>
        )}
        {selectedSession.sessionStatus === GroupServiceSessionStatus.INPROGRESS &&
          moment
            .tz(selectedSession.endDateTime, selectedSession.timezone)
            .isBefore(moment.tz(selectedSession.timezone)) && (
            <Warning
              content={
                <>
                  <Text weight={'bold'}>Session end - overdue</Text>
                  <br />
                  <Text>The scheduled end time of this session has passed, but it has not been ended yet.</Text>
                </>
              }
              className={'mb-large'}
            />
          )}
        {selectedSession.sessionStatus === GroupServiceSessionStatus.COMPLETED && (
          <>
            {selectedSession.sessionWarnings &&
              selectedSession.sessionWarnings &&
              selectedSession.sessionWarnings.bookingWaitingApproval > 0 && (
                <Warning
                  content={
                    <>
                      <Text weight={'bold'}>Customer bookings awaiting approval</Text>
                      <br />
                      <Text>
                        <b>{selectedSession.sessionWarnings.bookingWaitingApproval} customers</b> booking
                        {selectedSession.sessionWarnings.bookingWaitingApproval !== 1 && 's'} are awaiting approval.
                      </Text>
                      <br />
                      <HyperlinkButton>View customers</HyperlinkButton>
                    </>
                  }
                  className={'mb-large'}
                />
              )}
            {selectedSession.sessionWarnings &&
              selectedSession.sessionWarnings &&
              selectedSession.sessionWarnings.shiftSlotWaitingApproval > 0 && (
                <Warning
                  content={
                    <>
                      <Text weight={'bold'}>Team member shifts awaiting approval</Text>
                      <br />
                      <Text>
                        <b>
                          {selectedSession.sessionWarnings.shiftSlotWaitingApproval} shift
                          {selectedSession.sessionWarnings.shiftSlotWaitingApproval !== 1 && 's'}
                        </b>{' '}
                        are awaiting approval.
                      </Text>
                      <br />
                      <HyperlinkButton>View team members</HyperlinkButton>
                    </>
                  }
                  className={'mb-large'}
                />
              )}
          </>
        )}
      </>
    );
  };
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
});

export default connect(mapState, null)(SessionOverviewWarningPanel);
