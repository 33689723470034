import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const BookmarkSlash = forwardRef(function BookmarkSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.47 1.47a.75.75 0 0 1 1.06 0l2.113 2.112A2.749 2.749 0 0 1 7 2.25h6.055c1.368 0 2.47 0 3.337.117.9.12 1.658.38 2.26.981.602.602.86 1.36.982 2.26.116.867.116 1.97.116 3.337v9.745l2.78 2.78a.75.75 0 0 1-1.06 1.06l-3-3-14-14-3-3a.75.75 0 0 1 0-1.06Zm16.78 15.72V9c0-1.435-.002-2.437-.103-3.192-.099-.734-.28-1.122-.556-1.399-.277-.277-.665-.457-1.399-.556-.755-.101-1.757-.103-3.192-.103H7a1.25 1.25 0 0 0-1.219.97l12.47 12.47Zm-12.5 2.518V9a.75.75 0 1 0-1.5 0v12a.75.75 0 0 0 1.122.651l7-4a.75.75 0 1 0-.744-1.302L5.75 19.708Z"
        fill="currentColor"
      />
    </Icon>
  );
});
