import React from 'react';
import { RouterProvider } from 'react-aria-components';
import { useHistory } from 'react-router-dom';

type ReactAriaRouterProviderProps = {
  children: React.ReactNode;
};

export function ReactAriaRouterProvider({ children }: ReactAriaRouterProviderProps) {
  const history = useHistory();

  return <RouterProvider navigate={(path) => history.push(path)}>{children}</RouterProvider>;
}
