import * as styles from '@good/components/src/date-time-field/date-time-field.css';
import { twMerge } from '@good/components/src/utils';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export type TimeInputProps = {
  rowPath: string;
};

export const TimeInput = (props: TimeInputProps) => {
  const { input } = styles.dateField();
  const { register } = useFormContext();
  const { rowPath } = props;

  return (
    <div className='flex flex-col gap-2'>
      <input
        type='time'
        data-testid={rowPath}
        className={twMerge(
          input(),
          '[&::-webkit-calendar-picker-indicator]:hidden',
          'border-gray',
          'w-24',
          'focus-visible:outline-accent',
          'focus-visible:outline-offset-[-1px]',
        )}
        {...register(rowPath)}
      />
    </div>
  );
};
