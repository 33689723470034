import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const PlaylistPlus = forwardRef(function PlaylistPlus(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5A.75.75 0 0 1 3 4.25h16a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 5Zm0 4A.75.75 0 0 1 3 8.25h12a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 9ZM3 12.25a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5H3ZM2.25 17a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75Zm16.5-3a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 1 0 0 1.5h2.25V20a.75.75 0 1 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V14Z"
        fill="currentColor"
      />
    </Icon>
  );
});
