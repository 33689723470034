import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Avatar, Icon, notification } from 'antd';
import _ from 'lodash';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IDeleteDraftUserModalProps {
  isOpen: any;
  onClose: any;
  worker: any;
  doDeleteDraftTeamMember: typeof dispatch.teamStore.doDeleteDraftTeamMember;
}

interface IDeleteDraftUserModalState {
  title: any;
  isDone: boolean;
  isSaving: boolean;
}

class DeleteDraftUserModal extends Component<IDeleteDraftUserModalProps, IDeleteDraftUserModalState> {
  state = { title: 'Delete draft user', isDone: false, isSaving: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Delete draft user', isDone: false });
    this.props.onClose();
  };

  private _saveAction = async () => {
    try {
      this.setState({ isSaving: true });
      await this.props.doDeleteDraftTeamMember({ supportWorkerId: this.props.worker.supportWorkerId });
      this.setState({
        title: (
          <>
            User successfully deleted
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        isDone: true,
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isSaving: false });
  };

  render() {
    const { isOpen, worker } = this.props;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {!_.isEmpty(worker) &&
          (this.state.isDone ? (
            <>
              <div className="mv-medium ml-x4-large anim-slide-left">
                <div>
                  <SubTitle>User</SubTitle>
                  <div className={'flex-row align-center'}>
                    <Avatar shape={'square'} src={worker.attachmentUrl} className={'mr-medium'} />
                    <Text>{worker.firstName + ' ' + worker.lastName}</Text>
                    <div className={'ml-medium'}>
                      <Text size={'small'}>DELETED</Text>
                    </div>
                  </div>
                </div>

                <ActionModalFooter>
                  <PrimaryButton size="large" onClick={this._onCloseModal}>
                    Done
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ) : (
            <>
              <div className="mv-medium anim-slide-left">
                <div>
                  <Paragraph>
                    You are choosing to delete the draft user: <b>{worker.firstName + ' ' + worker.lastName}</b>.
                  </Paragraph>
                  <Paragraph>
                    This action is permanent and cannot be undone. Are you sure you want to continue?
                  </Paragraph>
                </div>

                <ActionModalFooter>
                  <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" loading={this.state.isSaving} onClick={this._saveAction}>
                    Delete draft
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ))}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doDeleteDraftTeamMember: dispatch.teamStore.doDeleteDraftTeamMember,
});

export default connect(null, mapDispatch)(DeleteDraftUserModal);
