import { Text } from '@good/components';
import { NumberFormatter } from '@goodhuman-me/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InsightsSummaryResponse } from 'views/insights/insights';

export type ShiftsWorkedProps = Pick<InsightsSummaryResponse, 'totalShifts'>;
export const ShiftsWorked = ({ totalShifts }: ShiftsWorkedProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'insights.summary.shiftsWorked' });

  return (
    <div className="align-center flex flex-col justify-center">
      <Text className="flex text-b-xs text-gray-dark-1">{t('label')}</Text>
      <span aria-valuetext={`${totalShifts} Shifts Worked`} className="text-semibold flex font-base text-h-lg">
        <NumberFormatter value={totalShifts} />
      </span>
    </div>
  );
};
