import { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';

const Portal: FC = ({ children }) => {
  const mount = document.getElementById('root');
  useEffect(() => {
    const bodyEle = document.querySelector('body');
    bodyEle.style.overflow = 'hidden';
    return () => {
      bodyEle.removeAttribute('style');
    };
  }, []);

  return createPortal(children, mount);
};

export default Portal;
