/* eslint-disable */

import React, { Component } from 'react';
import moment from 'moment';
import { FieldLabel, Paragraph, Text, Title } from 'common-components/typography';
import { Col, Popconfirm, Row } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import { IInviteUser } from 'src/interfaces/user-interfaces';
import { Card, ProgressBar } from '@blueprintjs/core';

import SecondaryButton from 'common-components/buttons/SecondaryButton';
import SpinningLoader from 'common-components/loading/SpinningLoader';

interface IPendingInvitaionProps {
  doFetchPendingInvitation: typeof dispatch.teamStore.doFetchPendingInvitation;
  doCancelInvitation: typeof dispatch.teamStore.doCancelInvitation;
  doResendInvitation: typeof dispatch.teamStore.doResendInvitation;
  pendingInvitations?: IInviteUser[];
}

interface IPendingInvitaionState {
  isLoading: boolean;
  cancelInvitations: any;
  resendInvitations: any;
  refreshList: boolean;
}

class PendingInvitationListingView extends Component<IPendingInvitaionProps, IPendingInvitaionState> {
  state = {
    isLoading: false,
    refreshList: false,
    cancelInvitations: [],
    resendInvitations: [],
  };

  private _loadContent = async () => {
    const { doFetchPendingInvitation } = this.props;
    this.setState({ isLoading: true });
    const payload = {};
    await doFetchPendingInvitation(payload);
    this.setState({ isLoading: false });
  };

  private _cancelInvitation = (token) => {
    const { doCancelInvitation } = this.props;
    this.setState({ refreshList: true });
    this.setState(
      {
        cancelInvitations: [...this.state.cancelInvitations, token],
      },
      async () => {
        let result = await doCancelInvitation(this.state.cancelInvitations);
        if (result) {
          this.setState({ refreshList: false });
          this._loadContent();
        } else {
          this.setState({ refreshList: false });
        }
      },
    );
  };

  private _confirmResendInvitation = (token) => {
    const { doResendInvitation } = this.props;
    this.setState({ refreshList: true });
    this.setState(
      {
        resendInvitations: [...this.state.resendInvitations, token],
      },
      async () => {
        let result = await doResendInvitation(this.state.resendInvitations);
        if (result) {
          this.setState({ refreshList: false, resendInvitations: [] });
          this._loadContent();
        } else {
          this.setState({ refreshList: false, resendInvitations: [] });
        }
      },
    );
  };

  componentDidMount = () => {
    this._loadContent();
  };

  render() {
    const { pendingInvitations } = this.props;

    if (this.state.isLoading) {
      return (
        <Card>
          <div className='mb-medium'>
            <Text>Fetching pending invitation list...</Text>
          </div>
          <ProgressBar animate={true} />
        </Card>
      );
    }

    if (this.state.refreshList) {
      return <SpinningLoader size={150} message={'Loading'} />;
    }

    return (
      <>
        <Title level={2}>Pending Invitation</Title>
        <Paragraph>View your invitations that are still pending.</Paragraph>
        <div className='pv-medium'>
          <div className='flex-row align-end mb-x-large'>
            <div className='flex-1'>
              <Title level={4}>{pendingInvitations.length} invitation(s) found.</Title>
            </div>
          </div>

          <div className=''>
            <Row className='pb-small'>
              <Col span={4}>
                <FieldLabel text='Name' />
              </Col>
              <Col span={3}>
                <FieldLabel text='Type' />
              </Col>
              <Col span={5}>
                <FieldLabel text='Email' />
              </Col>
              <Col span={4}>
                <FieldLabel text='Mobile' />
              </Col>
              <Col span={3}>
                <FieldLabel text='Invitation Date' />
              </Col>
              <Col span={2} />
              <Col span={2} />
            </Row>
            {pendingInvitations.map((invitation) => (
              <Row gutter={8} className='pv-medium bordered-top' type='flex' align='top'>
                <Col
                  span={4}
                  title={invitation.firstName + ' ' + invitation.lastName}
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  <Text size='x-large' weight='bold'>
                    {invitation.firstName} {invitation.lastName}
                  </Text>
                </Col>
                <Col span={3}>{invitation.type}</Col>
                <Col span={5} title={invitation.email} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Text color='forest-dark'>{invitation.email}</Text>
                </Col>
                <Col span={4}>
                  <Text color='forest-dark'>{invitation.mobile}</Text>
                </Col>
                <Col span={3}>{moment(invitation.createdOn).fromNow()}</Col>
                <Col span={2}>
                  <Popconfirm
                    title='Are you sure cancel this invitation?'
                    onConfirm={() => this._cancelInvitation(invitation.token)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <SecondaryButton>Cancel</SecondaryButton>
                  </Popconfirm>
                </Col>
                <Col span={2}>
                  <Popconfirm
                    title='Are you sure resend this invitation?'
                    onConfirm={() => this._confirmResendInvitation(invitation.token)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <SecondaryButton>Resend</SecondaryButton>
                  </Popconfirm>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  pendingInvitations: state.teamStore.pendingInvitations,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchPendingInvitation: dispatch.teamStore.doFetchPendingInvitation,
  doCancelInvitation: dispatch.teamStore.doCancelInvitation,
  doResendInvitation: dispatch.teamStore.doResendInvitation,
});

export default connect(mapState, mapDispatch)(PendingInvitationListingView);
