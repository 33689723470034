import { useState, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { getCustomRatioErrors } from './get-custom-ratio-errors';
import { getDurationErrors } from './get-duration-errors';
import { setCustomRatioErrors } from './set-custom-ratio-errors';
import { setDurationErrors } from './set-duration-errors';
import { EditRatiosSchema } from '../../schema';
import { ISessionTimesParsed } from '../../types';

export function useValidate(form: UseFormReturn<EditRatiosSchema>, sessionTimes: ISessionTimesParsed | null) {
  const { watch } = form;
  const [hasValidationError, setHasValidationError] = useState(false);

  const { unsubscribe } = watch((values) => {
    if (sessionTimes) {
      const customRatioErrors = getCustomRatioErrors(sessionTimes, values);
      const durationErrors = getDurationErrors(sessionTimes, values);
      setCustomRatioErrors(customRatioErrors, form);
      setDurationErrors(durationErrors, form);

      const hasVisibleCustomRatioErrors =
        values.ratios?.some((ratio, key) => {
          if (!customRatioErrors[key]) {
            return false;
          }
          return ratio?.isExactTime && !customRatioErrors[key]?.isValid;
        }) ?? false;
      const hasVisibleDurationErrors =
        durationErrors?.durationExceedsSessionLength && (values.ratios?.some((ratio) => !ratio?.isExactTime) ?? false);

      setHasValidationError(hasVisibleCustomRatioErrors || hasVisibleDurationErrors);
    }
  });

  useEffect(() => {
    return () => {
      unsubscribe();
    };
  }, [unsubscribe]);

  return hasValidationError;
}
