import React, { Component } from 'react';
import { Text, Paragraph } from 'common-components/typography';
import moment from 'moment-timezone';
import _ from 'lodash';
import { SecondaryButton, PrimaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import WorkerDetailTimeAvailabilityItem from '../../WorkerDetailTimeAvailabilityItem';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { notification, Tabs } from 'antd';
import AvailabilityConflictTimes from '../AvailablilityConflictTimes';
import { TeamMemberTimezoneInput } from 'common-components/timezone';
import ActionModal from 'common-components/modal/ActionModal';

const { TabPane } = Tabs;

interface IEditGeneralAvailabilityModalProps {
  isOpen: boolean;
  onClose: () => void;
  dateArray: any[];
  doUpdateTimeAvailability: typeof dispatch.teamStore.doUpdateTimeAvailability;
  doCheckAvailabilityConflict: typeof dispatch.teamStore.doCheckAvailabilityConflict;
  userTimezone: string;
  availabilityTimezone: string;
  cycle: string;
}

interface IEditGeneralAvailabilityModalState {
  tempArray: any[];
  step: number;
  conflictShifts: any;
  isLoading: boolean;
  selectedShiftsToKeep: any[];
  selectedTimezone: any;
  timezoneType: any;
  modalWidth: string;
  skipTimezoneWarning: boolean;
  title: string;
  isMissingTimezone: boolean;
}

class EditGeneralAvailabilityModal extends Component<
  IEditGeneralAvailabilityModalProps,
  IEditGeneralAvailabilityModalState
> {
  state = {
    tempArray: [],
    step: 1,
    conflictShifts: [],
    isLoading: false,
    selectedShiftsToKeep: [],
    selectedTimezone: null,
    timezoneType: 1,
    modalWidth: 'x-large',
    skipTimezoneWarning: false,
    title: 'Update team member’s availability',
    isMissingTimezone: false,
  };

  private _onChangeAvailableTime = (value, index) => {
    const { tempArray } = this.state;
    const newData = [...tempArray];
    newData[index] = value;
    this.setState({ tempArray: newData });
  };

  private _onChangeAvailableTimeFornightly = (value, index, week) => {
    const { tempArray } = this.state;
    const newData = [...tempArray];
    const weekData = _.filter(newData, (item) => item.week === week);
    weekData[index] = value;
    const filteredNewData = _.filter(newData, (item) => item.week !== week);
    this.setState({ tempArray: [...filteredNewData, ...weekData] });
  };

  private _validateAvailableTime = () => {
    const { tempArray } = this.state;

    const errorDate = _.filter(
      tempArray,
      (item) =>
        !_.isEmpty(item.timeRange) &&
        !_.isEmpty(
          _.find(item.timeRange, (time) => {
            return time.error;
          }),
        ),
    );

    return _.isEmpty(errorDate);
  };

  private _onClose = () => {
    this.setState({
      isLoading: false,
      selectedShiftsToKeep: [],
      step: 1,
      modalWidth: 'x-large',
      skipTimezoneWarning: false,
      title: 'Update team member’s availability',
      isMissingTimezone: false,
    });
    this.props.onClose();
  };

  private _convertTimeZone = (time) => {
    const { selectedTimezone } = this.state;
    const dateTimeInString = moment(time).format('YYYY-MM-DD HH:mm');

    return moment.tz(dateTimeInString, selectedTimezone).toISOString();
  };

  private _reverseMapData = () => {
    const { tempArray } = this.state;

    return _.map(tempArray, (item) => ({
      day: item.day,
      number: item.number,
      timeRange: !_.isEmpty(item.timeRange)
        ? _.map(item.timeRange, (time) => ({
            startTime: this._convertTimeZone(time.startDateTime),
            endTime: this._convertTimeZone(time.endDateTime),
          }))
        : [],
      isAllDay: item.isAvailableAllDay,
      weeklyCycle: item.week,
    }));
  };

  private _onSubmit = async () => {
    const { doCheckAvailabilityConflict, availabilityTimezone } = this.props;
    const { selectedTimezone, skipTimezoneWarning } = this.state;

    const isValid = this._validateAvailableTime();

    if (isValid && selectedTimezone) {
      if (availabilityTimezone !== selectedTimezone && !skipTimezoneWarning) {
        this.setState({ step: 3, modalWidth: 'medium', title: 'Timezone change' });
      } else {
        const requestData = this._reverseMapData();
        this.setState({ isLoading: true });
        try {
          const result = await doCheckAvailabilityConflict({
            timeAvailability: requestData,
            availabilityTimezone: selectedTimezone,
          });
          if (!_.isEmpty(result)) {
            this.setState({
              conflictShifts: result,
              step: 2,
              isLoading: false,
              title: 'Review upcoming shifts',
            });
          } else {
            this._updateGeneralAvailability();
          }
        } catch (error) {
          notification.error({ message: 'Oops! Something went wrong, please try again.' });
          this.setState({ isLoading: false });
        }
      }
    } else {
      this.setState({ isMissingTimezone: true });
    }
  };

  private _updateGeneralAvailability = () => {
    const { selectedShiftsToKeep, selectedTimezone } = this.state;
    const { doUpdateTimeAvailability } = this.props;
    const requestData = this._reverseMapData();
    this.setState({ isLoading: true });

    try {
      const result = doUpdateTimeAvailability({
        timeAvailability: requestData,
        shiftsToBeKept: selectedShiftsToKeep,
        timezone: selectedTimezone,
      });
      notification.success({
        message: 'General availability updated',
        description: 'You have successfully updated this team members general availability.',
      });
      this._onClose();
      this.setState({ isLoading: false });
    } catch (error) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isLoading: false });
    }
  };

  private _onChangeShiftsToKeep = (shifts) => {
    this.setState({ selectedShiftsToKeep: shifts });
  };

  private _onChangeTimezone = (type, newTimezone) => {
    const { tempArray } = this.state;
    const { userTimezone } = this.props;
    const selectedTimezone = type === 1 ? userTimezone : newTimezone;

    if (selectedTimezone) {
      _.forEach(tempArray, (day) => {
        if (!_.isEmpty(day.timeRange)) {
          day.timeRange = _.map(day.timeRange, (time) => ({
            startDateTime: moment(time.startDateTime).tz(selectedTimezone, true),
            endDateTime: moment(time.endDateTime).tz(selectedTimezone, true),
            localStartDateTime: time.localStartDateTime,
            localEndDateTime: time.localEndDateTime,
            error: time.error,
          }));
        }
      });
    }

    this.setState({
      selectedTimezone: type === 1 ? userTimezone : newTimezone,
      timezoneType: type,
      isMissingTimezone: false,
      tempArray: tempArray,
    });
  };

  private _renderAvailability = () => {
    const { tempArray, selectedTimezone } = this.state;

    const weekOne = tempArray.filter((item) => item.week === 1);

    const hasNoWeekTwoAvailabilities = tempArray.every((availability) => availability.week !== 2);
    const weekTwo = tempArray.filter((availability) => {
      // if no week 2 availabilities, just show all of them
      if (hasNoWeekTwoAvailabilities) return true;

      return availability.week === 2;
    });

    return (
      <Tabs defaultActiveKey="1" onChange={() => {}}>
        <TabPane tab="Week 1" key="1">
          {weekOne.map((data, index) => {
            return (
              <WorkerDetailTimeAvailabilityItem
                day={data.day}
                data={data}
                key={data.number}
                avblTimezone={selectedTimezone}
                onChangeAvailableTime={(value) => {
                  this._onChangeAvailableTimeFornightly(value, index, 1);
                }}
              />
            );
          })}
        </TabPane>
        <TabPane tab="Week 2" key="2">
          {weekTwo.map((data, index) => {
            return (
              <WorkerDetailTimeAvailabilityItem
                day={data.day}
                data={data}
                key={data.number}
                avblTimezone={selectedTimezone}
                onChangeAvailableTime={(value) => {
                  this._onChangeAvailableTimeFornightly(value, index, hasNoWeekTwoAvailabilities ? 1 : 2);
                }}
              />
            );
          })}
        </TabPane>
      </Tabs>
    );
  };

  private _renderContent = () => {
    const { step, selectedTimezone: timezone, timezoneType } = this.state;

    switch (step) {
      case 1:
        return (
          <>
            <Text className="mt-large">
              Select the hours this team member is available in a typical week or fortnight. These hours are a guide –
              you can override or change availability anytime.
            </Text>
            <div className="mt-medium">
              <TeamMemberTimezoneInput
                usePortal={false}
                value={timezone}
                onChange={this._onChangeTimezone}
                type={timezoneType}
                error={this.state.isMissingTimezone}
              />
            </div>
            <div className="mt-large">{this._renderAvailability()}</div>
            <div className="flex-row justify-end mt-large">
              <SecondaryButton
                className="mr-medium"
                size="large"
                onClick={this._onClose}
                disabled={this.state.isLoading}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton loading={this.state.isLoading} className="mr-medium" size="large" onClick={this._onSubmit}>
                Save changes
              </PrimaryButton>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <Paragraph>
              This team member is currently assigned to shift(s) that fall outside their updated availability.{' '}
              <Text>Please select the shifts (if any) you wish to keep</Text> for this team member or continue without
              selecting any.
            </Paragraph>
            <div className="bg-quaternary pv-x-large ph-12 mt-x-large">
              <AvailabilityConflictTimes
                conflicts={this.state.conflictShifts}
                timezone={timezone}
                onSelectShifts={this._onChangeShiftsToKeep}
              />
            </div>
            <div className="mt-x3-large flex justify-end">
              <SecondaryButton size="large" onClick={this._onClose} disabled={this.state.isLoading}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                loading={this.state.isLoading}
                size="large"
                className="ml-small"
                onClick={this._updateGeneralAvailability}
              >
                Done
              </PrimaryButton>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <Paragraph>You have changed the timezone for this user's general availability.</Paragraph>
            <Paragraph>
              This may have significant impact on the the user's availability. Are you sure you want to continue with
              this change?
            </Paragraph>
            <div className="mt-x3-large flex justify-end">
              <SecondaryButton
                size="large"
                onClick={() => {
                  this.setState({ step: 1, modalWidth: 'x-large', skipTimezoneWarning: false });
                }}
                disabled={this.state.isLoading}
              >
                Go back
              </SecondaryButton>
              <PrimaryButton
                loading={this.state.isLoading}
                size="large"
                className="ml-small"
                onClick={() =>
                  this.setState({ skipTimezoneWarning: true, modalWidth: 'x-large' }, () => this._onSubmit())
                }
              >
                Continue
              </PrimaryButton>
            </div>
          </>
        );
      default:
        return [];
    }
  };

  componentDidMount() {
    const { dateArray, availabilityTimezone, userTimezone } = this.props;
    this.setState({
      tempArray: [...dateArray],
      selectedTimezone: availabilityTimezone,
      timezoneType: availabilityTimezone === userTimezone ? 1 : 2,
    });
  }

  render() {
    const { isOpen } = this.props;

    return (
      <ActionModal isOpen={isOpen} title={this.state.title} onClose={this._onClose} width={this.state.modalWidth}>
        <div className="ph-medium mv-small">{this._renderContent()}</div>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateTimeAvailability: dispatch.teamStore.doUpdateTimeAvailability,
  doCheckAvailabilityConflict: dispatch.teamStore.doCheckAvailabilityConflict,
});

export default connect(null, mapDispatch)(EditGeneralAvailabilityModal);
