import React, { Component } from 'react';
import { Paragraph, Title } from 'common-components/typography';
import { Divider, Icon } from 'antd';
import { Inline, Stack } from '@goodhuman-me/components';

interface IFormTitleProps {
  title: {
    formTitle: string;
    formDescription: string;
  };
  openFormPropertiesModal: () => void;
}

interface IFormTitleState {}

class FormTitle extends Component<IFormTitleProps, IFormTitleState> {
  render() {
    const {
      title: { formTitle, formDescription },
    } = this.props;
    return (
      <>
        <div className="bg-white p-large mh-small rounded">
          <Inline justifyContent="space-between">
            <Title className="m-none" level={4}>
              {formTitle}
            </Title>
            <Icon
              type="edit"
              onClick={this.props.openFormPropertiesModal}
              className="text-color-info-blue text-size-x2-large"
            />
          </Inline>

          {formDescription && <Paragraph className="whitespace-pre-line">{formDescription}</Paragraph>}
        </div>
        <Divider />
      </>
    );
  }
}

export default FormTitle;
