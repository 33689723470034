import { Popover2 } from '@blueprintjs/popover2';
import { Icon } from 'antd';
import React from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TmMgmtSearchFilterContents } from './tm-mgmt-search-filter-contents';

type TmMgmtSearchFilterParams = {
  onUpdateFilter: (event: CheckboxChangeEvent, category: string, filterName: string) => Promise<void>;
};

export const TmMgmtSearchFilter = ({ onUpdateFilter }: TmMgmtSearchFilterParams) => {
  return (
    <Popover2
      content={<TmMgmtSearchFilterContents onUpdateFilter={onUpdateFilter} />}
      popoverClassName="mb-medium"
      position="bottom"
      interactionKind="click"
    >
      <div className="bordered p-small cursor-pointer" style={{ width: '200px' }}>
        <Icon type="filter" className="mr-small" /> Filters
      </div>
    </Popover2>
  );
};
