import React, { PureComponent } from 'react';

import { SubTitle } from './index';

interface IFieldLabelProps {
  text: string;
}

class FieldLabel extends PureComponent<IFieldLabelProps, any> {
  render() {
    const { text } = this.props;

    return <SubTitle>{text}</SubTitle>;
  }
}

export default FieldLabel;
