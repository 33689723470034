import React from 'react';

import { Alert } from '@good/ui/core';
import { Info } from '@good/icons';
import { useTranslation } from 'react-i18next';

export const FullAlert = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceCustomersTabs' });
  const icon = <Info />;

  return (
    <Alert variant='light' color='violet' title={t('capacityAlertheading')} icon={icon}>
      {t('capacityAlertDescription')}
    </Alert>
  );
};
