import { QueryClient } from '@tanstack/react-query';
import { addStatus, hasStatus, removeStatus } from './optimistic-status';

export class OptimisticUpdate<T extends object> {
  private queryClient: QueryClient;
  private key: string;
  private keys: string[];
  private item: T | undefined;
  private itemMatcher: (item: T) => boolean;
  private optimisticId: string | undefined;

  constructor(queryClient: QueryClient, key: string, keys: string[], itemMatcher: (item: T) => boolean) {
    this.queryClient = queryClient;
    this.key = key;
    this.keys = keys;
    this.itemMatcher = itemMatcher;
  }

  public async start(item: T, optimisticId: string): Promise<void> {
    await this.queryClient.cancelQueries([this.key, ...this.keys]);
    this.item = item;
    this.optimisticId = optimisticId;

    if (!this.hasUpdatePending()) {
      this.item = addStatus(this.item, 'update', this.optimisticId);
      this.takeSnapshot();
    }

    this.updateItem(this.item);
  }

  public rollback(): void {
    if (this.hasSnapshot()) {
      const snapshot = this.getSnapshot();
      this.replaceItem(snapshot);
      this.removeSnapshot();
    }
  }

  public finish(newItem?: T): void {
    this.updateItem(newItem, true);
  }

  private updateItem(newItem: T | undefined, removeUpdateStatus?: boolean) {
    const updater = (item: T) =>
      this.itemMatcher(item) ? { ...(removeUpdateStatus ? removeStatus(item, 'update') : item), ...newItem } : item;

    this.queryClient.setQueryData([this.key, ...this.keys], (prev: T[] | undefined) => prev?.map(updater) ?? []);
  }

  private replaceItem(newItem: T | undefined) {
    if (!newItem) {
      return;
    }

    this.queryClient.setQueryData(
      [this.key, ...this.keys],
      (prev: T[] | undefined) => prev?.map((item) => (this.itemMatcher(item) ? newItem : item)) ?? [],
    );
  }

  private getLatestItem() {
    const data = this.queryClient.getQueryData<T[]>([this.key, ...this.keys]);
    return data?.find((item) => this.itemMatcher(item)) ?? null;
  }

  private takeSnapshot() {
    const latestItem = this.getLatestItem();
    this.queryClient.setQueryData([this.key, ...this.keys, 'snapshot', this.optimisticId], latestItem);
  }

  private hasUpdatePending() {
    const latestItem = this.getLatestItem();
    if (!latestItem) {
      return false;
    }
    return hasStatus(latestItem, 'update');
  }

  private hasSnapshot() {
    return !!this.getSnapshot();
  }

  private getSnapshot() {
    const data = this.queryClient.getQueryData<T>([this.key, ...this.keys, 'snapshot', this.optimisticId]);
    return data;
  }

  private removeSnapshot() {
    this.queryClient.setQueryData([this.key, ...this.keys, 'snapshot', this.optimisticId], null);
  }
}
