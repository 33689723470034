import { notification, Skeleton } from 'antd';
import { PrimaryButton } from 'common-components/buttons';
import { InfiniteScroll } from 'components';
import { IGroupServiceCustomer, IGroupServiceNote } from 'interfaces/service-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { NoteVisibleType, WorkflowTriggerModuleType } from 'utilities/enum-utils';
import EditValueFormModal from 'views/form-builder/components/EditValueFormModal';
import { IElementValue } from 'views/form-builder/shared/form-interface';
import AddEditGroupNoteModal from 'views/group-services/components/booking-notes/AddEditGroupNoteModal';
import BookingNoteItem from 'views/group-services/components/booking-notes/BookingNoteItem';
import { DeleteGroupNoteModal } from 'views/group-services/components/booking-notes/DeleteGroupNoteModal';
import { EmptyNote } from 'views/group-services/components/booking-notes/EmptyNote';
import { ModalLoading } from 'views/group-services/manage-booking-modal/common-components/ModalLoading';
import AddIncidentNoteModal from 'views/workflows/trigger/AddIncidentNoteModal';

interface INotesPanelProps {
  booking: typeof state.groupBookingsStore.selectedGroupBookingItem;
  groupBookingNotes: typeof state.groupBookingsStore.groupBookingNotes;
  workflowForm: typeof state.workflowStore.workflowForm;
  doFetchGroupBookingNotes: typeof dispatch.groupBookingsStore.doFetchGroupBookingNotes;
  doRemoveGroupBookingNote: typeof dispatch.groupBookingsStore.doRemoveGroupBookingNote;
  doCheckViewWorkflowDetails: typeof dispatch.workflowStore.doCheckViewWorkflowDetails;
  doGetWorkflowFormDetail: typeof dispatch.workflowStore.doGetWorkflowFormDetail;
  doUpdateWorkflowForm: typeof dispatch.workflowStore.doUpdateWorkflowForm;
  doFetchMoreNotes: any;
  doLoadFreshNotes: any;
  onChangeBackdrop: any;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isLoading: boolean;
}

interface INotesPanelState {
  isAddNoteModalOpen: boolean;
  isDeleteNoteModalOpen: boolean;
  isOpenFormModal: boolean;
  isLoading: boolean;
  noteMode: 'add' | 'edit' | string;
  targetNote: IGroupServiceNote;
  selectedCustomers: IGroupServiceCustomer[];
  isAddIncidentNoteModalOpen: boolean;
  groupBookingData: object;
}

class NotesPanel extends Component<INotesPanelProps, INotesPanelState> {
  state = {
    isAddNoteModalOpen: false,
    isDeleteNoteModalOpen: false,
    isOpenFormModal: false,
    isLoading: false,
    noteMode: 'add',
    targetNote: null,
    selectedCustomers: [
      {
        taggedUserId: this.props.booking.userId,
        taggedUserFirstName: this.props.booking.firstName,
        taggedUserLastName: this.props.booking.lastName,
        taggedUserAvatarUrl: this.props.booking.attachmentUrl,
      },
    ],
    isAddIncidentNoteModalOpen: false,
    groupBookingData: {},
  };

  //region Event Handlers
  onAddNote = () => {
    this.openAddNoteModal({ noteMode: 'add', targetNote: null });
  };

  onEditNote = ({ targetNote }) => {
    this.openAddNoteModal({ noteMode: 'edit', targetNote });
  };

  onDeleteNote = ({ targetNote }) => {
    this.openDeleteNoteModal({ targetNote });
  };

  //region Actions

  // The following actions trigger whenever the action has been confirmed from the modal.

  onDeleteNoteAction = ({ targetNote }) => {
    const { booking, doRemoveGroupBookingNote } = this.props;
    const payload = {
      bookingId: booking.bookingId,
      noteId: targetNote.noteId,
    };

    doRemoveGroupBookingNote(payload);
  };

  onSaveNoteAction = async () => {
    this.setState({ isAddNoteModalOpen: false });
    await this.props.doLoadFreshNotes();
  };
  //endregion

  //endregion

  //region Modal Handlers
  openAddNoteModal = ({ noteMode, targetNote }) => this.setState({ isAddNoteModalOpen: true, noteMode, targetNote });
  closeAddNoteModal = () => this.setState({ isAddNoteModalOpen: false });

  openDeleteNoteModal = ({ targetNote }) => this.setState({ isDeleteNoteModalOpen: true, targetNote });
  closeDeleteNoteModal = () => this.setState({ isDeleteNoteModalOpen: false, targetNote: null });
  //endregion

  openAddIncidentNoteModal = () => {
    this.setState({ isAddIncidentNoteModalOpen: true, isAddNoteModalOpen: false });
    this.props.onChangeBackdrop(true);
  };

  closeAddIncidentNoteModal = () => {
    this.setState({ isAddIncidentNoteModalOpen: false });
    this.props.onChangeBackdrop(false);
  };

  showFormModal = async (note) => {
    try {
      const { doGetWorkflowFormDetail, onChangeBackdrop } = this.props;
      await doGetWorkflowFormDetail({ workflowFormId: note.workflowFormId, isFromNote: true });
      this.setState({ isOpenFormModal: true });
      onChangeBackdrop(true);
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  closeFormModal = () => {
    this.setState({ isOpenFormModal: false });
    this.props.onChangeBackdrop(false);
  };

  onSaveFormModal = async (id: string, elementValues: IElementValue[]) => {
    try {
      const { doUpdateWorkflowForm, onChangeBackdrop } = this.props;
      await doUpdateWorkflowForm({ workflowFormId: id, isFromNote: true, formData: elementValues });
      this.setState({ isOpenFormModal: false });
      onChangeBackdrop(false);
      notification.success({
        message: 'Update workflow form success',
        description: 'You have successfully update workflow form',
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  render() {
    const { isOpenFormModal } = this.state;
    const { booking, groupBookingNotes, page, pageSize, isLoading, workflowForm } = this.props;

    // Loading
    if (isLoading) {
      return <ModalLoading text={'Fetching notes...'} />;
    }

    const hasNotes = !_.isEmpty(groupBookingNotes);
    const isNoNotesFound = !isLoading && !hasNotes;
    const defaultNotePrivacyVisible = booking ? booking.defaultNotePrivacyVisible : NoteVisibleType.PORTAL;

    return (
      <>
        {/* Main content */}
        <div className='anim-fade-in-fast'>
          {/* Add notes button */}
          <section className='mb-large'>
            <PrimaryButton icon='plus' onClick={this.onAddNote}>
              Add Note
            </PrimaryButton>
          </section>

          {isNoNotesFound ? (
            <EmptyNote />
          ) : (
            <div id='scroll-container' style={{ height: '500px', overflowY: 'auto' }}>
              <section className='bg-quaternary bordered border-standard-gray ph-medium pt-medium'>
                {hasNotes && (
                  <InfiniteScroll
                    hasMore={groupBookingNotes.length >= page * pageSize}
                    loadMore={async () => await this.props.doFetchMoreNotes()}
                  >
                    {_.map(groupBookingNotes, (note) => (
                      <BookingNoteItem
                        onEditNote={() => this.onEditNote({ targetNote: note })}
                        onDeleteNote={() => this.onDeleteNote({ targetNote: note })}
                        note={note}
                        timezone={this.props.booking.timezone}
                        doCheckViewWorkflowDetails={this.props.doCheckViewWorkflowDetails}
                        showFormModal={this.showFormModal}
                      />
                    ))}
                  </InfiniteScroll>
                )}
                {isLoading && (
                  <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className='anim-slide-left' />
                )}
              </section>
            </div>
          )}
        </div>

        {/* Modals */}
        {/* Add / Edit modal */}
        <AddEditGroupNoteModal
          isOpen={this.state.isAddNoteModalOpen}
          onClose={this.closeAddNoteModal}
          noteMode={this.state.noteMode}
          targetNote={this.state.targetNote}
          onSaveNoteAction={this.onSaveNoteAction}
          isAllowSelecteCustomer={false}
          booking={this.props.booking}
          selectedCustomers={this.state.selectedCustomers}
          handleSubmitIncidentNote={(noteData) => {
            this.setState({ ...this.state, groupBookingData: noteData });
            this.openAddIncidentNoteModal();
          }}
          defaultVisibleType={defaultNotePrivacyVisible}
        />

        <AddIncidentNoteModal
          isOpen={this.state.isAddIncidentNoteModalOpen}
          onClose={this.closeAddIncidentNoteModal}
          screenData={this.state.groupBookingData}
          isManual={false}
          moduleType={WorkflowTriggerModuleType.GROUP_BOOKING}
          onSaveNoteAction={this.onSaveNoteAction}
        />

        {/* Delete modal */}
        <DeleteGroupNoteModal
          isOpen={this.state.isDeleteNoteModalOpen}
          targetNote={this.state.targetNote}
          onClose={this.closeDeleteNoteModal}
          onDeleteNoteAction={this.onDeleteNoteAction}
        />

        {isOpenFormModal && (
          <EditValueFormModal
            isOpen={isOpenFormModal}
            mode='VIEW'
            formId={workflowForm.workflowFormId}
            formElements={workflowForm.formContent}
            elementValues={workflowForm.formData}
            canEditForm={workflowForm.canEditForm}
            onSave={this.onSaveFormModal}
            onClose={this.closeFormModal}
          />
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupBookingNotes: state.groupBookingsStore.groupBookingNotes,
  workflowForm: state.workflowStore.workflowForm,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupBookingNotes: dispatch.groupBookingsStore.doFetchGroupBookingNotes,
  doRemoveGroupBookingNote: dispatch.groupBookingsStore.doRemoveGroupBookingNote,
  doCheckViewWorkflowDetails: dispatch.workflowStore.doCheckViewWorkflowDetails,
  doGetWorkflowFormDetail: dispatch.workflowStore.doGetWorkflowFormDetail,
  doUpdateWorkflowForm: dispatch.workflowStore.doUpdateWorkflowForm,
});

export default connect(mapState, mapDispatch)(NotesPanel);
