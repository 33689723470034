import { PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React from 'react';
import { CANCEL_SUBSCRIPTION_LINKS } from 'views/account-management/subscription-management/utils/constants';

interface IThanksForFeedbackModalProps {
  visible: boolean;
  onClose: () => void;
}

const ThanksForFeedbackModal: React.FC<IThanksForFeedbackModalProps> = ({ visible, onClose }) => {
  return (
    <ActionModal title="Thanks for your feedback" isOpen={visible} onClose={onClose}>
      <Paragraph className="mt-small" size="x-large">
        Your subscription has been cancelled and you will not be billed for the upcoming billing cycle. To reactivate
        your subscription,&nbsp;
        <a className="text-color-blue-action" href={CANCEL_SUBSCRIPTION_LINKS.GET_SUPPORT} target="_blank">
          contact the GoodHuman team.
        </a>
      </Paragraph>

      <ActionModalFooter>
        <PrimaryButton size="large" onClick={onClose}>
          Close
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};

export default ThanksForFeedbackModal;
