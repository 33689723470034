import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Text } from '@good/components';
import { Modal } from 'design-components';
import moment from 'moment-timezone';
import { fetchExportInsightsData } from 'stores/queries/support-workers/support-workers-queries';

type ExportDataModalProps = {
  isOpen: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
  serviceIds: string[];
  onClose: () => void;
};

export const ExportDataModal: React.FC<ExportDataModalProps> = ({
  isOpen,
  startDate,
  endDate,
  serviceIds,
  onClose,
}) => {
  const [datePickersOpen, setDatePickersOpen] = useState(false);
  const [exportStartDate, setExportStartDate] = useState(startDate);
  const [exportEndDate, setExportEndDate] = useState(endDate);

  const { t } = useTranslation('', { keyPrefix: 'insights.exportModal' });

  useEffect(() => {
    setExportStartDate(startDate);
    const adjustedEndDate = endDate.isAfter(moment(), 'day') ? moment().endOf('day') : endDate;
    setExportEndDate(adjustedEndDate);
  }, [startDate, endDate]);

  const datePickerProps = {
    onOpenChange: setDatePickersOpen,
    format: 'ddd, DD MMM YYYY',
    allowClear: false,
  };

  const attemptClose = () => datePickersOpen || onClose();

  // Dates more than a year ago and beyond today's date are disabled
  const disableDateForFromField = (date: moment.Moment | null) => {
    if (date === null) {
      return false;
    }

    return date.isBefore(moment().subtract(1, 'year')) || date.isAfter(moment(), 'day');
  };
  // Dates beyond today are disabled
  const disabledDateForToField = (date: moment.Moment | null) => (date ? date.isAfter(moment(), 'day') : false);

  const endDateBeforeStart = exportEndDate.isBefore(exportStartDate);
  const endDateIsToday = exportEndDate.isSame(new Date(), 'day');

  return (
    <Modal
      header={t('heading')}
      isOpen={isOpen}
      onClose={attemptClose}
      footer={
        <>
          <Button emphasis="outline" onPress={attemptClose}>
            {t('cancelButtonLabel')}
          </Button>
          <Button
            emphasis="fill"
            isDisabled={endDateBeforeStart}
            onPress={() =>
              fetchExportInsightsData({
                startDate: exportStartDate.toDate(),
                endDate: exportEndDate.toDate(),
                serviceIds,
              })
            }
          >
            {t('exportButtonLabel')}
          </Button>
        </>
      }
    >
      <Text size="md">
        <Trans i18nKey="insights.exportModal.description" />
      </Text>
      <div className="flex-row space-x-3 pb-8 pt-2">
        <div className="flex-column w-1/2 pt-2">
          <Text size="sm" className="pb-2 font-bold">
            {t('fromLabel')}
          </Text>
          <DatePicker
            value={exportStartDate}
            onChange={(date) => date && setExportStartDate(date)}
            {...datePickerProps}
            disabledDate={disableDateForFromField}
          />
        </div>
        <div className="flex-column w-1/2 pt-2">
          {endDateBeforeStart ? (
            <Text size="sm" className="pb-2 font-bold text-critical">
              {t('endDateBeforeStartLabel')}
            </Text>
          ) : (
            <Text size="sm" className="pb-2 font-bold">
              {t('toLabel')}
            </Text>
          )}
          <DatePicker
            value={exportEndDate}
            {...datePickerProps}
            onChange={(date) => date && setExportEndDate(date)}
            disabledDate={disabledDateForToField}
          />
          {endDateIsToday && (
            <Text size="sm" className="pt-4">
              {t('note')}
            </Text>
          )}
        </div>
      </div>
    </Modal>
  );
};
