import React from 'react';
import clsx from 'clsx';

import { IndentDecrease } from '@good/icons';
import { Button, Text } from '@good/components';

type Props = {
  title: string;
  children?: React.ReactNode;
  className?: string;
};

const ToggleButton = (props: React.ComponentPropsWithRef<typeof Button>): JSX.Element => (
  <Button className="border" size="sm" {...props}>
    <IndentDecrease />
  </Button>
);

export const CustomerViewExpansionPanel = ({ title, className, children, ...props }: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleToggle = () => setIsExpanded((prev) => !prev);

  return isExpanded ? (
    <div className={clsx('flex w-64 flex-col gap-6 rounded-lg border border-base bg-white p-6', className)} {...props}>
      <div className="no-wrap inline-flex items-center gap-4">
        <ToggleButton onClick={handleToggle} />
        <Text size="xl" className="whitespace-nowrap font-bold">
          {title}
        </Text>
      </div>
      {children}
    </div>
  ) : (
    <ToggleButton onClick={handleToggle} />
  );
};
