import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ErrorSVG } from '../../../assets/UndrawSVG';
import { Paragraph } from 'common-components/typography';
import { GhostButton, PrimaryButton } from '../../../common-components/buttons';
import { Spinner } from '@blueprintjs/core';
import { notification } from 'antd';

enum Steps {
  CONFIRMATION,
  DELETING,
  ERROR,
}
class BookingBillingLineItemDeleteActionModel extends Component<any, any> {
  state = { step: Steps.CONFIRMATION, canManuallyClose: true };

  renderView = () => {
    const { step } = this.state;

    switch (step) {
      case Steps.CONFIRMATION:
        return (
          <div className="anim-fade-in">
            <div className="mb-medium">
              <Paragraph>
                Are you sure you want to delete this line item? This action is permanent and cannot be undone
              </Paragraph>
            </div>
            <ActionModalFooter>
              <GhostButton className="mr-medium" size="large" color="black" onClick={this.onCloseModal}>
                Cancel
              </GhostButton>
              <PrimaryButton size="large" color="red" onClick={this.onAccept}>
                Delete
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        );
      case Steps.DELETING:
        return (
          <div className="anim-slide-right">
            <div className="text-align-center">
              <div className="pv-large">
                <Spinner size={150} />
              </div>
              <Paragraph>Deleting this line Item, won&apos;t be long...</Paragraph>
            </div>
          </div>
        );
      case Steps.ERROR:
        return (
          <div className="anim-fade-in">
            <div className="pv-medium">
              <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
            </div>
            <div className="text-align-center">
              <Paragraph>Something Went wrong, Please try again</Paragraph>
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this.onCloseModal}>
                Go Back to Booking
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        );
      default:
        return <></>;
    }
  };

  onAccept = async () => {
    const { onAction } = this.props;
    try {
      this.setState({ step: Steps.DELETING, canManuallyClose: false });

      await onAction();

      notification.success({
        message: 'Line item deleted',
        description: 'The line item has been deleted.',
      });
    } catch (e) {
      this.onError();
    }
  };
  onError = () => {
    this.setState({ step: Steps.ERROR, canManuallyClose: true });
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    // Reset back to step 1
    this.setState({ step: Steps.CONFIRMATION, canManuallyClose: true }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title="Delete line item"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
      >
        {this.renderView()}
      </ActionModal>
    );
  }
}

export default BookingBillingLineItemDeleteActionModel;
