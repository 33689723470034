import { Empty } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';

export function EmptyNote() {
  return (
    <div className="mt-large p-medium bordered border-standard-gray rounded-big">
      <div className="flex-1 bg-white mv-large align-center flex-column">
        <div className="">
          <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
        </div>
        <Text size="x2-large" color="secondary" weight="bold">
          No notes found.
        </Text>{' '}
        <br /> <br />
        <Text color="secondary">Notes related to this booking for this customer will appear here.</Text>
      </div>
    </div>
  );
}
