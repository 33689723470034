import { Popover } from '@blueprintjs/core';
import { Avatar, Checkbox, Col, Icon, Popover as AntPopover, Row } from 'antd';
import { ActionMenu, ActionMenuDivider, ActionMenuItem } from 'common-components/action-menu';
import { GhostButton, HyperlinkButton, IconButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { StatusTag } from 'common-components/tags';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { MessageOrigin } from 'interfaces/message-interfaces';
import { IGroupServiceCustomer } from 'interfaces/service-interfaces';
import _, { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip2 } from '@blueprintjs/popover2';
import { ISessionCustomer, IAssignedBookingRatio, IBookingTimeSlot } from 'interfaces/session-interfaces';
import { IBookingRatio } from 'interfaces/booking-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import {
  BookingStatus,
  NoteVisibleType,
  PaymentStatus,
  PaymentStatusLabel,
  ServiceType,
  WorkflowTriggerModuleType,
  TeamMemberCustomerRatio,
} from 'utilities/enum-utils';
import BookingErrorIndicator from 'views/bookings/new-listings/components/details/BookingErrorIndicator';
import { AcceptGroupBookingModal } from 'views/group-services/booking-modals/AcceptGroupBookingModal';
import { AssignCustomerToWorkerModal } from 'views/group-services/booking-modals/AssignCustomerToWorker/AssignCustomerToWorkerModal';
import { ConfirmGroupBookingModal } from 'views/group-services/booking-modals/ConfirmGroupBookingModal';
import { DeclineGroupBookingModal } from 'views/group-services/booking-modals/DeclineGroupBookingModal';
import { FinishGroupBookingModal } from 'views/group-services/booking-modals/FinishGroupBookingModal';
import { StartGroupBookingModal } from 'views/group-services/booking-modals/StartGroupBookingModal';
import AddEditGroupNoteModal from 'views/group-services/components/booking-notes/AddEditGroupNoteModal';
import GroupBookingModal from 'views/group-services/manage-booking-modal/GroupBookingModal';
import { LineItemsTable } from 'views/group-services/manage-booking-modal/panels/billings/LineItemsTable';
import EditCustomerScheduleModal from 'views/group-services/manage-booking-modal/panels/overview/EditCustomerScheduleModal';
import AddIncidentNoteModal from 'views/workflows/trigger/AddIncidentNoteModal';
import AssignTeamMemberToBookingTimeSlotModal from 'views/group-services/booking-modals/AssignTeamMemberToBookingTimeSlotModal';
import ActivityGroupsSummaryTag from 'common-components/activity-groups/popover/ActivityGroupsSummaryTag';
import { Stack } from '../../../../common-components/stack';
import AssignBookingRatiosPanel from './AssignBookingRatiosPanel';
import BookingCancelActionModel from 'views/bookings/components/BookingCancellationModals/BookingCancelActionModal';
import { withRouter, WithRouterProps } from 'utilities/with-router';

const extMoment = extendMoment(moment);

type ICustomerItemProps = {
  customerItem: ISessionCustomer;
  ratios: IBookingRatio[];
  timezone: string;
  handleItemClick: (attendanceId: string, transportBookingType?: string) => void;
  openBookingBillingPanel: (attendanceId: string) => void;
  closeBookingBillingPanel: (attendanceId: string) => void;
  isBillingPanelOpened: boolean;
  isBillingPanelLoading: boolean;
  billings: {
    fundedCategories: FundingNdisSupportCategory[];
    attendanceId: string;
    billingLineItems: IBillingLineItem[]
  } | null;
  openSessionFull: () => void;
  refreshList: () => void;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  doAcceptGroupBooking: typeof dispatch.groupBookingsStore.doAcceptGroupBooking;
  doConfirmGroupBooking: typeof dispatch.groupBookingsStore.doConfirmGroupBooking;
  doStartGroupBooking: typeof dispatch.groupBookingsStore.doStartGroupBooking;
  doDeclineGroupBooking: typeof dispatch.groupBookingsStore.doDeclineGroupBooking;
  doFinishGroupBooking: typeof dispatch.groupBookingsStore.doFinishGroupBooking;
  doFetchGroupBookingOverview: typeof dispatch.groupBookingsStore.doFetchGroupBookingOverview;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  customerBookingToOpen: typeof state.groupServiceStore.customerBookingToOpen;
  setCustomerBookingToOpen: typeof dispatch.groupServiceStore.setCustomerBookingToOpen;

  // Added by Jir
  doAssignToTeamMember?: any;
} & WithRouterProps

type ICustomerItemState = {
  isManageBookingOpen: boolean;
  selectedAttendanceId: string;
  selectedBookingTab: { key: string; name: string } | null;
  isAcceptBookingOpen: boolean;
  isDeclineBookingOpen: boolean;
  isConfirmBookingOpen: boolean;
  isCancelBookingOpen: boolean;
  isStartBookingOpen: boolean;
  isEndBookingOpen: boolean;
  isEditTimesBookingOpen: boolean;
  isAddNoteModalOpen: boolean;
  isAssignToTeamMemberOpen: boolean;
  selectedCustomers: IGroupServiceCustomer[];
  currentCustomer: IGroupServiceCustomer;
  assignedBookingRatios: IAssignedBookingRatio[];
  isAddIncidentNoteModalOpen: boolean;
  groupBookingData: any;
  isAssignTeamMemberModalOpened: boolean;
  selectedTimeSlot: IBookingTimeSlot;
}

class CustomerItem extends Component<ICustomerItemProps, ICustomerItemState> {
  state = {
    isManageBookingOpen: false,
    selectedAttendanceId: null,
    selectedBookingTab: null,
    isAcceptBookingOpen: false,
    isDeclineBookingOpen: false,
    isConfirmBookingOpen: false,
    isCancelBookingOpen: false,
    isStartBookingOpen: false,
    isEndBookingOpen: false,
    isEditTimesBookingOpen: false,
    isAddNoteModalOpen: false,
    isAssignToTeamMemberOpen: false,
    selectedCustomers: [],
    currentCustomer: null,
    assignedBookingRatios: [],
    isAddIncidentNoteModalOpen: false,
    groupBookingData: {},
    isAssignTeamMemberModalOpened: false,
    selectedTimeSlot: {
      attendanceId: null,
      startDateTime: null,
      endDateTime: null,
      attendanceTimeSlotId: null,
      teamMemberCustomerRatio: null,
      supportWorkerAttendanceId: null,
      customerUserId: null,
      supportWorkerId: null,
    },
  };

  private _goToCustomer = async (customerUserId) => {
    const { history } = this.props;
    history.push(`/customer/details/${customerUserId}`);
  };

  public openManageBookingModal = (attendanceId, selectedTab = null) => {
    this.setState({ isManageBookingOpen: true, selectedAttendanceId: attendanceId, selectedBookingTab: selectedTab });
  };

  private _closeManageBookingModal = () => {
    this.setState({ isManageBookingOpen: false, selectedAttendanceId: null, selectedBookingTab: null });
    this.props.refreshList();
  };

  private _openAcceptBookingModal = (attendanceId) => {
    this.setState({ isAcceptBookingOpen: true, selectedAttendanceId: attendanceId });
  };

  private _closeAcceptBookingModal = () => {
    this.setState({ isAcceptBookingOpen: false, selectedAttendanceId: null });
    this.props.refreshList();
  };

  private _openDeclineBookingModal = (attendanceId) => {
    this.setState({ isDeclineBookingOpen: true, selectedAttendanceId: attendanceId });
  };

  private _closeDeclineBookingModal = () => {
    this.setState({ isDeclineBookingOpen: false, selectedAttendanceId: null });
    this.props.refreshList();
  };

  private _openConfirmBookingModal = (attendanceId) => {
    this.setState({ isConfirmBookingOpen: true, selectedAttendanceId: attendanceId });
  };

  private _closeConfirmBookingModal = () => {
    this.setState({ isConfirmBookingOpen: false, selectedAttendanceId: null });
    this.props.refreshList();
  };

  private _openCancelBookingModal = (attendanceId) => {
    this.setState({ isCancelBookingOpen: true, selectedAttendanceId: attendanceId });
  };

  private _closeCancelBookingModal = () => {
    this.setState({ isCancelBookingOpen: false, selectedAttendanceId: null });
    this.props.refreshList();
  };

  private _openStartBookingModal = (attendanceId) => {
    this.setState({ isStartBookingOpen: true, selectedAttendanceId: attendanceId });
  };

  private _closStartBookingModal = () => {
    this.setState({ isStartBookingOpen: false, selectedAttendanceId: null });
    this.props.refreshList();
  };

  private _openEndBookingModal = (attendanceId) => {
    this.setState({ isEndBookingOpen: true, selectedAttendanceId: attendanceId });
  };

  private _closeEndBookingModal = () => {
    this.setState({ isEndBookingOpen: false, selectedAttendanceId: null });
    this.props.refreshList();
  };

  private _openEditTimesBookingModal = (attendanceId) => {
    this.setState({ isEditTimesBookingOpen: true, selectedAttendanceId: attendanceId });
  };

  private _closeEditTimesBookingModal = () => {
    this.setState({ isEditTimesBookingOpen: false, selectedAttendanceId: null });
    this.props.refreshList();
  };

  private _openAddNoteModal = (attendanceId) => {
    this.setState({ isAddNoteModalOpen: true, selectedAttendanceId: attendanceId });
  };

  private _closeAddNoteModal = () => {
    this.setState({ isAddNoteModalOpen: false, selectedAttendanceId: null });
    this.props.refreshList();
  };

  private _openAssignToTeamMemberModal = (attendanceId) => {
    this.setState({ isAssignToTeamMemberOpen: true, selectedAttendanceId: attendanceId });
  };

  private _closeAssignToTeamMemberModal = () => {
    this.setState({ isAssignToTeamMemberOpen: false, selectedAttendanceId: null });
    this.props.refreshList();
  };
  private _openAddIncidentNoteModal = () =>
    this.setState({ isAddNoteModalOpen: false, selectedAttendanceId: null, isAddIncidentNoteModalOpen: true });
  private _closeAddIncidentNoteModal = () => this.setState({ isAddIncidentNoteModalOpen: false });

  private _onMessageCustomer = (customerItem) => {
    const { customerUserId: userId, firstName, lastName, avatarUrl } = customerItem;
    const { serviceId, serviceName } = this.props.selectedSession;

    const userName = `${firstName} ${lastName}`;

    this.props.history.push({
      pathname: '/messaging',
      state: { origin: MessageOrigin.BookingDetailsCustomer, serviceId, userId, serviceName, userName, avatarUrl },
    });
  };

  private _openAssignTeamMemberModal = (timeSlot) => {
    const { attendanceId, customerUserId } = this.props.customerItem;
    this.setState({
      isAssignTeamMemberModalOpened: true,
      selectedTimeSlot: { ...timeSlot, customerUserId, attendanceId },
    });
  };

  private _closeAssignTeamMemberModal = () => this.setState({ isAssignTeamMemberModalOpened: false });

  private _getPopoverContent = (customerItem) => {
    return (
      <ActionMenu>
        <ActionMenuItem text="Manage booking" onClick={() => this.openManageBookingModal(customerItem.attendanceId)} />
        {customerItem.status === BookingStatus.PENDING && (
          <>
            <ActionMenuItem
              text="Accept booking"
              onClick={() => this._openAcceptBookingModal(customerItem.attendanceId)}
            />
            <ActionMenuItem
              text="Decline request"
              onClick={() => this._openDeclineBookingModal(customerItem.attendanceId)}
            />
          </>
        )}
        {customerItem.status === BookingStatus.ACCEPTED && (
          <>
            <ActionMenuItem
              text="Confirm booking"
              onClick={() => this._openConfirmBookingModal(customerItem.attendanceId)}
            />
            <ActionMenuItem
              text="Cancel booking"
              onClick={() => this._openCancelBookingModal(customerItem.attendanceId)}
            />
          </>
        )}
        {customerItem.status === BookingStatus.CONFIRMED && (
          <>
            <ActionMenuItem
              text="Start booking"
              onClick={() => this._openStartBookingModal(customerItem.attendanceId)}
            />
          </>
        )}
        {customerItem.status === BookingStatus.INPROGRESS && (
          <>
            <ActionMenuItem text="End booking" onClick={() => this._openEndBookingModal(customerItem.attendanceId)} />
          </>
        )}
        {customerItem.status === BookingStatus.COMPLETED &&
          (customerItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL ||
            customerItem.paymentStatus === PaymentStatus.INITIAL) && (
            <>
              <ActionMenuItem
                text="Change check-in/out time"
                onClick={() => this._openEditTimesBookingModal(customerItem.attendanceId)}
              />
            </>
          )}
        <ActionMenuDivider />
        {/*Disabled until the modal is changed to accept multiple shift slot per customers*/}
        {/*{customerItem.status === BookingStatus.CONFIRMED && (*/}
        {/*  <ActionMenuItem*/}
        {/*    text="Assign to team member"*/}
        {/*    onClick={() => this._openAssignToTeamMemberModal(customerItem.attendanceId)}*/}
        {/*  />*/}
        {/*)}*/}
        <ActionMenuItem text="Message customer" onClick={() => this._onMessageCustomer(customerItem)} />
        <ActionMenuItem text="Add note" onClick={() => this._openAddNoteModal(customerItem.attendanceId)} />
        <ActionMenuDivider />
        <ActionMenuItem text="View customer profile" onClick={() => this._goToCustomer(customerItem.customerUserId)} />
      </ActionMenu>
    );
  };

  private _getTransportPopoverContent = (customerItem) => {
    return (
      <ActionMenu>
        <ActionMenuItem
          text="View booking details"
          onClick={() => this.openManageBookingModal(customerItem.attendanceId)}
        />
      </ActionMenu>
    );
  };

  private _openBookingDetails = () => {
    this.openManageBookingModal(
      this.props.customerBookingToOpen.attendanceId,
      this.props.customerBookingToOpen.selectedTab,
    );
    this.props.setCustomerBookingToOpen(null);
  };

  private _hasTeamMemberCustomerRatio = (ratio: IBookingRatio, teamMemberCustomerRatios: TeamMemberCustomerRatio[]) => {
    return teamMemberCustomerRatios.includes(ratio.teamMemberCustomerRatio as TeamMemberCustomerRatio);
  };

  private _findAssignedShift = (ratio: IBookingRatio) => {
    const { customerItem, timezone } = this.props;
    return _.find(customerItem.shiftCustomerAssignments, (shift) => {
      const shiftRange = extMoment.range(
        moment.tz(shift.startDateTime, timezone),
        moment.tz(shift.endDateTime, timezone),
      );
      const ratioRange = extMoment.range(
        moment.tz(ratio.startDateTime, timezone),
        moment.tz(ratio.endDateTime, timezone),
      );
      return isEmpty(ratioRange.subtract(shiftRange)[0]);
    });
  };

  private _filterAssignedShift = (ratio: IBookingRatio) => {
    const { customerItem, timezone } = this.props;
    return customerItem.shiftCustomerAssignments.filter((shift) => {
      const shiftRange = extMoment.range(
        moment.tz(shift.startDateTime, timezone),
        moment.tz(shift.endDateTime, timezone),
      );
      const ratioRange = extMoment.range(
        moment.tz(ratio.startDateTime, timezone),
        moment.tz(ratio.endDateTime, timezone),
      );
      return isEmpty(ratioRange.subtract(shiftRange)[0]);
    });
  };

  private _mapAssignedRatio = (ratio: IBookingRatio) => {
    const assignedRatio: IAssignedBookingRatio = {
      ratio,
    };

    if (this._hasTeamMemberCustomerRatio(ratio, [TeamMemberCustomerRatio.TWO_TO_ONE])) {
      const assignedShift = this._filterAssignedShift(ratio);
      if (!isEmpty(assignedShift)) {
        assignedRatio.supportWorkers = assignedShift;
      }
    } else {
      const assignedShift = this._findAssignedShift(ratio);
      if (!isEmpty(assignedShift)) {
        assignedRatio.supportWorker = assignedShift;
      }
    }

    return assignedRatio;
  };

  private _assignSupportWorkerToRatios = () => {
    const { ratios } = this.props;
    const assignedBookingRatios: IAssignedBookingRatio[] = _.chain(ratios)
      .filter(
        (ratio) =>
          this._hasTeamMemberCustomerRatio(ratio, [
            TeamMemberCustomerRatio.ONE_TO_ONE,
            TeamMemberCustomerRatio.TWO_TO_ONE,
          ]) && ratio.isDuration !== true,
      )
      .map(this._mapAssignedRatio)
      .orderBy((assignedRatio) => assignedRatio.ratio.startDateTime)
      .value();
    this.setState({ assignedBookingRatios: assignedBookingRatios });
  };

  componentDidMount() {
    if (this.props.customerBookingToOpen?.attendanceId === this.props.customerItem.attendanceId) {
      this._openBookingDetails();
    }

    this._assignSupportWorkerToRatios();
  }

  componentDidUpdate = async (prevProps: Readonly<ICustomerItemProps>, prevState: Readonly<ICustomerItemState>) => {
    if (this.props.customerBookingToOpen?.attendanceId === this.props.customerItem.attendanceId) {
      this._openBookingDetails();
    }
    if (this.state.selectedAttendanceId && prevState.selectedAttendanceId !== this.state.selectedAttendanceId) {
      await this.props.doFetchGroupBookingOverview({
        bookingId: this.state.selectedAttendanceId,
      });
    }
    if (
      this.props.selectedGroupBookingItem &&
      this.props.selectedGroupBookingItem !== prevProps.selectedGroupBookingItem
    ) {
      this.setState({
        currentCustomer: {
          taggedUserId: this.props.selectedGroupBookingItem.userId,
          taggedUserFirstName: this.props.selectedGroupBookingItem.firstName,
          taggedUserLastName: this.props.selectedGroupBookingItem.lastName,
          taggedUserAvatarUrl: this.props.selectedGroupBookingItem.attachmentUrl,
        },
        selectedCustomers: [],
      });
    }
    if (
      this.props.customerItem.shiftCustomerAssignments !== prevProps.customerItem.shiftCustomerAssignments ||
      this.props.ratios !== prevProps.ratios
    ) {
      this._assignSupportWorkerToRatios();
    }
  };

  render() {
    const { customerItem, selectedSession, timezone, isBillingPanelOpened, isBillingPanelLoading, billings } =
      this.props;
    const { selectedAttendanceId, selectedBookingTab, assignedBookingRatios, selectedTimeSlot } = this.state;

    const customerItemForModals = {
      ...customerItem,
      serviceId: selectedSession.serviceId,
      serviceDateTimeId: selectedSession.serviceDateTimeId,
      bookingId: selectedAttendanceId,
      timezone: selectedSession.timezone,
    };
    const defaultNotePrivacyVisible = selectedSession
      ? selectedSession.defaultNotePrivacyVisible
      : NoteVisibleType.PORTAL;

    const hasUnassignedRatio = assignedBookingRatios.some(
      (assignedRatio) =>
        isEmpty(assignedRatio.supportWorker) &&
        (isEmpty(assignedRatio.supportWorkers) || assignedRatio?.supportWorkers?.length < 2),
    );

    const isCancelled = [
      BookingStatus.CUSTOMER_CANCELLED,
      BookingStatus.CUSTOMER_CANCELLED_WITH_FEE,
      BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE,
      BookingStatus.BUSINESS_CANCELLED,
    ].includes(customerItem.status);

    const columnBordered = isBillingPanelOpened
      ? 'bordered rounded-big-top'
      : assignedBookingRatios.length === 0 || isCancelled
      ? 'bordered rounded-big'
      : 'bordered-top bordered-left bordered-right rounded-big-top';
    const columnBorderColor =
      hasUnassignedRatio && !isCancelled
        ? 'border-orange-lighter'
        : assignedBookingRatios.length !== 0 && !isCancelled
        ? 'border-green-lighter'
        : '';

    return (
      <>
        <Row className="mt-medium select-none" type={'flex'} align={'middle'}>
          <GroupBookingModal
            isOpen={this.state.isManageBookingOpen}
            onClose={this._closeManageBookingModal}
            selectedBookingId={selectedAttendanceId}
            initialSelectedTab={selectedBookingTab}
          />
          <BookingCancelActionModel
            isOpen={this.state.isCancelBookingOpen}
            onClose={this._closeCancelBookingModal}
            serviceType={ServiceType.GROUP}
          />
          <EditCustomerScheduleModal
            isOpen={this.state.isEditTimesBookingOpen}
            isEditSchedule={false}
            onClose={this._closeEditTimesBookingModal}
            booking={this.props.selectedGroupBookingItem}
          />
          <AcceptGroupBookingModal
            isOpen={this.state.isAcceptBookingOpen}
            booking={customerItemForModals}
            onClose={this._closeAcceptBookingModal}
            doAcceptGroupBooking={this.props.doAcceptGroupBooking}
          />
          <StartGroupBookingModal
            doStartGroupBooking={this.props.doStartGroupBooking}
            isOpen={this.state.isStartBookingOpen}
            onClose={this._closStartBookingModal}
            booking={customerItemForModals}
          />
          <ConfirmGroupBookingModal
            doConfirmGroupBooking={this.props.doConfirmGroupBooking}
            isOpen={this.state.isConfirmBookingOpen}
            onClose={this._closeConfirmBookingModal}
            booking={customerItemForModals}
          />
          <DeclineGroupBookingModal
            doDeclineGroupBooking={this.props.doDeclineGroupBooking}
            isOpen={this.state.isDeclineBookingOpen}
            onClose={this._closeDeclineBookingModal}
            booking={customerItemForModals}
          />
          <FinishGroupBookingModal
            doFinishGroupBooking={this.props.doFinishGroupBooking}
            isOpen={this.state.isEndBookingOpen}
            onClose={this._closeEndBookingModal}
            booking={customerItemForModals}
          />

          <AssignCustomerToWorkerModal
            doAssignToTeamMember={this.props.doAssignToTeamMember}
            isOpen={this.state.isAssignToTeamMemberOpen}
            onClose={this._closeAssignToTeamMemberModal}
            booking={customerItemForModals}
          />

          <AddEditGroupNoteModal
            isOpen={this.state.isAddNoteModalOpen}
            onClose={this._closeAddNoteModal}
            noteMode={'add'}
            targetNote={null}
            onSaveNoteAction={this._closeAddNoteModal}
            booking={this.props.selectedGroupBookingItem}
            isAllowSelecteCustomer={true}
            selectedCustomers={this.state.selectedCustomers}
            currentCustomer={this.state.currentCustomer}
            handleSubmitIncidentNote={(noteData) => {
              this.setState({ ...this.state, groupBookingData: noteData });
              this._openAddIncidentNoteModal();
            }}
            defaultVisibleType={defaultNotePrivacyVisible}
          />

          <AddIncidentNoteModal
            isOpen={this.state.isAddIncidentNoteModalOpen}
            onClose={this._closeAddIncidentNoteModal}
            onSaveNoteAction={this.props.refreshList}
            screenData={this.state.groupBookingData}
            moduleType={WorkflowTriggerModuleType.GROUP_BOOKING}
            isManual={false}
          />

          <AssignTeamMemberToBookingTimeSlotModal
            isOpen={this.state.isAssignTeamMemberModalOpened}
            booking={customerItemForModals}
            selectedTimeSlot={selectedTimeSlot}
            onClose={this._closeAssignTeamMemberModal}
          />

          <Col span={1}>
            <Checkbox
              checked={customerItem.isChecked}
              onChange={() => this.props.handleItemClick(customerItem.attendanceId)}
            />
          </Col>
          <Col span={23} className={`${columnBordered} ${columnBorderColor}`}>
            <Row type="flex" align="middle" className="ph-medium mv-medium">
              <Col span={5}>
                <div className="flex-row align-center pr-small">
                  <Avatar className={'mr-small'} shape={'circle'} icon={'user'} src={customerItem.customerAvatarUrl} />
                  <Paragraph className="mv-none" ellipsis={{ rows: 1 }}>
                    <Stack gap="xs">
                      <HyperlinkButton onClick={() => this._goToCustomer(customerItem.customerUserId)}>
                        {customerItem.firstName} {customerItem.lastName}
                      </HyperlinkButton>

                      {customerItem.activityGroups.length > 0 && (
                        <ActivityGroupsSummaryTag
                          activityGroups={customerItem.activityGroups}
                          timezone={customerItemForModals.timezone}
                        />
                      )}
                    </Stack>
                  </Paragraph>
                </div>
              </Col>
              <Col span={3}>
                {moment.tz(customerItem.startDateTime, timezone).format('h:mm A')}
                <br />
                {moment.tz(customerItem.endDateTime, timezone).format('h:mm A')}
              </Col>
              <Col span={4}>
                <Tooltip2 position="top" content={CommonUtils.formatTooltipStatusString(customerItem.status)}>
                  <StatusTag status={customerItem.status} size={'small'} />
                </Tooltip2>
              </Col>
              <Col span={4}>{customerItem.paymentStatus ? PaymentStatusLabel[customerItem.paymentStatus] : '-'}</Col>
              <Col span={3}>{customerItem.billingTotal ? CommonUtils.formatPrice(customerItem.billingTotal) : '-'}</Col>
              <Col span={1}>
                {isEmpty(customerItem.bookingErrors) ? (
                  <></>
                ) : (
                  <BookingErrorIndicator
                    bookingErrors={customerItem.bookingErrors}
                    billableType={customerItem.billableType}
                  />
                )}
              </Col>
              <Col span={3}>
                <SecondaryButton
                  onClick={
                    isBillingPanelOpened
                      ? () => this.props.closeBookingBillingPanel(customerItem.attendanceId)
                      : () => this.props.openBookingBillingPanel(customerItem.attendanceId)
                  }
                  size={'small'}
                  disabled={isBillingPanelLoading}
                >
                  Billings <Icon type={isBillingPanelOpened ? 'up' : 'down'} />
                </SecondaryButton>
              </Col>
              <Col span={1}>
                <Popover content={this._getPopoverContent(customerItem)} position={'bottom'} interactionKind="click">
                  <IconButton
                    icon={'ellipsis'}
                    size="default"
                    iconColor={'blue-action'}
                    color={'white'}
                    bordered={true}
                    className="border-standard-gray"
                  />
                </Popover>
              </Col>
            </Row>
          </Col>
          <Col span={1}>{/* This line is to adds space like the checkbox */}</Col>
          <Col span={23}>
            {!isBillingPanelOpened && assignedBookingRatios.length > 0 && !isCancelled && (
              <AssignBookingRatiosPanel
                onChangeTeamMember={this._openAssignTeamMemberModal}
                assignedBookingRatios={assignedBookingRatios}
                timezone={timezone}
              />
            )}
          </Col>
        </Row>
        {isBillingPanelOpened && (
          <div className="bg-tertiary p-medium ml-x2-large rounded-big-bottom">
            {!billings ? (
              <SpinningLoader size={20} message={'Retrieving billing...'} />
            ) : (
              <>
                <div className={'flex-row justify-between align-center'}>
                  <SubTitle>Line items for this customer</SubTitle>
                  <HyperlinkButton
                    onClick={() =>
                      this.openManageBookingModal(customerItem.attendanceId, { key: 'billings', name: 'Billings' })
                    }
                  >
                    Edit line items...
                  </HyperlinkButton>
                </div>
                <section className="mt-medium ph-medium pt-small pb-medium bordered border-standard-gray rounded-big shadow-container bg-white">
                  <LineItemsTable
                    tabType={'session'}
                    lineItems={billings.billingLineItems}
                    onEditLineItem={() => { /* do nothing */ }}
                    onDeleteLineItem={() => { /* do nothing */ }}
                    fundedCategories={billings.fundedCategories}
                    canAddEditLineItem={false}
                  />
                </section>
              </>
            )}
          </div>
        )}
        {customerItem && customerItem.transportBeforeBooking && (
          <TransportBookingRow
            key={'before'}
            customerItem={customerItem}
            type={'before'}
            menuItems={this._getTransportPopoverContent(customerItem)}
          />
        )}
        {customerItem && customerItem.transportAfterBooking && (
          <TransportBookingRow
            key={'after'}
            customerItem={customerItem}
            type={'after'}
            menuItems={this._getTransportPopoverContent(customerItem)}
          />
        )}
      </>
    );
  }
}

type ITransportBookingRowProps = {
  customerItem: ISessionCustomer;
  type: string;
  menuItems: JSX.Element;
}

function TransportBookingRow(props: ITransportBookingRowProps) {
  const { type, customerItem, menuItems } = props;
  const transportBooking = type === 'before' ? customerItem.transportBeforeBooking : customerItem.transportAfterBooking;
  return (
    <div className="bordered rounded-big mt-medium pl-medium pv-x-small ml-x7-large flex-row align-center">
      <div style={{ width: '112px' }}>
        <Icon type={'car'} className={'mr-small'} />
        {type === 'before' ? 'Before' : 'After'}
      </div>
      <div style={{ width: '138.83px' }}>
        <SubTitle>Assigned to</SubTitle>
        {transportBooking.firstName ? (
          <div
            className={'flex-row align-center width-full'}
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            title={transportBooking.firstName + ' ' + transportBooking.lastName}
          >
            <Avatar className={'mr-small'} shape={'circle'} icon={'user'} src={transportBooking.attachmentUrl} />{' '}
            {transportBooking.firstName} {transportBooking.lastName}
          </div>
        ) : (
          <Text color={'secondary'}>Unassigned</Text>
        )}
      </div>
      <div style={{ width: '145.05px' }}>
        <StatusTag status={transportBooking.status} size={'small'} />
      </div>
      <div style={{ width: '145.05px' }}>
        {transportBooking.paymentStatus ? PaymentStatusLabel[transportBooking.paymentStatus] : '-'}
      </div>
      <div style={{ width: '108.83px' }}>
        {transportBooking.billingTotal ? CommonUtils.formatPrice(transportBooking.billingTotal) : '-'}
      </div>
      <div style={{ width: '36.27px' }}>
        {isEmpty(transportBooking.bookingErrors) ? (
          <></>
        ) : (
          <BookingErrorIndicator bookingErrors={customerItem.bookingErrors} billableType={customerItem.billableType} />
        )}
      </div>
      <div style={{ width: '108.83px' }} />
      <div style={{ width: '36.27px' }}>
        <Popover content={menuItems} position={'bottom'} interactionKind="click">
          <div
            className="bordered border-standard-gray rounded text-align-center"
            style={{ width: '28px', height: '28px' }}
          >
            <GhostButton icon={'ellipsis'} size={'small'} paddingSize={'x-small'} />
          </div>
        </Popover>
      </div>
    </div>
  );
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem,
  customerBookingToOpen: state.groupServiceStore.customerBookingToOpen,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAcceptGroupBooking: dispatch.groupBookingsStore.doAcceptGroupBooking,
  doConfirmGroupBooking: dispatch.groupBookingsStore.doConfirmGroupBooking,
  doStartGroupBooking: dispatch.groupBookingsStore.doStartGroupBooking,
  doDeclineGroupBooking: dispatch.groupBookingsStore.doDeclineGroupBooking,
  doFinishGroupBooking: dispatch.groupBookingsStore.doFinishGroupBooking,
  doFetchGroupBookingOverview: dispatch.groupBookingsStore.doFetchGroupBookingOverview,
  setCustomerBookingToOpen: dispatch.groupServiceStore.setCustomerBookingToOpen,
});

export default connect(mapState, mapDispatch)(withRouter(CustomerItem));
