import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import PermissionUtils from 'utilities/permission-utils';
import { CreateWorkflow } from '../workflows/workflow-templates/create/index';
import { ICrumb } from 'interfaces/common-interface';
import { IRootDispatch, IRootState, dispatch, state } from 'stores/rematch/root-store';

import type { History } from 'history';

const crumbs: ICrumb[] = [
  {
    title: 'Account Management',
    target: '/account/landing',
  },
  {
    title: 'Workflow templates',
    target: '/account/workflows/',
  },
  {
    title: 'Create',
  },
];

interface CreateWorkflowTemplateProps {
  doGetWorkflowTemplates: typeof dispatch.workflowStore.doGetWorkflowTemplates;
  history: History;
  portalUser: typeof state.authStore.portalUser;
  workflowTemplates: typeof state.workflowStore.workflowTemplates;
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  workflowTemplates: state.workflowStore.workflowTemplates,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetWorkflowTemplates: dispatch.workflowStore.doGetWorkflowTemplates,
});

export const CreateWorkflowTemplate = connect(
  mapState,
  mapDispatch,
)(function CreateWorkflowTemplate(props: CreateWorkflowTemplateProps) {
  const { portalUser, history } = props;

  useEffect(
    function authCheck() {
      if (!PermissionUtils.validatePermission('ViewAddEditWorkflow', portalUser.permissions.permissionRoles))
        history.push('/access-denied');
    },
    [history, portalUser.permissions.permissionRoles],
  );

  return (
    <div id='content-container'>
      <BreadcrumbNav icon='home' theme='filled' crumbs={crumbs} isBordered={false} />

      <div className='width-full height-full' style={{ maxWidth: '1152px', margin: '0 auto' }}>
        <div>
          <div className='mt-x2-large mb-large'>
            <CreateWorkflow />
          </div>
        </div>
      </div>
    </div>
  );
});
