import { Modal, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import React, { useState } from 'react';

interface IRemoveActivityGroupItemModalProps {
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
  activityGroupName: string;
}

function RemoveActivityGroupItemModal(props: IRemoveActivityGroupItemModalProps) {
  const { isOpen, activityGroupName, onClose, onConfirm } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const _handleSubmit = () => {
    setIsLoading(true);
    onConfirm();
    setIsLoading(false);
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      title={
        <Text size="x2-large" weight="bold">
          Remove activity group
        </Text>
      }
      footer={null}
      className="ant-modal-custom"
    >
      <div className="mt-small">
        <Row>
          <Paragraph>
            You’re about to remove <b>{activityGroupName}</b> from this session.
          </Paragraph>
        </Row>
        <ActionModalFooter>
          <SecondaryButton color="secondary" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton color={'red'} className="ml-medium" size="large" onClick={_handleSubmit} loading={isLoading}>
            Remove activity group
          </PrimaryButton>
        </ActionModalFooter>
      </div>
    </Modal>
  );
}

export default React.memo(RemoveActivityGroupItemModal);
