import React, { Component } from 'react';
import { Row, Col, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { Text, SubTitle } from 'common-components/typography';
import ActionModal from 'common-components/modal/ActionModal';
import _ from 'lodash';
import { GhostButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { Information } from 'common-components/alerts';
import SpinningLoader from 'common-components/loading/SpinningLoader';

interface IAddNDISGroupLineItemsModalProps {
  closeModal: () => void;
  isOpen: boolean;
  saveLineItem: (lineItems: any) => void;
  groupLineItems: any;
  selectedLineItem: any;
  alreadyAddedLineItems: any;
  alreadySavedLineItems: any;
}

interface IAddNDISGroupLineItemsModalState {
  isLoading: boolean;
  selectedGroupLineItems: any;
}

export default class AddNDISGroupLineItemsModal extends Component<
  IAddNDISGroupLineItemsModalProps,
  IAddNDISGroupLineItemsModalState
> {
  state = {
    isLoading: true,
    selectedGroupLineItems: [],
  };

  private _saveLineItems = async (addOnlySelected = false) => {
    const { saveLineItem, selectedLineItem, closeModal } = this.props;
    const { selectedGroupLineItems } = this.state;
    if (addOnlySelected) {
      saveLineItem([selectedLineItem]);
      closeModal();
    } else {
      const newLineItems = selectedGroupLineItems;
      newLineItems.unshift(selectedLineItem);
      saveLineItem(newLineItems);
      closeModal();
    }
  };

  private _closeModal = () => {
    this.setState({ isLoading: false, selectedGroupLineItems: [] });
    this.props.closeModal();
  };

  private _addSelection = (item) => {
    const { selectedGroupLineItems } = this.state;

    const isChecked = !_.find(
      selectedGroupLineItems,
      (lineItem) => lineItem.SupportItemNumber === item.SupportItemNumber,
    );

    let newSelections = [];
    if (isChecked) {
      if (!_.isEmpty(selectedGroupLineItems)) {
        newSelections = selectedGroupLineItems;
        newSelections.push(item);
      } else {
        newSelections = [item];
      }
    } else {
      newSelections = _.filter(
        selectedGroupLineItems,
        (lineItem) => lineItem.SupportItemNumber !== item.SupportItemNumber,
      );
    }
    this.setState({ selectedGroupLineItems: [...newSelections] });
  };

  private _selectAll = () => {
    const { groupLineItems, alreadyAddedLineItems, alreadySavedLineItems } = this.props;
    let newSelection = _.filter(
      groupLineItems,
      (lineItem) =>
        !_.find(
          alreadyAddedLineItems,
          (addedLineItem) => lineItem.SupportItemNumber === addedLineItem.SupportItemNumber,
        ),
    );
    newSelection = _.filter(
      newSelection,
      (lineItem) =>
        !_.find(
          alreadySavedLineItems,
          (savedLineItem) => lineItem.SupportItemNumber === savedLineItem.supportItemNumber,
        ),
    );
    this.setState({ selectedGroupLineItems: newSelection });
  };

  private _unselectAll = () => {
    this.setState({ selectedGroupLineItems: [] });
  };

  componentDidMount() {
    this._selectAll();
    this.setState({
      isLoading: false,
    });
  }

  componentDidUpdate = async (
    prevProps: Readonly<IAddNDISGroupLineItemsModalProps>,
    prevState: Readonly<IAddNDISGroupLineItemsModalState>,
    snapshot?: any,
  ) => {
    if (!prevProps.isOpen && this.props.isOpen) {
      this._selectAll();
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { selectedLineItem, groupLineItems, alreadyAddedLineItems, alreadySavedLineItems } = this.props;
    const { isLoading, selectedGroupLineItems } = this.state;

    return (
      <div>
        <ActionModal
          isOpen={this.props.isOpen}
          title={
            <>
              Add <b>NDIS Line Items</b>
            </>
          }
          onClose={this._closeModal}
          width={'large'}
        >
          <div className="anim-slide-left">
            <Information
              className={'mt-small mb-large'}
              content={
                <>
                  <Text weight={'bold'}>
                    The line item you have selected has variations that we recommend adding to the service as well.
                  </Text>
                  <br />
                  Please review if you would like to add the associated line items.
                </>
              }
            />
            {selectedLineItem && (
              <>
                <SubTitle>Selected line item</SubTitle>
                <div className={'mb-large mt-small'}>
                  {selectedLineItem.SupportItemNumber}
                  <br />
                  {selectedLineItem.SupportItem}
                </div>

                <SubTitle>Associated line items</SubTitle>
                <Row type={'flex'} justify={'space-between'} className={'mt-small'}>
                  <Col>
                    <HyperlinkButton onClick={this._selectAll}>Select all</HyperlinkButton>
                  </Col>
                  <Col>
                    <HyperlinkButton onClick={this._unselectAll}>Clear selection</HyperlinkButton>
                  </Col>
                </Row>
                <div style={{ height: '300px', overflow: 'auto' }} className={'bordered mt-small'}>
                  {isLoading ? (
                    <SpinningLoader size={50} message={'Retrieving line items..'} />
                  ) : (
                    _.map(groupLineItems, (associatedLineItem, key) => {
                      const isDisabled =
                        !!_.find(
                          alreadyAddedLineItems,
                          (existingLineItem) =>
                            existingLineItem.SupportItemNumber === associatedLineItem.SupportItemNumber,
                        ) ||
                        !!_.find(
                          alreadySavedLineItems,
                          (existingLineItem) =>
                            existingLineItem.supportItemNumber === associatedLineItem.SupportItemNumber,
                        );
                      const isChecked = !!_.find(
                        selectedGroupLineItems,
                        (selection) => selection.SupportItemNumber === associatedLineItem.SupportItemNumber,
                      );
                      return (
                        <Row
                          className={`pv-small bordered-bottom ${
                            isDisabled ? 'bg-secondary' : 'hover-bg-tertiary cursor-pointer'
                          }`}
                          key={key}
                          onClick={() => (!isDisabled ? this._addSelection(associatedLineItem) : false)}
                        >
                          <Col span={2} className={'text-align-center'}>
                            {!isDisabled && (
                              <Checkbox checked={isChecked} value={associatedLineItem.SupportItemNumber} />
                            )}
                          </Col>
                          <Col span={22}>
                            {associatedLineItem.SupportItemNumber}{' '}
                            {isDisabled && <Text weight={'bold'}>(Already added to service)</Text>}
                            <br />
                            {associatedLineItem.SupportItem}
                          </Col>
                        </Row>
                      );
                    })
                  )}
                </div>
              </>
            )}

            <div className={'mb-small mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <Col>
                  <GhostButton size={'large'} onClick={() => this._saveLineItems(true)}>
                    Add only the line item selected
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton size={'large'} onClick={() => this._saveLineItems(false)}>
                    Add Associated Line items
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
      </div>
    );
  }
}
