import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { notification, Skeleton } from 'antd';
import { Text } from 'common-components/typography';
import { ICard } from 'interfaces/subscription-interfaces';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';
import { COMMON_MODAL_ACTION } from 'views/account-management/subscription-management/utils/constants';
import AddCreditCardModal from '../components/AddCreditCardModal';
import CommonModal from '../components/CommonModal';
import EditCardModal from '../components/EditCardModal';
import PaymentMethodListing from '../components/PaymentMethodListing';
import { STRIPE_PUBLISHABLE_KEY } from '../../../../../../../config/app-config';

const stripePublishableKey = STRIPE_PUBLISHABLE_KEY;

const stripePromise = loadStripe(stripePublishableKey);

interface IProps {
  paymentMethods: ICard[];
  portalUser: typeof state.authStore.portalUser;
}

const PaymentMethodsSection = (props: IProps) => {
  const { portalUser, paymentMethods } = props;

  const dispatch = useDispatch<IRootDispatch>();

  const { clientSecret } = useSelector((state: IRootState) => ({
    clientSecret: state.subscriptionStore.clientSecret,
  }));

  const isAllowUpdateSubscriptionCard = PermissionUtils.validatePermission(
    'UpdateSubscriptionCard',
    portalUser.permissions.permissionRoles,
  );

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectedCard, setSelectedCard] = useState<ICard>(null);
  const [isOpenAddCreditCard, setIsOpenAddCreditCard] = useState(false);
  const [isOpenEditCardModal, setIsOpenEditCardModal] = useState(false);
  const [commonModalState, setCommonModalState] = useState<{ isOpen: boolean; type: COMMON_MODAL_ACTION }>({
    isOpen: false,
    type: undefined,
  });

  const _onClose = () => {
    setSelectedCard(null);
    setCommonModalState({ isOpen: false, type: undefined });
  };

  const _fetchData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        dispatch.subscriptionStore.doGetPaymentMethods(),
        dispatch.subscriptionStore.doCreateSetupIntent(),
      ]);
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e });
    }
    setLoading(false);
  };

  const _submitRemoveCard = async () => {
    setUpdating(true);
    const response = await dispatch.subscriptionStore.doRemovePaymentMethod(selectedCard?.id);
    if (response) {
      setCommonModalState({ ...commonModalState, type: COMMON_MODAL_ACTION.REMOVE_SUCCESS });
      await _fetchData();
    } else {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
    setUpdating(false);
  };

  const _updateExpiryCard = async (payload) => {
    setUpdating(true);
    const response = await dispatch.subscriptionStore.doUpdateExpiryOfPaymentMethod({
      ...payload,
      cardId: selectedCard?.id,
    });
    if (response) {
      setIsOpenEditCardModal(false);
      notification.success({
        message: <Text weight="bold">Update Expiry Payment Method</Text>,
        description: 'You have successfully updated expiry payment method.',
      });
      await _fetchData();
    } else {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
    setUpdating(false);
  };

  const _setDefaultPaymentMethod = async (card: ICard) => {
    const response = await dispatch.subscriptionStore.doSetDefaultPaymentMethod(card.id);
    if (response) {
      notification.success({
        message: <Text weight="bold">Update Default Payment Method</Text>,
        description: 'You have successfully updated default payment method.',
      });
      _fetchData();
    } else {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  if (loading) {
    return <Skeleton className="anim-slide-left" active title />;
  }

  return (
    <>
      <PaymentMethodListing
        timeZone={portalUser.timeZone}
        hasPermission={isAllowUpdateSubscriptionCard}
        paymentMethods={paymentMethods}
        setDefaultPayment={_setDefaultPaymentMethod}
        openCreditCardModal={() => {
          setIsOpenAddCreditCard(true);
        }}
        openEditCardModal={(card: ICard) => {
          setSelectedCard(card);
          setIsOpenEditCardModal(true);
        }}
        openCommonModal={(type: COMMON_MODAL_ACTION, card?: ICard) => {
          setSelectedCard(card);
          setCommonModalState({ isOpen: true, type });
        }}
      />

      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
          <AddCreditCardModal
            fetchData={_fetchData}
            isOpen={isOpenAddCreditCard}
            onClose={() => setIsOpenAddCreditCard(false)}
          />
        </Elements>
      )}

      <EditCardModal
        cardDetail={selectedCard}
        loading={updating}
        isOpen={isOpenEditCardModal}
        onClose={() => setIsOpenEditCardModal(false)}
        onUpdate={_updateExpiryCard}
      />

      <CommonModal
        loading={updating}
        isOpen={commonModalState.isOpen}
        modalType={commonModalState.type}
        onRemove={_submitRemoveCard}
        onClose={_onClose}
      />
    </>
  );
};

export default PaymentMethodsSection;
