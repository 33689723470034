import React from 'react';
import { Text } from '@good/components';
import { Skeleton } from '@good/ui/core';

import { reactTrpc } from 'utilities/react-trpc';
import { useSilServiceContext } from '../sil-service-provider/sil-service-provider';

export const SilServiceDepartmentLabel = () => {
  const { service, isLoading, isError } = useSilServiceContext();
  const {
    data: serviceDepartments,
    isLoading: isLoadingServiceDepartments,
    isError: isErrorServiceDepartments,
  } = reactTrpc.serviceProvider.serviceDepartments.list.useQuery(
    {
      serviceProviderId: service?.serviceProviderId ?? '',
    },
    {
      enabled: !isLoading && !isError && Boolean(service),
    },
  );

  const departmentName = (() => {
    if (isLoading || isError || isErrorServiceDepartments) {
      return '-';
    }

    if (service) {
      return (
        serviceDepartments?.find(({ serviceDepartmentId }) => service.serviceDepartmentId === serviceDepartmentId)
          ?.serviceDepartmentName ?? '-'
      );
    }

    return '-';
  })();

  const isLoadingDepartmentName = isLoadingServiceDepartments || isLoading;
  const hasNoService = !isError && !isLoading && !service;

  return (
    <Skeleton visible={isLoadingDepartmentName && !isError && !hasNoService}>
      <Text asChild size='sm'>
        <p>{departmentName}</p>
      </Text>
    </Skeleton>
  );
};
