import React, { forwardRef } from 'react';
import { useController } from 'react-hook-form';

import { Checkbox as BaseCheckbox, SelectField as _SelectField, TextField as _TextField } from '.';

import type Select from 'antd/lib/select';
import type {
  CheckboxProps as BaseCheckboxProps,
  SelectFieldProps as _SelectFieldProps,
  TextFieldProps as _TextFieldProps,
} from '.';
import type { SelectValue } from 'antd/lib/select';
import type { UseControllerProps } from 'react-hook-form';

/********************************************************************
 * Checkbox
 *******************************************************************/

export type CheckboxProps = UseControllerProps & Omit<BaseCheckboxProps, 'onChange'>;

/**
 * @name
 * Checkbox
 *
 * @description
 * Controlled `Checkbox` for usage within forms.
 *
 * @example
 * let { control, register } = useForm()
 * <Checkbox name="accept" label="Accept" control={control} {...register('accept')}
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(props, ref) {
  const {
    field: { ref: inputRef, name, onBlur, onChange, value },
  } = useController(props);

  return (
    <BaseCheckbox
      {...props}
      defaultSelected={value}
      inputRef={inputRef}
      isSelected={value}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
    />
  );
});

/********************************************************************
 * SelectField
 *******************************************************************/

export type SelectFieldProps = UseControllerProps & Omit<_SelectFieldProps, 'onBlur' | 'onChange'>;

/**
 * @name
 * SelectField
 *
 * @description
 * Controlled `SelectField` for usage within forms.
 *
 * @example
 * let { control, register } = useForm()
 *
 * <SelectField
 *   label="Favourite fod"
 *   control={control}
 *   {...register('food')}
 * >
 *   <Select.Option value="pizza">Pizza</Select.Option>
 *   <Select.Option value="dumplings">Dumplings</Select.Option>
 * </SelectField>
 */
export const SelectField = forwardRef<Select<SelectValue>, SelectFieldProps>(function SelectField(props, ref) {
  const {
    field: { name, onBlur, onChange, ref: inputRef, value },
    fieldState: { error },
  } = useController(props);

  return (
    <_SelectField
      {...props}
      errorMessage={error?.message}
      inputRef={inputRef}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      ref={ref}
      value={value}
    />
  );
});

/********************************************************************
 * TextField
 *******************************************************************/

export type TextFieldProps = UseControllerProps & Omit<_TextFieldProps, 'isRequired' | 'onChange'>;

/**
 * @name
 * TextField
 *
 * @description
 * Controlled `TextField` for usage within forms.
 *
 * @example
 * let { control, register } = useForm()
 *
 * <TextField
 *   label="Full name"
 *   control={control}
 *   {...register('name', { required: 'Full name is required.' })}
 * />
 */
export const TextField = forwardRef<HTMLTextAreaElement | HTMLInputElement, TextFieldProps>(function TextField(
  props,
  ref,
) {
  const {
    field: { ref: inputRef, name, onBlur, onChange, value },
    fieldState: { error },
  } = useController(props);

  return (
    <_TextField
      {...props}
      errorMessage={error?.message}
      inputRef={inputRef}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      value={value}
    />
  );
});

export const Forms = {
  Checkbox,
  SelectField,
  TextField,
};
