import type { PropsWithChildren } from 'react';
import React from 'react';
import { BaseStyleProps, compose, css } from '../..';
import { Announce, Critical, Notice, Positive } from '../../icon';
import { AlertVariants } from '../alert.css';
import { AlertTitleIconProvider } from './alert-title-provider';
import * as styles from './alert-title.css';

export type AlertTitleProps = PropsWithChildren<BaseStyleProps & AlertVariants>;

/**
 * @name
 * Alert Title
 *
 * @example
 */
export const AlertTitle = (props: AlertTitleProps) => {
  const { children, kind, tone, middle, boldTitle, emphasis } = props;

  let iconType;
  let iconColor;
  switch (tone) {
    case 'hero':
      iconType = <Announce />;
      iconColor = '$lavender';
      break;
    case 'success':
      iconType = <Positive />;
      iconColor = '$green';
      break;
    case 'warning':
      iconType = <Notice />;
      iconColor = '$orange';
      break;
    case 'danger':
      iconType = <Critical />;
      iconColor = '$red';
      break;
    default:
      iconType = <Announce />;
      iconColor = '$lavender';
      break;
  }

  return (
    <div
      className={compose(
        css(styles.alertTitle)(),
        styles.alertTitleVariants({
          boldTitle,
          middle,
          kind,
          tone,
          emphasis,
        }),
      )}
    >
      <AlertTitleIconProvider color={iconColor}>
        {iconType} {children}
      </AlertTitleIconProvider>
    </div>
  );
};
