import React, { Component } from 'react';
import { Avatar, Col, Form, Icon, Input, Popover, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { IAddCustomerDetailWizard, IAddCustomerGuardianDetailWizard } from 'interfaces/customer-interfaces';
import { GhostButton } from 'common-components/buttons';

interface IConnectionRowProps extends FormComponentProps {
  customerDetail: IAddCustomerDetailWizard;
  guardianDetail?: IAddCustomerGuardianDetailWizard;
  validateMobileNumber?: (rule, value, callback) => void;
  hasRemoveButton?: boolean;
  removeGuardian?: (guardianUserId) => void;
}

interface IConnectionRowState {}

class ConnectionRow extends Component<IConnectionRowProps, IConnectionRowState> {
  state = {};

  render() {
    const { form, customerDetail, guardianDetail, validateMobileNumber, hasRemoveButton, removeGuardian } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Row className="bg-white bordered-top bordered-bottom pv-small" type="flex" align="middle">
        {/*<Col span={2} className="mt-small">*/}
        {/*  <Icon type="close-circle" className="text-color-tertiary mr-x-small" theme="filled" />*/}
        {/*  Not yet*/}
        {/*</Col>*/}
        {/*<Col span={16} className="flex-row align-center flex-wrap">*/}
        {!customerDetail.hasGuardians ? (
          <>
            <Col span={8} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <div className="flex-row align-center ml-small">
                <div style={{ width: '50px' }}>
                  <Avatar icon="user" shape="circle" size="large" src="" />
                </div>
                <div>
                  <b>
                    {customerDetail.firstName} {customerDetail.lastName}
                  </b>
                </div>
              </div>
            </Col>
            <Col span={6} className="">
              <Form.Item className={'m-none'}>
                {getFieldDecorator('mobile', {
                  initialValue: customerDetail.mobile,
                  rules: [
                    {
                      validator: validateMobileNumber,
                    },
                  ],
                })(<Input style={{ width: '250px' }} size="large" addonBefore="+61(AU)" placeholder="Mobile number" />)}
                {getFieldDecorator('targetUserId', {
                  initialValue: customerDetail.userId,
                })}
              </Form.Item>
            </Col>
            <Col span={10} className="">
              <Form.Item className={'mb-none'}>
                {getFieldDecorator('email', {
                  initialValue: customerDetail.email,
                  rules: [
                    {
                      required: true,
                      type: 'email',
                    },
                  ],
                })(<Input style={{ width: '250px' }} size="large" placeholder="Email" />)}
              </Form.Item>
            </Col>
          </>
        ) : (
          <>
            {/*  <Row className="flex-row">
            <Col span={4} className="flex-row align-center"><div>*/}
            {/*    <Text weight="bold" className="mr-medium mb-none">*/}
            {/*      {guardianDetail.firstName} {guardianDetail.lastName}*/}
            {/*    </Text>*/}
            {/*  </div>*/}
            {/*</Col>*/}
            <Col span={8} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <div className="flex-row align-center ml-small">
                <div style={{ width: '50px' }}>
                  <Avatar icon="user" shape="circle" size="large" src="" />
                </div>
                <div>
                  <b>
                    {guardianDetail.firstName} {guardianDetail.lastName}
                  </b>
                  <br />
                  Parent/Guardian
                </div>
              </div>
            </Col>
            <Col span={6} className="">
              <Form.Item className={'m-none'}>
                {getFieldDecorator('mobile', {
                  initialValue: guardianDetail.mobile,
                  rules: [
                    {
                      validator: validateMobileNumber,
                    },
                  ],
                })(<Input style={{ width: '250px' }} size="large" addonBefore="+61(AU)" placeholder="Mobile number" />)}
                {getFieldDecorator('targetUserId', {
                  initialValue: guardianDetail.guardianUserId,
                })}
              </Form.Item>
            </Col>
            <Col span={9} className="">
              <Form.Item className={'mb-none'}>
                {getFieldDecorator('email', {
                  initialValue: guardianDetail.email,
                  rules: [
                    {
                      required: true,
                      type: 'email',
                    },
                  ],
                })(<Input style={{ width: '250px' }} size="large" placeholder="Email" />)}
              </Form.Item>
            </Col>
            {hasRemoveButton && (
              <Col span={1}>
                <Popover
                  placement="bottomRight"
                  className="cursor-pointer"
                  content={
                    <div>
                      {/*{connection.status === 'CONNECTED' && <GhostButton>Cancel connection request</GhostButton>}*/}
                      <GhostButton onClick={() => removeGuardian(guardianDetail.guardianUserId)}>
                        Remove from this list
                      </GhostButton>
                    </div>
                  }
                >
                  <Icon type={'ellipsis'} />
                </Popover>
              </Col>
            )}
            {/*</Row>*/}
          </>
        )}
        {/*</Col>*/}
      </Row>
    );
  }
}
export default Form.create<IConnectionRowProps>()(ConnectionRow);
