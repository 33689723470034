import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ErrorSVG } from 'assets/UndrawSVG';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Col, Form, InputNumber, Row } from 'antd';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import NumberInput from 'common-components/inputs/NumberInput';

interface IBillingModifyTravelClaimModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  selectedBookingItem: any;
  travelClaimType: string;
  doUpdateBookingTravelClaims: typeof dispatch.bookingsStore.doUpdateBookingTravelClaims;
}

class BillingModifyTravelClaimModal extends Component<IBillingModifyTravelClaimModalProps, any> {
  state = {
    step: 1,
    title: 'Modify travel claims',
  };
  private _onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, title: 'Modify travel claims' });
    onClose();
  };

  private _onSubmitChanges = () => {
    const { form, doUpdateBookingTravelClaims, selectedBookingItem, travelClaimType } = this.props;
    form.validateFields(async (err, value) => {
      if (!err) {
        this.setState({ step: 2 });

        try {
          if (travelClaimType === 'DURING_BOOKING') {
            const payload = {
              bookingId: selectedBookingItem.bookingId,
              isTravelDuringBooking:
                Number(value.travelDistanceDuringBooking) > 0 || Number(value.additionalCostDuringBooking) > 0,
              travelDistanceDuringBooking: Number(value.travelDistanceDuringBooking),
              additionalCostDuringBooking: Number(value.additionalCostDuringBooking),
              isTravelBeforeBooking: selectedBookingItem.isTravelBeforeBooking,
              travelDistanceBeforeBooking: Number(selectedBookingItem.travelDistanceBeforeBooking),
              travelTimeBeforeBooking: Number(selectedBookingItem.travelTimeBeforeBooking),
              additionalCostBeforeBooking: Number(selectedBookingItem.additionalCostBeforeBooking),
            };
            await doUpdateBookingTravelClaims(payload);
          } else {
            const payload = {
              bookingId: selectedBookingItem.bookingId,
              isTravelDuringBooking: selectedBookingItem.isTravelDuringBooking,
              travelDistanceDuringBooking: Number(selectedBookingItem.travelDistanceDuringBooking),
              additionalCostDuringBooking: Number(selectedBookingItem.additionalCostDuringBooking),
              isTravelBeforeBooking:
                Number(value.travelDistanceBeforeBooking) > 0 ||
                Number(value.travelTimeBeforeBookin) > 0 ||
                Number(value.additionalCostBeforeBooking) > 0,
              travelDistanceBeforeBooking: Number(value.travelDistanceBeforeBooking),
              travelTimeBeforeBooking: Number(value.travelTimeBeforeBooking),
              additionalCostBeforeBooking: Number(value.additionalCostBeforeBooking),
            };
            await doUpdateBookingTravelClaims(payload);
          }
          this.setState({ step: 3, title: 'Team member travel updated' });
        } catch (e) {
          console.log(e);
          this.setState({ step: 4 });
        }
      }
    });
  };

  render() {
    const { isOpen, form, selectedBookingItem } = this.props;
    const { getFieldDecorator } = form;
    const { step } = this.state;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="large"
        verticalAlignment="highest"
      >
        {step === 1 && (
          <>
            {this.props.travelClaimType === 'BEFORE_BOOKING' && (
              <div>
                <SubTitle>Claim for travel to and from the booking</SubTitle>
                <Row className={'mt-small'}>
                  <Col span={8}>
                    <Text color={'secondary'} size={'small'}>
                      DISTANCE TRAVELLED
                    </Text>
                  </Col>
                  <Col span={8}>
                    <Text color={'secondary'} size={'small'}>
                      TRAVEL TIME
                    </Text>
                  </Col>
                  <Col span={8}>
                    <Text color={'secondary'} size={'small'}>
                      ADDITIONAL EXPENSES
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Text color={'secondary'}>
                      <Form.Item className="width-full">
                        {getFieldDecorator('travelDistanceBeforeBooking', {
                          initialValue: selectedBookingItem.travelDistanceBeforeBooking,
                          // rules: [{ required: true, message: 'Please enter a distance.' }]
                        })(
                          <NumberInput
                            size={'large'}
                            step={1}
                            min={0}
                            max={10000}
                            className={'mr-small'}
                            addonAfter={'km'}
                            style={{ width: '150px' }}
                          />,
                        )}
                      </Form.Item>
                    </Text>
                  </Col>
                  <Col span={8}>
                    <Text color={'secondary'}>
                      <Form.Item className="width-full">
                        {getFieldDecorator('travelTimeBeforeBooking', {
                          initialValue: selectedBookingItem.travelTimeBeforeBooking,
                          // rules: [{ required: true, message: 'Please enter a travel time.' }]
                        })(
                          <NumberInput
                            size={'large'}
                            step={1}
                            min={0}
                            max={10000}
                            precision={0}
                            className={'mr-small'}
                            addonAfter={'minutes'}
                            style={{ width: '150px' }}
                          />,
                        )}{' '}
                      </Form.Item>
                    </Text>
                  </Col>
                  <Col span={8}>
                    <Text color={'secondary'}>
                      <Form.Item className="width-full">
                        {getFieldDecorator('additionalCostBeforeBooking', {
                          initialValue: selectedBookingItem.additionalCostBeforeBooking,
                        })(
                          <NumberInput
                            size={'large'}
                            step={1}
                            min={0}
                            max={10000}
                            className={'mr-small'}
                            addonBefore={'$'}
                            style={{ width: '150px' }}
                          />,
                        )}
                      </Form.Item>
                    </Text>
                  </Col>
                </Row>
                <Paragraph>
                  Changing the claims will re-calculate and automatically generate the relevant line items.
                </Paragraph>
                <Paragraph>
                  Any manually added <b>Provider travel - non-labour costs</b> line items will be automatically
                  replaced.
                </Paragraph>
              </div>
            )}
            {this.props.travelClaimType === 'DURING_BOOKING' && (
              <div>
                <SubTitle>Claim for travels during booking</SubTitle>
                <Row className={'mt-small'}>
                  <Col span={8}>
                    <Text color={'secondary'} size={'small'}>
                      DISTANCE TRAVELLED
                    </Text>
                  </Col>
                  <Col span={8} />
                  <Col span={8}>
                    <Text color={'secondary'} size={'small'}>
                      ADDITIONAL EXPENSES
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Text color={'secondary'}>
                      <Form.Item className="width-full">
                        {getFieldDecorator('travelDistanceDuringBooking', {
                          initialValue: selectedBookingItem.travelDistanceDuringBooking,
                          // rules: [{ required: true, message: 'Please enter a distance.' }]
                        })(
                          <NumberInput
                            size={'large'}
                            step={1}
                            min={0}
                            max={10000}
                            className={'mr-small'}
                            addonAfter={'km'}
                            style={{ width: '150px' }}
                          />,
                        )}
                      </Form.Item>
                    </Text>
                  </Col>
                  <Col span={8} />
                  <Col span={8}>
                    <Text color={'secondary'}>
                      <Form.Item className="width-full">
                        {getFieldDecorator('additionalCostDuringBooking', {
                          initialValue: selectedBookingItem.additionalCostDuringBooking,
                        })(
                          <NumberInput
                            size={'large'}
                            step={1}
                            min={0}
                            max={10000}
                            className={'mr-small'}
                            addonBefore={'$'}
                            style={{ width: '150px' }}
                          />,
                        )}
                      </Form.Item>
                    </Text>
                  </Col>
                </Row>
                <Paragraph>
                  Changing the claims will re-calculate and automatically generate the relevant line items.
                </Paragraph>
                <Paragraph>
                  Any manually added <b>Activity Based Transport</b> line items will be automatically replaced.
                </Paragraph>
              </div>
            )}
            <ActionModalFooter>
              <GhostButton size="large" onClick={this._onCloseModal} className="mr-medium">
                Cancel
              </GhostButton>
              <PrimaryButton size="large" onClick={this._onSubmitChanges}>
                Save
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 2 && <SpinningLoader size={100} message={'Saving changes...'} />}
        {step === 3 && (
          <>
            <div className="text-align-left mb-medium">
              <Paragraph>You have successfully updated the worker travel amounts.</Paragraph>
              <Paragraph>We have recalculated the line items accordingly</Paragraph>
            </div>
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 4 && (
          <div>
            <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
              <div className="pv-medium flex-column justify-center">
                <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
              </div>

              <div className="mb-medium flex-column justify-center ">
                <Paragraph>Oops something has gone wrong, please try again</Paragraph>
              </div>
            </div>
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Back to booking
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateBookingTravelClaims: dispatch.bookingsStore.doUpdateBookingTravelClaims,
});

export default connect(
  null,
  mapDispatch,
)(Form.create<IBillingModifyTravelClaimModalProps>()(BillingModifyTravelClaimModal));
