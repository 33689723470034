import React from 'react';
import { Text } from 'common-components/typography';
import { Col, Row } from 'antd';
import { PrimaryButton } from 'common-components/buttons';

export const AccountExportContentBox = ({
  title,
  content,
  onClick,
}: {
  title: string;
  content: string;
  onClick: () => void;
}) => {
  return (
    <div className="mv-x-large">
      <div className="bordered p-medium rounded">
        <Row type="flex" className="align-center">
          <Col span={20}>
            <div>
              <div className="mb-small">
                <Text weight="bold">{title}</Text>
              </div>
              <Text>{content}</Text>
            </div>
          </Col>
          <Col span={4}>
            <PrimaryButton onClick={onClick}>Export data</PrimaryButton>
          </Col>
        </Row>
      </div>
    </div>
  );
};
