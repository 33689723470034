import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const DistributeHorizontally = forwardRef(function DistributeHorizontally(
  props: IconProps,
  ref: Ref<SVGSVGElement>,
) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.25a.75.75 0 0 1 .75.75v18a.75.75 0 0 1-1.5 0V3A.75.75 0 0 1 3 2.25Zm8.948 2h.104c.899 0 1.648 0 2.243.08.627.084 1.194.27 1.65.725.455.456.64 1.023.725 1.65.08.595.08 1.345.08 2.243V15.052c0 .899 0 1.648-.08 2.242-.084.628-.27 1.195-.725 1.65-.456.456-1.023.642-1.65.726-.595.08-1.344.08-2.243.08h-.104c-.898 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.726-.456-.455-.642-1.022-.726-1.65-.08-.594-.08-1.344-.08-2.242V8.948c0-.898 0-1.648.08-2.242.084-.628.27-1.195.726-1.65.455-.456 1.022-.642 1.65-.726.594-.08 1.344-.08 2.242-.08ZM9.906 5.817c-.462.062-.66.169-.79.3-.13.13-.237.327-.3.788C8.753 7.388 8.75 8.036 8.75 9v6c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.789.3.483.064 1.13.066 2.094.066s1.612-.002 2.095-.067c.461-.062.659-.169.789-.3.13-.13.237-.327.3-.788.065-.483.066-1.131.066-2.095V9c0-.964-.002-1.612-.066-2.095-.063-.461-.17-.659-.3-.789-.13-.13-.328-.237-.79-.3-.482-.064-1.13-.066-2.094-.066s-1.611.002-2.094.067ZM21.75 3a.75.75 0 0 0-1.5 0v18a.75.75 0 0 0 1.5 0V3Z"
        fill="currentColor"
      />
    </Icon>
  );
});
