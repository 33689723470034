import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Megaphone = forwardRef(function Megaphone(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.05 6.4c1.417-1.063 2.412-1.806 3.188-2.228.781-.425 1.111-.395 1.321-.29.21.105.432.35.56 1.23.129.875.131 2.117.131 3.888v4c0 1.77-.002 3.013-.13 3.887-.13.88-.351 1.126-.561 1.23-.21.106-.54.136-1.321-.289-.776-.422-1.771-1.166-3.188-2.228l-1.3-.975v-7.25l1.3-.975Zm6.694 8.276c-.011.96-.044 1.772-.14 2.429-.148 1.004-.478 1.907-1.374 2.354-.896.448-1.817.172-2.708-.313-.885-.48-1.968-1.293-3.318-2.306l-.054-.04-1.4-1.05h-2v3.75a2.25 2.25 0 1 1-4.5 0v-3.809A4.751 4.751 0 0 1 6 6.25h5.75l1.4-1.05.053-.04c1.351-1.013 2.434-1.825 3.319-2.306.891-.485 1.812-.762 2.708-.314.896.448 1.226 1.35 1.374 2.355.096.656.129 1.468.14 2.429a3.751 3.751 0 0 1 0 7.352Zm.006-1.554a2.251 2.251 0 0 0 0-4.244v4.189m-9.5 1.183v-6.5H6a3.25 3.25 0 1 0 0 6.5h5.25Zm-4.5 1.5v3.75a.75.75 0 1 0 1.5 0v-3.75h-1.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
