import React, { Component } from 'react';
import { Layout, Row, Col, Typography, Icon } from 'antd';
import styled from 'styled-components';

import { HyperlinkButton, PrimaryButton } from '../common-components/buttons';

const currentYear = new Date().getFullYear();

const { Text } = Typography;
const { Header: AntHeader, Content: AntContent, Footer: AntFooter } = Layout;

const FooterHeaderText = styled(Text)`
  font-weight: 800;
`;

const Header = styled(AntHeader)`
  background-color: white !important;
  color: black;
`;

const Content = styled(AntContent)`
  background-color: white;
  padding: 24px;
`;

const Footer = styled(AntFooter)`
  background-color: white !important;
`;

type NotAuthorizedLayoutProps = {
  children: React.ReactNode;
};

type NotAuthorizedLayoutState = Record<string, never>;

export class NotAuthorizedLayout extends Component<NotAuthorizedLayoutProps, NotAuthorizedLayoutState> {
  goToLink = (link: string) => (window.location.href = link);

  override render() {
    return (
      <div>
        <Layout style={{ backgroundColor: 'white', minHeight: '100vh', width: '99vw' }}>
          {/* Header */}
          <Header>
            <div className='flex flex-row ph-x-large pt-medium justify-between'>
              <div className='flex justify-center' style={{ flex: 1 }}>
                <HyperlinkButton weight='bold' onClick={() => (window.location.href = '/')}>
                  Home
                </HyperlinkButton>
              </div>
              <div className='flex justify-center' style={{ flex: 1 }}>
                {/*<HyperlinkButton weight="bold">Sign In</HyperlinkButton>*/}
              </div>
              <div className='flex justify-center' style={{ flex: 1 }}>
                <PrimaryButton color='black' paddingSize='large'>
                  Get the App
                </PrimaryButton>
              </div>
            </div>
          </Header>

          {/* Content goes here */}
          <Content>{this.props.children}</Content>

          {/* Footer */}
          <Footer>
            <Row gutter={16} justify='center' type='flex' className='ph-x5-large pb-medium'>
              <Col span={8}>
                <div style={{ marginBottom: '8px' }}>
                  <FooterHeaderText>Schedule a conversation</FooterHeaderText>
                </div>
                <div style={{ marginBottom: '8px' }}>
                  <Icon type='mail' />
                  <span style={{ marginLeft: '8px' }}>
                    <HyperlinkButton>helllo@goodhuman.me</HyperlinkButton>
                  </span>
                </div>
              </Col>
              <Col span={8} className='text-align-center'>
                <div style={{ marginBottom: '8px' }}>
                  <FooterHeaderText>Legal</FooterHeaderText>
                </div>
                <div>
                  <HyperlinkButton onClick={() => this.goToLink('https://www.goodhuman.me/privacy.html')}>
                    Privacy Policy
                  </HyperlinkButton>
                </div>
                <div>
                  <HyperlinkButton onClick={() => this.goToLink('https://www.goodhuman.me/term-of-use.html')}>
                    Terms of Use
                  </HyperlinkButton>
                </div>
                <br />
              </Col>
              <Col span={8} className='text-align-right'>
                <FooterHeaderText>
                  Copyright © {currentYear.toString()} GOODHUMAN INC, All rights reserved.
                </FooterHeaderText>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </div>
    );
  }
}
