import React, { useState } from 'react';
import { TextField } from '@good/components';
import { notification, Row } from 'antd';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { Button } from 'design-components';
import { IRootDispatch } from 'stores/rematch/root-store';
import { useDispatch } from 'react-redux';

type IEditComapanyEmailModalProps = {
  isOpen: boolean;
  currentEmail: string;
  onClose: () => void;
};

const validateEditCompanyEmailModal = (currentEmail: string, newEmail: string) => {
  if (
    !newEmail.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
  ) {
    return 'Please enter valid email address';
  } else if (newEmail === currentEmail) {
    return 'New email matched the current email';
  }

  return '';
};

export const EditCompanyEmailModal = ({ onClose, isOpen, currentEmail }: IEditComapanyEmailModalProps) => {
  const { companyStore } = useDispatch<IRootDispatch>();
  const { doEditCompanyEmail } = companyStore;

  const [isLoading, setIsLoading] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onModalClose = () => {
    setNewEmail('');
    setErrorMessage('');
    onClose();
  };

  const onSave = async () => {
    try {
      setIsLoading(true);

      setErrorMessage('');
      const validationErrorMessage = validateEditCompanyEmailModal(currentEmail, newEmail);
      if (validationErrorMessage !== '') {
        setErrorMessage(validationErrorMessage);
        return;
      }

      await doEditCompanyEmail({ email: newEmail });
      notification.success({ message: 'Company email updated' });
      onModalClose();
    } catch (e) {
      notification.error({ message: 'We were unable to update the company email. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  const onNewEmailChange = (value) => {
    setNewEmail(value);
  };

  const onNewEmailBlur = (e) => {
    setErrorMessage(validateEditCompanyEmailModal(currentEmail, e.target.value));
  };

  return (
    <ActionModal
      title="Change company email"
      isOpen={isOpen}
      onClose={onModalClose}
      canCloseOutside={!isLoading}
      width="medium"
    >
      <Paragraph>The company&apos;s email will appear on invoices sent to customers.</Paragraph>
      <div className="mt-large">
        <Row>
          <div className="mb-medium">
            <TextField className="w-full" label="Current Email" isDisabled defaultValue={currentEmail} />
          </div>
          <TextField
            className="w-full"
            label="New Email"
            value={newEmail}
            validationState={errorMessage ? 'invalid' : 'valid'}
            errorMessage={errorMessage}
            onChange={onNewEmailChange}
            onBlur={onNewEmailBlur}
          />
        </Row>
      </div>
      <ActionModalFooter>
        <Button marginRight="14px" emphasis="quiet" color="black" onClick={onModalClose} isLoading={isLoading}>
          Cancel
        </Button>
        <Button
          isDisabled={newEmail === '' || errorMessage !== ''}
          emphasis="filled"
          isLoading={isLoading}
          onClick={onSave}
        >
          Save
        </Button>
      </ActionModalFooter>
    </ActionModal>
  );
};
