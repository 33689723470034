import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Send = forwardRef(function Send(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.53 2.47c.201.2.272.498.182.767l-6 18a.75.75 0 0 1-1.383.098L9.441 13.56 1.665 9.671a.75.75 0 0 1 .098-1.382l18-6a.75.75 0 0 1 .768.18Zm-9.617 10.678 2.948 5.897 4.953-14.86L3.954 9.14l5.898 2.949L12.97 8.97a.75.75 0 1 1 1.06 1.06l-3.117 3.118Z"
        fill="currentColor"
      />
    </Icon>
  );
});
