import { Icon } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { PaymentMethodTag } from 'common-components/tags';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import React, { Component } from 'react';
import CommonUtils from 'utilities/common-utils';
import PayWaivedItemRow from 'views/billings/payments/components/details/PayWaivedItemRow';
import PaymentErrorIndicator from '../PaymentErrorIndicator';

interface IPaymentCustomerRow {
  paymentItem?: any;
  history?: any;
  openActionModal?: any;
}

class PayWaivedCustomerRow extends Component<IPaymentCustomerRow> {
  _checkbox = null;
  state = {
    userId: null,
    showSubLines: false,
    openAction: false,
  };

  _onClickOpen = async () => {
    this.setState({ showSubLines: true });
  };

  _onClickClose = async () => {
    this.setState({ showSubLines: false });
  };

  _onClickCustomer = (userId) => {
    const { history } = this.props;
    history.push(`/customer/details/${userId}`);
  };

  componentDidMount = () => {
    const { paymentItem } = this.props;

    if (!_.isEmpty(paymentItem)) {
      const { userId } = paymentItem;
      this.setState({ userId });
    }
  };

  render() {
    const { paymentItem } = this.props;

    if (_.isEmpty(paymentItem)) {
      return <></>;
    }

    const {
      billingTotal,
      firstName,
      lastName,
      userId,
      numberOfItems,
      numberOfBooking,
      billingItems,
      paymentMethods,
      checkAllIndicator,
      errorTypes,
      warningTypes,
    } = paymentItem;

    const displayBorder = this.state.showSubLines ? 'bordered-none' : '';

    const numberOfErrors = errorTypes.length;
    return (
      <>
        <tr
          onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}
          className={`${!checkAllIndicator && 'hover-bg-tertiary'} anim-fade-in ${
            checkAllIndicator ? 'bg-blue-lightest' : 'bg-white'
          }`}
        >
          <td className={`${displayBorder} fluid-cell check-all `} />
          <td className={`${displayBorder} ellipsis `}>
            <div className="pr-small">
              <Text style={{ whiteSpace: 'nowrap' }} weight="bold" size="regular">
                <HyperlinkButton onClick={() => this._onClickCustomer(userId)} color="black">
                  {firstName} {lastName}
                </HyperlinkButton>
              </Text>
            </div>
          </td>
          <td className={`${displayBorder} ellipsis `}>
            {numberOfItems} Item{numberOfItems > 1 && 's'} ({numberOfBooking} Booking{numberOfBooking > 1 && 's'})
            {_.map(paymentMethods, (paymentMethod) => (
              <PaymentMethodTag paymentMethod={paymentMethod} className="ml-small" rounded={true} size="small" />
            ))}
          </td>
          <td className={`${displayBorder} ellipsis`}>
            <div className="text-align-right">
              <Text style={{ whiteSpace: 'nowrap' }} weight="bold" size="regular">
                {CommonUtils.formatPrice(billingTotal)}
              </Text>
              <PaymentErrorIndicator errorTypes={errorTypes} warningTypes={warningTypes} billingItems={billingItems} />
            </div>
          </td>
          <td
            className={`${displayBorder} fluid-cell `}
            onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}
          >
            <div className="pr-small">
              {this.state.showSubLines ? (
                <Icon className="size-x-large" type="up" />
              ) : (
                <Icon className="size-x-large" type="down" />
              )}
            </div>
          </td>
        </tr>
        <tr>
          {this.state.showSubLines && (
            <>
              <td colSpan={5}>
                <table className="subLines" style={{ width: '100%' }}>
                  {_.map(billingItems, (billingItem) => (
                    <PayWaivedItemRow
                      lineItem={billingItem}
                      history={this.props.history}
                      key={billingItem.bookingBillingLineItemId}
                      openActionModal={this.props.openActionModal}
                      customerId={userId}
                      errorTypes={errorTypes}
                      warningTypes={warningTypes}
                    />
                  ))}
                </table>
              </td>
            </>
          )}
        </tr>
      </>
    );
  }
}

export default PayWaivedCustomerRow;
