import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import { Icon } from 'antd';

export default class OptionsSelectorRow extends Component<{
  title: string;
  description: string;
  isSelected?: boolean;
  onClick: any;
}> {
  state = { isHovered: false };

  private _setHovered = () => this.setState({ isHovered: true });
  private _setNotHovered = () => this.setState({ isHovered: false });

  render() {
    const { title, onClick, description } = this.props;

    return (
      <div
        className={`bordered-top pv-medium ph-large cursor-pointer hover-bg-tertiary flex-row justify-between align-center`}
        onClick={onClick}
        onMouseEnter={this._setHovered}
        onMouseLeave={this._setNotHovered}
      >
        <div>
          <Title level={4} weight="regular">
            {title}
          </Title>
          <Text color="tertiary">{description}</Text>
        </div>
        <div className="ml-small">
          <Icon
            type="arrow-right"
            className={
              this.state.isHovered ? 'text-color-black text-size-x2-large' : 'text-color-white text-size-x2-large'
            }
          />
        </div>
      </div>
    );
  }
}
