import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@good/ui/core';
import { GroupOf3People } from '@good/illustrations';

export const ArchivedEmptyState = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceCustomersTabs' });

  return (
    <div className='flex flex-col align-center height-full'>
      <GroupOf3People />
      <Text> {t('archivedEmptyHeading')}</Text>
      <Text c='dimmed'> {t('archivedEmptyDescription')}</Text>
    </div>
  );
};
