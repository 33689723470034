import { em } from '@good/ui/core';
import { useMediaQuery } from '@good/ui/hooks';

/**
 * Determines whether the current viewport is mobile sized based on a media query.
 * @returns {boolean} A boolean value indicating whether the viewport is mobile sized.
 */
export function useIsMobile(): boolean {
  return useMediaQuery(`(max-width: ${em(768)})`, false) ?? false;
}
