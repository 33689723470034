import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const LetterSpacing2 = forwardRef(function LetterSpacing2(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.25a.75.75 0 0 0 0 1.5h3.25V12a.75.75 0 0 0 1.5 0V4.75H16a.75.75 0 0 0 0-1.5H8Zm13.53 15.28a.75.75 0 0 0 0-1.06l-2.5-2.5a.75.75 0 0 0-1.06 1.06l1.22 1.22H4.81l1.22-1.22a.75.75 0 1 0-1.06-1.06l-2.5 2.5a.75.75 0 0 0 0 1.06l2.5 2.5a.75.75 0 0 0 1.06-1.06l-1.22-1.22h14.38l-1.22 1.22a.75.75 0 1 0 1.06 1.06l2.5-2.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
