import { Customer } from '@good/schemas';
import { useQuery } from '@tanstack/react-query';
import { fetchCustomer } from 'stores/queries/customer/customer-queries';

function useCustomer({ customerUserId }: { customerUserId: string }) {
  const { data, remove, refetch, isFetching, isLoading, status, error } = useQuery<Customer, Error>({
    queryFn: async () => (await fetchCustomer(customerUserId)).data,
    queryKey: ['customer', { customerUserId }],
    onError: (err) => {
      global.newrelic.noticeError(err, { location: 'useCustomer' });
    },
  });

  return { data, remove, refetch, isFetching, isLoading, status, error };
}

export { useCustomer };
