import { Paragraph, Title } from 'common-components/typography';
import React, { PureComponent } from 'react';
import { IAddWorkflowTemplateWizard } from '../../../../../interfaces/workflow-interfaces';

interface Props {
  addWorkflowTemplateWizard: IAddWorkflowTemplateWizard;
  step: number;
  workflowName?: string;
}

const title = [
  "We'll guide you through creating a workflow",
  'Select type of workflow',
  'Configure the steps in the workflow',
  'Configure your workflow',
  'Configure the steps in the workflow',
  'Configure the steps in the workflow',
];

class CreateWorkflowTemplateGuideBox extends PureComponent<Props> {
  render() {
    const { step, addWorkflowTemplateWizard } = this.props;

    return (
      <>
        <Title level={3} lineHeight={150} weight="regular">
          {title[step - 1]}
        </Title>

        {step === 1 && (
          <>
            <Paragraph size="regular" lineHeight={150}>
              The next few screens will cover everything that&apos;s needed to create a workflow.
            </Paragraph>
            <Paragraph size="regular" lineHeight={150}>
              First give your workflow a <b>name</b> and <b>description</b>.
            </Paragraph>
          </>
        )}

        {(step === 2 || step === 3) && (
          <Paragraph size="large" lineHeight={150}>
            <Paragraph size="x-small" color="tertiary" weight="regular" className="mb-none">
              Workflow name
            </Paragraph>
            {addWorkflowTemplateWizard ? addWorkflowTemplateWizard.name : 'Workflow Name'}
          </Paragraph>
        )}

        {step === 2 && (
          <>
            <Paragraph size="regular" lineHeight={150}>
              Select which in platform action will trigger this workflow.
            </Paragraph>
            <Paragraph size="regular" lineHeight={150}>
              <b>&apos;In platform action&apos;</b> refers to actions such as an incident being reported or a booking
              being created which will trigger the workflow.
            </Paragraph>
            <Paragraph size="regular" lineHeight={150}>
              If you don&apos;t want any actions to trigger a workflow and would instead like to manually trigger
              worklfows please select the <b>&apos;Manually trigger&apos;</b> option.
            </Paragraph>
          </>
        )}

        {step === 3 && (
          <>
            <Paragraph size="regular" lineHeight={150}>
              Add the steps to the workflow.
            </Paragraph>
            <Paragraph size="regular" lineHeight={150}>
              Each step requires you to assign <b>approvers</b>, and the option to attach a form you have created using
              the form-builder.
            </Paragraph>
            <Paragraph size="regular" lineHeight={150}>
              If you have selected an &apos;In platform trigger&apos; you will need to configure a <b>trigger step</b>.
            </Paragraph>
            <Paragraph size="regular" lineHeight={150}>
              The <b>trigger step</b> for &apos;Incident reported&apos; will allow you to attach a form that will appear
              when reporting an incident on both the business app and the workspace.
            </Paragraph>
            <Paragraph size="regular" lineHeight={150}>
              Trigger steps do not require approvers and cannot be moved or deleted.
            </Paragraph>
          </>
        )}

        {step === 4 && (
          <>
            <Paragraph size="regular" lineHeight={150}>
              Configure the settings of this workflow.
            </Paragraph>
          </>
        )}

        {(step === 5 || step === 6) && (
          <>
            <Paragraph size="regular" lineHeight={150}>
              Add the steps to the workflow.
            </Paragraph>
            <Paragraph size="regular" lineHeight={150}>
              Each step requires you to assign <b>approvers</b>, and the option to attach a form you have created using
              the form-builder.
            </Paragraph>
            <Paragraph size="regular" lineHeight={150}>
              If you have selected an &apos;In platform trigger&apos; you will need to configure a&nbsp;
              <b>trigger step</b>.
            </Paragraph>
          </>
        )}
      </>
    );
  }
}

export default CreateWorkflowTemplateGuideBox;
