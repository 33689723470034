/* eslint-disable */
import React, { PureComponent } from 'react';
import CommonUtils from '../../utilities/common-utils';
import TextTag from 'common-components/tags/TextTag';

interface StatusTagProps {
  status:
    | 'PENDING'
    | 'ACCEPTED'
    | 'CANCELLED'
    | 'CONFIRMED'
    | 'IN_PROGRESS'
    | 'COMPLETED'
    | 'APPROVED'
    | 'READY_FOR_BILLING'
    | 'REQUIRES_APPROVAL'
    | 'BILLED'
    | 'BILLING_FAILED'
    | 'REJECTED'
    | 'ARCHIVED'
    | 'SEND_TO_FINANCE'
    | string;
  size?: 'small' | 'default' | 'large';
  textSize?: string;
  className?: any;
  textClassName?: any;
  rounded?: boolean;
  theme?: 'dark' | 'light';
  isFixedWidth?: boolean;
  width?: number;
}

export default class StatusTag extends PureComponent<StatusTagProps, any> {
  StatusColors = {
    PENDING: { color: 'secondary', theme: 'dark' },
    ACCEPTED: { color: 'green', theme: 'dark' },
    CANCELLED: { color: 'red', theme: 'light' },
    CONFIRMED: { color: 'blue', theme: 'dark' },
    INPROGRESS: { color: 'gold', theme: 'dark' },
    COMPLETED: { color: 'indigo', theme: 'light' },
    APPROVED: { color: 'orange', theme: 'dark' },
    READY_FOR_BILLING: { color: 'cobalt', theme: 'dark' },
    BILLED: { color: 'lime', theme: 'dark' },
    BILLING_FAILED: { color: 'red', theme: 'dark' },
    REJECTED: { color: 'rose', theme: 'dark' },
    CUSTOMER_CANCELLED: { color: 'rose', theme: 'light' },
    CUSTOMER_CANCELLED_WITH_FEE: { color: 'rose', theme: 'light' },
    CUSTOMER_CANCELLED_WITHOUT_FEE: { color: 'rose', theme: 'light' },
    BUSINESS_CANCELLED: { color: 'rose', theme: 'light' },
    REQUIRES_APPROVAL: { color: 'turquoise', theme: 'dark' },
    ARCHIVED: { color: 'red', theme: 'light' },
    SCHEDULED: { color: 'forest', theme: 'dark' }, // TODO: change the color
    SEND_TO_FINANCE: { color: 'blue', theme: 'light' },
  };

  render() {
    const {
      status,
      className,
      textSize = 'regular',
      size = 'default',
      rounded = true,
      isFixedWidth = false,
      width = 120,
    } = this.props;

    const color = this.StatusColors[status] ? this.StatusColors[status].color : 'blue';
    const theme = this.StatusColors[status] ? this.StatusColors[status].theme : 'dark';

    return (
      <TextTag
        theme={theme}
        color={color}
        textSize={textSize}
        size={size}
        className={className}
        content={CommonUtils.formatStatusString(status)}
        rounded={rounded}
        isFixedWidth={isFixedWidth}
        width={width}
      />
    );
  }
}
