import { PageHeader } from '@good/ui/templates';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SilRosterData } from 'utilities/react-trpc';
import { SilTable } from 'views/sil-service/components/table';
import { Collaboration8 } from '@good/illustrations';
import { EmptyTablePlaceholder } from 'views/sil-service/components/empty-table-placeholder';
import { Space } from '@good/ui/core';
import { memberColumns } from './members-helper';

type IMembersTabProps = {
  members: SilRosterData;
  membersCount: number;
};

export const MembersTab = ({ members, membersCount }: IMembersTabProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceTeamTabs' });

  return (
    <div data-testid='members-tab' className='w-full'>
      <PageHeader title={t('MembersHeading', { count: membersCount })} subtitle={t('MembersDescription')} size='h2' />
      {membersCount === 0 ? (
        <>
          <SilTable data={members} columns={memberColumns} />
          <Space h='md' />
          <EmptyTablePlaceholder
            image={<Collaboration8 />}
            heading={t('empty.memberHeading')}
            description={t('empty.memberDescription')}
          />
        </>
      ) : (
        <SilTable data={members} columns={memberColumns} />
      )}
    </div>
  );
};
