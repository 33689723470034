import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const DollarCircle = forwardRef(function DollarCircle(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm6-2.256a2.493 2.493 0 0 1 2.493-2.494h.507V6.5a.75.75 0 1 1 1.5 0v.75H13A2.75 2.75 0 0 1 15.75 10a.75.75 0 0 1-1.5 0c0-.69-.56-1.25-1.25-1.25h-.25v2.71l1.295.431a2.494 2.494 0 0 1-.788 4.86h-.507v.75a.75.75 0 0 1-1.5 0v-.75H11A2.75 2.75 0 0 1 8.25 14a.75.75 0 0 1 1.5 0c0 .69.56 1.25 1.25 1.25h.25v-2.71l-1.295-.432A2.493 2.493 0 0 1 8.25 9.744Zm3 1.216V8.75h-.507a.993.993 0 0 0-.314 1.936l.821.274Zm1.5 2.08v2.21h.507a.993.993 0 0 0 .314-1.936l-.82-.273Z"
        fill="currentColor"
      />
    </Icon>
  );
});
