import { getLatLng } from 'react-places-autocomplete';

import { InvalidAddressResultError } from './invalid-address-result-error';

export async function getPlaceDataByAddress(
  address: string,
  componentRestrictions: google.maps.GeocoderComponentRestrictions | null | undefined,
) {
  const geocoder = new window.google.maps.Geocoder();
  const {
    results: [firstResult],
  } = await geocoder.geocode({
    address,
    componentRestrictions,
  });

  if (!firstResult) {
    throw new InvalidAddressResultError('NO_RESULT');
  }

  if (firstResult.partial_match) {
    throw new InvalidAddressResultError('INCOMPLETE_RESULT');
  }

  const latLng = await getLatLng(firstResult);

  return {
    geocoderResult: firstResult,
    latLng,
  };
}
