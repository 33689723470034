import React from 'react';
import { Text } from '@good/ui/core';

export type OptionalLabelProps = {
  label: string;
  optional?: boolean;
};

export const OptionalLabel = ({ label, optional = false }: OptionalLabelProps) => {
  return (
    <span className='flex gap-1 justify-center align-center'>
      {label}
      {optional && (
        <Text component='span' size='xs' c='var(--brand-body-dark-1)'>
          (optional)
        </Text>
      )}
    </span>
  );
};
