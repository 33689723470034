import React, { Component } from 'react';
import { Paragraph, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import ActionModal from 'common-components/modal/ActionModal';
import Form, { FormComponentProps } from 'antd/es/form';
import { Checkbox, Col, InputNumber, notification, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

interface IEditSessionCapacityModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: (resetList?: boolean) => void;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  updateSessionCapacity: typeof dispatch.groupServiceStore.updateSessionCapacity;
}

interface IEditSessionCapacityModalState {
  step: number;
  isSaving: boolean;
  isFixedCapacity: boolean;
  isLessThanBookedCapacityError: boolean;
  savedNewCapacity: number;
}

class EditSessionCapacityModal extends Component<IEditSessionCapacityModalProps, IEditSessionCapacityModalState> {
  state = {
    step: 1,
    isSaving: false,
    isFixedCapacity:
      this.props.selectedSession && this.props.selectedSession.capacity && this.props.selectedSession.capacity > 0,
    isLessThanBookedCapacityError: !!(
      this.props.selectedSession &&
      this.props.selectedSession.capacity &&
      this.props.selectedSession.bookedCapacity &&
      this.props.selectedSession.capacity < this.props.selectedSession.bookedCapacity
    ),
    savedNewCapacity: null,
  };

  private _changeIsFixedCapacity = () => {
    this.setState({
      isFixedCapacity: !this.state.isFixedCapacity,
      isLessThanBookedCapacityError: this.state.isFixedCapacity ? this.state.isLessThanBookedCapacityError : false,
    });
  };

  private _changeCapacity = () => {
    this.setState({ isLessThanBookedCapacityError: false });
  };

  private _onCloseModal = () => {
    this.props.onClose();
    this.setState({ step: 1 });
  };

  private _saveCapacity = async () => {
    const { selectedSession, form } = this.props;
    const { isFixedCapacity } = this.state;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    const capacity = isFixedCapacity ? form.getFieldValue('capacity') : null;
    if (isFixedCapacity && capacity < selectedSession.bookedCapacity) {
      isFormValid = false;
      this.setState({ isLessThanBookedCapacityError: true });
    }
    if (isFormValid) {
      try {
        this.setState({ isSaving: true });
        await this.props.updateSessionCapacity({
          serviceId: selectedSession.serviceId,
          serviceDateTimeId: selectedSession.serviceDateTimeId,
          capacity: isFixedCapacity ? capacity : null,
        });
        this.setState({ step: 2, savedNewCapacity: capacity });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
      this.setState({ isSaving: false });
    }
  };

  componentDidUpdate(
    prevProps: Readonly<IEditSessionCapacityModalProps>,
    prevState: Readonly<IEditSessionCapacityModalState>,
    snapshot?: any,
  ) {
    if (prevProps.selectedSession !== this.props.selectedSession) {
      this.setState({
        isFixedCapacity: this.props.selectedSession.capacity && this.props.selectedSession.capacity > 0,
        isLessThanBookedCapacityError: !!(
          this.props.selectedSession.capacity &&
          this.props.selectedSession.bookedCapacity &&
          this.props.selectedSession.capacity < this.props.selectedSession.bookedCapacity
        ),
      });
    }
  }

  render() {
    const { form, selectedSession } = this.props;
    const { isSaving, isFixedCapacity, isLessThanBookedCapacityError, savedNewCapacity, step } = this.state;
    const { getFieldDecorator } = form;

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        onClose={this._onCloseModal}
        title={step === 1 ? 'Edit session capacity' : 'Session capacity successfully updated'}
        showCloseButton={true}
        width={'large'}
      >
        {step === 1 && (
          <>
            {selectedSession && (
              <>
                <Checkbox checked={isFixedCapacity} onChange={this._changeIsFixedCapacity}>
                  This time slot has a fixed capacity
                </Checkbox>
                {isFixedCapacity && (
                  <div className="bg-quaternary pv-medium pl-medium rounded-big" style={{ width: '432px' }}>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('capacity', {
                        initialValue: selectedSession.capacity ? selectedSession.capacity : undefined,
                        rules: [
                          {
                            required: true,
                            type: 'number',
                            min: 1,
                            max: 500,
                            message: 'Please enter a valid capacity between 1 and 500.',
                          },
                        ],
                      })(
                        <InputNumber
                          size="default"
                          style={{ width: '100px', marginTop: '8px' }}
                          className="mr-small text-align-center"
                          precision={0}
                          step={1}
                          onChange={this._changeCapacity}
                        />,
                      )}{' '}
                      <Text>maximum capacity</Text>
                    </Form.Item>
                  </div>
                )}
                {isFixedCapacity && isLessThanBookedCapacityError && (
                  <Text color={'red-dark'}>
                    There are currently <b>{selectedSession.bookedCapacity} active customers</b> in this session. You
                    cannot make the capacity of this session less than the number of active customer inside it.
                  </Text>
                )}
                <div className={'mt-x-large mb-small'}>
                  <Row type={'flex'} justify={'end'}>
                    <Col className={'mr-large'}>
                      <SecondaryButton onClick={this._onCloseModal} size={'large'}>
                        Cancel
                      </SecondaryButton>
                    </Col>
                    <Col>
                      <PrimaryButton onClick={this._saveCapacity} size={'large'} loading={isSaving}>
                        Save
                      </PrimaryButton>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </>
        )}
        {step === 2 && (
          <>
            <Paragraph>
              {savedNewCapacity
                ? `You have updated the maximum capacity of this session to ${savedNewCapacity}`
                : 'You have removed the max capacity.'}
            </Paragraph>
            <div className={'mt-x-large mb-small'}>
              <Row type={'flex'} justify={'end'}>
                <Col>
                  <PrimaryButton onClick={this._onCloseModal} size={'large'}>
                    Close
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  updateSessionCapacity: dispatch.groupServiceStore.updateSessionCapacity,
});

export default connect(mapState, mapDispatch)(Form.create<IEditSessionCapacityModalProps>()(EditSessionCapacityModal));
