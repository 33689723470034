import { tv } from 'tailwind-variants';

export const illustratedMessage = tv(
  {
    slots: {
      container: ['flex', 'flex-col', 'items-center', 'gap-4', 'max-w-[374px]'],
      content: 'flex flex-col items-center gap-2 text-center text-weak',
    },
  },
  { twMerge: false },
);
