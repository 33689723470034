import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const PlaylistPlay = forwardRef(function PlaylistPlay(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5A.75.75 0 0 1 3 4.25h16a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 5Zm0 4A.75.75 0 0 1 3 8.25h12a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 9ZM3 12.25a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5H3ZM2.25 17a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75Zm17.276-3.551-.05-.033c-.642-.428-1.194-.796-1.652-1.013-.471-.222-1.063-.392-1.65-.077-.587.314-.774.9-.85 1.415-.074.502-.074 1.165-.074 1.937v.645c0 .771 0 1.434.074 1.936.076.515.263 1.101.85 1.415.587.314 1.179.145 1.65-.078.458-.216 1.01-.584 1.652-1.012l.05-.033.394-.263.046-.03c.525-.35.987-.659 1.31-.951.345-.311.671-.726.671-1.307s-.326-.996-.67-1.307c-.324-.293-.786-.6-1.31-.95l-.047-.031-.395-.263Zm-2.67.207s.003-.002.013-.002l-.013.002Zm.327.104a1.05 1.05 0 0 0-.297-.105c-.02.037-.051.124-.078.306-.056.378-.058.928-.058 1.776v.526c0 .848.002 1.398.058 1.776.027.182.059.269.078.306.041-.005.131-.026.297-.105.346-.163.805-.467 1.51-.937l.395-.263c.585-.39.952-.637 1.183-.846a.88.88 0 0 0 .17-.189l.004-.005-.003-.005a.88.88 0 0 0-.17-.19c-.232-.208-.6-.455-1.184-.845l-.395-.263c-.705-.47-1.164-.774-1.51-.937Zm-.327 4.584.013.002c-.01 0-.014-.001-.013-.002Zm.038.016c.007.005.01.009.01.01-.001 0-.004-.002-.01-.01Zm0-4.72c.006-.008.009-.01.01-.01 0 .001-.003.005-.01.01Z"
        fill="currentColor"
      />
    </Icon>
  );
});
