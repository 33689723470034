import { tv } from 'tailwind-variants';

export const item = tv(
  {
    slots: {
      container: [],
      iconContainer: ['flex', 'text-[12.9px]'],
      icon: ['text-accent', 'b-md'],
    },
  },
  { twMerge: false },
);
