import { notification, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { IGroupServiceActivityGroup } from 'interfaces/service-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { ActivityGroupModalType } from 'utilities/enum-utils';

interface IDuplicateModalContentProps {
  activityGroup: IGroupServiceActivityGroup;
  handleAction: (payload, actionType: ActivityGroupModalType) => Promise<boolean | void>;
  onCloseViewModal: () => void;
}

interface IDuplicateModalContentState {
  isLoading: boolean;
}

const INITIAL_STATE: IDuplicateModalContentState = {
  isLoading: false,
};

class DuplicateModalContent extends Component<IDuplicateModalContentProps, IDuplicateModalContentState> {
  state = _.cloneDeep(INITIAL_STATE);

  private _onSubmitForm = async () => {
    const { activityGroup, handleAction, onCloseViewModal } = this.props;
    this.setState({ isLoading: true });

    try {
      const payload = { ...activityGroup };
      await handleAction(payload, ActivityGroupModalType.DUPLICATE);
      notification.success({
        message: 'Activity group duplicated',
        description: `You have successfully duplicated the "${activityGroup.name}".`,
      });
      onCloseViewModal();
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong! Please try again.',
      });
    }

    this.setState({ isLoading: false });
  };

  render() {
    const { activityGroup, onCloseViewModal } = this.props;
    const { isLoading } = this.state;
    return (
      <>
        <Row>
          <Paragraph>
            You’re about to duplicate <b>{activityGroup.name}</b>.
          </Paragraph>
          <Paragraph>This will copy all customers and team members across to the duplicated group.</Paragraph>
        </Row>
        <ActionModalFooter className="flex-row">
          <div className="flex flex-grow"></div>
          <SecondaryButton color="secondary" size="large" onClick={onCloseViewModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton className="ml-medium" size="large" loading={isLoading} onClick={this._onSubmitForm}>
            Duplicate activity group
          </PrimaryButton>
        </ActionModalFooter>
      </>
    );
  }
}

export default DuplicateModalContent;
