import { DatePicker, Icon, Radio } from 'antd';
import moment from 'moment';
import React from 'react';
import { HyperlinkButton } from 'common-components/buttons';
import { ISleepoverTimeSlot } from 'interfaces/booking-interfaces';
import { SleepoverTimeSlotErrorType, SleepoverType } from 'utilities/enum-utils';
import TimeInput from 'common-components/time-input/TimeInput';
import { Text } from 'common-components/typography';

interface IProps {
  bookStartDate: Date;
  bookEndDate: Date;
  bookingTimezone: string;
  sleepoverType: SleepoverType;
  timeSlots: ISleepoverTimeSlot[];
  onChangeDateTime: (index: number, key: string) => (date: moment.Moment | null, dateString: string) => void;
  onDeleteTimeSlot: (index: number) => () => void;
  onChangeSleepoverType: (e) => void;
  onAddTimeSlot: () => void;
}

const SleepoverTypeStep = ({
  sleepoverType,
  onChangeSleepoverType,
  timeSlots,
  bookingTimezone,
  onChangeDateTime,
  onDeleteTimeSlot,
  bookStartDate,
  bookEndDate,
  onAddTimeSlot,
}: IProps) => {
  const _renderErrorMessage = (errorType) => {
    if (
      errorType === SleepoverTimeSlotErrorType.OUT_OF_BOOKING_TIME ||
      errorType === SleepoverTimeSlotErrorType.OUT_OF_CHECKIN_CHECKOUT_TIME
    ) {
      return (
        <>
          <Text color="red">
            {errorType === SleepoverTimeSlotErrorType.OUT_OF_BOOKING_TIME
              ? 'Sleepover must occur within the booking time'
              : errorType === SleepoverTimeSlotErrorType.OUT_OF_CHECKIN_CHECKOUT_TIME
              ? 'Sleepover must occur within team member start/finish'
              : ''}
            {' ('}
          </Text>
          <Text weight="bold" color="red">
            {moment.tz(bookStartDate, bookingTimezone).format('h:mm a')},{' '}
          </Text>
          <Text color="red">{moment.tz(bookStartDate, bookingTimezone).format('D MMMM YYYY')} - </Text>
          <Text weight="bold" color="red">
            {moment.tz(bookEndDate, bookingTimezone).format('h:mm a')},{' '}
          </Text>
          <Text color="red">
            {moment.tz(bookEndDate, bookingTimezone).format('D MMMM YYYY')}
            {')'}
          </Text>
        </>
      );
    }

    return 'This time frame overlaps with a previous entry';
  };
  return (
    <>
      <div className="mt-small">Select the option that best describes this booking.</div>
      <Radio.Group
        value={sleepoverType}
        onChange={(e) => onChangeSleepoverType(e)}
        className="flex flex-column mt-small align-start"
      >
        <Radio value={SleepoverType.NONE} className="mt-small">
          Booking <b>doesn&apos;t</b> involve a sleepover
        </Radio>
        <Radio value={SleepoverType.ENTIRE} className="mt-small">
          Entire booking is a sleepover
        </Radio>
        <Radio value={SleepoverType.PARTIAL} className="mt-small">
          Part of this booking is a sleepover
        </Radio>
      </Radio.Group>
      {sleepoverType === SleepoverType.PARTIAL && (
        <>
          {timeSlots.map((timeSlot, index) => (
            <div key={index} className="mt-small">
              <div className="flex align-center">
                <DatePicker
                  allowClear={false}
                  className={'mr-small' + (timeSlot?.errorType ? ' error-date-picker' : '')}
                  format="DD/MM/YYYY"
                  value={moment.tz(timeSlot.startDateTime, bookingTimezone)}
                  onChange={onChangeDateTime(index, 'startDateTime')}
                />
                <TimeInput
                  timezone={bookingTimezone}
                  value={moment.tz(timeSlot.startDateTime, bookingTimezone)}
                  onChange={onChangeDateTime(index, 'startDateTime')}
                  className={!!timeSlot.errorType && 'error-date-picker'}
                />
                <span className="mh-medium">to</span>
                <DatePicker
                  allowClear={false}
                  className={'mr-small' + (timeSlot?.errorType ? ' error-date-picker' : '')}
                  format="DD/M/YYYY"
                  value={moment.tz(timeSlot.endDateTime, bookingTimezone)}
                  onChange={onChangeDateTime(index, 'endDateTime')}
                />
                <TimeInput
                  className={!!timeSlot.errorType && 'error-date-picker'}
                  value={moment.tz(timeSlot.endDateTime, bookingTimezone)}
                  onChange={onChangeDateTime(index, 'endDateTime')}
                  timezone={bookingTimezone}
                />
                {timeSlots.length > 1 && (
                  <Icon
                    type="minus-circle"
                    className="text-color-red cursor-pointer ml-12"
                    onClick={onDeleteTimeSlot(index)}
                  />
                )}
              </div>
              {!!timeSlot.errorType && (
                <div className="text-color-red mt-x-small mb-12">{_renderErrorMessage(timeSlot.errorType)}</div>
              )}
            </div>
          ))}
          <HyperlinkButton className="width-fit-content block mt-small" onClick={() => onAddTimeSlot()}>
            + Add another sleepover timeslot
          </HyperlinkButton>
        </>
      )}
    </>
  );
};

export default SleepoverTypeStep;
