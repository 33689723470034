import React, { useEffect, useState } from 'react';

import { Text } from 'common-components/typography';
import { Icon } from 'antd';
import { GhostButton } from 'common-components/buttons';

import moment from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import { FundingPackageStatus } from 'utilities/enum-utils';

interface IFundingPackageInfoProps {
  NDISFundingPackages: any[];
  hasEditPermission: boolean;
  addFundingPackageAction: () => void;
}

const _calculateFormattedDuration = (startDateTime: moment.Moment, endDateTime: moment.Moment) => {
  const duration = CommonUtils.formatDuration(startDateTime, endDateTime);
  const formattedDuration = [];
  if (duration) {
    if (duration.years) {
      formattedDuration.push(duration.years);
    }
    if (duration.months) {
      formattedDuration.push(duration.months);
    }
    if (duration.days && !duration.years) {
      formattedDuration.push(duration.days);
    }
  }
  return formattedDuration.map((duration) => `${duration.value} ${duration.label}`).join(', ');
};

const _determineFundingPackageToDisplay = (NDISFundingPackages: any[]) => {
  let fundingPackage;

  if (NDISFundingPackages && NDISFundingPackages.length > 0) {
    const sortedFundingPackages = NDISFundingPackages.sort((a, b) =>
      a.expiredDate?.split('-').join().localeCompare(b.expiredDate?.split('-').join()),
    );
    if (sortedFundingPackages.length > 0) {
      const cur = moment().startOf('day');
      // find an active package if it exists
      const activeFundingPackage = sortedFundingPackages.find((packageItem) => {
        return (
          cur.isBefore(packageItem.expiredDate) &&
          cur.isSameOrAfter(packageItem.startDate) &&
          packageItem.status === FundingPackageStatus.ACTIVE
        );
      });
      // return active funding package if exists, else return expired funding package if exists, else return undefined
      return activeFundingPackage
        ? activeFundingPackage
        : sortedFundingPackages.find((exppackage) => cur.isBefore(exppackage.startDate));
    }
  }
  return fundingPackage;
};

interface IFundingPackageDurationInfoProps {
  fundingPackage: {
    agency;
    expiredDate;
    fundingNDISSupportCategories: any[];
    startDate;
    status: FundingPackageStatus;
  };
}

const FundingPackageDurationInfo = ({ fundingPackage }: IFundingPackageDurationInfoProps) => {
  const isActive = fundingPackage.status === FundingPackageStatus.ACTIVE;
  const totalBudget = CommonUtils.formatPrice(
    fundingPackage.fundingNDISSupportCategories
      .reduce((acc, category) => {
        acc = acc + Number(category.funding);
        return acc;
      }, 0)
      .toFixed(2),
  );
  const formattedDuration = _calculateFormattedDuration(
    moment().startOf('day'),
    moment(isActive ? fundingPackage.expiredDate : fundingPackage.startDate),
  );

  return (
    <Text>
      <b>{fundingPackage.agency}</b> plan of <b>{totalBudget}</b>
      {isActive ? ' expiring in ' : ' starting in '}
      <b>{formattedDuration}</b>
    </Text>
  );
};

function FundingPackageInfo({
  NDISFundingPackages,
  hasEditPermission,
  addFundingPackageAction,
}: IFundingPackageInfoProps) {
  const [fundingPackage, setFundingPackage] = useState();

  useEffect(() => {
    setFundingPackage(_determineFundingPackageToDisplay(NDISFundingPackages));
  }, [NDISFundingPackages]);

  return fundingPackage ? (
    <FundingPackageDurationInfo fundingPackage={fundingPackage} />
  ) : (
    <Text>
      <Icon type="close" className="mr-x-small" style={{ color: '#cc5657' }} />
      <b>No NDIS funding set</b>{' '}
      {hasEditPermission && <GhostButton onClick={addFundingPackageAction}>+ Add</GhostButton>}
    </Text>
  );
}

export default FundingPackageInfo;
