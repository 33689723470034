import { Avatar, Col, Icon, Row } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { IUserInvolved, IWorkflow } from 'interfaces/workflow-interfaces';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import CustomerAndTeamMemberList from './CustomerAndTeamMemberList';
import { Link } from 'react-router-dom';

interface IWorkflowDetailsRightSideBarProps {
  selectedWorkflow: IWorkflow;
  customerInvolved: IUserInvolved[];
  teamMemberInvolved: IUserInvolved[];
  timezone: string;
  editableUsers: boolean;
  onOpenUsersListModal(modalType: string): void;
  isDisableAction: boolean;
}

class WorkflowDetailsRightSideBar extends PureComponent<IWorkflowDetailsRightSideBarProps> {
  render() {
    const {
      selectedWorkflow,
      customerInvolved,
      teamMemberInvolved,
      timezone,
      editableUsers,
      onOpenUsersListModal,
      isDisableAction,
    } = this.props;

    const attendanceId = selectedWorkflow?.bookingDetail?.attendanceId;
    const serviceDateTimeId = selectedWorkflow?.bookingDetail?.serviceDateTimeId;
    const serviceId = selectedWorkflow?.bookingDetail?.serviceId;

    return (
      <div className="bg-tertiary p-large wrap-break-word" style={{ minWidth: '350px', maxWidth: '400px' }}>
        <Row>
          <Col className="mv-medium">
            <SubTitle>UNIQUE ID</SubTitle>
            <Text className="mt-x2-small">{selectedWorkflow.referenceId}</Text>
          </Col>
        </Row>
        <Row>
          <Col className="mv-medium">
            <SubTitle>CREATED BY</SubTitle>
            <div className="flex align-center mt-x2-small">
              <Avatar icon="user" size="small" shape="square" src={selectedWorkflow.createdBy.avatar} />
              <Text className="ml-x-small">{selectedWorkflow.createdBy.name}</Text>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mv-medium">
            <SubTitle>DATE CREATED</SubTitle>
            <Text className="mt-x2-small">
              {moment.tz(selectedWorkflow.createdOn, timezone).format('D MMM YYYY, hh:mm A')} (
              {moment.tz(selectedWorkflow.createdOn, timezone).fromNow()})
            </Text>
          </Col>
        </Row>
        <Row>
          <Col className="mv-medium">
            <div className="flex-row justify-between">
              <SubTitle>CUSTOMERS INVOLVED</SubTitle>
              {!isDisableAction && editableUsers && (
                <Icon type="edit" onClick={() => onOpenUsersListModal('CUSTOMER')} className="text-color-blue-action" />
              )}
            </div>
            <div className="mt-x2-small">
              <CustomerAndTeamMemberList
                userType="CUSTOMER"
                onShowUserListModal={onOpenUsersListModal}
                users={customerInvolved}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mv-medium">
            <div className="flex-row justify-between">
              <SubTitle>TEAM MEMBERS INVOLVED</SubTitle>
              {!isDisableAction && editableUsers && (
                <Icon
                  type="edit"
                  onClick={() => onOpenUsersListModal('TEAM_MEMBER')}
                  className="text-color-blue-action"
                />
              )}
            </div>
            <div className="mt-x2-small">
              <CustomerAndTeamMemberList
                userType="TEAM_MEMBER"
                onShowUserListModal={onOpenUsersListModal}
                users={teamMemberInvolved}
              />
            </div>
          </Col>
        </Row>
        {selectedWorkflow && selectedWorkflow.bookingDetail && (
          <Row>
            <Col className="mv-medium">
              <SubTitle>{selectedWorkflow.bookingDetail.attendanceId ? 'BOOKING DETAILS' : 'SESSION DETAILS'}</SubTitle>
              <div className="mt-x2-small">
                <Text>
                  <span className="text-weight-bolder">Service</span>: {selectedWorkflow.bookingDetail.serviceName}
                </Text>
              </div>
              <div className="mt-x2-small">
                <Text>
                  <span className="text-weight-bolder">Department</span>:{' '}
                  {selectedWorkflow.bookingDetail.serviceDepartmentName}
                </Text>
              </div>
              <div className="mt-x2-small">
                <Text>
                  <span className="text-weight-bolder">Date</span>:{' '}
                  {moment
                    .tz(selectedWorkflow.bookingDetail.startDateTime, selectedWorkflow.bookingDetail.timezone)
                    .format('DD/MM/YYYY')}
                </Text>
              </div>
              {selectedWorkflow.bookingDetail.attendanceId ? (
                <div>
                  <div className="mt-x2-small">
                    <Text>
                      <span className="text-weight-bolder">Customer</span>: {selectedWorkflow.bookingDetail.customer}
                    </Text>
                  </div>

                  <div className="mt-x-small cursor-pointer">
                    <Link to={`/bookings/details/${attendanceId}`}>
                      <HyperlinkButton>View booking</HyperlinkButton>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="mt-x-small cursor-pointer">
                  <Link to={`/group-service/${serviceId}/session/details/${serviceDateTimeId}`}>
                    <HyperlinkButton>View session</HyperlinkButton>
                  </Link>
                </div>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default WorkflowDetailsRightSideBar;
