import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const TextUnderline = forwardRef(function TextUnderline(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 4a.75.75 0 0 0-1.5 0v7a5.75 5.75 0 0 0 11.5 0V4a.75.75 0 0 0-1.5 0v7a4.25 4.25 0 0 1-8.5 0V4ZM6 19.25a.75.75 0 0 0 0 1.5h12a.75.75 0 0 0 0-1.5H6Z"
        fill="currentColor"
      />
    </Icon>
  );
});
