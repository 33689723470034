import { forwardRef } from 'react';
import { Illustration } from './illustration';
import type { IllustrationProps } from './illustration';
import type { Ref } from 'react';
export const Cursor = forwardRef(function Cursor(props: IllustrationProps, ref: Ref<SVGSVGElement>) {
  return (
    <Illustration {...props} ref={ref}>
      <path
        d="M80.355 60.994c2.488 3.028 4.591 5.703 6.817 8.271 4.135 4.77 8.288 9.524 12.545 14.18 1.462 1.599 3.316 2.83 4.896 4.332 2.678 2.539 5.44 5.01 7.856 7.786 1.563 1.797 1.411 4.222.123 6.171-1.322 1.999-2.982 3.796-4.642 5.542-.427.447-1.394.493-2.121.527-1.82.088-3.822-.409-5.44.173-6.674 2.404-10.716-1.329-14.387-5.842-4.08-5.01-7.953-10.186-11.932-15.276-.14-.178-.406-.254-.706-.435-.57 3.273-.667 6.63-1.774 9.617-2.141 5.782-4.714 11.421-7.396 16.98-1.11 2.307-5.664 1.907-7.198-.43-5.267-8.031-9.368-16.584-12.523-25.686-2.083-6.01-5.213-11.658-7.743-17.525-2.897-6.719-5.377-13.632-8.55-20.211-3.192-6.63-7.137-12.894-10.5-19.444-1.364-2.65-2.23-5.602-2.944-8.508-.169-.687 1.001-2.138 1.884-2.56 3.029-1.463 6.175-2.703 9.326-3.884 4.68-1.76 9.386-1.83 14.058.118 8.84 3.69 17.694 7.339 26.517 11.059 6.885 2.906 13.762 5.841 20.592 8.874 3.916 1.738 7.747 3.674 11.59 5.567 4.36 2.147 8.719 4.294 12.993 6.597.583.316 1.048 1.632.883 2.328-.177.76-1.056 1.472-1.791 1.961-7.206 4.796-15.029 7.837-23.764 8.398-1.985.127-3.94.763-6.673 1.32h.004ZM24.084 18.277c.118.468.135.72.24.932 4.026 7.954 8.402 15.753 12.005 23.893 4.11 9.292 7.603 18.862 11.236 28.365 3.387 8.87 6.547 17.833 9.943 26.698.642 1.675 1.926 3.1 2.914 4.644.283-.101.57-.202.853-.304.304-1.623.49-3.281.93-4.863 2.335-8.389 4.198-16.807 2.75-25.614-.182-1.11.151-2.362.48-3.48.757-2.556 2.78-3.172 4.77-1.413 2.319 2.05 4.718 4.066 6.712 6.407 4.012 4.707 7.598 9.667 12.473 13.729 5.841 4.863 10.826 10.755 16.241 16.141.452.452 1.567.827 2.031.582 3.443-1.805 3.895-5.651 1.04-8.326-2.632-2.463-5.411-4.774-7.963-7.313-2.737-2.73-5.351-5.589-7.903-8.495-4.654-5.302-9.309-10.608-13.79-16.053-2.138-2.602-1.652-3.926 1.71-4.757 3.755-.924 7.586-1.666 11.426-2.075 6.69-.713 12.101-4.142 18.484-7.761-4.786-2.387-8.828-4.543-12.985-6.445-11.299-5.175-22.615-10.312-33.985-15.327-7.688-3.391-15.494-6.504-23.215-9.82-5.61-2.407-10.961-1.125-16.406.663l.009-.008Z"
        fill="currentColor"
      />
    </Illustration>
  );
});
