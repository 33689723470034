import { IExtendedMessage, IMessage } from 'interfaces/message-interfaces';
import React from 'react';
import { MessageItem } from 'views/messaging/messages/components/tags/MessageItem';
import _ from 'lodash';
import { Text } from 'common-components/typography';
import moment from 'moment';

import { FaRegEnvelopeOpen } from 'react-icons/fa';
import { IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IMessagesContentProps {
  messages: IMessage[];

  currentUser: any; // from authStore.portalUser

  isLoading: boolean;
  isFetchingMore: boolean;

  fetchMoreMessages: any;
  deleteMessage: any;

  currentChannelMode: any;

  // this is used to indicate whether it's scrolled to the bottom or not.
  onBottomReached: any;
  onNotBottomReached: any;
}

interface IMessagesContentState {}

class MessagesContent extends React.Component<IMessagesContentProps, IMessagesContentState> {
  selfRef = null;
  scrollRef = null;
  endMessagesRef = null;

  constructor(props) {
    super(props);
    this.selfRef = React.createRef();
  }

  getScrollHeight = () => this.scrollRef.scrollHeight;

  scrollToBottom = ({ smooth = true }) => {
    const { onBottomReached } = this.props;

    const scrollOptions = smooth ? { behavior: 'smooth' } : undefined;

    onBottomReached();

    if (smooth) {
      // smooth scrolling
      if (this.endMessagesRef) {
        this.endMessagesRef.scrollIntoView(scrollOptions);
      }
    } else {
      // jump directly.
      if (this.scrollRef) {
        this.scrollRef.scrollTop = this.scrollRef.scrollHeight;
      }
    }
  };

  scrollTo = (position) => {
    if (this.scrollRef) {
      this.scrollRef.scrollTop = position;
    }
  };

  handleScroll = (e: any) => {
    // const { scrollTop, scrollTopMax } = e.target;
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    const scrollTopMax = scrollHeight - clientHeight;

    // TODO How do we handle threshold buffering, eg load when on scrollPosition 200...?

    // Scrolled to the top.
    if (scrollTop === 0) {
      const { fetchMoreMessages } = this.props;
      fetchMoreMessages();
    }

    const diff = Math.abs(scrollTopMax - scrollTop);
    const scrollThreshold = 50;

    // Scrolled to bottom.
    if (diff <= scrollThreshold) {
      const { onBottomReached } = this.props;
      onBottomReached();
    } else {
      const { onNotBottomReached } = this.props;
      onNotBottomReached();
    }
  };

  renderMessageItem = (message: IExtendedMessage, index: number) => {
    const { messages, currentUser, deleteMessage } = this.props;

    // TODO get Current User from store.
    // const currentUser = { displayName: 'Jir Wong', currentUserId: '1234' };
    const { displayName: currentUserName, userId, attachmentUrl: currentUserAvatar } = currentUser;

    let nextMessage = null;
    let previousMessage = null;

    nextMessage = messages[index + 1];
    previousMessage = messages[index - 1];

    const fullMessageProps = {
      ...message,
      currentUserId: userId,
      currentUserName,
      currentUserAvatar,
      index,
      nextMessage,
      previousMessage,
      preferredTimezone: this.props.currentUser.timezone,
    };

    return <MessageItem message={fullMessageProps} key={message.messageId} deleteMessage={deleteMessage} />;
  };

  render() {
    const { messages, currentChannelMode, isLoading } = this.props;

    const currentDate = new Date();

    return (
      <div
        className="pt-medium flex-1 flex-column"
        style={{ overflowY: 'scroll' }}
        ref={(c) => (this.scrollRef = c)}
        onScroll={this.handleScroll}
      >
        {/* No message found. Assume that it's a new conversation */}

        {currentChannelMode === 'new' && (_.isEmpty(messages) || messages.length === 0) && (
          <div className="">
            {/* Date Tag */}
            <div className="align-center text-align-center pt-medium pb-small">
              <Text size="small" color="secondary" weight="bold" className="select-none">
                {moment(currentDate).format('dddd, D MMMM YYYY')}
              </Text>
            </div>

            <div className="pt-medium text-align-center">
              <FaRegEnvelopeOpen className="text-color-tertiary text-size-x3-large" style={{ opacity: 0.2 }} /> <br />
              <Text weight={'bold'} size="large" color="tertiary" className="pt-small">
                Start a conversation
              </Text>
              <br />
              <Text size="regular" color={'tertiary'}>
                Type a message below, and send it to start the conversation!
              </Text>
            </div>
          </div>
        )}

        {!isLoading && _.map(messages, this.renderMessageItem)}

        <div ref={(c) => (this.endMessagesRef = c)} />
      </div>
    );
  }
}

export default MessagesContent;
