import { tv } from 'tailwind-variants';

import type { VariantProps } from 'tailwind-variants';

export const statusLight = tv(
  {
    base: ['rounded-full'],
    variants: {
      size: {
        lg: ['w-3', 'min-w-3', 'h-3', 'min-h-3'],
        md: ['w-2.5', 'min-w-2.5', 'h-2.5', 'min-h-2.5'],
        sm: ['w-2', 'min-w-2', 'h-2', 'min-h-2'],
        xs: ['w-1.5', 'min-w-1.5', 'h-1.5', 'min-h-1.5'],
      },
      tone: {
        critical: [],
        info: [],
        neutral: [],
        notice: [],
        positive: [],
        blue: [],
        canary: [],
        lilac: [],
      },
      onBackground: {
        true: [],
        false: [],
      },
    },
    compoundVariants: [
      { onBackground: false, tone: 'critical', className: ['bg-critical'] },
      { onBackground: false, tone: 'info', className: ['bg-info'] },
      { onBackground: false, tone: 'neutral', className: ['bg-neutral'] },
      { onBackground: false, tone: 'notice', className: ['bg-notice'] },
      { onBackground: false, tone: 'positive', className: ['bg-positive'] },
      { onBackground: false, tone: 'blue', className: ['bg-blue'] },
      { onBackground: false, tone: 'canary', className: ['bg-canary'] },
      { onBackground: false, tone: 'lilac', className: ['bg-lilac'] },

      { onBackground: true, tone: 'critical', className: ['bg-critical-weak'] },
      { onBackground: true, tone: 'info', className: ['bg-info-weak'] },
      { onBackground: true, tone: 'neutral', className: ['bg-neutral-weak'] },
      { onBackground: true, tone: 'notice', className: ['bg-notice-weak'] },
      { onBackground: true, tone: 'positive', className: ['bg-positive-weak'] },
      { onBackground: true, tone: 'blue', className: ['bg-blue-weak'] },
      { onBackground: true, tone: 'canary', className: ['bg-canary-weak'] },
      { onBackground: true, tone: 'lilac', className: ['bg-lilac-weak'] },
    ],
    defaultVariants: {
      size: 'md',
      onBackground: false,
    },
  },
  { twMerge: false },
);

type Variants = Required<VariantProps<typeof statusLight>>;

export type StatusLightVariants = {
  /**
   * Display status light on solid backgrounds.
   * @default false
   */
  onBackground?: Variants['onBackground'];
  /**
   * Visually change the dimensions.
   * @default md
   */
  size?: Variants['size'];
  tone: Variants['tone'];
};
