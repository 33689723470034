import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { Icon } from 'antd';
import ActionSelectItem from 'common-components/items/ActionSelectItem';
import { WorkflowPermissionType } from 'utilities/enum-utils';
import { getWorkflowPermissionName } from 'views/account-management/utils/permission-utils';

interface IWorkflowSectionModalProps {
  isOpen: any;
  onClose: any;
  setSelectedOption: (servicePermission) => any;
  permissions?: any;
}

interface IWorkflowSectionModalState {
  workflowPermission: string;
}

class WorkflowSectionModal extends Component<IWorkflowSectionModalProps, IWorkflowSectionModalState> {
  state = { workflowPermission: WorkflowPermissionType.BASIC };

  private _onCloseModal = () => {
    this.props.onClose();
  };

  private _setSelectedOption = async () => {
    await this.props.setSelectedOption({ workflowPermission: this.state.workflowPermission });
    this._onCloseModal();
  };

  private _changePermissionMode = (newMode) => {
    this.setState({ workflowPermission: newMode });
  };

  resetPermissions = () => {
    const workflowPermission = getWorkflowPermissionName(this.props.permissions);
    this.setState({ workflowPermission });
  };

  componentDidMount() {
    this.resetPermissions();
  }

  componentDidUpdate(prevProps: Readonly<IWorkflowSectionModalProps>) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        this.resetPermissions();
      }
    }
  }

  render() {
    const { workflowPermission } = this.state;

    return (
      <ActionModal
        title={
          <>
            <Icon type={'share-alt'} className={'mr-small text-size-x2-large'} />
            Workflows
          </>
        }
        isOpen={this.props.isOpen}
        onClose={this._onCloseModal}
        width="medium"
      >
        <div className="mv-medium anim-slide-left">
          <div className={'mb-x-large'}>
            <div className={'mb-large'}>
              <Text size={'x-large'}>Permission level</Text>
            </div>

            <ActionSelectItem
              selectedAction={workflowPermission}
              value={WorkflowPermissionType.BASIC}
              title={'Basic (default)'}
              description={'Can view and manage workflows they are assigned to'}
              onSetAction={this._changePermissionMode}
            />

            <ActionSelectItem
              selectedAction={workflowPermission}
              value={WorkflowPermissionType.ELEVATED}
              title={'Elevated'}
              description={'Can view and manage all workflows'}
              onSetAction={this._changePermissionMode}
            />

            <ActionSelectItem
              selectedAction={workflowPermission}
              value={WorkflowPermissionType.MANAGER}
              title={'Manager'}
              description={'Can view, manage and create workflows'}
              onSetAction={this._changePermissionMode}
            />
          </div>

          <ActionModalFooter>
            <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._setSelectedOption}>
              Save changes
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    );
  }
}

export default WorkflowSectionModal;
