import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Transform = forwardRef(function Transform(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.75 5a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-1.396-.75a2.751 2.751 0 1 1 3.396 3.396v8.707a2.751 2.751 0 1 1-3.396 3.397H7.647a2.751 2.751 0 1 1-3.397-3.396V7.646A2.751 2.751 0 1 1 7.647 4.25h8.707ZM5.75 16.354c.916.259 1.638.98 1.897 1.896h8.707c.259-.916.98-1.637 1.896-1.896V7.646a2.756 2.756 0 0 1-1.896-1.896H7.647A2.756 2.756 0 0 1 5.75 7.646v8.707ZM19 17.75a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm-14-14a1.25 1.25 0 0 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM3.75 19a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
