import React, { CSSProperties, PureComponent } from 'react';

import { Text } from './index';
import { Icon } from 'antd';

interface IFieldValueTextProps {
  text: string;
  className?: string;
  style?: CSSProperties;
  isEditable?: boolean;
  editFunction?: () => void;
}

class FieldValueText extends PureComponent<IFieldValueTextProps, any> {
  render() {
    const { text, className = null, style = null, isEditable = false, editFunction = null } = this.props;

    return (
      <div>
        <Text size="x-large" className={className} style={style}>
          {text}
        </Text>
        {isEditable && <Icon type={'edit'} className={'edit-icon'} onClick={editFunction} />}
      </div>
    );
  }
}

export default FieldValueText;
