import React, { useState } from 'react';
import { DateRange } from 'views/sil-service/tabs/schedule/components/rostering-daterange-selector';
import { RangeOption } from 'views/sil-service/tabs/schedule/components/range-selector';
import { Button, Calendar, Card, RangeCalendar, Text } from '@good/components';
import { addDays, endOfDay } from 'date-fns';
import { useInternationalizedToJsDate } from 'utilities/internationalized-date/use-internationalized-to-js-date';
import { Popover } from 'design-components/popover';
import { ChevronLeft } from 'design-components/icon';
import { ChevronRight } from '@good/icons';
import { differenceInDaysNotRounded } from 'utilities/date-utils';
import { DateRangeToText } from './components/date-range-to-text/date-range-to-text';
import { SelectedRangeOptions } from './components/selected-range-options/selected-range-options';

export const RosteringDateRangeSelector = ({
  dateRange,
  onDateRangeChange,
  rangeOption,
  timezone,
}: {
  dateRange: DateRange;
  onDateRangeChange: (dateRange: DateRange) => void;
  rangeOption: RangeOption;
  timezone: string;
}) => {
  const { start, end } = dateRange;

  const { internationalizedDate: intStart } = useInternationalizedToJsDate({ date: start, timezone });
  const { internationalizedDate: intEnd } = useInternationalizedToJsDate({ date: end, timezone });

  const onSingleChange = (date: Date) => {
    onDateRangeChange({
      start: date,
      end: endOfDay(date),
    });
    setIsActive(false);
  };

  const onRangeChange = ({ start, end }: { start: Date; end: Date }) => {
    onDateRangeChange({
      start,
      end,
    });
    setIsActive(false);
  };

  const selectPreviousRange = () => {
    const daysToRemove = Math.ceil(differenceInDaysNotRounded(end, start));
    onDateRangeChange({ start: addDays(start, -daysToRemove), end: addDays(end, -daysToRemove) });
  };

  const selectNextRange = () => {
    const daysToAdd = Math.ceil(differenceInDaysNotRounded(end, start));
    onDateRangeChange({ start: addDays(start, daysToAdd), end: addDays(end, daysToAdd) });
  };

  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <div className='flex'>
        <Button
          onPress={selectPreviousRange}
          className='rounded-r-none border-r-0 w-8 text-black'
          aria-label='Select previous date range'
        >
          <ChevronLeft />
        </Button>
        <Button onPress={() => setIsActive(!isActive)} className='rounded-none text-black'>
          <Text>
            <DateRangeToText dateRange={dateRange} />
          </Text>
        </Button>
        <Button
          onPress={selectNextRange}
          className='rounded-l-none border-l-0 w-8 text-black'
          aria-label='Select next date range'
        >
          <ChevronRight />
        </Button>
      </div>
      <Popover isOpen={isActive} onClose={() => setIsActive(false)} backgroundColor='transparent'>
        <Card className='flex gap-4 bg-white mt-2'>
          <div className='w-60'>
            <SelectedRangeOptions
              dateRange={dateRange}
              rangeOption={rangeOption}
              onDateRangeChange={onDateRangeChange}
            />
          </div>
          <div className='w-[0.0625rem] bg-gray-light-1'></div>
          <div className='p-1'>
            {rangeOption === 'day' ? (
              <Calendar value={intStart} onChange={(date) => onSingleChange(date.toDate(timezone))} className='p-0' />
            ) : (
              <RangeCalendar
                value={{ start: intStart, end: intEnd }}
                onChange={({ start, end }) =>
                  onRangeChange({ start: start.toDate(timezone), end: end.toDate(timezone) })
                }
                className='p-0'
              />
            )}
          </div>
        </Card>
      </Popover>
    </div>
  );
};
