import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';

const PaymentMethodError = () => {
  return (
    <div
      style={{ margin: 'auto', width: '90%' }}
      className="mt-large bordered border-red-lighter rounded line-height-135 flex-row p-medium bg-red-lightest"
    >
      <Icon type="close-circle" theme="filled" className="mr-small pt-x-small text-color-red" />
      <div className="flex-column">
        <Text size="large" color="red" lineHeight={135} className="mb-medium">
          Invalid Payment Method
        </Text>
        <Text size="large" lineHeight={135}>
          Sorry, your card could not be validated. Please try using another card or reach out to your bank to find out
          why.
        </Text>
      </div>
    </div>
  );
};

export default PaymentMethodError;
