import { Icon, Tooltip } from 'antd';
import React, { ReactNode } from 'react';

interface IDefaultTooltipsProps {
  content: ReactNode | string;
  className?: string;
  overlayClassName?: string;
  icon?: string;
  iconClassName?: string;
  isDisplayed?: boolean;
}

function DefaultTooltips({
  content,
  className = '',
  overlayClassName = '',
  icon = 'question-circle',
  iconClassName = 'ml12',
  isDisplayed = true,
}: IDefaultTooltipsProps) {
  return isDisplayed ? (
    <Tooltip title={content} overlayClassName={overlayClassName} className={className}>
      <Icon type={icon} className={iconClassName} />
    </Tooltip>
  ) : (
    <></>
  );
}

export default DefaultTooltips;
