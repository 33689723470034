import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { notification } from 'antd';
import { Paragraph, FieldLabel, Text } from 'common-components/typography';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import moment from 'moment-timezone';
import Animate from 'rc-animate';
import CommonUtils from 'utilities/common-utils';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import { GroupServiceSessionStatus } from 'utilities/enum-utils';

interface ReinstateGroupBookingModalProps {
  isOpen: any;
  onClose: any;
  booking: any;
  doReinstateGroupBooking: any;
}
interface ReinstateGroupBookingModalState {
  step: number;
  canManuallyClose: boolean;
  title: string;
  startDateTime: Date;
  endDateTime: Date;
  showDateError: boolean;
  isLoading: boolean;
}

export class ReinstateGroupBookingModal extends Component<
  ReinstateGroupBookingModalProps,
  ReinstateGroupBookingModalState
> {
  state = {
    step: 1,
    canManuallyClose: true,
    title: '',
    startDateTime: null,
    endDateTime: null,
    showDateError: false,
    isLoading: false,
  };

  private _renderView = () => {
    const { step } = this.state;
    const { booking } = this.props;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>Are you sure you want to add this customer back to this session?</Paragraph>
          <ActionModalFooter>
            <GhostButton
              className="mr-medium"
              size="large"
              onClick={this._onCloseModal}
              disabled={this.state.isLoading}
            >
              Cancel
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this._onAccept} disabled={this.state.isLoading}>
              Re-instate
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>The customer has been added back to the session.</Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 3) {
      return (
        <Animate transitionName="fade" transitionAppear>
          <div>
            <Paragraph>You cannot add this customer back to this session as the session is at full capacity.</Paragraph>
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        </Animate>
      );
    }
    if (step === 4) {
      return (
        <div>
          <Paragraph>Are you sure you want to add this customer back to this session?</Paragraph>
          <Paragraph>As the session has been completed, please indicate the customers start and finish time.</Paragraph>
          <div className="flex-row">
            <div className="flex-1 mr-large">
              <FieldLabel text={'START TIME'} />
              <div className="flex-row mt-x2-small">
                <DatePicker
                  dateFormat="d/M/yyyy"
                  className="gh-datepicker rounded mr-small"
                  calendarClassName="gh-datepicker-calendar"
                  placeholderText="Start date"
                  onChange={this._updateStartDate}
                  selected={this.state.startDateTime}
                />
                <TimeInput
                  size="large"
                  className="bg-white"
                  value={moment.tz(this.state.startDateTime, booking.timezone)}
                  onChange={this._onChangeStartTime}
                />
              </div>
            </div>

            <div className="flex-1">
              <FieldLabel text={'END TIME'} />
              <div className="flex-row mt-x2-small">
                <DatePicker
                  dateFormat="d/M/yyyy"
                  className="gh-datepicker rounded mr-small"
                  calendarClassName="gh-datepicker-calendar"
                  placeholderText="End date"
                  onChange={this._updateEndDate}
                  selected={this.state.endDateTime}
                />
                <TimeInput
                  size="large"
                  className="bg-white"
                  value={moment.tz(this.state.endDateTime, booking.timezone)}
                  onChange={this._onChangeEndTime}
                />
              </div>
              {this.state.showDateError && <Text color="red">End time must be after start time</Text>}
            </div>
          </div>
          <div className="mt-medium">
            <ActionModalFooter align="right">
              <SecondaryButton
                size="large"
                className="mr-medium"
                onClick={this._onCloseModal}
                disabled={this.state.isLoading}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onAccept} disabled={this.state.isLoading}>
                Save
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        </div>
      );
    }
    if (step === 5) {
      return (
        <Animate transitionName="fade" transitionAppear>
          <div>
            <Paragraph>The customer has been added back to the session with the following details.</Paragraph>
            <Paragraph>
              <b>Start time:</b> {moment.tz(this.state.startDateTime, booking.timezone).format('DD/MM/YYYY h:mm a')}
              <br />
              <b>Finish time:</b> {moment.tz(this.state.endDateTime, booking.timezone).format('DD/MM/YYYY h:mm a')}
            </Paragraph>
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        </Animate>
      );
    }

    if (step === 6) {
      const momentStartDateTime = moment.tz(booking.startDateTime, booking.timezone);
      const momentEndDateTime = moment.tz(booking.endDateTime, booking.timezone);
      return (
        <>
          <Paragraph className="mb-medium">
            This booking cannot be re-instated as the selected customer is scheduled to be archived on{' '}
            <Text weight="bold">
              {moment.tz(booking.customerScheduleArchiveDate, booking.timezone).format('DD/MM/YYYY')}
            </Text>
            .
          </Paragraph>
          <FieldLabel text={'SCHEDULED SESSION TIME'} />
          {moment
            .tz(booking.startDateTime, booking.timezone)
            .isSame(moment.tz(booking.endDateTime, booking.timezone), 'day') ? (
            <Text>
              {momentStartDateTime.format('DD/MM/YYYY')}, {momentStartDateTime.format('LT')} -{' '}
              {momentEndDateTime.format('LT')}
            </Text>
          ) : (
            <Text>
              {momentStartDateTime.format('DD/MM/YYYY LT')} - {momentEndDateTime.format('DD/MM/YYYY LT')}
            </Text>
          )}
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }
  };

  private _onChangeStartTime = async (date) => {
    const { booking } = this.props;
    let startDateTime = moment.tz(CommonUtils.formatCeilingDateTime(date), booking.timezone);
    const dateDifference = startDateTime.diff(this.state.startDateTime, 'minutes');

    this.setState({
      startDateTime: startDateTime.toDate(),
      endDateTime: moment.tz(this.state.endDateTime, booking.timezone).add(dateDifference, 'minutes').toDate(),
    });
  };

  private _onChangeEndTime = async (date) => {
    const { booking } = this.props;
    let endDateTime = moment.tz(CommonUtils.formatCeilingDateTime(date), booking.timezone);

    this.setState({
      endDateTime: endDateTime.toDate(),
    });
  };

  private _updateStartDate = (event) => {
    this.setState({
      startDateTime: event,
    });
  };

  private _updateEndDate = (event) => {
    this.setState({ endDateTime: event });
  };

  private _onAccept = async () => {
    const { booking } = this.props;
    try {
      this.setState({ canManuallyClose: false, isLoading: true });
      const payload: any = {
        serviceId: booking.serviceId,
        serviceDateTimeId: booking.serviceDateTimeId,
        attendanceIds: [booking.bookingId],
      };
      if (booking.sessionStatus === GroupServiceSessionStatus.COMPLETED) {
        payload.startDateTime = this.state.startDateTime;
        payload.endDateTime = this.state.endDateTime;
      }

      await this.props.doReinstateGroupBooking(payload);

      if (booking.sessionStatus === GroupServiceSessionStatus.COMPLETED) {
        this.setState({
          step: 5,
          canManuallyClose: true,
          title: 'Booking re-instated',
          isLoading: false,
        });
      } else {
        this.setState({ step: 2, canManuallyClose: true, title: 'Booking re-instated', isLoading: false });
      }
    } catch (e) {
      this.setState({ canManuallyClose: true, isLoading: false });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: '' }, onClose);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      const { booking } = this.props;
      if (
        booking &&
        moment
          .tz(booking.endDateTime, booking.timezone)
          .isSameOrAfter(moment.tz(booking.customerScheduleArchiveDate, booking.timezone))
      ) {
        this.setState({ step: 6, title: 'Cannot re-instate booking' });
      } else {
        const toBeUpdatedStartDateTime = booking.startDateTime;
        const toBeUpdatedEndDateTime = booking.endDateTime;
        if (booking.capacity && booking.bookedCapacity >= booking.capacity) {
          this.setState({ step: 3, title: 'Session full' });
        } else if (booking.sessionStatus === GroupServiceSessionStatus.COMPLETED) {
          this.setState({
            startDateTime: moment
              .tz(CommonUtils.formatCeilingDateTime(new Date(toBeUpdatedStartDateTime)), booking.timezone)
              .toDate(),
            endDateTime: moment
              .tz(CommonUtils.formatCeilingDateTime(new Date(toBeUpdatedEndDateTime)), booking.timezone)
              .toDate(),
            step: 4,
            title: 'Re-instate booking',
          });
        } else {
          this.setState({ step: 1, title: 'Re-instate booking' });
        }
      }
    }
  }

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this._renderView()}</div>
      </ActionModal>
    );
  }
}
