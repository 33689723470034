import { Text } from '@good/components';
import { Avatar } from '@goodhuman-me/components';
import { Skeleton } from 'antd';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ContractedHoursUnit, InsightsTableResponseRow } from '../../insights';

type OptionalNumber = number | null | undefined;

type InsightsTableRowProperties = InsightsTableResponseRow & {
  dataStatus: 'success' | string;
};

export const toPercentage = (n: OptionalNumber): string => `${n ?? 0}%`;

const hoursToDisplayValue = (hours: number): string => {
  const minutes = hours * 60;

  if (minutes % 60) {
    return `${Math.floor(minutes / 60)}h ${Math.floor(minutes % 60)}m`;
  }

  return `${hours}h`;
};

const renderContractedHours = (hours: OptionalNumber, units: ContractedHoursUnit | null | undefined): string => {
  if (typeof hours !== 'number' || typeof units !== 'string') {
    return '-';
  }

  return `${hoursToDisplayValue(hours)} per ${units.slice(0, -2)}`;
};

export const InsightsTableRowSkeleton: React.FC = () => (
  <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} active={true} className="anim-slide-left" />
);

export const InsightsTableRow: React.FC<InsightsTableRowProperties> = ({
  supportWorkerId,
  attachmentUrl,
  displayName,
  contractedHours,
  contractedHoursUnit,
  billedHours,
  nonBilledHours,
  utilisation,
}: InsightsTableRowProperties) => {
  const { t } = useTranslation('', { keyPrefix: 'insights.table.row' });

  const renderUtilisation = (): ReactNode => {
    const { allTimePercent, toTodayPercent } = utilisation;
    if (typeof allTimePercent !== 'number' || typeof toTodayPercent !== 'number') {
      return '-';
    }

    return (
      <div className="flex flex-row space-x-2">
        <strong>
          {toPercentage(toTodayPercent)} {t('toDate')}
        </strong>
        <i>
          {toPercentage(allTimePercent)} {t('actual')}
        </i>
      </div>
    );
  };

  return (
    <tr className="border-t-2 border-neutral-weak text-gray-dark-2">
      <td>
        <Link className="align-center flex flex-row justify-start space-x-3" to={`/team/details/${supportWorkerId}`}>
          <div className="align-center flex h-10 w-10 justify-center rounded-lg bg-gray-light-1 text-h-xl text-white">
            <Avatar kind="square" size="large" src={attachmentUrl} alt={displayName} />
          </div>
          <Text className="truncate font-bold">{displayName}</Text>
        </Link>
      </td>
      <td>
        <Text>{renderContractedHours(contractedHours, contractedHoursUnit)}</Text>
      </td>
      <td>
        <Text>{hoursToDisplayValue(billedHours ?? 0)}</Text>
      </td>
      <td>
        <Text>{hoursToDisplayValue(nonBilledHours ?? 0)}</Text>
      </td>
      <td>
        <Text>{renderUtilisation()}</Text>
      </td>
    </tr>
  );
};
