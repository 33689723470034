import React from 'react';
import { useHistory } from 'react-router-dom';

import { ActionIcon, Indicator, Text } from '@good/ui/core';
import { Email } from '@good/icons';

export const MessagesButton = () => {
  const history = useHistory();
  // TODO: Currently, the messagingBadgeCount is not correctly cleared in the b/e.
  const messagesCount = 0; // useSelector((state: IRootState) => state.notificationsStore.messagingBadgeCount);

  const onClick = () => {
    history.push('/messaging');
  };

  return (
    <Indicator
      offset={6}
      withBorder
      color='red'
      size={24}
      label={
        <Text size='xs' fw={700} px={4}>
          {messagesCount}
        </Text>
      }
      disabled={!messagesCount}
    >
      <ActionIcon variant='white' color='gray.8' size={36} fz={20} onClick={onClick} aria-label='messages'>
        <Email />
      </ActionIcon>
    </Indicator>
  );
};
