import React from 'react';
import { useFormContext, MultipleFieldErrors } from 'react-hook-form';

import { Text } from 'common-components/typography';
import { EditRatiosSchema } from './schema';

interface ITimeDurationErrorProps {
  index: number;
}

interface IErrorsProps {
  errors: MultipleFieldErrors;
  ignoreTypes?: string[];
}

function Errors({ errors, ignoreTypes }: IErrorsProps) {
  if (!errors) {
    return null;
  }

  return (
    <>
      {Object.entries(errors)?.map(([type, error]) => {
        if (ignoreTypes?.includes(type)) {
          return null;
        }

        return (
          <div key={`${type}${error}`}>
            {error && (
              <Text size="small" color="red-dark">
                {error}
              </Text>
            )}
          </div>
        );
      })}
    </>
  );
}

export function TimeDurationError({ index }: ITimeDurationErrorProps) {
  const { formState, watch } = useFormContext<EditRatiosSchema>();
  const isExactTime = watch(`ratios.${index}.isExactTime`);

  const startTimeErrors = formState.errors?.ratios?.[index]?.customRatio?.startDateTime;
  const endTimeErrors = formState.errors?.ratios?.[index]?.customRatio?.endDateTime;
  const timeDurationErrors = formState.errors?.ratios?.[index]?.durationRatio?.duration;

  return (
    <>
      {isExactTime ? (
        <>
          <Errors errors={startTimeErrors?.types} />
          <Errors errors={endTimeErrors?.types} ignoreTypes={['isSameAsOtherTime', 'isEndBeforeStartTime']} />
        </>
      ) : (
        <Errors errors={timeDurationErrors?.types} />
      )}
    </>
  );
}
