import React, { Component } from 'react';
import { Col, DatePicker, Input, Row, Select, Icon, Radio, Form, notification, Tooltip, Avatar } from 'antd';
import { SubTitle, Text, Title, Paragraph } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { IServiceListItemLite } from 'interfaces/service-interfaces';
import _ from 'lodash';
import { INewBookingData, ISleepoverTimeSlot } from 'interfaces/booking-interfaces';
import TimeInput from 'common-components/time-input/TimeInput';
import { RecurringBookingPattern, ServiceType, SleepoverTimeSlotErrorType, SleepoverType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import { FormComponentProps } from 'antd/es/form';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { extendMoment } from 'moment-range';
import moment from 'moment-timezone';
import { TimezoneIndicator } from 'common-components/timezone';
import { Warning } from 'common-components/alerts';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { timeZone } from 'interfaces/timezone-type';
import SleepoverShiftBookingModal from '../../../components/SleepoverShiftBookingModal';

const { WeekPicker } = DatePicker;
const { Option } = Select;
// @ts-ignore
const extMoment = extendMoment(moment);

interface Step2ServiceDetailsPanelProps extends FormComponentProps {
  onNextStep: any;
  onPreviousStep: () => any;
  servicesLite: IServiceListItemLite[];
  doFetchServicesLite: any;
  doFetchSingleService: any;
  setSelectedCreateService: typeof dispatch.servicesStore.setSelectedCreateService;
  bookSelectedService: typeof state.servicesStore.bookSelectedService;
  newBookingData: INewBookingData | any;
  doSetNewBookingData: typeof dispatch.bookingsStore.doSetNewBookingData;
  portalUser: typeof state.authStore.portalUser;
  doCheckServiceAgreementDate: typeof dispatch.bookingsStore.doCheckServiceAgreementDate;
  doCheckServiceAvailability: typeof dispatch.bookingsStore.doCheckServiceAvailability;
}

interface Step2ServiceDetailsPanelState {
  isLoading: boolean;
  isLoadingBookingTimes: boolean;
  selectedWeek: any;
  summary: any;
  errorStartTime: string;
  errorEndTime: string;
  errorRangeEndTime: string;
  bookingTimezone: timeZone;
  isOpen: boolean;
  showActiveAgreementWarning: boolean;
  recurringPatternError: string;
  recurrenceToError: string;
  isSleepoverModalOpen: boolean;
  isSleepoverError: boolean;
  sleepoverType?: SleepoverType;
  sleepoverTimeSlots?: ISleepoverTimeSlot[];
}

export class Step2ServiceDetailsPanel extends Component<Step2ServiceDetailsPanelProps, Step2ServiceDetailsPanelState> {
  state = {
    isLoading: false,
    isLoadingBookingTimes: false,
    selectedWeek: moment(),
    summary: null,
    errorStartTime: null,
    errorEndTime: null,
    errorRangeEndTime: null,
    bookingTimezone: this.props.bookSelectedService ? this.props.bookSelectedService.timezone : moment.tz.guess(),
    isOpen: false,
    showActiveAgreementWarning: false,
    recurringPatternError: null,
    recurrenceToError: null,
    isSleepoverModalOpen: false,
    sleepoverType: null,
    sleepoverTimeSlots: [],
    isSleepoverError: false,
  };

  // Update Start Date
  private _setStartDate = (date) => {
    const { form } = this.props;
    const { bookingTimezone } = this.state;

    const roundedDate = CommonUtils.formatCeilingDateTime(date);

    const startDate = moment.tz(roundedDate, bookingTimezone).set('seconds', 0).set('ms', 0);
    const endDate = moment.tz(roundedDate, bookingTimezone).add(1, 'hour').set('seconds', 0).set('ms', 0);

    setTimeout(() => {
      form.setFieldsValue(
        {
          bookStartDate: startDate,
          bookStartTime: startDate,
          bookEndDate: endDate,
          bookEndTime: endDate,
        },
        () => this._dateChanged(),
      );
    }, 0);
  };

  private _dateChanged = async () => {
    this.props.form.validateFields();
    this.setState({ errorEndTime: null, errorStartTime: null, errorRangeEndTime: null });
    this._checkCustomerActiveServiceAgreement();
    await this._checkMultiDay();
    this._validateSleepoverTimeSlot(this.state.sleepoverType, this.state.sleepoverTimeSlots);
  };

  private _setSelectedServiceDateTimeId = async (serviceDateTimeId) => {
    const { newBookingData, doSetNewBookingData, bookSelectedService } = this.props;
    const { bookingTimezone } = this.state;
    serviceDateTimeId = serviceDateTimeId.target.value;
    const timeAvailability = _.find(
      bookSelectedService.timeAvailabilities,
      (time) => time.serviceDateTimeId === serviceDateTimeId,
    );
    const bookStartDate = moment.tz(timeAvailability.startDateTime, bookingTimezone);
    const bookEndDate = moment.tz(timeAvailability.endDateTime, bookingTimezone);
    await doSetNewBookingData({
      ...newBookingData,
      serviceDateTimeId: serviceDateTimeId,
      bookStartDate: bookStartDate.toDate(),
      bookEndDate: bookEndDate.toDate(),
      bookStartTime: bookStartDate.toDate(),
      bookEndTime: bookEndDate.toDate(),
      selectedWorkerId: null,
      selectedWorker: null,
    });
  };

  // Update End Date
  private _setEndDate = (date) => {
    const { form } = this.props;
    const { bookingTimezone } = this.state;

    const roundedDate = CommonUtils.formatCeilingDateTime(date);
    const bookStartTime = form.getFieldValue('bookStartTime');

    if (date !== null) {
      if (moment.tz(roundedDate, bookingTimezone) <= moment.tz(bookStartTime, bookingTimezone)) {
        const startTime = moment.tz(bookStartTime, bookingTimezone).add(1, 'hour').set('seconds', 0).set('ms', 0);
        setTimeout(() => {
          form.setFieldsValue(
            {
              bookEndDate: startTime,
              bookEndTime: startTime,
            },
            () => this._dateChanged(),
          );
        }, 0);
      } else {
        const endTime = moment.tz(roundedDate, bookingTimezone).set('seconds', 0).set('ms', 0);
        setTimeout(() => {
          form.setFieldsValue(
            {
              bookEndDate: endTime,
              bookEndTime: endTime,
            },
            () => this._dateChanged(),
          );
        }, 0);
      }
    } else {
      form.setFieldsValue({ bookEndDate: '' });
    }
  };

  private _checkCustomerActiveServiceAgreement = async () => {
    const { selectedServiceId, selectedCustomerId, selectedService } = this.props.newBookingData;
    const fieldsValue = this.props.form.getFieldsValue();
    const { bookingTimezone } = this.state;
    if (
      fieldsValue.bookStartDate &&
      fieldsValue.bookEndDate &&
      selectedServiceId &&
      selectedService.servicePaymentSources &&
      selectedService.servicePaymentSources.length === 1
    ) {
      const fullStartDate = moment.tz(fieldsValue.bookStartDate, bookingTimezone).toDate();
      const fullEndDate = moment.tz(fieldsValue.bookEndDate, bookingTimezone).toDate();

      const result: any = await this.props.doCheckServiceAgreementDate({
        startDateTime: fullStartDate,
        endDateTime: fullEndDate,
        serviceId: selectedServiceId,
        customerUserId: selectedCustomerId,
        paymentSourceType: selectedService.servicePaymentSources[0],
      });
      if (result && !result.conflict) {
        this.setState({
          showActiveAgreementWarning: true,
        });
      } else {
        this.setState({
          showActiveAgreementWarning: false,
        });
      }
    }
  };

  // Update Selected Date
  private _setSelectedService = async (serviceId) => {
    const { servicesLite, newBookingData, doSetNewBookingData, doFetchSingleService, setSelectedCreateService } =
      this.props;
    this.setState({ isLoadingBookingTimes: true });
    setSelectedCreateService({});
    const selectedService = _.find(servicesLite, (s) => s.serviceId === serviceId);

    await doSetNewBookingData({
      ...newBookingData,
      selectedServiceId: serviceId,
      selectedService,
      selectedWorkerId: null,
      selectedWorker: null,
      sleepoverType: null,
      sleepoverTimeSlots: [],
      sleepoverTimeSlotsRecurring: null,
    });

    await doFetchSingleService({ serviceId });
    const isOpenDifferentTimezoneModal =
      moment.tz(this.props.portalUser.timezone).format('Z') !==
      moment.tz(this.props.bookSelectedService.timezone).format('Z');
    this.setState({
      isLoadingBookingTimes: false,
      bookingTimezone: this.props.bookSelectedService.timezone,
      isOpen: isOpenDifferentTimezoneModal,
    });
    this._checkCustomerActiveServiceAgreement();
  };

  private _onNextStep = async () => {
    const { form, bookSelectedService, newBookingData, doSetNewBookingData } = this.props;
    const { bookingTimezone, sleepoverType, sleepoverTimeSlots } = this.state;

    let isFormValid = true;
    isFormValid = await this._validateBookingTime();
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    // schedule archive customer validation
    if (
      (newBookingData.selectedCustomer.scheduleArchiveDate &&
        moment
          .tz(form.getFieldsValue().bookEndDate, bookingTimezone)
          .isSameOrAfter(moment.tz(newBookingData.selectedCustomer.scheduleArchiveDate, bookingTimezone))) ||
      (newBookingData.selectedCustomer.scheduleArchiveDate &&
        moment
          .tz(form.getFieldsValue().recurringTo, bookingTimezone)
          .isSameOrAfter(moment.tz(newBookingData.selectedCustomer.scheduleArchiveDate, bookingTimezone)))
    ) {
      isFormValid = false;
    }

    if (isFormValid) {
      const fieldsValue = form.getFieldsValue();

      const startDateTime = moment
        .tz(CommonUtils.formatCeilingDateTime(fieldsValue.bookStartDate), bookingTimezone)
        .format('X');
      const fullStartDate = moment.tz(fieldsValue.bookStartDate, bookingTimezone).toDate();
      const fullEndDate = moment.tz(fieldsValue.bookEndDate, bookingTimezone).toDate();

      const availableTimes = bookSelectedService.timeAvailabilities;

      if (!_.isEmpty(availableTimes)) {
        const isGroupService = bookSelectedService.serviceType === ServiceType.GROUP;

        let serviceDateTimeId = isGroupService ? newBookingData.serviceDateTimeId : null;
        const isFutureBooking = isGroupService
          ? moment.tz(bookingTimezone).add(-1, 'hour') <= moment.tz(newBookingData.bookStartDate, bookingTimezone)
          : moment.tz(bookingTimezone).add(-1, 'hour') <= moment.tz(fieldsValue.bookStartDate, bookingTimezone);

        if (!serviceDateTimeId) {
          const serviceDateTimeObject = _.find(availableTimes, (time) => {
            let itemStartStr = moment.tz(time.startDateTime, bookingTimezone).format('X');
            let itemEndStr = moment.tz(time.endDateTime, bookingTimezone).format('X');
            return itemStartStr <= startDateTime && itemEndStr >= startDateTime;
          });

          if (!_.isEmpty(serviceDateTimeObject)) {
            serviceDateTimeId = serviceDateTimeObject.serviceDateTimeId;
          } else {
            notification.error({
              message: 'Selected time for this service is not available.',
            });

            // no point in continuing; return.
            return;
          }
        }

        const roundedBookStartDate = moment.tz(
          CommonUtils.formatCeilingDateTime(fieldsValue.bookStartDate),
          bookingTimezone,
        );
        const roundedBookStartTime = moment.tz(
          CommonUtils.formatCeilingDateTime(fieldsValue.bookStartTime),
          bookingTimezone,
        );
        const roundedBookEndDate = moment.tz(
          CommonUtils.formatCeilingDateTime(fieldsValue.bookEndDate),
          bookingTimezone,
        );
        const roundedBookEndTime = moment.tz(
          CommonUtils.formatCeilingDateTime(fieldsValue.bookEndTime),
          bookingTimezone,
        );

        if (!isGroupService) {
          if (fieldsValue.recurringType === 'RECURRING') {
            await doSetNewBookingData({
              ...newBookingData,
              isRecurring: true,
              bookStartDate: roundedBookStartDate,
              bookEndDate: roundedBookEndDate,
              bookStartTime: roundedBookStartTime,
              bookEndTime: roundedBookEndTime,
              recurringPattern: {
                startDateTime: roundedBookStartTime,
                endDateTime: roundedBookEndTime,
                recurringPattern: fieldsValue.recurringPattern,
                recurringTo: fieldsValue.recurringTo.toDate(),
              },
              isFutureBooking,
              selectedWorkerId: null,
              selectedWorker: null,
              sleepoverType,
              sleepoverTimeSlots,
            });
          } else {
            await doSetNewBookingData({
              ...newBookingData,
              bookStartDate: roundedBookStartDate,
              bookEndDate: roundedBookEndDate,
              bookStartTime: roundedBookStartTime,
              bookEndTime: roundedBookEndTime,
              isRecurring: false,
              isFutureBooking,
              timeSlots: [
                {
                  serviceDateTimeId: serviceDateTimeId,
                  startDateTime: fullStartDate,
                  endDateTime: fullEndDate,
                },
              ],
              selectedWorkerId: null,
              selectedWorker: null,
              sleepoverType,
              sleepoverTimeSlots,
            });
          }
        } else {
          await doSetNewBookingData({
            ...newBookingData,
            isRecurring: false,
            isFutureBooking,
            timeSlots: [
              {
                serviceDateTimeId: serviceDateTimeId,
                startDateTime: fullStartDate,
                endDateTime: fullEndDate,
              },
            ],
            selectedWorkerId: null,
            selectedWorker: null,
          });
        }
      } else {
        notification.error({ message: 'Selected time is not available.' });
      }

      this.props.onNextStep();
    }
  };

  private _generateSummary = () => {
    const { bookSelectedService, form } = this.props;
    const { bookingTimezone } = this.state;

    if (form.getFieldValue('recurringType') !== 'RECURRING') return;

    const fieldsValue = form.getFieldsValue();
    const recurringPattern = fieldsValue.recurringPattern;
    let dayText = '';
    let nbrOfRecurrence = 0;
    const dataArray =
      bookSelectedService && bookSelectedService.serviceConfig ? bookSelectedService.serviceConfig.dataArray : null;

    const list = extMoment.range(
      moment.tz(fieldsValue.bookStartDate, bookingTimezone).startOf('day'),
      moment.tz(fieldsValue.recurringTo, bookingTimezone).endOf('day'),
    );

    if (recurringPattern === RecurringBookingPattern.EveryDay) {
      dayText = 'day';
      const matchedDays = _.filter(
        _.map(Array.from(list.by('day')), (day) => {
          return _.find(
            dataArray,
            (date) => moment.tz(date.startDateTime, bookingTimezone).day() === moment.tz(day, bookingTimezone).day(),
          );
        }),
        (filtered: any) => {
          return filtered.selected;
        },
      );
      nbrOfRecurrence = matchedDays.length;
    } else if (recurringPattern === RecurringBookingPattern.EveryWeek) {
      dayText = moment.tz(fieldsValue.bookStartDate, bookingTimezone).format('dddd');
      nbrOfRecurrence = Array.from(list.by('week')).length;
    } else if (recurringPattern === RecurringBookingPattern.EveryFortnight) {
      dayText = 'second ' + moment.tz(fieldsValue.bookStartDate, bookingTimezone).format('dddd');
      nbrOfRecurrence = _.ceil(Array.from(list.by('week')).length / 2);
    } else if (recurringPattern === RecurringBookingPattern.EveryFourWeeks) {
      dayText = 'forth ' + moment.tz(fieldsValue.bookStartDate, bookingTimezone).format('dddd');
      nbrOfRecurrence = _.ceil(Array.from(list.by('week')).length / 4);
    }
    this.setState({
      summary: (
        <div
          className="pv-medium ph-large rounded bordered border-standard-gray bg-quaternary"
          style={{ width: '91.5%' }}
        >
          <SubTitle>Summary</SubTitle>
          <Paragraph className="mt-medium" color="secondary">
            This booking will happen <b>every {dayText}</b>, starting on the{' '}
            <b>{moment.tz(fieldsValue.bookStartDate, bookingTimezone).format('DD/MM/YYYY')}</b> and finishing on the{' '}
            <b>{moment.tz(fieldsValue.recurringTo, bookingTimezone).format('DD/MM/YYYY')}</b> for a total of{' '}
            <b>{nbrOfRecurrence}</b> booking
            {nbrOfRecurrence !== 1 && 's'}
          </Paragraph>
        </div>
      ),
    });
  };

  private _setRecurrence = (e) => {
    const { newBookingData, doSetNewBookingData } = this.props;

    this.setState({ errorStartTime: null });
    doSetNewBookingData({
      ...newBookingData,
      isRecurring: e.target.value === 'RECURRING',
    });
  };

  // Validate entire booking time from start time to endtime
  private _validateBookingTime = async () => {
    try {
      const { form, doCheckServiceAvailability, newBookingData } = this.props;
      const { bookingTimezone } = this.state;
      const fieldsValue = form.getFieldsValue();
      const fullStartDate = moment(fieldsValue.bookStartDate).toISOString();
      const fullEndDate = moment(fieldsValue.bookEndDate).toISOString();
      const recurrenceTo = moment(fieldsValue.recurringTo).toISOString();
      this.setState({ errorStartTime: null, errorEndTime: null, errorRangeEndTime: null, isLoading: true });
      const result: any = await doCheckServiceAvailability({
        serviceId: newBookingData.selectedServiceId,
        startDateTime: fullStartDate,
        endDateTime: fullEndDate,
        timezone: bookingTimezone,
        isRecurring: fieldsValue.recurringType === 'RECURRING',
        recurringPattern: fieldsValue.recurringPattern,
        recurrenceTo,
      });

      if (result.isBookingTimeError) {
        const { errorStartTime, errorEndTime, recurringPatternError, recurrenceToError, errorRangeEndTime } =
          result.errors;

        if (errorStartTime) this.setState({ errorStartTime });

        if (errorEndTime) this.setState({ errorEndTime });

        if (recurringPatternError) this.setState({ recurringPatternError });

        if (recurrenceToError) this.setState({ recurrenceToError });

        if (!errorStartTime && !errorEndTime && errorRangeEndTime) this.setState({ errorRangeEndTime });
      }

      this.setState({ isLoading: false });
      const isFormValid = !result.isBookingTimeError;
      return isFormValid;
    } catch (error) {
      throw Error(error);
    }
  };

  private _onCloseModal = () => {
    this.setState({ isOpen: false });
  };

  private _onOpenSleepoverModal = () => {
    this.setState({ isSleepoverModalOpen: true });
  };

  private _onCloseSleepoverModal = () => {
    this.setState({ isSleepoverModalOpen: false });
  };

  private _onSaveSleepoverModal = async ({ sleepoverType, sleepoverTimeSlots }) => {
    const isGroupService = this.props.bookSelectedService.serviceType === ServiceType.GROUP;

    if (!isGroupService) {
      this.setState({
        sleepoverType,
        sleepoverTimeSlots,
      });
    }

    this._validateSleepoverTimeSlot(sleepoverType, sleepoverTimeSlots);
  };

  private _renderSleepoverContent = () => {
    const { bookingTimezone, sleepoverTimeSlots, sleepoverType } = this.state;
    const lengthTimeSlots = sleepoverTimeSlots.length;

    if (sleepoverType === SleepoverType.PARTIAL) {
      return (
        <div>
          <b>Partial sleepover</b> – this booking involves a sleepover, for the following time periods:
          <div className="mt-12">
            {sleepoverTimeSlots.map((sleepoverTimeSlot, index) => {
              return (
                <div key={index} className="mt-12">
                  {`${moment.tz(sleepoverTimeSlot.startDateTime, bookingTimezone).format('h:mm a, DD MMMM YYYY')} -
                ${moment.tz(sleepoverTimeSlot.endDateTime, bookingTimezone).format('h:mm a, DD MMMM YYYY')}${
                    index < lengthTimeSlots - 1 ? ',' : ''
                  }`}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (sleepoverType === SleepoverType.ENTIRE) {
      return (
        <div>
          <b>Full sleepover</b> – this booking is a sleepover for the entire duration.
        </div>
      );
    }

    return (
      <div>
        <b>No</b> sleepover
      </div>
    );
  };

  private _checkMultiDay = async () => {
    const { form } = this.props;
    const { bookingTimezone, sleepoverType, sleepoverTimeSlots } = this.state;
    const fieldsValue = form.getFieldsValue();

    if (
      moment
        .tz(fieldsValue.bookEndDate, bookingTimezone)
        .isAfter(moment.tz(fieldsValue.bookStartDate, bookingTimezone), 'dates')
    ) {
      this.setState({
        sleepoverType: sleepoverType || SleepoverType.NONE,
        sleepoverTimeSlots: sleepoverTimeSlots || [],
      });
    } else {
      this.setState({
        sleepoverType: null,
        sleepoverTimeSlots: [],
      });
    }
  };

  private _validateSleepoverTimeSlot = (sleepoverType, sleepoverTimeSlots) => {
    const { form } = this.props;
    const { bookingTimezone } = this.state;
    const fieldsValue = form.getFieldsValue();
    let isError = false;
    if (sleepoverType === SleepoverType.PARTIAL) {
      sleepoverTimeSlots.forEach((sleepoverTimeSlot) => {
        if (
          moment
            .tz(fieldsValue.bookStartDate, bookingTimezone)
            .isAfter(moment.tz(sleepoverTimeSlot.startDateTime, bookingTimezone)) ||
          moment
            .tz(fieldsValue.bookEndDate, bookingTimezone)
            .isBefore(moment.tz(sleepoverTimeSlot.endDateTime, bookingTimezone))
        ) {
          isError = true;
          sleepoverTimeSlot.errorType = SleepoverTimeSlotErrorType.OUT_OF_BOOKING_TIME;
        } else {
          sleepoverTimeSlot.errorType = null;
        }
      });
    }
    if (isError) this.setState({ isSleepoverError: true });
    else this.setState({ isSleepoverError: false });
  };

  componentDidMount() {
    const { form, newBookingData } = this.props;
    form.validateFields();
    if (newBookingData.sleepoverType) {
      const localSleepoverTimeslot = _.cloneDeep(newBookingData.sleepoverTimeSlots);
      this.setState({
        sleepoverType: newBookingData.sleepoverType,
        sleepoverTimeSlots: localSleepoverTimeslot,
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Step2ServiceDetailsPanelProps>,
    prevState: Readonly<Step2ServiceDetailsPanelState>,
    snapshot?: any,
  ) {
    if (prevProps.form !== this.props.form) {
      this._generateSummary();
    }
  }

  render() {
    const { form, onPreviousStep, servicesLite, newBookingData, bookSelectedService } = this.props;
    const { bookingTimezone, isSleepoverModalOpen, isSleepoverError, sleepoverTimeSlots, sleepoverType } = this.state;
    const { bookStartDate, bookEndDate, selectedServiceId, serviceDateTimeId, selectedCustomer } = newBookingData;
    const { getFieldDecorator } = form;

    const attachmentUrl = _.get(selectedCustomer, 'attachmentUrl');
    const displayName = _.get(selectedCustomer, 'firstName') + ' ' + _.get(selectedCustomer, 'lastName');

    const weeklyAvailabilities =
      bookSelectedService && bookSelectedService.serviceType === ServiceType.GROUP
        ? _.filter(
            bookSelectedService.timeAvailabilities,
            (time) =>
              moment.tz(time.startDateTime, bookingTimezone).week() ===
              moment.tz(this.state.selectedWeek, bookingTimezone).week(),
          )
        : null;

    const hasSelectedASession =
      bookSelectedService && bookSelectedService.serviceType === ServiceType.GROUP
        ? _.some(weeklyAvailabilities, (time) => time.serviceDateTimeId === serviceDateTimeId)
        : true;

    let serviceUnavailableDays = [];
    if (bookSelectedService && bookSelectedService.serviceConfig && bookSelectedService.serviceConfig.dataArray) {
      serviceUnavailableDays = _.map(
        _.filter(bookSelectedService.serviceConfig.dataArray, (date) => !date.selected),
        (filteredDate) => {
          return moment.tz(filteredDate.startDateTime, bookingTimezone).day() + 1;
        },
      );
    }

    const errorSummary = (
      <div
        className="pv-medium ph-large rounded bordered border-standard-gray bg-orange-lightest"
        style={{ width: '91.5%' }}
      >
        <SubTitle>Summary</SubTitle>
        <Paragraph className="mt-medium" color="secondary">
          <Icon type="warning" className="mr-x-small text-color-warning-orange" theme={'filled'} />A summary cannot be
          generated while there are errors. Please resolve any errors in order to continue.
        </Paragraph>
      </div>
    );

    const fieldsValue = form.getFieldsValue();

    const isMultipleDay = moment
      .tz(fieldsValue.bookEndDate ? fieldsValue.bookEndDate : newBookingData.bookEndDate, bookingTimezone)
      .isAfter(
        moment.tz(
          fieldsValue.bookStartDate ? fieldsValue.bookStartDate : newBookingData.bookStartDate,
          bookingTimezone,
        ),
        'dates',
      );

    return (
      <div className="anim-slide-left">
        <ActionModal
          title="Different timezone"
          isOpen={this.state.isOpen}
          onClose={this._onCloseModal}
          width="medium"
          verticalAlignment="highest"
        >
          <div className="text-align-left">
            <div className="mb-x-large">
              <Warning
                content={
                  <>
                    This service is configured with a <b>different timezone</b> than your preferred timezone. Please
                    note that <b>all dates and times</b> will be created according to the <b>service's timezone</b>.{' '}
                  </>
                }
              />
            </div>
            <Paragraph>
              Service's timezone:
              <br /> <TimezoneIndicator timezone={bookingTimezone} />
            </Paragraph>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              I understand
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        {/* Header Section */}
        <div className="mb-x-large">
          <Title level={2}>
            <span className="text-weight-regular">Select a booking</span> time{' '}
            <span className="text-weight-regular">and</span> service
          </Title>
          <Text>
            Choose the <b>service</b> and <b>when</b> you’d like it to occur.
          </Text>
        </div>

        {/* Actual Form */}
        <div className="mb-x3-large">
          <Row className="mb-medium">
            <Col span={4}>
              <SubTitle>CUSTOMER</SubTitle>
            </Col>
            <Col span={20}>
              <div className="flex-row align-center">
                <Avatar shape={'circle'} className="mr-small" icon="user" src={attachmentUrl} />
                <Text>{displayName}</Text>
              </div>
            </Col>
          </Row>

          <Row className="mb-medium">
            <Col span={4}>
              <SubTitle>Service Name</SubTitle>
            </Col>

            {/* Select Service */}
            <Col span={20}>
              <Select
                className="pr-medium"
                size="large"
                showSearch
                filterOption
                optionFilterProp="label"
                style={{ width: '500px' }}
                onChange={this._setSelectedService}
                value={selectedServiceId}
              >
                {!this.state.isLoading &&
                  _.map(servicesLite, (service) => (
                    <Option key={service.serviceId} value={service.serviceId} label={service.serviceName}>
                      {service.serviceName}
                    </Option>
                  ))}
              </Select>
              <br />
              <Text size="regular" color="secondary">
                Select a service for this booking.
              </Text>
            </Col>
          </Row>

          {this.state.isLoadingBookingTimes && (
            <>
              <SpinningLoader size={80} message={"Fetching service's data..."} />
            </>
          )}
          {selectedServiceId &&
            bookSelectedService &&
            [ServiceType.INDIVIDUAL, ServiceType.COORDINATION].includes(bookSelectedService.serviceType) && (
              <>
                <Row>
                  <Col span={4}>
                    <SubTitle>When</SubTitle>
                  </Col>
                  <Col span={20}>
                    {/* Start Date/Time */}
                    <Row>
                      <Col span={10}>
                        <Text color="secondary">Booking Start Date</Text>
                        <Input.Group style={{ width: '100%' }} compact>
                          {getFieldDecorator('bookStartDate', {
                            initialValue: newBookingData.bookStartDate
                              ? moment.tz(newBookingData.bookStartDate, bookingTimezone)
                              : moment.tz(CommonUtils.formatCeilingDateTime(moment()), bookingTimezone),
                          })(
                            <DatePicker
                              size="large"
                              placeholder="Start Date"
                              format="DD/MM/YYYY"
                              onChange={this._setStartDate}
                              allowClear={false}
                              disabledDate={(current) => {
                                return (
                                  current > moment.tz(bookSelectedService.availableTo, bookingTimezone) ||
                                  _.find(serviceUnavailableDays, (day) => day === current.day() + 1)
                                );
                              }}
                            />,
                          )}
                          {getFieldDecorator('bookStartTime', {
                            initialValue: newBookingData.bookStartDate
                              ? moment.tz(newBookingData.bookStartTime, bookingTimezone)
                              : moment.tz(CommonUtils.formatCeilingDateTime(moment()), bookingTimezone),
                            // rules: [{ validator: this._validateStartTime }]
                          })(<TimeInput size="large" onChange={this._setStartDate} timezone={bookingTimezone} />)}
                        </Input.Group>
                        <div className="flex-row flex align-center">
                          <Text size="regular" color="secondary">
                            Start Date/time
                          </Text>
                          <div style={{ marginLeft: '140px' }}>
                            <TimezoneIndicator
                              hasIcon={false}
                              bordered={false}
                              showTzName={false}
                              placement={'bottom'}
                              timezone={bookSelectedService.timezone}
                            />
                          </div>
                        </div>
                        {this.state.errorStartTime && (
                          <>
                            <Text color="red-dark">{this.state.errorStartTime}</Text>
                            <br />
                          </>
                        )}
                      </Col>
                      <Col span={2} className="pt-x-large">
                        <Text className="ml-medium">to</Text>
                      </Col>
                      <Col span={10}>
                        <Text color="secondary">Booking End Date</Text>
                        <Input.Group compact>
                          {getFieldDecorator('bookEndDate', {
                            initialValue: newBookingData.bookEndDate
                              ? moment.tz(newBookingData.bookEndDate, bookingTimezone)
                              : moment.tz(CommonUtils.formatCeilingDateTime(moment()), bookingTimezone).add(1, 'hour'),
                            rules: [],
                          })(
                            <DatePicker
                              size="large"
                              placeholder="End Date"
                              format="DD/MM/YYYY"
                              onChange={this._setEndDate}
                              disabledDate={(current) => {
                                return (
                                  current < moment.tz(bookStartDate, bookingTimezone).startOf('day') ||
                                  current > moment.tz(bookSelectedService.availableTo, bookingTimezone) ||
                                  _.find(serviceUnavailableDays, (day) => day === current.day() + 1)
                                );
                              }}
                              allowClear={false}
                            />,
                          )}
                          {getFieldDecorator('bookEndTime', {
                            initialValue: newBookingData.bookEndTime
                              ? moment.tz(newBookingData.bookEndTime, bookingTimezone)
                              : moment.tz(CommonUtils.formatCeilingDateTime(moment()), bookingTimezone).add(1, 'hour'),
                            rules: [],
                          })(<TimeInput size="large" onChange={this._setEndDate} timezone={bookingTimezone} />)}
                        </Input.Group>
                        <div className="flex-row flex align-center">
                          <Text size="regular" color="secondary">
                            End Date/time
                          </Text>
                          <div style={{ marginLeft: '140px' }}>
                            <TimezoneIndicator
                              hasIcon={false}
                              bordered={false}
                              showTzName={false}
                              placement={'bottom'}
                              timezone={bookSelectedService.timezone}
                            />
                          </div>
                        </div>
                        {this.state.errorRangeEndTime && (
                          <>
                            <Text color="red-dark">{this.state.errorRangeEndTime}</Text>
                            <br />
                          </>
                        )}
                        {this.state.errorEndTime && (
                          <>
                            <Text color="red-dark">{this.state.errorEndTime}</Text>
                            <br />
                          </>
                        )}
                      </Col>
                    </Row>
                    {fieldsValue.recurringType === 'RECURRING' && (
                      <Row>
                        <Col span={24}>
                          <Text size="regular" color="secondary">
                            The start/end Time set here will be used as the first date for the recurring booking.
                          </Text>
                        </Col>
                      </Row>
                    )}
                    {this.props.newBookingData.selectedCustomer.scheduleArchiveDate &&
                      moment
                        .tz(fieldsValue.bookEndDate, bookingTimezone)
                        .isSameOrAfter(
                          moment.tz(this.props.newBookingData.selectedCustomer.scheduleArchiveDate, bookingTimezone),
                        ) && (
                        <Warning
                          borderNone
                          color="red-dark"
                          content={
                            <Text color="red-dark">
                              The customer selected is scheduled to be archived on{' '}
                              {moment
                                .tz(this.props.newBookingData.selectedCustomer.scheduleArchiveDate, bookingTimezone)
                                .format('DD/MM/YYYY')}
                              . Bookings cannot be made past this date
                            </Text>
                          }
                        />
                      )}
                  </Col>
                </Row>

                {isMultipleDay && (
                  <Row className="mt-large">
                    <Col span={4}>
                      <Row type="flex" align="middle">
                        <SubTitle>Sleepover</SubTitle>
                        <Tooltip
                          placement="top"
                          title="Because this booking runs over multiple days, you can choose to include any sleepover periods that are part of the shift. Changes here will directly impact
                        billing and the rates at which hours are paid."
                          overlayStyle={{ maxWidth: '332px', fontSize: '14px' }}
                        >
                          <Icon type="question-circle" className="text-color-tertiary text-size-small ml-small" />
                        </Tooltip>
                      </Row>
                    </Col>
                    <Col span={20}>
                      <div>{this._renderSleepoverContent()}</div>
                      {isSleepoverError && (
                        <Warning
                          color="red"
                          borderNone
                          content={
                            <Text color="red">
                              This sleepover period is no longer valid as the booking time has changed.
                            </Text>
                          }
                        />
                      )}
                      <HyperlinkButton
                        className="width-fit-content block mt-small"
                        onClick={this._onOpenSleepoverModal}
                      >
                        Edit
                      </HyperlinkButton>
                    </Col>
                  </Row>
                )}

                <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 'calc(100vh - 500px)' }}>
                  <Row className="mt-large">
                    <Col span={4}>
                      <SubTitle>Recurrence</SubTitle>
                    </Col>
                    <Col span={20}>
                      <Form.Item className="m-none">
                        {getFieldDecorator('recurringType', {
                          initialValue: this.props.newBookingData.isRecurring ? 'RECURRING' : 'ONE_OFF',
                        })(
                          <Radio.Group onChange={this._setRecurrence}>
                            <Radio value="ONE_OFF">
                              This booking is a <b>one-off</b> booking.
                            </Radio>
                            <br />
                            <Radio value="RECURRING">
                              This booking is a series of <b>recurring</b> bookings.
                            </Radio>
                          </Radio.Group>,
                        )}
                      </Form.Item>
                      {this.state.showActiveAgreementWarning && (
                        <Warning
                          borderNone
                          content={
                            <Text color="warning-orange">
                              The customer does not have active service agreement for every bookings being created in
                              this pattern.
                            </Text>
                          }
                          className="mb-large"
                        />
                      )}
                    </Col>
                  </Row>

                  {this.props.newBookingData.isRecurring && (
                    <>
                      <Row>
                        <Col span={4} className="pt-small">
                          <SubTitle>Repeats</SubTitle>
                        </Col>
                        <Col span={6}>
                          <Form.Item className={'m-none'}>
                            {getFieldDecorator('recurringPattern', {
                              initialValue:
                                this.props.newBookingData.recurringPattern &&
                                this.props.newBookingData.recurringPattern.recurringPattern
                                  ? this.props.newBookingData.recurringPattern.recurringPattern
                                  : RecurringBookingPattern.EveryDay,
                              rules: [],
                            })(
                              <Select size={'large'} style={{ width: '220px' }}>
                                <Select.Option key={0} value={RecurringBookingPattern.EveryDay}>
                                  Every day
                                </Select.Option>
                                <Select.Option key={0} value={RecurringBookingPattern.EveryWeek}>
                                  Every week
                                </Select.Option>
                                <Select.Option key={0} value={RecurringBookingPattern.EveryFortnight}>
                                  Every fortnight
                                </Select.Option>
                                <Select.Option key={0} value={RecurringBookingPattern.EveryFourWeeks}>
                                  Every 4 weeks
                                </Select.Option>
                              </Select>,
                            )}
                          </Form.Item>
                          {this.state.recurringPatternError && (
                            <>
                              <Text color="red-dark">{this.state.recurringPatternError}</Text>
                              <br />
                            </>
                          )}
                        </Col>
                        <Col span={2} style={{ marginTop: '9px' }}>
                          <Text>until</Text>
                        </Col>
                        <Col span={6} className="flex-row">
                          <Form.Item className={'m-none'}>
                            {getFieldDecorator('recurringTo', {
                              initialValue:
                                this.props.newBookingData.recurringPattern &&
                                this.props.newBookingData.recurringPattern.recurringTo
                                  ? moment.tz(this.props.newBookingData.recurringPattern.recurringTo, bookingTimezone)
                                  : moment.tz(bookSelectedService.availableTo, bookingTimezone) >
                                    moment.tz(bookingTimezone).add(6, 'month')
                                  ? moment.tz(bookingTimezone).add(6, 'month')
                                  : moment.tz(bookSelectedService.availableTo, bookingTimezone),
                              rules: [],
                            })(
                              <DatePicker
                                size="large"
                                allowClear={false}
                                format="DD/MM/YYYY"
                                disabledDate={(current) => {
                                  if (form.getFieldValue('recurringPattern') === RecurringBookingPattern.EveryDay) {
                                    return (
                                      current > moment.tz(fieldsValue.bookStartTime, bookingTimezone).add(1, 'year')
                                    );
                                  } else {
                                    return (
                                      current > moment.tz(fieldsValue.bookStartTime, bookingTimezone).add(3, 'year')
                                    );
                                  }
                                }}
                              />,
                            )}
                          </Form.Item>
                        </Col>
                        {this.state.recurrenceToError && (
                          <>
                            <Text color="red-dark">{this.state.recurrenceToError}</Text>
                            <br />
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col span={4}></Col>
                        <Col span={20}>
                          {this.props.newBookingData.selectedCustomer.scheduleArchiveDate &&
                            moment
                              .tz(fieldsValue.recurringTo, bookingTimezone)
                              .isSameOrAfter(
                                moment.tz(
                                  this.props.newBookingData.selectedCustomer.scheduleArchiveDate,
                                  bookingTimezone,
                                ),
                              ) && (
                              <Warning
                                borderNone
                                color="red-dark"
                                content={
                                  <Text color="red-dark">
                                    The customer selected is scheduled to be archived on{' '}
                                    {moment
                                      .tz(
                                        this.props.newBookingData.selectedCustomer.scheduleArchiveDate,
                                        bookingTimezone,
                                      )
                                      .format('DD/MM/YYYY')}
                                    . Bookings cannot be made past this date
                                  </Text>
                                }
                              />
                            )}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4} />
                        <Col span={20}>
                          {_.some(form.getFieldsError(), (error) => error !== undefined) ? (
                            errorSummary
                          ) : !_.isEmpty(this.state.summary) ? (
                            <Text>{this.state.summary}</Text>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </>
            )}
          {selectedServiceId && bookSelectedService && bookSelectedService.serviceType === ServiceType.GROUP && (
            <>
              <Row>
                <Col span={4}>
                  <SubTitle>Pick a Session</SubTitle>
                </Col>
                <Col span={20}>
                  <WeekPicker
                    size="large"
                    format="DD/MM/YYYY"
                    allowClear={false}
                    value={moment.tz(this.state.selectedWeek, bookingTimezone)}
                    onChange={(value) => this.setState({ selectedWeek: moment.tz(value, bookingTimezone) })}
                  />
                  <br />
                  <Text size="regular" color="secondary">
                    Select a week to get the session.
                  </Text>
                </Col>
              </Row>
              <Row className="mt-medium">
                <Col span={4} />
                <Col span={20}>
                  <div>
                    {weeklyAvailabilities.length === 0 ? (
                      <Text color={'orange'}>
                        <Icon type="warning" theme={'filled'} className="text-color-warning-orange mr-small" />
                        No times available. Please add availabilities to the service.
                      </Text>
                    ) : (
                      <Radio.Group
                        className="width-full"
                        defaultValue={serviceDateTimeId}
                        onChange={this._setSelectedServiceDateTimeId}
                        style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '30vh' }}
                      >
                        {_.map(weeklyAvailabilities, (time) => {
                          const selected = serviceDateTimeId === time.serviceDateTimeId;
                          return (
                            <>
                              <Radio
                                className={`bordered rounded pv-small ph-medium mb-small width-2/3 ${
                                  selected && 'bg-tertiary'
                                }`}
                                value={time.serviceDateTimeId}
                                checked={selected}
                              >
                                <>
                                  <b>{moment.tz(time.startDateTime, bookingTimezone).format('dddd, DD/MM')}</b> - From{' '}
                                  {moment.tz(time.startDateTime, bookingTimezone).format('hh:mm A')} to{' '}
                                  {!moment
                                    .tz(time.startDateTime, bookingTimezone)
                                    .startOf('day')
                                    .isSame(moment.tz(time.endDateTime, bookingTimezone).startOf('day')) && (
                                    <Text weight="bold">
                                      {moment.tz(time.startDateTime, bookingTimezone).format('dddd, DD/MM')}{' '}
                                    </Text>
                                  )}
                                  {moment.tz(time.endDateTime, bookingTimezone).format('hh:mm A')}
                                  {moment.tz(bookingTimezone).add(-1, 'hour') >
                                    moment.tz(time.startDateTime, bookingTimezone) && (
                                    <Text color={'secondary'}> (Past Booking)</Text>
                                  )}
                                </>
                              </Radio>
                              <br />
                            </>
                          );
                        })}
                      </Radio.Group>
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>

        {/* Nav buttons */}
        <div className="flex-row justify-between">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left">
            Previous
          </SecondaryButton>

          <PrimaryButton
            size="large"
            onClick={this._onNextStep}
            icon="right"
            iconPosition="right"
            disabled={
              _.isEmpty(selectedServiceId) ||
              _.isEmpty(bookSelectedService) ||
              bookStartDate === '' ||
              bookEndDate === '' ||
              !hasSelectedASession ||
              this.state.isLoading ||
              isSleepoverError
            }
          >
            Next
          </PrimaryButton>
        </div>

        <SleepoverShiftBookingModal
          isOpen={isSleepoverModalOpen}
          onClose={this._onCloseSleepoverModal}
          onSave={this._onSaveSleepoverModal}
          recurringType={fieldsValue.recurringType}
          bookingTimezone={bookingTimezone}
          bookStartDate={fieldsValue.bookStartDate}
          bookEndDate={fieldsValue.bookEndDate}
          sleepoverTimeSlots={sleepoverTimeSlots}
          sleepoverType={sleepoverType}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  servicesLite: state.servicesStore.servicesLite,
  bookSelectedService: state.servicesStore.bookSelectedService,
  newBookingData: state.bookingsStore.newBookingData,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServicesLite: dispatch.servicesStore.doFetchServicesLite,
  doFetchSingleService: dispatch.servicesStore.doFetchSingleService,
  doSetNewBookingData: dispatch.bookingsStore.doSetNewBookingData,
  setSelectedCreateService: dispatch.servicesStore.setSelectedCreateService,
  setBookSelectedService: dispatch.servicesStore.setBookSelectedService,
  doCheckServiceAgreementDate: dispatch.bookingsStore.doCheckServiceAgreementDate,
  doCheckServiceAvailability: dispatch.bookingsStore.doCheckServiceAvailability,
});

export default connect(mapState, mapDispatch)(Form.create<Step2ServiceDetailsPanelProps>()(Step2ServiceDetailsPanel));
