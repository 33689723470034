import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CallDecline = forwardRef(function CallDecline(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.776 6.913A14.176 14.176 0 0 1 12 6.25c1.361 0 2.786.214 4.224.663 1.931.604 3.884 1.632 5.737 3.139l.015.012c.147.12.265.215.407.372.376.416.589.954.66 1.48.07.526.007 1.101-.245 1.602-.096.19-.209.348-.35.546l-.023.03-.032.046c-.176.246-.311.437-.46.589a2.75 2.75 0 0 1-3.052.606c-.195-.084-.393-.208-.65-.368l-.047-.03-.702-.439-.078-.049c-.452-.282-.808-.503-1.078-.814a2.749 2.749 0 0 1-.471-.764c-.156-.382-.195-.799-.244-1.329l-.009-.092-.302-3.245A12.53 12.53 0 0 0 12 7.75c-1.056 0-2.167.146-3.3.455l-.302 3.245-.009.092c-.049.53-.087.947-.243 1.329a2.75 2.75 0 0 1-.472.764c-.27.31-.626.532-1.077.814l-.08.05-.7.438-.048.03a5.05 5.05 0 0 1-.65.368 2.75 2.75 0 0 1-3.052-.606c-.148-.152-.284-.342-.46-.589l-.032-.045-.023-.031c-.141-.199-.254-.357-.35-.546-.252-.5-.315-1.076-.245-1.602.071-.526.285-1.064.66-1.48.142-.157.26-.253.407-.372l.015-.012c1.853-1.507 3.806-2.535 5.737-3.139Zm-.632 1.818c-1.394.562-2.8 1.38-4.159 2.485-.16.13-.195.16-.255.226a1.275 1.275 0 0 0-.286.674c-.038.282.005.542.098.727.037.075.08.138.253.38.224.313.288.4.345.458.361.37.913.479 1.387.275.075-.032.168-.087.494-.291l.702-.439c.568-.355.715-.456.82-.576.09-.104.162-.221.214-.348.06-.148.085-.325.147-.992l.24-2.58Zm9.712 0 .24 2.58c.062.666.086.843.147.991.052.127.124.244.214.348.105.12.253.221.82.576l.702.439c.326.204.419.259.494.291a1.25 1.25 0 0 0 1.387-.275c.057-.059.121-.145.345-.458.173-.242.216-.305.254-.38.092-.184.135-.445.097-.727a1.274 1.274 0 0 0-.286-.674 1.943 1.943 0 0 0-.255-.226c-1.36-1.105-2.765-1.923-4.16-2.485Z"
        fill="currentColor"
      />
    </Icon>
  );
});
