import React, { Component } from 'react';
import { Form, Input, notification } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import { IPinnedAlert } from 'interfaces/customer-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';

const { TextArea } = Input;

interface IAddEditPinnedAlertModalProps extends FormComponentProps {
  closeAddEditPinnedAlertModal: () => void;
  isOpen: boolean;
  customerUserId: string;
  selectedPinnedAlert?: IPinnedAlert;
  isEdit?: boolean;
  doAddCustomerPinnedAlerts?: typeof dispatch.customersStore.doAddCustomerPinnedAlerts;
  doUpdateCustomerPinnedAlerts?: typeof dispatch.customersStore.doUpdateCustomerPinnedAlerts;
}

interface IAddEditPinnedAlertModalState {
  isLoading: boolean;
  isValidationModalOpen: boolean;
}

class AddEditPinnedAlertModal extends Component<IAddEditPinnedAlertModalProps, IAddEditPinnedAlertModalState> {
  state = {
    isLoading: false,
    isValidationModalOpen: false,
  };

  private _closeModal = () => {
    this.setState({
      isLoading: false,
      isValidationModalOpen: false,
    });
    this.props.closeAddEditPinnedAlertModal();
  };

  private _checkChangesBeforeClose = () => {
    const { isEdit, form, selectedPinnedAlert } = this.props;
    this.setState({ isLoading: true });
    if (isEdit) {
      if (
        form.getFieldValue('description') !== selectedPinnedAlert.description ||
        form.getFieldValue('title') !== selectedPinnedAlert.title
      ) {
        this.setState({ isValidationModalOpen: true });
      } else {
        this._closeModal();
      }
    } else {
      const description = form.getFieldValue('description');
      const title = form.getFieldValue('title');
      if (title && title !== '' && description && description !== '') {
        this.setState({ isValidationModalOpen: true });
      } else {
        this._closeModal();
      }
    }
    this.setState({ isLoading: false });
  };

  private _closeValidationModal = () => {
    this.setState({ isValidationModalOpen: false });
  };

  private _savePinnedAlert = async () => {
    const {
      customerUserId,
      form,
      isEdit,
      selectedPinnedAlert,
      doUpdateCustomerPinnedAlerts,
      doAddCustomerPinnedAlerts,
    } = this.props;

    this.setState({ isLoading: true });

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      const formValues = form.getFieldsValue();
      try {
        const payload = isEdit
          ? {
              customerUserId,
              pinnedAlertId: selectedPinnedAlert.pinnedAlertId,
              description: formValues.description,
              title: formValues.title,
            }
          : {
              customerUserId,
              supportWorkerId: formValues.supportWorkerId,
              description: formValues.description,
              title: formValues.title,
            };
        isEdit ? await doUpdateCustomerPinnedAlerts(payload) : await doAddCustomerPinnedAlerts(payload);
        notification.success({ message: `Pinned alert ${isEdit ? 'updated' : 'added'} successfully.` });
        this._closeModal();
      } catch (e) {
        notification.error({ message: 'Oops something went wrong! Please try again.' });
      }
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { form, selectedPinnedAlert, isEdit } = this.props;
    const { isLoading } = this.state;
    const { getFieldDecorator } = form;

    return (
      <div>
        <ActionModal
          isOpen={this.state.isValidationModalOpen}
          onClose={this._closeValidationModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeValidationModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._closeModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._checkChangesBeforeClose}
          title={`${isEdit ? 'Edit' : 'Add'} pinned alert.`}
          showCloseButton={true}
          canCloseOutside={false}
          verticalAlignment={'center'}
          width={'large'}
        >
          <div className="mb-large">
            <div className={'mb-medium'}>
              <Text>
                {!isEdit
                  ? `Add a title and a description of the pinned alert you wish to add.`
                  : `Edit the title and a description of the pinned alert.`}
              </Text>
            </div>
            <SubTitle>Alert title</SubTitle>
            <Form.Item className="mb-large">
              {getFieldDecorator('title', {
                initialValue: selectedPinnedAlert && selectedPinnedAlert.title,
                rules: [{ required: true, message: 'Please enter a title.' }],
              })(<Input size={'large'} style={{ width: '400px' }} maxLength={25} placeholder="Enter title here..." />)}
            </Form.Item>
          </div>
          <div className="mb-x-large">
            <SubTitle>Description</SubTitle>
            <Form.Item className="m-none">
              {getFieldDecorator('description', {
                rules: [{ required: true, message: 'Please enter a description.' }],
                initialValue: selectedPinnedAlert ? selectedPinnedAlert.description : null,
              })(<TextArea placeholder={'Enter description here...'} autoSize={{ minRows: 4, maxRows: 20 }} />)}
            </Form.Item>
          </div>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._checkChangesBeforeClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._savePinnedAlert} loading={isLoading}>
              {isEdit ? 'Edit' : 'Add'} alert
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  // workerListLite: state.teamStore.workerListLite
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddCustomerPinnedAlerts: dispatch.customersStore.doAddCustomerPinnedAlerts,
  doUpdateCustomerPinnedAlerts: dispatch.customersStore.doUpdateCustomerPinnedAlerts,
});

export default connect(mapState, mapDispatch)(Form.create<IAddEditPinnedAlertModalProps>()(AddEditPinnedAlertModal));
