import React, { Component } from 'react';
import CustomerListingPanel from './listing/CustomerListingPanel';
import * as H from 'history';
import AddNewCustomerWizardModal from 'views/customers/listing/components/AddNewCustomerWizardModal';
import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import { PageHeader } from '@good/ui/templates';
import { Button } from '@good/ui/core';
import { PlusSquare } from '@good/icons';

interface ICustomerLandingViewProps {
  history: H.History;
  setRefreshList: typeof dispatch.customersStore.setRefreshList;
}

interface ICustomerLandingViewState {
  isLoading: boolean;
  openAddNewCustomerWizardModal: boolean;
}

class CustomerLandingView extends Component<ICustomerLandingViewProps, ICustomerLandingViewState> {
  state = {
    isLoading: false,
    openAddNewCustomerWizardModal: false,
  };

  private _openAddNewCustomerWizardModal = () => this.setState({ openAddNewCustomerWizardModal: true });

  private _closeAddNewCustomerWizardModal = (serviceData = null) => {
    this.setState({ openAddNewCustomerWizardModal: false });
    this.props.setRefreshList(true);
  };

  render() {
    const { history } = this.props;

    return (
      <>
        <PageHeader
          title='Customers'
          subtitle='View, manage or add new customers.'
          actions={
            <Button leftSection={<PlusSquare />} onClick={this._openAddNewCustomerWizardModal} size='md'>
              Add customer
            </Button>
          }
        />

        <div className='mb-x-large' style={{ position: 'sticky', top: '0px', zIndex: 10 }}>
          <CustomerListingPanel history={history} />
        </div>

        <AddNewCustomerWizardModal
          isOpen={this.state.openAddNewCustomerWizardModal}
          history={history}
          closeAddCustomerModal={this._closeAddNewCustomerWizardModal}
        />
      </>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setRefreshList: dispatch.customersStore.setRefreshList,
});

export default connect(mapState, mapDispatch)(CustomerLandingView);
