import { Icon } from 'antd';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { Component } from 'react';
import { timeZone } from 'src/interfaces/timezone-type';
import './double-time-input.css';

const numberReg = /^-?[0-9]*(\.[0-9]*)?$/;
// const ampmReg = /^-?[apAP]*$/;

const KEYACTIONS = {
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  A: 65,
  P: 80,
};

const checkIsNumberInput = (input) => !isNaN(input) && numberReg.test(input);
// const checkIsAMPMInput = (input) => ampmReg.test(input);

interface DoubleTimeInputProps {
  size?: 'default' | 'large' | string;
  startTimeValue?: Moment;
  endTimeValue?: Moment;
  onChange?: (startDate: Date, endDate: Date) => void;
  defaultValue?: Moment;
  className?: any;
  containerStyle?: any;
  disabled?: boolean;
  startTimeInputDisabled?: boolean;
  endTimeInputDisabled?: boolean;
  timezone?: timeZone;
  displayIcon?: boolean;
}

interface DoubleTimeInputState {
  focus: string;
  startTimeInputBuffer: string;
  endTimeInputBuffer: string;
  startDate: Moment;
  endDate: Moment;
}

class DoubleTimeInput extends Component<DoubleTimeInputProps, DoubleTimeInputState> {
  _startTimeHourInput: HTMLInputElement | null = null;
  _startTimeMinuteInput: HTMLInputElement | null = null;
  _startTimeAmpmInput: HTMLInputElement | null = null;
  _endTimeHourInput: HTMLInputElement | null = null;
  _endTimeMinuteInput: HTMLInputElement | null = null;
  _endTimeAmpmInput: HTMLInputElement | null = null;

  state = {
    focus: '',
    startTimeInputBuffer: '',
    endTimeInputBuffer: '',
    startDate: this.props.timezone ? moment.tz(this.props.timezone) : moment(),
    endDate: this.props.timezone ? moment.tz(this.props.timezone) : moment(),
  };

  // Key handlers
  _onKeyHour = (e, isEndTimeValue) => {
    const { startTimeInputBuffer, endTimeInputBuffer, startDate } = this.state;

    let hours;
    isEndTimeValue ? (hours = parseInt(endTimeInputBuffer)) : (hours = parseInt(startTimeInputBuffer));

    if (isNaN(hours)) {
      hours = 0;
    }

    if (e.keyCode === KEYACTIONS.UP) {
      hours++;
      if (hours > 12) {
        hours = hours % 12;
        isEndTimeValue
          ? this.setState({
              endTimeInputBuffer: hours,
              endDate: startDate.add(startDate.format('a') === 'am' ? 12 : -12, 'hour'),
            })
          : this.setState({
              startTimeInputBuffer: hours,
              startDate: startDate.add(startDate.format('a') === 'am' ? 12 : -12, 'hour'),
            });
      } else {
        isEndTimeValue ? this.setState({ endTimeInputBuffer: hours }) : this.setState({ startTimeInputBuffer: hours });
      }
    }
    if (e.keyCode === KEYACTIONS.DOWN) {
      hours--;
      if (hours < 1) {
        hours = 12;
      }
      isEndTimeValue ? this.setState({ endTimeInputBuffer: hours }) : this.setState({ startTimeInputBuffer: hours });
    }
    if (e.keyCode === KEYACTIONS.RIGHT) {
      // const { inputBuffer } = this.state;
      // const { selectionStart } = this._hourInput;
      // disabled for now.
      // If it's at the end of the string, move to next column
      // if (inputBuffer.length === selectionStart) {
      //   this._minuteInput.focus();
      //   setTimeout(this._minuteInput.setSelectionRange(-1, 0), 0);
      // }
    }
  };

  _onKeyMinutes = async (e, isEndTimeValue) => {
    const { startTimeInputBuffer, endTimeInputBuffer } = this.state;
    let minutes;
    isEndTimeValue ? (minutes = parseInt(endTimeInputBuffer)) : (minutes = parseInt(startTimeInputBuffer));

    if (isNaN(minutes)) {
      minutes = 0;
    }

    if (e.keyCode === KEYACTIONS.DOWN) {
      minutes--;
      if (minutes < 0) {
        minutes = 59;
      }
      if (isEndTimeValue) {
        this.setState({ endTimeInputBuffer: minutes.toString().padStart(2, '0') }, () =>
          this._endTimeMinuteInput?.focus(),
        );
      } else {
        this.setState({ startTimeInputBuffer: minutes.toString().padStart(2, '0') }, () =>
          this._startTimeMinuteInput?.focus(),
        );
      }
    }

    if (e.keyCode === KEYACTIONS.UP) {
      minutes++;
      if (minutes > 59) {
        minutes = 0;
      }
      if (isEndTimeValue) {
        this.setState({ endTimeInputBuffer: minutes.toString().padStart(2, '0') }, () =>
          this._endTimeMinuteInput?.focus(),
        );
      } else {
        this.setState({ startTimeInputBuffer: minutes.toString().padStart(2, '0') }, () =>
          this._startTimeMinuteInput?.focus(),
        );
      }
    }
  };

  _onKeyAMPM = (e, isEndTimeValue) => {
    if (e.keyCode === KEYACTIONS.A) {
      isEndTimeValue ? this._endTimeAmpmInput?.select() : this._startTimeAmpmInput?.select();
      this._setAMPM('am', isEndTimeValue);
    }
    if (e.keyCode === KEYACTIONS.P) {
      isEndTimeValue ? this._endTimeAmpmInput?.select() : this._startTimeAmpmInput?.select();
      this._setAMPM('pm', isEndTimeValue);
    }
  };

  _onChangeHour = (e, isChangeEndTimeValue) => {
    if (checkIsNumberInput(e.target.value)) {
      let targetHour = e.target.value;

      const targetHourArray = String(targetHour).split('');
      // If the user add a third number to the time by using the manual selector.
      if (targetHourArray.length === 3) {
        const bufferHourArray = isChangeEndTimeValue
          ? String(this.state.endTimeInputBuffer).split('')
          : String(this.state.startTimeInputBuffer).split('');
        // The newly added number is determined by doing the sum of the new number minus the sum of the old one and then set as the new time.
        targetHour =
          targetHourArray.reduce((a, b) => Number(a) + Number(b), 0) -
          bufferHourArray.reduce((a, b) => Number(a) + Number(b), 0);
      }

      if (targetHour > 23) {
        targetHour = 23;
      }

      isChangeEndTimeValue
        ? this.setState({ endTimeInputBuffer: targetHour }, () => {
            if (this.state.endTimeInputBuffer.length >= 2 || (targetHour > 2 && targetHour < 10)) {
              this._endTimeMinuteInput.focus();
            }
          })
        : this.setState({ startTimeInputBuffer: targetHour }, () => {
            if (this.state.startTimeInputBuffer.length >= 2 || (targetHour > 2 && targetHour < 10)) {
              this._startTimeMinuteInput.focus();
            }
          });
    }
  };

  _onChangeMinutes = (e, isChangeEndTimeValue) => {
    if (checkIsNumberInput(e.target.value)) {
      let targetMinute = e.target.value;

      if (targetMinute < 0) {
        targetMinute = 0;
      }

      const targetMinuteArray = String(targetMinute).split('');
      // If the user add a third number to the time by using the manual selector.
      if (targetMinuteArray.length === 3) {
        const bufferMinuteArray = isChangeEndTimeValue
          ? String(this.state.endTimeInputBuffer).split('')
          : String(this.state.startTimeInputBuffer).split('');
        // The newly added number is determined by doing the sum of the new number minus the sum of the old one and then set as the new time.
        targetMinute =
          targetMinuteArray.reduce((a, b) => Number(a) + Number(b), 0) -
          bufferMinuteArray.reduce((a, b) => Number(a) + Number(b), 0);
      }

      if (targetMinute > 59) {
        targetMinute = 59;
      }
      isChangeEndTimeValue
        ? this.setState({ endTimeInputBuffer: targetMinute }, () => {
            if (this.state.endTimeInputBuffer.length >= 2) {
              this._endTimeAmpmInput.focus();
            }
          })
        : this.setState({ startTimeInputBuffer: targetMinute }, () => {
            if (this.state.startTimeInputBuffer.length >= 2) {
              this._startTimeAmpmInput.focus();
            }
          });
    }
  };

  _onchangeAMPM = () => {
    //Do nothing, the onkey will handle this
    //This function to avoid error warning
  };

  _setAMPM = (targetAmPm, isEndTimeValue) => {
    const { startDate, endDate } = this.state;
    const currentAmPm = isEndTimeValue ? endDate.format('a') : startDate.format('a');

    if (currentAmPm === 'am' && targetAmPm === 'pm') {
      isEndTimeValue
        ? this.setState({ endDate: endDate.add(12, 'hour') }, this.callOnChange)
        : this.setState({ startDate: startDate.add(12, 'hour') }, this.callOnChange);
    }

    if (currentAmPm === 'pm' && targetAmPm === 'am') {
      isEndTimeValue
        ? this.setState({ endDate: endDate.add(-12, 'hour') }, this.callOnChange)
        : this.setState({ startDate: startDate.add(-12, 'hour') }, this.callOnChange);
    }
  };

  _onFocusStartTime = (target, e) => {
    const { startDate } = this.state;
    if (target === 'startHour') {
      e.target.select();
      this.setState({
        focus: target,
        startTimeInputBuffer: startDate.format('hh'),
      });
    }
    if (target === 'startMinutes') {
      e.target.select();
      this.setState({
        focus: target,
        startTimeInputBuffer: startDate.format('mm'),
      });
    }
    if (target === 'startPeriod') {
      e.target.select();
      this.setState({
        focus: target,
        startTimeInputBuffer: startDate.format('a'),
      });
    }
  };

  _onFocusEndTime = (target, e) => {
    const { endDate } = this.state;
    if (target === 'endHour') {
      e.target.select();
      this.setState({
        focus: target,
        endTimeInputBuffer: endDate.format('hh'),
      });
    }
    if (target === 'endMinutes') {
      e.target.select();
      this.setState({
        focus: target,
        endTimeInputBuffer: endDate.format('mm'),
      });
    }
    if (target === 'endPeriod') {
      e.target.select();
      this.setState({
        focus: target,
        endTimeInputBuffer: endDate.format('a'),
      });
    }
  };

  _onBlur = (target) => {
    const { startDate, startTimeInputBuffer, endTimeInputBuffer, endDate } = this.state;
    if (target === 'startHour') {
      let startTimeTargetHour = parseInt(startTimeInputBuffer);
      const startTimeAMIndicator = startDate.format('a');
      // Retain PM if it's PM.
      if (startTimeTargetHour > 0 && startTimeAMIndicator === 'pm') {
        if (startTimeTargetHour < 12) {
          startTimeTargetHour += 12;
        }
      }

      this.setState({ startDate: startDate.hour(startTimeTargetHour), focus: '' }, this.callOnChange);
    }

    if (target === 'endHour') {
      let endTimeTargetHour = parseInt(endTimeInputBuffer);
      const endTimeAMIndicator = endDate.format('a');

      // Retain PM if it's PM.
      if (endTimeTargetHour > 0 && endTimeAMIndicator === 'pm') {
        if (endTimeTargetHour < 12) {
          endTimeTargetHour += 12;
        }
      }

      this.setState({ endDate: endDate.hour(endTimeTargetHour), focus: '' }, this.callOnChange);
    }

    if (target === 'startMinutes') {
      const targetMinutes = parseInt(startTimeInputBuffer);
      this.setState({ startDate: startDate.minute(targetMinutes), focus: '' }, this.callOnChange);
    }
    if (target === 'endMinutes') {
      const targetMinutes = parseInt(endTimeInputBuffer);
      this.setState({ endDate: endDate.minute(targetMinutes), focus: '' }, this.callOnChange);
    }

    if (target === 'startPeriod') {
      this.setState({ focus: '' });
      this.callOnChange;
    }
    if (target === 'endPeriod') {
      this.setState({ focus: '' });
      this.callOnChange;
    }
  };

  callOnChange = () => {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      onChange(this.state.startDate.toDate(), this.state.endDate.toDate());
    }
  };

  componentDidMount(): void {
    const { startTimeValue, endTimeValue, defaultValue, timezone } = this.props;
    // If there's a value, use that as precedence.
    if (!_.isEmpty(startTimeValue) && !_.isEmpty(endTimeValue)) {
      this.setState({
        startDate: timezone ? moment.tz(startTimeValue, timezone) : moment(startTimeValue),
        endDate: timezone ? moment.tz(endTimeValue, timezone) : moment(endTimeValue),
      });
    } else {
      // if there's a default value, use that instead.
      if (!_.isEmpty(defaultValue)) {
        this.setState(
          {
            startDate: timezone ? moment.tz(defaultValue, timezone) : moment(defaultValue),
            endDate: timezone ? moment.tz(defaultValue, timezone) : moment(defaultValue),
          },
          this.callOnChange,
        );
      } else {
        this.setState(
          { startDate: timezone ? moment.tz(timezone) : moment(), endDate: timezone ? moment.tz(timezone) : moment() },
          this.callOnChange,
        );
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<DoubleTimeInputProps>) {
    const { startTimeValue, endTimeValue, timezone } = this.props;
    if (startTimeValue !== prevProps.startTimeValue) {
      this.setState({ startDate: timezone ? moment.tz(startTimeValue, timezone) : moment(startTimeValue) });
    }
    if (endTimeValue !== prevProps.endTimeValue) {
      this.setState({ endDate: timezone ? moment.tz(endTimeValue, timezone) : moment(endTimeValue) });
    }
  }

  componentWillUnmount(): void {
    this.setState({
      focus: '',
      startTimeInputBuffer: '',
      endTimeInputBuffer: '',
      startDate: null,
      endDate: null,
    });
  }

  render() {
    const {
      size = 'default',
      className = '',
      disabled = false,
      displayIcon = true,
      startTimeInputDisabled,
      endTimeInputDisabled,
    } = this.props;

    // Input buffer is used to store the current input. This experience is much better than directly changing it.
    // Focus is used to indicate which control is currently highlighted.
    const { startDate, startTimeInputBuffer, endTimeInputBuffer, endDate, focus } = this.state;

    return (
      <div
        className={`double-timepicker-container ${!_.isEmpty(focus) && 'focused'} ${
          disabled ? 'bg-tertiary' : 'bg-white'
        } ${size} ${className}`}
        style={this.props.containerStyle}
      >
        <input
          value={focus === 'startHour' ? startTimeInputBuffer || '' : startDate.format('hh')}
          placeholder='HH'
          disabled={startTimeInputDisabled ? startTimeInputDisabled : disabled}
          onChange={(e) => this._onChangeHour(e, false)}
          onFocus={(e) => this._onFocusStartTime('startHour', e)}
          onBlur={() => this._onBlur('startHour')}
          ref={(c) => (this._startTimeHourInput = c)}
          className='hour-input'
        />
        <span>:</span>
        <input
          value={this.state.focus === 'startMinutes' ? startTimeInputBuffer : startDate.format('mm')}
          placeholder='MM'
          disabled={startTimeInputDisabled ? startTimeInputDisabled : disabled}
          onChange={(e) => this._onChangeMinutes(e, false)}
          onFocus={(e) => this._onFocusStartTime('startMinutes', e)}
          onBlur={() => this._onBlur('startMinutes')}
          ref={(c) => (this._startTimeMinuteInput = c)}
          className='minutes-input'
        />
        <input
          value={startDate.format('a')}
          placeholder='AM/PM'
          disabled={startTimeInputDisabled ? startTimeInputDisabled : disabled}
          onKeyDown={(e) => this._onKeyAMPM(e, false)}
          onFocus={(e) => this._onFocusStartTime('startPeriod', e)}
          onBlur={() => this._onBlur('startPeriod')}
          onChange={this._onchangeAMPM}
          ref={(c) => (this._startTimeAmpmInput = c)}
          className='ampm-input'
        />

        <span className='slash'>-</span>

        <input
          value={focus === 'endHour' ? endTimeInputBuffer || '' : endDate.format('hh')}
          placeholder='HH'
          disabled={endTimeInputDisabled ? endTimeInputDisabled : disabled}
          onChange={(e) => this._onChangeHour(e, true)}
          onFocus={(e) => this._onFocusEndTime('endHour', e)}
          onBlur={() => this._onBlur('endHour')}
          ref={(c) => (this._endTimeHourInput = c)}
          className='hour-input'
        />
        <span>:</span>
        <input
          value={this.state.focus === 'endMinutes' ? endTimeInputBuffer : endDate.format('mm')}
          placeholder='MM'
          disabled={endTimeInputDisabled ? endTimeInputDisabled : disabled}
          onChange={(e) => this._onChangeMinutes(e, true)}
          onFocus={(e) => this._onFocusEndTime('endMinutes', e)}
          onBlur={() => this._onBlur('endMinutes')}
          ref={(c) => (this._endTimeMinuteInput = c)}
          className='minutes-input'
        />
        <input
          value={endDate.format('a')}
          placeholder='AM/PM'
          disabled={endTimeInputDisabled ? endTimeInputDisabled : disabled}
          onKeyDown={(e) => this._onKeyAMPM(e, true)}
          onFocus={(e) => this._onFocusEndTime('endPeriod', e)}
          onBlur={() => this._onBlur('endPeriod')}
          onChange={this._onchangeAMPM}
          ref={(c) => (this._endTimeAmpmInput = c)}
          className='ampm-input'
        />
        {displayIcon && (
          <Icon type='clock-circle' className='ml-small text-color-tertiary' style={{ fontSize: '14px' }} />
        )}
      </div>
    );
  }
}

export default DoubleTimeInput;
