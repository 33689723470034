import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const reset: CSS = {
  display: 'flex',
  gap: '$space100',
  fontFamily: '$primary',
  fontSize: '$font75',
  lineHeight: '$leading400',
  width: 'max-content',
};

// Outer Circle Variants
export const outerCircle: CSS = {
  position: 'relative',
  flexShrink: 0,
  boxSizing: 'border-box',
  backgroundColor: '$white',
  borderStyle: '$solid',
  borderColor: '$body',
  borderRadius: '$full',
  overflow: 'hidden',
};

// Outer Circle Variants
export const outerCircleVariants = css({
  variants: {
    size: {
      small: {
        width: '$size200',
        height: '$size200',
        borderWidth: '1px',
        marginTop: '$space30',
      },
      medium: {
        width: '$size250',
        height: '$size250',
        borderWidth: '1.5px',
      },
    },
    isCheckMark: {
      true: {},
    },
    isChecked: {
      true: {
        borderColor: '$ocean',
      },
    },
    isDisabled: {
      true: {
        backgroundColor: '$body',
        borderColor: '$body',
      },
    },
    isPressed: {
      true: {
        backgroundColor: '$oceanLight1',
        borderColor: '$oceanDark2',
      },
    },
    isFocused: {
      true: {
        borderColor: '$transparent',
        backgroundColor: '$oceanLight2',
        boxShadow: '0px 0px 0px 1.25px white, 0px 0px 0px 2.5px #206E8C;',
      },
    },
  },
  compoundVariants: [
    {
      isPressed: true,
      isChecked: true,
      css: {
        backgroundColor: '$white',
      },
    },
    {
      isChecked: true,
      isCheckMark: true,
      css: {
        borderWidth: '$border0',
        backgroundColor: '#206E8C',
      },
    },
  ],
});

// Inner Circle Css
export const innerCircle: CSS = {
  position: 'absolute',
  borderRadius: '$full',
};

// Inner Circle Variants
export const innerCircleVariants = css({
  variants: {
    size: {
      small: {
        top: '3px',
        left: '3px',
        width: '$size100',
        height: '$size100',
      },
      medium: {
        top: '3.5px',
        left: '3.5px',
        width: '$size125',
        height: '$size125',
      },
    },
    isChecked: {
      true: {
        backgroundColor: '$ocean',
      },
    },
    isDisabled: {
      true: {},
    },
    isPressed: {
      true: {},
    },
  },
  compoundVariants: [
    {
      isChecked: true,
      isDisabled: true,
      css: {
        backgroundColor: '$bodyDark1',
      },
    },
    {
      isChecked: true,
      isPressed: true,
      css: {
        backgroundColor: '$oceanDark2',
      },
    },
  ],
});

// ------ Css text group ---------
export const textGroup: CSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$space20',
};

// Label text css
export const labelText: CSS = {
  color: '$bodyDark2',
};

// Label text variants
export const labelTextVariants = css({
  variants: {
    size: {
      small: {
        fontSize: '$font75',
        lineHeight: '$leading400',
      },
      medium: {
        fontSize: '$font100',
        lineHeight: '$leading400',
      },
    },
    emphasis: {
      regular: {
        fontWeight: '$normal',
      },
      bold: {
        fontWeight: '$bold',
      },
    },
  },
});

// Support text css
export const supportText: CSS = {
  fontWeight: '$normal',
  color: '$bodyDark1',
};

// Support text variants
export const supportTextVariants = css({
  variants: {
    size: {
      small: {
        fontSize: '$font50',
        lineHeight: '$leading100',
      },
      medium: {
        fontSize: '$font75',
        lineHeight: '$leading400',
      },
    },
  },
});

export type OuterCircleVariants = Stitches.VariantProps<typeof outerCircleVariants>;
export type InnerCircleVariants = Stitches.VariantProps<typeof innerCircleVariants>;
export type LabelTextVariants = Stitches.VariantProps<typeof labelTextVariants>;
export type SupportTextVariants = Stitches.VariantProps<typeof supportTextVariants>;
