import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Search from 'antd/lib/input/Search';
import { ProgressBar } from '@blueprintjs/core';
import { Modal, notification, Row, Skeleton } from 'antd';

import { HyperlinkButton } from 'common-components/buttons';
import { Paragraph, Title, Text } from 'common-components/typography';
import PreviewFormModal from 'views/form-builder/components/PreviewFormModal';
import EditValueFormModal from 'views/form-builder/components/EditValueFormModal';
import { state, dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';

import { IElementValue } from '../shared/form-interface';
import { FormType } from 'utilities/enum-utils';

interface IAttachFormWrapperProps {
  isOpen?: boolean;
  onClose?: () => void;
  isAddedFormOnly?: boolean;
  onSave: (
    formId: string,
    elementValues: IElementValue[],
    formName: string,
    formVersionId: string,
    status?: string,
    isSyncCustomerData?: boolean,
  ) => void;
  isWorkflowFormVersion?: boolean;
  isShowOnlyEditModal?: boolean;
  timezone?: string;
  isCustomerForm?: boolean;
  isWorkflowForm?: boolean;
  isNotGuardian?: boolean;
}
const AttachFormWrapper = ({
  isOpen,
  onClose,
  onSave,
  isAddedFormOnly = false,
  isWorkflowFormVersion = false,
  isShowOnlyEditModal = false,
  timezone,
  isCustomerForm,
  isWorkflowForm,
  isNotGuardian,
}: IAttachFormWrapperProps) => {
  return (
    <>
      {isOpen && (
        <AttachFormModalConnected
          onClose={onClose}
          onSave={onSave}
          isAddedFormOnly={isAddedFormOnly}
          isWorkflowFormVersion={isWorkflowFormVersion}
          isShowOnlyEditModal={isShowOnlyEditModal}
          timezone={timezone}
          isCustomerForm={isCustomerForm}
          isWorkflowForm={isWorkflowForm}
          isNotGuardian={isNotGuardian}
        />
      )}
    </>
  );
};

interface IAttachFormModalProps {
  onClose?: () => void;
  isAddedFormOnly?: boolean;
  onSave: (
    formVersionId: string,
    elementValues: IElementValue[],
    formName: string,
    versionId: string,
    status?: string,
    isSyncCustomerData?: boolean,
  ) => void;
  currentForm: typeof state.formBuilderStore.currentForm;
  doGetFormDetailsByVersion: typeof dispatch.formBuilderStore.doGetFormDetailsByVersion;
  doFetchFormLiteList: typeof dispatch.formBuilderStore.doFetchFormLiteList;
  formLiteList: typeof state.formBuilderStore.formLiteList;
  isWorkflowFormVersion: boolean;
  isShowOnlyEditModal?: boolean;
  timezone?: string;
  isCustomerForm?: boolean;
  isWorkflowForm?: boolean;
  isNotGuardian?: boolean;
}
const AttachFormModal = ({
  onClose,
  onSave,
  currentForm,
  doGetFormDetailsByVersion,
  doFetchFormLiteList,
  formLiteList,
  isAddedFormOnly = false,
  isWorkflowFormVersion,
  isShowOnlyEditModal = false,
  timezone,
  isCustomerForm,
  isWorkflowForm,
  isNotGuardian,
}: IAttachFormModalProps) => {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isEditValueFormModalOpen, setIsEditValueFormModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedFormVersion, setSelectedFormVersion] = useState<string | null>(null);
  const [selectedFormID, setSelectedFormID] = useState<string | null>(null);
  const _fetchFormLiterList = async (txt?: string) => {
    let formType = [];

    if (!isNotGuardian) formType = [FormType.INCIDENT, FormType.CUSTOM];
    else if (isCustomerForm) formType = [FormType.INTAKE, FormType.INCIDENT, FormType.CUSTOM];
    else if (isWorkflowForm) formType = [FormType.INCIDENT];
    else formType = [FormType.INCIDENT, FormType.CUSTOM];

    await doFetchFormLiteList({
      search: txt || '',
      formType,
    });
    setIsLoading(false);
  };

  const _doSearch = useCallback(_.debounce(_fetchFormLiterList, 500), []);

  const _onEnterSearchText = (event) => {
    setIsLoading(true);

    if (event.target.value.length >= 3 || event.target.value.length === 0) {
      _doSearch(event.target.value);
    }
  };

  const _onPreviewFormClick = async (formId: string, versionId: string, isWorkflowFormVersion: boolean) => {
    try {
      await doGetFormDetailsByVersion({ formId, versionId, isWorkflowFormVersion });
      setIsPreviewModalOpen(true);
    } catch (error) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: error.message });
    }
  };

  const _onSelectFormClick = async (formId: string, versionId: string, isWorkflowFormVersion: boolean) => {
    try {
      setSelectedFormVersion(versionId);
      setSelectedFormID(formId);
      const {
        title: { formTitle: formName },
      } = (await doGetFormDetailsByVersion({ formId, versionId, isWorkflowFormVersion })) as any;
      if (isAddedFormOnly) {
        onSave(formId, [], formName, versionId);

        if (onClose && typeof onClose === 'function') {
          onClose();
        }
      } else {
        setIsEditValueFormModalOpen(true);
      }
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  const _onClosePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  const _doSave = async (
    formId: string,
    elementValues: IElementValue[],
    status: string,
    isSyncCustomerData: boolean,
  ) => {
    const formName = currentForm && currentForm.title ? currentForm.title.formTitle : '';
    await onSave(formId, elementValues, formName, selectedFormVersion || '', status, isSyncCustomerData);
    setSelectedFormVersion(null);
    setIsEditValueFormModalOpen(false);

    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const _doClose = () => {
    setSelectedFormVersion(null);
    setIsEditValueFormModalOpen(false);
  };

  useEffect(() => {
    _fetchFormLiterList();
  }, []);

  return (
    <Modal
      centered
      visible={true}
      title={
        <Title level={4} className="mv-none">
          Attach a form
        </Title>
      }
      onCancel={onClose}
      width="1024px"
      footer={null}
      style={{ display: isShowOnlyEditModal && isEditValueFormModalOpen ? 'none' : '' }}
    >
      {isPreviewModalOpen && (
        <PreviewFormModal
          formContent={currentForm}
          isOpen={isPreviewModalOpen}
          onClose={_onClosePreviewModal}
          timezone={timezone}
        />
      )}

      {isEditValueFormModalOpen && (
        <EditValueFormModal
          formElements={currentForm}
          formId={selectedFormID}
          isOpen={isEditValueFormModalOpen}
          onClose={_doClose}
          onSave={_doSave}
          mode="ADD"
          isEditModal={isShowOnlyEditModal}
          timezone={timezone}
        />
      )}
      <Paragraph>Select a form to attach to this step</Paragraph>
      <Search
        placeholder="Search for a form"
        loading={isLoading}
        style={{ width: '300px' }}
        allowClear={true}
        className={'mr-large'}
        onChange={_onEnterSearchText}
      />
      <div className="bg-quaternary">
        {isLoading ? (
          <div>
            <div className="pv-large">
              <ProgressBar />
            </div>
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
          </div>
        ) : (
          <div className="pv-large ph-medium mt-medium overflow-auto" style={{ maxHeight: '50vh' }}>
            {_.isEmpty(formLiteList) ? (
              <div className="text-align-center">
                <Text weight="bold">No form found</Text>
              </div>
            ) : (
              formLiteList.map((form) => {
                const { formId, name, description, formVersionId } = form;

                return (
                  <Row key={formId} className="bg-white p-small rounded-big mb-medium">
                    <Text weight="bold">{name}</Text>
                    <Paragraph className="mt-large">{description}</Paragraph>

                    <HyperlinkButton
                      color="blue-action"
                      className="mr-large"
                      onClick={() => _onSelectFormClick(formId, formVersionId, isWorkflowFormVersion)}
                    >
                      Select form
                    </HyperlinkButton>
                    <HyperlinkButton
                      color="blue-action"
                      onClick={() => _onPreviewFormClick(formId, formVersionId, isWorkflowFormVersion)}
                    >
                      Preview form
                    </HyperlinkButton>
                  </Row>
                );
              })
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentForm: state.formBuilderStore.currentForm,
  formLiteList: state.formBuilderStore.formLiteList,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doGetFormDetailsByVersion: dispatch.formBuilderStore.doGetFormDetailsByVersion,
  doFetchFormLiteList: dispatch.formBuilderStore.doFetchFormLiteList,
});

const AttachFormModalWrapper = connect(mapStateToProps, mapDispatchToProps)(AttachFormWrapper);

const AttachFormModalConnected = connect(mapStateToProps, mapDispatchToProps)(AttachFormModal);

export default AttachFormModalWrapper;
