import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Soundwave = forwardRef(function Soundwave(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 4a.75.75 0 0 0-1.5 0v16a.75.75 0 1 0 1.5 0V4ZM7 7.25a.75.75 0 0 1 .75.75v8a.75.75 0 0 1-1.5 0V8A.75.75 0 0 1 7 7.25Zm-5 3a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75Zm15-3a.75.75 0 0 1 .75.75v8a.75.75 0 0 1-1.5 0V8a.75.75 0 0 1 .75-.75ZM22.75 11a.75.75 0 0 0-1.5 0v2a.75.75 0 1 0 1.5 0v-2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
