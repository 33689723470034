import React from 'react';
import { Loader2Adjustable } from '@good/icons';

export const FilesTabLoadingState = (): JSX.Element => {
  return (
    <div className='flex flex-col content-center align-center'>
      <Loader2Adjustable className='animate-spin' />
    </div>
  );
};
