import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Fire = forwardRef(function Fire(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.318 2.32 12 3l.318-.68.001.001.002.001.007.003.02.01.067.033a13.258 13.258 0 0 1 1.084.627 15.56 15.56 0 0 1 2.53 2.03c1.842 1.83 3.721 4.67 3.721 8.611 0 4.47-3.436 8.114-7.75 8.114s-7.75-3.643-7.75-8.114c0-2.233.82-4.033 1.622-5.262a10.71 10.71 0 0 1 1.11-1.423 8.346 8.346 0 0 1 .517-.51l.01-.008.004-.003V6.43h.001L8 7l-.485-.572A.75.75 0 0 1 8.75 7c0 1.547.007 2.67.221 3.419.101.354.228.541.353.646.115.095.306.185.676.185.37 0 .653-.178.901-.698.265-.555.415-1.376.476-2.349.06-.957.03-1.986-.017-2.916-.014-.296-.031-.59-.048-.868A28.24 28.24 0 0 1 11.25 3a.75.75 0 0 1 1.068-.68ZM7.277 8.974c-.05.072-.1.145-.149.221-.697 1.067-1.378 2.584-1.378 4.442 0 3.712 2.832 6.614 6.25 6.614s6.25-2.902 6.25-6.614c0-3.421-1.62-5.9-3.278-7.547a14.061 14.061 0 0 0-2.162-1.752c.016.268.033.56.049.876.047.945.08 2.04.015 3.084-.065 1.027-.228 2.08-.619 2.901-.408.855-1.125 1.552-2.255 1.552-.63 0-1.189-.16-1.636-.533-.438-.365-.686-.865-.835-1.386-.151-.529-.22-1.163-.252-1.858Z"
        fill="currentColor"
      />
    </Icon>
  );
});
