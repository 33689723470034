import { forwardRef } from 'react';

import type { HTMLAttributes, PropsWithChildren } from 'react';

const DIMENSION = {
  md: 128,
  lg: 256,
  xl: 512,
};

type Size = keyof typeof DIMENSION;

const px = (v: number) => `${v}px`;

export type IllustrationProps = PropsWithChildren<Omit<HTMLAttributes<SVGElement>, 'className' | 'style'>> & {
  /**
   * Change the dimensions of the illustration.
   *
   * @default md
   */
  size?: Size;
};

export const Illustration = forwardRef<SVGSVGElement, IllustrationProps>(function Illustration(props, ref) {
  const { children, size = 'md', ...otherProps } = props;
  const dimension = DIMENSION[size];

  return (
    <svg
      role="presentation"
      {...otherProps}
      xmlns="http://www.w3.org/2000/svg"
      fill="transparent"
      viewBox="0 0 128 128"
      height={px(dimension)}
      width={px(dimension)}
      className="block"
      ref={ref}
    >
      {children}
    </svg>
  );
});
