import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Globe = forwardRef(function Globe(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.836 4.25c.378-.363.724-.5 1.029-.5.304 0 .65.137 1.028.5.381.367.758.934 1.09 1.69.195.443.37.94.52 1.482a19.97 19.97 0 0 0-2.638-.172c-.912 0-1.798.06-2.638.172.15-.542.324-1.039.519-1.481.332-.757.71-1.324 1.09-1.69ZM7.625 7.713c.198-.875.456-1.678.765-2.38.161-.367.338-.711.531-1.029a8.23 8.23 0 0 0-4.667 4.72 8.569 8.569 0 0 1 1.017-.537 12.852 12.852 0 0 1 2.354-.774ZM3.707 12c0 .309.136.659.495 1.04.363.385.923.767 1.671 1.103.438.197.93.373 1.465.524A20.65 20.65 0 0 1 7.168 12c0-.922.059-1.818.17-2.668-.536.152-1.027.328-1.465.525-.748.336-1.308.718-1.67 1.103-.36.382-.496.732-.496 1.04Zm5.18 3.011A18.779 18.779 0 0 1 8.651 12c0-1.066.085-2.08.236-3.011.92-.154 1.924-.239 2.978-.239 1.054 0 2.057.085 2.977.239.152.93.236 1.945.236 3.011s-.084 2.08-.236 3.011c-.92.154-1.923.239-2.977.239-1.054 0-2.057-.085-2.978-.239Zm-1.262 1.277c-.866-.2-1.66-.462-2.354-.774a8.573 8.573 0 0 1-1.017-.537 8.23 8.23 0 0 0 4.667 4.72 8.73 8.73 0 0 1-.53-1.028 13.206 13.206 0 0 1-.766-2.38Zm1.602.29c.84.113 1.726.172 2.638.172.912 0 1.797-.06 2.637-.172-.15.542-.324 1.038-.518 1.481-.333.757-.71 1.324-1.091 1.69-.377.364-.724.501-1.028.501-.305 0-.651-.137-1.029-.5-.38-.367-.758-.934-1.09-1.69-.195-.444-.37-.94-.519-1.482Zm6.878-.29a13.211 13.211 0 0 1-.766 2.38c-.16.367-.338.712-.531 1.029a8.23 8.23 0 0 0 4.667-4.72 8.568 8.568 0 0 1-1.017.537c-.694.312-1.488.573-2.353.774Zm5.4-4.288c0-5.385-4.316-9.75-9.64-9.75-5.325 0-9.641 4.365-9.641 9.75s4.316 9.75 9.64 9.75c5.325 0 9.641-4.365 9.641-9.75Zm-2.03-2.977a8.562 8.562 0 0 0-1.017-.537 12.853 12.853 0 0 0-2.353-.774 13.21 13.21 0 0 0-.766-2.38 8.73 8.73 0 0 0-.531-1.029 8.23 8.23 0 0 1 4.667 4.72Zm-3.083.31c.535.15 1.026.327 1.464.524.749.336 1.309.718 1.671 1.103.36.382.495.732.495 1.04 0 .308-.136.658-.495 1.04-.362.385-.922.767-1.67 1.103-.439.197-.93.373-1.465.524.11-.85.17-1.745.17-2.667 0-.922-.06-1.818-.17-2.668Z"
        fill="currentColor"
      />
    </Icon>
  );
});
