import React, { useState } from 'react';

import moment from 'moment-timezone';

import { Avatar, Icon, Tooltip } from 'antd';
import { Menu, MenuItem, Popover } from '@blueprintjs/core';

import { Text } from 'common-components/typography';
import { IExtendedMessage, MessageType } from 'interfaces/message-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';

function ActionsBar({
  message,
  onDeleteMessage,
  isFirst,
}: {
  message: IExtendedMessage;
  onDeleteMessage: any;
  isFirst: boolean;
}) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModelOpen] = useState(false);

  // Event handler shortcuts
  const showMenu = () => setMenuOpen(true);
  const hideMenu = () => setMenuOpen(false);
  const showDeleteModal = () => setDeleteModelOpen(true);
  const hideDeleteModal = () => setDeleteModelOpen(false);

  const popoverContent = (
    <Menu>
      {/*<MenuDivider title={<Text>Message Actions </Text>} />*/}
      <MenuItem
        text={
          <Text size="regular">
            <Icon type="delete" className="mr-medium text-color-tertiary" />
            Delete this message
          </Text>
        }
        label={''}
        className=""
        onClick={showDeleteModal}
      />
    </Menu>
  );

  const topPadding = isFirst ? 'pt-x2-large' : 'pt-small';

  return (
    <div className={`pr-smaall ${topPadding}`}>
      {message.messageType === MessageType.NewMessage && (
        <>
          <Popover content={popoverContent} onClose={hideMenu} isOpen={isMenuOpen} position={'bottom'}>
            <Icon type="ellipsis" className="text-size-x2-large text-color-secondary mr-x-small" onClick={showMenu} />
          </Popover>
          {/* TODO - Reply */}
          {/*<Tooltip title={'Reply'} placement={'top'}>*/}
          {/*  <Icon type="select" className="text-size-x2-large text-color-tertiary" />*/}
          {/*</Tooltip>*/}
        </>
      )}

      <ActionModal
        isOpen={isDeleteModalOpen}
        onClose={hideDeleteModal}
        width={'small'}
        title={'Delete this message?'}
        verticalAlignment="high"
      >
        <div className="mt-small">
          <Text>
            This message will be permanently removed. People in this conversation will be able to see that you have
            removed this message.
          </Text>
        </div>

        <ActionModalFooter>
          <GhostButton size="large" className="mr-medium" color="blue" onClick={hideDeleteModal}>
            Cancel
          </GhostButton>
          <PrimaryButton
            size="large"
            color="blue"
            onClick={() => {
              onDeleteMessage();
              hideDeleteModal();
            }}
          >
            Delete
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    </div>
  );
}

export function MyMessage({ message, deleteMessage }: { message: IExtendedMessage; deleteMessage }) {
  const [hovered, setHovered] = useState(false);
  const [finishedAnim, setFinishedAnim] = useState(false);

  const { content, createdOn, subscriber } = message;
  const { text } = content;

  const avatarUrl = subscriber && subscriber.attachmentUrl;

  function onDeleteMessage() {
    deleteMessage({ messageId: message.messageId });
  }

  const isFirst = !_.isEmpty(message.previousMessage) && message.authorUserId !== message.previousMessage.authorUserId;

  const verticalPadding = isFirst ? 'pt-small pb-x2-small' : 'pv-x2-small';

  return (
    <div
      className={`ph-medium flex-row justify-end hover-bg-message-item ${verticalPadding} `}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)} // Should include
    >
      {hovered && <ActionsBar message={message} onDeleteMessage={onDeleteMessage} isFirst={isFirst} />}

      <div>
        <div className="text-align-right">
          {isFirst && (
            <div className="pb-x-small">
              <Text weight="bold" size="regular" color="black" className="select-none">
                {subscriber && subscriber.firstName} {subscriber && subscriber.lastName}
              </Text>
            </div>
          )}
        </div>

        <div
          className="bg-blue-lightest pv-small rounded wrap-break-word"
          style={{ maxWidth: '550px', paddingLeft: '12px', paddingRight: '12px', whiteSpace: 'pre-wrap' }}
        >
          {/* Normal Message */}
          {message.messageType === MessageType.NewMessage && <Text>{text}</Text>}

          {/* Deleted Message */}
          {message.messageType === MessageType.DeleteMessage && (
            <div className="flex-row align-center">
              <Icon type="stop" className="text-color-tertiary text-size-regular" />
              <Text size="regular" color="tertiary" className="ml-x-small">
                This message has been deleted.
              </Text>
            </div>
          )}

          <div className="text-align-right flex-row align-center justify-end pt-x-small">
            <Text size="small" color="tertiary" className="select-none">
              {moment.tz(createdOn, message.preferredTimezone).format('hh:mm A')}
            </Text>

            {/* Sent indicators */}
            {message.sent && <Icon type="check" className="text-size-x-small text-color-tertiary ml-x-small" />}
            {!message.sent && <Icon type="clock-circle" className="text-size-x-small text-color-tertiary ml-x-small" />}
          </div>
        </div>
      </div>
      <div className="ml-medium" style={{ width: '32px' }}>
        {isFirst && <Avatar icon="user" src={avatarUrl} />}
      </div>
    </div>
  );
}
