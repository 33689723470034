import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Avatar, Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { IconButton } from 'common-components/buttons';
import { Warning } from 'common-components/alerts';
import { CustomerStatusType } from 'utilities/enum-utils';

interface ISelectedCustomerRowProps extends FormComponentProps {
  selectedCustomerData: any;
  removeSelectedCustomer: () => void;
}

class SelectedCustomerRow extends Component<ISelectedCustomerRowProps> {
  render() {
    const { selectedCustomerData } = this.props;
    if (selectedCustomerData) {
      return (
        <>
          {' '}
          <div className="mb-medium flex-row align-center anim-fade-in">
            <Text size="small" color="secondary" className="mr-small">
              Selected customer
            </Text>

            <div className="inline-block rounded flex-row align-center bg-tertiary">
              <span className="mr-small">
                <Avatar size="large" icon="user" src={selectedCustomerData.attachmentUrl} />
              </span>
              <Text weight="black" className="" lineHeight={100}>
                {selectedCustomerData.firstName} {selectedCustomerData.lastName}
              </Text>
              <IconButton
                icon={'close'}
                color="transparent"
                iconColor="red-darker"
                size="default"
                className="line-height-100"
                onClick={this.props.removeSelectedCustomer}
              />
            </div>
          </div>
          {selectedCustomerData.customerStatus === CustomerStatusType.ENQUIRY && (
            <Warning
              content={
                'The customer is still in the enquiry state. Please double check before creating any bookings for them.'
              }
              className={'ml-x10-large'}
            />
          )}
        </>
      );
    } else {
      return <div />;
    }
  }
}

export default Form.create<ISelectedCustomerRowProps>()(SelectedCustomerRow);
