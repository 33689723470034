import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FileScan = forwardRef(function FileScan(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 3.753c-.21-.002-.452-.003-.735-.003H11c-1.435 0-2.437.002-3.192.103-.734.099-1.122.28-1.399.556-.277.277-.457.665-.556 1.4C5.752 6.562 5.75 7.564 5.75 9v3.25h12.5V9.485c0-.283 0-.526-.003-.735h-.299c-.899 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.725-.456-.456-.642-1.023-.726-1.65-.08-.595-.08-1.344-.08-2.243v-.299Zm4.767 3.497c-.174-.332-.5-.673-1.305-1.477l-.485-.485c-.804-.805-1.145-1.131-1.477-1.305V4c0 .964.002 1.612.066 2.095.063.461.17.659.3.789.13.13.328.238.79.3.482.065 1.13.066 2.094.066h.017Zm1.733 2.235v2.765H21a.75.75 0 0 1 0 1.5H3a.75.75 0 1 1 0-1.5h1.25V8.945c0-1.367 0-2.47.117-3.337.12-.9.38-1.658.981-2.26.602-.602 1.36-.86 2.26-.981.867-.117 1.97-.117 3.337-.117H12.657c1.088 0 1.844 0 2.535.285.692.287 1.226.822 1.995 1.591l.1.101.486.485.1.101c.77.769 1.305 1.303 1.591 1.995.287.691.287 1.447.286 2.535v.142ZM5.75 16a.75.75 0 0 0-1.5 0v.527c0 .676 0 1.221.03 1.665.031.457.097.86.255 1.243a3.75 3.75 0 0 0 2.03 2.03c.382.158.786.224 1.242.255.444.03.99.03 1.666.03h5.054c.676 0 1.222 0 1.665-.03.457-.031.86-.097 1.243-.255a3.75 3.75 0 0 0 2.03-2.03c.158-.382.224-.786.255-1.242.03-.445.03-.99.03-1.666V16a.75.75 0 0 0-1.5 0v.5c0 .71 0 1.203-.027 1.59-.026.38-.074.602-.144.771a2.25 2.25 0 0 1-1.218 1.218c-.169.07-.39.118-.77.144-.388.027-.882.027-1.591.027h-5c-.71 0-1.204 0-1.59-.027-.38-.026-.602-.074-.771-.144a2.25 2.25 0 0 1-1.218-1.218c-.07-.169-.118-.39-.144-.77-.027-.388-.027-.882-.027-1.591V16Z"
        fill="currentColor"
      />
    </Icon>
  );
});
