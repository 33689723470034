import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Bed2 = forwardRef(function Bed2(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.25h-.052c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.08.595-.08 1.345-.08 2.243v3.466c-.447.11-.852.3-1.195.642-.455.455-.64 1.022-.725 1.65-.047.348-.066.748-.074 1.201A.756.756 0 0 0 1.25 15v6a.75.75 0 0 0 1.5 0v-3.25h18.5V21a.75.75 0 0 0 1.5 0V15a.747.747 0 0 0-.006-.093 10.662 10.662 0 0 0-.074-1.201c-.084-.628-.27-1.195-.726-1.65-.342-.343-.747-.533-1.194-.642V7.948c0-.898 0-1.648-.08-2.242-.084-.628-.27-1.195-.726-1.65-.455-.456-1.022-.642-1.65-.726-.594-.08-1.343-.08-2.242-.08H8Zm10 9.5H6c-.964 0-1.612.002-2.095.066-.461.063-.659.17-.789.3-.13.13-.237.328-.3.79-.064.482-.066 1.13-.066 2.094v.25h18.5V16c0-.964-.002-1.612-.067-2.095-.062-.461-.169-.659-.3-.789-.13-.13-.327-.237-.788-.3-.483-.064-1.131-.066-2.095-.066Zm.153-1.5h1.097V8c0-.964-.002-1.612-.067-2.095-.062-.461-.169-.659-.3-.789-.13-.13-.327-.237-.788-.3-.483-.064-1.131-.066-2.095-.066H8c-.964 0-1.612.002-2.095.067-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788C4.753 6.388 4.75 7.036 4.75 8v3.25h1.502c.004-.595.02-1.111.078-1.544.084-.628.27-1.195.725-1.65.456-.456 1.023-.642 1.65-.726.595-.08 1.345-.08 2.243-.08H13.052c.899 0 1.648 0 2.242.08.628.084 1.195.27 1.65.725.456.456.642 1.023.726 1.65.058.434.074.95.078 1.545h.404Zm-1.905 0c-.004-.57-.018-1-.065-1.345-.062-.461-.169-.659-.3-.789-.13-.13-.327-.237-.788-.3-.483-.064-1.131-.066-2.095-.066h-.25v2.5h3.498Zm-4.998 0v-2.5H11c-.964 0-1.612.002-2.095.067-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788-.046.346-.06.776-.064 1.345h3.498Z"
        fill="currentColor"
      />
    </Icon>
  );
});
