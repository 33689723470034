import React, { useContext, createContext, useState, useMemo, useCallback } from 'react';
import { FieldValues, FormProvider, UseFormProps, useForm } from 'react-hook-form';
import { AddressInputRef } from '../address-input';
import { UnsavedChangesModal } from './unsaved-changes-modal';

type InlineFormContextType = {
  addAsyncTask: (taskId: string) => void;
  disabled?: boolean;
  editId: string;
  hasAsyncTasks: boolean;
  isEditing: boolean;
  isSubmitting: boolean;
  loading?: boolean;
  maxWidth?: React.CSSProperties['maxWidth'];
  onCancel: () => void;
  onEdit: () => void;
  registerAddressFieldRef: (name: string, ref: AddressInputRef) => void;
  removeAsyncTask: (taskId: string) => void;
  timezone?: string;
  title: string;
  toggleEditing: () => void;
};

const initialContext: InlineFormContextType = {
  addAsyncTask: () => null,
  disabled: false,
  editId: '',
  hasAsyncTasks: false,
  isEditing: false,
  isSubmitting: false,
  loading: false,
  maxWidth: undefined,
  onCancel: () => null,
  onEdit: () => null,
  registerAddressFieldRef: () => null,
  removeAsyncTask: () => null,
  timezone: undefined,
  title: '',
  toggleEditing: () => null,
};

type InlineFormProviderProps<T extends FieldValues> = {
  children: React.ReactNode;
  className?: string;
  classNameEditing?: string;
  disabled?: boolean;
  formProps?: UseFormProps<T, unknown>;
  loading?: boolean;
  maxWidth?: React.CSSProperties['maxWidth'];
  onSubmit: (values: T) => void | Promise<void>;
  timezone?: string;
  title: string;
};

const InlineFormContext = createContext(initialContext);

export function InlineFormProvider<T extends FieldValues>({
  children,
  className,
  classNameEditing,
  disabled,
  formProps,
  loading,
  maxWidth,
  onSubmit: onSubmitProp,
  timezone,
  title,
}: InlineFormProviderProps<T>) {
  const form = useForm<T>(formProps);
  const [asyncTasks, setAsyncTasks] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenUnsavedChangesModal, setIsOpenUnsavedChangesModal] = useState(false);
  const [formState, setFormState] = useState<T | undefined>(undefined);
  const editId = `edit-${title.replace(/\s+/g, '-').toLowerCase()}`;
  const addressFieldRefs = React.useRef<Record<string, AddressInputRef>>({});
  const hasAsyncTasks = useMemo(() => asyncTasks.length > 0, [asyncTasks]);

  const toggleEditing = useCallback(() => {
    if (disabled || hasAsyncTasks) {
      return;
    }
    setIsEditing((prev) => !prev);
  }, [disabled, hasAsyncTasks]);

  const takeSnapshot = useCallback(() => {
    setFormState(form.getValues());
  }, [form]);

  const restoreSnapshot = useCallback(() => {
    form.reset(formState);
  }, [form, formState]);

  const onEdit = useCallback(() => {
    takeSnapshot();
    toggleEditing();
  }, [takeSnapshot, toggleEditing]);

  const onCloseUnsavedChangesModal = useCallback(() => {
    setIsOpenUnsavedChangesModal(false);
  }, []);

  const onConfirmCancel = useCallback(() => {
    onCloseUnsavedChangesModal();
    restoreSnapshot();
    toggleEditing();
  }, [restoreSnapshot, toggleEditing, onCloseUnsavedChangesModal]);

  const onCancel = useCallback(() => {
    if (form.formState.isDirty) {
      setIsOpenUnsavedChangesModal(true);
    } else {
      onConfirmCancel();
    }
  }, [form.formState.isDirty, onConfirmCancel]);

  const registerAddressFieldRef = useCallback((name: string, ref: AddressInputRef) => {
    addressFieldRefs.current[name] = ref;
  }, []);

  const validateAddressFields = useCallback(() => {
    Object.values(addressFieldRefs.current).forEach(({ validate }) => {
      const { hasErrors } = validate();
      if (hasErrors) {
        throw new Error('Address field has errors');
      }
    });
  }, [addressFieldRefs]);

  const addAsyncTask = useCallback((taskId: string) => {
    setAsyncTasks((prevAsyncTasks) => {
      const doesTaskExist = prevAsyncTasks.includes(taskId);
      if (doesTaskExist) {
        return prevAsyncTasks;
      }

      return [...prevAsyncTasks, taskId];
    });
  }, []);

  const removeAsyncTask = useCallback((taskId: string) => {
    setAsyncTasks((prevAsyncTasks) => {
      const index = prevAsyncTasks.indexOf(taskId);
      if (index === -1) {
        return prevAsyncTasks;
      }

      return [...prevAsyncTasks.slice(0, index), ...prevAsyncTasks.slice(index + 1)];
    });
  }, []);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      await form.handleSubmit(
        async (values) => {
          try {
            validateAddressFields();
            await onSubmitProp(values);
            toggleEditing();
          } catch (er) {
            // do nothing
          }
        },
        (errors) => {
          // eslint-disable-next-line no-console -- useful
          console.warn(`${title} form has errors preventing submission`, errors);
          try {
            validateAddressFields();
          } catch (er) {
            // do nothing
          }
        },
      )(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const value = useMemo(
    () => ({
      addAsyncTask,
      disabled,
      editId,
      hasAsyncTasks,
      isEditing,
      isSubmitting,
      loading,
      maxWidth,
      onCancel,
      onEdit,
      registerAddressFieldRef,
      removeAsyncTask,
      timezone,
      title,
      toggleEditing,
    }),
    [
      addAsyncTask,
      disabled,
      editId,
      hasAsyncTasks,
      isEditing,
      isSubmitting,
      loading,
      maxWidth,
      onCancel,
      onEdit,
      registerAddressFieldRef,
      removeAsyncTask,
      timezone,
      title,
      toggleEditing,
    ],
  );

  return (
    <InlineFormContext.Provider value={value}>
      <FormProvider {...form}>
        <form
          onSubmit={onSubmit}
          className={['inline-flex w-full flex-col', isEditing ? classNameEditing : className].join(' ')}
          aria-label={title}
        >
          {children}
        </form>
      </FormProvider>
      <UnsavedChangesModal
        isOpen={isOpenUnsavedChangesModal}
        onCancel={onCloseUnsavedChangesModal}
        onConfirm={onConfirmCancel}
      />
    </InlineFormContext.Provider>
  );
}

export const useInlineForm = () => useContext(InlineFormContext);
