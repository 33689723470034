import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Functions = forwardRef(function Functions(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.31 3.707A.75.75 0 0 1 6 3.25h12a.75.75 0 0 1 0 1.5H7.767l6.5 6.729a.75.75 0 0 1 0 1.042l-6.5 6.729H18a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.54-1.271L12.686 12 5.46 4.521a.75.75 0 0 1-.151-.814Z"
        fill="currentColor"
      />
    </Icon>
  );
});
