import { IElementValue, IFormElement, IFormElementProperty } from './form-interface';
import { cloneDeep, isArray, isEmpty, keys, uniq } from 'lodash';
import { FormElementType, PropKey, PropType } from './form-enum';
import { ValidationRule } from 'antd/es/form';

export const getRules = (element: IFormElement, applyRulePlaceHolder = true): ValidationRule[] => {
  let isRequired = false;
  let messageRequired = '';
  const oldRules = element.properties.general.find((item) => item.key === PropKey.RULES)?.rules || [];

  element.properties.general.forEach((config) => {
    if (config.key === PropKey.REQUIRE && config.type === PropType.required) {
      isRequired = config.value;
      messageRequired = config?.caption || 'This field is required.';
    }
  });

  const rule: ValidationRule = { required: isRequired, message: messageRequired };
  if (isRequired && element.type === FormElementType.CHECKBOX) {
    rule.required = false;
    rule.validator = (_, value) => {
      if (!value) return Promise.reject('This field is required.');
      return Promise.resolve();
    };
  }

  oldRules.forEach((item) => {
    Object.keys(item).map((key) => {
      if (key === 'pattern') {
        item.pattern = new RegExp(item.pattern);
      }
    });
  });

  if (applyRulePlaceHolder) {
    const newRules = getRuleByKey(element.properties.appearance, 'placeholderText');
    return [rule, ...cloneDeep(newRules)];
  }
  return [rule, ...oldRules];
};

export const getPropertyByKey = (properties: IFormElementProperty[], key: string) => {
  const property = properties.find((p) => p.key === key) || null;
  return property;
};

export const getValueFromPropertyByKey = (properties: IFormElementProperty[], key: string) => {
  const property = getPropertyByKey(properties, key);
  if (property) {
    return property.value;
  }
  return null;
};

const getRuleByKey = (properties: IFormElementProperty[], key: string) => {
  const property = getPropertyByKey(properties, key);
  if (property) {
    return property.rules || [];
  }
  return [];
};

// Returns the elementData of an element in the ElementValue array that contains a matching elementId or NULL
export const getElementValueById = (elementValues: IElementValue[], id: string) => {
  let value = null;
  const eValue = elementValues?.find((value) => value.elementId === id);
  if (eValue) {
    value = eValue.elementData;
  }
  return value;
};

export const getConditionalElementConfigProperties = (conditionalFormElement: IFormElement) => {
  const targetElement = conditionalFormElement.properties.configuration.find(
    (element) => element.key === PropKey.TARGET_ELEMENT,
  );
  const targetOptions = conditionalFormElement.properties.configuration.find(
    (element) => element.key === PropKey.TARGET_OPTION,
  );
  const affectedElement = conditionalFormElement.properties.configuration.find(
    (element) => element.key === PropKey.AFFECT_TO_ELEMENT,
  );
  const repeatable = conditionalFormElement.properties.configuration.find(
    (element) => element.key === PropKey.REPEATABLE,
  );
  const comparator = conditionalFormElement.properties.configuration.find(
    (element) => element.key === PropKey.CONDITIONAL_COMPARATOR,
  );
  const comparatorTime = conditionalFormElement.properties.configuration.find(
    (element) => element.key === PropKey.CONDITIONAL_COMPARATOR_DATE,
  );

  return { targetElement, targetOptions, affectedElement, repeatable, comparator, comparatorTime };
};

// Is this a conditional element, and should it be rendered
export const isRelatedConditionalElement = (listFormElements: IFormElement[], item: IFormElement) => {
  // Get all conditional elements
  const listConditionalElements = getAllFormElementsWithChildren(listFormElements).filter(
    (el) => el.type === FormElementType.CONDITIONAL,
  );

  // Get list of element id's that have 'affectToElement' set, and that does not contain itself
  const listAffectElements = listConditionalElements.reduce((previousValue, currentValue) => {
    const affectElement = currentValue.properties.configuration.find((config) => config.key === 'affectToElement');
    if (!affectElement.value?.includes(currentValue.parentId)) {
      previousValue.push(...affectElement.value);
    }

    return previousValue;
  }, []);

  // Returns true if affectelements includes the ID of the element provided OR true if it is a conditional element
  return listConditionalElements.some((el) => el.id === item.id) || listAffectElements.includes(item.id);
};

export const getElementConfigurationByKey = (element: IFormElement, key: string) => {
  return element.properties?.configuration?.find((config) => config.key === key);
};

/**
 * * Note: some form templates in the database have been created with `childrens` keys for nested child elements.
 * This has been fixed for all new form templates, but this function is here to ensure backwards compatibility until a database migration to fix historic data has been rectified.
 * It looks for any nested `childrens` keys and replaces them with `children`.
 */
export const withBackwardsCompatibleChildElements = (formOrTemplate: object) => {
  if (!formOrTemplate || typeof formOrTemplate !== 'object') return formOrTemplate;
  if (isArray(formOrTemplate)) return formOrTemplate.map((item) => withBackwardsCompatibleChildElements(item));

  Object.entries(formOrTemplate).forEach(([key, value]) => {
    if (key === 'childrens') {
      formOrTemplate['children'] = withBackwardsCompatibleChildElements(value);
      delete formOrTemplate['childrens'];
    } else if (typeof value === 'object') {
      formOrTemplate[key] = withBackwardsCompatibleChildElements(value);
    }
  });

  return formOrTemplate;
};

export const cloneElement = (element: IFormElement): IFormElement => {
  let newElementStr = JSON.stringify(element);
  const listAllIds = uniq(newElementStr.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g));
  listAllIds.forEach((id) => {
    newElementStr = newElementStr.replaceAll(id, `${id}-cloned`);
  });

  return withBackwardsCompatibleChildElements(JSON.parse(newElementStr));
};

export const getOriginalId = (id: string) => id?.replace(/-cloned/g, '');

export const getCloneLevel = (id: string) => id.match(/-cloned/g)?.length ?? 0;

export const mapFormValueToElementValue = (formValues) => {
  if (isEmpty(formValues)) return [];

  const values = keys(formValues)
    .filter((x) => {
      const [id] = x.split(',');
      return formValues[`${id},value`] && formValues[`${id},time`]
        ? x.endsWith(',value')
        : x.endsWith(',value') || x.endsWith(',time');
    })
    .map((key) => {
      const [id] = key.split(',');
      const isClonedElement = getCloneLevel(id) > 0;
      const elementId = id;
      const elementType = formValues[`${id},type`];
      const parentElementId = formValues[`${id},parentElementId`];
      const value = formValues[key];

      const result = {
        elementId,
        elementData: value,
        elementType: elementType,
        parentElementId,
        isClonedElement,
      };
      return result;
    });

  return values;
};

export const getAllFormElementsWithChildren = (formElements: IFormElement[]): IFormElement[] => {
  const result = [];
  formElements.forEach((element) => {
    const stack = [element];
    while (stack.length) {
      const parentEl = stack.pop();
      result.push(parentEl);
      if (parentEl.children?.length) {
        stack.push(...parentEl.children);
      }
    }
  });

  return result;
};

export const convertRulesRequiredOfBundle = (isRequire: boolean, rules: object[]) => {
  const newRules: object[] = cloneDeep(rules);

  newRules.forEach((rule) => {
    if (Object.keys(rule).includes('required')) {
      rule['required'] = isRequire;
    }
  });

  return newRules;
};
