import React, { Component } from 'react';
import { Form, Icon } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, Text, Title } from 'common-components/typography';
import { connect } from 'react-redux';
import { dispatch, IRootState, state } from 'stores/rematch/root-store';
import { ServiceType } from 'utilities/enum-utils';
import ServiceUtils from 'utilities/service-utils';
import { timeZone } from 'interfaces/timezone-type';
import PaymentSourceTagV2 from 'common-components/tags/PaymentSourceTagV2';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Header, Heading, Text as _Text } from '@good/components';


interface IServiceTypeStepPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
  serviceProviderId: string;
  timezone: timeZone;
  portalUser: typeof state.authStore.portalUser;
  setTotalSteps: (serviceType: ServiceType) => void;
  flags: Record<string, boolean>;
}

interface ISelectCustomerStepPanelState {}

class ServiceTypeStep extends Component<IServiceTypeStepPanelProps, ISelectCustomerStepPanelState> {
  state = {};

  private _GoToNextStepAndSetType = async (type) => {
    const { setNewService, onNextStep, timezone, serviceProviderId, setTotalSteps } = this.props;

    const resetedNewService = ServiceUtils.createNewEmptyService(serviceProviderId, timezone);
    setNewService({
      ...resetedNewService,
      serviceType: type,
    });
    setTotalSteps(type);
    onNextStep({ serviceType: type });
  };

  render() {
    const { flags } = this.props;

    return (
      <div className="anim-slide-left mb-x-large">
        <Header size="xl">
          <Heading>Create a new service</Heading>
          <_Text>Select the type of service you would like to create.</_Text>
        </Header>

        <div
          className="mt-x3-large pv-medium ph-large bordered shadow-box hover-bg-tertiary align-center flex-grow cursor-pointer flex-row rounded"
          onClick={() => this._GoToNextStepAndSetType(ServiceType.INDIVIDUAL)}
          onKeyDown={() => this._GoToNextStepAndSetType(ServiceType.INDIVIDUAL)}
          role="presentation"
        >
          <div className="width-full">
            <Title level={4}>Support service</Title>
            <Text>
              Service such as providing care workers for participants to assist them at home or in the community.
              <br />
              For Support Service, customer can book by choosing a time in an availability window set by the Service
              Provider.
            </Text>
          </div>
          <div style={{ width: '25px' }}>
            <Icon type="right" className="text-size-x3-large text-color-tertiary" />
          </div>
        </div>

        <div
          className="mt-x3-large pv-medium ph-large bordered shadow-box hover-bg-tertiary align-center flex-grow cursor-pointer flex-row rounded"
          onClick={() => this._GoToNextStepAndSetType(ServiceType.GROUP)}
          onKeyDown={() => this._GoToNextStepAndSetType(ServiceType.GROUP)}
          role="presentation"
        >
          <div className="width-full">
            <Title level={4}>Group service</Title>
            <Text>
              Services such as workshops, classrooms that are designed for groups.
              <br />
              For Group Service, customer can book available sessions created by the Service Provider.
            </Text>
          </div>
          <div style={{ width: '25px' }}>
            <Icon type="right" className="text-size-x3-large text-color-tertiary" />
          </div>
        </div>

        <div
          className="mt-x3-large pv-medium ph-large bordered shadow-box hover-bg-tertiary align-center flex-grow cursor-pointer flex-row rounded"
          onClick={() => this._GoToNextStepAndSetType(ServiceType.COORDINATION)}
          onKeyDown={() => this._GoToNextStepAndSetType(ServiceType.COORDINATION)}
          role="presentation"
        >
          <div className="width-full">
            <div className="align-center flex">
              <Title level={4}>Support coordination service</Title>
              <div style={{ marginBottom: '6px', marginLeft: '6px' }}>
                <PaymentSourceTagV2 paymentSource="NDIS" size="small" />
              </div>
            </div>

            <Text>Create a dedicated service to manage support coordination activities.</Text>
          </div>
          <div style={{ width: '25px' }}>
            <Icon type="right" className="text-size-x3-large text-color-tertiary" />
          </div>
        </div>
        {flags['pinc1511SilService'] && (
          <div
            className="mt-x3-large pv-medium ph-large bordered shadow-box hover-bg-tertiary align-center flex-grow cursor-pointer flex-row rounded"
            onClick={() => this._GoToNextStepAndSetType(ServiceType.INDEPENDENT_LIVING)}
            onKeyDown={() => this._GoToNextStepAndSetType(ServiceType.INDEPENDENT_LIVING)}
            role="presentation"
          >
            <div className="width-full">
              <div className="align-center flex">
                <Title level={4}>Supported independent living service</Title>
                <div className="mb-1.5 ml-1.5">
                  <PaymentSourceTagV2 paymentSource="NDIS" size="small" />
                </div>
              </div>

              <Text>Create a dedicated service for managing Supported independent living properties </Text>
            </div>
            <div style={{ width: '25px' }}>
              <Icon type="right" className="text-size-x3-large text-color-tertiary" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });

export const ServiceTypeStepPanel = connect(mapState)(
  Form.create<IServiceTypeStepPanelProps>()(withLDConsumer()(ServiceTypeStep)),
);
