import { PropsWithChildren, ReactNode } from 'react';
import { useTimelineContext } from './timeline-provider';
import React from 'react';

export const TopLeftStickyCell = ({ children }: { children?: ReactNode }) => {
  const { cellWidth } = useTimelineContext();
  return (
    <div
      className="sticky left-0 z-30 flex items-center border-b border-r border-gray-light-1 bg-white p-3"
      style={{
        minWidth: `${cellWidth}rem`,
      }}
    >
      {children}
    </div>
  );
};

export const LeftStickyCell = ({ children, isLastElement }: PropsWithChildren<{ isLastElement?: boolean }>) => {
  const { cellWidth } = useTimelineContext();
  return (
    <div
      className={`${isLastElement ? '' : 'border-b'} sticky left-0 z-30 border-r border-gray-light-1 bg-white p-3 `}
      style={{
        minWidth: `${cellWidth}rem`,
      }}
    >
      {children}
    </div>
  );
};

export const HeaderCell = ({ children }: { children?: ReactNode }) => {
  const { cellWidth, headerHeight } = useTimelineContext();
  return (
    <div
      className="flex items-center justify-center border-b border-gray-light-1"
      style={{ minWidth: `${cellWidth}rem`, height: `${headerHeight}rem` }}
    >
      {children}
    </div>
  );
};

export const Cell = ({ children }: { children?: ReactNode }) => {
  const { cellWidth } = useTimelineContext();
  return (
    <div className="border-l border-r border-gray-light-2" style={{ minWidth: `${cellWidth}rem` }}>
      {children}
    </div>
  );
};
