import { Col, Form, Input, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import UploadImage from 'common-components/upload/UploadImage';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ServiceType } from 'utilities/enum-utils';
import Utils from 'utilities/Utils';

interface IInformationStepPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  doFetchServiceDepartmentsLite: typeof dispatch.servicesStore.doFetchServiceDepartmentsLite;
  serviceDepartmentList: typeof state.servicesStore.serviceDepartmentListLite;
  newService: typeof state.servicesStore.newService;
}

interface IInformationStepPanelState {
  bannerUrl: { attachmentUrl: string; attachmentPath: string };
}

class InformationStepPanel extends Component<IInformationStepPanelProps, IInformationStepPanelState> {
  state = {
    bannerUrl: this.props.newService.bannerUrl
      ? this.props.newService.bannerUrl
      : { attachmentUrl: '', attachmentPath: '' },
  };

  _onSuccessUploadImage = (item) => {
    this.setState({ bannerUrl: { attachmentUrl: item.url, attachmentPath: item.urlPath } });
  };

  private _validateServiceName = (rule, value, callback) => {
    try {
      if (Utils.isEmpty(value.trim())) {
        throw Error('Please enter a name.');
      }
    } catch (e) {
      callback(e);
      return;
    }

    callback();
  };

  private _validateServiceDescription = (rule, value, callback) => {
    try {
      if (Utils.isEmpty(value.trim())) {
        throw Error('Please enter a description.');
      }
    } catch (e) {
      callback(e);
      return;
    }

    callback();
  };

  private _onRemoveImage = () => {
    this.setState({ bannerUrl: { attachmentUrl: '', attachmentPath: '' } });
  };

  private _onNextStep = async () => {
    const { form, setNewService, onNextStep, newService } = this.props;
    const { bannerUrl } = this.state;

    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      let serviceName = form.getFieldValue('serviceName');
      let serviceDescription = form.getFieldValue('serviceDescription');
      let serviceDepartmentId = form.getFieldValue('serviceDepartment');

      await setNewService({ ...newService, serviceName, serviceDescription, bannerUrl, serviceDepartmentId });
      onNextStep();
    }
  };

  componentDidMount = async () => {
    await this.props.doFetchServiceDepartmentsLite({});
  };

  render() {
    const { onPreviousStep, newService, serviceDepartmentList, form } = this.props;
    const { getFieldDecorator } = form;
    const isCoordinationService = newService?.serviceType === ServiceType.COORDINATION;

    return (
      <div className="anim-slide-left mb-x-large">
        <Title level={2} weight="bolder" className="line-height-100">
          {isCoordinationService ? 'Add basic service info' : 'General Information'}
        </Title>

        <Paragraph>
          {isCoordinationService ? 'Enter a few details about this service.' : 'Tell us about this service.'}
        </Paragraph>

        <Row className="mt-x2-large mb-large">
          <Col span={4} style={{ marginTop: '8px' }}>
            <SubTitle>Service Name</SubTitle>
          </Col>
          <Col span={20}>
            <Form.Item className={'m-none'}>
              {getFieldDecorator('serviceName', {
                initialValue: newService.serviceName,
                rules: [{ validator: this._validateServiceName }],
              })(
                <Input placeholder={'Create a name'} maxLength={50} className="width-4/5" style={{ height: '40px' }} />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-large">
          <Col span={4} style={{ marginTop: '8px' }}>
            <SubTitle>Description</SubTitle>
          </Col>
          <Col span={20}>
            <Form.Item className={'m-none'}>
              {getFieldDecorator('serviceDescription', {
                initialValue: newService.serviceDescription,
                rules: [{ validator: this._validateServiceDescription }],
              })(
                <Input.TextArea
                  placeholder="Tell people a bit about this service..."
                  style={{ minWidth: '500px', minHeight: '200px' }}
                  className="width-4/5"
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-large">
          <Col span={4} style={{ marginTop: '8px' }}>
            <SubTitle>Service Department</SubTitle>
          </Col>
          <Col span={20}>
            <Form.Item className={'m-none'}>
              {getFieldDecorator('serviceDepartment', {
                initialValue: newService.serviceDepartmentId,
                rules: [{ required: true, message: 'Please select a service department.' }],
              })(
                <Select placeholder="Select a service department." size={'large'} className={'width-1/2'}>
                  {_.map(_.orderBy(serviceDepartmentList, 'serviceDepartmentName'), (department) => {
                    return (
                      <Select.Option value={department.serviceDepartmentId}>
                        {department.serviceDepartmentName}
                      </Select.Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-x2-large">
          <Col span={4} style={{ marginTop: '8px' }}>
            <SubTitle>Banner image</SubTitle>
            <Text color="secondary" size="small">
              (Optional)
            </Text>
          </Col>
          <Col span={20}>
            {this.state.bannerUrl.attachmentUrl ? (
              <>
                <UploadImage
                  style={{
                    objectFit: 'cover',
                    padding: '5px',
                    marginRight: '8px',
                    borderRadius: '4px',
                  }}
                  url={this.state.bannerUrl.attachmentUrl}
                  onFinishUpload={this._onSuccessUploadImage}
                  uploadPath={`services/${newService.serviceId}`}
                />
                <HyperlinkButton className="block" color="red" onClick={this._onRemoveImage}>
                  Remove Image
                </HyperlinkButton>
              </>
            ) : (
              <UploadImage
                style={{
                  objectFit: 'cover',
                  padding: '5px',
                  marginRight: '8px',
                  borderRadius: '4px',
                }}
                url={null}
                onFinishUpload={this._onSuccessUploadImage}
                uploadPath={`services/${newService.serviceId}`}
              />
            )}
            <Text color="secondary">Square images in JPEG or PNG formats work best - max file size 250MB.</Text>
          </Col>
        </Row>

        <div className="flex-row align-center mv-x2-large">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left" className="mr-large">
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this._onNextStep} icon="right" iconPosition="right">
            Next
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  serviceDepartmentList: state.servicesStore.serviceDepartmentListLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServiceDepartmentsLite: dispatch.servicesStore.doFetchServiceDepartmentsLite,
});

export default connect(mapState, mapDispatch)(Form.create<IInformationStepPanelProps>()(InformationStepPanel));
