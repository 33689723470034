import React, { Component } from 'react';
import _ from 'lodash';
import { notification } from 'antd';
import { ActivityGroupModalType, ActivityGroupMemberType } from 'utilities/enum-utils';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { IGroupServiceActivityGroup } from 'interfaces/service-interfaces';
import { Paragraph } from 'common-components/typography';
import { IActivityGroupUsers } from 'src/interfaces/user-interfaces';

interface IRemoveModalContentProps {
  serviceId: string;
  activityGroup: IGroupServiceActivityGroup;
  member: IActivityGroupUsers;
  memberType: ActivityGroupMemberType;
  modalType: ActivityGroupModalType;
  ignoredAlert?: boolean;
  handleAction: (payload, actionType: ActivityGroupModalType) => Promise<boolean | void>;
  onCloseViewModal: () => void;
}

interface IRemoveModalContentState {
  isLoading: boolean;
}

interface IRemoveModalContentPayload {
  serviceId: string;
  serviceActivityGroupId: string;
  memberType: ActivityGroupMemberType;
  userId?: string;
}

const INITIAL_STATE: IRemoveModalContentState = {
  isLoading: false,
};

class RemoveModalContent extends Component<IRemoveModalContentProps, IRemoveModalContentState> {
  state = _.cloneDeep(INITIAL_STATE);

  private _renderModalDescription = (modalType: ActivityGroupModalType): React.ReactNode => {
    const { member, memberType, activityGroup } = this.props;
    const membersText = memberType === ActivityGroupMemberType.CUSTOMER ? 'customers' : 'team members';
    let description = <></>;

    switch (modalType) {
      case ActivityGroupModalType.REMOVE_ALL:
        description = (
          <Paragraph>
            You&apos;re about to remove <b>all {membersText}</b> from <b>{activityGroup.name}</b>
          </Paragraph>
        );
        break;
      case ActivityGroupModalType.REMOVE:
        description = (
          <Paragraph>
            You&apos;re about to remove{' '}
            <b>
              {member.firstName} {member.lastName}
            </b>{' '}
            from <b>{activityGroup.name}</b>.
          </Paragraph>
        );
        break;
      default:
        description = <></>;
        break;
    }

    return <>{description}</>;
  };

  private _generatePrimaryButtonText = (
    modalType: ActivityGroupModalType,
    memberType: ActivityGroupMemberType,
  ): string => {
    let text = 'Remove';
    switch (modalType) {
      case ActivityGroupModalType.REMOVE_ALL:
        text += ActivityGroupMemberType.CUSTOMER === memberType ? ' all customers' : ' all team members';
        break;
      case ActivityGroupModalType.REMOVE:
        text += ActivityGroupMemberType.CUSTOMER === memberType ? ' customer' : ' team member';
        break;
      default:
        text = '';
        break;
    }

    return text;
  };

  private _handleRemove = async () => {
    const { serviceId, activityGroup, member, memberType, modalType, handleAction, onCloseViewModal } = this.props;
    if (
      (_.isEmpty(activityGroup.customers) && memberType === ActivityGroupMemberType.CUSTOMER) ||
      (_.isEmpty(activityGroup.teamMembers) && memberType === ActivityGroupMemberType.TEAM_MEMBER)
    ) {
      if (!this.props.ignoredAlert) {
        const memberTypeString = memberType.replaceAll(/_/g, ' ').toLowerCase();
        notification.error({
          message: `There is no ${memberTypeString} in this activity group`,
        });
      }
      onCloseViewModal();
      return;
    }

    this.setState({ isLoading: true });

    try {
      let payload: IRemoveModalContentPayload = {
        serviceId,
        serviceActivityGroupId: activityGroup.serviceActivityGroupId,
        memberType,
      };

      if (modalType === ActivityGroupModalType.REMOVE) {
        payload = { ...payload, userId: member.userId };
      }

      await handleAction(payload, modalType);
      if (!this.props.ignoredAlert) {
        switch (modalType) {
          case ActivityGroupModalType.REMOVE_ALL:
            notification.success({
              message: `${ActivityGroupMemberType.CUSTOMER === memberType ? 'Customers' : 'Team members'} removed`,
              description: `You have successfully removed all ${
                ActivityGroupMemberType.CUSTOMER === memberType ? 'customers' : 'team members'
              } from an "${activityGroup.name}"`,
            });
            break;
          case ActivityGroupModalType.REMOVE:
            notification.success({
              message: `${ActivityGroupMemberType.CUSTOMER === memberType ? 'Customer' : 'Team member'} removed`,
              description: `You have successfully removed ${member.firstName} ${member.lastName} from an "${activityGroup.name}"`,
            });
            break;
          default:
            break;
        }
      }
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong! Please try again.',
      });
    }

    this.setState({ isLoading: false });
    onCloseViewModal();
  };

  render() {
    const { modalType, memberType, onCloseViewModal } = this.props;
    const { isLoading } = this.state;

    return (
      <>
        {this._renderModalDescription(modalType)}
        <ActionModalFooter className="flex-row">
          <div className="flex flex-grow"></div>
          <SecondaryButton color="secondary" size="large" onClick={onCloseViewModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            color="red"
            className="ml-medium"
            size="large"
            loading={isLoading}
            onClick={this._handleRemove}
          >
            {this._generatePrimaryButtonText(modalType, memberType)}
          </PrimaryButton>
        </ActionModalFooter>
      </>
    );
  }
}

export default RemoveModalContent;
