import { HyperlinkButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { ISubscriptionStatusInfo } from 'interfaces/subscription-interfaces';
import moment from 'moment';
import React from 'react';
import { SubscriptionStatus } from 'utilities/enum-utils';
import { CANCEL_SUBSCRIPTION_LINKS } from '../subscription-management/utils/constants';

interface IProps {
  isSubscriptionScreen?: boolean;
  timezone: string;
  daysLeftLabel: string;
  subscriptionStatusInfo: ISubscriptionStatusInfo;
  hasSubscription?: boolean;
  hasPermission?: boolean;
}

const DescriptionSection = (props: IProps) => {
  const { isSubscriptionScreen, daysLeftLabel, subscriptionStatusInfo, timezone, hasPermission, hasSubscription } =
    props;
  const subscriptionStatus = subscriptionStatusInfo?.subscriptionStatus;

  if (!isSubscriptionScreen)
    return (
      <Text size="large">
        {hasSubscription ? (
          `Manage your organisation’s GoodHuman subscription, payment details and billing contacts. ${
            !hasPermission ? 'Contact your workspace’s primary owner to be granted access to this area.' : ''
          }`
        ) : (
          <Text>
            For questions about your account, contact us at{' '}
            <HyperlinkButton onClick={() => window.open('mailto:support@goodhuman.me')}>
              support@goodhuman.me
            </HyperlinkButton>
            .
          </Text>
        )}
      </Text>
    );

  switch (subscriptionStatus) {
    case SubscriptionStatus.TRIALING:
      return (
        <div className="flex align-baseline">
          <Text size="x4-large" color="forest" className="mr-x-small">
            {daysLeftLabel}
          </Text>
          <Text style={{ fontSize: 18 }}> remaining</Text>
        </div>
      );

    case SubscriptionStatus.ACTIVE:
      return <Text size="large">Enjoy full access to the GoodHuman workspace, business app and customer app.</Text>;

    case SubscriptionStatus.PAUSED:
      const formattedUntilDate = moment.tz(subscriptionStatusInfo?.untilDate, timezone).format('D MMM YYYY');
      return (
        <Text size="large">
          Your subscription has been cancelled. You can still access GoodHuman until&nbsp;
          <Text weight="bolder">{formattedUntilDate}.</Text>
        </Text>
      );

    case SubscriptionStatus.CANCELED:
      return (
        <Text size="large">
          Your subscription has been cancelled.{' '}
          <HyperlinkButton onClick={() => window.open(`mailto:${CANCEL_SUBSCRIPTION_LINKS.SUPPORT_MAIL_LINK}`)}>
            Contact us{' '}
          </HyperlinkButton>
          if you think this is a mistake.
        </Text>
      );
  }
};

export default DescriptionSection;
