import React, { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  AlertCircle,
  Book,
  CalendarPerson,
  Elements4Circle,
  Receipt,
  SignOut,
  SlidersHoriz,
  UserCircle,
  Users,
} from '@good/icons';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import PermissionUtils from 'utilities/permission-utils';
import type { NavLinkItem, NavSection } from '../types';
import { useLogout } from 'utilities/react-hooks/use-logout';

export function useNavBarSections(): NavSection[] {
  const portalUser = usePortalUser();
  const logout = useLogout();
  const { boom421RosteringTimelineView }: { boom421RosteringTimelineView: boolean } = useFlags();

  return useMemo(() => {
    const userPermissions = portalUser?.permissions.permissionRoles ?? [];

    const navBarSections: NavSection[] = [
      {
        title: 'Navigation',
        items: [
          {
            link: '/bookings',
            label: 'Bookings',
            icon: <Book />,
            permissions: ['ViewBookingListing'],
          },
          {
            link: '/rostering',
            label: 'Rostering',
            icon: <CalendarPerson />,
            permissions: [],
            hidden: !boom421RosteringTimelineView,
          },
          {
            link: '/customers',
            label: 'Customers',
            icon: <UserCircle />,
            permissions: ['ViewCustomerListing'],
            matchPath: ['/customer'],
          },
          {
            link: '/services',
            label: 'Services',
            icon: <Elements4Circle />,
            permissions: ['ViewServiceListing'],
            matchPath: ['/service', '/group-service'],
          },
          {
            link: '/group-service/sessions',
            label: 'Group sessions',
            icon: <Book />,
            permissions: ['ViewGroupSessions'],
          },
          {
            link: '/payments',
            label: 'Payments',
            icon: <Receipt />,
            permissions: ['ViewPayments'],
          },
        ],
        key: 'navigation',
      },
      {
        title: 'Organisation',
        items: [
          {
            link: '/team',
            label: 'Team',
            icon: <Users />,
            permissions: ['ViewTeamListing'],
          },
          {
            link: '/workflows',
            label: 'Incidents',
            icon: <AlertCircle />,
            permissions: [],
          },
          {
            link: '/account/landing',
            label: 'Account',
            icon: <SlidersHoriz />,
            permissions: ['ViewTeamManagement'],
            matchPath: ['/account'],
          },
        ],
        key: 'organisation',
      },
      {
        items: [
          {
            link: '#',
            label: 'Sign Out',
            icon: <SignOut />,
            permissions: [],
            onClick: logout,
            color: 'red',
          },
        ],
        key: 'sign-out',
      },
    ];

    const shouldDisplayItem = (item: NavLinkItem) => {
      return (
        !item.hidden &&
        item.permissions.every((permission) => {
          return PermissionUtils.validatePermission(permission, userPermissions);
        })
      );
    };

    return navBarSections.map((section) => {
      return {
        ...section,
        items: section.items.filter(shouldDisplayItem),
      };
    });
  }, [portalUser, boom421RosteringTimelineView, logout]);
}

export function useNavBarItems(): NavLinkItem[] {
  const navBarSections = useNavBarSections();
  return navBarSections.flatMap((section) => section.items);
}
