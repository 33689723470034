import styled, { CSSObject } from 'styled-components';

const gaps = {
  none: '0px',
  xs: '2px',
  sm: '4px',
  md: '8px',
  lg: '12px',
  xl: '16px',
  xxl: '24px',
};

const dirs = {
  row: 'row',
  column: 'column',
};

export const Stack = styled.div<{ dir?: keyof typeof dirs; gap?: keyof typeof gaps }>((props) => {
  let gap = gaps[props.gap] || 'none';
  let flexDirection = dirs[props.dir] || 'column';

  return {
    display: 'flex',
    flexDirection,
    gap,
  } as CSSObject;
});
