import React, { Component } from 'react';
import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { BookingErrorType } from 'utilities/enum-utils';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import BookingErrorBanner from 'common-components/alerts/BookingErrorBanner';

interface IBookingWarningProps {
  selectedBookingItem: any;
  bookingError: { bookingErrorType: BookingErrorType; number?: number };
  serviceType: string;
  doIgnoreBookingWarning: typeof dispatch.bookingsStore.doIgnoreBookingWarning;
  doIgnoreGroupBookingWarning: typeof dispatch.groupBookingsStore.doIgnoreGroupBookingWarning;
}

class Warning extends Component<{
  warningMessage: string;
  hasButton: boolean;
  onClick?: () => Promise<void>;
  buttonText?: string;
}> {
  render() {
    return (
      <div
        style={{
          width: '100%',
          backgroundColor: 'rgba(255,242,229,0.5)',
          border: '1px solid #FFB366',
          borderRadius: 8,
          verticalAlign: 'top',
        }}
        className="p-medium flex justify-start mt-medium"
      >
        <div className="mt-x2-small">
          <Icon type="warning" theme="filled" className="text-color-orange-lighter text-size-x2-large" />
        </div>
        <div className="ml-small">
          <div>
            <Text className="text-color-orange-lighter" style={{ verticalAlign: 'top' }}>
              Warning
            </Text>
          </div>
          <div>{this.props.warningMessage}</div>
          {this.props.hasButton && (
            <div className="mt-medium">
              <HyperlinkButton onClick={this.props.onClick}>{this.props.buttonText}</HyperlinkButton>
            </div>
          )}
        </div>
      </div>
    );
  }
}

class BookingWarning extends Component<IBookingWarningProps, any> {
  private _onClickIgnoreWarning = async () => {
    const { bookingError, selectedBookingItem, doIgnoreBookingWarning, serviceType, doIgnoreGroupBookingWarning } =
      this.props;
    if (serviceType === 'GROUP') {
      await doIgnoreGroupBookingWarning({
        bookingErrorType: bookingError.bookingErrorType,
        bookingId: selectedBookingItem.bookingId,
      });
    } else {
      await doIgnoreBookingWarning({
        bookingErrorType: bookingError.bookingErrorType,
        bookingId: selectedBookingItem.bookingId,
      });
    }
  };

  render() {
    const { bookingError } = this.props;

    switch (bookingError.bookingErrorType) {
      case BookingErrorType.PaymentMethodError:
        return (
          <BookingErrorBanner
            errorMessage={`${bookingError.number} line item${bookingError.number > 1 ? 's do' : ' does'} not have a
          specified management method.`}
          />
        );
      case BookingErrorType.BookingRecordedTimeWarning:
        return (
          <Warning
            warningMessage={"The worker's recorded start/finish time is different to the scheduled time."}
            hasButton={true}
            buttonText={'Ignore warning'}
            onClick={this._onClickIgnoreWarning}
          />
        );
      case BookingErrorType.BookingActualTimeWarning:
        return (
          <Warning
            warningMessage={"The worker's actual start/finish time is different to the scheduled time."}
            hasButton={true}
            buttonText={'Ignore warning'}
            onClick={this._onClickIgnoreWarning}
          />
        );
      case BookingErrorType.FundingPackageWarning:
        return (
          <Warning
            warningMessage={`${bookingError.number} line item${
              bookingError.number > 1 ? 's are' : ' is'
            } not part of the customer's
                funding package for the dates of this booking`}
            hasButton={true}
            buttonText={'Ignore warning'}
            onClick={this._onClickIgnoreWarning}
          />
        );

      case BookingErrorType.ServiceAgreementWarning:
        return (
          <Warning
            warningMessage={"This service is not part of the customer's service agreement for the dates of the booking"}
            hasButton={true}
            buttonText={'Ignore warning'}
            onClick={this._onClickIgnoreWarning}
          />
        );

      case BookingErrorType.ServiceAgreementLineItemWarning:
        return (
          <Warning
            warningMessage={`${bookingError.number} line item${
              bookingError.number > 1 ? 's are' : ' is'
            } not part of the customers service agreement for the dates of this booking`}
            hasButton={true}
            buttonText={'Ignore warning'}
            onClick={this._onClickIgnoreWarning}
          />
        );

      case BookingErrorType.SleepoverConflictBookingTime:
        return (
          <BookingErrorBanner
            errorMessage={`Sleepover period must occur within the booking time. Please correct the sleepover time.
              `}
            errorTitle={'Sleepover period outside booking time frame'}
          />
        );
      case BookingErrorType.SleepoverConflictCheckinoutTime:
        return (
          <BookingErrorBanner
            errorMessage={`The sleepover period entered isn't within the team member's start and finish times. Please correct the sleepover time to approve this booking for payment.`}
            errorTitle={`Sleepover period outside team member's start and finish time`}
          />
        );
      default:
        return <></>;
    }
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doIgnoreBookingWarning: dispatch.bookingsStore.doIgnoreBookingWarning,
  doIgnoreGroupBookingWarning: dispatch.groupBookingsStore.doIgnoreGroupBookingWarning,
});

export default connect(null, mapDispatch)(BookingWarning);
