import { Popover2 } from '@blueprintjs/popover2';
import clsx from 'clsx';
import React from 'react';
import { ActionMenu, ActionMenuItem } from '../../../common-components/action-menu';
import { IconButton } from '../../../common-components/buttons';
import { IFormListResponse } from '../../form-builder/shared/form-interface';
import { FormActions, FormStatus } from '../../../utilities/enum-utils';

interface IActionButtonProps {
  formItem: IFormListResponse;
  handleSelection: (formItem: IFormListResponse, action?: FormActions) => void;
}

const actionsForFormStatus: Record<FormStatus, FormActions[]> = {
  [FormStatus.ACTIVE]: [FormActions.EDIT, FormActions.COPY, FormActions.ARCHIVE],
  [FormStatus.DRAFT]: [FormActions.EDIT, FormActions.COPY],
  [FormStatus.ARCHIVED]: [FormActions.RE_ACTIVATE, FormActions.COPY],
  [FormStatus.INUSE]: [FormActions.COPY],
};

function actionsFor(formItem: IFormListResponse): FormActions[] {
  if (formItem.isUsedInActiveWorkFlow) return [FormActions.COPY];
  return actionsForFormStatus[formItem.status];
}

const FormManagementActionButton = ({ formItem, handleSelection }: IActionButtonProps) => {
  const formActions = actionsFor(formItem);
  if (!formActions.length) return null;

  const actionMenu = (
    <ActionMenu>
      {formActions.map((action) => (
        <ActionMenuItem
          text={action}
          key={action}
          className={clsx(action === FormActions.ARCHIVE && 'text-color-red-dark')}
          onClick={() => handleSelection(formItem, action)}
        />
      ))}
    </ActionMenu>
  );

  return (
    <Popover2 content={actionMenu} position="bottom-right" interactionKind="click">
      <IconButton
        icon="ellipsis"
        size="small"
        className="bg-white"
        bordered
        iconColor="primary"
        data-testid="form-actions"
      />
    </Popover2>
  );
};

export default FormManagementActionButton;
