import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const BookmarkPlus = forwardRef(function BookmarkPlus(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2.25h-.055c-1.367 0-2.47 0-3.337.117-.9.12-1.658.38-2.26.981-.601.602-.86 1.36-.981 2.26-.117.867-.117 1.97-.117 3.337V21a.75.75 0 0 0 1.122.651l5.016-2.866c.497-.284.823-.47 1.091-.59.253-.113.398-.142.521-.142s.269.03.521.142c.268.12.595.306 1.092.59l5.015 2.866A.75.75 0 0 0 19.75 21V8.945c0-1.367 0-2.47-.116-3.337-.121-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117H11ZM6.41 4.409c.276-.277.664-.457 1.398-.556.756-.101 1.757-.103 3.192-.103h2c1.435 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4.102.754.103 1.756.103 3.191v10.708l-3.893-2.225-.03-.017c-.46-.263-.85-.486-1.192-.64-.365-.163-.727-.273-1.135-.273-.408 0-.77.11-1.135.274-.342.153-.733.376-1.192.639l-.03.017-3.893 2.225V9c0-1.435.002-2.437.103-3.192.099-.734.28-1.122.556-1.399ZM12.75 8a.75.75 0 0 0-1.5 0v1.75H9.5a.75.75 0 0 0 0 1.5h1.75V13a.75.75 0 0 0 1.5 0v-1.75h1.75a.75.75 0 0 0 0-1.5h-1.75V8Z"
        fill="currentColor"
      />
    </Icon>
  );
});
