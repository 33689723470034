import React, { Component } from 'react';
import { Form } from 'antd';
import { Text, Title } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';

// Panels
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

import AddCustomerStepCustomerDetail from 'views/customers/listing/components/wizard-steps/AddCustomerStepCustomerDetail';
import AddCustomerStepDuplicateCheck from 'views/customers/listing/components/wizard-steps/AddCustomerStepDuplicateCheck';
import AddCustomerStepAddLegalGuardian from 'views/customers/listing/components/wizard-steps/AddCustomerStepAddLegalGuardian';
import AddCustomerStepSuccess from 'views/customers/listing/components/wizard-steps/AddCustomerStepSuccess';
import _ from 'lodash';
import { IMatchingUser } from 'interfaces/customer-interfaces';
import { CustomerDetailsTabKey } from 'utilities/enum-utils';

interface IAddNewCustomerWizardModalProps extends FormComponentProps {
  closeAddCustomerModal: (serviceData?: any) => void;
  setAddCustomerDetailWizard?: typeof dispatch.customersStore.setAddCustomerDetailWizard;
  setAddCustomerDetailWizardState: typeof dispatch.customersStore.setAddCustomerDetailWizardState;
  addCustomerDetailWizard: typeof state.customersStore.addCustomerDetailWizard;
  adminList?: typeof state.teamStore.adminList;
  isOpen: boolean;
  history: any;
  authStore: typeof state.authStore;
}

interface IAddNewCustomerWizardModalState {
  step: number;
  totalStep: number;
  isProceedOpen: boolean;
  customerFuzzyMatchingList: IMatchingUser[];
}

const steps = [
  { stepNumber: 1, stepLabel: 1, stepTitle: 'Customer details' },
  { stepNumber: 3, stepLabel: 2, stepTitle: 'Legal guardians' },
  { stepNumber: 4, stepLabel: 3, stepTitle: 'Done!' },
];

class AddNewCustomerWizardModal extends Component<IAddNewCustomerWizardModalProps, IAddNewCustomerWizardModalState> {
  private addCustomerDetailRef: any = null;

  state = {
    step: 1,
    totalStep: 4,
    isProceedOpen: false,
    customerFuzzyMatchingList: [],
  };

  private _setRef = (ref) => {
    if (ref) {
      this.addCustomerDetailRef = ref;
    }
  };

  private _onNextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  private _onCheckDuplicate = (matchesFound: boolean, customerFuzzyMatchingList: IMatchingUser[]) => {
    this.setState({
      step: matchesFound ? 2 : 3,
      customerFuzzyMatchingList: matchesFound ? customerFuzzyMatchingList : [],
    });
  };

  private _onPreviousStep = () => {
    const { step, customerFuzzyMatchingList } = this.state;
    this.setState({ step: step === 3 && _.isEmpty(customerFuzzyMatchingList) ? 1 : step - 1 });
  };

  private _addNewCustomer = async () => {
    const { setAddCustomerDetailWizard, setAddCustomerDetailWizardState } = this.props;
    setAddCustomerDetailWizardState({ isManualAddress: false, addressLookup: '' });
    await setAddCustomerDetailWizard({});
    this.setState({ step: 1 });
  };

  private _goToCustomer = async (userId, selectedTab?: CustomerDetailsTabKey) => {
    const { history } = this.props;
    history.push(`/customer/details/${userId}`, { selectedTab });
  };

  private _closeAddNewCustomerModal = async () => {
    let hasUnsavedData = false;

    if (!_.isEmpty(this.addCustomerDetailRef) && this.state.step !== 4) {
      const values = this.addCustomerDetailRef.props.form.getFieldsValue();

      hasUnsavedData = !_.isEmpty(values.firstName) || !_.isEmpty(values.lastName);
    }

    if (hasUnsavedData) {
      this.setState({ isProceedOpen: true });
    } else {
      this._resetAndCloseModal();
    }
  };

  private _resetAndCloseModal = () => {
    this.props.setAddCustomerDetailWizard([]);
    this.props.setAddCustomerDetailWizardState({ isManualAddress: false, addressLookup: '' });
    this.setState({ isProceedOpen: false, step: 1 });
    this.props.closeAddCustomerModal();
  };

  private _renderStepContent = () => {
    const { history } = this.props;
    const { step } = this.state;
    if (step === 1) {
      return (
        <AddCustomerStepCustomerDetail onCheckDuplicate={this._onCheckDuplicate} wrappedComponentRef={this._setRef} />
      );
    } else if (step === 2) {
      return (
        <AddCustomerStepDuplicateCheck
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          goToCustomer={this._goToCustomer}
          customerFuzzyMatchingList={this.state.customerFuzzyMatchingList}
          history={history}
        />
      );
    } else if (step === 3) {
      return <AddCustomerStepAddLegalGuardian onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />;
    } else if (step === 4) {
      return <AddCustomerStepSuccess goToCustomer={this._goToCustomer} addNewCustomer={this._addNewCustomer} />;
    }
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedOpen: false });
  };

  componentDidMount() {
    this.props.setAddCustomerDetailWizard([]);
    this.props.setAddCustomerDetailWizardState({ isManualAddress: false, addressLookup: '' });
  }

  render() {
    let headerTitle = (
      <Title level={2} className="m-none">
        Add customer
      </Title>
    );

    return (
      <>
        <ActionModal
          isOpen={this.state.isProceedOpen}
          onClose={this._closeProceedModal}
          title={'Cancel customer creation'}
          showCloseButton={true}
        >
          <div className={'mb-medium'}>
            <Text>Are you sure you want to cancel?</Text>
          </div>
          <div>
            <Text>The data you have entered will not be saved.</Text>
          </div>

          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
              Back
            </PrimaryButton>
            <SecondaryButton size="large" onClick={this._resetAndCloseModal}>
              Cancel
            </SecondaryButton>
          </ActionModalFooter>
        </ActionModal>
        <FullScreenScrollableModal
          isOpen={this.props.isOpen}
          onClose={this._closeAddNewCustomerModal}
          width={'full'}
          headerTitle={headerTitle}
          headerStyle="pt-x2-large pl-x3-large"
          bgColor="tertiary"
          canCloseEsc={false}
          fixedHeight={false}
          noMarginBottom={true}
          hasFooter={false}
          contentPadding={false}
          steps={steps}
          hasSteps={true}
          currentStep={this.state.step}
          stepStyle={'ph-x3-large pt-medium'}
        >
          {this._renderStepContent()}
        </FullScreenScrollableModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  adminList: state.teamStore.adminList,
  authStore: state.authStore,
  addCustomerDetailWizard: state.customersStore.addCustomerDetailWizard,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddCustomerDetailWizard: dispatch.customersStore.setAddCustomerDetailWizard,
  setAddCustomerDetailWizardState: dispatch.customersStore.setAddCustomerDetailWizardState,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IAddNewCustomerWizardModalProps>()(AddNewCustomerWizardModal));
