import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';
import { shadowValues } from 'design-components/theme/tokens';

export const reset: CSS = {
  margin: 0,
  padding: 0,
  outline: 'none',
  borderStyle: 'none',
};

export const container: CSS = {
  width: '100%',
};

export const charactersTotal: CSS = {
  display: 'flex',
  justifyContent: 'flex-end',
  color: '$textSecondary',

  marginTop: '$space115',
  fontSize: '$small',
};

export const textField: CSS = {
  '&:disabled': {
    color: '$bodyDark2 !important',
    cursor: 'inherit !important',
  },
  width: '100%',
  border: 'none',
  fontFamily: '$primary',
  color: '$black',
  backgroundColor: 'inherit',
  fontSize: 'inherit',
  fontWeight: '$normal',
  lineHeight: '$leading400',
};

export const wrapper: CSS = {
  // kind
  display: 'flex',
  alignItems: 'center',
  fontSize: '$small',

  boxSizing: 'border-box',
  cursor: 'text',

  padding: '$space150 $space200',
  lineHeight: '$leading400',
};

export const prefixComponent = css({
  lineHeight: '100%',
  color: '$bodyDark1',

  svg: {
    width: '$size250',
    height: '$size250',
  },
});

export const suffixComponent: CSS = {
  marginLeft: '$space125',
  lineHeight: '100%',
  color: '$bodyDark1',

  svg: {
    width: '$size250',
    height: '$size250',
  },
};

export const wrapperVariants = css({
  backgroundColor: '$white',
  color: '$black',

  borderRadius: '$small',
  borderWidth: '$border100',
  borderStyle: 'solid',
  borderColor: '$neutral',

  variants: {
    isHovered: {
      true: {
        borderColor: 'transparent',
        boxShadow: shadowValues.hoverShadow,
      },
    },
    isFocused: {
      true: {
        borderColor: 'transparent',
        boxShadow: shadowValues.activeShadow,
      },
    },
    isPressed: {
      true: {
        borderColor: 'transparent',
        boxShadow: shadowValues.activeShadow,
      },
    },
    isErrored: {
      true: {
        borderColor: 'transparent',
        boxShadow: shadowValues.errorShadow,
      },
    },
    isDisabled: {
      true: {
        borderColor: 'transparent !important',
        outline: 'none !important',
        backgroundColor: '$bodyLight2 !important',
        cursor: 'not-allowed !important',
        boxShadow: 'none !important',
      },
    },
  },
});

export type WrapperVariants = Stitches.VariantProps<typeof wrapperVariants>;
