import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';

export const useFirebaseStorage = () => {
  const [storage] = useState(() => getStorage());

  const uploadFile = async (file: File, filePath: string) => {
    const storageRef = ref(storage, filePath);
    return await uploadBytes(storageRef, file);
  };

  const getFileUrl = async (filePath: string) => await getDownloadURL(ref(storage, filePath));

  const uploadImage = async (file: File, filePath: string) => {
    const uploadPath = `${filePath}/${uuid()}/${file.name}`;
    const uploadResponse = await uploadFile(file, uploadPath);

    return { url: await getFileUrl(uploadResponse.metadata.fullPath), response: uploadResponse };
  };

  return { uploadFile, getFileUrl, uploadImage };
};
