import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const UserAlert = forwardRef(function UserAlert(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 7a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0ZM10 2.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5ZM20.75 5a.75.75 0 0 0-1.5 0v5a.75.75 0 0 0 1.5 0V5ZM7 15.75A3.25 3.25 0 0 0 3.75 19c0 .69.56 1.25 1.25 1.25h10c.69 0 1.25-.56 1.25-1.25A3.25 3.25 0 0 0 13 15.75H7ZM2.25 19A4.75 4.75 0 0 1 7 14.25h6A4.75 4.75 0 0 1 17.75 19 2.75 2.75 0 0 1 15 21.75H5A2.75 2.75 0 0 1 2.25 19ZM20 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
