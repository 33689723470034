import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { GhostButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Spinner } from '@blueprintjs/core';
import { Avatar, Col, notification, Row } from 'antd';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import Search from 'antd/es/input/Search';
import CommonUtils from 'utilities/common-utils';
import moment from 'moment-timezone';

interface IBookingBulkEndBookingModalProps {
  isOpen: boolean;
  selectedItems: any;
  onClose: (refreshListing?: boolean) => void;
  doFinishGroupBooking: typeof dispatch.groupBookingsStore.doFinishGroupBooking;
  selectedSession: typeof state.groupServiceStore.selectedSession;
}

interface IBookingBulkEndBookingModalState {
  canManuallyClose: boolean;
  step: number;
  title: string;
  isLoading: boolean;
  isEditCustomerTimesOpen: boolean;
  customersTimes: any;
  selectedCustomer: any;
  selectedCustomerTime: any;
  searchString: string;
}

class BookingBulkEndBookingModal extends Component<IBookingBulkEndBookingModalProps, IBookingBulkEndBookingModalState> {
  state = {
    canManuallyClose: true,
    step: 1,
    title: 'Finish bookings',
    isLoading: false,
    isEditCustomerTimesOpen: false,
    customersTimes: null,
    selectedCustomer: null,
    selectedCustomerTime: null,
    searchString: null,
  };

  private _openEditCustomerTimes = (customer) => {
    this.setState({
      isEditCustomerTimesOpen: true,
      selectedCustomer: customer,
      selectedCustomerTime: customer.endDateTime,
    });
  };

  private _closeEditCustomerTimes = () => {
    this.setState({ isEditCustomerTimesOpen: false, selectedCustomer: null, selectedCustomerTime: null });
  };

  private _onChangeDateTime = (e) => {
    this.setState({ selectedCustomerTime: CommonUtils.formatCeilingDateTime(e) });
  };

  private _onSaveCustomerTimes = () => {
    const { selectedCustomer, selectedCustomerTime } = this.state;
    const { timezone } = this.props.selectedSession;
    this.setState(
      {
        customersTimes: _.map(this.state.customersTimes, (customer) => {
          if (customer.attendanceId === selectedCustomer.attendanceId) {
            return { ...customer, endDateTime: moment.tz(selectedCustomerTime, timezone) };
          } else {
            return { ...customer };
          }
        }),
      },
      () => this._closeEditCustomerTimes(),
    );
  };

  private _onEnterSearchText = (e) => {
    this._debounceSearch(e.target.value);
  };

  private _searchText = async (txt) => {
    this.setState({ searchString: txt });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _renderView = () => {
    const {
      step,
      isEditCustomerTimesOpen,
      customersTimes,
      selectedCustomer,
      selectedCustomerTime,
      isLoading,
      searchString,
    } = this.state;
    const { selectedItems, selectedSession } = this.props;

    const searchStringArray = _.split(searchString, ' ');
    const filteredCustomerList =
      searchString && searchString.length > 0
        ? _.filter(
            customersTimes,
            (customer) =>
              _.some(searchStringArray, (txt) => _.includes(customer.firstName.toLowerCase(), txt.toLowerCase())) ||
              _.some(searchStringArray, (txt) => _.includes(customer.lastName.toLowerCase(), txt.toLowerCase())),
          )
        : customersTimes;
    const filterCustomerListWithoutTimezone = _.sortBy(
      _.map(filteredCustomerList, (customer) => {
        return {
          ...customer,
          startDateTime: moment.tz(customer.startDateTime, selectedSession.timezone).format('YYYY-MM-DD HH:mm'),
          endDateTime: moment.tz(customer.endDateTime, selectedSession.timezone).format('YYYY-MM-DD HH:mm'),
        };
      }),
      [(customer) => customer.firstName.toLowerCase(), (customer) => customer.lastName.toLowerCase()],
    );

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <ActionModal
            isOpen={isEditCustomerTimesOpen}
            title={'Edit finish time'}
            onClose={this._closeEditCustomerTimes}
            width={'large'}
          >
            <div className="anim-slide-left">
              <Paragraph>Edit the customers finish time for this session</Paragraph>

              {selectedCustomer && (
                <>
                  <div className={'mt-large'}>
                    <SubTitle>Customer</SubTitle>
                    <div className={' flex-row align-center'}>
                      <Avatar className="mr-small" src={selectedCustomer.customerAvatarUrl} icon={'user'} />{' '}
                      {selectedCustomer.firstName} {selectedCustomer.lastName}
                    </div>
                  </div>
                  <div className={'mt-large bg-white bordered round-big p-medium mb-x-large'}>
                    <SubTitle>Session finish time</SubTitle>
                    <div className="flex-row align-left">
                      <DatePicker
                        className="gh-datepicker rounded mr-small"
                        calendarClassName="gh-datepicker-calendar"
                        dateFormat="d/M/yyyy"
                        isClearable={false}
                        selected={moment.tz(selectedCustomerTime, selectedSession.timezone).toDate()}
                        onChange={(event) => this._onChangeDateTime(event)}
                      />
                      <TimeInput
                        size="large"
                        value={moment.tz(selectedCustomerTime, selectedSession.timezone)}
                        onChange={(event) => this._onChangeDateTime(event)}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className={'mb-small'}>
                <Row type={'flex'} justify={'end'}>
                  <Col>
                    <GhostButton size="large" onClick={this._closeEditCustomerTimes}>
                      Cancel
                    </GhostButton>
                  </Col>
                  <Col>
                    <PrimaryButton onClick={this._onSaveCustomerTimes} size="large">
                      Save
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          </ActionModal>
          {isLoading ? (
            <SpinningLoader size={100} message={'Loading customer list'} />
          ) : (
            <>
              <Paragraph>
                You are choosing to finish the booking for the following customers. Customers' booking will be finished
                with their scheduled session time which is displayed below. You can adjust their finish time if you
                wish.
              </Paragraph>
              <Search onChange={this._onEnterSearchText} placeholder="Search for..." allowClear size={'large'} />
              <div className={'bordered rounded-big shadow-container mt-medium mb-x-large'}>
                <Row className={'bordered-bottom ph-medium pv-small'}>
                  <Col span={10}>Customer</Col>
                  <Col span={10}>Finish time</Col>
                  <Col span={4}></Col>
                </Row>
                {filterCustomerListWithoutTimezone && filterCustomerListWithoutTimezone.length > 0 ? (
                  _.map(filterCustomerListWithoutTimezone, (customer) => {
                    return (
                      <Row className={'p-medium evenodd'} type={'flex'} align={'middle'}>
                        <Col span={10} className={'flex-row align-center'}>
                          <Text>
                            <Avatar className="mr-small" src={customer.customerAvatarUrl} icon={'user'} />{' '}
                            {customer.firstName} {customer.lastName}
                          </Text>
                        </Col>
                        <Col span={10}>{moment(customer.endDateTime).format('DD MMMM Y, h:mmA')}</Col>
                        <Col span={4}>
                          <HyperlinkButton onClick={() => this._openEditCustomerTimes(customer)}>
                            Edit finish time
                          </HyperlinkButton>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Row className={'p-medium evenodd'} type={'flex'} align={'middle'}>
                    <Text color={'secondary'}>No matching customer selected</Text>
                  </Row>
                )}
              </div>
              <div className={'mb-small'}>
                <Row type={'flex'} justify={'end'}>
                  <Col>
                    <GhostButton size="large" onClick={this._onCloseModal}>
                      Cancel
                    </GhostButton>
                  </Col>
                  <Col>
                    <PrimaryButton onClick={this._onFinishBookings} size="large">
                      Finish
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      );
    }
    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Finishing the bookings, won't be long...</Paragraph>
          </div>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>
              You have successfully finished{' '}
              <b>
                {selectedItems && selectedItems.length} customer booking
                {selectedItems && selectedItems.length !== 1 ? 's' : ''}
              </b>
              .
            </Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    onClose(this.state.step === 3);
    this.setState({ canManuallyClose: true, step: 1, title: 'Finish bookings' });
  };

  private _onFinishBookings = async () => {
    const { selectedSession } = this.props;
    const { customersTimes } = this.state;
    try {
      this.setState({ step: 2, canManuallyClose: false, isLoading: true });
      await this.props.doFinishGroupBooking({
        serviceId: selectedSession.serviceId,
        serviceDateTimeId: selectedSession.serviceDateTimeId,
        attendances: _.map(customersTimes, (booking) => {
          return {
            attendanceId: booking.attendanceId,
            endDateTime: moment.tz(moment(booking.endDateTime).format('YYYY-MM-DD HH:mm'), selectedSession.timezone),
          };
        }),
      });
      this.setState({ step: 3, canManuallyClose: true, title: 'Booking finished', isLoading: false });
    } catch (e) {
      this.setState({ canManuallyClose: true, isLoading: false });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  componentDidUpdate(prevProps: Readonly<IBookingBulkEndBookingModalProps>) {
    const { selectedItems } = this.props;
    if (prevProps.selectedItems !== selectedItems) {
      this.setState({
        customersTimes: _.map(selectedItems, (customer) => {
          return { ...customer };
        }),
        isLoading: false,
      });
    }
  }

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width="large"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div className="flex-column justify-center">{this._renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedSession: state.groupServiceStore.selectedSession });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFinishGroupBooking: dispatch.groupBookingsStore.doFinishGroupBooking,
});

export default connect(mapState, mapDispatch)(BookingBulkEndBookingModal);
