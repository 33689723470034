import React from 'react';
import { useFormContext } from 'react-hook-form';
import { INVITE_MEMBER_ROLE_TYPE } from 'views/team-v2/utils/constants';
import { Member } from '../../utils/form-utils';
import { FormComponents } from '../components/FormComponents';

interface IProps {
  memberDetails: Member;
  teamMemberList: Member[];
}

const InviteToAppColumn: React.FC<IProps> = ({ memberDetails, teamMemberList }) => {
  const { register } = useFormContext();

  const index = teamMemberList.findIndex((member) => member.memberId === memberDetails?.memberId);
  const isDisabled =
    memberDetails.roles.includes(INVITE_MEMBER_ROLE_TYPE.NO_ACCESS) && memberDetails.hasWorkerAppAccess;

  return (
    <FormComponents.SwitchItem
      name="hasWorkerAppAccess"
      aria-label="has-app-access"
      isDisabled={isDisabled}
      {...register(`teamMemberList.${index}.hasWorkerAppAccess` as const)}
    />
  );
};

export default InviteToAppColumn;
