import React from 'react';
import {
  Anchor,
  NumberInput,
  NumberInputProps,
  Select,
  SelectProps,
  Text,
  TextInput,
  TextInputProps,
} from '@good/ui/core';
import { PinCheck } from '@good/icons';

import { AddressInputFieldErrors, AddressInputValues, FieldUnionProps, State } from './types';

export type AddressInputManualProps = {
  errors: AddressInputFieldErrors;
  hideManualInputButton?: boolean;
  inputValues: AddressInputValues;
  onChangeInputValue: (field: keyof AddressInputValues, innerValue: string | undefined | null) => void;
  toggleOffManualInput?: () => void;
  validate: (fieldName?: keyof AddressInputFieldErrors) => void;
  width: React.CSSProperties['width'];
} & FieldUnionProps;

export const AddressInputManual = (props: AddressInputManualProps) => {
  const {
    errors,
    hideManualInputButton,
    inputValues,
    onChangeInputValue,
    toggleOffManualInput,
    validate,
    width,
    ...fieldProps
  } = props;

  const onBlur = (event: React.FocusEvent<HTMLInputElement>, fieldName: keyof AddressInputFieldErrors) => {
    validate(fieldName);
    fieldProps.onBlur?.(event);
  };

  const inputProps = (
    fieldName: keyof AddressInputValues,
  ): Pick<TextInputProps | NumberInputProps | SelectProps, 'name' | 'error' | 'labelProps'> & FieldUnionProps => ({
    ...fieldProps,
    className: [fieldProps.className, 'w-full'].join(' '),
    onBlur: (e) => onBlur(e, fieldName),
    name: fieldName,
    error: errors[fieldName]?.message,
    labelProps: {
      size: fieldProps.size ?? 'md',
    },
  });

  return (
    <div className='flex flex-col align-start gap-2' style={{ width }}>
      {!hideManualInputButton && (
        <Anchor
          component='button'
          onClick={() => {
            if (fieldProps.disabled) return;
            toggleOffManualInput?.();
          }}
          size='sm'
          className='focus:outline-none focus:underline'
          type='button'
        >
          <span className='flex gap-1 align-center'>
            <Text>
              <PinCheck />
            </Text>
            <Text>Enter address automatically</Text>
          </span>
        </Anchor>
      )}
      <div className='flex flex-col gap-6 w-full'>
        <TextInput
          {...inputProps('manualInput.streetAddress1')}
          label='Address line 1'
          placeholder='Enter address 1'
          value={inputValues['manualInput.streetAddress1']}
          onChange={({ target: { value: streetAddress1 } }) =>
            onChangeInputValue('manualInput.streetAddress1', streetAddress1)
          }
        />
        <TextInput
          {...inputProps('manualInput.streetAddress2')}
          label={
            <span className='flex gap-1 align-center'>
              <Text size='sm' className='font-bold'>
                Address line 2
              </Text>
              <Text size='xs' className='text-weak'>
                (optional)
              </Text>
            </span>
          }
          placeholder='Enter address 2'
          value={inputValues['manualInput.streetAddress2']}
          onChange={({ target: { value: streetAddress2 } }) =>
            onChangeInputValue('manualInput.streetAddress2', streetAddress2)
          }
        />
        <TextInput
          {...inputProps('manualInput.locality')}
          label='Suburb'
          placeholder='Enter suburb'
          value={inputValues['manualInput.locality']}
          onChange={({ target: { value: locality } }) => onChangeInputValue('manualInput.locality', locality)}
        />
        <div className='flex gap-2 w-full'>
          <Select
            {...inputProps('manualInput.state')}
            label='State'
            placeholder='Select state'
            allowDeselect={false}
            data={Object.values(State)}
            value={inputValues['manualInput.state']}
            onChange={(state) => onChangeInputValue('manualInput.state', state as State)}
          />
          <NumberInput
            {...inputProps('manualInput.postcode')}
            label='Postcode'
            placeholder='Type postcode'
            value={inputValues['manualInput.postcode']}
            onChange={(postcode) => onChangeInputValue('manualInput.postcode', postcode.toString())}
            min={0}
            max={9999}
            minLength={4}
            maxLength={4}
            hideControls
            decimalScale={0}
          />
        </div>
      </div>
    </div>
  );
};
