import React, { Component, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Card, ProgressBar } from '@blueprintjs/core';
import { Icon, Typography, message, notification } from 'antd';
import { Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import '@fullcalendar/react/dist/vdom';

import { AppContainer } from './layouts/app-container';
import NotificationUtils from './utilities/notification-utils';
import Routes from './routes';
import SessionExpiredModal from './views/sessions/details/SessionExpiredModal';
import firebaseApp from './stores/firebase-app';
import { loginRedirectUrl } from './config/app-config';
import { HyperlinkButton } from './common-components/buttons';
import { IMessage, MessageOrigin } from './interfaces/message-interfaces';
import { INotification } from './interfaces/notification-interfaces';
import { Paragraph, Text } from './common-components/typography';
import { PubNubProvider } from './integrations/pubnub/pubnub';
import { TeamStatus } from './utilities/enum-utils';
import { withRouter } from 'utilities/with-router';

import type { AuthStoreState } from './stores/rematch/models/auth-store';
import type { PubNubProviderProps } from './integrations/pubnub/pubnub';

import { identify, transformPortalUserToAppcuesUser } from 'integrations/appcues';
import { PortalUserContext } from './utilities/react-hooks/use-portal-user';
import { GoogleMapsApiProvider } from 'providers/google-maps-api-provider';

import './App.css';

// 30 * 60 * 1000 ms
const SESSION_TIME_OUT = 1800000;

const Loading = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Card style={{ width: '25%', textAlign: 'center' }} className='shadow-box bordered p-large'>
      <div style={{ marginBottom: '16px' }}>
        <Typography.Text style={{ fontSize: '16px' }} className='text-color-secondary'>
          Loading...
        </Typography.Text>
      </div>
      <ProgressBar animate={true} />
    </Card>
  </div>
);

class App extends Component<any, any> {
  private idleTimer = null;

  state = {
    isSessionExpiredModalOpen: false,
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.setAppLoaded(false);
    firebaseApp.attachAuthListener(this._handleAuthStateChange);
  }

  _handleNotification = async (notificationMessage: INotification) => {
    const {
      setDashboardNotificationItems,
      doGetNotificationBadgeCount,
      authStore,
      setCustomerDocumentStatus,
      setWorkerDocumentStatus,
      setBookingDocumentStatus,
      setWorkflowAttachmentStatus,
      doGetChannelDetail,
      appendSingleMessageToChannel,
      doGetAssignedServices,
      history,
    } = this.props;

    doGetNotificationBadgeCount();

    // Ignore receive message if different service provider id
    if (notificationMessage.serviceProviderId === authStore.portalUser.serviceProviderId) {
      if (notificationMessage.actionType === 'ACTION') {
        //append notification to dashboard notification list
        setDashboardNotificationItems([notificationMessage]);
      }

      //TODO: append notification to notification listing ?
      if (notificationMessage.notificationType === 'DocumentStatusUpdate') {
        notificationMessage.data.customerUserId
          ? setCustomerDocumentStatus({
              documentId: notificationMessage.data.documentId,
              status: notificationMessage.data.status,
              documentUrl: notificationMessage.data.documentUrl,
            })
          : notificationMessage.data.supportWorkerId
          ? setWorkerDocumentStatus({
              documentId: notificationMessage.data.documentId,
              status: notificationMessage.data.status,
              documentUrl: notificationMessage.data.documentUrl,
            })
          : notificationMessage.data.bookingId
          ? setBookingDocumentStatus({
              documentId: notificationMessage.data.documentId,
              status: notificationMessage.data.status,
              documentUrl: notificationMessage.data.documentUrl,
            })
          : notificationMessage.data.workflowAttachmentId ||
            notificationMessage.data.workflowFormId ||
            notificationMessage.data.workflowTemplateId
          ? setWorkflowAttachmentStatus({
              workflowAttachmentId: notificationMessage.data.workflowAttachmentId,
              documentUrl: notificationMessage.data.documentUrl,
              status: notificationMessage.data.status,
              workflowFormId: notificationMessage.data.workflowFormId,
            })
          : null;
      }

      // Added by Jir : Handle incoming notifications for messaging
      if (notificationMessage.notificationType === 'messaging') {
        // -=== • Messaging notification • ===-
        const parsedMessage: IMessage = notificationMessage.data && notificationMessage.data.data;
        // Do we need special handling if the current screen is messaging...?
        // we need to fetch services unread count as well.
        doGetAssignedServices();

        doGetChannelDetail({
          messageChannelId: parsedMessage.messageChannelId,
        });

        appendSingleMessageToChannel({
          message: parsedMessage,
          messageChannelId: parsedMessage.messageChannelId,
        });

        // Do we need to specially handle if current screen = messaging...?
        const currentLocation = this.props['location'].pathname;

        notification.open({
          key: notificationMessage.notificationId,
          message: (
            <div>
              <Text weight='bold' className='select-none'>
                Incoming message
              </Text>
            </div>
          ),
          description: (
            <div>
              <Text size='small' color='secondary' weight='bold' className='select-none'>
                # {parsedMessage.serviceName}
              </Text>
              <br />
              <Paragraph
                size='regular'
                ellipsis={{ rows: 2 }}
                style={{ width: '100%', whiteSpace: 'pre-wrap' }}
                className='select-none'
              >
                {parsedMessage.content.text}
              </Paragraph>
              <div className='flex-row'>
                <HyperlinkButton
                  onClick={() => {
                    notification.close(notificationMessage.notificationId);

                    this.props.setIncomingMessageNotification({
                      messageChannelId: parsedMessage.messageChannelId,
                      messageId: parsedMessage.messageId,
                    });

                    if (currentLocation !== '/messaging') {
                      history.push({
                        pathname: '/messaging',
                        state: {
                          messageChannelId: parsedMessage.messageChannelId,
                          origin: MessageOrigin.Notification,
                        },
                      });
                    }
                  }}
                  className='select-none'
                >
                  View full message
                </HyperlinkButton>
              </div>
            </div>
          ),
          icon: <Icon type='message' className='pt-small text-color-blue-dark text-size-x2-large' />,
          duration: 5,
        });
      } else {
        // -=== • Other notifications • ===-

        doGetNotificationBadgeCount();

        //TODO: append notification to notification listing ?
        if (document.hidden) {
          // when user not focus on browser's tab
          if (Notification.permission === 'granted') {
            this._createBrowserNotification(notificationMessage);
          } else if (Notification.permission !== 'denied') {
            Notification.requestPermission(function (permission) {
              if (permission === 'granted') {
                this._createBrowserNotification(notificationMessage);
              }
            });
          }
        } else {
          notification.open({
            key: notificationMessage.notificationId,
            message: (
              <Text weight='bold' size='x2-large'>
                {notificationMessage.title}
              </Text>
            ),
            description: (
              <div>
                <Text size='large'>
                  {/* Notification Text here */}
                  {notificationMessage.body}
                </Text>
                <br />

                <div className='text-align-right mt-medium'>
                  {notificationMessage.notificationType === 'DocumentStatusUpdate' ? (
                    notificationMessage.data.supportWorkerId ? (
                      <HyperlinkButton fontSize='x-large'>Go to the documents</HyperlinkButton>
                    ) : (
                      <HyperlinkButton fontSize='x-large'>Go to the documents</HyperlinkButton>
                    )
                  ) : notificationMessage.data.numberOfSession ? (
                    <HyperlinkButton fontSize='x-large'>Go to Session listing</HyperlinkButton>
                  ) : notificationMessage.data.serviceDateTimeId ? (
                    <HyperlinkButton fontSize='x-large'>Go to Session</HyperlinkButton>
                  ) : (
                    <HyperlinkButton fontSize='x-large'>Go to Booking</HyperlinkButton>
                  )}
                </div>
              </div>
            ),
            onClick: () => {
              NotificationUtils.Navigate(this.props.history, notificationMessage, false);
              notification.close(notificationMessage.notificationId);
              if (notificationMessage.actionType === 'ACTION') {
                this.props.doDimissNotification({ notificationId: notificationMessage.notificationId });
              }
            },
            duration: 5,
          });
        }
      }
    }
  };

  _createBrowserNotification = (receivedNotification: INotification) => {
    const browserNotification = new Notification(receivedNotification.title);
    browserNotification.onclick = () => {
      this.props.doDimissNotification({ notificationId: receivedNotification.notificationId });
      NotificationUtils.Navigate(this.props.history, receivedNotification, false);
      window.focus();
    };
  };

  private _checkSessionTimeOut = (): boolean => {
    return localStorage.getItem('isSessionTimeOut') === 'true';
  };

  // Handles the Auth state changes. This is triggered whenever someone logs in / out.
  _handleAuthStateChange = async (state) => {
    const {
      doFetchPortalUserList,
      setCurrentPortalUser,
      setServiceProviderId,
      doSignOutUsers,
      doSignInUsingToken,
      setIsUserBlocked,
      authStore,
      setIsSignOut,
      setIsForgetPassword,
    } = this.props;

    const serviceProviderId = new URL(window.location.href).searchParams.get('serviceProviderId');
    if (serviceProviderId) setServiceProviderId(serviceProviderId);

    this._checkSessionTimeOut();
    const { pathname } = this.props.history.location;

    if (state != null) {
      // firebase authenticated
      if (!this._checkSessionTimeOut()) {
        try {
          const result = await doFetchPortalUserList();

          if (_.isEmpty(result)) {
            // not authenticated
            await message.error(
              'You are not authorized to login to any service provider. Please contact your administrator.',
            );

            doSignOutUsers();
            this.props.history.push('/login');
          } else {
            let portalUserResult = result.find(
              (provider: { serviceProviderId: string }) => provider.serviceProviderId === serviceProviderId,
            );

            if (!portalUserResult || portalUserResult.status !== TeamStatus.ENABLED) {
              portalUserResult = result.find((provider: { status: string }) => provider.status === TeamStatus.ENABLED);
              setServiceProviderId(portalUserResult.serviceProviderId);
            }

            if (portalUserResult?.status !== TeamStatus.ENABLED) {
              setIsUserBlocked(true);
              setIsSignOut(false);
              doSignOutUsers();
            } else {
              setCurrentPortalUser(portalUserResult);
              setIsUserBlocked(false);
              setIsForgetPassword(false);

              // Update portal user's local timezone if autodetect is true and the timezone is different than the one saved
              const displayTimezone = moment.tz.guess();
              if (
                displayTimezone !== portalUserResult.timezone &&
                portalUserResult.displayTimezoneSetting &&
                portalUserResult.displayTimezoneSetting.autoDetect
              ) {
                await this.props.doUpdatePortalUserTimezone({ timezone: displayTimezone });
              }

              await message.success(`Sign in successful for ${portalUserResult.email}.`, 2);
              // Add here to auto redirect to dashboard.
              if (pathname === '/' || pathname === '/login') {
                this.props.history.push('/home');
              }
            }
          }
        } catch (e) {
          // not authenticated
          message.error('You are not authorized to login to any service provider. Please contact your administrator.');
          setIsForgetPassword(false);
          await doSignOutUsers();
          window.location.href = loginRedirectUrl();
        }
      } else {
        setIsForgetPassword(false);
        await doSignOutUsers();
        window.location.href = loginRedirectUrl();
      }
    } else {
      // not authenticated
      this.props.setPortalUserList(null);
      this.props.setCurrentPortalUser(null);
      const { location } = this.props;
      const data = new URLSearchParams(location.search);
      const loginToken = data.get('token');
      if (loginToken) {
        setIsForgetPassword(false);
        try {
          localStorage.setItem('isSessionTimeOut', 'false');
          await doSignInUsingToken({ token: loginToken });

          const result = await doFetchPortalUserList();
          if (_.isEmpty(result)) {
            // not authenticated
            await message.error(
              'You are not authorized to login to any service provider. Please contact your administrator.',
            );
            doSignOutUsers();
          } else {
            const portalUserResult =
              result.find(
                (provider: { serviceProviderId: string }) => provider.serviceProviderId === serviceProviderId,
              ) ?? result[0];
            if (portalUserResult.status !== TeamStatus.ENABLED) {
              setIsUserBlocked(true);
              setIsSignOut(false);
              doSignOutUsers();
            } else {
              setCurrentPortalUser(portalUserResult);
              setIsUserBlocked(false);

              // Update portal user's local timezone if autodetect is true and the timezone is different than the one saved
              const displayTimezone = moment.tz.guess();
              if (
                displayTimezone !== portalUserResult.timezone &&
                portalUserResult.displayTimezoneSetting &&
                portalUserResult.displayTimezoneSetting.autoDetect
              ) {
                await this.props.doUpdatePortalUserTimezone({ timezone: displayTimezone });
              }

              // Add here to auto redirect to dashboard.
              if (pathname === '/') {
                this.props.history.push('/home');
              } else {
                // Add here to auto redirect to path name.
                this.props.history.push(pathname);
              }
            }
          }
        } catch (error) {
          window.location.href = loginRedirectUrl();
        }
      } else {
        if (authStore.isSignOut === true && authStore.isForgetPassword === true) {
          window.location.href = `${loginRedirectUrl()}&action=reset`;
        } else {
          window.location.href = loginRedirectUrl();
        }
      }
    }
    this.props.setAppLoaded(true);
  };

  setRef = (ref) => {
    this.idleTimer = ref;
  };

  private _closeSessionExpiredModal = () => {
    this.setState({ isSessionExpiredModalOpen: false });
    localStorage.setItem('isSessionTimeOut', 'false');
  };

  private _handleOnAction = (event) => {
    localStorage.setItem('lastActive', this.idleTimer.getLastActiveTime());
  };

  private _handleOnIdle = (event) => {
    this.setState({ isSessionExpiredModalOpen: true });
    localStorage.setItem('isSessionTimeOut', 'true');
  };

  render() {
    const { authStore } = this.props;

    // Waiting for initialization
    if (!authStore.isAppLoaded) return <Loading />;

    const { portalUser } = authStore as AuthStoreState;
    if (!portalUser)
      return (
        <Switch>
          <Routes portalUser={portalUser} isAuthenticated={false} />
        </Switch>
      );

    const { pathname } = this.props['history'].location;
    switch (pathname) {
      case '/pdf':
      case '/redirect': {
        return (
          <div className='printable-screen'>
            <Switch>
              <Routes portalUser={portalUser} isAuthenticated={true} />
            </Switch>
          </div>
        );
      }
      case '/register':
      case '/reset': {
        return (
          <Switch>
            <Routes portalUser={portalUser} isAuthenticated={true} />
          </Switch>
        );
      }
      default: {
        return (
          <Authenticated onNotification={this._handleNotification} portalUser={portalUser}>
            <PortalUserContext.Provider value={portalUser}>
              <GoogleMapsApiProvider>
                <AppContainer>
                  <IdleTimer
                    ref={this.setRef}
                    timeout={SESSION_TIME_OUT}
                    // onActive={this.handleOnActive}
                    onIdle={this._handleOnIdle}
                    onAction={this._handleOnAction}
                    throttle={2000}
                    // stopOnIdle={this.state.stopOnIdle}
                  />
                  <SessionExpiredModal
                    isSessionExpiredOpen={this.state.isSessionExpiredModalOpen}
                    closeSessionExpired={this._closeSessionExpiredModal}
                  />
                  <Switch>
                    <Routes portalUser={portalUser} isAuthenticated={true} />
                  </Switch>
                </AppContainer>
              </GoogleMapsApiProvider>
            </PortalUserContext.Provider>
          </Authenticated>
        );
      }
    }
  }
}

const mapState = (state) => ({
  authStore: state.authStore,
  companyDataLite: state.companyStore.companyDataLite,
});

const mapDispatch = (dispatch) => ({
  setAppLoaded: dispatch.authStore.setAppLoaded,
  doSignOutUsers: dispatch.authStore.doSignOutUsers,
  setCurrentPortalUser: dispatch.authStore.setCurrentPortalUser,
  setServiceProviderId: dispatch.authStore.setServiceProviderId,
  doSignInUsingToken: dispatch.authStore.doSignInUsingToken,
  setPortalUserList: dispatch.authStore.setPortalUserList,
  doFetchPortalUserList: dispatch.authStore.doFetchPortalUserList,
  doUpdatePortalUserTimezone: dispatch.authStore.doUpdatePortalUserTimezone,
  setDashboardNotificationItems: dispatch.notificationsStore.setDashboardNotificationItems,
  setCustomerDocumentStatus: dispatch.customersStore.setCustomerDocumentStatus,
  setWorkerDocumentStatus: dispatch.teamStore.setWorkerDocumentStatus,
  setBookingDocumentStatus: dispatch.bookingsStore.setBookingDocumentStatus,
  setWorkflowAttachmentStatus: dispatch.workflowStore.setWorkflowAttachmentStatus,
  doDimissNotification: dispatch.notificationsStore.doDimissNotification,
  doGetNotificationBadgeCount: dispatch.notificationsStore.doGetNotificationBadgeCount,
  doResetNotificationBadgeCount: dispatch.notificationsStore.doResetNotificationBadgeCount,
  setIsUserBlocked: dispatch.authStore.setIsUserBlocked,
  setIsSignOut: dispatch.authStore.setIsSignOut,
  setIsForgetPassword: dispatch.authStore.setIsForgetPassword,
  // for messaging
  doGetChannelDetail: dispatch.channelsStore.doGetChannelDetail,
  appendSingleMessageToChannel: dispatch.messagesStore.appendSingleMessageToChannel,
  doGetAssignedServices: dispatch.channelsStore.doGetAssignedServices,
  setIncomingMessageNotification: dispatch.messagesStore.setIncomingMessageNotification,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
});

const connectDispatch = connect(mapState, mapDispatch);
export default compose(connectDispatch, withRouter)(App);

type AuthenticatedProps = Pick<AuthStoreState, 'portalUser'> & Omit<PubNubProviderProps, 'userId'>;

const Authenticated = (props: AuthenticatedProps) => {
  const { children, onNotification, portalUser } = props;
  const { userId } = portalUser;
  useEffect(() => identify(userId, transformPortalUserToAppcuesUser(portalUser)), []);

  return (
    <PubNubProvider onNotification={onNotification} userId={userId}>
      {children}
    </PubNubProvider>
  );
};
