import React from 'react';
import { Pill } from '@good/ui/core';
import { useTranslation } from 'react-i18next';

export const StatusPill = ({ status }: { status: string }) => {
  const { t } = useTranslation('', { keyPrefix: 'services.listing.status' });

  const statusMap: Record<string, JSX.Element> = {
    PUBLISHED: <Pill className='bg-positive-weak text-green-dark-2 font-semibold'>{t('published')}</Pill>,
    DRAFT: <Pill className='bg-blue-light-1 text-blue-dark-2 font-semibold'>{t('draft')}</Pill>,
    ARCHIVED: <Pill className='font-semibold'>{t('archived')}</Pill>,
    UNPUBLISHED: <Pill className='bg-red-light-1 text-red-dark-2 font-semibold'>{t('unpublished')}</Pill>,
  };

  return statusMap[status] || 'Unknown';
};
