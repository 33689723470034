import { tv } from 'tailwind-variants';

export const searchField = tv(
  {
    slots: {
      container: ['flex', 'flex-col', 'gap-2', 'min-w-[192px]', 'w-fit', 'relative', 'isolate', 'group'],
      inputContainer: ['relative', 'block', 'h-11'],
      input: [
        'absolute',
        'appearance-none',
        'bg-base',
        'border',
        'border-gray',
        'hover:border-gray-dark-1',
        'disabled:bg-neutral-weak',
        'disabled:border-neutral-weak',
        'enabled:aria-invalid:border-critical',
        'enabled:aria-invalid:data-[focus-visible=true]:outline-critical',
        'enabled:aria-invalid:data-[focused=true]:border-critical',
        'enabled:aria-invalid:data-[hovered=false]:border-critical',
        'enabled:aria-invalid:data-[hovered=true]:border-critical',
        'enabled:data-[focus-visible=true]:outline',
        'enabled:data-[focus-visible=true]:outline-accent',
        'enabled:data-[focus-visible=true]:outline-offset-[-1px]',
        'enabled:data-[focused=true]:border-accent',
        'enabled:data-[focused=true]:data-[hovered=false]:border-accent',
        'inset-0',
        'leading-b-sm',
        'outline-none',
        'placeholder:text-weak',
        'px-[38px]',
        'py-2.5',
        'rounded-md',
        'text-b-sm',
        'text-strong',
        'transition-colors',
        'w-full',
      ],
      icon: ['ml-3', 'absolute', 'top-3', 'left-0', 'z-10', 'text-weak'],
      button: ['mr-3', 'group-data-[empty=true]:invisible', 'absolute', 'right-0', 'top-2.5'],
    },
  },
  { twMerge: false },
);
