import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'stores/rematch/root-store';
import { useAsync } from 'react-use';
import { useFlags } from 'launchdarkly-react-client-sdk';

import themeVariables from 'theme/theme-variables';
// Custom CSS
import './payments/css/new-payment-listing.css';

import type { ViewProps } from 'types/views';

import { PayListNavigationSection } from './payments/sections/PayListNavigationSection';
import { PayListDetailsSection } from './payments/sections/PayListDetailsSection';
import PayListBatchesSection from './payments/sections/PayListBatchSection';
import PayListProcessedSection from './payments/sections/PayListProcessedSection';
import { PAYMENTLIST_FILTERCONFIGS, PaymentListFilterKey } from './payments/components/payListFilterConfig';
import PayListWaivedSection from 'views/billings/payments/sections/PayListWaivedSection';
import PayListRejectedSection from 'views/billings/payments/sections/PayListRejectedSection';
import ShiftHoursSection from 'views/billings/payments/sections/PayListShiftHoursSection';
import PermissionUtils from 'utilities/permission-utils';
import PayListPaidSection from './payments/sections/PayListPaidSection';
import { List as ListTimesheets } from 'views/payments/timesheets';
import { Box, Flex } from '@good/ui/core';
import { PageContainer } from 'layouts/page-container';

const useLegacySectionProps = () => {
  const paymentsList = useAppSelector((s) => s.billingsStore.paymentsList);
  const batchList = useAppSelector((s) => s.billingsStore.batchList);
  const invoicesList = useAppSelector((s) => s.billingsStore.invoiceList);
  const rejectedList = useAppSelector((s) => s.billingsStore.rejectedList);
  const shiftHoursList = useAppSelector((s) => s.billingsStore.shiftHoursList);
  const timesheetShifts = useAppSelector((s) => s.billingsStore.timesheetShifts);
  const overviewPayments = useAppSelector((s) => s.billingsStore.overviewPayments);
  const paymentsFilter = useAppSelector((s) => s.billingsStore.paymentsFilter);
  const batchesFilter = useAppSelector((s) => s.billingsStore.batchesFilter);
  const invoicesFilter = useAppSelector((s) => s.billingsStore.invoicesFilter);
  const shiftHoursFilter = useAppSelector((s) => s.billingsStore.shiftHoursFilter);
  const rejectedFilter = useAppSelector((s) => s.billingsStore.rejectedFilter);
  const isSelectedAll = useAppSelector((s) => s.billingsStore.paymentsSelectedAll);
  const selectedBillingLineItemId = useAppSelector((s) => s.billingsStore.selectedBillingLineItemId);
  const selectedBillingLineItem = useAppSelector((s) => s.billingsStore.selectedBillingLineItem);
  const paymentsSelectedAll = useAppSelector((s) => s.billingsStore.paymentsSelectedAll);
  const portalUser = useAppSelector((s) => s.authStore.portalUser);

  const {
    billingsStore: {
      doFetchBatches,
      doFetchInvoices,
      doFetchRejected,
      doFetchShiftHours,
      doFetchTimesheetHours,
      setInvoices,
      setBatches,
      setRejected,
      setShiftHours,
      setPaymentsFilter,
      setInvoicesFilter,
      setBatchesFilter,
      setRejectedFilter,
      setShiftHoursFilter,
      setPaymentsSelectedAll,
    },
  } = useAppDispatch();

  return {
    paymentsList,
    batchList,
    invoicesList,
    rejectedList,
    shiftHoursList,
    timesheetShifts,
    overviewPayments,
    paymentsFilter,
    batchesFilter,
    invoicesFilter,
    shiftHoursFilter,
    rejectedFilter,
    isSelectedAll,
    selectedBillingLineItemId,
    selectedBillingLineItem,
    paymentsSelectedAll,
    portalUser,
    doFetchBatches,
    doFetchInvoices,
    doFetchRejected,
    doFetchShiftHours,
    doFetchTimesheetHours,
    setInvoices,
    setBatches,
    setRejected,
    setShiftHours,
    setPaymentsFilter,
    setInvoicesFilter,
    setBatchesFilter,
    setRejectedFilter,
    setShiftHoursFilter,
    setPaymentsSelectedAll,
  };
};

export const PaymentListingView: React.VFC<ViewProps> = (viewProps) => {
  const selectedFilterKey = useAppSelector((s) => s.billingsStore.selectedFilterKey);
  const {
    billingsStore: { setSelectedFilterKey },
    navigationStore: { setSelectedSideNavMenuKeys },
  } = useAppDispatch();

  const currentFilterConfig = PAYMENTLIST_FILTERCONFIGS[selectedFilterKey];

  useEffect(() => {
    setSelectedSideNavMenuKeys(['/payments']);
  }, [setSelectedSideNavMenuKeys]);

  const { value: isServiceProviderVCPEnabled = false } = useAsync<boolean>(
    () => PermissionUtils.getIsServiceProviderVCPEnabled(),
    [],
  );

  const allSectionProps = {
    ...viewProps,
    isServiceProviderVCPEnabled,
    currentFilterConfig,
  };
  const onlyLegacySectionProps = useLegacySectionProps();
  const legacySectionProps = {
    selectedFilterKey,
    ...allSectionProps,
    ...onlyLegacySectionProps,
  };

  const { pinc1035TimesheetsReskin } = useFlags();

  return (
    <div style={{ height: themeVariables.bodyHeight }} data-testid='PaymentListingView'>
      <Flex mih='100%'>
        <PayListNavigationSection currentFilterConfig={currentFilterConfig} setFilter={setSelectedFilterKey} />

        <Box w='100%'>
          <PageContainer>
            {selectedFilterKey === PaymentListFilterKey.PROCESSED ? (
              <PayListProcessedSection {...legacySectionProps} />
            ) : selectedFilterKey === PaymentListFilterKey.PAID ? (
              <PayListPaidSection {...legacySectionProps} />
            ) : selectedFilterKey === PaymentListFilterKey.BATCH ? (
              <PayListBatchesSection {...legacySectionProps} />
            ) : selectedFilterKey === PaymentListFilterKey.WAITING_TO_BE_PROCESSED ? (
              <PayListDetailsSection {...legacySectionProps} isNonBillable={false} />
            ) : selectedFilterKey === PaymentListFilterKey.REJECTED ? (
              <PayListRejectedSection {...legacySectionProps} />
            ) : selectedFilterKey === PaymentListFilterKey.WAIVED ? (
              <PayListWaivedSection {...legacySectionProps} />
            ) : selectedFilterKey === PaymentListFilterKey.SHIFT_HOURS ? (
              !pinc1035TimesheetsReskin ? (
                <ShiftHoursSection {...legacySectionProps} />
              ) : (
                <ListTimesheets />
              )
            ) : (
              <PayListDetailsSection {...allSectionProps} isNonBillable={true} />
            )}
          </PageContainer>
        </Box>
      </Flex>
    </div>
  );
};
