import React, { Component } from 'react';
import { Divider, Empty, Form, notification, Skeleton } from 'antd';
import _ from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { IFilter } from 'interfaces/filter-interfaces';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { Text } from 'common-components/typography';
import { PrimaryButton } from 'common-components/buttons';
import { RecommendedTeamMembersPane } from 'views/group-services/session-details/team-members/RecommendedTeamMembersPane';
import { ShiftSlotTableHeader } from 'views/group-services/session-details/team-members/shift-slot-table/ShiftSlotTableHeader';
import { ShiftSlotRowsSection } from 'views/group-services/session-details/team-members/ShiftSlotRowsSection';
import AssignWorkerModal from 'views/group-services/session-details/team-members/modals/AssignWorkerModal';
import RemoveShiftSlotModal from 'views/group-services/session-details/team-members/modals/RemoveShiftSlotModal';
import RemoveTeamMemberModal from 'views/group-services/session-details/team-members/modals/RemoveTeamMemberModal';
import EditSlotTimesModal from 'views/group-services/session-details/team-members/modals/EditSlotTimesModal';
import MarkAsNoShowModal from 'views/group-services/session-details/team-members/modals/MarkAsNoShowModal';
import MarkAsAttendedModal from 'views/group-services/session-details/team-members/modals/MarkAsAttendedModal';
import RevokeApprovalModal from 'views/group-services/session-details/team-members/modals/RevokeApprovalModal';
import StartShiftModal from 'views/group-services/session-details/team-members/modals/StartShiftModal';
import FinishShiftModal from 'views/group-services/session-details/team-members/modals/FinishShiftModal';
import EditStartTimeModal from 'views/group-services/session-details/team-members/modals/EditStartTimeModal';
import EditStartFinishTimeModal from 'views/group-services/session-details/team-members/modals/EditStartFinishTimeModal';
import ViewActivityLogModal from 'views/group-services/session-details/team-members/modals/ViewActivityLogModal';
import AddShiftSlotModal from 'views/group-services/session-details/team-members/modals/AddShiftSlotModal';
import ConfirmShiftSlotModal from 'views/group-services/session-details/team-members/modals/ConfirmShiftSlotModal';
import AssignShiftToCustomerModal from 'views/group-services/session-details/team-members/modals/AssignShiftToCustomersModal/AssignShiftToCustomersModal';
import UndoShiftModal from 'views/group-services/session-details/team-members/modals/UndoShiftModal';

import { FilterType } from 'utilities/enum-utils';
import { FilterSection } from 'common-components/filter';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import Search from 'antd/lib/input/Search';
import ApproveSlotModal from 'views/group-services/session-details/team-members/modals/ApproveSlotModal';
import BulkRemoveShiftSlotsModal from './modals/BulkRemoveShiftSlotsModal';
import BulkConfirmShiftSlotsModal from './modals/BulkConfirmShiftSlotsModal';
import BulkRemoveTeamMemberModal from './modals/BulkRemoveTeamMemberModal';
import BulkStartShiftModal from './modals/BulkStartShiftModal';
import BulkFinishShiftModal from './modals/BulkFinishShiftModal';
import BulkApproveShiftSlotModal from './modals/BulkApproveShiftSlotModal';
import BulkApproveLeaveModal from './modals/bulk-approve-leave-modal';
import ViewStaffingBreakdownDetailModal from './modals/ViewStaffingBreakdownDetailModal';
import { FormComponentProps } from 'antd/es/form';
import MarkAsOnLeaveModal from './modals/MarkAsOnLeaveModal';
import EditLeaveModal from './modals/EditLeaveModal';
import ApproveLeaveModal from './modals/ApproveLeaveModal';
import CancelLeaveModal from './modals/CancelLeaveModal';

export interface IShiftSlotActions {
  onAssignWorker: any;
  onRemoveShiftSlot: any;
  onEditSlotTimes: any;
  onViewActivityLog: any;
  onRemoveTeamMember: any;
  onStartShift: any;
  onMarkAsOnLeave: any;
  onMarkAsNoShow: any;
  onFinishShift: any;
  onEditStartTime: any;
  onEditStartFinishTime: any;
  onRevokeApproval: any;
  onMarkAsAttended: any;
  onConfirmSlot: any;
  onApproveSlot: any;
  onUndoShift: (shiftSlot: IShiftSlot) => void;

  onEditLeave: any;
  onApproveLeave: any;
  onCancelLeave: (shift: { shiftSlot: IShiftSlot }) => void;
  // Added by Jir
  onAssignToCustomer: any;
}

interface ISessionTeamMembersPanelProps extends FormComponentProps {
  selectedSession: typeof state.groupServiceStore.selectedSession;
  sessionShiftSlotOverview: typeof state.groupServiceStore.sessionShiftSlotOverview;
  selectedShiftSlots: typeof state.groupServiceStore.selectedShiftSlots;
  doFetchSessionShiftSlotOverview: typeof dispatch.groupServiceStore.doFetchSessionShiftSlotOverview;
  setSelectedShiftSlots: typeof dispatch.groupServiceStore.setSelectedShiftSlots;
  doFetchSingleSession: typeof dispatch.groupServiceStore.doFetchSingleSession;
  customerBookingToOpen: typeof state.groupServiceStore.customerBookingToOpen;
  setCustomerBookingToOpen: typeof dispatch.groupServiceStore.setCustomerBookingToOpen;
  flags: { [key: string]: boolean };
}

interface ISessionTeamMemberPanelState {
  // Modal flags
  isAddShiftSlotOpen: boolean;
  isAssignWorkerOpen: boolean;
  isRemoveShiftSlotOpen: boolean;
  isEditSlotTimesOpen: boolean;
  isViewActivityLogOpen: boolean;
  isRemoveTeamMemberOpen: boolean;
  isStartShiftOpen: boolean;
  isMarkAsOnLeaveOpen: boolean;
  isMarkAsNoShowOpen: boolean;
  isFinishShiftOpen: boolean;
  isEditStartTimeOpen: boolean;
  isEditStartFinishTimeOpen: boolean;
  isRevokeApprovalOpen: boolean;
  isMarkAsAttendedOpen: boolean;
  isConfirmShiftSlotOpen: boolean;
  isApproveShiftOpen: boolean;
  isAssignToCustomerOpen: boolean;
  isUndoShiftOpen: boolean;
  isEditLeaveOpen: boolean;
  isApproveLeaveOpen: boolean;
  isCancelLeaveOpen: boolean;

  // target slot
  targetShiftSlot?: IShiftSlot;
  filters: any;
  isLoading: boolean;
  isSearching: boolean;
  searchString: string;
  checkAll: boolean;
  isTaskSuccess: boolean;
  // bulk action
  indeterminate: boolean;
  toBeHandledBulkShiftSlots: IShiftSlot[];
  isOpenBulkRemoveShiftSlotModal: boolean;
  isOpenBulkConfirmShiftSlotModal: boolean;
  isOpenBulkRemoveTeamMemberModal: boolean;
  isOpenBulkStartShiftModal: boolean;
  isOpenBulkFinishShiftModal: boolean;
  isOpenBulkApproveShiftModal: boolean;
  isOpenBulkApproveLeaveModal: boolean;
  isOpenViewStaffingBreakdownDetailModal: boolean;

  highlightedShift?: string;
}

//region Default parameters
const availableFilters = [FilterType.SHIFT_SLOT_STATUS, FilterType.SHIFT_SLOT_WARNINGS];

const defaultFilters: IFilter[] = [
  {
    filter: FilterType.SHIFT_SLOT_STATUS,
    values: [],
    selectionLabel: 'All',
  },
];
//endregion

const SlotEmptyState = () => (
  <div className="flex-1 bg-white mt-x2-large align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No shift found.
    </Text>{' '}
    <Text color="secondary">All shift(s) under this filter will appear here.</Text>
  </div>
);

class SessionTeamMembersPanel extends Component<ISessionTeamMembersPanelProps, ISessionTeamMemberPanelState> {
  state = {
    isAddShiftSlotOpen: false,
    isAssignWorkerOpen: false,
    isRemoveShiftSlotOpen: false,
    isEditSlotTimesOpen: false,
    isViewActivityLogOpen: false,
    isRemoveTeamMemberOpen: false,
    isStartShiftOpen: false,
    isMarkAsOnLeaveOpen: false,
    isMarkAsNoShowOpen: false,
    isFinishShiftOpen: false,
    isEditStartTimeOpen: false,
    isEditStartFinishTimeOpen: false,
    isRevokeApprovalOpen: false,
    isMarkAsAttendedOpen: false,
    isConfirmShiftSlotOpen: false,
    isApproveShiftOpen: false,
    isAssignToCustomerOpen: false,
    isEditLeaveOpen: false,
    isApproveLeaveOpen: false,
    isCancelLeaveOpen: false,

    targetShiftSlot: null,
    filters: [...defaultFilters],
    isLoading: false,
    isSearching: false,
    searchString: null,
    checkAll: false,
    isTaskSuccess: false,
    // bulk action
    indeterminate: false,
    toBeHandledBulkShiftSlots: [],
    isOpenBulkRemoveShiftSlotModal: false,
    isOpenBulkConfirmShiftSlotModal: false,
    isOpenBulkRemoveTeamMemberModal: false,
    isOpenBulkStartShiftModal: false,
    isOpenBulkFinishShiftModal: false,
    isOpenBulkApproveShiftModal: false,
    isOpenBulkApproveLeaveModal: false,
    isOpenViewStaffingBreakdownDetailModal: false,
  } as ISessionTeamMemberPanelState;

  //region Action handlers. The following methods open/closes the corresponding modals
  private _onAddShiftSlot = () => {
    this.setState({ isAddShiftSlotOpen: true });
  };

  private _onAssignWorker = ({ shiftSlot }) => {
    this.setState({ isAssignWorkerOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onRemoveShiftSlot = ({ shiftSlot }) => {
    this.setState({ isRemoveShiftSlotOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onEditSlotTimes = ({ shiftSlot }) => {
    this.setState({ isEditSlotTimesOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onViewActivityLog = ({ shiftSlot }) => {
    this.setState({ isViewActivityLogOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onRemoveTeamMember = ({ shiftSlot }) => {
    this.setState({ isRemoveTeamMemberOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onStartShift = ({ shiftSlot }) => {
    this.setState({ isStartShiftOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onMarkAsOnLeave = ({ shiftSlot }) => {
    this.setState({ isMarkAsOnLeaveOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onMarkAsNoShow = ({ shiftSlot }) => {
    this.setState({ isMarkAsNoShowOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onFinishShift = ({ shiftSlot }) => {
    this.setState({ isFinishShiftOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onEditStartTime = ({ shiftSlot }) => {
    this.setState({ isEditStartTimeOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onRevokeApproval = ({ shiftSlot }) => {
    this.setState({ isRevokeApprovalOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onMarkAsAttended = ({ shiftSlot }) => {
    this.setState({ isMarkAsAttendedOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onEditStartFinishTime = ({ shiftSlot }) => {
    this.setState({ isEditStartFinishTimeOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onConfirmSlot = ({ shiftSlot }) => {
    this.setState({ isConfirmShiftSlotOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onApproveSlot = ({ shiftSlot }) => {
    this.setState({ isApproveShiftOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onAssignToCustomer = ({ shiftSlot }) => {
    this.setState({ isAssignToCustomerOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onUndoShift = (shiftSlot: IShiftSlot) => {
    this.setState({ isUndoShiftOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onEditLeave = ({ shiftSlot }) => {
    this.setState({ isEditLeaveOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onApproveLeave = ({ shiftSlot }) => {
    this.setState({ isApproveLeaveOpen: true, targetShiftSlot: shiftSlot });
  };

  public refreshList = async () => this._applyFilter();

  public refreshToBeHandledBulkShiftSlots = async () => {
    if (this.state.toBeHandledBulkShiftSlots?.length) {
      await this.refreshList();

      const toBeHandledAttendanceIds = this.state.toBeHandledBulkShiftSlots.map(
        (shiftSlot) => shiftSlot.supportWorkerAttendanceId,
      );
      const refreshedSlots = this.props.sessionShiftSlotOverview.shiftSlots.filter((shiftSlot) =>
        toBeHandledAttendanceIds.includes(shiftSlot.supportWorkerAttendanceId),
      );

      this.setState({
        toBeHandledBulkShiftSlots: refreshedSlots,
      });
    }
  };

  private _onCancelLeave = ({ shiftSlot }) => {
    this.setState({ isCancelLeaveOpen: true, targetShiftSlot: shiftSlot });
  };

  private _onCloseByFlag = ({ targetFlag }, refreshShiftSlots = false) => {
    // shortcut function to close by the target flag. targetFlag corresponds to a state flag.
    // eg isRemoveShiftSlotOpen / isAssignWorkerOpen
    // Used to reduce the amount of functions here
    let flag = {};
    flag[targetFlag] = false;

    this.setState(flag);

    if (refreshShiftSlots) {
      this._applyFilter();
    }
  };

  private _onTaskSuccess = () => {
    this.setState({ isTaskSuccess: true });
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.setState({ filters });
  };

  private _applyFilter = async () => {
    const requestFilter = this._formatFilterQuery(this.state.filters);
    const { selectedSession } = this.props;
    const { serviceId, serviceDateTimeId } = selectedSession;
    await this._loadContent({ serviceId, serviceDateTimeId, ...requestFilter });
  };

  private _formatFilterQuery = (filters) => {
    const requestFilter: any = {};
    _.forEach(filters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'shiftSlotStatus':
            requestFilter.shiftSlotStatus = filter.values;
            break;
          case 'showWarningsOnly':
            requestFilter.showWarningsOnly = filter.values[0] === 'YES';
            break;
          default:
            break;
        }
      }
    });

    return requestFilter;
  };

  private _searchText = async (txt) => {
    const { selectedSession } = this.props;
    const { serviceId, serviceDateTimeId } = selectedSession;
    const requestFilter = this._formatFilterQuery(this.state.filters);

    this.setState({ isSearching: true, searchString: txt });
    await this._loadContent({ serviceId, serviceDateTimeId, searchString: txt, ...requestFilter });
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this._debounceSearch(e.target.value);
  };

  private _onToggleCheckAll = () => {
    const newCheckAll = !this.state.checkAll;
    const newSelectedShiftSlots = newCheckAll ? _.cloneDeep(this.props.sessionShiftSlotOverview.shiftSlots) : [];
    this.props.setSelectedShiftSlots(newSelectedShiftSlots);
    this.setState({ indeterminate: false, checkAll: newCheckAll });
  };

  private _loadContent = async (payload) => {
    this.setState({ isLoading: true });
    this.props.setSelectedShiftSlots([]);
    await this.props.doFetchSessionShiftSlotOverview(payload);
    this.props.doFetchSingleSession({
      serviceId: this.props.selectedSession.serviceId,
      serviceDateTimeId: this.props.selectedSession.serviceDateTimeId,
    });
    this.setState({ isLoading: false, indeterminate: false, checkAll: false });
  };

  private _onSelectShiftSlot = (slot: IShiftSlot) => {
    const newSelectedShiftSlots = [...this.props.selectedShiftSlots, slot];
    this.props.setSelectedShiftSlots(newSelectedShiftSlots);

    if (newSelectedShiftSlots.length === this.props.sessionShiftSlotOverview.shiftSlots.length) {
      this.setState({ indeterminate: false, checkAll: true });
    } else {
      this.setState({ indeterminate: true });
    }
  };

  private _onDeselectShiftSlot = (slot: IShiftSlot) => {
    const newSelectedShiftSlots = _.filter(
      this.props.selectedShiftSlots,
      (selectedSlot) => slot.supportWorkerAttendanceId !== selectedSlot.supportWorkerAttendanceId,
    );
    this.props.setSelectedShiftSlots(newSelectedShiftSlots);

    // Check the need to set the status of indeterminate and checkAll.
    if (newSelectedShiftSlots.length === 0) {
      this.setState({ indeterminate: false, checkAll: false });
    } else if (this.state.checkAll) {
      this.setState({ indeterminate: true });
    }
  };

  public onDeselectAllShiftSlots = () => {
    this.props.setSelectedShiftSlots([]);
    this.setState({ checkAll: false, indeterminate: false });
  };

  private _getFilteredSelectedItemsForModal = (selectionHistory) => {
    const { selectedShiftSlots } = this.props;
    const shiftSlotStatus = selectionHistory && selectionHistory[0];
    return shiftSlotStatus
      ? selectedShiftSlots.filter((shiftSlot) => shiftSlot.shiftSlotStatus === shiftSlotStatus)
      : null;
  };

  public onOpenBulkRemoveShiftSlotModal = (selectionHistory) => {
    this.setState({
      isOpenBulkRemoveShiftSlotModal: true,
      toBeHandledBulkShiftSlots: this._getFilteredSelectedItemsForModal(selectionHistory),
    });
  };
  public onOpenBulkConfirmShiftSlotModal = (selectionHistory) => {
    this.setState({
      isOpenBulkConfirmShiftSlotModal: true,
      toBeHandledBulkShiftSlots: this._getFilteredSelectedItemsForModal(selectionHistory),
    });
  };
  public onOpenBulkRemoveTeamMemberModal = (selectionHistory) => {
    this.setState({
      isOpenBulkRemoveTeamMemberModal: true,
      toBeHandledBulkShiftSlots: this._getFilteredSelectedItemsForModal(selectionHistory),
    });
  };
  public onOpenBulkStartShiftSlotModal = (selectionHistory) => {
    this.setState({
      isOpenBulkStartShiftModal: true,
      toBeHandledBulkShiftSlots: this._getFilteredSelectedItemsForModal(selectionHistory),
    });
  };
  public onOpenBulkFinishShiftSlotModal = (selectionHistory) => {
    this.setState({
      isOpenBulkFinishShiftModal: true,
      toBeHandledBulkShiftSlots: this._getFilteredSelectedItemsForModal(selectionHistory),
    });
  };
  public onOpenBulkApproveShiftSlotModal = (selectionHistory) => {
    this.setState({
      isOpenBulkApproveShiftModal: true,
      toBeHandledBulkShiftSlots: this._getFilteredSelectedItemsForModal(selectionHistory),
    });
  };

  public onOpenBulkApproveLeaveModal = (selectionHistory) => {
    this.setState({
      isOpenBulkApproveLeaveModal: true,
      toBeHandledBulkShiftSlots: this._getFilteredSelectedItemsForModal(selectionHistory),
    });
  };

  private _onCloseBulkRemoveShiftSlotModal = () => {
    this.setState({ isOpenBulkRemoveShiftSlotModal: false });
  };

  private _onCloseBulkConfirmShiftSlotModal = () => {
    this.setState({ isOpenBulkConfirmShiftSlotModal: false });
  };

  private _onCloseBulkRemoveTeamMemberModal = () => {
    this.setState({ isOpenBulkRemoveTeamMemberModal: false });
  };

  private _onCloseBulkStartShiftModal = () => {
    this.setState({ isOpenBulkStartShiftModal: false });
  };

  private _onCloseBulkFinishShiftModal = () => {
    this.setState({ isOpenBulkFinishShiftModal: false });
  };

  private _onCloseBulkApproveShiftModal = () => {
    this.setState({ isOpenBulkApproveShiftModal: false });
  };

  private _onCloseBulkApproveLeaveModal = () => {
    this.setState({ isOpenBulkApproveLeaveModal: false });
  };

  private _onOpenViewStaffingBreakdownDetailModal = () => {
    this.setState({ isOpenViewStaffingBreakdownDetailModal: true });
  };

  private _onCloseViewStaffingBreakdownDetailModal = () => {
    this.setState({ isOpenViewStaffingBreakdownDetailModal: false });
  };

  componentDidMount = async () => {
    const { serviceId, serviceDateTimeId } = this.props.selectedSession;
    const { customerBookingToOpen, setCustomerBookingToOpen } = this.props;
    await this._loadContent({ serviceId, serviceDateTimeId });
    if (customerBookingToOpen) {
      this.setState({ highlightedShift: customerBookingToOpen.attendanceId });
      await setCustomerBookingToOpen(null);
    }
  };

  componentDidUpdate = async (_prevProps, prevState) => {
    if (this.state.filters !== prevState.filters) {
      await this._applyFilter();
    }

    if (this.state.isTaskSuccess && this.state.isTaskSuccess !== prevState.isTaskSuccess) {
      const { selectedSession } = this.props;
      const { serviceId, serviceDateTimeId } = selectedSession;
      await this._loadContent({ serviceId, serviceDateTimeId });
      this.setState({ isTaskSuccess: false });
    }
  };

  render() {
    const { selectedSession, sessionShiftSlotOverview, selectedShiftSlots, flags } = this.props;
    const { isLoading, checkAll, toBeHandledBulkShiftSlots } = this.state;
    const shiftSlots = sessionShiftSlotOverview ? sessionShiftSlotOverview.shiftSlots : [];
    const { serviceId, serviceDateTimeId, timezone } = selectedSession;
    const { pinc1022HideStaffingOverview } = flags;

    const actions: IShiftSlotActions = {
      onAssignWorker: this._onAssignWorker,
      onRemoveTeamMember: this._onRemoveTeamMember,
      onRemoveShiftSlot: this._onRemoveShiftSlot,
      onEditSlotTimes: this._onEditSlotTimes,
      onViewActivityLog: this._onViewActivityLog,
      onStartShift: this._onStartShift,
      onMarkAsOnLeave: this._onMarkAsOnLeave,
      onMarkAsNoShow: this._onMarkAsNoShow,
      onFinishShift: this._onFinishShift,
      onEditStartTime: this._onEditStartTime,
      onRevokeApproval: this._onRevokeApproval,
      onMarkAsAttended: this._onMarkAsAttended,
      onEditStartFinishTime: this._onEditStartFinishTime,
      onConfirmSlot: this._onConfirmSlot,
      onApproveSlot: this._onApproveSlot,
      onAssignToCustomer: this._onAssignToCustomer,
      onUndoShift: this._onUndoShift,
      onEditLeave: this._onEditLeave,
      onApproveLeave: this._onApproveLeave,
      onCancelLeave: this._onCancelLeave,
    };

    return (
      <>
        <div className="pt-medium pb-x10-large">
          {/* Recommended team members counter */}
          {!_.isEmpty(selectedSession?.sessionTimeSlots) && !pinc1022HideStaffingOverview && (
            <RecommendedTeamMembersPane
              selectedSession={selectedSession}
              onOpenViewDetailModal={this._onOpenViewStaffingBreakdownDetailModal}
            />
          )}

          {/* Content container  */}
          <div className="ph-large pv-large rounded-big shadow-container bg-white flex-1 line-height-135">
            {/* Header section */}
            <section className="line-height-135 flex-row justify-between align-center">
              {/* Label section */}
              <div>
                <div className="mb-x2-small">
                  <Text weight="bold" lineHeight={135}>
                    Shifts
                  </Text>
                </div>
                <Text lineHeight={135}>Team member shifts for this session</Text>
              </div>

              <PrimaryButton icon="plus" size="large" onClick={this._onAddShiftSlot}>
                Add shift
              </PrimaryButton>
            </section>

            <Divider className="mt-large mb-none" />

            {/* Filter section */}
            <div className="flex-row align-center">
              <Search
                placeholder="Search for.."
                onChange={this._onEnterSearchText}
                loading={this.state.isSearching}
                className={'mr-medium'}
                style={{ width: '240px', marginBottom: '6px' }}
                allowClear
              />
              <FilterSection
                availableFilters={availableFilters}
                filters={this.state.filters}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={''}
              />
            </div>

            {/* Shift rows section */}
            <section>
              {/* Table header */}
              <div>
                <ShiftSlotTableHeader
                  checkAll={checkAll}
                  onToggleCheckAll={this._onToggleCheckAll}
                  indeterminate={this.state.indeterminate}
                />
                {isLoading ? (
                  <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                ) : shiftSlots && shiftSlots.length > 0 ? (
                  <ShiftSlotRowsSection
                    shiftSlots={shiftSlots}
                    selectedShiftSlots={selectedShiftSlots}
                    actions={actions}
                    // checkAll={checkAll}
                    session={selectedSession}
                    timezone={selectedSession && selectedSession.timezone}
                    onSelectShiftSlot={this._onSelectShiftSlot}
                    onDeselectShiftSlot={this._onDeselectShiftSlot}
                    highlightedShift={this.state.highlightedShift}
                  />
                ) : (
                  <SlotEmptyState />
                )}
              </div>
            </section>
          </div>
        </div>

        {/* Action modals */}
        <>
          {/* Add shift modal */}
          <AddShiftSlotModal
            isOpen={this.state.isAddShiftSlotOpen}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Assign worker */}
          <AssignWorkerModal
            isOpen={this.state.isAssignWorkerOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Remove shift */}
          <RemoveShiftSlotModal
            isOpen={this.state.isRemoveShiftSlotOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Remove team member */}
          <RemoveTeamMemberModal
            isOpen={this.state.isRemoveTeamMemberOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Edit slot times */}
          <EditSlotTimesModal
            isOpen={this.state.isEditSlotTimesOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          <MarkAsOnLeaveModal
            isOpen={this.state.isMarkAsOnLeaveOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Mark as no show */}
          <MarkAsNoShowModal
            isOpen={this.state.isMarkAsNoShowOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Mark as Attended */}
          <MarkAsAttendedModal
            isOpen={this.state.isMarkAsAttendedOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Revoke approval */}
          <RevokeApprovalModal
            isOpen={this.state.isRevokeApprovalOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Start shift */}
          <StartShiftModal
            isOpen={this.state.isStartShiftOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Finish shift */}
          <FinishShiftModal
            isOpen={this.state.isFinishShiftOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Edit start time */}
          <EditStartTimeModal
            isOpen={this.state.isEditStartTimeOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Edit Start/Finish time */}
          <EditStartFinishTimeModal
            isOpen={this.state.isEditStartFinishTimeOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* View activity log */}
          <ViewActivityLogModal
            isOpen={this.state.isViewActivityLogOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Confirm shift */}
          <ConfirmShiftSlotModal
            isOpen={this.state.isConfirmShiftSlotOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Approve shift */}
          <ApproveSlotModal
            isOpen={this.state.isApproveShiftOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          <ApproveLeaveModal
            isOpen={this.state.isApproveLeaveOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Assign to customer */}
          <AssignShiftToCustomerModal
            isOpen={this.state.isAssignToCustomerOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          <EditLeaveModal
            isOpen={this.state.isEditLeaveOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          <CancelLeaveModal
            isOpen={this.state.isCancelLeaveOpen}
            shiftSlot={this.state.targetShiftSlot}
            onClose={this._onCloseByFlag}
            session={selectedSession}
          />

          {/* Bulk action modals */}
          <BulkRemoveShiftSlotsModal
            isOpen={this.state.isOpenBulkRemoveShiftSlotModal}
            onClose={this._onCloseBulkRemoveShiftSlotModal}
            onTaskSuccess={this._onTaskSuccess}
            toBeRemovedShiftSlots={toBeHandledBulkShiftSlots}
            serviceId={serviceId}
            serviceDateTimeId={serviceDateTimeId}
          />

          <BulkConfirmShiftSlotsModal
            isOpen={this.state.isOpenBulkConfirmShiftSlotModal}
            onClose={this._onCloseBulkConfirmShiftSlotModal}
            onTaskSuccess={this._onTaskSuccess}
            toBeConfirmedShiftSlots={toBeHandledBulkShiftSlots}
            serviceId={serviceId}
            serviceDateTimeId={serviceDateTimeId}
            timezone={timezone}
          />

          <BulkRemoveTeamMemberModal
            isOpen={this.state.isOpenBulkRemoveTeamMemberModal}
            onClose={this._onCloseBulkRemoveTeamMemberModal}
            onTaskSuccess={this._onTaskSuccess}
            toBeRemovedShiftSlots={toBeHandledBulkShiftSlots}
            serviceId={serviceId}
            serviceDateTimeId={serviceDateTimeId}
          />

          {this.state.isOpenBulkStartShiftModal && (
            <BulkStartShiftModal
              isOpen={this.state.isOpenBulkStartShiftModal}
              onClose={this._onCloseBulkStartShiftModal}
              onTaskSuccess={this._onTaskSuccess}
              toBeStartedShiftSlots={toBeHandledBulkShiftSlots}
              serviceId={serviceId}
              serviceDateTimeId={serviceDateTimeId}
              timezone={timezone}
            />
          )}

          <BulkFinishShiftModal
            isOpen={this.state.isOpenBulkFinishShiftModal}
            onClose={this._onCloseBulkFinishShiftModal}
            onTaskSuccess={this._onTaskSuccess}
            toBeFinishedShiftSlots={toBeHandledBulkShiftSlots}
            serviceId={serviceId}
            serviceDateTimeId={serviceDateTimeId}
            timezone={timezone}
          />

          <BulkApproveShiftSlotModal
            isOpen={this.state.isOpenBulkApproveShiftModal}
            onClose={this._onCloseBulkApproveShiftModal}
            onTaskSuccess={this._onTaskSuccess}
            toBeApprovedShiftSlots={toBeHandledBulkShiftSlots}
            serviceId={serviceId}
            serviceDateTimeId={serviceDateTimeId}
            session={selectedSession}
          />

          <BulkApproveLeaveModal
            isOpen={this.state.isOpenBulkApproveLeaveModal}
            onClose={this._onCloseBulkApproveLeaveModal}
            onTaskSuccess={this._onTaskSuccess}
            onUpdatedShift={this.refreshToBeHandledBulkShiftSlots}
            toBeApprovedShiftSlots={toBeHandledBulkShiftSlots}
            serviceId={serviceId}
            serviceDateTimeId={serviceDateTimeId}
            session={selectedSession}
            timezone={timezone}
          />

          {/* View detail staffing breakdown modal*/}
          {!_.isEmpty(selectedSession?.sessionTimeSlots) && (
            <ViewStaffingBreakdownDetailModal
              isOpen={this.state.isOpenViewStaffingBreakdownDetailModal}
              onClose={this._onCloseViewStaffingBreakdownDetailModal}
              selectedSession={selectedSession}
            />
          )}

          <UndoShiftModal
            isOpen={this.state.isUndoShiftOpen}
            onClose={this._onCloseByFlag}
            serviceId={serviceId}
            serviceDateTimeId={serviceDateTimeId}
            supportWorkerAttendanceId={this.state.targetShiftSlot?.supportWorkerAttendanceId}
            shiftSlotStatus={this.state.targetShiftSlot?.shiftSlotStatus}
          />
        </>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  sessionShiftSlotOverview: state.groupServiceStore.sessionShiftSlotOverview,
  selectedShiftSlots: state.groupServiceStore.selectedShiftSlots,
  customerBookingToOpen: state.groupServiceStore.customerBookingToOpen,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedShiftSlots: dispatch.groupServiceStore.setSelectedShiftSlots,
  doFetchSessionShiftSlotOverview: dispatch.groupServiceStore.doFetchSessionShiftSlotOverview,
  doFetchSingleSession: dispatch.groupServiceStore.doFetchSingleSession,
  setCustomerBookingToOpen: dispatch.groupServiceStore.setCustomerBookingToOpen,
});

export default connect(
  mapState,
  mapDispatch,
)(withLDConsumer()(Form.create<ISessionTeamMembersPanelProps>()(SessionTeamMembersPanel)));
