import { forwardRef } from 'react';
import clsx from 'clsx';

import type { HTMLAttributes, PropsWithChildren } from 'react';

export type IconProps = PropsWithChildren<Omit<HTMLAttributes<SVGElement>, 'style'>>;

/**
 * @name
 * Icon
 *
 * @description
 * Icons are visual representations of commands, devices, directories, or common actions.
 *
 * (source: https://atlassian.design/foundations/iconography)
 */
export const Icon = forwardRef<SVGSVGElement, IconProps>(function Icon(props, ref) {
  const { children, className, ...otherProps } = props;

  return (
    <svg
      role="presentation"
      {...otherProps}
      xmlns="http://www.w3.org/2000/svg"
      fill="transparent"
      viewBox="0 0 24 24"
      className={clsx('inline-block w-[1.25em] h-[1.25em] min-w-[1.25em] min-h-[1.25em]', className)}
      ref={ref}
    >
      {children}
    </svg>
  );
});
