import { AutocompleteProps, NumberInputProps, SelectProps, TextInputProps } from '@good/ui/core';

export type FieldError = { type: string; message?: string };
export type FieldUnion = AutocompleteProps | TextInputProps | NumberInputProps | SelectProps;
export type FieldUnionProps = Pick<FieldUnion, 'onBlur' | 'disabled' | 'className' | 'style' | 'size'>;

export type ValidationFn = (value: string | undefined | null) => boolean | string;
export type ValidationFns = Record<string, ValidationFn>;
export type ValidationRules = Record<keyof AddressInputFieldErrors, ValidationFns>;

export type AddressInputValue = {
  address: Address;
  fieldState: {
    value: string;
    hasErrors: boolean;
  };
};

export enum State {
  VIC = 'VIC',
  NSW = 'NSW',
  QLD = 'QLD',
  SA = 'SA',
  WA = 'WA',
  TAS = 'TAS',
  ACT = 'ACT',
  NT = 'NT',
}

export type Address = {
  fullAddress: string;
  streetAddress1: string | undefined;
  streetAddress2?: string | undefined;
  locality: string | undefined;
  state: State | string | undefined | null;
  postcode: string | undefined;
  geoLat: number | undefined | null;
  geoLng: number | undefined | null;
};

export type AddressInputValues = {
  automaticInput: string | undefined;
  'manualInput.streetAddress1': string | undefined;
  'manualInput.streetAddress2'?: string | undefined;
  'manualInput.locality': string | undefined;
  'manualInput.state': State | string | undefined | null;
  'manualInput.postcode': string | undefined;
};

export type AddressInputFieldErrors = {
  automaticInput: FieldError | undefined;
  'manualInput.streetAddress1'?: FieldError | undefined;
  'manualInput.streetAddress2'?: FieldError | undefined;
  'manualInput.locality'?: FieldError | undefined;
  'manualInput.state'?: FieldError | undefined;
  'manualInput.postcode'?: FieldError | undefined;
};
