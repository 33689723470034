import React, { Component } from 'react';
import qs from 'query-string';
import asyncDelay from 'utilities/asyncDelay';
import { Text, Title } from 'common-components/typography';

class RedirectToView extends Component<any, any> {
  async componentDidMount() {
    const { location } = this.props;
    const query = qs.parse(location.search);
    let url = query.url;
    // query.to is the Type (zendesk etc)
    if (query.to === 'zendesk') {
      url += `&return_to=${query.return_to}`;
      if (location.hash && location.hash !== '') {
        url += location.hash.toString();
      }
    }
    await asyncDelay(1500);

    // @ts-ignore
    window.location.href = url;
  }

  render() {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ verticalAlign: 'middle' }}>
          <Title level={3}>Redirecting to Zendesk .....</Title>
        </div>
      </div>
    );
  }
}

export default RedirectToView;
