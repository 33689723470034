import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import CustomerDetailsPanel from './CustomerDetailsPanel';
import { CustomerDetailView } from './customer-detail-view';

type Props = RouteComponentProps;

export const CustomerDetailPanel = (props: Props): JSX.Element => {
  const { roo447CustomerProfileReArchitecture: isProfileV2 } = useFlags();
  return isProfileV2 ? <CustomerDetailView /> : <CustomerDetailsPanel {...props} />;
};
