import { RadioGroup } from 'design-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserPermissionRole } from 'utilities/enum-utils';
import { ADVANCED_PERMISSION_ROLE_TYPE } from 'views/team-v2/utils/constants';
import ActionSelectedItem from '../ActionSelectedItem';
import AdvancedPermissionRow from '../AdvancedPermissionRow';
import { Box, Link, Stack, Text } from '@goodhuman-me/components';

interface IProps {
  accessRoleId: number;
  viewRoleId: number;
  changePermissionForEachRole?: (value: number, permissionKey: string) => void;
  typePermissionPresent: string;
  handleSetTypePermissionPresent: (value: string) => void;
}

const PermissionCustomerSectionWithViewAndAccess: React.FC<IProps> = ({
  accessRoleId = UserPermissionRole.CustomerAccessViewOnly,
  viewRoleId = UserPermissionRole.CustomerViewAll,
  changePermissionForEachRole,
  typePermissionPresent,
  handleSetTypePermissionPresent,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'permissions.customers' });

  return (
    <AdvancedPermissionRow
      title="Customer"
      description="Customer profiles and data"
      type={ADVANCED_PERMISSION_ROLE_TYPE.CUSTOMER}
      permissionRoleId={accessRoleId}
      typePermissionPresent={typePermissionPresent}
      handleSetTypePermissionPresent={handleSetTypePermissionPresent}
      extendPermissions={
        <Stack gap="$small">
          <Stack gap="$small">
            <Box marginLeft="$space100">
              <Text fontWeight="$semi" size="small" color="$default">
                {t('viewPermissionRadio.label')}
              </Text>
            </Box>
            <RadioGroup
              aria-label="customer-view"
              value={viewRoleId}
              onChange={(value) => changePermissionForEachRole(value, 'customerView')}
            >
              <ActionSelectedItem
                ariaLabel="customer-view-all"
                label={<Text color="$bodyDark2">{t('viewPermissionRadio.optionAll')}</Text>}
                value={UserPermissionRole.CustomerViewAll}
                selectedPermission={viewRoleId}
              />
              <ActionSelectedItem
                ariaLabel="customer-view-service-only"
                label={
                  <Stack gap="$xxsmall">
                    <Text color="$bodyDark2">{t('viewPermissionRadio.optionService')}</Text>
                    <Text size="xxsmall" color="$muted" fontWeight="light" width="$size5000">
                      {t('viewPermissionRadio.optionServiceInfo')}
                    </Text>
                    <Text size="xxsmall">
                      <Link asChild>
                        <a href={t('viewPermissionRadio.optionServiceLinkUrl')} target="_blank" rel="noreferrer">
                          {t('viewPermissionRadio.optionServiceLinkLabel')}
                        </a>
                      </Link>
                    </Text>
                  </Stack>
                }
                value={UserPermissionRole.CustomerViewServiceOnly}
                selectedPermission={viewRoleId}
              />
            </RadioGroup>
          </Stack>
          <Stack gap="$small">
            <Box marginLeft="$space100">
              <Text size="small" color="$default" fontWeight="$semi">
                {t('editPermissionRadio.label')}
              </Text>
            </Box>
            <RadioGroup
              aria-label="customer-access"
              value={accessRoleId}
              onChange={(value) => changePermissionForEachRole(value, 'customerAccess')}
            >
              <ActionSelectedItem
                ariaLabel="customer-access-view-only"
                label={<Text color="$bodyDark2">{t('editPermissionRadio.optionView')}</Text>}
                value={UserPermissionRole.CustomerAccessViewOnly}
                selectedPermission={accessRoleId}
              />
              <ActionSelectedItem
                ariaLabel="customer-access-manager"
                label={<Text color="$bodyDark2">{t('editPermissionRadio.optionManage')}</Text>}
                value={UserPermissionRole.CustomerAccessManager}
                selectedPermission={accessRoleId}
              />
            </RadioGroup>
          </Stack>
        </Stack>
      }
    />
  );
};

export default PermissionCustomerSectionWithViewAndAccess;
