import React from 'react';
import { Text } from 'common-components/typography';
import moment from 'moment-timezone';
import _ from 'lodash';
import { AvailabilityStatusTag } from 'common-components/tags';
import { AvailabilityUnavailabilityRequestStatus } from 'utilities/enum-utils';

interface ICustomUnavailabilityWeekDays {
  timeRange: {
    endTime: Date;
    startTime: Date;
  }[];
  weekDayId: number;
  unavailabilityOption: number;
}

interface IUnavailabilityItem {
  supportWorkerUnavailabilityId: string;
  unavailabilityType: number;
  startTime: Date;
  endTime: Date;
  startDate: Date;
  endDate: Date;
  comment: string;
  timezone: string;
  isAllDay: boolean;
  customUnavailabilityWeekDays: ICustomUnavailabilityWeekDays[];
  status: AvailabilityUnavailabilityRequestStatus;
  updatedBy: string;
  updatedDate: Date;
}

interface IUnavailabilityCardProps {
  item: IUnavailabilityItem;
  onCancel: (item: IUnavailabilityItem) => void;
}

const weekDayParser = (number: number) => {
  switch (number) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return '';
  }
};

const renderFullDayItems = (list: ICustomUnavailabilityWeekDays[], unavailableItem: IUnavailabilityItem) => {
  const fullDayItems = _.filter(list, (day) => day.unavailabilityOption === 0);
  const daysString = _.map(fullDayItems, (day) => weekDayParser(day.weekDayId)).join(' & ');

  return (
    !_.isEmpty(fullDayItems) && (
      <>
        <Text className="mb-large text-size-regular">
          Every {daysString}{' '}
          <Text type="secondary" className="text-size-regular">
            (until {moment(unavailableItem.endDate).format('DD MMM YYYY')})
          </Text>
        </Text>
        <Text className="mb-x2-small text-size-regular">All day(s)</Text>
      </>
    )
  );
};

const renderAvailableItems = (
  customUnavailabilityWeekDayList: ICustomUnavailabilityWeekDays[],
  unavailableItem: IUnavailabilityItem,
) => {
  const daysString = _.map(customUnavailabilityWeekDayList, (day) => weekDayParser(day.weekDayId)).join(' & ');

  return (
    !_.isEmpty(customUnavailabilityWeekDayList) && (
      <>
        <Text className="mb-x2-small text-size-regular">Available all day</Text>
        <Text className="mb-large text-size-regular">
          Every {daysString}{' '}
          <Text type="secondary" className="text-size-regular">
            (until {moment(unavailableItem.endDate).format('DD MMM YYYY')})
          </Text>
        </Text>
      </>
    )
  );
};

const renderSpecificItems = (
  customUnavailabilityWeekDayList: ICustomUnavailabilityWeekDays[],
  unavailableItem: IUnavailabilityItem,
) => {
  const specificItems = _.filter(customUnavailabilityWeekDayList, (day) => day.unavailabilityOption !== 0);
  const timeRangeLengthEqualOne = _.filter(specificItems, (day) => day.timeRange.length === 1);
  const theRest = _.filter(specificItems, (day) => day.timeRange.length !== 1);
  const singleTimeRangeList = {};

  _.forEach(timeRangeLengthEqualOne, (day) => {
    const timeRange = day.timeRange;

    const hourString =
      moment.tz(timeRange[0].startTime, unavailableItem.timezone).format('h:mm a') +
      ' - ' +
      moment.tz(timeRange[0].endTime, unavailableItem.timezone).format('h:mm a');

    if (singleTimeRangeList[hourString]) {
      const newList = [...singleTimeRangeList[hourString]];
      newList.push(weekDayParser(day.weekDayId));
      singleTimeRangeList[hourString] = newList;
    } else {
      singleTimeRangeList[hourString] = [weekDayParser(day.weekDayId)];
    }
  });

  return (
    !_.isEmpty(specificItems) && (
      <>
        {_.map(theRest, (day) => (
          <>
            <Text className="mb-x2-small text-size-regular">
              {_.map(day.timeRange, (time, index) => (
                <Text key={index} className="text-size-regular block">
                  {moment.tz(time.startTime, unavailableItem.timezone).format('h:mm a')} -{' '}
                  {moment.tz(time.endTime, unavailableItem.timezone).format('h:mm a')}
                </Text>
              ))}
            </Text>
            <Text className="mb-large text-size-regular">
              Every {weekDayParser(day.weekDayId)}{' '}
              <Text type="secondary" className="text-size-regular">
                (until {moment(unavailableItem.endDate).format('DD MMM YYYY')})
              </Text>
            </Text>
          </>
        ))}
        {_.map(Object.keys(singleTimeRangeList), (timeRange) => (
          <>
            <Text className="mb-x2-small text-size-regular">{timeRange}</Text>
            <Text className="mb-large text-size-regular">
              Every {singleTimeRangeList[timeRange].join(' & ')}{' '}
              <Text type="secondary" className="text-size-regular">
                (until {moment(unavailableItem.endDate).format('DD MMM YYYY')})
              </Text>
            </Text>
          </>
        ))}
      </>
    )
  );
};

const singleDayRender = (list: ICustomUnavailabilityWeekDays[], unavailableItem: IUnavailabilityItem) => {
  return (
    !_.isEmpty(list) && (
      <>
        <Text className="mb-x2-small text-size-regular">
          {list[0].unavailabilityOption === 0
            ? 'All day(s)'
            : list[0].unavailabilityOption === 1
            ? 'Available all day'
            : _.map(list[0].timeRange, (time, index) => (
                <Text key={index} className="text-size-regular block">
                  {moment.tz(time.startTime, unavailableItem.timezone).format('h:mm a')} -{' '}
                  {moment.tz(time.endTime, unavailableItem.timezone).format('h:mm a')}
                </Text>
              ))}
        </Text>
        <Text className="mb-large text-size-regular">
          {weekDayParser(list[0].weekDayId)}{' '}
          <Text className="text-size-regular">
            {moment.tz(unavailableItem.endDate, unavailableItem.timezone).format('DD MMM YYYY')}
          </Text>
        </Text>
      </>
    )
  );
};

const renderUnavailableDateTime = (item: IUnavailabilityItem) => {
  switch (item.unavailabilityType) {
    case 1:
      return !(item.startTime && item.endTime) ? (
        <>
          <Text className="text-size-regular text-weight-bolder">
            {moment.tz(item.startDate, item.timezone).format('ddd, DD MMM YYYY')}{' '}
            <Text className="text-color-secondary text-size-regular">to</Text>{' '}
            {moment.tz(item.endDate, item.timezone).format('ddd, DD MMM YYYY')}
          </Text>
          <Text className="text-size-regular">All day(s)</Text>
        </>
      ) : (
        <Text className="text-size-regular text-weight-bolder">
          {moment.tz(item.startTime, item.timezone).format('h:mm a')}{' '}
          {moment.tz(item.startDate, item.timezone).format('ddd, DD MMM YYYY')}{' '}
          <Text className="text-color-secondary text-size-regular">to</Text>{' '}
          {moment.tz(item.endTime, item.timezone).format('h:mm a')}{' '}
          {moment.tz(item.endDate, item.timezone).format('ddd, DD MMM YYYY')}
        </Text>
      );
    case 2: {
      const timeListWithoutAvailable = _.filter(
        item.customUnavailabilityWeekDays,
        (day) => day.unavailabilityOption !== 1,
      );

      const availableTimeList = _.filter(item.customUnavailabilityWeekDays, (day) => day.unavailabilityOption === 1);

      const period = moment(item.endDate).diff(item.startDate, 'days');
      return period > 0 ? (
        <>
          {renderFullDayItems(timeListWithoutAvailable, item)}
          {renderSpecificItems(timeListWithoutAvailable, item)}
          {renderAvailableItems(availableTimeList, item)}
        </>
      ) : (
        <>{singleDayRender(item.customUnavailabilityWeekDays, item)}</>
      );
    }
    default:
      return <></>;
  }
};

export default function UnavailabilityCard({ item, onCancel }: IUnavailabilityCardProps) {
  let itemTitle = 'Unavailable';
  if (item.unavailabilityType === 2) {
    const availableTimeList = _.filter(item.customUnavailabilityWeekDays, (day) => day.unavailabilityOption === 1);
    if (availableTimeList.length === item.customUnavailabilityWeekDays.length) {
      itemTitle = 'Available';
    }
  }
  return (
    <div
      className="pv-12 ph-medium rounded-big mb-medium bg-white"
      style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05)' }}
    >
      <div className="flex-row justify-between align-center">
        <div className="flex-row align-center">
          <Text className="text-size-large mr-12" weight="bold">
            {itemTitle}
          </Text>
          <AvailabilityStatusTag status={item.status} />
        </div>
        <div className="action flex-row">
          {item.status !== AvailabilityUnavailabilityRequestStatus.COMPLETED && (
            <Text className="text-color-red cursor-pointer text-size-regular ml-medium" onClick={() => onCancel(item)}>
              Cancel
            </Text>
          )}
        </div>
      </div>
      <div className="date-time mt-small flex-column">{renderUnavailableDateTime(item)}</div>
      {item.comment !== null && (
        <div className="description mt-small text-color-secondary text-size-regular">{item.comment}</div>
      )}
      <div className="description mt-small text-color-secondary text-size-regular">
        Approved by <b>{item.updatedBy}</b> on{' '}
        <b>{moment.tz(item.updatedDate, item.timezone).format('DD MMM YYYY, hh:mm A')}</b>
      </div>
    </div>
  );
}
