import React from 'react';

import { IBookingRatio } from 'interfaces/booking-interfaces';
import { IAssignedBookingRatio, IBookingTimeSlot } from 'interfaces/session-interfaces';
import { TeamMemberCustomerRatio } from 'utilities/enum-utils';
import AssignedRatioRow from './AssignedRatioRow';
import TwoToOneBookingRatioPanel from './TwoToOneBookingRatioPanel';
import UnassignedRatioRow from './UnassignedRatioRow';

interface IAssignBookingRatiosPanelProps {
  assignedBookingRatios: IAssignedBookingRatio[];
  timezone: string;
  onChangeTeamMember: (timeSlot: IBookingTimeSlot) => void;
}

function AssignBookingRatiosPanel(props: IAssignBookingRatiosPanelProps): JSX.Element {
  const { assignedBookingRatios, timezone, onChangeTeamMember } = props;

  const checkTwoToOneTeamMemberCustomerRatio = (ratio: IBookingRatio) => {
    return ratio.teamMemberCustomerRatio === TeamMemberCustomerRatio.TWO_TO_ONE;
  };

  return (
    <>
      {assignedBookingRatios.map((assignedRatio, index) => {
        const { supportWorker, supportWorkers, ratio } = assignedRatio as IAssignedBookingRatio;

        const className =
          assignedBookingRatios.length === 1
            ? 'rounded-big-bottom bordered-top'
            : index === 0
            ? 'bordered-top'
            : index === assignedBookingRatios.length - 1
            ? 'rounded-big-bottom'
            : '';

        if (checkTwoToOneTeamMemberCustomerRatio(ratio)) {
          const isTheLastBookingRatio = index === assignedBookingRatios.length - 1;
          return (
            <TwoToOneBookingRatioPanel
              ratio={ratio}
              supportWorkers={supportWorkers}
              isTheLastBookingRatio={isTheLastBookingRatio}
              className={className}
              onChangeTeamMember={onChangeTeamMember}
              timezone={timezone}
              key={ratio.attendanceTimeSlotId}
            />
          );
        }

        if (supportWorker) {
          return (
            <div
              key={ratio.attendanceTimeSlotId}
              className={`bordered-bottom bordered-left bordered-right border-green-lighter pv-small ph-medium bg-green-lightest flex justify-between ${className}`}
            >
              <AssignedRatioRow
                ratio={ratio}
                timezone={timezone}
                assignedWorker={supportWorker}
                onChangeTeamMember={onChangeTeamMember}
              />
            </div>
          );
        } else {
          return (
            <div
              key={ratio.attendanceTimeSlotId}
              className={`bordered-bottom bordered-left bordered-right border-orange-lighter pv-small ph-medium bg-orange-lightest flex justify-between ${className}`}
            >
              <UnassignedRatioRow ratio={ratio} timezone={timezone} onChangeTeamMember={onChangeTeamMember} />
            </div>
          );
        }
      })}
    </>
  );
}

export default AssignBookingRatiosPanel;
