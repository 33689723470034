import React, { Component } from 'react';
import themeVariables from 'theme/theme-variables';

interface SectionContainerProps {
  bordered?: boolean;
  style?: object;
  className?: string;
  [prop: string]: any;
}

class SectionContainer extends Component<SectionContainerProps> {
  static defaultProps = {
    bordered: false,
    style: {},
    className: '',
  };

  render() {
    const { bordered, style, className, ...props } = this.props;

    return (
      <div
        className={`section-container ${className}`}
        style={{
          border: bordered ? `1px ${themeVariables.lightGreyVariant2} solid` : '0px',
          borderRadius: '4px',
          ...style,
        }}
        {...props}
      >
        {this.props.children}
      </div>
    );
  }
}

export default SectionContainer;
