import React from 'react';
import { Avatar, Col, Row } from 'antd';
import moment from 'moment-timezone';

import { FieldLabel, Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import CommonUtils from 'utilities/common-utils';

interface BulkTeamSummaryOptions {
  title: string;
  action: {
    label: string;
    onClick: (shiftSlot: IShiftSlot) => void;
  };
  span?: {
    teamMember: number;
    time: number;
    action: number;
  };
}

const optionsDefault = {
  span: { teamMember: 6, time: 6, action: 6 },
};

interface IBulkTeamSummary {
  timezone: timeZone;
  selectedShiftSlots: IShiftSlot[];
  options: BulkTeamSummaryOptions;
}

function BulkTeamSummary(props: IBulkTeamSummary): JSX.Element {
  const { selectedShiftSlots, timezone, options } = props;
  const { title, action, span } = { ...optionsDefault, ...options };

  return (
    <div>
      <Text>{title}</Text>
      <div className="bordered border-secondary mt-medium rounded-big shadow-container">
        <Row style={{ borderBottom: '1px solid #ebebeb' }}>
          <Col span={span.teamMember} className="p-medium">
            <FieldLabel text="Team member" />
          </Col>
          <Col span={span.time} className="p-medium">
            <FieldLabel text="Start time" />
          </Col>
          <Col span={span.time} className="p-medium">
            <FieldLabel text="End time" />
          </Col>
          <Col span={span.action} className="p-medium">
            <FieldLabel text="" />
          </Col>
        </Row>
        {selectedShiftSlots.map((shiftSlot) => (
          <Row key={shiftSlot.supportWorkerId}>
            <Col span={span.teamMember} className="p-medium">
              <div className="flex-row align-center">
                <Avatar icon={'user'} shape={'square'} src={shiftSlot.attachmentUrl} />
                <Text className="ml-small">{CommonUtils.formatFullName(shiftSlot)}</Text>
              </div>
            </Col>
            <Col span={span.time} className="p-medium">
              {moment.tz(shiftSlot.shiftStartDateTime, timezone).format("D MMMM 'YY. h:mmA")}
            </Col>
            <Col span={span.time} className="p-medium">
              {moment.tz(shiftSlot.shiftEndDateTime, timezone).format("D MMMM 'YY. h:mmA")}
            </Col>
            <Col span={span.action} className="p-medium">
              <HyperlinkButton onClick={() => action.onClick(shiftSlot)}>{action.label}</HyperlinkButton>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}

export default BulkTeamSummary;
