import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Shuffle = forwardRef(function Shuffle(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.53 1.47a.75.75 0 0 0-1.06 1.06l1.72 1.72h-2.387c-1.122 0-2.031 0-2.761.089-.76.093-1.412.29-1.974.745-.563.455-.892 1.05-1.142 1.774-.24.695-.43 1.584-.666 2.68l-.01.048-.967 4.514c-.247 1.155-.42 1.954-.627 2.553-.2.578-.404.885-.667 1.097-.262.212-.604.348-1.212.422-.629.077-1.447.078-2.627.078H3a.75.75 0 0 0 0 1.5h1.198c1.12 0 2.03 0 2.76-.089.76-.093 1.412-.29 1.974-.745.563-.455.892-1.05 1.142-1.774.24-.695.43-1.584.666-2.68l.01-.048.967-4.514c.247-1.155.42-1.954.627-2.552.2-.58.404-.886.667-1.098.262-.212.605-.348 1.213-.422.628-.077 1.446-.078 2.627-.078h2.338l-1.72 1.72a.75.75 0 1 0 1.061 1.06l3-3a.75.75 0 0 0 0-1.06l-3-3Zm0 21.06a.75.75 0 0 1-1.06-1.06l1.72-1.72H13a.75.75 0 1 1 0-1.5h6.19l-1.72-1.72a.75.75 0 1 1 1.06-1.06l3 3a.75.75 0 0 1 0 1.06l-3 3ZM3 5.75a.75.75 0 0 1 0-1.5h5a.75.75 0 0 1 0 1.5H3Z"
        fill="currentColor"
      />
    </Icon>
  );
});
