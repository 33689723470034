import type { PropsWithChildren, Ref } from 'react';
import React, { createContext } from 'react';
import type { IconProps } from '..';
import { IconContext } from '../icon/icon-provider';
import { SearchFieldProps } from './search-field';
import type { SearchFieldVariants } from './search-field.css';

type SearchFieldElement = Ref<HTMLInputElement>;
export type SearchFieldProviderProps = SearchFieldProps & {
  searchFieldRef?: SearchFieldElement;
};

export const SearchFieldContext = createContext<SearchFieldProviderProps>({} as SearchFieldProviderProps);

export const SearchFieldProvider = (props: SearchFieldProviderProps) => {
  const { children, ...otherProps } = props;
  return <SearchFieldContext.Provider value={otherProps}>{children}</SearchFieldContext.Provider>;
};

export type SearchFieldIconProviderProps = PropsWithChildren<IconProps & SearchFieldVariants>;

export function SearchFieldIconProvider(props: SearchFieldIconProviderProps) {
  let { children, size, ...otherProps } = props;
  let height;
  let width;

  switch (size) {
    case 'medium':
      width = '$size250';
      height = '$size250';
      break;
    case 'small':
      width = '$size200';
      height = '$size200';
      break;
  }

  otherProps = { ...otherProps, height, width };

  return <IconContext.Provider value={otherProps}>{children}</IconContext.Provider>;
}
