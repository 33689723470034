import { Popover2 } from '@blueprintjs/popover2';
import { Icon, Row } from 'antd';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import React, { Component } from 'react';
import { BillableType, BookingErrorType } from 'utilities/enum-utils';

interface IWarningErrorLineItem {
  icon: string;
  theme?: 'filled' | 'outlined' | 'twoTone';
  iconClassName?: string;
  content: string | React.ReactNode;
}

const WarningErrorLineItem = ({ icon, theme = 'filled', iconClassName, content }: IWarningErrorLineItem) => {
  return (
    <Row className="pt-12" style={{ width: '290px' }}>
      <Icon type={icon} theme={theme} className={`mr-small ${iconClassName}`} />
      <Text>{content}</Text>
    </Row>
  );
};

interface IBookingErrorIndicatorProps {
  bookingErrors: { bookingErrorType: BookingErrorType; number?: number }[];
  billableType?: BillableType;
  usePortal?: boolean;
}
class BookingErrorIndicator extends Component<IBookingErrorIndicatorProps> {
  private _renderIcon = () => {
    const { bookingErrors } = this.props;

    if (!bookingErrors || bookingErrors.length === 0) {
      return <Icon type="question-circle" className="mr-x-small" />;
    } else if (bookingErrors.length === 1) {
      const type = bookingErrors[0].bookingErrorType;

      switch (type) {
        case BookingErrorType.PaymentMethodError:
        case BookingErrorType.SleepoverConflictBookingTime:
        case BookingErrorType.SleepoverConflictCheckinoutTime:
        case BookingErrorType.DisturbancOutOfSleepoverTimeError:
        case BookingErrorType.DisturbancOutOfBookingTimeError:
        case BookingErrorType.DisturbancOutOfCheckinCheckoutTimeError:
          return <Icon type="close-circle" theme="filled" className="text-color-red-light mr-x-small" />;
        case BookingErrorType.BookingRecordedTimeWarning:
        case BookingErrorType.BookingActualTimeWarning:
        case BookingErrorType.FundingPackageWarning:
        case BookingErrorType.ServiceAgreementWarning:
        case BookingErrorType.ServiceAgreementLineItemWarning:
          return <Icon type="warning" theme="filled" className="text-color-orange-lighter mr-x-small" />;
        default:
          return <Icon type="question-circle" className="mr-x-small" />;
      }
    } else {
      const isError = _.find(bookingErrors, (error) =>
        [
          BookingErrorType.PaymentMethodError,
          BookingErrorType.SleepoverConflictBookingTime,
          BookingErrorType.SleepoverConflictCheckinoutTime,
          BookingErrorType.DisturbancOutOfSleepoverTimeError,
          BookingErrorType.DisturbancOutOfBookingTimeError,
          BookingErrorType.DisturbancOutOfCheckinCheckoutTimeError,
        ].includes(error.bookingErrorType),
      );
      if (isError) {
        return <Icon type="close-circle" theme="filled" className="text-color-red-light mr-x-small" />;
      } else {
        return <Icon type="warning" theme="filled" className="text-color-orange-lighter mr-x-small" />;
      }
    }
  };

  private _getPopoverContent = () => {
    const { bookingErrors, billableType } = this.props;
    let title = '';
    const isHasError = _.find(bookingErrors, (error) =>
      [
        BookingErrorType.PaymentMethodError,
        BookingErrorType.SleepoverConflictBookingTime,
        BookingErrorType.SleepoverConflictCheckinoutTime,
        BookingErrorType.DisturbancOutOfSleepoverTimeError,
        BookingErrorType.DisturbancOutOfBookingTimeError,
        BookingErrorType.DisturbancOutOfCheckinCheckoutTimeError,
      ].includes(error.bookingErrorType),
    );

    const isHasWarning = _.find(bookingErrors, (error) =>
      [
        BookingErrorType.BookingRecordedTimeWarning,
        BookingErrorType.ServiceAgreementWarning,
        BookingErrorType.FundingPackageWarning,
        BookingErrorType.BookingActualTimeWarning,
        BookingErrorType.BookingCheckinTimeWarning,
        BookingErrorType.BookingCheckOutTimeWarning,
        BookingErrorType.ServiceAgreementLineItemWarning,
      ].includes(error.bookingErrorType),
    );
    const isHasNonBillableOrNoLineItem = billableType && billableType !== BillableType.ALL_BILLABLE;

    if (isHasError && (isHasWarning || isHasNonBillableOrNoLineItem)) {
      title = 'Warnings/errors';
    } else if (isHasError && !isHasWarning && !isHasNonBillableOrNoLineItem) {
      title = 'Errors';
    } else {
      title = 'Warnings';
    }

    return (
      <div className="p-medium">
        <Text weight="bold" className="mb-x-small">
          {title}
        </Text>
        {isHasError &&
          bookingErrors.map((error) => {
            switch (error.bookingErrorType) {
              case BookingErrorType.PaymentMethodError:
                return (
                  <WarningErrorLineItem
                    key={error.bookingErrorType}
                    icon="close-circle"
                    iconClassName="text-color-red-light"
                    content="Management method error"
                  />
                );
              case BookingErrorType.SleepoverConflictBookingTime:
              case BookingErrorType.SleepoverConflictCheckinoutTime:
                return (
                  <WarningErrorLineItem
                    key={error.bookingErrorType}
                    icon="close-circle"
                    iconClassName="text-color-red-light"
                    content="Sleepover error"
                  />
                );
              case BookingErrorType.DisturbancOutOfSleepoverTimeError:
              case BookingErrorType.DisturbancOutOfBookingTimeError:
              case BookingErrorType.DisturbancOutOfCheckinCheckoutTimeError:
                return (
                  <WarningErrorLineItem
                    key={error.bookingErrorType}
                    icon="close-circle"
                    iconClassName="text-color-red-light"
                    content="Sleepover disturbance error"
                  />
                );
              default:
                return null;
            }
          })}

        {isHasWarning &&
          bookingErrors.map((error) => {
            switch (error.bookingErrorType) {
              case BookingErrorType.BookingRecordedTimeWarning:
                return (
                  <WarningErrorLineItem
                    key={error.bookingErrorType}
                    icon="warning"
                    iconClassName="text-color-orange-lighter"
                    content="Shift time variation warning"
                  />
                );
              case BookingErrorType.ServiceAgreementWarning:
                return (
                  <WarningErrorLineItem
                    key={error.bookingErrorType}
                    icon="warning"
                    iconClassName="text-color-orange-lighter"
                    content="Service not part of service agreement"
                  />
                );
              case BookingErrorType.FundingPackageWarning:
                return (
                  <WarningErrorLineItem
                    key={error.bookingErrorType}
                    icon="warning"
                    iconClassName="text-color-orange-lighter"
                    content="Funding package warning"
                  />
                );
              case BookingErrorType.BookingActualTimeWarning:
                return (
                  <WarningErrorLineItem
                    key={error.bookingErrorType}
                    icon="warning"
                    iconClassName="text-color-orange-lighter"
                    content="Checkin/checkout time"
                  />
                );
              case BookingErrorType.ServiceAgreementLineItemWarning:
                return (
                  <WarningErrorLineItem
                    key={error.bookingErrorType}
                    icon="warning"
                    iconClassName="text-color-orange-lighter"
                    content="Line item not part of service agreement"
                  />
                );
              default:
                return null;
            }
          })}

        {isHasNonBillableOrNoLineItem && (
          <WarningErrorLineItem
            icon="question-circle"
            theme="outlined"
            content={
              billableType === BillableType.NON_BILLABLE
                ? 'All line items are non-billable'
                : billableType === BillableType.SOME_BILLABLE
                ? 'Some line items are non-billable'
                : 'No line items in booking'
            }
          />
        )}
      </div>
    );
  };

  render() {
    const { bookingErrors, usePortal = true, billableType } = this.props;

    if (_.isEmpty(bookingErrors) && billableType === BillableType.ALL_BILLABLE) {
      return <></>;
    } else {
      return (
        <Popover2
          content={this._getPopoverContent()}
          position={'top-right'}
          interactionKind="hover"
          usePortal={usePortal}
        >
          {this._renderIcon()}
        </Popover2>
      );
    }
  }
}

export default BookingErrorIndicator;
