import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useElementSize } from 'utilities/react-hooks/element-size';

export const ReadMoreExpander: React.FC<{
  children: React.ReactNode;
  /** Class for the expanding div. */
  className?: string;
}> = ({ children, className }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const divSize = useElementSize(divRef);
  const divUniqueId = useMemo(() => `read-more-${uuid()}`, []);

  const [expanded, setExpanded] = useState(false);
  const onControlClick = useCallback(() => setExpanded(!expanded), [expanded]);

  const isOverflowing = useMemo(() => {
    const { current: div } = divRef;
    if (!div || !divSize) return undefined;
    return divSize.blockSize < div.scrollHeight || divSize.inlineSize < div.scrollWidth;
  }, [divSize]);

  return (
    <>
      <div
        ref={divRef}
        className={clsx(isOverflowing && !expanded && 'h-[3.6em] overflow-hidden', className)}
        id={divUniqueId}
      >
        {children}
      </div>

      {isOverflowing && (
        <button
          className='mt-2 cursor-pointer text-blue-ref underline'
          onClick={onControlClick}
          aria-controls={divUniqueId}
          aria-expanded={expanded}
        >
          Read {expanded ? 'less' : 'more'}
        </button>
      )}
    </>
  );
};
