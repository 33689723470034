import React from 'react';
import { useTranslation } from 'react-i18next';
import { MantineModal as Modal, Title, Loader } from '@good/ui/core';
import { SubscriptionBillingForm, SubscriptionBillingFormProps } from './subscription-billing-form';

type SubscriptionBillingConfigModalProps = {
  isOpen: boolean;
  isLoading: boolean;
} & SubscriptionBillingFormProps;

export const SubscriptionBillingConfigModal = ({
  isOpen,
  toggleModal,
  existingLineItems,
  serviceProviderId,
  isLoading,
  billingSubscriptionId,
  refetch,
  customerId,
  userServiceAgreementServiceId,
}: SubscriptionBillingConfigModalProps) => {
  const { t } = useTranslation('', {
    keyPrefix: 'serviceAgreements.silServiceAgreement.fundingDetails.createSubscription',
  });
  return (
    <Modal.Root
      opened={isOpen}
      onClose={() => {
        toggleModal(false);
      }}
      size='auto'
      style={{ padding: '10px 20px 10px 15px' }}
      data-testid='billing-subscription-modal'
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header style={{ padding: '25px 23px 10px 23px' }}>
          <Title order={3}>{t('header')}</Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Loader />
          ) : (
            <SubscriptionBillingForm
              existingLineItems={existingLineItems}
              billingSubscriptionId={billingSubscriptionId}
              serviceProviderId={serviceProviderId}
              customerId={customerId}
              userServiceAgreementServiceId={userServiceAgreementServiceId}
              refetch={refetch}
              toggleModal={toggleModal}
              t={t}
            />
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
