import React from 'react';
import { Title } from 'common-components/typography';

type ICompanyDetailsProps = {
  goToLandingPage: () => void;
};

export const CompanyDetails = ({ goToLandingPage }: ICompanyDetailsProps) => (
  <div className='mb-medium'>
    <Title level={3} weight='regular' className='mv-none'>
      <span
        className='text-color-blue-action cursor-pointer'
        onClick={goToLandingPage}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            goToLandingPage();
          }
        }}
        role='button'
        tabIndex={0}
      >
        {'Account >'}
      </span>{' '}
      Company Details
    </Title>
  </div>
);
