import { Button, Inline, Stack } from '@goodhuman-me/components';
import { Link, Announce, Box, Checkbox, Tooltip, Text } from 'design-components';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { UserPermissionRole } from 'utilities/enum-utils';
import { INVITE_MEMBER_ROLE_TYPE, ROLE_PERMISSION_LIST } from 'views/team-v2/utils/constants';
import { getListPermissionId } from 'views/team-v2/utils/permission-utils';
import { Member, PermissionRole, RolePermissionList } from '../../utils/form-utils';

interface IProps {
  memberSelected?: Member;
  onCancel: () => void;
  updateTeamMemberPermissions: (roles: string[], permissions: PermissionRole[]) => void;
}

const PermissionCheckBoxList: React.FC<IProps> = (props) => {
  const dispatch = useDispatch<IRootDispatch>();
  const { onCancel, updateTeamMemberPermissions, memberSelected } = props;

  const [selectedRole, setSelectedRole] = useState<string[]>(memberSelected?.roles || []);

  const { portalUser, serviceDepartmentListLite } = useSelector((state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    serviceDepartmentListLite: state.servicesStore.serviceDepartmentListLite,
  }));

  const isPrimaryOwner = portalUser.permissions.permissionRoles.find(
    (role) => role.permissionRole === UserPermissionRole.PrimaryOwner,
  );

  const handleCancel = () => {
    onCancel?.();
  };

  const onCheckBoxChange = (value: string) => {
    const isExisted = selectedRole.some((one) => one === value);

    if (value === INVITE_MEMBER_ROLE_TYPE.NO_ACCESS) {
      return setSelectedRole(isExisted ? [] : [value]);
    }

    if (value === INVITE_MEMBER_ROLE_TYPE.OWNER) {
      return setSelectedRole(
        isExisted
          ? []
          : ROLE_PERMISSION_LIST.map((one) => one.role).filter((one) => one !== INVITE_MEMBER_ROLE_TYPE.NO_ACCESS),
      );
    }

    setSelectedRole(
      isExisted
        ? selectedRole.filter((role) => role !== value && role !== INVITE_MEMBER_ROLE_TYPE.OWNER)
        : selectedRole.filter((one) => one === INVITE_MEMBER_ROLE_TYPE.NO_ACCESS).length
        ? [value]
        : [...selectedRole, value],
    );
  };

  const getPermissions = (permissionIds: number[]) => {
    if (!permissionIds || !permissionIds.length) {
      return [];
    }
    const permissions = [];
    permissionIds.forEach((roleId) => {
      if (roleId === UserPermissionRole.ServiceAdmin) {
        serviceDepartmentListLite &&
          serviceDepartmentListLite.length &&
          serviceDepartmentListLite.forEach((one) => {
            permissions.push({
              permissionRole: roleId,
              serviceDepartmentId: one.serviceDepartmentId,
              serviceId: null,
            });
          });
      } else {
        permissions.push({
          permissionRole: roleId,
          serviceDepartmentId: null,
          serviceId: null,
        });
      }
    });

    return permissions;
  };

  const onVisibleAdvancedPermission = () => {
    if (memberSelected && memberSelected.memberId) {
      dispatch.teamV2Store.setMemberSelected(memberSelected);
    } else {
      dispatch.teamV2Store.setMemberSelected(null);
    }
    dispatch.teamV2Store.setAdvancedPermissionModalVisible(true);
    onCancel?.();
  };

  const onSubmit = () => {
    const permissionIds = getListPermissionId(selectedRole);
    updateTeamMemberPermissions(selectedRole, getPermissions(permissionIds));
    handleCancel();
  };

  return (
    <Stack width={300}>
      <Stack gap="$medium" padding="$space100 $space200">
        {ROLE_PERMISSION_LIST.map((one: RolePermissionList) => {
          if (one.role === INVITE_MEMBER_ROLE_TYPE.OWNER && !isPrimaryOwner) {
            return <></>;
          }
          const isSelected = selectedRole.includes(one.role);
          return (
            <Checkbox
              textStyles={{ width: '100%' }}
              key={one.role}
              size="small"
              value={one.role}
              isSelected={isSelected}
              onChange={() => onCheckBoxChange(one.role)}
            >
              <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                <Text color={isSelected ? '$black' : '#3E3E3C'}>{one.label}</Text>
                {one.description && (
                  <Tooltip
                    isCloseImmediate
                    delay={0}
                    placement="top right"
                    content={
                      <Box
                        width={300}
                        gap="$space125"
                        display="flex"
                        minWidth="max-content"
                        backgroundColor="$infoPressed"
                        padding="$space200"
                        paddingRight="$space300"
                        borderRadius="$small"
                      >
                        <Announce marginTop="$space10" color="$white" size="medium" />
                        <Stack width={232} gap="$space100">
                          <Text
                            color="$white"
                            size="small"
                            fontWeight="$semibold !important"
                            fontSize="$font75 !important"
                          >
                            {one.title}
                          </Text>
                          <Text size="xsmall" color="$white" fontWeight="$normal">
                            {one.description}
                          </Text>
                        </Stack>
                      </Box>
                    }
                  >
                    <Announce marginTop="$space10" color="$neutralDark1" width="$medium" height="$medium" />
                  </Tooltip>
                )}
              </Box>
            </Checkbox>
          );
        })}
      </Stack>

      <Stack paddingX="$space200" marginTop="$space100">
        <Link
          size="small"
          color="$accent"
          emphasis="regular"
          justifyContent="start !important"
          textDecoration="none !important"
          onPress={onVisibleAdvancedPermission}
        >
          Advanced permissions
        </Link>
      </Stack>

      <Stack padding="$space200">
        <Inline justifyContent="flex-end">
          <Button kind="accent" emphasis="quiet" onPress={handleCancel}>
            Cancel
          </Button>
          <Button
            kind="accent"
            emphasis="filled"
            marginLeft="$space150"
            isDisabled={!selectedRole.length}
            onPress={onSubmit}
          >
            Apply
          </Button>
        </Inline>
      </Stack>
    </Stack>
  );
};

export default PermissionCheckBoxList;
