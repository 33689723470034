import { message } from 'antd';
import {
  IBillingAccessMember,
  IBillingHistoryOverView,
  ICard,
  IConfirmCancelInfo,
  ISubscriptionOverview,
  ISubscriptionStatusInfo,
} from 'interfaces/subscription-interfaces';
import apiClient from 'utilities/api-client';

interface ISubscriptionStoreState {
  subscriptionStatus: ISubscriptionStatusInfo;
  subscriptionOverview: ISubscriptionOverview;
  billingHistoryOverView: IBillingHistoryOverView;
  accessBillingContactTeamMembers: IBillingAccessMember[];
  noAccessBillingContactTeamMembers: IBillingAccessMember[];
  paymentMethods: ICard[];
  clientSecret: string;
  confirmCancelInfo: IConfirmCancelInfo;
}

const initialState: ISubscriptionStoreState = {
  subscriptionStatus: null,
  subscriptionOverview: null,
  billingHistoryOverView: { hasMore: false, billings: [] },
  accessBillingContactTeamMembers: [],
  noAccessBillingContactTeamMembers: [],
  paymentMethods: [],
  clientSecret: '',
  confirmCancelInfo: null,
};

const subscriptionStore = {
  state: initialState,

  reducers: {
    setSubscriptionStatus: (state: ISubscriptionStoreState, subscriptionStatus: ISubscriptionStatusInfo) => ({
      ...state,
      subscriptionStatus,
    }),
    setSubscriptionOverview: (state: ISubscriptionStoreState, subscriptionOverview: ISubscriptionOverview) => ({
      ...state,
      subscriptionOverview,
    }),
    setPaymentMethods: (state: ISubscriptionStoreState, payload: ICard[]) => ({ ...state, paymentMethods: payload }),
    setClientSecret: (state: ISubscriptionStoreState, payload: string) => ({ ...state, clientSecret: payload }),
    setBillingHistoryOverView: (state: ISubscriptionStoreState, billingHistoryOverView: IBillingHistoryOverView) => ({
      ...state,
      billingHistoryOverView,
    }),
    setAccessBillingContactTeamMembers: (
      state: ISubscriptionStoreState,
      accessBillingContactTeamMembers: IBillingAccessMember[],
    ) => ({
      ...state,
      accessBillingContactTeamMembers,
    }),
    setNoAccessBillingContactTeamMembers: (
      state: ISubscriptionStoreState,
      noAccessBillingContactTeamMembers: IBillingAccessMember[],
    ) => ({
      ...state,
      noAccessBillingContactTeamMembers,
    }),
    setConfirmCancelInfo: (state: ISubscriptionStoreState, confirmCancelInfo: IConfirmCancelInfo) => ({
      ...state,
      confirmCancelInfo,
    }),
  },

  effects: (dispatch) => ({
    async doGetSubscriptionStatus(): Promise<void> {
      try {
        const response = await apiClient.get('/api/portal/account/subscriptions');
        if (response.data) {
          dispatch.subscriptionStore.setSubscriptionStatus(response.data);
        }
      } catch (e) {
        return e;
      }
    },

    async doGetSubscriptionOverview(): Promise<void> {
      try {
        const response = await apiClient.get('/api/portal/account/subscriptions/overview');
        if (response.data) {
          dispatch.subscriptionStore.setSubscriptionOverview(response.data);
        }
      } catch (e) {
        return e;
      }
    },

    async doGetBillingAccessList(): Promise<void> {
      try {
        const response = await apiClient.get('/api/portal/account/billing-access');
        if (response.data) {
          dispatch.subscriptionStore.setAccessBillingContactTeamMembers(response.data);
        }
      } catch (e) {
        message.error(e.message);
        throw e;
      }
    },

    async doAddBillingAccess(userIds: string[]): Promise<boolean> {
      try {
        const result = await apiClient.post('/api/portal/account/billing-access', { userIds });
        return result.data;
      } catch (e) {
        return false;
      }
    },

    async doRemoveBillingAccess(userId: string): Promise<boolean> {
      try {
        const result = await apiClient.delete(`/api/portal/account/billing-access/${userId}`);
        return result.data;
      } catch (e) {
        return false;
      }
    },

    async doFetchNoAccessBillingContactTeamMember(
      payload: { page: number; pageSize: number; search: string },
      rootState,
    ): Promise<void> {
      try {
        const { page, pageSize, search } = payload;
        const result = await apiClient.get(
          `/api/portal/account/billing-access/team-members?page=${page}&pageSize=${pageSize}${
            search ? `&search=${search}` : ''
          }`,
        );
        if (result.data) {
          const noAccessBillingContactTeamMembers =
            page > 1 ? rootState.subscriptionStore.noAccessBillingContactTeamMembers.concat(result.data) : result.data;
          dispatch.subscriptionStore.setNoAccessBillingContactTeamMembers(noAccessBillingContactTeamMembers);
        }
      } catch (e) {
        message.error(e.message);
        throw e;
      }
    },

    async doGetPaymentMethods(): Promise<void> {
      try {
        const response = await apiClient.get('/api/portal/account/payment-methods');
        if (response.data) {
          dispatch.subscriptionStore.setPaymentMethods(response.data);
        }
      } catch (e) {
        return e;
      }
    },

    async doCreateSetupIntent(): Promise<void> {
      try {
        const response = await apiClient.post('/api/portal/account/payment-methods/setup-intent');
        if (response.data) {
          dispatch.subscriptionStore.setClientSecret(response.data.clientSecret);
        }
      } catch (e) {
        return e;
      }
    },

    async doSetDefaultPaymentMethod(cardId: string): Promise<boolean> {
      try {
        const response = await apiClient.put(`/api/portal/account/payment-methods/${cardId}/set-default`);
        return response.data;
      } catch (e) {
        return false;
      }
    },

    async doUpdateExpiryOfPaymentMethod(payload: {
      cardId: string;
      expMonth: number;
      expYear: number;
    }): Promise<boolean> {
      try {
        const { cardId, ...data } = payload;
        const response = await apiClient.put(`/api/portal/account/payment-methods/${cardId}`, data);
        return response.data;
      } catch (e) {
        return false;
      }
    },

    async doRemovePaymentMethod(cardId: string): Promise<boolean> {
      try {
        const response = await apiClient.delete(`/api/portal/account/payment-methods/${cardId}`);
        return response.data;
      } catch (e) {
        return false;
      }
    },

    async doGetBillingHistoryOverView(): Promise<void> {
      try {
        const response = await apiClient.get('/api/portal/account/billing-histories?limit=10');
        if (response.data) {
          dispatch.subscriptionStore.setBillingHistoryOverView(response.data);
        }
      } catch (e) {
        return e;
      }
    },

    async doFetchMoreBillingHistoryOverView(payload: { invoiceId: string }, rootStore): Promise<void> {
      try {
        const response = await apiClient.get(
          `/api/portal/account/billing-histories?limit=10&starting_after=${payload.invoiceId}`,
        );
        if (response.data) {
          const newBillings = rootStore.subscriptionStore.billingHistoryOverView.billings.concat(
            response.data.billings,
          );
          dispatch.subscriptionStore.setBillingHistoryOverView({
            hasMore: response.data.hasMore,
            billings: newBillings,
          });
        }
      } catch (e) {
        return e;
      }
    },

    async doFetchPreviewStripeInvoicePdf(invoiceId: string): Promise<string> {
      try {
        const response = await apiClient.get(`/viewpdf/portal/billing-histories/preview-pdf/${invoiceId}`);
        if (response.data) {
          return response.data;
        }
      } catch (e) {
        return '';
      }
    },

    async doFetchConfirmCancelInfo(): Promise<void> {
      try {
        const response = await apiClient.get(`/api/portal/account/subscriptions/confirm-cancel`);
        if (response.data) {
          dispatch.subscriptionStore.setConfirmCancelInfo(response.data);
        }
      } catch (e) {
        return e;
      }
    },

    async doCancelSubscription(payload: { [key: string]: boolean | string }): Promise<boolean> {
      try {
        const response = await apiClient.put(`/api/portal/account/subscriptions/pause`, payload);
        return response.data;
      } catch (e) {
        return false;
      }
    },
  }),
};

export default subscriptionStore;
