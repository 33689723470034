// TODO (Mitch): Replace with DS
import React from 'react';
import { AlertTriangle } from '@good/icons';
import { Box, Inline, Text } from '@goodhuman-me/components';

import type { ReactNode } from 'react';

export const Warning = (props: { children: ReactNode }) => {
  const { children } = props;

  return (
    <Box
      borderLeftStyle="$solid"
      borderLeftWidth="4px"
      borderLeftColor="$notice"
      borderRadius="6px"
      padding="$space100"
      backgroundColor="$noticeBackground"
    >
      <Inline gap="$space75">
        <Text color="$notice" marginRight="$space50" flex="auto">
          <AlertTriangle />
        </Text>

        <Text>{children}</Text>
      </Inline>
    </Box>
  );
};
