import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';

import { ISession } from 'interfaces/session-interfaces';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import EditLeaveForm from 'views/group-services/session-details/forms/EditLeaveForm';
import BulkApproveLeaveSummary from './BulkApproveLeaveSummary';
import BulkApproveLeaveSuccess from './BulkApproveLeaveSuccess';

interface IBulkApproveLeaveModal {
  doApproveLeaveShiftSlots: typeof dispatch.groupServiceStore.doApproveLeaveShiftSlots;
  isOpen: boolean;
  onClose: () => void;
  onTaskSuccess: () => void;
  onUpdatedShift: () => Promise<void>;
  serviceDateTimeId: string;
  serviceId: string;
  session: ISession;
  timezone: timeZone;
  toBeApprovedShiftSlots: IShiftSlot[];
}

const modalOptions = {
  title: 'Approve leave shifts',
  width: 760,
};

function BulkApproveLeaveModal(props: IBulkApproveLeaveModal): JSX.Element {
  const {
    doApproveLeaveShiftSlots,
    isOpen,
    onClose,
    onTaskSuccess,
    onUpdatedShift,
    session,
    timezone,
    toBeApprovedShiftSlots,
  } = props;

  const [selectedShiftSlots, setSelectedShiftSlots] = useState<IShiftSlot[]>([]);
  const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
  const [editingShiftSlot, setEditingShiftSlot] = useState<IShiftSlot | null>(null);
  const [hasSuccess, setHasSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (toBeApprovedShiftSlots?.length) {
      const selectedShiftSlots_ = toBeApprovedShiftSlots
        .slice()
        .sort((a, b) => a?.firstName?.toLowerCase().localeCompare(b?.firstName?.toLowerCase()))
        .slice()
        .sort((a, b) => a?.lastName?.toLowerCase().localeCompare(b?.lastName?.toLowerCase()));

      setSelectedShiftSlots(selectedShiftSlots_);
    }
  }, [toBeApprovedShiftSlots]);

  const onBulkApproveLeave = async () => {
    setIsLoadingApprove(true);
    try {
      const shiftSlots = selectedShiftSlots.map((shiftSlot) => ({
        supportWorkerAttendanceId: shiftSlot.supportWorkerAttendanceId,
        startDateTime: shiftSlot.workerCheckedInDateTime,
      }));

      const request = {
        serviceId: session.serviceId,
        sessionId: session.serviceDateTimeId,
        shiftSlots,
      };

      await doApproveLeaveShiftSlots(request);
      setHasSuccess(true);
      onTaskSuccess();
    } catch (e) {
      if (e.meta.message === 'Session status error') {
        notification.error({ message: 'Please check the session status before starting a shift.' });
      } else {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    } finally {
      setIsLoadingApprove(false);
    }
  };

  const handleClose = () => {
    setHasSuccess(false);
    setEditingShiftSlot(null);
    onClose();
  };

  if (!toBeApprovedShiftSlots?.length) {
    return null;
  }

  return (
    <ActionModal
      isOpen={isOpen}
      title={modalOptions.title}
      onClose={handleClose}
      width={modalOptions.width}
      canCloseOutside={false}
    >
      {!hasSuccess && !editingShiftSlot && (
        <>
          <BulkApproveLeaveSummary
            selectedShiftSlots={selectedShiftSlots}
            timezone={timezone}
            onEditShift={setEditingShiftSlot}
          />
          <ActionModalFooter align="right" className="mt-large">
            <SecondaryButton size="large" className="mr-medium" onClick={handleClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={onBulkApproveLeave} className="rounded" loading={isLoadingApprove}>
              Approve Leave
            </PrimaryButton>
          </ActionModalFooter>
        </>
      )}
      {!hasSuccess && editingShiftSlot && (
        <EditLeaveForm
          shiftSlot={editingShiftSlot}
          session={session}
          onCancel={() => setEditingShiftSlot(null)}
          cancelText="Back"
          onSuccess={async () => {
            await onUpdatedShift();
            setEditingShiftSlot(null);
          }}
        />
      )}
      {hasSuccess && (
        <>
          <BulkApproveLeaveSuccess itemCount={selectedShiftSlots?.length} />{' '}
          <ActionModalFooter align="right">
            <PrimaryButton size="large" onClick={handleClose}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </>
      )}
    </ActionModal>
  );
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doApproveLeaveShiftSlots: dispatch.groupServiceStore.doApproveLeaveShiftSlots,
  doFetchSessionShiftSlotOverview: dispatch.groupServiceStore.doFetchSessionShiftSlotOverview,
});

export default connect(null, mapDispatch)(BulkApproveLeaveModal);
