import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Cast = forwardRef(function Cast(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3.75c1.907 0 3.262.002 4.29.14 1.005.135 1.585.389 2.008.812.423.423.677 1.003.812 2.009.139 1.028.14 2.382.14 4.289v2c0 1.907-.002 3.262-.14 4.29-.135 1.005-.389 1.585-.812 2.008-.423.423-1.003.677-2.009.812-1.027.138-2.382.14-4.289.14h-.5a.75.75 0 1 0 0 1.5h.556c1.838 0 3.294 0 4.433-.153 1.172-.158 2.121-.49 2.87-1.238.748-.749 1.08-1.698 1.238-2.87.153-1.14.153-2.595.153-4.433v-2.112c0-1.838 0-3.294-.153-4.433-.158-1.172-.49-2.121-1.238-2.87-.749-.748-1.698-1.08-2.87-1.238-1.14-.153-2.595-.153-4.433-.153h-5.59c-1.139 0-2.04 0-2.766.062-.743.063-1.37.195-1.94.499a4.75 4.75 0 0 0-1.95 1.95c-.303.569-.435 1.196-.498 1.939-.062.725-.062 1.627-.062 2.766V9.5h1.5c0-1.18 0-2.018.056-2.673.055-.646.16-1.044.328-1.359a3.25 3.25 0 0 1 1.334-1.334c.315-.168.713-.273 1.359-.328.655-.055 1.493-.056 2.673-.056H14ZM2 22a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-.75-9a.75.75 0 0 1 .75-.75c4.767 0 8.75 4.03 8.75 8.75a.75.75 0 0 1-1.5 0c0-3.896-3.316-7.25-7.25-7.25a.75.75 0 0 1-.75-.75ZM2 16.25a.75.75 0 0 0 0 1.5c1.758 0 3.25 1.508 3.25 3.25a.75.75 0 0 0 1.5 0c0-2.566-2.158-4.75-4.75-4.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
