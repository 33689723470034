import React, { Component } from 'react';
import { Spinner } from '@blueprintjs/core';
import { Paragraph } from '../typography';

interface ISpinningLoadingProps {
  size: number;
  message: string;
}

class SpinningLoader extends Component<ISpinningLoadingProps, any> {
  render() {
    const { size, message } = this.props;
    return (
      <div className="text-align-center">
        <div className="pv-medium">
          <Spinner size={size} intent={'primary'} />
        </div>

        <div className="mb-medium">
          <Paragraph>{message}</Paragraph>
        </div>
      </div>
    );
  }
}

export default SpinningLoader;
