import { IBooking } from 'interfaces/booking-interfaces';
import { BookingStatus, ShiftSlotStatus } from 'utilities/enum-utils';
import fields from '../fields';
import { CustomerCancellationReason, Schema } from '../schema';
import { CustomerCancellationCode } from 'stores/queries/booking/booking-queries';
import moment from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';

type customerCodeType = { [key in CustomerCancellationReason]: CustomerCancellationCode };
const editCancelledBooking = (booking: IBooking): Schema => {
  if (!booking) {
    throw new Error('No booking selected');
  }

  const cancellationCode: customerCodeType = {
    transport: 'NSDT',
    health: 'NSDH',
    other: 'NSDO',
    family: 'NSDF',
  };

  const cancellationReason: CustomerCancellationReason = Object.entries(cancellationCode).find(
    ([key, value]: [CustomerCancellationReason, CustomerCancellationCode]) => value === booking.cancellationCode,
  )?.[0] as CustomerCancellationReason;

  const prefillEditFields: Schema = {};
  if (booking.status === BookingStatus.BUSINESS_CANCELLED) {
    prefillEditFields[fields.cancelledBy.name] = 'business';
    prefillEditFields[fields.otherCancellationReason.name] = booking.cancellationReason;
  } else {
    prefillEditFields[fields.cancelledBy.name] = 'customer';
    prefillEditFields[fields.customerCancellationReason.name] = cancellationReason;
    if (booking.cancellationCode === cancellationCode.other) {
      prefillEditFields[fields.otherCancellationReason.name] = booking.cancellationReason;
    }
  }
  prefillEditFields[fields.cancelRecurringBooking.name] = fields.cancelRecurringBooking.options.this;
  prefillEditFields[fields.shouldChargeCancellationFee.name] =
    booking.status === BookingStatus.CUSTOMER_CANCELLED_WITH_FEE;
  prefillEditFields[fields.shouldPayTeamMember.name] = booking.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID;

  if (booking.isCustomerNoShow !== null) {
    prefillEditFields[fields.cancellationFeeReason.name] = booking.isCustomerNoShow
      ? fields.cancellationFeeReason.options.noShow
      : fields.cancellationFeeReason.options.aheadOfTime;
  }

  if (
    booking.endDateTime === booking.portalCheckedOutDateTime &&
    booking.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID
  ) {
    prefillEditFields[fields.teamMemberPaymentDuration.defaultValue] =
      prefillEditFields[fields.teamMemberPaymentDuration.options.entireDuration];
  } else {
    const endDateTime = booking.portalCheckedOutDateTime ? moment(booking.portalCheckedOutDateTime) : null;
    const startDateTime = moment(booking.startDateTime);
    let duration = endDateTime ? endDateTime.diff(startDateTime, 'hours', true) : 0;
    const numberOfDecimals = CommonUtils.getNumberOfDecimals(duration);

    prefillEditFields[fields.customTeamMemberPaymentDurationUnit.name] =
      fields.customTeamMemberPaymentDurationUnit.options.hours;

    if (endDateTime && (duration < 1 || numberOfDecimals > 2)) {
      duration = endDateTime.diff(startDateTime, 'minutes', true);

      prefillEditFields[fields.customTeamMemberPaymentDurationUnit.name] =
        fields.customTeamMemberPaymentDurationUnit.options.minutes;
    }

    prefillEditFields[fields.teamMemberPaymentDuration.name] = fields.teamMemberPaymentDuration.options.customDuration;
    prefillEditFields[fields.customTeamMemberPaymentDuration.name] = duration;
  }

  return prefillEditFields;
};

export default editCancelledBooking;
