import React, { Component } from 'react';
import { MenuItemProps, MenuItem } from '@blueprintjs/core';

class ActionMenuItem extends Component<MenuItemProps, any> {
  render() {
    const { className } = this.props;

    return (
      <MenuItem
        {...this.props}
        className={`hover-bg-tertiary text-size-large pv-small ${className}`}
        style={{ paddingLeft: '12px', paddingRight: '12px' }}
      />
    );
  }
}

export default ActionMenuItem;
