import React, { useCallback } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { PrimaryButton, SecondaryButton } from '../../../../../common-components/buttons';
import { Stack, StepContainer } from '../components';
import { WorkflowSteps } from '../../forms';
import { createStepFactory } from '../../../workflow-template';
import { useCreateWorkflow } from '../create-workflow-provider';

import type { Approver, Attachment, Form } from '../../../workflow-template';
import type { DropResult } from 'react-beautiful-dnd';
import type { StepProps } from '../create-workflow';

const SKIP_FIRST_STEP = 1;

export function WorkflowBuild(props: StepProps) {
  let { onBack, onNext } = props;
  let { state, submitBuild } = useCreateWorkflow();
  let steps = state.details.steps;
  let formMethods = useForm({
    defaultValues: { steps },
  });
  let { control, getValues, handleSubmit } = formMethods;
  let { append, fields, move, remove, update } = useFieldArray({
    control,
    name: 'steps',
  });

  const handleOnAdd = useCallback(
    function handleOnAdd() {
      append(
        createStepFactory({
          details: {
            actionType: 'form',
            form: { id: '', name: '', versionId: '' },
          },
        }),
      );
    },
    [append],
  );

  const handleOnDragEnd = useCallback(
    function handleOnDragEnd(result: DropResult) {
      if (!result.destination) return;

      let {
        source: { index: sourceIndex },
        destination: { index: destinationIndex },
      } = result;

      move(sourceIndex + SKIP_FIRST_STEP, destinationIndex + SKIP_FIRST_STEP);
    },
    [move],
  );

  const handleOnRemove = useCallback(
    function handleOnRemove(index: number) {
      remove(index);
    },
    [remove],
  );

  const handleOnUpdate = useCallback(
    function handleOnUpdate(
      index: number,
      args: {
        approvers?: Approver[];
        attachment?: Attachment;
        form?: Form;
      },
    ) {
      let id = getValues(`steps.${index}.id`);
      let details = getValues(`steps.${index}.details`);

      const step = createStepFactory({
        id,
        details: { ...details, ...args },
      });

      update(index, step);
    },
    [getValues, update],
  );

  const handleOnSubmit = useCallback(
    function handleOnSubmit(data) {
      submitBuild({ steps: data });
      onNext();
    },
    [onNext, submitBuild],
  );

  return (
    <FormProvider {...formMethods}>
      <Stack gap="xxl">
        <StepContainer>
          <WorkflowSteps
            onAdd={handleOnAdd}
            onDragEnd={handleOnDragEnd}
            onRemove={handleOnRemove}
            onSubmit={handleSubmit}
            onUpdate={handleOnUpdate}
            steps={fields}
          />
        </StepContainer>
        <div style={{ alignSelf: 'end', marginBottom: '64px' }}>
          <Stack gap="md" dir="row">
            <SecondaryButton onClick={onBack}>Back</SecondaryButton>
            <PrimaryButton onClick={handleSubmit(handleOnSubmit)}>Next</PrimaryButton>
          </Stack>
        </div>
      </Stack>
    </FormProvider>
  );
}
