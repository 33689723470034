import { Col, Form, Icon, InputNumber, Radio, Row, Select, Checkbox, Tooltip } from 'antd';
import React, { useEffect, useRef } from 'react';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { ErrorSVG } from 'assets/UndrawSVG';
import moment, { Moment } from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import { BookingStatus, ServiceType, ShiftSlotStatus } from 'utilities/enum-utils';
import { cancelPayload } from 'interfaces/booking-interfaces';

const ACTION_TYPE = {
  BULK_CANCELLATION: 1,
  BEHALF: 2,
  REASON: 3,
  CANCELLATION_REASON: 4,
  LOADING: 5,
  ERROR: 6,
  SELECT_CHARGE: 7,
  SELECT_CHARGE_MANY_BOOKINGS: 8,
  PAY_TEAM_MEMBER_SHIFT: 9,
  CANCEL: 10,
  INFORM: 11,
};

interface ICancelBulkBookingsModalProps extends FormComponentProps {
  selectedBookings: any;
  onCancel: (data: any) => Promise<void>;
  amountItems: number;
  onClose: (cancelSuccessfully: boolean) => void;
  isOpen: boolean;
}

const CancelBulkBookingsModal = ({
  selectedBookings,
  amountItems,
  onClose,
  isOpen,
  onCancel,
  form,
}: ICancelBulkBookingsModalProps) => {
  const [step, setStep] = React.useState<number>(ACTION_TYPE.BULK_CANCELLATION);
  const [title, setTitle] = React.useState<any>('Bulk cancellation');
  const [cancelType, setCancelType] = React.useState<string>(null);
  const [isCharge, setIsCharge] = React.useState<boolean>(undefined);
  const [isChargeError, setIsChargeError] = React.useState<boolean>(undefined);
  const [formValues, setFormValues] = React.useState<any>(null);
  const [selectedCustomerCancelledOption, setSelectedCustomerCancelledOption] = React.useState<number>(1);
  const [inputBookingsToChargeValue, setInputBookingsToChargeValue] = React.useState<number>(1);
  const [isPayTeamMember, setIsPayTeamMember] = React.useState<boolean>(false);
  const [teamMemberShiftHours, setTeamMemberShiftHours] = React.useState<number>(undefined);
  const [isCustomerNoShow, setIsCustomerNoShow] = React.useState<number>(undefined);
  const [cancelSuccessfully, setCancelSuccessfully] = React.useState<boolean>(false);
  const { getFieldDecorator, getFieldValue } = form;

  const continueHandler = () => {
    setTitle('Cancel Booking');
    setStep(ACTION_TYPE.BEHALF);
  };

  const _onClose = () => {
    _reset();
    onClose(cancelSuccessfully);
  };

  const _reset = () => {
    setStep(ACTION_TYPE.BULK_CANCELLATION);
    setTitle('Bulk cancellation');
    setSelectedCustomerCancelledOption(1);
    setCancelType(null);
    setIsCharge(undefined);
    setFormValues(null);
    setInputBookingsToChargeValue(1);
    setIsCustomerNoShow(undefined);
    setCancelSuccessfully(false);
  };

  const _setBusinessCancel = () => {
    setTitle('Cancel on behalf of the business');
    setCancelType('business');
    setIsCharge(false);
    setStep(ACTION_TYPE.REASON);
  };

  const _setCustomerCancel = () => {
    setTitle(
      step !== ACTION_TYPE.BULK_CANCELLATION
        ? 'Cancel on behalf of the customer'
        : 'Cancel booking with no cancellation charges',
    );
    setCancelType('customer');
    setStep(ACTION_TYPE.CANCELLATION_REASON);
  };

  const _validateCancellationReason = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('You must add a cancellation reason.');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  const _goToChargeStep = () => {
    form.validateFieldsAndScroll(async (error, value) => {
      if (!error) {
        setFormValues(value);
        setStep(amountItems === 1 ? ACTION_TYPE.SELECT_CHARGE : ACTION_TYPE.SELECT_CHARGE_MANY_BOOKINGS);
      }
    });
  };

  const _onChangeIsChargeOption = (event) => {
    setIsCharge(event.target.value);
    setIsChargeError(false);
  };

  const _onChangeCustomerCancelledOption = (event) => {
    const selectedCustomerCancelledOption = event.target.value;
    setSelectedCustomerCancelledOption(event.target.value);
    setIsCharge(selectedCustomerCancelledOption > 1);
  };

  const _onChangeIsPayTeamMember = (e) => {
    const isPayTeamMemberStatus: boolean = isPayTeamMember;
    setIsPayTeamMember(!isPayTeamMemberStatus);
  };

  const _calculateTimeDifference = (startDateTime, endDateTime) => {
    const difference = moment(endDateTime).diff(moment(startDateTime), 'hours', true);
    return difference;
  };

  const _validateTeamMemberShiftHours = (rule, value, callback) => {
    const { startDateTime, endDateTime } = selectedBookings[0];
    const bookingTime: any = _calculateTimeDifference(moment(startDateTime), moment(endDateTime));
    try {
      if (!value || value <= 0) {
        throw Error('Please enter a value');
      } else if (value > bookingTime) {
        throw Error('You cannot enter a value greater than the scheduled length of the booking');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  const _renderDuration = (startDateTime: Moment, endDateTime: Moment) => {
    const duration = CommonUtils.formatDuration(startDateTime, endDateTime);
    const durationDisplays = [];

    if (duration.days) {
      durationDisplays.push(
        <>
          <b>{duration.days.value}</b> {`${duration.days.label} `}
        </>,
      );
    }
    if (duration.hours) {
      durationDisplays.push(
        <>
          <b>{duration.hours.value}</b> {`${duration.hours.label} `}
        </>,
      );
    }

    if (duration.minutes) {
      durationDisplays.push(
        <>
          <b>{duration.minutes.value}</b> {duration.minutes.label}
        </>,
      );
    }
    return (
      <Text color="tertiary">
        {_.map(durationDisplays, (display) => {
          return display;
        })}
      </Text>
    );
  };

  const firstTimeRender = useRef(true);

  useEffect(() => {
    if (!firstTimeRender.current && isCustomerNoShow !== undefined) {
      if (isCharge && amountItems === 1) {
        _onCancelBooking();
      } else if (
        isCharge === false &&
        selectedBookings[0].serviceType === ServiceType.INDIVIDUAL &&
        (selectedBookings[0].shiftSlotStatus === ShiftSlotStatus.PENDING ||
          selectedBookings[0].shiftSlotStatus === ShiftSlotStatus.CONFIRMED) &&
        selectedBookings[0].status === BookingStatus.CONFIRMED
      ) {
        setStep(ACTION_TYPE.PAY_TEAM_MEMBER_SHIFT);
      } else {
        _onCancelBooking();
      }
    }
  }, [isCustomerNoShow]);

  const _onCancelBookingChargeCustomer = () => {
    if (isCharge === undefined) {
      setIsChargeError(true);
      return;
    }

    form.validateFieldsAndScroll(async (error, value) => {
      if (!error) {
        const isCustomerNoShowValue = value.chargeCustomerCancellationOption;
        setIsCustomerNoShow(isCustomerNoShowValue !== undefined ? isCustomerNoShowValue : 0);
      }
    });
  };

  useEffect(() => {
    if (!firstTimeRender.current) {
      _onCancelBooking();
    }
  }, [teamMemberShiftHours]);

  useEffect(() => {
    firstTimeRender.current = false;
  }, []);

  const _onPayTeamMemberForShift = async () => {
    let isFormValid: boolean = true;
    form.validateFields((error, value) => {
      if (error) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      setTeamMemberShiftHours(
        form.getFieldValue('payTeamMemberShiftHours') !== undefined ? form.getFieldValue('payTeamMemberShiftHours') : 0,
      );
    }
  };

  const _onCancelBooking = async () => {
    let isValid = true;
    let data: cancelPayload = null;

    const numberOfBookingsToCharge =
      isCharge && cancelType === 'customer'
        ? amountItems === 1
          ? 1
          : selectedCustomerCancelledOption === 1
          ? 0
          : amountItems
        : 0;

    // When the cancellation action happens after the reason form step:
    if (formValues) {
      data = {
        isBusinessCancel: cancelType === 'business',
        isCharge: !!isCharge,
        isCustomerNoShow: isCustomerNoShow === 0 ? false : true,
        shiftSlot: {
          isPaidShift: isPayTeamMember,
          shiftHours: teamMemberShiftHours ? teamMemberShiftHours : 0,
        },
        ...formValues,
      };
    } else {
      // For business cancelled on 1 bookings. (form is the last step before saving):
      form.validateFieldsAndScroll(async (error, value) => {
        if (!error) {
          data = {
            isBusinessCancel: cancelType === 'business',
            isCharge: !!isCharge,
            isCustomerNoShow: isCustomerNoShow === 0 ? false : true,
            shiftSlot: {
              isPaidShift: false,
              shiftHours: 0,
            },
            ...value,
          };
        } else {
          console.log(error);
          isValid = false;
        }
      });
    }

    if (isValid && !_.isEmpty(data)) {
      setStep(ACTION_TYPE.LOADING);
      setInputBookingsToChargeValue(numberOfBookingsToCharge);

      try {
        await onCancel(data);
        setTitle(
          <>
            Booking cancelled successfully
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>,
        );
        setStep(ACTION_TYPE.INFORM);
        setCancelSuccessfully(true);
      } catch (e) {
        setStep(ACTION_TYPE.ERROR);
      }
    }
  };

  const _renderView = () => {
    if (step === ACTION_TYPE.BULK_CANCELLATION) {
      return (
        <>
          <div>
            <Paragraph className="pt-medium mb-large">
              You have selected <span className="text-weight-bold">{amountItems} bookings</span> to be cancelled.
            </Paragraph>

            <Paragraph className="pb-x2-large mb-none">
              You will be prompted to specify the cancellation reason in the following steps. This cancellation reason
              will be applied to all selected bookings.
            </Paragraph>
          </div>

          <Row type={'flex'} justify={'end'}>
            <Col>
              <SecondaryButton className="mr-medium" size="large" onClick={_onClose}>
                Go back
              </SecondaryButton>
            </Col>

            <Col>
              <PrimaryButton size="large" onClick={continueHandler}>
                Continue
              </PrimaryButton>
            </Col>
          </Row>
        </>
      );
    } else if (step === ACTION_TYPE.BEHALF) {
      return (
        <>
          <Paragraph>Please select one of the following options.</Paragraph>
          <div className="bordered-top mb-x2-large">
            <Row className="bordered-bottom hover-bg-tertiary cursor-pointer" onClick={_setBusinessCancel}>
              <div className="ph-12">
                <Paragraph className="text-size-x2-large pt-medium mb-12 text-weight-regular">
                  Cancel on behalf of the business
                </Paragraph>
                <Paragraph className="text-color-secondary text-size-regular">
                  Select this option if you are cancelling on behalf of the business.
                </Paragraph>
              </div>
            </Row>
            <Row className="bordered-bottom hover-bg-tertiary cursor-pointer" onClick={_setCustomerCancel}>
              <div className="ph-12">
                <Paragraph className="text-size-x2-large pt-medium mb-12 text-weight-regular">
                  Cancel on behalf of the customer
                </Paragraph>
                <Paragraph className="text-color-secondary text-size-regular">
                  Select this option if you are cancelling on behalf of the business.
                </Paragraph>
              </div>
            </Row>
          </div>

          <Row type={'flex'} justify={'end'}>
            <Col>
              <GhostButton size="large" onClick={_reset}>
                I've changed my mind
              </GhostButton>
            </Col>
          </Row>
        </>
      );
    } else if (step === ACTION_TYPE.REASON) {
      return (
        <>
          <div>
            <div style={{ minHeight: 317 }}>
              <Paragraph>
                Please indicate the reason for <b>cancelling</b> this booking.
              </Paragraph>
              <Row className="mb-small" type="flex" align="top">
                <Col span={24}>
                  <Form.Item>
                    {getFieldDecorator('reason', {
                      initialValue: undefined,
                      rules: [{ validator: _validateCancellationReason }],
                    })(
                      <TextArea
                        placeholder={'Cancellation reason'}
                        autoSize={{ minRows: 4, maxRows: 8 }}
                        className="width-full"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <ActionModalFooter>
              <GhostButton size="large" className="mr-medium" onClick={_reset}>
                I've changed my mind
              </GhostButton>
              <PrimaryButton size="large" onClick={_onCancelBooking}>
                Confirm
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        </>
      );
    } else if (step === ACTION_TYPE.CANCELLATION_REASON) {
      return (
        <div>
          <div style={{ minHeight: 317 }}>
            <Paragraph>
              Please select the reason the customer asked you to <b>cancel</b> this booking.
            </Paragraph>

            <div className="">
              <Row className="mb-small" type="flex" align="top">
                <Col span={24}>
                  <Form.Item>
                    {getFieldDecorator('cancellationReason', {
                      initialValue: undefined,
                      rules: [{ required: true, message: 'Please select cancellation reason' }],
                    })(
                      <Select className="width-2/3" size={'large'} placeholder={'Cancellation reason'}>
                        <Select.Option value="NSDH">No show due to health reasons (NSDH)</Select.Option>
                        <Select.Option value="NSDF">No show due to family reasons (NSDF)</Select.Option>
                        <Select.Option value="NSDT">No show due to unavailability of transport (NSDT)</Select.Option>
                        <Select.Option value="NSDO">Other</Select.Option>
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
              </Row>

              {getFieldValue('cancellationReason') === 'NSDO' && (
                <Row className="mb-small" type="flex" align="top">
                  <Col span={24}>
                    <div className={'mb-medium'}>
                      <Text>Please specify the reason</Text>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('reason', {
                        initialValue: undefined,
                        rules: [{ validator: _validateCancellationReason }],
                      })(
                        <TextArea
                          className="width-full"
                          placeholder={'Cancellation reason'}
                          autoSize={{ minRows: 3, maxRows: 6 }}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={_reset}>
              Back
            </GhostButton>
            <PrimaryButton size="large" onClick={_goToChargeStep}>
              Confirm
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    } else if (step === ACTION_TYPE.LOADING) {
      return (
        <div>
          <div style={{ minHeight: 317 }}>
            <SpinningLoader size={150} message={'Loading'} />
          </div>
        </div>
      );
    } else if (step === ACTION_TYPE.ERROR) {
      return (
        <div>
          <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
            <div className="pv-medium flex-column justify-center">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium flex-column justify-center ">
              <Paragraph>Oops something has gone wrong, please try again</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={_onClose}>
              Back to booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    } else if (step === ACTION_TYPE.SELECT_CHARGE) {
      return (
        <div>
          <div className="anim-slide-left">
            <div className="mb-medium">
              <Paragraph>Please select one of the following options. You can change your mind later.</Paragraph>
            </div>

            <div className=" mb-x-large">
              <Radio
                value={true}
                onChange={_onChangeIsChargeOption}
                checked={isCharge}
                className={`${isCharge === true && 'text-weight-bold'} mb-small width-full`}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Charge a cancellation fee
                </div>
              </Radio>
              {isCharge === true && (
                <Form.Item className="mt-small">
                  {getFieldDecorator('chargeCustomerCancellationOption', {
                    initialValue: undefined,
                    rules: [{ required: true, message: 'Please select a charge cancellation option' }],
                  })(
                    <Select
                      className="width-2/3"
                      size="large"
                      placeholder={'Select a charge cancellation option'}
                      dropdownMatchSelectWidth={true}
                    >
                      <Select.Option value={1}>Customer was a no show for the booking</Select.Option>
                      <Select.Option value={0}>Customer cancelled ahead of time</Select.Option>
                    </Select>,
                  )}
                </Form.Item>
              )}
              <Radio
                value={false}
                onChange={_onChangeIsChargeOption}
                checked={isCharge === false}
                className={`${isCharge === false && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Do not charge a cancellation fee
                </div>
              </Radio>
              {isChargeError && <div className="text-color-red-dark">Please select an option</div>}
            </div>
          </div>

          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={_onClose}>
              Back
            </SecondaryButton>
            <PrimaryButton size="large" onClick={_onCancelBookingChargeCustomer}>
              Cancel booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    } else if (step === ACTION_TYPE.SELECT_CHARGE_MANY_BOOKINGS) {
      return (
        <>
          <div className="anim-slide-left">
            <Paragraph>
              You are cancelling {amountItems} booking
              {amountItems !== 1 && 's'}. How many do you wish to charge a cancellation fee for?
            </Paragraph>
          </div>
          <div>
            <Radio.Group
              value={selectedCustomerCancelledOption}
              onChange={_onChangeCustomerCancelledOption}
              className="ml-medium"
            >
              <Radio value={1} className={`${selectedCustomerCancelledOption === 1 && 'text-weight-bold'} mb-small `}>
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Do not charge a cancellation fee for any bookings.
                </div>
              </Radio>
              <br />
              <Radio value={2} className={`${selectedCustomerCancelledOption === 2 && 'text-weight-bold'} mb-small `}>
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Charge cancellation fee for all bookings being cancelled.
                </div>
              </Radio>
            </Radio.Group>
          </div>
          <div className={'mt-large'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton
                className="mr-medium"
                size="large"
                onClick={() => setStep(ACTION_TYPE.CANCELLATION_REASON)}
              >
                Back
              </SecondaryButton>
              <PrimaryButton size="large" onClick={_onCancelBooking}>
                Confirm
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    } else if (step === ACTION_TYPE.PAY_TEAM_MEMBER_SHIFT) {
      const { startDateTime, endDateTime, timezone } = selectedBookings[0];
      const bookingStartDateTime = moment.tz(startDateTime, timezone).format('hh:mm A , DD MMMM YYYY');
      const bookingEndDateTime = moment.tz(endDateTime, timezone).format('hh:mm A , DD MMMM YYYY');

      return (
        <>
          <div className="anim-slide-left">
            <Paragraph>
              You are choosing to not charge a cancellation fee for this booking. If the assigned team member still
              needs to be paid for this booking please check the box below. If not press the finish button to finalise
              the cancellation of the booking
            </Paragraph>
          </div>
          <div className={'mt-large'}>
            <Checkbox checked={isPayTeamMember} onChange={_onChangeIsPayTeamMember} defaultChecked={false}>
              Pay Team member for this booking
            </Checkbox>
          </div>
          {isPayTeamMember && (
            <div>
              <div className={'mt-large'}>
                <Text className={'text-color-tertiary text-size-small'}>HOURS TEAM MEMBER IS TO BE PAID</Text>{' '}
                <Tooltip
                  title={"Decimal values only. Eg: if 1hour 30minutes needs to be paid, please enter '1.5'"}
                  placement="top"
                  style={{ width: '239px', height: '52px' }}
                >
                  <Icon type="question-circle" className="text-size-x-large text-color-blue ml-x-small" />
                </Tooltip>
              </div>
              <div>
                <Form.Item>
                  {getFieldDecorator('payTeamMemberShiftHours', {
                    initialValue: '',
                    rules: [{ validator: _validateTeamMemberShiftHours }],
                  })(<InputNumber style={{ width: '320px', height: '40px' }} placeholder={'Input Value...'} />)}
                </Form.Item>
              </div>
              <div className={'mt-large'}>
                <Text className={'text-color-tertiary text-size-small'}>BOOKING SCHEDULED TIME</Text>
              </div>
              <div>
                <Text>
                  {bookingStartDateTime} - {bookingEndDateTime}
                </Text>
              </div>
              <div>{_renderDuration(moment.tz(startDateTime, timezone), moment.tz(endDateTime, timezone))}</div>
            </div>
          )}
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={() => setStep(ACTION_TYPE.SELECT_CHARGE)}>
              Back
            </SecondaryButton>
            <PrimaryButton size="large" onClick={_onPayTeamMemberForShift}>
              Finish
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    } else if (step === ACTION_TYPE.INFORM) {
      return (
        <div>
          <div className="mb-medium">
            <Paragraph>
              {amountItems === 1 ? (
                <>
                  <Text>You have successfully</Text>{' '}
                  <Text weight={'bold'}>
                    cancelled {amountItems} booking
                    {amountItems !== 1 && 's'}
                  </Text>{' '}
                  {`on behalf of the ${
                    cancelType === 'customer'
                      ? `customer and ${!isCharge ? 'not' : ''} charged a cancellation fee`
                      : 'business'
                  }.`}
                  {selectedBookings[0].shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID ? (
                    <div className={'mt-large'}>
                      <Text>You have opted to pay the assigned team member for this shift.</Text>
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <Text>You have successfully</Text>{' '}
                  <Text weight={'bold'}>
                    cancelled {amountItems} booking{amountItems !== 1 && 's'}
                  </Text>{' '}
                  {`on behalf of the ${
                    cancelType === 'customer'
                      ? `customer and ${!isCharge ? 'not' : ''} charged a cancellation fee${
                          isCharge && inputBookingsToChargeValue >= 1
                            ? ' for ' + inputBookingsToChargeValue + ' bookings'
                            : ''
                        }`
                      : 'business'
                  }.`}
                </>
              )}
            </Paragraph>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={_onClose}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  return (
    <ActionModal isOpen={isOpen} onClose={_onClose} title={title}>
      {_renderView()}
    </ActionModal>
  );
};

export default Form.create<ICancelBulkBookingsModalProps>()(CancelBulkBookingsModal);
