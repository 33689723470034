import { ControllerRenderProps, FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { Address } from './types';
import { AddressInputProps } from './address-input';

export const rhfRegister = <T extends FieldValues>(
  form: UseFormReturn<T>,
  fieldName: Path<T>,
): Pick<AddressInputProps, 'onChange' | 'onBlur' | 'onChangeErrors'> => {
  let hasErrors_: boolean | undefined = form.formState.errors[fieldName] !== undefined;

  form.register(fieldName, {
    validate: () => !hasErrors_,
  });

  return {
    onChange: (address: Address) => {
      // @ts-expect-error -- Path<T> is not calculating the correct type
      form.setValue(fieldName, address, { shouldValidate: true, shouldDirty: true });
    },
    onBlur: form.register(fieldName).onBlur,
    onChangeErrors: (_errors, hasErrors) => {
      hasErrors_ = hasErrors;
      void form.trigger(fieldName);
    },
  };
};

export const rhfRegisterControlled = <T extends FieldValues>(
  form: UseFormReturn<T>,
  controllerRenderFieldProps: ControllerRenderProps<T, Path<T>>,
  fieldName: Path<T>,
): Pick<AddressInputProps, 'value' | 'onChange' | 'onBlur' | 'onChangeErrors'> => {
  let hasErrors_: boolean | undefined = form.formState.errors[fieldName] !== undefined;

  form.register(fieldName, {
    validate: () => !hasErrors_,
  });

  return {
    value: controllerRenderFieldProps.value,
    onChange: controllerRenderFieldProps.onChange,
    onBlur: controllerRenderFieldProps.onBlur,
    onChangeErrors: (_errors, hasErrors) => {
      hasErrors_ = hasErrors;
      void form.trigger(fieldName);
    },
  };
};
