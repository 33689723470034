import { Avatar } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';
import { ROSTER_WEEKLY_CONFIG } from 'common-components/roster-control/roster-weekly/roster-weekly-config';

const { CONTENT_PANEL_WIDTH } = ROSTER_WEEKLY_CONFIG;

export function OpenSlotsDetailsColumn({ numberOfSlots }: { numberOfSlots: number }) {
  return (
    <div
      className="flex-row pv-large pl-x-large pr-medium bg-quaternary justify-between bordered-right bordered-bottom"
      style={{ minWidth: `${CONTENT_PANEL_WIDTH}px`, maxWidth: `${CONTENT_PANEL_WIDTH}px` }}
    >
      <div className="flex-row">
        <Avatar shape="square" size="large" icon="calendar" className="mr-small" />
        <div className="flex-column line-height-135">
          <Text lineHeight={120}>Open slots</Text>
          <Text size="regular" color="secondary" lineHeight={135}>
            2 slots
          </Text>
        </div>
      </div>
    </div>
  );
}
