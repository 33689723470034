import { Box, Inline, Stack, Text } from '@goodhuman-me/components';
import { Pen } from 'design-components/icon';
import React from 'react';
import { Button } from 'design-components';
import { UserPermissionRole } from 'utilities/enum-utils';
import { ADVANCED_PERMISSION_ROLE_TYPE } from '../../utils/constants';

interface IProps {
  title: string;
  type?: string;
  description: string;
  extendPermissions?: React.ReactNode;
  permissionRoleId?: number;
  typePermissionPresent: string;
  handleSetTypePermissionPresent: (value: string) => void;
}

const AdvancedPermissionRow: React.FC<IProps> = ({
  extendPermissions,
  title,
  description,
  type,
  permissionRoleId,
  typePermissionPresent,
  handleSetTypePermissionPresent,
}) => {
  const advancedPermissionModalExtensions: {
    [key: string]: {
      [permissionRoleId: number]: string;
    };
  } = {
    [ADVANCED_PERMISSION_ROLE_TYPE.PAYMENT]: {
      [UserPermissionRole.PaymentMember]: 'Member',
      [UserPermissionRole.PaymentManager]: 'Manager',
    },
    [ADVANCED_PERMISSION_ROLE_TYPE.WORKFLOW]: {
      [UserPermissionRole.WorkflowBasic]: 'Basic',
      [UserPermissionRole.WorkflowElevated]: 'Elevated',
      [UserPermissionRole.WorkflowManager]: 'Manager',
    },
    [ADVANCED_PERMISSION_ROLE_TYPE.SUBSCRIPTION]: {
      [UserPermissionRole.SubscriptionBillingViewOnly]: 'View only',
      [UserPermissionRole.SubscriptionBillingAllAccess]: 'All access',
    },
    [ADVANCED_PERMISSION_ROLE_TYPE.PAYMENT]: {
      [UserPermissionRole.PaymentMember]: 'Member',
      [UserPermissionRole.PaymentManager]: 'Manager',
    },
    [ADVANCED_PERMISSION_ROLE_TYPE.TEAM]: {
      [UserPermissionRole.TeamViewOnly]: 'View only',
      [UserPermissionRole.TeamManager]: 'Manage',
      [UserPermissionRole.TeamNoAccess]: 'No access',
    },
    [ADVANCED_PERMISSION_ROLE_TYPE.CUSTOMER]: {
      [UserPermissionRole.CustomerAccessViewOnly]: 'View only',
      [UserPermissionRole.CustomerAccessManager]: 'Manager',
    },
    [ADVANCED_PERMISSION_ROLE_TYPE.FORM_BUILDER]: {
      [UserPermissionRole.FormBuilder]: 'Standard Access',
    },
    [ADVANCED_PERMISSION_ROLE_TYPE.PLAN_MANAGEMENT]: {
      [UserPermissionRole.PlanManager]: 'Access',
    },
  };

  const permissionButtonLabel = advancedPermissionModalExtensions[type][permissionRoleId];

  const extendSelectPermissionVisible = type === typePermissionPresent;

  return (
    <>
      <Inline
        justifyContent="space-between"
        paddingY="$space200 !important"
        paddingLeft="$space200 !important"
        paddingRight="$space125 !important"
      >
        <Stack gap="$space20">
          <Text size="small" color="$black" fontWeight="$semi">
            {title}
          </Text>
          <Text size="small" color="$muted" fontWeight="$normal">
            {description}
          </Text>
        </Stack>

        <Inline gap="$space150" justifyContent="center" alignItems="center">
          {!!extendPermissions && (
            <Button
              kind="accent"
              emphasis="quiet"
              size="small"
              onPress={() => handleSetTypePermissionPresent(type)}
              backgroundColor={extendSelectPermissionVisible ? '$accentBackground' : ''}
              color={extendSelectPermissionVisible ? '$accentPressed' : ''}
            >
              <Text size="small">{permissionButtonLabel}</Text>
              <Pen width="$size250 !important" height="$size250 !important" />
            </Button>
          )}
        </Inline>
      </Inline>

      {!!extendPermissions && extendSelectPermissionVisible && (
        <Box padding="$space125 $space100">{extendPermissions}</Box>
      )}
    </>
  );
};

export default AdvancedPermissionRow;
