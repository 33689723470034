import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootDispatch, IRootState } from 'stores/rematch/root-store';

/**
 * Custom hook to fetch company data lite.
 * @returns The company data lite.
 */
export function useFetchCompanyDataLite() {
  const companyDataLite = useSelector((state: IRootState) => state.companyStore.companyDataLite);
  const {
    companyStore: { doFetchCompanyLite },
  } = useDispatch<IRootDispatch>();

  useEffect(() => {
    if (!companyDataLite) {
      void doFetchCompanyLite([]);
    }
  }, [companyDataLite, doFetchCompanyLite]);

  return companyDataLite;
}
