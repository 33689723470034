import React, { Component } from 'react';
import { Avatar, Col, notification, Row, Skeleton } from 'antd';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActivityGroupsModal from 'common-components/activity-groups/modals/ActivityGroupsModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import {
  IGroupServiceSession,
  IGroupServiceScheduleTimeSlot,
  IGroupServiceCustomerRatio,
  IGroupServiceServiceAgreement,
} from 'interfaces/service-interfaces';
import GroupServiceSessionsPanel from 'views/group-services/service-details/components/GroupServiceSessionsPanel';
import _ from 'lodash';
import moment from 'moment-timezone';
import {
  ActivityGroupMemberType,
  ActivityGroupModalType,
  GroupBookingActionType,
  SessionStatus,
} from 'utilities/enum-utils';
import { IYearAndMonthFilter } from 'interfaces/filter-interfaces';
import EditSessionDetailModal from '../components/EditSessionDetailModal';

enum SessionAction {
  EDIT_BOOKING_TIME_RATIOS = 'EDIT_BOOKING_TIME_RATIOS',
}

type SessionModalType = ActivityGroupModalType | SessionAction;

type ISessionReviewStepPanelProps = {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setCustomerToSchedule?: typeof dispatch.servicesStore.setCustomerToSchedule;
  setGroupServiceActivityGroups: typeof dispatch.groupServiceStore.setGroupServiceActivityGroups;
  doFetchGroupServiceSessions?: typeof dispatch.servicesStore.doFetchGroupServiceSessions;
  doFetchGroupServiceIndividualSessionServiceAgreements: typeof dispatch.groupServiceStore.doFetchGroupServiceIndividualSessionServiceAgreements;
  customerToSchedule: typeof state.servicesStore.customerToSchedule;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  groupServiceSessions: typeof state.servicesStore.groupServiceSessions;
  history?: any;
};

type ISessionReviewStepPanelState = {
  isLoading: boolean;
  sessions: IGroupServiceSession[] | any;
  isNoSessionSelectedError: boolean;
  periodFilter: IYearAndMonthFilter | null;
  selectedSessions: IGroupServiceSession[];
  updatedSessions: IGroupServiceSession[];
  currentEditSession: IGroupServiceSession;
  currentModalType: SessionModalType;
  currentMemberType: ActivityGroupMemberType;
  isModalOpen: boolean;
};

class SessionReviewStepPanel extends Component<ISessionReviewStepPanelProps, ISessionReviewStepPanelState> {
  state = {
    isLoading: true,
    sessions: this.props.groupServiceSessions ? this.props.groupServiceSessions : [],
    isNoSessionSelectedError: false,
    periodFilter: null,
    selectedSessions: this.props.customerToSchedule.sessions ? this.props.customerToSchedule.sessions : [],
    updatedSessions: this.props.customerToSchedule.updatedSessions ? this.props.customerToSchedule.updatedSessions : [],
    currentEditSession: null,
    currentModalType: null,
    currentMemberType: ActivityGroupMemberType.CUSTOMER,
    isModalOpen: false,
  };

  private _onSelect = (session) => {
    const { selectedSessions } = this.state;
    let newSelectedSession = selectedSessions;
    if (
      _.find(selectedSessions, (selectedSession) => selectedSession.serviceDateTimeId === session.serviceDateTimeId)
    ) {
      newSelectedSession = _.filter(
        selectedSessions,
        (selectedSession) => selectedSession.serviceDateTimeId !== session.serviceDateTimeId,
      );
    } else {
      newSelectedSession.push(session);
    }
    this.setState({ selectedSessions: newSelectedSession, isNoSessionSelectedError: false });
  };

  private onSelectFiltered = (targetSessions) => {
    const { selectedSessions } = this.state;

    // Check if all sessions are selected
    const isAllSelected = _.every(targetSessions, (targetSession) =>
      _.find(
        selectedSessions,
        (selectedSession) => selectedSession.serviceDateTimeId === targetSession.serviceDateTimeId,
      ),
    );

    // if all sessions are selected, then unselect all
    let newSelectedSessions = selectedSessions;
    if (isAllSelected) {
      newSelectedSessions = _.filter(
        selectedSessions,
        (selectedSession) =>
          !_.find(
            targetSessions,
            (targetSession) => targetSession.serviceDateTimeId === selectedSession.serviceDateTimeId,
          ),
      );
    } else {
      newSelectedSessions = _.uniqBy([...selectedSessions, ...targetSessions], 'serviceDateTimeId');
    }

    this.setState({ selectedSessions: newSelectedSessions, isNoSessionSelectedError: false });
  };

  private onSelectAll = () => {
    this.setState({ selectedSessions: this.state.sessions, isNoSessionSelectedError: false });
  };
  private onDeselectAll = () => {
    this.setState({ selectedSessions: [], isNoSessionSelectedError: true });
  };

  private _goToNext = async () => {
    const { setCustomerToSchedule, customerToSchedule } = this.props;
    const { selectedSessions, updatedSessions } = this.state;

    let isFormValid = true;
    if (selectedSessions.length === 0) {
      isFormValid = false;
      this.setState({ isNoSessionSelectedError: true });
    }

    if (isFormValid) {
      try {
        await setCustomerToSchedule({
          ...customerToSchedule,
          sessions: selectedSessions,
          updatedSessions,
        });
        this.props.onNextStep();
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  private _goToPrevious = async () => {
    const { setCustomerToSchedule, customerToSchedule, onPreviousStep } = this.props;
    const newCustomerToSchedule = _.cloneDeep(customerToSchedule);
    delete newCustomerToSchedule['sessions'];
    await setCustomerToSchedule({
      ...newCustomerToSchedule,
    });
    onPreviousStep();
  };

  private _handleEditAddCustomerToSessions = (session: IGroupServiceSession, actionType) => {
    const { setGroupServiceActivityGroups } = this.props;
    const { sessions } = this.state;
    let modalType: SessionModalType = SessionAction.EDIT_BOOKING_TIME_RATIOS;

    if (actionType === ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SESSION) {
      setGroupServiceActivityGroups(
        _.get(
          _.find(sessions, (item) => item.serviceDateTimeId === session.serviceDateTimeId),
          'activityGroups',
          [],
        ),
      );
      modalType = ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SESSION;
    }

    this.setState({ isModalOpen: true, currentEditSession: session, currentModalType: modalType });
  };

  private _assignDataToSession = (session: IGroupServiceSession, data) => {
    const { selectedSessions, updatedSessions } = this.state;

    const indexOfSelectedSession = _.findIndex(
      selectedSessions,
      (item) => item.serviceDateTimeId === session.serviceDateTimeId,
    );
    const newSelections = selectedSessions;
    if (indexOfSelectedSession > -1) {
      _.set(newSelections, `[${indexOfSelectedSession}]`, {
        ...newSelections[indexOfSelectedSession],
        ...data,
      });
    }

    const indexOfUpdatedSession = _.findIndex(
      updatedSessions,
      (item) => item.serviceDateTimeId === session.serviceDateTimeId,
    );
    const newUpdatedSessions = updatedSessions;
    if (indexOfUpdatedSession > -1) {
      _.set(newUpdatedSessions, `[${indexOfUpdatedSession}]`, {
        ...newUpdatedSessions[indexOfUpdatedSession],
        ...data,
      });
    } else {
      newUpdatedSessions.push({ ...session, ...data });
    }
    this.setState({
      selectedSessions: newSelections,
      updatedSessions: newUpdatedSessions,
    });
  };

  private _handleModalAction = async (data, actionType: SessionModalType) => {
    if (actionType === ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SESSION) {
      const { session, addedActivityGroups } = data;
      this._assignDataToSession(session, { activityGroups: addedActivityGroups });
    } else if (actionType === SessionAction.EDIT_BOOKING_TIME_RATIOS) {
      const { session, customerRatio } = data;
      const { startDateTime, endDateTime } = customerRatio;
      this._assignDataToSession(session, { customerRatio: customerRatio, startDateTime, endDateTime });
    }
  };

  private _handleCloseModal = () => {
    this.setState({ isModalOpen: false, currentEditSession: null });
  };

  private _setMappingTime = (
    mappingTime: { startDateTime: moment.Moment; endDateTime: moment.Moment },
    startDatetime: moment.Moment,
    endDateTime: moment.Moment,
    timezone: string,
  ) => {
    if (!_.isEmpty(mappingTime)) {
      const mappingTimeStartDateTime = moment.tz(
        moment.tz(mappingTime.startDateTime, timezone).format('h:mmA'),
        'h:mmA',
        timezone,
      );
      const mappingTimeEndDateTime = moment.tz(
        moment.tz(mappingTime.endDateTime, timezone).format('h:mmA'),
        'h:mmA',
        timezone,
      );
      startDatetime.set({
        hour: mappingTimeStartDateTime.get('hour'),
        minute: mappingTimeStartDateTime.get('minute'),
      });
      endDateTime.set({
        hour: mappingTimeEndDateTime.get('hour'),
        minute: mappingTimeEndDateTime.get('minute'),
      });
    }
  };

  componentDidMount = async () => {
    const { selectedGroupService, customerToSchedule, doFetchGroupServiceIndividualSessionServiceAgreements } =
      this.props;
    try {
      let sessions: IGroupServiceSession[] = await this.props.doFetchGroupServiceSessions({
        serviceId: selectedGroupService.serviceId,
        customerUserId: customerToSchedule.selectedCustomer.userId,
        isSchedule: customerToSchedule.actionType === GroupBookingActionType.SCHEDULES,
        scheduleTimeSlotIds:
          customerToSchedule.actionType === GroupBookingActionType.SCHEDULES && customerToSchedule.scheduleTimeSlotIds,
        scheduleTimeSlots:
          customerToSchedule.actionType === GroupBookingActionType.SCHEDULES && customerToSchedule.scheduleTimeSlots,
        timezone: selectedGroupService.timezone,
      });
      const groupServiceServiceAgreements: IGroupServiceServiceAgreement[] =
        await doFetchGroupServiceIndividualSessionServiceAgreements({
          serviceId: selectedGroupService.serviceId,
          customerUserId: customerToSchedule.selectedCustomer.userId,
          serviceDateTimeIds: _.map(sessions, (session) => session.serviceDateTimeId),
        });
      let sessionsWithoutTimezone = [];
      let selectedSessions = [];
      _.forEach(sessions, (session) => {
        const hasError =
          session.alreadyInSession ||
          (session.capacity && session.bookedCapacity !== undefined && session.bookedCapacity >= session.capacity) ||
          session.sessionStatus === SessionStatus.CANCELLED ||
          session.sessionStatus === SessionStatus.CLOSED;
        const mappingTimeSlot = _.find(
          _.get(customerToSchedule, 'scheduleTimeSlots', []),
          (timeSlot: IGroupServiceScheduleTimeSlot) => timeSlot.scheduleTimeSlotId === session.scheduleTimeSlotId,
        );
        const customerServiceAgreement = _.find(
          groupServiceServiceAgreements,
          (agreement) => agreement.serviceDateTimeId === session.serviceDateTimeId,
        );

        if (!_.isEmpty(mappingTimeSlot) && !_.isEmpty(session.activityGroups)) {
          const scheduleActivityGroups = _.get(mappingTimeSlot, 'activityGroups', []);
          session.activityGroups = _.map(session.activityGroups, (activityGroup) =>
            Object.assign(activityGroup, {
              isAssignedTo: _.some(
                scheduleActivityGroups,
                (scheduleActivityGroup) =>
                  scheduleActivityGroup.serviceActivityGroupId === activityGroup.serviceActivityGroupId &&
                  scheduleActivityGroup.isAssignedTo,
              ),
            }),
          );
        }

        const currentDate = moment();
        const isSessionBeforeCurrentDate = moment(session.startDateTime).isBefore(currentDate);

        const sessionWithoutTimezone = {
          ...session,
          activityGroups: session.activityGroups,
          startDateTime: moment.tz(
            moment.tz(session.startDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm'),
            selectedGroupService.timezone,
          ),
          endDateTime: moment.tz(
            moment.tz(session.endDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm'),
            selectedGroupService.timezone,
          ),
        };

        if (_.isEmpty(sessionWithoutTimezone.customerRatio)) {
          const scheduleCustomerRatios = _.get(mappingTimeSlot, 'customerRatio');
          const startDateTime = moment.tz(sessionWithoutTimezone.startDateTime, selectedGroupService.timezone);
          const endDateTime = moment.tz(sessionWithoutTimezone.endDateTime, selectedGroupService.timezone);
          this._setMappingTime(mappingTimeSlot, startDateTime, endDateTime, selectedGroupService.timezone);
          const defaultTeamMemberCustomerRatio = _.get(
            customerServiceAgreement,
            'teamMemberCustomerRatio.ndis',
            selectedGroupService.teamMemberCustomerRatio.ndis,
          );

          const initialCustomerRatio: IGroupServiceCustomerRatio = {
            scheduleId: sessionWithoutTimezone.serviceDateTimeId,
            customRatio: scheduleCustomerRatios
              ? _.map(scheduleCustomerRatios.customRatio, (customRatio) => {
                  const overrideStartDateTime = moment.tz(_.clone(startDateTime), selectedGroupService.timezone);
                  const overrideEndDateTime = moment.tz(_.clone(endDateTime), selectedGroupService.timezone);
                  this._setMappingTime(
                    customRatio,
                    overrideStartDateTime,
                    overrideEndDateTime,
                    selectedGroupService.timezone,
                  );
                  return { ...customRatio, startDateTime: overrideStartDateTime, endDateTime: overrideEndDateTime };
                })
              : [
                  {
                    customTimeId: uuidv4(),
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    teamMemberCustomerRatio: defaultTeamMemberCustomerRatio,
                  },
                ],
            durationRatios: scheduleCustomerRatios?.durationRatios ?? [],
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            errors: null,
            isCustomRatio: _.get(scheduleCustomerRatios, 'isCustomRatio', false),
            teamMemberCustomerRatio: scheduleCustomerRatios?.teamMemberCustomerRatio,
          };
          sessionWithoutTimezone.customerRatio = initialCustomerRatio;
        }

        this._setMappingTime(
          mappingTimeSlot,
          sessionWithoutTimezone.startDateTime,
          sessionWithoutTimezone.endDateTime,
          selectedGroupService.timezone,
        );

        sessionsWithoutTimezone.push(sessionWithoutTimezone);

        if (
          customerToSchedule &&
          customerToSchedule.actionType === GroupBookingActionType.SCHEDULES &&
          !hasError &&
          !isSessionBeforeCurrentDate
        ) {
          selectedSessions.push(sessionWithoutTimezone);
        }
      });
      this.setState({
        sessions: sessionsWithoutTimezone,
        selectedSessions: customerToSchedule.sessions ? customerToSchedule.sessions : selectedSessions,
        isLoading: false,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
    }
  };

  render() {
    const { selectedGroupService, customerToSchedule } = this.props;
    const {
      isLoading,
      sessions,
      selectedSessions,
      updatedSessions,
      isNoSessionSelectedError,
      currentEditSession,
      currentMemberType,
      currentModalType,
      isModalOpen,
    } = this.state;

    const filterWarnings = customerToSchedule.actionType === GroupBookingActionType.SCHEDULES ? [] : null;
    if (filterWarnings && sessions) {
      _.forEach(sessions, (session) => {
        const isCapacityFull =
          session.capacity && session.bookedCapacity !== undefined && session.bookedCapacity >= session.capacity;
        const hasError =
          isCapacityFull ||
          session.alreadyInSession ||
          session.sessionStatus === SessionStatus.CANCELLED ||
          session.sessionStatus === SessionStatus.CLOSED;
        if (hasError) {
          filterWarnings.push(moment(session.startDateTime).format('MM-YYYY'));
        }
      });
    }

    return (
      <div className='anim-slide-left'>
        <Row className='ph-x4-large'>
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className='width-3/4'>
              <Title level={4}>Select which schedules you want to add this customer to</Title>
              <Paragraph>
                Based on your selections in the previous step the customer will be added to the following sessions.
              </Paragraph>
              <Paragraph>
                If the customer is <b>already part of a session</b> OR the session has <b>reached full capacity</b> then
                the customer will not be able to be added to the session.
              </Paragraph>
              {customerToSchedule && customerToSchedule.selectedCustomer ? (
                <div className={'p-medium bg-white rounded-big'}>
                  <SubTitle>Selected customer</SubTitle>
                  <div className={'flex-row align-center mt-small'}>
                    <Avatar
                      className={'mr-small'}
                      shape={'circle'}
                      icon={'user'}
                      src={customerToSchedule.selectedCustomer.attachmentUrl}
                    />{' '}
                    {customerToSchedule.selectedCustomer.firstName} {customerToSchedule.selectedCustomer.lastName}
                  </div>
                </div>
              ) : (
                <Text color={'secondary'}>No customer selected</Text>
              )}
            </div>
          </Col>
          <Col span={18} className='pl-large' style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className='bg-white p-large rounded-big' style={{ minWidth: '250px' }}>
              <Title level={4}>Sessions for {selectedGroupService.serviceName}</Title>
              <Paragraph>You may omit any sessions from being created by unchecking it below.</Paragraph>
              {isNoSessionSelectedError && <Text color={'red-dark'}>Please select at least one session.</Text>}
              {isLoading ? (
                <Skeleton active />
              ) : (
                <>
                  <BulkSelectActions onSelectAll={this.onSelectAll} onDeselectAll={this.onDeselectAll} />
                  <GroupServiceSessionsPanel
                    sessions={_.map(sessions, (session) => {
                      const selectedSession = _.find(
                        selectedSessions,
                        (selectedSession) => session.serviceDateTimeId === selectedSession.serviceDateTimeId,
                      );
                      const updatedSession = _.find(
                        updatedSessions,
                        (updatedSession) => session.serviceDateTimeId === updatedSession.serviceDateTimeId,
                      );
                      const displaySession = updatedSession ? updatedSession : session;
                      return {
                        ...displaySession,
                        isSelected: !!selectedSession,
                      };
                    })}
                    onSelect={this._onSelect}
                    timezone={selectedGroupService.timezone}
                    displayWarnings={true}
                    displayActivityGroup={true}
                    displayTeamMemberCustomerRatios={true}
                    isEditAddCustomerToSessions={true}
                    onEditAddCustomerToSessions={this._handleEditAddCustomerToSessions}
                    filterWarnings={filterWarnings}
                    isLoading={isLoading}
                    hideEmptyDates={true}
                    selectAllHandler={{ onCheckAll: this.onSelectFiltered }}
                  />
                </>
              )}
              {isModalOpen && currentModalType !== SessionAction.EDIT_BOOKING_TIME_RATIOS && (
                <ActivityGroupsModal
                  serviceId={this.props.selectedGroupService.serviceId}
                  activityGroup={null}
                  modalType={currentModalType}
                  member={null}
                  memberType={currentMemberType}
                  isOpen={isModalOpen}
                  session={currentEditSession}
                  selectedUser={customerToSchedule.selectedCustomer}
                  handleAction={this._handleModalAction}
                  onCloseViewModal={this._handleCloseModal}
                  timeZone={selectedGroupService.timezone}
                  ignoredAlert={true}
                />
              )}

              {isModalOpen && currentModalType === SessionAction.EDIT_BOOKING_TIME_RATIOS && (
                <EditSessionDetailModal
                  isModalOpen={isModalOpen}
                  selectedCustomer={customerToSchedule.selectedCustomer}
                  selectedSession={currentEditSession}
                  defaultRatioValue={selectedGroupService.teamMemberCustomerRatio.ndis}
                  timezone={selectedGroupService.timezone}
                  onCloseViewModal={this._handleCloseModal}
                  onSubmit={(data) => this._handleModalAction(data, SessionAction.EDIT_BOOKING_TIME_RATIOS)}
                />
              )}
            </div>
            <div className='pv-medium width-full bg-tertiary' style={{ position: 'sticky', bottom: 0 }}>
              <Row gutter={0} type='flex' align='middle' justify='space-between' className='bg-transparent'>
                <div className='text-align-right pv-medium'>
                  <SecondaryButton size='large' onClick={this._goToPrevious}>
                    Back
                  </SecondaryButton>
                </div>
                <div className='text-align-right pv-medium'>
                  <PrimaryButton size='large' loading={isLoading} onClick={this._goToNext}>
                    Next
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const BulkSelectActions: React.FunctionComponent<{ onSelectAll: () => void; onDeselectAll: () => void }> = ({
  onSelectAll,
  onDeselectAll,
}) => (
  <div>
    <HyperlinkButton className='mr-5' onClick={onSelectAll} fontSize='regular'>
      Select all
    </HyperlinkButton>
    <HyperlinkButton onClick={onDeselectAll} fontSize='regular'>
      Deselect all
    </HyperlinkButton>
  </div>
);

const mapDispatch = (dispatch: IRootDispatch) => ({
  setCustomerToSchedule: dispatch.servicesStore.setCustomerToSchedule,
  setGroupServiceActivityGroups: dispatch.groupServiceStore.setGroupServiceActivityGroups,
  doFetchGroupServiceSessions: dispatch.servicesStore.doFetchGroupServiceSessions,
  doFetchGroupServiceIndividualSessionServiceAgreements:
    dispatch.groupServiceStore.doFetchGroupServiceIndividualSessionServiceAgreements,
});

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  customerToSchedule: state.servicesStore.customerToSchedule,
  groupServiceSessions: state.servicesStore.groupServiceSessions,
});

export default connect(mapState, mapDispatch)(SessionReviewStepPanel);
