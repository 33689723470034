import React from 'react';
import { CheckCircle, CrossCircle, Loader2Adjustable, PauseCircle, Question } from '@good/icons';
import { CsvImportJob } from '@good/functions/routes/trpc/routers/imports';

type Props = { status: CsvImportJob['status'] };
export const JobStatusLabel = ({ status }: Props) =>
  jobStatusLabels[status] ??
  jobStatusLabels['Unknown'] ?? (
    <div className='flex align-center gap-1'>
      <Question />
      The status of the CSV import is in an unknown state
    </div>
  );

const jobStatusLabels: Record<CsvImportJob['status'], JSX.Element> = {
  Active: (
    <div className='flex align-center gap-1 text-notice'>
      <Loader2Adjustable className='animate-spin' />
      Your CSV is currently being processed.
    </div>
  ),
  Completed: (
    <div className='flex align-center gap-1 text-positive'>
      <CheckCircle />
      Your CSV was processed successfully.
    </div>
  ),
  Failed: (
    <div className='flex align-center gap-1 text-critical'>
      <CrossCircle />
      There was an issue processing your CSV.
    </div>
  ),
  Queued: (
    <div className='flex align-center gap-1'>
      <PauseCircle />
      Your CSV is in the queue to be processed.
    </div>
  ),
  Stalled: (
    <div className='flex align-center gap-1'>
      <PauseCircle />
      Your CSV is in the queue to be processed.
    </div>
  ),
};
