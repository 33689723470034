import React from 'react';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { Switch, SwitchProps } from '@good/ui/core';

import { useInlineForm } from './inline-form-context';
import { StaticValue } from './static-value';

export type InlineSwitchProps<T extends FieldValues> = {
  checkedValue?: string;
  label?: string;
  name: Path<T>;
  rules?: Omit<RegisterOptions<T, Path<T>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  uncheckedValue?: string;
} & Omit<SwitchProps, 'name' | 'label' | 'description' | 'error'>;

export const InlineSwitch = <T extends FieldValues>(props: InlineSwitchProps<T>) => {
  const { control } = useFormContext<T>();
  const { isEditing, isSubmitting } = useInlineForm();
  const { checkedValue, disabled, label, name, rules, uncheckedValue, ...switchProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
        const staticValue = value ? checkedValue : uncheckedValue;

        return isEditing ? (
          <div className='my-2'>
            <Switch
              onChange={onChange}
              checked={value}
              onBlur={onBlur}
              ref={ref}
              disabled={isSubmitting || disabled}
              label={label}
              error={error?.message}
              {...switchProps}
            />
          </div>
        ) : (
          <StaticValue value={staticValue ?? ''} hidden={staticValue === undefined} />
        );
      }}
    />
  );
};
