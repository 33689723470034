import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ListUnordered2 = forwardRef(function ListUnordered2(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.25h-.034c-.206 0-.427 0-.615.025-.219.03-.5.105-.735.341a1.239 1.239 0 0 0-.341.735c-.025.188-.025.41-.025.615V6.034c0 .206 0 .427.025.615.03.219.105.5.341.735.236.236.516.311.735.341.188.025.41.025.615.025H6.034c.206 0 .427 0 .615-.025.219-.03.5-.105.735-.341.236-.236.312-.516.341-.735.025-.188.025-.41.025-.615V3.966c0-.206 0-.427-.025-.615-.03-.219-.105-.5-.341-.735a1.239 1.239 0 0 0-.735-.341 4.854 4.854 0 0 0-.615-.025H4ZM3.75 4l.001-.249L4 3.75h2l.249.001L6.25 4v2l-.001.249L6 6.25H4l-.249-.001L3.75 6V4Zm6.5 1a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 0 1.5H11a.75.75 0 0 1-.75-.75Zm.75 6.25a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5H11Zm0 7a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5H11Zm-7.034-9H6.034c.206 0 .427 0 .615.025.219.03.5.105.735.341.236.236.312.516.341.735.025.188.025.41.025.614v2.069c0 .205 0 .427-.025.614-.03.22-.105.5-.341.736a1.239 1.239 0 0 1-.735.341 4.863 4.863 0 0 1-.615.025H3.966c-.206 0-.427 0-.615-.025-.219-.03-.5-.105-.735-.341a1.239 1.239 0 0 1-.341-.736c-.025-.187-.025-.409-.025-.614V10.966c0-.206 0-.427.025-.615.03-.219.105-.5.341-.735.236-.236.516-.312.735-.341.188-.025.41-.025.615-.025Zm-.215 1.501L3.75 11v2l.001.249.249.001h2l.249-.001L6.25 13v-2l-.001-.249L6 10.75H4l-.249.001ZM4 16.25h-.034c-.206 0-.427 0-.615.025-.219.03-.5.105-.735.341a1.239 1.239 0 0 0-.341.735c-.025.188-.025.41-.025.614v2.069c0 .206 0 .427.025.614.03.22.105.5.341.736.236.236.516.311.735.341.188.025.41.025.615.025H6.034c.206 0 .427 0 .615-.025.219-.03.5-.105.735-.341.236-.236.312-.516.341-.736.025-.187.025-.409.025-.614v-2.069c0-.205 0-.426-.025-.614-.03-.219-.105-.5-.341-.735a1.239 1.239 0 0 0-.735-.341 4.845 4.845 0 0 0-.615-.025H4ZM3.75 18l.001-.249L4 17.75h2l.249.001.001.249v2l-.001.249L6 20.25H4l-.249-.001A16.28 16.28 0 0 1 3.75 20v-2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
