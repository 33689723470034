import { Modal } from 'antd';
import React from 'react';
import { PrimaryButton, SecondaryButton } from '../buttons';
import { Text, Title } from '../typography';
import { ActionModalFooter } from './ActionModal';
interface IPropsConfirmDialog {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title?: string;
  message?: string;
  submitTextButton?: string;
  cancelTextButton?: string;
  [restProps: string]: any;
}
const ConfirmDialog = (props: IPropsConfirmDialog) => {
  const {
    isOpen,
    title = 'Warning',
    message = 'Are you sure you want to delete?',
    onSubmit,
    onClose,
    submitTextButton = 'Remove',
    cancelTextButton = 'Cancel',
    ...restProps
  } = props;

  return (
    <Modal
      visible={isOpen}
      title={
        <Title level={4} className="mv-none">
          {title}
        </Title>
      }
      width="640px"
      onCancel={onClose}
      footer={null}
      {...restProps}
    >
      <Text>{message}</Text>
      <ActionModalFooter>
        <SecondaryButton size="large" className="mr-medium" onClick={onClose}>
          {cancelTextButton}
        </SecondaryButton>
        <PrimaryButton size="large" className="mr-medium" onClick={onSubmit}>
          {submitTextButton}
        </PrimaryButton>
      </ActionModalFooter>
    </Modal>
  );
};

export { ConfirmDialog };
