import React from 'react';

export const TmMgmtBlueDot = () => (
  <div
    style={{
      height: '6px',
      width: '6px',
      backgroundColor: '#0083ff',
      borderRadius: '50%',
      display: 'inline-block',
    }}
  />
);
