import React from 'react';
import { useTranslation } from 'react-i18next';
import { rem } from '@good/ui/core';
import { PageHeader } from '@good/ui/templates';

export const ShiftActivity = () => {
  const { t } = useTranslation('', { keyPrefix: 'silService.activityTabs.shiftActivity' });

  return (
    <>
      <PageHeader title={t('title')} subtitle={t('description')} size='h2' mb={rem(24)} />
    </>
  );
};
