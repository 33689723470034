import React, { forwardRef } from 'react';

import { Icon } from '../icon';

import type { IconProps } from '../icon';

export const IconPlus = forwardRef<SVGSVGElement, IconProps>(function IconPlus(props, ref) {
  return (
    <Icon {...props} viewBox="0 0 16 16" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.5C8.27614 3.5 8.5 3.72386 8.5 4V7.5H12C12.2761 7.5 12.5 7.72386 12.5 8C12.5 8.27614 12.2761 8.5 12 8.5H8.5V12C8.5 12.2761 8.27614 12.5 8 12.5C7.72386 12.5 7.5 12.2761 7.5 12V8.5H4C3.72386 8.5 3.5 8.27614 3.5 8C3.5 7.72386 3.72386 7.5 4 7.5H7.5V4C7.5 3.72386 7.72386 3.5 8 3.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
