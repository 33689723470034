import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ChartLine = forwardRef(function ChartLine(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.25h-.055c-1.368 0-2.47 0-3.337.117-.9.12-1.658.38-2.26.981-.602.602-.86 1.36-.982 2.26-.116.867-.116 1.97-.116 3.337V15.055c0 1.367 0 2.47.116 3.337.121.9.38 1.658.982 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116H15.055c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.601-.602.86-1.36.981-2.26.117-.867.117-1.97.117-3.337V8.945c0-1.367 0-2.47-.117-3.337-.12-.9-.38-1.658-.982-2.26-.601-.602-1.36-.86-2.26-.981-.866-.117-1.969-.117-3.336-.117H9ZM4.409 4.409c.277-.277.665-.457 1.399-.556C6.563 3.752 7.564 3.75 9 3.75h6c1.435 0 2.436.002 3.192.103.733.099 1.122.28 1.399.556.277.277.457.665.556 1.4.101.754.103 1.756.103 3.191v6c0 1.435-.002 2.436-.103 3.192-.099.734-.28 1.122-.556 1.399-.277.277-.666.457-1.4.556-.755.101-1.756.103-3.191.103H9c-1.436 0-2.437-.002-3.192-.103-.734-.099-1.122-.28-1.4-.556-.276-.277-.456-.665-.555-1.4-.102-.755-.103-1.756-.103-3.191V9c0-1.435.001-2.437.103-3.192.099-.734.28-1.122.556-1.399ZM17.53 9.53a.75.75 0 1 0-1.06-1.06L13 11.94l-1.47-1.47a.75.75 0 0 0-1.06 0l-4 4a.75.75 0 1 0 1.06 1.06L11 12.06l1.47 1.47a.75.75 0 0 0 1.06 0l4-4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
