import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CropRotate = forwardRef(function CropRotate(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.348 1.63A.75.75 0 0 1 12 1.25c5.937 0 10.75 4.813 10.75 10.75a.75.75 0 0 1-1.5 0c0-4.637-3.412-8.478-7.863-9.147l.756 1.261a.75.75 0 1 1-1.286.772l-1.5-2.5a.75.75 0 0 1-.01-.756ZM2 11.25a.75.75 0 0 1 .75.75c0 4.637 3.413 8.478 7.864 9.147l-.757-1.261a.75.75 0 0 1 1.286-.772l1.5 2.5A.75.75 0 0 1 12 22.75C6.063 22.75 1.25 17.937 1.25 12a.75.75 0 0 1 .75-.75Zm6-6a.75.75 0 0 1 .75.75v6c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.789.3.483.064 1.13.066 2.094.066h6a.75.75 0 0 1 0 1.5h-1.25V18a.75.75 0 0 1-1.5 0v-1.25h-3.302c-.898 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.726-.456-.455-.642-1.022-.726-1.65-.08-.594-.08-1.344-.08-2.242V8.75H6a.75.75 0 0 1 0-1.5h1.25V6A.75.75 0 0 1 8 5.25Zm4 3.5c.964 0 1.612.002 2.095.067.461.062.659.169.789.3.13.13.237.327.3.788.065.483.066 1.131.066 2.095v1.5a.75.75 0 0 0 1.5 0v-1.552c0-.899 0-1.648-.08-2.242-.084-.628-.27-1.195-.725-1.65-.456-.456-1.023-.642-1.65-.726-.595-.08-1.344-.08-2.243-.08H10.5a.75.75 0 0 0 0 1.5H12Z"
        fill="currentColor"
      />
    </Icon>
  );
});
