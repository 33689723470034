import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const TextBold = forwardRef(function TextBold(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.111 3.25A1.861 1.861 0 0 0 5.25 5.111V18.667c0 1.15.933 2.083 2.083 2.083H14a4.75 4.75 0 0 0 1.29-9.323A4.75 4.75 0 0 0 12 3.25H7.111ZM12 12.75H6.75v5.917c0 .322.261.583.583.583H14a3.25 3.25 0 0 0 0-6.5h-2Zm0-1.5a3.25 3.25 0 0 0 0-6.5H7.111c-.2 0-.361.162-.361.361v6.139H12Z"
        fill="currentColor"
      />
    </Icon>
  );
});
