import { Popover } from '@blueprintjs/core';
import { Button, Col, Divider, Form, Icon, InputNumber, Row, Select, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Information, Warning } from 'common-components/alerts';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import PaymentSourceTagV1 from 'common-components/tags/PaymentSourceTagV1';
import { SubTitle, Text } from 'common-components/typography';
import { IBillingLineItem } from 'interfaces/booking-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import {
  BillingAutoGeneratedType,
  BillingPaymentStatus,
  BookingStatus,
  ClaimType,
  DisplayDaysOfWeek,
  MmmGroup,
  PaymentSources,
  PaymentStatus,
  TooltipType,
} from 'utilities/enum-utils';
import FundingUtils from 'utilities/funding-utils';
import Utils from 'utilities/Utils';
import { ndisHelper } from 'variables/data-helpers';
import BookingBillingLineItemDeleteActionModel from 'views/bookings/components/BookingBillingLineItemDeleteActionModel';
import BookingBillingLineItemWaiveActionModel from 'views/bookings/components/BookingBillingLineItemWaiveActionModel';
import AddVCPLineItemModal from 'views/bookings/details/sections/content-section/tabs-panel/AddVCPLineItemModal';
import AddNDISLineItemModal from '../../bookings/details/sections/content-section/tabs-panel/AddNDISLineItemModal';
import { CLAIM_TYPE_DIRECT_OPTIONS } from '../utils/constants';
import BillingLineItemOptionMenu from './BillingLineItemOptionMenu';
import BillingLineItemStatus from './BillingLineItemStatus';

const { Option } = Select;

interface IBillingLineItemProps extends FormComponentProps {
  index: number;
  isRemoving: boolean;
  billingLineItem: IBillingLineItem;
  claimTravelData?: any;
  bookingStatus?: string;
  billingSupportItems: any[];
  serviceBillingLineItems: any[];
  fundedCategories: any[];
  travelDistance: number;
  isCancelled: boolean;
  cancellationCode: string;
  cancellationReason: string;
  serviceAgreementItems?: any[];
  showLineItemLabel?: boolean;
  showDeleteIcon?: boolean;
  showRemoveReasonCheckbox?: boolean;
  showRemoveReason?: boolean;
  showPaymentMethod?: boolean;
  showActionButtons?: boolean;
  isEditPaymentLineItem?: boolean;
  onChangeLineItem?: (index: number, billingLineItem: any) => void;
  onWaiveLineItem?: (index: number) => void;
  onUnwaiveLineItem?: (index: number) => void;
  onDeleteLineItem?: (index: number) => void;
  onEditLineItem?: (index: number) => void;
  onSaveLineItem?: (index: number, isEditBillable: boolean) => void;
  onCancelLineItem?: (index: number) => void;
  checkForDuplicate?: (lineItem: any) => boolean;
  timezoneData?: any;
  hasEditBillingLineItemPermission?: boolean;
  hasABTLineItems: boolean;
  hasPTNLCLineItems: boolean;
  isFilterOutTravelItem?: boolean;
  isLineItemLocked?: boolean;
  mmmGroup?: MmmGroup;
  locationState?: string;
  preferredPaymentSourceType?: string;
  paymentSourceAvailableTypes?: Array<any>;
  isSaveButtonOutside?: boolean;
  serviceType?: string;
  updateBillingItem?: (item, index?) => void;
  hideWarningFunding?: boolean;
  isBulkAddLineItem?: boolean;
  doGetVCPItems: typeof dispatch.servicesStore.doGetVCPItems;
  hideManagementMethod?: boolean;
  hideSelectionOptionLineItemWarning?: boolean;
  hideLineItemCost?: boolean;
  isAutoChargeLineItems?: boolean;
  hideBottomLineItemName?: boolean;
  hideInputLabels?: boolean;
  disableUnitSelection?: boolean;
  isFilterOutPTNLCLineItem?: boolean;
  isUnitPriceDisabled?: boolean;
}

interface IBillingLineItemState {
  selectedSupportCategorySupportItems: { value: string; label: string }[];
  supportCategory: string;
  supportItem: string;
  serviceId: string;
  supportItemOptions: any[];
  showMileageBilling: boolean;
  showMileage: boolean;
  isRejectSelected: boolean;
  showQuantity: boolean;
  action: string;
  isOpenModal: boolean;
  isOpenAddLineItem: boolean;
  localBillingItem: any;
  isOpenVCPItem: boolean;
}

const billingAutoGeneratedLabel = [
  'scheduled time of booking:',
  'team member start/finish time:',
  'team member travel before booking',
  'team member travel during booking',
  'team member start/finish time:',
  'customers service agreement (Non face-to-face charges)',
  'customers service agreement (Auto-charged line item)',
];

const unitOptions = [
  { value: 'EA', label: 'Each' },
  { value: 'H', label: 'Hourly' },
  { value: 'WK', label: 'Weekly' },
];

const paymentMethodOptions = [
  { value: 'NDIA', label: 'NDIA Managed' },
  { value: 'SELF', label: 'Self Managed' },
  { value: 'PLAN', label: 'Plan Managed' },
];

let supportItemAddedNumbers = [];

const MAX_DISCOUNT = 1;

class BillingLineItemTooltip extends Component<{ type: TooltipType; value?: string | number }> {
  render() {
    const { type } = this.props;
    let content = <Text color='white'>This field cannot be edited</Text>;

    switch (type) {
      case TooltipType.UNIT_PRICE:
        content = <Text color='white'>The line item price is locked to {this.props.value} by NDIS and cannot be edited</Text>;
        break;
      case TooltipType.QTY:
        content = (
          <Text color='white'>
            Quantity is calculated based on{' '}
            <Text weight='bold' color='white'>
              Kilometers travelled
            </Text>{' '}
            and{' '}
            <Text weight='bold' color='white'>
              additional costs
            </Text>{' '}
            and cannot be directly edited
          </Text>
        );
        break;
      case TooltipType.TRAVEL_DISTANCE:
        content = (
          <Text color='white'>
            Kilometers travelled can be edited in the &apos;Travel claims&apos; section of billing above
          </Text>
        );
        break;
      case TooltipType.ADDITIONAL_COST:
        content = (
          <Text color='white'>
            Additional costs can be edited in the &apos;Travel claims&apos; section of billing above
          </Text>
        );
        break;
    }

    return (
      <Tooltip title={content} placement='top' className='cursor-pointer'>
        <Icon type='question-circle' className='text-size-large text-color-secondary ml-x-small' />
      </Tooltip>
    );
  }
}

class BillingLineItemV2 extends Component<IBillingLineItemProps, IBillingLineItemState> {
  state = {
    selectedSupportCategorySupportItems: [],
    supportCategory: '',
    supportItem: '',
    serviceId: '',
    supportItemOptions: [],
    showMileageBilling: false,
    showMileage: false,
    isRejectSelected: false,
    showQuantity: true,
    action: '',
    isOpenModal: false,
    isOpenAddLineItem: false,
    isEditPaymentLineItem: false,
    additionalCost: 0,
    localBillingItem: null,
    isOpenVCPItem: false,
  };

  private _showMileage = (lineItem) => {
    return (
      lineItem.ruleType && (lineItem.ruleType === 'ABT' || lineItem.ruleType === 'PTNLC') && lineItem.isAutoGenerated
    );
  };

  private _onChangeSupportItemNumber = async (value) => {
    const {
      mmmGroup,
      locationState,
      doGetVCPItems,
      isSaveButtonOutside,
      hideSelectionOptionLineItemWarning = false,
    } = this.props;
    const { localBillingItem, supportItemOptions } = this.state;

    if (localBillingItem.paymentSourceType === PaymentSources.NDIS) {
      // find supportItem
      const findItem = this.props.billingSupportItems.find((supportItem) => supportItem.supportItemNumber === value);

      // If the Support Item is not in the Service Line Item list, use the ndisHelper ( Be careful the name of the fields are different between them two)
      const supportItem = findItem ? findItem : ndisHelper.getBySupportItemNumber(value);

      localBillingItem.additionalCost = localBillingItem.additionalCost ? localBillingItem.additionalCost : 0;
      if (findItem) {
        localBillingItem.supportItemNumber = value;
        localBillingItem.isAutoGenerated = false;
        localBillingItem.supportCategoryNumber = supportItem.supportCategoryNumber;
        localBillingItem.supportItemNumberArray = value;
        localBillingItem.supportItem = supportItem.supportItem;
        localBillingItem.unitPrice =
          mmmGroup === MmmGroup.NonRemote
            ? CommonUtils.findStandardPrice(supportItem, locationState, 'API')
            : mmmGroup === MmmGroup.Remote
            ? supportItem.remotePrice
            : mmmGroup === MmmGroup.VeryRemote
            ? supportItem.veryRemotePrice
            : 0;
        localBillingItem.unit = supportItem.unit === 'H' ? 'H' : supportItem.unit === 'WK' ? 'WK' : 'EA';
        localBillingItem.paymentMethod = supportItem.paymentMethod;
        localBillingItem.claimType = localBillingItem.claimType ? localBillingItem.claimType : 'STD';
        localBillingItem.isFunded = findItem.isFunded;
        localBillingItem.ruleType = supportItem.ruleType;
      } else {
        const fundedCategory = FundingUtils.getFundingPackageCategoryFromLineItem(
          supportItem.SupportPurposeType,
          supportItem.SupportCategoryNumber,
          supportItem.SupportItemNumber,
          this.props.fundedCategories,
        );

        localBillingItem.isAutoGenerated = false;
        localBillingItem.supportItemNumber = value;
        localBillingItem.supportCategoryNumber = supportItem.SupportCategoryNumber;
        localBillingItem.supportItem = supportItem.SupportItem;
        localBillingItem.ruleType = supportItem.DateType;
        localBillingItem.supportItemNumberArray = value;
        localBillingItem.unitPrice =
          mmmGroup === MmmGroup.NonRemote
            ? CommonUtils.findStandardPrice(supportItem, locationState, 'JSON')
            : mmmGroup === MmmGroup.Remote
            ? supportItem.NationalRemote
            : mmmGroup === MmmGroup.VeryRemote
            ? supportItem.NationalVeryRemote
            : 0;
        localBillingItem.unit =
          supportItem.UnitOfMeasure === 'H' ? 'H' : supportItem.UnitOfMeasure === 'WK' ? 'WK' : 'EA';
        localBillingItem.paymentMethod = fundedCategory ? fundedCategory.paymentMethod : '';
        localBillingItem.claimType = 'STD';
      }
      const ruleType = supportItem.ruleType ? supportItem.ruleType : supportItem.DateType;
      localBillingItem.isNotInServiceAgreement = this._isNotInServiceAgreement(localBillingItem);

      // Depending if the lineItem comes from the Service list or ndisHelper, the structure is different
      if (ruleType === 'ABT' || ruleType === 'PTNLC') {
        // localBillingItem.qty = 1;
        // localBillingItem.billingQty = 1;
        localBillingItem.claimType = 'TRAN';
        localBillingItem.mileagePrice =
          ruleType === 'ABT'
            ? this.props.claimTravelData?.transportPriceDuringBooking
            : this.props.claimTravelData?.transportPriceBeforeBooking;
        localBillingItem.unitPrice = 1;
        localBillingItem.billingPrice = 1;
        localBillingItem.unit = 'EA';
        localBillingItem.additionalCost = 0;
        this.setState({ showMileage: true, showMileageBilling: true, showQuantity: false });

        this._calculateMileagePrice(localBillingItem);
        this.setState({
          localBillingItem: { ...localBillingItem },
        });
      } else {
        await this._calculateTotalPrice(localBillingItem);
        this.setState({
          localBillingItem: { ...localBillingItem },
          showMileage: false,
          showMileageBilling: false,
          showQuantity: true,
        });
      }
    } else if (localBillingItem.paymentSourceType === PaymentSources.VCP) {
      const findItem = _.find(this.props.billingSupportItems, (supportItem) => supportItem.supportItemNumber === value);
      const newItem = findItem
        ? findItem
        : _.isString(value)
        ? await doGetVCPItems({ searchString: value, isReturnOnly: true })
        : value;
      localBillingItem.supportItemNumber = newItem.supportItemNumber;
      localBillingItem.supportItem = newItem.supportItem;
      localBillingItem.isAutoGenerated = false;
      localBillingItem.unit = newItem.unit === 'H' ? 'H' : 'EA';
      localBillingItem.supportItemNumberArray = value;
      localBillingItem.unitPrice = newItem.unitPrice ? Number(newItem.unitPrice) : Number(newItem.price.nonRemotePrice);
      localBillingItem.supportItemName = newItem.supportItemName;
    }

    const lineItemSupportNumber = _.isString(value) ? value : value.supportItemNumber;
    // If the Line Items hasn't already been added to the select
    if (!supportItemAddedNumbers.find((number) => number === lineItemSupportNumber)) {
      const newSupportItemOptions = supportItemOptions;
      newSupportItemOptions.push(
        <Option
          key={supportItemOptions.length}
          value={lineItemSupportNumber}
          title={
            lineItemSupportNumber + ' - ' + localBillingItem.supportItemName
              ? localBillingItem.supportItemName
              : localBillingItem.supportItem
          }
        >
          <>
            <Text weight='bold'>
              {lineItemSupportNumber}
              {localBillingItem.paymentSourceType === PaymentSources.NDIS && !hideSelectionOptionLineItemWarning && (
                <>
                  {' '}
                  ({localBillingItem.customerSupportLevel === '1' ? 'Standard' : 'High Needs'})
                  {!localBillingItem.isFunded && localBillingItem.isNotInServiceAgreement && (
                    <Text color='orange' className='ml-small'>
                      (Not funded/in service agreement)
                    </Text>
                  )}
                  {!localBillingItem.isFunded && !localBillingItem.isNotInServiceAgreement && (
                    <Text color='orange' className='ml-small'>
                      (Not funded)
                    </Text>
                  )}
                  {localBillingItem.isFunded && localBillingItem.isNotInServiceAgreement && (
                    <Text color='orange' className='ml-small'>
                      (Not in service agreement)
                    </Text>
                  )}
                </>
              )}
            </Text>
            <br />
            <Text ellipsis={true} style={{ width: '100%' }}>
              {localBillingItem.paymentSourceType === PaymentSources.VCP
                ? localBillingItem.supportItemName
                  ? localBillingItem.supportItemName
                  : localBillingItem.supportItem
                : localBillingItem.supportItem}
            </Text>
          </>
        </Option>,
      );
      supportItemAddedNumbers.push(lineItemSupportNumber);
      this.props.form.setFieldsValue({
        qty: localBillingItem.qty ? localBillingItem.qty : 1,
        billingQty: localBillingItem.billingQty ? localBillingItem.billingQty : 1,
      });
      this.setState({ supportItemOptions: newSupportItemOptions });
    }

    this._calculateTotalPrice(localBillingItem);
    this.setState({
      localBillingItem: { ...localBillingItem },
      showMileage: false,
      showMileageBilling: false,
      showQuantity: true,
    });

    if (isSaveButtonOutside) {
      this.props.updateBillingItem(localBillingItem, this.props.index);
    }

    this.setState({ isOpenAddLineItem: false });
  };

  private _onChangeUnit = (value) => {
    const { localBillingItem } = this.state;
    localBillingItem.unit = value;
    this.setState({ localBillingItem });

    if (this.props.isSaveButtonOutside) {
      this.props.updateBillingItem(localBillingItem, this.props.index);
    }
  };

  private _onChangePaymentMethod = (value) => {
    const { localBillingItem } = this.state;
    localBillingItem.paymentMethod = value;
    this.setState({ localBillingItem });
    if (this.props.isSaveButtonOutside) {
      this.props.updateBillingItem(localBillingItem, this.props.index);
    }
  };

  private _onChangeClaimType = (value) => {
    const { localBillingItem } = this.state;
    localBillingItem.claimType = value;

    this.setState({
      localBillingItem: { ...localBillingItem },
    });
    if (this.props.isSaveButtonOutside) {
      this.props.updateBillingItem(localBillingItem, this.props.index);
    }
  };

  private _renderFormField = (editValue, displayValue, canEdit = true) => {
    return this.props.billingLineItem.isEditing && canEdit ? editValue : <Text>{displayValue}</Text>;
  };

  private _onChangeTravelDistance = () => {
    // const { localBillingItem } = this.state;
    const newLocalBillingItem = _.cloneDeep(this.state.localBillingItem);
    const billingLineItem = this._getBillingLineItemValue();
    this._calculateMileagePrice(billingLineItem);

    newLocalBillingItem.unitPrice = billingLineItem.unitPrice;
    newLocalBillingItem.total = billingLineItem.total;
    newLocalBillingItem.qty = billingLineItem.qty;
    newLocalBillingItem.billingPrice = billingLineItem.billingPrice;
    newLocalBillingItem.billingTotal = billingLineItem.billingTotal;
    newLocalBillingItem.billingQty = billingLineItem.billingQty;
    newLocalBillingItem.travelDistance = Number(billingLineItem.travelDistance);
    newLocalBillingItem.mileagePrice = billingLineItem.mileagePrice;
    newLocalBillingItem.additionalCost = billingLineItem.additionalCost;

    this.setState({ localBillingItem: newLocalBillingItem });
    if (this.props.isSaveButtonOutside) {
      this.props.updateBillingItem(newLocalBillingItem, this.props.index);
    }
  };

  // TODO: Revisit for cancellation calculation
  private _onChangeCalculateTotalPrice = () => {
    // const { localBillingItem } = this.state;
    const newLocalBillingItem = _.cloneDeep(this.state.localBillingItem);
    const billingLineItem = this._getBillingLineItemValue();
    const isNewVCPLineItem = newLocalBillingItem.gst && newLocalBillingItem.paymentSourceType === PaymentSources.VCP;

    this._calculateTotalPrice(billingLineItem);

    newLocalBillingItem.unitPrice = billingLineItem.unitPrice;
    newLocalBillingItem.total = billingLineItem.total;
    newLocalBillingItem.qty = billingLineItem.qty;
    newLocalBillingItem.billingPrice = billingLineItem.billingPrice;
    newLocalBillingItem.billingTotal = billingLineItem.billingTotal;
    newLocalBillingItem.billingQty = billingLineItem.billingQty;
    if (isNewVCPLineItem) {
      newLocalBillingItem.gstValue = billingLineItem.gstValue;
      newLocalBillingItem.billingGstValue = billingLineItem.billingGstValue;
    }

    this.setState({ localBillingItem: newLocalBillingItem });
    if (this.props.isSaveButtonOutside) {
      this.props.updateBillingItem(newLocalBillingItem, this.props.index);
    }
  };

  private _calculateMileagePrice = (billingLineItem) => {
    const mileagePrice = Number(billingLineItem.mileagePrice);
    let mileageTotal = 0;

    billingLineItem.additionalCost = billingLineItem.additionalCost ? billingLineItem.additionalCost : 0;

    if (mileagePrice > 0 && Number(billingLineItem.travelDistance) > 0) {
      mileageTotal = Number(
        (mileagePrice * Number(billingLineItem.travelDistance) + Number(billingLineItem.additionalCost)).toFixed(2),
      );
      billingLineItem.unitPrice = 1;
      billingLineItem.billingPrice = billingLineItem.unitPrice;
      billingLineItem.total = mileageTotal;
      billingLineItem.billingTotal = billingLineItem.total;
      billingLineItem.qty = mileageTotal;
      billingLineItem.billingQty = billingLineItem.qty;
    } else {
      billingLineItem.billingPrice = 0;
      billingLineItem.billingQty = billingLineItem.qty = 0;
    }

    // calculate total
    let lineTotal = 0;

    if (billingLineItem.unitPrice > 0 && billingLineItem.billingQty > 0) {
      lineTotal = billingLineItem.unitPrice * billingLineItem.billingQty;
    }
    billingLineItem.billingTotal = billingLineItem.total = Number(lineTotal.toFixed(2));

    this.props.form.setFieldsValue({ unitPrice: billingLineItem.unitPrice });
  };

  private _calculateTotalPrice = (billingLineItem) => {
    // set billingQty and billingPrice same as qty, unitPrice
    billingLineItem.billingQty = billingLineItem.qty;
    billingLineItem.billingPrice = billingLineItem.unitPrice;
    if (billingLineItem.gstValue) {
      billingLineItem.billingGstValue = billingLineItem.gstValue;
    }

    const newLocalBillingItem = _.cloneDeep(this.state.localBillingItem);
    const isNewVCPLineItem = newLocalBillingItem.gst && newLocalBillingItem.paymentSourceType === PaymentSources.VCP;

    if (billingLineItem.unitPrice && billingLineItem.qty) {
      // cancellation policy calculate
      if (this.props.isCancelled) {
        let billingPrice = Number(billingLineItem.unitPrice);
        billingLineItem.billingTotal = Number(billingPrice) * billingLineItem.qty;
        billingLineItem.total = Number(billingLineItem.unitPrice) * billingLineItem.qty;
      } else if (isNewVCPLineItem) {
        const billingPrice = Number(billingLineItem.unitPrice);
        const gstValue = CommonUtils.calcGstValue(billingPrice, newLocalBillingItem.gst);
        billingLineItem.billingGstValue = billingLineItem.gstValue = gstValue;
        billingLineItem.billingTotal = billingLineItem.total = CommonUtils.calcTotalPriceIncludeGst(
          billingPrice,
          gstValue,
          billingLineItem.qty,
        );
      } else {
        billingLineItem.billingTotal = billingLineItem.total = Number(billingLineItem.unitPrice) * billingLineItem.qty;
      }
    } else {
      billingLineItem.billingTotal = billingLineItem.total = 0;
    }
  };

  private _getBillingLineItemValue = () => {
    return this.props.form.getFieldsValue();
  };

  private _onSaveLineItem = async () => {
    const { onSaveLineItem, onChangeLineItem, index, form } = this.props;
    const { localBillingItem } = this.state;

    if (!this.props.checkForDuplicate(localBillingItem)) {
      form.validateFieldsAndScroll(async (error) => {
        if (!error) {
          await onChangeLineItem(index, localBillingItem);
          await onSaveLineItem(index, false);
        }
      });
    }
  };

  public onValidate = async () => {
    const { form } = this.props;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  private _onCancelLineItem = async () => {
    const { onCancelLineItem, index } = this.props;
    onCancelLineItem(index);
  };

  private _renderActionButtons = (isBillable?) => {
    const {
      index,
      showActionButtons,
      onEditLineItem,
      billingLineItem,
      bookingStatus,
      hasEditBillingLineItemPermission = true,
      isSaveButtonOutside = false,
    } = this.props;

    if (billingLineItem.isEditing) {
      if (!isSaveButtonOutside) {
        return (
          <Button.Group className='mt-x-small'>
            <PrimaryButton onClick={this._onSaveLineItem}>Save</PrimaryButton>
            <SecondaryButton onClick={this._onCancelLineItem}>Cancel</SecondaryButton>
          </Button.Group>
        );
      }
    } else {
      if (
        showActionButtons &&
        ![
          BookingStatus.CUSTOMER_CANCELLED,
          BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE,
          BookingStatus.BUSINESS_CANCELLED,
        ].includes(bookingStatus) &&
        [
          BillingPaymentStatus.INITIAL,
          BillingPaymentStatus.REQUIRES_APPROVAL,
          BillingPaymentStatus.READY_FOR_BILLING,
        ].includes(billingLineItem.paymentStatus) &&
        hasEditBillingLineItemPermission
      ) {
        return (
          <>
            <SecondaryButton
              className='mh-x-small'
              icon='edit'
              onClick={() => {
                onEditLineItem(index);
              }}
            >
              Edit
            </SecondaryButton>
            <Popover
              content={
                <BillingLineItemOptionMenu
                  isBillable={isBillable}
                  onClickDelete={() => {
                    this._openActionModal({ action: 'DELETE' });
                  }}
                  onClickChangeBillable={() => {
                    this._onClickChangeBillable();
                  }}
                />
              }
              position='bottom-right'
            >
              <SecondaryButton icon='ellipsis' onClick={() => false} />
            </Popover>
          </>
        );
      }
    }
  };

  private _onClickChangeBillable = () => {
    const { onSaveLineItem, index } = this.props;

    onSaveLineItem(index, true);
  };

  private _openActionModal = ({ action }) => {
    this.setState({ action, isOpenModal: true });
  };

  private _closeActionModal = () => this.setState({ isOpenModal: false });

  private _getActionModal = () => {
    // Depending on the action target type, use the correct modal.
    if (this.state.action === 'WAIVE') {
      return BookingBillingLineItemWaiveActionModel;
    }
    if (this.state.action === 'DELETE') {
      return BookingBillingLineItemDeleteActionModel;
    } else {
      return () => <></>;
    }
  };

  private _getActionModalOnAction = () => {
    const { onWaiveLineItem, onUnwaiveLineItem, onDeleteLineItem } = this.props;
    switch (this.state.action) {
      case 'WAIVE':
        return onWaiveLineItem;
      case 'DELETE':
        return onDeleteLineItem;
      case 'UNWAIVE':
        return onUnwaiveLineItem;
    }
  };

  private _closeLineItemModal = () => {
    this.setState({ isOpenAddLineItem: false });
  };

  private _openLineItemModal = () => {
    this.setState({ isOpenAddLineItem: true });
  };

  private _closeVCPModal = () => {
    this.setState({ isOpenVCPItem: false });
  };

  private _openVCPModal = () => {
    this.setState({ isOpenVCPItem: true });
  };

  public getBillingLineItem = () => {
    return this.state.localBillingItem;
  };

  private _validateUnitPrice = (rule, value, callback) => {
    try {
      if (Utils.isEmpty(value) || value === '0' || value === 0) {
        throw Error('Please input unit price');
      }
      if (value < 0) {
        throw Error('unit price should not less than 0');
      }
      if (value > 100000) {
        throw Error('unit price should not greater than 100000');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _validateQty = (rule, value, callback) => {
    try {
      if (Utils.isEmpty(value) || value === '0' || value === 0) {
        throw Error('Please input quantity');
      }
      if (value < 0) {
        throw Error('quantity should not less than 0');
      }
      if (value > 1000) {
        throw Error('quantity should not greater than 1000');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _validateTravelDistance = (rule, value, callback) => {
    try {
      if (Utils.isEmpty(value) || value === '0' || value === 0) {
        throw Error('Please input kilometers travelled');
      }
      if (value < 0) {
        throw Error('kilometers travelled should not less than 0');
      }
      if (value > 10000) {
        throw Error('kilometers travelled should not greater than 10000');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _validateTransportPrice = (rule, value, callback) => {
    try {
      if (Utils.isEmpty(value) || value === '0' || value === 0) {
        throw Error('Please input cost per kilometres');
      }
      if (value < 0) {
        throw Error('cost per kilometres should not less than 0');
      }
      if (value > 10000) {
        throw Error('cost per kilometres should not greater than 10000');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _validateAdditionalCost = (rule, value, callback) => {
    try {
      if (value < 0) {
        throw Error('additional cost should not less than 0');
      }
      if (value > 10000) {
        throw Error('additional cost should not greater than 10000');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _getInformationText = (lineItem) => {
    const { displayTimezone } = this.props.timezoneData;
    let informationText = (
      <Text>Automatically generated based on {billingAutoGeneratedLabel[lineItem.generatedBy]}</Text>
    );
    let additionalText = null;

    switch (lineItem.generatedBy) {
      case BillingAutoGeneratedType.ScheduledTime:
      case BillingAutoGeneratedType.WorkerCheckinCheckOutTime:
      case BillingAutoGeneratedType.PortalCheckinCheckOutTime:
        additionalText = (
          <b>
            {moment
              .tz(lineItem.startDateTime, displayTimezone)
              .startOf('day')
              .isSame(moment.tz(lineItem.endDateTime, displayTimezone).startOf('day')) ? (
              <>
                {moment.tz(lineItem.startDateTime, displayTimezone).format('D/MM/YYYY, h:mma')} -{' '}
                {moment.tz(lineItem.endDateTime, displayTimezone).format('h:mma')}
              </>
            ) : (
              <>
                {moment.tz(lineItem.startDateTime, displayTimezone).format('D/MM/YYYY, h:mma')} -{' '}
                {moment.tz(lineItem.endDateTime, displayTimezone).format('D/MM/YYYY, h:mma')}
              </>
            )}
          </b>
        );
        break;
      case BillingAutoGeneratedType.BeforeBookingTravel:
      case BillingAutoGeneratedType.DuringBookingTravel:
        informationText = (
          <Text>
            Automatically generated based on <b>{billingAutoGeneratedLabel[lineItem.generatedBy]}</b>
          </Text>
        );
        break;
    }

    return (
      <>
        {informationText} {additionalText}
      </>
    );
  };

  private _onChangePaymentSourceType = async (e, isABTAutoCharged?) => {
    const newLocalBillingItem = {
      ...this.state.localBillingItem,
      supportItemNumber: null,
      supportItemNumberArray: [],
      qty: 1,
      unitPrice: 0,
      unit: null,
      description: null,
      total: 0,
      paymentMethod: null,
      ruleType: null,
      isTravel: false,
      travelDistance: 0,
      mileagePrice: 0,
      claimType: '',
      paymentSourceType: e,
      gstValue: 0,
      billingGstValue: 0,
    };

    if (isABTAutoCharged) {
      this.setState({ showMileage: this._showMileage(newLocalBillingItem) });
    }
    this.setState({
      localBillingItem: newLocalBillingItem,
      supportItemOptions: await this._getSupportItemOptions(e),
    });

    if (this.props.isSaveButtonOutside) {
      this.props.updateBillingItem(newLocalBillingItem, this.props.index);
    }
    supportItemAddedNumbers = [];
  };

  private _getSupportItemOptions = (paymentSourceType) => {
    const {
      billingSupportItems,
      isEditPaymentLineItem = false,
      hideSelectionOptionLineItemWarning = false,
      isAutoChargeLineItems = false,
    } = this.props;

    const filteredBillingSupportItems = _.filter(
      billingSupportItems,
      (lineItem) => lineItem.paymentSourceType === paymentSourceType,
    );

    return isEditPaymentLineItem && !isAutoChargeLineItems
      ? []
      : _.map(filteredBillingSupportItems, (billingSupportItem, key) => {
          return (
            <Option
              key={key}
              value={billingSupportItem.supportItemNumber}
              title={
                billingSupportItem.supportItemNumber + '-' + billingSupportItem.supportItemName
                  ? billingSupportItem.supportItemName
                  : billingSupportItem.supportItem
              }
            >
              <>
                <Text weight='bold'>
                  {billingSupportItem.supportItemNumber}
                  {billingSupportItem.paymentSourceType === PaymentSources.NDIS &&
                    !hideSelectionOptionLineItemWarning && (
                      <>
                        {' '}
                        ({billingSupportItem.customerSupportLevel === '1' ? 'Standard' : 'High Needs'})
                        {!billingSupportItem.isFunded && billingSupportItem.isNotInServiceAgreement && (
                          <Text color='orange' className='ml-small'>
                            (Not funded/in service agreement)
                          </Text>
                        )}
                        {!billingSupportItem.isFunded && !billingSupportItem.isNotInServiceAgreement && (
                          <Text color='orange' className='ml-small'>
                            (Not funded)
                          </Text>
                        )}
                        {billingSupportItem.isFunded && billingSupportItem.isNotInServiceAgreement && (
                          <Text color='orange' className='ml-small'>
                            (Not in service agreement)
                          </Text>
                        )}
                      </>
                    )}
                </Text>
                <br />
                <Text>
                  {billingSupportItem.paymentSourceType === PaymentSources.VCP
                    ? billingSupportItem.supportItemName
                      ? billingSupportItem.supportItemName
                      : billingSupportItem.supportItem
                    : billingSupportItem.supportItem}
                </Text>
              </>
            </Option>
          );
        });
  };

  private _isNotInServiceAgreement = (lineItem) => {
    return !_.find(this.props.serviceAgreementItems, {
      supportItemNumber: lineItem.supportItemNumber,
    });
  };

  componentDidMount = () => {
    const {
      billingLineItem,
      claimTravelData,
      billingSupportItems,
      fundedCategories,
      preferredPaymentSourceType,
      hideSelectionOptionLineItemWarning = false,
    } = this.props;
    // let tobeSetBillingItem: any = _.cloneDeep(billingLineItem);
    let tobeSetBillingItem: any = { ...billingLineItem };

    tobeSetBillingItem.paymentSourceType = tobeSetBillingItem.paymentSourceType
      ? tobeSetBillingItem.paymentSourceType
      : preferredPaymentSourceType;

    if (tobeSetBillingItem.paymentSourceType === PaymentSources.NDIS) {
      tobeSetBillingItem.isFunded = !_.isEmpty(
        FundingUtils.getFundingPackageCategoryFromLineItem(
          tobeSetBillingItem.supportType,
          tobeSetBillingItem.supportCategoryNumber,
          tobeSetBillingItem.supportItemNumber,
          fundedCategories,
        ),
      );

      tobeSetBillingItem.isNotInServiceAgreement = this._isNotInServiceAgreement(tobeSetBillingItem);
    }

    if (!tobeSetBillingItem.supportItem) {
      const supportItem = ndisHelper.getBySupportItemNumber(tobeSetBillingItem.supportItemNumber);
      // If is adding new billing line item then supportItem will be undefined
      if (supportItem) {
        tobeSetBillingItem.supportItem = supportItem.SupportItem;
      }
    }

    _.forEach(billingSupportItems, (item) => {
      // Add supportItem for every billingSupportItems
      if (!item.supportItem && item.paymentSourceType === PaymentSources.NDIS) {
        item.supportItem = ndisHelper.getBySupportItemNumber(item.supportItemNumber).SupportItem;
      }
    });

    // Initialise supportItemAddedNumbers
    supportItemAddedNumbers = _.map(
      _.filter(billingSupportItems, (lineItem) => lineItem.paymentSourceType === tobeSetBillingItem.paymentSourceType),
      (supportItem) => supportItem.supportItemNumber,
    );

    const supportItemOptions = this._getSupportItemOptions(tobeSetBillingItem.paymentSourceType);

    // If the saved line is not from the Service list, we add it to the select.
    if (
      tobeSetBillingItem.supportItemNumber &&
      !billingSupportItems.find((item) => tobeSetBillingItem.supportItemNumber === item.supportItemNumber)
    ) {
      // const newItem = ndisHelper.getBySupportItemNumber(tobeSetBillingItem.supportItemNumber);
      // if (newItem) {
      supportItemAddedNumbers.push(tobeSetBillingItem.supportItemNumber);
      supportItemOptions.push(
        <Option
          key={supportItemOptions.length}
          value={tobeSetBillingItem.supportItemNumber}
          disabled={false}
          title={
            tobeSetBillingItem.supportItemNumber + '-' + tobeSetBillingItem.supportItemName
              ? tobeSetBillingItem.supportItemName
              : tobeSetBillingItem.supportItem
          }
        >
          <>
            <Text weight='bold'>
              {tobeSetBillingItem.supportItemNumber}
              {tobeSetBillingItem.paymentSourceType === PaymentSources.NDIS && !hideSelectionOptionLineItemWarning && (
                <>
                  {' '}
                  ({tobeSetBillingItem.customerSupportLevel === '1' ? 'Standard' : 'High Needs'})
                  {!tobeSetBillingItem.isFunded && tobeSetBillingItem.isNotInServiceAgreement && (
                    <Text color='orange' className='ml-small'>
                      (Not funded/in service agreement)
                    </Text>
                  )}
                  {!tobeSetBillingItem.isFunded && !tobeSetBillingItem.isNotInServiceAgreement && (
                    <Text color='orange' className='ml-small'>
                      (Not funded)
                    </Text>
                  )}
                  {tobeSetBillingItem.isFunded && tobeSetBillingItem.isNotInServiceAgreement && (
                    <Text color='orange' className='ml-small'>
                      (Not in service agreement)
                    </Text>
                  )}
                </>
              )}
            </Text>
            <br />
            <Text ellipsis={true} style={{ width: '100%' }}>
              {tobeSetBillingItem.paymentSourceType === PaymentSources.VCP
                ? tobeSetBillingItem.supportItemName
                  ? tobeSetBillingItem.supportItemName
                  : tobeSetBillingItem.supportItem
                : tobeSetBillingItem.supportItem}
            </Text>
          </>
        </Option>,
      );
      // }
    }

    // If the ABT or the PTNLC are claimable, they are added to the list based on the selected line item.

    if (billingLineItem.ruleType === 'ABT') {
      tobeSetBillingItem.travelDistance = tobeSetBillingItem.isAutoGenerated
        ? claimTravelData.travelDistanceDuringBooking
        : tobeSetBillingItem.travelDistance;
      tobeSetBillingItem.additionalCost = tobeSetBillingItem.isAutoGenerated
        ? claimTravelData.additionalCostDuringBooking
        : tobeSetBillingItem.total - tobeSetBillingItem.mileagePrice * tobeSetBillingItem.travelDistance;
    }
    if (billingLineItem.ruleType === 'PTNLC') {
      tobeSetBillingItem.travelDistance = tobeSetBillingItem.isAutoGenerated
        ? claimTravelData.travelDistanceBeforeBooking
        : tobeSetBillingItem.travelDistance;
      tobeSetBillingItem.additionalCost = tobeSetBillingItem.isAutoGenerated
        ? claimTravelData.additionalCostBeforeBooking
        : tobeSetBillingItem.total - tobeSetBillingItem.mileagePrice * tobeSetBillingItem.travelDistance;
    }
    this.setState(
      {
        supportItemOptions,
        showQuantity: true,
        showMileage: this._showMileage(billingLineItem),
        localBillingItem: tobeSetBillingItem,
      },
      () => this._calculateTotalPrice(tobeSetBillingItem),
    );
  };

  componentDidUpdate(prevProps: Readonly<IBillingLineItemProps>, prevState: Readonly<IBillingLineItemState>): void {
    const { claimTravelData } = this.props;
    if (prevState.localBillingItem !== this.state.localBillingItem && this.props.billingLineItem.isEditing) {
      const { localBillingItem } = this.state;
      // set antd form to latest value
      const fieldValues: any = {};
      if (localBillingItem.paymentSourceType === PaymentSources.NDIS) {
        fieldValues.supportItemNumber = localBillingItem.supportItemNumber;
        fieldValues.paymentMethod = localBillingItem.paymentMethod;
        fieldValues.unitPrice = localBillingItem.unitPrice;
        fieldValues.unit = localBillingItem.unit;
      } else {
        fieldValues.supportItemNumber = localBillingItem.supportItemNumber;
        fieldValues.unitPrice = localBillingItem.unitPrice;
      }
      this.props.form.setFieldsValue(fieldValues);
    }

    if (
      this.props.billingLineItem.isEditing &&
      prevProps.billingLineItem?.onlyAppliesToSpecificDays !== this.props.billingLineItem?.onlyAppliesToSpecificDays
    ) {
      this.setState({
        localBillingItem: this.props.billingLineItem,
      });
    }
    if (prevProps.billingLineItem !== this.props.billingLineItem && this.props.billingLineItem.supportItemNumber) {
      const billingLineItem: any = { ...this.props.billingLineItem };

      if (this.props.billingLineItem.paymentSourceType === PaymentSources.NDIS) {
        billingLineItem.supportType = ndisHelper.getBySupportItemNumber(
          billingLineItem.supportItemNumber,
        ).SupportPurposeType;

        if (billingLineItem.ruleType === 'ABT') {
          billingLineItem.travelDistance = billingLineItem.isAutoGenerated
            ? claimTravelData.travelDistanceDuringBooking
            : billingLineItem.travelDistance;
          billingLineItem.additionalCost = billingLineItem.isAutoGenerated
            ? claimTravelData.additionalCostDuringBooking
            : billingLineItem.total - billingLineItem.mileagePrice * billingLineItem.travelDistance;
        }
        if (billingLineItem.ruleType === 'PTNLC') {
          billingLineItem.travelDistance = billingLineItem.isAutoGenerated
            ? claimTravelData.travelDistanceBeforeBooking
            : billingLineItem.travelDistance;
          billingLineItem.additionalCost = billingLineItem.isAutoGenerated
            ? claimTravelData.additionalCostBeforeBooking
            : billingLineItem.total - billingLineItem.mileagePrice * billingLineItem.travelDistance;
        }

        billingLineItem.isFunded = !_.isEmpty(
          FundingUtils.getFundingPackageCategoryFromLineItem(
            billingLineItem.supportType,
            billingLineItem.supportCategoryNumber,
            billingLineItem.supportItemNumber,
            this.props.fundedCategories,
          ),
        );

        billingLineItem.isNotInServiceAgreement = this._isNotInServiceAgreement(billingLineItem);
        // this.props.onChangeLineItem(this.props.index, this.props.billingLineItem);
        this.setState({
          localBillingItem: billingLineItem,
          showQuantity: true,
          showMileage: this._showMileage(billingLineItem),
        });
      } else {
        this.setState({
          localBillingItem: billingLineItem,
        });
      }
    }
  }

  render() {
    const {
      billingSupportItems,
      showLineItemLabel = true,
      index,
      isCancelled,
      cancellationCode,
      cancellationReason,
      isEditPaymentLineItem = false,
      claimTravelData,
      form,
      paymentSourceAvailableTypes,
      hideWarningFunding = false,
      serviceType = 'SUPPORT',
      hideManagementMethod = false,
      hideLineItemCost = false,
      hideBottomLineItemName = false,
      hideInputLabels = false,
      disableUnitSelection = false,
      isUnitPriceDisabled = false,
    } = this.props;

    const { isEditing } = this.props.billingLineItem;

    const { showQuantity, showMileage, isOpenModal, localBillingItem, supportItemOptions } = this.state;

    const { getFieldDecorator } = form;

    const isNDISLineItem =
      localBillingItem && localBillingItem.paymentSourceType
        ? localBillingItem.paymentSourceType === PaymentSources.NDIS
        : false;

    let selectedUnitOption;
    if (localBillingItem && isNDISLineItem) {
      selectedUnitOption = unitOptions.find((i) => i.value === localBillingItem.unit);
    }

    let TargetActionModal: any = this._getActionModal();

    if (!localBillingItem) {
      return <></>;
    }
    const isInServiceBillingItems = billingSupportItems.find(
      (supportItem) => supportItem.supportItemNumber === localBillingItem.supportItemNumber,
    );

    if (isNDISLineItem && !isInServiceBillingItems && !_.isEmpty(localBillingItem.supportItemNumber)) {
      localBillingItem.supportItem = ndisHelper.getBySupportItemNumber(localBillingItem.supportItemNumber).SupportItem;
    }

    if (localBillingItem.claimType === '') {
      localBillingItem.claimType = ClaimType.DIRECT_SERVICE;
    }

    const isGroupTransport = serviceType === 'GROUP' && localBillingItem.claimType === 'TRAN';

    const isTravelClaim = localBillingItem.ruleType === 'PTNLC' || localBillingItem.ruleType === 'ABT';

    const lineItemInformationContent = localBillingItem.isAutoGenerated
      ? this._getInformationText(localBillingItem)
      : null;

    const quantityDisplay = CommonUtils.formatQuantityDisplay(localBillingItem.qty);

    const visibleGstColumn = !isNDISLineItem && (localBillingItem.gst || localBillingItem.isIncludeGst);

    const isABTAutoCharged =
      localBillingItem.ruleType === 'ABT' &&
      localBillingItem.generatedBy === BillingAutoGeneratedType.AutoChargedBillingItemsAdditionalCharge;

    const isBillable = !localBillingItem.discount || Number(localBillingItem.discount) < MAX_DISCOUNT;

    const listSpecificDayString: string[] = localBillingItem.specificDaysOfAvailability?.map(
      (day) => DisplayDaysOfWeek[day],
    );

    return (
      <>
        <AddNDISLineItemModal
          closeLineItemModal={this._closeLineItemModal}
          isOpen={this.state.isOpenAddLineItem}
          fundedCategories={this.props.fundedCategories}
          serviceBillingItems={this.props.serviceBillingLineItems}
          hasABTLineItems={this.props.hasABTLineItems}
          hasPTNLCLineItems={this.props.hasPTNLCLineItems}
          onChangeSupportItemNumber={this._onChangeSupportItemNumber}
          filterFunded={!isEditPaymentLineItem}
          isFilterOutTravelItem={this.props.isFilterOutTravelItem}
          serviceAgreementItems={this.props.serviceAgreementItems}
          hideWarningFunding={this.props.hideWarningFunding}
          isFilterOutPTNLCLineItem={this.props.isFilterOutPTNLCLineItem}
        />
        {!isNDISLineItem && this.state.isOpenVCPItem && (
          <AddVCPLineItemModal
            closeLineItemModal={this._closeVCPModal}
            isOpen={this.state.isOpenVCPItem}
            onChangeSupportItemNumber={this._onChangeSupportItemNumber}
          />
        )}
        <TargetActionModal
          isOpen={isOpenModal}
          onClose={this._closeActionModal}
          onAction={() => {
            const action = this._getActionModalOnAction();
            action(index);
          }}
        />

        <div className={`${!isEditPaymentLineItem && 'bordered bg-white rounded-big pv-x-large ph-large mb-medium'}`}>
          {showLineItemLabel && (
            <Row className='mb-large' type='flex' align='middle'>
              <Col span={17} className='flex-row align-center'>
                {isEditing && paymentSourceAvailableTypes && paymentSourceAvailableTypes.length > 1 ? (
                  <Form.Item className='m-none'>
                    {getFieldDecorator('paymentSourceType', {
                      initialValue: localBillingItem.paymentSourceType,
                      rules: [
                        {
                          required: true,
                          message: 'Please select a payment method',
                        },
                      ],
                    })(
                      <Select
                        size='large'
                        style={{ width: '200px' }}
                        onChange={(e) => this._onChangePaymentSourceType(e, isABTAutoCharged)}
                      >
                        {_.map(paymentSourceAvailableTypes, (paymentSourceType) => {
                          return <Select.Option value={paymentSourceType}>{paymentSourceType}</Select.Option>;
                        })}
                      </Select>,
                    )}
                  </Form.Item>
                ) : (
                  <>
                    <PaymentSourceTagV1
                      className='mr-large'
                      paymentSource={
                        localBillingItem.paymentSourceType ? localBillingItem.paymentSourceType : PaymentSources.NDIS
                      }
                      size='large'
                    />
                    {!isEditing && (
                      <Text className='text-weight-bold' size='x2-large'>
                        {localBillingItem.paymentStatus && localBillingItem.paymentStatus !== PaymentStatus.INITIAL && (
                          <BillingLineItemStatus paymentStatus={localBillingItem.paymentStatus} />
                        )}
                      </Text>
                    )}
                  </>
                )}
              </Col>

              <Col span={7} className='text-align-right'>
                {this._renderActionButtons(isBillable)}
              </Col>
            </Row>
          )}
          {/* Cancellation Reason if the booking is Cancelled */}
          {isCancelled && (
            <div className='mv-large'>
              <SubTitle>Cancellation Code</SubTitle>
              <Text>{cancellationCode}</Text>
              {/* If cancellation code is NSDO (Other) */}
              {cancellationCode === 'NSDO' && (
                <>
                  <br />
                  <SubTitle>Cancellation Reason</SubTitle>
                  <Text>{cancellationReason}</Text>
                </>
              )}
            </div>
          )}

          {/* Reject Reason */}
          {localBillingItem.paymentStatus === BillingPaymentStatus.REJECTED && (
            <div className='mv-large'>
              <SubTitle>
                <Text color='red-dark'>Reject Reason</Text>
              </SubTitle>
              <Text>{localBillingItem.rejectReason}</Text>
            </div>
          )}

          {localBillingItem.isAutoGenerated && !this.props.isLineItemLocked && (
            <div className='mb-large'>
              <Information content={<Text>{lineItemInformationContent}</Text>} className='width-full' />
            </div>
          )}

          {!isBillable && (
            <div className='mb-large'>
              <Warning content='Line item marked as non-billable' />
            </div>
          )}

          {!hideInputLabels && (
            <Row gutter={24} className='bordered-bottom pb-small'>
              <Col span={hideManagementMethod ? 11 : 7}>
                <b>Line item</b>
              </Col>
              {isNDISLineItem && (
                <>
                  <Col span={4}>
                    <b>Claim type</b>
                  </Col>
                  {!hideManagementMethod && (
                    <Col span={4}>
                      <b>Management</b>
                    </Col>
                  )}
                  <Col span={3}>
                    <b>Unit</b>
                  </Col>
                </>
              )}
              <Col span={3}>
                <b>Price</b>
              </Col>
              {visibleGstColumn && (
                <Col span={3}>
                  <b>{`GST (${Number(localBillingItem.gst) * 100}%)`}</b>
                </Col>
              )}
              {showQuantity && (
                <Col span={3}>
                  <b>Quantity</b>
                </Col>
              )}
            </Row>
          )}
          <Row gutter={24} className='pv-medium' type='flex' align='top'>
            <Col span={hideManagementMethod ? 11 : 7}>
              {this._renderFormField(
                <Form.Item className='m-none'>
                  {getFieldDecorator('supportItemNumber', {
                    initialValue: localBillingItem.supportItemNumberArray,
                    // valuePropName: 'defaultValue',
                    rules: [
                      {
                        required: true,
                        message: 'Please select a support item number',
                      },
                    ],
                  })(
                    <Select
                      allowClear={false}
                      size='large'
                      showSearch={true}
                      filterOption
                      optionFilterProp='title'
                      optionLabelProp='value'
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <div
                            style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            <HyperlinkButton
                              className='text-color-green'
                              onClick={!isNDISLineItem ? this._openVCPModal : this._openLineItemModal}
                            >
                              <Icon type='plus' /> Select another Line Item
                            </HyperlinkButton>
                          </div>
                        </div>
                      )}
                      dropdownMatchSelectWidth={false}
                      style={{ width: '100%', whiteSpace: 'pre-wrap' }}
                      onChange={(event) => this._onChangeSupportItemNumber(event)}
                    >
                      {supportItemOptions}
                    </Select>,
                  )}
                </Form.Item>,
                <>
                  {localBillingItem.supportItemNumber && (
                    <Text size='large'>
                      {localBillingItem.supportItemNumber ? <b>{localBillingItem.supportItemNumber}</b> : '-'}
                      <br />
                      {localBillingItem.paymentSourceType === PaymentSources.VCP
                        ? localBillingItem.supportItemName
                        : !_.isEmpty(isInServiceBillingItems)
                        ? isInServiceBillingItems.supportItem
                        : localBillingItem.supportItem
                        ? localBillingItem.supportItem
                        : ''}
                    </Text>
                  )}
                  {localBillingItem.specificDaysOfAvailability?.length > 0 && (
                    <>
                      <br />
                      <Text>
                        Applied on {new Intl.ListFormat('en').format(listSpecificDayString)} <b>only</b>
                      </Text>
                    </>
                  )}
                </>,
                !isTravelClaim ||
                  (!this.props.isFilterOutTravelItem && !localBillingItem.isAutoGenerated) ||
                  isABTAutoCharged,
              )}
            </Col>
            {isNDISLineItem && (
              <>
                <Col span={4}>
                  {isCancelled && this.props.bookingStatus !== BookingStatus.CUSTOMER_CANCELLED_WITH_FEE
                    ? CLAIM_TYPE_DIRECT_OPTIONS.find((item) => item.value === localBillingItem.claimType)
                      ? CLAIM_TYPE_DIRECT_OPTIONS.find((item) => item.value === localBillingItem.claimType).label
                      : 'Direct Service'
                    : this._renderFormField(
                        <Form.Item className='m-none'>
                          {getFieldDecorator('claimType', {
                            initialValue: localBillingItem.claimType,
                            rules: [
                              {
                                required: true,
                                message: 'Please select a claim type',
                              },
                            ],
                          })(
                            <Select
                              size='large'
                              showSearch
                              style={{ width: '160px' }}
                              onChange={this._onChangeClaimType}
                              filterOption={true}
                              optionFilterProp='children'
                              dropdownMatchSelectWidth={false}
                            >
                              {_.filter(
                                CLAIM_TYPE_DIRECT_OPTIONS,
                                (claimTypeOption) =>
                                  claimTypeOption.value !== ClaimType.CANCELLATION &&
                                  (claimTypeOption.value === 'TRAN' ||
                                    localBillingItem.ruleType !== 'ABT' ||
                                    isABTAutoCharged),
                              ).map((option) => {
                                return (
                                  <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                  </Select.Option>
                                );
                              })}
                            </Select>,
                          )}
                        </Form.Item>,
                        <div className={`${isEditing ? 'mt-small' : ''}`}>
                          {localBillingItem.claimType
                            ? CLAIM_TYPE_DIRECT_OPTIONS.find((item) => item.value === localBillingItem.claimType).label
                            : '-'}
                        </div>,
                        !isTravelClaim || (isTravelClaim && !localBillingItem.isAutoGenerated) || isABTAutoCharged,
                      )}
                </Col>
                {!hideManagementMethod && (
                  <Col span={4}>
                    {!this.props.isBulkAddLineItem ? (
                      this._renderFormField(
                        <Form.Item className='m-none'>
                          {getFieldDecorator('paymentMethod', {
                            initialValue: localBillingItem.paymentMethod,
                            rules: [
                              {
                                required: true,
                                message: 'Please select payment method',
                              },
                            ],
                          })(
                            <Select
                              size='large'
                              showSearch
                              style={{ width: '160px' }}
                              onChange={this._onChangePaymentMethod}
                              filterOption={true}
                              optionFilterProp='children'
                            >
                              {paymentMethodOptions.map((option) => {
                                return (
                                  <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                  </Select.Option>
                                );
                              })}
                            </Select>,
                          )}
                        </Form.Item>,
                        localBillingItem.paymentMethod
                          ? paymentMethodOptions.find((item) => item.value === localBillingItem.paymentMethod).label
                          : '-',
                      )
                    ) : (
                      <div className='mt-small'>-</div>
                    )}
                  </Col>
                )}
                <Col span={3}>
                  {this.state.showMileage && !isABTAutoCharged && (
                    <div className={`${isEditing ? 'mt-small' : ''}`}>
                      {selectedUnitOption ? selectedUnitOption.label : '-'}
                    </div>
                  )}
                  {(!this.state.showMileage || isABTAutoCharged) &&
                    this._renderFormField(
                      <Form.Item className='m-none'>
                        {getFieldDecorator('unit', {
                          initialValue: localBillingItem.unit,
                          rules: [
                            {
                              required: true,
                              message: 'Please select unit',
                            },
                          ],
                        })(
                          <Select
                            size='large'
                            style={{ width: '100%' }}
                            onChange={this._onChangeUnit}
                            disabled={disableUnitSelection}
                          >
                            {unitOptions.map((option) => {
                              return (
                                <Select.Option key={option.value} value={option.value}>
                                  {option.label}
                                </Select.Option>
                              );
                            })}
                          </Select>,
                        )}
                      </Form.Item>,
                      <div className={`${isEditing ? 'mt-small' : ''}`}>
                        {selectedUnitOption ? selectedUnitOption.label : '-'}
                      </div>,
                      !isTravelClaim || !localBillingItem.isAutoGenerated || isABTAutoCharged,
                    )}
                </Col>
              </>
            )}
            <Col span={3}>
              {this.state.showMileage && !isABTAutoCharged && (
                <div className={`${isEditing ? 'mt-small' : ''}`}>
                  {localBillingItem.unitPrice ? CommonUtils.formatPrice(localBillingItem.unitPrice) : '-'}
                  {isEditing && (
                    <BillingLineItemTooltip
                      type={TooltipType.UNIT_PRICE}
                      value={
                        localBillingItem.unitPrice ? `${CommonUtils.formatPrice(localBillingItem.unitPrice)}` : '-'
                      }
                    />
                  )}
                </div>
              )}
              {(!this.state.showMileage || isABTAutoCharged) &&
                this._renderFormField(
                  <>
                    <Form.Item className='m-none'>
                      {getFieldDecorator('unitPrice', {
                        initialValue: localBillingItem.unitPrice,
                        rules: [
                          {
                            validator: this._validateUnitPrice,
                          },
                        ],
                      })(
                        <InputNumber
                          disabled={isGroupTransport || isUnitPriceDisabled}
                          size='large'
                          precision={2}
                          onBlur={this._onChangeCalculateTotalPrice}
                          style={{ width: '100%' }}
                        />,
                      )}
                    </Form.Item>
                  </>,
                  <div className={`${isEditing ? 'mt-small' : ''}`}>
                    {localBillingItem.unitPrice ? `${CommonUtils.formatPrice(localBillingItem.unitPrice)}` : '-'}
                    {isEditing && (
                      <BillingLineItemTooltip
                        type={TooltipType.UNIT_PRICE}
                        value={
                          localBillingItem.unitPrice ? `${CommonUtils.formatPrice(localBillingItem.unitPrice)}` : '-'
                        }
                      />
                    )}
                  </div>,
                  (!isTravelClaim && !isABTAutoCharged) || isUnitPriceDisabled,
                )}
            </Col>
            {visibleGstColumn && (
              <Col span={3}>
                {this._renderFormField(
                  <>
                    <Form.Item className='m-none'>
                      {getFieldDecorator('gstValue', {
                        initialValue: localBillingItem.gstValue,
                      })(
                        <div>
                          {localBillingItem.gstValue ? CommonUtils.formatPrice(localBillingItem.gstValue) : '-'}
                        </div>,
                      )}
                    </Form.Item>
                  </>,
                  <div className={isEditing ? 'mt-small' : ''}>
                    {localBillingItem.gstValue ? `${CommonUtils.formatPrice(localBillingItem.gstValue)}` : '-'}
                  </div>,
                  !isTravelClaim && !localBillingItem.isAutoGenerated,
                )}
              </Col>
            )}
            {showQuantity && (
              <Col span={3}>
                {this.state.showMileage && !isABTAutoCharged && (
                  <div className={isEditing ? 'mt-small' : ''}>
                    {quantityDisplay}
                    {isEditing && <BillingLineItemTooltip type={TooltipType.QTY} />}
                  </div>
                )}
                {(!this.state.showMileage || isABTAutoCharged) &&
                  this._renderFormField(
                    <>
                      <Form.Item className='m-none'>
                        {getFieldDecorator('qty', {
                          initialValue: localBillingItem.qty,
                          rules: [
                            {
                              validator: this._validateQty,
                            },
                          ],
                        })(
                          <InputNumber
                            size='large'
                            precision={localBillingItem.claimType === 'NF2F' ? 8 : 2}
                            onBlur={this._onChangeCalculateTotalPrice}
                            style={{ width: '100%' }}
                          />,
                        )}
                      </Form.Item>
                    </>,
                    <div className={`${isEditing ? 'mt-small' : ''}`}>
                      {/* {localBillingItem.qty ? localBillingItem.qty : '-'} */}
                      {quantityDisplay}
                      {isEditing && <BillingLineItemTooltip type={TooltipType.QTY} />}
                    </div>,
                    !isTravelClaim || !localBillingItem.isAutoGenerated || isABTAutoCharged,
                  )}
              </Col>
            )}
          </Row>
          {localBillingItem.supportItem && !hideBottomLineItemName && (
            <Row className='mb-medium'>
              <Col span={24}>
                <Text>{localBillingItem.supportItem}</Text>
              </Col>
            </Row>
          )}
          {isNDISLineItem && localBillingItem.supportItemNumber && (
            <Row>
              <Col span={24}>
                {!localBillingItem.paymentMethod && !hideWarningFunding && (
                  <Text color='red-dark'>
                    <Icon type='close-circle' className='mr-x-small' /> This line item does not have a specified
                    management method.
                    <br />
                  </Text>
                )}
                {!localBillingItem.isFunded && !hideWarningFunding && (
                  <Text color='orange'>
                    <Icon type='exclamation-circle' className='mr-x-small' /> The customer does not have this line item
                    in their active funding package.
                    <br />
                  </Text>
                )}
                {localBillingItem.isNotInServiceAgreement && !hideWarningFunding && (
                  <Text color='orange'>
                    <Icon type='exclamation-circle' className='mr-x-small' /> The customer does not have this line item
                    in their active service agreement.
                  </Text>
                )}
              </Col>
            </Row>
          )}
          {/* travel claim type */}
          {isNDISLineItem && showMileage && !isABTAutoCharged && (
            <>
              <Row gutter={24} className='bordered-bottom pb-small mt-x-large' type='flex' align='middle'>
                <Col span={5}>
                  <b>Kilometers Travelled</b>
                </Col>
                <Col span={5}>
                  <b>Cost per Kilometres</b>
                </Col>
                <Col span={5}>
                  <b>Additional Cost</b>
                </Col>
              </Row>
              <Row gutter={24} className='mt-small' type='flex' align={localBillingItem.isEditing ? 'top' : 'middle'}>
                <Col span={5}>
                  {this._renderFormField(
                    <Form.Item className='m-none'>
                      {getFieldDecorator('travelDistance', {
                        initialValue: localBillingItem.travelDistance,
                        rules: [
                          {
                            validator: this._validateTravelDistance,
                          },
                        ],
                      })(
                        <InputNumber
                          style={{ width: '100%' }}
                          size='large'
                          className='width-full'
                          onBlur={this._onChangeTravelDistance}
                        />,
                      )}
                    </Form.Item>,
                    <div className={`${isEditing ? 'mt-small' : ''}`}>
                      {localBillingItem.travelDistance ? localBillingItem.travelDistance : '-'}
                      {isEditing && <BillingLineItemTooltip type={TooltipType.TRAVEL_DISTANCE} />}
                    </div>,
                    !isTravelClaim || !localBillingItem.isAutoGenerated,
                  )}
                </Col>
                <Col span={5}>
                  {' '}
                  {this._renderFormField(
                    <>
                      <Form.Item className='m-none'>
                        {getFieldDecorator('mileagePrice', {
                          initialValue: localBillingItem.mileagePrice,
                          rules: [
                            {
                              validator: this._validateTransportPrice,
                            },
                          ],
                        })(
                          <InputNumber
                            style={{ width: '100%' }}
                            size='large'
                            precision={2}
                            onBlur={this._onChangeTravelDistance}
                          />,
                        )}
                      </Form.Item>
                      {/*Only display the information about default price if the claimTravelData exist. (Doesn't exist in Payment)*/}
                      {localBillingItem.ruleType === 'ABT' &&
                        claimTravelData &&
                        Number(localBillingItem.mileagePrice) !==
                          Number(claimTravelData.transportPriceDuringBooking) && (
                          <Text color='orange'>
                            Set transport price: {CommonUtils.formatPrice(claimTravelData.transportPriceDuringBooking)}
                          </Text>
                        )}
                      {localBillingItem.ruleType === 'PTNLC' &&
                        claimTravelData &&
                        Number(localBillingItem.mileagePrice) !==
                          Number(claimTravelData.transportPriceBeforeBooking) && (
                          <Text color='orange'>
                            Set transport price: {CommonUtils.formatPrice(claimTravelData.transportPriceBeforeBooking)}
                          </Text>
                        )}
                    </>,
                    <div className={`${isEditing ? 'mt-small' : ''}`}>
                      {localBillingItem.mileagePrice ? CommonUtils.formatPrice(localBillingItem.mileagePrice) : '-'}
                    </div>,
                  )}
                </Col>
                <Col span={5}>
                  {this._renderFormField(
                    <Form.Item className='m-none'>
                      {getFieldDecorator('additionalCost', {
                        initialValue: localBillingItem.additionalCost,
                        rules: [{ validator: this._validateAdditionalCost }],
                      })(
                        <InputNumber
                          style={{ width: '100%' }}
                          size='large'
                          precision={2}
                          onBlur={this._onChangeTravelDistance}
                        />,
                      )}
                    </Form.Item>,
                    <div className={`${isEditing ? 'mt-small' : ''}`}>
                      {localBillingItem.additionalCost ? CommonUtils.formatPrice(localBillingItem.additionalCost) : '-'}
                      {isEditing && <BillingLineItemTooltip type={TooltipType.ADDITIONAL_COST} />}
                    </div>,
                    !isTravelClaim || !localBillingItem.isAutoGenerated,
                  )}
                </Col>
              </Row>
            </>
          )}
          {!hideLineItemCost && (
            <div className='mt-large text-align-right'>
              <Text>Line item cost:</Text>{' '}
              <Text weight={!isBillable ? '' : 'bold'} size='x-large'>
                {localBillingItem.paymentStatus === BillingPaymentStatus.WAIVED
                  ? 0.0
                  : localBillingItem.total
                  ? CommonUtils.formatPrice(localBillingItem.total)
                  : '-'}
              </Text>
              {!isBillable && (
                <div>
                  <Text>Less {Number(localBillingItem.discount) * 100}% discount:</Text>{' '}
                  <Text size='x-large'>
                    {localBillingItem.paymentStatus === BillingPaymentStatus.WAIVED
                      ? 0.0
                      : localBillingItem.total
                      ? `(-${CommonUtils.formatPrice(localBillingItem.total * Number(localBillingItem.discount))})`
                      : '-'}
                  </Text>
                  <br />
                  <Text weight='bold' size='x-large'>
                    = {CommonUtils.formatPrice(localBillingItem.total * (1 - Number(localBillingItem.discount)))}
                  </Text>
                </div>
              )}
              {/*{isCancelled && (*/}
              {/*  <>*/}
              {/*    <br />*/}
              {/*    <Text size="regular" color="secondary">*/}
              {/*      Reduced price (90%) due to cancellation :{' '}*/}
              {/*      <b>*/}
              {/*        {localBillingItem.billingTotal ? CommonUtils.formatPrice(localBillingItem.billingTotal) : '-'}*/}
              {/*      </b>*/}
              {/*    </Text>*/}
              {/*  </>*/}
              {/*)}*/}
              {localBillingItem.paymentStatus === BillingPaymentStatus.WAIVED && (
                <>
                  <br />
                  <Text size='regular' color='secondary'>
                    Waived from :{' '}
                    <b>
                      {localBillingItem.billingTotal ? CommonUtils.formatPrice(localBillingItem.billingTotal) : '-'}
                    </b>
                  </Text>
                </>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetVCPItems: dispatch.servicesStore.doGetVCPItems,
});

export default connect(null, mapDispatch)(Form.create<IBillingLineItemProps>()(BillingLineItemV2));
