import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const SliderHorizontal3 = forwardRef(function SliderHorizontal3(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.948 2.25h8.104c.899 0 1.648 0 2.242.08.628.084 1.195.27 1.65.725.456.456.642 1.023.726 1.65.08.595.08 1.345.08 2.243v2.104c0 .898 0 1.648-.08 2.242-.084.628-.27 1.195-.725 1.65-.456.456-1.023.642-1.65.726-.595.08-1.344.08-2.243.08H7.948c-.898 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.726-.456-.455-.642-1.022-.726-1.65-.08-.594-.08-1.344-.08-2.242V6.948c0-.898 0-1.648.08-2.242.084-.628.27-1.195.726-1.65.455-.456 1.022-.642 1.65-.726.594-.08 1.344-.08 2.242-.08ZM5.905 3.817c-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788C4.753 5.388 4.75 6.036 4.75 7v2c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.788.3.483.064 1.131.066 2.095.066h8c.964 0 1.612-.002 2.095-.066.461-.063.659-.17.789-.3.13-.13.238-.328.3-.79.064-.482.066-1.13.066-2.094V7c0-.964-.002-1.612-.067-2.095-.061-.461-.169-.659-.3-.789-.13-.13-.327-.237-.788-.3-.483-.064-1.13-.066-2.095-.066H8c-.964 0-1.612.002-2.095.067ZM5.25 17a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75ZM9 20.25a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5H9Z"
        fill="currentColor"
      />
    </Icon>
  );
});
