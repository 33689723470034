import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Avatar, Col, Row } from 'antd';
import DatePicker from 'react-datepicker';

import CommonUtils from 'utilities/common-utils';
import { timeZone } from 'src/interfaces/timezone-type';

import ActionModal from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Text, SubTitle } from 'common-components/typography';
import TimeInput from 'common-components/time-input/TimeInput';
import { IGroupServiceSupportWorkersWithConflicts } from 'src/interfaces/service-interfaces';

interface IEditShiftTimeModalProps {
  isOpen: boolean;
  onSave: (startDateTime, endDateTime) => void;
  onCancel: () => void;
  defaultStartDateTime: moment.Moment;
  defaultEndDateTime: moment.Moment;
  timezone: timeZone;
  selectedUser: IGroupServiceSupportWorkersWithConflicts;
}

const EditShiftTimeModal = (props: IEditShiftTimeModalProps) => {
  const { isOpen, onSave, onCancel, defaultStartDateTime, defaultEndDateTime, timezone, selectedUser } = props;
  const [startDateTime, setStartDateTime] = useState<moment.Moment>(
    moment(moment.tz(defaultStartDateTime, timezone).format('YYYY-MM-DD HH:mm:ss')),
  );
  const [endDateTime, setEndDateTime] = useState<moment.Moment>(
    moment(moment.tz(defaultEndDateTime, timezone).format('YYYY-MM-DD HH:mm:ss')),
  );
  const [hasError, setHasError] = useState<boolean>(false);

  const handleStartDateTime = (value) => {
    setStartDateTime(CommonUtils.formatCeilingDateTime(value));
    if (endDateTime.isSameOrBefore(value)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  const handleEndDateTime = (value) => {
    setEndDateTime(CommonUtils.formatCeilingDateTime(value));
    if (startDateTime.isSameOrAfter(value)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  const handleSave = () => {
    const convertedStartDateTime = moment.tz(moment(startDateTime).tz(timezone, true).format(), timezone).toISOString();
    const convertedEndDateTime = moment.tz(moment(endDateTime).tz(timezone, true).format(), timezone).toISOString();
    onSave(convertedStartDateTime, convertedEndDateTime);
    onCancel();
  };

  useEffect(() => {
    if (isOpen) {
      setStartDateTime(moment(moment.tz(defaultStartDateTime, timezone).format('YYYY-MM-DD HH:mm:ss')));
      setEndDateTime(moment(moment.tz(defaultEndDateTime, timezone).format('YYYY-MM-DD HH:mm:ss')));
    }
  }, [isOpen]);

  return (
    <ActionModal isOpen={isOpen} title="Edit shift time" showCloseButton={true} onClose={onCancel} width="large">
      <Row className="bg-quaternary rounded-big p-medium mt-x-small mb-large">
        <Col span={10}>
          <SubTitle>SELECTED TEAM MEMBER</SubTitle>
          <div className="flex align-center">
            <Avatar className="mt-x-small mr-small" size={32} src={selectedUser.attachmentUrl} shape="square" />{' '}
            <Text size="medium">{`${selectedUser.firstName} ${selectedUser.lastName}`}</Text>
          </div>
        </Col>
        <Col span={10}>
          <SubTitle>SESSION DATE</SubTitle>
          <Text>{moment.tz(defaultStartDateTime, timezone).format('DD MMMM YYYY')}</Text>
          <div>
            <Text color="secondary">
              {moment.tz(defaultStartDateTime, timezone).format('hh:mm a')} -{' '}
              {moment.tz(defaultEndDateTime, timezone).format('hh:mm a')}
            </Text>
          </div>
        </Col>
      </Row>

      <Text color="secondary" size="large" weight="bold">
        Shift time
      </Text>
      <Row className="bordered p-large rounded-big mt-x-small">
        <Row>
          <Col span={12}>
            <SubTitle>START DATE/TIME</SubTitle>
            <div className="flex">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                isClearable={false}
                selected={startDateTime.toDate()}
                onChange={handleStartDateTime}
                className={`${hasError ? 'border-red' : ''} mr-small gh-datepicker rounded p-small`}
                calendarClassName="gh-datepicker-calendar"
              />
              <TimeInput
                className={`${hasError ? 'border-red' : ''}`}
                size="large"
                onChange={handleStartDateTime}
                value={startDateTime}
              />
            </div>
            {hasError && <Text color="red">Start time cannot be same or after end time</Text>}
          </Col>
          <Col span={12}>
            <SubTitle>END DATE/TIME</SubTitle>
            <div className="flex">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                isClearable={false}
                selected={endDateTime.toDate()}
                onChange={handleEndDateTime}
                className={`${hasError ? 'border-red' : ''} mr-small gh-datepicker rounded p-small`}
                calendarClassName="gh-datepicker-calendar"
              />
              <TimeInput
                className={`${hasError ? 'border-red' : ''}`}
                size="large"
                onChange={handleEndDateTime}
                value={endDateTime}
              />
            </div>
          </Col>
        </Row>
      </Row>

      <div className="flex justify-end mt-x3-large">
        <SecondaryButton size="large" className="mr-medium" onClick={onCancel}>
          Cancel
        </SecondaryButton>
        <PrimaryButton size="large" onClick={handleSave} disabled={hasError}>
          Save changes
        </PrimaryButton>
      </div>
    </ActionModal>
  );
};

export default React.memo(EditShiftTimeModal);
