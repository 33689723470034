import React, { Component, CSSProperties } from 'react';
import { Input } from 'antd';
import _ from 'lodash';

interface NumberInputProps {
  size?: 'large' | 'small' | 'default';
  value?: any;
  defaultValue?: any;
  onChange?: any;
  className?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  precision?: number;
  addonBefore?: string;
  addonAfter?: string;
  step?: number;
  placeholder?: string;
  style?: CSSProperties;
  nullable?: boolean;
}

interface NumberInputState {
  value: string;
  defaultPrecision: number;
}

const KEYACTIONS = {
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  A: 65,
  P: 80,
};

class NumberInput extends Component<NumberInputProps, NumberInputState> {
  state = {
    value: this.props.value
      ? Number(this.props.value).toFixed(this.props.precision !== undefined ? this.props.precision : 2)
      : null,
    defaultPrecision: 2,
  };

  private _onChangeNumber = (e) => {
    const { min, max, precision, nullable = false } = this.props;
    const regex = new RegExp(
      `^\\d*${precision === 0 ? '' : '[.]?'}\\d{0,${
        precision !== undefined ? precision : this.state.defaultPrecision
      }}$`,
    );

    let number = e.target.value;

    if (!number.match(regex)) {
      return;
    }
    if (max && number > max) {
      number = max;
    }
    if (min && number < min) {
      number = min;
    }

    if (nullable) {
      number = _.toString(number) ? number : null;
    }

    if (this.props.onChange) this.props.onChange(number ? Number(number) : null);
    this.setState({ value: number });
  };

  private _formatValue = () => {
    const { precision = this.state.defaultPrecision, nullable = false } = this.props;
    if (nullable) {
      const value = _.toString(this.state.value) ? Number(this.state.value).toFixed(precision) : null;
      this.setState({ value });
    } else {
      this.setState({ value: Number(this.state.value).toFixed(precision) });
    }
  };

  private _checkStep = (e) => {
    const { precision } = this.props;
    const { value } = this.state;

    if (e.keyCode == KEYACTIONS.UP) {
      const step = this.props.step ? this.props.step : 1;
      const nextStep = Number(value) + step;
      if (this.props.max && nextStep <= Number(this.props.max)) {
        this.setState({ value: nextStep.toFixed(precision !== undefined ? precision : this.state.defaultPrecision) });
        this.props.onChange(Number(nextStep));
      }
    }
    if (e.keyCode == KEYACTIONS.DOWN) {
      const step = this.props.step ? this.props.step : 1;
      const previousStep = Number(value) - step;
      if (this.props.min !== undefined && previousStep >= Number(this.props.min)) {
        this.setState({
          value: previousStep.toFixed(precision !== undefined ? precision : this.state.defaultPrecision),
        });
        this.props.onChange(Number(previousStep));
      }
    }
  };

  componentDidUpdate(prevProps: Readonly<NumberInputProps>, prevState: Readonly<NumberInputState>, snapshot?: any) {
    if (!isNaN(this.props.value) && prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    const style: any = this.props.style ? this.props.style : { width: '90px', textAlign: 'right' };

    return (
      <Input
        size={this.props.size}
        className={'inline-block ' + this.props.className}
        onChange={this._onChangeNumber}
        addonBefore={this.props.addonBefore}
        addonAfter={this.props.addonAfter}
        defaultValue={this.props.defaultValue}
        value={this.state.value}
        onBlur={this._formatValue}
        onKeyDown={this._checkStep}
        disabled={this.props.disabled}
        // onKeyDown={this._substractStep}
        style={style}
        placeholder={this.props.placeholder}
      />
    );
  }
}

export default NumberInput;
