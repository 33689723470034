import styled from 'styled-components';

import type { CSSObject } from 'styled-components';

const gaps = {
  none: '0px',
  xs: '2px',
  sm: '4px',
  md: '8px',
  lg: '12px',
  xl: '16px',
  xxl: '24px',
  xxxl: '32px',
};

export type StackProps = Partial<{
  alignContent: CSSObject['alignContent'];
  alignItems: CSSObject['alignItems'];
  dir: CSSObject['flexDirection'];
  gap: keyof typeof gaps;
  justifyContent: CSSObject['justifyContent'];
  justifyItems: CSSObject['justifyItems'];
}>;

/**
 * @name
 * Stack
 *
 * @description
 * Evenly space children within a `Stack`.
 *
 * @example
 * <Stack gap="md">
 *  <div>one</div>
 *  <div>two</div>
 * </Stack>
 */
export const Stack = styled.div<StackProps>((props) => {
  let { alignContent, alignItems, dir = 'column', gap, justifyContent, justifyItems } = props;
  let gapValue = gaps[gap] || 'none';

  return {
    alignContent,
    alignItems,
    display: 'flex',
    flexDirection: dir,
    gap: gapValue,
    justifyContent,
    justifyItems,
  } as CSSObject;
});
