import React from 'react';
import { EndSpacer } from 'common-components/roster-control/roster-daily/timeline-pane/common/EndSpacer';
import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';

interface CustomersTimelineHeaderProps {
  rowWidth: number;
}

export function CustomersTimelineHeader({ rowWidth }: CustomersTimelineHeaderProps) {
  const { CONTENT_GROUP_HEIGHT } = ROSTER_DAILY_CONFIG;

  return (
    <div className="flex-row">
      <div
        className="bg-tertiary width-full bordered-bottom"
        style={{ height: `${CONTENT_GROUP_HEIGHT}px`, minWidth: `${rowWidth}px`, maxWidth: `${rowWidth}px` }}
      />
      {/* Spacer*/}
      <EndSpacer height={56} />
    </div>
  );
}
