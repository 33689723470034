import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Tags = forwardRef(function Tags(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 3A.75.75 0 0 1 9 2.25h2.058c2.78 0 4.942 0 6.626.226 1.717.231 3.048.71 4.089 1.751 1.041 1.041 1.52 2.372 1.75 4.09.227 1.683.227 3.846.227 6.625V15h-1.5c0-2.85-.002-4.911-.213-6.484-.208-1.55-.609-2.512-1.325-3.228-.716-.716-1.678-1.117-3.228-1.325C15.91 3.752 13.85 3.75 11 3.75H9A.75.75 0 0 1 8.25 3Zm-6.235 8.687a2.25 2.25 0 0 0 0 .627c.044.309.194.637.579 1.118.394.493.972 1.073 1.802 1.903.984.984 1.363 1.35 1.811 1.574.136.067.276.125.42.173.474.16 1 .168 2.392.168h4.982c1.435 0 2.436-.002 3.191-.103.734-.099 1.123-.28 1.4-.556.276-.277.457-.665.555-1.399.102-.755.104-1.757.104-3.192 0-1.435-.002-2.436-.104-3.192-.098-.734-.279-1.122-.555-1.399-.277-.277-.666-.457-1.4-.556-.755-.101-1.756-.103-3.191-.103H9.018c-1.391 0-1.918.01-2.393.168a3.25 3.25 0 0 0-.419.174c-.448.223-.827.589-1.81 1.573-.83.83-1.409 1.41-1.803 1.903-.385.481-.535.81-.579 1.119Zm-1.485.835a3.75 3.75 0 0 1 0-1.044c.096-.685.425-1.262.892-1.847.453-.566 1.09-1.204 1.882-1.995l.031-.031.091-.091c.862-.862 1.418-1.419 2.112-1.765a4.75 4.75 0 0 1 .612-.253c.735-.246 1.522-.246 2.741-.246H14.055c1.368 0 2.47 0 3.337.117.9.12 1.658.38 2.26.981.602.602.861 1.36.982 2.26.117.867.116 1.97.116 3.337v.11c0 1.368 0 2.47-.116 3.337-.121.9-.38 1.658-.982 2.26-.602.602-1.36.86-2.26.982-.867.116-1.969.116-3.337.116H8.891c-1.219 0-2.006 0-2.74-.245a4.756 4.756 0 0 1-.613-.254c-.694-.346-1.25-.902-2.112-1.765l-.09-.09-.032-.032c-.791-.79-1.43-1.429-1.882-1.995-.467-.585-.796-1.162-.892-1.847ZM7.75 12a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM9 9.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
