import React, { Component } from 'react';
import { Text } from 'common-components/typography';

interface ICustomerConnectionStatusTagProps {
  customerStatus: string;
}

class CustomerConnectionStatusTag extends Component<ICustomerConnectionStatusTagProps, any> {
  render() {
    const { customerStatus } = this.props;
    let label, bgColor, textColor, borderColor;
    switch (customerStatus) {
      case 'CONNECTED':
        label = 'Connected';
        bgColor = 'bg-green';
        textColor = 'text-color-white';
        borderColor = '#0F9960';
        break;
      case 'PENDING':
        label = 'Connection request sent';
        bgColor = 'bg-green-lightest';
        textColor = 'text-color-green';
        borderColor = '#3DCC91';
        break;
      case 'NOT_CONNECTED':
        label = 'Not connected';
        bgColor = 'bg-quaternary';
        textColor = 'text-color-secondary';
        borderColor = '#696969';
        break;
    }

    const containerClassNames = `text-align-center pv-x-small ph-small text-size-regular inline-block rounded ${bgColor} bordered`;
    const textClassNames = `${textColor}`;
    return (
      <div
        className={containerClassNames}
        style={
          borderColor
            ? {
                borderColor: borderColor,
              }
            : {}
        }
      >
        <Text size={'regular'} className={textClassNames}>
          {label}
        </Text>
      </div>
    );
  }
}

export default CustomerConnectionStatusTag;
