import type * as Stitches from '@stitches/react';

import { css, CSS } from '../../theme';

export const reset: CSS = {
  listStyle: 'none',
  boxSizing: 'border-box',
  marginBlockStart: '$space0',
  marginBlockEnd: '$space0',
};

export const option: CSS = {
  justifyContent: 'justify-between',
  alignItems: 'center',
  display: 'flex',
  margin: '$space0',
  padding: '$space100',
  cursor: 'pointer',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  transition: '$transition100',
  gap: '$space65',
  fontFamily: '$primary',
};

export const optionVariants = css({
  outlineOffset: '-1.5px !important',
  borderRadius: '$small',
  backgroundColor: '$$optionBackgroundColor',
  fontWeight: '$normal',
  color: '$bodyDark2',

  '&:focus': {
    outline: '$ocean solid 1.5px',
  },

  variants: {
    isSelected: {
      true: {
        '&:focus': {
          $$optionBackgroundColor: '$colors-oceanLight1',
          outline: 'none',
        },
      },
    },
    isHovered: {
      true: {
        $$optionBackgroundColor: '$colors-bodyLight2',
      },
    },
    isPressed: {
      true: {
        $$optionBackgroundColor: '#EBF2F5 !important',
        '&:focus': {
          outline: 'none !important',
        },
      },
    },
    isDisabled: {
      true: {
        opacity: 0.5,
      },
    },
  },
  compoundVariants: [
    {
      isSelected: true,
      isHovered: true,
      css: {
        $$optionBackgroundColor: '$colors-oceanLight2 !important',
      },
    },
    {
      isSelected: true,
      isPressed: true,
      css: {
        '&:focus': {
          $$optionBackgroundColor: '#EBF2F5 !important',
        },
      },
    },
  ],
});

export type OptionsVariants = Stitches.VariantProps<typeof optionVariants>;
