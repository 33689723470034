import { useState, useLayoutEffect } from 'react';

import { IUseFetchWorkTypesResult } from 'stores/hooks/query-hooks/use-query-fetch-work-types-per-category';
import { WorkTypeState } from 'utilities/enum-utils';
import { IWorkLeaveType } from 'interfaces/account-interfaces';

export type WorkLeaveTypesTableDataSource = IWorkLeaveType[][number] & { key: string; index: number };

type WorkLeaveTypeVisibleStates = {
  [key in WorkTypeState]: boolean;
};

type IDataSourcesArray = {
  setData(data: IUseFetchWorkTypesResult['data']): void;
  filterVisible(selectedWorkLeaveTypeStates: WorkLeaveTypeVisibleStates): void;
  sortByName(): void;
  sortByState(): void;
};

class DataSourcesArray extends Array<WorkLeaveTypesTableDataSource> implements IDataSourcesArray {
  setData(data: IWorkLeaveType[] | undefined) {
    this.length = 0;
    this.push(...(data?.map((member, index: number) => ({ ...member, key: member.workTypeId, index })) ?? []));
  }

  filterVisible(selectedWorkLeaveTypeStates: WorkLeaveTypeVisibleStates | undefined) {
    if (selectedWorkLeaveTypeStates == null) {
      return;
    }

    const filtered = this.filter((dataSource) => selectedWorkLeaveTypeStates[WorkTypeState[dataSource.state]]);
    this.length = 0;
    this.push(...filtered);
  }

  sortByName() {
    this.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLocaleLowerCase()) {
        return 1;
      }

      if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
        return -1;
      }

      return 0;
    });
  }

  sortByState() {
    this.sort((a, b) => {
      const aState = WorkTypeState[a.state];
      const bState = WorkTypeState[b.state];

      if (aState === WorkTypeState.INACTIVE) {
        return 1;
      }

      if (bState === WorkTypeState.INACTIVE) {
        return -1;
      }

      return 0;
    });
  }

  sortByPriority() {
    this.sort((a, b) => {
      if (a.priority === 0) {
        return 1;
      }

      if (b.priority === 0) {
        return -1;
      }

      return a.priority - b.priority;
    });
  }
}

function useDataSources(
  data: IUseFetchWorkTypesResult['data'],
  selectedWorkLeaveTypeStates: WorkLeaveTypeVisibleStates,
): WorkLeaveTypesTableDataSource[] {
  const [dataSources, setDataSources] = useState<WorkLeaveTypesTableDataSource[]>([]);

  useLayoutEffect(() => {
    const dataSources = new DataSourcesArray();
    dataSources.setData(data);
    dataSources.filterVisible(selectedWorkLeaveTypeStates);
    dataSources.sortByName();
    dataSources.sortByPriority();
    dataSources.sortByState();
    setDataSources(dataSources);
  }, [data, selectedWorkLeaveTypeStates]);

  return dataSources;
}

export default useDataSources;
