import React, { Component } from 'react';
import _ from 'lodash';
import { ACTIONS, AVAILABLE_ACTIONS } from './actions';
import { PrimaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';

export const ActionButton = (props) => (
  <PrimaryButton {...props} className="bordered border-white mr-medium text-weight-bold" color="blue" size="large" />
);

export class SingleActionHandler extends Component<{
  statusKeys: any[];
  onTriggerAction: any;
  bookingsCanRemoveTeamMember: any;
}> {
  _handleAction = (action) => {
    const { onTriggerAction, statusKeys } = this.props;

    const status = _.split(statusKeys[0], '__')[0];

    onTriggerAction({ status, action });
  };

  render() {
    const { statusKeys, bookingsCanRemoveTeamMember } = this.props;

    if (_.isEmpty(statusKeys)) {
      return (
        <div>
          <Text color="white">No actions available.</Text>
        </div>
      );
    }

    const targetActions = [...(AVAILABLE_ACTIONS[statusKeys[0]] || [])];

    if (_.isEmpty(targetActions)) return <Text color="white">No actions available.</Text>;

    const filterStatusBookingsCanRemoveTeamMember = _.filter(
      bookingsCanRemoveTeamMember,
      (booking) => booking.status === statusKeys[0],
    );

    if (_.isEmpty(filterStatusBookingsCanRemoveTeamMember) && targetActions.includes('REMOVE_TEAM_MEMBER'))
      _.remove(targetActions, (action) => action === 'REMOVE_TEAM_MEMBER');

    const actionsButtons = _.map(targetActions, (actionType) => {
      const action = ACTIONS[actionType];

      if (_.isEmpty(action)) return <></>;
      return (
        <ActionButton onClick={() => this._handleAction(action.key)} key={action.key}>
          {/* Action label */}
          {action.label}
        </ActionButton>
      );
    });

    return <div className="flex-row">{actionsButtons}</div>;
  }
}
