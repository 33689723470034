/* eslint-disable */
import React, { Component } from 'react';
import { Col, Divider, Row } from 'antd';
import { Text, Title } from 'common-components/typography';
import { PrimaryButton, GhostButton } from 'common-components/buttons';

interface IServDetailsHeaderProps {
  title: string;
  subtitle?: string;
  editAction?: () => void;
  hasEditButton?: boolean;
  hasDivider?: boolean;
  editButtonTitle?: string;
  hasCancelButton?: boolean;
  cancelAction?: () => void;
  displayPrimaryButtonIcon?: boolean;
}

export class ServDetailsSectionHeader extends Component<IServDetailsHeaderProps, any> {
  render() {
    const {
      subtitle,
      title,
      editAction,
      hasEditButton,
      hasCancelButton = false,
      cancelAction,
      hasDivider = true,
      editButtonTitle = 'Edit information',
      displayPrimaryButtonIcon = true,
    } = this.props;

    return (
      <div>
        <Row type={'flex'} align={'middle'} className="justify-between">
          <Col>
            <Title level={3} className={'mt-none mb-none'}>
              {title}
            </Title>
            <Text className="pt-x-small text-color-secondary">{subtitle}</Text>
          </Col>
          {hasEditButton && (
            <Col span={6} className="text-align-right pr-large">
              <div className="flex-row justify-end">
                {hasCancelButton && <GhostButton onClick={cancelAction}>Cancel</GhostButton>}
                <PrimaryButton icon={displayPrimaryButtonIcon ? 'edit' : null} onClick={editAction}>
                  {editButtonTitle}
                </PrimaryButton>
              </div>
            </Col>
          )}
        </Row>

        {hasDivider && <Divider className={'divider-large'} />}
        {!hasDivider && <div className="mv-large" />}
      </div>
    );
  }
}
