import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Share = forwardRef(function Share(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.325 4.25A3.751 3.751 0 0 1 21.75 5a3.75 3.75 0 0 1-7.425.75H9a3.251 3.251 0 0 0-3.183 2.59 3.751 3.751 0 0 1 0 7.32A3.251 3.251 0 0 0 9 18.25h5.325a3.751 3.751 0 0 1 7.425.75 3.75 3.75 0 0 1-7.425.75H9a4.751 4.751 0 0 1-4.7-4.065 3.751 3.751 0 0 1 0-7.37A4.751 4.751 0 0 1 9 4.25h5.325ZM18 2.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm-13 7a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM15.75 19a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
