import { Text } from 'common-components/typography';
import React from 'react';
import { IChannel } from 'interfaces/message-interfaces';
import { Icon } from 'antd';

import { FaHashtag } from 'react-icons/fa';

export function MessagesHeader({ channel }: { channel: IChannel }) {
  const { channelName, serviceName, lastMessagedOn, subscribers, unreadCount } = channel;

  return (
    <div
      className="pv-medium ph-medium bordered-bottom border-secondary flex-row justify-between align-end select-none"
      style={{ height: '72px' }}
    >
      <div>
        <div className="flex-row align-center">
          <Text size="large" weight="bold" lineHeight={100}>
            {channelName}
          </Text>
          {/* TODO : More Profile Options */}
          {/*<Icon type="down" className="text-size-x-small pl-small" />*/}
        </div>

        <Text size="small" color="secondary" lineHeight={100}>
          <span>
            <Icon type={'user'} className="text-size-small" /> {subscribers.length}
          </span>

          <span className="mh-x-small text-color-tertiary dimmer"> | </span>

          <span>
            <FaHashtag className="text-size-small text-color-secondary mr-x2-small" />
            {`${serviceName}`}
          </span>

          {/*<span className="mh-x-small text-color-tertiary dimmer"> | </span>*/}

          {/*<span>*/}
          {/*  <Icon type={'message'} /> unread {unreadCount}*/}
          {/*</span>*/}
        </Text>
      </div>

      {/*<Text color="blue" size="regular">*/}
      {/*  /!*View Profile*!/*/}
      {/*  View Users*/}
      {/*</Text>*/}
    </div>
  );
}
