import { Icon, Row, Tooltip } from 'antd';
import { Text } from 'common-components/typography';
import moment from 'moment';
import React from 'react';
import { IBatchLineItem } from 'interfaces/booking-interfaces';
import { ProcessedPaymentAction } from 'utilities/enum-utils';

interface IPopoverContentProps {
  count: number;
  lineItem?: IBatchLineItem;
  type: keyof typeof ProcessedPaymentAction;
}

const PopoverContent = ({ count, lineItem, type }: IPopoverContentProps) => {
  const commonTextLineItem: string = lineItem
    ? `${lineItem.userActionFirstName} ${lineItem.userActionLastName} on ${moment(lineItem.createdActionOn).format(
        'DD/MM/YYYY',
      )}`
    : '';

  let data: {
    typeIcon: string;
    classNameIcon: string;
    contentLineItem: string;
    content: string;
  };

  switch (type) {
    case ProcessedPaymentAction.REJECTED:
      data = {
        typeIcon: 'close-circle',
        classNameIcon: 'text-color-red-light mr-x-small',
        contentLineItem: 'Line item rejected by ',
        content: 'rejected line items to be resolved',
      };
      break;
    case ProcessedPaymentAction.WAIVED:
      data = {
        typeIcon: 'info-circle',
        classNameIcon: 'text-color-secondary mr-x-small',
        contentLineItem: 'Line item waived by ',
        content: 'rejected line items waived',
      };
      break;
    case ProcessedPaymentAction.REAPPROVED:
      data = {
        typeIcon: 'check-circle',
        classNameIcon: 'text-color-green mr-x-small',
        contentLineItem: 'Line item re-approved by ',
        content: 'rejected line items re-approved',
      };
      break;
    default:
      break;
  }

  return (
    <Row className="pb-medium">
      <Icon
        type={data.typeIcon}
        theme={type === ProcessedPaymentAction.REJECTED ? 'filled' : undefined}
        className={data.classNameIcon}
      />
      {lineItem ? (
        <>
          <Text>{data.contentLineItem}</Text>
          <Text weight="bold">{commonTextLineItem}</Text>
        </>
      ) : (
        <>
          <Text weight="bold">{count} </Text>
          <Text>{data.content}</Text>
        </>
      )}
    </Row>
  );
};

interface IInvoiceErrorIndicatorProps {
  errorCounts: {
    rejectedCount: number;
    waivedCount: number;
    reapprovedCount: number;
  };
  lineItem?: IBatchLineItem;
}

const InvoiceErrorIndicator = ({
  errorCounts: { rejectedCount = 0, waivedCount = 0, reapprovedCount = 0 },
  lineItem,
}: IInvoiceErrorIndicatorProps) => {
  const popoverContent = (
    <div className="ph-medium pt-medium" style={{ backgroundColor: '#ffffff', borderRadius: '8px' }}>
      {rejectedCount > 0 && (
        <PopoverContent count={rejectedCount} lineItem={lineItem} type={ProcessedPaymentAction.REJECTED} />
      )}
      {waivedCount > 0 && (
        <PopoverContent count={waivedCount} lineItem={lineItem} type={ProcessedPaymentAction.WAIVED} />
      )}
      {reapprovedCount > 0 && (
        <PopoverContent count={reapprovedCount} lineItem={lineItem} type={ProcessedPaymentAction.REAPPROVED} />
      )}
    </div>
  );

  const type = rejectedCount ? 'close-circle' : waivedCount ? 'info-circle' : reapprovedCount ? 'check-circle' : null;
  const color = rejectedCount
    ? 'text-color-red-light'
    : waivedCount
    ? 'text-color-secondary'
    : reapprovedCount
    ? 'text-color-green'
    : '';
  const theme = rejectedCount ? 'filled' : undefined;

  return (
    type && (
      <Tooltip title={popoverContent} placement="topRight" overlayStyle={{ maxWidth: 275 }}>
        <Icon type={type} theme={theme} className={`${color} ml-12`} />
      </Tooltip>
    )
  );
};

export default InvoiceErrorIndicator;
