import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ShoppingBag2 = forwardRef(function ShoppingBag2(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 5a2.25 2.25 0 1 1 4.5 0v1.25h-4.5V5Zm-1.5 2.756V9a.75.75 0 1 0 1.5 0V7.75h4.5V9a.75.75 0 0 0 1.5 0V7.756c.626.01 1.13.032 1.555.087.683.09 1.055.252 1.327.504.273.251.465.608.608 1.282.148.696.224 1.62.33 2.95l.162 2c.123 1.54.208 2.615.161 3.43-.045.794-.211 1.214-.49 1.517-.28.303-.685.502-1.473.61-.808.112-1.888.114-3.431.114H9.5c-1.543 0-2.623-.002-3.431-.114-.788-.108-1.193-.307-1.473-.61-.279-.303-.445-.723-.49-1.517-.047-.815.038-1.89.161-3.43l.161-2c.107-1.33.183-2.254.33-2.95.144-.674.336-1.03.609-1.282.272-.252.643-.415 1.327-.504.424-.055.93-.078 1.555-.087Zm0-1.5V5a3.75 3.75 0 0 1 7.5 0v1.256c.668.01 1.248.035 1.748.1.846.11 1.562.344 2.152.888.589.544.88 1.24 1.058 2.074.17.802.252 1.824.354 3.09l.004.051.16 2 .005.058c.118 1.468.213 2.648.16 3.578-.055.965-.274 1.785-.886 2.448s-1.412.947-2.369 1.08c-.924.127-2.107.127-3.58.127H9.444c-1.473 0-2.656 0-3.58-.128-.957-.132-1.757-.416-2.37-1.079-.61-.663-.83-1.483-.885-2.448-.053-.93.042-2.11.16-3.578l.004-.058.16-2 .005-.052c.102-1.265.184-2.287.354-3.09.178-.834.47-1.529 1.058-2.073.59-.544 1.306-.778 2.152-.888.5-.065 1.08-.09 1.748-.1Z"
        fill="currentColor"
      />
    </Icon>
  );
});
