import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ForwardAll = forwardRef(function ForwardAll(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.53 4.47a.75.75 0 1 0-1.06 1.06l3.72 3.72h-5.232c-1.369 0-2.454 0-3.32.088-.888.09-1.629.28-2.277.713a4.751 4.751 0 0 0-1.31 1.31c-.434.648-.623 1.39-.713 2.277-.088.866-.088 1.951-.088 3.32V17h1.5c0-1.42 0-2.429.08-3.21.079-.77.227-1.235.468-1.596a3.25 3.25 0 0 1 .896-.896c.361-.241.827-.39 1.596-.468.781-.08 1.79-.08 3.21-.08h5.19l-3.72 3.72a.75.75 0 1 0 1.06 1.06l5-5a.75.75 0 0 0 0-1.06l-5-5Zm4 0a.75.75 0 1 0-1.06 1.06L20.94 10l-4.47 4.47a.75.75 0 1 0 1.06 1.06l5-5a.75.75 0 0 0 0-1.06l-5-5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
