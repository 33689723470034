import React, { Component } from 'react';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import { FullScreenModalFooter } from 'common-components/modal/FullScreenModal';
import { Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import Steps from 'common-components/steps/Steps';

import { times } from 'lodash';
import InputInfoPanel from 'views/customers/details/tabs-panel/new-service-agreements/create-agreement/panels/InputInfoPanel';
import InputTermsPanel from 'views/customers/details/tabs-panel/new-service-agreements/create-agreement/panels/InputTermsPanel';
import InputServiceItemsPanel from 'views/customers/details/tabs-panel/new-service-agreements/create-agreement/panels/InputServiceItemsPanel';
import AgreementSummaryPanel from 'views/customers/details/tabs-panel/new-service-agreements/create-agreement/panels/AgreementSummaryPanel';
import CompletedAgreementPanel from 'views/customers/details/tabs-panel/new-service-agreements/create-agreement/panels/CompletedAgreementPanel';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import QuotesItemPanel from 'views/customers/details/tabs-panel/new-service-agreements/create-agreement/panels/QuotesItemPanel';

interface ICreateAgreementModalProps {
  onClose: any;
  isOpen: boolean;
  setNewServiceAgreement: typeof dispatch.customersStore.setNewServiceAgreement;
}

interface ICreateAgreementModalState {
  currentStep: number;
  totalSteps: number;
}

class CreateAgreementModal extends Component<ICreateAgreementModalProps, ICreateAgreementModalState> {
  state = { currentStep: 1, totalSteps: 6 };

  private _onNextStep = () => this.setState({ currentStep: this.state.currentStep + 1 });
  private _onPreviousStep = () => this.setState({ currentStep: this.state.currentStep - 1 });

  private _closeModal = () => {
    const { onClose } = this.props;

    // reset steps
    this.props.setNewServiceAgreement({});
    this.setState({ currentStep: 1 }, () => {
      onClose();
    });
  };

  private _renderStepContent = () => {
    if (this.state.currentStep === 1) {
      return <InputInfoPanel onNextStep={this._onNextStep} onPrevStep={this._closeModal} />;
    } else if (this.state.currentStep === 2) {
      return <InputServiceItemsPanel onNextStep={this._onNextStep} onPrevStep={this._onPreviousStep} />;
    } else if (this.state.currentStep === 3) {
      return <QuotesItemPanel onNextStep={this._onNextStep} onPrevStep={this._onPreviousStep} />;
    } else if (this.state.currentStep === 4) {
      return <InputTermsPanel onNextStep={this._onNextStep} onPrevStep={this._onPreviousStep} />;
    } else if (this.state.currentStep === 5) {
      return <AgreementSummaryPanel onNextStep={this._onNextStep} onPrevStep={this._onPreviousStep} />;
    } else if (this.state.currentStep === 6) {
      return <CompletedAgreementPanel onClose={this._closeModal} />;
    }
  };

  render() {
    const { isOpen } = this.props;

    const steps = times(this.state.totalSteps, (i) => ({ stepNumber: i + 1, stepLabel: i + 1 }));

    let bottomText = 'Service Agreement dates';

    if (this.state.currentStep === 2) {
      bottomText = 'Add schedule of support';
    } else if (this.state.currentStep === 3) {
      bottomText = 'Quotes creation';
    } else if (this.state.currentStep === 4) {
      bottomText = 'Add Terms and Conditions';
    } else if (this.state.currentStep === 5) {
      bottomText = 'Service Agreement summary';
    } else if (this.state.currentStep === 6) {
      bottomText = 'Done!';
    }

    const footerContent = (
      <FullScreenModalFooter align={'left'}>
        <Row gutter={0} type="flex" align="middle">
          <Col span={8} />
          <Col span={8}>
            <div className="justify-center flex-row">
              <Text size="regular" className="mb-medium">
                <span className="mr-medium text-weight-bold">
                  Step {this.state.currentStep} of {this.state.totalSteps}
                </span>
                <span className="">{bottomText}</span>
              </Text>
            </div>
            <Steps currentStep={this.state.currentStep} steps={steps} />
          </Col>
          <Col span={8}>{/* Nothing here.*/}</Col>
        </Row>
      </FullScreenModalFooter>
    );

    return (
      <FullScreenScrollableModal
        isOpen={isOpen}
        onClose={this._closeModal}
        hasFooter={true}
        footerContent={footerContent}
        width={'large'}
      >
        {this._renderStepContent()}
      </FullScreenScrollableModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewServiceAgreement: dispatch.customersStore.setNewServiceAgreement,
});

export default connect(null, mapDispatch)(CreateAgreementModal);
