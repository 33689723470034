// Text Message: My Message and Received Message
import { MyMessage } from 'views/messaging/messages/components/tags/MyMessage';
import { ReceivedMessage } from 'views/messaging/messages/components/tags/ReceivedMessage';
import React from 'react';
import { IExtendedMessage } from 'interfaces/message-interfaces';

export function TextMessage({ message, deleteMessage }: { message: IExtendedMessage; deleteMessage: any }) {
  const { currentUserId, authorUserId } = message;

  const isMyMessage = currentUserId === authorUserId;

  const RenderComponent = isMyMessage ? MyMessage : ReceivedMessage;

  return <RenderComponent message={message} deleteMessage={deleteMessage} />;
}
