// local helper components
import { Empty, Icon } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';

export function FetchMoreIndicator() {
  return (
    <div className="absolute flex-row justify-center anim-fade-in-fast" style={{ width: '100%', left: 0, top: '88px' }}>
      <div
        className="inline-block pv-x-small ph-small bordered rounded border-secondary"
        style={{ backgroundColor: 'rgba(255,255,255,0.95)' }}
      >
        <Icon type="loading" className="text-color-blue-lighter text-size-small mr-small" />

        <Text size="small" color="tertiary">
          Fetching more messages...
        </Text>
      </div>
    </div>
  );
}

export function LoadingIndicator() {
  return (
    <div className="absolute flex-row justify-center anim-fade-in-fast" style={{ width: '100%', left: 0, top: '88px' }}>
      <div
        className="inline-block pv-x-small ph-small bordered rounded border-secondary"
        style={{ backgroundColor: 'rgba(255,255,255,0.95)' }}
      >
        <Icon type="loading" className="text-color-blue-lighter text-size-small mr-small" />

        <Text size="small" color="tertiary">
          Loading conversation...
        </Text>
      </div>
    </div>
  );
}

export function CreateChannelIndicator() {
  return (
    <div
      className="absolute flex-row justify-center anim-slide-down"
      style={{ width: '100%', left: 0, top: '88px', zIndex: 999 }}
    >
      <div
        className="inline-block pv-small ph-medium bordered rounded border-secondary"
        style={{ backgroundColor: 'rgba(255,255,255,1)' }}
      >
        <Icon type="loading" className="text-color-blue-lighter text-size-small mr-small" />

        <Text size="large" color="secondary">
          Creating new conversation...
        </Text>
      </div>
    </div>
  );
}

export function NewMessagesIndicator({ unreadCount, onClick }) {
  return (
    <div
      className="absolute flex-row justify-center anim-slide-down"
      style={{ width: '100%', left: 0, bottom: '120px', zIndex: 999 }}
      onClick={onClick}
    >
      <div
        className="inline-block pv-x-small ph-medium rounded-full bg-blue-light flex-row cursor-pointer dim hover-undim"
        onClick={onClick}
      >
        <Icon type="mail" className="text-size-regular text-color-white mr-small" />

        <Text size="regular" color="white">
          <b>{unreadCount}</b> new message(s) received
        </Text>

        <Icon type="down-circle" className="text-size-regular text-color-white ml-small" />
      </div>
    </div>
  );
}

function LoadingState() {
  return (
    <div className="flex-1 align-center justify-center flex-column bg-tertiary anim-slide-down">
      <Icon type={'loading'} className="text-size-x-large text-color-tertiary" />
      <Text color="tertiary" size="large" className="pt-medium" weight="bold">
        Loading messages
      </Text>
      <Text size={'regular'} color="tertiary">
        One moment...
      </Text>
    </div>
  );
}

export function EmptyMessages() {
  return (
    <div className="flex-1 align-center justify-center flex-column bg-tertiary anim-fade-in">
      <Empty
        description={
          <>
            <Text size="x3-large" weight="light" color={'tertiary'}>
              Stay connected with your people.
            </Text>
            <br />
            <Text color="tertiary" weight="regular" size="regular">
              Select a conversation on the left to begin.
            </Text>
            <br />
          </>
        }
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    </div>
  );
}
