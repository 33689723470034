import React, { Component } from 'react';
import {
  capitalize,
  cloneDeep,
  filter,
  find,
  findIndex,
  forEach,
  includes,
  isArray,
  isEmpty,
  join,
  map,
  orderBy,
  reduce,
  sortBy,
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Col, DatePicker, Form, Input, notification, Row, Select, Tag, Checkbox } from 'antd';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import SubSectionHeaderText from 'common-components/typography/SubSectionHeaderText';
import { FieldLabel, FieldValueText, Text, Title } from 'common-components/typography';
import { GhostButton, PrimaryButton, HyperlinkButton } from 'common-components/buttons';
import moment from 'moment-timezone';
import { tagColors } from 'theme/theme-variables';
import { Icon, Radio } from 'antd/es';
import { FormComponentProps } from 'antd/es/form';
import CreateEditAddressModal from './CreateEditAddressModal';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import Map from './Map';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import FormCol from 'common-components/forms/FormCol';
import TeamContactDetailRow from './TeamContactDetailRow';
import RadioGroup from 'antd/lib/radio/group';
import { LocationDisplay } from 'common-components/locations';
import CommonUtils from 'utilities/common-utils';
import TeamAlertTag from '../../components/TeamAlert';

import hobbies from 'variables/data/hobbies.json';
import qualifications from 'variables/data/qualifications.json';
import religions from 'variables/data/religion.json';
import skills from 'variables/data/skills.json';
import { Flag } from 'common-components/utils/launchdarkly/flag';
import { useFetchTagsLanguages } from 'stores/hooks/query-hooks/use-query-fetch-tags';
import { Spinner } from '@blueprintjs/core';
import { Stack } from '@goodhuman-me/components';
import { EditEmail } from './edit-email';

const Option = Select.Option;
const { TextArea } = Input;

interface ITeamDetailsGeneralPanelProps extends FormComponentProps {
  selectedWorkerItem: any;
  hasEditPermission: boolean;
  onAlertTagView: any;
  companyDataLite: typeof state.companyStore.companyDataLite;
  doUpdateTeamProfile: typeof dispatch.teamStore.doUpdateTeamProfile;
  doFetchDisabilityList: typeof dispatch.customersStore.doFetchDisabilityList;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  doFetchSelectedWorker: typeof dispatch.teamStore.doFetchSelectedWorker;
  languagesQueryData: Array<any>;
  isLoadingLanguages: boolean;
}

interface ITeamDetailsGeneralPanelState {
  lattitude: any;
  longitude: any;
  selectedAddressIndex: number;
  isEdit: boolean;
  selectedTransport: string;
  isopen: boolean;
  isCreateNewAddress: boolean;
  isSaving: boolean;
  workerAddress: any;
  contacts: any;
  isActionModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isDeleteAddressModalOpen: boolean;
  rowID: string;
  addressIndex: number;
  specialities: Array<any>;
}

class TeamDetailsGeneralPanel extends Component<ITeamDetailsGeneralPanelProps, ITeamDetailsGeneralPanelState> {
  private teamContactInputRefs: any[] = [];

  state = {
    lattitude: 0,
    longitude: 0,
    selectedAddressIndex: -1,
    isEdit: false,
    selectedTransport: '',
    isopen: false,
    isCreateNewAddress: false,
    isActionModalOpen: false,
    isDeleteModalOpen: false,
    isDeleteAddressModalOpen: false,
    rowID: '',
    addressIndex: -1,
    isSaving: false,
    workerAddress: [],
    contacts: [],
    specialities: [],
  };

  private _deleteAddress = () => {
    const { addressIndex } = this.state;
    const workerAddress = [...this.state.workerAddress];
    if (addressIndex !== -1) {
      workerAddress.splice(addressIndex, 1);
      this.setState({ workerAddress, isDeleteAddressModalOpen: false });
    }
  };

  private _changePrimaryAddress = (addressIndex) => {
    const { workerAddress } = this.state;

    const newWorkerAddress = map(workerAddress, (address, index) => {
      if (index === addressIndex) return { ...address, isPrimary: true };
      else return { ...address, isPrimary: false };
    });
    this.setState({ workerAddress: newWorkerAddress, selectedAddressIndex: 0 });
  };

  private _saveWorkerAddress = async (addressLocation) => {
    let workerAddressObj;

    if (addressLocation.isManual) {
      const fulladdress =
        addressLocation.streetaddress +
        ' ' +
        addressLocation.streetaddress2 +
        ' , ' +
        addressLocation.suburb +
        ' , ' +
        addressLocation.state +
        ' , ' +
        addressLocation.country;
      const latlong = await CommonUtils.getLatLongByAddress(fulladdress);
      workerAddressObj = {
        state: addressLocation.state,
        geoLat: Number(latlong.lat),
        geoLng: Number(latlong.lng),
        country: addressLocation.country,
        locality: addressLocation.suburb,
        postcode: addressLocation.postcode,
        isPrimary: addressLocation.isPrimary !== undefined ? addressLocation.isPrimary : false,
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1: addressLocation.streetaddress,
          streetAddress2: addressLocation.streetaddress2,
          locality: addressLocation.suburb,
          state: addressLocation.state,
          postcode: addressLocation.postcode,
          country: addressLocation.country,
        }),
        streetAddress1: addressLocation.streetaddress,
        streetAddress2: addressLocation.streetaddress2,
      };
    } else {
      const postal_code = find(addressLocation.address_components, (address) => {
        return address.types[0] === 'postal_code';
      });
      const country = find(addressLocation.address_components, (address) => {
        return address.types[0] === 'country';
      });
      const province = find(addressLocation.address_components, (address) => {
        return address.types[0] === 'administrative_area_level_1';
      });
      const locality = find(addressLocation.address_components, (address) => {
        return address.types[0] === 'locality';
      });
      const street_number = find(addressLocation.address_components, (address) => {
        return address.types[0] === 'street_number';
      });
      const route = find(addressLocation.address_components, (address) => {
        return address.types[0] === 'route';
      });
      const subpremise = find(addressLocation.address_components, (address) => {
        return address.types[0] === 'subpremise';
      });

      let streetAddress1 = '';
      if (route) {
        const streetName = route.long_name;
        const streetNumber = street_number?.long_name || '';
        const streetSubPremise = subpremise ? `${subpremise.long_name.replace(/\D/g, '')}/` : '';

        streetAddress1 = `${streetSubPremise}${streetNumber} ${streetName}`;
      }

      workerAddressObj = {
        state: province !== undefined ? province.short_name : '',
        geoLat: Number(addressLocation.selectedLattitude),
        geoLng: Number(addressLocation.selectedLongitude),
        country: country !== undefined ? country.long_name : '',
        locality: locality !== undefined ? locality.long_name : '',
        postcode: postal_code !== undefined ? postal_code.long_name : '',
        isPrimary: addressLocation.isPrimary !== undefined ? addressLocation.isPrimary : false,
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1,
          streetAddress2: '',
          locality: locality !== undefined ? locality.long_name : '',
          state: province !== undefined ? province.short_name : '',
          postcode: postal_code !== undefined ? postal_code.long_name : '',
          country: country !== undefined ? country.long_name : '',
        }),
        streetAddress1,
        streetAddress2: '',
      };
    }

    if (this.state.selectedAddressIndex >= 0) {
      if (workerAddressObj.isPrimary) {
        this.state.workerAddress.forEach((a) => {
          a.isPrimary = false;
        });
        let newWorkerAddress = this.state.workerAddress.slice();
        newWorkerAddress[this.state.selectedAddressIndex] = workerAddressObj;
        newWorkerAddress = orderBy(newWorkerAddress, 'isPrimary', 'desc');
        this.setState({ workerAddress: newWorkerAddress, selectedAddressIndex: -1 });
      } else {
        let newWorkerAddress = this.state.workerAddress.slice();
        newWorkerAddress[this.state.selectedAddressIndex] = workerAddressObj;
        newWorkerAddress = orderBy(newWorkerAddress, 'isPrimary', 'desc');
        this.setState({ workerAddress: newWorkerAddress });
      }
    } else {
      let joined = this.state.workerAddress.concat(workerAddressObj);
      if (workerAddressObj.isPrimary) {
        this.state.workerAddress.forEach((a) => {
          a.isPrimary = false;
        });
        joined = orderBy(joined, 'isPrimary', 'desc');
        this.setState({ workerAddress: joined, selectedAddressIndex: -1 });
      } else {
        joined = orderBy(joined, 'isPrimary', 'desc');
        this.setState({ workerAddress: joined, selectedAddressIndex: joined.length - 1 });
      }
    }
  };

  private _onClickSave = async () => {
    const { form, doUpdateTeamProfile, selectedWorkerItem, doFetchSelectedWorker } = this.props;

    let isFormValid = true;

    form.validateFieldsAndScroll(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    // validate line items tab
    if (this.teamContactInputRefs.length > 0) {
      forEach(this.teamContactInputRefs, (teamContactInputRef) => {
        teamContactInputRef.props.form.validateFields((err) => {
          if (err) {
            isFormValid = false;
          }
        });
      });
    }

    // check address, make sure all address have required field.
    const failedAddress = filter(
      this.state.workerAddress,
      (address) =>
        !address.streetAddress1 || !address.state || !address.postcode || !address.fullAddress || !address.locality,
    );

    if (!isEmpty(failedAddress)) {
      isFormValid = false;
      notification.error({
        message: 'Empty Address',
        description: 'Seems there is empty address, please edit or delete it.',
      });
    }

    if (isFormValid) {
      this.setState({ workerAddress: orderBy(this.state.workerAddress, 'isPrimary', 'desc') });
        const dob = form.getFieldValue('dateOfBirth')
          ? moment(form.getFieldValue('dateOfBirth')).format('YYYY-MM-DD')
          : undefined;
        const startDate = form.getFieldValue('startDate')
          ? moment(form.getFieldValue('startDate')).toISOString()
          : undefined;
        const endDate = form.getFieldValue('endDate') ? moment(form.getFieldValue('endDate')).toISOString() : undefined;
        const payload = {
          firstName: form.getFieldValue('employeeFirstName'),
          lastName: form.getFieldValue('employeeLastName'),
          employeeNumber: form.getFieldValue('employeeNumber'),
          aboutMe: form.getFieldValue('aboutMe') ? form.getFieldValue('aboutMe') : undefined,
          dateOfBirth: dob,
          startDate,
          endDate,
          gender: form.getFieldValue('gender') ? form.getFieldValue('gender') : undefined,
          contactNumbers: this.state.contacts,
          addresses: this.state.workerAddress,
          hobbies: form.getFieldValue('hobbies'),
          qualifications: form.getFieldValue('qualifications'),
          specialities: form.getFieldValue('specialities'),
          religions: form.getFieldValue('religions') ? [form.getFieldValue('religions')] : [],
          languages: form.getFieldValue('languages'),
          skills: form.getFieldValue('skills'),
          email: form.getFieldValue('email'),
          roles: form.getFieldValue('roles') || undefined,
          roleOther: form.getFieldValue('roleOther') || undefined,
          employmentType: form.getFieldValue('employmentType') || undefined,
          employmentTypeOther: form.getFieldValue('employmentTypeOther') || undefined,
        };
        this.setState({ isSaving: true });
        try {
          await doUpdateTeamProfile(payload);
          await doFetchSelectedWorker({ supportWorkerId: selectedWorkerItem.supportWorkerId });
          this.setState({
            isSaving: false,
            isEdit: false,
            contacts: orderBy(this.state.contacts, ['preferredContact', 'numberType', 'number'], 'desc'),
          });
        } catch (e) {
          notification.error({ message: 'Error', description: e.message });
          this.setState({ isSaving: false });
        }
    }
  };

  private _closeDeleteModal = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  private _closeDeleteAddressModal = () => {
    this.setState({ isDeleteAddressModalOpen: false });
  };

  private _closeActionModal = () => {
    this.setState({ isActionModalOpen: false });
  };

  private _proceedDiscardChanges = () => {
    const { selectedWorkerItem } = this.props;
    this.setState({
      isActionModalOpen: false,
      isEdit: false,
      contacts: orderBy(selectedWorkerItem.contactNumbers, ['preferredContact'], 'desc'),
    });
  };

  private _closeWithActionModal = () => {
    const { form, selectedWorkerItem } = this.props;
    const aboutMe = form.getFieldValue('aboutMe');
    const dateOfBirth = form.getFieldValue('dateOfBirth');
    const email = form.getFieldValue('email');
    const languages = form.getFieldValue('languages');
    const religions = form.getFieldValue('religions') ? [form.getFieldValue('religions')] : [];
    const hobbies = form.getFieldValue('hobbies');
    const qualifications = form.getFieldValue('qualifications');
    const gender = form.getFieldValue('gender');
    const specialities = form.getFieldValue('specialities');
    const skills = form.getFieldValue('skills');
    const employeeNumber = form.getFieldValue('employeeNumber');
    const contactNumbers = this.state.contacts;
    const addresses = orderBy(this.state.workerAddress, 'isPrimary', 'desc');

    const cklang =
      selectedWorkerItem.languages.length === languages.length &&
      selectedWorkerItem.languages.sort().every(function (value, index) {
        return value === languages.sort()[index];
      });

    const chkreligions =
      selectedWorkerItem.religions.length === religions.length &&
      selectedWorkerItem.religions.sort().every(function (value, index) {
        return value === religions.sort()[index];
      });

    const chkhobbies =
      selectedWorkerItem.hobbies.length === hobbies.length &&
      selectedWorkerItem.hobbies.sort().every(function (value, index) {
        return value === hobbies.sort()[index];
      });

    const chkqualifications =
      selectedWorkerItem.qualifications.length === qualifications.length &&
      selectedWorkerItem.qualifications.sort().every(function (value, index) {
        return value === qualifications.sort()[index];
      });

    const chkspecialities =
      selectedWorkerItem.specialities.length === specialities.length &&
      selectedWorkerItem.specialities.sort().every(function (value, index) {
        return value === specialities.sort()[index];
      });

    const chkskills =
      selectedWorkerItem.skills.length === skills.length &&
      selectedWorkerItem.skills.sort().every(function (value, index) {
        return value === skills.sort()[index];
      });

    const chkcontacts =
      selectedWorkerItem.contactNumbers.length === contactNumbers.length &&
      selectedWorkerItem.contactNumbers.sort().every(function (value, index) {
        return value === contactNumbers.sort()[index];
      });
    const chkAddresses =
      selectedWorkerItem.addresses.length === addresses.length &&
      selectedWorkerItem.addresses.sort().every(function (value, index) {
        return value.fullAddress === addresses.sort()[index].fullAddress;
      });

    const chkdate = moment(selectedWorkerItem.dateOfBirth, 'YYYY-MM-DD').isSame(moment(dateOfBirth, 'YYYY-MM-DD'));

    if (
      this.state.isEdit &&
      (selectedWorkerItem.employeeNumber !== employeeNumber ||
        selectedWorkerItem.aboutMe !== aboutMe ||
        !chkdate ||
        selectedWorkerItem.email !== email ||
        !cklang ||
        !chkreligions ||
        !chkhobbies ||
        !chkqualifications ||
        !chkspecialities ||
        !chkcontacts ||
        !chkskills ||
        !chkAddresses ||
        selectedWorkerItem.gender !== gender)
    ) {
      this.setState({ isActionModalOpen: true });
    } else {
      this.setState({ isActionModalOpen: false, isEdit: false });
    }
  };

  private _closeCreateEditAddressModal = () => {
    this.setState({ isopen: false, isCreateNewAddress: true });
  };

  private _openCreateEditAddressModal = (addressId) => {
    if (addressId >= 0) {
      this.setState({ isCreateNewAddress: false, selectedAddressIndex: addressId, isopen: true });
    } else {
      this.setState({ isCreateNewAddress: true, selectedAddressIndex: -1, isopen: true });
    }
  };

  private _validateFirstNameInput = (rule, value, callback) => {
    try {
      if (isEmpty(value)) {
        throw Error('First Name is required');
      } else {
        callback();
      }
    } catch (e) {
      callback(e);
    }
  };

  private _validateLastNameInput = (rule, value, callback) => {
    try {
      if (isEmpty(value)) {
        throw Error('Last Name is required');
      } else {
        callback();
      }
    } catch (e) {
      callback(e);
    }
  };

  private _validateRoleOtherInput = (rule, value, callback) => {
    try {
      if (isEmpty(value)) {
        throw Error('Please enter a description');
      } else {
        callback();
      }
    } catch (e) {
      callback(e);
    }
  };

  private _validateEmploymentTypeOtherInput = (rule, value, callback) => {
    try {
      if (isEmpty(value)) {
        throw Error('Please enter a description');
      } else {
        callback();
      }
    } catch (e) {
      callback(e);
    }
  };

  private _renderHobbies = () => {
    const { selectedWorkerItem } = this.props;

    const list = map(selectedWorkerItem.hobbies, (tag) => {
      return hobbies.find((item) => item.tagId === tag);
    });

    return (
      <div>
        {map(list, (item) => {
          if (item && item.tagId) {
            return (
              <Tag color={tagColors.GREEN} className="mb-small" key={item.tagId}>
                <Text color="white" weight="bold" size="large">
                  {item.tag}
                </Text>
              </Tag>
            );
          }
        })}
      </div>
    );
  };

  private _renderQualifications = () => {
    const { selectedWorkerItem } = this.props;

    const list = map(selectedWorkerItem.qualifications, (tag) => {
      return qualifications.find((item) => item.tagId === tag);
    });

    return (
      <div>
        {map(list, (item) => {
          if (item && item.tagId) {
            return (
              <Tag color={tagColors.BLUE} className="mb-small" key={item.tagId}>
                <Text color="white" weight="bold" size="large">
                  {item.tag}
                </Text>
              </Tag>
            );
          }
        })}
      </div>
    );
  };

  private _renderSpecialities = () => {
    const { selectedWorkerItem } = this.props;
    const { specialities } = this.state;

    const list = sortBy(
      map(selectedWorkerItem.specialities, (tag) => {
        return specialities.find((item) => item.tagId === tag);
      }),
      'disabilityName',
    );

    return (
      <div>
        {map(list, (item) => {
          if (item && item.tagId) {
            return (
              <Tag color={tagColors.BLUE} className="mb-small" key={item.tagId}>
                <Text color="white" weight="bold" size="large">
                  {item.disabilityName}
                </Text>
              </Tag>
            );
          }
        })}
      </div>
    );
  };

  private renderSkills = () => {
    const { selectedWorkerItem } = this.props;

    const list = map(selectedWorkerItem.skills, (tag) => {
      return skills.find((item) => item.tagId === tag);
    });

    return (
      <div>
        {map(list, (item) => {
          if (item && item.tagId) {
            return (
              <Tag color={tagColors.GREEN} className="mb-small" key={item.tagId}>
                <Text color="white" weight="bold" size="large">
                  {item.tag}
                </Text>
              </Tag>
            );
          }
        })}
      </div>
    );
  };

  private _renderReligion = () => {
    const { selectedWorkerItem } = this.props;

    const list = map(selectedWorkerItem.religions, (tag) => {
      return religions.find((item) => item.tagId === tag);
    });

    return (
      <div>
        {map(list, (item) => {
          if (item && item.tagId) {
            return (
              <Tag color={tagColors.GOLD} className="mb-small" key={item.tagId}>
                <Text color="white" weight="bold" size="large">
                  {item.tag}
                </Text>
              </Tag>
            );
          }
        })}
      </div>
    );
  };

  private _setLatLong = (lattitude, longitude, index) => {
    this.setState({ lattitude, longitude, selectedAddressIndex: index });
  };

  private _renderAddresses = () => {
    if (this.state.workerAddress.length > 0) {
      return (
        <div>
          {map(this.state.workerAddress, (address, index) => {
            const selectedColor = this.state.selectedAddressIndex === index ? 'green' : null;
            return (
              <React.Fragment key={index}>
                <Row
                  onClick={() => this._setLatLong(address.geoLat, address.geoLng, index)}
                  className="pt-small cursor-pointer"
                >
                  <Col span={1} style={{ width: '30px' }}>
                    <Icon
                      type="environment"
                      className={
                        this.state.selectedAddressIndex === index
                          ? 'color-forest text-size-x3-large'
                          : 'text-size-x3-large'
                      }
                      theme={this.state.selectedAddressIndex === index ? 'filled' : null}
                      style={{ color: selectedColor }}
                    />
                  </Col>
                  <Col className="mr-small ml-small">
                    {address.isPrimary ? (
                      <Text type="secondary">Primary address</Text>
                    ) : (
                      <Text type="secondary">Other address</Text>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={1}></Col>
                  <Col span={23} className="ml-medium pl-medium">
                    <pre>{address.fullAddress}</pre>
                  </Col>
                </Row>
              </React.Fragment>
            );
          })}
        </div>
      );
    } else {
      return <div>No Address found.</div>;
    }
  };

  private _renderEditAddresses = () => {
    const addresses = this.state.workerAddress;

    return (
      <div>
        {isEmpty(addresses) && (
          <div className="mt-small">
            <Text color="secondary">No addresses set.</Text>
          </div>
        )}
        {map(addresses, (address, index) => {
          return (
            <React.Fragment key={index}>
              <Row className="mt-small mb-x-small">
                <Col>
                  <Checkbox checked={address.isPrimary} onChange={() => this._changePrimaryAddress(index)}>
                    {address.isPrimary ? 'Primary address' : 'Mark as primary address'}
                  </Checkbox>
                </Col>
              </Row>
              <Row>
                <Col>
                  <LocationDisplay location={address.fullAddress} />
                </Col>
              </Row>
              <Row gutter={16} className="mb-medium">
                <Col>
                  <HyperlinkButton className="mr-medium" onClick={() => this._openCreateEditAddressModal(index)}>
                    <Icon type="edit" className="mr-x-small" />
                    Edit
                  </HyperlinkButton>
                  {!address.isPrimary ? (
                    <HyperlinkButton onClick={() => this._removeAddress(index)}>
                      <Icon type="delete" className="mr-x-small" />
                      Delete
                    </HyperlinkButton>
                  ) : null}
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  private _renderLanguage = () => {
    const { selectedWorkerItem } = this.props;

    const list = map(selectedWorkerItem.languages, (tag) => {
      return this.props.languagesQueryData.data.find((item) => item.tagId === tag);
    });

    return (
      <div>
        {map(list, (item) => {
          return (
            <Tag color={tagColors.LIME} className="mb-small" key={item.tagId}>
              <Text color="white" weight="bold" size="large">
                {item.tag}
              </Text>
            </Tag>
          );
        })}
      </div>
    );
  };

  private _getRolesText = (roles, roleOther) => {
    if (isEmpty(roles)) return 'Not set';
    if (isArray(roles)) {
      const formattedRoles = reduce(
        roles,
        (acc, r) => {
          if (r === 'Other' && roleOther) {
            acc.push(`Other ${roleOther}`);
          } else if (r === 'SupportWorker') {
            acc.push('Support worker');
          } else {
            acc.push(capitalize(r));
          }
          return acc;
        },
        [],
      );
      return join(formattedRoles, ', ');
    }
    return roles;
  };

  private _getEmploymentTypeText = (employmentType, employmentTypeOther) => {
    if (isEmpty(employmentType)) {
      return 'Not set';
    } else if (employmentType === 'Other') {
      return employmentTypeOther ? `Other ${employmentTypeOther}` : 'Not set';
    } else if (employmentType === 'FullTime') {
      return 'Full time';
    } else if (employmentType === 'PartTime') {
      return 'Part time';
    } else {
      return employmentType;
    }
  };

  private _showEditForm = () => {
    this.setState({ isEdit: true });
  };

  private _addKeyContactInputRow = () => {
    this.setState((prevState) => ({
      contacts: [
        ...prevState.contacts,
        {
          numberType: '',
          numberCountryCode: 'AU',
          number: '',
          preferredContact: false,
          supportWorkerContactNumberId: uuidv4(),
        },
      ],
    }));
  };

  private _proceedActionModal = () => {
    const rowID = this.state.rowID;
    if (!isEmpty(rowID)) {
      const contacts = filter([...this.state.contacts], (item) => item.supportWorkerContactNumberId !== rowID);
      this.setState({
        contacts,
        isDeleteModalOpen: false,
      });
    } else {
      this.setState({
        isDeleteModalOpen: false,
      });
    }
  };

  private _removeContact = (rowID) => {
    this.setState({ isDeleteModalOpen: true });
    this.setState({ rowID });
  };

  private _removeAddress = (addressIndex) => {
    this.setState({ isDeleteAddressModalOpen: true });
    this.setState({ addressIndex });
  };

  private _setRef = (ref) => {
    if (ref) {
      this.teamContactInputRefs.push(ref);
    }
  };

  private _handleInputData = (event, key) => {
    if (event.target.name === 'preferredContact') {
      const updatedList = map(this.state.contacts, (contact, subKey) => {
        const isChecked = key === subKey;
        return {
          ...contact,
          preferredContact: isChecked,
        };
      });
      this.setState({ contacts: updatedList });
    } else {
      const contacts = this.state.contacts;
      contacts[key][event.target.name] = event.target.value;
      this.setState({ contacts });
    }
  };

  private _handleDropDownClick = (value, name, key) => {
    const contacts = this.state.contacts;
    contacts[key][name] = value;
    this.setState({ contacts });
  };

  private _onAlertTagView = () => {
    this.props.onAlertTagView();
  };

  componentDidMount = async () => {
    const { selectedWorkerItem, doFetchDisabilityList } = this.props;
    const contacts = selectedWorkerItem.contactNumbers;
    let addresses = orderBy(selectedWorkerItem.addresses, 'isPrimary');

    const specialities: any = await doFetchDisabilityList({});
    this.setState({ specialities });

    if (addresses.length > 0) {
      addresses = map(addresses, (address) => {
        address.geoLat = !isEmpty(address.geoLat) ? Number(address.geoLat) : 0;
        address.geoLng = !isEmpty(address.geoLng) ? Number(address.geoLng) : 0;
        return address;
      });

      addresses = addresses.sort(function (a, b) {
        return a.isPrimary === b.isPrimary ? 0 : a ? -1 : 1;
      });

      const primaryAddress = find(addresses, (address) => {
        return address.isPrimary;
      });

      const primaryIndex = findIndex(addresses, (address) => address.isPrimary);

      this.setState({
        workerAddress: addresses,
        lattitude: Number(primaryAddress.geoLat),
        longitude: Number(primaryAddress.geoLng),
        selectedAddressIndex: primaryIndex,
      });
    }

    if (contacts.length > 0) {
      this.setState({
        contacts: cloneDeep(orderBy(contacts, ['preferredContact', 'numberType', 'number'], 'desc')),
      });
    }
  };

  render() {
    const { selectedWorkerItem, companyDataLite, form } = this.props;
    const { specialities } = this.state;
    const hasAlert = selectedWorkerItem.alerts && selectedWorkerItem.alerts.length > 0;
    const { getFieldDecorator } = form;
    const lattitude = Number(this.state.lattitude);
    const longitude = Number(this.state.longitude);
    const contactNumbers = orderBy(selectedWorkerItem.contactNumbers, ['preferredContact'], 'desc');

    if (!isEmpty(selectedWorkerItem) && !this.state.isEdit) {
      return (
        <div className="x2-large">
          <Row type="flex" justify="space-between" align="bottom">
            <Col>
              <Title level={2} className="m-none">
                General information
              </Title>
              <Text type="secondary">General information about this team member.</Text>
            </Col>
            <Col className="align-center">
              <div className="align-center flex-row-reverse">
                {this.props.hasEditPermission && (
                  <PrimaryButton icon="edit" onClick={this._showEditForm}>
                    Edit information
                  </PrimaryButton>
                )}
              </div>
            </Col>
          </Row>
          {hasAlert ? (
            <Row type="flex" className="mt-x-large">
              <Col span={24}>
                <Row className="mb-small">
                  <Col span={12}>
                    <FieldLabel text="Alerts" />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {selectedWorkerItem.alerts &&
                      selectedWorkerItem.alerts
                        .sort((a, b) => (a.supportWorkerAlertType > b.supportWorkerAlertType ? 1 : -1))
                        .map((alert) => (
                          <TeamAlertTag
                            key={alert.supportWorkerAlertType}
                            alertType={alert.supportWorkerAlertType}
                            numberOfAlerts={alert.numberOfAlerts}
                            onClickView={() => this._onAlertTagView()}
                            className="mr-small"
                          />
                        ))}
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <div className="mt-x-large">
            <Row gutter={24}>
              <Col span={12}>
                <FieldLabel text="About" />
                <FieldValueText
                  text={selectedWorkerItem.aboutMe ? selectedWorkerItem.aboutMe : 'Not set'}
                  style={{ whiteSpace: 'pre-line' }}
                />
              </Col>
              <Col span={12}>
                <FieldLabel text="Role(s)" />
                <FieldValueText text={this._getRolesText(selectedWorkerItem.roles, selectedWorkerItem.roleOther)} />
              </Col>
            </Row>
          </div>
          <div className="mt-x-large">
            <Row gutter={24}>
              <Col span={12}>
                <FieldLabel text="Start Date" />
                <FieldValueText
                  text={
                    selectedWorkerItem.startDate
                      ? moment(
                          moment
                            .tz(selectedWorkerItem.startDate, companyDataLite && companyDataLite.timezone)
                            .format('YYYY-MM-DD'),
                        ).format('DD/MM/YYYY')
                      : 'n/a'
                  }
                  style={{ whiteSpace: 'pre-line' }}
                />
              </Col>
              <Col span={12}>
                <FieldLabel text="End Date" />
                <FieldValueText
                  text={
                    selectedWorkerItem.endDate
                      ? moment(
                          moment
                            .tz(selectedWorkerItem.endDate, companyDataLite && companyDataLite.timezone)
                            .format('YYYY-MM-DD'),
                        ).format('DD/MM/YYYY')
                      : 'n/a'
                  }
                  style={{ whiteSpace: 'pre-line' }}
                />
              </Col>
            </Row>
          </div>

          <div className="mt-x-large">
            <Row gutter={24}>
              <Col span={12}>
                <FieldLabel text="Employee ID" />
                <FieldValueText
                  text={selectedWorkerItem.employeeNumber !== null ? selectedWorkerItem.employeeNumber : 'Not set'}
                />
              </Col>
              {/* 
                  Hide Employment Type Field if crem260 (Workforce Insights)
                  feature is active
                  Employment type field has been moved to 'Work Details' Tab
              */}
              <Flag
                flag="crem260"
                off={
                  <Col span={12}>
                    <FieldLabel text="Employment Type" />
                    <FieldValueText
                      text={this._getEmploymentTypeText(
                        selectedWorkerItem.employmentType,
                        selectedWorkerItem.employmentTypeOther,
                      )}
                    />
                  </Col>
                }
              />
            </Row>
          </div>
          <div className="mt-x-large">
            <Row gutter={24}>
              <Col span={12}>
                <FieldLabel text="Email" />
                <Stack gap="$space50">
                  <FieldValueText text={selectedWorkerItem.email ?? 'Not set'} />
                  <EditEmail email={selectedWorkerItem?.email} />
                </Stack>
              </Col>
              <Col span={12}>
                <FieldLabel text="Contact Numbers" />
                {contactNumbers.map((contactNumber, index) => {
                  return (
                    <React.Fragment key={index}>
                      {' '}
                      <SubSectionHeaderText text={contactNumber.preferredContact ? 'Primary' : ''} />
                      <Row type="flex">
                        <Col span={7}>
                          <FieldValueText
                            text={parsePhoneNumberFromString(
                              contactNumber.number,
                              contactNumber.numberCountryCode !== null && contactNumber.numberCountryCode !== ''
                                ? contactNumber.numberCountryCode
                                : 'AU',
                            ).format('INTERNATIONAL')}
                          />
                        </Col>
                        <Col>
                          <FieldLabel text={'(' + contactNumber.numberType + ')'} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
                })}
              </Col>
            </Row>
          </div>
          <div className="mt-x-large">
            <Row gutter={24}>
              <Col span={12}>
                <FieldLabel text="Gender" />
                <FieldValueText text={selectedWorkerItem.gender ? selectedWorkerItem.gender : 'Not set'} />
              </Col>
              <Col span={12}>
                <FieldLabel text="Date of birth" />
                <FieldValueText
                  text={
                    selectedWorkerItem.dateOfBirth
                      ? moment(selectedWorkerItem.dateOfBirth).format('DD/MM/YYYY')
                      : 'Not set'
                  }
                />
              </Col>
            </Row>
          </div>

          <div className="mt-x-large">
            <Row gutter={24}>
              <Col span={12}>
                <FieldLabel text="Addresses" />
                <div className="mb-small">{this._renderAddresses()}</div>
              </Col>
              <Col span={12}>
                <div>{lattitude !== 0 && longitude !== 0 && <Map lattitude={lattitude} longitude={longitude} />}</div>
              </Col>
            </Row>
          </div>
          <div className="mt-x-large">
            <Row gutter={24} className="mt-medium">
              <Col span={12}>
                <FieldLabel text="Specialities" />
                <div>
                  {selectedWorkerItem.specialities && selectedWorkerItem.specialities.length > 0
                    ? this._renderSpecialities()
                    : 'Not set'}
                </div>
              </Col>
              <Col span={12}>
                <FieldLabel text="Skills" />
                <div>{selectedWorkerItem.skills.length > 0 ? this.renderSkills() : 'Not set'}</div>
              </Col>
            </Row>
          </div>
          <div className="mt-x-large">
            <Row gutter={24} className="mt-medium">
              <Col span={12}>
                <FieldLabel text="Religions" />
                <div>{selectedWorkerItem.religions.length > 0 ? this._renderReligion() : 'Not set'}</div>
              </Col>
              <Col span={12}>
                <FieldLabel text="Languages" />
                <div>
                  {this.props.isLoadingLanguages ? (
                    <div style={{ width: 'fit-content' }}>
                      <Spinner size={18} intent={'primary'} />
                    </div>
                  ) : selectedWorkerItem.languages.length > 0 ? (
                    this._renderLanguage()
                  ) : (
                    'Not set'
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div className="mt-x-large">
            <Row gutter={24} className="mt-medium">
              <Col span={12}>
                <FieldLabel text={'Hobbies & Interests'} />
                <div>{selectedWorkerItem.hobbies.length > 0 ? this._renderHobbies() : 'Not set'}</div>
              </Col>
              <Col span={12}>
                <FieldLabel text="Qualifications" />
                <div>{selectedWorkerItem.qualifications.length > 0 ? this._renderQualifications() : 'Not set'}</div>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else if (!isEmpty(selectedWorkerItem) && this.state.isEdit) {
      const dateofbirth =
        selectedWorkerItem.dateOfBirth !== null ? moment(selectedWorkerItem.dateOfBirth, 'YYYY-MM-DD') : null;
      const startDate =
        selectedWorkerItem.startDate !== null
          ? moment(moment.tz(selectedWorkerItem.startDate, companyDataLite.timezone).format('YYYY-MM-DD'))
          : null;
      const endDate =
        selectedWorkerItem.endDate !== null
          ? moment(moment.tz(selectedWorkerItem.endDate, companyDataLite.timezone).format('YYYY-MM-DD'))
          : null;

      return (
        <div className="x2-large">
          <ActionModal
            isOpen={this.state.isActionModalOpen}
            onClose={this._closeActionModal}
            title="Discard changes"
            showCloseButton={true}
          >
            <Text className="mb-medium">
              You have <b>unsaved data</b>, proceeding will discard these changes.
            </Text>
            <br />
            <Text className="mb-medium">Do you want to proceed?</Text>
            <ActionModalFooter>
              <PrimaryButton className="mr-medium" size="large" onClick={this._closeActionModal}>
                Cancel
              </PrimaryButton>
              <GhostButton size="large" onClick={this._proceedDiscardChanges}>
                Proceed
              </GhostButton>
            </ActionModalFooter>
          </ActionModal>
          <ActionModal
            isOpen={this.state.isDeleteModalOpen}
            onClose={this._closeDeleteModal}
            title="Confirmation"
            showCloseButton={true}
          >
            <Text className="mb-medium">You are about to delete the contact.</Text>
            <br />
            <Text className="mb-medium">Do you want to proceed?</Text>
            <ActionModalFooter>
              <PrimaryButton className="mr-medium" size="large" onClick={this._closeDeleteModal}>
                Cancel
              </PrimaryButton>
              <GhostButton size="large" onClick={this._proceedActionModal}>
                Proceed
              </GhostButton>
            </ActionModalFooter>
          </ActionModal>
          <ActionModal
            isOpen={this.state.isDeleteAddressModalOpen}
            onClose={this._closeDeleteAddressModal}
            title="Confirmation"
            showCloseButton={true}
          >
            <Text className="mb-medium">You are about to delete the Address.</Text>
            <br />
            <Text className="mb-medium">Do you want to proceed?</Text>
            <ActionModalFooter>
              <PrimaryButton className="mr-medium" size="large" onClick={this._closeDeleteAddressModal}>
                Cancel
              </PrimaryButton>
              <GhostButton size="large" onClick={this._deleteAddress}>
                Proceed
              </GhostButton>
            </ActionModalFooter>
          </ActionModal>
          <CreateEditAddressModal
            closeCreateEditAddressModal={() => {
              this._closeCreateEditAddressModal();
            }}
            selectedWorkerItem={selectedWorkerItem}
            isOpen={this.state.isopen}
            selectedAddressIndex={this.state.selectedAddressIndex}
            workerAddress={this.state.workerAddress}
            isCreateNewAddress={this.state.isCreateNewAddress}
            saveWorkerAddress={this._saveWorkerAddress}
          />
          <Title level={2} className="m-0">
            General information
          </Title>
          <Row type="flex" justify="space-between" align="bottom">
            <Col>
              <Text type="secondary">General information about this team member.</Text>
            </Col>
          </Row>
          <div className="mt-x-large">
            <Row>
              <FormCol label="First Name" leftSpan={5} textAlign="right" />
              <Col span={5} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('employeeFirstName', {
                    initialValue: selectedWorkerItem.firstName !== null ? selectedWorkerItem.firstName : '',
                    rules: [{ validator: this._validateFirstNameInput }],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label="Last Name" leftSpan={5} textAlign="right" />
              <Col span={5} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('employeeLastName', {
                    initialValue: selectedWorkerItem.lastName !== null ? selectedWorkerItem.lastName : '',
                    rules: [{ validator: this._validateLastNameInput }],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <FormCol label="Employee ID" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('employeeNumber', {
                    initialValue: selectedWorkerItem.employeeNumber !== null ? selectedWorkerItem.employeeNumber : '',
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label="About" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('aboutMe', {
                    initialValue: selectedWorkerItem.aboutMe,
                  })(<TextArea autoSize={{ minRows: 4, maxRows: 4 }} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label="Start Date" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('startDate', {
                    initialValue: startDate,
                  })(
                    <DatePicker
                      size="large"
                      allowClear={false}
                      format="DD/MM/YYYY"
                      className="mr-small"
                      showToday={false}
                      disabledDate={(current) => {
                        return current && current > moment().startOf('day');
                      }}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label="End Date" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('endDate', {
                    initialValue: endDate,
                  })(
                    <DatePicker
                      size="large"
                      allowClear={false}
                      format="DD/MM/YYYY"
                      className="mr-small"
                      showToday={false}
                      disabledDate={(current) => {
                        const startValue = this.props.form.getFieldValue('startDate');
                        if (startValue !== null && startValue !== '') {
                          return current.valueOf() < startValue.valueOf();
                        } else {
                          return current < moment();
                        }
                      }}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol leftSpan={5} label="Role(s)" textAlign="right" />
              <Col span={19} className="pl-medium mb-x-large pt-x-small">
                <Text size="small" color="secondary">
                  Select all roles that apply
                </Text>
                <Form.Item className="mb-none">
                  {getFieldDecorator('roles', {
                    initialValue: selectedWorkerItem.roles,
                  })(
                    <CheckboxGroup>
                      <Checkbox
                        style={{ display: 'block', height: '30px', lineHeight: '30px', margin: 0 }}
                        value="SupportWorker"
                      >
                        Support worker
                      </Checkbox>
                      <Checkbox
                        style={{ display: 'block', height: '30px', lineHeight: '30px', margin: 0 }}
                        value="Manager"
                      >
                        Manager
                      </Checkbox>
                      <Checkbox
                        style={{ display: 'block', height: '30px', lineHeight: '30px', margin: 0 }}
                        value="Volunteer"
                      >
                        Volunteer
                      </Checkbox>
                      <Checkbox
                        style={{ display: 'block', height: '30px', lineHeight: '30px', margin: 0 }}
                        value="Other"
                      >
                        Other
                      </Checkbox>
                    </CheckboxGroup>,
                  )}
                </Form.Item>
                <Form.Item>
                  {includes(form.getFieldValue('roles'), 'Other')
                    ? getFieldDecorator('roleOther', {
                        initialValue: selectedWorkerItem.roleOther ? selectedWorkerItem.roleOther : null,
                        rules: [{ validator: this._validateRoleOtherInput }],
                      })(
                        <Input
                          maxLength={100}
                          style={{ width: 300, display: 'block' }}
                          placeholder="Please specify..."
                        />,
                      )
                    : null}
                </Form.Item>
              </Col>
            </Row>
            {/* 
              Hide employment type field if crem260 (Workforce Insights) feature is active
              Employment type will be moved to 'Work Details' tab 
            */}
            <Flag
              flag="crem260"
              off={
                <Row>
                  <FormCol leftSpan={5} label="Employment Type" textAlign="right" />
                  <Col span={19} className="pl-medium mb-x-large">
                    <Form.Item className="mb-none">
                      {getFieldDecorator('employmentType', {
                        initialValue: selectedWorkerItem.employmentType,
                      })(
                        <RadioGroup>
                          <Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value="FullTime">
                            Full time
                          </Radio>
                          <Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value="PartTime">
                            Part time
                          </Radio>
                          <Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value="Casual">
                            Casual
                          </Radio>
                          <Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value="Volunteer">
                            Volunteer
                          </Radio>
                          <Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value="Other">
                            Other
                          </Radio>
                        </RadioGroup>,
                      )}
                    </Form.Item>
                    <Form.Item>
                      {includes(form.getFieldValue('employmentType'), 'Other') &&
                        getFieldDecorator('employmentTypeOther', {
                          initialValue: selectedWorkerItem?.employmentTypeOther,
                          rules: [{ validator: this._validateEmploymentTypeOtherInput }],
                        })(
                          <Input
                            maxLength={100}
                            style={{ width: 300, display: 'block' }}
                            placeholder="Please specify..."
                          />,
                        )}
                    </Form.Item>
                  </Col>
                </Row>
              }
            />

            <Row>
              <FormCol label="Contact Number(s)" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                {isEmpty(this.state.contacts) && (
                  <div className="mt-small">
                    <Text color="secondary">No contacts set.</Text>
                  </div>
                )}
                {map(this.state.contacts, (contact, i) => {
                  return (
                    <TeamContactDetailRow
                      rowID={contact.supportWorkerContactNumberId}
                      key={contact.supportWorkerContactNumberId}
                      contact={contact}
                      wrappedComponentRef={this._setRef}
                      removeTeamContactInputRow={() => this._removeContact(contact.supportWorkerContactNumberId)}
                      handleInputData={(event) => {
                        this._handleInputData(event, i);
                      }}
                      handleDropDownClick={(value, name) => {
                        this._handleDropDownClick(value, name, i);
                      }}
                      form={form}
                    />
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col span={5} />
              <Col>
                <div className="mb-x-large">
                  <GhostButton icon="plus-circle" onClick={this._addKeyContactInputRow}>
                    Add contact number
                  </GhostButton>
                </div>
              </Col>
            </Row>
            <Row>
              <FormCol label="Date of birth" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('dateOfBirth', {
                    initialValue: dateofbirth,
                  })(
                    <DatePicker
                      size="large"
                      allowClear={false}
                      format="DD-MM-YYYY"
                      className="mr-small"
                      showToday={false}
                      disabledDate={(current) => {
                        return current && current >= moment().startOf('day');
                      }}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label="Gender" leftSpan={5} textAlign="right" />
              <Col span={11} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('gender', {
                    initialValue: selectedWorkerItem.gender,
                  })(
                    <Select placeholder="Please select gender" style={{ fontSize: '16px' }} className="width-full">
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                      <Option value="Non-binary">Other</Option>
                      <Option value="Not specified">Prefer Not to Say</Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label="Email" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <div className="mt-small">{selectedWorkerItem.email ? selectedWorkerItem.email : 'Not set'}</div>
              </Col>
            </Row>
            <Row>
              <FormCol label="Address" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <div>{this._renderEditAddresses()}</div>
              </Col>
            </Row>
            <Row>
              <Col span={5} />
              <Col span={19}>
                <div className="mb-x-large">
                  <GhostButton icon="plus-circle" onClick={() => this._openCreateEditAddressModal(-1)}>
                    Add new address
                  </GhostButton>
                </div>
              </Col>
            </Row>
            <Row>
              <FormCol label="Specialities" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('specialities', { initialValue: selectedWorkerItem.specialities })(
                    <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={true}
                      optionFilterProp="children"
                      className="bg-tertiary focus-bg-white"
                      size="large"
                    >
                      {map(specialities, (speciality) => {
                        return (
                          <Select.Option key={speciality.tagId} value={speciality.tagId}>
                            {speciality.disabilityName}
                          </Select.Option>
                        );
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label="Qualifications" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('qualifications', { initialValue: selectedWorkerItem.qualifications })(
                    <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={true}
                      optionFilterProp="children"
                      className="bg-tertiary focus-bg-white"
                      size="large"
                    >
                      {map(sortBy(qualifications, 'tag'), (qualification) => {
                        return (
                          <Select.Option key={qualification.tagId} value={qualification.tagId}>
                            {qualification.tag}
                          </Select.Option>
                        );
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label="Skills" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('skills', {
                    initialValue: selectedWorkerItem.skills !== null ? selectedWorkerItem.skills : [],
                  })(
                    <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={true}
                      optionFilterProp="children"
                      className="bg-tertiary focus-bg-white"
                      size="large"
                    >
                      {map(sortBy(skills, 'tag'), (skill) => {
                        return (
                          <Select.Option key={skill.tagId} value={skill.tagId}>
                            {skill.tag}
                          </Select.Option>
                        );
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label="Religion" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('religions', {
                    initialValue: selectedWorkerItem.religions !== null ? selectedWorkerItem.religions[0] : [],
                  })(
                    <Select
                      mode="default"
                      style={{ width: '100%' }}
                      showSearch
                      allowClear
                      filterOption={true}
                      optionFilterProp="children"
                      className="bg-tertiary focus-bg-white"
                      size="large"
                    >
                      {map(sortBy(religions, 'tag'), (religion) => {
                        return (
                          <Select.Option key={religion.tagId} value={religion.tagId}>
                            {religion.tag}
                          </Select.Option>
                        );
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label="Languages" leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('languages', {
                    initialValue: selectedWorkerItem.languages !== null ? selectedWorkerItem.languages : [],
                  })(
                    this.props.isLoadingLanguages ? null : (
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        showSearch
                        filterOption={true}
                        optionFilterProp="children"
                        className="bg-tertiary focus-bg-white"
                        size="large"
                      >
                        {map(sortBy(this.props.languagesQueryData.data, 'tag'), (language) => {
                          return (
                            <Select.Option key={language.tagId} value={language.tagId}>
                              {language.tag}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    ),
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label={'Hobbies & Interests'} leftSpan={5} textAlign="right" />
              <Col span={19} className="pl-medium">
                <Form.Item>
                  {getFieldDecorator('hobbies', {
                    initialValue: selectedWorkerItem.hobbies !== null ? selectedWorkerItem.hobbies : [],
                  })(
                    <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={true}
                      optionFilterProp="children"
                      className="bg-tertiary focus-bg-white"
                      size="large"
                    >
                      {map(sortBy(hobbies, 'tag'), (hobbie) => {
                        return (
                          <Select.Option key={hobbie.tagId} value={hobbie.tagId}>
                            {hobbie.tag}
                          </Select.Option>
                        );
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label={''} leftSpan={5} textAlign="right" />
              <Col>
                <div className="mb-small">
                  <Row type="flex" justify="end">
                    <Col>
                      <GhostButton onClick={this._closeWithActionModal}>Discard changes</GhostButton>
                    </Col>
                    <Col>
                      <PrimaryButton disabled={this.state.isSaving} onClick={this._onClickSave} icon="save">
                        Save
                      </PrimaryButton>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

const mapState = (state: IRootState) => ({
  companyDataLite: state.companyStore.companyDataLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateTeamProfile: dispatch.teamStore.doUpdateTeamProfile,
  doFetchDisabilityList: dispatch.customersStore.doFetchDisabilityList,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
  doFetchSelectedWorker: dispatch.teamStore.doFetchSelectedWorker,
});

const TeamDetailsGeneralPanelComponent = connect(
  mapState,
  mapDispatch,
)(Form.create<ITeamDetailsGeneralPanelProps>()(TeamDetailsGeneralPanel));

/**
 * NOTE: Provides a simple wrapper allowing React-Query usage
 * Temporary until component is refactored into functional component
 */
const TeamDetailsPanelWithQueries = (props) => {
  const {
    data: fetchLanguageData,
    isLoading: isLoadingLanguages,
    error: isLoadingLanguagesError,
  } = useFetchTagsLanguages();

  return (
    <TeamDetailsGeneralPanelComponent
      {...props}
      languagesQueryData={fetchLanguageData}
      isLoadingLanguages={isLoadingLanguages && !isLoadingLanguagesError}
    />
  );
};

export default TeamDetailsPanelWithQueries;
