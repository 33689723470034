import React from 'react';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { notifications } from '@good/ui/notifications';

import { reactTrpc } from 'utilities/react-trpc';
import {
  ErrorAlert,
  SilServiceContextResolved,
  useInlineForm,
  useSilServiceContext,
} from 'views/sil-service/components';

export const WhatThisHomeOffersForm = () => {
  const { service } = useSilServiceContext() as SilServiceContextResolved;
  const { t } = useTranslation('', {
    keyPrefix: 'silService.serviceDetailsTabs.propertyAndRooms.whatThisHomeOffersForm',
  });
  const { t: tMessages } = useTranslation('', { keyPrefix: 'silService.messages' });
  const schema = z.object({
    bedroomFeatures: z.string().nullable(),
    bathroomFeatures: z.string().nullable(),
    accessibilityFeatures: z.string().nullable(),
  });
  const {
    data: serviceDetails,
    isLoading,
    isError,
  } = reactTrpc.sil.services.fetch.useQuery({
    serviceId: service.serviceId,
    serviceProviderId: service.serviceProviderId,
  });
  const updateServiceDetailsMutation = reactTrpc.sil.services.update.useMutation();

  const InlineForm = useInlineForm<z.infer<typeof schema>>({
    values: {
      bedroomFeatures: serviceDetails?.bedroomDetails ?? '',
      bathroomFeatures: serviceDetails?.bathroomDetails ?? '',
      accessibilityFeatures: serviceDetails?.accessibilityDetails ?? '',
    },
    resolver: zodResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    try {
      await updateServiceDetailsMutation.mutateAsync({
        serviceId: service.serviceId,
        serviceProviderId: service.serviceProviderId,
        bedroomDetails: values.bedroomFeatures,
        bathroomDetails: values.bathroomFeatures,
        accessibilityDetails: values.accessibilityFeatures,
      });
    } catch (e) {
      notifications.show({
        color: 'var(--color-red-main)',
        message: tMessages('updateService.error'),
      });
      throw e;
    }
    notifications.show({
      color: 'var(--color-green-main)',
      message: tMessages('updateService.success'),
    });
  };

  if (isError) {
    return (
      <InlineForm
        title={t('title')}
        onSubmit={() => {
          return;
        }}
        disabled
      >
        <ErrorAlert message={tMessages('fetchServiceDetails.error')} />
      </InlineForm>
    );
  }

  return (
    <InlineForm title={t('title')} onSubmit={onSubmit} loading={isLoading}>
      <InlineForm.RichTextEditor
        name='bedroomFeatures'
        description={t('fields.bedroomFeatures.description')}
        emptyValue={t('fields.bedroomFeatures.emptyValue')}
        label={t('fields.bedroomFeatures.label')}
        placeholder={t('fields.bedroomFeatures.placeholder')}
      />
      <InlineForm.RichTextEditor
        name='bathroomFeatures'
        description={t('fields.bathroomFeatures.description')}
        emptyValue={t('fields.bathroomFeatures.emptyValue')}
        label={t('fields.bathroomFeatures.label')}
        placeholder={t('fields.bathroomFeatures.placeholder')}
      />
      <InlineForm.RichTextEditor
        name='accessibilityFeatures'
        description={t('fields.accessibilityFeatures.description')}
        emptyValue={t('fields.accessibilityFeatures.emptyValue')}
        label={t('fields.accessibilityFeatures.label')}
        placeholder={t('fields.accessibilityFeatures.placeholder')}
      />
    </InlineForm>
  );
};
