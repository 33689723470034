import { SecondaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { IBrand } from 'interfaces/account-interfaces';
import React from 'react';
import { LogoShape, LogoShapeExtensions } from '../../utils/constants';

interface IProps {
  logoSrc?: string;
  brandInfo?: IBrand;
  shape?: LogoShape;
  companyName: string;
  onOpenAdjustModal: () => void;
}

const LogoSection = (props: IProps) => {
  const { shape, companyName, onOpenAdjustModal, brandInfo } = props;

  const isFullLogo = shape === LogoShape.FULL_LOGO;
  const { logoSectionStyles, nonLogoSectionStyles } = LogoShapeExtensions[shape];
  const logoSrc = isFullLogo ? brandInfo?.attachmentUrl : brandInfo?.squareIcon?.attachmentUrl;

  return (
    <div style={{ height: 285 }} className={`bg-tertiary flex-column align-center mb-x3-large p-large rounded-big`}>
      <div
        className={`flex flex-grow justify-center ${isFullLogo && !logoSrc ? ' mt-small align-start' : 'align-center'}`}
      >
        {logoSrc ? (
          <div className="bg-transparent bordered border-secondary">
            <img src={logoSrc} alt={companyName} style={logoSectionStyles} title={companyName} />
          </div>
        ) : (
          <div
            className="bg-white bordered border-secondary flex align-center justify-center text-align-center"
            style={nonLogoSectionStyles}
          >
            <Text className="text-italic" size="regular" color="secondary">
              None
              <br />
              uploaded
            </Text>
          </div>
        )}
      </div>
      <div className="text-align-center">
        <SecondaryButton onClick={onOpenAdjustModal}>{logoSrc ? 'Change logo' : 'Upload logo'}</SecondaryButton>
      </div>
    </div>
  );
};

export default React.memo(LogoSection);
