import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Popover } from '@blueprintjs/core';
import { Avatar, Col, Empty, notification, Row } from 'antd';

import { StringFormat } from 'firebase/storage';
import { SubTitle, Text, Title } from 'common-components/typography';
import { IElementValue } from 'views/form-builder/shared/form-interface';
import EditValueFormModal from 'views/form-builder/components/EditValueFormModal';
import AttachFormModalWrapper from 'views/form-builder/components/AttachFormModal';
import { IAttachFormResponse, IAttendanceForm } from 'interfaces/booking-interfaces';
import { HyperlinkButton, IconButton, PrimaryButton } from 'common-components/buttons';
import EditCustomersTeamMembersModal from './components/EditCustomersTeamMembersModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ActionMenu, ActionMenuDivider, ActionMenuItem } from 'common-components/action-menu';

import DeleteBookingFormModal from './components/DeleteBookingFormModal';
import { StatusTag } from 'common-components/tags';

const FormEmptyPanel = () => (
  <>
    <Row className="pv-medium bordered-bottom">
      <Col span={8}>
        <SubTitle>FORM NAME</SubTitle>
      </Col>
      <Col span={8}>
        <SubTitle>DATE ADDED</SubTitle>
      </Col>
      <Col span={8}>
        <SubTitle>ADDED BY</SubTitle>
      </Col>
    </Row>
    <div className="flex-1 bg-white align-center flex-column pt-x2-large">
      <div className="mb-small">
        <Empty className="m-none" description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      <Text size="x2-large" color="secondary" weight="bold">
        Nothing here yet.
      </Text>
      <Text color="secondary">Select ‘Add form’ to get started.</Text>
    </div>
  </>
);

interface IBookingFormsPanelProps {
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  currentForm: typeof state.formBuilderStore.currentForm;
  doAddBookingForm: typeof dispatch.bookingsStore.doAddBookingForm;
  doFetchFormDetailByAttendanceFormId: typeof dispatch.bookingsStore.doFetchFormDetailByAttendanceFormId;
  doUpdateBookingForm: typeof dispatch.bookingsStore.doUpdateBookingForm;
  setFormStatus: typeof dispatch.bookingsStore.setFormStatus;
  timezone: StringFormat;
}

const BookingFormsPanel = ({
  selectedBookingItem: { bookingId, forms: attachedBookingForms, workerUserId, bookerUserId },
  currentForm,
  doAddBookingForm,
  doFetchFormDetailByAttendanceFormId,
  doUpdateBookingForm,
  setFormStatus,
  timezone,
}: IBookingFormsPanelProps) => {
  const [isOpenAddFormModal, setIsOpenAddFormModal] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditProfileOpen, setIsEditProfileOpen] = useState<boolean>(false);
  const [selectedAttendanceForm, setSelectedAttendanceForm] = useState<IAttendanceForm>(null);
  const [isViewFormModalOpen, setIsViewFormModalOpen] = useState<boolean>(false);
  const [fetchedAttachForm, setFetchedAttachForm] = useState<IAttachFormResponse>(null);
  const [isEditForm, setIsEditForm] = useState<boolean>(false);

  const _handleOpenAddFormModal = () => {
    setIsOpenAddFormModal(true);
  };

  const _handleCloseAddFormModal = () => {
    setIsOpenAddFormModal(false);
  };

  const _handleDeleteFormAttach = (attendanceForm) => {
    setSelectedAttendanceForm(attendanceForm);
    setIsDeleteModalOpen(true);
  };

  const _handleEditFormAttach = async (attendanceForm) => {
    setSelectedAttendanceForm(attendanceForm);
    const attachFormData = await doFetchFormDetailByAttendanceFormId({
      attendanceFormId: attendanceForm.attendanceFormId,
      bookingId,
    });
    setFetchedAttachForm(attachFormData as any);
    setIsEditForm(true);
    setIsViewFormModalOpen(true);
  };

  const _handleOpenEditProfileModal = (attendanceForm) => {
    setSelectedAttendanceForm(attendanceForm);
    setIsEditProfileOpen(true);
  };

  const _handleViewFormAttach = async (attendanceForm) => {
    setSelectedAttendanceForm(attendanceForm);
    const attachFormData = await doFetchFormDetailByAttendanceFormId({
      attendanceFormId: attendanceForm.attendanceFormId,
      bookingId,
    });
    setFetchedAttachForm(attachFormData as any);
    setIsViewFormModalOpen(true);
  };

  const _handleCloseViewEditFormModal = () => {
    setIsViewFormModalOpen(false);
    setIsEditForm(false);
    setSelectedAttendanceForm(null);
    setFetchedAttachForm(null);
  };

  const _doSave = async (
    formId: string,
    elementValues: IElementValue[],
    formName: string,
    versionId: string,
    status: string,
  ) => {
    try {
      const payload = {
        bookingId: bookingId,
        formId: formId,
        formData: elementValues,
        involvedCustomerId: bookerUserId,
        involvedMemberId: workerUserId,
        status,
      };
      await doAddBookingForm(payload);

      notification.open({
        message: <span className={'text-weight-bolder'}>Form attached</span>,
        description: <span>You have attached a form.</span>,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  const _onEditFormAttach = async (id: string, elementValues: IElementValue[], status: string) => {
    const attendanceFormId = selectedAttendanceForm.attendanceFormId;

    try {
      const payload = {
        bookingId: bookingId,
        attendanceFormId,
        formData: elementValues,
        status,
      };
      const response = await doUpdateBookingForm(payload);
      notification.open({
        message: <span className={'text-weight-bolder'}>Form updated</span>,
        description: <span>You have updated a form.</span>,
      });

      if (response) setFormStatus({ attendanceFormId, status });

      _handleCloseViewEditFormModal();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  return (
    <>
      <AttachFormModalWrapper
        timezone={timezone}
        isOpen={isOpenAddFormModal}
        onClose={_handleCloseAddFormModal}
        onSave={_doSave}
      />

      {(isViewFormModalOpen || isEditForm) && selectedAttendanceForm != null && fetchedAttachForm != null && (
        <EditValueFormModal
          timezone={timezone}
          formId={selectedAttendanceForm.formId}
          formElements={currentForm}
          elementValues={fetchedAttachForm.formData}
          isOpen={isViewFormModalOpen}
          onClose={_handleCloseViewEditFormModal}
          onSave={_onEditFormAttach}
          mode={isEditForm ? 'EDIT' : 'VIEW'}
        />
      )}
      {isEditProfileOpen && (
        <EditCustomersTeamMembersModal
          isOpen={isEditProfileOpen}
          onClose={() => setIsEditProfileOpen(false)}
          attendanceForm={selectedAttendanceForm}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteBookingFormModal
          isOpen={isDeleteModalOpen}
          attendanceFormId={selectedAttendanceForm.attendanceFormId}
          bookingId={bookingId}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}

      <Row type={'flex'} justify={'space-between'} align={'bottom'} className="mb-x-large">
        <Col>
          <Title level={2} className={'m-none'}>
            Forms
          </Title>
          <Text type={'secondary'}>Add and manage all forms related to this booking.</Text>
        </Col>
        <Col className={'align-center'}>
          <div className={'flex align-center'}>
            <PrimaryButton size="large" onClick={_handleOpenAddFormModal}>
              + Add form
            </PrimaryButton>
          </div>
        </Col>
      </Row>

      <div className="mb-x2-large">
        {!_.isEmpty(attachedBookingForms) ? (
          <>
            <Row className="pv-medium bordered-bottom">
              <Col span={5}>
                <SubTitle>FORM NAME</SubTitle>
              </Col>
              <Col span={5}>
                <SubTitle>DATE ADDED</SubTitle>
              </Col>
              <Col span={3}>
                <SubTitle>STATUS</SubTitle>
              </Col>
              <Col span={5}>
                <SubTitle>ADDED BY</SubTitle>
              </Col>
              <Col span={4}>
                <SubTitle>LINKED PROFILES</SubTitle>
              </Col>
              <Col span={2} />
            </Row>
            {attachedBookingForms.map((form: IAttendanceForm) => (
              <Row type="flex" align="middle" className="pv-medium" key={form.attendanceFormId}>
                <Col span={5}>
                  <HyperlinkButton onClick={() => _handleViewFormAttach(form)}>{form.formName}</HyperlinkButton>
                </Col>
                <Col span={5}>{moment(form.addedOn).format('DD MMM YYYY')}</Col>
                <Col span={3}>
                  <StatusTag status={form.status} />
                </Col>
                <Col span={5}>
                  <Avatar className="mr-medium" size="default" icon="user" src={form.addedByAvatarUrl} />
                  <Text>{form.addedByName}</Text>
                </Col>
                <Col span={4}>
                  {form.involvedCustomer && (
                    <Text>
                      <strong>1</strong> Customer <br />
                    </Text>
                  )}
                  {!_.isEmpty(form.involvedMembers) && (
                    <Text>
                      <strong>{form.involvedMembers.length}</strong> Team member{form.involvedMembers.length > 1 && 's'}
                    </Text>
                  )}
                  {_.isEmpty(form.involvedMembers) && !form.involvedCustomer && '-'}
                </Col>
                <Col span={2} className="text-align-right pr-small">
                  <Popover
                    content={
                      <ActionMenu>
                        <>
                          <ActionMenuItem text="View form" onClick={() => _handleViewFormAttach(form)} />
                          <ActionMenuItem text="Edit form" onClick={() => _handleEditFormAttach(form)} />
                          <ActionMenuItem
                            text="Edit linked profiles"
                            onClick={() => _handleOpenEditProfileModal(form)}
                          />
                          <ActionMenuDivider />
                          <ActionMenuItem
                            text="Export as PDF"
                            onClick={() => {
                              window.open(`/pdf?type=view-form&attendanceFormId=${form.attendanceFormId}`, '_blank');
                            }}
                          />
                          <ActionMenuItem
                            text="Remove"
                            className="text-color-red"
                            onClick={() => _handleDeleteFormAttach(form)}
                          />
                        </>
                      </ActionMenu>
                    }
                  >
                    <IconButton
                      icon="ellipsis"
                      size="small"
                      className="bg-white"
                      bordered={true}
                      iconColor="secondary"
                      onClick={() => {}}
                    />
                  </Popover>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <FormEmptyPanel />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  selectedBookingItem: state.bookingsStore.selectedBookingItem,
  currentForm: state.formBuilderStore.currentForm,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doAddBookingForm: dispatch.bookingsStore.doAddBookingForm,
  doFetchFormDetailByAttendanceFormId: dispatch.bookingsStore.doFetchFormDetailByAttendanceFormId,
  doUpdateBookingForm: dispatch.bookingsStore.doUpdateBookingForm,
  setFormStatus: dispatch.bookingsStore.setFormStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingFormsPanel);
