import React from 'react';
import { useTranslation } from 'react-i18next';
import { rem } from '@good/ui/core';
import { PageHeader } from '@good/ui/templates';

import { AboutTheServiceForm } from './about-the-service-form';
import { LocationAndCapacityForm } from './location-and-capacity-form';
import { OtherDetailsForm } from './other-details-form';

export const GeneralInformation = () => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceDetailsTabs.general' });

  return (
    <>
      <PageHeader title={t('title')} subtitle={t('description')} size='h2' mb={rem(24)} />
      <div className='flex flex-col gap-6 w-full'>
        <AboutTheServiceForm />
        <LocationAndCapacityForm />
        <OtherDetailsForm />
      </div>
    </>
  );
};
