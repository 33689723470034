import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const MusicPlus = forwardRef(function MusicPlus(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 1a.75.75 0 0 0-1.5 0v2.25H17a.75.75 0 1 0 0 1.5h2.25V7a.75.75 0 1 0 1.5 0V4.75H23a.75.75 0 0 0 0-1.5h-2.25V1ZM8 3.25h-.055c-1.367 0-2.47 0-3.337.117-.9.12-1.658.38-2.26.981-.602.602-.86 1.36-.981 2.26-.117.867-.117 1.97-.117 3.337V16.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116H14.055c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337V10.5a.75.75 0 0 0-1.5 0V16c0 1.435-.002 2.436-.103 3.192-.099.734-.28 1.122-.556 1.399-.277.277-.665.457-1.4.556-.754.101-1.755.103-3.19.103H8c-1.436 0-2.437-.002-3.193-.103-.734-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191v-6c0-1.435.002-2.437.103-3.192.099-.734.28-1.122.556-1.399.277-.277.665-.457 1.4-.556C5.563 4.752 6.564 4.75 8 4.75h5.5a.75.75 0 0 0 0-1.5H8ZM8.75 16a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM10 13.25c.45 0 .875.108 1.25.3V8a.75.75 0 0 1 1.166-.624l3 2a.75.75 0 1 1-.832 1.248L12.75 9.401V16A2.75 2.75 0 1 1 10 13.25Z"
        fill="currentColor"
      />
    </Icon>
  );
});
