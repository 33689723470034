import { UserPermissionRole } from 'utilities/enum-utils';
import { z } from 'zod';

export const regexMobile = /^\d*$/;

export const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/;

export const MESSAGE_ERRORS = {
  FIRST_NAME: 'Please enter a valid first name',
  LAST_NAME: 'Please enter a valid last name',
  EMAIL: 'Please enter a valid email',
  MOBILE: 'Please enter a valid mobile number',
  ROLE: 'Please select the user’s permissions',
  EMAIL_EXISTS: 'This email address already exists',
};

export const ERROR_TYPES = {
  DUPLICATE_EMAILS: 'DUPLICATE_EMAIL',
  EMAIL_EXISTS: 'EMAIL_EXISTS',
};

const optionTypeSchema = z.enum(['OVERVIEW', 'PERMISSION', 'INVITE_TO_APP'] as const);

const permissionRoleSchema = z.object({
  serviceId: z.nullable(z.string()),
  permissionRole: z.nativeEnum(UserPermissionRole),
  serviceDepartmentId: z.nullable(z.string()),
});

export const memberSchema = z.object({
  index: z.number().optional().default(0),
  memberId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  mobile: z.string(),
  mobileCountryCode: z.string(z.literal('AU')).default('AU'),
  hasPortalAccess: z.boolean().default(false),
  hasWorkerAppAccess: z.boolean().default(true),
  permissions: z.array(permissionRoleSchema).default([]),
  roles: z.array(z.string()).default(['NO_ACCESS']),
  isChecked: z.boolean(),
  advancedPermissions: z.nullable(z.object({})).optional().default(null),
  isError: z.boolean().default(false),
  serviceDepartmentPermissions: z.array(permissionRoleSchema).optional().default([]),
});

const subRoleSchema = z.object({
  payments: z.nullable(z.string()),
  team: z.nullable(z.string()),
  customer: z.nullable(z.string()),
  formBuilder: z.nullable(z.string()),
  planManagement: z.nullable(z.string()),
  serviceDepartments: z.nullable(z.string()),
  workflow: z.nullable(z.string()),
});

const rolePermissionListSchema = z.object({
  role: z.string(),
  label: z.string(),
  subRole: z.nullable(subRoleSchema),
  title: z.string(),
  description: z.string(),
});

export type PermissionRole = z.infer<typeof permissionRoleSchema>;
export type Member = z.infer<typeof memberSchema>;
export type SubRole = z.infer<typeof subRoleSchema>;
export type RolePermissionList = z.infer<typeof rolePermissionListSchema>;
export type OptionType = z.infer<typeof optionTypeSchema>;

export interface IService {
  serviceId: string;
  serviceName: string;
  status: string;
}
