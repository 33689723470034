import { Spinner } from '@blueprintjs/core';
import { Alert, Heading, Text } from '@good/components';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Title } from 'common-components/typography';
import { ICustomer } from 'interfaces/customer-interfaces';
import { FundingNdisSupportCategory } from 'interfaces/funding-interfaces';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IRootDispatch, dispatch } from 'stores/rematch/root-store';
import EditFundingWarningModal from 'views/customers/details/tabs-panel/funding/components/EditFundingWarningModal';
import FundingEditCategoriesPanel from 'views/customers/details/tabs-panel/funding/components/FundingEditCategoriesPanel';
import { GhostButton, PrimaryButton } from '../../../../../../common-components/buttons';

const SUPPORT_TYPES = ['Capacity Building', 'Capital', 'Core'] as const;
type SupportType = (typeof SUPPORT_TYPES)[number];

export type FundPackage = {
  fundingPackageId: string;
  fundingNDISSupportCategories: FundingNdisSupportCategory[];
};

export type CustomerFunding = { NDISFundingPackages: FundPackage[] };

const getSupportTypeFunding = (categories: FundingNdisSupportCategory[], type: SupportType): number | null => {
  const categoriesWithType = categories.filter((category) => category.supportType === type);

  // If we are given no categories with that support type, then it doesn't make sense to count the total funding.
  if (categoriesWithType.length === 0) return null;

  return categoriesWithType.reduce((acc, { funding }) => {
    return acc + (funding ?? 0);
  }, 0);
};

const LoadingPlaceholder: React.FC = () => {
  const { t } = useTranslation('', { keyPrefix: 'customerFunding.editCategoryPanel.placeholder' });

  return (
    <div className="mt-x-large">
      <div className="align-center mb-x-large flex-row">
        <div className="mr-x-large">
          <Spinner size={120} />
        </div>
        <div>
          <Paragraph>{t('l1')}</Paragraph>
          <Paragraph weight="bold">{t('l2')}</Paragraph>
          <Paragraph>{t('l3')}</Paragraph>
        </div>
      </div>
    </div>
  );
};

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCloseParent: () => void;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, onCloseParent }) => {
  const { t } = useTranslation('', { keyPrefix: 'customerFunding.editCategoryPanel.confirmation' });

  return (
    <ActionModal isOpen={isOpen} onClose={onClose} title={t('title')} showCloseButton={true}>
      <Heading className="mb-medium">{t('body.closingWithoutSaving')}</Heading>
      <br />
      <Text className="mb-medium">{t('body.askToProceed')}</Text>
      <ActionModalFooter>
        <PrimaryButton className="mr-medium" size="large" onClick={onClose}>
          {t('buttons.cancel')}
        </PrimaryButton>
        <GhostButton size="large" onClick={onCloseParent}>
          {t('buttons.proceed')}
        </GhostButton>
      </ActionModalFooter>
    </ActionModal>
  );
};

const FundEditSupportCategoryPanelHeader: React.FC<{ customer: ICustomer }> = ({ customer }) => {
  const { t } = useTranslation('', { keyPrefix: 'customerFunding.editCategoryPanel.header' });

  return (
    <>
      <div className="anim-slide-left">
        <Title level={2} className="text-weight-regular">
          {t('title')}
        </Title>
      </div>
      <div>
        <Text>{t('subtitle', { firstName: customer.firstName, lastName: customer.lastName })}</Text>
      </div>
    </>
  );
};

type FundEditSupportCategoryPanelProps = {
  closeEditCategoryModal: () => void;
  packageId: string;
  selectedCustomer: ICustomer;
  doUpdateFundingPackageCategory: typeof dispatch.customersStore.doUpdateFundingPackageCategory;
} & FormComponentProps;

const extractSelectedSupportCategories = (customer: ICustomer, packageId: string): FundingNdisSupportCategory[] => {
  // This is the only necessary assertion here, since the old ICustomer interface is loaded with any.
  const funding = customer.funding as CustomerFunding;
  const fundPackages: FundPackage[] = funding.NDISFundingPackages;

  const selectedPackage: FundPackage | undefined = fundPackages.find(
    (fundPackage) => fundPackage.fundingPackageId === packageId,
  );

  if (selectedPackage === undefined) {
    return [];
  }

  return selectedPackage.fundingNDISSupportCategories;
};

const FundEditSupportCategoryPanel: React.FC<FundEditSupportCategoryPanelProps> = ({
  closeEditCategoryModal,
  packageId,
  selectedCustomer,
  doUpdateFundingPackageCategory,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'customerFunding.editCategoryPanel' });

  // Yes, this unknown is required. This interacts with the wrappedComponentRef of antd fame.
  // Not even the antd authors know what type this should be.
  const categoryDataRefs: unknown[] = [];

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [categoryData, setCategoryData] = useState<FundingNdisSupportCategory[]>([]);

  const [isCancelActionModalOpen, setIsCancelActionModalOpen] = useState<boolean>(false);
  const [isEditWarningModalOpen, setIsEditWarningModalOpen] = useState<boolean>(false);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  /**
   * Update the respective data and keep track that the user has made unsaved changes.
   */
  const updateCategoryData = (updatedCategoryData: FundingNdisSupportCategory[]) => {
    setHasUnsavedChanges(true);
    setCategoryData(updatedCategoryData);
  };

  /**
   * Update the form's selected package on customer change.
   */
  useEffect(() => {
    const categories = extractSelectedSupportCategories(selectedCustomer, packageId);
    setCategoryData(categories);
  }, [selectedCustomer, packageId]);

  const closeCancelActionModal = () => setIsCancelActionModalOpen(false);
  const closeEditWarningModal = () => setIsEditWarningModalOpen(false);

  /**
   * If the user has unsaved changes, prompt if they want to continue.
   */
  const attemptCloseModal = () => {
    if (hasUnsavedChanges) {
      return setIsCancelActionModalOpen(true);
    }

    closeEditCategoryModal();
  };

  const setRef = (ref: unknown) => {
    if (ref) {
      categoryDataRefs.push(ref);
    }
  };

  const onSave = async () => {
    setIsSaving(true);
    setIsEditWarningModalOpen(true);

    await doUpdateFundingPackageCategory({
      userId: selectedCustomer.userId,
      fundingPackageId: packageId,
      supportCategories: categoryData,
    });

    setIsSaving(false);
    closeEditCategoryModal();
  };

  // You need to have at least one category in the form.
  const categoryDataEmpty: boolean = categoryData.length === 0;

  // Each support type (capacity building, capital, core) may not have a total funding of zero.
  const anyZeroFundingSupportType: boolean = useMemo(
    () => SUPPORT_TYPES.some((type) => getSupportTypeFunding(categoryData, type) === 0),
    [categoryData],
  );

  const formHasErrors: boolean = categoryDataEmpty || anyZeroFundingSupportType;

  if (isSaving) {
    return (
      <>
        <FundEditSupportCategoryPanelHeader customer={selectedCustomer} />
        <LoadingPlaceholder />
      </>
    );
  }

  return (
    <>
      <ConfirmationModal
        isOpen={isCancelActionModalOpen}
        onClose={closeCancelActionModal}
        onCloseParent={closeEditCategoryModal}
      />

      <EditFundingWarningModal
        isOpen={isEditWarningModalOpen}
        closeModal={closeEditWarningModal}
        onProceed={() => onSave}
      />

      <FundEditSupportCategoryPanelHeader customer={selectedCustomer} />
      <FundingEditCategoriesPanel
        categoryData={categoryData}
        updateCategoryData={updateCategoryData}
        wrappedComponentRef={setRef}
      />
      <div style={{ marginTop: '1rem' }}>
        {anyZeroFundingSupportType && (
          <Alert tone="critical">
            <Heading>{t('errors.zeroFundingCategory')}</Heading>
            {'\n'}
            <Text>{t('errors.zeroFundingCategoryNote')}</Text>
          </Alert>
        )}
        {categoryDataEmpty && (
          <Alert tone="critical">
            <Heading>{t('errors.emptyFundingPackage')}</Heading>
          </Alert>
        )}
      </div>
      <div className="mt-x2-large align-center flex-row justify-end">
        <GhostButton className="mr-small" onClick={attemptCloseModal}>
          {t('modal.buttons.cancel')}
        </GhostButton>
        <PrimaryButton size="large" icon="save" onClick={onSave} disabled={formHasErrors}>
          {t('modal.buttons.save')}
        </PrimaryButton>
      </div>
    </>
  );
};

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateFundingPackageCategory: dispatch.customersStore.doUpdateFundingPackageCategory,
});

export default connect(
  null,
  mapDispatch,
)(Form.create<FundEditSupportCategoryPanelProps>()(FundEditSupportCategoryPanel));
