import React, { Component, ReactNode } from 'react';
import { Title } from '../typography';

interface PopoverContainerProps {
  title?: ReactNode;
  titleLevel?: 1 | 2 | 3 | 4;
  showHeader?: boolean;
  content?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  width?: 'small' | 'medium' | 'large' | 'full' | number | string;
}

export default class PopoverContainer extends Component<PopoverContainerProps> {
  // width values mapping
  widthValues = {
    small: '480px',
    medium: '640px',
    large: '720px',
    'x-large': '880px',
    'x2-large': '1024px',
    full: '100%',
  };

  render() {
    const { title, titleLevel = 4, showHeader = true, children, width = 'small' } = this.props;

    let maxWidth = `640px`;

    // width
    if (typeof width === 'number') {
      maxWidth = `${width}px`;
    } else {
      maxWidth = this.widthValues[width];
    }

    return (
      <div className="pv-large ph-large" style={{ maxWidth }}>
        {showHeader && (
          <Title level={titleLevel} className="mb-medium">
            {title}
          </Title>
        )}
        <div className="">{children}</div>
      </div>
    );
  }
}

interface PopoverFooterProps {
  children?: ReactNode;
  align?: 'left' | 'center' | 'right';
}

function PopoverFooter(props: PopoverFooterProps) {
  const { children, align = 'right' } = props;

  const finalAlignment = `text-align-${align}`;

  return <div className={`pt-medium ${finalAlignment}`}>{children}</div>;
}

export { PopoverFooter };
