import { Slot } from '@radix-ui/react-slot';
import { filterDOMProps, mergeProps } from '@react-aria/utils';
import { forwardRef } from 'react';
import { useFocusRing, useHover, useLink } from 'react-aria';

import * as styles from './link.css';
import { twMerge } from '../utils';

import type { HTMLProps } from '../types';
import type { LinkVariants } from './link.css';
import type { PropsWithChildren, RefObject } from 'react';

export type LinkProps = PropsWithChildren<LinkVariants & HTMLProps<HTMLElement>> & {
  /**
   * Whether the link is disabled.
   * @default false
   */
  isDisabled?: boolean;
};

export const Link = forwardRef<HTMLLinkElement, LinkProps>(function Link(props, ref) {
  const { children, className, emphasis = 'underline', isDisabled = false, ...otherProps } = props;
  const { linkProps, isPressed } = useLink({ isDisabled }, ref as RefObject<HTMLLinkElement>);
  const { hoverProps, isHovered } = useHover({ isDisabled });
  const { focusProps, isFocusVisible } = useFocusRing(props);
  const mergedLinkProps = mergeProps(linkProps, hoverProps, focusProps);

  return (
    <Slot
      {...mergedLinkProps}
      {...filterDOMProps(otherProps)}
      ref={ref}
      data-pressed={isPressed}
      data-hovered={isHovered}
      data-focus-visible={isFocusVisible}
      data-disabled={isDisabled}
      className={twMerge(styles.link({ emphasis }), className)}
    >
      {children}
    </Slot>
  );
});
