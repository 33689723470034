import apiClient from 'utilities/api-client';

export interface ITag {
  category?: string;
  subCategory?: string;
  tag: string;
  tagId: number;
}

export interface IGetTagsResponse {
  status: number;
  data: ITag[];
}

export async function fetchLanguageTags(): Promise<IGetTagsResponse> {
  return await apiClient.get(`/api/portal/tags/languages`);
}
