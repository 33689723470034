import React, { useState, useCallback } from 'react';
import { TabPane, Tabs } from 'design-components';
import { BreadcrumbItem, Breadcrumbs } from '@goodhuman-me/components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Heading, Text } from '@good/components';

import WorkLeaveTypesTable, { WorkLeaveTypeTableOptions } from './WorkLeaveTypesTable';
import { WorkTypeCategory } from 'utilities/enum-utils';
import { IWorkLeaveType } from 'interfaces/account-interfaces';
import { WorkLeaveTypesTabPaneContent } from './components';
import EditWorkLeaveTypeModal from './EditWorkLeaveTypeModal';
import useWorkLeaveTypes from './use-work-leave-types';
import { ArchiveRestoreWorkLeaveTypeModal } from './ArchiveRestoreWorkLeaveTypeModal';
import useCount from './use-count';

import './style.css';

const WorkLeaveTypesConfigurationView = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'accountManagement.workLeaveTypesPage' });
  const { workTypes, leaveTypes } = useWorkLeaveTypes();
  const [tableOptions, setTableOptions] = useState<{
    [key in WorkTypeCategory]?: WorkLeaveTypeTableOptions;
  }>({ [WorkTypeCategory.WorkType]: {}, [WorkTypeCategory.Leave]: {} });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedWorkLeaveType, setSelectedWorkLeaveType] = useState<IWorkLeaveType | null>(null);
  const [selectedArchiveWorkLeaveType, setSelectedArchiveWorkLeaveType] = useState<IWorkLeaveType | null>(null);
  const [selectedRestoreWorkLeaveType, setSelectedRestoreWorkLeaveType] = useState<IWorkLeaveType | null>(null);
  const { workTypesCount, leaveTypesCount } = useCount(tableOptions);

  const saveWorkTypeOptions = useCallback(
    (options: WorkLeaveTypeTableOptions) =>
      setTableOptions((prev) => ({ ...prev, [WorkTypeCategory.WorkType]: options })),
    [],
  );

  const saveLeaveTypeOptions = useCallback(
    (options: WorkLeaveTypeTableOptions) => setTableOptions((prev) => ({ ...prev, [WorkTypeCategory.Leave]: options })),
    [],
  );

  const addWorkLeaveType = useCallback(() => {
    setSelectedWorkLeaveType({ category: WorkTypeCategory.Leave } as IWorkLeaveType);
    setModalOpen(true);
  }, []);

  const editWorkLeaveType = useCallback((workLeaveType: IWorkLeaveType) => {
    setSelectedWorkLeaveType(workLeaveType);
    setModalOpen(true);
  }, []);

  return (
    <>
      <div className='flex-column pb-large relative flex gap-6' id='work-leave-types-configuration-view'>
        <Breadcrumbs>
          <BreadcrumbItem>
            <Link to='/account/landing'>Account management</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/account/features'>Feature configuration</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/account/features/finance-file-export-configuration'>Finance file export</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <span>{t('heading')}</span>
          </BreadcrumbItem>
        </Breadcrumbs>
        <div className='flex-column mb-small flex' style={{ gap: 4 }}>
          <Heading size='xl'>{t('heading')}</Heading>
          <Text className='text-weak' size='sm'>
            {t('description')}
          </Text>
        </div>
        <Tabs defaultSelectedKey={WorkTypeCategory.WorkType}>
          <TabPane
            title={<WorkLeaveTypesTabPaneContent label={t('workTypesPane.heading')} count={workTypesCount} />}
            key={WorkTypeCategory.WorkType}
          >
            <div className='mt-medium'>
              <WorkLeaveTypesTable
                workLeaveTypes={workTypes}
                category={WorkTypeCategory.WorkType}
                options={tableOptions[WorkTypeCategory.WorkType]}
                saveOptions={saveWorkTypeOptions}
                editWorkLeaveType={editWorkLeaveType}
              />
            </div>
          </TabPane>
          <TabPane
            title={<WorkLeaveTypesTabPaneContent label={t('leaveTypesPane.heading')} count={leaveTypesCount} />}
            key={WorkTypeCategory.Leave}
          >
            <div className='mt-medium'>
              <WorkLeaveTypesTable
                workLeaveTypes={leaveTypes}
                category={WorkTypeCategory.Leave}
                options={tableOptions[WorkTypeCategory.Leave]}
                saveOptions={saveLeaveTypeOptions}
                editWorkLeaveType={editWorkLeaveType}
                addWorkLeaveType={addWorkLeaveType}
                archiveWorkLeaveType={setSelectedArchiveWorkLeaveType}
                restoreWorkLeaveType={setSelectedRestoreWorkLeaveType}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
      <EditWorkLeaveTypeModal
        workLeaveType={selectedWorkLeaveType}
        onClose={() => setModalOpen(false)}
        isOpen={modalOpen}
      />
      <ArchiveRestoreWorkLeaveTypeModal
        workLeaveType={selectedArchiveWorkLeaveType}
        onClose={() => setSelectedArchiveWorkLeaveType(null)}
        type='archive'
      />
      <ArchiveRestoreWorkLeaveTypeModal
        workLeaveType={selectedRestoreWorkLeaveType}
        onClose={() => setSelectedRestoreWorkLeaveType(null)}
        type='restore'
      />
    </>
  );
};

export default WorkLeaveTypesConfigurationView;
