import { FormConditionalOperator, PropKey, PropType } from './form-enum';
import { IFormElementProperty } from './form-interface';

export const FIELD_TITLE_PROPERTY: IFormElementProperty = {
  key: PropKey.FIELD_TITLE,
  name: 'FIELD TITLE',
  value: 'Field title',
  placeholder: 'Enter field title',
  caption: 'Add a title for this field',
  rules: [
    {
      required: true,
      message: 'Please add a field name',
    },
    {
      max: 100,
      message: 'Max length of characters is 100',
    },
  ],
};

export const REQUIRED_PROPERTY: IFormElementProperty = {
  key: PropKey.REQUIRE,
  name: 'REQUIRED',
  value: true,
  caption: 'This is a required field',
  type: PropType.required,
};

export const PLACEHOLDER_PROPERTY: IFormElementProperty = {
  key: PropKey.PLACEHOLDER_TEXT,
  name: 'PLACEHOLDER TEXT',
  value: '',
  placeholder: 'Placeholder text',
  caption: 'Add example text to display inside the text box',
  rules: [
    {
      max: 100,
      message: 'Max length of characters is 100',
    },
  ],
};

export const CAPTION_TEXT_PROPERTY: IFormElementProperty = {
  key: PropKey.CAPTION_TEXT,
  name: 'CAPTION TEXT (OPTIONAL)',
  value: '',
  placeholder: 'Caption text',
  caption: 'Write a caption to display below the field',
  rules: [
    {
      max: 200,
      message: 'Max length of characters is 200',
    },
  ],
};

export const RULES_PROPERTY: IFormElementProperty = {
  key: PropKey.RULES,
  name: 'rules',
  value: '',
};

export const TARGET_ELEMENT_PROPERTY: IFormElementProperty = {
  key: PropKey.TARGET_ELEMENT,
  name: 'Target element',
  value: null,
  options: [],
  label: 'When',
  caption: '',
  type: PropType.Select,
};

export const TARGET_OPTION_PROPERTY: IFormElementProperty = {
  key: PropKey.TARGET_OPTION,
  name: 'Option',
  value: null,
  options: [],
  dependOn: 'targetElement',
  label: 'Option',
  caption: '',
  type: PropType.selectDepend,
};

export const EVENT_PROPERTY: IFormElementProperty = {
  key: PropKey.EVENT,
  name: 'Event',
  value: null,
  options: Object.values(FormConditionalOperator),
  label: 'Meets this condition',
  caption: '',
  type: PropType.Select,
};

export const AFFECT_TO_ELEMENT_PROPERTY: IFormElementProperty = {
  key: PropKey.AFFECT_TO_ELEMENT,
  name: 'Affected Element',
  value: [],
  options: [],
  label: 'Show these fields',
  caption: '',
  type: PropType.SelectMultiple,
  isShow: false,
};

export const REPEATABLE_PROPERTY: IFormElementProperty = {
  key: PropKey.REPEATABLE,
  name: 'Repeatable',
  value: false,
  label: 'Make conditional repeatable',
  caption: 'If checked, target and affected elements will be repeated',
  type: PropType.checkbox,
};

export const CONDITIONAL_COMPARATOR: IFormElementProperty = {
  key: PropKey.CONDITIONAL_COMPARATOR,
  name: 'this value',
  label: 'this value',
  dependOn: PropKey.EVENT,
  value: null,
  type: PropType.inputDepend,
  rules: [
    {
      required: true,
      message: 'Please enter a value to compare to',
    },
  ],
};

export const CONDITIONAL_COMPARATOR_DATE: IFormElementProperty = {
  key: PropKey.CONDITIONAL_COMPARATOR_DATE,
  name: 'comparator date',
  label: 'Time',
  dependOn: PropKey.EVENT,
  value: null,
  type: PropType.inputDependencyDate,
  rules: [
    {
      required: true,
      message: 'Please enter a value to compare to',
    },
  ],
};
