import { ErrorOption, MultipleFieldErrors, UseFormReturn } from 'react-hook-form';

import { EditRatiosSchema } from '../../schema';
import { CustomRatioErrors } from '../../types';

function getErrors(error: CustomRatioErrors[number]) {
  const {
    startOverlap,
    endOverlap,
    isBeforeSessionStart,
    isAfterSessionEnd,
    isSameAsOtherTime,
    isStartAfterEndTime,
    isEndBeforeStartTime,
  } = error;

  const startTimeErrors: ErrorOption[] = [];
  const endTimeErrors: ErrorOption[] = [];

  if (isSameAsOtherTime.length > 0) {
    const sameAsOtherTimeError = {
      type: 'isSameAsOtherTime',
      message: 'Start and end time is the same as another custom time.',
    };

    startTimeErrors.push(sameAsOtherTimeError);
    endTimeErrors.push(sameAsOtherTimeError);
  }

  if (isBeforeSessionStart) {
    startTimeErrors.push({
      type: 'isBeforeSessionStart',
      message: 'Start time cannot be before session’s start.',
    });
  }

  if (isStartAfterEndTime) {
    startTimeErrors.push({
      type: 'isStartAfterEndTime',
      message: 'Start time cannot be after end time.',
    });
  }

  if (startOverlap.length > 0) {
    startTimeErrors.push({
      type: 'startOverlap',
      message: 'Start time overlaps with another custom time.',
    });
  }

  if (isAfterSessionEnd) {
    endTimeErrors.push({
      type: 'isAfterSessionEnd',
      message: 'End time cannot be after session’s end.',
    });
  }

  if (isEndBeforeStartTime) {
    endTimeErrors.push({
      type: 'isEndBeforeStartTime',
      message: 'End time cannot be before start time.',
    });
  }

  if (endOverlap.length > 0) {
    endTimeErrors.push({
      type: 'endOverlap',
      message: 'End time overlaps with another custom time',
    });
  }

  return { startTimeErrors, endTimeErrors };
}

export function setCustomRatioErrors(errors: CustomRatioErrors, form: UseFormReturn<EditRatiosSchema>) {
  Object.keys(errors).forEach((id) => {
    const { startTimeErrors, endTimeErrors } = getErrors(errors[id]);

    if (startTimeErrors.length) {
      const errors: MultipleFieldErrors = {};

      startTimeErrors.forEach((error) => {
        errors[error.type] = error.message;
      });

      form.setError(`ratios.${+id}.customRatio.startDateTime`, {
        type: 'custom',
        message: 'Start time is invalid.',
        types: errors,
      });
    } else {
      form.clearErrors(`ratios.${+id}.customRatio.startDateTime`);
    }

    if (endTimeErrors.length) {
      const errors: MultipleFieldErrors = {};

      endTimeErrors.forEach((error) => {
        errors[error.type] = error.message;
      });

      form.setError(`ratios.${+id}.customRatio.endDateTime`, {
        type: 'custom',
        message: 'End time is invalid.',
        types: errors,
      });
    } else {
      form.clearErrors(`ratios.${+id}.customRatio.endDateTime`);
    }
  });
}
