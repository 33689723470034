import { forwardRef } from 'react';
import { Illustration } from './illustration';
import type { IllustrationProps } from './illustration';
import type { Ref } from 'react';
export const EmpathySymbol = forwardRef(function EmpathySymbol(props: IllustrationProps, ref: Ref<SVGSVGElement>) {
  return (
    <Illustration {...props} ref={ref}>
      <path
        d="M62.432 104.317c-.972-1.379-1.793-2.75-2.812-3.952-4.178-4.93-7.443-10.484-10.942-15.877-2.001-3.083-3.905-6.229-5.919-9.304-1.005-1.535-2.15-2.977-3.248-4.451-1.218-1.634-2.452-3.254-3.68-4.88-1.13-1.499-2.233-3.021-3.398-4.492-2.685-3.39-5.193-6.892-7.035-10.83-1.512-3.227-2.468-6.59-2.348-10.182.197-5.914 2.702-10.528 7.89-13.526 4.004-2.314 8.305-3.492 12.969-3.052 2.458.232 4.745 1.06 6.979 2.057 6.447 2.875 11.69 7.155 15.423 13.202.16.262.36.5.582.803.116-.15.214-.223.24-.319.59-2.183 1.788-4.017 3.379-5.575 4.55-4.453 9.901-7.247 16.353-7.616 4.052-.23 7.896.587 11.338 2.79 5.372 3.439 7.669 8.439 6.948 14.728-.57 4.97-2.357 9.61-4.46 14.11-2.682 5.734-6.031 11.081-9.667 16.256-4.07 5.794-8.407 11.393-13.076 16.702-3.714 4.224-7.855 8.07-11.774 12.116-1.047 1.081-2.296 1.336-3.741 1.29l-.001.002Zm3.305-6.955c2.397-1.848 4.7-3.635 7.018-5.4a23.933 23.933 0 0 0 4.268-4.184c1.787-2.255 3.613-4.48 5.41-6.726 3.597-4.498 7.003-9.134 10.092-13.996 2.723-4.285 5.272-8.674 7.162-13.405 1.38-3.453 2.686-6.944 3.056-10.68.503-5.053-1.324-9.1-5.554-11.943-3.76-2.527-7.983-3.187-12.395-2.563-4.803.68-8.952 2.848-12.573 6.024-.895.784-1.698 1.699-2.421 2.646-.926 1.212-1.654 2.544-1.745 4.134-.035.63-.447 1.055-.878 1.512-.14-.16-.227-.236-.285-.332-3.74-6.212-8.676-11.123-15.346-14.168-2.334-1.065-4.69-2.059-7.263-2.352-4.613-.526-8.792.766-12.634 3.22-3.852 2.465-5.754 6.096-6.02 10.617-.195 3.316.637 6.416 2.02 9.394a41.557 41.557 0 0 0 5.773 9.082 271.64 271.64 0 0 0 8.82 10.118c5.727 6.236 10.83 12.918 14.969 20.316 1.769 3.16 4.252 5.674 7.115 7.838.435.33.94.568 1.41.846v.002Z"
        fill="currentColor"
      />
    </Illustration>
  );
});
