import { Empty } from 'antd';
import React from 'react';
import { Text } from '@good/components';

export const TmMgmtTableEmpty = () => (
  <div style={{ borderBottom: '0px solid' }}>
    <div className="align-center flex-column  flex-1 bg-white">
      <div className="">
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      <Text className="text-color-secondary font-bold" size="xl">
        No team member found.
      </Text>{' '}
      <br /> <br />
      <Text className="text-color-secondary">All team member under this filter will appear here.</Text>
      <Text className="text-color-secondary">Try adjusting your filter, or clicking on another tab.</Text>
    </div>
  </div>
);
