import React, { ChangeEvent, useState, useEffect } from 'react';
import { Input } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';

import { Text } from 'common-components/typography';

import { MdSend } from 'react-icons/md';

import _ from 'lodash';

export function MessagesInput({ onSendMessage, isCreatingChannel, currentChannelId, currentChannelStatus }) {
  const [inputText, setText] = useState('');

  function onChangeText(e: ChangeEvent<HTMLInputElement>) {
    setText(e.target.value);
  }

  function onClickSend() {
    const messageText = inputText;
    onSendMessage({ messageText });
    clearText();
  }

  function onClickEnter() {
    if (!_.isEmpty(inputText) && inputText.length > 0 && !isCreatingChannel) {
      onClickSend();
    }
  }

  function clearText() {
    setText('');
  }

  useEffect(() => {
    // If channel ID changes, clear input box.
    // Later should implement individual buffers for each conversation.
    clearText();
  }, [currentChannelId]);

  return (
    <div className="pt-small pb-medium ph-medium bg-white">
      {currentChannelStatus !== 2 ? (
        <>
          <div className="flex-row align-center bordered pv-x-small ph-x-small rounded">
            {/*TODO : Toggle anonymous */}

            <Input
              className="bordered-none bg-white ml-x-small mr-small message-input-box "
              placeholder={'Enter message here...'}
              multiple={true}
              value={inputText}
              onChange={onChangeText}
              onPressEnter={onClickEnter}
              style={{ paddingTop: '16px', paddingBottom: '16px' }}
            />
            <GhostButton
              size="large"
              onClick={onClickSend}
              color={'blue'}
              // className="text-color-blue bg-white bordered-none"
              // icon={'right-circle'}
              // iconPosition={'right'}
              disabled={_.trim(inputText, ' ').length < 1 || isCreatingChannel}
            >
              Send
              <MdSend className="text-size-regular ml-small" />
            </GhostButton>
          </div>
          <div className="pl-medium">
            <Text size="x-small" color="tertiary">
              Pressing Enter will send the message.
            </Text>
          </div>
        </>
      ) : (
        <div className="bordered-top pt-medium pb-large">
          <div className="text-align-center">
            <Text weight="bold">You can no longer reply to these messages</Text>
          </div>
          <div className="text-align-center mt-x-small">
            <Text>
              This user is no longer part of your business. If you think this is a mistake please contact an
              administrator
            </Text>
          </div>
        </div>
      )}
    </div>
  );
}
