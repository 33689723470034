import React, { useEffect, useMemo, useState } from 'react';
import { Icon } from 'antd';
import { IconButton } from 'common-components/buttons';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { IFormElement } from 'views/form-builder/shared/form-interface';
import { ReactComponent as DragHandleIcon } from 'assets/Icons/form-builder-icons/drag-horizontal.svg';

interface IFormControlProps {
  elements: IFormElement[];
  selectedElement: IFormElement;
  isFormDisabled: boolean;
  provided: any;
  onSelectItem: (item: IFormElement) => void;
  setElements: (items: IFormElement[]) => void;
  editable?: boolean;
  isIntakeForm?: boolean;
}

const swapArrayLocs = (arr, index1, index2) => {
  const newList = _.cloneDeep(arr);
  [newList[index1], newList[index2]] = [newList[index2], newList[index1]];
  return newList;
};

const FormControl = ({
  elements,
  selectedElement,
  setElements,
  onSelectItem,
  isFormDisabled,
  provided,
  editable = true,
  isIntakeForm = false,
}: IFormControlProps) => {
  const [elementsData, setElementsData] = useState(elements);

  useEffect(() => {
    setElementsData(elements);
  }, [elements]);

  const isCopyOrDelete = useMemo(
    () => !!elementsData && elementsData.length > 0 && !!selectedElement,
    [elementsData, selectedElement],
  );

  const allowMoveDown = useMemo(() => {
    if (elementsData && elementsData.length > 0 && selectedElement) {
      const length = elementsData.length;
      const index = elementsData.findIndex((e) => e.id === selectedElement.id);
      return index < length - 1;
    }
    return false;
  }, [elementsData, selectedElement]);

  const allowMoveUp = useMemo(() => {
    if (elementsData && elementsData.length > 0 && selectedElement) {
      const index = elementsData.findIndex((e) => e.id === selectedElement.id);
      return index > 0;
    }
    return false;
  }, [elementsData, selectedElement]);

  const _doMoveDown = (event) => {
    if (allowMoveDown) {
      event.stopPropagation();
      const index = elementsData.findIndex((e) => e.id === selectedElement.id);
      const newList = swapArrayLocs(elementsData, index, index + 1);
      setElements(newList);
    }
  };

  const _doMoveUp = (event) => {
    if (allowMoveUp) {
      event.stopPropagation();
      const index = elementsData.findIndex((e) => e.id === selectedElement.id);
      const newList = swapArrayLocs(elementsData, index, index - 1);
      setElements(newList);
    }
  };

  const _doDelete = () => {
    const elementsDataToRemove = _.cloneDeep(elementsData);
    _.remove(elementsDataToRemove, (e) => e.id === selectedElement.id);
    setElements(elementsDataToRemove);
    onSelectItem(null);
  };

  const _doCopy = () => {
    const itemToCopy = _.cloneDeep(elementsData.find((e) => e.id === selectedElement.id));
    setElements([...elementsData, { ...itemToCopy, id: uuid() }]);
  };

  return (
    <div className="flex-row justify-between justify-center mb-small align-center">
      {editable && (
        <div className="text-color-info-blue">
          <Icon type="edit" className="text-color-info-blue" /> CURRENTLY EDITING
        </div>
      )}
      <div className="text-color-info-blue flex-row ">
        {!isFormDisabled && elements.length > 1 && editable && (
          <div {...provided.dragHandleProps} className="mr-x2-small">
            <DragHandleIcon />
          </div>
        )}
        {!isFormDisabled && isCopyOrDelete && editable && !isIntakeForm && (
          <IconButton icon="copy" color="turquoise" iconColor="white" className="mr-x2-small" onClick={_doCopy} />
        )}
        {!isFormDisabled && allowMoveDown && editable && (
          <IconButton
            icon="arrow-down"
            color="blue-action"
            iconColor="white"
            className="mr-x2-small"
            onClick={_doMoveDown}
          />
        )}
        {!isFormDisabled && allowMoveUp && editable && (
          <IconButton
            icon="arrow-up"
            color="blue-action"
            iconColor="white"
            className="mr-x2-small"
            onClick={_doMoveUp}
          />
        )}
        {isCopyOrDelete && editable && <IconButton icon="delete" color="red" iconColor="white" onClick={_doDelete} />}
      </div>
    </div>
  );
};

export default FormControl;
