import { Spinner } from '@blueprintjs/core';
import { Col, Empty, Icon, notification, Row } from 'antd';
import { Paragraph, Text, Title } from 'common-components/typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import DeclineApproveRequestModal from '../DeclineApproveRequestModal';
import UnavailabilityCard from './UnavailabilityCard';

interface IUnavailableTimesProps {
  hasEditPermission: boolean;
  unavailableTimes: any[];
  openUnavailableModal: (selectedTime?: any) => void;
  doCancelRequest: typeof dispatch.teamStore.doCancelRequest;
  doGetTimeAvailability: typeof dispatch.teamStore.doGetTimeAvailability;
  isLoading: boolean;
}

interface IUnavailableTimesState {
  selectedItem: any;
  isLoading: boolean;
  showUnavailableModal: boolean;
}

class UnavailableTimes extends Component<IUnavailableTimesProps, IUnavailableTimesState> {
  state = {
    selectedItem: null,
    isLoading: false,
    showUnavailableModal: false,
  };

  private _addNewUnavailableTime = () => {
    this.props.openUnavailableModal();
  };

  private _openCancelUnavailabilityModal = (item) => {
    this.setState({ showUnavailableModal: true, selectedItem: item });
  };

  private _closeCancelUnavailabilityModal = () => {
    this.setState({ showUnavailableModal: false, selectedItem: null });
  };

  private _cancelUnavailableTime = async () => {
    const { selectedItem } = this.state;
    this.setState({ isLoading: true });

    try {
      const result = await this.props.doCancelRequest({
        supportWorkerAvailabilityRequestId: selectedItem.supportWorkerAvailabilityRequestId,
      });
      notification.success({
        message: 'Unavailability cancelled',
        description: 'You have successfully cancelled this unavailability.',
      });

      if (result) {
        this.props.doGetTimeAvailability({});
      }
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    } finally {
      this._closeCancelUnavailabilityModal();
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { hasEditPermission, unavailableTimes, isLoading } = this.props;

    return (
      <div className="pv-large ph-medium rounded-big shadow-container bg-quaternary">
        {this.state.showUnavailableModal && (
          <DeclineApproveRequestModal
            title="Cancel unavailability"
            content={
              <Paragraph>You are about to cancel this unavailability. You can undo this later if you wish.</Paragraph>
            }
            actionColor="red"
            primaryButtonText="Cancel unavailability"
            onConfirm={this._cancelUnavailableTime}
            onClose={this._closeCancelUnavailabilityModal}
          />
        )}
        <Row type={'flex'} justify={'space-between'} align={'top'} className="mb-12">
          <Col>
            <Title level={4} className="m-none">
              Scheduled unavailability
            </Title>
          </Col>
          <Col className={'align-center'}>
            {hasEditPermission && (
              <div className="text-color-blue-action cursor-pointer" onClick={this._addNewUnavailableTime}>
                <Icon type="plus" /> Add
              </div>
            )}
          </Col>
        </Row>
        <div className="mb-medium">
          <Text type="secondary">Schedule upcoming unavailability, remove or change dates anytime.</Text>
        </div>
        {isLoading ? (
          <Spinner size={80} />
        ) : unavailableTimes.length === 0 ? (
          <>
            <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            <div className="text-align-center text-color-secondary text-size-x2-large">No scheduled unavailability</div>
          </>
        ) : (
          unavailableTimes.map((availableTime, index) => (
            <UnavailabilityCard
              key={availableTime.supportWorkerUnavailabilityId}
              item={availableTime}
              onCancel={this._openCancelUnavailabilityModal}
            />
          ))
        )}
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetTimeAvailability: dispatch.teamStore.doGetTimeAvailability,
  doCancelRequest: dispatch.teamStore.doCancelRequest,
});

export default connect(null, mapDispatch)(UnavailableTimes);
