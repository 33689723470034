import type { PropsWithChildren } from 'react';
import React, { createContext } from 'react';
import type { IconProps } from '..';
import { IconContext } from '../icon/icon-provider';
import type { TabLabelVariants } from './tabs.css';

export type TabsProviderProps = PropsWithChildren<unknown>;

export const TabsContext = createContext<TabsProviderProps>({} as TabsProviderProps);

export type TabLabelIconProviderProps = PropsWithChildren<IconProps & TabLabelVariants>;

export function TabLabelIconProvider(props: TabLabelIconProviderProps): JSX.Element {
  let { children, size, ...otherProps } = props;
  let height;
  let width;

  switch (size) {
    case 'xsmall':
      width = '$size160';
      height = '$size160';
      break;
    case 'small':
      width = '$size200';
      height = '$size200';
      break;
    case 'medium':
      width = '$size250';
      height = '$size250';
      break;
  }

  otherProps = { ...otherProps, height, width };

  return <IconContext.Provider value={otherProps}>{children}</IconContext.Provider>;
}
