import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ArrowTopLeft = forwardRef(function ArrowTopLeft(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 6A.75.75 0 0 1 6 5.25h9.6a.75.75 0 0 1 0 1.5H7.81l10.72 10.72a.75.75 0 1 1-1.06 1.06L6.75 7.81v7.79a.75.75 0 0 1-1.5 0V6Z"
        fill="currentColor"
      />
    </Icon>
  );
});
