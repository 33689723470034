import { Modal } from 'design-components';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IGroupServiceScheduleTimeSlot } from '../../../../interfaces/service-interfaces';
import {
  ExtendGroupSessionScheduleRequest,
  ExtendScheduleCopyOptions,
} from '../../../../stores/hooks/query-hooks/use-mutation-extend-group-session-schedule';
import ExtendScheduleForm from './ExtendScheduleForm';
import ExtendScheduleOptionsForm from './ExtendScheduleOptionsForm';
import ExtendScheduleReview from './ExtendScheduleReview';
import { parseTimeSlots } from './GroupServiceScheduleListingPanel';

type ExtendScheduleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  scheduleName: string;
  scheduleId: string;
  serviceId: string;
  schedulePeriodText: string;
  scheduleTimeSlots: IGroupServiceScheduleTimeSlot[];
  scheduleStartDate: moment.Moment;
  scheduleEndDate: moment.Moment;
  scheduleTimezone: string;
  longestPeriodWeeks: number;
  portalTimezone: string;
  submitExtendSchedule: (request: ExtendGroupSessionScheduleRequest) => void;
  isLoading: boolean;
};

export type ExtendPeriodOption = 'next' | 'custom';

const MODAL_STAGES = ['setup', 'advanced', 'review'] as const;
export type ExtendScheduleModalStage = (typeof MODAL_STAGES)[number];

export type ExtendScheduleFormValue = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  customCopyDate: moment.Moment;
  nextPeriod: ExtendPeriodOption;
};

/**
 * Function takes in a given moment and returns the next Monday from that date.
 * If the date itself is a Monday, it will just return itself.
 *
 * @param date The date to check.
 */
export const nextMonday = (date: moment.Moment): moment.Moment =>
  date.isoWeekday() === 1 ? date : date.clone().startOf('isoWeek').add(1, 'weeks');

const ExtendScheduleModal: React.FC<ExtendScheduleModalProps> = ({
  isOpen,
  isLoading,
  onClose,
  scheduleName,
  scheduleId,
  serviceId,
  schedulePeriodText,
  scheduleTimeSlots,
  scheduleStartDate,
  scheduleEndDate,
  scheduleTimezone,
  longestPeriodWeeks,
  portalTimezone,
  submitExtendSchedule,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'schedules.modal' });

  // The earliest date you can extend a schedule to is the minimum of the schedule start date and next monday.
  const earliestPossible = nextMonday(moment.max(scheduleStartDate, moment()));

  // Default copy range is the nearest longest period week range that does not fall outside the schedule.
  // So it's whatever comes first - the next longest period range, or the last one.
  const nextScheduleWeek = moment.max(scheduleStartDate, moment().startOf('isoWeek').add(1, 'weeks'));
  const defaultCustomCopyDate = nextMonday(
    moment.min(nextScheduleWeek, scheduleEndDate.clone().subtract(longestPeriodWeeks, 'weeks')),
  );

  const [sessionOptions, setSessionOptions] = useState<ExtendScheduleCopyOptions>({
    copyActivityGroups: true,
    copyBookings: true,
    copyShifts: true,
  });

  const [formData, setFormData] = useState<ExtendScheduleFormValue>({
    startDate: earliestPossible.clone(),
    endDate: earliestPossible.clone().add(longestPeriodWeeks, 'weeks'),
    customCopyDate: defaultCustomCopyDate.clone(),
    nextPeriod: 'next',
  });

  const updateSessionOptions = (updates: Partial<ExtendScheduleCopyOptions>) =>
    setSessionOptions({
      ...sessionOptions,
      ...updates,
    });

  const updateFormData = (updates: Partial<ExtendScheduleFormValue>): void =>
    setFormData({
      ...formData,
      ...updates,
      // Start date and custom copy date must fall on a Monday. So if the user selects a date which is not a Monday,
      // they need to be changed to be the Monday of that week.
      ...(updates.startDate && { startDate: updates.startDate.startOf('isoWeek') }),
      ...(updates.customCopyDate && { customCopyDate: updates.customCopyDate.startOf('isoWeek') }),
    });

  const [modalStage, setModalStage] = useState<ExtendScheduleModalStage>('setup');

  // The Antd date pickers render with position: absolute in their own div separate from the rest of the page.
  // This means that clicking on the date picker will be technically outside the modal, causing it to close.
  // So we need to specially keep track of whether date pickers are open or not, and disallow closing if they are.
  const [datePickersOpen, setDatePickersOpen] = useState<boolean>(false);
  const attemptClose = () => datePickersOpen || onClose();

  // If the set end date is earlier than the earliest possible end date, then the form should be disabled.
  const modalDisabled = formData.endDate.isSameOrBefore(formData.startDate);

  const onSubmit = () => {
    if (!modalDisabled && !isLoading) {
      return submitExtendSchedule({
        extendStartDateTime: moment.tz(formData.startDate, scheduleTimezone).startOf('day').toDate(),
        extendEndDateTime: moment.tz(formData.endDate, scheduleTimezone).endOf('day').toDate(),
        customCopyDate: moment.tz(formData.customCopyDate, scheduleTimezone).toDate(),
        serviceScheduleId: scheduleId,
        serviceId,
        sessionOptions,
      });
    }
  };

  const onOk = () => {
    // If we're at the final modal stage, then submit.
    if (modalStage === 'review') return onSubmit();
    setModalStage(MODAL_STAGES[MODAL_STAGES.indexOf(modalStage) + 1]);
  };

  const onCancel = () => {
    if (modalStage === 'setup') return onClose();
    setModalStage(MODAL_STAGES[MODAL_STAGES.indexOf(modalStage) - 1]);
  };

  return (
    <Modal
      header={t(`header.${modalStage}`)}
      isOpen={isOpen}
      onOk={onOk}
      onClose={attemptClose}
      onCancel={onCancel}
      cancelText={t(`cancelText.${modalStage}`)}
      okText={t(`okText.${modalStage}`)}
      isDisabled={modalDisabled || isLoading}
      isLoading={isLoading}
    >
      {modalStage === 'setup' && (
        <ExtendScheduleForm
          scheduleName={scheduleName}
          schedulePeriodText={schedulePeriodText}
          displayTimeSlots={parseTimeSlots(scheduleTimeSlots)}
          longestPeriodWeeks={longestPeriodWeeks}
          formData={formData}
          updateFormData={updateFormData}
          setDatePickersOpen={setDatePickersOpen}
          defaultCustomCopyDate={defaultCustomCopyDate}
          earliestPossible={earliestPossible}
          scheduleEndDate={scheduleEndDate}
        />
      )}
      {modalStage === 'advanced' && (
        <ExtendScheduleOptionsForm sessionOptions={sessionOptions} updateSessionOptions={updateSessionOptions} />
      )}
      {modalStage === 'review' && (
        <ExtendScheduleReview
          existingSchedulePeriodText={schedulePeriodText}
          existingScheduleTimeSlots={scheduleTimeSlots}
          timezone={portalTimezone}
          formData={formData}
          sessionOptions={sessionOptions}
        />
      )}
    </Modal>
  );
};

export default ExtendScheduleModal;
