import React, { PureComponent } from 'react';
import { BookingType, ServiceType } from 'utilities/enum-utils';
import { FaUserFriends, FaUsers, FaUserSlash } from 'react-icons/fa';
import { AiOutlineBook, AiFillHome } from 'react-icons/ai';

interface IBookingTypeIconProps {
  size?: 'small' | 'default' | 'large';
  className?: any;
  type: ServiceType | 'ACTIVITY_RECORD';
  style?: any;
}

export default class BookingTypeIcon extends PureComponent<IBookingTypeIconProps, any> {
  private _getIcon = (type, className, style) => {
    switch (type) {
      case ServiceType.GROUP:
        return <FaUsers className={className} />;
      case ServiceType.INDIVIDUAL:
        return <FaUserFriends className={className} />;
      case ServiceType.COORDINATION:
        return <AiOutlineBook className={className} />;
      case ServiceType.INDEPENDENT_LIVING:
        return <AiFillHome className={className} />;
      case BookingType.ACTIVITY_RECORD:
        return <FaUserSlash className={className} />;
      default:
        return <FaUserSlash className={className} />;
    }
  };
  render() {
    const { className, size = 'default', type, style = {} } = this.props;

    const iconColor = {
      GROUP: 'text-color-indigo',
      INDIVIDUAL: 'text-color-lime',
      COORDINATION: 'text-color-vermilion',
      INDEPENDENT_LIVING: 'text-color-indigo-dark',
      ACTIVITY_RECORD: 'text-color-gold',
    };

    let sizeClasses = '';
    if (size === 'large') {
      sizeClasses = 'text-size-large';
    } else if (size === 'small') {
      sizeClasses = 'text-size-small ';
    }
    const iconClassNames = `${iconColor[type]} ${sizeClasses} ${className}`;

    return this._getIcon(type, iconClassNames, style);
  }
}
