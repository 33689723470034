import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const EuroCircle = forwardRef(function EuroCircle(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 12a8.25 8.25 0 1 1 16.5 0 8.25 8.25 0 0 1-16.5 0ZM12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM8.75 12c0-.084.003-.167.01-.25H12a.75.75 0 0 0 0-1.5H9.261a3.247 3.247 0 0 1 5.177-.4.75.75 0 0 0 1.124-.992 4.752 4.752 0 0 0-7.98 1.392H7a.75.75 0 1 0 0 1.5h.257a4.83 4.83 0 0 0 0 .5H7a.75.75 0 1 0 0 1.5h.583a4.752 4.752 0 0 0 7.98 1.392.75.75 0 0 0-1.125-.992 3.248 3.248 0 0 1-5.177-.4h1.74a.75.75 0 0 0 0-1.5H8.76a3.294 3.294 0 0 1-.01-.25Z"
        fill="currentColor"
      />
    </Icon>
  );
});
