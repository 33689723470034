import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const BatteryCharging = forwardRef(function BatteryCharging(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.712 6.237a.75.75 0 1 0-1.423-.474l-2 6A.75.75 0 0 0 9 12.75h2.96l-1.671 5.013a.75.75 0 0 0 1.423.474l2-6A.75.75 0 0 0 13 11.25h-2.96l1.672-5.013ZM6.473 5.25H7a.75.75 0 0 1 0 1.5h-.5c-.71 0-1.203 0-1.59.027-.38.026-.602.074-.771.144A2.25 2.25 0 0 0 2.921 8.14c-.07.169-.118.39-.144.77-.027.387-.027.882-.027 1.591v3c0 .71 0 1.204.027 1.59.026.38.074.602.144.771.229.551.667.99 1.218 1.218.17.07.39.118.77.144.388.027.882.027 1.591.027H7a.75.75 0 0 1 0 1.5h-.527c-.676 0-1.221 0-1.665-.03-.457-.031-.86-.097-1.243-.255a3.75 3.75 0 0 1-2.03-2.03c-.158-.382-.223-.786-.255-1.242-.03-.444-.03-.99-.03-1.666V10.473c0-.676 0-1.222.03-1.666.032-.456.097-.86.256-1.242a3.75 3.75 0 0 1 2.029-2.03c.382-.158.786-.224 1.243-.255.444-.03.99-.03 1.665-.03ZM17.09 6.777c-.386-.027-.88-.027-1.59-.027H15a.75.75 0 1 1 0-1.5h.527c.676 0 1.222 0 1.666.03.456.031.86.097 1.242.255a3.75 3.75 0 0 1 2.03 2.03c.092.222.152.451.193.694.136.007.26.02.379.044a2.75 2.75 0 0 1 2.16 2.16c.053.268.053.571.053.964v1.146c0 .393 0 .696-.053.963a2.75 2.75 0 0 1-2.16 2.161c-.113.023-.231.035-.36.043A3.751 3.751 0 0 1 17 18.75h-2a.75.75 0 1 1 0-1.5h2A2.25 2.25 0 0 0 19.25 15v-4.5c0-.71 0-1.204-.027-1.59-.026-.38-.074-.602-.144-.771a2.25 2.25 0 0 0-1.218-1.218c-.169-.07-.39-.118-.77-.144Zm3.66 3.695c0-.25 0-.482-.002-.697.494.1.88.487.978.981.02.103.024.243.024.744v1c0 .5-.004.641-.024.744a1.25 1.25 0 0 1-.976.98v-3.753Z"
        fill="currentColor"
      />
    </Icon>
  );
});
