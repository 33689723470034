import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Paperclip = forwardRef(function Paperclip(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.75A3.25 3.25 0 0 0 6.75 6v10a5.25 5.25 0 1 0 10.5 0V5a.75.75 0 0 1 1.5 0v11a6.75 6.75 0 0 1-13.5 0V6a4.75 4.75 0 0 1 9.5 0v10a2.75 2.75 0 1 1-5.5 0V6a.75.75 0 0 1 1.5 0v10a1.25 1.25 0 1 0 2.5 0V6A3.25 3.25 0 0 0 10 2.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
