import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ClipboardSlash = forwardRef(function ClipboardSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.354 3.75c-.394 0-.572.004-.718.02a3.265 3.265 0 0 0-.449.083.75.75 0 1 1-.374-1.453c.213-.054.432-.095.655-.12.234-.027.498-.03.886-.03A2.751 2.751 0 0 1 11 .25h2c1.259 0 2.32.846 2.646 2 .387 0 .652.003.886.03a4.75 4.75 0 0 1 4.188 4.188c.03.266.03.57.03 1.049V13l.002.722v.001c.002.458.004.896-.002 1.296a.75.75 0 1 1-1.5-.021c.006-.388.004-.8.002-1.249L19.25 13V7.571c0-.55-.001-.764-.02-.935a3.25 3.25 0 0 0-2.866-2.866 7.01 7.01 0 0 0-.718-.02 2.751 2.751 0 0 1-2.646 2h-2a2.751 2.751 0 0 1-2.646-2ZM9.75 3c0-.69.56-1.25 1.25-1.25h2a1.25 1.25 0 1 1 0 2.5h-2c-.69 0-1.25-.56-1.25-1.25ZM2.53 1.47a.75.75 0 0 0-1.06 1.06l2.303 2.303a4.742 4.742 0 0 0-.493 1.635c-.03.266-.03.57-.03 1.049V13.056c0 1.838 0 3.294.153 4.433.158 1.172.49 2.121 1.238 2.87.749.748 1.698 1.08 2.87 1.238 1.14.153 2.595.153 4.433.153h.112c1.838 0 3.294 0 4.433-.153 1.157-.156 2.096-.48 2.84-1.208l2.14 2.141a.75.75 0 1 0 1.061-1.06L19.8 18.738 5.246 4.186 2.53 1.47Zm2.24 5.166c.026-.228.075-.45.145-.66l13.352 13.352c-.42.405-.996.65-1.978.782-1.028.138-2.382.14-4.289.14-1.907 0-3.261-.002-4.29-.14-1.005-.135-1.585-.389-2.008-.812-.423-.423-.677-1.003-.812-2.009-.138-1.027-.14-2.382-.14-4.289V7.571c0-.55.001-.764.02-.935Z"
        fill="currentColor"
      />
    </Icon>
  );
});
