import { Avatar, Icon, notification, Select, Spin } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import SelectActivityGroups from 'common-components/activity-groups/selectors/SelectActivityGroups';
import Error from 'common-components/alerts/BookingErrorBanner';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { CustomerAdditionalInfos } from 'common-components/tooltips';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { IGroupServiceActivityGroup, IGroupServiceCustomerRatio } from 'interfaces/service-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ActivityGroupModalType, CustomerType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import CustomerRatioSelectionPanel from 'views/group-services/components/CustomerRatioSelectioPanel';
import CustomerServiceAgreementAlert from './customer-service-agreement-alert';
import { EditRatios, IEditRatiosSubscribe } from 'views/group-services/components/edit-ratios';

type IAddCustomerModalProps = {
  isOpen: boolean;
  history: any;
  onClose: (resetList?: boolean) => void;
  doGetCustomersLite: typeof dispatch.customersStore.doGetCustomersLite;
  setCustomersLite: typeof dispatch.customersStore.setCustomersLite;
  doFetchGroupServiceServiceAgreements: typeof dispatch.groupServiceStore.doFetchGroupServiceServiceAgreements;
  doFetchSessionActivityGroups: typeof dispatch.groupServiceStore.doFetchSessionActivityGroups;
  doFetchGroupServiceActivityGroups: typeof dispatch.groupServiceStore.doFetchGroupServiceActivityGroups;
  doAddCustomerToSession: typeof dispatch.servicesStore.doAddCustomerToSession;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  customersLite: typeof state.customersStore.customersLite;
  groupServiceServiceAgreements: typeof state.groupServiceStore.groupServiceServiceAgreements;
  sessionCustomerDetails: typeof state.groupServiceStore.sessionCustomerDetails;
  portalUser: typeof state.authStore.portalUser;
  groupServiceActivityGroups: typeof state.groupServiceStore.groupServiceActivityGroups;
  sessionActivityGroups: typeof state.groupServiceStore.sessionActivityGroups;
  groupServiceUnAssignedMembers: typeof state.groupServiceStore.groupServiceUnAssignedMembers;
  sessionUnassignedMembers: typeof state.groupServiceStore.sessionUnassignedMembers;
  flags: Record<string, boolean>;
} & FormComponentProps;

type IAddCustomerModalState = {
  step: number;
  isLoading: boolean;
  isSearching: boolean;
  selectedCustomer: any;
  selectedRatioText: any;
  selectedActivityGroups: IGroupServiceActivityGroup[];
  isDisplayScheduledToBeArchivedMessage: boolean;
  ratioForm: IEditRatiosSubscribe;
};

class AddCustomerModal extends Component<IAddCustomerModalProps, IAddCustomerModalState> {
  private customerRatioRef: any;

  state: IAddCustomerModalState = {
    step: 1,
    isLoading: false,
    isSearching: false,
    selectedCustomer: null,
    selectedRatioText: null,
    isDisplayScheduledToBeArchivedMessage: false,
    selectedActivityGroups: [],
    ratioForm: null,
  };

  private _setRef = (ref) => {
    if (ref) {
      this.customerRatioRef = ref;
    }
  };

  private _onNextStep = async () => {
    this.setState({ isLoading: true });
    const { form } = this.props;
    const { step, selectedCustomer } = this.state;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      if (step === 1) {
        await this.props.doFetchGroupServiceServiceAgreements({
          serviceId: this.props.selectedSession.serviceId,
          customerUserId: selectedCustomer.userId,
          serviceDateTimeIds: [this.props.selectedSession.serviceDateTimeId],
        });

        await this.props.doFetchSessionActivityGroups({
          serviceId: this.props.selectedSession.serviceId,
          serviceDateTimeId: this.props.selectedSession.serviceDateTimeId,
        });
      }
      this.setState({ step: this.state.step + 1 });
    }
    this.setState({ isLoading: false });
  };

  private _onPreviousStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  private _onCloseModal = () => {
    this.props.onClose(this.state.step === 3);
    this.setState({
      step: 1,
      selectedRatioText: null,
      selectedCustomer: null,
      isDisplayScheduledToBeArchivedMessage: false,
    });
  };

  private _searchText = async (txt) => {
    const { doGetCustomersLite } = this.props;
    await doGetCustomersLite({
      search: txt,
      userType: CustomerType.CUSTOMERS,
      sortByRelevance: true,
    });
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    if (e.length >= 1) {
      this.setState({ isSearching: true });
      this._debounceSearch(e);
    } else if (e.length === 0) {
      this.props.setCustomersLite({});
    }
  };

  private _changeSelectedCustomer = (customerId) => {
    const { selectedSession, customersLite } = this.props;
    const selectedCustomer = _.find(customersLite, (customer) => customer.userId === customerId);
    this.setState({
      selectedCustomer,
      isDisplayScheduledToBeArchivedMessage:
        selectedCustomer.scheduleArchiveDate &&
        moment
          .tz(selectedSession.endDateTime, selectedSession.timezone)
          .isSameOrAfter(moment.tz(selectedCustomer.scheduleArchiveDate, selectedSession.timezone)),
    });
  };

  private _onInteractWithAddedItems = (addedItems: IGroupServiceActivityGroup[]) => {
    this.setState({ selectedActivityGroups: addedItems });
  };

  private _addCustomerToSession = () => {
    this.customerRatioRef._validateRatios();
  };

  private _getSelectedSessionWithoutTimezone = () => {
    const { selectedSession } = this.props;
    return {
      ...selectedSession,
      startDateTime: moment(
        moment.tz(selectedSession.startDateTime, selectedSession.timezone).format('YYYY-MM-DD HH:mm'),
      ).toDate(),
      endDateTime: moment(
        moment.tz(selectedSession.endDateTime, selectedSession.timezone).format('YYYY-MM-DD HH:mm'),
      ).toDate(),
    };
  };

  private _saveRatio = async (ratios) => {
    const { selectedSession, flags } = this.props;
    const { selectedCustomer, ratioForm } = this.state;

    const selectedSessionWithTimezone = this._getSelectedSessionWithoutTimezone();
    let selectedRatio: IGroupServiceCustomerRatio;

    if (!flags.pinc1004NonTimeBoundRatios) {
      selectedRatio = {
        ...ratios[0],
        startDateTime: moment.tz(moment(ratios[0].startDateTime).format('YYYY-MM-DD HH:mm'), selectedSession.timezone),
        endDateTime: moment.tz(moment(ratios[0].endDateTime).format('YYYY-MM-DD HH:mm'), selectedSession.timezone),
      };
    } else {
      selectedRatio = ratioForm.getRatio();
    }

    try {
      this.setState({ isLoading: true });
      await this.props.doAddCustomerToSession({
        serviceId: selectedSession.serviceId,
        customerUserId: selectedCustomer.userId,
        sessions: [
          {
            serviceDateTimeId: selectedSession.serviceDateTimeId,
            isCustomRatio: selectedRatio.isCustomRatio,
            teamMemberCustomerRatio: selectedRatio.teamMemberCustomerRatio,
            customRatio: _.map(selectedRatio.customRatio, (customTime) => {
              return {
                ...customTime,
                teamMemberCustomerRatio: customTime.teamMemberCustomerRatio.ndis
                  ? customTime.teamMemberCustomerRatio.ndis
                  : customTime.teamMemberCustomerRatio,
                startDateTime: selectedRatio.isCustomRatio
                  ? moment.tz(
                      moment(selectedSessionWithTimezone.startDateTime)
                        .set({
                          hours: moment(customTime.startDateTime.toString()).hour(),
                          minutes: moment(customTime.startDateTime.toString()).minutes(),
                        })
                        .format('YYYY-MM-DD HH:mm'),
                      selectedSession.timezone,
                    )
                  : moment.tz(
                      moment(selectedSessionWithTimezone.startDateTime).format('YYYY-MM-DD HH:mm'),
                      selectedSession.timezone,
                    ),
                endDateTime: selectedRatio.isCustomRatio
                  ? moment.tz(
                      moment(selectedSessionWithTimezone.endDateTime)
                        .set({
                          hours: moment(customTime.endDateTime.toString()).hour(),
                          minutes: moment(customTime.endDateTime.toString()).minutes(),
                        })
                        .format('YYYY-MM-DD HH:mm'),
                      selectedSession.timezone,
                    )
                  : moment.tz(
                      moment(selectedSessionWithTimezone.endDateTime).format('YYYY-MM-DD HH:mm'),
                      selectedSession.timezone,
                    ),
              };
            }),
            durationRatios: selectedRatio.durationRatios,
            activityGroupIds: _.map(this.state.selectedActivityGroups, (group) => group.serviceDateTimeActivityGroupId),
          },
        ],
      });
      this.setState({
        step: 3,
        selectedRatioText:
          selectedRatio && selectedRatio.customRatio.length > 0 && selectedRatio.isCustomRatio
            ? 'custom ratio'
            : selectedRatio.teamMemberCustomerRatio ?? selectedRatio.customRatio?.[0]?.teamMemberCustomerRatio,
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { form, customersLite, selectedSession, sessionCustomerDetails, flags } = this.props;
    const { step, selectedCustomer, isSearching, isLoading, isDisplayScheduledToBeArchivedMessage, ratioForm } =
      this.state;
    const { getFieldDecorator } = form;

    const title = step === 1 ? 'Add booking' : step === 2 ? 'Add booking' : 'Customer added to session';

    const markedCustomerList = _.map(customersLite, (customer) => {
      if (
        _.find(
          sessionCustomerDetails && sessionCustomerDetails.customerUserIds,
          (customerUserId) => customerUserId === customer.userId,
        )
      ) {
        return { ...customer, isAlreadyInSession: true };
      }
      return { ...customer };
    });

    const hasAccessToCustomerDetails = PermissionUtils.validatePermission(
      'ViewCustomerProfile',
      this.props.portalUser.permissions.permissionRoles,
    );

    const session = this._getSelectedSessionWithoutTimezone();
    const selectedRatioText = this.state.selectedRatioText;

    return (
      <>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._onCloseModal}
          title={title}
          showCloseButton={true}
          width={flags.pinc1004NonTimeBoundRatios ? 'large' : step === 1 ? 'large' : 'x-large'}
          canCloseOutside={!isLoading}
        >
          {step === 1 && (
            <>
              <div style={{ marginBottom: 65 }}>
                <div className='mb-large'>
                  <Paragraph>Please select the customer that you would like create a booking for.</Paragraph>
                </div>
                <Form.Item>
                  {getFieldDecorator('customerUserId', {
                    rules: [{ required: true, message: 'Please select a customer' }],
                    initialValue: selectedCustomer ? selectedCustomer.userId : undefined,
                  })(
                    <Select
                      showSearch={true}
                      placeholder={
                        <Text type='secondary'>
                          <Icon type='search' className='mr-small' />
                          Search for customers
                        </Text>
                      }
                      notFoundContent={isSearching ? <Spin size='small' /> : null}
                      onSearch={this._onEnterSearchText}
                      onChange={this._changeSelectedCustomer}
                      filterOption={false}
                      size='large'
                      style={{ width: '100%' }}
                    >
                      {_.map(markedCustomerList, (customer) => (
                        <Select.Option
                          key={customer.userId}
                          value={customer.userId}
                          disabled={customer.isAlreadyInSession}
                        >
                          <Avatar
                            icon='user'
                            size='small'
                            className='mr-small'
                            shape='circle'
                            src={customer.attachmentUrl}
                          />{' '}
                          {customer.firstName} {customer.lastName}
                          {hasAccessToCustomerDetails && !customer.isAlreadyInSession && customer.locality && (
                            <Text color='secondary'> ({customer.locality})</Text>
                          )}
                          {customer.isAlreadyInSession && ' (Already in session)'}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
                {selectedCustomer &&
                  hasAccessToCustomerDetails &&
                  (selectedCustomer.ndisNumber || selectedCustomer.dateOfBirth) && (
                    <CustomerAdditionalInfos
                      customer={selectedCustomer}
                      getOnlyContent={true}
                      noMarginTop={true}
                      hideInformation={['locality']}
                    />
                  )}
                {isDisplayScheduledToBeArchivedMessage && selectedCustomer.scheduleArchiveDate && (
                  <Error
                    errorMessage={
                      <Text>
                        This customer is scheduled to be archived on{' '}
                        <b>
                          {moment
                            .tz(selectedCustomer.scheduleArchiveDate, selectedSession.timezone)
                            .format('DD/MM/YYYY')}
                        </b>{' '}
                        and cannot be added to this session.
                      </Text>
                    }
                    hideErrorTitle
                    className='mv-small align-center'
                  />
                )}
              </div>

              <ActionModalFooter align='right'>
                <SecondaryButton onClick={this._onCloseModal} size='large' disabled={isLoading} className='mr-medium'>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={this._onNextStep}
                  size='large'
                  loading={isLoading}
                  disabled={isDisplayScheduledToBeArchivedMessage}
                >
                  Next
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}
          {step === 2 && (
            <>
              <div className='mv-large p-medium bg-tertiary rounded-big pr-xlarge'>
                <div className='flex justify-between' style={{ marginRight: 80 }}>
                  <div>
                    <div className='mb-small'>
                      <SubTitle>CUSTOMER</SubTitle>
                    </div>
                    <Avatar className='mr-small' shape='circle' icon='user' src={selectedCustomer.attachmentUrl} />{' '}
                    <Text>
                      {selectedCustomer.firstName} {selectedCustomer.lastName}
                    </Text>
                  </div>
                  <div>
                    <div className='mb-small'>
                      <SubTitle>CUSTOMER RATIO</SubTitle>
                    </div>
                    <Text>
                      {this.props.groupServiceServiceAgreements[0]?.teamMemberCustomerRatio?.ndis ??
                        this.props.groupServiceServiceAgreements[0]?.teamMemberCustomerRatio}
                    </Text>
                  </div>
                  <div>
                    <div className='mb-small'>
                      <SubTitle>BOOKING TIME</SubTitle>
                    </div>
                    <Text>
                      {moment.tz(session.startDateTime, session.timezone).format('hh:mm A')}
                      {' - '}
                      {moment.tz(session.endDateTime, session.timezone).format('hh:mm A')}
                    </Text>
                  </div>
                </div>
              </div>
              <div>
                {!flags.pinc1004NonTimeBoundRatios ? (
                  <CustomerRatioSelectionPanel
                    sessions={[session]}
                    wrappedComponentRef={this._setRef}
                    groupServiceServiceAgreements={this.props.groupServiceServiceAgreements}
                    saveRatios={this._saveRatio}
                    timezone={selectedSession.timezone}
                  />
                ) : (
                  <>
                    <CustomerServiceAgreementAlert />
                    <EditRatios
                      type='create'
                      subscribe={(ratioForm) => this.setState({ ratioForm })}
                      serviceAgreementPayload={{
                        serviceId: this.props.selectedSession?.serviceId,
                        customerUserId: selectedCustomer.userId,
                        serviceDateTimeIds: [this.props.selectedSession?.serviceDateTimeId],
                      }}
                    />
                  </>
                )}
              </div>
              <div style={{ marginBottom: 32 }}>
                <SelectActivityGroups
                  serviceId={this.props.selectedSession.serviceId}
                  onInteractWithAddedItems={this._onInteractWithAddedItems}
                  groupSessionActivityGroups={this.props.sessionActivityGroups}
                  isRenderSessionCard={true}
                  modalType={ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP}
                  selectingCustomer={selectedCustomer}
                  timezone={selectedSession.timezone}
                />
              </div>
              <ActionModalFooter>
                <SecondaryButton onClick={this._onPreviousStep} size='large' disabled={isLoading} className='mr-medium'>
                  Back
                </SecondaryButton>
                <PrimaryButton
                  onClick={!flags.pinc1004NonTimeBoundRatios ? this._addCustomerToSession : this._saveRatio}
                  size='large'
                  loading={isLoading}
                  disabled={ratioForm?.hasValidationError}
                >
                  Confirm
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}
          {step === 3 && (
            <>
              {selectedRatioText === 'custom ratio' ? (
                <Paragraph>
                  You have successfully added the following customer to the sessions with a custom ratio
                </Paragraph>
              ) : (
                <Paragraph>
                  You have successfully added the following customer to the sessions with a ratio of{' '}
                  <b>{selectedRatioText}</b>
                </Paragraph>
              )}
              <div className='ml-large mb-x2-large flex-row'>
                {' '}
                <Avatar
                  icon='user'
                  size='small'
                  className='mr-small'
                  shape='circle'
                  src={selectedCustomer.attachmentUrl}
                />{' '}
                {selectedCustomer.firstName} {selectedCustomer.lastName}
              </div>

              <ActionModalFooter>
                <PrimaryButton onClick={this._onCloseModal} size='large'>
                  Close
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}
        </ActionModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
  customersLite: state.customersStore.customersLite,
  groupServiceServiceAgreements: state.groupServiceStore.groupServiceServiceAgreements,
  sessionCustomerDetails: state.groupServiceStore.sessionCustomerDetails,
  portalUser: state.authStore.portalUser,
  groupServiceActivityGroups: state.groupServiceStore.groupServiceActivityGroups,
  sessionActivityGroups: state.groupServiceStore.sessionActivityGroups,
  groupServiceUnAssignedMembers: state.groupServiceStore.groupServiceUnAssignedMembers,
  sessionUnassignedMembers: state.groupServiceStore.sessionUnassignedMembers,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomersLite: dispatch.customersStore.doGetCustomersLite,
  setCustomersLite: dispatch.customersStore.setCustomersLite,
  doFetchGroupServiceServiceAgreements: dispatch.groupServiceStore.doFetchGroupServiceServiceAgreements,
  doAddCustomerToSession: dispatch.servicesStore.doAddCustomerToSession,
  doFetchSessionActivityGroups: dispatch.groupServiceStore.doFetchSessionActivityGroups,
  doFetchGroupServiceActivityGroups: dispatch.groupServiceStore.doFetchGroupServiceActivityGroups,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IAddCustomerModalProps>()(withLDConsumer()(AddCustomerModal)));
