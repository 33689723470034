import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { Text, Button } from '@good/components';
import { ChevronDown } from '@good/icons';

import { WorkTypeState } from 'utilities/enum-utils';
import { Popover } from 'design-components/popover';
import WorkLeaveTypeStateFilterCard from './WorkLeaveTypeStateFilterCard';

export type SelectedWorkLeaveTypeStates = { [key in WorkTypeState]: boolean };

type IButtonLabelProps = {
  visibleStates: WorkTypeState[];
  hasSelectedAll: boolean;
  hasSelectedNone: boolean;
};

const ButtonLabel = ({ visibleStates, hasSelectedAll, hasSelectedNone }: IButtonLabelProps) => {
  const visibleStatesText = (() => {
    if (hasSelectedAll) {
      return 'All';
    }

    if (hasSelectedNone) {
      return '';
    }

    return visibleStates.join(', ');
  })();

  return (
    <div className="flex" style={{ gap: 6 }}>
      <Text className={['font-semibold', hasSelectedNone ? 'text-weak' : ''].join(' ')}>Status</Text>
      {visibleStates.length > 0 && (
        <span style={{ opacity: 0.32 }}>
          <Text className="font-thin">|</Text>
        </span>
      )}
      <Text>{visibleStatesText}</Text>
      <Text className={hasSelectedNone ? 'text-weak' : ''}>
        <ChevronDown />
      </Text>
    </div>
  );
};

type IWorkLeaveTypesStateFilterProps = {
  selectedStates: SelectedWorkLeaveTypeStates;
  setSelectedStates: Dispatch<SetStateAction<SelectedWorkLeaveTypeStates>>;
};

const WorkLeaveTypeStateFilter = ({
  selectedStates,
  setSelectedStates,
}: IWorkLeaveTypesStateFilterProps): JSX.Element => {
  const [isFilterCardOpen, setIsFilterCardOpen] = useState(false);
  const [visibleStates, setVisibleStates] = useState<WorkTypeState[]>([]);
  const filterButton = useRef<HTMLButtonElement>(null);
  const [hasSelectedAll, setHasSelectedAll] = useState<boolean>(false);
  const [hasSelectedNone, setHasSelectedNone] = useState<boolean>(false);

  useEffect(() => {
    const allSelected = Object.keys(selectedStates).every((state) => selectedStates[state as WorkTypeState]);
    const noneSelected = Object.keys(selectedStates).every((state) => !selectedStates[state as WorkTypeState]);
    setHasSelectedAll(allSelected);
    setHasSelectedNone(noneSelected);
  }, [selectedStates]);

  useEffect(() => {
    const allStates = Object.keys(selectedStates) as WorkTypeState[];
    setVisibleStates(allStates.filter((state: WorkTypeState) => selectedStates[state]));
  }, [selectedStates]);

  const onFilterCardClose = () => {
    setIsFilterCardOpen(false);
  };

  return (
    <>
      <div className="flex">
        <Button
          emphasis={hasSelectedNone ? 'outline' : 'quiet'}
          aria-label="Filter by status"
          onPress={() => setIsFilterCardOpen(!isFilterCardOpen)}
          ref={filterButton}
          className={hasSelectedNone ? '' : 'bg-ocean-light-2'}
        >
          <ButtonLabel
            visibleStates={visibleStates}
            hasSelectedAll={hasSelectedAll}
            hasSelectedNone={hasSelectedNone}
          />
        </Button>
      </div>
      <Popover
        isOpen={isFilterCardOpen}
        onClose={onFilterCardClose}
        shouldCloseOnInteractOutside={(e) => {
          if (filterButton.current?.contains(e)) return false;
          return true;
        }}
        backgroundColor="transparent"
      >
        <WorkLeaveTypeStateFilterCard
          onClose={onFilterCardClose}
          selectedStates={selectedStates}
          setSelectedStates={setSelectedStates}
        />
      </Popover>
    </>
  );
};

export default WorkLeaveTypeStateFilter;
