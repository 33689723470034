import apiClient, { pickData } from 'utilities/api-client';
import { categoryType, notification } from '@good/schemas';
import { z } from 'zod';
import { CategoryResponse, NotificationFilters, NotificationsResponse } from '@good/schemas/src/notification';

export async function fetchCategories(): Promise<CategoryResponse> {
  const data = await apiClient
    .get('/api/portal/notification/categories')
    .then(pickData)
    .then((data: []) => z.object({ categories: z.array(categoryType) }).parse(data));
  return data;
}

export const fetchNotifications = async (filters: NotificationFilters): Promise<NotificationsResponse> => {
  const { page, status, category } = filters;
  const params = new URLSearchParams();
  if (page) {
    params.append('page', page);
  }

  if (status) {
    params.append('status', status);
  }

  if (category?.length) {
    params.set('category', category.toString());
  }

  return await apiClient
    .get(`/api/portal/notification?${params.toString()}`)
    .then(pickData)
    .then((data: NotificationsResponse) =>
      z.object({ notifications: z.array(notification), total_count: z.number().default(1) }).parse(data),
    );
};
