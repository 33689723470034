import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ShoppingBag = forwardRef(function ShoppingBag(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25h-.049c-1.535 0-2.755 0-3.726.116-.999.12-1.824.37-2.525.936a4.751 4.751 0 0 0-.343.304c-.644.63-.99 1.42-1.225 2.399-.23.95-.372 2.161-.552 3.686l-.006.049-.264 2.234-.007.06c-.24 2.034-.43 3.64-.402 4.902.028 1.296.284 2.36 1.049 3.22.764.86 1.79 1.24 3.075 1.419 1.25.175 2.867.175 4.915.175h.12c2.048 0 3.665 0 4.915-.175 1.284-.18 2.31-.56 3.075-1.42.764-.86 1.021-1.923 1.049-3.22.027-1.262-.162-2.867-.402-4.901l-.007-.06-.264-2.234-.006-.049c-.18-1.525-.323-2.736-.552-3.686-.236-.979-.582-1.769-1.225-2.399a4.75 4.75 0 0 0-.343-.304c-.701-.566-1.526-.816-2.526-.936-.97-.116-2.19-.116-3.725-.116h-.05ZM6.64 4.47c.397-.32.91-.513 1.763-.615.87-.104 2-.105 3.596-.105 1.595 0 2.725.001 3.596.105.853.102 1.366.294 1.763.615.081.065.16.135.234.208.365.357.616.844.817 1.678.205.853.34 1.975.526 3.56l.264 2.234c.249 2.107.424 3.608.4 4.753-.025 1.124-.242 1.774-.671 2.256-.429.483-1.048.775-2.162.93-1.134.16-2.646.161-4.767.161-2.121 0-3.633-.002-4.767-.16-1.114-.156-1.733-.448-2.162-.93-.43-.483-.646-1.133-.67-2.257-.025-1.145.15-2.646.399-4.753l.264-2.234c.187-1.585.32-2.707.526-3.56.201-.834.452-1.321.817-1.678.074-.073.153-.143.234-.208ZM9.75 9a2.25 2.25 0 0 0 4.5 0V7a.75.75 0 0 1 1.5 0v2a3.75 3.75 0 1 1-7.5 0V7a.75.75 0 0 1 1.5 0v2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
