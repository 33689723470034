import { Badge, Tabs } from 'antd';
import DocumentsPanel from 'common-components/documents/DocumentsPanel';
import WorkflowsPanel from 'common-components/workflow/WorkflowsPanel';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { AvailabilityUnavailabilityRequestStatus } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import WorkerDetailTimeAvailabilityPanel from './availability/WorkerDetailTimeAvailabilityPanel';
import WorkerDetailsBookingPanel from './bookings/WorkerDetailsBookingPanel';
import TeamMemberFormsPanel from './forms/TeamMemberFormsPanel';
import TeamDetailsGeneralPanel from './general/TeamDetailsGeneralPanel';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SupportWorker, WorkDetailsPanel } from './work-details/work-details-panel';

const { TabPane } = Tabs;

type ITeamDetailsTabs = {
  selectedWorkerItem: SupportWorker;
  workerDocuments: typeof state.teamStore.workerDocuments;
  portalUser: typeof state.authStore.portalUser;
  doFetchWorkerDocuments: typeof dispatch.teamStore.doFetchWorkerDocuments;
  doFetchMoreDocuments: typeof dispatch.teamStore.doFetchMoreDocuments;
  setWorkerDocuments: typeof dispatch.teamStore.setWorkerDocuments;
  workflowListOnProfile: typeof state.workflowStore.workflowListOnProfile;
  doFetchWorkflowListOnProfile: typeof dispatch.workflowStore.doFetchWorkflowListOnProfile;
  memberAvailabilities: typeof state.teamStore.memberAvailabilities;
  doGetTimeAvailability: typeof dispatch.teamStore.doGetTimeAvailability;
} & RouteComponentProps<{
  workerId: string;
}>;
const TeamDetailsTabs = ({
  selectedWorkerItem,
  portalUser,
  memberAvailabilities,
  workerDocuments,
  doFetchWorkerDocuments,
  doFetchMoreDocuments,
  setWorkerDocuments,
  workflowListOnProfile,
  doFetchWorkflowListOnProfile,
}: ITeamDetailsTabs) => {
  const [activeTab, setTab] = useState('generalinfo');

  const hasEditPermission = PermissionUtils.validatePermission(
    'EditTeamMemberProfile',
    portalUser.permissions.permissionRoles,
  );

  const { timeAvailabilityRequest, scheduledUnavailabilityRequest } = memberAvailabilities;
  const numAvailabilityRequest = [...timeAvailabilityRequest, ...scheduledUnavailabilityRequest].filter(
    (s) => s.status === AvailabilityUnavailabilityRequestStatus.PENDING_APPROVAL,
  ).length;

  const { crem260: workforceInsightsFeature } = useFlags();

  return (
    <Tabs type='card' className='customer-tab' activeKey={activeTab} onChange={setTab}>
      <TabPane key='generalinfo' tab='General information'>
        <TeamDetailsGeneralPanel
          selectedWorkerItem={selectedWorkerItem}
          hasEditPermission={hasEditPermission}
          onAlertTagView={() => setTab('documents')}
        />
      </TabPane>
      {workforceInsightsFeature ? (
        <TabPane
          key='work-details'
          tab={
            numAvailabilityRequest ? (
              <Badge dot color='#DB3737' offset={[8, 10]}>
                Work Details
              </Badge>
            ) : (
              'Work details'
            )
          }
        >
          <WorkDetailsPanel supportWorker={selectedWorkerItem} hasEditPermission={hasEditPermission} />
        </TabPane>
      ) : (
        <TabPane
          key='availabilities'
          tab={
            numAvailabilityRequest ? (
              <Badge dot color='#DB3737' offset={[8, 10]}>
                Availabilities
              </Badge>
            ) : (
              'Availabilities'
            )
          }
        >
          <WorkerDetailTimeAvailabilityPanel
            selectedWorkerItem={selectedWorkerItem}
            hasEditPermission={hasEditPermission}
          />
        </TabPane>
      )}
      {PermissionUtils.validatePermission('ViewBookingListing', portalUser.permissions.permissionRoles) && (
        <TabPane key='bookings' tab='Bookings'>
          <WorkerDetailsBookingPanel portalUser={portalUser} />
        </TabPane>
      )}
      <TabPane key='documents' tab='Documents'>
        <DocumentsPanel
          selectedItemId={selectedWorkerItem.supportWorkerId}
          itemDocuments={workerDocuments}
          doFetchDocuments={doFetchWorkerDocuments}
          doFetchMoreDocuments={doFetchMoreDocuments}
          setDocuments={setWorkerDocuments}
          hasEditPermission={hasEditPermission}
          itemType='worker'
        />
      </TabPane>
      <TabPane key='forms' tab='Forms'>
        <TeamMemberFormsPanel selectedWorkerUserId={selectedWorkerItem.userId} />
      </TabPane>

      <TabPane key='workflows' tab='Workflows'>
        <WorkflowsPanel
          selectedItemId={selectedWorkerItem.userId}
          itemWorkflows={workflowListOnProfile}
          doFetchWorkflows={doFetchWorkflowListOnProfile}
          timezone={portalUser.timezone}
          itemType='worker'
        />
      </TabPane>
    </Tabs>
  );
};

const mapState = (state: IRootState) => ({
  selectedWorkerItem: state.teamStore.selectedWorker,
  workerDocuments: state.teamStore.workerDocuments,
  memberAvailabilities: state.teamStore.memberAvailabilities,
  portalUser: state.authStore.portalUser,
  workflowListOnProfile: state.workflowStore.workflowListOnProfile,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchWorkerDocuments: dispatch.teamStore.doFetchWorkerDocuments,
  doFetchMoreDocuments: dispatch.teamStore.doFetchMoreDocuments,
  setWorkerDocuments: dispatch.teamStore.setWorkerDocuments,
  doFetchWorkflowListOnProfile: dispatch.workflowStore.doFetchWorkflowListOnProfile,
  doGetTimeAvailability: dispatch.teamStore.doGetTimeAvailability,
});

export default connect(mapState, mapDispatch)(TeamDetailsTabs);
