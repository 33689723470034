import { Clock } from '@good/icons';
import { Text } from '@good/components';
import { NumberFormatter } from '@goodhuman-me/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HoursChanged } from './hours-changed';
import { InsightsSummaryResponse } from 'views/insights/insights';

export type HoursWorkedProps = Pick<InsightsSummaryResponse, 'totalHours' | 'totalHoursChangedPercent'>;
export const HoursWorked = ({ totalHours, totalHoursChangedPercent }: HoursWorkedProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'insights.summary.totalHours' });

  return (
    <div className="align-center flex flex-row justify-center space-x-4">
      <div className="align-center flex h-12 w-12 justify-center rounded-lg bg-canary-ref">
        <Clock />
      </div>

      <div className="flex w-20 flex-col">
        <Text className="text-b-xs text-gray-dark-1">{t('label')}</Text>
        <span aria-valuetext={`${totalHours} total hours`} className="font-base text-h-lg font-semibold">
          <NumberFormatter value={totalHours} maximumSignificantDigits={3} />
        </span>
        <HoursChanged totalHoursChangedPercent={totalHoursChangedPercent} />
      </div>
    </div>
  );
};
