import React, { Component } from 'react';
import { FieldLabel, Text, Title } from 'common-components/typography';
import { PrimaryButton } from 'common-components/buttons';
import { IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
interface ICompletedAgreementPanelProps {
  onClose: any;
  newServiceAgreement: any;
  companyDataLite: typeof state.companyStore.companyDataLite;
}

class CompletedAgreementPanel extends Component<ICompletedAgreementPanelProps, any> {
  render() {
    const { onClose, newServiceAgreement, companyDataLite } = this.props;
    const serviceProviderTimezone = companyDataLite ? companyDataLite.timezone : null;

    return (
      <div className="anim-slide-left">
        <Title level={2}>
          <b>Service Agreement</b> created
        </Title>

        <div className="mt-large mb-x2-large">
          {/*  Content */}

          <div className="mb-large">
            <Text>
              <b>Success!</b> A new Service Agreement has been created for the following dates:
            </Text>
          </div>

          {/*  -=== Start Date ===- */}
          <div className="flex-row mb-large">
            <div className="mr-large">
              <FieldLabel text={'START DATE'} />
              <Text>{moment.tz(newServiceAgreement.startDate, serviceProviderTimezone).format('DD-MM-YYYY')}</Text>
            </div>

            <div>
              <FieldLabel text={'END DATE'} />
              <Text>{moment.tz(newServiceAgreement.endDate, serviceProviderTimezone).format('DD-MM-YYYY')}</Text>
            </div>
          </div>
        </div>

        <div className="mb-x2-large">
          <PrimaryButton size="large" onClick={onClose} icon={'close'}>
            Close
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  newServiceAgreement: state.customersStore.newServiceAgreement,
  companyDataLite: state.companyStore.companyDataLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({});

export default connect(mapState, mapDispatch)(CompletedAgreementPanel);
