import React, { useEffect, useRef, useState } from 'react';
import { Edit } from '@good/icons';
import { Inline, Stack, Text } from '@goodhuman-me/components';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom-v5-compat';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import * as Dialog from './dialog';
import apiClient, { pickData } from 'utilities/api-client';
import { Button } from 'design-components';
import { TextFormField } from './text-field';
import { UserPermissionRole } from 'utilities/enum-utils';
import { Warning } from './warning';
import { useHasPermission } from 'utilities/permission-utils';

import type { IRootDispatch } from 'stores/rematch/root-store';
import type { ISupportWorker } from 'interfaces/supportWorker-interfaces';
import type { PropsWithChildren } from 'react';

type Email = Pick<ISupportWorker, 'email'>;
type WorkerId = { workerId: ISupportWorker['userId'] };
type SupportWorker = Email & WorkerId;

/*************
 ** EditEmail
 *************/

export type EditEmailProps = Email;
export const _EditEmail = (props: EditEmailProps) => {
  const { email } = props;
  const { workerId } = useParams<WorkerId>();
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch<IRootDispatch>();
  const { t } = useTranslation('', { keyPrefix: 'teamMember.details.editEmail' });

  const handleOnOpenChange = () => setOpen((prev) => !prev);

  const handleOnSubmit = (data: Email) => {
    const { email } = data;
    dispatch.teamStore.setSelectedWorkerEmail({ email });
    handleOnOpenChange();
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOnOpenChange}>
      <Dialog.Trigger asChild>
        <Button size="xsmall" emphasis="quiet" alignSelf="flex-start">
          <Edit />
          {t('button')}
        </Button>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content>
          <>
            <Inline padding="$space300" justifyContent="space-between" alignItems="center">
              <Dialog.Title asChild>
                <Text role="heading" fontWeight="$bold" size="medium">
                  {t('heading')}
                </Text>
              </Dialog.Title>
            </Inline>

            <Stack paddingX="$space300" gap="$space200">
              <Dialog.Description asChild>
                <Warning>{t('warning')}</Warning>
              </Dialog.Description>

              <EditEmailForm currentEmail={email} onSubmit={handleOnSubmit} workerId={workerId} />
            </Stack>
          </>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

/*****************
 ** EditEmailForm
 *****************/

const updateEmail = async (data: SupportWorker) => {
  const { workerId, email } = data;
  return apiClient.patch(`/api/portal/support-worker/${workerId}/email`, { email }).then(pickData);
};

const useMutationUpdateEmail = () =>
  useMutation(updateEmail, {
    onSuccess(data: unknown) {
      // TODO (Mitch): replace with toast when available.
      console.log('success', data);
    },
    onError(err) {
      // TODO (Mitch): replace with toast when available.
      console.warn('error', err);
    },
  });

export type EditEmailFormProps = { onSubmit: (data: Email) => void } & { currentEmail: Email['email'] } & WorkerId;
export const EditEmailForm = (props: EditEmailFormProps) => {
  const { currentEmail, onSubmit, workerId } = props;
  const { isLoading, mutateAsync } = useMutationUpdateEmail();
  const { t } = useTranslation('', { keyPrefix: 'teamMember.details.editEmailForm' });

  const validationSchema = useRef(
    z
      .object({
        workerId: z.string().uuid(),
        email: z
          .string()
          .min(1, { message: t('emailAddress.required') })
          .email({ message: t('emailAddress.invalid') }),
      })
      .refine(({ email }) => email !== currentEmail, {
        path: ['email'],
        message: t('emailAddress.noChange'),
      }),
  ).current;

  const { control, handleSubmit, register, setFocus } = useForm({
    defaultValues: { email: '', workerId },
    resolver: zodResolver(validationSchema),
  });

  const handleOnSubmit = async (data: Email & WorkerId) => {
    await mutateAsync(data);
    onSubmit(data);
  };

  useEffect(
    function setEmailFieldFocus() {
      setFocus('email');
    },
    [setFocus],
  );

  return (
    <>
      <Stack paddingBottom="$space300" gap="$space300" asChild>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <input type="hidden" {...register('workerId', { required: true })} />
          <TextFormField label={t('emailAddress.label')} control={control} {...register('email')} />

          <Inline gap="$space200" justifyContent="flex-end">
            <Dialog.Close asChild>
              <Button kind="body" emphasis="quiet">
                Cancel
              </Button>
            </Dialog.Close>

            <Button kind="accent" emphasis="filled" type="submit" isLoading={isLoading}>
              Save
            </Button>
          </Inline>
        </form>
      </Stack>
    </>
  );
};

/**********************
 ** EditEmailGuard
 **********************/

const EditEmailGuard = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const { nas131: isEditEmailFlag } = useFlags();
  const isPermitted = useHasPermission([
    UserPermissionRole.Owner,
    UserPermissionRole.PrimaryOwner,
    UserPermissionRole.TeamManager,
  ]);

  if (!isEditEmailFlag || !isPermitted) return null;
  return <>{children}</>;
};

export const EditEmail = (props: EditEmailProps) => (
  <EditEmailGuard>
    <_EditEmail {...props} />
  </EditEmailGuard>
);
