import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ThumbsDown = forwardRef(function ThumbsDown(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4.75c.964 0 1.612.002 2.095.067.461.062.659.169.789.3.13.13.238.327.3.788.064.483.066 1.131.066 2.095v5.343c0 .879-.013 1.11-.095 1.307-.082.198-.235.37-.857.991l-5.417 5.418a.654.654 0 0 1-1.03-.787l2.8-4.9a.75.75 0 0 0-.65-1.122H8.236c-1.226 0-2.063-.002-2.673-.09-.59-.086-.81-.233-.939-.401-.128-.168-.212-.42-.14-1.012.076-.611.294-1.419.617-2.602l.818-3c.198-.726.33-1.208.474-1.563.136-.336.252-.482.375-.575.123-.094.293-.168.654-.21.38-.046.88-.047 1.632-.047H14Zm3.945-.695c-.456-.455-1.023-.64-1.65-.725-.595-.08-1.344-.08-2.243-.08h-5.04c-.698 0-1.286 0-1.765.057-.508.06-.974.19-1.388.508-.415.317-.664.73-.856 1.206-.18.447-.335 1.014-.519 1.687l-.011.042-.818 3-.016.058c-.303 1.11-.552 2.024-.642 2.757-.094.764-.036 1.486.436 2.104.472.618 1.154.864 1.916.975.73.106 1.677.106 2.828.106H10.707L8.55 19.528c-.82 1.436.216 3.222 1.87 3.222.57 0 1.119-.227 1.523-.63l5.417-5.418.085-.086c.495-.493.886-.884 1.097-1.392.21-.508.21-1.061.21-1.76V7.948c0-.898 0-1.648-.08-2.242-.085-.628-.27-1.195-.726-1.65ZM20.25 14a.75.75 0 0 0 1.5 0V4a.75.75 0 1 0-1.5 0v10Z"
        fill="currentColor"
      />
    </Icon>
  );
});
