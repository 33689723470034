import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps } from 'react-router-dom';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
// Custom CSS
import './new-listings/css/new-booking-listing.css';

import BookingListingPanel from './new-listings/sections/BookingListingPanel';
import CommonUtils from 'utilities/common-utils';
import * as H from 'history';
import { WithShowBookingFilters } from './with-show-bookings-filters';
// Default Body Height

//endregion

interface IBookingListingsV3Props extends RouteComponentProps, FormComponentProps {
  doFetchBookings: typeof dispatch.bookingsStore.doFetchBookings;
  doSetNewBookingData?: typeof dispatch.bookingsStore.doSetNewBookingData;
  selectedFilterKey: typeof state.bookingsStore.selectedFilterKey;
  setSelectedFilterKey: typeof dispatch.bookingsStore.setSelectedFilterKey;
  bookingsFilter: typeof state.bookingsStore.bookingsFilter;
  setBookingsFilter: typeof dispatch.bookingsStore.setBookingsFilter;
  isSelectedAll: boolean;
  setBookingListingActiveTab: typeof dispatch.bookingsStore.setBookingListingActiveTab;
  history: H.History;
  defaultBookingViews: typeof state.bookingsStore.bookingViews;
  setDisplayedBookingListingTabs: typeof dispatch.bookingsStore.setDisplayedBookingListingTabs;
  displayedBookingListingTabs: typeof state.bookingsStore.displayedBookingListingTabs;
  showBookingFilters: boolean;
  setShowBookingFilters: (value: boolean) => void;
}

interface IBookingListingsV3State {
  isLoading: boolean;
}

class BookingListingV3View extends Component<IBookingListingsV3Props, IBookingListingsV3State> {
  // filter config key
  state = {
    isLoading: false,
  };

  _setFilter = async (filterKey) => {
    // this.setState({ currentFilter: filterKey });
    const { setSelectedFilterKey } = this.props;
    // If the user re-click on the selected filter, reset the filters as default
    if (this.props.selectedFilterKey === filterKey) {
      this.props.defaultBookingViews.find((tab) => tab.customViewId === filterKey);
      this.props.setBookingsFilter(
        this.props.defaultBookingViews.find((tab) => tab.customViewId === filterKey).filterValue,
      );
    }
    setSelectedFilterKey(filterKey);
  };

  private _hideFilterTabToggle = () => {
    this.props.setShowBookingFilters(!this.props.showBookingFilters);
  };

  componentDidMount = async () => {
    const {
      selectedFilterKey,
      setSelectedFilterKey,
      setBookingsFilter,
      setBookingListingActiveTab,
      defaultBookingViews,
    } = this.props;

    // default it to TODAY if nothing is selected.
    if (_.isEmpty(selectedFilterKey)) {
      const defaultFilterKey = 'ALL';
      await setBookingListingActiveTab(defaultBookingViews[0]);
      await setSelectedFilterKey(defaultFilterKey);
      await setBookingsFilter(defaultBookingViews[0].filterValue);
    }
  };

  componentWillUnmount() {
    const { setDisplayedBookingListingTabs } = this.props;
    setDisplayedBookingListingTabs([]);
  }

  render() {
    // Used to determine what filter to apply.
    let { selectedFilterKey = 'ALL' } = this.props;

    if (_.isEmpty(selectedFilterKey)) {
      selectedFilterKey = 'ALL';
    }

    const currentFilterConfig = [...this.props.defaultBookingViews, ...this.props.displayedBookingListingTabs].find(
      (view) => view.customViewId === selectedFilterKey,
    );

    const searchFilter = CommonUtils.findFilter('search', this.props.bookingsFilter);
    if (searchFilter) {
      currentFilterConfig.filterValue.search = searchFilter;
    }

    return (
      <div className='flex-row height-full'>
        <BookingListingPanel
          currentFilterConfig={currentFilterConfig}
          hideFilterTabToggle={this._hideFilterTabToggle}
          history={this.props.history}
          setFilter={this._setFilter}
          showFilterSection={this.props.showBookingFilters}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedFilterKey: state.bookingsStore.selectedFilterKey,
  bookingsFilter: state.bookingsStore.bookingsFilter,
  isSelectedAll: state.bookingsStore.bookingsSelectedAll,
  defaultBookingViews: state.bookingsStore.defaultBookingViews,
  displayedBookingListingTabs: state.bookingsStore.displayedBookingListingTabs,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedFilterKey: dispatch.bookingsStore.setSelectedFilterKey,
  setBookingsFilter: dispatch.bookingsStore.setBookingsFilter,
  setBookingListingActiveTab: dispatch.bookingsStore.setBookingListingActiveTab,
  setDisplayedBookingListingTabs: dispatch.bookingsStore.setDisplayedBookingListingTabs,
});

export default WithShowBookingFilters(connect(mapState, mapDispatch)(BookingListingV3View));
