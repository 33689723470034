import React, { forwardRef } from 'react';
import { Select } from 'antd';
import { type SelectProps } from 'antd/es/select';

import { type CountryCode, useLocaleConfig } from 'providers/locale-provider';

/**
 * Antd component for selecting a country.
 */
export const SelectCountryAntd = forwardRef<Select<CountryCode>, SelectProps<CountryCode>>((props, ref) => {
  const { available: availableLocaleConfigs } = useLocaleConfig();

  return (
    <Select
      ref={(ref_) => {
        if (ref) {
          if (typeof ref === 'function') {
            ref(ref_);
          } else {
            ref_ = ref.current;
          }
        }
        return ref_;
      }}
      {...props}
    >
      {availableLocaleConfigs.map((localeConfig) => (
        <Select.Option value={localeConfig.name} key={localeConfig.countryCode}>
          {localeConfig.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectCountryAntd.displayName = 'SelectCountryAntd';
