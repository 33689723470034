import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CloudDrizzle = forwardRef(function CloudDrizzle(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.75a6.25 6.25 0 0 0-6.249 6.363.75.75 0 0 1-.563.74 3.252 3.252 0 0 0-.812 5.963.75.75 0 1 1-.751 1.298 4.751 4.751 0 0 1 .637-8.536 7.75 7.75 0 0 1 15.167-1.79 5.75 5.75 0 0 1 1.022 9.812.75.75 0 1 1-.901-1.2 4.252 4.252 0 0 0-1.02-7.367.75.75 0 0 1-.458-.521A6.253 6.253 0 0 0 12 2.75Zm-3 9.5a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75ZM9.75 18a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2ZM15 12.25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75Zm.75 5.75a.75.75 0 0 0-1.5 0v2a.75.75 0 1 0 1.5 0v-2ZM12 13.25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75Zm.75 5.75a.75.75 0 0 0-1.5 0v2a.75.75 0 1 0 1.5 0v-2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
