import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { IWorkflow } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';
import '../../../../css/workflow-styles.css';
interface Props {
  isOpen: boolean;
  workflowDetail: IWorkflow;
  isLoading: boolean;
  onClose(): void;
  onConfirm(): void;
}

class UndoApprovalModal extends PureComponent<Props> {
  render() {
    const { isOpen, workflowDetail, onClose, onConfirm, isLoading } = this.props;

    return (
      <ActionModal
        width="small"
        isOpen={isOpen}
        onClose={onClose}
        title="Undo workflow completion"
        verticalAlignment="highest"
      >
        <Paragraph size="x-large" className="mt-medium">
          Are you sure you want to undo the completion of this workflow.
        </Paragraph>
        <Paragraph size="x-large" className="mt-large">
          This will revert the workflow to &nbsp;
          <Text weight="bolder">Step {workflowDetail.steps.length}</Text>
        </Paragraph>
        <ActionModalFooter className={'mt-x-large'}>
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={onConfirm} loading={isLoading}>
            Confirm
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default UndoApprovalModal;
