import { useEffect, useState } from 'react';

export function useManualInput(
  isManualInputControlled: boolean | undefined,
  onChangeManualInputProp: ((isManualInput: boolean) => void) | undefined,
  resetValues: () => void,
  clearInternalErrors: () => void,
) {
  const [internalIsManualInput, setInternalIsManualInput] = useState<boolean>(isManualInputControlled ?? false);

  const isControlledManualInput = isManualInputControlled !== undefined;
  const isManualInput = isControlledManualInput ? isManualInputControlled : internalIsManualInput;

  useEffect(() => {
    onChangeManualInputProp?.(internalIsManualInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Cannot guarantee onChangeProp will be callback or setState dispatch
  }, [internalIsManualInput]);

  const toggleOnManualInput = () => {
    resetValues();
    clearInternalErrors();
    setInternalIsManualInput(true);
  };

  const toggleOffManualInput = () => {
    resetValues();
    clearInternalErrors();
    setInternalIsManualInput(false);
  };

  return {
    isManualInput,
    toggleOffManualInput,
    toggleOnManualInput,
  };
}
