import React, { useEffect, useRef, useState } from 'react';
import { Image, ImageProps, Skeleton, rem } from '@good/ui/core';

export type ThumbnailProps = ImageProps;

export const Thumbnail = (props: ThumbnailProps) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.addEventListener('load', () => {
        setIsLoaded(true);
      });
    }
  }, []);

  return (
    <Skeleton
      visible={!isLoaded}
      h={rem(80)}
      w={rem(80)}
      miw={rem(80)}
      radius='lg'
      style={{
        outline: '1px solid rgba(0, 0, 0, 0.06)',
        outlineOffset: '-1px',
      }}
    >
      <Image
        h={rem(80)}
        w={rem(80)}
        miw={rem(80)}
        radius='lg'
        bg='var(--brand-body-light-2)'
        aria-label='preview-thumbnail'
        ref={imageRef}
        {...props}
      />
    </Skeleton>
  );
};
