import React, { PureComponent, ReactNode } from 'react';
import { Icon } from 'antd';
import { PORTAL_COLORS } from 'src/interfaces/common-ui-types';

interface IFilterWarningProps {
  className?: string;
  icon?: string;
  color?: PORTAL_COLORS;
  content: ReactNode;
  displayType?: 'inline-block' | 'inline' | 'block';
  borderNone?: boolean;
}

class Warning extends PureComponent<IFilterWarningProps, any> {
  render() {
    const {
      className = '',
      borderNone = false,
      icon = 'warning',
      content,
      color = 'warning-orange',
      displayType = 'inline-block',
    } = this.props;

    let finalClassName = borderNone
      ? `${displayType} `
      : `bordered-left border border-${color} bg-${color} ${displayType} `;

    finalClassName += className;

    return (
      <div className={finalClassName}>
        <div className="flex p-small">
          <div>
            <Icon type={icon} theme="filled" className={`text-size-x2-large text-color-${color}`} />
          </div>
          <div className="ml-small pr-x-large">{content}</div>
        </div>
      </div>
    );
  }
}

export default Warning;
