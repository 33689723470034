import React from 'react';
import moment, { Moment } from 'moment';
import { Text } from '../../../../../../common-components/typography';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Col, DatePicker, Form, Icon, Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';

interface IServiceDateProps {
  selectedService: any;
  isEdit: boolean;
  serviceAgreementStartDate: Moment;
  serviceAgreementEndDate: Moment;
  startDate: Moment;
  endDate: Moment;
  setStartAndEndDates: (date: Moment, dateType: string) => void;
  form: WrappedFormUtils;
}
const serviceDatesFormat = 'DD/MM/YYYY';

const ServiceDates = (props: IServiceDateProps) => {
  const {
    serviceAgreementStartDate,
    serviceAgreementEndDate,
    startDate,
    endDate,
    isEdit,
    selectedService,
    setStartAndEndDates,
    form,
  } = props;

  const { getFieldDecorator } = form;
  let { t } = useTranslation('', { keyPrefix: 'serviceDates' });

  const validateEndDate = (_rule, date: Moment, validator) => {
    const endDate = date.format('L');
    const startDate = form.getFieldValue('startDate').format('L');

    try {
      if (moment(endDate) <= moment(startDate)) {
        throw new Error('End date must be after the start date.');
      }
    } catch (err) {
      validator(err);
      return;
    }
    validator();
  };

  const toolTip = () => {
    const startDate = serviceAgreementStartDate.format(serviceDatesFormat);
    const endDate = serviceAgreementEndDate.format(serviceDatesFormat);
    const content = t('toolTipInformation', { startDate, endDate });

    return (
      <div className="ml-x-small mt-small">
        <Tooltip2 usePortal={false} position="top" content={content}>
          <Icon type={'info-circle'} className={'text-color-tertiary ml-small'} />
        </Tooltip2>
      </div>
    );
  };

  return (
    <div className="mb-large">
      <div className="mb-x-small">
        <Text weight="bold">Service dates</Text>
      </div>
      <Row type={'flex'}>
        <Col span={4}>
          <Form.Item className="mb-none">
            {getFieldDecorator('startDate', {
              initialValue:
                selectedService && isEdit ? moment(selectedService.startDate) : moment(serviceAgreementStartDate),
              rules: [{ required: true }],
            })(
              <DatePicker
                data-testid="service-start-date"
                value={startDate}
                getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                allowClear={false}
                format={serviceDatesFormat}
                onChange={(value) => setStartAndEndDates(value, 'startDate')}
                disabledDate={(current) => {
                  return (
                    current.isBefore(moment(serviceAgreementStartDate)) ||
                    current.isAfter(moment(serviceAgreementEndDate))
                  );
                }}
              />,
            )}
          </Form.Item>
        </Col>
        <Col>
          <div className="ml-small mr-small mt-small">
            <Text className="text-color-secondary">to</Text>
          </div>
        </Col>
        <Col span={4}>
          <Form.Item className="mb-none">
            {getFieldDecorator('endDate', {
              initialValue:
                selectedService && isEdit ? moment(selectedService.endDate) : moment(serviceAgreementEndDate),
              rules: [
                {
                  required: true,
                  validator: validateEndDate,
                },
              ],
            })(
              <DatePicker
                data-testid="service-end-date"
                value={endDate}
                getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                allowClear={false}
                format={serviceDatesFormat}
                disabledDate={(current) => {
                  return (
                    current.isAfter(moment(serviceAgreementEndDate)) ||
                    current.isBefore(moment(form.getFieldValue('startDate')).add(1, 'day'))
                  );
                }}
                onChange={(value) => setStartAndEndDates(value, 'endDate')}
              />,
            )}
          </Form.Item>
        </Col>
        <Col span={5}>{toolTip()}</Col>
      </Row>
    </div>
  );
};

export default ServiceDates;
