import React, { useState } from 'react';
import moment from 'moment';
import { Popover } from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import { Text } from 'common-components/typography';
import { Icon } from 'antd';

export function DaySelector({
  onChangeDate,
  selectedDate,
  selectedWeek,
  mode,
}: {
  onChangeDate: any;
  selectedDate: Date;
  selectedWeek: [Date, Date];
  mode: string;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const dayDisplay = moment(selectedDate).format('ddd, D MMMM YYYY');

  const weekDisplay = `${moment(selectedWeek[0]).format('ddd, D MMM')} - ${moment(selectedWeek[1]).format(
    'ddd, D MMM YYYY',
  )}`;

  const openPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);

  function selectDay(selectedDate) {
    onChangeDate(selectedDate);
    closePopup();
  }

  return (
    <Popover
      content={
        <div>
          <DatePicker className='focus-none' value={selectedDate} onChange={selectDay} />
        </div>
      }
      isOpen={isOpen}
      onClose={closePopup}
    >
      <div
        className='hover-bg-quaternary cursor-pointer rounded-big select-none'
        onClick={openPopup}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            openPopup();
          }
        }}
        role='button'
        tabIndex={0}
      >
        <div>
          <Text size='x-large' weight='bold'>
            {mode === 'daily' ? dayDisplay : weekDisplay}
          </Text>
          <Icon type='down' style={{ fontSize: '16px' }} className='text-color-blue-action ml-small' />
        </div>
      </div>
    </Popover>
  );
}
