import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Bell = forwardRef(function Bell(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25a7.547 7.547 0 0 0-7.488 6.61l-.99 7.912a2.65 2.65 0 0 0 2.63 2.978h1.36l.053.118a4.853 4.853 0 0 0 8.87 0l.053-.118h1.36a2.65 2.65 0 0 0 2.63-2.978l-.99-7.911A7.547 7.547 0 0 0 12 2.25Zm4.017 16a.93.93 0 0 0-.033 0H8.016a.93.93 0 0 0-.033 0H6.152a1.15 1.15 0 0 1-1.14-1.292L6 9.047a6.047 6.047 0 0 1 12 0l.99 7.911a1.15 1.15 0 0 1-1.141 1.292h-1.832Zm-6.812 1.5h5.59a3.354 3.354 0 0 1-5.59 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
