import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CameraSlash = forwardRef(function CameraSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25h-.127c-1.202 0-1.978 0-2.704.239a4.75 4.75 0 0 0-.652.27c-.682.344-1.23.892-2.08 1.743l-.09.089-.613.613a.156.156 0 0 1-.11.046c-.102 0-.203.003-.303.01l-2.79-2.79A.75.75 0 0 0 1.47 2.53l3.046 3.046L19.738 20.8l1.732 1.731a.75.75 0 0 0 1.06-1.06l-1.142-1.142c.727-.744 1.052-1.683 1.208-2.839.153-1.14.153-2.595.153-4.433V8.625a4.375 4.375 0 0 0-4.375-4.375.156.156 0 0 1-.11-.046l-.613-.613-.09-.09c-.85-.85-1.398-1.398-2.08-1.742a4.749 4.749 0 0 0-.652-.27c-.726-.24-1.502-.24-2.704-.239H12ZM9.213 8.153 6.536 5.476c.093-.061.18-.132.259-.211l.613-.613c.97-.97 1.344-1.332 1.785-1.554.144-.072.293-.134.446-.185.469-.154.988-.163 2.36-.163 1.372 0 1.891.009 2.36.163.153.05.303.113.447.185.44.222.814.583 1.784 1.554l.613.613c.31.31.732.485 1.171.485a2.875 2.875 0 0 1 2.875 2.875V13c0 1.907-.002 3.262-.14 4.29-.132.98-.376 1.556-.781 1.977l-4.48-4.48a4.75 4.75 0 0 0-6.634-6.634Zm1.08 1.08 4.473 4.473a3.25 3.25 0 0 0-4.473-4.473ZM3.053 7.337a.75.75 0 1 0-1.34-.674 4.349 4.349 0 0 0-.464 1.962v4.431c0 1.838 0 3.294.153 4.433.158 1.172.49 2.121 1.238 2.87.749.748 1.698 1.08 2.87 1.238 1.14.153 2.595.153 4.433.153H16.002a.75.75 0 0 0-.004-1.5H9.999c-1.907 0-3.261-.002-4.29-.14-1.005-.135-1.585-.389-2.008-.812-.423-.423-.677-1.003-.812-2.009-.138-1.027-.14-2.382-.14-4.289V8.625a2.898 2.898 0 0 1 .304-1.288ZM8.75 12a.75.75 0 0 0-1.5 0A4.75 4.75 0 0 0 12 16.75a.75.75 0 0 0 0-1.5A3.25 3.25 0 0 1 8.75 12Z"
        fill="currentColor"
      />
    </Icon>
  );
});
