import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Text } from '@goodhuman-me/components';

import { Textarea } from 'design-components/textarea';
import { EValidationStatus } from 'design-components/types';
import CancelBookingFieldError from './CancelBookingFieldError';
import { Schema, SchemaFieldNames } from '../schema';
import { IField } from '../fields';

import CancelBookingTextAreaLegacy from './legacy/CancelBookingTextArea';

export const USE_LEGACY = false;

interface ICancelBookingTextAreaProps {
  field: IField<SchemaFieldNames, string | undefined>;
  label: string;
}

const CancelBookingTextArea = ({ field, label }: ICancelBookingTextAreaProps) => {
  const { control } = useFormContext<Schema>();

  return (
    <>
      {label && (
        <Box paddingBottom="$xsmall">
          <Text fontWeight={700}>{label}</Text>
        </Box>
      )}
      <Controller
        name={field.name}
        defaultValue={field.defaultValue}
        control={control}
        rules={field.rules}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
          <>
            <Textarea
              placeholder="Type cancellation reason"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              aria-label={field.name}
              autoSize={{ minRows: 5, maxRows: 5 }}
              ref={ref}
              validations={[{ status: error ? EValidationStatus.Invalid : EValidationStatus.Valid }]}
              marginBottom="$xsmall"
            />
            <CancelBookingFieldError message={error?.message} />
          </>
        )}
      />
    </>
  );
};

export default USE_LEGACY ? CancelBookingTextAreaLegacy : CancelBookingTextArea;
