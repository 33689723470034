import React from 'react';
import { Row } from 'antd';
import { ActivityGroupMemberType, ActivityGroupModalType } from 'utilities/enum-utils';
import { IGroupServiceActivityGroup } from 'src/interfaces/service-interfaces';
import { openModalFuncType } from '../GroupServiceActivityGroupsPanel';
import { Classes } from '@blueprintjs/popover2';

interface IEmptyStateProps {
  activityGroup: IGroupServiceActivityGroup;
  openModal: openModalFuncType;
}

function ActivityGroupPopoverContent(props: IEmptyStateProps) {
  const { activityGroup, openModal } = props;

  return (
    <div className={Classes.POPOVER2_DISMISS}>
      <Row
        className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
        onClick={() => openModal(activityGroup, ActivityGroupModalType.EDIT)}
      >
        Edit activity group
      </Row>
      <Row
        className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
        onClick={() => openModal(activityGroup, ActivityGroupModalType.DUPLICATE)}
      >
        Duplicate activity group
      </Row>
      <div className="bordered-top mh-medium" />

      <Row
        className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
        onClick={() => {
          openModal(activityGroup, ActivityGroupModalType.ADD_CUSTOMER);
        }}
      >
        Add new customers
      </Row>
      <Row
        className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
        onClick={() => openModal(activityGroup, ActivityGroupModalType.REMOVE_ALL, ActivityGroupMemberType.CUSTOMER)}
      >
        Remove all customers
      </Row>
      <div className="bordered-top mh-medium" />

      <Row
        className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
        onClick={() => {
          openModal(activityGroup, ActivityGroupModalType.ADD_TEAM_MEMBER, ActivityGroupMemberType.TEAM_MEMBER);
        }}
      >
        Add new team members
      </Row>
      <Row
        className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
        onClick={() => openModal(activityGroup, ActivityGroupModalType.REMOVE_ALL, ActivityGroupMemberType.TEAM_MEMBER)}
      >
        Remove all team members
      </Row>
      <div className="bordered-top mh-medium" />

      <Row
        className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest text-color-red"
        onClick={() => openModal(activityGroup, ActivityGroupModalType.DELETE)}
      >
        Delete activity group
      </Row>
    </div>
  );
}

export default ActivityGroupPopoverContent;
