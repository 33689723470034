import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const backdrop = {
  position: 'fixed',
  zIndex: 100,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: '$blackTransparent',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
};

export const verticalFreeSpace: CSS = {
  '@initial': {
    display: 'none',
  },
  '@small': {
    display: 'block',
  },
};

export const reset: CSS = {
  boxSizing: 'border-box',
  outline: 'none',
  maxWidth: '100%',
};

export const modalWrapper: CSS = {
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: 'fit-content',
  flexShrink: 0,
  height: '$sizeFull',

  '@small': {
    height: 'unset',
    paddingTop: '$space50 0',
  },
};

export const modal: CSS = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$white',
  fontFamily: '$primary',
  fontSize: '$font75',
  boxShadow: '$medium',
};

export const variants = css({
  width: '$$modalWidth',
  height: '$$modalHeight',

  $$modalWidth: '100%',
  $$modalHeight: '100%',
  borderRadius: '$none',

  variants: {
    /** Modal width */
    size: {
      small: {
        '@small': {
          $$modalWidth: '640px',
          $$modalHeight: 'fit-content',
          borderRadius: '8px',
        },
      },
      medium: {
        '@small': {
          $$modalWidth: '720px',
          $$modalHeight: 'fit-content',
          borderRadius: '8px',
        },
      },
      large: {
        '@small': {
          $$modalWidth: '928px',
          $$modalHeight: 'fit-content',
          borderRadius: '8px',
        },
      },
    },
  },
});

export type ModalVariants = Stitches.VariantProps<typeof variants>;

export const modalBody: CSS = {
  position: 'relative',
  fontFamily: '$primary',
  fontSize: '$font75',

  padding: '$space0 $space300',

  height: '100%',
  overflowY: 'auto',

  '&::-webkit-scrollbar, & ::-webkit-scrollbar': {
    height: '$size1450',
    width: '$size75',
  },

  '&::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb': {
    backgroundColor: '$bodyLight',
    borderRadius: '$medium',
  },

  '@small': {
    height: 'fit-content',
  },
};
