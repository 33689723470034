import React, { Component } from 'react';
import { PermissionRowBase } from 'views/account-management/add-new-member/panels/permissions-panel/portal/PermissionRowBase';
import { IPermissionRole, IPermissions } from 'interfaces/account-interfaces';
import FormBuilderSectionModal from 'views/account-management/components/FormBuilderSectionModal';
import { getFormBuilderPermissionName, getFormBuilderRole } from 'views/account-management/utils/permission-utils';
import { UserPermissionRole } from 'utilities/enum-utils';

interface FormBuilderPermissionRowProps {
  permissions: IPermissions;
  onChangePermissions: any;
  actionsDisabled: boolean;
  hasAllAccess: boolean;
}

interface FormBuilderPermissionRowState {
  formBuilderModalOpen: boolean;
}

class FormBuilderPermissionRow extends Component<FormBuilderPermissionRowProps, FormBuilderPermissionRowState> {
  state = { formBuilderModalOpen: false };

  private _onOpenFormBuilder = () => this.setState({ formBuilderModalOpen: true });
  private _onCloseFormBuilder = () => this.setState({ formBuilderModalOpen: false });

  // Triggered whenever a selection is made.
  private _onSelectFormBuilder = (result) => {
    const { permissions, onChangePermissions } = this.props;
    const { permissionRoles } = permissions;

    // filter out Form builder permissions first
    const filteredRoles = permissionRoles.filter((role) => role.permissionRole !== UserPermissionRole.FormBuilder);

    // Remap back to FormBuilderRole
    const targetRole = getFormBuilderRole(result.formBuilderPermission);

    if (targetRole) {
      const newFormBuilderRole: IPermissionRole = {
        permissionRole: targetRole,
        serviceDepartmentId: null,
        serviceId: null,
      };
      const updatedPermissions = { ...permissions, permissionRoles: [...filteredRoles, newFormBuilderRole] };
      onChangePermissions(updatedPermissions);
    } else {
      const updatedPermissions = { ...permissions, permissionRoles: filteredRoles };
      onChangePermissions(updatedPermissions);
    }
  };

  render() {
    const { permissions } = this.props;

    const formBuilderPermissionName = getFormBuilderPermissionName(permissions, this.props.hasAllAccess);

    return (
      <>
        {/* Display Row */}
        <PermissionRowBase
          iconName={'form'}
          title={'Form-builder'}
          description={'Access to form builder'}
          currentFlag={formBuilderPermissionName}
          onClickModify={this._onOpenFormBuilder}
          actionsDisabled={this.props.actionsDisabled}
        />

        {/* Modify Edit dialog */}
        <FormBuilderSectionModal
          isOpen={this.state.formBuilderModalOpen}
          onClose={this._onCloseFormBuilder}
          setSelectedOption={this._onSelectFormBuilder}
          permissions={permissions}
        />
      </>
    );
  }
}

export default FormBuilderPermissionRow;
