import { Button, Divider, Form, Icon, Row, Upload } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { HyperlinkButton, SecondaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { IWorkflow, IWorkflowAction, IWorkflowLinkedFormAttachment } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import EditValueFormModal from 'views/form-builder/components/EditValueFormModal';
import { FILE_EXTENSION_INVALID_ERROR_MESSAGE } from 'views/workflows/utils/constants';
import { getActiveStep, validFileExtension } from 'views/workflows/utils/workflow-utils';
import LinkedFormElement from '../../LinkedFormElement';
import LoadingProcess from '../../LoadingProcess';
import { Badge } from '../../../../../workflow-templates/create/components';
import { IElementValue } from 'views/form-builder/shared/form-interface';
import { downloadFileFromUrl } from 'utilities/file-utils';

interface Props extends FormComponentProps {
  isDisableAction: boolean;
  selectedWorkflowAction: IWorkflowAction;
  selectedWorkflow: IWorkflow;
  workflowLinkedForm: IWorkflowLinkedFormAttachment;
  attachmentFile: IWorkflowLinkedFormAttachment;
  addAttachmentFile: (file: IWorkflowLinkedFormAttachment) => void;
  doAddLinkedFormAttachment: typeof dispatch.workflowStore.doAddLinkedFormAttachment;
  doGetLinkedFormAttachment: typeof dispatch.workflowStore.doGetLinkedFormAttachment;
  timezone: string;
}

interface State {
  isLoading: boolean;
  errorMessage: string;
  isOpenEditValueFormModal: boolean;
}

class WorkflowLinkedFormPanel extends PureComponent<Props, State> {
  state = {
    isLoading: false,
    errorMessage: '',
    isOpenEditValueFormModal: false,
  };

  private _validateFile = (file: any) => {
    const { addAttachmentFile } = this.props;

    const isValidType = validFileExtension(file.name);

    this.setState({ errorMessage: null });
    if (!isValidType) {
      this.setState({
        errorMessage: FILE_EXTENSION_INVALID_ERROR_MESSAGE,
      });
      return false;
    } else {
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        this.setState({ errorMessage: 'File must be smaller than 25MB!' });
        return false;
      } else {
        addAttachmentFile(file);
        return true;
      }
    }
  };

  private _onOpenEditValueFormModal = () => {
    this.setState({ isOpenEditValueFormModal: true });
  };

  private _onCloseEditValueFormModal = () => {
    this.setState({ isOpenEditValueFormModal: false });
  };

  private _onSaveEditValueFormModal = async (id: string, elementValues: IElementValue[], status: string) => {
    const { doAddLinkedFormAttachment, selectedWorkflow, workflowLinkedForm, doGetLinkedFormAttachment } = this.props;
    const activeStep = selectedWorkflow && getActiveStep(selectedWorkflow.steps);

    await doAddLinkedFormAttachment({
      type: 'form',
      formData: elementValues,
      formVersionId: workflowLinkedForm && workflowLinkedForm.formVersionId,
      workflowStepId: activeStep.workflowStepId,
      workflowId: selectedWorkflow && selectedWorkflow.workflowId,
      status,
    });

    this._onCloseEditValueFormModal();
    this.setState({ isLoading: true });

    await doGetLinkedFormAttachment({
      workflowStepId: activeStep.workflowStepId,
      workflowId: selectedWorkflow && selectedWorkflow.workflowId,
    });

    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    const { selectedWorkflow, doGetLinkedFormAttachment } = this.props;
    const activeStep = selectedWorkflow && getActiveStep(selectedWorkflow.steps);

    this.setState({ isLoading: true });

    await doGetLinkedFormAttachment({
      workflowStepId: activeStep.workflowStepId,
      workflowId: selectedWorkflow && selectedWorkflow.workflowId,
    });

    this.setState({ isLoading: false });
  };

  render() {
    const { isLoading, errorMessage, isOpenEditValueFormModal } = this.state;
    const { attachmentFile, workflowLinkedForm, form, isDisableAction, selectedWorkflowAction, timezone } = this.props;

    const formContent = workflowLinkedForm
      ? {
          title: { formTitle: workflowLinkedForm.formName, formDescription: workflowLinkedForm.formDescription },
          elements: workflowLinkedForm.formElements,
        }
      : null;

    console.log('wlf::', workflowLinkedForm);

    return (
      <div className='bg-quaternary'>
        {isOpenEditValueFormModal && workflowLinkedForm && workflowLinkedForm.formElements && (
          <EditValueFormModal
            isOpen={isOpenEditValueFormModal}
            onClose={this._onCloseEditValueFormModal}
            formElements={formContent}
            elementValues={workflowLinkedForm.formData}
            formId={null}
            timezone={timezone}
            onSave={this._onSaveEditValueFormModal}
            mode='ADD'
            customActionName
          />
        )}

        <div className='flex justify-between'>
          <Text size='x3-large' weight='bolder'>
            Linked Form
          </Text>
          {selectedWorkflowAction &&
            selectedWorkflowAction.canChangeMember &&
            workflowLinkedForm &&
            workflowLinkedForm.formVersionId &&
            !isDisableAction && (
              <SecondaryButton onClick={this._onOpenEditValueFormModal}>Edit form...</SecondaryButton>
            )}
        </div>
        <div className='bg-white p-x-large mt-large'>
          {isLoading ? (
            <LoadingProcess />
          ) : workflowLinkedForm && workflowLinkedForm.name ? (
            <Row>
              <Text>Please download the following attachment. Once completed attach the completed version below</Text>
              <div className='flex-column mt-large'>
                <Text size='small' color='secondary' weight='bolder'>
                  ATTACHMENT
                </Text>

                <HyperlinkButton
                  onClick={() =>
                    downloadFileFromUrl({
                      documentUrl: attachment.url,
                      documentName: attachment.name,
                    })
                  }
                  className='word-break-all'
                >
                  <Icon type='download' className='text-color-blue-action mr-small' />
                  {workflowLinkedForm.name}
                </HyperlinkButton>
              </div>

              <Divider />

              <div className='flex-column'>
                <Text size='small' color='secondary' weight='bolder'>
                  ATTACH COMPLETED FORM HERE
                </Text>
                {attachmentFile ? (
                  <Text className='mb-small'>{attachmentFile.name}</Text>
                ) : (
                  <Text className='mb-small'>{workflowLinkedForm.uploadedAttachment}</Text>
                )}
                <div className='mb-small'>
                  <Upload multiple={false} beforeUpload={this._validateFile} showUploadList={false}>
                    {!attachmentFile && !workflowLinkedForm.uploadedAttachment && (
                      <HyperlinkButton>
                        <Icon type='plus' /> Select file...
                      </HyperlinkButton>
                    )}
                    {(attachmentFile || workflowLinkedForm.uploadedAttachment) && (
                      <HyperlinkButton>
                        <Icon type='edit' /> Select a different file...
                      </HyperlinkButton>
                    )}
                  </Upload>
                </div>
              </div>
              {errorMessage && (
                <div className='mt-medium'>
                  <Text color='red'>{errorMessage}</Text>
                </div>
              )}
            </Row>
          ) : workflowLinkedForm && workflowLinkedForm.formVersionId ? (
            <LinkedFormElement form={form} formContent={formContent} formData={workflowLinkedForm.formData} />
          ) : (
            <Text>No form linked to this step.</Text>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddLinkedFormAttachment: dispatch.workflowStore.doAddLinkedFormAttachment,
  doGetLinkedFormAttachment: dispatch.workflowStore.doGetLinkedFormAttachment,
});

export default connect(null, mapDispatch)(Form.create<Props>()(WorkflowLinkedFormPanel));

class _WorkflowLinkedForm extends PureComponent<Props, State> {
  state = {
    isLoading: false,
    errorMessage: '',
    isOpenEditValueFormModal: false,
  };

  private _onOpenEditValueFormModal = () => {
    this.setState({ isOpenEditValueFormModal: true });
  };

  private _onCloseEditValueFormModal = () => {
    this.setState({ isOpenEditValueFormModal: false });
  };

  private _onSaveEditValueFormModal = async (id: string, elementValues: IElementValue[], status: string) => {
    const { doAddLinkedFormAttachment, selectedWorkflow, workflowLinkedForm, doGetLinkedFormAttachment } = this.props;
    const activeStep = selectedWorkflow && getActiveStep(selectedWorkflow.steps);

    await doAddLinkedFormAttachment({
      type: 'form',
      formData: elementValues,
      formVersionId: workflowLinkedForm && workflowLinkedForm.formVersionId,
      workflowStepId: activeStep.workflowStepId,
      workflowId: selectedWorkflow && selectedWorkflow.workflowId,
      status,
    });

    this._onCloseEditValueFormModal();
    this.setState({ isLoading: true });

    await doGetLinkedFormAttachment({
      workflowStepId: activeStep.workflowStepId,
      workflowId: selectedWorkflow && selectedWorkflow.workflowId,
    });

    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    const { selectedWorkflow, doGetLinkedFormAttachment } = this.props;
    const activeStep = selectedWorkflow && getActiveStep(selectedWorkflow.steps);

    this.setState({ isLoading: true });

    await doGetLinkedFormAttachment({
      workflowStepId: activeStep.workflowStepId,
      workflowId: selectedWorkflow && selectedWorkflow.workflowId,
    });

    this.setState({ isLoading: false });
  };

  render() {
    const { isOpenEditValueFormModal } = this.state;
    const { workflowLinkedForm, isDisableAction, selectedWorkflowAction, timezone } = this.props;

    const formContent = workflowLinkedForm
      ? {
          title: { formTitle: workflowLinkedForm.formName, formDescription: workflowLinkedForm.formDescription },
          elements: workflowLinkedForm.formElements,
        }
      : null;

    return (
      <div>
        {isOpenEditValueFormModal && workflowLinkedForm && workflowLinkedForm.formElements && (
          <EditValueFormModal
            isOpen={isOpenEditValueFormModal}
            onClose={this._onCloseEditValueFormModal}
            formElements={formContent}
            elementValues={workflowLinkedForm.formData}
            formId={null}
            onSave={this._onSaveEditValueFormModal}
            timezone={timezone}
            mode='ADD'
            customActionName='Update'
          />
        )}

        <div>
          {selectedWorkflowAction &&
            selectedWorkflowAction.canChangeMember &&
            workflowLinkedForm &&
            workflowLinkedForm.formVersionId &&
            !isDisableAction && (
              <div style={{ fontSize: '14px', display: 'flex', flexDirection: 'column' }}>
                <span>{workflowLinkedForm.formName}</span>

                <Button
                  type='link'
                  style={{ alignSelf: 'start', fontSize: '14px', marginLeft: '-8px', color: '#106BA3 !important' }}
                  size='small'
                  onClick={this._onOpenEditValueFormModal}
                  color='#106BA3'
                >
                  Edit form
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export const WorkflowLinkedForm = connect(null, mapDispatch)(Form.create<Props>()(_WorkflowLinkedForm));

class _WorkflowLinkedAttachment extends PureComponent<Props, State> {
  state = {
    isLoading: false,
    errorMessage: '',
    isOpenEditValueFormModal: false,
  };

  private _validateFile = (file: any) => {
    const { addAttachmentFile } = this.props;

    const isValidType = validFileExtension(file.name);

    this.setState({ errorMessage: null });
    if (!isValidType) {
      this.setState({
        errorMessage: FILE_EXTENSION_INVALID_ERROR_MESSAGE,
      });
      return false;
    } else {
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        this.setState({ errorMessage: 'File must be smaller than 25MB!' });
        return false;
      } else {
        addAttachmentFile(file);
        return true;
      }
    }
  };

  componentDidMount = async () => {
    const { selectedWorkflow, doGetLinkedFormAttachment } = this.props;
    const activeStep = selectedWorkflow && getActiveStep(selectedWorkflow.steps);

    this.setState({ isLoading: true });

    await doGetLinkedFormAttachment({
      workflowStepId: activeStep.workflowStepId,
      workflowId: selectedWorkflow && selectedWorkflow.workflowId,
    });

    this.setState({ isLoading: false });
  };

  render() {
    const { isLoading, errorMessage } = this.state;
    const { attachmentFile, workflowLinkedForm, form } = this.props;

    const formContent = workflowLinkedForm
      ? {
          title: { formTitle: workflowLinkedForm.formName, formDescription: workflowLinkedForm.formDescription },
          elements: workflowLinkedForm.formElements,
        }
      : null;

    return (
      <div>
        {isLoading ? (
          <LoadingProcess />
        ) : workflowLinkedForm?.name ? (
          <Row>
            <div className='flex-column'>
              <HyperlinkButton
                onClick={() =>
                  downloadFileFromUrl({
                    documentUrl: workflowLinkedForm.url,
                    documentName: workflowLinkedForm.name,
                  })
                }
                className='word-break-all'
              >
                <Icon type='download' className='text-color-blue-action mr-small' />
                {workflowLinkedForm.name}
              </HyperlinkButton>
            </div>

            {/* <Divider style={{ margin: '12px 0' }} /> */}

            <div className='flex-column mt-small'>
              {attachmentFile && <Badge>{attachmentFile.name}</Badge>}
              {workflowLinkedForm?.uploadedAttachment && <Badge>{workflowLinkedForm.uploadedAttachment}</Badge>}
              <div className='mt-small mb-small'>
                <Upload multiple={false} beforeUpload={this._validateFile} showUploadList={false}>
                  {!attachmentFile && !workflowLinkedForm.uploadedAttachment && (
                    <HyperlinkButton>
                      <Icon type='paper-clip' className='mr-small' />
                      <span>Upload document</span>
                    </HyperlinkButton>
                  )}
                  {(attachmentFile || workflowLinkedForm.uploadedAttachment) && (
                    <HyperlinkButton>
                      <Icon type='paper-clip' className='mr-small' />
                      <span>Upload document</span>
                    </HyperlinkButton>
                  )}
                </Upload>
              </div>
            </div>
            {errorMessage && (
              <div className='mt-medium'>
                <Text color='red'>{errorMessage}</Text>
              </div>
            )}
          </Row>
        ) : workflowLinkedForm?.formVersionId ? (
          <LinkedFormElement form={form} formContent={formContent} formData={workflowLinkedForm.formData} />
        ) : (
          <Text>No form linked to this step.</Text>
        )}
      </div>
    );
  }
}

export const WorkflowLinkedAttachment = connect(null, mapDispatch)(Form.create<Props>()(_WorkflowLinkedAttachment));
