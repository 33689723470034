import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const BellSlash = forwardRef(function BellSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.114 3.327A7.547 7.547 0 0 1 19.489 8.86l.755 6.047a.75.75 0 1 1-1.488.186L18 9.047a6.047 6.047 0 0 0-9.113-4.434.75.75 0 1 1-.773-1.286ZM6 9.047c.102-.812.36-1.569.744-2.242L18.19 18.25h-2.172a.93.93 0 0 0-.033 0H8.016a.93.93 0 0 0-.033 0H6.152a1.15 1.15 0 0 1-1.14-1.292L6 9.047ZM7.513 19.75H6.152a2.65 2.65 0 0 1-2.63-2.978l.99-7.911a7.525 7.525 0 0 1 1.14-3.148L3.47 3.53a.75.75 0 0 1 1.06-1.06l18 18a.75.75 0 0 1-1.06 1.06l-1.78-1.78h-3.203l-.052.118a4.853 4.853 0 0 1-8.87 0l-.052-.118Zm1.692 0a3.354 3.354 0 0 0 5.59 0h-5.59Z"
        fill="currentColor"
      />
    </Icon>
  );
});
