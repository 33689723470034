import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Playlist = forwardRef(function Playlist(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4.25a.75.75 0 0 0 0 1.5h16a.75.75 0 0 0 0-1.5H3Zm0 4a.75.75 0 0 0 0 1.5h12a.75.75 0 0 0 0-1.5H3ZM2.25 13a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75ZM3 16.25a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5H3Zm14 1.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm1.25-1.2a2.75 2.75 0 1 0 1.5 2.45v-6.599l1.834 1.223a.75.75 0 1 0 .832-1.248l-3-2A.75.75 0 0 0 18.25 11v5.55Z"
        fill="currentColor"
      />
    </Icon>
  );
});
