import React, { useEffect } from 'react';
import _PubNub from 'pubnub';
import { PubNubProvider as _PubNubProvider, usePubNub } from 'pubnub-react';

import { PUBNUB_API_KEY } from '../../config/app-config';

import type { PropsWithChildren } from 'react';

const createPubNub = (userId: string): typeof _PubNub =>
  new _PubNub({
    listenToBrowserNetworkEvents: true,
    restore: true,
    subscribeKey: PUBNUB_API_KEY,
    uuid: userId,
  });

/************
 * PubNub
 ************/

type PubNubProps = {
  onNotification: (string) => void;
  userId: string;
};

export const PubNub = (props: PubNubProps) => {
  const { onNotification, userId } = props;
  const pubnub = usePubNub();

  const handleMessage = (event) => {
    const { message } = event;
    onNotification(message);
  };

  const handleStatus = (event) => {
    const { category } = event;
    if (category === 'PNNetworkUpCategory') pubnub.reconnect();
  };

  useEffect(
    function setup() {
      pubnub.addListener({ message: handleMessage, status: handleStatus });
      pubnub.subscribe({
        channels: [`${userId}-webpush`],
      });

      return function teardown() {
        pubnub.removeListener({ message: handleMessage, status: handleStatus });
        pubnub.unsubscribeAll();
      };
    },
    [pubnub],
  );

  return null;
};

/********************
 * PubNubProvider
 ********************/

export type PubNubProviderProps = PropsWithChildren<
  {
    userId: string;
  } & PubNubProps
>;

export const PubNubProvider = (props: PubNubProviderProps): JSX.Element => {
  const { children, onNotification, userId } = props;
  const pubnub = createPubNub(userId);

  return (
    <_PubNubProvider client={pubnub}>
      <PubNub onNotification={onNotification} userId={userId} />
      {children}
    </_PubNubProvider>
  );
};
