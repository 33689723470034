import { SubTitle, Text } from 'common-components/typography';
import React from 'react';
import { IFormElement } from 'views/form-builder/shared/form-interface';

interface IConditionalProps {
  event: string;
  elementTarget: IFormElement;
  affectedElements?: IFormElement[];
  comparatorValue?: string | null;
  optionTarget?: string | null;
  repeatable?: boolean;
  repeatableCaption?: string;
  isExplanationCondition?: boolean;
  isShowQuestion?: boolean;
}
function Conditional(props: IConditionalProps) {
  const {
    event,
    elementTarget,
    affectedElements,
    comparatorValue,
    optionTarget,
    repeatable,
    repeatableCaption,
    isExplanationCondition,
    isShowQuestion,
  } = props;

  const targetElementTitle =
    elementTarget?.properties.general?.find((config) => config.key === 'fieldTitle')?.value ||
    elementTarget?.properties.appearance?.find(
      (config) => config.key === 'headingText' || config.key === 'paragraphText',
    )?.value ||
    elementTarget?.name;

  const affectedElementTitles =
    affectedElements !== undefined && affectedElements?.length > 0
      ? affectedElements?.map((value) => value.name).join(', ')
      : 'NONE';

  return (
    <div className="flex-column width-full">
      {!isExplanationCondition && (
        <SubTitle weight="bolder"> {!isShowQuestion ? 'SHOW/HIDE LOGIC' : 'SHOW/HIDE LOGIC'}</SubTitle>
      )}
      {isExplanationCondition && repeatable && repeatableCaption ? (
        <Text size="small" color="secondary" lineHeight={100}>
          If <b>{optionTarget} again</b> selected, repeat <b>&apos;{repeatableCaption}&apos;</b>
        </Text>
      ) : repeatable && repeatableCaption && !isShowQuestion ? (
        <Text size="small" color="secondary" lineHeight={100}>
          If <b>{optionTarget}</b> selected, <b>{repeatableCaption.toLowerCase()}</b> will be repeated.
        </Text>
      ) : event && elementTarget && optionTarget ? (
        <Text size="small" color="secondary" lineHeight={100}>
          Show <b>{affectedElementTitles ?? '??'}</b> when <b>{optionTarget}</b> {event.toLowerCase()}
          {comparatorValue !== null ? <b> &quot;{comparatorValue}&quot;</b> : null}.
        </Text>
      ) : event && elementTarget && optionTarget === undefined ? (
        <Text size="small" color="secondary" lineHeight={100}>
          Show <b>{affectedElementTitles ?? '??'}</b> when <b>{targetElementTitle}</b> {event.toLowerCase()}
          {comparatorValue !== null ? <b> &quot;{comparatorValue}&quot;</b> : null}.
        </Text>
      ) : (
        <Text size="small" color="secondary" lineHeight={100}>
          No event to trigger hide question
        </Text>
      )}
    </div>
  );
}

export default Conditional;
