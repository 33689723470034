/* eslint-disable */
import React, { Component } from 'react';
import _ from 'lodash';
import CreateNewServiceModal from 'views/services/listing/components/CreateNewServiceModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { ServiceType } from 'utilities/enum-utils';
import { RouteComponentProps } from 'react-router-dom';
import { ServiceListingPage } from './list';

interface IServiceLandingViewProps extends RouteComponentProps {
  filteredServiceList: typeof state.servicesStore.filteredServices;
  setFilteredServices: typeof dispatch.servicesStore.setFilteredServices;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
}

interface IServiceLandingViewState {
  selectedServiceId: string;
  showCreateServiceModal: boolean;
}

class ServiceLandingView extends Component<IServiceLandingViewProps, IServiceLandingViewState> {
  state = {
    selectedServiceId: null,
    showCreateServiceModal: false,
  };

  private _openServiceModal = () => this.setState({ showCreateServiceModal: true });
  private _closeServiceModal = (serviceData = null) => {
    if (!_.isEmpty(serviceData)) {
      const newServiceListData = {
        ...serviceData,
        minBookingMinutes: null,
        gapMinutes: null,
        maxBookingMinutes: null,
        createdBy: null,
        likeCount: 0,
        dislikeCount: 0,
        createdOn: new Date(),
        UpdatedOn: new Date(),
      };
      const newFilteredServiceList = [newServiceListData, ...(this.props.filteredServiceList ?? [])];
      this.props.setFilteredServices(newFilteredServiceList);
      this.setState({ showCreateServiceModal: false });
      this.props.history.push(
        serviceData.serviceType === ServiceType.GROUP
          ? `/group-service/details/${serviceData.serviceId}`
          : `/service/details/${serviceData.serviceId}`,
      );
    } else {
      this.setState({ showCreateServiceModal: false });
    }
  };

  override componentDidMount() {
    this.props.setSelectedSideNavMenuKeys(['/services']);
  }

  override render() {
    return (
      <div style={{ height: 'calc(100vh - 234px)' }}>
        <div className='height-full'>
          <ServiceListingPage showServiceModal={this._openServiceModal} />
        </div>
        <CreateNewServiceModal
          isOpen={this.state.showCreateServiceModal}
          history={history}
          closeCreateServiceModal={this._closeServiceModal}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  filteredServiceList: state.servicesStore.filteredServices,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setFilteredServices: dispatch.servicesStore.setFilteredServices,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
});

export default connect(mapState, mapDispatch)(ServiceLandingView);
