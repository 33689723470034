import React from 'react';
import moment from 'moment-timezone';
import { Divider } from 'antd';

import { SubTitle, Text, Paragraph } from 'common-components/typography';

import FormElementsRendered from './FormElementsRendered';
import { IFormContent } from '../shared/form-interface';

interface IFormRenderPlainModeProps {
  formContent: IFormContent;
}
function FormRenderPlainMode({ formContent }: IFormRenderPlainModeProps) {
  const { title } = formContent;

  return (
    <div className="bg-white p-large">
      <SubTitle>Form Name</SubTitle>
      <Text weight="bold">{title.formTitle}</Text>
      {title.formDescription && (
        <div>
          <Paragraph className="whitespace-pre-line mt-large">{title.formDescription}</Paragraph>
          <Divider className="divider-large" />
        </div>
      )}
      <div className="disabled">
        <FormElementsRendered timezone={moment.tz.guess()} formContent={formContent} />
      </div>
    </div>
  );
}

export default FormRenderPlainMode;
