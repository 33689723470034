import { Input, Select } from 'antd';
import React from 'react';
import { Text, SubTitle } from 'common-components/typography';
import { IDropdownOptions, IFormElement } from 'views/form-builder/shared/form-interface';
import {
  convertRulesRequiredOfBundle,
  getRules,
  getValueFromPropertyByKey,
} from 'views/form-builder/shared/form-builder-utils';
import { FormComponentProps } from 'antd/es/form';
import FormItem from 'antd/es/form/FormItem';
import { IntakeFormElementType, FormElementType, PropKey } from 'views/form-builder/shared/form-enum';
import { Spinner } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';

interface IDropdownProps {
  options: any[];
  defaultValue: any;
  title: string;
  caption: string;
  isRequired?: boolean;
  isMultiSelect?: boolean;
  placeholder?: string;
}
function Dropdown({ options, defaultValue, title, caption, isRequired, isMultiSelect, placeholder }: IDropdownProps) {
  const fieldTextFormat = (
    <>
      {title}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bolder">{fieldTextFormat}</SubTitle>
      <Select
        mode={isMultiSelect ? 'multiple' : 'default'}
        size="large"
        className="pt-small"
        value={defaultValue}
        placeholder={placeholder}
      >
        {options.map((opt: string) => {
          return (
            <Select.Option key={opt} value={opt}>
              {opt}
            </Select.Option>
          );
        })}
      </Select>
      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
}

export default Dropdown;

interface IEditDropdownProps extends FormComponentProps {
  value?: string;
  element: IFormElement;
  isMultiSelect?: boolean;
  placeholder?: string;
  onChange?: (value) => void;
  isLoading?: boolean;
  onDropdownVisibleChange?: (open: boolean) => void;
  options: IDropdownOptions[];
}

interface GroupOption {
  groupName: string;
  options: IDropdownOptions[] | string[];
}

export const EditDropdown = ({
  value,
  element,
  isMultiSelect,
  placeholder,
  form,
  onChange,
  isLoading,
  onDropdownVisibleChange,
  options,
}: IEditDropdownProps) => {
  const { elementsBundleOptional } = useSelector((state: IRootState) => state.formBuilderStore);
  const { id, parentId } = element;
  const caption = getValueFromPropertyByKey(element.properties.appearance, PropKey.CAPTION_TEXT);
  const isRequired = getValueFromPropertyByKey(element.properties.general, PropKey.REQUIRE);
  let title = getValueFromPropertyByKey(element.properties.general, PropKey.FIELD_TITLE);
  const isGroupOption = [
    IntakeFormElementType.CONTACTS_ADDITIONAL_CONTACTS_RELATIONSHIP,
    IntakeFormElementType.CONTACTS_LEGAL_GUARDIAN_RELATIONSHIP,
  ].includes(element.properties.general?.[0]?.fieldType);
  let rules = getRules(element, false);
  const { getFieldDecorator } = form;

  if (isRequired) {
    const checkRequiredBundle = !(elementsBundleOptional || []).includes(id);
    rules = convertRulesRequiredOfBundle(checkRequiredBundle, rules);

    title = (
      <>
        {title}
        {checkRequiredBundle && <Text color="red"> *</Text>}
      </>
    );
  }

  if (isLoading) {
    return (
      <div className="flex-column width-full">
        <SubTitle weight="bolder">{title}</SubTitle>
        <div className="flex-row align-center ant-select-selection p-small text-size-large">
          <div className="text-align-center mr-small">
            <Spinner size={18} />
          </div>
          <div className="text-align-center">
            <Text>Loading options...</Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bolder">{title}</SubTitle>
      <FormItem className="m-none width-full">
        {getFieldDecorator([id, 'value'] as never, {
          initialValue: value || undefined,
          rules: rules,
        })(
          <Select
            showSearch
            mode={isMultiSelect ? 'multiple' : 'default'}
            size="large"
            optionFilterProp="children"
            className="mt-small width-full text-size-large"
            placeholder={placeholder}
            allowClear
            onChange={(value) => (typeof onChange === 'function' ? onChange(value) : null)}
            onDropdownVisibleChange={(open) =>
              typeof onDropdownVisibleChange === 'function' ? onDropdownVisibleChange(open) : null
            }
          >
            {isGroupOption
              ? options.map((optGroup: GroupOption, index: number) => {
                  return (
                    <Select.OptGroup key={index} label={optGroup.groupName}>
                      {optGroup.options.map((opt: IDropdownOptions | string) => {
                        const isOPTString = typeof opt === 'string';
                        const value = isOPTString ? opt : opt.value;
                        const name = isOPTString ? opt : opt.name;
                        const disabled = isOPTString ? false : opt?.disabled;

                        return (
                          <Select.Option key={value} value={value} disabled={disabled}>
                            {name}
                          </Select.Option>
                        );
                      })}
                    </Select.OptGroup>
                  );
                })
              : options.map((opt: IDropdownOptions | string) => {
                  const isOPTString = typeof opt === 'string';
                  const value = isOPTString ? opt : opt.value;
                  const name = isOPTString ? opt : opt.name;
                  const disabled = isOPTString ? false : opt?.disabled;

                  return (
                    <Select.Option key={value} value={value} disabled={disabled}>
                      {name}
                    </Select.Option>
                  );
                })}
          </Select>,
        )}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'type'] as never, {
          initialValue: FormElementType.DROPDOWN,
        })(<Input type="hidden" />)}
      </FormItem>

      <FormItem className="m-none">
        {getFieldDecorator([id, 'parentElementId'] as never, {
          initialValue: parentId,
        })(<Input type="hidden" />)}
      </FormItem>

      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
};
