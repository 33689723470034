import React from 'react';

import { Icon } from 'antd';
import _ from 'lodash';

import { IChannel, IServiceRosterUser, MessageSubscriberType } from 'interfaces/message-interfaces';
import { EmptyChannel } from 'views/messaging/channels/components/EmptyChannel';
import { ChannelItem } from 'views/messaging/channels/components/ChannelItem';
import { HyperlinkButton, SecondaryButton } from 'common-components/buttons';
import { FaHashtag } from 'react-icons/fa';
import { INewChannelAttributes } from 'views/messaging/MessagingLandingView';
import { CreateConversationModal } from 'views/messaging/channels/components/CreateConversationModal';

import { Paragraph } from 'common-components/typography';

interface IChannelsPanelProps {
  // event handlers
  onSelectChannel: any;
  onCreateChannel: any;

  // data
  channels: IChannel[];
  customers: any[];

  // currently selected items
  currentService?: any;
  selectedServiceRoster?: IServiceRosterUser[];

  currentChannel?: IChannel;
}

interface IChannelsPanelState {
  isCreateModalOpen?: boolean;
}

export class ChannelsPanel extends React.Component<IChannelsPanelProps, IChannelsPanelState> {
  state = {
    isCreateModalOpen: false,
  };

  openConversationModal = () => this.setState({ isCreateModalOpen: true });
  closeConversationModal = () => this.setState({ isCreateModalOpen: false });

  onTriggerCreateConversation = async ({ selectedUserId, selectedServiceId, subscriberType }) => {
    // TODO : Retrieve channels based on select serviceId...?

    // find out if there's such a channel or not.
    const matchedChannel: any = _.find(
      this.props.channels,
      (c) =>
        c.serviceId === selectedServiceId &&
        c.subscribers &&
        _.find(
          c.subscribers,
          (sub) => sub.userId === selectedUserId && sub.subscriberType !== MessageSubscriberType.PortalUser,
        ),
    );

    if (!_.isEmpty(matchedChannel)) {
      // There's an existing channel; switch to it.
      this.props.onSelectChannel({ messageChannelId: matchedChannel.messageChannelId });
    } else {
      // No such channel. Go ahead and create it...?
      // Need serviceName, serviceId, userId, userName to create
      //  TODO Create

      const users =
        subscriberType === MessageSubscriberType.SupportWorker
          ? this.props.selectedServiceRoster
          : subscriberType === MessageSubscriberType.Customer
          ? this.props.customers
          : [];

      const matchedUser: any = _.find(users, (user) => user.userId === selectedUserId);

      const newChannelAttributes: INewChannelAttributes = {
        serviceId: selectedServiceId,
        serviceName: this.props.currentService.serviceName,
        userId: selectedUserId,
        userName: matchedUser && `${matchedUser.firstName} ${matchedUser.lastName}`,
        subscriberType,
      };

      this.props.onCreateChannel({ newChannelAttributes });
    }
  };

  render() {
    const { channels, currentService, onSelectChannel, currentChannel, selectedServiceRoster, customers } = this.props;

    const currentServiceId = currentService && currentService.serviceId;
    const currentServiceName = currentService && currentService.serviceName;

    let currentChannels = null;

    if (!_.isEmpty(currentServiceId)) {
      currentChannels = _.chain(channels)
        .filter((c) => c.serviceId === currentServiceId)
        .orderBy(['lastMessagedOn'], ['desc'])
        .value();
    }

    return (
      <div
        className="bordered-left bordered-right flex-column border-secondary"
        style={{ width: '440px', overflowY: 'auto' }}
      >
        {/* Channels Header */}
        <div
          className="pv-medium ph-medium bordered-bottom border-secondary flex-row justify-between"
          style={{ height: '72px' }}
        >
          {/* Service Name Label */}
          <div className="flex-row align-center">
            <Paragraph size="x-large" weight="bold" className="select-none mv-none" ellipsis={{ rows: 2 }}>
              <FaHashtag className="text-size-regular mr-x-small" />
              {currentServiceName}
            </Paragraph>

            {/*TODO - More Options */}
            {/*<Icon type="down" className="text-size-x-small pl-small" />*/}
          </div>

          {/* Filter Button */}
          {/* TODO : Filter & Search */}
          <div className="flex-row align-center">
            {/*<Icon type="search" className="text-color-blue ml-small" />*/}

            {currentServiceId && (
              <SecondaryButton
                // fontSize="regular"
                size="small"
                onClick={this.openConversationModal}
                className="select-none"
                icon={'plus'}
              >
                New conversation
              </SecondaryButton>
            )}
          </div>
        </div>

        {/*  /!* TODO FILTERING & SEARCH *!/*/}
        {/*<OptionsBar />*/}

        {/* Empty state; no channels found. */}
        {_.isEmpty(currentChannels) && <EmptyChannel serviceName={currentServiceName} />}

        <div>
          {_.map(currentChannels, (channel: IChannel) => (
            <ChannelItem
              key={channel.messageChannelId}
              channel={channel}
              isSelected={!_.isEmpty(currentChannel) && channel.messageChannelId === currentChannel.messageChannelId}
              onClickChannel={onSelectChannel}
            />
          ))}
        </div>

        {/* Action Modal */}
        <CreateConversationModal
          isCreateModalOpen={this.state.isCreateModalOpen}
          onTriggerCreateConversation={this.onTriggerCreateConversation}
          onCloseCreateModal={this.closeConversationModal}
          selectedServiceRoster={selectedServiceRoster}
          currentServiceName={currentServiceName}
          currentServiceId={currentServiceId}
          customers={customers}
        />
      </div>
    );
  }
}

// Note : Not currently in use. To be implemented later.
function OptionsBar() {
  return (
    <div className="pv-small ph-medium flex-row justify-between bordered-bottom border-secondary bg-light-gray-lighter">
      {/*<HyperlinkButton*/}
      {/*  color="blue-dark"*/}
      {/*  fontSize="regular"*/}
      {/*  onClick={this.openConversationModal}*/}
      {/*  className="select-none"*/}
      {/*>*/}
      {/*  <Icon type={'plus'} className="mr-x-small" />*/}
      {/*  New Conversation*/}
      {/*</HyperlinkButton>*/}

      <HyperlinkButton color="blue-dark" fontSize="regular" className="select-none">
        <Icon type={'filter'} className="mr-x-small" />
        Filter
      </HyperlinkButton>

      <HyperlinkButton color="blue-dark" fontSize="regular" className="select-none">
        <Icon type={'search'} className="mr-x-small" />
        Search
      </HyperlinkButton>
    </div>
  );
}
