import React, { useState } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Avatar, Col, Empty, Skeleton } from 'antd';
import { FieldLabel, Text } from 'common-components/typography';
import { YearAndMonthFilter } from 'common-components/filter';
import moment from 'moment-timezone';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { IGroupServiceCustomerListItem } from 'interfaces/customer-interfaces';
import { PrimaryButton } from 'common-components/buttons';
import { GridHeader } from 'common-components/grids';
import { IYearAndMonthFilter } from 'interfaces/filter-interfaces';
import RemoveCustomerFromScheduleModal from 'views/group-services/service-details/components/RemoveCustomerFromScheduleModal';
import CustomerScheduleRow from './CustomerScheduleRow';
import { useQuery } from '@tanstack/react-query';

interface IViewCustomerScheduleModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCustomer: IGroupServiceCustomerListItem;
  groupServiceSessions: typeof state.servicesStore.groupServiceSessions;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  doFetchGroupServiceCustomerSessions: typeof dispatch.servicesStore.doFetchGroupServiceCustomerSessions;
}

const SessionEmptyState = () => (
  <div className="mt-x2-large align-center flex-column flex-1 bg-white">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No sessions found for the current month ({new Date().toLocaleString('default', { month: 'long' })}).
    </Text>
  </div>
);

const ViewCustomerScheduleModal: React.FC<IViewCustomerScheduleModalProps> = ({
  isOpen,
  onClose,
  selectedCustomer,
  groupServiceSessions,
  selectedGroupService,
  doFetchGroupServiceCustomerSessions,
}) => {
  const [filter, setFilter] = useState<IYearAndMonthFilter>({
    year: moment().year(),
    month: moment().month(),
  });
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
  const [removeModalType, setRemoveModalType] = useState<string>('individual');
  const [selectedSession, setSelectedSession] = useState<any>(null);

  const { isLoading } = useQuery(['fetch-group-service-customer-sessions'], {
    queryFn: () =>
      doFetchGroupServiceCustomerSessions({
        serviceId: selectedGroupService.serviceId,
        customerUserId: selectedCustomer.customerUserId,
      }),
  });

  const openRemoveModal = (session, isRemoveSelectedBookingOnly = false) => {
    setIsRemoveModalOpen(true);
    setSelectedSession(session);
    setRemoveModalType(isRemoveSelectedBookingOnly ? 'individual' : 'schedule');
  };

  const closeRemoveModal = () => {
    setIsRemoveModalOpen(false);
    setSelectedSession(null);
    setRemoveModalType('individual');
  };

  if (isLoading) {
    return (
      <ActionModal width="x2-large" isOpen={isOpen} onClose={onClose} title="View Schedule">
        <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
      </ActionModal>
    );
  }

  const customerFullName = `${selectedCustomer?.customerFirstName} ${selectedCustomer?.customerLastName}`;
  const sortedSesssions = groupServiceSessions.sort(
    (a, b) => new Date(a.startDateTime as unknown as string).valueOf() - new Date(b.startDateTime as unknown as string).valueOf()
  );
  const filteredSessionList = sortedSesssions.filter(
    (session) =>
      moment(session.startDateTime).year() === filter.year && moment(session.startDateTime).month() === filter.month,
  );

  return (
    <ActionModal width="x2-large" isOpen={isOpen} onClose={onClose} title="View Schedule">
      <RemoveCustomerFromScheduleModal
        removeModalType={removeModalType}
        isOpen={isRemoveModalOpen}
        customerUserId={selectedCustomer && selectedCustomer.customerUserId}
        selectedSession={selectedSession}
        onClose={closeRemoveModal}
      />
      <Text>You’re currently viewing the schedule for this following customer :</Text>
      <div className="mt-medium align-center flex-row">
        <Avatar size="large" className="mr-small" icon="user" src={selectedCustomer?.customerAvatarUrl} />
        <Text weight="bold">{customerFullName}</Text>
      </div>
      <YearAndMonthFilter
        filter={filter}
        startDateTime={moment(sortedSesssions[0]?.startDateTime)}
        endDateTime={moment(sortedSesssions[groupServiceSessions.length - 1]?.endDateTime)}
        updateFilter={setFilter}
        hideEmptyDates={true}
        dateList={groupServiceSessions}
      />
      <GridHeader bordered containerClassName="border-width-medium border-secondary">
        <Col span={6} className="bg-white">
          <FieldLabel text="Session date" />
        </Col>
        <Col span={4} className="bg-white">
          <FieldLabel text="Status" />
        </Col>
        <Col span={4} className="bg-white">
          <FieldLabel text="CAPACITY" />
        </Col>
        <Col span={6} className="bg-white">
          <FieldLabel text="SCHEDULE" />
        </Col>
        <Col span={4} className="bg-white"></Col>
      </GridHeader>
      {filteredSessionList.length === 0 && <SessionEmptyState />}
      {filteredSessionList.map((session, index) => (
        <CustomerScheduleRow
          key={`${session?.scheduleTimeSlotId}-${index}`}
          session={session}
          timezone={selectedGroupService?.timezone}
          openRemoveModal={openRemoveModal}
        />
      ))}
      <ActionModalFooter className="mt-medium">
        <PrimaryButton size="large" onClick={onClose}>
          Close
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceCustomerSessions: dispatch.servicesStore.doFetchGroupServiceCustomerSessions,
  setGroupServiceSessions: dispatch.servicesStore.setGroupServiceSessions,
});

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  groupServiceSessions: state.servicesStore.groupServiceSessions,
  customerToSchedule: state.servicesStore.customerToSchedule,
});

export default connect(mapState, mapDispatch)(ViewCustomerScheduleModal);
