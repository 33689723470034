import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Menu, Text, Box } from '@good/ui/core';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { useProfileMenuSections } from './use-profile-menu-sections';

type ProfileMenuProps = {
  children: React.ReactNode;
};

export const ProfileMenu = ({ children }: ProfileMenuProps) => {
  const portalUser = usePortalUser();
  const profileMenuSections = useProfileMenuSections();

  return (
    <Menu shadow='md' width={200} position='bottom-end'>
      <Menu.Target>{children}</Menu.Target>

      <Menu.Dropdown pb='sm'>
        <Box p='sm'>
          <Text fw={700}>{portalUser?.firstName && portalUser.firstName}</Text>
          <Text c='dimmed' size='sm'>
            {portalUser?.serviceProviderName}
          </Text>
        </Box>
        {profileMenuSections.map((section, index) => {
          const items = section.items;
          const isLastSection = index === profileMenuSections.length - 1;

          return (
            <Fragment key={section.key}>
              <Menu.Label>{section.title}</Menu.Label>
              {items.map((item) => {
                return (
                  <Link
                    key={item.label}
                    onClick={(event) => {
                      if (item.onClick) {
                        event.preventDefault();
                        item.onClick();
                      }
                    }}
                    to={item.link}
                  >
                    <Menu.Item leftSection={item.icon} color={item.color}>
                      {item.label}
                    </Menu.Item>
                  </Link>
                );
              })}
              {!isLastSection && <Menu.Divider />}
            </Fragment>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
};
