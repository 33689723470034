import { css } from '../theme';

import type { CSS } from '../theme';

export const reset: CSS = {
  margin: 0,
  padding: 0,
  outline: 'none',
};

export const textField: CSS = {
  width: '100%',
  border: 'none',
  fontFamily: '$primary',
  color: '$black',
  backgroundColor: '$white',
};

export const textarea: CSS = {
  // kind
  resize: 'none',
  border: 'none',

  width: '100%',

  // size
  height: '146px',
  padding: '$medium',
};

export const wrapper: CSS = {
  // kind
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  outlineWidth: '$outlineWidths-thin',
  outlineColor: '$neutral',
  outlineStyle: '$outlineStyles-solid',
  borderRadius: '$small',
  cursor: 'text',

  lineHeight: '$leading300',
};

export const container: CSS = {
  width: '100%',
};

export const charactersTotal: CSS = {
  display: 'flex',
  justifyContent: 'flex-end',
  color: '$textSecondary',

  marginTop: '$space115',
  fontSize: '$small',
};

export const wrapperVariants = css({
  backgroundColor: '$white',
  outlineColor: '$neutral',
  color: '$black',

  variants: {
    isHovered: {
      true: {
        outlineColor: '$accent',
        outlineWidth: '$outlineWidths-thick',
      },
    },
    isFocused: {
      true: {
        outlineColor: '$accent',
        outlineWidth: '$outlineWidths-thick',
      },
    },
    isPressed: {
      true: {
        outlineColor: '$accent',
        outlineWidth: '$outlineWidths-thick',
      },
    },
    isErrored: {
      true: {
        outlineColor: '$critical !important',
        outlineWidth: '$outlineWidths-thick',
      },
    },
    isDisabled: {
      true: {
        border: 'none',
        outline: 'none',
        backgroundColor: '$bodyLight2',
        cursor: 'not-allowed',
      },
    },
  },
});
