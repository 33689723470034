import React from 'react';

import { Avatar } from 'antd';
import _ from 'lodash';

import { Text } from 'common-components/typography';

import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';

// Change this to show empty rows whenever there's no data. A bit buggy, so setting it to 0 for now.
const EMPTY_ROW_COUNT = 1;

export const EmptySideColumn = ({ numberOfRows = EMPTY_ROW_COUNT, type = 'customer' }) => {
  const { TIMELINE_ROW_HEIGHT } = ROSTER_DAILY_CONFIG;

  return (
    <>
      {_.times(numberOfRows, (idx) => (
        <div
          className="bg-tertiary pv-medium pl-x-large pr-medium flex-row justify-between bordered-right bordered-bottom"
          style={{ height: TIMELINE_ROW_HEIGHT }}
          key={idx}
        >
          <div className="flex-row align-center">
            <div className="mr-12">
              <Avatar size="large" shape={type === 'customer' ? 'circle' : 'square'} icon="user" />
            </div>
            <div className="line-height-135 flex-column" style={{ width: '180px' }}>
              <Text color="tertiary" size="regular">
                No {type === 'customer' ? 'customers' : 'team members'}
              </Text>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
