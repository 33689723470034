import React, { PureComponent } from 'react';
import _ from 'lodash';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Popover2 } from '@blueprintjs/popover2';
import { GhostButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { timeZone } from 'interfaces/timezone-type';
import { FilterType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import { ActiveFilterItem, MoreFilterMenu } from 'common-components/filter';
import { IFilter } from 'interfaces/filter-interfaces';

type IFilterSectionProps = {
  filters: IFilter[];
  onChangeFilter: any;
  availableFilters: Array<FilterType>;
  displayTimezone: timeZone | null;
  services: typeof state.servicesStore.services;
  doFetchServicesLite: typeof dispatch.servicesStore.doFetchServicesLite;
  setHasBookingListingFilterChanged?: typeof dispatch.bookingsStore.setHasBookingListingFilterChanged;
  containerClassName?: string;
  usePortal?: boolean;
  verticalDisplay?: boolean;
  displayMoreFilter?: boolean;
};

type IFilterSectionState = {
  openedMenu: string;
  addNewFilterPanel: string;
};

class FilterSection extends PureComponent<IFilterSectionProps, IFilterSectionState> {
  state = {
    openedMenu: null,
    addNewFilterPanel: null,
  };

  private _addFilter = (filterName) => {
    if (CommonUtils.findFilter(filterName, this.props.filters)) {
      this._setIsOpen(filterName, true);
    } else {
      this.setState({ addNewFilterPanel: filterName });
    }
  };

  private _saveNewFilterValue = async (filterType, newFilterValue, selectionLabel) => {
    const newFilters = this.props.filters ? _.clone(this.props.filters) : [];
    const filterIndex = _.findIndex(newFilters, (filter: any) => filter.filter === filterType);
    if (filterIndex !== -1) {
      newFilters.splice(filterIndex, 1, { filter: filterType, values: newFilterValue, selectionLabel });
    } else {
      newFilters.push({ filter: filterType, values: newFilterValue, selectionLabel });
    }
    await this.props.onChangeFilter(newFilters);
  };

  private _removeFilter = (filterType) => {
    this.setState({ openedMenu: null });
    this.props.onChangeFilter(_.filter(this.props.filters, (data) => data.filter !== filterType));
  };

  private _setIsOpen = (filterType, isOpen) => {
    this.setState({ openedMenu: isOpen ? filterType : null });
  };

  render() {
    const {
      displayTimezone,
      filters,
      containerClassName,
      usePortal = true,
      verticalDisplay = false,
      displayMoreFilter = true,
    } = this.props;
    const { openedMenu } = this.state;

    return (
      <div className={containerClassName ? containerClassName : `flex-row justify-between pv-large`}>
        <div className={!verticalDisplay && 'flex-row flex-wrap'}>
          {_.map(filters, (filter, idx) => {
            if (_.find(FilterType, (f) => f === filter.filter)) {
              return (
                <React.Fragment key={idx}>
                  <ActiveFilterItem
                    filter={filter}
                    saveNewFilterValue={this._saveNewFilterValue}
                    removeFilter={this._removeFilter}
                    isOpen={openedMenu === filter.filter}
                    setIsOpen={this._setIsOpen}
                    displayTimezone={this.props.displayTimezone}
                    usePortal={usePortal}
                    canRemove={displayMoreFilter}
                  />
                  {verticalDisplay && <br />}
                </React.Fragment>
              );
            } else {
              return <React.Fragment key={idx} />;
            }
          })}
          {displayMoreFilter && (
            <Popover2
              content={
                <MoreFilterMenu
                  availableFilters={this.props.availableFilters}
                  filters={filters}
                  saveNewFilterValue={this._saveNewFilterValue}
                  removeFilter={this._removeFilter}
                  addFilter={this._addFilter}
                  addNewFilterPanel={this.state.addNewFilterPanel}
                  displayTimezone={displayTimezone}
                />
              }
              popoverClassName={'mb-medium'}
              position={'bottom-left'}
              interactionKind='click'
              usePortal={usePortal}
              onClosed={() => this.setState({ addNewFilterPanel: null })}
            >
              <GhostButton className='text-color-blue-action mr-x-small ml-small'>More filters...</GhostButton>
            </Popover2>
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  services: state.servicesStore.services,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServicesLite: dispatch.servicesStore.doFetchServicesLite,
});

export default connect(mapState, mapDispatch)(FilterSection);
