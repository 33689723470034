import { Col, DatePicker, Divider, Icon, notification, Row, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { IGroupServiceScheduleTimeSlot } from 'interfaces/service-interfaces';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import AddEditTimeSlotsModal from 'views/group-services/service-details/Create/components/AddEditTimeSlotsModal';
import { Alert } from '../../../../../design-components';
import { RecurringBookingPattern } from '../../../../../utilities/enum-utils';

type ScheduleTimeSlotsStepProps = {
  onNextStep: (stepData?: unknown) => void;
  onPreviousStep: (stepData?: unknown) => void;
  setNewGroupServiceSchedule?: typeof dispatch.servicesStore.setNewGroupServiceSchedule;
  newGroupServiceSchedule: typeof state.servicesStore.newGroupServiceSchedule;
  onChangeSchedule: () => void;
};

const TimeSlotEmptyState = ({ onClick }: { onClick: () => void }) => (
  <div className="flex-1 bg-quaternary align-center flex-column bordered p-large rounded-big">
    <Text size="x-large" color="secondary">
      No sessions added yet.
    </Text>{' '}
    <HyperlinkButton onClick={onClick} className="mt-x-small">
      <Icon type="plus" className="mr-x-small" />
      Add new sessions
    </HyperlinkButton>
  </div>
);

const ScheduleTimeSlotsStep: React.FC<ScheduleTimeSlotsStepProps> = ({
  onNextStep,
  onPreviousStep,
  setNewGroupServiceSchedule,
  newGroupServiceSchedule,
  onChangeSchedule,
}) => {
  const [scheduleStartDate, setScheduleStartDate] = useState<moment.Moment>(
    moment(newGroupServiceSchedule?.scheduleStartDate),
  );
  const [scheduleEndDate, setScheduleEndDate] = useState<moment.Moment>(
    newGroupServiceSchedule.scheduleEndDate ? moment(newGroupServiceSchedule.scheduleEndDate) : moment().add(1, 'week'),
  );
  const [timeSlots, setTimeSlots] = useState<IGroupServiceScheduleTimeSlot[]>(newGroupServiceSchedule?.timeSlots ?? []);
  const [isAddEditTimeSlotOpen, setIsAddEditTimeSlotOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedTimeslot, setSelectedTimeslot] = useState<IGroupServiceScheduleTimeSlot>(null);

  const onChangeStartingDate = (startingDate) => {
    setScheduleStartDate(moment(startingDate));

    if (scheduleEndDate < startingDate) {
      setScheduleEndDate(moment(startingDate).add(1, 'day'));
    } else if (moment(startingDate).add(2, 'year') < scheduleEndDate) {
      setScheduleEndDate(moment(startingDate).add(2, 'year'));
    }
  };

  const onChangeEndingDate = (endingDate) => setScheduleEndDate(endingDate);

  const openTimeslotModal = (newIsEdit = false, newSelectedTimeslot = null) => {
    setIsAddEditTimeSlotOpen(true);
    setIsEdit(newIsEdit);
    setSelectedTimeslot(newSelectedTimeslot);
  };

  const closeTimeslotModal = () => setIsAddEditTimeSlotOpen(false);

  const saveTimeSlot = (newTimeSlot: IGroupServiceScheduleTimeSlot, editing = false) => {
    closeTimeslotModal();

    if (editing) {
      // If we are editing, then change the time slot in question.
      return setTimeSlots(
        timeSlots.map((timeSlot) => {
          return newTimeSlot.scheduleTimeSlotId === timeSlot.scheduleTimeSlotId ? { ...newTimeSlot } : timeSlot;
        }),
      );
    }

    // If we're adding a new time slot, just append it to the list.
    setTimeSlots([...timeSlots, newTimeSlot]);
  };

  const deleteTimeSlot = (scheduleTimeSlotId) =>
    setTimeSlots(timeSlots.filter((timeSlot) => timeSlot.scheduleTimeSlotId !== scheduleTimeSlotId));

  const goToNext = async () => {
    const updatedTimeSlots = timeSlots.map((timeSlot) => {
      const timeSlotDay = moment(timeSlot.startDateTime).day();
      const scheduledDay = moment(scheduleStartDate).day();
      const scheduleDateAdjusted =
        scheduledDay > timeSlotDay ? moment(scheduleStartDate).add(1, 'week') : moment(scheduleStartDate);
      const adjustDateTime = CommonUtils.getMomentIsoWeekday(scheduleDateAdjusted, timeSlotDay);

      return {
        ...timeSlot,
        startDateTime: moment(timeSlot.startDateTime).set({
          date: adjustDateTime.date(),
          month: adjustDateTime.month(),
          year: adjustDateTime.year(),
        }),
        endDateTime: moment(timeSlot.endDateTime).set({
          date: adjustDateTime.date(),
          month: adjustDateTime.month(),
          year: adjustDateTime.year(),
        }),
      };
    });

    const updatedSchedule = {
      ...newGroupServiceSchedule,
      scheduleStartDate: moment(scheduleStartDate).startOf('day'),
      scheduleEndDate: moment(scheduleEndDate).endOf('day'),
      timeSlots: updatedTimeSlots,
    };

    const hasChangedSchedule = !_.isEqual(newGroupServiceSchedule, updatedSchedule);

    try {
      if (hasChangedSchedule) {
        onChangeSchedule();
        await setNewGroupServiceSchedule(updatedSchedule);
      }

      onNextStep();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
    }
  };

  const anyThirdWeeklyTimeSlots = timeSlots.some(
    (timeSlot) => timeSlot.recurringPattern === RecurringBookingPattern.EveryThreeWeeks,
  );
  const isNoTimeSlots = !timeSlots || timeSlots.length === 0;
  // End date cannot be greater than 2 years in the future from the start date.
  const isEndDateExceedMax = scheduleEndDate.isSameOrAfter(scheduleStartDate.clone().add(2, 'years'));

  const nextDisabled = isNoTimeSlots || isEndDateExceedMax;
  const attemptNext = () => !nextDisabled && goToNext();

  return (
    <div className="anim-slide-left">
      <AddEditTimeSlotsModal
        isOpen={isAddEditTimeSlotOpen}
        isEdit={isEdit}
        onCloseModal={closeTimeslotModal}
        onSaveTimeslot={saveTimeSlot}
        selectedTimeslot={selectedTimeslot}
      />
      <Row className="ph-x4-large">
        <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
          <div className="width-3/4">
            <Title level={4}>Create your schedule</Title>
            <Paragraph>
              Let us know how long this schedule goes for and then tell us when each session will occur.
            </Paragraph>
          </div>
        </Col>
        <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
          <div className="bg-white rounded-big p-large" style={{ minWidth: '250px' }}>
            <Title level={4}>Sessions</Title>
            <Paragraph>
              Please enter the <b>start/end date</b> for this schedule.
            </Paragraph>
            <div className="mb-large flex-row align-center">
              <div className={`mr-large ${isEndDateExceedMax && 'mb-large'}`}>
                <SubTitle>Start date</SubTitle>
                <div className="flex-row align-left">
                  <DatePicker
                    size="large"
                    format="DD/MM/YYYY"
                    allowClear={false}
                    value={scheduleStartDate}
                    disabledDate={(current) => {
                      return current < moment().startOf('day');
                    }}
                    onChange={(event) => onChangeStartingDate(event)}
                    className="mr-small"
                  />
                </div>
              </div>
              <div>
                <SubTitle>End date</SubTitle>
                <div className="flex-row align-left">
                  <DatePicker
                    size="large"
                    value={scheduleEndDate}
                    allowClear={false}
                    format="DD/MM/YYYY"
                    disabledDate={(current) => {
                      return (
                        current < moment(scheduleStartDate).startOf('day') ||
                        current > moment(scheduleStartDate).add(2, 'years')
                      );
                    }}
                    onChange={(event) => onChangeEndingDate(event)}
                    className="mr-small"
                  />
                  <Tooltip
                    title={
                      <div className="p-medium">
                        <Text className="text-size-regular text-color-white">
                          You cannot create a schedule on more than 2 years.
                        </Text>
                      </div>
                    }
                    overlayStyle={{ opacity: 1 }}
                  >
                    <Icon
                      type="info-circle"
                      className="text-color-blue text-weight-bold"
                      style={{ marginTop: '12px' }}
                    />
                  </Tooltip>
                </div>
                {isEndDateExceedMax && <Text color="red-dark">Date cannot be more than 2 years after from date.</Text>}
              </div>
            </div>
            <div className="mt-large">
              <Divider className="divider-medium" />

              <Paragraph>The following sessions for this schedule will be created :</Paragraph>

              <div className="mb-x-small">
                <SubTitle>Sessions</SubTitle>
              </div>
              {timeSlots && timeSlots.length > 0 ? (
                _.map(timeSlots, (timeSlot, index) => {
                  return (
                    <div
                      key={index}
                      className="mb-medium bordered border-standard-gray rounded-big bg-quaternary p-medium"
                    >
                      <div className="flex-row justify-between">
                        <div>
                          <Text>
                            <Text color="black" weight="bold">
                              {CommonUtils.getRecurringPatternLabel(timeSlot.recurringPattern)}
                            </Text>{' '}
                            <br />{' '}
                            <span className="text-color-black">{moment(timeSlot.startDateTime).format('dddd')}</span>{' '}
                            from{' '}
                            <span className="text-color-black">
                              {moment(timeSlot.startDateTime).format('h:mm A')} to{' '}
                              {moment(timeSlot.endDateTime).format('h:mm A')}
                            </span>
                          </Text>
                        </div>
                        <div>
                          <HyperlinkButton className="mr-medium" onClick={() => openTimeslotModal(true, timeSlot)}>
                            Edit
                          </HyperlinkButton>
                          <HyperlinkButton color="red" onClick={() => deleteTimeSlot(timeSlot.scheduleTimeSlotId)}>
                            Delete
                          </HyperlinkButton>
                        </div>
                      </div>
                      <div style={{ maxWidth: '30%' }}>
                        <Row>
                          <Col span={12}>
                            <Text size="regular" color="secondary">
                              Capacity
                            </Text>
                          </Col>
                          <Col span={12}>
                            {timeSlot.capacity > 0 ? (
                              <Text size="regular">
                                <b>{timeSlot.capacity}</b> {timeSlot.capacity === 1 ? 'customer' : 'customers'}
                              </Text>
                            ) : (
                              <Text size="regular">No capacity limit</Text>
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <Col span={12}>
                            <Text size="regular" color="secondary">
                              Activity groups
                            </Text>
                          </Col>
                          <Col span={12}>
                            {timeSlot.activityGroups.length > 0 ? (
                              <Text size="regular">
                                <b>{timeSlot.activityGroups.length}</b>{' '}
                                {timeSlot.activityGroups.length === 1 ? 'activity group' : 'activity groups'}
                              </Text>
                            ) : (
                              <Text size="regular">No activity group</Text>
                            )}
                          </Col>
                        </Row>
                      </div>
                      {timeSlot.description && <div className="mt-medium">{timeSlot.description}</div>}
                    </div>
                  );
                })
              ) : (
                <TimeSlotEmptyState onClick={() => openTimeslotModal(false)} />
              )}
            </div>
            {timeSlots && timeSlots.length > 0 && (
              <HyperlinkButton className="mt-small" onClick={() => openTimeslotModal(false)}>
                <Icon type="plus" className="mr-small" />
                Add more sessions
              </HyperlinkButton>
            )}
            {isNoTimeSlots && (
              <div className="mt-small">
                <Text color="red-dark">Please add at least one time slot.</Text>
              </div>
            )}
          </div>
          {anyThirdWeeklyTimeSlots && (
            <Alert tone="warning" title="Unable to extend schedule" kind="inline" closeable={false}>
              <Text>
                Schedules that contain sessions that repeat every three weeks <b>cannot be extended</b>.
              </Text>
            </Alert>
          )}
          <div className="pv-medium width-full bg-tertiary" style={{ position: 'sticky', bottom: 0 }}>
            <Row gutter={0} type="flex" align="middle" justify="space-between" className="bg-transparent">
              <div className="text-align-right pv-medium">
                <SecondaryButton size="large" onClick={onPreviousStep}>
                  Back
                </SecondaryButton>
              </div>
              <div className="text-align-right pv-medium">
                <PrimaryButton size="large" disabled={nextDisabled} onClick={attemptNext}>
                  Next
                </PrimaryButton>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewGroupServiceSchedule: dispatch.servicesStore.setNewGroupServiceSchedule,
});

const mapState = (state: IRootState) => ({
  newGroupServiceSchedule: state.servicesStore.newGroupServiceSchedule,
});

export default connect(mapState, mapDispatch)(ScheduleTimeSlotsStep);
