import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';
import FundGoalDetailRow from 'views/customers/details/tabs-panel/funding/components/FundGoalDetailRow';
import { Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';

interface IFundGoalDetailPanelProps {
  onNextStep: (stepData: any) => void;
  setFundingData: typeof dispatch.customersStore.setFundingData;
  fundingData: typeof state.customersStore.fundingData;
  closeCreatePackageModal: () => void;
  onPreviousStep: (stepData: any) => void;
}

interface IFundGoalDetailPanelState {
  goalFundData: any;
  isSkipActionModelOpen: boolean;
}

class FundGoalDetailPanel extends Component<IFundGoalDetailPanelProps, IFundGoalDetailPanelState> {
  state = {
    goalFundData: [],
    isSkipActionModelOpen: false,
  };

  private goalInputRefs: any[] = [];

  private _onOpenSkipActionModel = () => {
    if (this.goalInputRefs.length > 0) {
      this.setState({ isSkipActionModelOpen: true });
    } else {
      this._onSkipStep();
    }
  };

  private _onCloseSkipActionModel = () => {
    this.setState({ isSkipActionModelOpen: false });
  };

  private _onNextStep = () => {
    let isFormValid = true;
    const goalArray = [];
    if (this.goalInputRefs.length > 0) {
      _.forEach(this.goalInputRefs, (goalRef) => {
        goalRef.props.form.validateFieldsAndScroll((err, value) => {
          if (err) {
            isFormValid = false;
          } else {
            goalArray.push(value);
          }
        });
      });
    }
    if (isFormValid) {
      let payload = [];
      payload.push({ goalFundData: goalArray });

      this.props.onNextStep(payload);
    }
  };

  private _onPreviousStep = () => {
    const goalArray = [];
    if (this.goalInputRefs.length > 0) {
      _.forEach(this.goalInputRefs, (goalRef) => {
        const value = goalRef.props.form.getFieldsValue();
        goalArray.push(value);
      });
    }
    let payload = [];
    payload.push({ goalFundData: goalArray });

    this.props.onPreviousStep(payload);
  };

  private _onSkipStep = () => {
    let payload = [{ goalFundData: [] }];

    this._onCloseSkipActionModel();
    this.props.onNextStep(payload);
  };

  private _addNewGoal = () => {
    const previousState = this.state.goalFundData;
    const afterState = previousState.concat({ type: null, goalTitle: null, goalDescription: null });
    this.setState({ goalFundData: afterState });
  };

  private _setRef = (ref) => {
    if (ref) {
      this.goalInputRefs.push(ref);
    }
  };

  private _onRemoveRow = (key: number) => {
    const previousState = this.state.goalFundData;
    const afterState = _.filter(previousState, (item, index) => index !== key);
    _.remove(this.goalInputRefs, (item, index) => index === key);
    this.setState({ goalFundData: afterState });
  };

  componentDidMount() {
    const { fundingData } = this.props;
    if (!_.isEmpty(fundingData.goalFundData)) {
      this.setState({ goalFundData: fundingData.goalFundData });
    }
  }

  render() {
    return (
      <React.Fragment>
        <ActionModal
          isOpen={this.state.isSkipActionModelOpen}
          onClose={this._onCloseSkipActionModel}
          title={'Skip adding goals.'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>Are you sure you want to skip this step</Text>
          <br />
          <Text className={'mb-medium'}>Any data you have inputted will be lost.</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._onCloseSkipActionModel}>
              Back
            </PrimaryButton>
            <GhostButton size="large" onClick={this._onSkipStep}>
              Skip
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <div className={'customScrollDiv2'}>
          {_.chain(this.state.goalFundData)
            .map((fundData, i) => (
              <FundGoalDetailRow
                goal={fundData}
                key={i}
                rowIndex={i}
                wrappedComponentRef={this._setRef}
                onRemoveRow={this._onRemoveRow}
              />
            ))
            .value()}
        </div>
        <div className="mt-medium pv-small" style={{ marginBottom: 'auto' }}>
          <GhostButton icon={'plus-circle'} onClick={this._addNewGoal}>
            Add Goal
          </GhostButton>
        </div>
        <div className="pv-small" style={{ marginBottom: 'auto' }}>
          <GhostButton onClick={this._onOpenSkipActionModel}>Skip</GhostButton>
        </div>
        <div className={'mt-x2-large flex-row justify-end align-center'}>
          <GhostButton className={'mr-small'} onClick={this._onPreviousStep}>
            Back
          </GhostButton>
          <PrimaryButton size="large" icon={'right'} onClick={this._onNextStep}>
            Next
          </PrimaryButton>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = (state: IRootState) => ({
  fundingData: state.customersStore.fundingData,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setFundingData: dispatch.customersStore.setFundingData,
});

export default connect(mapState, mapDispatch)(FundGoalDetailPanel);
