import React from 'react';
import { Text } from '@good/ui/core';
import { useTranslation } from 'react-i18next';

export const FilesTabErrorState = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.filesTabs.error' });

  return (
    <div className='flex flex-col gap-2 align-center pt-8'>
      <Text>{t('title')}</Text>
      <Text>{t('message')}</Text>
    </div>
  );
};
