import React, { Component } from 'react';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { Row, Radio, notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import AvailabilityConflictTimes from './AvailablilityConflictTimes';
interface ISwitchCycleModalProps {
  isOpen: boolean;
  onClose: () => void;
  type?: 'weekly' | 'fortnightly';
  onSubmit?: (value?: any) => void;
  doSwitchAvailabilityCycle: typeof dispatch.teamStore.doSwitchAvailabilityCycle;
  doCheckAvailabilityConflict: typeof dispatch.teamStore.doCheckAvailabilityConflict;
  availabilities: any[];
  timezone: any;
}

interface ISwitchCycleModalState {}

const radioStyle = {
  display: 'flex',
  height: '30px',
  lineHeight: '30px',
  alignItems: 'center',
};

class SwitchCycleModal extends Component<ISwitchCycleModalProps, ISwitchCycleModalState> {
  state = {
    selectedWeek: 1,
    step: 1,
    conflictShifts: [],
    selectedShiftsToKeep: [],
    isLoading: false,
    modalWidth: 'medium',
  };

  private _onClose = () => {
    this.setState({ step: 1, conflictShifts: [], selectedShiftsToKeep: [], isLoading: false, modalWidth: 'medium' });
    this.props.onClose();
  };

  private _onChangeWeek = (e) => {
    this.setState({ selectedWeek: e.target.value });
  };

  private _reverseMapData = () => {
    const { availabilities } = this.props;

    return _.map(availabilities, (item) => ({
      day: item.day,
      number: item.number,
      timeRange: !_.isEmpty(item.timeRange)
        ? _.map(item.timeRange, (time) => ({
            startTime: time.startDateTime,
            endTime: time.endDateTime,
          }))
        : [],
      isAllDay: item.isAvailableAllDay,
      weeklyCycle: item.week,
    }));
  };

  private _switchCycle = async () => {
    const { type = 'fortnightly', doCheckAvailabilityConflict } = this.props;
    try {
      this.setState({ isLoading: true });
      if (type === 'weekly') {
        const requestData = this._reverseMapData();
        const conflict = await doCheckAvailabilityConflict({ timeAvailability: requestData });
        if (!_.isEmpty(conflict)) {
          this.setState({
            conflictShifts: conflict,
            step: 2,
            isLoading: false,
            modalWidth: 'x-large',
          });
        }
      } else {
        this._changeSwitchCycle();
      }
    } catch (error) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isLoading: false });
    }
  };

  private _changeSwitchCycle = async () => {
    const { doSwitchAvailabilityCycle, type = 'fortnightly' } = this.props;
    try {
      this.setState({ isLoading: true });
      const result = await doSwitchAvailabilityCycle({
        type,
        selectedWeek: this.state.selectedWeek,
        selectedShiftsToBeKept: this.state.selectedShiftsToKeep,
      });
      this.setState({
        isLoading: false,
      });
      this._onClose();
    } catch (error) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isLoading: false });
    }
  };

  private _onChangeShiftsToKeep = (shifts) => {
    this.setState({ selectedShiftsToKeep: shifts });
  };

  private _renderContent = () => {
    const { step } = this.state;
    const { type, onClose } = this.props;

    switch (step) {
      case 1:
        return (
          <>
            {type === 'fortnightly' ? (
              <>
                <Paragraph>You’re about to switch to a fortnightly availability cycle.</Paragraph>
                <Paragraph> Are you sure?</Paragraph>
              </>
            ) : (
              <Paragraph>Choose which week you’d like this team member’s availability to be based on.</Paragraph>
            )}
            {type === 'weekly' && (
              <Radio.Group onChange={this._onChangeWeek} value={this.state.selectedWeek}>
                <Radio style={radioStyle} value={1}>
                  Week 1
                </Radio>
                <Radio style={radioStyle} value={2}>
                  Week 2
                </Radio>
              </Radio.Group>
            )}
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._switchCycle}>
                  Confirm
                </PrimaryButton>
              </Row>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <Paragraph>
              This team member is currently assigned to shift(s) that fall outside their updated availability.{' '}
              <Text>Please select the shifts (if any) you wish to keep</Text> for this team member or continue without
              selecting any.
            </Paragraph>
            <div className="bg-quaternary pv-x-large ph-12 mt-x-large">
              <AvailabilityConflictTimes
                conflicts={this.state.conflictShifts}
                timezone={this.props.timezone.value}
                onSelectShifts={this._onChangeShiftsToKeep}
              />
            </div>
            <div className="mt-x3-large flex justify-end">
              <SecondaryButton size="large" onClick={this._onClose} disabled={this.state.isLoading}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                loading={this.state.isLoading}
                size="large"
                className="ml-small"
                onClick={this._changeSwitchCycle}
              >
                Done
              </PrimaryButton>
            </div>
          </>
        );
      default:
        return [];
    }
  };

  render() {
    const { isOpen, onClose, type = 'fortnightly' } = this.props;

    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.step === 1 ? `${`Switch to ${type} cycle`}` : 'Review upcoming shifts'}
        onClose={onClose}
        width={this.state.modalWidth}
      >
        {/* {type === 'fortnightly' ? (
          <>
            <Paragraph>You’re about to switch to a fortnightly availability cycle.</Paragraph>
            <Paragraph> Are you sure?</Paragraph>
          </>
        ) : (
          <Paragraph>Choose which week you’d like this team member’s availability to be based on.</Paragraph>
        )}
        {type === 'weekly' && (
          <Radio.Group onChange={this._onChangeWeek} value={this.state.selectedWeek}>
            <Radio style={radioStyle} value={1}>
              Week 1
            </Radio>
            <Radio style={radioStyle} value={2}>
              Week 2
            </Radio>
          </Radio.Group>
        )}
        <div className={'mt-large'}>
          <Row type={'flex'} justify={'end'}>
            <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._switchCycle}>
              Confirm
            </PrimaryButton>
          </Row>
        </div> */}
        {this._renderContent()}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doSwitchAvailabilityCycle: dispatch.teamStore.doSwitchAvailabilityCycle,
  doCheckAvailabilityConflict: dispatch.teamStore.doCheckAvailabilityConflict,
});

export default connect(null, mapDispatch)(SwitchCycleModal);
