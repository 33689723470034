import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Schema } from '../schema';
import fields from '../fields';

function useResetHiddenFields(form: UseFormReturn<Schema>) {
  const { watch, resetField } = form;

  const shouldChargeCancellationFee = watch(fields.shouldChargeCancellationFee.name);
  const cancellationFeeReason = watch(fields.cancellationFeeReason.name);
  const shouldPayTeamMember = watch(fields.shouldPayTeamMember.name);
  const teamMemberPaymentDuration = watch(fields.teamMemberPaymentDuration.name);

  useEffect(() => {
    if (!shouldChargeCancellationFee && cancellationFeeReason !== fields.cancellationFeeReason.defaultValue) {
      resetField(fields.cancellationFeeReason.name, {
        defaultValue: fields.cancellationFeeReason.defaultValue,
      });
    }

    if (!shouldPayTeamMember && teamMemberPaymentDuration !== fields.teamMemberPaymentDuration.defaultValue) {
      resetField(fields.teamMemberPaymentDuration.name, {
        defaultValue: fields.teamMemberPaymentDuration.defaultValue,
      });
    }
  }, [shouldChargeCancellationFee, cancellationFeeReason, shouldPayTeamMember, teamMemberPaymentDuration]);
}

export default useResetHiddenFields;
