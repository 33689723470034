import { HTMLAttributes } from 'react';

export type HTMLProps<T> = Omit<HTMLAttributes<T>, 'className' | 'style'>;
export type SlotProps = { asChild?: boolean };

export enum EValidationStatus {
  Uncheck = 'uncheck',
  Valid = 'valid',
  Invalid = 'invalid',
}

export type IValidations = {
  status: EValidationStatus;
  message: string;
}[];
