import React, { Component } from 'react';
import { Paragraph } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IGroupServiceTimesheetShiftSlot } from 'interfaces/service-interfaces';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState, state, dispatch } from 'src/stores/rematch/root-store';

interface IRevokeTimesheetModalProps {
  isOpen: boolean;
  onClose: (isRefresh: boolean) => void;
  selectedShiftSlots: IGroupServiceTimesheetShiftSlot[];
  selectedSessionId: string;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  doRevokeShiftSlotsApproval: typeof dispatch.groupServiceStore.doRevokeShiftSlotsApproval;
}

interface IRevokeTimesheetModalState {
  title: string;
  step: number;
  canCloseOutside: boolean;
  isLoading: boolean;
  isRefreshAfterClose: boolean;
}

class RevokeTimesheetModal extends Component<IRevokeTimesheetModalProps, IRevokeTimesheetModalState> {
  state = {
    title: 'Revoke timesheets',
    step: 1,
    canCloseOutside: true,
    isLoading: false,
    isRefreshAfterClose: false,
  };

  private _onSubmitRevoke = async () => {
    const { selectedGroupService, doRevokeShiftSlotsApproval, selectedSessionId, selectedShiftSlots } = this.props;
    this.setState({ isLoading: true, canCloseOutside: false });

    try {
      const payload = {
        serviceId: selectedGroupService.serviceId,
        sessionId: selectedSessionId,
        shiftSlots: selectedShiftSlots,
      };

      await doRevokeShiftSlotsApproval(payload);

      this.setState({
        isLoading: false,
        title: 'Timesheet revoked',
        step: 2,
        canCloseOutside: true,
        isRefreshAfterClose: true,
      });
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
      this.setState({ isLoading: false, canCloseOutside: true });
    }
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    onClose(this.state.isRefreshAfterClose);
    this.setState({
      title: 'Revoke timesheets',
      step: 1,
      canCloseOutside: true,
      isLoading: false,
      isRefreshAfterClose: false,
    });
  };

  private _renderContent = () => {
    const { selectedShiftSlots } = this.props;
    const { step } = this.state;

    if (step === 1) {
      return (
        <>
          <Paragraph>
            You're about to revoke the approval <b>{selectedShiftSlots.length}</b> timesheets.
          </Paragraph>
          <Paragraph>
            These approved timesheet may have been already exported to your HR/payroll system. Are you sure you want to
            proceed?
          </Paragraph>

          <ActionModalFooter>
            <SecondaryButton onClick={this._onCloseModal} disabled={this.state.isLoading} size="large">
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={this._onSubmitRevoke}
              loading={this.state.isLoading}
              size="large"
              className="ml-medium"
            >
              Revoke approval
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }

    if (step === 2) {
      return (
        <>
          <Paragraph>
            You're have successfully revoked the approval for <b>{selectedShiftSlots.length}</b> timesheets.
          </Paragraph>
          <ActionModalFooter>
            <PrimaryButton onClick={this._onCloseModal} size="large">
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }
  };

  render() {
    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={this.state.title}
        canCloseOutside={this.state.canCloseOutside}
        onClose={this._onCloseModal}
      >
        {this._renderContent()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doRevokeShiftSlotsApproval: dispatch.groupServiceStore.doRevokeShiftSlotsApproval,
});

export default connect(mapState, mapDispatch)(RevokeTimesheetModal);
