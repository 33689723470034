import { Avatar, Divider, List, Select } from 'antd';
import InfoPanel from 'common-components/alerts/InfoPanel';
import { HyperlinkButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { IWorkflowTemplateStep, IWorkflowTemplateStepApprover } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { WorkflowTemplateStepApprovalType, WorkflowTemplateStepType } from 'utilities/enum-utils';
import { getRoleDetail } from 'views/workflows/utils/workflow-utils';

interface Props {
  stepDetail: IWorkflowTemplateStep;
  moduleType: 'create' | 'view-edit';
  onUpdateStep(values: IWorkflowTemplateStep): void;
  onSetEditStep?: (editType: string) => void;
}

class StepItemDetail extends Component<Props> {
  private _onChangeApprovalType = (value) => {
    const { stepDetail, onUpdateStep } = this.props;

    onUpdateStep({ ...stepDetail, approvalType: value, stepApprovers: [], invalid: false });
  };

  private _onRemoveApprove = (approver: IWorkflowTemplateStepApprover) => {
    const { stepDetail, onUpdateStep } = this.props;

    const stepApprovers = [...stepDetail.stepApprovers];

    _.remove(stepApprovers, (one) => {
      if (stepDetail.approvalType === WorkflowTemplateStepApprovalType.ROLE) {
        return one.approverPermissionRole === approver.approverPermissionRole;
      }

      return one.approverUserId === approver.approverUserId;
    });

    onUpdateStep({ ...stepDetail, stepApprovers });
  };

  private _onRemoveAttachment = () => {
    const { stepDetail, onUpdateStep } = this.props;

    onUpdateStep({ ...stepDetail, attachment: null });
  };

  private _onRemoveForm = () => {
    const { stepDetail, onUpdateStep } = this.props;

    onUpdateStep({ ...stepDetail, formVersionId: null, formName: null, formId: null });
  };

  private _renderApproverErrorMessage = (stepDetail) => {
    const { moduleType } = this.props;

    if (moduleType == 'create') {
      return 'You must select an approver in order to proceed';
    }

    return stepDetail.approvalType === WorkflowTemplateStepApprovalType.ROLE
      ? 'Please select a role'
      : 'Please select an approver';
  };

  render() {
    const { stepDetail, onSetEditStep } = this.props;

    const approversRole =
      stepDetail && stepDetail.type === WorkflowTemplateStepType.STEP
        ? stepDetail.stepApprovers.filter((one) => one.approverPermissionRole)
        : [];
    const approversUser =
      stepDetail && stepDetail.type === WorkflowTemplateStepType.STEP
        ? stepDetail.stepApprovers.filter((one) => one.approverUserId)
        : [];

    return (
      <div className="flex-column bg-white rounded-big p-medium">
        {stepDetail && stepDetail.type === WorkflowTemplateStepType.STEP && (
          <>
            <SubTitle color={'tertiary'} size={'regular'}>
              APPROVERS
            </SubTitle>
            <Select
              value={stepDetail.approvalType}
              dropdownMatchSelectWidth={true}
              size="large"
              onChange={this._onChangeApprovalType}
              style={{ fontSize: '16px', marginTop: '16px' }}
            >
              <Select.Option value={WorkflowTemplateStepApprovalType.ANYONE}>Anyone can approve</Select.Option>
              <Select.Option value={WorkflowTemplateStepApprovalType.ROLE}>Approvers by role</Select.Option>
              <Select.Option value={WorkflowTemplateStepApprovalType.TEAM_MEMBER}>Specific team members</Select.Option>
            </Select>

            {stepDetail.approvalType === WorkflowTemplateStepApprovalType.ROLE && !!approversRole.length && (
              <div className="overflow-y-scroll bordered-top bordered-bottom mt-medium" style={{ maxHeight: '200px' }}>
                <List
                  itemLayout="horizontal"
                  dataSource={approversRole}
                  renderItem={(item) => {
                    const roleDetail = getRoleDetail(item.approverPermissionRole);
                    return (
                      <List.Item
                        actions={[
                          <HyperlinkButton key="remove" onClick={() => this._onRemoveApprove(item)}>
                            Remove...
                          </HyperlinkButton>,
                        ]}
                      >
                        <Text size="large" weight="bolder">
                          {roleDetail.name}

                          {roleDetail.description && (
                            <Text size="large" weight="regular">
                              {roleDetail.description}
                            </Text>
                          )}
                        </Text>
                      </List.Item>
                    );
                  }}
                />
              </div>
            )}

            {stepDetail.approvalType === WorkflowTemplateStepApprovalType.TEAM_MEMBER && !!approversUser.length && (
              <div className="overflow-y-scroll bordered-top bordered-bottom mt-medium" style={{ maxHeight: '200px' }}>
                <List
                  itemLayout="horizontal"
                  dataSource={approversUser}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <HyperlinkButton key="remove" onClick={() => this._onRemoveApprove(item)}>
                          Remove...
                        </HyperlinkButton>,
                      ]}
                    >
                      <div className="flex align-center pl-medium">
                        <Avatar icon="user" shape="circle" src={item.avatar} />
                        <Text size="large" className="pl-small">
                          {item.displayName}
                        </Text>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            )}

            {stepDetail.approvalType === WorkflowTemplateStepApprovalType.ROLE && (
              <HyperlinkButton
                className="mt-medium"
                onClick={() => onSetEditStep(WorkflowTemplateStepApprovalType.ROLE)}
              >
                + Add approver by role
              </HyperlinkButton>
            )}

            {stepDetail.approvalType === WorkflowTemplateStepApprovalType.TEAM_MEMBER && (
              <HyperlinkButton
                className="mt-medium"
                onClick={() => onSetEditStep(WorkflowTemplateStepApprovalType.TEAM_MEMBER)}
              >
                + Add team members for approval
              </HyperlinkButton>
            )}

            {stepDetail.invalid && (
              <Text className="mt-medium" color="red">
                {this._renderApproverErrorMessage(stepDetail)}
              </Text>
            )}

            <Divider className={'divider-medium'} />
          </>
        )}

        <SubTitle color={'tertiary'} size={'regular'}>
          FORM (OPTIONAL)
        </SubTitle>

        {stepDetail && !stepDetail.attachment && !stepDetail.formVersionId && (
          <>
            <Text color="secondary" className="mv-x-small">
              None selected
            </Text>

            <div className="mb-x-small">
              <HyperlinkButton fontSize="regular" onClick={() => onSetEditStep('form')}>
                Attach form...
              </HyperlinkButton>

              {stepDetail && stepDetail.type === WorkflowTemplateStepType.STEP && (
                <HyperlinkButton fontSize="regular" className="pl-small" onClick={() => onSetEditStep('attachment')}>
                  Add an external form/attachment....
                </HyperlinkButton>
              )}
            </div>
            {stepDetail.type === WorkflowTemplateStepType.TRIGGER && (
              <InfoPanel
                text={
                  <div>
                    If you choose to attach a form to the trigger step the form will be embedded into the incident
                    report screen on both the business app and workspace.
                  </div>
                }
              />
            )}
          </>
        )}

        {stepDetail && stepDetail.attachment && (
          <>
            <Text className="mv-x-small">
              <Text weight="bolder">External form/attachment:</Text>
              &nbsp;{stepDetail.attachment.name}
            </Text>

            <div className="mb-x-small">
              <HyperlinkButton fontSize="regular" onClick={() => onSetEditStep('attachment')}>
                Edit...
              </HyperlinkButton>

              {stepDetail && stepDetail.type === WorkflowTemplateStepType.STEP && (
                <HyperlinkButton color="red" fontSize="regular" className="pl-small" onClick={this._onRemoveAttachment}>
                  Remove...
                </HyperlinkButton>
              )}
            </div>
          </>
        )}

        {stepDetail && stepDetail.formVersionId && (
          <div className="flex justify-between pb-small">
            <HyperlinkButton onClick={() => onSetEditStep('form-preview')}>{stepDetail.formName}</HyperlinkButton>

            <div>
              <HyperlinkButton fontSize="regular" onClick={() => onSetEditStep('form')}>
                Edit
              </HyperlinkButton>

              {stepDetail && (
                <HyperlinkButton color="red" fontSize="regular" className="ml-medium" onClick={this._onRemoveForm}>
                  Remove
                </HyperlinkButton>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default StepItemDetail;
