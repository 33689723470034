import React, { Component } from 'react';
import { Col, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { Paragraph, Title } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import NewScheduleDetailPanel from 'views/group-services/service-details/Create/components/NewScheduleDetailPanel';
import { ScheduleType } from 'utilities/enum-utils';
import _ from 'lodash';
import moment from 'moment-timezone';

interface ISummaryStepPanelProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewGroupServiceSchedule: typeof dispatch.servicesStore.setNewGroupServiceSchedule;
  newGroupServiceSchedule: typeof state.servicesStore.newGroupServiceSchedule;
  doCreateNewSession: typeof dispatch.servicesStore.doCreateNewSession;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
}

interface ISummaryStepPanelState {
  isLoading: boolean;
}

class SummaryStepPanel extends Component<ISummaryStepPanelProps, ISummaryStepPanelState> {
  state = {
    isLoading: false,
  };

  private _createSession = async () => {
    const { newGroupServiceSchedule, doCreateNewSession, selectedGroupService } = this.props;

    try {
      this.setState({ isLoading: true });
      const sessionToSend =
        newGroupServiceSchedule.scheduleType === ScheduleType.ONE_OFF
          ? newGroupServiceSchedule.sessions
          : _.filter(newGroupServiceSchedule.sessions, (session) => session.isSelected);
      const result = await doCreateNewSession({
        ...newGroupServiceSchedule,
        address: newGroupServiceSchedule.address
          ? {
              ...newGroupServiceSchedule.address,
              geoLat: Number(newGroupServiceSchedule.address.geoLat),
              geoLng: Number(newGroupServiceSchedule.address.geoLng),
            }
          : null,
        serviceId: selectedGroupService.serviceId,
        serviceDepartmentId: selectedGroupService.serviceDepartmentId,
        sessions: _.map(sessionToSend, (session) => {
          return {
            ...session,
            activityGroups: _.map(session.activityGroups, (activityGroup) => ({
              ...activityGroup,
              startDateTime: moment(activityGroup.startDateTime).tz(selectedGroupService.timezone, true).format(),
              endDateTime: moment(activityGroup.endDateTime).tz(selectedGroupService.timezone, true).format(),
            })),
            startDateTime: moment(session.startDateTime).tz(selectedGroupService.timezone, true).format(),
            endDateTime: moment(session.endDateTime).tz(selectedGroupService.timezone, true).format(),
          };
        }),
        scheduleStartDate: moment(newGroupServiceSchedule.scheduleStartDate)
          .tz(selectedGroupService.timezone, true)
          .format(),
        scheduleEndDate: moment(newGroupServiceSchedule.scheduleEndDate)
          .tz(selectedGroupService.timezone, true)
          .format(),
        timeSlots: _.map(newGroupServiceSchedule.timeSlots, (timeSlot) => {
          return {
            ...timeSlot,
            activityGroups: _.map(timeSlot.activityGroups, (activityGroup) => ({
              ...activityGroup,
              startDateTime: moment(activityGroup.startDateTime).tz(selectedGroupService.timezone, true).format(),
              endDateTime: moment(activityGroup.endDateTime).tz(selectedGroupService.timezone, true).format(),
            })),
            startDateTime: moment(timeSlot.startDateTime).tz(selectedGroupService.timezone, true).format(),
            endDateTime: moment(timeSlot.endDateTime).tz(selectedGroupService.timezone, true).format(),
          };
        }),
      });
      if (result && result.serviceDateTimeId) {
        await this.props.setNewGroupServiceSchedule({
          ...newGroupServiceSchedule,
          serviceDateTimeId: result.serviceDateTimeId,
        });
      }
      this.props.onNextStep();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { newGroupServiceSchedule } = this.props;
    const { isLoading } = this.state;

    const isSchedule = newGroupServiceSchedule.scheduleType === ScheduleType.SCHEDULE;
    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>{isSchedule ? 'Schedule' : 'Session'} summary review</Title>
              <Paragraph>
                Almost there! Here's the details of the {isSchedule ? 'schedule' : 'session'} you are creating.
              </Paragraph>
              <Paragraph>Please review the details and go back to make any necessary adjustments.</Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
              <Title level={4}>Create {isSchedule ? 'schedule' : 'session'} summary</Title>
              <Paragraph>
                A new {isSchedule ? 'schedule' : 'session'} will be created {isSchedule ? 'based on' : 'with'} the
                following details:
              </Paragraph>
              <NewScheduleDetailPanel />
            </div>
            <div
              className="pv-medium width-full"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" disabled={isLoading} onClick={this.props.onPreviousStep}>
                    Back
                  </SecondaryButton>
                </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton size="large" loading={isLoading} onClick={this._createSession}>
                    Create{' '}
                    {newGroupServiceSchedule && newGroupServiceSchedule.scheduleType === ScheduleType.SCHEDULE
                      ? 'schedule'
                      : 'session'}
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  newGroupServiceSchedule: state.servicesStore.newGroupServiceSchedule,
  selectedGroupService: state.groupServiceStore.selectedGroupService,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateNewSession: dispatch.servicesStore.doCreateNewSession,
  setNewGroupServiceSchedule: dispatch.servicesStore.setNewGroupServiceSchedule,
});

export default connect(mapState, mapDispatch)(SummaryStepPanel);
