import { Popover, Tooltip } from '@blueprintjs/core';
import { Avatar, Icon, notification } from 'antd';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { HyperlinkButton, IconButton } from 'common-components/buttons';
import { TagUserAvatar } from 'common-components/notes/TagUserAvatar';
import TextTag from 'common-components/tags/TextTag';
import { Text } from 'common-components/typography';
import { IGroupServiceNote } from 'interfaces/service-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import moment from 'moment-timezone';
import { default as React, useCallback } from 'react';
import { connect } from 'react-redux';
import { IRootState, state } from 'src/stores/rematch/root-store';
import { NoteVisibleType } from 'utilities/enum-utils';
import globalHistory from '../../../../globalHistory';
import { downloadFileFromUrl } from 'utilities/file-utils';

function BookingNoteItem({
  note,
  onEditNote,
  onDeleteNote,
  timezone,
  doCheckViewWorkflowDetails,
  showFormModal,
  portalUser,
}: {
  note?: IGroupServiceNote;
  onEditNote: any;
  onDeleteNote: any;
  timezone?: timeZone;
  doCheckViewWorkflowDetails?: any;
  showFormModal?: any;
  portalUser: typeof state.authStore.portalUser;
}) {
  const hasEditor = !_.isEmpty(note.updatedBy);
  const hastaggedCustomers = !_.isEmpty(note.taggedTo);
  const authorName = `${note.authorFirstName || ''} ${note.authorLastName || ''}`;
  const editorName = `${note.updatedByFirstName || ''} ${note.updatedByLastName || ''}`;

  const _goToWorkflowDetailPage = useCallback(
    (workflowId) => async () => {
      if (workflowId) {
        const response: any = await doCheckViewWorkflowDetails({ workflowId });

        if (response && response.canViewDetail) {
          workflowId && globalHistory.push(`/workflows/details/${workflowId}`);
        } else {
          notification.error({ message: "You don't have permission to view this workflow" });
        }
      }
    },
    [],
  );

  return (
    <div className='mb-medium p-medium bordered border-standard-gray rounded-big flex-row bg-white'>
      {/* User avatar section */}
      <section className='flex-row mr-x-large' style={{ width: '200px' }}>
        <div className='mr-medium'>
          <Avatar src={note.authorAvatarUrl} icon='user' size='large' shape='square' className='rounded' />
        </div>
        <div className='flex-column'>
          <Text weight='bold'>{authorName}</Text>
          <Text size='regular' color='secondary' className='whitespace-nowrap'>
            {/* {moment(note.createdOn).format('D MMM YYYY h:mm:ss A')} */}
            {moment.tz(note.createdOn, timezone).format('D MMM YYYY h:mm:ss A')}
          </Text>
        </div>
      </section>

      {/* Note content section */}
      <section className='mh-large flex-1'>
        {/* Note tags*/}
        <div className='flex-row mb-medium flex-wrap'>
          {/* Incident tag */}
          {/* TODO @ JIR Replace this with proper tags */}
          {note.isIncident && <TextTag content={'Incident'} color='red' className='mr-small mb-x-small' />}

          {note.workflowId && (
            <TextTag
              rounded={true}
              color={'tertiary'}
              className='mr-small mb-x-small'
              size='small'
              content={
                <Text size='regular' weight='bolder'>
                  {note.workflowName}:&nbsp;
                  <HyperlinkButton
                    fontSize='regular'
                    className='ml-x2-small'
                    onClick={_goToWorkflowDetailPage(note.workflowId)}
                  >
                    Workflow {note.referenceId}
                  </HyperlinkButton>
                </Text>
              }
            />
          )}

          {/* Booking Note tag */}
          <TextTag
            className='mb-x-small'
            content={
              <>
                <Icon type='calendar' className='mr-small' />
                <Text lineHeight={100} size={'regular'}>
                  Booking note ({moment.tz(note.createdOn, timezone).format('D MMM YYYY h:mm:ss A')})
                </Text>
              </>
            }
            color={'tertiary'}
          />
          {(note.visibleType === NoteVisibleType.RESTRICTED_TO_SERVICE ||
            note.visibleType === NoteVisibleType.RESTRICTED_TO_SERVICE_PORTAL_AND_APP) && (
            <div className='ml-small'>
              <TextTag
                rounded={true}
                color='blue-lightest'
                icon={<Icon theme='twoTone' twoToneColor='black' type='lock' className='mr-x-small' />}
                content={
                  <Text size='regular' weight='bold'>
                    Restricted to service
                  </Text>
                }
              />
            </div>
          )}
          {note.visibleType === NoteVisibleType.PRIVATE_PORTAL_ONLY && (
            <div className='ml-small'>
              <TextTag
                rounded={true}
                color='blue-lightest'
                icon={<Icon theme='twoTone' twoToneColor='black' type='lock' className='mr-x-small' />}
                content={
                  <Text size='regular' weight='bold'>
                    Private to author
                  </Text>
                }
              />
            </div>
          )}
        </div>

        {/* Note contents */}
        <div className='mv-small'>
          <Text style={{ whiteSpace: 'pre-line' }} lineHeight={120}>
            {note.body}
          </Text>
        </div>

        {note.workflowFormId && (
          <div className='mt-large bg-tertiary rounded-big p-small'>
            <Text size='small' color='secondary' lineHeight={100}>
              Forms
            </Text>
            <br />
            <Text color='secondary'>
              <HyperlinkButton onClick={() => showFormModal(note)}>{note.formName}</HyperlinkButton>
            </Text>
          </div>
        )}

        {/* Attachments */}
        {!_.isEmpty(note.attachments) && (
          <div className='mt-large bg-tertiary rounded-big'>
            <div className='p-small'>
              <Text size='small' color='secondary' lineHeight={100}>
                Attachments
              </Text>
              <br />
              {_.map(note.attachments, (document) => {
                return (
                  <div className='mb-small' key={document.documentId}>
                    <span title={document.documentName}>
                      <Text
                        color={
                          document.documentStatus === 'SCANNING'
                            ? 'secondary'
                            : document.documentStatus === 'SCANNED'
                            ? 'green'
                            : 'red'
                        }
                      >
                        {document.documentStatus === 'SCANNED' ? (
                          <HyperlinkButton
                            onClick={() =>
                              downloadFileFromUrl({
                                documentUrl: document.documentUrl,
                                documentName: document.documentName,
                              })
                            }
                          >
                            <Icon type={'download'} className='mr-x-small text-color-blue' />
                            {document.documentName}
                          </HyperlinkButton>
                        ) : (
                          <>
                            <Tooltip
                              content={
                                document.documentStatus === 'SCANNING'
                                  ? 'This document is being scanned.'
                                  : 'This document failed to upload.'
                              }
                            >
                              <Icon
                                type='question-circle'
                                className={`text-size-x-large mr-x-small text-color-${
                                  document.documentStatus === 'SCANNING' ? 'blue' : 'red'
                                }`}
                              />
                            </Tooltip>
                            {document.documentName}
                          </>
                        )}
                      </Text>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* Tagged to */}
        {hastaggedCustomers && (
          <div className='mt-medium'>
            <div className='mb-x-small'>
              <Text size='small' color='secondary' lineHeight={100}>
                Tagged to
              </Text>
            </div>
            <div className='pt-medium ph-medium bordered border-standard-gray bg-quaternary rounded-big line-height-100'>
              {/* Customers */}
              <div className='flex-row flex-wrap'>
                {_.map(note.taggedTo, (customer) => (
                  <TagUserAvatar customer={customer} key={customer.taggedUserId} />
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Edited author/date display */}
        {hasEditor && (
          <div className='mt-medium flex-row align-center'>
            {/*<Avatar icon="user" size="small" shape={'circle'} className="rounded mr-small" src={editorAvatar} />*/}
            <Text size='regular' lineHeight={100} color={'secondary'}>
              Edited by <b>{editorName}</b> on {moment.tz(note.updatedOn, timezone).format('D MMM YYYY h:mm:ss A')}
            </Text>
          </div>
        )}
      </section>

      {/* Action button section */}
      {portalUser.userId === note.authorUserId && (
        <section>
          <Popover
            content={
              <ActionMenu>
                <ActionMenuItem text={'Edit note'} onClick={() => onEditNote(note)} />
                <ActionMenuItem className='text-color-red' text={'Delete note'} onClick={() => onDeleteNote(note)} />
              </ActionMenu>
            }
            position={'bottom-right'}
            usePortal={false}
          >
            <IconButton
              icon={note && note.workflowId ? '' : 'ellipsis'}
              size='default'
              iconColor={'blue-action'}
              color={'white'}
              bordered={!note.workflowId}
              className={`border-standard-gray ${!!note.workflowId && 'cursor-default'}`}
              disabled={!!note.workflowId}
            />
          </Popover>
        </section>
      )}
    </div>
  );
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

export default connect(mapState, null)(BookingNoteItem);
