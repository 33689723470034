import { Input } from 'antd';
import React from 'react';

interface IParagraphProps {
  value: string;
  placeholder?: string;
}

export default function ParagraphElement(props: IParagraphProps) {
  return (
    <Input.TextArea
      className="bordered-none bg-transparent"
      rows={1}
      autoSize
      style={{ resize: 'none' }}
      placeholder={props.placeholder}
      value={props.value}
    />
  );
}
