import React, { useState } from 'react';
import { Modal, Button, RadioGroup } from 'design-components';
import { UserPermissionRole } from 'utilities/enum-utils';
import { IPermissionRole, IPermissions } from 'interfaces/account-interfaces';
import { Box, Inline, Link, Stack, Text } from '@goodhuman-me/components';
import { useTranslation } from 'react-i18next';
import ActionSelectedItem from 'views/team-v2/team-details/advanced-permission/ActionSelectedItem';

interface ICustomerPermissionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChangePermissions: (payload: IPermissions) => void;
  permissions: IPermissions;
}

const CustomerPermissionsModal = ({
  isOpen,
  onClose,
  onChangePermissions,
  permissions,
}: ICustomerPermissionsModalProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'permissions.customers' });

  const customerAccessPermissions = [
    UserPermissionRole.CustomerAccessViewOnly,
    UserPermissionRole.CustomerAccessManager,
  ];
  const initAccessPermission = () =>
    permissions.permissionRoles.find((permission) => customerAccessPermissions.includes(permission.permissionRole))
      ?.permissionRole ?? UserPermissionRole.CustomerAccessViewOnly;

  const customerViewPermissions = [UserPermissionRole.CustomerViewAll, UserPermissionRole.CustomerViewServiceOnly];
  const initViewPermission = () =>
    permissions.permissionRoles.find((permission) => customerViewPermissions.includes(permission.permissionRole))
      ?.permissionRole ?? UserPermissionRole.CustomerViewAll;

  const [accessPermission, setAccessPermission] = useState(initAccessPermission());
  const [viewPermission, setViewPermission] = useState(initViewPermission());

  const onSelectCustomerPermissions = () => {
    const { permissionRoles } = permissions;

    const filteredRoles = permissionRoles.filter(
      (role) => ![...customerAccessPermissions, ...customerViewPermissions].includes(role.permissionRole),
    );

    const newCustomerAccessRole: IPermissionRole = {
      permissionRole: accessPermission,
      serviceDepartmentId: null,
      serviceId: null,
    };

    const newCustomerViewRole: IPermissionRole = {
      permissionRole: viewPermission,
      serviceDepartmentId: null,
      serviceId: null,
    };

    const updatedPermissions = {
      ...permissions,
      permissionRoles: [...filteredRoles, newCustomerAccessRole, newCustomerViewRole],
    };

    onChangePermissions(updatedPermissions);
  };

  const selectAndClose = () => {
    onSelectCustomerPermissions();
    onClose();
  };

  return (
    <Modal
      maxHeight={602}
      maxWidth={600}
      size="medium"
      header="Customers"
      onClose={onClose}
      isOpen={isOpen}
      footer={
        <Inline justifyContent="flex-end" gap="$space200">
          <Button size="large" emphasis="quiet" kind="accent" onClick={onClose}>
            Cancel
          </Button>
          <Button size="large" emphasis="filled" kind="accent" onClick={selectAndClose}>
            Save changes
          </Button>
        </Inline>
      }
    >
      <Box asChild>
        <div className="anim-slide-left">
          <Stack marginBottom="$large" gap="$large">
            <Text size="medium" color="$muted">
              {t('description')}
            </Text>
            {/* View Customer Permissions */}
            <Stack gap="$small">
              <Box>
                <Text size="small" color="$default" fontWeight="$semi">
                  {t('viewPermissionRadio.label')}
                </Text>
              </Box>
              <RadioGroup
                aria-label="customer-view"
                value={viewPermission}
                onChange={(value) => setViewPermission(value)}
              >
                <ActionSelectedItem
                  ariaLabel="customer-view-all"
                  label={<Text color="$bodyDark2">{t('viewPermissionRadio.optionAll')}</Text>}
                  value={UserPermissionRole.CustomerViewAll}
                  selectedPermission={viewPermission}
                />
                <ActionSelectedItem
                  ariaLabel="customer-view-service-only"
                  label={
                    <Stack gap="$xxsmall">
                      <Text color="$bodyDark2">{t('viewPermissionRadio.optionService')}</Text>
                      <Text size="xxsmall" color="$muted" fontWeight="light" width="$size5000">
                        {t('viewPermissionRadio.optionServiceInfo')}
                      </Text>
                      <Text size="xxsmall">
                        <Link asChild>
                          <a href={t('viewPermissionRadio.optionServiceLinkUrl')} target="_blank" rel="noreferrer">
                            {t('viewPermissionRadio.optionServiceLinkLabel')}
                          </a>
                        </Link>
                      </Text>
                    </Stack>
                  }
                  value={UserPermissionRole.CustomerViewServiceOnly}
                  selectedPermission={viewPermission}
                />
              </RadioGroup>
            </Stack>
            {/* Access Customer Permissions */}
            <Stack gap="$small">
              <Box>
                <Text size="small" color="$default" fontWeight="$semi">
                  {t('editPermissionRadio.label')}
                </Text>
              </Box>
              <RadioGroup
                aria-label="customer-access"
                value={accessPermission}
                onChange={(value) => setAccessPermission(value)}
              >
                <ActionSelectedItem
                  ariaLabel="customer-access-view-only"
                  label={<Text color="$bodyDark2">{t('editPermissionRadio.optionView')}</Text>}
                  value={UserPermissionRole.CustomerAccessViewOnly}
                  selectedPermission={accessPermission}
                />
                <ActionSelectedItem
                  ariaLabel="customer-access-manager"
                  label={<Text color="$bodyDark2">{t('editPermissionRadio.optionManage')}</Text>}
                  value={UserPermissionRole.CustomerAccessManager}
                  selectedPermission={accessPermission}
                />
              </RadioGroup>
            </Stack>
          </Stack>
        </div>
      </Box>
    </Modal>
  );
};

export default CustomerPermissionsModal;
