import React, { Component } from 'react';
import { Col, Row, Icon, Checkbox } from 'antd';
import _ from 'lodash';
import { HyperlinkButton } from 'common-components/buttons';
import NumberInput from 'common-components/inputs/NumberInput';
import CommonUtils from 'utilities/common-utils';
import { MmmGroup, VCPSupportItemDateTypeLabel } from 'utilities/enum-utils';
import { ndisHelper } from 'variables/data-helpers';

interface INDISLineItemGroupProps {
  groupedLineItems: Array<any>;
  displayMode: string;
  selectedLineItems?: Array<any>;
  onClickLineItem?: any;
  onClickGeneralCheckbox?: any;
  isSequenceAdjustable?: boolean;
  isSelectable?: boolean;
  onClickSequenceDown?: any;
  onClickSequenceUp?: any;
  index: number;
  numberOfDisplayLineItems: number;
  isDeletable?: boolean;
  onDelete?: any;
  onDeleteGroupLineItem?: (e, lineItemNumber) => void;
  isPriceAdjustable?: boolean;
  onEditLineItemPrice?: (lineItemNumber, newPrice, priceLabel) => void;
  getPriceLabel: (type) => void;
  numberOfSelectedGroups: number;
  mmmGroup?: MmmGroup;
  state?: string;
  size?: 'small';
  isServiceAgreementLineItems?: boolean;
  paymentSourceType?: string;
  onlyShowSpecificPrice?: boolean;
  vcpItems?: any;
}

interface INDISLineItemGroupState {
  isOpen: boolean;
}

const unitOptions = [
  { value: 'EA', label: 'Each' },
  { value: 'H', label: 'Hourly' },
  { value: 'D', label: 'Daily' },
  { value: 'WK', label: 'Weekly' },
  { value: 'MON', label: 'Monthly' },
  { value: 'YR', label: 'Yearly' },
];

class NDISLineItemGroup extends Component<INDISLineItemGroupProps, INDISLineItemGroupState> {
  state = {
    isOpen: false,
  };

  private onPressShowMoreLess = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const {
      groupedLineItems,
      displayMode,
      isSequenceAdjustable,
      isDeletable,
      isSelectable,
      isPriceAdjustable,
      onEditLineItemPrice,
      getPriceLabel,
      mmmGroup,
      size = null,
      isServiceAgreementLineItems = false,
      paymentSourceType,
    } = this.props;
    const { isOpen } = this.state;
    const hasActionCol = !!(isSequenceAdjustable || isDeletable);
    const hasOneOrMoreSelected = _.filter(groupedLineItems, (lineItems) =>
      _.find(
        this.props.selectedLineItems,
        (selectedLineItems) => selectedLineItems.supportItemNumber === lineItems.supportItemNumber,
      ),
    );
    const selectedGroupIntersection = _.intersectionBy(
      this.props.selectedLineItems,
      groupedLineItems,
      'supportItemNumber',
    );
    const hasCheckbox = isSelectable;
    const onlyShowSpecificPrice = isServiceAgreementLineItems || !isNaN(mmmGroup) || this.props.onlyShowSpecificPrice;

    return (
      <>
        <tr className={`cursor-pointer bordered-bottom`} onClick={this.onPressShowMoreLess}>
          {hasCheckbox && (
            <td style={{ width: '50px' }} className={'p-medium'}>
              <Checkbox
                checked={
                  selectedGroupIntersection &&
                  groupedLineItems &&
                  selectedGroupIntersection.length === groupedLineItems.length
                }
                indeterminate={
                  selectedGroupIntersection &&
                  groupedLineItems &&
                  groupedLineItems.length > selectedGroupIntersection.length &&
                  selectedGroupIntersection.length > 0
                }
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.onClickGeneralCheckbox(groupedLineItems);
                }}
              />
            </td>
          )}
          <td
            colSpan={onlyShowSpecificPrice ? 3 : 5}
            className={'p-medium'}
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            <div className="flex row">
              <div className={size === 'small' ? 'mr-large' : 'mr-small'}>
                <Icon type={!isOpen ? 'right' : 'down'} />
              </div>
              <b>{groupedLineItems[0].groupName}</b>
            </div>
          </td>
          {hasActionCol && (
            <td style={{ width: '88px', display: 'table-cell' }} className="text-align-right ph-medium flex-row">
              {((isSelectable && isSequenceAdjustable && !_.isEmpty(hasOneOrMoreSelected)) ||
                (!isSelectable && isSequenceAdjustable)) && (
                <>
                  {this.props.index > 0 && (
                    <HyperlinkButton
                      className={'mh-x-small'}
                      onClick={(e) => this.props.onClickSequenceUp(e, groupedLineItems)}
                    >
                      <Icon type="arrow-up" className="cursor-pointer" />
                    </HyperlinkButton>
                  )}
                  {((isSelectable && this.props.index < this.props.numberOfSelectedGroups - 1) ||
                    (!isSelectable && this.props.index < this.props.numberOfDisplayLineItems - 1)) && (
                    <HyperlinkButton
                      className={'mh-x-small'}
                      onClick={(e) => this.props.onClickSequenceDown(e, groupedLineItems)}
                    >
                      <Icon type="arrow-down" className="cursor-pointer" />
                    </HyperlinkButton>
                  )}
                </>
              )}
              {isDeletable && (
                <HyperlinkButton className={'mh-x-small'} onClick={(e) => this.props.onDelete(e, groupedLineItems)}>
                  <Icon type="cross" className="cursor-pointer" />
                </HyperlinkButton>
              )}
            </td>
          )}
        </tr>
        {isOpen &&
          _.map(groupedLineItems, (lineItem, key) => {
            const isSelected = _.find(
              this.props.selectedLineItems,
              (selectedLineItem) => selectedLineItem.supportItemNumber === lineItem.supportItemNumber,
            );
            const lineItemData =
              paymentSourceType === 'NDIS'
                ? ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber)
                : _.find(this.props.vcpItems, (item) => item.supportItemNumber === lineItem.supportItemNumber);

            const isPriceControlled =
              paymentSourceType === 'NDIS'
                ? lineItemData && lineItemData.PriceControl === 'Y'
                : lineItemData && lineItemData.priceControl === true;

            return (
              <tr
                key={key}
                className={`${key === groupedLineItems.length - 1 && 'bordered-bottom'} pv-medium`}
                style={{ verticalAlign: 'top' }}
              >
                {hasCheckbox && <td style={{ width: '50px' }} />}
                <td
                  className={'pv-medium flex-row'}
                  title={lineItem.supportItemNumber}
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  <div
                    style={{ width: size === 'small' ? '20px' : '50px' }}
                    className={paymentSourceType === 'NDIS' ? 'p-medium' : 'ph-medium'}
                  >
                    {hasCheckbox && (
                      <Checkbox checked={isSelected} onClick={() => this.props.onClickLineItem(lineItem)} />
                    )}
                  </div>
                  {paymentSourceType === 'NDIS' && (
                    <div className={'mh-medium'}>
                      <>
                        {lineItem.supportItemNumber}
                        <br />
                        {lineItem.supportItem}
                      </>
                    </div>
                  )}
                  {paymentSourceType === 'VCP' && <>{VCPSupportItemDateTypeLabel[lineItem.dateType]}</>}
                </td>
                {onlyShowSpecificPrice ? (
                  <td style={{ width: '321px' }} className={'text-align-right p-medium'}>
                    {isPriceAdjustable ? (
                      <>
                        <NumberInput
                          onChange={(event) => onEditLineItemPrice(lineItem.supportItemNumber, event, 'nonRemotePrice')}
                          value={lineItem.price ? lineItem.price.nonRemotePrice : lineItem.agreementPrice}
                          addonBefore={'$'}
                          min={0}
                          max={9999.99}
                          precision={2}
                          style={{ width: '120px' }}
                        />
                      </>
                    ) : (
                      <>
                        {CommonUtils.formatPrice(
                          isServiceAgreementLineItems && this.props.displayMode === 'VIEW'
                            ? // &&
                              // paymentSourceType === 'NDIS'
                              Number(lineItem.agreementPrice)
                            : paymentSourceType === 'VCP'
                            ? lineItem.price.nonRemotePrice
                            : mmmGroup === MmmGroup.NonRemote
                            ? CommonUtils.findStandardPrice(lineItem, this.props.state, 'API')
                            : lineItem.price[CommonUtils.switchMMMGroupString(mmmGroup)],
                        )}
                      </>
                    )}
                  </td>
                ) : (
                  <>
                    <td style={{ width: '152px' }} className={'text-align-right p-medium'}>
                      {isPriceControlled &&
                      (!lineItemData.NationalNonRemote || lineItemData.NationalNonRemote === '') ? (
                        <>
                          <div className={'mb-large'}>
                            {isPriceAdjustable ? (
                              <NumberInput
                                onChange={(event) => onEditLineItemPrice(lineItem.supportItemNumber, event, 'actPrice')}
                                value={lineItem.price.actPrice}
                                addonBefore={'$'}
                                min={0}
                                max={9999.99}
                                precision={2}
                                style={{ width: '120px' }}
                              />
                            ) : (
                              <div>{CommonUtils.formatPrice(lineItem.price.actPrice)}</div>
                            )}
                            {getPriceLabel('act')}
                          </div>
                          {isPriceAdjustable ? (
                            <NumberInput
                              onChange={(event) => onEditLineItemPrice(lineItem.supportItemNumber, event, 'ntPrice')}
                              value={lineItem.price.ntPrice}
                              addonBefore={'$'}
                              min={0}
                              max={9999.99}
                              precision={2}
                              style={{ width: '120px' }}
                            />
                          ) : (
                            <div>{CommonUtils.formatPrice(lineItem.price.ntPrice)}</div>
                          )}
                          {getPriceLabel('nt')}
                        </>
                      ) : isPriceAdjustable ? (
                        <NumberInput
                          onChange={(event) => onEditLineItemPrice(lineItem.supportItemNumber, event, 'nonRemotePrice')}
                          value={lineItem.price.nonRemotePrice}
                          addonBefore={'$'}
                          min={0}
                          max={9999.99}
                          precision={2}
                          style={{ width: '120px' }}
                        />
                      ) : (
                        CommonUtils.formatPrice(lineItem.price.nonRemotePrice)
                      )}
                    </td>
                    <td style={{ width: '152px' }} className={'text-align-right p-medium'}>
                      {isPriceControlled &&
                        (isPriceAdjustable ? (
                          <NumberInput
                            onChange={(event) => onEditLineItemPrice(lineItem.supportItemNumber, event, 'remotePrice')}
                            value={lineItem.price.remotePrice}
                            addonBefore={'$'}
                            min={0}
                            max={9999.99}
                            precision={2}
                            style={{ width: '120px' }}
                          />
                        ) : (
                          CommonUtils.formatPrice(lineItem.price.remotePrice)
                        ))}
                    </td>
                    <td style={{ width: '152px' }} className={'text-align-right p-medium'}>
                      {isPriceControlled &&
                        (isPriceAdjustable && isPriceControlled ? (
                          <NumberInput
                            onChange={(event) =>
                              onEditLineItemPrice(lineItem.supportItemNumber, event, 'veryRemotePrice')
                            }
                            value={lineItem.price.veryRemotePrice}
                            addonBefore={'$'}
                            min={0}
                            max={9999.99}
                            precision={2}
                            style={{ width: '120px' }}
                          />
                        ) : (
                          CommonUtils.formatPrice(lineItem.price.veryRemotePrice)
                        ))}
                    </td>
                  </>
                )}
                <td style={{ width: '88px' }} className={'text-align-right p-medium'}>
                  {unitOptions.find((i) => i.value === lineItem.unit).label}
                </td>
                {isDeletable && groupedLineItems.length > 1 && (
                  <td style={{ width: '116px' }} className={'text-align-right p-medium'}>
                    <HyperlinkButton
                      className={'mh-x-small'}
                      onClick={(e) => this.props.onDeleteGroupLineItem(e, lineItem)}
                    >
                      <Icon type="cross" />
                    </HyperlinkButton>
                  </td>
                )}
              </tr>
            );
          })}
      </>
    );
  }
}

export default NDISLineItemGroup;
