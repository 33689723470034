import { QueryClient } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';

import { addStatus, removeStatus, matchStatus, ItemWithStatus } from './optimistic-status';

export class OptimisticCreate<T extends object> {
  private queryClient: QueryClient;
  private key: string;
  private keys: string[];
  private item: ItemWithStatus<T> | undefined;
  private optimisticId: string;
  private onAdd: (prevData: T[], item: T) => T[];

  constructor(queryClient: QueryClient, key: string, keys: string[], onAdd?: (prevData: T[], item: T) => T[]) {
    this.queryClient = queryClient;
    this.key = key;
    this.keys = keys;
    this.optimisticId = uuid();
    if (onAdd) {
      this.onAdd = onAdd;
    } else {
      this.onAdd = (prevData, item) => [...prevData, item];
    }
  }

  public async start(item: T): Promise<void> {
    await this.queryClient.cancelQueries([this.key, ...this.keys]);
    this.item = addStatus(item, 'create', this.optimisticId);
    this.addItem(this.item);
  }

  public rollback(): void {
    this.removeItem();
  }

  public finish(newItem?: T): void {
    this.updateItem(newItem, true);
  }

  private addItem(newItem: T) {
    this.queryClient.setQueryData([this.key, ...this.keys], (prev: T[] | undefined) => this.onAdd(prev ?? [], newItem));
  }

  private removeItem() {
    this.queryClient.setQueryData(
      [this.key, ...this.keys],
      (prev: T[] | undefined) => prev?.filter((item) => !matchStatus(item, 'create', this.optimisticId)) ?? [],
    );
  }

  private updateItem(newItem?: T, removeCreateStatus?: boolean) {
    const updater = (item: T) =>
      matchStatus(item, 'create', this.optimisticId)
        ? { ...(removeCreateStatus ? removeStatus(item, 'create') : item), ...newItem }
        : item;

    this.queryClient.setQueryData([this.key, ...this.keys], (prev: T[] | undefined) => prev?.map(updater) ?? []);
  }
}
