import { Col, Form, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import FullScreenScrollableModal, { FullScreenModalFooter } from 'common-components/modal/FullScreenScrollableModal';
import Steps from 'common-components/steps/Steps';
import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ServiceType } from 'utilities/enum-utils';
import ServiceUtils from 'utilities/service-utils';
import AddSupportCoordinatorStepPanel from './wizard-steps/AddSupportCoordinatorStepPanel';
import ConfirmationStepPanel from './wizard-steps/ConfirmationStepPanel';
import GroupServiceClassification from './wizard-steps/GroupServiceClassificationStepPanel';
import InformationStepPanel from './wizard-steps/InformationStepPanel';
import LocationStepPanel from './wizard-steps/LocationStepPanel';
import NDISSettingsStepPanel from './wizard-steps/NDISSettingsStepPanel';
import PaymentMethodStepPanel from './wizard-steps/PaymentMethodStepPanel';
import PaymentSettingsStepPanel from './wizard-steps/PaymentSettingsStepPanel';
import PublishStepPanel from './wizard-steps/PublishStepPanel';
// Panels
import { ServiceTypeStepPanel } from './wizard-steps/ServiceTypeStepPanel';
import SummaryStepPanel from './wizard-steps/SummaryStepPanel';
import WhenStepPanel from './wizard-steps/WhenStepPanel';
import AddActivityGroup from './wizard-steps/AddActivityGroup';

interface ICreateNewServiceModalProps extends FormComponentProps {
  closeCreateServiceModal: (serviceData?: any, isGroupService?: boolean) => void;
  setNewService?: typeof dispatch.servicesStore.setNewService;
  newService?: typeof state.servicesStore.newService;
  doCreateNewService?: typeof dispatch.servicesStore.doCreateNewService;
  doCreateNewGroupService?: typeof dispatch.servicesStore.doCreateNewGroupService;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  companyData: typeof state.companyStore.companyDataLite;
  isOpen: boolean;
  history: any;
  authStore: typeof state.authStore;
}

interface ICreateNewServiceModalState {
  step: number;
  totalStep: number;
  isProceedOpen: boolean;
}

class CreateNewServiceModal extends Component<ICreateNewServiceModalProps, ICreateNewServiceModalState> {
  state = {
    step: 1,
    totalStep: 1,
    isProceedOpen: false,
  };

  private _createNewEmptyService = () => {
    const serviceProviderId = this.props.authStore.portalUser.serviceProviderId;
    const timezone = this.props.companyData.timezone;

    return ServiceUtils.createNewEmptyService(serviceProviderId, timezone);
  };

  private _onNextStep = (payload?: any) => {
    if (this.state.step === 1) {
      const { serviceType } = payload;
      if (serviceType === ServiceType.COORDINATION) {
        this.setState({ totalStep: 6 });
      } else {
        this.setState({ totalStep: 9 });
      }
    }
    this.setState({ step: this.state.step + 1 });
  };

  private _onPreviousStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  private _closeCreateServiceModal = async () => {
    if (this.state.step > 1) this.setState({ isProceedOpen: true });
    else this._resetAndCloseModal();
  };

  private _resetAndCloseModal = () => {
    this.props.setNewService(this._createNewEmptyService());
    this.setState({ isProceedOpen: false, step: 1, totalStep: 1 });
    this.props.closeCreateServiceModal();
  };

  private _goToNewService = () => {
    const { newService } = this.props;
    const newServiceData = newService;
    this.props.setNewService(this._createNewEmptyService());
    this.setState({ isProceedOpen: false, step: 1, totalStep: 1 });
    this.props.closeCreateServiceModal(newServiceData, newService?.serviceType === ServiceType.GROUP);
  };

  private _isGroupService = () => {
    return this.props.newService.serviceType && this.props.newService.serviceType === ServiceType.GROUP;
  };

  private _restartServiceCreation = () => {
    this.props.setNewService(this._createNewEmptyService());
    this.setState({ step: 1, totalStep: 1 });
  };

  componentDidMount = async () => {
    await this.props.doFetchCompanyLite([]);
    await this.props.setNewService(this._createNewEmptyService());
  };

  private _setTotalSteps = (serviceType: ServiceType) => {
    this.setState({ totalStep: serviceType === ServiceType.COORDINATION ? 6 : 9 });
  };

  private _renderStepContent = () => {
    const { setNewService, newService, doCreateNewService, doCreateNewGroupService, authStore, companyData } =
      this.props;
    const { step } = this.state;

    // all services
    if (step === 1) {
      return (
        <ServiceTypeStepPanel
          onNextStep={this._onNextStep}
          setNewService={setNewService}
          newService={newService}
          serviceProviderId={authStore.portalUser && authStore.portalUser.serviceProviderId}
          timezone={companyData && companyData.timezone}
          setTotalSteps={(serviceType) => this._setTotalSteps(serviceType)}
        />
      );
    }

    if (step === 2) {
      return (
        <InformationStepPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          setNewService={setNewService}
          newService={newService}
        />
      );
    }

    // support & group service
    if (step === 3) {
      switch (newService?.serviceType) {
        case ServiceType.GROUP:
          return (
            <GroupServiceClassification
              onNextStep={this._onNextStep}
              onPreviousStep={this._onPreviousStep}
              setNewService={setNewService}
              newService={newService}
            />
          );

        case ServiceType.INDIVIDUAL:
          return (
            <LocationStepPanel
              onNextStep={this._onNextStep}
              onPreviousStep={this._onPreviousStep}
              setNewService={setNewService}
              newService={newService}
            />
          );

        case ServiceType.COORDINATION:
          return (
            <LocationStepPanel
              onNextStep={this._onNextStep}
              onPreviousStep={this._onPreviousStep}
              setNewService={setNewService}
              newService={newService}
            />
          );

        case ServiceType.INDEPENDENT_LIVING:
          return (
            <LocationStepPanel
              onNextStep={this._onNextStep}
              onPreviousStep={this._onPreviousStep}
              setNewService={setNewService}
              newService={newService}
            />
          );

        default:
          break;
      }
    } else if (step === 4) {
      switch (newService?.serviceType) {
        case ServiceType.GROUP:
          return (
            <LocationStepPanel
              onNextStep={this._onNextStep}
              onPreviousStep={this._onPreviousStep}
              setNewService={setNewService}
              newService={newService}
            />
          );

        case ServiceType.INDIVIDUAL:
          return (
            <WhenStepPanel
              onNextStep={this._onNextStep}
              onPreviousStep={this._onPreviousStep}
              setNewService={setNewService}
              newService={newService}
            />
          );

        case ServiceType.COORDINATION:
          return (
            <NDISSettingsStepPanel
              onNextStep={this._onNextStep}
              onPreviousStep={this._onPreviousStep}
              setNewService={setNewService}
              newService={newService}
            />
          );

        case ServiceType.INDEPENDENT_LIVING:
          return (
            <WhenStepPanel
              onNextStep={this._onNextStep}
              onPreviousStep={this._onPreviousStep}
              setNewService={setNewService}
              newService={newService}
            />
          );

        default:
          break;
      }
    } else if (step === 5) {
      if (this.props.newService?.serviceType === ServiceType.COORDINATION) {
        return (
          <AddSupportCoordinatorStepPanel
            onNextStep={this._onNextStep}
            onPreviousStep={this._onPreviousStep}
            setNewService={setNewService}
            newService={newService}
          />
        );
      }

      if (newService?.serviceType === ServiceType.INDIVIDUAL) {
        return (
          <PaymentMethodStepPanel
            onNextStep={this._onNextStep}
            onPreviousStep={this._onPreviousStep}
            setNewService={setNewService}
            newService={newService}
          />
        );
      }

      if (newService?.serviceType === ServiceType.INDEPENDENT_LIVING) {
        return (
          <PublishStepPanel
            onNextStep={this._onNextStep}
            onPreviousStep={this._onPreviousStep}
            setNewService={setNewService}
            newService={newService}
          />
        );
      }

      return (
        <PaymentSettingsStepPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          setNewService={setNewService}
          newService={newService}
        />
      );
    } else if (step === 6) {
      if (newService?.serviceType === ServiceType.COORDINATION) {
        return (
          <SummaryStepPanel
            onPreviousStep={this._onPreviousStep}
            setNewService={setNewService}
            newService={newService}
            resetAndCloseModal={this._resetAndCloseModal}
            doCreateNewService={doCreateNewService}
            goToNewService={this._goToNewService}
            restartServiceCreation={this._restartServiceCreation}
          />
        );
      }

      if (newService?.serviceType === ServiceType.INDIVIDUAL) {
        return (
          <PaymentSettingsStepPanel
            onNextStep={this._onNextStep}
            onPreviousStep={this._onPreviousStep}
            setNewService={setNewService}
            newService={newService}
          />
        );
      }

      if (newService?.serviceType === ServiceType.INDEPENDENT_LIVING) {
        return (
          <SummaryStepPanel
            onPreviousStep={this._onPreviousStep}
            onNextStep={this._onNextStep}
            setNewService={setNewService}
            newService={newService}
            resetAndCloseModal={this._resetAndCloseModal}
            doCreateNewService={doCreateNewService}
            doCreateNewGroupService={doCreateNewGroupService}
            goToNewService={this._goToNewService}
            restartServiceCreation={this._restartServiceCreation}
          />
        );
      }

      return (
        <AddActivityGroup
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          setNewService={setNewService}
          newService={newService}
        />
      );
    } else if (step === 7) {
      if (newService?.serviceType === ServiceType.INDEPENDENT_LIVING) {
        return (
          <ConfirmationStepPanel
            onPreviousStep={this._onPreviousStep}
            newService={newService}
            goToNewService={this._goToNewService}
            restartServiceCreation={this._restartServiceCreation}
          />
        );
      }
      return (
        <PublishStepPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          setNewService={setNewService}
          newService={newService}
        />
      );
    } else if (step === 8) {
      if (!this._isGroupService()) {
        return (
          <SummaryStepPanel
            onPreviousStep={this._onPreviousStep}
            onNextStep={this._onNextStep}
            setNewService={setNewService}
            newService={newService}
            resetAndCloseModal={this._resetAndCloseModal}
            doCreateNewService={doCreateNewService}
            doCreateNewGroupService={doCreateNewGroupService}
            goToNewService={this._goToNewService}
            restartServiceCreation={this._restartServiceCreation}
          />
        );
      }

      return (
        <SummaryStepPanel
          onPreviousStep={this._onPreviousStep}
          onNextStep={this._onNextStep}
          setNewService={setNewService}
          newService={newService}
          resetAndCloseModal={this._resetAndCloseModal}
          doCreateNewService={doCreateNewService}
          doCreateNewGroupService={doCreateNewGroupService}
        />
      );
    } else if (step === 9) {
      return (
        <ConfirmationStepPanel
          onPreviousStep={this._onPreviousStep}
          newService={newService}
          goToNewService={this._goToNewService}
          restartServiceCreation={this._restartServiceCreation}
        />
      );
    }
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedOpen: false });
  };

  private _renderStepTitle = (): string => {
    const { step } = this.state;
    let title = 'Select service type';

    if (this.props.newService?.serviceType === ServiceType.INDIVIDUAL) {
      switch (step) {
        case 1:
          title = 'Select service type';
          break;
        case 2:
          title = 'General Information';
          break;
        case 3:
          title = 'Location';
          break;
        case 4:
          title = 'When';
          break;
        case 5:
          title = 'Payment method';
          break;
        case 6:
          title = 'Billing line items';
          break;
        case 7:
          title = 'Publish rules';
          break;
        case 8:
          title = 'Service summary';
          break;
        case 9:
          title = 'Confirmation';
          break;
        default:
          break;
      }
    } else if (this.props.newService?.serviceType === ServiceType.GROUP) {
      switch (step) {
        case 1:
          title = 'Select service type';
          break;
        case 2:
          title = 'General Information';
          break;
        case 3:
          title = 'Service classification';
          break;
        case 4:
          title = 'Service location and capacity';
          break;
        case 5:
          title = 'NDIS price settings';
          break;
        case 6:
          title = 'Add activity group';
          break;
        case 7:
          title = 'Publication settings';
          break;
        case 8:
          title = 'Overview';
          break;
        case 9:
          title = 'Confirmation';
          break;
        default:
          break;
      }
    } else if (this.props.newService?.serviceType === ServiceType.COORDINATION) {
      switch (step) {
        case 1:
          title = 'Select service type';
          break;
        case 2:
          title = 'Basic service info';
          break;
        case 3:
          title = 'Location';
          break;
        case 4:
          title = 'Select NDIS line items';
          break;
        case 5:
          title = 'Add support coordinators'; //todo check figma
          break;
        case 6:
          title = 'Review';
          break;
        default:
          break;
      }
    } else if (this.props.newService?.serviceType === ServiceType.INDEPENDENT_LIVING) {
      switch (step) {
        case 1:
          title = 'Select service type';
          break;
        case 2:
          title = 'General Information';
          break;
        case 3:
          title = 'Service location';
          break;
        case 4:
          title = 'Availability';
          break;
        case 5:
          title = 'Publication settings';
          break;
        case 6:
          title = 'Overview';
          break;
        case 7:
          title = 'Confirmation';
          break;
        default:
          break;
      }
    }

    return title;
  };

  render() {
    const { totalStep } = this.state;
    let steps = [];
    for (let i = 1; i <= totalStep; i++) {
      let stepObj = {};
      stepObj['stepNumber'] = i;
      stepObj['stepLabel'] = i;
      steps.push(stepObj);
    }

    return (
      <>
        <ActionModal
          isOpen={this.state.isProceedOpen}
          onClose={this._closeProceedModal}
          title={'Cancelling creating service'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, are you sure you want to <b>cancel</b> now ?
          </Text>
          <br />
          <Text className={'mb-medium'} weight={'bold'}>
            Your progress will not be saved.
          </Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
              Back
            </PrimaryButton>
            <GhostButton size="large" onClick={this._resetAndCloseModal}>
              Cancel
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <FullScreenScrollableModal
          isOpen={this.props.isOpen}
          onClose={this._closeCreateServiceModal}
          width={'large'}
          canCloseEsc={false}
          fixedHeight={false}
          footerContent={
            <FullScreenModalFooter align={'left'}>
              <Row gutter={0} type="flex" align="middle">
                <Col span={8} />
                <Col span={8}>
                  <div className="flex-row justify-center">
                    <Text size="regular" className="mb-medium">
                      Step {this.state.step}:{' '}
                      <span className="mr-medium text-weight-bold">{this._renderStepTitle()}</span>
                    </Text>
                  </div>
                  <div className={totalStep == 1 && 'flex justify-start'}>
                    <Steps currentStep={this.state.step} steps={steps} />
                  </div>
                </Col>
                <Col span={8} />
              </Row>
            </FullScreenModalFooter>
          }
        >
          {this._renderStepContent()}
        </FullScreenScrollableModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  newService: state.servicesStore.newService,
  authStore: state.authStore,
  companyData: state.companyStore.companyDataLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewService: dispatch.servicesStore.setNewService,
  doCreateNewService: dispatch.servicesStore.doCreateNewService,
  doCreateNewGroupService: dispatch.servicesStore.doCreateNewGroupService,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
});

export default connect(mapState, mapDispatch)(Form.create<ICreateNewServiceModalProps>()(CreateNewServiceModal));
