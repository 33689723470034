import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const TextFormat = forwardRef(function TextFormat(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 2.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-8 2h5.354c.259.916.98 1.637 1.896 1.896v10.708a2.756 2.756 0 0 0-1.896 1.896H6.646a2.756 2.756 0 0 0-1.896-1.896V6.646A2.756 2.756 0 0 0 6.646 4.75H12Zm-5.354-1.5h10.708a2.751 2.751 0 1 1 3.396 3.396v10.708a2.751 2.751 0 1 1-3.396 3.396H6.646a2.751 2.751 0 1 1-3.396-3.396V6.646A2.751 2.751 0 1 1 6.646 3.25ZM9 8.25a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0V9.75H15a.75.75 0 0 0 0-1.5H9ZM18.75 20a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM4 18.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM2.75 4a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
