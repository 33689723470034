import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Checkbox } from '../checkbox';
import { Text } from '../text';
import { BoxStyleProps, compose, CSS, css } from '../theme';
import { TableColumn, TableDataSource } from './table-interface';
import * as styles from './table.css';
import { Skeleton } from 'antd';

export type TableProps = PropsWithChildren<
  BoxStyleProps & {
    /**
     * Whether a row is selectable
     */
    enableSelect?: boolean;
    /**
     * Select mode
     */
    selectionMode?: 'multiple' | 'single';
    /**
     * Data for each row of table
     */
    dataSources: TableDataSource<unknown>[];
    /**
     * Column infos
     */
    columns: TableColumn<unknown>[];
    /**
     * Disable column horizontal borders
     */
    hasHorizontalBorder?: boolean;
    /**
     * Styles for select column
     */
    selectStyles?: CSS;
    /**
     * Styles for table wrapper
     */
    tableWrapperStyles?: CSS;
    /**
     * Manual handle selected key
     */
    selectedKeys?: string[];
    /**
     * Empty state
     */
    emptyState?: React.ReactNode;
    /**
     * Whether table is loading
     */
    isLoading?: boolean;
    /**
     * Select handle
     */
    onSelectionChange?(keys: string[]): void;
  }
>;

export const Table: React.FC<TableProps> = (props) => {
  const {
    columns,
    dataSources,
    enableSelect = false,
    selectionMode = 'multiple',
    tableWrapperStyles,
    hasHorizontalBorder = true,
    selectStyles,
    selectedKeys: manualSelectedKeys,
    emptyState,
    isLoading,
    onSelectionChange,
  } = props;

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const handleSelectedKeysChange = (keys: string[]) => {
    if (!manualSelectedKeys) {
      setSelectedKeys(keys);
    }
    if (onSelectionChange) {
      onSelectionChange(keys);
    }
  };

  const onKeyChange = (key: string, isSelected: boolean) => {
    if (selectionMode === 'single') {
      if (isSelected) {
        handleSelectedKeysChange([key]);
      } else {
        handleSelectedKeysChange([]);
      }
    } else {
      // Logic add if don't exist otherwise remove it
      const newSelectedKeys = selectedKeys.slice();
      const index = newSelectedKeys.findIndex((selectedKey) => selectedKey === key);
      if (isSelected && index < 0) {
        newSelectedKeys.push(key);
      } else {
        newSelectedKeys.splice(index, 1);
      }

      handleSelectedKeysChange(newSelectedKeys);
    }
  };

  const onCheckAllChange = () => {
    if (selectedKeys.length < dataSources.length) {
      handleSelectedKeysChange(dataSources.map((row) => row.key));
    } else {
      handleSelectedKeysChange([]);
    }
  };

  const renderRowContent = () => {
    return isLoading ? (
      <tr>
        <td colSpan={columns.length}>
          <Skeleton paragraph={{ rows: columns.length, width: '100%' }} active={true} className="anim-slide-left" />
        </td>
      </tr>
    ) : dataSources.length === 0 ? (
      <tr>
        <td colSpan={columns.length}>{emptyState}</td>
      </tr>
    ) : (
      dataSources.map((rowContent) => (
        <tr
          key={rowContent.key}
          className={compose(
            styles.tableRowVariants({
              isSelected: Boolean(selectedKeys.find((key) => key === rowContent.key)),
              isError: rowContent.isError,
            }),
          )}
        >
          {enableSelect && (
            <td className={css(styles.selectCell, selectStyles)()}>
              <Checkbox
                aria-label="select-row"
                size="medium"
                wrapperStyles={{ display: 'inline-block' }}
                isSelected={Boolean(selectedKeys.find((key) => key === rowContent.key))}
                onChange={(isSelected) => onKeyChange(rowContent.key, isSelected)}
              />
            </td>
          )}
          {columns.map(({ dataIndex: key, render }) => {
            if (key === 'key') return null;
            return (
              <td
                key={key}
                className={`${hasHorizontalBorder && 'table-cell '} ${styles.tableCell({ isHavePadding: !render })}`}
              >
                {render ? render(rowContent[key], rowContent) : rowContent[key]}
              </td>
            );
          })}
        </tr>
      ))
    );
  };

  useEffect(() => {
    setSelectedKeys(manualSelectedKeys ?? []);
  }, [manualSelectedKeys]);

  return (
    <table className={compose(css(styles.tableWrapper, tableWrapperStyles))}>
      <thead>
        <tr className={compose(css(styles.tableHeader))}>
          {enableSelect && (
            <th className={css(styles.selectCell)()}>
              {selectionMode === 'multiple' && (
                <Checkbox
                  aria-label="select-all"
                  size="medium"
                  wrapperStyles={{ display: 'inline-block' }}
                  isSelected={dataSources.length !== 0 && selectedKeys.length === dataSources.length}
                  isIndeterminate={selectedKeys.length > 0 && selectedKeys.length !== dataSources.length}
                  onChange={onCheckAllChange}
                />
              )}
            </th>
          )}
          {columns.map((column) => (
            <th
              key={column.key}
              className={css(
                styles.tableColumnHeader,
                column.width ? { width: column.width, minWidth: column.width } : {},
                column.marginLeft ? { marginLeft: column.marginLeft } : {},
                column.align ? { textAlign: column.align } : {},
              )()}
            >
              <Text emphasis="semibold" whiteSpace="nowrap" marginLeft={`${column.marginLeft ? column.marginLeft : 0}`}>
                {column.title}
              </Text>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{renderRowContent()}</tbody>
    </table>
  );
};
