import { Checkbox, Col, Input, Row } from 'antd';
import React from 'react';
import { Text, SubTitle } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import { IFormElement } from 'views/form-builder/shared/form-interface';
import { getRules, getValueFromPropertyByKey } from 'views/form-builder/shared/form-builder-utils';
import FormItem from 'antd/es/form/FormItem';
import { FormElementType } from 'views/form-builder/shared/form-enum';

const CheckboxGroup = Checkbox.Group;
interface IMultiChoiceProps {
  options: any[];
  defaultValue: any;
  title: string;
  caption: string;
  isInCreate?: boolean;
  isRequired?: boolean;
}
function MultiChoice({ options, defaultValue, title, caption, isInCreate, isRequired }: IMultiChoiceProps) {
  let newDefault = defaultValue;
  if (newDefault === 'No default') {
    newDefault = '';
  }
  if (!Array.isArray(newDefault)) {
    if (!newDefault) {
      newDefault = [];
    } else {
      newDefault = [newDefault];
    }
  }
  const selectedValues = newDefault || [];

  const fieldTextFormat = (
    <>
      {title}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );

  return (
    <div className="flex-column width-full">
      <SubTitle>{fieldTextFormat}</SubTitle>
      <CheckboxGroup className="pt-small" value={selectedValues}>
        {options.map((val: string) => {
          return (
            <Row type="flex">
              <Col
                span={24}
                className={`p-medium mb-small text-size-x2-large bordered border-secondary rounded
                  ${selectedValues.some((x) => x === val) && !isInCreate ? 'bg-blue-action-lightest' : 'bg-white'} `}
              >
                <Checkbox value={val}>{val}</Checkbox>
              </Col>
            </Row>
          );
        })}
      </CheckboxGroup>
      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
}

export default MultiChoice;

interface IEditMultiChoiceProps extends FormComponentProps {
  value?: string;
  element: IFormElement;
  onChange?: (value) => void;
}

export const EditMultiChoice = ({ value, element, form, onChange }: IEditMultiChoiceProps) => {
  const { id, parentId } = element;
  const options = getValueFromPropertyByKey(element.properties.configuration, 'options');
  const caption = getValueFromPropertyByKey(element.properties.appearance, 'captionText');
  const rules = getRules(element, false);
  const { getFieldDecorator } = form;
  const isRequired = getValueFromPropertyByKey(element.properties.general, 'require');
  let title = getValueFromPropertyByKey(element.properties.general, 'fieldTitle');

  if (isRequired) {
    title = (
      <>
        {title}
        <Text color="red"> *</Text>
      </>
    );
  }

  return (
    <div className="flex-column width-full">
      <SubTitle>{title}</SubTitle>

      <FormItem className="m-none">
        {getFieldDecorator([id, 'value'] as never, {
          initialValue: value,
          rules: rules,
        })(
          <CheckboxGroup className="pt-small width-full">
            {options.map((val: string) => {
              return (
                <Row type="flex">
                  <Col
                    span={24}
                    className={` p-medium mb-small text-size-x2-large bordered border-secondary rounded ${'bg-white'} `}
                  >
                    <Checkbox
                      value={val}
                      onChange={(e) => {
                        if (!onChange) return;
                        const values = form.getFieldValue([id, 'value'] as never);
                        if (e.target.checked) {
                          onChange([...values, val]);
                        } else {
                          onChange(values.filter((elem) => elem !== val));
                        }
                      }}
                    >
                      {val}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
          </CheckboxGroup>,
        )}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'type'] as never, {
          initialValue: FormElementType.MULTI_CHOICE,
        })(<Input type="hidden" />)}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'parentElementId'] as never, {
          initialValue: parentId,
        })(<Input type="hidden" />)}
      </FormItem>
      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
};
