import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Avatar, notification } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import { ShiftClashContent } from 'common-components/shift-clash';
import AvailabilityConflictWorker from 'views/team/details/tabs-panel/availability/components/AvailabilityConflictWorker';
import ConflictCheckUtils from 'utilities/conflict-check-utils';

interface IBulkConfirmShiftSlotsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTaskSuccess: () => void;
  toBeConfirmedShiftSlots: IShiftSlot[];
  serviceId: string;
  serviceDateTimeId: string;
  timezone: timeZone;
  shiftClashConflicts: typeof state.groupServiceStore.shiftClashConflicts;
  shiftsOutsideGeneralAvailability: typeof state.groupServiceStore.outsideAvailabilityShiftSlots;
  doFetchConflictShiftSlots: typeof dispatch.groupServiceStore.doFetchConflictShiftSlots;
  doRemoveShiftSlot: typeof dispatch.groupServiceStore.doRemoveShiftSlot;
  doShiftConfirmSlot: typeof dispatch.groupServiceStore.doShiftConfirmSlot;
}

interface IBulkConfirmShiftSlotsModalState {
  isLoading: boolean;
  isConflict: boolean;
  isOutsideAvailability: boolean;
  step: number;
  ignoreOutsideAvailabilityShifts: any[];
  ignoreShiftClashShiftIds: string[];
  currentShiftsOutsideGeneralAvailability: any[];
}

class BulkConfirmShiftSlotsModal extends Component<IBulkConfirmShiftSlotsModalProps, IBulkConfirmShiftSlotsModalState> {
  state = {
    isLoading: false,
    isConflict: false,
    isOutsideAvailability: false,
    step: 1,
    ignoreOutsideAvailabilityShifts: [],
    ignoreShiftClashShiftIds: [],
    currentShiftsOutsideGeneralAvailability: [],
  };

  private _reset = () => {
    this.setState({
      isLoading: false,
      isConflict: false,
      isOutsideAvailability: false,
      step: 1,
      ignoreOutsideAvailabilityShifts: [],
      ignoreShiftClashShiftIds: [],
      currentShiftsOutsideGeneralAvailability: [],
    });
  };

  private _onSelectShiftClashShifts = (ignoreShiftClashShiftIds: string[]) => {
    const { shiftsOutsideGeneralAvailability, shiftClashConflicts, toBeConfirmedShiftSlots } = this.props;

    const remainedOutsideAvailabilityShifts =
      ConflictCheckUtils.filterOutsideAvailabilityShiftsGroupedByWorkerAfterSelectingShiftClashShifts(
        ignoreShiftClashShiftIds,
        shiftClashConflicts,
        shiftsOutsideGeneralAvailability,
      );

    if (!_.isEmpty(remainedOutsideAvailabilityShifts) && toBeConfirmedShiftSlots.length > 1) {
      this.setState({
        ignoreShiftClashShiftIds,
        step: 3,
        currentShiftsOutsideGeneralAvailability: remainedOutsideAvailabilityShifts,
      });
    } else {
      this._onConfirmShiftSlots(ignoreShiftClashShiftIds);
    }
  };

  private _onConfirmShiftSlots = async (ignoreShiftClashShiftIds: string[] = []) => {
    if (this.state.step === 1) {
      if (this.state.isConflict) {
        this.setState({ step: 2 });
        return;
      }
      if (this.state.isOutsideAvailability && this.props.toBeConfirmedShiftSlots.length > 1) {
        this.setState({ step: 3 });
        return;
      }
    }

    const { toBeConfirmedShiftSlots, serviceId, serviceDateTimeId, doShiftConfirmSlot, onTaskSuccess, onClose } =
      this.props;
    const supportWorkerAttendanceIds = _.map(
      toBeConfirmedShiftSlots,
      (shiftSlot) => shiftSlot.supportWorkerAttendanceId,
    );

    const ignoreUnavailableShiftIds = _.reduce(
      this.state.ignoreOutsideAvailabilityShifts,
      (shiftIds: string[], worker) => {
        shiftIds = shiftIds.concat(_.map(worker, (selectedShift) => selectedShift.supportWorkerAttendanceId));

        return shiftIds;
      },
      [],
    );
    this.setState({ isLoading: true });

    try {
      const result = await doShiftConfirmSlot({
        serviceId,
        serviceDateTimeId,
        supportWorkerAttendanceIds,
        ignoreShiftClashShiftIds: !_.isEmpty(ignoreShiftClashShiftIds)
          ? ignoreShiftClashShiftIds
          : this.state.ignoreShiftClashShiftIds,
        ignoreUnavailableShiftIds,
      });
      onTaskSuccess();
      this._reset();
      onClose();
      if (result && result.haveShiftSlotsConfirmed) {
        notification.success({
          message: 'Shift slot(s) confirmed',
          description: 'You have successfully confirmed the selected shift slot(s)',
        });
      }
    } catch (e) {
      console.error(e);
      notification.error({
        message: <Text weight="bold">Bulk actions failed.</Text>,
        description: (
          <Text className="mt-medium">
            Bulk actions for <b>Confirming Team member</b> complete has encounter an error. Please try again.
          </Text>
        ),
      });
    }
    this.setState({ isLoading: false });
  };

  private _checkConflict = async () => {
    const { serviceId, serviceDateTimeId, toBeConfirmedShiftSlots, doFetchConflictShiftSlots } = this.props;
    const supportWorkerAttendanceIds = _.map(
      toBeConfirmedShiftSlots,
      (shiftSlot) => shiftSlot.supportWorkerAttendanceId,
    );
    this.setState({ isLoading: true });
    await doFetchConflictShiftSlots({ serviceDateTimeId, serviceId, supportWorkerAttendanceIds });
    this.setState({ isLoading: false });
  };

  private _onChangeShiftsToKeep = (shifts, worker) => {
    this.setState({
      ignoreOutsideAvailabilityShifts: {
        ...this.state.ignoreOutsideAvailabilityShifts,
        [worker.supportWorkerId]: shifts,
      },
    });
  };

  private _getModalTitle = () => {
    const { step, isConflict, isOutsideAvailability } = this.state;
    switch (step) {
      case 1:
        return 'Confirm team members';
      case 2:
        return isConflict ? 'Team member assigned to the following shift slot(s)' : 'Team members confirmed';
      case 3:
        return isOutsideAvailability ? 'Team member assigned to the following shift slot(s)' : 'Team members confirmed';
      default:
        return null;
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.isOpen && !prevProps.isOpen) {
      this._reset();
      await this._checkConflict();
    }
    if (this.props.shiftClashConflicts !== prevProps.shiftClashConflicts) {
      this.setState({
        isConflict: this.props.shiftClashConflicts && this.props.shiftClashConflicts.length > 0,
        isOutsideAvailability:
          this.props.shiftsOutsideGeneralAvailability && this.props.shiftsOutsideGeneralAvailability.length > 0,
        currentShiftsOutsideGeneralAvailability: this.props.shiftsOutsideGeneralAvailability,
      });
    }
  };

  render() {
    const { shiftClashConflicts, timezone } = this.props;
    const { isConflict, isOutsideAvailability, step, currentShiftsOutsideGeneralAvailability } = this.state;
    const itemCount = this.props.toBeConfirmedShiftSlots.length;

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={this._getModalTitle()}
        onClose={this.props.onClose}
        canCloseOutside={false}
        width={'large'}
      >
        {step === 1 && (
          <div>
            <Text>
              You are confirming the attendance of{' '}
              <b>
                {itemCount} team member{itemCount === 1 ? '' : 's'}
              </b>{' '}
              on their behalf.
            </Text>
            <SubTitle containerClassName="mt-medium mb-small">Team members being confirmed</SubTitle>
            {_.map(this.props.toBeConfirmedShiftSlots, (shiftSlot) => (
              <div className="flex-row align-center mb-medium">
                <Avatar icon={'user'} shape={'circle'} src={shiftSlot.attachmentUrl} />
                <Text className="ml-small">{shiftSlot.firstName || '' + ' ' + shiftSlot.lastName || ''}</Text>
              </div>
            ))}
            <Text>Are you sure you want to continue?</Text>
            <ActionModalFooter align="right" className="mt-large">
              <SecondaryButton size="large" className="mr-medium" onClick={this.props.onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                onClick={() => this._onConfirmShiftSlots()}
                className="rounded-left"
                loading={this.state.isLoading}
              >
                Confirm team members
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {step === 2 && !isConflict && (
          <div>
            <Text>
              You have confirmed{' '}
              <b>
                {itemCount} team member{itemCount !== 1 && 's'}
              </b>{' '}
              for their shifts.
            </Text>
            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.props.onClose} className="rounded-left">
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {step === 2 && isConflict && (
          <>
            <ShiftClashContent
              shiftClashConflicts={shiftClashConflicts}
              description={
                <Paragraph>
                  This team member is assigned to shifts that conflict with sessions in this recurring series. Choose
                  whether you’d like to keep them in the following bookings.
                </Paragraph>
              }
              firstColumnTitle="Shift slot(s) being assigned"
              okButtonText="Confirm anyway"
              isLoading={this.state.isLoading}
              hasKeepInShift={itemCount > 1}
              onCancel={this.props.onClose}
              onOk={this._onSelectShiftClashShifts}
            />
          </>
        )}

        {step === 3 && isOutsideAvailability && itemCount > 1 && (
          <>
            <Paragraph>
              The new session time conflicts with the team member’s <Text weight="bold">General availability</Text>.
              Please select which shifts (if any) you wish to keep for the team member or continue without selecting
              any.
            </Paragraph>

            <div className="bg-quaternary pv-x-large ph-12 mt-x-large">
              {_.map(currentShiftsOutsideGeneralAvailability, (worker) => (
                <>
                  <AvailabilityConflictWorker
                    worker={worker}
                    timezone={timezone}
                    onSelectShifts={(shifts) => this._onChangeShiftsToKeep(shifts, worker)}
                    type="SHIFT"
                  />
                </>
              ))}
            </div>
            <div className="mt-x3-large flex justify-end">
              <SecondaryButton size="large" onClick={this.props.onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" className="ml-small" onClick={() => this._onConfirmShiftSlots()}>
                Continue and keep in selected shifts
              </PrimaryButton>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  shiftClashConflicts: state.groupServiceStore.shiftClashConflicts,
  shiftsOutsideGeneralAvailability: state.groupServiceStore.outsideAvailabilityShiftSlots,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchConflictShiftSlots: dispatch.groupServiceStore.doFetchConflictShiftSlots,
  doRemoveShiftSlot: dispatch.groupServiceStore.doRemoveShiftSlot,
  doShiftConfirmSlot: dispatch.groupServiceStore.doShiftConfirmSlot,
});

export default connect(mapState, mapDispatch)(BulkConfirmShiftSlotsModal);
