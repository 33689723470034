import React from 'react';
import moment from 'moment';

import Card from 'views/account-management/WorkLeaveTypesConfigurationView/components/Card';
import { FilterItemMenu } from 'common-components/filter';
import { SaveNewFilterValueDateRange } from 'common-components/filter/FilterMenuDateRange';
import { FilterType } from 'utilities/enum-utils';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { useUserTimezone } from 'utilities/react-hooks/use-user-timezone';
import { IFilterDateRange } from 'interfaces/filter-interfaces';
import { Filter } from './filter/filter';
import { DateFilter as DateFilterType } from '../types';
import { allDateFilter } from '../use-filters';

type FilterCardProps = {
  filter: DateFilterType;
  setFilter: React.Dispatch<React.SetStateAction<DateFilterType>>;
  onClose: () => void;
} & DateFilterProps;

const DateFilterCard = ({ filter, setFilter, onClose }: FilterCardProps) => {
  const user = usePortalUser();
  const timezone = useUserTimezone(user);
  const filter_: IFilterDateRange = {
    filter: FilterType.DATE_RANGE,
    values: filter?.startDate && filter?.endDate ? [moment(filter?.startDate), moment(filter?.endDate)] : [],
    selectionLabel: filter.selectionLabel,
  };

  const onSaveNewFilterValue: SaveNewFilterValueDateRange = (_filterType, newFilterValue, selectionLabel) =>
    setFilter({
      startDate: newFilterValue[0]?.toDate(),
      endDate: newFilterValue[1]?.toDate(),
      selectionLabel,
    });

  const onClearFilter = () => {
    setFilter(allDateFilter);
    onClose();
  };

  return (
    <Card>
      <FilterItemMenu
        filter={filter_}
        saveNewFilterValue={onSaveNewFilterValue}
        removeFilter={onClearFilter}
        displayTimezone={timezone}
        onClose={onClose}
        canRemove
      />
    </Card>
  );
};

type DateFilterProps = {
  filter: DateFilterType;
  setFilter: React.Dispatch<React.SetStateAction<DateFilterType>>;
};

export const DateFilter = ({ filter, setFilter }: DateFilterProps): JSX.Element => {
  return (
    <Filter
      label="Date"
      selectedLabel={filter.selectionLabel === 'All' ? undefined : filter.selectionLabel}
      renderCard={(onClose) => <DateFilterCard onClose={onClose} filter={filter} setFilter={setFilter} />}
    />
  );
};
