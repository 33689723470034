const ocean = [
    '#F4F8F9',
    '#E9F1F4',
    '#206E8C',
    '#206E8C',
    '#206E8C',
    '#206E8C',
    '#206E8C',
    '#1A5870',
    '#1A5870',
    '#164D62',
];
const red = [
    '#FBEBEB',
    '#F7D7D7',
    '#D63737',
    '#D63737',
    '#D63737',
    '#D63737',
    '#AB2C2C',
    '#962727',
    '#962727',
    '#802121',
];
const green = [
    '#EBF5F2',
    '#C3E1D9',
    '#369A7F',
    '#369A7F',
    '#369A7F',
    '#369A7F',
    '#2B7B66',
    '#266C59',
    '#266C59',
    '#1B4D40',
];
const gray = [
    '#FAFAF9',
    '#F7F7F6',
    '#EBEAE8',
    '#EBEAE8',
    '#EBEAE8',
    '#CCCAC6',
    '#706F6D',
    '#3D3D3B',
    '#3D3D3B',
    '#3D3D3B',
];
const blue = [
    '#F2F9FF',
    '#E6F2FF',
    '#0080FF',
    '#0080FF',
    '#0080FF',
    '#0080FF',
    '#0073E6',
    '#0066CC',
    '#0066CC',
    '#004D99',
];
const lavender = [
    '#F1F2F8',
    '#EAECF5',
    '#7280BA',
    '#7280BA',
    '#7280BA',
    '#7280BA',
    '#6773A7',
    '#56608C',
    '#56608C',
    '#1A2859',
];
const canary = [
    '#F4F9FB',
    '#E8F3F7',
    '#8DC2D5',
    '#8DC2D5',
    '#8DC2D5',
    '#8DC2D5',
    '#557480',
    '#47616B',
    '#47616B',
    '#384E55',
];
const lilac = [
    '#F7F2FA',
    '#EFE6F5',
    '#AE80CB',
    '#AE80CB',
    '#AE80CB',
    '#AE80CB',
    '#8B66A2',
    '#7A5A8E',
    '#7A5A8E',
    '#574066',
];
const orange = [
    '#FDF1E9',
    '#FBE3D3',
    '#ED7421',
    '#ED7421',
    '#ED7421',
    '#ED7421',
    '#B25719',
    '#9A4B15',
    '#9A4B15',
    '#773A11',
];
export const colors = {
    gray,
    green,
    ocean,
    red,
    blue,
    lavender,
    canary,
    lilac,
    orange,
};
