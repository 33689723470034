import React from 'react';

import { Text } from '@good/ui/core';
import { Collaboration5 } from '@good/illustrations';
import { useTranslation } from 'react-i18next';

export const ActiveEmptyState = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceCustomersTabs' });
  
  return (
    <div className='flex flex-col align-center height-full'>
      <Collaboration5 />
      <Text>{t('activeEmptyHeading')}</Text>
      <Text c='dimmed'> {t('activeEmptyDescription')}.</Text>
    </div>
  );
};
