import { Checkbox, Col, Icon, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import { Text } from 'common-components/typography';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type TmMgmtSearchFilterContentsParams = {
  onUpdateFilter: (event: CheckboxChangeEvent, category: string, filterName: string) => Promise<void>;
};

export const TmMgmtSearchFilterContents = ({ onUpdateFilter }: TmMgmtSearchFilterContentsParams) => {
  const { accountTeamFilter } = useSelector((state: IRootState) => state.teamStore);

  const filter = accountTeamFilter.filter ?? {
    status: { isEnabled: true, isBlocked: true, isDraft: true, isRemoved: true },
    permission: { isOwner: true, hasPortalAccess: true, hasWorkerAppAccess: true },
    billable: { isBillable: true, isNotBillable: true },
  };

  return (
    <Row gutter={24} className="p-large" style={{ width: '600px' }}>
      <Col span={6}>
        <div className="mb-medium">Status</div>
        <Checkbox
          className="m-none mv-x-small"
          onChange={(event) => void onUpdateFilter(event, 'status', 'isEnabled')}
          checked={filter.status.isEnabled}
        >
          <Text className="text-color-green">Enabled</Text>
        </Checkbox>
        <Checkbox
          className="m-none mv-x-small"
          onChange={(event) => void onUpdateFilter(event, 'status', 'isBlocked')}
          checked={filter.status.isBlocked}
        >
          <Text>Blocked</Text>
        </Checkbox>
        <Checkbox
          className="m-none mv-x-small"
          onChange={(event) => void onUpdateFilter(event, 'status', 'isDraft')}
          checked={filter.status.isDraft}
        >
          <Text className="text-color-secondary">Draft</Text>
        </Checkbox>
        <Checkbox
          className="m-none mv-x-small"
          onChange={(event) => void onUpdateFilter(event, 'status', 'isRemoved')}
          checked={filter.status.isRemoved}
        >
          <Text className="text-color-red-dark">Removed</Text>
        </Checkbox>
      </Col>
      <Col span={9}>
        <div className="mb-medium">Permission summary</div>
        <Checkbox
          className="m-none mv-x-small"
          onChange={(event) => void onUpdateFilter(event, 'permission', 'hasPortalAccess')}
          checked={filter.permission.hasPortalAccess}
        >
          Workspace
        </Checkbox>
        <Checkbox
          className="m-none mv-x-small"
          onChange={(event) => void onUpdateFilter(event, 'permission', 'hasWorkerAppAccess')}
          checked={filter.permission.hasWorkerAppAccess}
        >
          Business App
        </Checkbox>
      </Col>
      <Col span={7}>
        <div className="mb-medium">Billable this month</div>
        <Checkbox
          className="m-none mv-x-small"
          onChange={(event) => void onUpdateFilter(event, 'billable', 'isBillable')}
          checked={filter.billable.isBillable}
        >
          <Icon type="check-circle" className="text-color-warning-orange mr-x-small" theme="filled" />
          Yes
        </Checkbox>
        <br />
        <Checkbox
          className="m-none mv-x-small"
          onChange={(event) => void onUpdateFilter(event, 'billable', 'isNotBillable')}
          checked={filter.billable.isNotBillable}
        >
          <Text className="text-color-secondary">No</Text>
        </Checkbox>
      </Col>
    </Row>
  );
};
