import React, { ReactNode } from 'react';
import { ChevronRight } from '@good/icons';
import { Link } from '../link';
import { Text } from '../text';

type BreadcrumbProps = { children: ReactNode };

const CurrentBreadcrumb = ({ children }: BreadcrumbProps) => (
  <li>
    <Text className='text-b text-weak'>{children}</Text>
  </li>
);

const LinkedBreadcrumb = ({ children }: BreadcrumbProps) => (
  <li>
    <Text>
      <Link className='text-b no-underline hover:underline active:underline focus:underline'>{children}</Link>
    </Text>
  </li>
);

export type BreadcrumbsProps = {
  children: ReactNode[];
};

export const Breadcrumbs = ({ children }: BreadcrumbsProps) => (
  <ul className='flex gap-2'>
    {children.map((item, index) => {
      const isLast = index === children.length - 1;
      if (isLast) return <CurrentBreadcrumb key={`breadcrumb-${index}`}>{item}</CurrentBreadcrumb>;

      return (
        <React.Fragment key={`breadcrumb-${index}`}>
          <LinkedBreadcrumb>{item}</LinkedBreadcrumb>
          <span className='text-b-sm text-weak'>
            <ChevronRight />
          </span>
        </React.Fragment>
      );
    })}
  </ul>
);
