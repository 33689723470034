import { forwardRef } from 'react';
import {
  DateInput,
  DateSegment,
  Text,
  TimeField as _TimeField,
  type TimeFieldProps as _TimeFieldProps,
  type TimeValue,
} from 'react-aria-components';

import * as styles from './date-time-field.css';
import type { InputProps, LabelProps } from '../types';
import { FieldLabel } from '../field-label';
import { StatusMessage } from '../status-message';
import { twMerge } from '../utils';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- using interface features
export interface TimeFieldProps<T extends TimeValue> extends _TimeFieldProps<T>, InputProps, LabelProps {}

export const TimeField = forwardRef<HTMLDivElement, TimeFieldProps<TimeValue>>((props, ref) => {
  const {
    description,
    errorMessage,
    hideTimeZone = true,
    hourCycle = 12,
    isRequired,
    label,
    requirementIndicator,
    validationState,
    ...otherProps
  } = props;

  const isDescription = Boolean(description);
  const isErrorMessage = Boolean(errorMessage);
  const isInvalid = validationState === 'invalid';
  const showErrorMessage = isInvalid && isErrorMessage;
  const showDescription = isDescription && !showErrorMessage;
  const { container, input, segment } = styles.dateField({ validationState });

  return (
    <_TimeField className={container()} hideTimeZone={hideTimeZone} hourCycle={hourCycle} {...otherProps} ref={ref}>
      <FieldLabel isRequired={isRequired} requirementIndicator={requirementIndicator}>
        {label}
      </FieldLabel>

      <DateInput className={twMerge(input())}>
        {(dateSegment) => <DateSegment className={segment()} segment={dateSegment} />}
      </DateInput>

      {showDescription && (
        <Text slot="description">
          <StatusMessage tone="neutral">{description}</StatusMessage>
        </Text>
      )}

      {showErrorMessage && (
        <Text slot="errorMessage">
          <StatusMessage tone="critical">{errorMessage}</StatusMessage>
        </Text>
      )}
    </_TimeField>
  );
});

TimeField.displayName = 'TimeField';
