import { Box, Inline, Stack, Text } from '@goodhuman-me/components';
import { notification } from 'antd';
import { Button, RadioGroup } from 'design-components';
import { Pen } from 'design-components/icon';
import { IServiceDepartment } from 'interfaces/account-interfaces';
import { IServiceDepartmentList } from 'interfaces/service-interfaces';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';
import { ServicePermissionType, UserPermissionRole } from 'utilities/enum-utils';
import { PermissionRole } from 'views/team-v2/utils/form-utils';
import ActionSelectedItem from './ActionSelectedItem';
import CustomServiceDepartmentPanel from './CustomServiceDepartmentPanel';

interface IProps {
  serviceDepartment: IServiceDepartmentList;
  type: string;
  setPermissionRolesDepartmentLiteItem: (permissionRole: PermissionRole) => void;
  permissionRolesDepartment: PermissionRole[];
  setPermissionRolesDepartment: (permissionRoles: PermissionRole[]) => void;
  typePermissionPresent: string;
  handleSetTypePermissionPresent: (value: string) => void;
}

const ServiceDepartmentRow: React.FC<IProps> = ({
  serviceDepartment,
  setPermissionRolesDepartmentLiteItem,
  permissionRolesDepartment,
  setPermissionRolesDepartment,
  type,
  typePermissionPresent,
  handleSetTypePermissionPresent,
}) => {
  const dispatch = useDispatch<IRootDispatch>();

  const extendSelectPermissionVisible = type === typePermissionPresent;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serviceDepartmentDetailsLite, setServiceDepartmentDetailsLite] = useState<IServiceDepartment>(null);
  const [selectedPermission, setSelectedPermission] = useState<string>(ServicePermissionType.NO_ACCESS);

  const serviceDepartmentExtensions = {
    [ServicePermissionType.NO_ACCESS]: {
      permissionId: UserPermissionRole.NoAccess,
      label: ServicePermissionType.NO_ACCESS,
    },
    [ServicePermissionType.MEMBER]: {
      permissionId: UserPermissionRole.ServiceMember,
      label: ServicePermissionType.MEMBER,
    },
    [ServicePermissionType.MANAGER]: {
      permissionId: UserPermissionRole.ServiceAdmin,
      label: ServicePermissionType.MANAGER,
    },
    [ServicePermissionType.CUSTOM]: {
      label: 'Custom',
    },
  };

  const getServiceDepartmentDetailsLite = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch.servicesStore.doFetchServiceDepartmentDetailsLite({
        serviceDepartmentId: serviceDepartment.serviceDepartmentId,
      });
      setServiceDepartmentDetailsLite(response.data);
    } catch (error) {
      notification.error({
        message: 'Oops, something went wrong! Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePermission = (selectedPermission: string) => {
    setSelectedPermission(selectedPermission);
    if (selectedPermission === ServicePermissionType.CUSTOM) {
      let newPermissionRolesDepartment = [...permissionRolesDepartment];
      if (newPermissionRolesDepartment.length) {
        newPermissionRolesDepartment = newPermissionRolesDepartment.filter(
          (one) => one.serviceDepartmentId !== serviceDepartment.serviceDepartmentId,
        );
        setPermissionRolesDepartment(newPermissionRolesDepartment);
      }
      if (!serviceDepartmentDetailsLite && selectedPermission) {
        getServiceDepartmentDetailsLite();
      }
    } else {
      setPermissionRolesDepartmentLiteItem({
        serviceDepartmentId: serviceDepartment.serviceDepartmentId,
        permissionRole: serviceDepartmentExtensions[selectedPermission].permissionId,
        serviceId: null,
      });
    }
  };

  useEffect(() => {
    if (permissionRolesDepartment.length > 0) {
      // if have no permission role custom
      const role = permissionRolesDepartment.find(
        (one) => one.serviceDepartmentId === serviceDepartment.serviceDepartmentId && one.serviceId === null,
      );
      if (role) {
        let selectedPermission = ServicePermissionType.NO_ACCESS;
        if (role.permissionRole === UserPermissionRole.ServiceMember) {
          selectedPermission = ServicePermissionType.MEMBER;
        }
        if (role.permissionRole === UserPermissionRole.ServiceAdmin) {
          selectedPermission = ServicePermissionType.MANAGER;
        }
        setSelectedPermission(selectedPermission);
      } else {
        // if have permission role custom
        const isExistCustom = permissionRolesDepartment.some(
          (one) => one.serviceDepartmentId === serviceDepartment.serviceDepartmentId && one.serviceId !== null,
        );
        if (isExistCustom) {
          setSelectedPermission(ServicePermissionType.CUSTOM);
          if (!serviceDepartmentDetailsLite && selectedPermission) {
            getServiceDepartmentDetailsLite();
          }
        }
      }
    }
  }, [permissionRolesDepartment]);

  return (
    <Box>
      <Inline
        alignItems="center"
        justifyContent="space-between"
        paddingLeft="$space400 !important"
        paddingRight="$space125 !important"
      >
        <Stack gap="$space20">
          <Text size="small" color="$black" fontWeight="$semi">
            {serviceDepartment.serviceDepartmentName}
          </Text>
        </Stack>

        <Button
          kind="accent"
          emphasis="quiet"
          size="small"
          onPress={() => handleSetTypePermissionPresent(type)}
          backgroundColor={extendSelectPermissionVisible ? '$accentBackground' : ''}
          color={extendSelectPermissionVisible ? '$accentPressed' : ''}
        >
          <Text size="small">{serviceDepartmentExtensions[selectedPermission].label}</Text>
          <Pen width="$size250 !important" height="$size250 !important" />
        </Button>
      </Inline>

      <Box padding="$space125 $space100">
        {extendSelectPermissionVisible && (
          <>
            <RadioGroup aria-label="team" value={selectedPermission} onChange={(value) => onChangePermission(value)}>
              <ActionSelectedItem
                ariaLabel="no-access"
                value={ServicePermissionType.NO_ACCESS}
                selectedPermission={selectedPermission}
                label="No access, cannot see or access this section"
              />
              <ActionSelectedItem
                ariaLabel="member"
                value={ServicePermissionType.MEMBER}
                selectedPermission={selectedPermission}
                label="Member, can see and perform basic actions for all services within this section"
              />
              <ActionSelectedItem
                ariaLabel="manager"
                value={ServicePermissionType.MANAGER}
                selectedPermission={selectedPermission}
                label="Manager, can see and perform all basic actions in this section"
              />
              <ActionSelectedItem
                ariaLabel="custom"
                value={ServicePermissionType.CUSTOM}
                selectedPermission={selectedPermission}
                label="Custom, assign custom permission on per-service level"
              />
            </RadioGroup>
            {selectedPermission === ServicePermissionType.CUSTOM && (
              <CustomServiceDepartmentPanel
                isLoading={isLoading}
                serviceDepartmentDetailsLite={serviceDepartmentDetailsLite}
                serviceDepartmentId={serviceDepartment.serviceDepartmentId}
                setPermissionRolesDepartmentLiteItem={setPermissionRolesDepartmentLiteItem}
                permissionRolesDepartment={permissionRolesDepartment}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ServiceDepartmentRow;
