import apiClient from 'utilities/api-client';
import { BookingStatus, PaymentStatus } from 'utilities/enum-utils';

export type CustomerCancellationCode = 'NSDT' | 'NSDH' | 'NSDO' | 'NSDF';

export enum EditRecurringMode {
  Current = 1, // current attendance
  CurrentAll, // current attendance and all future attendance from this booking.
  CurrentNext, // current attendance and number of attendance next
  Upcoming, // all attendance that booking status is CONFIRMED, PENDING or ACCEPTED after the current time
  AllWorkerUpcoming, // all attendance for all worker that booking status is CONFIRMED, PENDING or ACCEPTED
  AllWorkerCurrentAll, // all attendance for all worker that is after current booking
  Custom, // all attendance for each worker that is in request {bookingId , supportWorkerId}
}

export interface ICancelBookingRequest {
  bookingId: string;
  cancelBookingIds: string[];
  cancellationReason: CustomerCancellationCode;
  isBusinessCancel: boolean;
  isCharge: boolean;
  isCustomerNoShow: boolean;
  isRecurring: boolean;
  numberOfBookings: number;
  reason: string;
  shiftSlot: {
    isPaidShift: boolean;
    shiftHours: number;
  };
  bookingRequestId?: string;
  editRecurringMode?: EditRecurringMode;
  numberOfBookingsToCharge?: number;
  isEditing?: boolean;
}

export interface ICancelGroupBookingRequest extends ICancelBookingRequest {
  serviceDateTimeId: string;
  serviceId: string;
  cancellationCode: CustomerCancellationCode;
  isChargeCancellationFee: boolean;
  attendanceIds: string[];
}

export interface ICancelBookingResponse {
  status: BookingStatus;
  paymentStatus: PaymentStatus;
  cancellationReason?: string;
  bookingHistories?: any[];
}

export type ICancelGroupBookingResponse = true;

export async function cancelBooking(request: ICancelBookingRequest) {
  const { data: cancelledBookingResponse }: { data: ICancelBookingResponse } = await apiClient.put(
    `/api/portal/bookings/${request.bookingId}/cancel`,
    request,
  );

  return cancelledBookingResponse;
}

export async function cancelGroupBooking(request: ICancelGroupBookingRequest) {
  const cancelledBy = request.isBusinessCancel ? 'business' : 'customer';

  const { data: cancelledGroupBookingResponse }: { data: ICancelGroupBookingResponse } = await apiClient.put(
    `/api/portal/group-services/${request.serviceId}/sessions/${request.serviceDateTimeId}/bookings/${cancelledBy}-cancel`,
    request,
  );

  return cancelledGroupBookingResponse;
}
