import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

import { InvalidAddressResultError } from './invalid-address-result-error';

export async function getPlaceDataByPlaceId(placeId: string) {
  const [firstResult] = await geocodeByPlaceId(placeId);

  if (!firstResult) {
    throw new InvalidAddressResultError('NO_RESULT');
  }

  const latLng = await getLatLng(firstResult);

  return {
    geocoderResult: firstResult,
    latLng,
  };
}
