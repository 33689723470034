import { Avatar, message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { HyperlinkButton } from 'common-components/buttons';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { Component } from 'react';
import firebaseApp from 'stores/firebase-app';

interface UploadAvatarProps {
  style?: any;
  className?: string;
  url: string;
  onFinishUpload: ({ url, urlPath }) => void;
  uploadPath: string;
  uploadButtonText?: string;
  onStartUpload: () => void;
}

class UploadAvatar extends Component<UploadAvatarProps, any> {
  state = {
    loading: false,
    imageUrl: null,
  };

  componentDidMount() {
    const { url } = this.props;
    if (url) {
      this.setState({ imageUrl: url });
    }
  }

  private _getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  private _beforeUpload = (file) => {
    const isJpgOrPng =
      file.type.toLowerCase() === 'image/jpeg' ||
      file.type.toLowerCase() === 'image/jpg' ||
      file.type.toLowerCase() === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG or PNG files.');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('Image must smaller than 10MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  private _handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this._getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };

  private _customUpload = async ({ onError, onSuccess, file }) => {
    const { onFinishUpload, uploadPath, onStartUpload } = this.props;
    try {
      onStartUpload();

      const imageRef = ref(firebaseApp.storage, `${uploadPath}/${Date.now()}.jpg`);
      const uploadFile = uploadBytesResumable(imageRef, file);

      await uploadFile.on(
        'state_changed',
        () => ({}),
        () => ({}),
        () => {
          getDownloadURL(uploadFile.snapshot.ref)
            .then((url) => {
              onFinishUpload({ url: url, urlPath: uploadFile.snapshot.ref.fullPath });
              onSuccess(null, uploadFile);
            })
            .catch((error) => {
              console.log(error);
            });
        },
      );
    } catch (e) {
      onError(e);
    }
  };

  render() {
    const { imageUrl } = this.state;
    const { style, className, uploadButtonText } = this.props;

    return (
      <ImgCrop shape={'round'}>
        <Upload
          name="avatar"
          listType="text"
          style={style}
          className={className}
          showUploadList={false}
          customRequest={this._customUpload}
          beforeUpload={this._beforeUpload}
          onChange={this._handleChange}
        >
          <Avatar src={imageUrl} alt="avatar" size={120} shape={'circle'} className="bordered" />
          {uploadButtonText && (
            <div className="mt-medium">
              <HyperlinkButton>{uploadButtonText}</HyperlinkButton>
            </div>
          )}
        </Upload>
      </ImgCrop>
    );
  }
}

export default UploadAvatar;
