import { Tab, Tabs } from '@blueprintjs/core';
import ActionModal from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import React, { useLayoutEffect, useState, useEffect } from 'react';
import { state } from 'stores/rematch/root-store';
import {
  AvailabilityUnavailabilityRequestModalType,
  AvailabilityUnavailabilityRequestStatus,
  AvailabilityUnavailabilityRequestTabType,
} from 'utilities/enum-utils';
import AvailabilityUnavailabilityRequestTabItem from './tabs/AvailabilityUnavailabilityRequestTabItem';
import moment from 'moment-timezone';

interface IAvailabilityUnavailabilityRequestModalProps {
  type: AvailabilityUnavailabilityRequestModalType;
  memberAvailabilities: typeof state.teamStore.memberAvailabilities;
  onClose: () => void;
}
const AvailabilityUnavailabilityRequestModal = ({
  onClose,
  type,
  memberAvailabilities,
}: IAvailabilityUnavailabilityRequestModalProps) => {
  const title =
    type === AvailabilityUnavailabilityRequestModalType.AVAILABILITY ? 'General Availability' : 'Unavailability time';

  const [selectedTabId, setSelectedTabId] = useState<AvailabilityUnavailabilityRequestTabType>(null);

  const handleTabChange = (tabId: AvailabilityUnavailabilityRequestTabType) => {
    setSelectedTabId(tabId);
  };

  const _sortDateDesc = (firstDate, secondDate) =>
    moment(secondDate.updatedDate).format('YYYYMMDD') - moment(firstDate.updatedDate).format('YYYYMMDD');

  useLayoutEffect(() => {
    const modals = document.getElementsByClassName('bp3-portal');
    if (modals) {
      _.forEach(modals, (element) => element.setAttribute('style', 'z-index:1;'));
    }
  }, []);

  const timeAvailabilityPendingRequest = _.filter(
    memberAvailabilities.timeAvailabilityRequest,
    (item) => item.status === AvailabilityUnavailabilityRequestStatus.PENDING_APPROVAL,
  );

  const timeAvailabilityApprovedRequest = _.filter(
    memberAvailabilities.timeAvailabilityRequest,
    (item) => item.status === AvailabilityUnavailabilityRequestStatus.APPROVED,
  );
  timeAvailabilityApprovedRequest.sort(_sortDateDesc);

  const timeAvailabilityDeclinedRequest = _.filter(
    memberAvailabilities.timeAvailabilityRequest,
    (item) => item.status === AvailabilityUnavailabilityRequestStatus.DECLINED,
  );

  const timeUnavailabilityPendingRequest = _.filter(
    memberAvailabilities.scheduledUnavailabilityRequest,
    (item) =>
      item.status === AvailabilityUnavailabilityRequestStatus.PENDING_APPROVAL ||
      item.status === AvailabilityUnavailabilityRequestStatus.REQUEST_CANCELLATION,
  );

  const timeUnavailabilityCanceledRequest = _.filter(
    memberAvailabilities.scheduledUnavailabilityRequest,
    (item) =>
      item.status === AvailabilityUnavailabilityRequestStatus.CANCELLED ||
      item.status === AvailabilityUnavailabilityRequestStatus.DECLINED,
  );

  const _getDefaultTab = () => {
    if (AvailabilityUnavailabilityRequestModalType.AVAILABILITY === type) {
      if (timeAvailabilityApprovedRequest.length > 0) return AvailabilityUnavailabilityRequestTabType.APPROVED;
      if (timeAvailabilityPendingRequest.length > 0)
        return AvailabilityUnavailabilityRequestTabType.AVAILABILITY_REQUESTS;
      if (timeAvailabilityDeclinedRequest.length > 0) return AvailabilityUnavailabilityRequestTabType.DECLINED;
    } else {
      if (timeUnavailabilityPendingRequest.length > 0)
        return AvailabilityUnavailabilityRequestTabType.UNAVAILABILITY_REQUESTS;
      if (timeUnavailabilityCanceledRequest.length > 0) return AvailabilityUnavailabilityRequestTabType.CANCELLED;
    }
    return null;
  };

  useEffect(() => {
    _getDefaultTab() && _.isNull(selectedTabId) && setSelectedTabId(_getDefaultTab());
  });

  return (
    <>
      <ActionModal isOpen title={title} width="x3-large" onClose={onClose}>
        {type === AvailabilityUnavailabilityRequestModalType.AVAILABILITY && (
          <Tabs
            id={AvailabilityUnavailabilityRequestModalType.AVAILABILITY}
            onChange={handleTabChange}
            selectedTabId={selectedTabId}
          >
            {timeAvailabilityPendingRequest.length !== 0 && (
              <Tab
                id={AvailabilityUnavailabilityRequestTabType.AVAILABILITY_REQUESTS}
                title={
                  <Text
                    size="x-large"
                    className={`${
                      selectedTabId === AvailabilityUnavailabilityRequestTabType.AVAILABILITY_REQUESTS
                        ? 'text-color-blue-action'
                        : 'text-color-secondary'
                    } `}
                  >
                    Requests ({timeAvailabilityPendingRequest.length})
                  </Text>
                }
                panel={
                  <AvailabilityUnavailabilityRequestTabItem
                    onClose={onClose}
                    requestItems={timeAvailabilityPendingRequest}
                    type={type}
                    tabType={AvailabilityUnavailabilityRequestTabType.AVAILABILITY_REQUESTS}
                  />
                }
              />
            )}
            {timeAvailabilityApprovedRequest.length !== 0 && (
              <Tab
                id={AvailabilityUnavailabilityRequestTabType.APPROVED}
                title={
                  <Text
                    size="x-large"
                    className={`${
                      selectedTabId === AvailabilityUnavailabilityRequestTabType.APPROVED
                        ? 'text-color-blue-action'
                        : 'text-color-secondary'
                    } `}
                  >
                    Approved requests ({timeAvailabilityApprovedRequest.length})
                  </Text>
                }
                panel={
                  <AvailabilityUnavailabilityRequestTabItem
                    onClose={onClose}
                    requestItems={timeAvailabilityApprovedRequest}
                    type={type}
                    tabType={AvailabilityUnavailabilityRequestTabType.APPROVED}
                  />
                }
              />
            )}
            {timeAvailabilityDeclinedRequest.length !== 0 && (
              <Tab
                id={AvailabilityUnavailabilityRequestTabType.DECLINED}
                title={
                  <Text
                    size="x-large"
                    className={`${
                      selectedTabId === AvailabilityUnavailabilityRequestTabType.DECLINED
                        ? 'text-color-blue-action'
                        : 'text-color-secondary'
                    } `}
                  >
                    Declined requests ({timeAvailabilityDeclinedRequest.length})
                  </Text>
                }
                panel={
                  <AvailabilityUnavailabilityRequestTabItem
                    onClose={onClose}
                    requestItems={timeAvailabilityDeclinedRequest}
                    type={type}
                    tabType={AvailabilityUnavailabilityRequestTabType.DECLINED}
                  />
                }
              />
            )}
          </Tabs>
        )}

        {type === AvailabilityUnavailabilityRequestModalType.UNAVAILABILITY && (
          <Tabs
            id={AvailabilityUnavailabilityRequestModalType.UNAVAILABILITY}
            onChange={handleTabChange}
            selectedTabId={selectedTabId}
          >
            {timeUnavailabilityPendingRequest.length !== 0 && (
              <Tab
                id={AvailabilityUnavailabilityRequestTabType.UNAVAILABILITY_REQUESTS}
                title={
                  <Text
                    size="x-large"
                    className={`${
                      selectedTabId === AvailabilityUnavailabilityRequestTabType.UNAVAILABILITY_REQUESTS
                        ? 'text-color-blue-action'
                        : 'text-color-secondary'
                    } `}
                  >
                    Requests ({timeUnavailabilityPendingRequest.length})
                  </Text>
                }
                panel={
                  <AvailabilityUnavailabilityRequestTabItem
                    onClose={onClose}
                    requestItems={timeUnavailabilityPendingRequest}
                    type={type}
                    tabType={AvailabilityUnavailabilityRequestTabType.UNAVAILABILITY_REQUESTS}
                  />
                }
              />
            )}
            {timeUnavailabilityCanceledRequest.length !== 0 && (
              <Tab
                id={AvailabilityUnavailabilityRequestTabType.CANCELLED}
                title={
                  <Text
                    size="x-large"
                    className={`${
                      selectedTabId === AvailabilityUnavailabilityRequestTabType.CANCELLED
                        ? 'text-color-blue-action'
                        : 'text-color-secondary'
                    } `}
                  >
                    Cancelled requests ({timeUnavailabilityCanceledRequest.length})
                  </Text>
                }
                panel={
                  <AvailabilityUnavailabilityRequestTabItem
                    onClose={onClose}
                    requestItems={timeUnavailabilityCanceledRequest}
                    type={type}
                    tabType={AvailabilityUnavailabilityRequestTabType.CANCELLED}
                  />
                }
              />
            )}
          </Tabs>
        )}
      </ActionModal>
    </>
  );
};

export default AvailabilityUnavailabilityRequestModal;
