import React, { Component } from 'react';
import { Col, Form, Row, TimePicker, Icon } from 'antd';
import { Text } from 'common-components/typography';
import moment from 'moment-timezone';
import { FormComponentProps } from 'antd/es/form';
import { Select } from 'antd';
import _ from 'lodash';
interface IUnavailableTimeItemProps extends FormComponentProps {
  day: any;
  data: any;
  onChangeAvailableTime: (value) => void;
}

const { Option } = Select;
class UnavailableTimeItem extends Component<IUnavailableTimeItemProps, any> {
  private _onChangeAvailableType = (value) => {
    const { data, onChangeAvailableTime } = this.props;
    const newData = { ...data };
    if (value === 'unavailable-all-day') {
      newData.timeRange = [];
      newData.isAvailableAllDay = false;
    } else if (value === 'available-all-day') {
      newData.timeRange = [];
      newData.isAvailableAllDay = true;
    } else {
      newData.isAvailableAllDay = false;
      newData.timeRange = [
        {
          startTime: moment(9, 'hh').set('weekday', newData.number),
          endTime: moment(17, 'hh').set('weekday', newData.number),
        },
      ];
    }

    onChangeAvailableTime(newData);
  };

  private _onChangeAvailableTime = (value, type, index) => {
    const { data, onChangeAvailableTime } = this.props;
    const newData = { ...data };
    const momentTime = moment(value);

    if (type === 'startTime') {
      const endDateTime = moment(newData.timeRange[index].endTime);

      if (momentTime.isSameOrAfter(endDateTime)) {
        newData.timeRange[index].error = 'earlier';
      } else if (this._validateOverlappingTimeBlock(momentTime, endDateTime, newData.timeRange, index)) {
        newData.timeRange[index].error = 'overlaps';
      } else {
        newData.timeRange[index].error = null;
      }

      newData.timeRange[index].startTime = momentTime;
    } else {
      const startDateTime = moment(newData.timeRange[index].startTime);

      const timeString = momentTime.format('hh:mm a');
      const endOfDayTime = moment(momentTime).set('weekday', newData.number).endOf('days');
      const endOfDayTimeString = endOfDayTime.add(1, 'seconds').format('hh:mm a');

      if (momentTime.isSameOrBefore(startDateTime) && !(timeString === endOfDayTimeString)) {
        newData.timeRange[index].error = 'earlier';
      } else if (this._validateOverlappingTimeBlock(startDateTime, momentTime, newData.timeRange, index)) {
        newData.timeRange[index].error = 'overlaps';
      } else {
        newData.timeRange[index].error = null;
      }

      if (timeString === endOfDayTimeString) {
        newData.timeRange[index].endTime = endOfDayTime.add(1, 'seconds');
      } else {
        newData.timeRange[index].endTime = momentTime;
      }

      newData.timeRange[index].endTime = momentTime;
    }
    onChangeAvailableTime(newData);
  };

  private _validateOverlappingTimeBlock = (startTime, endTime, timeRanges, currentIndex) => {
    const timeRangesExcludeCurrentIdx = _.filter(timeRanges, (_, index) => {
      return index !== currentIndex;
    });

    const conflictingTime = _.find(timeRangesExcludeCurrentIdx, (timeRange) => {
      const existingStartTime = moment(timeRange.startTime);
      const existingEndTime = moment(timeRange.endTime);

      const startTimeInBetween = moment(startTime).isBetween(existingStartTime, existingEndTime);
      const endTimeInBetween = moment(endTime).isBetween(existingStartTime, existingEndTime);

      const startTimeOverlap = startTimeInBetween;
      const endTimeOverlap = endTimeInBetween;

      const existingStartTimeInBetween = moment(existingStartTime).isBetween(startTime, endTime);
      const existingEndTimeInBetween = moment(existingEndTime).isBetween(startTime, endTime);
      const existingStartTimeOverlap = existingStartTimeInBetween;
      const existingEndTimeOverlap = existingEndTimeInBetween;

      const duplicateTime = moment(existingStartTime).isSame(startTime) && moment(existingEndTime).isSame(endTime);

      return startTimeOverlap || endTimeOverlap || existingStartTimeOverlap || existingEndTimeOverlap || duplicateTime;
    });

    return conflictingTime;
  };

  private _addNewAvailableTime = () => {
    const { data, onChangeAvailableTime } = this.props;
    const newData = { ...data };
    const currentLastTime = data.timeRange[data.timeRange.length - 1];
    const newTimeRange = [...data.timeRange];

    const endDayTime = moment(currentLastTime.startTime).set('weekday', newData.number).endOf('days');

    if (endDayTime.diff(moment(currentLastTime.endTime)) > 0) {
      const newStartTime = moment(currentLastTime.endTime).add(1, 'hours');
      const newEndTime = moment(currentLastTime.endTime).add(2, 'hours');

      let newAvailableTime = {
        startTime: newStartTime,
        endTime: newEndTime,
        error: null,
      };

      if (endDayTime.diff(newStartTime) <= 0) {
        newAvailableTime.startTime = endDayTime.add(1, 'seconds');
      }

      if (endDayTime.diff(newEndTime) <= 0) {
        newAvailableTime.endTime = endDayTime.add(1, 'seconds');
      }

      if (newAvailableTime.startTime.diff(newAvailableTime.endTime) === 0) {
        newAvailableTime.error = 'overlaps';
      }

      newTimeRange.push(newAvailableTime);
      newData.timeRange = newTimeRange;
      onChangeAvailableTime(newData);
    } else if (endDayTime.add(1, 'seconds').diff(moment(currentLastTime.endTime)) === 0) {
      let newAvailableTime = {
        startTime: endDayTime.add(1, 'seconds'),
        endTime: endDayTime.add(1, 'seconds'),
        error: 'earlier',
      };
      newTimeRange.push(newAvailableTime);
      newData.timeRange = newTimeRange;
      onChangeAvailableTime(newData);
    }
  };

  private _removeAvailableTime = (index) => {
    const { data, onChangeAvailableTime } = this.props;
    const newData = { ...data };
    const newTimeRange = _.filter(data.timeRange, (_, idx) => idx !== index);
    const currentErrorIdxs = _.chain(newTimeRange)
      .map((timeRange, index) => ({ ...timeRange, index }))
      .filter((timeRange) => timeRange.error)
      .map((timeRange) => timeRange.index)
      .value();

    _.forEach(currentErrorIdxs, (idx) => {
      const error = this._validateOverlappingTimeBlock(
        newTimeRange[idx].startTime,
        newTimeRange[idx].endTime,
        newTimeRange,
        idx,
      );

      const earlierError = newTimeRange[idx].error === 'earlier';

      if (!earlierError && !error) {
        newTimeRange[idx].error = null;
      }
    });

    newData.timeRange = newTimeRange;
    onChangeAvailableTime(newData);
  };

  private _renderValidateError = (error) => {
    switch (error) {
      case 'overlaps':
        return 'This time frame overlaps with a previous entry.';
      case 'earlier':
        return 'End time is earlier than start time.';
      default:
        return '';
    }
  };

  private _errorClassName = (type, error) => {
    if (error) {
      switch (error) {
        case 'earlier':
          return type === 'startTime' ? '' : ' bordered border-red rounded';
        default:
          return ' bordered border-red rounded';
      }
    }

    return '';
  };

  private _valueSelector = () => {
    let { data } = this.props;
    if (data.isInRange) {
      return data.isAvailableAllDay
        ? 'available-all-day'
        : data.timeRange.length > 0
        ? 'specific-hours'
        : 'unavailable-all-day';
    } else {
      return 'n/a';
    }
  };

  render() {
    let { day, data } = this.props;

    return (
      <Row
        type="flex"
        align={data.timeRange.length > 1 ? 'top' : 'middle'}
        gutter={24}
        className="bordered-bottom pv-medium"
      >
        <Col span={4}>
          <Text weight="bold">{day}</Text>
        </Col>
        <Col span={8}>
          <Select
            className="width-full"
            size="large"
            defaultValue="unavailable-all-day"
            suffixIcon={<Icon type="caret-down" className="ml-small mr-small text-size-small text-color-secondary" />}
            onChange={this._onChangeAvailableType}
            value={this._valueSelector()}
            disabled={!data.isInRange}
          >
            <Option value="specific-hours">
              <Text className="text-color-orange-dark">Unavailable specific hours</Text>
            </Option>
            <Option value="available-all-day">
              <Text className="text-color-green">Available 24 hours</Text>
            </Option>
            <Option value="unavailable-all-day">
              <Text className="text-color-red">Unavailable all day</Text>
            </Option>
          </Select>
        </Col>
        {!data.isAvailableAllDay && (
          <Col span={12}>
            {data.timeRange.length > 0 &&
              _.map(data.timeRange, (time, index) => (
                <div className={data.timeRange.length > 1 ? 'mb-small' : ''}>
                  <div className="flex align-center">
                    <TimePicker
                      size="large"
                      format="hh:mm a"
                      allowClear={false}
                      use12Hours={true}
                      minuteStep={15}
                      value={time.startTime}
                      onChange={(time) => {
                        this._onChangeAvailableTime(time, 'startTime', index);
                      }}
                      className={this._errorClassName('startTime', time.error)}
                    />
                    <Text className="text-color-secondary mh-small">to</Text>
                    <TimePicker
                      size="large"
                      allowClear={false}
                      minuteStep={15}
                      format="hh:mm a"
                      use12Hours={true}
                      value={time.endTime}
                      onChange={(time) => {
                        this._onChangeAvailableTime(time, 'endTime', index);
                      }}
                      className={this._errorClassName('endTime', time.error)}
                    />
                    <div className="flex ml-small" style={{ width: '55px' }}>
                      <Icon
                        className="text-color-red text-size-regular cursor-pointer"
                        type="minus-circle"
                        onClick={() => {
                          this._removeAvailableTime(index);
                        }}
                      />
                      {index + 1 === data.timeRange.length && (
                        <Icon
                          className="text-color-blue-action text-size-regular cursor-pointer ml-large"
                          type="plus-circle"
                          onClick={this._addNewAvailableTime}
                        />
                      )}
                    </div>
                  </div>

                  {time.error && (
                    <div className="mt-x-small ml-small">
                      <Icon type="exclamation-circle" theme="filled" className="text-size-regular text-color-red" />
                      <Text className="text-color-red ml-x-small">{this._renderValidateError(time.error)}</Text>
                    </div>
                  )}
                </div>
              ))}
          </Col>
        )}
      </Row>
    );
  }
}

export default Form.create<IUnavailableTimeItemProps>()(UnavailableTimeItem);
