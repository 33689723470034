import { Avatar } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { Title, Text, Paragraph } from 'common-components/typography';
import { state } from 'stores/rematch/root-store';
import React from 'react';

type ICompanyDetailsCompanyOwnerProps = {
  onOpenModal: (string) => void;
  companyData: typeof state.companyStore.companyData;
  assignPrimaryOwner: string;
};

export const CompanyDetailsCompanyOwner = ({
  onOpenModal,
  companyData,
  assignPrimaryOwner,
}: ICompanyDetailsCompanyOwnerProps) => (
  <div className="mt-x-large">
    <Title level={4}>Primary Owner</Title>
    <div className="bordered border-standard-gray p-medium mv-large inline-block" style={{ width: '500px' }}>
      <div className="align-center flex-row">
        <Avatar size="large" shape="square" src={companyData.attachmentUrl} className="mr-medium" />
        <div>
          <Text>
            {companyData.firstName} {companyData.lastName}
          </Text>
          <br />
          <Text color="secondary">
            {companyData?.userLocality ?? 'No location set'} - {companyData?.userEmail ?? 'No email set'}
          </Text>
        </div>
      </div>
      <div className="mt-small">
        <HyperlinkButton onClick={() => onOpenModal(assignPrimaryOwner)}>Assign new Primary Owner</HyperlinkButton>
      </div>
    </div>
    <Paragraph>
      <b>You</b> are the Primary Owner of this GoodHuman instance.
    </Paragraph>
    <Paragraph>
      There is only ever one primary owner per GoodHuman instance.
      <br />
      As the Primary Owner you can make changes to company details or relinquish this role by assigning a new primary
      owner.
    </Paragraph>
    <Paragraph>
      Visit the <HyperlinkButton>Help Center</HyperlinkButton> to learn more.
    </Paragraph>
  </div>
);
