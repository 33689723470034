import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Repeat = forwardRef(function Repeat(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.53 1.47a.75.75 0 0 0-1.06 1.06l1.72 1.72H9.454c-1.135 0-2.05 0-2.779.082-.754.085-1.403.266-1.959.693-.26.2-.493.432-.692.692-.427.556-.608 1.205-.693 1.96-.082.729-.082 1.643-.082 2.778V11a.75.75 0 0 0 1.5 0v-.5c0-1.19.001-2.02.073-2.656.07-.62.199-.962.392-1.214.12-.156.26-.295.415-.415.252-.193.594-.322 1.214-.392C7.48 5.75 8.31 5.75 9.5 5.75h8.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3a.75.75 0 0 0 0-1.06l-3-3Zm3.22 12.03V13a.75.75 0 0 0-1.5 0v.5c0 1.19-.001 2.02-.073 2.656-.07.62-.199.962-.392 1.214a2.25 2.25 0 0 1-.415.415c-.252.193-.594.322-1.214.392-.636.072-1.466.073-2.656.073H5.81l1.72-1.72a.75.75 0 1 0-1.06-1.06l-3 3a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06-1.06l-1.72-1.72h8.735c1.135 0 2.05 0 2.779-.082.754-.085 1.403-.266 1.959-.693.26-.2.493-.432.692-.692.427-.556.608-1.205.693-1.96.082-.729.082-1.643.082-2.778V13.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
