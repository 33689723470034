import React, { useCallback, useEffect, useState } from 'react';
import { Col, Empty, Input, Row, Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import * as H from 'history';
import { connect } from 'react-redux';
import FilterSection from 'common-components/filter/FilterSection';
import { BookingType, FilterType } from '../../../../../utilities/enum-utils';
import CommonUtils from '../../../../../utilities/common-utils';
import moment from 'moment-timezone';
import CustomerAttachmentItem from './CustomerAttachmentItem';
import EditDocumentModal from 'common-components/documents/EditDocumentModal';
import DeleteDocumentModal from '../../../../../common-components/documents/DeleteDocumentModal';
import AddEditNoteModal from '../../../../../common-components/notes/AddEditNoteModal';
import DeleteNoteModal from '../../../../../common-components/notes/DeleteNoteModal';
import { InfiniteScroll } from 'components';

interface IBookingAttachmentsSubTabProps {
  customerUserId: string;
  doFetchCustomerBookingAttachments: typeof dispatch.customersStore.doFetchCustomerBookingAttachments;
  doFetchMoreCustomerBookingAttachments: typeof dispatch.customersStore.doFetchMoreCustomerBookingAttachments;
  customerBookingAttachments: typeof state.customersStore.customerBookingAttachments;
  history: H.History;
  portalUser: typeof state.authStore.portalUser;
}

const DocumentEmptyPanel = () => (
  <div className='flex-1 bg-white align-center flex-column'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No document found.
    </Text>
    <Text color='secondary'>All documents under this filter will appear here.</Text>
  </div>
);

const pageSize = 50;

const BookingAttachmentsSubTab: React.FC<IBookingAttachmentsSubTabProps> = ({
  customerUserId,
  doFetchCustomerBookingAttachments,
  doFetchMoreCustomerBookingAttachments,
  customerBookingAttachments,
  history,
  portalUser,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeFilters, setActiveFilters] = useState([
    {
      filter: FilterType.SERVICE,
      values: [],
      selectionLabel: CommonUtils.getFilterSettings(FilterType.SERVICE).fullSelectionName,
    },
    {
      filter: FilterType.NOTE_AUTHOR,
      values: [],
      selectionLabel: CommonUtils.getFilterSettings(FilterType.NOTE_AUTHOR).fullSelectionName,
    },
    {
      filter: FilterType.DATE_RANGE,
      values: [],
      selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName,
    },
  ]);
  const [searchString, setSearchString] = useState<string>('');

  // We have 4 different modals, 2 for editing, 2 for deleting (distinction between booking attachments & notes).
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditNoteModalOpen, setIsEditNoteModalOpen] = useState<boolean>(false);
  const [isDeleteNoteModalOpen, setIsDeleteNoteModalOpen] = useState<boolean>(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const fetchDocuments = useCallback(
    async (reduxStoreFunction) =>
      reduxStoreFunction &&
      (await reduxStoreFunction({
        userId: customerUserId,
        searchString,
        currentPage,
        pageSize,
        startDate,
      })),
    [],
  );

  const resetDocumentList = useCallback(async () => {
    setIsLoading(true);
    setCurrentPage(1);
    setStartDate(new Date());
    await fetchDocuments(doFetchCustomerBookingAttachments);
    setIsLoading(false);
  }, []);

  const fetchMoreDocuments = useCallback(async () => {
    setCurrentPage(currentPage + 1);
    await fetchDocuments(doFetchMoreCustomerBookingAttachments);
  }, []);

  // Reset the document list on load.
  useEffect(() => {
    resetDocumentList().catch(console.error);
  }, []);

  const onChangeFilter = (filters) => setActiveFilters(filters);
  const onChangeSearchString = (event) => setSearchString(event.target.value);

  const onOpenEditModal = (attachment) => {
    setSelectedAttachment({ ...attachment, isViewableOnApp: false });
    setIsEditModalOpen(true);
  };

  const onOpenDeleteModal = (attachment) => {
    setSelectedAttachment(attachment);
    setIsDeleteModalOpen(true);
  };

  const onOpenEditNoteModal = (attachment) => {
    setSelectedAttachment(attachment);
    setIsEditNoteModalOpen(true);
  };

  const onOpenDeleteNoteModal = (attachment) => {
    setSelectedAttachment(attachment);
    setIsDeleteNoteModalOpen(true);
  };

  const onCloseEditModal = () => setIsEditModalOpen(false);
  const onCloseDeleteModal = () => setIsDeleteModalOpen(false);
  const onCloseEditNoteModal = () => setIsEditNoteModalOpen(false);
  const onCloseDeleteNoteModal = () => setIsDeleteNoteModalOpen(false);

  // Filtering function to be used in Array.prototype.filter
  // Will take in a single item from the list of attachments, and return a Boolean of if it should be included
  const filterCustomerBookingAttachment = (attachment) => {
    const serviceFilter = activeFilters.find((filter) => filter.filter === FilterType.SERVICE);
    const authorFilter = activeFilters.find((filter) => filter.filter === FilterType.NOTE_AUTHOR);
    const dateRangeFilter = activeFilters.find((filter) => filter.filter === FilterType.DATE_RANGE);

    // Values for the date range filter.
    const timezone = portalUser.timezone;
    const attachmentDate = moment.tz(attachment.createdOn, timezone);

    // If the service filter is non-empty, and the attachment isn't in it, it doesn't get included.
    if (serviceFilter.values.length && !serviceFilter.values.includes(attachment.serviceId)) {
      return false;
    }

    // Same for the author filter.
    if (authorFilter.values.length && !authorFilter.values.includes(attachment.addedBy)) {
      return false;
    }

    // If we have no date range filter, then it's fine from here.
    if (!dateRangeFilter.values.length) {
      return true;
    }

    // If the attachment date is before the lower bound or after the upper bound, it isn't within the filter.
    if (attachmentDate.isBefore(moment.tz(dateRangeFilter.values[0], timezone))) {
      return false;
    }

    if (attachmentDate.isAfter(moment.tz(dateRangeFilter.values[1], timezone))) {
      return false;
    }

    // If the attachment passed through all 3 filters, then it is valid
    return true;
  };

  // Filter to only the attachments the user has permission to see, and fit within the users filters
  const filteredBookingAttachments = customerBookingAttachments
    .filter((attachment) => attachment.hasPermissionToView)
    .filter((attachment) => {
      // If we aren't given any search string, then let anything through.
      if (!searchString) {
        return true;
      }

      // The search text checks if it's a substring within either the attachment description or the name
      const isInName = attachment.documentName.toLowerCase().includes(searchString.toLowerCase());
      const isInDescription = attachment.description?.toLowerCase().includes(searchString.toLowerCase());

      return isInName || isInDescription;
    })
    .filter(filterCustomerBookingAttachment);

  return (
    <>
      <EditDocumentModal
        closeEditDocumentModal={onCloseEditModal}
        isOpen={isEditModalOpen}
        selectedDocument={selectedAttachment}
        selectedItemId={selectedAttachment?.attendanceId}
        itemType={'booking'}
        serviceProviderTimezone={portalUser.timezone}
        resetDocumentList={resetDocumentList}
        useAttachmentText={true}
      />
      <DeleteDocumentModal
        closeDeleteDocumentModal={onCloseDeleteModal}
        isOpen={isDeleteModalOpen}
        selectedDocument={selectedAttachment}
        selectedItemId={selectedAttachment?.attendanceId}
        itemType={'booking'}
        resetDocumentList={resetDocumentList}
        useAttachmentText={true}
      />
      <AddEditNoteModal
        bookingId={selectedAttachment?.noteAttendanceId}
        isOpen={isEditNoteModalOpen}
        onClose={onCloseEditNoteModal}
        editNote={true}
        editingNote={{
          ...selectedAttachment,
          body: selectedAttachment?.noteBody,
          attendanceId: selectedAttachment?.noteAttendanceId,
        }}
        noteType={BookingType.BOOKING}
        noteVisibleOptions={[]}
        customerUserId={customerUserId}
        resetDocumentList={resetDocumentList}
      />
      <DeleteNoteModal
        isOpen={isDeleteNoteModalOpen}
        noteItem={selectedAttachment}
        doCloseDeleteNoteModal={onCloseDeleteNoteModal}
        customerUserId={customerUserId}
        resetDocumentList={resetDocumentList}
      />
      <Row type='flex' justify='space-between' align='middle' className='mb-large'>
        <Col>
          <Title level={4} className='m-none'>
            Booking attachments
          </Title>
          <Text type='secondary'>Booking attachments related to this customer and their bookings</Text>
        </Col>
      </Row>
      <Row type='flex' justify='space-between' align='bottom' className='mb-large'>
        <Col className='width-1/3'>
          {/* Search box component, this is often renamed to 'Search' in the import. */}
          <Input placeholder='Search for an attachment' onChange={onChangeSearchString} allowClear />
        </Col>
      </Row>
      <Col>
        <FilterSection
          availableFilters={[FilterType.SERVICE, FilterType.NOTE_AUTHOR, FilterType.DATE_RANGE]}
          filters={activeFilters}
          onChangeFilter={onChangeFilter}
          displayTimezone={portalUser.timezone}
          displayMoreFilter={false}
        />
      </Col>
      <InfiniteScroll
        hasMore={customerBookingAttachments.length >= currentPage * pageSize}
        loadMore={fetchMoreDocuments}
        customLoader={
          <Col>
            <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className='anim-slide-left' />
          </Col>
        }
      >
        {isLoading ? (
          <Row>
            <Col>
              <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className='anim-slide-left' />
            </Col>
          </Row>
        ) : _.isEmpty(filteredBookingAttachments) ? (
          <DocumentEmptyPanel />
        ) : (
          filteredBookingAttachments.map((attachment) => {
            return (
              <CustomerAttachmentItem
                key={attachment.documentId}
                attachment={attachment}
                history={history}
                onEditAttachment={() => onOpenEditModal(attachment)}
                onDeleteAttachment={() => onOpenDeleteModal(attachment)}
                onEditNote={() => onOpenEditNoteModal(attachment)}
                onDeleteNote={() => onOpenDeleteNoteModal(attachment)}
              />
            );
          })
        )}
      </InfiniteScroll>
    </>
  );
};

const mapState = (state: IRootState) => ({
  companyDataLite: state.companyStore.companyDataLite,
  customerBookingAttachments: state.customersStore.customerBookingAttachments,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCustomerBookingAttachments: dispatch.customersStore.doFetchCustomerBookingAttachments,
  doFetchMoreCustomerBookingAttachments: dispatch.customersStore.doFetchMoreCustomerBookingAttachments,
});
export default connect(mapState, mapDispatch)(BookingAttachmentsSubTab);
