import { Heading } from '@good/components';
import { AriaOverlayProps, OverlayContainer } from '@react-aria/overlays';
import type { AriaDialogProps } from '@react-types/dialog';
import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { GhostButton } from '../../common-components/buttons';

export type FlyoutProperties = PropsWithChildren<
  AriaOverlayProps &
    AriaDialogProps & {
      heading: string | React.ReactNode;
      isOpen: boolean;
      onClose: () => void;
      footer?: React.ReactNode;
      className?: string;
    }
>;

/**
 * @name
 * Flyout
 *
 * @description
 * The <Flyout> element is an absolutely positioned container which slides in from the side of the viewport.
 * It is intended to be used as a menu or tab for additional information or functionality.
 *
 * @param heading The header text or JSX.
 * @param isOpen Whether the Flyout component is open or collapsed to the side.
 * @param onClose A function which is called whenever the Flyout attempts to close.
 * @param footer A footer element to render at the bottom of the Flyout on top of the content.
 * @param children Child JSX elements to be rendered inside the Flyout underneath the Heading.
 * @param props Additional props described in the FlyoutProps type, extra styling, ARIA dialog, etc.
 *
 * @example
 * // Flyout with content and basic string header
 * <Flyout header="Cool header" isOpen={isOpen} onClose={() => setIsOpen(false)}>
 *   <Card>
 *     I'm menu content :)
 *   </Card>
 * </Flyout>
 *
 * @example
 * // Flyout with no content and JSX header
 * <Flyout isOpen={isOpen} onClose={() => setIsOpen(false)} header={<><Heading>Cool!</Heading><Tooltip /></>} />
 */
export const Flyout: React.FC<FlyoutProperties> = ({ heading, isOpen, onClose, footer, children, className }) => {
  // To handle external events, we need to keep a reference to ourselves as a DOM element
  const outsideClickReference = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // If a mousedown event's target is not contained in ourselves, then it was outside.
    // If it was outside, then attempt to close the Flyout.
    const handleClickOutside = (event: MouseEvent) => {
      return !outsideClickReference.current?.contains(event.target as Node) && onClose();
    };

    // Also handle pressing 'esc' to close the Flyout.
    const handleKeyDown = (event: KeyboardEvent) => {
      return event.key === 'Escape' && onClose();
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    // Remove the event listeners on destruction (React syntax is a bit wonky here, but this is right).
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose, outsideClickReference]);

  const containerRight = isOpen ? 'right-0' : 'right-[-100%]';

  return (
    <OverlayContainer>
      <div className='relative h-full w-full overflow-hidden'>
        <div
          ref={outsideClickReference}
          role='dialog'
          className={`${className} ${containerRight} fixed inset-y-0 z-10 w-[480px] overflow-hidden bg-white py-12 drop-shadow-lg transition-all duration-300 ease-in-out`}
        >
          <div className='flex h-full flex-col justify-between'>
            <div className='flex flex-row justify-between px-8'>
              <Heading size='large'>{heading}</Heading>
              <GhostButton icon='close' color='black' onClick={onClose} />
            </div>
            <div className='flex grow flex-col overflow-y-auto px-8 py-6'>{children}</div>
            {footer && <div className='flex flex-row justify-between px-8'>{footer}</div>}
          </div>
        </div>
      </div>
    </OverlayContainer>
  );
};
