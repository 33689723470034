import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 as Popover } from '@blueprintjs/popover2';
import { Checkbox, Icon } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { PaymentMethodTag } from 'common-components/tags';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { CLAIM_TYPE_STANDARD_OPTIONS } from 'views/billings/utils/constants';
import CommonUtils from 'utilities/common-utils';
import { PaymentSources, ServiceType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import PaymentErrorIndicator from '../PaymentErrorIndicator';
import { ReactComponent as NonBillableIcon } from 'assets/Icons/billing-icons/non-billable_icon.svg';
import { CurrencyFormatter } from '@goodhuman-me/components';
import { DateDisplay } from './DateDisplay';

interface IPaymentItemRow {
  lineItem?: any;
  customerId?: any;
  history?: any;
  openActionModal?: any;
  errorTypes?: string[];
  setSelectedBillingLineItem?: typeof dispatch.billingsStore.setSelectedBillingLineItem;
  onCheckItem?: (bookingBillingLineItemId) => void;
  isLast?: boolean;
  portalUser: typeof state.authStore.portalUser;
  canProcess: boolean;
  warningTypes?: string[];
  isNonBillable: boolean;
  changeBillableLineItem?: (lineItem) => void;
}

const unit = {
  HOURS: 'H',
  EACH: 'EA',
};

const unitOptions = [
  { value: unit.HOURS, label: 'hours' },
  { value: unit.EACH, label: 'each' },
];

class PaymentItemRow extends Component<IPaymentItemRow> {
  _checkbox = null;
  state = {
    bookingBillingLineItemId: null,
    showSubLines: false,
    openAction: false,
    attendanceId: null,
  };

  _onClickBooking = () => {
    const { history } = this.props;
    history.push(`/bookings/details/${this.state.attendanceId}`);
  };

  _onClickCustomer = () => {
    const { history } = this.props;
    history.push(`/customer/details/${this.props.customerId}`);
  };

  _onCheckItem = (e, isError: boolean) => {
    e.stopPropagation();
    e.preventDefault();
    const { onCheckItem } = this.props;

    // cant select if error
    if (isError) return;

    onCheckItem(this.state.bookingBillingLineItemId);
  };

  componentDidMount = () => {
    const { lineItem } = this.props;

    if (!_.isEmpty(lineItem)) {
      const { bookingBillingLineItemId, attendanceId } = lineItem;
      this.setState({ bookingBillingLineItemId, attendanceId });
    }
  };

  render() {
    const {
      lineItem,
      isLast,
      openActionModal,
      portalUser,
      errorTypes,
      warningTypes,
      isNonBillable,
      changeBillableLineItem,
    } = this.props;

    if (_.isEmpty(lineItem)) {
      return <></>;
    }

    const {
      selected,
      paymentMethod,
      supportItemNumber,
      unitPrice,
      claimType,
      unit,
      isError,
      isNoNDISNumberError,
      qty,
      startDateTime,
      paymentSourceType,
      supportItemName,
      serviceType,
      isIncludeGst,
      gstValue,
      billingTotalWithDiscount,
    } = lineItem;
    return (
      <>
        <tr className={`subLines-payments ${!isLast ? 'no-border' : ''}`}>
          <td
            role="gridcell"
            tabIndex={0}
            className="fluid-cell check-all"
            onClick={(e) => this._onCheckItem(e, isError || isNoNDISNumberError)}
            onMouseEnter={() => this._checkbox.focus()}
            onMouseLeave={() => this._checkbox.blur()}
          >
            {this.props.canProcess && (
              <Checkbox
                checked={selected}
                disabled={isError || isNoNDISNumberError}
                ref={(c) => (this._checkbox = c)}
              />
            )}
          </td>

          <td className="ellipsis width-1/4">
            <DateDisplay
              lineItem={lineItem as { serviceType: ServiceType; startDateTime: string; attendanceId: string }}
              onClick={this._onClickBooking}
            />
          </td>

          <td className="ellipsis flex-column">
            <div className="align-center flex-row">
              <div className="text-size-regular mr-12">
                {paymentSourceType === PaymentSources.NDIS ? supportItemNumber : supportItemName}
              </div>
              {paymentSourceType === PaymentSources.NDIS && paymentMethod && (
                <PaymentMethodTag paymentMethod={paymentMethod} className="mr-12" rounded={true} size="small" />
              )}
              {isNonBillable && <Icon component={NonBillableIcon} style={{ color: 'transparent', fontSize: 24 }} />}
            </div>
            <p>
              {claimType
                ? CLAIM_TYPE_STANDARD_OPTIONS.find((item) => item.value === claimType)?.label
                : 'Standard Service'}
            </p>
          </td>

          <td className="ellipsis">
            {CommonUtils.formatQuantityDisplay(qty)} (
            {_.get(
              unitOptions.find((item) => item.value === unit),
              'label',
            )}
            ) x{' '}
            {CommonUtils.formatPrice(
              isIncludeGst ? CommonUtils.calcPriceIncludeGst(unitPrice, gstValue) : Number(unitPrice),
            )}{' '}
            {isIncludeGst && (
              <Text size="x-small" color="secondary">
                inc.GST
              </Text>
            )}
          </td>

          <td className="ellipsis">
            <div className="text-align-right mr-small">
              <PaymentErrorIndicator errorTypes={errorTypes} billingItems={[lineItem]} warningTypes={warningTypes} />
              <CurrencyFormatter value={Number(billingTotalWithDiscount)} />
            </div>
          </td>

          <td className="fluid-cell">
            <Popover
              content={
                <Menu>
                  {PermissionUtils.validatePermission(
                    'EditPaymentBillingLineItem',
                    portalUser.permissions.permissionRoles,
                  ) && (
                    <MenuItem
                      text={
                        <Text weight="bold" className="text-color-blue-action">
                          Edit
                        </Text>
                      }
                      className="hover-bg-green-lightest"
                      onClick={() => openActionModal({ action: 'EDIT', additionalData: lineItem })}
                    />
                  )}

                  <MenuItem
                    text={<Text weight="bold">{isNonBillable ? 'Mark as billable' : 'Mark as non-billable'}</Text>}
                    onClick={() => changeBillableLineItem(lineItem)}
                  />

                  {PermissionUtils.validatePermission(
                    'WaivePaymentBillingLineItem',
                    portalUser.permissions.permissionRoles,
                  ) &&
                    !isNonBillable && (
                      <MenuItem
                        text={
                          <Text weight="bold" color="red">
                            Waive
                          </Text>
                        }
                        className="hover-bg-red-lightest"
                        onClick={() => openActionModal({ action: 'WAIVE', additionalData: lineItem })}
                      />
                    )}
                </Menu>
              }
              position="bottom-right"
            >
              <Icon type="ellipsis" className="mr-small" />
            </Popover>
          </td>
        </tr>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });

export default connect(mapState, null)(PaymentItemRow);
