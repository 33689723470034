import React, { Component } from 'react';
import { notification } from 'antd';
import { connect } from 'react-redux';

import { NoteVisibleType } from 'utilities/enum-utils';
import { Text, Title } from 'common-components/typography';
import { NoteCardItem } from 'common-components/notes/note-card-item';
import DeleteNoteModal from 'common-components/notes/DeleteNoteModal';
import ExportNoteModal from 'common-components/notes/ExportNoteModal';
import AddEditNoteModal from 'common-components/notes/AddEditNoteModal';
import { IElementValue } from 'views/form-builder/shared/form-interface';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import AddIncidentNoteModal from 'views/workflows/trigger/AddIncidentNoteModal';
import EditValueFormModal from 'views/form-builder/components/EditValueFormModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

import { IBookingNoteItem } from './IBookingNoteItem';

type IBookingNotesPanelProps = {
  onAddNote: (payload) => void;
  bookingNotes: IBookingNoteItem[];
  onRefresh: () => void;
  timezoneData?: any;
  hasEditBookingNotesPermission?: boolean;
  serviceName: string;
  bookingStartDateTime: string;
  bookingId: string;
  selectedBookingItem: any;
  isBookingArchived: boolean;
  noteType: string;
  workflowForm: typeof state.workflowStore.workflowForm;
  doGetWorkflowFormDetail: typeof dispatch.workflowStore.doGetWorkflowFormDetail;
  doUpdateWorkflowForm: typeof dispatch.workflowStore.doUpdateWorkflowForm;
  timezone: string;
};

type IBookingNotesPanelState = {
  noteValue: string;
  noteSharing: boolean;
  isLoading: boolean;
  displayNoteModal: boolean;
  editNote: boolean;
  editingNote: any;
  isDeleteNoteModalOpen: boolean;
  deletingNote: any;
  isExportNoteModalOpen: boolean;
  displayIncidentNodeModal: boolean;
  bookingData: object;
  isOpenFormModal: boolean;
};

class BookingNotesPanel extends Component<IBookingNotesPanelProps, IBookingNotesPanelState> {
  state = {
    noteValue: '',
    noteSharing: false,
    isLoading: false,
    displayNoteModal: false,
    editNote: false,
    editingNote: null,
    isDeleteNoteModalOpen: false,
    deletingNote: null,
    isExportNoteModalOpen: false,
    displayIncidentNodeModal: false,
    bookingData: {},
    isOpenFormModal: false,
  };

  refAddIncidentNoteModal = React.createRef<any>();

  private _onPressAddNote = () => {
    this.setState({ displayNoteModal: true, editNote: false });
  };

  private _onPressEditNote = (note) => {
    this.setState({ editNote: true, editingNote: note, displayNoteModal: true });
  };

  private _onPressCloseNote = () => {
    this.setState({ displayNoteModal: false });
  };

  private _onPressCloseDeleteNoteModal = () => {
    this.setState({ isDeleteNoteModalOpen: false });
  };

  private _onPressDeleteNote = (noteItem) => {
    this.setState({ isDeleteNoteModalOpen: true, deletingNote: noteItem });
  };

  private _showFormModal = async (noteItem) => {
    try {
      const { doGetWorkflowFormDetail } = this.props;
      await doGetWorkflowFormDetail({ workflowFormId: noteItem.workflowFormId, isFromNote: true });
      this.setState({ isOpenFormModal: true });
    } catch (e) {
      notification.error({ message: 'Get workflow form failed.' });
    }
  };

  private _closeFormModal = () => {
    this.setState({ isOpenFormModal: false });
  };

  private _onSaveFormModal = async (id: string, elementValues: IElementValue[]) => {
    try {
      const { doUpdateWorkflowForm } = this.props;
      await doUpdateWorkflowForm({ workflowFormId: id, isFromNote: true, formData: elementValues });
      this.setState({ isOpenFormModal: false });
      notification.success({
        message: 'Update workflow form success',
        description: 'You have successfully update workflow form',
      });
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
    }
  };

  private _onPressAddIncidentNote = () => {
    this.setState({ displayIncidentNodeModal: true });
  };

  private _onPressCloseIncidentNote = () => {
    this.setState({ displayIncidentNodeModal: false });
  };

  render() {
    const { isOpenFormModal } = this.state;
    const { bookingNotes, isBookingArchived, noteType, selectedBookingItem, workflowForm, timezoneData, timezone } =
      this.props;

    const noteVisibleOptions = [
      NoteVisibleType.PORTAL,
      NoteVisibleType.PORTAL_AND_APP,
      NoteVisibleType.RESTRICTED_TO_SERVICE,
      NoteVisibleType.RESTRICTED_TO_SERVICE_PORTAL_AND_APP,
      NoteVisibleType.PRIVATE_PORTAL_ONLY,
    ];

    const defaultNotePrivacyVisible = selectedBookingItem
      ? selectedBookingItem.defaultNotePrivacyVisible
      : NoteVisibleType.PORTAL;

    const nonPrivateNotes = bookingNotes.filter((note) => note.visibleType !== NoteVisibleType.PRIVATE_PORTAL_ONLY);
    return (
      <div>
        <AddEditNoteModal
          bookingId={this.props.bookingId}
          isOpen={this.state.displayNoteModal}
          onClose={this._onPressCloseNote}
          editNote={this.state.editNote}
          editingNote={this.state.editingNote}
          noteType={noteType}
          serviceType={selectedBookingItem.serviceType}
          handleSubmitIncidentNote={(noteData) => {
            const bookingData = {
              involved: {
                customers:
                  selectedBookingItem && selectedBookingItem.bookerUserId
                    ? [
                        {
                          customerId: selectedBookingItem.bookerUserId,
                          displayName: `${selectedBookingItem.firstName} ${selectedBookingItem.lastName}`,
                          avatar: selectedBookingItem.attachmentUrl,
                        },
                      ]
                    : [],
                workers:
                  selectedBookingItem && selectedBookingItem.workerUserId
                    ? [
                        {
                          workerId: selectedBookingItem.workerUserId,
                          displayName: `${selectedBookingItem.workerFirstName} ${selectedBookingItem.workerLastName}`,
                          avatar: selectedBookingItem.workerAttachmentUrl,
                        },
                      ]
                    : [],
              },
              noteData: noteData.note,
              ...noteData,
            };
            this.setState({ ...this.state, bookingData });
            this._onPressAddIncidentNote();
          }}
          noteVisibleOptions={noteVisibleOptions}
          defaultVisibleType={defaultNotePrivacyVisible}
          notes={bookingNotes}
        />
        <AddIncidentNoteModal
          isOpen={this.state.displayIncidentNodeModal}
          onClose={this._onPressCloseIncidentNote}
          isManual={false}
          screenData={this.state.bookingData}
          moduleType={noteType}
          timezone={timezone}
        />
        <DeleteNoteModal
          noteItem={this.state.deletingNote}
          isOpen={this.state.isDeleteNoteModalOpen}
          doCloseDeleteNoteModal={this._onPressCloseDeleteNoteModal}
        />
        <ExportNoteModal
          isOpen={this.state.isExportNoteModalOpen}
          isBookingNote={true}
          onClose={() => this.setState({ isExportNoteModalOpen: false })}
        />
        <div className='flex-row'>
          <div className='width-full'>
            <Title level={3} className='mb-none' weight='bold'>
              Notes
            </Title>
            <Text size='large' type={'secondary'}>
              Notes related to this {noteType === 'BOOKING' ? 'booking' : 'activity record'}.
            </Text>
          </div>
          <div className='width-full flex justify-end'>
            <SecondaryButton
              color={'blue-action'}
              className={'mr-medium'}
              onClick={() => this.setState({ isExportNoteModalOpen: true })}
            >
              Export Notes
            </SecondaryButton>
            {!isBookingArchived && (
              <PrimaryButton color={'blue-action'} icon={'plus'} onClick={this._onPressAddNote}>
                Add Note
              </PrimaryButton>
            )}
          </div>
        </div>
        <div className='mt-large bg-quaternary p-small'>
          {/* Single notes on bookings are not deletable for compliance reasons. */}
          {bookingNotes.map((note) => (
            <NoteCardItem
              noteItem={note}
              key={note.noteId}
              noteType='BOOKING'
              serviceName={this.props.serviceName}
              onPressEditNote={this._onPressEditNote}
              bookingId={this.props.bookingId}
              onPressDeleteNote={this._onPressDeleteNote}
              bookingStartDateTime={this.props.bookingStartDateTime}
              timezone={timezoneData.displayTimezone}
              isBookingArchived={isBookingArchived}
              showFormModal={this._showFormModal}
              isNoteDeletable={nonPrivateNotes.length > 1 || note.visibleType === NoteVisibleType.PRIVATE_PORTAL_ONLY}
            />
          ))}
        </div>
        <br />
        <br />
        {isOpenFormModal && (
          <EditValueFormModal
            isOpen={isOpenFormModal}
            mode='VIEW'
            formId={workflowForm.workflowFormId}
            formElements={workflowForm.formContent}
            elementValues={workflowForm.formData}
            canEditForm={workflowForm.canEditForm}
            onSave={this._onSaveFormModal}
            onClose={this._closeFormModal}
            timezone={this.props.timezone}
          />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  workflowForm: state.workflowStore.workflowForm,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetWorkflowFormDetail: dispatch.workflowStore.doGetWorkflowFormDetail,
  doUpdateWorkflowForm: dispatch.workflowStore.doUpdateWorkflowForm,
});

export default connect(mapState, mapDispatch)(BookingNotesPanel);
