import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';

export function ActionSelectItem({ selectedAction = '', value, title, description, onSetAction }) {
  const isSelected = selectedAction === value;

  return (
    <div
      className={`flex-row ${
        isSelected ? 'bg-blue-lightest' : 'bg-white hover-bg-tertiary'
      } pv-small ph-medium rounded mb-medium cursor-pointer select-none`}
      style={{ width: '640px' }}
      onClick={() => onSetAction(value)}
    >
      <div className="mr-medium">
        {isSelected && (
          <Icon type="check-circle" style={{ fontSize: '16px' }} theme="filled" className="text-color-blue-light" />
        )}
        {!isSelected && (
          <div
            style={{ height: '16px', width: '16px' }}
            className="mt-x-small bordered rounded-full border-secondary"
          />
        )}
      </div>
      <div className="mb-large">
        <Text size="x-large">{title}</Text> <br />
        <Text size="large" color="secondary">
          {description}
        </Text>
      </div>
    </div>
  );
}
