import React from 'react';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { notifications } from '@good/ui/notifications';

import { reactTrpc } from 'utilities/react-trpc';
import {
  ErrorAlert,
  SilServiceContextResolved,
  useInlineForm,
  useSilServiceContext,
} from 'views/sil-service/components';

export const OtherDetailsForm = () => {
  const { service } = useSilServiceContext() as SilServiceContextResolved;
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceDetailsTabs.general.otherDetailsForm' });
  const { t: tMessages } = useTranslation('', { keyPrefix: 'silService.messages' });
  const schema = z.object({
    details: z.string().nullable(),
  });
  const {
    data: serviceDetails,
    isLoading,
    isError,
  } = reactTrpc.sil.services.fetch.useQuery({
    serviceId: service.serviceId,
    serviceProviderId: service.serviceProviderId,
  });
  const updateServiceDetailsMutation = reactTrpc.sil.services.update.useMutation();

  const InlineForm = useInlineForm<z.infer<typeof schema>>({
    values: {
      details: serviceDetails?.otherDetails ?? '',
    },
    resolver: zodResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    try {
      await updateServiceDetailsMutation.mutateAsync({
        serviceId: service.serviceId,
        serviceProviderId: service.serviceProviderId,
        otherDetails: values.details,
      });
    } catch (e) {
      notifications.show({
        color: 'var(--color-red-main)',
        message: tMessages('updateService.error'),
      });
      throw e;
    }
    notifications.show({
      color: 'var(--color-green-main)',
      message: tMessages('updateService.success'),
    });
  };

  if (isError) {
    return (
      <InlineForm
        title={t('title')}
        onSubmit={() => {
          return;
        }}
        disabled
      >
        <ErrorAlert message={tMessages('fetchServiceDetails.error')} />
      </InlineForm>
    );
  }

  return (
    <InlineForm title={t('title')} onSubmit={onSubmit} loading={isLoading}>
      <InlineForm.RichTextEditor
        name='details'
        emptyValue={t('fields.details.emptyValue')}
        aria-label={t('fields.details.ariaLabel')}
        placeholder={t('fields.details.placeholder')}
      />
    </InlineForm>
  );
};
