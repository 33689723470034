import type { CSS } from '../theme';

import { css } from '../theme';

export const progressBarWrapper: CSS = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const progressBarVariants = css({
  variants: {
    animation: {
      true: {
        transition: '200ms width ease-in-out',
      },
    },
  },
});
