import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Note = forwardRef(function Note(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2.25h-.056c-1.838 0-3.294 0-4.433.153-1.172.158-2.121.49-2.87 1.238-.748.749-1.08 1.698-1.238 2.87-.153 1.14-.153 2.595-.153 4.433v2.112c0 1.838 0 3.294.153 4.433.158 1.172.49 2.121 1.238 2.87.749.748 1.698 1.08 2.87 1.238 1.14.153 2.595.153 4.433.153h3.338c1.048 0 1.724 0 2.363-.182.35-.1.689-.24 1.008-.417.58-.323 1.059-.802 1.8-1.543l.077-.078.078-.078c.741-.74 1.22-1.218 1.543-1.8.177-.318.317-.656.417-1.007.182-.64.182-1.315.182-2.363v-3.338c0-1.838 0-3.294-.153-4.433-.158-1.172-.49-2.121-1.238-2.87-.749-.748-1.698-1.08-2.87-1.238-1.14-.153-2.595-.153-4.433-.153H11ZM4.702 4.702c.423-.423 1.003-.677 2.009-.812 1.028-.138 2.382-.14 4.289-.14h2c1.907 0 3.262.002 4.29.14 1.005.135 1.585.389 2.008.812.423.423.677 1.003.812 2.009.138 1.028.14 2.382.14 4.289v3.172c0 .453-.001.8-.009 1.078h-.293c-.899 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.08.595-.08 1.345-.08 2.243v.293c-.278.008-.625.009-1.078.009H11c-1.907 0-3.261-.002-4.29-.14-1.005-.135-1.585-.389-2.008-.812-.423-.423-.677-1.003-.812-2.009-.138-1.027-.14-2.382-.14-4.289v-2c0-1.907.002-3.261.14-4.29.135-1.005.389-1.585.812-2.008ZM16.75 19.93c.059-.028.116-.058.173-.09.376-.209.701-.525 1.547-1.37.845-.846 1.161-1.171 1.37-1.547.032-.057.062-.114.09-.173-.926 0-1.553.003-2.025.067-.461.061-.659.169-.789.3-.13.13-.237.327-.3.788-.063.472-.066 1.1-.066 2.025Z"
        fill="currentColor"
      />
    </Icon>
  );
});
