import { Checkbox, Form, Input } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Paragraph, Text } from 'common-components/typography';
import _ from 'lodash';
import React from 'react';
import {
  IFeedbackSurveyState,
  REASON_CANCELLATION_LIST,
} from 'views/account-management/subscription-management/utils/constants';
import WarningSection from './WarningSection';

const { TextArea } = Input;

interface IProps {
  form: WrappedFormUtils<Form>;
  feedbackSurveyState: IFeedbackSurveyState;
}

const FeedbackSurveySection: React.FC<IProps> = ({ form, feedbackSurveyState }) => {
  const selectedReasons = form.getFieldValue('selectedReasons') || feedbackSurveyState?.selectedReasons || [];

  const { getFieldDecorator } = form;

  return (
    <>
      <Paragraph lineHeight={200}>We’d love to learn more about your experience before you go.</Paragraph>

      <Text size="large" className="mt-medium" weight="bold">
        Select the main reason(s) for your cancellation&nbsp;
        <Text className="text-color-secondary" size="regular">
          (REQUIRED)
        </Text>
      </Text>

      <Form.Item className="m-none">
        {getFieldDecorator('selectedReasons', {
          initialValue: selectedReasons,
        })(
          <Checkbox.Group className="width-full mt-small">
            {_.map(REASON_CANCELLATION_LIST, (reason) => {
              const isSelectedReason = selectedReasons.includes(reason.field);
              const hasSubField = reason?.subField;

              return (
                <div key={reason.field} className="mt-small width-full">
                  <Checkbox
                    value={reason.field}
                    className="block m-none"
                    style={{ height: '30px', lineHeight: '30px' }}
                  >
                    {reason.label}
                  </Checkbox>
                  {isSelectedReason && hasSubField && (
                    <Form.Item className="mt-12" style={{ marginBottom: 20 }}>
                      {getFieldDecorator(reason?.subField, {
                        rules: [
                          {
                            max: 200,
                            message: 'Please enter less than 200 characters',
                          },
                        ],
                        initialValue: feedbackSurveyState?.[reason?.subField] || '',
                      })(
                        <Input
                          className="block width-full ph-large pv-medium text-size-large"
                          style={{ height: 56 }}
                          placeholder="Let us know what’s missing..."
                        />,
                      )}
                    </Form.Item>
                  )}
                </div>
              );
            })}
          </Checkbox.Group>,
        )}
      </Form.Item>

      <Text className="mt-large" size="large" weight="bold">
        How we could have improved your experience?&nbsp;
        <Text className="text-color-secondary" size="regular">
          (OPTIONAL)
        </Text>
      </Text>

      <Form.Item className="m-none mt-x-small">
        {getFieldDecorator('additionalNote', {
          rules: [
            {
              max: 500,
              message: 'Please enter less than 500 characters',
            },
          ],
          initialValue: feedbackSurveyState?.additionalNote || '',
        })(
          <TextArea
            autoSize={{ minRows: 4 }}
            style={{ fontSize: 16 }}
            placeholder="Type here..."
            className="block width-full ph-large pv-medium"
          />,
        )}
      </Form.Item>

      <WarningSection />
    </>
  );
};

export default FeedbackSurveySection;
