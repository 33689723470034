import React, { Dispatch, SetStateAction } from 'react';
import { TmMgmtTeamSearch } from './tm-mgmt-team-search';
import { TmMgmtTeamTableHeader } from './team-management-team-table/tm-mgmt-team-table-header';
import { TmMgmtTeamTableBody } from './team-management-team-table/tm-mgmt-team-table-body';

type TmMgmtTeamTabParams = {
  isLoading: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  setPage: Dispatch<SetStateAction<number>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setModalData: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedModal: Dispatch<SetStateAction<string>>;
};
export const TmMgmtTeamTab = ({
  isLoading,
  setIsLoading,
  setPage,
  page,
  pageSize,
  pageTimestamp,
  setModalData,
  setIsOpen,
  setSelectedModal,
}: TmMgmtTeamTabParams) => (
  <>
    <TmMgmtTeamSearch
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      setPage={setPage}
      page={page}
      pageSize={pageSize}
      pageTimestamp={pageTimestamp}
    />
    <TmMgmtTeamTableHeader />
    <TmMgmtTeamTableBody
      page={page}
      pageSize={pageSize}
      pageTimestamp={pageTimestamp}
      setPage={setPage}
      setSelectedModal={setSelectedModal}
      setModalData={setModalData}
      setIsOpen={setIsOpen}
    />
  </>
);
