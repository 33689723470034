import { Avatar, Form, Select, Skeleton } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { ICommonUserInfo } from 'interfaces/session-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { TeamStatus } from 'utilities/enum-utils';

interface IAddSupportCoordinatorProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  workerListLite: typeof state.teamStore.workerListLite;
  doFetchWorkerListLite: typeof dispatch.teamStore.doFetchWorkerListLite;
  setWorkerListLite: typeof dispatch.teamStore.setWorkerListLite;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
}

interface IAddSupportCoordinatorStates {
  isLoading: boolean;
}

class AddSupportCoordinatorStepPanel extends Component<IAddSupportCoordinatorProps, IAddSupportCoordinatorStates> {
  state = {
    isLoading: false,
  };

  private _getWorkersCommonInfo(workerIds: any[], workerList: any[]): ICommonUserInfo[] {
    const workersCommonInfo = [];
    _.forEach(workerList, (roster) => {
      if (workerIds.includes(roster.supportWorkerId)) {
        const workerInfo = {
          userId: roster.userId,
          userAvatar: roster.attachmentUrl,
          userFullName: roster.firstName + ' ' + roster.lastName,
        };
        workersCommonInfo.push(workerInfo);
      }
    });

    return workersCommonInfo;
  }

  private _onNextStep = () => {
    const { onNextStep } = this.props;
    onNextStep();
  };

  private _onAddSelectedUsers = (userIdsSelected) => {
    const workers = _.map(userIdsSelected, (userId) => {
      return {
        workerId: userId,
      };
    });
    this.props.setNewService({ ...this.props.newService, workers });
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await this.props.doFetchWorkerListLite({
      hasAppAccess: ['WORKER'],
      supportWorkerStatus: [TeamStatus.ENABLED, TeamStatus.DRAFT, TeamStatus.BLOCKED],
      sortByRelevance: true,
    });
    this.setState({ isLoading: false });
  };

  componentWillUnmount(): void {
    this.props.setWorkerListLite([]);
  }

  render = () => {
    const { onPreviousStep, workerListLite, newService } = this.props;
    const { isLoading } = this.state;
    const workerArray = _.map(workerListLite, (worker) => {
      return {
        userId: worker.supportWorkerId,
        userFullName: worker.firstName + ' ' + worker.lastName,
        userAvatar: worker.attachmentUrl,
      };
    });

    return (
      <>
        <div className="flex-column mb-large">
          <Text size="x3-large" weight="bold">
            Add support coordinators to this service
          </Text>
          <Text>
            Add all support coordinators in your team to this service roster so they can be assigned to bookings and
            record. You can do this now or after you have created the service
          </Text>
        </div>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Select
            size="large"
            placeholder="Select team member"
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            onChange={this._onAddSelectedUsers}
            defaultValue={_.map(newService.workers, (worker) => worker.workerId)}
            filterOption={(input, option) => {
              const selectedWorkerName = _.get(option, 'props.children[1].props.children', '');
              return selectedWorkerName.toLowerCase().includes(input.toLowerCase());
            }}
          >
            {_.map(workerArray, (user) => {
              return (
                <Select.Option style={{ height: 'fit-content' }} value={user.userId}>
                  <Avatar size={28} src={user.userAvatar} shape={'square'} />
                  <Text weight="regular" className="ml-small">
                    {user.userFullName}
                  </Text>
                </Select.Option>
              );
            })}
          </Select>
        )}
        <div className="flex-row align-center mv-x2-large">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left" className="mr-large">
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this._onNextStep} icon="right" iconPosition="right">
            Next
          </PrimaryButton>
        </div>
      </>
    );
  };
}

const mapState = (state: IRootState) => ({
  workerListLite: state.teamStore.workerListLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchWorkerListLite: dispatch.teamStore.doFetchWorkerListLite,
  setWorkerListLite: dispatch.teamStore.setWorkerListLite,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IAddSupportCoordinatorProps>()(AddSupportCoordinatorStepPanel));
