import { Star } from '@good/icons';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Text } from 'common-components/typography';
import { IExportFileStyle } from 'interfaces/account-interfaces';
import React from 'react';

interface Props {
  hasPermission: boolean;
  option: IExportFileStyle;
  positionPopover: 'auto' | 'top-right' | 'bottom-right';
}

const ActionSelectItem = ({ option, hasPermission, positionPopover }: Props) => {
  if (option.isDefault) {
    return (
      <div className="flex-row justify-between">
        <Text weight="bold" size="large">
          {option.name}
        </Text>
        <Tooltip2
          interactionKind="hover"
          position={positionPopover}
          content={
            <div style={{ width: 200 }}>
              <Text color="white" size="regular">
                {hasPermission
                  ? 'Default settings can be managed in the Account tab.'
                  : 'This default has been set by your workspace’s owners.'}
              </Text>
            </div>
          }
        >
          <Star />
        </Tooltip2>
      </div>
    );
  }

  return <Text size="large">{option.name}</Text>;
};

export default ActionSelectItem;
