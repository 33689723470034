/* eslint-disable */
import React, { Component } from 'react';
import { Col, Icon, Row } from 'antd';
import { IServiceDetailsItem } from 'interfaces/service-interfaces';
import { Paragraph, Text, Title } from 'common-components/typography';
import moment from 'moment-timezone';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { notification } from 'antd/es';
import { ServicePublishStatus, ServiceType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import ArchiveServiceModal from 'views/services/details/sections/ArchiveServiceModal';
import { withRouter, WithRouterProps } from 'utilities/with-router';

interface IServiceDetailsTopSectionProps extends WithRouterProps {
  selectedService: IServiceDetailsItem;
  setSelectedService: typeof dispatch.servicesStore.setSelectedService;
  doPublishService: typeof dispatch.servicesStore.doPublishService;
  doUnpublishService: typeof dispatch.servicesStore.doUnpublishService;
  setFilteredServices: typeof dispatch.servicesStore.setFilteredServices;
  filteredServices: typeof state.servicesStore.filteredServices;
  portalUser: typeof state.authStore.portalUser;
}

interface IServiceDetailsTopSectionState {
  publishModalOpen: boolean;
  unpublishModalOpen: boolean;
  archiveModalOpen: boolean;
  isLoading: boolean;
}

const serviceTypeLabel = [
  { tag: ServiceType.GROUP, label: 'Group Service' },
  { tag: ServiceType.INDIVIDUAL, label: 'Support Service' },
  { tag: ServiceType.COORDINATION, label: 'Support Coordination' },
];

class ServiceDetailsTopSection extends Component<IServiceDetailsTopSectionProps, IServiceDetailsTopSectionState> {
  state = {
    isLoading: false,
    publishModalOpen: false,
    unpublishModalOpen: false,
    archiveModalOpen: false,
  };

  private _openPublishModal = () => {
    this.setState({ publishModalOpen: true });
  };

  private _closePublishModal = () => {
    this.setState({ publishModalOpen: false });
  };

  private _openArchiveModal = () => {
    this.setState({ archiveModalOpen: true });
  };

  private _closeArchiveModal = () => {
    this.setState({ archiveModalOpen: false });
  };

  private _openUnpublishModal = () => {
    this.setState({ unpublishModalOpen: true });
  };

  private _closeUnpublishModal = () => {
    this.setState({ unpublishModalOpen: false });
  };

  private _unpublish = async () => {
    const { filteredServices, setFilteredServices, doUnpublishService, setSelectedService, selectedService } =
      this.props;
    try {
      this.setState({ isLoading: true });
      await doUnpublishService({ serviceId: selectedService.serviceId });
      notification.success({
        message: 'Unpublish service successful',
      });
      const updatedServiceList = _.map(filteredServices, (service) => {
        if (service.serviceId === selectedService.serviceId) {
          return { ...service, status: ServicePublishStatus.UNPUBLISHED };
        } else {
          return { ...service };
        }
      });
      setFilteredServices(updatedServiceList);
      await setSelectedService({ ...selectedService, status: ServicePublishStatus.UNPUBLISHED, updatedOn: new Date() });
      this.setState({ unpublishModalOpen: false, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({
        message: 'Unpublish service failed',
        description: 'An error has occurred while unpublishing your service. Please try again.',
      });
      throw e;
    }
  };

  private _publish = async () => {
    const { filteredServices, setFilteredServices, doPublishService, setSelectedService, selectedService } = this.props;
    try {
      this.setState({ isLoading: true });
      await doPublishService({ serviceId: selectedService.serviceId });
      notification.success({
        message: 'Publish service successful',
      });
      await setSelectedService({ ...selectedService, status: ServicePublishStatus.PUBLISHED, updatedOn: new Date() });
      const updatedServiceList = _.map(filteredServices, (service) => {
        if (service.serviceId === selectedService.serviceId) {
          return { ...service, status: ServicePublishStatus.PUBLISHED };
        } else {
          return { ...service };
        }
      });
      setFilteredServices(updatedServiceList);
      this.setState({ publishModalOpen: false, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({
        message: 'Publish service failed',
        description: 'An error has occurred while publishing your service. Please try again.',
      });
      throw e;
    }
  };

  render() {
    const { selectedService, portalUser } = this.props;

    return (
      <div className="width-full">
        <ArchiveServiceModal
          isOpen={this.state.archiveModalOpen}
          onClose={this._closeArchiveModal}
          selectedService={selectedService}
        />
        <ActionModal
          isOpen={this.state.unpublishModalOpen}
          onClose={this._closeUnpublishModal}
          title={'Confirmation'}
          showCloseButton={true}
        >
          <Paragraph>
            You are about to <b>unpublish this service</b>, it won't be visible in the Marketplace anymore.
          </Paragraph>
          <Paragraph>This service will be marked as Draft, you can re-publish it at a later time.</Paragraph>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this._closeUnpublishModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" color={'red'} loading={this.state.isLoading} onClick={this._unpublish}>
              Unpublish the Service
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.state.publishModalOpen}
          onClose={this._closePublishModal}
          title={'Confirmation'}
          showCloseButton={true}
        >
          <Paragraph>
            You are about to <b>publish this service</b>.
          </Paragraph>
          {selectedService.servicePublishDate && moment(selectedService.servicePublishDate) > moment() ? (
            <Paragraph>
              This service is set to be visible to the Marketplace on the{' '}
              <b>{moment(selectedService.servicePublishDate).format('DD/MM/YYYY')}</b>.<br /> You can change this
              publish date in the Settings tab.
            </Paragraph>
          ) : (
            <Paragraph>This service will be visible in the Marketplace.</Paragraph>
          )}
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this._closePublishModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" loading={this.state.isLoading} onClick={this._publish}>
              Publish the Service
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>

        <Row type={'flex'}>
          <Col>
            <div className="pb-medium pr-medium">
              <HyperlinkButton onClick={() => this.props.history.push('/services')}>
                <Icon type="left" className="mr-x-small" />
                Back to Services
              </HyperlinkButton>
            </div>
          </Col>
        </Row>

        <Row type={'flex'}>
          <Col span={24}>
            <div className="pb-small">
              {/*<Text type={'secondary'}>Viewing service details for</Text>*/}
              {/*<br />*/}
              <Title className="mv-none pb-small" color={'secondary'} level={3}>
                {_.find(serviceTypeLabel, (type) => type.tag === selectedService.serviceType)
                  ? _.find(serviceTypeLabel, (type) => type.tag === selectedService.serviceType).label
                  : selectedService.serviceType}{' '}
                / <span className="text-color-black">{selectedService.serviceName}</span>
              </Title>
            </div>

            <div className="align-center flex-row justify-between">
              <div className="pt-x-small">
                {selectedService.status === ServicePublishStatus.DRAFT && (
                  <Text color="orange" size="x2-large" weight="bold">
                    <Icon type="pause-circle" theme="filled" className="mr-x-small" />
                    Draft
                  </Text>
                )}
                {selectedService.status === ServicePublishStatus.UNPUBLISHED && (
                  <Text color="red" size="x2-large" weight="bold">
                    <Icon type="close-circle" theme="filled" className="mr-x-small" />
                    Unpublished
                  </Text>
                )}
                {selectedService.status === ServicePublishStatus.ARCHIVED && (
                  <Text color="secondary" size="x2-large" weight="bold">
                    <Icon type="close-circle" theme="filled" className="mr-x-small" />
                    Archived
                  </Text>
                )}
                {selectedService.status === ServicePublishStatus.PUBLISHED &&
                  (!selectedService.servicePublishDate ||
                    moment.tz(selectedService.servicePublishDate, selectedService.timezone).startOf('day') <
                      moment.tz(selectedService.timezone)) && (
                    <Text color="green" size="x2-large" weight="bold">
                      <Icon type="check-circle" theme="filled" className="mr-x-small" />
                      Published
                    </Text>
                  )}
                {selectedService.status === ServicePublishStatus.PUBLISHED &&
                  selectedService.servicePublishDate &&
                  moment.tz(selectedService.servicePublishDate, selectedService.timezone).startOf('day') >
                    moment.tz(selectedService.timezone) && (
                    <Text color="cobalt" size="x2-large" weight="bold">
                      <Icon type="clock-circle" theme="filled" className="mr-x-small" />
                      Scheduled to be published on the{' '}
                      {moment.tz(selectedService.servicePublishDate, selectedService.timezone).format('DD/MM/YYYY')}
                    </Text>
                  )}
              </div>
              <div>
                {(selectedService.status === ServicePublishStatus.DRAFT ||
                  selectedService.status === ServicePublishStatus.UNPUBLISHED) &&
                  PermissionUtils.validatePermission(
                    'PublishService',
                    portalUser.permissions.permissionRoles,
                    selectedService.serviceDepartmentId,
                    selectedService.serviceId,
                  ) && (
                    <>
                      <SecondaryButton
                        icon="calendar"
                        size="large"
                        color={'red'}
                        className="mr-small rounded-full"
                        onClick={this._openArchiveModal}
                      >
                        Archive
                      </SecondaryButton>
                      <PrimaryButton
                        icon="check"
                        size="large"
                        className="rounded-full"
                        onClick={this._openPublishModal}
                      >
                        Publish
                      </PrimaryButton>
                    </>
                  )}
                {selectedService.status === ServicePublishStatus.PUBLISHED &&
                  PermissionUtils.validatePermission(
                    'UnpublishService',
                    portalUser.permissions.permissionRoles,
                    selectedService.serviceDepartmentId,
                    selectedService.serviceId,
                  ) && (
                    <PrimaryButton
                      icon="close"
                      color="red"
                      size="large"
                      className="rounded-full"
                      onClick={this._openUnpublishModal}
                    >
                      Unpublish
                    </PrimaryButton>
                  )}
              </div>
            </div>
            <div className="text-align-right mt-medium">
              <Text size="regular" color="secondary">
                Created on the {moment(selectedService.createdOn).format('DD MMM YYYY')}. Last edited{' '}
                {moment(selectedService.updatedOn).fromNow()}
              </Text>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapState = (state: IRootState) => ({
  filteredServices: state.servicesStore.filteredServices,
  portalUser: state.authStore.portalUser,
  selectedService: state.servicesStore.selectedService,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doPublishService: dispatch.servicesStore.doPublishService,
  doUnpublishService: dispatch.servicesStore.doUnpublishService,
  setSelectedService: dispatch.servicesStore.setSelectedService,
  setFilteredServices: dispatch.servicesStore.setFilteredServices,
});

export default connect(mapState, mapDispatch)(withRouter(ServiceDetailsTopSection));
