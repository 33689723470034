import { BrowserType } from './enum-utils';

// related information https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent?fbclid=IwAR1MbLWnPYFp-8HpiaKM5TSDahDlRy9C0zsNGr5vfgSikeqtO2rWzWbMObQ
export const getBrowserType = (): BrowserType => {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1) {
    return BrowserType.OPERA;
  } else if (navigator.userAgent.indexOf('Edg') != -1) {
    return BrowserType.EDGE;
  } else if (navigator.userAgent.indexOf('Chrome') != -1) {
    return BrowserType.CHROME;
  } else if (navigator.userAgent.indexOf('Safari') != -1) {
    return BrowserType.SAFARI;
  } else if (navigator.userAgent.indexOf('Firefox') != -1) {
    return BrowserType.FIREFOX;
  } else if (navigator.userAgent.indexOf('MSIE') != -1) {
    return BrowserType.IE;
  } else {
    return BrowserType.UNKNOWN;
  }
};

export interface CLIENT_ENVIRONMENT {
  browser: {
    type: BrowserType;
  };
}

const clientEnvironment: CLIENT_ENVIRONMENT = {
  browser: {
    type: getBrowserType(),
  },
};

export default clientEnvironment;
