import { Tag } from 'antd';
import { Text } from '../../../../../../common-components/typography';
import { ICustomerRequestedService } from '../../../../../../interfaces/customer-interfaces';
import React from 'react';
import { tagColors } from '../../../../../../theme/theme-variables';

type Props = { requestedService: ICustomerRequestedService };

export const RequestedServiceTag = ({ requestedService }: Props) => (
  <Tag color={tagColors.COBALT} className="mb-small" key={requestedService.serviceId}>
    <Text color="white" weight="bold" size="large">
      {requestedService.serviceName}
    </Text>
  </Tag>
);
