import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Brackets = forwardRef(function Brackets(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.25h-.038c-.484 0-1.355 0-2.116.304a2.51 2.51 0 0 0-1.126.85c-.31.43-.47.966-.47 1.596v4.3c0 .464-.408.945-1.136 1.382a6.57 6.57 0 0 1-1.309.593l-.018.006h-.003a.75.75 0 0 0 0 1.437l.003.001.018.006a6.591 6.591 0 0 1 .385.14c.257.103.594.255.924.453.728.437 1.136.918 1.136 1.382V19c0 .63.16 1.166.47 1.595a2.51 2.51 0 0 0 1.126.851c.761.305 1.632.304 2.116.304H8a.75.75 0 0 0 0-1.5c-.525 0-1.128-.009-1.596-.196-.218-.087-.369-.2-.468-.337-.096-.133-.186-.347-.186-.717v-4.3c0-1.336-1.092-2.205-1.864-2.668L3.832 12l.054-.032c.772-.463 1.864-1.332 1.864-2.668V5c0-.37.09-.584.186-.717.1-.138.25-.25.468-.337C6.872 3.76 7.474 3.75 8 3.75a.75.75 0 1 0 0-1.5Zm8 1.5c.526 0 1.128.009 1.596.196.218.087.369.2.468.337.096.133.186.347.186.717v4.3c0 1.336 1.092 2.205 1.864 2.668l.054.032-.054.032c-.772.463-1.864 1.332-1.864 2.668V19c0 .37-.09.584-.186.717-.1.138-.25.25-.468.337-.468.187-1.07.196-1.596.196a.75.75 0 0 0 0 1.5h.038c.484 0 1.355 0 2.116-.304a2.51 2.51 0 0 0 1.126-.85c.31-.43.47-.966.47-1.596v-4.3c0-.464.408-.945 1.136-1.382a6.596 6.596 0 0 1 1.309-.593l.018-.006h.003a.75.75 0 0 0 0-1.437l-.003-.001-.018-.006a6.483 6.483 0 0 1-.385-.14 6.574 6.574 0 0 1-.924-.453c-.728-.437-1.136-.918-1.136-1.382V5c0-.63-.16-1.166-.47-1.595a2.51 2.51 0 0 0-1.126-.851c-.761-.305-1.632-.304-2.116-.304H16a.75.75 0 0 0 0 1.5ZM12 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
