import React from 'react';
import { Icon } from '../icon';

import type { IconProps } from '../icon';

export function Positive(props: IconProps) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="m172 104-58.7 56L84 132"
      />
      <circle
        cx={128}
        cy={128}
        r={96}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Icon>
  );
}
