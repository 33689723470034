import { css } from '../theme';

import type { CSS } from '../theme';

export const tooltip: CSS = {
  position: 'relative',
};

export const tooltipContent: CSS = {
  zIndex: '111111',
  outline: 'none !important',
};

export const arrowTooltip: CSS = {
  position: 'absolute',
  display: 'block',
  width: '12px',
  height: '12px',
  overflow: 'hidden',
  pointerEvent: 'none',
};

export const arrowTooltipContent: CSS = {
  position: 'absolute',
  inset: 0,
  display: 'block',
  width: '100%',
  height: '100%',
  margin: 'auto',
  content: '',
  pointerEvents: 'none',
  backgroundColor: '$lavenderDark2',
  transform: 'rotate(45deg) translate(50%,50%)',
};
