import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { Form, Icon, Input, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import _ from 'lodash';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface ICreateServiceDepartmentModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  serviceDepartmentList: any;
  doCreateServiceDepartment: typeof dispatch.servicesStore.doCreateServiceDepartment;
  resetServiceDepartmentList: () => void;
}

interface ICreateServiceDepartmentModalState {
  title: any;
  isCreated: boolean;
  serviceDepartmentName: string;
}

class CreateServiceDepartmentModal extends Component<
  ICreateServiceDepartmentModalProps,
  ICreateServiceDepartmentModalState
> {
  state = { title: 'Create a service department', isCreated: false, serviceDepartmentName: null };

  private _onCloseModal = () => {
    this.setState({ title: 'Create a service department', isCreated: false });
    this.props.onClose();
  };

  private _createDepartment = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      const newServiceDepartmentName = form.getFieldValue('serviceDepartmentName');
      try {
        await this.props.doCreateServiceDepartment({ serviceDepartmentName: newServiceDepartmentName });
        this.setState({
          title: (
            <>
              Service department created successfully
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isCreated: true,
          serviceDepartmentName: newServiceDepartmentName,
        });
        this.props.resetServiceDepartmentList();
      } catch (e) {
        if (e.meta.message === 'Duplicate service department name') {
          notification.error({ message: 'This service department name is already use.' });
        } else {
          notification.error({ message: 'Oops! Something went wrong, please try again.' });
        }
      }
    }
  };

  render() {
    const { isOpen, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isCreated ? (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <SubTitle>Service department name</SubTitle>
                <Title level={4}>{this.state.serviceDepartmentName}</Title>
                <Paragraph>You can now place services inside this department</Paragraph>
              </div>

              <ActionModalFooter>
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  Done
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        ) : (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <SubTitle>Service department name</SubTitle>

                <Form.Item className="width-full">
                  {getFieldDecorator('serviceDepartmentName', {
                    rules: [{ required: true, message: 'Please enter a name.' }],
                  })(<Input size={'large'} style={{ width: '400px' }} placeholder="Enter name" />)}
                </Form.Item>
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._createDepartment}>
                  Create service department
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateServiceDepartment: dispatch.servicesStore.doCreateServiceDepartment,
});

export default connect(
  null,
  mapDispatch,
)(Form.create<ICreateServiceDepartmentModalProps>()(CreateServiceDepartmentModal));
