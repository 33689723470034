import { Avatar, Divider } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { IAssignedCustomer, IShiftCustomerAssignment } from 'interfaces/assign-customer-interfaces';
import { ISession } from 'interfaces/session-interfaces';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { saveShiftCustomerAssignments } from 'stores/queries/group-services/group-services-queries';
import { useFetchCustomerBookingInSession } from 'stores/hooks/query-hooks/use-query-shift-customer-assignments';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Utils from 'utilities/Utils';
import { v4 } from 'uuid';
import { CustomerAssignmentCard } from 'views/group-services/session-details/team-members/modals/AssignShiftToCustomersModal/CustomerAssignmentCard';
import { events } from 'integrations/appcues';

interface IAssignShiftToCustomersModalProps {
  isOpen: boolean;
  shiftSlot?: IShiftSlot;
  onClose: (targetFlag, refreshShiftSlots?: boolean) => void;
  session?: ISession;
  setResetSessionCustomers: typeof dispatch.groupServiceStore.setResetSessionCustomers;
}

function AssignShiftToCustomersModal(props: IAssignShiftToCustomersModalProps) {
  // Determines if the modal can be closed or not

  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  const [canModalClose, setCanModalClose] = useState(false);

  // Assignments - local working copy
  const [customerAssignments, setCustomerAssignments] = useState<IShiftCustomerAssignment[]>([]);

  // Id for pending assignment
  const [pendingId, setPendingId] = useState(null);

  // Waiting for save action
  const [isSaving, setIsSaving] = useState(false);

  const { isOpen, onClose, session, shiftSlot } = props;

  const { startDateTime: sessionStartDateTime = new Date(), endDateTime: sessionEndDateTime = new Date() } =
    session || {};

  const { supportWorkerId, firstName = '', lastName = '', attachmentUrl = '' } = (shiftSlot || {}) as IShiftSlot;

  const hasAssignedCustomers = !_.isEmpty(customerAssignments);

  const { data: customerData } = useFetchCustomerBookingInSession(
    {
      serviceId: props.session && props.session.serviceId,
      serviceDateTimeId: props.session && props.session.serviceDateTimeId,
    },
    true,
  );

  const customers =
    customerData &&
    _.map(customerData.data.customers, (customer) => {
      return {
        attendanceId: customer.attendanceId,
        customerUserId: customer.customerUserId,
        customerFirstName: customer.firstName,
        customerLastName: customer.lastName,
        customerAttachmentUrl: customer.customerAvatarUrl,
      };
    });

  const uniqueCustomers = _.chain(customerAssignments)
    .sortBy(['customerUserId'])
    .groupBy('customerUserId')
    .keys()
    .value();

  const customersForSelecting = _.filter(customers, (c) => !_.includes(uniqueCustomers, c.customerUserId));

  useEffect(() => {
    if (!!isOpen && shiftSlot) {
      setCustomerAssignments(
        _.map(shiftSlot.shiftCustomerAssignments, (assignment) => {
          return {
            ...assignment,
            startDateTime: moment(
              moment.tz(assignment.startDateTime, session.timezone).format('YYYY-MM-DD HH:mm'),
            ).toDate(),
            endDateTime: moment(
              moment.tz(assignment.endDateTime, session.timezone).format('YYYY-MM-DD HH:mm'),
            ).toDate(),
          };
        }),
      );
      resetModal();
    }
  }, [isOpen, setCustomerAssignments]);

  // Reset modal to default state
  function resetModal() {
    setCanModalClose(true);
  }

  // Save the modal
  async function onSaveAction() {
    setIsSaving(true);

    // Appcues
    if (customerAssignments && customerAssignments.length) {
      const customerUserIds = customerAssignments.map((item) => item.customerUserId);
      const memberId = customerUserIds.length === 1 ? customerUserIds[0] : customerUserIds;
      events.trackAssignMemberToBooking({ memberId, serviceType: session.serviceName });
    }

    await saveShiftCustomerAssignments({
      supportWorkerAttendanceId: props.shiftSlot && props.shiftSlot.supportWorkerAttendanceId,
      serviceId: props.session && props.session.serviceId,
      serviceDateTimeId: props.session && props.session.serviceDateTimeId,
      attendances: _.map(
        _.filter(customerAssignments, (assignment) => !Utils.isEmpty(assignment.attendanceId)),
        (assignment) => {
          return {
            attendanceId: assignment.attendanceId,
            startDateTime: moment.tz(moment(assignment.startDateTime).format('YYYY-MM-DD HH:mm'), session.timezone),
            endDateTime: moment.tz(moment(assignment.endDateTime).format('YYYY-MM-DD HH:mm'), session.timezone),
            description: assignment.description,
          };
        },
      ),
    });
    setIsSaving(false);
    props.setResetSessionCustomers(true);
    onClose({ targetFlag: 'isAssignToCustomerOpen' }, true);
  }

  // Cancel modal
  function onCancelAction() {
    onClose({ targetFlag: 'isAssignToCustomerOpen' }, false);
  }

  // --- Assignment actions ---

  // Add a new customer assignment from Assign to Customer
  function onAddNewAssignment() {
    // No pending assigment
    if (_.isEmpty(pendingId) || !_.find(customerAssignments, (assignment) => Utils.isEmpty(assignment.attendanceId))) {
      const newShift: IShiftCustomerAssignment = {
        shiftCustomerAssignmentId: v4(), // temporarily assign a v4
        supportWorkerAttendanceId: '',
        attendanceId: '',
        startDateTime: moment(moment.tz(sessionStartDateTime, session.timezone).format('YYYY-MM-DD HH:mm')).toDate(),
        endDateTime: moment(moment.tz(sessionEndDateTime, session.timezone).format('YYYY-MM-DD HH:mm')).toDate(),
        description: '',
        isEditing: false,
        isNew: true,
      };

      setCustomerAssignments([...customerAssignments, newShift]);
      setPendingId(newShift.shiftCustomerAssignmentId);
    } else {
      // There's already a pending assignment. Don't do anything
    }
  }

  // Save the customer assignment for a pending row
  function onSelectAssignment({ customerUserId, pendingId }) {
    let row = _.find(customerAssignments, (a) => a.shiftCustomerAssignmentId === pendingId);

    const customer: IAssignedCustomer = _.find(customers, (c) => c.customerUserId === customerUserId);

    const { customerFirstName, customerLastName, customerAttachmentUrl, attendanceId } = customer;

    row = { ...row, customerUserId, customerFirstName, customerLastName, customerAttachmentUrl, attendanceId };

    const newArray = [row, ..._.filter(customerAssignments, (a) => a.shiftCustomerAssignmentId !== pendingId)];

    setCustomerAssignments(newArray);
    setPendingId(null);
  }

  // Cancel customer assignment (not selected)
  function onCancelAssignment({ pendingId }) {
    onDeleteRow({ rowId: pendingId });
    setPendingId(null);
  }

  // Remove customer assignment
  function onRemoveAssignment({ customerId }) {
    const newArray = _.filter(customerAssignments, (a) => a.customerUserId !== customerId);
    setCustomerAssignments(newArray);
  }

  // --- Row actions ---

  // Save the time assignment row
  function onEditRow({ rowId }) {
    let row = _.find(customerAssignments, (a) => a.shiftCustomerAssignmentId === rowId);
    row = { ...row, isEditing: true };
    const newArray = [row, ..._.filter(customerAssignments, (a) => a.shiftCustomerAssignmentId !== rowId)];
    setCustomerAssignments(newArray);
  }

  function onSaveRow({ rowId }) {
    let row = _.find(customerAssignments, (a) => a.shiftCustomerAssignmentId === rowId);
    row = { ...row, isEditing: false };
    const newArray = [row, ..._.filter(customerAssignments, (a) => a.shiftCustomerAssignmentId !== rowId)];
    setCustomerAssignments(newArray);
  }

  // TODO : Finish this later - multiple time slots assignment
  function onAddRow({ rowId }) {
    const newShift: IShiftCustomerAssignment = {
      shiftCustomerAssignmentId: v4(), // temporarily assign a v4
      supportWorkerAttendanceId: '',
      attendanceId: '',
      startDateTime: moment(sessionStartDateTime).toDate(),
      endDateTime: moment(sessionEndDateTime).toDate(),
      description: '',
      isEditing: true,
      isNew: true,
    };

    setCustomerAssignments([...customerAssignments, newShift]);
  }

  // TODO : Finish this later - multiple time slots assignment
  function onDeleteRow({ rowId }) {
    const newArray = _.filter(customerAssignments, (a) => a.shiftCustomerAssignmentId !== rowId);
    setCustomerAssignments(newArray);
  }

  function onUpdateRow({ rowId, field, value }) {
    let row = _.find(customerAssignments, (a) => a.shiftCustomerAssignmentId === rowId);
    row[field] = value;
    const newArray = [row, ..._.filter(customerAssignments, (a) => a.shiftCustomerAssignmentId !== rowId)];
    setCustomerAssignments(newArray);
  }

  return (
    <ActionModal
      isOpen={isOpen}
      title={'Assign shift to customers'}
      onClose={onCancelAction}
      width="x-large"
      verticalAlignment={'highest'}
      canCloseOutside={canModalClose}
    >
      <div className="select-none">
        <div className="line-height-135">
          <div className="flex-1 mr-large mb-small">
            <FieldLabel text={'TEAM MEMBER'} />
            <div className="flex-row align-center mt-x-small">
              <Avatar shape={'square'} icon={'user'} className="mr-small" src={attachmentUrl} />
              <Text>
                {firstName} {lastName}
              </Text>
            </div>
          </div>
        </div>

        <Divider className="divider-medium" />

        <div>
          <div className={'mb-small'}>
            <FieldLabel text={'ASSIGNED CUSTOMERS'} />
          </div>

          {!hasAssignedCustomers && (
            <div className="mt-x-small">
              <Text color="secondary">No assigned customers.</Text>
            </div>
          )}

          {/* Customer card*/}
          {_.map(uniqueCustomers, (customerId, idx) => (
            <CustomerAssignmentCard
              assignments={_.filter(customerAssignments, (assignment) => assignment.customerUserId === customerId)}
              key={idx}
              customerId={customerId}
              pendingId={pendingId}
              customers={customersForSelecting}
              onRemoveAssignment={onRemoveAssignment}
              onSelectAssignment={onSelectAssignment}
              onCancelAssignment={onCancelAssignment}
              onSaveRow={onSaveRow}
              onAddRow={onAddRow}
              onEditRow={onEditRow}
              onDeleteRow={onDeleteRow}
              onUpdateRow={onUpdateRow}
            />
          ))}

          <div className="mt-x-small">
            <SecondaryButton size="small" icon="plus" onClick={() => onAddNewAssignment()}>
              Assign a customer
            </SecondaryButton>
          </div>
        </div>

        <ActionModalFooter align="right">
          <SecondaryButton size="large" className="mr-medium" onClick={onCancelAction}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={onSaveAction}>
            Save
          </PrimaryButton>
        </ActionModalFooter>
      </div>
    </ActionModal>
  );
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setResetSessionCustomers: dispatch.groupServiceStore.setResetSessionCustomers,
});

export default connect(null, mapDispatch)(AssignShiftToCustomersModal);
