import React from 'react';
import { Draggable, DroppableProvided } from 'react-beautiful-dnd';
import { ReactComponent as DragHandleIcon } from 'assets/Icons/form-builder-icons/drag-vertical.svg';
import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import { IFormElement } from '../shared/form-interface';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormElementType } from '../shared/form-enum';

type Props = {
  provided: DroppableProvided;
  addedListElements: IFormElement[];
  items: any;
};

export default function FormElementsList({ provided, items, addedListElements }: Props) {
  const addedElementNames = addedListElements.map((elementAdded) => elementAdded.name);
  const { conditionalFormElements } = useFlags();

  return (
    <div ref={provided.innerRef} {...provided.droppableProps}>
      {items
        .filter((item) => item.type !== FormElementType.CONDITIONAL || conditionalFormElements)
        .map((item, index: number) => (
          <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided, snapshot) => (
              <div className={item?.isIntakeForm && addedElementNames.includes(item.name) ? 'disabled dimmer' : ''}>
                <div
                  className={`item ${snapshot.isDragging && 'dragging'}`}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    ...provided.draggableProps.style,
                    transform: snapshot.isDragging ? provided.draggableProps.style.transform : 'translate(0px, 0px)',
                  }}
                >
                  <div className="align-center flex">
                    <Icon component={DragHandleIcon} />
                    <Text>{item.name}</Text>
                  </div>
                  {item.label}
                </div>

                {snapshot.isDragging && (
                  <div className={`item`} style={{ transform: 'none !important' }}>
                    <div className="align-center flex">
                      <Icon component={DragHandleIcon} />
                      <Text className="pl-x-small">{item.name}</Text>
                    </div>
                    {item.label}
                  </div>
                )}
              </div>
            )}
          </Draggable>
        ))}
    </div>
  );
}
