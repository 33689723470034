import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Thermometer = forwardRef(function Thermometer(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 5a3.75 3.75 0 1 1 7.5 0v7.641a5.75 5.75 0 1 1-7.5 0V5Zm1.183 8.612a4.25 4.25 0 1 0 5.133 0A.75.75 0 0 1 14.25 13V5a2.25 2.25 0 0 0-4.372-.75h.622a.75.75 0 0 1 0 1.5h-.75v1.5h.75a.75.75 0 0 1 0 1.5h-.75v1.5h.75a.75.75 0 0 1 0 1.5h-.75V13a.749.749 0 0 1-.317.612Z"
        fill="currentColor"
      />
    </Icon>
  );
});
