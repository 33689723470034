import React from 'react';
import { Text } from '../../../../../common-components/typography';
import { BudgetStatus } from '../../../../budgets/budget';
import { BudgetStatusLabel } from '../../../../budgets/components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { formatWeeksFrom } from '../../../../../utilities/date-utils';
import { useTranslation } from 'react-i18next';

export type ActiveServiceAgreementProps = {
  budgetTrackingStatus: BudgetStatus;
  activeServiceAgreementId: string;
  services: ServiceBudget[];
};

export type ServiceBudget = {
  serviceId: string;
  hasPermissionToViewBudgetingPage: boolean;
  serviceName: string;
  serviceType: string;
  startDate: string;
  endDate: string;
  budgetTrackingStatus: BudgetStatus;
};

const BudgetingDetailRow = (props: {
  budgetingDetails: ActiveServiceAgreementProps;
  customerId: string;
}): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'budget.budgetingPanel' });
  const { activeServiceAgreementId, services } = props.budgetingDetails;
  const customerId = props.customerId;
  const dateFormat = 'DD MMM YYYY'; // 13 Oct 2022

  return (
    <tbody>
      {services.map((service) => (
        <tr key="index" style={{ borderBottom: '0px solid !important', height: '50px' }} className="hover-bg-tertiary">
          <td>
            <div className="p-small">
              <Text>{service.serviceName}</Text>
            </div>
          </td>

          <td>
            <BudgetStatusLabel status={service.budgetTrackingStatus} size="small" />
          </td>
          <td>
            <div className="p-small">
              <Text>
                {`${moment(service.startDate).format(dateFormat)} - ${moment(service.endDate).format(dateFormat)}`}
              </Text>
            </div>
          </td>
          <td>
            <div className="p-small">
              <Text data-testid="time-elapsed-days">
                {formatWeeksFrom(moment() < moment(service.startDate) ? moment() : moment(service.startDate))}
              </Text>
            </div>
          </td>
          <td>
            <div className="p-small">
              <Text data-testid="ending-in-days">
                {formatWeeksFrom(moment() > moment(service.endDate) ? moment() : moment(service.endDate))}
              </Text>
            </div>
          </td>
          <td>
            <div className="p-small">
              {service.hasPermissionToViewBudgetingPage ? (
                <Link
                  to={`/customers/${customerId}/service-agreements/${activeServiceAgreementId}/budget/${service.serviceId}`}
                  className={'text-color-blue-action'}
                >
                  {t('view')}
                </Link>
              ) : (
                <Link to={'/'} style={{ pointerEvents: 'none' }} className={'text-color-gray'}>
                  {t('view')}
                </Link>
              )}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default BudgetingDetailRow;
