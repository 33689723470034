import { Text } from 'common-components/typography';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';
import { Col, Icon, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import Search from 'antd/es/input/Search';
import CommonUtils from 'utilities/common-utils';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import Utils from 'utilities/Utils';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { useDispatch, useSelector } from 'react-redux';
import { FilterType, TeamStatus } from 'utilities/enum-utils';
import { IFilter } from 'interfaces/filter-interfaces';
import { Tooltip2 } from '@blueprintjs/popover2';
import moment from 'moment-timezone';
import { CustomerAdditionalInfos } from 'common-components/tooltips';

type IFilterMenuSearchLookupProps = {
  filter: IFilter;
  canRemove?: boolean;
  saveNewFilterValue: (filterType: string, newFilterValue: string, selectionLabel: string) => void;
  removeFilter: (filterType: string) => void;
};

const FilterMenuSearchLookup = ({
  filter,
  canRemove,
  saveNewFilterValue,
  removeFilter,
}: IFilterMenuSearchLookupProps) => {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [localItemList, setLocalItemList] = useState([]);
  const [localSelectedItemList, setLocalSelectedItemList] = useState(filter ? _.clone(filter.values) : []);
  const [searchedItemList, setSearchedItemList] = useState([]);

  const {
    customersLite,
    workerList,
    batchAuthors,
    sessions,
    groupServiceSchedules,
    suburbs,
    caseManagers,
    supportCoordinators,
    debtors,
  } = useSelector((state: IRootState) => ({
    customersLite: state.customersStore.customersLite,
    workerList: state.teamStore.workerList,
    batchAuthors: state.billingsStore.batchAuthors,
    sessions: state.groupServiceStore.sessions,
    groupServiceSchedules: state.servicesStore.groupServiceSchedules,
    suburbs: state.bookingsStore.suburbs,
    caseManagers: state.customersStore.caseManagers,
    supportCoordinators: state.customersStore.supportCoordinators,
    debtors: state.accountStore.debtors,
  }));

  const {
    customersStore: {
      doGetCustomersLite,
      setCustomerFilterLite,
      setCustomersLite,
      setSupportCoordinators,
      setSupportCoordinatorFilter,
      doFetchSupportCoordinators,
      setCaseManagerFilter,
      setCaseManagers,
      doFetchCaseManagers,
    },
    teamStore: { doFetchWorkerList, setWorkerFilter, setWorkerList },
    billingsStore: { doFetchBatchAuthors, setBatchAuthorsFilter, setBatchAuthors },
    groupServiceStore: { doFetchSessions, setSessions },
    servicesStore: { doFetchGroupServiceSchedules, setGroupServiceSchedules },
    bookingsStore: { doFetchSuburbs, setSuburbs },
    accountStore: { doFetchDebtorList, setDebtors },
  } = useDispatch<IRootDispatch>();
  const addSelection = (item) => {
    const newFilter = _.clone(localSelectedItemList);
    newFilter.push(item);
    setLocalSelectedItemList([...newFilter]);
  };

  const closePopover = () => {
    setIsSearching(false);
    setLocalSelectedItemList(filter ? _.clone(filter.values) : []);
  };

  const searchText = async (txt) => {
    setIsSearching(true);
    if (filter.filter === FilterType.CUSTOMER) {
      await getCustomerList(txt);
    } else if (
      filter.filter === FilterType.WORKER ||
      filter.filter === FilterType.PREFERRED_SUPPORT_WORKER ||
      filter.filter === FilterType.BLOCKED_SUPPORT_WORKER
    ) {
      await getWorkerList(txt);
    } else if (filter.filter === FilterType.BATCH_AUTHOR) {
      await getBatchAuthorList(txt);
    } else if (filter.filter === FilterType.SERVICE_DATE_TIMES) {
      await getServiceDateTimeList(txt);
    } else if (filter.filter === FilterType.GROUP_SERVICE_SCHEDULES) {
      await getGroupServiceSchedule(txt);
    } else if (filter.filter === FilterType.LOCATION_BY_SUBURBS) {
      await getSuburbs(txt);
    } else if (filter.filter === FilterType.SUPPORT_COORDINATOR) {
      await getSupportCoordinators(txt);
    } else if (filter.filter === FilterType.CASE_MANAGER) {
      await getCaseManagers(txt);
    } else if (filter.filter === FilterType.DEBTOR) {
      await getDebtors(txt);
    }
    setIsSearching(false);
  };

  const debounceSearch = _.debounce(searchText, 500);

  const onEnterSearchText = (e) => {
    const searchLength = _.trim(e.target.value).length;
    if (searchLength >= 1) {
      debounceSearch(e.target.value);
    } else if (searchLength === 0) {
      setLocalItemList([]);
    }
  };

  const applySelection = async () => {
    saveNewFilterValue(
      filter.filter,
      localSelectedItemList,
      CommonUtils.getFilterText(filter.filter, localSelectedItemList, localItemList),
    );
  };

  const removeSelection = (value) => {
    setLocalSelectedItemList(_.filter(localSelectedItemList, (item) => item.value !== value));
  };

  const getCustomerList = async (searchTxt) => {
    if (Utils.isEmpty(searchTxt)) {
      setCustomerFilterLite({});
      setCustomersLite({});
      setSearchedItemList([]);
      return;
    }
    setCustomerFilterLite({ search: searchTxt });
    await doGetCustomersLite({ sortByRelevance: true });
  };

  const getWorkerList = async (searchTxt) => {
    if (Utils.isEmpty(searchTxt)) {
      setWorkerFilter({});
      setWorkerList({});
      setSearchedItemList([]);
      return;
    }
    setWorkerFilter([
      {
        key: 'search',
        value: searchTxt,
      },
      {
        key: 'supportWorkerStatus',
        value: [TeamStatus.ENABLED, TeamStatus.DRAFT, TeamStatus.BLOCKED],
      },
    ]);
    await doFetchWorkerList({ sortByRelevance: true });
  };

  const getBatchAuthorList = async (searchTxt) => {
    if (Utils.isEmpty(searchTxt)) {
      setBatchAuthorsFilter({});
      setBatchAuthors({});
      setSearchedItemList([]);
      return;
    }
    setBatchAuthorsFilter([{ key: 'search', value: searchTxt }]);
    await doFetchBatchAuthors({});
  };

  const getServiceDateTimeList = async (searchTxt) => {
    if (Utils.isEmpty(searchTxt)) {
      setSessions({});
      setSearchedItemList([]);
      return;
    }
    await doFetchSessions({ search: searchTxt, page: 1, pageSize: 100, pageTimestamp: new Date() });
  };

  const getGroupServiceSchedule = async (searchTxt) => {
    if (Utils.isEmpty(searchTxt)) {
      setGroupServiceSchedules({});
      setSearchedItemList([]);
      return;
    }
    await doFetchGroupServiceSchedules({ search: searchTxt, page: 1, pageSize: 100, pageTimestamp: new Date() });
  };

  const getSuburbs = async (searchTxt) => {
    if (Utils.isEmpty(searchTxt)) {
      setSuburbs({});
      setSearchedItemList([]);
      return;
    }
    await doFetchSuburbs({ searchString: searchTxt });
  };

  const getSupportCoordinators = async (searchTxt) => {
    if (Utils.isEmpty(searchTxt)) {
      setSupportCoordinatorFilter(null);
      setSupportCoordinators([]);
      setSearchedItemList([]);
      return;
    }
    setSupportCoordinatorFilter({ search: searchTxt });
    await doFetchSupportCoordinators({});
  };

  const getCaseManagers = async (searchTxt) => {
    if (Utils.isEmpty(searchTxt)) {
      setCaseManagerFilter(null);
      setCaseManagers([]);
      setSearchedItemList([]);
      return;
    }
    setCaseManagerFilter(searchTxt);
    await doFetchCaseManagers({});
  };

  const getDebtors = async (searchTxt) => {
    if (Utils.isEmpty(searchTxt)) {
      setDebtors([]);
      setSearchedItemList([]);
      return;
    }
    await doFetchDebtorList({ search: searchTxt });
  };

  useEffect(() => {
    setSearchedItemList(
      _.map(customersLite, (customer) => {
        return {
          name: customer.firstName + ' ' + customer.lastName,
          displayText: (
            <>
              {customer.firstName + ' ' + customer.lastName}
              <Text color={'secondary'}>{customer.locality && ' (' + customer.locality + ')'}</Text>
            </>
          ),
          value: customer.userId,
          tooltip: <CustomerAdditionalInfos customer={customer} getOnlyContent={true} />,
        };
      }),
    );
  }, [customersLite]);

  useEffect(() => {
    setSearchedItemList(
      _.map(workerList, (worker) => {
        return { displayText: worker.firstName + ' ' + worker.lastName, value: worker.supportWorkerId };
      }),
    );
  }, [workerList]);

  useEffect(() => {
    setSearchedItemList(
      _.map(batchAuthors, (portalUser) => {
        return { displayText: portalUser.firstName + ' ' + portalUser.lastName, value: portalUser.userId };
      }),
    );
  }, [batchAuthors]);

  useEffect(() => {
    setSearchedItemList(
      _.map(suburbs, (suburb) => {
        return {
          displayText: (
            <>
              {suburb.suburb} ({suburb.postcode}) - <Text color={'secondary'}>{suburb.state}</Text>
            </>
          ),
          value: suburb.postcode,
        };
      }),
    );
  }, [suburbs]);

  useEffect(() => {
    setSearchedItemList(
      _.map(debtors, (debtor) => {
        return {
          name: debtor.debtorName,
          displayText: <>{debtor.debtorName}</>,
          value: debtor.debtorId,
        };
      }),
    );
  }, [debtors]);

  useEffect(() => {
    setSearchedItemList(
      _.map(sessions, (session) => {
        return {
          displayText: (
            <>
              <Text>{moment.tz(session.startDateTime, session.timezone).format('DD/MM/YY')}</Text>{' '}
              <Text color={'secondary'}>
                {moment.tz(session.startDateTime, session.timezone).format('h:mm a') +
                  ' - ' +
                  moment.tz(session.endDateTime, session.timezone).format('h:mm a')}
              </Text>
              <br />
              <Text>
                {session.description ? session.description : session.scheduleName ? session.scheduleName : 'One-off'} -{' '}
                {session.serviceName}
              </Text>
            </>
          ),
          value: session.serviceDateTimeId,
          tooltip: (
            <div className={'p-medium'} style={{ minWidth: '300px' }}>
              <div className={'mb-medium'}>
                <Text size={'regular'} weight={'bold'} color={'white'}>
                  Session times
                </Text>
                <br />
                {moment
                  .tz(session.startDateTime, session.timezone)
                  .startOf('day')
                  .isSame(moment.tz(session.endDateTime, session.timezone).startOf('day')) ? (
                  <Text color={'white'}>
                    {moment.tz(session.startDateTime, session.timezone).format('ddd, DD MMM YYYY')}
                    <br />
                    {moment.tz(session.startDateTime, session.timezone).format('h:mm a - ')}
                    {moment.tz(session.endDateTime, session.timezone).format('h:mm a')}
                  </Text>
                ) : (
                  <Text color={'white'}>
                    {moment.tz(session.startDateTime, session.timezone).format('ddd, DD MMM YYYY h:mm a')}
                    <br />
                    {moment.tz(session.endDateTime, session.timezone).format('ddd, DD MMM YYYY h:mm a')}
                  </Text>
                )}
              </div>
              <div className={'mb-medium'}>
                <Text size={'regular'} weight={'bold'} color={'white'}>
                  Schedule
                </Text>
                <br />
                <Text color={'white'}>{session.scheduleName ? session.scheduleName : 'One-off'}</Text>
              </div>
              <div className={'mb-medium'}>
                <Text size={'regular'} weight={'bold'} color={'white'}>
                  Session description
                </Text>
                <br />
                <Text color={'white'}>{session.description ? session.description : '-'}</Text>
              </div>
              <div className={'mb-medium'}>
                <Text size={'regular'} weight={'bold'} color={'white'}>
                  Service
                </Text>
                <br />
                <Text color={'white'}>{session.serviceName}</Text>
              </div>
              <div className={'mb-medium'}>
                <Text size={'regular'} weight={'bold'} color={'white'}>
                  Location
                </Text>
                <br />
                <Text color={'white'} style={{ whiteSpace: 'pre-line' }}>
                  {session.address ? CommonUtils.formatFullAddress(session.address) : '-'}
                </Text>
              </div>
            </div>
          ),
        };
      }),
    );
  }, [sessions]);

  useEffect(() => {
    setSearchedItemList(
      _.map(groupServiceSchedules, (schedule) => {
        return {
          displayText: (
            <>
              <Text>
                {moment.tz(schedule.scheduleStartDate, schedule.timezone).format('DD/MM/YY')} -{' '}
                {moment.tz(schedule.scheduleEndDate, schedule.timezone).format('DD/MM/YY')}
              </Text>
              <br />
              <Text>
                {schedule.scheduleName ? schedule.scheduleName : 'One-off'} - {schedule.serviceName}
              </Text>
            </>
          ),
          value: schedule.serviceScheduleId,
          tooltip: (
            <div className={'p-medium'} style={{ minWidth: '300px' }}>
              <div className={'mb-medium'}>
                <Text size={'regular'} weight={'bold'} color={'white'}>
                  Schedule dates
                </Text>
                <br />
                <Text color={'white'}>
                  {moment.tz(schedule.scheduleStartDate, schedule.timezone).format('ddd, DD MMM YYYY')}
                  <br />
                  {moment.tz(schedule.scheduleEndDate, schedule.timezone).format('ddd, DD MMM YYYY')}
                </Text>
              </div>
              <div className={'mb-medium'}>
                <Text size={'regular'} weight={'bold'} color={'white'}>
                  Name
                </Text>
                <br />
                <Text color={'white'}>{schedule.scheduleName ? schedule.scheduleName : 'One-off'}</Text>
              </div>
              <div className={'mb-medium'}>
                <Text size={'regular'} weight={'bold'} color={'white'}>
                  Service
                </Text>
                <br />
                <Text color={'white'}>{schedule.serviceName}</Text>
              </div>
              <div className={'mb-medium'}>
                <Text size={'regular'} weight={'bold'} color={'white'}>
                  Location
                </Text>
                <br />
                <Text color={'white'} style={{ whiteSpace: 'pre-line' }}>
                  {schedule.address ? CommonUtils.formatFullAddress(schedule.address) : '-'}
                </Text>
              </div>
            </div>
          ),
        };
      }),
    );
  }, [groupServiceSchedules]);

  useEffect(() => {
    setSearchedItemList(
      _.map(caseManagers, (caseManager) => ({
        displayText: caseManager.fullName,
        value: caseManager.userContactId,
      })),
    );
  }, [caseManagers]);

  useEffect(() => {
    setSearchedItemList(
      _.map(supportCoordinators, (supportCoordinator) => ({
        displayText: supportCoordinator.fullName,
        value: supportCoordinator.supportCoordinatorId,
      })),
    );
  }, [supportCoordinators]);

  const filterSettings = CommonUtils.getFilterSettings(filter.filter);

  const filteredSearchedItemList = _.filter(
    searchedItemList,
    (filter) => !_.find(localSelectedItemList, (item) => filter.value === item.value),
  );

  return (
    <div aria-label="filter-search-menu" style={{ width: '800px' }}>
      <div className={'pv-small ph-medium'}>
        <Text size={'x-large'} weight={'bold'}>
          {filterSettings.title}
        </Text>
      </div>
      <Row>
        <Col span={12}>
          <div className="ph-medium pt-small bordered border-standard-gray" style={{ height: '42px' }}>
            <Text>
              Filtered {filterSettings.title} ({localSelectedItemList.length})
            </Text>
          </div>
          <div
            style={{ height: '200px', overflow: 'auto' }}
            className={' bordered-bottom bordered-right border-standard-gray'}
          >
            {localSelectedItemList && localSelectedItemList.length > 0 ? (
              _.map(localSelectedItemList, (item, key) => (
                <div
                  className={`flex-row justify-between pv-x-small ph-medium align-center ${
                    (filter.filter === FilterType.GROUP_SERVICE_SCHEDULES ||
                      filter.filter === FilterType.SERVICE_DATE_TIMES) &&
                    'bordered-bottom border-color-quaternary'
                  }`}
                  key={key}
                >
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '320px',
                    }}
                  >
                    {item.displayText}
                  </div>
                  {!_.isEmpty(item.tooltip) && (
                    <Tooltip2 content={item.tooltip} position={'bottom-right'}>
                      <div className={'cursor-help mh-small'}>
                        <Icon type={'info-circle'} className={'text-color-tertiary'} />
                      </div>
                    </Tooltip2>
                  )}
                  <div>
                    <HyperlinkButton
                      color={'blue-action'}
                      className={'border-blue-action'}
                      onClick={() => removeSelection(item.value)}
                    >
                      <Icon type={'close'} />
                    </HyperlinkButton>
                  </div>
                </div>
              ))
            ) : (
              <div className={'pv-large text-align-center'}>
                <Text color={'secondary'}>
                  No {filterSettings.title} selected.
                  <br /> Search and add on the right.
                </Text>
              </div>
            )}
          </div>
        </Col>
        <Col span={12}>
          <div className="pv-x-small ph-medium bordered-top bordered-bottom border-standard-gray">
            {filterSettings.canSearch && (
              <Search
                placeholder={filterSettings.searchPlaceHolder}
                onChange={onEnterSearchText}
                loading={isSearching}
                allowClear={true}
                autoFocus={true}
              />
            )}
          </div>
          <div
            style={{ height: '200px', overflow: 'auto' }}
            className={' bordered-bottom bordered-right border-standard-gray'}
          >
            {isSearching ? (
              <SpinningLoader size={50} message={''} />
            ) : filteredSearchedItemList && filteredSearchedItemList.length > 0 ? (
              _.map(filteredSearchedItemList, (item, key) => (
                <div
                  className={`flex-row justify-between pv-x-small ph-medium hover-bg-secondary cursor-pointer align-center ${
                    (filter.filter === FilterType.GROUP_SERVICE_SCHEDULES ||
                      filter.filter === FilterType.SERVICE_DATE_TIMES) &&
                    'bordered-bottom border-color-quaternary'
                  }`}
                  key={key}
                  onClick={() => addSelection(item)}
                >
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '320px',
                    }}
                  >
                    {item.displayText}
                  </div>
                  {!_.isEmpty(item.tooltip) && (
                    <Tooltip2 content={item.tooltip} position={'bottom-right'}>
                      <div className={'cursor-help mh-small'}>
                        <Icon type={'info-circle'} className={'text-color-tertiary'} />
                      </div>
                    </Tooltip2>
                  )}
                  <div>
                    <HyperlinkButton color={'blue-action'} className={'border-blue-action'}>
                      Add
                    </HyperlinkButton>
                  </div>
                </div>
              ))
            ) : searchedItemList && searchedItemList.length === 0 ? (
              <div className={'pv-large text-align-center'}>
                <Text color={'secondary'}>No result found.</Text>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
      <div className="p-medium">
        <div className={'flex-row justify-between'}>
          <div>
            {canRemove && (
              <GhostButton color={'red'} onClick={() => removeFilter(filter.filter)}>
                Remove filter
              </GhostButton>
            )}
          </div>
          <div>
            <SecondaryButton color={'blue-action'} className={'mr-medium bp3-popover-dismiss'} onClick={closePopover}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              color={'blue-action'}
              className={'bp3-popover-dismiss border-blue-action'}
              onClick={applySelection}
            >
              Apply
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterMenuSearchLookup;
