import { Avatar, Icon } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { HyperlinkButton } from 'common-components/buttons';
import { ConfirmDialog } from 'common-components/modal/Dialog';
import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import { state } from 'src/stores/rematch/root-store';
import AddCustomerModal from './AddCustomerModal';

interface Props {
  value?: typeof state.customersStore.customersLite;
  onChange?: (data: any) => void;
  form: WrappedFormUtils;
  [key: string]: any;
}

interface State {
  isOpenAddModal: boolean;
  isOpenConfirmDeleteModal: boolean;
  indexRemove: number;
}

class CustomerListingView extends Component<Props, State> {
  state = {
    isOpenAddModal: false,
    isOpenConfirmDeleteModal: false,
    indexRemove: -1,
  };

  private _onRemove = (index: number) => {
    const { onChange, value = [] } = this.props;
    onChange(value.filter((_e, i) => index != i));
    this._onCloseConfirmModal();
  };

  private _onAdd = (selected) => {
    const { onChange, value = [] } = this.props;
    onChange([...value, ...selected]);
    this._onCloseConfirmModal();
  };

  private _onCloseConfirmModal = () => {
    this.setState({ isOpenConfirmDeleteModal: false });
  };

  render() {
    const { value: data = [], form } = this.props;

    const customers = form.getFieldValue('customers');
    return (
      <div className="flex-column">
        <Text className="text-uppercase mb-12" weight="bolder" color="secondary" size="small">
          {'Selected customers'}
        </Text>

        <div className="bg-quaternary rounded-big p-medium bordered">
          <div className="flex-row flex-wrap">
            {data.length == 0 ? (
              <Text color="secondary">{'No customers involved...'}</Text>
            ) : (
              data.map((e: any, index) => (
                <div className="flex-row align-center mb-small" key={index} style={{ marginLeft: index == 0 ? 0 : 10 }}>
                  <Avatar icon={'user'} size={'small'} className={'mr-small'} shape={'circle'} src={e.avatar} />
                  <Text size="regular mh-small" weight="bold">
                    {e.displayName}
                  </Text>
                  <Icon
                    type={'close'}
                    className="text-color-red"
                    style={{ fontSize: 14 }}
                    onClick={() => this.setState({ isOpenConfirmDeleteModal: true, indexRemove: index })}
                  />
                </div>
              ))
            )}
          </div>
          <HyperlinkButton fontSize="small" onClick={() => this.setState({ ...this.state, isOpenAddModal: true })}>
            {'Add customers involved...'}
          </HyperlinkButton>
          <ConfirmDialog
            isOpen={this.state.isOpenConfirmDeleteModal}
            onClose={this._onCloseConfirmModal}
            onSubmit={() => this._onRemove(this.state.indexRemove)}
          />
          <AddCustomerModal
            isOpen={this.state.isOpenAddModal}
            onClose={() => this.setState({ ...this.state, isOpenAddModal: false })}
            onSuccess={this._onAdd}
            customersSelected={customers}
          />
        </div>
      </div>
    );
  }
}

export default CustomerListingView;
