import { tv } from 'tailwind-variants';

import type { VariantProps } from 'tailwind-variants';

export const statusMessage = tv(
  {
    base: ['inline-flex', 'gap-1'],
    variants: {
      tone: {
        critical: ['text-critical'],
        info: ['text-info'],
        neutral: ['text-neutral'],
        notice: ['text-notice'],
        positive: ['text-positive'],
      },
    },
  },
  { twMerge: false },
);

export const icon = tv({ base: ['inline-flex', 'items-center', 'justify-center', 'h-[22px]'] }, { twMerge: false });

type Variants = Required<VariantProps<typeof statusMessage>>;

export type StatusMessageVariants = {
  /**
   * Visually change the color and icon based on semantic meaning of
   * message.
   */
  tone: Variants['tone'];
};
