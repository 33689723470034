import React, { Component } from 'react';
import { IGroupServiceTimesheetSession, IGroupServiceTimesheetShiftSlot } from 'interfaces/service-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import { Col, notification, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';
import EditTimesheetRows from './EditTimesheetRows';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

interface IBulkEditTimesheetModalProps {
  isOpen: boolean;
  onClose: (isRefresh: boolean) => void;
  selectedShiftSlots: IGroupServiceTimesheetShiftSlot[];
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  doEditShiftSlotsStartEndTime: typeof dispatch.groupServiceStore.doEditShiftSlotsStartEndTime;
  selectedSession: IGroupServiceTimesheetSession;
}

interface IBulkEditTimesheetModalState {
  step: number;
  title: string;
  isSaving: boolean;
  canCloseOutside: boolean;
  localSelectedShiftSlots: IGroupServiceTimesheetShiftSlot[];
  isRefreshAfterClose: boolean;
  shiftSlotErrorIds: string[];
}

class BulkEditTimesheetModal extends Component<IBulkEditTimesheetModalProps, IBulkEditTimesheetModalState> {
  state = {
    step: 1,
    title: 'Edit timesheet',
    isSaving: false,
    canCloseOutside: true,
    localSelectedShiftSlots: [],
    isRefreshAfterClose: false,
    shiftSlotErrorIds: [],
  };

  private _onCloseModal = () => {
    this.props.onClose(this.state.isRefreshAfterClose);
    this.setState({
      step: 1,
      title: 'Edit timesheet',
      isSaving: false,
      canCloseOutside: true,
      isRefreshAfterClose: true,
    });
  };

  private _onValidateUpdateDateTime = (shiftSlotId: string, isError: boolean) => {
    if (_.isEmpty(shiftSlotId)) return;
    const newShiftSlotIds = _.cloneDeep(this.state.shiftSlotErrorIds);
    const shiftSlotIdExisted = !!_.find(newShiftSlotIds, (currentShiftSlotId) => currentShiftSlotId === shiftSlotId);

    if (isError) {
      if (shiftSlotIdExisted) return;
      newShiftSlotIds.push(shiftSlotId);
    } else {
      if (!shiftSlotIdExisted) return;
      _.remove(newShiftSlotIds, (currentShiftSlotId) => currentShiftSlotId === shiftSlotId);
    }

    this.setState({ shiftSlotErrorIds: newShiftSlotIds });
  };

  componentDidMount() {
    const { selectedShiftSlots } = this.props;

    this.setState({ localSelectedShiftSlots: _.cloneDeep(selectedShiftSlots) });
  }

  private _onSave = async () => {
    const { selectedGroupService, selectedSession, doEditShiftSlotsStartEndTime } = this.props;
    const { localSelectedShiftSlots } = this.state;
    this.setState({ isSaving: true, canCloseOutside: false });

    try {
      const payload = {
        serviceId: selectedGroupService.serviceId,
        sessionId: selectedSession.serviceDateTimeId,
        shiftSlots: _.map(localSelectedShiftSlots, (shiftSlot) => {
          return {
            startDateTime: shiftSlot.workerCheckedInDateTime,
            endDateTime: shiftSlot.workerCheckedOutDateTime,
            supportWorkerAttendanceId: shiftSlot.supportWorkerAttendanceId,
          };
        }),
      };

      await doEditShiftSlotsStartEndTime(payload);

      this.setState({
        isSaving: false,
        canCloseOutside: true,
        step: 2,
        title: 'Timesheet edited',
        isRefreshAfterClose: true,
      });
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
      this.setState({ isSaving: false, canCloseOutside: true });
    }
  };

  private _onUpdateShiftSlot = (shiftSlot: IGroupServiceTimesheetShiftSlot) => {
    const { localSelectedShiftSlots } = this.state;
    const newShiftSlot = _.map(localSelectedShiftSlots, (slot) => {
      if (slot.supportWorkerAttendanceId === shiftSlot.supportWorkerAttendanceId) {
        return shiftSlot;
      } else {
        return slot;
      }
    });

    this.setState({ localSelectedShiftSlots: newShiftSlot });
  };

  private _renderContent = () => {
    const { step } = this.state;
    const { selectedGroupService, selectedSession } = this.props;
    const { localSelectedShiftSlots, shiftSlotErrorIds } = this.state;
    if (step === 1) {
      return (
        <>
          <div>
            <div className="mb-large">
              <Text>Edit the timesheet for the following team member.</Text>
            </div>
            <div
              className="rounded-big bg-quaternary flex-row mb-large align-center"
              style={{ height: '56px', paddingLeft: '12px' }}
            >
              <Text weight="bold">
                {moment.tz(selectedSession.startDateTime, selectedGroupService.timezone).format('Do MMMM YYYY')}
              </Text>
            </div>
            <div className="rounded-big bordered">
              <Row className="bordered-bottom">
                <Col span={4} className="p-medium">
                  <FieldLabel text="TEAM MEMBER" />
                </Col>
                <Col span={7} className="p-medium">
                  <FieldLabel text="IN" />
                </Col>
                <Col span={7} className="p-medium">
                  <FieldLabel text="OUT" />
                </Col>
                <Col span={2} className="p-medium text-align-right">
                  <FieldLabel text="SCHEDULE" />
                </Col>
                <Col span={2} className="p-medium text-align-right">
                  <FieldLabel text="ACTUAL" />
                </Col>
                <Col span={2} className="p-medium text-align-right">
                  <FieldLabel text="DIFF." />
                </Col>
              </Row>
              {_.map(localSelectedShiftSlots, (shiftSlot, index) => {
                return (
                  <EditTimesheetRows
                    key={index}
                    timezone={selectedGroupService.timezone}
                    selectedShiftSlot={shiftSlot}
                    index={index}
                    updateShiftSlot={this._onUpdateShiftSlot}
                    onValidateUpdateDateTime={this._onValidateUpdateDateTime}
                  />
                );
              })}
            </div>
          </div>
          <ActionModalFooter className="mt-large">
            <SecondaryButton
              className="mr-medium"
              size="large"
              onClick={this._onCloseModal}
              disabled={this.state.isSaving}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size="large"
              onClick={this._onSave}
              loading={this.state.isSaving}
              disabled={!_.isEmpty(shiftSlotErrorIds)}
            >
              Save
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }

    if (step === 2) {
      return (
        <>
          <Paragraph>You've successfully edited the timesheet for this session.</Paragraph>
          <ActionModalFooter className="mt-large">
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }
  };

  render() {
    return (
      <div>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._onCloseModal}
          canCloseOutside={this.state.canCloseOutside}
          width="x3-large"
          className="p-small"
          title={this.state.title}
        >
          {this.props.selectedShiftSlots && this._renderContent()}
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedGroupService: state.groupServiceStore.selectedGroupService });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditShiftSlotsStartEndTime: dispatch.groupServiceStore.doEditShiftSlotsStartEndTime,
});

export default connect(mapState, mapDispatch)(BulkEditTimesheetModal);
