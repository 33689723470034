import React, { PropsWithChildren, useState } from 'react';
import { Form, Input, notification, Radio } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import RadioGroup from 'antd/lib/radio/group';
import { Box, compose, css, Inline, Stack } from '@goodhuman-me/components';
import { Text, Button } from '@good/components';
import { SupportWorker } from './work-details-panel';
import NumberInput from 'common-components/inputs/NumberInput';
import TooltipInfo from 'views/team-v2/team-details/components/TooltipInfo';
import { Announce, Modal, Select } from 'design-components';
import { useTranslation } from 'react-i18next';

const EmploymentTypeText = ({ type, otherType }: { type: string; otherType?: string }) => {
  const { t } = useTranslation('', { keyPrefix: 'teamMember.workDetails.employment' });

  if (!type) {
    return <Text>{t('types.NotSet')}</Text>;
  }

  if (type === 'Other' && otherType) {
    return <Text>{`${t('types.Other')} ${otherType}`}</Text>;
  }

  return <Text>{t(`types.${type}`)}</Text>;
};

type EmploymentDetailsReadonlyProps = {
  hasEditPermission?: boolean;
  onEditClick: () => void;
  supportWorker: SupportWorker;
};
const EmploymentDetailsReadonly = ({
  hasEditPermission = false,
  onEditClick,
  supportWorker,
}: EmploymentDetailsReadonlyProps) => {
  const { t } = useTranslation('', { keyPrefix: 'teamMember.workDetails.employment' });
  return (
    <Stack gap="$xlarge">
      <Inline width="100%" justifyContent="space-between" alignItems="center" gap="$small">
        <Text size="xl" className="font-bold" asChild>
          <h2>{t('heading')}</h2>
        </Text>
        <Box>
          {hasEditPermission && (
            <Button onClick={onEditClick} size="sm">
              {t('editButton')}
            </Button>
          )}
        </Box>
      </Inline>
      <Inline gap="$large" asChild>
        <div style={{ flexWrap: 'wrap' }}>
          <EmploymentDetailsFieldCard label={t('employmentType.label')}>
            <EmploymentTypeText type={supportWorker.employmentType} otherType={supportWorker.employmentTypeOther} />
          </EmploymentDetailsFieldCard>
          <EmploymentDetailsFieldCard label={t('contractedHours.label')}>
            {typeof supportWorker.contractedHours === 'number' ? (
              <Text>{t('contractedHours.week', { count: supportWorker.contractedHours })}</Text>
            ) : (
              '-'
            )}
          </EmploymentDetailsFieldCard>
        </div>
      </Inline>
    </Stack>
  );
};

const EmploymentDetailsFieldCard = ({ label, children }: PropsWithChildren<{ label: string }>) => (
  <Stack
    borderRadius="$medium"
    borderColor="$bodyLight2"
    borderWidth="$border200"
    borderStyle="solid"
    padding="$medium"
    gap="$xsmall"
    width="$size3600"
  >
    <Text className="font-semibold" size="md" asChild>
      <p>{label}</p>
    </Text>
    <Text size="md" asChild>
      <p>{children}</p>
    </Text>
  </Stack>
);

const EmploymentDetailsFormOption = ({ label, children }: PropsWithChildren<{ label: string | JSX.Element }>) => (
  <Stack gap="$xsmall">
    <Text className="font-semibold" size="md">
      {label}
    </Text>
    {children}
  </Stack>
);

interface EmploymentDetailsProps extends FormComponentProps {
  supportWorker: SupportWorker;
  hasEditPermission: boolean;
  isEditing: boolean;
  setEditing: (payload: boolean) => void;
  doUpdateTeamProfile: typeof dispatch.teamStore.doUpdateTeamProfile;
  doFetchSelectedWorker: typeof dispatch.teamStore.doFetchSelectedWorker;
}

const EmploymentDetails = ({
  supportWorker,
  form,
  hasEditPermission,
  doUpdateTeamProfile,
  doFetchSelectedWorker,
  isEditing,
  setEditing,
}: EmploymentDetailsProps) => {
  const { getFieldDecorator } = form;
  const [isActionModalOpen, setModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const isFormValid = (): boolean => {
    let areFieldsValid = true;

    form.validateFieldsAndScroll((err) => {
      if (err) {
        areFieldsValid = false;
      }
    });

    return areFieldsValid;
  };

  const areAddressesValid = (): boolean => {
    const hasAddresses = supportWorker.addresses.length > 0;

    // check address, make sure all address have required fields.
    const anyInvalidAddresses = supportWorker.addresses.some(
      (address) =>
        !address.streetAddress1 || !address.state || !address.postcode || !address.fullAddress || !address.locality,
    );

    return hasAddresses && !anyInvalidAddresses;
  };

  const onSave = async () => {
    if (!areAddressesValid()) {
      notification.error({
        message: 'Error',
        description: 'Team member must have a primary address before employment details can be changed',
      });
    }

    if (!isFormValid()) {
      return;
    }

    const payload = {
      ...supportWorker,
      // address field needs to be formatted correctly, otherwise API will throw validation errors
      addresses: supportWorker.addresses
        .map((address) => ({
          ...address,
          // doUpdateTeamProfile returns geoLat and geoLng values as strings, parsing just in case
          geoLat: Number(address?.geoLat) ?? 0,
          geoLng: Number(address?.geoLng) ?? 0,
        }))
        .sort((a, b) => {
          if (a.isPrimary === b.isPrimary) {
            return 0;
          }

          return a ? -1 : 1;
        }),
      employmentType: form.getFieldValue('employmentType'),
      employmentTypeOther:
        form.getFieldValue('employmentType') === 'Other' ? form.getFieldValue('employmentTypeOther') : null,
      contractedHours: form.getFieldValue('setContractedHours') ? form.getFieldValue('contractedHours') : null,
      // units can currently only be weekly (as of 2023-01-23), but that will change in the future
      contractedHoursUnit: form.getFieldValue('setContractedHours') ? 'weekly' : null,
    };
    setIsSaving(true);
    try {
      await doUpdateTeamProfile(payload);
      await doFetchSelectedWorker({ supportWorkerId: supportWorker.supportWorkerId });
      setEditing(false);
    } catch (e) {
      notification.error({ message: 'Error', description: e.message });
    }
    setIsSaving(false);
  };

  const discardChanges = () => {
    setModalOpen(false);
    setEditing(false);
  };

  const closeModal = () => {
    if (isEditing) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
      setEditing(false);
    }
  };

  const validateEmploymentTypeOther = (_, value, callback) => {
    if (!value) {
      callback(new Error('Please enter a description'));
      return;
    }

    callback();
  };

  const validateContractedHours = (_, value, callback) => {
    if (typeof value !== 'number') {
      callback(new Error('Please enter contracted hours'));
      return;
    }

    callback();
  };

  const { t } = useTranslation('', { keyPrefix: 'teamMember.workDetails.employment' });

  return (
    <Stack>
      {!isEditing ? (
        <EmploymentDetailsReadonly
          hasEditPermission={hasEditPermission}
          supportWorker={supportWorker}
          onEditClick={() => setEditing(true)}
        />
      ) : (
        <>
          <Modal
            maxHeight={602}
            maxWidth={600}
            isOpen={isActionModalOpen}
            onClose={() => setModalOpen(false)}
            header="Discard changes"
            footer={
              <Inline justifyContent="flex-end" gap="$space200">
                <Button size="md" tone="accent" emphasis="quiet" onClick={() => setModalOpen(false)}>
                  Cancel
                </Button>
                <Button size="md" tone="accent" emphasis="fill" onClick={discardChanges}>
                  Proceed
                </Button>
              </Inline>
            }
          >
            <Text>
              You have <b>unsaved data</b>, proceeding will discard these changes.
            </Text>
            <br />
            <Text>Do you want to proceed?</Text>
          </Modal>
          <Stack gap="$xlarge">
            <Inline width="100%" justifyContent="space-between">
              <Text className="font-bold" size="xl" asChild>
                <h3>{t('heading')}</h3>
              </Text>
              <Inline gap="$small">
                <Button size="sm" className="w-24" onClick={closeModal}>
                  {t('cancelButton')}
                </Button>

                <Button isDisabled={isSaving} onClick={onSave} emphasis="fill" size="sm" className="w-24">
                  {t('saveButton')}
                </Button>
              </Inline>
            </Inline>
            <Stack gap="$large">
              <EmploymentDetailsFormOption label="Employment type">
                <>
                  {getFieldDecorator('employmentType', { initialValue: supportWorker?.employmentType })(
                    <Select
                      placeholder="Select an employment type"
                      width="$size3600"
                      paddingY="$xsmall"
                      selectedKey={form.getFieldValue('employmentType')}
                      onSelectionChange={(val: string) => form.setFieldsValue({ employmentType: val })}
                    >
                      <Select.Item key="FullTime">{t('types.FullTime')}</Select.Item>
                      <Select.Item key="PartTime">{t('types.PartTime')}</Select.Item>
                      <Select.Item key="Casual">{t('types.Casual')}</Select.Item>
                      <Select.Item key="Volunteer">{t('types.Volunteer')}</Select.Item>
                      <Select.Item key="Other">{t('types.Other')}</Select.Item>
                    </Select>,
                  )}
                  {form.getFieldValue('employmentType')?.includes('Other') && (
                    <Inline width="$size3600">
                      <Form.Item className="mb-none" style={{ width: '100%' }}>
                        {getFieldDecorator('employmentTypeOther', {
                          initialValue: supportWorker?.employmentTypeOther,
                          rules: [{ validator: validateEmploymentTypeOther }],
                        })(
                          <Input
                            className={compose(
                              css({
                                fontFamily: '$primary',
                                marginBottom: 0,
                              }),
                            )}
                            placeholder="Please specify..."
                          />,
                        )}
                      </Form.Item>
                    </Inline>
                  )}
                </>
              </EmploymentDetailsFormOption>

              <EmploymentDetailsFormOption
                label={
                  <Inline gap="$xsmall" alignItems="center">
                    <Text size="md" className="font-semibold">
                      {t('contractedHours.label')}
                    </Text>
                    <TooltipInfo
                      isShow
                      placement="top center"
                      content={
                        <Inline alignItems="center">
                          <Announce color="$neutralDark1" width="$medium" height="$medium" />
                        </Inline>
                      }
                      message={
                        <Box width="$size2400" margin="$small">
                          {t('contractedHours.info')}
                        </Box>
                      }
                    />
                  </Inline>
                }
              >
                <Form.Item className="mb-none">
                  {getFieldDecorator('setContractedHours', {
                    initialValue: typeof supportWorker?.contractedHours === 'number' ?? false,
                  })(
                    <RadioGroup className="flex">
                      <Radio style={{ display: 'flex', alignItems: 'center' }} value={true}>
                        <Text asChild>
                          <p>{t('contractedHours.setHours')}</p>
                        </Text>
                      </Radio>
                      <Radio style={{ display: 'flex', alignItems: 'center' }} value={false}>
                        <Text asChild>
                          <p>{t('contractedHours.noHoursRequired')}</p>
                        </Text>
                      </Radio>
                    </RadioGroup>,
                  )}
                </Form.Item>
              </EmploymentDetailsFormOption>

              {form.getFieldValue('setContractedHours') && (
                <EmploymentDetailsFormOption label={t('contractedHours.labelUnit')}>
                  <Inline width="$size3600">
                    <Form.Item style={{ width: '100%' }} className="mb-none">
                      {getFieldDecorator('contractedHours', {
                        initialValue: supportWorker?.contractedHours ?? 0,
                        rules: [{ validator: validateContractedHours }],
                      })(
                        <NumberInput
                          className={compose(
                            css({
                              fontFamily: '$primary',
                            }),
                          )}
                          precision={0}
                          min={0}
                          placeholder="0"
                          max={Number.MAX_SAFE_INTEGER}
                          style={{ width: '100%' }}
                        />,
                      )}
                    </Form.Item>
                  </Inline>
                </EmploymentDetailsFormOption>
              )}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

const mapState = (state: IRootState) => ({
  companyDataLite: state.companyStore.companyDataLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateTeamProfile: dispatch.teamStore.doUpdateTeamProfile,
  doFetchDisabilityList: dispatch.customersStore.doFetchDisabilityList,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
  doFetchSelectedWorker: dispatch.teamStore.doFetchSelectedWorker,
});

export default connect(mapState, mapDispatch)(Form.create<EmploymentDetailsProps>()(EmploymentDetails));
