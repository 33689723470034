import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Award = forwardRef(function Award(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25a7.75 7.75 0 0 0-4.773 13.856l-.97 6.788a.75.75 0 0 0 1.022.802L12 20.808l4.721 1.888a.75.75 0 0 0 1.021-.802l-.97-6.788A7.75 7.75 0 0 0 12 1.25Zm3.382 14.725A7.718 7.718 0 0 1 12 16.75a7.718 7.718 0 0 1-3.382-.775l-.692 4.847 3.796-1.518a.75.75 0 0 1 .556 0l3.796 1.518-.692-4.847ZM5.75 9a6.25 6.25 0 1 1 12.5 0 6.25 6.25 0 0 1-12.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
