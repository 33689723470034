import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const GlassCocktail = forwardRef(function GlassCocktail(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.94 2.25H17.06c.821 0 1.521 0 2.047.077.533.078 1.149.271 1.458.89.31.62.095 1.228-.163 1.701-.253.467-.673 1.027-1.166 1.684l-.036.048-2.095 2.793a.713.713 0 0 1-.01.013L12.75 15.25v5H16a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1 0-1.5h3.25v-5L6.905 9.456a.738.738 0 0 1-.01-.013L4.8 6.65l-.036-.048c-.493-.657-.913-1.217-1.167-1.684-.257-.473-.472-1.081-.162-1.7.31-.62.925-.813 1.458-.891.526-.077 1.226-.077 2.047-.077ZM9 9.75l3 4 3-4H9Zm7.125-1.5h-8.25L6 5.75c-.539-.719-.89-1.19-1.085-1.548a1.145 1.145 0 0 1-.13-.31c.042-.02.135-.053.326-.08.403-.06.99-.062 1.889-.062h10c.898 0 1.486.002 1.89.061.19.028.283.061.324.082-.008.045-.037.14-.13.309-.194.358-.545.83-1.084 1.548l-1.875 2.5ZM4.757 3.913c-.001 0 .002-.004.01-.01l-.01.01Zm14.486 0s-.005-.003-.01-.01c.008.006.011.01.01.01Zm-.026-.042c-.002-.008-.002-.013-.001-.013 0 0 .002.003 0 .013Zm-14.434 0c0-.01 0-.014.001-.013 0 0 .001.005-.001.013Z"
        fill="currentColor"
      />
    </Icon>
  );
});
