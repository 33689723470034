import { Avatar, Icon } from 'antd';
import { Text } from 'common-components/typography';
import moment from 'moment-timezone';
import React from 'react';
import { IExtendedMessage, MessageType } from 'interfaces/message-interfaces';

import _ from 'lodash';

export function ReceivedMessage({ message }: { message: IExtendedMessage }) {
  const { content, createdOn, subscriber } = message;

  const { text } = content;

  const avatarUrl = subscriber && subscriber.attachmentUrl;

  // TODO : Group messages if there's only one.
  const isFirst = !_.isEmpty(message.previousMessage) && message.authorUserId !== message.previousMessage.authorUserId;

  const verticalPadding = isFirst ? 'pt-small pb-x2-small' : 'pv-x2-small';

  return (
    <div className={`ph-medium flex-row hover-bg-message-item ${verticalPadding}`}>
      <div className="mr-medium" style={{ width: '32px' }}>
        {isFirst && <Avatar icon="user" src={avatarUrl} />}
      </div>
      <div>
        {isFirst && (
          <div className="pb-x-small">
            <Text weight="bold" size="regular" color="black" className="select-none">
              {subscriber && subscriber.firstName} {subscriber && subscriber.lastName}
            </Text>
          </div>
        )}

        <div
          className="bg-tertiary pv-small rounded relative"
          style={{ maxWidth: '550px', paddingLeft: '12px', paddingRight: '12px', whiteSpace: 'pre-wrap' }}
        >
          {/*{isFirst && (*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      position: 'absolute',*/}
          {/*      height: '10px',*/}
          {/*      width: '10px',*/}
          {/*      borderRadius: '2px',*/}
          {/*      left: '-5px',*/}
          {/*      top: '6px',*/}
          {/*      transform: 'rotate(45deg)'*/}
          {/*    }}*/}
          {/*    className="bg-blue-lightest"*/}
          {/*  />*/}
          {/*)}*/}

          {/* Normal Message */}
          {message.messageType === MessageType.NewMessage && <Text>{text}</Text>}

          {/* Deleted Message */}
          {message.messageType === MessageType.DeleteMessage && (
            <div className="flex-row align-center">
              <Icon type="stop" className="text-color-tertiary text-size-regular" />
              <Text size="regular" color="tertiary" className="ml-x-small">
                This message has been deleted.
              </Text>
            </div>
          )}

          <div className="flex-row justify-end pt-x-small">
            <Text size="small" color="tertiary" className="select-none">
              {moment.tz(createdOn, message.preferredTimezone).format('hh:mm A')}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
