import React, { useRef } from 'react';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { Textarea, TextareaProps } from '@good/ui/core';

import { useInlineForm } from './inline-form-context';
import { StaticField } from './static-field';
import { OptionalLabel } from './optional-label';

export type InlineTextareaFieldProps<T extends FieldValues> = {
  children?: React.ReactNode;
  emptyValue?: string;
  label?: string;
  maxInputWidth?: React.CSSProperties['maxWidth'];
  name: Path<T>;
  rules?: Omit<RegisterOptions<T, Path<T>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  showOptional?: boolean;
  staticLabel?: string;
} & Omit<TextareaProps, 'name' | 'label'>;

export const InlineTextareaField = <T extends FieldValues>(props: InlineTextareaFieldProps<T>) => {
  const { control } = useFormContext<T>();
  const { isEditing, isSubmitting, maxWidth } = useInlineForm();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const {
    children,
    disabled,
    emptyValue,
    label,
    maxInputWidth,
    name,
    rules,
    showOptional,
    staticLabel,
    ...textAreaInputProps
  } = props;

  return (
    <div className='flex flex-col gap-2'>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
          return isEditing ? (
            <>
              <Textarea
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? ''}
                ref={(ref_) => {
                  ref(ref_);
                  inputRef.current = ref_;
                }}
                disabled={isSubmitting || disabled}
                label={label ? <OptionalLabel label={label} optional={showOptional} /> : undefined}
                style={{ maxWidth }}
                inputContainer={(children) => <div style={{ maxWidth: maxInputWidth }}>{children}</div>}
                error={error?.message}
                {...textAreaInputProps}
              />
            </>
          ) : (
            <StaticField label={staticLabel ?? label} value={value} emptyValue={emptyValue} />
          );
        }}
      />
      {children}
    </div>
  );
};
