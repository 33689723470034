import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ArrowTop = forwardRef(function ArrowTop(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.47 4.47a.75.75 0 0 1 1.06 0l6 6a.75.75 0 0 1-1.06 1.06l-4.72-4.72V19a.75.75 0 1 1-1.5 0V6.81l-4.72 4.72a.75.75 0 1 1-1.06-1.06l6-6Z"
        fill="currentColor"
      />
    </Icon>
  );
});
