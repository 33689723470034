import { Popover } from '@blueprintjs/core';
import { Avatar, Row } from 'antd';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { IconButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { IWorkflowComment } from 'interfaces/workflow-interfaces';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

interface IWorkflowCommentRowItemProps {
  onEditComment(): void;
  onDeleteComment(): void;
  commentDetail: IWorkflowComment;
  portalUser: typeof state.authStore.portalUser;
  isDisableAction: boolean;
}

class WorkflowCommentRowItem extends PureComponent<IWorkflowCommentRowItemProps> {
  render() {
    const { commentDetail, onEditComment, onDeleteComment, portalUser, isDisableAction } = this.props;

    return (
      <Row type="flex" align="top" className="m-small bg-white p-medium rounded-big bordered">
        <Avatar alt="avatar" shape="square" size="large" src={commentDetail.userAvatar} className="mr-small" />

        <div className="flex-column mr-x-large">
          <Text weight="bolder">{commentDetail.userName}</Text>
          <Text color="secondary">
            {moment.tz(commentDetail.createdOn, portalUser.timezone).format('D MMM YYYY, hh:mm A')}
          </Text>
        </div>

        <Row
          type="flex"
          align="top"
          className="flex-1 mr-small whitespace-pre-line"
          style={{ wordBreak: 'break-word' }}
        >
          <Text>{commentDetail.content}</Text>
        </Row>
        {commentDetail.userId === portalUser.userId && !isDisableAction && (
          <Popover
            position="bottom-right"
            content={
              <ActionMenu>
                <ActionMenuItem text="Edit" onClick={onEditComment} />
                <ActionMenuItem className="text-color-red" text="Delete" onClick={onDeleteComment} />
              </ActionMenu>
            }
          >
            <IconButton
              icon={'ellipsis'}
              size="default"
              iconColor={'blue-action'}
              color={'white'}
              bordered={true}
              className="border-standard-gray"
            />
          </Popover>
        )}
      </Row>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetPortalUserDetail: dispatch.authStore.doGetPortalUserDetail,
});

export default connect(mapState, mapDispatch)(WorkflowCommentRowItem);
