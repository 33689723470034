import React from 'react';
import { Box, Stack } from '@goodhuman-me/components';

import { ServiceBudgetingOverview } from '../service-budgeting-overview';
import { Categories } from '../categories';
import { Header } from '../header';
import { createBudget } from '../../../../budget';

import type { BudgetResponse } from '../../services';

type BudgetProps = BudgetResponse & {
  serviceId: string;
};

function _Budget(props: BudgetProps & { serviceId: string }): JSX.Element {
  const { serviceId } = props;
  const { budget, overview } = createBudget(serviceId, props);

  return (
    <Box height="100%" width="100%" backgroundColor="$white">
      <Stack gap="$space800">
        <Stack gap="$space600">
          <Header {...overview} />
          <ServiceBudgetingOverview budget={budget} service={overview.service} />
        </Stack>

        <Categories categories={budget.categories} service={overview.service} />
      </Stack>
    </Box>
  );
}

export { _Budget as Budget };
