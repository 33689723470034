import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const HeartSlash = forwardRef(function HeartSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.47 1.47a.75.75 0 0 1 1.06 0l3.511 3.51L16.73 15.668l4.802 4.802a.75.75 0 1 1-1.061 1.06l-4.272-4.271-3.002 2.998a1.697 1.697 0 0 1-2.392 0l-7.006-6.998a5.223 5.223 0 0 1 0-7.421 5.42 5.42 0 0 1 .521-.458L1.47 2.53a.75.75 0 0 1 0-1.06Zm3.922 4.983a3.91 3.91 0 0 0-.534.446 3.723 3.723 0 0 0 0 5.299l7.006 6.998c.073.072.2.072.272 0l3.002-2.998-9.746-9.745Zm13.75.446c-1.533-1.532-4.065-1.532-5.6 0L12.53 7.91a.75.75 0 0 1-1.06 0L10.458 6.9a3.9 3.9 0 0 0-1.669-.988.75.75 0 1 1 .422-1.44 5.434 5.434 0 0 1 2.307 1.366L12 6.32l.483-.482c2.12-2.117 5.6-2.117 7.72 0a5.223 5.223 0 0 1 0 7.42l-1.273 1.273a.75.75 0 1 1-1.06-1.062l1.273-1.271a3.723 3.723 0 0 0 0-5.299Z"
        fill="currentColor"
      />
    </Icon>
  );
});
