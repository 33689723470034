import React from 'react';
import { StaticImageValue } from './static-image-value';

import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { useInlineForm } from './inline-form-context';
import { InlineFieldInputWrapper } from './inline-field-input-wrapper';
import { OptionalLabel } from './optional-label';
import { ImageUploader } from '../image-uploader';

export type InlineImageFieldProps<T extends FieldValues> = {
  alt?: string;
  defaultImageUrl?: string;
  description?: string;
  disabled?: boolean;
  emptyValue?: string;
  imageUploadUrl: string;
  label?: string;
  maxInputWidth?: React.CSSProperties['maxWidth'];
  name: Path<T>;
  showOptional?: boolean;
};

export const InlineImageField = <T extends FieldValues>(props: InlineImageFieldProps<T>) => {
  const { control } = useFormContext();
  const { addAsyncTask, isEditing, isSubmitting, removeAsyncTask } = useInlineForm();
  const {
    alt,
    defaultImageUrl,
    description,
    disabled,
    emptyValue,
    imageUploadUrl,
    label,
    maxInputWidth,
    name,
    showOptional,
  } = props;

  if (!alt) {
    // eslint-disable-next-line no-console -- don't want to throw error
    console.warn('Image field is missing alt text.');
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return isEditing ? (
          <InlineFieldInputWrapper
            description={description}
            label={label ? <OptionalLabel label={label} optional={showOptional} /> : undefined}
            maxInputWidth={maxInputWidth}
            name={name}
            useFormMaxWidth={false}
            render={(labelledBy) => {
              return (
                <div className='pt-3'>
                  <ImageUploader
                    aria-labelledby={labelledBy}
                    disabled={isSubmitting || disabled}
                    imageUploadUrl={imageUploadUrl}
                    onChange={onChange}
                    onUploadFinish={() => removeAsyncTask(name)}
                    onUploadStart={() => addAsyncTask(name)}
                    value={value}
                  />
                </div>
              );
            }}
          />
        ) : (
          <StaticImageValue
            value={value ?? []}
            alt={alt ?? ''}
            emptyValue={emptyValue}
            defaultImageUrl={defaultImageUrl}
          />
        );
      }}
    />
  );
};
