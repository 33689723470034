import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { Form, notification } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/es/form';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

interface IReApproveLineItemActionModelProps extends FormComponentProps {
  isOpen: boolean;
  selectedBillingLineItem: typeof state.billingsStore.selectedBillingLineItem;
  onClose: () => void;
  doReApproveLineItems: typeof dispatch.billingsStore.doReApproveLineItems;
}

interface IReApproveLineItemActionModelState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

class ReApproveLineItemActionModel extends Component<
  IReApproveLineItemActionModelProps,
  IReApproveLineItemActionModelState
> {
  override state = {
    isLoading: false,
    isSuccess: false,
    isError: false,
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  private _onSubmitReApprove = async () => {
    const { selectedBillingLineItem, doReApproveLineItems } = this.props;

    const payload = {
      billingLineItems: [
        {
          bookingBillingLineItemId: selectedBillingLineItem.bookingBillingLineItemId,
          attendanceId: selectedBillingLineItem.attendanceId,
        },
      ],
    };

    try {
      this.setState({ isLoading: true });
      await doReApproveLineItems(payload)
      this.setState({ isLoading: false }, () => {
        this._onCloseModal();
      });
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Error', description: 'Oops. Something went wrong, please try again later' });
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        title="Re-approve line item"
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        <div className="text-align-left">
          <div className="mb-medium">
            <Paragraph>Are you sure you want to re-approve this line item.</Paragraph>
          </div>
          <Paragraph>Doing so will allow the line item to be re-processed</Paragraph>
        </div>
        <ActionModalFooter>
          <GhostButton size="large" onClick={this._onCloseModal} className="mr-medium" disabled={this.state.isLoading}>
            Not now
          </GhostButton>
          <PrimaryButton size="large" onClick={this._onSubmitReApprove} loading={this.state.isLoading}>
            Re-approve
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doReApproveLineItems: dispatch.billingsStore.doReApproveLineItems,
});

export default connect(mapState, mapDispatch)(ReApproveLineItemActionModel);
