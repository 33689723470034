import React, { Component } from 'react';
import { Icon } from 'antd';

interface ICareLevelSelectorProps {
  selectedLevel: string;
  changeLevel: (level) => void;
}

class CareLevelSelector extends Component<ICareLevelSelectorProps> {
  render() {
    const { selectedLevel, changeLevel } = this.props;

    return (
      <div className="flex-row">
        <div>
          <CareLevelSelectorItem alertLevel={'HIGH'} active={selectedLevel === 'HIGH'} changeLevel={changeLevel} />
        </div>
        <div className="ml-medium">
          <CareLevelSelectorItem alertLevel={'MEDIUM'} active={selectedLevel === 'MEDIUM'} changeLevel={changeLevel} />
        </div>
        <div className="ml-medium">
          <CareLevelSelectorItem alertLevel={'LOW'} active={selectedLevel === 'LOW'} changeLevel={changeLevel} />
        </div>
      </div>
    );
  }
}

interface ICareLevelSelectorItemProps {
  alertLevel: string;
  active: boolean;
  changeLevel: (level) => void;
}

class CareLevelSelectorItem extends Component<ICareLevelSelectorItemProps> {
  state = {};

  render() {
    const { alertLevel, active, changeLevel } = this.props;

    let levelText = '';
    let levelColor = 'secondary';

    if (alertLevel === 'HIGH') {
      levelText = 'High';
      levelColor = 'red';
    } else if (alertLevel === 'MEDIUM') {
      levelText = 'Medium';
      levelColor = 'orange';
    } else if (alertLevel === 'LOW') {
      levelText = 'Low';
      levelColor = 'green';
    }

    return (
      <div
        style={{ width: '130px' }}
        className={`ph-large pv-x-small rounded-full bordered text-align-center border-${levelColor} ${
          active
            ? 'bg-' + levelColor + ' text-color-white font-weight-bold'
            : 'bg-white cursor-pointer text-color-' + levelColor
        }`}
        onClick={() => changeLevel(alertLevel)}
      >
        {active && <Icon type="check" className="mr-x-small" />}
        {levelText}
      </div>
    );
  }
}

export default CareLevelSelector;
