import React from 'react';
import { Button, Text, Link } from '@good/components';
import { Cross } from '@good/icons';

import Card from 'views/account-management/WorkLeaveTypesConfigurationView/components/Card';

export type FilterCardPropsNever = {
  children?: never;
  isApplyDisabled?: never;
  onApply?: never;
  onClose: never;
  title?: never;
  onClear?: never;
  width?: never;
};

export type FilterCardProps = {
  children: React.ReactNode;
  isApplyDisabled?: boolean;
  onApply?: () => Promise<void>;
  onClose: () => void;
  title: string;
  onClear?: () => void;
  width?: number;
};

type FilterCardHeaderProps = Pick<FilterCardProps, 'title' | 'onClose'>;

const FilterCardHeader = ({ title, onClose }: FilterCardHeaderProps) => {
  return (
    <div className="p-medium flex justify-between">
      <Text className="font-bold">{title}</Text>
      <button onClick={onClose} className="flex h-fit">
        <Text className="text-weak">
          <Cross />
        </Text>
      </button>
    </div>
  );
};

type FilterCardFooterProps = Pick<FilterCardProps, 'onClear' | 'onClose' | 'onApply' | 'isApplyDisabled'>;

const FilterCardFooter = ({ onClear, onClose, onApply, isApplyDisabled }: FilterCardFooterProps) => {
  const onPressApply = async () => {
    onClose();
    await onApply?.();
  };

  const className = ['p-medium flex', onClear ? 'justify-between' : 'justify-end'].join(' ');

  return (
    <div className={className}>
      {onClear && (
        <button onClick={onClear}>
          <Link>
            <Text className="text-critical no-underline">Clear</Text>
          </Link>
        </button>
      )}
      <div className="inline-flex">
        <Button onPress={onClose} className="mr-3">
          Cancel
        </Button>
        <Button emphasis="fill" onPress={onPressApply} isDisabled={isApplyDisabled}>
          Apply
        </Button>
      </div>
    </div>
  );
};

const widthDefault = 322;

export const FilterCard = ({
  onClose,
  onApply,
  children,
  title,
  isApplyDisabled,
  onClear,
  width,
}: FilterCardProps): JSX.Element => {
  return (
    <Card width={width ?? widthDefault}>
      <input
        type="text"
        style={{ opacity: 0, height: 0, padding: 0, margin: 0, overflow: 'hidden' }}
        aria-hidden="true"
      />
      <FilterCardHeader title={title} onClose={onClose} />
      <div className="pl-medium pr-medium">{children}</div>
      <FilterCardFooter onClear={onClear} onClose={onClose} onApply={onApply} isApplyDisabled={isApplyDisabled} />
    </Card>
  );
};
