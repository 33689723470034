import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Weight = forwardRef(function Weight(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.948 1.25h.104c.899 0 1.648 0 2.242.08.628.084 1.195.27 1.65.725.456.456.642 1.023.726 1.65.08.595.08 1.345.08 2.243V6.65a8.75 8.75 0 1 1-9.5 0V5.948c0-.898 0-1.648.08-2.242.084-.628.27-1.195.725-1.65.456-.456 1.023-.642 1.65-.726.595-.08 1.345-.08 2.243-.08Zm3.236 2.655c.062.462.066 1.074.066 1.968A8.726 8.726 0 0 0 12 5.25a8.726 8.726 0 0 0-3.25.623c0-.894.004-1.506.067-1.968.062-.461.169-.659.3-.789.13-.13.327-.237.788-.3.483-.064 1.131-.066 2.095-.066.964 0 1.612.002 2.095.067.461.062.659.169.789.3.13.13.237.327.3.788ZM12 6.75a7.25 7.25 0 1 0 0 14.5 7.25 7.25 0 0 0 0-14.5ZM8.75 14a.75.75 0 0 0-1.5 0A4.75 4.75 0 0 0 12 18.75a.75.75 0 0 0 0-1.5A3.25 3.25 0 0 1 8.75 14Z"
        fill="currentColor"
      />
    </Icon>
  );
});
