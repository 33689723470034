import React from 'react';

import { Button, MantineModal as Modal, Text } from '@good/ui/core';
import { useTranslation } from 'react-i18next';
import { Service, reactTrpc } from 'utilities/react-trpc';

type IPublishServiceModalProps = {
  service: Service;
  onClose: () => void;
  isOpen: boolean;
};

export const PublishServiceModal = ({ service, onClose, isOpen }: IPublishServiceModalProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.header.actions.publish' });

  const publishEndpoint = reactTrpc.services.updateServiceStatus.publish.useMutation();

  const handleSubmit = async () => {
    await publishEndpoint.mutateAsync({
      serviceId: service.serviceId,
      serviceProviderId: service.serviceProviderId,
    });
    onClose();
  };

  return (
    <>
      <Modal.Root opened={isOpen} onClose={close} size='lg' yOffset='30vh'>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title fw='bold'>{t('name')}</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <div className='flex-column flex' style={{ gap: 4 }}>
              <div className=' items-center gap-4 py-'>
                <Text fw='600' color='var(--brand-body-dark-2)'>
                  {t('note')}
                </Text>
                <Text className='text-weak' my='xs'>
                  {t('description')}
                </Text>
              </div>
            </div>
            <div className='flex-row justify-end'>
              <form onSubmit={handleSubmit}>
                <div className='p-small justify-end'>
                  <Button
                    variant='transparent'
                    color='var(--brand-body-dark-2)'
                    className='mr-3'
                    onClick={onClose}
                    aria-label='Cancel'
                  >
                    {t('cancelLabel')}
                  </Button>
                  <Button aria-label='Submit' type='submit'>
                    {t('label')}
                  </Button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
