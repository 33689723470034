import { Tooltip } from 'antd';
import { FaGlobeAsia } from 'react-icons/fa';
import { Text } from 'common-components/typography';
import React from 'react';
import moment from 'moment-timezone';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { timeZone } from 'interfaces/timezone-type';

interface ITimezoneIndicatorProps {
  timezone?: timeZone;
  hasIcon?: boolean;
  showTzName?: boolean;
  showAbbr?: boolean;
  placement?: TooltipPlacement;
  type?: 'PREFERRED' | 'CUSTOM';
}

export default function TimezoneIndicator({
  timezone = moment.tz.guess(),
  hasIcon = true,
  showTzName = true,
  showAbbr = false,
  placement = 'top',
  type = 'PREFERRED',
}: ITimezoneIndicatorProps) {
  return (
    <Tooltip
      title={moment.tz(timezone).zoneAbbr() + ' ' + timezone + ' ' + moment.tz(timezone).format('Z')}
      placement={placement}
    >
      <div className="flex-column align-end">
        <div className="flex align-center select-none">
          {hasIcon && <FaGlobeAsia className="text-size-small text-color-tertiary mr-small" />}
          <Text color="secondary" weight="bold" className="text-size-x-small">
            {type === 'PREFERRED' ? 'Team member preferred timezone' : 'Custom timezone'}
          </Text>
        </div>
        <div className="flex justify-end">
          <Text color="secondary" className={`text-size-x-small ${(showTzName || hasIcon) && 'ml-small'} mr-small`}>
            UTC
            {moment.tz(timezone).format('Z')}
          </Text>
        </div>
      </div>
    </Tooltip>
  );
}
