import React, { Component } from 'react';
import { Col, Icon, Row, Avatar } from 'antd';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { connect } from 'react-redux';
import { PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';

import { IAddCustomerGuardianDetailWizard, ICustomer } from 'src/interfaces/customer-interfaces';
import { IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

interface IAddLegalGuardianSuccessStepProps {
  addLegalGuardianDetailWizard: typeof state.customersStore.addLegalGuardianDetailWizard;
  selectedCustomer: ICustomer;
  resetAndCloseModal: () => void;
}

interface IAddLegalGuardianSuccessStepState {
  guardians: IAddCustomerGuardianDetailWizard[];
}

class AddLegalGuardianSuccessStep extends Component<
  IAddLegalGuardianSuccessStepProps,
  IAddLegalGuardianSuccessStepState
> {
  state = {
    guardians: this.props.addLegalGuardianDetailWizard,
  };
  render() {
    const { guardians } = this.state;
    const { selectedCustomer } = this.props;

    return (
      <>
        <div className="anim-slide-left pt-x3-large">
          <Row className="ph-x3-large">
            <Col span={6} style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div>
                <Paragraph size="x3-large" weight="bold">
                  Done
                </Paragraph>
                <Paragraph>You have successfully added legal guardians!</Paragraph>
              </div>
            </Col>
            <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
                <div className="mb-large">
                  <Title className="text-color-blue-action mb-large" level={4}>
                    <Icon className="mr-12 text-size-x4-large" type={'check-circle'} theme={'filled'} />
                    Legal guardians successfully added
                  </Title>
                </div>
                <div className="mb-large">
                  <SubTitle>Customer</SubTitle>
                  <div className="flex-row align-center pt-small">
                    <Avatar size="large" icon="user" className="mr-medium" src={selectedCustomer.attachmentUrl} />
                    <Text weight="bold">{selectedCustomer.firstName + ' ' + selectedCustomer.lastName}</Text>
                  </div>
                </div>

                <div className="mb-x5-large">
                  <SubTitle>Legal Guardian</SubTitle>
                  {_.map(guardians, (guardian) => {
                    return (
                      <div className="flex-row align-center pt-small">
                        <Avatar size="large" icon="user" className="mr-medium" src={guardian.attachmentUrl} />
                        <Text weight="bold">{guardian.firstName + ' ' + guardian.lastName}</Text>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="pv-medium width-full" style={{ position: 'sticky', bottom: 0 }}>
                <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                  <Col className="bg-transparent" span={24}>
                    <div className="text-align-right pv-medium">
                      <PrimaryButton size="large" onClick={this.props.resetAndCloseModal}>
                        Close
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  addLegalGuardianDetailWizard: state.customersStore.addLegalGuardianDetailWizard,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddLegalGuardianDetailWizard: dispatch.customersStore.setAddLegalGuardianDetailWizard,
});

export default connect(mapState, mapDispatch)(AddLegalGuardianSuccessStep);
