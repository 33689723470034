import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Link3 = forwardRef(function Link3(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 4A.75.75 0 0 1 4 3.25h16a.75.75 0 0 1 .75.75v16a.75.75 0 0 1-.75.75H4a.75.75 0 0 1-.75-.75V4Zm1.5.75v6.5h6.5v-6.5h-6.5Zm8 0v6.5h6.5v-6.5h-6.5Zm6.5 8h-6.5v6.5h6.5v-6.5Zm-8 6.5v-6.5h-6.5v6.5h6.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
