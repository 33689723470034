import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const PlayPrevious = forwardRef(function PlayPrevious(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.307 6.697c.705-.47 1.164-.774 1.51-.937.166-.079.256-.1.297-.105.02.037.051.124.078.306.056.378.058.928.058 1.776v8.526c0 .848-.002 1.398-.058 1.776a1.049 1.049 0 0 1-.078.306 1.045 1.045 0 0 1-.297-.105c-.346-.163-.805-.466-1.51-.937L9.912 13.04c-.585-.39-.952-.637-1.183-.846a.878.878 0 0 1-.17-.189L8.554 12l.003-.005a.878.878 0 0 1 .171-.189c.231-.21.598-.456 1.183-.846l6.395-4.263Zm1.837-1.041-.012-.002c.01 0 .013.001.012.002Zm-.038-.016c-.007-.005-.01-.009-.009-.01l.009.01Zm-.009 12.73c0-.001.002-.005.009-.01l-.009.01Zm.034-.024.013-.002s-.003.002-.012.002Zm.695-14.02c-.587-.314-1.179-.145-1.65.078-.458.216-1.01.584-1.652 1.012l-.05.033L9.08 9.712l-.046.03c-.525.35-.987.659-1.31.951-.345.311-.671.726-.671 1.307s.326.996.67 1.307c.324.293.786.6 1.311.95l.046.031 6.395 4.263.05.033c.641.428 1.193.796 1.651 1.012.471.223 1.063.393 1.65.078.587-.314.774-.9.85-1.415.074-.502.074-1.165.074-1.936V7.677c0-.771 0-1.434-.074-1.936-.076-.515-.263-1.101-.85-1.415ZM4.75 5a.75.75 0 1 0-1.5 0v14a.75.75 0 0 0 1.5 0V5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
