import { Slot } from '@radix-ui/react-slot';
import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';

import * as styles from './inline.css';
import { boxStyleProps, compose, css, useStyleProps } from '..';

import type { BoxStyleProps, HTMLProps, SlotProps } from '..';
import type { PropsWithChildren } from 'react';

export type InlineProps = SlotProps &
  HTMLProps<HTMLDivElement> &
  BoxStyleProps &
  PropsWithChildren<{
    collapseAt?: string;
  }>;

/**
 * @deprecated
 */
export const Inline = forwardRef<HTMLDivElement, InlineProps>(function Inline(props, ref) {
  const { styleProps, ...otherProps } = useStyleProps(props, boxStyleProps);
  const { asChild, children, collapseAt } = props;
  const ElementType = asChild ? Slot : 'div';

  return (
    <ElementType
      {...filterDOMProps(otherProps)}
      className={compose(
        css(styles.reset, styles.inline, styleProps, {
          [`@media(max-width: ${collapseAt})`]: {
            flexDirection: 'column',
          },
        }),
      )}
      ref={ref}
    >
      {children}
    </ElementType>
  );
});
