import React, { Component } from 'react';
import AlertLevelTag from 'common-components/tags/AlertLevelTag';
import { Text } from 'common-components/typography';
import { Col, Divider, Icon, Row } from 'antd';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import moment from 'moment-timezone';
import Animate from 'rc-animate';
import { AlertLevel, CareInformationType } from 'utilities/enum-utils';
import { ICareInfo } from 'src/interfaces/customer-interfaces';
import { connect } from 'react-redux';
import { IRootState, state } from 'src/stores/rematch/root-store';

interface ICareInformationRowProps {
  key?: any;
  openedRow?: string;
  careInformation: ICareInfo | any;
  handleRowSlider: (itemId) => void;
  openDeleteItemModal: (itemId) => void;
  openCreateItemEditModal: (type: CareInformationType | any, item?: ICareInfo) => void;
  hasEditPermission?: boolean;
  portalUser: typeof state.authStore.portalUser;
}

interface ICareInformationRowState {}

class CareInformationRow extends Component<ICareInformationRowProps, ICareInformationRowState> {
  private _getItemData = () => {
    const { careInformation } = this.props;

    let itemName = null;
    let itemId = null;
    let careInformationTypeName = null;

    if (careInformation.careInformationType === CareInformationType.PERMANENT || careInformation.disabilityInfoId) {
      itemName = careInformation.disabilityName;
      itemId = careInformation.disabilityInfoId;
      careInformationTypeName = 'Permanent Condition';
    } else if (
      careInformation.careInformationType === CareInformationType.MEDICATION ||
      careInformation.medicalConditionId
    ) {
      itemName = careInformation.medicationName;
      itemId = careInformation.medicalConditionId;
      careInformationTypeName = 'Medications';
    } else if (
      careInformation.careInformationType === CareInformationType.SUPPORTNEEDS ||
      careInformation.supportNeedId
    ) {
      itemName = careInformation.supportNeedName;
      itemId = careInformation.supportNeedId;
      careInformationTypeName = 'Support Needs';
    } else if (
      careInformation.careInformationType === CareInformationType.GENERAL ||
      careInformation.sensitivityInfoId
    ) {
      itemName = careInformation.sensitivityName;
      itemId = careInformation.sensitivityInfoId;
      careInformationTypeName = 'General Information';
    } else if (
      careInformation.careInformationType === CareInformationType.BEHAVIOUR ||
      careInformation.behaviouralInfoId
    ) {
      itemName = careInformation.behaviourName;
      itemId = careInformation.behaviouralInfoId;
      careInformationTypeName = 'Behaviour';
    }

    return { itemName, itemId, careInformationTypeName };
  };

  render() {
    const {
      openedRow,
      careInformation,
      handleRowSlider,
      openDeleteItemModal,
      openCreateItemEditModal,
      hasEditPermission,
      portalUser,
    } = this.props;

    const item = this._getItemData();
    const isOpen = careInformation.isOpen || openedRow === careInformation.careInformationId;
    return (
      <Animate key={item.itemId} transitionName='fade' transitionAppear>
        <Row
          className={`mb-large ${
            isOpen ? 'bg-white shadow-box' : 'bg-quaternary'
          } bordered border-standard-gray p-large`}
          style={{ borderRadius: '8px' }}
        >
          <Row type='flex' align='middle'>
            <Col span={3}>
              {careInformation.alertLevel && (
                <AlertLevelTag
                  alertLevel={
                    careInformation.name ? AlertLevel[careInformation.alertLevel] : careInformation.alertLevel
                  }
                />
              )}
            </Col>
            <Col span={18} className='text-weight-bold'>
              <div>
                <Text size='small' weight='bold' className='text-color-secondary'>
                  {item.careInformationTypeName}
                </Text>
                <br />
                <Text size='x2-large'>{careInformation.name}</Text>
              </div>
            </Col>
            <Col span={3} className='text-align-right'>
              <PrimaryButton
                color='standard-gray'
                className={`text-color-secondary ${
                  isOpen ? 'bg-quaternary' : 'bg-white'
                } shadow-none border-standard-gray`}
                icon={isOpen ? 'up' : 'down'}
                iconPosition='right'
                onClick={() => handleRowSlider(item.itemId || careInformation.careInformationId)}
              >
                {isOpen ? 'Hide' : 'Show'}
              </PrimaryButton>
            </Col>
          </Row>
          {isOpen && (
            <Row>
              <Col span={3} />
              <Col span={18}>
                <Divider className='divider-medium' />
                {careInformation.additionalInfo.treatmentFor && (
                  <div className='mb-x-large'>
                    <b>For the treatment of</b>
                    <br />
                    {careInformation.additionalInfo.treatmentFor}
                  </div>
                )}
                {careInformation.description && (
                  <div className='mb-x-large'>
                    <b>Description</b>
                    <br />
                    <Text style={{ whiteSpace: 'pre-wrap' }}>{careInformation.description}</Text>
                  </div>
                )}
                {careInformation.additionalInfo.managementStrategy && (
                  <div className='mb-x-large'>
                    <b>Management Strategy</b>
                    <br />
                    <Text style={{ whiteSpace: 'pre-wrap' }}>{careInformation.additionalInfo.managementStrategy}</Text>
                  </div>
                )}
                <div>
                  <Text color='secondary' size='regular'>
                    Last edited on {moment.tz(careInformation.updatedOn, portalUser.timezone).format('DD/MM/YYYY')}
                  </Text>
                </div>
                <div className='flex-row justify-between mt-large'>
                  {hasEditPermission && (
                    <div>
                      <SecondaryButton
                        className='mr-small'
                        onClick={() => openCreateItemEditModal(careInformation.careInformationType, careInformation)}
                      >
                        <Icon type='edit' theme='filled' />
                        Edit
                      </SecondaryButton>
                      <SecondaryButton color='red' onClick={() => openDeleteItemModal(careInformation)}>
                        <Icon type='delete' theme='filled' />
                        Delete
                      </SecondaryButton>
                    </div>
                  )}
                  <div>
                    <HyperlinkButton
                      color={'secondary'}
                      onClick={() => handleRowSlider(careInformation.careInformationId)}
                    >
                      Hide Details
                      <Icon type='up' className='ml-small' />
                    </HyperlinkButton>
                  </div>
                </div>
              </Col>
              <Col span={3} />
            </Row>
          )}
        </Row>
      </Animate>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

export default connect(mapState)(CareInformationRow);
