import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Glasshour = forwardRef(function Glasshour(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.946 3.863C15.05 3.751 13.784 3.75 12 3.75H9.364c-.646 0-1.103 0-1.444.016-.356.017-.496.05-.549.07A1.25 1.25 0 0 0 6.682 5.5c.023.052.1.175.339.438.23.252.553.576 1.01 1.033l2.25 2.25a.75.75 0 1 1-1.061 1.06L6.97 8.03l-.015-.014c-.439-.44-.788-.788-1.044-1.07-.25-.275-.475-.553-.604-.848a2.75 2.75 0 0 1 1.515-3.657c.3-.118.655-.155 1.026-.173.381-.018.875-.018 1.495-.018h2.715c1.713 0 3.073 0 4.073.124.983.123 1.893.391 2.38 1.182.155.25.269.524.337.81.214.905-.24 1.738-.848 2.519-.62.796-1.581 1.757-2.793 2.969l-.04.04-5.273 5.272c-1.261 1.262-2.155 2.158-2.71 2.87-.574.738-.615 1.07-.572 1.252.03.13.082.255.153.369.098.159.362.365 1.289.48.897.112 2.162.113 3.946.113h2.636c.646 0 1.103 0 1.444-.017.356-.017.496-.049.549-.07a1.25 1.25 0 0 0 .689-1.662c-.023-.052-.1-.175-.339-.438-.23-.252-.552-.576-1.01-1.033l-2.25-2.25a.75.75 0 1 1 1.061-1.06l2.25 2.25.015.014c.439.44.788.788 1.044 1.07.25.275.475.553.604.848a2.75 2.75 0 0 1-1.515 3.657c-.3.118-.655.155-1.026.173-.381.018-.875.018-1.495.018h-2.715c-1.713 0-3.073 0-4.073-.124-.983-.123-1.893-.391-2.38-1.182a2.749 2.749 0 0 1-.337-.81c-.214-.905.24-1.738.848-2.519.62-.796 1.581-1.757 2.793-2.968l.04-.041 5.273-5.272c1.261-1.262 2.155-2.158 2.71-2.87.574-.738.615-1.07.572-1.252a1.252 1.252 0 0 0-.153-.369c-.098-.159-.362-.365-1.289-.48Z"
        fill="currentColor"
      />
    </Icon>
  );
});
