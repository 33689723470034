import React, { LabelHTMLAttributes, PropsWithChildren, forwardRef } from 'react';
import clsx from 'clsx';

export type LabelProps = PropsWithChildren<LabelHTMLAttributes<HTMLLabelElement>>;

/**
 * @name
 * Label
 *
 * @description
 * Label form inputs
 *
 * @example
 * <Label htmlFor="fullName">Full name</Label>
 */
export const Label = forwardRef<HTMLLabelElement, LabelProps>(function Label(props, ref) {
  let { className, ...otherProps } = props;

  return (
    <label
      {...otherProps}
      className={clsx('text-color-gray-darker text-uppercase text-weight-bold block text-size-small', className)}
      ref={ref}
    />
  );
});
