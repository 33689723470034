import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React, { PureComponent } from 'react';

interface Props {
  isOpen: boolean;
  isDeleting: boolean;
  onClose(): void;
  onDelete(): void;
}

class DeleteWorkflowAttachmentModal extends PureComponent<Props> {
  render() {
    const { isOpen, isDeleting, onClose, onDelete } = this.props;

    return (
      <ActionModal isOpen={isOpen} title="Delete attachment" width="medium" onClose={onClose}>
        <Paragraph>Are you sure you want to delete this attachment?</Paragraph>
        <ActionModalFooter className="mt-x-large">
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={onDelete} loading={isDeleting}>
            Delete
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default DeleteWorkflowAttachmentModal;
