import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const WebcamSlash = forwardRef(function WebcamSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.47 1.47a.75.75 0 0 1 1.06 0l3.782 3.782 9.436 9.436 6.782 6.782a.75.75 0 1 1-1.06 1.06l-.833-.832a2.647 2.647 0 0 1-.607.588c-.698.466-1.468.465-2.416.464H6.386c-.948.001-1.718.002-2.416-.464a2.829 2.829 0 0 1-.739-.772 2.828 2.828 0 0 1-.43-.98c-.162-.82.086-1.417.387-2.14l.036-.086c.578-1.392 1.507-2.969 2.99-4.153A7.721 7.721 0 0 1 4.25 9c0-1.086.224-2.121.628-3.061L1.47 2.53a.75.75 0 0 1 0-1.06ZM7.33 15.187c-1.319.982-2.175 2.38-2.722 3.696-.346.834-.403 1.023-.336 1.36.018.093.085.257.206.438.12.18.246.305.325.358.276.184.558.211 1.732.211h10.928c1.175 0 1.457-.027 1.732-.21.092-.062.237-.213.364-.42l-4.499-4.498a7.727 7.727 0 0 1-3.06.628 7.716 7.716 0 0 1-4.67-1.563ZM6.043 7.103a6.25 6.25 0 0 0 7.854 7.854L6.043 7.103ZM12 2.75a6.218 6.218 0 0 0-3.57 1.12.75.75 0 1 1-.859-1.23 7.75 7.75 0 0 1 10.79 10.79.75.75 0 0 1-1.23-.86A6.25 6.25 0 0 0 12 2.75ZM11.25 7a.75.75 0 0 1 .75-.75A2.75 2.75 0 0 1 14.75 9a.75.75 0 0 1-1.5 0c0-.69-.56-1.25-1.25-1.25a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
