import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FolderCross = forwardRef(function FolderCross(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2.25h-.116c-.818 0-1.376 0-1.855.128a3.75 3.75 0 0 0-2.651 2.651c-.128.48-.128 1.037-.128 1.855V14.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116h8.11c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337V11.945c0-1.367 0-2.47-.116-3.337-.122-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117h-.934c-.907 0-1.216-.007-1.495-.086a2.249 2.249 0 0 1-.477-.198c-.254-.14-.477-.355-1.119-.996l-.07-.072c-.544-.543-.92-.92-1.38-1.175a3.751 3.751 0 0 0-.795-.33C9.28 2.25 8.748 2.25 7.98 2.25H6ZM4.418 3.827c.262-.07.604-.077 1.582-.077h1.879c.907 0 1.216.007 1.495.086.166.048.326.114.477.198.254.14.477.355 1.119.996l.07.072c.544.543.92.92 1.38 1.175.251.14.518.25.795.33.505.143 1.037.143 1.806.143H16c1.436 0 2.437.002 3.192.103.734.099 1.122.28 1.399.556.277.277.457.665.556 1.4.102.754.103 1.756.103 3.191v2c0 1.435-.002 2.436-.103 3.192-.099.734-.28 1.122-.556 1.399-.277.277-.665.457-1.4.556-.754.101-1.755.103-3.191.103H8c-1.435 0-2.436-.002-3.192-.103-.734-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191V7c0-.978.006-1.32.077-1.582a2.25 2.25 0 0 1 1.59-1.591ZM10.03 9.97a.75.75 0 0 0-1.06 1.06L10.94 13l-1.97 1.97a.75.75 0 1 0 1.06 1.06L12 14.06l1.97 1.97a.75.75 0 0 0 1.06-1.06L13.06 13l1.97-1.97a.75.75 0 1 0-1.06-1.06L12 11.94l-1.97-1.97Z"
        fill="currentColor"
      />
    </Icon>
  );
});
