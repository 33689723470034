import { tv } from 'tailwind-variants';

import type { VariantProps } from 'tailwind-variants';

export const text = tv(
  {
    base: ['font-base', 'font-normal', 'm-0', 'text-inherit', 'word-break'],
    variants: {
      size: {
        xl: ['leading-b-xl', 'text-b-xl'],
        lg: ['leading-b-lg', 'text-b-lg'],
        md: ['leading-b-md', 'text-b-md'],
        sm: ['leading-b-sm', 'text-b-sm'],
        xs: ['leading-b-xs', 'text-b-xs'],
        '2xs': ['leading-b-2xs', 'text-b-2xs'],
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  },
  { twMerge: false },
);

type Variants = Required<VariantProps<typeof text>>;

export type TextVariants = {
  /**
   * Change the `font-size` and `line-height` properties along our typographic scale.
   * @default sm
   */
  size?: Variants['size'];
};
