import React from 'react';
import { Popover as PopoverAntd, Icon } from 'antd';
import { Text } from '@good/components';
import { Info } from '@good/icons';

type IPopoverProps = {
  title?: string | JSX.Element;
  content: string;
};

const Popover = ({ title, content }: IPopoverProps): JSX.Element => {
  return (
    <PopoverAntd
      placement="topLeft"
      arrowPointAtCenter
      overlayStyle={{ width: 300 }}
      overlayClassName="popover-overlay"
      title={
        title ? (
          <div className="flex" style={{ gap: 6 }}>
            <Icon type="info-circle" className="text-size-default mr-x-small text-on-background" />
            <Text className="font-semibold" color="white">
              {title}
            </Text>
          </div>
        ) : null
      }
      content={
        <Text size="xs" className="text-on-background">
          {content}
        </Text>
      }
    >
      <div>
        <Text className="text-weak">
          <Info className="-mt-0.5" />
        </Text>
      </div>
    </PopoverAntd>
  );
};

export default Popover;
