import React, { useMemo } from 'react';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { notifications } from '@good/ui/notifications';

import {
  SilServiceContextResolved,
  useInlineForm,
  useSilServiceContext,
  ZodAddress,
} from 'views/sil-service/components';
import { useUpdateService } from 'views/sil-service/hooks';

export const LocationAndCapacityForm = () => {
  const { service } = useSilServiceContext() as SilServiceContextResolved;
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceDetailsTabs.general.locationAndCapacityForm' });
  const { t: tMessages } = useTranslation('', { keyPrefix: 'silService.messages' });
  const updateService = useUpdateService();
  const location = service.locations[0];
  const schema = useMemo(() => {
    return z.object({
      location: ZodAddress,
      homeCapacity: z
        .number()
        .nullable()
        .refine((val) => val !== null, { message: t('fields.homeCapacity.validation.required') }),
    });
  }, [t]);

  const InlineForm = useInlineForm<z.infer<typeof schema>>({
    defaultValues: {
      location: location
        ? {
            fullAddress: location.fullAddress ?? '',
            streetAddress1: location.streetAddress1 ?? '',
            streetAddress2: location.streetAddress2 ?? '',
            locality: location.locality ?? '',
            state: location.state ?? '',
            postcode: location.postcode ?? '',
            geoLat:
              location.geoLat !== undefined && location.geoLat !== null ? Number(location.geoLat) : location.geoLat,
            geoLng:
              location.geoLng !== undefined && location.geoLng !== null ? Number(location.geoLng) : location.geoLng,
          }
        : undefined,
      homeCapacity: service.capacity,
    },
    resolver: zodResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    try {
      await updateService.mutateAsync({
        serviceId: service.serviceId,
        serviceProviderId: service.serviceProviderId,
        data: {
          capacity: values.homeCapacity,
          locations: location
            ? [
                {
                  serviceLocationId: location.serviceLocationId,
                  country: 'Australia',
                  ...values.location,
                },
              ]
            : undefined,
        },
      });
    } catch (e) {
      notifications.show({
        color: 'var(--color-red-main)',
        message: tMessages('updateService.error'),
      });
      throw e;
    }
    notifications.show({
      color: 'var(--color-green-main)',
      message: tMessages('updateService.success'),
    });
  };

  return (
    <InlineForm title={t('title')} onSubmit={onSubmit} maxWidth={548}>
      <InlineForm.AddressField
        label={t('fields.location.label')}
        description={t('fields.location.description')}
        name='location'
      />
      <InlineForm.NumberField
        label={t('fields.homeCapacity.label')}
        description={t('fields.homeCapacity.description')}
        name='homeCapacity'
        min={1}
        max={1000}
        maxInputWidth={180}
      />
      <InlineForm.StaticField label={t('fields.currentVacancy.label')} value={0} />
    </InlineForm>
  );
};
