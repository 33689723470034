import { Popover, Position, Tooltip } from '@blueprintjs/core';
import { Avatar, Button, Icon, Row } from 'antd';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import WorkflowStatusTag from 'common-components/tags/WorkflowsStatusTag';
import { Text } from 'common-components/typography';
import { timeZone } from 'interfaces/timezone-type';
import { IWorkflow, IWorkflowStep, IWorkflowLinkedFormAttachment } from 'interfaces/workflow-interfaces';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { ActiveWorkflowsStatus, WorkflowTemplateTriggerType } from 'utilities/enum-utils';
import { IWorkflowAction } from '../../../../../interfaces/workflow-interfaces';

interface IWorkflowDetailsHeaderProps {
  workflowDetail: IWorkflow;
  selectedWorkflowAction: IWorkflowAction;
  activeStep: IWorkflowStep | null;
  timezone: timeZone;
  disableTooltip: boolean;
  workflowLinkedForm?: IWorkflowLinkedFormAttachment;
  onFlagIssue?(): void;
  onSendBack?(): void;
  onApprove(): void;
  onResolveIssue?(): void;
  onUndoCompletion(): void;
  onReopen(): void;
  onCloseWorkflow(): void;
}

class WorkflowDetailsHeader extends PureComponent<IWorkflowDetailsHeaderProps> {
  render() {
    const { workflowDetail, selectedWorkflowAction, timezone, onCloseWorkflow } = this.props;

    const isIncidentWorkflow = workflowDetail.triggerType === WorkflowTemplateTriggerType.INCIDENT;
    const triggerType =
      workflowDetail.triggerType === WorkflowTemplateTriggerType.MANUAL &&
      workflowDetail.workflowTemplateTriggerType === WorkflowTemplateTriggerType.MANUAL
        ? 'Manual workflow'
        : 'Triggered workflow';

    return (
      <Row type="flex" align="middle" justify="space-between">
        <Row className="flex-1">
          <Row type="flex" align="middle">
            <Text size="x3-large" weight="bolder" className="mr-medium">
              {workflowDetail.name}
            </Text>

            <WorkflowStatusTag status={workflowDetail.status} />
          </Row>

          <Row type="flex" align="middle" className="mt-small mb-small">
            {isIncidentWorkflow && (
              <div className="flex-row align-center">
                <Text color="secondary" className="mr-x-small">
                  Incident
                </Text>
                <div
                  className="bg-text-secondary mr-x-small"
                  style={{ borderRadius: '50%', width: '6px', height: '6px' }}
                />
              </div>
            )}

            <Text color="secondary" className="mr-large">
              {triggerType}
            </Text>

            <Avatar src={workflowDetail.createdBy.avatar} size={20} shape="square" icon="user" className="mr-small" />

            <Text size="regular" color="secondary">
              Created by &nbsp;
              <Text size="regular" weight="bolder" color="secondary">
                {workflowDetail.createdBy.name}
              </Text>
              &nbsp; on &nbsp;
              <Text size="regular" weight="bolder" color="secondary">
                {`${moment.tz(workflowDetail.createdOn, timezone).format('D MMM YYYY, hh:mm A')} (${moment
                  .tz(workflowDetail.createdOn, timezone)
                  .fromNow()})`}
              </Text>
            </Text>
          </Row>
        </Row>

        {selectedWorkflowAction.canClose && (
          <Popover
            content={
              <ActionMenu>
                <ActionMenuItem text="Close workflow" onClick={onCloseWorkflow} />
              </ActionMenu>
            }
            position={'bottom-right'}
            interactionKind="click"
          >
            <Icon
              type="ellipsis"
              className="text-color-blue-action bordered cursor-pointer mh-small align-center justify-center"
              style={{ height: '40px', width: '40px', display: 'flex' }}
            />
          </Popover>
        )}
      </Row>
    );
  }
}

export default WorkflowDetailsHeader;

export class WorkflowDetailsHeaderActions extends PureComponent<IWorkflowDetailsHeaderProps> {
  render() {
    const {
      workflowDetail,
      activeStep,
      selectedWorkflowAction,
      onFlagIssue,
      onSendBack,
      onApprove,
      onResolveIssue,
      onUndoCompletion,
      onReopen,
      disableTooltip,
      workflowLinkedForm,
    } = this.props;

    const isFlaggedIssue = workflowDetail.issue && workflowDetail.issue.isFlagged;

    return (
      <Row type="flex" align="middle" justify="space-between">
        {workflowDetail.status === ActiveWorkflowsStatus.INPROGRESS && (
          <div className="flex align-center">
            {selectedWorkflowAction.canFlagIssue && (
              <Button size="large" type="danger" ghost onClick={onFlagIssue} className="mh-small">
                Flag Issue
              </Button>
            )}

            {selectedWorkflowAction.canApprove && (
              <PrimaryButton size="large" disabled={!disableTooltip} onClick={onApprove} className="mh-small">
                Approve
              </PrimaryButton>
            )}

            {!disableTooltip && !isFlaggedIssue && selectedWorkflowAction.canApprove && (
              <Tooltip
                position={Position.TOP}
                intent="none"
                className="mh-medium"
                content={
                  workflowLinkedForm && workflowLinkedForm.formVersionId
                    ? 'The linked form for this step is incomplete and can’t be approved'
                    : 'A completed version of the specified attachment must be added before this step can be approved'
                }
              >
                <Icon type="question-circle" className="text-size-x2-large text-color-black" />
              </Tooltip>
            )}

            {selectedWorkflowAction.canSendBack && activeStep && activeStep.stepNumber > 1 && (
              <Button size="large" type="danger" ghost onClick={onSendBack} className="mh-small">
                Send back
              </Button>
            )}

            {selectedWorkflowAction.canResolveIssue && (
              <PrimaryButton size="large" onClick={onResolveIssue}>
                Resolve Issue
              </PrimaryButton>
            )}
          </div>
        )}

        {workflowDetail.status === ActiveWorkflowsStatus.COMPLETED && selectedWorkflowAction.canUndo && (
          <Button size="large" type="danger" ghost onClick={onUndoCompletion} className="mh-small">
            Undo approval
          </Button>
        )}

        {workflowDetail.status === ActiveWorkflowsStatus.CLOSED && selectedWorkflowAction.canReopen && (
          <SecondaryButton size="large" onClick={onReopen} className="mh-small">
            Re-open workflow
          </SecondaryButton>
        )}
      </Row>
    );
  }
}
