import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { Avatar, Checkbox, Input, Radio } from 'antd';
import _ from 'lodash';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { IGroupServiceCustomer } from 'interfaces/service-interfaces';

const { Search } = Input;

interface ITagNoteCustomersModalProps {
  groupServiceSessionCustomers: typeof state.groupServiceStore.groupServiceSessionCustomers;
  groupBookingCustomers: typeof state.groupBookingsStore.groupBookingCustomers;
  doFetchGroupServiceCustomer: typeof dispatch.groupServiceStore.doFetchGroupServiceCustomer;
  doFetchGroupBookingCustomer: typeof dispatch.groupBookingsStore.doFetchGroupBookingCustomer;
  isOpen: boolean;
  selectedCustomers: IGroupServiceCustomer[];
  currentCustomer?: IGroupServiceCustomer;
  selectedSession?: typeof state.groupServiceStore.selectedSession;
  booking?: typeof state.groupBookingsStore.selectedGroupBookingItem;
  onClose: () => void;
  onSelectCustomer: (v) => void;
}

interface ITagNoteCustomersModalState {
  selectedCustomers: IGroupServiceCustomer[];
  isLoading: boolean;
  isSearching: boolean;
  isCheckAll: boolean;
  isIndeterminate: boolean;
}

class TagNoteCustomersModal extends Component<ITagNoteCustomersModalProps, ITagNoteCustomersModalState> {
  state = {
    isLoading: false,
    isSearching: false,
    selectedCustomers: this.props.selectedCustomers ? this.props.selectedCustomers : [],
    isCheckAll: false,
    isIndeterminate: false,
  };

  private _searchText = async (txt) => {
    this.setState({ isSearching: true, isLoading: true, selectedCustomers: [] });
    const { selectedSession, booking } = this.props;
    if (!selectedSession && !booking) return;

    if (selectedSession) {
      const { serviceId, serviceDateTimeId } = selectedSession;
      await this.props.doFetchGroupServiceCustomer({ serviceId, serviceDateTimeId, searchString: txt });
    } else {
      const { bookingId } = booking;
      await this.props.doFetchGroupBookingCustomer({ bookingId, searchString: txt });
    }
    this.setState({ isLoading: false, isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this._debounceSearch(e.target.value);
  };

  onCancel = () => {
    this.setState({ selectedCustomers: this.props.selectedCustomers ? this.props.selectedCustomers : [] });
    this.props.onClose();
  };

  onSave = () => {
    this.props.onSelectCustomer(this.state.selectedCustomers);
    this.props.onClose();
  };

  _setSelectCustomer = (customer) => {
    const { groupServiceSessionCustomers, groupBookingCustomers, selectedSession } = this.props;
    const { selectedCustomers } = this.state;

    let newCustomers;
    if (!selectedCustomers) {
      newCustomers = [customer];
    } else if (this._findCustomerInSelected(customer)) {
      newCustomers = _.filter(
        selectedCustomers,
        (selectedCustomer) => selectedCustomer.taggedUserId !== customer.taggedUserId,
      );
    } else {
      newCustomers = _.cloneDeep(selectedCustomers);
      newCustomers.push(customer);
    }

    const customers = selectedSession ? groupServiceSessionCustomers : groupBookingCustomers;

    this.setState({ selectedCustomers: newCustomers });
    if (newCustomers.length === customers.length) {
      this.setState({ isCheckAll: true, isIndeterminate: false });
    } else if (newCustomers.length === 0) {
      this.setState({ isCheckAll: false, isIndeterminate: false });
    } else if (this.state.isCheckAll) {
      this.setState({ isIndeterminate: true });
    }
  };

  private _findCustomerInSelected = (customer): boolean => {
    const { selectedCustomers } = this.state;
    if (!selectedCustomers || selectedCustomers.length === 0) return false;

    return !!_.find(selectedCustomers, (selectedCustomer) => selectedCustomer.taggedUserId === customer.taggedUserId);
  };

  private _onCheckAll = (e) => {
    const newCheck = e.target.checked;
    if (newCheck) {
      const { groupServiceSessionCustomers, groupBookingCustomers, selectedSession } = this.props;
      let customers = selectedSession ? groupServiceSessionCustomers : groupBookingCustomers;
      this.setState({ selectedCustomers: customers || [] });
    } else {
      this.setState({ selectedCustomers: [] });
    }

    this.setState({ isCheckAll: newCheck, isIndeterminate: false });
  };

  private _getCustomerCount = () => {
    const { selectedCustomers } = this.state;
    if (!selectedCustomers) return 0;

    if (this.props.currentCustomer) {
      const customerWithoutCurrent = _.filter(
        selectedCustomers,
        (customer) => customer.taggedUserId !== this.props.currentCustomer.taggedUserId,
      );
      return customerWithoutCurrent.length;
    } else {
      return selectedCustomers.length;
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.isOpen && !prevProps.isOpen) {
      const { selectedSession, booking } = this.props;
      if (!selectedSession && !booking) return;
      this.setState({
        isLoading: true,
        selectedCustomers: this.props.selectedCustomers ? this.props.selectedCustomers : null,
        isCheckAll: false,
        isIndeterminate: false,
      });

      if (selectedSession) {
        const { serviceId, serviceDateTimeId } = selectedSession;
        await this.props.doFetchGroupServiceCustomer({ serviceId, serviceDateTimeId });
      } else {
        const { bookingId } = booking;
        await this.props.doFetchGroupBookingCustomer({ bookingId });
      }
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isOpen, currentCustomer, groupServiceSessionCustomers, groupBookingCustomers, selectedSession, onClose } =
      this.props;
    const { selectedCustomers } = this.state;
    const customers = selectedSession ? groupServiceSessionCustomers : groupBookingCustomers;
    const customerCount = this._getCustomerCount();
    const currentCustomerName = currentCustomer
      ? `${currentCustomer.taggedUserFirstName} ${currentCustomer.taggedUserLastName}`
      : '';

    return (
      <ActionModal
        isOpen={isOpen}
        onClose={onClose}
        title="Tag to customers"
        canCloseOutside={false}
        verticalAlignment={'highest'}
        width={'small'}
      >
        {/* Customer description */}
        <section className="mb-medium">
          <div className="mb-medium">
            <Text>Select a customer to tag to this note.</Text>
          </div>
        </section>

        {currentCustomer && (
          <section className="mb-medium">
            <div className="flex-row align-center">
              <Avatar src={currentCustomer && currentCustomer.taggedUserAvatarUrl} />
              <Text className="ml-small">{currentCustomerName}</Text>
            </div>
          </section>
        )}

        {/* Search section */}
        <section className="mb-medium">
          <Search
            placeholder="Search for customers..."
            size="large"
            onChange={this._onEnterSearchText}
            loading={this.state.isSearching}
            allowClear
          />
        </section>

        {/* Customer selection section */}
        <section className="mb-medium">
          <div className="line-height-100 mb-small flex-row justify-between align-center">
            <Text size="small" color="tertiary" lineHeight={100}>
              <b>
                {customerCount} customer{customerCount !== 1 && 's'}
              </b>{' '}
              selected
            </Text>
          </div>

          {/* Note - adjust min/max Height as required. The following values should work though */}
          <div
            className="p-none bordered border-standard-gray rounded-big"
            style={{ minHeight: '30vh', maxHeight: '40vh', overflowY: 'auto' }}
          >
            {_.map(customers, (customer) => (
              <div className="flex-row bg-white select-none">
                <Checkbox
                  className="bg-white ph-medium flex-row flex-1 line-height-100"
                  style={{ paddingTop: '12px', paddingBottom: '12px' }}
                  checked={this._findCustomerInSelected(customer)}
                  onChange={() => this._setSelectCustomer(customer)}
                >
                  <Avatar
                    icon="user"
                    size="default"
                    src={customer.taggedUserAvatarUrl}
                    className="ml-x-small mr-small"
                  />
                  <Text lineHeight={100}>{`${customer.taggedUserFirstName} ${customer.taggedUserLastName}`}</Text>
                </Checkbox>
              </div>
            ))}
          </div>
          <div className="mt-small">
            <Checkbox
              checked={this.state.isCheckAll}
              onChange={this._onCheckAll}
              indeterminate={this.state.isIndeterminate}
            >
              <Text size="regular">Tag to all customers in this session.</Text>
            </Checkbox>
          </div>
        </section>

        <ActionModalFooter align={'right'}>
          <SecondaryButton size="large" className="mr-medium" onClick={this.onCancel}>
            Cancel
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this.onSave}>
            Save
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupBookingCustomers: state.groupBookingsStore.groupBookingCustomers,
  groupServiceSessionCustomers: state.groupServiceStore.groupServiceSessionCustomers,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceCustomer: dispatch.groupServiceStore.doFetchGroupServiceCustomer,
  doFetchGroupBookingCustomer: dispatch.groupBookingsStore.doFetchGroupBookingCustomer,
});

export default connect(mapState, mapDispatch)(TagNoteCustomersModal);
