import React from 'react';
import { Label } from '@good/components';
import { Skeleton } from '@good/ui/core';

import { CommonUtils } from 'utilities/common-utils';
import { useSilServiceContext } from '../sil-service-provider/sil-service-provider';

type ServiceStatus = 'DRAFT' | 'PUBLISHED' | 'UNPUBLISHED' | 'ARCHIVED';

const getServiceStatusTone = (status: ServiceStatus) => {
  switch (status) {
    case 'PUBLISHED':
      return 'positive';
    case 'UNPUBLISHED':
      return 'critical';
    case 'DRAFT':
      return 'blue';
    case 'ARCHIVED':
      return 'neutral';
    default:
      return undefined;
  }
};

export const SilServiceStatusLabel = () => {
  const { service, isLoading, isError } = useSilServiceContext();

  const label = (() => {
    if (isLoading || isError) {
      return '-';
    }

    if (service) {
      return (
        <Label tone={getServiceStatusTone(service.status as ServiceStatus)} size='sm'>
          {CommonUtils.formatServiceStatusString(service.status)}
        </Label>
      );
    }

    return '-';
  })();

  return <Skeleton visible={isLoading}>{label}</Skeleton>;
};
