import { ReactNode } from 'react';
import { PropsWithChildren } from 'react';

type ConditionalWrapperProps = PropsWithChildren<{
  condition: boolean;
  wrapper: (children: ReactNode | string) => ReactNode;
}>;

function ConditionalWrapper({ condition, wrapper, children }: ConditionalWrapperProps) {
  return condition ? wrapper(children) : children;
}

export default ConditionalWrapper;
