import React, { useEffect, useState } from 'react';
import PermissionUtils from 'utilities/permission-utils';
import { Button, Breadcrumbs, Header, Heading, Text, TextInput, Combobox } from '@good/components';
import { IRootState } from 'stores/rematch/root-store';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Checkbox } from 'design-components';
import { getNewRelicBrowser } from 'integrations/new-relic-browser';
import { uploadTempFileForProvider } from 'utilities/firebase-storage';
import { CsvImportForm, CsvImportFormSchema, csvImportTypeLabels } from './schemas';
import { CsvImportDropzone } from './components/csv-import-dropzone';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { reactTrpc } from 'utilities/react-trpc';
import { CsvTemplateDownloadLink } from './components/csv-template-download-link';

export const AccountNewImportView = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const createImportJob = reactTrpc.imports.importCsv.useMutation();

  const {
    formState: { errors, isSubmitted, isValid },
    getValues,
    setValue,
    handleSubmit,
    trigger,
    getFieldState,
    register,
    watch,
  } = useForm<CsvImportForm>({
    defaultValues: { jobName: undefined, importType: 'customers', validateOnly: false },
    reValidateMode: 'onBlur',
    resolver: zodResolver(CsvImportFormSchema),
  });

  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  useEffect(() => {
    if (
      !portalUser ||
      !PermissionUtils.validatePermission('ImportPlatformData', portalUser.permissions.permissionRoles)
    )
      history.push('/access-denied');
  });
  if (!portalUser) return null;

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);
      const { importType, csvFile, jobName } = values;
      const { ref } = await uploadTempFileForProvider({
        serviceProviderId: portalUser.serviceProviderId,
        filePath: `jobs/csv-imports/${importType}/${csvFile.name}`,
        file: csvFile,
      });

      const result = await createImportJob.mutateAsync({
        importType: values.importType,
        serviceProviderId: portalUser.serviceProviderId,
        storageBucket: 'temp',
        storageRef: ref.fullPath,
        filename: csvFile.name,
        jobName: jobName && jobName.trim().length > 0 ? jobName.trim() : csvFile.name,
        processingStrategy: values.validateOnly ? 'dryRun' : 'rollbackOnError',
      });

      history.push(`/account/imports/${result.id ?? ''}`);
    } catch (err) {
      setError('Failed to upload file. Please try again.');
      getNewRelicBrowser()?.noticeError(err as Error, { action: 'csv-import-file-upload' });
    } finally {
      setLoading(false);
    }
  });

  return (
    <>
      <div id='imports-view' className='flex-column pb-large relative flex gap-6'>
        <Breadcrumbs>
          <Link to='/account/landing'>Account management</Link>
          <Link to='/account/imports'>Import data</Link>
          <Text>New import</Text>
        </Breadcrumbs>

        <Header size='xl'>
          <Heading>New import</Heading>
          <Text>Import your customer, team, and services data from other products into GoodHuman.</Text>
        </Header>

        <form
          onSubmit={onSubmit}
          className='mt-6 flex flex-col gap-6 w-full max-w-xl mx-auto border rounded-md px-10 py-8 border-gray'
          data-testid='csv-import-form'
        >
          <div className='flex flex-col gap-2'>
            <Combobox label='Import type' error={errors.importType?.message} {...register('importType')}>
              {Object.entries(csvImportTypeLabels).map(([value, name]) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </Combobox>

            <CsvTemplateDownloadLink importType={watch('importType')} />
          </div>

          <TextInput
            label='Job name'
            description='A readable name to reference the import. The filename will be used by default.'
            optional
            error={errors.jobName?.message}
            {...register('jobName')}
          />

          <CsvImportDropzone
            file={getValues().csvFile}
            onFileSelected={async (file: File | null) => {
              setValue('csvFile', file as never as File);
              await trigger('csvFile');
            }}
            error={getFieldState('csvFile').error?.message ?? error}
          />

          <Checkbox
            value={getValues().validateOnly ? 'checked' : undefined}
            onChange={(e) => setValue('validateOnly', e)}
          >
            <Text color='$default'>Validate only (no data will be imported)</Text>
          </Checkbox>

          <Button
            emphasis='fill'
            type='submit'
            className='align-self-center'
            isDisabled={isSubmitted && !isValid}
            isLoading={isLoading}
          >
            Process CSV
          </Button>
        </form>
      </div>
    </>
  );
};
