import { Icon as BIcon } from '@blueprintjs/core';
import { Checkbox, Col, Form, Icon, Input, notification, Row } from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import SelectMembers from 'common-components/activity-groups/selectors/SelectMembers';
import { Warning } from 'common-components/alerts';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import TimeInput from 'common-components/time-input/TimeInput';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { IGroupServiceActivityGroup, IGroupServiceSession } from 'interfaces/service-interfaces';
import { IActivityGroupUsers } from 'interfaces/user-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { ActivityGroupModalType, AddingActivityGroupItemType } from 'utilities/enum-utils';
import ActivityGroupRowItemEditDirect from './ActivityGroupRowItemEditDirect';

const { TextArea, Search } = Input;

interface IActivityGroupInSearch extends IGroupServiceActivityGroup {
  isExisted: boolean;
}

interface ICreateModalContentProps {
  serviceId: string;
  form: WrappedFormUtils;
  handleAction: (payload, actionType: ActivityGroupModalType) => Promise<boolean | void>;
  onCloseViewModal: () => void;
  session?: IGroupServiceSession;
  groupServiceActivityGroups?: typeof state.groupServiceStore.groupServiceActivityGroups;
  sessionActivityGroups?: typeof state.groupServiceStore.sessionActivityGroups;
  doFetchGroupServiceActivityGroups?: typeof dispatch.groupServiceStore.doFetchGroupServiceActivityGroups;
  doFetchSessionActivityGroups?: typeof dispatch.groupServiceStore.doFetchSessionActivityGroups;
}

interface ICreateModalContentState {
  activityGroup: IGroupServiceActivityGroup;
  isLoading: boolean;
  isUsedSessionTime: boolean;
  isTimeWithinSessionTime: boolean;
  addingType: AddingActivityGroupItemType;
  isSearching: boolean;
  searchingValue: string;
  activityGroupSelectedList: string[];
  activityGroupFilteredList: IActivityGroupInSearch[];
  activityGroupAddedList: IGroupServiceActivityGroup[];
  isDateTimeError: boolean;
}

const INITIAL_STATE: ICreateModalContentState = {
  activityGroup: {
    serviceActivityGroupId: '',
    name: '',
    description: '',
    customers: [],
    teamMembers: [],
    startDateTime: null,
    endDateTime: null,
  },
  isLoading: false,
  isUsedSessionTime: true,
  isTimeWithinSessionTime: true,
  addingType: AddingActivityGroupItemType.CREATE_NEW,
  isSearching: false,
  searchingValue: null,
  activityGroupSelectedList: [],
  activityGroupFilteredList: [],
  activityGroupAddedList: [],
  isDateTimeError: false,
};

class CreateModalContent extends Component<ICreateModalContentProps, ICreateModalContentState> {
  state = {
    ..._.cloneDeep(INITIAL_STATE),
    activityGroup: {
      ...INITIAL_STATE.activityGroup,
      startDateTime: this.props.session
        ? moment.tz(this.props.session.startDateTime, this.props.session.timezone).toDate()
        : null,
      endDateTime: this.props.session
        ? moment.tz(this.props.session.endDateTime, this.props.session.timezone).toDate()
        : null,
    },
  };

  searchRef: React.RefObject<HTMLDivElement> = React.createRef();

  private _onMouseDown = (event) => {
    // handle mouse outside control
    if (this.searchRef.current && !this.searchRef.current.contains(event.target)) {
      this.setState({ isSearching: false, searchingValue: null });
    }
  };

  componentDidMount = async () => {
    const { serviceId, session, doFetchGroupServiceActivityGroups, doFetchSessionActivityGroups } = this.props;
    if (session) {
      doFetchGroupServiceActivityGroups({ serviceId });
      doFetchSessionActivityGroups({ serviceId, serviceDateTimeId: session.serviceDateTimeId });
      document.addEventListener('mousedown', this._onMouseDown);
    }
  };

  componentWillUnmount() {
    const { session } = this.props;
    if (session) {
      document.removeEventListener('mousedown', this._onMouseDown);
    }
  }

  private _onCustomersSelectedChange = (selectedValues: IActivityGroupUsers[]) => {
    const { activityGroup } = this.state;
    const updatedActivityGroup = {
      ...activityGroup,
      customers: _.map(selectedValues, (value: IActivityGroupUsers) => {
        const { attachmentUrl, firstName, lastName, userId } = value;
        return {
          userId: userId,
          firstName: firstName,
          lastName: lastName,
          attachmentUrl: attachmentUrl,
        };
      }),
    };
    this.setState({
      activityGroup: updatedActivityGroup,
    });
  };

  private _onTeamMembersSelectedChange = (selectedValues: IActivityGroupUsers[]) => {
    const { activityGroup } = this.state;
    const updatedActivityGroup = {
      ...activityGroup,
      teamMembers: _.map(selectedValues, (value: IActivityGroupUsers) => {
        const { attachmentUrl, firstName, lastName, userId } = value;
        return {
          userId: userId,
          firstName: firstName,
          lastName: lastName,
          attachmentUrl: attachmentUrl,
        };
      }),
    };
    this.setState({
      activityGroup: updatedActivityGroup,
    });
  };

  private _onSubmitForm = async () => {
    const { form, handleAction, onCloseViewModal, session } = this.props;
    this.setState({ isLoading: true });

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    const { startDateTime, endDateTime } = this.state.activityGroup;
    if (
      startDateTime &&
      endDateTime &&
      moment.tz(endDateTime, session.timezone).isBefore(moment.tz(startDateTime, session.timezone))
    ) {
      isFormValid = false;
      this.setState({ isDateTimeError: true });
    } else {
      this.setState({ isDateTimeError: false });
    }

    if (isFormValid) {
      const formValues = form.getFieldsValue();
      try {
        const { activityGroupAddedList, addingType } = this.state;
        let payload = null;
        if (this.props.session && addingType === AddingActivityGroupItemType.FROM_TEMPLATE) {
          payload = {
            activityGroups: _.map(activityGroupAddedList, ({ serviceActivityGroupId, startDateTime, endDateTime }) => ({
              serviceActivityGroupId,
              startDateTime,
              endDateTime,
            })),
          };
        } else {
          payload = {
            ...this.state.activityGroup,
            ...formValues,
            startDateTime,
            endDateTime,
          };
        }

        await handleAction(payload, ActivityGroupModalType.CREATE);
        notification.success({
          message: 'Activity group added',
          description: `You have successfully created new activity group.`,
        });
        onCloseViewModal();
      } catch (e) {
        notification.error({
          message: 'Oops, something went wrong! Please try again.',
        });
      }
    }

    this.setState({ isLoading: false });
  };

  private _roundDate = (date) => moment(CommonUtils.formatCeilingDateTime(date));

  private _isTimeWithinSessionTime(currentStartDateTime: Date, currentEndDateTime: Date) {
    const { startDateTime, endDateTime, timezone } = this.props.session;
    return (
      moment
        .tz(currentStartDateTime, timezone)
        .isBetween(moment.tz(startDateTime, timezone), moment.tz(endDateTime, timezone), null, '[]') &&
      moment
        .tz(currentEndDateTime, timezone)
        .isBetween(moment.tz(startDateTime, timezone), moment.tz(endDateTime, timezone), null, '[]')
    );
  }

  private _isSameTimeAsSessionTime(currentStartDateTime: Date, currentEndDateTime: Date) {
    const { startDateTime, endDateTime, timezone } = this.props.session;
    return (
      moment.tz(currentStartDateTime, timezone).isSame(moment.tz(startDateTime, timezone)) &&
      moment.tz(currentEndDateTime, timezone).isSame(moment.tz(endDateTime, timezone))
    );
  }

  private _handleChangeStartDateTime = (value: Date) => {
    this.setState((prevState) => ({
      isUsedSessionTime: this._isSameTimeAsSessionTime(value, prevState.activityGroup.endDateTime),
      isTimeWithinSessionTime: this._isTimeWithinSessionTime(value, prevState.activityGroup.endDateTime),
      activityGroup: {
        ...prevState.activityGroup,
        startDateTime: this._roundDate(moment(value)).toDate(),
      },
    }));
  };

  private _handleChangeEndDateTime = (value: Date) => {
    this.setState((prevState) => ({
      isUsedSessionTime: this._isSameTimeAsSessionTime(prevState.activityGroup.startDateTime, value),
      isTimeWithinSessionTime: this._isTimeWithinSessionTime(prevState.activityGroup.startDateTime, value),
      activityGroup: {
        ...prevState.activityGroup,
        endDateTime: this._roundDate(moment(value)).toDate(),
      },
    }));
  };

  private _handleUseSessionTime = (e) => {
    this.setState((prevState) => {
      let newState = { ...prevState, isUsedSessionTime: !prevState.isUsedSessionTime };
      if (e.target.checked) {
        const { startDateTime, endDateTime, timezone } = this.props.session;
        newState = {
          ...newState,
          isTimeWithinSessionTime: true,
          activityGroup: {
            ...prevState.activityGroup,
            startDateTime: this._roundDate(moment.tz(startDateTime, timezone)).toDate(),
            endDateTime: this._roundDate(moment.tz(endDateTime, timezone)).toDate(),
          },
        };
      }

      return newState;
    });
  };

  private _handleChangeAddingType = (addingType: AddingActivityGroupItemType): void => {
    this.setState({ addingType });
  };

  private _renderSelectActivityGroup = () => {
    const { addingType } = this.state;
    return (
      <>
        <SubTitle containerClassName="mb-x-small">Select activity group</SubTitle>
        <div className="flex-row align-center">
          <div
            className={`cursor-pointer mr-large mb-small bordered rounded p-medium ${
              addingType === AddingActivityGroupItemType.CREATE_NEW && 'bg-blue-lightest border-blue-lighter'
            }`}
            style={{ maxWidth: '328px', minHeight: '88px' }}
            onClick={() => this._handleChangeAddingType(AddingActivityGroupItemType.CREATE_NEW)}
          >
            <Row>
              <Col span={20}>
                <Text weight="bold">Create new activity group</Text>
              </Col>
              <Col span={4} className="text-align-right">
                {addingType === AddingActivityGroupItemType.CREATE_NEW ? (
                  <BIcon icon="tick-circle" color="#0083FF" iconSize={21} />
                ) : (
                  <BIcon icon="circle" color="#EEEEEE" iconSize={21} />
                )}
              </Col>
            </Row>
            <Paragraph color="secondary" className="mb-none">
              Create new activity groups just for this session.
            </Paragraph>
          </div>

          <div
            className={`cursor-pointer mr-large mb-small bordered rounded p-medium ${
              addingType === AddingActivityGroupItemType.FROM_TEMPLATE && 'bg-blue-lightest border-blue-lighter'
            }`}
            style={{ maxWidth: '328px', minHeight: '88px' }}
            onClick={() => this._handleChangeAddingType(AddingActivityGroupItemType.FROM_TEMPLATE)}
          >
            <Row>
              <Col span={20}>
                <Text weight="bold">Add activity group from template</Text>
              </Col>
              <Col span={4} className="text-align-right">
                {addingType === AddingActivityGroupItemType.FROM_TEMPLATE ? (
                  <BIcon icon="tick-circle" color="#0083FF" iconSize={21} />
                ) : (
                  <BIcon icon="circle" color="#EEEEEE" iconSize={21} />
                )}
              </Col>
            </Row>
            <Paragraph color="secondary" className="mb-none">
              Use existing activity groups from the service template.
            </Paragraph>
          </div>
        </div>
      </>
    );
  };

  private _getActivityGroupSearchList = () => {
    const { groupServiceActivityGroups, sessionActivityGroups } = this.props;
    const { activityGroupAddedList } = this.state;
    return _.chain(groupServiceActivityGroups)
      .map((activity) => {
        let isExisted = true;
        if (
          _.isEmpty(
            _.find(
              sessionActivityGroups,
              (currentActivity) => currentActivity.serviceActivityGroupId === activity.serviceActivityGroupId,
            ),
          )
        ) {
          isExisted = false;
        }
        return { ...activity, isExisted };
      })
      .filter((activity) =>
        _.isEmpty(
          _.find(
            activityGroupAddedList,
            (addedActivity) => addedActivity.serviceActivityGroupId === activity.serviceActivityGroupId,
          ),
        ),
      )
      .value();
  };

  private _handleChangeSearchValue = (e) => {
    const searchValue = _.get(e, 'target.value', '');
    this.setState({
      activityGroupFilteredList: _.chain(this._getActivityGroupSearchList())
        .filter((activity: IActivityGroupInSearch) => activity.name.toLowerCase().includes(searchValue.toLowerCase()))
        .orderBy('name', 'asc')
        .value(),
      isSearching: true,
      searchingValue: searchValue,
    });
  };

  private _handleAddActivityGroupSelected = () => {
    const { groupServiceActivityGroups, session } = this.props;
    const { startDateTime, endDateTime } = session;
    const { activityGroupSelectedList } = this.state;
    if (!_.isEmpty(activityGroupSelectedList)) {
      const listForAdd = _.chain(groupServiceActivityGroups)
        .filter((activity: IGroupServiceActivityGroup) =>
          activityGroupSelectedList.includes(activity.serviceActivityGroupId),
        )
        .map((activity: IGroupServiceActivityGroup) => ({ ...activity, startDateTime, endDateTime }))
        .value();

      this.setState((prevState) => ({
        activityGroupAddedList: _.uniqBy(
          [...prevState.activityGroupAddedList, ...listForAdd],
          (activity) => activity.serviceActivityGroupId,
        ),
        isSearching: false,
        searchingValue: null,
      }));
    } else {
      this.setState({ isSearching: false, searchingValue: null });
    }
  };

  private _handleChangeRowItem = (item: IGroupServiceActivityGroup) => {
    const { activityGroupAddedList } = this.state;
    this.setState({
      activityGroupAddedList: _.map(activityGroupAddedList, (activity) => {
        if (activity.serviceActivityGroupId === item.serviceActivityGroupId) {
          return item;
        }
        return activity;
      }),
    });
  };

  private _handleRemoveRowItem = (serviceDateTimeActivityGroupId: string) => {
    const { activityGroupSelectedList, activityGroupAddedList } = this.state;
    this.setState({
      activityGroupSelectedList: _.remove(
        activityGroupSelectedList,
        (activityId) => activityId !== serviceDateTimeActivityGroupId,
      ),
      activityGroupAddedList: _.remove(
        activityGroupAddedList,
        (item) => item.serviceActivityGroupId !== serviceDateTimeActivityGroupId,
      ),
    });
  };

  private _renderActivityGroupFromTemplate = () => {
    const {
      searchingValue,
      isSearching,
      activityGroupSelectedList,
      activityGroupFilteredList,
      activityGroupAddedList,
    } = this.state;
    const { startDateTime, endDateTime, timezone } = this.props.session;
    return (
      <>
        <SubTitle containerClassName="mt-medium">Assign activity group</SubTitle>
        <div ref={this.searchRef} className="bg-quaternary" style={{ maxWidth: '580px', maxHeight: '703px' }}>
          <Row className="mb-medium rounded p-medium mt-small">
            <Col span={20}>
              <Search
                onFocus={this._handleChangeSearchValue}
                onChange={this._handleChangeSearchValue}
                placeholder="Search for activity group..."
                allowClear
                value={searchingValue}
              />
              {isSearching && (
                <div className="bg-white width-full p-medium bordered rounded overflow-y-scroll mt-medium">
                  <CheckboxGroup
                    onChange={(value: string[]) => {
                      this.setState({ activityGroupSelectedList: value });
                    }}
                    style={{ maxHeight: '210px' }}
                    defaultValue={activityGroupSelectedList}
                  >
                    {!_.isEmpty(activityGroupFilteredList) ? (
                      _.map(activityGroupFilteredList, (activity, index) => (
                        <div className="bordered-bottom" style={{ width: '400px' }} key={index}>
                          <Checkbox
                            value={activity.serviceActivityGroupId}
                            className="text-weight-bold text-overflow-ellipsis overflow-hidden whitespace-nowrap width-full"
                            disabled={activity.isExisted}
                          >
                            {activity.name} {activity.isExisted && ' (Already added)'}
                          </Checkbox>
                          <Paragraph
                            color={activity.isExisted ? 'light-gray' : 'secondary'}
                            className="ml-large mb-small"
                          >
                            {activity.description}
                          </Paragraph>
                        </div>
                      ))
                    ) : (
                      <Paragraph color="light-gray" className="ml-large">
                        No result found...
                      </Paragraph>
                    )}
                  </CheckboxGroup>
                </div>
              )}
            </Col>
            <Col span={4}>
              <PrimaryButton className="ml-medium" size="default" onClick={this._handleAddActivityGroupSelected}>
                Add
              </PrimaryButton>
            </Col>
          </Row>
          {!isSearching && !_.isEmpty(activityGroupAddedList) && (
            <div className="m-medium overflow-y-scroll" style={{ maxHeight: '600px' }}>
              {_.map(activityGroupAddedList, (activity, index) => (
                <ActivityGroupRowItemEditDirect
                  key={index}
                  scheduleStartDate={startDateTime}
                  scheduleEndDate={endDateTime}
                  item={activity}
                  onChange={this._handleChangeRowItem}
                  onRemove={this._handleRemoveRowItem}
                  isInSchedule={false}
                  timezone={timezone}
                />
              ))}
            </div>
          )}
        </div>
      </>
    );
  };

  render() {
    const { serviceId, form, onCloseViewModal, session } = this.props;
    const {
      isLoading,
      activityGroup,
      isUsedSessionTime,
      isTimeWithinSessionTime,
      addingType,
      isDateTimeError,
      activityGroupAddedList,
    } = this.state;
    const { name, description, customers, teamMembers, startDateTime, endDateTime } = activityGroup;
    const { getFieldDecorator } = form;
    return (
      <>
        {session && this._renderSelectActivityGroup()}
        {session && AddingActivityGroupItemType.FROM_TEMPLATE === addingType && this._renderActivityGroupFromTemplate()}
        {(!session || (session && AddingActivityGroupItemType.CREATE_NEW === addingType)) && (
          <>
            {!isTimeWithinSessionTime && (
              <Warning
                color='orange'
                borderNone={true}
                className='mb-small bg-orange-lightest rounded-big bordered border-orange-lighter'
                content={
                  <>
                    <Text weight='bold' className='block'>
                      Activity time is outside the session time
                    </Text>
                    <Text>
                      This activity group doesn&apos;t fit within the session timeframe. Please make sure your activity
                      runs within the session.
                    </Text>
                  </>
                }
              />
            )}
            <SubTitle>Group name</SubTitle>
            <Form.Item className='m-none'>
              {getFieldDecorator('name', {
                initialValue: name,
                rules: [
                  { required: true, message: 'Please enter a name for this group' },
                  {
                    max: 50,
                    message: 'Please enter less than 50 characters',
                  },
                ],
              })(<Input placeholder='Enter a group name' className='mt-x-small' />)}
            </Form.Item>
            <Row className='mt-small'>
              <SubTitle>Description (optional)</SubTitle>
              <Form.Item className='m-none'>
                {getFieldDecorator('description', {
                  initialValue: description,
                  rules: [
                    {
                      max: 2500,
                      message: 'Please enter less than 2500 characters',
                    },
                  ],
                })(<TextArea placeholder='Tell people a bit about this group...' autoSize={{ minRows: 5 }} />)}
              </Form.Item>
            </Row>
            {session && (
              <>
                <Row className='mt-small'>
                  <Col span={6} className='mr-large'>
                    <SubTitle>Start Date</SubTitle>
                    <Form.Item>
                      {getFieldDecorator('startDateTime', {
                        initialValue: moment.tz(startDateTime, session.timezone),
                        rules: [{ required: true, message: 'Please select an start date for this group' }],
                      })(
                        <DatePicker
                          className={`gh-datepicker rounded p-none ph-small ${isDateTimeError && 'border-red-dark'}`}
                          calendarClassName='gh-datepicker-calendar'
                          dateFormat='EEE, dd MMM yyyy'
                          onChange={this._handleChangeStartDateTime}
                          isClearable={false}
                          selected={moment.tz(startDateTime, session.timezone).toDate()}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <SubTitle>End Date</SubTitle>
                    <Form.Item>
                      {getFieldDecorator('endDateTime', {
                        initialValue: moment.tz(endDateTime, session.timezone),
                        rules: [{ required: true, message: 'Please select an end date for this group' }],
                      })(
                        <DatePicker
                          className={`gh-datepicker rounded p-none ph-small ${isDateTimeError && 'border-red-dark'}`}
                          calendarClassName='gh-datepicker-calendar'
                          dateFormat='EEE, dd MMM yyyy'
                          onChange={this._handleChangeEndDateTime}
                          isClearable={false}
                          selected={moment.tz(endDateTime, session.timezone).toDate()}
                          minDate={moment.tz(startDateTime, session.timezone).startOf('day').toDate()}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <SubTitle>Start Time</SubTitle>
                    <div className='flex align-center'>
                      <TimeInput
                        size='large'
                        onChange={this._handleChangeStartDateTime}
                        value={moment.tz(startDateTime, session.timezone)}
                        className={`${isDateTimeError && 'border-red-dark'}`}
                      />
                      <Text color='secondary' className='mh-medium'>
                        to
                      </Text>
                    </div>
                  </Col>
                  <Col span={6}>
                    <SubTitle>End Time</SubTitle>
                    <TimeInput
                      size='large'
                      onChange={this._handleChangeEndDateTime}
                      value={moment.tz(endDateTime, session.timezone)}
                      className={`${isDateTimeError && 'border-red-dark'}`}
                    />
                  </Col>
                </Row>
                {isDateTimeError && (
                  <Row>
                    <Col span={24}>
                      <Text size='large' color='red-dark'>
                        End date time must be after the start date time
                      </Text>
                    </Col>
                  </Row>
                )}
                <Row className='mt-12 mb-x-large'>
                  {!isTimeWithinSessionTime && (
                    <div className='mb-small'>
                      <Icon type='warning' theme='filled' className='mr-small text-color-warning-orange' />
                      <Text type='warning'>Activity time doesn&apos;t fit within the session timeframe</Text>
                    </div>
                  )}
                  <Checkbox
                    className='ant-typography text-size-large line-height-135'
                    checked={isUsedSessionTime}
                    onChange={this._handleUseSessionTime}
                  >
                    Make same as session time
                  </Checkbox>
                </Row>
              </>
            )}
            {!session && (
              <>
                <Row>
                  <SelectMembers
                    activityGroup={activityGroup}
                    activityGroupAddType={ActivityGroupModalType.ADD_CUSTOMER}
                    serviceId={serviceId}
                    membersInActivityGroup={customers}
                    onClose={onCloseViewModal}
                    onInteractWithAddedMembers={this._onCustomersSelectedChange}
                  />
                </Row>
                <Row>
                  <SelectMembers
                    activityGroup={activityGroup}
                    activityGroupAddType={ActivityGroupModalType.ADD_TEAM_MEMBER}
                    serviceId={serviceId}
                    membersInActivityGroup={teamMembers}
                    onClose={onCloseViewModal}
                    onInteractWithAddedMembers={this._onTeamMembersSelectedChange}
                  />
                </Row>
              </>
            )}
          </>
        )}
        <ActionModalFooter className='flex-row'>
          <div className='flex flex-grow'></div>
          <SecondaryButton size='large' onClick={onCloseViewModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            className='ml-medium'
            size='large'
            loading={isLoading}
            onClick={this._onSubmitForm}
            disabled={_.isEmpty(activityGroupAddedList) && addingType === AddingActivityGroupItemType.FROM_TEMPLATE}
          >
            Create activity group
          </PrimaryButton>
        </ActionModalFooter>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceActivityGroups: state.groupServiceStore.groupServiceActivityGroups,
  sessionActivityGroups: state.groupServiceStore.sessionActivityGroups,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceActivityGroups: dispatch.groupServiceStore.doFetchGroupServiceActivityGroups,
  doFetchSessionActivityGroups: dispatch.groupServiceStore.doFetchSessionActivityGroups,
});

export default connect(mapState, mapDispatch)(CreateModalContent);
