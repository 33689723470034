import React, { Component } from 'react';
import { Col, Form, Row, Select, Tabs, Checkbox, Switch, InputNumber } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Information } from 'common-components/alerts';
import { connect } from 'react-redux';
import { dispatch, state, IRootState, IRootDispatch } from 'stores/rematch/root-store';
import AddNDISLineItemsModal from 'views/services/listing/components/AddNDISLineItemsModal';
import Utils from 'utilities/Utils';
import NDISLineItemGrid from 'common-components/line-items/NDISLineItemGrid';
import NumberInput from 'common-components/inputs/NumberInput';
import { PaymentSources, TeamMemberCustomerRatio, ServiceType, PaymentSourceType } from 'utilities/enum-utils';
import ndisHelper from 'variables/data/ndis-helper';
import InfoPanel from 'common-components/alerts/InfoPanel';

const { TabPane } = Tabs;

const RECOMMENDED_TRAVEL_PRICE = 0.97;

interface INDISSettingsStepPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
  companyFundingAndPayment: typeof state.companyStore.companyFundingAndPayment;
  doFetchCompanyFundingAndPayment: typeof dispatch.companyStore.doFetchCompanyFundingAndPayment;
}

interface INDISSettingsStepPanelState {
  isOpen: boolean;
  serviceBillingItems: any;
  selectedTab: string;
  isWarningTravelPriceBefore: boolean;
  isWarningTravelPriceDuring: boolean;
  isWarningTeamCostumerRatio: boolean;
  isGroupService: boolean;
  isChargeTransportDuringBooking: boolean;
  transportPriceDuringBooking: number;
  isChargeCentreCapitalCosts: boolean;
  teamMemberCustomerRatio: string;
  isSupportCoodination: boolean;
  isIndependentLiving: boolean;
}
class NDISSettingsStepPanel extends Component<INDISSettingsStepPanelProps, INDISSettingsStepPanelState> {
  state = {
    isOpen: false,
    serviceBillingItems: this.props.newService.ndisServiceBillingItems
      ? this.props.newService.ndisServiceBillingItems
      : [],
    selectedTab: 'lineItems',
    isWarningTravelPriceBefore: false,
    isWarningTravelPriceDuring: false,
    isWarningTeamCostumerRatio: false,
    isGroupService: !!(this.props.newService.serviceType && this.props.newService.serviceType === ServiceType.GROUP),
    isChargeTransportDuringBooking:
      Boolean(this.props.newService.serviceClaimConfig.ndisClaims.isChargeTransportDuringBooking),
    transportPriceDuringBooking: this.props.newService.serviceClaimConfig.ndisClaims.transportPriceDuringBooking,
    isChargeCentreCapitalCosts: !!this.props.newService.serviceClaimConfig.ndisClaims.isChargeCentreCapitalCosts,
    teamMemberCustomerRatio:
      this.props.newService.teamMemberCustomerRatio && this.props.newService.teamMemberCustomerRatio.ndis,
    isSupportCoodination: !!(
      this.props.newService.serviceType && this.props.newService.serviceType === ServiceType.COORDINATION
    ),
  };

  private _closeLineItemModal = () => {
    this.setState({ isOpen: false });
  };

  private _openLineItemModal = () => {
    this.setState({ isOpen: true });
  };

  private _saveLineItem = (billingItems) => {
    let newBillingItems = _.clone(this.state.serviceBillingItems);

    billingItems.map((lineItem) => {
      let priority = null;
      if (
        !Utils.isEmpty(lineItem.GroupCode) &&
        _.find(newBillingItems, (existingLines) => existingLines.groupCode === lineItem.GroupCode)
      ) {
        priority = _.find(newBillingItems, (existingLines) => existingLines.groupCode === lineItem.GroupCode).priority;
      } else {
        let prioritizedLineItems = _.clone(newBillingItems);
        _.sortBy(prioritizedLineItems, 'priority', 'desc');
        let lastLineItem = prioritizedLineItems ? prioritizedLineItems.pop() : null;
        priority = lastLineItem && lastLineItem.priority ? lastLineItem.priority + 1 : 1;
      }

      newBillingItems.push({
        supportItemNumber: lineItem.SupportItemNumber,
        supportCategoryNumber: Number(lineItem.SupportCategoryNumber),
        price: {
          nonRemotePrice: lineItem.NationalNonRemote ? Number(lineItem.NationalNonRemote) : 0,
          remotePrice: Number(lineItem.NationalRemote),
          veryRemotePrice: Number(lineItem.NationalVeryRemote),
          actPrice: Number(lineItem.ACTPrice),
          ntPrice: Number(lineItem.NTPrice),
        },
        tax: 0,
        unit: lineItem.UnitOfMeasure,
        dateType: lineItem.DateType,
        billingProvider: 'NDIS',
        label: lineItem.SupportItem,
        priceControl: lineItem.PriceControl,
        priority,
        mileagePrice: 0,
        groupCode: lineItem.GroupCode,
        customerSupportLevel: '1', //TODO: Decide with product team if customerSupportLevel should still be used for automation.
      });
    });
    this.setState({ isOpen: false, serviceBillingItems: newBillingItems });
  };

  private _saveBillingLineItems = async () => {
    const { form, onNextStep, setNewService, newService } = this.props;
    const { serviceBillingItems } = this.state;
    const isGroupService = newService.serviceType === ServiceType.GROUP;

    if (isGroupService && !this.state.teamMemberCustomerRatio && this.state.selectedTab === 'claims') {
      this.setState({ selectedTab: 'lineItems', isWarningTeamCostumerRatio: true });
      return;
    }

    if (isGroupService && this.state.isChargeTransportDuringBooking && !this.state.transportPriceDuringBooking) {
      this.setState({ transportPriceDuringBooking: 0 });
    }

    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      const teamMemberCustomerRatio = { ndis: this.state.teamMemberCustomerRatio };
      const newServiceClaimConfig = _.cloneDeep(newService.serviceClaimConfig);

      if (isGroupService) {
        // Transport price setting is moved to LocationStepPanel for group service.
        newServiceClaimConfig.ndisClaims.isChargeCentreCapitalCosts = this.state.isChargeCentreCapitalCosts;
      } else {
        const isChargeTransportBeforeBooking = form.getFieldValue('isChargeTransportBeforeBooking');
        const transportPriceBeforeBooking = isChargeTransportBeforeBooking
          ? form.getFieldValue('transportPriceBeforeBooking')
          : 0;
        const isChargeTransportDuringBooking = form.getFieldValue('isChargeTransportDuringBooking');
        const transportPriceDuringBooking = isChargeTransportDuringBooking
          ? form.getFieldValue('transportPriceDuringBooking')
          : 0;

        newServiceClaimConfig.isChargeNdisTransportBeforeBooking = isChargeTransportBeforeBooking;
        newServiceClaimConfig.isChargeNdisTransportDuringBooking = isChargeTransportDuringBooking;
        newServiceClaimConfig.ndisClaims.transportPriceBeforeBooking = transportPriceBeforeBooking;
        newServiceClaimConfig.ndisClaims.transportPriceDuringBooking = transportPriceDuringBooking;
      }

      setNewService({
        ...newService,
        ndisServiceBillingItems: serviceBillingItems,
        serviceClaimConfig: newServiceClaimConfig,
        teamMemberCustomerRatio,
      });
      onNextStep();
    }
  };

  private _handleOnDelete = (billingLineItems) => {
    this.setState({ serviceBillingItems: billingLineItems });
  };

  private _handleSequenceChange = (billingLineItems) => {
    this.setState({ serviceBillingItems: billingLineItems });
  };

  private _handleOnPriceChange = (billingLineItems) => {
    this.setState({ serviceBillingItems: _.clone(billingLineItems) });
  };

  private _onChangeTravelPriceBefore(event) {
    if (event > 0 && event > RECOMMENDED_TRAVEL_PRICE) {
      this.setState({ isWarningTravelPriceBefore: true });
    } else {
      this.setState({ isWarningTravelPriceBefore: false });
    }
  }

  private _onChangeTravelPriceDuring(event) {
    if (event > 0 && event > RECOMMENDED_TRAVEL_PRICE) {
      this.setState({ isWarningTravelPriceDuring: true });
    } else {
      this.setState({ isWarningTravelPriceDuring: false });
    }
  }

  private _changeTab = (event) => {
    this.setState({ selectedTab: event });
  };

  private _onChangeIsChargeParticipantTransport = () => {
    this.setState({ isChargeTransportDuringBooking: !this.state.isChargeTransportDuringBooking });
  };

  private _onChangeIsChargeCentreCapitalCosts = () => {
    this.setState({ isChargeCentreCapitalCosts: !this.state.isChargeCentreCapitalCosts });
  };

  componentDidMount = async () => {
    if (this.state.isGroupService && _.isEmpty(this.state.serviceBillingItems)) {
      await this.props.doFetchCompanyFundingAndPayment({});
      const NDISConfig = this.props.companyFundingAndPayment
        ? _.find(
            this.props.companyFundingAndPayment.paymentSources,
            (funding) => funding.paymentSourceType === PaymentSourceType.NDIS,
          )
        : null;

      let groupCodes = NDISConfig.paymentSourceConfiguration.isTtpApproved
        ? ['C_GAS_T', 'C_GAHI_T']
        : ['C_GAS', 'C_GAHI'];

      let newBillingItems = [];
      groupCodes.map((c) => {
        newBillingItems = _.concat(newBillingItems, ndisHelper.getByGroupCode(c));
      });

      this._saveLineItem(newBillingItems);
    }

    if (this.state.isSupportCoodination && _.isEmpty(this.state.serviceBillingItems)) {
      const supportItemNumbers = ['07_001_0106_8_3', '07_002_0106_8_3', '07_004_0132_8_3'];

      let newBillingItems = [];
      supportItemNumbers.map((c) => {
        newBillingItems = _.concat(newBillingItems, ndisHelper.getBySupportItemNumber(c));
      });

      this._saveLineItem(newBillingItems);
    }
  };

  componentDidUpdate = () => {
    if (this.state.isWarningTeamCostumerRatio && this.state.selectedTab === 'lineItems') {
      const { form } = this.props;
      form.validateFields(async (err) => {
        if (err) {
          console.log(err);
          this.setState({ isWarningTeamCostumerRatio: false });
        }
      });
    }
  };

  render() {
    const { onPreviousStep, form, newService } = this.props;
    const {
      serviceBillingItems,
      isGroupService,
      isSupportCoodination,
      isChargeCentreCapitalCosts,
      teamMemberCustomerRatio,
      selectedTab,
    } = this.state;
    const { getFieldDecorator } = form;

    return (
      <div className="anim-slide-left mb-x-large">
        <AddNDISLineItemsModal
          closeLineItemModal={this._closeLineItemModal}
          isOpen={this.state.isOpen}
          saveLineItem={this._saveLineItem}
          serviceBillingItems={this.state.serviceBillingItems}
        />
        <Title level={2} weight="bolder" className="line-height-100">
          {isGroupService
            ? 'NDIS price settings'
            : isSupportCoodination
            ? 'Set up NDIS pricing'
            : 'Configure NDIS settings'}
        </Title>

        <Paragraph>
          {isGroupService
            ? 'Please add all the line items that you think you may charge for this service. You can use the arrows to adjust the priority order of the line items. Line items listed first will be the ones used as default. You can adjust this order on a per customer basis when you create a service agreement for a customer.'
            : isSupportCoodination
            ? "Add the NDIS line items you'd like to charge for in this service."
            : 'Configure the settings for NDIS'}
        </Paragraph>

        {!isSupportCoodination && (
          <Tabs animated={true} onChange={this._changeTab} activeKey={selectedTab}>
            <TabPane tab="NDIS Line Items" key="lineItems" />
            <TabPane tab={isGroupService ? 'NDIS claims' : 'Travel Claims'} key="claims" />
          </Tabs>
        )}

        {isSupportCoodination && (
          <div>
            <InfoPanel text="Line items recommended by the NDIS for Support coordination have been added automatically. You may customise these line items, but please be sure to check the NDIS price guide before making changes to stay compliant with NDIS guidelines."></InfoPanel>
          </div>
        )}

        {this.state.selectedTab === 'lineItems' && isGroupService && !isSupportCoodination && (
          <div className="mb-large flex-column">
            <Information
              className="bg-quaternary border-width-small rounded"
              content={
                <Text size="regular">
                  The line items for group services that are recommended by the NDIS have already been added to this
                  service; You may wish to customize your billing as required. Customising your billing for group
                  services may result in non-compliance. Please check the NDIS price guide before making any
                  adjustments.
                </Text>
              }
            />
            <div className="mt-large">
              <SubTitle>Select default team member : customer ratio</SubTitle>
            </div>
            <Form.Item className={'m-none pr-small'}>
              {getFieldDecorator('teamMemberCustomerRatio', {
                initialValue: teamMemberCustomerRatio ? teamMemberCustomerRatio : undefined,
                rules: [
                  {
                    required: true,
                    message: 'Default Ratio is required',
                  },
                ],
              })(
                <Select
                  placeholder="Select.."
                  style={{ width: '200px' }}
                  onChange={(value) => {
                    this.setState({ teamMemberCustomerRatio: value.toString() });
                  }}
                >
                  {_.map(TeamMemberCustomerRatio, (ratio) => (
                    <Select.Option value={ratio}>{ratio}</Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>

            <Text className="lato text-weight-regular" style={{ fontStyle: 'italic' }}>
              This will be the default ratio for customers if they do not not have a service agreement for this service.
            </Text>
          </div>
        )}

        {this.state.selectedTab === 'lineItems' && (
          <>
            <Row>
              <Col span={20}>
                <GhostButton icon="plus" onClick={this._openLineItemModal}>
                  Add line item(s)
                </GhostButton>
              </Col>
            </Row>

            <Row className={'mt-medium'}>
              <NDISLineItemGrid
                displayMode={'EDIT'}
                isDeletable={true}
                isSequenceAdjustable={true}
                onDelete={this._handleOnDelete}
                onSequenceChange={this._handleSequenceChange}
                isPriceAdjustable={true}
                onPriceChange={this._handleOnPriceChange}
                lineItems={serviceBillingItems}
                paymentSourceType={PaymentSources.NDIS}
              />
            </Row>
          </>
        )}

        {this.state.selectedTab === 'claims' && isGroupService && (
          <div>
            <div className="mt-large">
              <SubTitle>CENTRE CAPITAL COSTS</SubTitle>
            </div>
            <Text>Do you want to charge ‘Centre Capital Costs’ for this service</Text>
            <div className="mt-medium">
              <Switch
                size="small"
                className="mr-small"
                defaultChecked={isChargeCentreCapitalCosts}
                onChange={this._onChangeIsChargeCentreCapitalCosts}
              />
              <Text className="text-size-regular">Charge Centre Capital Costs</Text>
            </div>
            <div className={'mt-large'}>
              <InfoPanel
                text={
                  'Enabling centre capital costs will automatically add the relevant line items to the services pricing and all service agreement created for this service.'
                }
              />
            </div>
          </div>
        )}

        {this.state.selectedTab === 'claims' && !isGroupService && (
          <Row>
            <Col span={8}>
              <Text>Please indicate if you wish to claim for support worker travel for this service</Text>
            </Col>
            <Col span={8}>
              <div className={'mt-medium'}>
                {getFieldDecorator('isChargeTransportBeforeBooking', {
                  valuePropName: 'checked',
                  initialValue:
                    newService.serviceClaimConfig && newService.serviceClaimConfig.isChargeNdisTransportBeforeBooking
                      ? newService.serviceClaimConfig.isChargeNdisTransportBeforeBooking
                      : false,
                })(
                  <Checkbox>
                    Claim for worker travel <b>to/from</b> a booking.
                  </Checkbox>,
                )}
                <br />
                {form.getFieldValue('isChargeTransportBeforeBooking') && (
                  <>
                    <div className="flex-row">
                      <Form.Item className={'m-none ml-large'}>
                        {getFieldDecorator('transportPriceBeforeBooking', {
                          initialValue:
                            newService.serviceClaimConfig &&
                            newService.serviceClaimConfig.ndisClaims.transportPriceBeforeBooking
                              ? newService.serviceClaimConfig.ndisClaims.transportPriceBeforeBooking
                              : null,
                          rules: [
                            {
                              required: true,
                              message: 'Please enter a price.',
                            },
                          ],
                        })(
                          <NumberInput
                            min={0}
                            step={0.05}
                            max={9999}
                            onChange={(event) => this._onChangeTravelPriceBefore(event)}
                            className={'ml-x-small'}
                            addonBefore={'$'}
                            style={{ width: '120px' }}
                          />,
                        )}{' '}
                      </Form.Item>
                      <div>
                        <Text className="ml-small" style={{ verticalAlign: 'middle' }}>
                          price per km.
                        </Text>
                      </div>
                    </div>
                    {this.state.isWarningTravelPriceBefore && (
                      <div className={'text-color-orange text-size-regular'} style={{ lineHeight: 'initial' }}>
                        The NDIS <b>recommends</b> that cost per km do not exceed ${RECOMMENDED_TRAVEL_PRICE} for
                        vehicles not modified for accessibility.
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className={'mt-medium mb-large'}>
                {getFieldDecorator('isChargeTransportDuringBooking', {
                  valuePropName: 'checked',
                  initialValue:
                    newService.serviceClaimConfig && newService.serviceClaimConfig.isChargeNdisTransportDuringBooking
                      ? newService.serviceClaimConfig.isChargeNdisTransportDuringBooking
                      : false,
                })(
                  <Checkbox>
                    Claim for worker travel <b>during</b> a booking.
                  </Checkbox>,
                )}
                <br />
                {form.getFieldValue('isChargeTransportDuringBooking') && (
                  <>
                    <div className="flex-row">
                      <Form.Item className={'m-none ml-large'}>
                        {getFieldDecorator('transportPriceDuringBooking', {
                          initialValue:
                            newService.serviceClaimConfig &&
                            newService.serviceClaimConfig.ndisClaims.transportPriceDuringBooking
                              ? newService.serviceClaimConfig.ndisClaims.transportPriceDuringBooking
                              : null,
                          rules: [
                            {
                              required: true,
                              message: 'Please enter a price.',
                            },
                          ],
                        })(
                          <NumberInput
                            min={0}
                            step={0.05}
                            max={9999}
                            onChange={(event) => this._onChangeTravelPriceDuring(event)}
                            className={'ml-x-small'}
                            addonBefore={'$'}
                            style={{ width: '120px' }}
                          />,
                        )}
                      </Form.Item>
                      <div>
                        <Text className="ml-small" style={{ verticalAlign: 'middle' }}>
                          price per km.
                        </Text>
                      </div>
                    </div>{' '}
                    {this.state.isWarningTravelPriceDuring && (
                      <div className={'text-color-orange text-size-regular'} style={{ lineHeight: 'initial' }}>
                        The NDIS <b>recommends</b> that cost per km do not exceed ${RECOMMENDED_TRAVEL_PRICE} for
                        vehicles not modified for accessibility.
                      </div>
                    )}
                  </>
                )}
              </div>
            </Col>
            <Col span={8} />
          </Row>
        )}

        <div className="align-center mv-x2-large flex-row">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left" className="mr-large">
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this._saveBillingLineItems} icon="right" iconPosition="right">
            Next
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPayment: state.companyStore.companyFundingAndPayment,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCompanyFundingAndPayment: dispatch.companyStore.doFetchCompanyFundingAndPayment,
});

export default connect(mapState, mapDispatch)(Form.create<INDISSettingsStepPanelProps>()(NDISSettingsStepPanel));
