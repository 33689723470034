const ACTIONS = {
  ACCEPT: {
    key: 'ACCEPT',
    label: 'Accept',
    type: 'PRIMARY',
  },
  ACCEPTCONFIRM: {
    key: 'ACCEPTCONFIRM',
    label: 'Accept & Confirm',
    type: 'PRIMARY',
  },
  CONFIRM: {
    key: 'CONFIRM',
    label: 'Confirm',
    type: 'PRIMARY',
  },
  APPROVE: {
    key: 'APPROVE',
    label: 'Approve',
    type: 'PRIMARY',
  },
  REJECT: {
    key: 'REJECT',
    label: 'Decline',
    type: 'PRIMARY',
  },
  SEND_FOR_PAYMENT: {
    key: 'SEND_FOR_PAYMENT',
    label: 'Send for payment',
    type: 'PRIMARY',
  },
  REMOVE_TEAM_MEMBER: {
    key: 'REMOVE_TEAM_MEMBER',
    label: 'Remove team members',
    type: 'PRIMARY',
  },
  CANCEL: {
    key: 'CANCEL',
    label: 'Cancel bookings',
    type: 'PRIMARY',
  },
};

const AVAILABLE_ACTIONS = {
  // booking status
  PENDING: ['ACCEPT', 'ACCEPTCONFIRM', 'REJECT'],
  ACCEPTED: ['REMOVE_TEAM_MEMBER', 'CANCEL', 'CONFIRM'],
  CONFIRMED: ['REMOVE_TEAM_MEMBER', 'CANCEL'],
  COMPLETED__REQUIRES_APPROVAL: ['APPROVE'],
  COMPLETED__READY_FOR_BILLING: ['SEND_FOR_PAYMENT'],
  CUSTOMER_CANCELLED_WITH_FEE__REQUIRES_APPROVAL: ['APPROVE'],
  CUSTOMER_CANCELLED_WITH_FEE__READY_FOR_BILLING: ['SEND_FOR_PAYMENT'],
};

export { ACTIONS, AVAILABLE_ACTIONS };
