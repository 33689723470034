/* BOOM-475 - fix linting errors */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Stack, Text, Inline, Button } from 'design-components';
import { IRootState } from 'stores/rematch/root-store';
import { ModalType } from '../components/ViewProfilePictureModal';
import { ICustomerAttachment } from 'interfaces/customer-interfaces';
import { Medias } from 'design-components/medias';
import { Eye, ImageEdit, ImagePlus, Trash } from '@good/icons';

export interface IStep1AddEditProfilePictureModalProps {
  onNextStep: () => void;
  onRemove: (type: ModalType, item?: ICustomerAttachment) => void;
}

const Step1AddEditProfilePictureModal = (props: IStep1AddEditProfilePictureModalProps) => {
  const { onNextStep, onRemove } = props;
  const selectedCustomer = useSelector((state: IRootState) => state.customersStore.selectedCustomer);

  const [isProfilePictureLoadError, setIsProfilePictureLoadError] = useState<boolean>(false);

  const isAddNewProfilePicture = !selectedCustomer?.attachmentUrl || isProfilePictureLoadError;
  return (
    <Stack justifyContent={'space-between'} height={'100%'}>
      <Stack paddingTop={'$space100'}>
        <Text size="medium" color="$bodyDark2">
          A profile picture helps team members recognise customers on both the workspace and app.
        </Text>

        <Inline alignItems={'center'} justifyContent="flex-start" marginTop={'$space200 !important'}>
          <Eye className="text-h-md" />
          <Text size="medium" color="$bodyDark2" marginX="$space125">
            Visible across all services
          </Text>
        </Inline>
        <Inline alignItems={'center'} justifyContent="center" marginTop={'$space400 !important'}>
          <Medias
            className="position-relative align-center inline-flex justify-center"
            shape="circle"
            icon="user"
            stroked={false}
            style={{
              position: 'relative',
              backgroundColor: 'transparent',
              fontSize: '5rem',
            }}
            size={250}
            src={selectedCustomer.attachmentUrl}
            imageProps={{
              onError: () => {
                setIsProfilePictureLoadError(true);
                return true;
              },
              onLoad: () => {
                setIsProfilePictureLoadError(false);
              },
            }}
          />
        </Inline>
      </Stack>

      <Inline alignItems={'center'} justifyContent="flex-end" marginTop={'46px !important'}>
        <Button
          width={122}
          padding={'$space75 $space250'}
          size={'large'}
          emphasis="quiet"
          gap={'$space50'}
          marginRight="$space200"
          kind={'critical'}
          onClick={() => {
            !isAddNewProfilePicture && onRemove(ModalType.REMOVE_PROFILE_PICTURE);
          }}
          customStyles={{
            cursor: isAddNewProfilePicture ? 'not-allowed !important' : 'pointer',
            border: 'none',
            color: isAddNewProfilePicture ? '$bodyDark1' : '$redDark3',
            fontWeight: '$semibold',
            fontSize: '$size175',
          }}
          isDisabled={isAddNewProfilePicture}
        >
          <Trash className={`${isAddNewProfilePicture ? 'grey-dark-1' : 'red'}`} />
          Remove
        </Button>
        <Button
          backgroundColor={'$blueDark3'}
          padding={'0 $space250'}
          width={109}
          size={'large'}
          emphasis="filled"
          gap={'$space50'}
          onClick={onNextStep}
          customStyles={{
            fontSize: '$size175',
            fontWeight: '$semibold',
          }}
        >
          {isAddNewProfilePicture ? <ImageEdit /> : <ImagePlus />}
          {isAddNewProfilePicture ? 'Add' : 'Change'}
        </Button>
      </Inline>
    </Stack>
  );
};

export default Step1AddEditProfilePictureModal;
