/* eslint-disable */
import React, { PureComponent } from 'react';
import { Text } from 'common-components/typography';
import _ from 'lodash';

interface IStepsProps {
  steps: any;
  currentStep: number;
}

export default class Steps extends PureComponent<IStepsProps, any> {
  render() {
    const { steps, currentStep } = this.props;
    const items = steps.map(function (step) {
      let stepNumFiller = step.stepNumber <= currentStep ? 'step-item step-item-active' : 'step-item';
      let lineFiller =
        step.stepNumber <= currentStep && step.stepNumber != 1
          ? 'step-filler-active'
          : step.stepNumber != 1
          ? 'step-filler'
          : null;
      return (
        <React.Fragment key={step.stepNumber}>
          <div className={lineFiller} />
          <div className={stepNumFiller}>{step.stepLabel}</div>
          {step.stepTitle && <Text className={'mr-small'}>{step.stepTitle}</Text>}
        </React.Fragment>
      );
    });
    return <div className="step-container">{items}</div>;
  }
}
