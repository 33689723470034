import { useTooltip, useTooltipTrigger } from '@react-aria/tooltip';
import { mergeProps } from '@react-aria/utils';
import { TooltipTriggerProps, useTooltipTriggerState } from '@react-stately/tooltip';
import React, { PropsWithChildren, useEffect, useLayoutEffect, useState } from 'react';

import { isMobile, isTablet } from 'react-device-detect';

import { BaseStyleProps, BoxStyleProps, compose, css, useStyleProps } from '..';
import { getOverlayArrowStyles, getOverlayStyles, Placement, transformOutOfViewContent } from '../utils/overlay';
import Portal from '../utils/portal';
import * as styles from './tooltip.css';

export type TooltipProps = PropsWithChildren<
  BaseStyleProps &
    TooltipTriggerProps & {
      /**
       * The content shown in the tooltip
       */
      content: React.ReactNode;

      /**
       * Whether placement of tooltip
       */
      placement?: Placement;

      /**
       * Whether tooltip disappear immediately when hover away
       */
      isCloseImmediate?: boolean;
      /**
       * Whether tooltip auto align into view
       */
      autoAlign?: boolean;
    }
>;

const Tooltip = (props: TooltipProps) => {
  const { placement = 'top left', content, children, trigger, isCloseImmediate, autoAlign = false } = props;

  const ref = React.useRef<HTMLDivElement>();
  const overlayRef = React.useRef<HTMLDivElement>();

  const [portalPopoverStyles, setPortalPopoverStyles] = useState<BoxStyleProps>({
    position: 'fixed',
    top: 0,
    left: 0,
  });
  const [portalArrowStyles, setPortalArrowStyles] = useState<BoxStyleProps>({
    position: 'fixed',
    top: 0,
    left: 0,
  });

  const state = useTooltipTriggerState(props);

  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref);
  const { tooltipProps: tooltipContentProps } = useTooltip(tooltipProps, state);
  const { styleProps } = useStyleProps(props);
  const isClickableTooltip = trigger === 'focus' || isMobile || isTablet;

  const openTooltip = () => {
    isClickableTooltip && state.open();
  };

  useEffect(() => {
    if (isMobile) {
      const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target) && isClickableTooltip) state.close();
      };

      document.addEventListener('pointerdown', handleClickOutside);
      return () => {
        document.removeEventListener('pointerdown', handleClickOutside);
      };
    }
  }, [overlayRef]);

  useLayoutEffect(() => {
    if (state.isOpen) {
      const triggerPosition = ref.current.getBoundingClientRect();
      const adjustPosition = () => {
        const offset = { top: 10, right: 10, left: 10, bottom: 10 };
        setPortalPopoverStyles(getOverlayStyles({ placement, triggerPosition, offset }));
        setPortalArrowStyles(getOverlayArrowStyles(placement, triggerPosition, offset));
      };
      adjustPosition();
      overlayRef.current.focus();
      window.addEventListener('resize', adjustPosition);
      return () => {
        window.removeEventListener('resize', adjustPosition);
      };
    }
  }, [state.isOpen]);

  useLayoutEffect(
    function adjustOverlayPosition() {
      if (!autoAlign) {
        return;
      }
      const popoverPosition = overlayRef.current?.getBoundingClientRect();
      if (popoverPosition) {
        const overviewTransformStyles = transformOutOfViewContent(popoverPosition);
        overlayRef.current.style.transform = (portalPopoverStyles.transform ?? ' ') + ` ${overviewTransformStyles}`;
        setPortalArrowStyles({
          ...portalArrowStyles,
          transform: (portalArrowStyles.transform ?? '') + ` ${overviewTransformStyles}`,
        });
      }
    },
    [portalPopoverStyles],
  );

  const mergedTriggerProps = mergeProps(
    {
      onPointerLeave: () => {
        if (isCloseImmediate) {
          state.close(true);
        }
      },
    },
    triggerProps,
  );
  return (
    <div className={css(styles.tooltip, styleProps)()}>
      <div ref={ref} {...mergedTriggerProps} onClick={openTooltip}>
        {children}
      </div>

      {state.isOpen && (
        <Portal>
          <div
            tabIndex={0}
            ref={overlayRef}
            className={compose(css(styles.tooltipContent, portalPopoverStyles))}
            {...tooltipContentProps}
          >
            {content}
            <div className={compose(css(styles.arrowTooltip, portalArrowStyles))}>
              <span className={compose(css(styles.arrowTooltipContent))}></span>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default Tooltip;
