import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Edit2 = forwardRef(function Edit2(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.84 3.903a1.25 1.25 0 0 0-1.68 0 4.745 4.745 0 0 0-.13.127l-.307.307a1.903 1.903 0 0 0 1.94 1.94l.306-.307c.086-.086.11-.11.127-.13a1.25 1.25 0 0 0 0-1.68 4.649 4.649 0 0 0-.127-.13 4.645 4.645 0 0 0-.128-.127Zm-1.448 3.645a3.428 3.428 0 0 1-1.94-1.94l-5.355 5.355c-.807.807-1.107 1.117-1.314 1.48-.206.365-.316.782-.593 1.889l-.16.637.638-.16c1.107-.276 1.524-.387 1.888-.593.364-.206.674-.506 1.48-1.313l5.356-5.355ZM16.15 2.793a2.75 2.75 0 0 1 3.7 0c.048.045.1.097.168.164l.012.013.013.012c.067.068.119.12.163.168a2.75 2.75 0 0 1-.163 3.868l-.013.012-6.933 6.933-.074.074c-.708.708-1.165 1.166-1.728 1.485-.563.318-1.19.475-2.162.718l-.101.025-1.85.463a.75.75 0 0 1-.91-.91l.463-1.85.025-.101c.243-.972.4-1.6.718-2.162.319-.563.776-1.02 1.485-1.729l.073-.073L15.97 2.97l.012-.013c.067-.067.12-.12.168-.163ZM3.25 21a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
