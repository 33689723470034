import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const RotateCcw = forwardRef(function RotateCcw(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.77 4.924C6.363 3.596 8.777 2.25 12 2.25A9.731 9.731 0 0 1 21.75 12c0 5.385-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12a.75.75 0 0 1 1.5 0 8.25 8.25 0 0 0 16.5 0A8.231 8.231 0 0 0 12 3.75c-2.777 0-4.863 1.154-6.27 2.326-.49.408-.894.816-1.212 1.174H7a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 8V3.5a.75.75 0 0 1 1.5 0v2.372c.293-.303.633-.626 1.02-.948Z"
        fill="currentColor"
      />
    </Icon>
  );
});
