import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { notification, Row, Skeleton } from 'antd';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import firebaseApp from 'stores/firebase-app';
import {
  WorkflowTemplateStepApprovalType,
  WorkflowTemplateStepType,
  WorkflowTemplateTriggerType,
} from 'utilities/enum-utils';
import { Text } from 'common-components/typography';
import { ref, uploadBytesResumable } from 'firebase/storage';
import AddEditStepModal from './components/AddEditStepModal';
import StepViewListItem from './components/StepViewListItem';
import ConfirmMovingStepModal from './components/ConfirmMovingStepModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import PreviewFormModal from 'views/form-builder/components/PreviewFormModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { IWorkflowTemplate, IWorkflowTemplateStep } from 'interfaces/workflow-interfaces';

import CancelChangeWorkflowTemplateModal from './components/CancelChangeWorkflowTemplateModal';
import ConfirmChangeWorkflowTemplateModal from './components/ConfirmChangeWorkflowTemplateModal';
import ConfirmRemoveWorkflowTemplateModal from './components/ConfirmRemoveWorkflowTemplateModal';
import { withRouter, WithRouterProps } from 'utilities/with-router';

interface IWorkflowTemplateDetailStepsPanelProps extends WithRouterProps {
  enableEditStep: boolean;
  isDisabledEditStep?: boolean;
  isRefreshSteps?: boolean;
  selectedWorkflowTemplate: typeof state.workflowStore.selectedWorkflowTemplate;
  portalUser: typeof state.authStore.portalUser;

  onToggleVisibleEditStep(): void;
  onSetDataChangeState(isDataChanged: boolean): void;
  doGetWorkflowTemplateSteps: typeof dispatch.workflowStore.doGetWorkflowTemplateSteps;
  doUpdateWorkflowTemplateSteps: typeof dispatch.workflowStore.doUpdateWorkflowTemplateSteps;
  setSelectedWorkflowTemplate: typeof dispatch.workflowStore.setSelectedWorkflowTemplate;
  setStateSaveStepDataWorkflowTemplate: typeof dispatch.workflowStore.setStateSaveStepDataWorkflowTemplate;
  doGetFormDetailsByVersion: typeof dispatch.formBuilderStore.doGetFormDetailsByVersion;
  currentForm: typeof state.formBuilderStore.currentForm;
  providerTimezone: string;
}

interface IWorkflowTemplateDetailStepsPanelState {
  selectedWorkflowData: IWorkflowTemplate;
  selectedWorkflowDragDrop: IWorkflowTemplate;
  selectedStep: IWorkflowTemplateStep;
  isLoading: boolean;
  isUpdating: boolean;
  isOpenConfirmMovingStepModal: boolean;
  isOpenConfirmRemoveStepModal: boolean;
  isOpenAddEditStepModal: boolean;
  isOpenCancelChangeModal: boolean;
  isOpenConfirmSaveChangeModal: boolean;
  isPreviewModalOpen: boolean;
}

class WorkflowTemplateDetailStepsPanel extends Component<
  IWorkflowTemplateDetailStepsPanelProps,
  IWorkflowTemplateDetailStepsPanelState
> {
  state = {
    selectedWorkflowData: _.cloneDeep(this.props.selectedWorkflowTemplate),
    selectedWorkflowDragDrop: null,
    selectedStep: null,
    isLoading: false,
    isUpdating: false,
    isOpenConfirmMovingStepModal: false,
    isOpenConfirmRemoveStepModal: false,
    isOpenAddEditStepModal: false,
    isOpenCancelChangeModal: false,
    isOpenConfirmSaveChangeModal: false,
    isPreviewModalOpen: false,
  };

  private _renumberStepsData = (isManualTrigger, steps) => {
    return steps.map((one, stepNumber) => ({ ...one, stepNumber: isManualTrigger ? stepNumber + 1 : stepNumber }));
  };

  private _onFetchWorkflowStep = async () => {
    const { doGetWorkflowTemplateSteps, selectedWorkflowTemplate, setSelectedWorkflowTemplate } = this.props;

    this.setState({ isLoading: true });

    const workflowTemplateSteps = await doGetWorkflowTemplateSteps({
      workflowTemplateId: selectedWorkflowTemplate.workflowTemplateId,
    });

    setSelectedWorkflowTemplate({ ...selectedWorkflowTemplate, steps: workflowTemplateSteps });

    this.setState({ isLoading: false });
  };

  private _onOpenCancelChangeModal = () => {
    if (_.isEqual(this.state.selectedWorkflowData, this.props.selectedWorkflowTemplate)) {
      this.props.onToggleVisibleEditStep();
      return;
    }

    this.setState({ isOpenCancelChangeModal: true });
  };

  private _onCloseCancelChangeModal = () => this.setState({ isOpenCancelChangeModal: false });

  private _onOpenConfirmSaveChangeModal = () => this.setState({ isOpenConfirmSaveChangeModal: true });

  private _onCloseConfirmSaveChangeModal = () => this.setState({ isOpenConfirmSaveChangeModal: false });

  private _onOpenConfirmDeleteStepModal = (step) =>
    this.setState({ isOpenConfirmRemoveStepModal: true, selectedStep: step });

  private _onCloseConfirmDeleteStepModal = () =>
    this.setState({ isOpenConfirmRemoveStepModal: false, selectedStep: null });

  private _onOpenAddEditStepModal = (step) => this.setState({ isOpenAddEditStepModal: true, selectedStep: step });

  private _onCloseAddEditStepModal = () => this.setState({ isOpenAddEditStepModal: false, selectedStep: null });

  private _onConfirmRemoveStep = () => {
    this.setState({ isUpdating: true });

    const selectedWorkflowTemplate = _.clone(this.state.selectedWorkflowData);
    const stepIndex = selectedWorkflowTemplate.steps.findIndex(
      (step) => step.stepNumber === this.state.selectedStep.stepNumber,
    );

    if (stepIndex === -1) {
      return;
    }

    selectedWorkflowTemplate.steps.splice(stepIndex, 1);

    const steps = this._renumberStepsData(
      selectedWorkflowTemplate.triggerType === WorkflowTemplateTriggerType.MANUAL,
      selectedWorkflowTemplate.steps,
    );

    this.setState({
      selectedWorkflowData: { ...selectedWorkflowTemplate, steps },
      isUpdating: false,
      isOpenConfirmRemoveStepModal: false,
    });
  };

  private _onSaveAddEditStepModal = (values: IWorkflowTemplateStep) => {
    const selectedWorkflowTemplate = { ...this.state.selectedWorkflowData };

    if (values.type === WorkflowTemplateStepType.STEP) {
      const stepIndex = _.findIndex(selectedWorkflowTemplate.steps, { stepNumber: values.stepNumber });

      if (stepIndex === -1 && !values.workflowTemplateStepId) {
        selectedWorkflowTemplate.steps.push(values);
      } else {
        selectedWorkflowTemplate.steps[stepIndex] = values;
      }
    } else {
      selectedWorkflowTemplate.steps[0] = values;
    }

    this.setState({
      selectedWorkflowData: selectedWorkflowTemplate,
      isOpenAddEditStepModal: false,
      selectedStep: null,
    });
  };

  private _onAddNewStep = () => {
    const { selectedWorkflowData } = this.state;

    this.setState({
      isOpenAddEditStepModal: true,
      selectedStep: {
        name: '',
        type: WorkflowTemplateStepType.STEP,
        stepNumber: selectedWorkflowData.steps[selectedWorkflowData.steps.length - 1].stepNumber + 1,
        approvalType: WorkflowTemplateStepApprovalType.ANYONE,
        attachment: null,
        workflowTemplateStepId: null,
        stepApprovers: [],
      },
    });
  };

  private _onCancelSaveChange = () => {
    this.setState({
      selectedWorkflowData: _.cloneDeep(this.props.selectedWorkflowTemplate),
      isOpenCancelChangeModal: false,
    });
    this.props.onToggleVisibleEditStep();
    this.props.onSetDataChangeState(false);
    this.props.setStateSaveStepDataWorkflowTemplate(false);
  };

  private _onConfirmSaveChange = async () => {
    const { selectedWorkflowData } = this.state;
    const { setStateSaveStepDataWorkflowTemplate, onSetDataChangeState, onToggleVisibleEditStep, history } = this.props;

    onToggleVisibleEditStep();
    onSetDataChangeState(false);
    setStateSaveStepDataWorkflowTemplate(false);

    if (_.isEqual(selectedWorkflowData, this.props.selectedWorkflowTemplate)) {
      this.setState({ isOpenConfirmSaveChangeModal: false });
      return;
    }

    const payload = {
      workflowTemplateUniqueId: selectedWorkflowData.workflowTemplateUniqueId,
      steps: selectedWorkflowData.steps,
    };

    this.setState({ isUpdating: true, isOpenConfirmSaveChangeModal: false });

    const response: any = await this.props.doUpdateWorkflowTemplateSteps(payload);

    if (response) {
      notification.success({
        message: 'Workflow updated',
        description: 'You have successfully updated and created a new version of this workflow',
      });
    }

    const workflowStepResult = response.workflowTemplate.steps;

    this.setState({ isUpdating: false });

    const uploadTask = _.map(payload.steps, (step, index) => {
      if (step.attachment && step.attachment.file) {
        const metaData = {
          customMetadata: {
            documentId: workflowStepResult[index].attachment.documentId,
            workflowAttachmentId: workflowStepResult[index].attachment.workflowAttachmentId,
            serviceProviderId: this.props.portalUser.serviceProviderId,
            workflowTemplateId: response.workflowTemplate.workflowTemplateId,
          },
        };

        const storageRef = ref(firebaseApp.storage, `${response.uploadBucketUrl}/${step.attachment.file.name}`);
        const uploadFile = uploadBytesResumable(storageRef, step.attachment.file, metaData);

        return uploadFile.on(
          'state_changed',
          () => ({}),
          (error) => {
            this.setState({ isLoading: false });
            notification.error({ message: 'Upload failed! Please try again.', description: error });
          },
          () => {
            this.setState({ isLoading: false });
            notification.success({ message: 'Document is currently scanning.' });
          },
        );
      }
    });

    try {
      await Promise.all(uploadTask);
    } catch (e) {
      notification.error({ message: 'Upload failed! Please try again.' });
    }

    history.push(`/account/workflows/${response.workflowTemplate.workflowTemplateId}`);
  };

  private _onDragEnd = (result: DropResult) => {
    if (
      result.destination.index === 0 &&
      this.state.selectedWorkflowData.triggerType !== WorkflowTemplateTriggerType.MANUAL
    ) {
      return;
    }

    if (result.destination.index == result.source.index) {
      return;
    }

    const selectedWorkflowTemplate = _.cloneDeep(this.state.selectedWorkflowData);

    const [removed] = selectedWorkflowTemplate.steps.splice(result.source.index, 1);

    selectedWorkflowTemplate.steps.splice(result.destination.index, 0, removed);

    const steps = this._renumberStepsData(
      selectedWorkflowTemplate.triggerType === WorkflowTemplateTriggerType.MANUAL,
      selectedWorkflowTemplate.steps,
    );

    this.setState({
      selectedWorkflowData: { ...selectedWorkflowTemplate, steps },
    });
  };

  private _onCloseConfirmMovingStepModal = () => {
    this.setState({ isOpenConfirmMovingStepModal: false, selectedWorkflowDragDrop: null });
  };

  private _onConfirmMovingStep = async () => {
    const { selectedWorkflowDragDrop } = this.state;

    this.setState({
      selectedWorkflowData: selectedWorkflowDragDrop,
      isOpenConfirmMovingStepModal: false,
      selectedWorkflowDragDrop: null,
    });
  };

  private _onPreviewFormClick = async (step) => {
    const { doGetFormDetailsByVersion } = this.props;

    try {
      await doGetFormDetailsByVersion({
        formId: step.formId,
        versionId: step.formVersionId,
        isWorkflowFormVersion: true,
      });
      this.setState({ isPreviewModalOpen: true });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  private _onClosePreviewModal = () => {
    this.setState({ isPreviewModalOpen: false });
  };

  componentDidUpdate(prevProps) {
    const { selectedWorkflowData } = this.state;

    if (!_.isEqual(this.props.selectedWorkflowTemplate, prevProps.selectedWorkflowTemplate)) {
      this.setState({ selectedWorkflowData: _.cloneDeep(this.props.selectedWorkflowTemplate) });
    }

    if (!_.isEqual(this.props.selectedWorkflowTemplate, selectedWorkflowData) && this.props.enableEditStep) {
      const isDataChange = true;
      this.props.onSetDataChangeState(isDataChange);
    }

    if (prevProps.isRefreshSteps !== this.props.isRefreshSteps && this.props.isRefreshSteps) {
      this._onFetchWorkflowStep();
    }
  }

  componentDidMount() {
    this._onFetchWorkflowStep();
  }

  render() {
    const { enableEditStep, onToggleVisibleEditStep, isDisabledEditStep, currentForm, providerTimezone } = this.props;
    const {
      isLoading,
      isUpdating,
      selectedWorkflowData,
      selectedStep,
      isOpenConfirmMovingStepModal,
      isOpenConfirmRemoveStepModal,
      isOpenAddEditStepModal,
      isOpenCancelChangeModal,
      isOpenConfirmSaveChangeModal,
      isPreviewModalOpen,
    } = this.state;

    if (isLoading) {
      return (
        <div>
          <Skeleton active title />
          <Skeleton active title />
        </div>
      );
    }

    const isNothingChange = _.isEqual(selectedWorkflowData, this.props.selectedWorkflowTemplate);

    return (
      <>
        <div className="bg-quaternary rounded-big height-full">
          <Row className="mb-x-large" type="flex" align="middle" justify="space-between">
            <Text size="x2-large" weight="bolder">
              Steps
            </Text>
            {!enableEditStep ? (
              <SecondaryButton size="large" onClick={onToggleVisibleEditStep} disabled={isDisabledEditStep}>
                Edit/add steps
              </SecondaryButton>
            ) : (
              <PrimaryButton size="large" icon="plus" onClick={this._onAddNewStep} disabled={isDisabledEditStep}>
                Add steps
              </PrimaryButton>
            )}
          </Row>

          <DragDropContext onDragEnd={this._onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {selectedWorkflowData &&
                    selectedWorkflowData.steps &&
                    selectedWorkflowData.steps.map((step, index) => (
                      <Draggable
                        key={index}
                        draggableId={`${index}`}
                        index={index}
                        isDragDisabled={step.type === WorkflowTemplateStepType.TRIGGER || !enableEditStep}
                      >
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <StepViewListItem
                              key={index}
                              step={step}
                              isViewState={!enableEditStep}
                              amountStep={selectedWorkflowData.steps.length}
                              isManualTrigger={selectedWorkflowData.triggerType === WorkflowTemplateTriggerType.MANUAL}
                              onDeleteStep={() => this._onOpenConfirmDeleteStepModal(step)}
                              onEditStep={() => this._onOpenAddEditStepModal(step)}
                              onPreviewForm={this._onPreviewFormClick}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {enableEditStep && (
            <Row className="mt-large" type="flex" align="middle" justify="end">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onOpenCancelChangeModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                disabled={isNothingChange}
                loading={isUpdating}
                size="large"
                onClick={this._onOpenConfirmSaveChangeModal}
              >
                Save changes
              </PrimaryButton>
            </Row>
          )}
        </div>

        <ConfirmMovingStepModal
          isOpen={isOpenConfirmMovingStepModal}
          isUpdating={isUpdating}
          onClose={this._onCloseConfirmMovingStepModal}
          onConfirm={this._onConfirmMovingStep}
        />

        <ConfirmRemoveWorkflowTemplateModal
          isOpen={isOpenConfirmRemoveStepModal}
          isUpdating={isUpdating}
          onClose={this._onCloseConfirmDeleteStepModal}
          onConfirm={this._onConfirmRemoveStep}
        />

        <AddEditStepModal
          isOpen={isOpenAddEditStepModal}
          stepDetail={selectedStep}
          onClose={this._onCloseAddEditStepModal}
          onUpdateStep={this._onSaveAddEditStepModal}
        />

        <CancelChangeWorkflowTemplateModal
          isOpen={isOpenCancelChangeModal}
          onConfirm={this._onCancelSaveChange}
          onClose={this._onCloseCancelChangeModal}
        />

        <ConfirmChangeWorkflowTemplateModal
          isOpen={isOpenConfirmSaveChangeModal}
          onConfirm={this._onConfirmSaveChange}
          onClose={this._onCloseConfirmSaveChangeModal}
        />

        {isPreviewModalOpen && (
          <PreviewFormModal
            timezone={providerTimezone}
            formContent={currentForm}
            isOpen={isPreviewModalOpen}
            onClose={this._onClosePreviewModal}
          />
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedWorkflowTemplate: state.workflowStore.selectedWorkflowTemplate,
  portalUser: state.authStore.portalUser,
  currentForm: state.formBuilderStore.currentForm,
  providerTimezone: state.companyStore.companyDataLite.timezone,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedWorkflowTemplate: dispatch.workflowStore.setSelectedWorkflowTemplate,
  doGetWorkflowTemplateSteps: dispatch.workflowStore.doGetWorkflowTemplateSteps,
  doUpdateWorkflowTemplateSteps: dispatch.workflowStore.doUpdateWorkflowTemplateSteps,
  setStateSaveStepDataWorkflowTemplate: dispatch.workflowStore.setStateSaveStepDataWorkflowTemplate,
  doGetFormDetailsByVersion: dispatch.formBuilderStore.doGetFormDetailsByVersion,
});

export default connect(mapState, mapDispatch)(withRouter(WorkflowTemplateDetailStepsPanel));
