import { notification } from 'antd';
import ActionModal from 'common-components/modal/ActionModal';
import { IWorkflowTemplate } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import WorkflowTemplateStepNameSection from 'views/workflows/workflow-templates/components/WorkflowTemplateStepNameSection';

interface IUpdateWorkflowTemplateNameModalProps {
  workflowTemplate: IWorkflowTemplate;
  isOpen: boolean;
  onClose: () => void;
  doUpdateWorkflowTemplateConfigurations: typeof dispatch.workflowStore.doUpdateWorkflowTemplateConfigurations;
}

interface IUpdateWorkflowTemplateNameModalState {
  isUpdating: boolean;
}

class UpdateWorkflowTemplateNameModal extends PureComponent<
  IUpdateWorkflowTemplateNameModalProps,
  IUpdateWorkflowTemplateNameModalState
> {
  state = {
    isUpdating: false,
  };

  private _onUpdateWorkflowNameAndDescription = async (values) => {
    const { doUpdateWorkflowTemplateConfigurations } = this.props;

    this.setState({ isUpdating: true });

    const isUpdateSuccess = await doUpdateWorkflowTemplateConfigurations(values);

    this.setState({ isUpdating: false });

    if (isUpdateSuccess) {
      this.props.onClose();
      notification.success({ message: 'Workflow updated', description: 'You have successfully updated this workflow' });
      return;
    }

    notification.error({ message: 'Updated failed', description: 'The workflow name must be unique' });
  };

  render() {
    const { workflowTemplate, isOpen, onClose } = this.props;
    const { isUpdating } = this.state;

    return (
      <div>
        <ActionModal isOpen={isOpen} onClose={onClose} title="Workflow details" verticalAlignment="high">
          {workflowTemplate && (
            <WorkflowTemplateStepNameSection
              addWorkflowTemplateWizard={workflowTemplate}
              isEdit
              isEditing={isUpdating}
              onClose={onClose}
              onSubmit={this._onUpdateWorkflowNameAndDescription}
            />
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateWorkflowTemplateConfigurations: dispatch.workflowStore.doUpdateWorkflowTemplateConfigurations,
});

export default connect(null, mapDispatch)(UpdateWorkflowTemplateNameModal);
