import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const WalletPlus = forwardRef(function WalletPlus(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3.75c.964 0 1.612.002 2.095.067.461.062.659.169.789.3.13.13.238.327.3.788.046.346.06.776.064 1.345H4a1.25 1.25 0 0 1 0-2.5h10ZM1.25 5A2.75 2.75 0 0 1 4 2.25h10.052c.899 0 1.648 0 2.243.08.627.084 1.194.27 1.65.725.455.456.64 1.023.725 1.65.06.447.075.98.079 1.598.227.016.441.036.643.064.9.12 1.658.38 2.26.981.602.602.86 1.36.982 2.26.086.645.108 1.42.114 2.338a.752.752 0 0 1 .002.054v4c0 .018 0 .037-.002.055-.006.917-.028 1.692-.114 2.337-.121.9-.38 1.658-.982 2.26-.602.602-1.36.86-2.26.982-.867.116-1.97.116-3.337.116h-8.11c-1.367 0-2.47 0-3.337-.116-.9-.122-1.658-.38-2.26-.982-.601-.602-.86-1.36-.981-2.26-.117-.867-.117-1.97-.117-3.337V5Zm20 10v.25H20a1.25 1.25 0 0 1 0-2.5h1.25V15Zm-.011-3.75H20a2.75 2.75 0 1 0 0 5.5h1.239c-.012.569-.038 1.04-.092 1.442-.099.734-.28 1.122-.556 1.399-.277.277-.665.457-1.399.556-.755.101-1.756.103-3.192.103H8c-1.435 0-2.436-.002-3.192-.103-.734-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191V7.45c.375.192.8.3 1.25.3h12c1.436 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4.054.4.08.872.092 1.441ZM10 10.75a.75.75 0 0 1 .75.75v1.75h1.75a.75.75 0 0 1 0 1.5h-1.75v1.75a.75.75 0 0 1-1.5 0v-1.75H7.5a.75.75 0 0 1 0-1.5h1.75V11.5a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
