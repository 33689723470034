import { Icon, List } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { ICard } from 'interfaces/subscription-interfaces';
import _ from 'lodash';
import moment from 'moment';
import ScrollContainer from 'react-indiana-drag-scroll';
import React from 'react';
import { COMMON_MODAL_ACTION } from 'views/account-management/subscription-management/utils/constants';
import CardItem from './CardItem';
import { Button, Card } from '@good/ui/core';

interface IProps {
  timeZone: string;
  hasPermission: boolean;
  paymentMethods: ICard[];
  openCreditCardModal: () => void;
  openEditCardModal: (card: ICard) => void;
  setDefaultPayment: (card: ICard) => void;
  openCommonModal: (type: COMMON_MODAL_ACTION, card?: ICard) => void;
}

const PaymentMethodListing = (props: IProps) => {
  const {
    timeZone,
    openCreditCardModal,
    openEditCardModal,
    hasPermission,
    openCommonModal,
    setDefaultPayment,
    paymentMethods,
  } = props;

  const currentTime = moment.tz(new Date(), timeZone);

  const onlyCardDefault = paymentMethods.length === 1 && paymentMethods[0]?.isDefault;

  const _openRemoveCardModal = (card: ICard) => {
    if (onlyCardDefault) {
      return openCommonModal(COMMON_MODAL_ACTION.ADD_DEFAULT_PAYMENT_METHOD);
    }
    if (paymentMethods.length >= 2 && card.isDefault) {
      return openCommonModal(COMMON_MODAL_ACTION.SET_DEFAULT_PAYMENT_METHOD);
    }
    return openCommonModal(COMMON_MODAL_ACTION.REMOVE_PAYMENT_METHOD, card);
  };

  const _checkCardExpiry = (card: ICard) => {
    const currentYear = Number(currentTime.format('YYYY'));
    const currentMonth = Number(currentTime.format('M'));

    if (currentYear > card?.expYear) {
      return true;
    }
    if (currentYear === card?.expYear && currentMonth > card?.expMonth) {
      return true;
    }
    return false;
  };

  const _sortPaymentMethods = () => {
    const defaultCard = _.filter(paymentMethods, (card) => card.isDefault);
    const otherCards = _.filter(paymentMethods, (card) => !card.isDefault).reverse();
    return [...defaultCard, ...otherCards];
  };

  return (
    <Card p='xl' withBorder>
      <div className='flex justify-between pb-medium'>
        <Text size='x2-large' weight='bolder'>
          Payment methods
        </Text>
        {hasPermission && (
          <Button variant='outline' leftSection={<Icon type='plus' />} onClick={openCreditCardModal}>
            Add a card
          </Button>
        )}
      </div>
      {!_.isEmpty(paymentMethods) && (
        <ScrollContainer hideScrollbars={false} style={{ maxHeight: 360 }}>
          <List
            dataSource={_sortPaymentMethods()}
            renderItem={(card: ICard, key) => {
              return (
                <CardItem
                  card={card}
                  hasPermission={hasPermission}
                  onlyCardDefault={onlyCardDefault}
                  isCardExpiry={_checkCardExpiry(card)}
                  hasDivider={key !== paymentMethods.length - 1}
                  setDefaultPayment={() => setDefaultPayment(card)}
                  openEditCardModal={() => openEditCardModal(card)}
                  openRemoveCardModal={() => _openRemoveCardModal(card)}
                />
              );
            }}
          />
        </ScrollContainer>
      )}
    </Card>
  );
};

export default PaymentMethodListing;
