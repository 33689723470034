import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Paragraph, Title } from 'common-components/typography';
import * as H from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';
import EditInvoicesAndCreditNotesModal from './components/FeatureConfigurationModals/EditInvoicesAndCreditNotesModal';
import ConfigCard from './components/ConfigCard';

type IFeatureConfigurationViewProps = {
  doFetchCompanyFundingAndPayment: typeof dispatch.companyStore.doFetchCompanyFundingAndPayment;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  companyFundingAndPayment: typeof state.companyStore.companyFundingAndPayment;
  portalUser: typeof state.authStore.portalUser;
  history: H.History;
  flags: Record<string, boolean>;
};

type IFeatureConfigurationViewState = {
  isLoading: boolean;
  isEditingInvoicesAndCreditNotes: boolean;
};

class FeatureConfigurationView extends Component<IFeatureConfigurationViewProps, IFeatureConfigurationViewState> {
  state = {
    isLoading: true,
    isEditingInvoicesAndCreditNotes: false,
  };

  private _goToLandingPage = () => {
    this.props.history.push('/account/landing');
  };

  private _openEditInvoicesAndCreditNotes = () => {
    this.setState({ isEditingInvoicesAndCreditNotes: true });
  };

  private _closeEditInvoicesAndCreditNotes = async () => {
    this.setState({ isEditingInvoicesAndCreditNotes: false });
  };

  componentDidMount = async () => {
    await this.props.doFetchCompanyFundingAndPayment({});
    this.setState({ isLoading: false });
  };

  render() {
    const { portalUser, flags } = this.props;

    const isViewFinanceFileExportConfiguration = PermissionUtils.validatePermission(
      'ViewFinanceFileExportConfiguration',
      portalUser?.permissions?.permissionRoles,
    );

    return (
      <>
        {flags['invoicingEmailControl'] && this.state.isEditingInvoicesAndCreditNotes && (
          <EditInvoicesAndCreditNotesModal
            isOpen={this.state.isEditingInvoicesAndCreditNotes}
            onClose={this._closeEditInvoicesAndCreditNotes}
            isOutGoingInvoicesEmailEnabled={
              this.props.companyFundingAndPayment && this.props.companyFundingAndPayment.isOutGoingInvoicesEmailEnabled
            }
          />
        )}
        <div className='pb-x4-large'>
          <div className='mb-medium'>
            <Title level={3} weight='regular' className='mv-none'>
              <button
                className='cursor-pointer text-color-blue-action'
                onClick={this._goToLandingPage}
                onKeyDown={this._goToLandingPage}
              >
                {'Account >'}
              </button>
              Feature Configuration
            </Title>
          </div>
          <Paragraph>
            Configure features for your Workspace. Any changes to features below will take effect for your entire
            organisation’s Workspace settings and behaviour.
          </Paragraph>
          <div className='mt-x-large bordered-bottom border-standard-gray'>
            <ConfigCard
              title='Terms & conditions templates'
              description='Create and save templates for use in service agreements.'
              actionLabelText='Configure'
              href='/account/features/service-agreement-templates/ndis'
            />
            <ConfigCard
              title='Time based alerts'
              description='Configure time based alerts in your system.'
              actionLabelText='Configure'
              href='/account/features/service-time-based-alerts-configure'
            />
            {isViewFinanceFileExportConfiguration && (
              <ConfigCard
                title='Finance file exports'
                description='Select from leading accounting and payroll systems for easy reconcillation.'
                actionLabelText='Configure'
                href='/account/features/finance-file-export-configuration'
              />
            )}

            {flags['invoicingEmailControl'] && (
              <ConfigCard
                title='GoodHuman invoicing'
                onClick={this._openEditInvoicesAndCreditNotes}
                actionLabelText={this.props.companyFundingAndPayment?.isOutGoingInvoicesEmailEnabled ? 'ON' : 'OFF'}
                actionLabelColor='blue-action'
                isActionLabelLoading={this.state.isLoading}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPayment: state.companyStore.companyFundingAndPayment,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCompanyFundingAndPayment: dispatch.companyStore.doFetchCompanyFundingAndPayment,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
});

export default connect(mapState, mapDispatch)(withLDConsumer()(FeatureConfigurationView));
