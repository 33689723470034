import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { Form, Icon, Input, notification, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { Information } from 'common-components/alerts';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import _ from 'lodash';
import RegistrationDetailView from 'views/account-management/components/RegistrationDetailView';
import Utils from 'utilities/Utils';

interface IEditBusinessDetailsModalProps extends FormComponentProps {
  isOpen: any;
  onClose: () => void;
  doEditBusinessDetails: typeof dispatch.companyStore.doEditBusinessDetails;
  getCompanyRegistrationDetails: typeof dispatch.companyStore.getCompanyRegistrationDetails;
  setTemporaryRegistrationData: typeof dispatch.companyStore.setTemporaryRegistrationData;
  temporaryRegistrationDetails: typeof state.companyStore.temporaryRegistrationDetails;
}

interface IEditBusinessDetailsModalState {
  title: any;
  isLoadingLookup: boolean;
  isCreated: boolean;
  hasSearched: boolean;
  errorWithRegistrationDetails: boolean;
  newRegistrationNumber: string;
}

class EditBusinessDetailsModal extends Component<IEditBusinessDetailsModalProps, IEditBusinessDetailsModalState> {
  state = {
    title: 'Change registered business details',
    isLoadingLookup: false,
    isCreated: false,
    hasSearched: false,
    newRegistrationNumber: null,
    errorWithRegistrationDetails: false,
  };

  private _onCloseModal = () => {
    this.setState({
      title: 'Change registered business details',
      isLoadingLookup: false,
      isCreated: false,
      errorWithRegistrationDetails: false,
      hasSearched: false,
      newRegistrationNumber: null,
    });
    this.props.setTemporaryRegistrationData({});
    this.props.onClose();
  };

  private _validateRegistrationNumber = (rule, value, callback) => {
    try {
      if (value.length !== 9 && value.length !== 11) {
        throw Error('Registration number can have 9 or 11 digit.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _onChangeRegisteredNumber = async (event) => {
    await this._fetchRegistrationDetails(event.target.value);
  };

  private _onRefreshRegistrationDetails = async () => {
    const registrationNumber = this.props.form.getFieldValue('newRegistrationNumber');
    if (!Utils.isEmpty(registrationNumber)) {
      await this._fetchRegistrationDetails(registrationNumber);
    }
  };

  private _fetchRegistrationDetails = async (registrationNumber) => {
    if (!_.isEmpty(this.props.temporaryRegistrationDetails)) {
      this.props.setTemporaryRegistrationData({});
    }
    this.setState({ errorWithRegistrationDetails: false, hasSearched: false });
    if (registrationNumber.length === 9 || registrationNumber.length === 11) {
      this.setState({ hasSearched: true, isLoadingLookup: true });
      try {
        await this.props.getCompanyRegistrationDetails({
          businessRegistrationNumber: registrationNumber,
          businessRegistrationNumberType: registrationNumber.length === 9 ? 'ACR' : 'ABN',
          isLookingUp: true,
        });
        this.setState({ isLoadingLookup: false });
      } catch (e) {
        if (e.meta.message === 'Business registration number does not exist') {
          this.setState({ isLoadingLookup: false });
        } else {
          this.setState({ errorWithRegistrationDetails: true, isLoadingLookup: false });
        }
      }
    }
  };

  private _editRegistrationNumber = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        const businessRegistrationNumber = form.getFieldValue('newRegistrationNumber');
        await this.props.doEditBusinessDetails({ businessRegistrationNumber });
        this.setState({
          title: (
            <>
              Change registered business details successful
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isCreated: true,
          newRegistrationNumber: businessRegistrationNumber,
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  render() {
    const { isOpen, temporaryRegistrationDetails, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isCreated ? (
          <>
            <div className="mv-medium anim-slide-left">
              <SubTitle>New ABN/CRN</SubTitle>
              <Text size={'x-large'}>{this.state.newRegistrationNumber}</Text>
            </div>
            <div className="p-medium bg-secondary width-full mt-small">
              {temporaryRegistrationDetails ? (
                <RegistrationDetailView companyRegistrationDetails={temporaryRegistrationDetails} />
              ) : (
                'No match found.'
              )}
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Done
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <Information
                  content={
                    <div>
                      <b>You must have a valid registered business to provide services on GoodHuman.</b>
                    </div>
                  }
                />
                <div className={'mt-large'}>
                  <SubTitle>Country</SubTitle>
                  <Text>
                    <Select size={'large'} style={{ width: '200px' }} defaultValue={'AU'} disabled>
                      <Select.Option value={'AU'}>Australia</Select.Option>
                    </Select>
                  </Text>
                </div>
                <div className={'mt-large'}>
                  <SubTitle>ABN/ACN</SubTitle>
                  <Form.Item className="width-full">
                    {getFieldDecorator('newRegistrationNumber', {
                      rules: [{ validator: this._validateRegistrationNumber }],
                    })(
                      <Input
                        id="registrationNumber"
                        onChange={this._onChangeRegisteredNumber}
                        size={'large'}
                        style={{ width: '250px' }}
                      />,
                    )}
                  </Form.Item>
                  <div className="p-medium bg-secondary width-full mt-small mb-x-large" style={{ height: '180px' }}>
                    {!this.state.hasSearched ? (
                      'Enter a valid ABN/ACN to begin lookup'
                    ) : this.state.isLoadingLookup ? (
                      this.state.errorWithRegistrationDetails ? (
                        'An error occurred while retrieving the details.'
                      ) : (
                        <SpinningLoader size={50} message={'Retrieving Registration details...'} />
                      )
                    ) : temporaryRegistrationDetails ? (
                      <RegistrationDetailView companyRegistrationDetails={temporaryRegistrationDetails} />
                    ) : (
                      'No match found.'
                    )}
                    {!_.isEmpty(temporaryRegistrationDetails) && (
                      <div className={'text-align-right mt-medium'}>
                        <HyperlinkButton color={'blue'} onClick={this._onRefreshRegistrationDetails}>
                          Refresh
                        </HyperlinkButton>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  disabled={_.isEmpty(temporaryRegistrationDetails)}
                  onClick={this._editRegistrationNumber}
                >
                  Change business details
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  temporaryRegistrationDetails: state.companyStore.temporaryRegistrationDetails,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditBusinessDetails: dispatch.companyStore.doEditBusinessDetails,
  getCompanyRegistrationDetails: dispatch.companyStore.getCompanyRegistrationDetails,
  setTemporaryRegistrationData: dispatch.companyStore.setTemporaryRegistrationData,
});

export default connect(mapState, mapDispatch)(Form.create<IEditBusinessDetailsModalProps>()(EditBusinessDetailsModal));
