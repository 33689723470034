import React, { Component } from 'react';
import { Form, Icon, notification, Select } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, Title, SubTitle, Text } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { INewBookingData } from 'interfaces/booking-interfaces';
import NDISLineItemGrid from 'common-components/line-items/NDISLineItemGrid';
import moment from 'moment-timezone';
import { Warning } from 'common-components/alerts';
import Validation from 'common-components/alerts/Validation';
import SelectLineItemToChargeModal from 'views/bookings/listings/components/SelectLineItemToChargeModal';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { PaymentSources, SleepoverType } from 'utilities/enum-utils';

interface IStep5PaymentDetailPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  portalUser?: typeof state.authStore.portalUser;
  doSetNewBookingData?: any;
  newBookingData?: INewBookingData | any;
  customerBookingSuggestionPaymentDetail: typeof state.bookingsStore.customerBookingSuggestionPaymentDetail;
  customerBookingPaymentDetail: typeof state.bookingsStore.customerBookingPaymentDetail;
  doFetchBookingPaymentDetail: typeof dispatch.bookingsStore.doFetchBookingPaymentDetail;
  doFetchBookingSuggestionPaymentDetail: typeof dispatch.bookingsStore.doFetchBookingSuggestionPaymentDetail;
}

interface IStep5PaymentDetailPanelState {
  isLoading: boolean;
  customerServiceAgreementLineItems: any;
  displayServiceLineItem: boolean;
  statusMessages: any;
  isSelectLineItemOpen: boolean;
  selectedPaymentSourceType: string;
}

class Step5PaymentDetailPanel extends Component<IStep5PaymentDetailPanelProps, IStep5PaymentDetailPanelState> {
  state = {
    isLoading: false,
    customerServiceAgreementLineItems: null,
    displayServiceLineItem: false,
    statusMessages: [],
    isSelectLineItemOpen: false,
    selectedLineItems: [],
    selectedPaymentSourceType: null,
  };

  private _onNextStep = async () => {
    const { doSetNewBookingData, newBookingData } = this.props;
    const { selectedPaymentSourceType } = this.state;
    try {
      await doSetNewBookingData({
        ...newBookingData,
        paymentSourceType: selectedPaymentSourceType,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
    this.props.onNextStep();
  };

  private _onPreviousStep = () => {
    this.props.onPreviousStep();
  };

  private _openSelectLineItem = () => {
    this.setState({ isSelectLineItemOpen: true });
  };

  private _closeSelectLineItem = () => {
    this.setState({ isSelectLineItemOpen: false });
  };

  private _getServiceAgreementTitle = (billingPeriod) => {
    const { customerBookingSuggestionPaymentDetail } = this.props;
    const { timezone } = this.props.portalUser;
    if (customerBookingSuggestionPaymentDetail) {
      if (
        customerBookingSuggestionPaymentDetail.billingSuggestion &&
        customerBookingSuggestionPaymentDetail.billingSuggestion.length === 1
      ) {
        if (!_.isEmpty(billingPeriod.serviceAgreementBillingItems)) {
          return 'Line items being charged (From service agreement)';
        } else {
          return 'Line items being charged (From service)';
        }
      } else {
        if (!_.isEmpty(billingPeriod.serviceAgreementBillingItems)) {
          return (
            <>
              <b>{moment.tz(billingPeriod.startDateTime, timezone).format('DD/MM/YYYY')}</b> to{' '}
              <b>{moment.tz(billingPeriod.endDateTime, timezone).format('DD/MM/YYYY')}</b> (From service agreement)
            </>
          );
        } else {
          return (
            <>
              <b>{moment.tz(billingPeriod.startDateTime, timezone).format('DD/MM/YYYY')}</b> to{' '}
              <b>{moment.tz(billingPeriod.endDateTime, timezone).format('DD/MM/YYYY')}</b> (From service)
            </>
          );
        }
      }
    }
  };

  private _onSaveSelection = (selectedLineItems) => {
    this.props.doSetNewBookingData({ ...this.props.newBookingData, selectedLineItems });
  };

  private _onChangePaymentSourceType = (e) => {
    this._initialisePaymentSuggestions(e);
    this.setState({ selectedPaymentSourceType: e });
  };

  private _initialisePaymentSuggestions = async (paymentSourceType) => {
    const { doFetchBookingPaymentDetail, doFetchBookingSuggestionPaymentDetail, newBookingData } = this.props;
    this.setState({ isLoading: true });
    try {
      const payload = {
        serviceId: newBookingData.selectedServiceId,
        customerUserId: newBookingData.selectedCustomerId,
        timeSlots: newBookingData.timeSlots,
        address: {
          ...newBookingData.bookLocation,
          geoLat: Number(newBookingData.bookLocation.geoLat),
          geoLng: Number(newBookingData.bookLocation.geoLng),
        },
        paymentSourceType,
        sleepoverType: newBookingData.sleepoverType,
        sleepoverTimeSlots: newBookingData.isRecurring
          ? newBookingData.sleepoverTimeSlotsRecurring
          : [newBookingData.sleepoverTimeSlots],
      };
      await doFetchBookingSuggestionPaymentDetail(payload);
      await doFetchBookingPaymentDetail(payload);
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
    }
    this.setState({ isLoading: false });
  };

  private _renderSleepoverBillingContent = () => {
    const { newBookingData } = this.props;
    const { selectedPaymentSourceType } = this.state;
    const { sleepoverType } = newBookingData;
    const sleepoverLineItemText =
      selectedPaymentSourceType === PaymentSources.NDIS ? '(line Item 01_010_0107_1_1)' : '';

    if (sleepoverType === SleepoverType.ENTIRE) {
      return (
        <div className="mb-medium">
          <SubTitle>Sleepover billing</SubTitle>
          <Text>
            This is a <Text weight="bolder">sleepover booking</Text>. The customer will be charged at the{' '}
            <Text weight="bolder">sleepover rate</Text> {sleepoverLineItemText} for the entire booking. If you&rsquo;d
            like to charge for another line item, add it in after you&rsquo;ve finished creating this booking.
          </Text>
        </div>
      );
    }

    if (sleepoverType === SleepoverType.PARTIAL) {
      return (
        <div className="mb-medium">
          <SubTitle>Sleepover billing</SubTitle>
          <Text>
            This booking is a <Text weight="bolder">partial sleepover</Text>. The customer will be charged at the{' '}
            <Text weight="bolder">sleepover rate</Text> {sleepoverLineItemText} for the specified sleepover period you
            entered. The remaining part of the shift will be billed using the line items below.
          </Text>
        </div>
      );
    }

    return null;
  };

  componentDidMount = async () => {
    const { newBookingData } = this.props;
    const paymentSourceTypes = newBookingData.selectedService.servicePaymentSources;
    const selectedPaymentSourceType =
      newBookingData && newBookingData.paymentSourceType
        ? newBookingData.paymentSourceType
        : paymentSourceTypes.length > 1
        ? null
        : paymentSourceTypes[0];
    this.setState({
      selectedPaymentSourceType,
    });
    selectedPaymentSourceType && this._initialisePaymentSuggestions(selectedPaymentSourceType);
  };

  render() {
    const { newBookingData, customerBookingPaymentDetail, customerBookingSuggestionPaymentDetail } = this.props;
    const { isSelectLineItemOpen, selectedPaymentSourceType, isLoading } = this.state;

    const hasNoServiceAgreement =
      customerBookingSuggestionPaymentDetail &&
      customerBookingSuggestionPaymentDetail.billingSuggestion &&
      customerBookingSuggestionPaymentDetail.billingSuggestion.length === 1 &&
      !customerBookingSuggestionPaymentDetail.billingSuggestion[0].serviceAgreementId;
    const hasMoreThanOneServiceAgreement =
      _.filter(
        customerBookingSuggestionPaymentDetail && customerBookingSuggestionPaymentDetail.billingSuggestion,
        (billingPeriod) => !!billingPeriod.serviceAgreementId,
      ).length > 1;
    const hasPeriodWithoutServiceAgreement =
      customerBookingSuggestionPaymentDetail &&
      _.some(
        customerBookingSuggestionPaymentDetail.billingSuggestion,
        (billingPeriod) => !billingPeriod.serviceAgreementId,
      );

    const paymentSourceTypes =
      newBookingData && newBookingData.selectedService && newBookingData.selectedService.servicePaymentSources;

    return (
      <div className="anim-slide-left">
        <SelectLineItemToChargeModal
          isOpen={isSelectLineItemOpen}
          billingPeriods={customerBookingPaymentDetail && customerBookingPaymentDetail.billingSuggestion}
          onClose={this._closeSelectLineItem}
          locationMmmGroup={customerBookingSuggestionPaymentDetail && customerBookingSuggestionPaymentDetail.mmmGroup}
          locationState={newBookingData && newBookingData.bookLocation && newBookingData.bookLocation.state}
          getServiceAgreementTitle={this._getServiceAgreementTitle}
          onSaveSelection={this._onSaveSelection}
          paymentSourceType={selectedPaymentSourceType}
        />
        <Title level={2} weight="bolder" className="line-height-100">
          <span className="text-weight-regular">Select</span> how you want to charge{' '}
          <span className="text-weight-regular">for this booking.</span>
        </Title>
        <Paragraph>
          Below are the line items we will be using for the billing of this booking. This is based on the service and
          the customers service agreement. If you would like to charge different line items please select the 'Charge
          different line items' button.
        </Paragraph>

        {paymentSourceTypes && paymentSourceTypes.length > 1 && (
          <div className={'mb-medium'}>
            <SubTitle>Funding type</SubTitle>
            <Select
              size={'large'}
              style={{ width: '200px' }}
              value={selectedPaymentSourceType}
              onChange={this._onChangePaymentSourceType}
            >
              {_.map(paymentSourceTypes, (paymentSource) => (
                <Select.Option value={PaymentSources[paymentSource]}>{PaymentSources[paymentSource]}</Select.Option>
              ))}
            </Select>
          </div>
        )}

        {isLoading ? (
          <SpinningLoader size={150} message={'Retrieving line items...'} />
        ) : (
          <>
            <div style={{ maxHeight: 'calc(100vh - 450px)', overflow: 'auto' }}>
              {customerBookingSuggestionPaymentDetail && customerBookingSuggestionPaymentDetail.billingSuggestion && (
                <div className={'mb-medium'}>
                  {!hasNoServiceAgreement && !hasPeriodWithoutServiceAgreement && (
                    <Validation
                      content={'The customer has a service agreement for the dates of the bookings.'}
                      className={'mb-small'}
                    />
                  )}
                  {hasNoServiceAgreement && (
                    <Warning
                      content={'The customer does not have a service agreement for the dates selected.'}
                      className={'mb-small'}
                    />
                  )}
                  {hasMoreThanOneServiceAgreement && (
                    <Warning
                      content={'The dates for the bookings being created occurs on more than one service agreement.'}
                      className={'mb-small'}
                    />
                  )}
                  {!hasNoServiceAgreement && hasPeriodWithoutServiceAgreement && (
                    <Warning
                      content={
                        "The customer does not have a service agreement for some of the dates you have selected. The service's line item will be used."
                      }
                      className={'mb-small'}
                    />
                  )}
                </div>
              )}
              {selectedPaymentSourceType && this._renderSleepoverBillingContent()}
              {selectedPaymentSourceType &&
                customerBookingSuggestionPaymentDetail &&
                _.map(customerBookingSuggestionPaymentDetail.billingSuggestion, (billingPeriod, index) => {
                  const periodSelectedLineItems = _.find(
                    newBookingData.selectedLineItems,
                    (period) => period.startDateTime === billingPeriod.startDateTime,
                  );
                  return (
                    <>
                      <div className={'mb-small'}>{this._getServiceAgreementTitle(billingPeriod)}</div>
                      <div className={'mb-x2-large pt-medium bordered rounded-big shadow-container'} key={index}>
                        <NDISLineItemGrid
                          lineItems={
                            periodSelectedLineItems
                              ? periodSelectedLineItems.selectedLineItems
                              : !_.isEmpty(billingPeriod.serviceAgreementBillingItems)
                              ? billingPeriod.serviceAgreementBillingItems
                              : !_.isEmpty(billingPeriod.serviceBillingItems)
                              ? billingPeriod.serviceBillingItems
                              : null
                          }
                          displayMode={'VIEW'}
                          isServiceAgreementLineItems={
                            (periodSelectedLineItems &&
                              !_.isEmpty(periodSelectedLineItems.selectedLineItems) &&
                              periodSelectedLineItems.selectedLineItems[0].agreementPrice) ||
                            !_.isEmpty(billingPeriod.serviceAgreementBillingItems)
                          }
                          mmmGroup={customerBookingSuggestionPaymentDetail.mmmGroup}
                          state={newBookingData && newBookingData.bookLocation && newBookingData.bookLocation.state}
                          noMargin={true}
                          paymentSourceType={selectedPaymentSourceType}
                        />
                      </div>
                    </>
                  );
                })}
            </div>
            <div className={'mt-medium'}>
              {selectedPaymentSourceType && newBookingData.sleepoverType !== SleepoverType.ENTIRE && (
                <HyperlinkButton onClick={this._openSelectLineItem}>
                  <Icon type={'plus'} className={'mr-x-small'} />
                  Charge different line items
                </HyperlinkButton>
              )}
            </div>
          </>
        )}
        <div className="align-center mt-x-large flex-row justify-between">
          <SecondaryButton size="large" onClick={this._onPreviousStep} icon="left">
            Previous
          </SecondaryButton>

          <PrimaryButton
            size="large"
            onClick={this._onNextStep}
            disabled={!this.state.selectedPaymentSourceType}
            icon="right"
            iconPosition="right"
          >
            Next
          </PrimaryButton>
        </div>
        {/*</div>*/}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  newBookingData: state.bookingsStore.newBookingData,
  customerBookingPaymentDetail: state.bookingsStore.customerBookingPaymentDetail,
  customerBookingSuggestionPaymentDetail: state.bookingsStore.customerBookingSuggestionPaymentDetail,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchBookingPaymentDetail: dispatch.bookingsStore.doFetchBookingPaymentDetail,
  doFetchBookingSuggestionPaymentDetail: dispatch.bookingsStore.doFetchBookingSuggestionPaymentDetail,
  doSetNewBookingData: dispatch.bookingsStore.doSetNewBookingData,
});

export default connect(mapState, mapDispatch)(Form.create<IStep5PaymentDetailPanelProps>()(Step5PaymentDetailPanel));
