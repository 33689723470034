import { UseFormReturn } from 'react-hook-form';

import { Schema } from '../schema';
import fields from '../fields';

function getWatchConditions(form: UseFormReturn<Schema>) {
  const { watch } = form;

  const cancelledBy = watch(fields.cancelledBy.name);
  const shouldChargeCancellationFee = watch(fields.shouldChargeCancellationFee.name);
  const shouldPayTeamMember = watch(fields.shouldPayTeamMember.name);
  const teamMemberPaymentDuration = watch(fields.teamMemberPaymentDuration.name);
  const customTeamMemberPaymentDurationUnit = watch(fields.customTeamMemberPaymentDurationUnit.name);

  const cancellingOnBehalfOfBusiness = cancelledBy === fields.cancelledBy.options.business;
  const isCustomPaymentDurationHours =
    customTeamMemberPaymentDurationUnit === fields.customTeamMemberPaymentDurationUnit.options.hours;

  return {
    cancellingOnBehalfOfBusiness,
    shouldChargeCancellationFee,
    shouldPayTeamMember,
    teamMemberPaymentDuration,
    isCustomPaymentDurationHours,
  };
}

export default getWatchConditions;
