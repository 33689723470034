import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const SlidersVert2 = forwardRef(function SlidersVert2(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 3a.75.75 0 0 0-1.5 0v3.325A3.751 3.751 0 0 0 7 13.75a3.75 3.75 0 0 0 .75-7.425V3Zm-3 7a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Zm3 6a.75.75 0 0 0-1.5 0v5a.75.75 0 0 0 1.5 0v-5Zm10-13a.75.75 0 0 0-1.5 0v5a.75.75 0 0 0 1.5 0V3ZM17 16.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Zm.75 4.75v-3.325A3.751 3.751 0 0 0 17 10.25a3.75 3.75 0 0 0-.75 7.425V21a.75.75 0 0 0 1.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
