import { Row } from 'antd';
import React, { Component } from 'react';

interface IGridHeaderProps {
  bordered?: boolean;
  style?: object;
  containerClassName?: string;
  children?: React.ReactNode;
  [prop: string]: any;
}

class GridHeader extends Component<IGridHeaderProps> {
  static defaultProps = {
    containerClassName: '',
  };

  render() {
    const { containerClassName, bordered = true } = this.props;
    let className = `pv-medium ${containerClassName}`;
    if (bordered) {
      className += ' bordered-bottom';
    }
    return (
      <div className={className}>
        <Row gutter={32} className={'ph-medium'}>
          {this.props.children}
        </Row>
      </div>
    );
  }
}

export default GridHeader;
