import React, { Component } from 'react';
import * as H from 'history';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState, dispatch } from 'src/stores/rematch/root-store';
import { ICustomView } from 'src/interfaces/custom-views-interface';
import CustomAllViews from 'common-components/custom-views/all-views';
import { FilterType } from 'utilities/enum-utils';

interface ITeamAllViewsV2Props {
  doFetchWorkerViews: typeof dispatch.teamStore.doFetchWorkerViews;
  setWorkerListingActiveTab: typeof dispatch.teamStore.setWorkerListingActiveTab;
  doAddWorkerView: typeof dispatch.teamStore.doAddWorkerView;
  doUpdateWorkerViewTab: typeof dispatch.teamStore.doUpdateWorkerViewTab;
  doDuplicateWorkerView: typeof dispatch.teamStore.doDuplicateWorkerView;
  doDeleteWorkerView: typeof dispatch.teamStore.doDeleteWorkerView;
  setWorkerFilter: typeof dispatch.teamStore.setWorkerFilter;
  workerViews: ICustomView[];
  workerFilter: any;
  workerListingActiveTab: ICustomView;
  createdByMeViews: ICustomView[];
  createdByOthersViews: ICustomView[];
  defaultWorkerViews: ICustomView[];
  history: H.History;
}

const defaultFilterValue = [{ [FilterType.WORKER]: [] }, { [FilterType.SERVICE]: [] }];

class TeamAllViewsV2 extends Component<ITeamAllViewsV2Props, null> {
  render() {
    const {
      defaultWorkerViews,
      workerViews,
      workerFilter,
      workerListingActiveTab,
      doFetchWorkerViews,
      doAddWorkerView,
      doUpdateWorkerViewTab,
      doDuplicateWorkerView,
      doDeleteWorkerView,
      setWorkerFilter,
      setWorkerListingActiveTab,
    } = this.props;

    return (
      <CustomAllViews
        pageName="team members"
        pageNavigation="/team"
        pageViews={workerViews}
        pageFilter={workerFilter}
        pageListingActiveTab={workerListingActiveTab}
        defaultViews={defaultWorkerViews}
        defaultFilterValue={defaultFilterValue}
        doFetchPageViews={doFetchWorkerViews}
        setPageListingActiveTab={setWorkerListingActiveTab}
        doAddView={doAddWorkerView}
        doDeleteView={doDeleteWorkerView}
        doUpdateViewTab={doUpdateWorkerViewTab}
        doDuplicateView={doDuplicateWorkerView}
        setPageFilter={setWorkerFilter}
        {...this.props}
      ></CustomAllViews>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  defaultWorkerViews: state.teamStore.defaultWorkerViews,
  createdByMeViews: state.teamStore.workerViews && state.teamStore.workerViews.filter((view) => view.isOwner === true),
  createdByOthersViews:
    state.teamStore.workerViews && state.teamStore.workerViews.filter((view) => view.isOwner === false),
  displayedWorkerListingTabs: state.teamStore.displayedWorkerListingTabs,
  workerViews: state.teamStore.workerViews,
  workerFilter: state.teamStore.workerFilter,
  workerListingActiveTab: state.teamStore.workerListingActiveTab,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doFetchWorkerViews: dispatch.teamStore.doFetchWorkerViews,
  doAddWorkerView: dispatch.teamStore.doAddWorkerView,
  doUpdateWorkerViewTab: dispatch.teamStore.doUpdateWorkerViewTab,
  doDuplicateWorkerView: dispatch.teamStore.doDuplicateWorkerView,
  doDeleteWorkerView: dispatch.teamStore.doDeleteWorkerView,
  setWorkerFilter: dispatch.teamStore.setWorkerFilter,
  setDisplayedWorkerListingTabs: dispatch.teamStore.setDisplayedWorkerListingTabs,
  setWorkerListingActiveTab: dispatch.teamStore.setWorkerListingActiveTab,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamAllViewsV2);
