import React from 'react';
import { AlignJustify } from '@good/icons';

import { useRichTextEditor } from '../rich-text-editor-context';
import { MenuButton } from './menu-button';

export const AlignJustifyButton = () => {
  const { editor, disabled } = useRichTextEditor();

  const isDisabled = disabled || !editor.can().chain().focus().setTextAlign('justify').run();
  const onClick = () => editor.chain().focus().setTextAlign('justify').run();
  const variant = editor.isActive({ textAlign: 'justify' }) ? 'filled' : 'transparent';

  return (
    <MenuButton onClick={onClick} disabled={isDisabled} variant={variant} aria-label='align-justify'>
      <AlignJustify />
    </MenuButton>
  );
};
