import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Avatar, Icon, Radio, Tooltip } from 'antd';
import _ from 'lodash';
import WorkerStatusTagV2, { WorkerStatusTooltip } from 'common-components/tags/WorkerStatusTagV2';
import { CustomerSupportWorkerPreference, ShiftSlotStatus } from 'utilities/enum-utils';

class FoundWorkerRow extends Component<{
  worker: any;
  isSearch: boolean;
  selectWorker: (selectedWorker) => void;
  isSelected: boolean;
}> {
  render() {
    const { worker, isSelected } = this.props;

    const workerPreferenceBlocked = worker.preference === CustomerSupportWorkerPreference.BLOCKED;
    const workerPreferencePreferred = worker.preference === CustomerSupportWorkerPreference.PREFERRED;
    const isDisabled = worker.availability === 'NOT_AVAILABLE' || worker.availability === ShiftSlotStatus.ON_LEAVE;
    const isShowPreviouslyUsed = worker.previouslyUsed && !workerPreferenceBlocked;
    const isShowSkillMatched = worker.skillMatched && !workerPreferenceBlocked;
    return (
      <tr
        className={`bordered-bottom cursor-pointer ${isSelected && 'bg-blue-action-lightest'} ${
          workerPreferenceBlocked || (isDisabled && `cursor-not-allowed`)
        } `}
        onClick={() => (workerPreferenceBlocked || isDisabled ? null : this.props.selectWorker(worker))}
      >
        <td className="text-align-center pv-medium">
          <Radio key={worker.supportWorkerId} checked={isSelected} disabled={workerPreferenceBlocked || isDisabled} />
        </td>
        <td style={{ width: '50px' }}>
          <Avatar
            icon="user"
            size="large"
            shape="square"
            src={worker.attachmentUrl}
            style={{ opacity: workerPreferenceBlocked || isDisabled ? 0.5 : 1 }}
          />
        </td>
        <td>
          <table className="width-full">
            <tbody>
              <tr>
                <td colSpan={2}>
                  <Text weight="bold" style={{ opacity: workerPreferenceBlocked || isDisabled ? 0.5 : 1 }}>
                    {worker.firstName} {worker.lastName}
                  </Text>
                  {workerPreferencePreferred && (
                    <WorkerStatusTooltip content="This worker is preferred by the customer">
                      <Icon type="star" className="text-color-warning-orange ml-x-small" theme="filled" />
                    </WorkerStatusTooltip>
                  )}
                </td>
              </tr>
              <tr>
                <td width="45%">
                  <WorkerStatusTagV2
                    shiftSlotStatus={worker.availability}
                    preference={worker.preference}
                    icon="info-circle"
                  />
                </td>
                <td width="55%">
                  {(isShowPreviouslyUsed || isShowSkillMatched) && (
                    <div style={{ opacity: workerPreferenceBlocked || isDisabled ? 0.5 : 1 }} className="flex-row">
                      {isShowPreviouslyUsed && (
                        <WorkerStatusTooltip
                          title="Previously used"
                          content="Team member has previously worked with this customer"
                        >
                          <div className="cursor-pointer flex align-center">
                            <Icon type="info-circle" className="text-color-secondary mr-x-small" />
                            Previously used
                          </div>
                        </WorkerStatusTooltip>
                      )}
                      {isShowSkillMatched && (
                        <WorkerStatusTooltip
                          title="Skill match"
                          content="Team member’s skills match the customer’s care needs"
                        >
                          <div className="ml-small cursor-pointer flex align-center">
                            <Icon type="info-circle" className="text-color-secondary mr-x-small" />
                            Skill match
                          </div>
                        </WorkerStatusTooltip>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

export default FoundWorkerRow;
