import React, { ReactElement } from 'react';
import './AddTeamMemberFormFooter.css';
import { Button, Text } from '@good/components';
import { isMobile } from 'react-device-detect';
import { Popover2 } from '@blueprintjs/popover2';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { IconButton } from 'common-components/buttons';

type AddTeamMembersFooterParam = {
  handleAddNewMember: () => Promise<void>;
  isButtonDisabled: boolean;
  isLoading: boolean;
  teamMembersCount: number;
  onSubmit: (sendWithInvitation: boolean) => Promise<void>;
};

export const AddTeamMembersFooter = ({
  handleAddNewMember,
  isButtonDisabled,
  isLoading,
  teamMembersCount,
  onSubmit,
}: AddTeamMembersFooterParam): ReactElement => {
  const pluralise = `${teamMembersCount > 1 ? 's' : ''}`;
  return (
    <div className="flex flex-row flex-nowrap items-center justify-between">
      <Button
        emphasis="quiet"
        onClick={() => {
          void handleAddNewMember();
        }}
      >
        {isMobile ? '+ Add member' : '+ Add new team member'}
      </Button>
      <div className="flex flex-row flex-nowrap">
        <Button
          className="mainButton"
          isDisabled={isButtonDisabled || isLoading}
          type="submit"
          tone="accent"
          emphasis="fill"
          size="md"
        >
          {`Add team member${pluralise}`}
        </Button>
        <Popover2
          position="bottom-right"
          disabled={isButtonDisabled}
          content={
            <ActionMenu style={{ width: 230 }}>
              <ActionMenuItem
                data-testid="addTeamMemberMenuOption1"
                text={<Text>{`Add team member${pluralise}`}</Text>}
                onClick={() => void onSubmit(true)}
              />
              <ActionMenuItem
                data-testid="addTeamMemberMenuOption2"
                text={<Text>{`Add team member${pluralise} as draft`}</Text>}
                onClick={() => void onSubmit(false)}
              />
            </ActionMenu>
          }
        >
          <IconButton
            loading={isLoading}
            icon="down"
            size="large"
            disabled={isButtonDisabled}
            className={`popoverButton ${isButtonDisabled ? 'popoverButtonDisabled' : 'popoverButtonEnabled'}`}
            data-testid="addTeamMemberMenu"
          />
        </Popover2>
      </div>
    </div>
  );
};
