import { Col } from 'antd';
import { Avatar } from 'antd/es';
import { GridRow } from 'common-components/grids';
import { FieldValueText, Text } from 'common-components/typography';
import { ICustomerConnectionRequest } from 'interfaces/customer-interfaces';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment';
import React, { Component } from 'react';

interface IPreviousConnectionRowProps {
  connection: ICustomerConnectionRequest;
}

interface IPreviousConnectionRowState {}

class PreviousConnectionRow extends Component<IPreviousConnectionRowProps, IPreviousConnectionRowState> {
  render() {
    const { connection } = this.props;
    return (
      <GridRow>
        <Col span={5}>
          <div className="flex-row align-center">
            <Avatar className="mr-medium" size="large" icon="user" src={connection.attachmentUrl} />
            <div>
              <div>
                <Text weight="bold">{connection.firstName + ' ' + connection.lastName}</Text>
              </div>
              {connection.isDependent && (
                <div>
                  <Text>Parent/Guardian</Text>
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col span={11}>
          <Col className="ph-none" span={10}>
            <FieldValueText
              text={parsePhoneNumberFromString(connection.mobile, connection.mobileCountryCode).format('INTERNATIONAL')}
            />
          </Col>
          <Col className="ph-none" span={14}>
            <FieldValueText text={connection.email} />
          </Col>
        </Col>
        <Col span={3}>
          <FieldValueText text={moment(connection.createdOn).format('D MMMM YYYY')} />
        </Col>
        <Col span={4}>
          {/*<Tooltip placement="bottomRight" overlay={<Text color="white">This is a Tooltip.</Text>}>*/}
          <Text>{connection.status === 'CANCELLED' ? 'Cancelled' : 'Expired'}</Text>
          {/*<Icon type="question-circle" theme={'filled'} style={{ color: '#0083FF' }} className="ml-small" />*/}
          {/*</Tooltip>*/}
          <FieldValueText text={moment(connection.tokenExpireDate).format('D MMMM YYYY')} />
        </Col>
        <Col span={1}> </Col>
      </GridRow>
    );
  }
}

export default PreviousConnectionRow;
