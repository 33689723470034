import React from 'react';
import { useTranslation } from 'react-i18next';
import { rem } from '@good/ui/core';
import { PageHeader } from '@good/ui/templates';

import { AboutThisHomeForm } from './about-this-home-form';
import { WhatThisHomeOffersForm } from './what-this-home-offers-form';
import { OtherDetailsForm } from '../general-information/other-details-form';
import { AboutTheLocationForm } from './about-the-location-form';
import { AboutTheSupportForm } from './about-the-support-form';

export const PropertyAndRooms = () => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceDetailsTabs.propertyAndRooms' });

  return (
    <>
      <PageHeader title={t('title')} subtitle={t('description')} size='h2' mb={rem(24)} />
      <div className='flex flex-col gap-6 w-full'>
        <AboutThisHomeForm />
        <WhatThisHomeOffersForm />
        <AboutTheLocationForm />
        <AboutTheSupportForm />
        <OtherDetailsForm />
      </div>
    </>
  );
};
