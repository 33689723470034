import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Atom = forwardRef(function Atom(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.702 8.737c.227.604.529 1.241.9 1.895a24.09 24.09 0 0 1 2.333-2.698 24.09 24.09 0 0 1 2.697-2.332 13.376 13.376 0 0 0-1.895-.9c-1.996-.748-3.344-.59-3.985.05-.64.64-.798 1.989-.05 3.985Zm-1.404.526c.33.88.793 1.804 1.373 2.737a15.637 15.637 0 0 0-1.373 2.737c-.787 2.099-.918 4.26.394 5.571 1.312 1.313 3.473 1.181 5.572.394.88-.33 1.803-.792 2.736-1.373a15.64 15.64 0 0 0 2.737 1.373c2.099.787 4.26.919 5.572-.394 1.312-1.312 1.18-3.472.393-5.571-.33-.88-.792-1.803-1.373-2.737a15.636 15.636 0 0 0 1.374-2.737c.787-2.099.918-4.26-.394-5.572-1.312-1.312-3.473-1.18-5.572-.393-.88.33-1.803.792-2.737 1.373a15.636 15.636 0 0 0-2.736-1.373c-2.1-.787-4.26-.919-5.572.393C2.38 5.004 2.51 7.164 3.298 9.263Zm5.44 10.035a13.372 13.372 0 0 0 1.894-.9 24.09 24.09 0 0 1-2.698-2.332 24.088 24.088 0 0 1-2.332-2.698c-.371.654-.673 1.29-.9 1.895-.748 1.996-.59 3.344.05 3.985.641.64 1.99.798 3.985.05Zm.257-4.293c.983.983 2.002 1.83 3.005 2.529a22.222 22.222 0 0 0 3.006-2.529A22.21 22.21 0 0 0 17.534 12a22.21 22.21 0 0 0-2.529-3.005A22.218 22.218 0 0 0 12 6.466a22.21 22.21 0 0 0-3.005 2.529A22.211 22.211 0 0 0 6.467 12a22.218 22.218 0 0 0 2.528 3.005Zm6.269 4.293a13.376 13.376 0 0 1-1.896-.9 24.094 24.094 0 0 0 2.698-2.332 24.099 24.099 0 0 0 2.332-2.698c.372.654.673 1.29.9 1.895.748 1.996.59 3.344-.05 3.985-.64.64-1.989.798-3.985.05Zm4.034-10.561a13.37 13.37 0 0 1-.9 1.895 24.088 24.088 0 0 0-2.332-2.698 24.097 24.097 0 0 0-2.697-2.332c.653-.372 1.29-.673 1.895-.9 1.995-.748 3.344-.59 3.984.05.64.64.799 1.99.05 3.985ZM12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
