import React, { Component } from 'react';
import { TimezonePicker } from '@blueprintjs/timezone';
import { FaGlobeAsia } from 'react-icons/fa';
import { Text } from 'common-components/typography';
import { Icon, Popover } from 'antd';
import moment from 'moment-timezone';

interface ITimeInputProps {
  value?: any;
  onChange?: (type: number, value?: any) => void;
  size?: 'medium' | 'large';
  disabled?: boolean;
  usePortal?: boolean;
  type: number;
  error?: boolean;
}

interface ITimeInputState {
  type: number;
}

class TimezoneInput extends Component<ITimeInputProps, ITimeInputState> {
  state = {
    type: 1,
  };

  private _getPopoverContent = () => {
    return (
      <div className="flex-column">
        <Text weight="bold">Switch timezone to</Text>
        <div
          className={`flex cursor-pointer pv-x-small hover-bg-blue-lightest${
            this.state.type === 1 ? ' bg-blue-lightest' : ''
          }`}
          onClick={() => {
            this.props.onChange(1);
            this.setState({ type: 1 });
          }}
        >
          <Icon type="global" />
          <div className="flex-column ml-12">
            <Text className="text-size-large">Team member preferred timezone</Text>
            <Text className="text-size-regular" color="secondary">
              Show times in the team members preferred timezone
            </Text>
          </div>
        </div>
        <div
          className={`flex cursor-pointer pv-x-small hover-bg-blue-lightest${
            this.state.type === 2 ? ' bg-blue-lightest' : ''
          }`}
          onClick={() => {
            this.props.onChange(2, null);
            this.setState({ type: 2 });
          }}
        >
          <Icon type="sliders" />
          <div className="flex-column ml-12">
            <Text className="text-size-large">Custom timezone</Text>
            <Text className="text-size-regular" color="secondary">
              Select a custom timezone
            </Text>
          </div>
        </div>
      </div>
    );
  };

  private _typeSelector = (value) => {
    const { type } = this.state;
    const { size = 'medium' } = this.props;

    return (
      <Popover content={this._getPopoverContent()} trigger="click" placement="bottom">
        <div
          className="bordered rounded cursor-pointer pv-x-small ph-small flex-row align-center"
          style={{ height: size === 'large' ? '40px' : '', width: 'fit-content' }}
        >
          <FaGlobeAsia className="mr-x-small text-size-small text-color-tertiary" />
          <Text color="tertiary">
            {type === 1 ? `Team members preferred timezone - UTC${moment.tz(value).format('Z')}` : 'Custom timezone'}
          </Text>
          <Icon type="caret-down" className="ml-small text-size-small text-color-secondary" />
        </div>
      </Popover>
    );
  };

  private _onChangeTimezone = (value) => {
    this.props.onChange(this.state.type, value);
  };

  private _renderContent = () => {
    const { size = 'medium', disabled = false, usePortal = true, onChange, value = moment.tz.guess() } = this.props;
    const height = size === 'large' ? { height: '40px' } : {};
    const { error } = this.props;

    switch (this.state.type) {
      case 1:
        return this._typeSelector(value);
      case 2:
        return (
          <>
            <div className="flex">
              {this._typeSelector(value)}
              <TimezonePicker
                onChange={this._onChangeTimezone}
                popoverProps={{ usePortal, hasBackdrop: true }}
                disabled={disabled}
              >
                <div
                  className="bordered rounded cursor-pointer pv-x-small ph-small flex-row align-center ml-small"
                  style={height}
                >
                  <Icon type="sliders" className="mr-small" />
                  {value ? (
                    <>
                      <Text className="select-none">
                        {value.replace('_', ' ')}
                        {' (' + moment.tz(value).zoneAbbr() + ')'}
                      </Text>
                      <Text className="select-none ml-small" size="regular" color="secondary">
                        UTC{moment.tz(value).format('Z')}
                      </Text>
                    </>
                  ) : (
                    <Text color="tertiary">Search for a timezone</Text>
                  )}
                  <Icon type="caret-down" className="ml-small text-size-small" />
                </div>
              </TimezonePicker>
            </div>
            {error && (
              <div style={{ marginLeft: '190px' }}>
                <Text color="red">Please select a timezone</Text>
              </div>
            )}
          </>
        );
    }
  };

  componentDidMount() {
    const { type } = this.props;
    this.setState({ type });
  }

  render() {
    return this._renderContent();
  }
}

export default TimezoneInput;
