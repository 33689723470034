import React, { useState } from 'react';
import { ActionSelectItem } from 'views/account-management/add-new-member/panels/invite-panel/ActionSelectItem';

export function ActionSelectSection({ selectedAction, onSelectAction }) {
  // const [selectedAction, setAction] = useState('');

  return (
    <div>
      <ActionSelectItem
        selectedAction={selectedAction}
        value={'create'}
        title={'Create and invite'}
        description={
          'Send this person an invite to join your platform. This profile will be enabled on your platform according to their permissions.'
        }
        onSetAction={onSelectAction}
      />

      <ActionSelectItem
        selectedAction={selectedAction}
        value={'draft'}
        title={'Save as draft'}
        description={
          'Continue editing at a later time. This person cannot login to your platform until you send Invite and enable.'
        }
        onSetAction={onSelectAction}
      />
    </div>
  );
}
