import { notification, Radio } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';

interface IExportNoteModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCustomerUserId: string;
  isBookingNote?: boolean;
  selectedBookingItem: any;
  doExportCustomerNotes: typeof dispatch.customersStore.doExportCustomerNotes;
  doExportBookingNote: typeof dispatch.bookingsStore.doExportBookingNote;
}

interface IExportNoteModalState {
  isLoading: boolean;
  selectedOption: string;
  step: number;
}

class ExportNoteModal extends Component<IExportNoteModalProps, IExportNoteModalState> {
  state = {
    isLoading: false,
    selectedOption: 'ALL',
    step: 1,
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _onPressExport = async () => {
    this.setState({ isLoading: true });
    try {
      if (this.props.isBookingNote) {
        await this.props.doExportBookingNote({
          bookingId: this.props.selectedBookingItem.bookingId,
          customerUserId: this.props.selectedBookingItem.bookerUserId,
          noteType: 'BOOKING',
        });
      } else {
        await this.props.doExportCustomerNotes({
          customerUserId: this.props.selectedCustomerUserId,
          selectedOption: this.state.selectedOption,
        });
      }
      this.setState({ step: 2, isLoading: false });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  private _onCloseModel = () => {
    const { onClose } = this.props;
    this.setState({
      isLoading: false,
      selectedOption: 'ALL',
      step: 1,
    });
    onClose();
  };

  render() {
    const { isOpen } = this.props;
    const { step, isLoading } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={step === 1 ? 'Export notes' : 'Export underway!'}
        width="medium"
        onClose={this._onCloseModel}
        verticalAlignment="highest"
      >
        {step === 1 && (
          <>
            <div>
              <div className="mb-small">
                <Text>
                  The export may take some time to process. Once complete a CSV file will be sent to your email address.
                </Text>
              </div>
              {!this.props.isBookingNote && (
                <Radio.Group
                  value={this.state.selectedOption}
                  onChange={this._onChangeOption}
                  className="ml-medium mt-large"
                >
                  <Radio value={'ALL'} className="mb-small">
                    <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                      Export all notes
                    </div>
                  </Radio>
                  <br />
                  <Radio value={'CURRENT_FILTERS'} className="mb-small" onChange={this._onChangeOption}>
                    <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                      Export notes based on current filters
                    </div>
                  </Radio>
                </Radio.Group>
              )}
            </div>
            <div style={{ marginTop: 27 }} className="flex justify-end">
              <div>
                <GhostButton onClick={this._onCloseModel} size="large" loading={isLoading}>
                  Cancel
                </GhostButton>
              </div>
              <div className="ml-medium">
                <PrimaryButton onClick={this._onPressExport} loading={isLoading} size="large">
                  Export notes
                </PrimaryButton>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="mb-small">
              <Text>Preparing notes for export.</Text>
              <br />
              <br />
              <Text>
                The export may take some time to process. Once complete, a CSV file will be sent to you email address.
              </Text>

              <div style={{ marginTop: 27 }} className="flex justify-end">
                <div className="ml-medium">
                  <PrimaryButton onClick={this._onCloseModel} loading={isLoading} size="large">
                    Ok
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state) => ({
  selectedCustomerUserId: state.customersStore.selectedCustomerUserId,
  selectedBookingItem: state.bookingsStore.selectedBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doExportCustomerNotes: dispatch.customersStore.doExportCustomerNotes,
  doExportBookingNote: dispatch.bookingsStore.doExportBookingNote,
});

export default connect(mapState, mapDispatch)(ExportNoteModal);
