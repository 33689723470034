import { Avatar, Col, Empty, notification, Row, Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import { HyperlinkButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { timeZone } from 'interfaces/timezone-type';
import { IWorkflowListOnProfile } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { WorkflowTemplateTriggerType } from 'utilities/enum-utils';
import { withRouter, WithRouterProps } from 'utilities/with-router';
import ActiveWorkflowTemplateStatusTags from '../tags/ActiveWorkflowTemplateStatusTag';
import { InfiniteScroll } from 'components';

type IWorkflowsPanelProps = {
  selectedItemId: string;
  itemType: string;
  itemWorkflows: IWorkflowListOnProfile[];
  doFetchWorkflows: (userId) => Promise<void>;
  timezone?: timeZone;
  doCheckViewWorkflowDetails: typeof dispatch.workflowStore.doCheckViewWorkflowDetails;
} & WithRouterProps;

type IWorkflowsPanelState = {
  isLoading: boolean;
};

const WorkflowEmptyPanel = () => (
  <div className='flex-1 bg-white align-center flex-column'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No workflows found.
    </Text>
  </div>
);

class WorkflowsPanel extends Component<IWorkflowsPanelProps, IWorkflowsPanelState> {
  state = {
    isLoading: false,
  };

  private _params = { page: 1, pageSize: 10 };

  private _fetchMoreWorkflows = async () => {
    const { doFetchWorkflows, selectedItemId } = this.props;
    if (doFetchWorkflows) {
      this._params.page++;
      await doFetchWorkflows({
        userId: selectedItemId,
        ...this._params,
      });
    }
  };

  private _resetDocumentList = () => {
    const { selectedItemId, doFetchWorkflows } = this.props;
    if (doFetchWorkflows) {
      this.setState({ isLoading: true }, async () => {
        await doFetchWorkflows({
          userId: selectedItemId,
          ...this._params,
        });
        this.setState({ isLoading: false });
      });
    }
  };

  private _getRoleName = (itemType) => {
    return itemType === 'customer' ? 'customer' : itemType === 'worker' ? 'team member' : 'activity record';
  };

  private _getWorkflowType = (triggerType) => {
    return triggerType === WorkflowTemplateTriggerType.INCIDENT
      ? 'Incident'
      : triggerType === WorkflowTemplateTriggerType.MANUAL
      ? 'Manual'
      : 'Manual';
  };

  private _goToWorkflowPage = async (workflowId) => {
    const { doCheckViewWorkflowDetails, history } = this.props;

    const response: any = await doCheckViewWorkflowDetails({ workflowId });

    if (response && response.canViewDetail) {
      history.push(`/workflows/details/${workflowId}`);
    } else {
      notification.error({ message: "You don't have permission to view this workflow" });
    }
  };

  componentDidMount = async () => {
    await this._resetDocumentList();
    this.setState({ isLoading: false });
  };

  render() {
    const { selectedItemId, itemWorkflows, itemType, timezone } = this.props;

    if (!_.isEmpty(selectedItemId)) {
      return (
        <>
          <Row className='mb-large' type='flex' justify='space-between' align='middle'>
            <Col>
              <Title level={2} className='m-none'>
                Workflows
              </Title>
              <Text type='secondary'>View all workflows related this {this._getRoleName(itemType)}.</Text>
            </Col>
          </Row>

          <div className='mb-x2-large'>
            {!_.isEmpty(itemWorkflows) ? (
              <>
                <Row gutter={16} className='pv-medium bordered-bottom'>
                  <Col span={6} className='pl-medium'>
                    <SubTitle>Workflow</SubTitle>
                  </Col>

                  <Col span={5}>
                    <SubTitle>Status</SubTitle>
                  </Col>

                  <Col span={5}>
                    <SubTitle>Workflow type</SubTitle>
                  </Col>

                  <Col span={4}>
                    <SubTitle>Date created</SubTitle>
                  </Col>

                  <Col span={4}>
                    <SubTitle>Created by</SubTitle>
                  </Col>
                </Row>

                {this.state.isLoading ? (
                  <Row>
                    <Col>
                      <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className='anim-slide-left' />
                    </Col>
                  </Row>
                ) : (
                  <InfiniteScroll
                    hasMore={itemWorkflows.length >= this._params.page * this._params.pageSize}
                    loadMore={this._fetchMoreWorkflows}
                    customLoader={
                      <Col>
                        <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className='anim-slide-left' />
                      </Col>
                    }
                  >
                    {_.map(itemWorkflows, (workflow: IWorkflowListOnProfile) => {
                      return (
                        <Row gutter={16} key={workflow.workflowId} className='hover-bg-quaternary pv-medium'>
                          <Col
                            span={6}
                            className='pl-medium'
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                          >
                            <HyperlinkButton onClick={() => this._goToWorkflowPage(workflow.workflowId)}>
                              {workflow.name}
                            </HyperlinkButton>
                          </Col>

                          <Col span={5}>
                            <ActiveWorkflowTemplateStatusTags status={workflow.status} />
                          </Col>

                          <Col span={5}>
                            <Text>{this._getWorkflowType(workflow.triggerType)}</Text>
                          </Col>

                          <Col span={4}>
                            <Text>{moment.tz(workflow.createdOn, timezone).format('DD MMM YYYY')}</Text>
                          </Col>

                          <Col span={4}>
                            <Avatar className='mr-medium' size='small' src={workflow.createdBy.avatar} />
                            <Text>{workflow.createdBy.name}</Text>
                          </Col>
                        </Row>
                      );
                    })}
                  </InfiniteScroll>
                )}
              </>
            ) : (
              <Row>
                <WorkflowEmptyPanel />
              </Row>
            )}
          </div>
        </>
      );
    }
    return <></>;
  }
}

const mapState = (state: IRootState) => ({ companyDataLite: state.companyStore.companyDataLite });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
  doCheckViewWorkflowDetails: dispatch.workflowStore.doCheckViewWorkflowDetails,
});

export default connect(mapState, mapDispatch)(withRouter(WorkflowsPanel));
