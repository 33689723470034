import { notification } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Inline, Modal, Switch, Text } from 'design-components';
import { IRootDispatch } from 'stores/rematch/root-store';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  isOutGoingInvoicesEmailEnabled: boolean;
}

const EditInvoicesAndCreditNotesModal: React.FC<IProps> = ({ isOpen, onClose, isOutGoingInvoicesEmailEnabled }) => {
  const dispatch = useDispatch<IRootDispatch>();
  const [isSelected, setIsSelected] = useState<boolean>(isOutGoingInvoicesEmailEnabled);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await dispatch.companyStore.doUpdateInvoicesAndCreditNotes({ isOutGoingInvoicesEmailEnabled: isSelected });
      onClose();
      notification.success({ message: 'You have successfully updated.' });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      closable
      isOpen={isOpen}
      onClose={onClose}
      onOk={handleSubmit}
      okText="Save changes"
      isLoading={isSubmitting}
      header={
        <Text color="$black" size="medium" emphasis="bold">
          GoodHuman invoicing
        </Text>
      }
      footer={
        <>
          <Button emphasis="quiet" color="$bodyDark1" size="large" onPress={onClose} isDisabled={isSubmitting}>
            Cancel
          </Button>
          <Button emphasis="filled" size="large" onPress={handleSubmit} isLoading={isSubmitting}>
            Save changes
          </Button>
        </>
      }
      width="$size8000"
      headerStyles={{ padding: '$space300', marginBottom: '$space100' }}
      footerStyles={{ padding: '$space300' }}
    >
      <Inline gap="$space150" flexDirection="column !important">
        <Text color="$bodyDark2" display="block" size="medium" emphasis="regular">
          Invoices and credit notes are automatically sent from the GoodHuman platform by default to your debtors.
        </Text>
        <Text color="$bodyDark2" display="block" size="medium" marginBottom="$space300">
          Configure this setting using the toggle below.
        </Text>
      </Inline>
      <Inline alignItems="center">
        <Switch isSelected={isSelected} isDisabled={false} isShowCheckedIcon={false} onChange={setIsSelected} />
        <Text color="$bodyDark2" size="medium" emphasis="bold" marginLeft="$space200 !important">
          {isSelected ? 'ON' : 'OFF'}
        </Text>
      </Inline>
    </Modal>
  );
};

export default EditInvoicesAndCreditNotesModal;
