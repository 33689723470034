import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Degrees360 = forwardRef(function Degrees360(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.171 5.421c-.37.292-.421.493-.421.579 0 .07.03.205.23.41.204.207.54.435 1.024.66.967.447 2.382.814 4.084 1.014a.75.75 0 0 1 .662.745v5.513c.722-.06 1.475-.092 2.25-.092.775 0 1.528.032 2.25.092V8.829c0-.38.285-.7.662-.745 1.702-.2 3.117-.567 4.083-1.014.485-.225.82-.453 1.024-.66.201-.205.231-.34.231-.41 0-.086-.052-.287-.422-.579-.361-.285-.93-.574-1.701-.83-1.534-.512-3.7-.841-6.127-.841-2.427 0-4.593.329-6.127.84-.77.257-1.34.546-1.702.831ZM20.25 8.103a6.751 6.751 0 0 1-.624.328c-1.015.47-2.355.83-3.876 1.055V19.97c1.33-.211 2.442-.527 3.245-.9.485-.224.82-.452 1.024-.66.201-.204.231-.34.231-.409V8.103ZM21.75 6v12c0 .584-.283 1.077-.661 1.461-.375.382-.885.702-1.463.97-1.16.537-2.744.932-4.538 1.143a.75.75 0 0 1-.838-.745v-4.982A25.554 25.554 0 0 0 12 15.75c-.78 0-1.534.034-2.25.097v4.982a.75.75 0 0 1-.838.745c-1.794-.211-3.379-.606-4.538-1.144-.578-.267-1.088-.587-1.463-.969-.378-.384-.661-.877-.661-1.461V6c0-.743.452-1.33.993-1.757.55-.433 1.298-.79 2.156-1.076C7.123 2.593 9.457 2.25 12 2.25s4.877.343 6.601.917c.858.286 1.607.643 2.156 1.076.54.426.993 1.014.993 1.757Zm-18 2.103V18c0 .07.03.205.23.41.204.207.54.435 1.024.66.804.372 1.917.688 3.246.899V9.486c-1.521-.224-2.861-.585-3.876-1.055a6.747 6.747 0 0 1-.624-.328Z"
        fill="currentColor"
      />
    </Icon>
  );
});
