import React from 'react';
import { InsightsSummaryResponse } from 'views/insights/insights';
import { ActiveTeamMembersBreakdown } from '../active-team-members-breakdown/active-team-members-breakdown';
import { BillableHours } from '../billable-hours/billable-hours';
import { HoursWorked } from '../hours-changed/hours-worked';
import { ShiftsWorked } from '../shifts-worked/shifts-worked';
import { Utilisation } from '../utilisation/utilisation';

export type InsightsSummaryProps = {
  insights: InsightsSummaryResponse;
  showUtilisation?: boolean;
};
export const InsightsSummary = ({ insights, showUtilisation = false }: InsightsSummaryProps) => {
  const {
    overallUtilisationPercent,
    totalShifts,
    totalHours,
    totalHoursChangedPercent,
    billableHours,
    nonBillableHours,
    activeTeamMembers,
  } = insights;
  return (
    <div className="flex flex-col">
      <div className="flex flex-col space-y-4">
        <div className="rounded-lg bg-gray-light-2 p-2">
          <div className="flex flex-row justify-between">
            {showUtilisation ? (
              <>
                <div className="align-center flex w-1/2 basis-1/2 justify-center ">
                  <Utilisation overallUtilisationPercent={overallUtilisationPercent} />
                </div>
                <div className="basis-1/2 rounded-lg bg-white px-8 py-4">
                  <HoursWorked totalHours={totalHours} totalHoursChangedPercent={totalHoursChangedPercent} />
                </div>
              </>
            ) : (
              <>
                <div className="align-center flex w-1/2 basis-1/3 justify-center ">
                  <ShiftsWorked totalShifts={totalShifts} />
                </div>
                <div className="basis-2/3 rounded-lg bg-white px-8 py-4">
                  <HoursWorked totalHours={totalHours} totalHoursChangedPercent={totalHoursChangedPercent} />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="border-px rounded-lg border-base p-4">
          <BillableHours billableHours={billableHours} nonBillableHours={nonBillableHours} />
        </div>
        <div className="border-px rounded-lg border-base p-4">
          <ActiveTeamMembersBreakdown activeTeamMembers={activeTeamMembers} />
        </div>
      </div>
    </div>
  );
};
