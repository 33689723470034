import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Keyboard = forwardRef(function Keyboard(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.25h-.052c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.08.595-.08 1.345-.08 2.243V14.052c0 .899 0 1.648.08 2.242.084.628.27 1.195.726 1.65.455.456 1.022.642 1.65.726.594.08 1.344.08 2.242.08h12.104c.899 0 1.648 0 2.242-.08.628-.084 1.195-.27 1.65-.726.456-.455.642-1.022.726-1.65.08-.594.08-1.344.08-2.242V9.948c0-.898 0-1.648-.08-2.242-.084-.628-.27-1.195-.725-1.65-.456-.456-1.023-.642-1.65-.726-.595-.08-1.345-.08-2.243-.08H6ZM3.116 7.116c.13-.13.328-.237.79-.3.482-.064 1.13-.066 2.094-.066h12c.964 0 1.612.002 2.095.067.461.062.659.169.789.3.13.13.238.327.3.788.064.483.066 1.131.066 2.095v4c0 .964-.002 1.612-.067 2.095-.061.461-.169.659-.3.789-.13.13-.327.237-.788.3-.483.064-1.13.066-2.095.066H6c-.964 0-1.612-.002-2.095-.067-.461-.062-.658-.169-.789-.3-.13-.13-.237-.327-.3-.788-.064-.483-.066-1.131-.066-2.095v-4c0-.964.002-1.612.067-2.095.062-.461.169-.659.3-.789ZM10 13.25a.75.75 0 1 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4ZM7 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm11 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1-5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
