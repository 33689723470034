import React, { Component } from 'react';
import _ from 'lodash';
import { Text } from 'common-components/typography';
import CommonUtils from 'utilities/common-utils';
import { PaymentMethodTag } from 'common-components/tags';
import { HyperlinkButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Icon, notification } from 'antd';
import PermissionUtils from 'utilities/permission-utils';
import { PaymentSources } from 'utilities/enum-utils';
import { Link } from 'react-router-dom';

interface IBatchItemRow {
  batchItem?: any;
  downloadCSVFile?: any;
  doResendEmail: typeof dispatch.billingsStore.doResendEmail;
  portalUser: typeof state.authStore.portalUser;
}

const statusOptions = [
  { value: 'SUCCESS', label: 'Processed', style: 'bg-green rounded ph-medium pv-x-small' },
  { value: 'PENDING', label: 'Processed', style: 'bg-green rounded ph-medium pv-x-small' },
  { value: 'FAILED', label: 'Failed', style: 'bg-red rounded ph-medium pv-x-small' },
];

class BatchDetailItemRow extends Component<IBatchItemRow> {
  _checkbox = null;
  state = {
    batchId: null,
  };

  _doResendEmail = async (invoiceId) => {
    const { doResendEmail } = this.props;

    try {
      await doResendEmail(invoiceId);
      notification.success({
        message: 'Email sent successfully',
        description: 'The invoice has been sent successfully.',
      });
    } catch (e) {
      notification.error({
        message: 'Error while sending the email',
        description: 'The sending of the email failed, please try again.',
      });
    }
  };

  componentDidMount = () => {
    const { batchItem } = this.props;

    if (!_.isEmpty(batchItem)) {
      const { batchId } = batchItem;
      this.setState({ batchId });
    }
  };

  render() {
    const { batchItem, portalUser } = this.props;

    if (_.isEmpty(batchItem)) {
      return <></>;
    }

    const {
      firstName,
      lastName,
      userId,
      invoiceNumber,
      invoiceId,
      numberOfItem,
      paymentMethod,
      invoiceBillingTotal,
      status,
      paymentSourceType,
    } = batchItem;

    const isFullyRejected = batchItem.listOfItem.every((items) => items.isRejected);

    const noEmailSet = false;

    return (
      <>
        <tr className="anim-fade-in bg-white">
          <td className="ellipsis">
            <div className="pr-small">
              <Text style={{ whiteSpace: 'nowrap' }}>
                <PaymentMethodTag paymentMethod={paymentMethod} className="mr-small" rounded={true} size="small" />
                {invoiceNumber} ({numberOfItem} Item{numberOfItem > 1 && 's'})
                {isFullyRejected && (
                  <Text className="text-color-red ml-medium" weight="bold">
                    Rejected
                  </Text>
                )}
              </Text>
            </div>
          </td>
          <td className="ellipsis">
            <div className="pr-small">
              <Text style={{ whiteSpace: 'nowrap' }} weight="bold">
                {PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles) ? (
                  <Link to={`/customer/details/${userId}`}>
                    <HyperlinkButton color="black">
                      {firstName} {lastName}
                    </HyperlinkButton>
                  </Link>
                ) : (
                  firstName + ' ' + lastName
                )}{' '}
                {paymentMethod !== 'NDIA' && noEmailSet && (
                  <Text className="text-color-red ml-medium">
                    <Icon type="warning" className="mr-x-small" />
                    No Email set to send invoice to.{' '}
                  </Text>
                )}
              </Text>
            </div>
          </td>
          <td className="ellipsis">
            <div>
              <span className={statusOptions.find((e) => e.value === status).style}>
                <Text style={{ whiteSpace: 'nowrap' }} color="white" weight="bold">
                  {statusOptions.find((e) => e.value === status).label}
                </Text>
              </span>
            </div>
          </td>
          <td className="fluid-cell text-align-right">
            <div className="pr-small text-align-right">
              <Text style={{ whiteSpace: 'nowrap' }}>{CommonUtils.formatPrice(invoiceBillingTotal)}</Text>
            </div>
          </td>

          <td className="ellipsis text-align-right" style={{ width: '150px' }}>
            <div className="pr-small">
              {paymentSourceType === PaymentSources.NDIS &&
                paymentMethod === 'NDIA' &&
                !isFullyRejected &&
                PermissionUtils.validatePermission('DownloadNDISFile', portalUser.permissions.permissionRoles) && (
                  <HyperlinkButton onClick={this.props.downloadCSVFile}>
                    <Icon type="download" className="mr-x-small" />
                    NDIS file
                  </HyperlinkButton>
                )}
              {paymentSourceType === PaymentSources.NDIS &&
                paymentMethod !== 'NDIA' &&
                !isFullyRejected &&
                !noEmailSet && (
                  <HyperlinkButton onClick={() => this._doResendEmail({ invoiceId: invoiceId })}>
                    Resend email
                  </HyperlinkButton>
                )}
              {paymentSourceType === PaymentSources.VCP && (
                <HyperlinkButton
                  onClick={() => {
                    window.open(`/pdf?type=view-invoice&invoiceId=${invoiceId}`, '_blank');
                  }}
                >
                  Download invoice
                </HyperlinkButton>
              )}
            </div>
          </td>
        </tr>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doResendEmail: dispatch.billingsStore.doResendEmail,
});

export default connect(mapState, mapDispatch)(BatchDetailItemRow);
