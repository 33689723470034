import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ColorFilter = forwardRef(function ColorFilter(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 8a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0ZM12 2.25a5.75 5.75 0 0 0-5.562 7.215A5.752 5.752 0 0 0 8 20.75a5.731 5.731 0 0 0 4-1.62 5.75 5.75 0 1 0 5.562-9.666A5.75 5.75 0 0 0 12 2.25Zm4.987 8.615a5.763 5.763 0 0 1-3.425 2.67 5.757 5.757 0 0 1-.624 4.413 4.25 4.25 0 1 0 4.048-7.083Zm-4.924 2.885H12a5.748 5.748 0 0 1-4.987-2.885 4.25 4.25 0 1 0 5.05 2.885Z"
        fill="currentColor"
      />
    </Icon>
  );
});
