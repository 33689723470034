import React from 'react';
import { Inline, PercentFormatter, Text } from '@goodhuman-me/components';

import { statusToColors } from '../../budget';
import { BudgetStatusMessage } from '../budget-status-message';
import { ProgressBar } from '../progress-bar';

import type { BudgetStatus } from '../../budget';
import type { InlineProps } from '@goodhuman-me/components';
import type { ProgressBarProps } from '../progress-bar/progress-bar';

export type BudgetStatusProgressBarProps = {
  status: BudgetStatus;
  valueLabelProps: InlineProps;
} & ProgressBarProps;

export function BudgetStatusProgressBar(props: BudgetStatusProgressBarProps): JSX.Element {
  const { label, status, value, projectedValue, valueLabelProps, ...otherProps } = props;

  return (
    <ProgressBar
      width="100%"
      {...otherProps}
      {...statusToColors(status)}
      label={label}
      value={value}
      projectedValue={projectedValue}
      valueLabel={
        <Inline gap="$space100" {...valueLabelProps}>
          <BudgetStatusMessage status={status} size="xsmall" />
          <Text fontWeight="$bold" color="$default" size="xsmall">
            <PercentFormatter value={value} minimumFractionDigits={0} maximumFractionDigits={0} />
          </Text>
        </Inline>
      }
    />
  );
}
