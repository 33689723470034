import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const IndentFirstLine = forwardRef(function IndentFirstLine(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 4a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 0 1.5h-8a.75.75 0 0 1-.75-.75Zm-8 15a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75ZM12 8.25a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5h-8ZM4.47 3.47a.75.75 0 0 1 1.06 0l2.5 2.5a.75.75 0 0 1 0 1.06l-2.5 2.5a.75.75 0 1 1-1.06-1.06L6.44 6.5 4.47 4.53a.75.75 0 0 1 0-1.06ZM4 13.25a.75.75 0 1 0 0 1.5h16a.75.75 0 0 0 0-1.5H4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
