import { Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import CommonUtils from 'utilities/common-utils';
import { ServiceType } from 'utilities/enum-utils';

class ViewQuotationQuoteItemView extends Component<any, any> {
  render() {
    const { quoteItem, isShowIncludeGST } = this.props;
    const pricePerOneUnit = Number(isShowIncludeGST ? quoteItem.priceIncGst : quoteItem.price);
    const totalQuoteAmount =
      quoteItem.serviceType === ServiceType.GROUP
        ? Number(quoteItem.totalQuoteAmount)
        : isShowIncludeGST && quoteItem.quoteAmountIncGst
        ? quoteItem.quoteAmountIncGst
        : Number(quoteItem.qty) * pricePerOneUnit;

    return (
      <Row className={'pv-small bordered-bottom bordered-left bordered-right'} type={'flex'} align={'middle'}>
        <Col span={1} />
        <Col span={16} className={'pl-medium'}>
          <Text weight={'bold'}>{quoteItem.supportItemNumber}</Text>
          <br />
          <Text>{quoteItem.supportItemName}</Text>
        </Col>
        <Col span={4} className={'text-align-right'}>
          <Text weight={'bold'}>{CommonUtils.formatPrice(Number(totalQuoteAmount))}</Text>
          <br />
          <Text size={'regular'} color={'gray'}>
            {quoteItem.isTravel
              ? `(${quoteItem.kms * quoteItem.numberOfBookings}km)`
              : `(${Number(quoteItem.qty)} x ${CommonUtils.formatPrice(pricePerOneUnit)})`}
          </Text>{' '}
          {Number(quoteItem.gst) > 0 && isShowIncludeGST ? (
            <Text size="x-small" color={'secondary'}>
              inc.GST
            </Text>
          ) : null}
        </Col>
        <Col span={3} />
      </Row>
    );
  }
}

export default ViewQuotationQuoteItemView;
