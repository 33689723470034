import { Input } from 'antd';
import React, { Component, CSSProperties } from 'react';
import CommonUtils from 'utilities/common-utils';
import { DurationUnit, DurationDisplayFormat } from 'utilities/enum-utils';

interface DurationInputProps {
  size?: 'large' | 'small' | 'default';
  value: any;
  defaultValue?: any;
  onChange: (number, durationUnit, hourReturnValue, minuteReturnValue) => void;
  className?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  addonBefore?: string;
  addonAfter?: string;
  step?: number;
  placeholder?: string;
  style?: CSSProperties;
  durationUnit: DurationUnit;
  displayFormat: DurationDisplayFormat;
}

interface DurationInputState {
  value: string;
  onFocus: boolean;
}

class DurationInput extends Component<DurationInputProps, DurationInputState> {
  state = {
    value: this.props.value,
    onFocus: false,
  };

  private _onChangeNumber = (e) => {
    const regex = new RegExp(`^\\d*[.,]?\\d{0,2}$`);

    let number = e.target.value;
    let durationUnit;
    let hourReturnValue;
    let minuteReturnValue;
    const maxMinuteInputValue = 1440;
    const maxHourInputValue = 24;

    if (!number.match(regex)) {
      return;
    }

    if (number.includes('.') || number.includes(',')) {
      //Indentity if user input . or , for hour display
      const { hours, minutes } = CommonUtils.splitHourAndMinute(number);
      if (hours > maxHourInputValue || (hours === maxHourInputValue && minutes > 0)) {
        hourReturnValue = maxHourInputValue;
        minuteReturnValue = 0;
        if (number.includes('.')) number = maxHourInputValue + '.' + 0;
        if (number.includes(',')) number = maxHourInputValue + ',' + 0;
      } else if (minutes >= 60) {
        hourReturnValue = hours;
        minuteReturnValue = 59;
        if (number.includes('.')) number = hours + '.' + 59;
        if (number.includes(',')) number = hours + ',' + 59;
      } else {
        hourReturnValue = hours;
        minuteReturnValue = minutes;
      }

      durationUnit = DurationUnit.HOUR;
    } else {
      //User input number only
      if (number > maxMinuteInputValue) number = maxMinuteInputValue;
      hourReturnValue = Math.floor(number / 60);
      minuteReturnValue = number % 60;
      durationUnit = DurationUnit.MINUTE;
    }

    if (this.props.onChange) this.props.onChange(number, durationUnit, hourReturnValue, minuteReturnValue);
    this.setState({ value: number });
  };

  private _formatValue = () => {
    const { durationUnit, value } = this.props;
    if (durationUnit === DurationUnit.MINUTE) {
      this.setState({
        value: Number.parseInt(value).toString(),
      });
    }
    if (durationUnit === DurationUnit.HOUR) {
      this.setState({
        value: value,
      });
    }
    this.setState({ onFocus: true });
  };

  private _formatDisplay = (value) => {
    const { durationUnit, displayFormat } = this.props;
    let hourValue;
    let minuteValue;
    if (durationUnit === DurationUnit.MINUTE) {
      hourValue = Math.floor(value / 60);
      minuteValue = Math.floor(value % 60);
    }
    if (durationUnit === DurationUnit.HOUR) {
      const { hours, minutes } = CommonUtils.splitHourAndMinute(value);
      hourValue = hours;
      minuteValue = minutes;
    }

    let displayValue = '';
    if (displayFormat === DurationDisplayFormat.HOURS_AND_MINUTES) {
      displayValue = `${hourValue} hr ${minuteValue} min`;
    } else {
      displayValue = Number(hourValue) * 60 + Number(minuteValue) + ' min';
    }

    this.setState({
      value: displayValue,
      onFocus: false,
    });
  };

  private _handleReDisplayWhenUpdate = () => {
    const { value, durationUnit } = this.props;
    let refinedValue = value;
    if (durationUnit === DurationUnit.HOUR) {
      const hourReturnValue = Math.floor(value / 60);
      const minuteReturnValue = value % 60;
      refinedValue = hourReturnValue + '.' + minuteReturnValue;
    }
    this._formatDisplay(refinedValue);
  };

  componentDidMount(): void {
    this._handleReDisplayWhenUpdate();
  }

  componentWillUnmount(): void {
    this.setState({ onFocus: false });
  }

  componentDidUpdate(prevProps: Readonly<DurationInputProps>) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
      if (!this.state.onFocus) {
        this._formatDisplay(this.props.value);
      }
    }
  }

  render() {
    const style: any = this.props.style ? this.props.style : { width: '90px', textAlign: 'right' };
    return (
      <Input
        size={this.props.size}
        className={'inline-block ' + this.props.className}
        onChange={this._onChangeNumber}
        addonBefore={this.props.addonBefore}
        addonAfter={this.props.addonAfter}
        defaultValue={this.props.defaultValue}
        value={this.state.value}
        onBlur={() => this._formatDisplay(this.props.value)}
        onFocus={this._formatValue}
        disabled={this.props.disabled}
        style={style}
        placeholder={this.props.placeholder}
      />
    );
  }
}

export default DurationInput;
