import React, { ReactNode } from 'react';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { Switch, SwitchProps } from '@good/ui/core';

import { useInlineForm } from './inline-form-context';
import { StaticField } from './static-field';
import { InlineFieldInputWrapper } from './inline-field-input-wrapper';

export type InlineSwitchFieldProps<T extends FieldValues> = {
  description?: string;
  error?: ReactNode;
  label?: string;
  name: Path<T>;
  rules?: Omit<RegisterOptions<T, Path<T>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  staticLabel?: string;
  switchOptions: {
    label: string;
    checkedValue: string;
    uncheckedValue: string;
  };
} & Omit<SwitchProps, 'name' | 'label' | 'description' | 'error'>;

export const InlineSwitchField = <T extends FieldValues>(props: InlineSwitchFieldProps<T>) => {
  const { control } = useFormContext<T>();
  const { isEditing, isSubmitting } = useInlineForm();
  const {
    description,
    disabled,
    error: errorProp,
    label,
    name,
    rules,
    staticLabel,
    switchOptions: { label: switchLabel, checkedValue, uncheckedValue },
    ...switchProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
        return isEditing ? (
          <InlineFieldInputWrapper
            label={label}
            description={description}
            error={errorProp ?? error?.message}
            name={name}
            render={(labelledBy) => {
              return (
                <Switch
                  onChange={onChange}
                  checked={value}
                  onBlur={onBlur}
                  ref={ref}
                  disabled={isSubmitting || disabled}
                  label={switchLabel}
                  aria-labelledby={labelledBy}
                  {...switchProps}
                />
              );
            }}
          />
        ) : (
          <StaticField label={staticLabel ?? label} value={value ? checkedValue : uncheckedValue} />
        );
      }}
    />
  );
};
