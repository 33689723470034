import React, { PureComponent } from 'react';
import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';

import _ from 'lodash';
import { IBulkAction } from 'interfaces/common-interface';
import { BottomActionSheetHandler } from 'common-components/bulk-actions/BottomActionSheetHandler';

interface IBottomActionSheetProps {
  selectedItems?: IBulkAction[];
  onDeselect?: any;
  label?: string;
  labelPlural?: string;
  refreshItemListings?: any;
  noLeftMargin?: boolean;
}

interface IBottomActionSheetState {
  open: boolean;
  showMultiPopover: boolean;
}

class BottomActionSheet extends PureComponent<IBottomActionSheetProps, IBottomActionSheetState> {
  state = {
    open: true,
    showMultiPopover: false,
  };

  private _onDeselect = () => {
    this.setState({ open: false });
  };

  private _onHideSheet = () => {
    this.setState({ open: false });
  };

  private _animationEnd = () => {
    if (!this.state.open) {
      this.props.onDeselect();
    }
  };

  private _onSetPopover = (showPopover) => {
    this.setState({ showMultiPopover: showPopover });
  };

  render() {
    const { selectedItems, label = 'item', labelPlural = 'items', noLeftMargin = false } = this.props;
    const animClass = this.state.open ? 'action-anim-slide-in' : 'action-anim-slide-out';

    const itemCount = selectedItems && _.sumBy(selectedItems, 'itemCount');
    const filteredSelectedItems = _.filter(
      selectedItems,
      (action) => !_.isEmpty(action) && (action.actions !== null || !_.isEmpty(action.children)),
    );

    return (
      <div
        className={`${animClass} ${!noLeftMargin && 'ml-small'}`}
        style={{ bottom: 0, position: 'sticky', zIndex: 20, width: 'inherit', marginTop: '-32px' }}
        onAnimationEnd={this._animationEnd}
      >
        <div
          className="bg-blue"
          style={{
            width: '12px',
            height: '12px',
            position: 'absolute',
            top: '-6px',
            left: '58px',
            transform: 'rotate(45deg)',
          }}
        />
        <div className="bg-blue pv-medium ph-large flex-row justify-between align-center">
          <div>
            <Text color="white" weight="bold" className="mr-medium">
              {itemCount} {itemCount !== 1 ? labelPlural : label} selected
            </Text>
            <HyperlinkButton color="white" className="dim" onClick={this._onDeselect}>
              Deselect all
            </HyperlinkButton>
          </div>
          <div>
            {filteredSelectedItems && filteredSelectedItems.length > 0 ? (
              <BottomActionSheetHandler
                showPopover={this.state.showMultiPopover}
                onSetPopover={this._onSetPopover}
                selectedItems={filteredSelectedItems}
              />
            ) : (
              <div className={'mv-small'}>
                <Text color="white">No actions available.</Text>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export { BottomActionSheet };
