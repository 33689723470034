import { Menu, MenuItem, Popover } from '@blueprintjs/core';
import { Checkbox, Icon } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import * as H from 'history';
import { IBatch } from 'interfaces/booking-interfaces';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import CommonUtils from 'utilities/common-utils';
import { ReactComponent as NonBillableIcon } from 'assets/Icons/billing-icons/non-billable_icon.svg';

interface IBatchItemRow {
  history: H.History;
  batchItem?: IBatch;
  isChecked: boolean;
  actionExportTitle: string;
  onCheckBatch: (batchDetail: IBatch) => void;
  exportFinanceFile: (batchId: string) => void;
}

class BatchItemRow extends Component<IBatchItemRow> {
  state = {
    batchId: null,
  };

  _onClickBatch = (batchId: string) => {
    this.props.history.push(`/batch/details/${batchId}`);
  };

  onCheck = (e) => {
    const { onCheckBatch, batchItem } = this.props;
    e.stopPropagation();
    e.preventDefault();
    onCheckBatch(batchItem);
  };

  componentDidMount = () => {
    const { batchItem } = this.props;

    if (!_.isEmpty(batchItem)) {
      const { batchId } = batchItem;
      this.setState({ batchId });
    }
  };

  render() {
    const { batchItem, isChecked, exportFinanceFile, actionExportTitle } = this.props;

    if (_.isEmpty(batchItem)) {
      return <></>;
    }

    const {
      batchId,
      exportedDate,
      firstName,
      lastName,
      numberOfInvoice,
      batchNumber,
      batchBillingTotal,
      numberOfRejectedInvoice,
      isNonBillable,
    } = batchItem;

    const isFullyRejected = numberOfRejectedInvoice === numberOfInvoice;

    return (
      <tr className="anim-fade-in bg-white">
        <td className="fluid-cell check-all">
          <Checkbox checked={isChecked} onClick={this.onCheck} disabled={isFullyRejected} />
        </td>
        <td>
          <Text size="large" className="whitespace-nowrap">
            {moment(exportedDate).format('DD MMM YYYY')}
          </Text>
        </td>
        <td className="ellipsis">
          <Text size="large">
            {firstName} {lastName}
          </Text>
        </td>
        <td className="ellipsis">
          <div className="flex align-center">
            {(!Number(batchBillingTotal) || isNonBillable) && (
              <Icon component={NonBillableIcon} style={{ color: 'transparent', fontSize: 24 }} className="mr-small" />
            )}
            <HyperlinkButton hoverUnderline={false} fontSize="large" onClick={() => this._onClickBatch(batchId)}>
              {batchNumber}
            </HyperlinkButton>
            &nbsp;
            <Text size="large">
              ({numberOfInvoice} {numberOfInvoice > 1 ? 'invoices' : 'invoice'}
              {numberOfRejectedInvoice > 0 && (
                <>
                  {','}&nbsp;
                  <Text color="red-darker">{`${numberOfRejectedInvoice} rejected`}</Text>
                </>
              )}
              )
            </Text>
          </div>
        </td>
        <td className="fluid-cell">
          <Text size="large">{CommonUtils.formatPrice(batchBillingTotal)}</Text>
        </td>

        <td style={{ textAlign: 'right' }}>
          <Popover
            content={
              <Menu>
                <MenuItem
                  onClick={() => this._onClickBatch(batchId)}
                  text={
                    <HyperlinkButton hoverUnderline={false} color="blue-action">
                      View batch details
                    </HyperlinkButton>
                  }
                />
                <MenuItem
                  disabled={isFullyRejected}
                  onClick={() => exportFinanceFile(batchId)}
                  text={
                    <HyperlinkButton
                      hoverUnderline={false}
                      color={isFullyRejected ? 'secondary' : 'blue-action'}
                      cursor={isFullyRejected ? 'cursor-not-allowed' : ''}
                    >
                      {actionExportTitle}
                    </HyperlinkButton>
                  }
                />
              </Menu>
            }
            position={'bottom-right'}
          >
            <Icon type="ellipsis" className="text-size-x3-large text-color-blue-action" />
          </Popover>
        </td>
      </tr>
    );
  }
}

export default BatchItemRow;
