import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const IndentDecrease = forwardRef(function IndentDecrease(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 4A.75.75 0 0 1 4 3.25h16a.75.75 0 0 1 0 1.5H4A.75.75 0 0 1 3.25 4Zm7 5a.75.75 0 0 1 .75-.75h9a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1-.75-.75Zm-7 10a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75ZM11 13.25a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5h-9ZM7.03 9.53a.75.75 0 0 0-1.06-1.06l-2.5 2.5a.75.75 0 0 0 0 1.06l2.5 2.5a.75.75 0 1 0 1.06-1.06L5.06 11.5l1.97-1.97Z"
        fill="currentColor"
      />
    </Icon>
  );
});
