import React, { Component } from 'react';
import { Avatar, Icon, Input, Radio, Switch, Tooltip } from 'antd';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import { IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ShiftStatusTag } from 'views/group-services/session-details/team-members/shift-slot-table/ShiftStatusTag';
import moment from 'moment-timezone';
import _ from 'lodash';
import { Popover, Spinner } from '@blueprintjs/core';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import {
  FilterType,
  GroupServiceSessionStatus,
  PublishShiftApplicationAvailability,
  ShiftSlotStatus,
} from 'utilities/enum-utils';
import { ISession, ISessionSupportWorker } from 'interfaces/session-interfaces';
import { IFilter } from 'interfaces/filter-interfaces';
import { FilterSection } from 'common-components/filter';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { ShiftClashContent } from 'common-components/shift-clash';
import CommonUtils from 'utilities/common-utils';
import WorkerStatusTagV2 from 'common-components/tags/WorkerStatusTagV2';

interface IAssignWorkerModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot | any;
  onClose: any;
  doAssignWorkerToShift: typeof dispatch.groupServiceStore.doAssignWorkerToShift;
  session: ISession | any;
  sessionSupportWorkers: typeof state.groupServiceStore.sessionSupportWorkers;
  doFetchSessionSupportWorkers: typeof dispatch.groupServiceStore.doFetchSessionSupportWorkers;
  shiftClashConflicts: typeof state.groupServiceStore.shiftClashConflicts;
  doFetchShiftClashConflicts: typeof dispatch.groupServiceStore.doFetchShiftClashConflicts;
}

interface IAssignWorkerModalState {
  step: number;
  selectedWorker: ISessionSupportWorker;
  canManuallyClose: boolean;
  targetStatus: string;
  filters: IFilter[];
  isSearchAvailable: boolean;
  isLoading: boolean;
  isSearching: boolean;
  isWarningNotEnoughWorker: boolean;
  refreshShiftSlots: boolean;
}

const availableFilters = [
  FilterType.QUALIFICATIONS,
  FilterType.SKILLS,
  FilterType.RELIGIONS,
  FilterType.LANGUAGES,
  FilterType.GENDER,
  FilterType.INTEREST,
  FilterType.SPECIALITIES,
];

class AssignWorkerModal extends Component<IAssignWorkerModalProps, IAssignWorkerModalState> {
  state = {
    step: 1,
    selectedWorker: null,
    canManuallyClose: false,
    targetStatus: ShiftSlotStatus.PENDING,
    filters: [],
    isSearchAvailable: true,
    isLoading: false,
    isSearching: false,
    isWarningNotEnoughWorker: false,
    refreshShiftSlots: false,
  };

  private _goToStep = (step: number, canManuallyClose = true, refreshShiftSlots = false) => {
    this.setState({ step: step, canManuallyClose: canManuallyClose, refreshShiftSlots: refreshShiftSlots });
  };

  private _reset = () => {
    this.setState({
      step: 1,
      selectedWorker: null,
      canManuallyClose: false,
      targetStatus: ShiftSlotStatus.PENDING,
      filters: [],
      isSearchAvailable: true,
      isLoading: false,
      isSearching: false,
      isWarningNotEnoughWorker: false,
      refreshShiftSlots: false,
    });
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.setState({ filters });
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.state.filters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'qualifications':
            requestFilter.qualifications = filter.values;
            break;
          case 'skills':
            requestFilter.skills = filter.values;
            break;
          case 'languages':
            requestFilter.languages = filter.values;
            break;
          case 'interests':
            requestFilter.interests = filter.values;
            break;
          case 'religions':
            requestFilter.religions = filter.values;
            break;
          case 'specialities':
            requestFilter.specialities = filter.values;
            break;
          case 'gender':
            requestFilter.gender = filter.values;
            break;
          default:
            break;
        }
      }
    });

    return requestFilter;
  };

  private _onToggleSearchAvailable = async () => {
    const newSearchAvailable = !this.state.isSearchAvailable;
    this.setState({ isSearchAvailable: newSearchAvailable });
    const payload = this._buildPayload();
    payload.isAvailable = newSearchAvailable;
    await this._loadContent(payload);
  };

  private _loadContent = async (payload) => {
    this.setState({ isLoading: true, selectedWorker: null });
    if (payload.startDateTime && payload.endDateTime) {
      await this.props.doFetchSessionSupportWorkers(payload);
    }
    this.setState({ isLoading: false });
  };

  private _searchText = async (txt) => {
    const payload = this._buildPayload();
    await this._loadContent({ ...payload, searchString: txt });
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this.setState({ isSearching: true });
      this._debounceSearch(e.target.value);
    }
  };

  private _buildPayload = () => {
    const { session, shiftSlot } = this.props;
    const { isSearchAvailable } = this.state;
    const { serviceDateTimeId, serviceId } = session;
    const requestFilter = this._formatFilterQuery();

    const payload = {
      serviceDateTimeId,
      serviceId,
      startDateTime: shiftSlot && shiftSlot.shiftStartDateTime,
      endDateTime: shiftSlot && shiftSlot.shiftEndDateTime,
      isAvailable: isSearchAvailable,
    };

    return _.isEmpty(requestFilter) ? payload : { ...payload, ...requestFilter };
  };

  private _onAssignTeamMember = async (status: ShiftSlotStatus = null) => {
    const { doAssignWorkerToShift, shiftSlot, session } = this.props;
    const { selectedWorker } = this.state;

    const { serviceId, serviceDateTimeId } = session;
    const { supportWorkerAttendanceId } = shiftSlot;
    const { supportWorkerId } = selectedWorker;

    // go to loading step
    this._goToStep(2, false);
    const payload = {
      serviceId,
      serviceDateTimeId,
      supportWorkerAttendanceId,
      supportWorkerId,
    };

    if (status) {
      await doAssignWorkerToShift({ ...payload, shiftSlotStatus: status });
    } else {
      await doAssignWorkerToShift(payload);
    }

    // go to success step
    this._goToStep(4, true, true);
  };

  private _handleLogicBeforeAssign = async (targetStatus: ShiftSlotStatus = null) => {
    const { selectedWorker } = this.state;
    const { session, shiftSlot, doFetchShiftClashConflicts } = this.props;

    // validation
    if (!session || !shiftSlot) return;

    if (!selectedWorker) {
      this.setState({ isWarningNotEnoughWorker: true });
      return;
    }

    // get all conflict shifts of selected worker to show in ShiftClashModal
    const { serviceId, serviceDateTimeId } = session;
    const { supportWorkerAttendanceId } = shiftSlot;
    const payload = {
      serviceDateTimeId,
      serviceId,
      supportWorkerIds: [selectedWorker.supportWorkerId],
      supportWorkerAttendanceId,
    };

    const shiftClashConflicts = await doFetchShiftClashConflicts(payload);

    // no shift conflicts
    if (_.isEmpty(shiftClashConflicts)) {
      await this._onAssignTeamMember(targetStatus);
    }
    // has shift conflicts
    else {
      // go to shift clash confirm step
      this._goToStep(3);
    }
    this.setState({ targetStatus: targetStatus });
  };

  private _onClose = () => {
    const { onClose } = this.props;
    this._reset();
    onClose({ targetFlag: 'isAssignWorkerOpen' }, this.state.refreshShiftSlots);
  };

  private _onSelectWorker = (selectedWorker) => {
    this.setState({ selectedWorker, isWarningNotEnoughWorker: false });
  };

  private _onConfirmAssignShiftClash = () => {
    const { targetStatus } = this.state;
    this._onAssignTeamMember(targetStatus);
  };

  private onCancelShiftClash = () => {
    this._goToStep(1);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.session && this.props.session !== prevProps.session) {
      this._reset();
    }
    if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen && this.props.session) {
      const payload = this._buildPayload();
      await this._loadContent(payload);
    }
    if (this.state.filters !== prevState.filters) {
      const payload = this._buildPayload();
      await this._loadContent(payload);
    }
  };

  render() {
    const { shiftSlot, sessionSupportWorkers, session, shiftClashConflicts } = this.props;
    const { selectedWorker } = this.state;

    if (_.isEmpty(shiftSlot)) {
      return <></>;
    }

    const { shiftStartDateTime, shiftEndDateTime } = shiftSlot;
    const assignedWorkerFullName =
      shiftSlot.firstName && shiftSlot.lastName ? `${shiftSlot.firstName} ${shiftSlot.lastName}` : 'Not assigned';
    const selectedWorkerFullName = selectedWorker
      ? `${selectedWorker.firstName || ''} ${selectedWorker.lastName || ''}`
      : '';

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={
          this.state.step === 3
            ? 'Hold on, this team member is already working'
            : ' Select a team member for this shift'
        }
        onClose={this._onClose}
        width={(() => {
          switch (this.state.step) {
            case 1:
              return 'x-large';
            case 3:
              return 'large';
            case 2:
            case 4:
              return 'medium';
            default:
              return 'large';
          }
        })()}
        canCloseOutside={this.state.canManuallyClose}
      >
        {/* assign team meber */}
        {this.state.step === 1 && (
          <div className="anim-fade-in-fast">
            {/*Title */}
            <div className="mb-large">
              <Text>
                Please confirm a team member for this booking. Alternatively, if you&apos;re unsure of their
                availability, choose &apos;Mark as not confirmed&apos;.
              </Text>
            </div>

            {/* Display icon */}
            <div className="rounded-big bordered border-standard-gray flex-row line-height-120 mb-large">
              <div className="p-medium bg-quaternary flex-column justify-center align-end">
                <Text lineHeight={120} className="text-align-right">
                  {/*9:30 AM*/}
                  {moment.tz(shiftStartDateTime, session.timezone).format('h:mm A')}
                  <br />
                  {/*10:30 AM*/}
                  {moment.tz(shiftEndDateTime, session.timezone).format('h:mm A')}
                </Text>
              </div>
              <div className="flex-row p-medium bg-quaternary align-center">
                <div>
                  {!_.isEmpty(shiftSlot.attachmentUrl) && (
                    <Avatar icon="user" shape="square" src={shiftSlot.attachmentUrl} className="mr-medium" />
                  )}

                  {_.isEmpty(shiftSlot.attachmentUrl) && <Avatar icon="user" shape="square" className="mr-medium" />}
                </div>
                <div>
                  <Text color={shiftSlot.firstName ? 'secondary' : ''}>{assignedWorkerFullName}</Text>
                </div>
              </div>
              <div className="p-medium flex-1 bg-quaternary flex-column justify-center">
                <div className="inline-block">
                  <ShiftStatusTag shiftStatus={shiftSlot.shiftSlotStatus} />
                </div>
              </div>
            </div>

            {/* Content panel */}
            <div className="flex-row line-height-120 mb-medium">
              {/* Left panel */}
              <div className="bg-quaternary p-medium rounded mr-medium" style={{ width: '360px' }}>
                <div className="mb-medium">
                  <FieldLabel text={'FILTERS'} />
                </div>
                <div className="mb-medium">
                  <Text lineHeight={120}>Show team members who...</Text>
                </div>
                <div className="mb-medium">
                  <Switch
                    size="small"
                    checked={this.state.isSearchAvailable}
                    onChange={this._onToggleSearchAvailable}
                    style={{ backgroundColor: this.state.isSearchAvailable ? '#1890FF' : '' }}
                  />
                  <Text lineHeight={200} className="ml-small" size="x-small">
                    Are available during the scheduled shift(s)
                  </Text>
                </div>

                <div>
                  <FilterSection
                    availableFilters={availableFilters}
                    filters={this.state.filters}
                    onChangeFilter={this._onChangeFilter}
                    displayTimezone={''}
                    usePortal={false}
                  />
                </div>
              </div>

              {/* Right panel */}
              <div className="flex-1">
                <div className="mb-medium">
                  <Text size={'regular'} color="secondary" lineHeight={120}>
                    {/* TODO @ Jir - Update this number */}
                    <b>{this.state.selectedWorker === null ? '0' : '1'}/1</b> shift(s) to fill
                  </Text>
                </div>
                <div className="mb-medium">
                  <Input.Search
                    size="large"
                    placeholder={'Search for team members...'}
                    onChange={this._onEnterSearchText}
                    loading={this.state.isSearching}
                    allowClear
                  />{' '}
                </div>

                <div
                  className="bordered border-standard-gray rounded"
                  style={{ minHeight: '25vh', maxHeight: '35vh', overflowY: 'auto' }}
                >
                  {_.map(sessionSupportWorkers, (worker) => (
                    <AssignWorkerItem
                      worker={worker}
                      onSelectWorker={this._onSelectWorker}
                      isSelected={
                        worker.supportWorkerId ===
                        (this.state.selectedWorker && this.state.selectedWorker.supportWorkerId)
                      }
                      key={worker.supportWorkerId}
                    />
                  ))}
                </div>
              </div>
            </div>

            <ActionModalFooter align="right">
              <div className="flex-row justify-end">
                <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                  Cancel
                </SecondaryButton>
                {session.sessionStatus === GroupServiceSessionStatus.COMPLETED ? (
                  <PrimaryButton size="large" onClick={() => this._onAssignTeamMember()} className="rounded-left">
                    Assign team member
                  </PrimaryButton>
                ) : (
                  <>
                    <PrimaryButton
                      size="large"
                      onClick={() => this._handleLogicBeforeAssign(ShiftSlotStatus.CONFIRMED)}
                      className="rounded-left"
                    >
                      Assign as <b> Confirmed</b>
                    </PrimaryButton>

                    <Popover
                      content={
                        <ActionMenu>
                          <ActionMenuItem
                            text="Assign as Pending"
                            onClick={() => this._handleLogicBeforeAssign(ShiftSlotStatus.PENDING)}
                          />
                        </ActionMenu>
                      }
                      position="bottom-left"
                      usePortal={false}
                    >
                      <IconButton className="rounded-right ml-x2-small" icon="down" size="large" />
                    </Popover>
                  </>
                )}
              </div>
              {this.state.isWarningNotEnoughWorker && (
                <div>
                  <Text color="red-dark">You must select a team member to assign.</Text>
                </div>
              )}
            </ActionModalFooter>
          </div>
        )}

        {/* loading */}
        {this.state.step === 2 && (
          <div className="line-height-135 anim-slide-left">
            <div className="flex-column align-center mv-large justify-center">
              <div className="mb-medium">
                <Spinner size={80} />
              </div>
              <div className="text-align-center">
                <Text color="secondary" weight="bold">
                  Assigning team member to shift...
                </Text>
                <br />
                <Text color="secondary">This won&apos;t take long.</Text>
              </div>
            </div>
          </div>
        )}

        {/* shift clash  */}
        {this.state.step === 3 && (
          <div className="anim-fade-in-fast">
            <ShiftClashContent
              shiftClashConflicts={shiftClashConflicts}
              description={(() => {
                const totalShiftClashes = CommonUtils.calculateTotalShiftClashes(shiftClashConflicts);
                return (
                  <Paragraph>
                    This team member is assigned to{' '}
                    <b>
                      {totalShiftClashes} shift{totalShiftClashes > 1 ? 's ' : ' '}
                    </b>
                    that conflict{totalShiftClashes === 1 ? 's' : ''} with this one. Be sure to double-check their
                    availability before confirming.
                  </Paragraph>
                );
              })()}
              firstColumnTitle="BOOKING BEING ASSIGNED"
              okButtonText="Assign anyway"
              onCancel={this.onCancelShiftClash}
              onOk={this._onConfirmAssignShiftClash}
            />
          </div>
        )}

        {/* success */}
        {this.state.step === 4 && (
          <div className="anim-fade-in-fast line-height-135">
            <div className="mb-medium">
              <Text lineHeight={135}>You have successfully added a team member to the following slot: </Text>
            </div>

            <div className="mb-medium">
              <FieldLabel text={'SHIFT TIME'} />
              <div className="mt-x2-small">
                <Text>
                  {moment.tz(shiftStartDateTime, session.timezone).format('h:mm A')} -{' '}
                  {moment.tz(shiftEndDateTime, session.timezone).format('h:mm A D MMMM YYYY')}
                </Text>
              </div>
            </div>

            <div className="mb-large">
              <FieldLabel text={'ASSIGNED TEAM MEMBER'} />
              <div className="mt-x-small flex-row align-center">
                <Avatar
                  className={'mr-small'}
                  src={this.state.selectedWorker && this.state.selectedWorker.attachmentUrl}
                  shape={'square'}
                />
                <Text>
                  {selectedWorkerFullName} {this.state.targetStatus && `(${_.capitalize(this.state.targetStatus)})`}
                </Text>
              </div>
            </div>

            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this._onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

class AssignWorkerItem extends Component<{
  worker: ISessionSupportWorker;
  onSelectWorker: any;
  isSelected: boolean;
}> {
  onSelect = () => {
    const { worker, onSelectWorker } = this.props;
    onSelectWorker(worker);
  };

  checkCanAssign = (workerAvailability: string) => {
    // check can assign
    switch (workerAvailability) {
      // can assign even conflict
      case PublishShiftApplicationAvailability.AVAILABLE:
      case PublishShiftApplicationAvailability.SHIFT_CLASH:
      case PublishShiftApplicationAvailability.UNAVAILABLE:
      case PublishShiftApplicationAvailability.OUTSIDE_GENERAL_AVAILABILITY:
      case PublishShiftApplicationAvailability.UNAVAILABLE_CAN_BE_OVERRIDDEN:
        return true;

      // can't assign
      default:
        return false;
    }
  };

  render() {
    const { worker, isSelected } = this.props;
    const fullName = `${worker.firstName} ${worker.lastName}`;

    const isAvailable = this.checkCanAssign(worker.availability);

    const isShiftClash = worker.availability === PublishShiftApplicationAvailability.SHIFT_CLASH;
    const isOutsideGeneralAvailability =
      worker.availability === PublishShiftApplicationAvailability.OUTSIDE_GENERAL_AVAILABILITY;
    const isUnavailableCanBeOverridden =
      worker.availability === PublishShiftApplicationAvailability.UNAVAILABLE_CAN_BE_OVERRIDDEN;

    const unavailableStyle = {
      iconClassName: 'text-color-red-dark bg-red-lightest',
      text: 'Unavailable',
      iconType: 'close',
    };

    const unavailableCanBeOverriddenStyle = {
      iconClassName: 'text-color-red-dark bg-red-lightest',
      text: 'Unavailable (can be overridden)',
      iconType: 'close',
    };

    const outsideGeneralAvailabilityStyle = {
      iconClassName: 'text-color-orange bg-orange-lightest',
      text: 'Outside general availability',
      iconType: 'question',
    };

    const shiftClashStyle = {
      iconClassName: 'text-color-red-dark bg-red-lightest',
      text: 'Shift Clash (can override)',
      iconType: 'close',
    };

    const availableStyle = {
      iconClassName: 'text-color-green-dark bg-green-lightest',
      text: 'Available',
      iconType: 'check',
    };

    const iconStyle = !isAvailable
      ? unavailableStyle
      : isShiftClash
      ? shiftClashStyle
      : isUnavailableCanBeOverridden
      ? unavailableCanBeOverriddenStyle
      : isOutsideGeneralAvailability
      ? outsideGeneralAvailabilityStyle
      : availableStyle;

    return (
      <div
        className={`flex-row ph-medium align-center hover-bg-quaternary  ${
          isAvailable && 'cursor-pointer'
        } select-none`}
        style={{ paddingTop: '12px', paddingBottom: '12px' }}
        onClick={isAvailable ? this.onSelect : () => false}
      >
        <Radio checked={isSelected} disabled={!isAvailable} />
        <Avatar
          src={worker.attachmentUrl}
          size="large"
          className="ml-medium mr-medium"
          shape={'square'}
          style={{ opacity: !isAvailable ? 0.5 : 1 }}
        />
        <div
          style={{ width: '220px', flexDirection: 'column', opacity: !isAvailable ? 0.5 : 1 }}
          className="line-height-150"
        >
          <Text lineHeight={150}>{fullName}</Text> <br />
          <div className="line-height-100 align-center flex-row">
            <WorkerStatusTagV2 shiftSlotStatus={worker.availability} icon="info-circle" />
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  sessionSupportWorkers: state.groupServiceStore.sessionSupportWorkers,
  shiftClashConflicts: state.groupServiceStore.shiftClashConflicts,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAssignWorkerToShift: dispatch.groupServiceStore.doAssignWorkerToShift,
  doFetchSessionSupportWorkers: dispatch.groupServiceStore.doFetchSessionSupportWorkers,
  doFetchShiftClashConflicts: dispatch.groupServiceStore.doFetchShiftClashConflicts,
});

export default connect(mapState, mapDispatch)(AssignWorkerModal);
