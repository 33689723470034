import React from 'react';
import { Skeleton } from '@goodhuman-me/components';

export const PanelSkeleton = () => (
  <div className="m-large" data-testid="loading-service-agreements-skeleton">
    <Skeleton.Root height={400}>
      <Skeleton.Heading size="xlarge" />
      {Array.from({ length: 4 }).map((_, index) => (
        <Skeleton.Heading key={index} y={60 + index * 40} />
      ))}
    </Skeleton.Root>
  </div>
);
