import React from 'react';
import clsx from 'clsx';
import { Avatar } from 'antd';
import { format, intervalToDuration } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Text, Link } from '@good/components';
import { NoteText, User, Users } from '@good/icons';

import { TableColumn } from 'design-components';
import { IShiftComponent, ITimesheetShiftItem } from 'interfaces/booking-interfaces';
import { ServiceType } from 'utilities/enum-utils';
import { Popover } from '../../Popover';

type ITextCellProps = {
  text: string;
  className?: string;
};

type IRecordCellProps = {
  record: ITimesheetShiftItem;
};

type IExtractedWorkType = {
  label: string;
  weight: number;
};

const orderWorkTypesAndExtractText = (shiftComponents: IShiftComponent[]): string[] => {
  const orderedWorkTypes: IExtractedWorkType[] = [];
  shiftComponents.forEach((shiftComponent) => {
    const workType = shiftComponent.workType;
    if (workType.shiftType === 'sleepover') {
      orderedWorkTypes.push({
        label: workType.name,
        weight: 1,
      });
    } else if (workType.shiftType === 'disturbance') {
      orderedWorkTypes.push({
        label: workType.name,
        weight: 2,
      });
    } else if (workType.category === 'LEAVE_TYPE') {
      orderedWorkTypes.push({
        label: workType.name,
        weight: 3,
      });
    } else if (workType.shiftType === 'normal') {
      orderedWorkTypes.push({
        label: workType.name,
        weight: 4,
      });
    } else {
      orderedWorkTypes.push({
        label: workType.name,
        weight: 5,
      });
    }
  });
  return orderedWorkTypes.sort((a, b) => a.weight - b.weight).map((workType) => workType.label);
};

const TextCell = ({ text, className }: ITextCellProps) => {
  return <Text className={clsx(className, 'px-3 py-2')}>{text}</Text>;
};

const DateCell = ({ record }: IRecordCellProps) => {
  return (
    <div className="flex flex-col gap-1 py-3.5 pl-3 pr-3">
      <Text className="font-semibold">
        {record.startDateTime ? format(new Date(record.startDateTime), 'd LLL yyyy') : '-'}
      </Text>
      {record.startDateTime && record.endDateTime && (
        <Text className="text-weak" size="xs">
          {format(new Date(record.startDateTime), 'h aaa')} - {format(new Date(record.endDateTime), 'h aaa')}{' '}
        </Text>
      )}
    </div>
  );
};

const TeamMemberCell = ({ record }: IRecordCellProps) => {
  const { supportWorkerDetails } = record;
  const hasEmployeeNumber =
    supportWorkerDetails?.employeeNumber !== null && supportWorkerDetails?.employeeNumber !== '';
  return (
    <div className="flex-row py-2">
      <div className="flex-row items-center pl-2 pr-2">
        <Avatar icon="user" shape="square" src={supportWorkerDetails?.avatar ?? ''} size={32} />
      </div>
      <div className={`${hasEmployeeNumber ? 'flex-col' : 'flex-row'}`}>
        <div className="flex items-center">
          <Text className="font-semibold">
            {supportWorkerDetails?.firstName} {supportWorkerDetails?.lastName}
          </Text>
        </div>
        {hasEmployeeNumber && (
          <div className="flex">
            <Text size="xs" className="text-weak">
              ID: {supportWorkerDetails?.employeeNumber ?? '-'}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

const ServiceCell = ({ record }: IRecordCellProps) => {
  const { serviceDetails } = record;
  return (
    <div className="overflow-hidden truncate px-3 py-2">
      {serviceDetails?.serviceType === ServiceType.GROUP ? (
        <Users className="pr-1" aria-label="Group service icon" />
      ) : serviceDetails?.serviceType === ServiceType.COORDINATION ? (
        <NoteText className="pr-1" aria-label="Support coordination icon" />
      ) : (
        <User className="pr-1" aria-label="Support service icon" />
      )}
      <Text>{serviceDetails?.serviceName}</Text>
    </div>
  );
};

const WorkTypeCell = ({ record }: IRecordCellProps) => {
  const workTypes: string[] = orderWorkTypesAndExtractText(record.shiftComponents ?? []);
  const additionalWorkTypes: JSX.Element | undefined =
    workTypes.length > 1 ? <Text className="text-weak">{` (+${workTypes.length - 1} more)`}</Text> : undefined;

  return (
    <div className="px-3 py-2">
      <Text>{workTypes[0]}</Text>
      {additionalWorkTypes && additionalWorkTypes}
    </div>
  );
};

const DurationCell = ({ record }: IRecordCellProps) => {
  const duration = record.duration && intervalToDuration({ start: new Date(0), end: record.duration * 60 * 60 * 1000 });
  const durationLabel = duration
    ? `${duration.hours ? `${duration.hours}h` : ''} ${duration.minutes ? `${duration.minutes}m` : ''}`
    : '-';

  return <TextCell text={durationLabel} className="font-semibold" />;
};

export function useTableColumns(): TableColumn<unknown>[] {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets.table' });

  const tableColumns = [];

  // Date and time
  tableColumns.push({
    title: (
      <Text className="font-semibold">
        <span className="text-uppercase">{t('headers.date_and_time')}</span>
      </Text>
    ),
    dataIndex: 'date',
    key: 'date',
    width: 180,
    render: (_text: string, record: ITimesheetShiftItem) => <DateCell record={record} />,
  });

  // Team member
  tableColumns.push({
    title: (
      <Text className="font-semibold">
        <span className="text-uppercase">{t('headers.team_member')}</span>
      </Text>
    ),
    dataIndex: 'teamMember',
    key: 'teamMember',
    width: 250,
    render: (_text: string, record: ITimesheetShiftItem) => <TeamMemberCell record={record} />,
  });

  // Service
  tableColumns.push({
    title: (
      <Text className="font-semibold">
        <span className="text-uppercase">{t('headers.service')}</span>
      </Text>
    ),
    dataIndex: 'service',
    key: 'service',
    width: 200,
    render: (_text: string, record: ITimesheetShiftItem) => <ServiceCell record={record} />,
  });

  // Time type
  tableColumns.push({
    title: (
      <div className="align-center flex" style={{ gap: 4 }}>
        <Text className="text-uppercase font-semibold">{t('headers.time_type')}</Text>
        <Popover content={t('tooltips.headers.duration')} />
      </div>
    ),
    dataIndex: 'timeType',
    key: 'timeType',
    width: 200,
    render: (_text: string, record: ITimesheetShiftItem) => <WorkTypeCell record={record} />,
  });

  // Travel
  tableColumns.push({
    title: (
      <Text className="font-semibold">
        <span className="text-uppercase">{t('headers.travel')}</span>
      </Text>
    ),
    dataIndex: 'travel',
    key: 'travel',
    width: 180,
    render: (_text: string, record: ITimesheetShiftItem) => (
      <TextCell
        text={
          record.travelBreakdown?.travelDistanceTotal && record.travelBreakdown.travelDistanceTotal > 0
            ? `${Math.round(record.travelBreakdown.travelDistanceTotal)}km`
            : '-'
        }
      />
    ),
  });

  // Duration
  tableColumns.push({
    title: (
      <Text className="text-right font-semibold">
        <span className="text-uppercase">{t('headers.duration')}</span>
      </Text>
    ),
    dataIndex: 'duration',
    key: 'duration',
    width: 230,
    align: 'right',
    render: (_text: string, record: ITimesheetShiftItem) => (
      <div className="text-right">
        <DurationCell record={record} />
      </div>
    ),
  });

  // View button
  tableColumns.push({
    title: '',
    dataIndex: 'view',
    key: 'view',
    width: 75,
    render: () => (
      <div className="text-center">
        <Text>
          <Link emphasis="no-underline">
            <button>View</button>
          </Link>
        </Text>
      </div>
    ),
  });

  return tableColumns;
}
