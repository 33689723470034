import React, { Component } from 'react';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import { Paragraph, SubTitle, Title, Text } from 'common-components/typography';
import { Checkbox, Form, Select, Tabs } from 'antd';
import _ from 'lodash';
import { Information } from 'common-components/alerts';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import NDISLineItemGrid from 'common-components/line-items/NDISLineItemGrid';
import { PaymentSources, TeamMemberCustomerRatio } from 'utilities/enum-utils';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import AddNDISLineItemsModal from 'views/services/listing/components/AddNDISLineItemsModal';
import Utils from 'utilities/Utils';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ITeamMemberCustomerRatio } from 'interfaces/service-interfaces';

interface IGroupServicePricingConfigModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  serviceId: string;
  groupServicePayment: typeof state.servicesStore.groupServicePayment;
  doFetchGroupServicePayment: typeof dispatch.servicesStore.doFetchGroupServicePayment;
  doUpdateGroupServicePayment: typeof dispatch.servicesStore.doUpdateGroupServicePayment;
}

interface IGroupServicePricingConfigModalState {
  isChargeCentreCapitalCosts: boolean;
  isEditMode: boolean;
  isOpenAddLineItemsModal: boolean;
  isDiscardChangesModalOpen: boolean;
  isWarningTeamCustomerRatio: boolean;
  selectedTab: string;
  serviceBillingItems: any[];
  teamMemberCustomerRatio: string;
}

class GroupServicePricingConfigModal extends Component<
  IGroupServicePricingConfigModalProps,
  IGroupServicePricingConfigModalState
> {
  state = {
    isChargeCentreCapitalCosts:
      this.props.groupServicePayment &&
      this.props.groupServicePayment.groupServiceClaims.ndisClaims.isChargeCentreCapitalCosts,
    isEditMode: false,
    isOpenAddLineItemsModal: false,
    isDiscardChangesModalOpen: false,
    isWarningTeamCustomerRatio: false,
    selectedTab: 'lineItems',
    serviceBillingItems: this.props.groupServicePayment
      ? _.cloneDeep(this.props.groupServicePayment.billingLineItems)
      : [],
    teamMemberCustomerRatio: this.props.groupServicePayment
      ? this.props.groupServicePayment.teamMemberCustomerRatio.ndis
      : null,
  };

  private _closePricingConfigModal = () => {
    this.setState({
      isEditMode: false,
      selectedTab: 'lineItems',
      isOpenAddLineItemsModal: false,
      isDiscardChangesModalOpen: false,
    });
    this.props.onClose();
  };

  private _openAddLineItemModal = () => {
    this.setState({ isOpenAddLineItemsModal: true });
  };

  private _closeAddLineItemModal = () => {
    this.setState({ isOpenAddLineItemsModal: false });
  };

  private _openDiscardChangesModal = () => {
    this.setState({ isDiscardChangesModalOpen: true });
  };

  private _closeDiscardChangesModal = () => {
    this.setState({ isDiscardChangesModalOpen: false });
  };

  private _changeTab = (event) => {
    this.setState({ selectedTab: event });
  };

  private _changeMemberCustomerRatio = (e) => {
    this.setState({ teamMemberCustomerRatio: e });
  };

  private _changeIsChargeCentreCapitalCosts = () => {
    const newIsCharge = !this.state.isChargeCentreCapitalCosts;
    this.setState({ isChargeCentreCapitalCosts: newIsCharge });
  };

  private _enterEditMode = () => {
    this.setState({ isEditMode: true });
  };

  private _cancelAllChanges = () => {
    this.setState({
      isChargeCentreCapitalCosts:
        this.props.groupServicePayment.groupServiceClaims.ndisClaims.isChargeCentreCapitalCosts,
      isEditMode: false,
      isDiscardChangesModalOpen: false,
      serviceBillingItems: this.props.groupServicePayment
        ? _.cloneDeep(this.props.groupServicePayment.billingLineItems)
        : [],
      teamMemberCustomerRatio: this.props.groupServicePayment
        ? this.props.groupServicePayment.teamMemberCustomerRatio.ndis
        : null,
    });
  };

  private _handleOnDelete = (billingLineItems) => {
    this.setState({ serviceBillingItems: billingLineItems });
  };

  private _handleSequenceChange = (billingLineItems) => {
    this.setState({ serviceBillingItems: billingLineItems });
  };

  private _handleOnPriceChange = (billingLineItems) => {
    this.setState({ serviceBillingItems: _.clone(billingLineItems) });
  };

  private _validateForm = () => {
    const { form } = this.props;
    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    return isFormValid;
  };

  private _onSave = async () => {
    if (!this.state.teamMemberCustomerRatio && this.state.selectedTab === 'claims') {
      this.setState({ selectedTab: 'lineItems', isWarningTeamCustomerRatio: true });
      return;
    }

    if (!this._validateForm()) return;

    const payload = {
      paymentSourceType: this.props.groupServicePayment.paymentSourceType,
      billingLineItems: this._parseServiceBillingItems(this.state.serviceBillingItems),
      groupServiceClaims: { ndisClaims: { isChargeCentreCapitalCosts: this.state.isChargeCentreCapitalCosts } },
      serviceId: this.props.serviceId,
      teamMemberCustomerRatio: { ndis: this.state.teamMemberCustomerRatio },
    };
    await this.props.doUpdateGroupServicePayment(payload);
    this.setState({ isEditMode: false });
  };

  private _parseServiceBillingItems = (serviceBillingItems) => {
    return _.map(serviceBillingItems, (lineItem) => {
      return {
        ...lineItem,
        tax: Number(lineItem.tax),
        mileagePrice: Number(lineItem.mileagePrice),
      };
    });
  };

  private _saveLineItem = (billingItems) => {
    let newBillingItems = _.clone(this.state.serviceBillingItems);
    billingItems.map((lineItem) => {
      let priority = null;
      if (
        !Utils.isEmpty(lineItem.GroupCode) &&
        _.find(newBillingItems, (existingLines) => existingLines.groupCode === lineItem.GroupCode)
      ) {
        priority = _.find(newBillingItems, (existingLines) => existingLines.groupCode === lineItem.GroupCode).priority;
      } else {
        let prioritizedLineItems = _.clone(newBillingItems);
        _.sortBy(prioritizedLineItems, 'priority', 'desc');
        let lastLineItem = prioritizedLineItems ? prioritizedLineItems.pop() : null;
        priority = lastLineItem && lastLineItem.priority ? lastLineItem.priority + 1 : 1;
      }
      newBillingItems.push({
        supportItemNumber: lineItem.SupportItemNumber,
        supportCategoryNumber: Number(lineItem.SupportCategoryNumber),
        price: {
          nonRemotePrice: lineItem.NationalNonRemote ? Number(lineItem.NationalNonRemote) : 0,
          remotePrice: Number(lineItem.NationalRemote),
          veryRemotePrice: Number(lineItem.NationalVeryRemote),
          actPrice: Number(lineItem.ACTPrice),
          ntPrice: Number(lineItem.NTPrice),
        },
        tax: 0,
        unit: lineItem.UnitOfMeasure,
        dateType: lineItem.DateType,
        billingProvider: 'NDIS',
        label: lineItem.SupportItem,
        priceControl: lineItem.PriceControl,
        priority,
        mileagePrice: 0,
        groupCode: lineItem.GroupCode,
        customerSupportLevel: '1',
      });
    });
    this.setState({ isOpenAddLineItemsModal: false, serviceBillingItems: newBillingItems });
  };

  componentDidMount = async () => {
    const { serviceId } = this.props;
    this.props.doFetchGroupServicePayment({ serviceId });
  };

  componentDidUpdate = (prevProps) => {
    if (this.state.isWarningTeamCustomerRatio && this.state.selectedTab === 'lineItems') {
      const { form } = this.props;
      form.validateFields(async (err) => {
        if (err) {
          this.setState({ isWarningTeamCustomerRatio: false });
        }
      });
    }

    if (this.props.groupServicePayment && this.props.groupServicePayment !== prevProps.groupServicePayment) {
      this.setState({
        serviceBillingItems: _.cloneDeep(this.props.groupServicePayment.billingLineItems),
        isChargeCentreCapitalCosts:
          this.props.groupServicePayment.groupServiceClaims.ndisClaims.isChargeCentreCapitalCosts,
        teamMemberCustomerRatio: this.props.groupServicePayment.teamMemberCustomerRatio.ndis,
      });
    }
  };

  render() {
    const { form } = this.props;
    const { isChargeCentreCapitalCosts, isEditMode, selectedTab, serviceBillingItems, teamMemberCustomerRatio } =
      this.state;
    const { getFieldDecorator } = form;

    const infoLineItems = (
      <Text>
        The line items for group services are fixed by NDIS.
        <br />
        You can adjust your billing based on the customers care requirements and edit prices based on location.
      </Text>
    );

    const infoCentreCost = (
      <Text>
        Enabling centre capital costs will automatically add the relevant line items to the services pricing and all
        service agreements created for this service.
      </Text>
    );

    return (
      <FullScreenScrollableModal
        isOpen={this.props.isOpen}
        onClose={this._closePricingConfigModal}
        width={'large'}
        canCloseEsc={false}
        fixedHeight={false}
      >
        <ActionModal
          isOpen={this.state.isDiscardChangesModalOpen}
          onClose={this._closeDiscardChangesModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className='mr-medium' size='large' onClick={this._closeDiscardChangesModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size='large' onClick={this._cancelAllChanges}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <AddNDISLineItemsModal
          closeLineItemModal={this._closeAddLineItemModal}
          isOpen={this.state.isOpenAddLineItemsModal}
          saveLineItem={this._saveLineItem}
          serviceBillingItems={this.state.serviceBillingItems}
        />
        <div className='anim-slide-left mb-x-large height-full relative'>
          <Title level={2} weight='bolder' className='line-height-100'>
            Configure NDIS settings
          </Title>
          <Paragraph>Configure settings for this service’s pricings.</Paragraph>

          <Tabs animated={true} onChange={this._changeTab} activeKey={selectedTab}>
            <Tabs.TabPane tab='NDIS Line Items' key='lineItems' />
            <Tabs.TabPane tab='NDIS claims' key='claims' />
          </Tabs>

          {selectedTab === 'lineItems' && (
            <>
              <Paragraph className='mt-small mb-large'>
                The following line items will be used to charge the customer for this service.
              </Paragraph>

              <Information content={infoLineItems} className='width-full mb-large' />

              {!isEditMode && (
                <PrimaryButton className='mb-large' onClick={this._enterEditMode}>
                  Edit
                </PrimaryButton>
              )}

              <SubTitle>default team member : customer ratio</SubTitle>
              {isEditMode ? (
                <Form.Item className={'m-none pr-small'}>
                  {getFieldDecorator('teamMemberCustomerRatio', {
                    initialValue: teamMemberCustomerRatio,
                    rules: [
                      {
                        required: true,
                        message: 'Default Ratio is required',
                      },
                    ],
                  })(
                    <Select
                      placeholder='Select..'
                      style={{ width: '200px' }}
                      onChange={this._changeMemberCustomerRatio}
                    >
                      {_.map(TeamMemberCustomerRatio, (ratio) => (
                        <Select.Option value={ratio}>{ratio}</Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              ) : (
                <Text>{teamMemberCustomerRatio}</Text>
              )}

              <br />
              <Text size='regular' className='mt-medium' style={{ fontStyle: 'italic' }}>
                This will be the default ratio for customers if they do not not have a service agreement for this
                service.
              </Text>
              <div className='mt-large'>
                <NDISLineItemGrid
                  displayMode={'EDIT'}
                  isDeletable={isEditMode}
                  isSequenceAdjustable={isEditMode}
                  onDelete={this._handleOnDelete}
                  onSequenceChange={this._handleSequenceChange}
                  isPriceAdjustable={isEditMode}
                  onPriceChange={this._handleOnPriceChange}
                  lineItems={serviceBillingItems}
                  paymentSourceType={PaymentSources.NDIS}
                />
              </div>
            </>
          )}

          {selectedTab === 'claims' && !isEditMode && (
            <>
              <PrimaryButton className='mt-small mb-large' onClick={this._enterEditMode}>
                Edit claims
              </PrimaryButton>
              <div className='bordered shadow-container rounded-big p-large'>
                <SubTitle>centre - Capital costs</SubTitle>
                <Text>
                  <span
                    className={
                      isChargeCentreCapitalCosts
                        ? 'text-color-green text-weight-bold'
                        : 'text-color-red text-weight-bold'
                    }
                  >
                    {isChargeCentreCapitalCosts ? 'Yes' : 'No'}
                  </span>{' '}
                  - Centre capital costs{' '}
                  <span className='text-weight-bold'>{isChargeCentreCapitalCosts ? 'can' : 'can not'}</span> be charged
                  for this service.
                </Text>
              </div>
            </>
          )}

          {selectedTab === 'claims' && isEditMode && (
            <>
              <div className='flex-column bordered mt-large ph-large pt-large pb-x2-large shadow-container rounded'>
                <SubTitle containerClassName='mb-medium'>centre capital costs</SubTitle>
                <Checkbox checked={isChargeCentreCapitalCosts} onClick={this._changeIsChargeCentreCapitalCosts}>
                  Claim for centre capital costs.
                </Checkbox>
                <Information content={infoCentreCost} className='mt-large' />
              </div>
            </>
          )}

          {!isEditMode ? (
            <SecondaryButton size='large' className='mt-large' onClick={this._closePricingConfigModal}>
              Close
            </SecondaryButton>
          ) : selectedTab === 'lineItems' ? (
            <div className='align-center flex-row justify-between'>
              <HyperlinkButton onClick={this._openAddLineItemModal}>+ Add line items</HyperlinkButton>
              <div>
                <SecondaryButton size='large' className='mr-medium' onClick={this._openDiscardChangesModal}>
                  Discard changes
                </SecondaryButton>
                <PrimaryButton size='large' onClick={this._onSave}>
                  Save
                </PrimaryButton>
              </div>
            </div>
          ) : (
            <div className='absolute' style={{ left: '0', bottom: '60px' }}>
              <SecondaryButton size='large' className='mr-medium' onClick={this._openDiscardChangesModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size='large' onClick={this._onSave}>
                Save
              </PrimaryButton>
            </div>
          )}
        </div>
      </FullScreenScrollableModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServicePayment: state.servicesStore.groupServicePayment,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServicePayment: dispatch.servicesStore.doFetchGroupServicePayment,
  doUpdateGroupServicePayment: dispatch.servicesStore.doUpdateGroupServicePayment,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IGroupServicePricingConfigModalProps>()(GroupServicePricingConfigModal));
