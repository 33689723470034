import React from 'react';

import { ActionIcon } from '@good/ui/core';
import { Globe } from '@good/icons';
import { LocalesMenu } from './locales-menu';

export const LocalesButton = () => {
  return (
    <LocalesMenu>
      <ActionIcon variant='white' color='gray.8' size={36} fz={20} aria-label='locales'>
        <Globe />
      </ActionIcon>
    </LocalesMenu>
  );
};
