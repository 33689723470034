import { notification } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import CommonUtils from 'utilities/common-utils';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'src/stores/rematch/root-store';
import { BookingStatus, ServiceRecurringType, SleepoverTimeSlotErrorType, SleepoverType } from 'utilities/enum-utils';
import SleepoverRecurringStep from './SleepoverRecurringStep';
import SleepoverTypeStep from './SleepoverTypeStep';
import { ISleepoverTimeSlot } from 'src/interfaces/booking-interfaces';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data) => void;
  recurringType: string;
  bookingTimezone: string;
  bookStartDate: Date;
  bookEndDate: Date;
  sleepoverTimeSlots: ISleepoverTimeSlot[];
  sleepoverType: SleepoverType;
  isCreatingBooking?: boolean;
  selectedBookingItem?: any;
}

const SleepoverShiftBookingModal = ({
  isOpen,
  onClose,
  onSave,
  recurringType,
  bookingTimezone,
  bookStartDate,
  bookEndDate,
  sleepoverTimeSlots,
  sleepoverType,
  isCreatingBooking = true,
  selectedBookingItem,
}: IProps) => {
  const dispatch = useDispatch<IRootDispatch>();
  const [step, setStep] = useState(1);
  const [type, setType] = useState(SleepoverType.NONE);
  const [timeSlots, setTimeSlots] = useState([]);
  const [editRecurringMode, setEditRecurringMode] = useState(1);
  const [numberOfBookings, setNumberOfBookings] = useState(1);
  const [sleepoverTimeSlotsRecurring, setSleepoverTimeSlotsRecurring] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const showRecurringOption: boolean = useMemo(
    (): boolean =>
      _.includes(
        [BookingStatus.PENDING, BookingStatus.ACCEPTED, BookingStatus.CONFIRMED],
        selectedBookingItem?.status,
      ) &&
      selectedBookingItem?.numberOfBookingLeft > 0 &&
      recurringType === ServiceRecurringType.RECURRING &&
      !isCreatingBooking,
    [selectedBookingItem, isCreatingBooking, recurringType],
  );

  const isFinalStep = step === (showRecurringOption ? 2 : 1);

  let isSaveButtonDisabled = timeSlots.some(({ errorType }) => !!errorType);

  const _onClose = () => {
    onClose();
    _clearData();
  };

  const _onChangeSleepoverType = (e) => {
    setType(e.target.value);
  };

  const _onChangeEditRecurringMode = (e) => {
    setEditRecurringMode(e.target.value);
  };

  const _onChangeNumberOfBookings = (e) => {
    setNumberOfBookings(e);
  };

  const _onAddTimeSlot = () => {
    const preTimeSlot = timeSlots[timeSlots.length - 1];
    const startDateTime = moment.tz(preTimeSlot.endDateTime, bookingTimezone).add(1, 'hour');
    const endDateTime = moment.tz(startDateTime, bookingTimezone).add(1, 'hour');
    const newTimeSlots = _.cloneDeep([...timeSlots, { startDateTime, endDateTime, errorType: null }]);
    _validateOverlapTimeSlots(newTimeSlots);
    _validateWithBookingDate(newTimeSlots);
    setTimeSlots(newTimeSlots);
  };

  const _onDeleteTimeSlot = (index) => () => {
    const newTimeSlots = _.cloneDeep([...timeSlots]);
    newTimeSlots.splice(index, 1);
    _validateOverlapTimeSlots(newTimeSlots);
    _validateWithBookingDate(newTimeSlots);
    setTimeSlots(newTimeSlots);
  };

  const _onChangeDateTime = (index, key) => (datetime) => {
    const newTimeSlots = _.cloneDeep([...timeSlots]);
    newTimeSlots[index][key] = CommonUtils.formatCeilingDateTime(datetime);

    if (
      moment
        .tz(newTimeSlots[index].startDateTime, bookingTimezone)
        .isSameOrAfter(moment.tz(newTimeSlots[index].endDateTime, bookingTimezone))
    ) {
      newTimeSlots[index].endDateTime = moment.tz(newTimeSlots[index].startDateTime, bookingTimezone).add(1, 'hour');
    }

    _validateOverlapTimeSlots(newTimeSlots);
    _validateWithBookingDate(newTimeSlots);
    setTimeSlots(newTimeSlots);
  };

  const _verifyRecurrence = async (data: {
    sleepoverType: SleepoverType;
    sleepoverTimeSlots: ISleepoverTimeSlot[];
  }) => {
    const payload = {
      serviceId: selectedBookingItem.serviceId,
      startDateTime: selectedBookingItem.startDateTime,
      endDateTime: selectedBookingItem.endDateTime,
      recurringPattern: selectedBookingItem.recurringPattern.recurringPattern,
      recurringTo: selectedBookingItem.recurringPattern.recurringTo,
      serviceDirection: selectedBookingItem.serviceDirection,
      timezone: selectedBookingItem.timezone,
      address: selectedBookingItem.address
        ? {
            ...selectedBookingItem.address,
            geoLat: Number(selectedBookingItem.address.geoLat),
            geoLng: Number(selectedBookingItem.address.geoLng),
          }
        : null,
      userId: selectedBookingItem.bookerUserId,
      sleepoverType: data.sleepoverType,
      sleepoverTimeSlots: data.sleepoverTimeSlots,
    };

    try {
      const result = await dispatch.bookingsStore.doCheckRecurringPattern(payload);
      if (result.data) {
        setSleepoverTimeSlotsRecurring(result.data.sleepoverTimeSlotList);
        return result.data;
      }
    } catch (err) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  const _onNext = async () => {
    setIsLoading(true);

    const result = await _verifyRecurrence({
      sleepoverTimeSlots: type === SleepoverType.PARTIAL ? timeSlots : [],
      sleepoverType: type,
    });
    setIsLoading(false);
    if (result) {
      setStep(step + 1);
      return;
    }

    _onClose();
  };

  const _onSave = async () => {
    const payload = {
      sleepoverType: type,
      editRecurringMode,
      numberOfBookings: numberOfBookings,
      sleepoverTimeSlots: [],
    };

    setIsLoading(true);
    if (showRecurringOption) {
      payload.sleepoverTimeSlots = sleepoverTimeSlotsRecurring ? sleepoverTimeSlotsRecurring : [];

      await onSave(payload);
    } else {
      const sleepoverTimeSlots = timeSlots.map(({ startDateTime, endDateTime, ...rest }) => ({
        startDateTime: moment.tz(startDateTime, bookingTimezone).toDate(),
        endDateTime: moment.tz(endDateTime, bookingTimezone).toDate(),
        ...rest,
      }));
      payload.sleepoverTimeSlots =
        type !== SleepoverType.PARTIAL ? [] : !isCreatingBooking ? [sleepoverTimeSlots] : sleepoverTimeSlots;

      await onSave(payload);
    }

    setIsLoading(false);
    _onClose();
  };

  const _validateWithBookingDate = (newTimeSlots) => {
    _.forEach(newTimeSlots, (newTimeSlot) => {
      if (
        moment.tz(newTimeSlot.startDateTime, bookingTimezone).isBefore(moment.tz(bookStartDate, bookingTimezone)) ||
        moment.tz(newTimeSlot.endDateTime, bookingTimezone).isAfter(moment.tz(bookEndDate, bookingTimezone))
      ) {
        newTimeSlot.errorType =
          selectedBookingItem?.status === BookingStatus.COMPLETED
            ? SleepoverTimeSlotErrorType.OUT_OF_CHECKIN_CHECKOUT_TIME
            : SleepoverTimeSlotErrorType.OUT_OF_BOOKING_TIME;
      } else {
        newTimeSlot.errorType = _.includes(
          [SleepoverTimeSlotErrorType.OUT_OF_CHECKIN_CHECKOUT_TIME, SleepoverTimeSlotErrorType.OUT_OF_BOOKING_TIME],
          newTimeSlot.errorType,
        )
          ? null
          : newTimeSlot.errorType;
      }
    });
  };

  const _validateOverlapTimeSlots = (newTimeSlots) => {
    newTimeSlots[0].errorType = null;

    if (newTimeSlots.length > 1) {
      for (let preIndex = newTimeSlots.length - 1; preIndex >= 1; preIndex--) {
        for (let index = 0; index < preIndex; index++) {
          if (
            !(
              moment
                .tz(newTimeSlots[preIndex].endDateTime, bookingTimezone)
                .isBefore(moment.tz(newTimeSlots[index].startDateTime, bookingTimezone)) ||
              moment
                .tz(newTimeSlots[preIndex].startDateTime, bookingTimezone)
                .isAfter(moment.tz(newTimeSlots[index].endDateTime, bookingTimezone))
            )
          ) {
            newTimeSlots[preIndex].errorType = SleepoverTimeSlotErrorType.OVERLAP;
            break;
          }
          newTimeSlots[preIndex].errorType = null;
        }
      }
    }
  };

  const _clearData = () => {
    setStep(1);
    setType(SleepoverType.NONE);
    setTimeSlots([]);
    setEditRecurringMode(1);
    setNumberOfBookings(1);
  };

  useEffect(() => {
    if (type === SleepoverType.PARTIAL && !timeSlots.length) {
      setTimeSlots([
        {
          startDateTime: bookStartDate,
          endDateTime: bookEndDate,
          errorType: null,
        },
      ]);
    }

    if ((type === SleepoverType.ENTIRE || type === SleepoverType.NONE) && timeSlots.length > 0) {
      setTimeSlots([]);
    }
  }, [type, timeSlots, bookStartDate, bookEndDate]);

  useEffect(() => {
    if (isOpen) {
      setType(sleepoverType);
      setTimeSlots(sleepoverTimeSlots);
    }
  }, [isOpen, sleepoverTimeSlots, sleepoverType]);

  return (
    <ActionModal
      title={step === 1 ? 'Enter sleepover details' : 'Recurring options'}
      isOpen={isOpen}
      onClose={_onClose}
      width="x-large"
      verticalAlignment="center"
      enforceFocus={false}
    >
      {step === 1 && (
        <SleepoverTypeStep
          bookEndDate={bookEndDate}
          bookStartDate={bookStartDate}
          bookingTimezone={bookingTimezone}
          sleepoverType={type}
          timeSlots={timeSlots}
          onAddTimeSlot={_onAddTimeSlot}
          onChangeDateTime={_onChangeDateTime}
          onChangeSleepoverType={_onChangeSleepoverType}
          onDeleteTimeSlot={_onDeleteTimeSlot}
        />
      )}

      {step === 2 && (
        <SleepoverRecurringStep
          editRecurringMode={editRecurringMode}
          onChangeEditRecurringMode={_onChangeEditRecurringMode}
          onChangeNumberOfBookings={_onChangeNumberOfBookings}
          numberOfBookings={numberOfBookings}
          numberOfBookingLeft={selectedBookingItem.numberOfBookingLeft}
        />
      )}

      <div className="flex justify-end mt-large">
        <GhostButton onClick={_onClose} size="large">
          Cancel
        </GhostButton>
        <PrimaryButton
          onClick={isFinalStep ? _onSave : _onNext}
          size="large"
          className="ml-medium"
          disabled={isSaveButtonDisabled}
          loading={isLoading}
        >
          {isFinalStep ? 'Save' : 'Next'}
        </PrimaryButton>
      </div>
    </ActionModal>
  );
};

export default SleepoverShiftBookingModal;
