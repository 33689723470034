import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Replay = forwardRef(function Replay(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.493.435a.75.75 0 0 1 .073 1.058l-1.57 1.801A11.3 11.3 0 0 1 12 3.25c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 18.385 2.25 13c0-2.003.605-3.868 1.642-5.417a.75.75 0 0 1 1.247.834A8.25 8.25 0 1 0 12 4.75c-.418 0-.82.026-1.207.074l2.15 1.57a.75.75 0 1 1-.885 1.212l-3.5-2.557a.75.75 0 0 1-.123-1.098l3-3.444a.75.75 0 0 1 1.058-.072Z"
        fill="currentColor"
      />
    </Icon>
  );
});
