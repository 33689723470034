/* eslint-disable */
import {
  IGroupServiceCustomerToSchedule,
  IGroupServiceDetail,
  IGroupServicePayment,
  IGroupServiceSchedule,
  IGroupServiceSession,
  IGroupServiceSettings,
  IGroupServiceTeamMember,
  IServiceDepartmentList,
  IServiceDetail,
  IServiceDetailsItem,
  IServiceListItem,
  IServiceListItemLite,
  IServiceTeamMember,
  IUpdateGroupServiceDetail,
  IVCPItems,
} from 'interfaces/service-interfaces';
import _ from 'lodash';
import apiClient from 'utilities/api-client';
import { GroupServiceSessionStatus, ServicePublishStatus, ServiceType } from 'utilities/enum-utils';
import { events } from 'integrations/appcues';
import { IRootState } from '../root-store';

interface IServiceStoreState {
  services?: IServiceListItem[];
  servicesLite?: IServiceListItemLite[];
  filteredServices?: IServiceListItem[];
  selectedService?: IServiceDetailsItem;
  teamList: IServiceTeamMember[];
  bookSelectedService: IServiceDetailsItem;
  newService: IServiceDetail;
  serviceDepartmentList: IServiceDepartmentList[];
  serviceDepartmentListLite: IServiceDepartmentList[];
  archivedServiceDepartmentList: any;
  servicesFilter: any[];
  vcpItems: IVCPItems[];
  groupServiceSessions: IGroupServiceSession[];
  groupServiceSchedules: IGroupServiceSchedule[];
  groupServiceSessionFilter: any[];
  selectedServiceSettings: IGroupServiceSettings;
  selectedServiceDetail: IGroupServiceDetail;
  newGroupServiceSchedule: IGroupServiceSchedule;
  groupServicePayment: IGroupServicePayment;
  customerToSchedule: IGroupServiceCustomerToSchedule;
  groupServiceWorkerList: IGroupServiceTeamMember[];
  filteredGroupServiceWorkerList: IGroupServiceTeamMember[];
  groupBookingReloadSessionList: boolean;
}

const initialState: IServiceStoreState = {
  services: [],
  servicesLite: [],
  filteredServices: [],
  selectedService: null,
  teamList: [],
  bookSelectedService: null,
  newService: null,
  serviceDepartmentList: null,
  serviceDepartmentListLite: null,
  archivedServiceDepartmentList: [],
  servicesFilter: [],
  vcpItems: [],
  groupServiceSchedules: [],
  groupServiceSessions: [],
  newGroupServiceSchedule: null,
  groupServicePayment: null,
  groupServiceWorkerList: null,
  filteredGroupServiceWorkerList: null,
  groupServiceSessionFilter: [],
  selectedServiceSettings: null,
  selectedServiceDetail: null,
  customerToSchedule: null,
  groupBookingReloadSessionList: false,
};

const matchNameWithStrings = (firstName: string, lastName: string, texts: string[]) => {
  for (const t of texts) {
    if (_.includes(firstName.toLowerCase(), t.toLowerCase()) || _.includes(lastName.toLowerCase(), t.toLowerCase())) {
      return true;
    }
  }
  return false;
};

const servicesStore = {
  state: { ...initialState },
  reducers: {
    // increment: (state, payload) => ({ ...state, currentCounter: state.currentCounter + payload })
    setServices: (state, payload) => ({ ...state, services: payload }),
    setServicesLite: (state, payload) => ({ ...state, servicesLite: payload }),
    setFilteredServices: (state, payload) => ({ ...state, filteredServices: payload }),
    setSelectedService: (state, payload) => ({ ...state, selectedService: payload }),
    setTeamList: (state, payload) => ({ ...state, teamList: payload }),
    setBookSelectedService: (state, payload) => ({ ...state, bookSelectedService: payload }),
    setNewService: (state, payload) => ({ ...state, newService: payload }),
    setGroupServiceSchedules: (state, payload) => ({ ...state, groupServiceSchedules: payload }),
    setGroupServiceSessions: (state, payload) => ({ ...state, groupServiceSessions: payload }),
    setNewGroupServiceSchedule: (state, payload) => ({ ...state, newGroupServiceSchedule: payload }),
    setCustomerToSchedule: (state, payload) => ({ ...state, customerToSchedule: payload }),
    setSelectedCreateService: (state, payload) => ({
      ...state,
      selectedService: payload,
      bookSelectedService: payload,
    }),
    setServiceDepartmentList: (state, payload) => ({ ...state, serviceDepartmentList: payload }),
    setServiceDepartmentListLite: (state, payload) => ({ ...state, serviceDepartmentListLite: payload }),
    setArchivedServiceDepartmentList: (state, payload) => ({
      ...state,
      selectedService: payload,
      archivedServiceDepartmentList: payload,
    }),
    setServicesFilter: (state, payload) => ({ ...state, servicesFilter: payload }),
    updateServiceStatus: (state, payload) => {
      const updatedServiceList = _.map(state.filteredServices, (service) => {
        if (service.serviceId === payload.serviceId) {
          return { ...service, status: ServicePublishStatus.ARCHIVED };
        } else {
          return { ...service };
        }
      });
      return {
        ...state,
        selectedService: {
          ...state.selectedService,
          status: ServicePublishStatus.ARCHIVED,
          updatedOn: new Date(),
        },
        filteredServices: updatedServiceList,
      };
    },
    setVcpItems: (state, payload) => ({ ...state, vcpItems: payload }),
    setGroupServicePayment: (state, payload) => ({ ...state, groupServicePayment: payload }),
    updateGroupServicePayment: (state, payload) => {
      const newGroupServicePayment = {
        ...state.groupServicePayment,
        ...payload,
      };

      return { ...state, groupServicePayment: newGroupServicePayment };
    },
    setGroupServiceWorkerList: (state, payload) => ({ ...state, groupServiceWorkerList: payload }),
    setFilteredGroupServiceWorkerList: (state, payload) => ({ ...state, filteredGroupServiceWorkerList: payload }),
    addWorkerToRoster: (state, payload) => {
      let newFilteredWorkerList = _.cloneDeep(state.filteredGroupServiceWorkerList);
      let newWorkerList = _.cloneDeep(state.groupServiceWorkerList);

      _.map(payload.selectedMembers, (member) => {
        const worker = {
          supportWorkerId: member.supportWorkerId,
          teamMemberUserId: member.userId,
          teamMemberFirstName: member.firstName,
          teamMemberLastName: member.lastName,
          teamMemberAvatarUrl: member.attachmentUrl,
          upcomingShiftSlotCount: 0,
          completedShiftSlotCount: 0,
          incompletedShiftSlotCount: 0,
        };

        newWorkerList.push(worker);

        // Only update the filtered worker list when the user is not searching in the listing panel,
        // or the worker name matches the search text.
        const searchTexts = payload.searchText ? payload.searchText.split(/\s+/) : null;
        if (!searchTexts || matchNameWithStrings(worker.teamMemberFirstName, worker.teamMemberLastName, searchTexts)) {
          newFilteredWorkerList.push(worker);
        }
      });

      return { ...state, filteredGroupServiceWorkerList: newFilteredWorkerList, groupServiceWorkerList: newWorkerList };
    },
    removeWorkerFromRoster: (state, payload) => {
      const newWorkerList = _.filter(
        state.groupServiceWorkerList,
        (member) => member.teamMemberUserId !== payload.teamMemberUserId,
      );
      const newFilteredWorkerList = _.filter(
        state.filteredGroupServiceWorkerList,
        (member) => member.teamMemberUserId !== payload.teamMemberUserId,
      );

      return { ...state, groupServiceWorkerList: newWorkerList, filteredGroupServiceWorkerList: newFilteredWorkerList };
    },
    setGroupServiceSessionFilter: (state, payload) => ({ ...state, groupServiceSessionFilter: payload }),
    setGroupServiceSettings: (state, payload) => ({ ...state, selectedServiceSettings: payload }),
    updateGroupServiceSettings: (state, payload) => {
      return { ...state, selectedServiceSettings: { ...state.selectedServiceSettings, ...payload } };
    },
    updateGroupServiceDetails: (state, payload) => {
      return { ...state, selectedServiceDetail: { ...state.selectedServiceDetail, ...payload } };
    },
    setGroupBookingReloadSessionList: (state, payload) => {
      return { ...state, groupBookingReloadSessionList: payload.reload };
    },
    updateGroupServiceSession: (state, payload) => {
      return {
        ...state,
        groupServiceSessions: _.filter(
          state.groupServiceSessions,
          (session) => !_.find(payload.attendanceIds, (attendanceId) => session.attendanceId === attendanceId),
        ),
      };
    },
  },
  effects: (dispatch) => ({
    async doFetchServices(payload, rootState) {
      try {
        // console.log('fetching services...');
        const response = await apiClient.get(`/api/portal/services/list`);
        if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
          const serviceList = response.data;
          // Mock data
          // console.log(response);

          dispatch.servicesStore.setServices(serviceList);
          dispatch.servicesStore.setFilteredServices(serviceList);

          // console.log('services fetched.');
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
      // dispatch.userStore.increment(payload);
    },

    async doSearchService(payload, rootState) {
      try {
        const response = await apiClient.post(`/api/portal/services/list`, payload);
        let newServices;
        let newFilteredService;
        if (payload.page === 1) {
          newServices = response.data;
          newFilteredService = response.data;
        } else {
          newServices = [...rootState.servicesStore.services, ...response.data];
          newFilteredService = [...rootState.servicesStore.filteredServices, ...response.data];
        }
        dispatch.servicesStore.setServices(newServices);
        dispatch.servicesStore.setFilteredServices(newFilteredService);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doSearchGroupServiceSession(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/list`;

      try {
        const response = await apiClient.post(endpoint, payload);

        let newGroupSessions =
          payload.page === 1
            ? response.data.sessions
            : [...rootState.servicesStore.groupServiceSessions, ...response.data.sessions];

        dispatch.servicesStore.setGroupServiceSessions(newGroupSessions);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doSearchGroupServiceSchedules(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/schedules/list`;

      try {
        const response = await apiClient.post(endpoint, payload);

        let newGroupServiceSchedule =
          payload.page === 1
            ? response.data.schedules
            : [...rootState.servicesStore.groupServiceSchedules, ...response.data.schedules];

        dispatch.servicesStore.setGroupServiceSchedules(newGroupServiceSchedule);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRemoveGroupServiceSchedule(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/schedules/${payload.serviceScheduleId}`;

      try {
        await apiClient.delete(endpoint, payload);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchServicesLite(payload, rootState) {
      try {
        const response = await apiClient.post(`/api/portal/services/lite-list`, payload);
        dispatch.servicesStore.setServicesLite(response ? response.data : []);
      } catch (e) {
        console.log(e);
        throw e;
      }
      // dispatch.userStore.increment(payload);
    },

    async doCreateNewService(payload, rootState: IRootState) {
      const endPoint = `api/portal/services`;

      try {
        await apiClient.post(endPoint, payload);
        const { userId } = rootState.authStore.portalUser;

        const eventData = {
          userId: rootState.authStore?.portalUser?.userId,
          serviceId: payload.serviceId,
          serviceType: payload.serviceType,
          serviceName: payload.serviceName,
          servicePublishDate: payload.servicePublishDate,
          serviceDescription: payload.serviceDescription,
          serviceProviderId: payload.serviceProviderId,
        };

        if (eventData.serviceType === ServiceType.COORDINATION)
          events.trackCreateNewSupportCoordinationService(userId, eventData);
        else if (eventData.serviceType === ServiceType.INDIVIDUAL)
          events.trackCreateNewStandardService(userId, eventData);
      } catch (e) {
        throw e;
      }
    },

    async doFetchSingleService(payload, rootStore) {
      try {
        const { serviceId } = payload;

        const response = await apiClient.get(`/api/portal/services/${serviceId}`);
        const service = response.data;

        dispatch.servicesStore.setSelectedService(service);
        dispatch.servicesStore.setBookSelectedService(service);
        return service;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doUpdateIndividualServiceTime(payload, rootStore) {
      try {
        const selectedServiceId = rootStore.servicesStore.selectedService.serviceId;
        const endpoint = `/api/portal/services/${selectedServiceId}/individual/time`;
        const result = await apiClient.put(endpoint, payload);
        // console.log(result);

        await dispatch.servicesStore.doFetchSingleService({ serviceId: selectedServiceId });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doUpdateIndividualServiceDetail(payload, rootStore) {
      try {
        const selectedServiceId = rootStore.servicesStore.selectedService.serviceId;
        const endpoint = `/api/portal/services/${selectedServiceId}/individual/details`;
        const result = await apiClient.put(endpoint, payload);
        await dispatch.servicesStore.doFetchSingleService({ serviceId: selectedServiceId });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doUpdateServicePricing(payload, rootStore) {
      try {
        const selectedServiceId = rootStore.servicesStore.selectedService.serviceId;
        const endpoint = `/api/portal/services/${selectedServiceId}/pricing`;
        const result = await apiClient.put(endpoint, payload);
        await dispatch.servicesStore.doFetchSingleService({ serviceId: selectedServiceId });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doUpdateServicePayment(payload, rootStore) {
      try {
        const selectedServiceId = rootStore.servicesStore.selectedService.serviceId;
        const endpoint = `/api/portal/services/${selectedServiceId}/payment`;
        const result = await apiClient.put(endpoint, payload);
        await dispatch.servicesStore.doFetchSingleService({ serviceId: selectedServiceId });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doAddWorkerIntoRoster(payload, rootStore) {
      try {
        const { serviceId, supportWorkerIds } = payload;
        const data = { serviceId, supportWorkerIds };
        const endPoint = `/api/portal/services/worker`;
        const result = await apiClient.post(endPoint, data);
        await dispatch.servicesStore.doFetchSingleService({ serviceId });

        events.trackAddTeamMemberToServiceRoster({
          serviceId,
          supportWorkerIds,
          data: payload,
        });
      } catch (e) {
        throw e;
      }
    },

    async doRemoveWorkerFromRoster(payload, rootState) {
      try {
        const { serviceId, supportWorkerIds } = payload;
        const data = {
          serviceId,
          supportWorkerIds,
        };
        const endPoint = `/api/portal/services/worker`;
        await apiClient.delete(endPoint, data);
        await dispatch.servicesStore.doFetchSingleService({ serviceId });
      } catch (e) {
        throw e;
      }
    },

    async doFetchTeamListForServiceContact(payload, rootState) {
      try {
        const endPoint = `/api/portal/services/list/team`;
        const data = await apiClient.get(endPoint);
        if (!_.isEmpty(data) && !_.isEmpty(data.data)) {
          dispatch.servicesStore.setTeamList(data.data);
          // console.log(data.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async doAddKeyContactIntoService(payload, rootState) {
      try {
        const selectedServiceId = rootState.servicesStore.selectedService.serviceId;
        const endPoint = `/api/portal/services/${selectedServiceId}/contact`;
        const data = await apiClient.post(endPoint, payload);
        if (!_.isEmpty(data) && !_.isEmpty(data.data)) {
          const service = rootState.servicesStore.selectedService;
          service.contacts = data.data;
          dispatch.servicesStore.setSelectedService(service);
        }
      } catch (e) {
        throw e;
      }
    },

    async doRemoveContactFromService(payload, rootState) {
      try {
        const selectedServiceId = rootState.servicesStore.selectedService.serviceId;
        const endPoint = `/api/portal/services/${selectedServiceId}/contact`;
        const data = await apiClient.delete(endPoint, payload);
        if (!_.isEmpty(data) && !_.isEmpty(data.data)) {
          // console.log(data);
          const service = rootState.servicesStore.selectedService;
          _.remove(service.contacts, (contact: any) => contact.serviceKeyContactId === data.data.serviceKeyContactId);
          dispatch.servicesStore.setSelectedService(service);
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateContactForService(payload, rootState) {
      try {
        const selectedServiceId = rootState.servicesStore.selectedService.serviceId;
        const endPoint = `/api/portal/services/${selectedServiceId}/contact`;
        const data = await apiClient.put(endPoint, payload);
        if (!_.isEmpty(data) && !_.isEmpty(data.data)) {
          const service = rootState.servicesStore.selectedService;
          service.contacts = data.data;
          dispatch.servicesStore.setSelectedService(service);
        }
      } catch (e) {
        throw e;
      }
    },

    async doAddManagerIntoService(payload, rootState) {
      try {
        const selectedServiceId = rootState.servicesStore.selectedService.serviceId;
        const endPoint = `/api/portal/services/${selectedServiceId}/manager`;
        const data = await apiClient.post(endPoint, payload);
        if (!_.isEmpty(data) && !_.isEmpty(data.data)) {
          const service = rootState.servicesStore.selectedService;
          service.managers = data.data;
          dispatch.servicesStore.setSelectedService(service);
        }
      } catch (e) {
        throw e;
      }
    },

    async doRemoveManagerFromService(payload, rootState) {
      try {
        const selectedServiceId = rootState.servicesStore.selectedService.serviceId;
        const endPoint = `/api/portal/services/${selectedServiceId}/manager`;
        const data = await apiClient.delete(endPoint, payload);
        const service = rootState.servicesStore.selectedService;
        service.managers = data.data;
        dispatch.servicesStore.setSelectedService(service);
      } catch (e) {
        throw e;
      }
    },

    async doPublishService(payload) {
      const endPoint = `api/portal/services/${payload.serviceId}/published`;

      try {
        await apiClient.put(endPoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doUnpublishService(payload) {
      const endPoint = `api/portal/services/${payload.serviceId}/unpublished`;

      // TODO : Add option to publish right now.

      try {
        await apiClient.put(endPoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doPublishGroupService(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/published`;
      try {
        await apiClient.put(endPoint, payload);
        dispatch.servicesStore.updateGroupServiceStatus({
          serviceId: payload.serviceId,
          publishStatus: ServicePublishStatus.PUBLISHED,
        });
      } catch (e) {
        throw e;
      }
    },

    async doUnpublishGroupService(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/unpublished`;
      try {
        await apiClient.put(endPoint, payload);
        dispatch.servicesStore.updateGroupServiceStatus({
          serviceId: payload.serviceId,
          publishStatus: ServicePublishStatus.UNPUBLISHED,
        });
      } catch (e) {
        throw e;
      }
    },

    async doFetchServiceDepartments(payload, rootState) {
      try {
        const response = await apiClient.post(`/api/portal/service-departments/list`, payload);
        if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
          let serviceDepartments = response.data;
          if (payload.page > 1) {
            serviceDepartments = rootState.servicesStore.serviceDepartmentList.concat(serviceDepartments);
          }
          dispatch.servicesStore.setServiceDepartmentList(serviceDepartments);
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchServiceDepartmentsLite(payload, rootState) {
      try {
        const response = await apiClient.post(`/api/portal/service-departments/lite-list`, payload);
        if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
          let serviceDepartments = response.data;
          if (payload.page > 1) {
            serviceDepartments = rootState.servicesStore.serviceDepartmentList.concat(serviceDepartments);
          }
          dispatch.servicesStore.setServiceDepartmentListLite(serviceDepartments);
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchArchivedServiceDepartments(payload, rootState) {
      try {
        const response = await apiClient.post(`/api/portal/service-departments/list`, { ...payload, isArchived: true });
        let archivedServiceDepartmentList = response.data;
        if (payload.page > 1) {
          archivedServiceDepartmentList =
            rootState.servicesStore.archivedServiceDepartmentList.concat(archivedServiceDepartmentList);
        }
        dispatch.servicesStore.setArchivedServiceDepartmentList(archivedServiceDepartmentList);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchServiceDepartmentDetails(payload, rootState) {
      try {
        return await apiClient.get(`/api/portal/service-departments/${payload.serviceDepartmentId}`);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchServiceDepartmentDetailsLite(payload, rootState) {
      try {
        return await apiClient.get(`/api/portal/service-departments/lite-list/${payload.serviceDepartmentId}/services`);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCreateServiceDepartment(payload, rootState) {
      try {
        await apiClient.post(`/api/portal/service-departments`, payload);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditServiceDepartment(payload, rootState) {
      try {
        await apiClient.put(`/api/portal/service-departments/${payload.serviceDepartmentId}`, payload);
        let serviceDepartmentList = rootState.servicesStore.serviceDepartmentList;
        serviceDepartmentList = _.map(serviceDepartmentList, (serviceDepartment) => {
          if (serviceDepartment.serviceDepartmentId === payload.serviceDepartmentId) {
            return { ...serviceDepartment, serviceDepartmentName: payload.serviceDepartmentName };
          } else {
            return { ...serviceDepartment };
          }
        });
        dispatch.servicesStore.setServiceDepartmentList(serviceDepartmentList);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doArchiveServiceDepartment(payload, rootState) {
      try {
        return await apiClient.delete(`/api/portal/service-departments/${payload.serviceDepartmentId}`, payload);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doArchiveService(payload, rootState) {
      try {
        await apiClient.delete(`/api/portal/services/${payload.serviceId}`, payload);
        dispatch.servicesStore.updateServiceStatus({ serviceId: payload.serviceId });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doGetVCPItems(payload, rootState) {
      try {
        const result = await apiClient.post('/api/portal/billing/support-items/vcp', payload);
        !payload.isReturnOnly && dispatch.servicesStore.setVcpItems(result.data);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doUpdateServicePaymentSources(payload, rootState) {
      try {
        const result = await apiClient.put(
          `/api/portal/services/${rootState.servicesStore.selectedService.serviceId}/payment-sources`,
          payload,
        );
        await dispatch.servicesStore.doFetchSingleService({
          serviceId: rootState.servicesStore.selectedService.serviceId,
        });

        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doGetGroupServiceSettings(payload: {serviceId: string}, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/settings`;

      try {
        const response = await apiClient.get(endpoint);
        //console.log('Get response: ', response);
        dispatch.servicesStore.setGroupServiceSettings(response.data);
      } catch (e) {
        throw e;
      }
    },
    // GROUP SERVICES

    async doFetchGroupServiceSessions(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/customers/${payload.customerUserId}/sessions/check-availability`;
      try {
        const result = await apiClient.post(endPoint, payload);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupServiceSchedules(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/schedules/list`;
      try {
        const result = await apiClient.post(endPoint, payload);
        dispatch.servicesStore.setGroupServiceSchedules(result.data && result.data.schedules);
      } catch (e) {
        throw e;
      }
    },

    async doCreateNewGroupService(payload, rootState) {
      const endpoint = `api/portal/group-services/create`;

      try {
        await apiClient.post(endpoint, payload);
        // Appcues
        events.trackCreateNewGroupService({
          serviceId: payload.serviceId,
          serviceType: payload.serviceType,
          serviceName: payload.serviceName,
          servicePublishDate: payload.servicePublishDate,
          serviceDescription: payload.serviceDescription,
          serviceProviderId: payload.serviceProviderId,
        });
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupServiceDetail(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/details`;

      try {
        const response = await apiClient.get(endpoint);

        const groupServiceDetails = {
          ...response.data,
          bannerUrl: {
            attachmentUrl: response.data.bannerUrl,
            attachmentPath: response.data.bannerPath,
          },
        };

        dispatch.servicesStore.updateGroupServiceDetails(groupServiceDetails);
      } catch (e) {
        throw e;
      }
    },

    // GROUP SERVICES
    async doCreateNewSession(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/schedules`;
      try {
        const result = await apiClient.post(endPoint, payload);
        dispatch.servicesStore.setGroupBookingReloadSessionList({ reload: true });
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doUpdateGroupServiceSettings(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/settings`;

      try {
        await apiClient.put(endpoint, payload);
        dispatch.servicesStore.updateGroupServiceSettings(payload);
      } catch (e) {
        throw e;
      }
    },

    async doUpdateGroupServiceDetail(payload: IUpdateGroupServiceDetail) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/details`;
      try {
        await apiClient.put(endpoint, payload);

        let updatedSelectedGroupServicePayload = null;

        if ('bannerUrl' in payload && 'bannerPath' in payload) {
          const bannerUrl = { attachmentUrl: payload.bannerUrl, attachmentPath: payload.bannerPath };
          dispatch.servicesStore.updateGroupServiceDetails({ ...payload, bannerUrl });
          dispatch.groupServiceStore.updateSelectedGroupServiceDetail({ bannerUrl: payload.bannerUrl });
        } else {
          if (payload.serviceName) {
            updatedSelectedGroupServicePayload = { serviceName: payload.serviceName };
          } else if (payload.serviceDepartmentId) {
            updatedSelectedGroupServicePayload = {
              serviceDepartmentId: payload.serviceDepartmentId,
              serviceDepartmentName: payload.serviceDepartmentName,
            };
          } else if (payload.groupServiceClassification) {
            updatedSelectedGroupServicePayload = {
              groupServiceClassification: payload.groupServiceClassification,
            };
          }

          if (updatedSelectedGroupServicePayload) {
            dispatch.groupServiceStore.updateSelectedGroupServiceDetail(updatedSelectedGroupServicePayload);
          }

          dispatch.servicesStore.updateGroupServiceDetails(payload);
        }
      } catch (e) {
        throw e;
      }
    },

    async doGeneratedSessions(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/generate-session`;
      try {
        const result = await apiClient.post(endPoint, payload);
        return result.data ? result.data.sessions : [];
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupServicePayment(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/payment`;

      try {
        const result = await apiClient.get(endpoint);
        dispatch.servicesStore.setGroupServicePayment(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doAddCustomerToSession(payload, rootState) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/customers/${payload.customerUserId}/sessions`;
      try {
        const response = await apiClient.post(endPoint, { sessions: payload.sessions });

        events.trackCreateBooking({
          serviceType: response.data.serviceType,
          bookingId: response.data?.bookingId,
        });
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupServiceTeamMembers(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/team-members/list`;
      try {
        const response = await apiClient.post(endpoint, payload);
        dispatch.servicesStore.setFilteredGroupServiceWorkerList(response.data.teamMembers);
        dispatch.servicesStore.setGroupServiceWorkerList(response.data.teamMembers);
      } catch (e) {
        throw e;
      }
    },

    async doSearchGroupServiceTeamMembers(payload, rootState) {
      const newFilteredWorkerList = _.filter(rootState.servicesStore.groupServiceWorkerList, (worker) => {
        return matchNameWithStrings(
          worker.teamMemberFirstName,
          worker.teamMemberLastName,
          payload.searchString.split(/\s+/),
        );
      });

      dispatch.servicesStore.setFilteredGroupServiceWorkerList(newFilteredWorkerList);
    },

    async doAddTeamMemberToRoster(payload, rootState) {
      const supportWorkerIds = _.map(payload.selectedMembers, (i) => i.supportWorkerId);
      const newPayload = { serviceId: payload.serviceId, supportWorkerIds };
      const endpoint = `api/portal/group-services/${payload.serviceId}/team-members`;

      try {
        await apiClient.post(endpoint, newPayload);
        dispatch.servicesStore.addWorkerToRoster(payload);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doUpdateGroupServicePayment(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/payment`;

      try {
        const result = await apiClient.put(endpoint, payload);
        dispatch.servicesStore.updateGroupServicePayment(payload);
      } catch (e) {
        throw e;
      }
    },

    async doRemoveTeamMemberFromRoster(payload, rootState) {
      const { serviceId, selectedTeamMember } = payload;
      const { supportWorkerId } = selectedTeamMember;
      const newPayload = { serviceId, supportWorkerIds: [supportWorkerId] };
      const endpoint = `api/portal/group-services/${serviceId}/team-members/${supportWorkerId}`;

      try {
        await apiClient.delete(endpoint, newPayload);
        dispatch.servicesStore.removeWorkerFromRoster(selectedTeamMember);
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupServiceCustomerSessions(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/customers/${payload.customerUserId}/sessions`;

      try {
        const result = await apiClient.get(endpoint);
        dispatch.servicesStore.setGroupServiceSessions(result.data.sessions);
      } catch (e) {
        throw e;
      }
    },

    async doBulkCancelGroupServiceSession(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/cancel`;

      try {
        await apiClient.put(endpoint, payload);

        const newGroupServiceSessions = _.map(rootState.servicesStore.groupServiceSessions, (session) => {
          return {
            ...session,
            sessionStatus: _.includes(payload.serviceDateTimeIds, session.serviceDateTimeId)
              ? GroupServiceSessionStatus.CANCELLED
              : session.sessionStatus,
            isChecked: false,
          };
        });

        dispatch.servicesStore.setGroupServiceSessions(newGroupServiceSessions);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doGetLiteList(payload) {
      const endpoint = 'api/portal/services/lite-list';
      const response = await apiClient.post(endpoint, payload);
      return response.data;
    },
  }),
};

export default servicesStore;
