import React from 'react';
import { Text } from '@good/components';
import { Button, Stack } from '@goodhuman-me/components';
import { PaperPlane } from '@good/illustrations';

export const NoServiceAgreements = (): JSX.Element => {
  return (
    <Stack alignItems="center">
      <Stack gap="$space200" alignItems="center">
        <Text>No service agreements</Text>
        <PaperPlane />
        <Text className="text-center">
          Create your first service agreement<br></br> or folder
        </Text>
        <Button size="small" onClick={undefined}>
          Create
        </Button>
      </Stack>
    </Stack>
  );
};
