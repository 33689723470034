import React, { Component, PureComponent } from 'react';

import { Button, Col, Icon, message, Row, Tag, Timeline, Tooltip } from 'antd';
import { Text, Title } from 'common-components/typography';
import { Colors } from '@blueprintjs/core';
import moment from 'moment-timezone';
import { TimezoneIndicator } from 'common-components/timezone';
import { CheckInLocation } from 'common-components/locations';

interface BookingActivityLogPanelProps {
  bookingHistory;
  onRefresh;
  timezoneData?: any;
  isActivityRecord?: boolean;
}

interface BookingActivityLogPanelState {
  isLoading: boolean;
}

class BookingActivityLogPanel extends Component<BookingActivityLogPanelProps, BookingActivityLogPanelState> {
  state = {
    isLoading: false,
  };

  _onClickRefresh = async () => {
    const { onRefresh } = this.props;
    this.setState({ isLoading: true });
    message.info('Refreshing');
    await onRefresh();
    message.info('Refresh Successful');
    this.setState({ isLoading: false });
  };

  render() {
    const { bookingHistory } = this.props;
    return (
      <div className="">
        <Title level={3} className="mb-none" weight="bold">
          Activity Timeline
        </Title>

        <Text color="secondary" size="large">
          Timeline for the current {this.props.isActivityRecord ? 'activity record' : 'booking'}
        </Text>
        <br />

        <Row type={'flex'} justify={'space-between'} align={'middle'} className={'mb-medium pt-x-large'}>
          <Col span={24}>
            <div className="flex-row align-center justify-between mr-medium">
              <div className="ph-x-small bg-white" style={{ height: '1px' }} />
              <Tag className="text-size-small mr-small">
                {bookingHistory.length} {bookingHistory.length > 1 ? 'Activities' : 'Activity'} found
              </Tag>
              <div className="flex bg-white width-full mr-small" style={{ height: '1px' }} />
              <Button.Group>
                <Tooltip title={'Refresh Activity'}>
                  <Button
                    icon={'reload'}
                    onClick={() => this._onClickRefresh()}
                    disabled={this.state.isLoading}
                    loading={this.state.isLoading}
                  />
                </Tooltip>
              </Button.Group>
            </div>
          </Col>
        </Row>

        {/*<Divider className="divider-medium" />*/}

        <div className="pl-medium pt-medium">
          <Timeline>
            {bookingHistory.map((history, idx) => {
              const actionType = history.requestUserType;
              const { lat, long } = history;

              const iconColor =
                actionType === 'SupportWorker'
                  ? Colors.BLUE4
                  : actionType === 'PortalUser'
                  ? Colors.GREEN5
                  : Colors.GOLD5;

              return (
                <BookingHistoryItem
                  key={idx}
                  historyId={history.historyId}
                  actionType={history.requestUserType}
                  icon={'calendar'}
                  iconColor={iconColor}
                  content={
                    <>
                      <Text>
                        <Text className="text-weight-black">
                          {history.firstName} {history.lastName}
                        </Text>{' '}
                        {history.action}
                      </Text>
                      {lat && long && <CheckInLocation action={history.action} latitude={lat} longitude={long} />}
                    </>
                  }
                  createdOn={history.createdOn}
                  timezoneData={this.props.timezoneData}
                />
              );
            })}
          </Timeline>
        </div>
      </div>
    );
  }
}

export default BookingActivityLogPanel;

interface BookingHistoryItemProps {
  historyId: any;
  icon: any;
  iconColor: string;
  actionType: any;
  content: any;
  createdOn: any;
  timezoneData: any;
}

class BookingHistoryItem extends PureComponent<BookingHistoryItemProps> {
  static defaultProps = {
    iconColor: Colors.GOLD5,
    historyId: 0,
    icon: 'calendar',
    actionType: '',
    content: '',
    createdOn: '',
  };

  render() {
    let { historyId, icon, iconColor, actionType, content, createdOn, timezoneData } = this.props;

    return (
      <Timeline.Item
        key={historyId}
        dot={
          <Icon
            type={icon}
            style={{
              backgroundColor: iconColor,
              padding: '12px',
              color: 'white',
              borderRadius: '4px',
              fontSize: '15px',
            }}
          />
        }
      >
        <div className="pl-medium">
          <div className="flex-row align-center">
            <Text className="mr-small text-weight-bold">
              {actionType === 'PortalUser'
                ? `Workspace Administrator`
                : actionType === 'Customer'
                ? `Customer`
                : actionType === 'SupportWorker'
                ? `Support Worker`
                : `Other`}
            </Text>

            <Text className="text-size-small text-color-secondary">
              {moment.tz(createdOn, timezoneData.displayTimezone).format('h:mm A, ddd Do MMMM YYYY')}
            </Text>
            <div className="ml-small">
              {timezoneData.isTimezoneIndicatorDisplayed && (
                <TimezoneIndicator
                  bordered={true}
                  hasIcon={true}
                  showTzName={false}
                  timezone={timezoneData.bookingTimezone}
                />
              )}
            </div>
          </div>

          <div className="mt-small ml-x-small">{content}</div>
          <br />
        </div>
      </Timeline.Item>
    );
  }
}
