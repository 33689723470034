import React, { createContext, type ReactPropTypes, useCallback, useContext, useMemo, useState } from 'react';

import { I18nProvider } from '@good/components';
import type { ChangeLocaleConfigFunction, CountryCode, LocaleConfigContext } from './types';
import { useInferLocale } from './use-infer-locale';
import { localeConfigs } from './local-configs';
import { useTranslation } from 'react-i18next';
import { useValidatePostcode } from './use-validate-postcode';
import { useValidatePhoneNumber } from './use-validate-phone-number';

const initialLocaleConfigContext: LocaleConfigContext = {
  current: localeConfigs.AU,
  available: [localeConfigs.AU],
  all: Object.values(localeConfigs),
  changeConfig: () => {
    throw new Error('changeConfig not implemented');
  },
  validatePostcode: () => {
    throw new Error('validatePostcode not implemented');
  },
  validatePhoneNumber: () => {
    throw new Error('validatePhoneNumber not implemented');
  },
  tFixed: () => {
    throw new Error('tFixed not implemented');
  },
};

const LocaleConfigContext = createContext<LocaleConfigContext>(initialLocaleConfigContext);

type LocaleConfigProviderProps = {
  /** Provide a default country code; otherwise it will be inferred. To update this you must use changeConfig */
  countryCode?: CountryCode;
  availableCountryCodes?: CountryCode[];
  children: React.ReactNode;
};

export const LocaleConfigProvider = ({
  children,
  countryCode: initialCountryCode,
  availableCountryCodes: initialAvailableCountryCodes,
}: LocaleConfigProviderProps) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState<CountryCode>(
    initialCountryCode ?? initialLocaleConfigContext.current.countryCode,
  );
  const [availableCountryCodes, setAvailableCountryCodes] = useState<CountryCode[]>(
    initialAvailableCountryCodes ?? [initialLocaleConfigContext.current.countryCode],
  );
  const { i18n } = useTranslation();
  const changeConfig: ChangeLocaleConfigFunction = useCallback(
    (opts: { countryCode?: CountryCode; availableCountryCodes?: CountryCode[]; updateGlobalLang?: boolean }) => {
      const { countryCode, availableCountryCodes } = opts;
      if (!countryCode && !availableCountryCodes) {
        return;
      }
      setSelectedCountryCode((prevCountryCode) => {
        const nextCountryCode = countryCode ?? prevCountryCode;

        if (!Object.keys(localeConfigs).includes(nextCountryCode)) {
          // eslint-disable-next-line no-console --- We want to log this but not raise an error
          console.warn(`Invalid country code: ${nextCountryCode}`);
          return prevCountryCode;
        }

        setAvailableCountryCodes((prevAvailable) => {
          return [
            ...(availableCountryCodes ?? prevAvailable).filter((code) => code !== nextCountryCode),
            nextCountryCode,
          ];
        });

        if (opts.updateGlobalLang) {
          void i18n.changeLanguage(localeConfigs[nextCountryCode].lang);
        }

        return nextCountryCode;
      });
    },
    [],
  );
  useInferLocale((cc) => changeConfig({ countryCode: cc, availableCountryCodes: [cc] }), {
    skip: Boolean(initialCountryCode),
  });
  const validatePhoneNumber = useValidatePhoneNumber(selectedCountryCode);
  useInferLocale((cc) => changeConfig({ countryCode: cc, availableCountryCodes: [cc], updateGlobalLang: true }), {
    skip: Boolean(initialCountryCode),
  });
  const tFixed = i18n.getFixedT(localeConfigs[selectedCountryCode].lang);
  const validatePostcode = useValidatePostcode(selectedCountryCode, tFixed);

  const value = useMemo(
    () => ({
      ...initialLocaleConfigContext,
      current: localeConfigs[selectedCountryCode],
      available: availableCountryCodes.map((countryCode) => localeConfigs[countryCode]),
      changeConfig,
      validatePostcode,
      validatePhoneNumber,
      tFixed,
    }),
    [selectedCountryCode, availableCountryCodes, changeConfig, validatePostcode, validatePhoneNumber, tFixed],
  );

  return (
    <I18nProvider locale={value.current.lang}>
      <LocaleConfigContext.Provider value={value}>{children}</LocaleConfigContext.Provider>
    </I18nProvider>
  );
};

export const useLocaleConfig = () => useContext(LocaleConfigContext);

export const withLocaleConfig = <P extends ReactPropTypes>(
  Component: React.ComponentType<P & { localeConfig: LocaleConfigContext }>,
) =>
  function WithLocaleConfig(props: P) {
    const localeConfig = useLocaleConfig();
    return <Component {...props} localeConfig={localeConfig} />;
  };
