import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ActionIcon, Indicator, Text } from '@good/ui/core';
import { Bell } from '@good/icons';
import { type IRootDispatch } from 'stores/rematch/root-store';
import { useNotificationsCount } from './use-notifications-count';

export const NotificationsButton = () => {
  const history = useHistory();
  const notificationsCount = useNotificationsCount();
  const {
    notificationsStore: { doResetNotificationBadgeCount },
  } = useDispatch<IRootDispatch>();

  const onClick = () => {
    void doResetNotificationBadgeCount();
    history.push('/notifications');
  };

  return (
    <Indicator
      offset={6}
      withBorder
      color='red'
      size={24}
      label={
        <Text size='xs' fw={700} px={4}>
          {notificationsCount}
        </Text>
      }
      disabled={!notificationsCount}
    >
      <ActionIcon variant='white' color='gray.8' size={36} fz={20} onClick={onClick} aria-label='notifications'>
        <Bell />
      </ActionIcon>
    </Indicator>
  );
};
