import { filterDOMProps } from '@react-aria/utils';
import { forwardRef } from 'react';
import { Label } from 'react-aria-components';

import * as styles from './field-label.css';

import type { HTMLProps } from '../types';
import type { PropsWithChildren } from 'react';

export type FieldLabelProps = PropsWithChildren<HTMLProps<HTMLLabelElement>> & {
  /**
   * Controls whether a label is required or optional.
   * @default false
   */
  isRequired?: boolean;
  /**
   * Visibly controls whether `required` or `optional` label is shown.
   */
  requirementIndicator?: 'label';
  htmlFor?: string;
};

export const FieldLabel = forwardRef<HTMLLabelElement, FieldLabelProps>(function FieldLabel(props, ref) {
  const { children, isRequired = false, requirementIndicator, ...otherProps } = props;
  const { container, label, indicator } = styles.fieldLabel();

  return (
    <Label
      {...filterDOMProps(otherProps, { propNames: new Set(['htmlFor', 'onClick']) })}
      ref={ref}
      className={container()}
    >
      <span className={label()}>{children}</span>
      {requirementIndicator === 'label' && (
        <span className={indicator()}>({isRequired ? 'required' : 'optional'})</span>
      )}
    </Label>
  );
});
