import React, { Fragment, useCallback, useState } from 'react';
import { Stack, Text, Box, Divider } from '@good/ui/core';

import { useNavBarSections } from './use-navbar-sections';
import { NavbarLink } from './navbar-link';
import type { NavLinkItem } from '../types';
import { useMatchedNavItem } from './use-matched-nav-item';

type NavBarLinksProps = {
  onLinkSelect: () => void;
};

export const NavbarLinks = ({ onLinkSelect }: NavBarLinksProps) => {
  const [active, setActive] = useState<NavLinkItem | undefined>(undefined);

  const onMatchedNavItem = useCallback((navItem: NavLinkItem | undefined) => {
    setActive(navItem);
  }, []);

  const navbarSections = useNavBarSections();
  useMatchedNavItem(navbarSections, onMatchedNavItem);

  return (
    <Stack>
      {navbarSections.map((section, index) => {
        const { items } = section;
        const isLastSection = index === navbarSections.length - 1;

        return (
          <Fragment key={section.key}>
            <Box px='sm'>
              <Stack gap={4}>
                {section.title && (
                  <Text tt='uppercase' c='dimmed' fw={700} fz='xs' ml='md' my={6}>
                    {section.title}
                  </Text>
                )}
                {items.map((item) => (
                  <NavbarLink
                    key={item.label}
                    isActive={item.label === active?.label}
                    item={item}
                    onClick={(item) => {
                      // setActive will be called again by useMatchedNavItem - but this provides a smoother experience than waiting for the useMatchedNavItem hook to update the active item
                      setActive(item);
                      onLinkSelect();
                    }}
                  />
                ))}
              </Stack>
            </Box>
            {!isLastSection && <Divider />}
          </Fragment>
        );
      })}
    </Stack>
  );
};
