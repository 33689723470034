import React, { Component } from 'react';
import { Avatar, Col, Tag, Icon } from 'antd';
import * as H from 'history';
import _ from 'lodash';
import { Text } from 'common-components/typography';
import { IGroupServiceCustomerListItem } from 'interfaces/customer-interfaces';
import { GridRow } from 'common-components/grids';
import { GhostButton, HyperlinkButton } from 'common-components/buttons';
import { Popover } from '@blueprintjs/core';
import { IGroupServiceOverview } from 'interfaces/service-interfaces';
import { MessageOrigin } from 'interfaces/message-interfaces';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';

interface ICustomerListItemProps {
  history: H.History;
  serviceId: string;
  selectedGroupService: IGroupServiceOverview;
  customerItem: IGroupServiceCustomerListItem;
  onOpenCustomerScheduleModal: () => void;
}

export default class CustomerListItem extends Component<ICustomerListItemProps> {
  private _getPopoverContent = () => {
    return (
      <div>
        <ActionMenu className="pv-small">
          <ActionMenuItem
            className="hover-bg-secondary pv-small ph-medium"
            text={'View schedule'}
            onClick={this.props.onOpenCustomerScheduleModal}
          />
          <ActionMenuItem
            className="hover-bg-secondary pv-small ph-medium"
            text={'Message customer'}
            onClick={this._onMessageCustomer}
          />
        </ActionMenu>
      </div>
    );
  };

  private _getActivityGroupPopoverContent = (activityGroups) => {
    return (
      <div className="ph-x-large pv-medium" style={{ minWidth: '327px', maxHeight: '246px', overflowX: 'hidden' }}>
        <div className="mb-medium">
          <Text className={'text-weight-bold'}>Assigned to ({_.get(activityGroups, 'length')}) activity groups</Text>
        </div>
        <div className="flex-column space-between" style={{ rowGap: '12px' }}>
          {_.map(activityGroups, (item) => {
            return (
              <div className="whitespace-nowrap">
                <Tag key={item} className="tag-icon bg-tertiary flex-row align-center" style={{ border: 'none' }}>
                  <Icon type="calendar" style={{ fontSize: '14px' }} />
                  <Text className={'text-overflow-ellipsis text-weight-bold'} weight="500" size="regular">
                    {item}
                  </Text>
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  private _viewCustomerProfile = () => {
    const newUrl = `/customer/details/${this.props.customerItem.customerUserId}`;
    this.props.history.push(newUrl);
  };

  private _onMessageCustomer = () => {
    const { history, customerItem, serviceId, selectedGroupService } = this.props;
    const userId = customerItem.customerUserId;
    const avatarUrl = customerItem.customerAvatarUrl;
    const serviceName = selectedGroupService.serviceName;
    const userName = `${customerItem.customerFirstName} ${customerItem.customerLastName}`;

    history.push({
      pathname: '/messaging',
      state: { origin: MessageOrigin.BookingDetailsCustomer, serviceId, userId, serviceName, userName, avatarUrl },
    });
  };

  render() {
    const { customerItem } = this.props;
    const activityGroupsLength = _.get(customerItem, 'activityGroups.length', 0);

    return (
      <GridRow containerClassName={'bordered rounded mt-medium'}>
        <Col span={6}>
          <div className="flex-row align-center">
            <Avatar className="mr-medium" size="large" icon="user" src={customerItem.customerAvatarUrl} />
            <HyperlinkButton
              onClick={this._viewCustomerProfile}
            >{`${customerItem.customerFirstName} ${customerItem.customerLastName}`}</HyperlinkButton>
          </div>
        </Col>
        <Col span={3} className={'text-align-center'}>
          <Text>{customerItem.upcomingBookingCount}</Text>
        </Col>
        <Col span={3} className={'text-align-center'}>
          <Text>{customerItem.completedBookingCount}</Text>
        </Col>
        <Col span={3}></Col>
        <Col span={3} className={'text-align-center'}></Col>
        <Col span={4} className={'text-align-center'}>
          {activityGroupsLength !== 0 && (
            <Popover
              content={this._getActivityGroupPopoverContent(_.get(customerItem, 'activityGroups', []))}
              position={'bottom-right'}
              interactionKind="hover"
            >
              <Tag className="tag-icon bg-tertiary flex-row align-center" style={{ border: 'none' }}>
                <Icon type="calendar" style={{ fontSize: '14px' }} />
                <Text size="regular">
                  {activityGroupsLength} {activityGroupsLength >= 2 ? 'groups' : 'activity'}
                </Text>
              </Tag>
            </Popover>
          )}
        </Col>
        <Col span={2} className="text-align-right">
          <Popover content={this._getPopoverContent()} position={'bottom-right'} interactionKind="click">
            <div
              className="bordered border-standard-gray rounded text-align-center"
              style={{ width: '28px', height: '28px' }}
            >
              <GhostButton icon={'ellipsis'} size={'small'} paddingSize={'x-small'} />
            </div>
          </Popover>
        </Col>
      </GridRow>
    );
  }
}
