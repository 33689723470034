import { Menu, MenuItem, Popover } from '@blueprintjs/core';
import { CurrencyFormatter } from '@goodhuman-me/components';
import { Icon } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { PaymentMethodTag } from 'common-components/tags';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { CLAIM_TYPE_STANDARD_OPTIONS } from 'views/billings/utils/constants';
import { ServiceType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import PaymentErrorIndicator from '../PaymentErrorIndicator';

interface IPaymentItemRow {
  lineItem?: any;
  customerId?: any;
  history?: any;
  openActionModal?: any;
  errorTypes?: string[];
  setSelectedBillingLineItem?: typeof dispatch.billingsStore.setSelectedBillingLineItem;
  portalUser?: typeof state.authStore.portalUser;
  warningTypes?: string[];
}

const unit = {
  HOURS: 'H',
  EACH: 'EA',
};

const unitOptions = [
  { value: unit.HOURS, label: 'hours' },
  { value: unit.EACH, label: 'each' },
];

class PayWaivedItemRow extends Component<IPaymentItemRow> {
  _checkbox = null;
  state = {
    bookingBillingLineItemId: null,
    showSubLines: false,
    openAction: false,
    attendanceId: null,
  };

  _onClickBooking = () => {
    const { history } = this.props;
    history.push(`/bookings/details/${this.state.attendanceId}`);
  };

  _onClickCustomer = () => {
    const { history } = this.props;
    history.push(`/customer/details/${this.props.customerId}`);
  };

  componentDidMount = () => {
    const { lineItem } = this.props;

    if (!_.isEmpty(lineItem)) {
      const { bookingBillingLineItemId, attendanceId } = lineItem;
      this.setState({ bookingBillingLineItemId, attendanceId });
    }
  };

  render() {
    const { lineItem, openActionModal, portalUser, errorTypes, warningTypes } = this.props;

    if (_.isEmpty(lineItem)) {
      return <></>;
    }

    const {
      billingTotal,
      selected,
      paymentMethod,
      supportItemNumber,
      referenceId,
      unitPrice,
      claimType,
      unit,
      isError,
      qty,
      serviceType,
      isIncludeGst,
      gstValue,
      billingTotalWithDiscount,
    } = lineItem;

    const displayBorder = !isError ? { borderBottom: '1px solid #ccc' } : {};
    return (
      <>
        <tr style={displayBorder}>
          <td className="fluid-cell check-all" />
          {paymentMethod && paymentMethod !== '' && (
            <td className="fluid-cell">
              <PaymentMethodTag paymentMethod={paymentMethod} className="mr-medium" rounded={true} size="small" />{' '}
            </td>
          )}
          <td colSpan={paymentMethod && paymentMethod !== '' ? 1 : 2} className="ellipsis">
            {supportItemNumber}
          </td>
          <td className="ellipsis">
            {claimType
              ? CLAIM_TYPE_STANDARD_OPTIONS.find((item) => item.value === claimType).label
              : 'Standard Service'}
          </td>
          <td className="ellipsis">
            {[ServiceType.GROUP, ServiceType.INDEPENDENT_LIVING].includes(serviceType) ? (
              referenceId
              ) : (
              <HyperlinkButton onClick={this._onClickBooking}>{referenceId}</HyperlinkButton>
            )}
          </td>
          <td className="ellipsis">
            <div className="text-align-right">
              <CurrencyFormatter value={Number(billingTotalWithDiscount)} />
              <PaymentErrorIndicator errorTypes={errorTypes} billingItems={[lineItem]} warningTypes={warningTypes} />
            </div>
          </td>
          <td className="fluid-cell">
            <Popover
              content={
                <Menu>
                  {PermissionUtils.validatePermission(
                    'UnwaivePaymentBillingLineItem',
                    portalUser.permissions.permissionRoles,
                  ) && (
                    <MenuItem
                      text={
                        <Text weight="bold" color="green">
                          Unwaive
                        </Text>
                      }
                      className="hover-bg-green-lightest"
                      onClick={() => openActionModal({ action: 'UNWAIVE', additionalData: lineItem })}
                    />
                  )}
                  <MenuItem
                    text={
                      <Text weight="bold" color="green">
                        View waive reason
                      </Text>
                    }
                    className="hover-bg-green-lightest"
                    onClick={() => openActionModal({ action: 'VIEW', additionalData: lineItem })}
                  />
                  {PermissionUtils.validatePermission(
                    'DeletePaymentBillingLineItem',
                    portalUser.permissions.permissionRoles,
                  ) && (
                    <MenuItem
                      text={
                        <Text weight="bold" color="red">
                          Delete
                        </Text>
                      }
                      className="hover-bg-red-lightest"
                      onClick={() => openActionModal({ action: 'DELETE', additionalData: lineItem })}
                    />
                  )}
                </Menu>
              }
              position={'bottom-right'}
            >
              <Icon type="ellipsis" className="text-size-x4-large" />
            </Popover>
          </td>
        </tr>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });

export default connect(mapState, null)(PayWaivedItemRow);
