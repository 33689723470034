import _ from 'lodash';
import { FilterType, UserPermissionRole, WorkflowTemplateTriggerType } from 'utilities/enum-utils';
import { IWorkflowStep, IWorkflowStepApprover } from '../../../interfaces/workflow-interfaces';
import { FILE_EXTENSION_UPLOAD_SUPPORT } from './constants';
import PermissionUtils from 'utilities/permission-utils';

const roles = [
  { id: UserPermissionRole.ServiceAdmin, name: 'Service managers', description: ' - (Of service workflow relates to)' },
  {
    id: UserPermissionRole.DepartmentManager,
    name: 'Department managers',
    description: ' - (Of service workflow relates to)',
  },
  { id: UserPermissionRole.Owner, name: 'Owners' },
  { id: UserPermissionRole.PrimaryOwner, name: 'Primary owner' },
];

export const getRoleDetail = (roleId) => {
  return roles.find((role) => role.id == roleId);
};

export const getTriggerTypeWorkflowLabel = (type) => {
  switch (type) {
    case WorkflowTemplateTriggerType.MANUAL:
      return 'Manual';
    case WorkflowTemplateTriggerType.INCIDENT:
      return 'Incident';
    default:
      return '';
  }
};

export const formatWorkflowListingFilters = (filters: Array<any>) => {
  return filters.reduce((filterObj, filter) => {
    const newFilterObj = { ...filterObj };

    if (!_.isEmpty(filter.values)) {
      switch (filter.filter) {
        case FilterType.DATE_RANGE:
          newFilterObj.date = [filter.values[0].toDate(), filter.values[1].toDate()];
          break;
        case FilterType.ALL_WORKFLOWS_STATUS:
          newFilterObj.status = filter.values;
          break;
      }
    }
    return newFilterObj;
  }, {});
};

export const getActiveStep = (steps: IWorkflowStep[]) => {
  return steps.find((one) => one.status === 'ACTIVE');
};

export const getStepAvailable = (steps: IWorkflowStep[]) => {
  const activeStep = getActiveStep(steps);

  if (activeStep) {
    return activeStep;
  }

  if (steps.length) {
    return steps[steps.length - 1];
  }

  return undefined;
};

export const getWorkersByKeyword = (keyword, workerListLite) => {
  const targetStr = keyword
    .toLowerCase()
    .split(' ')
    .filter(function (el) {
      return el.length !== 0;
    });

  const result =
    targetStr.length > 0
      ? _.filter(workerListLite, (item) => {
          if (
            _.some(
              targetStr,
              (string) => `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()}`.indexOf(string) >= 0,
            )
          ) {
            return item;
          }
        })
      : workerListLite;
  return result;
};

export const getIndividualMembers = (approvers: IWorkflowStepApprover[], isRoleApproval?: boolean) => {
  if (isRoleApproval) {
    return _.sortBy(approvers, 'name');
  }
  return _.sortBy(
    approvers.filter((one) => !one.role),
    'name',
  );
};

export const validFileExtension = (fileName) => {
  return new RegExp('(' + FILE_EXTENSION_UPLOAD_SUPPORT.join('|').replace(/\./g, '\\.') + ')$').test(
    fileName.toLowerCase(),
  );
};

export const checkManagerRole = (portalUser) => {
  return (
    portalUser && PermissionUtils.validatePermission('ViewLinkedFormWorkflow', portalUser.permissions.permissionRoles)
  );
};
