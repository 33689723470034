import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Webcam = forwardRef(function Webcam(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.75a6.25 6.25 0 1 0 0 12.5 6.25 6.25 0 0 0 0-12.5ZM4.25 9a7.75 7.75 0 1 1 13.537 5.155c1.188.949 2.019 2.15 2.598 3.3l.034.068c.358.71.683 1.354.823 1.943.164.685.091 1.32-.313 1.976-.427.694-1.04 1.025-1.785 1.175-.668.133-1.512.133-2.49.133H7.346c-.979 0-1.823 0-2.49-.133-.747-.15-1.359-.48-1.786-1.175-.404-.655-.477-1.291-.313-1.976.14-.589.465-1.233.823-1.943l.034-.068c.58-1.15 1.41-2.351 2.598-3.3A7.721 7.721 0 0 1 4.25 9Zm3.081 6.187c-1.067.794-1.832 1.862-2.377 2.943-.402.8-.642 1.287-.737 1.685-.077.322-.05.546.131.84.159.258.36.402.803.49.503.102 1.196.105 2.269.105h9.16c1.073 0 1.766-.003 2.27-.104.442-.089.644-.233.802-.49.182-.295.208-.52.132-.841-.096-.398-.335-.885-.738-1.685-.545-1.081-1.31-2.149-2.377-2.943A7.716 7.716 0 0 1 12 16.75a7.716 7.716 0 0 1-4.669-1.563Zm4.67-7.437a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM9.25 9a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
