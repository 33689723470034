import { Col, Icon, notification, Row } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';

interface IArchiveDocumentModalProps {
  closeArchiveDocumentModal: () => void;
  isOpen: boolean;
  selectedDocument: any;
  selectedItemId: string;
  itemType: string;
  doArchiveCustomerDocument: typeof dispatch.customersStore.doArchiveCustomerDocument;
  doArchiveWorkerDocument: typeof dispatch.teamStore.doArchiveWorkerDocument;
  resetDocumentList: () => void;
  useAttachmentText: boolean;
}

class ArchiveDocumentModal extends Component<IArchiveDocumentModalProps> {
  state = { isLoading: false, title: `Archive ${this.props.useAttachmentText ? 'attachment' : 'document'}`, step: 1 };

  private _archiveDocument = async () => {
    const { selectedDocument, selectedItemId } = this.props;
    try {
      this.props.itemType === 'customer'
        ? await this.props.doArchiveCustomerDocument({
            userId: selectedItemId,
            documentId: selectedDocument.documentId,
          })
        : await this.props.doArchiveWorkerDocument({
            supportWorkerId: selectedItemId,
            documentId: selectedDocument.documentId,
          });
      this.props.resetDocumentList();
      this.setState({
        title: (
          <>
            Document successfully archived
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        step: 2,
      });
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
    }
  };

  private _closeModal = () => {
    this.setState({ title: 'Archive document', step: 1 });
    this.props.closeArchiveDocumentModal();
  };

  render() {
    const { useAttachmentText } = this.props;
    const { step } = this.state;
    return (
      <div>
        <ActionModal
          title={this.state.title}
          isOpen={this.props.isOpen}
          onClose={this.props.closeArchiveDocumentModal}
          width="medium"
        >
          {step === 1 && (
            <div className="anim-slide-left">
              <SpinningLoadingActionModel isOpen={this.state.isLoading} verticalAlignment={'highest'} />
              {this.props.itemType === 'customer' ? (
                <>
                  <Paragraph>
                    Are you sure you want to archive this {useAttachmentText ? 'attachment' : 'document'}?
                  </Paragraph>{' '}
                  <Paragraph>
                    This action is permanent and cannot be undone. The {useAttachmentText ? 'attachment' : 'document'}{' '}
                    can still be accessed from this customer&#39;s profile.
                  </Paragraph>
                </>
              ) : (
                <Paragraph>
                  Are you sure you want to archive this {useAttachmentText ? 'attachment' : 'document'}? This action is
                  permanent and cannot be undone. The
                  {useAttachmentText ? 'attachment' : 'document'} can still be accessed from this users profile.
                </Paragraph>
              )}

              <div className={'mb-small'}>
                <Row type={'flex'} className={'justify-end'}>
                  <Col className="mr-large">
                    <GhostButton size="large" onClick={this.props.closeArchiveDocumentModal}>
                      Cancel
                    </GhostButton>
                  </Col>
                  <Col>
                    <PrimaryButton size="large" color="red" onClick={this._archiveDocument}>
                      Archive
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="anim-slide-left">
              <Paragraph>
                You have successfully archived this {useAttachmentText ? 'attachment' : 'document'}.
              </Paragraph>
              <div className={'mb-small'}>
                <Row type={'flex'} className={'justify-end'}>
                  <Col>
                    <PrimaryButton size="large" onClick={this._closeModal}>
                      Close
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doArchiveCustomerDocument: dispatch.customersStore.doArchiveCustomerDocument,
  doArchiveWorkerDocument: dispatch.teamStore.doArchiveWorkerDocument,
});

export default connect(null, mapDispatch)(ArchiveDocumentModal);
