import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ErrorSVG, SuccessSVG } from 'assets/UndrawSVG';
import { Paragraph, Text } from 'common-components/typography';
import { Form } from 'antd';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';

interface IRejectPaymentActionModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  doRemoveBillingLineItem: typeof dispatch.billingsStore.doRemoveBillingLineItem;
  selectedBillingLineItem: typeof state.billingsStore.selectedBillingLineItem;
}

class RejectPaymentActionModal extends Component<IRejectPaymentActionModalProps, any> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false,
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  private _onSubmitReject = () => {
    const { form, doRemoveBillingLineItem, selectedBillingLineItem } = this.props;
    form.validateFields(async (err, value) => {
      if (!err) {
        this.setState({ isLoading: true });

        const rejectReason = value.note;
        try {
          await doRemoveBillingLineItem({
            attendanceId: selectedBillingLineItem.attendanceId,
            bookingBillingLineItemId: selectedBillingLineItem.bookingBillingLineItemId,
            rejectReason: rejectReason,
          });

          this.setState({ isLoading: false, isSuccess: true });
        } catch (e) {
          this.setState({ isLoading: false, isError: true });
        }
      }
    });
  };

  render() {
    const { isOpen, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ActionModal
        title="Reject Line Item"
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        {!this.state.isLoading && !this.state.isSuccess && !this.state.isError && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>
                You have decided to reject this item from your Payment list. This will change the payment status of this
                Line Item to Rejected. It can then be edited and resend for payment at a later date.
              </Paragraph>
            </div>

            <div className="flex-row align-left mb-medium">
              <Text>Are you sure you want to reject it ?</Text>
            </div>
            <div className="flex-row align-center justify-center mb-x-large">
              <Form.Item className="width-full">
                {getFieldDecorator('note', { rules: [{ required: true, message: 'Please enter a rejection reason' }] })(
                  <TextArea placeholder="Rejection Reason(s)" />,
                )}
              </Form.Item>
            </div>
          </div>
        )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading && this.state.isSuccess && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={SuccessSVG} alt={'Success'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>The Payment has been rejected successfully.</Paragraph>
            </div>
          </div>
        )}

        {!this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <GhostButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Close
            </GhostButton>
            <PrimaryButton size="large" onClick={this._onSubmitReject} loading={this.state.isLoading}>
              Reject
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {!this.state.isLoading && this.state.isError && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>An error has occured.</Paragraph>
            </div>
          </div>
        )}

        {this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveBillingLineItem: dispatch.billingsStore.doRemoveBillingLineItem,
});

export default connect(mapState, mapDispatch)(Form.create<IRejectPaymentActionModalProps>()(RejectPaymentActionModal));
