import { Form, Input, Icon, Button } from 'antd';
import React from 'react';

interface IDynamicAddOptionsProps {
  options: any[];
  form: any;
  prefix: string;
  setProperties: any;
  isDisabled: boolean;
}

class DynamicAddOptions extends React.Component<IDynamicAddOptionsProps, any> {
  state = {
    id: this.props.options.length,
  };

  private _remove = (k) => {
    const { form } = this.props;

    const keys = form.getFieldValue('keys');
    // We need at least 2 options
    if (keys.length === 2) {
      return;
    }
    const newKeys = keys.filter((key) => key !== k);
    form.setFieldsValue({
      keys: newKeys,
    });
    this.props.setProperties();
  };

  private _add = () => {
    const { form } = this.props;

    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(this.state.id);
    this.setState({ id: this.state.id + 1 });

    form.setFieldsValue({
      keys: nextKeys,
    });
    this.props.setProperties();
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { options, isDisabled } = this.props;
    const defaultOptions = options.map((option, index) => index);

    getFieldDecorator('keys', { initialValue: defaultOptions });
    const keys = getFieldValue('keys');

    const formItems = keys.map((k, index) => {
      return (
        <Form.Item required={false} key={k}>
          {getFieldDecorator([this.props.prefix, `options[${k}]`] as never, {
            validateTrigger: ['onChange', 'onBlur'],
            initialValue: options[parseInt(k)],
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Please enter a value',
              },
            ],
          })(<Input placeholder={`Option (${index + 1})`} className=" mr-small" style={{ width: '60%' }} />)}
          {keys.length > 2 ? (
            <Icon className="text-color-red-light" type="minus-circle-o" onClick={() => this._remove(k)} />
          ) : null}
        </Form.Item>
      );
    });
    return (
      <>
        {formItems}
        {!isDisabled && (
          <Button type="link" className="width-3\/5" onClick={this._add}>
            <Icon type="plus" className="text-color-blue-action" /> Add new choice
          </Button>
        )}
      </>
    );
  }
}

export default DynamicAddOptions;
