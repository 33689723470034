import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Search = forwardRef(function Search(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 11a7.25 7.25 0 1 1 14.5 0 7.25 7.25 0 0 1-14.5 0ZM11 2.25a8.75 8.75 0 1 0 5.634 15.445l2.835 2.835a.75.75 0 0 0 1.06-1.06l-2.835-2.836A8.75 8.75 0 0 0 11 2.25Z"
        fill="currentColor"
      />
    </Icon>
  );
});
