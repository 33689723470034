import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import React, { PureComponent } from 'react';

interface Props {
  isOpen: boolean;
  approvers: any[];
  isAdding: boolean;
  onClose(): void;
  onConfirm(): void;
}

export default class ConfirmAddApproversModal extends PureComponent<Props> {
  render() {
    const { isOpen, approvers, isAdding, onClose, onConfirm } = this.props;

    const approversTotal = approvers.length;

    return (
      <ActionModal isOpen={isOpen} onClose={onClose} title="Add approvers" verticalAlignment="high">
        <Paragraph>Making a team member an approver will give them access to this workflow.</Paragraph>
        <Paragraph>
          Are you sure you want to add &nbsp;
          <Text className="text-weight-bold">
            {approversTotal} team member{approversTotal > 1 ? 's' : ''}
          </Text>
          &nbsp;as {approversTotal > 1 ? '' : 'an'} approver
          {approversTotal > 1 ? 's' : ''}?
        </Paragraph>

        <ActionModalFooter align="right">
          <GhostButton size="large" className="mr-medium" onClick={onClose}>
            Cancel
          </GhostButton>
          <PrimaryButton size="large" onClick={onConfirm} loading={isAdding}>
            Add
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}
