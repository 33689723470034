import React from 'react';
import { Checkbox, Input } from 'antd';
import { Text, SubTitle } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import { IFormElement } from 'views/form-builder/shared/form-interface';
import {
  convertRulesRequiredOfBundle,
  getRules,
  getValueFromPropertyByKey,
} from 'views/form-builder/shared/form-builder-utils';
import FormItem from 'antd/es/form/FormItem';
import { FormElementType } from 'views/form-builder/shared/form-enum';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
interface ICheckboxElementProps {
  defaultValue: any;
  title: string;
  caption: string;
  text: string;
  isRequired?: boolean;
}
export default function CheckboxElement({ defaultValue, title, caption, text, isRequired }: ICheckboxElementProps) {
  const fieldTextFormat = (
    <>
      {title}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bolder">{fieldTextFormat}</SubTitle>

      <Checkbox className="pt-small" checked={defaultValue}>
        {text}
      </Checkbox>
      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
}

interface IEditCheckboxElementProps extends FormComponentProps {
  value?: string;
  element: IFormElement;
  enableCaption?: boolean;
  onChange?: (isChecked: boolean) => void;
}

export const EditCheckboxElement = ({
  value,
  element,
  form,
  enableCaption = true,
  onChange,
}: IEditCheckboxElementProps) => {
  const { elementsBundleOptional } = useSelector((state: IRootState) => state.formBuilderStore);
  const { id, parentId } = element;
  const text = getValueFromPropertyByKey(element.properties.appearance, 'checkboxText');
  const caption = getValueFromPropertyByKey(element.properties.appearance, 'captionText');
  let rules = getRules(element, false);
  const { getFieldDecorator } = form;
  const isRequired = getValueFromPropertyByKey(element.properties.general, 'require');
  let title = getValueFromPropertyByKey(element.properties.general, 'fieldTitle');

  if (isRequired) {
    const checkRequiredBundle = !elementsBundleOptional.includes(id);
    rules = convertRulesRequiredOfBundle(checkRequiredBundle, rules);

    title = (
      <>
        {title}
        {checkRequiredBundle && <Text color="red"> *</Text>}
      </>
    );
  }

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bolder">{title}</SubTitle>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'value'] as never, {
          initialValue: value,
          rules: rules,
          valuePropName: 'checked',
        })(
          <Checkbox className="pt-small" onChange={(event: CheckboxChangeEvent) => onChange(event.target.checked)}>
            {text}
          </Checkbox>,
        )}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'type'] as never, {
          initialValue: FormElementType.CHECKBOX,
        })(<Input type="hidden" />)}
      </FormItem>

      <FormItem className="m-none">
        {getFieldDecorator([id, 'parentElementId'] as never, {
          initialValue: parentId,
        })(<Input type="hidden" />)}
      </FormItem>

      {enableCaption && (
        <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
          {caption}
        </Text>
      )}
    </div>
  );
};
