import { ProgressBar } from '@blueprintjs/core';
import { Skeleton } from 'antd';
import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import { Text } from 'common-components/typography';
import { ICrumb } from 'interfaces/common-interface';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { WorkflowTemplateStatus } from 'utilities/enum-utils';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import WorkflowTemplateDetailActivePanel from './components/tabs-panel/active-workflow-templates/WorkflowTemplateDetailActivePanel';
import WorkflowTemplateDetailConfigurationPanel from './components/tabs-panel/configuration/WorkflowTemplateDetailConfigurationPanel';
import CancelChangeWorkflowTemplateModal from './components/tabs-panel/steps/components/CancelChangeWorkflowTemplateModal';
import RouteLeavingGuard from './components/tabs-panel/steps/components/RouteLeavingGuard';
import WorkflowTemplateDetailStepsPanel from './components/tabs-panel/steps/WorkflowTemplateDetailStepsPanel';
import WorkflowTemplateDetailVersionHistory from './components/tabs-panel/version-history/WorkflowTemplateDetailVersionHistory';
import WorkflowTemplateDetailHeader from './components/WorkflowTemplateDetailHeader';
import WorkflowTemplateDetailTabPanel from './components/WorkflowTemplateDetailTabPanel';
import { WORKFLOW_TEMPLATE_DETAIL_TAB_KEY } from '../../utils/constants';

interface IWorkflowTemplateUrlParams {
  workflowTemplateId: string;
}

interface IWorkflowTemplateDetailViewProps extends RouteComponentProps<IWorkflowTemplateUrlParams> {
  selectedWorkflowTemplate: typeof state.workflowStore.selectedWorkflowTemplate;
  doGetWorkflowTemplateConfigurations: typeof dispatch.workflowStore.doGetWorkflowTemplateConfigurations;
  setSelectedWorkflowTemplate: typeof dispatch.workflowStore.setSelectedWorkflowTemplate;
  setStateSaveStepDataWorkflowTemplate: typeof dispatch.workflowStore.setStateSaveStepDataWorkflowTemplate;
}

interface IWorkflowTemplateDetailViewState {
  isLoading: boolean;
  selectedTab: string;
  enableEditStep: boolean;
  isOpenCancelChangeModal: boolean;
  targetSelectTab: string;
  isDataChange: boolean;
  disableEditStep?: boolean;
}

class WorkflowTemplateDetailView extends Component<IWorkflowTemplateDetailViewProps, IWorkflowTemplateDetailViewState> {
  state = {
    isLoading: false,
    selectedTab: WORKFLOW_TEMPLATE_DETAIL_TAB_KEY.CONFIGURATION,
    enableEditStep: false,
    isOpenCancelChangeModal: false,
    targetSelectTab: '',
    isDataChange: false,
    disableEditStep: false,
  };

  private _onChangeSelectedTab = (tab) => {
    const { selectedTab, enableEditStep, isDataChange } = this.state;
    if (selectedTab === WORKFLOW_TEMPLATE_DETAIL_TAB_KEY.STEPS && enableEditStep && isDataChange) {
      this.setState({ targetSelectTab: tab, isOpenCancelChangeModal: true });
      return;
    }

    this.setState({ selectedTab: tab, enableEditStep: false });
  };

  private _toggleVisibleEditStep = () => {
    this.setState({ enableEditStep: !this.state.enableEditStep });
  };

  private _setDataChangedState = (isDataChanged) => {
    this.setState({ isDataChange: isDataChanged });
    this.props.setStateSaveStepDataWorkflowTemplate(isDataChanged);
  };

  componentWillUnmount() {
    this.props.setSelectedWorkflowTemplate(null);
  }

  componentDidUpdate = async (prevProps: IWorkflowTemplateDetailViewProps) => {
    const { state } = this.props.location as any;

    if (prevProps.match.params.workflowTemplateId !== this.props.match.params.workflowTemplateId) {
      const { doGetWorkflowTemplateConfigurations, match } = this.props;

      this.setState({ isLoading: true });
      await doGetWorkflowTemplateConfigurations({ workflowTemplateId: match.params.workflowTemplateId });
      this.setState({ isLoading: false });
    }

    if (state?.selectedTab) {
      this.props.history.replace(this.props.location.pathname, {});
      this._onChangeSelectedTab(state?.selectedTab);
    }
  };

  async componentDidMount() {
    const { doGetWorkflowTemplateConfigurations, match } = this.props;
    const { state } = this.props.location as any;
    const { params } = match;
    const selectedWorkflowTemplateId = params.workflowTemplateId;

    this.setState({ isLoading: true });
    await doGetWorkflowTemplateConfigurations({ workflowTemplateId: selectedWorkflowTemplateId });
    if (state?.selectedTab) {
      this._onChangeSelectedTab(state?.selectedTab);
    }
    this.setState({ isLoading: false });
  }

  render() {
    const { history, selectedWorkflowTemplate } = this.props;
    const { selectedTab, isLoading, enableEditStep, isOpenCancelChangeModal, targetSelectTab, isDataChange } =
      this.state;
    const { state } = this.props.location as any;

    const isDisabledEditStep =
      selectedWorkflowTemplate && selectedWorkflowTemplate.status === WorkflowTemplateStatus.ARCHIVED;

    const crumbs: ICrumb[] = [
      {
        title: 'Workflows',
        target: '/account/workflows',
      },
      {
        title: selectedWorkflowTemplate ? selectedWorkflowTemplate.name : '-',
      },
    ];

    if (isLoading) {
      return (
        <div className='view-container'>
          <div className='pb-medium'>
            <div className='pb-medium'>
              <Text>Fetching workflow details configuration...</Text>
            </div>
            <ProgressBar />
          </div>
          <Skeleton loading={isLoading} />
        </div>
      );
    }

    return (
      <div id='content-container'>
        <BreadcrumbNav icon='home' theme='filled' crumbs={crumbs} />

        <div className='width-full'>
          {/* Header */}
          <WorkflowTemplateDetailHeader disabledWorkflowAction={enableEditStep || isDisabledEditStep} />

          {/* Content */}
          <WorkflowTemplateDetailTabPanel selectedTab={selectedTab} onChangeTab={this._onChangeSelectedTab} />

          <div className='height-full p-large'>
            {selectedTab === WORKFLOW_TEMPLATE_DETAIL_TAB_KEY.CONFIGURATION && (
              <WorkflowTemplateDetailConfigurationPanel
                workflowTemplate={selectedWorkflowTemplate}
                isDisabledEditStep={isDisabledEditStep}
              />
            )}
            {selectedTab === WORKFLOW_TEMPLATE_DETAIL_TAB_KEY.STEPS && (
              <WorkflowTemplateDetailStepsPanel
                onToggleVisibleEditStep={this._toggleVisibleEditStep}
                onSetDataChangeState={this._setDataChangedState}
                enableEditStep={enableEditStep}
                isDisabledEditStep={isDisabledEditStep}
                isRefreshSteps={state?.refresh}
              />
            )}
            {selectedTab === WORKFLOW_TEMPLATE_DETAIL_TAB_KEY.ACTIVE_WORKFLOWS && (
              <WorkflowTemplateDetailActivePanel selectedWorkflowTemplate={selectedWorkflowTemplate} />
            )}
            {selectedTab === WORKFLOW_TEMPLATE_DETAIL_TAB_KEY.VERSION_HISTORY && (
              <WorkflowTemplateDetailVersionHistory />
            )}
          </div>
        </div>

        <CancelChangeWorkflowTemplateModal
          isOpen={isOpenCancelChangeModal}
          onClose={() => this.setState({ isOpenCancelChangeModal: false })}
          onConfirm={() =>
            this.setState({ isOpenCancelChangeModal: false, selectedTab: targetSelectTab, isDataChange: false })
          }
        />

        <RouteLeavingGuard
          navigate={(path) => history.push(path)}
          shouldBlockNavigation={() => {
            return isDataChange;
          }}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedWorkflowTemplate: state.workflowStore.selectedWorkflowTemplate,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedWorkflowTemplate: dispatch.workflowStore.setSelectedWorkflowTemplate,
  doGetWorkflowTemplateConfigurations: dispatch.workflowStore.doGetWorkflowTemplateConfigurations,
  setStateSaveStepDataWorkflowTemplate: dispatch.workflowStore.setStateSaveStepDataWorkflowTemplate,
});

export default connect(mapState, mapDispatch)(WorkflowTemplateDetailView);
