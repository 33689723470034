import { Spinner } from '@blueprintjs/core';
import { notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

interface IActivityRecordApproveActionModelProps {
  isOpen: boolean;
  navigateTab: (key) => void;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  onClose: () => void;
  doSendToFinance: typeof dispatch.bookingsStore.doSendToFinance;
}

class ActivityRecordApproveActionModel extends Component<IActivityRecordApproveActionModelProps, any> {
  state = {
    canManuallyClose: true,
    title: 'Approve activity record',
    isLoading: false,
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ canManuallyClose: true, title: 'Approve Activity Record', isLoading: false });
    onClose();
  };

  private _onApprove = async () => {
    this.setState({ canManuallyClose: false, isLoading: true });
    const { doSendToFinance } = this.props;
    try {
      await doSendToFinance({});
      notification.success({
        message: 'Activity record approved',
        description: 'You have successfully approved this activity record',
      });
      this._onCloseModal();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
      this._onCloseModal();
    }
  };

  render() {
    const { isOpen } = this.props;
    const { isLoading } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width="medium"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div className="flex-column justify-center">
          <div className="anim-fade-in">
            {isLoading ? (
              <Spinner size={150} />
            ) : (
              <>
                <div className="text-align-left">
                  <Paragraph>Are you sure you want to approve this customers activity record? </Paragraph>
                  <Paragraph>This will send all line items to payments where they can be processed </Paragraph>
                </div>
                <ActionModalFooter>
                  <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" onClick={this._onApprove}>
                    Approve
                  </PrimaryButton>
                </ActionModalFooter>
              </>
            )}
          </div>
        </div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedBookingItem: state.bookingsStore.selectedBookingItem });

const mapDispatch = (dispatch: IRootDispatch) => ({ doSendToFinance: dispatch.bookingsStore.doSendToFinance });

export default connect(mapState, mapDispatch)(ActivityRecordApproveActionModel);
