import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Bluetooth = forwardRef(function Bluetooth(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.695 2.315a.75.75 0 0 1 .807.128l5 4.5a.75.75 0 0 1 0 1.114L13.122 12l4.38 3.943a.75.75 0 0 1 0 1.115l-5 4.5A.75.75 0 0 1 11.25 21v-7.19l-3.72 3.72a.75.75 0 0 1-1.06-1.06L10.94 12 6.47 7.53a.75.75 0 0 1 1.06-1.06l3.72 3.72V3a.75.75 0 0 1 .445-.685Zm1.055 11.369v5.632L15.88 16.5l-3.129-2.816Zm0-3.368V4.684L15.88 7.5l-3.129 2.816Z"
        fill="currentColor"
      />
    </Icon>
  );
});
