import { useCallback } from 'react';
import { FirebaseStorage, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { FileWithPath } from '@good/ui/dropzone';

import { notifications } from '@good/ui/notifications';

export type UseHandleUploadImageOptions = {
  onUploadStart: (file: FileWithPath) => void;
  onProgressChanged: (progress: number) => void;
  onUploadError: (error: unknown, location: string) => void;
  onUploadSuccess: (downloadURL: string) => void;
};

export function useHandleUploadImage(
  imageUploadUrl: string,
  storage: FirebaseStorage,
  options: UseHandleUploadImageOptions,
) {
  return useCallback(
    (files: FileWithPath[]) => {
      const fileToUpload = files[0];

      if (!fileToUpload) {
        notifications.show({
          color: 'red',
          message: 'This file cannot be uploaded.',
        });
        return null;
      }

      const { onUploadStart, onProgressChanged, onUploadError, onUploadSuccess } = options;

      onUploadStart(fileToUpload);

      const storageRef = ref(storage, `${imageUploadUrl}/${fileToUpload.name}`);
      const uploadTask = uploadBytesResumable(storageRef, fileToUpload);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          onProgressChanged(Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
        },
        (error) => {
          onUploadError(error, 'ImageDropzone > uploadTask on Error');
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              onUploadSuccess(downloadURL);
              return downloadURL;
            })
            .catch((error) => {
              onUploadError(error, 'ImageDropzone > getDownloadURL');
            });
        },
      );

      return uploadTask;
    },
    [imageUploadUrl, options, storage],
  );
}
