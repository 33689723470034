import { Col } from 'antd';
import React from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { GhostButton } from 'common-components/buttons';
import { TeamStatus } from 'utilities/enum-utils';

type TmMgmtOptionMenuColumnParams = {
  status?: TeamStatus;
  menu: JSX.Element;
};

export const TmMgmtOptionMenuColumn = ({ status, menu }: TmMgmtOptionMenuColumnParams) => {
  const _status = status ?? TeamStatus.ENABLED;
  return (
    <Col span={2}>
      <Popover2 content={menu} popoverClassName="mb-medium" position="bottom" interactionKind="click">
        <GhostButton icon="ellipsis" className={_status !== TeamStatus.ENABLED ? 'bg-quaternary' : ''} />
      </Popover2>
    </Col>
  );
};
