import { state } from 'stores/rematch/root-store';

export const deselectedBillingItems = (paymentsList: typeof state.billingsStore.paymentsList) => {
  return paymentsList?.map((payment) => {

    const uncheckedBillingItems = payment.billingItems.map((lineItem) => {
      return { ...lineItem, selected: false };
    });
    return { ...payment, billingItems: uncheckedBillingItems, indeterminateCheck: false, checkAllIndicator: false };
  });
};
