import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Modal, Text, Table, TextInput } from '@good/ui/core';
import { Loader2Adjustable, Search } from '@good/icons';
import { RouterInputs, SilRosterData, reactTrpc } from 'utilities/react-trpc';
import {
  SilServiceContextResolved,
  useSilServiceContext,
} from 'views/sil-service/components/sil-service-provider/sil-service-provider';
import { TeamMemberRow, ITeamMember } from './team-member-row';
import { InfiniteScrollHandler } from 'views/team/listing/components/InfiniteScrollHandler';
import rootStore, { IRootState } from 'stores/rematch/root-store';
import { useSelector } from 'react-redux';
import { FilterType } from 'utilities/enum-utils';
import { debounce } from 'lodash';
import { IFilter } from 'interfaces/filter-interfaces';
import { useTranslation } from 'react-i18next';
import { EmptyTeamMembers } from './empty-team-members';
import { notifications } from '@good/ui/notifications';
import { useNewRelicBrowser } from 'integrations/new-relic-browser';

type IAddTeamMemberModalProps = {
  onClose: () => void;
  isOpen: boolean;
  rosteredTeamMembers: SilRosterData;
};

export const AddTeamMemberModal = ({ onClose, isOpen, rosteredTeamMembers }: IAddTeamMemberModalProps): JSX.Element => {
  const { service } = useSilServiceContext() as SilServiceContextResolved;
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceTeamTabs.modal' });

  const workerPageFilter = useSelector((state: IRootState) => state.teamStore.workerPageFilter);
  const setWorkerPageFilter = rootStore.dispatch.teamStore.setWorkerPageFilter;

  const _searchText = (searchTerm: string) => {
    setWorkerPageFilter([
      ...workerPageFilter.filter((viewFilter) => viewFilter.filter !== FilterType.SEARCH),
      { filter: 'search', values: searchTerm, selectionLabel: 'All' },
    ]);
  };

  const _debounceSearch = debounce(_searchText, 500);

  const _onEnterSearchText = useCallback(
    (searchTerm: string) => {
      _debounceSearch(searchTerm);
    },
    [_debounceSearch],
  );

  const [selectedMembers, setSelectedMembers] = useState<ITeamMember[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const filters = mapViewFiltersToTeamMemberSearchFilters(workerPageFilter);
  const newRelicBrowser = useNewRelicBrowser();

  const teamMemberQuery = reactTrpc.teamMembers.infiniteList.useInfiniteQuery(
    {
      limit: 25,
      filters,
      serviceProviderId: service.serviceProviderId,
    },
    { getNextPageParam: (lastPage) => lastPage.nextCursor },
  );

  const handleMemberSelect = (member: ITeamMember) => {
    setSelectedMembers((prevSelected) => [...prevSelected, member]);
  };

  const handleTeamMemberRemove = (member: ITeamMember) => {
    setSelectedMembers((prevSelected) => prevSelected.filter((item) => item !== member));
  };

  const addTeamMembersEndpoint = reactTrpc.sil.roster.add.useMutation();

  useEffect(() => {
    if (addTeamMembersEndpoint.isSuccess) {
      notifications.show({
        title: 'Team members add to roster',
        message: '',
        color: 'green',
      });
      setSelectedMembers([]);
      _onEnterSearchText('');
      onClose();
      addTeamMembersEndpoint.reset();
    }

    if (addTeamMembersEndpoint.isError) {
      notifications.show({
        title: 'Failed to add team members to roster',
        message: '',
        color: 'red',
      });
      newRelicBrowser?.noticeError('Unable to add team members to roster', { action: 'add-team-members' });
      addTeamMembersEndpoint.reset();
    }
  }, [addTeamMembersEndpoint, _onEnterSearchText, onClose, newRelicBrowser]);

  const handleSubmit = () => {
    addTeamMembersEndpoint.mutate({
      serviceProviderId: service.serviceProviderId,
      serviceId: service.serviceId,
      supportWorkerIds: selectedMembers.map((member) => member.supportWorkerId),
    });
  };

  const handleOnClose = () => {
    if (addTeamMembersEndpoint.isLoading) return;
    setSelectedMembers([]);
    _onEnterSearchText('');
    onClose();
  };

  const getKey = (supportWorkerId: string, state: string) => {
    return supportWorkerId + state;
  };

  const icon = <Search />;
  const count = () => {
    if (selectedMembers.length) {
      return `(${selectedMembers.length})`;
    }
    return '';
  };

  const buttonDisabled = () => {
    if (selectedMembers.length === 0) {
      return true;
    }
    return false;
  };

  const uniqueTeamMemberDisplay = (item: ITeamMember) => {
    const allReadyRostered = rosteredTeamMembers.find((member) => member.supportWorkerId === item.supportWorkerId);
    if (allReadyRostered) {
      return <></>;
    }
    return (
      <TeamMemberRow
        teamMember={item}
        key={getKey(item.supportWorkerId, 'ADD')}
        onSelect={handleMemberSelect}
        state='ADD'
        selectedTeamMembers={selectedMembers}
      />
    );
  };

  return (
    <>
      <Modal
        opened={isOpen}
        onClose={handleOnClose}
        size={1056}
        centered
        maxHeight={600}
        title={t('heading')}
        titleDescription={t('description')}
        confirmProps={{
          label: t('addButton'),
          onClick: handleSubmit,
          'aria-label': 'Submit',
          disabled: buttonDisabled(),
          // @ts-expect-error -- TODO: fix this
          'data-testid': 'add-button',
          loading: addTeamMembersEndpoint.isLoading,
        }}
        cancelProps={{
          label: t('cancel'),
          onClick: handleOnClose,
          'aria-label': 'Cancel',
          // @ts-expect-error -- TODO: fix this
          'data-testid': 'cancel-button',
          disabled: addTeamMembersEndpoint.isLoading,
        }}
      >
        <div className='flex-column anim-slide-left'>
          <div className='flex flex-wrap flex-row'>
            <TextInput
              leftSection={icon}
              placeholder='Search'
              size='xs'
              onChange={(e) => _onEnterSearchText(e.target.value)}
              wrapperProps={{ variant: 'no-label' }}
            />
          </div>
          <div className='flex-row'>
            <div className='flex-col w-full py-4 sm:w-1/2 lg:w-1/2 xl:w-1/2 '>
              <Text c='dimmed' size='sm' fw={600}>
                {t('teamMembers')}
              </Text>

              <div
                className='bordered rounded flex-col p-small overflow-y-auto'
                style={{ height: '422px' }}
                ref={scrollRef}
              >
                <Table.ScrollContainer minWidth={350} data-testid='team-member-table'>
                  <Table withRowBorders={false}>
                    <Table.Tbody>
                      {teamMemberQuery.isLoading ? (
                        <Table.Tr className='flex flex-col align-center'>
                          <Loader2Adjustable className='animate-spin' />
                        </Table.Tr>
                      ) : teamMemberQuery.data?.pages[0]?.items.length ? (
                        teamMemberQuery.data.pages.flatMap(({ items }) =>
                          items.map((item) => <>{uniqueTeamMemberDisplay(item)}</>),
                        )
                      ) : (
                        <EmptyTeamMembers />
                      )}
                    </Table.Tbody>
                  </Table>
                </Table.ScrollContainer>
                <InfiniteScrollHandler
                  scrollRef={scrollRef}
                  isFetching={teamMemberQuery.isFetching}
                  fetchFn={teamMemberQuery.fetchNextPage}
                />
              </div>
            </div>
            <div className='flex-col w-full p-4 sm:w-1/2 lg:w-1/2 xl:w-1/2 '>
              <Text c='dimmed' size='sm' fw={600}>
                {t('selectedTeamMembers')} {count()}
              </Text>
              <div className='bordered rounded flex-col p-small' style={{ height: '422px', overflowY: 'scroll' }}>
                <Table.ScrollContainer minWidth={350}>
                  <Table withRowBorders={false}>
                    <Table.Tbody>
                      {selectedMembers.length !== 0 &&
                        selectedMembers.map((item) => (
                          <TeamMemberRow
                            teamMember={item}
                            key={getKey(item.supportWorkerId, 'REMOVE')}
                            onSelect={handleTeamMemberRemove}
                            state='REMOVE'
                          />
                        ))}
                    </Table.Tbody>
                  </Table>
                </Table.ScrollContainer>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapViewFiltersToTeamMemberSearchFilters = (
  filters: IFilter[],
): RouterInputs['teamMembers']['infiniteList']['filters'] => {
  function getFilterValue<T = string[]>(filterType: FilterType) {
    return filters.find((filter) => filter.filter === filterType)?.values as T | undefined;
  }
  return {
    searchTerm: getFilterValue(FilterType.SEARCH),
  };
};
