import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import { IWorkflowTemplate } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';
import UpdateWorkflowTemplateManagementModal from './components/UpdateWorkflowTemplateManagementModal';
import UpdateWorkflowTemplateNameModal from './components/UpdateWorkflowTemplateNameModal';
import UpdateWorkflowTemplateNotificationsModal from './components/UpdateWorkflowTemplateNotificationsModal';
import WorkflowTemplateConfigurationSection from './components/WorkflowTemplateConfigurationSection';

interface IWorkflowTemplateDetailConfigurationPanelProps {
  workflowTemplate: IWorkflowTemplate;
  isDisabledEditStep?: boolean;
}

interface IWorkflowTemplateDetailConfigurationPanelState {
  isOpenUpdateWorkflowTemplateNameModal: boolean;
  isOpenUpdateWorkflowTemplateManagementModal: boolean;
  isOpenUpdateWorkflowTemplateNotificationsModal: boolean;
}

class WorkflowTemplateDetailConfigurationPanel extends PureComponent<
  IWorkflowTemplateDetailConfigurationPanelProps,
  IWorkflowTemplateDetailConfigurationPanelState
> {
  state = {
    isOpenUpdateWorkflowTemplateNameModal: false,
    isOpenUpdateWorkflowTemplateManagementModal: false,
    isOpenUpdateWorkflowTemplateNotificationsModal: false,
  };

  private _handleOpenUpdateWorkflowTemplateNameModal = () => {
    if (this.props.isDisabledEditStep) {
      return;
    }
    this.setState({ isOpenUpdateWorkflowTemplateNameModal: true });
  };

  private _handleCloseUpdateWorkflowTemplateNameModal = () => {
    this.setState({ isOpenUpdateWorkflowTemplateNameModal: false });
  };

  private _handleOpenUpdateWorkflowTemplateManagementModal = () => {
    if (this.props.isDisabledEditStep) {
      return;
    }
    this.setState({ isOpenUpdateWorkflowTemplateManagementModal: true });
  };

  private _handleCloseUpdateWorkflowTemplateManagementModal = () => {
    this.setState({ isOpenUpdateWorkflowTemplateManagementModal: false });
  };

  private _handleOpenUpdateWorkflowTemplateNotificationsModal = () => {
    if (this.props.isDisabledEditStep) {
      return;
    }
    this.setState({ isOpenUpdateWorkflowTemplateNotificationsModal: true });
  };

  private _handleCloseUpdateWorkflowTemplateNotificationsModal = () => {
    this.setState({ isOpenUpdateWorkflowTemplateNotificationsModal: false });
  };

  private _getStatusTextWorkflowTemplate = (status) => {
    if (status) return { label: 'Yes', color: 'text-color-green' };
    return { label: 'No', color: 'text-color-red' };
  };

  render() {
    const { workflowTemplate, isDisabledEditStep } = this.props;
    const {
      isOpenUpdateWorkflowTemplateNameModal,
      isOpenUpdateWorkflowTemplateManagementModal,
      isOpenUpdateWorkflowTemplateNotificationsModal,
    } = this.state;

    const allowApproverFlagIssueText = this._getStatusTextWorkflowTemplate(
      workflowTemplate && workflowTemplate.isAllowApproverFlagIssue,
    );
    const notifyWorkflowCompletedText = this._getStatusTextWorkflowTemplate(
      workflowTemplate && workflowTemplate.isNotifyWorkflowCompleted,
    );
    const notifyWorkflowFlaggedText = this._getStatusTextWorkflowTemplate(
      workflowTemplate && workflowTemplate.isNotifyWorkflowFlagged,
    );

    return (
      <div>
        <Text size="x2-large" weight="bolder">
          Overview
        </Text>

        <WorkflowTemplateConfigurationSection
          defaultGuideBoxContent={{
            title: 'Workflow details',
            description: 'These details describe your service, and this is how it appears to your customers',
          }}
          className="mb-x-large"
        >
          <div className="text-color-tertiary">Workflow name</div>
          <Text>{workflowTemplate ? workflowTemplate.name : ''}</Text>

          <div className="text-color-tertiary mt-large">Workflow description</div>
          <Text className="whitespace-pre-line">{(workflowTemplate && workflowTemplate.description) || 'None'}</Text>

          <div
            className={`mt-large width-fit-content ${
              isDisabledEditStep ? 'cursor-not-allowed pointer-event-none' : 'cursor-pointer'
            }`}
            onClick={this._handleOpenUpdateWorkflowTemplateNameModal}
          >
            <Icon
              type="edit"
              className={`${isDisabledEditStep ? 'text-color-tertiary' : 'text-color-blue-action'} mr-x-small`}
            />
            <Text className={isDisabledEditStep ? 'text-color-tertiary' : 'text-color-blue-action'}>Edit...</Text>
          </div>
        </WorkflowTemplateConfigurationSection>

        <WorkflowTemplateConfigurationSection
          defaultGuideBoxContent={{
            title: 'Workflow management',
            description: 'Settings related to the management of a workflow',
          }}
          className="mb-x-large"
        >
          <div className="text-color-tertiary">Allow approvers to flag issues</div>
          <Text className={`${allowApproverFlagIssueText.color} text-weight-bolder`}>
            {allowApproverFlagIssueText.label}
          </Text>

          <div
            className={`mt-large width-fit-content ${
              isDisabledEditStep ? 'cursor-not-allowed pointer-event-none' : 'cursor-pointer'
            }`}
            onClick={this._handleOpenUpdateWorkflowTemplateManagementModal}
          >
            <Icon
              type="edit"
              className={`${isDisabledEditStep ? 'text-color-tertiary' : 'text-color-blue-action'} mr-x-small`}
            />
            <Text className={isDisabledEditStep ? 'text-color-tertiary' : 'text-color-blue-action'}>Edit...</Text>
          </div>
        </WorkflowTemplateConfigurationSection>

        <WorkflowTemplateConfigurationSection
          defaultGuideBoxContent={{
            title: 'Workflow notifications',
            description: 'Settings related to notifications for a workflow',
          }}
        >
          <div className="text-color-tertiary">Notify all approvers when a workflow has been completed/closed</div>
          <Text className={`${notifyWorkflowCompletedText.color} text-weight-bolder`}>
            {notifyWorkflowCompletedText.label}
          </Text>

          <div className="text-color-tertiary mt-large">Notify all approvers when an issue is flagged</div>
          <Text className={`${notifyWorkflowFlaggedText.color} text-weight-bolder`}>
            {notifyWorkflowFlaggedText.label}
          </Text>

          <div
            className={`mt-large width-fit-content ${
              isDisabledEditStep ? 'cursor-not-allowed pointer-event-none' : 'cursor-pointer'
            }`}
            onClick={this._handleOpenUpdateWorkflowTemplateNotificationsModal}
          >
            <Icon
              type="edit"
              className={`${isDisabledEditStep ? 'text-color-tertiary' : 'text-color-blue-action'} mr-x-small`}
            />
            <Text className={isDisabledEditStep ? 'text-color-tertiary' : 'text-color-blue-action'}>Edit...</Text>
          </div>
        </WorkflowTemplateConfigurationSection>

        <UpdateWorkflowTemplateNameModal
          isOpen={isOpenUpdateWorkflowTemplateNameModal}
          workflowTemplate={workflowTemplate}
          onClose={this._handleCloseUpdateWorkflowTemplateNameModal}
        />

        <UpdateWorkflowTemplateManagementModal
          isOpen={isOpenUpdateWorkflowTemplateManagementModal}
          workflowTemplate={workflowTemplate}
          onClose={this._handleCloseUpdateWorkflowTemplateManagementModal}
        />

        <UpdateWorkflowTemplateNotificationsModal
          isOpen={isOpenUpdateWorkflowTemplateNotificationsModal}
          workflowTemplate={workflowTemplate}
          onClose={this._handleCloseUpdateWorkflowTemplateNotificationsModal}
        />
      </div>
    );
  }
}

export default WorkflowTemplateDetailConfigurationPanel;
