import { Checkbox, Form, Input, Select, Switch } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Information } from 'common-components/alerts';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import NumberInput from 'common-components/inputs/NumberInput';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { TimezoneInput } from 'common-components/timezone';
import { SubTitle, Text } from 'common-components/typography';
import { IGroupServiceSettings } from 'interfaces/service-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { Stack, Group, Switch as MantineSwitch } from '@good/ui/core';

export enum EditGroupServiceSettingsModalType {
  TIMEZONE = 'TIMEZONE',
  PUBLISH_DATE = 'PUBLISH_DATE',
  PUBLISH_RULE = 'PUBLISH_RULE',
  SERVICE_CODE = 'SERVICE_CODE',
  SITE_NAME = 'SITE_NAME',
  SITE_CODE = 'SITE_CODE',
  CAPACITY = 'CAPACITY',
  THRESHOLD = 'THRESHOLD',
  TIME_MODIFICATION = 'TIME_MODIFICATION',
  TRANSPORT = 'TRANSPORT',
  RETAIN_ACTIVITY_GROUPS = 'RETAIN_ACTIVITY_GROUPS',
}

const RECOMMENDED_TRAVEL_PRICE = 0.97;

interface IEditGroupServiceSettingsModalProps extends FormComponentProps {
  isOpen: boolean;
  editSection: EditGroupServiceSettingsModalType;
  selectedServiceSettings: IGroupServiceSettings;
  serviceId: string;
  onClose: () => void;
  doUpdateGroupServiceSettings: typeof dispatch.servicesStore.doUpdateGroupServiceSettings;
}

interface IEditGroupServiceSettingsModalState {
  isLoading: boolean;
  isSaving: boolean;
  isFixedCapacity: boolean;
  isOfferTransport: boolean;
  canWorkerEditCheckInCheckOutTime: boolean;
  isWarningTravelPriceBefore: boolean;
  publishDate: Date;
  transportPricePerKM: number;
  retainActivityGroups: boolean;
}

class EditGroupServiceSettingsModal extends Component<
  IEditGroupServiceSettingsModalProps,
  IEditGroupServiceSettingsModalState
> {
  state = {
    isLoading: false,
    isSaving: false,
    isWarningTravelPriceBefore: false,
    publishDate: moment(
      moment
        .tz(this.props.selectedServiceSettings.servicePublishDate, this.props.selectedServiceSettings.timezone)
        .format('YYYY-MM-DD HH:mm'),
    ).toDate(),
    // No this property in response, so check it by capacity value.
    isFixedCapacity: this.props.selectedServiceSettings.capacity && this.props.selectedServiceSettings.capacity > 0,
    // Bug: no this property in response, so it's always false.
    isOfferTransport: !!this.props.selectedServiceSettings.isOfferTransport,
    transportPricePerKM: this.props.selectedServiceSettings.transportPricePerKm
      ? this.props.selectedServiceSettings.transportPricePerKm
      : 0,
    canWorkerEditCheckInCheckOutTime: !!this.props.selectedServiceSettings.canWorkerEditCheckInCheckOutTime,
    retainActivityGroups: !!this.props.selectedServiceSettings.retainActivityGroups,
  };

  private reset = () => {
    this.setState({
      isLoading: false,
      isSaving: false,
      isWarningTravelPriceBefore: false,
      publishDate: moment(
        moment
          .tz(this.props.selectedServiceSettings.servicePublishDate, this.props.selectedServiceSettings.timezone)
          .format('YYYY-MM-DD HH:mm'),
      ).toDate(),
      isFixedCapacity: this.props.selectedServiceSettings.capacity && this.props.selectedServiceSettings.capacity > 0,
      isOfferTransport: !!this.props.selectedServiceSettings.isOfferTransport,
      transportPricePerKM: this.props.selectedServiceSettings.transportPricePerKm
        ? this.props.selectedServiceSettings.transportPricePerKm
        : 0,
      canWorkerEditCheckInCheckOutTime: !!this.props.selectedServiceSettings.canWorkerEditCheckInCheckOutTime,
    });
  };

  private _getModalTitle = (type: EditGroupServiceSettingsModalType) => {
    switch (type) {
      case EditGroupServiceSettingsModalType.TIMEZONE:
        return 'Edit timezone';
      case EditGroupServiceSettingsModalType.PUBLISH_DATE:
        return 'Edit publish date';
      case EditGroupServiceSettingsModalType.PUBLISH_RULE:
        return 'Edit publication rules';
      case EditGroupServiceSettingsModalType.SERVICE_CODE:
        return 'Edit service code';
      case EditGroupServiceSettingsModalType.SITE_NAME:
        return 'Edit site name';
      case EditGroupServiceSettingsModalType.SITE_CODE:
        return 'Edit site code';
      case EditGroupServiceSettingsModalType.CAPACITY:
        return 'Edit default capacity';
      case EditGroupServiceSettingsModalType.THRESHOLD:
        return 'Start/finish time threshold';
      case EditGroupServiceSettingsModalType.TIME_MODIFICATION:
        return 'Team member time modification';
      case EditGroupServiceSettingsModalType.TRANSPORT:
        return 'Transport before/after booking';
      case EditGroupServiceSettingsModalType.RETAIN_ACTIVITY_GROUPS:
        return 'Keep customers in activity groups';
      default:
        return null;
    }
  };

  private _onCloseModal = () => {
    this.reset();
    this.props.onClose();
  };

  private _onChangePublishDate = (e) => {
    this.setState({ publishDate: e });
  };

  private _onChangeIsOfferTransport = () => {
    const newOfferTransport = !this.state.isOfferTransport;
    this.setState({ isOfferTransport: newOfferTransport });
  };

  private _onChangeIsFixedCapacity = () => {
    const newFixedCapacity = !this.state.isFixedCapacity;
    this.setState({ isFixedCapacity: newFixedCapacity });
  };

  private _onChangecanWorkerEditCheckInCheckOutTime = () => {
    const newCheck = !this.state.canWorkerEditCheckInCheckOutTime;
    this.setState({ canWorkerEditCheckInCheckOutTime: newCheck });
  };

  private onChangeRetainActivityGroup = () => {
    this.setState({ retainActivityGroups: !this.state.retainActivityGroups });
  };

  private _onSave = async () => {
    if (!this._validateForm()) return;

    const payload = this._buildPayload();
    this.setState({ isSaving: true });
    // await asyncDelay(1000);
    await this.props.doUpdateGroupServiceSettings(payload);
    this.setState({ isSaving: false });
    this._onCloseModal();
  };

  private _onChangeTravelPrice = (event) => {
    if (event > 0 && event > RECOMMENDED_TRAVEL_PRICE) {
      this.setState({ isWarningTravelPriceBefore: true });
    } else {
      this.setState({ isWarningTravelPriceBefore: false });
    }
    this.setState({ transportPricePerKM: event });
  };

  private _validateForm = () => {
    const { form } = this.props;

    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    return isFormValid;
  };

  private _buildPayload = () => {
    const { editSection, selectedServiceSettings, serviceId, form } = this.props;

    switch (editSection) {
      case EditGroupServiceSettingsModalType.TIMEZONE:
        return { serviceId, timezone: form.getFieldValue('timezone') };

      case EditGroupServiceSettingsModalType.PUBLISH_DATE: {
        const { publishDate } = this.state;
        const servicePublishDate = publishDate
          ? moment.tz(moment(publishDate).format('YYYY-MM-DD HH:mm'), selectedServiceSettings.timezone).toISOString()
          : null;
        return { serviceId, servicePublishDate };
      }
      case EditGroupServiceSettingsModalType.PUBLISH_RULE: {
        const isPublicMarketplace = form.getFieldValue('publicationRule') === 'PUBLIC';
        return { serviceId, isPublicMarketplace };
      }

      case EditGroupServiceSettingsModalType.SERVICE_CODE:
        return { serviceId, serviceStockNumber: form.getFieldValue('serviceCode') };

      case EditGroupServiceSettingsModalType.SITE_NAME:
        return { serviceId, siteName: form.getFieldValue('siteName') };

      case EditGroupServiceSettingsModalType.SITE_CODE:
        return { serviceId, siteCode: form.getFieldValue('siteCode') };

      case EditGroupServiceSettingsModalType.CAPACITY: {
        const { isFixedCapacity } = this.state;
        const capacity = form.getFieldValue('capacity');
        return { serviceId, isFixedCapacity, capacity };
      }

      case EditGroupServiceSettingsModalType.THRESHOLD:
        return { serviceId, workerTimeLeeway: form.getFieldValue('workerTimeLeeway') };

      case EditGroupServiceSettingsModalType.TIME_MODIFICATION:
        return { serviceId, canWorkerEditCheckInCheckOutTime: this.state.canWorkerEditCheckInCheckOutTime };

      case EditGroupServiceSettingsModalType.TRANSPORT: {
        const { isOfferTransport, transportPricePerKM } = this.state;
        return { serviceId, isOfferTransport, transportPricePerKm: transportPricePerKM };
      }

      case EditGroupServiceSettingsModalType.RETAIN_ACTIVITY_GROUPS:
        return { serviceId, retainActivityGroups: this.state.retainActivityGroups };

      default:
        return null;
    }
  };

  private _renderModalContent = () => {
    const { form, selectedServiceSettings } = this.props;
    const { getFieldDecorator } = form;
    const timezone = selectedServiceSettings && selectedServiceSettings.timezone;

    switch (this.props.editSection) {
      case EditGroupServiceSettingsModalType.TIMEZONE:
        return (
          <>
            <SubTitle>select timezone</SubTitle>
            <Form.Item className={'m-none'}>
              {getFieldDecorator('timezone', {
                initialValue: selectedServiceSettings.timezone,
                rules: [{ required: true, message: 'Please select a timezone.' }],
              })(<TimezoneInput size={'large'} usePortal={false} />)}
            </Form.Item>
          </>
        );

      case EditGroupServiceSettingsModalType.PUBLISH_DATE: {
        const { publishDate } = this.state;
        return (
          <>
            <SubTitle>publish date</SubTitle>
            <DatePicker
              className='gh-datepicker rounded'
              calendarClassName='gh-datepicker-calendar'
              onChange={this._onChangePublishDate}
              dateFormat={'dd/MM/yyyy'}
              isClearable={false}
              selected={moment(publishDate).toDate()}
              minDate={moment.tz(timezone).add(1, 'day').startOf('day').toDate()}
            />
          </>
        );
      }
      case EditGroupServiceSettingsModalType.PUBLISH_RULE:
        return (
          <>
            <SubTitle>Select publication rule</SubTitle>
            <Form.Item className={'m-none'}>
              {getFieldDecorator('publicationRule', {
                initialValue: selectedServiceSettings.isPublicMarketplace ? 'PUBLIC' : 'PRIVATE',
                rules: [{ required: true, message: 'Please select a publication rule.' }],
              })(
                <Select size={'large'} style={{ width: '410px' }}>
                  {/* <Select.Option value={'PUBLIC'}>Public Marketplace</Select.Option> */}
                  <Select.Option value={'PRIVATE'}>Private Marketplace</Select.Option>
                </Select>,
              )}
            </Form.Item>
          </>
        );
      case EditGroupServiceSettingsModalType.SERVICE_CODE:
        return (
          <>
            <SubTitle>Service code</SubTitle>
            <Form.Item>
              {getFieldDecorator('serviceCode', {
                initialValue: selectedServiceSettings.serviceStockNumber,
                rules: [{ required: true, min: 1, max: 50, message: 'Service code length should be between 1 and 50' }],
              })(<Input size='large' style={{ width: '150px' }} />)}
            </Form.Item>
          </>
        );
      case EditGroupServiceSettingsModalType.SITE_NAME:
        return (
          <>
            <SubTitle>Site Name</SubTitle>
            <Form.Item>
              {getFieldDecorator('siteName', {
                initialValue: selectedServiceSettings.siteName,
                rules: [{ required: true, min: 1, max: 255, message: 'Site name length should be between 1 and 255' }],
              })(<Input size='large' style={{ width: '150px' }} />)}
            </Form.Item>
          </>
        );
      case EditGroupServiceSettingsModalType.SITE_CODE:
        return (
          <>
            <SubTitle>Site Code</SubTitle>
            <Form.Item>
              {getFieldDecorator('siteCode', {
                initialValue: selectedServiceSettings.siteCode,
                rules: [{ required: true, min: 1, max: 255, message: 'Site code length should be between 1 and 255' }],
              })(<Input size='large' style={{ width: '150px' }} />)}
            </Form.Item>
          </>
        );
      case EditGroupServiceSettingsModalType.CAPACITY:
        return (
          <>
            <Checkbox defaultChecked={this.state.isFixedCapacity} onChange={this._onChangeIsFixedCapacity}>
              This service has a fixed capacity
            </Checkbox>
            {this.state.isFixedCapacity ? (
              <>
                <SubTitle containerClassName='mt-medium'>CAPACITY</SubTitle>
                <Form.Item className={'m-none'}>
                  {getFieldDecorator('capacity', {
                    initialValue: selectedServiceSettings.capacity,
                    rules: [
                      {
                        required: true,
                        type: 'number',
                        min: 1,
                        max: 500,
                        message: 'Please enter a number between 1 and 500.',
                      },
                    ],
                  })(<NumberInput size='large' precision={0} style={{ width: '150px' }} />)}
                </Form.Item>
              </>
            ) : (
              <></>
            )}
          </>
        );
      case EditGroupServiceSettingsModalType.THRESHOLD: {
        const optionMinutes = [0, 5, 10, 15, 20, 25, 30];
        return (
          <>
            <SubTitle>select threshold</SubTitle>
            <Form.Item>
              {getFieldDecorator('workerTimeLeeway', {
                initialValue: selectedServiceSettings.workerTimeLeeway,
              })(
                <Select size={'large'} style={{ width: '410px' }}>
                  {_.map(optionMinutes, (minute) => (
                    <Select.Option value={minute}>{minute} minutes</Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </>
        );
      }
      case EditGroupServiceSettingsModalType.TIME_MODIFICATION: {
        const { canWorkerEditCheckInCheckOutTime } = this.state;
        return (
          <>
            <Switch
              size='small'
              checked={canWorkerEditCheckInCheckOutTime}
              onChange={this._onChangecanWorkerEditCheckInCheckOutTime}
            />
            <Text size='secondary' className='pl-small'>
              Allow team members to modify their start and finish times.
            </Text>
          </>
        );
      }
      case EditGroupServiceSettingsModalType.TRANSPORT: {
        const { isOfferTransport } = this.state;
        return (
          <>
            <Switch size='small' checked={isOfferTransport} onChange={this._onChangeIsOfferTransport} />
            <Text size='secondary' className='pl-small'>
              {' '}
              Transport before/after booking{' '}
              <span color={isOfferTransport ? 'green' : 'red'}>{isOfferTransport ? ' enabled' : ' disabled'}</span>
            </Text>
            {isOfferTransport ? (
              <>
                <div
                  className='bg-quaternary pv-small pl-small rounded mv-medium flex-row anim-slide-left'
                  style={{ width: '670px', height: '48px' }}
                >
                  <NumberInput
                    size='default'
                    addonBefore={'$'}
                    min={0}
                    max={99999}
                    style={{ width: '100px' }}
                    value={this.state.transportPricePerKM}
                    onChange={this._onChangeTravelPrice}
                  />
                  <Text className='ml-small' style={{ verticalAlign: 'middle' }}>
                    price per km.
                  </Text>
                </div>

                {this.state.isWarningTravelPriceBefore && (
                  <div className={'text-color-orange text-size-regular'}>
                    The NDIS <b>recommends</b> that cost per km do not exceed ${RECOMMENDED_TRAVEL_PRICE} for vehicles
                    not modified for accessibility.
                  </div>
                )}
              </>
            ) : (
              <Information
                className='mv-medium anim-slide-left'
                content='Disabling transport before/after booking will prevent any new transport bookings from being made.
               Any existing transport bookings will not be affected and can be completed as normal.'
              />
            )}
          </>
        );
      }
      case EditGroupServiceSettingsModalType.RETAIN_ACTIVITY_GROUPS:
        return (
          <Stack>
            <Text>
              If the toggle is turned on, we will keep the customer in their activity group, even if their booking was
              cancelled.
            </Text>
            <br />
            <Group>
              <MantineSwitch checked={this.state.retainActivityGroups} onChange={this.onChangeRetainActivityGroup} />
              <Text>Keep customer in activity group when their booking is cancelled</Text>
            </Group>
          </Stack>
        );
      default:
        return null;
    }
  };

  render() {
    const modalWidth =
      this.props.editSection === EditGroupServiceSettingsModalType.TRANSPORT ||
      this.props.editSection === EditGroupServiceSettingsModalType.TIME_MODIFICATION ||
      this.props.editSection === EditGroupServiceSettingsModalType.RETAIN_ACTIVITY_GROUPS
        ? 'large'
        : 'small';
    const footer = (
      <ActionModalFooter className='mt-large'>
        <SecondaryButton className='mr-medium' size='large' onClick={this._onCloseModal}>
          Cancel
        </SecondaryButton>
        <PrimaryButton size='large' onClick={this._onSave} disabled={this.state.isSaving}>
          Save
        </PrimaryButton>
      </ActionModalFooter>
    );

    return (
      <>
        <SpinningLoadingActionModel isOpen={this.state.isLoading} verticalAlignment={'highest'} />
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._onCloseModal}
          showCloseButton={true}
          width={modalWidth}
          className='p-none'
          title={this._getModalTitle(this.props.editSection)}
        >
          {this._renderModalContent()}
          {footer}
        </ActionModal>
      </>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateGroupServiceSettings: dispatch.servicesStore.doUpdateGroupServiceSettings,
});

export default connect(
  null,
  mapDispatch,
)(Form.create<IEditGroupServiceSettingsModalProps>()(EditGroupServiceSettingsModal));
