import { Empty } from 'antd';
import { Text } from 'common-components/typography';
import React, { PureComponent } from 'react';

interface Props {
  title: string;
  description?: string;
}

export default class ListingEmptyState extends PureComponent<Props> {
  render() {
    const { title, description } = this.props;

    return (
      <div className="flex-1 bg-white align-center flex-column">
        <div className="">
          <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
        <Text size="x2-large" color="secondary" weight="bold">
          {title}
        </Text>
        <br />
        {!!description && <Text color="secondary">{description}</Text>}
      </div>
    );
  }
}
