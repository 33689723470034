import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React, { PureComponent } from 'react';

interface Props {
  isOpen: boolean;
  isUpdating: boolean;
  onClose(): void;
  onConfirm(): void;
}

class ConfirmMovingStepModal extends PureComponent<Props> {
  render() {
    const { isOpen, isUpdating, onClose, onConfirm } = this.props;

    return (
      <ActionModal isOpen={isOpen} onClose={onClose} title="Confirm changes" verticalAlignment="high">
        <Paragraph>
          Moving this step will create a new version of this form. All workflows created from this point onwards will be
          the updated version. All existing workflows will be unaffected and remain at the current version.
        </Paragraph>
        <Paragraph className="mt-medium">Are you sure you want to move this step?</Paragraph>

        <div className="mt-x2-large flex justify-end">
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={onConfirm} loading={isUpdating}>
            Confirm
          </PrimaryButton>
        </div>
      </ActionModal>
    );
  }
}

export default ConfirmMovingStepModal;
