import { tv } from 'tailwind-variants';

import type { VariantProps } from 'tailwind-variants';

export const alert = tv(
  {
    slots: {
      actions: [],
      container: [
        'border-solid',
        'gap-2',
        'inline-flex',
        'pr-[47px]',
        'py-[15px]',
        'rounded-[6px]',
        'rounded-md',
        'w-fit',
      ],
      content: ['flex', 'flex-col', 'gap-1'],
      icon: ['inline-flex', 'items-center', 'justify-center', 'h-[22px]'],
    },
    variants: {
      emphasis: {
        fill: {
          container: ['border-l-4', 'border-y', 'border-r', 'pl-4'],
        },
        outline: {
          container: ['border', 'pl-[19px]', 'border-base', 'bg-base'],
        },
      },
      tone: {
        critical: {},
        info: {},
        notice: {},
        positive: {},
      },
    },
    compoundVariants: [
      {
        emphasis: 'fill',
        tone: 'critical',
        className: {
          actions: ['text-critical-strong'],
          container: ['border-y-critical-weak', 'border-r-critical-weak', 'border-l-critical', 'bg-critical-weak'],
          icon: ['text-critical-strong'],
        },
      },
      {
        emphasis: 'outline',
        tone: 'critical',
        className: {
          actions: ['text-critical'],
          container: ['border-critical-weak'],
          icon: ['text-critical'],
        },
      },
      {
        emphasis: 'fill',
        tone: 'info',
        className: {
          actions: ['text-info-strong'],
          container: ['border-y-info-weak', 'border-r-info-weak', 'border-l-info', 'bg-info-weak'],
          icon: ['text-info-strong'],
        },
      },
      {
        emphasis: 'outline',
        tone: 'info',
        className: {
          actions: ['text-info'],
          container: [],
          icon: ['text-info'],
        },
      },
      {
        emphasis: 'fill',
        tone: 'notice',
        className: {
          actions: ['text-notice-strong'],
          container: ['border-y-notice-weak', 'border-r-notice-weak', 'border-l-notice', 'bg-notice-weak'],
          icon: ['text-notice-strong'],
        },
      },
      {
        emphasis: 'outline',
        tone: 'notice',
        className: {
          actions: ['text-notice'],
          container: [],
          icon: ['text-notice'],
        },
      },
      {
        emphasis: 'fill',
        tone: 'positive',
        className: {
          actions: ['text-positive-strong'],
          container: ['border-y-positive-weak', 'border-r-positive-weak', 'border-l-positive', 'bg-positive-weak'],
          icon: ['text-positive-strong'],
        },
      },
      {
        emphasis: 'outline',
        tone: 'positive',
        className: {
          actions: ['text-positive'],
          container: [],
          icon: ['text-positive'],
        },
      },
    ],
    defaultVariants: {
      emphasis: 'fill',
    },
  },
  { twMerge: false },
);

type Variants = Required<VariantProps<typeof alert>>;

export type AlertVariants = {
  /**
   * Visual important of the alert.
   * @default fill
   */
  emphasis?: Variants['emphasis'];
  /**
   * Visually change the color and icon based on semantic meaning of
   * alert.
   */
  tone: Variants['tone'];
};
