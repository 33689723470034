import { Avatar, Icon, Row } from 'antd';
import { Text } from 'common-components/typography';
import { IUserInvolved } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';

interface Props {
  users: IUserInvolved[];
  userType: 'CUSTOMER' | 'TEAM_MEMBER';
  onShowUserListModal?(modalType: string): void;
}

class CustomerAndTeamMemberList extends PureComponent<Props> {
  private _onOpenCustomerAndTeamMemberModal = () => {
    this.props.onShowUserListModal(this.props.userType);
  };

  render() {
    const { users, userType } = this.props;
    const isShowMoreUsers = users && users.length > 2;
    const othersAmount = isShowMoreUsers && users.length - 2;
    const othersUserLabel = userType === 'CUSTOMER' ? 'customer' : 'team member';
    const sortedUsers = users && users.sort((a, b) => a.displayName.localeCompare(b.displayName));

    return (
      <>
        {sortedUsers && sortedUsers.length > 0 ? (
          sortedUsers.map((user, index) => {
            if (index < 2)
              return (
                <Row
                  type="flex"
                  align="middle"
                  className={`${index !== 0 ? 'mt-small' : 'mt-x-small'}`}
                  key={user.userId}
                >
                  <Avatar shape="circle" icon={<Icon type="user" />} size={32} className="mr-small" src={user.avatar} />
                  <Text>{user.displayName}</Text>
                </Row>
              );
          })
        ) : (
          <Text>No {othersUserLabel}s involved</Text>
        )}
        {sortedUsers && sortedUsers.length > 2 && (
          <Row
            type="flex"
            align="middle"
            className="mt-small cursor-pointer"
            onClick={this._onOpenCustomerAndTeamMemberModal}
          >
            <Row
              type="flex"
              align="middle"
              justify="center"
              className="bg-tertiary bordered mr-small"
              style={{ width: '32px', height: '32px', borderRadius: '50%', borderColor: 'rgba(0, 0, 0, 0.15)' }}
            >
              <Icon type="team" />
            </Row>
            <Text className="text-color-blue-action">{`${othersAmount} other ${othersUserLabel}${
              othersAmount !== 1 ? 's' : ''
            }`}</Text>
          </Row>
        )}
      </>
    );
  }
}

export default CustomerAndTeamMemberList;
