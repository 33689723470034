import React, { forwardRef } from 'react';

import { Select, type SelectProps } from '@good/ui/core';
import { type CountryCode, useLocaleConfig } from 'providers/locale-provider';

type SelectPhoneCountryCodeProps = Omit<SelectProps, 'value' | 'onChange' | 'data' | 'allowDeselect' | 'clearable'> & {
  value?: CountryCode;
  onChange?: (value: CountryCode | null) => void;
};

/**
 * Mantine component for selecting phone number country codes.
 */
export const SelectPhoneCountryCode = forwardRef<HTMLInputElement, SelectPhoneCountryCodeProps>((props, ref) => {
  const { available: availableLocaleConfigs, current: currentLocaleConfig } = useLocaleConfig();

  return (
    <Select
      ref={ref}
      miw={120}
      w={120}
      defaultValue={currentLocaleConfig.countryCode}
      {...props}
      onChange={(value) => props.onChange?.(value as CountryCode)}
      data={availableLocaleConfigs.map((localeConfig) => ({
        value: localeConfig.countryCode,
        label: localeConfig.phone.label,
      }))}
      allowDeselect={false}
      clearable={false}
    />
  );
});

SelectPhoneCountryCode.displayName = 'SelectPhoneCountryCode';
