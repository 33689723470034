import * as H from 'history';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import AccessDeniedView from 'views/exceptions/AccessDeniedView';
import PaymentFailedBanner from './components/PaymentFailedBanner';
import BillingHistoryPanel from './components/tabs-panel/billing-history/BillingHistoryPanel';
import OverviewPanel from './components/tabs-panel/overview/OverviewPanel';
import PaymentMethodPanel from './components/tabs-panel/payment-method/PaymentMethodPanel';
import { SUBSCRIPTION_MANAGEMENT_TABS, SUBSCRIPTION_MANAGEMENT_TAB_KEY } from './utils/constants';
import { Link } from 'react-router-dom';
import { Anchor, Breadcrumbs, Stack, Tabs } from '@good/ui/core';
import { Header, Heading, Text } from '@good/components';

interface ISubscriptionManagementViewProps {
  history: H.History;
}

const SubscriptionManagementView: React.FC<ISubscriptionManagementViewProps> = ({ history }) => {
  const { portalUser, companyDataLite } = useSelector((state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    companyDataLite: state.companyStore.companyDataLite,
  }));

  const hasSubscriptionPermission = companyDataLite?.hasSubscription;

  const subscriptionTitle = `${portalUser?.serviceProviderName}'${
    _.endsWith(portalUser?.serviceProviderName, 's') ? '' : 's'
  } GoodHuman subscription`;

  if (companyDataLite && !hasSubscriptionPermission) return <AccessDeniedView history={history} />;

  return (
    <Stack gap='xl'>
      <Stack>
        <Breadcrumbs>
          <Anchor component={Link} to='/account/landing'>
            Account management
          </Anchor>
          <Text>Subscription</Text>
        </Breadcrumbs>

        <Header size='xl'>
          <Heading>{subscriptionTitle}</Heading>
          <Text>Manage your GoodHuman subscription and access to billing information.</Text>
        </Header>
      </Stack>

      <PaymentFailedBanner />

      <Tabs defaultValue={SUBSCRIPTION_MANAGEMENT_TAB_KEY.OVERVIEW}>
        <Tabs.List>
          {SUBSCRIPTION_MANAGEMENT_TABS.map((tab) => (
            <Tabs.Tab key={tab.key} value={tab.key}>
              {tab.title}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        <Tabs.Panel value={SUBSCRIPTION_MANAGEMENT_TAB_KEY.OVERVIEW}>
          <OverviewPanel history={history} />
        </Tabs.Panel>

        <Tabs.Panel value={SUBSCRIPTION_MANAGEMENT_TAB_KEY.PAYMENT_METHOD}>
          <PaymentMethodPanel history={history} />
        </Tabs.Panel>

        <Tabs.Panel value={SUBSCRIPTION_MANAGEMENT_TAB_KEY.BILLING_HISTORY}>
          <BillingHistoryPanel />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
};

export default SubscriptionManagementView;
