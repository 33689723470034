import React, { Component } from 'react';
import { IMenuProps, Menu } from '@blueprintjs/core';

class ActionMenu extends Component<IMenuProps, any> {
  render() {
    const { className } = this.props;

    return <Menu {...this.props} className={`ph-none pv-small ${className}`} />;
  }
}

export default ActionMenu;
