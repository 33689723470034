import { Icon } from '@blueprintjs/core';
import { Checkbox, Col, DatePicker, Form, Icon as Icon2, Input, Modal, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import { Warning } from 'common-components/alerts';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import TimeInput from 'common-components/time-input/TimeInput';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { IActivityGroup } from 'interfaces/service-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { AddingActivityGroupItemType } from 'utilities/enum-utils';
import ActivityGroupRowItemEditDirect from '../components/ActivityGroupRowItemEditDirect';

const { Search } = Input;

interface IActivityGroupInSearch extends IActivityGroup {
  isExisted: boolean;
}

interface IAddActivityGroupModalProps extends FormComponentProps {
  onClose: () => void;
  onConfirm: (activities: IActivityGroup[]) => void;
  isOpen: boolean;
  scheduleEndDate: any;
  scheduleStartDate: any;
  currentActivityGroupsList: IActivityGroup[];
  doFetchActivityGroup: typeof dispatch.groupServiceStore.doFetchActivityGroup;
  activityGroupsList: typeof state.groupServiceStore.activityGroupsList;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  isInSchedule?: boolean;
}

interface IAddActivityGroupModalState {
  currentStartDate: any;
  currentEndDate: any;
  isEndTimeBeforeStartTimeError: boolean;
  isSameTime: boolean;
  isSearching: boolean;
  isLoading: boolean;
  activityGroupSelectedList: string[];
  activityGroupFilteredList: IActivityGroupInSearch[];
  activityGroupAddedList: IActivityGroup[];
  searchingValue: string;
  addingType: AddingActivityGroupItemType;
  isChecked: boolean;
  isSubmitting: boolean;
}

function AddActivityGroupModal({
  isOpen,
  onClose,
  scheduleEndDate,
  scheduleStartDate,
  form,
  activityGroupsList,
  doFetchActivityGroup,
  selectedGroupService,
  currentActivityGroupsList,
  onConfirm,
  isInSchedule,
}: IAddActivityGroupModalProps) {
  const initState = useRef<IAddActivityGroupModalState>({
    currentStartDate: scheduleStartDate,
    currentEndDate: scheduleEndDate,
    isEndTimeBeforeStartTimeError: false,
    isSameTime: true,
    isSearching: false,
    isLoading: false,
    activityGroupSelectedList: [],
    activityGroupFilteredList: [],
    activityGroupAddedList: [],
    searchingValue: null,
    addingType: isInSchedule ? AddingActivityGroupItemType.FROM_TEMPLATE : AddingActivityGroupItemType.CREATE_NEW,
    isChecked: true,
    isSubmitting: false,
  });

  const [state, setState] = useState<IAddActivityGroupModalState>(initState.current);

  const {
    addingType,
    currentEndDate,
    currentStartDate,
    isEndTimeBeforeStartTimeError,
    isLoading,
    isSameTime,
    isSearching,
    activityGroupAddedList,
    activityGroupFilteredList,
    activityGroupSelectedList,
    searchingValue,
    isChecked,
    isSubmitting,
  } = state;

  const searchRef = useRef(null);

  const { getFieldDecorator } = form;

  const activityGroupSearchList = useMemo<IActivityGroupInSearch[]>(() => {
    return _.chain(activityGroupsList)
      .map((activity) => {
        let isExisted = true;
        if (
          _.isEmpty(
            _.find(
              currentActivityGroupsList,
              (currentActivity) => currentActivity.serviceActivityGroupId === activity.serviceActivityGroupId,
            ),
          )
        ) {
          isExisted = false;
        }
        return { ...activity, isExisted };
      })
      .filter((activity) =>
        _.isEmpty(
          _.find(
            activityGroupAddedList,
            (addedActivity) => addedActivity.serviceActivityGroupId === activity.serviceActivityGroupId,
          ),
        ),
      )
      .value();
  }, [activityGroupsList, activityGroupAddedList, currentActivityGroupsList]);

  const _roundDate = (date) => moment(CommonUtils.formatCeilingDateTime(date));

  const _handleChangeStartingDate = (startingDate) => {
    const roundedValue = _roundDate(moment(startingDate));
    setState({
      ...state,
      currentStartDate: moment(roundedValue),
    });
  };

  const _handleChangeEndingDate = (endingDate) => {
    const roundedValue = _roundDate(moment(endingDate));
    setState({
      ...state,
      currentEndDate: moment(roundedValue),
    });
  };

  const _resetStates = () => {
    form.resetFields();
    setState(initState.current);
  };

  const _handleCloseModal = () => {
    _resetStates();
    onClose();
  };

  const _handleAddActivityGroup = () => {
    let isFormValid = true;
    setState({ ...state, isSubmitting: true });
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (!isEndTimeBeforeStartTimeError && isFormValid) {
      let listToAdd: IActivityGroup[];

      if (addingType === AddingActivityGroupItemType.FROM_TEMPLATE) {
        listToAdd = activityGroupAddedList;
      } else {
        listToAdd = [
          {
            name: form.getFieldValue('groupName'),
            description: form.getFieldValue('description'),
            startDateTime: currentStartDate,
            endDateTime: currentEndDate,
          },
        ];
      }
      onConfirm(listToAdd);
      _resetStates();
    }

    setState({ ...state, isSubmitting: false });
  };

  const _handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      setState({
        ...state,
        currentStartDate: scheduleStartDate,
        currentEndDate: scheduleEndDate,
        isChecked: e.target.checked,
      });
    } else {
      setState({
        ...state,
        isChecked: e.target.checked,
      });
    }
  };

  const _handleChangeSearchValue = (e) => {
    const searchValue = _.get(e, 'target.value', '');

    setState({
      ...state,
      activityGroupFilteredList: _.chain(activityGroupSearchList)
        .filter((activity) => activity.name.toLowerCase().includes(searchValue.toLowerCase()))
        .orderBy('name', 'asc')
        .value(),
      isSearching: true,
      searchingValue: searchValue,
    });
  };

  const _handleAddActivityGroupSelected = () => {
    if (!_.isEmpty(activityGroupSelectedList)) {
      const listForAdd = _.chain(activityGroupsList)
        .filter((activity) => activityGroupSelectedList.includes(activity.serviceActivityGroupId))
        .map((activity) => ({ ...activity, startDateTime: scheduleStartDate, endDateTime: scheduleEndDate }))
        .value();

      setState((preState) => ({
        ...state,
        activityGroupAddedList: _.uniqBy(
          [...preState.activityGroupAddedList, ...listForAdd],
          (activity) => activity.serviceActivityGroupId,
        ),
        isSearching: false,
        searchingValue: null,
      }));
    } else {
      setState({ ...state, isSearching: false, searchingValue: null });
    }
  };

  const _handleChangeRowItem = (item: IActivityGroup, isValid: boolean) => {
    setState({
      ...state,
      activityGroupAddedList: _.map(activityGroupAddedList, (activity) => {
        if (activity.serviceActivityGroupId === item.serviceActivityGroupId) {
          return item;
        }
        return activity;
      }),
      isEndTimeBeforeStartTimeError: isValid,
    });
  };

  const _handleChangeAddingType = async (type: AddingActivityGroupItemType) => {
    if (type === AddingActivityGroupItemType.FROM_TEMPLATE && _.isEmpty(activityGroupsList)) {
      setState({ ...state, isLoading: true });
      await doFetchActivityGroup({ serviceId: selectedGroupService.serviceId });
      setState({ ...state, isLoading: false, addingType: type });
    } else {
      setState({ ...state, addingType: type });
    }
  };

  const _handleRemoveRowItem = (serviceActivityGroupId: string) => {
    setState({
      ...state,
      activityGroupSelectedList: _.remove(
        activityGroupSelectedList,
        (activityId) => activityId !== serviceActivityGroupId,
      ),
      activityGroupAddedList: _.remove(
        activityGroupAddedList,
        (item) => item.serviceActivityGroupId !== serviceActivityGroupId,
      ),
    });
  };

  useEffect(() => {
    setState({
      ...state,
      isEndTimeBeforeStartTimeError: moment(currentEndDate).isBefore(currentStartDate),
      isSameTime:
        moment(currentStartDate).isSameOrAfter(scheduleStartDate) &&
        moment(currentEndDate).isSameOrBefore(scheduleEndDate),
      isChecked: moment(currentStartDate).isSame(scheduleStartDate) && moment(currentEndDate).isSame(scheduleEndDate),
    });
  }, [currentStartDate, currentEndDate]);

  useEffect(() => {
    setState({ ...state, currentStartDate: scheduleStartDate, currentEndDate: scheduleEndDate });
  }, [scheduleStartDate, scheduleEndDate]);

  useEffect(() => {
    function _onMouseDown(event) {
      // handle mouse outside control
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setState({
          ...state,
          isSearching: false,
          searchingValue: null,
        });
      }
    }
    document.addEventListener('mousedown', _onMouseDown);
    return () => {
      document.removeEventListener('mousedown', _onMouseDown);
    };
  }, [searchRef, state]);

  return (
    <Modal
      visible={isOpen}
      onCancel={_handleCloseModal}
      title={<Title level={4}>Add activity group</Title>}
      footer={null}
      style={{ minWidth: '880px' }}
      className="ant-modal-custom"
    >
      <div>
        <SubTitle>Select activity group</SubTitle>
      </div>
      <div className="flex-row align-center">
        {!isInSchedule && (
          <div
            className={`mr-large mb-small bordered rounded p-medium ${
              addingType === AddingActivityGroupItemType.CREATE_NEW && 'bg-blue-lightest border-blue-lighter'
            }`}
            style={{ maxWidth: '328px', minHeight: '88px' }}
            onClick={() => _handleChangeAddingType(AddingActivityGroupItemType.CREATE_NEW)}
          >
            <Row>
              <Col span={20}>
                <Text weight="bold">Create new activity group</Text>
              </Col>
              <Col span={4} className="text-align-right">
                {addingType === AddingActivityGroupItemType.CREATE_NEW ? (
                  <Icon icon="tick-circle" color="#0083FF" iconSize={21} />
                ) : (
                  <Icon icon="circle" color="#EEEEEE" iconSize={21} />
                )}
              </Col>
            </Row>
            <Paragraph color="secondary" className="mb-none">
              Create new activity groups just for this session.
            </Paragraph>
          </div>
        )}

        <div
          className={`mr-large mb-small bordered rounded p-medium ${
            addingType === AddingActivityGroupItemType.FROM_TEMPLATE && 'bg-blue-lightest border-blue-lighter'
          }`}
          style={{ maxWidth: '328px', minHeight: '88px' }}
          onClick={() => _handleChangeAddingType(AddingActivityGroupItemType.FROM_TEMPLATE)}
        >
          <Row>
            <Col span={20}>
              <Text weight="bold">Add activity group from template</Text>
            </Col>
            <Col span={4} className="text-align-right">
              {addingType === AddingActivityGroupItemType.FROM_TEMPLATE ? (
                <Icon icon="tick-circle" color="#0083FF" iconSize={21} />
              ) : (
                <Icon icon="circle" color="#EEEEEE" iconSize={21} />
              )}
            </Col>
          </Row>
          <Paragraph color="secondary" className="mb-none">
            Use existing activity groups from the service template.
          </Paragraph>
        </div>
      </div>
      {addingType === AddingActivityGroupItemType.CREATE_NEW ? (
        <>
          {!isSameTime && (
            <Warning
              color="orange"
              borderNone={true}
              className="mb-small bg-orange-lightest rounded-big bordered border-orange-lighter"
              content={
                <>
                  <Text weight="bold" className="block">
                    Activity time is outside the session time
                  </Text>
                  <Text>
                    This activity group doesn’t fit within the session timeframe. Please make sure your activity runs
                    within the session.
                  </Text>
                </>
              }
            />
          )}

          <div className="mt-large">
            <SubTitle>Group name</SubTitle>
            <Form.Item>
              {getFieldDecorator('groupName', {
                rules: [{ required: true, message: 'Please enter a group' }],
                initialValue: null,
              })(
                <Input placeholder="Enter a group name" maxLength={50} style={{ height: '40px', maxWidth: '540px' }} />,
              )}
            </Form.Item>
          </div>

          <div className="mt-large">
            <SubTitle>Description (optional)</SubTitle>
            <Form.Item>
              {getFieldDecorator('description', {
                initialValue: null,
              })(
                <Input.TextArea
                  placeholder="Tell people a bit about this group..."
                  style={{ maxWidth: '540px', minHeight: '98px' }}
                />,
              )}
            </Form.Item>
          </div>

          <div className="flex-row align-center">
            <div className="mr-large mb-large">
              <SubTitle>Start Date</SubTitle>
              <div className="flex-row align-left">
                <DatePicker
                  size="large"
                  format={'DD/MM/YYYY'}
                  allowClear={false}
                  value={moment(currentStartDate)}
                  disabledDate={(current) => {
                    return current < moment().startOf('day');
                  }}
                  onChange={(event) => _handleChangeStartingDate(event)}
                  className="mr-small"
                />
              </div>
            </div>
            <div className="mb-large">
              <SubTitle>End Date</SubTitle>
              <div className="flex-row align-left">
                <DatePicker
                  size="large"
                  value={moment(currentEndDate)}
                  allowClear={false}
                  format={'DD/MM/YYYY'}
                  disabledDate={(current) => {
                    return current < moment(currentStartDate).startOf('day');
                  }}
                  onChange={(event) => _handleChangeEndingDate(event)}
                  className="mr-small"
                />
              </div>
            </div>
          </div>

          <div className="flex-row align-center">
            <div className="mb-small">
              <SubTitle>Start Time</SubTitle>
              <div className="flex-row align-left">
                <TimeInput
                  size="large"
                  value={moment(currentStartDate)}
                  onChange={(event) => _handleChangeStartingDate(event)}
                />
              </div>
            </div>
            <div className="mh-medium text-color-gray-dark">to</div>
            <div className="mb-small">
              <SubTitle>End Time</SubTitle>
              <div className="flex-row align-left">
                <TimeInput
                  size="large"
                  value={moment(currentEndDate)}
                  onChange={(event) => _handleChangeEndingDate(event)}
                />
              </div>
            </div>
          </div>

          {isEndTimeBeforeStartTimeError && (
            <Text color="red-dark" style={{ lineHeight: '200%' }} className="block">
              End time must be after the start time.
            </Text>
          )}

          {!isSameTime && (
            <div>
              <Icon2 type="warning" theme="filled" className="mr-small text-color-warning-orange" />
              <Text type="warning">Activity time doesn’t fit within the session timeframe</Text>
            </div>
          )}

          <Checkbox checked={isChecked} onChange={_handleChangeCheckbox}>
            Make same as session time
          </Checkbox>
        </>
      ) : (
        <>
          <SubTitle>Assign activity group</SubTitle>
          {isLoading && <SpinningLoader size={100} message="Loading" />}
          <div
            ref={searchRef}
            className="bg-quaternary ph-medium pv-small"
            style={{ maxWidth: '580px', maxHeight: '703px' }}
          >
            <Row className="rounded">
              <Col span={20}>
                <Search
                  onFocus={_handleChangeSearchValue}
                  onChange={_handleChangeSearchValue}
                  placeholder="Search for activity group..."
                  allowClear
                  value={searchingValue}
                />
                {isSearching && (
                  <div className="bg-white width-full p-medium bordered rounded overflow-y-scroll">
                    <CheckboxGroup
                      onChange={(value: string[]) => {
                        setState({ ...state, activityGroupSelectedList: value });
                      }}
                      style={{ maxHeight: '210px' }}
                      defaultValue={activityGroupSelectedList}
                    >
                      {!_.isEmpty(activityGroupFilteredList) ? (
                        _.map(activityGroupFilteredList, (activity, index) => (
                          <div className="bordered-bottom" style={{ width: '400px' }} key={index}>
                            <Checkbox
                              value={activity.serviceActivityGroupId}
                              className="text-weight-bold text-overflow-ellipsis overflow-hidden whitespace-nowrap width-full"
                              disabled={activity.isExisted}
                            >
                              {activity.name} {activity.isExisted && ' (Already added)'}
                            </Checkbox>
                            <Paragraph
                              color={activity.isExisted ? 'light-gray' : 'secondary'}
                              className="ml-large mb-small"
                            >
                              {activity.description}
                            </Paragraph>
                          </div>
                        ))
                      ) : (
                        <Paragraph color="light-gray" className="ml-large">
                          No result found...
                        </Paragraph>
                      )}
                    </CheckboxGroup>
                  </div>
                )}
              </Col>
              <Col span={4}>
                <PrimaryButton className="ml-medium" size="default" onClick={_handleAddActivityGroupSelected}>
                  Add
                </PrimaryButton>
              </Col>
            </Row>
            <Row className="text-color-secondary mt-small">Activity groups added ({activityGroupAddedList.length})</Row>
            {!isSearching && !_.isEmpty(activityGroupAddedList) && (
              <div className="overflow-y-scroll mb-small" style={{ maxHeight: '600px' }}>
                {_.map(activityGroupAddedList, (activity, index) => (
                  <ActivityGroupRowItemEditDirect
                    key={index}
                    scheduleStartDate={scheduleStartDate}
                    scheduleEndDate={scheduleEndDate}
                    item={activity}
                    onChange={(item, isValid) => _handleChangeRowItem(item, isValid)}
                    onRemove={(serviceActivityGroupId) => _handleRemoveRowItem(serviceActivityGroupId)}
                    isInSchedule={isInSchedule}
                  />
                ))}
              </div>
            )}
          </div>
        </>
      )}
      <ActionModalFooter>
        <SecondaryButton size="large" onClick={_handleCloseModal}>
          Cancel
        </SecondaryButton>
        <PrimaryButton className="ml-medium" size="large" onClick={_handleAddActivityGroup} loading={isSubmitting}>
          Add activity group
        </PrimaryButton>
      </ActionModalFooter>
    </Modal>
  );
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchActivityGroup: dispatch.groupServiceStore.doFetchActivityGroup,
});

const mapState = (state: IRootState) => ({
  activityGroupsList: state.groupServiceStore.activityGroupsList,
  selectedGroupService: state.groupServiceStore.selectedGroupService,
});

export default connect(mapState, mapDispatch)(Form.create<IAddActivityGroupModalProps>()(AddActivityGroupModal));
