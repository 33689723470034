import { tv } from 'tailwind-variants';

export const header = tv(
  {
    slots: {
      container: ['flex', 'flex-row', 'items-center', 'justify-between', 'w-full'],
      content: ['flex', 'flex-col', 'gap-1'],
    },
  },
  { twMerge: false },
);
