import React from 'react';
import { useHistory } from 'react-router-dom';

import { Center, SegmentedControl, Stack, Box } from '@good/ui/core';
import { PageHeader } from '@good/ui/templates';
import { PageContainer } from 'layouts/page-container';
import BookingListingV3View from './BookingListingV3View';
import RosterLandingView from './roster-view/RosterLandingView';
import { useSearchParam } from 'utilities/react-hooks/use-search-param';
import { Calendar, Book } from '@good/icons';
import { useTranslation } from 'react-i18next';

type View = 'list' | 'calendar';

type BookingsViewSwitchProps = {
  view: View;
};

export const BookingsViewSwitch = ({ view }: BookingsViewSwitchProps) => {
  const history = useHistory();

  switch (view) {
    case 'list':
      return (
        <PageContainer pt={0}>
          <BookingListingV3View history={history} />
        </PageContainer>
      );
    case 'calendar':
      return <RosterLandingView history={history} />;
    default:
      return (
        <PageContainer pt={0}>
          <BookingListingV3View history={history} />
        </PageContainer>
      );
  }
};

export const BookingsView = () => {
  const [view, setView] = useSearchParam<View>('view', 'list');
  const { t } = useTranslation('', { keyPrefix: 'bookings' });

  return (
    <Stack gap={0}>
      <PageContainer pb={0}>
        <PageHeader
          title={t('title')}
          subtitle={t('subtitle')}
          actions={
            <Box>
              <SegmentedControl
                value={view}
                onChange={(view) => setView(view as View)}
                data={[
                  {
                    label: (
                      <Center style={{ gap: 10 }}>
                        <Book />
                        <span>{t('views.all')}</span>
                      </Center>
                    ),
                    value: 'list',
                  },
                  {
                    label: (
                      <Center style={{ gap: 10 }}>
                        <Calendar />
                        <span>{t('views.calendar')}</span>
                      </Center>
                    ),
                    value: 'calendar',
                  },
                ]}
                color='ocean'
                fullWidth
                w={{ base: '100%', md: 400 }}
              />
            </Box>
          }
        />
      </PageContainer>
      <BookingsViewSwitch view={view} />
    </Stack>
  );
};
