import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@good/components';
import { WriteLetter } from '@good/illustrations';

export const TableEmpty = () => {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets.table.empty' });

  return (
    <div data-testid="timesheets-table-empty">
      <div className="flex-column mt-12 items-center">
        <div className="p-auto">
          <WriteLetter size="md" />
        </div>
        <div className="mt-12">
          <Text className="font-bold">{t('title')}</Text>
        </div>
        <div className="mt-2">
          <Text>{t('description')}</Text>
        </div>
      </div>
    </div>
  );
};
