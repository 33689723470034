import { filterDOMProps } from '@react-aria/utils';
import { forwardRef } from 'react';
import { label } from './label.css';

import type { LabelVariants } from './label.css';
import type { PropsWithChildren } from 'react';
import { HTMLProps } from '../types';

export type LabelProps = PropsWithChildren<HTMLProps<HTMLDivElement>> & LabelVariants;

export const Label = forwardRef<HTMLDivElement, LabelProps>((props, ref) => {
  const { children, emphasis, tone, size = 'md', ...otherProps } = props;

  return (
    <div {...filterDOMProps(otherProps)} ref={ref} className={label({ size, emphasis, tone })}>
      {children}
    </div>
  );
});

Label.displayName = 'Label';
