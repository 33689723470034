import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import _ from 'lodash';
import { Spinner } from '@blueprintjs/core';

interface IRenderAvailabilityProps {
  availableTimes: any[];
  cycle: string;
  activeWeek: number;
  activeDate: any;
  isLoading: boolean;
}

interface IRenderAvailabilityState {
  showSwitchCycleModal: boolean;
}

class RenderAvailability extends Component<IRenderAvailabilityProps, IRenderAvailabilityState> {
  state = {
    showSwitchCycleModal: false,
  };

  private _isNoon = (time) => {
    const timeString = moment(time).format('hh:mm a');
    const noonTime = moment(12, 'hh').format('hh:mm a');
    return timeString === noonTime;
  };

  private _renderAbilityItem = (availableTime, isActive?: boolean) => {
    return (
      availableTime && (
        <div className="pv-large">
          {availableTime.isAvailableAllDay ? (
            <Text className="text-color-green text-size-regular">Available 24 hours</Text>
          ) : availableTime.timeRange.length > 0 ? (
            _.map(availableTime.timeRange, (time, index) => (
              <Text
                className="block mb-x-small text-size-regular"
                key={`${availableTime.day}-${index + 1}`}
                color={isActive ? 'secondary' : 'primary'}
              >
                {moment(time.startDateTime).format('h:mm a')} - {moment(time.endDateTime).format('h:mm a')}{' '}
                {this._isNoon(time.endDateTime) && (
                  <Text className="text-color-secondary text-size-regular">(noon)</Text>
                )}
              </Text>
            ))
          ) : (
            <Text className="text-color-orange-dark text-size-regular">Unavailable</Text>
          )}
        </div>
      )
    );
  };

  private _renderWeekly = (availableTimes) => {
    return availableTimes.map((availableTime, index) => (
      <Row className="bordered-bottom" key={index}>
        <Col span={8}>
          <div className="pv-large pl-medium">
            <Text className="text-size-regular">{availableTime.day}</Text>
          </div>
        </Col>
        <Col span={14}>{this._renderAbilityItem(availableTime)}</Col>
      </Row>
    ));
  };

  private _renderFortnight = (availableTimes) => {
    const { activeWeek } = this.props;

    const weekOne = _.filter(availableTimes, (item) => item.week === 1);
    const weekTwo = _.filter(availableTimes, (item) => item.week === 2);

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    return (
      <div>
        {weekOne.map((availableTime, index) => (
          <Row className="bordered-bottom evenodd bg-white" key={index} gutter={[20, 0]} type="flex">
            <Col span={6} className="bg-white">
              <div className="pv-large pl-medium">
                {availableTime.day ? (
                  <Text className="text-size-regular">{availableTime.day}</Text>
                ) : (
                  <Text className="text-size-regular">{days[index]}</Text>
                )}
              </div>
            </Col>
            <Col span={9} className="bg-white">
              {this._renderAbilityItem(availableTime, activeWeek === 1)}
            </Col>
            <Col span={9} className="bg-white">
              {this._renderAbilityItem(weekTwo[index], activeWeek === 2)}
            </Col>
          </Row>
        ))}
      </div>
    );
  };

  render() {
    const { availableTimes, cycle, isLoading } = this.props;

    return (
      <div>
        {isLoading ? (
          <Spinner size={80} />
        ) : (
          <>{cycle === 'weekly' ? this._renderWeekly(availableTimes) : this._renderFortnight(availableTimes)}</>
        )}
      </div>
    );
  }
}

export default RenderAvailability;
