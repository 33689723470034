import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Box } from '@goodhuman-me/components';
import { notification } from 'antd';
import { Spinner } from '@blueprintjs/core';
import { Modal, Text } from 'design-components';
import { ShiftSlotStatus } from 'utilities/enum-utils';
import { useMutationCancelBooking } from 'stores/hooks/query-hooks/use-mutation-cancel-booking';
import { useMutationCancelGroupBooking } from 'stores/hooks/query-hooks/use-mutation-cancel-group-booking';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { IBooking } from 'interfaces/booking-interfaces';
import BookingCancelActionModalBookingView from '../BookingCancelActionModalBookingView';
import BookingCancelActionModalPaymentView from '../BookingCancelActionModalPaymentView';
import BookingCancelActionModalSuccessView from '../BookingCancelActionModalSuccessView';
import { ConditionallyHidden } from '../components';
import { Schema } from '../schema';
import cancelBooking from '../cancel-booking';
import fields from '../fields';
import editCancelledBooking from './editCancelledBooking';
import { useFlags } from 'launchdarkly-react-client-sdk';
import '../styles.css';

export const testId = 'booking-edit-cancel-action-modal';
export interface IOnSubmitBookingEditCancellationResult {
  isCancelStep: boolean;
  isCancelStepInputValid: boolean;
  isPaymentStep: boolean;
  isPaymentStepInputValid: boolean;
  formValues: Schema;
}
interface IBookingEditCancelActionModalProps {
  onClose: () => void;
  isOpen: boolean;
  onSubmit?: (result: IOnSubmitBookingEditCancellationResult) => void;
  serviceType: string;
}

// TODO: Replace this with a spinner that matches the design system or add a loading state to the continue button
const SpinnerContainer = () => (
  <div className="loading-spinner">
    <Spinner size={80} />
  </div>
);

export const BookingEditCancellationActionModalV2 = ({
  onClose: onCloseProp,
  onSubmit,
  isOpen,
  serviceType,
}: IBookingEditCancelActionModalProps): JSX.Element => {
  const { selectedBookingItem: selectedBooking } = useSelector(
    (state: IRootState) => state.bookingsStore,
  ) as unknown as IBooking;
  const { selectedGroupBookingItem: selectedGroupBooking } = useSelector(
    (state: IRootState) => state.groupBookingsStore,
  ) as unknown as IBooking;
  const {
    bookingsStore: { setSelectedBookingItem },
  } = useDispatch<IRootDispatch>();
  const {
    groupBookingsStore: { doFetchGroupBookingOverview },
  } = useDispatch<IRootDispatch>();

  const { t } = useTranslation('', { keyPrefix: 'bookings.modals.cancelBooking' });

  const [title, setTitle] = useState('');
  const [hasCancelledBookingSuccessfully, setHasCancelledBookingSuccessfully] = useState(false);
  const [isCancellingBooking, setIsCancellingBooking] = useState(false);
  const [hasError, setHasError] = useState(null);

  const [isCancelStep, setIsCancelStep] = useState(true);
  const [isCancelStepInputValid, setIsCancelStepInputValid] = useState(false);

  const [isPaymentStep, setIsPaymentStep] = useState(false);
  const [isPaymentStepInputValid, setIsPaymentStepInputValid] = useState(false);

  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(true);
  const [continueButtonLabel, setContinueButtonLabel] = useState('Continue');

  const setTitleCallback = useCallback(setTitle, [setTitle]);
  const setContinueButtonLabelCallback = useCallback(setContinueButtonLabel, [setContinueButtonLabel]);

  const form = useForm<Schema>({ mode: 'all' });

  const isGroupBooking = serviceType !== 'SUPPORT';
  const booking = isGroupBooking ? selectedGroupBooking : selectedBooking;

  const cancelBookingMutation = useMutationCancelBooking();
  const cancelGroupBookingMutation = useMutationCancelGroupBooking();

  useEffect(() => {
    if (!isCancellingBooking && !hasCancelledBookingSuccessfully) {
      form.reset(editCancelledBooking(selectedBooking));
    }
  }, [selectedBooking, isOpen]);

  useEffect(() => {
    if (hasCancelledBookingSuccessfully) {
      setIsCancelStep(false);
      setIsPaymentStep(false);
      setIsContinueButtonDisabled(false);
      return;
    }

    if (isCancelStep && isCancelStepInputValid) {
      setIsContinueButtonDisabled(false);
      return;
    }
    if (isPaymentStep && isPaymentStepInputValid) {
      setIsContinueButtonDisabled(false);
      return;
    }

    setIsContinueButtonDisabled(true);
  }, [isCancelStep, isCancelStepInputValid, isPaymentStep, isPaymentStepInputValid, hasCancelledBookingSuccessfully]);

  useEffect(() => {
    if (hasError) {
      notification.error({
        message: <Text size="medium">{t('errorMessage')}</Text>,
        description: <Text>{hasError}</Text>,
      });
    }
  }, [hasError]);

  const resetSteps = () => {
    setIsCancelStep(true);
    setIsPaymentStep(false);
    setHasCancelledBookingSuccessfully(false);
  };

  const onClose = () => {
    if (isCancellingBooking) {
      return;
    }

    form.reset();
    resetSteps();
    onCloseProp();
  };

  const onBack = () => {
    if (isCancelStep) {
      onClose();
      return;
    }

    if (isPaymentStep) {
      setIsCancelStep(true);
      setIsPaymentStep(false);
    }
  };

  const onContinue = async (event) => {
    event.preventDefault();
    if (hasCancelledBookingSuccessfully) {
      onClose();
      return;
    }

    const formValues: Schema = form.getValues();
    onSubmit?.({ isCancelStep, isCancelStepInputValid, isPaymentStep, isPaymentStepInputValid, formValues });

    if (isCancelStep && isCancelStepInputValid) {
      const isTeamMemberAssigned = Boolean(booking?.workerId);
      const skipPaymentStep = !isTeamMemberAssigned && formValues.cancelledBy === fields.cancelledBy.options.business;

      if (skipPaymentStep) {
        fields.customerCancellationReason.name = undefined;
        await onCancelBooking(formValues);
        return;
      }

      setIsCancelStep(false);
      setIsPaymentStep(true);
    } else if (isPaymentStep && isPaymentStepInputValid) {
      await onCancelBooking(formValues);
    }
  };

  const onCancelBooking = async (values: Schema) => {
    const isEditing = true;
    try {
      setIsCancellingBooking(true);
      setHasCancelledBookingSuccessfully(false);

      if (isGroupBooking === true) {
        await cancelBooking(
          values,
          booking,
          isGroupBooking,
          cancelBookingMutation,
          cancelGroupBookingMutation,
          isEditing,
        );
        await doFetchGroupBookingOverview({
          bookingId: booking.bookingId,
        });
      } else {
        const { request, response, shiftEndTimeToPay } = await cancelBooking(
          values,
          booking,
          isGroupBooking,
          cancelBookingMutation,
          cancelGroupBookingMutation,
          isEditing,
        );
        setSelectedBookingItem({
          ...booking,
          ...response,
          cancellationReason: response.cancellationReason ?? request.reason,
          cancellationCode: request.cancellationReason,
          isCustomerNoShow: request.isCustomerNoShow,
          shiftSlotStatus: request.shiftSlot.isPaidShift
            ? ShiftSlotStatus.CANCELLED_PAID
            : ShiftSlotStatus.CANCELLED_UNPAID,
          ...(request.shiftSlot.shiftHours > 0
            ? {
                portalCheckedInDateTime: booking.startDateTime,
                portalCheckedOutDateTime: shiftEndTimeToPay.toISOString(),
              }
            : {}),
        });
      }

      setHasCancelledBookingSuccessfully(true);
    } catch (e) {
      setHasError(e?.meta?.message ?? e.message ?? e ?? 'Unknown error');
      console.error(e);
    } finally {
      setIsCancellingBooking(false);
    }
  };

  return (
    <FormProvider {...form}>
      <Modal
        maxWidth={600}
        header={title}
        isOpen={isOpen}
        onClose={onClose}
        isDismissable={false}
        id="booking-cancel-action-modal"
        bodyStyles={{ overflowY: 'unset', position: 'relative' }}
        footer={
          <form onSubmit={onContinue}>
            <Box padding="$xsmall">
              {!hasCancelledBookingSuccessfully && (
                <Button kind="neutral" emphasis="quiet" marginRight="$small" onPress={onBack}>
                  {isCancelStep ? 'Cancel' : 'Back'}
                </Button>
              )}
              <Button
                kind="accent"
                emphasis="filled"
                type="submit"
                isDisabled={isContinueButtonDisabled || isCancellingBooking}
              >
                {continueButtonLabel}
              </Button>
            </Box>
          </form>
        }
      >
        {isCancellingBooking && <SpinnerContainer />}
        <form data-testid={testId}>
          <ConditionallyHidden hidden={!isCancelStep}>
            <BookingCancelActionModalBookingView
              setTitle={setTitle}
              setContinueButtonLabel={setContinueButtonLabel}
              setIsInputValid={setIsCancelStepInputValid}
              serviceType={serviceType}
              isVisible={isCancelStep}
              booking={booking}
              isEditing={true}
            />
          </ConditionallyHidden>
          <ConditionallyHidden hidden={!isPaymentStep}>
            <BookingCancelActionModalPaymentView
              setTitle={setTitle}
              setContinueButtonLabel={setContinueButtonLabel}
              setIsInputValid={setIsPaymentStepInputValid}
              serviceType={serviceType}
              isVisible={isPaymentStep}
              booking={booking}
              isEditing={true}
            />
          </ConditionallyHidden>
          <ConditionallyHidden hidden={!hasCancelledBookingSuccessfully}>
            <BookingCancelActionModalSuccessView
              setTitle={setTitleCallback}
              setContinueButtonLabel={setContinueButtonLabelCallback}
              booking={booking}
              isVisible={hasCancelledBookingSuccessfully}
              isEditing={true}
            />
          </ConditionallyHidden>
        </form>
      </Modal>
    </FormProvider>
  );
};

const BookingEditCancellationActionModal = (props: IBookingEditCancelActionModalProps) => {
  const { pinc912CancellationFlowEnhancements: isNewCancellationFlowEnabled } = useFlags();
  const isSupportBooking = props.serviceType === 'SUPPORT';

  if (isSupportBooking && isNewCancellationFlowEnabled) {
    return <BookingEditCancellationActionModalV2 {...props} />;
  }

  return <BookingEditCancellationActionModal {...props} />;
};

export default BookingEditCancellationActionModal;
