import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const IndentIncrease = forwardRef(function IndentIncrease(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 4A.75.75 0 0 1 4 3.25h16a.75.75 0 0 1 0 1.5H4A.75.75 0 0 1 3.25 4Zm6 5a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 0 1.5H10A.75.75 0 0 1 9.25 9Zm-6 10a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75ZM10 13.25a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5H10ZM4.53 8.47a.75.75 0 0 0-1.06 1.06l1.97 1.97-1.97 1.97a.75.75 0 1 0 1.06 1.06l2.5-2.5a.75.75 0 0 0 0-1.06l-2.5-2.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
