import { NoteVisibleType, ServiceType } from './enum-utils';

type SelectOptionAttrs = {
  label: string;
  value: NoteVisibleType;
  description: string;
};

export const getNoteVisibleOption = (noteVisibleType: NoteVisibleType): SelectOptionAttrs => {
  switch (noteVisibleType) {
    case NoteVisibleType.PORTAL_AND_APP:
      return {
        label: 'Public (workspace & app)',
        value: noteVisibleType,
        description:
          'Show this note on the GoodHuman workspace and app. Anyone with access to the customer’s profile or customer bookings can view it.',
      };
    case NoteVisibleType.RESTRICTED_TO_SERVICE:
      return {
        label: 'Restricted to service (workspace only)',
        value: noteVisibleType,
        description:
          'Show this note on the GoodHuman workspace. Only people with access to the service this booking is linked to can view it.',
      };
    case NoteVisibleType.RESTRICTED_TO_SERVICE_PORTAL_AND_APP:
      return {
        label: 'Restricted to service (workspace & app)',
        value: noteVisibleType,
        description:
          'Show this note on the GoodHuman workspace and app. Only people with access to the service this booking is linked to, can view it.',
      };
    case NoteVisibleType.PRIVATE_PORTAL_ONLY:
      return {
        label: 'Private (author only)',
        value: noteVisibleType,
        description:
          'Show this note on the GoodHuman workspace. This note is only visible to yourself, and will be made visible to the primary owner if your account is removed.',
      };
    default:
      return {
        label: 'Public (workspace only)',
        value: noteVisibleType,
        description:
          'Show this note on the GoodHuman workspace. Anyone with access to the customer’s profile can view it.',
      };
  }
};

export const isPrivacySettingsRestricted = (
  serviceType: ServiceType,
  defaultVisibilityType: NoteVisibleType,
): boolean => {
  return (
    serviceType === ServiceType.COORDINATION &&
    [NoteVisibleType.RESTRICTED_TO_SERVICE, NoteVisibleType.RESTRICTED_TO_SERVICE_PORTAL_AND_APP].includes(
      defaultVisibilityType,
    )
  );
};
