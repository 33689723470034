import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@good/components';
import { Skeleton } from '@good/ui/core';

import { useSilServiceContext } from '../sil-service-provider/sil-service-provider';

export const SilServiceResidentsLabel = () => {
  const { t } = useTranslation('', { keyPrefix: 'silService.header' });
  const { service, isLoading, isError } = useSilServiceContext();

  const label = (() => {
    if (isLoading || isError) {
      return '-';
    }

    if (service) {
      return t('residents.currentResidents', { residents: 0, capacity: service.capacity ?? 0 });
    }

    return '-';
  })();

  return (
    <Skeleton visible={isLoading}>
      <Text asChild size='sm'>
        <p>{label}</p>
      </Text>
    </Skeleton>
  );
};
