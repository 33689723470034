import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { connect } from 'react-redux';
import { Paragraph, Text } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { SuccessRejectSVG } from 'assets/UndrawSVG';
import { Spinner } from '@blueprintjs/core';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';

interface IBookingRejectActionModalProps extends FormComponentProps {
  onClose: () => void;
  selectedBooking: any;
  userName: string;
  doRejectBooking: typeof dispatch.bookingsStore.doRejectBooking;
  isOpen: boolean;
}

class BookingRejectActionModal extends Component<IBookingRejectActionModalProps, any> {
  state = {
    step: 1,
    canManuallyClose: true,
    selectedItem: null,
    isNextClickable: false,
    title: 'Decline Booking Request',
  };

  onCloseModal = () => {
    const { onClose } = this.props;

    // Do any manual clean up; reset to initial state
    this._reset();

    onClose();
  };

  private _reset = () => this.setState({ step: 1, selectedItem: null, title: 'Decline Booking Request' });

  private _validateRejectReason = (rule, value, callback) => {
    try {
      if (_.isEmpty(value)) {
        this.setState({ isNextClickable: false });
        throw Error('Reject reason is required');
      } else {
        if (_.trim(value).length < 5) {
          this.setState({ isNextClickable: false });
          throw Error(`${5 - _.trim(value).length} Characters left`);
        } else {
          this.setState({ isNextClickable: true });
          callback();
        }
      }
    } catch (e) {
      callback(e);
    }
  };

  private _renderView = () => {
    const { step } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium flex-column">
            <Text className="pb-small">
              Are you sure you want to decline this booking request from {this.props.userName}?
            </Text>
            <Text weight={'bold'}>If so, please provide a reason for declining this booking</Text>
          </div>
          <div className="text-align-left" style={{ minHeight: '200px' }}>
            <Form.Item>
              {getFieldDecorator('rejectReason', { rules: [{ validator: this._validateRejectReason }] })(
                <TextArea autoSize={{ maxRows: 4, minRows: 4 }} placeholder={'Decline reason(s)'} />,
              )}
            </Form.Item>

            <Text className="mt-medium">
              The customer will be notified that you have declined this booking and will see the reason you provide.
            </Text>
          </div>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal}>
              Not Now
            </GhostButton>
            <PrimaryButton size="large" onClick={this.onAccept}>
              Decline Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Declining this booking, won't be long...</Paragraph>
          </div>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div>
          <div className="text-align-left">
            <Paragraph>You have successfully declined this booking.</Paragraph>
            <Paragraph>
              The customer has been notified that their request has been declined along with the reason provided.
            </Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  onAccept = async () => {
    const { selectedBooking, doRejectBooking, form } = this.props;

    form.validateFieldsAndScroll(async (error, value) => {
      if (!error) {
        this.setState({ step: 2, canManuallyClose: false });
        const rejectReason = form.getFieldValue('rejectReason');
        await doRejectBooking({ bookingId: selectedBooking.bookingId, reason: rejectReason });

        this.onComplete();
      }
    });

    // for illustration purposes only. Replace this with actual trigger on finish...
    // setTimeout(this.onComplete, 1000);
  };

  onComplete = () => {
    this.setState({ step: 3, canManuallyClose: true, title: 'Booking successfully declined' });
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this.onCloseModal}
        width="large"
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        {this._renderView()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedBooking: state.bookingsStore.selectedBookingItem });

const mapDispatch = (dispatch: IRootDispatch) => ({ doRejectBooking: dispatch.bookingsStore.doRejectBooking });

export default connect(mapState, mapDispatch)(Form.create<IBookingRejectActionModalProps>()(BookingRejectActionModal));
