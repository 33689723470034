import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { notification } from 'antd';
import { Paragraph } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';

interface ApproveGroupBookingModalProps {
  isOpen: any;
  onClose: any;
  booking: any;
  doApproveGroupBookingsPayment: any;
}
interface ApproveGroupBookingModalState {
  step: number;
  canManuallyClose: boolean;
  title: string;
  isLoading: boolean;
}

export class ApproveGroupBookingModal extends Component<ApproveGroupBookingModalProps, ApproveGroupBookingModalState> {
  state = { step: 1, canManuallyClose: true, title: '', isLoading: false };

  renderView = () => {
    const { step } = this.state;
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>Are you sure you want to approve this customers booking?</Paragraph>
          <Paragraph>
            This will send all line items for this customer to payments where they can be processed.
          </Paragraph>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal} disabled={this.state.isLoading}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this.onAccept} disabled={this.state.isLoading}>
              Approve
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>You have successfully approved this booking.</Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ step: 1, title: 'Approve customer booking' });
    }
  }

  onAccept = async () => {
    const { booking } = this.props;
    try {
      this.setState({ canManuallyClose: false, isLoading: true });
      await this.props.doApproveGroupBookingsPayment({
        serviceId: booking.serviceId,
        serviceDateTimeId: booking.serviceDateTimeId,
        bookingIds: [booking.bookingId],
      });
      this.setState({ step: 2, canManuallyClose: true, title: 'Customer booking approved', isLoading: false });
    } catch (e) {
      this.setState({ canManuallyClose: true, isLoading: false });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: '' }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}
