import React, { useCallback } from 'react';

import { useLocalStorage } from '@good/ui/hooks';

type withShowBookingFiltersProps = {
  showBookingFilters: boolean;
  setShowBookingFilters: (value: boolean) => void;
};

/**
 * Wraps a component with ability to save showBookingFilters state to local storage.
 *
 * @template P - The props type of the wrapped component.
 * @param {React.ComponentType<P & withShowBookingFiltersProps>} WrappedComponent - The component to be wrapped.
 * @returns {(props: P) => JSX.Element} - The wrapped component.
 */
export function WithShowBookingFilters<P>(
  WrappedComponent: React.ComponentType<P & withShowBookingFiltersProps>,
): (props: P) => JSX.Element {
  const Component = (props: P) => {
    const [showBookingFilters, setShowBookingFilters] = useLocalStorage({
      key: 'showBookingsFilters',
      defaultValue: false,
    });

    const setShowBookingFiltersCallback = useCallback(
      (value: boolean) => {
        setShowBookingFilters(value);
      },
      [setShowBookingFilters],
    );

    return (
      <WrappedComponent
        {...props}
        showBookingFilters={showBookingFilters}
        setShowBookingFilters={setShowBookingFiltersCallback}
      />
    );
  };

  return Component;
}
