import { Customer } from '@good/schemas';
import api from 'utilities/api-client';

export type GetCustomerResponse = {
  status: number;
  data: Customer;
};

export async function fetchCustomer(customerUserId: string): Promise<GetCustomerResponse> {
  return await api.get(`/api/portal/customers/${customerUserId}`);
}
