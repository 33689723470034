import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { SubTitle, Text } from 'common-components/typography';
import { Input, Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { IFormElement } from 'views/form-builder/shared/form-interface';
import {
  convertRulesRequiredOfBundle,
  getRules,
  getValueFromPropertyByKey,
} from 'views/form-builder/shared/form-builder-utils';
import { IntakeFormElementType, FormElementType, PropKey } from 'views/form-builder/shared/form-enum';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';

const FormItem = Form.Item;
interface IInlineEditInputProps {
  fieldTitle?: string;
  placeholder?: string;
  caption?: string;
  isRequired?: boolean;
  fieldType?: IntakeFormElementType | string;
}

export const InlineEditInput = ({
  fieldTitle: fieldText,
  placeholder,
  caption,
  isRequired,
  fieldType,
}: IInlineEditInputProps) => {
  const fieldTextFormat = (
    <>
      {fieldText}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );

  const isFullWithInput = ![
    IntakeFormElementType.GENERAL_INFORMATION_MEDICARE_NUMBER,
    IntakeFormElementType.GENERAL_INFORMATION_AMBULANCE_NUMBER,
    IntakeFormElementType.GENERAL_INFORMATION_COMPANION_CARD,
    IntakeFormElementType.GENERAL_INFORMATION_CENTRELINK_NUMBER,
  ].includes(fieldType);

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bolder">{fieldTextFormat}</SubTitle>
      <Input
        size="large"
        className="mt-x-small bordered text-size-large"
        placeholder={placeholder}
        style={{ width: isFullWithInput ? '100% !important' : '250px' }}
      />
      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
};

interface IEditInlineEditInputProps extends FormComponentProps {
  value?: string;
  element: IFormElement;
}

export const EditInlineEditInput = ({ value, element, form }: IEditInlineEditInputProps) => {
  const { elementsBundleOptional } = useSelector((state: IRootState) => state.formBuilderStore);
  const { debtors } = useSelector((state: IRootState) => state.accountStore);
  const { id, parentId } = element;
  const placeholder = getValueFromPropertyByKey(element.properties.appearance, 'placeholderText');
  const caption = getValueFromPropertyByKey(element.properties.appearance, 'captionText');
  let rules = getRules(element, false);
  const { getFieldDecorator, setFieldsValue, validateFields, getFieldError } = form;
  const isRequired = getValueFromPropertyByKey(element.properties.general, 'require');
  let fieldText = getValueFromPropertyByKey(element.properties.general, 'fieldTitle');
  const [messageError, setMessageError] = useState(getFieldError(`${id},value`)?.join(' '));
  const isRequiredBundle = !elementsBundleOptional.includes(id);

  if (isRequired) {
    rules = convertRulesRequiredOfBundle(isRequiredBundle, rules);

    fieldText = (
      <>
        {fieldText}
        {isRequiredBundle && <Text color="red"> *</Text>}
      </>
    );
  }

  const isFullWithInput = ![
    IntakeFormElementType.GENERAL_INFORMATION_MEDICARE_NUMBER,
    IntakeFormElementType.GENERAL_INFORMATION_AMBULANCE_NUMBER,
    IntakeFormElementType.GENERAL_INFORMATION_COMPANION_CARD,
    IntakeFormElementType.GENERAL_INFORMATION_CENTRELINK_NUMBER,
  ].includes(element.properties.general?.[0]?.fieldType);

  const validateNewManagerEmail = (rule, value, callback) => {
    const fieldType = element.properties.general.find((prop) => prop.key === PropKey.FIELD_TITLE)?.fieldType;
    if (
      fieldType !== IntakeFormElementType.FUNDING_PLAN_MANAGER_EMAIL &&
      fieldType !== IntakeFormElementType.FUNDING_SELF_MANAGER_EMAIL
    ) {
      callback();
      return;
    }

    try {
      if (debtors?.some((debtor) => debtor.debtorEmail === value?.trim())) {
        throw Error('This debtor already exists. Please enter a different email address');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  const changeValue = useCallback((value) => {
    const fieldName = `${id},value`;

    setFieldsValue({
      [fieldName]: value,
    });

    validateFields([fieldName]);
  }, []);

  const debounceChangeValue = debounce(changeValue, 300);

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    debounceChangeValue(value);
  };

  useEffect(() => {
    const fieldError = getFieldError(`${id},value`)?.join(' ');
    if (fieldError !== messageError) {
      setMessageError(fieldError);
    }
  }, [elementsBundleOptional]);

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bolder">{fieldText}</SubTitle>
      <FormItem className="m-none" validateStatus={messageError ? 'error' : ''} help={messageError}>
        <Input
          id={`${id},value`}
          defaultValue={form.getFieldValue(`${id},value`) ?? value}
          onChange={onChangeValue}
          size="large"
          className="mt-x-small bordered text-size-large"
          placeholder={placeholder}
          style={{ width: isFullWithInput ? '100% !important' : '250px' }}
        />
      </FormItem>
      <FormItem className="m-none hide">
        {getFieldDecorator([id, 'value'] as never, {
          initialValue: value,
          rules: [
            ...rules,
            {
              validator: validateNewManagerEmail,
            },
          ],
        })(<Input type="hidden" />)}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'type'] as never, {
          initialValue: FormElementType.SHORT_TEXT,
        })(<Input type="hidden" />)}
      </FormItem>

      <FormItem className="m-none">
        {getFieldDecorator([id, 'parentElementId'] as never, {
          initialValue: parentId,
        })(<Input type="hidden" />)}
      </FormItem>

      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
};
