import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ClipboardText = forwardRef(function ClipboardText(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.353 3.75c-.628 0-1.084.003-1.448.03-.398.028-.629.08-.805.158A2.25 2.25 0 0 0 4.938 5.1c-.077.176-.13.407-.159.805-.029.405-.03.923-.03 1.666V15c0 1.435.002 2.436.104 3.192.099.734.28 1.122.556 1.399.277.277.665.457 1.399.556.755.101 1.756.103 3.192.103h4c1.435 0 2.436-.002 3.192-.103.733-.099 1.122-.28 1.399-.556.277-.277.457-.665.556-1.4.101-.755.103-1.756.103-3.191V7.571c0-.743 0-1.26-.03-1.666-.028-.398-.081-.629-.158-.805A2.25 2.25 0 0 0 17.9 3.938c-.176-.077-.407-.13-.805-.159-.364-.026-.82-.029-1.449-.029a2.751 2.751 0 0 1-2.646 2h-2a2.751 2.751 0 0 1-2.647-2Zm7.293-1.5c.622 0 1.133.003 1.556.033.478.034.9.106 1.297.28A3.75 3.75 0 0 1 20.438 4.5c.173.398.245.82.28 1.298.033.465.033 1.037.033 1.745v7.512c0 1.367 0 2.47-.117 3.337-.12.9-.38 1.658-.982 2.26-.601.602-1.36.86-2.26.982-.866.116-1.969.116-3.336.116H9.945c-1.368 0-2.47 0-3.337-.116-.9-.122-1.658-.38-2.26-.982-.602-.602-.86-1.36-.982-2.26-.116-.867-.116-1.97-.116-3.337V7.544c0-.71 0-1.281.033-1.746.034-.478.106-.9.28-1.298A3.75 3.75 0 0 1 5.5 2.563c.398-.174.82-.246 1.298-.28.423-.03.934-.033 1.555-.033A2.751 2.751 0 0 1 11 .25h2c1.259 0 2.32.846 2.646 2ZM9.75 3c0-.69.56-1.25 1.25-1.25h2a1.25 1.25 0 1 1 0 2.5h-2c-.69 0-1.25-.56-1.25-1.25Zm-1.5 7A.75.75 0 0 1 9 9.25h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM9 13.25a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5H9Z"
        fill="currentColor"
      />
    </Icon>
  );
});
