import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { CompatRoute as Route } from 'react-router-dom-v5-compat';
import { Paragraph } from 'common-components/typography';
import { Title } from '@good/ui/core';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import InviteSupportWorkerView from './InviteSupportWorkerView';
import InviteConsumerView from './InviteConsumerView';

class InviteUsersLandingView extends Component {
  state = {
    isInviteWorkerModalOpen: false,
    isInviteConsumerModalOpen: false,
  };

  private _openInviteWorkerModal = () => {
    this.setState({ isInviteWorkerModalOpen: true });
  };

  private _closeInviteWorkerModal = () => {
    this.setState({ isInviteWorkerModalOpen: false });
  };

  private _openInviteConsumerModal = () => {
    this.setState({ isInviteConsumerModalOpen: true });
  };

  private _closeInviteConsumerModal = () => {
    this.setState({ isInviteConsumerModalOpen: false });
  };

  render() {
    return (
      <>
        <InviteSupportWorkerView
          isOpen={this.state.isInviteWorkerModalOpen}
          closeInviteWorkerModal={this._closeInviteWorkerModal}
        />

        <InviteConsumerView
          isOpen={this.state.isInviteConsumerModalOpen}
          closeInviteConsumerModal={this._closeInviteConsumerModal}
        />

        {/*<div className="item-container">*/}
        <Title>Support Workers</Title>
        <Paragraph>
          Invite your <b>support workers</b> to download the GoodHuman APP (IOs and Android) so they can manage
          bookings, communicate and much more.
        </Paragraph>

        <div className='mv-x-large'>
          <PrimaryButton size='large' icon='plus' className='mb-x-large' onClick={this._openInviteWorkerModal}>
            Invite support worker
          </PrimaryButton>

          <Row>
            <Col span={10}>
              <Route
                render={({ history }) => (
                  <HyperlinkButton
                    color='green'
                    onClick={() => {
                      history.push('/pending-invitations');
                    }}
                  >
                    View pending invitations
                  </HyperlinkButton>
                )}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default InviteUsersLandingView;
