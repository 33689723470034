import React, { Component } from 'react';
import { Col, Form, notification, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { Paragraph, SubTitle } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import TimeInput from 'common-components/time-input/TimeInput';
import DatePicker from 'react-datepicker';
import MarkAttendancePanel from 'views/group-services/session-details/components/MarkAttendancePanel';
import CommonUtils from 'utilities/common-utils';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { BookingStatus } from 'utilities/enum-utils';

interface IStartSessionModalProps extends FormComponentProps {
  closeModal: (resetCustomerList) => void;
  isOpen: boolean;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  doStartSession: typeof dispatch.groupServiceStore.doStartSession;
  doCheckStartValidity: typeof dispatch.groupServiceStore.doCheckStartValidity;
}

interface IStartSessionModalState {
  title: string;
  isLoading: boolean;
  isChecking: boolean;
  checkStartValidity: any;
  displayErrorStep: boolean;
  isMarkAttendanceStep: boolean;
  selectedCustomerToStart: any;
  startDateTime: Moment;
}

class StartSessionModal extends Component<IStartSessionModalProps, IStartSessionModalState> {
  state = {
    title: 'Start session',
    isLoading: false,
    isChecking: false,
    checkStartValidity: null,
    displayErrorStep: false,
    isMarkAttendanceStep: false,
    selectedCustomerToStart: null,
    startDateTime: moment(),
  };

  private _closeModal = (resetCustomerList = false) => {
    this.setState({
      title: 'Start session',
      isLoading: false,
      isChecking: false,
      checkStartValidity: null,
      isMarkAttendanceStep: false,
      selectedCustomerToStart: null,
      startDateTime: moment(),
    });
    this.props.closeModal(resetCustomerList);
  };

  private _startSession = async (selectedCustomersTimes) => {
    const { selectedSession } = this.props;
    const { checkStartValidity } = this.state;
    this.setState({ isLoading: true, isMarkAttendanceStep: false });
    try {
      await this.props.doStartSession({
        serviceId: selectedSession.serviceId,
        serviceDateTimeId: selectedSession.serviceDateTimeId,
        pendingCustomerAttendances: checkStartValidity && checkStartValidity.pendingCustomerAttendances,
        customerAttendances: _.map(selectedCustomersTimes, (customerTime) => {
          return {
            attendanceId: customerTime.attendanceId,
            startDateTime: moment.tz(
              moment(customerTime.dateTime).format('YYYY-MM-DD HH:mm'),
              selectedSession.timezone,
            ),
          };
        }),
        sessionStartDateTime: moment.tz(
          moment(this.state.startDateTime).format('YYYY-MM-DD HH:mm'),
          selectedSession.timezone,
        ),
      });
      this._closeModal(true);
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isLoading: false });
    }
  };

  private _declineWaitingCustomer = () => {
    this.setState({ displayErrorStep: false, title: 'Start session' });
  };

  private _onChangeStartingDate = (event) => {
    this.setState({ startDateTime: CommonUtils.formatCeilingDateTime(event) });
  };

  private _goToMarkAttendanceStep = () => {
    this.setState({ isMarkAttendanceStep: true, title: 'Mark customer attendance (Optional)' });
  };

  private _goBackFromMarkAttendance = () => {
    this.setState({ isMarkAttendanceStep: false, title: 'Start session' });
  };

  componentDidUpdate = async (prevProps: Readonly<IStartSessionModalProps>) => {
    const { selectedSession } = this.props;
    if (!prevProps.isOpen && this.props.isOpen && this.props.selectedSession) {
      this.setState({ isChecking: true });
      let checkStartValidity = null;
      try {
        checkStartValidity = await this.props.doCheckStartValidity({
          serviceId: selectedSession.serviceId,
          serviceDateTimeId: selectedSession.serviceDateTimeId,
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
      this.setState({
        checkStartValidity,
        displayErrorStep: !!checkStartValidity,
        title:
          checkStartValidity && !checkStartValidity.canStartSession
            ? 'Whoops!'
            : checkStartValidity &&
              checkStartValidity.pendingCustomerAttendances &&
              checkStartValidity.pendingCustomerAttendances.length > 0
            ? 'Customers still pending'
            : 'Start session',
        startDateTime: moment(
          moment.tz(selectedSession.startDateTime, selectedSession.timezone).format('YYYY-MM-DD HH:mm'),
        ),
        isChecking: false,
      });
    }
  };

  render() {
    const { selectedSession } = this.props;
    const { title, isLoading, isChecking, checkStartValidity, isMarkAttendanceStep, startDateTime, displayErrorStep } =
      this.state;

    return (
      <ActionModal isOpen={this.props.isOpen} title={title} onClose={() => this._closeModal(false)} width={'large'}>
        {isChecking ? (
          <SpinningLoader size={50} message={'Checking session..'} />
        ) : (
          selectedSession && (
            <>
              {displayErrorStep && checkStartValidity && !checkStartValidity.canStartSession ? (
                <>
                  <div className="mb-large">
                    <Paragraph>
                      You cannot start a session unless there is at least one confirmed team member and one customer in
                      the session.
                    </Paragraph>
                  </div>
                  <div className={'mt-large'}>
                    <Row type={'flex'} justify={'end'}>
                      <PrimaryButton size="large" onClick={() => this._closeModal(false)}>
                        Close
                      </PrimaryButton>
                    </Row>
                  </div>
                </>
              ) : displayErrorStep &&
                checkStartValidity &&
                checkStartValidity.pendingCustomerAttendances &&
                checkStartValidity.pendingCustomerAttendances.length > 0 ? (
                <>
                  <div>
                    <Paragraph>
                      The following customers have requested to be part of this session and have not yet been
                      accepted/declined:
                    </Paragraph>
                    <ul>
                      {_.map(checkStartValidity.pendingCustomerAttendances, (customer) => {
                        return (
                          <li>
                            {customer.firstName} {customer.lastName}
                          </li>
                        );
                      })}
                    </ul>
                    <Paragraph>
                      Starting the session will automatically decline these customer from the session.
                    </Paragraph>
                    <Paragraph>Are you sure you want to continue?</Paragraph>
                  </div>
                  <div className={'mb-small'}>
                    <Row type={'flex'} justify={'end'}>
                      <Col>
                        <GhostButton size="large" onClick={() => this._closeModal(false)} className="mr-medium">
                          Cancel
                        </GhostButton>
                      </Col>
                      <Col>
                        <PrimaryButton loading={isLoading} onClick={this._declineWaitingCustomer} size="large">
                          Continue
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : isMarkAttendanceStep ? (
                <MarkAttendancePanel
                  type={'startDateTime'}
                  saveTimes={this._startSession}
                  goBack={this._goBackFromMarkAttendance}
                  selectedTime={startDateTime ? startDateTime : moment()}
                  displayedStatus={[BookingStatus.CONFIRMED]}
                />
              ) : (
                <div className="anim-slide-left">
                  <SubTitle>Session start time</SubTitle>
                  <div className="flex-row align-left mb-x-large">
                    <DatePicker
                      className="gh-datepicker rounded mr-small"
                      calendarClassName="gh-datepicker-calendar"
                      dateFormat="d/M/yyyy"
                      isClearable={false}
                      selected={moment(startDateTime).toDate()}
                      onChange={(event) => this._onChangeStartingDate(event)}
                    />
                    <TimeInput
                      size="large"
                      value={moment(startDateTime)}
                      onChange={(event) => this._onChangeStartingDate(event)}
                    />
                  </div>

                  <div className={'mb-small'}>
                    <Row type={'flex'} justify={'end'}>
                      <Col>
                        <GhostButton size="large" onClick={() => this._closeModal(false)} className="mr-medium">
                          Cancel
                        </GhostButton>
                      </Col>
                      <Col>
                        <PrimaryButton loading={isLoading} onClick={this._goToMarkAttendanceStep} size="large">
                          Next
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </>
          )
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doStartSession: dispatch.groupServiceStore.doStartSession,
  doCheckStartValidity: dispatch.groupServiceStore.doCheckStartValidity,
});

export default connect(mapState, mapDispatch)(Form.create<IStartSessionModalProps>()(StartSessionModal));
