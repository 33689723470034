import { Box, Button, Stack, Text } from '@goodhuman-me/components';
import { Divider, Link } from 'design-components';
import React, { useState } from 'react';
import { OptionType } from '../../utils/form-utils';
import OptionSelectRow from './OptionSelectRow';

interface IProps {
  teamMembersSelected: number;
  onRemoveMember?: () => void;
  setViewDropdown?: (value: OptionType) => void;
}

const DropdownOverview: React.FC<IProps> = ({ teamMembersSelected, setViewDropdown, onRemoveMember }) => {
  const [isConfirmRemoveMembers, setIsConfirmRemoveMembers] = useState<boolean>(false);

  return (
    <Stack width={300}>
      <Stack padding="$space100">
        <OptionSelectRow label="Workspace permission" onPress={() => setViewDropdown('PERMISSION')} />

        <OptionSelectRow label="Invite to app" onPress={() => setViewDropdown('INVITE_TO_APP')} />
      </Stack>

      <Divider />

      <Box padding="$space200">
        {isConfirmRemoveMembers ? (
          <>
            <Text color="$critical" size="small">
              Are you sure you want to remove these {teamMembersSelected} team member
              {teamMembersSelected === 1 ? '' : 's'}?{' '}
            </Text>

            <Box display="flex" justifyContent="center" marginTop="$space125" gap="$space200">
              <Button
                flexGrow={1}
                flexBasis="30%"
                emphasis="quiet"
                kind="accent"
                size="small"
                onPress={() => setIsConfirmRemoveMembers(false)}
              >
                <Text size="small" fontWeight="$semi">
                  Cancel
                </Text>
              </Button>
              <Button
                flexGrow={1}
                flexBasis="30%"
                kind="critical"
                emphasis="filled"
                size="small"
                onPress={onRemoveMember}
              >
                <Text size="small" fontWeight="$semi">
                  Yes, remove
                </Text>
              </Button>
            </Box>
          </>
        ) : (
          <Link
            kind="destructive"
            size="small"
            emphasis="regular"
            textDecoration="none !important"
            onPress={() => setIsConfirmRemoveMembers(true)}
          >
            Remove {teamMembersSelected} team member{teamMembersSelected === 1 ? '' : 's'}
          </Link>
        )}
      </Box>
    </Stack>
  );
};

export default DropdownOverview;
