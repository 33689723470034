import React from 'react';
import { Col, Row, Avatar, Empty } from 'antd';
import moment, { Moment } from 'moment-timezone';
import _ from 'lodash';

import { SubTitle, Text } from 'common-components/typography';
import { ITeamMemberAttendance } from 'interfaces/session-interfaces';

interface IRosterTeamMembersListingProps {
  teamMembers: ITeamMemberAttendance[];
  timezone: string;
}

const formatDuration = (startDateTime: Moment, endDateTime: Moment): string => {
  const duration = moment.duration(endDateTime.diff(startDateTime));

  return (
    duration
      .asHours()
      .toFixed(4)
      .replace(/\.?0+$/, '') + ' hrs'
  );
};

function RosterTeamMembersListing(props: IRosterTeamMembersListingProps) {
  const { teamMembers, timezone } = props;

  return (
    <table className="width-full">
      <thead>
        <tr className="page-break-inside-avoid">
          <div className="mb-medium">
            <Text size="x2-large" weight="bold">
              Team members <Text color="secondary">({teamMembers.length})</Text>
            </Text>
          </div>
        </tr>
      </thead>
      <tbody>
        <tr className="page-break-inside-avoid">
          <Row className="flex align-center mb-medium">
            <Col span={10}>
              <div className="ml-medium">
                <SubTitle containerClassName="line-height-135">Team member</SubTitle>
              </div>
            </Col>
            <Col span={14}>
              <div className="inline-flex">
                <SubTitle containerClassName="mr-large line-height-135">Start time</SubTitle>
                <SubTitle containerClassName="line-height-135">End time</SubTitle>
              </div>
            </Col>
          </Row>
        </tr>
        {_.isEmpty(teamMembers) ? (
          <tr className="page-break-inside-avoid">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </tr>
        ) : (
          _.map(teamMembers, (teamMember) => {
            const {
              supportWorkerAttendanceId,
              firstName,
              lastName,
              attachmentUrl,
              shiftEndDateTime,
              shiftStartDateTime,
            } = teamMember;
            const shiftStartTime = moment.tz(shiftStartDateTime, timezone);
            const shiftEndTime = moment.tz(shiftEndDateTime, timezone);
            const duration = formatDuration(shiftStartTime, shiftEndTime);
            return (
              <tr key={supportWorkerAttendanceId} className="page-break-inside-avoid">
                <>
                  <div className="bordered-top"></div>
                  <Row className="flex align-center mv-medium">
                    <Col span={10}>
                      <div className="flex-row">
                        <div>
                          <Avatar size={32} src={attachmentUrl} shape="square" />
                        </div>
                        <div className="m-auto ml-small">
                          <Text size="regular" weight="regular" className="break-word">
                            {firstName} {lastName}
                          </Text>
                        </div>
                      </div>
                    </Col>
                    <Col span={14}>
                      <Text className="inline-block mr-medium" size="regular" style={{ width: '62px' }}>
                        {shiftStartTime.format('h:mm A')}
                      </Text>
                      <Text className="mr-small" weight="bold">
                        -
                      </Text>
                      <Text className="inline-block mr-small" size="regular" style={{ width: '62px' }}>
                        {shiftEndTime.format('h:mm A')}
                      </Text>
                      <Text size="regular" color="secondary">
                        ({duration})
                      </Text>
                    </Col>
                  </Row>
                </>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
}

export default RosterTeamMembersListing;
