export enum FormElementType {
  HEADER = 'HEADER',
  PARAGRAPH = 'PARAGRAPH',
  DIVIDER = 'DIVIDER', // horizontal line
  SHORT_TEXT = 'SHORT_TEXT', // for single line editable text ie: short text
  LONG_TEXT = 'LONG_TEXT', // for multi-line editable text ie: Paragraph
  NUMBER = 'NUMBER', // number input
  CURRENCY = 'CURRENCY', // for dollar amount
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTI_CHOICE = 'MULTI_CHOICE',
  DROPDOWN = 'DROPDOWN',
  CHECKBOX = 'CHECKBOX', // checkbox
  FILE_UPLOAD = 'FILE_UPLOAD', // attachment
  DATE_TIME = 'DATE_TIME',
  DROPDOWN_MULTI_SELECT = 'DROPDOWN_MULTI_SELECT',
  ADDRESS_LOOKUP = 'ADDRESS_LOOKUP',
  PHONE_NUMBER = 'PHONE_NUMBER',
  FIRST_LAST_NAME = 'FIRST_LAST_NAME',
  MULTIPLE_ELEMENT = 'MULTIPLE_ELEMENT',
  CONDITIONAL = 'CONDITIONAL',
}

export const textInputElementTypes: FormElementType[] = [FormElementType.LONG_TEXT, FormElementType.SHORT_TEXT];

export const numberInputElementTypes: FormElementType[] = [FormElementType.NUMBER, FormElementType.CURRENCY];

export enum PropType {
  required = 1,
  checkbox = 2,
  options = 3,
  defaultOption = 4,
  inputDepend = 5,
  Select = 6,
  selectDepend = 7,
  SelectMultiple = 8,
  inputDependencyDate = 9,
}

export enum ActionType {
  ADD = 'ADD',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

export enum IntakeFormElementType {
  // COMMON
  RELATIONSHIP = 'RELATIONSHIP',

  // GENERAL_INFORMATION
  GENERAL_INFORMATION_MOBILE_NUMBER = 'GENERAL_INFORMATION_MOBILE_NUMBER',
  GENERAL_INFORMATION_HOME_NUMBER = 'GENERAL_INFORMATION_HOME_NUMBER',
  GENERAL_INFORMATION_DATE_OF_BIRTH = 'GENERAL_INFORMATION_DATE_OF_BIRTH',
  GENERAL_INFORMATION_EMAIL_ADDRESS = 'GENERAL_INFORMATION_EMAIL_ADDRESS',
  GENERAL_INFORMATION_HOME_ADDRESS = 'GENERAL_INFORMATION_HOME_ADDRESS',
  GENERAL_INFORMATION_GENDER = 'GENERAL_INFORMATION_GENDER',
  GENERAL_INFORMATION_LGA = 'GENERAL_INFORMATION_LGA',
  GENERAL_INFORMATION_PERMANENT_CONDITIONS = 'GENERAL_INFORMATION_PERMANENT_CONDITIONS',
  GENERAL_INFORMATION_HOBBIES = 'GENERAL_INFORMATION_HOBBIES',
  GENERAL_INFORMATION_RELIGION = 'GENERAL_INFORMATION_RELIGION',
  GENERAL_INFORMATION_LANGUAGES = 'GENERAL_INFORMATION_LANGUAGES',
  GENERAL_INFORMATION_COUNTRY_OF_BIRTH = 'GENERAL_INFORMATION_COUNTRY_OF_BIRTH',
  GENERAL_INFORMATION_STRAIT_ISLANDER_STATUS = 'GENERAL_INFORMATION_STRAIT_ISLANDER_STATUS',
  GENERAL_INFORMATION_MEDICARE_NUMBER = 'GENERAL_INFORMATION_MEDICARE_NUMBER',
  GENERAL_INFORMATION_AMBULANCE_NUMBER = 'GENERAL_INFORMATION_AMBULANCE_NUMBER',
  GENERAL_INFORMATION_COMPANION_CARD = 'GENERAL_INFORMATION_COMPANION_CARD',
  GENERAL_INFORMATION_CENTRELINK_NUMBER = 'GENERAL_INFORMATION_CENTRELINK_NUMBER',
  GENERAL_INFORMATION_LIVING_ARRANGEMENTS = 'GENERAL_INFORMATION_LIVING_ARRANGEMENTS',
  GENERAL_INFORMATION_REQUIRES_TRANSLATOR = 'GENERAL_INFORMATION_REQUIRES_TRANSLATOR',
  GENERAL_INFORMATION_TRANSLATOR_LANGUAGE = 'GENERAL_INFORMATION_TRANSLATOR_LANGUAGE',

  // ENQUIRIES
  ENQUIRIES_REQUESTED_SERVICES = 'ENQUIRIES_REQUESTED_SERVICES',
  ENQUIRIES_CUSTOMER_SOURCE = 'ENQUIRIES_CUSTOMER_SOURCE',
  ENQUIRIES_OTHER_SOURCE = 'ENQUIRIES_OTHER_SOURCE',

  // CONTACTS
  CONTACTS_FIRST_NAME = 'CONTACTS_FIRST_NAME',
  CONTACTS_LAST_NAME = 'CONTACTS_LAST_NAME',
  CONTACTS_EMAIL = 'CONTACTS_EMAIL',
  CONTACTS_LEGAL_GUARDIAN_RELATIONSHIP = 'CONTACTS_LEGAL_GUARDIAN_RELATIONSHIP',
  CONTACTS_FULL_NAME = 'CONTACTS_FULL_NAME',
  CONTACTS_PHONE_NUMBER = 'CONTACTS_PHONE_NUMBER',
  CONTACTS_EMAIL_ADDRESS = 'CONTACTS_EMAIL_ADDRESS',
  CONTACTS_ADDITIONAL_CONTACTS_RELATIONSHIP = 'CONTACTS_ADDITIONAL_CONTACTS_RELATIONSHIP',
  CONTACTS_CHECK_PRIMARY_CONTACT = 'CONTACTS_CHECK_PRIMARY_CONTACT',
  CONTACTS_CHECK_ADD_OTHER_CONTACT = 'CONTACTS_CHECK_ADD_OTHER_CONTACT',

  // CARE INFORMATION
  CARE_INFORMATION_MEDICATION_NAME = 'CARE_INFORMATION_MEDICATION_NAME',
  CARE_INFORMATION_TREATMENT = 'CARE_INFORMATION_TREATMENT',
  CARE_INFORMATION_MEDICATION_DESCRIPTION = 'CARE_INFORMATION_MEDICATION_DESCRIPTION',
  CARE_INFORMATION_MEDICATION_ALERT_LEVEL = 'CARE_INFORMATION_MEDICATION_ALERT_LEVEL',
  CARE_INFORMATION_CHECK_ADD_ANOTHER_MEDICATION = 'CARE_INFORMATION_CHECK_ADD_ANOTHER_MEDICATION',

  CARE_INFORMATION_SUPPORT_NEED = 'CARE_INFORMATION_SUPPORT_NEED',
  CARE_INFORMATION_SUPPORT_NEED_DESCRIPTION = 'CARE_INFORMATION_SUPPORT_NEED_DESCRIPTION',
  CARE_INFORMATION_SUPPORT_NEED_ALERT_LEVEL = 'CARE_INFORMATION_SUPPORT_NEED_ALERT_LEVEL',
  CARE_INFORMATION_CHECK_ADD_ANOTHER_SUPPORT_NEED = 'CARE_INFORMATION_CHECK_ADD_ANOTHER_SUPPORT_NEED',

  CARE_INFORMATION_BEHAVIOUR = 'CARE_INFORMATION_BEHAVIOUR',
  CARE_INFORMATION_BEHAVIOUR_DESCRIPTION = 'CARE_INFORMATION_BEHAVIOUR_DESCRIPTION',
  CARE_INFORMATION_MANAGEMENT_STRATEGY = 'CARE_INFORMATION_MANAGEMENT_STRATEGY',
  CARE_INFORMATION_BEHAVIOUR_ALERT_LEVEL = 'CARE_INFORMATION_BEHAVIOUR_ALERT_LEVEL',
  CARE_INFORMATION_CHECK_ADD_ANOTHER_BEHAVIOUR = 'CARE_INFORMATION_CHECK_ADD_ANOTHER_BEHAVIOUR',

  CARE_INFORMATION_GENERAL_INFORMATION = 'CARE_INFORMATION_GENERAL_INFORMATION',
  CARE_INFORMATION_GENERAL_INFORMATION_DESCRIPTION = 'CARE_INFORMATION_GENERAL_INFORMATION_DESCRIPTION',
  CARE_INFORMATION_GENERAL_INFORMATION_ALERT_LEVEL = 'CARE_INFORMATION_GENERAL_INFORMATION_ALERT_LEVEL',
  CARE_INFORMATION_CHECK_ADD_MORE_GENERAL_INFORMATION = 'CARE_INFORMATION_CHECK_ADD_MORE_GENERAL_INFORMATION',

  CARE_INFORMATION_PERMANENT_CONDITION = 'CARE_INFORMATION_PERMANENT_CONDITION',
  CARE_INFORMATION_PERMANENT_CONDITION_DESCRIPTION = 'CARE_INFORMATION_PERMANENT_CONDITION_DESCRIPTION',
  CARE_INFORMATION_PERMANENT_CONDITION_ALERT_LEVEL = 'CARE_INFORMATION_PERMANENT_CONDITION_ALERT_LEVEL',
  CARE_INFORMATION_CHECK_ADD_ANOTHER_PERMANENT_CONDTION = 'CARE_INFORMATION_CHECK_ADD_ANOTHER_PERMANENT_CONDTION',

  // FUNDING
  FUNDING_NDIS_NUMBER = 'FUNDING_NDIS_NUMBER',
  FUNDING_MANAGEMENT_METHOD = 'FUNDING_MANAGEMENT_METHOD',
  FUNDING_PLAN_MANAGER_TYPE = 'FUNDING_PLAN_MANAGER_TYPE',
  FUNDING_PLAN_MANAGER_NAME = 'FUNDING_PLAN_MANAGER_NAME',
  FUNDING_PLAN_MANAGER_ORGANISATION_NAME = 'FUNDING_PLAN_MANAGER_ORGANISATION_NAME',
  FUNDING_PLAN_MANAGER_ID = 'FUNDING_PLAN_MANAGER_ID',
  FUNDING_PLAN_MANAGER_CONTACT_NUMBER = 'FUNDING_PLAN_MANAGER_CONTACT_NUMBER',
  FUNDING_PLAN_MANAGER_EMAIL = 'FUNDING_PLAN_MANAGER_EMAIL',
  FUNDING_PLAN_MANAGER_ADDRESS = 'FUNDING_PLAN_MANAGER_ADDRESS',
  FUNDING_SELF_MANAGER_NAME = 'FUNDING_SELF_MANAGER_NAME',
  FUNDING_SELF_MANAGER_ID = 'FUNDING_SELF_MANAGER_ID',
  FUNDING_SELF_MANAGER_ORGANISATION_NAME = 'FUNDING_SELF_MANAGER_ORGANISATION_NAME',
  FUNDING_SELF_MANAGER_CONTACT_NUMBER = 'FUNDING_SELF_MANAGER_CONTACT_NUMBER',
  FUNDING_SELF_MANAGER_ADDRESS = 'FUNDING_SELF_MANAGER_ADDRESS',
  FUNDING_SELF_MANAGER_EMAIL = 'FUNDING_SELF_MANAGER_EMAIL',
  FUNDING_GOAL_TYPE = 'FUNDING_GOAL_TYPE',
  FUNDING_GOAL_TITLE = 'FUNDING_GOAL_TITLE',
  FUNDING_GOAL_DESCRIPTION = 'FUNDING_GOAL_DESCRIPTION',
  FUNDING_ANOTHER_GOAL = 'FUNDING_ANOTHER_GOAL',
  FUNDING_SUPPORT_COORDINATOR = 'FUNDING_SUPPORT_COORDINATOR',
  SELECT_EXIST_OR_ADD_PLAN_MANAGER = 'SELECT_EXIST_OR_ADD_PLAN_MANAGER',
  SELECT_EXIST_OR_ADD_SELF_MANAGER = 'SELECT_EXIST_OR_ADD_SELF_MANAGER',
}

export enum IntakeFormComponentsTabs {
  GENERAL_INFORMATION_TAB_ELEMENTS = 'GENERAL_INFORMATION_TAB_ELEMENTS',
  ENQUIRIES_ELEMENTS = 'ENQUIRIES_ELEMENTS',
  CONTACTS_TAB_ELEMENTS = 'CONTACTS_TAB_ELEMENTS',
  FUNDING_TAB_ELEMENTS = 'FUNDING_TAB_ELEMENTS',
  CARE_INFORMATION_TAB_ELEMENTS = 'CARE_INFORMATION_TAB_ELEMENTS',
}

export enum DisplayIntakeFormComponentsTabs {
  GENERAL_INFORMATION_TAB_ELEMENTS = 'General information',
  ENQUIRIES_ELEMENTS = 'Enquiries',
  CONTACTS_TAB_ELEMENTS = 'Contacts',
  FUNDING_TAB_ELEMENTS = 'Funding',
  CARE_INFORMATION_TAB_ELEMENTS = 'Care information',
}

export enum UserActionType {
  ON_CLICK = 'Clicked',
  ON_SELECT = 'Selected',
  ON_CHECK = 'Checked',
  ON_ENTER = 'Entered',
  ON_FOCUS = 'Focused',
  ON_BLUR = 'Blured',
}

export enum FormConditionalOperator {
  IS = 'is',
  IS_NOT = 'is not',
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'does not contain',
  IS_GREATER_THAN = 'is greater than',
  IS_LESS_THAN = 'is less than',
  IS_UPLOADED = 'is uploaded',
  IS_NOT_UPLOADED = 'is not uploaded',
  IS_SELECTED = 'is selected',
  IS_NOT_SELECTED = 'is not selected',
  IS_EQUAL_TO = 'is equal to',
}

export enum DateTimeType {
  DATE_AND_TIME = 'Date & time',
  DATE_ONLY = 'Date only',
  TIME_ONLY = 'Time only',
}

export const validNumberOperators: FormConditionalOperator[] = [
  FormConditionalOperator.IS,
  FormConditionalOperator.IS_NOT,
  FormConditionalOperator.IS_EQUAL_TO,
  FormConditionalOperator.IS_LESS_THAN,
  FormConditionalOperator.IS_GREATER_THAN,
];

export const validTextOperators: FormConditionalOperator[] = [
  FormConditionalOperator.IS,
  FormConditionalOperator.IS_NOT,
  FormConditionalOperator.CONTAINS,
  FormConditionalOperator.DOES_NOT_CONTAIN,
];

export enum PropKey {
  FIELD_TITLE = 'fieldTitle',
  REQUIRE = 'require',
  PLACEHOLDER_TEXT = 'placeholderText',
  RULES = 'rules',
  CAPTION_TEXT = 'captionText',
  DEFAULT_OPTION = 'defaultOption',
  OPTIONS = 'options',
  DATE_TIME_TYPE = 'dateTimeType',
  CONSTRAINT = 'constraint',
  TARGET_ELEMENT = 'targetElement',
  TARGET_OPTION = 'targetOption',
  EVENT = 'event',
  CONDITIONAL_COMPARATOR = 'conditionalComparator',
  CONDITIONAL_COMPARATOR_DATE = 'conditionalComparatorDate',
  AFFECT_TO_ELEMENT = 'affectToElement',
  REPEATABLE = 'repeatable',
  HEADING_TEXT = 'headingText',
  SUBHEADING_TEXT = 'subHeadingText',
  PARAGRAPH_TEXT = 'paragraphText',
  CHECKBOX_OPTIONS = 'checkboxOptions',
  CHECKBOX_TEXT = 'checkboxText',
  PLACEHOLDER_TEXT_FIRST_NAME = 'placeholderTextFirstName',
  PLACEHOLDER_TEXT_LAST_NAME = 'placeholderTextLastName',
  FIELD_TITLE_FIRST_NAME = 'fieldTitleFirstName',
  FIELD_TITLE_LAST_NAME = 'fieldTitleLastName',
}

export enum ConditionType {
  DUPLICATION = 'DUPLICATION',
}
