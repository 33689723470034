import React from 'react';
import { Text, Space, Title } from '@good/ui/core';
import { useTranslation } from 'react-i18next';

import { SilTable } from 'views/sil-service/components/table';
import { SilCustomerData } from 'utilities/react-trpc';
import { customerColumns } from './customer-column-helper';
import { ActiveEmptyState } from '../components/active-empty-state';
import { FullAlert } from '../components/full-alert';
import { useSilServiceContext } from 'views/sil-service/components/sil-service-provider/sil-service-provider';

type IActiveCustomersTabProps = {
  activeCustomers: SilCustomerData;
  upcomingCustomers: SilCustomerData;
  customerCount: number;
};
export const ActiveCustomersTab = ({
  activeCustomers,
  upcomingCustomers,
  customerCount,
}: IActiveCustomersTabProps): JSX.Element => {
  const { service } = useSilServiceContext();
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceCustomersTabs' });

  return (
    <div className='flex flex-col w-full'>
      <div className='flex flex-col mb-small' style={{ gap: 4 }}>
        <Title order={3}>
          {t('Customers')} ({customerCount})
        </Title>
        <Text c='dimmed'> {t('customerDescription')} </Text>
      </div>
      <Space h='md' />
      {service.capacity === activeCustomers.length && <FullAlert />}
      {activeCustomers.length === 0 ? (
        <ActiveEmptyState />
      ) : (
        <>
          <Text mb='xs'>{t('activeCustomers')}</Text>
          <SilTable columns={customerColumns} data={activeCustomers} />
        </>
      )}
      {upcomingCustomers.length > 0 && (
        <>
          <Text mb='xs' mt='sm'>
            {t('upcomingCustomers')}
          </Text>
          <Space h='sm' />
          <SilTable columns={customerColumns} data={upcomingCustomers} />
        </>
      )}
    </div>
  );
};
