// Assigned customer card - For display only
import { IAssignedCustomer, IShiftCustomerAssignment } from 'interfaces/assign-customer-interfaces';
import React, { useState } from 'react';
import _ from 'lodash';
import { Avatar, Col, Icon, Row } from 'antd';
import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { Collapse } from '@blueprintjs/core';
import moment from 'moment';

export function CustomerDisplayCard({ customerAssignments }: { customerAssignments: IShiftCustomerAssignment[] }) {
  const [isOpen, setOpen] = useState(false);

  // Get the userId/names/profile images for the first object
  const { customerUserId, customerFirstName, customerLastName, customerAttachmentUrl } = (_.head(customerAssignments) ||
    {}) as IAssignedCustomer;

  return (
    <div className="rounded-big overflow-hidden bordered mb-x-small">
      <div className="bg-secondary flex-row align-center p-small justify-between">
        <div className="flex-row align-center">
          <Avatar icon="user" shape={'circle'} className="mr-small" size={'small'} src={customerAttachmentUrl} />
          <Text className="mr-small" size="small">
            {`${customerFirstName} ${customerLastName}`}
          </Text>
        </div>

        <HyperlinkButton fontSize="small" onClick={() => setOpen(!isOpen)}>
          {isOpen ? 'Hide' : 'Show'}
          <Icon type={isOpen ? 'up' : 'down'} className="text-size-small ml-x-small" />
        </HyperlinkButton>
      </div>

      <Collapse isOpen={isOpen}>
        <div className="bg-quaternary p-small">
          {_.map(customerAssignments, (assignment, idx) => (
            <Row key={idx}>
              <Col span={10}>
                <Text size="regular">
                  {moment(assignment.startDateTime).format('h:mm a')} -{' '}
                  {moment(assignment.endDateTime).format('h:mm a, D MMM YYYY')}
                </Text>
              </Col>
              <Col span={14}>
                <Text size="regular">{assignment.description}</Text>
              </Col>
            </Row>
          ))}
        </div>
      </Collapse>
    </div>
  );
}
