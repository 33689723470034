import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import NDISLineItemGrid from 'common-components/line-items/NDISLineItemGrid';
import { EditRecurringMode, MmmGroup, PaymentSources } from 'utilities/enum-utils';
import _ from 'lodash';
import { Paragraph, Text } from 'common-components/typography';
import { Radio, Row, Select } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import moment from 'moment';

interface IChangePreferredPaymentMethodModalProps {
  onClose: any;
  isOpen: boolean;
  locationMmmGroup?: MmmGroup;
  locationState?: string;
  getServiceAgreementTitle?: (billingPeriod) => any;
  onSaveSelection: (selectedPaymentSourceType, selectedLineItems, selectedOption) => void;
  displayRecurringModeStep?: boolean;
  paymentSourceAvailableTypes?: any;
  customerBookingPaymentDetail: typeof state.bookingsStore.customerBookingPaymentDetail;
  doFetchBookingPaymentDetail: typeof dispatch.bookingsStore.doFetchBookingPaymentDetail;
  doFetchRecurringBookingsList: typeof dispatch.bookingsStore.doFetchRecurringBookingsList;
  recurringBookingList: typeof state.bookingsStore.recurringBookingList;
  bookingRecurringPattern: typeof state.bookingsStore.bookingRecurringPattern;
  bookingItem: any;
}

interface IChangePreferredPaymentMethodModalState {
  selectedLineItems: any;
  step: number;
  selectedOption: any;
  selectedPaymentSource: string;
  isLoading: boolean;
  bookingRecurrences: Array<any>;
}

class ChangePreferredPaymentMethodModal extends Component<
  IChangePreferredPaymentMethodModalProps,
  IChangePreferredPaymentMethodModalState
> {
  state = {
    selectedLineItems: [],
    step: 1,
    selectedOption: EditRecurringMode.Current,
    selectedPaymentSource: null,
    isLoading: false,
    bookingRecurrences: null,
  };

  private _onClose = () => {
    this.props.onClose();
    this.setState({
      step: 1,
      selectedOption: EditRecurringMode.Current,
      selectedPaymentSource: null,
      isLoading: false,
      bookingRecurrences: null,
      selectedLineItems: [],
    });
  };

  private _onSaveSelection = () => {
    this.props.onSaveSelection(
      this.state.selectedPaymentSource,
      this.state.selectedLineItems,
      this.state.selectedOption,
    );
    this._onClose();
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _displayRecurringModeMenu = () => {
    this.setState({ step: 2 });
  };

  private _onClickLineItemCheckbox = (lineItems, billingPeriod) => {
    const { selectedLineItems } = this.state;
    let newSelectedLineItem = !_.isEmpty(selectedLineItems) ? _.clone(selectedLineItems) : [];

    if (
      !_.find(selectedLineItems, (existingLineItem) => existingLineItem.startDateTime === billingPeriod.startDateTime)
    ) {
      newSelectedLineItem.push({
        endDateTime: billingPeriod.endDateTime,
        startDateTime: billingPeriod.startDateTime,
        selectedLineItems: _.map(lineItems, (item) => item.supportItemNumber),
      });
    } else if (_.isEmpty(lineItems)) {
      newSelectedLineItem = _.filter(
        selectedLineItems,
        (period) => period.startDateTime !== billingPeriod.startDateTime,
      );
    } else {
      newSelectedLineItem = _.map(selectedLineItems, (period) => {
        if (period.startDateTime === billingPeriod.startDateTime) {
          return { ...period, selectedLineItems: _.map(lineItems, (item) => item.supportItemNumber) };
        } else {
          return { ...period };
        }
      });
    }
    this.setState({ selectedLineItems: newSelectedLineItem });
  };

  private _goToLineItemCharged = async () => {
    const { doFetchBookingPaymentDetail, bookingItem } = this.props;
    this.setState({ isLoading: true, step: 3 });
    const timeSlots = await this._getTimeSlots();
    await doFetchBookingPaymentDetail({
      serviceId: bookingItem.serviceId,
      customerUserId: bookingItem.bookerUserId,
      timeSlots: _.map(timeSlots, (timeSlot) => {
        return { ...timeSlot, serviceDateTimeId: timeSlot.attendanceId ? timeSlot.attendanceId : timeSlot.bookingId };
      }),
      address: bookingItem.address,
      paymentSourceType: this.state.selectedPaymentSource,
    });
    this.setState({ isLoading: false });
  };

  private _getStepTitle = () => {
    const { step } = this.state;
    if (step === 1) return 'Change preferred payment method';
    if (step === 2) return 'Change preferred payment method';
    if (step === 3) return 'Select which VCP items you wish to charge for this booking';
  };

  private _onChangePaymentSourceType = (e) => {
    this.setState({ selectedPaymentSource: e });
  };

  private _getTimeSlots = () => {
    const { bookingItem } = this.props;
    const { bookingRecurrences, selectedOption } = this.state;
    if (bookingItem.isRecurring && selectedOption !== EditRecurringMode.Current) {
      const recurrences = bookingRecurrences?.bookingInRecurrenceList;
      if (selectedOption === EditRecurringMode.Upcoming) {
        return _.filter(recurrences, (booking) => moment(booking.startDateTime) > moment());
      } else if (selectedOption === EditRecurringMode.CurrentAll) {
        return _.filter(recurrences, (booking) => moment(booking.startDateTime) > moment(bookingItem.startDateTime));
      }
    } else {
      return [
        {
          startDateTime: bookingItem.startDateTime,
          endDateTime: bookingItem.endDateTime,
          bookingId: bookingItem.bookingId,
        },
      ];
    }
  };

  componentDidMount = async () => {
    if (this.props.bookingItem.isRecurring) {
      const bookingRecurrences: any = await this.props.doFetchRecurringBookingsList({
        bookingId: this.props.bookingItem.bookingId,
      });
      this.setState({ bookingRecurrences });
    }
  };

  componentDidUpdate = async (prevProps: Readonly<IChangePreferredPaymentMethodModalProps>) => {
    if (this.props.bookingItem.isRecurring && !this.state.bookingRecurrences) {
      const bookingRecurrences =
        prevProps.bookingItem !== this.props.bookingItem
          ? await this.props.doFetchRecurringBookingsList({
              bookingId: this.props.bookingItem.bookingId,
            })
          : {
              bookingInRecurrenceList: this.props.recurringBookingList,
              recurringPattern: this.props.bookingRecurringPattern,
            };
      this.setState({ bookingRecurrences });
    }
  };

  render() {
    const {
      isOpen,
      locationMmmGroup,
      locationState,
      getServiceAgreementTitle,
      displayRecurringModeStep = false,
      customerBookingPaymentDetail,
      bookingItem,
    } = this.props;
    const { selectedLineItems, selectedPaymentSource, step, isLoading } = this.state;

    return (
      <ActionModal
        isOpen={isOpen}
        onClose={this._onClose}
        width={step === 3 ? 'x3-large' : 'large'}
        title={this._getStepTitle()}
      >
        {step === 1 && (
          <>
            <Paragraph>Select the preferred payment method for this booking</Paragraph>
            <Select
              size={'large'}
              style={{ width: '200px' }}
              value={this.state.selectedPaymentSource}
              onChange={this._onChangePaymentSourceType}
            >
              {_.map(this.props.paymentSourceAvailableTypes, (paymentSource) => (
                <Select.Option value={PaymentSources[paymentSource]}>{PaymentSources[paymentSource]}</Select.Option>
              ))}
            </Select>
            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                className="mr-medium"
                disabled={_.isEmpty(selectedPaymentSource)}
                onClick={
                  displayRecurringModeStep && bookingItem.numberOfBookingLeft > 0
                    ? this._displayRecurringModeMenu
                    : this._goToLineItemCharged
                }
              >
                Continue
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 2 && (
          <>
            <div className="anim-slide-left">
              <Paragraph>
                The selected booking is part of a recurring booking series. Please select one of the following options
                for editing this booking
              </Paragraph>
            </div>
            <div>
              <div className="mb-small">
                <Text weight="bold">Edit preferred payment for:</Text>
              </div>
              <Radio.Group
                value={this.state.selectedOption}
                onChange={this._onChangeOption}
                className="ml-medium flex-wrap"
              >
                <Radio
                  value={EditRecurringMode.Current}
                  className={`${
                    this.state.selectedOption === EditRecurringMode.Current && 'text-weight-bold'
                  } mb-small `}
                >
                  <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                    This booking only.
                  </div>
                </Radio>
                <br />
                {/* KMS-424 : Temporary hide option all upcoming  */}
                {/* <Radio
                  value={EditRecurringMode.Upcoming}
                  className={`${this.state.selectedOption === EditRecurringMode.Upcoming &&
                    'text-weight-bold'} mb-small `}
                >
                  <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                    This booking and all upcoming bookings.
                  </div>
                </Radio>
                <br /> */}
                <Radio
                  value={EditRecurringMode.CurrentAll}
                  className={`${
                    this.state.selectedOption === EditRecurringMode.CurrentAll && 'text-weight-bold'
                  } mb-small `}
                >
                  <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                    This booking and all following bookings.
                  </div>
                </Radio>
              </Radio.Group>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._goToLineItemCharged}>
                  Continue
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <Paragraph>
              We will automatically calculate the exact value for the line items based on the details of the booking.
            </Paragraph>
            <div className={'mt-large'} style={{ maxHeight: 'calc(60vh)', overflow: 'auto' }}>
              {isLoading ? (
                <SpinningLoader size={150} message={'Fetching line items...'} />
              ) : (
                _.map(
                  customerBookingPaymentDetail && customerBookingPaymentDetail.billingSuggestion,
                  (billingPeriod, index) => {
                    const periodSelectedLineItems = _.find(
                      selectedLineItems,
                      (period) => period.startDateTime === billingPeriod.startDateTime,
                    );
                    return (
                      <>
                        <div className={'mb-small'}>{getServiceAgreementTitle(billingPeriod)}</div>
                        <div className={'mb-x2-large pt-medium bordered rounded-big shadow-container'} key={index}>
                          <NDISLineItemGrid
                            lineItems={
                              !_.isEmpty(billingPeriod.serviceAgreementBillingItems)
                                ? billingPeriod.serviceAgreementBillingItems
                                : !_.isEmpty(billingPeriod.serviceBillingItems)
                                ? billingPeriod.serviceBillingItems
                                : null
                            }
                            displayMode={'VIEW'}
                            isSelectable={true}
                            selectedLineItems={periodSelectedLineItems ? periodSelectedLineItems.selectedLineItems : []}
                            isServiceAgreementLineItems={!_.isEmpty(billingPeriod.serviceAgreementBillingItems)}
                            onClickLineItemCheckbox={(lineItems) =>
                              this._onClickLineItemCheckbox(lineItems, billingPeriod)
                            }
                            onSequenceChange={() => false}
                            mmmGroup={locationMmmGroup}
                            state={locationState}
                            noMargin={true}
                            paymentSourceType={selectedPaymentSource}
                          />
                        </div>
                      </>
                    );
                  },
                )
              )}
            </div>
            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" className="mr-medium" onClick={this._onSaveSelection}>
                Save selection
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  customerBookingPaymentDetail: state.bookingsStore.customerBookingPaymentDetail,
  recurringBookingList: state.bookingsStore.recurringBookingList,
  bookingRecurringPattern: state.bookingsStore.bookingRecurringPattern,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchBookingPaymentDetail: dispatch.bookingsStore.doFetchBookingPaymentDetail,
  doFetchRecurringBookingsList: dispatch.bookingsStore.doFetchRecurringBookingsList,
});

export default connect(mapState, mapDispatch)(ChangePreferredPaymentMethodModal);
