import _ from 'lodash';
import { IRootState } from 'stores/rematch/root-store';
import apiClient from 'utilities/api-client';
import { getStepAvailable } from 'views/workflows/utils/workflow-utils';
import {
  IActiveWorkflow,
  IActivityLog,
  IAddWorkflowTemplateWizard,
  IVersionHistoryWorkflowTemplate,
  IWorkflow,
  IWorkflowAction,
  IWorkflowAttachment,
  IWorkflowComment,
  IWorkflowLinkedFormAttachment,
  IWorkflowListItem,
  IWorkflowListOnProfile,
  IWorkflowListStatus,
  IWorkflowSelected,
  IWorkflowSubmittedForm,
  IWorkflowTemplate,
  IWorkflowTemplateListItem,
} from 'interfaces/workflow-interfaces';
import type { HttpCheckCanViewWorkflowDetailResponse } from '@handlers/http/portal/workflow/view/http-check-can-view-workflow-detail';

interface IWorkflowStoreState {
  // init workflow template store
  workflowTemplates: IWorkflowTemplateListItem[];
  workflowTemplateFilter: any;
  filteredWorkflowTemplates: IWorkflowTemplateListItem[];
  selectedWorkflowTemplate: IWorkflowTemplate;
  addWorkflowTemplateWizard: IAddWorkflowTemplateWizard;
  versionHistoryWorkflowTemplates: IVersionHistoryWorkflowTemplate[];
  notSaveStepData?: boolean;
  // init workflow (active workflow) store
  allWorkflows: IWorkflowListItem[];
  allApprovalWorkflows: IWorkflowListItem[];
  numberWorkflowByStatus: IWorkflowListStatus[];
  selectedWorkflow: IWorkflow;
  selectedWorkflowAction: IWorkflowAction;
  workflowLinkedForm: IWorkflowLinkedFormAttachment;
  workflowSubmittedForms: IWorkflowSubmittedForm[];
  workflowAttachments: IWorkflowAttachment[];
  workflowCommentList: IWorkflowComment[];
  activityLogs: IActivityLog[];
  workflowListOnProfile: IWorkflowListOnProfile[];
  workflowSelected: IWorkflowSelected;
  activeWorkflowList: IActiveWorkflow[];
  activeStatusWorkflowTotal: IWorkflowListStatus[];
  workflowForm: any;
}

const initialState: IWorkflowStoreState = {
  workflowTemplates: [],
  workflowTemplateFilter: [],
  filteredWorkflowTemplates: [],
  selectedWorkflowTemplate: null,
  addWorkflowTemplateWizard: null,
  versionHistoryWorkflowTemplates: [],
  notSaveStepData: false,
  selectedWorkflow: null,
  selectedWorkflowAction: null,
  allWorkflows: [],
  allApprovalWorkflows: [],
  numberWorkflowByStatus: [],
  workflowLinkedForm: null,
  workflowSubmittedForms: [],
  workflowAttachments: [],
  workflowCommentList: [],
  activityLogs: [],
  workflowListOnProfile: [],
  workflowSelected: null,
  activeWorkflowList: [],
  activeStatusWorkflowTotal: [],
  workflowForm: {
    formContent: {
      title: {
        formTitle: '',
        formDescription: '',
      },
      elements: [],
    },
  },
};

const reducers = {
  setWorkflowTemplates: (state, payload) => ({ ...state, workflowTemplates: payload }),
  setFilteredWorkflowTemplates: (state, payload) => ({ ...state, filteredWorkflowTemplates: payload }),
  setSelectedWorkflowTemplate: (state, payload) => ({ ...state, selectedWorkflowTemplate: payload }),
  setVersionHistoryWorkflowTemplates: (state, payload) => ({ ...state, versionHistoryWorkflowTemplates: payload }),
  setAddWorkflowTemplateWizard: (state, payload: IAddWorkflowTemplateWizard) => {
    return { ...state, addWorkflowTemplateWizard: payload };
  },

  setStateSaveStepDataWorkflowTemplate: (state, payload) => ({ ...state, notSaveStepData: payload }),
  setWorkflowListOnProfile: (state, payload) => ({ ...state, workflowListOnProfile: payload }),
  setSelectedWorkflow: (state, payload: { workflowDto: IWorkflow; action: IWorkflowAction }) => ({
    ...state,
    selectedWorkflow: payload.workflowDto,
    selectedWorkflowAction: payload.action,
  }),
  setAllWorkflows: (state, payload) => ({ ...state, allWorkflows: payload }),
  setAllApprovalWorkflows: (state, payload) => ({ ...state, allApprovalWorkflows: payload }),
  setNumberWorkflowByStatus: (state, payload) => ({ ...state, numberWorkflowByStatus: payload }),
  setWorkflowLinkedForm: (state, payload) => ({ ...state, workflowLinkedForm: payload }),
  setWorkflowSubmittedForms: (state, payload) => ({ ...state, workflowSubmittedForms: payload }),
  setWorkflowCommentList: (state, payload) => ({ ...state, workflowCommentList: payload }),
  setWorkflowAttachments: (state, payload) => ({ ...state, workflowAttachments: payload }),
  setActivityLog: (state, payload) => ({ ...state, activityLogs: payload }),
  setWorkflowSelected: (state, payload) => ({ ...state, workflowSelected: payload }),
  setActiveWorkflowList: (state, payload) => ({ ...state, activeWorkflowList: payload }),
  setActiveStatusWorkflowTotal: (state, payload) => ({
    ...state,
    activeStatusWorkflowTotal: payload,
  }),
  setWorkflowForm: (state, payload) => ({ ...state, workflowForm: payload }),
  setWorkflowAttachmentStatus: (state, payload) => {
    const updatedAttachments = _.map(state.workflowAttachments, (attachment) => {
      if (
        payload.workflowAttachmentId === attachment.workflowAttachmentId ||
        payload.workflowFormId === attachment.workflowFormId
      ) {
        return {
          ...attachment,
          url: payload.documentUrl,
          documentStatus: payload.status,
        };
      } else {
        return { ...attachment };
      }
    });

    return {
      ...state,
      workflowAttachments: updatedAttachments,
    };
  },
};

const workflowStore = {
  state: { ...initialState },

  reducers,

  effects: (dispatch) => ({
    getListWorkflowTemplates: async (payload): Promise<Array<any>> => {
      try {
        const filters = payload ? payload : {};
        // TODO: generate search with filter params
        const response = await apiClient.post(`/api/portal/workflow-templates/list`, filters);

        return response.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doGetWorkflowTemplates: async (payload, rootState: IRootState) => {
      try {
        const filters = payload ? payload : {};
        const response = await apiClient.post(`/api/portal/workflow-templates/list`, filters);
        let workflowTemplates = response.data;

        if (payload.page > 1) {
          workflowTemplates = rootState.workflowStore.workflowTemplates.concat(workflowTemplates);
        }

        dispatch.workflowStore.setWorkflowTemplates(workflowTemplates);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doCreateNewWorkflowTemplate: async (payload) => {
      try {
        const response = await apiClient.post(`/api/portal/workflow-templates/create`, payload);

        return response.data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    doCheckWorkflowTemplateNameExist: async (payload) => {
      try {
        const response = await apiClient.post(`/api/portal/workflow-templates/check-exist`, payload);

        return response.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doGetWorkflowTemplateConfigurations: async (payload, state: IRootState) => {
      try {
        const response = await apiClient.get(
          `/api/portal/workflow-templates/${payload.workflowTemplateId}/configurations`,
        );

        dispatch.workflowStore.setSelectedWorkflowTemplate({
          ...state.workflowStore.selectedWorkflowTemplate,
          ...response.data,
        });

        return response.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doUpdateWorkflowTemplateConfigurations: async (payload, rootState: IRootState) => {
      try {
        const { selectedWorkflowTemplate } = rootState.workflowStore;

        const endPoint = `/api/portal/workflow-templates/${selectedWorkflowTemplate.workflowTemplateId}/configurations`;

        const response = await apiClient.put(endPoint, {
          ...selectedWorkflowTemplate,
          ...payload,
        });

        if (response.status === 200) {
          dispatch.workflowStore.setSelectedWorkflowTemplate({ ...selectedWorkflowTemplate, ...response.data });
          return true;
        }
      } catch (e) {
        return false;
      }
    },

    doUpdateWorkflowTemplateSteps: async (payload, rootState: IRootState) => {
      try {
        const workflowTemplateId = rootState.workflowStore.selectedWorkflowTemplate.workflowTemplateId;
        const endPoint = `/api/portal/workflow-templates/${workflowTemplateId}/steps`;

        const data = {
          workflowTemplateUniqueId: payload.workflowTemplateUniqueId,
          steps: payload.steps,
        };

        const response = await apiClient.put(endPoint, data);
        if (response.status === 200) {
          dispatch.workflowStore.setSelectedWorkflowTemplate(response.data.workflowTemplate);
          return response.data;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doGetWorkflowTemplateSteps: async (payload) => {
      try {
        const response = await apiClient.get(`/api/portal/workflow-templates/${payload.workflowTemplateId}/steps`);
        return response.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doGetWorkflowTemplateVersionHistoryList: async (workflowTemplateId) => {
      try {
        const response = await apiClient.get(`/api/portal/workflow-templates/${workflowTemplateId}/versions`);
        const workflowTemplateVersions = response.data;

        dispatch.workflowStore.setVersionHistoryWorkflowTemplates(workflowTemplateVersions);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doFetchWorkflowListOnProfile: async (payload, rootState: IRootState) => {
      try {
        const response = await apiClient.post(`/api/portal/workflows/${payload.userId}/list`, payload);
        let workflowListOnProfile = response.data;

        if (payload.page > 1) {
          workflowListOnProfile = rootState.workflowStore.workflowListOnProfile.concat(workflowListOnProfile);
        }

        dispatch.workflowStore.setWorkflowListOnProfile(workflowListOnProfile);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doCreateNewWorkflowComment: async (payload) => {
      const { workflowId } = payload;
      try {
        const endPoint = `/api/portal/workflows/${workflowId}/comments`;
        const data = { content: payload.content };
        const response = await apiClient.post(endPoint, data);

        if (response.data) {
          await dispatch.workflowStore.doGetWorkflowComments(workflowId);
          return response.data;
        }
      } catch (e) {
        return e;
      }
    },

    doGetWorkflowDetail: async (workflowId) => {
      try {
        const response = await apiClient.get(`/api/portal/workflows/${workflowId}/info`);
        const workflow = response.data;

        if (response.status === 200) {
          dispatch.workflowStore.setSelectedWorkflow(workflow);
        }

        return response;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doGetApprovalWorkflowsList: async (payload, rootState: IRootState) => {
      try {
        const filters = payload ? payload : {};
        const response = await apiClient.post(`/api/portal/workflows/approval-list`, filters);
        let allApprovalWorkflows = response.data;

        if (payload.page > 1) {
          allApprovalWorkflows = rootState.workflowStore.allApprovalWorkflows.concat(allApprovalWorkflows);
        }

        dispatch.workflowStore.setAllApprovalWorkflows(allApprovalWorkflows);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doGetWorkflowComments: async (workflowId) => {
      try {
        const response = await apiClient.get(`/api/portal/workflows/${workflowId}/comments`);
        const comments = response.data;

        dispatch.workflowStore.setWorkflowCommentList(comments);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doRemoveWorkflowComment: async (payload) => {
      const { workflowId, workflowCommentId } = payload;
      try {
        const endPoint = `/api/portal/workflows/${workflowId}/comments/${workflowCommentId}`;
        const response = await apiClient.delete(endPoint);

        if (response.data.isDeleted) {
          await dispatch.workflowStore.doGetWorkflowComments(workflowId);
          return response.data;
        }
      } catch (e) {
        return e;
      }
    },

    doUpdateWorkflowComment: async (payload) => {
      const { workflowId, workflowCommentId } = payload;
      try {
        const endPoint = `/api/portal/workflows/${workflowId}/comments/${workflowCommentId}`;
        const data = { content: payload.content };
        const response = await apiClient.put(endPoint, data);

        if (response.status === 200) {
          await dispatch.workflowStore.doGetWorkflowComments(workflowId);
          return response.data;
        }
      } catch (e) {
        return e;
      }
    },

    doGetAllWorkflows: async (payload, rootState: IRootState) => {
      try {
        const filters = payload ? payload : {};
        const response = await apiClient.post(`/api/portal/workflows/list`, filters);
        let allWorkflows = response.data;

        if (payload.page > 1) {
          allWorkflows = rootState.workflowStore.allWorkflows.concat(allWorkflows);
        }

        dispatch.workflowStore.setAllWorkflows(allWorkflows);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doGetWorkflowSubmittedForms: async (payload) => {
      try {
        const { workflowId } = payload;
        const response = await apiClient.post(`/api/portal/workflows/${workflowId}/submitted-forms`, payload);

        if (response.status === 200) {
          dispatch.workflowStore.setWorkflowSubmittedForms(response.data);
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doGetAttachments: async (payload) => {
      try {
        const { workflowId, stepNumber } = payload;
        const response = await apiClient.get(
          `/api/portal/workflows/${workflowId}/attachments?stepNumber=${stepNumber}`,
        );

        if (response.status === 200) {
          dispatch.workflowStore.setWorkflowAttachments(response.data);
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doCreateAttachment: async (payload) => {
      try {
        const data = {
          name: payload.name,
          description: payload.description,
        };

        const response = await apiClient.post(`/api/portal/workflows/${payload.workflowId}/attachments`, data);
        if (response.status === 200) {
          return response.data;
        }
      } catch (e) {
        return e;
      }
    },

    doUpdateAttachment: async (payload) => {
      try {
        const data = {
          description: payload.description,
        };
        const response = await apiClient.put(
          `/api/portal/workflows/${payload.workflowId}/attachments/${payload.workflowAttachmentId}`,
          data,
        );
        return response.data;
      } catch (e) {
        return e;
      }
    },

    doDeleteAttachment: async (payload) => {
      try {
        const response = await apiClient.delete(
          `/api/portal/workflows/${payload.workflowId}/attachments/${payload.workflowAttachmentId}`,
        );

        return response.data;
      } catch (e) {
        return e;
      }
    },

    doGetNumberWorkflowByStatus: async (payload) => {
      try {
        const response = await apiClient.post(`/api/portal/workflows/number-workflow-by-status`, payload);

        if (response.data) {
          dispatch.workflowStore.setNumberWorkflowByStatus(response.data);
        }
      } catch (e) {
        return false;
      }
    },

    doGetActiveWorkflowList: async (payload, rootState: IRootState) => {
      const { workflowTemplateId } = payload;
      const filters = payload ? payload : {};
      try {
        const response = await apiClient.post(
          `/api/portal/workflow-templates/${workflowTemplateId}/active-workflows`,
          filters,
        );
        let activeWorkflowList = response.data;

        if (payload.page > 1) {
          activeWorkflowList = rootState.workflowStore.activeWorkflowList.concat(activeWorkflowList);
        }

        if (response.status === 200) {
          dispatch.workflowStore.setActiveWorkflowList(activeWorkflowList);
        }
      } catch (e) {
        return false;
      }
    },

    doGetActiveStatusWorkflowTotal: async (payload) => {
      const { workflowTemplateId } = payload;
      const filters = payload ? payload : {};
      try {
        const response = await apiClient.post(
          `/api/portal/workflow-templates/${workflowTemplateId}/number-active-workflows`,
          filters,
        );

        if (response.data) {
          dispatch.workflowStore.setActiveStatusWorkflowTotal(response.data);
        }
      } catch (e) {
        return false;
      }
    },

    doAddUsersInvolved: async (payload) => {
      try {
        const data = {
          members: payload.members,
          type: payload.type,
        };
        const response = await apiClient.post(`api/portal/workflows/${payload.workflowId}/add-member`, data);

        return response.data;
      } catch (e) {
        return e;
      }
    },

    doGetWorkflowActivityLog: async (payload, rootState) => {
      try {
        const endPoint = `/api/portal/workflows/${payload.workflowId}/activity-logs`;
        const response = await apiClient.post(endPoint, payload);

        if (response) {
          let activityLogs = response.data;
          if (payload.page > 1) {
            activityLogs = rootState.workflowStore.activityLogs.concat(activityLogs);
          }
          dispatch.workflowStore.setActivityLog(activityLogs);
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doAddApprovers: async (payload) => {
      try {
        const data = {
          approvers: payload.approvers,
          workflowStepId: payload.workflowStepId,
          stepNumber: payload.stepNumber,
        };
        const response = await apiClient.post(`api/portal/workflows/${payload.workflowId}/add-approver`, data);

        return response.data;
      } catch (e) {
        return e;
      }
    },

    doTriggerManualWorkflow: async (payload) => {
      try {
        await apiClient.post(`/api/portal/workflows/manually`, payload);
      } catch (e) {
        return false;
      }
    },

    doFlagIssueWorkflow: async (payload) => {
      try {
        const response = await apiClient.put(`api/portal/workflows/${payload.workflowId}/flag-issue`, payload);

        return response.data;
      } catch (e) {
        return e;
      }
    },

    doResolveIssueWorkflow: async (payload) => {
      try {
        const response = await apiClient.put(`api/portal/workflows/${payload.workflowId}/resolve`, payload);

        return response.data;
      } catch (e) {
        return e;
      }
    },

    doSendBackStepWorkflow: async (payload) => {
      try {
        const response = await apiClient.put(`api/portal/workflows/${payload.workflowId}/send-back`, payload);

        return response.data;
      } catch (e) {
        return e;
      }
    },

    doUndoCompletionWorkflow: async (payload) => {
      try {
        const response = await apiClient.put(`api/portal/workflows/${payload}/undo`);

        return response.data;
      } catch (e) {
        return e;
      }
    },

    doReopenWorkflow: async (payload) => {
      try {
        const response = await apiClient.put(`api/portal/workflows/${payload}/re-open`);

        return response.data;
      } catch (e) {
        return e;
      }
    },

    doCloseWorkflow: async (payload) => {
      try {
        const data = {
          closeReason: payload.closeReason,
        };

        const response = await apiClient.put(`api/portal/workflows/${payload.workflowId}/close`, data);

        return response.data;
      } catch (e) {
        return e;
      }
    },

    doApproveWorkflowStep: async (payload) => {
      try {
        const data = {
          workflowStepId: payload.workflowStepId,
          stepNumber: payload.stepNumber,
        };

        const response = await apiClient.put(`api/portal/workflows/${payload.workflowId}/approve`, data);

        return response.data;
      } catch (e) {
        return e;
      }
    },

    doCheckViewWorkflowDetails: async (payload: { workflowId: string }) => {
      try {
        const response = await apiClient.get<HttpCheckCanViewWorkflowDetailResponse>(
          `api/portal/workflows/${payload.workflowId}/can-view`,
        );

        if (response.status === 200) {
          return response.data;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    doGetLinkedFormAttachment: async (payload, rootState: IRootState) => {
      try {
        const { selectedWorkflow } = rootState.workflowStore;

        const step = getStepAvailable(selectedWorkflow.steps);

        const body = {
          workflowStepId: step.workflowStepId,
        };

        const response = await apiClient.post(`api/portal/workflows/${selectedWorkflow.workflowId}/linked-form`, body);

        if (response) {
          dispatch.workflowStore.setWorkflowLinkedForm(response.data);
        }

        return response.data;
      } catch (e) {
        return false;
      }
    },

    doAddLinkedFormAttachment: async (payload) => {
      try {
        const body = {
          type: payload.type,
          workflowStepId: payload.workflowStepId,
          status: payload.status,
        };

        if (payload.type === 'attachment') {
          body['name'] = payload.name;
        } else {
          body['formVersionId'] = payload.formVersionId;
          body['formData'] = payload.formData;
        }

        const response = await apiClient.post(
          `/api/portal/workflows/${payload.workflowId}/linked-form-attachment`,
          body,
        );

        return response.data;
      } catch (e) {
        return false;
      }
    },

    doGetWorkflowFormDetail: async (payload) => {
      try {
        const { data } = await apiClient.get(
          `/api/portal/workflows/${payload.workflowFormId}/view-form?isFromNote=${!!payload.isFromNote}`,
        );
        if (data) {
          const title = { formTitle: data.formName, formDescription: data.formDescription };
          const elements = data.formElements;
          const status = data.status;
          const version = data.version;
          const formContent = { title, elements, status, version };
          const workflowForm = {
            canEditForm: data.canEditForm,
            workflowFormId: data.workflowFormId,
            formData: data.formData,
            formContent,
          };
          dispatch.workflowStore.setWorkflowForm(workflowForm);
          return workflowForm;
        }
      } catch (error) {
        throw error;
      }
    },

    doUpdateWorkflowForm: async (payload) => {
      try {
        const { workflowFormId, ...data } = payload;
        const response = await apiClient.put(`/api/portal/workflows/${workflowFormId}/update-form`, data);
        if (response.status === 200) {
          return response.data;
        }
      } catch (error) {
        throw error;
      }
    },

    doCreateSubmittedForm: async (payload) => {
      try {
        const data = {
          formVersionId: payload.formVersionId,
          formData: payload.formElement,
          status: payload.status,
        };

        const response = await apiClient.post(`/api/portal/workflows/${payload.workflowId}/add-form`, data);
        if (response.status === 200) {
          return response.data;
        }
      } catch (e) {
        return e;
      }
    },

    doDeleteSubmittedForm: async (payload) => {
      try {
        const response = await apiClient.delete(`/api/portal/workflows/${payload.workflowFormId}/delete-form`);
        if (response.status === 200) {
          return;
        }
      } catch (e) {
        return e;
      }
    },
  }),
};

export default workflowStore;
