import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { APP_CONFIG } from '../../../../config/app-config';

import accountManagement from './accountManagement.json';
import availability from './availability.json';
import bookings from './bookings.json';
import budget from './budget.json';
import budgetStatus from './budgetStatus.json';
import customerFunding from './customerFunding.json';
import insights from './insights.json';
import permissions from './permissions.json';
import schedules from './schedules.json';
import serviceAgreements from './serviceAgreements.json';
import serviceDates from './serviceDates.json';
import teamMember from './teamMember.json';
import warningMessages from './warningMessages.json';
import payments from './payments.json';
import alerts from './alerts.json';
import rostering from './rostering.json';
import silService from './sil-service.json';
import services from './services.json';
import subscriptions from './subscriptions.json';

import { enAu } from './en-AU';
import { enUs } from './en-US';

const isDevelopment = APP_CONFIG.meta.MODE === 'development';

const translationEn = {
  accountManagement,
  availability,
  bookings,
  budget,
  budgetStatus,
  insights,
  permissions,
  schedules,
  serviceAgreements,
  serviceDates,
  teamMember,
  warningMessages,
  payments,
  customerFunding,
  alerts,
  rostering,
  silService,
  services,
  subscriptions,
};

const resources = {
  'en-US': {
    translation: { ...translationEn, ...enUs.translation },
  },
  'en-AU': {
    translation: { ...translationEn, ...enAu.translation },
  },
  en: {
    translation: { ...translationEn, ...enAu.translation },
  },
};

use(initReactI18next)
  .init({
    ns: ['translation'],
    fallbackNS: ['translation'],
    fallbackLng: 'en',
    debug: isDevelopment,
    resources,
  })
  .catch((e: Error) => {
    console.error(`Encountered an error while loading translations: ${e.message}`);
  });

export default i18n;
