import React, { useState, useEffect, useCallback } from 'react';
import { useInViewport } from '@mantine/hooks';
import { Box, Stack } from '@mantine/core';
import { CheckboxSkeleton } from './checkbox-skeleton';
export const InfiniteScrollCheckbox = ({ hasMore, loadMore, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { ref, inViewport } = useInViewport();
    const load = useCallback(async () => {
        try {
            setIsLoading(true);
            await loadMore?.();
        }
        finally {
            setIsLoading(false);
        }
    }, []);
    useEffect(() => {
        const shouldLoadMore = Boolean(inViewport && hasMore && !isLoading && loadMore);
        if (shouldLoadMore) {
            void load();
        }
    }, [inViewport, isLoading, hasMore, load]);
    return (<>
      {children}
      {hasMore ? (<Stack my='sm' mx='xs'>
          <CheckboxSkeleton />
          <CheckboxSkeleton />
        </Stack>) : null}
      <Box ref={ref} h={1}/>
    </>);
};
