import { useState, useEffect } from 'react';

/**
 * Custom React hook that manages a search parameter in the URL.
 *
 * @template T - The optional type of the search parameter value.
 * @param {string} searchParamName - The name of the search parameter.
 * @param {T} defaultSearchParam - The default value of the search parameter.
 * @returns {[T, React.Dispatch<React.SetStateAction<T>>]} - A tuple containing the current search parameter value and a function to update it.
 */
export const useSearchParam = <T extends string>(searchParamName: string, defaultSearchParam: T) => {
  const [searchParam, setSearchParam] = useState<T>(
    (new URLSearchParams(window.location.search).get(searchParamName) as T) || defaultSearchParam,
  );

  useEffect(() => {
    if (searchParam) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(searchParamName, searchParam);
      window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
    }
  }, [searchParam, searchParamName]);

  return [searchParam, setSearchParam] as const;
};
