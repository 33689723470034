import React, { useEffect, useState } from 'react';
import {
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  flexRender,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { ServiceAgreement } from '../types';
import { formatTableData } from './format-table-data';
import { useTableColumns } from './use-table-columns';

export const ServiceAgreementsTable = (props: { serviceAgreements: ServiceAgreement[] }): JSX.Element => {
  const { serviceAgreements } = props;
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const columns = useTableColumns();
  const table = useReactTable({
    data: formatTableData(serviceAgreements),
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    autoResetExpanded: true,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    setExpanded({});
  }, [serviceAgreements]);

  return (
    <>
      <table className="mt-10">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div className="text-size-regular mb-2">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className="text-sm pb-2 pt-2">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
