export const ROSTER_WEEKLY_CONFIG = {
  // left content panel
  CONTENT_PANEL_WIDTH: 320,

  // top hour block height/width
  HOUR_BLOCK_HEIGHT: 64,
  HOUR_BLOCK_WIDTH: 120,

  // content group
  GROUP_ROW_HEIGHT: 56,

  // Timeline row
  ITEM_ROW_HEIGHT: 88,
  ITEM_CARD_HEIGHT: 82,
  ITEM_CARD_PADDING: 4,

  // Footer
  FOOTER_BLOCK_HEIGHT: 18,

  // !!! This should be updated every time the side panel width is changed !!!
  SIDEBAR_WIDTH: 216,
};
