import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Replay15 = forwardRef(function Replay15(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.77 4.924C6.363 3.596 8.778 2.25 12 2.25A9.731 9.731 0 0 1 21.75 12c0 5.385-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12a.75.75 0 0 1 1.5 0 8.25 8.25 0 0 0 16.5 0A8.231 8.231 0 0 0 12 3.75c-2.777 0-4.863 1.154-6.27 2.326a11.346 11.346 0 0 0-1.582 1.611 8.93 8.93 0 0 0-.502.694l-.003.005A.75.75 0 0 1 2.25 8V3.5a.75.75 0 0 1 1.5 0v2.372c.293-.303.633-.626 1.02-.948ZM9 8.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V9A.75.75 0 0 1 9 8.25Zm3 0a.75.75 0 0 0-.75.75v3c0 .414.336.75.75.75h2.25v1.5H12a.75.75 0 1 0 0 1.5h3a.75.75 0 0 0 .75-.75v-3a.75.75 0 0 0-.75-.75h-2.25v-1.5H15a.75.75 0 0 0 0-1.5h-3Z"
        fill="currentColor"
      />
    </Icon>
  );
});
