import React, { Component } from 'react';
import { Checkbox, InputNumber } from 'antd';
import TimeInput from 'common-components/time-input/TimeInput';
import moment from 'moment';
import _ from 'lodash';

interface IServiceTimeRecurrenceItemProps {
  item: any;
  eventHandler: any;
  timeAvailabilities?: any;
}

class ServiceTimeRecurrenceItem extends Component<IServiceTimeRecurrenceItemProps> {
  state = {};

  render() {
    const { item, timeAvailabilities } = this.props;

    const maxBillableHours = moment.duration(item.endDateTime.diff(item.startDateTime)).asHours();

    const times = {
      startDateTime: moment(item.startDateTime.format('YYYY-MM-DD HH:mm')),
      endDateTime: moment(item.endDateTime.format('YYYY-MM-DD HH:mm')),
    };

    return (
      <>
        <tr style={{ height: '55px' }}>
          <td>
            <Checkbox checked={item.selected} onClick={() => this.props.eventHandler('checkbox', item.dayOfWeek)} />
          </td>
          <td className={!item.selected && 'text-color-tertiary'}>{item.day}</td>
          {item.selected ? (
            <>
              <td className="pv-small">
                <TimeInput
                  size="large"
                  disabled={!item.selected}
                  value={times.startDateTime}
                  onChange={(event) => this.props.eventHandler('startDate', item.dayOfWeek, event)}
                />
              </td>
              <td>
                <TimeInput
                  size="large"
                  disabled={!item.selected}
                  value={times.endDateTime}
                  onChange={(event) => this.props.eventHandler('endDate', item.dayOfWeek, event)}
                />
              </td>
              <td>{_.round(maxBillableHours, 2)} h</td>
              <td>
                <InputNumber
                  value={item.billableHours}
                  style={{ maxWidth: '75px' }}
                  disabled={!item.selected}
                  min={0}
                  precision={2}
                  max={maxBillableHours}
                  className="mr-small"
                  onChange={(event) => this.props.eventHandler('billableHours', item.dayOfWeek, event)}
                />
                h
              </td>
              <td>
                {timeAvailabilities
                  ? timeAvailabilities.filter((time) => {
                      return time.dayOfWeek === item.dayOfWeek;
                    }).length
                  : '-'}
              </td>
            </>
          ) : (
            <>
              <td className="pv-small">-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </>
          )}
        </tr>{' '}
      </>
    );
  }
}

export default ServiceTimeRecurrenceItem;
