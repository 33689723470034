import { useQuery } from '@tanstack/react-query';

import { fetchCustomerRatio } from 'stores/queries/roster/roster-queries';

export function useFetchCustomerRatio({ bookingId }, enabled) {
  const { data, remove, refetch, isFetching, isLoading, status, error } = useQuery(
    ['customer-ratios', bookingId],
    () => fetchCustomerRatio({ bookingId }),
    {
      enabled,
    },
  );

  return { data, remove, refetch, isFetching, isLoading, status, error };
}
