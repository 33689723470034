import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { SubTitle, Text } from 'common-components/typography';
import { Input, Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { IFormElement } from 'views/form-builder/shared/form-interface';
import {
  convertRulesRequiredOfBundle,
  getRules,
  getValueFromPropertyByKey,
} from 'views/form-builder/shared/form-builder-utils';
import { FormElementType, PropKey } from 'views/form-builder/shared/form-enum';
import { IRootState } from 'stores/rematch/root-store';
import { useSelector } from 'react-redux';

const FormItem = Form.Item;

interface IFullNameProps {
  fieldTitleFirstName?: string;
  fieldTitleLastName?: string;
  placeholderFirstName?: string;
  placeholderLastName?: string;
  caption?: string;
  isRequired?: boolean;
}

export const FullName = ({
  fieldTitleFirstName,
  fieldTitleLastName,
  placeholderFirstName,
  placeholderLastName,
  caption,
  isRequired,
}: IFullNameProps) => {
  const fieldTextFirstNameFormat = (
    <>
      {fieldTitleFirstName}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );
  const fieldTextLastNameFormat = (
    <>
      {fieldTitleLastName}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );

  return (
    <div className="flex-column width-full">
      <div className="flex width-full">
        <div className="flex-1 mr-x-small">
          <SubTitle weight="bolder">{fieldTextFirstNameFormat}</SubTitle>
          <Input
            size="large"
            className="mb-medium width-full mt-x-small bordered border-standard-gray text-size-large"
            placeholder={placeholderFirstName}
          />
        </div>
        <div className="flex-1 ml-x-small">
          <SubTitle weight="bolder">{fieldTextLastNameFormat}</SubTitle>
          <Input
            size="large"
            className="mb-medium width-full mt-x-small bordered border-standard-gray text-size-large"
            placeholder={placeholderLastName}
          />
        </div>
      </div>
      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
};

interface IEditFirstAndLastNameProps extends FormComponentProps {
  value?: string[];
  element: IFormElement;
}

export const EditFirstAndLastNameInput = ({ value, element, form }: IEditFirstAndLastNameProps) => {
  const { elementsBundleOptional } = useSelector((state: IRootState) => state.formBuilderStore);
  const { id, parentId } = element;
  const placeholderFirstName = getValueFromPropertyByKey(
    element.properties.appearance,
    PropKey.PLACEHOLDER_TEXT_FIRST_NAME,
  );
  const placeholderLastName = getValueFromPropertyByKey(
    element.properties.appearance,
    PropKey.PLACEHOLDER_TEXT_LAST_NAME,
  );
  const caption = getValueFromPropertyByKey(element.properties.appearance, PropKey.CAPTION_TEXT);

  const { getFieldDecorator, setFieldsValue, validateFields, getFieldError } = form;
  const isRequired = getValueFromPropertyByKey(element.properties.general, PropKey.REQUIRE);
  let fieldTitleFirstName = getValueFromPropertyByKey(element.properties.general, PropKey.FIELD_TITLE_FIRST_NAME);
  let fieldTitleLastName = getValueFromPropertyByKey(element.properties.general, PropKey.FIELD_TITLE_LAST_NAME);
  const [firstName, setFirstName] = useState<string>(value?.[0] ?? '');
  const [lastName, setLastName] = useState<string>(value?.[1] ?? '');
  let rules = getRules(element, false);
  const [messageErrorFirstName, setMessageErrorFirstName] = useState(getFieldError(`${id},firstName`)?.join(' '));
  const [messageErrorLastName, setMessageErrorLastName] = useState(getFieldError(`${id},lastName`)?.join(' '));
  const isRequiredBundle = !elementsBundleOptional.includes(id);

  if (isRequired) {
    rules = convertRulesRequiredOfBundle(isRequiredBundle, rules);

    fieldTitleFirstName = (
      <>
        {fieldTitleFirstName}
        {isRequiredBundle && <Text color="red"> *</Text>}
      </>
    );
    fieldTitleLastName = (
      <>
        {fieldTitleLastName}
        {isRequiredBundle && <Text color="red"> *</Text>}
      </>
    );
  }

  useEffect(() => {
    setFieldsValue({
      [`${id},value`]: [firstName, lastName],
    });
  }, [firstName, lastName]);

  const changeValue = useCallback((value, type) => {
    const fieldName = `${id},${type}`;

    if (type === 'firstName') {
      setFirstName(value);
    } else {
      setLastName(value);
    }

    setFieldsValue({
      [fieldName]: value,
    });

    validateFields([fieldName], (errors) => {
      const message = errors?.[fieldName]?.errors?.map((error) => error.message).join(' ');
      if (type === 'firstName') {
        setMessageErrorFirstName(message);
      } else {
        setMessageErrorLastName(message);
      }
    });
  }, []);

  const debounceChangeValue = debounce(changeValue, 300);

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const value = event.target.value;

    debounceChangeValue(value, type);
  };

  const getErrorMessage = (type: 'firstName' | 'lastName') => {
    const fieldName = `${id},${type}`;
    const valueInput = form.getFieldValue(fieldName);

    if (!valueInput && isRequired && !isRequiredBundle) {
      form.resetFields([fieldName]);
      return null;
    }

    if (!getFieldError(fieldName)) {
      return null;
    }

    return type === 'firstName' ? messageErrorFirstName : messageErrorLastName;
  };

  useEffect(() => {
    const fieldFirstNameError = getFieldError(`${id},firstName`)?.join(' ');
    const fieldLastNameError = getFieldError(`${id},lastName`)?.join(' ');

    if (fieldFirstNameError !== messageErrorFirstName) {
      setMessageErrorFirstName(fieldFirstNameError);
    }
    if (fieldLastNameError !== messageErrorLastName) {
      setMessageErrorLastName(fieldLastNameError);
    }
  }, [elementsBundleOptional]);

  return (
    <div className="flex-column width-full">
      <div className="flex width-full">
        <div className="flex-1 mr-x-small">
          <SubTitle weight="bolder">{fieldTitleFirstName}</SubTitle>
          <FormItem
            className="m-none"
            validateStatus={getErrorMessage('firstName') ? 'error' : ''}
            help={getErrorMessage('firstName')}
          >
            <Input
              id={`${id},value`}
              defaultValue={form.getFieldValue(`${id},firstName`) ?? firstName}
              size="large"
              className="width-full mt-x-small bordered text-size-large"
              placeholder={placeholderFirstName}
              onChange={(e) => onChangeValue(e, 'firstName')}
            />
          </FormItem>
          <FormItem className="m-none hide">
            {getFieldDecorator([id, 'firstName'] as never, {
              initialValue: value?.[0],
              rules: rules,
            })(<Input type="hidden" />)}
          </FormItem>
        </div>
        <div className="flex-1 ml-x-small">
          <SubTitle weight="bolder">{fieldTitleLastName}</SubTitle>
          <FormItem
            className="m-none"
            validateStatus={getErrorMessage('lastName') ? 'error' : ''}
            help={getErrorMessage('lastName')}
          >
            <Input
              id={`${id},value`}
              defaultValue={form.getFieldValue(`${id},lastName`) ?? lastName}
              size="large"
              className="width-full mt-x-small bordered text-size-large"
              placeholder={placeholderLastName}
              onChange={(e) => onChangeValue(e, 'lastName')}
            />
          </FormItem>
          <FormItem className="m-none hide">
            {getFieldDecorator([id, 'lastName'] as never, {
              initialValue: value?.[1],
              rules: rules,
            })(<Input type="hidden" />)}
          </FormItem>
        </div>
      </div>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'value'] as never)(<Input type="hidden" />)}
        {getFieldDecorator([id, 'type'] as never, {
          initialValue: FormElementType.FIRST_LAST_NAME,
        })(<Input type="hidden" />)}
      </FormItem>

      <FormItem className="m-none">
        {getFieldDecorator([id, 'parentElementId'] as never, {
          initialValue: parentId,
        })(<Input type="hidden" />)}
      </FormItem>

      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
};
