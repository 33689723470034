/* eslint-disable */
import React, { PureComponent } from 'react';
import CommonUtils from 'utilities/common-utils';
import TextTag from 'common-components/tags/TextTag';
import { WorkflowStatus } from 'utilities/enum-utils';

interface IWorkflowStatusTagProps {
  status: WorkflowStatus;
  size?: 'small' | 'default' | 'large';
  textSize?: string;
  className?: any;
  textClassName?: any;
  rounded?: boolean;
}

export default class WorkflowStatusTag extends PureComponent<IWorkflowStatusTagProps, any> {
  render() {
    const { status } = this.props;

    let tagColor = 'blue';
    let theme = 'dark';

    switch (status) {
      case WorkflowStatus.ACTIVE:
        tagColor = 'green';
        theme = 'dark';
        break;
      case WorkflowStatus.INACTIVE:
        tagColor = 'secondary';
        theme = 'light';
        break;
    }

    return (
      <TextTag content={CommonUtils.formatWorkflowStatus(status)} textSize="regular" theme={theme} color={tagColor} />
    );
  }
}
