import PermissionUtils from 'utilities/permission-utils';

import type { PortalUser } from 'stores/rematch/models/auth-store';

type ValueType = string | boolean | number | string[];
type EventData = Record<string, ValueType>;

type Appcues = {
  identify: (userId: string, data: EventData) => void;
  page: () => void;
  track: (eventName: string, data?: EventData) => void;
};

declare global {
  interface Window {
    Appcues: Appcues;
  }
}

const ACTION_EVENT = {
  ADD_COMPANY_ADDRESS: 'Add Company Address',
  ADD_CUSTOMER: 'Add Customer',
  ADD_TEAM_MEMBER_TO_SERVICE_ROSTER: 'Add team member to service roster',
  APPROVE_BOOKING: 'Approve Booking',
  ASSIGN_MEMBER: 'Assign Member',
  CREATE_ACTIVITY_RECORD: 'Create Activity Record',
  CREATE_BOOKING: 'Create Booking',
  CREATE_NEW_GROUP_SERVICE: 'Create New Group Service',
  CREATE_NEW_STANDARD_SERVICE: 'Create New Standard Service',
  CREATE_NEW_SUPPORT_COORDINATION_SERVICE: 'Create New Support Coordination Service',
  CREATE_SERVICE_AGREEMENT: 'Create Service Agreement',
  CREATE_TC_TEMPLATE: 'Create T&Cs Template',
  INVITE_TEAM_MEMBER: 'Invite Team Member',
  MARK_LINE_ITEM_AS_PAID: 'Mark Line Item As Paid',
  PROCESS_LINE_ITEM: 'Process Line Item',
  PUBLISH_BOOKING: 'Publish Booking',
  SET_FINANCE_FILE_DEFAULT: 'Set Finance File Default',
  SET_INVOICE_TERMS: 'Set Invoice Terms',
  SET_PAYROLL_FILE_DEFAULT: 'Set Payroll File Default',
  UPLOAD_LOGO: 'Upload Logo',
} as const;

type ACTION_EVENTS = keyof typeof ACTION_EVENT;

export const track = (name: ACTION_EVENTS) => (data: EventData) => {
  if (window.Appcues)
    try {
      window.Appcues.track(ACTION_EVENT[name], data);
    } catch (e) {
      console.error(`[Appcues] track event ${name} error`, e);
    }
};

export const identify = (userId: string, data: EventData) => {
  if (window.Appcues)
    try {
      window.Appcues.identify(userId, data);
    } catch (e) {
      console.error(`[Appcues] identify user properties error`, e);
    }
};

export const events = {
  trackAddCompanyAddress: track('ADD_COMPANY_ADDRESS'),
  trackAddCustomer(userId: string, data: EventData) {
    const { customerUserId: addedCustomerId } = data;
    track('ADD_CUSTOMER')(data);
    identify(userId, { addedCustomerId });
  },
  trackAddTeamMemberToServiceRoster: track('ADD_TEAM_MEMBER_TO_SERVICE_ROSTER'),
  trackApproveBooking: track('APPROVE_BOOKING'),
  trackAssignMemberToBooking: track('ASSIGN_MEMBER'),
  trackCreateActivityRecord: track('CREATE_ACTIVITY_RECORD'),
  trackCreateBooking: track('CREATE_BOOKING'),
  trackCreateNewGroupService: track('CREATE_NEW_GROUP_SERVICE'),
  trackCreateNewStandardService(userId: string, data: EventData) {
    const { serviceId: createdStandardServiceId } = data;
    track('CREATE_NEW_STANDARD_SERVICE')(data);
    identify(userId, { createdStandardServiceId });
  },
  trackCreateNewSupportCoordinationService(userId: string, data: EventData) {
    const { serviceId: createdSupportCoordinationServiceId } = data;
    track('CREATE_NEW_SUPPORT_COORDINATION_SERVICE')(data);
    identify(userId, { createdSupportCoordinationServiceId });
  },
  trackCreateServiceAgreement: track('CREATE_SERVICE_AGREEMENT'),
  trackCreateTCTemplate: track('CREATE_TC_TEMPLATE'),
  trackInviteTeamMember(userId: string, data: EventData) {
    const { teamMemberId: addedTeamMemberId } = data;
    track('INVITE_TEAM_MEMBER')(data);
    identify(userId, { addedTeamMemberId });
  },
  trackMarkLineItemsAsPaid: track('MARK_LINE_ITEM_AS_PAID'),
  trackProcessLineItemsForPayment: track('PROCESS_LINE_ITEM'),
  trackPublishBookingToMember: track('PUBLISH_BOOKING'),
  trackSetFinanceFileDefault: track('SET_FINANCE_FILE_DEFAULT'),
  trackSetInvoiceTerms: track('SET_INVOICE_TERMS'),
  trackSetPayrollFileDefault: track('SET_PAYROLL_FILE_DEFAULT'),
  trackUploadLogo: track('UPLOAD_LOGO'),
};

type AppcuesUser = {
  createdAt: PortalUser['createdOn'];
  companyName: PortalUser['serviceProviderName'];
} & Pick<
  PortalUser,
  'firstName' | 'lastName' | 'email' | 'companyStatus' | 'isFirstLogin' | 'enabledUsers' | 'paymentType'
>;

export const transformPortalUserToAppcuesUser = (user: PortalUser): AppcuesUser => {
  const {
    companyStatus,
    createdOn: createdAt,
    email,
    enabledUsers,
    firstName,
    isFirstLogin,
    lastName,
    paymentType,
    serviceProviderName: companyName,
    permissions: { permissionRoles },
  } = user;

  return {
    companyName,
    companyStatus,
    createdAt,
    email,
    enabledUsers,
    firstName,
    isFirstLogin,
    lastName,
    paymentType,
    ...PermissionUtils.formatPermissionRoles(permissionRoles),
  };
};
