import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const StarSlash = forwardRef(function StarSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.47 1.47a.75.75 0 0 1 1.06 0L8.76 7.7l9.156 9.156 4.614 4.614a.75.75 0 0 1-1.06 1.06l-2.777-2.777.038.171a.75.75 0 0 1-1.074.833L12 17.844l-5.656 2.913a.75.75 0 0 1-1.075-.833l1.408-6.202-4.206-4.19a.75.75 0 0 1 .42-1.274l3.754-.553L1.47 2.53a.75.75 0 0 1 0-1.06Zm6.496 7.557-3.377.497 3.44 3.428a.75.75 0 0 1 .203.697l-1.144 5.037 4.569-2.353a.75.75 0 0 1 .687 0l4.568 2.353-.21-.923-8.736-8.736ZM12 1.25a.75.75 0 0 1 .674.42L15.5 7.432l5.61.826a.75.75 0 0 1 .42 1.273l-4 3.986a.75.75 0 0 1-1.06-1.063l2.941-2.93-4.52-.665a.75.75 0 0 1-.564-.412L12 3.703 10.712 6.33a.75.75 0 0 1-1.347-.66l1.962-4A.75.75 0 0 1 12 1.25Z"
        fill="currentColor"
      />
    </Icon>
  );
});
