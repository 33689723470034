import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Camera = forwardRef(function Camera(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25h-.126c-1.202 0-1.978 0-2.704.239a4.749 4.749 0 0 0-.652.27c-.682.344-1.23.892-2.08 1.743l-.09.089-.613.613a.156.156 0 0 1-.11.046A4.375 4.375 0 0 0 1.25 8.625v4.431c0 1.838 0 3.294.153 4.433.158 1.172.49 2.121 1.238 2.87.749.748 1.698 1.08 2.87 1.238 1.14.153 2.595.153 4.433.153h4.112c1.838 0 3.294 0 4.433-.153 1.172-.158 2.121-.49 2.87-1.238.748-.749 1.08-1.698 1.238-2.87.153-1.14.153-2.595.153-4.433V8.625a4.375 4.375 0 0 0-4.375-4.375.156.156 0 0 1-.11-.046l-.613-.613-.09-.09c-.85-.85-1.398-1.398-2.08-1.742a4.744 4.744 0 0 0-.652-.27c-.726-.24-1.502-.24-2.704-.239H12ZM9.64 2.913c.469-.154.988-.163 2.36-.163 1.372 0 1.892.009 2.36.163.154.05.303.113.447.185.44.222.814.583 1.784 1.554l.613.613c.31.31.732.485 1.171.485a2.875 2.875 0 0 1 2.875 2.875V13c0 1.907-.002 3.262-.14 4.29-.135 1.005-.389 1.585-.812 2.008-.423.423-1.003.677-2.009.812-1.027.138-2.382.14-4.289.14h-4c-1.907 0-3.261-.002-4.29-.14-1.005-.135-1.585-.389-2.008-.812-.423-.423-.677-1.003-.812-2.009-.138-1.027-.14-2.382-.14-4.289V8.625A2.875 2.875 0 0 1 5.625 5.75c.439 0 .86-.174 1.17-.485l.614-.613c.97-.97 1.344-1.332 1.785-1.554.143-.072.293-.134.446-.185ZM8.75 12a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0ZM12 7.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
