import { ClaimType, BillingPaymentStatus } from 'utilities/enum-utils';

export const PAYMENT_TAB = {
  SHIFT_HOURS: 'SHIFT_HOURS',
  BATCH_LISTING: 'BATCH_LISTING',
  BATCH_DETAILS: 'BATCH_DETAILS',
};

export enum InvoiceNoteModalType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export enum PreviewInvoicesModalType {
  PREVIEW = 'PREVIEW',
  PREVIEW_OR_EDIT = 'PREVIEW_OR_EDIT',
}

type BulkActionExtension = Record<
  string,
  {
    hasBorder: boolean;
    borderColor: string;
    borderRadius: string;
    sizeButton: string;
    titleButton: string;
    positionPopover: 'auto' | 'top-right' | 'bottom-right';
    iconSize: 'default' | 'small' | 'large';
    icon: string;
  }
>;

export const BulkActionExtensions: BulkActionExtension = {
  [PAYMENT_TAB.BATCH_LISTING]: {
    hasBorder: true,
    borderColor: 'border-white',
    borderRadius: 'rounded',
    sizeButton: 'large',
    titleButton: 'Export finance file',
    positionPopover: 'top-right',
    iconSize: 'large',
    icon: 'up',
  },
  [PAYMENT_TAB.BATCH_DETAILS]: {
    hasBorder: false,
    borderColor: '',
    borderRadius: 'rounded-x3-big',
    sizeButton: 'large',
    titleButton: 'Export finance file',
    positionPopover: 'bottom-right',
    iconSize: 'large',
    icon: 'down',
  },
  [PAYMENT_TAB.SHIFT_HOURS]: {
    hasBorder: false,
    borderColor: '',
    borderRadius: 'rounded',
    sizeButton: 'default',
    titleButton: 'Export as CSV',
    positionPopover: 'bottom-right',
    iconSize: 'default',
    icon: 'down',
  },
};

export enum TimesheetExportStyle {
  GOODHUMAN_DEFAULT = 'GOODHUMAN_DEFAULT',
  EMPLOYMENT_HERO = 'EMPLOYMENT_HERO',
  KEYPAY = 'KEYPAY',
  ZESTCARE_CONNX = 'ZESTCARE-CONNX',
  XERO_UPSHEETS = 'XERO_UPSHEETS',
}

export enum SupportedFinanceExportConfigStyle {
  TENCIA = 'TENCIA',
  MYOB = 'MYOB',
  WISEFINANCE = 'WISEFINANCE',
  XERO = 'XERO',
  MD365 = 'MD365',
  QUICKBOOKS = 'QUICKBOOKS',
  KNOX_BROOKE_MYOB = 'KNOX_BROOKE_MYOB',
  XERO_FIGHTING_CHANCE = 'XERO_FIGHTING_CHANCE',
  CAREVIEW = 'CAREVIEW',
  NDIA = 'NDIA',
  RECKON = 'RECKON',
  GOODHUMAN_ALL = 'GOODHUMAN_ALL',
}

export const ConfigStyleDisplayName = {
  TENCIA: 'Tencia',
  MYOB: 'MYOB',
  WISEFINANCE: 'WiseFinance',
  XERO: 'Xero',
  MD365: 'MD365',
  QUICKBOOKS: 'QuickBooks',
  KNOX_BROOKE_MYOB: 'MYOB (custom)',
  XERO_FIGHTING_CHANCE: 'Xero (FC custom)',
  CAREVIEW: 'Careview',
  GOODHUMAN_ALL: 'All fields',
};

export type IData = Record<string, string | number>;

export type IBatch = {
  batchNumber?: string;
  data?: IData[] | string;
};

export const CLAIM_TYPE_STANDARD_OPTIONS = [
  { value: ClaimType.STANDARD_SERVICE, label: 'Standard Service' },
  { value: ClaimType.CANCELLATION, label: 'Cancellation' },
  { value: ClaimType.TRAVEL, label: 'Travel' },
  { value: ClaimType.REPORT_WRITING, label: 'Report Writing' },
  { value: ClaimType.NON_FACE_TO_FACE, label: 'Non Face-to-face' },
  { value: ClaimType.TELEHEALTH_SUPPORTS, label: 'Telehealth supports' },
  { value: ClaimType.IRREGULAR_SIL_SUPPORTS, label: 'Irregular SIL supports' },
];

export const CLAIM_TYPE_DIRECT_OPTIONS = [
  { value: ClaimType.DIRECT_SERVICE, label: 'Direct Service' },
  { value: ClaimType.CANCELLATION, label: 'Cancellation' },
  { value: ClaimType.TRAVEL, label: 'Travel' },
  { value: ClaimType.REPORT_WRITING, label: 'Report Writing' },
  { value: ClaimType.NON_FACE_TO_FACE, label: 'Non Face-to-face' },
  { value: ClaimType.TELEHEALTH_SUPPORTS, label: 'Telehealth supports' },
  { value: ClaimType.IRREGULAR_SIL_SUPPORTS, label: 'Irregular SIL supports' },
];

export const PAYMENT_STATUS_DISPLAY = {
  [BillingPaymentStatus.READY_FOR_BILLING]: {
    icon: 'check-circle',
    iconTheme: 'filled',
    iconColor: 'gray',
    statusText: 'Approved for Payment',
  },
  [BillingPaymentStatus.SEND_TO_FINANCE]: {
    icon: 'clock-circle',
    iconTheme: 'filled',
    iconColor: 'blue',
    statusText: 'Sent for Payment',
  },
  [BillingPaymentStatus.PROCESSED]: {
    icon: 'check',
    iconTheme: 'outlined',
    iconColor: 'green',
    statusText: 'Processed',
  },
  [BillingPaymentStatus.REJECTED]: {
    icon: 'exclamation-circle',
    iconTheme: 'filled',
    iconColor: 'red',
    statusText: 'Rejected',
  },
  [BillingPaymentStatus.WAIVED]: {
    icon: 'minus-circle',
    iconTheme: 'filled',
    iconColor: 'indigo',
    statusText: 'Waived',
  },
  [BillingPaymentStatus.NO_CHARGE]: {
    icon: 'close-circle',
    iconTheme: 'filled',
    iconColor: 'gray',
    statusText: 'Not charged',
  },
  [BillingPaymentStatus.REQUIRES_APPROVAL]: {
    icon: 'check-circle',
    iconTheme: 'filled',
    iconColor: 'orange',
    statusText: 'Pending',
  },
  [BillingPaymentStatus.INITIAL]: {
    icon: 'question-circle',
    iconTheme: 'filled',
    iconColor: 'white',
    statusText: '',
  },
};
