import React from 'react';
import _ from 'lodash';
import { ActivityGroupModalType } from 'utilities/enum-utils';
import { IGroupServiceActivityGroup } from 'src/interfaces/service-interfaces';
import { IActivityGroupUsers } from 'src/interfaces/user-interfaces';
import { openModalFuncType } from '../GroupServiceActivityGroupsPanel';
import { PrimaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { ActivityGroup } from 'common-components/activity-groups/listing';
import ActivityGroupPopoverContent from './ActivityGroupPopoverContent';
import MemberPopoverContent from './MemberPopoverContent';
import UnAssignedMemberListing from './UnAssignedMemberListing';
import { Stack } from '../../../../common-components/stack';

interface IActivityGroupListingProps {
  activityGroups: IGroupServiceActivityGroup[];
  unassignedMembers: {
    customers: IActivityGroupUsers[];
    teamMembers: IActivityGroupUsers[];
  };
  openModal: openModalFuncType;
}

function ActivityGroupListing(props: IActivityGroupListingProps) {
  const { activityGroups, unassignedMembers, openModal } = props;
  const { customers, teamMembers } = unassignedMembers;
  if (_.isEmpty(activityGroups)) return <></>;

  return (
    <>
      <div className="flex-row justify-between align-start mb-x-small">
        <Stack gap="lg">
          <Text weight="bold" size="x2-large">
            Activity group templates ({activityGroups.length})
          </Text>

          <div style={{ maxWidth: '62ch' }}>
            Use activity groups to coordinate busy group sessions by organising people into smaller groups, ready for
            activities. Create group templates and apply them to sessions in this service.
          </div>
        </Stack>

        <div>
          <PrimaryButton size="large" icon={'plus'} onClick={() => openModal(null, ActivityGroupModalType.CREATE)}>
            {' '}
            Create activity group
          </PrimaryButton>
        </div>
      </div>

      <div
        className="mt-large grid align-start overflow-auto justify-start"
        style={{ gridAutoFlow: 'column', columnGap: '24px' }}
      >
        <UnAssignedMemberListing customers={customers} teamMembers={teamMembers} openModal={openModal} />
        {_.map(activityGroups, (activityGroup: IGroupServiceActivityGroup) => (
          <ActivityGroup
            key={activityGroup.serviceActivityGroupId}
            activityGroup={activityGroup}
            activityGroupPopoverContent={
              <ActivityGroupPopoverContent activityGroup={activityGroup} openModal={openModal} />
            }
            MemberPopoverContent={MemberPopoverContent}
            memberPopoverContentProps={{
              activityGroup: activityGroup,
              openModal: openModal,
            }}
            addMember={() => openModal(activityGroup, ActivityGroupModalType.ADD_BOTH)}
          />
        ))}
      </div>
    </>
  );
}

export default ActivityGroupListing;
