import { AlertTag } from 'common-components/tags';
import { AlertSeverity } from 'common-components/tags/AlertTag';
import React, { HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportWorkerAlertType } from 'utilities/enum-utils';

export type TeamAlertTagProps = {
  alertType: SupportWorkerAlertType;
  numberOfAlerts: number;
  onClickView: () => void;
  className?: string;
} & HTMLProps<HTMLSpanElement>;

export const alertTypeToSeverity = (type: SupportWorkerAlertType): AlertSeverity => {
  switch (type) {
    case SupportWorkerAlertType.ComplianceDocumentExpired:
    case SupportWorkerAlertType.DocumentExpired:
      return 'error';
    default:
      return 'warning';
  }
};

export const supportWorkerAlertTypeToTranslationMap: {
  [key in SupportWorkerAlertType]: string;
} = {
  [SupportWorkerAlertType.ComplianceDocumentExpired]: 'ComplianceDocumentExpired',
  [SupportWorkerAlertType.DocumentExpired]: 'DocumentExpired',
  [SupportWorkerAlertType.ComplianceDocumentExpiringSoon]: 'ComplianceDocumentExpiringSoon',
  [SupportWorkerAlertType.DocumentExpiringSoon]: 'DocumentExpiringSoon',
  [SupportWorkerAlertType.ScheduledToBeRemoved]: 'ScheduledToBeRemoved',
};

const TeamAlertTag: React.FC<TeamAlertTagProps> = ({
  alertType,
  numberOfAlerts,
  onClickView,
  className,
  ...htmlProps
}) => {
  const { t } = useTranslation('', { keyPrefix: 'alerts.team.description' });
  const severity: AlertSeverity = alertTypeToSeverity(alertType);

  return (
    <AlertTag
      severity={severity}
      onClickView={onClickView}
      className={className}
      text={t(supportWorkerAlertTypeToTranslationMap[alertType], { count: numberOfAlerts })}
      {...htmlProps}
    />
  );
};

export default TeamAlertTag;
