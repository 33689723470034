import React, { Component } from 'react';

import BlockAccessModal from 'views/account-management/components/TeamManagementModals/BlockAccessModal';
import { PermanentlyRemoveUserModal } from 'views/account-management/components/TeamManagementModals/PermanentlyRemoveUserModal/PermanentlyRemoveUserModal';
import UnblockAccess from 'views/account-management/components/TeamManagementModals/UnblockAccessModal';
import { InviteUserModal } from 'views/account-management/components/TeamManagementModals/invite-user-modal';
import DeleteDraftUserModal from 'views/account-management/components/TeamManagementModals/DeleteDraftUserModal';
import RevokeInvitationModal from 'views/account-management/components/TeamManagementModals/RevokeInvitationModal';
import ReSendInvitationModal from 'views/account-management/components/TeamManagementModals/ReSendInvitationModal';
import ModifyInvitationModal from 'views/account-management/components/TeamManagementModals/ModifyInvitationModal';
import EditPermissionsModal from 'views/account-management/edit-permissions/EditPermissionsModal';
import ReSendRevokedInvitationModal from 'views/account-management/components/TeamManagementModals/ReSendRevokedInvitationModal';
import ViewRemovalReasonModal from 'views/account-management/components/TeamManagementModals/ViewRemovalReasonModal';
import ReEnableUserModal from 'views/account-management/components/TeamManagementModals/ReEnableUserModal';
import EditRemovalDateModal from 'views/account-management/components/TeamManagementModals/EditRemovalDateModal';
import CancelRemovalDateModal from 'views/account-management/components/TeamManagementModals/CancelRemovalDateModal';

interface ITeamManagementModalsProps {
  isOpen: any;
  onClose: any;
  selectedModal: string;
  data: any;
}

interface ITeamManagementModalsState {}

class TeamManagementModals extends Component<ITeamManagementModalsProps, ITeamManagementModalsState> {
  render() {
    const { isOpen, selectedModal, data, onClose } = this.props;

    return (
      <>
        <BlockAccessModal isOpen={selectedModal === 'BlockAccessModal' && isOpen} onClose={onClose} worker={data} />
        <UnblockAccess isOpen={selectedModal === 'UnblockAccessModal' && isOpen} onClose={onClose} worker={data} />
        <PermanentlyRemoveUserModal
          isOpen={selectedModal === 'PermanentlyRemoveUserModal' && isOpen}
          onClose={onClose}
          worker={data}
        />
        <InviteUserModal isOpen={selectedModal === 'InviteUserModal' && isOpen} onClose={onClose} worker={data} />
        <DeleteDraftUserModal
          isOpen={selectedModal === 'DeleteDraftUserModal' && isOpen}
          onClose={onClose}
          worker={data}
        />
        <RevokeInvitationModal
          isOpen={selectedModal === 'RevokeInvitationModal' && isOpen}
          onClose={onClose}
          invite={data}
        />
        <ReSendInvitationModal
          isOpen={selectedModal === 'ReSendInvitationModal' && isOpen}
          onClose={onClose}
          invite={data}
        />
        <ReSendRevokedInvitationModal
          isOpen={selectedModal === 'ReSendRevokedInvitationModal' && isOpen}
          onClose={onClose}
          invite={data}
        />
        <ModifyInvitationModal
          isOpen={selectedModal === 'ModifyInvitationModal' && isOpen}
          onClose={onClose}
          invite={data}
        />
        <EditPermissionsModal
          isOpen={selectedModal === 'EditPermissionModal' && isOpen}
          onClose={onClose}
          worker={data}
        />
        <ViewRemovalReasonModal
          isOpen={selectedModal === 'ViewRemovalReason' && isOpen}
          onClose={onClose}
          worker={data}
        />

        <ReEnableUserModal isOpen={selectedModal === 'ReEnableUser' && isOpen} onClose={onClose} worker={data} />
        <EditRemovalDateModal
          isOpen={selectedModal === 'EditRemovalDateModal' && isOpen}
          onClose={onClose}
          worker={data}
        />
        <CancelRemovalDateModal
          isOpen={selectedModal === 'CancelRemovalDateModal' && isOpen}
          onClose={onClose}
          worker={data}
        />
      </>
    );
  }
}

export default TeamManagementModals;
