import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import Script from 'react-load-script';

import { GOOGLE_MAPS } from '../config/app-config';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- extending global window
  interface Window {
    googleMapsApiCallback: () => void;
  }
}

const GoogleMapsApiLoadedEvent = new CustomEvent('googleMapsApiLoaded');

window.googleMapsApiCallback = () => {
  window.dispatchEvent(GoogleMapsApiLoadedEvent);
};

type GoogleMapsApiContextType = {
  isReady: boolean;
};

const GoogleMapsApiContext = createContext<GoogleMapsApiContextType>({
  isReady: false,
});

type GoogleMapsApiProviderProps = {
  isReadyInitialValue?: boolean;
  children: React.ReactNode;
};

export function GoogleMapsApiProvider({ isReadyInitialValue = false, children }: GoogleMapsApiProviderProps) {
  const [isReady, setIsReady] = useState(isReadyInitialValue);

  useEffect(() => {
    const handleGoogleMapsLoaded = () => {
      setIsReady(true);
    };

    window.addEventListener('googleMapsApiLoaded', handleGoogleMapsLoaded);

    if (typeof window !== undefined && window.google?.maps) {
      // fallback for hmr & testing
      setIsReady(true);
    }

    return () => {
      window.removeEventListener('googleMapsApiLoaded', handleGoogleMapsLoaded);
    };
  }, []);

  const value = useMemo(() => ({ isReady }), [isReady]);

  return (
    <>
      <Script url={`${GOOGLE_MAPS.MAP_URL}&callback=googleMapsApiCallback`} />
      <GoogleMapsApiContext.Provider value={value}>{children}</GoogleMapsApiContext.Provider>
    </>
  );
}

export const useGoogleMapsApi = () => useContext(GoogleMapsApiContext);
