import React from 'react';
import { Breadcrumbs, Stack, Box, Text, Title, Flex, rem } from '@mantine/core';
import { ChevronRight } from '@good/icons';
export const PageHeader = ({ title, subtitle, breadcrumbItems, actions, size, mb }) => (<Box mb={mb ?? 'xl'}>
    {breadcrumbItems?.length && (<Breadcrumbs py='xl' separator={<ChevronRight />}>
        {breadcrumbItems}
      </Breadcrumbs>)}

    <Flex gap='xl' justify='space-between' direction={{ base: 'column', md: 'row' }}>
      <Stack gap={rem(4)}>
        <Title size={size ?? 'h1'}>{title}</Title>
        {subtitle && <Text c='var(--brand-body-dark-1)'>{subtitle}</Text>}
      </Stack>

      {actions}
    </Flex>
  </Box>);
