import React, { PropsWithChildren } from 'react';
import { FieldLabel } from '../field-label';
import { StatusMessage } from '../status-message';
import { twMerge } from '../utils';
import { sharedInputStyles } from '../styles';

type ComboboxProps = PropsWithChildren<{
  label?: string;
  description?: string;
  error?: string;
  required?: boolean;
  optional?: boolean;
  className?: string;
}> &
  React.HTMLProps<HTMLSelectElement>;

export const Combobox = React.forwardRef<HTMLSelectElement, ComboboxProps>(
  ({ children, label, description, error, required, optional, className, ...selectProps }, ref) => {
    const id = selectProps.id ?? selectProps.name;

    return (
      <div className="flex flex-col gap-2">
        {label && (
          <FieldLabel
            htmlFor={id}
            isRequired={required}
            requirementIndicator={required || optional ? 'label' : undefined}
          >
            {label}
          </FieldLabel>
        )}

        <select
          id={id}
          ref={ref}
          role='combobox'
          aria-expanded='false'
          aria-controls={id}
          className={twMerge(sharedInputStyles, className)}
          aria-invalid={Boolean(error)}
          aria-required={required}
          {...selectProps}
        >
          {children}
        </select>

        {error && <StatusMessage tone='critical'>{error}</StatusMessage>}
        {description && !error && <StatusMessage tone='neutral'>{description}</StatusMessage>}
      </div>
    );
  },
);

Combobox.displayName = 'Combobox';
