import React, { Component } from 'react';
import { Avatar, Checkbox, Col, notification, Row, Tag, Icon } from 'antd';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import {
  IGroupServiceActivityGroup,
  IGroupServiceSchedule,
  IGroupServiceScheduleTimeSlot,
  IGroupServiceCustomerRatio,
} from 'interfaces/service-interfaces';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import moment from 'moment-timezone';
import _ from 'lodash';
import CommonUtils from 'utilities/common-utils';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Popover2 } from '@blueprintjs/popover2';
import { ActivityGroupMemberType, ActivityGroupModalType } from 'utilities/enum-utils';
import ActivityGroupsModal from 'common-components/activity-groups/modals/ActivityGroupsModal';

import EditScheduleDetailModal from '../components/EditScheduleDetailModal';
import RatioPopoverContent from './ratio-popover-content';

interface ISchedulesStepPanelProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setCustomerToSchedule?: typeof dispatch.servicesStore.setCustomerToSchedule;
  setGroupServiceActivityGroups: typeof dispatch.groupServiceStore.setGroupServiceActivityGroups;
  doFetchGroupServiceSchedules?: typeof dispatch.servicesStore.doFetchGroupServiceSchedules;
  doFetchGroupServiceActivityGroups: typeof dispatch.groupServiceStore.doFetchGroupServiceActivityGroups;
  customerToSchedule: typeof state.servicesStore.customerToSchedule;
  groupServiceSchedules: typeof state.servicesStore.groupServiceSchedules;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  history?: any;
}

enum ScheduleAction {
  EDIT_BOOKING_TIME_RATIOS = 'EDIT_BOOKING_TIME_RATIOS',
}

type ScheduleModalType = ActivityGroupModalType | ScheduleAction;

interface ISchedulesStepPanelState {
  isLoading: boolean;
  schedules: IGroupServiceSchedule[];
  scheduleTimeSlots: IGroupServiceScheduleTimeSlot[];
  scheduleTimeSlotIds: string[];
  isNotScheduleSelectedError: boolean;
  currentEditSchedule: IGroupServiceSchedule;
  currentEditTimeSlot: IGroupServiceScheduleTimeSlot;
  currentModalType: ScheduleModalType;
  currentMemberType: ActivityGroupMemberType;
  isModalOpen: boolean;
}

class SchedulesStepPanel extends Component<ISchedulesStepPanelProps, ISchedulesStepPanelState> {
  state = {
    isLoading: false,
    schedules: this.props.groupServiceSchedules ? this.props.groupServiceSchedules : [],
    scheduleTimeSlots: this.props.customerToSchedule.scheduleTimeSlots
      ? this.props.customerToSchedule.scheduleTimeSlots
      : [],
    scheduleTimeSlotIds: this.props.customerToSchedule.scheduleTimeSlotIds
      ? this.props.customerToSchedule.scheduleTimeSlotIds
      : [],
    isNotScheduleSelectedError: false,
    currentEditSchedule: null,
    currentEditTimeSlot: null,
    currentModalType: null,
    currentMemberType: ActivityGroupMemberType.CUSTOMER,
    isModalOpen: false,
  };

  private _goToNext = async () => {
    const { setCustomerToSchedule, customerToSchedule, selectedGroupService } = this.props;
    const { scheduleTimeSlots, scheduleTimeSlotIds } = this.state;

    let isSelectionValid = true;
    if (scheduleTimeSlotIds.length === 0) {
      isSelectionValid = false;
      this.setState({ isNotScheduleSelectedError: true });
    }

    if (isSelectionValid) {
      const newScheduleTimeSlots: IGroupServiceScheduleTimeSlot[] = _.reduce(
        scheduleTimeSlots,
        (result, scheduleTimeSlot: IGroupServiceScheduleTimeSlot) => {
          if (
            !_.isEmpty(
              _.find(
                scheduleTimeSlotIds,
                (scheduleTimeSlotId) => scheduleTimeSlotId === scheduleTimeSlot.scheduleTimeSlotId,
              ),
            )
          ) {
            return result.concat({
              ...scheduleTimeSlot,
              teamMemberCustomerRatio: selectedGroupService.teamMemberCustomerRatio.ndis,
            });
          }

          return result;
        },
        [],
      );

      try {
        await setCustomerToSchedule({
          ...customerToSchedule,
          scheduleTimeSlots: newScheduleTimeSlots,
          scheduleTimeSlotIds,
        });
        this.props.onNextStep();
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  private _selectTimeSlot = (scheduleTimeSlotId) => {
    let newSelectedTimeSlotIds = this.state.scheduleTimeSlotIds;
    if (!_.find(newSelectedTimeSlotIds, (selectedTimeSlotId) => selectedTimeSlotId === scheduleTimeSlotId)) {
      newSelectedTimeSlotIds.push(scheduleTimeSlotId);
    } else {
      newSelectedTimeSlotIds = _.filter(this.state.scheduleTimeSlotIds, (timeSlot) => timeSlot !== scheduleTimeSlotId);
    }
    this.setState({ scheduleTimeSlotIds: newSelectedTimeSlotIds, isNotScheduleSelectedError: false });
  };

  private _openModal = (
    schedule: IGroupServiceSchedule,
    timeSlot: IGroupServiceScheduleTimeSlot,
    action: ScheduleModalType,
  ) => {
    const { groupServiceSchedules, setGroupServiceActivityGroups } = this.props;
    switch (action) {
      case ScheduleAction.EDIT_BOOKING_TIME_RATIOS:
        this.setState({
          isModalOpen: true,
          currentEditSchedule: schedule,
          currentEditTimeSlot: timeSlot,
          currentModalType: action,
        });
        break;

      default:
        const timeSlots = _.get(
          _.find(groupServiceSchedules, (item) => item.serviceScheduleId === schedule.serviceScheduleId),
          'scheduleConfig.timeSlots',
          [],
        );
        const selectedTimeSlot = _.find(timeSlots, (item) => item.scheduleTimeSlotId === timeSlot.scheduleTimeSlotId);
        setGroupServiceActivityGroups(_.get(selectedTimeSlot, 'activityGroups', []));
        this.setState({ isModalOpen: true, currentEditTimeSlot: timeSlot, currentModalType: action });
        break;
    }
  };

  private _handleCloseModal = () => {
    this.setState({ isModalOpen: false, currentEditSchedule: null, currentEditTimeSlot: null });
  };

  private _assignDataToTimeSlot = (timeSlot: IGroupServiceScheduleTimeSlot, data) => {
    const newScheduleTimeSlots = _.get(this.state, 'scheduleTimeSlots', []);
    const indexOfScheduleTimeSlot = _.findIndex(
      newScheduleTimeSlots,
      (item: IGroupServiceScheduleTimeSlot) => item.scheduleTimeSlotId === timeSlot.scheduleTimeSlotId,
    );

    if (indexOfScheduleTimeSlot > -1) {
      _.set(newScheduleTimeSlots, `[${indexOfScheduleTimeSlot}]`, {
        ...newScheduleTimeSlots[indexOfScheduleTimeSlot],
        ...data,
      });
    } else {
      newScheduleTimeSlots.push({
        ...timeSlot,
        ...data,
      });
    }

    this.setState({ scheduleTimeSlots: newScheduleTimeSlots });
  };

  private _handleModalAction = async (data, actionType: ScheduleModalType) => {
    if (actionType === ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SCHEDULE) {
      const { timeSlot, addedActivityGroups } = data;
      this._assignDataToTimeSlot(timeSlot, { activityGroups: addedActivityGroups });
    } else if (actionType === ScheduleAction.EDIT_BOOKING_TIME_RATIOS) {
      const { timeSlot, customerRatio } = data;
      const { startDateTime, endDateTime } = customerRatio;
      this._assignDataToTimeSlot(timeSlot, {
        customerRatio,
        startDateTime,
        endDateTime,
      });
    }
  };

  private _getActivityGroupPopoverContent = (activityGroups: IGroupServiceActivityGroup[]) => {
    const { selectedGroupService } = this.props;
    return (
      <div className="ph-x-large pv-medium" style={{ width: '446px', maxHeight: '362px', overflowX: 'hidden' }}>
        <div className="mb-medium">
          <Text className={'text-weight-bold'}>Available activity groups ({_.get(activityGroups, 'length')})</Text>
        </div>
        <div className="flex-column space-between" style={{ rowGap: '12px' }}>
          {_.map(activityGroups, (activityGroup: IGroupServiceActivityGroup, index) => {
            const { serviceActivityGroupId, name, startDateTime, endDateTime } = activityGroup;
            const startDateTimeMoment = moment.tz(startDateTime, selectedGroupService.timezone);
            const endDateTimeMoment = moment.tz(endDateTime, selectedGroupService.timezone);
            return (
              <div key={index}>
                <div className="whitespace-nowrap">
                  <Tag
                    key={serviceActivityGroupId}
                    className="tag-icon bg-tertiary flex-row align-center"
                    style={{ border: 'none' }}
                  >
                    <Icon type="calendar" style={{ fontSize: '14px' }} />
                    <Text className={'text-overflow-ellipsis'} size="regular">
                      {name}
                    </Text>
                  </Tag>
                </div>
                <div className="whitespace-nowrap">
                  <Text>
                    {startDateTimeMoment.format('hh:mm a')} - {endDateTimeMoment.format('hh:mm a')} •{' '}
                    {startDateTimeMoment.format('ddd, D MMM')} - {endDateTimeMoment.format('ddd, D MMM YYYY')}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  private _autoAssignCustomerData = () => {
    const { groupServiceSchedules, selectedGroupService } = this.props;
    const { scheduleTimeSlots } = this.state;
    _.forEach(groupServiceSchedules, (schedule: IGroupServiceSchedule) => {
      const timeSlots = schedule.scheduleConfig.timeSlots;
      _.forEach(timeSlots, (timeSlot: IGroupServiceScheduleTimeSlot) => {
        const updatedTimeSlot = _.find(
          scheduleTimeSlots,
          (item) => item.scheduleTimeSlotId === timeSlot.scheduleTimeSlotId,
        );
        const selectedTimeSlot = updatedTimeSlot ? updatedTimeSlot : timeSlot;
        const startDateTime = moment.tz(timeSlot.startDateTime, selectedGroupService.timezone).toDate();
        const endDateTime = moment.tz(timeSlot.endDateTime, selectedGroupService.timezone).toDate();
        const defaultTeamMemberCustomerRatio = _.get(
          schedule,
          'teamMemberCustomerRatio.ndis',
          selectedGroupService.teamMemberCustomerRatio.ndis,
        );
        const initialCustomerRatio: IGroupServiceCustomerRatio = {
          scheduleId: timeSlot.scheduleTimeSlotId,
          customRatio: [
            {
              customTimeId: uuidv4(),
              startDateTime: startDateTime,
              endDateTime: endDateTime,
              teamMemberCustomerRatio: defaultTeamMemberCustomerRatio,
            },
          ],
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          errors: null,
          isCustomRatio: false,
        };
        const activityGroups = _.get(selectedTimeSlot, 'activityGroups', []);
        const customerRatio = _.get(selectedTimeSlot, 'customerRatio', initialCustomerRatio);
        const data = {
          activityGroups,
          customerRatio,
        };
        this._assignDataToTimeSlot(selectedTimeSlot, data);
      });
    });
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await this.props.doFetchGroupServiceActivityGroups(this.props.selectedGroupService);
    await this.props.doFetchGroupServiceSchedules({
      serviceId: this.props.selectedGroupService.serviceId,
      finishAfter: new Date(),
      customerUserId: _.get(this.props, 'customerToSchedule.selectedCustomer.userId', ''),
    });

    this._autoAssignCustomerData();
    this.setState({ isLoading: false });
  };

  componentDidUpdate(
    prevProps: Readonly<ISchedulesStepPanelProps>,
    prevState: Readonly<ISchedulesStepPanelState>,
  ): void {
    if (prevState.schedules !== prevProps.groupServiceSchedules) {
      this.setState({ schedules: prevProps.groupServiceSchedules });
    }
  }

  render() {
    const { customerToSchedule, selectedGroupService } = this.props;
    const {
      isLoading,
      schedules,
      scheduleTimeSlots,
      scheduleTimeSlotIds,
      isNotScheduleSelectedError,
      currentEditSchedule,
      currentEditTimeSlot,
      currentMemberType,
      currentModalType,
      isModalOpen,
    } = this.state;

    const scheduleWithoutTz = _.map(schedules, (schedule) => {
      return {
        ...schedule,
        scheduleStartDate: moment.tz(
          moment.tz(schedule.scheduleStartDate, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm'),
          selectedGroupService.timezone,
        ),
        scheduleEndDate: moment.tz(
          moment.tz(schedule.scheduleEndDate, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm'),
          selectedGroupService.timezone,
        ),
        scheduleConfig: {
          ...schedule.scheduleConfig,
          timeSlots: _.map(schedule.scheduleConfig.timeSlots, (timeSlot) => {
            const startDateTime = moment.tz(timeSlot.startDateTime, selectedGroupService.timezone).toDate();
            const endDateTime = moment.tz(timeSlot.endDateTime, selectedGroupService.timezone).toDate();
            const defaultTeamMemberCustomerRatio = _.get(
              schedule,
              'teamMemberCustomerRatio.ndis',
              selectedGroupService.teamMemberCustomerRatio.ndis,
            );
            const initialCustomerRatio: IGroupServiceCustomerRatio = {
              scheduleId: timeSlot.scheduleTimeSlotId,
              customRatio: [
                {
                  customTimeId: uuidv4(),
                  startDateTime: startDateTime,
                  endDateTime: endDateTime,
                  teamMemberCustomerRatio: defaultTeamMemberCustomerRatio,
                },
              ],
              startDateTime: startDateTime,
              endDateTime: endDateTime,
              errors: null,
              isCustomRatio: false,
            };
            return {
              ...timeSlot,
              customerRatio: initialCustomerRatio,
              startDateTime: moment.tz(
                moment.tz(timeSlot.startDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm'),
                selectedGroupService.timezone,
              ),
              endDateTime: moment.tz(
                moment.tz(timeSlot.endDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm'),
                selectedGroupService.timezone,
              ),
            };
          }),
        },
      };
    });

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Select a schedule</Title>
              <Paragraph>
                Select which schedules you want to add this customer to. You can select multiple sessions if you would
                like.
              </Paragraph>
              {customerToSchedule && customerToSchedule.selectedCustomer ? (
                <div className={'p-medium bg-white rounded-big'}>
                  <SubTitle>Selected customer</SubTitle>
                  <div className={'flex-row align-center mt-small'}>
                    <Avatar
                      className={'mr-small'}
                      shape={'circle'}
                      icon={'user'}
                      src={customerToSchedule.selectedCustomer.attachmentUrl}
                    />{' '}
                    {customerToSchedule.selectedCustomer.firstName} {customerToSchedule.selectedCustomer.lastName}
                  </div>
                </div>
              ) : (
                <Text color={'secondary'}>No customer selected</Text>
              )}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
              <Title level={4}>Add to schedule</Title>
              <Paragraph>
                Select the <b>schedule</b> that you'd like to add this customer to.
              </Paragraph>
              {isNotScheduleSelectedError && <Text color={'red-dark'}>Please select at least one schedule.</Text>}
              <div className={!isNotScheduleSelectedError && 'mt-large'}>
                {isLoading ? (
                  <SpinningLoader size={150} message={'Fetching schedules...'} />
                ) : (
                  _.map(scheduleWithoutTz, (schedule) => {
                    return (
                      <div key={schedule.serviceScheduleId} className={'mb-large'}>
                        <div className={'p-medium bg-tertiary mb-x-small'}>
                          <Text weight={'bold'}>
                            {selectedGroupService.serviceName} - {schedule.scheduleName} (
                            {moment
                              .tz(schedule.scheduleStartDate, selectedGroupService.timezone)
                              .format('DD MMMM YYYY')}{' '}
                            -{' '}
                            {moment.tz(schedule.scheduleEndDate, selectedGroupService.timezone).format('DD MMMM YYYY')})
                          </Text>
                        </div>
                        {_.map(schedule.scheduleConfig && schedule.scheduleConfig.timeSlots, (timeSlot) => {
                          const updatedTimeSlot = _.find(
                            scheduleTimeSlots,
                            (item) => item.scheduleTimeSlotId === timeSlot.scheduleTimeSlotId,
                          );
                          const displayTimeSlot = updatedTimeSlot ? updatedTimeSlot : timeSlot;
                          const isSelected = !!_.find(
                            scheduleTimeSlotIds,
                            (selectedTimeSlot) => selectedTimeSlot === timeSlot.scheduleTimeSlotId,
                          );
                          const assignedActivityGroups = _.filter(
                            _.get(displayTimeSlot, 'activityGroups', []),
                            (activityGroup) => activityGroup.isAssignedTo,
                          );
                          const activityGroupsLength = _.get(displayTimeSlot, 'activityGroups.length', 0);
                          return (
                            <div
                              key={timeSlot.scheduleTimeSlotId}
                              className={`p-medium bordered bordered-standard-grey ${isSelected && 'bg-blue-lightest'}`}
                            >
                              <Row type="flex" align="middle">
                                <Col span={1} className="align-self-start">
                                  <Checkbox
                                    checked={isSelected}
                                    className="mr-medium"
                                    onClick={() => this._selectTimeSlot(displayTimeSlot.scheduleTimeSlotId)}
                                  />
                                </Col>
                                <Col span={19}>
                                  <Row>
                                    <Text>
                                      {CommonUtils.getRecurringPatternLabel(displayTimeSlot.recurringPattern)} -{' '}
                                      {moment
                                        .tz(displayTimeSlot.startDateTime, selectedGroupService.timezone)
                                        .format('dddd')}
                                      ,{' '}
                                      {moment
                                        .tz(displayTimeSlot.startDateTime, selectedGroupService.timezone)
                                        .format('hh:mm A')}{' '}
                                      to{' '}
                                      {moment
                                        .tz(displayTimeSlot.endDateTime, selectedGroupService.timezone)
                                        .format('hh:mm A')}
                                    </Text>
                                  </Row>
                                  <Row>
                                    <Text size={'regular'}>
                                      {displayTimeSlot.description ? displayTimeSlot.description : '-'}
                                    </Text>
                                  </Row>
                                  <Row>
                                    <div className="flex-row">
                                      <Text color="secondary" className="mr-x2-large">
                                        Upcoming sessions
                                      </Text>
                                      {schedule.totalUpcomingSessions && (
                                        <Text>
                                          <b>{schedule.totalUpcomingSessions}</b> sessions
                                        </Text>
                                      )}
                                    </div>
                                  </Row>
                                  <Row>
                                    <div className="flex-row">
                                      <Text color="secondary" className="mr-x5-large">
                                        Activity groups
                                      </Text>
                                      {activityGroupsLength !== 0 ? (
                                        <div>
                                          <Text size="regular" className="mv-none mr-small">
                                            <b>{assignedActivityGroups.length}</b> out of {activityGroupsLength}{' '}
                                            activities
                                          </Text>
                                          <Popover2
                                            content={this._getActivityGroupPopoverContent(assignedActivityGroups)}
                                            position={'bottom-right'}
                                            interactionKind="hover"
                                          >
                                            <Icon type="info-circle" />
                                          </Popover2>
                                        </div>
                                      ) : (
                                        <Text size="regular" color={'tertiary'}>
                                          No activity groups in schedule
                                        </Text>
                                      )}
                                    </div>
                                  </Row>
                                  <Row>
                                    <div className="flex-row">
                                      <Text color="secondary" style={{ marginRight: '132px' }}>
                                        Ratio
                                      </Text>
                                      <Text className="mv-none mr-small">
                                        {!displayTimeSlot.customerRatio.isCustomRatio
                                          ? displayTimeSlot.customerRatio?.teamMemberCustomerRatio ??
                                            displayTimeSlot.customerRatio.customRatio?.[0]?.teamMemberCustomerRatio
                                          : 'Custom'}
                                      </Text>
                                      {displayTimeSlot.customerRatio.isCustomRatio && (
                                        <Popover2
                                          content={
                                            <RatioPopoverContent
                                              customerRatio={displayTimeSlot.customerRatio}
                                              timezone={this.props.selectedGroupService.timezone}
                                            />
                                          }
                                          position={'bottom-right'}
                                          interactionKind="hover"
                                        >
                                          <Icon type="info-circle" />
                                        </Popover2>
                                      )}
                                    </div>
                                  </Row>
                                </Col>
                                <Col span={4} className="text-align-right">
                                  {isSelected && (
                                    <Popover2
                                      position="bottom-right"
                                      content={
                                        <ActionMenu>
                                          <ActionMenuItem
                                            text={'Edit booking time/ratios'}
                                            onClick={() =>
                                              this._openModal(
                                                schedule,
                                                displayTimeSlot,
                                                ScheduleAction.EDIT_BOOKING_TIME_RATIOS,
                                              )
                                            }
                                          />
                                          <ActionMenuItem
                                            text={'Edit activity groups'}
                                            onClick={() =>
                                              this._openModal(
                                                schedule,
                                                displayTimeSlot,
                                                ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SCHEDULE,
                                              )
                                            }
                                          />
                                        </ActionMenu>
                                      }
                                    >
                                      <Text className="cursor-pointer p-small text-color-blue-action">
                                        Edit schedule
                                      </Text>
                                    </Popover2>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                )}
              </div>
              {isModalOpen && currentModalType !== ScheduleAction.EDIT_BOOKING_TIME_RATIOS && (
                <ActivityGroupsModal
                  serviceId={this.props.selectedGroupService.serviceId}
                  activityGroup={null}
                  modalType={currentModalType}
                  member={null}
                  memberType={currentMemberType}
                  isOpen={isModalOpen}
                  timeSlot={currentEditTimeSlot}
                  selectedUser={customerToSchedule.selectedCustomer}
                  handleAction={this._handleModalAction}
                  onCloseViewModal={this._handleCloseModal}
                  timeZone={selectedGroupService.timezone}
                  ignoredAlert={true}
                />
              )}

              {isModalOpen && currentModalType === ScheduleAction.EDIT_BOOKING_TIME_RATIOS && (
                <EditScheduleDetailModal
                  isModalOpen={isModalOpen}
                  selectedCustomer={customerToSchedule.selectedCustomer}
                  selectedSchedule={currentEditSchedule}
                  selectedScheduleTimeSlot={currentEditTimeSlot}
                  defaultRatioValue={selectedGroupService.teamMemberCustomerRatio.ndis}
                  timezone={selectedGroupService.timezone}
                  onCloseViewModal={this._handleCloseModal}
                  onSubmit={(data) => this._handleModalAction(data, ScheduleAction.EDIT_BOOKING_TIME_RATIOS)}
                />
              )}
            </div>
            <div className="pv-medium width-full bg-tertiary" style={{ position: 'sticky', bottom: 0 }}>
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" disabled={isLoading} onClick={this.props.onPreviousStep}>
                    Back
                  </SecondaryButton>
                </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton size="large" loading={isLoading} onClick={this._goToNext}>
                    Next
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setCustomerToSchedule: dispatch.servicesStore.setCustomerToSchedule,
  setGroupServiceActivityGroups: dispatch.groupServiceStore.setGroupServiceActivityGroups,
  doFetchGroupServiceSchedules: dispatch.servicesStore.doFetchGroupServiceSchedules,
  doFetchGroupServiceActivityGroups: dispatch.groupServiceStore.doFetchGroupServiceActivityGroups,
});

const mapState = (state: IRootState) => ({
  customerToSchedule: state.servicesStore.customerToSchedule,
  groupServiceSchedules: state.servicesStore.groupServiceSchedules,
  selectedGroupService: state.groupServiceStore.selectedGroupService,
});

export default connect(mapState, mapDispatch)(SchedulesStepPanel);
