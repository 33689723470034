import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Avatar, Icon, notification } from 'antd';
import _ from 'lodash';
import { Information } from 'common-components/alerts';
import moment from 'moment';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IRevokeInvitationModalProps {
  isOpen: any;
  onClose: any;
  invite: any;
  doRevokeTeamInvitation: typeof dispatch.teamStore.doRevokeTeamInvitation;
  doRevokeInvitation: typeof dispatch.teamStore.doRevokeInvitation;
}

interface IRevokeInvitationModalState {
  title: any;
  isDone: boolean;
  isSaving: boolean;
}

class RevokeInvitationModal extends Component<IRevokeInvitationModalProps, IRevokeInvitationModalState> {
  state = { title: 'Revoke Invitation', isDone: false, isSaving: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Revoke Invitation', isDone: false });
    this.props.onClose();
  };

  private _saveAction = async () => {
    try {
      this.setState({ isSaving: true });
      this.props.invite.mode === 'TEAM'
        ? await this.props.doRevokeTeamInvitation({ token: this.props.invite.token })
        : this.props.doRevokeInvitation({ token: this.props.invite.token });
      this.setState({
        title: (
          <>
            Invite successfully revoked
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        isDone: true,
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isSaving: false });
  };

  render() {
    const { isOpen, invite } = this.props;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {!_.isEmpty(invite) &&
          (this.state.isDone ? (
            <>
              <div className="mv-medium ml-x4-large anim-slide-left">
                <div>
                  <SubTitle>User</SubTitle>
                  <div className={'flex-row align-center'}>
                    <Avatar shape={'square'} src={invite.attachmentUrl} className={'mr-medium'} />
                    <Text>{invite.firstName + ' ' + invite.lastName}</Text>
                  </div>
                </div>

                <ActionModalFooter>
                  <PrimaryButton size="large" onClick={this._onCloseModal}>
                    Done
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ) : (
            <>
              <div className="mv-medium anim-slide-left">
                <div>
                  <Information
                    content={
                      <>
                        <Text weight={'bold'}>
                          Revoking this invitation will invalidate all invite links sent to this user.
                        </Text>
                        <br />
                        The user will not be able to login and access any GoodHuman product associated with your
                        business. Invites can be re-sent at a later date.
                      </>
                    }
                  />
                  <div className={'mt-large'}>
                    <SubTitle>User</SubTitle>
                    <div className={'flex-row align-center'}>
                      <Avatar shape={'square'} src={invite.attachmentUrl} className={'mr-medium'} />
                      <Text>{invite.firstName + ' ' + invite.lastName}</Text>
                    </div>
                  </div>
                  <div className={'mt-large'}>
                    <SubTitle>Current invitation details</SubTitle>
                    {invite.email}
                    <br />
                    Sent on {moment(invite.sentOn).format('DD/MM/YYYY')}
                  </div>
                </div>

                <ActionModalFooter>
                  <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" loading={this.state.isSaving} onClick={this._saveAction}>
                    Revoke Invitation
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ))}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRevokeTeamInvitation: dispatch.teamStore.doRevokeTeamInvitation,
  doRevokeInvitation: dispatch.teamStore.doRevokeInvitation,
});

export default connect(null, mapDispatch)(RevokeInvitationModal);
