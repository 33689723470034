import { Paragraph } from 'common-components/typography';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IRootState, state } from 'src/stores/rematch/root-store';

interface IProps {
  workflowSelected: typeof state.workflowStore.workflowSelected;
}

class TriggerWorkflowManually extends PureComponent<IProps> {
  render() {
    const { workflowSelected } = this.props;

    return (
      <div className="flex-column anim-slide-left mb-medium">
        <Paragraph>
          The workflow you&apos;ve selected: <b>{workflowSelected.name}</b> is usually triggered by an incident report.
        </Paragraph>
        <Paragraph>Triggering this workflow manually will skip the trigger event and any form linked to it.</Paragraph>
        <Paragraph>Are you sure you want to start this workflow?</Paragraph>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  workflowSelected: state.workflowStore.workflowSelected,
});

export default connect(mapState, null)(TriggerWorkflowManually);
