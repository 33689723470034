import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Stack, Inline, Text } from '@goodhuman-me/components';

import { TextField } from 'design-components';
import CancelBookingFieldError from './CancelBookingFieldError';
import { Schema, SchemaFieldNames } from '../schema';
import { IField } from '../fields';

import CancelBookingNumberInputLegacy from './legacy/CancelBookingNumberInput';

const USE_LEGACY = true;

interface ICancelBookingNumberInputProps {
  field: IField<SchemaFieldNames, number>;
  disabled?: boolean;
  precision?: number;
  step?: number;
  max?: number;
  extra?: string;
}

const CancelBookingNumberInput = ({
  field,
  disabled,
  precision,
  step = 1,
  max,
  extra,
}: ICancelBookingNumberInputProps): JSX.Element => {
  const { control } = useFormContext<Schema>();

  return (
    <Controller
      name={field.name}
      defaultValue={field.defaultValue}
      control={control}
      rules={field.rules(max)}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
        <Stack gap="$xsmall">
          <Inline gap="$space100" alignItems="center">
            <TextField
              type="number"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              defaultValue={field.defaultValue}
              aria-label={field.name}
              containerStyles={{ width: 50 }}
              validations={[{ status: error ? 'invalid' : 'valid' }]}
              precision={precision}
              step={step}
              paddingY="$xxsmall"
              paddingX="$xsmall"
              min={0}
              isDisabled={disabled}
              ref={ref}
              inputRef={ref}
            />
            <Text fontWeight={500}>{extra}</Text>
          </Inline>
          <CancelBookingFieldError message={error?.message} />
        </Stack>
      )}
    />
  );
};

export default USE_LEGACY ? CancelBookingNumberInputLegacy : CancelBookingNumberInput;
