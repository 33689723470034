import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const BulbSlash = forwardRef(function BulbSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.53 1.47a.75.75 0 0 0-1.06 1.06L4.878 5.94A7.727 7.727 0 0 0 4.25 9c0 2.066.81 3.944 2.126 5.332 1.069 1.127 1.874 2.311 1.874 3.579V19a3.75 3.75 0 1 0 7.5 0v-1.09c0-.32.052-.638.148-.952l5.572 5.572a.75.75 0 0 0 1.06-1.06l-6.342-6.343-9.876-9.875L2.53 1.47ZM5.75 9c0-.662.103-1.299.293-1.897L14.74 15.8a5.713 5.713 0 0 0-.25.635c-.032.02-.073.043-.12.07a6.865 6.865 0 0 1-.612.31c-.52.231-1.159.435-1.758.435-.598 0-1.237-.204-1.758-.435a6.85 6.85 0 0 1-.732-.38c-.401-1.237-1.244-2.29-2.045-3.135A6.226 6.226 0 0 1 5.75 9Zm4 9.236c.596.254 1.406.514 2.25.514.845 0 1.654-.26 2.25-.514V19a2.25 2.25 0 0 1-4.5 0v-.764ZM8.43 3.87a6.25 6.25 0 0 1 8.701 8.701.75.75 0 0 0 1.23.858A7.75 7.75 0 0 0 7.57 2.64a.75.75 0 0 0 .858 1.23Z"
        fill="currentColor"
      />
    </Icon>
  );
});
