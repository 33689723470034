import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Col, Divider, Icon, Popover, Row } from 'antd';
import { GhostButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { ICard } from 'interfaces/subscription-interfaces';
import React from 'react';
import LogoCardItem from './LogoCardItem';

interface ICardItemProps {
  card: ICard;
  isCardExpiry: boolean;
  hasDivider: boolean;
  hasPermission: boolean;
  onlyCardDefault: boolean;
  setDefaultPayment?: () => void;
  openEditCardModal?: () => void;
  openRemoveCardModal?: () => void;
}
const CardItem = (props: ICardItemProps) => {
  const {
    card,
    hasPermission,
    hasDivider,
    onlyCardDefault,
    isCardExpiry,
    setDefaultPayment,
    openEditCardModal,
    openRemoveCardModal,
  } = props;

  return (
    <div className={`${card.isDefault && hasPermission ? 'bg-light-gray-lighter' : ''}`}>
      <Row
        className={`pv-medium flex align-center ${hasPermission ? 'justify-center' : 'justify-around'} ${
          card.isDefault ? 'text-weight-bold' : ''
        }`}
      >
        <LogoCardItem brand={card?.brand} />
        <Col span={7}>
          <Text size="regular" className="text-capitalize">
            {card.isDefault ? `${card.brand} (Default)` : card.brand}
          </Text>
        </Col>
        <Col span={5} className="text-align-center">
          <Text size="regular">•••• {card.last4}</Text>
        </Col>
        <Col span={6} className="text-align-center">
          <Text size="regular" color={isCardExpiry ? 'red' : ''}>
            {isCardExpiry ? `Expired` : 'Expiry'} {card.expMonth < 10 ? '0' + card.expMonth : card.expMonth}/
            {card.expYear.toString().slice(-2)}
          </Text>
        </Col>
        {hasPermission && (
          <Col span={2} className="text-align-center">
            <Popover2
              content={
                <Menu>
                  {!card.isDefault && (
                    <>
                      <MenuItem
                        style={{ paddingLeft: 23, paddingRight: 23 }}
                        className="hover-bg-blue-lightest pv-12"
                        text={
                          <Text size="regular" className="text-color-blue-action">
                            Set as default payment method
                          </Text>
                        }
                        onClick={setDefaultPayment}
                      />
                      <Divider className="divider-x-small" />
                    </>
                  )}
                  <MenuItem
                    style={{ paddingLeft: 23, paddingRight: 23 }}
                    className="hover-bg-blue-lightest pv-12"
                    text={
                      <Text size="regular" className="text-color-blue-action">
                        Edit...
                      </Text>
                    }
                    onClick={openEditCardModal}
                  />
                  <Divider className="divider-x-small" />
                  <MenuItem
                    style={{ paddingLeft: 23, paddingRight: 23 }}
                    className="hover-bg-blue-lightest text-color-gray pv-12"
                    onClick={openRemoveCardModal}
                    text={
                      <>
                        <Text size="regular" color={onlyCardDefault ? 'gray-dark' : 'red'}>
                          Remove
                        </Text>
                        {onlyCardDefault && (
                          <Popover
                            placement="bottom"
                            overlayClassName="rounded-big"
                            content={
                              <div className="p-x-small" style={{ maxWidth: '340px' }}>
                                <Text size="regular">
                                  This card cannot be removed as it is the only payment method. Please add a new default
                                  payment method to remove this card.
                                </Text>
                              </div>
                            }
                          >
                            <Icon type="question-circle" className="text-color-tertiary ml-small" />
                          </Popover>
                        )}
                      </>
                    }
                  />
                </Menu>
              }
              modifiers={{ arrow: { enabled: false } }}
              popoverClassName="mb-12"
              position="bottom-right"
              interactionKind="click"
            >
              <GhostButton paddingSize="small" color="secondary" className={card.isDefault ? 'bg-transparent' : ''}>
                <Icon type="ellipsis" className="text-size-x3-large cursor-pointer" />
              </GhostButton>
            </Popover2>
          </Col>
        )}
      </Row>
      {hasDivider && <Divider className="divider-x-small" />}
    </div>
  );
};

export default CardItem;
