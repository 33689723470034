import _ from 'lodash';
import { IPermissions } from 'interfaces/account-interfaces';
import {
  CustomerPermissionType,
  FormBuilderPermissionType,
  PaymentPermissionType,
  PlanManagementPermissionType,
  ServicePermissionType,
  SubscriptionPermissionType,
  TeamPermissionType,
  UserPermissionRole,
  WorkflowPermissionType,
} from 'utilities/enum-utils';
import { SUBSCRIPTION_PERMISSION_LIST } from './constants';

// quick wrapper for lodash
function permissionsLookup(permissions) {
  return (role) =>
    permissions && permissions.permissionRoles && _.some(permissions.permissionRoles, ['permissionRole', role]);
}

// Note from Jir : I don't like the way the following functions are written,  to be honest.
// It's a bit messy and unintuitive. To refactor later to remove these functions into maybe dictionary later on.

// Converts permission roles to user permission types
export function getPaymentPermissionName(permissions: IPermissions, hasAllAccess = false) {
  const permissionsContain = permissionsLookup(permissions);

  if (hasAllAccess) {
    return PaymentPermissionType.FULL_ACCESS;
  } else if (permissionsContain(UserPermissionRole.PaymentManager)) {
    return PaymentPermissionType.MANAGER;
  } else if (permissionsContain(UserPermissionRole.PaymentMember)) {
    return PaymentPermissionType.MEMBER;
  } else {
    return PaymentPermissionType.NO_ACCESS;
  }
}

// Converts permission types to user permission roles
export function getPaymentRole(permissionName: string) {
  if (permissionName === PaymentPermissionType.MANAGER) {
    return UserPermissionRole.PaymentManager;
  } else if (permissionName === PaymentPermissionType.MEMBER) {
    return UserPermissionRole.PaymentMember;
  } else {
    return null;
  }
}

// Converts team role to team permission type
export function getTeamPermissionName(permissions: IPermissions, hasAllAccess = false) {
  const permissionsContain = permissionsLookup(permissions);

  if (hasAllAccess) {
    return PaymentPermissionType.FULL_ACCESS;
  } else if (permissionsContain(UserPermissionRole.TeamManager)) {
    return TeamPermissionType.MANAGE;
  } else if (permissionsContain(UserPermissionRole.TeamViewOnly)) {
    return TeamPermissionType.VIEW_ONLY;
  } else {
    return TeamPermissionType.NO_ACCESS;
  }
}

// Converts team permission to team roles
export function getTeamRole(permissionName: string) {
  switch (permissionName) {
    case TeamPermissionType.MANAGE:
      return UserPermissionRole.TeamManager;
    case TeamPermissionType.VIEW_ONLY:
      return UserPermissionRole.TeamViewOnly;
    default:
      return UserPermissionRole.TeamNoAccess;
  }
}

export function getCustomerPermissionName(permissions: IPermissions, hasAllAccess = false) {
  const permissionsContain = permissionsLookup(permissions);

  if (hasAllAccess) {
    return PaymentPermissionType.FULL_ACCESS;
  } else if (permissionsContain(UserPermissionRole.CustomerAccessManager)) {
    return CustomerPermissionType.MANAGER;
  } else if (permissionsContain(UserPermissionRole.CustomerAccessViewOnly)) {
    return CustomerPermissionType.VIEW_ONLY;
  } else {
    return CustomerPermissionType.VIEW_ONLY;
  }
}

export function getWorkflowPermissionName(permissions: IPermissions, hasAllAccess = false) {
  const permissionsContain = permissionsLookup(permissions);

  if (hasAllAccess) {
    return PaymentPermissionType.FULL_ACCESS;
  } else if (permissionsContain(UserPermissionRole.WorkflowManager)) {
    return WorkflowPermissionType.MANAGER;
  } else if (permissionsContain(UserPermissionRole.WorkflowElevated)) {
    return WorkflowPermissionType.ELEVATED;
  } else if (permissionsContain(UserPermissionRole.WorkflowBasic)) {
    return WorkflowPermissionType.BASIC;
  } else {
    return WorkflowPermissionType.BASIC;
  }
}

export function getPlanManagementPermissionName(permissions: IPermissions, hasAllAccess = false) {
  const permissionsContain = permissionsLookup(permissions);

  if (hasAllAccess || permissionsContain(UserPermissionRole.PlanManager)) {
    return PlanManagementPermissionType.ACCESS;
  } else {
    return PlanManagementPermissionType.NO_ACCESS;
  }
}

export function getSubscriptionBillingPermission(
  permissions?: IPermissions,
  permissionType?: SubscriptionPermissionType,
) {
  const permissionsContain = permissionsLookup(permissions);

  if (permissionType) return SUBSCRIPTION_PERMISSION_LIST.find((permission) => permission.type === permissionType);

  if (permissionsContain(UserPermissionRole.SubscriptionBillingAllAccess))
    return SUBSCRIPTION_PERMISSION_LIST.find((permission) => permission.type === SubscriptionPermissionType.ALL_ACCESS);

  if (permissionsContain(UserPermissionRole.SubscriptionBillingViewOnly))
    return SUBSCRIPTION_PERMISSION_LIST.find((permission) => permission.type === SubscriptionPermissionType.VIEW_ONLY);

  return SUBSCRIPTION_PERMISSION_LIST.find((permission) => permission.type === SubscriptionPermissionType.NO_ACCESS);
}

export function getCustomerRole(permissionName: string) {
  if (permissionName === CustomerPermissionType.MANAGER) {
    return UserPermissionRole.CustomerAccessManager;
  } else if (permissionName === CustomerPermissionType.VIEW_ONLY) {
    return UserPermissionRole.CustomerAccessViewOnly;
  } else {
    return null;
  }
}

export function getWorkflowRole(permissionName: string) {
  if (permissionName === WorkflowPermissionType.MANAGER) {
    return UserPermissionRole.WorkflowManager;
  } else if (permissionName === WorkflowPermissionType.ELEVATED) {
    return UserPermissionRole.WorkflowElevated;
  } else if (permissionName === WorkflowPermissionType.BASIC) {
    return UserPermissionRole.WorkflowBasic;
  } else {
    return null;
  }
}

export function getPlanManagementRole(permissionName: string) {
  if (permissionName === PlanManagementPermissionType.ACCESS) {
    return UserPermissionRole.PlanManager;
  } else {
    return null;
  }
}

export function getServicePermission(permissionRole: UserPermissionRole, hasAllAccess = false) {
  if (hasAllAccess) {
    return PaymentPermissionType.FULL_ACCESS;
  } else if (permissionRole === UserPermissionRole.ServiceAdmin) {
    return ServicePermissionType.MANAGER;
  } else if (permissionRole === UserPermissionRole.ServiceMember) {
    return ServicePermissionType.MEMBER;
  } else {
    return ServicePermissionType.NO_ACCESS;
  }
}

export function getServiceRole(permissionName: string) {
  if (permissionName === ServicePermissionType.MANAGER) {
    return UserPermissionRole.ServiceAdmin;
  } else if (permissionName === ServicePermissionType.MEMBER) {
    return UserPermissionRole.ServiceMember;
    // } else if (permissionName === ServicePermissionType.CUSTOM) {
    //   return UserPermissionRole.ServiceMemberCustom
  } else {
    // No access
    return null;
  }
}

export function getFormBuilderPermissionName(permissions: IPermissions, hasAllAccess = false) {
  const permissionsContain = permissionsLookup(permissions);

  if (hasAllAccess || permissionsContain(UserPermissionRole.FormBuilder)) {
    return FormBuilderPermissionType.ACCESS;
  } else {
    return FormBuilderPermissionType.NO_ACCESS;
  }
}

export function getFormBuilderRole(permissionName: string) {
  if (permissionName === FormBuilderPermissionType.ACCESS) {
    return UserPermissionRole.FormBuilder;
  } else {
    return null;
  }
}
