import React, { Component } from 'react';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { notification, Row } from 'antd';
import _ from 'lodash';
import { Spinner } from '@blueprintjs/core';

interface IActivityRecordArchiveModalProps {
  isOpen: boolean;
  onClose: () => void;
  doArchiveBooking: typeof dispatch.bookingsStore.doArchiveBooking;
  selectedBooking: typeof state.bookingsStore.selectedBookingItem;
  recurringBookingList: typeof state.bookingsStore.recurringBookingList;
}

interface IActivityRecordArchiveModalState {
  title: string;
  canManuallyClose: boolean;
  isLoading: boolean;
}

class ActivityRecordArchiveModal extends Component<IActivityRecordArchiveModalProps, IActivityRecordArchiveModalState> {
  state = {
    title: 'Archive record',
    canManuallyClose: true,
    isLoading: false,
  };

  private _onArchive = async () => {
    const { selectedBooking } = this.props;
    this.setState({ isLoading: true, canManuallyClose: false });
    try {
      const { doArchiveBooking } = this.props;
      await doArchiveBooking({
        bookingId: selectedBooking.bookingId,
        isRecurring: selectedBooking.isRecurring,
        bookingRequestId: selectedBooking.bookingRequestId,
      });
      notification.success({
        message: 'Activity record archived',
        description: 'You have successfully archived this activity record',
      });
      this._onCloseModal();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this._onCloseModal();
    }
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({
      title: 'Archive booking',
      canManuallyClose: true,
      isLoading: false,
    });
    onClose();
  };

  render() {
    let { isOpen } = this.props;
    let { title, isLoading } = this.state;

    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        {isLoading ? (
          <Spinner size={150} />
        ) : (
          <>
            <div className="anim-slide-left">
              <Paragraph>Are you sure you want to archive this record?</Paragraph>

              <Paragraph>This action is permanent and cannot be undone.</Paragraph>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton
                  className="mr-medium"
                  size="large"
                  onClick={this._onCloseModal}
                  disabled={this.state.isLoading}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onArchive} loading={this.state.isLoading}>
                  Archive
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  recurringBookingList: state.bookingsStore.recurringBookingList,
  selectedBooking: state.bookingsStore.selectedBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doArchiveBooking: dispatch.bookingsStore.doArchiveBooking,
});

export default connect(mapState, mapDispatch)(ActivityRecordArchiveModal);
