import { css } from '../../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../../theme';

export const alertTitle: CSS = {
  display: 'flex',
  alignItems: 'center',
  fontWeight: '$$titleWeight',
  fontSize: '$font100',
  lineHeight: '$leading400',
  position: 'relative',
  color: '$black',
};

export const alertTitleVariants = css({
  fontWeight: '$$titleWeight',
  fontSize: '$$titleFont',
  gap: '$$titleGap',

  kind: {
    inline: {},
    hero: {},
  },
  middle: {
    true: {},
  },
  variants: {
    boldTitle: {
      true: {
        $$titleWeight: '$fontWeights-bold',
      },
    },
    kind: {
      inline: {
        $$titleFont: '$fontSizes-font100',
        $$titleGap: '$space-space100',
      },
      hero: {
        $$titleFont: '$fontSizes-font75',
        $$titleGap: '$space-space115',
      },
    },
    middle: {
      true: {
        justifyContent: 'center',
      },
    },
    state: {
      hero: {},
      success: {},
      warning: {},
      danger: {},
    },
    emphasis: {
      filled: {},
      outlined: {},
      ghost: {},
    },
  },
  compoundVariants: [
    {
      state: 'hero',
      emphasis: 'ghost',
      css: {
        color: '$black',
      },
    },
    {
      state: 'success',
      emphasis: 'ghost',
      css: {
        color: '$positive',
      },
    },
    {
      state: 'warning',
      emphasis: 'ghost',
      css: {
        color: '$notice',
      },
    },
    {
      state: 'danger',
      emphasis: 'ghost',
      css: {
        color: '$critical',
      },
    },
  ],
});

export type AlertTitleVariants = Stitches.VariantProps<typeof alertTitleVariants>;
