import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const SortText = forwardRef(function SortText(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 2.25A2.75 2.75 0 0 0 14.25 5v4a.75.75 0 0 0 1.5 0V7.5h2.5V9a.75.75 0 0 0 1.5 0V5A2.75 2.75 0 0 0 17 2.25ZM18.25 5v1h-2.5V5a1.25 1.25 0 1 1 2.5 0ZM7 5.25a.75.75 0 0 1 .75.75v10.19l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l1.72 1.72V6A.75.75 0 0 1 7 5.25ZM14.25 13a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 .624 1.166l-3.223 4.834H19a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.624-1.166l3.223-4.834H15a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
