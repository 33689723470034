import { Checkbox, Col, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import _ from 'lodash';
import React, { Component } from 'react';
import { UserPermissionRole } from 'utilities/enum-utils';
import { IWorkflowTemplateStep } from '../../../../interfaces/workflow-interfaces';

const roles = [
  { id: UserPermissionRole.ServiceAdmin, name: 'Service managers' },
  { id: UserPermissionRole.DepartmentManager, name: 'Department managers' },
  { id: UserPermissionRole.Owner, name: 'Owners' },
  { id: UserPermissionRole.PrimaryOwner, name: 'Primary owner' },
];

interface Props {
  isOpen: boolean;
  selectedStep: IWorkflowTemplateStep;
  onUpdateStep(step: IWorkflowTemplateStep): void;
  onClose(): void;
}

interface State {
  selectedRole: number[];
}
class WorkflowTemplateSelectRoleModal extends Component<Props, State> {
  state = {
    selectedRole: [],
  };

  private _onSetStepApproverRole = () => {
    const { selectedStep, onUpdateStep, onClose } = this.props;

    if (!this.props.selectedStep) {
      return;
    }

    const stepApprovers = this.state.selectedRole.map((roleId: number) => ({ approverPermissionRole: roleId }));

    onUpdateStep({ ...selectedStep, stepApprovers, invalid: false });

    onClose();
  };

  private _onChangeSelectedRole = (values: number[]) => {
    this.setState({ selectedRole: values.sort((a, b) => b - a) });
  };

  private _onClose = () => {
    if (this.props.selectedStep) {
      this.setState({ selectedRole: this.props.selectedStep.stepApprovers.map((one) => one.approverPermissionRole) });
    }

    this.props.onClose();
  };

  componentDidUpdate(prevProps: Props) {
    if (!_.isEqual(prevProps.selectedStep, this.props.selectedStep)) {
      this.setState({
        selectedRole: this.props.selectedStep
          ? this.props.selectedStep.stepApprovers.map((one) => one.approverPermissionRole)
          : [],
      });
    }
  }

  componentDidMount() {
    if (this.props.selectedStep) {
      this.setState({ selectedRole: this.props.selectedStep.stepApprovers.map((one) => one.approverPermissionRole) });
    }
  }

  render() {
    const { isOpen } = this.props;

    const { selectedRole } = this.state;

    return (
      <ActionModal
        isOpen={isOpen}
        title={'Select a role for approval'}
        width="large"
        onClose={this._onClose}
        showCloseButton={true}
        verticalAlignment="center"
      >
        Please select the specific role which you want to have as an approver for this step
        <Checkbox.Group value={selectedRole} onChange={this._onChangeSelectedRole}>
          {roles.map((one) => (
            <Row className="mv-medium" key={one.id}>
              <Col span={24}>
                <Checkbox value={one.id}>{one.name}</Checkbox>
              </Col>
            </Row>
          ))}
        </Checkbox.Group>
        <ActionModalFooter>
          <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton disabled={!this.state.selectedRole.length} size="large" onClick={this._onSetStepApproverRole}>
            Add
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default WorkflowTemplateSelectRoleModal;
