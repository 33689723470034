import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Grid3Horizontal = forwardRef(function Grid3Horizontal(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.948 2.25h10.104c.899 0 1.648 0 2.243.08.627.084 1.194.27 1.65.725.455.456.64 1.023.725 1.65.08.595.08 1.345.08 2.243v10.104c0 .899 0 1.648-.08 2.242-.084.628-.27 1.195-.725 1.65-.456.456-1.023.642-1.65.726-.595.08-1.344.08-2.243.08H6.948c-.898 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.726-.456-.455-.642-1.022-.726-1.65-.08-.594-.08-1.343-.08-2.242V6.948c0-.898 0-1.648.08-2.242.084-.628.27-1.195.726-1.65.455-.456 1.022-.642 1.65-.726.594-.08 1.344-.08 2.242-.08Zm8.802 18H17c.964 0 1.612-.002 2.095-.067.461-.062.659-.169.789-.3.13-.13.238-.327.3-.788.065-.483.066-1.131.066-2.095V7c0-.964-.001-1.612-.066-2.095-.062-.461-.17-.659-.3-.789-.13-.13-.328-.237-.79-.3-.482-.064-1.13-.066-2.094-.066h-1.25v16.5Zm-1.5-16.5v16.5h-4.5V3.75h4.5ZM7 3.75h1.25v16.5H7c-.964 0-1.611-.002-2.094-.067-.462-.062-.66-.169-.79-.3-.13-.13-.237-.327-.3-.788-.064-.483-.066-1.131-.066-2.095V7c0-.964.002-1.612.067-2.095.062-.461.169-.659.3-.789.13-.13.327-.237.789-.3.483-.064 1.13-.066 2.094-.066Z"
        fill="currentColor"
      />
    </Icon>
  );
});
