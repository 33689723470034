import { reactTrpc } from 'utilities/react-trpc';

export function useUpdateService() {
  const utils = reactTrpc.useContext();
  return reactTrpc.services.service.update.useMutation({
    onSuccess: async (data) => {
      await utils.services.service.fetch.invalidate({
        serviceProviderId: data.serviceProviderId,
        serviceId: data.serviceId,
      });
    },
  });
}
