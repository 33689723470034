import _ from 'lodash';
import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ErrorSVG } from 'assets/UndrawSVG';
import { Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Spinner } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import WorkerStatusTag from 'common-components/tags/WorkerStatusTag';
import { Avatar, notification } from 'antd';
import { BookingActionType, EditRecurringMode, ShiftSlotStatus } from 'utilities/enum-utils';
import { ShiftClashContent } from 'common-components/shift-clash';
import { IShiftClashConflict } from 'interfaces/service-interfaces';
import CommonUtils from 'utilities/common-utils';
import BookingActionSuccessNotificationContent from './BookingActionSuccessNotificationContent';

interface IBookingConfirmShiftForWorkerActionModelState {
  step: number;
  canManuallyClose: boolean;
  title: string;
  width: string;
  shiftClashConflicts: IShiftClashConflict[];
  isLoading: boolean;
  shiftsOutsideGeneralAvailability: any;
  shiftSlotStatus: ShiftSlotStatus;
  ignoreOutsideAvailabilityShifts: any;
}

class BookingConfirmShiftForWorkerActionModel extends Component<any, IBookingConfirmShiftForWorkerActionModelState> {
  state = {
    step: 1,
    canManuallyClose: true,
    title: 'Confirm shift on behalf of Worker',
    width: 'medium',
    isShiftClashModalOpen: false,
    shiftClashConflicts: [],
    isLoading: false,
    shiftsOutsideGeneralAvailability: null,
    shiftSlotStatus: ShiftSlotStatus.CONFIRMED,
    ignoreOutsideAvailabilityShifts: [],
  };

  private _renderView = () => {
    const { step, shiftClashConflicts, shiftsOutsideGeneralAvailability, shiftSlotStatus } = this.state;
    const { selectedBooking, displayTimezone } = this.props;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <div>
            <Paragraph>
              The assigned Worker for this shift has not yet confirmed. You can confirm on their behalf if they are
              unable to do so (the Worker will still receive notification).
            </Paragraph>
          </div>
          <div className="text-align-center pv-x-large">
            <WorkerStatusTag shiftSlotStatus={selectedBooking.shiftSlotStatus} size="x-large" />
            <div className="mt-medium">
              <Avatar
                icon="user"
                size={120}
                className="mb-small"
                shape="square"
                src={selectedBooking.workerAttachmentUrl}
              />
              <br />
              <b>
                <Text size="x-large">
                  {selectedBooking.workerFirstName} {selectedBooking.workerLastName}
                </Text>
              </b>
            </div>
          </div>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" icon="check" onClick={this._checkForConflict}>
              Confirm on behalf of Worker
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Confirming this booking, won't be long...</Paragraph>
          </div>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Oops something has gone wrong, please try again</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Go Back to Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (this.state.step === 5) {
      const totalShiftClashes = CommonUtils.calculateTotalShiftClashes(shiftClashConflicts);
      return (
        <ShiftClashContent
          shiftClashConflicts={shiftClashConflicts}
          description={
            <Paragraph>
              This team member is assigned to{' '}
              <b>
                {totalShiftClashes} shift{totalShiftClashes > 1 ? 's ' : ' '}
              </b>
              that conflict{totalShiftClashes === 1 ? 's' : ''} with this booking time. Be sure to double-check their
              availability before assigning.
            </Paragraph>
          }
          firstColumnTitle="Booking being assigned"
          okButtonText="Confirm anyway"
          isLoading={this.state.isLoading}
          onCancel={() => {
            this.setState({
              step: 1,
              title: 'Confirm shift on behalf of Worker',
              width: 'medium',
            });
          }}
          onOk={() => this._onAccept()}
        />
      );
    }
  };

  private _checkForConflict = async () => {
    const { selectedBooking, doCheckAssignWorker } = this.props;
    let result = null;
    try {
      result = await doCheckAssignWorker({
        bookingRequestId: selectedBooking.bookingRequestId,
        bookingId: selectedBooking.bookingId,
        editRecurringMode: EditRecurringMode.Current,
        workerId: selectedBooking.workerId,
        startDateTime: selectedBooking.startDateTime,
        isConfirmBookings: true,
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    if (result && result.data && !_.isEmpty(result.data.shiftClashConflicts)) {
      this.setState({
        shiftClashConflicts: result.data.shiftClashConflicts,
        shiftsOutsideGeneralAvailability: !_.isEmpty(result.data.shiftsOutsideGeneralAvailability)
          ? result.data.shiftsOutsideGeneralAvailability
          : null,
        step: 5,
        title: 'Hold on, this team member is already working',
        width: 'x-large',
      });
    } else {
      this._onAccept();
    }
  };

  private _onAccept = async () => {
    const { doConfirmShiftForWorker, selectedBooking } = this.props;
    this.setState({ step: 2, canManuallyClose: false, width: 'medium', isLoading: true });
    try {
      await doConfirmShiftForWorker({
        bookingId: selectedBooking.bookingId,
        isRemovePendingShiftSlots: true,
      });

      notification.open({
        message: <b>Team member confirmed</b>,
        description: (
          <BookingActionSuccessNotificationContent
            action={BookingActionType.CONFIRM}
            shiftsCount={1}
            currentShiftStatus={ShiftSlotStatus.CONFIRMED}
            workerName={`${selectedBooking.workerFirstName} ${selectedBooking.workerLastName}`}
          />
        ),
      });
      this._onCloseModal();
    } catch (e) {
      this._onError();
    }

    this.setState({ isLoading: false });
  };

  private _onError = () => {
    this.setState({ step: 4, canManuallyClose: true, width: 'medium' });
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, title: 'Confirm shift on behalf of Worker', width: 'medium' }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width={this.state.width}
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this._renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doConfirmShiftForWorker: dispatch.bookingsStore.doConfirmShiftForWorker,
  doCheckAssignWorker: dispatch.bookingsStore.doCheckAssignWorker,
});

export default connect(mapState, mapDispatch)(BookingConfirmShiftForWorkerActionModel);
