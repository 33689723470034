import React, { forwardRef } from 'react';
import { StatusLabel } from '@goodhuman-me/components';
import { useTranslation } from 'react-i18next';

import { BUDGET_STATUS, statusToTone } from '../../budget';
import type { BudgetStatus } from '../../budget';
import type { StatusLabelProps } from '@goodhuman-me/components';
import styled from 'styled-components';

export type BudgetStatusLabelProps = {
  status: BudgetStatus;
} & Omit<StatusLabelProps, 'tone'>;

const LabelText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 1200px) {
    width: 80px;
  }
  @media screen and (max-width: 700px) {
    width: 70px;
  }
`;

/**
 * @name
 * BudgetStatusLabel
 *
 * @example
 * <BudgetStatusLabel status="SLIGHTLY_AHEAD" />
 */
export const BudgetStatusLabel = forwardRef<HTMLDivElement, BudgetStatusLabelProps>(function BudgetStatusLabel(
  props,
  ref,
) {
  const { t } = useTranslation('', { keyPrefix: 'budgetStatus' });
  const { status, ...otherProps } = props;

  const emphasis = status === BUDGET_STATUS.OVERBUDGET ? 'heavy' : 'light';
  const statusText = t(status);
  const tone = statusToTone(status);

  return (
    <StatusLabel ref={ref} {...otherProps} emphasis={emphasis} tone={tone}>
      <LabelText>{statusText}</LabelText>
    </StatusLabel>
  );
});
