import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ActionModal from 'common-components/modal/ActionModal';
import { Form, notification } from 'antd';
import { isEmpty } from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { IRootDispatch } from '../../../../../stores/rematch/root-store';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { PermanentlyRemoveUserModalStep1 } from './PermanentlyRemoveUserModalStep1';
import { PermanentlyRemoveUserModalStep2 } from './PermanentlyRemoveUserModalStep2';
import { TeamMember } from 'interfaces/team-member-interface';

type IPermanentlyRemoveUserModalProps = FormComponentProps & {
  isOpen: boolean;
  onClose: () => void;
  worker: TeamMember;
};
type Ref = FormComponentProps;

const PermanentlyRemoveUserForm = forwardRef<Ref, IPermanentlyRemoveUserModalProps>(
  ({ onClose, worker, form, isOpen }: IPermanentlyRemoveUserModalProps, ref) => {
    useImperativeHandle(ref, () => ({
      form,
    }));

    const { teamStore } = useDispatch<IRootDispatch>();
    const { doRemoveTeamMember } = teamStore;

    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [removeReason, setRemoveReason] = useState('');
    const [isSelectFutureDate, setIsSelectFutureDate] = useState(false);
    const [removalDate, setRemovalDate] = useState(null);
    const [title, setTitle] = useState('Remove');

    const onModalClose = () => {
      setTitle('Remove');
      setStep(1);
      setIsLoading(false);
      setRemoveReason('');
      setRemovalDate(null);
      setIsSelectFutureDate(false);
      onClose();
    };

    const onModalSave = async () => {
      let hasError = false;
      form.validateFields((err) => {
        if (err) {
          hasError = true;
        }
      });

      if (!hasError) {
        setIsLoading(true);
        const formRemoveReason = form.getFieldValue('removeReason');
        const formRemovalDate = form.getFieldValue('removalDate');
        try {
          const payload = isSelectFutureDate
            ? {
                removeReason: formRemoveReason,
                supportWorkerId: worker.supportWorkerId,
                removedOn: moment.utc(moment(formRemovalDate).format('YYYY/MM/DD')).toISOString(),
              }
            : { removeReason: formRemoveReason, supportWorkerId: worker.supportWorkerId };

          await doRemoveTeamMember(payload);
          if (isSelectFutureDate) {
            setRemovalDate(formRemovalDate);
          }
          setTitle(!isSelectFutureDate ? 'User successfully removed' : 'User successfully scheduled for removal');
          setRemoveReason(formRemoveReason);
          setStep(2);
        } catch (e) {
          notification.error({ message: 'Something went wrong, please try again.' });
        }

        setIsLoading(false);
      }
    };

    const onCheckBoxChange = (e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
      setIsSelectFutureDate(e.target.checked);
    };

    const onChangeRemovalDate = (date: object) => {
      form.setFieldsValue({ removalDate: date });
    };

    const showInModal =
      isEmpty(worker) || ![1, 2].includes(step) ? (
        <></>
      ) : step === 1 ? (
        <Form>
          <PermanentlyRemoveUserModalStep1
            worker={worker}
            form={form}
            isSelectFutureDate={isSelectFutureDate}
            isLoading={isLoading}
            onCheckBoxChange={onCheckBoxChange}
            onChangeRemovalDate={onChangeRemovalDate}
            onClose={onModalClose}
            onSave={onModalSave}
          />
        </Form>
      ) : (
        //step 2
        <PermanentlyRemoveUserModalStep2
          worker={worker}
          isSelectFutureDate={isSelectFutureDate}
          removalDate={removalDate}
          removeReason={removeReason}
          onClose={onModalClose}
        />
      );

    return (
      <ActionModal
        title={!isEmpty(worker) ? (step === 2 ? title : `${title} ${worker.firstName} ${worker.lastName}`) : ''}
        isOpen={isOpen}
        onClose={onModalClose}
        canCloseOutside={!isLoading}
        width="medium"
      >
        {showInModal}
      </ActionModal>
    );
  },
);

PermanentlyRemoveUserForm.displayName = 'PermanentlyRemoveUserForm';

export const PermanentlyRemoveUserModal = Form.create<IPermanentlyRemoveUserModalProps>()(PermanentlyRemoveUserForm);
