import { Popover2 as Popover } from '@blueprintjs/popover2';
import { IRosterShift } from 'common-components/roster-control/interfaces/roster-interfaces';
import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';
import {
  getTimelineCardWidth,
  getTimelineOffset,
} from 'common-components/roster-control/roster-daily/timeline-pane/common/card-width-utils';
import OpenShiftPopover from 'common-components/roster-control/roster-daily/timeline-pane/workers/OpenShiftPopover';
import { BookingTypeIcon } from 'common-components/tags';
import TextTag from 'common-components/tags/TextTag';
import { Text } from 'common-components/typography';
import moment from 'moment';
import React from 'react';
import { BookingType } from 'utilities/enum-utils';

const { TIMELINE_CARD_HEIGHT, HOUR_BLOCK_WIDTH, TIMELINE_ROW_HEIGHT } = ROSTER_DAILY_CONFIG;

interface OpenShiftCardProps {
  shift: IRosterShift;
  rowIndex?: number;
}

export function OpenShiftCard({ shift, rowIndex }: OpenShiftCardProps) {
  const { shiftStartDateTime, shiftEndDateTime, serviceName, duplicateCount } = shift;

  const mStartDate = moment(shiftStartDateTime);
  const mEndDate = moment(shiftEndDateTime);

  const startTimeDisplay = mStartDate.format('h:mm a');
  const endTimeDisplay = mEndDate.format('h:mm a');

  // Card properties
  const cardWidth = getTimelineCardWidth(shiftStartDateTime, shiftEndDateTime);
  const leftOffset = getTimelineOffset(shiftStartDateTime);
  const topOffset = rowIndex === 0 ? 4 : rowIndex * TIMELINE_ROW_HEIGHT + 4;

  const isSmallCard = cardWidth < HOUR_BLOCK_WIDTH / 1.35;

  return (
    <div
      className="position-absolute rounded bg-gold-lightest flex-column inline-block rounded-big cursor-pointer bordered border-gold-lighter border-dashed"
      style={{
        width: `${cardWidth}px`,
        top: `${topOffset}px`,
        left: `${leftOffset}px`,
        minHeight: `${TIMELINE_CARD_HEIGHT}px`,
        maxHeight: `${TIMELINE_CARD_HEIGHT}px`,
        zIndex: 10,
      }}
    >
      {/* Content for timeline item */}
      <Popover content={<OpenShiftPopover shift={shift} />} position="bottom-right">
        <div
          className={`${isSmallCard ? 'pv-x-small ph-x2-small' : 'ph-small pv-x-small'} overflow-hidden`}
          style={{ width: `${cardWidth}px`, height: `${TIMELINE_CARD_HEIGHT}px` }}
        >
          {!isSmallCard && (
            <div className="flex-row">
              {duplicateCount > 1 && (
                <TextTag content={`${duplicateCount}x`} color={'gold'} size={'small'} className="mr-x-small" />
              )}

              <TextTag
                content={'Open slot'}
                theme="light"
                color="gold"
                size="small"
                weight="bold"
                backgroundColor={'white'}
              />
            </div>
          )}

          {!isSmallCard && (
            <div className="pt-x-small flex-column line-height-100">
              <div className="text-overflow-ellipsis overflow-hidden whitespace-nowrap">
                <Text lineHeight={100} size="small" className="whitespace-nowrap">
                  <b>{startTimeDisplay}</b> - {endTimeDisplay}
                </Text>
              </div>
              <div className={'flex-row align-center'}>
                <BookingTypeIcon
                  type={
                    shift.bookingType === BookingType.ACTIVITY_RECORD ? BookingType.ACTIVITY_RECORD : shift.serviceType
                  }
                  className={'mr-x-small'}
                />
                <div className="text-overflow-ellipsis overflow-hidden whitespace-nowrap">
                  <Text lineHeight={100} size="regular" className="whitespace-nowrap">
                    {serviceName}
                  </Text>
                </div>
              </div>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
}

export default OpenShiftCard;
