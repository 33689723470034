import React from 'react';
import { Flex, Button } from '@mantine/core';
export const ModalFooterButtons = ({ cancelProps, confirmProps, variant }) => {
    const buttonGap = {
        compact: '0.75rem',
        regular: '1rem',
    }[variant ?? 'regular'] ?? '1rem';
    const buttonSize = {
        compact: 'sm',
        regular: 'md',
    }[variant ?? 'regular'] ?? 'md';
    return (<div>
      <Flex gap={buttonGap}>
        {cancelProps && (<Button variant='transparent' color='neutral' {...cancelProps} size={buttonSize}>
            {cancelProps.label ?? 'Cancel'}
          </Button>)}
        {confirmProps && (<Button {...confirmProps} size={buttonSize}>
            {confirmProps.label ?? 'Confirm'}
          </Button>)}
      </Flex>
    </div>);
};
