import React from 'react';

import { Icon } from '../icon';

import type { IconProps } from '../icon';

export function IconWarning(props: IconProps) {
  return (
    <Icon width={props.width || 18} height={props.height || 17} viewBox="0 0 18 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16764 2.71298C7.76269 3.22182 7.30836 4.00325 6.65651 5.12917L3.27133 10.9763C2.61715 12.1063 2.16395 12.8924 1.92346 13.499C1.68501 14.1005 1.72627 14.3541 1.82203 14.5202C1.91779 14.6863 2.1166 14.8491 2.75661 14.9441C3.40214 15.0399 4.30953 15.0416 5.6152 15.0416H12.3855C13.6912 15.0416 14.5986 15.0399 15.2441 14.9441C15.8841 14.8491 16.083 14.6863 16.1787 14.5202C16.2745 14.3541 16.3157 14.1005 16.0773 13.499C15.8368 12.8924 15.3836 12.1063 14.7294 10.9763L11.3442 5.12918C10.6924 4.00325 10.238 3.22182 9.8331 2.71298C9.43177 2.20869 9.19193 2.11804 9.00037 2.11804C8.80882 2.11804 8.56897 2.20869 8.16764 2.71298ZM7.18957 1.9346C7.66975 1.33123 8.23101 0.868042 9.00037 0.868042C9.76974 0.868042 10.331 1.33123 10.8112 1.9346C11.2808 2.52477 11.7807 3.38811 12.3988 4.45579L12.426 4.50288L15.8112 10.35L15.8385 10.3971L15.8385 10.3972L15.8385 10.3972C16.459 11.4688 16.9605 12.3351 17.2393 13.0384C17.5242 13.7571 17.6468 14.4765 17.2616 15.1446C16.8764 15.8127 16.1925 16.067 15.4277 16.1806C14.6794 16.2916 13.6783 16.2916 12.44 16.2916H12.3855H5.6152H5.56074C4.32243 16.2916 3.32138 16.2916 2.57305 16.1806C1.80827 16.067 1.12433 15.8127 0.739123 15.1446C0.353921 14.4765 0.476503 13.7571 0.761431 13.0384C1.04023 12.3351 1.54181 11.4688 2.16226 10.3971L2.16227 10.3971L2.18955 10.35L5.57473 4.50288L5.60198 4.4558C6.22009 3.38811 6.7199 2.52477 7.18957 1.9346ZM9.00037 5.87494C9.34554 5.87494 9.62537 6.15476 9.62537 6.49994V9.83327C9.62537 10.1784 9.34554 10.4583 9.00037 10.4583C8.65519 10.4583 8.37537 10.1784 8.37537 9.83327V6.49994C8.37537 6.15476 8.65519 5.87494 9.00037 5.87494ZM9.00037 13.1666C9.46061 13.1666 9.8337 12.7935 9.8337 12.3333C9.8337 11.873 9.46061 11.4999 9.00037 11.4999C8.54013 11.4999 8.16704 11.873 8.16704 12.3333C8.16704 12.7935 8.54013 13.1666 9.00037 13.1666Z"
        fill={props.fill ?? '#C13232'}
      />
    </Icon>
  );
}
