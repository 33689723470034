import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';
import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';

interface ContentGroupHeaderProps {
  text: string;
  onClick: any;
  isOpen: boolean;
}

export function ColumnGroupHeader({ text, onClick = () => {}, isOpen = true }: ContentGroupHeaderProps) {
  const { CONTENT_GROUP_HEIGHT } = ROSTER_DAILY_CONFIG;

  return (
    <div
      className="line-height-100 ph-x-large pv-medium cursor-pointer bordered-right bordered-bottom cursor-pointer bg-tertiary"
      style={{ height: CONTENT_GROUP_HEIGHT }}
      onClick={onClick}
    >
      <Icon type={isOpen ? 'down' : 'up'} className="text-color-secondary mr-small" />
      <Text lineHeight={100} size="x-large">
        {text}
      </Text>
    </div>
  );
}
