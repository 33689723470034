import { notification, Upload } from 'antd';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { IBrand } from 'interfaces/account-interfaces';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { ReactCropperElement } from 'react-cropper';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';
import {
  ADJUST_MODAL_BUTTONS,
  VALID_SIZE_IMAGE,
  VALID_SIZE_SQUARE_LOGO,
  LogoShape,
  LogoShapeExtensions,
} from '../../utils/constants';
import CropperElement from './CropperElement';

interface IProps {
  isOpen: boolean;
  brandInfo?: IBrand;
  shape: LogoShape;
  onClose: () => void;
}

const AdjustBrandingLogoModal: React.FC<IProps> = ({ isOpen, onClose, brandInfo, shape }) => {
  const dispatch = useDispatch<IRootDispatch>();

  const cropperRef = useRef<ReactCropperElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>(null);
  const [errorMessageFile, setErrorMessageFile] = useState<string>('');

  const isFullLogo = shape === LogoShape.FULL_LOGO;

  const isDisabled = !imageUrl && (isFullLogo ? !brandInfo?.attachmentUrl : !brandInfo?.squareIcon.attachmentUrl);

  const validateExtension = (file: File) => {
    return new RegExp('(' + ['.png', '.gif', '.jpg', '.bmp', '.jpeg'].join('|').replace(/\./g, '\\.') + ')$').test(
      file.name.toLowerCase(),
    );
  };

  const validateFile = (file: File, isValid: boolean) => {
    if (isValid) {
      setErrorMessageFile('');
      setSelectedFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const validateImageDimension = (image: HTMLImageElement) => {
    if (isFullLogo) {
      if (image.width < VALID_SIZE_IMAGE.MIN_WIDTH) {
        setErrorMessageFile(`Please select a file that is at least ${VALID_SIZE_IMAGE.MIN_WIDTH} pixels in width.`);
      }
      if (image.width > VALID_SIZE_IMAGE.MAX_WIDTH) {
        setErrorMessageFile(`Please select a file that is less than ${VALID_SIZE_IMAGE.MAX_WIDTH} pixels in width.`);
      }
      if (image.height < VALID_SIZE_IMAGE.MIN_HEIGHT) {
        setErrorMessageFile(`Please select a file that is at least ${VALID_SIZE_IMAGE.MIN_HEIGHT} pixels in height.`);
      }
      return (
        image.width < VALID_SIZE_IMAGE.MIN_WIDTH ||
        image.width > VALID_SIZE_IMAGE.MAX_WIDTH ||
        image.height < VALID_SIZE_IMAGE.MIN_HEIGHT
      );
    } else {
      if (image.height < VALID_SIZE_SQUARE_LOGO.MIN_HEIGHT) {
        setErrorMessageFile(
          `Please select a file that is at least ${VALID_SIZE_SQUARE_LOGO.MIN_HEIGHT} pixels in height.`,
        );
      }
      if (image.width < VALID_SIZE_SQUARE_LOGO.MIN_WIDTH) {
        setErrorMessageFile(
          `Please select a file that is at least ${VALID_SIZE_SQUARE_LOGO.MIN_WIDTH} pixels in width.`,
        );
      }
      return image.width < VALID_SIZE_SQUARE_LOGO.MIN_WIDTH || image.height < VALID_SIZE_SQUARE_LOGO.MIN_HEIGHT;
    }
  };

  const checkImageDimensions = (file: File) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.addEventListener('load', () => {
      const isInValid = validateImageDimension(image);
      if (isInValid) {
        validateFile(file, false);
      } else {
        validateFile(file, true);
      }
    });
  };

  const beforeUpload = (file: File) => {
    const isValidType = validateExtension(file);
    const isLimitSize = file.size / 1024 / 1024 < LogoShapeExtensions[shape].maximumSizeOfImage;
    if (!isValidType) {
      setErrorMessageFile('The file you selected is in the wrong format. Please select a PNG, GIF, JPG, or BMP file.');
      validateFile(file, false);
      return;
    }
    if (!isLimitSize) {
      setErrorMessageFile(
        `The file you selected is too large. Please selected a file smaller than ${LogoShapeExtensions[shape].maximumSizeOfImage} MB.`,
      );
      validateFile(file, false);
      return;
    }
    checkImageDimensions(file);
    return false;
  };

  const onSave = async (file: File) => {
    const formData = new FormData();
    if (_.isEmpty(selectedFile)) {
      formData.append('cropFile', file);
    } else {
      formData.append('originFile', selectedFile);
      formData.append('cropFile', file);
    }
    setIsLoading(true);
    const response = await dispatch.accountStore.doUploadCompanyLogo({ formData, shape });
    if (response) {
      _onClose();
    } else {
      notification.error({ message: 'Upload failed! Please try again.' });
    }
    setIsLoading(false);
  };

  const onPreview = (file: File) => {
    const urlInvoice = URL.createObjectURL(file);
    window.open(`/pdf?type=preview-brand-invoice&urlInvoice=${urlInvoice}`, '_blank');
  };

  const onSubmit = async (type: string) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    const splitOriginFile =
      !selectedFile && (isFullLogo ? brandInfo?.originFileName : brandInfo?.squareIcon?.originFileName).split('.');

    cropper.getCroppedCanvas().toBlob(
      async (blob: Blob) => {
        try {
          const newFile = new File([blob], selectedFile ? selectedFile?.name : splitOriginFile[0], {
            type: selectedFile ? selectedFile?.type : 'image/' + splitOriginFile[1],
          });
          if (type === ADJUST_MODAL_BUTTONS.SAVE) {
            onSave(newFile);
          }
          if (type === ADJUST_MODAL_BUTTONS.PREVIEW) {
            onPreview(newFile);
          }
        } catch (error) {
          notification.error({ message: 'Upload failed! Please try again.', description: error });
        }
      },
      'image/png',
      0.6,
    );
  };

  const _onClose = () => {
    setImageUrl('');
    setSelectedFile(null);
    setErrorMessageFile('');
    onClose();
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={_onClose}
      title="Adjust your logo within the frame"
      canCloseOutside={true}
      verticalAlignment="center"
      width="x-large"
      className="pb-small"
    >
      <Paragraph>
        Position your logo in the centre of the frame leaving as little padding as possible around the edges.
      </Paragraph>

      <div className="flex flex-column justify-center align-center">
        <Text size="large" weight="bold">
          Choose a file to upload:
        </Text>

        <CropperElement
          shape={shape}
          cropperRef={cropperRef}
          errorMessage={errorMessageFile}
          imageUrl={imageUrl || (isFullLogo ? brandInfo?.attachmentUrl : brandInfo?.squareIcon?.attachmentUrl)}
          onDropFile={(file: File) => beforeUpload(file)}
        />

        <Text size="x-small" color="secondary" className="mb-medium">
          {isFullLogo
            ? 'PNG, GIF, JPG or BMP. Minimum width 800 pixels. Maximum 10 MB.'
            : 'PNG, GIF, JPG or BMP. Square (1:1 aspect ratio) at least 400 x 400 pixels. Maximum 5 MB.'}
        </Text>

        <div className="flex mb-x2-small">
          <Upload multiple={false} showUploadList={false} beforeUpload={beforeUpload}>
            <HyperlinkButton className="mr-small whitespace-nowrap" fontSize="large" weight="bold" underline>
              {selectedFile || (isFullLogo ? brandInfo?.originFileName : brandInfo?.squareIcon.originFileName)
                ? 'Change file'
                : 'Upload file'}
            </HyperlinkButton>
          </Upload>
          <Text size="large" style={{ color: '#919191' }} className="text-italic">
            {selectedFile
              ? selectedFile.name
              : (isFullLogo ? brandInfo?.originFileName : brandInfo?.squareIcon?.originFileName) || 'No file selected'}
          </Text>
        </div>
      </div>

      <ActionModalFooter className="mt-medium mb-x2-small">
        {isFullLogo ? (
          <SecondaryButton
            className="mr-medium"
            size="large"
            disabled={isDisabled}
            onClick={() => onSubmit(ADJUST_MODAL_BUTTONS.PREVIEW)}
          >
            Preview an invoice
          </SecondaryButton>
        ) : (
          <SecondaryButton className="mr-medium" size="large" onClick={_onClose}>
            Cancel
          </SecondaryButton>
        )}
        <PrimaryButton
          size="large"
          loading={isLoading}
          disabled={isDisabled}
          onClick={() => onSubmit(ADJUST_MODAL_BUTTONS.SAVE)}
        >
          Save
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};

export default AdjustBrandingLogoModal;
