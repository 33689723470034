import React, { Component } from 'react';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { GroupServiceClassification, ServiceStatus } from 'utilities/enum-utils';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { notification, Radio, Icon } from 'antd';
import ArchiveGroupServiceModal from 'views/group-services/service-details/components/ArchiveGroupServiceModal';
import ServiceStatusTag from 'common-components/tags/ServiceStatusTag';
import GroupService from 'assets/banner/group-service.png';

interface IGroupServiceHeaderProps {
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  selectedServiceDetail: typeof state.servicesStore.selectedServiceDetail;
  doPublishGroupService: typeof dispatch.groupServiceStore.doPublishGroupService;
  doUnpublishGroupService: typeof dispatch.groupServiceStore.doUnpublishGroupService;
}

interface IGroupServiceHeaderState {
  unpublishModalOpen: boolean;
  publishModalOpen: boolean;
  publishStep: number;
  archiveModalOpen: boolean;
  isLoading: boolean;
  marketplaceType: string;
  isNoMarketplaceSelectedError: boolean;
}

class GroupServiceHeader extends Component<IGroupServiceHeaderProps, IGroupServiceHeaderState> {
  state = {
    unpublishModalOpen: false,
    publishModalOpen: false,
    publishStep: 1,
    archiveModalOpen: false,
    isLoading: false,
    marketplaceType: null,
    isNoMarketplaceSelectedError: false,
  };

  private _openArchiveModal = () => {
    this.setState({ archiveModalOpen: true });
  };
  private _closeArchiveModal = () => {
    this.setState({ archiveModalOpen: false });
  };

  private _openUnpublishModal = () => {
    this.setState({ unpublishModalOpen: true });
  };
  private _closeUnpublishModal = () => {
    this.setState({ unpublishModalOpen: false });
  };

  private _openPublishModal = () => {
    this.setState({ publishModalOpen: true });
  };
  private _closePublishModal = () => {
    this.setState({ publishModalOpen: false, publishStep: 1 });
  };

  private _updateIsMarketplace = (marketplaceType) => {
    this.setState({ marketplaceType, isNoMarketplaceSelectedError: false });
  };

  private _unpublish = () => {
    try {
      this.setState({ isLoading: true });
      this.props.doUnpublishGroupService({ serviceId: this.props.selectedGroupService.serviceId });
      this._closeUnpublishModal();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
    }
    this.setState({ isLoading: false });
  };

  private _publish = () => {
    const { marketplaceType } = this.state;

    if (marketplaceType === null) {
      this.setState({ isNoMarketplaceSelectedError: true });
    } else {
      try {
        this.setState({ isLoading: true });
        this.props.doPublishGroupService({
          serviceId: this.props.selectedGroupService.serviceId,
          isPublicMarketplace: this.state.marketplaceType === 'PUBLIC_MARKETPLACE',
        });
        // this._closePublishModal();
        this.setState({ publishStep: 2 });
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
      this.setState({ isLoading: false });
    }
  };

  private _getActionButtons = () => {
    const { selectedGroupService } = this.props;
    if (selectedGroupService) {
      if (
        selectedGroupService.status === ServiceStatus.DRAFT ||
        selectedGroupService.status === ServiceStatus.UNPUBLISHED
      ) {
        return (
          <>
            <PrimaryButton size='large' className='mr-medium' onClick={this._openPublishModal}>
              Publish
            </PrimaryButton>
            <SecondaryButton size='large' className='mr-medium' color='red' onClick={this._openArchiveModal}>
              Archive
            </SecondaryButton>
          </>
        );
      } else if (selectedGroupService.status === ServiceStatus.PUBLISHED) {
        return (
          <>
            <SecondaryButton size='large' onClick={this._openUnpublishModal}>
              Unpublish
            </SecondaryButton>
          </>
        );
      }
    }
  };

  render = () => {
    const { selectedGroupService, selectedServiceDetail } = this.props;
    const { marketplaceType, publishStep } = this.state;
    const isInternalMarketPlace = this.state.marketplaceType === 'INTERNAL_MARKETPLACE';

    return (
      <>
        {/* Service header */}

        <div className='flex-row justify-center height-full'>
          <div className='width-full' style={{ maxWidth: '1200px' }}>
            <div className='bg-white pv-large'>
              <div className='mb-small'>
                <SubTitle color='tertiary' containerClassName='line-height-100'>
                  Service details
                </SubTitle>
              </div>

              <div className='flex-row align-center justify-between'>
                {selectedGroupService && (
                  <>
                    <div className='flex-row align-center'>
                      <div className='pr-medium'>
                        <img
                          style={{ width: '100px', height: '100px' }}
                          src={selectedGroupService && selectedGroupService.bannerUrl}
                          alt='Service banner'
                          className='rounded-big'
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = GroupService;
                          }}
                        />
                      </div>
                      <div className='pl-medium'>
                        <div className='flex-row align-center line-height-120 mb-small'>
                          {' '}
                          <Title level={3} className='mv-none mr-small' weight='regular' lineHeight={120}>
                            {selectedGroupService.serviceName}
                          </Title>
                          <ServiceStatusTag status={selectedGroupService.status} size='small' />
                        </div>

                        <div style={{ width: '720px' }}>
                          <Paragraph lineHeight={120} color='secondary' ellipsis={{ rows: 1 }} className='mv-none'>
                            {selectedServiceDetail && selectedServiceDetail.serviceDescription}
                          </Paragraph>
                        </div>

                        <div className='flex-row align-center mt-small'>
                          <div className='flex-row align-center mr-medium'>
                            <Icon
                              type='database'
                              className='mr-x-small text-color-green-light'
                              style={{ fontSize: '16px' }}
                            />
                            <Text color='green-light' size='large'>
                              {selectedGroupService.serviceDepartmentName}
                            </Text>
                          </div>

                          <div className='flex-row align-center'>
                            <Icon type='appstore' className='mr-x-small text-color-gold' style={{ fontSize: '16px' }} />
                            <Text color='gold' size='large'>
                              Group service /{' '}
                              {selectedGroupService.groupServiceClassification === GroupServiceClassification.COMMUNITY
                                ? 'Community'
                                : 'Centre-based'}
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='text-align-right'>{this._getActionButtons()}</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        <ArchiveGroupServiceModal
          isOpen={this.state.archiveModalOpen}
          onClose={this._closeArchiveModal}
          selectedGroupService={selectedGroupService}
        />
        <ActionModal
          isOpen={this.state.unpublishModalOpen}
          onClose={this._closeUnpublishModal}
          title='Unpublish service'
          showCloseButton={true}
        >
          <Paragraph>Are you sure you want to unpublish this service?</Paragraph>
          <Paragraph>
            This service will no longer be available in the marketplace but you can still create and manage your
            sessions through the workspace.
          </Paragraph>
          <ActionModalFooter>
            <GhostButton className='mr-medium' size='large' onClick={this._closeUnpublishModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size='large' color='red' loading={this.state.isLoading} onClick={this._unpublish}>
              Unpublish
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.state.publishModalOpen}
          onClose={this._closePublishModal}
          title={publishStep === 1 ? 'Publish service' : 'Service successfully published'}
          showCloseButton={true}
        >
          {publishStep === 1 && (
            <>
              <Paragraph>Please select how you would like this service to be published:</Paragraph>
              <Radio.Group value={marketplaceType}>
                <Radio value='INTERNAL_MARKETPLACE' onClick={() => this._updateIsMarketplace('INTERNAL_MARKETPLACE')}>
                  Internal marketplace (Only your current customers can view)
                </Radio>
                {/* <Radio value={'PUBLIC_MARKETPLACE'} onClick={() => this._updateIsMarketplace('PUBLIC_MARKETPLACE')}>
                  Open marketplace (Anyone can view)
                </Radio> */}
              </Radio.Group>
              {this.state.isNoMarketplaceSelectedError && <Text color='red-dark'>Please select an option.</Text>}
              <ActionModalFooter>
                <GhostButton className='mr-medium' size='large' onClick={this._closePublishModal}>
                  Cancel
                </GhostButton>
                <PrimaryButton size='large' loading={this.state.isLoading} onClick={this._publish}>
                  Publish
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}

          {publishStep === 2 && (
            <>
              <Text>
                You have published this service to {isInternalMarketPlace && 'your '}
                <b>{isInternalMarketPlace ? 'internal marketplace.' : 'public marketplace.'}</b>
              </Text>
              <ActionModalFooter>
                <PrimaryButton size='large' loading={this.state.isLoading} onClick={this._closePublishModal}>
                  Close
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}
        </ActionModal>
      </>
    );
  };
}

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  selectedServiceDetail: state.servicesStore.selectedServiceDetail,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doPublishGroupService: dispatch.groupServiceStore.doPublishGroupService,
  doUnpublishGroupService: dispatch.groupServiceStore.doUnpublishGroupService,
});

export default connect(mapState, mapDispatch)(GroupServiceHeader);
