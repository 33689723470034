import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Icon, notification } from 'antd';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { TimezoneIndicator, TimezoneInput } from 'common-components/timezone';
import { timeZone } from 'interfaces/timezone-type';

interface IEditTimezoneModalProps {
  isOpen: any;
  onClose: () => void;
  doEditCompanyTimezone: typeof dispatch.companyStore.doEditCompanyTimezone;
  currentTimezone: timeZone;
}

interface IEditTimezoneModalState {
  title: any;
  isCreated: boolean;
  timezone: string;
}

class EditTimezoneModal extends Component<IEditTimezoneModalProps, IEditTimezoneModalState> {
  state = { title: 'Change default timezone', isCreated: false, timezone: this.props.currentTimezone };

  private _onCloseModal = () => {
    this.setState({ title: 'Change default timezone', isCreated: false });
    this.props.onClose();
  };

  private _onChangeTimezone = (event) => {
    this.setState({ timezone: event });
  };

  private _editTimezone = async () => {
    try {
      await this.props.doEditCompanyTimezone({ timezone: this.state.timezone });
      this.setState({
        title: (
          <>
            Default timezone successfully changed
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        isCreated: true,
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  componentDidUpdate(
    prevProps: Readonly<IEditTimezoneModalProps>,
    prevState: Readonly<IEditTimezoneModalState>,
    snapshot?: any,
  ) {
    if (prevProps.currentTimezone !== this.props.currentTimezone) {
      this.setState({ timezone: this.props.currentTimezone });
    }
  }

  render() {
    const { isOpen } = this.props;
    const { timezone } = this.state;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isCreated ? (
          <>
            <div className="mv-medium anim-slide-left">
              <SubTitle>Company default timezone</SubTitle>
              <Text size={'x-large'}>{<TimezoneIndicator timezone={timezone} />}</Text>
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Done
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <Paragraph>Select the default timezone for your business</Paragraph>
                <div className={'mt-large'}>
                  <SubTitle>Select timezone</SubTitle>
                  <TimezoneInput size={'large'} value={timezone} usePortal={false} onChange={this._onChangeTimezone} />
                </div>
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._editTimezone}>
                  Change timezone
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditCompanyTimezone: dispatch.companyStore.doEditCompanyTimezone,
});

export default connect(null, mapDispatch)(EditTimezoneModal);
