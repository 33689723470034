import React from 'react';

import { Image } from '@good/ui/core';
import { StaticValue } from './static-value';

type StaticImageValueProps = {
  value: string[];
  defaultImageUrl?: string;
  emptyValue?: string;
  alt: string;
};

export const StaticImageValue = ({ value, alt, emptyValue, defaultImageUrl }: StaticImageValueProps) => {
  const hasValue = value.length > 0;

  if (!hasValue && emptyValue) {
    return <StaticValue emptyValue={emptyValue} />;
  }

  const imageSrc = hasValue ? value[0] : defaultImageUrl;

  return <Image h={208} w={208} src={imageSrc} className='rounded-2xl' alt={alt} />;
};
