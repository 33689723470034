import React, { Component } from 'react';
import { Button, Row } from 'antd';
import { RowProps } from 'antd/lib/row';

interface IGridRowProps extends RowProps {
  bordered?: boolean;
  style?: object;
  containerClassName?: string;
  actionButtons?: Array<any>;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  gutter?: number;
  alignCenter?: boolean;
  [prop: string]: any;
  paddingLeft?: boolean;
}

class GridRow extends Component<IGridRowProps> {
  static defaultProps = {
    containerClassName: '',
  };

  state = {
    isHovered: false,
  };

  toggleHover = () => this.setState({ isHovered: !this.state.isHovered });

  render() {
    const {
      actionButtons,
      containerClassName,
      onClick,
      bordered = true,
      gutter = 32,
      alignCenter = true,
      paddingLeft = true,
      style,
      prop,
    } = this.props;
    let rowContainerClassName = 'position-relative pv-medium hover-bg-quaternary';

    if (onClick) {
      rowContainerClassName += ' cursor-pointer';
    }
    if (bordered) {
      rowContainerClassName += ' bordered-bottom';
    }

    let rowClassName = paddingLeft ? 'ph-medium' : 'pr-medium';

    if (alignCenter) {
      rowClassName += ' align-center';
    }

    return (
      <div
        className={`${rowContainerClassName} ${containerClassName}`}
        onClick={onClick ? onClick : null}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
      >
        <Row gutter={gutter} className={rowClassName} {...prop} type="flex" style={style}>
          {this.props.children}
          {this.state.isHovered && (
            <div
              className="position-absolute text-color-secondary mr-x-large"
              style={{ top: 'calc(50% - 16px)', right: 0 }}
            >
              <Button.Group className="anim-fade-in-fast">{actionButtons}</Button.Group>
            </div>
          )}
        </Row>
      </div>
    );
  }
}

export default GridRow;
