import _ from 'lodash';
import createRematchPersist from '@rematch/persist';
import { createTransform } from 'redux-persist';
import { init } from '@rematch/core';

import accountStore from 'stores/rematch/models/account-store';
import authStore from 'stores/rematch/models/auth-store';
import billingsStore from 'stores/rematch/models/billings-store';
import bookingsStore from 'stores/rematch/models/bookings-store';
import channelsStore from 'stores/rematch/models/channels-store';
import companyStore from 'stores/rematch/models/company-store';
import customersStore from 'stores/rematch/models/customers-store';
import formBuilderStore from 'stores/rematch/models/form-builder-store';
import groupBookingsStore from 'stores/rematch/models/group-bookings-store';
import groupServiceStore from 'stores/rematch/models/group-service-store';
import messagesStore from 'stores/rematch/models/messages-store';
import navigationStore from 'stores/rematch/models/navigation-store';
import notificationsStore from 'stores/rematch/models/notifications-store';
import profileStore from 'stores/rematch/models/profile-store';
import rosterStore from 'stores/rematch/models/roster-store';
import servicesStore from 'stores/rematch/models/services-store';
import subscriptionStore from 'stores/rematch/models/subscription-store';
import teamStore from 'stores/rematch/models/team-store';
import teamV2Store from 'stores/rematch/models/teamV2-store';
import workflowStore from 'stores/rematch/models/workflow-store';

import type { PartialDeep } from 'type-fest';
import type { RematchRootState } from '@rematch/core';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

// The following intercepts the persist hydrated state, and transforms it to a new state.
// Why? Because isAppLoaded is persisted as true; it's cleaner to reset it to false every time it's re-hydrated.
const SetTransform = createTransform(
  null,
  (outbound) => {
    type PartiallyUnknownState = { isAppLoaded: boolean; [key: string]: unknown };

    // clone state and reset app loaded property to false.
    const newState = _.cloneDeep(outbound) as PartiallyUnknownState;

    newState.isAppLoaded = false;

    // We can add any state that we wish to reset here, eg
    // newState.currentUser = null;

    return newState;
  },
  { whitelist: ['authStore'] },
);

// Persist Plugin for Rematch
const persistPlugin = createRematchPersist({
  // storage: AsyncStorage,
  // whitelist: ['userStore', 'authStore']
  whitelist: ['authStore'],
  transforms: [SetTransform],
  // whitelist: ['authStore', 'servicesStore']
  // blacklist: ['non-auth'],{}
  // throttle: 5000,
  // version: 1,
});

export const models = {
  accountStore,
  authStore,
  billingsStore,
  bookingsStore,
  channelsStore,
  companyStore,
  customersStore,
  formBuilderStore,
  groupBookingsStore,
  groupServiceStore,
  messagesStore,
  navigationStore,
  notificationsStore,
  profileStore,
  rosterStore,
  servicesStore,
  subscriptionStore,
  teamStore,
  teamV2Store,
  workflowStore,
};

const rootStore = init({
  models,
  plugins: [persistPlugin],
});

export default rootStore;

export type IStore = typeof rootStore;
export type IRootDispatch = typeof rootStore.dispatch;
export type IRootState = RematchRootState<typeof models>;

export let dispatch: IRootDispatch;
export let state: IRootState;

export const useAppDispatch: () => IRootDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;

const genericStoreFactory = <T extends { state: Record<string, unknown> }>(
  store: T,
  overrides: PartialDeep<T['state']>,
) => {
  return {
    ...store,
    state: {
      ...store.state,
      ...overrides,
    },
  };
};

/**
 * @name storeFactory
 *
 * @description
 * Seed stores with data for tests
 *
 * @example
 * const portalUser = { ... }
 * const store = storeFactory({ authStore: { portalUser } });
 * renderWithProviders(<Test />, { store })
 */
export const storeFactory = (overrides: PartialDeep<IRootState>) => {
  return init({
    models: {
      // Extend with more stores as needed
      authStore: genericStoreFactory<typeof models.authStore>(models.authStore, overrides.authStore ?? {}),
      teamStore: genericStoreFactory<typeof models.teamStore>(models.teamStore, overrides.teamStore ?? {}),
      teamV2Store: genericStoreFactory<typeof models.teamV2Store>(models.teamV2Store, overrides.teamV2Store ?? {}),
      companyStore: genericStoreFactory<typeof models.companyStore>(models.companyStore, overrides.companyStore ?? {}),
      accountStore: genericStoreFactory<typeof models.accountStore>(models.accountStore, overrides.accountStore ?? {}),
      servicesStore: genericStoreFactory<typeof models.servicesStore>(
        models.servicesStore,
        overrides.servicesStore ?? {},
      ),
    },
  });
};
