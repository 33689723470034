import { useTimelineContext } from './timeline-provider';
import { Cell, HeaderCell, LeftStickyCell, TopLeftStickyCell } from './timeline-cells';
import { format } from 'date-fns';
import { isAfter, isBefore } from 'date-fns';
import React from 'react';
import { Text } from '@good/components';
import { Block, SingleRow } from 'design-components/timeline';

const orderBlocksByStartDate = (blocks: Block[]) =>
  blocks.sort((a, b) => {
    return a.start.getTime() - b.start.getTime();
  });

export const TimelineHeaderRow = () => {
  const { dateRange, unitsInRange, calendarUnit, differenceInUnit, searchBar } = useTimelineContext();

  const isRangeLongerThanOneDay = differenceInUnit(dateRange.end, dateRange.start) > 1;
  const showHours = calendarUnit === 'hours';

  return (
    <div className="flex border-gray-light-1 bg-white">
      <TopLeftStickyCell>{searchBar}</TopLeftStickyCell>
      {unitsInRange.map((day) => (
        <HeaderCell key={day.toISOString()}>
          <Text className="flex gap-2 text-gray-dark-1" asChild>
            <p>
              {isRangeLongerThanOneDay && (
                <>
                  <span className="font-semibold">{format(day, 'eeee')}</span>
                  <span>{format(day, 'dd')}</span>
                </>
              )}
              {showHours && <span>{format(day, 'haaa')}</span>}
            </p>
          </Text>
        </HeaderCell>
      ))}
    </div>
  );
};

export const TimelineSingleRow = ({ row, isLastElement }: { row: SingleRow; isLastElement?: boolean }) => {
  const { unitsInRange, cellHeight, differenceInUnit, getPositionFromLeftBasedOnDate, isOutOfRange, dateRange } =
    useTimelineContext();

  const splitBlocksByOverlappingDate = (blocks: Block[]): Block[][] => {
    const { group, hasOverlap } = blocks.reduce(
      (acc, curr) => {
        const lastBlock = acc.group[acc.group.length - 1];

        if (!lastBlock) {
          return { group: [...acc.group, curr], hasOverlap: [] };
        }

        if (differenceInUnit(lastBlock.end, curr.start) > 0) {
          return { ...acc, hasOverlap: [...acc.hasOverlap, curr] };
        }

        return { group: [...acc.group, curr], hasOverlap: [...acc.hasOverlap] };
      },
      { group: [], hasOverlap: [] } as { group: Block[]; hasOverlap: Block[] },
    );

    if (hasOverlap.length === 0) {
      return [group];
    }

    return [group, ...splitBlocksByOverlappingDate(hasOverlap)];
  };

  const orderedBlocks = orderBlocksByStartDate(row.blocks ?? []);
  const filteredBlocks = orderedBlocks.filter((block) => !isOutOfRange(block.start) || !isOutOfRange(block.end));
  const trimmedBlocks = filteredBlocks.map((block) => {
    const start = isBefore(block.start, dateRange.start) ? dateRange.start : block.start;
    const end = isAfter(block.end, dateRange.end) ? dateRange.end : block.end;

    return {
      ...block,
      start,
      end,
    };
  });
  const blockGroups = splitBlocksByOverlappingDate(trimmedBlocks);

  const rowHeight = blockGroups.length > 1 ? `${blockGroups.length * cellHeight}rem` : `${cellHeight}rem`;
  return (
    <div className="relative flex bg-gray-light-3" style={{ height: rowHeight }}>
      <LeftStickyCell isLastElement={isLastElement}>{row.label}</LeftStickyCell>
      {unitsInRange.map((_, index) => (
        <Cell key={`cell-${index}`}></Cell>
      ))}
      {blockGroups.map((group, index) => {
        return group.map((block) => {
          const startPosition = getPositionFromLeftBasedOnDate(block.start);
          const width = getPositionFromLeftBasedOnDate(block.end) - startPosition;

          const { children } = block;

          return (
            <div
              key={`block-${index}`}
              className="absolute z-10 p-2"
              style={{
                top: `${index * cellHeight}rem`,
                left: `${startPosition}rem`,
                width: `${width}rem`,
                height: `${cellHeight}rem`,
              }}
            >
              {children}
            </div>
          );
        });
      })}
    </div>
  );
};
