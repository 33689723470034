import React from 'react';
import { Combobox } from '@good/components';
import { useFormContext } from 'react-hook-form';

type SupportTypeProps = {
  rowPath: string;
};

export const SupportTypeCombobox = (props: SupportTypeProps) => {
  const { rowPath } = props;
  const { getFieldState, register } = useFormContext();

  const supportTypes = ['Sleepover', 'High intensity', 'Active overnight', 'Regular'];

  return (
    <Combobox
      className='border-gray'
      data-testid={`${rowPath}`}
      error={getFieldState(rowPath).error?.message}
      {...register(rowPath)}
    >
      {supportTypes.map((supportType, supportTypeIndex) => (
        <option key={supportTypeIndex} value={supportType}>
          {supportType}
        </option>
      ))}
    </Combobox>
  );
};
