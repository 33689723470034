import React, { forwardRef } from 'react';

import { Icon } from '../icon';

import type { IconProps } from '../icon';

export const Pen = forwardRef<SVGSVGElement, IconProps>(function Pen(props, ref) {
  return (
    <Icon {...props} ref={ref} viewBox="0 0 16 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7006 2.25294C13.3033 1.89177 12.6966 1.89176 12.2993 2.25292C12.2837 2.26708 12.2634 2.28716 12.1919 2.35861L11.9361 2.61436C11.9159 3.51116 12.6553 4.25069 13.5521 4.23058L13.808 3.97469C13.8794 3.90324 13.8995 3.88291 13.9137 3.86732C14.2748 3.47003 14.2748 2.86331 13.9137 2.466C13.8995 2.45042 13.8794 2.43009 13.808 2.35864C13.7365 2.28719 13.7162 2.2671 13.7006 2.25294ZM12.493 5.28964C11.7553 5.00157 11.165 4.41129 10.877 3.67349L6.4142 8.13624C5.74189 8.80854 5.4914 9.06638 5.31961 9.36979C5.14782 9.6732 5.0556 10.0207 4.825 10.943L4.69217 11.4744L5.22351 11.3415C6.1459 11.1109 6.49334 11.0187 6.79675 10.8469C7.10015 10.6751 7.358 10.4247 8.03029 9.75236L12.493 5.28964ZM11.4585 1.32795C12.3326 0.533408 13.6674 0.533427 14.5414 1.32799C14.5821 1.36496 14.6253 1.40817 14.6814 1.46428L14.6814 1.46429L14.6919 1.47477L14.7023 1.48521L14.7023 1.48522C14.7585 1.54134 14.8017 1.58455 14.8387 1.62522C15.6332 2.4993 15.6331 3.83407 14.8386 4.70812C14.8017 4.74876 14.7585 4.79193 14.7025 4.84796L14.7024 4.84804L14.7023 4.84814L14.6918 4.85857L8.91418 10.6362L8.8529 10.6975C8.26285 11.2878 7.88177 11.6691 7.41262 11.9347C6.94348 12.2003 6.42051 12.3309 5.61076 12.5332L5.61076 12.5332L5.52668 12.5542L3.98477 12.9397C3.77179 12.9929 3.54648 12.9305 3.39125 12.7753C3.23601 12.6201 3.1736 12.3947 3.22685 12.1818L3.61232 10.6399L3.63333 10.5558C3.8356 9.74604 3.96623 9.22306 4.23186 8.75391C4.49749 8.28477 4.87873 7.90369 5.46902 7.31363L5.46903 7.31362L5.53032 7.25235L11.308 1.47472L11.3185 1.46425C11.3746 1.40813 11.4178 1.36492 11.4585 1.32795ZM0.708374 16.5C0.708374 16.1548 0.988196 15.875 1.33337 15.875H14.6667C15.0119 15.875 15.2917 16.1548 15.2917 16.5C15.2917 16.8452 15.0119 17.125 14.6667 17.125H1.33337C0.988196 17.125 0.708374 16.8452 0.708374 16.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
