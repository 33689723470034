import moment from 'moment';
import { FilterType, PaymentStatus, ServiceType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';

// all possibilities of filters:

// status: [x,y,z]
// paymentStatus: [x,y,z]
// workerStatus: [x,y,z]
// startDate: [startDate, endDate]
// serviceName: ''
// sort: []
// sort filter format is [col,asc] eg ['desc','asc']

// this serves as the filter configurations. Should expand this later to a proper interface
// to add a filter item, add here as well as BookingListingNavigation.

interface IBookingListFilterConfigs {
  [key: string]: {
    key: string;
    title: string;
    description: string;
    filters?: {
      filter: FilterType | 'sort';
      values: any[];
      selectionLabel?: string;
    }[];
    customFields?: {
      header: string;
      field: string;
    }[];
  };
}

const BOOKINGLIST_FILTERCONFIGS: IBookingListFilterConfigs = {
  NEW_REQUESTS: {
    key: 'NEW_REQUESTS',
    title: 'New requests',
    description: 'Booking requests from customer.',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName,
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName,
      },
      {
        filter: FilterType.WORKER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName,
      },
      {
        filter: FilterType.BOOKING_STATUS,
        values: ['PENDING'],
        selectionLabel: 'Pending',
      },
      {
        filter: FilterType.SERVICE_TYPE,
        values: [ServiceType.INDIVIDUAL],
        selectionLabel: 'Support services',
      },
      { filter: 'sort', values: [['startDateTime', 'asc']] },
    ],
    customFields: [
      {
        header: 'Received Date',
        field: 'RECEIVED_DATE',
      },
      {
        header: 'Booking Date',
        field: 'BOOKING_DATE',
      },
      {
        header: 'Client Name',
        field: 'CUSTOMER',
      },
      {
        header: 'Service',
        field: 'SERVICE',
      },
      {
        header: 'Team member',
        field: 'WORKER',
      },
      {
        header: 'Forecast',
        field: 'TOTAL',
      },
      {
        header: '',
        field: 'ALERTS',
      },
    ],
  },

  NO_WORKER_CONFIRMED: {
    key: 'NO_WORKER_CONFIRMED',
    title: 'No worker confirmed',
    description: "Bookings that don't have a confirmed worker.",
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName,
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName,
      },
      {
        filter: FilterType.WORKER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName,
      },
      {
        filter: FilterType.SHIFT_SLOT_STATUS,
        values: ['UNASSIGNED', 'PENDING'],
        selectionLabel: CommonUtils.getFilterText(FilterType.SHIFT_SLOT_STATUS, ['UNASSIGNED', 'PENDING']),
      },
      {
        filter: FilterType.BOOKING_STATUS,
        values: ['ACCEPTED', 'CONFIRMED', 'PENDING'],
        selectionLabel: CommonUtils.getFilterText(FilterType.BOOKING_STATUS, ['ACCEPTED', 'CONFIRMED', 'PENDING']),
      },
      {
        filter: FilterType.SERVICE_TYPE,
        values: [ServiceType.INDIVIDUAL],
        selectionLabel: 'Support services',
      },
      { filter: 'sort', values: [['startDateTime', 'asc']] },
    ],
  },

  OVERDUE: {
    key: 'OVERDUE',
    title: 'Overdue',
    description: 'Booking scheduled to start before today but no action yet.',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [moment().add(-1, 'year'), moment().startOf('day')],
        selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
          moment().add(-1, 'year'),
          moment().startOf('day'),
        ]),
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName,
      },
      {
        filter: FilterType.WORKER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName,
      },
      {
        filter: FilterType.BOOKING_STATUS,
        values: ['PENDING', 'ACCEPTED', 'CONFIRMED'],
        selectionLabel: CommonUtils.getFilterText(FilterType.BOOKING_STATUS, ['PENDING', 'ACCEPTED', 'CONFIRMED']),
      },
      {
        filter: FilterType.SERVICE_TYPE,
        values: [ServiceType.INDIVIDUAL],
        selectionLabel: 'Support services',
      },
      { filter: 'sort', values: [['startDateTime', 'asc']] },
    ],
  },

  REQUIRED_APPROVAL: {
    key: 'REQUIRED_APPROVAL',
    title: 'Requires approval',
    description: 'Bookings that are complete in some way and require you to make a payment decision.',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName,
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName,
      },
      {
        filter: FilterType.WORKER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName,
      },
      {
        filter: FilterType.PAYMENT_STATUS,
        values: ['REQUIRES_APPROVAL'],
        selectionLabel: 'Requires approval',
      },
      {
        filter: FilterType.SERVICE_TYPE,
        values: [ServiceType.INDIVIDUAL],
        selectionLabel: 'Support services',
      },
      { filter: 'sort', values: [['startDateTime', 'asc']] },
    ],
  },

  REMOVE_TEAM_MEMBER: {
    key: 'REMOVE_TEAM_MEMBER',
    title: 'Remove team members',
    description: 'Bookings that can remove assigned team members',
    customFields: [
      {
        header: 'Bookings',
        field: 'BOOKINGS',
      },
      {
        header: 'Customer',
        field: 'CUSTOMER',
      },
      {
        header: 'Team member',
        field: 'WORKER',
      },
    ],
  },

  ALL: {
    key: 'ALL',
    title: 'All bookings',
    description: 'Shows all bookings.',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName,
      },
      { filter: 'sort', values: [['startDateTime', 'asc']] },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName,
      },
      {
        filter: FilterType.WORKER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName,
      },
      {
        filter: FilterType.SHIFT_SLOT_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.SHIFT_SLOT_STATUS).fullSelectionName,
      },
      {
        filter: FilterType.SERVICE_TYPE,
        values: [ServiceType.INDIVIDUAL],
        selectionLabel: 'Support services',
      },
    ],
  },
};

export { BOOKINGLIST_FILTERCONFIGS };
