import React from 'react';
import { Header, Heading, Text, Label, Link } from '@good/components';
import { ArrowRight } from '@good/icons';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

type ServiceDetailsProps = { name: string; status: string; startDate: Date; endDate: Date };

export const ServiceDetailsCard = (props: ServiceDetailsProps) => {
  const { t } = useTranslation('', { keyPrefix: 'serviceAgreements.service.serviceDetailsSummary' });

  return (
    <div className="w-[40%] flex-none space-y-4 pb-8 pl-6 pt-8 md:w-[38%] lg:pl-16">
      <Header size="sm">
        <Text className="font-semibold">{t('header')}</Text>
      </Header>
      <div className="flex flex-col space-y-4">
        <Header size="md" className="pt-4">
          <Heading>{props.name}</Heading>
        </Header>
        <Label size="sm" tone="canary">
          {t(`statuses.${props.status}`)}
        </Label>
        <div className="flex flex-col space-y-1">
          <Text size="md">{t('effectiveAsOf')}</Text>
          <Text size="md" className="font-semibold">
            {format(props.startDate, 'dd MMM yyyy')} - {format(props.endDate, 'dd MMM yyyy')}
          </Text>
        </div>
        <Link emphasis="no-underline" className="font-bold">
          <a href="http://www.google.com">
            Edit Details <ArrowRight />
          </a>
        </Link>
      </div>
    </div>
  );
};
