import React, { Component } from 'react';
import { Col, Form, Icon, Row, Avatar, Dropdown, notification, Menu } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';

import { IAddCustomerDetailWizard } from 'interfaces/customer-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { CustomerDetailsTabKey, FormType } from 'utilities/enum-utils';

interface IAddCustomerStepSuccessProps extends FormComponentProps {
  addNewCustomer: () => void;
  goToCustomer: (userId: string, selectedTab?: CustomerDetailsTabKey) => void;
  selectedCustomerUserId: typeof state.customersStore.selectedCustomerUserId;
  addCustomerDetailWizard: typeof state.customersStore.addCustomerDetailWizard;
  doFetchFormLiteList: typeof dispatch.formBuilderStore.doFetchFormLiteList;
}

interface IAddCustomerStepSuccessState {
  customerDetails: IAddCustomerDetailWizard;
  hasIntakeForm: boolean;
  isLoading: boolean;
}

class AddCustomerStepSuccess extends Component<IAddCustomerStepSuccessProps, IAddCustomerStepSuccessState> {
  state = {
    customerDetails: this.props.addCustomerDetailWizard,
    hasIntakeForm: true,
    isLoading: false,
  };

  private handleSubmitButton = async (selectedTab?: CustomerDetailsTabKey) => {
    const { goToCustomer, selectedCustomerUserId } = this.props;

    goToCustomer(selectedCustomerUserId, selectedTab);
  };

  async componentDidMount() {
    try {
      const { doFetchFormLiteList } = this.props;

      this.setState({ isLoading: true });

      const intakeFormList = await doFetchFormLiteList({
        search: '',
        formType: [FormType.INTAKE],
      });

      this.setState({ hasIntakeForm: Boolean(intakeFormList.length > 0) });
    } catch (error) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: error.meta.message });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { addNewCustomer } = this.props;
    const { customerDetails, isLoading, hasIntakeForm } = this.state;

    return (
      <>
        <div className="anim-slide-left pt-x3-large">
          <Row className="ph-x3-large">
            <Col span={6} style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div>
                <Paragraph size="x3-large" className="mb-medium" weight="bold">
                  Done
                </Paragraph>
                <Paragraph>You have successfully added a customer!</Paragraph>
              </div>
            </Col>
            <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
                <div className="mb-large ">
                  <Text className="text-color-blue-action mb-large text-size-x2-large flex-row align-center">
                    <Icon className="mr-12 text-size-x4-large" type={'check-circle'} theme={'filled'} />
                    Customer successfully added
                  </Text>
                </div>
                <div className="mb-large">
                  <SubTitle>Customer</SubTitle>
                  <div className="flex-row align-center pt-small">
                    <Avatar size="large" icon="user" className="mr-medium" src={'TBD'} />
                    <Text weight="bold">{customerDetails.firstName + ' ' + customerDetails.lastName}</Text>
                  </div>
                </div>

                {customerDetails.hasGuardians ? (
                  <div className="mb-x5-large">
                    <SubTitle>Legal Guardian</SubTitle>
                    {_.map(customerDetails.guardians, (guardian) => {
                      return (
                        <div className="flex-row align-center pt-small">
                          <Avatar size="large" icon="user" className="mr-medium" src={guardian.attachmentUrl} />
                          <Text weight="bold">{guardian.firstName + ' ' + guardian.lastName}</Text>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>

              <div className="pv-medium width-full" style={{ position: 'sticky', bottom: 0 }}>
                <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                  <Col className="bg-transparent" span={24}>
                    <div className="text-align-right pv-large">
                      <SecondaryButton size="large" className="mr-medium" onClick={addNewCustomer}>
                        Add another customer
                      </SecondaryButton>

                      <Dropdown.Button
                        onClick={() => this.handleSubmitButton()}
                        overlay={
                          <Menu>
                            <Menu.Item onClick={() => this.handleSubmitButton(CustomerDetailsTabKey.FORMS)}>
                              Start intake form
                            </Menu.Item>
                          </Menu>
                        }
                        icon={<Icon type={isLoading ? 'loading' : 'down'} />}
                        disabled={isLoading}
                        type="primary"
                        size="large"
                        trigger={['click']}
                      >
                        View profile
                      </Dropdown.Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  addCustomerDetailWizard: state.customersStore.addCustomerDetailWizard,
  selectedCustomerUserId: state.customersStore.selectedCustomerUserId,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddCustomerDetailWizard: dispatch.customersStore.setAddCustomerDetailWizard,
  doFetchFormLiteList: dispatch.formBuilderStore.doFetchFormLiteList,
});

export default connect(mapState, mapDispatch)(Form.create<IAddCustomerStepSuccessProps>()(AddCustomerStepSuccess));
