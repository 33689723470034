import { InputNumber, Radio } from 'antd';
import React from 'react';
import { EditRecurringMode } from 'utilities/enum-utils';

interface IProps {
  editRecurringMode: EditRecurringMode;
  onChangeEditRecurringMode: (e) => void;
  onChangeNumberOfBookings: (e) => void;
  numberOfBookingLeft: number;
  numberOfBookings: number;
}

const SleepoverRecurringStep = ({
  editRecurringMode,
  onChangeEditRecurringMode,
  onChangeNumberOfBookings,
  numberOfBookingLeft,
  numberOfBookings,
}: IProps) => {
  return (
    <>
      <div className="mt-small">
        The booking you&apos;re editing is part of a recurring booking series. Please select what you want part of the
        series you want to edit.
      </div>
      <Radio.Group
        value={editRecurringMode}
        onChange={(e) => onChangeEditRecurringMode(e)}
        className="flex flex-column mt-12"
      >
        <Radio value={EditRecurringMode.Current} className="mt-small">
          This booking only
        </Radio>
        <Radio value={EditRecurringMode.CurrentAll} className="mt-small">
          This booking and all following bookings
        </Radio>
        <Radio value={EditRecurringMode.CurrentNext} className="mt-small">
          This booking and the next{' '}
          <InputNumber
            className="mh-x-small"
            style={{ width: '50px' }}
            min={1}
            max={numberOfBookingLeft}
            value={numberOfBookings}
            onChange={onChangeNumberOfBookings}
          />{' '}
          booking
          {numberOfBookings !== 1 && 's'}.
        </Radio>
      </Radio.Group>
    </>
  );
};

export default SleepoverRecurringStep;
