import moment from 'moment-timezone';
import { Text } from 'common-components/typography';
import React, { Component } from 'react';

interface IRegistrationDetailView {
  companyRegistrationDetails: any;
}

class RegistrationDetailView extends Component<IRegistrationDetailView> {
  render() {
    const { companyRegistrationDetails } = this.props;

    return (
      <Text size={'regular'}>
        {companyRegistrationDetails.mainName && (
          <>
            <b>Entity Name: </b>
            {companyRegistrationDetails.mainName.organizationName
              ? companyRegistrationDetails.mainName.organizationName
              : 'Not set.'}
            <br />
          </>
        )}
        {companyRegistrationDetails.entityStatus && (
          <>
            <b>ABN status: </b>
            {companyRegistrationDetails.entityStatus.entityStatusCode
              ? companyRegistrationDetails.entityStatus.entityStatusCode === 'Active' &&
                'Active from ' +
                  moment(companyRegistrationDetails.entityStatus.statusEffectiveFrom).format('DD MMM YYYY') +
                  '.'
              : companyRegistrationDetails.entityStatus.entityStatusCode === 'Inactive'
              ? 'Inactive.'
              : 'Not set.'}
            <br />
          </>
        )}
        {companyRegistrationDetails.entityType && (
          <>
            <b>Entity type: </b>
            {companyRegistrationDetails.entityType
              ? companyRegistrationDetails.entityType.entityDescription
              : 'Not set.'}
            <br />
          </>
        )}
        {/* {companyRegistrationDetails.goodsAndServicesTax && (
          <>
            <b>Goods and Service tax (GST): </b>
            {moment(companyRegistrationDetails.goodsAndServicesTax.taxEffectiveFrom) < moment() &&
            moment(companyRegistrationDetails.goodsAndServicesTax.taxEffectiveTo) > moment()
              ? 'Registered'
              : 'Not currently registered for GST.'}
            <br />
          </>
        )} */}
        {companyRegistrationDetails.mainBusinessPhysicalAddress && (
          <>
            <b>Main business location: </b>
            {companyRegistrationDetails.mainBusinessPhysicalAddress.stateCode &&
              companyRegistrationDetails.mainBusinessPhysicalAddress.stateCode}{' '}
            {companyRegistrationDetails.mainBusinessPhysicalAddress.postcode &&
              companyRegistrationDetails.mainBusinessPhysicalAddress.postcode}
          </>
        )}
      </Text>
    );
  }
}

export default RegistrationDetailView;
