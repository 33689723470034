import { Col } from 'antd';
import React from 'react';
import { Text } from '@good/components';
import { TeamStatus } from 'utilities/enum-utils';
import { TmMgmtBlueDot } from './tm-mgmt-blue-dot';

type TmMgmtWorkspaceColumnParams = {
  status: TeamStatus;
  hasPortalAccess: boolean;
  ownerRole: number;
};

export const TmMgmtWorkspaceColumn = ({ status, hasPortalAccess, ownerRole }: TmMgmtWorkspaceColumnParams) => {
  const fontColor = status !== TeamStatus.ENABLED ? 'tertiary' : 'black';

  if (status === TeamStatus.REMOVED) {
    return (
      <Col span={4}>
        <Text className="text-color-secondary">-</Text>
      </Col>
    );
  }

  if (hasPortalAccess) {
    return (
      <Col span={4}>
        <Text className={`text-color-${fontColor}`}>
          <TmMgmtBlueDot /> Yes {ownerRole === 1 && '(Primary owner)'}
          {ownerRole === 2 && '(Owner)'}
          {ownerRole === 5 && '(Team manager)'}
        </Text>
      </Col>
    );
  }

  return (
    <Col span={4}>
      <Text className="text-color-secondary">No</Text>
    </Col>
  );
};
