import { Spinner } from '@blueprintjs/core';
import { Avatar, Col, Divider, Form, Icon, notification, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { LocationDisplay } from 'common-components/locations';
import { TimezoneIndicator } from 'common-components/timezone';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { ICommonUserInfo } from 'interfaces/session-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import {
  GroupServiceClassification,
  NotePrivacy,
  ServiceLocationType,
  ServicePublishStatus,
  ServiceRecurringType,
  ServiceTimeType,
  ServiceType,
  TeamStatus,
} from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';

/* Spinner while create Service process */
class CreatingServicePanel extends Component<{ serviceName: string }> {
  render() {
    return (
      <div className="anim-slide-left">
        <Title level={2} weight="bolder" className="line-height-100 mb-x-large">
          <span className="text-weight-regular">Creating a new </span> service
        </Title>
        <div className="align-center mb-x-large flex-row">
          <div className="mr-x-large">
            <Spinner size={120} />
          </div>
          <div>
            <Paragraph>
              Creating the new Service: <b>{this.props.serviceName}</b>.
            </Paragraph>
            <Paragraph weight="bold">Finalizing service details...</Paragraph>
            <Paragraph>This won't take long.</Paragraph>
          </div>
        </div>
      </div>
    );
  }
}

interface ISummaryStepPanelProps extends FormComponentProps {
  resetAndCloseModal: (serviceId?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  onNextStep?: () => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
  doFetchSelectedWorker: typeof dispatch.teamStore.doFetchSelectedWorker;
  doCreateNewService?: typeof dispatch.servicesStore.doCreateNewService;
  doCreateNewGroupService?: typeof dispatch.servicesStore.doCreateNewGroupService;
  serviceDepartmentList: typeof state.servicesStore.serviceDepartmentListLite;
  portalUser: typeof state.authStore.portalUser;
  goToNewService?: () => void;
  restartServiceCreation?: () => void;
  workerListLite: typeof state.teamStore.workerListLite;
  doFetchWorkerListLite: typeof dispatch.teamStore.doFetchWorkerListLite;
}

interface ISummaryStepPanelState {
  isLoading: boolean;
  isGroupService: boolean;
  isIndividualService: boolean;
  isIndependentLiving: boolean;
  isCoordinationService: boolean;
  isDoneCreatingService: boolean;
}

class SummaryStepPanel extends Component<ISummaryStepPanelProps, ISummaryStepPanelState> {
  state = {
    isLoading: false,
    isGroupService: this.props.newService?.serviceType === ServiceType.GROUP,
    isIndividualService: this.props.newService?.serviceType === ServiceType.INDIVIDUAL,
    isCoordinationService: this.props.newService?.serviceType === ServiceType.COORDINATION,
    isIndependentLiving: this.props.newService?.serviceType === ServiceType.INDEPENDENT_LIVING,
    isDoneCreatingService: false,
  };

  private _changeBannerFormat = (service) => {
    // using a function to use await, to avoid the doCreateNewService to be trigger before the copy is completed.
    return { ...service, bannerUrl: service.bannerUrl.attachmentUrl, bannerUrlPath: service.bannerUrl.attachmentPath };
  };

  private _createService = async () => {
    const { newService, doCreateNewService, doCreateNewGroupService, onNextStep } = this.props;
    try {
      this.setState({ isLoading: true });
      let newServiceWithBanner;
      switch (newService?.serviceType) {
        case ServiceType.INDIVIDUAL: {
          // Cast all date data to the selected timezone.
          const timezone = newService.timezone;
          const { serviceClaimConfig, ...serviceInfo } = newService;
          const newServiceWithTimezone = {
            ...serviceInfo,
            vcpClaims: {
              ...serviceClaimConfig.vcpClaims,
              isChargeTransportDuringBooking: serviceClaimConfig.isChargeVcpTransportDuringBooking,
            },
            ndisClaims: {
              ...serviceClaimConfig.ndisClaims,
              isChargeTransportBeforeBooking: serviceClaimConfig.isChargeNdisTransportBeforeBooking,
              isChargeTransportDuringBooking: serviceClaimConfig.isChargeNdisTransportDuringBooking,
            },
            availableFrom: moment.tz(moment(newService.availableFrom).format('YYYY-MM-DD HH:mm'), timezone),
            availableTo: moment.tz(moment(newService.availableTo).format('YYYY-MM-DD HH:mm'), timezone),
            servicePublishDate: moment.tz(moment(newService.servicePublishDate).format('YYYY-MM-DD HH:mm'), timezone),
            serviceConfig: {
              ...newService.serviceConfig,
              availableFrom: moment.tz(moment(newService.availableFrom).format('YYYY-MM-DD HH:mm'), timezone),
              availableTo: moment.tz(moment(newService.availableTo).format('YYYY-MM-DD HH:mm'), timezone),
              dataArray: newService.serviceConfig.dataArray
                ? _.map(newService.serviceConfig.dataArray, (data) => {
                    return {
                      ...data,
                      startDateTime: moment.tz(moment(data.startDateTime).format('YYYY-MM-DD HH:mm'), timezone),
                      endDateTime: moment.tz(moment(data.endDateTime).format('YYYY-MM-DD HH:mm'), timezone),
                    };
                  })
                : null,
            },
            timeAvailabilities: _.map(newService.timeAvailabilities, (availability) => {
              return {
                ...availability,
                startDateTime: moment
                  .tz(moment(availability.startDateTime).format('YYYY-MM-DD HH:mm'), timezone)
                  .toISOString(),
                endDateTime: moment
                  .tz(moment(availability.endDateTime).format('YYYY-MM-DD HH:mm'), timezone)
                  .toISOString(),
              };
            }),
          };
          newServiceWithBanner = await this._changeBannerFormat(newServiceWithTimezone);
          // Temporary solution until the API is changed to cater the new structure.
          await doCreateNewService(newServiceWithBanner);
          break;
        }

        case ServiceType.GROUP:
          newServiceWithBanner = await this._changeBannerFormat(newService);
          await doCreateNewGroupService(newServiceWithBanner);
          break;

        case ServiceType.COORDINATION: {
          const service = { ...newService };
          service.status = ServicePublishStatus.PUBLISHED;
          service.servicePublishDate = new Date();
          newServiceWithBanner = await this._changeBannerFormat(service);
          await doCreateNewService(newServiceWithBanner);
          break;
        }

        case ServiceType.INDEPENDENT_LIVING: {
          const timezone = newService.timezone;
          const { serviceClaimConfig, ...serviceInfo } = newService;
          const timeAvailabilities = newService.timeAvailabilities.map((availability) => {
            return {
              ...availability,
              startDateTime: moment
                .tz(moment(availability.startDateTime).format('YYYY-MM-DD HH:mm'), timezone)
                .toISOString(),
              endDateTime: moment
                .tz(moment(availability.endDateTime).format('YYYY-MM-DD HH:mm'), timezone)
                .toISOString(),
            };
          });
          const serviceConfig = {
            ...newService.serviceConfig,
            availableFrom: moment.tz(moment(newService.availableFrom).format('YYYY-MM-DD HH:mm'), timezone),
            availableTo: moment.tz(moment(newService.availableTo).format('YYYY-MM-DD HH:mm'), timezone),
            dataArray: null,
          };
          const newServiceWithTimezone = {
            ...serviceInfo,
            vcpClaims: {
              ...serviceClaimConfig.vcpClaims,
              isChargeTransportDuringBooking: serviceClaimConfig.isChargeVcpTransportDuringBooking,
            },
            ndisClaims: serviceClaimConfig.ndisClaims,
            availableFrom: moment.tz(moment(newService.availableFrom).format('YYYY-MM-DD HH:mm'), timezone),
            availableTo: moment.tz(moment(newService.availableTo).format('YYYY-MM-DD HH:mm'), timezone),
            servicePublishDate: moment.tz(moment(newService.servicePublishDate).format('YYYY-MM-DD HH:mm'), timezone),
            serviceConfig,
            timeAvailabilities,
            NDISEligible: true,
            ndisServiceBillingItems: [
              {
                billingProvider: 'NDIS',
                customerSupportLevel: '1',
                dateType: '',
                groupCode: '',
                groupName: '',
                label: 'Assistance in Supported Independent Living',
                mileagePrice: 0,
                price: {
                  actPrice: 0,
                  nonRemotePrice: 0,
                  ntPrice: 0,
                  remotePrice: 0,
                  veryRemotePrice: 0,
                },
                priceControl: 'N',
                priority: 3,
                supportCategoryNumber: 1,
                supportItem: 'Assistance in Supported Independent Living',
                supportItemNumber: '01_825_0115_1_1',
                tax: 0,
                unit: 'WK',
              },
            ],
            paymentSources: ['NDIS'],
          };
          newServiceWithBanner = await this._changeBannerFormat(newServiceWithTimezone);
          // Temporary solution until the API is changed to cater the new structure.
          await doCreateNewService(newServiceWithBanner);
          break;
        }

        default:
          break;
      }

      this.setState({ isLoading: false });
      if (this.state.isCoordinationService) {
        this.setState({ isDoneCreatingService: true });
      } else {
        onNextStep();
      }
    } catch (e) {
      this.setState({ isLoading: false });
      console.log(e);
      notification.error({
        message: 'Error while creating your booking',
        description: 'Please try again.',
      });
    }
  };

  private _getRostersCommonInfo(rosters: any[], workerList: any[]): ICommonUserInfo[] {
    const workerInfoList = [];
    const rosterIds = _.map(rosters, (roster) => roster.workerId);
    _.forEach(workerList, (roster) => {
      if (rosterIds.includes(roster.supportWorkerId)) {
        const workerInfo = {
          userId: roster.userId,
          userAvatar: roster.attachmentUrl,
          userFullName: roster.firstName + ' ' + roster.lastName,
        };
        workerInfoList.push(workerInfo);
      }
    });

    return workerInfoList;
  }

  componentDidMount = () => {
    this.props.doFetchWorkerListLite({
      hasAppAccess: ['WORKER'],
      supportWorkerStatus: [TeamStatus.ENABLED, TeamStatus.DRAFT, TeamStatus.BLOCKED],
      sortByRelevance: true,
    });
  };

  render() {
    const { onPreviousStep, newService, restartServiceCreation, goToNewService, portalUser, workerListLite } =
      this.props;
    const rostersInfo = this._getRostersCommonInfo(newService.workers, workerListLite);
    const { isGroupService, isIndividualService, isIndependentLiving, isCoordinationService, isDoneCreatingService } =
      this.state;

    const hasNDIS = _.find(newService.paymentSources, (source) => source === 'NDIS');
    const hasVCP = _.find(newService.paymentSources, (source) => source === 'VCP');

    if (this.state.isLoading) {
      return <CreatingServicePanel serviceName={newService.serviceName} />;
    }

    return (
      <div className="anim-slide-left mb-x-large">
        <Title
          level={2}
          weight={isIndividualService ? 'regular' : 'bolder'}
          className={isDoneCreatingService ? 'line-height-100 text-color-blue-action' : 'line-height-100'}
        >
          {!isDoneCreatingService ? (
            isIndividualService ? (
              'Review Service'
            ) : (
              'Service overview'
            )
          ) : (
            <>
              <Icon type="check-circle" theme="filled" className="text-size-x2-large" />
              &nbsp;Service successfully created
            </>
          )}
        </Title>

        <Paragraph>
          {isIndividualService && !isDoneCreatingService && 'Review the service details before you create.'}
          {isIndependentLiving && !isDoneCreatingService && 'Review the service details before you create.'}
          {(isGroupService || isCoordinationService) &&
            !isDoneCreatingService &&
            'You’ve done it! Review the details of the service and press the ‘Create service’ button when you are ready'}
          {isCoordinationService && isDoneCreatingService && (
            <div>
              You have successfully created a <b>Support coordination</b> service.
            </div>
          )}
        </Paragraph>

        <Row className="mt-large">
          <Col span={isIndividualService ? 4 : 6} className={isIndividualService ? 'pt-large' : ''}>
            <SubTitle>{isIndividualService ? 'General' : 'GENERAL INFORMATION'}</SubTitle>
          </Col>
          <Col
            span={isIndividualService ? 20 : 18}
            className={isIndividualService ? 'bordered pv-large ph-medium rounded' : 'bordered pt-medium rounded'}
          >
            <Row className={isIndividualService ? 'mb-medium' : 'mb-medium mh-medium'}>
              <Col span={6}>
                <Text weight={'bold'}>Service Type</Text>
              </Col>
              <Col span={18}>
                {isIndividualService
                  ? 'Support Service'
                  : isGroupService
                  ? 'Group Service'
                  : isIndependentLiving
                  ? 'Supported Independent Living Service'
                  : 'Support Coordination'}
              </Col>
            </Row>
            {isGroupService && <Divider className="mv-medium" />}
            <Row className={isIndividualService ? 'mb-medium' : 'mb-medium mh-medium'}>
              <Col span={6}>
                <Text weight="bold">Service name</Text>
              </Col>
              <Col span={18}>{newService.serviceName}</Col>
            </Row>
            {isGroupService && <Divider className="mv-medium" />}
            <Row className={isIndividualService ? 'mb-medium' : 'mb-medium mh-medium'}>
              <Col span={6}>
                <Text weight={'bold'}>Service Description</Text>
              </Col>
              <Col span={18}>
                <Paragraph style={{ whiteSpace: 'pre-line' }} ellipsis={{ rows: 5 }} className="m-none">
                  {newService.serviceDescription}
                </Paragraph>
              </Col>
            </Row>
            {isGroupService && <Divider className="mv-medium" />}
            {isIndividualService && (
              <>
                <Row className="mb-medium">
                  <Col span={6}>
                    <Text weight={'bold'}>Service Department</Text>
                  </Col>
                  <Col span={18}>
                    {
                      _.find(
                        this.props.serviceDepartmentList,
                        (department) => department.serviceDepartmentId === newService.serviceDepartmentId,
                      ).serviceDepartmentName
                    }
                  </Col>
                </Row>

                <Row className={'mb-medium'}>
                  <Col span={6}>
                    <Text weight="bold">Service Time Zone</Text>
                  </Col>
                  <Col span={18}>
                    <TimezoneIndicator timezone={newService.timezone} />
                  </Col>
                </Row>
              </>
            )}

            <Row className={!isIndividualService ? 'mb-medium mh-medium' : ''}>
              <Col span={6}>
                <Text weight={'bold'}>Banner Image</Text>
              </Col>
              <Col span={18}>
                {newService.bannerUrl.attachmentPath ? (
                  <img alt="Service's Banner" style={{ maxWidth: '80%' }} src={newService.bannerUrl.attachmentUrl} />
                ) : (
                  'Not set'
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-large">
          <Col span={!isIndividualService ? 6 : 4} className={!isIndividualService ? '' : 'pt-large'}>
            <SubTitle>Additional Information</SubTitle>
          </Col>
          <Col
            span={!isIndividualService ? 18 : 20}
            className={!isIndividualService ? 'bordered pt-medium rounded' : 'bordered pv-large ph-medium rounded'}
          >
            {' '}
            {isGroupService && (
              <>
                <Row className="mb-medium mh-medium">
                  <Col span={6}>
                    <Text weight={'bold'}>Service Classification</Text>
                  </Col>
                  <Col span={18}>
                    {newService.groupServiceClassification === GroupServiceClassification.COMMUNITY
                      ? 'Community'
                      : 'Centre-based'}
                  </Col>
                </Row>
                <Divider className="mv-medium" />
              </>
            )}
            <Row className={!isIndividualService ? 'mb-medium mh-medium' : ''}>
              <Col span={6}>
                <Text weight={'bold'}>{!isIndividualService ? 'Default location' : 'Location'}</Text>
              </Col>
              <Col span={18}>
                {newService.serviceDirection === ServiceLocationType.ONLINE ? (
                  'Online'
                ) : newService.serviceDirection === ServiceLocationType.CUSTOMERLOCATION ? (
                  "At customer's chosen location"
                ) : newService.locations ? (
                  <LocationDisplay location={newService.locations[0].fullAddress} />
                ) : (
                  'Not set'
                )}
              </Col>
            </Row>
            {!isCoordinationService && newService.capacity > 0 && (
              <>
                <Divider className="mv-medium" />
                <Row className={isGroupService || isIndependentLiving ? 'mb-medium mh-medium' : 'mb-medium'}>
                  <Col span={6}>
                    <Text weight={'bold'}>{isGroupService ? 'Default capacity' : 'Capacity'}</Text>
                  </Col>
                  <Col span={18}>
                    {newService.capacity} {isGroupService ? '' : 'person max'}
                  </Col>
                </Row>
              </>
            )}
            {isGroupService && (
              <>
                <Divider className="mv-medium" />
                <Row className="mb-medium mh-medium">
                  <Col span={6}>
                    <Text weight={'bold'}>Transport</Text>
                  </Col>
                  <Col span={18}>
                    {newService.serviceClaimConfig.isChargeNdisTransportDuringBooking ? 'Enabled' : 'Disabled'}
                  </Col>
                </Row>
              </>
            )}
            {isCoordinationService && (
              <>
                <Divider className="mv-medium" />
                <Row className={'mb-medium mh-medium'}>
                  <Col span={6}>
                    <Text weight={'bold'}>{'Default note privacy'}</Text>
                  </Col>
                  <Col span={18}>{NotePrivacy[newService.defaultNotePrivacyVisible]}</Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        {isIndividualService && (
          <Row className="mt-large">
            <Col span={4} className="pt-large">
              <SubTitle>Availability</SubTitle>
            </Col>
            <Col span={20} className="bordered pv-large ph-medium rounded">
              <Row>
                <Col span={6}>
                  <Text weight="bold">Availability</Text>
                </Col>
                <Col span={18}>
                  {newService.serviceType === ServiceType.INDIVIDUAL &&
                    newService.bookingTimeType === ServiceTimeType.FIXEDTIME && (
                      <>
                        {_.map(newService.serviceConfig.dataArray, (time) => {
                          if (time.selected) {
                            return (
                              <div>
                                <b>{time.day}</b>:{' '}
                                {time.isAllDay
                                  ? 'All day'
                                  : moment(time.startDateTime).format('hh:mm A') +
                                    ' - ' +
                                    moment(time.endDateTime).format('hh:mm A')}
                              </div>
                            );
                          }
                        })}
                      </>
                    )}
                  {newService.serviceType === ServiceType.INDIVIDUAL &&
                    newService.bookingTimeType === ServiceTimeType.OPENTIME && <div>All days and times.</div>}
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {isIndependentLiving && (
          <Row className="mt-large">
            <Col span={6} className="pt-large">
              <SubTitle>Availability</SubTitle>
            </Col>
            <Col span={18} className="bordered pv-large ph-medium rounded">
              <Row>
                <Col span={4}>
                  <Text weight="bold">Availability</Text>
                </Col>
                <Col span={20}>
                  <div>All days and times.</div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {!isIndividualService && !isIndependentLiving && (
          <Row className="mt-large">
            <Col span={6}>
              <SubTitle>PRICING SETTINGS (NDIS)</SubTitle>
            </Col>
            <Col span={18} className="bordered pt-medium rounded">
              <Row className="mb-medium mh-medium">
                <Col span={6}>
                  <Text weight={'bold'}>Line items selected</Text>
                </Col>
                <Col span={18}>
                  {newService.ndisServiceBillingItems ? newService.ndisServiceBillingItems.length : 0} line item
                  {newService.ndisServiceBillingItems && newService.ndisServiceBillingItems.length !== 1 && 's'}{' '}
                  selected
                </Col>
              </Row>
              {isGroupService && (
                <>
                  <Divider className="mv-medium" />
                  <Row className="mb-medium mh-medium">
                    <Col span={6}>
                      <Text weight={'bold'}>Default ratio</Text>
                    </Col>
                    <Col span={18}>{newService.teamMemberCustomerRatio.ndis}</Col>
                  </Row>
                  <Divider className="mv-medium" />

                  <Row className="mb-medium mh-medium">
                    <Col span={6}>
                      <Text weight={'bold'}>Centre based capital</Text>
                    </Col>
                    <Col span={18}>
                      {newService.serviceClaimConfig.ndisClaims.isChargeCentreCapitalCosts
                        ? 'Claimable'
                        : 'Non-claimable'}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        )}

        {isIndividualService && (
          <Row className="mt-large">
            <Col span={4} className="pt-large">
              <SubTitle>Payment methods</SubTitle>
            </Col>
            <Col span={20} className="bordered pv-large ph-medium rounded">
              <Row>
                <Col span={6}>
                  <Text weight={'bold'}>Enabled Payment methods</Text>
                </Col>
                <Col span={18}>
                  {hasNDIS && <Text>NDIS</Text>}
                  {hasVCP && (
                    <div>
                      <Text>VCP</Text>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {!isCoordinationService && (
          <>
            <Row className="mt-large">
              <Col span={isGroupService || isIndependentLiving ? 6 : 4} className={isGroupService ? '' : 'pt-large'}>
                <SubTitle>{isGroupService ? 'PUBLISH SETTINGS' : 'Publish rules'}</SubTitle>
              </Col>
              <Col
                span={isGroupService || isIndependentLiving ? 18 : 20}
                className={isGroupService ? 'bordered pt-medium rounded' : 'bordered pv-large ph-medium rounded'}
              >
                {' '}
                <Row className={isGroupService ? 'mb-medium mh-medium' : 'mb-medium'}>
                  <Col span={6}>
                    <Text weight={'bold'}>Publish Date</Text>
                  </Col>
                  <Col span={18}>{moment(newService.servicePublishDate).format('dddd DD/MM/YY')}</Col>
                </Row>
                {isGroupService && <Divider className="mv-medium" />}
                <Row className={isGroupService ? 'mb-medium mh-medium' : ''}>
                  <Col span={6}>
                    <Text weight={'bold'}>{isGroupService ? 'Publish settings' : 'Publish Strategy'}</Text>
                  </Col>
                  <Col span={18}>
                    {newService.status === 'DRAFT'
                      ? 'This service will be saved as a draft'
                      : newService.isPublicMarketplace
                      ? 'This service will be available to every customer.'
                      : 'This service will be available to your customers.'}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        {isCoordinationService && (
          <>
            <Row className="mt-large">
              <Col span={6} className="pt-large">
                <SubTitle>ROSTER</SubTitle>
              </Col>
              <Col span={18} className="bordered flex flex-wrap rounded">
                {_.map(rostersInfo, (roster) => {
                  return (
                    <div
                      className="ml-small mr-small mt-small mb-small rounded-big align-center ph-small bordered flex-row bg-white"
                      style={{
                        height: '40px',
                        width: 'fit-content',
                      }}
                    >
                      <Avatar size={28} src={roster.userAvatar} shape={'square'} />
                      <Text weight="regular" className="ml-small">
                        {roster.userFullName}
                      </Text>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </>
        )}

        {!isDoneCreatingService ? (
          <div className="align-center mv-x2-large flex-row">
            <SecondaryButton size="large" onClick={onPreviousStep} icon="left" className="mr-large">
              Previous
            </SecondaryButton>

            <PrimaryButton size="large" onClick={this._createService} icon="right" iconPosition="right">
              Create Service
            </PrimaryButton>
          </div>
        ) : (
          <div className="mt-large flex-row justify-start">
            <SecondaryButton size="large" onClick={restartServiceCreation} icon="left" className="mr-medium">
              Create another Service
            </SecondaryButton>

            {PermissionUtils.validatePermission('ViewTeamMemberProfile', portalUser.permissions.permissionRoles) && (
              <PrimaryButton size="large" onClick={goToNewService} icon="close">
                Go to the Service
              </PrimaryButton>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  serviceDepartmentList: state.servicesStore.serviceDepartmentListLite,
  portalUser: state.authStore.portalUser,
  workerListLite: state.teamStore.workerListLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSelectedWorker: dispatch.teamStore.doFetchSelectedWorker,
  doFetchWorkerListLite: dispatch.teamStore.doFetchWorkerListLite,
});

export default connect(mapState, mapDispatch)(Form.create<ISummaryStepPanelProps>()(SummaryStepPanel));
