import React, { Component } from 'react';
import { Col, notification, Radio, Row } from 'antd';
import { Paragraph } from 'common-components/typography';
import Title from 'antd/lib/typography/Title';
import { ScheduleType } from 'utilities/enum-utils';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { ActionModalFooter } from 'common-components/modal/ActionModal';

interface IActionTypeStepPanelProps {
  scheduleType: ScheduleType;
  onNextStep: () => void;
  onSetScheduleType: (v: ScheduleType) => void;
}

interface IActionTypeStepPanelState {
  scheduleType: ScheduleType;
  isScheduleTypeError: boolean;
}

class ActionTypeStepPanel extends Component<IActionTypeStepPanelProps, IActionTypeStepPanelState> {
  state = {
    scheduleType: this.props.scheduleType,
    isScheduleTypeError: false,
  };

  private _selectScheduleType = async (scheduleType) => {
    this.setState({ isScheduleTypeError: false, scheduleType });
  };

  private _goToNext = () => {
    if (!this.state.scheduleType) {
      this.setState({ isScheduleTypeError: true });
      return;
    }

    this.props.onSetScheduleType(this.state.scheduleType);
    this.props.onNextStep();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.scheduleType !== prevProps.scheduleType) {
      this.setState({ scheduleType: this.props.scheduleType });
    }
  };

  private _handleRedirectToHelpCenter = () => {
    const targetUrl = this.state.scheduleType
      ? this.state.scheduleType === ScheduleType.SCHEDULE
        ? 'https://goodhuman.zendesk.com/hc/en-au/articles/4403969405849-Assigning-team-members-to-multiple-group-sessions#step-1-select-schedule-or-individual-sessions-0-9'
        : 'https://goodhuman.zendesk.com/hc/en-au/articles/4403969405849-Assigning-team-members-to-multiple-group-sessions#step-1-select-schedule-or-individual-sessions-0-2'
      : 'https://goodhuman.zendesk.com/hc/en-au/articles/4403969405849-Assigning-team-members-to-multiple-group-sessions#assigning-team-members-to-multiple-group-sessions-0-0';
    window.open(targetUrl, '_blank');
  };

  render() {
    const { scheduleType } = this.state;

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={3}>We'll guide you on assigning team members to sessions.</Title>
              <Paragraph>The next few screens will cover everything that's needed to create sessions.</Paragraph>
              <Paragraph>
                Visit this <HyperlinkButton onClick={this._handleRedirectToHelpCenter}>help center</HyperlinkButton> to
                see more.
              </Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white rounded-big ph-large pt-large pb-x4-large" style={{ minWidth: '250px' }}>
              <Title level={4}>Which sessions would you like to assign team members to?</Title>
              <Paragraph className="mv-large">Please select an action below.</Paragraph>
              <div
                className={`mb-medium bordered border-standard-gray rounded shadow-container cursor-pointer p-medium flex-row ${
                  scheduleType === ScheduleType.SCHEDULE && 'bg-blue-lightest'
                }`}
                onClick={() => this._selectScheduleType(ScheduleType.SCHEDULE)}
              >
                <div className={'pr-medium'}>
                  <Radio checked={scheduleType === ScheduleType.SCHEDULE} value={ScheduleType.SCHEDULE} />
                </div>
                <div>
                  <b>Assign to a schedule</b>
                  <br />
                  Assign to a scheduled series of sessions for a group service.
                </div>
              </div>
              <div
                className={`bordered border-standard-gray rounded shadow-box cursor-pointer p-medium flex-row ${
                  scheduleType === ScheduleType.ONE_OFF && 'bg-blue-lightest'
                }`}
                onClick={() => this._selectScheduleType(ScheduleType.ONE_OFF)}
              >
                <div className={'pr-medium'}>
                  <Radio checked={scheduleType === ScheduleType.ONE_OFF} value={ScheduleType.ONE_OFF} />
                </div>
                <div>
                  <b>Assign to individual sessions</b>
                  <br />
                  Assign to a individual or multiple individual sessions.
                </div>
              </div>
              {this.state.isScheduleTypeError && (
                <div className={'mt-medium'}>
                  <Paragraph className={'text-color-red-dark'}>Please select a type.</Paragraph>
                </div>
              )}
            </div>

            <ActionModalFooter align="right" className="mt-large mb-x2-large pr-large">
              <PrimaryButton size="large" onClick={this._goToNext}>
                Next
              </PrimaryButton>
            </ActionModalFooter>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ActionTypeStepPanel;
