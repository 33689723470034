import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';
import { Avatar } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';
import _ from 'lodash';
import { IRosterShift } from 'src/common-components/roster-control/interfaces/roster-interfaces';

interface OpenShiftsContentRowProps {
  unassignedShifts?: IRosterShift[][];
}

const { TIMELINE_ROW_HEIGHT } = ROSTER_DAILY_CONFIG;

export function OpenShiftsSideColumn({ unassignedShifts = [] }: OpenShiftsContentRowProps) {
  const rowHeight = _.size(unassignedShifts) * TIMELINE_ROW_HEIGHT;

  const shiftsCount = _.chain(unassignedShifts).flatten().size().value();

  return (
    <div
      className="bg-quaternary pv-medium pl-x-large pr-medium flex-row justify-between bordered-right bordered-bottom"
      style={{ height: rowHeight }}
    >
      <div className="flex-row align-center">
        <div className="mr-12">
          <Avatar size="large" shape="square" icon="team" />
        </div>
        <div className="line-height-135 flex-column">
          <Text lineHeight={120}>Unassigned shifts</Text>
          <div className="line-height-120 flex-row align-center">
            <Text size="regular" color="secondary" lineHeight={120}>
              {shiftsCount} unassigned
            </Text>
            {/*<Icon type="warning" theme="filled" className="text-color-warning-orange ml-small" />*/}
          </div>
        </div>
      </div>
      {/*<Popover*/}
      {/*  content={*/}
      {/*    <ActionMenu>*/}
      {/*      <ActionMenuItem text={'Action 1'} />*/}
      {/*      <ActionMenuItem text={'Action 2'} />*/}
      {/*    </ActionMenu>*/}
      {/*  }*/}
      {/*  position="bottom-left"*/}
      {/*>*/}
      {/*  <IconButton icon="ellipsis" size="small" className="bg-white" bordered={true} iconColor="secondary" />*/}
      {/*</Popover>*/}
    </div>
  );
}
