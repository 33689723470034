import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { Form, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/es/form';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';

interface IWaiveLineItemActionModelProps extends FormComponentProps {
  isOpen: boolean;
  selectedBillingLineItem: typeof state.billingsStore.selectedBillingLineItem;
  onClose: () => void;
  doWaiveBillingLineItem: typeof dispatch.billingsStore.doWaiveBillingLineItem;
  lineItems?: Array<any>;
  type: string;
}

interface IWaiveLineItemActionModelState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

class WaiveLineItemActionModel extends Component<IWaiveLineItemActionModelProps, IWaiveLineItemActionModelState> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false,
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  private _validateWaiveReason = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('You must add a waive reason.');
      } else if (_.trim(value).length < 5) {
        throw Error('Please enter at least 5 characters.');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _onSubmitWaive = async () => {
    const { form, selectedBillingLineItem, doWaiveBillingLineItem, type = 'payment', lineItems = [] } = this.props;

    const reason = form.getFieldValue('note');

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      const payload = {
        type: type,
        list:
          type === 'preview'
            ? _.map(lineItems, (lineItem) => {
                return {
                  bookingBillingLineItemId: lineItem.bookingBillingLineItemId,
                  attendanceId: lineItem.attendanceId,
                  waiveReason: reason,
                };
              })
            : [
                {
                  bookingBillingLineItemId: selectedBillingLineItem.bookingBillingLineItemId,
                  attendanceId: selectedBillingLineItem.attendanceId,
                  waiveReason: reason,
                },
              ],
      };

      try {
        this.setState({ isLoading: true });
        await doWaiveBillingLineItem(payload);
        this.setState({ isLoading: false }, () => {
          this._onCloseModal();
        });
        this._onCloseModal();
      } catch (e) {
        this.setState({ isLoading: false });
        notification.error({ message: 'Error', description: 'Oops. Something wrong, please try again later' });
      }
    }
  };

  render() {
    const { isOpen, type, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <ActionModal
        title={type === 'preview' ? 'Waive invoice' : 'Waive Line Item'}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        <div className="text-align-left">
          <div className="mb-medium">
            {type === 'preview' ? (
              <Paragraph>
                Waiving the invoice will waive all the line items inside and move the lines to the waived section of
                payments.
              </Paragraph>
            ) : (
              <Paragraph>
                Waiving the line item will waive the payment and move the line to the waived section of payments.
              </Paragraph>
            )}
            <Paragraph>
              Waived line items can be un-waived if you wish to charge the consumer for the line item.
            </Paragraph>
          </div>
          <div>
            <Paragraph>Please provide a reason for waiving this {type === 'preview' ? 'invoice' : 'booking'}</Paragraph>
            <Form.Item>
              {getFieldDecorator('note', { rules: [{ validator: this._validateWaiveReason }] })(
                <TextArea placeholder="Waive Reason(s)" autoSize={{ minRows: 3, maxRows: 3 }} />,
              )}
            </Form.Item>
          </div>
        </div>
        <ActionModalFooter>
          <GhostButton size="large" onClick={this._onCloseModal} className="mr-medium" disabled={this.state.isLoading}>
            Not now
          </GhostButton>
          <PrimaryButton size="large" onClick={this._onSubmitWaive} loading={this.state.isLoading}>
            Waive
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doWaiveBillingLineItem: dispatch.billingsStore.doWaiveBillingLineItem,
});

export default connect(mapState, mapDispatch)(Form.create<IWaiveLineItemActionModelProps>()(WaiveLineItemActionModel));
