import React from 'react';
import { PrimaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { ActivityGroupModalType } from 'utilities/enum-utils';
import { openModalFuncType } from '../GroupServiceActivityGroupsPanel';

interface IEmptyStateProps {
  openModal: openModalFuncType;
}

function EmptyState(props: IEmptyStateProps) {
  const { openModal } = props;

  return (
    <>
      <div className="flex-row justify-between align-center mb-x-small">
        <div className="line-height-120">
          <Text weight="bold">Manage activities & groups</Text> <br />
          <Text>View and manage all groups and activities for this service.</Text>
        </div>
      </div>

      <div
        className="bg-tertiary mt-large rounded flex justify-center align-center text-align-center"
        style={{ height: '400px' }}
      >
        <div>
          <Text>Nothing here yet.</Text>
          <br />
          <Text color="secondary">
            Select <b>+ Create activity group</b> to get started!
          </Text>
          <br />

          <PrimaryButton
            className="mt-medium"
            size="large"
            icon={'plus'}
            onClick={() => openModal(null, ActivityGroupModalType.CREATE)}
          >
            {' '}
            Create activity group
          </PrimaryButton>
        </div>
      </div>
    </>
  );
}

export default EmptyState;
