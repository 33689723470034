import { useCallback, useEffect, useState } from 'react';

import { AddressInputFieldErrors, FieldError } from '../types';
import { emptyFieldErrors } from '../defaults';

export const hasErrors = (errors: AddressInputFieldErrors) => Object.values(errors).filter((x) => x).length > 0;

export function useErrors(onChangeErrorsCallback?: (errors: AddressInputFieldErrors, hasErrors?: boolean) => void) {
  const [errors, setErrors] = useState<AddressInputFieldErrors>(emptyFieldErrors);

  useEffect(() => {
    onChangeErrorsCallback?.(errors, hasErrors(errors));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Cannot guarantee onChangeErrorsProp will be callback or setState dispatch
  }, [errors]);

  const addInternalError = useCallback((field: keyof AddressInputFieldErrors, error: FieldError) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  }, []);

  const clearInternalErrors = useCallback((field?: keyof AddressInputFieldErrors) => {
    if (field) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
      return;
    }

    setErrors(emptyFieldErrors);
  }, []);

  return {
    errors,
    hasErrors: hasErrors(errors),
    addInternalError,
    clearInternalErrors,
  };
}
