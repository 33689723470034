import { Slot } from '@radix-ui/react-slot';
import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';

import * as styles from './text.css';
import { css, compose, useStyleProps, textStyleProps } from '..';

import type { HTMLProps, SlotProps, TextStyleProps } from '..';
import type { PropsWithChildren } from 'react';

import type { TextVariants } from './text.css';

export type TextProps = PropsWithChildren<HTMLProps<HTMLSpanElement> & TextVariants & SlotProps & TextStyleProps>;

/**
 * @deprecated
 * @see https://main--6371c62d928a15c9d0425ed3.chromatic.com/?path=/docs/components-text--docs
 */
export const Text = forwardRef<HTMLElement, TextProps>(function Text(props, ref) {
  const { styleProps, ...otherProps } = useStyleProps(props, textStyleProps);
  const {
    asChild,
    children,
    size = 'small',
    emphasis,
    capitalize,
    uppercase,
    lowercase,
    underline,
    throughline,
  } = props; // Pick HTML color for typing
  const ElementType = asChild ? Slot : 'span';

  return (
    <ElementType
      {...filterDOMProps(otherProps)}
      className={compose(
        css(styles.reset, styles.text, styleProps),
        styles.variants({ size, emphasis, uppercase, capitalize, lowercase, underline, throughline }),
      )}
      ref={ref}
    >
      {children}
    </ElementType>
  );
});
