import React from 'react';
import { SubTitle, Text } from 'common-components/typography';
import { Input, InputNumber } from 'antd';
import { getRules, getValueFromPropertyByKey } from 'views/form-builder/shared/form-builder-utils';
import FormItem from 'antd/es/form/FormItem';
import { FormElementType } from 'views/form-builder/shared/form-enum';
import { FormComponentProps } from 'antd/es/form';
import { IFormElement } from 'views/form-builder/shared/form-interface';

interface INumberInputProps {
  value?: string;
  fieldTitle?: string;
  placeholder?: string;
  caption?: string;
  isRequired?: boolean;
}
export default function NumberInputElement({
  fieldTitle: fieldText,
  placeholder,
  value,
  caption,
  isRequired,
}: INumberInputProps) {
  const fieldTextFormat = (
    <>
      {fieldText}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bold">{fieldTextFormat}</SubTitle>
      <Input
        type="number"
        size="large"
        className="mb-medium mt-x-small bordered border-standard-gray"
        placeholder={placeholder}
        value={value}
        style={{ width: '224px' }}
      />
      <Text color="secondary">{caption}</Text>
    </div>
  );
}

interface IEditNumberInputProps extends FormComponentProps {
  value?: string;
  element: IFormElement;
}

export const EditNumberInput = ({ value, element, form }: IEditNumberInputProps) => {
  const { id, parentId } = element;
  const placeholder = getValueFromPropertyByKey(element.properties.appearance, 'placeholderText');
  const caption = getValueFromPropertyByKey(element.properties.appearance, 'captionText');
  const rules = getRules(element, false);
  const checkMin: boolean = getValueFromPropertyByKey(element.properties.configuration, 'minimum');
  const checkMax: boolean = getValueFromPropertyByKey(element.properties.configuration, 'maximum');
  let fieldText = getValueFromPropertyByKey(element.properties.general, 'fieldTitle');
  const isRequired = getValueFromPropertyByKey(element.properties.general, 'require');

  if (isRequired) {
    fieldText = (
      <>
        {fieldText}
        <Text color="red"> *</Text>
      </>
    );
  }
  if (checkMin) {
    const min = getValueFromPropertyByKey(element.properties.configuration, 'minimumValue');
    rules.push({
      validator: (rule: any, value: string, cb: (msg?: string) => void) => {
        parseFloat(value) < parseFloat(min) ? cb(`Minimum is ${min}`) : cb();
      },
    });
  }
  if (checkMax) {
    const max = getValueFromPropertyByKey(element.properties.configuration, 'maximumValue');
    rules.push({
      validator: (rule: any, value: string, cb: (msg?: string) => void) => {
        parseFloat(value) > parseFloat(max) ? cb(`Maximum is ${max}`) : cb();
      },
    });
  }
  const { getFieldDecorator } = form;
  return (
    <div className="flex-column width-full">
      <SubTitle weight="bold">{fieldText}</SubTitle>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'value'] as never, {
          initialValue: value,
          rules: rules,
        })(
          <InputNumber
            size="large"
            className="mb-medium mt-x-small bordered border-standard-gray"
            placeholder={placeholder}
            style={{ width: '224px' }}
          />,
        )}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'type'] as never, {
          initialValue: FormElementType.NUMBER,
        })(<Input type="hidden" />)}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'parentElementId'] as never, {
          initialValue: parentId,
        })(<Input type="hidden" />)}
      </FormItem>
      <Text color="secondary">{caption}</Text>
    </div>
  );
};
