import postalCodes from 'postal-codes-js';

import type { CountryCode } from '../providers/locale-provider';

/**
 * Validates a postcode based on the given country code.
 *
 * @param postcode - The postcode to validate.
 * @param opts - The options for validation.
 * @param opts.callback - An optional callback function to be called after validation.
 * @param opts.countryCode - The country code for the postcode validation.
 * @param opts.required - Specifies if the postcode is required. Default is false.
 * @param opts.requiredMessage - The error message to be thrown if the postcode is required but not provided.
 * @param opts.invalidMessage - The error message to be thrown if the postcode is invalid.
 * @returns A boolean indicating if the postcode is valid.
 */
export function validatePostcode(
  postcode: string | number | null | undefined,
  opts: {
    callback?: (err?: unknown) => void;
    countryCode: CountryCode;
    required?: boolean;
    requiredMessage?: string;
    invalidMessage?: string;
  },
): boolean {
  const {
    callback,
    countryCode,
    required,
    requiredMessage = 'Postcode is required',
    invalidMessage = 'Postcode is invalid',
  } = opts;

  try {
    const hasValue = postcode && postcode !== '';

    if (!hasValue) {
      if (required) {
        throw new Error(requiredMessage);
      }

      // eslint-disable-next-line callback-return -- return is present
      callback?.();
      return true;
    }

    const result = postalCodes.validate(countryCode, postcode);
    if (typeof result === 'string') {
      throw new Error(invalidMessage);
    }
  } catch (err: unknown) {
    // eslint-disable-next-line callback-return -- return is present
    callback?.(err);
    return false;
  }
  // eslint-disable-next-line callback-return -- return is present
  callback?.();
  return true;
}
