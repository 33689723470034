import { Stack } from '@goodhuman-me/components';
import { Box, Button, Divider, Inline, Modal } from 'design-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { UserPermissionRole } from 'utilities/enum-utils';
import { defaultPermissionState } from 'views/team-v2/utils/constants';
import { Member, PermissionRole } from 'views/team-v2/utils/form-utils';
import PermissionCustomerSectionWithViewAndAccess from './sections/PermissionCustomerSectionWithViewAndAccess';
import PermissionFormBuilderSection from './sections/PermissionFormBuilderSection';
import PermissionPaymentsSection from './sections/PermissionPaymentSection';
import PermissionPlanManagementSection from './sections/PermissionPlanManagementSection';
import PermissionServiceDepartmentSection from './sections/PermissionServiceDepartmentSection';
import PermissionSubscriptionSection from './sections/PermissionSubscriptionSection';
import { PermissionTeamSection } from './sections/permission-team-section';
import PermissionWorkflowSection from './sections/PermissionWorkflowSection';

interface IProps {
  teamMemberList: Member[];
  update: (index: number, memberDetails: Member) => void;
  updateTeamMemberPermissions?: (
    roles: string[],
    permissions: PermissionRole[],
    advancedPermissions: { [key: string]: number },
    serviceDepartmentPermissions: PermissionRole[],
  ) => void;
}

const AdvancedPermissionModal: React.FC<IProps> = ({ updateTeamMemberPermissions, teamMemberList, update }) => {
  const dispatch = useDispatch<IRootDispatch>();

  const { advancedPermissionModalVisible, memberSelected, permissionRolesDepartmentRedux } = useSelector(
    (state: IRootState) => state.teamV2Store,
  );

  const { companyDataLite, portalUser } = useSelector((state: IRootState) => ({
    companyDataLite: state.companyStore.companyDataLite,
    portalUser: state.authStore.portalUser,
  }));

  const [typePermissionPresent, setTypePermissionPresent] = useState<string>('');
  const [permissionRolesState, setPermissionRolesState] = useState<{ [key: string]: number }>(defaultPermissionState);
  const [permissionRolesDepartment, setPermissionRolesDepartment] = useState<PermissionRole[]>([]);

  const isPrimaryOwner = portalUser.permissions.permissionRoles.find(
    (role) => role.permissionRole === UserPermissionRole.PrimaryOwner,
  );

  const changePermissionForEachRole = (permissionRoleId: number, permissionKey: string) => {
    setPermissionRolesState({
      ...permissionRolesState,
      [permissionKey]: permissionRoleId,
    });
  };

  const getPermissionRoleIds = () => {
    const permissionRoleIds = Object.values(permissionRolesState).filter((one) => one !== UserPermissionRole.NoAccess);
    if (!permissionRoleIds.length) {
      return [];
    }
    return permissionRoleIds.map((roleId) => ({
      permissionRole: roleId,
      serviceDepartmentId: null,
      serviceId: null,
    }));
  };

  const handleSetTypePermissionPresent = (type: string) => {
    if (!typePermissionPresent || type !== typePermissionPresent) {
      setTypePermissionPresent(type);
    } else {
      setTypePermissionPresent('');
    }
  };

  const onClose = () => {
    setTypePermissionPresent('');
    setPermissionRolesDepartment([]);
    dispatch.teamV2Store.setPermissionRolesDepartmentRedux([]);
    dispatch.teamV2Store.setAdvancedPermissionModalVisible(false);
  };

  const onSubmit = () => {
    const serviceDepartmentPermissions = permissionRolesDepartment.filter(
      (one) => one.permissionRole !== UserPermissionRole.NoAccess,
    );

    const permissions = [...getPermissionRoleIds(), ...serviceDepartmentPermissions];
    const roles = [];
    if (memberSelected) {
      const index = teamMemberList.findIndex((member) => member.memberId === memberSelected.memberId);
      update(index, {
        ...memberSelected,
        roles,
        permissions,
        advancedPermissions: permissionRolesState,
        serviceDepartmentPermissions: permissionRolesDepartment,
      });
    } else {
      updateTeamMemberPermissions(roles, permissions, permissionRolesState, permissionRolesDepartment);
    }
    onClose();
  };

  useEffect(() => {
    if (memberSelected && memberSelected?.advancedPermissions) {
      setPermissionRolesState(memberSelected.advancedPermissions);
    } else {
      setPermissionRolesState(defaultPermissionState);
    }
    if (memberSelected && memberSelected?.serviceDepartmentPermissions) {
      dispatch.teamV2Store.setPermissionRolesDepartmentRedux(memberSelected.serviceDepartmentPermissions);
    } else {
      dispatch.teamV2Store.setPermissionRolesDepartmentRedux([]);
    }
  }, [memberSelected]);

  useEffect(() => {
    if (permissionRolesDepartmentRedux.length > 0) {
      setPermissionRolesDepartment(permissionRolesDepartmentRedux);
    }
  }, [permissionRolesDepartmentRedux]);
  return (
    <Modal
      maxHeight={602}
      maxWidth={600}
      size="medium"
      header="Advanced permissions"
      onClose={onClose}
      isOpen={advancedPermissionModalVisible}
      footer={
        <Inline justifyContent="flex-end" gap="$space200" marginTop="-6px !important" marginBottom="-6px !important">
          <Button size="large" emphasis="quiet" kind="accent" onPress={onClose}>
            Cancel
          </Button>
          <Button size="large" kind="accent" emphasis="filled" onPress={onSubmit}>
            Apply
          </Button>
        </Inline>
      }
    >
      <Divider
        backgroundColor="#D9D9D9 !important"
        position="absolute"
        left="24px"
        right="7px"
        width="unset !important"
      />
      <Box maxHeight="450px">
        <Stack>
          <PermissionPaymentsSection
            permissionRoleId={permissionRolesState?.payments}
            changePermissionForEachRole={changePermissionForEachRole}
            typePermissionPresent={typePermissionPresent}
            handleSetTypePermissionPresent={handleSetTypePermissionPresent}
          />
          <PermissionTeamSection
            permissionRoleId={permissionRolesState?.team}
            changePermissionForEachRole={changePermissionForEachRole}
            typePermissionPresent={typePermissionPresent}
            handleSetTypePermissionPresent={handleSetTypePermissionPresent}
          />
          <PermissionCustomerSectionWithViewAndAccess
            accessRoleId={permissionRolesState?.customerAccess}
            viewRoleId={permissionRolesState?.customerView}
            changePermissionForEachRole={changePermissionForEachRole}
            typePermissionPresent={typePermissionPresent}
            handleSetTypePermissionPresent={handleSetTypePermissionPresent}
          />
          <PermissionFormBuilderSection
            permissionRoleId={permissionRolesState?.formBuilder}
            changePermissionForEachRole={changePermissionForEachRole}
            typePermissionPresent={typePermissionPresent}
            handleSetTypePermissionPresent={handleSetTypePermissionPresent}
          />
          <PermissionWorkflowSection
            permissionRoleId={permissionRolesState?.workflow}
            changePermissionForEachRole={changePermissionForEachRole}
            typePermissionPresent={typePermissionPresent}
            handleSetTypePermissionPresent={handleSetTypePermissionPresent}
          />
          {companyDataLite && companyDataLite.hasPlanManagement && (
            <PermissionPlanManagementSection
              permissionRoleId={permissionRolesState?.planManagement}
              changePermissionForEachRole={changePermissionForEachRole}
              typePermissionPresent={typePermissionPresent}
              handleSetTypePermissionPresent={handleSetTypePermissionPresent}
            />
          )}
          {companyDataLite && companyDataLite.hasSubscription && isPrimaryOwner && (
            <PermissionSubscriptionSection
              permissionRoleId={permissionRolesState?.subscription}
              changePermissionForEachRole={changePermissionForEachRole}
              typePermissionPresent={typePermissionPresent}
              handleSetTypePermissionPresent={handleSetTypePermissionPresent}
            />
          )}
          <PermissionServiceDepartmentSection
            title="Service departments"
            description="Service configuration and associated booking"
            permissionRolesDepartment={permissionRolesDepartment}
            setPermissionRolesDepartment={setPermissionRolesDepartment}
            typePermissionPresent={typePermissionPresent}
            handleSetTypePermissionPresent={handleSetTypePermissionPresent}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

export default AdvancedPermissionModal;
