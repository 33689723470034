import { Button, Text } from '@good/components';
import React, { PropsWithChildren, ReactNode } from 'react';

type AvailabilityTabProps = PropsWithChildren<{
  isSelected: boolean;
  onClick?: () => void;
}>;

export const AvailabilityTab = ({ isSelected, children, onClick }: AvailabilityTabProps) => {
  return (
    <div className={isSelected ? 'border-b-2 border-ocean' : ''}>
      <Button onPress={onClick} size="md" className="border-none bg-inherit text-gray-dark-1">
        <Text className={`${isSelected ? 'text-ocean' : 'text-gray-dark-1'} font-semibold`}>{children}</Text>
      </Button>
    </div>
  );
};

type AvailabilityTabGroupProps = PropsWithChildren<ReactNode>;

export const AvailabilityTabGroup = ({ children }: AvailabilityTabGroupProps) => {
  return <div className="flex flex-row space-x-3 border-b-2 border-gray-light-1">{children}</div>;
};
