import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const reset: CSS = {
  margin: 0,
  heading: 0,
  overflowWrap: 'break-word',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  textRendering: 'optimizeLegibility',
  color: 'inherit',
};

export const text: CSS = {
  fontFamily: '$primary',
};

export const variants = css({
  variants: {
    size: {
      xlarge: {
        fontSize: '$font300',
        lineHeight: '$leading600',
      },
      large: {
        fontSize: '$font200',
        lineHeight: '$leading500',
      },
      medium: {
        fontSize: '$font100',
        lineHeight: '$leading400',
      },
      small: {
        fontSize: '$font75',
        lineHeight: '$leading400',
      },
      xsmall: {
        fontSize: '$font50',
        lineHeight: '$leading100',
      },
      xxsmall: {
        fontSize: '$font25',
        lineHeight: '$leading100',
      },
    },
    emphasis: {
      bold: {
        fontWeight: '$bold',
      },
      semibold: {
        fontWeight: '$semibold',
      },
      regular: {
        fontWeight: '$normal',
      },
    },
    underline: {
      true: {
        textDecorationLine: 'underline',
      },
    },
    throughline: {
      true: {
        textDecorationLine: 'line-through',
      },
    },
    uppercase: {
      true: {
        textTransform: 'uppercase',
      },
    },
    capitalize: {
      true: {
        textTransform: 'capitalize',
      },
    },
    lowercase: {
      true: {
        textTransform: 'lowercase',
      },
    },
  },
  defaultVariants: {
    size: 'small',
    emphasis: 'regular',
  },
});

export type TextVariants = Stitches.VariantProps<typeof variants>;
