import _ from 'lodash';
import React from 'react';
import moment from 'moment-timezone';
import { IShiftClashConflictShift } from 'src/interfaces/service-interfaces';
import { timeZone } from 'src/interfaces/timezone-type';
import { Text } from 'common-components/typography';

interface IShiftClashTooltipContentProps {
  totalConflicts: number;
  conflicts: IShiftClashConflictShift[];
  timezone: timeZone;
}

const ShiftClashTooltipContent = (props: IShiftClashTooltipContentProps) => {
  const { conflicts, timezone, totalConflicts } = props;
  const NUMBER_OF_CONFLICTS = 3;
  return (
    <div className="p-medium">
      <Text className="block" color="white">
        <b>
          {totalConflicts} shift clash{totalConflicts > 1 ? 'es' : ''}
        </b>
      </Text>
      <Text className="block" color="white">
        This team member is already assigned to the following shifts:
      </Text>
      <ul className="whitespace-nowrap mv-medium">
        {_.map(
          _.take(conflicts, NUMBER_OF_CONFLICTS),
          ({ supportWorkerAttendanceId, serviceName, shiftStartDateTime, shiftEndDateTime, shiftSlotStatus }) => {
            const isSameDate = moment(shiftStartDateTime).isSame(shiftEndDateTime, 'date');
            return (
              <li key={supportWorkerAttendanceId}>
                <Text className="block" color="white">
                  {serviceName}
                </Text>
                <Text className="block" color="white">
                  {`${moment.tz(shiftStartDateTime, timezone).format('ddd DD MMM YYYY')}${
                    !isSameDate ? ` - ${moment.tz(shiftEndDateTime, timezone).format('ddd DD MMM YYYY')}` : ''
                  }
                  `}{' '}
                  ·{' '}
                  {`${moment.tz(shiftStartDateTime, timezone).format('hh:mm a')} -
                  ${moment.tz(shiftEndDateTime, timezone).format('hh:mm a')}`}{' '}
                  ({shiftSlotStatus})
                </Text>
              </li>
            );
          },
        )}
      </ul>
      {totalConflicts > NUMBER_OF_CONFLICTS && (
        <Text className="block" color="white">
          and {totalConflicts - NUMBER_OF_CONFLICTS} more conflicts
        </Text>
      )}
    </div>
  );
};

export default React.memo(ShiftClashTooltipContent);
