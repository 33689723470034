import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import moment from 'moment-timezone';
import { DragDropContext, DragStart, Droppable, ResponderProvided } from 'react-beautiful-dnd';
import { GROUP_NAME_FORM_ELEMENT, ITEMS } from './shared/constants';
import * as H from 'history';
import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import { ICrumb } from 'interfaces/common-interface';

import { Col, Row, Layout, Icon, Button, Skeleton, Affix, notification } from 'antd';
import { Text } from 'common-components/typography';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

import FormElementsList from './components/FormElementsList';
import FormDroppableArea from './components/FormDroppableArea';
import FormPropertiesModal from './components/FormPropertiesModal';
import FormTitle from './components/form-elements/FormTitle/FormTitle';
import FormElementConfigurationPanel from './components/FormElementConfigurationPanel';
import ConfirmSaveFormModal from './components/ConfirmSaveFormModal';
import { IRootDispatch, IRootState, dispatch, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { IFormElement, IFormContent } from './shared/form-interface';
import { FormType, FormStatus } from 'utilities/enum-utils';
import { RouteComponentProps } from 'react-router-dom';
import { ProgressBar } from '@blueprintjs/core';
import PreviewFormModal from './components/PreviewFormModal';
import ResetChangesModal from './components/ResetChangesModal';
import DiscardChangesModal from './components/DiscardChangesModal';
import CancelFormBuilderModal from './components/CancelFormBuilderModal';
import { getAllFormElementsWithChildren } from './shared/form-builder-utils';
import {
  DisplayIntakeFormComponentsTabs,
  IntakeFormComponentsTabs,
  FormElementType,
  UserActionType,
  PropKey,
} from './shared/form-enum';
import './css/new-form-builder-listing.css';

const { Content, Sider } = Layout;

type IFormBuilderUrlParams = {
  formId: string;
};

type IFormBuilderLocationStates = {
  formTitle?: string;
  formType?: FormType;
  formElements?: string;
};

type IFormBuilderProps = {
  history: H.History;
  currentForm: typeof state.formBuilderStore.currentForm;
  setCurrentForm: typeof dispatch.formBuilderStore.setCurrentForm;
  setSelectedElement: typeof dispatch.formBuilderStore.setSelectedElement;
  doCreateForm: typeof dispatch.formBuilderStore.doCreateForm;
  doUpdateForm: typeof dispatch.formBuilderStore.doUpdateForm;
  doGetFormDetails: typeof dispatch.formBuilderStore.doGetFormDetails;
} & RouteComponentProps<IFormBuilderUrlParams, Record<string, unknown>, IFormBuilderLocationStates>;

type IFormBuilderState = {
  isLoading: boolean;
  isLeftPanelCollapsed: boolean;
  formContent: IFormContent;
  isFormPropertiesModalOpen: boolean;
  isRightPanelOpen: boolean;
  selectedItem: any;
  isFormDisabled: boolean;
  isConfirmSaveFormModalOpen: boolean;
  dragItem?: IFormElement;
  dragItemIndex: number;
  isPreviewFormModalOpen: boolean;
  isCancelFormModalOpen: boolean;
  isResetFormModalOpen: boolean;
  isEditingForm: boolean;
  copyFormContent: IFormContent;
  isFormInuse: boolean;
  customerProfileActive: IntakeFormComponentsTabs[];
  checkDraggingPlaceholder: boolean;
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = cloneDeep(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// clone an item from one list to another list.
const copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = cloneDeep(source);
  const destClone = cloneDeep(destination);
  const item: IFormElement = sourceClone[droppableSource.index];
  const newElementId = uuid();
  const objIdChanged = {
    [item.id]: newElementId,
  };
  // Function to make the dragging element id different than the origin element id
  if (item.children?.length) {
    item.children.forEach((child) => {
      child.parentId = newElementId;
      const stack = [child];

      while (stack.length) {
        const currentElement = stack.pop();
        const newId = uuid();
        objIdChanged[currentElement.id] = newId;
        currentElement.id = newId;

        currentElement.children?.forEach((item) => {
          item.parentId = currentElement.id;
          stack.push(item);
        });
      }
    });

    getAllFormElementsWithChildren([item])
      .filter((el) => el.type === FormElementType.CONDITIONAL)
      .forEach((childConditionalElement) => {
        mapNewIdForChildConditionalElement(childConditionalElement, objIdChanged);
      });
  }

  destClone.splice(droppableDestination.index, 0, { ...item, id: newElementId });
  return destClone;
};

const mapNewIdForChildConditionalElement = (element: IFormElement, objIdChanged: { [oldId: string]: string }) => {
  const mapNewId = (value: { id: string }[]) =>
    value.map((v) => ({
      ...v,
      id: objIdChanged[v.id] ?? v.id,
    }));
  for (const configuration of element.properties.configuration) {
    if (configuration.key !== PropKey.TARGET_ELEMENT && configuration.key !== PropKey.AFFECT_TO_ELEMENT) {
      continue;
    }

    configuration.options = mapNewId(configuration.options);
    if (configuration.key === PropKey.TARGET_ELEMENT) {
      configuration.value = mapNewId([{ id: configuration.value }])[0].id;
    } else if (configuration.key === PropKey.AFFECT_TO_ELEMENT) {
      configuration.value = mapNewId(configuration.value.map((id) => ({ id }))).map(({ id }) => id);
    }
  }
};

// Moves an item from one list to another list.
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = cloneDeep(source);
  const destClone = cloneDeep(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const onSaveChildrenFormConfiguration = (
  listElement: IFormElement[],
  element: IFormElement,
  eleIndex: number,
  currentElement: IFormElement,
) => {
  if (element.type === FormElementType.MULTIPLE_ELEMENT) {
    const childStoredEleIndex = listElement[eleIndex].children.findIndex((e) => e.id === currentElement.id);
    listElement[eleIndex].children[childStoredEleIndex] = currentElement;
    if (childStoredEleIndex === -1) {
      element.children.forEach((eleChild, eleChildIndex) => {
        onSaveChildrenFormConfiguration(element.children, eleChild, eleChildIndex, currentElement);
      });
    }
  } else {
    const storedEleIndex = listElement.findIndex((e) => e.id === currentElement.id);
    listElement[storedEleIndex] = currentElement;
  }
};

const keyDrop = uuid();
class FormBuilder extends Component<IFormBuilderProps, IFormBuilderState> {
  private formPropertiesRef: any;
  private historyPushTimeoutRef: any;
  state = {
    formContent: {
      title: { formTitle: '', formDescription: '' },
      elements: [],
      formType: FormType.CUSTOM,
      status: '',
    },
    isLoading: false,
    isLeftPanelCollapsed: false,
    isFormPropertiesModalOpen: false,
    isRightPanelOpen: false,
    selectedItem: null,
    isFormDisabled: false,
    isConfirmSaveFormModalOpen: false,
    dragItem: null,
    dragItemIndex: null,
    isPreviewFormModalOpen: false,
    isCancelFormModalOpen: false,
    isResetFormModalOpen: false,
    isEditingForm: false, //use for /account/forms/edit/:formId
    copyFormContent: null, //use for reset form
    isFormInuse: false, //check if form is in use
    customerProfileActive: [],
    checkDraggingPlaceholder: false,
  };

  private _onLeftPanelCollapse = () => {
    this.setState({ isLeftPanelCollapsed: !this.state.isLeftPanelCollapsed });
  };
  private _onRightPanelCollapse = () => {
    this.setState({ isRightPanelOpen: !this.state.isRightPanelOpen, selectedItem: null });
  };

  private _onDragUpdate = (update) => {
    if (!update.destination) return;
    const isDraggingFormFormElements = Object.values(GROUP_NAME_FORM_ELEMENT || {}).includes(update.source.droppableId);
    const checkDragging =
      Boolean(
        update.source.index < update.destination.index && update.source.index !== 0 && !isDraggingFormFormElements,
      ) ||
      Boolean(
        update.source.index === 0 &&
          this.state.formContent.elements.filter((item) => item?.id === update.draggableId)?.length &&
          !isDraggingFormFormElements,
      );
    this.setState({ checkDraggingPlaceholder: checkDragging });
    this.setState({ dragItemIndex: update?.destination?.index });
  };

  private _onDragEnd = (result) => {
    if (this.state.isFormDisabled) {
      return;
    }
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case destination.droppableId:
        this.setState({
          formContent: {
            ...this.state.formContent,
            elements: reorder(this.state.formContent.elements, source.index, destination.index),
          },
        });
        break;
      case GROUP_NAME_FORM_ELEMENT.DISPLAY_ELEMENTS:
      case GROUP_NAME_FORM_ELEMENT.FORM_FIELDS: {
        const allElements = cloneDeep([...ITEMS].find((item) => item.groupName === source.droppableId).formElements);
        const currentElementsForm = this.state.formContent.elements;
        const newElements = copy(allElements, currentElementsForm, source, destination);
        const selectedItem = cloneDeep(newElements[destination.index]);
        this._onChangeElements(newElements);
        this._onSelectItem(selectedItem);
        break;
      }
      case IntakeFormComponentsTabs.CARE_INFORMATION_TAB_ELEMENTS:
      case IntakeFormComponentsTabs.CONTACTS_TAB_ELEMENTS:
      case IntakeFormComponentsTabs.ENQUIRIES_ELEMENTS:
      case IntakeFormComponentsTabs.FUNDING_TAB_ELEMENTS:
      case IntakeFormComponentsTabs.GENERAL_INFORMATION_TAB_ELEMENTS: {
        const allElements = cloneDeep(
          [...ITEMS]
            .find((item) => item.groupName === GROUP_NAME_FORM_ELEMENT.INTAKE_FORM_COMPONENTS)
            .formElements.filter((formElement) => formElement.tab === source.droppableId),
        );
        const currentElementsForm = this.state.formContent.elements;
        const newElements = copy(allElements, currentElementsForm, source, destination);
        const selectedItem = cloneDeep(newElements[destination.index]);
        this._onChangeElements(newElements);
        this._onSelectItem(selectedItem);
        break;
      }
      default:
        this.setState(
          move(
            this.state.formContent[source.droppableId],
            this.state.formContent[destination.droppableId],
            source,
            destination,
          ),
        );
        break;
    }
  };

  private _onDragStart = (initial: DragStart, provided: ResponderProvided) => {
    switch (initial.source.droppableId) {
      case GROUP_NAME_FORM_ELEMENT.DISPLAY_ELEMENTS:
      case GROUP_NAME_FORM_ELEMENT.FORM_FIELDS: {
        const allElements = [...ITEMS].find((item) => item.groupName === initial.source.droppableId).formElements;
        const item = allElements[initial.source.index];
        this.setState({ dragItem: cloneDeep(item) });
        break;
      }
      case IntakeFormComponentsTabs.CARE_INFORMATION_TAB_ELEMENTS:
      case IntakeFormComponentsTabs.CONTACTS_TAB_ELEMENTS:
      case IntakeFormComponentsTabs.ENQUIRIES_ELEMENTS:
      case IntakeFormComponentsTabs.FUNDING_TAB_ELEMENTS:
      case IntakeFormComponentsTabs.GENERAL_INFORMATION_TAB_ELEMENTS: {
        const allElements = [...ITEMS]
          .find((item) => item.groupName === GROUP_NAME_FORM_ELEMENT.INTAKE_FORM_COMPONENTS)
          .formElements.filter((formElement) => formElement.tab === initial.source.droppableId);
        const item = allElements[initial.source.index];
        this.setState({ dragItem: cloneDeep(item) });
        break;
      }
      default: {
        const item = [...this.state.formContent.elements].find((item) => item.id === initial.draggableId);
        this.setState({ dragItem: cloneDeep(item) });
        break;
      }
    }
  };

  private _setFormPropertiesRef = (ref) => {
    if (ref) {
      this.formPropertiesRef = ref;
    }
  };

  private _openFormPropertiesModal = () => {
    this.setState({ isFormPropertiesModalOpen: true });
  };

  private _closeFormPropertiesModal = () => {
    this.setState({ isFormPropertiesModalOpen: false });
  };

  private _onSaveFormProperties = () => {
    const { form } = this.formPropertiesRef.props;

    let isFormValid = true;

    form.validateFields((err, values) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      const formTitle = form.getFieldValue('form-title');
      const formDescription = form.getFieldValue('form-description');
      this.setState({
        formContent: {
          ...this.state.formContent,
          title: { formTitle, formDescription },
          elements: this.state.formContent.elements,
        },
      });

      this.setState({ isFormPropertiesModalOpen: false });
    }
  };

  private _onSelectItem = (item) => {
    if (!item) {
      this.setState({ isRightPanelOpen: false, selectedItem: null, isFormDisabled: false });
      return;
    }
    if (!this.state.isFormDisabled) {
      if (item.type === FormElementType.CONDITIONAL) {
        this._updateConditionalConfiguration(item);
      }
      this.setState({ isRightPanelOpen: true, selectedItem: item });
    }
  };

  private _onSaveFormConfiguration = (currentElement, isFormValid) => {
    const newElements = cloneDeep(this.state.formContent.elements);
    const storedEleIndex = newElements.findIndex((e) => e.id === currentElement.id);
    newElements[storedEleIndex] = currentElement;

    if (storedEleIndex === -1) {
      newElements.forEach((ele, index) => {
        if (ele.type !== FormElementType.MULTIPLE_ELEMENT) return;
        onSaveChildrenFormConfiguration(newElements, ele, index, currentElement);
      });
    }

    if (isFormValid) {
      this.setState({
        isFormDisabled: false,
        formContent: {
          ...this.state.formContent,
          elements: newElements,
        },
      });
    } else {
      this.setState({
        isFormDisabled: true,
        formContent: {
          ...this.state.formContent,
          elements: newElements,
        },
      });
    }
  };

  private _onChangeElements = (elements) => {
    this.setState({
      formContent: {
        ...this.state.formContent,
        elements,
      },
    });
  };

  private _saveForm = async (status: FormStatus.DRAFT | FormStatus.ACTIVE) => {
    try {
      this.setState({ isLoading: true });
      const { formId } = this.props.match.params;

      const { formContent, isEditingForm } = this.state;
      const elements = cloneDeep(formContent.elements);
      elements.forEach((element) => {
        delete element.label;

        const stack = [element];
        while (stack.length) {
          const parentEl = stack.pop();
          delete element.label;

          if (parentEl.type === FormElementType.CONDITIONAL) {
            const targetElements = element.properties.configuration.find(
              (element) => element.key === PropKey.TARGET_ELEMENT,
            );
            const affectElements = element.properties.configuration.find(
              (element) => element.key === PropKey.AFFECT_TO_ELEMENT,
            );

            if (targetElements?.value) {
              targetElements.options = targetElements.options.filter((opt) => opt.id === targetElements?.value);
            }

            if (affectElements?.value?.length) {
              affectElements.options = affectElements.options.filter((opt) => affectElements?.value.includes(opt.id));
            }
          }

          if (parentEl.children?.length) {
            stack.push(...parentEl.children);
          }
        }
      });
      if (isEditingForm) {
        await this.props.doUpdateForm({
          formId,
          name: formContent.title.formTitle,
          description: formContent.title.formDescription,
          formElements: elements,
          status: status,
        });
      } else {
        await this.props.doCreateForm({
          name: formContent.title.formTitle,
          description: formContent.title.formDescription,
          status: status,
          formElements: elements,
          formType: formContent.formType,
        });
      }

      this.historyPushTimeoutRef = setTimeout(() => this.props.history.push(`/account/forms`), 200);

      const message = isEditingForm ? 'Form updated' : 'Form created!';
      notification.open({
        message: <span className={'text-weight-bolder'}>{message}</span>,
        description: <span>You have successfully {isEditingForm ? 'updated this ' : 'created a '} form.</span>,
      });
    } catch (error) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private _openConfirmSaveFormModal = () => {
    //todo: call api to check if form is inuse
    //then setState isFormInuse
    this.setState({ isConfirmSaveFormModalOpen: true });
  };

  private _openPreviewFormModal = () => {
    this.setState({ isPreviewFormModalOpen: true });
  };

  private _closePreviewFormModal = () => {
    this.setState({ isPreviewFormModalOpen: false });
  };

  private _closeConfirmSaveFormModal = () => {
    this.setState({ isConfirmSaveFormModalOpen: false });
  };

  private _closeActionModal = () => {
    this.setState({ isCancelFormModalOpen: false });
  };

  private _proceedDiscardChanges = () => {
    const { formContent, isEditingForm } = this.state;
    if (isEditingForm && formContent.status === FormStatus.DRAFT) {
      this.props.history.push(`/account/forms`);
      return;
    }

    const { formId } = this.props.match.params;
    this.props.history.push(`/account/form/${formId}`);
  };

  private _cancelFormCreation = () => {
    this.props.history.push(`/account/forms`);
  };

  private _openDiscardFormModal = () => {
    const isProceeding =
      !!this.state.formContent.title.formTitle ||
      !!this.state.formContent.title.formDescription ||
      !!this.state.formContent.elements.length;
    if (isProceeding) {
      this.setState({ isCancelFormModalOpen: isProceeding });
    } else {
      this._closeActionModal();
    }
  };

  private _onResetForm = () => {
    const isChanged = !isEqual(this.state.formContent, this.state.copyFormContent);
    if (isChanged) {
      this.setState({ formContent: this.state.copyFormContent });
    }
    this._closeResetFormModal();
  };

  private _openResetFormModal = () => {
    this.setState({ isResetFormModalOpen: true });
  };

  private _closeResetFormModal = () => {
    this.setState({ isResetFormModalOpen: false });
  };

  private _onOpenCustomerProfileActive = (tab: IntakeFormComponentsTabs) => {
    const customerProfileActive = this.state.customerProfileActive;
    if (customerProfileActive.includes(tab)) {
      const index = customerProfileActive.indexOf(tab);
      if (index > -1) {
        customerProfileActive.splice(index, 1);
      }
    } else {
      customerProfileActive.push(tab);
    }
    this.setState({ customerProfileActive });
  };

  private _updateConditionalConfiguration = (element) => {
    const listElementForConditionalTarget = getAllFormElementsWithChildren(this.state.formContent.elements).filter(
      (el) => el.type !== FormElementType.CONDITIONAL,
    );
    const listAllElementsForTarget = listElementForConditionalTarget.filter((el) => !el.children?.length);

    const targetElements = element.properties.configuration.find((element) => element.key === PropKey.TARGET_ELEMENT);
    const targetOptions = element.properties.configuration.find((element) => element.key === PropKey.TARGET_OPTION);
    const eventConfiguration = element.properties.configuration.find((element) => element.key === PropKey.EVENT);
    const affectToElements = element.properties.configuration.find(
      (element) => element.key === PropKey.AFFECT_TO_ELEMENT,
    );

    targetElements.options = [...listAllElementsForTarget];
    let targetElementSelected: IFormElement;
    if (targetElements.value) {
      targetElementSelected = targetElements.options?.find((el) => el.id === targetElements.value);
      if (targetElementSelected) {
        targetOptions.options =
          targetElementSelected?.properties.configuration?.find((config) => config.key === PropKey.OPTIONS)?.value ||
          [];
        targetOptions.value = targetOptions.options.some(
          (opt) => opt === targetOptions.value || opt?.value === targetOptions.value,
        )
          ? targetOptions.value
          : null;

        eventConfiguration.options = this._getUserActionForElement(targetElementSelected);
        eventConfiguration.value = eventConfiguration.options.some((opt) => opt === eventConfiguration.value)
          ? eventConfiguration.value
          : null;
      }
    }

    const listAllElementsForAffect = [...listElementForConditionalTarget];
    const listElementForConditionalAffect = listAllElementsForAffect.filter(
      (el) => el.id !== targetElementSelected?.id,
    );
    affectToElements.options = listElementForConditionalAffect.map((item) => {
      const conditionalChild = item.children?.some((el) => el.type === FormElementType.CONDITIONAL);
      return { ...item, children: conditionalChild ? [] : item.children };
    });
    affectToElements.value = affectToElements.value.filter((elementId) =>
      listElementForConditionalAffect.some((el) => el.id === elementId),
    );
  };

  // Gets possible operators for element
  private _getUserActionForElement = (element: IFormElement) => {
    if (element.allowedConditionalOperators) {
      return element.allowedConditionalOperators;
    }

    if (
      [
        FormElementType.SINGLE_CHOICE,
        FormElementType.MULTI_CHOICE,
        FormElementType.DROPDOWN,
        FormElementType.DROPDOWN_MULTI_SELECT,
      ].includes(element.type)
    ) {
      return [UserActionType.ON_SELECT];
    } else if (
      [
        FormElementType.ADDRESS_LOOKUP,
        FormElementType.LONG_TEXT,
        FormElementType.SHORT_TEXT,
        FormElementType.NUMBER,
        FormElementType.PHONE_NUMBER,
      ].includes(element.type)
    ) {
      return [UserActionType.ON_FOCUS, UserActionType.ON_ENTER, UserActionType.ON_BLUR];
    } else if (
      [
        FormElementType.PARAGRAPH,
        FormElementType.HEADER,
        FormElementType.FILE_UPLOAD,
        FormElementType.DATE_TIME,
      ].includes(element.type)
    ) {
      return [UserActionType.ON_CLICK];
    } else if ([FormElementType.CHECKBOX].includes(element.type)) {
      return [UserActionType.ON_CHECK];
    } else {
      return [];
    }
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { form } = this.formPropertiesRef.props;
    const formTitle = this.props.location.state?.formTitle || form.getFieldValue('form-title');
    const formType = this.props.location.state?.formType || FormType.CUSTOM;
    const formDescription = form.getFieldValue('form-description');
    const elements = this.props.location.state?.formElements ? JSON.parse(this.props.location.state?.formElements) : [];
    this.setState({
      formContent: {
        title: { formTitle, formDescription },
        elements,
        formType,
      },
    });
    //Set form content from API for copy form or edit form
    const { formId } = this.props.match.params;
    if (formId) {
      await this.props.doGetFormDetails({ formId });

      if (this.props.match.path === '/account/forms/copy/:formId') {
        this.setState({
          formContent: {
            ...this.props.currentForm,
            title: {
              formTitle: `${this.props.currentForm.title.formTitle} - copy`,
              formDescription: this.props.currentForm.title.formDescription,
            },
          },
        });
      } else {
        this.setState({
          formContent: this.props.currentForm,
        });
      }

      if (this.props.match.path === '/account/forms/edit/:formId') {
        this.setState({ isEditingForm: true, copyFormContent: this.props.currentForm });
      }
    }

    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    if (this.historyPushTimeoutRef) {
      clearTimeout(this.historyPushTimeoutRef);
    }
  }

  render() {
    const { isLeftPanelCollapsed, formContent, selectedItem, isFormDisabled, isLoading } = this.state;
    const { history } = this.props;
    const crumbs: ICrumb[] = [
      {
        title: 'Account Management',
        target: '/account/landing',
      },
      {
        title: 'Forms',
        target: '/account/forms',
      },
      {
        title: formContent ? formContent.title.formTitle : 'New Form',
      },
    ];

    if (isEmpty(formContent)) {
      return (
        <>
          <div className='item-container'>
            <ProgressBar />
          </div>
          <Skeleton loading={this.state.isLoading} />
        </>
      );
    } else
      return (
        <div className='form-builder'>
          <ResetChangesModal
            isProceedOpen={this.state.isResetFormModalOpen}
            onReset={this._onResetForm}
            closeProceedModal={this._closeResetFormModal}
          />

          <DiscardChangesModal
            isOpen={this.state.isCancelFormModalOpen && this.state.isEditingForm}
            onCancel={this._closeActionModal}
            onProceed={this._proceedDiscardChanges}
          />

          <CancelFormBuilderModal
            isProceedOpen={this.state.isCancelFormModalOpen && !this.state.isEditingForm}
            onBack={this._closeActionModal}
            onCancel={this._cancelFormCreation}
          />

          <ConfirmSaveFormModal
            isOpen={this.state.isConfirmSaveFormModalOpen}
            onCancel={this._closeConfirmSaveFormModal}
            onSave={() => this._saveForm(FormStatus.ACTIVE)}
            isEditingForm={this.state.isEditingForm}
            isInuse={this.state.isFormInuse}
          />
          <PreviewFormModal
            isOpen={this.state.isPreviewFormModalOpen}
            onClose={this._closePreviewFormModal}
            formContent={this.state.formContent}
            timezone={moment.tz.guess()}
          />
          <BreadcrumbNav
            history={history}
            icon='home'
            theme='filled'
            className='ph-small'
            crumbs={crumbs}
            isBordered={false}
          />
          <DragDropContext
            onDragEnd={this._onDragEnd}
            onDragStart={this._onDragStart}
            onDragUpdate={this._onDragUpdate}
          >
            <Layout className='bg-tertiary'>
              {/* form elements */}
              <Affix className='form-builder__custom-affix'>
                <Sider
                  width={320}
                  className='bg-quaternary bordered-right bordered-bottom customScrollDivFull custom-scrollbar'
                  collapsed={isLeftPanelCollapsed}
                >
                  <Text
                    className={`${
                      isLeftPanelCollapsed ? 'justify-center' : 'justify-between'
                    } text-size-large align-center p-medium text-overflow-ellipsis mv-none flex cursor-pointer`}
                    weight='bold'
                    onClick={this._onLeftPanelCollapse}
                  >
                    {!isLeftPanelCollapsed && <span>Form Elements</span>}
                    <Icon type={isLeftPanelCollapsed ? 'double-right' : 'double-left'} />
                  </Text>
                  {!isLeftPanelCollapsed && (
                    <Text size='small' className='p-medium text-color-secondary block'>
                      Drag any of the element below to the form.
                    </Text>
                  )}
                  {!isLeftPanelCollapsed &&
                    ITEMS &&
                    ITEMS.filter(
                      (groupElement) =>
                        this.state.formContent.formType === FormType.INTAKE ||
                        groupElement.groupName !== GROUP_NAME_FORM_ELEMENT.INTAKE_FORM_COMPONENTS,
                    ).map((groupElement, index) => (
                      <React.Fragment key={index}>
                        <Text
                          size='small'
                          className='text-uppercase text-weight-bold p-medium text-color-secondary block'
                        >
                          {groupElement.groupName}
                        </Text>
                        <Row>
                          <Col>
                            {groupElement.groupName === GROUP_NAME_FORM_ELEMENT.INTAKE_FORM_COMPONENTS ? (
                              Object.values(IntakeFormComponentsTabs).map((value) => {
                                const showSubElements = this.state.customerProfileActive.includes(value);

                                return (
                                  <div key={value} style={{ marginBottom: '20px' }}>
                                    <div
                                      className='align-center ph-large pv-12 rounded-big bg-tertiary text-size-large flex cursor-pointer justify-between'
                                      style={{
                                        margin: '0 16px 12px 16px',
                                        border: `2px solid ${showSubElements ? '#206E8C' : '#EEEEEE'}`,
                                      }}
                                      onClick={() => this._onOpenCustomerProfileActive(value)}
                                    >
                                      {DisplayIntakeFormComponentsTabs[value]}
                                      <Icon type={showSubElements ? 'minus' : 'plus'} className='text-color-black' />
                                    </div>

                                    {showSubElements && (
                                      <Droppable droppableId={value} isDropDisabled={true}>
                                        {(provided) => (
                                          <FormElementsList
                                            items={groupElement.formElements.filter(
                                              (element) => element?.tab === value,
                                            )}
                                            provided={provided}
                                            addedListElements={formContent.elements}
                                          />
                                        )}
                                      </Droppable>
                                    )}
                                  </div>
                                );
                              })
                            ) : (
                              <Droppable droppableId={groupElement.groupName} isDropDisabled={true}>
                                {(provided) => (
                                  <FormElementsList
                                    items={groupElement.formElements}
                                    provided={provided}
                                    addedListElements={formContent.elements}
                                  />
                                )}
                              </Droppable>
                            )}
                          </Col>
                        </Row>
                      </React.Fragment>
                    ))}
                </Sider>
              </Affix>

              {/* main */}
              <Layout>
                <Content className='pv-large mh-medium'>
                  <Row className='mb-large' type='flex' align='middle' justify='start'>
                    <Button
                      type='link'
                      className={`mr-medium text-weight-bold ${!isFormDisabled && 'text-color-info-blue'}`}
                      disabled={isFormDisabled}
                      onClick={this._openPreviewFormModal}
                    >
                      Preview form
                    </Button>
                    {this.state.isEditingForm ? (
                      <>
                        <Button
                          type='link'
                          disabled={isFormDisabled}
                          className={`text-weight-bold ${!isFormDisabled && 'text-color-info-blue'}`}
                          onClick={this._openResetFormModal}
                        >
                          Reset changes
                        </Button>
                        {formContent.status === FormStatus.DRAFT && (
                          <Button
                            type='link'
                            disabled={isFormDisabled}
                            className={`ml-medium text-weight-bold ${!isFormDisabled && 'text-color-info-blue'}`}
                            loading={isLoading}
                            onClick={() => this._saveForm(FormStatus.DRAFT)}
                          >
                            Save as draft
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        type='link'
                        disabled={isFormDisabled}
                        className={`text-weight-bold ${!isFormDisabled && 'text-color-info-blue'}`}
                        onClick={() => this._saveForm(FormStatus.DRAFT)}
                        loading={isLoading}
                      >
                        Save as draft
                      </Button>
                    )}
                  </Row>
                  <div className='p-large custom-scrollbar droppable-area-container rounded'>
                    <FormPropertiesModal
                      title={formContent.title}
                      wrappedComponentRef={this._setFormPropertiesRef}
                      isOpen={this.state.isFormPropertiesModalOpen}
                      onClose={this._closeFormPropertiesModal}
                      onSave={this._onSaveFormProperties}
                    />
                    <FormTitle title={formContent.title} openFormPropertiesModal={this._openFormPropertiesModal} />
                    <>
                      <Droppable key={keyDrop} droppableId={keyDrop} isDropDisabled={this.state.isFormDisabled}>
                        {(provided, snapshot) => (
                          <FormDroppableArea
                            key={keyDrop}
                            list={formContent.elements}
                            provided={provided}
                            snapshot={snapshot}
                            onSelectItem={this._onSelectItem}
                            selectedItem={this.state.selectedItem}
                            setElements={this._onChangeElements}
                            isFormDisabled={this.state.isFormDisabled}
                            dragItem={this.state.dragItem}
                            dragItemIndex={this.state.dragItemIndex}
                            checkDraggingPlaceholder={this.state.checkDraggingPlaceholder}
                            updateConditionalConfiguration={this._updateConditionalConfiguration}
                          />
                        )}
                      </Droppable>
                    </>
                  </div>
                  <Row className='mt-large' type='flex' align='middle' justify='end'>
                    <SecondaryButton className='mr-medium' size='large' onClick={this._openDiscardFormModal}>
                      {!this.state.isEditingForm || formContent.status === FormStatus.DRAFT
                        ? 'Cancel'
                        : 'Discard Changes'}
                    </SecondaryButton>
                    <PrimaryButton
                      size='large'
                      disabled={this.state.isFormDisabled}
                      onClick={this._openConfirmSaveFormModal}
                    >
                      {!this.state.isEditingForm || formContent.status === FormStatus.DRAFT
                        ? 'Activate form'
                        : 'Save changes'}
                    </PrimaryButton>
                  </Row>
                </Content>
              </Layout>

              {/* elements properties */}
              <Affix className='form-builder__custom-affix'>
                <Sider width={320} className='bg-tertiary customScrollDivFull custom-scrollbar'>
                  {this.state.isRightPanelOpen && selectedItem && (
                    <FormElementConfigurationPanel
                      selectedItem={selectedItem}
                      onRightPanelCollapse={this._onRightPanelCollapse}
                      save={this._onSaveFormConfiguration}
                    />
                  )}
                </Sider>
              </Affix>
            </Layout>
          </DragDropContext>
        </div>
      );
  }
}

const mapState = (state: IRootState) => ({
  currentForm: state.formBuilderStore.currentForm,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateForm: dispatch.formBuilderStore.doCreateForm,
  doUpdateForm: dispatch.formBuilderStore.doUpdateForm,
  doGetFormDetails: dispatch.formBuilderStore.doGetFormDetails,
});

export default connect(mapState, mapDispatch)(FormBuilder);
