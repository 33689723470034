import { LabelProps, Label } from '@goodhuman-me/components';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailabilityUnavailabilityRequestStatus as UnavailabilityStatus } from 'utilities/enum-utils';

export type UnavailabilityLabelProps = {
  status: UnavailabilityStatus;
} & Omit<LabelProps, 'tone'>;

/**
 * @name
 * UnavailabilityLabel
 *
 * @example
 * <Unavailability status="APPROVED" />
 */
export const UnavailabilityLabel = forwardRef<HTMLDivElement, UnavailabilityLabelProps>(function (props, ref) {
  const { status, ...otherProps } = props;

  const { t } = useTranslation('', { keyPrefix: 'availability.unavailabilityStatus' });
  const labelOptions: Record<UnavailabilityStatus, Pick<LabelProps, 'children' | 'emphasis' | 'tone'>> = {
    [UnavailabilityStatus.PENDING_APPROVAL]: {
      emphasis: 'light',
      tone: 'notice',
      children: t(UnavailabilityStatus.PENDING_APPROVAL),
    },
    [UnavailabilityStatus.APPROVED]: {
      emphasis: 'light',
      tone: 'positive',
      children: t(UnavailabilityStatus.APPROVED),
    },
    [UnavailabilityStatus.DECLINED]: {
      emphasis: 'light',
      tone: 'critical',
      children: t(UnavailabilityStatus.DECLINED),
    },
    [UnavailabilityStatus.REQUEST_CANCELLATION]: {
      emphasis: 'light',
      tone: 'notice',
      children: t(UnavailabilityStatus.REQUEST_CANCELLATION),
    },
    [UnavailabilityStatus.COMPLETED]: {
      emphasis: 'light',
      tone: 'lilac',
      children: t(UnavailabilityStatus.COMPLETED),
    },
    [UnavailabilityStatus.CANCELLED]: {
      emphasis: 'light',
      tone: 'critical',
      children: t(UnavailabilityStatus.CANCELLED),
    },
  } as const;

  return <Label ref={ref} {...otherProps} {...labelOptions[status]} />;
});
