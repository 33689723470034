import React from 'react';
import { useTranslation } from 'react-i18next';

import { SideNav, TabContent } from 'views/sil-service/components';
import { GeneralInformation } from './general-information';
import { Gallery } from './gallery';
import { PropertyAndRooms } from './property-and-rooms';

export const DetailsTab = () => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceDetailsTabs' });
  const tabs: TabContent[] = [
    { id: 1, title: `${t('general.tabName')}`, content: <GeneralInformation /> },
    { id: 2, title: `${t('gallery.tabName')}`, content: <Gallery /> },
    { id: 3, title: `${t('propertyAndRooms.tabName')}`, content: <PropertyAndRooms /> },
  ];

  return <SideNav tabs={tabs} />;
};
