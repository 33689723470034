import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { Avatar, Col, Divider, Form, Row, Select, notification } from 'antd';
import { Label } from '@goodhuman-me/components';

import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Text, SubTitle, FieldLabel } from 'common-components/typography';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import TimeInput from 'common-components/time-input/TimeInput';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { ISession } from 'interfaces/session-interfaces';
import { IRootDispatch } from 'stores/rematch/root-store';
import { useFetchWorkTypes } from 'stores/hooks/query-hooks/use-query-fetch-work-types-per-category';
import CommonUtils from 'utilities/common-utils';
import { WorkTypeCategory, WorkTypeState } from 'utilities/enum-utils';

type EditLeaveProps = {
  shiftSlot: IShiftSlot;
  session: ISession;
  setCanCancel?: (canCancel: boolean) => void;
  onCancel?: () => void;
  onFail?: () => void;
  onSuccess?: (() => Promise<void>) | (() => void);
  cancelText?: string;
};

const EditLeaveForm = (props: EditLeaveProps) => {
  const { session, shiftSlot, onCancel, onSuccess, onFail, setCanCancel, cancelText } = props;

  const dispatch = useDispatch<IRootDispatch>();
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [leaveId, setLeaveId] = useState<string | undefined>(undefined);

  const [startDateTime, setStartDateTime] = useState<moment.Moment>(
    moment.tz(moment(shiftSlot?.shiftStartDateTime).format('YYYY-MM-DD HH:mm'), session.timezone),
  );
  const [endDateTime, setEndDateTime] = useState<moment.Moment>(
    moment.tz(moment(shiftSlot?.shiftEndDateTime).format('YYYY-MM-DD HH:mm'), session.timezone),
  );

  useEffect(() => {
    if (setCanCancel) {
      setCanCancel(!isSubmitting);
    }
  }, [isSubmitting, setCanCancel]);

  useEffect(() => {
    if (shiftSlot) {
      setStartDateTime(moment(moment.tz(shiftSlot.shiftStartDateTime, session.timezone).format('YYYY-MM-DD HH:mm')));
      setEndDateTime(moment(moment.tz(shiftSlot.shiftEndDateTime, session.timezone).format('YYYY-MM-DD HH:mm')));
      setLeaveId(shiftSlot.workTypeId);
    }
  }, [shiftSlot]);

  const { data: leaveData, isLoading: leaveLoading } = useFetchWorkTypes({
    workTypeCategory: WorkTypeCategory.Leave,
  });
  const initialLeaveType = leaveData?.find((workType) => workType.workTypeId === shiftSlot.workTypeId) ?? undefined;
  const serviceId = session.serviceId;
  const serviceDateTimeId = session.serviceDateTimeId;
  const supportWorkerAttendanceId = shiftSlot?.supportWorkerAttendanceId;
  const timezone = session.timezone;

  const handleStartDateTime = (value) => {
    setStartDateTime(CommonUtils.formatCeilingDateTime(value));
    if (endDateTime.isSameOrBefore(value)) {
      setHasValidationError(true);
    } else {
      setHasValidationError(false);
    }
  };

  const handleEndDateTime = (value) => {
    setEndDateTime(CommonUtils.formatCeilingDateTime(value));
    if (startDateTime.isSameOrAfter(value)) {
      setHasValidationError(true);
    } else {
      setHasValidationError(false);
    }
  };

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      await updateShiftAttendance();
      await onSuccess?.();
    } catch (error) {
      onFail?.();
      notification.error({
        message: 'Error',
        description: 'There was an error updating the shift times.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateShiftAttendance = async () => {
    if (!leaveId) {
      throw new Error('Leave type is required');
    }

    const request = {
      serviceId,
      serviceDateTimeId,
      supportWorkerAttendanceId,
      workTypeId: leaveId,
      startDateTime: moment.tz(moment(startDateTime).format('YYYY-MM-DD HH:mm'), session.timezone),
      endDateTime: moment.tz(moment(endDateTime).format('YYYY-MM-DD HH:mm'), session.timezone),
    };

    await dispatch.teamStore.doUpdateWorkTypes({
      ...request,
      timezone,
    });
    await dispatch.groupServiceStore.doEditShiftTimes({
      ...request,
      keepTeamMember: true,
    });
  };

  function Duration() {
    const hoursDiff = endDateTime.diff(startDateTime, 'hours');
    const duration = hoursDiff === 1 ? 'hour' : 'hours ';
    return (
      <>
        <FieldLabel text={'DURATION'} />
        <Text>
          <b>{hoursDiff}</b> {duration}
        </Text>
      </>
    );
  }

  if (!leaveData) {
    return <></>;
  }

  return (
    <>
      <Row>
        <Text>Leave will show up on team member&apos;s timesheet once it&apos;s approved</Text>
      </Row>
      <Row>
        <Col span={10}>
          <div className="align-left" style={{ marginTop: '15px' }}>
            <FieldLabel text={'TEAM MEMBER'} />
            <Avatar icon="user" className="mr-small" src={shiftSlot?.attachmentUrl} shape="square" />
            <Text size="medium">{`${shiftSlot?.firstName} ${shiftSlot?.lastName}`}</Text>
          </div>
        </Col>
      </Row>
      <div style={{ marginTop: '20px', marginBottom: '-20px' }}>
        <Row>
          <Form wrapperCol={{ span: 10 }} layout="vertical">
            <Form.Item>
              <FieldLabel text={'LEAVE TYPE'} />
              <Select defaultValue={initialLeaveType?.workTypeId} onChange={setLeaveId}>
                {leaveLoading ? (
                  <SpinningLoader size={20} message={'Fetching leave...'} />
                ) : (
                  leaveData
                    .filter((item) => item.state === 'ACTIVE' || item.workTypeId === initialLeaveType?.workTypeId)
                    .map((item) => (
                      <Select.Option
                        key={item.workTypeId}
                        value={item.workTypeId}
                        className={item.state === 'INACTIVE' ? 'text-weak' : ''}
                      >
                        <div className="align-center flex" style={{ gap: 2 }}>
                          {item.name}
                          {item.state === 'INACTIVE' && (
                            <div className="-mt-1 ml-1 inline align-middle">
                              <Label size="xsmall" emphasis="outlined" tone="neutral">
                                {WorkTypeState.INACTIVE}
                              </Label>
                            </div>
                          )}
                        </div>
                      </Select.Option>
                    ))
                )}
              </Select>
            </Form.Item>
          </Form>
        </Row>
      </div>
      <Divider />
      <Row>
        <Row>
          <div className="flex">
            <Col span={6}>
              <SubTitle>START DATE</SubTitle>
              <div style={{ width: '150px' }}>
                <DatePicker
                  selected={startDateTime.toDate()}
                  isClearable={false}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(event) => handleStartDateTime(event)}
                  className="gh-datepicker mr-medium rounded"
                  calendarClassName="gh-datepicker-calendar"
                />
              </div>
            </Col>
            <Col span={6}>
              <div style={{ width: '150px' }}>
                <SubTitle>START TIME</SubTitle>
                <TimeInput size="large" value={startDateTime} onChange={(event) => handleStartDateTime(event)} />
              </div>
              {hasValidationError && <Text color="red">Start time cannot be same or after end time</Text>}
            </Col>
            <Col span={6}>
              <SubTitle>END DATE</SubTitle>
              <div style={{ width: '150px' }}>
                <DatePicker
                  className="gh-datepicker mr-medium rounded"
                  selected={endDateTime.toDate()}
                  isClearable={false}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(event) => handleEndDateTime(event)}
                  calendarClassName="gh-datepicker-calendar"
                />
              </div>
            </Col>
            <Col span={6}>
              <div style={{ width: '150px' }}>
                <SubTitle>END TIME</SubTitle>
                <TimeInput size="large" value={endDateTime} onChange={(event) => handleEndDateTime(event)} />
              </div>
            </Col>
          </div>
        </Row>
      </Row>
      <div style={{ marginTop: '20px' }}>
        <Row>
          <Duration />
        </Row>
      </div>
      <div className="mt-x3-large flex justify-end">
        {onCancel && (
          <SecondaryButton size="large" className="mr-medium" onClick={onCancel}>
            {cancelText ?? 'Cancel'}
          </SecondaryButton>
        )}
        <PrimaryButton size="large" onClick={handleSave} disabled={hasValidationError} loading={isSubmitting}>
          Save Changes
        </PrimaryButton>
      </div>
    </>
  );
};

export default React.memo(EditLeaveForm);
