import { Icon } from 'antd';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

const preventAction = `-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;`;

export const StyledDatePicker = styled(DatePicker)`
  width: 232px;
  padding-top: 7px;
  padding-bottom: 7px;
`;

export const PreventHighlightTextIcon = styled(Icon)`
  ${preventAction}
`;
