import { Col } from 'antd';
import AmericanExpress from 'assets/logo/subscription/amex.svg';
import DefaultCard from 'assets/logo/subscription/DefaultCard.png';
import Jcb from 'assets/logo/subscription/jcb.svg';
import MasterCard from 'assets/logo/subscription/mastercard.svg';
import UnionPay from 'assets/logo/subscription/unionpay.svg';
import Visa from 'assets/logo/subscription/visa.svg';
import React from 'react';
import { SUPPORT_CARDS } from 'views/account-management/subscription-management/utils/constants';

interface ILogoCardProps {
  brand: string;
}

const LogoCardItem = (props: ILogoCardProps) => {
  let logoCard = undefined;

  switch (props.brand) {
    case SUPPORT_CARDS.AMERICAN_EXPRESS: {
      logoCard = AmericanExpress;
      break;
    }
    case SUPPORT_CARDS.MASTER_CARD: {
      logoCard = MasterCard;
      break;
    }
    case SUPPORT_CARDS.JCB: {
      logoCard = Jcb;
      break;
    }
    case SUPPORT_CARDS.UNION_PAY: {
      logoCard = UnionPay;
      break;
    }
    case SUPPORT_CARDS.VISA: {
      logoCard = Visa;
      break;
    }
    default: {
      logoCard = DefaultCard;
    }
  }

  return (
    <Col span={2}>
      <img src={logoCard} alt={props.brand} style={{ width: '75%' }} />
    </Col>
  );
};

export default LogoCardItem;
