import React, { useEffect, useState, useRef } from 'react';
import { z } from 'zod';
import { Text } from '@good/ui/core';
import { zodResolver } from '@hookform/resolvers/zod';
import { parseDate } from '@internationalized/date';

import { ZodDateValue } from 'utilities/date-utils';
import { useUserTimezone } from 'utilities/react-hooks/use-user-timezone';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { useInlineForm } from './inline-form';
import { ZodAddress, State } from '../address-input';

const schema = z.object({
  name: z
    .string()
    .nonempty({ message: 'A name is required' })
    .max(10, { message: 'Name cannot be longer than 10 characters' }),
  isNickname: z.boolean(),
  favoriteSongLyrics: z.string().max(256, { message: 'Lyric cannot be longer than 256 characters' }),
  luckyNumber: z
    .number()
    .min(0)
    .max(10)
    .nullable()
    .refine((data) => data !== null, { message: 'A lucky number is required' }),
  favoriteCitrus: z
    .string()
    .nullable()
    .refine((data) => data !== null, { message: 'A citrus is required' }),
  niceRestaurant: ZodAddress,
  birthday: ZodDateValue,
  wearsGlasses: z.boolean(),
});

type Schema = z.infer<typeof schema>;

const SubmittedValue = ({ values }: { values: Schema | undefined }) => (
  <div className='flex flex-col gap-2 p-2 bg-gray-light-2 rounded'>
    <Text className='font-bold'>Submitted Value</Text>
    <code>{JSON.stringify(values, undefined, 1)}</code>
  </div>
);

export const ExampleInlineForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState<Schema | undefined>(undefined);
  const user = usePortalUser();
  const timezone = useUserTimezone(user);
  const currentDate = useRef(new Date());
  const InlineForm = useInlineForm<Schema>({
    defaultValues: {
      name: 'Pluto',
      isNickname: false,
      birthday: parseDate('1990-01-01'),
      luckyNumber: null,
      favoriteCitrus: 'Orange',
      niceRestaurant: {
        fullAddress: '123 Shrimp Street, Shrimpville VIC 3000',
        streetAddress1: '123 Shrimp Street',
        streetAddress2: '',
        locality: 'Shrimpville',
        state: State.VIC,
        postcode: '3000',
        geoLat: 0,
        geoLng: 0,
      },
      wearsGlasses: true,
    },
    values: formValues,
    resolver: zodResolver(schema),
    mode: 'onBlur',
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const onSubmit = async (values: Schema) => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setFormValues(values);
  };

  return (
    <>
      <InlineForm title='Service settings' onSubmit={onSubmit} maxWidth={546} loading={isLoading} timezone={timezone}>
        <InlineForm.StaticField label='Current date' value={currentDate.current} />
        <InlineForm.TextField label='Name' name='name' description='What is your name?' placeholder='Enter name here'>
          <InlineForm.Switch name='isNickname' label='Is this a nickname?' />
        </InlineForm.TextField>
        <InlineForm.DatePickerField
          label='Birthday'
          name='birthday'
          description='When is your birthday?'
          maxInputWidth={200}
        />
        <InlineForm.TextareaField
          label='Favourite song lyric'
          name='favoriteSongLyrics'
          description='Enter the lyrics to your favourite song.'
          rows={5}
          showOptional
          emptyValue='No lyrics provided.'
          placeholder={`Shirley, Shirley Bo-ber-ley\nBo-na-na fanna Fo-fer-ley\nFee-fi-mo-mer-ley\nShirley!`}
        />
        <InlineForm.NumberField
          label='Lucky number'
          name='luckyNumber'
          description='What is your luckiest number between 0 and 10?'
          placeholder=''
          min={0}
          max={10}
          step={1}
          maxInputWidth={100}
          emptyValue='No lucky number provided.'
        />
        <InlineForm.AddressField
          label='Nice Restaurant'
          name='niceRestaurant'
          description='Where is a nice restaurant?'
        />
        <InlineForm.SelectField
          label='Favourite citrus'
          description='Which citrus fruit is the best?'
          name='favoriteCitrus'
          placeholder='Pick a citrus fruit'
          data={['Lemon', 'Lime', 'Orange', 'Mandarin', 'Grapefruit']}
          allowDeselect
        />
        <InlineForm.SwitchField
          label='Glasses'
          description='Do you wear glasses?'
          switchOptions={{
            label: 'I wear glasses',
            checkedValue: 'Yes, I do wear glasses',
            uncheckedValue: 'No, I do not wear glasses',
          }}
          name='wearsGlasses'
        />
      </InlineForm>
      <SubmittedValue values={formValues} />
    </>
  );
};
