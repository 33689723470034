import { DeepPartial } from 'react-hook-form';
import { EditRatiosSchema } from '../../schema';
import { ISessionTimesParsed, CustomRatioErrors } from '../../types';

type CustomRatio = {
  teamMemberCustomerRatio?: string | undefined;
  isExactTime?: boolean | undefined;
  customRatio?:
    | {
        startDateTime?: Date | undefined;
        endDateTime?: Date | undefined;
      }
    | undefined;
  durationRatio?:
    | {
        duration?: number | undefined;
        unit?: 'minutes' | 'hours';
      }
    | undefined;
};

const isDefined = <T>(value: T | undefined): value is T => Boolean(value);

export function getCustomRatioErrors(
  sessionTimes: ISessionTimesParsed,
  values: DeepPartial<EditRatiosSchema>,
): CustomRatioErrors {
  const isExactTime = (ratio: CustomRatio | undefined) => ratio?.isExactTime;
  const customRatios: CustomRatio[] = (values?.ratios?.filter(isExactTime) ?? []).filter(isDefined);

  const sortedCustomRatios = customRatios
    ?.map(({ customRatio }, index) => {
      if (!customRatio?.startDateTime || !customRatio?.endDateTime) {
        return undefined;
      }
      return {
        id: index,
        startDateTime: customRatio?.startDateTime,
        endDateTime: customRatio?.endDateTime,
      };
    })
    .filter(isDefined);

  if (!sortedCustomRatios) {
    return {};
  }

  sortedCustomRatios.sort((a, b) => a.startDateTime.getTime() - b.startDateTime.getTime());

  return sortedCustomRatios.reduce((acc, curr) => {
    const startOverlap = sortedCustomRatios
      .filter((time) => time.startDateTime < curr.startDateTime && time.endDateTime > curr.startDateTime)
      .map((time) => time.id);

    const endOverlap = sortedCustomRatios
      .filter((time) => time.startDateTime < curr.endDateTime && time.endDateTime > curr.endDateTime)
      .map((time) => time.id);

    const isBeforeSessionStart = curr.startDateTime < new Date(sessionTimes.startDateTime);
    const isAfterSessionEnd = curr.endDateTime > new Date(sessionTimes.endDateTime);

    const isSameAsOtherTime = sortedCustomRatios
      .filter(
        (time) =>
          time !== curr &&
          time.startDateTime.getTime() === curr.startDateTime.getTime() &&
          time.endDateTime.getTime() === curr.endDateTime.getTime(),
      )
      .map((time) => time.id);

    const isStartAfterEndTime = curr.startDateTime.getTime() > curr.endDateTime.getTime();
    const isEndBeforeStartTime = curr.endDateTime.getTime() < curr.startDateTime.getTime();

    const isValid =
      !startOverlap?.length &&
      !endOverlap?.length &&
      !isBeforeSessionStart &&
      !isAfterSessionEnd &&
      !isSameAsOtherTime?.length &&
      !isStartAfterEndTime &&
      !isEndBeforeStartTime;

    return {
      ...acc,
      [curr.id]: {
        isValid,
        startOverlap,
        endOverlap,
        isBeforeSessionStart,
        isAfterSessionEnd,
        isSameAsOtherTime,
        isStartAfterEndTime,
        isEndBeforeStartTime,
      },
    };
  }, {});
}
