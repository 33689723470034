import React, { Component } from 'react';
import { SubTitle, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';
import Search from 'antd/es/input/Search';
import PublishWorkerRow from 'views/bookings/components/PublishWorkerRow';

interface IPublishBookingSearchWorkerModalProps {
  isOpen: boolean;
  selectedWorkers: Array<any>;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  changeIsIncluded: (event, supportWorkerId, workerData) => void;
  onCloseSearchModal: () => void;
  doFetchWorkerSearchList: typeof dispatch.bookingsStore.doFetchWorkerSearchList;
  isCreateNewBooking?: boolean;
}

interface IPublishBookingSearchWorkerModalState {
  isSearching: boolean;
  searchedList: Array<null>;
  searchInputValue: string;
  searchString: string;
  workerNotFound: boolean;
}

class PublishBookingSearchWorkerModal extends Component<
  IPublishBookingSearchWorkerModalProps,
  IPublishBookingSearchWorkerModalState
> {
  state = {
    isSearching: false,
    searchedList: [],
    searchInputValue: '',
    searchString: '',
    workerNotFound: false,
  };

  private _searchText = async (txt) => {
    const { selectedBookingItem } = this.props;
    const searchedList: any = !_.isEmpty(txt)
      ? await this.props.doFetchWorkerSearchList({
          startDateTime: selectedBookingItem.startDateTime
            ? selectedBookingItem.startDateTime
            : selectedBookingItem.bookStartTime,
          endDateTime: selectedBookingItem.endDateTime
            ? selectedBookingItem.endDateTime
            : selectedBookingItem.bookEndTime,
          address: selectedBookingItem.address ? selectedBookingItem.address : selectedBookingItem.bookLocation,
          customerUserIds: !this.props.isCreateNewBooking
            ? [selectedBookingItem.bookerUserId]
            : [selectedBookingItem.selectedCustomer.userId],
          serviceId: !this.props.isCreateNewBooking
            ? selectedBookingItem.serviceId
            : selectedBookingItem.selectedServiceId,
          searchString: txt,
        })
      : [];
    this.setState({
      isSearching: false,
      searchString: txt,
      searchedList,
      workerNotFound: searchedList.length === 0 && !_.isEmpty(txt),
    });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true, searchInputValue: e.target.value });
    this._debounceSearch(e.target.value);
  };

  private _onCloseSearchModal = () => {
    const { onCloseSearchModal } = this.props;
    this.setState({ searchInputValue: '', searchString: '', workerNotFound: false, searchedList: [] });
    onCloseSearchModal();
  };

  render() {
    const { isOpen } = this.props;
    const { isSearching, workerNotFound, searchInputValue } = this.state;

    const fullSearchList = _.map(this.state.searchedList, (worker) => {
      return _.find(
        this.props.selectedWorkers,
        (selectedWorker) => selectedWorker.supportWorkerId === worker.supportWorkerId && selectedWorker.isIncluded,
      )
        ? { ...worker, isIncluded: true }
        : { ...worker, isIncluded: false };
    });

    return (
      <>
        <div
          className={`width-full height-full absolute bg-white ${isOpen ? 'block' : 'hidden'}`}
          style={{ opacity: 0.8, top: 0, left: 0, zIndex: 9000 }}
        />
        <div
          className={`bordered absolute bg-white p-medium ${isOpen ? 'block' : 'hidden'}`}
          style={{
            width: '51%',
            minHeight: '350px',
            height: 'calc(100vh - 600px)',
            zIndex: 9000,
            right: '48px',
            borderRadius: '10px',
            boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.2)',
          }}
        >
          <div className="flex-row justify-between">
            <Search
              onChange={this._onEnterSearchText}
              loading={isSearching}
              placeholder="Enter Team member name"
              style={{ width: '80%' }}
              allowClear
              value={searchInputValue}
              size="large"
            />
            <PrimaryButton size="large" onClick={this._onCloseSearchModal}>
              Done
            </PrimaryButton>
          </div>
          <div
            className="width-full mv-medium"
            style={{ overflow: 'auto', minHeight: '250px', height: 'calc(100vh - 750px)' }}
          >
            {this.state.isSearching && <SpinningLoader size={100} message={'Loading'} />}
            {_.isEmpty(fullSearchList) &&
              !this.state.isSearching &&
              (!workerNotFound ? (
                <div className="text-align-center" style={{ margin: 'auto', marginTop: '10%' }}>
                  <Text weight="bold">Enter a Team member name to begin searching</Text>
                  <br />
                  <Text>We’ll search your entire organisation for matches.</Text>
                </div>
              ) : (
                <div className="text-align-center" style={{ margin: 'auto', marginTop: '10%' }}>
                  <Text weight="bold">No Team member found</Text>
                  <br />
                  <Text>Try searching for another person.</Text>
                </div>
              ))}
            {fullSearchList.length > 0 && !this.state.isSearching && (
              <table className="width-full">
                <tbody>
                  <tr className="pb-small">
                    <td className="text-align-center bordered-bottom mb-x-small" style={{ width: '100px' }}>
                      <SubTitle>Include</SubTitle>
                    </td>
                    <td className="bordered-bottom mb-x-small" colSpan={2}>
                      <SubTitle>Team members</SubTitle>
                    </td>
                  </tr>
                  {_.map(fullSearchList, (worker) => {
                    return (
                      <PublishWorkerRow
                        worker={worker}
                        changeIsIncluded={(event) => this.props.changeIsIncluded(event, worker.supportWorkerId, worker)}
                        isSearch={true}
                      />
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchWorkerSearchList: dispatch.bookingsStore.doFetchWorkerSearchList,
});

export default connect(null, mapDispatch)(PublishBookingSearchWorkerModal);
