import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import { TmMgmtTableEmpty } from '../../team-management-team-tab/tm-mgmt-table-empty';
import { TmMgmtInviteTableLoading } from './tm-mgmt-invite-table-loading';
import { TmMgmtInviteTablePending } from './tm-mgmt-invite-table-pending';
import { TmMgmtInviteTableRevoked } from './tm-mgmt-invite-table-revoked';

type TmMgmtInviteTableBodyParams = {
  isLoading: boolean;
  setSelectedModal: Dispatch<SetStateAction<string>>;
  setModalData: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const TmMgmtInviteTableBody = ({
  isLoading,
  setIsOpen,
  setModalData,
  setSelectedModal,
}: TmMgmtInviteTableBodyParams) => {
  const { invitesTeamList } = useSelector((state: IRootState) => state.teamStore);
  const { portalUser } = useSelector((state: IRootState) => state.authStore);

  const onOpenModal = (selectedModal: string, modalData = '') => {
    setIsOpen(true);
    setModalData(modalData);
    setSelectedModal(selectedModal);
  };

  if (!invitesTeamList || isLoading) {
    return <TmMgmtInviteTableLoading />;
  }

  const { pendingList, revokedList } = invitesTeamList;

  if (pendingList.length === 0 && (revokedList.length ?? 0) === 0) {
    return <TmMgmtTableEmpty />;
  }

  return (
    <>
      {pendingList && pendingList.length > 0 && (
        <TmMgmtInviteTablePending portalUser={portalUser} onOpenModal={onOpenModal} />
      )}
      {revokedList && revokedList.length > 0 && (
        <TmMgmtInviteTableRevoked portalUser={portalUser} onOpenModal={onOpenModal} />
      )}
    </>
  );
};
