import { PropTypes as PlacesAutocompletePropTypes } from 'react-places-autocomplete';

import { AddressInputFieldErrors, Address, AddressInputValues, ValidationRules } from './types';

export const emptyInputValues: AddressInputValues = {
  automaticInput: '',
  'manualInput.streetAddress1': '',
  'manualInput.streetAddress2': '',
  'manualInput.locality': '',
  'manualInput.state': null,
  'manualInput.postcode': '',
};

export const emptyFieldErrors: AddressInputFieldErrors = {
  automaticInput: undefined,
  'manualInput.streetAddress1': undefined,
  'manualInput.streetAddress2': undefined,
  'manualInput.locality': undefined,
  'manualInput.state': undefined,
  'manualInput.postcode': undefined,
};

export const emptyAddress: Address = {
  fullAddress: '',
  streetAddress1: '',
  streetAddress2: '',
  locality: '',
  state: null,
  postcode: '',
  geoLat: null,
  geoLng: null,
};

export const placesAutocompleteSearchOptionsAU: PlacesAutocompletePropTypes['searchOptions'] = {
  componentRestrictions: { country: 'au' },
  types: ['address'],
};

export const defaultValidationRules: Partial<ValidationRules> = {
  automaticInput: {
    required: (value) => {
      if (!value || value.trim() === '') {
        return 'Please enter an address';
      }
      return true;
    },
  },
  'manualInput.streetAddress1': {
    required: (value) => {
      if (!value || value.trim() === '') {
        return 'Please enter a street address';
      }
      return true;
    },
  },
  'manualInput.locality': {
    required: (value) => {
      if (!value || value.trim() === '') {
        return 'Please enter a suburb';
      }
      return true;
    },
  },
  'manualInput.state': {
    required: (value) => {
      if (!value || value.trim() === '') {
        return 'Please select a state';
      }
      return true;
    },
  },
  'manualInput.postcode': {
    required: (value) => {
      if (!value || value.trim() === '') {
        return 'Postcode is required';
      }
      return true;
    },
    length: (value) => {
      if ((value && !/^[0-9]{4}$/.test(value)) || value?.startsWith('0')) {
        return 'Postcode is not valid';
      }
      return true;
    },
  },
};
