import { Slot } from '@radix-ui/react-slot';
import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';

import * as styles from './heading.css';
import { compose, css, textStyleProps, useStyleProps } from '..';

import type { HTMLProps, SlotProps, TextStyleProps } from '..';
import type { HeadingVariants } from './heading.css';
import type { PropsWithChildren } from 'react';

export type HeadingProps = PropsWithChildren<
  HTMLProps<HTMLHeadingElement> & HeadingVariants & SlotProps & TextStyleProps
>;

/**
 * @deprecated
 * @see https://main--6371c62d928a15c9d0425ed3.chromatic.com/?path=/docs/components-heading--docs
 * */
export const Heading = forwardRef<HTMLElement & HTMLHeadingElement, HeadingProps>(function Heading(props, ref) {
  const { styleProps, ...otherProps } = useStyleProps(props, textStyleProps);
  const { asChild, children, level, emphasis, uppercase, capitalize, lowercase } = props; // Pick HTML color for typing
  const ElementType = asChild ? Slot : 'h3';

  return (
    <ElementType
      {...filterDOMProps(otherProps)}
      className={compose(
        css(styles.reset, styles.heading, styleProps),
        styles.variants({ level, emphasis, uppercase, capitalize, lowercase }),
      )}
      ref={ref}
    >
      {children}
    </ElementType>
  );
});
