import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ArrowRight = forwardRef(function ArrowRight(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.47 5.47a.75.75 0 0 1 1.06 0l6 6a.75.75 0 0 1 0 1.06l-6 6a.75.75 0 1 1-1.06-1.06l4.72-4.72H5a.75.75 0 0 1 0-1.5h12.19l-4.72-4.72a.75.75 0 0 1 0-1.06Z"
        fill="currentColor"
      />
    </Icon>
  );
});
