import React, { Component } from 'react';
import { Empty, Icon, notification } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Paragraph, Text } from 'common-components/typography';
import { IPinnedAlert } from 'interfaces/customer-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import moment from 'moment';
import AddEditPinnedAlertModal from './AddEditPinnedAlertModal';

interface IViewPinnedAlertModalProps {
  closeViewPinnedAlertModal: () => void;
  isOpen: boolean;
  customerUserId: string;
  customerPinnedAlerts: typeof state.customersStore.customerPinnedAlerts;
  doFetchCustomerPinnedAlerts: typeof dispatch.customersStore.doFetchCustomerPinnedAlerts;
  doDeleteCustomerPinnedAlert: typeof dispatch.customersStore.doDeleteCustomerPinnedAlert;
  isViewOnly: boolean;
}

interface IViewPinnedAlertModalState {
  isLoading: boolean;
  isAddEditModalOpen: boolean;
  isDeleteModalOpen: boolean;
  selectedPinnedAlert: IPinnedAlert | null;
  modalMode: ModalMode;
}

const PinnedAlertEmptyState = () => (
  <div className="flex-1 bg-white mt-x2-large align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No Pinned alerts
    </Text>
    <Paragraph>
      <Text color={'secondary'}>Press 'Add pinned warning' button to add one.</Text>
    </Paragraph>
  </div>
);

class ViewPinnedAlertModal extends Component<IViewPinnedAlertModalProps, IViewPinnedAlertModalState> {
  override state = {
    isLoading: false,
    isAddEditModalOpen: false,
    isDeleteModalOpen: false,
    selectedPinnedAlert: null,
    modalMode: 'VIEW',
  };

  private _closeModal = () => {
    this.setState({
      isLoading: false,
      isAddEditModalOpen: false,
      isDeleteModalOpen: false,
      selectedPinnedAlert: null,
      modalMode: 'VIEW',
    });
    this.props.closeViewPinnedAlertModal();
  };

  private _openAddEditPinnedAlert = (modalMode: ModalMode , selectedPinnedAlert: IPinnedAlert | null = null) => {
    this.setState({ selectedPinnedAlert, modalMode, isAddEditModalOpen: true });
  };

  private _closeAddEditPinnedAlert = () => {
    this.setState({ selectedPinnedAlert: null, modalMode: 'VIEW', isAddEditModalOpen: false });
  };

  private _openDeletePinnedAlertModal = (selectedPinnedAlert: IPinnedAlert | null) => {
    this.setState({ selectedPinnedAlert, isDeleteModalOpen: true });
  };

  private _closeDeletePinnedAlertModal = () => {
    this.setState({ selectedPinnedAlert: null, isDeleteModalOpen: false });
  };

  private _deletePinnedAlert = async () => {
    const { doDeleteCustomerPinnedAlert, customerUserId } = this.props;
    const { selectedPinnedAlert } = this.state;
    this.setState({ isLoading: true });
    try {
      await doDeleteCustomerPinnedAlert({ customerUserId, pinnedAlertId: selectedPinnedAlert.pinnedAlertId });
      notification.success({
        message: 'Pinned alert successfully deleted.',
      });
      this._closeDeletePinnedAlertModal();
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong. Please try again.',
      });
    }
    this.setState({ isLoading: false });
  };

  override componentDidUpdate = async (
    prevProps: Readonly<IViewPinnedAlertModalProps>,
    prevState: Readonly<IViewPinnedAlertModalState>,
    snapshot?: any,
  ) => {
    if (!prevProps.isOpen && this.props.isOpen) {
      await this.props.doFetchCustomerPinnedAlerts({ customerUserId: this.props.customerUserId });
    }
  };

  override render() {
    const { customerPinnedAlerts, customerUserId, isViewOnly } = this.props;
    const { isLoading, isDeleteModalOpen, isAddEditModalOpen, modalMode, selectedPinnedAlert } = this.state;

    return (
      <div>
        <AddEditPinnedAlertModal
          isOpen={isAddEditModalOpen}
          isEdit={modalMode === 'EDIT'}
          closeAddEditPinnedAlertModal={this._closeAddEditPinnedAlert}
          customerUserId={customerUserId}
          selectedPinnedAlert={selectedPinnedAlert}
        />
        <ActionModal
          isOpen={isDeleteModalOpen}
          onClose={this._closeDeletePinnedAlertModal}
          title={`Delete pinned alert`}
          showCloseButton={true}
          width={'medium'}
          verticalAlignment={'high'}
        >
          <Paragraph>Are you sure you want to delete this pinned alert?</Paragraph>
          <Paragraph>It will no longer be viewable on the workspace or business app.</Paragraph>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._closeDeletePinnedAlertModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" color="red" loading={isLoading} onClick={this._deletePinnedAlert}>
              Delete
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._closeModal}
          title={`Pinned alerts`}
          showCloseButton={true}
          canCloseOutside={false}
          verticalAlignment={'center'}
          width={'x-large'}
        >
          <div className="mb-large bordered-bottom border-standard-gray">
            <Paragraph>View{!isViewOnly && ', modify & add'} pinned alerts for this customer</Paragraph>
          </div>
          {isLoading ? (
            <SpinningLoader size={150} message={'Editing...'} />
          ) : _.isEmpty(customerPinnedAlerts) ? (
            <PinnedAlertEmptyState />
          ) : (
            _.map(customerPinnedAlerts, (alert) => {
              return (
                <div className={'bg-quaternary p-medium rounded-big mv-large bordered border-standard-gray'}>
                  <div>
                    <Icon type={'pushpin'} theme={'filled'} className={'text-color-warning-orange mr-x-small'} />
                    <Text weight={'bold'}>{alert.title}</Text>
                  </div>
                  <div style={{ whiteSpace: 'pre-line' }}>
                    <Text>{alert.description}</Text>
                  </div>
                  <div className={'flex-row'}>
                    <Text color={'secondary'} size={'regular'}>
                      Last edited by{' '}
                      <b>
                        {alert.firstName} {alert.lastName}
                      </b>{' '}
                      on <Text weight={'bold'}>{moment(alert.updatedOn).format('HH:mm, DD MMM YYYY')}</Text>
                    </Text>
                    {!isViewOnly && <div className={'mh-small'}>|</div>}
                    {!isViewOnly && (
                      <>
                        <HyperlinkButton
                          className={'text-size-regular mr-medium'}
                          onClick={() => this._openAddEditPinnedAlert('EDIT', alert)}
                        >
                          Edit
                        </HyperlinkButton>
                        <HyperlinkButton
                          className={'text-size-regular'}
                          onClick={() => this._openDeletePinnedAlertModal(alert)}
                        >
                          Delete
                        </HyperlinkButton>
                      </>
                    )}
                  </div>
                </div>
              );
            })
          )}
          {!isViewOnly && (
            <PrimaryButton onClick={() => this._openAddEditPinnedAlert('ADD')} icon={'plus'} className={'mt-large'}>
              Add pinned alert
            </PrimaryButton>
          )}
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._closeModal}>
              Close
            </SecondaryButton>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  customerPinnedAlerts: state.customersStore.customerPinnedAlerts,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCustomerPinnedAlerts: dispatch.customersStore.doFetchCustomerPinnedAlerts,
  doDeleteCustomerPinnedAlert: dispatch.customersStore.doDeleteCustomerPinnedAlert,
});

export default connect(mapState, mapDispatch)(ViewPinnedAlertModal);
