import React, { Select, type SelectProps } from '@good/ui/core';
import { type CountryCode, useLocaleConfig } from 'providers/locale-provider';

type SelectLocaleConfigProps = Omit<SelectProps, 'value' | 'onChange' | 'data' | 'allowDeselect' | 'clearable'>;

export const SelectLocaleConfig = (props: SelectLocaleConfigProps) => {
  const { all: allLocaleConfigs, current: currentLocaleConfig, changeConfig: changeLocaleConfig } = useLocaleConfig();

  return (
    <Select
      data={allLocaleConfigs.map((localConfig) => {
        return {
          value: localConfig.countryCode,
          label: `${localConfig.icon ?? ''}  ${localConfig.name}`,
        };
      })}
      allowDeselect={false}
      clearable={false}
      value={currentLocaleConfig.countryCode}
      onChange={(countryCode) =>
        changeLocaleConfig({ countryCode: countryCode as CountryCode, updateGlobalLang: true })
      }
      {...props}
    />
  );
};
