import { parsePhoneNumberFromString } from 'libphonenumber-js';
import type { CountryCode } from '../providers/locale-provider';

/**
 * Validates a phone number.
 *
 * @param phoneNumber - The phone number to validate.
 * @param opts - Options for validation.
 * @param opts.callback - An optional callback function to be called after validation.
 * @param opts.countryCode - The country code for the phone number.
 * @param opts.required - Specifies if the phone number is required.
 * @returns A boolean indicating if the phone number is valid.
 */
export function validatePhoneNumber(
  phoneNumber: string | null | undefined,
  opts: {
    callback?: (err?: unknown) => void;
    countryCode: CountryCode;
    required?: boolean;
    requiredMessage?: string;
    invalidMessage?: string;
  },
): boolean {
  const {
    callback,
    countryCode,
    required,
    requiredMessage = 'Phone number is required',
    invalidMessage = 'Phone number is invalid',
  } = opts;

  try {
    const hasValue = phoneNumber && phoneNumber !== '';

    if (!hasValue) {
      if (required) {
        throw new Error(requiredMessage);
      }

      // eslint-disable-next-line callback-return -- return is present
      callback?.();
      return true;
    }

    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, countryCode);
    if (!parsedPhoneNumber?.isValid()) {
      throw new Error(invalidMessage);
    }
  } catch (err: unknown) {
    // eslint-disable-next-line callback-return -- return is present
    callback?.(err);
    return false;
  }
  // eslint-disable-next-line callback-return -- return is present
  callback?.();
  return true;
}
