import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const PenTool = forwardRef(function PenTool(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.639.607a.75.75 0 0 0-1.278 0l-8 13a.75.75 0 0 0 .053.862l3.178 3.972A2.25 2.25 0 0 0 7.5 22.75h9a2.25 2.25 0 0 0 .908-4.31l3.178-3.972a.75.75 0 0 0 .053-.861l-8-13Zm3.354 19.143h.507a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1 0-1.5h8.493Zm-.354-1.5 3.446-4.306L12.75 3.65v5.704a2.751 2.751 0 1 1-1.5 0V3.65L4.915 13.944 8.36 18.25h7.28ZM12 10.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
