// Assigned worker card - to add and remove as well
// TODO : Replace interface with correct one
import { IAssignedWorker, IShiftCustomerAssignment } from 'interfaces/assign-customer-interfaces';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Avatar, Select } from 'antd';
import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { TimeAssignmentRow } from 'views/group-services/booking-modals/AssignCustomerToWorker/TimeAssignmentRow';

export function AssignedWorkerCard({
  workers,
  workerAssignments,
  onAddRow,
  onDeleteRow,
  onEditRow,
  onSaveRow,
  onUpdateRow,
  onUpdateWorkerAssignment,
}: {
  workers: IAssignedWorker[];
  workerAssignments: IShiftCustomerAssignment[];
  onAddRow: any;
  onDeleteRow: any;
  onEditRow: any;
  onSaveRow: any;
  onUpdateRow: any;
  onUpdateWorkerAssignment: any;
}) {
  const [isAssigning, setIsAssigning] = useState(false);
  const [assignedWorker, setAssignedWorker] = useState<IAssignedWorker>(null);
  const [selectedWorkerId, setSelectedWorkerId] = useState(null);

  const hasAssignedWorker = !_.isEmpty(assignedWorker);

  function selectWorker(workerId) {
    // setSelectedWorkerId(workerId);
    const findWorker = workers.find((worker) => worker.supportWorkerUserId === workerId);
    setAssignedWorker(findWorker);
    onUpdateWorkerAssignment({ workerId });
    setIsAssigning(false);
  }

  function assignWorker() {
    setIsAssigning(true);
  }

  function reassignWorker() {
    setSelectedWorkerId(assignedWorker ? assignedWorker.supportWorkerUserId : null);
    setIsAssigning(true);
  }

  function unassignWorker() {
    setIsAssigning(false);
    setAssignedWorker(null);
  }

  function cancelAssignment() {
    setIsAssigning(false);
  }

  useEffect(() => {
    // Get the assigned worker for this card
    const assignedWorker = _.chain(workerAssignments)
      .head()
      .pick(['supportWorkerUserId', 'supportWorkerFirstName', 'supportWorkerLastName', 'supportWorkerAttachmentUrl'])
      .value();

    // setWorkingAssignments(workerAssignments);
    setAssignedWorker(assignedWorker);
  }, [workerAssignments]);

  const sortedAssignments = _.sortBy(workerAssignments, ['startDateTime', 'asc'], ['shiftCustomerAssignmentId', 'asc']);

  return (
    <div className="select-none" style={{ transition: 'height 1s' }}>
      <div className="rounded-big mb-12 bordered">
        {/* Card header */}
        <div className="bg-secondary flex-row align-center  p-small bordered-bottom">
          {/* Avatar / name */}
          {!isAssigning && (
            <div className="flex-row align-center">
              <Avatar
                icon="user"
                shape={'square'}
                className="mr-small"
                size={'small'}
                src={assignedWorker && assignedWorker.supportWorkerAttachmentUrl}
              />
              <Text className="mr-small" color={hasAssignedWorker ? 'black' : 'secondary'}>
                {hasAssignedWorker
                  ? `${assignedWorker.supportWorkerFirstName} ${assignedWorker.supportWorkerLastName}`
                  : 'No team member assigned'}
              </Text>
            </div>
          )}

          {/* Team member assignment */}
          {!!isAssigning && (
            <div className="flex-row">
              <Select style={{ width: '320px' }} value={selectedWorkerId} onChange={selectWorker} showSearch={true}>
                {_.map(workers, (worker) => (
                  <Select.Option value={worker.supportWorkerUserId} key={worker.supportWorkerUserId}>
                    <div className="flex-row align-center">
                      <Avatar src={worker.supportWorkerAttachmentUrl} size={'small'} className={'mr-small'} />
                      {worker.supportWorkerFirstName} {worker.supportWorkerLastName}
                    </div>
                  </Select.Option>
                ))}
              </Select>
              <div className="ml-small mt-x-small">
                <HyperlinkButton onClick={() => cancelAssignment()}>Cancel</HyperlinkButton>
              </div>
            </div>
          )}

          {/* Card actions */}
          {/*<div>*/}
          {/*  <IconButton icon="ellipsis" size="small" color="white" bordered={true} iconColor="blue-action" />*/}
          {/*</div>*/}
        </div>

        {/* Time assignment rows */}
        {hasAssignedWorker && (
          <div className="bg-quaternary ph-small">
            {/* Time */}

            {_.map(sortedAssignments, (assignment, idx) => (
              <TimeAssignmentRow
                assignment={assignment}
                onDeleteRow={onDeleteRow}
                onEditRow={onEditRow}
                onSaveRow={onSaveRow}
                onUpdateRow={onUpdateRow}
                key={idx}
              />
            ))}
            {/* Add time */}
            <div className="pb-x2-small">
              {/* TODO : Finish adding time later. */}
              {/*<HyperlinkButton fontSize="regular" onClick={addRow}>*/}
              {/*  + Add time*/}
              {/*</HyperlinkButton>*/}
            </div>
          </div>
        )}
      </div>

      {/* Action row */}

      <div className="flex-row">
        {hasAssignedWorker && (
          <>
            <HyperlinkButton onClick={reassignWorker} className="mr-medium">
              Re-assign to another team member
            </HyperlinkButton>
            <HyperlinkButton onClick={unassignWorker}>Unassign</HyperlinkButton>
          </>
        )}
        {!hasAssignedWorker && !isAssigning && (
          <HyperlinkButton onClick={assignWorker}>Assign to team member</HyperlinkButton>
        )}
      </div>
    </div>
  );
}
