import { useInfiniteQuery } from '@tanstack/react-query';

import { getNextPageParam } from 'stores/hooks/common/common-hook-utils';
import { ROSTER_COMMON_CONFIG } from 'views/bookings/roster-view/common/roster-common-config';

import { formatBookingListingFilters } from 'stores/rematch/models/bookings-store';

import { fetchShifts } from 'stores/queries/roster/roster-queries';
import { QueryKeys } from 'stores/hooks/common/query-keys';

const pageSize = ROSTER_COMMON_CONFIG.pageSize;

// Request helper
function createShiftsRequest({
  filters = [],
  page = 1,
  pageSize = 20,
  showOnlyAssigned = true,
  fetchUnassignedShifts = false,
}) {
  const formatted = formatBookingListingFilters({ filters });

  if (fetchUnassignedShifts) {
    formatted.shiftSlotStatus = ['UNASSIGNED'];
  } else {
    formatted.shiftSlotStatus = formatted.shiftSlotStatus?.filter((status) => status !== 'UNASSIGNED');
  }

  return {
    ...formatted,
    page,
    pageSize,
    showOnlyAssigned,
    sort: [
      ['workerFirstName', 'asc'],
      ['startDateTime', 'asc'],
    ],
  };
}

export function useQueryInfiniteShifts({ showOnlyAssigned = true, enabled, filters, fetchUnassignedShifts = false }) {
  // Query function that calls the API
  const queryShifts = ({ pageParam = 1 }) => {
    const request = createShiftsRequest({
      page: pageParam,
      pageSize,
      filters,
      showOnlyAssigned,
      fetchUnassignedShifts,
    });

    return fetchShifts(request);
  };

  const {
    data, // data returned from queryBooking
    fetchNextPage, // function to fetch next page
    hasNextPage, // boolean to indicate if there is a next page
    isFetching, // boolean to indicate if the query is in progress
    isFetchingNextPage, // boolean to indicate if the query to fetch the next page is in progress
    status, // status of the query
    refetch, // function to refetch the query
    remove, // function to remove/invalidate the query
  } = useInfiniteQuery([QueryKeys.SHIFTS, { filters, showOnlyAssigned, fetchUnassignedShifts }], queryShifts, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    // Should this query run on mount...? Set enabled = false to disable query from running
    enabled,
    // Extract the next page param to be passed to API
    getNextPageParam,
  });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
    remove,
  };
}
