/* BOOM-475 - fix linting errors */
import React, { useRef, useState } from 'react';
import { Skeleton, Tabs } from 'antd';

import UploadNewPictureSection from '../components/UploadNewPictureSection';
import { Inline, Text } from 'design-components';
import PhotoItem from '../components/PhotoItem';
import { EProfilePictureDestination } from '../components/ViewProfilePictureModal';
import { ICustomerAttachment } from 'interfaces/customer-interfaces';
import { ModalType } from '../components/ModalShortMessage';
import { CustomScrollBar } from '../components/CustomScrollBar';
import { DEFAULT_CUSTOMER_AVATAR } from 'views/customers/utils/constants';
import { FileUpload, ImageUpload } from '@good/icons';

export interface IStep2AddEditProfilePictureModalProps {
  errorMessageFile: string;
  isChangeProfilePicture?: boolean;
  previousUploadedPictures: ICustomerAttachment[];
  activeTab: EProfilePictureDestination;
  onSelectImage: (item?: ICustomerAttachment) => void;
  onChangeActiveTab: (tab: EProfilePictureDestination) => void;
  onDelete: (type: ModalType, item?: ICustomerAttachment) => void;
  isLoading?: boolean;
  beforeUpload?: (file: File) => boolean;
}

const Step2AddEditProfilePictureModal = (props: IStep2AddEditProfilePictureModalProps) => {
  const {
    onSelectImage,
    onChangeActiveTab,
    onDelete,
    errorMessageFile,
    previousUploadedPictures,
    activeTab,
    isChangeProfilePicture,
    isLoading,
    beforeUpload,
  } = props;

  const listPictureRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);

  const onChangeDragging = (drag: boolean) => {
    setDragging(drag);
  };

  return isChangeProfilePicture ? (
    <Tabs className="profile-picture-tabs profile-previous-pictures" activeKey={activeTab} onChange={onChangeActiveTab}>
      <Tabs.TabPane
        tab={
          <Inline display="flex" gap="$space50" alignItems="center" justifyContent="center">
            <ImageUpload
              className={`${
                activeTab === EProfilePictureDestination.UPLOADS ? 'text-blue-dark-2' : 'text-gray-dark-1'
              }`}
            />
            <Text className={'tab-text'} emphasis={'semibold'} size={'small'} color={'$bodyDark1'}>
              {EProfilePictureDestination.UPLOADS}
            </Text>
            {!!previousUploadedPictures.length && (
              <div className="profile-picture-badge align-center pv-x2-small ph-x-small flex-row justify-center">
                <Text lineHeight={'$leading100'} emphasis={'semibold'} size={'xsmall'} color={'$blueDark3'}>
                  {previousUploadedPictures.length}
                </Text>
              </div>
            )}
          </Inline>
        }
        key={EProfilePictureDestination.UPLOADS}
      >
        {isLoading ? (
          <Skeleton active={true} />
        ) : (
          <div
            className="list-picture"
            ref={listPictureRef}
            onScrollCapture={(event) => {
              if (!dragging) {
                scrollRef.current.scrollTop = event.currentTarget.scrollTop;
              }
            }}
            onWheel={() => {
              dragging && onChangeDragging(false);
            }}
          >
            {previousUploadedPictures.map((item, index) => (
              <PhotoItem
                key={index}
                item={item}
                onDelete={() => {
                  onDelete(ModalType.DELETE_PICTURE, item);
                }}
                onSelect={(item: ICustomerAttachment) => {
                  onSelectImage(item);
                }}
              />
            ))}
            {previousUploadedPictures.length > DEFAULT_CUSTOMER_AVATAR && (
              <CustomScrollBar
                trackHeight={350}
                targetRef={listPictureRef}
                ref={scrollRef}
                dragging={dragging}
                onChangeDragging={onChangeDragging}
              />
            )}
          </div>
        )}
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={
          <Inline display={'flex'} alignItems={'center'} gap="$space50" justifyContent={'center'}>
            <FileUpload
              className={`${
                activeTab === EProfilePictureDestination.DEVICES ? 'text-blue-dark-2' : 'text-gray-dark-1'
              }`}
            />
            <Text className={'tab-text'} emphasis={'semibold'} size={'small'} color={'$bodyDark1'}>
              {EProfilePictureDestination.DEVICES}
            </Text>
          </Inline>
        }
        key={EProfilePictureDestination.DEVICES}
      >
        <UploadNewPictureSection
          errorMessageFile={errorMessageFile}
          isChangeProfilePicture={!!previousUploadedPictures.length}
          beforeUpload={beforeUpload}
        />
      </Tabs.TabPane>
    </Tabs>
  ) : (
    <UploadNewPictureSection errorMessageFile={errorMessageFile} beforeUpload={beforeUpload} />
  );
};

export default Step2AddEditProfilePictureModal;
