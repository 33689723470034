/* eslint-disable */

import React, { Component } from 'react';
import { Form, Checkbox, Icon } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { Text, SubTitle } from 'common-components/typography';
import { ICustomer } from 'interfaces/customer-interfaces';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import { GhostButton, PrimaryButton, HyperlinkButton, SecondaryButton } from 'common-components/buttons';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import CommonUtils from 'utilities/common-utils';
import Utils from 'utilities/Utils';
import _ from 'lodash';
import { AddressInputManualAntd, PlacesAutocompleteWithLocale } from 'components';

const FormItem = Form.Item;

interface ICreateEditAddressModalProps extends FormComponentProps {
  closeCreateEditAddressModal: () => void;
  isOpen: boolean;
  selectedCustomer: ICustomer;
  selectedAddressIndex: number;
  isCreateNewAddress: boolean;
  customerAddress: any;
  saveCustomerAddress: (addressLocation: any) => void;
  isEditBookingAddress?: boolean;
}

interface ICreateEditAddressModalState {
  isLoadingFilter: boolean;
  isActionModalOpen: boolean;
  isManual: boolean;
  address: string;
  selectedLocation: boolean;
  selectedLattitude: any;
  selectedLongitude: any;
  address_components: any;
  savedInputAddress: string;
  temporarySelectedAddress: any;
  isInitialAddressPrimary: boolean;
}

class CreateEditAddressModal extends Component<ICreateEditAddressModalProps, ICreateEditAddressModalState> {
  state = {
    isLoadingFilter: false,
    isActionModalOpen: false,
    isManual: false,
    address: '',
    selectedLocation: false,
    selectedLattitude: '',
    selectedLongitude: '',
    isInitialAddressPrimary:
      this.props.selectedAddressIndex > -1
        ? this.props.customerAddress[this.props.selectedAddressIndex].isPrimary
        : _.isEmpty(this.props.customerAddress),
    address_components: [],
    savedInputAddress: null,
    temporarySelectedAddress: null,
  };

  private _saveCustomerAddress = () => {
    let addressLocation;
    const { form, saveCustomerAddress } = this.props;
    if (this.state.isManual) {
      form.validateFieldsAndScroll((error) => {
        if (!error) {
          let streetaddress = form.getFieldValue('streetaddress');
          let streetaddress2 = form.getFieldValue('streetaddress2');
          let suburb = form.getFieldValue('suburb');
          let state = form.getFieldValue('state');
          let postcode = form.getFieldValue('postcode');
          let country = form.getFieldValue('country') === 'AU' ? 'Australia' : '';
          let isPrimary = form.getFieldValue('isPrimary');
          addressLocation = {
            streetaddress: streetaddress,
            streetaddress2: streetaddress2,
            suburb: suburb,
            state: state,
            postcode: postcode,
            country: country,
            isPrimary: isPrimary,
            isManual: true,
          };
          saveCustomerAddress(addressLocation);
          this._closeActionCreateModal();
        }
      });
    } else {
      addressLocation = {
        selectedLattitude: this.state.selectedLattitude,
        selectedLongitude: this.state.selectedLongitude,
        address: this.state.address,
        address_components: this.state.address_components,
        isPrimary: form.getFieldValue('isPrimary'),
        isManual: false,
      };
      saveCustomerAddress(addressLocation);
      this._closeActionCreateModal();
    }
  };

  private _handleChange = (address) => {
    this.setState({ address });
    if (address == '') {
      this.setState({ selectedLocation: false });
    }
  };

  private _handleSelect = (address) => {
    this.setState({ address: address, savedInputAddress: address });
    geocodeByAddress(address)
      .then((results) =>
        this.setState({
          address_components: results[0].address_components,
          temporarySelectedAddress: CommonUtils.createAddressFromGoogleMap(results[0].address_components),
        }),
      )
      .catch(() => this.setState({ selectedLocation: false }));
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => this.setState({ selectedLocation: true, selectedLattitude: lat, selectedLongitude: lng }))
      .catch(() => this.setState({ selectedLocation: false }));
  };

  private _closeActionCreateModal = () => {
    this.setState({
      address: '',
      selectedLocation: false,
      isManual: false,
      temporarySelectedAddress: null,
      savedInputAddress: null,
    });
    this.props.closeCreateEditAddressModal();
  };

  private _switchToManual = () => {
    this.setState({ isManual: true, selectedLocation: false, address: '' });
  };

  private _switchToAuto = () => {
    this.setState({ isManual: false, selectedLocation: false, address: '' });
  };

  private _onOpenConfirmationModel = () => {
    this.setState({ isActionModalOpen: true });
  };

  private _onCloseConfirmationModel = () => {
    this.setState({ isActionModalOpen: false });
  };

  private _onConfirmActionModel = () => {
    this.setState({ isActionModalOpen: false });
    this._closeActionCreateModal();
  };

  private _validatePostcode = (rule, value, callback) => {
    try {
      if (!value.match(new RegExp('^\\d{4}$'))) {
        throw Error('Postcode is required (4 Digits)');
      }
    } catch (e) {
      callback(e);
      return;
    }
    callback();
  };

  componentDidUpdate(
    prevProps: Readonly<ICreateEditAddressModalProps>,
    prevState: Readonly<ICreateEditAddressModalState>,
    snapshot?: any,
  ) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        isInitialAddressPrimary:
          this.props.selectedAddressIndex > -1
            ? this.props.customerAddress[this.props.selectedAddressIndex].isPrimary
            : _.isEmpty(this.props.customerAddress),
      });
    }
  }

  render() {
    const { selectedCustomer, form, isCreateNewAddress, selectedAddressIndex } = this.props;
    const { getFieldDecorator } = form;
    let selectedCustomerAddress = this.state.temporarySelectedAddress
      ? this.state.temporarySelectedAddress
      : this.props.customerAddress[selectedAddressIndex];

    return (
      <ActionModal
        title={<>{!isCreateNewAddress ? 'Edit' : 'Add new'} address</>}
        isOpen={this.props.isOpen}
        onClose={this.props.closeCreateEditAddressModal}
      >
        <ActionModal
          isOpen={this.state.isActionModalOpen}
          onClose={this._onCloseConfirmationModel}
          title={'Confirmation'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>You are closing the editing page without saving.</Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className='mr-medium' size='large' onClick={this._onCloseConfirmationModel}>
              Cancel
            </PrimaryButton>
            <GhostButton size='large' onClick={this._onConfirmActionModel}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <div className='anim-slide-left'>
          <SpinningLoadingActionModel isOpen={this.state.isLoadingFilter} verticalAlignment={'highest'} />

          {!this.state.isManual ? (
            <React.Fragment>
              <div className='mt-medium mb-x2-small'>
                <SubTitle>Address Lookup</SubTitle>
                <div className='mb-x-small'></div>
                <FormItem className={'m-none'}>
                  <PlacesAutocompleteWithLocale
                    value={this.state.address}
                    onSelect={this._handleSelect}
                    onChange={this._handleChange}
                    shouldFetchSuggestions={this.state.address.length > 2}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <input
                          value={this.state.address}
                          {...getInputProps({
                            placeholder: 'Type address here',
                            className: 'location-search-input width-full pl-small',
                          })}
                        />
                        <div className='autocomplete-dropdown-container'>
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                            const style = suggestion.active
                              ? {
                                  backgroundColor: '#fafafa',
                                  cursor: 'pointer',
                                  borderTop: '1px ridge grey',
                                  borderLeft: '1px ridge grey',
                                  borderRight: '1px ridge grey',
                                }
                              : {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer',
                                  borderTop: '1px ridge grey',
                                  borderLeft: '1px ridge grey',
                                  borderRight: '1px ridge grey',
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>
                                  <Icon type={'environment'} /> {suggestion.description}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocompleteWithLocale>
                </FormItem>
              </div>
              <div className={'mt-x-large'}>
                <SubTitle>Selected address</SubTitle>
                <div className='mb-x-small' style={{ width: '320px' }}></div>
                <Text style={{ width: '320px' }}>
                  {!Utils.isEmpty(this.state.savedInputAddress) ? this.state.savedInputAddress : 'Input value'}
                </Text>
              </div>
              <React.Fragment>
                <div className='mb-medium'>
                  <FormItem className={'m-none'}>
                    {getFieldDecorator('isPrimary', {
                      valuePropName: 'checked',
                      initialValue: this.state.isInitialAddressPrimary,
                    })(<Checkbox>This is the customers primary address</Checkbox>)}
                  </FormItem>
                </div>
              </React.Fragment>
              <ActionModalFooter>
                {!this.state.isManual ? (
                  <HyperlinkButton onClick={this._switchToManual} className={'mt-small mr-medium'}>
                    Enter the address manually
                  </HyperlinkButton>
                ) : (
                  <HyperlinkButton onClick={this._switchToAuto} className={'mt-small mr-medium'}>
                    Address lookup tool
                  </HyperlinkButton>
                )}

                <SecondaryButton size='large' onClick={this._onOpenConfirmationModel} className='mr-medium'>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size='large'
                  disabled={!this.state.savedInputAddress}
                  onClick={this._saveCustomerAddress}
                >
                  {!isCreateNewAddress ? 'Save' : 'Add Address'}
                </PrimaryButton>
              </ActionModalFooter>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <AddressInputManualAntd
                form={form}
                required
                formMapping={{
                  streetAddress1: 'streetaddress',
                  streetAddress2: 'streetaddress2',
                  locality: 'suburb',
                }}
                initialValues={selectedCustomerAddress}
              />
              <ActionModalFooter>
                {!this.state.isManual ? (
                  <HyperlinkButton onClick={this._switchToManual} className={'mt-small mr-medium'}>
                    Enter the address manually
                  </HyperlinkButton>
                ) : (
                  <HyperlinkButton onClick={this._switchToAuto} className={'mt-small mr-medium'}>
                    Address lookup tool
                  </HyperlinkButton>
                )}
                <SecondaryButton onClick={this._onOpenConfirmationModel} size={'large'} className='mr-medium'>
                  Cancel
                </SecondaryButton>
                <PrimaryButton onClick={this._saveCustomerAddress} size={'large'}>
                  {!isCreateNewAddress ? 'Edit Address' : 'Add Address'}
                </PrimaryButton>
              </ActionModalFooter>
            </React.Fragment>
          )}
        </div>
      </ActionModal>
    );
  }
}

export default connect(null, null)(Form.create<ICreateEditAddressModalProps>()(CreateEditAddressModal));
