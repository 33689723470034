import { Divider } from '@blueprintjs/core';
import { Inline, Stack, Text } from '@goodhuman-me/components';
import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/es/date-picker/interface';
import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubTitle } from '../../../../common-components/typography';
import { Radio, RadioGroup } from '../../../../design-components';
import { ExtendScheduleFormValue, nextMonday } from './ExtendScheduleModal';
import ScheduleListItem from './ScheduleListItem';

type ExtendScheduleFormProps = {
  scheduleName: string;
  schedulePeriodText: string;
  displayTimeSlots: string[];
  longestPeriodWeeks: number;
  formData: ExtendScheduleFormValue;
  updateFormData: (updates: Partial<ExtendScheduleFormValue>) => void;
  setDatePickersOpen: (open: boolean) => void;
  defaultCustomCopyDate: moment.Moment;
  earliestPossible: moment.Moment;
  scheduleEndDate: moment.Moment;
};

const ExtendScheduleForm: React.FC<ExtendScheduleFormProps> = ({
  scheduleName,
  schedulePeriodText,
  displayTimeSlots,
  longestPeriodWeeks,
  formData,
  updateFormData,
  setDatePickersOpen,
  defaultCustomCopyDate,
  earliestPossible,
  scheduleEndDate,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'schedules.form' });
  const { startDate, endDate, customCopyDate, nextPeriod }: ExtendScheduleFormValue = formData;

  // The end of the default range is the longest time slot cadence after the default start date.
  const defaultCopyEndDate = defaultCustomCopyDate.clone().add(longestPeriodWeeks, 'weeks');

  // If the first and second dates fall on different years, they should both specify the year.
  const defaultCopyDateFormat: string =
    defaultCopyEndDate.year() !== defaultCustomCopyDate.year() ? 'DD MMM YYYY' : 'DD MMM';

  // If the start date falls before your current time, it should say 'from X ago', rather than just 'X ago'.
  // This variable is just for storing whether we say 'from' or 'in' a given amount of time.
  const defaultCopyTextFrom: string = defaultCustomCopyDate.isBefore(moment()) ? 'from' : '';
  // e.g. Should read something like 'Select nearest 4 week period in 2 weeks (1 Jan - 29 Jan 2000)'
  const defaultCopyText = t('defaultCopyDateLabel', {
    weeks: longestPeriodWeeks,
    from: defaultCopyTextFrom,
    fromNow: defaultCustomCopyDate.fromNow(),
    start: defaultCustomCopyDate.format(defaultCopyDateFormat),
    end: defaultCopyEndDate.format('DD MMM YYYY'),
  });

  const datePickerProps: DatePickerProps = {
    onOpenChange: setDatePickersOpen,
    format: 'ddd, DD MMM YYYY',
    allowClear: false,
  };

  const maximumEndDate: moment.Moment = startDate.clone().add(3, 'months');

  const endDateBeforeStart: boolean = endDate.isBefore(startDate);

  // Using this value to represent the earliest date that the user can select for the start date.
  // Without this, the user would appear to be able to select a date where that dates' Monday is invalid.
  const earliestStartDate: moment.Moment = nextMonday(earliestPossible);

  return (
    <Stack gap="$space200">
      <Text size="small">{t('promptText')}</Text>
      <ScheduleListItem
        scheduleName={scheduleName}
        schedulePeriodText={schedulePeriodText}
        displayTimeSlots={displayTimeSlots}
      />
      <Divider />
      <Text size="small">{t('secondaryPromptText')}</Text>
      <Inline justifyContent="space-between">
        <Stack width="48%">
          <SubTitle>{t('startDateLabel')}</SubTitle>
          <DatePicker.WeekPicker
            value={startDate}
            onChange={(startDate: moment.Moment) => updateFormData({ startDate })}
            disabledDate={(date) => date.isBefore(earliestStartDate)}
            {...datePickerProps}
          />
        </Stack>
        <Stack width="48%">
          {endDateBeforeStart ? (
            <SubTitle color="red">{t('endDateBeforeStartLabel')}</SubTitle>
          ) : (
            <SubTitle>{t('endDateLabel')}</SubTitle>
          )}
          <DatePicker.WeekPicker
            value={endDate}
            onChange={(endDate: moment.Moment) => updateFormData({ endDate })}
            disabledDate={(date) => date.isBefore(startDate) || date.isAfter(maximumEndDate)}
            {...datePickerProps}
          />
        </Stack>
      </Inline>
      <Text>{t('customRangeLabel')}</Text>
      <RadioGroup value={nextPeriod} onChange={(nextPeriod) => updateFormData({ nextPeriod })}>
        <Radio size="small" label={defaultCopyText} value="next" />
        <Radio size="small" label={t('customRangeRadioLabel')} value="custom" />
      </RadioGroup>
      {nextPeriod === 'custom' && (
        <>
          <Text>{t('customRangePrompt', { weeks: longestPeriodWeeks })}</Text>
          <Stack>
            <SubTitle>{t('customCopyDateLabel', { weeks: longestPeriodWeeks })}</SubTitle>
            <DatePicker.WeekPicker
              value={customCopyDate}
              onChange={(customCopyDate: moment.Moment) => updateFormData({ customCopyDate })}
              disabledDate={(date) =>
                date.isBefore(earliestPossible) ||
                date.isAfter(scheduleEndDate.clone().subtract(longestPeriodWeeks, 'weeks'))
              }
              {...datePickerProps}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default ExtendScheduleForm;
