import React from 'react';
import { FilterType } from 'utilities/enum-utils';
import { timeZone } from 'interfaces/timezone-type';
import {
  FilterMenuDateRange,
  FilterMenuSearchLookup,
  FilterMenuCheckboxList,
  FilterMenuRadioList,
} from 'common-components/filter';
import { IFilter, IFilterDateRange } from 'interfaces/filter-interfaces';
import { useFetchTagsLanguages } from 'stores/hooks/query-hooks/use-query-fetch-tags';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { IGetTagsResponse } from 'stores/queries/tags/tag-queries';
import { SaveNewFilterValueDateRange } from './FilterMenuDateRange';

type SaveNewFilterValue = (filterType: string, newFilterValue: string, selectionLabel: string) => void;

type IFilterItemMenuBaseProps = {
  canRemove?: boolean;
  removeFilter: (filterType: string) => void;
  optionsQuery?: IGetTagsResponse;
  optionsIsLoading?: boolean;
};

type IFilterItemMenuDefaultProps = {
  filter: IFilter;
  saveNewFilterValue: SaveNewFilterValue;
  displayTimezone?: never;
  onClose?: never;
};

type IFilterItemMenuDateRangeProps = {
  filter: IFilterDateRange;
  saveNewFilterValue: SaveNewFilterValueDateRange;
  displayTimezone: timeZone;
  onClose?: () => void;
};

type IFilterItemMenuProps = IFilterItemMenuBaseProps & (IFilterItemMenuDefaultProps | IFilterItemMenuDateRangeProps);

const isDateRangeFilter = (filter: IFilter | IFilterDateRange): filter is IFilterDateRange => {
  return (
    filter.filter === FilterType.DATE_RANGE ||
    filter.filter === FilterType.INVOICE_DATE ||
    filter.filter === FilterType.DATE_PAID ||
    filter.filter === FilterType.ACTIVE_WORKFLOWS_DATE ||
    filter.filter === FilterType.DATE_ADDED
  );
};

const FilterItemMenu = ({
  filter,
  canRemove,
  saveNewFilterValue,
  removeFilter,
  optionsQuery,
  optionsIsLoading,
}: IFilterItemMenuBaseProps & IFilterItemMenuDefaultProps) => {
  const filterType = filter.filter;

  /* If a filterType dependant on a useQuery (see functional wrapper switch) is defined 
    optionsData needs to be available (not loading) before rendering */
  if (optionsIsLoading) {
    return <SpinningLoader size={25} message={'Loading filter data'} />;
  }

  if (
    filterType === FilterType.CUSTOMER ||
    filterType === FilterType.WORKER ||
    filterType === FilterType.BATCH_AUTHOR ||
    filterType === FilterType.PREFERRED_SUPPORT_WORKER ||
    filterType === FilterType.BLOCKED_SUPPORT_WORKER ||
    filterType === FilterType.SERVICE_DATE_TIMES ||
    filterType === FilterType.GROUP_SERVICE_SCHEDULES ||
    filterType === FilterType.LOCATION_BY_SUBURBS ||
    filterType === FilterType.SUPPORT_COORDINATOR ||
    filterType === FilterType.CASE_MANAGER ||
    filterType === FilterType.DEBTOR
  ) {
    return (
      <FilterMenuSearchLookup
        filter={filter}
        canRemove={canRemove}
        saveNewFilterValue={saveNewFilterValue}
        removeFilter={removeFilter}
      />
    );
  } else if (
    filterType === FilterType.BOOKING_BILLING ||
    filterType === FilterType.PAID_BILLING ||
    filterType === FilterType.BATCH_BILLING
  ) {
    return (
      <FilterMenuRadioList
        filter={filter}
        saveNewFilterValue={saveNewFilterValue}
        removeFilter={removeFilter}
        canRemove={canRemove}
      />
    );
  }
  return (
    <FilterMenuCheckboxList
      filter={filter}
      saveNewFilterValue={saveNewFilterValue}
      removeFilter={removeFilter}
      canRemove={canRemove}
      optionsQuery={optionsQuery}
    />
  );
};

const FilterItemMenuWithQueries = ({
  canRemove,
  displayTimezone,
  filter,
  removeFilter,
  saveNewFilterValue,
  onClose,
}: IFilterItemMenuProps) => {
  const { data: queryData, isLoading: isLoading, error: queryError } = useFetchTagsLanguages();
  if (isDateRangeFilter(filter)) {
    return (
      <FilterMenuDateRange
        filter={filter}
        canRemove={canRemove}
        displayTimezone={displayTimezone as timeZone}
        saveNewFilterValue={saveNewFilterValue as SaveNewFilterValueDateRange}
        removeFilter={removeFilter}
        onClose={onClose}
      />
    );
  }

  switch (filter.filter) {
    case FilterType.LANGUAGES:
      return (
        <FilterItemMenu
          filter={filter}
          canRemove={canRemove}
          saveNewFilterValue={saveNewFilterValue as SaveNewFilterValue}
          removeFilter={removeFilter}
          optionsQuery={queryData}
          optionsIsLoading={isLoading || !!queryError}
        />
      );
    default:
      return (
        <FilterItemMenu
          filter={filter}
          canRemove={canRemove}
          saveNewFilterValue={saveNewFilterValue as SaveNewFilterValue}
          removeFilter={removeFilter}
        />
      );
  }
};

export default FilterItemMenuWithQueries;
