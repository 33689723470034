import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ReplyAll = forwardRef(function ReplyAll(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53 5.53a.75.75 0 1 0-1.06-1.06l-5 5a.75.75 0 0 0 0 1.06l5 5a.75.75 0 0 0 1.06-1.06L5.06 10l4.47-4.47Zm4 0a.75.75 0 1 0-1.06-1.06l-5 5a.75.75 0 0 0 0 1.06l5 5a.75.75 0 0 0 1.06-1.06l-3.72-3.72H15c1.42 0 2.429 0 3.21.08.77.079 1.235.227 1.596.468.354.237.66.541.896.896.241.361.39.827.468 1.596.08.781.08 1.79.08 3.21h1.5v-.042c0-1.369 0-2.454-.088-3.32-.09-.888-.28-1.629-.712-2.277a4.751 4.751 0 0 0-1.311-1.31c-.648-.434-1.39-.623-2.277-.713-.866-.088-1.951-.088-3.32-.088H9.81l3.72-3.72Z"
        fill="currentColor"
      />
    </Icon>
  );
});
