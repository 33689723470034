import React from 'react';
import { Title, Text } from 'common-components/typography';

interface IHeaderProps {
  headingText: string;
  subHeadingText: string;
}

function Header({ headingText, subHeadingText }: IHeaderProps) {
  return (
    <div className="flex-column">
      <Title level={4}>{headingText}</Title>
      <Text>{subHeadingText}</Text>
    </div>
  );
}

export default Header;
