import { Spinner } from '@blueprintjs/core';
import { Form, Icon, Input, message, notification, Progress, Select, Tooltip, Upload } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Information } from 'common-components/alerts';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import { ref, uploadBytesResumable } from 'firebase/storage';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAttachmentsNote } from 'src/interfaces/common-interface';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import firebaseApp from 'stores/firebase-app';
import {
  BookingType,
  NoteVisibleType,
  ServiceType,
  WorkflowTemplateStatus,
  WorkflowTemplateTriggerType,
} from '../../utilities/enum-utils';
import Utils from '../../utilities/Utils';
import { getNoteVisibleOption, isPrivacySettingsRestricted } from '../../utilities/booking-utils';
import { IBookingNoteItem } from '../../views/bookings/details/sections/content-section/tabs-panel/IBookingNoteItem';

const { Option } = Select;
interface IAddEditNoteModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  bookingId?: string;
  editNote: boolean;
  editingNote: any;
  noteType: string;
  customerUserId?: string;
  noteVisibleOptions: NoteVisibleType[];
  defaultVisibleType?: NoteVisibleType;
  doAddBookingNote: typeof dispatch.bookingsStore.doAddNote;
  doEditBookingNote: typeof dispatch.bookingsStore.doEditNote;
  doFetchSingleBooking: typeof dispatch.bookingsStore.doFetchSingleBooking;
  doAddCustomerNotes: typeof dispatch.customersStore.doAddCustomerNotes;
  doEditCustomerNote: typeof dispatch.customersStore.doEditCustomerNote;
  dofetchCustomerNotes: typeof dispatch.customersStore.dofetchCustomerNotes;
  resetCustomerNotesPageInfo: typeof dispatch.customersStore.resetCustomerNotesPageInfo;
  getListWorkflowTemplates: typeof dispatch.workflowStore.getListWorkflowTemplates;
  portalUser: typeof state.authStore.portalUser;
  handleSubmitIncidentNote?: (data) => void;
  serviceType?: ServiceType;
  notes?: IBookingNoteItem[];
  resetDocumentList?: () => Promise<void>;
}

interface IAddEditNoteModalState {
  isLoading: boolean;
  isIncident: boolean;
  noteDetails: string;
  visibleType: NoteVisibleType;
  isUploading: boolean;
  isInitialise: boolean;
  progress: number;
  selectedFiles: Array<File>;
  selectedDocumentsName: IAttachmentsNote[];
  removedFiles: Array<any>;
}

class AddEditNoteModal extends Component<IAddEditNoteModalProps, IAddEditNoteModalState> {
  static defaultProps = {
    defaultVisibleType: NoteVisibleType.PORTAL,
  };

  state = {
    isLoading: false,
    isIncident: false,
    noteDetails: '',
    visibleType: this.props.defaultVisibleType,
    selectedFile: null,
    isUploading: false,
    isInitialise: false,
    progress: 0,
    selectedFiles: [],
    selectedDocumentsName: [],
    removedFiles: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.props.isOpen !== prevProps.isOpen) {
      const { editingNote, editNote } = this.props;
      if (editNote && Utils.isEmpty(this.state.noteDetails) && this.props.isOpen) {
        this.setState({
          noteDetails: editingNote.body,
          visibleType: editingNote.visibleType,
          isIncident: editingNote.isIncident,
          selectedFiles: [],
          selectedDocumentsName: _.cloneDeep(editingNote.attachments) || [],
        });
      }
      if (this.props.isOpen !== prevProps.isOpen) {
        this.setState({ selectedFiles: [] });
      }
    }
  }

  private _onCloseModel = () => {
    const { defaultVisibleType, onClose } = this.props;
    this.setState({
      isIncident: false,
      noteDetails: '',
      visibleType: defaultVisibleType,
      selectedDocumentsName: [],
    });
    onClose();
  };

  private _onPressAddNote = async () => {
    const {
      doAddBookingNote,
      doAddCustomerNotes,
      bookingId,
      noteType,
      customerUserId,
      handleSubmitIncidentNote,
      portalUser,
      getListWorkflowTemplates,
      notes,
    } = this.props;
    const { noteDetails, isIncident, visibleType, selectedFiles } = this.state;

    if (!Utils.isEmpty(noteDetails) && noteDetails.length >= 1 && noteDetails.length < 35001) {
      this.setState({ isLoading: true });
      const documents = _.map(selectedFiles, (document) => {
        return {
          documentName: document.name,
          description: `${document.size} Bytes`,
        };
      });

      try {
        let result = null;
        let payload: any = {
          isIncident: isIncident,
          visibleType: visibleType,
          noteContent: noteDetails,
          documents: documents,
          isActivityRecordNote: noteType === BookingType.ACTIVITY_RECORD && notes.length === 0,
        };
        this.setState({ isLoading: true });

        // Filter data
        if (noteType === BookingType.BOOKING || noteType === BookingType.ACTIVITY_RECORD) {
          payload.bookingId = bookingId;
        } else {
          //need to add document list into payload
          payload.customerUserId = customerUserId;
        }

        // Handle submit an incident note
        if (handleSubmitIncidentNote && isIncident) {
          const incidentWorkflowList: any = await getListWorkflowTemplates({
            page: 1,
            pageSize: 5,
            triggerType: [WorkflowTemplateTriggerType.INCIDENT],
            status: [WorkflowTemplateStatus.ACTIVE],
            customerUserId: customerUserId,
          });
          if (incidentWorkflowList.length > 0) {
            this.setState({ isLoading: false });
            handleSubmitIncidentNote({ note: payload, selectedFiles, portalUser });
            this._onCloseModel();
            return;
          }
        }

        if (noteType === BookingType.BOOKING || noteType === BookingType.ACTIVITY_RECORD) {
          result = await doAddBookingNote(payload);
        } else {
          result = await doAddCustomerNotes(payload);
        }

        if (!_.isEmpty(selectedFiles)) {
          await this._uploadFile(result);
        }

        this._onCloseModel();
      } catch (e) {
        notification.error({ message: 'Add note failed! Please try again.' });
      }

      this.setState({ isLoading: false });
    } else {
      this.props.form.validateFields();
    }
  };

  private _onPressEditNote = async () => {
    const {
      doEditBookingNote,
      doEditCustomerNote,
      editingNote,
      noteType,
      customerUserId,
      handleSubmitIncidentNote,
      portalUser,
      getListWorkflowTemplates,
    } = this.props;
    const { noteDetails, isIncident, visibleType, selectedFiles, selectedDocumentsName } = this.state;
    if (!Utils.isEmpty(noteDetails) && noteDetails.length >= 1 && noteDetails.length < 35001) {
      try {
        this.setState({ isLoading: true });

        const documents = _.map(selectedFiles, (document) => {
          return {
            documentName: document.name,
            description: `${document.size} Bytes`,
          };
        });

        let result = null;
        let payload: any = {
          isIncident: isIncident,
          visibleType: visibleType,
          noteContent: noteDetails,
          documents: [...selectedDocumentsName, ...documents],
          noteId: editingNote.noteId,
        };
        this.setState({ isLoading: true });

        // Filter data
        if (noteType === BookingType.BOOKING || noteType === BookingType.ACTIVITY_RECORD) {
          payload.bookingId = editingNote.attendanceId ?? editingNote.noteAttendanceId;
        } else {
          payload.customerUserId = customerUserId;
        }

        // Handle submit an incident note
        if (handleSubmitIncidentNote && !editingNote.isIncident && isIncident) {
          const incidentWorkflowList: any = await getListWorkflowTemplates({
            page: 1,
            pageSize: 5,
            triggerType: [WorkflowTemplateTriggerType.INCIDENT],
            status: [WorkflowTemplateStatus.ACTIVE],
          });
          if (incidentWorkflowList.length > 0) {
            this.setState({ isLoading: false });
            if (!payload.customerUserId && customerUserId) {
              payload.customerUserId = customerUserId;
            }
            handleSubmitIncidentNote({ note: payload, selectedFiles, portalUser, isEdit: true });
            this._onCloseModel();
            return;
          }
        }

        if (noteType === BookingType.BOOKING || noteType === BookingType.ACTIVITY_RECORD) {
          result = await doEditBookingNote(payload);

          // If we are on a customer details view, then we need to reset the customer notes
          // and customer booking attachments sub-tabs after each successful call.
          if (customerUserId) {
            this.props.resetCustomerNotesPageInfo({});
            await this.props.dofetchCustomerNotes({
              customerUserId: this.props.customerUserId,
            });

            this.props.resetDocumentList && (await this.props.resetDocumentList());
          } else {
            await this.props.doFetchSingleBooking({
              bookingId: editingNote.attendanceId,
            });
          }
        } else {
          //need to add document list into payload
          result = await doEditCustomerNote(payload);
        }

        if (!_.isEmpty(selectedFiles)) {
          await this._uploadFile(result);
        }

        this._onCloseModel();
      } catch (e) {
        notification.error({ message: 'Edit note failed! Please try again.' });
      }
      this.setState({ isLoading: false });
    } else {
      this.props.form.validateFields();
    }
  };

  private _uploadFile = async (result) => {
    //need to be specify which type uploading for : BOOKING or CUSTOMER
    const { portalUser, bookingId, noteType, customerUserId } = this.props;
    const { selectedFiles } = this.state;
    const { noteId, uploadDocumentsResult } = result;
    const { uploadBucketUrl, documentIds } = uploadDocumentsResult;

    this.setState({ isInitialise: true, isUploading: false, progress: 0 });
    const promise = [];
    let numberOfUploadedFile = 0;
    try {
      for (let index = 0; index < selectedFiles.length; index++) {
        const metadata = {
          customMetadata: {
            documentId: documentIds[index],
            serviceProviderId: portalUser.serviceProviderId,
            attendanceId:
              noteType === BookingType.BOOKING || noteType === BookingType.ACTIVITY_RECORD ? bookingId : customerUserId,
            noteId: noteId,
          },
        };

        const storageRef = ref(firebaseApp.storage, `${uploadBucketUrl}/${selectedFiles[index].name}`);
        const uploadFile = uploadBytesResumable(storageRef, selectedFiles[index], metadata);

        uploadFile.on(
          'state_changed',
          () => void 0,
          (error) => {
            notification.error({ message: 'Upload failed! Please try again.', description: error });
          },
          () => {
            const progress = Math.round((++numberOfUploadedFile / selectedFiles.length) * 100);
            this.setState({ progress });
          },
        );

        promise.push(uploadFile);
      }

      this.setState({ isInitialise: false, isUploading: true });
      await Promise.all(promise)
        .then(() => {
          notification.success({
            message:
              numberOfUploadedFile === 1
                ? 'Attachment is currently being scanned.'
                : `${numberOfUploadedFile} attachments are currently being scanned.`,
          });
        })
        .catch((err) =>
          notification.error({ message: 'All attachments upload failed! Please try again.', description: err }),
        );

      await new Promise((r) => setTimeout(r, 1000)); // nothing but smooth UX
      this.setState({ isUploading: false });
    } catch (e) {
      notification.error({ message: 'Upload failed! Please try again.', description: 'Failed to upload files.' });
      console.log(e, 'error catch');
      this.setState({ isInitialise: false, isUploading: false });
    }
  };

  private _noteDetailsValidator = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('Please fill in the note details.');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _hasExtension = (file) => {
    return new RegExp(
      '(' +
        [
          '.jpeg',
          '.jpg',
          '.png',
          '.gif',
          '.pdf',
          '.txt',
          '.ppt',
          '.pptx',
          '.doc',
          '.docx',
          '.csv',
          '.xls',
          '.xlsx',
          '.xlsm',
        ]
          .join('|')
          .replace(/\./g, '\\.') +
        ')$',
    ).test(file.name.toLowerCase());
  };

  private _validateFile = (file) => {
    const isValidType = this._hasExtension(file);
    if (!isValidType) {
      message.error('This file extension is not supported, please choose another format.');
    }

    const isLessThan25Mb = file.size / 1024 / 1024 < 25;
    if (!isLessThan25Mb) {
      message.error('File must be smaller than 25MB.');
    }

    return false;
  };

  private _validateSingleFile = (file) => {
    const isValidType = this._hasExtension(file);
    const isLessThan25Mb = file.size / 1024 / 1024 < 25;

    return isLessThan25Mb && isValidType;
  };

  private _onChangeFiles = (info: any) => {
    const { selectedFiles, selectedDocumentsName, removedFiles } = this.state;

    const fileList = _.map(info.fileList, (file) => file.originFileObj);
    const newFiles = _.filter(fileList, (file) => {
      return (
        this._validateSingleFile(file) &&
        !_.some(removedFiles, ['uid', file.uid]) &&
        !_.some(selectedFiles, ['uid', file.uid])
      );
    });

    const amountCurrentFiles = selectedFiles.length;
    if (amountCurrentFiles <= 5) {
      const newFilesToAttach = _.take(newFiles, 5 - amountCurrentFiles - selectedDocumentsName.length);
      const newFilesNotToAttach = _.filter(newFiles, (file) => !_.some(newFilesToAttach, ['uid', file.uid]));
      this.setState({
        selectedFiles: [...selectedFiles, ...newFilesToAttach],
        removedFiles: [...removedFiles, ...newFilesNotToAttach],
      });
    }
  };

  private _onRemoveFile = (removeFile: any) => {
    const { selectedFiles, removedFiles } = this.state;
    if (removeFile) {
      removedFiles.push(removeFile);
      _.remove(selectedFiles, (file) => {
        return _.isEqual(file, removeFile);
      });

      this.setState({ selectedFiles: selectedFiles, removedFiles: removedFiles });
    }
  };

  private _onRemoveFileName = (removeFile: any) => {
    const { selectedDocumentsName } = this.state;
    if (removeFile) {
      _.remove(selectedDocumentsName, (file) => {
        return _.isEqual(file, removeFile);
      });
      this.setState({ selectedDocumentsName: selectedDocumentsName });
    }
  };

  render() {
    const { form, isOpen, editNote, noteType, editingNote, defaultVisibleType, noteVisibleOptions, serviceType } =
      this.props;
    const { getFieldDecorator } = form;
    const { isIncident, isLoading, isUploading, progress, isInitialise, selectedFiles, selectedDocumentsName } =
      this.state;

    // Edge case: when edit note have restricted to service option
    // that have not support in the current options, push option.
    const adjustedNoteVisibleOptions: NoteVisibleType[] = [...noteVisibleOptions];

    const isEditRestrictedToServiceNote =
      editingNote && !noteVisibleOptions.includes(editingNote.visibleType) && serviceType !== ServiceType.COORDINATION;

    if (isEditRestrictedToServiceNote) {
      adjustedNoteVisibleOptions.push(editingNote.visibleType);
    }

    return (
      <ActionModal
        isOpen={isOpen}
        title={editNote ? 'Edit note' : 'Add note'}
        width="medium"
        onClose={this._onCloseModel}
      >
        {isUploading ? (
          <div className="mv-x2-large">
            <div className="text-align-center">
              <Progress type="circle" percent={progress} className="mb-medium" />
            </div>
            <div className="text-align-center">
              <Text size="x2-large">Uploading...</Text>
            </div>
          </div>
        ) : isInitialise ? (
          <div className="mv-x2-large">
            <div className="text-align-center">
              <Spinner size={80} />
            </div>
            <div className="text-align-center">
              <Text size="x2-large">Initializing...</Text>
            </div>
          </div>
        ) : (
          <>
            <div>
              {!editNote && noteType === 'GENERAL' && (
                <div className="mb-large">
                  <Information
                    content={
                      <Text size="regular">
                        This note will be added as a <b>general note</b>, and will be viewable only by Workspace users.
                        If you wish to add a note that is <b>related to a specific booking</b>, please{' '}
                        <b>add via the booking details page</b>.
                      </Text>
                    }
                  />
                </div>
              )}
              <Text>Does this note relate to an incident?</Text>
              <div className="mt-medium">
                {!isIncident && (
                  <>
                    <PrimaryButton className="mr-medium" onClick={() => this.setState({ isIncident: false })}>
                      No
                    </PrimaryButton>

                    <SecondaryButton color="secondary" onClick={() => this.setState({ isIncident: true })}>
                      Yes, this note relate to an incident
                    </SecondaryButton>
                  </>
                )}

                {isIncident && (
                  <>
                    <SecondaryButton
                      className="mr-medium"
                      color="secondary"
                      onClick={() => this.setState({ isIncident: false })}
                    >
                      No
                    </SecondaryButton>
                    <PrimaryButton color="red-dark" onClick={() => this.setState({ isIncident: true })}>
                      Yes, this note relate to an incident
                    </PrimaryButton>
                  </>
                )}
              </div>
            </div>
            <div className="mt-large">
              <SubTitle>Note</SubTitle>
              <div className="mt-medium">
                <Form.Item className="m-none">
                  {getFieldDecorator('noteDetails', {
                    initialValue: this.state.noteDetails,
                    rules: [{ validator: this._noteDetailsValidator }],
                  })(
                    <Input.TextArea
                      rows={4}
                      style={{ minHeight: 96 }}
                      placeholder="Enter your note content here..."
                      value={this.state.noteDetails}
                      onChange={(e) => this.setState({ noteDetails: e.target.value })}
                    />,
                  )}
                </Form.Item>
              </div>
            </div>
            {editNote && editingNote && (
              <div className="mt-medium">
                <Text color="gray-darker" weight="regular" size="regular">
                  Added by&nbsp;
                </Text>
                <Text color="gray-darker" weight="bold" size="regular">
                  {editingNote.firstName} {editingNote.lastName},&nbsp;
                  {moment(editingNote.createdOn).format('DD MMMM YYYY, hh:mm A')}
                </Text>
              </div>
            )}
            {editNote && editingNote && (editingNote.updatedBy || editingNote?.updatedByWorkerFirstName) && (
              <div>
                <Text color="gray-darker" weight="regular" size="regular">
                  Last edited by&nbsp;
                </Text>
                <Text color="gray-darker" weight="bold" size="regular">
                  {editingNote.updatedByWorkerFirstName} {editingNote.updatedByWorkerLastName},&nbsp;
                  {moment(editingNote.updatedOn).format('DD MMMM YYYY, hh:mm A')}
                </Text>
              </div>
            )}
            {(noteType === BookingType.BOOKING ||
              noteType === BookingType.ACTIVITY_RECORD ||
              noteType === 'GENERAL') && (
              <>
                <div className="mt-large">
                  <div className="flex-row">
                    <div>
                      <SubTitle>Privacy</SubTitle>
                    </div>
                    {isPrivacySettingsRestricted(serviceType, this.props.defaultVisibleType) && (
                      <div className="ml-small">
                        <Tooltip title="For Support Coordination services, editing the default privacy level for individual Notes and Attachments has been restricted at the Service Level">
                          <Icon type="question-circle" className="text-size-x-large mr-x-small" />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <div className="mt-medium">
                    <Select
                      defaultValue={editNote ? editingNote && editingNote.visibleType : defaultVisibleType}
                      dropdownMatchSelectWidth={true}
                      onChange={(event: NoteVisibleType) => {
                        this.setState({
                          visibleType: event,
                        });
                      }}
                      size="large"
                      className="width-full"
                      optionLabelProp="label"
                      disabled={isPrivacySettingsRestricted(serviceType, defaultVisibleType)}
                    >
                      {adjustedNoteVisibleOptions.map((noteVisibleOption) => {
                        const { label, value, description } = getNoteVisibleOption(noteVisibleOption);
                        return (
                          <Option key={noteVisibleOption} value={value} label={label}>
                            <Text style={{ whiteSpace: 'pre-wrap' }} lineHeight={100}>
                              <b>{label}</b>
                              <br />
                              <Text size="regular" color="secondary">
                                {description}
                              </Text>
                            </Text>
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div className="mt-large">
                  <SubTitle>Attachment (Optional)</SubTitle>

                  <div className="mt-medium">
                    {!_.isEmpty(selectedFiles) &&
                      _.map(selectedFiles, (file) => (
                        <div
                          className="mb-12 pv-medium ph-12 bg-tertiary rounded-big border-gray-darker flex-row justify-between"
                          key={file.name}
                        >
                          {file.name}
                          <HyperlinkButton
                            className="ml-small"
                            onClick={this._onRemoveFile.bind(null, file)}
                            color="red-dark"
                          >
                            Remove
                          </HyperlinkButton>
                        </div>
                      ))}
                    {!_.isEmpty(selectedDocumentsName) &&
                      _.map(selectedDocumentsName, (file) => (
                        <div
                          className="mb-12 pv-medium ph-12 bg-tertiary rounded-big border-gray-darker flex-row justify-between"
                          key={file.documentId}
                        >
                          {file.documentName}
                          <HyperlinkButton
                            className="ml-small"
                            onClick={this._onRemoveFileName.bind(null, file)}
                            color="red-dark"
                          >
                            Remove
                          </HyperlinkButton>
                        </div>
                      ))}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {selectedFiles.length + selectedDocumentsName.length >= 5 && (
                        <Text type="danger" className="text-color-warning-orange mb-12">
                          Maximum number of attachments reached (5)
                        </Text>
                      )}
                      <Upload
                        multiple={true}
                        beforeUpload={this._validateFile}
                        showUploadList={false}
                        onChange={this._onChangeFiles}
                      >
                        <SecondaryButton
                          disabled={selectedFiles.length + selectedDocumentsName.length >= 5}
                          icon="plus"
                        >
                          Add attachment
                        </SecondaryButton>
                      </Upload>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div style={{ marginTop: 27 }} className="flex justify-end">
              <div>
                <GhostButton onClick={this._onCloseModel} size="large" loading={isLoading}>
                  Cancel
                </GhostButton>
              </div>
              {editNote ? (
                <div className="ml-medium">
                  <PrimaryButton onClick={this._onPressEditNote} loading={isLoading} size="large">
                    Save changes
                  </PrimaryButton>
                </div>
              ) : (
                <div className="ml-medium">
                  <PrimaryButton onClick={this._onPressAddNote} loading={isLoading} size="large">
                    Add Note
                  </PrimaryButton>
                </div>
              )}
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddBookingNote: dispatch.bookingsStore.doAddNote,
  doEditBookingNote: dispatch.bookingsStore.doEditNote,
  doAddCustomerNotes: dispatch.customersStore.doAddCustomerNotes,
  doEditCustomerNote: dispatch.customersStore.doEditCustomerNote,
  dofetchCustomerNotes: dispatch.customersStore.dofetchCustomerNotes,
  resetCustomerNotesPageInfo: dispatch.customersStore.resetCustomerNotesPageInfo,
  doFetchSingleBooking: dispatch.bookingsStore.doFetchSingleBooking,
  getListWorkflowTemplates: dispatch.workflowStore.getListWorkflowTemplates,
});

export default connect(mapState, mapDispatch)(Form.create<IAddEditNoteModalProps>()(AddEditNoteModal));
