import React, { useContext, createContext } from 'react';
import { Editor, EditorOptions, useEditor } from '@tiptap/react';
import { Box, rem } from '@good/ui/core';

type RichTextEditorContextType = {
  fontSizes: number[];
  disabled: boolean;
  editor: Editor;
};

const initialContext: RichTextEditorContextType = {
  fontSizes: [14, 18, 24],
  disabled: false,
  editor: {} as Editor,
};

type RichTextEditorProviderProps = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  editorOptions?: Partial<EditorOptions>;
  fontSizes?: number[];
  height: number;
};

const RichTextEditorContext = createContext(initialContext);

export function RichTextEditorProvider({
  children,
  className,
  disabled,
  editorOptions,
  fontSizes = initialContext.fontSizes,
  height,
}: RichTextEditorProviderProps) {
  const editor = useEditor(editorOptions);
  const focusEditor = () => editor?.chain().focus().run();

  if (!editor) {
    return <Box className={className} h={rem(height)} />;
  }

  return (
    <RichTextEditorContext.Provider value={{ editor, disabled: disabled ?? false, fontSizes }}>
      <Box className={className} h={rem(height)} onClick={focusEditor}>
        {children}
      </Box>
    </RichTextEditorContext.Provider>
  );
}

export const useRichTextEditor = () => useContext(RichTextEditorContext);
