import { useEffect, useState } from 'react';
import { add } from 'date-fns';

import { ITimesheetsRequest } from 'stores/queries/payments/payment-queries';
import { Filters } from './types';

export function useTimesheetsRequest(filters: Filters) {
  const [timesheetsRequest, setTimesheetsRequest] = useState<ITimesheetsRequest | undefined>();

  useEffect(() => {
    setTimesheetsRequest({
      startDate: filters.dateFilter.startDate ?? add(new Date(), { years: -20 }),
      endDate: filters.dateFilter.endDate ?? add(new Date(), { years: 20 }),
      serviceIds: filters.serviceIds,
    });
  }, [filters.dateFilter, filters.serviceIds]);

  return timesheetsRequest;
}
