import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Dumbell = forwardRef(function Dumbell(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.555 2.708a1.25 1.25 0 0 0-1.11 0c-.156.078-.327.234-.915.822-.588.588-.744.759-.822.915a1.25 1.25 0 0 0 0 1.11c.078.156.234.327.822.915l2 2 2 2c.588.588.759.744.915.821.35.174.76.174 1.11 0 .156-.077.326-.233.915-.821.588-.588.744-.759.822-.915a1.25 1.25 0 0 0 0-1.11c-.078-.156-.234-.327-.822-.915l-2-2-2-2c-.588-.588-.759-.744-.915-.822ZM11.47 11.47 13.94 9l-1.47-1.47-.086-.086c-.461-.46-.818-.816-1.02-1.223a2.75 2.75 0 0 1 0-2.442c.202-.407.559-.763 1.02-1.224l.086-.085.085-.086c.46-.461.817-.818 1.224-1.02a2.75 2.75 0 0 1 2.442 0c.407.202.763.559 1.223 1.02l.086.086L19 3.94l.97-.97a.75.75 0 0 1 1.06 1.06l-.97.97 1.47 1.47.086.085c.461.46.818.817 1.02 1.224a2.75 2.75 0 0 1 0 2.442c-.202.407-.559.763-1.02 1.223l-.086.086-.086.086c-.46.461-.816.818-1.223 1.02a2.75 2.75 0 0 1-2.442 0c-.407-.202-.764-.559-1.224-1.02l-.085-.086L15 10.06l-2.47 2.47L10.06 15l1.47 1.47.086.085c.461.46.818.817 1.02 1.224a2.75 2.75 0 0 1 0 2.442c-.202.407-.559.763-1.02 1.223l-.086.086-.086.086c-.46.461-.816.818-1.223 1.02a2.75 2.75 0 0 1-2.442 0c-.407-.202-.763-.559-1.224-1.02l-.085-.086L5 20.06l-.97.97a.75.75 0 1 1-1.06-1.06l.97-.97-1.47-1.47-.086-.086c-.461-.46-.818-.816-1.02-1.223a2.75 2.75 0 0 1 0-2.442c.202-.407.559-.764 1.02-1.224l.086-.085.085-.086c.46-.461.817-.818 1.224-1.02a2.75 2.75 0 0 1 2.442 0c.407.202.763.559 1.223 1.02l.086.086L9 13.94l2.47-2.47Zm-5.94 6.999v.001l2 2c.588.588.759.744.915.822.35.173.76.173 1.11 0 .156-.078.327-.234.915-.822.588-.588.744-.759.821-.915a1.25 1.25 0 0 0 0-1.11c-.077-.156-.233-.327-.821-.915l-2-2-2-2c-.588-.588-.759-.744-.915-.822a1.25 1.25 0 0 0-1.11 0c-.156.078-.327.234-.915.822-.588.588-.744.759-.822.915a1.25 1.25 0 0 0 0 1.11c.078.156.234.327.822.915l2 1.999Z"
        fill="currentColor"
      />
    </Icon>
  );
});
