import { Box, Button, Inline, Stack, Text } from '@goodhuman-me/components';
import moment from 'moment';
import React from 'react';
import { isEmpty } from 'lodash';
import { UnavailabilityLabel } from './unavailability-label';
import { UnavailableTime } from '../../availability';
import {
  AvailabilityUnavailabilityRequestStatus as UnavailabilityStatus,
  CustomUnavailabilityOption,
  UnavailabilityType,
} from 'utilities/enum-utils';

const weekDayParser = (number: number) => {
  switch (number) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return '';
  }
};

type CustomUnavailabilityWeekDays = UnavailableTime['customUnavailabilityWeekDays'];

export const UnavailabilityCard = ({
  unavailableTime,
  onCancel,
}: {
  unavailableTime: UnavailableTime;
  onCancel: (payload: UnavailableTime) => void;
}) => {
  return (
    <Stack
      gap="$xsmall"
      borderRadius="$medium"
      borderWidth="$border200"
      borderColor="$bodyLight1"
      borderStyle="solid"
      padding="$medium"
      backgroundColor="$white"
    >
      <Inline justifyContent="space-between" alignItems="start">
        <Box>
          <UnavailabilityLabel status={unavailableTime.status} size="small" />
        </Box>
        {unavailableTime.status !== UnavailabilityStatus.COMPLETED && (
          <Button
            onClick={() => onCancel(unavailableTime)}
            emphasis="quiet"
            kind="critical"
            size="small"
            marginTop="-0.18rem"
            aria-label="Cancel"
          >
            Cancel
          </Button>
        )}
      </Inline>
      <Stack gap="$xsmall">
        <UnavailableDateFormatter unavailableTime={unavailableTime} />
        <Text size="xsmall" color="$bodyDark1">
          {unavailableTime.comment}
        </Text>
      </Stack>
    </Stack>
  );
};

// Logic to display unavailability information in <UnavailabilityCard />
const UnavailableDateFormatter = ({ unavailableTime }: { unavailableTime: UnavailableTime }) => {
  const renderOneOff = (item: UnavailableTime) => {
    return !(item.startTime && item.endTime) ? (
      <Stack>
        <Text size="small">All day</Text>
        <Text size="small">
          {moment.tz(item.startDate, item.timezone).format('ddd, DD MMM YYYY')} <Text>to</Text>{' '}
          {moment.tz(item.endDate, item.timezone).format('ddd, DD MMM YYYY')}
        </Text>
      </Stack>
    ) : (
      <Text size="small">
        {moment.tz(item.startTime, item.timezone).format('h:mm a')}{' '}
        {moment.tz(item.startDate, item.timezone).format('ddd, DD MMM YYYY')} <Text>to</Text>{' '}
        {moment.tz(item.endTime, item.timezone).format('h:mm a')}{' '}
        {moment.tz(item.endDate, item.timezone).format('ddd, DD MMM YYYY')}
      </Text>
    );
  };

  const renderCustom = (item: UnavailableTime) => {
    const isSingleDay = moment(item.endDate).diff(item.startDate, 'days') < 1;
    if (isSingleDay) {
      return renderCustomSingleDay(item.customUnavailabilityWeekDays, item);
    }

    return (
      <>
        {renderFullDayItems(item)}
        {renderSpecificItems(item)}
        {renderAvailableItems(item)}
      </>
    );
  };

  const renderCustomSingleDay = (list: CustomUnavailabilityWeekDays[], unavailableItem: UnavailableTime) => {
    return (
      !isEmpty(list) && (
        <Stack>
          <Text size="small">
            {list[0].unavailabilityOption === CustomUnavailabilityOption.UnavailableAllDay
              ? 'All day(s)'
              : list[0].unavailabilityOption === CustomUnavailabilityOption.AvailableAllDay
              ? 'Available all day'
              : list[0].timeRange.map((time, index) => (
                  <Text key={index}>
                    {moment.tz(time.startTime, unavailableItem.timezone).format('h:mm a')} -{' '}
                    {moment.tz(time.endTime, unavailableItem.timezone).format('h:mm a')}
                  </Text>
                ))}
          </Text>
          <Text size="small">
            {weekDayParser(list[0].weekDayId)}{' '}
            <Text>{moment.tz(unavailableItem.endDate, unavailableItem.timezone).format('DD MMM YYYY')}</Text>
          </Text>
        </Stack>
      )
    );
  };

  const renderFullDayItems = (unavailableItem: UnavailableTime) => {
    const fullDayItems = unavailableItem.customUnavailabilityWeekDays.filter(
      (day) => day.unavailabilityOption === CustomUnavailabilityOption.UnavailableAllDay,
    );

    if (isEmpty(fullDayItems)) {
      return;
    }

    const formattedDays = fullDayItems.map((day) => weekDayParser(day.weekDayId));
    return (
      <Stack>
        <Text size="small">All day</Text>
        <Text size="small">
          Every {new Intl.ListFormat('en').format(formattedDays)}{' '}
          <Text color="$bodyDark1">(until {moment(unavailableItem.endDate).format('DD MMM YYYY')})</Text>
        </Text>
      </Stack>
    );
  };

  const renderAvailableItems = (unavailableItem: UnavailableTime) => {
    const customUnavailabilityWeekDayList = unavailableTime.customUnavailabilityWeekDays.filter(
      (day) => day.unavailabilityOption === CustomUnavailabilityOption.AvailableAllDay,
    );

    const daysString = customUnavailabilityWeekDayList.map((day) => weekDayParser(day.weekDayId)).join(' & ');

    return (
      !isEmpty(customUnavailabilityWeekDayList) && (
        <Stack>
          <Text size="small">
            <Text fontWeight="600">Available</Text> all day
          </Text>
          <Text size="small">
            Every {daysString}{' '}
            <Text color="$bodyDark1">(until {moment(unavailableItem.endDate).format('DD MMM YYYY')})</Text>
          </Text>
        </Stack>
      )
    );
  };

  const renderSpecificItems = (unavailableItem: UnavailableTime) => {
    const customUnavailabilityWeekDayList = unavailableItem.customUnavailabilityWeekDays.filter(
      (day) => day.unavailabilityOption !== CustomUnavailabilityOption.AvailableAllDay,
    );

    const specificItems = customUnavailabilityWeekDayList.filter(
      (day) => day.unavailabilityOption !== CustomUnavailabilityOption.UnavailableAllDay,
    );
    const timeRangeLengthEqualOne = specificItems.filter(
      (day) => day.timeRange.length === CustomUnavailabilityOption.AvailableAllDay,
    );
    const theRest = specificItems.filter((day) => day.timeRange.length !== CustomUnavailabilityOption.AvailableAllDay);
    const singleTimeRangeList = {};

    timeRangeLengthEqualOne.forEach((day) => {
      const timeRange = day.timeRange;

      const hourString =
        moment.tz(timeRange[0].startTime, unavailableItem.timezone).format('h:mm a') +
        ' - ' +
        moment.tz(timeRange[0].endTime, unavailableItem.timezone).format('h:mm a');

      if (singleTimeRangeList[hourString]) {
        const newList = [...singleTimeRangeList[hourString]];
        newList.push(weekDayParser(day.weekDayId));
        singleTimeRangeList[hourString] = newList;
      } else {
        singleTimeRangeList[hourString] = [weekDayParser(day.weekDayId)];
      }
    });

    return (
      !isEmpty(specificItems) && (
        <>
          {theRest.map((day, index) => (
            <Stack key={index}>
              <Text size="small">
                {day.timeRange.map((time, index) => (
                  <Text key={index}>
                    {moment.tz(time.startTime, unavailableItem.timezone).format('h:mm a')} -{' '}
                    {moment.tz(time.endTime, unavailableItem.timezone).format('h:mm a')}
                  </Text>
                ))}
              </Text>
              <Text size="small">
                Every {weekDayParser(day.weekDayId)}{' '}
                <Text color="$bodyDark1">(until {moment(unavailableItem.endDate).format('DD MMM YYYY')})</Text>
              </Text>
            </Stack>
          ))}
          {Object.keys(singleTimeRangeList).map((timeRange) => (
            <Stack key={timeRange}>
              <Text size="small">{timeRange}</Text>
              <Text size="small">
                {singleTimeRangeList[timeRange].join(' & ')}{' '}
                <Text color="$bodyDark1">(until {moment(unavailableItem.endDate).format('DD MMM YYYY')})</Text>
              </Text>
            </Stack>
          ))}
        </>
      )
    );
  };

  return (
    <Stack gap="$medium">
      {(() => {
        switch (unavailableTime.unavailabilityType) {
          case UnavailabilityType.ONE_OFF:
            return renderOneOff(unavailableTime);
          case UnavailabilityType.CUSTOM:
            return renderCustom(unavailableTime);
          default:
            return <></>;
        }
      })()}
    </Stack>
  );
};
