import React from 'react';
import styled from 'styled-components';
import z from 'zod';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';

import apiClient from '../../../../../utilities/api-client';
import { Badge, Field, Heading, Label, Stack, StepContainer, File } from '../components';
import { PrimaryButton, SecondaryButton } from '../../../../../common-components/buttons';
import { StepProps } from '../create-workflow';
import { hasFormsOrAttachments, stepCountDisplay, triggerTypeDisplay } from '../../../workflow-template';
import { useCreateWorkflow } from '../create-workflow-provider';

const createWorkflowPayload = z.object({
  name: z.string(),
  description: z.string().optional(),
  workflowTemplateType: z.literal('PLATFORM').optional().default('PLATFORM'),
  triggerType: z.literal('INCIDENT_NOTE').default('INCIDENT_NOTE'),
  isAllowApproverFlagIssue: z.boolean(),
  isNotifyWorkflowCompleted: z.boolean(),
  isNotifyWorkflowFlagged: z.boolean(),
  steps: z.array(
    z.object({
      name: z.string(),
      description: z.string().optional(),
      stepNumber: z.number(),
      approvalType: z.literal('SPECIFIC').optional().default('SPECIFIC'),
      invalid: z.boolean().optional().default(false),
      type: z.enum(['STEP', 'TRIGGER']).optional().default('STEP'),
      // form
      formId: z.string().uuid().optional(),
      formName: z.string().optional(),
      formVersionId: z.string().uuid().optional(),
      // attachment
      attachment: z
        .object({
          file: z.object({
            uid: z.string(),
          }),
          name: z.string(),
          description: z.string().optional(),
        })
        .optional(),
      // approvers
      stepApprovers: z
        .array(
          z.object({
            displayName: z.string().nullable(),
            avatar: z.string().url().nullable().or(z.literal('')),
            approverUserId: z.string().uuid().nullable(),
          }),
        )
        .optional()
        .default([]),
    }),
  ),
});

type Payload = z.infer<typeof createWorkflowPayload>;

const FieldContent = styled.div({
  maxWidth: '52ch',
});

export function WorkflowReview(props: StepProps) {
  let { onBack } = props;
  let { state } = useCreateWorkflow();
  let history = useHistory();

  async function handleOnSubmit() {
    const payload = createWorkflowPayload.parse({
      description: state.details.description,
      name: state.details.name,
      isAllowApproverFlagIssue: state.settings.allowFlagging,
      isNotifyWorkflowCompleted: state.settings.notifyOnDone,
      isNotifyWorkflowFlagged: state.settings.notifyOnFlagged,
      steps: state.details.steps.map((step, i) => ({
        name: step.details.name,
        description: step.details.description,
        ...(step.details.actionType === 'form' && {
          formId: step.details.form.id,
          formName: step.details.form.name,
          formVersionId: step.details.form.versionId,
        }),
        ...(step.details.actionType === 'attachment' && {
          attachment: {
            description: step.details.attachment.description,
            file: {
              uid: step.details.attachment.id,
            },
            name: step.details.attachment.name,
          },
        }),
        ...(i === 0
          ? {
              stepApprovers: [
                {
                  displayName: null,
                  avatar: null,
                  approverUserId: null,
                },
              ],
            }
          : {
              stepApprovers: step.details.approvers.map((approver) => ({
                displayName: approver.name,
                avatar: approver.avatarUrl,
                approverUserId: approver.id,
              })),
            }),
        stepNumber: i,
        type: i === 0 ? 'TRIGGER' : 'STEP',
        // type: 'STEP',
      })),
    } as Payload);

    try {
      let result = await apiClient.post('/api/portal/workflow-templates/create', payload);
      if (result) history.push('/account/workflows');
      notification.success({ message: 'Workflow template created!' });
    } catch (e) {
      notification.error({ message: 'Something went wrong. Please try again.' });
    }
  }

  return (
    <>
      <StepContainer>
        <Heading>Review workflow details and publish</Heading>

        <Stack gap="xl">
          <Field width="fit-content">
            <Label>Workflow name</Label>
            <FieldContent>{state.details.name}</FieldContent>
          </Field>

          {state.details.description && (
            <Field width="fit-content">
              <Label>Workflow description</Label>
              <FieldContent>{state.details.description}</FieldContent>
            </Field>
          )}

          <Field width="fit-content">
            <Label>Workflow trigger</Label>
            <div>{triggerTypeDisplay(state.details.triggerType)}</div>
          </Field>

          <Field width="fit-content">
            <Label>Steps</Label>
            <div>{stepCountDisplay(state.details.steps)}</div>
          </Field>

          {hasFormsOrAttachments(state.details.steps) && (
            <Field width="66%">
              <Label>Forms and documents</Label>
              <Stack dir="row" gap="md" style={{ flexWrap: 'wrap' }}>
                {state.details.steps.map((s) => {
                  if (s.details.actionType === 'form')
                    return (
                      <Badge key={s.id}>
                        <File size={16} /> {s.details.form.name}
                      </Badge>
                    );
                  else if (s.details.actionType === 'attachment')
                    return <Badge key={s.id}>{s.details.attachment.name}</Badge>;
                  else return null;
                })}
              </Stack>
            </Field>
          )}
        </Stack>
      </StepContainer>

      <Stack gap="md" dir="row" style={{ alignSelf: 'end' }}>
        <SecondaryButton onClick={onBack}>Back</SecondaryButton>

        <PrimaryButton htmlType="submit" onClick={handleOnSubmit}>
          Publish workflow
        </PrimaryButton>
      </Stack>
    </>
  );
}
