import React, { useMemo } from 'react';
import { Cross } from '@good/icons';
import { InputProps, MantineSize, useMantineTheme } from '@good/ui/core';

type ClearButtonProps = {
  onClick: () => void;
  size?: InputProps['size'];
};

const isMantineSize = (size: InputProps['size']): size is MantineSize => {
  return ['xs', 'sm', 'md', 'lg', 'xl'].includes(size ?? '');
};

export const ClearInputButton = ({ onClick, size = 'md' }: ClearButtonProps) => {
  const theme = useMantineTheme();

  const fontSize: number = useMemo(() => {
    if (isMantineSize(size)) {
      const baseSizePxOrRem = theme.fontSizes[size];
      const baseSize = baseSizePxOrRem.includes('rem') ? parseFloat(baseSizePxOrRem) * 16 : parseFloat(baseSizePxOrRem);

      if (!Number.isNaN(baseSize)) {
        return baseSize * 0.625; // scales to 10px for md size
      }
    }

    return 10;
  }, [theme, size]);

  return (
    <button
      className='flex align-center justify-center p-1 rounded-full bg-neutral-weak text-weak focus:outline-none focus:bg-gray-light-1 hover:bg-gray-light-1'
      style={{ fontSize }}
      onClick={onClick}
      aria-label='clear value'
      type='button'
    >
      <Cross />
    </button>
  );
};
