import React, { Component } from 'react';
import _ from 'lodash';
import { Divider, Modal } from 'antd';

import { Text, Paragraph, Title } from 'common-components/typography';

import { IFormContent } from '../shared/form-interface';
import FormElementsRendered from './FormElementsRendered';
import { Inline, Stack } from '@goodhuman-me/components';

interface IPreviewFormModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  formContent: IFormContent;
  timezone?: string;
}
interface IPreviewFormModalState {}

class PreviewFormModal extends Component<IPreviewFormModalProps, IPreviewFormModalState> {
  render() {
    const { isOpen, onClose, formContent, timezone } = this.props;
    const { title } = formContent;

    return (
      <Modal
        centered
        visible={isOpen}
        title={
          <Inline alignItems="center" gap="$space200">
            <Title level="4" className="mv-none">
              {title.formTitle}
            </Title>
            <Text size="x2-large" color="tertiary">
              View only
            </Text>
          </Inline>
        }
        onCancel={onClose}
        className="mv-x2-large form-modal-container"
        footer={null}
        width="100vh"
      >
        <span
          className={`position-relative bg-blue pv-small ph-12 rounded-big-top`}
          style={{
            left: '-24px',
            top: '-116px',
            height: '35px',
          }}
        >
          <Text size="x2-large" color="white">
            Preview mode
          </Text>
        </span>
        <div className="position-relative" style={{ top: '-35px' }}>
          <div className={`disabled`}>
            <FormElementsRendered timezone={timezone} formContent={formContent} />
          </div>
        </div>
      </Modal>
    );
  }
}

export default PreviewFormModal;
