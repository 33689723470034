import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Maximize2 = forwardRef(function Maximize2(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 5.75a.75.75 0 0 1 0-1.5h6a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V6.81L6.81 18.25H11a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75v-6a.75.75 0 0 1 1.5 0v4.19L17.19 5.75H13Z"
        fill="currentColor"
      />
    </Icon>
  );
});
