import { Text } from '@good/components';
import { Radio, Announce } from 'design-components';
import React from 'react';
import TooltipInfo from '../components/TooltipInfo';

type ActionSelectedItemProps = {
  ariaLabel: string;
  label: string | JSX.Element;
  value: number | string;
  selectedPermission: number | string;
  tooltipText?: string;
};

const ActionSelectedItem: React.FC<ActionSelectedItemProps> = ({
  ariaLabel,
  label,
  value,
  selectedPermission,
  tooltipText,
}) => {
  const isChecked = selectedPermission === value;
  return (
    <Radio
      aria-label={ariaLabel}
      size="small"
      label={
        <div className="flex w-full flex-row flex-nowrap">
          <Text color="$muted">{label}</Text>
          {tooltipText && (
            <div className="my-auto ml-2">
              <TooltipInfo
                isShow
                placement="top left"
                message={<Text>{tooltipText}</Text>}
                content={
                  <div className="items-center">
                    <Announce color="$neutralDark1" width="$medium" height="$medium" />
                  </div>
                }
              />
            </div>
          )}
        </div>
      }
      value={value}
      wrapperStyles={{
        backgroundColor: isChecked ? '#EBF2F5' : '$white',
        width: '100%',
        padding: '$space100',
        borderRadius: '$small',
      }}
    />
  );
};

export default ActionSelectedItem;
