import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Text, SubTitle } from 'common-components/typography';
import { Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { IFormElement } from 'views/form-builder/shared/form-interface';
import { getRules, getValueFromPropertyByKey } from 'views/form-builder/shared/form-builder-utils';
import FormItem from 'antd/es/form/FormItem';
import { FormElementType } from 'views/form-builder/shared/form-enum';

interface ICurrencyInputProps {
  value?: string;
  fieldTitle?: string;
  placeholder?: string;
  caption?: string;
  isRequired?: boolean;
}

export const CurrencyInput = ({
  fieldTitle: fieldText,
  placeholder,
  value,
  caption,
  isRequired,
}: ICurrencyInputProps) => {
  const fieldTextFormat = (
    <>
      {fieldText}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bold">{fieldTextFormat}</SubTitle>
      <Input
        addonBefore="$"
        size="large"
        className="mb-medium mt-x-small bordered border-standard-gray"
        placeholder={placeholder}
        value={value}
        style={{ width: '224px' }}
      />
      <Text color="secondary">{caption}</Text>
    </div>
  );
};

interface IEditCurrencyInputProps extends FormComponentProps {
  value?: string;
  element: IFormElement;
}

export const EditCurrencyInput = ({ value, element, form }: IEditCurrencyInputProps) => {
  const { id, parentId } = element;
  const [inputValue, setInputValue] = useState<string>('');
  const [detectedSafari, setDetectedSafari] = useState(false);

  useEffect(() => {
    if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) setDetectedSafari(true);
  }, []);

  const placeholder = getValueFromPropertyByKey(element.properties.appearance, 'placeholderText');
  const caption = getValueFromPropertyByKey(element.properties.appearance, 'captionText');
  const rules = getRules(element, false);
  const checkMin: boolean = getValueFromPropertyByKey(element.properties.configuration, 'minimum');
  const checkMax: boolean = getValueFromPropertyByKey(element.properties.configuration, 'maximum');
  const isRequired = getValueFromPropertyByKey(element.properties.general, 'require');
  const reg = /^(0|[1-9][0-9]*)(\.[0-9]*)?$/;
  let fieldText = getValueFromPropertyByKey(element.properties.general, 'fieldTitle');

  if (isRequired) {
    fieldText = (
      <>
        {fieldText}
        <Text color="red"> *</Text>
      </>
    );
  }

  if (checkMin && checkMax) {
    rules.push({
      validator: (rule: any, value: string, cb: (msg?: string) => void) => {
        if ((isNaN(Number(value)) && !reg.test(value)) || value === '-') {
          cb('Invalid Dolla Amount');
        } else {
          cb();
        }
      },
    });
  }

  if (checkMin) {
    const min = getValueFromPropertyByKey(element.properties.configuration, 'minimumValue');

    rules.push({
      validator: (rule: any, value: string, cb: (msg?: string) => void) => {
        parseFloat(value) < parseFloat(min) ? cb(`Minimum is ${min}`) : cb();
      },
    });
  }

  if (checkMax) {
    const max = getValueFromPropertyByKey(element.properties.configuration, 'maximumValue');
    rules.push({
      validator: (rule: any, value: string, cb: (msg?: string) => void) => {
        parseFloat(value) > parseFloat(max) ? cb(`Maximum is ${max}`) : cb();
      },
    });
  }

  const handleChangeInputNumber = (e) => {
    if (!detectedSafari) return;

    if (e.target.value) setInputValue(e.target.value);
  };

  const handleInputNumber = (e) => {
    if (!detectedSafari) return;

    const specialCharRegex = new RegExp(/[0-9]|[\b]|[eE]/g);
    const keyCodeOfComma = [188, 190, 194];
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (keyCodeOfComma.includes(e.keyCode)) {
      if (inputValue.includes('.')) {
        e.preventDefault();
        return false;
      }
      setInputValue(`${inputValue}.`);
      return true;
    }
    if (!specialCharRegex.test(pressedKey) || (pressedKey === 'E' && inputValue.includes('e'))) {
      e.preventDefault();
      return false;
    }
    setInputValue(
      pressedKey === '\b'
        ? inputValue.slice(0, -1)
        : pressedKey === 'E'
        ? `${inputValue}e`
        : `${inputValue}${pressedKey}`,
    );
  };

  const { getFieldDecorator } = form;
  return (
    <div className="flex-column width-full">
      <SubTitle weight="bold">{fieldText}</SubTitle>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'value'] as never, {
          initialValue: value,
          rules: rules,
        })(
          <Input
            addonBefore="$"
            size="large"
            type="number"
            className="mb-medium mt-x-small bordered border-standard-gray"
            placeholder={placeholder}
            onKeyDown={handleInputNumber}
            onChange={handleChangeInputNumber}
            style={{ width: '224px' }}
          />,
        )}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'type'] as never, {
          initialValue: FormElementType.CURRENCY,
        })(<Input type="hidden" />)}
      </FormItem>

      <FormItem className="m-none">
        {getFieldDecorator([id, 'parentElementId'] as never, {
          initialValue: parentId,
        })(<Input type="hidden" />)}
      </FormItem>

      <Text color="secondary">{caption}</Text>
    </div>
  );
};
