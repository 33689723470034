import _ from 'lodash';
import React from 'react';

import Map from 'common-components/map/Map';
import { Text } from 'common-components/typography';

interface CheckInLocationProps {
  action: string;
  latitude: string | number;
  longitude: string | number;
}

const CheckInLocation = ({ action, latitude, longitude }: CheckInLocationProps) => {
  return (
    <div>
      <Text>Check {_.includes(action, 'started') ? 'in' : 'out'} location:</Text>
      <Map lattitude={_.toNumber(latitude)} longitude={_.toNumber(longitude)} />
    </div>
  );
};

export default CheckInLocation;
