import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputNumber } from 'antd';
import { Stack, Inline, Text } from '@goodhuman-me/components';

import { colorValues } from 'design-components/theme/tokens';
import CancelBookingFieldError from '../CancelBookingFieldError';
import { Schema, SchemaFieldNames } from '../../schema';
import { IField } from '../../fields';

interface ICancelBookingNumberInputProps {
  field: IField<SchemaFieldNames, number>;
  disabled?: boolean;
  precision?: number;
  step?: number;
  max?: number;
  extra?: string | JSX.Element;
}

const CancelBookingNumberInput = ({
  field,
  disabled,
  precision,
  step = 1,
  max,
  extra,
}: ICancelBookingNumberInputProps): JSX.Element => {
  const { control } = useFormContext<Schema>();

  return (
    <Controller
      name={field.name}
      defaultValue={field.defaultValue}
      control={control}
      rules={field.rules(max)}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
        <Stack gap="$xsmall">
          <Inline gap="$space100" alignItems="center">
            <InputNumber
              type="number"
              onChange={onChange}
              onBlur={onBlur}
              value={Number(value)}
              defaultValue={field.defaultValue}
              aria-label={field.name}
              precision={precision}
              step={step}
              min={0}
              style={{ width: 70, borderColor: error ? colorValues.redDark1 : undefined }}
              disabled={disabled}
              ref={ref}
            />
            <Text fontWeight={500}>{extra}</Text>
          </Inline>
          <CancelBookingFieldError message={error?.message} />
        </Stack>
      )}
    />
  );
};

export default CancelBookingNumberInput;
