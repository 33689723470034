import { Tabs, notification } from 'antd';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { TEAM_MANANGEMENT_TABS } from 'views/team-v2/utils/constants';

type TmMgmtTabsParams = {
  setSelectedTab: Dispatch<SetStateAction<TEAM_MANANGEMENT_TABS>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

type TeamMemberTabContentParams = {
  title: string;
  count: number;
};

const TeamMemberTabContent = ({ title, count }: TeamMemberTabContentParams) => (
  <span className="flex items-center">
    <span className="flex-1">{title}</span>
    <span className="ml-2 min-w-[30px] flex-1 rounded-lg border-2 border-solid border-[#ebebeb] p-1 text-center">
      {count}
    </span>
  </span>
);

export const TmMgmtTabs = ({ setSelectedTab, setIsLoading }: TmMgmtTabsParams) => {
  const { TabPane } = Tabs;
  const { uninvitedTeamList, accountTeamList, invitesTeamList } = useSelector((state: IRootState) => state.teamStore);
  const {
    teamStore: { doFetchUninvited, doFetchAccountTeamList, doFetchInvitations },
  } = useDispatch<IRootDispatch>();

  const [page] = useState(1);
  const [pageSize] = useState(20);
  const [pageTimestamp] = useState(new Date());

  const refreshLists = useCallback(() => {
    setIsLoading(true);
    // eslint-disable-next-line promise/catch-or-return -- has a catch
    Promise.all([
      doFetchUninvited({
        page: page,
        pageTimestamp: pageTimestamp,
      }).catch(() => {
        notification.error({ message: 'Error while fetching invited team members' });
      }),
      doFetchAccountTeamList({
        page: page,
        pageSize: pageSize,
        pageTimestamp: pageTimestamp,
      }).catch(() => {
        notification.error({ message: 'Error while fetching team members' });
      }),
      doFetchInvitations({}).catch(() => {
        notification.error({ message: 'Error while fetching invited team members' });
      }),
    ])
      .catch(() => {
        notification.error({ message: 'Error while fetching team members' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [doFetchAccountTeamList, doFetchUninvited, doFetchInvitations, setIsLoading, page, pageSize, pageTimestamp]);

  useEffect(() => {
    refreshLists();
  }, [refreshLists]);

  const onChange = (key: string) => {
    switch (key) {
      case TEAM_MANANGEMENT_TABS.INVITES:
        setSelectedTab(TEAM_MANANGEMENT_TABS.INVITES);
        break;
      case TEAM_MANANGEMENT_TABS.UNINVITED:
        setSelectedTab(TEAM_MANANGEMENT_TABS.UNINVITED);
        break;
      default:
        setSelectedTab(TEAM_MANANGEMENT_TABS.TEAM);
        break;
    }
  };

  const inviteTabTotalTeamMember =
    invitesTeamList?.pendingList && invitesTeamList?.revokedList
      ? invitesTeamList.pendingList.length + invitesTeamList.revokedList.length
      : 0;

  return (
    <Tabs defaultActiveKey='WORKERS' animated={true} onChange={onChange}>
      <TabPane
        tab={<TeamMemberTabContent title='Team members' count={accountTeamList.length} />}
        key={TEAM_MANANGEMENT_TABS.TEAM}
      />
      <TabPane
        tab={<TeamMemberTabContent title='Invited' count={inviteTabTotalTeamMember} />}
        key={TEAM_MANANGEMENT_TABS.INVITES}
      />
      <TabPane
        tab={<TeamMemberTabContent title='Draft Invites' count={uninvitedTeamList.length} />}
        key={TEAM_MANANGEMENT_TABS.UNINVITED}
      />
    </Tabs>
  );
};
