import React from 'react';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';
import { ExportModals } from 'utilities/enum-utils';
import { ExportDataModal } from './export-data-modal';

export const ExportDataModals = ({
  openModal,
  setOpenModal,
}: {
  openModal: ExportModals;
  setOpenModal: (modal?: ExportModals) => void;
}) => {
  const {
    accountStore: {
      doFetchExportCustomerData,
      doFetchExportBookingData,
      doFetchExportTeamMemberGeneralInfoData,
      doFetchExportTeamMemberTags,
      doFetchExportTeamMemberAvailabilityData,
      doFetchExportTeamMemberDocumentData,
      doFetchExportStaffAdditionalClaimsData,
      doFetchExportIncidentData,
      doFetchExportPinnedAlertsData
    },
  } = useDispatch<IRootDispatch>();

  const setModalProps = {
    [ExportModals.CUSTOMER]: {
      modalName: 'customer',
      csvName: 'Customer',
      doFetchData: doFetchExportCustomerData,
      additionalDataFields: [],
    },
    [ExportModals.BOOKING]: {
      modalName: 'booking',
      csvName: 'Booking',
      doFetchData: doFetchExportBookingData,
      additionalDataFields: [],
    },
    [ExportModals.TEAM_MEMBER_GENERAL]: {
      modalName: `team members' general info`,
      csvName: 'TeamMemberGeneral',
      doFetchData: doFetchExportTeamMemberGeneralInfoData,
      additionalDataFields: [],
    },
    [ExportModals.TEAM_MEMBER_TAGS]: {
      modalName: `team members' tags`,
      csvName: 'TeamMemberTags',
      doFetchData: doFetchExportTeamMemberTags,
      additionalDataFields: [],
    },
    [ExportModals.TEAM_MEMBER_AVAILABILITIES]: {
      modalName: `team members' availabilities`,
      csvName: 'TeamMemberAvailability',
      doFetchData: doFetchExportTeamMemberAvailabilityData,
      additionalDataFields: [],
    },
    [ExportModals.TEAM_MEMBER_DOCUMENTS]: {
      modalName: `team members' documents`,
      csvName: 'TeamMemberDocument',
      doFetchData: doFetchExportTeamMemberDocumentData,
      additionalDataFields: [],
    },
    [ExportModals.STAFF_ADDTIONAL_CLAIMS]: {
      modalName: 'staff additional claims',
      doFetchData: doFetchExportStaffAdditionalClaimsData,
      csvName: 'StaffAdditionalClaims',
      additionalDataFields: [
        { label: 'First Name', value: 'workerFirstName' },
        { label: 'Last Name', value: 'workerLastName' },
        { label: 'Additional Cost', value: 'additionalCost' },
        { label: 'Reference Id', value: 'referenceId' },
        { label: 'Start Date', value: 'endDateTime' },
        { label: 'End Date', value: 'endDateTime' },
      ],
    },
    [ExportModals.INCIDENT]: {
      modalName: 'incidents',
      csvName: 'Incident',
      doFetchData: doFetchExportIncidentData,
      additionalDataFields: [],
    },
    [ExportModals.PINNED_ALERTS]: {
      modalName: 'pinned alerts',
      csvName: 'PinnedAlerts',
      doFetchData: doFetchExportPinnedAlertsData,
      additionalDataFields: [],
    },
  };
  return (
    <ExportDataModal
      doFetchData={setModalProps[openModal].doFetchData}
      exportName={setModalProps[openModal].modalName}
      csvName={setModalProps[openModal].csvName}
      additionalDataFields={setModalProps[openModal].additionalDataFields}
      onClose={() => setOpenModal(undefined)}
    />
  );
};
