import { forwardRef } from 'react';
import { Check } from '@good/icons';
import { ListBoxItem as _Item, ListBoxItemProps as _ItemProps } from 'react-aria-components';
import * as styles from './item.css';
import { twMerge } from 'tailwind-merge';

export type ItemProps = _ItemProps & { className?: string; children: string };

export const Item = forwardRef<HTMLDivElement, ItemProps>((props, ref) => {
  const { children, className, ...otherProps } = props;
  const { container, iconContainer, icon } = styles.item();

  return (
    <_Item {...otherProps} ref={ref} className={twMerge(className, container())} textValue={children}>
      {({ isSelected }) => (
        <>
          {children}
          {isSelected && (
            <span className={iconContainer()}>
              <Check className={icon()} />
            </span>
          )}
        </>
      )}
    </_Item>
  );
});

Item.displayName = 'Item';
