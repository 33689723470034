import React, { useState } from 'react';

import { ROSTER_WEEKLY_CONFIG } from 'common-components/roster-control/roster-weekly/roster-weekly-config';
import { PORTAL_COLORS } from 'interfaces/common-ui-types';
import { Icon } from 'antd';
import { Popover } from '@blueprintjs/core';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/stores/rematch/root-store';

const { CONTENT_PANEL_WIDTH, SIDEBAR_WIDTH } = ROSTER_WEEKLY_CONFIG;

interface DayColumnProps {
  content: any;
  backgroundColor?: PORTAL_COLORS;
  hasAddAction?: boolean;
}

export function DayColumn({ content, backgroundColor, hasAddAction = false }: DayColumnProps) {
  const navigationStore = useSelector((state: IRootState) => state.navigationStore);

  const { sideMenuCollapsed } = navigationStore;

  const [isHovered, setIsHovered] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onOpen = () => setIsDialogOpen(true);
  const onClose = () => setIsDialogOpen(false);

  const bgColor = `bg-${backgroundColor}`;

  const columnWidth = sideMenuCollapsed
    ? `calc((100vw - ${CONTENT_PANEL_WIDTH}px) / 7)`
    : `calc((100vw - ${CONTENT_PANEL_WIDTH + SIDEBAR_WIDTH}px) / 7)`;

  return (
    <div
      style={{ minWidth: columnWidth, maxWidth: columnWidth, minHeight: '100%' }}
      className={`bordered-right position-relative bordered-bottom ${bgColor}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {hasAddAction && (isHovered || isDialogOpen) && (
        <div className="position-absolute" style={{ bottom: 4, right: 4 }}>
          <Popover
            content={
              <ActionMenu>
                <ActionMenuItem text={'Add new booking...'} />
              </ActionMenu>
            }
            position={'bottom-right'}
            usePortal={false}
            targetTagName={'span'}
            onOpening={onOpen}
            onClose={onClose}
          >
            <div
              className="bg-white p-x-small rounded bordered border-blue-action flex-row align-center justify-center cursor-pointer"
              style={{ width: '24px', height: '24px' }}
            >
              <Icon type="plus" style={{ fontSize: '13px' }} className="text-color-blue-action" />
            </div>
          </Popover>
        </div>
      )}

      {content}
    </div>
  );
}
