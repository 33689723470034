import React, { useState } from 'react';

import moment from 'moment';

import * as H from 'history';

import { Popover } from '@blueprintjs/core';

import TextTag from 'common-components/tags/TextTag';
import { Text } from 'common-components/typography';

import { ROSTER_WEEKLY_CONFIG } from 'common-components/roster-control/roster-weekly/roster-weekly-config';
import { IRosterShift } from 'common-components/roster-control/interfaces/roster-interfaces';
import OpenSlotPopover from 'common-components/roster-control/roster-weekly/workers/OpenSlotPopover';
import { BookingTypeIcon } from 'common-components/tags';
import { BookingType } from 'utilities/enum-utils';

const { ITEM_CARD_HEIGHT } = ROSTER_WEEKLY_CONFIG;

export function OpenSlotCard({
  shift,
  refreshData,
  history,
}: {
  shift: IRosterShift;
  refreshData: () => void;
  canClosePopover?: boolean;
  history: H.History;
}) {
  const { shiftStartDateTime, shiftEndDateTime, serviceName, duplicateCount } = shift;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [canClosePopover, setCanClosePopover] = useState<boolean>(true);

  const mStartDate = moment(shiftStartDateTime);
  const mEndDate = moment(shiftEndDateTime);

  const startDateDisplay = mStartDate.format('h:mm a');
  const endDateDisplay = mEndDate.format('h:mm a');

  const openClosePopover = (nextOpenState) => {
    if (canClosePopover) {
      setIsOpen(nextOpenState);
    }
  };

  const refreshAndCloseData = () => {
    setIsOpen(false);
    refreshData();
  };

  return (
    <div className="p-x-small cursor-pointer" style={{ width: '100%' }}>
      <Popover
        content={
          <OpenSlotPopover
            shift={shift}
            history={history}
            setCanClosePopover={setCanClosePopover}
            refreshAndCloseData={refreshAndCloseData}
          />
        }
        targetTagName={'div'}
        position="right-bottom"
        key={shift.supportWorkerAttendanceId}
        isOpen={isOpen}
        onInteraction={(nextOpenState) => openClosePopover(nextOpenState)}
      >
        <div
          className="bg-orange-lightest rounded-big p-small line-height-120 bordered border-orange-lighter border-dashed anim-fade-in-fast"
          style={{ width: '100%', minHeight: `${ITEM_CARD_HEIGHT}px` }}
        >
          <div className="flex-row">
            {/* Show only if duplicate count is more than 1 v*/}
            <div className="inline-block">
              {duplicateCount > 1 && (
                <TextTag content={`${duplicateCount}x`} color={'orange-light'} size="small" className="mr-x-small" />
              )}
            </div>

            <div className="inline-block">
              <TextTag content="Open slot" size="small" theme="light" color="orange" backgroundColor="white" />
            </div>
          </div>

          <div className="whitespace-nowrap overflow-hidden text-overflow-ellipsis" style={{ width: '100%' }}>
            <Text size="regular" lineHeight={120}>
              <b>{startDateDisplay}</b> - {endDateDisplay}
            </Text>
            <br />
            <div className={'flex-row align-center'}>
              <div style={{ minWidth: '16px' }}>
                <BookingTypeIcon
                  type={
                    shift.bookingType === BookingType.ACTIVITY_RECORD ? BookingType.ACTIVITY_RECORD : shift.serviceType
                  }
                  className={'mr-x-small'}
                />
              </div>
              <Text size="regular" lineHeight={120}>
                {serviceName}
              </Text>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}
