import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';

interface SearchInputProps {
  height: number;
  isEnabled?: boolean;
}

export function SearchInput({ height, isEnabled = false }: SearchInputProps) {
  return (
    <div className="flex-row align-center bg-quaternary pv-small ph-x-large bordered" style={{ height: `${height}px` }}>
      {isEnabled && (
        <>
          <Icon type="search" className="text-color-tertiary" />
          <Text color="tertiary" size="regular">
            Search for a name...
          </Text>
        </>
      )}
    </div>
  );
}
