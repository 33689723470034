import { Avatar, Col, Tag } from 'antd';
import { GridRow } from 'common-components/grids';
import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IRootState, state } from 'stores/rematch/root-store';
import { SupportWorkerAlertType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import { WithRouterProps, withRouter } from 'utilities/with-router';
import TeamListingAlerts from './TeamListingAlerts';

type WorkerItem = unknown & {
  firstName: string;
  lastName: string;
  attachmentUrl: string | null;
  supportWorkerId: string;
  userLocationByState: string | null;
  availabilityChangeRequested?: number | null;
  alerts: { supportWorkerAlertType: SupportWorkerAlertType; numberOfAlerts: number }[];
};

type IWorkerListingItemV2Props = {
  workerItem: WorkerItem;
  portalUser: typeof state.authStore.portalUser;
} & WithRouterProps;

class WorkerListingItemV2 extends Component<IWorkerListingItemV2Props, unknown> {
  state = { isHovered: false };

  setHovered = () => this.setState({ isHovered: true });

  setUnhovered = () => this.setState({ isHovered: false });

  _getSupportWorkerHref = (): string => {
    const { portalUser, workerItem } = this.props;
    return portalUser &&
      PermissionUtils.validatePermission('ViewTeamMemberProfile', portalUser.permissions.permissionRoles)
      ? `/team/details/${workerItem.supportWorkerId}`
      : '#';
  };

  render() {
    const { workerItem } = this.props;

    return (
      <Link to={this._getSupportWorkerHref()}>
        <GridRow onMouseEnter={this.setHovered} onMouseLeave={this.setUnhovered}>
          <Col span={5}>
            <div className='align-center flex-row'>
              <Avatar className='mr-medium' size='large' icon='user' src={workerItem.attachmentUrl} />
              <Text weight='bold'>{`${workerItem.firstName} ${workerItem.lastName}`}</Text>
            </div>
          </Col>
          <Col span={4}>
            <Text>{workerItem.userLocationByState}</Text>
          </Col>
          <Col span={5}>
            {workerItem.availabilityChangeRequested ? (
              <Tag color='orange' className='text-size-regular'>
                {`${workerItem.availabilityChangeRequested} ${
                  workerItem.availabilityChangeRequested > 1 ? 'pending requests' : 'pending request'
                }`}
              </Tag>
            ) : (
              '-'
            )}
          </Col>
          <Col span={10}>
            <TeamListingAlerts alerts={workerItem.alerts} />
          </Col>
        </GridRow>
      </Link>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

export default connect(mapState, null)(withRouter(WorkerListingItemV2));
