import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Gear = forwardRef(function Gear(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25A2.75 2.75 0 0 0 9.25 4v.752a7.706 7.706 0 0 0-.43.179l-.532-.532a2.75 2.75 0 0 0-3.89 3.889l.533.532a7.7 7.7 0 0 0-.179.43H4a2.75 2.75 0 1 0 0 5.5h.752c.056.146.115.289.179.43l-.532.532a2.75 2.75 0 0 0 0 3.89l.53-.53-.53.53a2.75 2.75 0 0 0 3.889 0l.532-.533c.141.064.284.123.43.179V20a2.75 2.75 0 1 0 5.5 0v-.752c.146-.056.289-.115.43-.179l.532.532a2.75 2.75 0 0 0 3.89-3.889l-.533-.532c.064-.141.123-.284.179-.43H20a2.75 2.75 0 1 0 0-5.5h-.752a7.667 7.667 0 0 0-.179-.43l.532-.532a2.75 2.75 0 0 0 0-3.89l-.53.53.53-.53a2.75 2.75 0 0 0-3.889 0l-.532.533a7.694 7.694 0 0 0-.43-.179V4A2.75 2.75 0 0 0 12 1.25ZM10.75 4a1.25 1.25 0 0 1 2.5 0v1.29a.75.75 0 0 0 .536.719c.415.123.813.289 1.187.492a.75.75 0 0 0 .887-.13l.913-.912a1.25 1.25 0 1 1 1.768 1.768l-.913.912a.75.75 0 0 0-.129.888c.203.374.369.772.492 1.187a.75.75 0 0 0 .72.536H20a1.25 1.25 0 1 1 0 2.5h-1.29a.75.75 0 0 0-.719.536c-.123.415-.289.813-.492 1.187a.75.75 0 0 0 .13.887l.912.913a1.25 1.25 0 1 1-1.768 1.768l-.912-.913a.75.75 0 0 0-.888-.129 6.207 6.207 0 0 1-1.187.492.75.75 0 0 0-.536.72V20a1.25 1.25 0 1 1-2.5 0v-1.29a.75.75 0 0 0-.536-.719 6.205 6.205 0 0 1-1.187-.492.75.75 0 0 0-.888.13l-.912.912a1.25 1.25 0 1 1-1.768-1.768l.913-.912a.75.75 0 0 0 .129-.888 6.209 6.209 0 0 1-.492-1.187.75.75 0 0 0-.72-.536H4a1.25 1.25 0 1 1 0-2.5h1.29a.75.75 0 0 0 .719-.536c.123-.415.289-.813.492-1.187a.75.75 0 0 0-.13-.888l-.912-.912a1.25 1.25 0 0 1 1.768-1.768l.912.913a.75.75 0 0 0 .888.129 6.205 6.205 0 0 1 1.187-.492.75.75 0 0 0 .536-.72V4Zm-1 8a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM12 8.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
