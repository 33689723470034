import { v4 as uuid } from 'uuid';
import {
  AFFECT_TO_ELEMENT_PROPERTY,
  CAPTION_TEXT_PROPERTY,
  EVENT_PROPERTY,
  FIELD_TITLE_PROPERTY,
  PLACEHOLDER_PROPERTY,
  REPEATABLE_PROPERTY,
  REQUIRED_PROPERTY,
  RULES_PROPERTY,
  TARGET_ELEMENT_PROPERTY,
  TARGET_OPTION_PROPERTY,
} from '../common-field-constants';
import {
  LabelCalendar,
  LabelNumber,
  LabelOption,
  LabelPhoneNumber,
  LabelShortText,
  LabelSingleSelection,
} from '../common-label-constants';
import { FormElementType, IntakeFormElementType, PropType, UserActionType } from '../form-enum';
import { IFormElement } from '../form-interface';

const RULE_NUMBER = [
  {
    max: 50,
    message: 'Value should be less than 50 character. ',
  },
  {
    pattern: `^[0-9]*$`,
    message: 'Must be in numeric format. ',
  },
];

//#region Mobile phone number
const MOBILE_PHONE_NUMBER: IFormElement = {
  id: uuid(),
  name: 'Mobile phone number',
  type: FormElementType.PHONE_NUMBER,
  label: LabelPhoneNumber,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Mobile phone number',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_MOBILE_NUMBER,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};
//#endregion

//#region Home phone number
const HOME_PHONE_NUMBER: IFormElement = {
  id: uuid(),
  name: 'Home phone number',
  type: FormElementType.PHONE_NUMBER,
  label: LabelPhoneNumber,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Home phone number',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_HOME_NUMBER,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};
//#endregion

//#region Date of birth
const DATE_OF_BIRTH: IFormElement = {
  id: uuid(),
  name: 'Date of birth',
  type: FormElementType.DATE_TIME,
  label: LabelCalendar,
  isHideConfig: true,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Date of birth',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_DATE_OF_BIRTH,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: 'dateTimeType',
        name: 'Date/time TYPE',
        value: 'Date only',
        options: ['Date only'],
        caption: 'Determines whether date & time should be shown for this field.',
        type: PropType.Select,
      },
      {
        key: 'constraint',
        name: 'CONSTRAINTS',
        value: 'Allow past dates only',
        options: ['Allow past dates only'],
        caption: 'Determines what dates can be chosen',
        dependOn: 'dateTimeType',
        type: PropType.selectDepend,
      },
    ],
  },
};
//#endregion

//#region Email address
const EMAIL_ADDRESS: IFormElement = {
  id: uuid(),
  name: 'Email address',
  type: FormElementType.SHORT_TEXT,
  label: LabelShortText,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Email address',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_EMAIL_ADDRESS,
      },
      { ...RULES_PROPERTY, rules: [{ type: 'email', message: 'Please enter a valid email' }] },
      REQUIRED_PROPERTY,
    ],
    appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter email here' }, CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};
//#endregion

//#region Home address
const HOME_ADDRESS: IFormElement = {
  id: uuid(),
  name: 'Home address',
  type: FormElementType.ADDRESS_LOOKUP,
  label: LabelShortText,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Home address',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_HOME_ADDRESS,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Search address here' }, CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};
//#endregion

//#region Gender
const GENDER: IFormElement = {
  id: uuid(),
  name: 'Gender',
  type: FormElementType.DROPDOWN,
  label: LabelOption,
  properties: {
    general: [
      { ...FIELD_TITLE_PROPERTY, value: 'Gender', fieldType: IntakeFormElementType.GENERAL_INFORMATION_GENDER },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Select gender' }],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: ['Male', 'Female', 'Other', 'Prefer not to say'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};
//#endregion

//#region Local Government Area
const LOCAL_GOVERNMENT_AREA: IFormElement = {
  id: uuid(),
  name: 'Local Government Area',
  type: FormElementType.SHORT_TEXT,
  label: LabelShortText,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Local Government Area',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_LGA,
      },
      REQUIRED_PROPERTY,
      { ...RULES_PROPERTY, rules: [{ max: 255, message: 'The maximum length of this field is 255 characters' }] },
    ],
    appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter LGA here' }, CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};
//#endregion

//#region Hobbies and interests
const HOBBIES_AND_INTERESTS: IFormElement = {
  id: uuid(),
  name: 'Hobbies and interests',
  type: FormElementType.DROPDOWN_MULTI_SELECT,
  label: LabelOption,
  isHideConfig: true,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Hobbies and interests',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_HOBBIES,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Select 1 or more hobbies and interests' }],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: [],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};
//#endregion

//#region Religion
const RELIGION: IFormElement = {
  id: uuid(),
  name: 'Religion',
  type: FormElementType.DROPDOWN,
  label: LabelOption,
  isHideConfig: true,
  properties: {
    general: [
      { ...FIELD_TITLE_PROPERTY, value: 'Religion', fieldType: IntakeFormElementType.GENERAL_INFORMATION_RELIGION },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Select religion' }],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: [],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};
//#endregion

//#region Language
const LANGUAGE: IFormElement = {
  id: uuid(),
  name: 'Language(s)',
  type: FormElementType.DROPDOWN_MULTI_SELECT,
  label: LabelOption,
  isHideConfig: true,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Language(s)',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_LANGUAGES,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Select 1 or more languages' }],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: [],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};
//#endregion

//#region Country of birth
const COUNTRY_OF_BIRTH: IFormElement = {
  id: uuid(),
  name: 'Country of birth',
  type: FormElementType.DROPDOWN,
  isHideConfig: true,
  label: LabelOption,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Country of birth',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_COUNTRY_OF_BIRTH,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Select country of birth' }],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: [],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};
//#endregion

//#region Aboriginal or Torres Strait Islander Status
const ISLANDER_STATUS: IFormElement = {
  id: uuid(),
  name: 'Aboriginal or Torres Strait Islander Status',
  type: FormElementType.SINGLE_CHOICE,
  label: LabelSingleSelection,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Aboriginal or Torres Strait Islander Status',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_STRAIT_ISLANDER_STATUS,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: ['No', 'Yes', 'Prefer not to say'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};
//#endregion

//#region Medicare number
const MEDICARE_NUMBER: IFormElement = {
  id: uuid(),
  name: 'Medicare number',
  type: FormElementType.SHORT_TEXT,
  label: LabelNumber,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Medicare number',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_MEDICARE_NUMBER,
      },
      REQUIRED_PROPERTY,
      {
        ...RULES_PROPERTY,
        rules: [
          {
            pattern: `^[0-9]{10}$`,
            message: 'Must be in numeric format and contain 10 digits.',
          },
        ],
      },
    ],
    appearance: [{ ...PLACEHOLDER_PROPERTY, value: '00 0000 0000' }, CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};
//#endregion

//#region Ambulance number
const AMBULANCE_NUMBER: IFormElement = {
  id: uuid(),
  name: 'Ambulance number',
  type: FormElementType.SHORT_TEXT,
  label: LabelNumber,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Ambulance number',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_AMBULANCE_NUMBER,
      },
      REQUIRED_PROPERTY,
      { ...RULES_PROPERTY, rules: RULE_NUMBER },
    ],
    appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter ambulance membership number...' }, CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};
//#endregion

//#region Companion Card number
const COMPANION_CARD_NUMBER: IFormElement = {
  id: uuid(),
  name: 'Companion Card number',
  type: FormElementType.SHORT_TEXT,
  label: LabelNumber,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Companion Card number',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_COMPANION_CARD,
      },
      REQUIRED_PROPERTY,
      { ...RULES_PROPERTY, rules: RULE_NUMBER },
    ],
    appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter companion card number...' }, CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};
//#endregion

//#region Centrelink number
const CENTRELINK_NUMBER: IFormElement = {
  id: uuid(),
  name: 'Centrelink number',
  type: FormElementType.SHORT_TEXT,
  label: LabelNumber,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Centrelink number',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_CENTRELINK_NUMBER,
      },
      REQUIRED_PROPERTY,
      { ...RULES_PROPERTY, rules: RULE_NUMBER },
    ],
    appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter Centrelink number...' }, CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};
//#endregion

//#region Living arrangements
const LIVING_ARRANGEMENTS: IFormElement = {
  id: uuid(),
  name: 'Living arrangements',
  type: FormElementType.LONG_TEXT,
  label: LabelShortText,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Living arrangements',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_LIVING_ARRANGEMENTS,
      },
      REQUIRED_PROPERTY,
      {
        ...RULES_PROPERTY,
        rules: [
          {
            max: 500,
            message: 'The maximum length of this field is 500 characters',
          },
        ],
      },
    ],
    appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Type living situation here' }, CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};
//#endregion

//#region Requires translator
const REQUIRE_TRANSLATOR_BUNDLE_ID = uuid();
const REQUIRE_TRANSLATOR_ELEMENT_ID = uuid();
const SELECT_REQUIRE_TRANSLATOR: IFormElement = {
  id: REQUIRE_TRANSLATOR_ELEMENT_ID,
  parentId: REQUIRE_TRANSLATOR_BUNDLE_ID,
  name: 'Requires translator',
  type: FormElementType.SINGLE_CHOICE,
  label: LabelSingleSelection,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Translator required?',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_REQUIRES_TRANSLATOR,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: ['No', 'Yes'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const TRANSLATOR_LANGUAGE_DROPDOWN_ID = uuid();
const TRANSLATOR_LANGUAGE_DROPDOWN: IFormElement = {
  id: TRANSLATOR_LANGUAGE_DROPDOWN_ID,
  name: 'Translator language',
  type: FormElementType.DROPDOWN,
  label: LabelOption,
  isHideConfig: true,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Translator language',
        fieldType: IntakeFormElementType.GENERAL_INFORMATION_TRANSLATOR_LANGUAGE,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Select language' }],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: [],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const REQUIRE_TRANSLATOR_CONDITIONAL_ID = uuid();
const REQUIRE_TRANSLATOR_CONDITIONAL: IFormElement = {
  id: uuid(),
  parentId: REQUIRE_TRANSLATOR_BUNDLE_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: REQUIRE_TRANSLATOR_ELEMENT_ID,
        options: [SELECT_REQUIRE_TRANSLATOR],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'Yes',
        options: ['No', 'Yes'],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [TRANSLATOR_LANGUAGE_DROPDOWN_ID],
        options: [TRANSLATOR_LANGUAGE_DROPDOWN],
      },
      {
        ...REPEATABLE_PROPERTY,
      },
    ],
  },
};

const REQUIRE_TRANSLATOR_BUNDLE: IFormElement = {
  id: REQUIRE_TRANSLATOR_BUNDLE_ID,
  name: 'Requires translator',
  type: FormElementType.MULTIPLE_ELEMENT,
  label: LabelSingleSelection,
  isHideConfig: true,
  properties: {
    general: [REQUIRED_PROPERTY],
    appearance: [],
    configuration: [],
  },
  children: [
    SELECT_REQUIRE_TRANSLATOR,
    { ...REQUIRE_TRANSLATOR_CONDITIONAL, conditionId: REQUIRE_TRANSLATOR_CONDITIONAL_ID },
    {
      ...TRANSLATOR_LANGUAGE_DROPDOWN,
      parentId: REQUIRE_TRANSLATOR_BUNDLE_ID,
      conditionId: REQUIRE_TRANSLATOR_CONDITIONAL_ID,
    },
  ],
};
//#endregion

export const GENERAL_INFORMATION_TAB_ELEMENTS: IFormElement[] = [
  MOBILE_PHONE_NUMBER,
  HOME_PHONE_NUMBER,
  DATE_OF_BIRTH,
  EMAIL_ADDRESS,
  HOME_ADDRESS,
  GENDER,
  LOCAL_GOVERNMENT_AREA,
  HOBBIES_AND_INTERESTS,
  RELIGION,
  LANGUAGE,
  COUNTRY_OF_BIRTH,
  ISLANDER_STATUS,
  MEDICARE_NUMBER,
  AMBULANCE_NUMBER,
  COMPANION_CARD_NUMBER,
  CENTRELINK_NUMBER,
  LIVING_ARRANGEMENTS,
  REQUIRE_TRANSLATOR_BUNDLE,
];
