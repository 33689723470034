import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ClockPlus = forwardRef(function ClockPlus(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.53 2.53a.75.75 0 0 0-1.06-1.06l-3 3a.75.75 0 0 0 1.06 1.06l3-3ZM3.75 12a8.25 8.25 0 1 1 16.5 0 8.25 8.25 0 0 1-16.5 0ZM12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm6.47-.78a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06-1.06l-3-3a.75.75 0 0 0-1.06 0ZM12.75 8a.75.75 0 0 0-1.5 0v3.25H8a.75.75 0 0 0 0 1.5h3.25V16a.75.75 0 0 0 1.5 0v-3.25H16a.75.75 0 0 0 0-1.5h-3.25V8Z"
        fill="currentColor"
      />
    </Icon>
  );
});
