import React, { useEffect, Fragment } from 'react';
import { Icon, notification } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';

import { SubTitle } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { IGroupServiceCustomerRatio } from 'interfaces/service-interfaces';
import { CustomRatioRow } from './custom-ratio-row';
import { DefaultBookingRatioSelect } from './default-booking-ratio-select';
import { getGroupServiceCustomerRatioFromForm } from './get-group-service-customer-ratio-from-form';
import { useRatioForm } from './hooks/use-ratio-form';
import { useSaveRatio, EditBookingCustomerRatioPayload } from './hooks/use-save-ratio';
import { EditRatiosSchema } from './schema';
import { IEditRatiosSubscribe, ISessionTimesParsed } from './types';

import './style.css';

type ICustomRatiosProps = {
  type: 'edit' | 'create';
  subscribe?: (form: IEditRatiosSubscribe) => void;
  sessionTimes: ISessionTimesParsed | null;
  showConfirmButton?: boolean;
  initialRatios: IGroupServiceCustomerRatio | null;
};

export const CustomerRatios = ({
  subscribe,
  sessionTimes,
  showConfirmButton,
  initialRatios,
  type,
}: ICustomRatiosProps) => {
  const form = useForm<EditRatiosSchema>({ criteriaMode: 'all' });

  const { ratios, add, remove, hasValidationError } = useRatioForm(form, initialRatios, sessionTimes);
  const { saveRatio, isLoading, hasError, error } = useSaveRatio();

  useEffect(() => {
    subscribe?.({
      submit: async (
        onSuccess?: (payload: EditBookingCustomerRatioPayload) => void,
        onError?: (error: Error | null) => void,
      ) => {
        await form.handleSubmit((data) => saveRatio(data, onSuccess, onError))();
      },
      getRatio: () => getGroupServiceCustomerRatioFromForm(form),
      hasValidationError,
      isSubmitting: isLoading,
      hasSubmissionError: hasError,
      submissionError: error,
    });
  }, [isLoading, hasError, error, form, hasValidationError]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await form.handleSubmit(async (data) =>
      saveRatio(
        data,
        () => {
          notification.success({ message: "You've successfully updated the booking ratio." });
        },
        () => {
          notification.error({ message: 'There was an error updating the booking ratio.' });
        },
      ),
    )();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="edit-ratios-form">
        <div className={`mb-large rounded-big ${type === 'edit' ? 'bordered' : ''} border-secondary p-medium`}>
          <div className="flex flex-col gap-3">
            <SubTitle>DEFAULT BOOKING RATIO</SubTitle>
            <DefaultBookingRatioSelect />
            {ratios?.length === 0 && (
              <HyperlinkButton onClick={add}>
                <Icon type="plus" className="mr-small" />
                Add more ratios
              </HyperlinkButton>
            )}
          </div>
        </div>

        {ratios?.length > 0 && (
          <div className="mb-large bordered border-secondary p-medium">
            <div className="mb-medium">
              <SubTitle>ADD MORE RATIOS{false}</SubTitle>
            </div>
            <div className="flex-column flex" style={{ gap: 14 }}>
              {ratios.map((ratio, index) => (
                <Fragment key={ratio.id}>
                  <CustomRatioRow ratio={ratio} index={index} onRemoveTimeSlot={() => remove(index)} />
                </Fragment>
              ))}
              <HyperlinkButton onClick={add} className="mb-small mt-small ml-medium width-fit-content">
                <Icon type="plus" className="mr-small" />
                Add more ratios
              </HyperlinkButton>
            </div>
          </div>
        )}

        {showConfirmButton && (
          <div className="text-align-right">
            <PrimaryButton size="large" htmlType="submit" loading={isLoading} disabled={hasValidationError}>
              Confirm
            </PrimaryButton>
          </div>
        )}
      </form>
    </FormProvider>
  );
};
