import React, { useState } from 'react';
import { Icon } from 'antd';
import { Text } from 'common-components/typography';

export function RevealContainer({ children, title }) {
  const [isOpen, setOpen] = useState(true);

  return (
    <div>
      <div onClick={() => setOpen(!isOpen)} className="cursor-pointer ph-medium">
        <div className="flex-row align-baseline pb-x-small">
          <Icon
            type="caret-down"
            className="text-size-x-small text-color-secondary mr-x-small"
            style={{ transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'all 0.2s' }}
          />

          <Text size="x2-small" weight="bold" className="ml-x-small select-none" color={'secondary'}>
            {title}
          </Text>
        </div>
      </div>

      <div style={{ height: isOpen ? '100%' : 0, overflow: 'hidden' }}>{children}</div>
    </div>
  );
}
