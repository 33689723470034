import React from 'react';
import { CustomerDetailsCard } from './customer-details-card';
import { ServiceDetailsCard } from './service-details-card';
import { Card } from '@good/components';

type ServiceDetailsProps = {
  customer: {
    attachmentUrl: string;
    ndisNumber: string;
    address: string;
    displayName: string;
  };
  silService: {
    name: string;
    status: string;
    startDate: Date;
    endDate: Date;
  };
};

export const ServiceOverviewCard = (props: ServiceDetailsProps) => {
  const { customer, silService } = props;

  return (
    <>
      <Card className="flex">
        <ServiceDetailsCard
          name={silService.name}
          status={silService.status}
          startDate={silService.startDate}
          endDate={silService.endDate}
        />
        <CustomerDetailsCard
          attachmentUrl={customer.attachmentUrl}
          displayName={customer.displayName}
          ndisNumber={customer.ndisNumber}
          address={customer.address}
        />
      </Card>
    </>
  );
};
