import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Text } from 'common-components/typography';

/********************************************************************
 * Types
 *******************************************************************/

type StepsProps = {
  currentStep: number;
  steps: string[];
} & HTMLAttributes<HTMLDivElement>;

type StepProps = {
  isActive: boolean;
  isCurrent: boolean;
  step: string;
  stepNumber: number;
};

/********************************************************************
 * Styles
 *******************************************************************/

const StepsContainer = styled.div({
  maxWidth: '80%',
  '@media (max-width: 1024px)': {
    maxWidth: '100%',
  },
});

const StyledText = styled(Text)({
  '@media (max-width: 768px)': {
    display: 'none',
  },
});

/********************************************************************
 * Steps
 *******************************************************************/

export function Steps(props: StepsProps) {
  let { steps, currentStep, className, ...otherProps } = props;

  return (
    <StepsContainer className={'step-container ' + className} {...otherProps}>
      {steps.map((s, i) => {
        let isActive = i <= currentStep;
        let isCurrent = i === currentStep;
        return <Step step={s} stepNumber={i + 1} key={i} isActive={isActive} isCurrent={isCurrent} />;
      })}
    </StepsContainer>
  );
}

export function Step(props: StepProps) {
  let { isActive, isCurrent, step, stepNumber } = props;

  return (
    <>
      {stepNumber > 1 && <div className={isActive ? 'step-filler-active' : 'step-filler'} />}
      <div
        className={isActive ? 'step-item step-item-active' : 'step-item'}
        style={stepNumber === 1 ? { marginLeft: 0 } : {}}
      >
        {stepNumber}
      </div>
      {step &&
        (isCurrent ? <Text className="mr-small">{step}</Text> : <StyledText className="mr-small">{step}</StyledText>)}
    </>
  );
}
