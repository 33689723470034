import { useState, useEffect } from 'react';
import { FieldValues, UseFormProps } from 'react-hook-form';

/* 
  To avoid recreating the form on every change to formProps if that is not passed as a state (unlikely)
*/
export function useFreezeFormProps<T extends FieldValues>(formProps: UseFormProps<T, unknown> | undefined) {
  const [formPropsFrozen, setFormPropsFrozen] = useState<UseFormProps<T, unknown> | undefined>(formProps);

  useEffect(() => {
    if (formProps) {
      if (
        JSON.stringify(formProps.values) !== JSON.stringify(formPropsFrozen?.values) ||
        JSON.stringify(formProps.defaultValues) !== JSON.stringify(formPropsFrozen?.defaultValues)
      ) {
        setFormPropsFrozen(formProps);
      }
    }
  }, [formProps, formPropsFrozen]);

  return formPropsFrozen;
}
