import React, { Component } from 'react';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { Col, Empty, Icon, Input, Row, Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import { SubTitle, Text } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { DocumentExpiryStatus } from 'utilities/enum-utils';
import { GhostButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';
import Utils from 'utilities/Utils';
import AddDocumentModal from 'common-components/documents/AddDocumentModal';
import ArchiveDocumentModal from 'common-components/documents/ArchiveDocumentModal';
import DeleteDocumentModal from 'common-components/documents/DeleteDocumentModal';
import EditDocumentModal from 'common-components/documents/EditDocumentModal';
import { ICustomerDocument } from 'interfaces/customer-interfaces';
import { downloadFileFromUrl } from 'utilities/file-utils';
import { InfiniteScroll } from 'components';
const { Search } = Input;

interface ICustomerDocumentsSubTabProps {
  customerUserId: string;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  doFetchCustomerDocuments: typeof dispatch.customersStore.doFetchCustomerDocuments;
  doFetchMoreCustomerDocuments: typeof dispatch.customersStore.doFetchMoreCustomerDocuments;
  customerDocuments: typeof state.customersStore.customerDocuments;
  companyDataLite: typeof state.companyStore.companyDataLite;
  hasEditPermission: boolean;
  useAttachmentText: false;
}
interface ICustomerDocumentsSubTabState {
  currentPage: number;
  pageSize: number;
  startDate: Date;
  isLoading: boolean;
  selectedDocument: any;
  isAddEditNoteModalOpen: boolean;
  isOpenEditDocumentModal: boolean;
  isOpenArchiveDocumentModal: boolean;
  isOpenDeleteDocumentModal: boolean;
  isOpenAddDocumentModal: boolean;
  isSearching: boolean;
  isOpenUnArchiveDocumentModal: boolean;
  searchString: string;
}

const DocumentEmptyPanel = () => (
  <div className='flex-1 bg-white align-center flex-column'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No document found.
    </Text>
    <Text color='secondary'>All documents under this filter will appear here.</Text>
  </div>
);

class CustomerDocumentsSubTab extends Component<ICustomerDocumentsSubTabProps, ICustomerDocumentsSubTabState> {
  state = {
    currentPage: 1,
    pageSize: 10,
    startDate: new Date(),
    isLoading: false,
    selectedDocument: null,
    isAddEditNoteModalOpen: false,
    isOpenEditDocumentModal: false,
    isOpenArchiveDocumentModal: false,
    isOpenDeleteDocumentModal: false,
    isOpenAddDocumentModal: false,
    isSearching: false,
    isOpenUnArchiveDocumentModal: false,
    searchString: '',
  };

  private _resetDocumentList = async () => {
    const { customerUserId, doFetchCustomerDocuments } = this.props;
    if (doFetchCustomerDocuments) {
      this.setState({ isLoading: true, currentPage: 1, startDate: new Date() });
      await doFetchCustomerDocuments({
        userId: customerUserId,
        searchString: this.state.searchString,
        currentPage: this.state.currentPage,
        pageSize: this.state.pageSize,
        startDate: this.state.startDate,
      });
      this.setState({ isLoading: false });
    }
  };
  private _editDocument = (document) => {
    if (document.noteId) {
      this.setState({ selectedDocument: document, isAddEditNoteModalOpen: true, isOpenEditDocumentModal: false });
    } else {
      this.setState({ selectedDocument: document, isOpenEditDocumentModal: true, isAddEditNoteModalOpen: false });
    }
  };
  private _deleteDocument = (document) => {
    this.setState({ selectedDocument: document, isOpenDeleteDocumentModal: true });
  };

  private _closeAddDocumentModal = () => {
    this.setState({ isOpenAddDocumentModal: false });
  };

  private _archiveDocument = (document) => {
    this.setState({ selectedDocument: document, isOpenArchiveDocumentModal: true });
  };

  private _getPopoverContent = (document) => {
    return (
      <Menu>
        <MenuDivider title={'ACTIONS'} className={'text-color-secondary bordered-top pt-large'} />

        {!document.isArchived && (
          <MenuItem
            label={''}
            className='hover-bg-blue-lightest mv-medium'
            text={'Edit'}
            onClick={() => this._editDocument(document)}
          />
        )}
        {!document.isArchived && (
          <MenuItem
            label={''}
            className='hover-bg-blue-lightest text-color-red-dark mv-medium'
            text={'Archive'}
            onClick={() => this._archiveDocument(document)}
          />
        )}
        <MenuItem
          label={''}
          className='hover-bg-blue-lightest text-color-red-dark mv-medium'
          text={'Delete'}
          onClick={() => this._deleteDocument(document)}
        />
      </Menu>
    );
  };

  private _closeArchiveDocumentModal = () => {
    this.setState({ isOpenArchiveDocumentModal: false });
  };
  private _closeEditDocumentModal = () => {
    this.setState({ isOpenEditDocumentModal: false });
  };
  private _openAddDocumentModal = () => {
    this.setState({ isOpenAddDocumentModal: true });
  };

  private _closeDeleteDocumentModal = () => {
    this.setState({ isOpenDeleteDocumentModal: false });
  };

  private _fetchMoreDocuments = () => {
    const { doFetchMoreCustomerDocuments, customerUserId } = this.props;
    if (doFetchMoreCustomerDocuments) {
      this.setState({ currentPage: this.state.currentPage + 1 }, async () => {
        await doFetchMoreCustomerDocuments({
          userId: customerUserId,
          searchString: this.state.searchString,
          currentPage: this.state.currentPage,
          pageSize: this.state.pageSize,
          startDate: this.state.startDate,
        });
      });
    }
  };

  private _getAddAttachmentBlock(hasEditPermission, useAttachmentText) {
    return (
      <Col className={'align-center'}>
        <div className={'flex-row-reverse align-center'}>
          {hasEditPermission && (
            <PrimaryButton icon={'file-add'} onClick={this._openAddDocumentModal}>
              Add {useAttachmentText ? 'Attachment' : 'Document'}
            </PrimaryButton>
          )}
        </div>
      </Col>
    );
  }

  private _searchText = async (txt) => {
    const { customerUserId, doFetchCustomerDocuments } = this.props;
    const startDate = new Date();
    if (doFetchCustomerDocuments) {
      await doFetchCustomerDocuments({
        userId: customerUserId,
        searchString: txt,
        currentPage: 1,
        startDate,
        pageSize: this.state.pageSize,
      });
    }
    this.setState({ isSearching: false, currentPage: 1, startDate, searchString: txt });
  };
  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    this._debounceSearch(e.target.value);
  };

  componentDidMount = async () => {
    await this._resetDocumentList();
    this.setState({ isLoading: false });
  };

  render() {
    const {
      customerDocuments,
      companyDataLite,
      hasEditPermission,
      useAttachmentText = false,
      customerUserId,
    } = this.props;
    const serviceProviderTimezone = companyDataLite ? companyDataLite.timezone : null;

    return (
      <>
        <AddDocumentModal
          closeAddDocumentModal={this._closeAddDocumentModal}
          selectedItemId={customerUserId}
          isOpen={this.state.isOpenAddDocumentModal}
          itemType={'customer'}
          resetDocumentList={this._resetDocumentList}
          useAttachmentText={useAttachmentText}
          isExpiryDisplayed={true}
        />
        <EditDocumentModal
          closeEditDocumentModal={this._closeEditDocumentModal}
          selectedDocument={this.state.selectedDocument}
          selectedItemId={customerUserId}
          isOpen={this.state.isOpenEditDocumentModal}
          itemType={'customer'}
          serviceProviderTimezone={serviceProviderTimezone}
          resetDocumentList={this._resetDocumentList}
          useAttachmentText={useAttachmentText}
          isExpiryDisplayed={true}
        />
        <DeleteDocumentModal
          closeDeleteDocumentModal={this._closeDeleteDocumentModal}
          selectedDocument={this.state.selectedDocument}
          selectedItemId={customerUserId}
          isOpen={this.state.isOpenDeleteDocumentModal}
          itemType={'customer'}
          resetDocumentList={this._resetDocumentList}
          useAttachmentText={useAttachmentText}
          isDocumentLinkedToNote={this.state.selectedDocument && !Utils.isEmpty(this.state.selectedDocument.noteId)}
        />
        <ArchiveDocumentModal
          closeArchiveDocumentModal={this._closeArchiveDocumentModal}
          selectedDocument={this.state.selectedDocument}
          selectedItemId={customerUserId}
          isOpen={this.state.isOpenArchiveDocumentModal}
          itemType={'customer'}
          resetDocumentList={this._resetDocumentList}
          useAttachmentText={useAttachmentText}
        />

        <Row type={'flex'} justify={'space-between'} align={'middle'} className='mb-large'>
          <Col>
            <Title level={4} className={'m-none'}>
              Customer documents
            </Title>
            <Text type={'secondary'}>Documents related to this customer.</Text>
          </Col>
        </Row>
        <Row type={'flex'} justify={'space-between'} align={'bottom'} className='mb-large'>
          <Col className='width-1/3'>
            <Search
              placeholder='Search for a document'
              onChange={this._onEnterSearchText}
              loading={this.state.isSearching}
              allowClear
            />
          </Col>
          {this._getAddAttachmentBlock(hasEditPermission, useAttachmentText)}
        </Row>
        <Row className='pv-small bordered-bottom'>
          <Col span={5} className={'pl-medium'}>
            <SubTitle>{useAttachmentText ? 'Attachment' : 'Document'} Name</SubTitle>
          </Col>

          <Col span={7}>
            <SubTitle>Description</SubTitle>
          </Col>

          <Col span={3}>
            <SubTitle>Expiry date</SubTitle>
          </Col>

          <Col span={3}>
            <SubTitle>Added by</SubTitle>
          </Col>

          <Col span={4} className='text-align-right'>
            <SubTitle>Viewable On App</SubTitle>
          </Col>

          <Col span={2} className='text-align-center'>
            <SubTitle></SubTitle>
          </Col>
        </Row>
        <InfiniteScroll
          hasMore={customerDocuments.length >= this.state.currentPage * this.state.pageSize}
          loadMore={this._fetchMoreDocuments}
          customLoader={
            <Col>
              <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className='anim-slide-left' />
            </Col>
          }
        >
          {this.state.isLoading ? (
            <Row>
              <Col>
                <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className='anim-slide-left' />
              </Col>
            </Row>
          ) : _.isEmpty(customerDocuments) ? (
            <DocumentEmptyPanel />
          ) : (
            customerDocuments.map((document: ICustomerDocument) => {
              const expiryColor =
                !document.isArchived && document.expiryStatus === DocumentExpiryStatus.EXPIRING_SOON
                  ? 'orange'
                  : !document.isArchived && document.expiryStatus === DocumentExpiryStatus.EXPIRED
                  ? 'red'
                  : 'black';
              const expiryText =
                !document.isArchived && document.expiryStatus === DocumentExpiryStatus.EXPIRING_SOON
                  ? 'Expiring soon'
                  : !document.isArchived && document.expiryStatus === DocumentExpiryStatus.EXPIRED
                  ? 'Expired'
                  : '';
              return (
                <Row
                  key={document.documentId}
                  className={`pv-medium ${document.isArchived && 'bg-red-lightest'} evenodd`}
                  type='flex'
                  align='middle'
                  gutter={8}
                >
                  <Col
                    span={5}
                    className={'pl-medium'}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  >
                    <span title={document.documentName}>
                      <Text
                        color={
                          document.status === 'SCANNING' ? 'secondary' : document.status === 'SCANNED' ? 'green' : 'red'
                        }
                      >
                        {document.status === 'SCANNED' ? (
                          <HyperlinkButton onClick={() => downloadFileFromUrl(document)}>
                            <Icon type={'download'} className='mr-x-small text-color-blue' />
                            {document.documentName}
                          </HyperlinkButton>
                        ) : (
                          <>
                            <Tooltip2
                              content={
                                document.status === 'SCANNING'
                                  ? 'This document is being scanned.'
                                  : 'This document failed to upload.'
                              }
                            >
                              <Icon
                                type='question-circle'
                                className={`text-size-x-large mr-x-small text-color-${
                                  document.status === 'SCANNING' ? 'blue' : 'red'
                                }`}
                              />
                            </Tooltip2>
                            {document.documentName}
                          </>
                        )}
                      </Text>
                    </span>
                  </Col>

                  <Col span={7}>
                    {document.noteId
                      ? 'Linked to note'
                      : document.status === 'FAILED'
                      ? 'Error in uploading document.'
                      : document.description
                      ? document.description
                      : '-'}
                  </Col>

                  <Col span={3} className='pl-none'>
                    <Text color={expiryColor}>
                      {document.isArchived
                        ? 'Archived'
                        : document.expiryDate
                        ? moment.tz(document.expiryDate, serviceProviderTimezone).format('DD/MM/YYYY')
                        : 'No expiry'}
                      {!Utils.isEmpty(expiryText) && (
                        <>
                          <br />
                          {expiryText}
                        </>
                      )}
                    </Text>
                  </Col>

                  <Col span={3} className='pl-none'>
                    {document.firstName} {document.lastName}
                  </Col>

                  <Col span={4} className='text-align-right'>
                    {document.isViewableOnApp ? (
                      <Icon type={'check'} className='text-color-green' />
                    ) : (
                      <Icon type={'close'} className='text-color-red' />
                    )}
                  </Col>

                  <Col span={2} className='text-align-center'>
                    {hasEditPermission && (
                      <Popover2
                        content={this._getPopoverContent(document)}
                        popoverClassName={'mb-medium'}
                        position={'bottom'}
                        interactionKind='click'
                      >
                        <GhostButton icon={'ellipsis'} />
                      </Popover2>
                    )}
                  </Col>
                </Row>
              );
            })
          )}
        </InfiniteScroll>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyDataLite: state.companyStore.companyDataLite,
  customerDocuments: state.customersStore.customerDocuments,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
  doFetchCustomerDocuments: dispatch.customersStore.doFetchCustomerDocuments,
  doFetchMoreCustomerDocuments: dispatch.customersStore.doFetchMoreCustomerDocuments,
});

export default connect(mapState, mapDispatch)(CustomerDocumentsSubTab);
