import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const AlignJustify = forwardRef(function AlignJustify(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3.25a.75.75 0 0 0 0 1.5h16a.75.75 0 0 0 0-1.5H4ZM3.25 9A.75.75 0 0 1 4 8.25h16a.75.75 0 0 1 0 1.5H4A.75.75 0 0 1 3.25 9ZM4 13.25a.75.75 0 0 0 0 1.5h16a.75.75 0 0 0 0-1.5H4Zm0 5a.75.75 0 0 0 0 1.5h16a.75.75 0 0 0 0-1.5H4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
