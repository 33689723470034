import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const PrinterSlash = forwardRef(function PrinterSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.53 1.47a.75.75 0 0 0-1.06 1.06l4.742 4.742c-.166.008-.322.018-.47.031-.606.055-1.136.172-1.617.45a3.75 3.75 0 0 0-1.373 1.372c-.277.481-.394 1.011-.449 1.617-.053.589-.053 1.318-.053 2.222V15c0 1.815 1.29 3.33 3.003 3.676.003.171.008.327.018.47.021.316.068.616.188.906a2.75 2.75 0 0 0 1.489 1.489c.29.12.59.167.907.188.304.021.675.021 1.12.021H15.025c.445 0 .816 0 1.12-.02.317-.022.617-.069.907-.19a2.75 2.75 0 0 0 1.489-1.488 2.23 2.23 0 0 0 .109-.341l2.82 2.82a.75.75 0 1 0 1.06-1.061l-14-14-6-6Zm14.72 16.84-2.56-2.56H9c-.476 0-.796 0-1.043.017-.241.017-.358.046-.435.078a1.25 1.25 0 0 0-.677.677c-.032.077-.061.194-.078.435-.017.247-.017.567-.017 1.043s0 .796.017 1.043c.017.241.046.358.078.435.127.307.37.55.677.677.077.032.194.061.435.078.247.017.567.017 1.043.017h6c.476 0 .796 0 1.043-.017.241-.017.358-.046.435-.078.307-.127.55-.37.677-.677.032-.077.061-.194.078-.435.013-.19.016-.42.017-.733Zm-4.06-4.06-5.5-5.5c-.786 0-1.357.006-1.812.047-.503.046-.788.13-1.003.254a2.25 2.25 0 0 0-.824.824c-.124.215-.208.5-.254 1.003-.046.512-.047 1.174-.047 2.122v2c0 .983.63 1.818 1.508 2.125.003-.096.007-.186.013-.27.021-.317.068-.617.188-.907a2.75 2.75 0 0 1 1.489-1.489c.29-.12.59-.167.907-.188.304-.021.675-.021 1.12-.021H13.19ZM16 8.75c.948 0 1.61 0 2.122.047.503.046.788.13 1.003.254.342.198.626.482.824.824.124.215.208.5.254 1.003.046.512.047 1.174.047 2.122v3a.75.75 0 0 0 1.5 0v-3.036c0-.904 0-1.633-.053-2.222-.055-.606-.172-1.136-.45-1.617a3.75 3.75 0 0 0-1.372-1.373 3.332 3.332 0 0 0-1.125-.386v-.418c0-.898 0-1.648-.08-2.242-.084-.628-.27-1.195-.726-1.65-.455-.456-1.022-.642-1.65-.726-.594-.08-1.344-.08-2.242-.08H8a.75.75 0 0 0 0 1.5h6c.964 0 1.612.002 2.095.067.461.062.659.169.789.3.13.13.237.327.3.788.064.483.066 1.131.066 2.095v.256c-.365-.006-.768-.006-1.214-.006H12a.75.75 0 0 0 0 1.5h4ZM5.25 11a.75.75 0 0 1 .75-.75h1a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
