import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Popover } from '@blueprintjs/core';
import { Avatar, Col, Empty, notification, Row } from 'antd';

import { SubTitle, Text, Title } from 'common-components/typography';
import { IServiceDateTimeForm } from 'interfaces/session-interfaces';
import { IElementValue } from 'views/form-builder/shared/form-interface';
import EditValueFormModal from 'views/form-builder/components/EditValueFormModal';
import { HyperlinkButton, IconButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ActionMenu, ActionMenuDivider, ActionMenuItem } from 'common-components/action-menu';

import AttachFormModalWrapper from './components/AttachFormModal';
import DeleteSessionFormModal from './components/DeleteSessionFormModal';
import EditCustomersTeamMembersModal from './components/EditCustomersTeamMembersModal';
import { StatusTag } from 'common-components/tags';

const FormEmptyPanel = () => (
  <>
    <Row className="pv-medium bordered-bottom">
      <Col span={8}>
        <SubTitle>FORM NAME</SubTitle>
      </Col>
      <Col span={8}>
        <SubTitle>DATE ADDED</SubTitle>
      </Col>
      <Col span={8}>
        <SubTitle>ADDED BY</SubTitle>
      </Col>
    </Row>
    <div className="flex-1 bg-white align-center flex-column pt-x2-large">
      <div className="mb-small">
        <Empty className="m-none" description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      <Text size="x2-large" color="secondary" weight="bold">
        Nothing here yet.
      </Text>
      <Text color="secondary">Select ‘Add form’ to get started.</Text>
    </div>
  </>
);

interface ISessionFormPanelProps {
  currentForm: typeof state.formBuilderStore.currentForm;
  session: typeof state.groupServiceStore.selectedSession;
  selectedSessionInvolveUserIds: typeof state.groupServiceStore.selectedSessionInvolveUserIds;
  doAddGroupServiceForm: typeof dispatch.groupServiceStore.doAddGroupServiceForm;
  doFetchFormDetailByServiceDateTimeFormId: typeof dispatch.groupServiceStore.doFetchFormDetailByServiceDateTimeFormId;
  doFetchSessionFormList: typeof dispatch.groupServiceStore.doFetchSessionFormList;
  setSessionInvolveUsers: typeof dispatch.groupServiceStore.setSessionInvolveUsers;
  doUpdateSessionForm: typeof dispatch.groupServiceStore.doUpdateSessionForm;
}

const SessionFormPanel = ({
  currentForm,
  session: { serviceId, serviceDateTimeId, customers, supportWorkers },
  selectedSessionInvolveUserIds,
  doFetchFormDetailByServiceDateTimeFormId,
  doFetchSessionFormList,
  setSessionInvolveUsers,
  doAddGroupServiceForm,
  doUpdateSessionForm,
}: ISessionFormPanelProps) => {
  const [isOpenAddFormModal, setIsOpenAddFormModal] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditProfileOpen, setIsEditProfileOpen] = useState<boolean>(false);
  const [selectedSessionForm, setSelectedSessionForm] = useState<IServiceDateTimeForm>(null);
  const [isViewFormModalOpen, setIsViewFormModalOpen] = useState<boolean>(false);
  const [fetchedAttachForm, setFetchedAttachForm] = useState<any>(null);
  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  const [attachedSessionForms, setAttachedSessionForms] = useState<IServiceDateTimeForm[]>(null);

  const _fetchAttachForms = () => {
    doFetchSessionFormList({ serviceId, serviceDateTimeId }).then((forms) => setAttachedSessionForms(forms as any));
  };

  const _handleOpenAddFormModal = () => {
    setIsOpenAddFormModal(true);
  };

  const _handleCloseAddFormModal = () => {
    setIsOpenAddFormModal(false);
  };

  const _handleDeleteFormAttach = (attendanceForm) => {
    setSelectedSessionForm(attendanceForm);
    setIsDeleteModalOpen(true);
  };

  const _handleEditFormAttach = async (form: IServiceDateTimeForm) => {
    const attachFormData = await doFetchFormDetailByServiceDateTimeFormId({
      serviceId,
      serviceDateTimeId,
      serviceDateTimeFormId: form.serviceDateTimeFormId,
    });
    setIsEditForm(true);
    setSelectedSessionForm(form);
    setFetchedAttachForm(attachFormData);
    setIsViewFormModalOpen(true);
  };

  const _handleOpenEditProfileModal = (attendanceForm) => {
    setSelectedSessionForm(attendanceForm);
    setIsEditProfileOpen(true);
  };

  const _handleViewFormAttach = async (form: IServiceDateTimeForm) => {
    const attachFormData = await doFetchFormDetailByServiceDateTimeFormId({
      serviceId,
      serviceDateTimeId,
      serviceDateTimeFormId: form.serviceDateTimeFormId,
    });
    setIsEditForm(false);
    setSelectedSessionForm(form);
    setFetchedAttachForm(attachFormData);
    setIsViewFormModalOpen(true);
  };

  const _handleCloseViewEditFormModal = () => {
    setIsViewFormModalOpen(false);
    setIsEditForm(false);
    setSelectedSessionForm(null);
    setFetchedAttachForm(null);
  };
  const _doSave = async (formId: string, elementValues: IElementValue[], status: string) => {
    try {
      const payload = {
        serviceId,
        serviceDateTimeId,
        formId: formId,
        formData: elementValues,
        involvedCustomerIds: selectedSessionInvolveUserIds.involvedCustomerIds,
        involvedMemberIds: selectedSessionInvolveUserIds.involvedMemberIds,
        status,
      };
      await doAddGroupServiceForm(payload);

      _fetchAttachForms();
      notification.open({
        message: <span className={'text-weight-bolder'}>Form attached</span>,
        description: <span>You have successfully attached a form to this session.</span>,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  const _onSaveEditFormAttach = async (id: string, elementValues: IElementValue[], status: string) => {
    const serviceDateTimeFormId = selectedSessionForm.serviceDateTimeFormId;

    try {
      const payload = {
        serviceId,
        serviceDateTimeId,
        serviceDateTimeFormId,
        formData: elementValues,
        status,
      };
      const response = await doUpdateSessionForm(payload);
      notification.open({
        message: <span className={'text-weight-bolder'}>Form updated</span>,
        description: <span>You have successfully updated a form on this session.</span>,
      });

      if (response) updateFormStatus(serviceDateTimeFormId, status);

      _handleCloseViewEditFormModal();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  const updateFormStatus = (serviceDateTimeFormId: string, status: string) => {
    const forms = [...attachedSessionForms];
    const findIndexForm = forms.findIndex((form) => form.serviceDateTimeFormId === serviceDateTimeFormId);

    if (findIndexForm > -1) {
      forms[findIndexForm].status = status;
      setAttachedSessionForms(forms);
    }
  };

  useEffect(() => {
    _fetchAttachForms();
    setSessionInvolveUsers({
      involvedCustomerIds: customers.map((c) => c.userId),
      involvedMemberIds: supportWorkers.map((w) => w.userId),
    });
  }, []);

  return (
    <>
      <AttachFormModalWrapper
        timezone={moment.tz.guess()}
        isOpen={isOpenAddFormModal}
        onClose={_handleCloseAddFormModal}
        onSave={_doSave}
      />

      {currentForm && selectedSessionForm && fetchedAttachForm && (
        <EditValueFormModal
          formId={selectedSessionForm.serviceDateTimeFormId}
          formElements={currentForm}
          elementValues={fetchedAttachForm.formData}
          isOpen={isViewFormModalOpen}
          onClose={_handleCloseViewEditFormModal}
          onSave={_onSaveEditFormAttach}
          mode={isEditForm ? 'EDIT' : 'VIEW'}
        />
      )}
      {isEditProfileOpen && (
        <EditCustomersTeamMembersModal
          isOpen={isEditProfileOpen}
          onClose={() => setIsEditProfileOpen(false)}
          attendanceForm={selectedSessionForm}
          fetchFormList={_fetchAttachForms}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteSessionFormModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          serviceId={serviceId}
          serviceDateTimeId={serviceDateTimeId}
          serviceDateTimeFormId={selectedSessionForm.serviceDateTimeFormId}
          fetchFormList={_fetchAttachForms}
        />
      )}

      <Row type={'flex'} justify={'space-between'} align={'bottom'} className="mb-x-large">
        <Col>
          <Title level={2} className={'m-none'}>
            Forms
          </Title>
          <Text type={'secondary'}>Add and manage all forms related to this session.</Text>
        </Col>
        <Col className={'align-center'}>
          <div className={'flex align-center'}>
            <PrimaryButton size="large" onClick={_handleOpenAddFormModal}>
              + Add form
            </PrimaryButton>
          </div>
        </Col>
      </Row>

      <div className="mb-x2-large">
        {!_.isEmpty(attachedSessionForms) ? (
          <>
            <Row className="pv-medium bordered-bottom">
              <Col span={5}>
                <SubTitle>FORM NAME</SubTitle>
              </Col>
              <Col span={5}>
                <SubTitle>DATE ADDED</SubTitle>
              </Col>
              <Col span={3}>
                <SubTitle>STATUS</SubTitle>
              </Col>
              <Col span={5}>
                <SubTitle>ADDED BY</SubTitle>
              </Col>
              <Col span={4}>
                <SubTitle>LINKED PROFILES</SubTitle>
              </Col>
              <Col span={2} />
            </Row>
            {attachedSessionForms.map((form: IServiceDateTimeForm) => (
              <Row type="flex" align="middle" className="pv-medium" key={form.serviceDateTimeFormId}>
                <Col span={5}>
                  <HyperlinkButton onClick={() => _handleViewFormAttach(form)}>{form.formName}</HyperlinkButton>
                </Col>
                <Col span={5}>{moment(form.addedOn).format('DD MMM YYYY')}</Col>
                <Col span={3}>
                  <StatusTag status={form.status} />
                </Col>
                <Col span={5}>
                  <Avatar className="mr-medium" size="default" icon="user" src={form.addedByAvatarUrl} />
                  <Text>{form.addedByName}</Text>
                </Col>
                <Col span={4}>
                  {!_.isEmpty(form.involvedCustomers) && (
                    <Text>
                      <strong>{form.involvedCustomers.length}</strong> Customer
                      {form.involvedCustomers.length > 1 && 's'}
                      <br />
                    </Text>
                  )}
                  {!_.isEmpty(form.involvedMembers) && (
                    <Text>
                      <strong>{form.involvedMembers.length}</strong> Team member{form.involvedMembers.length > 1 && 's'}
                    </Text>
                  )}
                  {_.isEmpty(form.involvedMembers) && _.isEmpty(form.involvedCustomers) && '-'}
                </Col>
                <Col span={2} className="text-align-right pr-small">
                  <Popover
                    content={
                      <ActionMenu>
                        <>
                          <ActionMenuItem text="View form" onClick={() => _handleViewFormAttach(form)} />
                          <ActionMenuItem text="Edit form" onClick={() => _handleEditFormAttach(form)} />
                          <ActionMenuItem
                            text="Edit linked profiles"
                            onClick={() => _handleOpenEditProfileModal(form)}
                          />
                          <ActionMenuDivider />
                          <ActionMenuItem
                            text="Export as PDF"
                            onClick={() => {
                              window.open(
                                `/pdf?type=view-form&serviceDateTimeFormId=${form.serviceDateTimeFormId}`,
                                '_blank',
                              );
                            }}
                          />
                          <ActionMenuItem
                            text="Remove"
                            className="text-color-red"
                            onClick={() => _handleDeleteFormAttach(form)}
                          />
                        </>
                      </ActionMenu>
                    }
                  >
                    <IconButton
                      icon="ellipsis"
                      size="small"
                      className="bg-white"
                      bordered={true}
                      iconColor="secondary"
                      onClick={() => {}}
                    />
                  </Popover>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <FormEmptyPanel />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentForm: state.formBuilderStore.currentForm,
  selectedSessionInvolveUserIds: state.groupServiceStore.selectedSessionInvolveUserIds,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doAddGroupServiceForm: dispatch.groupServiceStore.doAddGroupServiceForm,
  doFetchFormDetailByServiceDateTimeFormId: dispatch.groupServiceStore.doFetchFormDetailByServiceDateTimeFormId,
  doFetchSessionFormList: dispatch.groupServiceStore.doFetchSessionFormList,
  setSessionInvolveUsers: dispatch.groupServiceStore.setSessionInvolveUsers,
  doUpdateSessionForm: dispatch.groupServiceStore.doUpdateSessionForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionFormPanel);
