import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Globe2 = forwardRef(function Globe2(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.414 2.106a.736.736 0 0 0-1.049 0 .756.756 0 0 0 0 1.06A8.269 8.269 0 0 1 20.755 9c0 4.556-3.653 8.25-8.158 8.25a8.084 8.084 0 0 1-5.768-2.416.736.736 0 0 0-1.05 0 .756.756 0 0 0 0 1.06 9.566 9.566 0 0 0 6.076 2.828v1.528H9.63a.746.746 0 0 0-.741.75c0 .414.332.75.741.75h5.933c.41 0 .742-.336.742-.75a.746.746 0 0 0-.742-.75h-2.225v-1.528c4.978-.383 8.9-4.59 8.9-9.722 0-2.692-1.08-5.13-2.824-6.894ZM7.406 9c0-2.9 2.324-5.25 5.19-5.25 2.868 0 5.192 2.35 5.192 5.25s-2.324 5.25-5.191 5.25S7.406 11.9 7.406 9Zm5.19-6.75C8.912 2.25 5.923 5.272 5.923 9s2.989 6.75 6.675 6.75S19.27 12.728 19.27 9s-2.988-6.75-6.674-6.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
