import { useTimelineContext } from './timeline-provider';
import { TimelineHeaderRow, TimelineSingleRow } from './timeline-rows';
import { CurrentTimeIndicator } from './current-time-indicator';
import React from 'react';
import { TimelineExpandSectionRow } from './timeline-expand-section-row';
import { ExpandSectionRow, SingleRow } from 'design-components/timeline';

export const isExpandSectionRow = (component: unknown): component is ExpandSectionRow => {
  if ((component as ExpandSectionRow).rows !== undefined) {
    return true;
  }

  return false;
};

export const isSingleRow = (component: unknown): component is SingleRow => {
  if ((component as SingleRow).blocks !== undefined) {
    return true;
  }

  return false;
};

export const TimelineLayout = () => {
  const { rows, totalTimelineWidth, showCurrentTimeIndicator, footer } = useTimelineContext();

  return (
    <div className="rounded-lg border border-gray-light-1" style={{ maxWidth: `${totalTimelineWidth}rem` }}>
      <div className="relative min-w-full overflow-x-scroll rounded-t-lg">
        <div style={{ width: `${totalTimelineWidth}rem` }}>
          <TimelineHeaderRow />
          {rows.map((row, index) => {
            const isLastElement = rows.length - 1 === index;

            if (isExpandSectionRow(row)) {
              return <TimelineExpandSectionRow key={`section-${index}`} section={row} isLastSection={isLastElement} />;
            }

            if (isSingleRow(row)) {
              return <TimelineSingleRow key={`row-${index}`} row={row} isLastElement={isLastElement} />;
            }

            return null;
          })}
          {showCurrentTimeIndicator && <CurrentTimeIndicator />}
        </div>
      </div>
      <div
        className="flex h-10 flex-grow rounded-b-lg border-t border-gray-light-1 bg-white"
        style={{ maxWidth: `${totalTimelineWidth}rem` }}
      >
        {footer}
      </div>
    </div>
  );
};
