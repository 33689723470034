import { Menu, MenuItem, Popover } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Avatar, Col, Icon, notification, Row, Steps } from 'antd';
import AddAddressModal from 'common-components/addresses/AddAddressModal';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import PinnedAlertItem from 'common-components/pinned-alerts/PinnedAlertItem';
import { TimezoneIndicator, TimezoneSelector } from 'common-components/timezone';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import {
  BookingErrorType,
  BookingStatus,
  PaymentSources,
  PaymentStatus,
  ServiceLocationType,
  ShiftSlotStatus,
} from 'utilities/enum-utils';
import FundingUtils from 'utilities/funding-utils';
import PermissionUtils from 'utilities/permission-utils';
import ActivityRecordApproveActionModel from 'views/bookings/components/ActivityRecordApproveActionModal';
import ActivityRecordArchiveModal from '../../components/ActivityRecordArchiveModal';
import ActivityRecordEditTimeActionModalV2 from '../../components/ActivityRecordEditTimeActionModalV2';
import ActivityRecordRemoveShiftFromTimeSheetActionModal from '../../components/ActivityRecordRemoveShiftFromTimeSheetActionModal';
import ActivityRecordRevokeApprovalModal from '../../components/ActivityRecordRevokeApprovalModal';
import BookingAssignWorkerModal from '../../components/BookingAssignWorkerModal';
import BookingEditBookingDatesActionModel from '../../components/BookingEditBookingDatesActionModel';
import BookingRemoveWorkerActionModal from '../../components/BookingRemoveWorkerActionModal';
import BookingWarning from './content-section/tabs-panel/components/BookingWarning';

const { Step } = Steps;

interface IActivityRecordOverviewTabProps {
  selectedBookingItem: any;
  history: any;
  navigateTab: any;
  doAssignWorker: typeof dispatch.bookingsStore.doAssignWorker;
  doUpdateBookingLocation: typeof dispatch.bookingsStore.doUpdateBookingLocation;
  mode: string;
  userTimezone: timeZone;
  timezone: timeZone;
  bookingDisplayTzCustom: timeZone;
  onChangeTimezone: (type, value) => void;
  portalUser: typeof state.authStore.portalUser;
  isBookingArchived: boolean;
}

class ActivityRecordOverviewTab extends Component<IActivityRecordOverviewTabProps, any> {
  state = {
    action: '',
    openAction: false,
    openAssignWorker: false,
    openEditAddress: false,
    openLocationConfirmationModal: false,
    openEditDate: false,
    isSentToTimeSheet: false,
    openRemoveTimeSheet: false,
  };

  private _openActionModal = ({ action }) => {
    this.setState({ action, openAction: true });
  };

  private _closeActionModal = () => this.setState({ openAction: false });

  private _openAssignWorkerModal = () => {
    this.setState({ openAssignWorker: true });
  };

  private _closeAssignWorkerModal = () => this.setState({ openAssignWorker: false });

  private _openLocationConfirmationModal = () => {
    this.setState({ openLocationConfirmationModal: true });
  };

  private _closeLocationConfirmationModal = () => this.setState({ openLocationConfirmationModal: false });

  private _onCloseEditTime = () => {
    this.setState({ openEditDate: false, isSentToTimeSheet: false });
  };

  private _getActionModal = () => {
    // Depending on the action target type, use the correct modal.
    if (this.state.action === 'APPROVE') {
      return ActivityRecordApproveActionModel;
    } else if (this.state.action === 'REMOVE_WORKER') {
      return BookingRemoveWorkerActionModal;
    } else if (this.state.action === 'EDIT_BOOKING_DATES') {
      return BookingEditBookingDatesActionModel;
    } else if (this.state.action === 'REVOKE_APPROVAL') {
      return ActivityRecordRevokeApprovalModal;
    } else if (this.state.action === 'ARCHIVE_BOOKING') {
      return ActivityRecordArchiveModal;
    } else {
      return () => <></>;
    }
  };

  private _toCustomerPage = () => {
    const { selectedBookingItem, history } = this.props;
    history.push(`/customer/details/${selectedBookingItem.bookerUserId}`);
  };

  private _toServicePage = () => {
    const { selectedBookingItem, history } = this.props;
    history.push(`/service/details/${selectedBookingItem.serviceId}`);
  };

  private _toWorkerPage = () => {
    const { selectedBookingItem, history } = this.props;
    history.push(`/team/details/${selectedBookingItem.workerId}`);
  };

  private _billingErrors = () => {
    const { selectedBookingItem } = this.props;

    const lineItems = selectedBookingItem.billingLineItems;

    const isNoPaymentMethodItems = _.countBy(
      lineItems,
      (item) => !item.paymentMethod && item.paymentSourceType === PaymentSources.NDIS,
    ).true;

    const fundedLineItems = _.countBy(
      lineItems,
      (item) =>
        item.paymentSourceType === PaymentSources.NDIS &&
        !FundingUtils.doesLineItemFundingCategoryExistInFundingPackage(
          item.supportType,
          item.supportCategoryNumber,
          item.supportItemNumber,
          selectedBookingItem.fundedCategories,
        ),
    ).true;

    return { isNoPaymentMethodItems, fundedLineItems };
  };

  private _renderStatusBar = () => {
    const { selectedBookingItem } = this.props;

    switch (selectedBookingItem.status) {
      case BookingStatus.COMPLETED:
        if (selectedBookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL) {
          return (
            <Steps current={0} size="small" className="mt-x-large" style={{ width: '40%' }}>
              <Step
                icon={<Icon type="check-circle" className="text-color-orange" theme="filled" />}
                title={<Text className="text-color-orange text-weight-bold">Requires approval</Text>}
              />
              <Step
                icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
                title={<Text className="text-color-secondary text-weight-bold">Approved</Text>}
              />
            </Steps>
          );
        } else if (selectedBookingItem.paymentStatus === PaymentStatus.SEND_TO_FINANCE) {
          return (
            <Steps current={1} size="small" className="mt-x-large" style={{ width: '40%' }}>
              <Step
                icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
                title={<Text className="text-color-secondary text-weight-bold">Requires approval</Text>}
              />
              <Step
                icon={<Icon type="check-circle" className="text-color-blue" theme="filled" />}
                title={<Text className="text-color-blue text-weight-bold">Approved</Text>}
              />
            </Steps>
          );
        }
        break;
      case BookingStatus.ARCHIVED:
        return (
          <Steps current={2} size="small" className="mt-x-large" style={{ width: '40%' }}>
            <Step
              icon={<Icon type="check-circle" className="text-color-red" theme="filled" />}
              title={<Text className="text-color-red text-weight-bold">Archived</Text>}
            />
          </Steps>
        );

      default:
        return '';
    }
  };

  private _renderActionButtons = () => {
    const { selectedBookingItem, portalUser } = this.props;

    const archiveBookingMenu = (
      <div className="flex-row flex-wrap">
        <Popover
          content={
            <Menu>
              <MenuItem
                onClick={() => this._openActionModal({ action: 'ARCHIVE_BOOKING' })}
                className="hover-bg-blue-lightest mv-medium"
                text={'Archive'}
              />
            </Menu>
          }
          popoverClassName={'mb-medium'}
          position={'bottom'}
          interactionKind="click"
        >
          <SecondaryButton size={'large'} className="mr-medium" icon={'ellipsis'} />
        </Popover>
      </div>
    );

    const billingErrors = this._billingErrors();

    switch (selectedBookingItem.status) {
      case 'COMPLETED':
        if ([PaymentStatus.REQUIRES_APPROVAL, PaymentStatus.INITIAL].includes(selectedBookingItem.paymentStatus)) {
          return (
            <div className="align-center flex-row flex-wrap">
              {PermissionUtils.validatePermission(
                'ApproveBooking',
                portalUser.permissions.permissionRoles,
                selectedBookingItem.serviceDepartmentId,
                selectedBookingItem.serviceId,
              ) && (
                <>
                  <PrimaryButton
                    size="large"
                    className="mr-medium"
                    disabled={
                      billingErrors.isNoPaymentMethodItems >= 1 || selectedBookingItem.billingLineItems.length === 0
                    }
                    onClick={() => this._openActionModal({ action: 'APPROVE' })}
                  >
                    Approve for Payment
                  </PrimaryButton>
                  {billingErrors.isNoPaymentMethodItems >= 1 && (
                    <Tooltip2
                      position="top"
                      content="You cannot send to finance an activity record with a billing line item without a payment method."
                    >
                      <Icon
                        type="question-circle"
                        style={{ marginLeft: '-8px', marginRight: '8px' }}
                        className="text-size-x-large text-color-blue"
                      />
                    </Tooltip2>
                  )}
                  {selectedBookingItem.billingLineItems.length === 0 && (
                    <Tooltip2
                      position="top"
                      content="You cannot send an activity record to finance without any billing line items."
                    >
                      <Icon
                        type="question-circle"
                        style={{ marginLeft: '-8px', marginRight: '8px' }}
                        className="text-size-x-large text-color-blue"
                      />
                    </Tooltip2>
                  )}
                </>
              )}

              {archiveBookingMenu}
            </div>
          );
        } else {
          const hasRejectedProcessedBillingLineItem = _.find(
            selectedBookingItem.billingLineItems,
            (item) => item.paymentStatus === PaymentStatus.REJECTED || item.paymentStatus === PaymentStatus.PROCESSED,
          );
          const hasNotWaviedSentToPaymentsBillingLineItem = _.find(
            selectedBookingItem.billingLineItems,
            (item) =>
              item.paymentStatus !== PaymentStatus.SEND_TO_FINANCE && item.paymentStatus !== PaymentStatus.WAIVED,
          );
          return (
            <div className="flex-row flex-wrap">
              <SecondaryButton
                size="large"
                className="mr-medium"
                disabled={!!hasRejectedProcessedBillingLineItem || !!hasNotWaviedSentToPaymentsBillingLineItem}
                onClick={() => this._openActionModal({ action: 'REVOKE_APPROVAL' })}
              >
                Revoke approval
              </SecondaryButton>
              {(!!hasRejectedProcessedBillingLineItem || !!hasNotWaviedSentToPaymentsBillingLineItem) && (
                <div className="align-center flex">
                  <Tooltip2
                    position="top"
                    content="You cannot revoke approval for this booking as it has already been processed in payments"
                  >
                    <Icon
                      type="question-circle"
                      style={{ marginLeft: '-8px', marginRight: '8px' }}
                      className="text-size-x-large text-color-blue"
                    />
                  </Tooltip2>
                </div>
              )}
            </div>
          );
        }

      default:
        return '';
    }
  };

  private _renderDuration = (startDateTime: Moment, endDateTime: Moment) => {
    const duration = CommonUtils.formatDuration(startDateTime, endDateTime);
    const durationDisplays = [];

    if (duration.days) {
      durationDisplays.push(
        <>
          <b>{duration.days.value}</b> {`${duration.days.label} `}
        </>,
      );
    }
    if (duration.hours) {
      durationDisplays.push(
        <>
          <b>{duration.hours.value}</b> {`${duration.hours.label} `}
        </>,
      );
    }

    if (duration.minutes) {
      durationDisplays.push(
        <>
          <b>{duration.minutes.value}</b> {duration.minutes.label}
        </>,
      );
    }
    return (
      <Text color="secondary">
        {_.map(durationDisplays, (display) => {
          return display;
        })}
      </Text>
    );
  };

  private _onSubmitAssign = async ({
    selectedWorker,
    editRecurringMode,
    shiftSlotStatus,
    isRemovePendingShiftSlots,
    startDateTime,
    endDateTime,
    ignoreShiftClashShiftIds,
  }) => {
    const { doAssignWorker, selectedBookingItem } = this.props;
    const data = {
      bookingId: selectedBookingItem.bookingId,
      bookingRequestId: selectedBookingItem.bookingRequestId,
      supportWorkerId: selectedWorker.supportWorkerId,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      isRecurring: selectedBookingItem.isRecurring,
      shiftSlotStatus,
      isRemovePendingShiftSlots,
      editRecurringMode,
      bookingType: selectedBookingItem.bookingType,
      ignoreShiftClashShiftIds,
    };
    await doAssignWorker(data);
  };

  private _saveBookingAddress = (
    location,
    editRecurringMode,
    numberOfBookings,
    conflictSupportWorkerAttendanceIds = null,
  ) => {
    const { doUpdateBookingLocation, selectedBookingItem } = this.props;

    try {
      doUpdateBookingLocation({
        bookingId: selectedBookingItem.bookingId,
        bookingRequestId: selectedBookingItem.bookingRequestId,
        address: location,
        editRecurringMode,
        numberOfBookings,
        conflictSupportWorkerAttendanceIds,
        bookingType: selectedBookingItem.bookingType,
      });
      this._closeEditAddressModal();
      this._openLocationConfirmationModal();
    } catch (e) {
      this._closeEditAddressModal();
      notification.error({ message: 'Oops, something went wrong, please try again!' });
    }
  };

  private _closeEditAddressModal = () => {
    this.setState({ openEditAddress: false });
  };

  private _openEditAddressModal = () => {
    this.setState({ openEditAddress: true });
  };

  private _bookingErrors = () => {
    const { selectedBookingItem } = this.props;

    const bookingErrorTypes = _.map(selectedBookingItem.bookingErrors, (error) => {
      return error.bookingErrorType;
    });

    const isBothServiceAgreementWarningsExist =
      _.includes(bookingErrorTypes, BookingErrorType.ServiceAgreementWarning) &&
      _.includes(bookingErrorTypes, BookingErrorType.ServiceAgreementLineItemWarning)
        ? true
        : false;

    let bookingErrors = _.clone(selectedBookingItem.bookingErrors);
    if (isBothServiceAgreementWarningsExist) {
      bookingErrors = _.remove(bookingErrors, (error: any) => {
        return error.bookingErrorType !== BookingErrorType.ServiceAgreementLineItemWarning;
      });
    }

    return bookingErrors;
  };

  render() {
    // default with empty react node.
    const TargetActionModal: any = this._getActionModal();
    const { selectedBookingItem, navigateTab, timezone, userTimezone, portalUser, isBookingArchived } = this.props;
    const bookingErrors = this._bookingErrors();

    const displayTimezone = timezone ? timezone : selectedBookingItem.timezone;

    // Permissions
    const hasEditWorkerPermissions = PermissionUtils.validatePermission(
      'EditWorkerInBooking',
      portalUser.permissions.permissionRoles,
      selectedBookingItem.serviceDepartmentId,
      selectedBookingItem.serviceId,
    );

    return (
      <div className="anim-fade-in">
        {/* Header */}
        {/*<TopHeaderText text={'booking overview'} />*/}
        <div className="align-end flex-row justify-between">
          <Title level={3} color="secondary">
            Activity Record / <span className="text-color-black">{selectedBookingItem.referenceId}</span>
          </Title>

          <div className="text-align-right">
            <Text className="mr-small" size="small" color="secondary">
              Timezone display
            </Text>
            <br />
            <TimezoneSelector
              bordered={false}
              mode={this.props.mode}
              userTimezone={this.props.userTimezone}
              customTimezone={this.props.bookingDisplayTzCustom}
              onChange={this.props.onChangeTimezone}
            />
          </div>
        </div>
        <TargetActionModal
          isOpen={this.state.openAction}
          onClose={this._closeActionModal}
          history={this.props.history}
          navigateTab={navigateTab}
          displayTimezone={displayTimezone}
          serviceType={'SUPPORT'}
          userName={selectedBookingItem.firstName + ' ' + selectedBookingItem.lastName}
          bookingType={selectedBookingItem.bookingType}
        />
        {this.state.openEditDate && (
          <ActivityRecordEditTimeActionModalV2
            isOpen={this.state.openEditDate}
            booking={selectedBookingItem}
            onClose={this._onCloseEditTime}
            isSentToTimeSheet={this.state.isSentToTimeSheet}
            timezone={displayTimezone}
          />
        )}
        {this.state.openRemoveTimeSheet && (
          <ActivityRecordRemoveShiftFromTimeSheetActionModal
            onClose={() => this.setState({ openRemoveTimeSheet: false })}
            isOpen={this.state.openRemoveTimeSheet}
            booking={selectedBookingItem}
          />
        )}
        {this.state.openAssignWorker && (
          <BookingAssignWorkerModal
            isOpen={this.state.openAssignWorker}
            onClose={this._closeAssignWorkerModal}
            serviceId={selectedBookingItem.serviceId}
            bookingId={selectedBookingItem.bookingId}
            bookingRequestId={selectedBookingItem.bookingRequestId}
            onSubmitAssign={this._onSubmitAssign}
            isRecurring={selectedBookingItem.isRecurring}
            address={selectedBookingItem.address}
            sessions={[]}
            isEditingBooking={true}
            startDateTime={selectedBookingItem.startDateTime}
            endDateTime={selectedBookingItem.endDateTime}
            customerUserIds={[selectedBookingItem.bookerUserId]}
            displayTimezone={displayTimezone}
            history={this.props.history}
            bookingType={selectedBookingItem.bookingType}
          />
        )}
        {selectedBookingItem.serviceDirection !== ServiceLocationType.ONLINE && (
          <>
            <AddAddressModal
              closeCreateEditAddressModal={() => {
                this._closeEditAddressModal();
              }}
              isOpen={this.state.openEditAddress}
              address={selectedBookingItem.address}
              saveCustomerAddress={this._saveBookingAddress}
              isRecurring={selectedBookingItem.isRecurring}
              isEditBookingAddress={true}
              numberOfBookingLeft={selectedBookingItem.numberOfBookingLeft}
              bookingId={selectedBookingItem.bookingId}
              bookingRequestId={selectedBookingItem.bookingRequestId}
              timezone={selectedBookingItem.timezone}
              title={
                <>
                  <b>Activity record location</b>
                </>
              }
              subTitle={
                'Type the address you want to change the activity record location to and we will automatically search for it.'
              }
            />
          </>
        )}
        <ActionModal
          isOpen={this.state.openLocationConfirmationModal}
          onClose={this._closeLocationConfirmationModal}
          title={'Location changed'}
        >
          <div className="anim-fade-in">
            <div className="text-align-left">
              <Paragraph>The location has been successfully changed for the selected activity record.</Paragraph>
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._closeLocationConfirmationModal}>
                OK
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        </ActionModal>

        {this._renderStatusBar()}

        <div>
          <div className="align-start mv-x2-large mh-large justify-start">
            <div className="p-small">
              <Row gutter={24} className="mb-x-large justify-end">
                <Col span={5}>
                  <SubTitle color="tertiary" weight="black">
                    Customer
                  </SubTitle>
                </Col>
                <Col span={19}>
                  {PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles) ? (
                    <>
                      <Avatar icon="user" className="avatar-medium mr-small" src={selectedBookingItem.attachmentUrl} />
                      <HyperlinkButton onClick={this._toCustomerPage} className="ml-small">
                        {selectedBookingItem.firstName + ' ' + selectedBookingItem.lastName}
                      </HyperlinkButton>
                    </>
                  ) : (
                    selectedBookingItem.firstName + ' ' + selectedBookingItem.lastName
                  )}
                  <br />

                  {selectedBookingItem.pinnedAlertNumber > 0 && (
                    <PinnedAlertItem
                      pinnedAlertNumber={selectedBookingItem.pinnedAlertNumber}
                      customerUserId={selectedBookingItem.bookerUserId}
                      isViewOnly={true}
                    />
                  )}
                </Col>
              </Row>
              <Row gutter={24} className="mb-large justify-end">
                <Col span={5}>
                  <SubTitle color="tertiary" weight="black">
                    Service
                  </SubTitle>
                </Col>
                <Col span={19}>
                  <Text className="">{selectedBookingItem.serviceName ?? ''}</Text>
                </Col>
              </Row>
              <Row gutter={24} className="mb-large justify-end">
                <Col span={5} className="justify-end">
                  <SubTitle color="tertiary" weight="black">
                    Team member
                  </SubTitle>
                </Col>
                <Col span={19}>
                  {selectedBookingItem.workerUserId ? (
                    <>
                      <Row type="flex" align="middle">
                        <Col style={{ minWidth: '25px' }} className="mr-medium">
                          <Avatar
                            icon="user"
                            className="avatar-medium mr-small"
                            shape="square"
                            src={selectedBookingItem.workerAttachmentUrl}
                          />
                          {PermissionUtils.validatePermission(
                            'ViewTeamListing',
                            portalUser.permissions.permissionRoles,
                          ) ? (
                            <HyperlinkButton className="text-weight-bold ml-small" onClick={this._toWorkerPage}>
                              {`${selectedBookingItem.workerFirstName} ${selectedBookingItem.workerLastName}`}
                            </HyperlinkButton>
                          ) : (
                            <Text>{`${selectedBookingItem.workerFirstName} ${selectedBookingItem.workerLastName}`}</Text>
                          )}
                        </Col>
                        <Col className="flex flex-wrap justify-start">
                          {!isBookingArchived &&
                            selectedBookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL &&
                            hasEditWorkerPermissions && (
                              <HyperlinkButton
                                className="ml-small"
                                color="red"
                                onClick={() => this._openActionModal({ action: 'REMOVE_WORKER' })}
                              >
                                <Icon type="edit" className="text-color-black" /> Remove
                              </HyperlinkButton>
                            )}
                        </Col>
                      </Row>

                      <div className="mt-12">
                        {!isBookingArchived && (
                          <>
                            {selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.CHECKED_OUT ||
                            selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.SENT_TO_FINANCE ||
                            selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.UNASSIGNED ? (
                              <>
                                <Text size="medium" style={{ fontStyle: 'italic' }} color="secondary">
                                  Activity record hours added to the team member timesheet
                                </Text>
                                {!isBookingArchived &&
                                  selectedBookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL &&
                                  hasEditWorkerPermissions && (
                                    <HyperlinkButton
                                      className="text-weight-bold ml-12"
                                      underline
                                      onClick={() => {
                                        this.setState({ openRemoveTimeSheet: true });
                                      }}
                                    >
                                      Remove hours from timesheet
                                    </HyperlinkButton>
                                  )}
                              </>
                            ) : (
                              <>
                                <Text size="medium" style={{ fontStyle: 'italic' }} color="secondary">
                                  Activity record hours <b>are not</b> being added to the team member timesheet
                                </Text>
                                {!isBookingArchived &&
                                  selectedBookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL &&
                                  hasEditWorkerPermissions && (
                                    <HyperlinkButton
                                      className="text-weight-bold ml-12"
                                      underline
                                      onClick={() => {
                                        this.setState({ isSentToTimeSheet: true, openEditDate: true });
                                      }}
                                    >
                                      Add hours to timesheet
                                    </HyperlinkButton>
                                  )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <Text size="medium">No team member assigned</Text>
                      {hasEditWorkerPermissions &&
                        [PaymentStatus.INITIAL, PaymentStatus.REQUIRES_APPROVAL].includes(
                          selectedBookingItem.paymentStatus,
                        ) && (
                          <div className="text-size-regular mt-x-small">
                            {!isBookingArchived && (
                              <HyperlinkButton onClick={this._openAssignWorkerModal} fontSize="regular">
                                <Icon type="edit" />

                                <Text size="medium" color="blue-action">
                                  Select a team member
                                </Text>
                              </HyperlinkButton>
                            )}
                          </div>
                        )}
                    </>
                  )}
                </Col>
              </Row>
              <Row gutter={24} className="mb-large justify-end">
                <Col span={5} className="justify-end">
                  <SubTitle color="tertiary" weight="black">
                    When
                  </SubTitle>
                </Col>
                <Col span={19}>
                  {moment
                    .tz(selectedBookingItem.startDateTime, displayTimezone)
                    .isSame(moment.tz(selectedBookingItem.endDateTime, displayTimezone), 'day') ? (
                    <>
                      <Text size="large">
                        {moment.tz(selectedBookingItem.startDateTime, displayTimezone).format('dddd, DD MMM YYYY')}
                      </Text>
                      <br />
                      {selectedBookingItem.isDuration ? (
                        <div>
                          <Text size="large">
                            (
                            {this._renderDuration(
                              moment.tz(selectedBookingItem.startDateTime, displayTimezone),
                              moment.tz(selectedBookingItem.endDateTime, displayTimezone),
                            )}
                            )
                          </Text>
                        </div>
                      ) : (
                        <div className="mb-x-small">
                          <Text size="large">
                            ({moment.tz(selectedBookingItem.startDateTime, displayTimezone).format('LT')} -{' '}
                            {moment.tz(selectedBookingItem.endDateTime, displayTimezone).format('LT')})
                          </Text>
                        </div>
                      )}
                      {!timezone && moment.tz(userTimezone).format('Z') !== moment.tz(displayTimezone).format('Z') && (
                        <TimezoneIndicator
                          bordered={true}
                          hasIcon={true}
                          showTzName={false}
                          timezone={selectedBookingItem.timezone}
                        />
                      )}
                      {selectedBookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL &&
                        !isBookingArchived &&
                        PermissionUtils.validatePermission(
                          'EditBookingTime',
                          portalUser.permissions.permissionRoles,
                          selectedBookingItem.serviceDepartmentId,
                          selectedBookingItem.serviceId,
                        ) && (
                          <HyperlinkButton color="blue-action" onClick={() => this.setState({ openEditDate: true })}>
                            <Icon type="edit" /> Edit...
                          </HyperlinkButton>
                        )}
                    </>
                  ) : (
                    <>
                      <Text size="large">
                        {moment.tz(selectedBookingItem.startDateTime, displayTimezone).format('dddd, DD MMM YYYY')} -{' '}
                        <Text>{moment.tz(selectedBookingItem.startDateTime, displayTimezone).format('LT')}</Text>
                      </Text>
                      <br />
                      <Text size="large">
                        {moment.tz(selectedBookingItem.endDateTime, displayTimezone).format('dddd, DD MMM YYYY')} -{' '}
                        <Text>{moment.tz(selectedBookingItem.endDateTime, displayTimezone).format('LT')}</Text>
                      </Text>
                      <br />
                      <div className="mb-x-small">
                        <Text size="large">
                          {moment
                            .tz(selectedBookingItem.endDateTime, displayTimezone)
                            .diff(moment.tz(selectedBookingItem.startDateTime, displayTimezone), 'hours')}{' '}
                          hour
                        </Text>
                      </div>
                      {!timezone && moment.tz(userTimezone).format('Z') !== moment.tz(displayTimezone).format('Z') && (
                        <TimezoneIndicator
                          bordered={true}
                          hasIcon={true}
                          showTzName={false}
                          timezone={selectedBookingItem.timezone}
                        />
                      )}
                      {selectedBookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL &&
                        !isBookingArchived &&
                        PermissionUtils.validatePermission(
                          'EditBookingTime',
                          portalUser.permissions.permissionRoles,
                          selectedBookingItem.serviceDepartmentId,
                          selectedBookingItem.serviceId,
                        ) && (
                          <HyperlinkButton color="blue-action" onClick={() => this.setState({ openEditDate: true })}>
                            <Icon type="edit" /> Edit...
                          </HyperlinkButton>
                        )}
                    </>
                  )}
                </Col>
              </Row>
              <Row gutter={24} className="mb-large justify-end">
                <Col span={5} className="justify-end">
                  <SubTitle color="tertiary" weight="black">
                    Where
                  </SubTitle>
                </Col>
                <Col span={19}>
                  {selectedBookingItem.serviceDirection !== ServiceLocationType.ONLINE ? (
                    <>
                      <Text className="">
                        {selectedBookingItem.address ? selectedBookingItem.address.fullAddress : '-'}
                      </Text>
                      {selectedBookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL &&
                        PermissionUtils.validatePermission(
                          'EditBookingLocation',
                          portalUser.permissions.permissionRoles,
                          selectedBookingItem.serviceDepartmentId,
                          selectedBookingItem.serviceId,
                        ) &&
                        !isBookingArchived && (
                          <>
                            <br />
                            <HyperlinkButton color="blue-action" onClick={this._openEditAddressModal}>
                              <Icon type="edit" /> Edit location
                            </HyperlinkButton>
                          </>
                        )}
                    </>
                  ) : (
                    <Text>Online</Text>
                  )}
                  <br />
                </Col>
              </Row>
              <Row gutter={24} className="mb-medium justify-end">
                <Col span={5} className="justify-end">
                  <SubTitle color="tertiary" weight="black">
                    Total Amount
                  </SubTitle>
                </Col>
                <Col span={19}>
                  <Text className="text-weight-bold">{CommonUtils.formatPrice(selectedBookingItem.total)}</Text>
                  <br />
                </Col>
              </Row>

              {!_.isEmpty(bookingErrors) && selectedBookingItem.status !== BookingStatus.ARCHIVED && (
                <Row gutter={24} className="mb-medium" type="flex" align="middle">
                  <Col span={5} />
                  <Col span={19}>
                    {_.chain(bookingErrors)
                      .orderBy('bookingErrorType', 'asc')
                      .map((bookingError, index) => {
                        return (
                          <BookingWarning
                            key={index}
                            bookingError={bookingError}
                            selectedBookingItem={selectedBookingItem}
                            serviceType={'SUPPORT'}
                          />
                        );
                      })
                      .value()}
                  </Col>
                </Row>
              )}
            </div>
          </div>

          <div className="mb-x-large bg-quaternary p-large">{this._renderActionButtons()}</div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  selectedBooking: state.bookingsStore.selectedBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAssignWorker: dispatch.bookingsStore.doAssignWorker,
  doUpdateBookingLocation: dispatch.bookingsStore.doUpdateBookingLocation,
});

export default connect(mapState, mapDispatch)(ActivityRecordOverviewTab);
