import { tv } from 'tailwind-variants';

export const card = tv({
  base: 'rounded-xl border-2 p-2 border-gray-light-1',
  variants: {
    boxShadow: {
      xs: 'shadow-xs',
    },
  },
});
