import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Box, Text } from '@goodhuman-me/components';

import { IBooking } from 'interfaces/booking-interfaces';
import { Popover } from 'views/account-management/WorkLeaveTypesConfigurationView/components';
import {
  ConditionallyHidden,
  CancelBookingSelect,
  CancelBookingsRadio,
  CancelBookingTextArea,
  CancelBookingNumberInput,
} from '../components';
import useIsInputValid from './use-is-input-valid';
import useFocusAndResetRecurringBookingNumber from './use-focus-and-reset-recurring-booking-number';
import useCancelledByValidation from './use-cancelled-by-validation';
import getWatchConditions from './get-watch-conditions';
import { Schema } from '../schema';
import fields from '../fields';

export const testId = 'booking-cancel-action-modal-booking-view';

// enable when we have copy for this popover ready
const SHOW_CANCEL_BOOKING_POPOVER = false;

interface IBookingCancelActionModalBookingViewProps {
  setTitle: (title: string) => void;
  setContinueButtonLabel: (label: string) => void;
  setIsInputValid: (isValid: boolean) => void;
  serviceType: string;
  booking: IBooking;
  isVisible: boolean;
  isEditing: boolean;
}

const BookingCancelActionModalBookingView = ({
  setIsInputValid,
  booking,
  setTitle,
  setContinueButtonLabel,
  isVisible,
  isEditing,
}: IBookingCancelActionModalBookingViewProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'bookings.modals.cancelBooking' });
  const { isRecurring: isRecurringBooking } = booking ?? { isRecurring: null };

  useEffect(() => {
    if (isVisible) {
      if (isEditing) {
        setTitle(t('editCancelStep.title'));
        setContinueButtonLabel(t('editCancelStep.continue'));
      } else {
        setTitle(t('cancelStep.title'));
        setContinueButtonLabel(t('cancelStep.continue'));
      }
    }
  }, [isVisible, isEditing]);

  const form = useFormContext<Schema>();
  useIsInputValid({
    form,
    isRecurringBooking,
    setIsInputValid,
    booking,
  });

  const { resetField } = form;

  useFocusAndResetRecurringBookingNumber(form);
  useCancelledByValidation(form);
  const {
    cancellingOnBehalfOfBusiness,
    cancellingOnBehalfOfCustomer,
    cancellationReason,
    selectedCancelRecurringBookingOption,
  } = getWatchConditions(form);

  if (!booking) {
    return <Text data-testid={`${testId}-no-booking`}>Oops! No booking selected.</Text>;
  }

  return (
    <>
      <Box marginTop="$xsmall" marginBottom="$xsmall" data-testid={`${testId}`}>
        <Box width={375}>
          <Box marginBottom="$large">
            <CancelBookingSelect
              field={fields.cancelledBy}
              onClear={() => {
                resetField(fields.customerCancellationReason.name, {
                  defaultValue: fields.customerCancellationReason.defaultValue,
                });
                resetField(fields.otherCancellationReason.name, {
                  defaultValue: fields.otherCancellationReason.defaultValue,
                });
              }}
            />
          </Box>
          <ConditionallyHidden hidden={!isRecurringBooking || isEditing}>
            <Box marginBottom="$large" data-testid={`${testId}-recurring`}>
              <CancelBookingsRadio
                field={fields.cancelRecurringBooking}
                popover={
                  <Popover
                    title={t('cancelRecurringBooking.popover.title')}
                    content={t('cancelRecurringBooking.popover.content')}
                  />
                }
              />
              <Box paddingLeft="$large" paddingTop="$xxsmall">
                <CancelBookingNumberInput
                  precision={0}
                  max={booking.numberOfBookingLeft}
                  field={fields.numberOfRecurringBookingsToDelete}
                  disabled={selectedCancelRecurringBookingOption !== fields.cancelRecurringBooking.options.specified}
                  extra="bookings"
                />
              </Box>
            </Box>
          </ConditionallyHidden>
          <ConditionallyHidden hidden={cancellingOnBehalfOfBusiness}>
            <Box>
              <CancelBookingSelect
                field={fields.customerCancellationReason}
                onClear={() => {}}
                disabled={!(cancellingOnBehalfOfCustomer || cancellingOnBehalfOfBusiness)}
                popover={
                  SHOW_CANCEL_BOOKING_POPOVER && cancellingOnBehalfOfCustomer ? (
                    <Popover
                      title={t('customerCancellationReason.popover.title')}
                      content={t('customerCancellationReason.popover.content')}
                    />
                  ) : null
                }
              />
            </Box>
          </ConditionallyHidden>
        </Box>
        <ConditionallyHidden
          hidden={
            cancellationReason !== fields.customerCancellationReason.options.other && !cancellingOnBehalfOfBusiness
          }
        >
          <Box paddingTop="$xsmall">
            <CancelBookingTextArea
              field={fields.otherCancellationReason}
              label={cancellingOnBehalfOfBusiness ? t('otherCancellationReason.label') : null}
            />
          </Box>
        </ConditionallyHidden>
      </Box>
    </>
  );
};

export default BookingCancelActionModalBookingView;
