/* eslint-disable */
import React, { PureComponent } from 'react';
import CommonUtils from 'utilities/common-utils';
import TextTag from 'common-components/tags/TextTag';
import { FormStatus } from 'utilities/enum-utils';

interface IFormStatusTagProps {
  status: FormStatus;
  size?: 'small' | 'default' | 'large';
  textSize?: string;
  className?: any;
  textClassName?: any;
  rounded?: boolean;
}

export default class FormStatusTag extends PureComponent<IFormStatusTagProps, any> {
  render() {
    const { status } = this.props;

    let tagColor = 'blue';
    let theme = 'dark';

    switch (status) {
      case FormStatus.ACTIVE:
      case FormStatus.INUSE:
        tagColor = 'green';
        theme = 'light';
        break;
      case FormStatus.DRAFT:
        tagColor = 'secondary';
        theme = 'dark';
        break;
      case FormStatus.ARCHIVED:
        tagColor = 'red';
        theme = 'dark';
        break;
    }

    return (
      <TextTag content={CommonUtils.formatFormStatusString(status)} textSize="regular" theme={theme} color={tagColor} />
    );
  }
}
