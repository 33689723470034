import { useState, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IBooking } from 'interfaces/booking-interfaces';
import CommonUtils from 'utilities/common-utils';
import { Schema } from '../schema';
import useBookingDuration from '../BookingCancelActionModalPaymentView/use-booking-duration';
import getNumberOfCancelledBookings from '../get-number-of-cancelled-bookings';

function useGetText(form: UseFormReturn<Schema>, booking: IBooking, isEditing) {
  const { t } = isEditing
    ? useTranslation('', { keyPrefix: 'bookings.modals.cancelBooking.editSuccessStep' })
    : useTranslation('', { keyPrefix: 'bookings.modals.cancelBooking.successStep' });
  const [text, setText] = useState<{ summary: string; body: string[] }>({ summary: '', body: [] });
  const duration = useBookingDuration(booking);

  useEffect(() => {
    const {
      cancelledBy,
      cancelRecurringBooking,
      numberOfRecurringBookingsToDelete,
      shouldChargeCancellationFee,
      shouldPayTeamMember,
      teamMemberPaymentDuration,
      customTeamMemberPaymentDuration,
      customTeamMemberPaymentDurationUnit,
    } = form.getValues();

    if (!booking) {
      return;
    }

    const isTeamMemberAssigned = Boolean(booking.workerId);

    const paymentDuration =
      teamMemberPaymentDuration === 'entireDuration'
        ? duration
        : `${customTeamMemberPaymentDuration} ${t(`body.duration.${customTeamMemberPaymentDurationUnit}`)}${
            customTeamMemberPaymentDuration > 1 ? 's' : ''
          }`;

    const numberOfCancelledBookings = getNumberOfCancelledBookings(
      booking,
      cancelRecurringBooking,
      numberOfRecurringBookingsToDelete,
    );

    const customerName = CommonUtils.formatFullName({ firstName: booking.firstName, lastName: booking.lastName });
    const teamMemberName = CommonUtils.formatFullName({
      firstName: booking.workerFirstName,
      lastName: booking.workerLastName,
    });

    const summary = t('summary', {
      numberOfCancelledBookings,
      cancelledBy,
      count: numberOfCancelledBookings ?? 1,
    });

    const body: string[] = [];

    if (isEditing && cancelledBy) {
      body.push(t(`body.cancelledBy.${cancelledBy}`));
    }

    body.push(
      t(`body.chargeCancellationFee.${Boolean(shouldChargeCancellationFee) ?? false}`, {
        customerName,
        interpolation: { escapeValue: false },
      }),
    );

    if (isTeamMemberAssigned) {
      body.push(
        t(`body.payTeamMember.${Boolean(shouldPayTeamMember) ?? false}`, {
          teamMemberName,
          paymentDuration,
          interpolation: { escapeValue: false },
        }),
      );
    }

    setText({ summary, body });
  }, [form, duration, booking]);

  return text;
}

export default useGetText;
