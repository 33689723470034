import { tv } from 'tailwind-variants';

export const fieldLabel = tv(
  {
    slots: {
      container: ['break-words', 'flex-wrap', 'font-base', 'gap-1', 'inline-flex', 'items-center', 'm-0'],
      label: ['font-bold', 'leading-b-sm', 'text-b-sm', 'text-strong'],
      indicator: ['leading-b-xs', 'text-b-xs', 'text-weak'],
    },
  },
  { twMerge: false },
);
