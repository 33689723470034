import React from 'react';
import { Skeleton } from '@good/ui/core';
import { Heading } from '@good/components';

import { useSilServiceContext } from '../sil-service-provider/sil-service-provider';

export const SilServiceHeading = () => {
  const { service, isLoading, isError } = useSilServiceContext();

  const heading = (() => {
    if (isLoading || isError) {
      return 'Service';
    }

    if (service) {
      return service.serviceName;
    }

    return 'Service';
  })();

  return (
    <Heading size='xl' asChild>
      <Skeleton visible={isLoading || isError || !service} animate={isLoading}>
        <h1>{heading}</h1>
      </Skeleton>
    </Heading>
  );
};
