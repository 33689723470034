import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph } from 'common-components/typography';
import { Checkbox, Form, Icon, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Information } from 'common-components/alerts';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IArchiveGroupServiceModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  doArchiveGroupService: typeof dispatch.groupServiceStore.doArchiveGroupService;
  groupServiceOverviewWarnings: typeof state.groupServiceStore.groupServiceOverviewWarnings;
}

interface IArchiveGroupServiceModalState {
  isLoading: boolean;
  title: any;
  isArchived: boolean;
}

class ArchiveGroupServiceModal extends Component<IArchiveGroupServiceModalProps, IArchiveGroupServiceModalState> {
  state = { isLoading: false, title: 'Archive service', isArchived: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Archive service', isArchived: false, isLoading: false });
    this.props.onClose();
  };

  private _archiveService = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        this.setState({ isLoading: true });
        await this.props.doArchiveGroupService({
          serviceId: this.props.selectedGroupService.serviceId,
        });
        this.setState({
          title: (
            <>
              Service successfully archived
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isArchived: true,
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
      this.setState({ isLoading: false });
    }
  };

  private _validateAcknowledge = (rule, value, callback) => {
    try {
      if (!value) {
        throw Error('In order to continue you must acknowledge this statement.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  render() {
    const { isOpen, selectedGroupService, groupServiceOverviewWarnings, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isArchived ? (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <Paragraph>You have successfully archived {selectedGroupService.serviceName}.</Paragraph>
              </div>

              <ActionModalFooter>
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  Close
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        ) : groupServiceOverviewWarnings && !groupServiceOverviewWarnings.noUpcomingSessionsWarning ? (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <Information
                  content={
                    <>
                      <Paragraph weight={'bold'}>Cannot archive service</Paragraph>
                      <Paragraph>This service has upcoming sessions and thus cannot be archived.</Paragraph>
                      <Paragraph>
                        Please either cancel or complete the scheduled sessions before you archive this service.
                      </Paragraph>
                    </>
                  }
                />
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" onClick={this._onCloseModal}>
                  Close
                </SecondaryButton>
              </ActionModalFooter>
            </div>
          </>
        ) : (
          <>
            <div className="mb-large anim-slide-left">
              <Paragraph>
                You are choosing to archive <b>{selectedGroupService ? selectedGroupService.serviceName : ''}</b>.
              </Paragraph>
              <Paragraph>
                This action is <b>Permanent</b> and cannot be reversed.
                <Form.Item className="width-full">
                  {getFieldDecorator('acknowledgment', { rules: [{ validator: this._validateAcknowledge }] })(
                    <Checkbox>
                      I understand that archiving a service means I can no longer create sessions for it.
                    </Checkbox>,
                  )}
                </Form.Item>
              </Paragraph>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._archiveService} loading={this.state.isLoading}>
                  Archive
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doArchiveGroupService: dispatch.groupServiceStore.doArchiveGroupService,
});

const mapState = (state: IRootState) => ({
  groupServiceOverviewWarnings: state.groupServiceStore.groupServiceOverviewWarnings,
});

export default connect(mapState, mapDispatch)(Form.create<IArchiveGroupServiceModalProps>()(ArchiveGroupServiceModal));
