import { Popover, Spinner } from '@blueprintjs/core';
import { Avatar, Checkbox, Divider, Empty, Icon, Input, Switch, Tooltip } from 'antd';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import InfoPanel from 'common-components/alerts/InfoPanel';
import { IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FilterSection } from 'common-components/filter';
import NumberInput from 'common-components/inputs/NumberInput';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import TimeInput from 'common-components/time-input/TimeInput';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import { IFilter } from 'interfaces/filter-interfaces';
import { ISession, ISessionSupportWorker } from 'interfaces/session-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import {
  // ActivityGroupModalType,
  FilterType,
  GroupServiceSessionStatus,
  PublishShiftApplicationAvailability,
  ShiftSlotStatus,
} from 'utilities/enum-utils';
import { ShiftStatusTag } from 'views/group-services/session-details/team-members/shift-slot-table/ShiftStatusTag';
import { ShiftClashContent } from 'common-components/shift-clash';
// import SelectActivityGroups from 'common-components/activity-groups/selectors/SelectActivityGroups';
import { IGroupServiceActivityGroup } from 'interfaces/service-interfaces';
import WorkerStatusTagV2 from 'common-components/tags/WorkerStatusTagV2';

interface IAddShiftSlotModalProps {
  isOpen: boolean;
  onClose: any;
  sessionSupportWorkers: typeof state.groupServiceStore.sessionSupportWorkers;
  doAddShiftSlots: typeof dispatch.groupServiceStore.doAddShiftSlots;
  setSessionSupportWorkers: typeof dispatch.groupServiceStore.setSessionSupportWorkers;
  doFetchSessionSupportWorkers: typeof dispatch.groupServiceStore.doFetchSessionSupportWorkers;
  doFetchSessionActivityGroups: typeof dispatch.groupServiceStore.doFetchSessionActivityGroups;
  doFetchGroupServiceActivityGroups: typeof dispatch.groupServiceStore.doFetchGroupServiceActivityGroups;
  session: ISession;
  shiftClashConflicts: typeof state.groupServiceStore.shiftClashConflicts;
  doFetchShiftClashConflicts: typeof dispatch.groupServiceStore.doFetchShiftClashConflicts;
  groupServiceActivityGroups: typeof state.groupServiceStore.groupServiceActivityGroups;
  sessionActivityGroups: typeof state.groupServiceStore.sessionActivityGroups;
  groupServiceUnAssignedMembers: typeof state.groupServiceStore.groupServiceUnAssignedMembers;
  sessionUnassignedMembers: typeof state.groupServiceStore.sessionUnassignedMembers;
}

interface IAddShiftSlotModalState {
  step: number;
  canManuallyClose: boolean;
  numberOfSlots: number;
  startDateTime: Date;
  endDateTime: Date;
  selectedWorkers: ISessionSupportWorker[];
  filters: IFilter[];
  isSearchAvailable: boolean;
  isLoading: boolean;
  isSearching: boolean;
  isWarningNotEnoughWorker: boolean;
  refreshShiftSlots: boolean;
  targetStatus: string;
  selectedActivityGroups: IGroupServiceActivityGroup[];
}

const availableFilters = [
  FilterType.QUALIFICATIONS,
  FilterType.SKILLS,
  FilterType.RELIGIONS,
  FilterType.LANGUAGES,
  FilterType.GENDER,
  FilterType.INTEREST,
  FilterType.SPECIALITIES,
];

class AddShiftSlotModal extends Component<IAddShiftSlotModalProps, IAddShiftSlotModalState> {
  state = {
    step: 1,
    canManuallyClose: true,
    numberOfSlots: 1,
    startDateTime: moment(
      moment.tz(this.props.session.startDateTime, this.props.session.timezone).format('YYYY-MM-DD HH:mm'),
    ).toDate(),
    endDateTime: moment(
      moment.tz(this.props.session.endDateTime, this.props.session.timezone).format('YYYY-MM-DD HH:mm'),
    ).toDate(),
    selectedWorkers: [],
    filters: [],
    isSearchAvailable: true,
    isLoading: false,
    isSearching: false,
    isWarningNotEnoughWorker: false,
    refreshShiftSlots: false,
    targetStatus: ShiftSlotStatus.PENDING,
    selectedActivityGroups: [],
  };

  reset = () => {
    const { session } = this.props;
    this.setState({
      step: 1,
      canManuallyClose: true,
      numberOfSlots: 1,
      startDateTime: moment(moment.tz(session.startDateTime, session.timezone).format('YYYY-MM-DD HH:mm')).toDate(),
      endDateTime: moment(moment.tz(session.endDateTime, session.timezone).format('YYYY-MM-DD HH:mm')).toDate(),
      selectedWorkers: [],
      filters: [],
      isSearchAvailable: true,
      isLoading: false,
      isSearching: false,
      isWarningNotEnoughWorker: false,
      refreshShiftSlots: false,
    });
  };

  goToStep = (step: number, canManuallyClose = true, refreshShiftSlots = false) => {
    this.setState({ step: step, canManuallyClose: canManuallyClose, refreshShiftSlots: refreshShiftSlots });
  };

  onBack = () => {
    this.setState({ selectedWorkers: [] });
    this.goToStep(1);
  };

  onAssignTeamMembers = async () => {
    const payload = this._buildPayload();
    await this._loadContent(payload);
    // go to assign team member step
    this.goToStep(2);
  };

  private _onInteractWithAddedItems = (addedItems: IGroupServiceActivityGroup[]) => {
    this.setState({ selectedActivityGroups: addedItems });
  };

  onAddShiftSlot = async (shiftSlotStatus: ShiftSlotStatus = null) => {
    const { doAddShiftSlots, session } = this.props;
    const { serviceId, serviceDateTimeId } = session;
    const { startDateTime, endDateTime, numberOfSlots, selectedWorkers } = this.state;

    // Show loading
    this.goToStep(3, false);

    const request = {
      startDateTime: moment.tz(moment(startDateTime).format('YYYY-MM-DD HH:mm'), session.timezone).toDate(),
      endDateTime: moment.tz(moment(endDateTime).format('YYYY-MM-DD HH:mm'), session.timezone).toDate(),
      numberOfSlots,
      serviceId,
      serviceDateTimeId,
    };

    if (shiftSlotStatus) {
      const supportWorkerIds = _.map(selectedWorkers, (w) => w.supportWorkerId);
      await doAddShiftSlots({ ...request, shiftSlotStatus, supportWorkerIds });
    } else {
      await doAddShiftSlots(request);
    }

    // go to success step
    this.goToStep(5, true, true);
  };

  handleLogicBeforeAddShiftSlot = async (targetStatus: ShiftSlotStatus = null) => {
    const { selectedWorkers, step, numberOfSlots, startDateTime, endDateTime } = this.state;
    const { session, doFetchShiftClashConflicts } = this.props;

    // validation
    if (step > 1 && selectedWorkers.length < numberOfSlots) {
      this.setState({ isWarningNotEnoughWorker: true });
      return;
    }

    // get all conflict shifts of selected worker to show in ShiftClashModal
    const { serviceId, serviceDateTimeId, timezone } = session;
    const payload = {
      serviceDateTimeId,
      serviceId,
      startDateTime: moment(startDateTime).tz(timezone, true).format(),
      endDateTime: moment(endDateTime).tz(timezone, true).format(),

      supportWorkerIds: selectedWorkers.map((worker) => worker.supportWorkerId),
    };
    const shiftClashConflicts = await doFetchShiftClashConflicts(payload);

    // no shift conflicts
    if (_.isEmpty(shiftClashConflicts)) {
      await this.onAddShiftSlot(targetStatus);
    }
    // has shift conflicts
    else {
      this.setState({ targetStatus: targetStatus });
      this.goToStep(4);
    }
  };

  onConfirmAssignShiftClash = () => {
    const { targetStatus } = this.state;
    this.onAddShiftSlot(targetStatus);
  };

  onCancelShiftClash = () => {
    this.goToStep(2);
  };

  onChangeSlots = (v) => {
    this.setState({ numberOfSlots: v });
  };

  onChangeStartDate = (date) => {
    this.setState({ startDateTime: moment(date).toDate() });
    const minEndDate = moment(date).add(1, 'hour').toDate();
    if (this.state.endDateTime < minEndDate) {
      this.setState({ endDateTime: moment(minEndDate).toDate() });
    }
  };

  // TODO Handle start time
  onChangeStartTime = (date) => {
    this.setState({ startDateTime: moment(CommonUtils.formatCeilingDateTime(date)).toDate() });
    const minEndDate = moment(CommonUtils.formatCeilingDateTime(date)).add(1, 'hour').toDate();
    if (this.state.endDateTime < minEndDate) {
      this.setState({ endDateTime: moment(minEndDate).toDate() });
    }
  };

  onChangeEndTime = (date) => {
    if (moment(date).isAfter(this.state.startDateTime)) {
      this.setState({ endDateTime: moment(CommonUtils.formatCeilingDateTime(date)).toDate() });
    } else {
      this.setState({ endDateTime: this.state.endDateTime });
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    this.reset();
    onClose({ targetFlag: 'isAddShiftSlotOpen' }, this.state.refreshShiftSlots);
  };

  onChangeFilter = (filters: Array<any>) => {
    this.setState({ filters });
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.state.filters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'qualifications':
            requestFilter.qualifications = filter.values;
            break;
          case 'skills':
            requestFilter.skills = filter.values;
            break;
          case 'languages':
            requestFilter.languages = filter.values;
            break;
          case 'interests':
            requestFilter.interests = filter.values;
            break;
          case 'religions':
            requestFilter.religions = filter.values;
            break;
          case 'specialities':
            requestFilter.specialities = filter.values;
            break;
          case 'gender':
            requestFilter.gender = filter.values;
            break;
          default:
            break;
        }
      }
    });

    return requestFilter;
  };

  onToggleSearchAvailable = async () => {
    const newSearchAvailable = !this.state.isSearchAvailable;
    this.setState({ isSearchAvailable: newSearchAvailable });
    const payload = this._buildPayload();
    payload.isAvailable = newSearchAvailable;
    await this._loadContent(payload);
  };

  onAddWorker = (worker: ISessionSupportWorker) => {
    const newWorkers = _.cloneDeep(this.state.selectedWorkers);
    newWorkers.push(worker);
    const newSupportWorkers = _.cloneDeep(this.props.sessionSupportWorkers);
    const isListFull = newWorkers.length === this.state.numberOfSlots;

    _.forEach(newSupportWorkers, (supportWorker: ISessionSupportWorker) => {
      if (supportWorker.supportWorkerId === worker.supportWorkerId) {
        supportWorker.checked = true;
      }
      if (isListFull && !supportWorker.checked) {
        supportWorker.disableCheck = true;
      }
    });
    this.props.setSessionSupportWorkers(newSupportWorkers);

    this.setState({ selectedWorkers: newWorkers, isWarningNotEnoughWorker: false });
  };

  onRemoveWorker = (worker) => {
    const newWorkers = _.filter(this.state.selectedWorkers, (selectedWorker) => {
      return worker.supportWorkerId !== selectedWorker.supportWorkerId;
    });

    if (newWorkers.length === this.state.numberOfSlots - 1) {
      const newSupportWorkers = _.cloneDeep(this.props.sessionSupportWorkers);
      _.forEach(newSupportWorkers, (supportWorker: ISessionSupportWorker) => {
        if (supportWorker.supportWorkerId === worker.supportWorkerId) {
          supportWorker.checked = false;
        }
        supportWorker.disableCheck = false;
      });
      this.props.setSessionSupportWorkers(newSupportWorkers);
    }

    this.setState({ selectedWorkers: newWorkers });
  };

  private _loadContent = async (payload) => {
    this.setState({ isLoading: true });
    await this.props.doFetchSessionSupportWorkers(payload);
    this.setState({ isLoading: false });
  };

  private _searchText = async (txt) => {
    const payload = this._buildPayload();
    await this._loadContent({ ...payload, searchString: txt });
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this.setState({ isSearching: true });
      this._debounceSearch(e.target.value);
    }
  };

  private _buildPayload = () => {
    const { session } = this.props;
    const { startDateTime, endDateTime, isSearchAvailable } = this.state;
    const { serviceDateTimeId, serviceId } = session;
    const requestFilter = this._formatFilterQuery();

    const payload = {
      serviceDateTimeId,
      serviceId,
      startDateTime: moment.tz(moment(startDateTime).format('YYYY-MM-DD HH:mm'), session.timezone).toDate(),
      endDateTime: moment.tz(moment(endDateTime).format('YYYY-MM-DD HH:mm'), session.timezone).toDate(),
      isAvailable: isSearchAvailable,
    };

    return _.isEmpty(requestFilter) ? payload : { ...payload, ...requestFilter };
  };

  componentDidMount = async () => {
    const payload = this._buildPayload();
    await this._loadContent(payload);
  };

  componentDidUpdate = async (_prevProps, prevState) => {
    if (this.state.filters !== prevState.filters) {
      const payload = this._buildPayload();
      await this._loadContent(payload);
    }
  };

  render() {
    const { session, sessionSupportWorkers, shiftClashConflicts } = this.props;
    const { startDateTime, endDateTime, numberOfSlots, selectedWorkers } = this.state;
    const sessionStartDateTime = moment(moment.tz(session.startDateTime, session.timezone).format('YYYY-MM-DD HH:mm'));
    const workerCount = selectedWorkers ? selectedWorkers.length : 0;
    const isSessionComplete = session.sessionStatus === GroupServiceSessionStatus.COMPLETED;

    if (_.isEmpty(session)) {
      return <></>;
    }

    const WorkerEmptyState = () => (
      <div className="flex-1 bg-white mt-x2-large align-center flex-column">
        <div className="">
          <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
        </div>
        <div>
          <Text size="x2-large" color="secondary" weight="bold">
            No team member(s) found.
          </Text>
        </div>
        <Text color="secondary">Try adjusting your filters on the left.</Text>
      </div>
    );

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={
          this.state.step === 4
            ? `Hold on, ${numberOfSlots > 1 ? 'these' : 'this'} team member${
                numberOfSlots > 1 ? 's' : ''
              } is already working`
            : ' Select a team member for this shift'
        }
        onClose={this.onClose}
        canCloseOutside={this.state.canManuallyClose}
        width={this.state.step === 2 ? 'x-large' : 'large'}
      >
        {/* Add shifts slot */}
        {this.state.step === 1 && (
          <div>
            <div className="line-height-135 mb-medium">
              <Text lineHeight={135}>
                {isSessionComplete
                  ? "This shift will be used for the team member's shift start/end time when the session is completed."
                  : 'The following shift(s) will be added.'}
              </Text>
            </div>

            <div className="mb-large flex-row">
              <div className="flex-1 mr-medium">
                <FieldLabel text={isSessionComplete ? 'NUMBER OF SHIFTS' : 'NUMBER OF SHIFTS TO ADD'} />

                <div className="flex-row mt-x2-small">
                  <NumberInput
                    size="large"
                    style={{ width: '148px' }}
                    addonAfter={'shifts'}
                    value={this.state.numberOfSlots}
                    onChange={this.onChangeSlots}
                    precision={0}
                  />
                </div>
              </div>

              <div className="flex-1" />
            </div>

            <Divider className="divider-large" />

            <div className="mb-large flex-row">
              <div className="flex-1 mr-medium">
                <FieldLabel text={'SHIFT START TIME'} />

                <div className="flex-row mt-x2-small">
                  <DatePicker
                    className="gh-datepicker rounded mr-medium"
                    calendarClassName="gh-datepicker-calendar"
                    onChange={this.onChangeStartDate}
                    dateFormat={'dd/MM/yyyy'}
                    selected={moment(startDateTime).toDate()}
                    isClearable={false}
                    minDate={sessionStartDateTime.toDate()}
                  />

                  <TimeInput size="large" onChange={this.onChangeStartTime} value={moment(startDateTime)} />
                </div>
              </div>

              <div className="flex-1">
                <FieldLabel text={'SHIFT FINISH TIME'} />

                <div className="flex-row mt-x2-small">
                  <DatePicker
                    className="gh-datepicker rounded mr-medium"
                    calendarClassName="gh-datepicker-calendar"
                    onChange={this.onChangeEndTime}
                    dateFormat={'dd/MM/yyyy'}
                    selected={moment(endDateTime).toDate()}
                    isClearable={false}
                    minDate={moment(startDateTime).toDate()}
                  />

                  <TimeInput size="large" onChange={this.onChangeEndTime} value={moment(endDateTime)} />
                </div>
              </div>
            </div>

            {!isSessionComplete && (
              <div className="mv-large">
                <InfoPanel
                  text={
                    <>
                      You can choose to <b>create shifts & assign team members</b> with the{' '}
                      <b>Add & assign team members</b> option, or opt to <b>assign team members later</b> with the{' '}
                      <b>Add shifts only</b> option below.
                    </>
                  }
                />
              </div>
            )}

            {/* Temporarily hide assign team member to activity groups when add & assign member to shift slot - will rework for v2 */}

            {/* <div>
              <SelectActivityGroups
                serviceId=""
                onInteractWithAddedItems={this._onInteractWithAddedItems}
                isRenderSessionCard={true}
                groupSessionActivityGroups={this.props.sessionActivityGroups}
                modalType={ActivityGroupModalType.ASSIGN_TEAM_MEMBER_TO_ACTIVITY_GROUP}
                timezone={session.timezone}
              />
            </div> */}

            <ActionModalFooter align="right">
              <div className="flex-row justify-end">
                <SecondaryButton size="large" className="mr-medium" onClick={this.onClose}>
                  Cancel
                </SecondaryButton>

                {/* Check session status */}
                {isSessionComplete ? (
                  <PrimaryButton size="large" onClick={this.onAssignTeamMembers} className="rounded-left">
                    Next
                  </PrimaryButton>
                ) : (
                  <>
                    <PrimaryButton size="large" onClick={() => this.onAddShiftSlot()} className="mr-medium">
                      Add shifts only
                    </PrimaryButton>
                    <PrimaryButton size="large" onClick={this.onAssignTeamMembers} className="">
                      Add & assign team members
                    </PrimaryButton>
                  </>
                )}
              </div>
            </ActionModalFooter>
          </div>
        )}

        {/* Assign to team member */}
        {this.state.step === 2 && (
          <div className="line-height-135 anim-fade-in-fast">
            <div className="mb-medium">
              <Text lineHeight={135}>Select & assign team members for this shift.</Text>
            </div>

            {/* Top display */}
            <div className="rounded-big bordered border-standard-gray flex-row line-height-120 mb-large">
              <div className="p-medium bordered-right border-standard-gray flex-column justify-center">
                <Text lineHeight={120} size="x-large" color="secondary">
                  {numberOfSlots}x
                </Text>
              </div>
              <div className="p-medium bg-quaternary flex-column justify-center align-end">
                <Text lineHeight={120} className="text-align-right">
                  {moment(startDateTime).format('h:mm A')}
                  <br />
                  {moment(endDateTime).format('h:mm A')}
                </Text>
              </div>
              <div className="flex-row p-medium bg-quaternary align-center">
                <div>
                  <Avatar icon="user" shape="square" className="mr-medium" />
                </div>
                <div>
                  <Text color="secondary">Not assigned</Text>
                </div>
              </div>
              <div className="p-medium flex-1 bg-quaternary flex-column justify-center">
                <div className="inline-block">
                  <ShiftStatusTag shiftStatus={ShiftSlotStatus.UNASSIGNED} />
                  {/*<TextTag color="secondary" theme="light" content="Open slot" />*/}
                </div>
              </div>
            </div>

            {/* Filter selectors */}
            <div className="flex-row line-height-120 mb-medium">
              {/* Left panel */}
              <div className="bg-quaternary p-medium rounded mr-medium" style={{ width: '360px' }}>
                <div className="mb-medium">
                  <FieldLabel text={'FILTERS'} />
                </div>
                <div className="mb-medium">
                  <Text lineHeight={120}>Show team members who...</Text>
                </div>
                <div className="mb-medium">
                  <Switch
                    size="small"
                    checked={this.state.isSearchAvailable}
                    onChange={this.onToggleSearchAvailable}
                    style={{ backgroundColor: this.state.isSearchAvailable ? '#1890FF' : '' }}
                  />
                  <Text lineHeight={200} className="ml-small" size="x-small">
                    Are available during the scheduled shift(s)
                  </Text>
                </div>

                <div>
                  <FilterSection
                    availableFilters={availableFilters}
                    filters={this.state.filters}
                    onChangeFilter={this.onChangeFilter}
                    displayTimezone={''}
                    usePortal={false}
                  />
                </div>
              </div>

              {/* Right panel */}
              <div className="flex-1">
                <div className="mb-medium">
                  <Text size={'regular'} color="secondary" lineHeight={120}>
                    {workerCount}/{numberOfSlots} team members selected
                  </Text>
                </div>
                <div className="mb-medium">
                  <Input.Search
                    size="large"
                    placeholder={'Search for team members...'}
                    onChange={this._onEnterSearchText}
                    loading={this.state.isSearching}
                    allowClear
                  />
                </div>

                <div
                  className="bordered border-standard-gray rounded"
                  style={{ minHeight: '30vh', maxHeight: '40vh', overflowY: 'auto' }}
                >
                  {_.isEmpty(sessionSupportWorkers) ? (
                    <WorkerEmptyState />
                  ) : (
                    _.map(sessionSupportWorkers, (worker) => (
                      <AssignWorkerItem
                        worker={worker}
                        onAddWorker={() => this.onAddWorker(worker)}
                        onRemoveWorker={() => this.onRemoveWorker(worker)}
                        key={worker.supportWorkerId}
                      />
                    ))
                  )}
                </div>
              </div>
            </div>

            {/* Action buttons */}
            <ActionModalFooter align="right">
              {isSessionComplete ? (
                <div className="flex-row justify-end align-start">
                  <SecondaryButton size="large" className="mr-medium" onClick={this.onClose}>
                    Cancel
                  </SecondaryButton>
                  <div>
                    <PrimaryButton
                      size="large"
                      onClick={() => this.onAddShiftSlot(ShiftSlotStatus.CONFIRMED)}
                      className="rounded-left"
                    >
                      Assign team member(s)
                    </PrimaryButton>
                    {this.state.isWarningNotEnoughWorker && (
                      <div>
                        <Text color="red-dark">All shifts must be filled.</Text>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex-row justify-end">
                    <SecondaryButton size="large" className="mr-medium" onClick={this.onBack}>
                      Back
                    </SecondaryButton>

                    <PrimaryButton
                      size="large"
                      onClick={() => this.handleLogicBeforeAddShiftSlot(ShiftSlotStatus.CONFIRMED)}
                      className="rounded-left"
                    >
                      Assign as <b> Confirmed</b>
                    </PrimaryButton>

                    <Popover
                      content={
                        <ActionMenu>
                          <ActionMenuItem
                            text={'Assign as Pending'}
                            onClick={() => this.handleLogicBeforeAddShiftSlot(ShiftSlotStatus.PENDING)}
                          />
                        </ActionMenu>
                      }
                      position="bottom-left"
                      usePortal={false}
                      minimal={true}
                    >
                      <IconButton size="large" icon={'down'} className="ml-x2-small rounded-right" />
                    </Popover>
                  </div>
                  {this.state.isWarningNotEnoughWorker && (
                    <div>
                      <Text color="red-dark">All shifts must be filled.</Text>
                    </div>
                  )}
                </>
              )}
            </ActionModalFooter>
          </div>
        )}

        {/* Loading screen */}
        {this.state.step === 3 && (
          <div className="line-height-135 anim-slide-left">
            <div className="flex-column align-center mv-large justify-center">
              <div className="mb-medium">
                <Spinner size={80} />
              </div>
              <div className="text-align-center">
                <Text color="secondary" weight="bold">
                  Adding shift(s)...
                </Text>
                <br />
                <Text color="secondary">This won&apos;t take long.</Text>
              </div>
            </div>
          </div>
        )}

        {/* shift clash  */}
        {this.state.step === 4 && (
          <div className="anim-fade-in-fast">
            <ShiftClashContent
              shiftClashConflicts={shiftClashConflicts}
              description={(() => {
                const totalShiftClashes = CommonUtils.calculateTotalShiftClashes(shiftClashConflicts);
                return (
                  <Paragraph>
                    {selectedWorkers.length > 1 ? 'These' : 'This'} team member{selectedWorkers.length > 1 ? 's' : ''}{' '}
                    is assigned to{' '}
                    <b>
                      {totalShiftClashes} shift{totalShiftClashes > 1 ? 's ' : ' '}
                    </b>
                    that conflict{totalShiftClashes === 1 ? 's' : ''} with {numberOfSlots > 1 ? 'these ' : 'this '}
                    booking time{numberOfSlots > 1 ? 's ' : ''}. Be sure to double-check their availability before
                    confirming.
                  </Paragraph>
                );
              })()}
              firstColumnTitle="Booking being assigned"
              okButtonText="Assign anyway"
              onCancel={this.onCancelShiftClash}
              onOk={this.onConfirmAssignShiftClash}
            />
          </div>
        )}

        {/* Success */}
        {this.state.step === 5 && (
          <div className="anim-fade-in-fast">
            <div className="mb-medium">
              <Text>The following shift(s) has been added to this session.</Text>
            </div>

            <div className="mb-medium">
              <FieldLabel text="SHIFT TIME" />
              <div className="mt-x2-small line-height-135">
                <Text lineHeight={135}>
                  {/*3:00 PM - 5:00 PM, 4 April 2021*/}
                  {moment(this.state.startDateTime).format('h:mm A')} -{' '}
                  {moment(this.state.endDateTime).format('h:mm A, D MMMM YYYY')}
                </Text>
              </div>
            </div>

            <Divider className="divider-medium" />

            <div className="mb-medium flex-row">
              <div className="flex-1 mr-medium">
                <FieldLabel text="SHIFTS" />
                <div className="mt-x2-small line-height-135">
                  <Text lineHeight={135}>
                    <b>{this.state.numberOfSlots}</b> shift(s)
                  </Text>
                </div>
              </div>

              {workerCount > 0 && (
                <div className="flex-1">
                  <FieldLabel text="ASSIGNED TO" />
                  <div className="mt-x2-small line-height-135">
                    <Text lineHeight={135}>
                      <b>{workerCount}</b> team members
                    </Text>
                  </div>
                </div>
              )}
            </div>

            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

interface IAssignWorkerItemProps {
  worker: ISessionSupportWorker;
  onAddWorker: () => void;
  onRemoveWorker: () => void;
}

interface IAssignWorkerItemState {
  isChecked: boolean;
}

class AssignWorkerItem extends Component<IAssignWorkerItemProps, IAssignWorkerItemState> {
  state = {
    isChecked: this.props.worker.checked,
  };

  onChangeCheck = () => {
    const newIsChecked = !this.state.isChecked;
    if (newIsChecked) {
      this.props.onAddWorker();
    } else {
      this.props.onRemoveWorker();
    }

    this.setState({ isChecked: newIsChecked });
  };

  onClickWorker = (isAvailable) => {
    const { worker } = this.props;

    if (isAvailable) {
      if (!worker.disableCheck) {
        this.onChangeCheck();
      }
    }
  };

  checkCanAssign = (workerAvailability: string) => {
    // check can assign
    switch (workerAvailability) {
      // can assign even conflict
      case PublishShiftApplicationAvailability.AVAILABLE:
      case PublishShiftApplicationAvailability.SHIFT_CLASH:
      case PublishShiftApplicationAvailability.UNAVAILABLE:
      case PublishShiftApplicationAvailability.OUTSIDE_GENERAL_AVAILABILITY:
      case PublishShiftApplicationAvailability.UNAVAILABLE_CAN_BE_OVERRIDDEN:
        return true;

      // can't assign
      default:
        return false;
    }
  };

  render() {
    const { worker } = this.props;

    const isAvailable = this.checkCanAssign(worker.availability);

    const isShiftClash = worker.availability === PublishShiftApplicationAvailability.SHIFT_CLASH;
    const isOutsideGeneralAvailability =
      worker.availability === PublishShiftApplicationAvailability.OUTSIDE_GENERAL_AVAILABILITY;
    const isUnavailableCanBeOverridden =
      worker.availability === PublishShiftApplicationAvailability.UNAVAILABLE_CAN_BE_OVERRIDDEN;

    const unavailableStyle = {
      iconClassName: 'text-color-red-dark bg-red-lightest',
      text: 'Unavailable',
      iconType: 'close',
    };

    const unavailableCanBeOverriddenStyle = {
      iconClassName: 'text-color-red-dark bg-red-lightest',
      text: 'Unavailable (can override)',
      iconType: 'close',
    };

    const shiftClashStyle = {
      iconClassName: 'text-color-red-dark bg-red-lightest',
      text: 'Shift Clash (can override)',
      iconType: 'close',
    };

    const outsideGeneralAvailabilityStyle = {
      iconClassName: 'text-color-orange bg-orange-lightest',
      text: 'Outside general availability',
      iconType: 'question',
    };

    const availableStyle = {
      iconClassName: 'text-color-green-dark bg-green-lightest',
      text: 'Available',
      iconType: 'check',
    };

    const iconStyle = !isAvailable
      ? unavailableStyle
      : isShiftClash
      ? shiftClashStyle
      : isUnavailableCanBeOverridden
      ? unavailableCanBeOverriddenStyle
      : isOutsideGeneralAvailability
      ? outsideGeneralAvailabilityStyle
      : availableStyle;

    return (
      <div
        className={`flex-row ph-medium align-center select-none hover-bg-quaternary ${isAvailable && 'cursor-pointer'}`}
        style={{ paddingTop: '12px', paddingBottom: '12px' }}
        onClick={() => this.onClickWorker(isAvailable)}
      >
        <Checkbox checked={this.state.isChecked} disabled={worker.disableCheck || !isAvailable} />
        <Avatar
          style={{ opacity: !isAvailable ? 0.5 : 1 }}
          src={worker.attachmentUrl}
          size="large"
          className="ml-medium mr-medium"
          shape={'square'}
        />
        <div
          style={{ width: '220px', flexDirection: 'column', opacity: !isAvailable ? 0.5 : 1 }}
          className="line-height-150"
        >
          <Text lineHeight={150}>{`${worker.firstName} ${worker.lastName}`}</Text> <br />
          <div className="line-height-100 align-center flex-row">
            <WorkerStatusTagV2 shiftSlotStatus={worker.availability} icon="info-circle" />
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  sessionSupportWorkers: state.groupServiceStore.sessionSupportWorkers,
  shiftClashConflicts: state.groupServiceStore.shiftClashConflicts,
  groupServiceActivityGroups: state.groupServiceStore.groupServiceActivityGroups,
  sessionActivityGroups: state.groupServiceStore.sessionActivityGroups,
  groupServiceUnAssignedMembers: state.groupServiceStore.groupServiceUnAssignedMembers,
  sessionUnassignedMembers: state.groupServiceStore.sessionUnassignedMembers,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddShiftSlots: dispatch.groupServiceStore.doAddShiftSlots,
  setSessionSupportWorkers: dispatch.groupServiceStore.setSessionSupportWorkers,
  doFetchSessionSupportWorkers: dispatch.groupServiceStore.doFetchSessionSupportWorkers,
  doFetchShiftClashConflicts: dispatch.groupServiceStore.doFetchShiftClashConflicts,
  doFetchSessionActivityGroups: dispatch.groupServiceStore.doFetchSessionActivityGroups,
  doFetchGroupServiceActivityGroups: dispatch.groupServiceStore.doFetchGroupServiceActivityGroups,
});

export default connect(mapState, mapDispatch)(AddShiftSlotModal);
