import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Vector = forwardRef(function Vector(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM6.776 14.33a8.204 8.204 0 0 1 7.554-7.554A3.751 3.751 0 0 0 21.75 6a3.75 3.75 0 0 0-7.43-.727 9.705 9.705 0 0 0-9.047 9.047A3.751 3.751 0 0 0 6 21.75a3.75 3.75 0 0 0 .776-7.42ZM3.75 18a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
