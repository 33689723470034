import React, { Component } from 'react';
import * as H from 'history';
import _ from 'lodash';
import { GridRow } from 'common-components/grids';
import { Avatar, Col, Tag, Icon } from 'antd';
import { GhostButton, HyperlinkButton } from 'common-components/buttons';
import { Popover } from '@blueprintjs/core';
import { Text } from 'common-components/typography';
import { IGroupServiceTeamMember } from 'interfaces/service-interfaces';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';

interface ITeamMemberListItemProps {
  history: H.History;
  teamMemberItem: IGroupServiceTeamMember;
  onOpenRemoveTeamMemberModal: (i) => void;
  onOpenTeamMemberScheduleModal: (i) => void;
}

class TeamMemberListItem extends Component<ITeamMemberListItemProps> {
  private _getPopoverContent = () => {
    return (
      <div>
        <ActionMenu className="pv-small">
          {/*<ActionMenuItem label={''} className="hover-bg-secondary pv-small ph-medium" text={'View schedule'} />*/}
          <ActionMenuItem
            label={''}
            className="hover-bg-secondary pv-small ph-medium"
            text={'View schedule'}
            onClick={() => this.props.onOpenTeamMemberScheduleModal(this.props.teamMemberItem)}
          />
          <ActionMenuItem
            label={''}
            className="text-color-red hover-bg-secondary pv-small ph-medium"
            text={'Remove from roster'}
            onClick={() => this.props.onOpenRemoveTeamMemberModal(this.props.teamMemberItem)}
          />
        </ActionMenu>
      </div>
    );
  };

  private _getActivityGroupPopoverContent = (activityGroups) => {
    return (
      <div className="ph-x-large pv-medium" style={{ minWidth: '327px', maxHeight: '246px', overflowX: 'hidden' }}>
        <div className="mb-medium">
          <Text className={'text-weight-bold'}>Assigned to ({_.get(activityGroups, 'length')}) activity groups</Text>
        </div>
        <div className="flex-column space-between" style={{ rowGap: '12px' }}>
          {_.map(activityGroups, (item) => {
            return (
              <div className="whitespace-nowrap">
                <Tag key={item} className="tag-icon bg-tertiary flex-row align-center">
                  <Icon type="calendar" style={{ fontSize: '14px' }} />
                  <Text className={'text-overflow-ellipsis'} size="regular">
                    {item}
                  </Text>
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  private _viewTeamMemberProfile = () => {
    const newUrl = `/team/details/${this.props.teamMemberItem.supportWorkerId}`;
    this.props.history.push(newUrl);
  };

  render() {
    const { teamMemberItem } = this.props;
    const activityGroupsLength = _.get(teamMemberItem, 'activityGroups.length', 0);

    return (
      <GridRow containerClassName={'bordered rounded mt-medium'}>
        <Col span={6}>
          <div className="flex-row align-center">
            <Avatar className="mr-medium" size="large" icon="user" src={teamMemberItem.teamMemberAvatarUrl} />
            <HyperlinkButton
              onClick={this._viewTeamMemberProfile}
            >{`${teamMemberItem.teamMemberFirstName} ${teamMemberItem.teamMemberLastName}`}</HyperlinkButton>
          </div>
        </Col>
        <Col span={6} className={'text-align-center'}>
          <Text>{teamMemberItem.upcomingShiftSlotCount}</Text>
        </Col>
        <Col span={6} className={'text-align-center'}>
          <Text>{teamMemberItem.completedShiftSlotCount}</Text>
        </Col>
        <Col span={4} className={'text-align-center'}>
          {activityGroupsLength !== 0 && (
            <Popover
              content={this._getActivityGroupPopoverContent(_.get(teamMemberItem, 'activityGroups', []))}
              position={'bottom-right'}
              interactionKind="hover"
            >
              <Tag className="tag-icon bg-tertiary flex-row align-center" style={{ border: 'none' }}>
                <Icon type="calendar" style={{ fontSize: '14px' }} />
                <Text size="regular">
                  {activityGroupsLength} {activityGroupsLength >= 2 ? 'groups' : 'activity'}
                </Text>
              </Tag>
            </Popover>
          )}
        </Col>
        <Col span={2} className="text-align-right">
          <Popover
            content={this._getPopoverContent()}
            popoverClassName={'mb-medium'}
            position={'bottom-right'}
            interactionKind="click"
          >
            <div
              className="bordered border-standard-gray rounded text-align-center"
              style={{ width: '28px', height: '28px' }}
            >
              <GhostButton icon={'ellipsis'} size={'small'} paddingSize={'x-small'} />
            </div>
          </Popover>
        </Col>
      </GridRow>
    );
  }
}

export default TeamMemberListItem;
