import React from 'react';
import { ListUnordered } from '@good/icons';

import { useRichTextEditor } from '../rich-text-editor-context';
import { MenuButton } from './menu-button';

export const UnorderedListButton = () => {
  const { editor, disabled } = useRichTextEditor();

  const isDisabled = disabled || !editor.can().chain().focus().toggleBulletList().run();
  const onClick = () => editor.chain().focus().toggleBulletList().run();
  const variant = editor.isActive('bulletList') ? 'filled' : 'transparent';

  return (
    <MenuButton onClick={onClick} disabled={isDisabled} variant={variant} aria-label='unordered-list'>
      <ListUnordered />
    </MenuButton>
  );
};
