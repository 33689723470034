import z from 'zod';

import { defaultValidationRules } from './defaults';

const applyDefaultValidationRule = (
  value: string | undefined | null,
  fieldName: keyof typeof defaultValidationRules,
  validationRule: string,
) => {
  const validationFn = defaultValidationRules[fieldName]?.[validationRule];
  if (!validationFn) {
    return true;
  }
  const result = validationFn(value);

  if (typeof result === 'boolean') {
    return result;
  }

  if (typeof result === 'string') {
    return false;
  }

  return true;
};

export const ZodAddress = z
  .object({
    fullAddress: z.string().refine((value) => applyDefaultValidationRule(value, 'automaticInput', 'required'), {
      message: 'address is required',
    }),
    streetAddress1: z
      .string()
      .optional()
      .refine((value) => applyDefaultValidationRule(value, 'manualInput.streetAddress1', 'required'), {
        message: 'streetAddress1 is required',
      }),
    streetAddress2: z.string().optional(),
    locality: z
      .string()
      .optional()
      .refine((value) => applyDefaultValidationRule(value, 'manualInput.locality', 'required'), {
        message: 'locality is required',
      }),
    state: z
      .string()
      .optional()
      .nullable()
      .refine((value) => applyDefaultValidationRule(value, 'manualInput.state', 'required'), {
        message: 'state is required',
      }),
    postcode: z
      .string()
      .optional()
      .refine((value) => applyDefaultValidationRule(value, 'manualInput.postcode', 'required'), {
        message: 'postcode is required',
      })
      .refine((value) => applyDefaultValidationRule(value, 'manualInput.postcode', 'length'), {
        message: 'postcode is invalid',
      }),
    geoLat: z.number().optional().nullable(),
    geoLng: z.number().optional().nullable(),
  })
  .nullable()
  .refine((value) => value !== null, { message: 'Address is required' });
