import { Form } from 'antd/es';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle } from 'common-components/typography';
import Utils from 'utilities/Utils';
import React, { useEffect } from 'react';
import { ICreatingInvoiceNote } from 'src/interfaces/booking-interfaces';
import { InvoiceNoteModalType } from '../../utils/constants';

interface Props extends FormComponentProps {
  isOpen: boolean;
  currentCustomerId: string;
  currentPaymentMethod: string;
  modifyingInvoiceNotes: ICreatingInvoiceNote[];
  invoiceNoteModalType: InvoiceNoteModalType;
  onSave(invoiceNotes: ICreatingInvoiceNote[]): void;
  onClose(): void;
}

const InvoiceNoteModal = (props: Props) => {
  const {
    form,
    isOpen,
    currentCustomerId,
    currentPaymentMethod,
    modifyingInvoiceNotes,
    invoiceNoteModalType,
    onSave,
    onClose,
  } = props;
  const { getFieldDecorator, setFieldsValue, getFieldError } = form;

  const saveNote = () => {
    form.validateFieldsAndScroll((error, values) => {
      if (error) return;

      const trimmedValue = values.note.trim();

      const newModifyingInvoiceNotes = modifyingInvoiceNotes.filter(
        (invoiceNote) => invoiceNote.userId !== currentCustomerId || invoiceNote.paymentMethod !== currentPaymentMethod,
      );

      // Always save new instance for preventing memory address conflict with the exist ones
      if (!Utils.isEmpty(trimmedValue)) {
        newModifyingInvoiceNotes.push({
          userId: currentCustomerId,
          note: trimmedValue,
          paymentMethod: currentPaymentMethod,
        });
      }

      onSave(newModifyingInvoiceNotes);
    });
  };

  useEffect(
    function setDefaultNote() {
      const currentInvoiceNote = modifyingInvoiceNotes.find(
        (note) => note.userId === currentCustomerId && note.paymentMethod === currentPaymentMethod,
      );
      setFieldsValue({ note: currentInvoiceNote?.note ?? '' });
    },
    [currentCustomerId, modifyingInvoiceNotes, currentPaymentMethod],
  );

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={invoiceNoteModalType === InvoiceNoteModalType.ADD ? 'Add notes' : 'Edit notes'}
    >
      <Form>
        <SubTitle containerClassName="mb-medium mt-small">Notes</SubTitle>
        <div>
          {getFieldDecorator('note', {
            rules: [{ max: 1000, message: 'Please enter less than 1000 characters' }],
          })(<TextArea style={{ padding: '11px 12px' }} placeholder="Enter notes here..." autoSize={{ minRows: 5 }} />)}
        </div>
        {!!getFieldError('note') && <Paragraph color="red-darker">{getFieldError('note')}</Paragraph>}
        <div style={{ marginBottom: 7 }}>
          <ActionModalFooter className="pt-x2-large">
            <SecondaryButton size="large" onClick={onClose}>
              Discard
            </SecondaryButton>
            <PrimaryButton size="large" className="ml-medium" onClick={saveNote}>
              Save changes
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </Form>
    </ActionModal>
  );
};

export default Form.create<Props>()(InvoiceNoteModal);
