import React, { Component } from 'react';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle, Title, Text } from 'common-components/typography';
import { Form, Checkbox, Icon, notification } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { SecondaryButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';
import { Information } from 'common-components/alerts';
import PublishBookingSearchWorkerModal from 'views/bookings/components/PublishBookingSearchWorkerModal';
import PublishWorkerRow from 'views/bookings/components/PublishWorkerRow';
import moment from 'moment';
import { CustomerSupportWorkerPreference } from 'utilities/enum-utils';
import WorkerStatusTagV2 from 'common-components/tags/WorkerStatusTagV2';

interface IPublishBookingSettingsModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  publishWorkersList: typeof state.bookingsStore.publishWorkersList;
  publishSettings: typeof state.bookingsStore.publishSettings;
  doFetchPublishSettings: typeof dispatch.bookingsStore.doFetchPublishSettings;
  doFetchWorkerSearchList: typeof dispatch.bookingsStore.doFetchWorkerSearchList;
  doPublishToWorkers: typeof dispatch.bookingsStore.doPublishToWorkers;
  doUnpublishShift: typeof dispatch.bookingsStore.doUnpublishShift;
  isCreateNewBooking?: boolean;
  savePublishList?: (publishList) => void;
  removePublish?: () => void;
}

interface IPublishBookingSettingsModalState {
  isLoading: boolean;
  isLoadingSelectedWorkers: boolean;
  isSuccess: boolean;
  selectedWorkers: Array<any>;
  workerGroups: {
    isSkillMatched: boolean;
    previouslyUsed: boolean;
    isAvailable: boolean;
    isOnRoster: boolean;
    isCustomerPreferred: boolean;
  };
  isSearchOpen: boolean;
  isOpenStop: boolean;
}

class PublishBookingSettingsModal extends Component<
  IPublishBookingSettingsModalProps,
  IPublishBookingSettingsModalState
> {
  state = {
    isLoading: false,
    isLoadingSelectedWorkers: false,
    isSuccess: false,
    selectedWorkers: [],
    workerGroups: {
      isSkillMatched: false,
      previouslyUsed: false,
      isAvailable: false,
      isOnRoster: false,
      isCustomerPreferred: false,
    },
    isSearchOpen: false,
    isOpenStop: false,
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({
        isOpenStop: false,
        isSearchOpen: false,
        selectedWorkers:
          this.props.publishSettings && this.props.publishSettings.supportWorkers
            ? this.props.publishSettings.supportWorkers
            : [],
        workerGroups:
          this.props.publishSettings && this.props.publishSettings.workerGroups
            ? this.props.publishSettings.workerGroups
            : {
                serviceRosterWorkers: false,
                previouslyUsedWorkers: false,
              },
      });
      onClose();
    }
  };

  private _doPublishToWorkers = async () => {
    const { doPublishToWorkers, selectedBookingItem } = this.props;
    const { selectedWorkers, workerGroups } = this.state;
    const filteredSelectedWorkers = _.filter(
      selectedWorkers,
      (worker) =>
        !(!worker.isIncluded && !worker.onRoster && !worker.previouslyUsed) &&
        worker.preference !== CustomerSupportWorkerPreference.BLOCKED,
    );
    const data = {
      bookingId: selectedBookingItem.bookingId,
      supportWorkers: filteredSelectedWorkers ? filteredSelectedWorkers : null,
      workerGroups: workerGroups ? workerGroups : null,
      isShiftPublished: selectedBookingItem.isShiftPublished ? selectedBookingItem.isShiftPublished : false,
    };
    try {
      this.setState({ isLoading: true });
      await doPublishToWorkers(data);
      this.setState({ isLoading: false });
      notification.success({
        message: selectedBookingItem.isShiftPublished ? 'Shift publish updated.' : 'Shift published to workers.',
      });
      this._onCloseModal();
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong, please try again.',
      });
      this.setState({ isLoading: false });
    }
  };

  private _savePublishList = () => {
    const { savePublishList } = this.props;
    const { selectedWorkers, workerGroups } = this.state;

    if (savePublishList) {
      savePublishList({ supportWorkers: selectedWorkers, workerGroups });
      this._onCloseModal();
    } else {
      notification.error({
        message: 'Oops, something went wrong, please try again.',
      });
    }
  };

  private _removePublish = () => {
    const { removePublish } = this.props;
    removePublish();
    this._onCloseModal();
  };

  private _doStopPublishToWorker = async () => {
    const { selectedBookingItem, doUnpublishShift } = this.props;
    const data = {
      bookingId: selectedBookingItem.bookingId,
      serviceId: selectedBookingItem.serviceId,
    };
    try {
      this.setState({ isLoading: true });
      await doUnpublishShift(data);
      this.setState({ isLoading: false });
      notification.success({
        message: 'Shift unpublished.',
      });
      this._onCloseModal();
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong, please try again.',
      });
      this.setState({ isLoading: false });
    }
  };

  private _doChangeSetting = async (event, workerGroupLabel) => {
    const { selectedBookingItem } = this.props;
    const { workerGroups } = this.state;
    const newWorkerGroups = { ...workerGroups, [workerGroupLabel]: event.target.checked };

    this.setState({ isLoadingSelectedWorkers: true, workerGroups: newWorkerGroups });

    const isAllUnselect =
      !newWorkerGroups.previouslyUsed &&
      !newWorkerGroups.isAvailable &&
      !newWorkerGroups.isOnRoster &&
      !newWorkerGroups.isSkillMatched &&
      !newWorkerGroups.isCustomerPreferred;

    const newSelectedWorkers: any = isAllUnselect
      ? []
      : await this.props.doFetchWorkerSearchList({
          startDateTime: selectedBookingItem.startDateTime
            ? selectedBookingItem.startDateTime
            : selectedBookingItem.bookStartTime,
          endDateTime: selectedBookingItem.endDateTime
            ? selectedBookingItem.endDateTime
            : selectedBookingItem.bookEndTime,
          address: selectedBookingItem.address ? selectedBookingItem.address : selectedBookingItem.bookLocation,
          customerUserIds: !this.props.isCreateNewBooking
            ? [selectedBookingItem.bookerUserId]
            : [selectedBookingItem.selectedCustomer.userId],
          serviceId: !this.props.isCreateNewBooking
            ? selectedBookingItem.serviceId
            : selectedBookingItem.selectedServiceId,
          ...newWorkerGroups,
        });

    if (newSelectedWorkers) {
      this.setState({
        selectedWorkers: newSelectedWorkers,
        isLoadingSelectedWorkers: false,
      });
    }
  };

  private _changeIsIncluded = (event, supportWorkerId, workerData = null) => {
    const { selectedWorkers } = this.state;
    let newSelectedWorkers = [];
    if (_.find(selectedWorkers, (worker) => worker.supportWorkerId === supportWorkerId)) {
      newSelectedWorkers = _.map(selectedWorkers, (worker) => {
        return supportWorkerId === worker.supportWorkerId ? { ...worker, isIncluded: event } : { ...worker };
      });
    } else if (workerData && event) {
      newSelectedWorkers = selectedWorkers;
      newSelectedWorkers.push({ ...workerData, isIncluded: true });
    }

    this.setState({
      selectedWorkers: newSelectedWorkers,
    });
  };

  private _onOpenSearchModal = () => {
    this.setState({ isSearchOpen: true });
  };

  private _onCloseSearchModal = () => {
    this.setState({ isSearchOpen: false });
  };

  private _onOpenStopModal = () => {
    this.setState({ isOpenStop: true });
  };

  private _onCloseStopModal = () => {
    this.setState({ isOpenStop: false });
  };

  componentDidMount = async () => {
    if (this.props.selectedBookingItem.isShiftPublished) {
      if (!this.props.isCreateNewBooking) {
        this.setState({ isLoading: true });
        await this.props.doFetchPublishSettings({
          bookingId: this.props.selectedBookingItem.bookingId,
          serviceId: this.props.selectedBookingItem.serviceId,
        });
        this.setState({
          isLoading: false,
          selectedWorkers:
            this.props.publishSettings && this.props.publishSettings.supportWorkers
              ? this.props.publishSettings.supportWorkers
              : [],
          workerGroups:
            this.props.publishSettings && this.props.publishSettings.workerGroups
              ? this.props.publishSettings.workerGroups
              : {
                  isSkillMatched: false,
                  previouslyUsed: false,
                  isAvailable: false,
                  isOnRoster: false,
                },
        });
      } else {
        this.setState({
          isLoading: false,
          selectedWorkers:
            this.props.selectedBookingItem && this.props.selectedBookingItem.supportWorkers
              ? this.props.selectedBookingItem.supportWorkers
              : [],
          workerGroups:
            this.props.selectedBookingItem && this.props.selectedBookingItem.workerGroups
              ? this.props.selectedBookingItem.workerGroups
              : {
                  isSkillMatched: false,
                  previouslyUsed: false,
                  isAvailable: false,
                  isOnRoster: false,
                },
        });
      }
    }
  };

  render() {
    const { isOpen } = this.props;
    const { selectedWorkers, workerGroups, isSearchOpen, isOpenStop, isLoadingSelectedWorkers } = this.state;

    const sortedSelectedWorkers = _.sortBy(selectedWorkers, 'firstName', 'lastName');

    return (
      <>
        {this.props.selectedBookingItem.isShiftPublished && (
          <ActionModal
            title=""
            showCloseButton={false}
            isOpen={isOpenStop}
            onClose={this._onCloseStopModal}
            width="large"
            verticalAlignment="center"
          >
            <div className="pt-none p-x2-large">
              <Title level={4}>Are you sure you want to Stop Publishing to Team members?</Title>
              <div className="flex-row">
                {/*<div className="width-1/5"></div>*/}
                {/*<div className="width-4/5">*/}
                <div className="mb-large">
                  <Paragraph>When you stop publishing the following will occur:</Paragraph>
                  <ul>
                    <li>Team members will no longer see this shift in their app</li>
                    <li>
                      Team members that have applied will be notified that they were unsuccessful
                      <br />
                      (You will still be able to view them in the applicant list)
                    </li>
                  </ul>
                  <Information content="If you intend to choose an applicant, it is better to continue publishing and assign a Team member from the Applicant list." />
                </div>
              </div>
              <div className="flex-row justify-between">
                <SecondaryButton size="large" onClick={this._onCloseStopModal}>
                  Continue publishing
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  color="red"
                  onClick={this._doStopPublishToWorker}
                  loading={this.state.isLoading}
                >
                  Stop publishing to Team members
                </PrimaryButton>
              </div>
            </div>
          </ActionModal>
        )}
        <ActionModal
          title={
            this.props.selectedBookingItem.isShiftPublished
              ? 'Published on: ' + moment(this.props.selectedBookingItem.shiftPublishDate).format('DD/MM/YYYY')
              : 'Publish to Team members'
          }
          isOpen={isOpen}
          onClose={this._onCloseModal}
          width="x3-large"
          verticalAlignment="center"
        >
          {!this.state.isLoading && !this.state.isSuccess && (
            <>
              <div>
                <Paragraph className="mb-large">
                  Select Team members that can see and apply for this shift via the app, allowing you to choose the best
                  applicant.
                  <br />
                  {this.props.isCreateNewBooking
                    ? 'This booking will not be made available to team members until it has been created'
                    : 'Changing these settings won’t affect Team members that have already applied.'}
                </Paragraph>
              </div>
              <PublishBookingSearchWorkerModal
                isOpen={isSearchOpen}
                selectedWorkers={sortedSelectedWorkers}
                selectedBookingItem={this.props.selectedBookingItem}
                changeIsIncluded={this._changeIsIncluded}
                onCloseSearchModal={this._onCloseSearchModal}
                isCreateNewBooking={this.props.isCreateNewBooking}
              />
              <table className="width-full flex-row align-start">
                <tbody>
                  <tr style={{ verticalAlign: 'top' }}>
                    <td style={{ width: '45%' }}>
                      <div className="bg-tertiary p-medium mb-large">
                        <Title level={4}>Automatically add Team members who...</Title>
                        <div className="ph-small pv-small">
                          <Checkbox
                            checked={workerGroups.previouslyUsed}
                            className="m-none mb-x-small"
                            onChange={(event) => this._doChangeSetting(event, 'previouslyUsed')}
                          >
                            <Text lineHeight={200} size="x-small">
                              Worked with the customer(s) before
                            </Text>
                          </Checkbox>
                          <Checkbox
                            checked={workerGroups.isAvailable}
                            className="m-none mb-x-small"
                            onChange={(event) => this._doChangeSetting(event, 'isAvailable')}
                          >
                            <Text size="x-small">Available to work this booking</Text>
                          </Checkbox>
                          <Checkbox
                            checked={workerGroups.isSkillMatched}
                            className="m-none mb-x-small"
                            onChange={(event) => this._doChangeSetting(event, 'isSkillMatched')}
                          >
                            <Text size="x-small">Skill matched for the customer(s) care needs</Text>
                          </Checkbox>
                          <Checkbox
                            checked={workerGroups.isOnRoster}
                            className="m-none mb-x-small"
                            onChange={(event) => this._doChangeSetting(event, 'isOnRoster')}
                          >
                            <Text size="x-small">Are on the roster for this service</Text>
                          </Checkbox>
                          <Checkbox
                            checked={workerGroups.isCustomerPreferred}
                            className="m-none"
                            onChange={(event) => this._doChangeSetting(event, 'isCustomerPreferred')}
                          >
                            <Text size="x-small">Preferred team members by the customer</Text>
                          </Checkbox>
                        </div>
                      </div>
                    </td>
                    <td style={{ width: '32px' }} />
                    <td className="p-medium">
                      <div className="mb-x-small">
                        <div className="flex-row justify-between align-center ">
                          <Title level={4}>Team members that can see and apply ({sortedSelectedWorkers.length})</Title>
                          <HyperlinkButton onClick={this._onOpenSearchModal}>
                            <Icon type="search" className="mr-x-small" />
                            Find Team member
                          </HyperlinkButton>
                        </div>
                        <div style={{ minHeight: '300px', height: 'calc(100vh - 600px)', overflow: 'auto' }}>
                          {this.state.isLoadingSelectedWorkers && <SpinningLoader size={100} message={'Loading'} />}
                          {_.isEmpty(sortedSelectedWorkers) && !isLoadingSelectedWorkers && (
                            <div className="mt-x2-large text-align-center" style={{ margin: 'auto' }}>
                              <Text weight="bold">No Team member added</Text>
                              <br />
                              <Text>Select groups or find Workers to add.</Text>
                            </div>
                          )}
                          {sortedSelectedWorkers.length > 0 && !isLoadingSelectedWorkers && (
                            <table className="width-full">
                              <tbody>
                                <tr className="bordered-bottom">
                                  <td className="text-align-center pb-small" style={{ width: '100px' }}>
                                    <SubTitle>Include</SubTitle>
                                  </td>
                                  <td colSpan={2} className="pb-small">
                                    <SubTitle>Team members</SubTitle>
                                  </td>
                                </tr>
                                {_.map(sortedSelectedWorkers, (worker) => {
                                  return (
                                    <PublishWorkerRow
                                      worker={worker}
                                      changeIsIncluded={this._changeIsIncluded}
                                      isSearch={false}
                                    />
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
          {!this.state.isSuccess &&
            (!this.props.isCreateNewBooking ? (
              <div className="pt-medium flex-row justify-between">
                <div>
                  {this.props.selectedBookingItem.isShiftPublished && (
                    <SecondaryButton
                      size="large"
                      color="red"
                      onClick={this._onOpenStopModal}
                      className="mr-medium"
                      disabled={this.state.isLoading}
                    >
                      Stop publishing to Team members
                    </SecondaryButton>
                  )}
                </div>
                <div>
                  <SecondaryButton
                    size="large"
                    onClick={this._onCloseModal}
                    className="mr-medium"
                    disabled={this.state.isLoading}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    size="large"
                    loading={this.state.isLoading}
                    disabled={
                      _.isEmpty(sortedSelectedWorkers) ||
                      !_.countBy(sortedSelectedWorkers, (worker) => worker.isIncluded).true
                    }
                    onClick={this._doPublishToWorkers}
                  >
                    {this.props.selectedBookingItem.isShiftPublished ? 'Apply changes' : 'Publish to Team members'}
                  </PrimaryButton>
                </div>
              </div>
            ) : (
              <div className="pt-medium flex-row justify-between">
                <div>
                  {this.props.selectedBookingItem.isShiftPublished && (
                    <SecondaryButton
                      size="large"
                      color="red"
                      onClick={this._removePublish}
                      className="mr-medium"
                      disabled={this.state.isLoading}
                    >
                      Remove Publish
                    </SecondaryButton>
                  )}
                </div>
                <div>
                  <SecondaryButton
                    size="large"
                    onClick={this._onCloseModal}
                    className="mr-medium"
                    disabled={this.state.isLoading}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    size="large"
                    loading={this.state.isLoading}
                    disabled={
                      _.isEmpty(sortedSelectedWorkers) ||
                      !_.countBy(sortedSelectedWorkers, (worker) => worker.isIncluded).true
                    }
                    onClick={this._savePublishList}
                  >
                    Save Publish List
                  </PrimaryButton>
                </div>
              </div>
            ))}
        </ActionModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  publishWorkersList: state.bookingsStore.publishWorkersList,
  publishSettings: state.bookingsStore.publishSettings,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchWorkerSearchList: dispatch.bookingsStore.doFetchWorkerSearchList,
  doFetchPublishSettings: dispatch.bookingsStore.doFetchPublishSettings,
  doPublishToWorkers: dispatch.bookingsStore.doPublishToWorkers,
  doUnpublishShift: dispatch.bookingsStore.doUnpublishShift,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IPublishBookingSettingsModalProps>()(PublishBookingSettingsModal));
