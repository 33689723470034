import React from 'react';
import { Box, Icons, Inline, Text } from '@goodhuman-me/components';
import { Popover2 } from '@blueprintjs/popover2';

import * as styles from './legend.css';

import type { PropsWithChildren } from 'react';

function Info(): JSX.Element {
  return (
    <Popover2
      content={
        <Box padding="$space200">
          <Text size="xsmall">Graph does not show historical bookings that have not been paid.</Text>
        </Box>
      }
      position="top"
      interactionKind="hover"
    >
      <Text fontSize="xxsmall" color="$muted">
        <Icons.Info width="1em" />
      </Text>
    </Popover2>
  );
}

type LegendItemProps = PropsWithChildren<
  Pick<SVGLineElement, 'stroke'> & Partial<Pick<SVGLineElement, 'strokeDasharray'>>
>;

function LegendItem(props: LegendItemProps) {
  let { children, ...lineProps } = props;

  return (
    <Inline gap="$space100" alignItems="center">
      <svg height={1} width={12} className={styles.reset()}>
        <line x1="0" x2="12" y1="0" y2="0" strokeWidth="2px" strokeLinecap="round" {...lineProps} />
      </svg>

      {children}
    </Inline>
  );
}

export type LegendProps = Pick<SVGLineElement, 'stroke'>;

export function Legend(props: LegendProps): JSX.Element {
  let { stroke } = props;

  return (
    <Inline gap="$space400">
      <LegendItem stroke="#1A285B">
        <Text size="xsmall">Allocated</Text>
      </LegendItem>

      <LegendItem stroke={stroke}>
        <Text size="xsmall">Spent</Text> <Info />
      </LegendItem>

      <LegendItem stroke={stroke} strokeDasharray="4">
        <Text size="xsmall">Projected</Text>
      </LegendItem>

      <LegendItem stroke="#8B66A2" strokeDasharray="0,4">
        <Text size="xsmall">Planned pace</Text>
      </LegendItem>
    </Inline>
  );
}
