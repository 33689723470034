import React, { PureComponent } from 'react';
import { Alert, Row } from 'antd';
import { Text } from 'common-components/typography';
import { IWorkflowStep, IWorkflow } from 'interfaces/workflow-interfaces';
import { ActiveWorkflowsStatus } from 'utilities/enum-utils';

interface Props {
  selectedWorkflow: IWorkflow;
  activeStep: IWorkflowStep | null;
}

class WorkflowDetailsAlertSection extends PureComponent<Props> {
  render() {
    const { selectedWorkflow, activeStep } = this.props;
    const isInprogressWorkflow = selectedWorkflow.status === ActiveWorkflowsStatus.INPROGRESS;

    return (
      <Row>
        {isInprogressWorkflow && selectedWorkflow.issue && selectedWorkflow.issue.isFlagged && (
          <Alert
            type="error"
            showIcon
            className="rounded-big"
            message={
              <Text weight="bolder" color="red">
                Issue flagged by: {selectedWorkflow.issue.reporter}
              </Text>
            }
            description={<Text className="break-word whitespace-pre-line">{selectedWorkflow.issue.message}</Text>}
          />
        )}

        {isInprogressWorkflow && selectedWorkflow.issue && !selectedWorkflow.issue.isFlagged && (
          <Alert
            type="error"
            showIcon
            className="rounded-big"
            message={
              <Text weight="bolder" color="red">
                Workflow sent back to step {activeStep.stepNumber} by: {selectedWorkflow.issue.reporter}
              </Text>
            }
            description={<Text className="break-word whitespace-pre-line">{selectedWorkflow.issue.message}</Text>}
          />
        )}
        {selectedWorkflow.status === ActiveWorkflowsStatus.CLOSED && (
          <Alert
            type="error"
            showIcon
            className="rounded-big"
            message={
              <Text weight="bolder" color="red">
                Workflow closed by: {selectedWorkflow.closedBy}
              </Text>
            }
            description={<Text className="break-word whitespace-pre-line">{selectedWorkflow.closedReason}</Text>}
          />
        )}

        {!selectedWorkflow.issue && selectedWorkflow.status == ActiveWorkflowsStatus.COMPLETED && (
          <Alert
            type="success"
            showIcon
            className="bg-green-lightest rounded-big"
            message={
              <Text weight="bolder" color="green">
                Incident completed
              </Text>
            }
            description={
              <Text>
                This incident workflow has been <strong>completed</strong>. No further action is required.
              </Text>
            }
          />
        )}
      </Row>
    );
  }
}

export default WorkflowDetailsAlertSection;
