import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const TextItalic = forwardRef(function TextItalic(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.016 3.25H19a.75.75 0 0 1 0 1.5h-4.415l-3.625 14.5H15a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1 0-1.5h4.414l3.625-14.5H9a.75.75 0 1 1 0-1.5h5.016Z"
        fill="currentColor"
      />
    </Icon>
  );
});
