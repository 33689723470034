import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Hashtag = forwardRef(function Hashtag(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.745 3.083a.75.75 0 1 0-1.49-.166L8.662 8.25H3a.75.75 0 0 0 0 1.5h5.495l-.5 4.5H2a.75.75 0 1 0 0 1.5h5.829l-.574 5.167a.75.75 0 1 0 1.49.166l.593-5.333h4.49l-.573 5.167a.75.75 0 1 0 1.49.166l.593-5.333H20a.75.75 0 0 0 0-1.5h-4.495l.5-4.5H21a.75.75 0 0 0 0-1.5h-4.829l.574-5.167a.75.75 0 1 0-1.49-.166l-.593 5.333h-4.49l.573-5.167Zm3.25 11.167.5-4.5h-4.49l-.5 4.5h4.49Z"
        fill="currentColor"
      />
    </Icon>
  );
});
