import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Voicemail = forwardRef(function Voicemail(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.75a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5ZM10.75 12c0 1.257-.488 2.4-1.286 3.25h5.072A4.75 4.75 0 1 1 18 16.75H6A4.75 4.75 0 1 1 10.75 12ZM18 15.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
