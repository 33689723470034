import React from 'react';
import { Text } from '@good/ui/core';
import { Image } from '@good/icons';

export const ImageIcon = () => {
  return (
    <Text c='var(--brand-body-dark-1)' size='32px'>
      <Image aria-label='icon of picture' />
    </Text>
  );
};
