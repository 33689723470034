/**
 * @todo
 * + `minItemWidth` takes responsive value.
 * + `minItemWidth` has theme types.
 */
import { Slot as _Slot } from '@radix-ui/react-slot';
import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';
import { styled } from '@stitches/react';

import { boxStyleProps, css, useStyleProps } from '..';

import type { BoxStyleProps, HTMLProps, SlotProps } from '..';
import type { PropsWithChildren } from 'react';

export type GridProps = SlotProps &
  HTMLProps<HTMLDivElement> &
  BoxStyleProps & {
    minItemWidth: string;
  } & Required<PropsWithChildren<unknown>>;

const Div = styled('div');
const Slot = styled(_Slot);

/**
 * @name
 * Grid
 *
 * @description
 * `Grid` creates a responsive grid of items that will automatically
 * wrap based on the number of children and the `minItemWidth`
 * prop's value.
 */
export const Grid = forwardRef<HTMLDivElement, GridProps>(function Grid(props, ref) {
  const { styleProps, ...otherProps } = useStyleProps(props, boxStyleProps);
  const { asChild, children, minItemWidth } = props;
  const ElementType = asChild ? Slot : Div;

  return (
    <ElementType
      {...filterDOMProps(otherProps)}
      className={css({
        ...styleProps,
        display: 'grid',
        gap: '$medium',
        gridTemplateColumns: `repeat(
          auto-fit,
          minmax(min(var(--minItemWidth, ${minItemWidth}), 100%), 1fr)
        )`,
      })()}
      ref={ref}
    >
      {children}
    </ElementType>
  );
});
