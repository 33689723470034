import React from 'react';
import { Button, Text } from '@good/components';

type TmMgmtUninvitedBatchInviteParams = {
  selectedTeamMemberIds: string[];
  onBatchInvite: () => void;
};

export const TmMgmtUninvitedBatchInvite = ({
  selectedTeamMemberIds,
  onBatchInvite,
}: TmMgmtUninvitedBatchInviteParams) => {
  const pluralise = selectedTeamMemberIds.length > 1 ? 's' : '';

  return (
    <div className="flex w-full flex-row flex-nowrap rounded-lg bg-[#F4F8F9]">
      <div className="mx-2 my-auto  pl-4">
        <Text className="font-bold">{`${selectedTeamMemberIds.length} team member${pluralise} selected`}</Text>
        <Text className="ml-2 !text-[#CCCAC6]">|</Text>
      </div>
      <Button className="my-2.5" size="md" emphasis="fill" onClick={onBatchInvite}>
        {`Invite team member${pluralise}`}
      </Button>
    </div>
  );
};
