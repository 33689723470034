import React, { Component } from 'react';
import _ from 'lodash';
import Title from 'antd/lib/typography/Title';
import { Checkbox, Col, Row, Icon, Tag } from 'antd';
import { Paragraph, Text } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import moment from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import { IGroupServiceActivityGroup, IGroupServiceTimeSlot } from 'interfaces/service-interfaces';
import ActivityGroupsModal from 'common-components/activity-groups/modals/ActivityGroupsModal';
import { ActivityGroupMemberType, ActivityGroupModalType } from 'utilities/enum-utils';
import { Popover } from '@blueprintjs/core';
import { Popover2, Classes } from '@blueprintjs/popover2';
import EditShiftTimeOfAllSessionsModal from './EditShiftTimeOfAllSessionsModal';

interface ISelectScheduleStepPanelProps {
  onPreviousStep: () => void;
  onNextStep: () => void;
  onSetSelectedTimeSlot: (v) => void;
  groupServiceTimeSlots: IGroupServiceTimeSlot[];
  activityGroups: IGroupServiceActivityGroup[];
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  selectedTimeSlot: IGroupServiceTimeSlot;
  doFetchGroupServiceTimeSlots: typeof dispatch.groupServiceStore.doFetchGroupServiceTimeSlots;
  doFetchGroupServiceActivityGroups: typeof dispatch.groupServiceStore.doFetchGroupServiceActivityGroups;
  setGroupServiceTimeSlots: typeof dispatch.groupServiceStore.setGroupServiceTimeSlots;
  setGroupServiceActivityGroups: typeof dispatch.groupServiceStore.setGroupServiceActivityGroups;
}

interface ISelectScheduleStepPanelState {
  isLoading: boolean;
  isNotScheduleSelectedError: boolean;
  selectedTimeSlot: IGroupServiceTimeSlot;
  serviceDateTimes: IGroupServiceTimeSlot[];
  isModalOpen: boolean;
  currentModalType: ActivityGroupModalType;
  isEditShiftTimeModalOpen: boolean;
}

class SelectScheduleStepPanel extends Component<ISelectScheduleStepPanelProps, ISelectScheduleStepPanelState> {
  state = {
    isLoading: false,
    isNotScheduleSelectedError: false,
    selectedTimeSlot: _.get(this.props, 'selectedTimeSlot', null),
    serviceDateTimes: [],
    isModalOpen: false,
    currentModalType: ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SCHEDULE,
    currentMemberType: ActivityGroupMemberType.TEAM_MEMBER,
    isEditShiftTimeModalOpen: false,
  };

  private _goToNext = () => {
    if (!this.state.selectedTimeSlot) {
      this.setState({ isNotScheduleSelectedError: true });
      return;
    }

    this.props.onSetSelectedTimeSlot(this.state.selectedTimeSlot);
    this.props.onNextStep();
  };

  private _selectTimeSlot = (slot: IGroupServiceTimeSlot) => {
    const { selectedTimeSlot } = this.state;
    if (selectedTimeSlot && slot.serviceScheduleId === selectedTimeSlot.serviceScheduleId) {
      this.setState({ selectedTimeSlot: null });
    } else {
      this.setState({ selectedTimeSlot: slot });
    }
  };

  private _groupTimeSlots = () => {
    const { groupServiceTimeSlots } = this.props;
    if (_.isEmpty(groupServiceTimeSlots)) return [];

    // Filtered out the past sessions in response.
    const availableTimeSlots = groupServiceTimeSlots.map((slot) => {
      const newServiceDateTimes = slot.serviceDateTimes.filter((session) => moment().isBefore(session.startDateTime));
      slot.serviceDateTimes = newServiceDateTimes;
      return slot;
    });
    // Filtered out time slots with no available upcomming sessions.
    const filteredSlots = availableTimeSlots.filter((slot) => slot.serviceDateTimes.length > 0);

    // Group the timeslots by schedule id to iterate in render function.
    const groupedSlotsObject = _.groupBy(filteredSlots, (slot) => slot.serviceScheduleId);
    return Object.values(groupedSlotsObject);
  };

  private _getActivityGroupPopoverContent = (activityGroups: IGroupServiceActivityGroup[]) => {
    const { selectedGroupService } = this.props;
    return (
      <div className="ph-x-large pv-medium" style={{ width: '446px', maxHeight: '362px', overflowX: 'hidden' }}>
        <div className="mb-medium">
          <Text className={'text-weight-bold'}>Available activity groups ({_.get(activityGroups, 'length')})</Text>
        </div>
        <div className="flex-column space-between" style={{ rowGap: '12px' }}>
          {_.map(activityGroups, (activityGroup: IGroupServiceActivityGroup) => {
            const { serviceActivityGroupId, name, startDateTime, endDateTime } = activityGroup;
            const startDateTimeMoment = moment.tz(startDateTime, selectedGroupService.timezone);
            const endDateTimeMoment = moment.tz(endDateTime, selectedGroupService.timezone);
            return (
              <>
                <div className="whitespace-nowrap">
                  <Tag key={serviceActivityGroupId} className="tag-icon bg-tertiary flex-row align-center">
                    <Icon type="calendar" style={{ fontSize: '14px' }} />
                    <Text className={'text-overflow-ellipsis text-weight-bold'} weight="500" size="regular">
                      {name}
                    </Text>
                  </Tag>
                </div>
                <div className="whitespace-nowrap">
                  <Text>
                    {startDateTimeMoment.format('hh:mm A')} - {endDateTimeMoment.format('hh:mm A')} •{' '}
                    {startDateTimeMoment.format('ddd, D MMM')} - {endDateTimeMoment.format('ddd, D MMM YYYY')}
                  </Text>
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  };

  private _handleModalAction = async (payload, actionType: ActivityGroupModalType) => {
    const { onSetSelectedTimeSlot, groupServiceTimeSlots, setGroupServiceTimeSlots } = this.props;
    const { selectedTimeSlot } = this.state;

    switch (actionType) {
      case ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP:
      case ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SCHEDULE:
        const updatedGroups = payload.addedActivityGroups
          ? _.filter(payload.addedActivityGroups, (group) => group.isAssignedTo)
          : [];

        const serviceDateTimes = _.map(selectedTimeSlot.serviceDateTimes, (session) => {
          const activityGroups = _.map(session.activityGroups, (group) => {
            const isAssignedTo = _.some(
              updatedGroups,
              (preselectedGroup) => preselectedGroup.serviceActivityGroupId === group.serviceActivityGroupId,
            );
            return { ...group, isAssignedTo };
          });

          return { ...session, activityGroups };
        });

        const editingSchedule = _.find(
          groupServiceTimeSlots,
          (schedule) => schedule.scheduleTimeSlotId === selectedTimeSlot.scheduleTimeSlotId,
        );
        if (editingSchedule) {
          editingSchedule.timeslot.activityGroups = [...payload.addedActivityGroups];
          selectedTimeSlot.timeslot.activityGroups = [...payload.addedActivityGroups];
          setGroupServiceTimeSlots(groupServiceTimeSlots);
        }

        this.setState({
          selectedTimeSlot: {
            ...selectedTimeSlot,
            serviceDateTimes,
          },
        });

        onSetSelectedTimeSlot({
          ...selectedTimeSlot,
          serviceDateTimes,
        });
        break;

      default:
        break;
    }
  };

  private _getEditSchedulePopoverContent = () => {
    return (
      <div className={`${Classes.POPOVER2_DISMISS} pv-small`}>
        <Row
          className="cursor-pointer ph-medium pv-small hover-bg-blue-lightest"
          onClick={() =>
            this.setState({
              isEditShiftTimeModalOpen: true,
            })
          }
        >
          Edit shift times
        </Row>
        <Row
          className="cursor-pointer ph-medium pv-small hover-bg-blue-lightest"
          onClick={() =>
            this.setState({
              isModalOpen: true,
            })
          }
        >
          Edit activity groups
        </Row>
      </div>
    );
  };

  private _handleSaveEditShiftTimeModal = (startTime, endTime) => {
    const { selectedTimeSlot } = this.state;
    this.props.setGroupServiceTimeSlots(
      _.map(this.props.groupServiceTimeSlots, (groupServiceTimeSlot) => {
        if (groupServiceTimeSlot.scheduleTimeSlotId === selectedTimeSlot.scheduleTimeSlotId) {
          const newGroupServiceTimeSlot = _.cloneDeep(groupServiceTimeSlot);
          _.set(newGroupServiceTimeSlot, 'timeslot.startDateTime', startTime);
          _.set(newGroupServiceTimeSlot, 'timeslot.endDateTime', endTime);
          return newGroupServiceTimeSlot;
        }

        return groupServiceTimeSlot;
      }),
    );

    const newSelectedTimeSlot = _.cloneDeep(selectedTimeSlot);
    _.set(newSelectedTimeSlot, 'timeslot.startDateTime', startTime);
    _.set(newSelectedTimeSlot, 'timeslot.endDateTime', endTime);
    this.setState({ selectedTimeSlot: newSelectedTimeSlot });
  };

  private _getSelectedTimeSlot() {
    const { selectedTimeSlot } = this.state;
    const { timeslot } = selectedTimeSlot || {};
    const newTimeSlot = timeslot || {};

    const newSelectedTimeSlot = {
      ...newTimeSlot,
      upcomingSession: selectedTimeSlot?.serviceDateTimes?.length,
      activityGroups: timeslot?.activityGroups
        ? _.map(timeslot.activityGroups, (group) => {
            return { ...group, serviceDateTimeActivityGroupId: group.serviceActivityGroupId };
          })
        : [],
    };

    return newSelectedTimeSlot;
  }

  componentDidMount = async () => {
    this.props.setGroupServiceTimeSlots([]);
    this.props.setGroupServiceActivityGroups([]);
    this.setState({ isLoading: true });
    await this.props.doFetchGroupServiceTimeSlots({ serviceId: this.props.selectedGroupService.serviceId });
    await this.props.doFetchGroupServiceActivityGroups({ serviceId: this.props.selectedGroupService.serviceId });
    this.setState({ isLoading: false });
  };

  private _handleRedirectToHelpCenter = () => {
    window.open(
      'https://goodhuman.zendesk.com/hc/en-au/articles/4403969405849-Assigning-team-members-to-multiple-group-sessions#step-2-select-schedule-0-10',
      '_blank',
    );
  };

  render() {
    const { selectedGroupService, groupServiceTimeSlots } = this.props;
    const { isLoading, isNotScheduleSelectedError, selectedTimeSlot } = this.state;

    if (!groupServiceTimeSlots) return null;

    const { timezone } = selectedGroupService;
    const groupedSlots = this._groupTimeSlots();

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Select schedule to add team members into</Title>
              <Paragraph>
                Select which schedule you want to add team members into. You will be able to select multiple team
                members to add to this schedule in the next step, allowing you to quickly roster your upcoming schedules
              </Paragraph>
              <Paragraph>
                Visit the <HyperlinkButton onClick={this._handleRedirectToHelpCenter}>Help Center</HyperlinkButton> to
                learn more.
              </Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white rounded-big ph-large pt-large pb-x2-large" style={{ minWidth: '250px' }}>
              <Title level={4}>Assign to schedule</Title>
              <Paragraph className="mt-large">
                Select the <b>schedule</b> that you’d like to assign the team members to.{' '}
              </Paragraph>
              {isNotScheduleSelectedError && <Text color={'red-dark'}>Please select at least one schedule.</Text>}
              <div className={!isNotScheduleSelectedError && 'mt-large'}>
                {isLoading ? (
                  <SpinningLoader size={150} message={'Fetching schedules...'} />
                ) : (
                  _.map(groupedSlots, (slots) => {
                    return (
                      <div className={'mb-large'}>
                        <div className={'p-medium bg-tertiary mb-x-small'}>
                          <Text weight={'bold'}>
                            {selectedGroupService.serviceName} - {slots[0].scheduleName} (
                            {moment.tz(slots[0].scheduleStartDate, timezone).format('DD MMMM YYYY')} -{' '}
                            {moment.tz(slots[0].scheduleEndDate, timezone).format('DD MMMM YYYY')})
                          </Text>
                        </div>
                        {_.map(slots, (slot, index) => {
                          const isSelected =
                            selectedTimeSlot && slot.scheduleTimeSlotId === selectedTimeSlot.scheduleTimeSlotId;
                          const { timeslot } = _.cloneDeep(slot);
                          const displayTimeSlot = isSelected ? selectedTimeSlot.timeslot : timeslot;
                          const assignedActivityGroups = _.filter(
                            displayTimeSlot?.activityGroups,
                            (group: any) => group.isAssignedTo,
                          );

                          if (isSelected) {
                            _.set(timeslot, 'startDateTime', selectedTimeSlot.timeslot.startDateTime);
                            _.set(timeslot, 'endDateTime', selectedTimeSlot.timeslot.endDateTime);
                          }

                          return (
                            <div
                              className={`p-medium bordered bordered-standard-grey ${isSelected && 'bg-blue-lightest'}`}
                            >
                              <Row key={index} type="flex" align="middle">
                                <Col span={1} className="align-self-start">
                                  <Checkbox
                                    checked={isSelected}
                                    className="mr-medium"
                                    disabled={!isSelected && selectedTimeSlot !== null}
                                    onChange={() => this._selectTimeSlot(slot)}
                                  />
                                </Col>
                                <Col span={19}>
                                  <Text>
                                    {CommonUtils.getRecurringPatternLabel(timeslot.recurringPattern)} -{' '}
                                    {moment.tz(timeslot.startDateTime, timezone).format('dddd')},{' '}
                                    {moment.tz(timeslot.startDateTime, timezone).format('hh:mm A')} to{' '}
                                    {moment.tz(timeslot.endDateTime, timezone).format('hh:mm A')}
                                  </Text>
                                  <br />
                                  <Text size={'regular'}>{timeslot.description ? timeslot.description : '-'}</Text>
                                  <br />
                                  <div>
                                    <Col span={6}>
                                      <Text size={'regular'} color={'secondary'}>
                                        Upcoming sessions
                                      </Text>
                                    </Col>
                                    <Col span={18}>
                                      <Text weight={'bold'}>{slot.serviceDateTimes.length} </Text>
                                      <Text size={'regular'}>sessions</Text>
                                    </Col>
                                  </div>
                                  <div>
                                    <Col span={6}>
                                      <Text size={'regular'} color={'secondary'}>
                                        Activity groups
                                      </Text>
                                    </Col>
                                    <Col span={18}>
                                      {timeslot?.activityGroups?.length !== 0 ? (
                                        <>
                                          <Text weight={'bold'}>{assignedActivityGroups.length || 0} </Text>
                                          <Text size={'regular'} className="mv-none mr-small">
                                            of {timeslot?.activityGroups?.length || 0} {'available groups'}
                                          </Text>
                                          <Popover
                                            content={this._getActivityGroupPopoverContent(assignedActivityGroups)}
                                            position={'bottom-right'}
                                            interactionKind="hover"
                                          >
                                            <Icon type="info-circle" />
                                          </Popover>
                                        </>
                                      ) : (
                                        <Text size="regular" color={'tertiary'}>
                                          No activity groups in schedule
                                        </Text>
                                      )}
                                    </Col>
                                  </div>
                                </Col>
                                <Col span={4} className="text-align-right">
                                  {isSelected && (
                                    <Popover2 position="bottom-right" content={this._getEditSchedulePopoverContent()}>
                                      <Text className="cursor-pointer p-small text-color-blue-action">
                                        Edit schedule
                                      </Text>
                                    </Popover2>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                )}
              </div>
              {!_.isEmpty(selectedTimeSlot) && (
                <EditShiftTimeOfAllSessionsModal
                  isOpen={this.state.isEditShiftTimeModalOpen}
                  onSave={this._handleSaveEditShiftTimeModal}
                  onCancel={() => this.setState({ isEditShiftTimeModalOpen: false })}
                  defaultStartTime={_.get(selectedTimeSlot, 'timeslot.startDateTime')}
                  defaultEndTime={_.get(selectedTimeSlot, 'timeslot.endDateTime')}
                  upcomingSession={_.get(selectedTimeSlot, 'serviceDateTimes.length')}
                  recurringPattern={_.get(selectedTimeSlot, 'timeslot.recurringPattern')}
                  timezone={timezone}
                />
              )}
              {this.state.isModalOpen && (
                <ActivityGroupsModal
                  serviceId={this.props.selectedGroupService.serviceId}
                  activityGroup={null}
                  modalType={this.state.currentModalType}
                  member={null}
                  memberType={this.state.currentMemberType}
                  isOpen={this.state.isModalOpen}
                  selectedUser={null}
                  handleAction={this._handleModalAction}
                  onCloseViewModal={() => {
                    this.setState({ isModalOpen: false });
                  }}
                  ignoredAlert={true}
                  timeZone={timezone}
                  session={this.state.selectedTimeSlot}
                  timeSlot={this._getSelectedTimeSlot()}
                />
              )}
            </div>

            <ActionModalFooter align="right" className="mt-small mb-x2-large pr-large">
              <SecondaryButton size="large" className="mr-medium" onClick={this.props.onPreviousStep}>
                Back
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._goToNext}>
                Next
              </PrimaryButton>
            </ActionModalFooter>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceTimeSlots: state.groupServiceStore.groupServiceTimeSlots,
  activityGroups: state.groupServiceStore.groupServiceActivityGroups,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceTimeSlots: dispatch.groupServiceStore.doFetchGroupServiceTimeSlots,
  doFetchGroupServiceActivityGroups: dispatch.groupServiceStore.doFetchGroupServiceActivityGroups,
  setGroupServiceTimeSlots: dispatch.groupServiceStore.setGroupServiceTimeSlots,
  setGroupServiceActivityGroups: dispatch.groupServiceStore.setGroupServiceActivityGroups,
});

export default connect(mapState, mapDispatch)(SelectScheduleStepPanel);
