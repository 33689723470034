import React from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Breadcrumbs, Space } from '@good/ui/core';
import { reactTrpc } from 'utilities/react-trpc';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import { PayPeriodForm } from './payPeriodForm';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Loader2Adjustable } from '@good/icons';

export const PayPeriodConfigurationView = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'accountManagement.payPeriodPage' });
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  const navigate = useNavigate();

  const { data: payPeriodData, isLoading } = reactTrpc.serviceProvider.payPeriod.fetch.useQuery({
    serviceProviderId: portalUser?.serviceProviderId ?? '',
  });


  const items = [
    { title: 'Account management', href: '/account/landing' },
    { title: 'Feature configuration', href: '/account/features' },
    { title: 'Finance file export', href: '/account/features/finance-file-export-configuration' },
    { title: `${t('heading')}` },
  ].map((item, index) => (
    <Anchor
      href={item.href}
      key={index}
      onClick={(e) => {
        e.preventDefault();
        navigate(item.href ?? '');
      }}
    >
      {item.title}
    </Anchor>
  ));

  return (
    <div className='flex-column row-gap-x-large'>
      <div className='flex-column pl-x-large pr-x-large pt-small pb-large relative flex' style={{ gap: 24 }}>
        <Breadcrumbs separator='>'>{items}</Breadcrumbs>
        <Space h='md' />
        <div>
          {payPeriodData ? (
            <PayPeriodForm initialValues={payPeriodData} isLoading={isLoading} />
          ) : (
            <div className='flex flex-col content-center align-center '>
              <Loader2Adjustable className='animate-spin' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
