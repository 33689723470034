import { Checkbox, Input, Col, Row } from 'antd';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import { PrimaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IGroupServiceActivityGroup } from 'src/interfaces/service-interfaces';
import { timeZone } from 'src/interfaces/timezone-type';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ActivityGroupModalType } from 'utilities/enum-utils';
import styled from 'styled-components';
import { Stack } from '../../../common-components/stack';

const { Search } = Input;

const StyledCheckboxGroup = styled(CheckboxGroup)({
  '> div': {
    borderBottom: '1px solid #eeeeee',
  },
  'div:last-child': {
    borderBottom: 'none',
  },
});

interface ISelectActivityGroupProps {
  serviceId: string;
  modalType: ActivityGroupModalType;
  onInteractWithAddedItems: (addedMember: IGroupServiceActivityGroup[]) => void;
  groupServiceActivityGroups: typeof state.groupServiceStore.groupServiceActivityGroups;
  isRenderSessionCard?: boolean;
  selectedActivityGroups?: IGroupServiceActivityGroup[];
  groupSessionActivityGroups?: IGroupServiceActivityGroup[];
  doFetchGroupServiceActivityGroups: typeof dispatch.groupServiceStore.doFetchGroupServiceActivityGroups;
  selectingCustomer?: any;
  timezone?: timeZone;
}

interface ISelectActivityGroupState {
  selectedActivityGroups: IGroupServiceActivityGroup[];
  filteredActivityGroups: IGroupServiceActivityGroup[];
  isSearching: boolean;
  searchValue: string;
  currentSelectingItems: any[];
}

class SelectActivityGroup extends Component<ISelectActivityGroupProps, ISelectActivityGroupState> {
  state = {
    isSearching: false,
    searchValue: '',
    selectedActivityGroups: this.props.selectedActivityGroups ? this.props.selectedActivityGroups : [],
    filteredActivityGroups: [],
    currentSelectingItems: [],
  };
  searchRef: React.RefObject<HTMLDivElement> = React.createRef();

  private _searchText = async (searchValue) => {
    const { groupServiceActivityGroups, groupSessionActivityGroups, isRenderSessionCard } = this.props;
    let activityGroupKeys;
    let selectedActivityGroupKeys;
    let activityGroupNotSelected;
    let filteredItems;
    if (isRenderSessionCard) {
      //Filter for session activity group
      activityGroupKeys = _.map(groupSessionActivityGroups, (group) => group.serviceDateTimeActivityGroupId);
      selectedActivityGroupKeys = _.map(
        this.state.selectedActivityGroups,
        (group) => group.serviceDateTimeActivityGroupId,
      );
      activityGroupNotSelected = _.filter(
        activityGroupKeys,
        (groupKey) => !selectedActivityGroupKeys.includes(groupKey),
      );
      filteredItems = _.filter(groupSessionActivityGroups, (group) =>
        activityGroupNotSelected.includes(group.serviceDateTimeActivityGroupId),
      );
      if (!_.isEmpty(searchValue)) {
        filteredItems = _.filter(
          groupSessionActivityGroups,
          (group) =>
            group.name.toLowerCase().includes(searchValue.toLowerCase()) &&
            activityGroupNotSelected.includes(group.serviceDateTimeActivityGroupId),
        );
      }
    } else {
      //Filter for service activity group
      activityGroupKeys = _.map(groupServiceActivityGroups, (group) => group.serviceActivityGroupId);
      selectedActivityGroupKeys = _.map(this.state.selectedActivityGroups, (group) => group.serviceActivityGroupId);
      activityGroupNotSelected = _.filter(
        activityGroupKeys,
        (groupKey) => !selectedActivityGroupKeys.includes(groupKey),
      );
      filteredItems = _.filter(groupServiceActivityGroups, (group) =>
        activityGroupNotSelected.includes(group.serviceActivityGroupId),
      );
      if (!_.isEmpty(searchValue)) {
        filteredItems = _.filter(
          groupServiceActivityGroups,
          (group) =>
            group.name.toLowerCase().includes(searchValue.toLowerCase()) &&
            activityGroupNotSelected.includes(group.serviceActivityGroupId),
        );
      }
    }
    this.setState({ filteredActivityGroups: filteredItems });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _handleOnSearchChange = (e) => {
    this.setState({ searchValue: e.target.value, isSearching: true });
    if (e.target.value.length >= 0) {
      this.setState({ isSearching: true });
      this._debounceSearch(e.target.value);
    }
  };

  private _handleAddCheckedItems = () => {
    const { currentSelectingItems, selectedActivityGroups } = this.state;
    const { onInteractWithAddedItems } = this.props;
    const newAddedArray = selectedActivityGroups.concat(currentSelectingItems);
    this.setState({ selectedActivityGroups: newAddedArray });
    this.setState({ isSearching: false, searchValue: '', currentSelectingItems: [] });
    onInteractWithAddedItems(newAddedArray);
  };

  private _handleRemoveSelectedItem = (selectedGroupKey: string) => {
    const { selectedActivityGroups } = this.state;
    const { isRenderSessionCard, onInteractWithAddedItems } = this.props;
    const filteredActivityGroups = _.filter(selectedActivityGroups, (group) =>
      isRenderSessionCard
        ? group.serviceDateTimeActivityGroupId !== selectedGroupKey
        : group.serviceActivityGroupId !== selectedGroupKey,
    );
    this.setState({ selectedActivityGroups: filteredActivityGroups });
    onInteractWithAddedItems(filteredActivityGroups);
  };

  private _handleCheckIsCustomerInServiceActivityGroup = async () => {
    const { groupServiceActivityGroups, groupSessionActivityGroups, selectingCustomer, isRenderSessionCard } =
      this.props;
    if (!isRenderSessionCard)
      if (_.isEmpty(groupServiceActivityGroups) || _.isEmpty(groupSessionActivityGroups)) return;
    if (!selectingCustomer) return;

    const serviceActivityGroupIds = _.map(groupServiceActivityGroups, (group) => group.serviceActivityGroupId);
    const activityGroupThatCustomerAreIn = _.filter(
      groupServiceActivityGroups,
      (serviceActivityGroup) =>
        _.includes(serviceActivityGroupIds, serviceActivityGroup.serviceActivityGroupId) &&
        _.includes(
          _.map(serviceActivityGroup.customers, (customer) => customer.userId),
          selectingCustomer.userId,
        ),
    );

    const filteredServiceActivityGroupIds = _.map(
      activityGroupThatCustomerAreIn,
      (group) => group.serviceActivityGroupId,
    );
    const filteredSessionActivityGroup = _.filter(groupSessionActivityGroups, (sessionActivityGroup) =>
      _.includes(filteredServiceActivityGroupIds, sessionActivityGroup.serviceActivityGroupId),
    );

    if (!_.isEmpty(filteredSessionActivityGroup)) {
      this.setState({ selectedActivityGroups: filteredSessionActivityGroup });
      this.props.onInteractWithAddedItems(filteredSessionActivityGroup);
    }
  };

  private _renderSelectedCard = (groupServiceActivityGroups: IGroupServiceActivityGroup[]) => {
    const { timezone } = this.props;
    return (
      !_.isEmpty(groupServiceActivityGroups) && (
        <div className="mt-12">
          {_.map(groupServiceActivityGroups, (group) => {
            return (
              <div
                key={
                  this.props.isRenderSessionCard ? group.serviceDateTimeActivityGroupId : group.serviceActivityGroupId
                }
              >
                {this.props.isRenderSessionCard ? (
                  <div
                    className="mb-small bg-white rounded-big ph-medium pv-medium bordered"
                    style={{
                      minHeight: '122px',
                    }}
                  >
                    <div className="block">
                      <Row className="mb-x-small">
                        <Col span={16}>
                          <Text weight="bold" size="large" className="mb-small">
                            {group.name}
                          </Text>
                        </Col>
                        <Col span={8} className="text-align-right">
                          <Text
                            color="red"
                            className="cursor-pointer p-small"
                            onClick={() =>
                              this._handleRemoveSelectedItem(
                                this.props.isRenderSessionCard
                                  ? group.serviceDateTimeActivityGroupId
                                  : group.serviceActivityGroupId,
                              )
                            }
                          >
                            Remove
                          </Text>
                        </Col>
                      </Row>
                      {group.startDateTime && group.endDateTime && (
                        <Row className="mb-x-small">
                          <Col span={5}>
                            <Text color="secondary">When</Text>
                          </Col>
                          <Col span={19}>
                            <Text weight="400">
                              {moment.tz(group.startDateTime, timezone).format('ddd, DD MMMM')} <br />{' '}
                              {moment.tz(group.startDateTime, timezone).format('hh:mm A')} –{' '}
                              {moment.tz(group.endDateTime, timezone).format('hh:mm A')}
                            </Text>
                          </Col>
                        </Row>
                      )}
                      <Row className="mb-x-small">
                        <Col span={5}>
                          <Text color="secondary">Description</Text>
                        </Col>
                        <Col span={19}>
                          <Text color="secondary">{group.description}</Text>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : (
                  <div className="flex-row justify-between mb-small bg-white rounded-big ph-medium pv-medium bordered">
                    <div className="block">
                      <div className="block">
                        {' '}
                        <Text weight="bold" size="large" className="mb-small">
                          {group.name}
                        </Text>
                        <Paragraph>{group.description}</Paragraph>
                      </div>
                    </div>
                    <div>
                      <Text
                        color="red"
                        className="cursor-pointer p-small"
                        onClick={() =>
                          this._handleRemoveSelectedItem(
                            this.props.isRenderSessionCard
                              ? group.serviceDateTimeActivityGroupId
                              : group.serviceActivityGroupId,
                          )
                        }
                      >
                        Remove
                      </Text>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )
    );
  };

  private _onMouseDown = (event) => {
    const { searchValue } = this.state;
    // handle mouse outside control
    if (this.searchRef.current && !this.searchRef.current.contains(event.target)) {
      this.setState({ isSearching: false, searchValue: null });
    }

    // handle mouse inside search input
    if (
      this.searchRef.current &&
      this.searchRef.current.contains(event.target) &&
      event.target.type === 'text' &&
      !searchValue
    ) {
      this.setState({ isSearching: true, searchValue: '' });
      this._searchText('');
    }
  };

  componentDidMount(): void {
    const { serviceId, doFetchGroupServiceActivityGroups } = this.props;
    doFetchGroupServiceActivityGroups({ serviceId });
    this._handleCheckIsCustomerInServiceActivityGroup();
    document.addEventListener('mousedown', this._onMouseDown);
  }

  componentDidUpdate(prevProps: Readonly<ISelectActivityGroupProps>): void {
    if (prevProps.groupServiceActivityGroups != this.props.groupServiceActivityGroups) {
      this._handleCheckIsCustomerInServiceActivityGroup();
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this._onMouseDown);
  }

  render() {
    const { modalType, timezone } = this.props;
    const { searchValue, isSearching, selectedActivityGroups, filteredActivityGroups, currentSelectingItems } =
      this.state;

    return (
      <Stack gap="sm">
        <div className="mb-medium">
          <SubTitle>
            {modalType === ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP
              ? 'Assign customer to activity group'
              : 'Assign team member to activity group'}
          </SubTitle>
        </div>

        <div className="bg-quaternary rounded p-medium">
          <div className="flex justify-around p-5" ref={this.searchRef}>
            <div className="width-full relative">
              <Search
                placeholder="Search for activity group"
                onChange={this._handleOnSearchChange}
                loading={false}
                value={searchValue}
                allowClear
              />
              {isSearching && (
                <div
                  className="absolute bg-white width-full p-medium bordered rounded overflow-y-scroll"
                  style={{ zIndex: 5 }}
                >
                  {!_.isEmpty(filteredActivityGroups) ? (
                    <StyledCheckboxGroup
                      className="width-full"
                      onChange={(value) => {
                        this.setState({ currentSelectingItems: value });
                      }}
                      value={currentSelectingItems}
                    >
                      {_.map(filteredActivityGroups, (group) => (
                        <div className="p-small bordered-bottom width-full flex-row" key={group.serviceActivityGroupId}>
                          <Checkbox value={group} />
                          <div className="block ml-12">
                            <Text weight="bold" size="large">
                              {group.name}
                            </Text>
                            <Stack gap="sm">
                              {group.startDateTime && group.endDateTime && (
                                <>
                                  <Text>
                                    {moment.tz(group.startDateTime, timezone).format('ddd, D MMM')} -{' '}
                                    {moment.tz(group.endDateTime, timezone).format('ddd, D MMM YYYY')} •{' '}
                                    {moment.tz(group.startDateTime, timezone).format('hh:mm A')} -{' '}
                                    {moment.tz(group.endDateTime, timezone).format('hh:mm A')}
                                  </Text>
                                  <br />
                                </>
                              )}
                              {group.description && (
                                <Text className="whitespace-pre-line" color="secondary">
                                  {group.description}
                                </Text>
                              )}
                            </Stack>
                          </div>
                        </div>
                      ))}
                    </StyledCheckboxGroup>
                  ) : (
                    'No result found...'
                  )}
                </div>
              )}
            </div>
            <PrimaryButton size="medium" className="ml-12" onClick={this._handleAddCheckedItems}>
              Add
            </PrimaryButton>
          </div>

          <div className="mt-small">
            {!_.isEmpty(selectedActivityGroups) ? (
              <>
                <Text color="secondary" size="large">
                  Activity Group Added ({_.get(selectedActivityGroups, 'length', 0)})
                </Text>
                <div>{this._renderSelectedCard(selectedActivityGroups)}</div>
              </>
            ) : (
              'No activity group added yet.'
            )}
          </div>
        </div>
      </Stack>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceActivityGroups: state.groupServiceStore.groupServiceActivityGroups,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceActivityGroups: dispatch.groupServiceStore.doFetchGroupServiceActivityGroups,
});

export default connect(mapState, mapDispatch)(SelectActivityGroup);
