import { maxBy } from 'lodash';
import { UserPermissionRole } from 'utilities/enum-utils';
import {
  CustomerPermissionTypeNumber,
  FormBuilderPermissionTypeNumber,
  INVITE_MEMBER_ROLE_TYPE,
  PaymentPermissionTypeNumber,
  PlanManagementPermissionTypeNumber,
  ROLE_PERMISSION_LIST,
  ServiceDepartmentPermissionTypeNumber,
  TeamPermissionTypeNumber,
  WorkflowPermissionTypeNumber,
} from './constants';

const getPermission = (
  permissions,
  key: string,
  objectData: { [key: string]: { level: number; permission: number } },
): number => {
  const newPermissions: { level: number; permission: number }[] = permissions.map((item) =>
    item[key] && objectData[item[key]] ? objectData[item[key]] : { level: -1, permission: null },
  );
  return maxBy(newPermissions, 'level').permission;
};

export const getListPermissionId = (roleSelected: string[]) => {
  if (roleSelected.includes(INVITE_MEMBER_ROLE_TYPE.NO_ACCESS)) {
    return [
      UserPermissionRole.CustomerAccessViewOnly,
      UserPermissionRole.TeamViewOnly,
      UserPermissionRole.WorkflowBasic,
    ];
  }
  if (roleSelected.includes(INVITE_MEMBER_ROLE_TYPE.OWNER)) {
    return [UserPermissionRole.Owner];
  }
  const permissions = roleSelected.map((role) => ROLE_PERMISSION_LIST.find((item) => item.role === role).subRole);

  return [
    getPermission(permissions, 'customer', CustomerPermissionTypeNumber),
    getPermission(permissions, 'payments', PaymentPermissionTypeNumber),
    getPermission(permissions, 'team', TeamPermissionTypeNumber),
    getPermission(permissions, 'formBuilder', FormBuilderPermissionTypeNumber),
    getPermission(permissions, 'workflow', WorkflowPermissionTypeNumber),
    getPermission(permissions, 'planManagement', PlanManagementPermissionTypeNumber),
    getPermission(permissions, 'serviceDepartments', ServiceDepartmentPermissionTypeNumber),
  ].filter(Number);
};
