import React, { Component } from 'react';
import { FieldLabel, Text } from 'common-components/typography';
import { YearAndMonthFilter } from 'common-components/filter';
import moment from 'moment-timezone';
import { IYearAndMonthFilter } from 'interfaces/filter-interfaces';
import { Checkbox, Col, Empty } from 'antd';
import { GridHeader, GridRow } from 'common-components/grids';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import { IGroupServiceSession } from 'interfaces/service-interfaces';
import SpinningLoader from 'common-components/loading/SpinningLoader';

interface IAssignTeamMemberSessionListPanelProps {
  sessions: IGroupServiceSession[];
  onSelect: (session) => void;
  timezone: timeZone;
  onChangeFilter?: (filter) => void;
  isLoading?: boolean;
}

interface IAssignTeamMemberSessionListPanelState {
  filter: IYearAndMonthFilter;
  sessions: IGroupServiceSession[];
}

class AssignTeamMemberSessionListPanel extends Component<
  IAssignTeamMemberSessionListPanelProps,
  IAssignTeamMemberSessionListPanelState
> {
  state = {
    filter: this._getFilterFromSessionsList(),
    sessions: [],
  };

  private _getFilterFromSessionsList() {
    let startDateTime = this.props.sessions[0] && this.props.sessions[0].startDateTime;
    if (this.state && this.state.filter && !_.isEmpty(this.state.sessions)) {
      const filteredSessions = this._getFilteredSessions(this.state.filter, this.state.sessions);
      if (!_.isEmpty(filteredSessions)) {
        startDateTime = filteredSessions[0] && filteredSessions[0].startDateTime;
      }
    }

    const startDateTimeMoment = moment(startDateTime);

    return {
      year: startDateTimeMoment.year(),
      month: startDateTimeMoment.month(), // moment.month() is zero based it will return 0-11
    };
  }

  private _getFilteredSessions(filter, sessions) {
    return _.filter(
      sessions,
      (session) =>
        moment(session.startDateTime).year() === filter.year && moment(session.startDateTime).month() === filter.month,
    );
  }

  private _updateFilter = (filter) => {
    this.setState({ filter });
    if (this.props.onChangeFilter) {
      this.props.onChangeFilter(filter);
    }
  };

  componentDidUpdate = async (prevPops) => {
    if (this.props.sessions !== prevPops.sessions) {
      this.setState({
        filter: this._getFilterFromSessionsList(),
        sessions: _.sortBy(this.props.sessions, 'startDateTime'),
      });
    }
  };

  render = () => {
    const { timezone, onSelect = null, isLoading = false } = this.props;
    const { filter, sessions } = this.state;

    const SessionEmptyState = () => (
      <div className="flex-1 bg-white mt-x2-large align-center flex-column">
        <div className="">
          <Empty
            description={
              <Text size="x2-large" color="secondary" weight="bold">
                No session found for the selected month.
              </Text>
            }
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="mv-none"
          />
        </div>
      </div>
    );

    const filteredSessionList = this._getFilteredSessions(filter, sessions);

    return (
      <div>
        {sessions && !isLoading && (
          <YearAndMonthFilter
            filter={filter}
            startDateTime={moment(sessions[0] && sessions[0].startDateTime)}
            endDateTime={sessions.length > 0 && _.last(sessions) ? moment(_.last(sessions).endDateTime) : moment()}
            updateFilter={this._updateFilter}
          />
        )}
        <GridHeader bordered containerClassName="border-width-medium border-secondary">
          <Col span={2} className=" bg-white">
            <FieldLabel text="" />
          </Col>
          <Col span={6} className=" bg-white">
            <FieldLabel text="Session date" />
          </Col>
          <Col span={6} className=" bg-white">
            <FieldLabel text="Roster Capacity" />
          </Col>
          <Col span={10} className=" bg-white">
            <FieldLabel text="Schedule" />
          </Col>
        </GridHeader>
        {isLoading ? (
          <SpinningLoader size={100} message={'Generating the sessions...'} />
        ) : filteredSessionList.length === 0 ? (
          <SessionEmptyState />
        ) : (
          _.map(filteredSessionList, (session, index) => {
            const startDateTime = moment.tz(session.startDateTime, timezone);
            const endDateTime = moment.tz(session.endDateTime, timezone);

            const dateLineText = !startDateTime.isSame(endDateTime, 'day')
              ? !startDateTime.isSame(endDateTime, 'month')
                ? !startDateTime.isSame(endDateTime, 'year')
                  ? startDateTime.format("ddd D MMM 'YY") + ' - ' + endDateTime.format("ddd D MMM 'YY")
                  : startDateTime.format('ddd D MMM') + ' - ' + endDateTime.format("ddd D MMM 'YY")
                : startDateTime.format('ddd D') + ' - ' + endDateTime.format("ddd D MMM 'YY")
              : startDateTime.format("ddd D MMM 'YY");

            return (
              <GridRow onClick={() => onSelect(session)} key={index}>
                <Col span={2}>
                  <Checkbox checked={session.isSelected} />
                </Col>
                <Col span={6}>
                  <div>
                    <Text style={{ whiteSpace: 'nowrap' }} weight="bold" size="regular">
                      {dateLineText}
                    </Text>
                    <br />
                    <div className="flex-row">
                      <Text size="large" color={'secondary'} style={{ whiteSpace: 'nowrap' }}>
                        {startDateTime.format('h:mmA') + ' – ' + endDateTime.format('h:mmA')}
                      </Text>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  {session.totalAssignedShiftSlot && session.totalShiftSlot
                    ? session.totalAssignedShiftSlot + '/' + session.totalShiftSlot
                    : '-'}
                </Col>
                <Col span={10}>{session.scheduleName ? session.scheduleName : '-'}</Col>
              </GridRow>
            );
          })
        )}
      </div>
    );
  };
}

export default AssignTeamMemberSessionListPanel;
