import React, { PropsWithChildren, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, Heading, Inline, Split, Stack, Text } from '@goodhuman-me/components';
import { Trans, useTranslation } from 'react-i18next';
import { lowerCase } from 'lodash';

import type { Budget, BudgetCategory, SupportCategory } from '../../../../../budget';
import type { HeadingProps, TextProps } from '@goodhuman-me/components';

import {
  paceCalendarSVG,
  paceCriticalSVG,
  paceIdealSVG,
  paceNoticeSVG,
  pacePlannedSVG,
  pacePositiveSVG,
} from '../../../../../../../assets/Icons/budgeting';

const paceTrackingInfo = {
  ON_TRACK: {
    statusColor: '$positive',
    statusIcon: pacePositiveSVG,
  },
  SLIGHTLY_AHEAD: {
    statusColor: '$notice',
    statusIcon: paceNoticeSVG,
  },
  SIGNIFICANTLY_AHEAD: {
    statusColor: '$critical',
    statusIcon: paceCriticalSVG,
  },
  SLIGHTLY_BEHIND: {
    statusColor: '$notice',
    statusIcon: paceNoticeSVG,
  },
  SIGNIFICANTLY_BEHIND: {
    statusColor: '$critical',
    statusIcon: paceCriticalSVG,
  },
  OVERBUDGET: {
    statusIcon: paceCriticalSVG,
    statusColor: '$critical',
  },
};

const formatPaceValue = (paceValue: number) => {
  const hours = paceValue > 0 ? Math.floor(paceValue) : Math.ceil(paceValue);
  const minutes = +Math.abs((paceValue % 1) * 60).toFixed(0);

  return {
    hours,
    minutes,
  };
};

const formatPaceDescription = (hours: number, minutes: number, cadence: string) => {
  if (minutes > 0) {
    return `${hours}h ${minutes}m / ${cadence}`;
  }
  return `${hours}h / ${cadence}`;
};

const availableCadencesInRemainingServicePeriod = (serviceEndDate: Date) => {
  const weekInDays = 7;
  const monthInDays = 365.25 / 12;
  const quarterInDays = 365.23 / 4;
  const currentDate = new Date().toISOString();
  const daysTilEndOfAgreement = moment(serviceEndDate).diff(currentDate, 'days');
  const cadenceOptions: [{ value: string; label: string }] = [
    {
      value: 'DAY',
      label: 'Daily',
    },
  ];

  if (daysTilEndOfAgreement > monthInDays) {
    cadenceOptions.push({
      value: 'WEEK',
      label: 'Weekly',
    });
  }

  if (daysTilEndOfAgreement > weekInDays) {
    cadenceOptions.push({
      value: 'MONTH',
      label: 'Monthly',
    });
  }

  if (daysTilEndOfAgreement > quarterInDays) {
    cadenceOptions.push({
      value: 'QUARTER',
      label: 'Quarterly',
    });
  }

  return cadenceOptions;
};

const calculateDefaultCadence = (cadenceValues: string[]) => {
  const weeklyCadence = cadenceValues.find((value) => value === 'WEEK');

  if (weeklyCadence) {
    return weeklyCadence;
  }

  return 'DAY';
};

export type PaceProps = Pick<Budget['overview'], 'service'> &
  Pick<BudgetCategory, 'status'> &
  Pick<SupportCategory, 'supportCoordinationTracking'>;

const _Pace = (props: PaceProps): JSX.Element => {
  const { service, status, supportCoordinationTracking } = props;
  const { pace } = supportCoordinationTracking;
  const { t } = useTranslation('', { keyPrefix: 'budget.pace' });
  const cadenceOptions = useMemo(() => availableCadencesInRemainingServicePeriod(service.endDate), [service.endDate]);
  const defaultCadence = useMemo(
    () => calculateDefaultCadence(cadenceOptions.map((option) => option.value)),
    [cadenceOptions],
  );
  const [selectedCadence, setCadence] = useState(defaultCadence);
  const [paceValues, setPaceValues] = useState(pace[selectedCadence]);

  const selectedCadenceLabel = lowerCase(selectedCadence);
  const cadenceAdverb = t(`cadenceAdverbs.${selectedCadence}`);
  const trackingHeader = t(`trackingHeader.${status}`);
  const trackingColor = paceTrackingInfo[status].statusColor;
  const trackingIcon = paceTrackingInfo[status].statusIcon;
  const idealPaceDifference = Math.abs(paceValues.ideal - paceValues.actual);

  const handleCadenceSelect = (selectedCadence: string) => {
    setCadence(selectedCadence);
    setPaceValues(pace[selectedCadence]);
  };

  return (
    <Inline
      marginTop="$space600"
      alignItems="center"
      justifyContent="center"
      width="100%"
      borderColor="$bodyLight1"
      borderRadius="$medium"
      borderStyle="solid"
      borderWidth="$thick"
    >
      <Box
        height="100%"
        width="100%"
        padding="$space150"
        borderLeftColor={trackingColor}
        borderLeftStyle="solid"
        borderLeftWidth="calc($thickest - $thick)"
        borderRadius="calc($medium - 2px)"
      >
        <Split fraction="one-third" padding="$small" width="100%" collapseAt="1024px" gap="$medium">
          <PaceSummary
            paceValue={idealPaceDifference}
            selectedCadenceLabel={selectedCadenceLabel}
            cadenceAdverb={cadenceAdverb}
            trackingColor={trackingColor}
            trackingHeader={trackingHeader}
          />

          <Inline
            justifyContent="space-between"
            width="100%"
            alignItems={{
              initial: 'flex-start',
              large: 'center',
            }}
            collapseAt="768px"
            gap="$small"
          >
            <CadenceSelector
              selectedCadence={selectedCadence}
              cadenceOptions={cadenceOptions}
              handleCadenceSelect={handleCadenceSelect}
            />
            <PlannedPace paceValue={paceValues.planned} selectedCadenceLabel={selectedCadenceLabel} />
            <ActualPace
              paceValue={paceValues.actual}
              selectedCadenceLabel={selectedCadenceLabel}
              trackingIcon={trackingIcon}
              trackingColor={trackingColor}
            />
            <IdealPace paceValue={paceValues.ideal} selectedCadenceLabel={selectedCadenceLabel} />
          </Inline>
        </Split>
      </Box>
    </Inline>
  );
};

export { _Pace as Pace };

const PaceSummary = (props: {
  paceValue: number;
  selectedCadenceLabel: string;
  cadenceAdverb: string;
  trackingColor: Extract<HeadingProps['color'], '$positive' | '$notice' | '$critical'>;
  trackingHeader: string;
}) => {
  const { paceValue, selectedCadenceLabel, cadenceAdverb, trackingColor, trackingHeader } = props;
  const { hours, minutes } = formatPaceValue(paceValue);
  const paceText = formatPaceDescription(hours, minutes, selectedCadenceLabel);

  return (
    <Stack gap="$space50">
      <Heading size="small" color={trackingColor}>
        {trackingHeader}
      </Heading>
      <Text size="small">
        <Trans
          i18nKey="budget.pace.summary"
          values={{
            cadenceAdverb: cadenceAdverb,
            pace: paceText,
          }}
        />
      </Text>
    </Stack>
  );
};

type PaceInfoProps = PropsWithChildren<{
  label: string;
  iconPath: string;
}>;

const PaceInfo = (props: PaceInfoProps) => {
  let { children, label, iconPath } = props;

  return (
    <Inline justifyContent="center">
      <Inline alignItems="center" gap="$space150">
        <Inline alignItems="center" justifyContent="center" height="calc($size400 + $size25)">
          <img src={iconPath} height="32px" />
        </Inline>

        <Stack backgroundColor="$white">
          <Text size="xsmall" color="$muted">
            {label}
          </Text>
          {children}
        </Stack>
      </Inline>
    </Inline>
  );
};

const PlannedPace = (props: { paceValue: number; selectedCadenceLabel: string }) => {
  const { paceValue, selectedCadenceLabel } = props;
  const { hours, minutes } = formatPaceValue(paceValue);
  const paceText = formatPaceDescription(hours, minutes, selectedCadenceLabel);

  return (
    <PaceInfo label="Planned pace" iconPath={pacePlannedSVG}>
      <Text size="small">{paceText}</Text>
    </PaceInfo>
  );
};

const ActualPace = (props: {
  paceValue: number;
  selectedCadenceLabel: string;
  trackingIcon: string;
  trackingColor: Extract<TextProps['color'], '$positive' | '$notice' | '$critical'>;
}) => {
  const { paceValue, selectedCadenceLabel, trackingIcon, trackingColor } = props;
  const { hours, minutes } = formatPaceValue(paceValue);
  const paceText = formatPaceDescription(hours, minutes, selectedCadenceLabel);

  return (
    <PaceInfo label="Your pace" iconPath={trackingIcon}>
      <Text size="small" color={trackingColor}>
        {paceText}
      </Text>
    </PaceInfo>
  );
};

const IdealPace = (props: { paceValue: number; selectedCadenceLabel: string }) => {
  const { paceValue, selectedCadenceLabel } = props;
  const { hours, minutes } = formatPaceValue(paceValue);
  const paceText = formatPaceDescription(hours, minutes, selectedCadenceLabel);

  return (
    <PaceInfo label="Ideal pace" iconPath={paceIdealSVG}>
      <Text size="small">{paceText}</Text>
    </PaceInfo>
  );
};

const CadenceSelector = (props: {
  cadenceOptions: { value: string; label: string }[];
  handleCadenceSelect: (value: string) => void;
  selectedCadence: string;
}) => {
  const { cadenceOptions, selectedCadence, handleCadenceSelect } = props;

  return (
    <PaceInfo label="Cadence" iconPath={paceCalendarSVG}>
      <Text fontSize="$small">
        <Box asChild borderStyle="none" marginLeft="-3px">
          <select value={selectedCadence} onChange={(e) => handleCadenceSelect(e.target.value)}>
            {cadenceOptions.map((option) => (
              <option key={option.value} value={option.value} label={option.label} />
            ))}
          </select>
        </Box>
      </Text>
    </PaceInfo>
  );
};
