import { IAssignedCustomer, IShiftCustomerAssignment } from 'interfaces/assign-customer-interfaces';
import _ from 'lodash';
import { Avatar, Select } from 'antd';
import { HyperlinkButton, IconButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { Popover } from '@blueprintjs/core';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { TimeAssignmentRow } from 'views/group-services/session-details/team-members/modals/AssignShiftToCustomersModal/TimeAssignmentRow';
import React from 'react';

export function CustomerAssignmentCard({
  pendingId,
  assignments,
  customers,
  onSelectAssignment,
  onCancelAssignment,
  onRemoveAssignment,
  onSaveRow,
  onAddRow,
  onEditRow,
  onDeleteRow,
  onUpdateRow,
}: {
  customerId: string;
  pendingId: string;
  assignments: IShiftCustomerAssignment[];
  customers: IAssignedCustomer[];
  onSelectAssignment: any;
  onRemoveAssignment: any;
  onCancelAssignment: any;
  onSaveRow: any;
  onAddRow: any;
  onEditRow: any;
  onDeleteRow: any;
  onUpdateRow: any;
}) {
  const { customerUserId, customerFirstName, customerLastName, customerAttachmentUrl } = (_.head(assignments) ||
    {}) as IAssignedCustomer;

  const customerDisplayName = `${customerFirstName} ${customerLastName}`;

  const isAssigning = _.isEmpty(customerUserId);

  return (
    <div className="rounded-big overflow-hidden mb-medium bordered">
      <div className="bg-secondary flex-row align-center justify-between p-small bordered-bottom">
        {!!isAssigning && (
          <div className="flex-row">
            <Select
              style={{ width: '320px' }}
              onChange={(selectedId) => {
                onSelectAssignment({
                  customerUserId: selectedId,
                  pendingId,
                });
              }}
            >
              {_.map(customers, (customer) => (
                <Select.Option value={customer.customerUserId} key={customer.customerUserId}>
                  <div className="flex-row align-center">
                    <Avatar src={customer.customerAttachmentUrl} size={'small'} className={'mr-small'} />
                    {customer.customerFirstName} {customer.customerLastName}
                  </div>
                </Select.Option>
              ))}
            </Select>
            <div className="ml-small mt-x-small">
              <HyperlinkButton onClick={() => onCancelAssignment({ pendingId })}>Cancel</HyperlinkButton>
            </div>
          </div>
        )}

        {!isAssigning && (
          <div className="flex-row align-center">
            <Avatar icon="user" src={customerAttachmentUrl} shape={'circle'} className="mr-small" size={'small'} />
            <Text className="mr-small">{customerDisplayName}</Text>
          </div>
        )}
        <div>
          {!isAssigning && (
            <Popover
              content={
                <ActionMenu>
                  <ActionMenuItem
                    text={'Remove customer assignment'}
                    onClick={() => onRemoveAssignment({ customerId: customerUserId })}
                  />
                </ActionMenu>
              }
              usePortal={false}
              position={'bottom-left'}
            >
              <IconButton
                icon="ellipsis"
                size="small"
                color="white"
                bordered={true}
                iconColor="blue-action"
                onClick={() => {}}
              />
            </Popover>
          )}
        </div>
      </div>
      <div className="bg-quaternary p-small">
        {_.map(assignments, (assignment, idx) => (
          <TimeAssignmentRow
            assignment={assignment}
            key={idx}
            onSaveRow={onSaveRow}
            onEditRow={onEditRow}
            onUpdateRow={onUpdateRow}
            onDeleteRow={onDeleteRow}
          />
        ))}
        {/*<div>*/}
        {/*  <HyperlinkButton fontSize="regular">+ Add time</HyperlinkButton>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
