import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Grid3Equal = forwardRef(function Grid3Equal(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.948 2.25h10.104c.899 0 1.648 0 2.243.08.627.084 1.194.27 1.65.725.455.456.64 1.023.725 1.65.08.595.08 1.345.08 2.243v10.104c0 .899 0 1.648-.08 2.242-.084.628-.27 1.195-.725 1.65-.456.456-1.023.642-1.65.726-.595.08-1.344.08-2.243.08H6.948c-.898 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.726-.456-.455-.642-1.022-.726-1.65-.08-.594-.08-1.343-.08-2.242V6.948c0-.898 0-1.648.08-2.242.084-.628.27-1.195.726-1.65.455-.456 1.022-.642 1.65-.726.594-.08 1.344-.08 2.242-.08ZM3.75 9.75V17c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.789.3.483.064 1.13.066 2.094.066h4.25V9.75h-7.5ZM12 8.25H3.75V7c0-.964.002-1.612.067-2.095.062-.461.169-.659.3-.789.13-.13.327-.237.789-.3.483-.064 1.13-.066 2.094-.066h10c.964 0 1.612.002 2.095.067.461.062.659.169.789.3.13.13.238.327.3.788.065.483.066 1.131.066 2.095v1.25H12Zm.75 1.5v10.5H17c.964 0 1.612-.002 2.095-.067.461-.062.659-.169.789-.3.13-.13.238-.327.3-.788.065-.483.066-1.131.066-2.095V9.75h-7.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
