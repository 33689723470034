import _ from 'lodash';
import { ROSTER_COMMON_CONFIG } from 'views/bookings/roster-view/common/roster-common-config';

// Query stuff
const pageSize = ROSTER_COMMON_CONFIG.pageSize;

export function getNextPageParam(lastPage: any) {
  // Check to see if the size of data array is less than the page size
  if (lastPage && lastPage.data && _.size(lastPage.data) === pageSize) {
    // Same size; assume that there are more pages
    return lastPage.page + 1;
  } else {
    // Smaller size; no more pages
    return undefined; // return undefined to stop infinite query
  }
}

export function flattenPages(resp) {
  if (resp && resp.pages && _.size(resp.pages) > 0) {
    return _.chain(resp.pages)
      .map((page) => page.data)
      .flatten()
      .value();
  } else {
    return [];
  }
}
