import React from 'react';
import { Anchor, Avatar, Text } from '@good/ui/core';
import { ColumnDef } from '@tanstack/react-table';
import { SilCustomerData } from 'utilities/react-trpc';
import { SilPill } from '../components/sil-pill';
import { Link } from 'react-router-dom';

export const customerColumns: ColumnDef<SilCustomerData[number]>[] = [
  {
    id: 'customer',
    header: 'CUSTOMERS',
    accessorKey: 'displayName',
    sortingFn: 'alphanumeric',
    size: 200,
    cell: (ctx) => {
      const { avatarUrl, displayName } = ctx.row.original;
      return (
        <>
          <div className='flex items-center'>
            <Avatar radius='sm' className='mx-2' size={32} src={avatarUrl} />
            <Text size='sm'>{displayName}</Text>
          </div>
        </>
      );
    },
  },
  {
    id: 'status',
    header: 'STATUS',
    accessorKey: 'status',
    cell: (ctx) => {
      const { status } = ctx.row.original;

      return (
        <>
          <SilPill status={status} />
        </>
      );
    },
  },
  {
    id: 'startDate',
    header: 'START DATE',
    accessorKey: 'startDate',
    cell: (ctx) => {
      const { startDate } = ctx.row.original;

      return <>{startDate} </>;
    },
  },
  {
    id: 'tenure',
    header: 'TENURE',
    accessorKey: 'tenure',
    cell: (ctx) => {
      const { tenure, status } = ctx.row.original;

      return <>{durationStringBuilder(tenure, status)} </>;
    },
  },
  {
    id: 'profile',
    header: '',
    accessorKey: 'userId',
    cell: (ctx) => {
      const { userId } = ctx.row.original;
      return (
        <Anchor component={Link} size='sm' to={`/customer/details/${userId}`}>
          View profile
        </Anchor>
      );
    },
  },
];

const durationStringBuilder = (duration: Duration, status: string | null) => {
  if (status === 'UPCOMING' || status === 'EXPIRED') {
    return '-';
  }
  const yearString = duration.years > 0 ? `${duration.years}y` : '';

  const monthString = duration.months > 0 ? `${duration.months}m` : '';

  const dayString = duration.days > 0 ? `${duration.days}d` : '';

  return `${yearString} ${monthString} ${dayString}`;
};
