import { tv, type VariantProps } from 'tailwind-variants';

export const button = tv(
  {
    base: [
      'align-top',
      'appearance-none',
      'border-2',
      'border-solid',
      'box-border',
      'cursor-pointer',
      'data-[disabled=true]:bg-gray-light-1',
      'data-[disabled=true]:border-gray-light-1',
      'data-[disabled=true]:cursor-default',
      'data-[disabled=true]:text-strong',
      'data-[focus-visible=true]:outline',
      'data-[focus-visible=true]:outline-offset-2',
      'font-base',
      'font-bold',
      'gap-2',
      'inline-flex',
      'items-center',
      'justify-center',
      'outline-none',
      'relative',
      'rounded-md',
      'touch-none',
      'transition-all',
    ],
    variants: {
      tone: {
        accent: ['data-[focus-visible=true]:outline-accent'],
        critical: ['data-[focus-visible=true]:outline-critical'],
        neutral: ['data-[focus-visible=true]:outline-accent'],
      },
      size: {
        lg: ['h-14', 'px-6', 'text-b-md', 'leading-b-md'],
        md: ['h-11', 'px-5', 'text-b-sm', 'leading-b-md'],
        sm: ['h-8', 'px-3.5', 'text-b-sm', 'leading-b-md'],
        xs: ['h-6', 'px-1.5', 'text-b-xs', 'leading-b-xs'],
      },
      emphasis: {
        fill: [],
        outline: [],
        quiet: [],
      },
      isSelected: {
        true: [],
        false: [],
      },
    },
    compoundVariants: [
      {
        tone: 'accent',
        emphasis: 'fill',
        className: [
          'bg-accent',
          'border-accent',
          'data-[focus-visible=true]:bg-ocean-dark-1',
          'data-[focus-visible=true]:bg-ocean-dark-1',
          'data-[focus-visible=true]:border-ocean-dark-1',
          'data-[hovered=true]:bg-ocean-dark-1',
          'data-[hovered=true]:border-ocean-dark-1',
          'data-[pressed=true]:bg-ocean-dark-2',
          'data-[pressed=true]:border-ocean-dark-2',
          'data-[pressed=true]:data-[hovered=true]:border-ocean-dark-2',
          'text-on-background',
        ],
      },
      {
        tone: 'accent',
        emphasis: 'outline',
        className: [
          'bg-base',
          'border-gray-light-1',
          'data-[focus-visible=true]:text-accent-strong',
          'data-[hovered=true]:text-accent-strong',
          'data-[pressed=true]:bg-accent-weak',
          'data-[pressed=true]:text-accent-strong',
          'group-aria-selected:bg-accent-weak',
          'group-aria-selected:text-accent-strong',
          'text-accent',
        ],
      },
      {
        tone: 'accent',
        emphasis: 'quiet',
        className: [
          'bg-base',
          'border-white',
          'data-[focus-visible=true]:text-accent-strong',
          'data-[hovered=true]:text-accent-strong',
          'data-[pressed=true]:bg-accent-weak',
          'data-[pressed=true]:border-accent-weak',
          'data-[pressed=true]:text-accent-weak',
          'text-accent',
        ],
      },
      {
        tone: 'critical',
        emphasis: 'fill',
        className: [
          'bg-critical',
          'border-critical',
          'data-[focus-visible=true]:bg-red-dark-1',
          'data-[focus-visible=true]:border-red-dark-1',
          'data-[hovered=true]:bg-red-dark-1',
          'data-[hovered=true]:border-red-dark-1',
          'data-[pressed=true]:bg-red-dark-2',
          'data-[pressed=true]:border-red-dark-2',
          'text-on-background',
        ],
      },
      {
        tone: 'critical',
        emphasis: 'outline',
        className: [
          'bg-base',
          'border-gray-light-1',
          'data-[focus-visible=true]:text-critical-strong',
          'data-[hovered=true]:text-critical-strong',
          'data-[pressed=true]:bg-critical-weak',
          'data-[pressed=true]:border-red-dark-2',
          'data-[pressed=true]:text-critical-strong',
          'text-critical',
        ],
      },
      {
        tone: 'critical',
        emphasis: 'quiet',
        className: [
          'bg-base',
          'border-white',
          'data-[focus-visible=true]:text-critical-strong',
          'data-[hovered=true]:text-critical-strong',
          'data-[pressed=true]:bg-critical-weak',
          'data-[pressed=true]:border-critical-weak',
          'data-[pressed=true]:text-critical-weak',
          'text-critical',
        ],
      },
      {
        tone: 'neutral',
        emphasis: 'fill',
        className: [
          'bg-gray-light-2',
          'border-gray-light-2',
          'data-[focus-visible=true]:bg-gray-light-1',
          'data-[focus-visible=true]:border-gray-light-1',
          'data-[hovered=true]:bg-gray-light-1',
          'data-[hovered=true]:border-gray-light-1',
          'data-[pressed=true]:bg-gray',
          'data-[pressed=true]:border-gray',
          'text-base',
        ],
      },
      {
        tone: 'neutral',
        emphasis: 'outline',
        className: [
          'bg-base',
          'border-gray-light-1',
          'data-[focus-visible=true]:bg-gray-light-2',
          'data-[hovered=true]:bg-gray-light-2',
          'data-[pressed=true]:bg-gray-light-1',
          'data-[pressed=true]:border-gray-dark-1',
          'text-base',
        ],
      },
      {
        tone: 'neutral',
        emphasis: 'quiet',
        className: [
          'bg-base',
          'border-white',
          'data-[focus-visible=true]:bg-gray-light-2',
          'data-[focus-visible=true]:border-gray-light-2',
          'data-[hovered=true]:bg-gray-light-2',
          'data-[hovered=true]:border-gray-light-2',
          'data-[pressed=true]:bg-gray-light-1',
          'data-[pressed=true]:border-gray-light-1',
          'text-base',
        ],
      },
    ],
    defaultVariants: {
      emphasis: 'outline',
      size: 'md',
      tone: 'accent',
    },
  },
  { twMerge: false },
);

export const icon = tv({
  base: [],
  variants: {
    size: {
      lg: ['only:m-[-8px]'],
      md: ['only:m-[-9px]'],
      sm: ['only:m-[-9px]'],
      xs: ['only:m-[-3.5px]'],
    },
  },
});

type Variants = Required<VariantProps<typeof button>>;

export type ButtonVariants = {
  /**
   * Visual importance of the button.
   * @default outline
   */
  emphasis?: Variants['emphasis'];
  /**
   * Change the size of the button.
   * @default md
   */
  size?: Variants['size'];
  /**
   * Change the color and icon based on semantic meaning of the button.
   * @default accent
   */
  tone?: Variants['tone'];
};
