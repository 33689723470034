import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Avatar, Icon, notification } from 'antd';
import { TagUserAvatar } from 'common-components/notes/TagUserAvatar';
import TextTag from 'common-components/tags/TextTag';
import { Text } from 'common-components/typography';
import { History } from 'history';
import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { useAppDispatch } from 'stores/rematch/root-store';
import { NoteVisibleType } from 'utilities/enum-utils';
import globalHistory from '../../globalHistory';
import { GhostButton, HyperlinkButton } from '../buttons';
import type { IBookingNoteItem } from 'views/bookings/details/sections/content-section/tabs-panel/IBookingNoteItem';
import type { ICustomerNotes } from 'interfaces/customer-interfaces';
import type { IBooking } from 'interfaces/booking-interfaces';
import { Label } from '@goodhuman-me/components';
import { ReadMoreExpander } from 'common-components/read-more-expander/read-more-expander';
import { useNoteGoals } from 'stores/hooks/query-hooks/use-query-fetch-note-goals';
import { downloadFileFromUrl } from 'utilities/file-utils';

type NoteCardItemNote = ICustomerNotes | IBookingNoteItem;

export type NoteCardItemProps = (
  | {
      /** noteType `GENERAL` is for Customers. */
      noteType: 'GENERAL';
      noteItem: ICustomerNotes;
    }
  | {
      noteType: 'BOOKING';
      noteItem: IBookingNoteItem;
    }
) & {
  serviceName: string | null;
  bookingId: string | null;
  onPressEditNote: (note: NoteCardItemNote) => void;
  onPressDeleteNote: (note: NoteCardItemNote) => void;
  bookingStartDateTime?: IBooking['startDateTime'];
  timezone: string;
  history?: History;
  isBookingArchived?: boolean;
  showFormModal?: (noteItem: NoteCardItemNote) => void;
  selectedCustomerUserId?: string;
  isNoteDeletable: boolean;
};

export const NoteCardItem: React.VFC<NoteCardItemProps> = ({
  noteItem,
  noteType,
  serviceName,
  bookingStartDateTime,
  selectedCustomerUserId,
  showFormModal,
  timezone,
  bookingId,
  isNoteDeletable,
  onPressDeleteNote,
  onPressEditNote,
  history,
  isBookingArchived,
}) => {
  const {
    workflowStore: { doCheckViewWorkflowDetails },
  } = useAppDispatch();

  const goToWorkflowDetailPage = useCallback(async () => {
    const workflowId = 'workflowId' in noteItem ? (noteItem.workflowId as string) : undefined;
    if (!workflowId) return;
    const response = await doCheckViewWorkflowDetails({ workflowId });
    if (response?.canViewDetail) {
      globalHistory.push(`/workflows/details/${workflowId}`);
    } else {
      notification.error({ message: "You don't have permission to view this workflow" });
    }
  }, [noteItem, doCheckViewWorkflowDetails]);

  const { data: goals } = useNoteGoals({ customerId: selectedCustomerUserId, noteId: noteItem.noteId, noteType });

  return (
    <div className='bordered ph-medium mb-medium rounded-big bg-white' data-testid='note-card-item'>
      <div className='pb-large mt-medium flex flex-row'>
        <div className='align-start w-1/4 flex-shrink-0'>
          <div className='align-center flex-row'>
            <Avatar src={noteItem.authorImage} alt='avatar' size={40} shape='square' />
            <div className='mh-small'>
              <Text weight='bold'>
                {noteItem.firstName} {noteItem.lastName}
              </Text>
              <br />
              <Text size='regular' color='secondary'>
                {moment.tz(noteItem.createdOn, timezone).format('DD MMM YYYY, hh:mm A')}
              </Text>
            </div>
          </div>
        </div>
        <div className='ml-x2-large flex-grow rounded'>
          <ul className='mb-medium flex flex-row flex-wrap gap-2' aria-label='Tags'>
            {'isIncident' in noteItem && noteItem.isIncident && (
              <li>
                <Label emphasis='light' tone='critical'>
                  Incident
                </Label>
              </li>
            )}

            {'workflowName' in noteItem && 'referenceId' in noteItem && noteItem.workflowName && (
              <li>
                <TextTag
                  rounded={true}
                  color='tertiary'
                  className='mr-small mb-x-small'
                  content={
                    <Text className='mh-small' size='regular' weight='bolder'>
                      {noteItem.workflowName}:{' '}
                      <HyperlinkButton fontSize='regular' className='ml-x2-small' onClick={goToWorkflowDetailPage}>
                        Workflow {noteItem.referenceId}
                      </HyperlinkButton>
                    </Text>
                  }
                />
              </li>
            )}

            {noteType === 'BOOKING' && 'startDateTime' in noteItem ? (
              <li>
                <TextTag
                  rounded={true}
                  color='tertiary'
                  className='mb-x-small'
                  content={
                    <>
                      <Icon type='calendar' className='mr-x-small' />
                      <Text size='regular' weight='bold'>
                        {serviceName} &nbsp;
                      </Text>
                      <Text size='regular' weight='regular' className='mr-small'>
                        {`@ ${moment
                          .tz(noteItem.startDateTime ? noteItem.startDateTime : bookingStartDateTime, timezone)
                          .format('DD MMM YYYY, hh:mma')}`}
                      </Text>
                    </>
                  }
                />
              </li>
            ) : !('isIncident' in noteItem && noteItem.isIncident) ? (
              <li>
                <Label emphasis='light' tone='neutral'>
                  General
                </Label>
              </li>
            ) : null}

            {noteItem.visibleType === NoteVisibleType.RESTRICTED_TO_SERVICE ||
            noteItem.visibleType === NoteVisibleType.RESTRICTED_TO_SERVICE_PORTAL_AND_APP ? (
              <li className='ml-small'>
                <TextTag
                  rounded={true}
                  color='blue-lightest'
                  content={
                    <div>
                      <Icon theme='twoTone' twoToneColor='black' type='lock' className='mr-x-small' />
                      <Text size='regular' weight='bold'>
                        Restricted to service
                      </Text>
                    </div>
                  }
                />
              </li>
            ) : (
              noteItem.visibleType === NoteVisibleType.PRIVATE_PORTAL_ONLY && (
                <li className='ml-small'>
                  <TextTag
                    rounded={true}
                    color='blue-lightest'
                    content={
                      <div>
                        <Icon theme='twoTone' twoToneColor='black' type='lock' className='mr-x-small' />
                        <Text size='regular' weight='bold'>
                          Private to author
                        </Text>
                      </div>
                    }
                  />
                </li>
              )
            )}

            {goals && goals.length > 0 && (
              <li>
                <Label emphasis='light' tone='positive'>
                  Goal
                </Label>
              </li>
            )}
          </ul>

          <ReadMoreExpander className='text-size-large line-height-120 whitespace-pre-line'>
            {noteItem.body}
          </ReadMoreExpander>

          {goals && goals.length > 0 && (
            <ul className='mt-5 flex flex-wrap gap-2' aria-label='Goals'>
              {goals.map(({ goalTitle, fundingNDISGoalId }) => (
                <li key={fundingNDISGoalId}>
                  <Label emphasis='outlined' size='small'>
                    {goalTitle}
                  </Label>
                </li>
              ))}
            </ul>
          )}

          {'attachments' in noteItem && Array.isArray(noteItem.attachments) && noteItem.attachments.length > 0 && (
            <div className='mt-large bg-tertiary rounded-big'>
              <div className='p-small'>
                <Text size='small' color='secondary' lineHeight={100} className='mb-small'>
                  Attachments
                </Text>
                <br />
                {noteItem.attachments.map((document) => {
                  return (
                    <div className='mb-small' key={document.documentId}>
                      <span title={document.documentName}>
                        <Text
                          color={
                            document.documentStatus === 'SCANNING'
                              ? 'secondary'
                              : document.documentStatus === 'SCANNED'
                              ? 'green'
                              : 'red'
                          }
                        >
                          {document.documentStatus === 'SCANNED' ? (
                            <HyperlinkButton
                              onClick={() =>
                                downloadFileFromUrl({
                                  documentUrl: document.documentUrl,
                                  documentName: document.documentName,
                                })
                              }
                            >
                              <Icon type='download' className='mr-x-small text-color-blue' />
                              {document.documentName}
                            </HyperlinkButton>
                          ) : (
                            <>
                              <Tooltip2
                                content={
                                  document.documentStatus === 'SCANNING'
                                    ? 'This document is being scanned.'
                                    : 'This document failed to upload.'
                                }
                              >
                                <Icon
                                  type='question-circle'
                                  className={`text-size-x-large mr-x-small text-color-${
                                    document.documentStatus === 'SCANNING' ? 'blue' : 'red'
                                  }`}
                                />
                              </Tooltip2>
                              {document.documentName}
                            </>
                          )}
                        </Text>
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {'formName' in noteItem && 'workflowFormId' in noteItem && noteItem.workflowFormId && showFormModal && (
            <div className='mt-large bg-tertiary rounded-big p-small'>
              <Text size='small' color='secondary' lineHeight={100}>
                Forms
              </Text>
              <br />
              <Text color='secondary'>
                <HyperlinkButton onClick={() => showFormModal(noteItem)}>{noteItem.formName}</HyperlinkButton>
              </Text>
            </div>
          )}

          {/* Tag to */}
          {'taggedTo' in noteItem && noteItem.taggedTo?.some((tag) => tag.taggedUserId !== selectedCustomerUserId) ? (
            <div className='mt-medium'>
              <div className='pt-medium ph-medium bordered border-standard-gray bg-quaternary rounded-big line-height-100'>
                <div className='mb-x-small'>
                  <Text size='small' color='secondary' lineHeight={100}>
                    For:
                  </Text>
                </div>
                <div className='flex-row flex-wrap'>
                  {noteItem.taggedTo.map(
                    (customer) =>
                      customer.taggedUserId !== selectedCustomerUserId && (
                        <TagUserAvatar colorName='blue-action' customer={customer} key={customer.taggedUserId} />
                      ),
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {'updatedByWorkerFirstName' in noteItem && noteItem.updatedByWorkerFirstName && (
            <div className='mt-medium align-center flex-row'>
              <Avatar src={noteItem.updatedByWorkerImage} alt='avatar' size={15} shape='square' />
              <Text size='regular' color='secondary' className='mh-small'>
                Edited By &nbsp;
                <b>
                  {noteItem.updatedByWorkerFirstName} {noteItem.updatedByWorkerLastName}
                </b>
                &nbsp; on &nbsp;
                <b>{moment.tz(noteItem.updatedOn, timezone).format('DD MMM YYYY, hh:mm A')}</b>
              </Text>
            </div>
          )}
        </div>
        <div className='flex justify-end'>
          {!isBookingArchived && (
            <Popover2
              content={
                <Menu>
                  {/* bookingStartDateTime does not exist means we are not in booking details, which means we are in customer details page*/}
                  {!bookingStartDateTime && 'attendanceId' in noteItem && noteItem.attendanceId && (
                    <MenuItem
                      label=''
                      className='hover-bg-blue-lightest mv-medium'
                      text='Go to related booking'
                      onClick={() => history?.push(`/bookings/details/${bookingId}`)}
                    />
                  )}
                  <MenuItem
                    label=''
                    className='hover-bg-blue-lightest mv-medium'
                    text='Edit note'
                    onClick={() => onPressEditNote(noteItem)}
                  />
                  {isNoteDeletable && (
                    <MenuItem
                      label=''
                      className='hover-bg-blue-lightest text-color-red-dark mv-medium'
                      text='Delete note'
                      onClick={() => onPressDeleteNote(noteItem)}
                    />
                  )}
                </Menu>
              }
              popoverClassName='mb-medium'
              position='bottom'
              interactionKind='click'
              disabled={Boolean('workflowId' in noteItem && noteItem.workflowId)}
            >
              <GhostButton
                icon={'workflowId' in noteItem && noteItem.workflowId ? '' : 'ellipsis'}
                disabled={Boolean('workflowId' in noteItem && noteItem.workflowId)}
                className={'workflowId' in noteItem && noteItem.workflowId ? 'cursor-default' : undefined}
              />
            </Popover2>
          )}
        </div>
      </div>
    </div>
  );
};
