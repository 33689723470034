import { useEffect, useRef } from 'react';

import { WorkTypeCategory } from 'utilities/enum-utils';
import { useFetchWorkTypes } from 'stores/hooks/query-hooks/use-query-fetch-work-types-per-category';
import { useIsMutating } from '@tanstack/react-query';

function useWorkLeaveTypes(skipFetch?: boolean): {
  workTypes: ReturnType<typeof useFetchWorkTypes>;
  leaveTypes: ReturnType<typeof useFetchWorkTypes>;
} {
  const workTypes = useFetchWorkTypes({ workTypeCategory: WorkTypeCategory.WorkType }, true);
  const leaveTypes = useFetchWorkTypes({ workTypeCategory: WorkTypeCategory.Leave }, true);
  const isMutatingWorkTypes = useIsMutating(['work-types', WorkTypeCategory.WorkType]);
  const isMutatingLeaveTypes = useIsMutating(['work-types', WorkTypeCategory.Leave]);
  const hasReFetchedWorkTypes = useRef(false);
  const hasReFetchedLeaveTypes = useRef(false);

  useEffect(() => {
    if (!skipFetch && !hasReFetchedWorkTypes.current && isMutatingWorkTypes === 0) {
      void (async () => await workTypes.refetch())();
      hasReFetchedWorkTypes.current = true;
    }
  }, [isMutatingWorkTypes, workTypes, skipFetch]);

  useEffect(() => {
    if (!skipFetch && !hasReFetchedLeaveTypes.current && isMutatingLeaveTypes === 0) {
      void (async () => await leaveTypes.refetch())();
      hasReFetchedLeaveTypes.current = true;
    }
  }, [isMutatingLeaveTypes, leaveTypes, skipFetch]);

  return {
    workTypes,
    leaveTypes,
  };
}

export default useWorkLeaveTypes;
