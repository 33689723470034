import { initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import { FirebaseStorage, getStorage } from 'firebase/storage';

import { FIREBASE_CONFIG } from '../config/app-config';

const app = initializeApp(FIREBASE_CONFIG);

class FirebaseApp {
  auth: Auth;
  storage: FirebaseStorage;

  constructor() {
    this.auth = getAuth(app);
    this.storage = getStorage(app);
  }

  attachAuthListener(handler) {
    this.auth.onAuthStateChanged(handler);
  }
}

const firebaseApp = new FirebaseApp();

export default firebaseApp;
