import React from 'react';
import { PageHeader } from '@good/ui/templates';
import { useTranslation } from 'react-i18next';
import { Button, rem } from '@good/ui/core';
import { PlusSquare } from '@good/icons';
import { ServicesList } from './services-list';

type ServiceListingProps = {
  showServiceModal: () => void;
};

export const ServiceListingPage = ({ showServiceModal }: ServiceListingProps) => {
  const { t } = useTranslation('', { keyPrefix: 'services.listing' });

  return (
    <>
      <PageHeader
        title={t('title')}
        subtitle={t('description')}
        mb={rem(24)}
        actions={
          <Button leftSection={<PlusSquare />} onClick={showServiceModal} size='md'>
            {t('button')}
          </Button>
        }
      />
      <ServicesList />
    </>
  );
};
