import { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { WorkTypeCategory } from 'utilities/enum-utils';
import { IWorkLeaveType } from 'interfaces/account-interfaces';
import { DropDownItem } from './Dropdown';

function useMeatBallsDropdownItems(
  workLeaveType: IWorkLeaveType,
  onClose: () => void,
  onEdit: ((workLeaveType: IWorkLeaveType) => void) | undefined,
  onArchive: ((workLeaveType: IWorkLeaveType) => void) | undefined,
  onRestore: ((workLeaveType: IWorkLeaveType) => void) | undefined,
): DropDownItem[] {
  const { pinc762Stage2PayCodeConfigurationWithArchive } = useFlags();
  const [dropdownItems, setDropdownItems] = useState<DropDownItem[]>([]);

  useEffect(() => {
    const dropdownItems_: DropDownItem[] = [];
    const isArchived = workLeaveType.state === 'INACTIVE';

    if (onEdit && !isArchived) {
      dropdownItems_.push({
        label: `Edit ${workLeaveType.category === WorkTypeCategory.WorkType ? 'work' : 'leave'} type`,
        onClick: () => {
          onEdit(workLeaveType);
          onClose();
        },
      });
    }

    if (pinc762Stage2PayCodeConfigurationWithArchive && onArchive && !isArchived) {
      dropdownItems_.push({
        label: 'Archive leave type',
        onClick: () => {
          onArchive(workLeaveType);
          onClose();
        },
        className: 'text-critical',
      });
    }

    if (pinc762Stage2PayCodeConfigurationWithArchive && onRestore && isArchived) {
      dropdownItems_.push({
        label: 'Restore',
        onClick: () => {
          onRestore(workLeaveType);
          onClose();
        },
      });
    }

    setDropdownItems(dropdownItems_);
  }, [onArchive, onClose, onEdit, onRestore, pinc762Stage2PayCodeConfigurationWithArchive, workLeaveType]);

  return dropdownItems;
}

export default useMeatBallsDropdownItems;
