import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ArrowRightCurvedCircle = forwardRef(function ArrowRightCurvedCircle(
  props: IconProps,
  ref: Ref<SVGSVGElement>,
) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 12a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0ZM12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25Zm2.53 6.22a.75.75 0 1 0-1.06 1.06l.72.72H11a3.75 3.75 0 1 0 0 7.5h2a.75.75 0 0 0 0-1.5h-2a2.25 2.25 0 0 1 0-4.5h5a.75.75 0 0 0 .53-1.28l-2-2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
