import React from 'react';
import { Link } from 'react-router-dom';

import { Text, UnstyledButton, useComputedColorScheme, useMantineTheme } from '@good/ui/core';
import type { NavLinkItem } from '../types';
import classes from './navbar.module.css';

type NavbarLinkProps = {
  isActive: boolean;
  item: NavLinkItem;
  onClick: (item: NavLinkItem) => void;
};

export const NavbarLink = ({ isActive, item, onClick }: NavbarLinkProps) => {
  const iconWithProps = React.cloneElement(item.icon, { className: classes.linkIcon, style: { color: 'inherit' } });
  const theme = useMantineTheme();
  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });
  const itemColor = item.color
    ? computedColorScheme === 'dark'
      ? theme.colors[item.color]?.[2]
      : theme.colors[item.color]?.[7]
    : undefined;

  return (
    <UnstyledButton
      component={Link}
      onClick={(event) => {
        if (item.onClick) {
          event.preventDefault();
          item.onClick();
          return;
        }
        onClick(item);
      }}
      to={item.link}
      className={classes.link}
      data-active={isActive || undefined}
      py='xs'
      px='sm'
      c={itemColor}
    >
      {iconWithProps}
      <Text component='span' fz='sm'>
        {item.label}
      </Text>
    </UnstyledButton>
  );
};
