import React, { Component } from 'react';
import { Text } from 'common-components/typography';

class ItemCountSheet extends Component<{ itemCount: number; itemLabel: string; plurial?: string }> {
  render() {
    const { itemCount, itemLabel, plurial } = this.props;

    return (
      <div className="booking-item-count">
        <Text weight="bold">
          {itemCount} {itemLabel}
          {itemCount > 1 && (plurial ? plurial : 's')}
        </Text>
      </div>
    );
  }
}

export { ItemCountSheet };
