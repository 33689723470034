import React from 'react';
import { useTimelineContext } from './timeline-provider';

const INDICATOR_SIZE = 0.8;

export const CurrentTimeIndicator = ({ date = new Date() }: { date?: Date }) => {
  const { getPositionFromLeftBasedOnDate, headerHeight, isOutOfRange } = useTimelineContext();
  const positionFromLeft = getPositionFromLeftBasedOnDate(date);

  if (isOutOfRange(date)) {
    return null;
  }

  return (
    <div
      className="absolute bottom-0 text-red"
      style={{
        left: `${positionFromLeft}rem`,
        top: `${headerHeight - INDICATOR_SIZE * 0.5}rem`,
        width: `${INDICATOR_SIZE}rem`,
      }}
    >
      <svg width="100%" height="100%">
        <defs>
          <pattern
            id="dashed-line"
            x="0"
            y="0"
            width={`${INDICATOR_SIZE}rem`}
            height={`${INDICATOR_SIZE}rem`}
            patternUnits="userSpaceOnUse"
          >
            <line
              x1={`${INDICATOR_SIZE / 2}rem`}
              y1="0rem"
              x2={`${INDICATOR_SIZE / 2}rem`}
              y2={`${INDICATOR_SIZE}rem`}
              stroke="currentColor"
              strokeWidth={`${INDICATOR_SIZE * 0.125}rem`}
              strokeLinecap="round"
              strokeDasharray={`${INDICATOR_SIZE * 0.3}rem ${INDICATOR_SIZE * 0.6}rem`}
            />
          </pattern>
        </defs>

        <rect x="0" y="0" width="100%" height="100%" fill="url(#dashed-line)"></rect>
        <circle
          cx={`${INDICATOR_SIZE / 2}rem`}
          cy={`${INDICATOR_SIZE / 2}rem`}
          r={`${INDICATOR_SIZE / 2}rem`}
          fill="currentColor"
        ></circle>
      </svg>
    </div>
  );
};
