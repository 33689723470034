import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const VideoCameraSlash = forwardRef(function VideoCameraSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.53 1.47a.75.75 0 0 0-1.06 1.06l1.47 1.471c-.492.502-.838.893-1.09 1.346a4.75 4.75 0 0 0-.418 1.008c-.182.64-.182 1.315-.182 2.363v3.338c0 1.838 0 3.294.153 4.433.158 1.172.49 2.121 1.238 2.87.749.748 1.698 1.08 2.87 1.238 1.14.153 2.595.153 4.433.153H16c1 0 1.876-.534 2.357-1.332l3.113 3.112a.75.75 0 1 0 1.06-1.06l-4-4-14-14-2-2Zm.63 4.607c.158-.285.378-.54.842-1.014l13.217 13.216A1.25 1.25 0 0 1 16 19.25h-6c-1.907 0-3.261-.002-4.29-.14-1.005-.135-1.585-.389-2.008-.812-.423-.423-.677-1.003-.812-2.009-.138-1.028-.14-2.382-.14-4.289V8.828c0-1.196.007-1.649.125-2.062a3.25 3.25 0 0 1 .285-.69ZM10 4.75c1.907 0 3.262.002 4.29.14 1.005.135 1.585.389 2.008.812.423.423.677 1.003.812 2.009.139 1.028.14 2.382.14 4.289v1a.75.75 0 0 0 1.5 0v-1.056c0-.95 0-1.798-.021-2.553L19.25 9c.719-.539 1.19-.89 1.548-1.085.17-.092.264-.12.31-.13.02.042.053.135.08.326.06.403.062.99.062 1.889v7a.75.75 0 0 0 1.5 0V9.94c0-.821 0-1.521-.077-2.047-.078-.533-.27-1.149-.89-1.458-.62-.31-1.228-.095-1.701.162-.416.227-.906.585-1.473 1.009a8.444 8.444 0 0 0-.012-.095c-.158-1.172-.49-2.121-1.238-2.87-.749-.748-1.698-1.08-2.87-1.238-1.14-.153-2.595-.153-4.433-.153H9a.75.75 0 0 0 0 1.5h1Zm11.142 3.034s-.005.001-.013-.001c.01 0 .014 0 .013.001Zm-.046-.017-.009-.01c0-.001.004.002.01.01Z"
        fill="currentColor"
      />
    </Icon>
  );
});
