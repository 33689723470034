import { Checkbox, Col, Form, Icon, Row, Select, Tooltip } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { OptionProps } from 'antd/lib/select';
import { Text } from 'common-components/typography';
import { INewActivityRecordData } from 'interfaces/booking-interfaces';
import { IServiceListItemLite } from 'interfaces/service-interfaces';
import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import styled from 'styled-components';
import { ServiceType } from 'utilities/enum-utils';

const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-selection,
  .ant-select-selection__rendered {
    height: 57px;
  }

  .ant-select-selection-selected-value,
  .ant-select-selection__placeholder {
    font-size: 14px;
  }

  .ant-select-selection__rendered {
    line-height: 30px;
  }
`;

interface IActivityRecordServiceSelectorProps {
  form: WrappedFormUtils<any>;
  servicesLite: IServiceListItemLite[];
  isLoadingServiceAgreement: boolean;
  hasValidServiceAgreement: boolean;
  newActivityRecordData: INewActivityRecordData;
  onSelectService: (selectedServiceId: string) => void;
  onCheckUseNextTime: (useServiceNextTime: boolean) => void;
  doFetchServicesLite: typeof dispatch.servicesStore.doFetchServicesLite;
}

interface IActivityRecordServiceSelectorState {
  selectedServiceLite: IServiceListItemLite;
  isLoadingService: boolean;
}

const ActivityRecordServiceSelector = (props: IActivityRecordServiceSelectorProps) => {
  const {
    form,
    servicesLite,
    isLoadingServiceAgreement,
    hasValidServiceAgreement,
    newActivityRecordData,
    onSelectService,
    onCheckUseNextTime,
    doFetchServicesLite,
  } = props;
  const { getFieldDecorator } = form;
  const [state, setState] = useState<IActivityRecordServiceSelectorState>({
    selectedServiceLite: null,
    isLoadingService: false,
  });

  const _getServicesLite = async () => {
    setState({ ...state, isLoadingService: true });
    await doFetchServicesLite({
      serviceType: [ServiceType.INDIVIDUAL, ServiceType.COORDINATION],
    });
    setState({ ...state, isLoadingService: false });
  };

  const _onSelectService = (selectedServiceId: string) => {
    const selectedServiceLite = _.find(servicesLite, (s) => s.serviceId === selectedServiceId);
    setState({ ...state, selectedServiceLite });
    onSelectService(selectedServiceId);
  };

  const filterServiceOption = (inputValue: string, option: React.ReactElement<OptionProps>) => {
    return _.includes(_.toLower(option.props.title).trim(), _.toLower(inputValue).trim());
  };

  const renderServiceOptionLabel = (serviceLiteDetail: IServiceListItemLite) => {
    return (
      <>
        <Text size="regular"> {serviceLiteDetail.serviceName}</Text>
        {hasValidServiceAgreement ? (
          <Text className="block" size="x-small" color="green-dark">
            <Icon type="check-circle" theme="filled" className="text-size-x-small" /> In customer service agreement
          </Text>
        ) : (
          <Text className="block" size="x-small" color="orange-darker">
            <Icon type="warning" theme="filled" className="text-size-x-small" /> Not in customer service agreement
          </Text>
        )}
      </>
    );
  };

  useEffect(() => {
    _getServicesLite();
  }, []);

  useEffect(() => {
    if (newActivityRecordData.selectedServiceId && _.isEmpty(state.selectedServiceLite)) {
      const selectedServiceLite = _.find(servicesLite, (s) => s.serviceId === newActivityRecordData.selectedServiceId);
      setState({ ...state, selectedServiceLite: selectedServiceLite });
    }
  }, [servicesLite, newActivityRecordData.selectedServiceId, state.selectedServiceLite]);

  return (
    <Row>
      <Col span={12}>
        <Form.Item>
          {getFieldDecorator('service', {
            initialValue: newActivityRecordData.selectedServiceId || undefined,
            rules: [
              {
                required: true,
                message: (
                  <div className="flex align-center mt-small">
                    <Icon type="close-circle" theme="filled" className="text-size-regular mr-x-small" />
                    <Text type="danger" size="x-small">
                      Service is required
                    </Text>
                  </div>
                ),
              },
            ],
          })(
            <StyledSelect
              placeholder="Search or select service"
              className="width-full"
              size="large"
              showSearch
              filterOption={filterServiceOption}
              optionLabelProp="label"
              onChange={_onSelectService}
              loading={isLoadingServiceAgreement}
            >
              {!state.isLoadingService &&
                servicesLite.map((service) => (
                  <Option
                    key={service.serviceId}
                    value={service.serviceId}
                    title={service.serviceName}
                    label={renderServiceOptionLabel(service)}
                  >
                    {service.serviceName}
                  </Option>
                ))}
            </StyledSelect>,
          )}
        </Form.Item>
      </Col>
      <Col span={12} className="flex align-center pl-large" style={{ height: '57px' }}>
        <div>
          <Checkbox
            checked={newActivityRecordData.useServiceNextTime}
            onChange={(e) => onCheckUseNextTime(e.target.checked)}
          >
            Use next time
          </Checkbox>
          <Tooltip
            placement="top"
            title={<div className="p-small">Remember this service next time you create an activity record.</div>}
          >
            <Icon type="question-circle" className="text-size-x-large text-color-secondary" />
          </Tooltip>
        </div>
      </Col>
    </Row>
  );
};

const mapState = (state: IRootState) => ({
  servicesLite: state.servicesStore.servicesLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServicesLite: dispatch.servicesStore.doFetchServicesLite,
});

export default connect(mapState, mapDispatch)(memo(ActivityRecordServiceSelector));
