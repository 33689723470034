import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Avatar, Button, Col, Icon, Row } from 'antd';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { timeZone } from 'interfaces/timezone-type';
import { BookingStatus, PaymentStatus, SleepoverTimeSlotErrorType } from 'utilities/enum-utils';
import { IDisturbance } from 'src/interfaces/booking-interfaces';

interface ICommentAreaProps {
  comment: string;
}

const CommentArea = ({ comment }: ICommentAreaProps) => {
  const [showMore, setShowMore] = useState(false);
  const lengthTextInline = 26;
  return (
    <div>
      <div>
        <Text>
          {showMore
            ? comment
            : comment
            ? `${comment.substring(0, lengthTextInline)}${comment.length > lengthTextInline ? '...' : ''}`
            : ''}
        </Text>
      </div>
      {comment?.length > lengthTextInline ? (
        <HyperlinkButton onClick={() => setShowMore(!showMore)}>
          {showMore && comment ? 'Show less...' : 'Show more...'}
        </HyperlinkButton>
      ) : null}
    </div>
  );
};

interface IProps {
  bookingSelectedItem: any;
  onOpenEditDisturbance: (disturbance: IDisturbance) => void;
  disturbances: IDisturbance[];
  onDeleteDisturbanceLineItem: (payload: { disturbanceId: string }) => void;
  isLoading: boolean;
  displayTimezone: timeZone;
}

const BookingDisturbancePanel = ({
  bookingSelectedItem,
  onOpenEditDisturbance,
  disturbances,
  onDeleteDisturbanceLineItem,
  isLoading,
  displayTimezone,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [disturbanceId, setDisturbanceId] = useState(null);

  const onClickEdit = (disturbance) => {
    onOpenEditDisturbance(disturbance);
  };

  const onClickDelete = (disturbance) => {
    setDisturbanceId(disturbance.disturbanceId);
    setIsOpen(true);
  };

  const menu = (disturbance) => (
    <Menu>
      <MenuItem
        label={''}
        onClick={() => onClickEdit(disturbance)}
        className="hover-bg-blue-lightest"
        text={<Text>Edit</Text>}
      />
      <MenuItem
        label={''}
        onClick={() => onClickDelete(disturbance)}
        className="hover-bg-blue-lightest"
        text={<Text className="text-color-red">Delete</Text>}
      />
    </Menu>
  );

  const onCloseModal = () => {
    setIsOpen(false);
  };
  const onDeleteDisturbance = async () => {
    await onDeleteDisturbanceLineItem({ disturbanceId });
    onCloseModal();
  };

  const renderErrorMessage = (errorType) => {
    const textError =
      errorType === SleepoverTimeSlotErrorType.OUT_OF_BOOKING_TIME
        ? 'The recorded disturbance falls outside the scheduled booking time.'
        : errorType === SleepoverTimeSlotErrorType.OUT_OF_CHECKIN_CHECKOUT_TIME
        ? "The recorded disturbance falls outside team member's start and finish time."
        : errorType === SleepoverTimeSlotErrorType.OUT_OF_SLEEPOVER_TIME
        ? "The recorded disturbance falls outside team member's sleepover time."
        : null;
    const title = 'Wrong time entry';
    return (
      <Popover2
        position={'bottom-right'}
        interactionKind="hover"
        content={
          <div className="ph-medium pv-medium" style={{ width: '290px' }}>
            <div className="text-color-red-light">
              <Icon
                type="minus-circle"
                theme="filled"
                className="text-color-red-light mr-x-small"
                style={{ fontSize: 20 }}
              />{' '}
              {title}
            </div>
            <Text className="mt-medium">{textError}</Text>
          </div>
        }
      >
        <Icon type="minus-circle" theme="filled" className="text-color-red-light mr-x-small" style={{ fontSize: 20 }} />
      </Popover2>
    );
  };

  const editable =
    bookingSelectedItem.status === BookingStatus.INPROGRESS ||
    (bookingSelectedItem.status === BookingStatus.COMPLETED &&
      bookingSelectedItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL);

  return (
    <div className="mt-large">
      <ActionModal isOpen={isOpen} title="Delete sleep disturbance" onClose={onCloseModal}>
        <div className="anim-slide-left">
          <Paragraph>You&apos;re about to delete a reported sleep disturbance. This can&apos;t be undone</Paragraph>
          <Paragraph>Are you sure?</Paragraph>

          <div className={'mb-small pt-large'}>
            <Row type={'flex'} justify={'end'}>
              <Col>
                <SecondaryButton className="mr-medium" size={'large'} onClick={onCloseModal}>
                  Cancel
                </SecondaryButton>
              </Col>
              <Col>
                <PrimaryButton loading={isLoading} size={'large'} onClick={onDeleteDisturbance}>
                  Delete
                </PrimaryButton>
              </Col>
            </Row>
          </div>
        </div>
      </ActionModal>
      <Row gutter={24} className="bordered-bottom pb-small">
        <Col span={5}>
          <SubTitle>Disturbance start</SubTitle>
        </Col>
        <Col span={5}>
          <SubTitle>DISTURBANCE END</SubTitle>
        </Col>
        <Col span={3}>
          <SubTitle containerClassName="text-align-right line-height-100">total time</SubTitle>
        </Col>
        <Col span={4}>
          <SubTitle>ADDED BY</SubTitle>
        </Col>
        <Col span={5}>
          <SubTitle>Comment</SubTitle>
        </Col>
        <Col span={1} />
        <Col span={1} />
      </Row>
      {_.map(disturbances, (disturbance) => {
        const startDateMoment = moment.tz(disturbance.startDateTime, displayTimezone);
        const endDateMoment = moment.tz(disturbance.endDateTime, displayTimezone);
        return (
          <Row gutter={24} className="pv-medium" type="flex" align="middle" key={disturbance.disturbanceId}>
            <Col span={5}>
              <Text>{startDateMoment.format('h:mm a, DD MMM YYYY')}</Text>
            </Col>
            <Col span={5}>
              <Text>{endDateMoment.format('h:mm a, DD MMM YYYY')}</Text>
            </Col>
            <Col span={3} className="text-align-right">
              <Text>
                {Math.round(disturbance.totalTime * 100) / 100} {`hour${disturbance.totalTime > 1 ? 's' : ''}`}
              </Text>
            </Col>
            <Col span={4}>
              <div className="flex align-center">
                <Avatar
                  icon="user"
                  className={`mr-medium ${disturbance.attachmentUrl ? 'rounded-big' : ''}`}
                  size="default"
                  src={disturbance.attachmentUrl}
                />
                <Text>{disturbance.displayName}</Text>
              </div>
            </Col>
            <Col span={5}>
              <CommentArea comment={disturbance.comment} />
            </Col>
            <Col span={1}>{disturbance.errorType ? renderErrorMessage(disturbance.errorType) : null}</Col>
            <Col span={1} className="flex align-center">
              <Popover2 content={menu(disturbance)} position={'bottom-right'}>
                <Button
                  icon={'ellipsis'}
                  className="bordered bordered-standard-grey text-color-blue-action"
                  disabled={!editable}
                ></Button>
              </Popover2>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default BookingDisturbancePanel;
