import { Slot } from '@radix-ui/react-slot';
import { filterDOMProps } from '@react-aria/utils';
import React, { forwardRef } from 'react';

import { boxStyleProps, css, useStyleProps } from '..';

import type { BoxStyleProps, HTMLProps, SlotProps } from '..';
import type { PropsWithChildren } from 'react';

export type BoxProps = PropsWithChildren<SlotProps & HTMLProps<HTMLDivElement> & BoxStyleProps>;

/**
 * @deprecated
 */
export const Box = forwardRef<HTMLDivElement, BoxProps>(function Box(props, ref) {
  const { styleProps, ...otherProps } = useStyleProps(props, boxStyleProps);
  const { asChild, children } = props;
  const ElementType = asChild ? Slot : 'div';

  return (
    <ElementType {...filterDOMProps(otherProps)} className={css(styleProps)()} ref={ref}>
      {children}
    </ElementType>
  );
});
