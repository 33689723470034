import React from 'react';
import { Header, Heading, Text, Button } from '@good/components';
import { Export } from '@good/icons';
import { useTranslation } from 'react-i18next';
import { TimesheetsTable } from './components/table';
import { DateFilter } from './components/date-filter';
import { useTimesheetsRequest } from './use-timesheets-request';
import { useFilters } from './use-filters';

export const List = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets' });
  const filters = useFilters();
  const timesheetsRequest = useTimesheetsRequest(filters);

  return (
    <div className="w-full flex-1 overflow-y-auto bg-white px-8 pb-6 pt-12">
      <div className="mb-10 flex-row">
        <Header size="xl">
          <Heading>{t('heading')}</Heading>
          <Text>{t('description')}</Text>
        </Header>
        <Button emphasis="fill" tone="accent" size="lg" className="mt-1">
          <span className="whitespace-nowrap">{t('actions.exportAll')}</span>
          <Text size="lg">
            <Export />
          </Text>
        </Button>
      </div>
      <div className="flex gap-2">
        <DateFilter filter={filters.dateFilter} setFilter={filters.setDateFilter} />
      </div>
      {timesheetsRequest && (
        <div className="-mt-3 overflow-y-auto">
          <TimesheetsTable filters={timesheetsRequest} />
        </div>
      )}
    </div>
  );
};
