import { SubTitle } from 'common-components/typography';
import React from 'react';

export function ListingTableHeader() {
  return (
    <div className='flex-row align-center line-height-100 bordered-bottom border-standard-gray mb-large'>
      <div className='bg-white flex-column' style={{ width: '4px', alignSelf: 'stretch' }} />

      <div className='flex-1 p-3'>
        <SubTitle>SESSION DATE</SubTitle>
        {/*<Text lineHeight={100}>9:30 - 12:30 PM</Text>*/}
      </div>
      <div className='flex-1 p-3'>
        <SubTitle>SERVICE</SubTitle>
      </div>
      <div className='flex-1 p-3'>
        <SubTitle>STATUS</SubTitle>
      </div>
      <div className='flex-1 p-3'>
        <SubTitle>CUSTOMERS</SubTitle>
      </div>
      <div className='flex-1 p-3'>
        <SubTitle>TEAM MEMBERS</SubTitle>
      </div>

      <div className='p-3' style={{ width: '246px' }} />
    </div>
  );
}
