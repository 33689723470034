import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const AlignCenter = forwardRef(function AlignCenter(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3.25a.75.75 0 1 0 0 1.5h16a.75.75 0 0 0 0-1.5H4ZM5.25 9A.75.75 0 0 1 6 8.25h12a.75.75 0 0 1 0 1.5H6A.75.75 0 0 1 5.25 9ZM4 13.25a.75.75 0 0 0 0 1.5h16a.75.75 0 0 0 0-1.5H4Zm4 5a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5H8Z"
        fill="currentColor"
      />
    </Icon>
  );
});
