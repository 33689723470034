import { IShiftCustomerAssignment } from 'interfaces/assign-customer-interfaces';
import moment from 'moment-timezone';
import { Col, Icon, Input, Row } from 'antd';
import { Text } from 'common-components/typography';
import TimeInput from 'common-components/time-input/TimeInput';
import { HyperlinkButton } from 'common-components/buttons';
import React from 'react';

export function TimeAssignmentRow({
  assignment,
  onSaveRow,
  onEditRow,
  onUpdateRow,
  onDeleteRow,
}: {
  assignment: IShiftCustomerAssignment;
  onSaveRow: any;
  onEditRow: any;
  onUpdateRow: any;
  onDeleteRow: any;
}) {
  // const [isEditing, setIsEditing] = useState(false);

  const { startDateTime, endDateTime, isEditing } = assignment;

  const timeDisplay = `${moment(startDateTime).format('h:mm a')} - ${moment(endDateTime).format('h:mm a, D MMM YYYY')}`;

  const isBeforeEnd = moment(startDateTime).isSameOrBefore(endDateTime);
  const errorMessage = !isBeforeEnd ? 'End time must be after start time' : '';

  const hasError = !isBeforeEnd;

  function saveRow() {
    if (isEditing) {
      onSaveRow({ rowId: assignment.shiftCustomerAssignmentId });
    }
  }

  function editRow() {
    if (!isEditing) {
      onEditRow({ rowId: assignment.shiftCustomerAssignmentId });
    }
  }

  return (
    <div>
      <Row>
        <Col span={8}>
          {!isEditing && (
            <div className="anim-fade-in-fast">
              <Text size="regular">{timeDisplay}</Text>
            </div>
          )}
          {!!isEditing && (
            <div className="flex-row anim-fade-in-fast">
              <TimeInput
                containerStyle={{ height: '32px' }}
                className="mr-x-small"
                onChange={(dt) =>
                  onUpdateRow({ rowId: assignment.shiftCustomerAssignmentId, field: 'startDateTime', value: dt })
                }
                value={moment(startDateTime)}
                // defaultValue={startDateTime}
              />
              <TimeInput
                containerStyle={{ height: '32px' }}
                onChange={(dt) =>
                  onUpdateRow({ rowId: assignment.shiftCustomerAssignmentId, field: 'endDateTime', value: dt })
                }
                value={moment(endDateTime)}
                // defaultValue={endDateTime}
              />
            </div>
          )}
        </Col>
        <Col span={14}>
          {!isEditing && (
            <div className="anim-fade-in-fast">
              <Text size="regular">{assignment.description}</Text>
            </div>
          )}
          {!!isEditing && (
            <div className="flex-row anim-fade-in-fast">
              <Input
                placeholder={'Enter description here...'}
                style={{ height: '32px' }}
                onChange={(e) =>
                  onUpdateRow({
                    rowId: assignment.shiftCustomerAssignmentId,
                    field: 'description',
                    value: e.target.value,
                  })
                }
                value={assignment.description}
              />
            </div>
          )}
        </Col>
        <Col span={2}>
          <div className="flex-row justify-end align-center mt-x-small mr-x2-small">
            {!!isEditing && (
              <HyperlinkButton onClick={saveRow}>
                <Icon type="save" className="" />
              </HyperlinkButton>
            )}

            {!isEditing && (
              <HyperlinkButton onClick={editRow}>
                <Icon type="edit" className="" />
              </HyperlinkButton>
            )}

            {/*<Popconfirm title="Delete this row?" okText={'Delete'} cancelText={'Cancel'} placement={'bottomRight'}>*/}
            {/*  <HyperlinkButton>*/}
            {/*    <Icon type="delete" className="text-color-red" />*/}
            {/*  </HyperlinkButton>*/}
            {/*</Popconfirm>*/}
          </div>
        </Col>
      </Row>
      {hasError && (
        <Text color="red" size="x-small">
          * {errorMessage}
        </Text>
      )}
    </div>
  );
}
