import React, { Component } from 'react';
import { Col, DatePicker, Form, Icon, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import moment from 'moment';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import _ from 'lodash';

interface IGeneralInfoDetailRowProps extends FormComponentProps {
  onNextStep: (stepData: any) => void;
  setFundingData: typeof dispatch.customersStore.setFundingData;
  fundingData: typeof state.customersStore.fundingData;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  closeCreatePackageModal: () => void;
}

interface IGeneralInfoDetailRowState {
  generalFundData: any;
  isActionModalOpen: boolean;
  divHeight: any;
  warnings: any;
}

class GeneralInfoDetailRow extends Component<IGeneralInfoDetailRowProps, IGeneralInfoDetailRowState> {
  state = {
    isActionModalOpen: false,
    generalFundData: {
      agencyName: 'NDIS',
      startDate: this.props.fundingData.startDate !== undefined ? this.props.fundingData.startDate : '',
      expiryDate: this.props.fundingData.expiryDate !== undefined ? this.props.fundingData.expiryDate : '',
      agencyAddress: this.props.fundingData.agencyAddress !== undefined ? this.props.fundingData.agencyAddress : '',
      phoneNumberCountryCode:
        this.props.fundingData.phoneNumberCountryCode !== '' &&
        this.props.fundingData.phoneNumberCountryCode !== undefined
          ? this.props.fundingData.phoneNumberCountryCode
          : 'AU',
      agencyNumber: this.props.fundingData.agencyNumber !== undefined ? this.props.fundingData.agencyNumber : '',
      agencyEmail: this.props.fundingData.agencyEmail !== undefined ? this.props.fundingData.agencyEmail : '',
    },
    divHeight: '',
    warnings: [],
  };

  // private _validateMobileNumber = (rule, value, callback) => {
  //   const region = 'AU';
  //   let phoneNumber = parsePhoneNumberFromString(value, region);
  //   try {
  //     if (!_.isEmpty(phoneNumber) && !phoneNumber.isValid()) {
  //       throw new Error('Invalid Number');
  //     }
  //   } catch (err) {
  //     callback(err);
  //     return;
  //   }
  //   callback();
  // };

  private _warningMessages = () => {
    const { selectedCustomer } = this.props;
    const { generalFundData } = this.state;
    let warnings = [];

    if (!_.isEmpty(generalFundData.startDate) && !_.isEmpty(generalFundData.expiryDate)) {
      const fundPackages = _.filter(selectedCustomer.funding.NDISFundingPackages, (fundPackage) => {
        return fundPackage.fundingPackageId !== this.props.fundingData.fundingPackageId;
      });

      // Check if there is a previous package
      const latestPreviousPackage = _.find(
        _.orderBy(fundPackages, 'expiredDate', 'desc'),
        (fundPackage) =>
          moment(fundPackage.expiredDate).endOf('day') <= moment(generalFundData.startDate).add(-1, 'day').endOf('day'),
      );

      if (
        latestPreviousPackage &&
        !_.find(
          fundPackages,
          (fundPackage) =>
            moment(fundPackage.startDate).endOf('day') < moment(generalFundData.startDate).endOf('day') &&
            moment(fundPackage.expiredDate).endOf('day') >
              moment(generalFundData.startDate).add(-1, 'day').startOf('day') &&
            fundPackage.status !== 'EXPIRED',
        )
      ) {
        warnings.push(
          //check if there is only one day between latestPreviousPackage.expiredDate and generalFundData.startDate
          moment(latestPreviousPackage.expiredDate).add(1, 'day').format('DD/MM/YYYY') ===
            moment(generalFundData.startDate).add(-1, 'day').format('DD/MM/YYYY')
            ? `The dates selected will result in there being no funding package for this customer for the date ${moment(
                latestPreviousPackage.expiredDate,
              )
                .add(1, 'day')
                .format('DD/MM/YYYY')}`
            : `The dates selected will result in there being no funding package for this customer for the dates ${moment(
                latestPreviousPackage.expiredDate,
              )
                .add(1, 'day')
                .format('DD/MM/YYYY')} to ${moment(generalFundData.startDate).add(-1, 'day').format('DD/MM/YYYY')}`,
        );
      }

      const overlapingPackages = _.find(
        fundPackages,
        (fundPackage) =>
          moment(fundPackage.startDate).endOf('day') <= moment(generalFundData.expiryDate).endOf('day') &&
          moment(fundPackage.expiredDate).endOf('day') >= moment(generalFundData.startDate).endOf('day'),
      );

      if (overlapingPackages) {
        warnings.push(
          `The dates selected overlap with another of the current funding package on the customer's profile. Bookings that occur in the overlap dates will be updated to reflect the details of this plan.`,
        );
      }

      this.setState({ warnings });
    }
  };

  private _closeActionModal = () => {
    this.setState({ isActionModalOpen: false });
  };

  private _handleDateData = (event, date, name) => {
    let generalFundData = this.state.generalFundData;
    generalFundData[name] = event.format('YYYY-MM-DD');
    this.setState(
      {
        generalFundData,
      },
      () => this._warningMessages(),
    );
  };

  private _closeModalWithAction = () => {
    let generalInoInputs = this.state.generalFundData;
    if (
      generalInoInputs.agencyName !== '' ||
      generalInoInputs.agencyAddress !== '' ||
      generalInoInputs.agencyEmail !== '' ||
      generalInoInputs.agencyNumber !== '' ||
      generalInoInputs.expiryDate !== '' ||
      generalInoInputs.startDate !== ''
    ) {
      this.setState({ isActionModalOpen: true });
    } else {
      this.props.closeCreatePackageModal();
    }
  };

  private _onNextStep = () => {
    let isFormValid = true;
    this.props.form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      let payload = [];
      payload.push({ generalFundData: this.state.generalFundData });
      this.props.onNextStep(payload);
    }
  };

  render() {
    const { form } = this.props;
    const { generalFundData } = this.state;
    const { getFieldDecorator } = form;
    // let style = this.state.divHeight !== '' ?
    return (
      <React.Fragment>
        <ActionModal
          isOpen={this.state.isActionModalOpen}
          onClose={this._closeActionModal}
          title={'Confirmation'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>You are closing the editing page without saving.</Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeActionModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this.props.closeCreatePackageModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <div className={'customScrollDiv'}>
          <Row gutter={16}>
            <Col span={12}>
              <SubTitle>Start Date</SubTitle>
              <Form.Item>
                {getFieldDecorator('startDate', {
                  initialValue: generalFundData.startDate !== '' ? moment(generalFundData.startDate, 'DD-MM-YYYY') : '',
                  rules: [
                    {
                      required: true,
                      message: 'Please select start date',
                    },
                  ],
                })(
                  <DatePicker
                    size="large"
                    placeholder={'Start Date'}
                    allowClear={false}
                    format="DD/MM/YYYY"
                    className={'width-full'}
                    onChange={(date, dateString) => this._handleDateData(date, dateString, 'startDate')}
                    disabledDate={(current) => {
                      const endValue = this.props.form.getFieldValue('expiryDate');
                      if (endValue !== null && endValue !== '') {
                        return current.valueOf() > endValue.startOf('day').valueOf();
                      }
                    }}
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <SubTitle>Plan Review Date</SubTitle>
              <Form.Item>
                {getFieldDecorator('expiryDate', {
                  initialValue:
                    generalFundData.expiryDate !== '' ? moment(generalFundData.expiryDate, 'DD-MM-YYYY') : '',
                  rules: [
                    {
                      required: true,
                      message: 'Please select expiry date',
                    },
                  ],
                })(
                  <DatePicker
                    size="large"
                    placeholder={'Plan Review Date'}
                    allowClear={false}
                    format="DD/MM/YYYY"
                    className={'width-full'}
                    onChange={(date, dateString) => this._handleDateData(date, dateString, 'expiryDate')}
                    disabledDate={(current) => {
                      const startValue = this.props.form.getFieldValue('startDate');
                      const minEndDate = moment(startValue).add(1, 'day');
                      if (startValue !== null && startValue !== '') {
                        return current.valueOf() < minEndDate.valueOf();
                      }
                    }}
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
          {this.state.warnings.length > 0 &&
            _.map(this.state.warnings, (warning) => (
              <Paragraph className="text-color-warning-orange">
                <Icon type="calendar" className="mr-x-small" />
                {warning}
              </Paragraph>
            ))}
        </div>
        <div className={'mt-x2-large flex-row justify-end align-center'}>
          <GhostButton className={'mr-small'} onClick={this._closeModalWithAction}>
            Cancel
          </GhostButton>
          <PrimaryButton size="large" icon={'right'} onClick={this._onNextStep}>
            Next
          </PrimaryButton>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = (state: IRootState) => ({
  fundingData: state.customersStore.fundingData,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setFundingData: dispatch.customersStore.setFundingData,
});

export default connect(mapState, mapDispatch)(Form.create<IGeneralInfoDetailRowProps>()(GeneralInfoDetailRow));
