import { forwardRef, type ForwardedRef, type InputHTMLAttributes, type RefObject, useRef } from 'react';
import { mergeProps, mergeRefs } from '@react-aria/utils';
import { type AriaTextFieldProps, useFocusRing, useHover, useTextField } from 'react-aria';

import * as styles from './text-area-field.css';
import { FieldLabel } from '../field-label';
import { StatusMessage } from '../status-message';
import { twMerge } from '../utils/';
import type { HTMLProps, LabelProps } from '../types';

export type TextAreaFieldProps = HTMLProps<HTMLTextAreaElement> & {
  /**
   * Text for the input's description element, if any.
   * */
  description?: AriaTextFieldProps['description'];
  /**
   * Text for the input's error message element.
   */
  errorMessage?: AriaTextFieldProps['errorMessage'];
  /**
   * `ref object` to reference input element.
   * */
  inputRef?: ForwardedRef<HTMLTextAreaElement>;
  /**
   * Controls whether the input is disabled.
   * @default false
   */
  isDisabled?: AriaTextFieldProps['isDisabled'];
  /**
   * Controls whether the input is readonly.
   * @default false
   */
  isReadOnly?: AriaTextFieldProps['isReadOnly'];
  /**
   * Controls the input's validation state.
   */
  validationState?: AriaTextFieldProps['validationState'];
  rows?: number;
} & LabelProps &
  Omit<
    AriaTextFieldProps,
    'description' | 'errorMessage' | 'inputRef' | 'isDisabled' | 'isReadOnly' | 'isRequired' | 'validationState'
  >;

export const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>((props, ref) => {
  const {
    className,
    description,
    errorMessage,
    inputRef,
    isRequired,
    label,
    requirementIndicator,
    rows = 2,
    validationState,
  } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks -- in general yes, but refs won't change
  const mergedRef = mergeRefs(ref, inputRef ?? useRef(null), useRef(null));
  const { focusProps, isFocusVisible, isFocused } = useFocusRing({ ...props, isTextInput: true });
  const { hoverProps, isHovered } = useHover(props);

  const { descriptionProps, errorMessageProps, inputProps, labelProps } = useTextField(
    props,
    mergedRef as RefObject<HTMLInputElement>,
  );

  const mergedInputProps = mergeProps(focusProps, hoverProps, inputProps);
  const isDescription = Boolean(description);
  const isErrorMessage = Boolean(errorMessage);
  const isInvalid = validationState === 'invalid';
  const showErrorMessage = isInvalid && isErrorMessage;
  const showDescription = isDescription && !showErrorMessage;
  const { container, input } = styles.textAreaField();

  return (
    <div className={twMerge(container(), className)}>
      <FieldLabel {...labelProps} requirementIndicator={requirementIndicator} isRequired={isRequired}>
        {label}
      </FieldLabel>

      <textarea
        {...(mergedInputProps as InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>)}
        ref={mergedRef}
        rows={rows}
        className={input()}
        data-focus-visible={isFocusVisible}
        data-focused={isFocused}
        data-hovered={isHovered}
      />

      {showDescription && (
        <StatusMessage tone="neutral" {...descriptionProps}>
          {description}
        </StatusMessage>
      )}

      {showErrorMessage && (
        <StatusMessage tone="critical" {...errorMessageProps}>
          {errorMessage}
        </StatusMessage>
      )}
    </div>
  );
});

TextAreaField.displayName = 'TextAreaField';
