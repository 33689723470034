/*add by dkamphuoc 20 Jan 2022 */
import { Input } from 'antd';
import { Icon } from 'antd/es';
import { InfiniteScroll } from 'components';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import Utils from 'utilities/Utils';
import { Text } from '../typography';

interface IProps {
  options: Array<any>;
  optionLabel: (e: any, index?: number) => string;
  optionKey: (e: any, index?: number) => string;
  renderItem: (data: { item: any; index: number; options: Array<any> }) => JSX.Element;
  onFetchData?: (data: { page: number; pageSize: number; textSearch: string }) => void;
  onSelected?: (value: any, option: any) => void;
  placeholder?: string;
  noData?: string;
  isLoading?: boolean;
}
interface IState {
  // isLoading: boolean;
  selected: any;
  isShowOption: boolean;
}

class SelectInline extends PureComponent<IProps, IState> {
  state = {
    // isLoading: false,
    selected: null,
    isShowOption: false,
  };

  _params = {
    page: 1,
    pageSize: 10,
    textSearch: '',
  };

  _isScroll = false;

  refInput = React.createRef<any>();

  private _onLoadMore = () => {
    this._params.page++;
    this._searchWithParams();
  };

  private _searchWithParams = async () => {
    if (this.props.isLoading === true) return;
    const { onFetchData } = this.props;
    onFetchData({ ...this._params });
  };

  private _debounceSearch = _.debounce(this._searchWithParams, 300);

  private _onTextSearch = async (text) => {
    this._params = { ...this._params, page: 1, textSearch: text };
    this.forceUpdate();
    this._debounceSearch();
  };

  private _onSelected = async (value, option) => {
    this.setState({ ...this.state, isShowOption: false, selected: option }, () => {
      this.props.onSelected(value, option);
    });
  };

  private _onFocusTextSearch = (text) => {
    this._isScroll = false;
    this.setState({ ...this.state, isShowOption: true }, () => {
      this.props.options.length == 0 && this._onTextSearch(text);
    });
  };

  private _onBlurTextSearch = () => {
    if (this._isScroll == true) {
      this._isScroll = false;
      this.refInput.current.input.focus();
      return;
    }
    this.setState({ ...this.state, isShowOption: false });
  };

  private _onBlurTextSearchDebounce = _.debounce(this._onBlurTextSearch, 200);

  private _onScrollOptions = () => {
    this.refInput.current.input.focus();
    this._isScroll = false;
  };
  private _onScrollOptionsDebounce = _.debounce(this._onScrollOptions, 200);

  render() {
    const {
      options,
      optionKey,
      optionLabel,
      renderItem,
      placeholder = 'Search for ...',
      noData = 'No Data',
      isLoading = false,
    } = this.props;
    const { isShowOption, selected } = this.state;
    return (
      <>
        <Input
          key={'input-search'}
          ref={this.refInput}
          placeholder={selected == null || !isShowOption ? placeholder : optionLabel(selected)}
          value={
            isShowOption ? this._params.textSearch : !isShowOption && selected != null ? optionLabel(selected) : ''
          }
          size='large'
          onChange={(e) => this._onTextSearch(e.target.value)}
          onFocus={(e) => this._onFocusTextSearch(e.target.value)}
          onBlur={() => this._onBlurTextSearchDebounce()}
          prefix={<Icon type='search' className='text-color-tertiary pr-x-small' />}
          suffix={
            !isLoading ? (
              <Icon
                type='caret-down'
                className='text-color-tertiary'
                onClick={() => {
                  if (this.state.isShowOption) {
                    this._isScroll = false;
                    this._onBlurTextSearch();
                  } else {
                    this.refInput.current.input.focus();
                  }
                }}
              />
            ) : (
              <Icon type='loading' className='text-color-tertiary' />
            )
          }
          style={{ width: '100%' }}
          className='mb-x2-small'
          allowClear={false}
        />
        {!isShowOption ? null : options.length > 0 ? (
          <div
            className='overflow-y-scroll bordered shadow-container'
            id='scroll-modal'
            style={{ maxHeight: '350px', overflowY: 'auto', overflowX: 'hidden' }}
            onScroll={() => {
              this._isScroll = true;
              this._onScrollOptionsDebounce();
            }}
          >
            <InfiniteScroll
              hasMore={options.length >= this._params.page * this._params.pageSize}
              loadMore={this._onLoadMore}
            >
              {options.map((e, index) => {
                const key = optionKey(e, index);
                const isSelected = selected != null && key === optionKey(selected);
                return (
                  <div
                    key={optionKey(e, index) || index}
                    className={`flex-row align-center pv-small ph-medium pl-12 hover-bg-tertiary cursor-pointer ${
                      isSelected ? 'bg-blue-action-lightest' : ''
                    }`}
                    onMouseDown={() => this._onSelected(key, e)}
                  >
                    {renderItem({ item: e, index, options })}
                    {isSelected && <Icon type='check' className='text-color-blue-lighter' />}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        ) : (
          !isLoading &&
          !Utils.isEmpty(this._params.textSearch) && (
            <Text className='bordered shadow-container text-align-center pv-12'>{noData}</Text>
          )
        )}
      </>
    );
  }
}
export { SelectInline };
