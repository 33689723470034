import React, { forwardRef, useImperativeHandle } from 'react';
import PlacesAutocomplete, { PropTypes as PlacesAutocompletePropTypes } from 'react-places-autocomplete';
import { Anchor, Autocomplete, Button, Loader, Popover, Skeleton, Text } from '@good/ui/core';
import { Pin, Search } from '@good/icons';

import { useGoogleMapsApi } from 'providers/google-maps-api-provider';
import { getPlaceDataByPlaceId } from './get-place-data-by-place-id';
import { getPlaceDataByAddress } from './get-place-data-by-address';
import { Address, AddressInputFieldErrors, FieldUnionProps, ValidationRules } from './types';
import { placeDataToAddress } from './place-data-to-address';
import { AddressInputManual } from './address-input-manual';
import {
  useErrors,
  useHandleAutoInputError,
  useHasValidAutoInputResult,
  useInputAndAddress,
  useManualInput,
  usePopoverMessage,
  useValidate,
} from './hooks';
import { defaultValidationRules, emptyAddress, placesAutocompleteSearchOptionsAU } from './defaults';
import { ClearInputButton } from './clear-input-button';

export type AddressInputProps = {
  /**
   * Message to show when address was updated by autocomplete
   * @default 'Address was updated. Please double check it is correct.'
   **/
  autocompletePopoverMessage?: string;
  /**
   * the geocoder component to restrict autocompletion results to when the field is blurred
   * @default { country: 'AU' }
   **/
  autocompleteGeoComponentRestrictions?: google.maps.GeocoderComponentRestrictions | null | undefined;
  /**
   * Address input default value
   **/
  defaultValue?: Partial<Address>;
  /**
   * Hides the "Enter address manually" button
   *
   * When hidden use @isManualInput to control the desired state
   **/
  hideManualInputButton?: boolean;
  /**
   * Controls the manual input state
   **/
  isManualInput?: boolean;
  /**
   * Address change
   **/
  onChange?: (addressInput: Address) => void;
  /**
   * callback to subscribe to error changes
   **/
  onChangeErrors?: (errors: AddressInputFieldErrors, hasError?: boolean) => void;
  /**
   * Manual input change
   **/
  onChangeManualInput?: (isManualInput: boolean) => void;
  /**
   * Props passed to the react-places-autocomplete component
   **/
  placesAutocompleteProps?: Omit<PlacesAutocompletePropTypes, 'children'>;
  /**
   * validation rules to run on blur and on validate
   * @example { 'manualInput.streetAddress1': { requiredRule: (value) => value === '' ? 'Address line 1 is required' : true} }
   **/
  validationRules?: Partial<ValidationRules>;
  /**
   * Address input value to be controlled by parent
   **/
  value?: Address | null;
  /**
   * Width of the autocomplete and manual input form
   * @default 500px
   **/
  width?: React.CSSProperties['width'];
} & FieldUnionProps;

export type AddressInputRef = {
  /**
   * the current value of the address input
   **/
  address: Address | null;
  /**
   * list of validation errors
   **/
  errors: AddressInputFieldErrors;
  /**
   * boolean to indicate if there are any validation errors
   **/
  hasErrors: boolean;
  /**
   * trigger validation of entire address input or a specific field
   **/
  validate: (fieldName?: keyof AddressInputFieldErrors) => { errors: AddressInputFieldErrors; hasErrors: boolean };
};

export const AddressInput = forwardRef((props: AddressInputProps, ref: React.Ref<AddressInputRef>) => {
  const {
    autocompletePopoverMessage = 'Address was updated. Please double check it is correct.',
    autocompleteGeoComponentRestrictions = {
      country: 'AU',
    },
    defaultValue,
    hideManualInputButton,
    isManualInput: isManualInputControlled,
    onChange: onChangeProp,
    onChangeErrors: onChangeErrorsProp,
    onChangeManualInput: onChangeManualInputProp,
    placesAutocompleteProps,
    validationRules = defaultValidationRules,
    value: controlledValue,
    width = 500,
    ...fieldProps
  } = props;
  const { isReady } = useGoogleMapsApi();
  const { errors, addInternalError, clearInternalErrors, hasErrors } = useErrors(onChangeErrorsProp);
  const handleError = useHandleAutoInputError(addInternalError);
  const { inputValues, onChangeInputValue, address, onChangeAddress, resetValues } = useInputAndAddress(
    controlledValue,
    defaultValue,
    onChangeProp,
    clearInternalErrors,
  );
  const { hidePopoverMessage, popoverMessage, showPopoverMessage } = usePopoverMessage();
  const { isManualInput, toggleOffManualInput, toggleOnManualInput } = useManualInput(
    isManualInputControlled,
    onChangeManualInputProp,
    resetValues,
    clearInternalErrors,
  );
  const hasValidAutoInputResult = useHasValidAutoInputResult(address);
  const validate = useValidate(
    addInternalError,
    clearInternalErrors,
    inputValues,
    validationRules,
    isManualInput,
    hasValidAutoInputResult,
  );
  useImperativeHandle(ref, () => ({ address, validate, errors, hasErrors }));

  const onOptionSubmit = async (placeId: string) => {
    try {
      const placeData = await getPlaceDataByPlaceId(placeId);
      const address = placeDataToAddress(placeData);
      onChangeAddress(address);
    } catch (e) {
      handleError(e, 'onOptionSubmit');
    }
  };

  const onBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const { automaticInput: searchAddress } = inputValues;
    const isAddressEmpty = searchAddress === '' || searchAddress === undefined;

    if (hasValidAutoInputResult || isAddressEmpty) {
      validate('automaticInput');
      fieldProps.onBlur?.(event);
      return;
    }

    try {
      const placeData = await getPlaceDataByAddress(searchAddress, autocompleteGeoComponentRestrictions);
      const address = placeDataToAddress(placeData);
      onChangeAddress(address);
      showPopoverMessage(autocompletePopoverMessage);
    } catch (e) {
      handleError(e, 'onBlur');
    } finally {
      fieldProps.onBlur?.(event);
    }
  };

  if (!isReady) {
    return (
      <Skeleton visible data-testid='loading-skeleton'>
        <div className='flex flex-col align-start'>
          <Autocomplete {...fieldProps} />
          <Button variant='link'>...</Button>
        </div>
      </Skeleton>
    );
  }

  if (isManualInput) {
    return (
      <AddressInputManual
        inputValues={inputValues}
        onChangeInputValue={onChangeInputValue}
        errors={errors}
        hideManualInputButton={hideManualInputButton}
        toggleOffManualInput={toggleOffManualInput}
        width={width}
        validate={validate}
        {...fieldProps}
      />
    );
  }

  return (
    <div className='flex flex-col align-start gap-2' style={{ width }}>
      <PlacesAutocomplete
        value={inputValues.automaticInput}
        onChange={(address) => {
          clearInternalErrors('automaticInput');
          onChangeInputValue('automaticInput', address);
        }}
        searchOptions={placesAutocompleteSearchOptionsAU}
        {...placesAutocompleteProps}
        debounce={0}
      >
        {({ getInputProps, suggestions, loading }) => {
          const { onChange: placesAutocompleteOnChange, ...inputProps } = getInputProps();
          return (
            <Popover opened={popoverMessage !== undefined} withArrow onClose={hidePopoverMessage}>
              <Popover.Target>
                <Autocomplete
                  {...inputProps}
                  onChange={(value: string) => {
                    hidePopoverMessage();
                    onChangeAddress(emptyAddress);
                    placesAutocompleteOnChange({ target: { value } });
                  }}
                  value={inputValues.automaticInput}
                  onOptionSubmit={onOptionSubmit}
                  leftSection={
                    loading ? (
                      <Loader size='1rem' />
                    ) : (
                      <Text className='text-weak' size={fieldProps.size}>
                        <Search />
                      </Text>
                    )
                  }
                  rightSection={
                    <ClearInputButton
                      onClick={() => {
                        if (fieldProps.disabled) return;
                        clearInternalErrors();
                        resetValues();
                      }}
                      size={fieldProps.size}
                    />
                  }
                  placeholder='Search for address'
                  data={suggestions.map((suggestion) => ({
                    value: suggestion.placeId,
                    label: suggestion.description,
                  }))}
                  filter={({ options }) => options}
                  error={errors.automaticInput?.message}
                  name='automaticInput'
                  {...fieldProps}
                  className={[fieldProps.className, 'w-full'].join(' ')}
                  onBlur={onBlur}
                />
              </Popover.Target>
              <Popover.Dropdown style={{ pointerEvents: 'none' }}>
                <Text size='sm'>{popoverMessage}</Text>
              </Popover.Dropdown>
            </Popover>
          );
        }}
      </PlacesAutocomplete>
      {!hideManualInputButton && (
        <Anchor
          component='button'
          onClick={() => {
            if (fieldProps.disabled) return;
            toggleOnManualInput();
          }}
          size='sm'
          className='focus:outline-none focus:underline'
          type='button'
        >
          <span className='flex gap-1 align-center'>
            <Text>
              <Pin />
            </Text>
            <Text>Enter address manually</Text>
          </span>
        </Anchor>
      )}
    </div>
  );
});

AddressInput.displayName = 'AddressInput';
