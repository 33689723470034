import styled from 'styled-components';

export type FieldProps = {
  /** @default 'fit-content' */
  width?: string;
};

/**
 * @name
 * Field
 *
 * @description
 * Form Field layout
 *
 * @example
 * <Field width="33%">
 *   <Forms.TextField ... />
 * </Field>
 */
export const Field = styled.div<{ width?: string }>((props) => {
  let { width } = props;

  return {
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
    width: width ?? 'fit-content',
    '@media (max-width: 768px)': {
      minWidth: '100%',
    },
  };
});
