/* eslint-disable */
import moment from 'moment';
import { IFilter } from 'interfaces/filter-interfaces';

import { ROSTER_DISPLAY_MODE, ROSTER_TAB } from 'views/bookings/roster-view/common/roster-common-enums';
import { FilterType, ServiceType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';

// TODO : Should move this somewhere else

//region Roster view default filters
const ROSTER_VIEW_DEFAULT_DATE_FILTER = {
  filter: FilterType.DATE_RANGE,
  values: [moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate()],
  selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
    moment().startOf('isoWeek'),
    moment().endOf('isoWeek'),
  ]),
};

const ROSTER_VIEW_DEFAULT_VIEW_FILTERS: IFilter[] = [
  { filter: 'sort', values: [['startDateTime', 'asc']] },

  {
    filter: FilterType.CUSTOMER,
    values: [],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName,
  },

  {
    filter: FilterType.WORKER,
    values: [],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName,
  },
  {
    filter: FilterType.SHIFT_SLOT_STATUS,
    values: [],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.SHIFT_SLOT_STATUS).fullSelectionName,
  },
  {
    filter: FilterType.SERVICE,
    values: [],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.SERVICE_TYPE).fullSelectionName,
  },
  {
    filter: FilterType.SERVICE_TYPE,
    values: [],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.SERVICE_TYPE).fullSelectionName,
  },
];
//endregion

const rosterStore = {
  // -----•===== STATE =====•-----
  state: {
    //UI State
    filters: ROSTER_VIEW_DEFAULT_VIEW_FILTERS,
    dateFilter: ROSTER_VIEW_DEFAULT_DATE_FILTER,

    selectedTab: ROSTER_TAB.WEEKLY,
    selectedDate: new Date(),
    selectedMode: ROSTER_DISPLAY_MODE.TEAM_MEMBERS,

    // Everything below is obsolete.
    // isWeeklyFetching: false,

    // Weekly states
    // weeklyBookingsList: [],
    // weeklyShiftsList: [],
    // weeklyOpenShiftsList: [],

    // weeklyBookingsPage: 0,
    // weeklyBookingsHasMore: true,

    // weeklyShiftsPage: 0,
    // weeklyShiftsHasMore: true,

    // weeklyOpenShiftsPage: 0,
    // weeklyOpenShiftsHasMore: true,

    // Daily states
    // isDailyFetching: false,

    // dailyBookingsList: [],
    // dailyShiftsList: [],
    // dailyOpenShiftsList: [],

    // dailyBookingsPage: 0,
    // dailyBookingsHasMore: true,

    // dailyShiftsPage: 0,
    // dailyShiftsHasMore: true,

    // dailyOpenShiftsPage: 0,
    // dailyOpenShiftsHasMore: true,
  },

  // -----•===== REDUCERS =====•-----
  reducers: {
    // data states
    // setDailyBookingsList: (state, payload) => ({ ...state, dailyBookingsList: payload }),
    // setWeeklyBookingsList: (state, payload) => ({ ...state, weeklyBookingsList: payload }),
    // appendWeeklyBookingsList: (state, payload) => ({
    //   ...state,
    //   weeklyBookingsList: [...state.weeklyBookingsList, ...payload]
    // }),

    // setDailyShiftsList: (state, payload) => ({ ...state, dailyShiftsList: payload }),
    // setWeeklyShiftsList: (state, payload) => ({ ...state, weeklyShiftsList: payload }),
    // appendWeeklyShiftsList: (state, payload) => ({
    //   ...state,
    //   weeklyShiftsList: [...state.weeklyShiftsList, ...payload]
    // }),

    // setWeeklyOpenShiftsList: (state, payload) => ({ ...state, weeklyOpenShiftsList: payload }),
    // appendWeeklyOpenShiftsList: (state, payload) => ({
    //   ...state,
    //   weeklyOpenShiftsList: [...state.weeklyOpenShiftsList, ...payload]
    // }),

    // setDailyBookingsList: (state, payload) => ({ ...state, dailyBookingsList: payload }),
    // appendDailyBookingsList: (state, payload) => ({
    //   ...state,
    //   dailyBookingsList: [...state.dailyBookingsList, ...payload]
    // }),

    // setDailyShiftsList: (state, payload) => ({ ...state, dailyShiftsList: payload }),
    // appendDailyShiftsList: (state, payload) => ({
    //   ...state,
    //   dailyShiftsList: [...state.dailyShiftsList, ...payload]
    // }),

    // setDailyOpenShiftsList: (state, payload) => ({ ...state, dailyOpenShiftsList: payload }),
    // appendDailyOpenShiftsList: (state, payload) => ({
    //   ...state,
    //   dailyOpenShiftsList: [...state.dailyOpenShiftsList, ...payload]
    // }),

    // app view states
    // Filters
    setViewFilters: (state, payload) => ({ ...state, filters: payload }),
    setDateFilter: (state, payload) => ({ ...state, dateFilter: payload }),

    // Navigational state
    setSelectedTab: (state, payload) => ({ ...state, selectedTab: payload }),
    setSelectedDate: (state, payload) => ({ ...state, selectedDate: payload }),
    setSelectedMode: (state, payload) => ({ ...state, selectedMode: payload }),

    // setIsWeeklyFetching: (state, payload) => ({ ...state, isWeeklyFetching: payload }),
    // setIsDailyFetching: (state, payload) => ({ ...state, isDailyFetching: payload }),

    // Pages & hasMore
    // ---=== Weekly ===---
    // setWeeklyBookingsPage: (state, payload) => ({ ...state, weeklyBookingsPage: payload }),
    // setWeeklyBookingsHasMore: (state, payload) => ({ ...state, weeklyBookingsHasMore: payload }),

    // setWeeklyShiftsPage: (state, payload) => ({ ...state, weeklyShiftsPage: payload }),
    // setWeeklyShiftsHasMore: (state, payload) => ({ ...state, weeklyShiftsHasMore: payload }),

    // setWeeklyOpenShiftsPage: (state, payload) => ({ ...state, weeklyOpenShiftsPage: payload }),
    // setWeeklyOpenShiftsHasMore: (state, payload) => ({ ...state, weeklyOpenShiftsHasMore: payload }),

    // Resets & clears
    // resetWeeklyBookingCounter: (state) => ({ ...state, weeklyBookingsPage: 0, weeklyBookingsHasMore: true }),
    // resetWeeklyShiftCounter: (state) => ({
    //   ...state,
    //   weeklyShiftsPage: 0,
    //   weeklyShiftsHasMore: true,
    //   weeklyOpenShiftsPage: 0,
    //   weeklyOpenShiftsHasMore: true
    // }),
    // resetAllWeeklyCounters: (state) => ({
    //   ...state,
    //   weeklyBookingsPage: 0,
    //   weeklyBookingsHasMore: true,
    //   weeklyShiftsPage: 0,
    //   weeklyShiftsHasMore: true,
    //   weeklyOpenShiftsPage: 0,
    //   weeklyOpenShiftsHasMore: true
    // }),

    // clearWeeklyBookings: (state) => ({ ...state, weeklyBookingsList: [] }),
    // clearWeeklyShifts: (state) => ({ ...state, weeklyShiftsList: [] }),
    // clearWeeklyOpenShifts: (state) => ({ ...state, weeklyOpenShiftsList: [] }),
    // clearWeeklyAll: (state) => ({ ...state, weeklyBookingsList: [], weeklyShiftsList: [], weeklyOpenShiftsList: [] }),

    // ---=== Daily ===---
    // setDailyBookingsPage: (state, payload) => ({ ...state, dailyBookingsPage: payload }),
    // setDailyBookingsHasMore: (state, payload) => ({ ...state, dailyBookingsHasMore: payload }),

    // setDailyShiftsPage: (state, payload) => ({ ...state, dailyShiftsPage: payload }),
    // setDailyShiftsHasMore: (state, payload) => ({ ...state, dailyShiftsHasMore: payload }),

    // setDailyOpenShiftsPage: (state, payload) => ({ ...state, dailyOpenShiftsPage: payload }),
    // setDailyOpenShiftsHasMore: (state, payload) => ({ ...state, dailyOpenShiftsHasMore: payload }),

    // resetDailyBookingCounter: (state) => ({ ...state, dailyBookingsPage: 0, dailyBookingsHasMore: true }),
    // resetDailyShiftCounter: (state) => ({
    //   ...state,
    //   dailyShiftsPage: 0,
    //   dailyShiftsHasMore: true,
    //   dailyOpenShiftsPage: 0,
    //   dailyOpenShiftsHasMore: true
    // }),
    // resetAllDailyCounters: (state) => ({
    //   ...state,
    //   dailyBookingsPage: 0,
    //   dailyBookingsHasMore: true,
    //   dailyShiftsPage: 0,
    //   dailyShiftsHasMore: true,
    //   dailyOpenShiftsPage: 0,
    //   dailyOpenShiftsHasMore: true
    // }),
    //
    // clearDailyBookings: (state) => ({ ...state, dailyBookingsList: [] }),
    // clearDailyShifts: (state) => ({ ...state, dailyShiftsList: [] }),
    // clearDailyOpenShifts: (state) => ({ ...state, dailyOpenShiftsList: [] }),
    // clearDailyAll: (state) => ({ ...state, dailyBookingsList: [], dailyShiftsList: [], dailyOpenShiftsList: [] })
  },

  // -----•===== EFFECTS =====•-----
  effects: (dispatch) => ({
    // async doFetchWeeklyBookings(payload, rootState) {
    //   if (rootState.rosterStore.weeklyBookingsHasMore) {
    //     dispatch.rosterStore.setIsWeeklyFetching(true);
    //
    //     const nextPage = rootState.rosterStore.weeklyBookingsPage + 1;
    //
    //     let requestPayload = formatBookingListingFilters(payload);
    //
    //     requestPayload.shiftSlotStatus = requestPayload.workerStatus;
    //
    //     console.log('fetch next weekly view: bookings');
    //
    //     try {
    //       let result = await apiClient.post(`/api/portal/bookings/list`, {
    //         ...requestPayload,
    //         pageSize: PAGE_SIZE,
    //         page: nextPage,
    //         sort: [['customerFirstName', 'asc'], ['startDateTime', 'asc']]
    //       });
    //
    //       if (result && result.data) {
    //         const bookings = result.data;
    //
    //         if (_.size(bookings) === 0) {
    //           dispatch.rosterStore.setWeeklyBookingsHasMore(false);
    //         } else {
    //           dispatch.rosterStore.setWeeklyBookingsPage(nextPage);
    //           dispatch.rosterStore.appendWeeklyBookingsList(bookings);
    //         }
    //       }
    //
    //       dispatch.rosterStore.setIsWeeklyFetching(false);
    //     } catch (e) {
    //       dispatch.rosterStore.setIsWeeklyFetching(false);
    //       throw e;
    //     }
    //   } else {
    //     // do nothing...?
    //     console.log('no more bookings');
    //   }
    // },
    // async doFetchWeeklyShifts(payload, rootState) {
    //   if (rootState.rosterStore.weeklyShiftsHasMore) {
    //     dispatch.rosterStore.setIsWeeklyFetching(true);
    //     const nextPage = rootState.rosterStore.weeklyShiftsPage + 1;
    //
    //     console.log(payload);
    //
    //     let requestPayload = formatBookingListingFilters(payload);
    //
    //     try {
    //       let result = await apiClient.post(`/api/portal/support-worker/shift-slot/list`, {
    //         ...requestPayload,
    //         pageSize: PAGE_SIZE,
    //         page: nextPage,
    //         showOnlyAssigned: true,
    //         sort: [['workerFirstName', 'asc'], ['startDateTime', 'asc']]
    //       });
    //
    //       if (result && result.data) {
    //         const shifts = result.data;
    //
    //         if (_.size(shifts) === 0) {
    //           dispatch.rosterStore.setWeeklyShiftsHasMore(false);
    //         } else {
    //           dispatch.rosterStore.setWeeklyShiftsPage(rootState.rosterStore.weeklyShiftsPage + 1);
    //           dispatch.rosterStore.appendWeeklyShiftsList(shifts);
    //         }
    //       }
    //
    //       dispatch.rosterStore.setIsWeeklyFetching(false);
    //     } catch (e) {
    //       dispatch.rosterStore.setIsWeeklyFetching(false);
    //       throw e;
    //     }
    //   } else {
    //     // do nothing...?
    //     console.log('no more shifts');
    //   }
    // },
    // async doFetchWeeklyOpenShifts(payload, rootState) {
    //   if (rootState.rosterStore.weeklyOpenShiftsHasMore) {
    //     dispatch.rosterStore.setIsWeeklyFetching(true);
    //     const nextPage = rootState.rosterStore.weeklyOpenShiftsPage + 1;
    //     let requestPayload = formatBookingListingFilters(payload);
    //     // requestPayload.shiftSlotStatus = requestPayload.workerStatus;
    //
    //     console.log('fetch weekly view : Open Shifts');
    //
    //     try {
    //       let result = await apiClient.post(`/api/portal/support-worker/shift-slot/list`, {
    //         ...requestPayload,
    //         pageSize: OPEN_SHIFTS_SIZE,
    //         showOnlyAssigned: false,
    //         shiftSlotStatus: ['UNASSIGNED'],
    //         sort: [['startDateTime', 'asc']],
    //         page: nextPage
    //       });
    //
    //       if (result && result.data) {
    //         const shifts = result.data;
    //
    //         if (_.size(shifts) === 0) {
    //           dispatch.rosterStore.setWeeklyOpenShiftsHasMore(false);
    //         } else {
    //           dispatch.rosterStore.setWeeklyOpenShiftsPage(nextPage);
    //           dispatch.rosterStore.appendWeeklyOpenShiftsList(shifts);
    //         }
    //       }
    //
    //       dispatch.rosterStore.setIsWeeklyFetching(false);
    //     } catch (e) {
    //       dispatch.rosterStore.setIsWeeklyFetching(false);
    //       throw e;
    //     }
    //   }
    // },
    // async doFetchDailyBookings(payload, rootState) {
    //   if (rootState.rosterStore.dailyBookingsHasMore) {
    //     dispatch.rosterStore.setIsDailyFetching(true);
    //
    //     const nextPage = rootState.rosterStore.dailyBookingsPage + 1;
    //
    //     let requestPayload = formatBookingListingFilters(payload);
    //
    //     requestPayload.shiftSlotStatus = requestPayload.workerStatus;
    //
    //     console.log('fetch daily view : Bookings');
    //
    //     try {
    //       let result = await apiClient.post(`/api/portal/bookings/list`, {
    //         ...requestPayload,
    //         pageSize: PAGE_SIZE,
    //         page: nextPage,
    //         sort: [['customerFirstName', 'asc'], ['startDateTime', 'asc']]
    //       });
    //
    //       if (result && result.data) {
    //         const bookings = result.data;
    //
    //         if (_.size(bookings) === 0) {
    //           dispatch.rosterStore.setDailyBookingsHasMore(false);
    //         } else {
    //           dispatch.rosterStore.setDailyBookingsPage(nextPage);
    //           dispatch.rosterStore.appendDailyBookingsList(bookings);
    //         }
    //       }
    //
    //       dispatch.rosterStore.setIsDailyFetching(false);
    //     } catch (e) {
    //       dispatch.rosterStore.setIsDailyFetching(false);
    //       throw e;
    //     }
    //   } else {
    //     // do nothing...?
    //     console.log('no more bookings');
    //   }
    // },
    // async doFetchDailyShifts(payload, rootState) {
    //   if (rootState.rosterStore.dailyShiftsHasMore) {
    //     dispatch.rosterStore.setIsDailyFetching(true);
    //
    //     const nextPage = rootState.rosterStore.dailyShiftsPage + 1;
    //
    //     let requestPayload = formatBookingListingFilters(payload);
    //
    //     try {
    //       let result = await apiClient.post(`/api/portal/support-worker/shift-slot/list`, {
    //         ...requestPayload,
    //         pageSize: PAGE_SIZE,
    //         page: nextPage,
    //         showOnlyAssigned: true,
    //         sort: [['workerFirstName', 'asc'], ['startDateTime', 'asc']]
    //       });
    //
    //       if (result && result.data) {
    //         const shifts = result.data;
    //
    //         if (_.size(shifts) === 0) {
    //           dispatch.rosterStore.setDailyShiftsHasMore(false);
    //         } else {
    //           dispatch.rosterStore.setDailyShiftsPage(nextPage);
    //           dispatch.rosterStore.appendDailyShiftsList(shifts);
    //         }
    //       }
    //
    //       dispatch.rosterStore.setIsDailyFetching(false);
    //     } catch (e) {
    //       dispatch.rosterStore.setIsDailyFetching(false);
    //       throw e;
    //     }
    //   } else {
    //     // do nothing...?
    //     console.log('no more shifts');
    //   }
    // },
    // async doFetchDailyOpenShifts(payload, rootState) {
    //   if (rootState.rosterStore.dailyOpenShiftsHasMore) {
    //     dispatch.rosterStore.setIsDailyFetching(true);
    //     const nextPage = rootState.rosterStore.dailyOpenShiftsPage + 1;
    //
    //     let requestPayload = formatBookingListingFilters(payload);
    //
    //     // console.log('fetch weekly view : Open Shifts');
    //     console.log('fetch daily view : Open Shifts');
    //
    //     try {
    //       let result = await apiClient.post(`/api/portal/support-worker/shift-slot/list`, {
    //         ...requestPayload,
    //         pageSize: OPEN_SHIFTS_SIZE,
    //         showOnlyAssigned: false,
    //         shiftSlotStatus: ['UNASSIGNED'],
    //         sort: [['startDateTime', 'asc']],
    //         page: nextPage
    //       });
    //
    //       if (result && result.data) {
    //         const shifts = result.data;
    //
    //         if (_.size(shifts) === 0) {
    //           dispatch.rosterStore.setDailyOpenShiftsHasMore(false);
    //         } else {
    //           dispatch.rosterStore.setDailyOpenShiftsPage(nextPage);
    //           dispatch.rosterStore.appendDailyOpenShiftsList(shifts);
    //         }
    //       }
    //
    //       dispatch.rosterStore.setIsDailyFetching(false);
    //     } catch (e) {
    //       dispatch.rosterStore.setIsDailyFetching(false);
    //       throw e;
    //     }
    //   }
    // }
  }),
};

export default rosterStore;
