import { UseFormReturn } from 'react-hook-form';

import { EditRatiosSchema } from '../../schema';
import { DurationErrors } from '../../types';

export function setDurationErrors(errors: DurationErrors, form: UseFormReturn<EditRatiosSchema>) {
  const ratios = form.getValues().ratios;
  if (errors.durationExceedsSessionLength) {
    ratios.forEach((_, index) => {
      form.setError(`ratios.${index}.durationRatio.duration`, {
        type: 'custom',
        message: 'Duration is invalid',
        types: {
          durationExceedsSessionLength: 'Total duration exceeds session length.',
        },
      });
    });
  } else {
    ratios.forEach((_, index) => {
      form.clearErrors(`ratios.${index}.durationRatio.duration`);
    });
  }
}
