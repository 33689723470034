import {
  InsightsSummaryFilters,
  InsightsSummaryResponse,
  InsightsTableFilters,
  InsightsTableRequest,
  InsightsTableResponse,
} from 'views/insights/insights';
import { getRequest } from '../typed-api-wrapper';
import fileDownload from 'js-file-download';
import { parse } from 'json2csv/dist/json2csv.umd';

type InsightsSummaryRequest = InsightsSummaryFilters;

export const fetchInsightsSummary = async (request: InsightsSummaryRequest): Promise<InsightsSummaryResponse> => {
  const parameters: URLSearchParams = new URLSearchParams(request);
  return await getRequest<InsightsSummaryResponse>({
    path: '/api/portal/support-worker/insights-summary',
    parameters,
  });
};

export const fetchInsightsTableData = async (request: InsightsTableRequest): Promise<InsightsTableResponse> => {
  const withoutServiceIds: URLSearchParams = new URLSearchParams({
    startDate: request.startDate.toISOString(),
    endDate: request.endDate.toISOString(),
    page: request.page.toString(),
  });

  // The limit may be undefined, so here we want to just not include it at all and let the API decide on its default limit.
  if (request.limit) {
    withoutServiceIds.append('limit', request.limit.toString());
  }

  // searchByName may be null. When constructed into URLSearchParams, this results in 'null' being sent to the API as the search string.
  // It needs to just not be included at all.
  if (request.searchByName) {
    withoutServiceIds.append('searchByName', request.searchByName);
  }

  // Passing an array into URL params requires appending the same key to the params repeatedly.
  const parameters: URLSearchParams =
    request.serviceIds?.reduce((params, id) => {
      params.append('serviceIds', id);
      return params;
    }, withoutServiceIds) ?? withoutServiceIds;

  return await getRequest<InsightsTableResponse>({
    path: '/api/portal/support-worker/insights',
    parameters,
  });
};

export const fetchExportInsightsData = async (request: InsightsTableFilters) => {
  const withoutServiceIds: URLSearchParams = new URLSearchParams({
    startDate: request.startDate.toISOString(),
    endDate: request.endDate.toISOString(),
  });

  const parameters: URLSearchParams =
    request.serviceIds?.reduce((params, id) => {
      params.append('serviceIds', id);
      return params;
    }, withoutServiceIds) ?? withoutServiceIds;

  const response = await getRequest({
    path: 'api/portal/support-worker/insights/export',
    parameters: parameters,
  });

  fileDownload(
    parse(response),
    `Team Insights Export ${request.startDate.toLocaleDateString()} to ${request.endDate.toLocaleDateString()}.csv`,
  );
  return true;
};
