import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Form, Icon, Input, notification, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import { australianFlag } from 'assets/Icons/country-flags';
import { PaymentSourceType } from 'utilities/enum-utils';

interface IAssignNewPrimaryOwnerProps extends FormComponentProps {
  isOpen: any;
  onClose: () => void;
  doEditNDISConfig: typeof dispatch.companyStore.doEditNDISConfig;
  companyFundingAndPayment: typeof state.companyStore.companyFundingAndPayment;
}

interface IAssignNewPrimaryOwnerState {
  title: any;
  isCreated: boolean;
}

class EditNDISModal extends Component<IAssignNewPrimaryOwnerProps, IAssignNewPrimaryOwnerState> {
  state = { title: 'National Disability Insurance Scheme (NDIS)', isCreated: false };

  private _onCloseModal = () => {
    this.setState({ title: 'National Disability Insurance Scheme (NDIS)', isCreated: false });
    this.props.onClose();
  };

  private _editNDISConfig = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        await this.props.doEditNDISConfig({
          vcpEnabled: this.props.companyFundingAndPayment
            ? _.find(
                this.props.companyFundingAndPayment.paymentSources,
                (funding) => funding.paymentSourceType === PaymentSourceType.VCP,
              )?.paymentSourceConfiguration?.vcpEnabled
            : null,
          isTtpApproved: form.getFieldValue('isTtpApproved') === 'YES',
          ndisRegistrationNumber: form.getFieldValue('ndisRegistrationNumber'),
        });
        this.setState({
          title: (
            <>
              NDIS funding successfully edited
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isCreated: true,
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == ' ') {
      e.preventDefault();
    }
  };

  render() {
    const { isOpen, form } = this.props;
    const { getFieldDecorator } = form;

    const NDISConfig = this.props.companyFundingAndPayment
      ? _.find(
          this.props.companyFundingAndPayment.paymentSources,
          (funding) => funding.paymentSourceType === PaymentSourceType.NDIS,
        )
      : null;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isCreated ? (
          <>
            <div className="mv-medium anim-slide-left">
              <SubTitle>NDIS provider number</SubTitle>
              <Text size={'x-large'}>
                {NDISConfig ? NDISConfig.paymentSourceConfiguration.ndisRegistrationNumber : '-'}
              </Text>
              <div className={'mt-large'}>
                <SubTitle>TTP approved</SubTitle>
                <Text size={'x-large'}>
                  {NDISConfig ? (NDISConfig.paymentSourceConfiguration.isTtpApproved ? 'YES' : 'NO') : '-'}
                </Text>
              </div>
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Done
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : (
          <>
            <div className="anim-slide-left">
              <div className={'mb-x-large'}>
                <img src={australianFlag} height={'8px'} alt={'Australian National Flag'} className={'mr-x-small'} />
                <Text>Australia</Text>
                <Paragraph className={'mt-small'}>
                  The National Disability Insurance Scheme is a scheme of the Australian government that funds costs
                  associated with disability.
                </Paragraph>
                <div className={'mt-large'}>
                  <SubTitle>NDIS provider number</SubTitle>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('ndisRegistrationNumber', {
                      initialValue: NDISConfig && NDISConfig.paymentSourceConfiguration.ndisRegistrationNumber,
                      rules: [
                        { pattern: new RegExp(/^[0-9]+$/), message: 'Please enter a valid registration number.' },
                      ],
                    })(
                      <Input
                        onKeyDown={this.handleOnKeyDown}
                        placeholder="405 xxx xxx"
                        className="input-ndis-registration"
                        maxLength={30}
                        size="large"
                      />,
                    )}
                  </Form.Item>
                </div>
                <div className={'mt-large'}>
                  <SubTitle>TTP Approved</SubTitle>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('isTtpApproved', {
                      initialValue: NDISConfig && NDISConfig.paymentSourceConfiguration.isTtpApproved ? 'YES' : 'NO',
                    })(
                      <Select size={'large'} style={{ width: '150px' }}>
                        <Select.Option value={'YES'}>Yes</Select.Option>
                        <Select.Option value={'NO'}>No</Select.Option>
                      </Select>,
                    )}
                  </Form.Item>
                </div>
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._editNDISConfig}>
                  Save changes
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPayment: state.companyStore.companyFundingAndPayment,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditNDISConfig: dispatch.companyStore.doEditNDISConfig,
});

export default connect(mapState, mapDispatch)(Form.create<IAssignNewPrimaryOwnerProps>()(EditNDISModal));
