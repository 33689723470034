import React, { Component } from 'react';
import { GridHeader } from 'common-components/grids';
import _ from 'lodash';
import { Col, Empty, Skeleton } from 'antd';
import { Text, FieldLabel } from 'common-components/typography';
import TeamMemberListItem from './TeamMemberListItem';
import * as H from 'history';
import { state } from 'stores/rematch/root-store';

interface IGroupServiceTeamMemberListingSectionProps {
  history: H.History;
  isLoading: boolean;
  filteredGroupServiceWorkerList: typeof state.servicesStore.filteredGroupServiceWorkerList;
  onOpenRemoveTeamMemberModal: (i) => void;
  onOpenTeamMemberScheduleModal: (i) => void;
}

const TeamMemberEmptyState = () => (
  <div className="mt-large mh-large mb-x2-large align-center flex-column">
    <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mt-x4-large mb-small" />
    <div className="text-align-center" style={{ width: '365px' }}>
      <Text size="x2-large" color="secondary" weight="bold">
        No team members found.
      </Text>
      <br />
      <Text color="secondary">You can add a new team member to this roster with the Add to Roster button above.</Text>
    </div>
  </div>
);

class GroupServiceTeamMemberListingSection extends Component<IGroupServiceTeamMemberListingSectionProps> {
  render() {
    const { filteredGroupServiceWorkerList } = this.props;

    return (
      <div className="height-full bg-white mt-medium" style={{ overflow: 'hidden' }}>
        <GridHeader bordered containerClassName="border-width-small border-secondary">
          <Col span={6} className=" bg-white">
            <FieldLabel text="TEAM MEMBER" />
          </Col>
          <Col span={6} className=" bg-white">
            <FieldLabel text="UPCOMING SHIFTS" />
          </Col>
          <Col span={6} className=" bg-white">
            <FieldLabel text="COMPLETED SHIFTS" />
          </Col>
          <Col span={4} className=" bg-white">
            <FieldLabel text="ACTIVITY GROUPS" />
          </Col>
          <Col span={2} className=" bg-white">
            <FieldLabel text=" " />
          </Col>
        </GridHeader>

        {this.props.isLoading ? (
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
        ) : _.isEmpty(filteredGroupServiceWorkerList) ? (
          <TeamMemberEmptyState />
        ) : (
          _.map(filteredGroupServiceWorkerList, (member, index) => (
            <TeamMemberListItem
              key={index}
              teamMemberItem={member}
              history={this.props.history}
              onOpenRemoveTeamMemberModal={this.props.onOpenRemoveTeamMemberModal}
              onOpenTeamMemberScheduleModal={this.props.onOpenTeamMemberScheduleModal}
            />
          ))
        )}
      </div>
    );
  }
}

export default GroupServiceTeamMemberListingSection;
