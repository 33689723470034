import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ArrowTopRight = forwardRef(function ArrowTopRight(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65 6a.75.75 0 0 1 .75-.75H18a.75.75 0 0 1 .75.75v9.6a.75.75 0 0 1-1.5 0V7.81L6.53 18.53a.75.75 0 0 1-1.06-1.06L16.19 6.75H8.4A.75.75 0 0 1 7.65 6Z"
        fill="currentColor"
      />
    </Icon>
  );
});
