import React from 'react';

import { Button } from 'antd';

import { ROSTER_DISPLAY_MODE } from 'views/bookings/roster-view/common/roster-common-enums';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

export function DisplayModeSelector({
  selectedMode,
  setSelectedMode,
}: {
  selectedMode: ROSTER_DISPLAY_MODE;
  setSelectedMode: any;
}) {
  const CustomersButton = selectedMode === ROSTER_DISPLAY_MODE.CUSTOMERS ? PrimaryButton : SecondaryButton;
  const TeamMembersButton = selectedMode === ROSTER_DISPLAY_MODE.TEAM_MEMBERS ? PrimaryButton : SecondaryButton;
  const AllButton = selectedMode === ROSTER_DISPLAY_MODE.ALL ? PrimaryButton : SecondaryButton;

  return (
    <Button.Group>
      <TeamMembersButton onClick={() => setSelectedMode(ROSTER_DISPLAY_MODE.TEAM_MEMBERS)}>
        Team members
      </TeamMembersButton>

      <CustomersButton onClick={() => setSelectedMode(ROSTER_DISPLAY_MODE.CUSTOMERS)}>Customers</CustomersButton>

      <AllButton onClick={() => setSelectedMode(ROSTER_DISPLAY_MODE.ALL)}>All</AllButton>
    </Button.Group>
  );
}
