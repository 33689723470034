import type { PropsWithChildren } from 'react';
import React from 'react';
import type { BaseStyleProps, HTMLProps } from '../..';
import { compose, css, Text, useStyleProps, Button } from '../..';
import { BackIcon, Cross } from '../../icon';
import * as styles from './dropdown-header.css';

export type DropdownHeaderProps = PropsWithChildren<BaseStyleProps & HTMLProps<HTMLDivElement>> & {
  /**
   * Text label
   */
  label?: string;
  /**
   * Close handle when trigger close button
   */
  onClose?(): void;
  /**
   * Show back button
   */
  isShowBackButton?: boolean;
  /**
   * Show back button
   */
  isShowCloseButton?: boolean;
  /**
   * Action when click back button
   */
  onBack?(): void;
};

/**
 * @name
 * Dropdown Header
 *
 * @example
 * <DropdownHeader label='Label' onClose={()=>{}} />
 */

export const DropdownHeader = (props: DropdownHeaderProps) => {
  const { label, onClose, isShowBackButton, isShowCloseButton, onBack } = props;

  const { styleProps } = useStyleProps(props);

  return (
    <div className={compose(css(styles.reset, styles.dropdownHeader), css(styleProps))}>
      {isShowBackButton && (
        <Button onlyIcon size="xsmall" kind="body" emphasis="outlined" marginRight="$space150" onClick={onBack}>
          <BackIcon width="$size200" height="$size200 !important" color="$neutralDark1" />
        </Button>
      )}
      <Text color="$black" size="small" emphasis="bold" lineHeight="$leading300 !important">
        {label}
      </Text>
      {isShowCloseButton && (
        <Button onlyIcon size="xsmall" kind="body" emphasis="quiet" marginLeft="auto" onClick={onClose}>
          <Cross width="$size200" height="$size200 !important" color="$neutralDark1" />
        </Button>
      )}
    </div>
  );
};
