import React, { useCallback, useEffect, useMemo } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { IconButton, PrimaryButton } from 'common-components/buttons';
import RosterWeekly from 'common-components/roster-control/roster-weekly/RosterWeekly';
import RosterDaily from 'common-components/roster-control/roster-daily/RosterDaily';
import { FilterSection } from 'common-components/filter';

import { bodyHeight } from 'theme/theme-variables';

import { RosterViewSelector } from 'views/bookings/roster-view/common/RosterViewSelector';
import { DaySelector } from 'views/bookings/roster-view/common/DaySelector';
import { RosterLoadingIndicator } from 'views/bookings/roster-view/common/RosterLoadingIndicator';
import { LoaderElement } from 'views/bookings/roster-view/common/LoaderElement';
import { AVAILABLE_FILTERS } from 'views/bookings/roster-view/common/roster-common-config';
import { ROSTER_DISPLAY_MODE, ROSTER_TAB } from 'views/bookings/roster-view/common/roster-common-enums';
import { DisplayModeSelector } from 'views/bookings/roster-view/common/DisplayModeSelector';

import CreateNewBookingModal from 'views/bookings/listings/components/CreateNewBookingModal';

import { useQueryInfiniteShifts } from 'stores/hooks/query-hooks/use-query-infinite-shifts';
import { useQueryInfiniteBookings } from 'stores/hooks/query-hooks/use-query-infinite-bookings';

import { flattenPages } from 'stores/hooks/common/common-hook-utils';

import { IRootDispatch, IRootState } from 'stores/rematch/root-store';

import * as H from 'history';
import { InfiniteQueryObserverResult } from '@tanstack/react-query';
import { PageContainer } from 'layouts/page-container';

interface RosterLandingViewProps {
  history: H.History;
}

const RosterLandingView = ({ history }: RosterLandingViewProps) => {
  // STATE SELECTORS
  const rosterStore = useSelector((state: IRootState) => state.rosterStore);

  const { filters, dateFilter, selectedTab, selectedDate, selectedMode } = rosterStore;

  // DISPATCH ACTIONS
  const {
    rosterStore: rosterDispatch,
    navigationStore: navigationDispatch,
    customersStore: customersDispatch,
  } = useDispatch<IRootDispatch>();

  const { setViewFilters, setSelectedMode, setSelectedTab, setSelectedDate, setDateFilter } = rosterDispatch;
  const { setSelectedSideNavMenuKeys } = navigationDispatch;
  const { doGetCustomer } = customersDispatch;

  // Booking modals states
  const [showBookingModal, setShowBookingModal] = React.useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState<object>({});
  const [selectedCreateDate, setSelectedCreateDate] = React.useState<Date>(new Date());

  // currently selected week
  const selectedWeek: [Date, Date] = useMemo(() => [dateFilter.values[0], dateFilter.values[1]], [dateFilter]);

  // Determines what filter to use. If it's daily, use selectedDate, else use selectedWeek. This is used for the daily/weekly tabs
  const queryDateFilter = useMemo(() => {
    if (selectedTab === ROSTER_TAB.DAILY) {
      return {
        filter: 'startDate',
        values: [moment(selectedDate).startOf('day').toDate(), moment(selectedDate).endOf('day').toDate()],
        selectionLabel: moment(selectedDate).format('dddd, DD MMM YYYY'),
      };
    }

    return {
      filter: 'startDate',
      values: selectedWeek,
      selectionLabel: `${moment(selectedWeek[0]).format('dddd, DD MMM YYYY')} - ${moment(selectedWeek[1]).format(
        'dddd, DD MMM YYYY',
      )}`,
    };
  }, [selectedDate, selectedWeek, selectedTab]);

  const combinedFilters = [...filters, queryDateFilter];

  // ---===[ Queries ]===---

  // SHIFTS
  // Fetch infinite query for UNASSIGNED SHIFTS
  const {
    data: unassignedShiftsData,
    fetchNextPage: fetchNextUnassignedShifts,
    hasNextPage: hasNextUnassignedShifts,
    isFetching: isFetchingNextUnassignedShifts,
    refetch: refetchUnassignedShifts,
    // remove: removeUnassignedShifts
  } = useQueryInfiniteShifts({
    fetchUnassignedShifts: true,
    filters: combinedFilters,
    showOnlyAssigned: false,
    enabled: true,
  });

  // hasNextPage by default is initialised as undefined on page load.
  // Defer loading for unassigned shifts until hasNextPage is an actual boolean value (true/false).
  const shouldFetchShifts = hasNextUnassignedShifts === undefined ? false : !hasNextUnassignedShifts;

  // Fetch infinite query for ASSIGNED SHIFTS
  const {
    data: shiftsData,
    fetchNextPage: fetchNextShifts,
    hasNextPage: hasNextShifts,
    isFetching: isFetchingNextShifts,
    refetch: refetchShifts,
  } = useQueryInfiniteShifts({
    filters: combinedFilters,
    showOnlyAssigned: true,
    enabled: shouldFetchShifts,
  });

  // BOOKINGS
  // fetch query for BOOKINGS
  const {
    data: bookingsData,
    fetchNextPage: fetchNextBookings,
    hasNextPage: hasNextBookings,
    isFetching: isFetchingNextBookings,
    refetch: refetchBookings,
  } = useQueryInfiniteBookings({ filters: combinedFilters, enabled: true });

  // Expanded memo-ised list of shifts/bookings from response.
  const assignedShifts = useMemo(() => flattenPages(shiftsData), [shiftsData]);
  const unassignedShifts = useMemo(() => flattenPages(unassignedShiftsData), [unassignedShiftsData]);
  const bookings = useMemo(() => flattenPages(bookingsData), [bookingsData]);

  // Combined shifts for rendering.
  const shifts = [...unassignedShifts, ...assignedShifts];

  // Has more rows to fetch (depending on which mode is selected)
  const getGeneralStateFromRosterMode = (nextUnassignedShifts: boolean, nextShifts: boolean, nextBookings: boolean) => {
    switch (selectedMode) {
      // If we're checking for everything, return true if there's ANYTHING needed.
      case ROSTER_DISPLAY_MODE.ALL:
        return nextUnassignedShifts || nextShifts || nextBookings;
      // Otherwise, only return values relevant to the roster display mode.
      case ROSTER_DISPLAY_MODE.TEAM_MEMBERS:
        return nextUnassignedShifts || nextShifts;
      case ROSTER_DISPLAY_MODE.CUSTOMERS:
        return nextBookings;
      default:
        return false;
    }
  };

  // If we have any more relevant data available
  const hasMore = getGeneralStateFromRosterMode(hasNextUnassignedShifts, hasNextShifts, hasNextBookings);

  // If there are any relevant queries running
  const isFetching = getGeneralStateFromRosterMode(
    isFetchingNextUnassignedShifts,
    isFetchingNextShifts,
    isFetchingNextBookings,
  );

  // Fetch more data when the user scrolls to the bottom of the list/request a reload / etc. Triggered by loadingElement.
  const fetchMore = async (): Promise<InfiniteQueryObserverResult> => {
    if (
      selectedMode === ROSTER_DISPLAY_MODE.CUSTOMERS ||
      (selectedMode === ROSTER_DISPLAY_MODE.ALL && hasNextBookings)
    ) {
      return fetchNextBookings();
    }

    if (hasNextUnassignedShifts) {
      return fetchNextUnassignedShifts();
    }

    if (hasNextShifts) {
      return fetchNextShifts();
    }
  };

  // Go to a specific date.
  const goToDate = useCallback(
    (targetDate: Date): void => {
      if (targetDate === null) {
        return;
      }

      setSelectedDate(targetDate);

      const startDateFilter = dateFilter.values[0];
      const endDateFilter = dateFilter.values[1];

      const targetMoment = moment(targetDate);
      const isWithin = targetMoment.isSameOrAfter(startDateFilter) && targetMoment.isSameOrBefore(endDateFilter);

      if (!isWithin) {
        const updatedDateFilter = {
          ...dateFilter,
          values: [targetMoment.startOf('isoWeek').toDate(), targetMoment.endOf('isoWeek').toDate()],
        };

        setDateFilter(updatedDateFilter);
      }
    },
    [dateFilter, setDateFilter, setSelectedDate],
  );

  // Go to a specific day and switch to the daily view. Used for handling date header clicks.
  const goToDailyDate = useCallback(
    (date: Date): void => {
      setSelectedTab(ROSTER_TAB.DAILY);
      goToDate(date);
    },
    [goToDate, setSelectedTab],
  );

  // Move to the previous/next week or day, depending on the selected tab
  const navigateByDate = (offset: moment.DurationInputArg1): void => {
    // If we're on the daily tab, we need to move by 1 day as a unit.
    if (selectedTab === ROSTER_TAB.DAILY) {
      goToDate(moment(selectedDate).add(offset, 'day').toDate());
    }

    // If we're on the weekly tab, we need to move by 1 week as a unit.
    if (selectedTab === ROSTER_TAB.WEEKLY) {
      goToDate(
        moment(selectedWeek[0])
          .add(offset, 'week')
          .toDate(),
      );
    }
  };

  // Function to refresh the data
  const refreshData = async (): Promise<void> => {
    if (selectedMode !== ROSTER_DISPLAY_MODE.CUSTOMERS) {
      await refetchUnassignedShifts();
      await refetchShifts();
    }

    if (selectedMode !== ROSTER_DISPLAY_MODE.TEAM_MEMBERS) {
      await refetchBookings();
    }
  };

  // Used to create booking within weekly cells.
  async function createBookingForCustomer({ customerId, startDateTime }: { customerId: string; startDateTime?: Date }) {
    const selectedCustomer = await doGetCustomer({ userId: customerId });
    setSelectedCustomer(selectedCustomer);
    setSelectedCreateDate(startDateTime);
    setShowBookingModal(true);
  }

  useEffect(() => {
    setSelectedSideNavMenuKeys(['/bookings/calendar']);
  }, [setSelectedSideNavMenuKeys]);

  // Refresh the data on close in case of stale or in-flight data
  const closeBookingModal = async () => {
    await refreshData();
    setShowBookingModal(false);
  };

  return (
    <div className='position-relative bg-white' style={{ minHeight: `${bodyHeight}` }}>
      {isFetching && <RosterLoadingIndicator />}

      <div>
        <div id='sub-content'>
          {/* Header */}
          <PageContainer pt={0}>
            <div className='pb-8'>
              <div className='flex items-start md:items-center align-center flex-col md:flex-row justify-between gap-4'>
                {/* Left column - Time navigator */}
                <div className='items-center md:items-start xl:items-center flex flex-1 flex-row md:flex-col xl:flex-row gap-4'>
                  <RosterViewSelector onSelectTab={setSelectedTab} selectedTab={selectedTab} />
                  <div className='items-center flex flex-1 flex-row gap-4'>
                    <PrimaryButton size='large' onClick={() => goToDate(new Date())}>
                      Today
                    </PrimaryButton>

                    <div className=' whitespace-nowrap'>
                      <IconButton
                        size='large'
                        icon='left'
                        className='rounded-left'
                        onClick={() => navigateByDate(-1)}
                      />
                      <IconButton
                        size='large'
                        icon='right'
                        className='rounded-right'
                        onClick={() => navigateByDate(1)}
                      />
                    </div>
                  </div>
                </div>

                {/* Middle column - Day selector */}
                <div className='flex-1 flex-row justify-center'>
                  <DaySelector
                    selectedDate={selectedDate}
                    selectedWeek={selectedWeek}
                    onChangeDate={goToDate}
                    mode={selectedTab}
                  />
                </div>

                {/* Right column - Empty holder */}
                <div className='align-center text-align-right flex-1 flex-row justify-end whitespace-nowrap'>
                  <IconButton
                    icon='reload'
                    className='mr-medium'
                    bordered={true}
                    iconColor='blue-action'
                    color='white'
                    onClick={refreshData}
                  />
                  <DisplayModeSelector selectedMode={selectedMode} setSelectedMode={setSelectedMode} />
                </div>
              </div>
            </div>

            {/* TODO : Change to international timezone selector */}
            <div className='mb-small -ml-1'>
              <FilterSection
                availableFilters={AVAILABLE_FILTERS}
                filters={filters}
                displayTimezone='Australia/Melbourne'
                onChangeFilter={setViewFilters}
                containerClassName='flex-row justify-between'
              />
            </div>
          </PageContainer>

          <div style={{ overflowX: 'auto' }}>
            {/* Tabs for daily / weekly view */}
            <Tabs
              animated={false}
              renderTabBar={() => <div />}
              activeKey={selectedTab}
              style={{ alignSelf: 'stretch', overflowY: 'auto' }}
              className='flex-column flex-1'
              destroyInactiveTabPane={true}
            >
              {/* Daily tab */}
              <Tabs.TabPane tab='Daily' key={ROSTER_TAB.DAILY}>
                <RosterDaily
                  bookings={bookings}
                  assignedShifts={assignedShifts}
                  unassignedShifts={unassignedShifts}
                  selectedDate={selectedDate}
                  showCustomers={
                    selectedMode === ROSTER_DISPLAY_MODE.CUSTOMERS || selectedMode === ROSTER_DISPLAY_MODE.ALL
                  }
                  showWorkers={
                    selectedMode === ROSTER_DISPLAY_MODE.TEAM_MEMBERS ||
                    (selectedMode === ROSTER_DISPLAY_MODE.ALL && !hasNextBookings) // Hide the team members section until bookings has completely loaded
                  }
                  onAddBooking={createBookingForCustomer}
                  showOpenSlots={true}
                  refreshData={refreshData}
                  history={history}
                />

                {/* Load more element. This element will fetch more data whenever it's visible. */}
                <LoaderElement isFetching={isFetching} onFetch={fetchMore} hasMore={hasMore} />
              </Tabs.TabPane>

              {/* Weekly tab */}
              <Tabs.TabPane tab='Weekly' key={ROSTER_TAB.WEEKLY}>
                <RosterWeekly
                  bookings={bookings}
                  shifts={shifts}
                  selectedWeek={selectedWeek}
                  onClickHeaderDay={goToDailyDate}
                  showCustomers={
                    selectedMode === ROSTER_DISPLAY_MODE.CUSTOMERS || selectedMode === ROSTER_DISPLAY_MODE.ALL
                  }
                  showWorkers={
                    selectedMode === ROSTER_DISPLAY_MODE.TEAM_MEMBERS ||
                    (selectedMode === ROSTER_DISPLAY_MODE.ALL && !hasNextBookings) // Hide the team members section until bookings has completely loaded
                  }
                  isFetching={isFetching}
                  refreshData={refreshData}
                  history={history}
                />

                {/* Load more element */}
                <LoaderElement isFetching={isFetching} onFetch={fetchMore} hasMore={hasMore} />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </div>

      <CreateNewBookingModal
        isOpen={showBookingModal}
        history={history}
        incomingCustomer={selectedCustomer}
        incomingStartDateTime={selectedCreateDate}
        closeCreateBookingModal={closeBookingModal}
      />
    </div>
  );
};

export default RosterLandingView;
