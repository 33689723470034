import React, { Badge } from '@good/ui/core';
import { Filter, type FilterProps } from '@good/ui/templates';
import { type CountryCode, useLocaleConfig } from 'providers/locale-provider';

type SelectAvailableLocaleConfigsProps = Omit<FilterProps, 'value' | 'onChange' | 'items' | 'clearable' | 'label'> & {
  label?: string;
};

export const SelectAvailableLocaleConfigs = (props: SelectAvailableLocaleConfigsProps) => {
  const {
    all: allLocaleConfigs,
    current: currentLocaleConfig,
    changeConfig: changeLocaleConfig,
    available: availableLocaleConfigs,
  } = useLocaleConfig();

  return (
    <Filter
      label='Locales'
      {...props}
      items={allLocaleConfigs.map((localConfig) => {
        return {
          leftSection: currentLocaleConfig.countryCode === localConfig.countryCode ? <Badge>Default</Badge> : undefined,
          icon: localConfig.icon,
          key: localConfig.countryCode,
          label: localConfig.countryCode,
        };
      })}
      clearable={false}
      value={availableLocaleConfigs.map((localConfig) => localConfig.countryCode)}
      onChange={(countryCodes) => {
        changeLocaleConfig({ availableCountryCodes: countryCodes as CountryCode[] });
      }}
    />
  );
};
