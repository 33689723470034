import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionModal from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { Paragraph, Text } from 'common-components/typography';
import { Col, Icon, Input, message, Row } from 'antd';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import firebaseApp from 'stores/firebase-app';
import _ from 'lodash';
import { PrimaryButton, SecondaryButton, HyperlinkButton } from 'common-components/buttons';
import { withRouter, WithRouterProps } from 'utilities/with-router';

interface ISessionExpiredModalProps extends WithRouterProps {
  isSessionExpiredOpen: boolean;
  portalUser: typeof state.authStore.portalUser;
  doReAuthenticate: typeof dispatch.authStore.doReAuthenticate;
  closeSessionExpired: () => void;

  setCurrentUser: typeof dispatch.authStore.setCurrentUser;
  setCurrentPortalUser: typeof dispatch.authStore.setCurrentPortalUser;
  setPortalUserList: typeof dispatch.authStore.setPortalUserList;
  setIsForgetPassword: typeof dispatch.authStore.setIsForgetPassword;
}

interface ISessionExpiredModalState {
  // login: string;
  password: string;
  isLoggingIn: boolean;
  isError: boolean;
  errorMessage: string;
}

class SessionExpiredModal extends Component<ISessionExpiredModalProps, ISessionExpiredModalState> {
  state = { password: null, isLoggingIn: false, isError: false, errorMessage: '' };

  // Simple state handler
  private _handleInput = (target, value) => {
    let targetObj = {};
    targetObj[target] = value;
    this.setState(targetObj);
  };

  private _onClickLogout = async () => {
    const { history } = this.props;
    this.props.closeSessionExpired();
    this.props.setIsForgetPassword(false);
    history.push('/sign-out');
    await firebaseApp.auth.signOut();

    this.props.setCurrentUser(null);
    this.props.setCurrentPortalUser(null);
    this.props.setPortalUserList(null);
    await message.success('Signed out.');
  };

  private _onClickForgetPassword = async () => {
    const { history } = this.props;
    this.props.closeSessionExpired();
    history.push('/sign-out');
    this.props.setIsForgetPassword(true);
    await firebaseApp.auth.signOut();

    this.props.setCurrentUser(null);
    this.props.setCurrentPortalUser(null);
    this.props.setPortalUserList(null);
    await message.success('Signed out.');
  };

  private _onClickLogin = async () => {
    this.setState({ isLoggingIn: true, isError: false });

    try {
      if (_.isEmpty(this.state.password)) {
        this.setState({ isLoggingIn: false, isError: true, errorMessage: 'Please enter password' });
      } else {
        await this.props.doReAuthenticate({ password: this.state.password });
        this.setState({ isLoggingIn: false, password: '' });
        this.props.closeSessionExpired();
      }
    } catch (err) {
      this.setState({ isLoggingIn: false, isError: true, errorMessage: 'Incorrect password' });
    }
  };

  render() {
    return (
      <div>
        <ActionModal
          title='Session expired'
          isOpen={this.props.isSessionExpiredOpen}
          canCloseOutside={false}
          width='large'
          verticalAlignment='highest'
          showCloseButton={false}
          backdropClassName='bg-black darker'
          zIndex={999999}
        >
          {this.state.isLoggingIn ? (
            <SpinningLoader size={150} message='Signing in ...' />
          ) : (
            <div className='mt-large'>
              <Paragraph>
                Your session has timed out for security reasons, please re-type your password to keep using the
                GoodHuman workspace.
              </Paragraph>
              <Paragraph>
                Connected as:{' '}
                <b>
                  {this.props.portalUser.firstName} {this.props.portalUser.lastName}
                </b>
              </Paragraph>
              <Row className='mt-x2-large'>
                <Col md={24} className='text-align-right'>
                  <HyperlinkButton onClick={this._onClickForgetPassword}>Forgot your password?</HyperlinkButton>
                </Col>
              </Row>
              <Row type='flex' justify='center' className='mt-x2-small'>
                {/* Password Input */}
                <Input.Password
                  size='large'
                  placeholder='Password'
                  value={this.state.password}
                  onChange={(e) => this._handleInput('password', e.target.value)}
                  prefix={<Icon type='lock' className='text-color-tertiary' />}
                  className='w-full'
                  onPressEnter={this._onClickLogin}
                />
                {this.state.isError && <Text color='red'>{this.state.errorMessage}</Text>}
              </Row>
              <Row className='mt-x2-large' gutter={32}>
                <Col span={12}>
                  <SecondaryButton className='width-full' size='large' onClick={this._onClickLogout}>
                    Sign-in with a different account
                  </SecondaryButton>
                </Col>
                <Col span={12}>
                  <PrimaryButton className='width-full' size='large' onClick={this._onClickLogin}>
                    Sign In
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doReAuthenticate: dispatch.authStore.doReAuthenticate,
  setCurrentUser: dispatch.authStore.setCurrentUser,
  setCurrentPortalUser: dispatch.authStore.setCurrentPortalUser,
  setPortalUserList: dispatch.authStore.setPortalUserList,
  setIsForgetPassword: dispatch.authStore.setIsForgetPassword,
});

export default connect(mapState, mapDispatch)(withRouter(SessionExpiredModal));
