import React from 'react';
import clsx from 'clsx';

import { UnstyledButton, type UnstyledButtonProps } from '@good/ui/core';

import classes from './profile-button.module.css';

type ProfileButtonProps = Omit<UnstyledButtonProps, 'component'> & {
  children: React.ReactNode;
};

export const ProfileButton = React.forwardRef<HTMLButtonElement, ProfileButtonProps>(
  ({ children, ...profileButtonProps }, ref) => {
    return (
      <UnstyledButton
        ref={ref}
        {...profileButtonProps}
        className={clsx(classes.profileButton, profileButtonProps.className)}
        aria-label='open profile menu'
      >
        {children}
      </UnstyledButton>
    );
  },
);

ProfileButton.displayName = 'ProfileButton';
