import React, { Component } from 'react';

import { Tabs } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as H from 'history';

import { IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';

import { TimezoneSelectorMode } from 'utilities/enum-utils';

import SessionsListView from 'views/group-services/session-listings/list-view/SessionsListView';
import SessionsWeekView from 'views/group-services/session-listings/week-view/SessionsWeekView';

import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { timeZone } from 'interfaces/timezone-type';
import { PageHeader } from '@good/ui/templates';

interface IGroupSessionsListProps {
  portalUser: typeof state.authStore.portalUser;

  // TODO : Change this to session listings specific timezone.
  // setBookingDisplayTzMode: typeof dispatch.bookingsStore.setBookingDisplayTzMode;
  // setBookingDisplayTzCustom: typeof dispatch.bookingsStore.setBookingDisplayTzCustom;
  // bookingDisplayTzCustom: typeof state.bookingsStore.bookingDisplayTzCustom;
  // bookingDisplayTzMode: typeof state.bookingsStore.bookingDisplayTzMode;
  sessionListsDisplayTzMode: typeof state.groupServiceStore.sessionListDisplayTzMode;
  sessionListDisplayTzCustom: typeof state.groupServiceStore.sessionListDisplayTzCustom;
  setSessionListDisplayTzMode: typeof dispatch.groupServiceStore.setSessionListDisplayTzMode;
  setSessionListDisplayTzCustom: typeof dispatch.groupServiceStore.setSessionListDisplayTzCustom;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;

  history: H.History;
}

interface IGroupSessionsListState {
  selectedTab: string;
  displayTimezone: timeZone;
  isFetching: boolean;
}

function ViewNavigator({ selectedTab, onSelectTab }: { selectedTab: string; onSelectTab: any }) {
  const ListButton = selectedTab === 'list-view' ? PrimaryButton : SecondaryButton;
  const WeekButton = selectedTab === 'week-view' ? PrimaryButton : SecondaryButton;

  return (
    <div>
      <ListButton onClick={() => onSelectTab('list-view')} size='large' className='rounded-left'>
        List
      </ListButton>
      <WeekButton onClick={() => onSelectTab('week-view')} size='large' className='rounded-right'>
        Week
      </WeekButton>
    </div>
  );
}

class GroupSessionsListingView extends Component<IGroupSessionsListProps, IGroupSessionsListState> {
  state = {
    selectedTab: 'list-view',
    displayTimezone: this.props.portalUser.timezone,
    isFetching: false,
  };

  _onSelectTab = (selectedTab) => this.setState({ selectedTab });

  // noinspection DuplicatedCode
  _onChangeTimezone = async (type, value) => {
    if (type === 'mode') {
      // await this.props.setBookingDisplayTzMode(value);
      await this.props.setSessionListDisplayTzMode(value);
      this.setState({
        displayTimezone:
          value === TimezoneSelectorMode.MyTimezone
            ? this.props.portalUser.timezone
            : value === TimezoneSelectorMode.BookingTimezone
            ? null
            : this.props.sessionListsDisplayTzMode
            ? this.props.sessionListsDisplayTzMode
            : this.state.displayTimezone,
      });
    } else if (type === 'customTimezone') {
      await this.props.setSessionListDisplayTzCustom(value);
      this.setState({ displayTimezone: value });
    }
  };

  componentDidMount() {
    const { portalUser } = this.props;
    const timezone = _.get(portalUser, 'timezone');
    this.setState({ displayTimezone: timezone });
    this.props.setSelectedSideNavMenuKeys(['/group-service/sessions']);
  }

  render() {
    const { history } = this.props;

    return (
      <div style={{ overflowY: 'auto' }} id='content-container'>
        <PageHeader
          title='Sessions'
          subtitle='View and manage your group sessions.'
          actions={
            <div className='flex-row gap-4'>
              <IconButton
                icon='reload'
                bordered={true}
                className='ml-medium border-standard-gray'
                color='white'
                size='large'
                iconColor='secondary'
              />
              {/* Navigation buttons (List/week)*/}
              <ViewNavigator selectedTab={this.state.selectedTab} onSelectTab={this._onSelectTab} />

              {/* Timezone + reload button*/}
              <div>
                {/*<TimezoneSelector*/}
                {/*  mode={this.props.sessionListsDisplayTzMode}*/}
                {/*  bordered={false}*/}
                {/*  userTimezone={this.props.portalUser.timezone}*/}
                {/*  customTimezone={this.props.sessionListsDisplayTzMode}*/}
                {/*  onChange={this._onChangeTimezone}*/}
                {/*/>*/}
              </div>
            </div>
          }
        />
        {/* Local nav + timezone selector */}

        {/* Main content (Listings / weekly) */}
        <Tabs
          animated={false}
          renderTabBar={() => <div />}
          activeKey={this.state.selectedTab}
          style={{ alignSelf: 'stretch', overflowY: 'auto' }}
          className='flex-column flex-1'
        >
          <Tabs.TabPane tab='list-view' key='list-view'>
            <SessionsListView isActive={this.state.selectedTab === 'list-view'} timezone={this.state.displayTimezone} />
          </Tabs.TabPane>

          <Tabs.TabPane tab='week-view' key='week-view'>
            <SessionsWeekView
              history={history}
              isActive={this.state.selectedTab === 'week-view'}
              timezone={this.state.displayTimezone}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  sessionListsDisplayTzMode: state.groupServiceStore.sessionListDisplayTzMode,
  sessionListDisplayTzCustom: state.groupServiceStore.sessionListDisplayTzCustom,
  sessions: state.groupServiceStore.sessions,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSessionListDisplayTzMode: dispatch.groupServiceStore.setSessionListDisplayTzMode,
  setSessionListDisplayTzCustom: dispatch.groupServiceStore.setSessionListDisplayTzCustom,
  doFetchSessions: dispatch.groupServiceStore.doFetchSessions,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
});

export default connect(mapState, mapDispatch)(GroupSessionsListingView);
