import React, { Component } from 'react';
import _ from 'lodash';
import { ndisHelper } from 'variables/data-helpers';
import { Col, Icon, Row } from 'antd';
import { SubTitle, Text } from 'common-components/typography';
import CommonUtils from 'utilities/common-utils';
import ViewQuotationQuoteItemView from './ViewQuotationQuoteItemView';
import ViewSubscriptionQuotationItemView from './ViewSubscriptionQuotationItemView';
import moment from 'moment';
import { ISubscriptionQuoteSubQuoteDetails } from 'interfaces/customer-interfaces';

class ViewQuotationSubQuoteView extends Component<any, any> {
  state = {
    openedQuoteItem: [],
  };

  private _handleQuoteItems = (index) => {
    const { openedQuoteItem } = this.state;
    const { quotation } = this.props;

    if (!!_.find(this.state.openedQuoteItem, (item) => item === index)) {
      this.setState({ openedQuoteItem: _.filter(openedQuoteItem, (item) => item !== index) });
    } else {
      if (!quotation.isSubscriptionQuote) {
        this.setState({ openedQuoteItem: [...openedQuoteItem, index] });
      } else {
        const { timeSlots, subQuotes, subscriptionPrice } = this.props.quotation;
        const { recurringPattern, startDateTime, endDateTime } = _.first(timeSlots);
        const { multiplier, quoteAmount } = _.first(subQuotes);
        const quoteItem = [
          {
            startDateTime,
            endDateTime,
            recurringPattern,
            subscriptionPrice,
            quoteAmount,
            multiplier,
          },
        ];
        this.setState({ openedQuoteItem: [...quoteItem, index] });
      }
    }
  };

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>): void {
    if (this.props.quotation.isSubscriptionQuote && prevProps.quotation.isSubscriptionQuote) {
      const {
        timeSlots: prevTimeSlots,
        subQuotes: prevSubQuotes,
        subscriptionPrice: prevSubscriptionPrice,
      } = prevProps.quotation;
      const {
        recurringPattern: prevRecurringPattern,
        startDateTime: prevStartDateTime,
        endDateTime: prevEndDateTime,
      } = _.first(prevTimeSlots);
      const { multiplier: prevMultiplier, quoteAmount: prevQuoteAmount } = _.first(prevSubQuotes);

      const { timeSlots, subQuotes, subscriptionPrice } = this.props.quotation;
      const { recurringPattern, startDateTime, endDateTime } = _.first(timeSlots);
      const { multiplier, quoteAmount } = _.first(subQuotes);

      const prevSubQuote: ISubscriptionQuoteSubQuoteDetails = {
        recurringPattern: prevRecurringPattern,
        startDateTime: prevStartDateTime,
        endDateTime: prevEndDateTime,
        multiplier: prevMultiplier,
        quoteAmount: prevQuoteAmount,
        subscriptionPrice: prevSubscriptionPrice,
      };

      const subQuote: ISubscriptionQuoteSubQuoteDetails = {
        recurringPattern,
        startDateTime,
        endDateTime,
        multiplier,
        quoteAmount,
        subscriptionPrice,
      };

      const isChanged = this.checkQuotationUpdated(prevSubQuote, subQuote);

      if (isChanged) {
        const currentOpenedItem = prevState.openedQuoteItem;
        currentOpenedItem[0] = {
          startDateTime,
          endDateTime,
          recurringPattern,
          subscriptionPrice,
          quoteAmount,
          multiplier,
        };

        this.setState({ openedQuoteItem: currentOpenedItem });
      }
    }
  }

  private checkQuotationUpdated = (
    prevQuote: ISubscriptionQuoteSubQuoteDetails,
    quote: ISubscriptionQuoteSubQuoteDetails,
  ) => {
    const isChanged = Object.keys(prevQuote).some((key) => {
      if (typeof prevQuote[key] === 'object') {
        return !moment(prevQuote[key]).isSame(moment(quote[key]), 'days');
      } else {
        return prevQuote[key] !== quote[key];
      }
    });

    return isChanged;
  };

  render() {
    const { quotation, isShowIncludeGST } = this.props;
    const { openedQuoteItem } = this.state;
    return _.map(quotation.subQuotes, (subQuote, index) => {
      const isOpen = !!_.find(openedQuoteItem, (item) => item === index + 1);
      if (quotation.isManualQuote && !quotation.isManualTotal) {
        const subQuoteCategoryDetail = ndisHelper.getByCategoryNumber(subQuote.supportCategoryNumber);
        return (
          <div key={index} className="p-medium bg-secondary">
            <div className="rounded-big bg-white">
              <Row
                className="pv-small bordered-bottom bordered-left bordered-right border-standard-gray"
                type="flex"
                align="middle"
              >
                <Col span={1} />
                <Col span={8} className="pl-medium">
                  <Text weight="bold">{subQuoteCategoryDetail[0].SupportPurposeType}</Text>
                  <br />
                  <Text>{subQuoteCategoryDetail[0].SupportCategories}</Text>
                </Col>
                <Col span={4} className="text-align-right">
                  <SubTitle>Quote Amount</SubTitle>
                  <Text weight="bold">{CommonUtils.formatPrice(Number(subQuote.quoteAmount))}</Text>
                </Col>
                <Col span={4} className="text-align-right">
                  <SubTitle>Amount spent</SubTitle>
                  <Text weight="bold">{CommonUtils.formatPrice(Number(subQuote.spentAmount))}</Text>
                </Col>
                <Col span={4} className="text-align-right">
                  <SubTitle>Balance</SubTitle>
                  <Text weight="bold">
                    {CommonUtils.formatPrice(Number(subQuote.quoteAmount) - Number(subQuote.spentAmount))}
                  </Text>
                </Col>
                <Col span={3} />
              </Row>
            </div>
          </div>
        );
      } else if (quotation.isSubscriptionQuote) {
        const displayedQuoteItems = [_.first(openedQuoteItem)];
        return (
          <div key={index} className="p-medium bg-secondary">
            <div className="rounded-big bg-white">
              <Row
                className="pv-small bordered-bottom bordered-left bordered-right border-standard-gray"
                type="flex"
                align="middle"
              >
                <Col span={1}>
                  <Icon
                    type={isOpen ? 'down' : 'right'}
                    onClick={() => this._handleQuoteItems(index + 1)}
                    className="ml-medium"
                  />
                </Col>
                <Col span={8} className="pl-medium">
                  <Row>
                    <Text weight="bold">Total</Text>
                  </Row>
                  <Row>
                    <Text>Subscription quote</Text>
                  </Row>
                </Col>
                <Col span={8} className="text-align-right">
                  <Text>{CommonUtils.formatPrice(Number(subQuote.quoteAmount))}</Text>
                </Col>
                <Col span={3} />
              </Row>
              {isOpen &&
                _.map(displayedQuoteItems, (quoteItem) => {
                  const { subscriptionPrice, recurringPattern, startDateTime, endDateTime, multiplier } = quoteItem;
                  return (
                    <ViewSubscriptionQuotationItemView
                      subscriptionPrice={subscriptionPrice}
                      recurringPattern={recurringPattern}
                      startDateTime={startDateTime}
                      endDateTime={endDateTime}
                      multiplier={multiplier}
                    />
                  );
                })}
            </div>
          </div>
        );
      } else {
        const quoteAmount =
          isShowIncludeGST && subQuote.quoteAmountIncGst ? subQuote.quoteAmountIncGst : subQuote.quoteAmount;
        return (
          <div className="p-medium bg-secondary">
            <div className="rounded-big bg-white">
              <Row className="pv-small bordered-bottom bordered-left bordered-right" type="flex" align="middle">
                <Col span={1}>
                  {subQuote.quoteItems && subQuote.quoteItems.length > 0 && (
                    <Icon
                      type={!isOpen ? 'right' : 'down'}
                      onClick={() => this._handleQuoteItems(index + 1)}
                      className="ml-medium"
                    />
                  )}
                </Col>
                <Col span={12} className="pl-medium">
                  {subQuote.supportType && subQuote.supportType !== 'VCP' && (
                    <>
                      <Text weight="bold">{subQuote.supportType}</Text>
                      <br />
                    </>
                  )}
                  <Text>{subQuote.subQuoteName}</Text>
                </Col>
                <Col span={8} className="text-align-right">
                  <Text weight="bold">{CommonUtils.formatPrice(quoteAmount)}</Text>
                </Col>
                <Col span={3} />
              </Row>
              {isOpen &&
                _.map(subQuote.quoteItems, (quoteItem) => {
                  quoteItem.serviceType = quotation.serviceType;
                  return <ViewQuotationQuoteItemView quoteItem={quoteItem} isShowIncludeGST={isShowIncludeGST} />;
                })}
            </div>
          </div>
        );
      }
    });
  }
}

export default ViewQuotationSubQuoteView;
