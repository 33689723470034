import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Speed = forwardRef(function Speed(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <g clipPath="url(#speed_svg__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25 1A.75.75 0 0 1 10 .25h4a.75.75 0 0 1 0 1.5h-4A.75.75 0 0 1 9.25 1Zm-5.5 12A8.25 8.25 0 1 1 12 21.25H7a.75.75 0 0 0 0 1.5h5c5.385 0 9.75-4.365 9.75-9.75S17.385 3.25 12 3.25 2.25 7.615 2.25 13a.75.75 0 0 0 1.5 0Zm-2.5 3a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75Zm2 3a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75Zm9.5-9a.75.75 0 0 0-1.5 0v4a.75.75 0 1 0 1.5 0v-4Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="speed_svg__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
});
