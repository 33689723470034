import React from 'react';
import { Avatar, Box, BreadcrumbItem, Breadcrumbs, Heading, Inline, Stack, Text } from '@goodhuman-me/components';
import { Link } from 'react-router-dom';

import { BudgetStatusLabel } from '../../../../components/budget-status-label';

import type { Budget } from '../../../../budget';

type ParticipantsProps = Pick<HeaderProps, 'customer' | 'service'>;

function Participants(props: ParticipantsProps) {
  let {
    customer: { id, avatarUrl, name },
  } = props;

  // TODO (Mitch): assigned workers

  return (
    <Link to={`/customer/details/${id}`}>
      <Box
        borderRadius='$large'
        borderColor='$bodyLight2'
        borderWidth='$thick'
        borderStyle='solid'
        paddingY='$space300'
        paddingX='$space400'
        width='fit-content'
      >
        <Inline gap='$space200' alignItems='center'>
          <Stack alignItems='flex-end' gap='$space50'>
            <Text color='$muted' size='xsmall'>
              Customer
            </Text>

            <Text size='xsmall' fontWeight='$semi'>
              {name}
            </Text>
          </Stack>

          <Avatar icon='user' src={avatarUrl} />
        </Inline>
      </Box>
    </Link>
  );
}

export type HeaderProps = Budget['overview'];

export function Header(props: HeaderProps): JSX.Element {
  let { budgetStatus, customer, service } = props;

  // TODO - Budgeting (Mitch): navigate to new service
  // function handleOnChangeService(serviceId: HeaderProps['service']['id']) {}

  return (
    <Stack gap="$space400">
      <Breadcrumbs>
        <BreadcrumbItem>
          <Link to="/customers">Customers</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`/customer/details/${customer.id}`}>{customer.name}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="#">{service.name}</Link>
        </BreadcrumbItem>
      </Breadcrumbs>

      <Inline gap="$space300" justifyContent="space-between" collapseAt="768px">
        <Stack gap="$space150" justifyContent="center">
          <Inline gap="$space300">
            <Heading size="large" asChild>
              <h1>{service.name}</h1>
            </Heading>

            {/** TODO (Mitch): Change service dropdown*/}
          </Inline>

          <BudgetStatusLabel status={budgetStatus} />
        </Stack>

        <Participants customer={customer} service={service} />
      </Inline>
    </Stack>
  );
}
