/* BOOM-475 - fix linting errors */
import React, { Ref, useEffect, useState } from 'react';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { Slider, Icon } from 'antd';

import { Inline, Link, IconPlus } from 'design-components';
import { ModalType } from '../components/ViewProfilePictureModal';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { RotateCw } from '@good/icons';
import '../styles/override-scrop-image.css';
import '../styles/profile-photo-styles.css';
import { Button } from '@good/components';

interface ICropAndRotateModalProps {
  errorMessage?: string;
  imageUrl: string;
  cropperRef: Ref<ReactCropperElement> | any;
  onSubmit: () => void;
  rotate?: () => void;
  onCancel: (type: ModalType) => void;
  isLoading: boolean;
}

export const cropAndRotateImage = {
  cropperElementStyles: {
    width: 300,
    height: 300,
  },
  nonLogoSectionStyles: {
    height: 72,
    width: 72,
  },
  logoSectionStyles: {
    height: 72,
    width: 72,
  },
  nonFileUploadedTextStyles: {
    display: 'block',
    margin: 'auto',
    width: 100,
  },
  aspectRatio: 1,
  minCropBoxWidth: 72,
  maximumSizeOfImage: 25,
};

const rangeZoomImage = {
  max: 100,
  min: 1,
};

const CropAndRotateModal = (props: ICropAndRotateModalProps) => {
  const { onSubmit, errorMessage, cropperRef, imageUrl, rotate, onCancel, isLoading } = props;

  const [isLoadingCropper, setIsLoadingCropper] = useState<boolean>(true);
  const [zoomValue, setZoomValue] = useState<number>(0);
  const [zoomAble, setZoomAble] = useState<boolean>(true);
  const [imagePercentage, setImagePercentage] = useState<number>(0);

  const onChange = (value: number) => {
    const zoomedValue = value > rangeZoomImage.max ? 100 : value;
    setZoomValue(zoomedValue);
  };

  const onPressMinusPlus = (isPressMinus) => {
    const actualZoomValue = renderZoomValue(zoomValue);
    let newZoomValue;
    if (isPressMinus) {
      newZoomValue = actualZoomValue === 0 ? zoomValue : zoomValue - 1;
    }

    if (!isPressMinus) {
      newZoomValue = actualZoomValue === 100 ? zoomValue : zoomValue + 1;
    }
    setZoomValue(newZoomValue);
  };

  const renderZoomValue = (value: number) => {
    if (((value - imagePercentage) / (100 - imagePercentage)) * 100 < 0) {
      return 0;
    }

    const roundedValue = Math.round(((value - Math.round(imagePercentage)) / (99 - Math.round(imagePercentage))) * 100);

    return roundedValue > 100 ? 100 : roundedValue;
  };

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;
    image.addEventListener('load', () => {
      const relativeSize = image.height > image.width ? image.height : image.width;
      setImagePercentage((300 / relativeSize) * 100);

      // Resolve the problem slowly when first zooming with a large image
      setTimeout(() => {
        setZoomValue(100);
        setTimeout(() => {
          setZoomValue(0);
          setIsLoadingCropper(false);
        }, 500);
      }, 500);
    });
  }, []);

  return (
    <div height={'$sizeFull'}>
      <Inline flex={1} alignItems="center" justifyContent="center">
        {imageUrl ? (
          <>
            {isLoadingCropper && (
              <div className="cropper-loading">
                <SpinningLoader size={100} message="Loading" />
              </div>
            )}
            <Cropper
              crossOrigin="anonymous"
              className="cropper-image profile-cropper-image"
              viewMode={2}
              checkOrientation={false}
              checkCrossOrigin={false}
              aspectRatio={cropAndRotateImage.aspectRatio}
              autoCropArea={1}
              minCropBoxWidth={cropAndRotateImage.minCropBoxWidth}
              style={cropAndRotateImage.cropperElementStyles}
              src={imageUrl}
              ref={cropperRef}
              dragMode="move"
              center
              guides={false}
              background={true}
              responsive={true}
              movable={true}
              cropBoxResizable={false}
              cropBoxMovable={false}
              zoomTo={zoomValue / 100}
              zoomOnWheel={zoomAble}
              zoom={(e) => {
                const resizeRatio = e.detail.ratio * 100;
                if (e?.detail?.originalEvent) {
                  if (resizeRatio <= rangeZoomImage.max && resizeRatio >= rangeZoomImage.min) {
                    onChange(resizeRatio);
                    return;
                  }
                  e.preventDefault();
                  if (resizeRatio > rangeZoomImage.max) {
                    onChange(100);
                  }
                  if (resizeRatio <= rangeZoomImage.min) {
                    onChange(0);
                  }
                }
                setZoomAble(false);
              }}
            />
          </>
        ) : null}
      </Inline>

      {!isLoadingCropper && (
        <>
          <Inline alignItems="center" justifyContent="space-between" marginY={'$space150 !important'}>
            <Button
              height={18}
              marginY="$space75"
              marginLeft="$space175"
              marginRight="3px"
              size="xsmall"
              emphasis="quiet"
              onClick={() => {
                onPressMinusPlus(true);
              }}
              paddingX={'$space30 !important'}
            >
              <Icon type="minus" height={'$size225 !important'} width={'$size225 !important'} />
            </Button>

            <Slider
              className={'cropper-image-slider  profile-cropper-image-slider'}
              value={zoomValue}
              onChange={onChange}
              min={imagePercentage}
              tipFormatter={renderZoomValue}
            />

            <Button
              height={18}
              marginY="$space75"
              marginRight="$space125"
              marginLeft="3px"
              size="xsmall"
              emphasis="quiet"
              onClick={() => {
                onPressMinusPlus(false);
              }}
              paddingX={'$space0 !important'}
            >
              <IconPlus height={'$size225 !important'} width={'$size225 !important'} />
            </Button>
          </Inline>

          <Inline alignItems="center" justifyContent="space-between">
            <Link
              color={errorMessage ? '$bodyDark1' : '$blueDark3'}
              size={'small'}
              emphasis="regular"
              gap="$space50"
              marginRight="$space200"
              onClick={rotate}
              isDisabled={!!errorMessage}
            >
              <RotateCw />
              Rotate
            </Link>

            <div className="flex gap-1">
              <Button size="lg" emphasis="quiet" onClick={() => !isLoading && onCancel(ModalType.UNSAVED_CHANGES)}>
                Cancel
              </Button>
              <Button
                size="lg"
                emphasis="fill"
                onClick={onSubmit}
                isDisabled={Boolean(errorMessage) || isLoading}
                color="$white"
              >
                Save
              </Button>
            </div>
          </Inline>
        </>
      )}
    </div>
  );
};

export default CropAndRotateModal;
