import React, { PureComponent } from 'react';
import { Paragraph } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';

interface IConfirmRemoveCommentModalProps {
  isOpen: boolean;
  isUpdating: boolean;
  onClose(): void;
  onConfirm(): void;
}

class ConfirmRemoveCommentModal extends PureComponent<IConfirmRemoveCommentModalProps> {
  render() {
    const { isOpen, isUpdating, onClose, onConfirm } = this.props;

    return (
      <ActionModal isOpen={isOpen} onClose={onClose} title="Delete comment" verticalAlignment="high">
        <Paragraph className="mt-medium">Are you sure you want to delete this comment?</Paragraph>
        <ActionModalFooter className={'mt-x-large'}>
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" loading={isUpdating} onClick={onConfirm}>
            Delete
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default ConfirmRemoveCommentModal;
