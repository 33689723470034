import React from 'react';
import { LeftNavItem } from 'common-components/navigation/LeftNavItem';
import {
  PaymentListFilterConfig,
  PaymentListFilterKey,
  PAYMENTLIST_FILTERCONFIGS,
} from '../components/payListFilterConfig';

const paymentListNavigationItems = [
  [PaymentListFilterKey.WAITING_TO_BE_PROCESSED, PaymentListFilterKey.PROCESSED, PaymentListFilterKey.PAID],
  [PaymentListFilterKey.BATCH],
  [PaymentListFilterKey.NON_BILLABLE],
  [PaymentListFilterKey.REJECTED, PaymentListFilterKey.WAIVED],
  [PaymentListFilterKey.SHIFT_HOURS],
] as const satisfies readonly (readonly PaymentListFilterKey[])[];

export const PayListNavigationSection: React.VFC<{
  currentFilterConfig: PaymentListFilterConfig;
  setFilter: (value: PaymentListFilterKey) => void;
}> = ({ currentFilterConfig, setFilter }) => {
  return (
    <div className='bg-quaternary' style={{ minWidth: '300px' }}>
      <div className='mh-large sticky top-24'>
        {paymentListNavigationItems.map((group) =>
          group.map((key, index) => (
            <div key={key} className={index === group.length - 1 ? 'mb-large' : undefined}>
              <LeftNavItem
                filterConfig={PAYMENTLIST_FILTERCONFIGS[key]}
                onClick={setFilter}
                isSelected={currentFilterConfig.key === key}
              />
            </div>
          )),
        )}
      </div>
    </div>
  );
};
