import clsx from 'clsx';
import React from 'react';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export const CustomerViewPanel = ({ className, children, ...props }: Props): JSX.Element => {
  return (
    <div className={clsx('flex flex-col rounded-lg border border-base bg-white p-6', className)} {...props}>
      {children}
    </div>
  );
};
