import React from 'react';

import { Table, Text } from '@good/ui/core';
import { Cat } from '@good/illustrations';
import { useTranslation } from 'react-i18next';

export const EmptyTeamMembers = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceTeamTabs.modal' });

  return (
    <>
      <Table.Tr>
        <Table.Td colSpan={2}>
          <div className='flex flex-col items-center justify-center p-4 mt-8'>
            <Cat />
            <Text size='sm' fw='700'>
              {t('emptyHeading')}
            </Text>
            <div className='flex flex-col items-center justify-center mt-1'>
              <Text size='xs' c='dimmed'>
                {t('emptyDescription1')}
              </Text>
              <Text size='xs' c='dimmed'>
                {t('emptyDecription2')}
              </Text>
            </div>
          </div>
        </Table.Td>
      </Table.Tr>
    </>
  );
};
