import { timeZone } from 'interfaces/timezone-type';
import { v4 as uuidv4 } from 'uuid';
import { ServiceLocationType, ServiceTimeType } from './enum-utils';

class ServiceUtils {
  static createNewEmptyService(serviceProviderId: string, timezone: timeZone) {
    return {
      serviceId: uuidv4(),
      serviceProviderId,
      serviceName: '',
      serviceDescription: '',
      bookingPeriodType: 'MINIMUMHOURS',
      bookingTimeType: ServiceTimeType.FIXEDTIME,
      recurringType: null,
      serviceType: null,
      serviceDirection: ServiceLocationType.FIXEDVENUE,
      workerSelectionType: 'ASSIGNED',
      priceFrequency: null,
      price: null,
      currency: 'AUD',
      paymentType: 'POST',
      capacity: null,
      NDISEligible: true,
      servicePublishDate: null,
      status: null,
      bannerUrl: null,
      locations: null,
      managers: null,
      availableFrom: null,
      availableTo: null,
      timeAvailabilities: null,
      vcpServiceBillingItems: [],
      ndisServiceBillingItems: [],
      paymentSources: [],
      groupServiceClassification: null,
      teamMemberCustomerRatio: null,
      serviceClaimConfig: {
        vcpClaims: {
          transportPriceDuringBooking: 0,
        },
        ndisClaims: {
          transportPriceBeforeBooking: 0,
          transportPriceDuringBooking: 0,
          isChargeCentreCapitalCosts: false,
        },
        isChargeNdisTransportBeforeBooking: false,
        isChargeNdisTransportDuringBooking: false,
      },
      isPublicMarketplace: false,
      workers: [],
      serviceCategory: [],
      serviceConfig: null,
      timezone,
    };
  }
}

export default ServiceUtils;
