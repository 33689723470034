import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import React from 'react';

export function NoDuplicatesSection({ onStepPrevious, onStepNext }) {
  return (
    <div className="flex-row p-x-large">
      <div className="mr-medium">
        <Icon type="check-circle" style={{ fontSize: '40px' }} theme="filled" className="text-color-forest-light" />
      </div>
      <div style={{ maxWidth: '536px' }}>
        <div className="mb-x-large">
          <Text size="x2-large">No duplicates found!</Text>
        </div>
        <div className="mb-x-large">
          <Text>
            No existing people match the data you've entered. <br /> <br />
            If this is someone new to your business you can create a profile for them or alternatively try searching
            again.
            {/*<br /> <br />*/}
            {/*Visit the <HyperlinkButton>Help Center</HyperlinkButton> to learn more.*/}
          </Text>
        </div>
        <div className="flex-row align-center">
          <SecondaryButton size="large" className="mr-medium" onClick={onStepPrevious}>
            Search again
          </SecondaryButton>
          <span className="mr-medium">or</span>
          <PrimaryButton size="large" onClick={onStepNext}>
            Create new team member
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
