import { SearchInput } from 'common-components/roster-control/roster-daily/content-pane/common/SearchInput';
import _ from 'lodash';
import { HeaderDayDisplay } from 'common-components/roster-control/roster-weekly/common/HeaderDayDisplay';
import React from 'react';
import { ROSTER_WEEKLY_CONFIG } from 'common-components/roster-control/roster-weekly/roster-weekly-config';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/stores/rematch/root-store';

const { CONTENT_PANEL_WIDTH, HOUR_BLOCK_HEIGHT, SIDEBAR_WIDTH } = ROSTER_WEEKLY_CONFIG;

export function HeaderRow({ days, onClickDay }: { days: Date[]; onClickDay: any }) {
  const navigationStore = useSelector((state: IRootState) => state.navigationStore);

  const { sideMenuCollapsed } = navigationStore;

  const rowWidthOffset = sideMenuCollapsed ? CONTENT_PANEL_WIDTH : CONTENT_PANEL_WIDTH + SIDEBAR_WIDTH;

  return (
    <div className="flex-row" id="main-container">
      {/* (Left) Search input container */}
      <div
        className="bg-tertiary"
        id="search-container"
        style={{ minWidth: `${CONTENT_PANEL_WIDTH}px`, maxWidth: `${CONTENT_PANEL_WIDTH}px` }}
      >
        {/* TODO : Change this to localised WIP search input */}
        <SearchInput height={HOUR_BLOCK_HEIGHT} />
      </div>

      {/* (Right)  Date rows */}
      <div style={{ width: `calc(100vw - ${rowWidthOffset}px)` }} className="flex-row">
        {_.map(days, (day, idx) => (
          <HeaderDayDisplay day={day} key={idx} onClickDay={onClickDay} />
        ))}
      </div>
    </div>
  );
}
