import { ProviderConfig, useLDClient } from 'launchdarkly-react-client-sdk';
import { LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { PortalUser } from '../stores/rematch/models/auth-store';
import { FIREBASE_CONFIG, LAUNCH_DARKLY_CLIENT_ID } from '../config/app-config';

export const ANONYMOUS_LD_USER_KEY = 'anonymous-goodhuman';

/**
 * Takes a PortalUser (or lack thereof), and from it generates an LDUser we can provide to `ldClient.identify`.
 * Where a user is not authenticated, we identify them with a shared key and mark anonymous as false.
 */
export const ldUserFromPortalUser = (portalUser?: PortalUser | null | undefined): LDSingleKindContext => {
  const fixedLdUserAttributes = {
    kind: 'user',
    projectId: FIREBASE_CONFIG.projectId,
  };

  return portalUser
    ? {
        ...fixedLdUserAttributes,
        key: portalUser.serviceProviderId,
        email: portalUser.email,
        userId: portalUser.userId,
        supportWorkerId: portalUser.supportWorkerId,
        serviceProviderId: portalUser.serviceProviderId,
        serviceProviderName: portalUser.serviceProviderName,
      }
    : {
        ...fixedLdUserAttributes,
        key: ANONYMOUS_LD_USER_KEY,
      };
};

export const providerConfig: ProviderConfig = {
  clientSideID: LAUNCH_DARKLY_CLIENT_ID,
  user: ldUserFromPortalUser(),
  reactOptions: {
    useCamelCaseFlagKeys: true,
  },
  options: {
    bootstrap: 'localStorage',
  },
};

type useIdentifyLDUserProps = {
  portalUser?: PortalUser | null;
};

/**
 * useIdentifyLDUser is a hook with the sole responsibility of identifying LaunchDarkly users.
 */
export async function useIdentifyLDUser(props: useIdentifyLDUserProps) {
  const ldClient = useLDClient();
  const ldUser = ldUserFromPortalUser(props.portalUser);

  await ldClient?.identify(ldUser);
}
