import React, { PureComponent } from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';
import { Text } from 'common-components/typography';
import { SecondaryButton } from 'common-components/buttons';
import { FilterLabel, FilterType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import { timeZone } from 'interfaces/timezone-type';
import { FilterItemMenu } from 'common-components/filter';
import _ from 'lodash';
import { Icon } from 'antd';
import { IFilter } from 'interfaces/filter-interfaces';

interface IMoreFilterMenuProps {
  addNewFilterPanel: string;
  filters: IFilter[];
  saveNewFilterValue: (filterType, newFilterValue, selectionLabel) => void;
  removeFilter: (filterType) => void;
  addFilter: (filterType) => void;
  displayTimezone: timeZone;
  availableFilters: Array<FilterType>;
}

interface IMoreFilterMenuState {
  loading: boolean;
}

class MoreFilterMenu extends PureComponent<IMoreFilterMenuProps, IMoreFilterMenuState> {
  private _addFilter = (filterType) => {
    this.props.addFilter(filterType);
  };
  render() {
    const { addNewFilterPanel, filters, displayTimezone } = this.props;

    return (
      <Menu style={{ minWidth: '300px' }} className={'p-none'}>
        {addNewFilterPanel ? (
          <div className={'anim-slide-left'}>
            <FilterItemMenu
              filter={{ filter: addNewFilterPanel, values: [] }}
              saveNewFilterValue={this.props.saveNewFilterValue}
              removeFilter={this.props.removeFilter}
              canRemove={false}
              displayTimezone={displayTimezone}
            />
          </div>
        ) : (
          <>
            <div className={'pv-small ph-medium bordered-bottom border-standard-gray'}>
              <Text size={'x-large'} weight={'bold'}>
                Filter by
              </Text>
            </div>
            {/*Filter option are displayed alphabetically.*/}
            {_.map(
              _.orderBy(
                _.map(this.props.availableFilters, (availableFilter) => {
                  return { type: availableFilter, label: FilterLabel[availableFilter] };
                }),
                'label',
              ),
              (filter, idx) => {
                return (
                  <MenuItem
                    label={''}
                    className="hover-bg-blue-lightest ph-medium pv-small flex-row justify-between"
                    onClick={() => this._addFilter(filter.type)}
                    text={
                      <div className={'flex-row justify-between align-center'}>
                        {filter.label}
                        <Icon type={'right'} className={'text-size-regular'} />
                      </div>
                    }
                    shouldDismissPopover={!!CommonUtils.findFilter(filter.type, filters)}
                    key={idx}
                  />
                );
              },
            )}

            <div className="text-align-right p-medium bordered-top border-standard-gray">
              <SecondaryButton color={'blue-action bp3-popover-dismiss'}>Cancel</SecondaryButton>
            </div>
          </>
        )}
      </Menu>
    );
  }
}

export default MoreFilterMenu;
