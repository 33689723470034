import type { PropsWithChildren, Ref } from 'react';
import React, { createContext } from 'react';
import type { IconProps } from '..';
import { IconContext } from '../icon/icon-provider';
import { CheckboxProps } from './checkbox';
import type { CheckboxVariants } from './checkbox.css';

type CheckboxElement = Ref<HTMLInputElement>;
export type CheckboxProviderProps = CheckboxProps & {
  checkboxRef?: CheckboxElement;
};

export const CheckboxContext = createContext<CheckboxProviderProps>({} as CheckboxProviderProps);

export function CheckboxProvider(props: CheckboxProviderProps) {
  const { children, ...otherProps } = props;

  return <CheckboxContext.Provider value={otherProps}>{children}</CheckboxContext.Provider>;
}

export type CheckboxIconProviderProps = PropsWithChildren<IconProps & CheckboxVariants>;

export function CheckboxIconProvider(props: CheckboxIconProviderProps) {
  const { children, size, ...otherProps } = props;
  let height;
  let width;

  switch (size) {
    case 'small':
      height = '$size125';
      width = '$size125';
      break;
    case 'medium':
      height = '$size150';
      width = '$size150';
      break;
  }

  const providerProps = { ...otherProps, height, width };

  return <IconContext.Provider value={providerProps}>{children}</IconContext.Provider>;
}
