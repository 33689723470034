import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IShiftSlot } from 'interfaces/shift-interfaces';

interface IBulkRemoveShiftSlotsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTaskSuccess: () => void;
  toBeRemovedShiftSlots: IShiftSlot[];
  serviceId: string;
  serviceDateTimeId: string;
  doRemoveShiftSlot: typeof dispatch.groupServiceStore.doRemoveShiftSlot;
}

interface IRemoveShiftSlotsModalState {
  isLoading: boolean;
  step: number;
}

class BulkRemoveShiftSlotsModal extends Component<IBulkRemoveShiftSlotsModalProps, IRemoveShiftSlotsModalState> {
  state = {
    isLoading: false,
    step: 1,
  };

  private _onRemoveShiftSlots = async () => {
    const { toBeRemovedShiftSlots, serviceId, serviceDateTimeId, doRemoveShiftSlot, onTaskSuccess } = this.props;
    const attendenceIds = _.map(toBeRemovedShiftSlots, (shiftSlot) => shiftSlot.supportWorkerAttendanceId);
    this.setState({ isLoading: true });
    try {
      await doRemoveShiftSlot({
        serviceId,
        serviceDateTimeId,
        supportWorkerAttendanceIds: attendenceIds,
        isRemoveTeamMemberOnly: false,
      });
      this.setState({ step: 2 });
      onTaskSuccess();
    } catch (e) {
      console.error(e);
      notification.error({
        message: <Text weight="bold">Bulk actions failed.</Text>,
        description: (
          <Text className="mt-medium">
            An error occurred while <b>removing shifts</b>. Please try again.
          </Text>
        ),
      });
    }
    this.setState({ isLoading: false });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.setState({ step: 1 });
    }
  };

  render() {
    const { step } = this.state;
    const modalTitle = step === 1 ? 'Remove shifts' : 'Shifts removed';
    const itemCount = this.props.toBeRemovedShiftSlots.length;

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={modalTitle}
        onClose={this.props.onClose}
        canCloseOutside={false}
        width={'medium'}
      >
        {step === 1 && (
          <div>
            <Text>
              You are removing{' '}
              <b>
                {itemCount} shift{itemCount === 1 ? '' : 's'}
              </b>
              . This action is permanent and cannot be reversed. Are you sure you want to continue?
            </Text>
            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this.props.onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onRemoveShiftSlots} loading={this.state.isLoading}>
                Remove shifts
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {step === 2 && (
          <div>
            <Text>
              You have removed{' '}
              <b>
                {itemCount} shift{itemCount === 1 ? '' : 's'}
              </b>
              .
            </Text>
            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.props.onClose} className="rounded-left">
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveShiftSlot: dispatch.groupServiceStore.doRemoveShiftSlot,
});

export default connect(null, mapDispatch)(BulkRemoveShiftSlotsModal);
