import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React, { PureComponent } from 'react';
import '../../../../css/workflow-styles.css';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onClose(): void;
  onConfirm(): void;
}

class ApproveWorkflowStepModal extends PureComponent<Props> {
  render() {
    const { isOpen, onClose, onConfirm, isLoading } = this.props;

    return (
      <ActionModal width="small" isOpen={isOpen} onClose={onClose} title="Approve" verticalAlignment="highest">
        <Paragraph size="x-large" className="mt-medium">
          Are you sure you want to approve this step of the workflow?
        </Paragraph>
        <ActionModalFooter className={'mt-x-large'}>
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={onConfirm} loading={isLoading}>
            Approve
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default ApproveWorkflowStepModal;
