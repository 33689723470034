import React from 'react';
import { PageHeader } from '@good/ui/templates';
import { useTranslation } from 'react-i18next';
import { ServiceDocumentData } from 'utilities/react-trpc';
import { EmptyTablePlaceholder } from 'views/sil-service/components/empty-table-placeholder';
// import { SilTable } from 'views/sil-service/components/table';
import { LaptopDesk } from '@good/illustrations';

type IServiceFilesTabProps = {
  data: ServiceDocumentData[];
};

export const ServiceFilesTab = ({ data }: IServiceFilesTabProps) => {
  const { t } = useTranslation('', { keyPrefix: 'silService.filesTabs.serviceFiles' });

  const fileCount = data.length;

  return (
    <div data-testid='service-files-tab' className='w-full'>
      <PageHeader title={t('title')} subtitle={t('description')} size='h2' />
      {/* <SilTable data={serviceFiles} columns={customerFileColumns} isSelectable={false} /> */}
      {fileCount === 0 ? (
        <EmptyTablePlaceholder
          image={<LaptopDesk />}
          heading={t('empty.heading')}
          description={t('empty.description')}
        />
      ) : null}
    </div>
  );
};
