import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Direction = forwardRef(function Direction(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.987 4.687c-.457.11-1.091.363-2.041.743l-9.42 3.768c-1.294.517-2.193.878-2.77 1.197a2.44 2.44 0 0 0-.45.302c.092.056.247.13.498.214.624.208 1.575.4 2.94.673l.789.158.038.007c.601.12 1.11.222 1.52.351.435.138.825.325 1.154.655.33.33.517.719.655 1.155.13.41.23.918.351 1.52l.008.038.157.788c.274 1.366.465 2.316.674 2.94.084.252.157.407.214.498a2.44 2.44 0 0 0 .301-.45c.319-.576.68-1.476 1.198-2.77l3.768-9.42c.38-.95.632-1.584.743-2.04.05-.21.058-.325.055-.382a1.41 1.41 0 0 0-.382.055Zm.43-.046s-.005 0-.012-.005c.01.002.013.004.012.005Zm-.053-.046c-.004-.007-.005-.011-.004-.012l.004.012Zm-.732-1.366c.563-.137 1.277-.205 1.81.329.534.533.466 1.248.33 1.81-.14.574-.435 1.309-.786 2.186l-.023.057-3.768 9.42-.022.057c-.49 1.224-.885 2.213-1.255 2.882-.188.34-.402.659-.665.893-.283.25-.651.425-1.092.385-.44-.04-.771-.28-1.003-.578-.216-.277-.368-.63-.491-.998-.242-.726-.451-1.77-.71-3.063l-.012-.06-.157-.787c-.13-.65-.217-1.078-.319-1.4-.096-.306-.186-.448-.284-.546-.099-.099-.24-.189-.546-.285-.322-.102-.75-.189-1.4-.319l-.788-.157-.06-.012c-1.293-.258-2.337-.467-3.062-.71-.368-.123-.721-.275-.999-.49-.298-.233-.537-.563-.578-1.004-.04-.44.135-.809.386-1.092.233-.262.553-.476.892-.664.67-.37 1.658-.766 2.883-1.256l.055-.022 9.42-3.768.058-.023c.878-.351 1.613-.645 2.186-.785Z"
        fill="currentColor"
      />
    </Icon>
  );
});
