import { Stack, Text } from '@goodhuman-me/components';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import { PermissionRole } from 'views/team-v2/utils/form-utils';
import ServiceDepartmentRow from '../ServiceDepartmentRow';

interface IProps {
  title: string;
  description: string;
  permissionRolesDepartment: PermissionRole[];
  setPermissionRolesDepartment: (permissionRoles: PermissionRole[]) => void;
  typePermissionPresent: string;
  handleSetTypePermissionPresent: (value: string) => void;
}

const PermissionServiceDepartmentSection: React.FC<IProps> = ({
  title,
  description,
  permissionRolesDepartment,
  setPermissionRolesDepartment,
  typePermissionPresent,
  handleSetTypePermissionPresent,
}) => {
  const { serviceDepartmentListLite } = useSelector((state: IRootState) => state.servicesStore);

  const [permissionRolesDepartmentLiteItem, setPermissionRolesDepartmentLiteItem] = useState<PermissionRole>({
    serviceDepartmentId: null,
    serviceId: null,
    permissionRole: null,
  });

  const handleSetPermissionRolesDepartment = (payload: PermissionRole) => {
    if (!payload.serviceDepartmentId) return;
    if (permissionRolesDepartment.length) {
      let newPermissionRolesDepartment = [...permissionRolesDepartment];
      const isExistServiceDepartmentId = newPermissionRolesDepartment.some(
        (one) => one.serviceDepartmentId === payload.serviceDepartmentId,
      );
      if (!isExistServiceDepartmentId) {
        newPermissionRolesDepartment.push(payload);
      } else {
        if (!payload.serviceId) {
          newPermissionRolesDepartment = newPermissionRolesDepartment.filter(
            (one) => one.serviceDepartmentId !== payload.serviceDepartmentId,
          );
          newPermissionRolesDepartment.push(payload);
        } else {
          const indexByServiceDepartmentId = newPermissionRolesDepartment.findIndex(
            (one) => one.serviceDepartmentId === payload.serviceDepartmentId,
          );
          if (!newPermissionRolesDepartment[indexByServiceDepartmentId].serviceId) {
            newPermissionRolesDepartment.splice(indexByServiceDepartmentId, 1, payload);
          } else {
            const indexByServiceId = newPermissionRolesDepartment.findIndex(
              (one) => one.serviceId === payload.serviceId,
            );
            if (indexByServiceId === -1) {
              newPermissionRolesDepartment.push(payload);
            } else {
              newPermissionRolesDepartment.splice(indexByServiceId, 1, payload);
            }
          }
        }
      }
      setPermissionRolesDepartment(newPermissionRolesDepartment);
    } else {
      setPermissionRolesDepartment([...permissionRolesDepartment, payload]);
    }
  };

  useEffect(() => {
    handleSetPermissionRolesDepartment(permissionRolesDepartmentLiteItem);
  }, [permissionRolesDepartmentLiteItem]);

  return (
    <>
      <Stack
        gap="$space20"
        paddingY="$space200 !important"
        paddingLeft="$space200 !important"
        paddingRight="$space125 !important"
      >
        <Text size="small" color="$black" fontWeight="$semi">
          {title}
        </Text>
        <Text size="small" color="$muted" fontWeight="$normal">
          {description}
        </Text>
      </Stack>
      {serviceDepartmentListLite &&
        serviceDepartmentListLite.map((serviceDepartment) => (
          <Fragment key={serviceDepartment.serviceDepartmentId}>
            <ServiceDepartmentRow
              serviceDepartment={serviceDepartment}
              setPermissionRolesDepartmentLiteItem={setPermissionRolesDepartmentLiteItem}
              permissionRolesDepartment={permissionRolesDepartment}
              setPermissionRolesDepartment={setPermissionRolesDepartment}
              type={serviceDepartment.serviceDepartmentName}
              typePermissionPresent={typePermissionPresent}
              handleSetTypePermissionPresent={handleSetTypePermissionPresent}
            />
          </Fragment>
        ))}
    </>
  );
};

export default PermissionServiceDepartmentSection;
