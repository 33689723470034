import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import GeneralInfoDetailRow from './GeneralInfoDetailRow';
import { ICustomer } from 'interfaces/customer-interfaces';

interface IFundGeneralInformationPanelProps {
  onNextStep: (stepData: any) => void;
  closeCreatePackageModal: () => void;
  selectedCustomer: ICustomer;
}

interface IFundGeneralInformationPanelState {
  isLoading: boolean;
}

class FundGeneralInformationPanel extends Component<
  IFundGeneralInformationPanelProps,
  IFundGeneralInformationPanelState
> {
  state = {
    isLoading: false,

    generalInoInputs: {
      agencyName: '',
      startDate: '',
      expiryDate: '',
      agencyAddress: '',
      phoneNumberCountryCode: 'AU',
      agencyNumber: '',
      agencyEmail: '',
    },
  };

  private _onNextStep = (stepData) => {
    this.props.onNextStep(stepData);
  };

  private _generalInfoSection = () => {
    return (
      <GeneralInfoDetailRow
        onNextStep={this._onNextStep}
        selectedCustomer={this.props.selectedCustomer}
        closeCreatePackageModal={this.props.closeCreatePackageModal}
      />
    );
  };

  render() {
    const { selectedCustomer } = this.props;
    return (
      <React.Fragment>
        <div className="anim-slide-left">
          {' '}
          <Title level={2} className="text-weight-regular">
            Create a New <b>Funding Package</b>
          </Title>
        </div>
        <div>
          <Text>
            You are creating a Funding Package for{' '}
            <b>
              {selectedCustomer.firstName} {selectedCustomer.lastName}
            </b>
            . Please enter the following details
          </Text>
        </div>
        <div className={'mt-small'}>{this._generalInfoSection()}</div>
      </React.Fragment>
    );
  }
}

export default FundGeneralInformationPanel;
