import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Notebook = forwardRef(function Notebook(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.958 2.25h.084c1.369 0 2.454 0 3.32.088.888.09 1.629.28 2.277.713.519.346.964.791 1.31 1.31.434.648.623 1.39.713 2.277.088.866.088 1.951.088 3.32v4.084c0 1.369 0 2.454-.088 3.32-.09.888-.28 1.629-.712 2.277a4.753 4.753 0 0 1-1.311 1.31c-.648.434-1.39.623-2.277.713-.866.088-1.951.088-3.32.088h-.084c-1.369 0-2.454 0-3.32-.088-.888-.09-1.629-.28-2.277-.712a4.751 4.751 0 0 1-1.31-1.311c-.434-.648-.623-1.39-.713-2.277-.02-.193-.035-.397-.047-.612H3a.75.75 0 0 1 0-1.5h1.253c-.003-.376-.003-.778-.003-1.208V12.75H3a.75.75 0 0 1 0-1.5h1.25V9.958c0-.43 0-.832.003-1.208H3a.75.75 0 0 1 0-1.5h1.291c.012-.215.027-.42.047-.612.09-.888.28-1.629.713-2.277a4.75 4.75 0 0 1 1.31-1.31c.648-.434 1.39-.623 2.277-.713.866-.088 1.951-.088 3.32-.088Zm-6.205 6.5H7a.75.75 0 0 0 0-1.5H5.794c.01-.162.022-.315.036-.46.079-.77.227-1.235.468-1.596a3.25 3.25 0 0 1 .896-.896c.361-.241.827-.39 1.596-.468.781-.08 1.79-.08 3.21-.08s2.429 0 3.21.08c.77.079 1.235.227 1.596.468.354.237.66.542.896.896.241.361.39.827.468 1.596.08.781.08 1.79.08 3.21v4c0 1.42 0 2.429-.08 3.21-.079.77-.227 1.235-.468 1.596-.237.354-.542.66-.896.896-.361.241-.827.39-1.596.468-.781.08-1.79.08-3.21.08s-2.429 0-3.21-.08c-.77-.079-1.235-.227-1.596-.468a3.25 3.25 0 0 1-.896-.896c-.241-.361-.39-.827-.468-1.596a11.366 11.366 0 0 1-.036-.46H7a.75.75 0 0 0 0-1.5H5.753c-.003-.378-.003-.793-.003-1.25v-1.25H7a.75.75 0 0 0 0-1.5H5.75V10c0-.457 0-.872.003-1.25ZM15 6.25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0V7a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
