import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const VolumeMute = forwardRef(function VolumeMute(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.715 4.831c-.405.371-.92.987-1.675 1.893l-.264.317c-.03.035-.06.07-.088.105-.502.604-.9 1.083-1.458 1.344-.558.262-1.18.261-1.967.26H10c-.964 0-1.612.002-2.095.067-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788-.064.484-.066 1.131-.066 2.095 0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.788.3.483.064 1.131.066 2.095.066h.263c.786 0 1.409-.002 1.967.26.557.26.956.74 1.458 1.344l.088.105.264.317c.755.906 1.27 1.522 1.675 1.893.181.165.295.24.359.272.028-.065.067-.195.1-.438.074-.545.076-1.348.076-2.527V7.524c0-1.179-.002-1.982-.076-2.526a1.794 1.794 0 0 0-.1-.44 1.798 1.798 0 0 0-.359.273Zm.424-.297s-.003.002-.01.002l.01-.002Zm-.093-.028-.007-.008s.003.001.007.008Zm-.007 14.996.007-.008c-.004.007-.007.009-.007.008Zm.09-.038c.007 0 .01.002.01.002l-.01-.002Zm-1.426-15.74c.46-.42 1.12-.887 1.893-.607.773.28.98 1.061 1.065 1.679.09.657.09 1.564.09 2.666V16.538c0 1.102 0 2.009-.09 2.666-.084.618-.292 1.4-1.065 1.68-.774.28-1.434-.188-1.893-.609-.49-.448-1.07-1.144-1.776-1.991l-.04-.048-.264-.317c-.634-.761-.813-.95-1.03-1.051-.216-.101-.475-.118-1.466-.118h-.179c-.898 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.725-.456-.456-.642-1.023-.726-1.65-.08-.595-.08-1.345-.08-2.243v-.104c0-.899 0-1.648.08-2.242.084-.628.27-1.195.726-1.65.455-.456 1.022-.642 1.65-.726.594-.08 1.344-.08 2.242-.08h.179c.99 0 1.25-.017 1.466-.118.217-.101.396-.29 1.03-1.051l.264-.317.04-.048c.706-.846 1.286-1.543 1.776-1.991Z"
        fill="currentColor"
      />
    </Icon>
  );
});
