import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { notification } from 'antd';
import { Paragraph, FieldLabel, Text } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import moment from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';

interface FinishGroupBookingModalProps {
  isOpen: any;
  onClose: any;
  booking: any;
  doFinishGroupBooking: any;
}
interface FinishGroupBookingModalState {
  step: number;
  canManuallyClose: boolean;
  title: string;
  endDateTime: Date;
  isLoading: boolean;
  isEndTimeBeforeStartTimeError: boolean;
}

export class FinishGroupBookingModal extends Component<FinishGroupBookingModalProps, FinishGroupBookingModalState> {
  state = {
    step: 1,
    canManuallyClose: true,
    title: '',
    endDateTime: null,
    isLoading: false,
    isEndTimeBeforeStartTimeError: false,
  };

  private _updateEndDate = (event) => {
    this.setState({
      endDateTime: event,
    });
  };

  private _onChangeEndTime = async (date) => {
    const { booking } = this.props;
    const endDateTime = moment.tz(CommonUtils.formatCeilingDateTime(date), booking.timezone);

    this.setState({
      endDateTime: endDateTime.toDate(),
      isEndTimeBeforeStartTimeError: moment(endDateTime).isSameOrBefore(booking.customerCheckedInDateTime),
    });
  };

  renderView = () => {
    const { step, isEndTimeBeforeStartTimeError } = this.state;
    const { booking } = this.props;
    const { firstName, lastName, timezone } = booking;
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>Please input the date/time the customer finished this session.</Paragraph>

          <div className="mt-small line-height-120 bg-quaternary p-medium bordered border-standard-gray rounded-big">
            <div className="flex-row">
              <div className="flex-1 mr-large">
                <div className="flex-row mt-x2-small">
                  <div>
                    <FieldLabel text={'finish date'} />
                    <DatePicker
                      dateFormat="d/M/yyyy"
                      className="gh-datepicker rounded mr-small"
                      calendarClassName="gh-datepicker-calendar"
                      placeholderText="End date"
                      onChange={this._onChangeEndTime}
                      selected={this.state.endDateTime}
                    />
                  </div>
                  <div>
                    <FieldLabel text={'customer finish time'} />
                    <TimeInput
                      size="large"
                      className="bg-white"
                      value={moment.tz(this.state.endDateTime, timezone)}
                      onChange={this._onChangeEndTime}
                    />
                  </div>
                </div>
                {isEndTimeBeforeStartTimeError && <Text color={'red-dark'}>End time must be after start time.</Text>}
              </div>
            </div>
          </div>

          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal} disabled={this.state.isLoading}>
              Cancel
            </GhostButton>
            <PrimaryButton
              size="large"
              icon="check"
              onClick={this.onAccept}
              disabled={isEndTimeBeforeStartTimeError}
              loading={this.state.isLoading}
            >
              Finish booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>
            You have finished {firstName} {lastName} booking for this session
          </Paragraph>

          <FieldLabel text={'finish time'} />
          <Text weight="bold">{moment.tz(this.state.endDateTime, timezone).format('h:mm a, DD MMM YYYY')}</Text>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen && this.props.booking) {
      const { booking } = this.props;
      this.setState({
        step: 1,
        title: 'Finish booking',
        endDateTime: moment
          .tz(CommonUtils.formatCeilingDateTime(new Date(booking.endDateTime)), booking.timezone)
          .toDate(),
      });
    }
  }

  onAccept = async () => {
    const { booking } = this.props;
    try {
      this.setState({ canManuallyClose: false, isLoading: true });
      await this.props.doFinishGroupBooking({
        serviceId: booking.serviceId,
        serviceDateTimeId: booking.serviceDateTimeId,
        attendances: [
          {
            attendanceId: booking.bookingId,
            endDateTime: this.state.endDateTime,
          },
        ],
      });
      this.setState({ step: 2, canManuallyClose: true, title: 'Booking finished', isLoading: false });
    } catch (e) {
      this.setState({ canManuallyClose: true, isLoading: false });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: '' }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}
