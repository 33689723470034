import React, { useEffect, useState } from 'react';

import { v4 } from 'uuid';

import _ from 'lodash';
import moment from 'moment';
import { Avatar } from 'antd';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FieldLabel, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';

import { IShiftCustomerAssignment } from 'interfaces/assign-customer-interfaces';
import { CustomerDisplayCard } from 'views/group-services/booking-modals/AssignCustomerToWorker/CustomerDisplayCard';
import { AssignedWorkerCard } from 'views/group-services/booking-modals/AssignCustomerToWorker/AssignedWorkerCard';
import { useFetchShiftSlotsInSession } from 'stores/hooks/query-hooks/use-query-shift-customer-assignments';
import { saveShiftCustomerAssignments } from 'stores/queries/group-services/group-services-queries';
import Utils from 'utilities/Utils';

// import { customerAssignments as dummyAssignments, workersData } from 'views/wip/tmp-modal-tests/dummy-assignment-data';

interface AssignCustomerToWorkerModalProps {
  isOpen: any;
  onClose: any;
  booking: any;
  doAssignToTeamMember: any;
  targetCustomerId?: string;
}

// Assign customers to worker modal
export function AssignCustomerToWorkerModal({
  isOpen,
  onClose,
  targetCustomerId,
  booking,
}: AssignCustomerToWorkerModalProps) {
  const [canManuallyClose, setCanManuallyClose] = useState(true);
  const [customerAssignments, setCustomerAssignments] = useState<IShiftCustomerAssignment[]>([]);

  const {
    firstName = '',
    lastName = '',
    customerUserId = '',
    customerAvatarUrl = '',
    attendanceId = '',
    serviceId = '',
    serviceDateTimeId = '',
    startDateTime = new Date(),
    endDateTime = new Date(),
  } = booking || {};

  const { data: workerData } = useFetchShiftSlotsInSession(
    {
      serviceId,
      serviceDateTimeId,
    },
    true,
  );

  // target assignment = assignments for this customer
  const targetAssignments = _.filter(
    customerAssignments,
    (assignment) => assignment.customerUserId === targetCustomerId,
  );

  const workers =
    workerData &&
    _.map(
      _.filter(workerData.data.shiftSlots, (shiftSlot) => !Utils.isEmpty(shiftSlot.supportWorkerUserId)),
      (shiftSlot) => {
        return {
          supportWorkerAttendanceId: shiftSlot.supportWorkerAttendanceId,
          supportWorkerUserId: shiftSlot.supportWorkerUserId,
          supportWorkerFirstName: shiftSlot.firstName,
          supportWorkerLastName: shiftSlot.lastName,
          supportWorkerAttachmentUrl: shiftSlot.attachmentUrl,
        };
      },
    );

  const otherAssignments = _.filter(
    customerAssignments,
    (assignment) => assignment.customerUserId !== targetCustomerId,
  );

  const hasTargetAssignments = !_.isEmpty(customerAssignments);
  const hasOtherAssignments = !_.isEmpty(otherAssignments);

  const uniqueCustomers = _.chain(otherAssignments).groupBy('supportWorkerAttendanceId').keys().value();

  useEffect(() => {
    if (booking) {
      setCustomerAssignments(booking.shiftCustomerAssignments);
    }
  }, [setCustomerAssignments]);

  // Reset modal to initial state
  function resetModal() {
    setCanManuallyClose(true);
  }

  // Close modal
  function onCloseAction() {
    onClose();
  }

  // Save modal
  async function onSaveAction() {
    await saveShiftCustomerAssignments({
      attendanceId,
      serviceId,
      serviceDateTimeId,
      attendances: _.map(
        _.filter(customerAssignments, (assignment) => !Utils.isEmpty(assignment.attendanceId)),
        (assignment) => {
          return {
            supportWorkerAttendanceId: assignment.supportWorkerAttendanceId,
            startDateTime: assignment.startDateTime,
            endDateTime: assignment.endDateTime,
            description: assignment.description,
          };
        },
      ),
    });
    onClose();
  }

  // Assignments
  // Add a new assignment
  function onNewAssignment() {
    onAddRow();
  }

  // Update the current worker assignment
  function onUpdateAssignment({ workerId }) {
    const worker = _.find(workers, { supportWorkerUserId: workerId });

    const updatedRows = _.map(targetAssignments, (assignment) => ({
      ...assignment,

      supportWorkerUserId: worker.supportWorkerUserId,
      supportWorkerFirstName: worker.supportWorkerFirstName,
      supportWorkerLastName: worker.supportWorkerLastName,
      supportWorkerAttachmentUrl: worker.supportWorkerAttachmentUrl,
    }));

    setCustomerAssignments([
      ...updatedRows,
      ..._.filter(customerAssignments, (assignment) => assignment.customerUserId !== targetCustomerId),
    ]);
  }

  // Row functions

  // Save time assignment row
  function onSaveRow({ rowId }) {
    let row = _.find(customerAssignments, (a) => a.shiftCustomerAssignmentId === rowId);
    row = { ...row, isEditing: false };
    const newArray = [row, ..._.filter(customerAssignments, (a) => a.shiftCustomerAssignmentId !== rowId)];
    setCustomerAssignments(newArray);
  }

  // Add a new time assignment row
  function onAddRow() {
    const newShift: IShiftCustomerAssignment = {
      shiftCustomerAssignmentId: v4(), // temporarily assign a v4
      supportWorkerAttendanceId: '',
      attendanceId: '',
      startDateTime: moment(startDateTime).toDate(),
      endDateTime: moment(endDateTime).toDate(),
      description: '',
      isEditing: true,
      isNew: true,
    };

    setCustomerAssignments([...customerAssignments, newShift]);
  }

  // Update time assignment row
  function onUpdateRow({ rowId, field, value }) {
    let row = _.find(customerAssignments, (a) => a.shiftCustomerAssignmentId === rowId);
    row[field] = value;
    const newArray = [row, ..._.filter(customerAssignments, (a) => a.shiftCustomerAssignmentId !== rowId)];
    setCustomerAssignments(newArray);
  }

  // Toggle edit mode for time assignment row
  function onEditRow({ rowId }) {
    let row = _.find(customerAssignments, (a) => a.shiftCustomerAssignmentId === rowId);
    row = { ...row, isEditing: true };
    const newArray = [row, ..._.filter(customerAssignments, (a) => a.shiftCustomerAssignmentId !== rowId)];
    setCustomerAssignments(newArray);
  }

  // Delete time assignment row
  function onDeleteRow({ rowId }) {
    const newArray = _.filter(customerAssignments, (a) => a.shiftCustomerAssignmentId !== rowId);
    setCustomerAssignments(newArray);
  }

  return (
    <ActionModal
      isOpen={false}
      width="x-large"
      title={'Assign to team member'}
      onClose={onCloseAction}
      canCloseOutside={canManuallyClose}
      showCloseButton={canManuallyClose}
      verticalAlignment="highest"
    >
      <div className="line-height-135">
        {/* Customer display */}
        <div className="flex-1 mr-large mb-medium">
          <FieldLabel text={'CUSTOMER'} />
          <div className="flex-row align-center mt-x-small">
            <Avatar shape={'circle'} icon={'user'} className="mr-small" src={customerAvatarUrl} />
            <Text className="mr-small">
              {firstName} {lastName}
            </Text>
          </div>
        </div>

        <div className="line-height-135 mb-medium">
          <Text>This customer has been assigned to the following team member : </Text>
        </div>

        {/* Worker card */}
        <div className="mb-large">
          {!hasTargetAssignments && (
            <div>
              <div className="bg-quaternary flex-row align-center p-small bordered-bottom rounded-big">
                <Text>No team member assigned to this customer.</Text>
              </div>
              <div className="ml-small mt-x-small">
                <HyperlinkButton onClick={onNewAssignment}>Assign to team member</HyperlinkButton>
              </div>
            </div>
          )}

          {hasTargetAssignments && (
            <AssignedWorkerCard
              workers={workers}
              workerAssignments={targetAssignments}
              onUpdateRow={onUpdateRow}
              onEditRow={onEditRow}
              onDeleteRow={onDeleteRow}
              onSaveRow={onSaveRow}
              onAddRow={onAddRow}
              onUpdateWorkerAssignment={onUpdateAssignment}
            />
          )}
        </div>

        {/* Other customers card */}
        {hasOtherAssignments && (
          <div>
            <div className="mb-x-small">
              <FieldLabel text={'OTHER CUSTOMERS ASSIGNED TO THIS TEAM MEMBER'} />
            </div>
            <div className="bg-tertiary p-x-small rounded-big overflow-hidden">
              {_.map(uniqueCustomers, (customerId, idx) => (
                <CustomerDisplayCard
                  customerAssignments={_.filter(
                    otherAssignments,
                    (assignment) => assignment.customerUserId === customerId,
                  )}
                  key={idx + customerId}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <ActionModalFooter align="right">
        <SecondaryButton size="large" className="mr-medium" onClick={onCloseAction}>
          Cancel
        </SecondaryButton>
        <PrimaryButton size="large" onClick={onSaveAction}>
          Save
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
}
