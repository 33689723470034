import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const LoadingTrolley = forwardRef(function LoadingTrolley(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 1.25h.5V2v-.75a3.664 3.664 0 0 1 3.621 3.107l.838 5.441c.19 1.233.322 2.089.505 2.73.177.622.377.95.645 1.18.267.23.622.377 1.263.458.34.042.734.063 1.205.074a3.502 3.502 0 0 1-.247-.946c-.08-.594-.08-1.344-.08-2.242v-.104c0-.898 0-1.648.08-2.242.084-.628.27-1.195.726-1.65.455-.456 1.022-.642 1.65-.726.594-.08 1.344-.08 2.242-.08h2.104c.899 0 1.648 0 2.242.08.628.084 1.195.27 1.65.726.456.455.642 1.022.726 1.65.08.594.08 1.344.08 2.242v.104c0 .899 0 1.648-.08 2.242-.046.34-.12.66-.252.956H22a.75.75 0 1 1 0 1.5H11.098c-1.186 0-2.146 0-2.913-.096-.797-.1-1.478-.314-2.053-.808-.575-.493-.89-1.133-1.11-1.906-.212-.743-.358-1.692-.538-2.864l-.008-.05-.837-5.44A2.164 2.164 0 0 0 1.5 2.75H1a.75.75 0 1 1 0-1.5Zm15 13c.964 0 1.612-.002 2.095-.066.461-.063.659-.17.789-.3.13-.13.237-.328.3-.79.064-.482.066-1.13.066-2.094s-.002-1.612-.067-2.095c-.062-.461-.169-.659-.3-.789-.13-.13-.327-.237-.788-.3-.483-.064-1.131-.066-2.095-.066h-2c-.964 0-1.612.002-2.095.067-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788-.064.483-.066 1.131-.066 2.095 0 .964.002 1.612.066 2.095.063.461.17.659.3.789.13.13.328.237.79.3.482.065 1.13.066 2.094.066h2ZM7.75 20a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM9 17.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5ZM17.75 20a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM19 17.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
