// TODO: Deprecate this file and its uses in favour of updated version
// in src/views/budgets/components/support-categories/support-categories.ts
// in Mitch's latest work https://github.com/goodhuman-me/workspace/pull/977

export const supportCategories = {
  1: {
    id: 1,
    name: 'Assistance With Daily Life',
    type: 'Core Supports',
    color: '#EAECF5', // $lavenderLight1
  },
  2: {
    id: 2,
    name: 'Transport',
    type: 'Core Supports',
    color: '#8DC2D5', // $canaryRef
  },
  3: {
    id: 3,
    name: 'Consumables',
    type: 'Core Supports',
    color: '#574066', // $lilacDark2
  },
  4: {
    id: 4,
    name: 'Assistance With Social and Community Participation',
    type: 'Core Supports',
    color: '#E6F2FF', // $blueLight1
  },
  5: {
    id: 5,
    name: 'Assistive Technology',
    type: 'Capital Supports',
    color: '#369A7F', // $greenRef
  },
  6: {
    id: 6,
    name: 'Home Modifications',
    type: 'Capital Supports',
    color: '#7280BA', // $lavenderRef
  },
  7: {
    id: 7,
    name: 'Support Coordination',
    type: 'Capacity Building Supports',
    color: '#384E55', // $lilacLight1
  },
  8: {
    id: 8,
    type: 'Capacity Building Supports',
    name: 'Improved Living Arrangements',
    color: '#0080FF', // $blueRef
  },
  9: {
    id: 9,
    type: 'Capacity Building Supports',
    name: 'Increased Social and Community Participation',
    color: '#1B4D40', // $greenDark1
  },
  10: {
    id: 10,
    type: 'Capacity Building Supports',
    name: 'Finding and Keeping a Job',
    color: '#1A2859', // $lavendarDark1
  },
  11: {
    id: 11,
    type: 'Capacity Building Supports',
    name: 'Improved Relationships',
    color: '#E8F3F7', // $canaryLight1
  },
  12: {
    id: 12,
    type: 'Capacity Building Supports',
    name: 'Improved Health and Wellbeing',
    color: '#AE80CB', // $lilacRef
  },
  13: {
    id: 13,
    type: 'Capacity Building Supports',
    name: 'Improved Learning',
    color: '#004D99', // $blueDark1
  },
  14: {
    id: 14,
    type: 'Capacity Building Supports',
    name: 'Improved Life Choices',
    color: '#C3E1D9', // $greenLight1
  },
  15: {
    id: 15,
    type: 'Capacity Building Supports',
    name: 'Improved Daily Living Skills',
    color: '#384E55', // $canaryDark1
  },
};
