import React from 'react';
import { BaseStyleProps, compose, css, HTMLProps, useStyleProps } from '@goodhuman-me/components';
import { filterDOMProps } from '@react-aria/utils';

export type DisabledMenuItemProps = BaseStyleProps &
  HTMLProps<HTMLLIElement> & {
    label: string;
  };

/**
 * Component for a menu item to replace <MenuItem /> in the case where it is unusable.
 * This pattern shows a given user why the item is disabled, and prevents screen-readers from interpreting it
 * as a button.
 * @param string Takes in a string label to display in place of the normal button.
 * @constructor
 */
const DisabledMenuItem: React.FC<DisabledMenuItemProps> = ({ label, ...props }) => {
  const { styleProps, ...otherProps } = useStyleProps(props);

  const disabledMenuItemDefaultStyles = {
    fontStyle: 'italic',
    // Explicitly prevent pointer events so this doesn't appear as a button.
    pointerEvents: 'none',
  };

  // bp3-menu-item is the CSS attribute used by the Antd <MenuItem /> component.
  return (
    <li
      className={`bp3-menu-item text-color-gray ${compose(css(disabledMenuItemDefaultStyles, styleProps))}`}
      {...filterDOMProps(otherProps)}
    >
      {label}
    </li>
  );
};

export default DisabledMenuItem;
