import React, { useState } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Avatar, Col, Empty, Icon, Skeleton } from 'antd';
import { FieldLabel, Text } from 'common-components/typography';
import { FilterSection, YearAndMonthFilter } from 'common-components/filter';
import moment from 'moment-timezone';
import _ from 'lodash';
import { IGroupServiceTeamMember } from 'interfaces/service-interfaces';
import { PrimaryButton } from 'common-components/buttons';
import { GridHeader, GridRow } from 'common-components/grids';
import * as H from 'history';
import { BookingTypeIcon, StatusTag } from 'common-components/tags';
import { useFetchTeamMemberSchedule } from 'stores/hooks/query-hooks/use-query-fetch-team-member-schedule';
import { FilterType, ServiceType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';

interface IFilter {
  year: number;
  month: number;
}

function ViewTeamMemberScheduleModal({
  onClose,
  selectedTeamMember,
  selectedGroupService,
}: {
  onClose: () => void;
  selectedTeamMember: IGroupServiceTeamMember;
  selectedGroupService: any;
}) {
  const [filter, setFilter] = useState<IFilter>({ year: 0, month: 0 });
  const [filteredShiftsList, setFilteredShiftsList] = useState<any>([]);
  const availableFilters = [FilterType.SERVICE, FilterType.SHIFT_SLOT_STATUS];

  const defaultFilters = [
    {
      filter: FilterType.SERVICE,
      values: [selectedGroupService.serviceId],
      selectionLabel: selectedGroupService.serviceName,
    },
    {
      filter: FilterType.SHIFT_SLOT_STATUS,
      values: [],
      selectionLabel: CommonUtils.getFilterSettings(FilterType.SHIFT_SLOT_STATUS).fullSelectionName,
    },
  ];
  const [activeFilters, setActiveFilters] = useState<any>(defaultFilters);

  const afterFetch = (apiResponse) => {
    const data = apiResponse.data.shifts;
    const shifts = data ? _.sortBy(data, 'shiftStartDateTime') : [];
    const year = moment(shifts[0] && shifts[0].shiftStartDateTime).year();
    const month = moment(shifts[0] && shifts[0].shiftStartDateTime).month();
    setFilter({
      year,
      month,
    });
    setFilteredShiftsList(
      _.filter(
        shifts,
        (shift) =>
          moment(shift.shiftStartDateTime).year() === year && moment(shift.shiftStartDateTime).month() === month,
      ),
    );
  };

  const { data, isLoading } = useFetchTeamMemberSchedule(
    {
      serviceIds: _.find(activeFilters, (activeFilter) => activeFilter.filter === FilterType.SERVICE).values,
      supportWorkerId: selectedTeamMember?.supportWorkerId,
      shiftSlotStatuses: _.find(activeFilters, (activeFilter) => activeFilter.filter === FilterType.SHIFT_SLOT_STATUS)
        .values,
    },
    filter.year === 0,
    afterFetch,
  );

  const shifts = data ? _.sortBy(data?.data.shifts, 'shiftStartDateTime') : [];

  const _updateFilter = (e) => {
    setFilter(e);
    setFilteredShiftsList(
      _.filter(
        shifts,
        (shift) =>
          moment(shift.shiftStartDateTime).year() === e.year && moment(shift.shiftStartDateTime).month() === e.month,
      ),
    );
  };

  const _onChangeFilter = (e) => {
    setActiveFilters(e);
    setFilter({
      year: 0,
      month: 0,
    });
  };

  const TeamMemberFullName = selectedTeamMember
    ? `${selectedTeamMember.teamMemberFirstName} ${selectedTeamMember.teamMemberLastName}`
    : '';

  const SessionEmptyState = () => (
    <div className="flex-1 bg-white mt-x2-large align-center flex-column">
      <div className="">
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
      </div>
      <Text size="x2-large" color="secondary" weight="bold">
        No session found for the selected month.
      </Text>
    </div>
  );

  return (
    <ActionModal width="x2-large" isOpen={true} onClose={onClose} title={'View team member schedule'}>
      {isLoading ? (
        <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
      ) : (
        <>
          {' '}
          <Text>You’re currently viewing the schedule for this following team member:</Text>
          <div className="flex-row justify-between">
            <div className="flex-row align-center">
              <Avatar
                size="large"
                className="mr-small"
                icon="user"
                src={selectedTeamMember && selectedTeamMember.teamMemberAvatarUrl}
              />
              <Text weight="bold">{TeamMemberFullName}</Text>
            </div>
            <div className="flex-row align-center" style={{ marginTop: '1px' }}>
              <div className="mr-small">
                <Icon type={'filter'} className={'text-color-tertiary'} />
              </div>
              <div style={{ marginTop: '10px' }}>
                <FilterSection
                  availableFilters={availableFilters}
                  filters={activeFilters}
                  onChangeFilter={_onChangeFilter}
                  displayTimezone={null}
                  displayMoreFilter={false}
                  usePortal={false}
                />
              </div>
            </div>
          </div>
          <YearAndMonthFilter
            filter={filter}
            startDateTime={moment(shifts[0] && shifts[0].shiftStartDateTime)}
            endDateTime={shifts.length > 0 && _.last(shifts) ? moment(_.last(shifts).shiftEndDateTime) : moment()}
            updateFilter={_updateFilter}
            hideEmptyDates={true}
            dateList={_.map(shifts, (shift) => {
              return {
                ...shift,
                startDateTime: shift.shiftStartDateTime,
                endDateTime: shift.shiftEndDateTime,
              };
            })}
          />
          <GridHeader bordered containerClassName="border-width-medium border-secondary">
            <Col span={6} className=" bg-white">
              <FieldLabel text="SHIFT TIME" />
            </Col>
            <Col span={8} className=" bg-white">
              <FieldLabel text={'SERVICE'} />
            </Col>
            <Col span={4} className=" bg-white">
              <FieldLabel text={'STATUS'} />
            </Col>
          </GridHeader>
          {filteredShiftsList.length === 0 ? (
            <SessionEmptyState />
          ) : (
            _.map(filteredShiftsList, (shift) => {
              const date = moment.tz(shift.shiftStartDateTime, selectedGroupService.timezone);
              const startDate = moment.tz(shift.shiftStartDateTime, selectedGroupService.timezone);
              const endTime = moment.tz(shift.shiftEndDateTime, selectedGroupService.timezone);
              return (
                <GridRow className="">
                  <Col span={6}>
                    <Text>{date.format('ddd, D MMM YYYY')}</Text>
                    <br />
                    <Text type="secondary">
                      {startDate.format('HH:mm a')} - {endTime.format('HH:mm a')}
                    </Text>
                  </Col>
                  <Col span={8}>
                    {shift.serviceType === ServiceType.GROUP ? (
                      <>
                        <div className="flex-row align-center">
                          <div>
                            <BookingTypeIcon type={shift.serviceType} className={'mr-small'} />
                          </div>
                          <div>
                            <div className="mr-0">
                              <Text>{shift.serviceName}</Text>
                            </div>
                            <div>
                              <Text type="secondary">{`${moment
                                .tz(shift.sessionStartDateTime, shift.timezone)
                                .format('Do MMM YYYY')}, ${moment
                                .tz(shift.sessionStartDateTime, shift.timezone)
                                .format('HH:mm a')} - ${moment
                                .tz(shift.sessionEndDateTime, shift.timezone)
                                .format('HH:mm a')}`}</Text>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex-row align-center">
                          <div>
                            <BookingTypeIcon type={shift.serviceType} className={'mr-small'} />
                          </div>
                          <div className="mr-0">
                            <Text>{shift.serviceName}</Text>
                          </div>
                        </div>
                      </>
                    )}
                  </Col>
                  <Col span={4}>
                    <StatusTag status={shift.shiftSlotStatus} size="small" />
                  </Col>
                </GridRow>
              );
            })
          )}
          <ActionModalFooter className="mt-medium">
            <PrimaryButton size="large" onClick={onClose}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </>
      )}
    </ActionModal>
  );
}

export default ViewTeamMemberScheduleModal;
