import { IGroupServiceCustomerRatio } from 'interfaces/service-interfaces';
import { convertToDate } from 'utilities/date-utils';
import { filterHiddenCustomRatios } from './filter-hidden-ratios';

export function parseInitialRatio(ratio?: IGroupServiceCustomerRatio): IGroupServiceCustomerRatio | null {
  if (!ratio?.startDateTime || !ratio?.endDateTime) {
    return null;
  }

  let parsedRatio: IGroupServiceCustomerRatio = {
    ...ratio,
    startDateTime: convertToDate(ratio.startDateTime),
    endDateTime: convertToDate(ratio.endDateTime),
    customRatio: ratio.customRatio?.map((customRatio_) => ({
      ...customRatio_,
      startDateTime: convertToDate(customRatio_.startDateTime),
      endDateTime: convertToDate(customRatio_.endDateTime),
    })),
  };

  const teamMemberCustomerRatio =
    ratio?.teamMemberCustomerRatio ??
    (ratio?.isCustomRatio ? undefined : ratio?.customRatio?.[0]?.teamMemberCustomerRatio);

  parsedRatio.teamMemberCustomerRatio = teamMemberCustomerRatio;
  parsedRatio = filterHiddenCustomRatios(parsedRatio);

  return parsedRatio;
}
