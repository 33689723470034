import { Text } from 'common-components/typography';
import moment from 'moment-timezone';
import React from 'react';
import { IExtendedMessage } from 'interfaces/message-interfaces';

export function DateTag({ message }: { message: IExtendedMessage }) {
  const { createdOn } = message;

  return (
    <div className="align-center text-align-center pt-medium pb-small">
      <Text size="small" color="secondary" weight="bold" className="select-none">
        {moment.tz(createdOn, message.preferredTimezone).format('dddd, D MMMM YYYY')}
      </Text>
    </div>
  );
}
