import { Avatar, Col } from 'antd';
import React from 'react';
import { Text } from '@good/components';

type TmMgmtSentToColumnParams = {
  attachmentUrl: string;
  firstName: string;
  lastName: string;
  email?: string;
};

export const TmMgmtSentToColumn = ({ attachmentUrl, firstName, lastName, email }: TmMgmtSentToColumnParams) => (
  <Col span={10}>
    <div className="align-center flex-row">
      <Avatar size="large" className="mr-medium" shape="square" icon="user" src={attachmentUrl} />
      <div>
        <Text size="md" className="font-bold">{`${firstName} ${lastName}`}</Text>
        <br />
        <Text>{email ?? 'Email not set'}</Text>
      </div>
    </div>
  </Col>
);
