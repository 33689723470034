import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FrameBounds = forwardRef(function FrameBounds(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 2a.75.75 0 0 0-1.5 0v2.25H2a.75.75 0 1 0 0 1.5h2.25v12.5H2a.75.75 0 0 0 0 1.5h2.25V22a.75.75 0 0 0 1.5 0v-2.25h12.5V22a.75.75 0 0 0 1.5 0v-2.25H22a.75.75 0 1 0 0-1.5h-2.25V5.75H22a.75.75 0 1 0 0-1.5h-2.25V2a.75.75 0 1 0-1.5 0v2.25H5.75V2Zm12.5 16.25V5.75H5.75v12.5h12.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
