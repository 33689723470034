import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FastRewind = forwardRef(function FastRewind(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.817 5.76c-.346.163-.805.467-1.51.937L3.912 10.96c-.585.39-.952.637-1.183.846a.878.878 0 0 0-.173.194l.002.005c.017.03.062.09.171.189.231.21.598.456 1.183.846l6.395 4.263c.705.47 1.164.774 1.51.937.166.079.256.1.297.105.02-.037.051-.124.078-.306.056-.378.058-.928.058-1.776V14a.75.75 0 0 1 1.166-.624l5.89 3.927c.706.47 1.165.774 1.51.937.167.079.257.1.298.105.02-.037.051-.124.078-.306.056-.378.058-.928.058-1.776V7.737c0-.848-.002-1.398-.058-1.776a1.053 1.053 0 0 0-.078-.306 1.049 1.049 0 0 0-.297.105c-.346.163-.805.467-1.51.937l-5.89 3.927A.75.75 0 0 1 12.25 10V7.737c0-.848-.002-1.398-.058-1.776a1.053 1.053 0 0 0-.078-.306 1.049 1.049 0 0 0-.297.105Zm.328-.104-.013-.002c.01 0 .013.001.013.002Zm-.04-.016c-.006-.005-.008-.009-.008-.01 0 0 .004.002.009.01Zm9.04.016-.013-.002c.01 0 .013.001.012.002Zm-.04-.016c-.006-.005-.008-.009-.008-.01l.009.01Zm-.008 12.73c0-.001.002-.005.009-.01l-.009.01Zm.035-.024c.007-.003.012-.002.012-.002.001 0-.003.002-.012.002Zm-9.035.024c0-.001.002-.005.009-.01-.005.008-.009.01-.009.01Zm.035-.024c.007-.003.012-.003.013-.002 0 0-.004.002-.013.002Zm-.956-13.942c.472-.223 1.063-.393 1.65-.078.587.314.774.9.85 1.415.074.502.074 1.165.074 1.936V8.6l4.725-3.15.05-.033c.641-.428 1.193-.796 1.651-1.012.472-.223 1.063-.393 1.65-.078.587.314.774.9.85 1.415.074.502.074 1.165.074 1.936v8.646c0 .771 0 1.434-.074 1.936-.076.515-.263 1.101-.85 1.415-.587.314-1.178.145-1.65-.078-.458-.216-1.01-.584-1.652-1.012l-.05-.033-4.724-3.15V16.323c0 .771 0 1.434-.074 1.936-.076.515-.263 1.101-.85 1.415-.587.314-1.178.145-1.65-.078-.458-.216-1.01-.584-1.652-1.012l-.05-.033-6.394-4.263-.046-.03c-.525-.35-.987-.659-1.31-.951-.345-.311-.671-.726-.671-1.307s.326-.996.67-1.307c.324-.293.786-.6 1.311-.95l.046-.031 6.395-4.263.05-.033c.641-.428 1.193-.796 1.651-1.013Z"
        fill="currentColor"
      />
    </Icon>
  );
});
