import { Col, Row, Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CustomerDocumentsSubTab from './CustomerDocumentsSubTab';
import BookingAttachmentsSubTab from './BookingAttachmentsSubTab';
import * as H from 'history';

interface ICustomerFilesProps {
  customerUserId: string;
  itemDocuments: any;
  doFetchDocuments?: (payload) => void;
  doFetchMoreDocuments?: (payload) => void;
  setDocuments: (payload) => void;
  hasEditPermission: boolean;
  itemType: string;
  useAttachmentText?: boolean;
  companyDataLite: typeof state.companyStore.companyDataLite;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  doFetchCustomerDocuments: typeof dispatch.customersStore.doFetchCustomerDocuments;
  history: H.History;
  // The portal user here is needed to prefill the filter options for some tabs
  portalUser: typeof state.authStore.portalUser;
}

interface ICustomerFilesState {
  selectedTab: string;
}

class CustomerFiles extends Component<ICustomerFilesProps, ICustomerFilesState> {
  state: ICustomerFilesState = {
    selectedTab: 'CUSTOMER',
  };

  private _changeSelectedTab = (event) => {
    this.setState({ selectedTab: event });
  };

  render() {
    const { customerUserId, hasEditPermission, useAttachmentText, history, portalUser } = this.props;

    return (
      <>
        <Row className='mb-large' type={'flex'} justify={'space-between'} align={'middle'}>
          <Col>
            <Title level={2} className={'m-none'}>
              Files
            </Title>
            <Text type={'secondary'}>
              Documents and attachments that are related to this customer and their bookings.
            </Text>
          </Col>
        </Row>
        <Tabs className={'inner-tab mv-large'} onChange={this._changeSelectedTab}>
          <Tabs.TabPane key='CUSTOMER' tab='Customer documents' />
          <Tabs.TabPane key='BOOKING' tab='Booking attachments' />
        </Tabs>
        {this.state.selectedTab === 'CUSTOMER' && (
          <CustomerDocumentsSubTab
            customerUserId={customerUserId}
            hasEditPermission={hasEditPermission}
            useAttachmentText={useAttachmentText}
          />
        )}
        {this.state.selectedTab === 'BOOKING' && (
          <BookingAttachmentsSubTab customerUserId={customerUserId} history={history} portalUser={portalUser} />
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyDataLite: state.companyStore.companyDataLite,
  customerDocuments: state.customersStore.customerDocuments,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
  doFetchCustomerDocuments: dispatch.customersStore.doFetchCustomerDocuments,
});

export default connect(mapState, mapDispatch)(CustomerFiles);
