import { useCallback } from 'react';
import { TFunction } from 'react-i18next';

import { validatePostcode } from 'utilities/validate-postcode';
import type { CountryCode, ValidatePostcodeFunction } from './types';

export function useValidatePostcode(countryCode: CountryCode, t: TFunction): ValidatePostcodeFunction {
  return useCallback(
    (
      postcode: number | string | null | undefined,
      opts?: {
        callback?: (err?: unknown) => void;
        countryCode?: CountryCode;
        required?: boolean;
      },
    ) => {
      return validatePostcode(postcode, {
        ...opts,
        countryCode: opts?.countryCode ?? countryCode,
        requiredMessage: t('address.required.postcode'),
        invalidMessage: t('address.invalid.postcode'),
      });
    },
    [countryCode, t],
  );
}
