import React from 'react';

import { Box, Switch, useMantineColorScheme } from '@good/ui/core';
import { Sun, Moon } from '@good/icons';

export function ColorSwitch() {
  const { colorScheme, setColorScheme } = useMantineColorScheme();

  return (
    <Switch
      size='lg'
      onLabel={
        <Box fz={12}>
          <Sun />
        </Box>
      }
      offLabel={
        <Box fz={12}>
          <Moon />
        </Box>
      }
      checked={colorScheme === 'light'}
      onChange={() => setColorScheme(colorScheme === 'dark' ? 'light' : 'dark')}
    />
  );
}
