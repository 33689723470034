import React, { Dispatch, SetStateAction } from 'react';
import { TmMgmtInviteSearch } from './tm-mgmt-invites-search';
import { TmMgmtInviteTableHeader } from './team-managemen-iInvite-table/tm-mgmt-invite-table-header';
import { TmMgmtInviteTableBody } from './team-managemen-iInvite-table/tm-mgmt-invite-table-body';

type TmMgmtInvitesTabParams = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setSelectedModal: Dispatch<SetStateAction<string>>;
  setModalData: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const TmMgmtInvitesTab = ({
  isLoading,
  setIsLoading,
  setIsOpen,
  setModalData,
  setSelectedModal,
}: TmMgmtInvitesTabParams) => (
  <>
    <TmMgmtInviteSearch isLoading={isLoading} setIsLoading={setIsLoading} />
    <TmMgmtInviteTableHeader />
    <TmMgmtInviteTableBody
      isLoading={isLoading}
      setSelectedModal={setSelectedModal}
      setIsOpen={setIsOpen}
      setModalData={setModalData}
    />
  </>
);
