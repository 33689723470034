import { Avatar, Checkbox, Col, Form, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import { ErrorSVG } from 'assets/UndrawSVG';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import NumberInput from 'common-components/inputs/NumberInput';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import TimeInput from 'common-components/time-input/TimeInput';
import { TimezoneIndicator } from 'common-components/timezone';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { NoteVisibleType } from 'utilities/enum-utils';

interface IBookingCheckoutActionModelProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  doCheckoutBooking: typeof dispatch.bookingsStore.doCheckoutBooking;
  displayTimezone: timeZone;
}

class BookingCheckoutActionModel extends Component<IBookingCheckoutActionModelProps, any> {
  state = {
    step: 1,
    title: 'Finish Shift',
    checkOutTime: moment(
      moment.tz(this.props.selectedBookingItem.endDateTime, this.props.displayTimezone).format('YYYY-MM-DD HH:mm'),
    ),
    isTravelBeforeBooking: false,
    travelDistanceBeforeBooking: null,
    travelTimeBeforeBooking: null,
    additionalCostBeforeBooking: null,
    isTravelDuringBooking: false,
    travelDistanceDuringBooking: null,
    additionalCostDuringBooking: null,
    isFinishTimeError: false,
  };
  private _onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, title: 'Finish Shift' });
    onClose();
  };

  private _validateFinishTime = (value) => {
    const { selectedBookingItem } = this.props;
    return moment(value).isSameOrBefore(selectedBookingItem.workerCheckedInDateTime);
  };

  private _goToNext = () => {
    const { selectedBookingItem, displayTimezone } = this.props;
    const { form } = this.props;
    form.validateFields(async (err, value) => {
      if (!err) {
        const ndisClaimCheck =
          selectedBookingItem.serviceClaimConfig.isChargeNdisTransportDuringBooking ||
          selectedBookingItem.serviceClaimConfig.isChargeNdisTransportBeforeBooking;

        const vcpClaimCheck = selectedBookingItem.serviceClaimConfig.isChargeVcpTransportDuringBooking;

        const serviceClaimCheck = selectedBookingItem.paymentSourceType === 'VCP' ? vcpClaimCheck : ndisClaimCheck;

        if (this.state.step === 1) {
          const checkOutTime = moment.tz(moment(value.Date).format('YYYY-MM-DD HH:mm'), displayTimezone);
          serviceClaimCheck
            ? this.setState({ step: 2, title: 'Travel claims', checkOutTime })
            : this.setState({ step: 3, title: 'Case note', checkOutTime });
        } else if (this.state.step === 2) {
          this.setState({
            step: 3,
            title: 'Case note',
            travelDistanceBeforeBooking: form.getFieldValue('travelDistanceBeforeBooking'),
            travelTimeBeforeBooking: form.getFieldValue('travelTimeBeforeBooking'),
            additionalCostBeforeBooking: form.getFieldValue('additionalCostBeforeBooking'),
            travelDistanceDuringBooking: form.getFieldValue('travelDistanceDuringBooking'),
            travelTimeDuringBooking: form.getFieldValue('travelTimeDuringBooking'),
            additionalCostDuringBooking: form.getFieldValue('additionalCostDuringBooking'),
          });
        }
      }
    });
  };

  private _goToPrevious = () => {
    const { selectedBookingItem, form } = this.props;
    if (this.state.step === 3) {
      const ndisClaimCheck =
        selectedBookingItem.serviceClaimConfig.isChargeNdisTransportDuringBooking ||
        selectedBookingItem.serviceClaimConfig.isChargeNdisTransportBeforeBooking;

      const vcpClaimCheck = selectedBookingItem.serviceClaimConfig.isChargeVcpTransportDuringBooking;

      const serviceClaimCheck = selectedBookingItem.paymentSourceType === 'VCP' ? vcpClaimCheck : ndisClaimCheck;
      serviceClaimCheck
        ? this.setState({ step: 2, title: 'Travel claims' })
        : this.setState({ step: 1, title: 'Finish Shift' });
    } else if (this.state.step === 2) {
      this.setState({
        step: 1,
        title: 'Finish Shift',
        travelDistanceBeforeBooking: form.getFieldValue('travelDistanceBeforeBooking'),
        travelTimeBeforeBooking: form.getFieldValue('travelTimeBeforeBooking'),
        additionalCostBeforeBooking: form.getFieldValue('additionalCostBeforeBooking'),
        travelDistanceDuringBooking: form.getFieldValue('travelDistanceDuringBooking'),
        travelTimeDuringBooking: form.getFieldValue('travelTimeDuringBooking'),
        additionalCostDuringBooking: form.getFieldValue('additionalCostDuringBooking'),
      });
    }
  };

  private _handleTravelBeforeBooking = (event) => {
    this.setState({ isTravelBeforeBooking: event.target.checked });
  };

  private _handleTravelDuringBooking = (event) => {
    this.setState({ isTravelDuringBooking: event.target.checked });
  };

  private _onSubmitCheckin = () => {
    const { form, doCheckoutBooking } = this.props;
    form.validateFields(async (err, value) => {
      if (!err) {
        this.setState({ step: 4 });
        const noteContent = value.note;

        try {
          await doCheckoutBooking({
            checkOutTime: this.state.checkOutTime.toISOString(),
            noteContent,
            isPrivate: false,
            isIncident: false,
            visibleType: NoteVisibleType.PORTAL_AND_APP,
            isTravelBeforeBooking: this.state.isTravelBeforeBooking,
            travelDistanceBeforeBooking: this.state.travelDistanceBeforeBooking
              ? Number(this.state.travelDistanceBeforeBooking)
              : 0,
            travelTimeBeforeBooking: this.state.travelTimeBeforeBooking
              ? Number(this.state.travelTimeBeforeBooking)
              : 0,
            additionalCostBeforeBooking: this.state.additionalCostBeforeBooking
              ? Number(this.state.additionalCostBeforeBooking)
              : 0,
            isTravelDuringBooking: this.state.isTravelDuringBooking,
            travelDistanceDuringBooking: this.state.travelDistanceDuringBooking
              ? Number(this.state.travelDistanceDuringBooking)
              : 0,
            additionalCostDuringBooking: this.state.additionalCostDuringBooking
              ? Number(this.state.additionalCostDuringBooking)
              : 0,
          });
          this.setState({ step: 5, title: 'Booking finished' });
        } catch (e) {
          console.log(e);
          this.setState({ step: 6 });
        }
      }
    });
  };

  private _onChangeDate = (value) => {
    const { form } = this.props;

    this.setState({ isFinishTimeError: this._validateFinishTime(value) });
    const newTime = moment(form.getFieldValue('Time'));
    const newDate = moment(value);

    newDate.set('hour', newTime.hours());
    newDate.set('minutes', newTime.minutes());

    setTimeout(() => {
      form.setFieldsValue({
        Time: newDate.toDate(),
        Date: newDate.toDate(),
      });
    }, 0);
  };

  private _roundTime = (value) => {
    const { form } = this.props;
    this.setState({ isFinishTimeError: this._validateFinishTime(value) });
    const roundedTime = CommonUtils.formatCeilingDateTime(value);
    setTimeout(() => {
      form.setFieldsValue({
        Time: roundedTime.toDate(),
        Date: roundedTime.toDate(),
      });
    }, 0);
  };

  private _validateCaseNote = (rule, value, callback) => {
    try {
      if (_.isEmpty(_.trim(value)) || _.trim(value).length < 5) {
        throw Error('Please enter at least 5 characters');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  componentDidMount = () => {
    this.setState({ isFinishTimeError: this._validateFinishTime(this.state.checkOutTime.toDate()) });
  };

  componentDidUpdate = (prevProps) => {
    const { isOpen } = this.props;
    const { checkOutTime } = this.state;
    if (isOpen && prevProps.isOpen !== isOpen) {
      this.setState({ isFinishTimeError: this._validateFinishTime(checkOutTime.toDate()) });
    }
  };

  render() {
    const { isOpen, form, selectedBookingItem, displayTimezone } = this.props;
    const { getFieldDecorator } = form;
    const { step, isFinishTimeError } = this.state;
    const { endDateTime } = selectedBookingItem;
    const roundedDate = moment(moment.tz(endDateTime, displayTimezone).format('YYYY-MM-DD HH:mm'));

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="large"
        verticalAlignment="highest"
      >
        {step === 1 && (
          <div>
            <div className="mv-medium">
              <Paragraph>You are finishing this booking on behalf of this worker: </Paragraph>
              <div className={'flex-row align-center'}>
                <Avatar
                  size="large"
                  shape="square"
                  src={selectedBookingItem.workerAttachmentUrl}
                  className="avatar-medium mr-small"
                />
                <div className="text-weight-bold text-size-x-large">
                  {selectedBookingItem.workerFirstName + ' ' + selectedBookingItem.workerLastName}
                </div>
              </div>
            </div>

            <SubTitle>Booking finish time</SubTitle>
            <Text>This will be the official finish time for the booking.</Text>
            <Row className={'mt-medium'}>
              <Col span={8}>
                <Text color={'secondary'}>DATE</Text>
              </Col>
              <Col span={16}>
                <Text color={'secondary'}>TIME</Text>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                {getFieldDecorator('Date', {
                  initialValue: this.state.checkOutTime.toDate(),
                  valuePropName: 'selected',
                  rules: [{ required: true, message: 'Please please select date' }],
                })(
                  <DatePicker
                    className="gh-datepicker rounded"
                    showPopperArrow={false}
                    calendarClassName="gh-datepicker-calendar"
                    dateFormat="d/M/yyyy"
                    onChange={this._onChangeDate}
                  />,
                )}
              </Col>
              <Col span={16}>
                {getFieldDecorator('Time', { initialValue: this.state.checkOutTime })(
                  <TimeInput size="large" onChange={this._roundTime} />,
                )}
              </Col>
            </Row>
            <div className="mt-medium mb-large">
              <Text color="secondary">
                current timezone{' '}
                <span className="ml-small">
                  <TimezoneIndicator hasIcon={false} showTzName={false} bordered={false} timezone={displayTimezone} />
                </span>
              </Text>
            </div>

            {isFinishTimeError && <Text color="red-dark">Finish time must be later than start time.</Text>}

            <ActionModalFooter>
              <GhostButton size="large" onClick={this._onCloseModal} className="mr-medium">
                Not now
              </GhostButton>
              <PrimaryButton size="large" onClick={this._goToNext} disabled={isFinishTimeError}>
                Next
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
        {step === 2 && (
          <>
            <div className="mv-medium">
              <Paragraph>
                Please specify if there are any travel claims to be made with this booking. You can input these values
                at a later time if you do not have this information yet.
              </Paragraph>
            </div>
            {selectedBookingItem.serviceClaimConfig.isChargeNdisTransportBeforeBooking &&
              selectedBookingItem.paymentSourceType === 'NDIS' && (
                <div className="mt-large bordered border-standard-gray bg-quaternary ph-large pv-medium">
                  <Checkbox checked={this.state.isTravelBeforeBooking} onChange={this._handleTravelBeforeBooking}>
                    <Text
                      color={'secondary'}
                      weight={'bold'}
                      size="small"
                      className="text-uppercase letter-spacing-wide"
                    >
                      Claim for travel to and from the booking
                    </Text>
                  </Checkbox>
                  {this.state.isTravelBeforeBooking && (
                    <>
                      <Row className={'mt-small'}>
                        <Col span={8}>
                          <Text color={'secondary'} size={'small'}>
                            DISTANCE TRAVELLED
                          </Text>
                        </Col>
                        <Col span={8}>
                          <Text color={'secondary'} size={'small'}>
                            TRAVEL TIME
                          </Text>
                        </Col>
                        <Col span={8}>
                          <Text color={'secondary'} size={'small'}>
                            ADDITIONAL EXPENSES
                          </Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <Text color={'secondary'}>
                            <Form.Item className="width-full">
                              {getFieldDecorator('travelDistanceBeforeBooking', {
                                initialValue: this.state.travelDistanceBeforeBooking,
                                rules: [{ required: true, message: 'Please enter a distance.' }],
                              })(
                                <NumberInput
                                  size={'large'}
                                  min={0}
                                  max={10000}
                                  className={'mr-small'}
                                  addonAfter={'km'}
                                  style={{ width: '150px' }}
                                />,
                              )}
                            </Form.Item>
                          </Text>
                        </Col>
                        <Col span={8}>
                          <Text color={'secondary'}>
                            <Form.Item className="width-full">
                              {getFieldDecorator('travelTimeBeforeBooking', {
                                initialValue: this.state.travelTimeBeforeBooking,
                                rules: [{ required: true, message: 'Please enter a travel time.' }],
                              })(
                                <NumberInput
                                  size={'large'}
                                  min={0}
                                  max={10000}
                                  precision={0}
                                  className={'mr-small'}
                                  addonAfter={'minutes'}
                                  style={{ width: '150px' }}
                                />,
                              )}
                            </Form.Item>
                          </Text>
                        </Col>
                        <Col span={8}>
                          <Text color={'secondary'}>
                            <Form.Item className="width-full">
                              {getFieldDecorator('additionalCostBeforeBooking', {
                                initialValue: this.state.additionalCostBeforeBooking,
                              })(
                                <NumberInput
                                  size={'large'}
                                  min={0}
                                  max={10000}
                                  className={'mr-small'}
                                  addonBefore={'$'}
                                  style={{ width: '150px' }}
                                />,
                              )}
                            </Form.Item>
                          </Text>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              )}
            {((selectedBookingItem.serviceClaimConfig.isChargeNdisTransportDuringBooking &&
              selectedBookingItem.paymentSourceType === 'NDIS') ||
              (selectedBookingItem.serviceClaimConfig.isChargeVcpTransportDuringBooking &&
                selectedBookingItem.paymentSourceType === 'VCP')) && (
              <div className="mt-large bordered border-standard-gray bg-quaternary ph-large pv-medium">
                <Checkbox checked={this.state.isTravelDuringBooking} onChange={this._handleTravelDuringBooking}>
                  <Text color={'secondary'} weight={'bold'} size="small" className="text-uppercase letter-spacing-wide">
                    Claims for travels during the booking
                  </Text>
                </Checkbox>
                {this.state.isTravelDuringBooking && (
                  <>
                    <Row className={'mt-small'}>
                      <Col span={8}>
                        <Text color={'secondary'} size={'small'}>
                          DISTANCE TRAVELLED
                        </Text>
                      </Col>
                      <Col span={8} />
                      <Col span={8}>
                        <Text color={'secondary'} size={'small'}>
                          ADDITIONAL EXPENSES
                        </Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Text color={'secondary'}>
                          <Form.Item className="width-full">
                            {getFieldDecorator('travelDistanceDuringBooking', {
                              initialValue: this.state.travelDistanceDuringBooking,
                              rules: [{ required: true, message: 'Please enter a distance.' }],
                            })(
                              <NumberInput
                                size={'large'}
                                min={0}
                                max={10000}
                                className={'mr-small'}
                                addonAfter={'km'}
                                style={{ width: '150px' }}
                              />,
                            )}
                          </Form.Item>
                        </Text>
                      </Col>
                      <Col span={8} />
                      <Col span={8}>
                        <Text color={'secondary'}>
                          <Form.Item className="width-full">
                            {getFieldDecorator('additionalCostDuringBooking', {
                              initialValue: this.state.additionalCostDuringBooking,
                            })(
                              <NumberInput
                                size={'large'}
                                min={0}
                                max={10000}
                                className={'mr-small'}
                                addonBefore={'$'}
                                style={{ width: '150px' }}
                              />,
                            )}
                          </Form.Item>
                        </Text>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            )}
            <ActionModalFooter>
              <GhostButton size="large" onClick={this._goToPrevious} className="mr-medium">
                Back
              </GhostButton>
              <PrimaryButton size="large" onClick={this._goToNext}>
                Next
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 3 && (
          <>
            <div className="mv-medium">
              <Paragraph>Please describe the activities engaged in this booking.</Paragraph>
            </div>
            <div className="flex-row align-center justify-center mb-x-large">
              <Form.Item className="width-full">
                {getFieldDecorator('note', { rules: [{ validator: this._validateCaseNote }] })(
                  <TextArea placeholder={'Enter your case note here.'} autoSize={{ maxRows: 8, minRows: 5 }} />,
                )}
              </Form.Item>
            </div>
            <ActionModalFooter>
              <GhostButton size="large" onClick={this._goToPrevious} className="mr-medium">
                Back
              </GhostButton>
              <PrimaryButton size="large" onClick={this._onSubmitCheckin}>
                Finish
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 4 && <SpinningLoader size={100} message={'Loading'} />}
        {step === 5 && (
          <>
            <div className="text-align-left">
              <div className="mb-medium">
                <Paragraph>
                  This booking is now marked as <b>completed</b>.
                </Paragraph>
                <div className={'flex-row align-center'}>
                  <Avatar
                    size="large"
                    shape="square"
                    src={selectedBookingItem.workerAttachmentUrl}
                    className="avatar-medium mr-small"
                  />
                  <div className="text-weight-bold text-size-x-large">
                    {selectedBookingItem.workerFirstName + ' ' + selectedBookingItem.workerLastName}
                  </div>
                </div>

                <div className={'ml-x3-large mt-large'}>
                  <SubTitle>Booking finish time</SubTitle>
                  <Paragraph size={'large'} weight="bold">
                    {this.state.checkOutTime.format('h:mm A, dddd DD MMMM YYYY')}{' '}
                    <TimezoneIndicator showTzName={false} timezone={displayTimezone} />
                  </Paragraph>
                </div>
              </div>
            </div>
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Back to booking
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 6 && (
          <div>
            <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
              <div className="pv-medium flex-column justify-center">
                <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
              </div>

              <div className="mb-medium flex-column justify-center ">
                <Paragraph>Oops something has gone wrong, please try again</Paragraph>
              </div>
            </div>
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Back to booking
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedBookingItem: state.bookingsStore.selectedBookingItem });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCheckoutBooking: dispatch.bookingsStore.doCheckoutBooking,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IBookingCheckoutActionModelProps>()(BookingCheckoutActionModel));
