import { v4 as uuid } from 'uuid';
import {
  AFFECT_TO_ELEMENT_PROPERTY,
  CAPTION_TEXT_PROPERTY,
  EVENT_PROPERTY,
  FIELD_TITLE_PROPERTY,
  PLACEHOLDER_PROPERTY,
  REPEATABLE_PROPERTY,
  REQUIRED_PROPERTY,
  RULES_PROPERTY,
  TARGET_ELEMENT_PROPERTY,
  TARGET_OPTION_PROPERTY,
} from '../common-field-constants';
import { LabelMultipleElements, LabelOption, LabelShortText } from '../common-label-constants';
import { FormElementType, IntakeFormElementType, PropType, UserActionType } from '../form-enum';
import { IFormElement } from '../form-interface';
import { ndisNumberRegex } from '@constants/billing/ndis';

//#region NDIS Number
const NDIS_NUMBER: IFormElement = {
  id: uuid(),
  name: 'NDIS Number',
  type: FormElementType.SHORT_TEXT,
  label: LabelShortText,
  properties: {
    general: [
      { ...FIELD_TITLE_PROPERTY, value: 'NDIS Number', fieldType: IntakeFormElementType.FUNDING_NDIS_NUMBER },
      REQUIRED_PROPERTY,
      {
        ...RULES_PROPERTY,
        rules: [
          {
            pattern: ndisNumberRegex,
            message: 'Invalid NDIS Number',
          },
        ],
      },
    ],
    appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter NDIS Number' }, CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};
//#endregion

//#region Add Plan Manager Element
const ADD_PLAN_MANAGEMENT_ELEMENT_ID = uuid();
const ADD_PLAN_MANAGER_HEADER: IFormElement = {
  id: uuid(),
  parentId: ADD_PLAN_MANAGEMENT_ELEMENT_ID,
  name: 'Add Plan Manager',
  type: FormElementType.HEADER,
  properties: {
    general: [],
    appearance: [
      {
        key: 'headingText',
        name: 'HEADING TEXT',
        value: 'Add Plan Manager',
        rules: [
          { required: true, message: 'Please enter headline' },
          {
            max: 200,
            message: 'Max length of characters is 200',
          },
        ],
        placeholder: 'Heading Text',
        caption: 'Enter heading text for this field.',
      },
      {
        key: 'subHeadingText',
        name: 'SUBHEADING TEXT (OPTIONAL)',
        value: '',
        rules: [
          {
            max: 1000,
            message: 'Max length of characters is 1000',
          },
        ],
        placeholder: 'Subheading Text',
        caption: 'Accompanying subheading text for this header.',
      },
    ],
    configuration: [],
  },
};

const PLAN_MANAGEMENT_TYPE_ELEMENT_ID = uuid();
const PLAN_MANAGEMENT_TYPE: IFormElement = {
  id: PLAN_MANAGEMENT_TYPE_ELEMENT_ID,
  parentId: ADD_PLAN_MANAGEMENT_ELEMENT_ID,
  name: 'Plan Manager Type',
  type: FormElementType.SINGLE_CHOICE,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Is the plan manager external (not part of our organisation) or internal (part of our organisation)?',
        fieldType: IntakeFormElementType.FUNDING_PLAN_MANAGER_TYPE,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: ['External plan manager', 'Internal plan manager'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const SELECT_EXISTING_OR_ADD_NEW_PLAN_MANAGER_ELEMENT_ID = uuid();
const SELECT_EXISTING_OR_ADD_NEW_PLAN_MANAGER: IFormElement = {
  id: SELECT_EXISTING_OR_ADD_NEW_PLAN_MANAGER_ELEMENT_ID,
  parentId: ADD_PLAN_MANAGEMENT_ELEMENT_ID,
  name: 'Select Plan Manager',
  type: FormElementType.DROPDOWN,
  isHideConfig: true,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Choose from existing plan managers or add plan manager details',
        fieldType: IntakeFormElementType.SELECT_EXIST_OR_ADD_PLAN_MANAGER,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Select plan manager' }],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: [
          {
            value: 'ADD_PLAN_MANAGER',
            name: 'Add plan manager details',
          },
        ],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const SELECT_PLAN_MANAGER_CONDITIONAL_ID = uuid();
const SELECT_PLAN_MANAGER_CONDITIONAL: IFormElement = {
  id: uuid(),
  parentId: ADD_PLAN_MANAGEMENT_ELEMENT_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: PLAN_MANAGEMENT_TYPE_ELEMENT_ID,
        options: [PLAN_MANAGEMENT_TYPE],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'External plan manager',
        options: ['External plan manager', 'Internal plan manager'],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [SELECT_EXISTING_OR_ADD_NEW_PLAN_MANAGER_ELEMENT_ID],
        options: [SELECT_EXISTING_OR_ADD_NEW_PLAN_MANAGER],
      },
      {
        ...REPEATABLE_PROPERTY,
      },
    ],
  },
};

const ADD_NEW_PLAN_ELEMENT_ID = uuid();
const ADD_NEW_PLAN_MANAGER: IFormElement = {
  id: ADD_NEW_PLAN_ELEMENT_ID,
  parentId: ADD_PLAN_MANAGEMENT_ELEMENT_ID,
  name: 'Add New Plan Manager Detail',
  type: FormElementType.MULTIPLE_ELEMENT,
  isHideConfig: true,
  properties: {
    general: [REQUIRED_PROPERTY],
    appearance: [],
    configuration: [],
  },
  children: [
    {
      id: uuid(),
      parentId: ADD_NEW_PLAN_ELEMENT_ID,
      name: 'Plan Manager Name',
      type: FormElementType.SHORT_TEXT,
      properties: {
        general: [
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'Plan Manager Name',
            fieldType: IntakeFormElementType.FUNDING_PLAN_MANAGER_NAME,
          },
          REQUIRED_PROPERTY,
          {
            ...RULES_PROPERTY,
            rules: [
              {
                max: 50,
                message: 'Value should be less than 50 character',
              },
            ],
          },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter full name here' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
    {
      id: uuid(),
      parentId: ADD_NEW_PLAN_ELEMENT_ID,
      name: 'Organisation Name',
      type: FormElementType.SHORT_TEXT,
      properties: {
        general: [
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'Organisation Name',
            fieldType: IntakeFormElementType.FUNDING_PLAN_MANAGER_ORGANISATION_NAME,
          },
          { ...REQUIRED_PROPERTY, value: false },
          {
            ...RULES_PROPERTY,
            rules: [
              {
                max: 255,
                message: 'Value should be less than 255 character',
              },
            ],
          },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter organisation name' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
    {
      id: uuid(),
      parentId: ADD_NEW_PLAN_ELEMENT_ID,
      name: 'Plan Manager ID',
      type: FormElementType.SHORT_TEXT,
      properties: {
        general: [
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'Plan Manager ID',
            fieldType: IntakeFormElementType.FUNDING_PLAN_MANAGER_ID,
          },
          { ...REQUIRED_PROPERTY, value: false },
          {
            ...RULES_PROPERTY,
            rules: [
              {
                max: 50,
                message: 'Value should be less than 50 character',
              },
            ],
          },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: '0' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
    {
      id: uuid(),
      name: 'Email',
      parentId: ADD_NEW_PLAN_ELEMENT_ID,
      type: FormElementType.SHORT_TEXT,
      properties: {
        general: [
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'Email',
            fieldType: IntakeFormElementType.FUNDING_PLAN_MANAGER_EMAIL,
          },
          REQUIRED_PROPERTY,
          { ...RULES_PROPERTY, rules: [{ type: 'email', message: 'Please enter a valid email' }] },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter email here' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
    {
      id: uuid(),
      name: 'Contact Number',
      parentId: ADD_NEW_PLAN_ELEMENT_ID,
      type: FormElementType.PHONE_NUMBER,
      properties: {
        general: [
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'Contact Number',
            fieldType: IntakeFormElementType.FUNDING_PLAN_MANAGER_CONTACT_NUMBER,
          },
          { ...REQUIRED_PROPERTY, value: false },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: '+61 000 000 000' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
    {
      id: uuid(),
      parentId: ADD_NEW_PLAN_ELEMENT_ID,
      name: 'Address',
      type: FormElementType.ADDRESS_LOOKUP,
      properties: {
        general: [
          { ...FIELD_TITLE_PROPERTY, value: 'Address', fieldType: IntakeFormElementType.FUNDING_PLAN_MANAGER_ADDRESS },
          { ...REQUIRED_PROPERTY, value: false },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Search address here' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
  ],
};

const ADD_NEW_PLAN_MANAGER_CONDITIONAL_ID = uuid();
const ADD_NEW_PLAN_MANAGER_CONDITIONAL: IFormElement = {
  id: uuid(),
  parentId: ADD_PLAN_MANAGEMENT_ELEMENT_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: SELECT_EXISTING_OR_ADD_NEW_PLAN_MANAGER_ELEMENT_ID,
        options: [SELECT_EXISTING_OR_ADD_NEW_PLAN_MANAGER],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'ADD_PLAN_MANAGER',
        options: [
          {
            value: 'ADD_PLAN_MANAGER',
            name: 'Add plan manager details',
          },
        ],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [ADD_NEW_PLAN_ELEMENT_ID],
        options: [ADD_NEW_PLAN_MANAGER],
      },
      {
        ...REPEATABLE_PROPERTY,
      },
    ],
  },
};

const PLAN_MANAGER_BUNDLE: IFormElement = {
  id: ADD_PLAN_MANAGEMENT_ELEMENT_ID,
  name: 'Add Plan Manager',
  type: FormElementType.MULTIPLE_ELEMENT,
  isHideConfig: true,
  properties: {
    general: [{ ...REQUIRED_PROPERTY, value: false }],
    appearance: [],
    configuration: [],
  },
  children: [
    ADD_PLAN_MANAGER_HEADER,
    PLAN_MANAGEMENT_TYPE,
    { ...SELECT_PLAN_MANAGER_CONDITIONAL, conditionId: SELECT_PLAN_MANAGER_CONDITIONAL_ID },
    { ...SELECT_EXISTING_OR_ADD_NEW_PLAN_MANAGER, conditionId: SELECT_PLAN_MANAGER_CONDITIONAL_ID },
    { ...ADD_NEW_PLAN_MANAGER_CONDITIONAL, conditionId: ADD_NEW_PLAN_MANAGER_CONDITIONAL_ID },
    { ...ADD_NEW_PLAN_MANAGER, conditionId: ADD_NEW_PLAN_MANAGER_CONDITIONAL_ID },
  ],
};
//#endregion

//#region Add Self Manager Element
const ADD_SELF_MANAGEMENT_ELEMENT_ID = uuid();

const ADD_SELF_MANAGER_HEADER: IFormElement = {
  id: uuid(),
  parentId: ADD_SELF_MANAGEMENT_ELEMENT_ID,
  name: 'Add Self Manager',
  type: FormElementType.HEADER,
  properties: {
    general: [],
    appearance: [
      {
        key: 'headingText',
        name: 'HEADING TEXT',
        value: 'Add Self Manager',
        rules: [
          { required: true, message: 'Please enter headline' },
          {
            max: 200,
            message: 'Max length of characters is 200',
          },
        ],
        placeholder: 'Heading Text',
        caption: 'Enter heading text for this field.',
      },
      {
        key: 'subHeadingText',
        name: 'SUBHEADING TEXT (OPTIONAL)',
        value: '',
        rules: [
          {
            max: 1000,
            message: 'Max length of characters is 1000',
          },
        ],
        placeholder: 'Subheading Text',
        caption: 'Accompanying subheading text for this header.',
      },
    ],
    configuration: [],
  },
};

const SELECT_EXISTING_OR_ADD_NEW_SELF_MANAGER_ELEMENT_ID = uuid();
const SELECT_EXISTING_OR_ADD_NEW_SELF_MANAGER: IFormElement = {
  id: SELECT_EXISTING_OR_ADD_NEW_SELF_MANAGER_ELEMENT_ID,
  parentId: ADD_SELF_MANAGEMENT_ELEMENT_ID,
  name: 'Select Self Manager',
  type: FormElementType.DROPDOWN,
  isHideConfig: true,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Choose from existing self managers or add self manager details',
        fieldType: IntakeFormElementType.SELECT_EXIST_OR_ADD_SELF_MANAGER,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Select self manager' }],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: [
          {
            value: 'ADD_SELF_MANAGER',
            name: 'Add self manager details',
          },
        ],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const ADD_NEW_SELF_ELEMENT_ID = uuid();
const ADD_NEW_SELF_MANAGER: IFormElement = {
  id: ADD_NEW_SELF_ELEMENT_ID,
  parentId: ADD_SELF_MANAGEMENT_ELEMENT_ID,
  name: 'Add New Self Manager Detail',
  type: FormElementType.MULTIPLE_ELEMENT,
  isHideConfig: true,
  properties: {
    general: [REQUIRED_PROPERTY],
    appearance: [],
    configuration: [],
  },
  children: [
    {
      id: uuid(),
      parentId: ADD_NEW_SELF_ELEMENT_ID,
      name: 'Self Manager Name',
      type: FormElementType.SHORT_TEXT,
      properties: {
        general: [
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'Self Manager Name',
            fieldType: IntakeFormElementType.FUNDING_SELF_MANAGER_NAME,
          },
          REQUIRED_PROPERTY,
          {
            ...RULES_PROPERTY,
            rules: [
              {
                max: 50,
                message: 'Value should be less than 50 character',
              },
            ],
          },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter full name here' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
    {
      id: uuid(),
      parentId: ADD_NEW_SELF_ELEMENT_ID,
      name: 'Organisation Name',
      type: FormElementType.SHORT_TEXT,
      properties: {
        general: [
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'Organisation Name',
            fieldType: IntakeFormElementType.FUNDING_SELF_MANAGER_ORGANISATION_NAME,
          },
          { ...REQUIRED_PROPERTY, value: false },
          {
            ...RULES_PROPERTY,
            rules: [
              {
                max: 255,
                message: 'Value should be less than 255 character',
              },
            ],
          },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter organisation name' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
    {
      id: uuid(),
      parentId: ADD_NEW_SELF_ELEMENT_ID,
      name: 'Self Manager ID',
      type: FormElementType.SHORT_TEXT,
      properties: {
        general: [
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'Self Manager ID',
            fieldType: IntakeFormElementType.FUNDING_SELF_MANAGER_ID,
          },
          { ...REQUIRED_PROPERTY, value: false },
          {
            ...RULES_PROPERTY,
            rules: [
              {
                max: 50,
                message: 'Value should be less than 50 character',
              },
            ],
          },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: '0' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
    {
      id: uuid(),
      parentId: ADD_NEW_SELF_ELEMENT_ID,
      name: 'Email',
      type: FormElementType.SHORT_TEXT,
      properties: {
        general: [
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'Email',
            fieldType: IntakeFormElementType.FUNDING_SELF_MANAGER_EMAIL,
          },
          REQUIRED_PROPERTY,
          { ...RULES_PROPERTY, rules: [{ type: 'email', message: 'Please enter a valid email' }] },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter email here' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
    {
      id: uuid(),
      parentId: ADD_NEW_SELF_ELEMENT_ID,
      name: 'Contact Number',
      type: FormElementType.PHONE_NUMBER,
      properties: {
        general: [
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'Contact Number',
            fieldType: IntakeFormElementType.FUNDING_SELF_MANAGER_CONTACT_NUMBER,
          },
          { ...REQUIRED_PROPERTY, value: false },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: '+61 000 000 000' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
    {
      id: uuid(),
      parentId: ADD_NEW_SELF_ELEMENT_ID,
      name: 'Address',
      type: FormElementType.ADDRESS_LOOKUP,
      properties: {
        general: [
          { ...FIELD_TITLE_PROPERTY, value: 'Address', fieldType: IntakeFormElementType.FUNDING_SELF_MANAGER_ADDRESS },
          { ...REQUIRED_PROPERTY, value: false },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Search address here' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
  ],
};

const ADD_NEW_SELF_MANAGER_CONDITIONAL_ID = uuid();
const ADD_NEW_SELF_MANAGER_CONDITIONAL: IFormElement = {
  id: uuid(),
  parentId: ADD_SELF_MANAGEMENT_ELEMENT_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: SELECT_EXISTING_OR_ADD_NEW_SELF_MANAGER_ELEMENT_ID,
        options: [SELECT_EXISTING_OR_ADD_NEW_SELF_MANAGER],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'ADD_SELF_MANAGER',
        options: [
          {
            value: 'ADD_SELF_MANAGER',
            name: 'Add self manager details',
          },
        ],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [ADD_NEW_SELF_ELEMENT_ID],
        options: [ADD_NEW_SELF_MANAGER],
      },
      {
        ...REPEATABLE_PROPERTY,
      },
    ],
  },
};

const SELF_MANAGER_BUNDLE: IFormElement = {
  id: ADD_SELF_MANAGEMENT_ELEMENT_ID,
  name: 'Add Self Manager',
  type: FormElementType.MULTIPLE_ELEMENT,
  isHideConfig: true,
  properties: {
    general: [{ ...REQUIRED_PROPERTY, value: false }],
    appearance: [],
    configuration: [],
  },
  children: [
    ADD_SELF_MANAGER_HEADER,
    SELECT_EXISTING_OR_ADD_NEW_SELF_MANAGER,
    { ...ADD_NEW_SELF_MANAGER_CONDITIONAL, conditionId: ADD_NEW_SELF_MANAGER_CONDITIONAL_ID },
    { ...ADD_NEW_SELF_MANAGER, conditionId: ADD_NEW_SELF_MANAGER_CONDITIONAL_ID },
  ],
};
//#endregion

//#region Select Management Method
const MANAGEMENT_METHOD_BUNDLE_ID = uuid();
const SELECT_MANAGEMENT_ELEMENT_ID = uuid();
const SELECT_MANAGEMENT_METHOD: IFormElement = {
  id: SELECT_MANAGEMENT_ELEMENT_ID,
  parentId: MANAGEMENT_METHOD_BUNDLE_ID,
  name: 'Select Management Method',
  type: FormElementType.SINGLE_CHOICE,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'How is the NDIS plan managed?',
        fieldType: IntakeFormElementType.FUNDING_MANAGEMENT_METHOD,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: ['NDIA Managed', 'Self Managed', 'Plan Managed'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const PLAN_MANAGED_CONDITIONAL_ID = uuid();
const PLAN_MANAGED_CONDITIONAL: IFormElement = {
  id: uuid(),
  parentId: MANAGEMENT_METHOD_BUNDLE_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: SELECT_MANAGEMENT_ELEMENT_ID,
        options: [SELECT_MANAGEMENT_METHOD],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'Plan Managed',
        options: ['NDIA Managed', 'Self Managed', 'Plan Managed'],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [ADD_PLAN_MANAGEMENT_ELEMENT_ID],
        options: [PLAN_MANAGER_BUNDLE],
      },
      {
        ...REPEATABLE_PROPERTY,
      },
    ],
  },
};

const SELF_MANAGED_CONDITIONAL_ID = uuid();
const SELF_MANAGED_CONDITIONAL: IFormElement = {
  id: uuid(),
  parentId: MANAGEMENT_METHOD_BUNDLE_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: SELECT_MANAGEMENT_ELEMENT_ID,
        options: [SELECT_MANAGEMENT_METHOD],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'Self Managed',
        options: ['NDIA Managed', 'Self Managed', 'Plan Managed'],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [ADD_SELF_MANAGEMENT_ELEMENT_ID],
        options: [SELF_MANAGER_BUNDLE],
      },
      {
        ...REPEATABLE_PROPERTY,
      },
    ],
  },
};

const MANAGEMENT_METHOD_BUNDLE: IFormElement = {
  id: MANAGEMENT_METHOD_BUNDLE_ID,
  name: 'Management Method',
  type: FormElementType.MULTIPLE_ELEMENT,
  label: LabelMultipleElements,
  isHideConfig: true,
  properties: {
    general: [REQUIRED_PROPERTY],
    appearance: [],
    configuration: [],
  },
  children: [
    SELECT_MANAGEMENT_METHOD,
    { ...SELF_MANAGED_CONDITIONAL, conditionId: SELF_MANAGED_CONDITIONAL_ID },
    { ...SELF_MANAGER_BUNDLE, parentId: MANAGEMENT_METHOD_BUNDLE_ID, conditionId: SELF_MANAGED_CONDITIONAL_ID },
    { ...PLAN_MANAGED_CONDITIONAL, conditionId: PLAN_MANAGED_CONDITIONAL_ID },
    { ...PLAN_MANAGER_BUNDLE, parentId: MANAGEMENT_METHOD_BUNDLE_ID, conditionId: PLAN_MANAGED_CONDITIONAL_ID },
  ],
};

//#endregion

//#region Select Support Coordinator
const SELECT_SUPPORT_COORDINATOR: IFormElement = {
  id: uuid(),
  name: 'Select support coordinators',
  type: FormElementType.DROPDOWN_MULTI_SELECT,
  label: LabelOption,
  isHideConfig: true,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Choose from team members',
        fieldType: IntakeFormElementType.FUNDING_SUPPORT_COORDINATOR,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [
      { ...CAPTION_TEXT_PROPERTY, value: 'A maximum of 5 coordinators can be added for a customer' },
      { ...PLACEHOLDER_PROPERTY, value: 'Search and select team members' },
    ],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: [],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};
//#endregion

//#region Add Goal From NDIS Plan
const ADD_GOAL_ELEMENT_ID = uuid();
const ADD_NEW_GOAL: IFormElement[] = [
  {
    id: uuid(),
    parentId: ADD_GOAL_ELEMENT_ID,
    name: 'Please add your goals from your NDIS plan',
    type: FormElementType.HEADER,
    properties: {
      general: [],
      appearance: [
        {
          key: 'headingText',
          name: 'HEADING TEXT',
          value: 'Please add your goals from your NDIS plan',
          rules: [
            { required: true, message: 'Please enter headline' },
            {
              max: 200,
              message: 'Max length of characters is 200',
            },
          ],
          placeholder: 'Heading Text',
          caption: 'Enter heading text for this field.',
        },
        {
          key: 'subHeadingText',
          name: 'SUBHEADING TEXT (OPTIONAL)',
          value: '',
          rules: [
            {
              max: 1000,
              message: 'Max length of characters is 1000',
            },
          ],
          placeholder: 'Subheading Text',
          caption: 'Accompanying subheading text for this header.',
        },
      ],
      configuration: [],
    },
  },
  {
    id: uuid(),
    parentId: ADD_GOAL_ELEMENT_ID,
    name: 'Goal Type',
    type: FormElementType.DROPDOWN,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Goal Type',
          fieldType: IntakeFormElementType.FUNDING_GOAL_TYPE,
        },
        REQUIRED_PROPERTY,
      ],
      appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Select goal type' }],
      configuration: [
        {
          key: 'options',
          name: 'OPTIONS',
          value: ['Short Term', 'Medium/Long Term'],
          caption: 'Option that will be shown for this input field.',
          type: PropType.options,
        },
      ],
    },
  },
  {
    id: uuid(),
    name: 'Goal Title',
    parentId: ADD_GOAL_ELEMENT_ID,
    type: FormElementType.SHORT_TEXT,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Goal Title',
          fieldType: IntakeFormElementType.FUNDING_GOAL_TITLE,
        },
        REQUIRED_PROPERTY,
      ],
      appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter goal title' }, CAPTION_TEXT_PROPERTY],
      configuration: [],
    },
  },
  {
    id: uuid(),
    name: 'Goal Description',
    parentId: ADD_GOAL_ELEMENT_ID,
    type: FormElementType.LONG_TEXT,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Goal Description',
          fieldType: IntakeFormElementType.FUNDING_GOAL_DESCRIPTION,
        },
        { ...REQUIRED_PROPERTY, value: false },
      ],
      appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter goal description' }, CAPTION_TEXT_PROPERTY],
      configuration: [],
    },
  },
];

const ADD_ANOTHER_GOAL_ELEMENT_ID = uuid();
const ADD_ANOTHER_GOAL: IFormElement = {
  id: ADD_ANOTHER_GOAL_ELEMENT_ID,
  parentId: ADD_GOAL_ELEMENT_ID,
  name: 'Another Goal',
  type: FormElementType.SINGLE_CHOICE,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Would you like to add another goal?',
        fieldType: IntakeFormElementType.FUNDING_ANOTHER_GOAL,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: ['Yes', 'No'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const ADD_GOAL_BUNDLE: IFormElement = {
  id: ADD_GOAL_ELEMENT_ID,
  name: 'Add your goals from your NDIS plan',
  type: FormElementType.MULTIPLE_ELEMENT,
  label: LabelMultipleElements,
  isHideConfig: true,
  properties: {
    general: [REQUIRED_PROPERTY],
    appearance: [],
    configuration: [],
  },
  children: [...ADD_NEW_GOAL, ADD_ANOTHER_GOAL],
};

const ADD_ANOTHER_GOAL_CONDITIONAL: IFormElement = {
  id: uuid(),
  parentId: ADD_GOAL_ELEMENT_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: ADD_ANOTHER_GOAL_ELEMENT_ID,
        options: [ADD_ANOTHER_GOAL],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'Yes',
        options: ['Yes', 'No'],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [ADD_GOAL_ELEMENT_ID],
        options: [{ ...ADD_GOAL_BUNDLE, children: [] }],
      },
      {
        ...REPEATABLE_PROPERTY,
        value: true,
      },
    ],
  },
};
ADD_GOAL_BUNDLE.children.push(ADD_ANOTHER_GOAL_CONDITIONAL);
//#endregion

export const FUNDING_TAB_ELEMENTS = [
  NDIS_NUMBER,
  MANAGEMENT_METHOD_BUNDLE,
  SELECT_SUPPORT_COORDINATOR,
  // ADD_GOAL_BUNDLE,
];
