import apiClient from 'utilities/api-client';
import { ExtendGroupSessionScheduleRequest } from '../../hooks/query-hooks/use-mutation-extend-group-session-schedule';

export async function fetchCustomerBookings(request) {
  return await apiClient.post(
    `api/portal/group-services/${request.serviceId}/sessions/${request.serviceDateTimeId}/bookings/list`,
    request,
  );
}

export async function saveShiftCustomerAssignments(request) {
  return await apiClient.put(
    `api/portal/group-services/shift/${request.supportWorkerAttendanceId}/assign-attendances`,
    request,
  );
}

export async function fetchShiftSlots(request) {
  return await apiClient.post(
    `api/portal/group-services/${request.serviceId}/sessions/${request.serviceDateTimeId}/shift-slots/list`,
    request,
  );
}

export async function saveCustomerShiftAssignments(request) {
  return await apiClient.put(`api/portal/group-services/shift/${request.attendanceId}/assign-shift-slots`, request);
}

export async function fetchTeamMemberSchedule(request) {
  return await apiClient.post(`api/portal/group-services/team-members/${request.supportWorkerId}/sessions`, request);
}

export async function extendGroupSessionSchedule(request: ExtendGroupSessionScheduleRequest) {
  return await apiClient.post(
    `api/portal/group-services/${request.serviceId}/schedules/${request.serviceScheduleId}/extend`,
    {
      ...request,
      customCopyDate: request.customCopyDate.toISOString(),
      extendStartDateTime: request.extendStartDateTime.toISOString(),
      extendEndDateTime: request.extendEndDateTime.toISOString(),
    },
  );
}
