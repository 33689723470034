import { forwardRef } from 'react';
import {
  Tabs as _Tabs,
  TabList as _TabList,
  Tab as _Tab,
  TabPanel as _TabPanel,
  TabProps,
  TabListProps,
  TabPanelProps,
  TabsProps,
} from 'react-aria-components';
import * as styles from './tabs.css';
import { AriaTabProps } from 'react-aria';

export const Tab = forwardRef<HTMLDivElement, TabProps>((props, ref) => {
  return (
    <_Tab className={styles.tab} {...props} ref={ref}>
      {props.children}
    </_Tab>
  );
});

export const TabList = forwardRef<HTMLDivElement, TabListProps<AriaTabProps>>((props, ref) => {
  return (
    <_TabList className={styles.tabList} {...props} ref={ref}>
      {props.children}
    </_TabList>
  );
});

export const TabPanel = forwardRef<HTMLDivElement, TabPanelProps>((props, ref) => {
  return (
    <_TabPanel className={styles.tabPanel} {...props} ref={ref}>
      {props.children}
    </_TabPanel>
  );
});
export const Tabs = forwardRef<HTMLDivElement, TabsProps>((props, ref) => {
  return (
    <_Tabs className={styles.tabs} {...props} ref={ref}>
      {props.children}
    </_Tabs>
  );
});

TabList.displayName = 'TabList';
Tab.displayName = 'Tab';
TabPanel.displayName = 'TabPanel';
Tabs.displayName = 'Tabs';
