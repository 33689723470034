import React, { forwardRef, type HTMLProps } from 'react';
import { ChevronDown, ChevronUp } from '@good/icons';
import {
  Button,
  Group,
  Input,
  NumberField as _NumberField,
  Text,
  type NumberFieldProps as _NumberFieldProps,
} from 'react-aria-components';

import * as styles from './number-field.css';
import { twMerge } from '../utils';
import { StatusMessage } from '../status-message';
import { FieldLabel } from '../field-label';
import type { InputProps, LabelProps } from '../types';

export type NumberFieldProps = _NumberFieldProps & LabelProps & InputProps & HTMLProps<HTMLDivElement>;

export const NumberField = forwardRef<HTMLInputElement, NumberFieldProps>((props, ref) => {
  const {
    className,
    description,
    errorMessage,
    isRequired,
    label,
    requirementIndicator,
    validationState,
    ...otherProps
  } = props;

  const isDescription = Boolean(description);
  const isErrorMessage = Boolean(errorMessage);
  const isInvalid = validationState === 'invalid';
  const showErrorMessage = isInvalid && isErrorMessage;
  const showDescription = isDescription && !showErrorMessage;
  const { container, input, inputContainer, stepButton, stepContainer } = styles.numberField({ validationState });

  return (
    <_NumberField
      {...otherProps}
      className={twMerge(container(), className)}
      validationState={validationState}
      ref={ref}
    >
      <FieldLabel requirementIndicator={requirementIndicator} isRequired={isRequired}>
        {label}
      </FieldLabel>

      <Group className={inputContainer()}>
        <Input className={input()} />
        <div className={stepContainer()}>
          <Button slot="increment" data-disabled={props.isDisabled} className={stepButton()}>
            <ChevronUp />
          </Button>
          <Button slot="decrement" data-disabled={props.isDisabled} className={stepButton()}>
            <ChevronDown />
          </Button>
        </div>
      </Group>

      {showDescription && (
        <Text slot="description">
          <StatusMessage tone="neutral">{description}</StatusMessage>
        </Text>
      )}

      {showErrorMessage && (
        <Text slot="errorMessage">
          <StatusMessage tone="critical">{errorMessage}</StatusMessage>
        </Text>
      )}
    </_NumberField>
  );
});

NumberField.displayName = 'NumberField';
