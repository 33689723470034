import React, { useEffect, useState } from 'react';
import { SubTitle, Text, Title } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { useDispatch, useSelector } from 'react-redux';
import * as H from 'history';
import { notification } from 'antd';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { TimezoneIndicator } from 'common-components/timezone';
import AssignNewPrimaryOwner from 'views/account-management/components/CompanyDetailModals/AssignNewPrimaryOwnerModal';
import EditDisplayNameModal from 'views/account-management/components/CompanyDetailModals/EditDisplayNameModal';
import EditCompanyAddressModal from 'views/account-management/components/CompanyDetailModals/EditCompanyAddressModal';
import EditTimezoneModal from 'views/account-management/components/CompanyDetailModals/EditTimezoneModal';
import EditBusinessDetailsModal from 'views/account-management/components/CompanyDetailModals/EditBusinessDetailsModal';
import RegistrationDetailView from 'views/account-management/components/RegistrationDetailView';
import EditReplyToEmailModal from '../components/CompanyDetailModals/EditReplyToEmailModal';
import { CompanyDetailsWidget } from '../components/CompanyDetailsComponents/CompanyDetailsWidget';
import { EditCompanyEmailModal } from '../components/CompanyDetailModals/EditCompanyEmailModal';
import { CompanyDetails } from '../components/CompanyDetailsComponents/CompanyDetails';
import { CompanyDetailsCompanyOwner } from '../components/CompanyDetailsComponents/CompanyDetailsPrimaryOwner';

type ICompanyDetailsViewProps2 = {
  history: H.History;
};

const COMPANY_DETAILS_MODALS = {
  AssignPrimaryOwner: 'AssignPrimaryOwner',
  EditDisplayName: 'EditDisplayName',
  EditCompanyAddress: 'EditCompanyAddress',
  EditTimezone: 'EditTimezone',
  EditBusinessDetails: 'EditBusinessDetails',
  EditReplyToEmail: 'EditReplyToEmail',
  EditCompanyEmail: 'EditCompanyEmail',
};

export const CompanyDetailsView = ({ history }: ICompanyDetailsViewProps2) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const [isLoadingRegistrationDetails, setIsLoadingRegistrationDetails] = useState(false);
  const [errorWithRegistrationDetails, setErrorWithRegistrationDetails] = useState(false);

  const { companyStore } = useDispatch<IRootDispatch>();
  const { doFetchCompany, getCompanyRegistrationDetails } = companyStore;

  const companyData: typeof state.companyStore.companyData = useSelector<IRootState>(
    (state) => state.companyStore.companyData,
  );

  const companyRegistrationDetails: typeof state.companyStore.companyRegistrationDetails = useSelector<IRootState>(
    (state) => state.companyStore.companyRegistrationDetails,
  );

  const initialiseCompanyData = async () => {
    try {
      setIsLoading(true);
      await doFetchCompany({});
    } catch (e) {
      notification.error({ message: 'Error while fetching company data' });
    } finally {
      setIsLoading(false);
    }
  };

  const initialiseCompanyRegistrationDetails = async () => {
    try {
      setIsLoadingRegistrationDetails(true);
      await getCompanyRegistrationDetails({
        businessRegistrationNumber: companyData.businessRegistrationNumber,
        businessRegistrationNumberType: companyData.businessRegistrationNumber.length === 9 ? 'ACR' : 'ABN',
        isLookup: false,
      });
    } catch (e) {
      setIsLoadingRegistrationDetails(false);
      setErrorWithRegistrationDetails(e.meta.message !== 'Business registration number does not exist');
    } finally {
      setIsLoadingRegistrationDetails(false);
    }
  };

  useEffect(() => {
    initialiseCompanyData();
  }, []);

  useEffect(() => {
    if (companyData?.businessRegistrationNumber) {
      initialiseCompanyRegistrationDetails();
    }
  }, [companyData?.businessRegistrationNumber]);

  const goToLandingPage = () => {
    history.push('/account/landing');
  };
  const onCloseModal = () => {
    setOpenModal(null);
  };

  const onOpenModal = (modalName: string) => {
    setOpenModal(modalName);
  };

  if (isLoading || !companyData) {
    return <SpinningLoader size={100} message='Fetching company details' />;
  }

  return (
    <>
      <AssignNewPrimaryOwner
        isOpen={openModal === COMPANY_DETAILS_MODALS.AssignPrimaryOwner}
        onClose={onCloseModal}
        goToLandingPage={goToLandingPage}
        currentPrimaryOwner={
          companyData && {
            firstName: companyData.firstName,
            lastName: companyData.lastName,
            attachmentUrl: companyData.attachmentUrl,
          }
        }
      />
      <EditDisplayNameModal
        isOpen={openModal === COMPANY_DETAILS_MODALS.EditDisplayName}
        onClose={onCloseModal}
        currentDisplayName={companyData && companyData.displayName}
      />
      <EditCompanyAddressModal
        isOpen={openModal === COMPANY_DETAILS_MODALS.EditCompanyAddress}
        onClose={onCloseModal}
        address={companyData && companyData.address ? companyData.address : ''}
      />
      <EditTimezoneModal
        isOpen={openModal === COMPANY_DETAILS_MODALS.EditTimezone}
        onClose={onCloseModal}
        currentTimezone={companyData && companyData.timezone}
      />
      <EditBusinessDetailsModal
        isOpen={openModal === COMPANY_DETAILS_MODALS.EditBusinessDetails}
        onClose={onCloseModal}
      />
      <EditReplyToEmailModal isOpen={openModal === COMPANY_DETAILS_MODALS.EditReplyToEmail} onClose={onCloseModal} />
      <EditCompanyEmailModal
        isOpen={openModal === COMPANY_DETAILS_MODALS.EditCompanyEmail}
        onClose={onCloseModal}
        currentEmail={companyData?.email ?? 'Not set.'}
      />
      <div className='pb-x4-large'>
        <CompanyDetails goToLandingPage={goToLandingPage} />
        <CompanyDetailsCompanyOwner
          onOpenModal={onOpenModal}
          companyData={companyData}
          assignPrimaryOwner={COMPANY_DETAILS_MODALS.AssignPrimaryOwner}
        />
        <div className='mt-x-large'>
          <Title level={4}>Company</Title>
          <CompanyDetailsWidget
            title='display name'
            subTitle='Public name of your business. Shown to customers and used in the workspace.'
            content={<Text size='x-large'>{companyData.serviceProviderName}</Text>}
            hyperlinkCaption='Change display name'
            onHyperlinkClick={() => onOpenModal(COMPANY_DETAILS_MODALS.EditDisplayName)}
            panelClassName='inline-block'
            testId='display-name'
          />
          <CompanyDetailsWidget
            title='company email'
            subTitle="The company's email will appear on the invoices sent to customers."
            panelClassName='inline-block'
            onHyperlinkClick={() => onOpenModal(COMPANY_DETAILS_MODALS.EditCompanyEmail)}
            hyperlinkCaption='Change company email'
            content={<Text style={{ whiteSpace: 'pre-line' }}>{companyData?.email ?? 'Not set.'}</Text>}
            testId='company-email'
          />
          <CompanyDetailsWidget
            title='Business address'
            subTitle='Primary location of you business. Used in invoices.'
            onHyperlinkClick={() => onOpenModal(COMPANY_DETAILS_MODALS.EditCompanyAddress)}
            panelClassName='flex-row justify-between'
            content={
              <div>
                <Text style={{ whiteSpace: 'pre-line' }}>
                  {companyData.address && companyData.address.fullAddress !== ''
                    ? companyData.address.fullAddress
                    : 'Not set.'}
                </Text>
              </div>
            }
            testId='business-address'
          />
          <CompanyDetailsWidget
            title='Registered business details'
            content={
              <>
                <div className='mb-small flex-row justify-between'>
                  <div>
                    <SubTitle>Country</SubTitle>
                    <Text>Australia</Text>
                  </div>
                  <HyperlinkButton
                    data-testId='registered-business-details-link'
                    onClick={() => onOpenModal(COMPANY_DETAILS_MODALS.EditBusinessDetails)}
                  >
                    Modify
                  </HyperlinkButton>
                </div>
                <SubTitle>ABN/ACN Number</SubTitle>
                <Text>{companyData.businessRegistrationNumber}</Text>
                <div className='p-medium bg-secondary width-full mt-small'>
                  {isLoadingRegistrationDetails ? (
                    <SpinningLoader size={50} message='' />
                  ) : errorWithRegistrationDetails ? (
                    'An error occurred while retrieving the details.'
                  ) : companyRegistrationDetails ? (
                    <RegistrationDetailView companyRegistrationDetails={companyRegistrationDetails} />
                  ) : (
                    'No match found.'
                  )}
                </div>
              </>
            }
          />
          <CompanyDetailsWidget
            title='Default timezone'
            subTitle='Default timezone for your business.'
            onHyperlinkClick={() => onOpenModal(COMPANY_DETAILS_MODALS.EditTimezone)}
            hyperlinkCaption='Change default timezone'
            content={
              <Text>
                <TimezoneIndicator timezone={companyData.timezone} />
              </Text>
            }
            testId='default-timezone'
          />
          <CompanyDetailsWidget
            title='reply-to email address'
            subTitle='This email address will be used to handle any replies sent from users who click reply to the
                notifications emails that we send out from GoodHuman.'
            onHyperlinkClick={() => onOpenModal(COMPANY_DETAILS_MODALS.EditReplyToEmail)}
            hyperlinkCaption=' Change reply-to details'
            content={
              <div className='mt-small'>
                <Text>
                  {companyData && companyData.replyEmailConfig ? companyData.replyEmailConfig.replyEmailAddress : ''}
                </Text>
              </div>
            }
            testId='reply-to-email-address'
          />
          <CompanyDetailsWidget
            title='Storage capacity'
            subTitle='How much storage your business is using in GoodHuman.'
            panelClassName='flex-row justify-between'
            content={<Text size='x-large'>{Number(companyData.totalUsedStorageSizeGb).toFixed(2)}GB</Text>}
          />
        </div>
      </div>
    </>
  );
};
