import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import { WorkflowTemplateStatus } from 'utilities/enum-utils';

interface IWorkflowTemplateStatusTagsProps {
  workflowStatus: string;
}

class WorkflowTemplateStatusTags extends Component<IWorkflowTemplateStatusTagsProps, any> {
  render() {
    const { workflowStatus } = this.props;
    let label, bgColor, textColor, borderColor;

    if (workflowStatus === WorkflowTemplateStatus.ACTIVE) {
      label = 'Active';
      bgColor = 'bg-green';
      textColor = 'text-color-green-lightest';
      borderColor = 'text-color-green';
    } else if (workflowStatus === WorkflowTemplateStatus.INACTIVE) {
      label = 'Inactive';
      bgColor = 'bg-quaternary';
      textColor = 'text-color-secondary';
      borderColor = 'text-color-tertiary';
    }

    const containerClassNames = `text-align-center pv-x-small ph-small text-size-regular inline-block rounded ${bgColor} bordered`;
    const textClassNames = `${textColor}`;
    return (
      <div
        className={containerClassNames}
        style={
          borderColor
            ? {
                borderColor,
              }
            : {}
        }
      >
        <Text size={'regular'} className={textClassNames}>
          {label}
        </Text>
      </div>
    );
  }
}

export default WorkflowTemplateStatusTags;
