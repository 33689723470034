import React from 'react';

import { Text } from '@good/components';

export const Count = ({ count }: { count: number }): JSX.Element => {
  return (
    <div
      className='
        flex
        items-center
        outline
        outline-gray-light-1
        rounded-md
        py-[2px]
        px-1
        text-weak
        bg-white
        group-hover:text-base
        group-aria-selected:outline-none
        group-aria-selected:text-ocean-dark-1
        group-active:outline-none
        group-active:bg-white
        group-active:text-accent
        group-focus-visible:outline-none
        group-focus-visible:bg-ocean-light-2
        group-focus-visible:text-accent
        '
    >
      <Text
        size='xs'
        className='
                font-semibold
                min-w-[13px]
                h-4
                text-center
                '
      >
        {count}
      </Text>
    </div>
  );
};
