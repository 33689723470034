import { useQuery } from '@tanstack/react-query';

import { fetchPrintSessionRoster } from 'stores/queries/roster/roster-queries';

export function useFetchPrintSessionRoster(payload: { serviceId: string; serviceDateTimeId: string }) {
  const { serviceDateTimeId, serviceId } = payload;
  const { data, remove, refetch, isFetching, isLoading, status, error } = useQuery(
    ['print-session-roster', serviceId, serviceDateTimeId],
    () => fetchPrintSessionRoster(payload),
    {
      refetchOnWindowFocus: false,
    },
  );

  return { data, remove, refetch, isFetching, isLoading, status, error };
}
