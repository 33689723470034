/* eslint-disable */
import _ from 'lodash';
import { IInvoiceConfig } from 'interfaces/company-interfaces';
import apiClient from 'utilities/api-client';
import { PaymentSourceType, UserPermissionRole } from 'utilities/enum-utils';
import Utils from 'utilities/Utils';
import { events } from 'integrations/appcues';
import { type CountryCode } from 'providers/locale-provider';

export type CompanyDataLite = {
  hasPlanManagement: boolean;
  hasSubscription: boolean;
  serviceProviderId: string;
  serviceProviderName: string;
  timezone: string;
  phoneCountryCode: CountryCode;
};

export interface CompanyStoreState {
  companyList: any[];
  companyData: any | null;
  companyDataLite: CompanyDataLite | null;
  ownerList: any | null;
  companyRegistrationDetails: any | null;
  temporaryRegistrationDetails: any | null;
  companyFundingAndPayment: any | null;
  companyFundingAndPaymentLite: any | null;
}

const companyStoreInitialState: CompanyStoreState = {
  companyList: [],
  companyData: null,
  companyDataLite: null,
  ownerList: null,
  companyRegistrationDetails: null,
  temporaryRegistrationDetails: null,
  companyFundingAndPayment: null,
  companyFundingAndPaymentLite: null,
};

const companyStore = {
  state: companyStoreInitialState,
  reducers: {
    setCompanyData: (state, payload) => ({ ...state, companyData: payload }),
    setCompanyDataLite: (state, payload) => ({ ...state, companyDataLite: payload }),
    setOwnerList: (state, payload) => ({ ...state, ownerList: payload }),
    setCompanyRegistrationData: (state, payload) => ({ ...state, companyRegistrationDetails: payload }),
    setTemporaryRegistrationData: (state, payload) => ({ ...state, temporaryRegistrationDetails: payload }),
    setCompanyFundingAndPayment: (state, payload) => ({ ...state, companyFundingAndPayment: payload }),
    setCompanyFundingAndPaymentLite: (state, payload) => ({ ...state, companyFundingAndPaymentLite: payload }),
    setPlanManagementData: (state, payload) => ({
      ...state,
      companyFundingAndPayment: {
        ...state.companyFundingAndPayment,
        planManagement: {
          hasPlanManagement: payload.hasPlanManagement,
          planManagementProviderEmail: payload.planManagementProviderEmail,
          planManagementProviderNumber: payload.planManagementProviderNumber,
        },
      },
    }),
    setIsInvoicesAndCreditNotesEmailEnabled: (state, payload) => ({
      ...state,
      companyFundingAndPayment: {
        ...state.companyFundingAndPayment,
        isOutGoingInvoicesEmailEnabled: payload.isOutGoingInvoicesEmailEnabled,
      },
    }),
    updateInvoiceConfigList: (state, payload: IInvoiceConfig) => {
      const paymentSources = _.map(state.companyFundingAndPayment.paymentSources, (funding) => {
        if (funding.paymentSourceType === PaymentSourceType.INVOICE) {
          return {
            ...funding,
            invoicePrefix: payload.invoicePrefix,
            paymentSourceConfiguration: payload,
          };
        } else {
          return { ...funding };
        }
      });
      return { ...state, companyFundingAndPayment: { ...state.companyFundingAndPayment, paymentSources } };
    },
    updateCompanyFundingPaymentSources: (state, payload) => {
      const paymentSources = _.map(state.companyFundingAndPayment.paymentSources, (funding) => {
        if (funding.paymentSourceType === PaymentSourceType.NDIS) {
          return {
            ...funding,
            paymentSourceConfiguration: {
              isTtpApproved: payload.isTtpApproved,
              ndisRegistrationNumber: payload.ndisRegistrationNumber,
            },
          };
        } else if (funding.paymentSourceType === PaymentSourceType.VCP) {
          return {
            ...funding,
            paymentSourceConfiguration: {
              vcpEnabled: payload.vcpEnabled,
            },
          };
        } else {
          return { ...funding };
        }
      });
      return { ...state, companyFundingAndPayment: { ...state.companyFundingAndPayment, paymentSources } };
    },
  },
  effects: (dispatch) => ({
    async doFetchCompany() {
      try {
        const endpoint = 'api/portal/service-provider/view';
        const result = await apiClient.get(endpoint);
        if (result.data) {
          dispatch.companyStore.setCompanyData(result.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchCompanyFundingAndPayment() {
      try {
        const endpoint = '/api/portal/service-provider/payment-sources/list';
        const result = await apiClient.get(endpoint);
        if (result.data) {
          dispatch.companyStore.setCompanyFundingAndPayment(result.data);
          return result.data;
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchCompanyFundingAndPaymentLite() {
      try {
        const endpoint = '/api/portal/service-provider/payment-sources/lite';
        const result = await apiClient.get(endpoint);
        if (result.data) {
          dispatch.companyStore.setCompanyFundingAndPaymentLite(result.data);
          return result.data;
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchCompanyLite() {
      try {
        const endpoint = 'api/portal/service-provider/lite';
        const result = await apiClient.get(endpoint);
        if (result.data) {
          dispatch.companyStore.setCompanyDataLite(result.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async getCompanyRegistrationDetails(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/business-registration-number/view';
        const result = await apiClient.post(endpoint, payload);
        payload.isLookingUp
          ? dispatch.companyStore.setTemporaryRegistrationData(result.data)
          : dispatch.companyStore.setCompanyRegistrationData(result.data);
      } catch (e) {
        payload.isLookingUp
          ? dispatch.companyStore.setTemporaryRegistrationData(null)
          : dispatch.companyStore.setCompanyRegistrationData(null);
        throw e;
      }
    },

    async doUpdateCompanyDetail(payload, rootState) {
      try {
        const endpoint = 'api/portal/service-provider/update';
        await apiClient.put(endpoint, payload);
        const companyData = rootState.companyStore.companyData;
        let updatedData = { ...companyData, ...payload };
        dispatch.companyStore.setCompanyData(updatedData);
      } catch (e) {
        throw e;
      }
    },

    async doFetchOwnerList(payload, rootState) {
      try {
        const endpoint = '/api/portal/account/owners/list';
        const result = await apiClient.get(endpoint, payload);

        dispatch.companyStore.setOwnerList(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doEditBusinessDetails(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/registered-business-details';
        await apiClient.put(endpoint, payload);
        const companyData = rootState.companyStore.companyData;
        let updatedData = { ...companyData, businessRegistrationNumber: payload.businessRegistrationNumber };
        dispatch.companyStore.setCompanyData(updatedData);
        dispatch.companyStore.getCompanyRegistrationDetails({
          businessRegistrationNumber: payload.businessRegistrationNumber,
          businessRegistrationNumberType: payload.businessRegistrationNumber.length === 9 ? 'ACR' : 'ABN',
          isLookingUp: false,
        });
      } catch (e) {
        throw e;
      }
    },

    async doAssignNewPrimaryOwner(payload, rootState) {
      try {
        const endpoint = '/api/portal/account/primary-owners/switch';
        const currentSubscriptionPermission = rootState.authStore?.portalUser?.permissions?.permissionRoles?.find(
          (permission) =>
            permission.permissionRole === UserPermissionRole.SubscriptionBillingAllAccess ||
            permission.permissionRole === UserPermissionRole.SubscriptionBillingViewOnly,
        );

        await apiClient.post(endpoint, payload);
        dispatch.authStore.setCurrentPortalUser({
          ...rootState.authStore.portalUser,
          permissions: {
            hasPortalAccess: true,
            hasWorkerAppAccess: true,
            permissionRoles: [
              {
                serviceId: null,
                permissionRole: UserPermissionRole.Owner,
                serviceDepartmentId: null,
              },
              currentSubscriptionPermission ?? {},
            ],
          },
        });
      } catch (e) {
        throw e;
      }
    },

    async doEditDisplayName(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/display-name';
        await apiClient.put(endpoint, payload);
        dispatch.companyStore.setCompanyData({
          ...rootState.companyStore.companyData,
          serviceProviderName: payload.displayName,
        });
      } catch (e) {
        throw e;
      }
    },

    async doEditCompanyEmail(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/email';
        await apiClient.put(endpoint, payload);
        dispatch.companyStore.setCompanyData({ ...rootState.companyStore.companyData, ...payload });
      } catch (e) {
        throw e;
      }
    },

    async doEditCompanyAddress(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/primary-address';
        await apiClient.put(endpoint, payload);
        dispatch.companyStore.setCompanyData({ ...rootState.companyStore.companyData, address: payload });

        events.trackAddCompanyAddress({
          address1: payload.streetAddress1,
          address2: payload.streetAddress2,
        });
      } catch (e) {
        throw e;
      }
    },

    async doEditCompanyTimezone(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/timezone';
        await apiClient.put(endpoint, payload);
        dispatch.companyStore.setCompanyData({ ...rootState.companyStore.companyData, timezone: payload.timezone });
      } catch (e) {
        throw e;
      }
    },

    async doEditInvoiceConfig(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/invoice-configurations';
        await apiClient.put(endpoint, payload);
        dispatch.companyStore.updateInvoiceConfigList(payload);

        events.trackSetInvoiceTerms({
          invoicePrefix: payload.invoicePrefix,
          invoiceDueAfter: payload.invoiceDueDate,
          invoiceTermAndConditions: payload.invoiceTerms,
        });
      } catch (e) {
        throw e;
      }
    },

    async doEditNDISConfig(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/ndis-configurations';
        await apiClient.put(endpoint, payload);
        payload.paymentSourceType = PaymentSourceType.NDIS;
        dispatch.companyStore.updateCompanyFundingPaymentSources(payload);
      } catch (e) {
        throw e;
      }
    },

    async doEditVCPConfig(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/vcp-configurations';
        const resp = await apiClient.put(endpoint, payload);
        if (Utils.isEmpty(resp.meta.errorCode)) {
          payload.paymentSourceType = PaymentSourceType.VCP;
          dispatch.companyStore.updateCompanyFundingPaymentSources(payload);
        }
        return resp;
      } catch (e) {
        throw e;
      }
    },

    async doUpdatePlanManagementConfigurations(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/plan-management-configurations';
        const resp = await apiClient.put(endpoint, payload);
        if (resp.status === 200) {
          dispatch.companyStore.setPlanManagementData(payload);
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateReplyEmailConfig(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/reply-email-address-config';
        const resp = await apiClient.put(endpoint, payload);
        if (resp.status === 200) {
          dispatch.companyStore.setCompanyData({
            ...rootState.companyStore.companyData,
            replyEmailConfig: {
              replyEmailName: payload.replyEmailName,
              replyEmailAddress: payload.replyEmailAddress,
            },
          });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateInvoicesAndCreditNotes(payload: { isOutGoingInvoicesEmailEnabled: boolean }): Promise<void> {
      const endPoint = 'api/portal/service-provider/outgoing-invoice';
      try {
        const res = await apiClient.put(endPoint, payload);
        if (res.status === 200) {
          dispatch.companyStore.setIsInvoicesAndCreditNotesEmailEnabled(payload);
        }
      } catch (e) {
        throw e;
      }
    },
  }),
};

export default companyStore;
