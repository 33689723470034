import { Text } from '@good/components';
import React from 'react';
import GeneralAvailability from './components/general-availability';
import ScheduledUnavailability from './components/scheduled-unavailability';

export const SupportWorkerAvailability = ({ hasEditPermission }: { hasEditPermission: boolean }) => {
  return (
    <div className="flex flex-col">
      <Text size="xl" className="mb-5 font-bold" asChild>
        <h2>Availability</h2>
      </Text>
      <div className="mb-6">
        <GeneralAvailability hasEditPermission={hasEditPermission} />
      </div>
      <div className="mb-6">
        <ScheduledUnavailability hasEditPermission={hasEditPermission} />
      </div>
    </div>
  );
};
