import { Menu, MenuItem, Popover } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Checkbox, Icon, Row, Tooltip, notification } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { PaymentMethodTag } from 'common-components/tags';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootState, state, IRootDispatch } from 'stores/rematch/root-store';
import { CLAIM_TYPE_STANDARD_OPTIONS } from 'views/billings/utils/constants';
import CommonUtils from 'utilities/common-utils';
import { ProcessedPaymentAction, ServiceType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import Utils from 'utilities/Utils';
import InvoiceErrorIndicator from '../InvoiceErrorIndicator';
import { DateDisplay } from './DateDisplay';

interface IInvoiceItemRow {
  invoiceItem?: any;
  history?: any;
  openActionModal?: any;
  onClickReject?: any;
  setSelectedBillingLineItem?: typeof dispatch.billingsStore.setSelectedBillingLineItem;
  setSelectedRejectBillingItems?: typeof dispatch.billingsStore.setSelectedRejectBillingItems;
  portalUser?: typeof state.authStore.portalUser;
  selectedRejectBillingItems?: typeof state.billingsStore.selectedRejectBillingItems;
  onCheckInvoice?: (invoiceId) => void;
  isChecked?: boolean;
  doResendEmail: typeof dispatch.billingsStore.doResendEmail;
}

const unit = {
  HOURS: 'H',
  EACH: 'EA',
};

const unitOptions = [
  { value: unit.HOURS, label: 'hours' },
  { value: unit.EACH, label: 'each' },
];

class InvoiceItemRow extends Component<IInvoiceItemRow> {
  _checkbox = null;
  state = {
    customerId: null,
    showSubLines: false,
    openAction: false,
    invoiceId: null,
  };

  _doResendEmail = async (invoiceId, invoiceNumber, debtorName) => {
    const { doResendEmail } = this.props;
    const toDebtorName = debtorName ? ` to ${debtorName}` : '';

    try {
      await doResendEmail(invoiceId);

      notification.success({
        message: 'Invoice re-sent',
        description: `Invoice ${invoiceNumber ?? ''} has been re-sent${toDebtorName}`,
      });
    } catch (e) {
      notification.error({
        message: 'Resend unsuccessful',
        description: `Invoice ${invoiceNumber ?? ''} couldn't be sent${debtorName ?? ''}. Please try again`,
      });
    }
  };

  private _onClickBooking = (attendanceId) => {
    const { history } = this.props;
    history.push(`/bookings/details/${attendanceId}`);
  };

  private _onClickOpen = async () => {
    this.setState({ showSubLines: true });
  };

  private _onClickClose = async () => {
    this.setState({ showSubLines: false });
  };

  private _onClickBatch = (batchId) => {
    const { history } = this.props;
    history.push(`/batch/details/${batchId}`);
  };

  private _onClickCustomer = (userId) => {
    const { history } = this.props;
    history.push(`/customer/details/${userId}`);
  };

  private _onCheckInvoice = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { onCheckInvoice, invoiceItem } = this.props;
    onCheckInvoice(invoiceItem);
  };

  private _disableMarkAsPaid = (items) => {
    const rejectedItems = _.filter(items, (item) => item.isRejected);
    return rejectedItems.length === items.length;
  };

  componentDidMount = () => {
    const { invoiceItem } = this.props;

    if (!_.isEmpty(invoiceItem)) {
      const { invoiceId } = invoiceItem;
      this.setState({ invoiceId });
    }
  };

  render() {
    const { invoiceItem, portalUser, isChecked = false, setSelectedRejectBillingItems, openActionModal } = this.props;

    if (_.isEmpty(invoiceItem)) {
      return <></>;
    }

    const {
      exportedDate,
      invoiceTotal,
      firstName,
      lastName,
      userId,
      invoiceId,
      invoiceNumber,
      numberOfItem,
      batchNumber,
      batchId,
      paymentMethod,
      items,
      debtorName,
      numberOfRejected: rejectedCount,
      numberOfWaived: waivedCount,
      numberOfReapproved: reapprovedCount,
    } = invoiceItem;

    const displayBorder = this.state.showSubLines ? 'bordered-none' : '';
    const isDisableMarkAsPaid = this._disableMarkAsPaid(items);
    const availableToReject = _.some(items, (item) => !item.isRejected);

    return (
      <>
        <tr
          onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}
          className={`anim-fade-in bg-white ${this.state.showSubLines && 'no-border'}`}
        >
          <td
            className={`${displayBorder} fluid-cell check-all `}
            onClick={(e) => {
              if (!isDisableMarkAsPaid) {
                this._onCheckInvoice(e);
              }
            }}
            onMouseEnter={() => this._checkbox.focus()}
            onMouseLeave={() => this._checkbox.blur()}
          >
            <Checkbox checked={isChecked} ref={(c) => (this._checkbox = c)} disabled={isDisableMarkAsPaid} />
          </td>
          <td className="fluid-cell text-size-large text-weight-bold">{moment(exportedDate).format('DD/MM/YYYY')}</td>
          <td className="ellipsis width-1/4">
            {paymentMethod && (
              <PaymentMethodTag paymentMethod={paymentMethod} className="mr-small" rounded={true} size="small" />
            )}
            <HyperlinkButton
              onClick={(e) => {
                e.stopPropagation();
                window.open(`/pdf?type=view-invoice&invoiceId=${invoiceId}`, '_blank');
              }}
              style={{ whiteSpace: 'nowrap' }}
            >
              {invoiceNumber}
            </HyperlinkButton>{' '}
            ({numberOfItem} item{numberOfItem > 1 && 's'})
          </td>
          <td className="width-1/5">
            <div className="pr-small">
              <Text style={{ whiteSpace: 'nowrap' }} onClick={(e) => e.stopPropagation()}>
                {PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles) ? (
                  <HyperlinkButton onClick={() => this._onClickCustomer(userId)} color="black">
                    {firstName} {lastName}
                  </HyperlinkButton>
                ) : (
                  firstName + ' ' + lastName
                )}
              </Text>
            </div>
          </td>
          <td className="ellipsis">
            <div className="pr-small">
              <HyperlinkButton onClick={() => this._onClickBatch(batchId)} style={{ whiteSpace: 'nowrap' }}>
                {batchNumber}
              </HyperlinkButton>
            </div>
          </td>
          <td className="ellipsis">
            <div className="pr-small text-align-right">
              <Text style={{ whiteSpace: 'nowrap' }}>{CommonUtils.formatPrice(invoiceTotal)}</Text>
              <InvoiceErrorIndicator
                errorCounts={{
                  reapprovedCount,
                  waivedCount,
                  rejectedCount,
                }}
              />
            </div>
          </td>
          <td style={{ width: '100px', textAlign: 'center' }} onClick={(e) => e.stopPropagation()}>
            <Popover2
              content={
                <Menu>
                  {PermissionUtils.validatePermission('ViewInvoices', portalUser.permissions.permissionRoles) && (
                    <MenuItem
                      text={
                        <div
                          onClick={() => {
                            window.open(`/pdf?type=view-invoice&invoiceId=${invoiceId}`, '_blank');
                          }}
                        >
                          <HyperlinkButton>View invoice</HyperlinkButton>
                        </div>
                      }
                      className="hover-bg-gray-lightest"
                    />
                  )}
                  {PermissionUtils.validatePermission('MarkInvoicesAsPaid', portalUser.permissions.permissionRoles) && (
                    <MenuItem
                      disabled={isDisableMarkAsPaid}
                      text={
                        <div
                          onClick={() => {
                            if (!isDisableMarkAsPaid) {
                              openActionModal({
                                action: 'MARK_AS_PAID',
                                additionalData: items,
                              });
                            }
                          }}
                        >
                          <HyperlinkButton color={isDisableMarkAsPaid ? ' text-color-secondary' : 'blue-action'}>
                            Mark as paid
                            {isDisableMarkAsPaid && (
                              <Tooltip
                                title={
                                  <div style={{ paddingLeft: '10px' }}>
                                    <p>
                                      You cannot mark an invoice
                                      <br />
                                      as paid if all line items are
                                      <br />
                                      rejected
                                    </p>
                                  </div>
                                }
                              >
                                <Icon type="question-circle" className="ml-12" />
                              </Tooltip>
                            )}
                          </HyperlinkButton>
                        </div>
                      }
                      className="hover-bg-gray-lightest"
                    />
                  )}
                  {paymentMethod !== 'NDIA' &&
                    PermissionUtils.validatePermission('ViewInvoices', portalUser.permissions.permissionRoles) && (
                      <MenuItem
                        onClick={() => this._doResendEmail({ invoiceId: invoiceId }, invoiceNumber, debtorName)}
                        text={<HyperlinkButton>Resend invoice</HyperlinkButton>}
                        className="hover-bg-gray-lightest"
                      />
                    )}
                  {availableToReject &&
                    PermissionUtils.validatePermission(
                      'RejectPaymentBillingLineItem',
                      portalUser.permissions.permissionRoles,
                    ) && (
                      <MenuItem
                        text={
                          <div>
                            <HyperlinkButton
                              color="red"
                              onClick={() => {
                                if (!Utils.isEmpty(items) && items.length === 1) {
                                  const newItem = {
                                    billingLineItemId: items[0].bookingBillingLineItemId,
                                    attendanceId: items[0].attendanceId,
                                  };
                                  setSelectedRejectBillingItems([newItem]);
                                } else {
                                  setSelectedRejectBillingItems([]);
                                }
                                openActionModal({ action: 'REJECT_LINEITEM', additionalData: items });
                              }}
                            >
                              Reject Line Items
                            </HyperlinkButton>
                          </div>
                        }
                        className="hover-bg-gray-lightest"
                      />
                    )}
                  {paymentMethod !== 'NDIA' && availableToReject && (
                    <MenuItem
                      text={
                        <div>
                          <HyperlinkButton
                            color="red"
                            onClick={() =>
                              openActionModal({
                                action: 'REJECT_LINEITEM',
                                additionalData: items,
                                selected: 'ALL',
                              })
                            }
                          >
                            Reject Entire Invoice
                          </HyperlinkButton>
                        </div>
                      }
                      className="hover-bg-gray-lightest"
                    />
                  )}
                </Menu>
              }
              position={'bottom-right'}
            >
              <Icon type="ellipsis" className="text-size-x4-large" />
            </Popover2>
          </td>
          <td style={{ width: '20px' }} onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}>
            <div className="pr-small text-align-right">
              {this.state.showSubLines ? (
                <Icon className="size-x-large" type="up" />
              ) : (
                <Icon className="size-x-large" type="down" />
              )}
            </div>
          </td>
        </tr>
        <tr>
          {this.state.showSubLines && (
            <>
              <td colSpan={8} className="p-none">
                <table className="subLines" style={{ width: '100%' }}>
                  {_.map(items, (lineItem, key) => (
                    <tr className={`text-align-middle ${items.length === key + 1 && 'no-border'}`} key={key}>
                      {/* Meant to simulate the checkbox cell width, hence the hardcoded 64px  */}
                      <td style={{ width: '64px' }}></td>
                      <td className="fluid-cell">
                      <DateDisplay
                          lineItem={
                            lineItem as { serviceType: ServiceType; startDateTime: string; attendanceId: string }
                          }
                          onClick={this._onClickBooking}
                        />
                      </td>
                      <td className="width-1/4">
                        <p className="mb-none">{lineItem.supportItemNumber}</p>
                        <p className="mb-none">
                          {lineItem.claimType
                            ? CLAIM_TYPE_STANDARD_OPTIONS.find((item) => item.value === lineItem.claimType).label
                            : 'Standard Service'}
                        </p>
                      </td>
                      <td className="width-1/5">
                        {CommonUtils.formatQuantityDisplay(lineItem.qty)} (
                        {unitOptions.find((item) => item.value === lineItem.unit).label}) x{' '}
                        {CommonUtils.formatPrice(
                          lineItem.isIncludeGst
                            ? CommonUtils.calcPriceIncludeGst(lineItem.unitPrice, lineItem.gstValue)
                            : lineItem.unitPrice,
                        )}{' '}
                        {lineItem.isIncludeGst && (
                          <Text size="x-small" color={'secondary'}>
                            inc.GST
                          </Text>
                        )}
                      </td>
                      <td className="ellipsis">{lineItem.claimReference}</td>
                      <td className="ellipsis flex-row justify-end align-center" colSpan={2}>
                        <div className="text-align-right">{CommonUtils.formatPrice(lineItem.billingTotal)}</div>
                        <InvoiceErrorIndicator
                          errorCounts={{
                            rejectedCount: lineItem?.actionType === ProcessedPaymentAction.REJECTED ? 1 : 0,
                            reapprovedCount: lineItem?.actionType === ProcessedPaymentAction.REAPPROVED ? 1 : 0,
                            waivedCount: lineItem?.actionType === ProcessedPaymentAction.WAIVED ? 1 : 0,
                          }}
                          lineItem={lineItem}
                        />
                      </td>
                      <td style={{ width: '158px' }}></td>
                    </tr>
                  ))}
                </table>
              </td>
            </>
          )}
        </tr>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  selectedRejectBillingItems: state.billingsStore.selectedRejectBillingItems,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doResendEmail: dispatch.billingsStore.doResendEmail,
});

export default connect(mapState, mapDispatch)(InvoiceItemRow);
