import React, { Component } from 'react';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import _ from 'lodash';
import { Paragraph, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Spinner } from '@blueprintjs/core';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import FundGoalEditDetailRow from 'views/customers/details/tabs-panel/funding/components/FundGoalEditDetailRow';

interface IFundGoalEditDetailRowProps extends FormComponentProps {
  closeEditGoalModal: () => void;
  packageId: string;
  selectedCustomer: any;
  doUpdateFundingPackageGoal: typeof dispatch.customersStore.doUpdateFundingPackageGoal;
}

interface IFundGoalEditDetailRowState {
  goalFundData: any;
  isSaving: boolean;
  isActionModalOpen: boolean;
}

class FundGoalEditDetailPanel extends Component<IFundGoalEditDetailRowProps, IFundGoalEditDetailRowState> {
  state = {
    isSaving: false,
    isActionModalOpen: false,
    goalFundData: [],
  };

  private goalInputRefs: any[] = [];

  componentDidMount() {
    const { selectedCustomer, packageId } = this.props;
    let fundpackages = selectedCustomer.funding.NDISFundingPackages;
    const selectedPackage = _.find(fundpackages, (fundpackage) => fundpackage.fundingPackageId === packageId);
    let prevGoals = selectedPackage.fundingNDISGoals;
    this.setState({ goalFundData: prevGoals });
  }

  private _closeActionModal = () => {
    this.setState({ isActionModalOpen: false });
  };

  private _closeModalWithAction = () => {
    const { selectedCustomer, packageId } = this.props;
    let fundpackages = selectedCustomer.funding.NDISFundingPackages;
    const selectedPackage = _.find(fundpackages, (fundpackage) => fundpackage.fundingPackageId === packageId);
    let prevGoals = selectedPackage.fundingNDISGoals;

    if (this.state.goalFundData === prevGoals) {
      this.props.closeEditGoalModal();
    } else {
      this.setState({ isActionModalOpen: true });
    }
  };

  private _onSave = async () => {
    const { selectedCustomer, packageId, doUpdateFundingPackageGoal } = this.props;
    let isFormValid = true;
    const goalDataArray = [];
    _.forEach(this.goalInputRefs, (goalRef) => {
      goalRef.props.form.validateFields((err) => {
        if (err) {
          isFormValid = false;
        } else {
          if (goalRef.props.goal?.isDeleted !== true) {
            goalDataArray.push(
              goalRef.props.form.getFieldsValue(['fundingNDISGoalId', 'goalDescription', 'goalTitle', 'type']),
            );
          }
        }
      });
    });

    if (isFormValid) {
      let payload = { userId: selectedCustomer.userId, fundingPackageId: packageId, goals: goalDataArray };
      this.setState({ isSaving: true });
      await doUpdateFundingPackageGoal(payload);
      this.setState({ isSaving: false });
      this.props.closeEditGoalModal();
    }
  };

  private _addNewGoal = () => {
    const previousState = this.state.goalFundData;
    const afterState = previousState.concat({ type: null, goalTitle: null, goalDescription: null });
    this.setState({ goalFundData: afterState });
  };

  private _setRef = (ref) => {
    if (ref) {
      this.goalInputRefs.push(ref);
    }
  };

  private _onRemoveRow = (key) => {
    const currentGoalFundData = this.state.goalFundData;
    currentGoalFundData[key]['isDeleted'] = true;
    this.setState({ goalFundData: currentGoalFundData });
    this.goalInputRefs[key].props.form.setFields({
      isDeleted: {
        value: true,
      },
    });
  };

  render() {
    return (
      <div>
        {this.state.isSaving ? (
          <div>
            <div className="mt-x-large">
              <div className="align-center mb-x-large flex-row">
                <div className="mr-x-large">
                  <Spinner size={120} />
                </div>
                <div>
                  <Paragraph>Loading.......</Paragraph>
                  <Paragraph weight="bold">Editing Goal.</Paragraph>
                  <Paragraph>This won't take long.</Paragraph>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <ActionModal
              isOpen={this.state.isActionModalOpen}
              onClose={this._closeActionModal}
              title={'Confirmation'}
              showCloseButton={true}
            >
              <Text className={'mb-medium'}>You are closing the editing page without saving.</Text>
              <br />
              <Text className={'mb-medium'}>Do you want to proceed?</Text>
              <ActionModalFooter>
                <PrimaryButton className="mr-medium" size="large" onClick={this._closeActionModal}>
                  Cancel
                </PrimaryButton>
                <GhostButton size="large" onClick={this.props.closeEditGoalModal}>
                  Proceed
                </GhostButton>
              </ActionModalFooter>
            </ActionModal>
            <div className={'customScrollDiv'}>
              {_.chain(this.state.goalFundData)
                .map((fundData, i) => (
                  <FundGoalEditDetailRow
                    goal={fundData}
                    key={i}
                    rowIndex={i}
                    onRemoveRow={this._onRemoveRow}
                    wrappedComponentRef={this._setRef}
                  />
                ))
                .value()}
            </div>
            <div className="mt-medium pv-small" style={{ marginBottom: 'auto' }}>
              <GhostButton icon={'plus-circle'} onClick={this._addNewGoal}>
                Add Goal
              </GhostButton>
            </div>
            <div className="mt-x2-large align-center flex-row justify-end">
              <GhostButton className={'mr-small'} onClick={this._closeModalWithAction}>
                Cancel
              </GhostButton>
              <PrimaryButton size="large" icon={'save'} onClick={this._onSave}>
                Save Changes
              </PrimaryButton>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateFundingPackageGoal: dispatch.customersStore.doUpdateFundingPackageGoal,
});

export default connect(null, mapDispatch)(Form.create<IFundGoalEditDetailRowProps>()(FundGoalEditDetailPanel));
