import { Checkbox, Col, Form, notification, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text, Title } from 'common-components/typography';
import { timeZone } from 'interfaces/timezone-type';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import TimeInput from 'common-components/time-input/TimeInput';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import NumberInput from 'common-components/inputs/NumberInput';
import TextArea from 'antd/lib/input/TextArea';
import { NoteVisibleType } from 'utilities/enum-utils';
import { isEmpty, trim } from 'lodash';
import { CalendarOutlined } from '@ant-design/icons';
import { CompleteBookingStep } from '../utils/constants';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface IBookingCompleteActionModalProps extends FormComponentProps {
  isOpen: boolean;
  displayTimezone: timeZone;
  onClose(): void;
}

function BookingCompleteActionModal(props: IBookingCompleteActionModalProps): JSX.Element {
  const { isOpen, onClose, displayTimezone, form } = props;
  const selectedBookingItem = useSelector((state: IRootState) => state.bookingsStore.selectedBookingItem);
  const dispatch = useDispatch<IRootDispatch>();

  const [step, setStep] = useState<CompleteBookingStep>(CompleteBookingStep.COMPLETE_BOOKING);
  const [isDateError, setIsDateError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bookingInfo, setBookingInfo] = useState({
    checkInTime: selectedBookingItem.startDateTime,
    checkOutTime: selectedBookingItem.endDateTime,
    isTravelBeforeBooking: false,
    travelDistanceBeforeBooking: null,
    travelTimeBeforeBooking: null,
    additionalCostBeforeBooking: null,
    isTravelDuringBooking: false,
    travelDistanceDuringBooking: null,
    additionalCostDuringBooking: null,
  });

  const onSubmit = () => {
    form.validateFields(async (err, value) => {
      if (!err) {
        const noteContent = value.note;
        try {
          setIsLoading(true);
          await dispatch.bookingsStore.doCompleteBooking({
            checkInTime: bookingInfo.checkInTime,
            checkOutTime: bookingInfo.checkOutTime,
            noteContent,
            isPrivate: false,
            isIncident: false,
            visibleType: NoteVisibleType.PORTAL_AND_APP,
            isTravelBeforeBooking: bookingInfo.isTravelBeforeBooking,
            travelDistanceBeforeBooking: Number(bookingInfo.travelDistanceBeforeBooking) || 0,
            travelTimeBeforeBooking: Number(bookingInfo.travelTimeBeforeBooking) || 0,
            additionalCostBeforeBooking: Number(bookingInfo.additionalCostBeforeBooking) || 0,
            isTravelDuringBooking: bookingInfo.isTravelDuringBooking,
            travelDistanceDuringBooking: Number(bookingInfo.travelDistanceDuringBooking) || 0,
            additionalCostDuringBooking: Number(bookingInfo.additionalCostDuringBooking) || 0,
          });
          notification.open({
            message: <b>Booking completed</b>,
            description: 'You have successfully completed this booking on behalf of the assigned team member.',
          });
          onClose();
        } catch (e) {
          notification.error({
            message: 'Oops, something went wrong! Please try again.',
          });
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const onCloseModal = () => {
    onClose();
  };

  const checkValidDateTime = () => {
    const startTime = moment(form.getFieldValue(`startTime`));
    const endTime = moment(form.getFieldValue(`endTime`));
    const startDate = moment(form.getFieldValue('startDate'));
    const endDate = moment(form.getFieldValue('endDate'));
    startDate.set('hour', startTime.hour());
    startDate.set('minutes', startTime.minutes());
    endDate.set('hour', endTime.hour());
    endDate.set('minutes', endTime.minutes());
    setIsDateError(
      moment
        .tz(endDate, selectedBookingItem.timezone)
        .isSameOrBefore(moment.tz(startDate, selectedBookingItem.timezone)),
    );
  };

  const onChangeDate = (value: Date, type: 'start' | 'end') => {
    const newTime = moment(form.getFieldValue(`${type}Time`));
    const newDate = moment(value);

    newDate.set('hour', newTime.hours());
    newDate.set('minutes', newTime.minutes());

    setTimeout(() => {
      form.setFieldsValue({
        [`${type}Time`]: newDate.toDate(),
        [`${type}Date`]: newDate.toDate(),
      });
      checkValidDateTime();
    }, 0);
  };

  const roundTime = (value: Date, type: 'start' | 'end') => {
    const roundedTime = CommonUtils.formatCeilingDateTime(value);
    setTimeout(() => {
      form.setFieldsValue({
        [`${type}Time`]: roundedTime.toDate(),
        [`${type}Date`]: roundedTime.toDate(),
      });
      checkValidDateTime();
    }, 0);
  };

  const handleTravelBeforeBooking = (event: CheckboxChangeEvent) => {
    setBookingInfo((prev) => ({ ...prev, isTravelBeforeBooking: event.target.checked }));
  };

  const handleTravelDuringBooking = (event: CheckboxChangeEvent) => {
    setBookingInfo((prev) => ({ ...prev, isTravelDuringBooking: event.target.checked }));
  };

  const goToNext = () => {
    setIsDateError(false);
    form.validateFields(async (err, value) => {
      if (!err) {
        const ndisClaimCheck =
          selectedBookingItem.serviceClaimConfig.isChargeNdisTransportDuringBooking ||
          selectedBookingItem.serviceClaimConfig.isChargeNdisTransportBeforeBooking;

        const vcpClaimCheck = selectedBookingItem.serviceClaimConfig.isChargeVcpTransportDuringBooking;

        const serviceClaimCheck = selectedBookingItem.paymentSourceType === 'VCP' ? vcpClaimCheck : ndisClaimCheck;

        if (step === CompleteBookingStep.COMPLETE_BOOKING) {
          const { startDate, endDate } = value;
          const checkInTime = moment.tz(moment(startDate).format('YYYY-MM-DD HH:mm'), displayTimezone).toISOString();
          const checkOutTime = moment.tz(moment(endDate).format('YYYY-MM-DD HH:mm'), displayTimezone).toISOString();
          setStep(serviceClaimCheck ? CompleteBookingStep.TRAVEL_CLAIMS : CompleteBookingStep.CASE_NOTE);
          setBookingInfo((prev) => ({ ...prev, checkOutTime, checkInTime }));
        } else if (step === CompleteBookingStep.TRAVEL_CLAIMS) {
          setStep(CompleteBookingStep.CASE_NOTE);
          setBookingInfo((prev) => ({
            ...prev,
            travelDistanceBeforeBooking: form.getFieldValue('travelDistanceBeforeBooking'),
            travelTimeBeforeBooking: form.getFieldValue('travelTimeBeforeBooking'),
            additionalCostBeforeBooking: form.getFieldValue('additionalCostBeforeBooking'),
            travelDistanceDuringBooking: form.getFieldValue('travelDistanceDuringBooking'),
            travelTimeDuringBooking: form.getFieldValue('travelTimeDuringBooking'),
            additionalCostDuringBooking: form.getFieldValue('additionalCostDuringBooking'),
          }));
        }
      }
    });
  };

  const goToPrevious = () => {
    if (step === CompleteBookingStep.CASE_NOTE) {
      const ndisClaimCheck =
        selectedBookingItem.serviceClaimConfig.isChargeNdisTransportDuringBooking ||
        selectedBookingItem.serviceClaimConfig.isChargeNdisTransportBeforeBooking;

      const vcpClaimCheck = selectedBookingItem.serviceClaimConfig.isChargeVcpTransportDuringBooking;

      const serviceClaimCheck = selectedBookingItem.paymentSourceType === 'VCP' ? vcpClaimCheck : ndisClaimCheck;
      setStep(serviceClaimCheck ? CompleteBookingStep.TRAVEL_CLAIMS : CompleteBookingStep.COMPLETE_BOOKING);
    } else if (step === CompleteBookingStep.TRAVEL_CLAIMS) {
      setStep(CompleteBookingStep.COMPLETE_BOOKING);
      setBookingInfo((prev) => ({
        ...prev,
        travelDistanceBeforeBooking: form.getFieldValue('travelDistanceBeforeBooking'),
        travelTimeBeforeBooking: form.getFieldValue('travelTimeBeforeBooking'),
        additionalCostBeforeBooking: form.getFieldValue('additionalCostBeforeBooking'),
        travelDistanceDuringBooking: form.getFieldValue('travelDistanceDuringBooking'),
        travelTimeDuringBooking: form.getFieldValue('travelTimeDuringBooking'),
        additionalCostDuringBooking: form.getFieldValue('additionalCostDuringBooking'),
      }));
    }
  };

  const validateCaseNote = (rule, value, callback) => {
    if (isEmpty(trim(value)) || trim(value).length < 5) {
      callback('Please enter at least 5 characters');
      return;
    }
    callback();
  };

  useEffect(
    function resetState() {
      if (isOpen) {
        setStep(CompleteBookingStep.COMPLETE_BOOKING);
        setIsDateError(false);
        setBookingInfo((prev) => ({
          ...prev,
          checkInTime: selectedBookingItem.startDateTime,
          checkOutTime: selectedBookingItem.endDateTime,
        }));
      }
    },
    [isOpen],
  );

  const { getFieldDecorator } = form;

  const roundedStartDateTime = moment(moment.tz(bookingInfo.checkInTime, displayTimezone).format('YYYY-MM-DD HH:mm'));
  const roundedEndDateTime = moment(moment.tz(bookingInfo.checkOutTime, displayTimezone).format('YYYY-MM-DD HH:mm'));

  return (
    <ActionModal
      title={
        <Title weight="bolder" level={4} style={{ color: 'black' }}>
          {step === CompleteBookingStep.COMPLETE_BOOKING
            ? 'Complete booking'
            : step === CompleteBookingStep.TRAVEL_CLAIMS
            ? 'Travel claims'
            : 'Case note'}
        </Title>
      }
      isOpen={isOpen}
      onClose={onCloseModal}
      width="medium"
      verticalAlignment="highest"
    >
      {step === CompleteBookingStep.COMPLETE_BOOKING && (
        <>
          <div className="text-align-left">
            <div className="mb-medium mt-small">
              <Paragraph size="large" style={{ color: 'black' }}>
                You are completing this booking on behalf of{' '}
                <span className="text-weight-bold">
                  {`${selectedBookingItem.workerFirstName} ${selectedBookingItem.workerLastName}.`}
                </span>
              </Paragraph>
            </div>

            <div className="mb-large mt-x-small flex">
              <div style={{ color: 'black' }} className="flex-column align-left position-relative">
                <Text size="small" color="secondary" className="mb-x-small">
                  Start date
                </Text>
                {getFieldDecorator('startDate', {
                  initialValue: roundedStartDateTime.toDate(),
                  valuePropName: 'selected',
                  rules: [{ required: true, message: 'Please enter a check-in date!' }],
                })(
                  <DatePicker
                    className="gh-datepicker text-size-large mr-medium pv-small ph-12 rounded"
                    calendarClassName="gh-datepicker-calendar"
                    dateFormat="d/M/yyyy"
                    onChange={(date) => onChangeDate(date, 'start')}
                  />,
                )}
                <CalendarOutlined
                  className="position-absolute disabled"
                  style={{ left: 123, bottom: 12, color: '#9D9D9D' }}
                />
              </div>
              <div className="flex-column align-left position-relative">
                <Text size="small" color="secondary" className="mb-x-small">
                  Start time
                </Text>
                {getFieldDecorator('startTime', { initialValue: roundedStartDateTime })(
                  <TimeInput
                    className="pv-small ph-12 line-height-100 "
                    containerStyle={{ width: 129, color: 'black' }}
                    onChange={(value) => roundTime(value, 'start')}
                  />,
                )}
              </div>
            </div>

            <div className="mb-large mt-x-small flex">
              <div style={{ color: 'black' }} className="flex-column align-left position-relative">
                <Text size="small" color="secondary" className="mb-x-small">
                  Finish date
                </Text>
                {getFieldDecorator('endDate', {
                  initialValue: roundedEndDateTime.toDate(),
                  valuePropName: 'selected',
                  rules: [{ required: true, message: 'Please enter a check-in date!' }],
                })(
                  <DatePicker
                    className="gh-datepicker text-size-large mr-medium pv-small ph-12 rounded"
                    calendarClassName="gh-datepicker-calendar"
                    dateFormat="d/M/yyyy"
                    onChange={(date) => onChangeDate(date, 'end')}
                  />,
                )}
                <CalendarOutlined
                  className="position-absolute disabled"
                  style={{ left: 123, bottom: 12, color: '#9D9D9D' }}
                />
              </div>
              <div className="flex-column align-left">
                <Text size="small" color="secondary" className="mb-x-small">
                  Finish time
                </Text>
                {getFieldDecorator('endTime', { initialValue: roundedEndDateTime })(
                  <TimeInput
                    className="pv-small ph-12 line-height-100 "
                    containerStyle={{ width: 129, color: 'black' }}
                    onChange={(value) => roundTime(value, 'end')}
                  />,
                )}
              </div>
            </div>
            {isDateError && <Text color="red">Finish time must be after start time</Text>}
          </div>
          <ActionModalFooter>
            <SecondaryButton size="large" onClick={onCloseModal} className="mr-medium">
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={goToNext} disabled={isDateError}>
              Next
            </PrimaryButton>
          </ActionModalFooter>
        </>
      )}

      {step === CompleteBookingStep.TRAVEL_CLAIMS && (
        <>
          <div className="mv-medium">
            <Paragraph size="large">
              Please specify if there are any travel claims to be made with this booking. You can input these values at
              a later time if you do not have this information yet.
            </Paragraph>
          </div>
          {selectedBookingItem.serviceClaimConfig.isChargeNdisTransportBeforeBooking &&
            selectedBookingItem.paymentSourceType === 'NDIS' && (
              <div className="mt-large bordered border-standard-gray bg-quaternary ph-large pv-medium">
                <Checkbox checked={bookingInfo.isTravelBeforeBooking} onChange={handleTravelBeforeBooking}>
                  <Text color="secondary" weight="bold" size="small" className="text-uppercase letter-spacing-wide">
                    Claims for travel on the way to booking
                  </Text>
                </Checkbox>
                {bookingInfo.isTravelBeforeBooking && (
                  <>
                    <Row className="mt-small">
                      <Col span={8}>
                        <Text color="secondary" size="small">
                          DISTANCE TRAVELLED
                        </Text>
                      </Col>
                      <Col span={8}>
                        <Text color="secondary" size="small">
                          TRAVEL TIME
                        </Text>
                      </Col>
                      <Col span={8}>
                        <Text color="secondary" size="small">
                          ADDITIONAL EXPENSES
                        </Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Text color="secondary">
                          <Form.Item className="width-full">
                            {getFieldDecorator('travelDistanceBeforeBooking', {
                              initialValue: bookingInfo.travelDistanceBeforeBooking,
                              rules: [{ required: true, message: 'Please enter a distance.' }],
                            })(
                              <NumberInput
                                size="large"
                                min={0}
                                max={10000}
                                className="mr-small"
                                addonAfter="km"
                                style={{ width: '150px' }}
                              />,
                            )}
                          </Form.Item>
                        </Text>
                      </Col>
                      <Col span={8}>
                        <Text color="secondary">
                          <Form.Item className="width-full">
                            {getFieldDecorator('travelTimeBeforeBooking', {
                              initialValue: bookingInfo.travelTimeBeforeBooking,
                              rules: [{ required: true, message: 'Please enter a travel time.' }],
                            })(
                              <NumberInput
                                size="large"
                                min={0}
                                max={10000}
                                precision={0}
                                className="mr-small"
                                addonAfter="minutes"
                                style={{ width: '150px' }}
                              />,
                            )}
                          </Form.Item>
                        </Text>
                      </Col>
                      <Col span={8}>
                        <Text color="secondary">
                          <Form.Item className="width-full">
                            {getFieldDecorator('additionalCostBeforeBooking', {
                              initialValue: bookingInfo.additionalCostBeforeBooking,
                            })(
                              <NumberInput
                                size="large"
                                min={0}
                                max={10000}
                                className="mr-small"
                                addonBefore="$"
                                style={{ width: '150px' }}
                              />,
                            )}
                          </Form.Item>
                        </Text>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            )}
          {((selectedBookingItem.serviceClaimConfig.isChargeNdisTransportDuringBooking &&
            selectedBookingItem.paymentSourceType === 'NDIS') ||
            (selectedBookingItem.serviceClaimConfig.isChargeVcpTransportDuringBooking &&
              selectedBookingItem.paymentSourceType === 'VCP')) && (
            <div className="mt-large bordered border-standard-gray bg-quaternary ph-large pv-medium">
              <Checkbox checked={bookingInfo.isTravelDuringBooking} onChange={handleTravelDuringBooking}>
                <Text color="secondary" weight="bold" size="small" className="text-uppercase letter-spacing-wide">
                  Claims for travels during the booking
                </Text>
              </Checkbox>
              {bookingInfo.isTravelDuringBooking && (
                <>
                  <Row className="mt-small">
                    <Col span={8}>
                      <Text color="secondary" size="small">
                        DISTANCE TRAVELLED
                      </Text>
                    </Col>
                    <Col span={8} />
                    <Col span={8}>
                      <Text color="secondary" size="small">
                        ADDITIONAL EXPENSES
                      </Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Text color="secondary">
                        <Form.Item className="width-full">
                          {getFieldDecorator('travelDistanceDuringBooking', {
                            initialValue: bookingInfo.travelDistanceDuringBooking,
                            rules: [{ required: true, message: 'Please enter a distance.' }],
                          })(
                            <NumberInput
                              size="large"
                              min={0}
                              max={10000}
                              className="mr-small"
                              addonAfter="km"
                              style={{ width: '150px' }}
                            />,
                          )}
                        </Form.Item>
                      </Text>
                    </Col>
                    <Col span={8} />
                    <Col span={8}>
                      <Text color="secondary">
                        <Form.Item className="width-full">
                          {getFieldDecorator('additionalCostDuringBooking', {
                            initialValue: bookingInfo.additionalCostDuringBooking,
                          })(
                            <NumberInput
                              size="large"
                              min={0}
                              max={10000}
                              className="mr-small"
                              addonBefore="$"
                              style={{ width: '150px' }}
                            />,
                          )}
                        </Form.Item>
                      </Text>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )}
          <ActionModalFooter>
            <GhostButton size="large" onClick={goToPrevious} className="mr-medium">
              Back
            </GhostButton>
            <PrimaryButton size="large" onClick={goToNext}>
              Next
            </PrimaryButton>
          </ActionModalFooter>
        </>
      )}
      {step === CompleteBookingStep.CASE_NOTE && (
        <>
          <div className="mv-medium">
            <Paragraph size="large">Please describe the activities engaged in this booking.</Paragraph>
          </div>
          <div className="align-center mb-x-large flex-row justify-center">
            <Form.Item className="width-full">
              {getFieldDecorator('note', { rules: [{ validator: validateCaseNote }] })(
                <TextArea placeholder={'Enter your case note here.'} autoSize={{ maxRows: 8, minRows: 5 }} />,
              )}
            </Form.Item>
          </div>
          <ActionModalFooter>
            <GhostButton size="large" onClick={goToPrevious} className="mr-medium">
              Back
            </GhostButton>
            <PrimaryButton size="large" onClick={onSubmit} loading={isLoading}>
              Complete booking
            </PrimaryButton>
          </ActionModalFooter>
        </>
      )}
    </ActionModal>
  );
}

export default Form.create<IBookingCompleteActionModalProps>()(BookingCompleteActionModal);
