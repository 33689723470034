import React from 'react';
import { Button, rem } from '@good/ui/core';

type MenuButtonProps = {
  children: React.ReactNode;
} & React.ComponentPropsWithoutRef<typeof Button<'button'>>;

export const MenuButton = ({ children, ...buttonProps }: MenuButtonProps) => {
  return (
    <Button color='neutral' size='xs' h={rem(24)} w={rem(24)} p={0} {...buttonProps}>
      {children}
    </Button>
  );
};
