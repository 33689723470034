import _ from 'lodash';
import qs from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import SpinningLoadingActionModel from '../../common-components/loading/SpinningLoadingActionModel';

class PdfPreviewView extends Component<any, any> {
  state = {
    pdfData: null,
    onLoadSuccess: false,
    page: 1,
  };

  async componentDidMount() {
    const {
      doFetchCustomerServiceAgreementPDF,
      doFetchPreviewInvoicePDF,
      doFetchViewInvoicePDF,
      doFetchPreviewInvoiceByLineItemPDF,
      location,
      doFetchPreviewCreditNoteByLineItemPDF,
      doFetchPreviewBrandInvoicePdf,
      doFetchPreviewBrandInvoiceUploadedPdf,
      doFetchBookingFormPdf,
      doFetchPreviewStripeInvoicePdf,
    } = this.props;

    const query = qs.parse(location.search);
    let data;

    switch (query.type) {
      case 'service-agreement':
        data = await doFetchCustomerServiceAgreementPDF({
          userServiceAgreementId: query.agreement,
          historyId: query.historyId ? query.historyId : null,
        });
        break;
      case 'preview-invoice':
        data = await doFetchPreviewInvoicePDF({ invoiceNumber: query.invoicenumber });
        break;
      case 'preview-brand-invoice-uploaded':
        data = await doFetchPreviewBrandInvoiceUploadedPdf();
        break;
      case 'view-invoice':
        data = await doFetchViewInvoicePDF({ invoiceId: query.invoiceId });
        break;
      case 'preview-invoice-by-line-items': {
        const localStorageTag = `previewInvoiceLineItems-${query.time}`;
        const localStorageNoteTag = `previewInvoiceNotes-${query.time}`;
        data = await doFetchPreviewInvoiceByLineItemPDF({
          billingLineItemIds: _.split(localStorage.getItem(localStorageTag), ','),
          note: localStorage.getItem(localStorageNoteTag),
        });
        localStorage.removeItem(localStorageTag);
        localStorage.removeItem(localStorageNoteTag);
        break;
      }
      case 'preview-credit-note-by-line-items': {
        const localStorageCreditNote = `previewCreditNoteLineItems-${query.time}`;
        data = await doFetchPreviewCreditNoteByLineItemPDF(JSON.parse(localStorage.getItem(localStorageCreditNote)));
        localStorage.removeItem(localStorageCreditNote);
        break;
      }
      case 'view-form':
        data = await doFetchBookingFormPdf({
          attendanceFormId: query.attendanceFormId,
          serviceDateTimeFormId: query.serviceDateTimeFormId,
          userFormId: query.userFormId,
          workflowFormId: query.workflowFormId,
          workflowId: query.workflowId,
        });
        break;
      case 'preview-stripe-invoice':
        data = await doFetchPreviewStripeInvoicePdf(query?.invoiceId);
        break;
      case 'preview-brand-invoice': {
        const fetchUrlInvoice = await fetch(query?.urlInvoice as string);
        const formData = new FormData();
        formData.append('file', new File([await fetchUrlInvoice.blob()], `${new Date()}`));
        data = await doFetchPreviewBrandInvoicePdf(formData);
        break;
      }
    }

    data = 'data:application/pdf;base64,' + data;

    this.setState({ pdfData: data, onLoadSuccess: true });
  }

  render() {
    if (this.state.pdfData) {
      return (
        <div style={{ width: '100%', height: '100vh', minHeight: '100%' }}>
          <iframe src={this.state.pdfData} style={{ width: '100%', height: '100%' }} width={'100%'} height={'100%'} />
        </div>
      );
    }
    return (
      <div>
        <SpinningLoadingActionModel isOpen={true} />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCustomerServiceAgreementPDF: dispatch.customersStore.doFetchCustomerServiceAgreementPDF,
  doFetchPreviewInvoicePDF: dispatch.billingsStore.doFetchPreviewInvoicePDF,
  doFetchViewInvoicePDF: dispatch.billingsStore.doFetchViewInvoicePDF,
  doFetchPreviewInvoiceByLineItemPDF: dispatch.billingsStore.doFetchPreviewInvoiceByLineItemPDF,
  doFetchPreviewCreditNoteByLineItemPDF: dispatch.billingsStore.doFetchPreviewCreditNoteByLineItemPDF,
  doFetchPreviewBrandInvoiceUploadedPdf: dispatch.accountStore.doFetchPreviewBrandInvoiceUploadedPdf,
  doFetchPreviewBrandInvoicePdf: dispatch.accountStore.doFetchPreviewBrandInvoicePdf,
  doFetchBookingFormPdf: dispatch.accountStore.doFetchBookingFormPdf,
  doFetchPreviewStripeInvoicePdf: dispatch.subscriptionStore.doFetchPreviewStripeInvoicePdf,
});

export default connect(mapState, mapDispatch)(PdfPreviewView);
