import React from 'react';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import { useInlineForm } from './inline-form-context';
import { StaticField } from './static-field';
import { InlineFieldInputWrapper } from './inline-field-input-wrapper';
import { OptionalLabel } from './optional-label';
import { RichTextEditor } from '../rich-text-editor';

export type InlineRichTextEditorFieldProps<T extends FieldValues> = {
  description?: string;
  disabled?: boolean;
  emptyValue?: string;
  label?: string;
  maxInputWidth?: React.CSSProperties['maxWidth'];
  name: Path<T>;
  placeholder?: string;
  showOptional?: boolean;
};

export const InlineRichTextEditorField = <T extends FieldValues>(props: InlineRichTextEditorFieldProps<T>) => {
  const form = useFormContext<T>();
  const { isEditing, isSubmitting } = useInlineForm();
  const { description, disabled, emptyValue, label, maxInputWidth, name, placeholder, showOptional } = props;

  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field: { onBlur, onChange, ref, value } }) => {
        return isEditing ? (
          <InlineFieldInputWrapper
            description={description}
            label={label ? <OptionalLabel label={label} optional={showOptional} /> : undefined}
            maxInputWidth={maxInputWidth}
            name={name}
            render={(labelledBy) => {
              return (
                <RichTextEditor
                  aria-labelledby={labelledBy}
                  disabled={isSubmitting || disabled}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={placeholder}
                  ref={ref}
                  value={value}
                />
              );
            }}
          />
        ) : (
          <StaticField label={label} value={value} emptyValue={emptyValue} html />
        );
      }}
    />
  );
};
