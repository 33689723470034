import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Col, Icon, Input, Row, Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import { GhostButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import AddDocumentModal from 'common-components/documents/AddDocumentModal';
import ArchiveDocumentModal from 'common-components/documents/ArchiveDocumentModal';
import DeleteDocumentModal from 'common-components/documents/DeleteDocumentModal';
import EditDocumentModal from 'common-components/documents/EditDocumentModal';
import UnArchiveDocumentModal from 'common-components/documents/UnArchiveDocumentModal';
import { InfiniteScroll } from 'components';
import AddEditNoteModal from 'common-components/notes/AddEditNoteModal';
import { SubTitle, Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { BookingType, DocumentExpiryStatus, NoteVisibleType, BookingStatus } from 'utilities/enum-utils';
import Utils from 'utilities/Utils';
import DocumentCardItem from 'common-components/documents/DocumentCardItem';
import { ICustomerDocument } from 'interfaces/customer-interfaces';
import { Heading, IllustratedMessage } from '@good/components';
import { Letter } from '@good/illustrations';
import { downloadFileFromUrl } from 'utilities/file-utils';

const { Search } = Input;

type IDocumentsPanelProps = {
  selectedItemId: string;
  itemType: string;
  itemDocuments: ICustomerDocument[];
  doFetchDocuments?: (payload) => Promise<void>;
  doFetchMoreDocuments?: (payload) => Promise<void>;
  setDocuments: (payload) => void;
  hasEditPermission: boolean;
  isExpiryDisplayed?: boolean;
  isDateAddedDisplayed?: boolean;
  isSearchable?: boolean;
  useAttachmentText?: boolean;
  companyDataLite: typeof state.companyStore.companyDataLite;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  selectedBookingItem?: typeof state.bookingsStore.selectedBookingItem;
};

type IDocumentsPanelState = {
  isOpen: boolean;
  isLoading: boolean;
  isSearching: boolean;
  isOpenAddDocumentModal: boolean;
  isOpenEditDocumentModal: boolean;
  isOpenDeleteDocumentModal: boolean;
  isOpenArchiveDocumentModal: boolean;
  isOpenUnArchiveDocumentModal: boolean;
  isAddEditNoteModalOpen: boolean;
  selectedDocument: any;
  currentPage: number;
  pageSize: number;
  startDate: Date;
  searchString: string;
};

const DocumentEmptyPanel = () => (
  <div className='align-center flex-column mt-12 flex-1 bg-white'>
    <IllustratedMessage>
      <Letter />
      <Heading>No documents found</Heading>
    </IllustratedMessage>
  </div>
);

class DocumentsPanel extends Component<IDocumentsPanelProps, IDocumentsPanelState> {
  state = {
    isOpen: false,
    isLoading: true,
    isSearching: false,
    isOpenAddDocumentModal: false,
    isOpenEditDocumentModal: false,
    isOpenDeleteDocumentModal: false,
    isOpenArchiveDocumentModal: false,
    isOpenUnArchiveDocumentModal: false,
    isAddEditNoteModalOpen: false,
    selectedDocument: null,
    currentPage: 1,
    pageSize: 10,
    startDate: new Date(),
    searchString: null,
  };

  private _closeAddDocumentModal = () => {
    this.setState({ isOpenAddDocumentModal: false });
  };

  private _openAddDocumentModal = () => {
    this.setState({ isOpenAddDocumentModal: true });
  };

  private _closeDeleteDocumentModal = () => {
    this.setState({ isOpenDeleteDocumentModal: false });
  };

  private _deleteDocument = (document) => {
    this.setState({ selectedDocument: document, isOpenDeleteDocumentModal: true });
  };

  private _closeArchiveDocumentModal = () => {
    this.setState({ isOpenArchiveDocumentModal: false });
  };

  private _archiveDocument = (document) => {
    this.setState({ selectedDocument: document, isOpenArchiveDocumentModal: true });
  };

  private _closeUnArchiveDocumentModal = () => {
    this.setState({ isOpenUnArchiveDocumentModal: false });
  };

  private _openUnArchiveDocumentModal = (document) => {
    this.setState({ selectedDocument: document, isOpenUnArchiveDocumentModal: true });
  };

  private _closeEditDocumentModal = () => {
    this.setState({ isOpenEditDocumentModal: false });
  };

  private _editDocument = (document) => {
    if (document.noteId) {
      this.setState({ selectedDocument: document, isAddEditNoteModalOpen: true, isOpenEditDocumentModal: false });
    } else {
      this.setState({ selectedDocument: document, isOpenEditDocumentModal: true, isAddEditNoteModalOpen: false });
    }
  };

  private _closeAddEditNoteModal = () => {
    this.setState({ isAddEditNoteModalOpen: false });
  };

  private _addEditNoteModal = (document) => {
    this.setState({ isAddEditNoteModalOpen: true });
  };

  private _fetchMoreDocuments = () => {
    const { doFetchMoreDocuments, selectedItemId } = this.props;
    if (doFetchMoreDocuments) {
      this.setState({ currentPage: this.state.currentPage + 1 }, async () => {
        await doFetchMoreDocuments({
          userId: selectedItemId,
          searchString: this.state.searchString,
          currentPage: this.state.currentPage,
          pageSize: this.state.pageSize,
          startDate: this.state.startDate,
        });
      });
    }
  };

  private _searchText = async (txt) => {
    const { selectedItemId, doFetchDocuments } = this.props;
    const startDate = new Date();
    if (doFetchDocuments) {
      await doFetchDocuments({
        userId: selectedItemId,
        searchString: txt,
        currentPage: 1,
        startDate,
        pageSize: this.state.pageSize,
      });
    }
    this.setState({ isSearching: false, currentPage: 1, startDate });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    this._debounceSearch(e.target.value);
  };

  private _getPopoverContent = (document) => {
    const { itemType } = this.props;
    return (
      <Menu>
        <MenuDivider title='ACTIONS' className='text-color-secondary bordered-top pt-large' />

        {!document.isArchived && (
          <MenuItem
            label=''
            className='hover-bg-blue-lightest mv-medium'
            text='Edit'
            onClick={() => this._editDocument(document)}
          />
        )}
        {!document.isArchived && itemType !== 'booking' && itemType !== 'activity_record' && (
          <MenuItem
            label=''
            className='hover-bg-blue-lightest text-color-red-dark mv-medium'
            text='Archive'
            onClick={() => this._archiveDocument(document)}
          />
        )}
        <MenuItem
          label=''
          className='hover-bg-blue-lightest text-color-red-dark mv-medium'
          text='Delete'
          onClick={() => this._deleteDocument(document)}
        />
      </Menu>
    );
  };

  private _resetDocumentList = () => {
    const { selectedItemId, doFetchDocuments } = this.props;
    if (doFetchDocuments) {
      this.setState({ isLoading: true, currentPage: 1, startDate: new Date() }, async () => {
        await doFetchDocuments({
          userId: selectedItemId,
          searchString: null,
          currentPage: this.state.currentPage,
          pageSize: this.state.pageSize,
          startDate: this.state.startDate,
        });
        this.setState({ isLoading: false });
      });
    }
  };

  private _getAddAttachmentBlock(hasEditPermission, useAttachmentText) {
    return (
      <Col className='align-center'>
        <div className='align-center flex-row-reverse'>
          {hasEditPermission && (
            <PrimaryButton icon='file-add' onClick={this._openAddDocumentModal}>
              Add {useAttachmentText ? 'attachment' : 'document'}
            </PrimaryButton>
          )}
        </div>
      </Col>
    );
  }

  private _getItemName = (itemType) => {
    return itemType === 'customer'
      ? 'customer'
      : itemType === 'booking'
      ? 'booking'
      : itemType === 'activity_record'
      ? 'activity record'
      : 'team member';
  };

  componentDidMount = async () => {
    await this.props.doFetchCompanyLite([]);
    await this._resetDocumentList();
    this.setState({ isLoading: false });
  };

  render() {
    const {
      selectedItemId,
      itemDocuments,
      hasEditPermission,
      companyDataLite,
      itemType,
      isExpiryDisplayed = true,
      isDateAddedDisplayed = false,
      isSearchable = true,
      useAttachmentText = false,
      selectedBookingItem,
    } = this.props;

    const serviceProviderTimezone = companyDataLite ? companyDataLite.timezone : null;

    const defaultNotePrivacyVisible = selectedBookingItem
      ? selectedBookingItem.defaultNotePrivacyVisible
      : NoteVisibleType.PORTAL;

    if (!_.isEmpty(selectedItemId)) {
      return (
        <div>
          <AddDocumentModal
            closeAddDocumentModal={this._closeAddDocumentModal}
            selectedItemId={selectedItemId}
            isOpen={this.state.isOpenAddDocumentModal}
            itemType={itemType}
            resetDocumentList={this._resetDocumentList}
            useAttachmentText={useAttachmentText}
            isExpiryDisplayed={isExpiryDisplayed}
            defaultVisibleType={defaultNotePrivacyVisible}
            serviceType={selectedBookingItem?.serviceType}
          />
          <EditDocumentModal
            closeEditDocumentModal={this._closeEditDocumentModal}
            selectedDocument={this.state.selectedDocument}
            selectedItemId={selectedItemId}
            isOpen={this.state.isOpenEditDocumentModal}
            itemType={itemType}
            serviceProviderTimezone={serviceProviderTimezone}
            resetDocumentList={this._resetDocumentList}
            useAttachmentText={useAttachmentText}
            isExpiryDisplayed={isExpiryDisplayed}
            defaultVisibleType={defaultNotePrivacyVisible}
            serviceType={selectedBookingItem?.serviceType}
          />
          <DeleteDocumentModal
            closeDeleteDocumentModal={this._closeDeleteDocumentModal}
            selectedDocument={this.state.selectedDocument}
            selectedItemId={selectedItemId}
            isOpen={this.state.isOpenDeleteDocumentModal}
            itemType={itemType}
            resetDocumentList={this._resetDocumentList}
            useAttachmentText={useAttachmentText}
            isDocumentLinkedToNote={this.state.selectedDocument && !Utils.isEmpty(this.state.selectedDocument.noteId)}
          />
          <ArchiveDocumentModal
            closeArchiveDocumentModal={this._closeArchiveDocumentModal}
            selectedDocument={this.state.selectedDocument}
            selectedItemId={selectedItemId}
            isOpen={this.state.isOpenArchiveDocumentModal}
            itemType={itemType}
            resetDocumentList={this._resetDocumentList}
            useAttachmentText={useAttachmentText}
          />
          <UnArchiveDocumentModal
            closeUnArchiveDocumentModal={this._closeUnArchiveDocumentModal}
            selectedDocument={this.state.selectedDocument}
            selectedItemId={selectedItemId}
            isOpen={this.state.isOpenUnArchiveDocumentModal}
            itemType={itemType}
            resetDocumentList={this._resetDocumentList}
            useAttachmentText={useAttachmentText}
          />
          <AddEditNoteModal
            bookingId={this.props.selectedItemId}
            isOpen={this.state.isAddEditNoteModalOpen}
            onClose={this._closeAddEditNoteModal}
            editNote={true}
            editingNote={this.state.selectedDocument && this.state.selectedDocument.noteDetail}
            noteType={BookingType.ACTIVITY_RECORD}
            noteVisibleOptions={[]}
          />
          <Row className='mb-large' type='flex' justify='space-between' align='middle'>
            <Col>
              <Title level={2} className='m-none'>
                {useAttachmentText ? 'Attachments' : 'Documents'}
              </Title>
              <Text type='secondary'>Documents/files related to this {this._getItemName(itemType)}</Text>
            </Col>
            {!isSearchable && this._getAddAttachmentBlock(hasEditPermission, useAttachmentText)}
          </Row>
          {isSearchable && (
            <Row type='flex' justify='space-between' align='bottom' className='mb-medium'>
              <Col className='width-1/3'>
                <Search
                  placeholder='Search for a document'
                  onChange={this._onEnterSearchText}
                  loading={this.state.isSearching}
                  allowClear
                />
              </Col>
              {this._getAddAttachmentBlock(hasEditPermission, useAttachmentText)}
            </Row>
          )}
          {!_.isEmpty(itemDocuments) && (itemType === 'booking' || itemType === 'activity_record') ? (
            _.map(itemDocuments, (document) => {
              return (
                <DocumentCardItem
                  documentItem={document}
                  key={document.documentId}
                  documentType='BOOKING'
                  serviceName={this.props.selectedBookingItem.serviceName}
                  onPressEditDocument={this._editDocument}
                  bookingId={this.props.selectedItemId}
                  onPressDeleteDocument={this._deleteDocument}
                  bookingStartDateTime={this.props.selectedBookingItem.startDateTime}
                  timezone={this.props.selectedBookingItem.timezone}
                  isBookingArchived={this.props.selectedBookingItem.status === BookingStatus.ARCHIVED}
                />
              );
            })
          ) : _.isEmpty(itemDocuments) && (itemType === 'booking' || itemType === 'activity_record') ? (
            <Row>
              <DocumentEmptyPanel />
            </Row>
          ) : (
            <div className='mb-x2-large'>
              <Row className='pv-small bordered-bottom'>
                <Col span={5} className='pl-medium'>
                  <SubTitle>{useAttachmentText ? 'Attachment' : 'Document'} Name</SubTitle>
                </Col>
                {itemType === 'booking' ? (
                  <Col span={14}>
                    <SubTitle>Description</SubTitle>
                  </Col>
                ) : (
                  <Col span={isExpiryDisplayed ? 7 : 10}>
                    <SubTitle>Description</SubTitle>
                  </Col>
                )}

                {isExpiryDisplayed && (
                  <Col span={3}>
                    <SubTitle>Expiry date</SubTitle>
                  </Col>
                )}
                <Col span={3}>
                  <SubTitle>Added by</SubTitle>
                </Col>
                {itemType !== 'booking' && (
                  <Col span={4} className='text-align-right'>
                    {itemType === 'customer' ? (
                      <SubTitle>Viewable On App</SubTitle>
                    ) : (
                      <SubTitle>Compliance Document</SubTitle>
                    )}
                  </Col>
                )}

                <Col span={2} className='text-align-center'>
                  <SubTitle></SubTitle>
                </Col>
              </Row>
              {this.state.isLoading ? (
                <Row>
                  <Col>
                    <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className='anim-slide-left' />
                  </Col>
                </Row>
              ) : !_.isEmpty(itemDocuments) ? (
                <InfiniteScroll
                  hasMore={itemDocuments.length >= this.state.currentPage * this.state.pageSize}
                  loadMore={this._fetchMoreDocuments}
                  customLoader={
                    <Col>
                      <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className='anim-slide-left' />
                    </Col>
                  }
                >
                  {itemDocuments.map((document) => {
                    const expiryColor =
                      !document.isArchived && document.expiryStatus === DocumentExpiryStatus.EXPIRING_SOON
                        ? 'orange'
                        : !document.isArchived && document.expiryStatus === DocumentExpiryStatus.EXPIRED
                        ? 'red'
                        : 'black';
                    const expiryText =
                      !document.isArchived && document.expiryStatus === DocumentExpiryStatus.EXPIRING_SOON
                        ? 'Expiring soon'
                        : !document.isArchived && document.expiryStatus === DocumentExpiryStatus.EXPIRED
                        ? 'Expired'
                        : '';
                    return (
                      <Row
                        key={document.documentId}
                        className={`pv-medium ${document.isArchived && 'bg-red-lightest'} evenodd`}
                        type='flex'
                        align='middle'
                        gutter={8}
                      >
                        <Col
                          span={5}
                          className='pl-medium'
                          style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                          <span title={document.documentName}>
                            <Text
                              color={
                                document.status === 'SCANNING'
                                  ? 'secondary'
                                  : document.status === 'SCANNED'
                                  ? 'green'
                                  : 'red'
                              }
                            >
                              {document.status === 'SCANNED' ? (
                                <HyperlinkButton onClick={() => downloadFileFromUrl(document)}>
                                  <Icon type='download' className='mr-x-small text-color-blue' />
                                  {document.documentName}
                                </HyperlinkButton>
                              ) : (
                                <>
                                  <Tooltip2
                                    content={
                                      document.status === 'SCANNING'
                                        ? 'This document is being scanned.'
                                        : 'This document failed to upload.'
                                    }
                                  >
                                    <Icon
                                      type='question-circle'
                                      className={`text-size-x-large mr-x-small text-color-${
                                        document.status === 'SCANNING' ? 'blue' : 'red'
                                      }`}
                                    />
                                  </Tooltip2>
                                  {document.documentName}
                                </>
                              )}
                            </Text>
                          </span>
                        </Col>

                        {itemType === 'booking' ? (
                          <Col span={14}>
                            {document.noteId
                              ? 'Linked to note'
                              : document.status === 'FAILED'
                              ? 'Error in uploading document.'
                              : document.description
                              ? document.description
                              : '-'}
                          </Col>
                        ) : (
                          <Col span={isExpiryDisplayed ? 7 : 10}>
                            {document.noteId
                              ? 'Linked to note'
                              : document.status === 'FAILED'
                              ? 'Error in uploading document.'
                              : document.description
                              ? document.description
                              : '-'}
                          </Col>
                        )}
                        {isExpiryDisplayed && (
                          <Col span={3} className='pl-none'>
                            <Text color={expiryColor}>
                              {document.isArchived
                                ? 'Archived'
                                : document.expiryDate
                                ? moment.tz(document.expiryDate, serviceProviderTimezone).format('DD/MM/YYYY')
                                : 'No expiry'}
                              {!Utils.isEmpty(expiryText) && (
                                <>
                                  <br />
                                  {expiryText}
                                </>
                              )}
                            </Text>
                          </Col>
                        )}
                        <Col span={3} className='pl-none'>
                          {document.firstName} {document.lastName}
                        </Col>

                        {itemType !== 'booking' && (
                          <Col span={4} className='text-align-right'>
                            {this.props.itemType === 'customer' &&
                              (document.isViewableOnApp ? (
                                <Icon type='check' className='text-color-green' />
                              ) : (
                                <Icon type='close' className='text-color-red' />
                              ))}
                            {this.props.itemType === 'worker' &&
                              (document.isCompliant ? (
                                <Icon type='check' className='text-color-green' />
                              ) : (
                                <Icon type='close' className='text-color-red' />
                              ))}
                          </Col>
                        )}
                        <Col span={2} className='text-align-center'>
                          {hasEditPermission && (
                            <Popover2
                              content={this._getPopoverContent(document)}
                              popoverClassName='mb-medium'
                              position='bottom'
                              interactionKind='click'
                            >
                              <GhostButton icon='ellipsis' />
                            </Popover2>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </InfiniteScroll>
              ) : (
                // </div>
                <Row>
                  <DocumentEmptyPanel />
                </Row>
              )}
            </div>
          )}
        </div>
      );
    }
    return <></>;
  }
}

const mapState = (state: IRootState) => ({ companyDataLite: state.companyStore.companyDataLite });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
});

export default connect(mapState, mapDispatch)(DocumentsPanel);
