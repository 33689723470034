import React, { Component } from 'react';
import { Paragraph, Title } from 'common-components/typography';
import { PrimaryButton } from 'common-components/buttons';
import { IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import * as H from 'history';

interface IAccessDeniedViewProps {
  history: H.History;
}

interface IAccessDeniedViewState {}

class AccessDeniedView extends Component<IAccessDeniedViewProps, IAccessDeniedViewState> {
  state = {};

  private _goToDashboard = () => {
    this.props.history.push(`/dashboard`);
  };

  componentDidMount = async () => {};

  render() {
    return (
      <div className='ph-x4-large pv-x2-large width-full bg-white shadow-box bordered' style={{ margin: 'auto' }}>
        <Title>Access denied</Title>
        <Paragraph>Oops! Looks like you don’t have the correct permissions to access this page.</Paragraph>
        <Paragraph>Please contact your manager/administrator for further assistance.</Paragraph>
        <div className='text-align-right'>
          <PrimaryButton size='large' onClick={this._goToDashboard}>
            Go back to Dashboard
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({});

export default connect(mapState, mapDispatch)(AccessDeniedView);
