import { IRosterShift } from 'common-components/roster-control/interfaces/roster-interfaces';
import moment from 'moment';
import TextTag from 'common-components/tags/TextTag';
import { FieldLabel, Text } from 'common-components/typography';
import { Avatar } from 'antd';
import { HyperlinkButton, IconButton, PrimaryButton } from 'common-components/buttons';
import React, { useState } from 'react';
import { BookingStatus, BookingType, ServiceType } from 'utilities/enum-utils';
import * as H from 'history';
import { BookingTypeIcon } from 'common-components/tags';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { useDispatch, useSelector } from 'react-redux';
import CommonUtils from 'utilities/common-utils';
import BookingAssignWorkerModal from 'views/bookings/components/BookingAssignWorkerModal';
import PermissionUtils from 'utilities/permission-utils';
import AssignWorkerModal from 'views/group-services/session-details/team-members/modals/AssignWorkerModal';
import { Popover } from '@blueprintjs/core';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { RecommendedPanel } from 'common-components/roster-control/common/RecommendedPanel';
import { useFetchSessionCapacity } from 'stores/hooks/query-hooks/use-query-fetch-session-capacity';

function OpenSlotPopover({
  shift,
  history,
  refreshAndCloseData,
  setCanClosePopover,
}: {
  shift: IRosterShift;
  history: H.History;
  refreshAndCloseData: () => void;
  setCanClosePopover: (value) => void;
}) {
  const {
    serviceName,
    shiftStartDateTime,
    shiftEndDateTime,
    duplicateCount,
    customerFirstName,
    customerLastName,
    customerAvatarUrl,
    attachmentUrl,
    serviceId,
    serviceDateTimeId,
  } = shift;

  const [openAssignWorker, setOpenAssignWorker] = useState(false);

  const { data: sessionData, isLoading: sessionIsLoading } = useFetchSessionCapacity(
    {
      serviceId,
      serviceDateTimeId,
    },
    shift.serviceType === ServiceType.GROUP,
  );

  // State selectors
  const authStore = useSelector((state: IRootState) => state.authStore);
  const { portalUser } = authStore;

  // Actions dispatch
  const { navigationStore: navigationDispatch, bookingsStore: bookingDispatch } = useDispatch<IRootDispatch>();
  const { setSelectedSideNavMenuKeys } = navigationDispatch;
  const { doAssignWorker } = bookingDispatch;

  const customerFullName = `${customerFirstName} ${customerLastName}`;

  const mStartDate = moment(shiftStartDateTime);
  const mEndDate = moment(shiftEndDateTime);

  const dateDisplay = mStartDate.format('dddd, D MMMM YYYY');
  const timeDisplay = (
    <>
      <b>{mStartDate.format('h:mm a')}</b> - {mEndDate.format('h:mm a')}
    </>
  );

  const goToShift = () => {
    if (shift.serviceType === ServiceType.GROUP) {
      history.push(`/group-service/${shift.serviceId}/session/details/${shift.serviceDateTimeId}`, {
        selectedTab: 'TEAM-MEMBERS',
      });
    } else {
      history.push(`/bookings/details/${shift.bookingId}`, {
        from: { url: '/bookings/calendar', linkLabel: 'Back to Calendar' },
      });
    }
  };

  const goToService = (serviceId, serviceType) => {
    setSelectedSideNavMenuKeys(['/services']);
    return serviceType === ServiceType.GROUP
      ? history.push(`/group-service/details/${serviceId}`)
      : history.push(`/service/details/${serviceId}`);
  };

  const goToSession = (serviceId, serviceDateTimeId) => {
    setSelectedSideNavMenuKeys(['/services']);
    return history.push(`/group-service/${serviceId}/session/details/${serviceDateTimeId}`);
  };

  const onSubmitAssign = async ({
    selectedWorker,
    editRecurringMode,
    shiftSlotStatus,
    isRemovePendingShiftSlots,
    ignoreShiftClashShiftIds,
  }) => {
    const data = {
      bookingId: shift.bookingId,
      bookingRequestId: shift.bookingRequestId,
      supportWorkerId: selectedWorker.supportWorkerId,
      startDateTime: shift.shiftStartDateTime,
      isRecurring: shift.isRecurring,
      shiftSlotStatus,
      isRemovePendingShiftSlots,
      editRecurringMode,
      bookingType: shift.bookingType,
      doNotSetBookingItem: true,
      ignoreShiftClashShiftIds,
    };
    await doAssignWorker(data);
  };

  const openAssignWorkerModal = () => {
    setCanClosePopover(false);
    setOpenAssignWorker(true);
  };

  const closeAssignWorkerModal = (doRefreshData = false) => {
    setCanClosePopover(true);
    setOpenAssignWorker(false);
    doRefreshData && refreshAndCloseData();
  };

  const hasEditWorkerPermissions = PermissionUtils.validatePermission(
    'EditWorkerInBooking',
    portalUser.permissions.permissionRoles,
    shift.serviceDepartmentId,
    shift.serviceId,
  );

  const getActionMenuItems = () => {
    return (
      <ActionMenu>
        {hasEditWorkerPermissions &&
          ((shift.bookingStatus !== BookingStatus.ARCHIVED && shift.serviceType === ServiceType.INDIVIDUAL) ||
            shift.serviceType === ServiceType.GROUP) && (
            <ActionMenuItem onClick={openAssignWorkerModal} text={'Assign team member'} />
          )}
      </ActionMenu>
    );
  };

  return (
    <div className="p-large flex-column line-height-100 select-none" style={{ width: '360px' }}>
      {shift.attendanceAddress && shift.serviceType === ServiceType.INDIVIDUAL && (
        <BookingAssignWorkerModal
          isOpen={openAssignWorker}
          onClose={closeAssignWorkerModal}
          serviceId={shift.serviceId}
          bookingId={shift.bookingId}
          bookingRequestId={shift.bookingRequestId}
          onSubmitAssign={onSubmitAssign}
          isRecurring={shift.isRecurring}
          address={shift.attendanceAddress}
          sessions={[]}
          isEditingBooking={true}
          startDateTime={shift.shiftStartDateTime}
          endDateTime={shift.shiftEndDateTime}
          customerUserIds={[shift.bookerUserId]}
          displayTimezone={shift.timezone}
          history={history}
          bookingType={shift.bookingType}
        />
      )}
      {shift.sessionAddress && shift.serviceType === ServiceType.GROUP && (
        <AssignWorkerModal
          isOpen={openAssignWorker}
          shiftSlot={shift}
          onClose={closeAssignWorkerModal}
          session={shift}
        />
      )}

      <div className="inline-block mb-x-small line-height-100">
        {/* TODO : Change this with actual booking status tag */}
        <div className="flex-row align-center">
          <TextTag content="Open slot" size="small" theme="light" color="orange" backgroundColor="white" />

          {duplicateCount > 0 && (
            <div className="ml-small">
              <Text color="orange" size="regular">
                <b>{duplicateCount}</b> slots unassigned
              </Text>
            </div>
          )}
        </div>
      </div>
      <div className="line-height-120 mb-medium">
        <Text size="x-large" lineHeight={135} weight="bold">
          {dateDisplay}
        </Text>
        <br />
        <Text size="x-large" lineHeight={135}>
          {timeDisplay}
        </Text>
        <br />
        <Text size="x-large" lineHeight={135}>
          <Text size="x-large" color={'secondary'}>
            {CommonUtils.formatDurationString(mStartDate, mEndDate)}
          </Text>
        </Text>
        <br />
        <div className={'flex-row align-center mt-small'}>
          <BookingTypeIcon
            type={shift.bookingType === BookingType.ACTIVITY_RECORD ? BookingType.ACTIVITY_RECORD : shift.serviceType}
            className={'mr-small'}
          />
          <Text color={'tertiary'}>{CommonUtils.formatServiceTypeStatus(shift)}</Text>
        </div>
        {shift.serviceType === ServiceType.INDIVIDUAL || !shift.scheduleName ? (
          <HyperlinkButton color={'black'} onClick={() => goToService(shift.serviceId, shift.serviceType)}>
            {serviceName}
          </HyperlinkButton>
        ) : (
          <Text>
            {shift.scheduleName}{' '}
            <HyperlinkButton color={'secondary'} onClick={() => goToService(shift.serviceId, shift.serviceType)}>
              ({shift.serviceName})
            </HyperlinkButton>
          </Text>
        )}
        {shift.serviceType === ServiceType.GROUP && (
          <div>
            <HyperlinkButton onClick={() => goToSession(shift.serviceId, shift.serviceDateTimeId)}>
              Go to session
            </HyperlinkButton>
          </div>
        )}
      </div>

      {shift.serviceType === ServiceType.GROUP && (
        <div className={'mb-large'}>
          <div className="mt-x-small">
            {sessionIsLoading ? (
              <SpinningLoader size={20} message={'Fetching recommended capacity...'} />
            ) : (
              sessionData &&
              sessionData.data && (
                <RecommendedPanel
                  recommendedTotalShiftSlot={sessionData.data.recommendedTotalShiftSlot}
                  totalAssignedShiftSlot={sessionData.data.totalAssignedShiftSlot}
                />
              )
            )}
          </div>
        </div>
      )}

      {shift.bookingId && (
        <div className="mb-large">
          <FieldLabel text={'CUSTOMER'} />
          <div className="flex-row align-center mt-x-small">
            <Avatar icon="user" className="mr-small" shape="circle" src={attachmentUrl} />
            <Text color="secondary">{customerFullName}</Text>
          </div>
        </div>
      )}

      <div className="mb-large">
        <FieldLabel text={'TEAM MEMBER'} />
        <div className="flex-row align-center mt-x-small">
          <Avatar icon="user" className="mr-small" shape="square" />
          <Text color="secondary">Not assigned</Text>
        </div>
      </div>

      <div className="flex-row justify-end">
        <PrimaryButton onClick={goToShift}>View shift</PrimaryButton>
        {hasEditWorkerPermissions && (
          <Popover
            content={getActionMenuItems()}
            onOpening={() => setCanClosePopover(false)}
            onClose={() => setCanClosePopover(!openAssignWorker)}
            interactionKind={'hover'}
            position={'bottom-left'}
          >
            <IconButton
              icon="ellipsis"
              color="white"
              iconColor="blue-action"
              bordered={true}
              className="border-blue-action ml-small"
            />
          </Popover>
        )}
      </div>
    </div>
  );
}

export default OpenSlotPopover;
