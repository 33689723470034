import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ArrowLeftCurvedCircle = forwardRef(function ArrowLeftCurvedCircle(
  props: IconProps,
  ref: Ref<SVGSVGElement>,
) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 12a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0ZM12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25Zm-1.47 7.28a.75.75 0 0 0-1.06-1.06l-2 2A.75.75 0 0 0 8 10.75h5a2.25 2.25 0 1 1 0 4.5h-2a.75.75 0 0 0 0 1.5h2a3.75 3.75 0 1 0 0-7.5H9.81l.72-.72Z"
        fill="currentColor"
      />
    </Icon>
  );
});
