// Please use this component in the future
//   over the V1 version.

/* eslint-disable */
import React from 'react';
import { Label } from '@goodhuman-me/components';

interface IPaymentSourceTagProps {
  paymentSource: 'NDIS' | 'VCP';
  size: 'xsmall' | 'small' | 'medium' | 'large';
}

enum BackgroundColor {
  NDIS = 'canary',
  VCP = 'canary',
}

const PaymentSourceTagV2 = ({ paymentSource, size }: IPaymentSourceTagProps) => (
  <Label emphasis="outlined" tone={BackgroundColor[paymentSource]} size={size}>
    {paymentSource.toUpperCase()}
  </Label>
);

export default PaymentSourceTagV2;
