import { IRootState } from 'stores/rematch/root-store';
import apiClient from 'utilities/api-client';

import _ from 'lodash';

import moment from 'moment';

import { IMessage, MessageType } from 'interfaces/message-interfaces';

import { v4 as uuidv4 } from 'uuid';

/*
  State structure for channelMessages.
  Channel Messages is an object / dictionary, not an array.
  state = {
    channelMessages: {
      'channelId1': [...messages],
      'channelId2': [...messages],
      'channelId3': [...messages]
      ... rest
    }
  }
 */

const initialState = {
  channelMessages: {},
  currentMessageChannel: null, // do we really need this?
  incomingChannelId: null,
  incomingMessageId: null,
};

const reducers = {
  // Sets the entire channelMessages variable; directly replaces it
  setChannelMessages: (state, { channelMessages }) => ({ ...state, channelMessages: { ...channelMessages } }),

  // Add a new channelMessages
  addChannelMessages: (state, { channelMessages, messageChannelId }) => {
    const newChannelMessage = _.set({}, messageChannelId, channelMessages);
    return { ...state, channelMessages: { ...state.channelMessages, ...newChannelMessage } };
  },

  //Append a message to the messageChannelId.
  appendMessagesToChannel: (state, { messages, messageChannelId }) => {
    const targetMessages = _.isEmpty(state.channelMessages[messageChannelId])
      ? [...messages]
      : [...messages, ...state.channelMessages[messageChannelId]]; // TODO not sure if this order is right

    // _.set is used to insert the object. the ...filter expression is used to just replace the message object with the new one.
    return {
      ...state,
      channelMessages: _.set({ ...state.channelMessages }, messageChannelId, targetMessages),
    };
  },

  //Append a message to the messageChannelId.
  appendSingleMessageToChannel: (state, { message, messageChannelId }) => {
    const targetMessages = _.isEmpty(state.channelMessages[messageChannelId])
      ? [message]
      : [...state.channelMessages[messageChannelId], message];

    // _.set is used to insert the object. the ...filter expression is used to just replace the message object with the new one.
    return {
      ...state,
      channelMessages: _.set({ ...state.channelMessages }, messageChannelId, targetMessages),
    };
  },

  // Update/replace a target message with the target messageId.
  updateMessageInChannel: (state, { message, messageId, messageChannelId }) => {
    const targetMessages = _.isEmpty(state.channelMessages[messageChannelId])
      ? []
      : state.channelMessages[messageChannelId];

    const updatedMessages = _.map(targetMessages, (m) => (m.messageId === messageId ? message : m));

    return { ...state, channelMessages: _.set({ ...state.channelMessages }, messageChannelId, updatedMessages) };
  },

  // Temporary fix for handling incoming notifications if on same screen.
  // Would prefer if there is a global store to handle these notifications.
  setIncomingMessageNotification: (state, { messageChannelId, messageId }) => ({
    ...state,
    incomingChannelId: messageChannelId,
    incomingMessageId: messageId,
  }),
};

const messagesStore = {
  state: { ...initialState },

  reducers,

  effects: (dispatch) => ({
    doGetMessagesForChannel: async (
      payload: { messageChannelId: string; page: number; pageSize: number; timeStamp: Date; addToStore: boolean },
      state: IRootState,
    ) => {
      try {
        const { messageChannelId, page = 1, pageSize = 5, timeStamp = new Date(), addToStore = true } = payload;

        const endpoint = `/api/portal/messaging/channels/${messageChannelId}/messages/list`;
        const request = { messageChannelId, page, pageSize, timeStamp };
        const result = await apiClient.post(endpoint, request);

        if (result.status === 200) {
          const { data }: { data: IMessage[] } = result;

          // do we need to map with sent status and order...?
          const localData = _.chain(data)
            .map((m) => ({ ...m, sent: true, createdOn: moment(m.createdOn).toDate() }))
            .orderBy('createdOn', 'asc')
            .value();

          // addToStore is used to indicate whether to just fetch & reset unread messages,
          // or to fetch and replace channel messages

          if (addToStore) {
            dispatch.messagesStore.addChannelMessages({
              channelMessages: localData,
              messageChannelId,
            });
          }
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    doFetchMoreMessages: async (
      payload: { messageChannelId: string; page: number; pageSize: number; timeStamp: Date },
      state: IRootState,
    ) => {
      // TODO Finish this
      try {
        const { messageChannelId, page = 1, pageSize = 20, timeStamp = new Date() } = payload;

        const endpoint = `/api/portal/messaging/channels/${messageChannelId}/messages/list`;
        const request = { messageChannelId, page, pageSize, timeStamp };
        const result = await apiClient.post(endpoint, request);

        if (result.status === 200) {
          const { data }: { data: IMessage[] } = result;

          if (data.length > 0) {
            // do we need to map with sent status and order...?
            const localData = _.chain(data)
              .map((m) => ({ ...m, sent: true, createdOn: moment(m.createdOn).toDate() }))
              .orderBy('createdOn', 'asc')
              .value();

            dispatch.messagesStore.appendMessagesToChannel({
              messages: localData,
              messageChannelId,
            });

            return { hasMore: true };
          } else {
            // nothing more to fetch
            return { hasMore: false };
          }
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    doAddNewMessage: async (
      payload: { messageChannelId: string; text: string; authorUserId?: string; scrollToBottom: any },
      state: IRootState,
    ) => {
      try {
        const { authorUserId, messageChannelId, text, scrollToBottom } = payload;

        const endpoint = `/api/portal/messaging/channels/${messageChannelId}/messages`;

        // Insert a placeholder local message first, and update it later with a sent indicator = true once done.
        const localMessageId = uuidv4();
        let localMessage: IMessage = {
          messageType: MessageType.NewMessage,
          messageChannelId,
          content: { text, attachments: [] },
          authorUserId,
          createdOn: new Date(),
          isLocal: true,
          messageId: localMessageId,
          sent: false,
          animateIn: true,
        };

        await dispatch.messagesStore.appendSingleMessageToChannel({ message: localMessage, messageChannelId });

        const request: { content: { text: string; attachments: [] }; isAnonymous: boolean } = {
          content: {
            text,
            attachments: [],
          },
          isAnonymous: false,
        };

        // scrollToBottom({ smooth: true });

        const result = await apiClient.post(endpoint, request);

        if (result.status === 200) {
          const { data }: { data: any } = result;

          // Update the inserted message into API.
          localMessage = { ...data, messageChannelId, messageType: MessageType.NewMessage, sent: true };

          dispatch.messagesStore.updateMessageInChannel({
            message: localMessage,
            messageId: localMessageId,
            messageChannelId,
          });
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    doDeleteMessage: async (payload: { messageChannelId: string; messageId: string }, state: IRootState) => {
      try {
        const { messageChannelId, messageId } = payload;

        const endpoint = `/api/mobile/messaging/channels/${messageChannelId}/messages/${messageId}`;
        const result = await apiClient.delete(endpoint);
        if (result.status === 200) {
          const { data } = result;

          let deletedMessage: IMessage = _.chain(state.messagesStore.channelMessages[messageChannelId])
            .filter((m) => m.messageId === messageId)
            .first()
            .value();

          if (!_.isEmpty(deletedMessage)) {
            deletedMessage.messageType = MessageType.DeleteMessage;
            deletedMessage.content.text = null;
            dispatch.messagesStore.updateMessageInChannel({ message: deletedMessage, messageId, messageChannelId });
          }

          return true;
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    doSearchMessages: async (payload: any, state: IRootState) => {
      // TODO How to implement this...? Not sure yet.
      try {
        const endpoint = ``;
        const result = await apiClient.get(endpoint);
        if (result.status === 200) {
          const { data }: { data: any } = result;
          dispatch.channelsStore.setAssignedServices({ services: data });
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
  }),
};

export default messagesStore;
