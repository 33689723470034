import { Checkbox, Col, Icon, Row } from 'antd';
import { GhostButton } from 'common-components/buttons';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import { SubTitle, Text } from 'common-components/typography';
import { IActivityGroup } from 'interfaces/service-interfaces';
import moment from 'moment-timezone';
import React, { memo, useCallback, useEffect, useState } from 'react';
import CommonUtils from 'utilities/common-utils';
import { timeZone } from 'interfaces/timezone-type';

interface IActivityGroupRowItemEditDirectProps {
  item: IActivityGroup;
  scheduleStartDate: Date | string;
  scheduleEndDate: Date | string;
  onChange: (item: IActivityGroup, isValid: boolean) => void;
  onRemove: (serviceActivityGroupId: string) => void;
  isInSchedule: boolean;
  timezone?: timeZone;
}

const ActivityGroupRowItemEditDirect = ({
  item,
  scheduleEndDate,
  scheduleStartDate,
  onChange,
  onRemove,
  isInSchedule,
  timezone,
}: IActivityGroupRowItemEditDirectProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(
    moment(item.startDateTime).isSame(scheduleStartDate) && moment(item.endDateTime).isSame(scheduleEndDate),
  );
  const _roundDate = (date) => moment(CommonUtils.formatCeilingDateTime(date));

  const _handleChangeStartingDate = useCallback(
    (startingDate) => {
      const roundedValue = _roundDate(moment(startingDate));
      onChange(
        {
          ...item,
          startDateTime: roundedValue.toDate(),
        },
        moment(item.endDateTime).isBefore(moment(roundedValue)),
      );
    },
    [item, onChange],
  );

  const _handleChangeEndingDate = useCallback(
    (endingDate) => {
      const roundedValue = _roundDate(moment(endingDate));
      onChange(
        {
          ...item,
          endDateTime: roundedValue.toDate(),
        },
        moment(roundedValue).isBefore(moment(item.startDateTime)),
      );
    },
    [item, onChange],
  );

  const _handleChangeCheckbox = useCallback(
    (e) => {
      if (e.target.checked) {
        onChange(
          {
            ...item,
            startDateTime: scheduleStartDate,
            endDateTime: scheduleEndDate,
          },
          false,
        );
      }
      setIsChecked(e.target.checked);
    },
    [item, scheduleStartDate, scheduleEndDate, onChange],
  );

  const isDateWithinScheduleTime =
    moment(item.startDateTime).isSameOrAfter(scheduleStartDate) &&
    moment(item.endDateTime).isSameOrBefore(scheduleEndDate);

  const isStartDateBeforeEndDate = moment(item.endDateTime).isBefore(moment(item.startDateTime));

  useEffect(() => {
    setIsChecked(
      moment(item.startDateTime).isSame(scheduleStartDate) && moment(item.endDateTime).isSame(scheduleEndDate),
    );
  }, [item, scheduleStartDate, scheduleEndDate]);

  return (
    <>
      <div key={item.serviceActivityGroupId} className="mt-medium bg-white p-medium rounded bordered">
        <Row className="bordered-bottom mb-medium">
          <Col span={20}>
            <Text weight="bold" className="block">
              {item.name}
            </Text>
            <Text color="secondary">{item.description}</Text>
          </Col>
          <Col span={4}>
            <GhostButton color="red" onClick={() => onRemove(item.serviceActivityGroupId)}>
              Remove
            </GhostButton>
          </Col>
        </Row>
        {!isInSchedule && (
          <div className="flex-row align-center">
            <div className="mr-large mb-large">
              <SubTitle>Start Date</SubTitle>
              <div className="flex-row align-left">
                <DatePicker
                  dateFormat="EEE, dd MMM yyyy"
                  isClearable={false}
                  selected={moment.tz(item.startDateTime, timezone).toDate()}
                  onChange={_handleChangeStartingDate}
                  className="mr-small gh-datepicker rounded p-small"
                  calendarClassName="gh-datepicker-calendar"
                />
              </div>
            </div>
            <div className="mb-large">
              <SubTitle>End Date</SubTitle>
              <div className="flex-row align-left">
                <DatePicker
                  selected={moment.tz(item.endDateTime, timezone).toDate()}
                  isClearable={false}
                  dateFormat="EEE, dd MMM yyyy"
                  minDate={moment.tz(item.startDateTime, timezone).startOf('day').toDate()}
                  onChange={_handleChangeEndingDate}
                  className="mr-small gh-datepicker rounded p-small"
                  calendarClassName="gh-datepicker-calendar"
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex-row align-center">
          <div className="mr-large mb-small">
            <SubTitle>Start Time</SubTitle>
            <div className="flex-row align-left">
              <TimeInput
                size="large"
                value={moment.tz(item.startDateTime, timezone)}
                onChange={(event) => _handleChangeStartingDate(event)}
              />
            </div>
          </div>
          <div className="mb-small">
            <SubTitle>End Time</SubTitle>
            <div className="flex-row align-left">
              <TimeInput
                size="large"
                value={moment.tz(item.endDateTime, timezone)}
                onChange={(event) => _handleChangeEndingDate(event)}
              />
            </div>
          </div>
        </div>

        {isStartDateBeforeEndDate && (
          <Text color="red-dark" style={{ lineHeight: '200%' }} className="block">
            End time must be after the start time.
          </Text>
        )}

        {!isDateWithinScheduleTime && (
          <div>
            <Icon type="warning" theme="filled" className="mr-small text-color-warning-orange" />
            <Text type="warning">Activity time doesn’t fit within the session timeframe</Text>
          </div>
        )}

        <Checkbox checked={isChecked} onChange={_handleChangeCheckbox}>
          Make same as session time
        </Checkbox>
      </div>
    </>
  );
};

export default memo(ActivityGroupRowItemEditDirect);
