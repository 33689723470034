import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  CurrencyFormatter,
  NumberFormatter,
  Icons,
  Inline,
  Separator,
  Stack,
  PercentFormatter,
} from '@goodhuman-me/components';
import { Text } from 'common-components/typography';
import { ndisHelper } from 'variables/data-helpers';
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import { GhostButton } from 'common-components/buttons';
import { useTranslation } from 'react-i18next';

const UnitFormatter = ({ value, count = 1 }: { value: string; count?: number }): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'budget.billedItems' });

  const lineItemUnitKeys = {
    EA: 'each',
    H: 'hour',
    D: 'day',
    WK: 'week',
    MON: 'month',
    YR: 'year',
  };
  const unitKey = lineItemUnitKeys[value] ?? lineItemUnitKeys.EA;

  let unit = t(`lineItemUnits.${unitKey}`, { count });

  return <span>{unit}</span>;
};

interface AccordionButtonProps {
  label: string;
  isAccordionOpen: boolean;
  onClick: () => void;
}

const AccordionButton = ({ isAccordionOpen, label, onClick }: AccordionButtonProps): JSX.Element => {
  return (
    <div>
      <Button kind="accent" emphasis="quiet" size="small" onClick={onClick}>
        {isAccordionOpen ? <Icons.ChevronBottom /> : <Icons.ChevronRight />}
        {label}
      </Button>
    </div>
  );
};

const SortableTable = <T,>({
  columns: columnProps,
  data,
  emptyState,
}: {
  columns: ColumnDef<T>[];
  data: T[];
  emptyState?: JSX.Element;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const columns = useMemo<ColumnDef<T>[]>(() => columnProps, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  return (
    <table>
      <Inline asChild backgroundColor="$bodyLight3" borderRadius="$small" paddingX="$xsmall" flex="1">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Inline asChild key={headerGroup.id} width="stretch">
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Box key={header.id} asChild padding="$space75" flex="1">
                      <th colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <GhostButton paddingSize="none" bgNone onClick={header.column.getToggleSortingHandler()}>
                            <Text size="small" weight="bold" className="select-none">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </Text>
                            <Text size="small" className="ml-small">
                              {{
                                asc: <Icons.ChevronBottom width="1em" />,
                                desc: <Icons.ChevronTop width="1em" />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </Text>
                          </GhostButton>
                        )}
                      </th>
                    </Box>
                  );
                })}
              </tr>
            </Inline>
          ))}
        </thead>
      </Inline>
      <Stack asChild paddingX="$xsmall">
        <tbody>
          {table.getRowModel().rows.map((row, index) => {
            const isLastItem = index + 1 === table.getRowModel().rows.length;
            return (
              <>
                <Inline key={row.id} asChild borderRadius="$small">
                  <tr>
                    {row.getVisibleCells().map((cell) => (
                      <Box asChild flex="1" key={cell.id} padding="$space75">
                        <td>
                          <Text size="small">{flexRender(cell.column.columnDef.cell, cell.getContext())}</Text>
                        </td>
                      </Box>
                    ))}
                  </tr>
                </Inline>
                {!isLastItem && <Separator thickness="thin" />}
              </>
            );
          })}

          {table.getRowModel().rows.length === 0 && emptyState !== null && (
            <Inline asChild>
              <tr className="flex justify-center text-align-center">
                <Box asChild flex="1" padding="$space125">
                  <td>{emptyState}</td>
                </Box>
              </tr>
            </Inline>
          )}
        </tbody>
      </Stack>
    </table>
  );
};

interface TableCellProps<Trow, Tcell> {
  row: {
    original: Trow;
  };
  getValue: () => Tcell;
}

const BilledItemsTable = ({ billedItems }: { billedItems: IBilledItemRow[] }) => {
  const { t } = useTranslation('', { keyPrefix: 'budget.billedItems' });

  const columns: ColumnDef<IBilledItemRow>[] = [
    {
      header: 'Unit price',
      accessorKey: 'unitPrice',
      cell: (info: TableCellProps<IBilledItemRow, string>) => {
        const lineItem = ndisHelper.getBySupportItemNumber(info.row.original.supportItemNumber);

        return (
          <>
            <CurrencyFormatter value={parseFloat(info.getValue())} /> per{' '}
            <UnitFormatter value={lineItem.UnitOfMeasure} />
          </>
        );
      },
    },
    {
      header: 'Quantity',
      accessorKey: 'quantity',
      cell: (info: TableCellProps<IBilledItemRow, number>) => {
        const lineItem = ndisHelper.getBySupportItemNumber(info.row.original.supportItemNumber);
        const value = info.getValue();

        return (
          <>
            <NumberFormatter value={value} /> <UnitFormatter value={lineItem.UnitOfMeasure} count={value} />
          </>
        );
      },
    },
    {
      header: 'Total',
      accessorKey: 'total',
      cell: (info: TableCellProps<IBilledItemRow, number>) => <CurrencyFormatter value={info.getValue()} />,
    },
    {
      header: '% of the line',
      accessorKey: 'linePercent',
      cell: (info: TableCellProps<IBilledItemRow, number>) => <PercentFormatter value={info.getValue()} />,
    },
    {
      header: 'Instance',
      accessorKey: 'instances',
      cell: (info: TableCellProps<IBilledItemRow, number>) => <>{info.getValue()}</>,
    },
  ];

  return (
    <SortableTable
      data={billedItems}
      columns={columns}
      emptyState={
        <>
          <Text color="$muted" weight="bold">
            {t('noBilledItems.title')}
          </Text>
          <br />
          <Text color="$muted" size="small">
            {t('noBilledItems.instructions')}
          </Text>
        </>
      }
    />
  );
};

export interface IBilledItemRow {
  instances: number;
  quantity: number;
  supportItemNumber: string;
  total: number;
  unitPrice: string;
  linePercent: number;
}

export interface BilledItemsAccordionProps {
  billedItems: IBilledItemRow[];
}

export const BilledItemsAccordion = ({ billedItems }: BilledItemsAccordionProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => setIsOpen(!isOpen);

  const itemsCount = billedItems.length;
  const shouldPluralise = itemsCount !== 1;
  const accordionButtonLabel = `${isOpen ? 'Hide' : 'Show'} ${itemsCount} line item${shouldPluralise ? 's' : ''}`;

  return (
    <Stack gap="$xsmall" width="100%" paddingY="$small">
      <AccordionButton label={accordionButtonLabel} isAccordionOpen={isOpen} onClick={toggleAccordion} />
      {isOpen && <BilledItemsTable billedItems={billedItems} />}
    </Stack>
  );
};
