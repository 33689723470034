import { v4 as uuid } from 'uuid';
import {
  AFFECT_TO_ELEMENT_PROPERTY,
  CAPTION_TEXT_PROPERTY,
  EVENT_PROPERTY,
  FIELD_TITLE_PROPERTY,
  PLACEHOLDER_PROPERTY,
  REPEATABLE_PROPERTY,
  REQUIRED_PROPERTY,
  RULES_PROPERTY,
  TARGET_ELEMENT_PROPERTY,
  TARGET_OPTION_PROPERTY,
} from '../common-field-constants';
import { LabelMultipleElements } from '../common-label-constants';
import { FormElementType, IntakeFormElementType, PropKey, PropType, UserActionType } from '../form-enum';
import { IFormElement } from '../form-interface';

//#region Legal guardian details
const LEGAL_GUARDIAN_DETAILS: IFormElement = {
  id: uuid(),
  name: 'Legal guardian details',
  type: FormElementType.MULTIPLE_ELEMENT,
  label: LabelMultipleElements,
  isHideConfig: true,
  properties: { general: [REQUIRED_PROPERTY], appearance: [], configuration: [] },
  children: [
    {
      id: uuid(),
      name: 'Legal guardian details',
      type: FormElementType.HEADER,
      properties: {
        general: [],
        appearance: [
          {
            key: PropKey.HEADING_TEXT,
            name: 'HEADING TEXT',
            value: 'Legal guardian details',
            rules: [
              { required: true, message: 'Please enter headline' },
              {
                max: 200,
                message: 'Max length of characters is 200',
              },
            ],
            placeholder: 'Heading Text',
            caption: 'Enter heading text for this field.',
          },
          {
            key: PropKey.SUBHEADING_TEXT,
            name: 'SUBHEADING TEXT (OPTIONAL)',
            value: '',
            rules: [
              {
                max: 1000,
                message: 'Max length of characters is 1000',
              },
            ],
            placeholder: 'Subheading Text',
            caption: 'Accompanying subheading text for this header.',
          },
        ],
        configuration: [],
      },
    },
    {
      id: uuid(),
      name: 'First & Last name',
      type: FormElementType.FIRST_LAST_NAME,
      properties: {
        general: [
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'First name',
            key: 'fieldTitleFirstName',
            fieldType: IntakeFormElementType.CONTACTS_FIRST_NAME,
          },
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'Last name',
            key: 'fieldTitleLastName',
            fieldType: IntakeFormElementType.CONTACTS_LAST_NAME,
          },
          REQUIRED_PROPERTY,
          { ...RULES_PROPERTY, rules: [{ max: 50, message: 'Max length of characters is 50' }] },
        ],
        appearance: [
          { ...PLACEHOLDER_PROPERTY, value: 'Input name...', key: 'placeholderTextFirstName' },
          { ...PLACEHOLDER_PROPERTY, value: 'Input name...', key: 'placeholderTextLastName' },
          CAPTION_TEXT_PROPERTY,
        ],
        configuration: [],
      },
    },
    {
      id: uuid(),
      name: 'Email',
      type: FormElementType.SHORT_TEXT,
      properties: {
        general: [
          { ...FIELD_TITLE_PROPERTY, value: 'Email', fieldType: IntakeFormElementType.CONTACTS_EMAIL },
          REQUIRED_PROPERTY,
          { ...RULES_PROPERTY, rules: [{ type: 'email', message: 'Please enter a valid email' }] },
        ],
        appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter email here' }, CAPTION_TEXT_PROPERTY],
        configuration: [],
      },
    },
    {
      id: uuid(),
      name: 'Relationship',
      type: FormElementType.DROPDOWN,
      isHideConfig: true,
      properties: {
        general: [
          {
            ...FIELD_TITLE_PROPERTY,
            value: 'Relationship',
            fieldType: IntakeFormElementType.CONTACTS_LEGAL_GUARDIAN_RELATIONSHIP,
          },
          REQUIRED_PROPERTY,
        ],
        appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Select relationship' }],
        configuration: [
          {
            key: PropKey.OPTIONS,
            name: 'OPTIONS',
            value: [],
            caption: 'Option that will be shown for this input field.',
            type: PropType.options,
          },
        ],
      },
    },
  ],
};
//#endregion

//#region Add another contact
const ADD_ANOTHER_CONTACT_ELEMENT_ID = uuid();
const ADDITIONAL_CONTACT_ELEMENT_ID = uuid();

const RADIO_ADD_ANOTHER_CONTACT: IFormElement = {
  id: ADD_ANOTHER_CONTACT_ELEMENT_ID,
  parentId: ADDITIONAL_CONTACT_ELEMENT_ID,
  name: 'Would you like to add another contact?',
  type: FormElementType.SINGLE_CHOICE,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Would you like to add another contact?',
        fieldType: IntakeFormElementType.CONTACTS_CHECK_ADD_OTHER_CONTACT,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: PropKey.OPTIONS,
        name: 'OPTIONS',
        value: ['Yes', 'No'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const ADDITIONAL_CONTACT_DETAIL: IFormElement[] = [
  {
    id: uuid(),
    parentId: ADDITIONAL_CONTACT_ELEMENT_ID,
    name: 'Additional Contacts details',
    type: FormElementType.HEADER,
    properties: {
      general: [],
      appearance: [
        {
          key: PropKey.HEADING_TEXT,
          name: 'HEADING TEXT',
          value: 'Additional Contacts details',
          rules: [
            { required: true, message: 'Please enter headline' },
            {
              max: 200,
              message: 'Max length of characters is 200',
            },
          ],
          placeholder: 'Heading Text',
          caption: 'Enter heading text for this field.',
        },
        {
          key: PropKey.SUBHEADING_TEXT,
          name: 'SUBHEADING TEXT (OPTIONAL)',
          value: '',
          rules: [
            {
              max: 1000,
              message: 'Max length of characters is 1000',
            },
          ],
          placeholder: 'Subheading Text',
          caption: 'Accompanying subheading text for this header.',
        },
      ],
      configuration: [],
    },
  },
  {
    id: uuid(),
    parentId: ADDITIONAL_CONTACT_ELEMENT_ID,
    name: 'Full name',
    type: FormElementType.SHORT_TEXT,
    properties: {
      general: [
        { ...FIELD_TITLE_PROPERTY, value: 'Full name', fieldType: IntakeFormElementType.CONTACTS_FULL_NAME },
        REQUIRED_PROPERTY,
        { ...RULES_PROPERTY, rules: [{ max: 50, message: 'Max length of characters is 50' }] },
      ],
      appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter full name here' }, CAPTION_TEXT_PROPERTY],
      configuration: [],
    },
  },
  {
    id: uuid(),
    parentId: ADDITIONAL_CONTACT_ELEMENT_ID,
    name: 'Phone number',
    type: FormElementType.PHONE_NUMBER,
    properties: {
      general: [
        { ...FIELD_TITLE_PROPERTY, value: 'Phone number', fieldType: IntakeFormElementType.CONTACTS_PHONE_NUMBER },
        REQUIRED_PROPERTY,
      ],
      appearance: [CAPTION_TEXT_PROPERTY],
      configuration: [],
    },
  },
  {
    id: uuid(),
    parentId: ADDITIONAL_CONTACT_ELEMENT_ID,
    name: 'Email address',
    type: FormElementType.SHORT_TEXT,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Email address',
          fieldType: IntakeFormElementType.CONTACTS_EMAIL_ADDRESS,
        },
        REQUIRED_PROPERTY,
        {
          ...RULES_PROPERTY,
          rules: [
            {
              type: 'email',
              message: 'Please enter a valid email',
            },
          ],
        },
      ],
      appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter email here' }, CAPTION_TEXT_PROPERTY],
      configuration: [],
    },
  },
  {
    id: uuid(),
    parentId: ADDITIONAL_CONTACT_ELEMENT_ID,
    name: 'Relationship',
    type: FormElementType.DROPDOWN,
    isHideConfig: true,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Relationship',
          fieldType: IntakeFormElementType.CONTACTS_ADDITIONAL_CONTACTS_RELATIONSHIP,
        },
        REQUIRED_PROPERTY,
      ],
      appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Select relationship' }],
      configuration: [
        {
          key: PropKey.OPTIONS,
          name: 'OPTIONS',
          value: [],
          caption: 'Option that will be shown for this input field.',
          type: PropType.options,
        },
      ],
    },
  },
  {
    id: uuid(),
    parentId: ADDITIONAL_CONTACT_ELEMENT_ID,
    name: 'Primary contact',
    type: FormElementType.CHECKBOX,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: '',
          rules: [],
          fieldType: IntakeFormElementType.CONTACTS_CHECK_PRIMARY_CONTACT,
        },
        { ...REQUIRED_PROPERTY, value: false },
      ],
      appearance: [
        {
          key: PropKey.CHECKBOX_TEXT,
          name: 'CHECKBOX TEXT',
          value: 'Is this the primary contact?',
          rules: [
            {
              max: 200,
              message: 'Max length of characters is 200',
            },
          ],
          caption: 'Text caption that is shown below the input field.',
        },
        { ...CAPTION_TEXT_PROPERTY, value: '' },
      ],
      configuration: [
        {
          key: PropKey.CHECKBOX_OPTIONS,
          name: 'DEFAULT VALUE',
          value: ['Checked', 'Unchecked'],
          defaultChecked: false,
          caption: 'The default check state for this input field.',
        },
      ],
    },
  },
  RADIO_ADD_ANOTHER_CONTACT,
];

const ADD_ANOTHER_CONTACT_BUNDLE: IFormElement = {
  id: ADDITIONAL_CONTACT_ELEMENT_ID,
  name: 'Additional Contacts details',
  type: FormElementType.MULTIPLE_ELEMENT,
  label: LabelMultipleElements,
  isHideConfig: true,
  properties: { general: [REQUIRED_PROPERTY], appearance: [], configuration: [] },
  children: [],
};

const CONDITIONAL_ADD_ANOTHER_CONTACT = {
  id: uuid(),
  parentId: ADDITIONAL_CONTACT_ELEMENT_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: ADD_ANOTHER_CONTACT_ELEMENT_ID,
        options: [RADIO_ADD_ANOTHER_CONTACT],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'Yes',
        options: ['Yes', 'No'],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [ADDITIONAL_CONTACT_ELEMENT_ID],
        options: [ADD_ANOTHER_CONTACT_BUNDLE],
      },
      {
        ...REPEATABLE_PROPERTY,
        value: true,
      },
    ],
  },
};

ADD_ANOTHER_CONTACT_BUNDLE.children.push(...ADDITIONAL_CONTACT_DETAIL);
ADD_ANOTHER_CONTACT_BUNDLE.children.push(CONDITIONAL_ADD_ANOTHER_CONTACT);
//#endregion

export const CONTACTS_TAB_ELEMENTS = [LEGAL_GUARDIAN_DETAILS, ADD_ANOTHER_CONTACT_BUNDLE];
