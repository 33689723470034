import { CategoryResponse, NotificationFilters, NotificationsResponse } from '@good/schemas';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchCategories, fetchNotifications } from 'stores/queries/notifications/notifications-queries';

export const useFetchNotifications = (params: NotificationFilters) => {
  const { data, isLoading, isError, isSuccess } = useQuery<NotificationsResponse, Error>(
    ['notifications', JSON.stringify(params)],
    () => fetchNotifications(params),
  );
  return { data, isLoading, isError, isSuccess };
};

export const useFetchCategories = (): UseQueryResult<CategoryResponse> => {
  const data = useQuery(['categories'], () => fetchCategories());
  return data;
};
