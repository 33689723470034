import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Moon = forwardRef(function Moon(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.326 1.718a.75.75 0 0 1-.055.838 7.25 7.25 0 0 0 8.807 11.01.75.75 0 0 1 1.019.95A9.753 9.753 0 0 1 12 20.75c-5.385 0-9.75-4.365-9.75-9.75 0-4.9 3.613-8.953 8.32-9.646a.75.75 0 0 1 .756.364Zm-2.25 1.565a8.25 8.25 0 1 0 9.809 12.263A8.75 8.75 0 0 1 9.077 3.283Z"
        fill="currentColor"
      />
    </Icon>
  );
});
