import React, { Fragment } from 'react';

import { Skeleton, Box, Stack, Loader, Center, rem } from '@good/ui/core';

type InfiniteScrollLoadingSkeletonProps = {
  asTableRow?: boolean;
  h?: number;
  rows?: number;
  loading: boolean;
};

export const InfiniteScrollLoadingSkeleton = ({
  loading,
  asTableRow,
  rows = 4,
  h = 380,
}: InfiniteScrollLoadingSkeletonProps) => {
  if (asTableRow) {
    const gap = 12;

    return (
      <>
        {Array.from({ length: rows }).map((_, i) => (
          <Fragment key={i}>
            <tr style={{ height: rem(gap) }} />
            <Skeleton
              component='tr'
              w='100%'
              h={(h - (rows + 1) * gap) / rows}
              animate={loading}
              style={{ transition: 'opacity 0.2s ease-in-out' }}
              opacity={loading ? 1 : 0.6}
            />
            {i === rows - 1 && <tr style={{ height: rem(gap) }} />}
          </Fragment>
        ))}
      </>
    );
  }

  return (
    <Box w='100%' p='md' h={h} pos='relative'>
      <Stack w='100%' h={h} style={{ transition: 'opacity 0.2s ease-in-out' }} opacity={loading ? 1 : 0.6} gap='sm'>
        {Array.from({ length: rows }).map((_, i) => (
          <Skeleton key={i} w='100%' animate={loading} flex={1} />
        ))}
        <Center w='100%' style={{ transition: 'opacity 0.2s ease-in-out' }} opacity={loading ? 1 : 0} p='md'>
          <Loader type='bars' size='sm' color='gray.3' />
        </Center>
      </Stack>
    </Box>
  );
};
