import React, { Component } from 'react';
import _ from 'lodash';
import { Col, Row } from 'antd';
import { connect } from 'react-redux';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import {
  WorkflowTemplateStepApprovalType,
  WorkflowTemplateStepType,
  WorkflowTemplateTriggerType,
  WorkflowTemplateType,
} from 'utilities/enum-utils';
import { PrimaryButton } from 'common-components/buttons';
import { Paragraph, Title } from 'common-components/typography';
import PreviewFormModal from 'views/form-builder/components/PreviewFormModal';
import AttachFormModalWrapper from 'views/form-builder/components/AttachFormModal';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import StepListItem from 'views/workflows/workflow-templates/components/step-detail/StepListItem';
import { IAddWorkflowTemplateWizard, IWorkflowTemplateStep } from 'src/interfaces/workflow-interfaces';
import WorkflowTemplateSelectRoleModal from 'views/workflows/workflow-templates/components/WorkflowTemplateSelectRoleModal';

import GhostButton from '../../../../../../common-components/buttons/GhostButton';
import WorkflowTemplateAddTeamMemberModal from '../../../components/WorkflowTemplateAddTeamMemberModal';
import WorkflowTemplateAddEditAttachmentModal from '../../../components/WorkflowTemplateAddEditAttachmentModal';

interface ICreateWorkflowTemplateStepsStepPanelProps {
  onNextStep(): void;
  onPreviousStep(): void;
  addWorkflowTemplateWizard: typeof state.workflowStore.addWorkflowTemplateWizard;
  setAddWorkflowTemplateWizard: typeof dispatch.workflowStore.setAddWorkflowTemplateWizard;
  doGetFormDetailsByVersion: typeof dispatch.formBuilderStore.doGetFormDetailsByVersion;
  currentForm: typeof state.formBuilderStore.currentForm;
  providerTimezone: string;
}

interface ICreateWorkflowTemplateStepsStepPanelState {
  addWorkflowTemplateWizard: IAddWorkflowTemplateWizard;
  selectedStep: IWorkflowTemplateStep;
  editType: string;
  isDisabledButtonNextStep: boolean;
}

class CreateWorkflowTemplateStepsStepPanel extends Component<
  ICreateWorkflowTemplateStepsStepPanelProps,
  ICreateWorkflowTemplateStepsStepPanelState
> {
  state = {
    addWorkflowTemplateWizard: this.props.addWorkflowTemplateWizard,
    selectedStep: null,
    stepType: '',
    editType: '',
    isDisabledButtonNextStep: true,
  };

  private _onValidateBeforeNextStep = () => {
    const { setAddWorkflowTemplateWizard, onNextStep } = this.props;

    const steps = this.state.addWorkflowTemplateWizard.steps.map((step) => ({
      ...step,
      invalid: step.approvalType !== WorkflowTemplateStepApprovalType.ANYONE ? !step.stepApprovers.length : false,
    }));

    const addWorkflowTemplateWizard: IAddWorkflowTemplateWizard = {
      ...this.state.addWorkflowTemplateWizard,
      steps,
    };

    const isInvalid = addWorkflowTemplateWizard.steps.findIndex((one) => one.invalid) !== -1;

    this.setState({ addWorkflowTemplateWizard });

    if (isInvalid) {
      return;
    }

    setAddWorkflowTemplateWizard(addWorkflowTemplateWizard);

    onNextStep();
  };

  private _reGenerateSteps = (isWorkflowTypePlatform, steps) => {
    return steps.map((one, index) => ({
      ...one,
      stepNumber: isWorkflowTypePlatform ? index : index + 1,
    }));
  };

  private _onAddNewStep = () => {
    const { addWorkflowTemplateWizard, isDisabledButtonNextStep } = this.state;

    if (isDisabledButtonNextStep) {
      return;
    }

    const newAddWorkflowWizard = {
      ...addWorkflowTemplateWizard,
      steps: [
        ...addWorkflowTemplateWizard.steps,
        {
          name: '',
          type: WorkflowTemplateStepType.STEP,
          stepNumber: addWorkflowTemplateWizard.steps[addWorkflowTemplateWizard.steps.length - 1].stepNumber + 1,
          approvalType: WorkflowTemplateStepApprovalType.ANYONE,
          attachment: null,
          stepApprovers: [],
        },
      ],
    };

    this._validateButtonNextStep(newAddWorkflowWizard);

    this.setState({ addWorkflowTemplateWizard: newAddWorkflowWizard });
  };

  private _onUpdateStep = (values: IWorkflowTemplateStep) => {
    const addWorkflowTemplateWizard = _.clone(this.state.addWorkflowTemplateWizard);

    if (values.type === WorkflowTemplateStepType.STEP) {
      const stepIndex = _.findIndex(addWorkflowTemplateWizard.steps, { stepNumber: values.stepNumber });
      addWorkflowTemplateWizard.steps[stepIndex] = values;
    } else {
      addWorkflowTemplateWizard.steps[0] = values;
    }

    this._validateButtonNextStep(addWorkflowTemplateWizard);

    this.setState({ addWorkflowTemplateWizard, selectedStep: null });
  };

  private _onRemoveStep = (index: number) => {
    const addWorkflowTemplateWizard = _.clone(this.state.addWorkflowTemplateWizard);
    addWorkflowTemplateWizard.steps.splice(index, 1);

    const steps = this._reGenerateSteps(
      addWorkflowTemplateWizard.workflowTemplateType === WorkflowTemplateType.PLATFORM,
      addWorkflowTemplateWizard.steps,
    );

    this.setState({ addWorkflowTemplateWizard: { ...addWorkflowTemplateWizard, steps } });
  };

  private _onSetEditStep = async (editType, selectedStep) => {
    if (editType === 'form-preview') {
      await this.props.doGetFormDetailsByVersion({
        formId: selectedStep.formId,
        versionId: selectedStep.formVersionId,
        isWorkflowFormVersion: true,
      });
    }

    this.setState({
      editType,
      selectedStep,
    });
  };

  private _onCloseModal = () => {
    this.setState({ editType: '' });
  };

  private _onDragEnd = (result: DropResult) => {
    if (
      result.destination.index === 0 &&
      this.state.addWorkflowTemplateWizard.workflowTemplateType === WorkflowTemplateType.PLATFORM
    ) {
      return;
    }

    const addWorkflowTemplateWizard = _.clone(this.state.addWorkflowTemplateWizard);

    if (!addWorkflowTemplateWizard.steps[result.destination.index].name) {
      return;
    }

    const [removed] = addWorkflowTemplateWizard.steps.splice(result.source.index, 1);

    addWorkflowTemplateWizard.steps.splice(result.destination.index, 0, removed);

    const steps = this._reGenerateSteps(
      addWorkflowTemplateWizard.workflowTemplateType === WorkflowTemplateType.PLATFORM,
      addWorkflowTemplateWizard.steps,
    );

    this.setState({ addWorkflowTemplateWizard: { ...addWorkflowTemplateWizard, steps } });
  };

  private _validateButtonNextStep = (addWorkflowTemplateWizard: IAddWorkflowTemplateWizard) => {
    const invalidStepIndex = addWorkflowTemplateWizard.steps.findIndex((step) => !step.name || step.name.trim() === '');

    this.setState({ isDisabledButtonNextStep: invalidStepIndex !== -1 });
  };

  private _onSelectForm = (formId, elementValues, formName, formVersionId) => {
    const { selectedStep } = this.state;

    if (formVersionId) {
      this._onUpdateStep({ ...selectedStep, formId, formName, formVersionId });
    }
  };

  componentDidMount() {
    const { addWorkflowTemplateWizard } = this.props;

    if (addWorkflowTemplateWizard) {
      this.setState({
        isDisabledButtonNextStep: addWorkflowTemplateWizard.workflowTemplateType !== WorkflowTemplateType.PLATFORM,
      });
    }
  }

  render() {
    const { currentForm, providerTimezone } = this.props;
    const { addWorkflowTemplateWizard, selectedStep, editType, isDisabledButtonNextStep } = this.state;

    return (
      <>
        <WorkflowTemplateSelectRoleModal
          selectedStep={selectedStep}
          isOpen={editType === WorkflowTemplateStepApprovalType.ROLE}
          onUpdateStep={(values) => this._onUpdateStep(values)}
          onClose={this._onCloseModal}
        />

        <WorkflowTemplateAddTeamMemberModal
          selectedStep={selectedStep}
          isOpen={editType === WorkflowTemplateStepApprovalType.TEAM_MEMBER}
          onUpdateStep={(values) => this._onUpdateStep(values)}
          onClose={this._onCloseModal}
        />

        {editType === 'attachment' && (
          <WorkflowTemplateAddEditAttachmentModal
            isOpen={editType === 'attachment'}
            selectedStep={selectedStep}
            onUpdateStep={(values) => this._onUpdateStep(values)}
            onClose={this._onCloseModal}
          />
        )}

        {editType === 'form' && (
          <AttachFormModalWrapper
            isOpen={editType === 'form'}
            onClose={this._onCloseModal}
            onSave={this._onSelectForm}
            isAddedFormOnly={true}
            timezone={providerTimezone}
          />
        )}
        {editType === 'form-preview' && (
          <PreviewFormModal
            formContent={currentForm}
            isOpen={editType === 'form-preview'}
            onClose={this._onCloseModal}
            timezone={providerTimezone}
          />
        )}

        <div className="flex-column">
          <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
            <Row>
              <Col>
                <Title className="mb-large" level={4} weight="regular">
                  Add steps to a workflow
                </Title>
                <Paragraph className="mb-medium">
                  {addWorkflowTemplateWizard.triggerType === WorkflowTemplateTriggerType.MANUAL
                    ? 'Create workflows for your business.'
                    : 'You can add steps here for the workflow.'}
                </Paragraph>

                <DragDropContext onDragEnd={this._onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {addWorkflowTemplateWizard.steps.map((step, index) => (
                          <Draggable
                            key={index}
                            draggableId={`${index}`}
                            index={index}
                            isDragDisabled={step.type === WorkflowTemplateStepType.TRIGGER || !step.name}
                          >
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <StepListItem
                                  key={index}
                                  step={step}
                                  amountStep={addWorkflowTemplateWizard.steps.length}
                                  isDisableButtonDelete={isDisabledButtonNextStep}
                                  workflowTemplateType={addWorkflowTemplateWizard.workflowTemplateType}
                                  onUpdateStep={(values) => this._onUpdateStep(values)}
                                  onRemoveStep={() => this._onRemoveStep(index)}
                                  onSetEditStep={(editType) => this._onSetEditStep(editType, step)}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <GhostButton disabled={isDisabledButtonNextStep} onClick={this._onAddNewStep} icon="plus">
                  Add new step
                </GhostButton>
              </Col>
            </Row>
          </div>

          <div
            style={{ bottom: 24, position: 'sticky', width: 'max-content', marginLeft: 'auto' }}
            className="mv-large mr-large"
          >
            <PrimaryButton size="large" onClick={this._onValidateBeforeNextStep} disabled={isDisabledButtonNextStep}>
              Next
            </PrimaryButton>
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  addWorkflowTemplateWizard: state.workflowStore.addWorkflowTemplateWizard,
  currentForm: state.formBuilderStore.currentForm,
  providerTimezone: state.companyStore.companyDataLite.timezone,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddWorkflowTemplateWizard: dispatch.workflowStore.setAddWorkflowTemplateWizard,
  doGetFormDetailsByVersion: dispatch.formBuilderStore.doGetFormDetailsByVersion,
});

export default connect(mapState, mapDispatch)(CreateWorkflowTemplateStepsStepPanel);
