import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CallOutgoing = forwardRef(function CallOutgoing(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.527 2.25h-.065c-.383 0-.686 0-.983.066-1.084.242-1.995 1.246-2.13 2.348-.038.302-.012.562.02.886l.004.046c.258 2.64.917 5.016 1.957 7.085 1.545 3.074 3.915 5.445 6.99 6.99 2.069 1.039 4.444 1.698 7.084 1.956l.047.005c.323.031.583.057.885.02 1.103-.136 2.106-1.047 2.348-2.13.066-.298.066-.6.066-.984v-.125c0-.332 0-.59-.042-.83a2.75 2.75 0 0 0-1.587-2.033c-.222-.099-.472-.161-.795-.242l-.058-.014-1.655-.414-.036-.009c-.56-.14-1.036-.259-1.438-.316-.43-.06-.84-.063-1.26.074-.42.137-.75.38-1.061.682-.292.283-.607.66-.977 1.102l-.024.029-1.348 1.612a13.897 13.897 0 0 1-5.523-5.523l1.612-1.348.029-.024c.443-.37.82-.685 1.103-.977.301-.31.544-.64.68-1.061.138-.42.136-.83.075-1.26-.057-.402-.176-.878-.316-1.438l-.01-.036-.413-1.655-.014-.058c-.08-.322-.143-.573-.242-.794a2.75 2.75 0 0 0-2.034-1.588c-.238-.042-.497-.042-.829-.042h-.06ZM18.55 20.134c-2.109-.206-4.01-.682-5.691-1.402l1.11-1.328c.4-.48.665-.794.895-1.017.216-.21.355-.29.48-.331.127-.041.286-.058.585-.016.316.045.715.144 1.322.296l1.654.413c.408.102.52.133.605.171.38.17.65.515.722.924.016.092.02.208.02.63 0 .477-.005.604-.031.72-.104.465-.594.91-1.067.969-.121.015-.217.009-.604-.029ZM6.596 10.032l-1.328 1.11c-.72-1.68-1.196-3.582-1.402-5.691-.038-.387-.043-.483-.029-.604.059-.472.504-.963.969-1.067.116-.026.243-.03.721-.03.42 0 .537.003.629.02.409.071.755.342.924.72.038.086.069.198.171.606l.414 1.655c.151.606.25 1.005.295 1.321.042.3.026.458-.016.584-.04.126-.12.265-.33.482-.223.229-.538.493-1.018.894ZM16.2 2.25a.75.75 0 0 0 0 1.5h2.99l-4.72 4.72a.75.75 0 1 0 1.06 1.06l4.72-4.72V7.8a.75.75 0 0 0 1.5 0V3a.75.75 0 0 0-.75-.75h-4.8Z"
        fill="currentColor"
      />
    </Icon>
  );
});
