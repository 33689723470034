import { Avatar, List } from 'antd';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { IUserInvolved } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';
import ListingEmptyState from '../ListingEmptyState';

interface Props {
  isOpen: boolean;
  editable?: boolean;
  modalType: 'CUSTOMER' | 'TEAM_MEMBER';
  users: IUserInvolved[];
  isAddingUsers: boolean;
  disabled: boolean;
  onClose(): void;
  onConfirmModal(users: any[]): void;
  onOpenAddUsersModal?(): void;
  onRemoveUser(user: IUserInvolved): void;
}

class CustomerAndTeamMemberModal extends PureComponent<Props> {
  private _onConfirmModal = () => {
    const { editable, onClose, onConfirmModal } = this.props;

    if (!editable) {
      onClose();
      return;
    }
    onConfirmModal(this.props.users);
  };

  render() {
    const { isOpen, editable, modalType, users, isAddingUsers, disabled, onClose, onOpenAddUsersModal, onRemoveUser } =
      this.props;
    const isCustomerInvolvedModal = modalType === 'CUSTOMER';
    const modalTitle = isCustomerInvolvedModal ? 'Customers involved' : 'Team members involved';
    const userLabel = isCustomerInvolvedModal ? 'customers' : 'team members';
    const sortedUsers = users && users.sort((a, b) => a.displayName.localeCompare(b.displayName));

    return (
      <div>
        <ActionModal
          isOpen={isOpen}
          width="large"
          onClose={onClose}
          showCloseButton={true}
          verticalAlignment="highest"
          title={modalTitle}
        >
          <div className="mt-medium">
            <Text>The following {userLabel} have been listed as being involved in this workflow.</Text>
          </div>
          {users && !!users.length ? (
            <div className="bordered-top bordered-bottom mt-medium" style={{ maxHeight: '200px', overflowY: 'auto' }}>
              <List
                itemLayout="horizontal"
                dataSource={sortedUsers}
                renderItem={(item) => (
                  <List.Item
                    actions={
                      editable && [
                        <HyperlinkButton key={item.userId} onClick={() => onRemoveUser(item)}>
                          Remove
                        </HyperlinkButton>,
                      ]
                    }
                    className="p-medium"
                  >
                    <div className="flex align-center">
                      <Avatar icon="user" src={item.avatar} className="mr-small" />
                      <Text size="large">{item.displayName}</Text>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          ) : (
            <ListingEmptyState title={`No ${userLabel} found`} />
          )}

          {editable && (
            <GhostButton icon="plus" className="mt-medium" onClick={onOpenAddUsersModal}>
              Add {userLabel}
            </GhostButton>
          )}

          <ActionModalFooter>
            {editable && (
              <SecondaryButton size="large" className="mr-medium" onClick={onClose}>
                Cancel
              </SecondaryButton>
            )}
            <PrimaryButton size="large" onClick={this._onConfirmModal} loading={isAddingUsers} disabled={disabled}>
              {editable ? 'Save' : 'Close'}
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}

export default CustomerAndTeamMemberModal;
