import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Diskette = forwardRef(function Diskette(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.619 3.921c-.382-.158-.817-.17-2.104-.17H9c-1.435 0-2.436 0-3.192.102-.734.099-1.122.28-1.399.556-.277.277-.457.665-.556 1.4C3.752 6.563 3.75 7.564 3.75 9v6c0 1.436.002 2.437.103 3.192.099.734.28 1.122.556 1.399.277.277.665.457 1.4.556.4.054.872.08 1.441.092v-3.291c0-.898 0-1.648.08-2.242.084-.628.27-1.195.726-1.65.455-.456 1.022-.642 1.65-.726.594-.08 1.344-.08 2.242-.08h.104c.899 0 1.648 0 2.242.08.628.084 1.195.27 1.65.726.456.455.642 1.022.726 1.65.08.594.08 1.344.08 2.242v3.291c.57-.012 1.04-.038 1.442-.092.734-.099 1.122-.28 1.4-.556.276-.277.456-.665.555-1.4.102-.754.103-1.755.103-3.19V9.484c0-1.287-.013-1.722-.171-2.103-.158-.382-.456-.699-1.367-1.609l-.485-.485c-.91-.91-1.227-1.209-1.608-1.367Zm-.564 17.827c.917-.006 1.692-.028 2.337-.114.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337V9.343c0-1.088.001-1.844-.285-2.535-.287-.692-.822-1.226-1.591-1.995l-.101-.1-.485-.486-.101-.1c-.769-.77-1.303-1.305-1.995-1.591-.69-.287-1.447-.287-2.535-.286H8.945c-1.367 0-2.47 0-3.337.117-.9.12-1.658.38-2.26.981-.601.602-.86 1.36-.981 2.26-.117.867-.117 1.97-.117 3.337V15.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.645.086 1.42.108 2.338.114a.755.755 0 0 0 .1 0c.286.002.586.002.9.002h6.109l.899-.001a.78.78 0 0 0 .1 0Zm-.805-1.498V17c0-.964-.002-1.611-.066-2.095-.063-.461-.17-.659-.3-.789-.13-.13-.328-.237-.79-.3-.482-.064-1.13-.066-2.094-.066s-1.611.002-2.095.067c-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788-.064.484-.066 1.131-.066 2.095v3.25h6.5ZM7.25 8A.75.75 0 0 1 8 7.25h8a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 8Z"
        fill="currentColor"
      />
    </Icon>
  );
});
