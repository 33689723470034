/* eslint-disable */
import React, { Component, PureComponent } from 'react';

import styled from 'styled-components';
import { Colors } from '@blueprintjs/core';

const HoverCardBase: any = styled.div`
  background-color: ${(props: HoverCardProps) => (props.isSelected ? Colors.WHITE : Colors.WHITE)};

  border: ${(props: HoverCardProps) =>
    props.isSelected
      ? `1px ${Colors.BLUE4} solid`
      : `1px ${props.borderColor ? props.borderColor : Colors.WHITE} solid`};
  border-radius: 4px;
  &:hover {
    border: 1px solid ${Colors.BLUE3};
    background-color: ${Colors.WHITE};
    box-shadow: ${(props) => (props ? 'rgba(19, 124, 189, 0.2) 0px 0px 0px 2px' : '')};
    cursor: pointer;
  }
  transition: all 0.3s;
  padding: ${(props: HoverCardProps) => (props.padding ? `${props.padding}px` : '16px')};
  margin-bottom: ${(props: HoverCardProps) => (props.marginBottom ? `${props.marginBottom}px` : '16px')};
`;

interface HoverCardProps {
  isSelected?: boolean;
  onClick?: () => void;
  marginBottom?: number;
  borderColor?: string;
  padding?: number;
  [x: string]: any;
}

class HoverCard extends PureComponent<HoverCardProps, any> {
  render() {
    return <HoverCardBase {...this.props}>{this.props.children}</HoverCardBase>;
  }
}

export default HoverCard;
