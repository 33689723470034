import React, { PureComponent } from 'react';
import { Text } from '../typography';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';

interface IBottomActionSheetProps {
  paymentsList?: any;
  onDeselect?: any;
  refreshPaymentListings?: any; //not sure if this is needed so I changed it to optional
  openActionModal?: any;
  hasAdditionalButton?: boolean;
  additionalButtonLabel?: any;
  primaryButtonTitle?: string;
  isHaveActions?: boolean;
  hasPrimaryButton?: boolean;
  isActionProcessing?: boolean;
}

class BottomActionSheet extends PureComponent<IBottomActionSheetProps> {
  state = { open: true };

  _onDeselect = () => {
    this.setState({ open: false });
  };

  _onHideSheet = () => {
    this.setState({ open: false });
  };

  _animationEnd = () => {
    if (!this.state.open) {
      this.props.onDeselect();
    }
  };

  render() {
    const {
      paymentsList,
      primaryButtonTitle = 'Process Selected',
      hasAdditionalButton = false,
      additionalButtonLabel = 'Process',
      isHaveActions = true,
      hasPrimaryButton = true,
    } = this.props;
    const animClass = this.state.open ? 'action-anim-slide-in' : 'action-anim-slide-out';

    const itemCount = paymentsList.length;

    return (
      <div
        className={animClass}
        style={{ bottom: 0, position: 'sticky', zIndex: 20, width: 'inherit' }}
        onAnimationEnd={this._animationEnd}
      >
        <div
          className="bg-blue"
          style={{
            width: '12px',
            height: '12px',
            position: 'absolute',
            top: '-6px',
            left: '58px',
            transform: 'rotate(45deg)',
          }}
        />
        <div className="bg-blue pv-medium ph-large flex-row justify-between align-center">
          <div>
            <Text color="white" weight="bold" className="mr-medium">
              {itemCount} item{itemCount > 1 ? 's' : ''} selected
            </Text>
            <HyperlinkButton color="white" className="dim" onClick={this._onDeselect}>
              Deselect all
            </HyperlinkButton>
          </div>
          {!isHaveActions ? (
            <div className={'mv-small'}>
              <Text color="white">No actions available.</Text>
            </div>
          ) : (
            <div className={'flex-row'}>
              {hasPrimaryButton && (
                <PrimaryButton
                  loading={this.props.isActionProcessing}
                  size="large"
                  className="border-white bg-blue text-weight-bold"
                  onClick={() => this.props.openActionModal({ action: 'PROCESS' })}
                >
                  {primaryButtonTitle}
                </PrimaryButton>
              )}
              {hasAdditionalButton && (
                <PrimaryButton
                  loading={this.props.isActionProcessing}
                  size="large"
                  className="border-white bg-blue text-weight-bold ml-large"
                  onClick={() => this.props.openActionModal({ action: 'PROCESS_ADDITIONAL' })}
                >
                  {additionalButtonLabel}
                </PrimaryButton>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export { BottomActionSheet };
