import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Euro = forwardRef(function Euro(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4.75a7.252 7.252 0 0 0-6.71 4.5H13a.75.75 0 0 1 0 1.5H5.857a7.297 7.297 0 0 0 0 2.5H11a.75.75 0 0 1 0 1.5H6.29a7.252 7.252 0 0 0 12.148 2.046.75.75 0 0 1 1.124.992A8.754 8.754 0 0 1 4.691 14.75H3a.75.75 0 0 1 0-1.5h1.339a8.823 8.823 0 0 1 0-2.5h-1.34a.75.75 0 0 1 .001-1.5h1.69a8.754 8.754 0 0 1 14.872-3.038.75.75 0 0 1-1.124.993A7.23 7.23 0 0 0 13 4.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
