import React from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Col, Row } from 'antd';
import moment from 'moment-timezone';

import { timeZone } from 'interfaces/timezone-type';
import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { DocumentExpiryStatus, CustomerDetailsTabKey } from 'utilities/enum-utils';

type CustomerDocument = {
  attachmentUrl: string;
  documentName: string;
  expiryDate: string;
  expiryStatus: DocumentExpiryStatus;
  firstName: string;
  lastName: string;
  locality: string;
  userId: string;
};

type CustomerNotificationRowItemProps = {
  documentData: CustomerDocument;
  serviceProviderTimezone: timeZone;
};

export function CustomerNotificationRowItem({
  documentData,
  serviceProviderTimezone,
}: CustomerNotificationRowItemProps) {
  const history = useHistory();
  const rowTitle = `${documentData.firstName} ${documentData.lastName}`;

  const goToCustomer = (userId: string, selectedTab: CustomerDetailsTabKey = CustomerDetailsTabKey.GENERAL_INFO) => {
    history.push(`/customer/details/${userId}`, { refresh: true, selectedTab });
  };

  return (
    <div className='ph-small bordered-top pv-medium'>
      <Row type='flex' align='middle' gutter={8}>
        <Col span={8}>
          <div className='align-center flex-row' title={rowTitle}>
            {/* Removed src from avatar to prevent loading for now */}
            <Avatar icon='user' className='mr-medium' />
            <div>
              <Text weight='bold'>{rowTitle}</Text>
              <br />
              <Text color='secondary'>{documentData.locality}</Text>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className='text-align-left' title={documentData.documentName}>
            <Text color='secondary'>
              {documentData.expiryStatus === DocumentExpiryStatus.EXPIRED
                ? 'Document expired:'
                : 'Document expiring soon:'}
            </Text>
            <br />
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              <Text>{documentData.documentName}</Text>
            </div>
          </div>
        </Col>
        <Col span={4}>
          <div className='text-align-left'>
            {documentData.expiryStatus === DocumentExpiryStatus.EXPIRED ? 'Expired ' : 'Expiring '}{' '}
            {moment.tz(documentData.expiryDate, serviceProviderTimezone).format('DD/MM/YYYY')}
          </div>
        </Col>
        <Col span={4}>
          <div className='align-center flex-row justify-end'>
            <HyperlinkButton onClick={() => goToCustomer(documentData.userId, CustomerDetailsTabKey.FILES)}>
              View profile
            </HyperlinkButton>
          </div>
        </Col>
      </Row>
    </div>
  );
}
