import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Replay5 = forwardRef(function Replay5(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25c-3.223 0-5.637 1.346-7.23 2.674-.387.322-.727.645-1.02.948V3.5a.75.75 0 0 0-1.5 0V8a.75.75 0 0 0 1.393.386l.003-.005.02-.033.093-.139a8.93 8.93 0 0 1 .39-.522c.35-.438.878-1.024 1.581-1.61C7.137 4.903 9.223 3.75 12 3.75A8.231 8.231 0 0 1 20.25 12a8.25 8.25 0 0 1-16.5 0 .75.75 0 0 0-1.5 0c0 5.385 4.365 9.75 9.75 9.75s9.75-4.365 9.75-9.75A9.731 9.731 0 0 0 12 2.25Zm-2 6a.75.75 0 0 0-.75.75v3c0 .414.336.75.75.75h3.25v1.5H10a.75.75 0 1 0 0 1.5h4a.75.75 0 0 0 .75-.75v-3a.75.75 0 0 0-.75-.75h-3.25v-1.5H14a.75.75 0 0 0 0-1.5h-4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
