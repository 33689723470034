import { useState, useEffect } from 'react';

import { Address } from '../types';

export function useHasValidAutoInputResult(address: Address | null) {
  const [hasValidResult, setHasValidResult] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq -- checking for null & undefined
    if (address?.geoLat != undefined && address.geoLng != undefined) {
      setHasValidResult(true);
    } else {
      setHasValidResult(false);
    }
  }, [address?.geoLat, address?.geoLng]);

  return hasValidResult;
}
