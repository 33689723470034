import React, { Component } from 'react';
import { Col, Form, Radio, Row, Avatar, notification } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { connect } from 'react-redux';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';

import { IAddCustomerGuardianDetailWizard } from 'src/interfaces/customer-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import InfoPanel from 'common-components/alerts/InfoPanel';

interface IAddLegalGuardianStepInviteToAppProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setAddLegalGuardianDetailWizard: typeof dispatch.customersStore.setAddLegalGuardianDetailWizard;
  addLegalGuardianDetailWizard: typeof state.customersStore.addLegalGuardianDetailWizard;
  doCreateConnectionRequest: typeof dispatch.customersStore.doCreateConnectionRequest;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  doAddGuardiansToCustomer: typeof dispatch.customersStore.doAddGuardiansToCustomer;
}

interface IAddLegalGuardianStepInviteToAppState {
  guardians: IAddCustomerGuardianDetailWizard[];
  openEmailConflictModal: boolean;
  isInviteToApp: boolean;
}

class AddLegalGuardianStepInviteToApp extends Component<
  IAddLegalGuardianStepInviteToAppProps,
  IAddLegalGuardianStepInviteToAppState
> {
  state = {
    guardians: this.props.addLegalGuardianDetailWizard,
    openEmailConflictModal: false,
    isInviteToApp: false,
  };

  private _changeIsInviteToApp = (value) => {
    this.setState({ isInviteToApp: value });
  };

  private _validateBeforeNextStep = async () => {
    try {
      const { form, onNextStep, setAddLegalGuardianDetailWizard, doAddGuardiansToCustomer } = this.props;
      const { guardians } = this.state;

      let isFormValid = true;

      form.validateFields(async (err, value) => {
        if (err) {
          isFormValid = false;
        }
      });

      if (isFormValid) {
        if (form.getFieldValue('IsInviteToApp')) {
          const updatedGuardians = _.map(guardians, (guardian) => {
            return guardian.connected ? guardian : { ...guardian, isInviteToApp: true };
          });
          setAddLegalGuardianDetailWizard(updatedGuardians);
          const request = {
            isInviteToApp: true,
            guardians: updatedGuardians,
          };
          await doAddGuardiansToCustomer(request);

          onNextStep();
        } else {
          setAddLegalGuardianDetailWizard(guardians);
          const request = {
            isInviteToApp: false,
            guardians: guardians,
          };
          await doAddGuardiansToCustomer(request);
          onNextStep();
        }
      }
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.meta.message });
    }
  };

  render() {
    const { form, onPreviousStep } = this.props;

    const { getFieldDecorator } = form;

    return (
      <>
        <div className="anim-slide-left pt-x3-large">
          <Row className="ph-x3-large">
            <Col span={6} style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div>
                <Paragraph size="x3-large" weight="bold">
                  Invite to GoodHuman app
                </Paragraph>
                <Paragraph>
                  Inviting the customer to the GoodHuman app allows them to manage their own care. This includes being
                  able to directly book services through the app and update their own information.
                </Paragraph>
                <Paragraph>
                  It also gives them access to GoodHumans messaging platform which will let you directly communicate
                  with them.
                </Paragraph>
                <Paragraph>
                  Some customers may not have the ability to access the app (Due to lack of a mobile phone). Please
                  double check with the customer if they have the capacity to download the app.
                </Paragraph>
              </div>
            </Col>
            <Col span={18} className="pl-x-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
                <div className="mb-large">
                  <Title level={4} className="mb-small">
                    Invite to GoodHuman app?
                  </Title>
                  <Form.Item className={'m-none'}>
                    {getFieldDecorator('IsInviteToApp', {
                      initialValue: this.state.isInviteToApp,
                      rules: [
                        {
                          required: true,
                          message: 'Please select an option.',
                        },
                      ],
                    })(
                      <Radio.Group
                        defaultValue={false}
                        onChange={(value) => this._changeIsInviteToApp(value.target.value)}
                      >
                        <Radio className="mb-medium" value={false}>
                          No
                        </Radio>
                        <br />
                        <Radio disabled value={true}>
                          Yes
                        </Radio>
                      </Radio.Group>,
                    )}
                    <InfoPanel
                      text={
                        <Paragraph>
                          Currently unavailable. You will be able to invite your customers to the GoodHuman customer app
                          experience shortly.
                        </Paragraph>
                      }
                    />
                  </Form.Item>
                </div>
                {form.getFieldValue('IsInviteToApp') && (
                  <>
                    <SubTitle>Invitation Details</SubTitle>
                    {_.map(this.state.guardians, (legalGuardian) => {
                      return (
                        <div className="flex-row align-center pt-small">
                          <Avatar size="large" icon="user" className="mr-medium" src={legalGuardian.attachmentUrl} />
                          <Text weight="bold">{legalGuardian.firstName + ' ' + legalGuardian.lastName}</Text>
                          <Text className="pl-medium text-underline" size="small" color="gray">
                            {legalGuardian.connected ? 'Already Invited' : legalGuardian.email}
                          </Text>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>

              <div className="pv-medium width-full" style={{ position: 'sticky', bottom: 0 }}>
                <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                  <Col className="bg-transparent" span={24}>
                    <div className="text-align-right pv-medium">
                      <SecondaryButton size="large" className="mr-medium" onClick={onPreviousStep}>
                        Back
                      </SecondaryButton>
                      <PrimaryButton size="large" onClick={this._validateBeforeNextStep}>
                        Add Legal Guardians
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  addLegalGuardianDetailWizard: state.customersStore.addLegalGuardianDetailWizard,
  selectedCustomer: state.customersStore.selectedCustomer,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddLegalGuardianDetailWizard: dispatch.customersStore.setAddLegalGuardianDetailWizard,
  doCreateConnectionRequest: dispatch.customersStore.doCreateConnectionRequest,
  doAddGuardiansToCustomer: dispatch.customersStore.doAddGuardiansToCustomer,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IAddLegalGuardianStepInviteToAppProps>()(AddLegalGuardianStepInviteToApp));
