/* eslint-disable */
import React, { PureComponent, ReactNode } from 'react';
import { Button, Icon } from 'antd';
import { PORTAL_COLORS } from '../../interfaces/common-ui-types';

import { isEmpty } from 'lodash';

// Default Properties
const baseUiColor = 'blue-action';
const defaultShape = 'default';
const defaultSize = 'large';

interface PrimaryButtonProps {
  children?: ReactNode;
  size?: 'small' | 'default' | 'large' | string;
  color?: PORTAL_COLORS;
  shape?: 'circle' | 'round' | 'circle-outline' | 'default' | string;
  loading?: boolean;
  disabled?: boolean | any;
  icon?: string;
  paddingSize?: 'none' | 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
  iconPosition?: 'left' | 'right';
  className?: string;
  onClick?: any;
  htmlType?: 'submit' | 'button';
  form?: string;
}

class PrimaryButton extends PureComponent<PrimaryButtonProps, any> {
  render() {
    const {
      children,
      size = 'default',
      color = baseUiColor,
      shape = defaultShape,
      loading = false,
      icon = '',
      paddingSize = '',
      iconPosition = 'left',
      className = '',
      disabled = false,
      onClick = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
          console.warn('button handler not implemented');
      },
      htmlType,
      form,
    } = this.props;

    let finalClassName = '';
    let fontSize = '16px';
    let shapeProps: any = { shape };
    let disabledProps = { disabled };

    // Color
    if (disabled) {
      // Disabled; color defaults to grey
      finalClassName += 'bg-secondary bordered-none text-color-tertiary ';
    } else {
      finalClassName += 'bg-' + color + ' border-' + color + ' ';
    }

    // Size
    if (size === 'default') {
      finalClassName += `ph-${isEmpty(paddingSize) ? 'medium' : paddingSize} `;
      fontSize = '16px';
    } else if (size === 'large') {
      finalClassName += `ph-${isEmpty(paddingSize) ? 'large' : paddingSize} `;
      fontSize = '17px';
    } else if (size === 'small') {
      finalClassName += `ph-${isEmpty(paddingSize) ? 'small' : paddingSize} `;
      fontSize = '15px';
    }

    // Button Shape
    if (shape === 'default') {
      // Remove shape properties
      shapeProps = {};
    }

    // Loading; if it's loading, disable buttons and change the color as well
    if (loading) {
      disabledProps = { disabled: true };
      finalClassName += 'dimmer text-color-white cursor-wait ';
    }

    finalClassName += className;

    return (
      <Button
        type="primary"
        className={finalClassName}
        style={{ fontSize }}
        {...shapeProps}
        size={size}
        {...disabledProps}
        onClick={onClick}
        htmlType={htmlType}
        form={form}
      >
        {!loading && !isEmpty(icon) && iconPosition === 'left' && <Icon type={icon} />}
        {loading && iconPosition === 'left' && <Icon type="loading" />}
        {children}
        {!loading && !isEmpty(icon) && iconPosition === 'right' && <Icon type={icon} />}
        {loading && iconPosition === 'right' && <Icon type="loading" />}
      </Button>
    );
  }
}

export default PrimaryButton;
