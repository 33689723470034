import { FilterType, FormStatus, FormType } from 'utilities/enum-utils';
import { v4 as uuid } from 'uuid';
import {
  AFFECT_TO_ELEMENT_PROPERTY,
  CAPTION_TEXT_PROPERTY,
  CONDITIONAL_COMPARATOR,
  CONDITIONAL_COMPARATOR_DATE,
  EVENT_PROPERTY,
  FIELD_TITLE_PROPERTY,
  PLACEHOLDER_PROPERTY,
  REPEATABLE_PROPERTY,
  REQUIRED_PROPERTY,
  TARGET_ELEMENT_PROPERTY,
  TARGET_OPTION_PROPERTY,
} from './common-field-constants';
import {
  LabelAttachment,
  LabelCalendar,
  LabelCheckBox,
  LabelConditional,
  LabelCurrency,
  LabelDivider,
  LabelHeader,
  LabelLongText,
  LabelMultiSelection,
  LabelNumber,
  LabelOption,
  LabelParagraph,
  LabelShortText,
  LabelSingleSelection,
} from './common-label-constants';
import { FormConditionalOperator, FormElementType, PropType } from './form-enum';
import { IFormElement, IFormItems } from './form-interface';
import { INTAKE_FORM } from './intake-form';

export const GROUP_NAME_FORM_ELEMENT = {
  DISPLAY_ELEMENTS: 'DISPLAY ELEMENTS',
  FORM_FIELDS: 'FORM FIELDS',
  INTAKE_FORM_COMPONENTS: 'INTAKE FORM COMPONENTS',
};

export const ITEMS: IFormItems[] = [
  {
    groupName: GROUP_NAME_FORM_ELEMENT.INTAKE_FORM_COMPONENTS,
    formElements: INTAKE_FORM.map((element) => {
      const newElement = element;
      newElement.isIntakeForm = true;

      const stack: IFormElement[] = [element];
      while (stack.length) {
        const parentEl = stack.pop();
        parentEl.children?.forEach((child) => {
          stack.push(child);
          child.isIntakeForm = true;
        });
      }
      return newElement;
    }),
  },
  {
    groupName: GROUP_NAME_FORM_ELEMENT.DISPLAY_ELEMENTS,
    formElements: [
      {
        id: uuid(),
        name: 'Header',
        type: FormElementType.HEADER,
        label: LabelHeader,
        properties: {
          general: [],
          appearance: [
            {
              key: 'headingText',
              name: 'HEADING TEXT',
              value: 'Heading text',
              rules: [
                { required: true, message: 'Please enter headline' },
                {
                  max: 200,
                  message: 'Max length of characters is 200',
                },
              ],
              placeholder: 'Heading Text',
              caption: 'Enter heading text for this field.',
            },
            {
              key: 'subHeadingText',
              name: 'SUBHEADING TEXT (OPTIONAL)',
              value: 'Subheading text',
              rules: [
                {
                  max: 1000,
                  message: 'Max length of characters is 1000',
                },
              ],
              placeholder: 'Subheading Text',
              caption: 'Accompanying subheading text for this header.',
            },
          ],
          configuration: [],
        },
      },
      {
        id: uuid(),
        name: 'Paragraph',
        type: FormElementType.PARAGRAPH,
        label: LabelParagraph,
        properties: {
          general: [],
          appearance: [
            {
              key: 'paragraphText',
              name: 'PARAGRAPH TEXT',
              value: 'Paragraph text',
              rules: [
                { required: true, message: 'Please add a paragraph text' },
                {
                  max: 1000,
                  message: 'Max length of characters is 1000',
                },
              ],
              placeholder: 'Paragraph Text',
              caption: 'Text content for this paragraph.',
            },
          ],
          configuration: [],
        },
      },
      {
        id: uuid(),
        name: 'Divider',
        type: FormElementType.DIVIDER,
        label: LabelDivider,
        properties: { general: [], appearance: [], configuration: [] },
      },
      {
        id: uuid(),
        name: 'Show / Hide Logic',
        type: FormElementType.CONDITIONAL,
        label: LabelConditional,
        properties: {
          general: [],
          appearance: [],
          configuration: [
            {
              ...AFFECT_TO_ELEMENT_PROPERTY,
              value: [],
              options: [],
            },
            { ...TARGET_ELEMENT_PROPERTY },
            { ...TARGET_OPTION_PROPERTY },
            { ...EVENT_PROPERTY },
            { ...CONDITIONAL_COMPARATOR },
            { ...CONDITIONAL_COMPARATOR_DATE },
            // Repeatable Property config options removed - previously enabeld, code not to be trusted.
            // { ...REPEATABLE_PROPERTY },
          ],
        },
      },
    ],
  },
  {
    groupName: GROUP_NAME_FORM_ELEMENT.FORM_FIELDS,
    formElements: [
      {
        id: uuid(),
        name: 'Short text',
        type: FormElementType.SHORT_TEXT,
        label: LabelShortText,
        properties: {
          general: [
            {
              ...FIELD_TITLE_PROPERTY,
              value: 'Short text',
            },
            REQUIRED_PROPERTY,
          ],
          appearance: [PLACEHOLDER_PROPERTY, CAPTION_TEXT_PROPERTY],
          configuration: [],
        },
        allowedConditionalOperators: [
          FormConditionalOperator.IS,
          FormConditionalOperator.IS_NOT,
          FormConditionalOperator.CONTAINS,
          FormConditionalOperator.DOES_NOT_CONTAIN,
        ],
      },
      {
        id: uuid(),
        name: 'Long text',
        type: FormElementType.LONG_TEXT,
        label: LabelLongText,
        properties: {
          general: [
            {
              ...FIELD_TITLE_PROPERTY,
              value: 'Long text',
            },
            REQUIRED_PROPERTY,
          ],
          appearance: [PLACEHOLDER_PROPERTY, CAPTION_TEXT_PROPERTY],
          configuration: [],
        },
        allowedConditionalOperators: [
          FormConditionalOperator.IS,
          FormConditionalOperator.IS_NOT,
          FormConditionalOperator.CONTAINS,
          FormConditionalOperator.DOES_NOT_CONTAIN,
        ],
      },
      {
        id: uuid(),
        name: 'Number Input',
        type: FormElementType.NUMBER,
        label: LabelNumber,
        properties: {
          general: [
            {
              ...FIELD_TITLE_PROPERTY,
              value: 'Number',
            },
            REQUIRED_PROPERTY,
          ],
          appearance: [PLACEHOLDER_PROPERTY, CAPTION_TEXT_PROPERTY],
          configuration: [
            {
              key: 'minimum',
              name: 'MINIMUM VALUE',
              value: false,
              label: 'Add a minimum value',
              caption: 'Minimum accepted value for this input field.',
              type: PropType.checkbox,
            },
            {
              key: 'minimumValue',
              name: '',
              dependOn: 'minimum',
              value: null,
              type: PropType.inputDepend,
              rules: [
                {
                  required: true,
                  message: 'Please enter a number for the minimum value',
                },
              ],
            },
            {
              key: 'maximum',
              name: 'MAXIMUM VALUE',
              value: false,
              label: 'Add a maximum value',
              caption: 'Maximum accepted value for this input field.',
              type: PropType.checkbox,
            },
            {
              key: 'maximumValue',
              name: '',
              dependOn: 'maximum',
              value: null,
              type: PropType.inputDepend,
              rules: [
                {
                  required: true,
                  message: 'Please enter a number for the maximum value',
                },
              ],
            },
            {
              key: 'default',
              name: 'DEFAULT VALUE',
              value: false,
              label: 'Add a default value',
              caption: 'Pre-populated data for this input field.',
              type: PropType.checkbox,
            },
            {
              key: 'defaultValue',
              name: '',
              dependOn: 'default',
              value: null,
              type: PropType.inputDepend,
              rules: [
                {
                  required: true,
                  message: 'Please enter a number for the maximum value',
                },
              ],
            },
          ],
        },
        allowedConditionalOperators: [
          FormConditionalOperator.IS_EQUAL_TO,
          FormConditionalOperator.IS_LESS_THAN,
          FormConditionalOperator.IS_GREATER_THAN,
          FormConditionalOperator.IS_NOT,
        ],
      },
      {
        id: uuid(),
        name: 'Dollar amount',
        type: FormElementType.CURRENCY,
        label: LabelCurrency,
        properties: {
          general: [
            {
              ...FIELD_TITLE_PROPERTY,
              value: 'Dollar amount',
            },
            REQUIRED_PROPERTY,
          ],
          appearance: [PLACEHOLDER_PROPERTY, CAPTION_TEXT_PROPERTY],
          configuration: [
            {
              key: 'minimum',
              name: 'MINIMUM VALUE',
              value: false,
              label: 'Add a minimum value',
              caption: 'Minimum accepted value for this input field.',
              type: PropType.checkbox,
            },
            {
              key: 'minimumValue',
              name: '',
              dependOn: 'minimum',
              value: null,
              placeholder: 'Minimum value, eg 1, 2...',
              type: PropType.inputDepend,
              rules: [
                {
                  required: true,
                  pattern: new RegExp(/^\d*\.?\d+$/g),
                  message: 'Please enter a number for the minimum value',
                },
              ],
            },
            {
              key: 'maximum',
              name: 'MAXIMUM VALUE',
              value: false,
              label: 'Add a maximum value',
              caption: 'Maximum accepted value for this input field.',
              type: PropType.checkbox,
            },
            {
              key: 'maximumValue',
              name: '',
              dependOn: 'maximum',
              placeholder: 'Maximum value, eg 1, 2...',
              value: null,
              type: PropType.inputDepend,
              rules: [
                {
                  required: true,
                  pattern: new RegExp(/^\d*\.?\d+$/g),
                  message: 'Please enter a number for the maximum value',
                },
              ],
            },
            {
              key: 'default',
              name: 'DEFAULT VALUE',
              value: false,
              label: 'Add a default value',
              caption: 'Pre-populated data for this input field.',
              type: PropType.checkbox,
            },
            {
              key: 'defaultValue',
              name: '',
              dependOn: 'default',
              value: null,
              type: PropType.inputDepend,
              rules: [
                {
                  required: true,
                  pattern: new RegExp(/^\d*\.?\d+$/g),
                  message: 'Please enter a number for the maximum value',
                },
              ],
            },
          ],
        },
        allowedConditionalOperators: [
          FormConditionalOperator.IS_EQUAL_TO,
          FormConditionalOperator.IS_LESS_THAN,
          FormConditionalOperator.IS_GREATER_THAN,
          FormConditionalOperator.IS_NOT,
        ],
      },
      {
        id: uuid(),
        name: 'Dropdown',
        type: FormElementType.DROPDOWN,
        label: LabelOption,
        properties: {
          general: [
            {
              ...FIELD_TITLE_PROPERTY,
              value: 'Dropdown',
            },
            REQUIRED_PROPERTY,
          ],
          appearance: [CAPTION_TEXT_PROPERTY],
          configuration: [
            {
              key: 'options',
              name: 'OPTIONS',
              value: ['Option 1', 'Option 2'],
              caption: 'Option that will be shown for this input field.',
              type: PropType.options,
            },
            {
              key: 'defaultOption',
              name: 'DEFAULT VALUE',
              value: 'No default',
              caption: 'Default selected value for this input field.',
              type: PropType.defaultOption,
            },
          ],
        },
        allowedConditionalOperators: [FormConditionalOperator.IS_SELECTED, FormConditionalOperator.IS_NOT_SELECTED],
      },
      {
        id: uuid(),
        name: 'Multiple selection',
        type: FormElementType.DROPDOWN_MULTI_SELECT,
        label: LabelOption,
        properties: {
          general: [
            {
              ...FIELD_TITLE_PROPERTY,
              value: 'Multiple selection',
            },
            REQUIRED_PROPERTY,
          ],
          appearance: [CAPTION_TEXT_PROPERTY, PLACEHOLDER_PROPERTY],
          configuration: [
            {
              key: 'options',
              name: 'OPTIONS',
              value: ['Option 1', 'Option 2'],
              caption: 'Option that will be shown for this input field.',
              type: PropType.options,
            },
            {
              key: 'defaultOption',
              name: 'DEFAULT VALUE',
              value: [],
              caption: 'Default selected value for this input field.',
              type: PropType.defaultOption,
            },
          ],
        },
        allowedConditionalOperators: [FormConditionalOperator.IS_SELECTED, FormConditionalOperator.IS_NOT_SELECTED],
      },
      {
        id: uuid(),
        name: 'Attachment',
        type: FormElementType.FILE_UPLOAD,
        label: LabelAttachment,
        properties: {
          general: [
            {
              ...FIELD_TITLE_PROPERTY,
              value: 'Attachment',
            },
            REQUIRED_PROPERTY,
          ],
          appearance: [CAPTION_TEXT_PROPERTY],
          configuration: [
            {
              key: 'multipleAttachment',
              name: 'Allow multiple attachments',
              value: false,
              caption: 'Allow multiple attachments',
              type: PropType.required,
            },
            {
              key: 'multipleAttachmentValue',
              name: 'Maximum number of files',
              dependOn: 'multipleAttachment',
              caption: 'Maximum number of files',
              value: 2,
              type: PropType.inputDepend,
              addonAfter: 'Files',
              style: {
                width: '150px',
              },
              rules: [
                {
                  required: true,
                  message: 'Please enter a number for the maximum files value',
                },
                {
                  validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                    if (!value && parseInt(value) !== 0) {
                      cb();
                    } else if (isNaN(parseInt(value))) {
                      cb(`The value must be number`);
                    } else parseInt(value) < 2 || parseInt(value) > 10 ? cb(`The value is from 2-10`) : cb();
                  },
                },
              ],
            },
          ],
        },
        allowedConditionalOperators: [FormConditionalOperator.IS_UPLOADED, FormConditionalOperator.IS_NOT_UPLOADED],
      },
      {
        id: uuid(),
        name: 'Date/time',
        type: FormElementType.DATE_TIME,
        label: LabelCalendar,
        properties: {
          general: [
            {
              ...FIELD_TITLE_PROPERTY,
              value: 'Date/time',
            },
            REQUIRED_PROPERTY,
          ],
          appearance: [CAPTION_TEXT_PROPERTY],
          configuration: [
            {
              key: 'dateTimeType',
              name: 'Date/time TYPE',
              value: 'Date & time',
              options: ['Date & time', 'Date only', 'Time only'],
              caption: 'Determines whether date & time should be shown for this field.',
              type: PropType.Select,
            },
            {
              key: 'constraint',
              name: 'CONSTRAINTS',
              value: 'Allow past and future dates',
              options: ['Allow past and future dates', 'Allow past dates only', 'Allow future dates only'],
              caption: 'Determines what dates can be chosen',
              dependOn: 'dateTimeType',
              type: PropType.selectDepend,
            },
          ],
        },
        allowedConditionalOperators: [
          FormConditionalOperator.IS_EQUAL_TO,
          FormConditionalOperator.IS_LESS_THAN,
          FormConditionalOperator.IS_GREATER_THAN,
          FormConditionalOperator.IS_NOT,
        ],
      },
      {
        id: uuid(),
        name: 'Single selection',
        type: FormElementType.SINGLE_CHOICE,
        label: LabelSingleSelection,
        properties: {
          general: [
            {
              ...FIELD_TITLE_PROPERTY,
              value: 'Single choice',
            },
            REQUIRED_PROPERTY,
          ],
          appearance: [CAPTION_TEXT_PROPERTY],
          configuration: [
            {
              key: 'options',
              name: 'OPTIONS',
              value: ['Option 1', 'Option 2'],
              caption: 'Option that will be shown for this input field.',
              type: PropType.options,
            },
            {
              key: 'defaultOption',
              name: 'DEFAULT VALUE',
              value: 'No default',
              caption: 'Default selected value for this input field.',
              type: PropType.defaultOption,
            },
          ],
        },
        allowedConditionalOperators: [FormConditionalOperator.IS_SELECTED, FormConditionalOperator.IS_NOT_SELECTED],
      },
      {
        id: uuid(),
        name: 'Multiple choice',
        type: FormElementType.MULTI_CHOICE,
        label: LabelMultiSelection,
        properties: {
          general: [
            {
              ...FIELD_TITLE_PROPERTY,
              value: 'Multiple choice',
            },
            REQUIRED_PROPERTY,
          ],
          appearance: [CAPTION_TEXT_PROPERTY],
          configuration: [
            {
              key: 'options',
              name: 'OPTIONS',
              value: ['Option 1', 'Option 2'],
              caption: 'Option that will be shown for this input field.',
              type: PropType.options,
            },
            {
              key: 'defaultOption',
              name: 'DEFAULT VALUE',
              value: 'No default',
              caption: 'Default selected value for this input field.',
              type: PropType.defaultOption,
            },
          ],
        },
        allowedConditionalOperators: [FormConditionalOperator.IS_SELECTED, FormConditionalOperator.IS_NOT_SELECTED],
      },
      {
        id: uuid(),
        name: 'Checkbox',
        type: FormElementType.CHECKBOX,
        label: LabelCheckBox,
        properties: {
          general: [
            {
              ...FIELD_TITLE_PROPERTY,
              value: 'Checkbox',
            },
            REQUIRED_PROPERTY,
          ],
          appearance: [
            {
              key: 'checkboxText',
              name: 'CHECKBOX TEXT',
              value: 'Checkbox text',
              rules: [
                {
                  max: 200,
                  message: 'Max length of characters is 200',
                },
              ],
              caption: 'Text that will be shown next to the input field.',
            },
            CAPTION_TEXT_PROPERTY,
          ],
          configuration: [
            {
              key: 'checkboxOptions',
              name: 'DEFAULT VALUE',
              value: ['Checked', 'Unchecked'],
              defaultChecked: true,
              caption: 'The default check state for this input field.',
            },
          ],
        },
        allowedConditionalOperators: [FormConditionalOperator.IS_SELECTED, FormConditionalOperator.IS_NOT_SELECTED],
      },
    ],
  },
];

export const STATUS = [
  {
    filter: FilterType.FORM_STATUS,
    values: [FormStatus.ACTIVE, FormStatus.DRAFT],
    selectionLabel: ' 2 status',
  },
  {
    filter: FilterType.FORM_TYPE,
    values: [FormType.INCIDENT, FormType.INTAKE, FormType.CUSTOM],
    selectionLabel: ' All',
  },
];

export const MAX_SELECTED_OPTIONS = {
  FUNDING_SUPPORT_COORDINATOR: 5,
};
