import { useQuery } from '@tanstack/react-query';

import { fetchNDIADebtorId } from 'stores/queries/acccount/account-queries';

export function useFetchNDIADebtorId() {
  const { data, remove, refetch, isFetching, isLoading, isSuccess, status, error } = useQuery(['NDIA-Debtor-ID'], () =>
    fetchNDIADebtorId(),
  );

  return { data, remove, refetch, isFetching, isLoading, isSuccess, status, error };
}
