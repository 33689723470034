import { useButton } from '@react-aria/button';
import { useMenuTrigger } from '@react-aria/menu';
import { MenuTriggerProps, useMenuTriggerState } from '@react-stately/menu';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { BoxStyleProps, HTMLProps } from '..';
import { compose, css, useStyleProps } from '..';
import { ButtonProvider } from '../button';
import { Popover } from '../popover';
import { getOverlayStyles, Placement, transformOutOfViewContent } from '../utils/overlay';
import * as styles from './dropdown.css';
import { DropdownHeader, DropdownHeaderProps } from './header';
import { OverlayContainer } from '@react-aria/overlays';

export type DropdownProps = MenuTriggerProps &
  BoxStyleProps &
  DropdownHeaderProps &
  HTMLProps<HTMLDivElement> & {
    /**
     * Text label
     */
    label?: string;
    /**
     * Show back button
     */
    isShowBackButton?: boolean;
    /**
     * Action when click back button
     */
    onBack?(): void;
    /**
     * Content dropdown
     */
    overlay?: React.ReactNode;
    /**
     * Status visible dropdown
     */
    visible?: boolean;
    /**
     * Change status visible
     */
    onVisibleChange?(visible: boolean): void;
    /**
     * Placement overlay
     */
    placement?: Placement;
    /**
     * Handle close dropdown
     */
    onClose?(): void;
    /**
     * Disabled status
     */
    isDisabled?: boolean;
    /**
     * This is used for trigger normal element
     */
    asPseudoButton?: boolean;
    /**
     * Has no dropdown header
     */
    hasNoHeader?: boolean;
  };

/**
 * @name
 * Dropdown
 *
 * @example
 * <Dropdown label='Label' overlay='Content Dropdown' placement='bottomLeft'>
 *    <Button>Dropdown</Button>
 * </Dropdown>
 */

// eslint-disable-next-line react/display-name
export const Dropdown = (props: DropdownProps) => {
  const {
    children,
    label,
    isShowBackButton,
    isShowCloseButton,
    onBack,
    overlay,
    visible,
    onVisibleChange,
    placement = 'bottom left',
    onClose,
    isDisabled,
    hasNoHeader,
  } = props;

  let { asPseudoButton = false } = props;

  const headerProps = {
    label,
    isShowBackButton,
    isShowCloseButton,
    onBack,
  };

  const [portalPopoverStyles, setPortalPopoverStyles] = useState<BoxStyleProps>({
    position: 'fixed',
    top: 0,
    left: 0,
  });

  const ref = useRef(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const state = useMenuTriggerState({ ...props });
  const { isOpen, close, setOpen } = state;
  const { menuTriggerProps } = useMenuTrigger({ ...props }, state, ref);

  const { buttonProps } = useButton(
    {
      ...menuTriggerProps,
      elementType: 'div',
    },
    ref,
  );

  const { styleProps } = useStyleProps(props);

  const handleClose = () => {
    close();
    onClose?.();
  };

  if (isDisabled) {
    asPseudoButton = true;
  }

  useEffect(() => {
    setOpen(!!visible);
  }, [visible]);

  useEffect(() => {
    onVisibleChange?.(isOpen);
  }, [isOpen]);

  useLayoutEffect(() => {
    if (isOpen) {
      const triggerPosition = ref.current.getBoundingClientRect();
      setPortalPopoverStyles(getOverlayStyles({ placement, triggerPosition, offset: { top: 5, bottom: 5 } }));
    }
  }, [isOpen]);

  useLayoutEffect(
    function adjustOverlayPosition() {
      const popoverPosition = popoverRef.current?.getBoundingClientRect();
      if (popoverPosition) {
        popoverRef.current.style.transform = transformOutOfViewContent(popoverPosition, { bottom: 10 });
      }
      popoverRef.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [portalPopoverStyles],
  );

  return (
    <div className={css(styles.container)()}>
      <div className={css(styles.wrapper)()} ref={asPseudoButton ? null : ref}>
        {asPseudoButton && (
          <div
            className={compose(
              css(styles.trigger),
              styles.triggerVariants({
                isDisabled,
              }),
              css(styleProps),
            )}
            ref={ref}
            {...buttonProps}
          ></div>
        )}
        <ButtonProvider {...menuTriggerProps}>{children}</ButtonProvider>
      </div>
      {isOpen && (
        <OverlayContainer>
          <Popover
            {...styleProps}
            {...styles.dropdownPopover}
            {...portalPopoverStyles}
            isOpen={state.isOpen}
            onClose={handleClose}
            popoverRef={popoverRef}
          >
            {hasNoHeader ? '' : <DropdownHeader {...headerProps} onClose={handleClose} />}
            {overlay}
          </Popover>
        </OverlayContainer>
      )}
    </div>
  );
};
