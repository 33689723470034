import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const WifiSlash = forwardRef(function WifiSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53 2.47a.75.75 0 0 0-1.06 1.06L5.63 6.692c-1.71.82-3.237 1.96-4.504 3.34a.75.75 0 1 0 1.105 1.015 13.284 13.284 0 0 1 4.532-3.222l1.94 1.94a10.745 10.745 0 0 0-4.072 2.407.75.75 0 0 0 1.029 1.092 9.228 9.228 0 0 1 4.262-2.28l2.27 2.269a6.726 6.726 0 0 0-4.57 1.61.75.75 0 0 0 .972 1.14A5.226 5.226 0 0 1 12 14.75c.766 0 1.492.164 2.147.457l6.323 6.323a.75.75 0 0 0 1.06-1.06l-6.404-6.405a.743.743 0 0 0-.025-.025l-4.395-4.395a.634.634 0 0 0-.015-.015L7.484 6.424A.746.746 0 0 0 7.46 6.4L3.53 2.47ZM12 6.75c-.643 0-1.276.046-1.894.134A.75.75 0 1 1 9.894 5.4c.688-.098 1.391-.149 2.106-.149 4.304 0 8.178 1.844 10.873 4.783a.75.75 0 1 1-1.106 1.014A13.213 13.213 0 0 0 12 6.75Zm1.265 3.3a.75.75 0 0 1 .884-.585 10.724 10.724 0 0 1 5.218 2.707.75.75 0 0 1-1.028 1.092 9.224 9.224 0 0 0-4.488-2.329.75.75 0 0 1-.586-.884ZM12 19a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
