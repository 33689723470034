import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import React from 'react';

export function EmailConflictSection() {
  return (
    <div className="flex-row p-x-large">
      <div className="mr-medium">
        <Icon type="close-circle" theme={'filled'} style={{ fontSize: '40px' }} className="text-color-red" />
      </div>
      <div style={{ maxWidth: '536px' }}>
        <div className="mb-x-large">
          <Text size="x2-large">Email matches an existing user!</Text>
        </div>
        <Text>
          The email you have entered for this user is the same as the email for an existing user in your platform. Two
          users cannot have the same email address. <br /> <br />
          Please check the email address you have entered and try again. <br /> <br />
          {/*<br />*/}
          {/*Visit the <HyperlinkButton>Help Center</HyperlinkButton> to learn more.*/}
        </Text>
      </div>
    </div>
  );
}
