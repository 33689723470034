import React from 'react';
import { Heading } from '@good/components';
import { useParams } from 'react-router-dom';
import { ServiceAgreementServiceBreadCrumbs } from '../components/service-agreement-service-bread-crumbs';
import { useFetchServiceAgreementService } from 'stores/hooks/query-hooks/use-query-fetch-service-agreement-service';
import { Loader } from '../components/loader';
import { reactTrpc } from 'utilities/react-trpc';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import { Tab, TabList, TabPanel, Tabs } from '@good/components/src/tabs';
import { BillingHistoryTable } from '../components/billing-history-table';
import { useTranslation } from 'react-i18next';
import { ServiceLanding } from '../components/service-landing';

export const ServiceAgreementService = () => {
  const { serviceAgreementServiceId } = useParams<{
    serviceAgreementServiceId: string;
    customerId: string;
  }>();

  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  const { t } = useTranslation('', { keyPrefix: 'serviceAgreements.service' });
  const {
    data: serviceAgreementServiceDetails,
    isLoading,
    isError,
  } = useFetchServiceAgreementService({
    serviceAgreementServiceId,
  });
  const { data: billingSubscription } = reactTrpc.billingSubscriptions.fetch.useQuery({
    serviceProviderId: portalUser?.serviceProviderId ?? '',
    userServiceAgreementServiceId: serviceAgreementServiceId,
  });

  if (isLoading) return <Loader />;
  if (isError) throw new Error(`Failed to fetch Service agreements service for: ${serviceAgreementServiceId}`);

  const { serviceAgreementDetails, userDetails, serviceProviderId } = serviceAgreementServiceDetails;

  return (
    <div className='flex-column row-gap-x-large'>
      <div className='flex-column pl-x-large pr-x-large pt-small pb-large relative flex' style={{ gap: 24 }}>
        <ServiceAgreementServiceBreadCrumbs userId={userDetails.userId} displayName={userDetails.displayName} />

        <div className='flex-column mb-small flex' style={{ gap: 4 }}>
          <Heading size='xl'>{serviceAgreementDetails.serviceName}</Heading>
        </div>

        <Tabs>
          <TabList>
            <Tab id='SERVICE_AGREEMENT'>{t('labels.serviceAgreement')}</Tab>
            <Tab id='BILLING_HISTORY'>{t('labels.billingHistory')}</Tab>
          </TabList>
          <TabPanel id='SERVICE_AGREEMENT'>
            <ServiceLanding
              userDetails={userDetails}
              serviceAgreementDetails={serviceAgreementDetails}
              serviceProviderId={serviceProviderId}
            />
          </TabPanel>
          <TabPanel id='BILLING_HISTORY'>
            <div className='flex-column pl-x-large pr-x-large pt-small pb-large relative flex' style={{ gap: 24 }}>
              <BillingHistoryTable billingSubscription={billingSubscription} />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};
