import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const PlaySlowmotion = forwardRef(function PlaySlowmotion(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.074 2.796a9.25 9.25 0 1 1 0 18.409.75.75 0 0 0-.148 1.492c.353.035.712.053 1.074.053 5.937 0 10.75-4.813 10.75-10.75S17.937 1.25 12 1.25c-.362 0-.72.018-1.074.053a.75.75 0 0 0 .148 1.493ZM8.3 3.519a.75.75 0 0 0-.6-1.374c-.768.335-1.489.758-2.15 1.255a.75.75 0 0 0 .9 1.199 9.242 9.242 0 0 1 1.85-1.08ZM4.6 6.45a.75.75 0 1 0-1.2-.9c-.496.661-.919 1.382-1.254 2.15a.75.75 0 1 0 1.374.6c.289-.66.652-1.28 1.08-1.85Zm-1.803 4.624a.75.75 0 1 0-1.493-.148 10.868 10.868 0 0 0 0 2.148.75.75 0 1 0 1.493-.148 9.368 9.368 0 0 1 0-1.852Zm.723 4.626a.75.75 0 0 0-1.374.6A10.74 10.74 0 0 0 3.4 18.45a.75.75 0 0 0 1.199-.9 9.24 9.24 0 0 1-1.08-1.85Zm2.93 3.7a.75.75 0 1 0-.9 1.2c.661.497 1.382.92 2.15 1.255a.75.75 0 0 0 .601-1.374 9.24 9.24 0 0 1-1.85-1.08Zm7.076-9.951-.049-.033c-.642-.428-1.194-.796-1.652-1.012-.471-.223-1.063-.393-1.65-.078-.587.314-.774.9-.85 1.415-.074.502-.074 1.165-.074 1.937v.645c0 .771 0 1.434.074 1.936.076.515.263 1.101.85 1.415.587.315 1.179.145 1.65-.077.459-.217 1.01-.585 1.652-1.013l.05-.033.394-.263.046-.03c.525-.35.987-.658 1.31-.951.345-.311.671-.726.671-1.307s-.326-.996-.67-1.307c-.324-.293-.786-.6-1.31-.95l-.047-.031-.395-.263Zm-2.67.207s.004-.002.014-.002l-.013.002Zm.328.104a1.049 1.049 0 0 0-.297-.105c-.02.037-.051.124-.078.306-.056.378-.058.928-.058 1.776v.526c0 .848.002 1.398.058 1.776.027.182.059.269.078.306.042-.005.131-.026.297-.105.346-.163.805-.467 1.51-.937l.395-.263c.585-.39.952-.637 1.183-.846a.88.88 0 0 0 .17-.189l.004-.005-.003-.005a.88.88 0 0 0-.17-.19c-.232-.208-.6-.455-1.184-.845l-.395-.263c-.705-.47-1.164-.774-1.51-.937Zm-.327 4.584.013.002c-.01 0-.014-.001-.013-.002Zm.039.016c.006.005.008.009.008.01 0 0-.003-.002-.008-.01Zm0-4.72c.005-.008.008-.01.008-.01 0 .001-.002.005-.008.01Z"
        fill="currentColor"
      />
    </Icon>
  );
});
