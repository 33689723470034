import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Schema } from '../schema';
import fields from '../fields';

function useFocusAndResetRecurringBookingNumber(form: UseFormReturn<Schema>) {
  const { watch, resetField, setFocus } = form;

  const selectedDeleteRecurringBookingOption = watch(fields.cancelRecurringBooking.name);
  const numberOfRecurringBookingsToDelete = watch(fields.numberOfRecurringBookingsToDelete.name);

  useEffect(() => {
    if (selectedDeleteRecurringBookingOption === fields.cancelRecurringBooking.options.specified) {
      setFocus(fields.numberOfRecurringBookingsToDelete.name);
    } else {
      resetField(fields.numberOfRecurringBookingsToDelete.name, {
        defaultValue: fields.numberOfRecurringBookingsToDelete.defaultValue,
      });
    }
  }, [numberOfRecurringBookingsToDelete, selectedDeleteRecurringBookingOption]);
}

export default useFocusAndResetRecurringBookingNumber;
