import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import _ from 'lodash';
import { ActivityGroupMemberType, ActivityGroupModalType } from 'utilities/enum-utils';
import { IGroupServiceActivityGroup } from 'src/interfaces/service-interfaces';
import { IActivityGroupUsers } from 'src/interfaces/user-interfaces';
import { Skeleton } from 'antd';
import ActivityGroupsModal from 'common-components/activity-groups/modals/ActivityGroupsModal';
import ActivityGroupListing from './components/ActivityGroupListing';
import EmptyState from './components/EmptyState';

export type openModalFuncType = (
  activityGroup: IGroupServiceActivityGroup,
  modalType: ActivityGroupModalType,
  memberType?: ActivityGroupMemberType,
  member?: IActivityGroupUsers,
) => void;

interface ISessionActivityGroupsPanelProps {
  selectedSession: typeof state.groupServiceStore.selectedSession;
  sessionActivityGroups: typeof state.groupServiceStore.sessionActivityGroups;
  sessionUnassignedMembers: typeof state.groupServiceStore.sessionUnassignedMembers;
  doFetchSessionActivityGroups: typeof dispatch.groupServiceStore.doFetchSessionActivityGroups;
  doCreateSessionActivityGroup: typeof dispatch.groupServiceStore.doCreateSessionActivityGroup;
  doEditSessionActivityGroup: typeof dispatch.groupServiceStore.doEditSessionActivityGroup;
  doDuplicateSessionActivityGroup: typeof dispatch.groupServiceStore.doDuplicateSessionActivityGroup;
  doDeleteSessionActivityGroup: typeof dispatch.groupServiceStore.doDeleteSessionActivityGroup;
  doEditCustomerOfASessionActivityGroup: typeof dispatch.groupServiceStore.doEditCustomerOfASessionActivityGroup;
  doEditTeamMembersOfASessionActivityGroup: typeof dispatch.groupServiceStore.doEditTeamMembersOfASessionActivityGroup;
  doEditMembersOfASessionActivityGroup: typeof dispatch.groupServiceStore.doEditMembersOfASessionActivityGroup;
  doRemoveSpecificMemberOfASessionActivityGroup: typeof dispatch.groupServiceStore.doRemoveSpecificMemberOfASessionActivityGroup;
  doRemoveAllMembersFromSessionActivityGroup: typeof dispatch.groupServiceStore.doRemoveAllMembersFromSessionActivityGroup;
  doAssignSpecificMemberToSessionActivityGroups: typeof dispatch.groupServiceStore.doAssignSpecificMemberToSessionActivityGroups;
}

interface ISessionActivityGroupsPanelState {
  currentActivityGroup: IGroupServiceActivityGroup;
  currentModalType: ActivityGroupModalType;
  currentMember: IActivityGroupUsers;
  currentMemberType: ActivityGroupMemberType;
  isLoading: boolean;
  isModalOpen: boolean;
}

class SessionActivityGroupsPanel extends Component<ISessionActivityGroupsPanelProps, ISessionActivityGroupsPanelState> {
  state = {
    currentActivityGroup: null,
    currentModalType: ActivityGroupModalType.CREATE,
    currentMember: null,
    currentMemberType: ActivityGroupMemberType.CUSTOMER,
    isLoading: false,
    isModalOpen: false,
  };

  private _loadActivityGroups = async () => {
    const { selectedSession } = this.props;
    this.setState({ isLoading: true });
    await this.props.doFetchSessionActivityGroups({
      serviceId: selectedSession.serviceId,
      serviceDateTimeId: selectedSession.serviceDateTimeId,
    });
    this.setState({ isLoading: false });
  };

  private _openModal = (
    activityGroup: IGroupServiceActivityGroup,
    modalType: ActivityGroupModalType,
    memberType: ActivityGroupMemberType = ActivityGroupMemberType.CUSTOMER,
    member: IActivityGroupUsers = null,
  ) => {
    this.setState({
      currentActivityGroup: activityGroup,
      currentModalType: modalType,
      isModalOpen: true,
      currentMember: member,
      currentMemberType: memberType,
    });
  };

  private _handleModalAction = async (payload, actionType: ActivityGroupModalType) => {
    const {
      selectedSession,
      doFetchSessionActivityGroups,
      doCreateSessionActivityGroup,
      doEditSessionActivityGroup,
      doEditCustomerOfASessionActivityGroup,
      doEditTeamMembersOfASessionActivityGroup,
      doEditMembersOfASessionActivityGroup,
      doDuplicateSessionActivityGroup,
      doDeleteSessionActivityGroup,
      doRemoveSpecificMemberOfASessionActivityGroup,
      doRemoveAllMembersFromSessionActivityGroup,
      doAssignSpecificMemberToSessionActivityGroups,
    } = this.props;
    const { serviceId, serviceDateTimeId } = selectedSession;
    const { currentActivityGroup } = this.state;
    const prePayload = {
      serviceId,
      serviceDateTimeId,
      serviceDateTimeActivityGroupId: currentActivityGroup ? currentActivityGroup.serviceDateTimeActivityGroupId : null,
      ...payload,
    };

    let result = true;
    switch (actionType) {
      case ActivityGroupModalType.CREATE:
        await doCreateSessionActivityGroup(prePayload);
        break;
      case ActivityGroupModalType.EDIT:
        await doEditSessionActivityGroup(prePayload);
        break;
      case ActivityGroupModalType.DUPLICATE:
        await doDuplicateSessionActivityGroup(prePayload);
        break;
      case ActivityGroupModalType.REMOVE:
        await doRemoveSpecificMemberOfASessionActivityGroup(prePayload);
        break;
      case ActivityGroupModalType.DELETE:
        await doDeleteSessionActivityGroup(prePayload);
        break;
      case ActivityGroupModalType.REMOVE_ALL:
        await doRemoveAllMembersFromSessionActivityGroup(prePayload);
        break;
      case ActivityGroupModalType.ADD_CUSTOMER:
        result = !!(await doEditCustomerOfASessionActivityGroup(prePayload));
        break;
      case ActivityGroupModalType.ADD_TEAM_MEMBER:
        result = !!(await doEditTeamMembersOfASessionActivityGroup(prePayload));
        break;
      case ActivityGroupModalType.ADD_BOTH:
        result = !!(await doEditMembersOfASessionActivityGroup(prePayload));
        break;
      case ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP:
      case ActivityGroupModalType.ASSIGN_TEAM_MEMBER_TO_ACTIVITY_GROUP:
        await doAssignSpecificMemberToSessionActivityGroups(prePayload);
        break;
    }

    await doFetchSessionActivityGroups({ serviceId, serviceDateTimeId });
    return result;
  };

  componentDidMount = async () => {
    await this._loadActivityGroups();
  };

  render() {
    const { selectedSession, sessionActivityGroups, sessionUnassignedMembers } = this.props;
    const { currentActivityGroup, currentModalType, currentMember, currentMemberType, isModalOpen, isLoading } =
      this.state;

    return (
      <div className="pt-large pl-large width-full pb-x3-large" style={{ maxWidth: '1000px' }}>
        <div className="bg-white ph-large pt-large pb-x2-large shadow-container rounded-big">
          {isLoading ? (
            <Skeleton active />
          ) : _.isEmpty(sessionActivityGroups) ? (
            <EmptyState openModal={this._openModal} />
          ) : (
            <ActivityGroupListing
              activityGroups={sessionActivityGroups}
              unAssignedMembers={sessionUnassignedMembers}
              openModal={this._openModal}
              timezone={selectedSession.timezone}
            />
          )}
        </div>
        {isModalOpen && (
          <ActivityGroupsModal
            serviceId={selectedSession.serviceId}
            activityGroup={currentActivityGroup}
            modalType={currentModalType}
            member={currentMember}
            memberType={currentMemberType}
            isOpen={isModalOpen}
            selectedUser={currentMember}
            handleAction={this._handleModalAction}
            onCloseViewModal={() => {
              this.setState({ isModalOpen: false });
            }}
            session={selectedSession}
          />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  sessionActivityGroups: state.groupServiceStore.sessionActivityGroups,
  sessionUnassignedMembers: state.groupServiceStore.sessionUnassignedMembers,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSessionActivityGroups: dispatch.groupServiceStore.doFetchSessionActivityGroups,
  doCreateSessionActivityGroup: dispatch.groupServiceStore.doCreateSessionActivityGroup,
  doEditSessionActivityGroup: dispatch.groupServiceStore.doEditSessionActivityGroup,
  doEditCustomerOfASessionActivityGroup: dispatch.groupServiceStore.doEditCustomerOfASessionActivityGroup,
  doEditTeamMembersOfASessionActivityGroup: dispatch.groupServiceStore.doEditTeamMembersOfASessionActivityGroup,
  doEditMembersOfASessionActivityGroup: dispatch.groupServiceStore.doEditMembersOfASessionActivityGroup,
  doDuplicateSessionActivityGroup: dispatch.groupServiceStore.doDuplicateSessionActivityGroup,
  doDeleteSessionActivityGroup: dispatch.groupServiceStore.doDeleteSessionActivityGroup,
  doRemoveAllMembersFromSessionActivityGroup: dispatch.groupServiceStore.doRemoveAllMembersFromSessionActivityGroup,
  doRemoveSpecificMemberOfASessionActivityGroup:
    dispatch.groupServiceStore.doRemoveSpecificMemberOfASessionActivityGroup,
  doAssignSpecificMemberToSessionActivityGroups:
    dispatch.groupServiceStore.doAssignSpecificMemberToSessionActivityGroups,
});

export default connect(mapState, mapDispatch)(SessionActivityGroupsPanel);
