import { AriaRadioGroupProps, useRadioGroup } from '@react-aria/radio';
import { useRadioGroupState } from '@react-stately/radio';
import React, { PropsWithChildren } from 'react';
import { BoxStyleProps, boxStyleProps, css, HTMLProps, useStyleProps } from '..';
import { RadioProvider } from './radio-context';

export type RadioGroupProps = PropsWithChildren<
  BoxStyleProps & Omit<AriaRadioGroupProps, 'value'> & HTMLProps<HTMLInputElement>
> & {
  /**
   * The current value (controlled)
   */
  value?: string | number;
  /**
   * Text label of group radio
   */
  label?: string;
  /**
   * Whether style of selected radio render as check mark
   */
  isCheckMark?: boolean;
};

/**
 * @name
 * RadioGroup
 *
 * @description
 * The RadioGroup element include multiple Radio
 *
 *
 * @example
 * <RadioGroup>
 *      <Radio size="medium" emphasis="bold" label="Female" value="female" support="Support" />
 *      <Radio size="small" label="Male" value="male" />
 * </RadioGroup>
 */

// eslint-disable-next-line react/display-name
export const RadioGroup = (props: RadioGroupProps) => {
  const { children, label, isCheckMark = false } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps } = useRadioGroup(props, state);
  const { styleProps } = useStyleProps(props, boxStyleProps);

  return (
    <div {...radioGroupProps} className={css(styleProps)()}>
      <span {...labelProps}>{label}</span>
      <RadioProvider state={state} isCheckMark={isCheckMark}>
        {children}
      </RadioProvider>
    </div>
  );
};
