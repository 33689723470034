import { Box, Inline, Text } from '@goodhuman-me/components';
import { Select } from 'design-components';
import React, { useEffect, useState } from 'react';
import { ServicePermissionType, UserPermissionRole } from 'utilities/enum-utils';
import { IService, PermissionRole } from 'views/team-v2/utils/form-utils';
import LabelTagStatus from './LabelTagStatus';

interface IProps {
  service: IService;
  serviceDepartmentId: string;
  setPermissionRolesDepartmentLiteItem: (permissionRole: PermissionRole) => void;
  permissionRolesDepartment: PermissionRole[];
}

const ServiceDepartmentDetailsLiteItem: React.FC<IProps> = ({
  service,
  serviceDepartmentId,
  setPermissionRolesDepartmentLiteItem,
  permissionRolesDepartment,
}) => {
  const [selectedPermission, setSelectedPermission] = useState<string>(ServicePermissionType.NO_ACCESS);

  const handleSelectPermission = (selectedPermission: string) => {
    setSelectedPermission(selectedPermission);
    let permissionRole = UserPermissionRole.NoAccess;
    if (selectedPermission === ServicePermissionType.MEMBER) {
      permissionRole = UserPermissionRole.ServiceMember;
    }
    if (selectedPermission === ServicePermissionType.MANAGER) {
      permissionRole = UserPermissionRole.ServiceAdmin;
    }
    setPermissionRolesDepartmentLiteItem({
      serviceDepartmentId,
      serviceId: service.serviceId,
      permissionRole,
    });
  };

  useEffect(() => {
    if (permissionRolesDepartment.length) {
      const role = permissionRolesDepartment.find(
        (one) => serviceDepartmentId === one.serviceDepartmentId && one.serviceId === service.serviceId,
      );
      if (role?.permissionRole === UserPermissionRole.ServiceMember) {
        setSelectedPermission(ServicePermissionType.MEMBER);
      }
      if (role?.permissionRole === UserPermissionRole.ServiceAdmin) {
        setSelectedPermission(ServicePermissionType.MANAGER);
      }
    }
  }, [permissionRolesDepartment]);

  return (
    <Inline alignItems="center" paddingTop="$medium !important" paddingBottom="$medium !important">
      <Box width="$size2400">
        <Text color="$black" fontWeight="$normal">
          {service.serviceName}
        </Text>
      </Box>
      <Box width="$size2400">
        <LabelTagStatus status={service.status} />
      </Box>
      <Box width="$size3400">
        <Select
          borderColor="$transparent !important"
          boxShadow="none !important"
          overlayStyles={{ borderRadius: '8px' }}
          selectedKey={selectedPermission}
          onSelectionChange={handleSelectPermission}
        >
          <Select.Item key={ServicePermissionType.NO_ACCESS}>{ServicePermissionType.NO_ACCESS}</Select.Item>
          <Select.Item key={ServicePermissionType.MEMBER}>{ServicePermissionType.MEMBER}</Select.Item>
          <Select.Item key={ServicePermissionType.MANAGER}>{ServicePermissionType.MANAGER}</Select.Item>
        </Select>
      </Box>
    </Inline>
  );
};

export default ServiceDepartmentDetailsLiteItem;
