import React from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Text } from 'common-components/typography';
import { IconButton, PrimaryButton } from 'common-components/buttons';

import _ from 'lodash';
import moment from 'moment-timezone';

import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { Popover } from '@blueprintjs/core';
import EditInstructionsModal from 'views/group-services/manage-booking-modal/panels/instructions/EditInstructionsModal';
import DeleteInstructionsModal from 'views/group-services/manage-booking-modal/panels/instructions/DeleteInstructionsModal';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../../stores/rematch/root-store';
import { ModalLoading } from 'views/group-services/manage-booking-modal/common-components/ModalLoading';

interface IInstructionsPanelProps {
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  isLoading: boolean;
}

interface IInstructionsPanelState {
  isEditInstructionsModalOpen: boolean;
  isDeleteInstructionsModalOpen: boolean;
  editMode: string;
  selectedInstruction: any;
}

class InstructionsPanel extends React.Component<IInstructionsPanelProps, IInstructionsPanelState> {
  state = {
    isEditInstructionsModalOpen: false,
    isDeleteInstructionsModalOpen: false,
    editMode: 'add',
    isLoading: false,
    selectedInstruction: null,
  };

  //region Action handlers
  onAddNote = () => {
    this.openEditModal({ editMode: 'add' });
  };
  onEditNote = (instruction) => {
    this.setState({ selectedInstruction: instruction });
    this.openEditModal({ editMode: 'edit' });
  };
  onDeleteNote = (instruction) => {
    this.setState({ selectedInstruction: instruction });
    this.openDeleteModal();
  };
  //endregion

  //region Modal handlers
  openEditModal = ({ editMode }) => this.setState({ isEditInstructionsModalOpen: true, editMode });
  closeEditModal = () => this.setState({ isEditInstructionsModalOpen: false, selectedInstruction: null });
  openDeleteModal = () => this.setState({ isDeleteInstructionsModalOpen: true });
  closeDeleteModal = () => this.setState({ isDeleteInstructionsModalOpen: false, selectedInstruction: null });
  //endregion

  render() {
    const { selectedGroupBookingItem, isLoading } = this.props;

    // Loading
    if (isLoading) {
      return <ModalLoading text={'Fetching instructions...'} />;
    }

    const { instructions } = selectedGroupBookingItem;

    const hasInstructions = !_.isEmpty(instructions); // replace with actual condition.

    return (
      <>
        {/* Main panel */}
        <div className="anim-fade-in-fast mb-x3-large">
          {/* Add instructions button. */}
          {/* The following section should only show if there are no instructions. */}
          {/*{!hasInstructions && (*/}
          {/*  <section className="mb-large">*/}
          {/*    <PrimaryButton icon="plus" size="large">*/}
          {/*      Add instructions*/}
          {/*    </PrimaryButton>*/}
          {/*  </section>*/}
          {/*)}*/}

          {/* Instructions */}
          <section className="p-large bordered border-standard-gray rounded-big">
            <Row>
              {this.state.isLoading ? (
                <div>
                  <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                </div>
              ) : (
                <>
                  {!hasInstructions && (
                    <Col span={20}>
                      {/* Empty instructions. */}
                      <div>
                        <Text color="secondary">No instructions found for this booking.</Text>
                        <br />

                        <PrimaryButton icon="plus" size="regular" className="mt-large" onClick={this.onAddNote}>
                          Add instructions
                        </PrimaryButton>
                      </div>
                    </Col>
                  )}
                  {/* Has instructions */}
                  {hasInstructions &&
                    _.map(instructions, (instruction) => {
                      return (
                        <>
                          <Row className="mt-large ">
                            <Col span={20}>
                              <div className="line-height-135">
                                <Text lineHeight={135} className="whitespace-pre-line">
                                  {instruction.instruction}
                                </Text>
                              </div>
                              <div className="line-height-135 mt-medium">
                                <Text size="regular" color="secondary" lineHeight={135}>
                                  Created by{' '}
                                  <b>
                                    {instruction.createdByFirstName} {instruction.createdByLastName}
                                  </b>{' '}
                                  on{' '}
                                  {moment
                                    .tz(instruction.createdOn, selectedGroupBookingItem.timezone)
                                    .format('DD/MM/YYYY h:mm A')}
                                </Text>
                                <br />

                                {!_.isEmpty(instruction.updatedByFirstName) && (
                                  <Text size="regular" color="secondary" lineHeight={135}>
                                    Edited by{' '}
                                    <b>
                                      {instruction.updatedByFirstName} {instruction.updatedByLastName}
                                    </b>{' '}
                                    on{' '}
                                    {moment
                                      .tz(instruction.updatedOn, selectedGroupBookingItem.timezone)
                                      .format('DD/MM/YYYY h:mm A')}
                                  </Text>
                                )}
                              </div>
                            </Col>
                            <Col span={4}>
                              <div className="text-align-right">
                                <Popover
                                  content={
                                    <ActionMenu>
                                      <ActionMenuItem
                                        text={'Edit instructions'}
                                        onClick={() => this.onEditNote(instruction)}
                                      />
                                      <ActionMenuItem
                                        text={'Delete instructions'}
                                        onClick={() => this.onDeleteNote(instruction)}
                                      />
                                    </ActionMenu>
                                  }
                                  position={'bottom-right'}
                                  usePortal={false}
                                >
                                  <IconButton
                                    icon={'ellipsis'}
                                    size="default"
                                    iconColor={'blue-action'}
                                    color={'white'}
                                    bordered={true}
                                    className="border-standard-gray"
                                  />
                                </Popover>
                              </div>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                </>
              )}
            </Row>
          </section>
        </div>

        {/* Modals */}
        <EditInstructionsModal
          isOpen={this.state.isEditInstructionsModalOpen}
          onClose={this.closeEditModal}
          instructions={this.state.selectedInstruction}
          mode={this.state.editMode}
        />

        <DeleteInstructionsModal
          isOpen={this.state.isDeleteInstructionsModalOpen}
          onClose={this.closeDeleteModal}
          instructions={this.state.selectedInstruction}
        />
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({});

export default connect(mapState, mapDispatch)(InstructionsPanel);
