import React, { forwardRef } from 'react';

import { Icon } from '../icon';

import type { IconProps } from '../icon';

export const Bin = forwardRef<SVGSVGElement, IconProps>(function Bin(props, ref) {
  return (
    <Icon {...props} ref={ref}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="M216 56H40M104 104v64M152 104v64M200 56v152a8 8 0 0 1-8 8H64a8 8 0 0 1-8-8V56M168 56V40a16 16 0 0 0-16-16h-48a16 16 0 0 0-16 16v16"
      />
    </Icon>
  );
});
