import { Avatar, Collapse, Divider, Row } from 'antd';
import { PrimaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { IWorkflowStep } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { getIndividualMembers, getRoleDetail } from 'views/workflows/utils/workflow-utils';
import GhostButton from '../../../../../../common-components/buttons/GhostButton';
import '../../../../css/workflow-styles.css';

interface Props {
  step?: IWorkflowStep;
  isOpen: boolean;
  editable: boolean;
  onClose(): void;
  onAddApprover(): void;
}

class CurrentApproversModal extends PureComponent<Props> {
  render() {
    const { step, isOpen, editable, onClose, onAddApprover } = this.props;

    if (!step) {
      return <></>;
    }

    const roleIds = _.uniq(_.map(step.approvers, 'role')).filter((one) => one !== null);
    const roleDetails = _.sortBy(
      roleIds.map((roleId) => getRoleDetail(roleId)),
      'name',
    );

    const individualMembers = getIndividualMembers(step.approvers);

    return (
      <ActionModal
        width="medium"
        isOpen={isOpen}
        onClose={onClose}
        title="Current approvers"
        verticalAlignment="highest"
      >
        <Paragraph>The following users are assigned as the approvers of this step.</Paragraph>

        <Collapse className="mv-small" bordered={false}>
          {roleDetails.map((role) => {
            const roleMembers = step.approvers
              .filter((one) => one.role === role.id && one.userId)
              .sort((a, b) => a.name.localeCompare(b.name));

            return (
              <Collapse.Panel
                header={
                  <Text weight="bold" size="large">
                    {`${role.name} (${roleMembers.length})`}
                  </Text>
                }
                className="bordered-none"
                key={role.id}
              >
                {roleMembers.map((one) => (
                  <div key={one.userId}>
                    <Row type="flex" align="middle" className="pt-12 ph-12">
                      <Avatar src={one.avatar} icon="user" shape="square" size="large" className="mr-small" />
                      <Text className="flex-1">{one.name}</Text>
                    </Row>

                    <Divider className="mt-12 ml-x5-large mr-none mb-none" />
                  </div>
                ))}
              </Collapse.Panel>
            );
          })}

          {!!individualMembers.length && (
            <Collapse.Panel
              header={
                <Text weight="bold" size="large">
                  {`Individual approvers (${individualMembers.length})`}
                </Text>
              }
              className="bordered-none"
              key={'individual'}
            >
              {individualMembers.map((one) => (
                <div key={one.userId}>
                  <Row type="flex" align="middle" className="pt-12 ph-12">
                    <Avatar src={one.avatar} icon="user" shape="square" size="large" className="mr-small" />
                    <Text className="flex-1">{one.name}</Text>
                  </Row>

                  <Divider className="mt-12 ml-x5-large mr-none mb-none" />
                </div>
              ))}
            </Collapse.Panel>
          )}
        </Collapse>
        {editable && (
          <GhostButton icon="plus" onClick={onAddApprover}>
            Add approvers
          </GhostButton>
        )}

        <div className="mt-x2-large flex justify-end">
          <PrimaryButton size="large" onClick={onClose}>
            Close
          </PrimaryButton>
        </div>
      </ActionModal>
    );
  }
}

export default CurrentApproversModal;
