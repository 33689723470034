import React from 'react';
import { useController } from 'react-hook-form';
import { useField } from '@react-aria/label';

export function HiddenField(props) {
  let { style = {} } = props;

  let { fieldProps, errorMessageProps = {} } = useField(props);

  return (
    <div style={{ display: props.errorMessage ? 'flex' : 'none', flexDirection: 'column', gap: '4px', ...style }}>
      <input type="hidden" {...fieldProps} />
      {props.errorMessage && (
        <div {...errorMessageProps} style={{ color: '#C13232', fontSize: 14 }}>
          {props.errorMessage}
        </div>
      )}
    </div>
  );
}

export function ControlledHiddenField(props) {
  const {
    field: { name, value },
    fieldState: { error },
  } = useController({
    ...props,
  });

  let { message: errorMessage } = error || {};
  return <HiddenField {...props} value={value} name={name} errorMessage={errorMessage} />;
}
