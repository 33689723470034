import React, { useLayoutEffect, useRef, useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Button } from 'antd';
import { css } from '@goodhuman-me/components';
import type { CollapsibleProps } from '@radix-ui/react-collapsible';

const styles = css({
  '&[data-state="closed"]': {
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
  },
});

function useIsOverflow(value: string) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflow, setOverflow] = useState(false);

  useLayoutEffect(() => {
    const { current } = ref;
    if (!current) setOverflow(false);
    const hasOverflow = current.scrollHeight > current.clientHeight;
    setOverflow(hasOverflow);
  }, [value, ref]);

  return { isOverflow, ref };
}

export function CollapsibleText(props: { text: string } & CollapsibleProps) {
  const { text, ...otherProps } = props;
  const { isOverflow, ref } = useIsOverflow(text);

  return (
    <Collapsible.Root style={{ display: 'flex', flexDirection: 'column', gap: '8px' }} {...otherProps}>
      <Collapsible.Content className={styles()} ref={ref} forceMount>
        {text}
      </Collapsible.Content>

      {isOverflow && (
        <Collapsible.Trigger asChild>
          <Button className="text-size-regular" size="small" style={{ alignSelf: 'flex-end' }} type="link">
            Read more
          </Button>
        </Collapsible.Trigger>
      )}
    </Collapsible.Root>
  );
}
