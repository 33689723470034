import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Col, Row } from 'antd';

import CommonUtils from 'utilities/common-utils';
import { timeZone } from 'src/interfaces/timezone-type';

import ActionModal from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Text, SubTitle } from 'common-components/typography';
import TimeInput from 'common-components/time-input/TimeInput';

interface IEditShiftTimeOfAllSessionsModalProps {
  isOpen: boolean;
  onSave: (startTime, endTime) => void;
  onCancel: () => void;
  defaultStartTime: moment.Moment;
  defaultEndTime: moment.Moment;
  upcomingSession: number;
  recurringPattern: number;
  timezone: timeZone;
}

const EditShiftTimeOfAllSessionsModal = (props: IEditShiftTimeOfAllSessionsModalProps) => {
  const { isOpen, onSave, onCancel, defaultStartTime, defaultEndTime, upcomingSession, recurringPattern, timezone } =
    props;
  const [startTime, setStartTime] = useState<moment.Moment>(
    moment(moment.tz(defaultStartTime, timezone).format('YYYY-MM-DD HH:mm:ss')),
  );
  const [endTime, setEndTime] = useState<moment.Moment>(
    moment(moment.tz(defaultEndTime, timezone).format('YYYY-MM-DD HH:mm:ss')),
  );
  const [hasError, setHasError] = useState<boolean>(false);

  const handleStartTime = (value) => {
    setStartTime(CommonUtils.formatCeilingDateTime(value));
    if (endTime.isSameOrBefore(value)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  const handleEndTime = (value) => {
    setEndTime(CommonUtils.formatCeilingDateTime(value));
    if (startTime.isSameOrAfter(value)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  const handleSave = () => {
    const convertedStartTime = moment.tz(moment(startTime).tz(timezone, true).format(), timezone).toISOString();
    const convertedEndTime = moment.tz(moment(endTime).tz(timezone, true).format(), timezone).toISOString();
    onSave(convertedStartTime, convertedEndTime);
    onCancel();
  };

  return (
    <ActionModal isOpen={isOpen} title="Edit shift time" showCloseButton={true} onClose={onCancel} width="large">
      <Row className="bg-quaternary rounded-big p-medium mt-x-small mb-large">
        <Col span={10}>
          <SubTitle>SCHEDULE</SubTitle>
          <Text size="large">
            {CommonUtils.getRecurringPatternLabel(recurringPattern)}{' '}
            {moment.tz(defaultStartTime, timezone).format('dddd')},
          </Text>
          <div>
            <Text size="regular" color="secondary">
              {moment.tz(defaultStartTime, timezone).format('hh:mm A')} -{' '}
              {moment.tz(defaultEndTime, timezone).format('hh:mm A')}
            </Text>
          </div>
        </Col>
        <Col span={10}>
          <SubTitle>UPCOMING SESSIONS</SubTitle>
          <Text size="large">{upcomingSession} sessions</Text>
        </Col>
      </Row>

      <Text color="secondary" size="large" weight="bold">
        Shift time (Applied to all sessions in this schedule)
      </Text>
      <Row className="bordered p-large rounded-big mt-x-small">
        <Row>
          <Col span={12}>
            <SubTitle>START TIME</SubTitle>
            <TimeInput
              className={`${hasError ? 'border-red' : ''}`}
              size="large"
              onChange={handleStartTime}
              value={startTime}
            />
            {hasError && <Text color="red">Start time cannot be same or after end time</Text>}
          </Col>
          <Col span={12}>
            <SubTitle>END TIME</SubTitle>
            <TimeInput
              className={`${hasError ? 'border-red' : ''}`}
              size="large"
              onChange={handleEndTime}
              value={endTime}
            />
          </Col>
        </Row>
      </Row>

      <div className="flex justify-end mt-x3-large">
        <SecondaryButton size="large" className="mr-medium" onClick={onCancel}>
          Cancel
        </SecondaryButton>
        <PrimaryButton size="large" onClick={handleSave} disabled={hasError}>
          Save changes
        </PrimaryButton>
      </div>
    </ActionModal>
  );
};

export default React.memo(EditShiftTimeOfAllSessionsModal);
