import React from 'react';

import { Text } from 'common-components/typography';

interface IBulkApproveLeaveSuccess {
  itemCount: number;
}

function BulkApproveLeaveSuccess(props: IBulkApproveLeaveSuccess): JSX.Element {
  const { itemCount } = props;

  return (
    <div>
      <Text>
        You have approved leave shifts for{' '}
        <b>
          {itemCount} team member{itemCount === 1 ? '' : 's'}
        </b>
        .
      </Text>
    </div>
  );
}

export default BulkApproveLeaveSuccess;
