import React from 'react';
import _ from 'lodash';
import { Col, Row, Avatar, Empty } from 'antd';
import moment, { Moment } from 'moment-timezone';

import { SubTitle, Text } from 'common-components/typography';
import { ICustomerAttendance } from 'interfaces/session-interfaces';
import { IRosterWorker } from 'common-components/roster-control/interfaces/roster-interfaces';

interface IRosterCustomerListingProps {
  customers: ICustomerAttendance[];
  timezone: string;
}

const formatDuration = (startDateTime: Moment, endDateTime: Moment): string => {
  const duration = moment.duration(endDateTime.diff(startDateTime));

  return (
    duration
      .asHours()
      .toFixed(4)
      .replace(/\.?0+$/, '') + ' hrs'
  );
};

function RosterCustomerListing(props: IRosterCustomerListingProps) {
  const { customers, timezone } = props;
  const hasCustomerAssignees = _.some(customers, (customer) =>
    _.some(customer.attendanceTimeSlots, (timeSlot) => timeSlot.supportWorker),
  );

  const renderAssignedTo = (supportWorkers?: IRosterWorker[]) => {
    return (
      hasCustomerAssignees && (
        <Col span={6} className="pl-x-large">
          <Text size="regular" weight="regular" className="break-word">
            {supportWorkers &&
              supportWorkers.map((supportWorker, index) => (
                <>
                  {index !== 0 && ' & '}
                  {supportWorker.workerFirstName} {supportWorker.workerLastName}
                </>
              ))}
          </Text>
        </Col>
      )
    );
  };

  return (
    <table className="width-full">
      <thead>
        <tr className="page-break-inside-avoid">
          <div className="mb-medium">
            <Text size="x2-large" weight="bold">
              Customers <Text color="secondary">({customers.length})</Text>
            </Text>
          </div>
        </tr>
      </thead>
      <tbody>
        <tr className="page-break-inside-avoid">
          <Row className="flex align-center mb-medium">
            <Col span={6}>
              <div className="ml-medium">
                <SubTitle containerClassName="line-height-135">Customer</SubTitle>
              </div>
            </Col>
            <Col span={11} className="pl-medium">
              <div className="inline-flex">
                <SubTitle containerClassName="mr-large line-height-135">Start time</SubTitle>
                <SubTitle containerClassName="line-height-135">End time</SubTitle>
              </div>
            </Col>
            <Col span={1}>
              <SubTitle containerClassName="line-height-135">Ratio</SubTitle>
            </Col>
            {hasCustomerAssignees && (
              <Col span={6} className="pl-x-large">
                <SubTitle containerClassName="line-height-135">Assigned To</SubTitle>
              </Col>
            )}
          </Row>
        </tr>
        {_.isEmpty(customers) ? (
          <tr className="page-break-inside-avoid">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </tr>
        ) : (
          _.map(customers, (customer) => {
            const { userId, firstName, lastName, attachmentUrl, attendanceTimeSlots } = customer;
            const firstBooking = _.first(attendanceTimeSlots);
            const firstBookingStartTime = moment.tz(firstBooking.startDateTime, timezone);
            const firstBookingEndTime = moment.tz(firstBooking.endDateTime, timezone);
            const firstDuration = formatDuration(firstBookingStartTime, firstBookingEndTime);
            const supportWorkersOfFirstBooking = firstBooking.supportWorker;

            return (
              <tr key={userId}>
                <div className="bordered-top"></div>
                <Row className="flex align-center mv-medium page-break-inside-avoid">
                  <Col span={6}>
                    <div className="flex-row">
                      <div>
                        <Avatar size={32} src={attachmentUrl} shape="circle" />
                      </div>
                      <div className="m-auto ml-small">
                        <Text size="regular" weight="regular" className="break-word">
                          {firstName} {lastName}
                        </Text>
                      </div>
                    </div>
                  </Col>
                  <Col span={11} className="pl-medium">
                    <Text className="inline-block mr-medium" size="regular" style={{ width: '62px' }}>
                      {firstBookingStartTime.format('h:mm A')}
                    </Text>
                    <Text className="mr-small" weight="bold">
                      -
                    </Text>
                    <Text className="inline-block mr-small" size="regular" style={{ width: '62px' }}>
                      {firstBookingEndTime.format('h:mm A')}
                    </Text>
                    <Text size="regular" color="secondary">
                      ({firstDuration})
                    </Text>
                  </Col>
                  <Col span={1} className="text-align-center">
                    <Text size="regular" weight="regular">
                      {firstBooking.teamMemberCustomerRatio}
                    </Text>
                  </Col>
                  {renderAssignedTo(supportWorkersOfFirstBooking)}
                </Row>
                {_.map(attendanceTimeSlots, (timeSlot, index) => {
                  if (index === 0) return <></>;
                  const { attendanceTimeSlotId, startDateTime, endDateTime, teamMemberCustomerRatio } = timeSlot;
                  const bookingStartTime = moment.tz(startDateTime, timezone);
                  const bookingEndTime = moment.tz(endDateTime, timezone);
                  const duration = formatDuration(bookingStartTime, bookingEndTime);
                  const supportWorker = timeSlot.supportWorker;
                  return (
                    <Row
                      key={attendanceTimeSlotId}
                      className="flex align-center mv-medium page-break-inside-avoid"
                      style={{ minHeight: '32px' }}
                    >
                      <Col span={6}></Col>
                      <Col span={11} className="pl-medium">
                        <Text className="inline-block mr-medium" size="regular" style={{ width: '62px' }}>
                          {bookingStartTime.format('h:mm A')}
                        </Text>
                        <Text className="mr-small" weight="bold">
                          -
                        </Text>
                        <Text className="inline-block mr-small" size="regular" style={{ width: '62px' }}>
                          {bookingEndTime.format('h:mm A')}
                        </Text>
                        <Text size="regular" color="secondary">
                          ({duration})
                        </Text>
                      </Col>
                      <Col span={1} className="text-align-center">
                        <Text size="regular" weight="regular">
                          {teamMemberCustomerRatio}
                        </Text>
                      </Col>
                      {renderAssignedTo(supportWorker)}
                    </Row>
                  );
                })}
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
}

export default RosterCustomerListing;
