import {
  SchemaFieldNames,
  SchemaOptions,
  CancelledBy,
  CancelRecurringBooking,
  CustomerCancellationReason,
  CancellationFeeReason,
  TeamMemberPaymentDuration,
  CustomTeamMemberPaymentDurationUnit,
} from './schema';
import { UseControllerProps } from 'react-hook-form';

export interface IField<T extends SchemaFieldNames, U> {
  name: T;
  defaultValue: U;
  rules?: UseControllerProps['rules'] & ((val) => UseControllerProps['rules']);
}

export interface IFieldWithOptions<T extends SchemaFieldNames, U, V extends SchemaOptions> extends IField<T, U> {
  options: IFieldsOptions<V>;
  optionInterpolations?: (interpolations: Record<string, string>) => {
    [key in V]: Record<string, string>;
  };
}

export type IFieldsOptions<T extends SchemaOptions> = {
  [key in T]: T;
};

export interface IFields {
  cancelledBy: IFieldWithOptions<'cancelledBy', string | null, CancelledBy>;
  cancelRecurringBooking: IFieldWithOptions<'cancelRecurringBooking', string | null, CancelRecurringBooking>;
  customerCancellationReason: IFieldWithOptions<
    'customerCancellationReason',
    string | null,
    CustomerCancellationReason
  >;
  otherCancellationReason: IField<'otherCancellationReason', string | null>;
  numberOfRecurringBookingsToDelete: IField<'numberOfRecurringBookingsToDelete', number>;
  shouldChargeCancellationFee: IField<'shouldChargeCancellationFee', boolean>;
  cancellationFeeReason: IFieldWithOptions<'cancellationFeeReason', string | null, CancellationFeeReason>;
  shouldPayTeamMember: IField<'shouldPayTeamMember', boolean>;
  teamMemberPaymentDuration: IFieldWithOptions<'teamMemberPaymentDuration', string | null, TeamMemberPaymentDuration>;
  customTeamMemberPaymentDuration: IField<'customTeamMemberPaymentDuration', number>;
  customTeamMemberPaymentDurationUnit: IFieldWithOptions<
    'customTeamMemberPaymentDurationUnit',
    string,
    CustomTeamMemberPaymentDurationUnit
  >;
}

const fields: IFields = {
  cancelledBy: {
    name: 'cancelledBy',
    options: {
      customer: 'customer',
      business: 'business',
    },
    defaultValue: null,
    rules: {
      validate: (value) => (value ? true : 'Select cancellation option'),
    },
  },
  cancelRecurringBooking: {
    name: 'cancelRecurringBooking',
    options: {
      this: 'this',
      all: 'all',
      specified: 'specified',
    },
    defaultValue: '',
  },
  customerCancellationReason: {
    name: 'customerCancellationReason',
    options: { health: 'health', family: 'family', transport: 'transport', other: 'other' },
    defaultValue: null,
    rules: {
      validate: (value) => (value ? true : 'Select cancellation reason'),
    },
  },
  otherCancellationReason: {
    name: 'otherCancellationReason',
    defaultValue: '',

    rules: {
      validate: (value) => {
        if (!value || !value?.length) {
          return 'Type cancellation reason';
        }
        if (value.length < 5) {
          return 'Minimum of 5 characters';
        }
        return true;
      },
    },
  },
  numberOfRecurringBookingsToDelete: {
    name: 'numberOfRecurringBookingsToDelete',
    defaultValue: 0,
    rules: (max) => ({
      validate: (value) => {
        if (value > max) {
          return `Cannot be more than remaining bookings`;
        }
        if (value && value > 0) {
          return true;
        }
        return false;
      },
    }),
  },
  shouldChargeCancellationFee: {
    name: 'shouldChargeCancellationFee',
    defaultValue: false,
  },
  cancellationFeeReason: {
    name: 'cancellationFeeReason',
    options: {
      noShow: 'noShow',
      aheadOfTime: 'aheadOfTime',
    },
    defaultValue: null,
    rules: {
      validate: (value) => (value ? true : 'Select cancellation fee option'),
    },
  },
  shouldPayTeamMember: {
    name: 'shouldPayTeamMember',
    defaultValue: false,
  },
  teamMemberPaymentDuration: {
    name: 'teamMemberPaymentDuration',
    options: {
      entireDuration: 'entireDuration',
      customDuration: 'customDuration',
    },
    optionInterpolations: (interpolations: { duration: string }) => ({
      entireDuration: {
        duration: interpolations.duration,
      },
      customDuration: {},
    }),
    defaultValue: 'entireDuration',
  },
  customTeamMemberPaymentDuration: {
    name: 'customTeamMemberPaymentDuration',
    defaultValue: 0,
    rules: (max) => ({
      validate: (value) => {
        if (value > max) {
          return `Cannot be longer than shift length`;
        }
        if (value && value > 0) {
          return true;
        }
        return false;
      },
    }),
  },
  customTeamMemberPaymentDurationUnit: {
    name: 'customTeamMemberPaymentDurationUnit',
    defaultValue: 'hours',
    options: {
      hours: 'hours',
      minutes: 'minutes',
    },
  },
};

export default fields;
