import React, { ForwardedRef, forwardRef, useState } from 'react';
import { EditorContent, EditorOptions } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Underline } from '@tiptap/extension-underline';
import { TextAlign } from '@tiptap/extension-text-align';
import { TextStyle } from '@tiptap/extension-text-style';
import TextSize from 'tiptap-extension-font-size';
import { Box, rem } from '@good/ui/core';

import { MenuBar } from './menu-bar/menu-bar';
import { RichTextEditorProvider, useRichTextEditor } from './rich-text-editor-context';

import './prose-mirror.css';

const EditorField = forwardRef((_props, ref: ForwardedRef<HTMLDivElement>) => {
  const { editor, disabled, fontSizes } = useRichTextEditor();

  return (
    <EditorContent
      editor={editor}
      ref={ref}
      disabled={disabled}
      style={{
        fontSize: `${fontSizes[0] ?? 14}px`,
      }}
      className='ProseMirrorEditor'
    />
  );
});

EditorField.displayName = 'EditorField';

type RichTextEditorProps = {
  baseFontSize?: number;
  defaultValue?: string;
  disabled?: boolean;
  height?: number;
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
} & Omit<Partial<EditorOptions>, 'content' | 'onUpdate' | 'extensions'>;

export const RichTextEditor = forwardRef((props: RichTextEditorProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    defaultValue = '',
    disabled,
    onChange,
    placeholder,
    value: valueControlled,
    height = 200,
    ...editorOptions
  } = props;
  const [valueInternal, setValueInternal] = useState<string | undefined>(defaultValue);
  const value = valueControlled ?? valueInternal;

  return (
    <RichTextEditorProvider
      editorOptions={{
        ...editorOptions,
        extensions: [
          StarterKit,
          Placeholder.configure({
            emptyEditorClass: 'is-editor-empty',
            placeholder,
          }),
          Underline,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          TextStyle,
          TextSize.configure({
            types: ['textStyle'],
          }),
        ],
        onUpdate: ({ editor }) => {
          const html = editor.getHTML();
          setValueInternal(html);
          onChange?.(html);
        },
        content: value ?? '',
      }}
      disabled={disabled}
      className='rounded-[6px] border border-gray-light-1'
      height={height}
    >
      <Box p={rem(12)} h={rem(48)}>
        <MenuBar />
      </Box>
      <Box px={rem(12)} pb={rem(12)} pt={rem(8)} h={rem(height - 48)} data-testid='rich-text-editor' ref={ref}>
        <EditorField />
      </Box>
    </RichTextEditorProvider>
  );
});

RichTextEditor.displayName = 'RichTextEditor';
