import moment from 'moment';

function calculateCurrentWeekForAvailability(
  isTwoWeekCycle: boolean,
  currentWeekCycle: number,
): { startDateTime: Date[] | string[]; endDateTime: Date[] | string[] } {
  const startDateTime = new Array<string>();
  const endDateTime = new Array<string>();

  if (isTwoWeekCycle) {
    if (currentWeekCycle === 1) {
      // Week 1
      startDateTime.push(moment(new Date()).startOf('isoWeek').toISOString());
      endDateTime.push(moment(new Date()).endOf('isoWeek').toISOString());
      // Week 2
      const nextWeek = moment(new Date()).add(7, 'days');
      startDateTime.push(nextWeek.startOf('isoWeek').toISOString());
      endDateTime.push(nextWeek.endOf('isoWeek').toISOString());
    } else {
      // Week 1
      const prevWeek = moment(new Date()).subtract(7, 'days');
      startDateTime.push(prevWeek.startOf('isoWeek').toISOString());
      endDateTime.push(prevWeek.endOf('isoWeek').toISOString());
      // Week 2
      startDateTime.push(moment(new Date()).startOf('isoWeek').toISOString());
      endDateTime.push(moment(new Date()).endOf('isoWeek').toISOString());
    }
  } else {
    startDateTime.push(moment(new Date()).startOf('isoWeek').toISOString());
    endDateTime.push(moment(new Date()).endOf('isoWeek').toISOString());
  }

  return { startDateTime, endDateTime };
}

export { calculateCurrentWeekForAvailability };
