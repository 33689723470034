import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Avatar, Form, Icon, Input, notification } from 'antd';
import _ from 'lodash';
import { Information } from 'common-components/alerts';
import moment from 'moment';
import { FormComponentProps } from 'antd/es/form';
import FormItem from 'antd/es/form/FormItem';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IModifyInvitationModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  invite: any;
  doModifyTeamInvitation: typeof dispatch.teamStore.doModifyTeamInvitation;
}

interface IModifyInvitationModalState {
  title: any;
  isDone: boolean;
  email: string;
  isEmailNotAnEmailError: boolean;
  isEmailExistingError: boolean;
  isSaving: boolean;
}

class ModifyInvitationModal extends Component<IModifyInvitationModalProps, IModifyInvitationModalState> {
  state = {
    title: 'Modify invitation',
    isDone: false,
    email: null,
    isEmailNotAnEmailError: false,
    isEmailExistingError: false,
    isSaving: false,
  };

  private _onCloseModal = () => {
    this.setState({
      title: 'Modify invitation',
      isDone: false,
      isEmailNotAnEmailError: false,
      isEmailExistingError: false,
    });
    this.props.onClose();
  };

  private _saveAction = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        this.setState({ isSaving: true });
        const email = form.getFieldValue('email');
        await this.props.doModifyTeamInvitation({ token: this.props.invite.token, email });
        this.setState({
          title: (
            <>
              Invite successfully modified
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isDone: true,
          email,
        });
      } catch (e) {
        if (e.meta.message === 'email must be an email') {
          this.setState({ isEmailNotAnEmailError: true });
        } else if (e.meta.message === 'Email existing in system') {
          this.setState({ isEmailExistingError: true });
        } else {
          notification.error({ message: 'Oops! Something went wrong, please try again.' });
        }
      }
      this.setState({ isSaving: false });
    }
  };

  render() {
    const { isOpen, invite, form } = this.props;
    const { isEmailNotAnEmailError, isEmailExistingError } = this.state;
    const { getFieldDecorator } = form;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {!_.isEmpty(invite) &&
          (this.state.isDone ? (
            <>
              <div className="mv-medium ml-x4-large anim-slide-left">
                <div>
                  <SubTitle>User</SubTitle>
                  <div className={'flex-row align-center'}>
                    <Avatar shape={'square'} src={invite.attachmentUrl} className={'mr-medium'} />
                    <Text>{invite.firstName + ' ' + invite.lastName}</Text>
                  </div>
                </div>
                <div className={'mt-large'}>
                  <SubTitle>Invite sent to</SubTitle>
                  {this.state.email}
                </div>

                <ActionModalFooter>
                  <PrimaryButton size="large" onClick={this._onCloseModal}>
                    Done
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ) : (
            <>
              <div className="mv-medium anim-slide-left">
                <Information
                  content={
                    <>
                      <Text weight={'bold'}>
                        Modifying this invitation will invalidate any invitation link previously sent to this user.
                      </Text>
                      <br />
                      Please double-check before you confirm the modification of this invite. A new invite link will be
                      sent to the user.
                    </>
                  }
                />
                <div className={'mt-large'}>
                  <SubTitle>User</SubTitle>
                  <div className={'flex-row align-center'}>
                    <Avatar shape={'square'} src={invite.attachmentUrl} className={'mr-medium'} />
                    <Text>{invite.firstName + ' ' + invite.lastName}</Text>
                  </div>
                </div>
                <div className={'mt-large'}>
                  <SubTitle>Previous invitation details</SubTitle>
                  {invite.email}
                  <br />
                  Sent on {moment(invite.sentOn).format('DD/MM/YYYY')}
                </div>
                <div className={'mt-large mb-x2-large'}>
                  <SubTitle>Enter email for invitation</SubTitle>
                  <FormItem className={'m-none'}>
                    {getFieldDecorator('email', {
                      initialValue: invite.email,
                      rules: [
                        {
                          required: true,
                          type: 'email',
                          message: 'Please enter a valid Email',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter email address"
                        className={`${(isEmailNotAnEmailError || isEmailExistingError) && 'border-red-dark'}`}
                        size="large"
                        onChange={() => this.setState({ isEmailExistingError: false, isEmailNotAnEmailError: false })}
                        style={{ width: '400px' }}
                      />,
                    )}
                  </FormItem>
                  {isEmailNotAnEmailError && (
                    <div>
                      <Text color={'red-dark'}>Please enter a valid Email</Text>
                    </div>
                  )}
                  {isEmailExistingError && (
                    <div>
                      <Text color={'red-dark'}>This email is already in use.</Text>
                    </div>
                  )}
                </div>

                <ActionModalFooter>
                  <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" loading={this.state.isSaving} onClick={this._saveAction}>
                    Modify Invitation
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ))}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doModifyTeamInvitation: dispatch.teamStore.doModifyTeamInvitation,
});

export default connect(null, mapDispatch)(Form.create<IModifyInvitationModalProps>()(ModifyInvitationModal));
