import React, { Component } from 'react';
import { Avatar, Col, Form, Icon, Input, InputNumber, notification, Radio, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import ActionModal from 'common-components/modal/ActionModal';
import _ from 'lodash';
import CommonUtils from 'utilities/common-utils';
import { EditRecurringMode, GroupBookingActionType, RecurringBookingPattern, ScheduleType } from 'utilities/enum-utils';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import moment from 'moment-timezone';

const FormItem = Form.Item;

const searchAutocompleteOptions = {
  componentRestrictions: { country: ['au'] },
};

interface IEditSessionAddressModalProps extends FormComponentProps {
  closeModal: () => void;
  isOpen: boolean;
  address?: any;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  doUpdateSessionLocation: typeof dispatch.groupServiceStore.doUpdateSessionLocation;
}

interface IEditSessionAddressModalState {
  title: string;
  isActionModalOpen: boolean;
  isManual: boolean;
  inputAddress: string;
  addressLocation: any;
  selectedLocation: boolean;
  selectedLattitude: any;
  selectedLongitude: any;
  address_components: any;
  displayRecurringOptions: boolean;
  selectedOption: number;
  numberOfSessions: number;
  isVerifying: boolean;
  workerConflicts: any;
  isWorkerConflictList: boolean;
  modalWidth: string;
  cancelAllConflicts: boolean;
  errorConfirmConflicts: boolean;
  temporarySelectedAddress: any;
}

class EditSessionAddressModal extends Component<IEditSessionAddressModalProps, IEditSessionAddressModalState> {
  state = {
    title: 'Session location',
    isLoadingFilter: false,
    isActionModalOpen: false,
    isManual: false,
    inputAddress: '',
    addressLocation: null,
    selectedLocation: false,
    selectedLattitude: '',
    selectedLongitude: '',
    address_components: [],
    displayRecurringOptions: false,
    selectedOption: EditRecurringMode.Current,
    numberOfSessions: 0,
    isVerifying: false,
    workerConflicts: [],
    isWorkerConflictList: false,
    modalWidth: 'medium',
    cancelAllConflicts: false,
    errorConfirmConflicts: false,
    temporarySelectedAddress: null,
  };

  private _displayRecurringOptions = async () => {
    await this._generateSessionAddress();
    this.setState({ displayRecurringOptions: true, title: 'Schedule options', numberOfSessions: 1 });
  };

  private _generateSessionAddress = async () => {
    let addressLocation;
    const { form } = this.props;
    const { selectedLattitude, selectedLongitude, inputAddress, address_components } = this.state;
    if (this.state.isManual) {
      const streetAddress1 = form.getFieldValue('streetaddress');
      const streetAddress2 = form.getFieldValue('streetaddress2') ? form.getFieldValue('streetaddress2') : '';
      const suburb = form.getFieldValue('suburb');
      const state = form.getFieldValue('state');
      const postcode = form.getFieldValue('postcode');
      const country = 'Australia';
      const inputAddress = streetAddress1 + ' ' + streetAddress2 + ' , ' + suburb + ' , ' + state + ' , ' + country;
      let latlong = await CommonUtils.getLatLongByAddress(inputAddress);
      addressLocation = {
        state: state,
        geoLat: latlong.lat,
        geoLng: latlong.lng,
        country: country,
        locality: suburb,
        postcode: postcode,
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1,
          streetAddress2,
          locality: suburb,
          state,
          postcode,
          country,
        }),
        streetAddress1: streetAddress1,
        streetAddress2: streetAddress2,
        locationType: 'ActualVenue',
      };
      this.setState({ addressLocation });
    } else {
      let latlong = await CommonUtils.getLatLongByAddress(inputAddress);
      this.setState({
        addressLocation: {
          ...CommonUtils.createAddressFromGoogleMap(address_components),
          geoLat: latlong.lat,
          geoLng: latlong.lng,
          locationType: 'ActualVenue',
        },
      });
    }
  };

  private _saveSessionAddress = async () => {
    const { selectedSession, doUpdateSessionLocation } = this.props;
    try {
      this.setState({ isVerifying: true });
      const result: any = await doUpdateSessionLocation({
        serviceScheduleId: selectedSession.serviceScheduleId,
        scheduleTimeSlotId: selectedSession.scheduleTimeSlotId,
        serviceId: selectedSession.serviceId,
        serviceDateTimeId: selectedSession.serviceDateTimeId,
        location: this.state.addressLocation,
        recurringPattern: selectedSession.recurringPattern
          ? selectedSession.recurringPattern
          : RecurringBookingPattern.EveryWeek,
        recurringNext:
          this.state.selectedOption === 1
            ? 0
            : this.state.selectedOption === 2
            ? selectedSession.upcomingSession
            : this.state.numberOfSessions,
        conflictWorkers: _.map(this.state.workerConflicts, (conflict) => {
          return conflict.attendanceId;
        }),
      });
      if (result && !_.isEmpty(result.workerConflicts)) {
        this.setState({
          isVerifying: false,
          workerConflicts: result.workerConflicts,
          displayRecurringOptions: false,
          modalWidth: 'x-large',
          isWorkerConflictList: true,
          title: 'Team member conflicts',
        });
      } else {
        this.setState({ isVerifying: false, displayRecurringOptions: false });
        notification.success({ message: `Session's location updated successfully` });
      }
      this._closeActionCreateModal();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
      this.setState({
        isVerifying: false,
      });
    }
  };

  private _onChangeConflicts = () => {
    if (!this.state.cancelAllConflicts) {
      this.setState({ errorConfirmConflicts: true });
    } else {
      this.setState({ isWorkerConflictList: false });
      this._saveSessionAddress();
    }
  };

  private _handleChange = (inputAddress) => {
    this.setState({ inputAddress: inputAddress });
    if (inputAddress === '') {
      this.setState({ selectedLocation: false });
    }
  };

  private _handleSelect = async (inputAddress) => {
    try {
      let selectedLocation = null;
      await geocodeByAddress(inputAddress)
        .then((results) => (selectedLocation = results[0]))
        .catch(() => this.setState({ selectedLocation: false }));

      if (selectedLocation) {
        const latLng = await getLatLng(selectedLocation);
        this.setState({
          selectedLocation: true,
          selectedLattitude: latLng.lat,
          selectedLongitude: latLng.lng,
          address_components: selectedLocation.address_components,
          temporarySelectedAddress: CommonUtils.createAddressFromGoogleMap(selectedLocation.address_components),
        });
      }
    } catch {
      this.setState({ selectedLocation: false });
    }
    this.setState({ inputAddress: inputAddress });
  };

  private _closeActionCreateModal = () => {
    this.setState({
      isActionModalOpen: false,
      inputAddress: '',
      selectedLocation: false,
      isManual: false,
      displayRecurringOptions: false,
      isWorkerConflictList: false,
      errorConfirmConflicts: false,
      cancelAllConflicts: false,
      workerConflicts: [],
    });
    this.props.closeModal();
  };

  private _switchToManual = () => {
    this.setState({ isManual: true, selectedLocation: false, inputAddress: '' });
  };

  private _switchToAuto = () => {
    this.setState({ isManual: false, selectedLocation: false, inputAddress: '' });
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _onChangeNumberOfSessions = (event) => {
    this.setState({ numberOfSessions: event });
  };

  private _validatePostcode = (rule, value, callback) => {
    try {
      if (Number(value).toString().length !== 4) {
        throw Error('Postcode is required (4 digits)');
      }
    } catch (e) {
      callback(e);
      return;
    }
    callback();
  };

  private _addAddress = async () => {
    const { selectedSession } = this.props;
    let isFormValid = true;
    if (this.state.isManual) {
      this.props.form.validateFields((err, value) => {
        if (err) {
          isFormValid = false;
        }
      });
    }
    if (isFormValid) {
      await this._generateSessionAddress();
      selectedSession && selectedSession.scheduleType === ScheduleType.SCHEDULE && selectedSession.upcomingSession > 0
        ? this._displayRecurringOptions()
        : this._saveSessionAddress();
    }
  };

  render() {
    const { form, selectedSession, address } = this.props;
    const { getFieldDecorator } = form;
    const { workerConflicts, title } = this.state;

    const defaultStyles = {
      autocompleteItem: {
        backgroundColor: '#000',
        padding: '10px',
        color: '#555555',
        cursor: 'pointer',
      },
      autocompleteItemActive: {
        backgroundColor: '#fafafa',
      },
    };

    let selectedAddress = this.state.temporarySelectedAddress ? this.state.temporarySelectedAddress : address;

    return (
      <div>
        <ActionModal
          isOpen={this.props.isOpen}
          title={title}
          onClose={this._closeActionCreateModal}
          width={this.state.modalWidth}
        >
          {this.state.displayRecurringOptions ? (
            <>
              <div>
                <div className="mb-small">
                  <Text weight="bold">Edit location for:</Text>
                </div>
                <Radio.Group value={this.state.selectedOption} onChange={this._onChangeOption} className="ml-medium">
                  <Radio
                    value={EditRecurringMode.Current}
                    className={`${
                      this.state.selectedOption === EditRecurringMode.Current && 'text-weight-bold'
                    } mb-small `}
                  >
                    <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                      This session only.
                    </div>
                  </Radio>
                  <br />
                  <Radio
                    value={EditRecurringMode.CurrentAll}
                    className={`${
                      this.state.selectedOption === EditRecurringMode.CurrentAll && 'text-weight-bold'
                    } mb-small `}
                  >
                    <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                      This session and all following sessions.
                    </div>
                  </Radio>
                  <br />
                  <Radio
                    value={EditRecurringMode.CurrentNext}
                    className={`${
                      this.state.selectedOption === EditRecurringMode.CurrentNext && 'text-weight-bold'
                    } mb-small `}
                  >
                    <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                      This session and the next{' '}
                      <InputNumber
                        className="mh-x-small"
                        style={{ width: '50px' }}
                        min={1}
                        max={selectedSession.upcomingSession}
                        value={this.state.numberOfSessions}
                        onChange={this._onChangeNumberOfSessions}
                      />{' '}
                      session
                      {this.state.numberOfSessions !== 1 && 's'}.
                    </div>
                    <br />
                    <Text color="secondary" weight="regular">
                      <i>
                        There {selectedSession.upcomingSession !== 1 ? 'are' : 'is'} {selectedSession.upcomingSession}{' '}
                        upcoming sessions in this schedule
                      </i>
                    </Text>
                  </Radio>
                </Radio.Group>
              </div>
              <div className={'mt-large'}>
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton className="mr-medium" size="large" onClick={this._closeActionCreateModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" onClick={this._saveSessionAddress}>
                    Continue
                  </PrimaryButton>
                </Row>
              </div>
            </>
          ) : this.state.isWorkerConflictList ? (
            <>
              <div className="anim-slide-left">
                <Paragraph>
                  As a result of the changes being made, team members assigned to this session are no longer available
                  due to schedule conflicts.
                </Paragraph>
                <Paragraph>
                  If you choose to continue, the worker will be removed from the following sessions.
                </Paragraph>
                <Row className="text-weight-bold bordered-bottom border-standard-gray p-small mt-large">
                  <Col span={8}>Team member</Col>
                  <Col span={8}>Session date</Col>
                  <Col span={8}>Conflicting session/booking</Col>
                </Row>
                <div
                  style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}
                  className="bordered-bottom border-standard-gray"
                >
                  {(!workerConflicts || workerConflicts.length === 0) && (
                    <Row className="pv-medium">
                      <Text>No conflicts found.</Text>
                    </Row>
                  )}
                  {_.map(workerConflicts, (conflict) => (
                    <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                      <Col
                        span={8}
                        title={conflict.shiftSlotStatus}
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        <Text>
                          <Avatar className="mr-medium" size={'small'} src={conflict.attachmentUrl} />{' '}
                          {conflict.firstName} {conflict.lastName}
                        </Text>
                      </Col>
                      <Col span={8}>
                        {moment(conflict.startDateTime)
                          .startOf('day')
                          .isSame(moment(conflict.endDateTime).startOf('day')) ? (
                          <text>
                            <b>{moment(conflict.startDateTime).format('DD/MM/YYYY')}</b>
                            <br />
                            <Text>
                              {moment(conflict.startDateTime).format('hh:mm a')} -{' '}
                              {moment(conflict.endDateTime).format('hh:mm a')}
                            </Text>
                          </text>
                        ) : (
                          <text>
                            <b>{moment(conflict.startDateTime).format('DD/MM/YYYY')}</b>{' '}
                            {moment(conflict.startDateTime).format('hh:mm a')}
                            <br />
                            <b>{moment(conflict.endDateTime).format('DD/MM/YYYY')}</b>{' '}
                            {moment(conflict.endDateTime).format('hh:mm a')}
                          </text>
                        )}
                      </Col>
                      <Col span={8}>
                        <Text color={'blue-action'}>{conflict.bookingNumber}</Text>
                        {moment
                          .tz(conflict.startDateTime, selectedSession.timezone)
                          .startOf('day')
                          .isSame(moment.tz(conflict.endDateTime, selectedSession.timezone).startOf('day')) ? (
                          <text>
                            <b>{moment.tz(conflict.startDateTime, selectedSession.timezone).format('DD MMMM YYYY')}</b>
                            <br />
                            <Text>
                              {moment.tz(conflict.startDateTime, selectedSession.timezone).format('hh:mm a')} -{' '}
                              {moment.tz(conflict.endDateTime, selectedSession.timezone).format('hh:mm a')}
                            </Text>
                          </text>
                        ) : (
                          <text>
                            <b>{moment.tz(conflict.startDateTime, selectedSession.timezone).format('DD MMMM YYYY')}</b>{' '}
                            {moment.tz(conflict.startDateTime, selectedSession.timezone).format('hh:mm a')}
                            <br />
                            <b>
                              {moment.tz(conflict.endDateTime, selectedSession.timezone).format('DD MMMM YYYY')}
                            </b>{' '}
                            {moment.tz(conflict.endDateTime, selectedSession.timezone).format('hh:mm a')}
                          </text>
                        )}
                      </Col>
                    </Row>
                  ))}
                </div>
              </div>
              <div className={'mt-large'}>
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton className="mr-medium" size="large" onClick={this._closeActionCreateModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" onClick={this._onChangeConflicts}>
                    Confirm
                  </PrimaryButton>
                </Row>
              </div>
            </>
          ) : (
            <div className="anim-slide-left">
              <SpinningLoadingActionModel isOpen={this.state.isLoadingFilter} verticalAlignment={'highest'} />
              <Paragraph>
                Type the address you want to change the session location to and we will automatically search for it.
              </Paragraph>
              {!this.state.isManual ? (
                <React.Fragment>
                  <div className="mb-small" style={{ minHeight: '300px' }}>
                    {/*<SubTitle>Lookup Address</SubTitle>*/}
                    <FormItem className={'m-none'}>
                      <PlacesAutocomplete
                        value={this.state.inputAddress}
                        onSelect={this._handleSelect}
                        onChange={this._handleChange}
                        shouldFetchSuggestions={this.state.inputAddress.length > 2}
                        searchOptions={searchAutocompleteOptions}
                        styles={defaultStyles}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <input
                              value={this.state.inputAddress}
                              {...getInputProps({
                                placeholder: 'Type your address ...',
                                className: 'location-search-input width-full pl-small',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                      borderTop: '1px ridge grey',
                                      borderLeft: '1px ridge grey',
                                      borderRight: '1px ridge grey',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                      borderTop: '1px ridge grey',
                                      borderLeft: '1px ridge grey',
                                      borderRight: '1px ridge grey',
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>
                                      <Icon type={'environment'} /> {suggestion.description}
                                    </span>
                                  </div>
                                );
                              })}
                              {suggestions.length === 0 &&
                                !_.isEmpty(this.state.inputAddress) &&
                                !this.state.selectedLocation && <div>No results found.</div>}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </FormItem>
                  </div>
                  <div className={'mb-small'}>
                    <Row type={'flex'} justify={'end'}>
                      <Col className="text-align-left flex-grow">
                        <HyperlinkButton onClick={this._switchToManual}>Enter Address manually</HyperlinkButton>
                      </Col>
                      <Col>
                        <GhostButton size="large" className="mr-medium" onClick={this._closeActionCreateModal}>
                          Cancel
                        </GhostButton>
                      </Col>
                      <Col>
                        <PrimaryButton
                          disabled={!this.state.selectedLocation}
                          loading={this.state.isVerifying}
                          onClick={
                            selectedSession.scheduleType === ScheduleType.SCHEDULE &&
                            selectedSession.upcomingSession > 0
                              ? this._displayRecurringOptions
                              : this._addAddress
                          }
                          size="large"
                        >
                          Add Address
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div style={{ height: '60vh', overflow: 'auto' }}>
                    <div className="mb-small">
                      <SubTitle>Street Address</SubTitle>
                      <FormItem className={'m-none pr-small'}>
                        {getFieldDecorator('streetaddress', {
                          initialValue: selectedAddress ? selectedAddress.streetAddress1 : null,
                          rules: [
                            {
                              required: true,
                              message: 'Street address is required',
                            },
                          ],
                        })(<Input placeholder="Enter Street Address" />)}
                      </FormItem>
                    </div>
                    <div className="mb-small">
                      <SubTitle>Street Address 2</SubTitle>
                      <FormItem className={'m-none pr-small'}>
                        {getFieldDecorator('streetaddress2', {
                          initialValue: selectedAddress ? selectedAddress.streetAddress2 : null,
                        })(<Input placeholder="Enter Street Address 2" />)}
                      </FormItem>
                    </div>
                    <div className="mb-small width-1/2">
                      <SubTitle>Suburb</SubTitle>
                      <FormItem className={'m-none pr-small'}>
                        {getFieldDecorator('suburb', {
                          initialValue: selectedAddress ? selectedAddress.locality : null,
                          rules: [
                            {
                              required: true,
                              message: 'Suburb is required',
                            },
                          ],
                        })(<Input placeholder="Enter Suburb" />)}
                      </FormItem>
                    </div>
                    <div className="mb-small width-1/2">
                      <SubTitle>State</SubTitle>
                      <FormItem className={'m-none pr-small'}>
                        {getFieldDecorator('state', {
                          initialValue: selectedAddress ? selectedAddress.state : null,
                          rules: [
                            {
                              required: true,
                              message: 'State is required',
                            },
                          ],
                        })(
                          <Select
                            placeholder="Select a State"
                            style={{ width: '200px' }}
                            showSearch={true}
                            optionFilterProp={'children'}
                          >
                            <Select.Option value={'ACT'}>Australian Capital Territory</Select.Option>
                            <Select.Option value={'NSW'}>New South Wales</Select.Option>
                            <Select.Option value={'NT'}>Northern Territory</Select.Option>
                            <Select.Option value={'QLD'}>Queensland</Select.Option>
                            <Select.Option value={'SA'}>South Australia</Select.Option>
                            <Select.Option value={'TAS'}>Tasmania</Select.Option>
                            <Select.Option value={'VIC'}>Victoria</Select.Option>
                            <Select.Option value={'WA'}>Western Australia</Select.Option>
                          </Select>,
                        )}
                      </FormItem>
                    </div>
                    <div className="mb-small">
                      <SubTitle>Postcode</SubTitle>
                      <FormItem className={'m-none pr-small'}>
                        {getFieldDecorator('postcode', {
                          initialValue: selectedAddress ? selectedAddress.postcode : null,
                          rules: [
                            {
                              validator: this._validatePostcode,
                            },
                          ],
                        })(<Input maxLength={4} placeholder="Enter Postcode" style={{ width: '200px' }} />)}
                      </FormItem>
                    </div>
                    <div className="mb-small width-1/2">
                      <SubTitle>Country</SubTitle>
                      <FormItem className={'m-none pr-small'}>
                        <Select size={'large'} style={{ width: '200px' }} defaultValue={'AU'} disabled>
                          <Select.Option value={'AU'}>Australia</Select.Option>
                        </Select>
                      </FormItem>
                    </div>
                  </div>
                  <div className={'mb-small'}>
                    <Row type={'flex'} justify={'end'}>
                      {this.state.isManual && (
                        <Col className="mb-small text-align-left flex-grow">
                          <HyperlinkButton onClick={this._switchToAuto}>Address lookup tool</HyperlinkButton>
                        </Col>
                      )}
                      <Col>
                        <GhostButton onClick={this._closeActionCreateModal}>Close</GhostButton>
                      </Col>
                      <Col>
                        <PrimaryButton loading={this.state.isVerifying} onClick={this._addAddress}>
                          Add Address
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateSessionLocation: dispatch.groupServiceStore.doUpdateSessionLocation,
});

export default connect(mapState, mapDispatch)(Form.create<IEditSessionAddressModalProps>()(EditSessionAddressModal));
