import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Sticker = forwardRef(function Sticker(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.644 2.256a.75.75 0 0 1 .557.22l9.323 9.323a.75.75 0 0 1 .22.557c-.188 5.22-4.478 9.394-9.744 9.394-5.385 0-9.75-4.365-9.75-9.75 0-5.266 4.174-9.556 9.394-9.744Zm-1.1 1.622a8.25 8.25 0 1 0 9.578 9.578 7.75 7.75 0 0 1-9.578-9.578Zm1.423.484a6.25 6.25 0 0 0 7.671 7.671l-7.671-7.67Z"
        fill="currentColor"
      />
    </Icon>
  );
});
