import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const VideoPlus = forwardRef(function VideoPlus(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 1a.75.75 0 0 0-1.5 0v2.25H17a.75.75 0 1 0 0 1.5h2.25V7a.75.75 0 1 0 1.5 0V4.75H23a.75.75 0 0 0 0-1.5h-2.25V1ZM7.945 3.25H13.5a.75.75 0 0 1 0 1.5H8c-1.435 0-2.436.002-3.192.103-.734.099-1.122.28-1.399.556-.277.277-.457.665-.556 1.4-.101.754-.103 1.756-.103 3.191v6c0 1.435.002 2.436.103 3.192.099.734.28 1.122.556 1.399.277.277.665.457 1.4.556.755.101 1.756.103 3.191.103h6c1.435 0 2.437-.002 3.192-.103.734-.099 1.122-.28 1.4-.556.276-.277.456-.665.555-1.4.102-.755.103-1.756.103-3.191v-5.5a.75.75 0 1 1 1.5 0v5.555c0 1.367 0 2.47-.116 3.337-.122.9-.38 1.658-.982 2.26-.602.602-1.36.86-2.26.982-.867.116-1.97.116-3.337.116H7.945c-1.367 0-2.47 0-3.337-.116-.9-.122-1.658-.38-2.26-.982-.601-.602-.86-1.36-.981-2.26-.117-.867-.117-1.97-.117-3.337V9.945c0-1.367 0-2.47.117-3.337.12-.9.38-1.658.981-2.26.602-.602 1.36-.86 2.26-.981.867-.117 1.97-.117 3.337-.117Zm4.531 7.166.05.033.394.263.046.03c.525.35.987.658 1.31.951.345.311.671.726.671 1.307s-.326.996-.67 1.307c-.324.293-.786.6-1.31.95l-.047.031-.395.263-.049.033c-.642.428-1.194.796-1.652 1.012-.471.223-1.063.392-1.65.078s-.774-.9-.85-1.415c-.074-.502-.074-1.165-.074-1.937v-.645c0-.771 0-1.434.074-1.936.076-.515.263-1.101.85-1.415.587-.315 1.179-.145 1.65.078.459.216 1.01.584 1.652 1.012Zm-2.62.24s.003-.002.013-.002l-.013.002Zm.03 0c.042.004.131.025.297.104.346.163.805.467 1.51.937l.395.263c.585.39.952.637 1.183.846a.88.88 0 0 1 .17.189l.004.005-.003.005a.88.88 0 0 1-.17.19c-.232.208-.6.455-1.184.845l-.395.263c-.705.47-1.164.774-1.51.937-.166.079-.255.1-.297.105a1.048 1.048 0 0 1-.078-.306c-.056-.378-.058-.928-.058-1.776v-.526c0-.848.002-1.398.058-1.776.027-.182.059-.269.078-.306Zm-.03 4.688.013.002c-.01 0-.014-.001-.013-.002Zm.038.016c.007.005.01.009.01.01-.001 0-.004-.002-.01-.01Zm0-4.72c.006-.008.009-.01.01-.01 0 .001-.003.005-.01.01Z"
        fill="currentColor"
      />
    </Icon>
  );
});
