import { Icon, Popover, Switch } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import DoubleTimeInput from 'common-components/double-time-input/DoubleTimeInput';
import DurationInput from 'common-components/inputs/DurationInput';
import { Text, Title } from 'common-components/typography';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import CommonUtils from 'utilities/common-utils';
import { DurationUnit, DurationDisplayFormat } from 'utilities/enum-utils';
import { PreventHighlightTextIcon, StyledDatePicker } from './style/activityRecordStyle';

export interface ITimeSelectionOnChangeData {
  dateTime: { startDate: Moment | string | Date; endDate: Moment | string | Date };
  duration: string | number;
  durationUnit: DurationUnit;
  includeStartEndTime: boolean;
}

interface IActivityRecordTimeSelectSectionProps {
  isResetCurrentTime?: boolean;
  durationTimeBlock: number;
  duration: string | number;
  durationUnit: DurationUnit;
  startDate: Moment;
  endDate: Moment;
  includeStartEndTime: boolean;
  timezone: string;
  onChange: (data: ITimeSelectionOnChangeData) => void;
}

const maxDuration = 1440;
const minDuration = 0;

export const ActivityRecordTimeSelectSection = (props: IActivityRecordTimeSelectSectionProps) => {
  const { includeStartEndTime, durationTimeBlock, timezone } = props;
  const [duration, setDuration] = useState(props.duration);
  const [durationUnit, setDurationUnit] = useState(props.durationUnit || DurationUnit.MINUTE);
  const [startDate, setStartDate] = useState(moment.tz(props.startDate, timezone));
  const [endDate, setEndDate] = useState(moment.tz(props.endDate, timezone));
  const [isDurationInputError, setIsDurationInputError] = useState(false);
  const [isDisablePlus, setIsDisablePlus] = useState(false);
  const [isDisableMinus, setIsDisableMinus] = useState(false);

  const _onChangeCalendarDate = (date) => {
    let newDuration = duration;
    if (durationUnit === DurationUnit.HOUR) {
      const { hours, minutes } = CommonUtils.splitHourAndMinute(String(duration));
      newDuration = _convertToMinutes(hours, Number(minutes));
    }
    const fixedStartTime = moment.tz(date, timezone);
    const fixedEndTime = moment.tz(date, timezone).add('minutes', newDuration);
    setStartDate(fixedStartTime);
    setEndDate(fixedEndTime);
    //Restart duration
    const data: ITimeSelectionOnChangeData = {
      dateTime: { startDate: fixedStartTime.toDate(), endDate: fixedEndTime.toDate() },
      duration: newDuration,
      durationUnit: durationUnit,
      includeStartEndTime: includeStartEndTime,
    };

    props.onChange(data);
  };

  const _convertToMinutes = (hour: number, minute: number) => {
    return Number(hour * 60 + minute);
  };

  const _calculateHourAndMinute = (duration: number) => {
    const hourValue = Math.floor(duration / 60);
    const minuteValue = Math.floor(duration % 60);
    return { hourValue, minuteValue };
  };

  const _onChangeDuration = (durationValue, durationUnit, hour, minute, isResetCurrentTime = false) => {
    const newStartDate = isResetCurrentTime ? moment().tz(timezone) : startDate;
    if (durationValue === '' || _.isNull(durationValue) || _.isUndefined(durationValue)) durationValue = 0;
    const changedEndTime = moment.tz(newStartDate, timezone).add(hour, 'hours').add(minute, 'minutes');
    setDuration(durationValue);
    setDurationUnit(durationUnit);
    setStartDate(newStartDate);
    setEndDate(changedEndTime);
    const data: ITimeSelectionOnChangeData = {
      dateTime: { startDate: newStartDate.toDate(), endDate: changedEndTime.toDate() },
      duration: _convertToMinutes(Number(hour), Number(minute)),
      durationUnit: durationUnit,
      includeStartEndTime: includeStartEndTime,
    };
    props.onChange(data);
  };

  const _onChangeDateTime = (newStartDate, newEndDate) => {
    let startTime = moment.tz(newStartDate, timezone);
    let endTime = moment.tz(newEndDate, timezone);

    if (startTime.isSame(startDate)) {
      //Input end time
      if (endTime.isSameOrBefore(startTime)) {
        startTime = moment.tz(endTime, timezone).add(-1, 'hour');
      }
      const duration = moment.duration(endTime.diff(startTime));
      const calculatedTotalMinutes = Math.floor(duration.asMinutes());
      _handleUpdateDateTime({ duration, calculatedTotalMinutes, startTime, endTime });
    } else if (endTime.isSame(endDate)) {
      //Input start time
      if (startTime.isSameOrAfter(endTime)) {
        endTime = moment.tz(startTime, timezone).add(1, 'hour');
      }
      const duration = moment.duration(startTime.diff(endTime));
      const calculatedTotalMinutes = Math.abs(Math.floor(duration.asMinutes()));
      _handleUpdateDateTime({ duration, calculatedTotalMinutes, startTime, endTime });
    }
  };

  const _handleUpdateDateTime = (data: {
    duration: moment.Duration;
    calculatedTotalMinutes: number;
    startTime: Moment;
    endTime: Moment;
  }) => {
    let { duration, calculatedTotalMinutes, startTime, endTime } = data;
    let hour = Math.floor(duration.asHours());
    let minute = Math.floor(calculatedTotalMinutes % 60);
    if (calculatedTotalMinutes > maxDuration) {
      hour = 24;
      minute = 0;
      calculatedTotalMinutes = maxDuration;
      endTime = moment.tz(startTime, timezone).add(1, 'days');
    }
    setStartDate(startTime);
    setEndDate(endTime);
    durationUnit === DurationUnit.MINUTE ? setDuration(calculatedTotalMinutes) : setDuration(hour + '.' + minute);
    const onChangeData: ITimeSelectionOnChangeData = {
      dateTime: { startDate: startTime.toDate(), endDate: endTime.toDate() },
      duration: calculatedTotalMinutes,
      durationUnit: durationUnit,
      includeStartEndTime: includeStartEndTime,
    };
    props.onChange(onChangeData);
  };

  const _handleRoundDuration = () => {
    durationUnit === DurationUnit.MINUTE ? _roundDurationForMinuteType() : _roundDurationForHourType();
  };

  const _roundDurationForMinuteType = () => {
    let newDuration = Number(duration);
    newDuration = _roundDuration(newDuration);
    const { hourValue, minuteValue } = _calculateHourAndMinute(newDuration);
    _onChangeDuration(newDuration, DurationUnit.MINUTE, hourValue, minuteValue);
  };

  const _roundDurationForHourType = () => {
    const { hours, minutes } = CommonUtils.splitHourAndMinute(String(duration));
    let newDuration = _convertToMinutes(Number(hours), Number(minutes));
    newDuration = _roundDuration(newDuration);
    const { hourValue, minuteValue } = _calculateHourAndMinute(newDuration);
    _onChangeDuration(hourValue + '.' + minuteValue, DurationUnit.HOUR, hourValue, minuteValue);
  };

  const _roundDuration = (newDuration: number) => {
    const durationRedundant = Number(newDuration) % durationTimeBlock;
    if (durationRedundant <= durationTimeBlock / 2) {
      newDuration -= durationRedundant;
    } else {
      newDuration -= durationRedundant;
      newDuration += durationTimeBlock;
    }
    return newDuration;
  };

  const _handleAdjustDuration = (isAdd: boolean) => {
    durationUnit === DurationUnit.MINUTE ? _adjustDurationForMinuteType(isAdd) : _adjustDurationForHourType(isAdd);
  };

  const _adjustDurationForMinuteType = (isAdd: boolean) => {
    let newDuration = Number(duration);
    newDuration = _adjustNewDuration(newDuration, isAdd);
    const { hourValue, minuteValue } = _calculateHourAndMinute(newDuration);
    _onChangeDuration(newDuration, DurationUnit.MINUTE, hourValue, minuteValue);
  };

  const _adjustDurationForHourType = (isAdd: boolean) => {
    const { hours, minutes } = CommonUtils.splitHourAndMinute(String(duration));
    let newDuration = _convertToMinutes(Number(hours), Number(minutes));
    newDuration = _adjustNewDuration(newDuration, isAdd);
    const { hourValue, minuteValue } = _calculateHourAndMinute(newDuration);
    _onChangeDuration(hourValue + '.' + minuteValue, DurationUnit.HOUR, hourValue, minuteValue);
  };

  const _adjustNewDuration = (newDuration: number, isAdd: boolean) => {
    const durationRedundant = Number(newDuration) % durationTimeBlock;
    if (!isAdd && newDuration <= durationTimeBlock) return newDuration;
    if (isAdd) {
      durationRedundant === 0
        ? (newDuration += durationTimeBlock)
        : (newDuration = newDuration - durationRedundant + durationTimeBlock);
    } else {
      durationRedundant === 0 ? (newDuration -= durationTimeBlock) : (newDuration = newDuration - durationRedundant);
    }
    if (newDuration >= maxDuration) newDuration = maxDuration;
    if (newDuration <= minDuration) newDuration = minDuration;
    return newDuration;
  };

  const _handleDisableAdjustButton = (duration: number) => {
    if (Number(duration) >= maxDuration) {
      setIsDisablePlus(true);
      setIsDisableMinus(false);
    } else if (Number(duration) <= durationTimeBlock) {
      setIsDisablePlus(false);
      setIsDisableMinus(true);
    } else {
      setIsDisablePlus(false);
      setIsDisableMinus(false);
    }
  };

  const handleChangeIncludeStartEndTime = (includeStartEndTime: boolean) => {
    const data: ITimeSelectionOnChangeData = {
      dateTime: { startDate: startDate.toDate(), endDate: endDate.toDate() },
      duration: duration,
      durationUnit: durationUnit,
      includeStartEndTime: includeStartEndTime,
    };
    props.onChange(data);
  };

  useEffect(() => {
    //Validate duration input
    if (durationUnit === DurationUnit.MINUTE) {
      Number(duration) % durationTimeBlock !== 0 ? setIsDurationInputError(true) : setIsDurationInputError(false);
      _handleDisableAdjustButton(Number(duration));
    } else {
      const { hours, minutes } = CommonUtils.splitHourAndMinute(String(duration));
      const totalMinutes = _convertToMinutes(hours, Number(minutes));
      totalMinutes % durationTimeBlock !== 0 ? setIsDurationInputError(true) : setIsDurationInputError(false);
      _handleDisableAdjustButton(Number(totalMinutes));
    }
  }, [duration]);

  useEffect(() => {
    const { duration } = props;
    const minutes = 60;
    if (props.durationUnit === DurationUnit.HOUR) {
      const hourValue = Math.floor(Number(duration) / minutes);
      const minuteValue = Math.floor(Number(duration) % minutes);
      setDuration(hourValue + '.' + minuteValue);
    }
  }, [props.durationUnit, props.duration]);

  useEffect(() => {
    if (props.isResetCurrentTime) {
      const newDuration = _adjustNewDuration(durationTimeBlock, false);
      const { hourValue, minuteValue } = _calculateHourAndMinute(newDuration);
      _onChangeDuration(newDuration, DurationUnit.MINUTE, hourValue, minuteValue, true);
    }
  }, [props.isResetCurrentTime, durationTimeBlock]);

  useEffect(() => {
    if (!includeStartEndTime) {
      const oldDuration = moment.duration(startDate.diff(endDate));
      const now = moment().tz(timezone);
      const newStartTime = moment.tz(startDate, timezone).set({
        hour: now.hour(),
        minute: now.minute(),
      });
      const newEndTime = moment.tz(newStartTime, timezone).add(Math.abs(Math.floor(oldDuration.asMinutes())), 'minute');
      const newDuration = moment.duration(newStartTime.diff(newEndTime));
      const calculatedTotalMinutes = Math.abs(Math.floor(newDuration.asMinutes()));
      _handleUpdateDateTime({
        duration: newDuration,
        calculatedTotalMinutes,
        startTime: newStartTime,
        endTime: newEndTime,
      });
    }
  }, [includeStartEndTime, timezone]);

  return (
    <>
      <div className="flex-column row-gap-x-large">
        <div className="flex-column">
          <Text weight="bold" className="mb-small">
            Date performed
          </Text>
          <StyledDatePicker
            className="gh-datepicker rounded"
            calendarClassName="gh-datepicker-calendar"
            dateFormat="EEE dd MMM, yyyy"
            onChange={_onChangeCalendarDate}
            selected={startDate.toDate()}
          />
        </div>
        <div className="flex-column row-gap-regular">
          <div className="flex-column">
            <Text weight="bold" className="mb-medium">
              Time spent
            </Text>
            <div className="flex-row">
              <Switch checked={includeStartEndTime} onChange={(value) => handleChangeIncludeStartEndTime(value)} />
              <p className="ml-medium">Include start and finish time</p>
            </div>
          </div>
          <div className="flex-row">
            {includeStartEndTime && (
              <div>
                <Text size="regular">Start and finish time</Text>
                <DoubleTimeInput
                  size="large"
                  onChange={_onChangeDateTime}
                  startTimeValue={startDate}
                  endTimeValue={endDate}
                />
              </div>
            )}
            <div className={includeStartEndTime ? 'ml-large' : undefined}>
              <div>
                <Text size="regular">Total duration</Text>
                <Popover
                  placement="top"
                  trigger="hover"
                  content={
                    <div>
                      <Title level={4}>QUICK ADD</Title>
                      <Text>
                        Enter total minutes{' '}
                        <span
                          className="pv-x2-small ph-x-small bg-tertiary bordered rounded"
                          style={{ borderColor: '#FF00FF' }}
                        >
                          m
                        </span>
                      </Text>
                      <br />
                      <Text>
                        or enter hours and minutes{' '}
                        <span
                          className="pv-x2-small ph-x-small bg-tertiary bordered rounded"
                          style={{ borderColor: '#FF00FF' }}
                        >
                          h.m
                        </span>{' '}
                        or{' '}
                        <span
                          className="pv-x2-small ph-x-small bg-tertiary bordered rounded"
                          style={{ borderColor: '#FF00FF' }}
                        >
                          h,m
                        </span>
                      </Text>
                    </div>
                  }
                >
                  <Icon type="question-circle" className="text-size-regular text-color-tertiary ml-x-small" />
                </Popover>
              </div>
              <DurationInput
                min={0}
                value={duration}
                onChange={_onChangeDuration}
                placeholder="1"
                size="large"
                style={{ width: '120px' }}
                durationUnit={durationUnit}
                displayFormat={DurationDisplayFormat.MINUTES}
              />
            </div>
            <div className="ml-medium pt-medium align-center flex">
              <PreventHighlightTextIcon
                type="plus-circle"
                className={`text-size-large text-color-${
                  isDisablePlus ? 'blue-lighter' : 'info-blue'
                } text-size-small mr-12 cursor-pointer`}
                onClick={() => _handleAdjustDuration(true)}
              />
              <PreventHighlightTextIcon
                type="minus-circle"
                className={`text-size-large text-color-${
                  isDisableMinus ? 'red-lighter' : 'red'
                } text-size-small cursor-pointer`}
                onClick={() => _handleAdjustDuration(false)}
              />
            </div>
          </div>
        </div>
      </div>
      {isDurationInputError && (
        <div className="mt-medium flex-row">
          <div>
            <Icon type="warning" theme="filled" className="text-size-small text-color-orange-dark mr-x-small" />
            <Text color="orange-dark" size="small" className="mr-small">
              Duration typically in {durationTimeBlock} minute blocks
            </Text>
          </div>
          <HyperlinkButton className="align-center text-size-small mt-x-small flex" onClick={_handleRoundDuration}>
            Round to nearest {durationTimeBlock} minutes
          </HyperlinkButton>
        </div>
      )}
    </>
  );
};

export default ActivityRecordTimeSelectSection;
