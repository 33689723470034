import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Search from 'antd/lib/input/Search';
import { ProgressBar } from '@blueprintjs/core';
import { Modal, notification, Row, Skeleton } from 'antd';

import { HyperlinkButton } from 'common-components/buttons';
import { Paragraph, Title, Text } from 'common-components/typography';
import { IElementValue } from 'views/form-builder/shared/form-interface';
import PreviewFormModal from 'views/form-builder/components/PreviewFormModal';
import { SelectCustomersTeamMembersModal } from './EditCustomersTeamMembersModal';
import EditValueFormModal from 'views/form-builder/components/EditValueFormModal';
import { state, dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { FormType } from 'utilities/enum-utils';

interface IAttachFormWrapperProps {
  isOpen?: boolean;
  onClose?: () => void;
  onSave: (formVersionId: string, elementValues: IElementValue[], status: string) => void;
  timezone: string;
}
const AttachFormWrapper = ({ isOpen, onClose, onSave, timezone }: IAttachFormWrapperProps) => {
  return <>{isOpen && <AttachFormModalConnected timezone={timezone} onClose={onClose} onSave={onSave} />}</>;
};

interface IAttachFormModalProps {
  onClose?: () => void;
  onSave: (formVersionId: string, elementValues: IElementValue[], status: string) => void;
  currentForm: typeof state.formBuilderStore.currentForm;
  doGetFormDetailsByVersion: typeof dispatch.formBuilderStore.doGetFormDetailsByVersion;
  doFetchFormLiteList: typeof dispatch.formBuilderStore.doFetchFormLiteList;
  formLiteList: typeof state.formBuilderStore.formLiteList;
  timezone: string;
}
const AttachFormModal = ({
  onClose,
  onSave,
  currentForm,
  doGetFormDetailsByVersion,
  doFetchFormLiteList,
  formLiteList,
  timezone,
}: IAttachFormModalProps) => {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [isEditValueFormModalOpen, setIsEditValueFormModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedFormVersion, setSelectedFormVersion] = useState<string | null>(null);
  const [selectedFormID, setSelectedFormID] = useState<string | null>(null);
  const [isSelectCustomerMemberModal, setIsSelectCustomerMemberModal] = useState<boolean>(false);
  const [formPayload, setFormPayload] = useState<{ formId: string; elementValues: IElementValue[] }>(null);
  const [statusForm, setStatusForm] = useState<string>('');

  const _fetchFormLiterList = async (txt?: string) => {
    await doFetchFormLiteList({
      search: txt || '',
      formType: [FormType.INCIDENT, FormType.CUSTOM],
    });
    setIsLoading(false);
  };

  const _doSearch = useCallback(_.debounce(_fetchFormLiterList, 500), []);

  const _onEnterSearchText = (e) => {
    setIsLoading(true);
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      _doSearch(e.target.value);
    }
  };

  const _onPreviewFormClick = async (formId: string, versionId: string) => {
    try {
      await doGetFormDetailsByVersion({ formId, versionId });
      setIsPreviewModalOpen(true);
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  const _onSelectFormClick = async (formId: string, versionId: string) => {
    try {
      setSelectedFormVersion(versionId);
      setSelectedFormID(formId);
      await doGetFormDetailsByVersion({ formId, versionId });
      setIsEditValueFormModalOpen(true);
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  const _onClosePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  const _onAttachFormClick = (formId: string, elementValues: IElementValue[], status: string) => {
    setFormPayload({ formId, elementValues });
    setIsSelectCustomerMemberModal(true);
    setStatusForm(status);
  };

  const _doSave = () => {
    if (formPayload) {
      onSave(formPayload.formId, formPayload.elementValues, statusForm);

      setFormPayload(null);
      setSelectedFormVersion(null);
      setIsSelectCustomerMemberModal(false);
      setIsEditValueFormModalOpen(false);

      if (onClose && typeof onClose === 'function') {
        onClose();
      }
    }
  };

  const _onCloseSelectCustomerMemberModal = () => {
    setIsSelectCustomerMemberModal(false);
  };

  const _doClose = () => {
    setSelectedFormVersion(null);
    setIsEditValueFormModalOpen(false);
  };

  useEffect(() => {
    _fetchFormLiterList();
  }, []);

  return (
    <Modal
      centered
      visible={true}
      title={
        <Title level={4} className="mv-none">
          Attach a form
        </Title>
      }
      onCancel={onClose}
      width="1152px"
      footer={null}
    >
      {isPreviewModalOpen && (
        <PreviewFormModal
          timezone={timezone}
          formContent={currentForm}
          isOpen={isPreviewModalOpen}
          onClose={_onClosePreviewModal}
        />
      )}

      {isEditValueFormModalOpen && (
        <EditValueFormModal
          formElements={currentForm}
          formId={selectedFormID}
          isOpen={isEditValueFormModalOpen}
          onClose={_doClose}
          onSave={_onAttachFormClick}
          mode="ADD"
        />
      )}

      {isSelectCustomerMemberModal && (
        <SelectCustomersTeamMembersModal
          isOpen={isSelectCustomerMemberModal}
          onClose={_onCloseSelectCustomerMemberModal}
          onSave={_doSave}
        />
      )}

      <Paragraph>Select a form to attach to this step</Paragraph>
      <Search
        placeholder="Search for a form"
        loading={isLoading}
        style={{ width: '300px' }}
        allowClear={true}
        className={'mr-large'}
        onChange={_onEnterSearchText}
      />
      <div className="bg-quaternary">
        {isLoading ? (
          <div>
            <div className="pv-large">
              <ProgressBar />
            </div>
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
          </div>
        ) : (
          !_.isEmpty(formLiteList) && (
            <div className="pv-large ph-medium mt-medium " style={{ maxHeight: '50vh', overflow: 'auto' }}>
              {formLiteList.map((form, index) => (
                <Row key={index} className="bg-white p-small rounded-big mb-medium">
                  <Text weight="bold">{form.name}</Text>
                  <Paragraph className="mt-large">{form.description}</Paragraph>

                  <HyperlinkButton
                    color="blue-action"
                    className="mr-large"
                    onClick={() => _onSelectFormClick(form.formId, form.formVersionId)}
                  >
                    Select form
                  </HyperlinkButton>
                  <HyperlinkButton
                    color="blue-action"
                    onClick={() => _onPreviewFormClick(form.formId, form.formVersionId)}
                  >
                    Preview form
                  </HyperlinkButton>
                </Row>
              ))}
            </div>
          )
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentForm: state.formBuilderStore.currentForm,
  formLiteList: state.formBuilderStore.formLiteList,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doGetFormDetailsByVersion: dispatch.formBuilderStore.doGetFormDetailsByVersion,
  doFetchFormLiteList: dispatch.formBuilderStore.doFetchFormLiteList,
});

const AttachFormModalWrapper = connect(mapStateToProps, mapDispatchToProps)(AttachFormWrapper);

const AttachFormModalConnected = connect(mapStateToProps, mapDispatchToProps)(AttachFormModal);

export default AttachFormModalWrapper;
