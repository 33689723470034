import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Coffee = forwardRef(function Coffee(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.948 2.25H14.052c.899 0 1.648 0 2.242.08.628.084 1.195.27 1.65.725.456.456.642 1.023.726 1.65.058.434.074.95.078 1.545.31.001.562.008.788.053a2.75 2.75 0 0 1 2.161 2.16c.053.268.053.571.053.964v.146c0 .393 0 .696-.053.963a2.75 2.75 0 0 1-2.16 2.161c-.236.047-.5.053-.828.053-.012.215-.027.42-.047.612-.09.888-.28 1.629-.712 2.277a4.753 4.753 0 0 1-1.311 1.31c-.648.434-1.39.623-2.277.713-.866.088-1.951.088-3.32.088h-.084c-1.369 0-2.454 0-3.32-.088-.888-.09-1.629-.28-2.277-.712a4.751 4.751 0 0 1-1.31-1.311c-.434-.648-.623-1.39-.713-2.277-.088-.866-.088-1.951-.088-3.32V6.948c0-.898 0-1.648.08-2.242.084-.628.27-1.195.725-1.65.456-.456 1.023-.642 1.65-.726.595-.08 1.345-.08 2.243-.08Zm10.8 9c.304-.002.413-.008.496-.024a1.25 1.25 0 0 0 .982-.982c.02-.103.024-.243.024-.744 0-.5-.004-.641-.024-.744a1.25 1.25 0 0 0-.982-.982c-.083-.016-.191-.022-.494-.024v2.292c0 .43 0 .832-.003 1.207ZM17.25 6.997V10c0 1.42 0 2.429-.08 3.21-.079.77-.227 1.235-.468 1.596-.237.355-.542.66-.896.896-.361.241-.827.39-1.596.468-.781.08-1.79.08-3.21.08s-2.429 0-3.21-.08c-.77-.079-1.235-.227-1.596-.468a3.25 3.25 0 0 1-.896-.896c-.241-.361-.39-.827-.468-1.596-.08-.781-.08-1.79-.08-3.21V7c0-.964.002-1.612.067-2.095.062-.461.169-.659.3-.789.13-.13.327-.237.788-.3C6.388 3.753 7.036 3.75 8 3.75h6c.964 0 1.612.002 2.095.067.461.062.659.169.789.3.13.13.237.327.3.788.064.483.066 1.13.066 2.093ZM2.25 21a.75.75 0 0 1 .75-.75h18a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
