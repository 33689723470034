import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';
import { BookingSVG, ConfirmedSVG, ErrorSVG } from 'assets/UndrawSVG';
import { Paragraph } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Spinner } from '@blueprintjs/core';
import { connect } from 'react-redux';

interface IBookingSendToFinanceActionModelProps {
  isOpen: boolean;
  navigateTab: (key) => void;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  onClose: () => void;
  doSendToFinance: typeof dispatch.bookingsStore.doSendToFinance;
}

class BookingSendToFinanceActionModel extends Component<IBookingSendToFinanceActionModelProps, any> {
  state = {
    canManuallyClose: true,
    step: 1,
  };

  renderView = () => {
    const { step } = this.state;
    const { selectedBookingItem } = this.props;

    if (step === 1) {
      const totalAmount = _.reduce(
        selectedBookingItem.billingLineItem,
        (acc, items) => {
          return _.round(acc + Number(items.billingTotal), 2);
        },
        0,
      );

      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={BookingSVG} alt="BOOKING" style={{ width: '100%', height: '200px' }} />
          </div>

          <div className="text-align-center">
            <Paragraph>Are you sure you want to send this booking to finance?</Paragraph>
            <Paragraph>
              The total cost of this booking is <b>$ {totalAmount}</b>
            </Paragraph>
          </div>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onReview}>
              Review Billing Items
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this.onApprove}>
              Send for Payment
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Sending booking, won't be long...</Paragraph>
          </div>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={ConfirmedSVG} alt="SUCCESS" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Booking successfully sent to finance.</Paragraph>
            <Paragraph>This booking will await finance to bill.</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              OK
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Oops something has gone wrong, please try again</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Go Back to Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ canManuallyClose: true, step: 1 });
    onClose();
  };

  onApprove = async () => {
    const { doSendToFinance } = this.props;
    this.setState({ step: 2, canManuallyClose: false });
    try {
      await doSendToFinance({});
      this.setState({ step: 3, canManuallyClose: true });
    } catch (e) {
      this.setState({ step: 4, canManuallyClose: true });
    }
  };

  onReview = () => {
    const { navigateTab } = this.props;
    navigateTab('billing');
    this.onCloseModal();
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title="Send Booking from Payment"
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div style={{ minHeight: '300px' }} className="flex-column justify-center">
          {this.renderView()}
        </div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBookingItem: state.bookingsStore.selectedBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doSendToFinance: dispatch.bookingsStore.doSendToFinance,
});

export default connect(mapState, mapDispatch)(BookingSendToFinanceActionModel);
