import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Alert, Anchor, AlertProps } from '@good/ui/core';
import { CrossCircle } from '@good/icons';

export type ErrorAlertProps = {
  title?: string;
  message: string;
  onRetry?: () => void;
  isRetrying?: boolean;
} & Omit<AlertProps, 'title'>;

export const ErrorAlert = (props: ErrorAlertProps) => {
  const { t } = useTranslation('', { keyPrefix: 'silService.errorAlert' });
  const { title = t('title'), message, onRetry, isRetrying, ...alertProps } = props;

  return (
    <Alert color='red' title={title} w={400} icon={<CrossCircle />} {...alertProps}>
      <div className='flex align-start flex-col gap-1'>
        <Text size='sm'>{message}</Text>
        {onRetry && (
          <Anchor component='button' disabled={isRetrying} onClick={onRetry} className='group'>
            <Text size='sm' className='group-disabled:text-weak group-disabled:cursor-not-allowed' td='none'>
              {t('retry')}
            </Text>
          </Anchor>
        )}
      </div>
    </Alert>
  );
};
