import { IRosterShift } from 'common-components/roster-control/interfaces/roster-interfaces';
import moment from 'moment-timezone';
import * as H from 'history';
import { FieldLabel, Text } from 'common-components/typography';
import { Avatar } from 'antd';
import { HyperlinkButton, IconButton, PrimaryButton } from 'common-components/buttons';
import React, { useState } from 'react';
import { BookingTypeIcon } from 'common-components/tags';
import { BookingStatus, BookingType, ServiceType } from 'utilities/enum-utils';
import ShiftSlotStatusTag from 'common-components/tags/ShiftSlotStatusTag';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { useDispatch, useSelector } from 'react-redux';
import CommonUtils from 'utilities/common-utils';
import PermissionUtils from 'utilities/permission-utils';
import BookingRemoveWorkerActionModal from 'views/bookings/components/BookingRemoveWorkerActionModal';
import RemoveTeamMemberModal from 'views/group-services/session-details/team-members/modals/RemoveTeamMemberModal';
import { Popover } from '@blueprintjs/core';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { RecommendedPanel } from 'common-components/roster-control/common/RecommendedPanel';
import { useFetchSessionCapacity } from 'stores/hooks/query-hooks/use-query-fetch-session-capacity';
import _ from 'lodash';

function AssignedShiftPopover({
  shift,
  history,
  refreshAndCloseData,
  setCanClosePopover,
}: {
  shift: IRosterShift;
  history: H.History;
  refreshAndCloseData: () => void;
  setCanClosePopover: (value) => void;
}) {
  const {
    shiftStartDateTime,
    shiftEndDateTime,
    serviceName,
    workerLastName,
    workerFirstName,
    attachmentUrl,
    workerAttachmentUrl,
    shiftSlotStatus,
    customerFirstName,
    customerLastName,
    serviceId,
    serviceDateTimeId,
  } = shift;

  const [openRemoveWorker, setOpenRemoveWorker] = useState<boolean>(false);

  const { data: sessionData, isLoading: sessionIsLoading } = useFetchSessionCapacity(
    {
      serviceId,
      serviceDateTimeId,
    },
    shift.serviceType === ServiceType.GROUP,
  );

  // State selectors
  const authStore = useSelector((state: IRootState) => state.authStore);
  const { portalUser } = authStore;

  // Actions dispatch
  const { navigationStore: navigationDispatch, bookingsStore: bookingDispatch } = useDispatch<IRootDispatch>();
  const { setSelectedSideNavMenuKeys } = navigationDispatch;
  const { setSelectedBookingItem } = bookingDispatch;

  const mStartDate = moment(shiftStartDateTime);
  const mEndDate = moment(shiftEndDateTime);

  const dateDisplay = mStartDate.format('dddd, D MMMM YYYY');
  const timeDisplay = (
    <>
      <b>{mStartDate.format('h:mm a')}</b> - {mEndDate.format('h:mm a')}
    </>
  );

  const workerFullName = `${workerFirstName} ${workerLastName}`;
  const customerFullName = `${customerFirstName} ${customerLastName}`;

  const goToShift = () => {
    if (shift.serviceType === ServiceType.GROUP) {
      history.push(`/group-service/${shift.serviceId}/session/details/${shift.serviceDateTimeId}`, {
        selectedTab: 'TEAM-MEMBERS',
      });
      setSelectedSideNavMenuKeys(['/services']);
    } else {
      history.push(`/bookings/details/${shift.bookingId}`, {
        from: { url: '/bookings/calendar', linkLabel: 'Back to Calendar' },
      });
      setSelectedSideNavMenuKeys(['/services']);
    }
  };

  const goToService = (serviceId, serviceType) => {
    setSelectedSideNavMenuKeys(['/services']);
    return serviceType === ServiceType.GROUP
      ? history.push(`/group-service/details/${serviceId}`)
      : history.push(`/service/details/${serviceId}`);
  };

  const goToSession = (serviceId, serviceDateTimeId) => {
    setSelectedSideNavMenuKeys(['/services']);
    return history.push(`/group-service/${serviceId}/session/details/${serviceDateTimeId}`);
  };

  const openRemoveWorkerModal = async () => {
    await setSelectedBookingItem(shift);
    setOpenRemoveWorker(true);
  };

  const doCloseAndRefresh = async (doRefreshData = false) => {
    setCanClosePopover(true);
    setOpenRemoveWorker(false);
    await setSelectedBookingItem({});
    doRefreshData && refreshAndCloseData();
  };

  const hasEditWorkerPermissions = PermissionUtils.validatePermission(
    'EditWorkerInBooking',
    portalUser.permissions.permissionRoles,
    shift.serviceDepartmentId,
    shift.serviceId,
  );

  const getActionMenuItems = () => {
    return (
      <ActionMenu>
        {hasEditWorkerPermissions &&
          ((shift.bookingStatus !== BookingStatus.ARCHIVED &&
            shift.serviceType === ServiceType.INDIVIDUAL &&
            (shift.bookingStatus === BookingStatus.PENDING ||
              shift.bookingStatus === BookingStatus.ACCEPTED ||
              shift.bookingStatus === BookingStatus.CONFIRMED)) ||
            shift.serviceType === ServiceType.GROUP) && (
            <ActionMenuItem className={'mr-small'} onClick={openRemoveWorkerModal} text={'Remove team member'} />
          )}
      </ActionMenu>
    );
  };

  return (
    <div className="p-large flex-column line-height-100 select-none" style={{ width: '360px' }}>
      {hasEditWorkerPermissions && shift.serviceType === ServiceType.INDIVIDUAL && (
        <BookingRemoveWorkerActionModal
          bookingType={shift.bookingType}
          isOpen={openRemoveWorker}
          onClose={doCloseAndRefresh}
        />
      )}
      {hasEditWorkerPermissions && shift.serviceType === ServiceType.GROUP && (
        <RemoveTeamMemberModal
          isOpen={openRemoveWorker}
          shiftSlot={{
            ...shift,
            firstName: shift.workerFirstName,
            lastName: shift.workerLastName,
            attachmentUrl: shift.workerAttachmentUrl,
          }}
          onClose={doCloseAndRefresh}
          session={shift}
        />
      )}
      <div className="inline-block mb-x-small line-height-100">
        <ShiftSlotStatusTag status={shiftSlotStatus} size={'small'} />
      </div>
      <div className="line-height-120 mb-medium">
        <Text size="x-large" lineHeight={135} weight="bold">
          {dateDisplay}
        </Text>
        <br />
        <Text size="x-large" lineHeight={135}>
          {timeDisplay}
        </Text>
        <br />
        <Text size="x-large" lineHeight={135}>
          <Text size="x-large" color={'secondary'}>
            {CommonUtils.formatDurationString(mStartDate, mEndDate)}
          </Text>
        </Text>
        <br />
        <div className={'flex-row align-center mt-small'}>
          <BookingTypeIcon
            type={shift.bookingType === BookingType.ACTIVITY_RECORD ? BookingType.ACTIVITY_RECORD : shift.serviceType}
            className={'mr-small'}
          />
          <Text color={'tertiary'}>{CommonUtils.formatServiceTypeStatus(shift)}</Text>
        </div>
        {shift.serviceType === ServiceType.INDIVIDUAL || !shift.scheduleName ? (
          <HyperlinkButton color={'black'} onClick={() => goToService(shift.serviceId, shift.serviceType)}>
            {serviceName}
          </HyperlinkButton>
        ) : (
          <Text>
            {shift.scheduleName}{' '}
            <HyperlinkButton color={'secondary'} onClick={() => goToService(shift.serviceId, shift.serviceType)}>
              ({shift.serviceName})
            </HyperlinkButton>
          </Text>
        )}
        {shift.serviceType === ServiceType.GROUP && (
          <div>
            <HyperlinkButton onClick={() => goToSession(shift.serviceId, shift.serviceDateTimeId)}>
              Go to session
            </HyperlinkButton>
          </div>
        )}
      </div>

      {shift.serviceType === ServiceType.GROUP && (
        <div className={'mb-large'}>
          <div className="mt-x-small">
            {sessionIsLoading ? (
              <SpinningLoader size={20} message={'Fetching recommended capacity...'} />
            ) : (
              sessionData &&
              sessionData.data && (
                <RecommendedPanel
                  recommendedTotalShiftSlot={sessionData.data.recommendedTotalShiftSlot}
                  totalAssignedShiftSlot={sessionData.data.totalAssignedShiftSlot}
                />
              )
            )}
          </div>
        </div>
      )}

      {shift.bookingId && (
        <div className="mb-large">
          <FieldLabel text={'CUSTOMER'} />
          <div className="flex-row align-center mt-x-small">
            <Avatar icon="user" className="mr-small" shape="circle" src={attachmentUrl} />
            <Text color="secondary">{customerFullName}</Text>
          </div>
        </div>
      )}

      {shift.serviceType === ServiceType.GROUP && shift.shiftCustomerAssignments && (
        <div className="mb-large">
          <FieldLabel text={'CUSTOMERS'} />
          <div className="mt-x-small" style={{ overflow: 'auto', maxHeight: '120px' }}>
            {_.map(shift.shiftCustomerAssignments, (assignment) => {
              return (
                <div className="flex-row align-center mt-x-small">
                  <div className="flex-row line-height-120 mb-small">
                    <Avatar icon="user" size="small" className="mr-small" src={assignment.customerAttachmentUrl} />
                    <Text size="regular">
                      <span className={'text-weight-bold'}>
                        {assignment.customerFirstName} {assignment.customerLastName}
                      </span>
                      <br />
                      <span>
                        {moment.tz(assignment.startDateTime, shift.timezone).format('h:mm a')}{' '}
                        {moment.tz(assignment.endDateTime, shift.timezone).format('h:mm a')}
                      </span>
                    </Text>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="mb-large">
        <FieldLabel text={'TEAM MEMBER'} />
        <div className="flex-row align-center mt-x-small">
          <Avatar icon="user" className="mr-small" shape="square" src={workerAttachmentUrl} />
          <Text color="secondary">{workerFullName}</Text>
        </div>
      </div>

      <div className="flex-row justify-end">
        <PrimaryButton onClick={goToShift}>View shift</PrimaryButton>
        {hasEditWorkerPermissions && (
          <Popover
            content={getActionMenuItems()}
            onOpening={() => setCanClosePopover(false)}
            onClose={() => setCanClosePopover(!openRemoveWorker)}
            interactionKind={'hover'}
            position={'bottom-left'}
          >
            <IconButton
              icon="ellipsis"
              color="white"
              iconColor="blue-action"
              bordered={true}
              className="border-blue-action ml-small"
            />
          </Popover>
        )}
      </div>
    </div>
  );
}

export default AssignedShiftPopover;
