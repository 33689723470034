import { forwardRef } from 'react';
import { Illustration } from './illustration';
import type { IllustrationProps } from './illustration';
import type { Ref } from 'react';
export const Cloud = forwardRef(function Cloud(props: IllustrationProps, ref: Ref<SVGSVGElement>) {
  return (
    <Illustration {...props} ref={ref}>
      <path
        d="M77.936 52.733C82.84 49.25 88.12 48.25 93.7 50.323c5.645 2.097 8.935 6.068 8.829 12.518 1.504-.163 2.86-.433 4.218-.44 9.234-.051 15.249 8.165 13.155 17.72-1.059 4.832-3.852 8.712-6.814 12.387-1.095 1.357-3.481 2.006-5.39 2.323-6.693 1.111-13.379.222-20.075-.394-3.927-.36-7.91-.095-11.869-.176-3.258-.066-6.515-.286-9.773-.338-8.85-.145-17.702-.217-26.551-.354-7.093-.112-14.185-.274-21.277-.419-5.25-.108-10.167-5.138-10.45-11.147-.348-7.439 2.952-12.766 9.376-16.229 2.633-1.42 5.462-2.479 8.147-3.81.426-.21.88-1.05.798-1.504-1.396-7.644 1.522-13.462 7.238-18.391 4.513-3.893 9.584-6.71 15.238-8.248 7.935-2.157 15.66-1.664 22.18 4.046 3.523 3.085 6.534 6.635 6.528 11.77 0 .745.172 1.491.29 2.236.028.172.159.327.433.866l.004-.006ZM53.657 36.688c-7.958.294-13.555 1.807-19.273 7.302-4.644 4.465-7.325 9.682-5.79 16.155.658 2.777 2.434 5.29 3.674 7.936.216.462.297.989.44 1.486-.53.106-1.084.363-1.578.276-.503-.091-1.037-.436-1.398-.819-2.829-2.997-6.598-3.926-10.283-2.473-6.091 2.4-10.133 9.296-9.22 15.742.693 4.915 4.072 8.764 8.155 9.135 2.557.232 5.13.362 7.699.394 4.386.051 8.775-.034 13.161-.017 4.069.017 8.135.126 12.202.128 3.445.003 6.89-.188 10.332-.114 4.571.1 9.14.388 13.711.566 5.078.2 10.16.322 15.238.564 2.875.137 5.76.765 8.609.603 3.911-.22 7.942-.155 10.567-4.28 3.13-4.918 4.517-9.976 2.517-15.518-2.11-5.843-7.026-8.77-13.146-6.587-.592.211-1.165.477-1.766.657-1.75.526-2.673-.323-2.357-2.12.15-.856.376-1.733.328-2.585-.243-4.233-2.787-6.871-6.365-8.633-6.484-3.19-13.365-.226-15.544 6.579-.12.377-.73.937-.961.873-.528-.15-1.226-.546-1.373-1-.264-.808-.106-1.743-.237-2.607-.752-4.983-.09-10.223-4.01-14.503-3.907-4.263-8.474-6.684-13.327-7.138l-.005-.002Z"
        fill="currentColor"
      />
    </Illustration>
  );
});
