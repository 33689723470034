import { tv } from 'tailwind-variants';

export const textField = tv(
  {
    slots: {
      container: ['flex', 'flex-col', 'gap-2', 'min-w-[192px]', 'w-fit'],
      inputContainer: ['relative', 'flex'],
      input: [
        'appearance-none',
        'bg-base',
        'border',
        'border-gray',
        'hover:border-gray-dark-1',
        'rounded-md',
        'text-b-sm',
        'leading-b-sm',
        'text-strong',
        'placeholder:text-weak',
        'py-[10px]',
        'px-3',
        'outline-none',
        'transition-colors',
        'w-full',
        'enabled:data-[focus-visible=true]:outline',
        'enabled:data-[focus-visible=true]:outline-offset-[-1px]',
        'enabled:data-[focused=true]:border-accent',
        'enabled:data-[focused=true]:data-[hovered=false]:border-accent',
        'enabled:data-[focus-visible=true]:outline-accent',
        'enabled:aria-invalid:border-critical',
        'enabled:aria-invalid:data-[hovered=false]:border-critical',
        'enabled:aria-invalid:data-[hovered=true]:border-critical',
        'enabled:aria-invalid:data-[focused=true]:border-critical',
        'enabled:aria-invalid:data-[focus-visible=true]:outline-critical',
        'disabled:bg-[#F7F7F6]',
        'disabled:border-[#F7F7F6]',
      ],
      icon: [
        'absolute',
        'pointer-events-none',
        'translate-y-2',
        'data-[start=true]:translate-x-2',
        'data-[end=true]:right-0',
        'data-[end=true]:-translate-x-2',
      ],
    },
  },
  { twMerge: false },
);
