import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Schema } from '../schema';
import fields from '../fields';

const useIsInputValid = ({
  form,
  isTeamMemberAssigned,
  setIsInputValid,
  maxDuration,
}: {
  form: UseFormReturn<Schema>;
  isTeamMemberAssigned: boolean;
  setIsInputValid: (isValid: boolean) => void;
  maxDuration: number;
}) => {
  const { watch } = form;

  const cancelledBy = watch(fields.cancelledBy.name);
  const shouldChargeCancellationFee = watch(fields.shouldChargeCancellationFee.name);
  const cancellationFeeReason = watch(fields.cancellationFeeReason.name);
  const shouldPayTeamMember = watch(fields.shouldPayTeamMember.name);
  const teamMemberPaymentDuration = watch(fields.teamMemberPaymentDuration.name);
  const customTeamMemberPaymentDuration = watch(fields.customTeamMemberPaymentDuration.name);
  const customTeamMemberPaymentDurationUnit = watch(fields.customTeamMemberPaymentDurationUnit.name);
  const isCustomPaymentDurationHours =
    customTeamMemberPaymentDurationUnit === fields.customTeamMemberPaymentDurationUnit.options.hours;

  useEffect(() => {
    const cancellingOnBehalfOfBusiness = cancelledBy === fields.cancelledBy.options.business;
    const cancellingOnBehalfOfCustomer = cancelledBy === fields.cancelledBy.options.customer;

    const validPayTeamMemberConditions = [
      !isTeamMemberAssigned,
      !shouldPayTeamMember,
      shouldPayTeamMember &&
        teamMemberPaymentDuration &&
        teamMemberPaymentDuration !== fields.teamMemberPaymentDuration.options.customDuration,
      shouldPayTeamMember &&
        teamMemberPaymentDuration === fields.teamMemberPaymentDuration.options.customDuration &&
        customTeamMemberPaymentDuration > 0 &&
        customTeamMemberPaymentDuration <= maxDuration * (isCustomPaymentDurationHours ? 1 : 60),
    ];

    if (cancellingOnBehalfOfBusiness) {
      setIsInputValid(validPayTeamMemberConditions.some((condition) => condition));
    } else if (cancellingOnBehalfOfCustomer) {
      const validChargeCancellationFeeConditions = [
        !isTeamMemberAssigned,
        !shouldChargeCancellationFee,
        shouldChargeCancellationFee && cancellationFeeReason,
      ];

      setIsInputValid(
        validPayTeamMemberConditions.some((condition) => condition) &&
          validChargeCancellationFeeConditions.some((condition) => condition),
      );
    }
  }, [
    cancelledBy,
    shouldChargeCancellationFee,
    cancellationFeeReason,
    shouldPayTeamMember,
    teamMemberPaymentDuration,
    customTeamMemberPaymentDuration,
    isTeamMemberAssigned,
    maxDuration,
  ]);
};

export default useIsInputValid;
