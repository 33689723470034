import { MenuDivider, MenuItem } from '@blueprintjs/core';
import { Menu } from 'antd';
import React from 'react';
import { PortalUser } from 'stores/rematch/models/auth-store';
import { InvitationStatus } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';

type TmMgmtInviteTableActionMenuParams = {
  status: InvitationStatus;
  portalUser: PortalUser;
  worker: object;
  onOpenModal: (status: string, worker: object) => void;
};

export const TmMgmtInviteTableActionMenu = ({
  status,
  portalUser,
  worker,
  onOpenModal,
}: TmMgmtInviteTableActionMenuParams) => (
  <Menu>
    <MenuDivider title="INVITE OPTIONS" className="text-color-secondary bordered-top pt-large" />

    {PermissionUtils.validatePermission('ResendInvites', portalUser.permissions.permissionRoles) && (
      <MenuItem
        label=""
        className="hover-bg-blue-lightest mv-medium"
        text={status === InvitationStatus.PENDING ? 'Re-send invitation' : 'Modify invitation'}
        onClick={() =>
          onOpenModal(
            status === InvitationStatus.PENDING ? 'ReSendInvitationModal' : 'ReSendRevokedInvitationModal',
            worker,
          )
        }
      />
    )}

    {status === InvitationStatus.PENDING && (
      <>
        {PermissionUtils.validatePermission('ResendInvites', portalUser.permissions.permissionRoles) && (
          <MenuItem
            label=""
            className="hover-bg-blue-lightest mv-medium"
            text="Modify invitation"
            onClick={() => onOpenModal('ModifyInvitationModal', worker)}
          />
        )}
        {PermissionUtils.validatePermission('RevokeInvites', portalUser.permissions.permissionRoles) && (
          <MenuItem
            label=""
            className="hover-bg-blue-lightest mv-medium text-color-red-dark"
            text="Revoke invitation"
            onClick={() => onOpenModal('RevokeInvitationModal', { ...worker, mode: 'INVITE' })}
          />
        )}
      </>
    )}
  </Menu>
);
