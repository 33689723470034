import React from 'react';
import {
  Select as _Select,
  Button as _Button,
  SelectValue as _SelectValue,
  Popover as _Popover,
  ListBox as _ListBox,
  SelectProps as _SelectProps,
} from 'react-aria-components';
import * as styles from './select-field.css';

import type { HTMLProps } from '../types';
import type { SelectVariants } from './select-field.css';
import { ChevronDown, ChevronUp } from '@good/icons';
import { FieldLabel, FieldLabelProps } from '../field-label';
import { AriaTextFieldProps } from 'react-aria';
import { StatusMessage } from '../status-message';
import { Text } from '../text';
import { Item } from '../item';

type LabelProps = {
  /**
   * Text for the text field's visible label element
   */
  label?: FieldLabelProps['children'];
} & Omit<FieldLabelProps, 'children'>;

// Support for small size variant coming in a future release
export type SelectFieldProps = Omit<SelectVariants, 'size'> &
  HTMLProps<Element> & {
    /**
     * Text for the select field's description element, if any.
     * */
    description?: AriaTextFieldProps['description'];
    /**
     * Text for the select field's error message element.
     */
    errorMessage?: AriaTextFieldProps['errorMessage'];
    /**
     * Controls whether the select field is disabled.
     * @default false
     */
    isDisabled?: AriaTextFieldProps['isDisabled'];
    /**
     * Controls the select field's validation state.
     * @default valid
     */
    validationState?: AriaTextFieldProps['validationState'];
    /**
     * Temporary text that occupies the text input when it is empty.
     */
    placeholder?: string;
    /**
     * Handler that is called when the selection changes.
     */
    onSelectionChange?: _SelectProps<SelectFieldProps>['onSelectionChange'];
    /**
     * The currently selected key in the collection (controlled).
     */
    selectedKey?: _SelectProps<SelectFieldProps>['selectedKey'];
    /**
     * The initial selected keys in the collection (uncontrolled).
     */
    defaultSelectedKey?: _SelectProps<SelectFieldProps>['defaultSelectedKey'];
  } & LabelProps;

export const SelectField = React.forwardRef<HTMLDivElement, SelectFieldProps>((props, ref) => {
  const {
    children,
    requirementIndicator,
    isRequired,
    validationState = 'valid',
    description,
    errorMessage,
    label,
    isDisabled,
    placeholder,
    onSelectionChange,
    selectedKey,
    defaultSelectedKey,
    ...otherProps
  } = props;

  // Support for small size coming in a future release
  const size = 'medium';
  const isDescription = Boolean(description);
  const isErrorMessage = Boolean(errorMessage);
  const isValid = validationState === 'valid';
  const showErrorMessage = !isValid && isErrorMessage;
  const showDescription = isDescription && !showErrorMessage;

  const { container, button, value, popover, listbox, chevron, item } = styles.selectField({
    size,
    isValid,
    isDisabled,
  });

  const validateItem = (child: React.ReactNode): boolean => {
    return React.isValidElement(child) && child.type === Item;
  };

  const clonedChildren = React.Children.map(children, (child) => {
    if (!validateItem(child)) {
      return null;
    }

    return React.cloneElement(child as React.ReactElement, { size, className: item() });
  });

  return (
    // Note: React Aria's validationState prop doesn't seem to be working with the current version (1.0.0-alpha.3)
    // TODO: Update React Aria Components when RC is released and re-test
    <_Select
      className={container()}
      validationState={validationState}
      onSelectionChange={onSelectionChange}
      isDisabled={isDisabled}
      selectedKey={selectedKey}
      defaultSelectedKey={defaultSelectedKey}
      ref={ref}
      {...otherProps}
    >
      {label && (
        <div className="mb-2 flex">
          <FieldLabel requirementIndicator={requirementIndicator} isRequired={isRequired}>
            {label}
          </FieldLabel>
        </div>
      )}

      <_Button className={button()}>
        <_SelectValue className={value()}>
          {({ selectedText }) =>
            selectedText ? (
              <Text className="text-black">{selectedText}</Text>
            ) : (
              <Text className="text-neutral">{placeholder}</Text>
            )
          }
        </_SelectValue>
        <div aria-hidden="true" className={chevron()}>
          <ChevronDown className="group-aria-expanded:hidden" />
          <ChevronUp className="group-aria-[expanded=false]:hidden" />
        </div>
      </_Button>

      <_Popover className={popover()}>
        <_ListBox className={listbox()}>{clonedChildren}</_ListBox>
      </_Popover>

      {showDescription && (
        <StatusMessage slot="description" tone="neutral">
          {description}
        </StatusMessage>
      )}
      {showErrorMessage && (
        <StatusMessage slot="errorMessage" tone="critical">
          {errorMessage}
        </StatusMessage>
      )}
    </_Select>
  );
});

SelectField.displayName = 'SelectField';
