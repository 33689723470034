import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Ticket = forwardRef(function Ticket(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 4.25h-.073c-.393 0-.696 0-.963.053a2.75 2.75 0 0 0-2.161 2.16c-.053.268-.053.571-.053.964v2.397c0 .511.415.926.927.926H3a1.25 1.25 0 0 1 0 2.5h-.823a.926.926 0 0 0-.927.927v2.396c0 .393 0 .696.053.963a2.75 2.75 0 0 0 2.16 2.161c.268.053.571.053.964.053h15.146c.393 0 .696 0 .963-.053a2.75 2.75 0 0 0 2.161-2.16c.053-.268.053-.57.053-.964v-2.396a.926.926 0 0 0-.926-.927H21a1.25 1.25 0 0 1 0-2.5h.824a.926.926 0 0 0 .926-.926V7.427c0-.393 0-.696-.053-.963a2.75 2.75 0 0 0-2.16-2.161c-.268-.053-.57-.053-.964-.053H4.5Zm5.25 14V16a.75.75 0 0 0-1.5 0v2.25H4.5c-.5 0-.641-.004-.744-.024a1.25 1.25 0 0 1-.982-.982c-.02-.103-.024-.243-.024-.744v-1.75H3a2.75 2.75 0 1 0 0-5.5h-.25V7.5c0-.5.004-.641.024-.744a1.25 1.25 0 0 1 .982-.982c.103-.02.243-.024.744-.024h3.75V8a.75.75 0 1 0 1.5 0V5.75h9.75c.5 0 .641.004.744.024.496.099.883.486.982.982.02.103.024.243.024.744v1.75H21a2.75 2.75 0 0 0 0 5.5h.25v1.75c0 .5-.004.641-.024.744a1.25 1.25 0 0 1-.982.982c-.103.02-.243.024-.744.024H9.75Zm0-7.25a.75.75 0 0 0-1.5 0v2a.75.75 0 1 0 1.5 0v-2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
