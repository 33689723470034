import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CallEnd = forwardRef(function CallEnd(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.25c-1.361 0-2.786.214-4.224.663-1.931.604-3.884 1.632-5.737 3.139l-.015.012c-.147.12-.265.215-.407.372-.376.416-.59.954-.66 1.48-.07.526-.008 1.101.245 1.602.095.19.208.347.35.546l.022.03.033.046c.175.246.311.437.46.589a2.75 2.75 0 0 0 3.051.606 5.05 5.05 0 0 0 .65-.369l.048-.03.701-.438.08-.049c.45-.282.806-.503 1.077-.814a2.75 2.75 0 0 0 .471-.764c.156-.382.195-.799.244-1.329l.008-.093.302-3.244A12.531 12.531 0 0 1 12 5.75c1.057 0 2.168.146 3.301.455l.302 3.244.009.093c.049.53.087.947.243 1.329.114.278.274.537.471.764.271.31.627.532 1.078.814l.079.05.701.438.048.03c.256.16.454.284.65.368a2.75 2.75 0 0 0 3.051-.606c.15-.152.285-.342.46-.589l.033-.045.022-.031c.142-.198.255-.357.35-.546.253-.5.316-1.076.245-1.602-.07-.526-.284-1.064-.66-1.48-.142-.157-.26-.253-.406-.372l-.015-.012c-1.853-1.507-3.806-2.535-5.738-3.139A14.176 14.176 0 0 0 12 4.25ZM2.985 9.216C4.345 8.11 5.75 7.293 7.144 6.73l-.24 2.58c-.062.666-.087.843-.147.991a1.251 1.251 0 0 1-.214.348c-.105.12-.253.221-.82.576l-.702.439c-.326.204-.419.259-.494.291a1.25 1.25 0 0 1-1.387-.275c-.057-.059-.122-.145-.345-.458-.173-.242-.216-.306-.254-.38a1.275 1.275 0 0 1-.097-.727c.037-.281.148-.521.286-.674.06-.066.095-.096.255-.226Zm14.11.094-.24-2.58c1.394.563 2.8 1.38 4.16 2.486.16.13.195.16.254.226.139.153.249.393.287.674.038.282-.005.543-.098.727-.038.075-.08.138-.254.38-.223.313-.288.4-.345.458a1.25 1.25 0 0 1-1.387.275c-.075-.032-.167-.087-.494-.291l-.701-.439c-.568-.355-.715-.456-.82-.576a1.25 1.25 0 0 1-.215-.348c-.06-.148-.084-.325-.146-.992ZM12.75 12a.75.75 0 0 0-1.5 0v6.19l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V12Z"
        fill="currentColor"
      />
    </Icon>
  );
});
