import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Invoice = forwardRef(function Invoice(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.372 2.349A.75.75 0 0 0 2.25 3v12.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116H19A2.75 2.75 0 0 0 21.75 19v-7a.75.75 0 0 0-.75-.75h-3.25V3a.75.75 0 0 0-1.122-.651L13.5 4.136 10.372 2.35a.75.75 0 0 0-.744 0L6.5 4.136 3.372 2.35ZM16.25 12V4.292l-2.378 1.36a.75.75 0 0 1-.744 0L10 3.863 6.872 5.65a.75.75 0 0 1-.744 0L3.75 4.292V15c0 1.435.002 2.436.103 3.192.099.734.28 1.122.556 1.399.277.277.665.457 1.4.556.755.101 1.756.103 3.191.103h7.55c-.192-.375-.3-.8-.3-1.25v-7ZM19 20.25c.69 0 1.25-.56 1.25-1.25v-6.25h-2.5V19c0 .69.56 1.25 1.25 1.25Zm-8.25-11V9a.75.75 0 0 0-1.5 0v.264a2.25 2.25 0 0 0 .25 4.486h1a.75.75 0 0 1 0 1.5H8a.75.75 0 0 0 0 1.5h1.25V17a.75.75 0 0 0 1.5 0v-.264a2.25 2.25 0 0 0-.25-4.486h-1a.75.75 0 0 1 0-1.5H12a.75.75 0 0 0 0-1.5h-1.25Z"
        fill="currentColor"
      />
    </Icon>
  );
});
