/* eslint-disable */
import React, { Component } from 'react';
import { ServDetailsSectionHeader } from '../common/ServDetailsSectionHeader';
import { Avatar, Button, Col, Divider, Icon, notification, Popconfirm, Row, Tag } from 'antd';
import _ from 'lodash';
import { Text, Title } from 'common-components/typography';
import { tagColors } from 'theme/theme-variables';
import { PrimaryButton, GhostButton } from 'common-components/buttons';
import ServiceRosterAddWorkerDrawer from 'views/services/details/sections/content-section/roster/ServiceRosterAddWorkerDrawer';

export class ServiceRosterTab extends Component<any, any> {
  state = {
    isDrawerVisible: false,
    isLoading: false,
    isLoadingRemoveId: '',
  };

  private _onCloseDrawer = () => {
    this.setState({ isDrawerVisible: false });
  };

  private _onAddNewWorker = () => {};

  private _onClickOpenDrawer = () => {
    this.setState({ isDrawerVisible: true });
  };

  private _onRemoveSupportWorker = async (worker) => {
    const { selectedService, doRemoveWorkerFromRoster } = this.props;
    try {
      this.setState({ isLoading: true, isLoadingRemoveId: worker.supportWorkerId });
      await doRemoveWorkerFromRoster({
        serviceId: selectedService.serviceId,
        supportWorkerIds: [worker.supportWorkerId],
      });
      notification.success({
        description:
          'The selected worker has been removed from the service. This change will be reflected in the marketplace.',
        message: 'Support worker removed',
      });
      this.setState({ isLoading: false, isLoadingRemoveId: '' });
    } catch (e) {
      this.setState({ isLoading: false, isLoadingRemoveId: -1 });
      notification.error({ description: e.meta.message, message: e.message });
    }
  };

  render() {
    const { selectedService } = this.props;

    return (
      <div className="mt-small">
        <ServDetailsSectionHeader title={'Roster'} subtitle={'Team roster for this service.'} hasDivider={false} />

        {this.props.hasEditPermission && (
          <PrimaryButton icon={'plus'} onClick={this._onClickOpenDrawer}>
            Add to roster
          </PrimaryButton>
        )}

        {selectedService.supportWorkers.length > 0 ? (
          <div>
            <Row type={'flex'} justify={'start'} align={'middle'} className="mv-medium">
              <Col span={6}>
                <Text>
                  <b>Name</b>
                </Text>
              </Col>
              <Col span={4}>
                <Text>
                  <b>Phone Number</b>
                </Text>
              </Col>
              <Col span={8}>
                <div className="flex-row justify-end" />
              </Col>
            </Row>
            <Divider className="mv-small" />

            {_.map(
              _.sortBy(
                selectedService.supportWorkers,
                [(customer) => customer.firstName.toLowerCase(), (customer) => customer.lastName.toLowerCase()],
                'desc',
              ),
              (worker, key) => {
                return (
                  <div className="mv-medium">
                    <Row
                      type={'flex'}
                      justify={'start'}
                      align={'middle'}
                      className="mt-small "
                      key={worker.supportWorkerId}
                    >
                      <Col span={6}>
                        <div className="flex-row align-center">
                          <Avatar src={worker.attachmentUrl} className="mr-medium" />
                          <Text>{worker.firstName + ' ' + worker.lastName}</Text>
                        </div>
                      </Col>
                      <Col span={4}>
                        <Text>
                          <Icon type={'mobile'} className="mr-x-small text-color-secondary" />
                          {worker.mobile}
                        </Text>
                      </Col>
                      <Col span={3}>{worker.isManager ? <Tag color={tagColors.BLUE}> Manager </Tag> : ''}</Col>
                      <Col span={3}>{worker.isKeyContact ? <Tag color={tagColors.GREEN}>Key Contact</Tag> : ''}</Col>
                      <Col span={2}>
                        <Popconfirm
                          title={'Remove this worker from service?'}
                          placement={'topLeft'}
                          okText={'Yes'}
                          cancelText={'No'}
                          onConfirm={() => {
                            this._onRemoveSupportWorker(worker);
                          }}
                          disabled={this.state.isLoading}
                        >
                          <GhostButton
                            icon={'close'}
                            disabled={this.state.isLoading}
                            loading={this.state.isLoadingRemoveId === worker.supportWorkerId}
                          >
                            Remove
                          </GhostButton>
                        </Popconfirm>
                      </Col>
                    </Row>
                    {/*<Divider className="mv-small" />*/}
                  </div>
                );
              },
            )}
          </div>
        ) : (
          <div>
            <Row className="mv-medium">
              <Text size="x2-large" color="secondary" weight="bold">
                No Team Member set.
              </Text>
            </Row>
          </div>
        )}
        {this.props.hasEditPermission && (
          <ServiceRosterAddWorkerDrawer
            visible={this.state.isDrawerVisible}
            serviceItem={selectedService}
            onAssignWorker={this._onAddNewWorker}
            onCloseDrawer={this._onCloseDrawer}
            rosterList={selectedService.supportWorkers}
          />
        )}
      </div>
    );
  }
}
