import React from 'react';
import FormRenderPlainMode from '../FormRenderPlainMode';
import { GhostButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import * as H from 'history';
import { IFormContent } from '../../shared/form-interface';

interface IFormDetailsPanelProps {
  formId: string;
  history: H.History;
  formDetails: IFormContent;
}

export default function FormDetailsPanel({ formDetails, history, formId }: IFormDetailsPanelProps) {
  const _OnEditForm = () => {
    history.push(`/account/forms/edit/${formId}`);
  };

  return (
    <div className='p-large' height='100%'>
      <div className='flex-row justify-between mb-x-large'>
        <Text size='x2-large' weight='bolder'>
          Form
        </Text>
        <GhostButton color='info-blue' onClick={_OnEditForm}>
          Edit...
        </GhostButton>
      </div>
      <FormRenderPlainMode formContent={formDetails} />
    </div>
  );
}
