import React from 'react';
import { ProgressBar } from '@blueprintjs/core';
import { Skeleton } from 'antd';

export const TmMgmtInviteTableLoading = () => (
  <>
    <div className="pv-large">
      <ProgressBar />
    </div>
    <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
    <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
    <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
  </>
);
