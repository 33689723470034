import { Popover2 } from '@blueprintjs/popover2';
import { Icon } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import PopoverContainer from 'common-components/modal/PopoverContainer';
import { StatusTag } from 'common-components/tags';
import { SubTitle, Text } from 'common-components/typography';
import _ from 'lodash';
import React, { Component } from 'react';
import { IBulkAction } from 'src/interfaces/common-interface';
import { BookingStatus } from 'utilities/enum-utils';

export const ActionButton = (props) => (
  <PrimaryButton {...props} className="bordered border-white mr-medium text-weight-bold" color="blue" size="large" />
);

class ParentActionItem extends Component<{ item: any; onClick: any }> {
  render() {
    const { item, onClick } = this.props;

    return (
      <div
        className="flex-row justify-between p-medium bordered-top hover-bg-tertiary cursor-pointer"
        onClick={() => onClick(item.itemLabel)}
      >
        <StatusTag status={item.itemLabel} size={'small'} />
        <div>
          <Text className="mr-small">
            {item.itemCount} item{item.itemCount !== 1 && 's'}
          </Text>
          <Icon type="right" />
        </div>
      </div>
    );
  }
}

class ParentSelectSheet extends Component<{
  itemList: IBulkAction[];
  onClick: (selection) => void;
  isBackEnable: boolean;
  parentLabel: string;
  onClickBack: () => void;
}> {
  render() {
    const { itemList, onClick, onClickBack, parentLabel, isBackEnable } = this.props;

    return (
      <>
        <div className="anim-slide-left">
          {parentLabel ? (
            <div className="pt-medium pb-medium ph-medium bg-tertiary text-align-center ">
              {isBackEnable && (
                <div style={{ position: 'absolute', left: 16, top: 16 }}>
                  <div className="p-x-small bg-transparent rounded-full cursor-pointer" onClick={onClickBack}>
                    <Icon type="arrow-left" />
                  </div>
                </div>
              )}
              <div className="text-align-center">
                <StatusTag status={parentLabel} size="small" />
                <br />
                <Text size="regular" color="secondary">
                  Actionable items
                </Text>
              </div>
            </div>
          ) : (
            <div className="pv-medium ph-medium flex-row align-center justify-center bordered-bottom">
              <SubTitle>Actionable Items</SubTitle>
            </div>
          )}

          {/* Render status selections */}
          {itemList &&
            itemList.length > 0 &&
            _.map(itemList, (item, key) => <ParentActionItem key={key} item={item} onClick={onClick} />)}
          {itemList && itemList.length === 0 && (
            <div className="p-medium">
              <Text color="secondary">No actions available.</Text>
            </div>
          )}
        </div>
      </>
    );
  }
}

class ActionSelectSheet extends Component<{
  onClickBack: () => void;
  selectionHistory: Array<string>;
  isBackEnable: boolean;
  parentLabel: string;
  item: any;
}> {
  private _onClickAction = (action) => {
    if (action.actionOnClick) {
      action.actionOnClick(this.props.selectionHistory);
    }
  };

  private _renderActionButton = (action) => {
    const { actionName, actionLabel, buttonType, buttonBackgroundColor } = action;

    const TargetButton =
      buttonType === 'PRIMARY'
        ? PrimaryButton
        : //
        buttonType === 'SECONDARY'
        ? SecondaryButton
        : // Default
          PrimaryButton;

    return (
      <React.Fragment key={actionName}>
        <TargetButton
          key={actionName}
          onClick={() => this._onClickAction(action)}
          className="width-full mb-medium"
          size="large"
          color={buttonBackgroundColor ? buttonBackgroundColor : 'blue'}
        >
          {actionLabel}
        </TargetButton>
        <br />
      </React.Fragment>
    );
  };

  render() {
    const { onClickBack, item, isBackEnable, parentLabel } = this.props;

    return (
      <div className="anim-slide-right">
        <div className="pt-medium pb-medium ph-medium bg-tertiary text-align-center ">
          {isBackEnable && (
            <div style={{ position: 'absolute', left: 16, top: 16 }}>
              <div className="p-x-small bg-transparent rounded-full cursor-pointer" onClick={onClickBack}>
                <Icon type="arrow-left" />
              </div>
            </div>
          )}
          <div className="text-align-center">
            <StatusTag status={parentLabel} size="small" />
            <br />
            <Text size="regular" color="secondary">
              {item.itemCount} item{item.itemCount !== 1 && 's'} in selection
            </Text>
          </div>
        </div>
        <div className="p-medium">
          <div className="mb-medium text-align-center">
            <Text color="secondary" size="regular">
              {_.isEmpty(item.actions) ? 'No Available Actions' : 'Available Actions'}
            </Text>
          </div>

          {_.map(item.actions, (action) => this._renderActionButton(action))}
        </div>
      </div>
    );
  }
}

interface IBottomActionSheetContainerProps {
  selectedItems?: any[];
  forcedStatus?: BookingStatus;
}

interface IBottomActionSheetContainerState {
  selectionHistory: Array<string>;
  currentlySelectedLevel: any;
  actualNavigationHistory: Array<string>;
}

export class BottomActionSheetContainer extends Component<
  IBottomActionSheetContainerProps,
  IBottomActionSheetContainerState
> {
  state = {
    selectionHistory: [],
    currentlySelectedLevel: this.props.selectedItems,
    actualNavigationHistory: [],
  };

  private _handleSelectStatus = (selection) => {
    this.setState({
      selectionHistory: [...this.state.selectionHistory, selection],
      actualNavigationHistory: [...this.state.actualNavigationHistory, selection],
    });
  };

  private _onClickBack = () => {
    const { actualNavigationHistory, selectionHistory } = this.state;
    const previousItemLabel = _.clone(actualNavigationHistory);
    const previousItemLabelIndexInHistory = _.findIndex(
      selectionHistory,
      (history) => history === actualNavigationHistory[actualNavigationHistory.length - 1],
    );
    previousItemLabel.pop();
    this.setState({
      selectionHistory: _.slice(selectionHistory, 0, previousItemLabelIndexInHistory),
      actualNavigationHistory: previousItemLabel,
    });
  };

  componentDidMount() {
    const { currentlySelectedLevel } = this.state;
    // If the starting step have only one option, then skip it. (Example: two bookings with the same status (COMPLETED)
    // but different paymentStatus are selected: We don't want the user to have to select COMPLETED and skip directly to the paymentStatus choice.
    if (currentlySelectedLevel.length === 1) {
      this.setState({
        selectionHistory: [...this.state.selectionHistory, currentlySelectedLevel[0].itemLabel],
        currentlySelectedLevel: currentlySelectedLevel[0].children,
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IBottomActionSheetContainerProps>,
    prevState: Readonly<IBottomActionSheetContainerState>,
    snapshot?: any,
  ) {
    // If a new selection has been added or removed from the history, we will update the currentlySelectedItem
    if (
      this.state.selectionHistory &&
      prevState.selectionHistory &&
      prevState.selectionHistory.length !== this.state.selectionHistory.length
    ) {
      const { selectedItems } = this.props;
      const { selectionHistory } = this.state;
      let tempCurrentlySelected = selectedItems;
      _.forEach(selectionHistory, (prev) => {
        const newItem = _.find(tempCurrentlySelected, (selected) => selected.itemLabel === prev);
        if (newItem && newItem.children && !_.isEmpty(newItem.children)) {
          tempCurrentlySelected = newItem.children;
        } else {
          tempCurrentlySelected = newItem;
        }
      });
      this.setState({ currentlySelectedLevel: tempCurrentlySelected });
    }
    // If the level selected has been changed and this new level is still an array and have only one choice then add it to the history to skip it.
    if (prevState.currentlySelectedLevel !== this.state.currentlySelectedLevel) {
      const { currentlySelectedLevel } = this.state;
      if (currentlySelectedLevel.length === 1) {
        // Add the skipped step to the selections history
        this.setState({
          selectionHistory: [...this.state.selectionHistory, currentlySelectedLevel[0].itemLabel],
        });
      }
    }
  }

  render() {
    const { selectedItems } = this.props;
    const { selectionHistory, currentlySelectedLevel, actualNavigationHistory } = this.state;

    // null check.
    if (_.isEmpty(selectedItems)) {
      return <PopoverContainer showHeader={false}>No actions available</PopoverContainer>;
    }

    const parentLabel = selectionHistory[selectionHistory.length - 1];

    return (
      <div className="" style={{ minWidth: '300px', minHeight: '350px' }}>
        {currentlySelectedLevel && currentlySelectedLevel.length > 1 ? (
          <ParentSelectSheet
            itemList={currentlySelectedLevel}
            onClick={this._handleSelectStatus}
            onClickBack={this._onClickBack}
            parentLabel={parentLabel}
            isBackEnable={!_.isEmpty(actualNavigationHistory)}
          />
        ) : (
          <ActionSelectSheet
            item={currentlySelectedLevel}
            onClickBack={this._onClickBack}
            selectionHistory={selectionHistory}
            parentLabel={parentLabel}
            isBackEnable={!_.isEmpty(actualNavigationHistory)}
          />
        )}
      </div>
    );
  }
}

export class BottomActionSheetHandler extends Component<{
  selectedItems: any[];
  onSetPopover: any;
  showPopover: boolean;
}> {
  _closePopover = () => this.props.onSetPopover(false);
  _openPopover = () => this.props.onSetPopover(true);

  render() {
    const { selectedItems, showPopover } = this.props;

    const popoverContent = <BottomActionSheetContainer selectedItems={selectedItems} />;

    return (
      <>
        <Popover2 isOpen={showPopover} onClose={this._closePopover} content={popoverContent}>
          <ActionButton onClick={this._openPopover}>Selection actions...</ActionButton>
        </Popover2>
      </>
    );
  }
}
