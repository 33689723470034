import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { type IRootDispatch, type IRootState } from 'stores/rematch/root-store';

export function useNotificationsCount() {
  const notificationsCount = useSelector((state: IRootState) => state.notificationsStore.badgeCount);
  const {
    notificationsStore: { doGetNotificationBadgeCount },
  } = useDispatch<IRootDispatch>();

  useEffect(() => {
    void doGetNotificationBadgeCount();
  }, [doGetNotificationBadgeCount]);

  return notificationsCount;
}
