import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Flag = forwardRef(function Flag(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1.25a.75.75 0 0 1 .75.75v1.25H19a.75.75 0 0 1 .697 1.029L17.806 9l1.89 4.722A.75.75 0 0 1 19 14.75H5.75V22a.75.75 0 0 1-1.5 0V2A.75.75 0 0 1 5 1.25Zm.75 12h12.142l-1.588-3.971a.75.75 0 0 1 0-.558l1.588-3.971H5.75v8.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
