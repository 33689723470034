import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Divider, Empty, Skeleton } from 'antd';
import _ from 'lodash';
import { ActivityItem } from 'views/group-services/manage-booking-modal/panels/activity-log/ActivityItem';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import asyncDelay from 'utilities/asyncDelay';
import { ModalLoading } from 'views/group-services/manage-booking-modal/common-components/ModalLoading';

interface IActivityLogPanelProps {
  booking?: typeof state.groupBookingsStore.selectedGroupBookingItem;
  session?: typeof state.groupServiceStore.selectedSession;
  groupBookingActivityLogs: typeof state.groupBookingsStore.groupBookingActivityLogs;
  groupServiceSessionActivityLogs: typeof state.groupServiceStore.groupServiceSessionActivityLogs;
  doFetchGroupBookingActivityLogs: typeof dispatch.groupBookingsStore.doFetchGroupBookingActivityLogs;
  doFetchGroupServiceSessionActivityLogs: typeof dispatch.groupServiceStore.doFetchGroupServiceSessionActivityLogs;
  isLoading: boolean;
}

interface IActivityLogPanelState {
  isLoading: boolean;
}

class ActivityLogPanel extends Component<IActivityLogPanelProps, IActivityLogPanelState> {
  state = {
    isLoading: false,
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    const { booking, doFetchGroupBookingActivityLogs, session, doFetchGroupServiceSessionActivityLogs } = this.props;
    if (session) {
      const { serviceId, serviceDateTimeId } = session;
      await doFetchGroupServiceSessionActivityLogs({ serviceId, serviceDateTimeId });
    }
    //await asyncDelay(1000);
    this.setState({ isLoading: false });
  };

  render() {
    const { groupBookingActivityLogs, groupServiceSessionActivityLogs, booking, session, isLoading } = this.props;

    // Loading
    if (isLoading) {
      return <ModalLoading text={'Fetching activity log...'} />;
    }

    const activitieLogs = booking ? groupBookingActivityLogs : groupServiceSessionActivityLogs;
    const timezone = booking ? booking.timezone : session.timezone;
    const hasActivities = !_.isEmpty(activitieLogs);
    const totalItems = hasActivities ? activitieLogs.length : 0;

    return (
      <div className="anim-fade-in-fast">
        {isLoading ? (
          <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className="anim-slide-left" />
        ) : (
          <>
            {/* Panel header section */}
            {booking && (
              <section>
                <Text size="small" color={'tertiary'}>
                  {totalItems} activitie{totalItems !== 1 && 's'} found for this booking
                </Text>
              </section>
            )}

            <Divider className="divider-medium" />

            {/* Activity listing */}
            {/* TODO: check whether InfinityLoading is used */}
            <section>
              {_.map(activitieLogs, (log, idx) => (
                <ActivityItem isLast={idx === totalItems - 1} activityLog={log} timezone={timezone} />
              ))}
              {!hasActivities && (
                <div>
                  <Text size="large" color="tertiary">
                    No activities.
                  </Text>
                </div>
              )}
            </section>
          </>
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupBookingActivityLogs: state.groupBookingsStore.groupBookingActivityLogs,
  groupServiceSessionActivityLogs: state.groupServiceStore.groupServiceSessionActivityLogs,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupBookingActivityLogs: dispatch.groupBookingsStore.doFetchGroupBookingActivityLogs,
  doFetchGroupServiceSessionActivityLogs: dispatch.groupServiceStore.doFetchGroupServiceSessionActivityLogs,
});

export default connect(mapState, mapDispatch)(ActivityLogPanel);
