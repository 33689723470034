import React from 'react';
import { CurrencyFormatter, Inline, Message, Separator, Stack, Text } from '@goodhuman-me/components';
import { coordinationTimeString } from '../../../../../../../utilities/budgeting/support-coordination';
import { statusToTone } from '../../../../../budget';

import type { BudgetCategory } from '../../../../../budget';
import type { InlineProps, StackProps, MessageProps } from '@goodhuman-me/components';
import type { PropsWithChildren, ReactNode } from 'react';

/*******************************************************************
 ** Metric
 ******************************************************************/

export type MetricProps = {
  label: string;
  value: ReactNode;
  tone?: MessageProps['tone'];
} & StackProps;

export function Metric(props: MetricProps): JSX.Element {
  let { label, value, tone = 'black', ...otherProps } = props;

  return (
    <Stack gap="$space20" {...otherProps}>
      <Text size="xsmall" color="$muted">
        {label}
      </Text>

      {/* TODO (Mitch): Add black */}
      <Text size="large" fontWeight="$semi">
        <Message tone={tone} iconPosition="end">
          {value}
        </Message>
      </Text>
    </Stack>
  );
}

/*******************************************************************
 ** MetricsColumn
 ******************************************************************/

export type MetricsColumnProps = StackProps;

export function MetricsColumn(props: MetricsColumnProps): JSX.Element {
  let { children, ...otherProps } = props;
  return (
    <Stack flex="1" gap="$space300" height="$full" {...otherProps}>
      {children}
    </Stack>
  );
}

/*******************************************************************
 ** MetricsContainer
 ******************************************************************/

type MetricsContainerProps = PropsWithChildren<InlineProps>;

function MetricsContainer(props: MetricsContainerProps): JSX.Element {
  return <Inline flex="1" gap="$large" height="100%" width="100%" {...props} />;
}

/*******************************************************************
 ** Metrics
 ******************************************************************/

export type MetricsProps = Pick<
  BudgetCategory,
  'actuals' | 'projections' | 'supportCoordinationTracking' | 'status'
> & {
  displayInHours: boolean;
};

function CoordinationTime({
  paceValue,
  signDisplay = false,
}: {
  paceValue: number;
  signDisplay?: boolean;
}): JSX.Element {
  return <span>{coordinationTimeString(paceValue, { signDisplay })}</span>;
}

export function Metrics(props: MetricsProps): JSX.Element {
  let { actuals, displayInHours, projections, status, supportCoordinationTracking } = props;
  let tone = statusToTone(status);

  return (
    <MetricsContainer>
      <MetricsColumn>
        <Metric
          label="Allocated"
          value={
            displayInHours ? (
              <CoordinationTime paceValue={supportCoordinationTracking.quoted} />
            ) : (
              <CurrencyFormatter value={actuals.quoted.value} />
            )
          }
        />
        <Metric
          label="Spent"
          value={
            displayInHours ? (
              <CoordinationTime paceValue={supportCoordinationTracking.spent} />
            ) : (
              <CurrencyFormatter value={actuals.spent.value} />
            )
          }
        />
        <Metric
          label="Balance"
          value={
            displayInHours ? (
              <CoordinationTime paceValue={supportCoordinationTracking.balance} />
            ) : (
              <CurrencyFormatter value={actuals.balance.value} />
            )
          }
        />
      </MetricsColumn>

      <Separator
        orientation="vertical"
        thickness="thick"
        marginRight="$large"
        height={{ initial: 'unset', medium: '100%' }}
      />

      <MetricsColumn>
        <Metric
          label="Tracking"
          tone={tone}
          value={
            displayInHours ? (
              <CoordinationTime signDisplay paceValue={supportCoordinationTracking.tracking} />
            ) : (
              <CurrencyFormatter signDisplay="exceptZero" value={projections.tracking.value} />
            )
          }
        />
        <Metric
          label="Projected spend"
          value={
            displayInHours ? (
              <CoordinationTime paceValue={supportCoordinationTracking.projectedSpend} />
            ) : (
              <CurrencyFormatter value={projections.spend.value} />
            )
          }
        />
        <Metric
          label="Projected balance"
          value={
            displayInHours ? (
              <CoordinationTime signDisplay paceValue={supportCoordinationTracking.projectedBalance} />
            ) : (
              <CurrencyFormatter signDisplay="exceptZero" value={projections.balance.value} />
            )
          }
        />
      </MetricsColumn>
    </MetricsContainer>
  );
}
