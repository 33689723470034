import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { useIsMobile } from 'utilities/react-hooks/use-is-mobile';

/**
 * Synchronizes the navbar open state with the store.
 * @param openDesktop - A boolean indicating whether the navbar is open on desktop.
 * @param openMobile - A boolean indicating whether the navbar is open on mobile.
 * @returns The current state of the side menu collapse in the store.
 */
export function useSyncNavbarOpenStateWithStore(openDesktop: boolean, openMobile: boolean): boolean {
  const isMobile = useIsMobile();
  const sideMenuCollapsed = useSelector((state: IRootState) => state.navigationStore.sideMenuCollapsed);
  const {
    navigationStore: { setSideMenuCollapsed },
  } = useDispatch<IRootDispatch>();

  useEffect(() => {
    setSideMenuCollapsed(isMobile ? !openMobile : !openDesktop);
  }, [isMobile, openDesktop, openMobile, setSideMenuCollapsed]);

  return sideMenuCollapsed;
}
