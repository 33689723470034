import React from 'react';
import { Icon } from '../icon';

import type { IconProps } from '../icon';

export function IconChecked(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 12 12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3801 2.58535C10.6091 2.79527 10.6246 3.15109 10.4146 3.3801L4.91465 9.3801C4.81098 9.4932 4.66561 9.55904 4.51222 9.56237C4.35883 9.5657 4.21074 9.50624 4.10225 9.39775L1.60225 6.89775C1.38258 6.67808 1.38258 6.32193 1.60225 6.10226C1.82192 5.88259 2.17808 5.88259 2.39775 6.10226L4.48233 8.18684L9.58535 2.61991C9.79527 2.3909 10.1511 2.37543 10.3801 2.58535Z"
        fill="currentColor"
      />
      <path
        d="M10.4146 3.3801L10.2304 3.21117L10.2304 3.21117L10.4146 3.3801ZM10.3801 2.58535L10.2112 2.76964V2.76964L10.3801 2.58535ZM4.91465 9.3801L5.09894 9.54903L4.91465 9.3801ZM4.51222 9.56237L4.51765 9.81231H4.51765L4.51222 9.56237ZM4.10225 9.39775L4.27903 9.22097L4.27903 9.22097L4.10225 9.39775ZM1.60225 6.89775L1.77903 6.72097H1.77903L1.60225 6.89775ZM1.60225 6.10226L1.77903 6.27903H1.77903L1.60225 6.10226ZM2.39775 6.10226L2.22097 6.27903H2.22097L2.39775 6.10226ZM4.48233 8.18684L4.30555 8.36362C4.35377 8.41183 4.41959 8.43826 4.48776 8.43678C4.55593 8.4353 4.62054 8.40604 4.66662 8.35577L4.48233 8.18684ZM9.58535 2.61991L9.76964 2.78884V2.78884L9.58535 2.61991ZM10.5989 3.54903C10.9022 3.21825 10.8798 2.70428 10.549 2.40107L10.2112 2.76964C10.3384 2.88627 10.347 3.08394 10.2304 3.21117L10.5989 3.54903ZM5.09894 9.54903L10.5989 3.54903L10.2304 3.21117L4.73036 9.21117L5.09894 9.54903ZM4.51765 9.81231C4.73921 9.8075 4.94919 9.71239 5.09894 9.54903L4.73036 9.21117C4.67276 9.274 4.592 9.31058 4.50679 9.31243L4.51765 9.81231ZM3.92548 9.57453C4.08218 9.73123 4.29609 9.81713 4.51765 9.81231L4.50679 9.31243C4.42157 9.31428 4.3393 9.28124 4.27903 9.22097L3.92548 9.57453ZM1.42548 7.07453L3.92548 9.57453L4.27903 9.22097L1.77903 6.72097L1.42548 7.07453ZM1.42548 5.92548C1.10817 6.24278 1.10817 6.75723 1.42548 7.07453L1.77903 6.72097C1.65699 6.59894 1.65699 6.40107 1.77903 6.27903L1.42548 5.92548ZM2.57452 5.92548C2.25722 5.60818 1.74278 5.60818 1.42548 5.92548L1.77903 6.27903C1.90107 6.15699 2.09893 6.15699 2.22097 6.27903L2.57452 5.92548ZM4.65911 8.01006L2.57452 5.92548L2.22097 6.27903L4.30555 8.36362L4.65911 8.01006ZM9.40106 2.45098L4.29804 8.01791L4.66662 8.35577L9.76964 2.78884L9.40106 2.45098ZM10.549 2.40107C10.2182 2.09785 9.70428 2.12019 9.40106 2.45098L9.76964 2.78884C9.88626 2.66161 10.0839 2.65302 10.2112 2.76964L10.549 2.40107Z"
        fill="currentColor"
      />
    </Icon>
  );
}
