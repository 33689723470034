import { RadioGroup } from 'design-components';
import React from 'react';
import { UserPermissionRole } from 'utilities/enum-utils';
import { ADVANCED_PERMISSION_ROLE_TYPE } from 'views/team-v2/utils/constants';
import ActionSelectedItem from '../ActionSelectedItem';
import AdvancedPermissionRow from '../AdvancedPermissionRow';

interface IProps {
  permissionRoleId: number;
  changePermissionForEachRole?: (value: number, permissionKey: string) => void;
  typePermissionPresent: string;
  handleSetTypePermissionPresent: (value: string) => void;
}

const PermissionFormBuilderSection: React.FC<IProps> = ({
  permissionRoleId = UserPermissionRole.NoAccess,
  changePermissionForEachRole,
  typePermissionPresent,
  handleSetTypePermissionPresent,
}) => {
  return (
    <AdvancedPermissionRow
      title="Form builder"
      description="Access to form builder"
      type={ADVANCED_PERMISSION_ROLE_TYPE.FORM_BUILDER}
      permissionRoleId={permissionRoleId}
      typePermissionPresent={typePermissionPresent}
      handleSetTypePermissionPresent={handleSetTypePermissionPresent}
      extendPermissions={
        <RadioGroup
          aria-label="form-builder"
          value={permissionRoleId}
          onChange={(value) => changePermissionForEachRole(value, 'formBuilder')}
        >
          <ActionSelectedItem
            ariaLabel="form-builder-view-only"
            label="No access, cannot access form-builder"
            value={UserPermissionRole.NoAccess}
            selectedPermission={permissionRoleId}
          />
          <ActionSelectedItem
            ariaLabel="form-builder-member"
            label="Standard access, can access form-builder and create, view and edit forms"
            value={UserPermissionRole.FormBuilder}
            selectedPermission={permissionRoleId}
          />
        </RadioGroup>
      }
    />
  );
};

export default PermissionFormBuilderSection;
