import { ProgressBar } from '@blueprintjs/core';
import { Col, Skeleton, notification } from 'antd';
import { GridHeader } from 'common-components/grids';
import { FieldLabel } from 'common-components/typography';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { InvoiceHistoryStatus } from 'utilities/enum-utils';
import { BILLING_TABLE_HEADER } from '../../../utils/constants';
import InvoiceDetailItem from './components/InvoiceDetailItem';
import ListingEmptyState from './components/ListingEmptyState';
import { InfiniteScroll } from 'components';

const BillingHistoryPanel: React.FC = () => {
  const dispatch = useDispatch<IRootDispatch>();

  const { billingHistoryOverView, portalUser } = useSelector((state: IRootState) => ({
    billingHistoryOverView: state.subscriptionStore.billingHistoryOverView,
    portalUser: state.authStore.portalUser,
  }));
  const { hasMore, billings } = billingHistoryOverView;

  const [loading, setLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const _fetchBillingHistoryOverView = async () => {
    setLoading(true);
    try {
      await dispatch.subscriptionStore.doGetBillingHistoryOverView();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
    setLoading(false);
  };

  const _fetchMoreBillingHistoryOverView = async () => {
    const lastInvoice = billings.slice(-1)[0] || undefined;
    if (!hasMore || !lastInvoice) {
      return;
    }
    setIsLoadingMore(true);
    try {
      await dispatch.subscriptionStore.doFetchMoreBillingHistoryOverView({ invoiceId: lastInvoice.id });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
    setIsLoadingMore(false);
  };

  useEffect(() => {
    _fetchBillingHistoryOverView();
  }, []);

  return (
    <>
      <div className='bg-white text-align-center' style={{ position: 'sticky', top: '0px', zIndex: 10 }}>
        <GridHeader bordered>
          {_.map(BILLING_TABLE_HEADER, (column) => {
            return (
              <Col key={column.key} span={column.span}>
                <FieldLabel text={column.name} />
              </Col>
            );
          })}
        </GridHeader>
      </div>
      {loading ? (
        <>
          <div className='pv-large'>
            <ProgressBar />
          </div>
          <Skeleton active title paragraph={{ rows: 1, width: '100%' }} />
          <Skeleton active title paragraph={{ rows: 1, width: '100%' }} />
          <Skeleton active title paragraph={{ rows: 1, width: '100%' }} />
        </>
      ) : _.isEmpty(billings) ? (
        <ListingEmptyState />
      ) : (
        <InfiniteScroll loadMore={_fetchMoreBillingHistoryOverView} hasMore={hasMore}>
          {_.map(billings, (invoice) => {
            return (
              <React.Fragment key={invoice.id}>
                <InvoiceDetailItem
                  invoiceDetail={invoice}
                  timeZone={portalUser?.timeZone}
                  openLinkPaymentIssue={() => window.open(invoice.paymentLink, '_blank')}
                  onDownloadInvoice={() => window.open(invoice.pdfLink, '_blank')}
                  previewInvoicePdf={() =>
                    window.open(
                      invoice.status === InvoiceHistoryStatus.REFUNDED
                        ? invoice.pdfLink
                        : `/pdf?type=preview-stripe-invoice&invoiceId=${invoice.id}`,
                      '_blank',
                    )
                  }
                />
              </React.Fragment>
            );
          })}
        </InfiniteScroll>
      )}
      {isLoadingMore && <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />}
    </>
  );
};

export default BillingHistoryPanel;
