import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Avatar, Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { ICustomerListItem } from 'interfaces/customer-interfaces';
import { CustomerAdditionalInfos } from 'common-components/tooltips';
import { Position } from '@blueprintjs/core';

interface ICustomerItemRowProps extends FormComponentProps {
  customer: ICustomerListItem;
  setSelectedCustomerId: (customerId: string) => void;
  hasAccessToCustomerDetails: boolean;
}

class CustomerItemRow extends Component<ICustomerItemRowProps, any> {
  private _setCurrentCustomer = async (customerId) => {
    this.props.setSelectedCustomerId(customerId);
  };

  render() {
    const { firstName, lastName, attachmentUrl, locality, userId } = this.props.customer;

    return (
      <div
        className="bordered-bottom ph-medium pv-medium flex-row align-center justify-between hover-bg-tertiary cursor-pointer"
        onClick={() => this._setCurrentCustomer(userId)}
      >
        <div className="flex-row align-center">
          <Avatar size="large" icon="user" className="mr-medium" src={attachmentUrl} />
          <div>
            <div className={'flex-row align-start'}>
              <Text weight="bold">{firstName + ' ' + lastName}</Text>
              {this.props.hasAccessToCustomerDetails && (
                <CustomerAdditionalInfos customer={this.props.customer} position={Position.RIGHT} usePortal={false} />
              )}
            </div>
            <Text color={'secondary'}>{locality}</Text>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create<ICustomerItemRowProps>()(CustomerItemRow);
