import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Scan = forwardRef(function Scan(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 2.25h-.027c-.676 0-1.222 0-1.666.03-.456.031-.86.097-1.242.255a3.75 3.75 0 0 0-2.03 2.03c-.158.382-.224.786-.255 1.242-.03.444-.03.99-.03 1.666V8a.75.75 0 0 0 1.5 0v-.5c0-.71 0-1.203.027-1.59.026-.38.074-.602.144-.771A2.25 2.25 0 0 1 5.14 3.921c.169-.07.39-.118.77-.144.388-.027.882-.027 1.591-.027H8a.75.75 0 0 0 0-1.5h-.5Zm9 1.5c.71 0 1.203 0 1.59.027.38.026.602.074.771.144.551.229.99.667 1.218 1.218.07.169.118.39.144.77.027.388.027.882.027 1.591V8a.75.75 0 0 0 1.5 0v-.527c0-.676 0-1.222-.03-1.666-.031-.456-.097-.86-.255-1.242a3.75 3.75 0 0 0-2.03-2.03c-.382-.158-.786-.224-1.242-.255-.445-.03-.99-.03-1.666-.03H16a.75.75 0 0 0 0 1.5h.5ZM3.75 16a.75.75 0 0 0-1.5 0v.527c0 .676 0 1.221.03 1.665.031.457.097.86.255 1.243a3.75 3.75 0 0 0 2.03 2.03c.382.158.786.224 1.242.255.444.03.99.03 1.666.03H8a.75.75 0 0 0 0-1.5h-.5c-.71 0-1.203 0-1.59-.027-.38-.026-.602-.074-.771-.144a2.25 2.25 0 0 1-1.218-1.218c-.07-.169-.118-.39-.144-.77-.027-.388-.027-.882-.027-1.591V16Zm18 0a.75.75 0 0 0-1.5 0v.5c0 .71 0 1.203-.027 1.59-.026.38-.074.602-.144.771a2.25 2.25 0 0 1-1.218 1.218c-.169.07-.39.118-.77.144-.388.027-.882.027-1.591.027H16a.75.75 0 0 0 0 1.5h.527c.676 0 1.221 0 1.665-.03.457-.031.86-.097 1.243-.255a3.75 3.75 0 0 0 2.03-2.03c.158-.382.224-.786.255-1.242.03-.445.03-.99.03-1.666V16ZM2 11.25a.75.75 0 0 0 0 1.5h20a.75.75 0 0 0 0-1.5H2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
