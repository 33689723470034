/* BOOM-475 - fix linting errors */
import { connect, useDispatch, useSelector } from 'react-redux';
import { ReactCropperElement } from 'react-cropper';
import React, { useState, useRef, useEffect } from 'react';
import { notification } from 'antd';

import '../styles/profile-photo-styles.css';

import { Inline, Modal, Positive, Text } from 'design-components';
import Step1AddEditProfilePictureModal from '../steps/Step1AddEditProfilePictureModal';
import Step2AddEditProfilePictureModal from '../steps/Step2AddEditProfilePictureModal';
import CropAndRotateModal, { cropAndRotateImage } from '../steps/CropAndRotateModal';
import { ModalShortMessage } from './ModalShortMessage';
import { IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ICustomerAttachment } from 'interfaces/customer-interfaces';
import { validateExtension } from 'utilities/file-utils';
import { useFirebaseStorage } from 'utilities/firebase-storage';
import { ArrowLeft } from '@good/icons';
import { Button } from '@good/components';

export interface IViewProfilePictureModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceProviderId: typeof state.authStore.portalUser.serviceProviderId;
}

enum AddEditProfilePictureStep {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3,
}

export enum EProfilePictureDestination {
  UPLOADS = 'Uploads',
  DEVICES = 'From device',
}

export enum ModalType {
  REMOVE_PROFILE_PICTURE = 'REMOVE_PROFILE_PICTURE',
  UNSAVED_CHANGES = 'UNSAVED_CHANGES',
  DELETE_PICTURE = 'DELETE_PICTURE',
  FILE_SIZE_LIMIT = 'FILE_SIZE_LIMIT',
}

const AddEditProfilePictureExtensions = {
  [AddEditProfilePictureStep.STEP1]: {
    title: 'Profile picture',
    canGoBack: false,
    modalWidth: 400,
    modalHeight: 'fit-content',
    headerStyle: {
      padding: '$space225 $space300',
      height: '64px',
    },
  },
  [AddEditProfilePictureStep.STEP2]: {
    title: 'Add profile picture',
    canGoBack: true,
    modalWidth: 900,
    modalHeight: 522,
    headerStyle: {
      padding: '$space300',
      height: '80px',
    },
  },
  [AddEditProfilePictureStep.STEP3]: {
    title: 'Crop and rotate',
    canGoBack: true,
    modalWidth: 400,
    modalHeight: 522,
    headerStyle: {
      height: '64px',
    },
  },
};

const ViewProfilePictureModal = (props: IViewProfilePictureModalProps) => {
  const { isOpen, onClose, serviceProviderId } = props;
  const dispatch = useDispatch<IRootDispatch>();
  const selectedCustomer = useSelector((state: IRootState) => state.customersStore.selectedCustomer);
  const cropperRef = useRef<ReactCropperElement>(null);

  const [steps, setSteps] = useState<AddEditProfilePictureStep>(AddEditProfilePictureStep.STEP1);
  const [modalType, setModalType] = useState<ModalType>(ModalType.REMOVE_PROFILE_PICTURE);
  const [previousUploadedPictures, setPreviousUploadedPictures] = useState<ICustomerAttachment[]>([]);
  const [selectedPreviousPictures, setSelectedPreviousPictures] = useState<ICustomerAttachment>();
  const [activeTab, setActiveTab] = useState<EProfilePictureDestination>(EProfilePictureDestination.UPLOADS);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [selectImage, setSelectImage] = useState<ICustomerAttachment>(null);
  const [isOpenModel, setOpenModel] = useState<boolean>(false);
  const [errorMessageFile, setErrorMessageFile] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);
  const [isFetchingPreviousProfilePicture, setIsFetchingPreviousProfilePicture] = useState<boolean>(false);

  const { uploadImage } = useFirebaseStorage();

  const onNextStep = async () => {
    setSteps((prev) => prev + 1);
  };

  const onPreviousStep = () => {
    if (steps === AddEditProfilePictureStep.STEP3) {
      if (!isUploadingImage) {
        setModalType(ModalType.UNSAVED_CHANGES);
        setOpenModel(true);
      }
      return;
    }

    setSteps((prev) => prev - 1);
    setImageUrl('');
    setErrorMessageFile('');
    setSelectedFile(null);
  };

  const onChangeActiveTab = (activeKey: EProfilePictureDestination) => {
    setActiveTab(activeKey);
    setSelectedFile(null);
    setSelectImage(null);
  };

  const onCancelStep = (type: ModalType, item?: ICustomerAttachment) => {
    setModalType(type);
    setOpenModel(true);

    if (type === ModalType.DELETE_PICTURE) {
      setSelectedPreviousPictures(item);
    }
  };

  const onCloseModalShortMessage = () => {
    setOpenModel(false);
  };

  const onPressPrimaryButton = async () => {
    switch (modalType) {
      case ModalType.UNSAVED_CHANGES:
        onCloseModalShortMessage();
        return;
      case ModalType.REMOVE_PROFILE_PICTURE:
        onRemoveDeletePicture(false);
        return;
      case ModalType.DELETE_PICTURE:
        await onRemoveDeletePicture(true);
        return;
      default:
        onCloseModalShortMessage();
        setErrorMessageFile(undefined);
        return;
    }
  };

  const onDiscardChanges = () => {
    setSteps((prev) => prev - 1);
    setImageUrl('');
    setErrorMessageFile('');
    setSelectedFile(null);
    onCloseModalShortMessage();
  };

  const onPressSecondaryButton = () => {
    switch (modalType) {
      case ModalType.UNSAVED_CHANGES:
        onDiscardChanges();
        return;
      case ModalType.DELETE_PICTURE:
      case ModalType.REMOVE_PROFILE_PICTURE:
        onCloseModalShortMessage();
        return;

      default:
        return;
    }
  };

  const onShowSuccessNotification = (title: string) => {
    notification.open({
      message: (
        <Inline display="flex" alignItems="center" justifyContent="center">
          <Positive color="$green" size={'small'} />
          <Text marginLeft={'$space100'} emphasis={'bold'} size={'small'}>
            {title}
          </Text>
        </Inline>
      ),
      placement: 'bottomRight',
      style: {
        width: 300,
        height: 56,
        marginBottom: 2,
        marginLeft: 71,
      },
    });
  };

  const validateFile = (file: File, isValid: boolean) => {
    if (isValid) {
      setErrorMessageFile(null);
      setSelectedFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const beforeUpload = (file?: File) => {
    const isValidType = validateExtension(file, ['.png', '.jpg', '.jpeg']);
    const isLimitSize = file.size / 1024 / 1024 <= cropAndRotateImage.maximumSizeOfImage;
    if (!isValidType) {
      setErrorMessageFile('Sorry, this file type cannot be uploaded. Please select a PNG or JPG file.');
      return false;
    }
    if (!isLimitSize) {
      setErrorMessageFile('');

      setModalType(ModalType.FILE_SIZE_LIMIT);
      setOpenModel(true);
      return false;
    }

    validateFile(file, true);
    return true;
  };

  const onSelectImage = (item?: ICustomerAttachment) => {
    if (item) {
      setErrorMessageFile(null);
      setImageUrl(item.attachmentUrl);
      setSelectImage(item);
    }
  };

  const rotate = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.rotate(90);
  };

  const uploadNewAvatar = async (serviceProviderId: string, userId: string, avatarImage: File, originalImage: File) => {
    const [{ url: avatarUrl, response: avatarResponse }, { url: originalUrl, response: originalResponse }] =
      await Promise.all([
        await uploadImage(avatarImage, `serviceProvider/${serviceProviderId}/customers/${userId}`),
        await uploadImage(originalImage, `serviceProvider/${serviceProviderId}/customers/${userId}`),
      ]);

    return await dispatch.customersStore.doUploadCustomerAvatar({
      payload: {
        avatarUrl,
        avatarFilePath: avatarResponse.metadata.fullPath,
        originalFileName: originalResponse.metadata.name,
        originalFilePath: originalResponse.metadata.fullPath,
        originalUrl: originalUrl,
      },
      customerUserId: userId,
    });
  };

  const uploadAvatarFromExistingImage = async (
    serviceProviderId: string,
    userId: string,
    avatarImage: File,
    customerAttachmentId: string,
  ) => {
    const { url: avatarUrl, response: avatarResponse } = await uploadImage(
      avatarImage,
      `serviceProviderId/${serviceProviderId}/customers/${userId}`,
    );

    return await dispatch.customersStore.doUploadCustomerAvatar({
      payload: {
        avatarUrl,
        avatarFilePath: avatarResponse.metadata.fullPath,
      },
      customerUserId: selectedCustomer.userId,
      customerAttachmentId: customerAttachmentId,
    });
  };

  const uploadAvatar = async (file: File) => {
    if (selectedFile) {
      return await uploadNewAvatar(serviceProviderId, selectedCustomer.userId, file, selectedFile);
    }

    return await uploadAvatarFromExistingImage(
      serviceProviderId,
      selectedCustomer.userId,
      file,
      selectImage?.customerAttachmentId,
    );
  };

  const onSave = async (file: File) => {
    setIsUploadingImage(true);

    const response = await uploadAvatar(file);

    if (response) {
      onShowSuccessNotification('Profile picture changed');
      dispatch.customersStore.doGetCustomer({ userId: selectedCustomer.userId });
    } else {
      notification.error({ message: 'Upload failed! Please try again.' });
    }
    onClose();
    setIsUploadingImage(false);
  };

  const onSubmit = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    const splitOriginFile = selectImage?.originalAttachmentName ? selectImage.originalAttachmentName.split('.') : [];

    cropper.getCroppedCanvas().toBlob(
      async (blob: Blob) => {
        try {
          const newFile = new File([blob], selectedFile?.name ?? splitOriginFile[0], {
            type: selectedFile?.type ?? 'image/' + splitOriginFile[1],
          });
          onSave(newFile);
        } catch (error) {
          notification.error({ message: 'Upload failed! Please try again.', description: error });
        }
      },
      'image/jpeg',
      0.6,
    );
  };

  const loadPreviousUploadedPictures = async () => {
    setIsFetchingPreviousProfilePicture(true);
    const res = await dispatch.customersStore.doGetCustomerProfilePicture({
      customerUserId: selectedCustomer.userId,
    });

    setIsFetchingPreviousProfilePicture(false);
    setPreviousUploadedPictures(res);
  };

  const onRemoveDeletePicture = async (isRemovePermanent: boolean) => {
    setIsLoading(true);

    const res = await dispatch.customersStore.doRemoveOrDeleteCustomerProfilePicture({
      customerUserId: selectedCustomer.userId,
      customerAttachmentId: isRemovePermanent
        ? selectedPreviousPictures.customerAttachmentId
        : selectedCustomer.customerAttachmentId,
      isRemovePermanent,
    });

    if (res) {
      isRemovePermanent &&
        setPreviousUploadedPictures((prev: ICustomerAttachment[]) => {
          let filtedPrev = [...prev];
          if (prev.length > 0) {
            filtedPrev = prev.filter(
              (item) => item.customerAttachmentId !== selectedPreviousPictures.customerAttachmentId,
            );
          }
          return filtedPrev;
        });

      await dispatch.customersStore.doGetCustomer({ userId: selectedCustomer.userId });
    } else {
      notification.error({ message: 'Remove failed! Please try again.' });
    }

    !isRemovePermanent && onShowSuccessNotification('Profile picture removed');
    setIsLoading(false);
    onCloseModalShortMessage();
  };

  const renderContent = () => {
    switch (steps) {
      case AddEditProfilePictureStep.STEP1:
        return <Step1AddEditProfilePictureModal onNextStep={onNextStep} onRemove={onCancelStep} />;
      case AddEditProfilePictureStep.STEP2:
        return (
          <Step2AddEditProfilePictureModal
            errorMessageFile={errorMessageFile}
            previousUploadedPictures={previousUploadedPictures}
            activeTab={activeTab}
            onSelectImage={onSelectImage}
            onChangeActiveTab={onChangeActiveTab}
            onDelete={onCancelStep}
            isLoading={isFetchingPreviousProfilePicture}
            isChangeProfilePicture={selectedCustomer.isListCustomerAttachment}
            beforeUpload={beforeUpload}
          />
        );
      case AddEditProfilePictureStep.STEP3:
        return (
          <CropAndRotateModal
            onSubmit={onSubmit}
            cropperRef={cropperRef}
            errorMessage={errorMessageFile}
            imageUrl={imageUrl}
            rotate={rotate}
            onCancel={onCancelStep}
            isLoading={isUploadingImage}
          />
        );
      default:
        return <></>;
    }
  };

  const renderHeader = () => {
    const title =
      steps === AddEditProfilePictureStep.STEP2 && selectedCustomer?.isListCustomerAttachment
        ? 'Change profile picture'
        : AddEditProfilePictureExtensions[steps].title;

    return (
      <Inline alignItems={'center'}>
        {AddEditProfilePictureExtensions[steps].canGoBack && (
          <Button
            size="sm"
            borderColor="$bodyLight"
            kind={'neutral'}
            emphasis="quiet"
            marginRight="$space150"
            onClick={onPreviousStep}
            maxWidth={'32px'}
            className="mr-1 text-gray-dark-2"
          >
            <ArrowLeft />
          </Button>
        )}
        <Text emphasis="bold" size="medium" color={'$black'} lineHeight={'28px !important'}>
          {title}
        </Text>
      </Inline>
    );
  };

  useEffect(() => {
    if (steps === AddEditProfilePictureStep.STEP2 && selectedCustomer?.isListCustomerAttachment) {
      loadPreviousUploadedPictures();
    }
  }, [steps]);

  useEffect(() => {
    errorMessageFile === null && onNextStep();
  }, [errorMessageFile]);

  return (
    <Modal
      isLockScroll
      height={
        steps === AddEditProfilePictureStep.STEP2 && !selectedCustomer?.isListCustomerAttachment
          ? 442
          : AddEditProfilePictureExtensions[steps].modalHeight
      }
      maxWidth={AddEditProfilePictureExtensions[steps].modalWidth}
      header={renderHeader()}
      onClose={() => {
        if (!isUploadingImage) {
          steps === AddEditProfilePictureStep.STEP3 ? onCancelStep(ModalType.UNSAVED_CHANGES) : onClose();
        }
      }}
      size={'large'}
      headerStyles={AddEditProfilePictureExtensions[steps].headerStyle}
      bodyStyles={{
        flex: 1,
        paddingBottom: '16px',
        overflowY: 'hidden',
        paddingTop: steps === AddEditProfilePictureStep.STEP3 ? 14 : 0,
      }}
      isOpen={isOpen}
      footer={null}
      customCloseButtonStyles={{
        width: '32px !important',
        height: '32px !important',
      }}
    >
      {renderContent()}
      <ModalShortMessage
        isLoading={isLoading}
        isOpen={isOpenModel}
        modalType={modalType}
        onClose={onCloseModalShortMessage}
        onPressPrimaryButton={onPressPrimaryButton}
        onPressSecondaryButton={onPressSecondaryButton}
      />
    </Modal>
  );
};

export default connect(
  (state: IRootState) => ({ serviceProviderId: state.authStore.portalUser.serviceProviderId }),
  null,
)(ViewProfilePictureModal);
