import React, { PureComponent, ReactNode } from 'react';
import { Icon } from 'antd';

interface IFilterValidationProps {
  className?: string;
  icon?: string;
  content: ReactNode;
}

class Validation extends PureComponent<IFilterValidationProps, any> {
  render() {
    const { className = '', icon = 'check-circle', content } = this.props;
    let finalClassName = 'bordered-left border border-green bg-green-lightest inline-block ';

    finalClassName += className;

    return (
      <div className={finalClassName}>
        <div className="flex p-small">
          <div>
            <Icon type={icon} theme="filled" className="text-size-x2-large text-color-green" />
          </div>
          <div className="ml-small pr-x-large">{content}</div>
        </div>
      </div>
    );
  }
}

export default Validation;
