import { Col, Row } from 'antd';
import { FilterSection } from 'common-components/filter';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment';
import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import {
  AvailabilityUnavailabilityRequestModalType,
  AvailabilityUnavailabilityRequestTabType,
  FilterType,
} from 'utilities/enum-utils';
import AvailabilityUnavailabilityCard from '../AvailabilityUnavailabilityCard';
import AvailabilityUnavailabilityDetails from '../AvailabilityUnavailabilityDetails';

interface IAvailabilityUnavailabilityRequestTabItemProps {
  onClose: () => void;
  type: AvailabilityUnavailabilityRequestModalType;
  tabType: AvailabilityUnavailabilityRequestTabType;
  requestItems?: any; //! this can't be an interface as it maybe Availability or Unavailability request
  memberAvailabilities: typeof state.teamStore.memberAvailabilities;
  setHasBookingListingFilterChanged: typeof dispatch.bookingsStore.setHasBookingListingFilterChanged;
  setBookingsFilter?: typeof dispatch.bookingsStore.setBookingsFilter;
  bookingsFilter: typeof state.bookingsStore.bookingsFilter;
}
const availableFilters = [FilterType.DATE_RANGE];

function AvailabilityUnavailabilityRequestTabItem(props: IAvailabilityUnavailabilityRequestTabItemProps) {
  const {
    type,
    tabType,
    requestItems,
    memberAvailabilities,
    onClose,
    setHasBookingListingFilterChanged,
    setBookingsFilter,
    bookingsFilter,
  } = props;

  const firstRequest: any = _.head(requestItems);
  const [cardSelected, setCardSelected] = useState<any>(firstRequest);
  const [filterRequestItems, setFilterRequestItems] = useState<any>(null);

  const configFilters = [
    {
      filter: FilterType.DATE_RANGE,
      values: [],
      selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName,
    },
  ];

  if (_.isEmpty(bookingsFilter)) {
    setBookingsFilter(configFilters);
  }

  const _onChangeFilter = (filters: Array<any>) => {
    setHasBookingListingFilterChanged(true);
    setBookingsFilter(filters);
  };

  useLayoutEffect(() => {
    let filterRequestItems;
    if (!_.isEmpty(bookingsFilter)) {
      const dateFilterValues = bookingsFilter[0].values;
      const startDate = moment(dateFilterValues[0]).toDate();
      const endDate = moment(dateFilterValues[1]).toDate();
      filterRequestItems = requestItems.filter((requestItem: any) => {
        return moment(requestItem.startDate).isBetween(startDate, endDate, null, '[]');
      });
    } else {
      filterRequestItems = [...requestItems];
    }

    filterRequestItems = _.chain([...filterRequestItems])
      .map((item) => ({ ...item, updatedDate: moment(item.updatedDate, 'YYYY/MM/DD').format('MMMM YYYY') }))
      .groupBy('updatedDate')
      .value();
    setFilterRequestItems(filterRequestItems);
  }, [bookingsFilter]);

  return (
    <Row type="flex" gutter={24}>
      <Col span={9}>
        {tabType !== AvailabilityUnavailabilityRequestTabType.AVAILABILITY_REQUESTS && (
          <FilterSection
            availableFilters={availableFilters}
            filters={bookingsFilter}
            onChangeFilter={_onChangeFilter}
            displayTimezone={memberAvailabilities.availabilityTimezone}
            displayMoreFilter={false}
          />
        )}

        <div style={{ minHeight: '600px', maxHeight: '600px', overflowY: 'auto', paddingRight: '10px' }}>
          {_.map(filterRequestItems, (groupedItem, index) => {
            return (
              <React.Fragment key={index}>
                <div className="flex justify-between mb-12">
                  <Text color="black" weight="bold" size="large">
                    {index}
                  </Text>
                  <Text color="secondary">
                    {groupedItem.length} item{groupedItem.length > 1 && 's'}
                  </Text>
                </div>
                {_.map(groupedItem, (requestItem) => {
                  const oldRequestItem = _.find(
                    requestItems,
                    (item) =>
                      item.supportWorkerAvailabilityRequestId === requestItem.supportWorkerAvailabilityRequestId,
                  );
                  return (
                    <AvailabilityUnavailabilityCard
                      key={requestItem.supportWorkerAvailabilityRequestId}
                      cardIndex={requestItem.supportWorkerAvailabilityRequestId}
                      cardItem={oldRequestItem}
                      isSelected={
                        cardSelected?.supportWorkerAvailabilityRequestId ===
                        requestItem.supportWorkerAvailabilityRequestId
                      }
                      onClick={() => setCardSelected(oldRequestItem)}
                      tabType={tabType}
                      type={type}
                    />
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      </Col>
      <Col span={15}>
        <AvailabilityUnavailabilityDetails
          tabType={tabType}
          requestDetail={cardSelected}
          type={type}
          onClose={onClose}
        />
      </Col>
    </Row>
  );
}

const mapStateToProps = (state: IRootState) => ({
  memberAvailabilities: state.teamStore.memberAvailabilities,
  bookingsFilter: state.bookingsStore.bookingsFilter,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  setHasBookingListingFilterChanged: dispatch.bookingsStore.setHasBookingListingFilterChanged,
  setBookingsFilter: dispatch.bookingsStore.setBookingsFilter,
});
export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityUnavailabilityRequestTabItem);
