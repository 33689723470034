import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const divider: CSS = {
  boxSizing: 'border-box',
};

export const variants = css({
  variants: {
    type: {
      horizontal: {
        height: '$size10',
        width: '$sizeFull',
        backgroundColor: '$neutralLight2',
      },
      vertical: {
        height: '$sizeFull',
        minHeight: '1rem',
        width: '$size10',
        backgroundColor: '#3E3E3C',
        opacity: 0.16,
      },
    },
  },
});

export type DividerVariants = Stitches.VariantProps<typeof variants>;
