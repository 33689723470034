import React, { ReactElement } from 'react';
import { Inline, Text } from '@goodhuman-me/components';
import { Announce } from 'design-components';
import { Control, FormProvider, UseFormRegister, UseFormReturn, UseFormTrigger } from 'react-hook-form';
import { COLUMN_PROPERTIES } from '../../utils/constants';
import { MESSAGE_ERRORS, Member } from '../../utils/form-utils';
import EmailColumn from './EmailColumn';
import InviteToAppColumn from './InviteToAppColumn';
import PhoneNumberColumn from './PhoneColumn';
import WorkspacePermissionColumn from './WorkspacePermissionColumn';
import { FormComponents } from '../components/FormComponents';
import InfoWorkspacePermission from '../components/InfoWorkspacePermission';
import TooltipInfo from '../components/TooltipInfo';

type AddTeamMembersColumn = {
  title: string | ReactElement;
  dataIndex: string;
  key: string;
  width: number;
  marginLeft?: string;
  render: (_: unknown, record: Member) => ReactElement;
};

export const addTeamMembersColumns = (
  control: Control<{ teamMemberList: Member[] }>,
  register: UseFormRegister<{ teamMemberList: Member[] }>,
  trigger: UseFormTrigger<{ teamMemberList: Member[] }>,
  methods: UseFormReturn<{ teamMemberList: Member[] }>,
  teamMemberList: Member[],
): AddTeamMembersColumn[] => [
  {
    ...COLUMN_PROPERTIES.FIRST_NAME,
    render: (_: unknown, record: Member) => (
      <FormComponents.TextFieldItem
        placeholder="John"
        control={control}
        {...register(`teamMemberList.${record.index}.firstName` as const, {
          required: MESSAGE_ERRORS.FIRST_NAME,
          maxLength: {
            value: 50,
            message: MESSAGE_ERRORS.FIRST_NAME,
          },
          pattern: {
            value: /[0-9a-zA-Z_]/,
            message: MESSAGE_ERRORS.FIRST_NAME,
          },
          onBlur: () => void trigger(`teamMemberList.${record.index}.firstName`),
        })}
      />
    ),
  },
  {
    ...COLUMN_PROPERTIES.LAST_NAME,
    render: (_: unknown, record: Member) => (
      <FormComponents.TextFieldItem
        placeholder="Smith"
        control={control}
        {...register(`teamMemberList.${record.index}.lastName` as const, {
          required: MESSAGE_ERRORS.LAST_NAME,
          maxLength: {
            value: 50,
            message: MESSAGE_ERRORS.LAST_NAME,
          },
          pattern: {
            value: /[0-9a-zA-Z_]/,
            message: MESSAGE_ERRORS.LAST_NAME,
          },
          onBlur: () => void trigger(`teamMemberList.${record.index}.lastName`),
        })}
      />
    ),
  },
  {
    ...COLUMN_PROPERTIES.EMAIL,
    render: (_: unknown, record: Member) => (
      <FormProvider {...methods}>
        <EmailColumn memberDetails={record} teamMemberList={teamMemberList} />
      </FormProvider>
    ),
  },
  {
    ...COLUMN_PROPERTIES.PHONE,
    render: (_: unknown, record: Member) => (
      <FormProvider {...methods}>
        <PhoneNumberColumn memberDetails={record} />
      </FormProvider>
    ),
  },
  {
    ...COLUMN_PROPERTIES.WORKSPACE_PERMISSIONS,
    title: (
      <Inline justifyContent="space-between">
        <Inline gap="$space75" alignItems="center" height="23px">
          <Text>WORKSPACE PERMISSION</Text>
          <TooltipInfo
            isShow
            placement="top right"
            message={
              <InfoWorkspacePermission
                title="Workspace permissions"
                description="Unsure of what permission to set?"
                linkUrl="https://goodhuman.zendesk.com/hc/en-au/articles/9872648625945-Roles-and-Permissions-in-GoodHuman"
              />
            }
            content={
              <Inline alignItems="center">
                <Announce color="$neutralDark1" width="$medium" height="$medium" />
              </Inline>
            }
          />
        </Inline>
      </Inline>
    ),
    render: (_: unknown, record: Member) => (
      <FormProvider {...methods}>
        <WorkspacePermissionColumn memberDetails={record} teamMemberList={teamMemberList} />
      </FormProvider>
    ),
  },
  {
    ...COLUMN_PROPERTIES.INVITE_TO_APP,
    title: (
      <Inline justifyContent="center">
        <Text marginRight="$space150 !important">INVITE TO APP</Text>
      </Inline>
    ),
    render: (_: unknown, record: Member) => (
      <FormProvider {...methods}>
        <InviteToAppColumn memberDetails={record} teamMemberList={teamMemberList} />
      </FormProvider>
    ),
  },
];
