import React from 'react';
import { Text } from '@good/components';
import { ChevronDown } from '@good/icons';

type Label = {
  customLabel?: never;
  label: string;
  selectedLabel?: string;
  isSelected?: never;
};

type CustomLabel = {
  customLabel: JSX.Element;
  label?: never;
  selectedLabel?: never;
  isSelected?: boolean;
};

export type FilterLabelProps = Label | CustomLabel;

const FilterLabelDivider = () => (
  <span style={{ opacity: 0.32 }}>
    <Text className="font-thin">|</Text>
  </span>
);

export const FilterLabel = ({ customLabel, label, selectedLabel, isSelected }: FilterLabelProps) => {
  const isSelected_ = isSelected || selectedLabel !== undefined;

  return (
    <div className="flex" style={{ gap: 2.5 }}>
      {label && (
        <div className="flex" style={{ gap: 6 }}>
          <Text className={['font-semibold', !isSelected_ ? 'text-weak' : ''].join(' ')}>{label}</Text>
          {selectedLabel && <FilterLabelDivider />}
          <Text>{selectedLabel}</Text>
        </div>
      )}
      {customLabel && customLabel}
      <Text className={!isSelected_ ? 'text-weak' : ''}>
        <ChevronDown />
      </Text>
    </div>
  );
};
