import React, { Component } from 'react';
import { Title } from 'common-components/typography';
import * as H from 'history';
import { PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';
import { Col, Row } from 'antd';
import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import CustomAllViewsViewCard from './components/CustomAllViewsViewCard';
import { CustomViewsModalType, CustomViewsViewCardType } from 'utilities/enum-utils';
import CustomViewsModal from '../modals/CustomViewsModal';
import { ICrumb } from 'interfaces/common-interface';
import { ICustomView, IFilterValue } from 'interfaces/custom-views-interface';
import { RematchDispatcherAsync } from '@rematch/core';

interface ICustomAllViewsProps {
  pageName: string;
  pageNavigation: string;
  pageViews: ICustomView[];
  pageFilter: IFilterValue[];
  pageListingActiveTab: ICustomView;
  defaultFilterValue: IFilterValue[];
  doFetchPageViews: (action) => ReturnType<RematchDispatcherAsync>;
  doAddView: (action) => ReturnType<RematchDispatcherAsync>;
  doUpdateViewTab: (action) => ReturnType<RematchDispatcherAsync>;
  doDuplicateView: (action) => ReturnType<RematchDispatcherAsync>;
  doDeleteView: (action) => ReturnType<RematchDispatcherAsync>;
  setPageListingActiveTab: (action) => void;
  setSelectedFilterKey?: (action) => void;
  setPageFilter: (action) => void;
  createdByMeViews: ICustomView[];
  createdByOthersViews: ICustomView[];
  defaultViews: ICustomView[];
  history: H.History;
}

interface ICustomAllViewsState {
  isLoading: boolean;
  openAddModal: boolean;
}

class CustomAllViews extends Component<ICustomAllViewsProps, ICustomAllViewsState> {
  state = {
    isLoading: false,
    openAddModal: false,
  };

  private _openViewModal = () => {
    this.setState({ openAddModal: true });
  };

  private _closeViewModal = () => {
    this.setState({ openAddModal: false });
  };

  componentDidMount() {
    this.props.doFetchPageViews({});
  }

  render() {
    const { pageName, pageNavigation, history, defaultViews, createdByMeViews, createdByOthersViews } = this.props;
    const crumbs: ICrumb[] = [
      {
        title: _.startCase(pageName),
        target: pageNavigation,
      },
      {
        title: 'All Views',
      },
    ];
    // passing props and override pageListingActiveTab to null
    // cause CustomViewsModal creating new view no need pageListingActiveTab.
    const customViewsModalProps = { ...this.props, pageListingActiveTab: null };

    return (
      <>
        <div>
          <CustomViewsModal
            isOpen={this.state.openAddModal}
            onCloseViewModal={this._closeViewModal}
            onUpdateViewModal={this._openViewModal}
            type={CustomViewsModalType.CREATE}
            {...customViewsModalProps}
          />
          <BreadcrumbNav
            icon={'home'}
            theme={'filled'}
            crumbs={crumbs}
            isBordered={false}
            style={{ marginLeft: '-16px' }}
          />

          <div className='flex-row align-center justify-between pb-x-small'>
            <Title level={3} className='mv-none'>
              All Views
            </Title>

            <PrimaryButton size='large' onClick={this._openViewModal} className='ml-small'>
              Create new view
            </PrimaryButton>
          </div>
        </div>
        <div className=' pt-x-large pb-x-large'>
          <Row gutter={40}>
            <Col span={8}>
              <CustomAllViewsViewCard
                viewList={defaultViews}
                type={CustomViewsViewCardType.DEFAULT}
                to={{
                  pathname: pageNavigation,
                  state: { refresh: true, showActiveTab: true },
                }}
                {...this.props}
              />
            </Col>
            <Col span={8}>
              <CustomAllViewsViewCard
                to={{
                  pathname: pageNavigation,
                  state: { refresh: true, showActiveTab: true },
                }}
                viewList={createdByMeViews}
                type={CustomViewsViewCardType.CREATED_BY_ME}
                {...this.props}
              />
            </Col>
            <Col span={8}>
              <CustomAllViewsViewCard
                to={{
                  pathname: pageNavigation,
                  state: { refresh: true, showActiveTab: true },
                }}
                viewList={createdByOthersViews}
                type={CustomViewsViewCardType.CREATED_BY_OTHERS}
                {...this.props}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default CustomAllViews;
