import React from 'react';
import { Text, Space, Title } from '@good/ui/core';
import { SilTable } from 'views/sil-service/components/table';

import { SilCustomerData } from 'utilities/react-trpc';
import { customerColumns } from './customer-column-helper';
import { useTranslation } from 'react-i18next';
import { ArchivedEmptyState } from '../components/archived-empty-state';

type IArchivedCustomerTabProps = {
  archivedCustomers: SilCustomerData;
  customerCount: number;
};
export const ArchivedCustomerTab = ({ archivedCustomers, customerCount }: IArchivedCustomerTabProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceCustomersTabs' });

  return (
    <>
      <div className='flex flex-col w-full'>
        <div className='flex flex-col mb-small' style={{ gap: 4 }}>
          <Title order={3}>
            {t('Archived')} ({customerCount})
          </Title>
          <Text c='dimmed'> {t('archivedDescription')}</Text>
        </div>
        <Space h='md' />
        {archivedCustomers.length === 0 ? (
          <ArchivedEmptyState />
        ) : (
          <SilTable columns={customerColumns} data={archivedCustomers} />
        )}
      </div>
    </>
  );
};
