import { Button, Text } from '@goodhuman-me/components';
import { Form, Modal } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import TextField from 'common-components/text-field/TextField';
import React from 'react';
import { RiCloseFill } from 'react-icons/ri';
import '../css/new-form-builder-listing.css';

interface ICreateFormNameModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  onClickCreate: (name: string) => void;
}

const CreateFormNameModal = (props: ICreateFormNameModalProps): JSX.Element => {
  const { isOpen, onClose, onClickCreate, form } = props;

  const handleClickCreateForm = () => {
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      const formName: string = form.getFieldValue('formName');
      onClickCreate(formName);
    }
  };

  const handleCloseFormModal = () => {
    form.resetFields();
    onClose();
  };

  const formNameValidator = (rule, value, callback) => {
    value = value.trim();
    try {
      if (!value) {
        throw Error('Please enter a form name');
      }
      if (value.length > 50) {
        throw Error('Please enter less than 50 characters');
      }

      callback();
    } catch (e) {
      callback(e);
    }
  };

  return (
    <Modal
      visible={isOpen}
      footer={
        <>
          <Button kind="accent" emphasis="quiet" size="large" marginRight={16} onClick={handleCloseFormModal}>
            Cancel
          </Button>
          <Button kind="accent" emphasis="filled" size="large" onClick={handleClickCreateForm}>
            Create form
          </Button>
        </>
      }
      title={
        <div className="flex align-center justify-between">
          <Text size="medium" fontWeight={'$bold'}>
            Name your form
          </Text>
          <button className="ant-modal-close-button" onClick={handleCloseFormModal}>
            <RiCloseFill size={25} color="#706F6D" />
          </button>
        </div>
      }
      onCancel={handleCloseFormModal}
      closable={false}
      centered
      width={600}
      className="create-form-name-modal"
    >
      <Form.Item className="mb-none">
        {form.getFieldDecorator('formName', {
          rules: [{ validator: formNameValidator }],
          initialValue: '',
        })(<TextField label="Form name" placeholder="Enter a form name" />)}
      </Form.Item>
    </Modal>
  );
};

export default Form.create<ICreateFormNameModalProps>()(CreateFormNameModal);
