import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const BorderRight = forwardRef(function BorderRight(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 3a.75.75 0 0 0-1.5 0v18a.75.75 0 0 0 1.5 0V3ZM16 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-4 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm4 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-4 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-7 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM5 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm8-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-3-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm8-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM8 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM5 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
