import React, { useEffect, useLayoutEffect, useRef } from 'react';
import * as d3 from 'd3';
import { CurrencyFormatter, Stack, Text, css } from '@goodhuman-me/components';

import * as styles from './burn-chart.css';
import { Legend } from './legend';

import type { NumberValue } from 'd3';
import { BudgetCategory } from 'src/views/budgets/budget';
import { coordinationTimeString } from '../../../../../../../utilities/budgeting/support-coordination';

type Coordinate = [number, number];
type Point = { x: Coordinate; y: Coordinate };

const width = 536;
const height = 336;

const CRITICAL_COLORS = {
  LINE: '#D63737',
  FILLS: ['#FBEBEB', '#F7D7D7'],
};
const NOTICE_COLORS = {
  LINE: '#ED7321',
  FILLS: ['#FDF0E8', '#FBE4D5'],
};

const POSITIVE_COLORS = {
  LINE: '#369B80',
  FILLS: ['#EBF5F2', '#C2E0D8'],
};

const NEUTRAL_COLORS = {
  LINE: '#6F6E6D',
  FILLS: ['#F5F4F4', '#E0DEDC'],
};

const STATUS_COLORS = {
  SIGNIFICANTLY_AHEAD: CRITICAL_COLORS,
  SIGNIFICANTLY_BEHIND: CRITICAL_COLORS,
  OVERBUDGET: CRITICAL_COLORS,
  SLIGHTLY_AHEAD: NOTICE_COLORS,
  SLIGHTLY_BEHIND: NOTICE_COLORS,
  ON_TRACK: POSITIVE_COLORS,
  NONE: NEUTRAL_COLORS,
  WITHIN_BUDGET: NEUTRAL_COLORS,
};

export function BurnChart(
  props: Pick<BudgetCategory, 'actuals' | 'status' | 'projections' | 'spendGraph' | 'supportCoordinationTracking'> & {
    displayInHours: boolean;
  },
): JSX.Element {
  let { actuals, status, projections, displayInHours, spendGraph, supportCoordinationTracking } = props;

  let {
    quoted: { value: allocated },
  } = actuals;
  allocated = displayInHours ? supportCoordinationTracking.quoted : allocated;

  let {
    spend: { value: spend },
  } = projections;
  spend = displayInHours ? supportCoordinationTracking.spent : spend;

  let {
    interval: { start, end },
  } = spendGraph;

  let totalSpend = 0;
  let totalTime = 0;

  let data = spendGraph.plotData.map((d) => {
    totalSpend = d.cumulativeSpend.cost;
    totalTime = d.cumulativeSpend.hours;

    return {
      date: d.startDateTime,
      value: displayInHours ? d.cumulativeSpend.hours : d.cumulativeSpend.cost,
    };
  });

  let yTotal = displayInHours ? totalTime : totalSpend;
  let xDomain = [new Date(start), new Date(end)];
  let yDomain = [0, Math.max(spend, allocated, yTotal)];

  // let { allocated, data, status, xDomain, yDomain } = props;

  const xScale = d3.scaleTime().domain(xDomain).range([0, width]);
  const yScale = d3.scaleLinear().domain(yDomain).range([height, 0]);

  let ref = useRef(null);

  let future = [];
  let past = [{ date: xDomain[0], value: yDomain[0] }];
  let today = new Date();
  const startDate = new Date(start);
  const endDate = new Date(end);

  for (let d of data) {
    if (new Date(d.date).getTime() > today.getTime()) future.push(d);
    else past.push(d);
  }

  let elapsedTimeDataPoint: Date;
  const betweenBudgetingPeriod = today.getTime() < endDate.getTime() && today.getTime() > startDate.getTime();

  if (betweenBudgetingPeriod) {
    elapsedTimeDataPoint = today;
  } else if (today.getTime() < startDate.getTime()) {
    elapsedTimeDataPoint = startDate;
  } else if (today.getTime() > endDate.getTime()) {
    elapsedTimeDataPoint = endDate;
  }

  past.push({ date: elapsedTimeDataPoint, value: past[past.length - 1].value });
  future = [past[past.length - 1], ...future];

  useEffect(function createDataPoint() {
    if (ref.current) {
      let g = d3
        .select(ref.current)
        .selectAll('.circle')
        .data(past)
        .enter()
        .filter((d, i) => {
          return i === past.length - 1;
        });

      betweenBudgetingPeriod &&
        g
          .append('circle')
          .attr('class', 'circle')
          .attr('r', 10)
          .attr('fill', STATUS_COLORS[status].FILLS[1])
          .attr('cx', (d) => xScale(new Date(d.date)))
          .attr('cy', (d) => yScale(d.value))
          .attr('opacity', 0.7);

      betweenBudgetingPeriod &&
        g
          .append('circle')
          .attr('class', 'circle')
          .attr('fill', 'white')
          .attr('stroke', STATUS_COLORS[status].LINE)
          .attr('stroke-width', 2)
          .attr('r', 3.5)
          .attr('cx', (d) => xScale(new Date(d.date)))
          .attr('cy', (d) => yScale(d.value));
    }
  });

  return (
    <Stack gap="$medium" alignItems="center">
      <svg className={css(styles.reset, styles.svg)()} height={height} width={width} ref={ref}>
        <g>
          <linearGradient
            gradientTransform="rotate(150 .5 .5)"
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            id={`gradient-${status}`}
          >
            <stop stopColor={STATUS_COLORS[status].FILLS[0]} offset="0%" />
            <stop stopColor={STATUS_COLORS[status].FILLS[1]} offset="100%" />
          </linearGradient>

          {/* <OverPlot data={over} /> */}
          <AxisX domain={xDomain} />
          <AxisY domain={yDomain} displayInHours={displayInHours} />
          <LineOfBestFit x={[xScale(xDomain[0]), xScale(xDomain[1])]} y={[yScale(yDomain[0]), yScale(allocated)]} />

          <Fills
            status={status}
            allocated={allocated}
            data={past}
            xScale={xScale}
            yScale={yScale}
            x={[xScale(xDomain[0]), xScale(xDomain[1])]}
            y={[yScale(allocated), yScale(allocated)]}
          />

          <UnderPlot status={status} data={past} xScale={xScale} yScale={yScale} />

          <FuturePlot status={status} data={future} xScale={xScale} yScale={yScale} />

          <Allocated
            value={allocated}
            x={[xScale(xDomain[0]), xScale(xDomain[1])]}
            y={[yScale(allocated), yScale(allocated)]}
            displayInHours={displayInHours}
          />
        </g>
      </svg>

      <Legend stroke={STATUS_COLORS[status].LINE} />
    </Stack>
  );
}

function Fills(props) {
  // eslint-disable-next-line react/prop-types
  let { data, status, allocated, xScale, yScale } = props;
  let fillRef = useRef(null);

  useEffect(() => {
    d3.select(fillRef.current)
      .append('path')
      .datum(data)
      .attr('fill', `url("#gradient-${status}")`)
      .attr('fill-opacity', 0.5)
      .attr('stroke', 'none')
      .attr(
        'd',
        d3
          .area()
          .curve(d3.curveStepAfter)
          .x((d) => xScale(new Date(d.date)))
          .y0(height)
          .y1((d) => {
            // if (yScale(allocated) < yScale(d.values)) return yScale(d.value)
            // else return yScale(allocated)
            return d.value <= allocated ? yScale(d.value) : yScale(allocated);
          }),
      );
  }, [fillRef.current]);

  useEffect(() => {
    d3.select(fillRef.current)
      .append('path')
      .datum(data)
      .attr('fill', STATUS_COLORS[status].FILLS[1])
      .attr('fill-opacity', 0.7)
      .attr('stroke', 'none')
      .attr(
        'd',
        d3
          .area()
          .curve(d3.curveStepAfter)
          .x((d) => xScale(new Date(d.date)))
          .y0(yScale(allocated))
          .y1((d) => {
            // if (yScale(allocated) < yScale(d.values)) return yScale(d.value)
            // else return yScale(allocated)
            return d.value >= allocated ? yScale(d.value) : yScale(allocated);
          }),
      );
  }, [fillRef.current]);

  return <g ref={fillRef}></g>;
}

/*******************************************************************
 ** Future
 ******************************************************************/
function UnderPlot(props) {
  // eslint-disable-next-line react/prop-types
  let { data, xScale, yScale, status } = props;
  let pathRef = useRef();

  useEffect(() => {
    if (!pathRef.current) return;
    d3.select(pathRef.current)
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', STATUS_COLORS[status].LINE)
      .attr('stroke-width', 2)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr(
        'd',
        d3
          .line()
          .curve(d3.curveStepAfter)
          .x(function (d) {
            return xScale(new Date(d.date));
          })
          .y(function (d) {
            return yScale(d.value);
          }),
      );
  }, [pathRef.current]);

  return <g ref={pathRef} />;
}

function FuturePlot(props) {
  // eslint-disable-next-line react/prop-types
  let { data, status, xScale, yScale } = props;
  let pathRef = useRef();

  useEffect(() => {
    if (pathRef.current)
      d3.select(pathRef.current)
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', STATUS_COLORS[status].LINE)
        .attr('stroke-dasharray', '4')
        .attr('stroke-width', 2)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr(
          'd',
          d3
            .line()
            .curve(d3.curveStepAfter)
            .x(function (d) {
              return xScale(new Date(d.date));
            })
            .y(function (d) {
              return yScale(d.value);
            }),
        );
  }, [pathRef.current]);

  return <g ref={pathRef} />;
}

/*******************************************************************
 ** Allocated
 ******************************************************************/

type AllocatedProps = { value: number } & Point & { displayInHours: boolean };

function Allocated({ value, x, y, displayInHours }: AllocatedProps) {
  let labelRef = useRef<SVGRectElement>(null);
  let lineRef = useRef<SVGLineElement>(null);
  let textRef = useRef<SVGTextElement>(null);

  useLayoutEffect(
    function dynamicallySizeLabel() {
      if (labelRef.current === null || lineRef.current === null || textRef.current === null) return;

      const padding = 16;
      let textWidth = textRef.current.getComputedTextLength();
      let rectangleWidth = textWidth + padding;
      let lineX1 = rectangleWidth + padding * 3;
      labelRef.current.setAttribute('width', rectangleWidth.toString());
      lineRef.current.setAttribute('x1', lineX1.toString());
    },
    [labelRef.current, lineRef.current, textRef.current],
  );

  return (
    <g>
      <line x1={x[0]} x2={24} y1={y[1]} y2={y[1]} strokeWidth={4} stroke="#1A2859" strokeLinecap="round" />
      <line
        ref={lineRef}
        x1={18}
        x2={x[1]}
        y1={y[1]}
        y2={y[1]}
        strokeWidth={4}
        stroke="#1A2859"
        strokeLinecap="round"
      />

      <g>
        <rect ref={labelRef} x={36} y={y[0] - 10} fill="#1A2859" width={132} height={20} rx={6}></rect>

        <Text asChild size="xxsmall">
          <text ref={textRef} x={44} y={y[0] + 4} fill="white">
            Allocated{' '}
            <tspan style={{ fontWeight: 700 }}>
              {displayInHours ? <tspan>{coordinationTimeString(value)}</tspan> : <CurrencyFormatter value={value} />}
            </tspan>
          </text>
        </Text>
      </g>
    </g>
  );
}

/*******************************************************************
 ** AxisX
 ******************************************************************/

type AxisXProps = { domain: Date[] };

function formatXTick(interval: Date | NumberValue, i: number) {
  if (interval instanceof Date) {
    let label = new Intl.DateTimeFormat('en-AU', {
      month: 'short',
      day: 'numeric',
    }).format(interval);
    // return label
    return i % 3 !== 0 ? ' ' : label; // Only show label on every third tick.
  }

  return ' ';
}

function AxisX(props: AxisXProps) {
  let { domain } = props;
  const xScale = d3.scaleTime().domain(domain).range([0, width]).nice();
  // const xAxis = d3.axisBottom(xScale)
  const xAxis = d3.axisBottom(xScale).tickSize(10).tickPadding(10).tickFormat(formatXTick);
  let lineRef = useRef<SVGLineElement>(null);

  useEffect(
    function createAxis() {
      if (!lineRef.current) return;

      d3.select(lineRef.current).call(xAxis);
    },
    [lineRef.current],
  );

  useEffect(
    function createGuidelines() {
      if (!lineRef.current) return;

      d3.select(lineRef.current)
        .selectAll('line')
        .attr('stroke-dasharray', `5, 5`)
        .attr('stroke', 'var(--colors-bodyLight1')
        .attr('y1', `${10}px`)
        .attr('y2', `-${height}px`);
    },
    [lineRef.current],
  );

  return <g className={styles.axis()} ref={lineRef} transform={`translate(0, ${height})`} />;
}

/*******************************************************************
 ** AxisY
 ******************************************************************/

type AxisYProps = { domain: number[]; displayInHours: boolean };

function formatCurrencyYTick(interval: NumberValue) {
  let label = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'aud',
    minimumFractionDigits: 0,
  }).format(interval as number);

  return label;
}

function formatHoursYTick(hours: number) {
  return coordinationTimeString(hours);
}

function AxisY(props: AxisYProps) {
  let { domain, displayInHours } = props;
  const yScale = d3.scaleLinear().domain(domain).range([height, 0]);
  const yAxis = d3
    .axisLeft(yScale)
    .tickSize(16)
    .tickPadding(10)
    .tickFormat(displayInHours ? formatHoursYTick : formatCurrencyYTick);
  let lineRef = useRef<SVGLineElement>(null);

  useEffect(
    function createAxis() {
      if (!lineRef.current) return;

      d3.select(lineRef.current).call(yAxis);
    },
    [lineRef.current],
  );

  useEffect(
    function createGuideLines() {
      if (lineRef.current)
        d3.select(lineRef.current)
          .selectAll('line')
          .attr('stroke', 'var(--colors-bodyLight1)')
          .attr('x1', `-${10}px`)
          .attr('x2', `${width}px`);
    },
    [lineRef.current],
  );

  return <g className={styles.axis()} ref={lineRef} transform={`translate(0, 0)`} />;
}

/*******************************************************************
 ** LineOfBestFit
 ******************************************************************/

type LineOfBestFitProps = Point;

function LineOfBestFit(props: LineOfBestFitProps) {
  let { x, y } = props;

  return (
    <line
      x1={x[0]}
      x2={x[1]}
      y1={y[0]}
      y2={y[1]}
      strokeWidth="4"
      stroke="#8B66A2"
      strokeDasharray="0, 10"
      strokeLinecap="round"
    />
  );
}
