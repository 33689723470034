import { Avatar, Checkbox, Icon, Input } from 'antd';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import { PrimaryButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { IActivityGroupUsers } from 'interfaces/user-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IGroupServiceActivityGroup } from 'src/interfaces/service-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ActivityGroupModalType, BookingStatus, CustomerActiveType, ShiftSlotStatus } from 'utilities/enum-utils';
const { Search } = Input;

interface ISelectMembersProps {
  serviceId: string;
  activityGroup: IGroupServiceActivityGroup;
  activityGroupAddType: ActivityGroupModalType;
  onInteractWithAddedMembers: (addedMembers: IActivityGroupUsers[]) => void;
  onClose: () => void;
  membersInActivityGroup: IActivityGroupUsers[];
  selectedSession: typeof state.groupServiceStore.selectedSession;
  doGetGroupServiceCustomers: typeof dispatch.customersStore.doGetGroupServiceCustomers;
  setGroupServiceCustomers: typeof dispatch.customersStore.setGroupServiceCustomers;
  groupServiceCustomers: typeof state.customersStore.groupServiceCustomers;
  doFetchGroupServiceTeamMembers: typeof dispatch.servicesStore.doFetchGroupServiceTeamMembers;
  setGroupServiceWorkerList: typeof dispatch.servicesStore.setGroupServiceWorkerList;
  groupServiceWorkerList: typeof state.servicesStore.groupServiceWorkerList;
  doFetchSessionCustomerDetails: typeof dispatch.groupServiceStore.doFetchSessionCustomerDetails;
  sessionCustomerCustomerBookings: typeof state.groupServiceStore.sessionCustomerCustomerBookings;
  setSelectedShiftSlots: typeof dispatch.groupServiceStore.setSelectedShiftSlots;
  doFetchSessionShiftSlotOverview: typeof dispatch.groupServiceStore.doFetchSessionShiftSlotOverview;
  sessionShiftSlotOverview: typeof state.groupServiceStore.sessionShiftSlotOverview;
}

interface ISelectMembersState {
  users: IActivityGroupUsers[];
  memberAlreadyInActivityGroup: IActivityGroupUsers[];
  filteredMemberArray: IActivityGroupUsers[];
  itemsSelectedToAdd: IActivityGroupUsers[];
  isLoading: boolean;
  isSearching: boolean;
  isSessionActivityGroup: boolean;
  searchValue: string;
  checkedMembers: any[];
}

class SelectMembers extends Component<ISelectMembersProps, ISelectMembersState> {
  state = {
    users: [],
    isLoading: false,
    isSearching: false,
    isSessionActivityGroup: false,
    searchValue: '',
    memberAlreadyInActivityGroup: this.props.membersInActivityGroup,
    filteredMemberArray: null,
    checkedMembers: [],
    itemsSelectedToAdd: [],
  };
  searchRef: React.RefObject<HTMLDivElement> = React.createRef();

  private _searchText = async (value) => {
    this.setState({ filteredMemberArray: [] });
    const allUserKeys = _.map(this.state.users, (user) => user.userId);
    const allUserAlreadyInActivityKeys = _.map(this.state.memberAlreadyInActivityGroup, (user) => user.userId);
    const memberNotInActivityGroup = _.filter(
      allUserKeys,
      (userKey) => !allUserAlreadyInActivityKeys.includes(userKey),
    );

    let filteredItem = _.filter(this.state.users, (user) => memberNotInActivityGroup.includes(user.userId));
    if (!_.isEmpty(value)) {
      filteredItem = _.filter(
        this.state.users,
        (user) =>
          (user.firstName + ' ' + user.lastName).toLowerCase().includes(value.toLowerCase()) &&
          memberNotInActivityGroup.includes(user.userId),
      );
    }
    this.setState({ filteredMemberArray: filteredItem });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _handleOnSearchChange = (e) => {
    this.setState({ searchValue: e.target.value });
    if (e.target.value.length >= 0) {
      this.setState({ isSearching: true });
      this._debounceSearch(e.target.value);
    }
  };

  private _handleAddCheckedMember = () => {
    const { checkedMembers, memberAlreadyInActivityGroup } = this.state;
    const newAddedArray = memberAlreadyInActivityGroup.concat(checkedMembers);
    this.setState({ memberAlreadyInActivityGroup: newAddedArray, itemsSelectedToAdd: checkedMembers });
    this.setState({ isSearching: false, searchValue: '', checkedMembers: [] });
    this.props.onInteractWithAddedMembers(newAddedArray);
  };

  private _handleRemoveSelectedMember = (selectedMemberKey: string) => {
    const { memberAlreadyInActivityGroup } = this.state;
    const filteredMemberArray = _.filter(memberAlreadyInActivityGroup, (member) => member.userId != selectedMemberKey);
    this.setState({ memberAlreadyInActivityGroup: filteredMemberArray });
    this.props.onInteractWithAddedMembers(filteredMemberArray);
  };

  private _handleClassifyByMemberType = async () => {
    const {
      activityGroup,
      activityGroupAddType,
      groupServiceCustomers,
      groupServiceWorkerList,
      sessionShiftSlotOverview,
      sessionCustomerCustomerBookings,
    } = this.props;
    const { isSessionActivityGroup } = this.state;
    let allCustomers = [];
    let allTeamMembers = [];
    if ((activityGroup.serviceActivityGroupId && isSessionActivityGroup) || isSessionActivityGroup) {
      const validBookingStatus = [BookingStatus.CONFIRMED, BookingStatus.INPROGRESS, BookingStatus.COMPLETED];
      const sessionCustomerIds = _.chain(sessionCustomerCustomerBookings)
        .filter((customer) => validBookingStatus.includes(customer.status))
        .map((customer) => customer.customerUserId)
        .value();
      allCustomers = _.filter(groupServiceCustomers, (serviceCustomer) =>
        _.includes(sessionCustomerIds, serviceCustomer.customerUserId),
      );

      const unavailableStatuses = [ShiftSlotStatus.ON_LEAVE, ShiftSlotStatus.LEAVE_APPROVED];

      const shiftSlotWorkerIds = sessionShiftSlotOverview?.shiftSlots
        ?.filter((shift) => !unavailableStatuses.includes(shift.shiftSlotStatus))
        .map((shift) => shift.supportWorkerId);

      const nonDuplicateShiftSlotWorkerIds = _.filter(
        shiftSlotWorkerIds,
        (workerId, index) => shiftSlotWorkerIds.indexOf(workerId) === index,
      );
      allTeamMembers = _.filter(groupServiceWorkerList, (worker) =>
        _.includes(nonDuplicateShiftSlotWorkerIds, worker.supportWorkerId),
      );
    } else {
      allCustomers = groupServiceCustomers;
      allTeamMembers = groupServiceWorkerList;
    }

    if (activityGroupAddType === ActivityGroupModalType.ADD_CUSTOMER) {
      const newCustomerArray: IActivityGroupUsers[] = _.map(allCustomers, (customer) => {
        return {
          userId: customer.customerUserId,
          firstName: customer.customerFirstName,
          lastName: customer.customerLastName,
          attachmentUrl: customer.customerAvatarUrl,
        };
      });
      this.setState({ users: newCustomerArray });
    }
    if (activityGroupAddType === ActivityGroupModalType.ADD_TEAM_MEMBER) {
      const newTeamMemberArray: IActivityGroupUsers[] = _.map(allTeamMembers, (teamMember) => {
        return {
          userId: teamMember.teamMemberUserId,
          firstName: teamMember.teamMemberFirstName,
          lastName: teamMember.teamMemberLastName,
          attachmentUrl: teamMember.teamMemberAvatarUrl,
        };
      });
      this.setState({ users: newTeamMemberArray });
    }
  };

  private _renderSelectedUsersCard = (memberAlreadyInActivityGroup: IActivityGroupUsers[], isAvatarSquare: boolean) => {
    return (
      !_.isEmpty(memberAlreadyInActivityGroup) && (
        <div className="mt-12 flex-row" style={{ gap: '4px', flexWrap: 'wrap' }}>
          {_.map(memberAlreadyInActivityGroup, (user) => {
            return (
              <div className="inline-block" key={user.userId}>
                <div
                  className="flex-row bg-white rounded-big align-center ph-small bordered"
                  style={{
                    maxHeight: '32px',
                    minHeight: '32px',
                  }}
                >
                  <div className="flex-row align-center">
                    <Avatar size={20} src={user.attachmentUrl} shape={isAvatarSquare ? 'square' : 'circle'} />
                    <Text weight="regular" className="ml-small" size="small">
                      {user.firstName + ' ' + user.lastName}
                    </Text>
                  </div>

                  <Icon
                    className="text-color-black cursor-pointer ml-12"
                    type="close"
                    theme="outlined"
                    style={{ fontSize: '14px' }}
                    onClick={() => {
                      this._handleRemoveSelectedMember(user.userId);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )
    );
  };

  private _handleClickOutside = (event) => {
    // handle mouse outside control
    if (this.searchRef.current && !this.searchRef.current.contains(event.target)) {
      this.setState({ isSearching: false, searchValue: null, checkedMembers: [] });
    }
  };

  private _handleClickInsideSearchInput = (event) => {
    const { searchValue } = this.state;
    // handle mouse inside search input
    if (
      this.searchRef.current &&
      this.searchRef.current.contains(event.target) &&
      event.target.type === 'text' &&
      !searchValue
    ) {
      this.setState({ isSearching: true, searchValue: '' });
      this._searchText('');
    }
  };

  componentDidMount = async () => {
    const {
      serviceId,
      selectedSession,
      activityGroup,
      activityGroupAddType,
      doGetGroupServiceCustomers,
      doFetchSessionCustomerDetails,
      doFetchGroupServiceTeamMembers,
      doFetchSessionShiftSlotOverview,
      setGroupServiceCustomers,
      setGroupServiceWorkerList,
    } = this.props;
    document.addEventListener('mousedown', this._handleClickOutside);
    this.setState({ isLoading: true });
    this.searchRef.current.addEventListener('mousedown', this._handleClickInsideSearchInput);
    if (activityGroup.serviceDateTimeActivityGroupId && activityGroup.startDateTime && activityGroup.endDateTime) {
      this.setState({ isSessionActivityGroup: true });
      await doFetchSessionCustomerDetails({
        serviceId,
        serviceDateTimeId: selectedSession.serviceDateTimeId,
      });
      await doFetchSessionShiftSlotOverview({
        serviceId,
        serviceDateTimeId: selectedSession.serviceDateTimeId,
      });
    }

    // reset store data
    // due to fetching is appending...
    if (activityGroupAddType === ActivityGroupModalType.ADD_TEAM_MEMBER) {
      setGroupServiceWorkerList([]);
      await doFetchGroupServiceTeamMembers({
        serviceId,
      });
    } else {
      setGroupServiceCustomers([]);
      await doGetGroupServiceCustomers({
        serviceId,
        customerType: CustomerActiveType.ACTIVE,
      });
    }

    this._handleClassifyByMemberType();
    this._searchText(this.state.searchValue);
    this.setState({ isLoading: false });
  };

  componentDidUpdate(prevProps: Readonly<ISelectMembersProps>): void {
    const { membersInActivityGroup, groupServiceCustomers, groupServiceWorkerList } = this.props;
    const { searchValue } = this.state;
    if (prevProps.membersInActivityGroup !== membersInActivityGroup) {
      this.setState({ memberAlreadyInActivityGroup: membersInActivityGroup });
    }

    // deps of _searchText
    if (
      prevProps.groupServiceCustomers !== groupServiceCustomers ||
      prevProps.groupServiceWorkerList !== groupServiceWorkerList
    ) {
      this._searchText(searchValue);
    }
  }

  componentWillUnmount(): void {
    this.props.setGroupServiceCustomers([]);
    this.props.setGroupServiceWorkerList([]);
    document.removeEventListener('mousedown', this._handleClickOutside);
    this.searchRef.current.removeEventListener('mousedown', this._handleClickInsideSearchInput);
  }

  render() {
    const { activityGroupAddType } = this.props;
    const { searchValue, isSearching, isLoading, filteredMemberArray, checkedMembers, memberAlreadyInActivityGroup } =
      this.state;

    const memberType = activityGroupAddType === ActivityGroupModalType.ADD_TEAM_MEMBER ? 'team member' : 'customer';
    const membersCount = memberAlreadyInActivityGroup.length;

    return (
      <div>
        <div className="mt-small">
          <div className="mb-small">
            <SubTitle>{`Add ${memberType}s`}</SubTitle>
          </div>
          <div className="bg-quaternary rounded p-12">
            <div className="flex justify-around p-5" ref={this.searchRef}>
              <div className="width-full relative">
                <Search
                  placeholder={
                    activityGroupAddType === ActivityGroupModalType.ADD_CUSTOMER
                      ? 'Search for a customer'
                      : 'Search for a member'
                  }
                  onChange={this._handleOnSearchChange}
                  value={searchValue}
                  allowClear
                />
                {isSearching && (
                  <div
                    className="absolute bg-white width-full p-medium bordered rounded overflow-y-scroll"
                    style={{ zIndex: 5 }}
                  >
                    {
                      // show loading only when input first time
                      filteredMemberArray === null || isLoading ? (
                        <Icon type="loading" />
                      ) : !_.isEmpty(filteredMemberArray) ? (
                        <CheckboxGroup
                          className="width-full"
                          onChange={(value) => {
                            this.setState({ checkedMembers: value });
                          }}
                          value={checkedMembers}
                        >
                          {_.map(filteredMemberArray, (user) => (
                            <div
                              className="block p-small bordered-bottom width-full"
                              style={{ maxHeight: '48px' }}
                              key={user.userId}
                            >
                              <Checkbox value={user}>
                                <span>
                                  <Avatar size={32} src={user.attachmentUrl} shape={'circle'} />
                                </span>
                                <span className="ml-small">{user.firstName + ' ' + user.lastName}</span>
                              </Checkbox>
                            </div>
                          ))}
                        </CheckboxGroup>
                      ) : (
                        'No result found...'
                      )
                    }
                  </div>
                )}
              </div>
              <PrimaryButton
                size="default"
                className="ml-12"
                onClick={this._handleAddCheckedMember}
                disabled={_.isEmpty(checkedMembers)}
              >
                Add
              </PrimaryButton>
            </div>
            <div className="mt-small">
              <Text color="secondary" size="large">
                {membersCount > 0 ? (
                  <>
                    <span className="text-weight-bold">{membersCount}</span> {memberType}
                    {membersCount > 1 ? 's' : ''}
                  </>
                ) : (
                  `No ${memberType}s`
                )}
              </Text>
              <div>
                {this._renderSelectedUsersCard(
                  memberAlreadyInActivityGroup,
                  activityGroupAddType === ActivityGroupModalType.ADD_TEAM_MEMBER,
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceCustomers: state.customersStore.groupServiceCustomers,
  groupServiceWorkerList: state.servicesStore.groupServiceWorkerList,
  sessionCustomerCustomerBookings: state.groupServiceStore.sessionCustomerCustomerBookings,
  selectedSession: state.groupServiceStore.selectedSession,
  sessionShiftSlotOverview: state.groupServiceStore.sessionShiftSlotOverview,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetGroupServiceCustomers: dispatch.customersStore.doGetGroupServiceCustomers,
  setGroupServiceCustomers: dispatch.customersStore.setGroupServiceCustomers,
  doFetchGroupServiceTeamMembers: dispatch.servicesStore.doFetchGroupServiceTeamMembers,
  setGroupServiceWorkerList: dispatch.servicesStore.setGroupServiceWorkerList,
  doFetchSessionCustomerDetails: dispatch.groupServiceStore.doFetchSessionCustomerDetails,
  setSelectedShiftSlots: dispatch.groupServiceStore.setSelectedShiftSlots,
  doFetchSessionShiftSlotOverview: dispatch.groupServiceStore.doFetchSessionShiftSlotOverview,
});

export default connect(mapState, mapDispatch)(SelectMembers);
