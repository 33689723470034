import { IVersionHistoryWorkflowTemplate } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import WorkflowTemplateDetailVersionHistoryListing from './components/WorkflowTemplateDetailVersionHistoryListing';
import WorkflowTemplateDetailVersionHistoryModal from './components/WorkflowTemplateDetailVersionHistoryModal';

interface IWorkflowTemplateDetailVersionHistoryProps {
  versionHistoryWorkflowTemplates: typeof state.workflowStore.versionHistoryWorkflowTemplates;
  selectedWorkflowTemplate: typeof state.workflowStore.selectedWorkflowTemplate;
  doGetWorkflowTemplateVersionHistoryList: typeof dispatch.workflowStore.doGetWorkflowTemplateVersionHistoryList;
}

interface IWorkflowTemplateDetailVersionHistoryState {
  isLoading: boolean;
  isOpenVersionHistoryModal: boolean;
  selectedVersionHistory?: IVersionHistoryWorkflowTemplate;
}

class WorkflowTemplateDetailVersionHistory extends PureComponent<
  IWorkflowTemplateDetailVersionHistoryProps,
  IWorkflowTemplateDetailVersionHistoryState
> {
  state = {
    isLoading: true,
    isOpenVersionHistoryModal: false,
    selectedVersionHistory: null,
  };

  private _openVersionHistoryModal = (versionHistory: IVersionHistoryWorkflowTemplate) => {
    this.setState({ isOpenVersionHistoryModal: true, selectedVersionHistory: versionHistory });
  };

  private _closeVersionHistoryModal = () => {
    this.setState({ isOpenVersionHistoryModal: false, selectedVersionHistory: null });
  };

  componentDidMount = async () => {
    const { doGetWorkflowTemplateVersionHistoryList, selectedWorkflowTemplate } = this.props;
    const selectedWorkflowUniqueId = selectedWorkflowTemplate && selectedWorkflowTemplate.workflowTemplateUniqueId;

    if (selectedWorkflowUniqueId) {
      await doGetWorkflowTemplateVersionHistoryList(selectedWorkflowUniqueId);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isOpenVersionHistoryModal, selectedVersionHistory, isLoading } = this.state;

    return (
      <div>
        <WorkflowTemplateDetailVersionHistoryListing
          onOpenVersionHistoryModal={this._openVersionHistoryModal}
          isLoading={isLoading}
        />
        <WorkflowTemplateDetailVersionHistoryModal
          isOpenModal={isOpenVersionHistoryModal}
          workflowVersionHistory={selectedVersionHistory}
          onCloseModal={this._closeVersionHistoryModal}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  versionHistoryWorkflowTemplates: state.workflowStore.versionHistoryWorkflowTemplates,
  selectedWorkflowTemplate: state.workflowStore.selectedWorkflowTemplate,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetWorkflowTemplateVersionHistoryList: dispatch.workflowStore.doGetWorkflowTemplateVersionHistoryList,
});

export default connect(mapState, mapDispatch)(WorkflowTemplateDetailVersionHistory);
