import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Devices = forwardRef(function Devices(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4.25h-.052c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.08.595-.08 1.345-.08 2.243v7.302h-.917c-.598 0-1.083.485-1.083 1.083a3.417 3.417 0 0 0 3.417 3.417h15.385c.899 0 1.648 0 2.242-.08.628-.084 1.195-.27 1.65-.726.456-.455.642-1.022.726-1.65.08-.594.08-1.343.08-2.242V11.948c0-.899 0-1.648-.08-2.242-.084-.628-.27-1.195-.725-1.65-.343-.343-.75-.533-1.197-.642a10.035 10.035 0 0 0-.019-.56 2.822 2.822 0 0 0-.188-.906 2.75 2.75 0 0 0-1.489-1.489c-.29-.12-.59-.167-.907-.188-.304-.021-.675-.021-1.12-.021H7Zm-3.333 15h10.915a3.484 3.484 0 0 1-.252-.956 7.46 7.46 0 0 1-.05-.544H1.794c.19.858.956 1.5 1.872 1.5Zm10.583-3.198v.198H3.75V9c0-.964.002-1.612.067-2.095.062-.461.169-.659.3-.789.13-.13.327-.237.788-.3C5.388 5.753 6.036 5.75 7 5.75h11c.476 0 .796 0 1.043.017.241.017.358.046.435.078.307.127.55.37.677.677.032.077.061.194.078.435.006.09.01.189.012.302-.362-.009-.76-.009-1.193-.009h-.104c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.08.595-.08 1.345-.08 2.243V16.052Zm6.845 3.131c-.483.065-1.13.067-2.095.067-.964 0-1.611-.002-2.095-.067-.461-.062-.659-.169-.789-.3-.13-.13-.237-.327-.3-.788-.064-.483-.066-1.131-.066-2.095v-4c0-.964.002-1.612.067-2.095.062-.461.169-.659.3-.789.13-.13.327-.237.788-.3.483-.064 1.131-.066 2.095-.066.964 0 1.612.002 2.095.067.461.062.659.169.789.3.13.13.238.327.3.788.064.483.066 1.131.066 2.095v4c0 .964-.002 1.612-.067 2.095-.061.461-.169.659-.3.789-.13.13-.327.237-.788.3ZM19 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
