import { GroupServiceSessionStatus } from 'utilities/enum-utils';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';

import moment from 'moment-timezone';
import { timeZone } from 'interfaces/timezone-type';

interface ISessionCalloutActionProps {
  sessionStatus: GroupServiceSessionStatus;
  startDate: Date;
  endDate?: Date;
  onStartSession?: any;
  onEndSession?: any;
  onCloseSession?: any;
  timezone?: timeZone;
}

export function SessionCalloutAction({
  sessionStatus,
  startDate,
  endDate,
  onCloseSession,
  onEndSession,
  onStartSession,
  timezone,
}: ISessionCalloutActionProps) {
  let actionLabel = '';
  let actionDescription = <></>;

  let highlightClass = '';
  let containerClass = '';
  let buttonClass = '';
  let iconClass = 'text-color-tertiary';

  let textColor = 'secondary';
  let buttonColor = 'blue-action';

  let buttonText = '';
  let shouldShowAction = true;
  let ButtonComponent = PrimaryButton;

  let isFutureDate = moment().diff(startDate) <= 0;
  let targetAction = () => {};
  // let isFutureDate = false;

  // Render styles for the callout action.
  switch (sessionStatus) {
    case GroupServiceSessionStatus.SCHEDULED:
      if (isFutureDate) {
        // Scheduled but start date has not arrived yet
        actionLabel = 'Session scheduled';
        actionDescription = (
          <>
            This session is scheduled to start on <b>{moment.tz(startDate, timezone).format('h:mm A, D MMMM YYYY')}</b>.
          </>
        );

        buttonText = 'Start session early';
        ButtonComponent = SecondaryButton;

        highlightClass = 'bg-standard-gray';
        containerClass = 'bg-white bordered border-standard-gray';

        targetAction = onStartSession;
      } else {
        // Scheduled and start date has passed
        actionLabel = 'Ready to start';
        actionDescription = (
          <>
            This session is scheduled to start on <b>{moment.tz(startDate, timezone).format('h:mm A, D MMMM YYYY')}</b>.
          </>
        );

        buttonColor = 'green-light';
        buttonClass = 'border-white';
        buttonText = 'Start session now';

        iconClass = 'text-color-white';
        textColor = 'white';

        highlightClass = 'bg-green-light';
        containerClass = 'bg-green-light';

        targetAction = onStartSession;
      }

      break;

    case GroupServiceSessionStatus.INPROGRESS:
      actionLabel = 'Session in progress';
      actionDescription = (
        <>
          This session is scheduled to end on <b>{moment.tz(endDate, timezone).format('h:mm A, D MMMM YYYY')}</b>.
        </>
      );

      buttonColor = 'orange-light';
      buttonClass = 'border-white';
      buttonText = 'End session now';

      iconClass = 'text-color-white';
      textColor = 'white';

      highlightClass = 'bg-orange-light';
      containerClass = 'bg-orange-light';

      targetAction = onEndSession;

      break;

    case GroupServiceSessionStatus.COMPLETED:
      actionLabel = 'Session completed';
      actionDescription = (
        <>
          This session was completed on <b>{moment.tz(endDate, timezone).format('h:mm A, D MMMM YYYY')}</b>.
        </>
      );

      buttonColor = 'blue-light';
      buttonClass = 'border-white';
      buttonText = 'Close session';

      iconClass = 'text-color-white';
      textColor = 'white';

      highlightClass = 'bg-blue-light';
      containerClass = 'bg-blue-light';

      targetAction = onCloseSession;

      break;

    case GroupServiceSessionStatus.CLOSED:
      actionLabel = 'Session closed';
      actionDescription = (
        <>
          This session is <b>closed</b>.
        </>
      );
      shouldShowAction = false;

      highlightClass = 'bg-tertiary';
      containerClass = 'bg-white bordered border-standard-gray';

      textColor = 'secondary';

      break;

    case GroupServiceSessionStatus.CANCELLED:
      actionLabel = 'Session cancelled';
      actionDescription = (
        <>
          This session has been <b>cancelled</b>.
        </>
      );
      shouldShowAction = false;

      // highlightClass = 'bg-red-lightest';
      containerClass = 'bg-secondary border-standard-gray';

      break;
  }

  return (
    <div
      className={`rounded-big shadow-box justify-between mb-large align-center line-height-100 ${containerClass}`}
      style={{ overflow: 'hidden' }}
    >
      <div className={`width-full ${highlightClass}`} style={{ height: '2px' }} />

      <div className="flex-row ph-x-large pv-medium justify-between">
        <div className="flex-row align-center">
          <Icon type="calendar" style={{ fontSize: '28px' }} className={`mr-large ${iconClass}`} />
          <div className="flex-column">
            <div className="mb-small">
              <Text size="x2-large" weight="bold" color={textColor} lineHeight={100}>
                {actionLabel}
              </Text>
            </div>
            <Text size="x-large" color={textColor} lineHeight={100}>
              {actionDescription}
            </Text>
          </div>
        </div>

        {shouldShowAction && (
          <ButtonComponent size="large" className={buttonClass} color={buttonColor} onClick={targetAction}>
            {buttonText}
          </ButtonComponent>
        )}
      </div>
    </div>
  );
}
