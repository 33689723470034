import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import _ from 'lodash';
import * as H from 'history';
import { ActivityGroupMemberType, ActivityGroupModalType } from 'utilities/enum-utils';
import { IGroupServiceActivityGroup, IGroupServiceUnAssignedMembers } from 'src/interfaces/service-interfaces';
import { IActivityGroupUsers } from 'src/interfaces/user-interfaces';
import { Skeleton } from 'antd';
import ActivityGroupsModal from 'common-components/activity-groups/modals/ActivityGroupsModal';
import ActivityGroupListing from './components/ActivityGroupListing';
import EmptyState from './components/EmptyState';

export type openModalFuncType = (
  activityGroup: IGroupServiceActivityGroup,
  modalType: ActivityGroupModalType,
  memberType?: ActivityGroupMemberType,
  member?: IActivityGroupUsers,
) => void;

interface IGroupServiceActivityGroupsPanelProps {
  history: H.History;
  serviceId: string;
  doFetchGroupServiceActivityGroups: typeof dispatch.groupServiceStore.doFetchGroupServiceActivityGroups;
  groupServiceActivityGroups: IGroupServiceActivityGroup[];
  groupServiceUnAssignedMembers: IGroupServiceUnAssignedMembers;
  doGetGroupServiceCustomers: typeof dispatch.customersStore.doGetGroupServiceCustomers;
  setGroupServiceCustomers: typeof dispatch.customersStore.setGroupServiceCustomers;
  doAddActivityGroup: typeof dispatch.groupServiceStore.doAddActivityGroup;
  doDuplicateActivityGroup: typeof dispatch.groupServiceStore.doDuplicateActivityGroup;
  doRemoveAllMembersFromActivityGroup: typeof dispatch.groupServiceStore.doRemoveAllMembersFromActivityGroup;
  doRemoveMemberFromActivityGroup: typeof dispatch.groupServiceStore.doRemoveMemberFromActivityGroup;
  doEditCustomersToActivityGroup: typeof dispatch.groupServiceStore.doEditCustomersToActivityGroup;
  doEditTeamMembersToActivityGroup: typeof dispatch.groupServiceStore.doEditTeamMembersToActivityGroup;
  doAddMembersToEmptyActivityGroup: typeof dispatch.groupServiceStore.doAddMembersToEmptyActivityGroup;
  doAssignSpecificMemberToActivityGroups: typeof dispatch.groupServiceStore.doAssignSpecificMemberToActivityGroups;
  doDeleteActivityGroup: typeof dispatch.groupServiceStore.doDeleteActivityGroup;
  doEditActivityGroup: typeof dispatch.groupServiceStore.doEditActivityGroup;
}

interface IGroupServiceActivityGroupsPanelState {
  currentActivityGroup: IGroupServiceActivityGroup;
  currentModalType: ActivityGroupModalType;
  currentMember: IActivityGroupUsers;
  currentMemberType: ActivityGroupMemberType;
  isLoading: boolean;
  isModalOpen: boolean;
}

class GroupServiceActivityGroupsPanel extends Component<
  IGroupServiceActivityGroupsPanelProps,
  IGroupServiceActivityGroupsPanelState
> {
  state = {
    currentActivityGroup: null,
    currentModalType: ActivityGroupModalType.CREATE,
    currentMember: null,
    currentMemberType: ActivityGroupMemberType.CUSTOMER,
    isLoading: false,
    isModalOpen: false,
  };

  private _loadActivityGroups = async () => {
    this.setState({ isLoading: true });
    await this.props.doFetchGroupServiceActivityGroups({ serviceId: this.props.serviceId });
    this.setState({ isLoading: false });
  };

  private _openModal = (
    activityGroup: IGroupServiceActivityGroup,
    modalType: ActivityGroupModalType,
    memberType: ActivityGroupMemberType = ActivityGroupMemberType.CUSTOMER,
    member: IActivityGroupUsers = null,
  ) => {
    this.setState({
      currentActivityGroup: activityGroup,
      currentModalType: modalType,
      isModalOpen: true,
      currentMember: member,
      currentMemberType: memberType,
    });
  };

  private _handleModalAction = async (payload, actionType: ActivityGroupModalType) => {
    const {
      serviceId,
      doAddActivityGroup,
      doDuplicateActivityGroup,
      doRemoveMemberFromActivityGroup,
      doRemoveAllMembersFromActivityGroup,
      doEditCustomersToActivityGroup,
      doEditTeamMembersToActivityGroup,
      doAddMembersToEmptyActivityGroup,
      doEditActivityGroup,
      doAssignSpecificMemberToActivityGroups,
      doDeleteActivityGroup,
      doFetchGroupServiceActivityGroups,
    } = this.props;
    const prePayload = {
      serviceId,
      ...payload,
    };
    let result = true;
    switch (actionType) {
      case ActivityGroupModalType.CREATE:
        await doAddActivityGroup(prePayload);
        break;
      case ActivityGroupModalType.EDIT:
        await doEditActivityGroup(prePayload);
        break;
      case ActivityGroupModalType.DUPLICATE:
        await doDuplicateActivityGroup(prePayload);
        break;
      case ActivityGroupModalType.REMOVE:
        await doRemoveMemberFromActivityGroup(prePayload);
        break;
      case ActivityGroupModalType.DELETE:
        await doDeleteActivityGroup(prePayload);
        break;
      case ActivityGroupModalType.REMOVE_ALL:
        await doRemoveAllMembersFromActivityGroup(prePayload);
        break;
      case ActivityGroupModalType.ADD_CUSTOMER:
        result = !!(await doEditCustomersToActivityGroup(prePayload));
        break;
      case ActivityGroupModalType.ADD_TEAM_MEMBER:
        result = !!(await doEditTeamMembersToActivityGroup(prePayload));
        break;
      case ActivityGroupModalType.ADD_BOTH:
        result = !!(await doAddMembersToEmptyActivityGroup(prePayload));
        break;
      case ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP:
      case ActivityGroupModalType.ASSIGN_TEAM_MEMBER_TO_ACTIVITY_GROUP:
        await doAssignSpecificMemberToActivityGroups(prePayload);
        break;
    }

    await doFetchGroupServiceActivityGroups({ serviceId });
    return result;
  };

  componentDidMount = async () => {
    await this._loadActivityGroups();
  };

  render() {
    const { groupServiceActivityGroups, groupServiceUnAssignedMembers } = this.props;
    const { currentActivityGroup, currentModalType, currentMember, currentMemberType, isModalOpen, isLoading } =
      this.state;
    return (
      <div className="pt-large pl-large width-full pb-x3-large" style={{ maxWidth: '1000px' }}>
        <div className="bg-white ph-large pt-large pb-x2-large shadow-container rounded-big">
          {isLoading ? (
            <Skeleton active />
          ) : _.isEmpty(groupServiceActivityGroups) ? (
            <EmptyState openModal={this._openModal} />
          ) : (
            <ActivityGroupListing
              activityGroups={groupServiceActivityGroups}
              unassignedMembers={groupServiceUnAssignedMembers}
              openModal={this._openModal}
            />
          )}
        </div>
        {isModalOpen && (
          <ActivityGroupsModal
            serviceId={this.props.serviceId}
            activityGroup={currentActivityGroup}
            modalType={currentModalType}
            member={currentMember}
            memberType={currentMemberType}
            isOpen={isModalOpen}
            selectedUser={currentMember}
            handleAction={this._handleModalAction}
            onCloseViewModal={() => {
              this.setState({ isModalOpen: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceActivityGroups: state.groupServiceStore.groupServiceActivityGroups,
  groupServiceUnAssignedMembers: state.groupServiceStore.groupServiceUnAssignedMembers,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceActivityGroups: dispatch.groupServiceStore.doFetchGroupServiceActivityGroups,
  doGetGroupServiceCustomers: dispatch.customersStore.doGetGroupServiceCustomers,
  setGroupServiceCustomers: dispatch.customersStore.setGroupServiceCustomers,
  doAddActivityGroup: dispatch.groupServiceStore.doAddActivityGroup,
  doDuplicateActivityGroup: dispatch.groupServiceStore.doDuplicateActivityGroup,
  doRemoveAllMembersFromActivityGroup: dispatch.groupServiceStore.doRemoveAllMembersFromActivityGroup,
  doRemoveMemberFromActivityGroup: dispatch.groupServiceStore.doRemoveMemberFromActivityGroup,
  doEditCustomersToActivityGroup: dispatch.groupServiceStore.doEditCustomersToActivityGroup,
  doEditTeamMembersToActivityGroup: dispatch.groupServiceStore.doEditTeamMembersToActivityGroup,
  doAddMembersToEmptyActivityGroup: dispatch.groupServiceStore.doAddMembersToEmptyActivityGroup,
  doEditActivityGroup: dispatch.groupServiceStore.doEditActivityGroup,
  doAssignSpecificMemberToActivityGroups: dispatch.groupServiceStore.doAssignSpecificMemberToActivityGroups,
  doDeleteActivityGroup: dispatch.groupServiceStore.doDeleteActivityGroup,
});

export default connect(mapState, mapDispatch)(GroupServiceActivityGroupsPanel);
