import React, { PureComponent } from 'react';
import { Paragraph, Text } from 'common-components/typography';

interface IWorkflowTemplateConfigurationSectionProps {
  defaultGuideBoxContent?: {
    title: string;
    description: string;
  };
  customGuideBox?: React.ReactNode;
  className?: string;
}

class WorkflowTemplateConfigurationSection extends PureComponent<IWorkflowTemplateConfigurationSectionProps> {
  render() {
    const { defaultGuideBoxContent, customGuideBox, className, children } = this.props;

    return (
      <div className={`flex align-start ${className || ''}`}>
        {defaultGuideBoxContent && (
          <div className="mr-large pv-medium" style={{ minWidth: '300px', width: '300px' }}>
            <Text className="text-weight-bolder">{defaultGuideBoxContent.title}</Text>
            <Paragraph className="mt-medium">{defaultGuideBoxContent.description}</Paragraph>
          </div>
        )}
        {customGuideBox && (
          <div className="mr-large pv-medium" style={{ minWidth: '300px', width: '300px' }}>
            {customGuideBox}
          </div>
        )}
        <div className="p-medium bg-white width-full rounded-big bordered shadow-container">{children}</div>
      </div>
    );
  }
}

export default WorkflowTemplateConfigurationSection;
