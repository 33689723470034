import { PageHeader } from '@good/ui/templates';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SilRosterData } from 'utilities/react-trpc';
import { SilTable } from 'views/sil-service/components/table';
import { Collaboration8 } from '@good/illustrations';
import { EmptyTablePlaceholder } from 'views/sil-service/components/empty-table-placeholder';
import { Space } from '@good/ui/core';
import { memberColumns } from './members-helper';

type IManagersTabProps = {
  managers: SilRosterData;
  managersCount: number;
};

export const ManagersTab = ({ managers, managersCount }: IManagersTabProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceTeamTabs' });

  return (
    <div data-testid='managers-tab'>
      <PageHeader
        title={t('ManagersHeading', { count: managersCount })}
        subtitle={t('ManagersDescription')}
        size='h2'
      />
      {managersCount === 0 ? (
        <>
          <SilTable data={managers} columns={memberColumns} />
          <Space h='md' />
          <EmptyTablePlaceholder
            image={<Collaboration8 />}
            heading={t('empty.managerHeading')}
            description={t('empty.managerDescription')}
          />
        </>
      ) : (
        <SilTable data={managers} columns={memberColumns} />
      )}
    </div>
  );
};
