import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IGroupServiceActivityGroup, IGroupServiceSession } from 'interfaces/service-interfaces';
import { IActivityGroupUsers } from 'interfaces/user-interfaces';
import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { ActivityGroupMemberType, ActivityGroupModalType } from 'utilities/enum-utils';
import SelectActivityGroups from '../../selectors/SelectActivityGroups';
import { Avatar, Col, notification, Row } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { timeZone } from 'interfaces/timezone-type';

interface IAssignToSessionModalContentProps {
  serviceId: string;
  modalType: ActivityGroupModalType;
  memberType: ActivityGroupMemberType;
  activityGroup: IGroupServiceActivityGroup;
  selectedUser: IActivityGroupUsers;
  session?: IGroupServiceSession;
  onCloseViewModal: () => void;
  handleAction: (payload, actionType: ActivityGroupModalType) => Promise<boolean | void>;
  timeZone?: timeZone;
}

interface IAssignToSessionModalContentState {
  isLoading: boolean;
  isDirty: boolean;
  addedActivityGroups: IGroupServiceActivityGroup[];
}

class AssignToSessionModalContent extends Component<
  IAssignToSessionModalContentProps,
  IAssignToSessionModalContentState
> {
  state = {
    isLoading: false,
    isDirty: false,
    addedActivityGroups: [],
  };

  private _handleAlterStateWhenInteractWithSelectedItems = (addedItems: IGroupServiceActivityGroup[]) => {
    this.setState({ addedActivityGroups: addedItems, isDirty: true });
  };

  private _handleAssign = async () => {
    const { serviceId, selectedUser, modalType, memberType, session, handleAction } = this.props;
    const { isDirty, addedActivityGroups } = this.state;
    this.setState({ isLoading: true });
    try {
      const serviceActivityGroupIds = _.map(addedActivityGroups, (group) => group.serviceActivityGroupId);
      const updatedActivityGroups = _.chain(_.get(session, 'activityGroups', []))
        .map((activityGroup) =>
          Object.assign(activityGroup, {
            isAssignedTo: isDirty
              ? _.some(
                  addedActivityGroups,
                  (addItem) => addItem.serviceDateTimeActivityGroupId === activityGroup.serviceDateTimeActivityGroupId,
                )
              : activityGroup.isAssignedTo,
          }),
        )
        .value();
      const payload = {
        serviceId,
        userId: selectedUser.userId,
        memberType,
        serviceActivityGroupIds,
        session,
        addedActivityGroups: updatedActivityGroups,
      };

      await handleAction(payload, modalType);
      this.props.onCloseViewModal();
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong! Please try again.',
      });
      console.log(e);
    }

    this.setState({ isLoading: false });
  };

  render() {
    const { serviceId, selectedUser, modalType, memberType, session, timeZone, onCloseViewModal } = this.props;
    const { capacity, bookedCapacity, scheduleName, startDateTime, endDateTime } = session;
    const sessionStart = moment.tz(startDateTime, timeZone);
    const sessionEnd = moment.tz(endDateTime, timeZone);
    // rematch object to reuse common component
    const groupActivityGroups = _.get(session, 'activityGroups', []);
    const selectedActivityGroups = _.filter(groupActivityGroups, (activityGroup) => activityGroup.isAssignedTo);

    return (
      <div className="width-full">
        <div className="bg-tertiary rounded pv-12 ph-medium block ">
          <Row className="">
            <Col span={memberType === ActivityGroupMemberType.CUSTOMER ? 8 : 12}>
              <div className="mb-small">
                <Text className="text-uppercase" weight="bold" color="secondary">
                  {memberType === ActivityGroupMemberType.CUSTOMER ? 'SELECTED CUSTOMER' : 'SELECTED TEAM MEMBER'}
                </Text>
              </div>
              <div>
                <Avatar
                  size={32}
                  src={selectedUser.attachmentUrl}
                  shape={modalType === ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SESSION ? 'circle' : 'square'}
                />{' '}
                <Text className="ml-small" color="primary" size="medium">
                  {selectedUser.firstName + ' ' + selectedUser.lastName}
                </Text>
              </div>
            </Col>

            <Col span={memberType === ActivityGroupMemberType.CUSTOMER ? 8 : 12}>
              <div className="mb-small">
                <Text className="text-uppercase" weight="bold" color="secondary">
                  SESSION DATE
                </Text>
              </div>
              <div>
                <Text size="large" weight="bolder">
                  {sessionStart.format('D MMMM YYYY')}
                </Text>
              </div>
              <div>
                <Text size="large" color="secondary">
                  {sessionStart.format('h:mm A')} - {sessionEnd.format('h:mm A')}
                </Text>
              </div>
            </Col>
            {memberType === ActivityGroupMemberType.CUSTOMER && (
              <div>
                <Col span={4}>
                  <div className="mb-small">
                    <Text className="text-uppercase" weight="bold" color="secondary">
                      CAPACITY
                    </Text>
                  </div>
                  <div>
                    <Text size="large" weight="bolder">
                      {capacity && bookedCapacity
                        ? bookedCapacity + '/' + capacity
                        : session.capacity
                        ? session.capacity
                        : 'No capacity'}
                    </Text>
                  </div>
                </Col>
                <Col span={4}>
                  <div className="mb-small">
                    <Text className="text-uppercase" weight="bold" color="secondary">
                      SCHEDULE
                    </Text>
                  </div>
                  <div>
                    <Text size="large" weight="bolder">
                      {scheduleName}
                    </Text>
                  </div>
                </Col>
              </div>
            )}
          </Row>
        </div>

        <SelectActivityGroups
          serviceId={serviceId}
          modalType={
            memberType === ActivityGroupMemberType.CUSTOMER
              ? ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP
              : ActivityGroupModalType.ASSIGN_TEAM_MEMBER_TO_ACTIVITY_GROUP
          }
          groupSessionActivityGroups={groupActivityGroups}
          selectedActivityGroups={selectedActivityGroups}
          isRenderSessionCard={true}
          onInteractWithAddedItems={this._handleAlterStateWhenInteractWithSelectedItems}
          timezone={timeZone}
        />
        <div className="flex justify-end mt-medium">
          <SecondaryButton size="large" color="secondary" className="mr-12" onClick={onCloseViewModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._handleAssign} loading={this.state.isLoading}>
            Save changes
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export default AssignToSessionModalContent;
