import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Text, Inline } from '@goodhuman-me/components';

import CancelBookingFieldError from '../CancelBookingFieldError';
import { Schema, SchemaFieldNames, SchemaOptions } from '../../schema';
import { IFieldWithOptions } from '../../fields';

interface ICancelBookingSelectProps<T extends SchemaOptions> {
  field: IFieldWithOptions<SchemaFieldNames, string | null, T>;
  onClear?: () => void;
  disabled?: boolean;
  popover?: React.ReactNode;
  size?: 'small' | 'default' | 'large';
  allowClear?: boolean;
}

const CancelBookingSelect = <T extends SchemaOptions>({
  field,
  onClear,
  disabled,
  popover,
  size = 'large',
  allowClear = true,
}: ICancelBookingSelectProps<T>) => {
  const keyPrefix = `bookings.modals.cancelBooking.${field.name}`;
  const { t, i18n } = useTranslation('', { keyPrefix });
  const { control } = useFormContext<Schema>();

  return (
    <>
      {i18n.exists(`${keyPrefix}.label`) && (
        <Box paddingBottom="$xsmall">
          <Inline gap="$xxsmall" alignItems="center">
            <Text fontWeight={700}>{t(`label`)}</Text>
            {popover}
          </Inline>
        </Box>
      )}
      <Controller
        name={field.name}
        defaultValue={field.defaultValue}
        control={control}
        rules={field.rules}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
          return (
            <>
              <Select
                onChange={(value) => {
                  onChange(value);
                  if (value === undefined) {
                    onClear?.();
                  }
                }}
                onBlur={onBlur}
                value={value ?? undefined}
                placeholder={t(`placeholder`)}
                className={`width-full ${error ? 'cancel-booking-select__error' : ''}`}
                aria-label={`${field.name} ${t('placeholder')}`}
                allowClear={allowClear}
                notFoundContent={null}
                disabled={disabled}
                ref={ref}
                size={size}
              >
                {Object.values(field.options).map((option: string) => (
                  <Select.Option
                    value={option}
                    key={option}
                    aria-label={t(`options.${option}`)}
                    className="cancel-booking-select-option"
                  >
                    {t(`options.${option}`)}
                  </Select.Option>
                ))}
              </Select>
              {error?.message && (
                <Box marginTop="$xxsmall">
                  <CancelBookingFieldError message={error?.message} />
                </Box>
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default CancelBookingSelect;
