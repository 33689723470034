import { Text } from '@good/components';
import { Separator } from '@goodhuman-me/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InsightsSummaryResponse } from 'views/insights/insights';

type SingleBarData = {
  label: string;
  colorClass: string;
  value: number;
};

export type BarChartData = [SingleBarData, SingleBarData];

export type BillableHoursProps = Pick<InsightsSummaryResponse, 'billableHours' | 'nonBillableHours'>;
export const BillableHours = ({ billableHours, nonBillableHours }: BillableHoursProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'insights.summary.billableHours' });

  const chartData: BarChartData = [
    {
      label: t('billableLabel'),
      value: billableHours,
      colorClass: 'bg-lavender-ref',
    },
    {
      label: t('nonBillableLabel'),
      value: nonBillableHours,
      colorClass: 'bg-lilac-ref',
    },
  ];

  return (
    <div className="flex flex-row space-x-10">
      <div className="flex-1 py-6">
        <BillableHoursLegend data={chartData} />
      </div>
      <BillableHoursBarChart data={chartData} />
    </div>
  );
};

type BillableHoursBarChartProps = {
  data: BarChartData;
  horizontal?: boolean;
};

export const BillableHoursBarChart: React.FC<BillableHoursBarChartProps> = ({ data, horizontal }) => {
  const maxHours = data.reduce((currentMax, { value }) => {
    if (value > currentMax) {
      return value;
    }

    return currentMax;
  }, 0);

  if (maxHours < 1) {
    return null;
  }

  const bars: JSX.Element[] = data.map(({ value, colorClass }) => {
    const percent = Math.floor(Math.max((value / maxHours) * 100, 5));

    return (
      <div
        style={{
          height: horizontal ? '0.75rem' : `${percent}%`,
          width: horizontal ? `${percent}%` : '2rem',
        }}
        key={value}
        className={`${colorClass} rounded-sm`}
      />
    );
  });

  return (
    <div className="flex flex-col">
      {horizontal ? (
        <div className="w-full flex-col space-y-1">{bars}</div>
      ) : (
        <div className="align-end h-full flex-row space-x-3">{bars}</div>
      )}
    </div>
  );
};

const BillableHoursLegend = ({ data }: { data: BarChartData }) => {
  const { t } = useTranslation('', { keyPrefix: 'insights.summary.billableHours' });

  return (
    <div className="flex flex-col space-y-3">
      {data.map(({ label, value, colorClass }, index) => {
        const isLastElement = data.length - 1 === index;
        return (
          <div className="flex flex-col space-y-3" key={index}>
            <div className="flex-row justify-between">
              <div className="align-center flex-row space-x-2">
                <div className={`h-3 w-3 rounded-sm ${colorClass}`} />
                <Text className="text-b-sm">{label}</Text>
              </div>
              <Text className="text-b-sm font-semibold">
                <span aria-valuetext={`${value} ${label} hours`}>{t('hours', { count: value })}</span>
              </Text>
            </div>
            {!isLastElement && <Separator thickness="thick" color="$backgroundSupport" />}
          </div>
        );
      })}
    </div>
  );
};
