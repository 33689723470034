import React from 'react';

import { Input, Menu, Stack } from '@good/ui/core';
import { SelectLocaleConfig, SelectAvailableLocaleConfigs } from 'components';

type LocalesMenuProps = {
  children: React.ReactNode;
};

export const LocalesMenu = ({ children }: LocalesMenuProps) => {
  return (
    <Menu shadow='md' width={300} position='bottom-end'>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Stack gap='xs' p='sm'>
          <SelectLocaleConfig label='Default Locale' w='100%' size='sm' comboboxProps={{ withinPortal: false }} />
          <Input.Wrapper label='Available Locales'>
            <SelectAvailableLocaleConfigs mt='xs' w='100%' size='sm' comboboxProps={{ withinPortal: false }} />
          </Input.Wrapper>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
};
