import React from 'react';
import { Button, DateFormatter, I18nProvider, Label, StatusMessage, Text } from '@good/components';
import { CheckCircle, CrossCircle, FileShredder, Loader2Adjustable, Time } from '@good/icons';
import { Anchor, Group, MantineModal as Modal, Table } from '@good/ui/core';
import { useDisclosure } from '@good/ui/hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { BillingSubscription, reactTrpc } from 'utilities/react-trpc';

const PaymentStatusLabelProps = {
  SEND_TO_FINANCE: {
    labelColor: 'blue',
    labelIcon: <Time />,
    labelText: 'Waiting to be processed',
  },
  PROCESSED: {
    labelColor: 'positive',
    labelIcon: <CheckCircle />,
    labelText: 'Processed',
  },
  WAIVED: {
    labelColor: 'neutral',
    labelIcon: <FileShredder />,
    labelText: 'Waived',
  },
  REJECTED: {
    labelColor: 'critical',
    labelIcon: <CrossCircle />,
    labelText: 'Rejected',
  },
  PAID: {
    labelColor: 'info',
    labelIcon: <CheckCircle />,
    labelText: 'Paid',
  },
};

type PaymentLabelPropKey = keyof typeof PaymentStatusLabelProps;

const PaymentStatusLabel = (props: { paymentStatus: string }) => {
  const { labelColor, labelIcon, labelText } = PaymentStatusLabelProps[props.paymentStatus as PaymentLabelPropKey];
  return (
    <Label tone={labelColor} size='sm'>
      {labelIcon}
      <Text size='sm' className='pl-1'>
        {labelText}
      </Text>
    </Label>
  );
};

const BillingItemRowDetails = (props: {
  supportItemName: string;
  billingTotal: number;
  startDateTime: Date;
  supportItemNumber: string;
  attendanceId: string;
  paymentStatus: string;
  noDate?: boolean;
}) => {
  return (
    <Table.Tr>
      {!props.noDate && (
        <Table.Td className='border-b-2 border-gray-light-2'>
          <I18nProvider locale='en-AU'>
            <Text size='sm' className='font-semibold'>
              <DateFormatter value={props.startDateTime} />
            </Text>
          </I18nProvider>
        </Table.Td>
      )}
      <Table.Td className='border-b-2 border-gray-light-2'>
        <Text size='sm' className='font-semibold'>
          {props.supportItemNumber}
          <Text size='sm' className='block font-light'>
            {props.supportItemName}
          </Text>
        </Text>
      </Table.Td>
      <Table.Td className='border-b-2 border-gray-light-2'>
        <Text size='sm' className='font-semibold'>
          {CommonUtils.formatPrice(props.billingTotal)}
        </Text>
      </Table.Td>
      <Table.Td className='border-b-2 border-gray-light-2'>
        <PaymentStatusLabel paymentStatus={props.paymentStatus} />
      </Table.Td>
    </Table.Tr>
  );
};

const BillingHistoryRow = (props: {
  billingItems: {
    supportItemName: string;
    billingTotal: number;
    startDateTime: Date;
    supportItemNumber: string;
    attendanceId: string;
    paymentStatus: string;
  }[];
}) => {
  const { t } = useTranslation('', { keyPrefix: 'serviceAgreements.service.billingHistory' });
  const [opened, { open, close }] = useDisclosure(false);
  const billingItem = props.billingItems[0];

  if (props.billingItems.length === 1) {
    return billingItem && <BillingItemRowDetails {...billingItem} />;
  }

  return (
    billingItem && (
      <>
        <Modal
          opened={opened}
          onClose={close}
          title={
            <I18nProvider locale='en-AU'>
              <Text size='lg' className='font-semibold'>
                <DateFormatter value={billingItem.startDateTime} /> {t('modal.title')}
              </Text>
            </I18nProvider>
          }
          centered
          size='xl'
        >
          <Table withRowBorders={false} mt='md'>
            <Table.Tbody>
              {props.billingItems.map((billingItem, index) => {
                return <BillingItemRowDetails key={index} noDate {...billingItem} />;
              })}
            </Table.Tbody>
          </Table>
          <Group justify='flex-end' mt='md'>
            <Button emphasis='fill' onClick={close}>
              Close
            </Button>
          </Group>
        </Modal>
        <Table.Tr>
          <Table.Td py='md' className='border-b-2 border-gray-light-2'>
            <I18nProvider locale='en-AU'>
              <Text size='sm' className='font-semibold'>
                <DateFormatter value={billingItem.startDateTime} />
              </Text>
            </I18nProvider>
          </Table.Td>
          <Table.Td className='border-b-2 border-gray-light-2'>
            <Anchor className='underline text-black font-semibold' onClick={open} size='sm'>
              {t('table.rows.lineItemCount', { count: props.billingItems.length })}
            </Anchor>
          </Table.Td>
          <Table.Td className='border-b-2 border-gray-light-2'>
            <Text size='sm' className='font-semibold'>
              {CommonUtils.formatPrice(props.billingItems.reduce((acc, current) => acc + current.billingTotal, 0))}
            </Text>
          </Table.Td>
          <Table.Td className='border-b-2 border-gray-light-2' />
        </Table.Tr>
      </>
    )
  );
};

export const BillingHistoryTable = ({ billingSubscription }: { billingSubscription?: BillingSubscription }) => {
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  const { t } = useTranslation('', { keyPrefix: 'serviceAgreements.service.billingHistory' });
  const shouldFetch = billingSubscription?.subscription.bookingId ? true : false;
  const { data, isLoading, error, isFetching } = reactTrpc.sil.billingHistory.fetch.useQuery(
    {
      serviceProviderId: portalUser?.serviceProviderId ?? '',
      subscriptionBookingId: billingSubscription?.subscription.bookingId ?? '',
    },
    {
      enabled: shouldFetch,
    },
  );

  if (error) throw new Error(`failed to fetch billing history: ${error.message}`);
  if (isLoading && isFetching)
    return (
      <div className='flex flex-col align-center'>
        <Loader2Adjustable className='animate-spin' />
        <Text size='md'>Loading history</Text>
      </div>
    );

  const historyAvailable = data && Object.keys(data).length !== 0;

  return historyAvailable ? (
    <Table withRowBorders={false}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>{t('table.headers.billingDate')}</Table.Th>
          <Table.Th>{t('table.headers.lineItem')}</Table.Th>
          <Table.Th>{t('table.headers.total')}</Table.Th>
          <Table.Th>{t('table.headers.paymentStatus')}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {Object.entries(data).map(([key, value]) => {
          return <BillingHistoryRow key={key} billingItems={value} />;
        })}
      </Table.Tbody>
    </Table>
  ) : (
    <StatusMessage tone='info'>{t('emptyHistory')}</StatusMessage>
  );
};
