import { Col, notification, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import ScheduleListItem from './ScheduleListItem';

interface IDeleteScheduleModalProps {
  onClose: () => void;
  doRemoveGroupServiceSchedule: typeof dispatch.servicesStore.doRemoveGroupServiceSchedule;
  isOpen: boolean;
  serviceId: string;
  serviceScheduleId: string;
  resetScheduleList: () => void;
  scheduleName: string;
  schedulePeriodText: string;
  displayTimeSlots: string[];
}

const DeleteScheduleModal: React.FC<IDeleteScheduleModalProps> = ({
  onClose,
  doRemoveGroupServiceSchedule,
  isOpen,
  serviceId,
  serviceScheduleId,
  resetScheduleList,
  scheduleName,
  schedulePeriodText,
  displayTimeSlots,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deleteSchedule = async () => {
    try {
      setIsLoading(true);
      await doRemoveGroupServiceSchedule({ serviceId, serviceScheduleId });
      notification.success({ message: 'Schedule removed successfully.' });
      onClose();
      resetScheduleList();
    } catch (_) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ActionModal title="Delete schedule" isOpen={isOpen} onClose={onClose} width="medium">
        <div className="anim-slide-left">
          <SpinningLoadingActionModel isOpen={isLoading} verticalAlignment="highest" />
          <Paragraph>You are about to delete the following schedule :</Paragraph>
          <ScheduleListItem
            scheduleName={scheduleName}
            schedulePeriodText={schedulePeriodText}
            displayTimeSlots={displayTimeSlots}
          />
          <div className="mt-large mb-x2-large">
            All upcoming <b>(Scheduled)</b> sessions will be cancelled.
            <br /> <b>Completed sessions</b> and <b>sessions in progress</b> will not be affected.
            <br />
            <br />
            Would you like to continue?
          </div>

          <div className="mb-small">
            <Row type="flex" className="justify-end">
              <Col className="mr-large">
                <SecondaryButton size="large" onClick={onClose}>
                  Cancel
                </SecondaryButton>
              </Col>
              <Col>
                <PrimaryButton size="large" onClick={deleteSchedule}>
                  Delete schedule
                </PrimaryButton>
              </Col>
            </Row>
          </div>
        </div>
      </ActionModal>
    </div>
  );
};

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveGroupServiceSchedule: dispatch.servicesStore.doRemoveGroupServiceSchedule,
});

export default connect(null, mapDispatch)(DeleteScheduleModal);
