import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import InfoPanel from 'common-components/alerts/InfoPanel';
import { SelectInline } from 'common-components/inputs/SelectInline';
import { SubTitle, Text } from 'common-components/typography';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { WorkflowTemplateStatus, WorkflowTemplateTriggerType } from 'utilities/enum-utils';
import Utils from 'utilities/Utils';

interface IProps extends FormComponentProps {
  onGetValues?: (_onValues) => any;
  initData?: any;
  isManual?: boolean;
  workflowTemplates: typeof state.workflowStore.workflowTemplates;
  doGetWorkflowTemplates: typeof dispatch.workflowStore.doGetWorkflowTemplates;
  setWorkflowTemplates: typeof dispatch.workflowStore.setWorkflowTemplates;
  setWorkflowSelected: typeof dispatch.workflowStore.setWorkflowSelected;
  triggerType?: string[]; // This value will be [WorkflowTemplateTriggerType.INCIDENT] before merging to feature/workflows
}
interface IState {
  isLoading: boolean;
}

class SelectWorkflowPanel extends PureComponent<IProps, IState> {
  state = {
    isLoading: false,
  };

  private _searchWithParams = async ({ textSearch: searchString, ...params }) => {
    const { isManual } = this.props;
    this.setState({ ...this.state, isLoading: true });

    const triggerType = [WorkflowTemplateTriggerType.INCIDENT];
    if (isManual) {
      triggerType.push(WorkflowTemplateTriggerType.MANUAL);
    }

    const data = {
      ...params,
      searchString,
      triggerType,
      status: [WorkflowTemplateStatus.ACTIVE],
    };

    if (!isManual) {
      data['isIncludeForm'] = true;
    }
    await this.props.doGetWorkflowTemplates(data);

    this.setState({ ...this.state, isLoading: false });
  };

  private _onSelected = async (value, option) => {
    if (this.props.isManual) {
      this.props.setWorkflowSelected({
        name: option.name,
        workflowTemplateId: option.workflowTemplateId,
        triggerType: option.triggerType,
      });
    }
    this.props.form.setFieldsValue({ workflowTemplateId: option });
  };

  private _onValidForm = () => {
    const { form } = this.props;
    return new Promise((resolve) => {
      form.validateFields((errors, values) => {
        [null, undefined].includes(errors) && console.log('errors', errors);
        resolve([null, undefined].includes(errors) ? values.workflowTemplateId : undefined);
      });
    });
  };

  private _renderSubtitleSelectWorkflow = () => {
    const { isManual } = this.props;
    return isManual ? 'Select workflow' : 'Select incident workflow';
  };

  async componentDidMount() {
    const { onGetValues, setWorkflowTemplates } = this.props;
    onGetValues(this._onValidForm);

    setWorkflowTemplates([]);
  }

  render() {
    const { form, initData, isManual, workflowTemplates } = this.props;
    const { isLoading } = this.state;

    form.getFieldDecorator('workflowTemplateId', {
      initialValue: initData,
      rules: [
        { required: true, message: isManual ? 'Please select a workflow' : 'Please choose an incident workflow.' },
      ],
    });
    const { workflowTemplateId: errors = [] } = form.getFieldsError(['workflowTemplateId']);
    const [error] = errors || undefined;
    return (
      <div>
        {isManual !== true ? (
          <InfoPanel
            className="align-center"
            text={
              <div>
                Your workspace has active <span className={'text-weight-bolder'}>incident workflows</span>. Select an
                option below that best corresponds to the incident you have reported.
              </div>
            }
          />
        ) : (
          <Text>{'Select which workflow you would like to manually trigger'}</Text>
        )}
        <div className="flex-column mt-medium">
          <SubTitle weight="regular">{this._renderSubtitleSelectWorkflow()}</SubTitle>
          <SelectInline
            options={workflowTemplates}
            optionKey={(e) => e.workflowTemplateId}
            optionLabel={(e) => e.name}
            isLoading={isLoading}
            placeholder="Search for ..."
            noData="No workflow found"
            renderItem={({ item: e }) => (
              <Text className="whitespace-pre-wrap flex-1 pr-small">
                <b>{e.name}</b>
                <div className="pv-x-small">{e.description}</div>
              </Text>
            )}
            onFetchData={this._searchWithParams}
            onSelected={this._onSelected}
          />
          {!Utils.isEmpty(error) && <Text className="pt-x-small text-color-red">{error}</Text>}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  workflowTemplates: state.workflowStore.workflowTemplates,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetWorkflowTemplates: dispatch.workflowStore.doGetWorkflowTemplates,
  setWorkflowTemplates: dispatch.workflowStore.setWorkflowTemplates,
  setWorkflowSelected: dispatch.workflowStore.setWorkflowSelected,
});

export default connect(mapState, mapDispatch)(Form.create<IProps>()(SelectWorkflowPanel));
