import React, { memo } from 'react';
import { Box, CurrencyFormatter, Stack, Text, css } from '@goodhuman-me/components';
import { arc as Arc, pie as Pie } from 'd3';
import { useHover } from 'react-aria';

import type { Budget, BudgetCategory } from '../../../../../budget';
import type { PieArcDatum } from 'd3';

const styles = {
  svg: css({
    overflow: 'visible !important',
  }),
  path: css({
    transition: 'd .15s ease-out',
  }),
};

type DonutLabelProps = Budget['budget'];

function DonutLabel(props: DonutLabelProps) {
  let { quoted, balance } = props;

  return (
    <g>
      <Text asChild color="$muted" size="xsmall">
        <text x="50%" y="96px" dominantBaseline="middle" textAnchor="middle">
          Allocated
        </text>
      </Text>

      <text x="50%" y="96px" dominantBaseline="middle" textAnchor="middle">
        <Text asChild color="$muted" fontWeight="$bold">
          <tspan dy="1.25em">
            <CurrencyFormatter value={quoted.value} />
          </tspan>
        </Text>
      </text>

      <Text asChild color="$muted" size="xsmall">
        <text x="50%" y="144px" dominantBaseline="middle" textAnchor="middle">
          Remaining
        </text>
      </Text>

      <text x="50%" y="144px" dominantBaseline="middle" textAnchor="middle">
        <Text asChild color="$muted" fontWeight="$bold">
          <tspan dy="1.25em">
            <CurrencyFormatter value={balance.value} />
          </tspan>
        </Text>
      </text>
    </g>
  );
}

type SliceProps = {
  path: {
    value: string;
    hoveredValue: string;
    fill: string;
  };
};

const Slice = memo(function Slice(props: SliceProps) {
  let {
    path: { value, hoveredValue, fill },
  } = props;

  let { isHovered, hoverProps } = useHover({});
  let d = isHovered ? hoveredValue : value;

  return (
    <g>
      <path {...hoverProps} d={d} fill={fill} className={styles.path()} />
    </g>
  );
});

export type DonutChartProps = Pick<Budget['budget'], 'balance' | 'categories' | 'quoted'>;

export function DonutChart(props: DonutChartProps): JSX.Element {
  let { balance, categories, quoted } = props;

  const height = 264;
  const width = 264;
  const donutWidth = 32;
  const radius = Math.min(width, height) / 2;
  const innerRadius = radius - donutWidth;
  const hoverRadius = radius + 3;

  let totalQuoted = 0;
  categories.forEach((c) => {
    let {
      actuals: {
        quoted: { percent },
      },
    } = c;

    totalQuoted = totalQuoted + percent;
  }, totalQuoted);

  let isEmptyChart = totalQuoted === 0;

  const pie = Pie<DonutChartProps['categories'][0]>()
    .value(
      ({
        actuals: {
          quoted: { percent },
        },
      }) => percent,
    )
    .sort(null);

  const arc = Arc<PieArcDatum<BudgetCategory>>().innerRadius(innerRadius).outerRadius(radius);
  const hoverArc = Arc<PieArcDatum<BudgetCategory>>().innerRadius(innerRadius).outerRadius(hoverRadius);

  let emptyDonut = pie([{ actuals: { quoted: { percent: 100 } } }]).map((e) => {
    let path = {
      fill: '#CCCAC6',
      hoveredValue: arc(e),
      value: arc(e),
    };

    return <Slice key={e.data.id} path={path} />;
  });

  return (
    <Stack gap="$large">
      <Box>
        <svg
          height={height}
          width={width}
          className={styles.svg()}
          aria-label="Donut chart divided by budget's support categories"
          role="img"
        >
          <g transform={`translate(${width / 2}, ${height / 2})`}>
            {isEmptyChart
              ? emptyDonut
              : pie(categories).map((e) => {
                  let path = {
                    fill: e.data.meta.color,
                    hoveredValue: hoverArc(e),
                    value: arc(e),
                  };

                  return <Slice key={e.data.id} path={path} />;
                })}
          </g>

          <DonutLabel quoted={quoted} balance={balance} />
        </svg>
      </Box>
    </Stack>
  );
}
