import { Label } from '@goodhuman-me/components';
import React from 'react';
import { ServicePublishStatus } from 'utilities/enum-utils';
import { capitalize } from 'lodash';

interface IProps {
  status: string;
}

const LabelTagStatus: React.FC<IProps> = ({ status }) => {
  let tone: 'positive' | 'blue' | 'notice' = null;

  switch (status) {
    case ServicePublishStatus.PUBLISHED: {
      tone = 'positive';
      break;
    }
    case ServicePublishStatus.UNPUBLISHED:
    case ServicePublishStatus.ARCHIVED: {
      tone = 'blue';
      break;
    }
    case ServicePublishStatus.DRAFT: {
      tone = 'notice';
      break;
    }
  }

  return (
    <Label emphasis="heavy" size="small" tone={tone}>
      {capitalize(status)}
    </Label>
  );
};

export default LabelTagStatus;
