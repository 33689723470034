import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Wifi = forwardRef(function Wifi(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.232 11.047A13.213 13.213 0 0 1 12 6.75c3.866 0 7.344 1.655 9.767 4.297a.75.75 0 1 0 1.106-1.014A14.713 14.713 0 0 0 12 5.25a14.713 14.713 0 0 0-10.873 4.783.75.75 0 1 0 1.105 1.014Zm3.429 2.217A9.215 9.215 0 0 1 12 10.75c2.454 0 4.683.954 6.34 2.514a.75.75 0 0 0 1.027-1.092A10.716 10.716 0 0 0 12 9.25c-2.851 0-5.444 1.11-7.368 2.922a.75.75 0 0 0 1.029 1.092ZM12 14.75c-1.3 0-2.488.471-3.405 1.253a.75.75 0 1 1-.973-1.14A6.726 6.726 0 0 1 12 13.25c1.67 0 3.2.607 4.378 1.612a.75.75 0 1 1-.973 1.141A5.227 5.227 0 0 0 12 14.75ZM12 19a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
