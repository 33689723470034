import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import React, { Component } from 'react';
import { IActivityGroupUsers } from 'src/interfaces/user-interfaces';
import { ActivityGroupMemberType } from 'utilities/enum-utils';
import ActionModal from 'common-components/modal/ActionModal';
import ActivityGroupRemoveMemberSelectedModal from '../../../../views/group-services/activity-groups/components/ActivityGroupRemoveMemberSelectedModal';
import { Paragraph, Text } from 'common-components/typography';

interface IRemoveMemberSelectedModalContentProps {
  serviceId: string;
  activityGroupName: string;
  customersSelected: IActivityGroupUsers[];
  teamMembersSelected: IActivityGroupUsers[];
  onInteractWithRemovedMember: (
    customersUnRemove: IActivityGroupUsers[],
    teamMemberUnRemove: IActivityGroupUsers[],
  ) => void;
  onClose: () => void;
}

interface IRemoveMemberSelectedModalContentState {
  customersSelected: IActivityGroupUsers[];
  teamMembersSelected: IActivityGroupUsers[];
  isLoading: boolean;
}

class RemoveMemberSelectedModalContent extends Component<
  IRemoveMemberSelectedModalContentProps,
  IRemoveMemberSelectedModalContentState
> {
  state = {
    customersSelected: this.props.customersSelected,
    teamMembersSelected: this.props.teamMembersSelected,
    isLoading: false,
  };

  private _handleClickRemove = () => {
    this.setState({ isLoading: true });
    this.props.onInteractWithRemovedMember(this.state.customersSelected, this.state.teamMembersSelected);
    this.setState({ isLoading: false });
    this.props.onClose();
  };

  private _onRemoveCustomersSelected = (members: IActivityGroupUsers[]) => {
    this.setState({ customersSelected: members });
    // this.props.onInteractWithRemovedMember(members, ActivityGroupMemberType.CUSTOMER)
  };

  private _onRemoveTeamMembersSelected = (members: IActivityGroupUsers[]) => {
    this.setState({ teamMembersSelected: members });
    // this.props.onInteractWithRemovedMember(members, ActivityGroupMemberType.TEAM_MEMBER)
  };

  render() {
    return (
      <div>
        <ActionModal
          canCloseOutside={false}
          showHeader={true}
          title={'Remove activity group members'}
          isOpen={true}
          showCloseButton={true}
          width={'large'}
          onClose={this.props.onClose}
        >
          <div className="mb-small">
            <Text weight="regular" size="large" color="dark-gray">
              You're about to remove the following people from
              <b> {this.props.activityGroupName}:</b>
            </Text>
          </div>
          <ActivityGroupRemoveMemberSelectedModal
            memberInActivityGroup={this.state.customersSelected}
            serviceId={this.props.serviceId}
            activityGroupAddType={ActivityGroupMemberType.CUSTOMER}
            onInteractWithAddedMember={this._onRemoveCustomersSelected}
            onClose={this.props.onClose}
          />
          <ActivityGroupRemoveMemberSelectedModal
            memberInActivityGroup={this.state.teamMembersSelected}
            serviceId={this.props.serviceId}
            activityGroupAddType={ActivityGroupMemberType.TEAM_MEMBER}
            onInteractWithAddedMember={this._onRemoveTeamMembersSelected}
            onClose={this.props.onClose}
          />
          <Paragraph className="mt-large">Are you sure?</Paragraph>
          <div className="flex justify-end mt-medium">
            <SecondaryButton size="large" color="secondary" className="mr-12" onClick={this.props.onClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" color="red" onClick={this._handleClickRemove} loading={this.state.isLoading}>
              Remove group members
            </PrimaryButton>
          </div>
        </ActionModal>
      </div>
    );
  }
}

export default RemoveMemberSelectedModalContent;
