import React from 'react';
import { Combobox } from '@good/components';
import { TeamMemberCustomerRatio } from 'utilities/enum-utils';
import { useFormContext } from 'react-hook-form';

type RatioProps = {
  rowPath: string;
};

export const RatioCombobox = (props: RatioProps) => {
  const { rowPath } = props;
  const { getFieldState, register } = useFormContext();

  const ratios = Object.values(TeamMemberCustomerRatio);

  return (
    <Combobox
      className='border-gray'
      data-testid={`${rowPath}`}
      defaultValue='1:1'
      {...register(rowPath)}
      error={getFieldState(rowPath).error?.message}
    >
      {ratios.map((ratio, ratioIndex) => (
        <option key={ratioIndex} value={ratio}>
          {ratio}
        </option>
      ))}
    </Combobox>
  );
};
