import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Laptop = forwardRef(function Laptop(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4.25h-.052c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.08.595-.08 1.345-.08 2.243v7.302h-.917c-.598 0-1.083.485-1.083 1.083a3.417 3.417 0 0 0 3.417 3.417h16.666a3.417 3.417 0 0 0 3.417-3.417c0-.598-.485-1.083-1.083-1.083h-.917V8.948c0-.898 0-1.648-.08-2.242-.084-.628-.27-1.195-.725-1.65-.456-.456-1.023-.642-1.65-.726-.595-.08-1.345-.08-2.243-.08H7Zm13.25 12V9c0-.964-.002-1.612-.067-2.095-.061-.461-.169-.659-.3-.789-.13-.13-.327-.237-.788-.3-.483-.064-1.13-.066-2.095-.066H7c-.964 0-1.612.002-2.095.067-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788C3.753 7.388 3.75 8.036 3.75 9v7.25h16.5ZM3 17.75H1.795c.19.858.956 1.5 1.872 1.5h16.666c.916 0 1.681-.642 1.872-1.5H3Z"
        fill="currentColor"
      />
    </Icon>
  );
});
