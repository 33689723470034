// Segmented Item Control
import { Text } from 'common-components/typography';
import React from 'react';

export function SegmentedItem({ text, isSelected, onSelect }) {
  // const isSelected = selectedValue === value;

  return (
    <div
      className={`bordered ph-medium pv-small cursor-pointer inline-block align-self-start ${
        isSelected ? 'bg-blue-light border-blue-light' : 'bg-white border-standard-gray'
      }`}
      onClick={onSelect}
    >
      <Text color={isSelected ? 'white' : 'black'}>{text}</Text>
    </div>
  );
}
