import { AxiosRequestConfig } from 'axios';
import apiClient from 'utilities/api-client';

/**
 * The data structure which is returned from the API in general.
 */
type RawApiResponse = { status: number; data: object };

/**
 * Parameters required to make any request to the API (like a GET).
 */
type RequestParameters = { path: string; parameters?: URLSearchParams; conf?: AxiosRequestConfig };

/**
 * Parameters required to make a POST/PUT/PATCH request to the API.
 */
type PostRequestParameters = RequestParameters & { data?: Record<string, unknown> };

/**
 * Function takes in a base path and a set of URL search params, and will append those parameters to the path if given.
 * @param path The base path before appending search parameters.
 * @param parameters The search parameters to be appended.
 * @returns
 */
const getQualifiedUrl = (path: string, parameters?: URLSearchParams): string =>
  parameters ? `${path}?${parameters.toString()}` : path;

/**
 * Lightweight typed wrapper around apiClient.get(), for ensuring some level of type safety when communicating with the API.
 * @param params RequestParameters object containing all of the info to make this axios request.
 * @returns Any response type that you set, so long as it's an object.
 * @example
 * To make a GET request:
 * const myData: MyType = await getRequest<MyType>({ path: '/api/portal/my-endpoint' });
 *
 * To make a GET request incl parameters:
 * const myData: MyType = await getRequest<MyType>({
 *   path: '/api/portal/my-endpoint',
 *   params: myRequestParameters,
 * });
 */
export const getRequest = async <T extends object>({ path, parameters, conf }: RequestParameters): Promise<T> => {
  return ((await apiClient.get(getQualifiedUrl(path, parameters), conf)) as RawApiResponse).data as T;
};

/**
 * Lightweight typed wrapper around apiClient.post(), for ensuring some level of type safety when communicating with the API.
 * @param params PostRequestParameters object containing all of the info to make the request.
 * @returns Any response type set, so long as it's an object
 * @example
 * To make a POST request:
 * const myData: MyType = await postRequest<MyType>({ path: '/api/portal/my-endpoint', data: { ... } });
 *
 * To make a POST request incl parameters:
 * const myData: MyType = await postRequest<MyType>({
 *   path: '/api/portal/my-endpoint',
 *   data: { ... },
 *   params: { ... },
 * });
 */
export const postRequest = async <T extends object>({
  path,
  parameters,
  conf,
  data,
}: PostRequestParameters): Promise<T> => {
  return ((await apiClient.post(getQualifiedUrl(path, parameters), data, conf)) as RawApiResponse).data as T;
};
