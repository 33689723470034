import React from 'react';
import { CompatRoute } from 'react-router-dom-v5-compat';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AuthorizedRoutes } from './authorized-routes';
import NonAuthRoutes from './non-authorized-routes';
import { NotFound } from '../views/other';
import { PortalUser } from '../stores/rematch/models/auth-store';
import { useIdentifyLDUser } from '../integrations/launch-darkly';

function NotFoundRoutes() {
  const { en53 } = useFlags();

  return (
    <>
      {en53 && (
        <CompatRoute path='*'>
          <NotFound />
        </CompatRoute>
      )}
    </>
  );
}

type RoutesProps = {
  portalUser?: PortalUser | null;
  isAuthenticated: boolean;
};

function Routes({ portalUser, isAuthenticated }: RoutesProps): JSX.Element {
  void useIdentifyLDUser({ portalUser });

  return (
    <>
      <AuthorizedRoutes />
      <NonAuthRoutes isAuthenticated={isAuthenticated} />
      <NotFoundRoutes />
    </>
  );
}

export default Routes;
