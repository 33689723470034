import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const WrapText = forwardRef(function WrapText(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3.25a.75.75 0 0 0 0 1.5h16a.75.75 0 0 0 0-1.5H4Zm13.5 8.5H4a.75.75 0 0 1 0-1.5h13.5a4.25 4.25 0 0 1 0 8.5h-3.69l1.72 1.72a.75.75 0 1 1-1.06 1.06l-3-3a.75.75 0 0 1 0-1.06l3-3a.75.75 0 1 1 1.06 1.06l-1.72 1.72h3.69a2.75 2.75 0 1 0 0-5.5ZM3.25 18a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
