import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Broadcast = forwardRef(function Broadcast(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.673 3.668a.75.75 0 0 1-.341 1.005C4.612 6.013 2.75 8.793 2.75 12s1.862 5.987 4.582 7.327a.75.75 0 0 1-.664 1.346C3.461 19.09 1.25 15.804 1.25 12c0-3.804 2.21-7.091 5.418-8.673a.75.75 0 0 1 1.005.341Zm8.654 0a.75.75 0 0 1 1.005-.34C20.539 4.907 22.75 8.195 22.75 12c0 3.804-2.21 7.091-5.418 8.673a.75.75 0 0 1-.664-1.346c2.72-1.34 4.582-4.12 4.582-7.327s-1.862-5.987-4.582-7.327a.75.75 0 0 1-.34-1.005ZM8.6 7.55A.75.75 0 0 1 8.45 8.6 4.242 4.242 0 0 0 6.75 12c0 1.39.666 2.624 1.7 3.4a.75.75 0 1 1-.9 1.2 5.742 5.742 0 0 1-2.3-4.6c0-1.881.904-3.552 2.3-4.6a.75.75 0 0 1 1.05.15Zm6.802 0a.75.75 0 0 1 1.05-.149 5.742 5.742 0 0 1 2.3 4.6 5.742 5.742 0 0 1-2.3 4.6.75.75 0 0 1-.901-1.2 4.242 4.242 0 0 0 1.7-3.4c0-1.39-.666-2.624-1.7-3.4a.75.75 0 0 1-.15-1.05ZM10.75 12a1.25 1.25 0 1 1 2.499 0 1.25 1.25 0 0 1-2.5 0ZM12 9.25a2.75 2.75 0 1 0-.001 5.5 2.75 2.75 0 0 0 0-5.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
