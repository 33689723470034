import { Modal, ModalRoot } from '@mantine/core';
import modalClassNames from './modal.module.css';
export const modalProps = {
    Modal: Modal.extend({
        classNames: modalClassNames,
    }),
    ModalRoot: ModalRoot.extend({
        defaultProps: {
            variant: 'desktop',
            size: 'md',
            centered: true,
        },
    }),
};
