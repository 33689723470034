import { Col, Icon } from 'antd';
import moment from 'moment';
import React from 'react';
import { TeamStatus } from 'utilities/enum-utils';
import { Text } from '@good/components';
type TmMgmtLastAccessColumnParams = {
  lastVisited?: Date;
  isBillable: boolean;
  status: TeamStatus;
};

export const TmMgmtLastAccessColumn = ({ status, lastVisited, isBillable }: TmMgmtLastAccessColumnParams) => {
  const lastAccess = moment().diff(moment(lastVisited), 'days');
  const fontColor = status !== TeamStatus.ENABLED ? 'tertiary' : 'black';
  return (
    <Col span={4}>
      {isBillable ? (
        <Icon type="check-circle" className="mr-small text-color-warning-orange" theme="filled" />
      ) : (
        <Icon type="close-circle" className="mr-small text-color-tertiary" />
      )}
      {!lastVisited || moment(lastVisited).unix() === 0 ? (
        <Text className={`text-color-${fontColor}`}>Never</Text>
      ) : (
        <Text className={`text-color-${fontColor}`}>
          {lastAccess > 0 ? `${lastAccess}  day${lastAccess !== 1 ? 's' : ''} ago` : 'Today'}
        </Text>
      )}
    </Col>
  );
};
