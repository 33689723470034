import { Popover2 } from '@blueprintjs/popover2';
import { Col, Icon, Row } from 'antd';
import ActionModal from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import moment from 'moment';
import React from 'react';
import { IStaffingTimeSlot } from 'src/interfaces/session-interfaces';
import { timeZone } from 'src/interfaces/timezone-type';
import { state } from 'stores/rematch/root-store';
import { StaffingStatus } from 'utilities/enum-utils';

interface IStaffingStatusText {
  staffingStatus: StaffingStatus;
}

const StaffingStatusText = ({ staffingStatus }: IStaffingStatusText) => {
  let statusText = 'Optimally staffed';
  let statusColor = 'bg-green';

  switch (staffingStatus) {
    case StaffingStatus.OVERSTAFFED:
      statusText = 'Overstaffed';
      statusColor = 'bg-red';
      break;
    case StaffingStatus.UNDERSTAFFED:
      statusText = 'Understaffed';
      statusColor = 'bg-orange';
      break;
    case StaffingStatus.CORRECTLY_STAFFED:
      statusText = 'Optimally staffed';
      statusColor = 'bg-green';
      break;
  }

  return (
    <div className={`${statusColor} ph-small pv-x-small text-color-white rounded flex align-center width-fit-content`}>
      {statusText}
    </div>
  );
};

interface IStaffingBreakdownItem {
  breakdownItem: IStaffingTimeSlot;
  timezone: timeZone;
  isMultipleDays: boolean;
}

const StaffingBreakdownItem = ({ breakdownItem, timezone, isMultipleDays }: IStaffingBreakdownItem) => {
  const {
    startDateTime,
    endDateTime,
    numberOfTeamMemberRecommended,
    numberOfTeamMemberRecommendedRounded,
    numberOfTeamMemberActual,
    staffingStatus,
    isMergedWithRounded,
  } = breakdownItem;
  const startDateMoment = moment.tz(startDateTime, timezone);
  const endDateMoment = moment.tz(endDateTime, timezone);
  const isShowTooltip =
    isMergedWithRounded ||
    (!isMergedWithRounded && numberOfTeamMemberRecommended !== numberOfTeamMemberRecommendedRounded);
  let borderLeftColor = '#DB3737';

  switch (staffingStatus) {
    case StaffingStatus.OVERSTAFFED:
      borderLeftColor = '#DB3737';
      break;
    case StaffingStatus.UNDERSTAFFED:
      borderLeftColor = '#D9822B';
      break;
    case StaffingStatus.CORRECTLY_STAFFED:
      borderLeftColor = '#0F9960';
      break;
  }

  return (
    <Row
      className="flex width-full align-center bordered pv-12 mb-large"
      style={{ borderLeftWidth: 4, borderLeftColor: borderLeftColor }}
    >
      <Col span={6} className="pl-12">
        {isMultipleDays && (
          <>
            <Text size="x-small" color="secondary" weight="bold">
              {startDateMoment.format('DD/MM/YYYY')}
            </Text>
            <br />
          </>
        )}
        <Text size="large" color="secondary">
          {startDateMoment.format('h:mm a')} - {endDateMoment.format('h:mm a')}
        </Text>
      </Col>
      <Col span={6} className="pl-12">
        <StaffingStatusText staffingStatus={staffingStatus} />
      </Col>
      <Col className="pl-12 width-full flex-column">
        <Text color="secondary" size="large" className="mb-small">
          <b>{numberOfTeamMemberRecommendedRounded}</b> recommended{' '}
          {isShowTooltip && (
            <Popover2
              content={
                <div className="p-medium">
                  {isMergedWithRounded ? (
                    <Text size="regular">Rounded up</Text>
                  ) : (
                    <Text size="regular">
                      Rounded up from <b>{numberOfTeamMemberRecommended}</b>
                    </Text>
                  )}
                </div>
              }
              position="top"
              usePortal={false}
              interactionKind="hover"
            >
              <Icon type="question-circle" className="text-color-secondary" />
            </Popover2>
          )}
        </Text>
        <Text color="secondary" size="large">
          <b>{numberOfTeamMemberActual}</b> rostered
        </Text>
      </Col>
    </Row>
  );
};

interface IViewStaffingBreakdownDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedSession: typeof state.groupServiceStore.selectedSession;
}

const ViewStaffingBreakdownDetailModal = (props: IViewStaffingBreakdownDetailModalProps) => {
  const { isOpen, onClose, selectedSession } = props;
  let isMultipleDays = false;

  const orderByStartDate = (before, after) => {
    const endDateBeforeMoment = moment(before.startDateTime);
    const endDateAfterMoment = moment(after.startDateTime);

    return moment.duration(endDateBeforeMoment.diff(endDateAfterMoment)).asMinutes();
  };

  const breakdownList = Object.keys(selectedSession.sessionTimeSlots)
    .reduce((timeSlots, key) => timeSlots.concat(selectedSession.sessionTimeSlots[key].listTimeSlots), [])
    .sort(orderByStartDate);

  if (breakdownList.length > 0) {
    const startDateMoment = moment.tz(breakdownList[0].startDateTime, selectedSession.timezone);
    const endDateMoment = moment.tz(breakdownList[breakdownList.length - 1].endDateTime, selectedSession.timezone);
    isMultipleDays = !moment(startDateMoment).isSame(endDateMoment, 'day');
  }

  return (
    <ActionModal isOpen={isOpen} title="Staffing breakdown" onClose={onClose} canCloseOutside={true}>
      <div className="mt-large">
        {breakdownList.map((breakdown) => (
          <StaffingBreakdownItem
            breakdownItem={breakdown}
            key={breakdown.startDateTime.toString()}
            timezone={selectedSession.timezone}
            isMultipleDays={isMultipleDays}
          />
        ))}
      </div>
    </ActionModal>
  );
};

export default ViewStaffingBreakdownDetailModal;
