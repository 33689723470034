import React from 'react';
import { Redo } from '@good/icons';

import { useRichTextEditor } from '../rich-text-editor-context';
import { MenuButton } from './menu-button';

export const RedoButton = () => {
  const { editor, disabled } = useRichTextEditor();

  const isDisabled = disabled || !editor.can().chain().focus().redo().run();
  const onClick = () => editor.chain().focus().redo().run();
  const variant = editor.isActive('redo') ? 'filled' : 'transparent';

  return (
    <MenuButton onClick={onClick} disabled={isDisabled} variant={variant} aria-label='redo'>
      <Redo />
    </MenuButton>
  );
};
