import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Forward10 = forwardRef(function Forward10(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 12A9.731 9.731 0 0 1 12 2.25c3.223 0 5.637 1.346 7.23 2.674.387.322.727.645 1.02.948V3.5a.75.75 0 1 1 1.5 0V8a.75.75 0 0 1-1.392.387l-.001-.001-.003-.005-.02-.033a8.919 8.919 0 0 0-.481-.66 11.344 11.344 0 0 0-1.583-1.612C16.863 4.904 14.778 3.75 12 3.75A8.231 8.231 0 0 0 3.75 12a8.25 8.25 0 0 0 16.5 0 .75.75 0 1 1 1.5 0c0 5.385-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM9 8.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V9A.75.75 0 0 1 9 8.25Zm4.5 0a2.25 2.25 0 0 0-2.25 2.25v3a2.25 2.25 0 1 0 4.5 0v-3a2.25 2.25 0 0 0-2.25-2.25Zm-.75 2.25a.75.75 0 1 1 1.5 0v3a.75.75 0 0 1-1.5 0v-3Z"
        fill="currentColor"
      />
    </Icon>
  );
});
