import React, { Component } from 'react';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../stores/rematch/root-store';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from '../../../../common-components/modal/ActionModal';
import { TransportAttendanceType } from '../../../../utilities/enum-utils';
import { notification } from 'antd';
import { Paragraph } from '../../../../common-components/typography';
import { GhostButton, PrimaryButton } from '../../../../common-components/buttons';

interface IRemoveTransportBookingWorkerModalProps {
  isOpen: any;
  onClose: any;
  booking: any;
  attendanceType: TransportAttendanceType;
  doRemoveTransportBookingWorker: typeof dispatch.groupBookingsStore.doRemoveTransportBookingWorker;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
}

interface IRemoveTransportBookingWorkerModalState {
  step: number;
  canManuallyClose: boolean;
  title: string;
}

class RemoveTransportBookingWorkerModal extends Component<
  IRemoveTransportBookingWorkerModalProps,
  IRemoveTransportBookingWorkerModalState
> {
  state = {
    step: 1,
    canManuallyClose: true,
    title: 'Remove team member',
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: 'Remove team member' }, onClose);
  };

  onSubmit = async () => {
    const { booking, selectedGroupBookingItem, doRemoveTransportBookingWorker, attendanceType } = this.props;
    try {
      await doRemoveTransportBookingWorker({
        bookingId: selectedGroupBookingItem.bookingId,
        transportBookingId: booking.attendanceId,
        attendanceType,
      });

      this.setState({ step: 2, canManuallyClose: true, title: 'Team member removed' });
    } catch (e) {
      this.setState({ canManuallyClose: true });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  renderView = () => {
    const { step } = this.state;
    const { booking } = this.props;
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>
            Are you sure you want to remove{' '}
            <b>
              {booking.firstName} {booking.lastName}
            </b>{' '}
            as the assigned team member for this transport booking.
          </Paragraph>

          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this.onSubmit}>
              Remove team member
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>
            You have successfully removed{' '}
            <b>
              {booking.firstName} {booking.lastName}
            </b>{' '}
            as the assigned team member for this transport booking.
          </Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  render() {
    let { isOpen, booking } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        {booking && <div>{this.renderView()}</div>}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveTransportBookingWorker: dispatch.groupBookingsStore.doRemoveTransportBookingWorker,
});

export default connect(mapState, mapDispatch)(RemoveTransportBookingWorkerModal);
