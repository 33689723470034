// eslint-disable -- will be refactored by another task
import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Avatar, Col, Icon, notification, Row, Steps } from 'antd';
import { Stack } from '@goodhuman-me/components';
import AddAddressModal from 'common-components/addresses/AddAddressModal';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import PinnedAlertItem from 'common-components/pinned-alerts/PinnedAlertItem';
import WorkerStatusTag from 'common-components/tags/WorkerStatusTag';
import { TimezoneIndicator, TimezoneSelector } from 'common-components/timezone';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { MessageOrigin } from 'interfaces/message-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import {
  BookingErrorType,
  BookingStatus,
  EditRecurringMode,
  PaymentSources,
  PaymentStatus,
  ServiceLocationType,
  ShiftSlotStatus,
  SleepoverTimeSlotErrorType,
  SleepoverType,
} from 'utilities/enum-utils';
import FundingUtils from 'utilities/funding-utils';
import PermissionUtils from 'utilities/permission-utils';
import Utils from 'utilities/Utils';
import BookingArchiveModal from 'views/bookings/components/BookingArchiveModal';
import BookingCancelChangeToBusinessCancelledModal from 'views/bookings/components/BookingCancelChangeToBusinessCancelledModal';
import BookingCancelChangeToCustomerCancelledModal from 'views/bookings/components/BookingCancelChangeToCustomerCancelledModal';
import BookingCancelChargeFeeModal from 'views/bookings/components/BookingCancelChargeFeeModal';
import BookingCancelActionModal from 'views/bookings/components/BookingCancellationModals/BookingCancelActionModal';
import BookingEditCancellationActionModal from 'views/bookings/components/BookingCancellationModals/BookingEditCancellationActionModal';
import BookingEditCancellationReasonActionModel from 'views/bookings/components/BookingCancellationModals/BookingEditCancellationActionModal/BookingEditCancellationReasonActionModelV1';
import BookingCancelReInstateModal from 'views/bookings/components/BookingCancelReInstateModal';
import BookingCancelRemoveFeeModal from 'views/bookings/components/BookingCancelRemoveFeeModal';
import BookingChargeFeeModal from 'views/bookings/components/BookingChargeFeeModal';
import BookingConfirmShiftForWorkerActionModel from 'views/bookings/components/BookingConfirmShiftForWorkerActionModel';
import BookingNoFeeModal from 'views/bookings/components/BookingNoFeeModal';
import BookingRevertCheckInModel from 'views/bookings/components/BookingRevertCheckInModel';
import BookingRevertCompletedModal from 'views/bookings/components/BookingRevertCompleteModal';
import BookingRevokeApprovalModal from 'views/bookings/components/BookingRevokeApprovalModal';
import BookingWaiveWholeBookingActionModel from 'views/bookings/components/BookingWaiveWholeBookingActionModel';
import RecurringBookingListModal from 'views/bookings/listings/components/RecurringBookingListModal';
import BookingAcceptActionModel from '../../components/BookingAcceptActionModel';
import BookingApproveActionModel from '../../components/BookingApproveActionModel';
import BookingApproveTeamMemberPayForCancelledBooking from '../../components/BookingApproveTeamMemberPayForCancelledBooking';
import BookingAssignWorkerModal from '../../components/BookingAssignWorkerModal';
import BookingCheckinActionModel from '../../components/BookingCheckinActionModel';
import BookingCheckoutActionModel from '../../components/BookingCheckoutActionModel';
import BookingCompleteActionModal from '../../components/BookingCompleteActionModal';
import BookingConfirmActionModel from '../../components/BookingConfirmActionModel';
import BookingEditBookingDatesActionModel from '../../components/BookingEditBookingDatesActionModel';
import BookingEditInstructionsModel from '../../components/BookingEditInstructionsModel';
import BookingEditWorkerCheckedInOutDatesActionModel from '../../components/BookingEditWorkerCheckedInOutDatesActionModel';
import RejectBookingModal from '../../components/BookingRejectActionModal';
import BookingRemoveWorkerActionModal from '../../components/BookingRemoveWorkerActionModal';
import BookingSendToFinanceActionModel from '../../components/BookingSendToFinanceActionModel';
import BookingUpdateTeamMemberPayHoursModal from '../../components/BookingUpdateTeamMemberPayHoursModal';
import PublishBookingSettingsModal from '../../components/PublishBookingSettingsModal';
import RecurringBookingConfirmShiftForWorkerActionModel from '../../components/RecurringBookingConfirmShiftForWorkerActionModel';
import ViewApplicantsModal from '../../components/ViewApplicantsModal';
import BookingWarning from './content-section/tabs-panel/components/BookingWarning';

const { Step } = Steps;

class CancellationCodesTag extends Component<{ code: string }> {
  render() {
    const { code } = this.props;

    const tag =
      code === 'NSDH'
        ? 'No show due to health (NSDH)'
        : code === 'NSDT'
        ? 'No show due to unavailability of transport (NSDT)'
        : code === 'NSDF'
        ? 'No show due to family (NSDF)'
        : code === 'NSDO'
        ? 'Other.'
        : '';

    return <Text color="secondary">{tag}</Text>;
  }
}

class PaymentStatusTag extends Component<{ paymentStatus: string }> {
  render() {
    const { paymentStatus } = this.props;

    const tag =
      paymentStatus === PaymentStatus.REQUIRES_APPROVAL ? (
        'Awaiting Approval'
      ) : paymentStatus === PaymentStatus.READY_FOR_BILLING ? (
        'Approved'
      ) : paymentStatus === PaymentStatus.SEND_TO_FINANCE ? (
        'Sent for Payment'
      ) : paymentStatus === PaymentStatus.PROCESSED ? (
        'Processed'
      ) : paymentStatus === PaymentStatus.REJECTED ? (
        'Declined'
      ) : paymentStatus === PaymentStatus.WAIVED ? (
        'Waived'
      ) : paymentStatus === PaymentStatus.BILLING_FAILED ? (
        <>
          <Icon type="warning" className="text-color-orange mr-x-small" /> Billing Issue
        </>
      ) : paymentStatus === PaymentStatus.NO_CHARGE ? (
        'Not charged'
      ) : (
        ''
      );

    return <Text>{tag}</Text>;
  }
}

interface IBookingDetailOverviewTabV2Props {
  selectedBookingItem: any;
  navigateTab: any;
  doFetchPublishSettings: typeof dispatch.bookingsStore.doFetchPublishSettings;
  doAssignWorker: typeof dispatch.bookingsStore.doAssignWorker;
  doUpdateBookingLocation: typeof dispatch.bookingsStore.doUpdateBookingLocation;
  mode: string;
  userTimezone: timeZone;
  timezone: timeZone;
  bookingDisplayTzCustom: timeZone;
  onChangeTimezone: (type, value) => void;
  portalUser: typeof state.authStore.portalUser;
  isBookingArchived: boolean;
  openSleepoverShiftModal: () => void;
  sleepoverViewRef: any;
  flags: { [key: string]: boolean };
}

class BookingDetailOverviewTabV2Class extends Component<IBookingDetailOverviewTabV2Props> {
  private bookingInstructionElement;
  state = {
    bookingStatus: BookingStatus.PENDING,
    action: '',
    openAction: false,
    openPublishSettings: false,
    openAssignWorker: false,
    displayDescription: true,
    openEditAddress: false,
    openLocationConfirmationModal: false,
    isRecurringListModalOpen: false,
    isBookingPayTeamMemberModalOpen: false,
    isBookingApproveTeamMemberPayModalOpen: false,
    recurringEditOptions: { selectedOption: null, numberOfBookings: null },
    readMore: false,
    hasTextOverflown: true,
    textOffsetHeight: 0,
  };

  private _onPressEditBookingInstruction = () => {
    this.setState({ readMore: false });
    this.openActionModal({ action: 'EDIT_INSTRUCTIONS' });
  };

  openActionModal = ({ action }) => {
    this.setState({ action, openAction: true });
  };

  closeActionModal = () => this.setState({ openAction: false });

  private _openPublishSettingsModal = () => {
    this.setState({ openPublishSettings: true });
  };

  private _closePublishSettingsModal = () => this.setState({ openPublishSettings: false });

  private _openAssignWorkerModal = () => {
    this.setState({ openAssignWorker: true });
  };

  private _closeAssignWorkerModal = () => this.setState({ openAssignWorker: false });

  private _openLocationConfirmationModal = () => {
    this.setState({ openLocationConfirmationModal: true });
  };

  private _openRecurringListModal = () => {
    this.setState({ isRecurringListModalOpen: true });
  };

  private _closeRecurringListModal = () => {
    this.setState({ isRecurringListModalOpen: false });
  };

  private _closeLocationConfirmationModal = () => this.setState({ openLocationConfirmationModal: false });

  private _openTeamMemberPayModal = () => {
    this.setState({ isBookingPayTeamMemberModalOpen: true, action: 'PAY_TEAM_MEMBER' });
  };

  private _closeTeamMemberPayModal = () => {
    this.setState({ isBookingPayTeamMemberModalOpen: false });
  };

  private _openTeamMemberPayApprovalModal = () => {
    this.setState({
      isBookingApproveTeamMemberPayModalOpen: true,
      action: 'APPROVE_TEAM_MEMBER_PAY_FOR_CANCELLED_BOOKING',
    });
  };
  private _closeTeamMemberPayApprovalModal = () => {
    this.setState({ isBookingApproveTeamMemberPayModalOpen: false });
  };

  private _getCancellationMenu = (displayArchiveOption = true) => {
    const { selectedBookingItem } = this.props;
    const { pinc912CancellationFlowEnhancements } = this.props.flags;
    return (
      <Menu>
        {!pinc912CancellationFlowEnhancements &&
          (selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITH_FEE ||
            selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE) && (
            <MenuItem
              label={''}
              onClick={() => this.openActionModal({ action: 'CHANGE_TO_BUSINESS_CANCEL' })}
              className="hover-bg-blue-lightest mv-medium"
              text={"Change to 'Cancelled by Business'"}
            />
          )}
        {!pinc912CancellationFlowEnhancements && selectedBookingItem.status === BookingStatus.BUSINESS_CANCELLED && (
          <MenuItem
            label={''}
            onClick={() => this.openActionModal({ action: 'CHANGE_TO_CUSTOMER_CANCEL' })}
            className="hover-bg-blue-lightest mv-medium"
            text={"Change to 'Cancelled by Customer'"}
          />
        )}
        {!pinc912CancellationFlowEnhancements &&
          selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITH_FEE && (
            <MenuItem
              label={''}
              onClick={() => this.openActionModal({ action: 'REMOVE_CANCELLATION_FEE' })}
              className="hover-bg-blue-lightest mv-medium"
              text={'Remove cancellation fee'}
            />
          )}
        {!pinc912CancellationFlowEnhancements &&
          selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE && (
            <MenuItem
              label={''}
              onClick={() => this.openActionModal({ action: 'ADD_CANCELLATION_FEE' })}
              className="hover-bg-blue-lightest mv-medium"
              text={'Charge cancellation fee'}
            />
          )}
        {this._isBeforeCustomerScheduledArchiveDate() ? (
          <MenuItem
            label={''}
            onClick={() => this.openActionModal({ action: 'REINSTATE_BOOKING' })}
            className="hover-bg-blue-lightest mv-medium"
            text={'Reinstate booking'}
          />
        ) : (
          ''
        )}
        {displayArchiveOption && (
          <MenuItem
            label={''}
            onClick={() => this.openActionModal({ action: 'ARCHIVE_BOOKING' })}
            className="hover-bg-blue-lightest mv-medium"
            text={'Archive booking'}
          />
        )}
      </Menu>
    );
  };

  getActionModal = () => {
    const { ny2047, pinc912CancellationFlowEnhancements } = this.props.flags;
    // Depending on the action target type, use the correct modal.
    if (this.state.action === 'ACCEPT') {
      return BookingAcceptActionModel;
    } else if (this.state.action === 'CHECKIN') {
      return BookingCheckinActionModel;
    } else if (this.state.action === 'CHECKOUT') {
      return BookingCheckoutActionModel;
    } else if (ny2047 && this.state.action === 'COMPLETE') {
      return BookingCompleteActionModal;
    } else if (this.state.action === 'CHARGE') {
      return BookingCancelActionModal;
    } else if (this.state.action === 'REJECT') {
      return RejectBookingModal;
    } else if (this.state.action === 'CONFIRM') {
      return BookingConfirmActionModel;
    } else if (this.state.action === 'APPROVE') {
      return BookingApproveActionModel;
    } else if (this.state.action === 'SEND_TO_FINANCE') {
      return BookingSendToFinanceActionModel;
    } else if (this.state.action === 'CONFIRM_SHIFT') {
      if (this.props.selectedBookingItem.isRecurring) {
        return RecurringBookingConfirmShiftForWorkerActionModel;
      } else {
        return BookingConfirmShiftForWorkerActionModel;
      }
    } else if (this.state.action === 'REMOVE_WORKER') {
      return BookingRemoveWorkerActionModal;
    } else if (this.state.action === 'CANCEL') {
      return BookingCancelActionModal;
    } else if (this.state.action === 'REMOVE_CANCELLATION_FEE') {
      return BookingCancelRemoveFeeModal;
    } else if (this.state.action === 'ADD_CANCELLATION_FEE') {
      return BookingCancelChargeFeeModal;
    } else if (this.state.action === 'CHARGE_CANCELLATION_FEE') {
      return BookingChargeFeeModal;
    } else if (this.state.action === 'NO_CANCELLATION_FEE') {
      return BookingNoFeeModal;
    } else if (this.state.action === 'CHANGE_TO_BUSINESS_CANCEL') {
      return BookingCancelChangeToBusinessCancelledModal;
    } else if (this.state.action === 'CHANGE_TO_CUSTOMER_CANCEL') {
      return BookingCancelChangeToCustomerCancelledModal;
    } else if (this.state.action === 'REINSTATE_BOOKING') {
      return BookingCancelReInstateModal;
    } else if (this.state.action === 'WAIVE_BOOKING') {
      return BookingWaiveWholeBookingActionModel;
    } else if (this.state.action === 'EDIT_BOOKING_DATES') {
      return BookingEditBookingDatesActionModel;
    } else if (this.state.action === 'EDIT_WORKER_CHECKINOUT_DATES') {
      return BookingEditWorkerCheckedInOutDatesActionModel;
    } else if (this.state.action === 'EDIT_CANCELLATION_REASON') {
      if (pinc912CancellationFlowEnhancements) {
        return BookingEditCancellationActionModal;
      } else {
        return BookingEditCancellationReasonActionModel;
      }
    } else if (this.state.action === 'VIEW_APPLICANTS') {
      return ViewApplicantsModal;
    } else if (this.state.action === 'EDIT_INSTRUCTIONS') {
      return BookingEditInstructionsModel;
    } else if (this.state.action === 'REVOKE_APPROVAL') {
      return BookingRevokeApprovalModal;
    } else if (this.state.action === 'ARCHIVE_BOOKING') {
      return BookingArchiveModal;
    } else if (this.state.action === 'REVERT_COMPLETED') {
      return BookingRevertCompletedModal;
    } else if (this.state.action === 'REVERT_CHECK_IN') {
      return BookingRevertCheckInModel;
    } else if (this.state.action === 'PAY_TEAM_MEMBER') {
      return BookingUpdateTeamMemberPayHoursModal;
    } else if (this.state.action === 'APPROVE_TEAM_MEMBER_PAY_FOR_CANCELLED_BOOKING') {
      return BookingApproveTeamMemberPayForCancelledBooking;
    } else {
      return () => <></>;
    }
  };

  checkPreviousStatus = (actionTypeSearched) => {
    const { selectedBookingItem } = this.props;
    return !_.isEmpty(
      _.uniq(selectedBookingItem.bookingHistories.map((obj) => obj.actionType)).filter(
        (status) => status === actionTypeSearched,
      ),
    );
  };

  private _billingErrors = () => {
    const { selectedBookingItem } = this.props;

    const lineItems = selectedBookingItem.billingLineItems;

    const isNoPaymentMethodItems = _.countBy(
      lineItems,
      (item) => !item.paymentMethod && item.paymentSourceType === PaymentSources.NDIS,
    ).true;

    const fundedLineItems = _.countBy(
      lineItems,
      (item) =>
        item.paymentSourceType === PaymentSources.NDIS &&
        !FundingUtils.doesLineItemFundingCategoryExistInFundingPackage(
          item.supportType,
          item.supportCategoryNumber,
          item.supportItemNumber,
          selectedBookingItem.fundedCategories,
        ),
    ).true;

    return { isNoPaymentMethodItems, fundedLineItems };
  };

  renderCancelledOrArchivedStatusBar = (statusName, description = null) => {
    // const { statusName } = this.props;
    if (this.checkPreviousStatus('Booking Confirmed')) {
      if (this.checkPreviousStatus('Booking Checked In')) {
        // Build Status Bar ( Checked In last )
        return (
          <Steps current={4} size="small" className="mt-x-large" style={{ width: '100%' }}>
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Requested</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-gray text-weight-bold">Accepted</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-gray text-weight-bold">Confirmed</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-gray text-weight-bold">In Progress</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-red" theme="filled" />}
              title={<Text className="text-color-red text-weight-bold">{statusName}</Text>}
              description={
                description ? (
                  <Text size="regular" color="secondary">
                    {description}
                  </Text>
                ) : (
                  ''
                )
              }
            />
          </Steps>
        );
      } else {
        // Build Status Bar ( Confirmed last )
        return (
          <Steps current={3} size="small" className="mt-x-large" style={{ width: '80%' }}>
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Requested</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-gray text-weight-bold">Accepted</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-gray text-weight-bold">Confirmed</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-red" theme="filled" />}
              title={<Text className="text-color-red text-weight-bold">{statusName}</Text>}
              description={
                description ? (
                  <Text size="regular" color="secondary">
                    {description}
                  </Text>
                ) : (
                  ''
                )
              }
            />
          </Steps>
        );
      }
    } else {
      // Build Status Bar ( Accepted last )
      return (
        <Steps current={2} size="small" className="mt-x-large" style={{ width: '60%' }}>
          <Step
            icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
            title={<Text className="text-color-secondary text-weight-bold">Requested</Text>}
          />
          <Step
            icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
            title={<Text className="text-color-gray text-weight-bold">Accepted</Text>}
          />
          <Step
            icon={<Icon type="check-circle" className="text-color-red" theme="filled" />}
            title={<Text className="text-color-red text-weight-bold">{statusName}</Text>}
            description={
              description ? (
                <Text size="regular" color="secondary">
                  {description}
                </Text>
              ) : (
                ''
              )
            }
          />
        </Steps>
      );
    }
  };

  private _isBookingCancelled = (bookingItem) => {
    return (
      bookingItem.status === BookingStatus.BUSINESS_CANCELLED ||
      bookingItem.status === BookingStatus.CUSTOMER_CANCELLED ||
      bookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITH_FEE ||
      bookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE
    );
  };

  renderStatusBar = () => {
    const { selectedBookingItem } = this.props;

    switch (selectedBookingItem.status) {
      case 'PENDING':
        return (
          <Steps current={0} size="small" className="mt-x-large">
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Requested</Text>}
            />
            <Step
              icon={
                <div
                  className="bordered rounded-full"
                  style={{ height: '23px', width: '23px', borderWidth: 'medium' }}
                />
              }
              title="Accepted"
            />
            <Step
              icon={
                <div
                  className="bordered rounded-full"
                  style={{ height: '23px', width: '23px', borderWidth: 'medium' }}
                />
              }
              title="Confirmed"
            />
            <Step
              icon={
                <div
                  className="bordered rounded-full"
                  style={{ height: '23px', width: '23px', borderWidth: 'medium' }}
                />
              }
              title="In Progress"
            />
            <Step
              icon={
                <div
                  className="bordered rounded-full"
                  style={{ height: '23px', width: '23px', borderWidth: 'medium' }}
                />
              }
              title="Completed"
            />
          </Steps>
        );
      case 'REJECTED':
        return (
          <Steps current={1} size="small" className="mt-x-large" style={{ width: '40%' }}>
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Requested</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-red" theme="filled" />}
              title={<Text className="text-color-red text-weight-bold">Declined</Text>}
            />
          </Steps>
        );
      case 'ACCEPTED':
        return (
          <Steps current={1} size="small" className="mt-x-large">
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Requested</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-green" theme="filled" />}
              title={<Text className="text-color-green text-weight-bold">Accepted</Text>}
            />
            <Step
              icon={
                <div
                  className="bordered rounded-full"
                  style={{ height: '23px', width: '23px', borderWidth: 'medium' }}
                />
              }
              title="Confirmed"
            />
            <Step
              icon={
                <div
                  className="bordered rounded-full"
                  style={{ height: '23px', width: '23px', borderWidth: 'medium' }}
                />
              }
              title="In Progress"
            />
            <Step
              icon={
                <div
                  className="bordered rounded-full"
                  style={{ height: '23px', width: '23px', borderWidth: 'medium' }}
                />
              }
              title="Completed"
            />
          </Steps>
        );
      case 'CANCELLED':
        return this.renderCancelledOrArchivedStatusBar('Cancelled');
      case 'BUSINESS_CANCELLED':
        return this.renderCancelledOrArchivedStatusBar('Cancelled by Business');
      case 'CUSTOMER_CANCELLED':
        return this.renderCancelledOrArchivedStatusBar('Cancelled by Customer');
      case 'CUSTOMER_CANCELLED_WITH_FEE':
        return this.renderCancelledOrArchivedStatusBar('Cancelled by Customer - Fee');
      case 'CUSTOMER_CANCELLED_WITHOUT_FEE':
        return this.renderCancelledOrArchivedStatusBar('Cancelled by Customer - No fee');
      case 'ARCHIVED':
        return (
          <Steps current={2} size="small" className="mt-x-large">
            <Step
              icon={<Icon type="close-circle" className="text-color-red-dark" theme="filled" />}
              title={<Text className="text-color-red-dark text-weight-bold">Archived</Text>}
            />
          </Steps>
        );
      case 'CONFIRMED':
        return (
          <Steps current={2} size="small" className="mt-x-large">
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Requested</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Accepted</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-blue" theme="filled" />}
              title={<Text className="text-color-blue text-weight-bold">Confirmed</Text>}
            />
            <Step
              icon={
                <div
                  className="bordered rounded-full"
                  style={{ height: '23px', width: '23px', borderWidth: 'medium' }}
                />
              }
              title="In Progress"
            />
            <Step
              icon={
                <div
                  className="bordered rounded-full"
                  style={{ height: '23px', width: '23px', borderWidth: 'medium' }}
                />
              }
              title="Completed"
            />
          </Steps>
        );
      case 'INPROGRESS':
        return (
          <Steps current={3} size="small" className="mt-x-large">
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Requested</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Accepted</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Confirmed</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-gold" theme="filled" />}
              title={<Text className="text-color-gold text-weight-bold">In Progress</Text>}
            />
            <Step
              icon={
                <div
                  className="bordered rounded-full"
                  style={{ height: '23px', width: '23px', borderWidth: 'medium' }}
                />
              }
              title="Completed"
            />
          </Steps>
        );
      case 'COMPLETED':
        return (
          <Steps current={4} size="small" className="mt-x-large">
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Requested</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Accepted</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">Confirmed</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-gray" theme="filled" />}
              title={<Text className="text-color-secondary text-weight-bold">In Progress</Text>}
            />
            <Step
              icon={<Icon type="check-circle" className="text-color-indigo" theme="filled" />}
              title={<Text className="text-color-indigo text-weight-bold">Completed</Text>}
            />
          </Steps>
        );
      default:
        // return <div className="flex-row flex-wrap">N/A</div>;
        return '';
    }
  };

  renderActionButtons = () => {
    const { selectedBookingItem, portalUser, flags } = this.props;
    const { ny2047, pinc912CancellationFlowEnhancements } = flags;
    const { bookingErrors } = selectedBookingItem;
    const isSleepoverError = _.some(
      bookingErrors,
      (error) =>
        error.bookingErrorType === BookingErrorType.SleepoverConflictBookingTime ||
        error.bookingErrorType === BookingErrorType.SleepoverConflictCheckinoutTime,
    );
    const isDisturbanceError = _.some(
      bookingErrors,
      (error) =>
        error.bookingErrorType === BookingErrorType.DisturbancOutOfBookingTimeError ||
        error.bookingErrorType === BookingErrorType.DisturbancOutOfCheckinCheckoutTimeError ||
        error.bookingErrorType === BookingErrorType.DisturbancOutOfSleepoverTimeError,
    );

    const archiveBookingMenu = (
      <div className="flex-row flex-wrap">
        <Popover2
          content={
            <Menu>
              {ny2047 && (
                <>
                  {PermissionUtils.validatePermission(
                    'StartBooking',
                    portalUser.permissions.permissionRoles,
                    selectedBookingItem.serviceDepartmentId,
                    selectedBookingItem.serviceId,
                  ) &&
                    selectedBookingItem.shiftSlotStatus === BookingStatus.CONFIRMED && (
                      <MenuItem
                        label={''}
                        onClick={() => this.openActionModal({ action: 'CHECKIN' })}
                        className="hover-bg-blue-lightest mv-medium"
                        text="Start booking"
                      />
                    )}
                </>
              )}

              <MenuItem
                label={''}
                disabled={
                  selectedBookingItem.status === BookingStatus.INPROGRESS ||
                  selectedBookingItem.status === BookingStatus.COMPLETED ||
                  (selectedBookingItem.paymentStatus !== PaymentStatus.REQUIRES_APPROVAL &&
                    selectedBookingItem.paymentStatus !== PaymentStatus.INITIAL &&
                    selectedBookingItem.paymentStatus !== PaymentStatus.WAIVED &&
                    selectedBookingItem.paymentStatus !== PaymentStatus.NO_CHARGE)
                }
                onClick={() => this.openActionModal({ action: 'ARCHIVE_BOOKING' })}
                className="hover-bg-blue-lightest mv-medium"
                text={'Archive booking'}
              />
            </Menu>
          }
          popoverClassName={'mb-medium'}
          position={'bottom'}
          interactionKind="click"
        >
          <SecondaryButton size={'large'} className="mr-medium" icon={'ellipsis'} />
        </Popover2>
      </div>
    );

    const revertBookingMenu = () => {
      const { selectedBookingItem } = this.props;
      const afterApprovalBillingItems = _.filter(
        selectedBookingItem.billingLineItems,
        (item) =>
          item.paymentStatus !== PaymentStatus.INITIAL && item.paymentStatus !== PaymentStatus.REQUIRES_APPROVAL,
      );
      if (
        (selectedBookingItem.status === BookingStatus.INPROGRESS ||
          selectedBookingItem.status === BookingStatus.COMPLETED) &&
        afterApprovalBillingItems.length === 0
      ) {
        return (
          <div className="flex-row flex-wrap">
            <Popover2
              content={
                <Menu>
                  <MenuItem
                    label={''}
                    onClick={() =>
                      this.openActionModal({
                        action:
                          selectedBookingItem.status === BookingStatus.INPROGRESS
                            ? 'REVERT_CHECK_IN'
                            : 'REVERT_COMPLETED',
                      })
                    }
                    className="hover-bg-blue-lightest mv-medium"
                    text={
                      selectedBookingItem.status === BookingStatus.INPROGRESS
                        ? 'Revert to confirmed'
                        : 'Undo booking completion'
                    }
                  />
                </Menu>
              }
              popoverClassName={'mb-medium'}
              position={'bottom'}
              interactionKind="click"
            >
              <SecondaryButton size={'large'} className="mr-medium" icon={'ellipsis'} />
            </Popover2>
          </div>
        );
      }
    };

    const billingErrors = this._billingErrors();

    switch (selectedBookingItem.status) {
      case 'PENDING':
        return (
          // Create the
          <div className="flex-row flex-wrap">
            {PermissionUtils.validatePermission(
              'AcceptBooking',
              portalUser.permissions.permissionRoles,
              selectedBookingItem.serviceDepartmentId,
              selectedBookingItem.serviceId,
            ) && (
              <PrimaryButton
                size="large"
                className="mr-medium"
                icon="check"
                onClick={() => this.openActionModal({ action: 'ACCEPT' })}
              >
                Accept Booking
              </PrimaryButton>
            )}
            {PermissionUtils.validatePermission(
              'DeclineBooking',
              portalUser.permissions.permissionRoles,
              selectedBookingItem.serviceDepartmentId,
              selectedBookingItem.serviceId,
            ) && (
              <PrimaryButton
                size="large"
                className="mr-medium"
                icon="close"
                color="red"
                onClick={() => this.openActionModal({ action: 'REJECT' })}
              >
                Decline Booking
              </PrimaryButton>
            )}
          </div>
        );
      case 'ACCEPTED':
        return (
          <div className="flex-row flex-wrap">
            {PermissionUtils.validatePermission(
              'ConfirmBooking',
              portalUser.permissions.permissionRoles,
              selectedBookingItem.serviceDepartmentId,
              selectedBookingItem.serviceId,
            ) && (
              <PrimaryButton
                size="large"
                className="mr-medium"
                icon="check"
                onClick={() => this.openActionModal({ action: 'CONFIRM' })}
              >
                Confirm Booking
              </PrimaryButton>
            )}

            {PermissionUtils.validatePermission(
              'CancelBooking',
              portalUser.permissions.permissionRoles,
              selectedBookingItem.serviceDepartmentId,
              selectedBookingItem.serviceId,
            ) && (
              <SecondaryButton
                size="large"
                className="mr-medium"
                icon="close"
                color="red"
                onClick={() => this.openActionModal({ action: 'CANCEL' })}
              >
                Cancel booking
              </SecondaryButton>
            )}
            {archiveBookingMenu}
          </div>
        );
      case 'CONFIRMED':
        return (
          <div className="align-center flex-row flex-wrap">
            {PermissionUtils.validatePermission(
              'StartBooking',
              portalUser.permissions.permissionRoles,
              selectedBookingItem.serviceDepartmentId,
              selectedBookingItem.serviceId,
            ) && (
              <>
                <PrimaryButton
                  disabled={selectedBookingItem.shiftSlotStatus !== ShiftSlotStatus.CONFIRMED}
                  size="large"
                  className="mr-medium"
                  icon="carry-out"
                  onClick={() => this.openActionModal({ action: ny2047 ? 'COMPLETE' : 'CHECKIN' })}
                >
                  {ny2047 ? 'Complete booking' : 'Start Shift'}
                </PrimaryButton>
                {_.includes(
                  [ShiftSlotStatus.PENDING, ShiftSlotStatus.UNASSIGNED],
                  selectedBookingItem.shiftSlotStatus,
                ) && (
                  <Tooltip2
                    position="bottom"
                    content={
                      <Text color="white">
                        {ny2047 ? (
                          <>
                            Before completing the booking, you need to assign a team member
                            <br /> to the booking and make sure the shift is confirmed.
                            <br />
                            <br />
                            The worker can confirm the shift on their GoodHuman app,
                            <br /> or you can confirm the shift on behalf of the worker.
                          </>
                        ) : (
                          <>
                            {selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.UNASSIGNED && (
                              <>
                                You can&apos;t start the shift until
                                <br />a team member is assigned
                              </>
                            )}
                            {selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.PENDING && (
                              <>
                                Before Check-In, you need to select a Worker
                                <br /> and make sure the shift is confirmed.
                                <br />
                                <br />
                                The Worker can confirm the shift on their GoodHuman App
                                <br /> or you can click on &apos;Confirm Shift&apos; next to the Worker section above.
                              </>
                            )}
                          </>
                        )}
                      </Text>
                    }
                  >
                    <Icon
                      type="question-circle"
                      style={{ marginLeft: '-8px' }}
                      className="text-size-x-large mr-medium text-color-blue"
                    />
                  </Tooltip2>
                )}
              </>
            )}
            <SecondaryButton
              size="large"
              className="mr-medium"
              icon="close"
              onClick={() => this.openActionModal({ action: 'CANCEL' })}
            >
              Cancel booking
            </SecondaryButton>
            {archiveBookingMenu}
          </div>
        );
      case 'INPROGRESS':
        return (
          <div className="flex-row flex-wrap">
            {PermissionUtils.validatePermission(
              'FinishBooking',
              portalUser.permissions.permissionRoles,
              selectedBookingItem.serviceDepartmentId,
              selectedBookingItem.serviceId,
            ) && (
              <PrimaryButton
                size="large"
                className="mr-medium"
                icon="carry-out"
                onClick={() => this.openActionModal({ action: 'CHECKOUT' })}
              >
                Finish shift
              </PrimaryButton>
            )}
            {revertBookingMenu()}
          </div>
        );
      case 'COMPLETED':
        if (selectedBookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL) {
          return (
            <div className="align-center flex-row flex-wrap">
              {PermissionUtils.validatePermission(
                'ApproveBooking',
                portalUser.permissions.permissionRoles,
                selectedBookingItem.serviceDepartmentId,
                selectedBookingItem.serviceId,
              ) && (
                <>
                  <PrimaryButton
                    size="large"
                    className="mr-medium"
                    disabled={
                      billingErrors.isNoPaymentMethodItems >= 1 ||
                      selectedBookingItem.billingLineItems.length === 0 ||
                      isSleepoverError ||
                      isDisturbanceError
                    }
                    icon="check-circle"
                    onClick={() => this.openActionModal({ action: 'APPROVE' })}
                  >
                    Approve for Payment
                  </PrimaryButton>
                  {isSleepoverError || isDisturbanceError ? (
                    <Tooltip2 position="top" content="Please resolve the errors on this booking before approving.">
                      <Icon
                        type="question-circle"
                        style={{ marginLeft: '-8px', marginRight: '8px' }}
                        className="text-size-x-large text-color-blue"
                      />
                    </Tooltip2>
                  ) : billingErrors.isNoPaymentMethodItems >= 1 ? (
                    <Tooltip2
                      position="top"
                      content="You cannot send to finance a booking with a line without a payment method."
                    >
                      <Icon
                        type="question-circle"
                        style={{ marginLeft: '-8px', marginRight: '8px' }}
                        className="text-size-x-large text-color-blue"
                      />
                    </Tooltip2>
                  ) : selectedBookingItem.billingLineItems.length === 0 ? (
                    <Tooltip2
                      position="top"
                      content="You cannot send a booking to finance without any billing line items."
                    >
                      <Icon
                        type="question-circle"
                        style={{ marginLeft: '-8px', marginRight: '8px' }}
                        className="text-size-x-large text-color-blue"
                      />
                    </Tooltip2>
                  ) : null}
                </>
              )}
              {PermissionUtils.validatePermission(
                'WaiveBooking',
                portalUser.permissions.permissionRoles,
                selectedBookingItem.serviceDepartmentId,
                selectedBookingItem.serviceId,
              ) &&
                !(isSleepoverError || isDisturbanceError) && (
                  <SecondaryButton
                    size="large"
                    className="mr-medium"
                    icon="close-circle"
                    onClick={() => this.openActionModal({ action: 'WAIVE_BOOKING' })}
                  >
                    Waive booking
                  </SecondaryButton>
                )}
              {revertBookingMenu()}
            </div>
          );
        } else if (selectedBookingItem.paymentStatus === PaymentStatus.INITIAL) {
          return (
            <div className="align-center flex-row flex-wrap">
              {PermissionUtils.validatePermission(
                'ApproveBooking',
                portalUser.permissions.permissionRoles,
                selectedBookingItem.serviceDepartmentId,
                selectedBookingItem.serviceId,
              ) && (
                <>
                  <PrimaryButton
                    size="large"
                    className="mr-medium"
                    disabled={
                      billingErrors.isNoPaymentMethodItems >= 1 || selectedBookingItem.billingLineItems.length === 0
                    }
                    icon="check-circle"
                    onClick={() => this.openActionModal({ action: 'APPROVE' })}
                  >
                    Approve for Payment
                  </PrimaryButton>
                  {selectedBookingItem.billingLineItems.length === 0 && (
                    <Tooltip2 content="You cannot send a booking to finance without any billing line items.">
                      <Icon
                        type="question-circle"
                        style={{ marginLeft: '-8px', marginRight: '8px' }}
                        className="text-size-x-large text-color-blue"
                      />
                    </Tooltip2>
                  )}
                </>
              )}
              {revertBookingMenu()}
            </div>
          );
        } else {
          const hasRejectedProcessedBillingLineItem = _.find(
            selectedBookingItem.billingLineItems,
            (item) => item.paymentStatus === PaymentStatus.REJECTED || item.paymentStatus === PaymentStatus.PROCESSED,
          );
          const hasNotWaviedSentToPaymentsBillingLineItem = _.find(
            selectedBookingItem.billingLineItems,
            (item) =>
              item.paymentStatus !== PaymentStatus.SEND_TO_FINANCE && item.paymentStatus !== PaymentStatus.WAIVED,
          );
          return (
            <div className="flex-row flex-wrap">
              <PrimaryButton
                size="large"
                className="mr-medium"
                disabled={!!hasRejectedProcessedBillingLineItem || !!hasNotWaviedSentToPaymentsBillingLineItem}
                icon="check-circle"
                onClick={() => this.openActionModal({ action: 'REVOKE_APPROVAL' })}
              >
                Revoke approval
              </PrimaryButton>
              {(!!hasRejectedProcessedBillingLineItem || !!hasNotWaviedSentToPaymentsBillingLineItem) && (
                <Tooltip2
                  position="top"
                  content="You cannot revoke approval for this booking as it has already been processed in payments"
                >
                  <Icon
                    type="question-circle"
                    style={{ marginLeft: '-8px', marginRight: '8px' }}
                    className="text-size-x-large text-color-blue"
                  />
                </Tooltip2>
              )}
            </div>
          );
        }
      case 'CUSTOMER_CANCELLED_WITH_FEE': {
        const hasRejectedProcessedBillingLineItem = _.find(
          selectedBookingItem.billingLineItems,
          (item) => item.paymentStatus === PaymentStatus.REJECTED || item.paymentStatus === PaymentStatus.PROCESSED,
        );
        const hasNotWaivedSentToPaymentsBillingLineItem = _.find(
          selectedBookingItem.billingLineItems,
          (item) => item.paymentStatus !== PaymentStatus.SEND_TO_FINANCE && item.paymentStatus !== PaymentStatus.WAIVED,
        );
        return (
          <div className="align-center flex-row flex-wrap">
            {selectedBookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL && (
              <>
                <PrimaryButton
                  size="large"
                  className="mr-medium"
                  disabled={
                    billingErrors.isNoPaymentMethodItems >= 1 ||
                    selectedBookingItem.billingLineItems.length === 0 ||
                    isSleepoverError ||
                    isDisturbanceError
                  }
                  icon="check-circle"
                  onClick={() => this.openActionModal({ action: 'APPROVE' })}
                >
                  Approve Booking
                </PrimaryButton>
                {billingErrors.isNoPaymentMethodItems >= 1 ? (
                  <Tooltip2
                    position="top"
                    content="You cannot send to finance a booking with a line without a payment method."
                  >
                    <Icon
                      type="question-circle"
                      style={{ marginLeft: '-8px', marginRight: '8px' }}
                      className="text-size-x-large text-color-blue"
                    />
                  </Tooltip2>
                ) : selectedBookingItem.billingLineItems.length === 0 ? (
                  <Tooltip2
                    position="top"
                    content="You cannot send a booking to finance without any billing line items."
                  >
                    <Icon
                      type="question-circle"
                      style={{ marginLeft: '-8px', marginRight: '8px' }}
                      className="text-size-x-large text-color-blue"
                    />
                  </Tooltip2>
                ) : (
                  <></>
                )}
                <SecondaryButton
                  size="large"
                  className="mr-medium"
                  icon="close-circle"
                  onClick={() => this.openActionModal({ action: 'WAIVE_BOOKING' })}
                  disabled={isSleepoverError || isDisturbanceError}
                >
                  Waive booking
                </SecondaryButton>
                <Popover2
                  content={this._getCancellationMenu()}
                  popoverClassName={'mb-medium'}
                  position={'bottom'}
                  interactionKind="click"
                >
                  <SecondaryButton size="large" className="mr-medium" icon={'ellipsis'} iconPosition={'right'} />
                </Popover2>
              </>
            )}
            {selectedBookingItem.paymentStatus !== PaymentStatus.REQUIRES_APPROVAL && (
              <>
                <PrimaryButton
                  size="large"
                  className="mr-medium"
                  disabled={!!hasRejectedProcessedBillingLineItem || hasNotWaivedSentToPaymentsBillingLineItem}
                  icon="check-circle"
                  onClick={() => this.openActionModal({ action: 'REVOKE_APPROVAL' })}
                >
                  Revoke approval
                </PrimaryButton>
                {(!!hasRejectedProcessedBillingLineItem || hasNotWaivedSentToPaymentsBillingLineItem) && (
                  <Tooltip2
                    position="top"
                    content="You cannot revoke approval for this booking as it has already been processed in payments"
                  >
                    <Icon
                      type="question-circle"
                      style={{ marginLeft: '-8px', marginRight: '8px' }}
                      className="text-size-x-large text-color-blue"
                    />
                  </Tooltip2>
                )}
                {archiveBookingMenu}
              </>
            )}
          </div>
        );
      }
      case 'CUSTOMER_CANCELLED_WITHOUT_FEE':
        return (
          <div className="flex-row flex-wrap">
            {PermissionUtils.validatePermission(
              'ApproveCancelledBookingTeamMemberPay',
              portalUser.permissions.permissionRoles,
              selectedBookingItem.serviceDepartmentId,
              selectedBookingItem.serviceId,
            ) &&
              selectedBookingItem.workerUserId &&
              (selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID ? (
                <PrimaryButton
                  size="large"
                  className="mr-medium"
                  icon="check-circle"
                  onClick={this._openTeamMemberPayApprovalModal}
                  disabled={isSleepoverError || isDisturbanceError}
                >
                  Approve team member payment
                </PrimaryButton>
              ) : selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.SENT_TO_FINANCE ? (
                <SecondaryButton
                  size="large"
                  className="mr-medium"
                  icon={'close-circle'}
                  onClick={this._openTeamMemberPayApprovalModal}
                >
                  Revoke team member payment
                </SecondaryButton>
              ) : null)}
            <>
              {pinc912CancellationFlowEnhancements ? (
                <SecondaryButton
                  size="large"
                  className="mr-medium"
                  disabled={!this._isBeforeCustomerScheduledArchiveDate()}
                  icon="check-circle"
                  onClick={() => this.openActionModal({ action: 'REINSTATE_BOOKING' })}
                >
                  Reinstate booking
                </SecondaryButton>
              ) : (
                <Popover2
                  content={this._getCancellationMenu(false)}
                  popoverClassName={'mb-medium'}
                  position={'bottom'}
                  interactionKind="click"
                >
                  <SecondaryButton size="large" className="mr-medium" iconPosition={'right'}>
                    Cancellation actions
                  </SecondaryButton>
                </Popover2>
              )}
              {archiveBookingMenu}
            </>
          </div>
        );
      case 'BUSINESS_CANCELLED':
        return (
          <div className="flex-row flex-wrap">
            {PermissionUtils.validatePermission(
              'ApproveCancelledBookingTeamMemberPay',
              portalUser.permissions.permissionRoles,
              selectedBookingItem.serviceDepartmentId,
              selectedBookingItem.serviceId,
            ) &&
              selectedBookingItem.workerUserId &&
              (selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID ? (
                <PrimaryButton
                  size="large"
                  className="mr-medium"
                  icon="check-circle"
                  onClick={this._openTeamMemberPayApprovalModal}
                  disabled={isSleepoverError || isDisturbanceError}
                >
                  Approve team member payment
                </PrimaryButton>
              ) : selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.SENT_TO_FINANCE ? (
                <SecondaryButton
                  size="large"
                  className="mr-medium"
                  icon="close-circle"
                  onClick={this._openTeamMemberPayApprovalModal}
                >
                  Revoke team member payment
                </SecondaryButton>
              ) : null)}
            {pinc912CancellationFlowEnhancements ? (
              <SecondaryButton
                size="large"
                className="mr-medium"
                disabled={!this._isBeforeCustomerScheduledArchiveDate()}
                icon="check-circle"
                onClick={() => this.openActionModal({ action: 'REINSTATE_BOOKING' })}
              >
                Reinstate booking
              </SecondaryButton>
            ) : (
              <Popover2
                content={this._getCancellationMenu(false)}
                popoverClassName={'mb-medium'}
                position={'bottom'}
                interactionKind="click"
              >
                <SecondaryButton size="large" className="mr-medium" icon={'ellipsis'} iconPosition={'right'}>
                  Cancellation actions
                </SecondaryButton>
              </Popover2>
            )}
            {archiveBookingMenu}
          </div>
        );
      case 'CUSTOMER_CANCELLED':
        return (
          <div className="flex-row flex-wrap">
            <PrimaryButton
              size="large"
              className="mr-medium"
              icon="check-circle"
              onClick={() => this.openActionModal({ action: 'CHARGE_CANCELLATION_FEE' })}
            >
              Charge cancellation fee
            </PrimaryButton>
            <SecondaryButton
              size="large"
              className="mr-medium"
              icon="close-circle"
              onClick={() => this.openActionModal({ action: 'NO_CANCELLATION_FEE' })}
            >
              Do not charge cancellation fee
            </SecondaryButton>
            {archiveBookingMenu}
          </div>
        );
      case 'REJECTED':
        return <div className="flex-row flex-wrap">{archiveBookingMenu}</div>;
      default:
        return '';
    }
  };

  private _renderDuration = (startDateTime: Moment, endDateTime: Moment) => {
    const duration = CommonUtils.formatDuration(startDateTime, endDateTime);
    const durationDisplays = [];

    let hours = duration.hours?.value || 0;
    if (duration.days) {
      hours += duration.days.value * 24;
    }

    if (hours > 0) {
      durationDisplays.push(
        <>
          <b>{hours}</b> {`${hours > 1 ? 'hours' : 'hour'} `}
        </>,
      );
    }

    if (duration.minutes) {
      durationDisplays.push(
        <>
          <b>{duration.minutes.value}</b> {duration.minutes.label}
        </>,
      );
    }
    return <Text color="secondary">{durationDisplays}</Text>;
  };

  private _renderSleepOverContent = (displayTimezone) => {
    const { sleepoverType, sleepoverTimeSlots } = this.props.selectedBookingItem;
    let errorType = '';

    return (
      <>
        <div className="mb-small">
          <Text className={sleepoverType === SleepoverType.PARTIAL ? 'mb-x-small block' : ''}>
            <Text weight="bold">
              {sleepoverType === SleepoverType.NONE
                ? 'No'
                : sleepoverType === SleepoverType.ENTIRE
                ? 'Full sleepover'
                : sleepoverType === SleepoverType.PARTIAL
                ? 'Partial sleepover'
                : ''}
            </Text>{' '}
            {sleepoverType === SleepoverType.NONE
              ? 'sleepover'
              : sleepoverType === SleepoverType.ENTIRE
              ? '- this booking is a sleepover for the entire duration'
              : sleepoverType === SleepoverType.PARTIAL
              ? '- this booking involves a sleepover, for the following time periods:'
              : ''}
          </Text>

          {sleepoverType === SleepoverType.PARTIAL && sleepoverTimeSlots ? (
            <div className="mb-small">
              {_.map(sleepoverTimeSlots, (timeslot, index) => {
                if (timeslot.errorType?.length > 0) {
                  errorType = timeslot.errorType;
                }
                return (
                  <div key={index} className="mb-x-small align-center flex">
                    <Text>
                      {moment.tz(timeslot.startDateTime, displayTimezone).format('dddd, DD MMM YYYY')}
                      <Text className="text-color-secondary ml-small">
                        {moment.tz(timeslot.startDateTime, displayTimezone).format('LT')}
                      </Text>
                    </Text>
                    <Text className="mh-x-small">-</Text>
                    <Text>
                      {moment.tz(timeslot.endDateTime, displayTimezone).format('dddd, DD MMM YYYY')}
                      <Text className="text-color-secondary ml-small">
                        {moment.tz(timeslot.endDateTime, displayTimezone).format('LT')}
                      </Text>
                    </Text>
                    {timeslot.errorType && (
                      <>
                        <Icon
                          type="close-circle"
                          theme="filled"
                          className="text-size-x2-large text-color-red-light ml-small"
                        />
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        {errorType.length > 0 && (
          <div className="mb-small">
            <Text color="red">
              {errorType === SleepoverTimeSlotErrorType.OUT_OF_BOOKING_TIME
                ? `Sleepover must occur within booking time frame`
                : `Sleepover period must fall within team member's start and finish time`}
            </Text>
          </div>
        )}
      </>
    );
  };

  private _onSubmitAssign = async ({
    selectedWorker,
    editRecurringMode,
    shiftSlotStatus,
    isRemovePendingShiftSlots,
    bookingOutSideCanBeAssign = [],
    ignoreShiftClashShiftIds,
  }) => {
    const { doAssignWorker, selectedBookingItem } = this.props;
    const outsideAvailabilityBookingId = _.map(
      bookingOutSideCanBeAssign,
      (booking) => booking.supportWorkerAttendanceId,
    );
    const data = {
      bookingId: selectedBookingItem.bookingId,
      bookingRequestId: selectedBookingItem.bookingRequestId,
      supportWorkerId: selectedWorker.supportWorkerId,
      startDateTime: selectedBookingItem.startDateTime,
      isRecurring: selectedBookingItem.isRecurring,
      shiftSlotStatus,
      isRemovePendingShiftSlots,
      bookingOutSideCanBeAssign: outsideAvailabilityBookingId,
      editRecurringMode,
      bookingType: selectedBookingItem.bookingType,
      ignoreShiftClashShiftIds,
    };

    const result = await doAssignWorker(data);
    return {
      assignedShiftsCount: _.get(result, 'data.assignedShiftsCount', 1),
      currentShiftStatus: result.data.shiftSlotStatus,
    };
  };

  private _saveBookingAddress = (
    location,
    editRecurringMode,
    numberOfBookings,
    conflictSupportWorkerAttendanceIds = null,
  ) => {
    const { doUpdateBookingLocation, selectedBookingItem } = this.props;

    try {
      doUpdateBookingLocation({
        bookingId: selectedBookingItem.bookingId,
        bookingRequestId: selectedBookingItem.bookingRequestId,
        address: location,
        editRecurringMode,
        numberOfBookings,
        conflictSupportWorkerAttendanceIds,
        bookingType: selectedBookingItem.bookingType,
      });
      this.setState({ recurringEditOptions: { editRecurringMode, numberOfBookings } });
      this._closeEditAddressModal();
      this._openLocationConfirmationModal();
    } catch (e) {
      this._closeEditAddressModal();
      notification.error({ message: 'Oops, something went wrong, please try again!' });
    }
  };

  private _closeEditAddressModal = () => {
    this.setState({ openEditAddress: false });
  };

  private _openEditAddressModal = () => {
    this.setState({ openEditAddress: true });
  };

  private _bookingErrors = () => {
    const { selectedBookingItem } = this.props;

    const bookingErrorTypes = _.map(selectedBookingItem.bookingErrors, (error) => {
      return error.bookingErrorType;
    });

    const isBothServiceAgreementWarningsExist =
      _.includes(bookingErrorTypes, BookingErrorType.ServiceAgreementWarning) &&
      _.includes(bookingErrorTypes, BookingErrorType.ServiceAgreementLineItemWarning)
        ? true
        : false;

    let bookingErrors = _.clone(selectedBookingItem.bookingErrors);
    if (isBothServiceAgreementWarningsExist) {
      bookingErrors = _.remove(bookingErrors, (error: any) => {
        return (
          error.bookingErrorType !== BookingErrorType.ServiceAgreementLineItemWarning ||
          error.bookingErrorType !== BookingErrorType.SleepoverConflictBookingTime ||
          error.bookingErrorType !== BookingErrorType.SleepoverConflictCheckinoutTime
        );
      });
    }

    return bookingErrors;
  };

  private _isBeforeCustomerScheduledArchiveDate = () => {
    const { customerScheduleArchiveDate, endDateTime } = this.props.selectedBookingItem;
    if (customerScheduleArchiveDate) {
      return moment(endDateTime).isBefore(customerScheduleArchiveDate);
    } else {
      return true;
    }
  };

  componentDidMount() {
    if (!Utils.isEmpty(this.props.selectedBookingItem.instructions)) {
      const { bookingInstructionElement } = this;

      const hasTextOverflown =
        bookingInstructionElement.offsetHeight < bookingInstructionElement.scrollHeight ||
        bookingInstructionElement.offsetWidth < bookingInstructionElement.scrollWidth;

      this.setState({
        hasTextOverflown,
        textOffsetHeight: bookingInstructionElement.offsetHeight,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedBookingItem.instructions !== this.props.selectedBookingItem.instructions) {
      const { bookingInstructionElement } = this;
      if (!Utils.isEmpty(this.props.selectedBookingItem.instructions)) {
        const textOffsetHeight =
          this.state.textOffsetHeight === 0 ? bookingInstructionElement.offsetHeight : this.state.textOffsetHeight;

        const hasTextOverflown =
          textOffsetHeight < bookingInstructionElement.scrollHeight ||
          bookingInstructionElement.offsetWidth < bookingInstructionElement.scrollWidth;

        this.setState({
          hasTextOverflown,
          readMore: false,
          textOffsetHeight,
        });
      }
    }
  }

  render() {
    // default with empty react node.
    const TargetActionModal: any = this.getActionModal();
    const { selectedBookingItem, navigateTab, timezone, userTimezone, portalUser, isBookingArchived } = this.props;
    const { pinc912CancellationFlowEnhancements } = this.props.flags;
    const bookingErrors = this._bookingErrors();

    const displayTimezone = timezone ? timezone : selectedBookingItem.timezone;
    const isBookingCancelled = this._isBookingCancelled(selectedBookingItem);
    const isHideEditWorkerButton =
      [
        PaymentStatus.WAIVED,
        PaymentStatus.PAID,
        PaymentStatus.PROCESSED,
        PaymentStatus.BILLING_FAILED,
        PaymentStatus.SEND_TO_FINANCE,
      ].includes(selectedBookingItem.paymentStatus) &&
      selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITH_FEE;

    // Permissions
    const hasEditWorkerPermissions = PermissionUtils.validatePermission(
      'EditWorkerInBooking',
      portalUser.permissions.permissionRoles,
      selectedBookingItem.serviceDepartmentId,
      selectedBookingItem.serviceId,
    );

    const editWorkerPayHoursForCancelledBooking = PermissionUtils.validatePermission(
      'EditCancelledBookingTeamMemberPayHours',
      portalUser.permissions.permissionRoles,
      selectedBookingItem.serviceDepartmentId,
      selectedBookingItem.serviceId,
    );

    const workerPaidHours =
      (selectedBookingItem.portalCheckedInDateTime &&
        selectedBookingItem.portalCheckedOutDateTime &&
        selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID) ||
      selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.SENT_TO_FINANCE
        ? this._renderDuration(
            moment(selectedBookingItem.portalCheckedInDateTime),
            moment(selectedBookingItem.portalCheckedOutDateTime),
          )
        : 0;

    const shiftHours = this._renderDuration(
      moment(selectedBookingItem.shiftStartTime),
      moment(selectedBookingItem.shiftEndTime),
    );

    const paidStaffHours = workerPaidHours !== 0 ? workerPaidHours : shiftHours;

    const isShowEditSleepOverButton =
      _.includes(
        [
          BookingStatus.ACCEPTED,
          BookingStatus.CONFIRMED,
          BookingStatus.INPROGRESS,
          BookingStatus.BUSINESS_CANCELLED,
          BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE,
        ],
        selectedBookingItem.status,
      ) ||
      (selectedBookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL &&
        selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITH_FEE) ||
      (selectedBookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL &&
        selectedBookingItem.status === BookingStatus.COMPLETED);

    const isEnableEditCheckInCheckOutTime =
      selectedBookingItem.workerCheckedInDateTime &&
      !isBookingArchived &&
      _.includes([PaymentStatus.INITIAL, PaymentStatus.REQUIRES_APPROVAL], selectedBookingItem.paymentStatus);

    const isEnabledEditScheduledTime =
      !isBookingCancelled &&
      !isBookingArchived &&
      _.includes([PaymentStatus.INITIAL, PaymentStatus.REQUIRES_APPROVAL], selectedBookingItem.paymentStatus) &&
      PermissionUtils.validatePermission(
        'EditBookingTime',
        portalUser.permissions.permissionRoles,
        selectedBookingItem.serviceDepartmentId,
        selectedBookingItem.serviceId,
      );

    const { serviceId, serviceName } = selectedBookingItem;

    const customerState = {
      origin: MessageOrigin.BookingDetailsCustomer,
      userId: selectedBookingItem.bookerUserId,
      serviceId,
      serviceName,
      userName: `${selectedBookingItem.firstName} ${selectedBookingItem.lastName}`,
      avatarUrl: selectedBookingItem.attachmentUrl,
    };

    const workerState = {
      origin: MessageOrigin.BookingDetailsWorker,
      userId: selectedBookingItem.workerId,
      serviceId,
      serviceName,
      avatarUrl: selectedBookingItem.workerAttachmentUrl,
      userName: `${selectedBookingItem.workerFirstName} ${selectedBookingItem.workerLastName}`,
    };

    const cancelledBy = selectedBookingItem.status === BookingStatus.BUSINESS_CANCELLED ? 'Business' : 'Customer';

    const paidHours =
      selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID ||
      selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.SENT_TO_FINANCE ? (
        selectedBookingItem.workerId ? (
          selectedBookingItem.endDateTime === selectedBookingItem.portalCheckedOutDateTime ? (
            <Text color="secondary">
              Pay the team member for entire shift <b>{paidStaffHours}</b>
            </Text>
          ) : (
            <Text color="secondary">
              Pay the team member for <b>{paidStaffHours}</b>
            </Text>
          )
        ) : (
          <Text color="secondary">
            <b>Do not pay</b> team member
          </Text>
        )
      ) : selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.CANCELLED_UNPAID ? (
        <Text color="secondary">
          <b>Do not pay</b> team member
        </Text>
      ) : (
        ''
      );

    return (
      <div className="anim-fade-in">
        {/* Header */}
        {/*<TopHeaderText text={'booking overview'} />*/}
        <div className="align-end flex-row justify-between">
          <Title level={3} color="secondary">
            Booking / <span className="text-color-black">{selectedBookingItem.referenceId}</span>
          </Title>
          <div className="text-align-right">
            <Text className="mr-small" size="small" color="secondary">
              Timezone display
            </Text>
            <br />
            <TimezoneSelector
              bordered={false}
              mode={this.props.mode}
              userTimezone={this.props.userTimezone}
              customTimezone={this.props.bookingDisplayTzCustom}
              onChange={this.props.onChangeTimezone}
            />
          </div>
        </div>
        <TargetActionModal
          isOpen={this.state.openAction}
          onClose={this.closeActionModal}
          navigateTab={navigateTab}
          displayTimezone={displayTimezone}
          serviceType={'SUPPORT'}
          userName={selectedBookingItem.firstName + ' ' + selectedBookingItem.lastName}
        />
        {this.state.openPublishSettings && (
          <PublishBookingSettingsModal
            isOpen={this.state.openPublishSettings}
            onClose={this._closePublishSettingsModal}
            selectedBookingItem={selectedBookingItem}
          />
        )}
        {this.state.openAssignWorker && (
          <BookingAssignWorkerModal
            isOpen={this.state.openAssignWorker}
            onClose={this._closeAssignWorkerModal}
            serviceId={selectedBookingItem.serviceId}
            bookingId={selectedBookingItem.bookingId}
            bookingRequestId={selectedBookingItem.bookingRequestId}
            onSubmitAssign={this._onSubmitAssign}
            isRecurring={selectedBookingItem.isRecurring}
            address={selectedBookingItem.address}
            sessions={[]}
            isEditingBooking={true}
            startDateTime={selectedBookingItem.startDateTime}
            endDateTime={selectedBookingItem.endDateTime}
            customerUserIds={[selectedBookingItem.bookerUserId]}
            displayTimezone={displayTimezone}
            bookingType={selectedBookingItem.bookingType}
            isBookingCancelled={isBookingCancelled}
          />
        )}
        {selectedBookingItem.isRecurring && this.state.isRecurringListModalOpen && (
          <RecurringBookingListModal
            isOpen={this.state.isRecurringListModalOpen}
            bookingItem={selectedBookingItem}
            displayTimezone={displayTimezone}
            closeRecurringBookingListModal={this._closeRecurringListModal}
          />
        )}
        {selectedBookingItem.serviceDirection !== ServiceLocationType.ONLINE && (
          <>
            <AddAddressModal
              closeCreateEditAddressModal={() => {
                this._closeEditAddressModal();
              }}
              isOpen={this.state.openEditAddress}
              address={selectedBookingItem.address}
              saveCustomerAddress={this._saveBookingAddress}
              isRecurring={selectedBookingItem.isRecurring}
              isEditBookingAddress={true}
              numberOfBookingLeft={selectedBookingItem.numberOfBookingLeft}
              bookingId={selectedBookingItem.bookingId}
              bookingRequestId={selectedBookingItem.bookingRequestId}
              timezone={selectedBookingItem.timezone}
              title={
                <>
                  Booking <b>Location</b>
                </>
              }
              subTitle={
                'Type the address you want to change the activity record location to and we will automatically search for it.'
              }
            />
          </>
        )}
        {!pinc912CancellationFlowEnhancements
          ? this.state.isBookingPayTeamMemberModalOpen && (
              <BookingUpdateTeamMemberPayHoursModal
                isOpen={this.state.isBookingPayTeamMemberModalOpen}
                onClose={this._closeTeamMemberPayModal}
                isNextStep={true}
              />
            )
          : ''}
        {this.state.isBookingApproveTeamMemberPayModalOpen && (
          <BookingApproveTeamMemberPayForCancelledBooking
            isOpen={this.state.isBookingApproveTeamMemberPayModalOpen}
            onClose={this._closeTeamMemberPayApprovalModal}
            isApproved={selectedBookingItem.shiftSlotStatus === 'SENT_TO_FINANCE' ? true : false}
            selectedBooking={selectedBookingItem}
          />
        )}
        <ActionModal
          isOpen={this.state.openLocationConfirmationModal}
          onClose={this._closeLocationConfirmationModal}
          title={'Location changed'}
        >
          <div className="anim-fade-in">
            <div className="text-align-left">
              <Paragraph>
                {selectedBookingItem.isRecurring &&
                this.state.recurringEditOptions.selectedOption === EditRecurringMode.CurrentNext
                  ? `The location has been successfully changed for the selected booking and the next ${
                      this.state.recurringEditOptions.numberOfBookings
                    } booking${this.state.recurringEditOptions.numberOfBookings !== 1 ? 's' : ''}`
                  : selectedBookingItem.isRecurring &&
                    this.state.recurringEditOptions.selectedOption === EditRecurringMode.CurrentAll
                  ? 'The location has been successfully changed for this selected booking and all following bookings.'
                  : 'The location has been successfully changed for the selected booking.'}
              </Paragraph>
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._closeLocationConfirmationModal}>
                OK
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        </ActionModal>
        {this.renderStatusBar()}
        <div>
          <div className="align-start mv-x2-large flex-row justify-start">
            <div className="mr-x-large">
              <Avatar icon="user" className="avatar-x2-large" src={selectedBookingItem.attachmentUrl} />
            </div>
            <div className="p-small flex-1">
              <Row gutter={24} className="mb-medium justify-end">
                <Col span={5}>
                  <SubTitle color="tertiary" weight="black">
                    Customer
                  </SubTitle>
                </Col>
                <Col span={19}>
                  {PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles) ? (
                    <Link to={`/customer/details/${selectedBookingItem.bookerUserId}`}>
                      <HyperlinkButton>
                        {selectedBookingItem.firstName + ' ' + selectedBookingItem.lastName}
                      </HyperlinkButton>
                    </Link>
                  ) : (
                    selectedBookingItem.firstName + ' ' + selectedBookingItem.lastName
                  )}
                  <br />

                  {selectedBookingItem.pinnedAlertNumber > 0 && (
                    <PinnedAlertItem
                      pinnedAlertNumber={selectedBookingItem.pinnedAlertNumber}
                      customerUserId={selectedBookingItem.bookerUserId}
                      isViewOnly={true}
                    />
                  )}

                  {/* Added by Jir : Message Customer */}
                  <Link
                    to={{
                      pathname: '/messaging',
                      state: customerState,
                    }}
                  >
                    <SecondaryButton size={'small'} icon={'message'} className="mt-x-small">
                      Message Customer
                    </SecondaryButton>
                  </Link>

                  {/* <Text size="regular" color="secondary">
                    {this.getCustomerContactList()}
                  </Text> */}
                </Col>
              </Row>
              <Row gutter={24} className="mb-medium justify-end">
                <Col span={5}>
                  <SubTitle color="tertiary" weight="black">
                    Service
                  </SubTitle>
                </Col>
                <Col span={19}>
                  <Text className="">{selectedBookingItem.serviceName ?? ''}</Text>
                </Col>
              </Row>
              <Row gutter={24} className="mb-medium justify-end">
                <Col span={5} className="justify-end">
                  <SubTitle color="tertiary" weight="black">
                    Team member
                  </SubTitle>
                </Col>
                <Col span={19}>
                  {selectedBookingItem.workerUserId && (
                    <>
                      {selectedBookingItem.shiftSlotStatus && (
                        <div className="mb-small">
                          <WorkerStatusTag shiftSlotStatus={selectedBookingItem.shiftSlotStatus} />
                        </div>
                      )}
                      <Row type="flex" align="middle">
                        <Col span={2} style={{ minWidth: '25px' }}>
                          <Avatar
                            icon="user"
                            className="avatar-medium mr-small"
                            shape="square"
                            src={selectedBookingItem.workerAttachmentUrl}
                          />
                        </Col>
                        <Col span={6}>
                          {PermissionUtils.validatePermission(
                            'ViewTeamListing',
                            portalUser.permissions.permissionRoles,
                          ) ? (
                            <Link to={`/team/details/${selectedBookingItem.workerId}`}>
                              <HyperlinkButton color="black" className="text-weight-bold ml-small">
                                {`${selectedBookingItem.workerFirstName} ${selectedBookingItem.workerLastName}`}
                              </HyperlinkButton>
                            </Link>
                          ) : (
                            <Text>{`${selectedBookingItem.workerFirstName} ${selectedBookingItem.workerLastName}`}</Text>
                          )}
                        </Col>
                        <Col className="flex flex-wrap justify-start">
                          {!isBookingArchived &&
                            selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.PENDING &&
                            !isBookingCancelled &&
                            hasEditWorkerPermissions && (
                              <HyperlinkButton
                                className="ml-small mr-large"
                                onClick={() => this.openActionModal({ action: 'CONFIRM_SHIFT' })}
                              >
                                <Icon type="check" /> Confirm Shift on behalf of worker
                              </HyperlinkButton>
                            )}
                          {!isBookingArchived &&
                            (selectedBookingItem.status === BookingStatus.PENDING ||
                              selectedBookingItem.status === BookingStatus.ACCEPTED ||
                              selectedBookingItem.status === BookingStatus.CONFIRMED ||
                              isBookingCancelled) &&
                            !isHideEditWorkerButton &&
                            hasEditWorkerPermissions && (
                              <HyperlinkButton
                                className="ml-small"
                                color="red"
                                onClick={() => this.openActionModal({ action: 'REMOVE_WORKER' })}
                              >
                                <Icon type="user-delete" className="text-color-black" /> Remove team member
                              </HyperlinkButton>
                            )}
                        </Col>
                      </Row>

                      {selectedBookingItem.workerUserId &&
                        (selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID ||
                          selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.CANCELLED_UNPAID ||
                          selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.SENT_TO_FINANCE) &&
                        selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE &&
                        editWorkerPayHoursForCancelledBooking && (
                          <div>
                            <Text>
                              Team member{' '}
                              {selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID ||
                              selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.SENT_TO_FINANCE ? (
                                <> to be paid {workerPaidHours}</>
                              ) : (
                                `will not be paid`
                              )}{' '}
                              for this booking.
                            </Text>
                            {selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID ? <br /> : null}
                            {selectedBookingItem.shiftSlotStatus !== ShiftSlotStatus.SENT_TO_FINANCE ? (
                              <HyperlinkButton color="blue-action" onClick={this._openTeamMemberPayModal}>
                                {selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID ? (
                                  <Icon type="edit" />
                                ) : null}
                                {selectedBookingItem.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID ? (
                                  `Edit`
                                ) : (
                                  <span className={'ml-small'}>Pay team member...</span>
                                )}
                              </HyperlinkButton>
                            ) : null}
                          </div>
                        )}

                      <Link
                        to={{
                          pathname: '/messaging',
                          state: workerState,
                        }}
                      >
                        <SecondaryButton size={'small'} icon={'message'} className="mt-small">
                          Message Team Member
                        </SecondaryButton>
                      </Link>
                    </>
                  )}
                  {!selectedBookingItem.workerUserId && !selectedBookingItem.isShiftPublished && (
                    <>
                      <div>
                        <Icon type="warning" className="text-color-warning-orange mr-x-small" theme={'filled'} />{' '}
                        <b>No Team member selected</b>
                      </div>
                      {hasEditWorkerPermissions && (
                        <div className="text-size-regular mt-x-small">
                          {!isBookingArchived && !isHideEditWorkerButton && (
                            <HyperlinkButton onClick={this._openAssignWorkerModal} fontSize="regular">
                              Select Team member
                            </HyperlinkButton>
                          )}{' '}
                          {!isBookingArchived && !isBookingCancelled && (
                            <>
                              or{' '}
                              <HyperlinkButton onClick={this._openPublishSettingsModal} fontSize="regular">
                                Publish to Team members
                              </HyperlinkButton>
                            </>
                          )}
                          {selectedBookingItem.unsuccessfulApplicants.length > 0 && !isBookingArchived && (
                            <>
                              {' '}
                              or{' '}
                              <HyperlinkButton
                                onClick={() => this.openActionModal({ action: 'VIEW_APPLICANTS' })}
                                fontSize="regular"
                              >
                                View previous applicants ({selectedBookingItem.unsuccessfulApplicants.length})
                              </HyperlinkButton>
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {selectedBookingItem.isShiftPublished && (
                    <>
                      <div>
                        <Icon type="wifi" className="text-color-violet-light mr-x-small" /> Published to Worker on{' '}
                        {moment(selectedBookingItem.publishShiftDate).format('ddd D MMMM Y')}
                      </div>
                      <div className="text-size-regular">
                        <Text
                          size="regular"
                          color={selectedBookingItem.activeApplicants.length === 0 ? 'secondary' : 'black'}
                          weight={selectedBookingItem.activeApplicants.length === 0 ? 'regular' : 'bold'}
                          className="mr-x2-large"
                        >
                          <Text size="x2-large">{selectedBookingItem.activeApplicants.length}</Text>{' '}
                          <Text>
                            applicant
                            {selectedBookingItem.activeApplicants.length !== 1 && 's'}
                            {selectedBookingItem.activeApplicants.length > 0 && '!'}
                          </Text>
                          {selectedBookingItem.unsuccessfulApplicants.length >= 1 && (
                            <Text weight="regular" className="ml-small">
                              (+{selectedBookingItem.unsuccessfulApplicants.length} unsuccessful)
                            </Text>
                          )}
                        </Text>
                        {(selectedBookingItem.activeApplicants.length > 0 ||
                          selectedBookingItem.unsuccessfulApplicants.length > 0) &&
                          hasEditWorkerPermissions && (
                            <HyperlinkButton
                              className="mr-x-large"
                              onClick={() => this.openActionModal({ action: 'VIEW_APPLICANTS' })}
                            >
                              <Icon type="team" className="mr-x-small text-color-secondary" />
                              View applicants
                            </HyperlinkButton>
                          )}
                        {hasEditWorkerPermissions && !isBookingArchived && (
                          <HyperlinkButton onClick={this._openPublishSettingsModal}>
                            <Icon type="setting" className="mr-x-small text-color-secondary" />
                            Settings
                          </HyperlinkButton>
                        )}
                      </div>
                    </>
                  )}
                </Col>
              </Row>
              <Row gutter={24} className="mb-medium justify-end">
                <Col span={5} className="justify-end">
                  <SubTitle color="tertiary" weight="black">
                    When
                  </SubTitle>
                </Col>
                <div ref={this.props.sleepoverViewRef} />
                <Col span={19}>
                  <Row>
                    <SubTitle color="tertiary" weight="black">
                      Booking Time
                    </SubTitle>
                    {moment
                      .tz(selectedBookingItem.startDateTime, displayTimezone)
                      .isSame(moment.tz(selectedBookingItem.endDateTime, displayTimezone), 'day') ? (
                      <>
                        <div className="mb-x-small">
                          <Text weight="bold">
                            {moment.tz(selectedBookingItem.startDateTime, displayTimezone).format('dddd, DD MMM YYYY')}
                          </Text>
                        </div>
                        <div className="mb-x-small">
                          <Text className="text-color-secondary">
                            {moment.tz(selectedBookingItem.startDateTime, displayTimezone).format('LT')} -{' '}
                            {moment.tz(selectedBookingItem.endDateTime, displayTimezone).format('LT')}
                            &nbsp;(
                            {this._renderDuration(
                              moment.tz(selectedBookingItem.startDateTime, displayTimezone),
                              moment.tz(selectedBookingItem.endDateTime, displayTimezone),
                            )}
                            )
                          </Text>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mb-x-small">
                          <Text>
                            {moment.tz(selectedBookingItem.startDateTime, displayTimezone).format('dddd, DD MMM YYYY')}
                            <Text className="text-color-secondary ml-small">
                              {moment.tz(selectedBookingItem.startDateTime, displayTimezone).format('LT')}
                            </Text>
                          </Text>
                        </div>
                        <Text className="mb-x-small block">
                          {moment.tz(selectedBookingItem.endDateTime, displayTimezone).format('dddd, DD MMM YYYY')}
                          <Text className="text-color-secondary ml-small">
                            {moment.tz(selectedBookingItem.endDateTime, displayTimezone).format('LT')}
                          </Text>
                        </Text>
                        <div className="mb-small">
                          <Text className="text-color-secondary">
                            {this._renderDuration(
                              moment.tz(selectedBookingItem.startDateTime, displayTimezone),
                              moment.tz(selectedBookingItem.endDateTime, displayTimezone),
                            )}
                          </Text>
                        </div>
                      </>
                    )}
                    {!timezone && moment.tz(userTimezone).format('Z') !== moment.tz(displayTimezone).format('Z') && (
                      <TimezoneIndicator
                        bordered={true}
                        hasIcon={true}
                        showTzName={false}
                        timezone={selectedBookingItem.timezone}
                      />
                    )}
                    {isEnabledEditScheduledTime && (
                      <HyperlinkButton
                        color="blue-action"
                        onClick={() => this.openActionModal({ action: 'EDIT_BOOKING_DATES' })}
                      >
                        <Icon type="edit" /> Edit
                      </HyperlinkButton>
                    )}
                  </Row>

                  {selectedBookingItem.sleepoverType && (
                    <Row className="mt-small pt-small bordered-top">
                      <SubTitle color="tertiary" weight="black">
                        Sleepover
                      </SubTitle>
                      {this._renderSleepOverContent(displayTimezone)}
                      {isShowEditSleepOverButton && (
                        <HyperlinkButton color="blue-action" onClick={this.props.openSleepoverShiftModal}>
                          <Icon type="edit" /> Edit
                        </HyperlinkButton>
                      )}
                    </Row>
                  )}

                  {(selectedBookingItem.workerCheckedInDateTime || selectedBookingItem.workerCheckedOutDateTime) && (
                    <Row className="pt-small mt-small bordered-top">
                      {selectedBookingItem.workerCheckedInDateTime && (
                        <div className="mb-small">
                          <SubTitle color="tertiary" weight="black">
                            Check in
                          </SubTitle>
                          <Text>
                            {selectedBookingItem.portalCheckedInDateTime
                              ? moment
                                  .tz(selectedBookingItem.portalCheckedInDateTime, displayTimezone)
                                  .format('dddd, DD MMM YYYY')
                              : moment
                                  .tz(selectedBookingItem.workerCheckedInDateTime, displayTimezone)
                                  .format('dddd, DD MMM YYYY')}
                            <Text className="text-color-secondary ml-small">
                              {selectedBookingItem.portalCheckedInDateTime
                                ? moment.tz(selectedBookingItem.portalCheckedInDateTime, displayTimezone).format('LT')
                                : moment.tz(selectedBookingItem.workerCheckedInDateTime, displayTimezone).format('LT')}
                            </Text>
                          </Text>
                        </div>
                      )}
                      {selectedBookingItem.workerCheckedOutDateTime && (
                        <div className="mb-small">
                          <SubTitle color="tertiary" weight="black">
                            Check out
                          </SubTitle>
                          <Text>
                            {selectedBookingItem.portalCheckedOutDateTime
                              ? moment
                                  .tz(selectedBookingItem.portalCheckedOutDateTime, displayTimezone)
                                  .format('dddd, DD MMM YYYY')
                              : moment
                                  .tz(selectedBookingItem.workerCheckedOutDateTime, displayTimezone)
                                  .format('dddd, DD MMM YYYY')}
                            <Text className="text-color-secondary ml-small">
                              {selectedBookingItem.portalCheckedOutDateTime
                                ? moment.tz(selectedBookingItem.portalCheckedOutDateTime, displayTimezone).format('LT')
                                : moment.tz(selectedBookingItem.workerCheckedOutDateTime, displayTimezone).format('LT')}
                            </Text>
                          </Text>
                        </div>
                      )}
                      {isEnableEditCheckInCheckOutTime && (
                        <HyperlinkButton
                          color="blue-action"
                          onClick={() => this.openActionModal({ action: 'EDIT_WORKER_CHECKINOUT_DATES' })}
                        >
                          <Icon type="edit" /> Edit
                        </HyperlinkButton>
                      )}
                    </Row>
                  )}
                  {selectedBookingItem.isRecurring && (
                    <div className="mt-medium ph-medium pv-small bg-quaternary" style={{ display: 'table' }}>
                      <Icon type="history" className="mr-x-small text-color-secondary" /> This booking is part of a
                      recurring series.
                      <br />
                      <Text size={'regular'} color={'secondary'} className="ml-large">
                        {selectedBookingItem.numberOfBookingLeft === 0
                          ? 'This is the last booking of this recurring series.'
                          : selectedBookingItem.numberOfBookingLeft +
                            ` booking${
                              selectedBookingItem.numberOfBookingLeft !== 1 ? 's' : ''
                            } remaining after this booking.`}
                      </Text>
                      <br />
                      <HyperlinkButton onClick={this._openRecurringListModal} className="ml-large" fontSize="regular">
                        View all bookings in this series.
                      </HyperlinkButton>
                    </div>
                  )}
                </Col>
              </Row>
              <Row gutter={24} className="mb-medium justify-end">
                <Col span={5} className="justify-end">
                  <SubTitle color="tertiary" weight="black">
                    Where
                  </SubTitle>
                </Col>
                <Col span={19}>
                  {selectedBookingItem.serviceDirection !== ServiceLocationType.ONLINE ? (
                    <>
                      <Text className="">
                        {selectedBookingItem.address ? selectedBookingItem.address.fullAddress : '-'}
                      </Text>
                      {(selectedBookingItem.status === BookingStatus.PENDING ||
                        selectedBookingItem.status === BookingStatus.ACCEPTED ||
                        selectedBookingItem.status === BookingStatus.CONFIRMED) &&
                        PermissionUtils.validatePermission(
                          'EditBookingLocation',
                          portalUser.permissions.permissionRoles,
                          selectedBookingItem.serviceDepartmentId,
                          selectedBookingItem.serviceId,
                        ) &&
                        !isBookingArchived && (
                          <>
                            <br />
                            <HyperlinkButton color="blue-action" onClick={this._openEditAddressModal}>
                              <Icon type="edit" /> Edit
                            </HyperlinkButton>
                          </>
                        )}
                    </>
                  ) : (
                    <Text>Online</Text>
                  )}
                  <br />
                </Col>
              </Row>
              <Row gutter={24} className="mb-medium justify-end">
                <Col span={5} className="justify-end">
                  <SubTitle color="tertiary" weight="black">
                    Total Amount
                  </SubTitle>
                </Col>
                <Col span={19}>
                  <Text className="text-weight-bold">{CommonUtils.formatPrice(selectedBookingItem.total)}</Text>
                  <br />
                </Col>
              </Row>

              <Row gutter={24} className="mb-medium justify-end" type="flex">
                <Col span={5} className="justify-end">
                  <SubTitle color="tertiary" weight="black">
                    Instructions
                  </SubTitle>
                </Col>
                <Col span={19}>
                  {selectedBookingItem.instructions ? (
                    <>
                      <div
                        ref={(ref) => (this.bookingInstructionElement = ref)}
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'pre-line',
                          height: this.state.readMore || !this.state.hasTextOverflown ? null : '2.4em',
                        }}
                        className="text-size-large line-height-120"
                      >
                        {selectedBookingItem.instructions}
                      </div>
                      {this.state.hasTextOverflown && (
                        <div
                          className="mt-small text-color-green cursor-pointer"
                          onClick={() => this.setState({ readMore: !this.state.readMore })}
                        >
                          {this.state.readMore ? 'Read less' : 'Read more'}
                        </div>
                      )}
                      <div className="mt-small">
                        <HyperlinkButton color="blue-action" onClick={this._onPressEditBookingInstruction}>
                          <Icon type="edit" /> Edit
                        </HyperlinkButton>
                        <br />
                        {selectedBookingItem.instructionUpdatedBy && (
                          <Text color="tertiary" size="regular">
                            Last edited by&nbsp;
                            <b>
                              {selectedBookingItem.instructionUpdatedByFirstName}{' '}
                              {selectedBookingItem.instructionUpdatedByLastName}
                            </b>
                            &nbsp; on &nbsp;
                            {moment(selectedBookingItem.instructionUpdatedOn).format('DD MMM YYYY, hh:mm A')}
                          </Text>
                        )}
                      </div>
                    </>
                  ) : (
                    <div>
                      <Text className="text-size-large line-height-120" color="secondary">
                        No instruction included. Click Edit to add instructions.
                      </Text>
                      <br />
                      {!isBookingArchived && (
                        <HyperlinkButton color="blue-action" onClick={this._onPressEditBookingInstruction}>
                          <Icon type="edit" /> Edit
                        </HyperlinkButton>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
              {selectedBookingItem.paymentStatus && selectedBookingItem.paymentStatus !== PaymentStatus.INITIAL && (
                <Row gutter={24} className="mb-medium" type="flex" align="middle">
                  <Col span={5}>
                    <SubTitle color="tertiary" weight="black">
                      Payment Status
                    </SubTitle>
                  </Col>
                  <Col span={19}>
                    <PaymentStatusTag paymentStatus={selectedBookingItem.paymentStatus} />
                  </Col>
                </Row>
              )}
              {selectedBookingItem.status === BookingStatus.REJECTED && (
                <Row gutter={24} className="mb-medium" type="flex" align="middle">
                  <Col span={5}>
                    <SubTitle color="red" weight="black">
                      Decline Reason
                    </SubTitle>
                  </Col>
                  <Col span={19}>
                    <Text className="text-weight-bold" color="red-dark">
                      {selectedBookingItem.rejectReason}
                    </Text>
                  </Col>
                </Row>
              )}
              {(selectedBookingItem.status === BookingStatus.BUSINESS_CANCELLED ||
                selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED ||
                selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITH_FEE ||
                selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE) && (
                <Row gutter={24} className="mb-medium justify-end">
                  <Col span={5} className="justify-end">
                    <SubTitle color="red" weight="black">
                      {pinc912CancellationFlowEnhancements ? (
                        <div> Cancellation Details</div>
                      ) : (
                        <div> Cancellation Reason</div>
                      )}
                    </SubTitle>
                  </Col>
                  <Col span={19}>
                    <Stack gap="$xxsmall">
                      <Text color="secondary">
                        Cancelled on behalf of <b>{cancelledBy?.toLowerCase()}</b>
                      </Text>
                      <Text color="secondary">
                        {selectedBookingItem.cancellationReason &&
                        (selectedBookingItem.cancellationCode === 'NSDO' ||
                          selectedBookingItem.status === BookingStatus.BUSINESS_CANCELLED)
                          ? selectedBookingItem.cancellationReason
                          : ''}
                        {selectedBookingItem.status !== BookingStatus.BUSINESS_CANCELLED &&
                          selectedBookingItem.cancellationCode !== 'NSDO' &&
                          (selectedBookingItem.cancellationCode ? (
                            <CancellationCodesTag code={selectedBookingItem.cancellationCode} />
                          ) : (
                            'No Code.'
                          ))}{' '}
                      </Text>
                      <Text color="secondary">
                        {selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITH_FEE ? (
                          <b> Charge</b>
                        ) : (
                          <b>Do not charge</b>
                        )}{' '}
                        a cancellation fee
                      </Text>
                      <Text color="secondary">{paidHours}</Text>

                      {!isBookingArchived && (
                        <HyperlinkButton
                          color="blue-action"
                          onClick={() => this.openActionModal({ action: 'EDIT_CANCELLATION_REASON' })}
                        >
                          <Icon type="edit" /> Edit
                        </HyperlinkButton>
                      )}
                    </Stack>
                  </Col>
                </Row>
              )}
              {!_.isEmpty(bookingErrors) && (
                <Row gutter={24} className="mb-medium" type="flex" align="middle">
                  <Col span={5} />
                  <Col span={19}>
                    {_.chain(bookingErrors)
                      .orderBy('bookingErrorType', 'asc')
                      .map((bookingError, index) => {
                        return (
                          <BookingWarning
                            key={index}
                            bookingError={bookingError}
                            selectedBookingItem={selectedBookingItem}
                            serviceType={'SUPPORT'}
                          />
                        );
                      })
                      .value()}
                  </Col>
                </Row>
              )}
            </div>
          </div>

          <div className="mb-x-large bg-quaternary p-large">{this.renderActionButtons()}</div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  selectedBooking: state.bookingsStore.selectedBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchPublishSettings: dispatch.bookingsStore.doFetchPublishSettings,
  doAssignWorker: dispatch.bookingsStore.doAssignWorker,
  doUpdateBookingLocation: dispatch.bookingsStore.doUpdateBookingLocation,
});

export const BookingDetailOverviewTabV2 = connect(
  mapState,
  mapDispatch,
)(withLDConsumer()(BookingDetailOverviewTabV2Class));
