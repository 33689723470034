import { forwardRef } from 'react';
import { Illustration } from './illustration';
import type { IllustrationProps } from './illustration';
import type { Ref } from 'react';
// biome-ignore lint/suspicious/noShadowRestrictedNames: this is an illustration component and shouldn't be confused
export const Error = forwardRef(function Error(props: IllustrationProps, ref: Ref<SVGSVGElement>) {
  return (
    <Illustration {...props} ref={ref}>
      <path
        d='M118.392 111.767c.937-.604 1.736-1.119 2.728-1.756-3.976-8.612-7.695-17.12-11.796-25.442C103.511 72.776 97.5 61.075 91.416 49.42c-3.905-7.482-7.338-15.175-10.844-22.844-1.962-4.287-4.186-8.453-6.334-12.65-.455-.886-1.059-1.701-1.66-2.5-.842-1.122-2.038-1.159-2.84-.09-.513.684-1.038 1.397-1.364 2.179-.81 1.936-2.228 3.293-3.888 4.451-2.221 1.554-4.445 3.101-6.676 4.64-1.885 1.298-3.75 2.628-4.949 4.65-3.207 5.41-6.536 10.75-9.56 16.263-4.951 9.023-9.72 18.147-14.526 27.25A5319.692 5319.692 0 0 0 14.25 98.496a564.442 564.442 0 0 0-6.57 13.024c-1.595 3.279-.85 4.789 2.606 5.598.69.162 1.407.313 2.112.303 7.83-.098 15.668.331 23.482-.585 1.974-.233 3.982-.214 5.977-.248 3.705-.061 7.412-.025 11.116-.116 7.125-.175 14.258-.779 21.368-.537 10.258.35 20.51.484 30.768.469 2.853-.003 5.706.092 8.556.086 1.135 0 2.288-.055 3.402-.27 1.358-.26 1.858-1.283 1.602-2.91-.086-.54-.192-1.073-.281-1.548l.003.004Zm-6.194-2.255c-4.717-.242-9.373.082-14.05.778-3.51.521-7.104.698-10.656.665-12.68-.12-25.358-.469-38.04-.589-8.265-.076-16.534.059-24.802.178-1.7.025-3.393.423-5.093.598-1.397.144-2.8.22-5 .386C33.653 78.306 52.284 45.894 71.07 13.209c.512.472 1.153.812 1.416 1.342 2.8 5.626 5.696 11.21 8.26 16.946C85.533 42.205 90.79 52.671 96.19 63.073c4.87 9.378 9.621 18.824 14.356 28.27 1.727 3.444 3.262 6.986 4.895 10.478 1.083 2.317 2.102 4.654 2.755 7.409-2.109.579-4.077.38-5.999.282Z'
        fill='currentColor'
      />
      <path
        d='M68.752 75.61c2.069.086 3.63-.876 4.565-2.622.442-.83.454-1.934.509-2.92.229-4.142.403-8.288.595-12.433h.037c.128-4.008.296-8.012.369-12.02.04-2.08-.998-3.079-3.128-3.244-1.354-.107-2.44.27-3.393 1.366-1.757 2.022-2.828 4.222-2.807 6.974.025 3.15-.128 6.296-.174 9.445-.055 3.867-.13 7.733-.097 11.597.021 2.626 1.14 3.76 3.53 3.857h-.006Zm.607-25.577c.052-1.816.555-3.633 1-5.416.092-.368.892-.846 1.28-.775.417.076.912.674 1.052 1.142.198.659.156 1.413.131 2.12-.262 7.415-.522 14.83-.839 22.244-.043.971-.265 2.007-.699 2.865-.204.407-1.095.465-1.928.778-.131-1.048-.296-1.734-.29-2.418.067-6.847.1-13.695.296-20.54h-.003ZM71.794 86.536c-.83-.147-1.697-.064-2.536-.156-2.181-.242-3.554.83-4.195 2.782-1.279 3.9-.247 7.13 4.452 7.711 1.309.163 2.71-.435 4.186-.704.622-1.903 1.178-3.22 1.477-4.596.528-2.445-.958-4.605-3.384-5.037Zm1.394 4.678c-.17 1.532-1.083 2.749-2.136 2.853-1.25.122-2.724-1.042-2.88-2.617-.091-.91.162-2.025.668-2.766.397-.582 1.395-.923 2.17-1.024.906-.12 1.815.852 2.047 1.863.095.414.125.84.165 1.115-.015.294-.015.435-.03.576h-.004Z'
        fill='currentColor'
      />
    </Illustration>
  );
});
