import type { LocaleConfig, CountryCode } from './types';

export const localeConfigs: Record<CountryCode, LocaleConfig> = {
  AU: {
    countryCode: 'AU',
    lang: 'en-AU',
    name: 'Australia',
    icon: '🇦🇺',
    phone: {
      label: '+61 (AU)',
      extension: 61,
    },
  },
  US: {
    countryCode: 'US',
    lang: 'en-US',
    name: 'United States',
    icon: '🇺🇸',
    phone: {
      label: '+1 (US)',
      extension: 1,
    },
  },
};
