import React from 'react';
import { CompatRoute as Route } from 'react-router-dom-v5-compat';

// Views
import RegisterView from 'views/non-auth/RegisterView';
import ResetPasswordView from '../views/non-auth/ResetPasswordView';

interface NonAuthRoutesProps {
  isAuthenticated: boolean;
}

// Non Authorized Routes
const NonAuthRoutes = (props: NonAuthRoutesProps): JSX.Element => (
  <>
    <Route path="/register" component={RegisterView} />
    <Route path="/reset" component={ResetPasswordView} />
  </>
);

export default NonAuthRoutes;
