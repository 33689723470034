import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const SlidersHoriz = forwardRef(function SlidersHoriz(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2.25a2.75 2.75 0 1 0 2.646 3.5H21a.75.75 0 0 0 0-1.5h-3.354a2.751 2.751 0 0 0-2.646-2ZM16.25 5a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0ZM3 4.25a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5H3Zm0 14a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5H3Zm12-2c1.259 0 2.32.846 2.646 2H21a.75.75 0 0 1 0 1.5h-3.354A2.751 2.751 0 1 1 15 16.25Zm0 4a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM2.25 12a.75.75 0 0 1 .75-.75h3.354a2.751 2.751 0 0 1 5.396.75 2.75 2.75 0 0 1-5.396.75H3a.75.75 0 0 1-.75-.75Zm11 0a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm-5.5 0a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
