import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';
import { Text } from 'common-components/typography';

interface ICannotEditOrDeleteNoteModalProps {
  isOpen: boolean;
  isEditingNote: boolean;
  onClose: () => void;
  onGoToSession: () => void;
}

class CannotEditOrDeleteNoteModal extends Component<ICannotEditOrDeleteNoteModalProps> {
  render() {
    const { isOpen, isEditingNote, onClose, onGoToSession } = this.props;
    const modalTitle = isEditingNote ? 'Cannot edit group booking note' : 'Cannot delete group booking note';

    return (
      <ActionModal isOpen={isOpen} title={modalTitle} width="small" onClose={onClose} verticalAlignment="highest">
        <div className="mb-medium">
          <Text>
            As this note relates to a group booking it cannot be {isEditingNote ? 'edited' : 'deleted'} on the customer
            profile.
          </Text>
        </div>
        <div>
          <Text>Please visit the relavent group session to {isEditingNote ? 'edit' : 'delete'} the note.</Text>
          <ActionModalFooter align="right" className="mt-medium">
            <SecondaryButton size="large" className="mr-medium" onClick={onClose}>
              Close
            </SecondaryButton>
            <PrimaryButton size="large" onClick={onGoToSession}>
              Go to session
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    );
  }
}

export default CannotEditOrDeleteNoteModal;
