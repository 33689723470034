import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Wrench = forwardRef(function Wrench(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.067 3.119a.75.75 0 0 1-.209.657L16.564 7.07c-.348.348-.555.557-.684.726a.709.709 0 0 0-.095.155l-.001.003v.002l.005.011c.009.022.032.067.091.144.13.17.336.378.684.727.349.348.558.555.727.684a.709.709 0 0 0 .154.095l.003.001h.003a.703.703 0 0 0 .155-.095c.169-.13.378-.337.726-.685l3.294-3.294a.75.75 0 0 1 1.207.207 6.753 6.753 0 0 1-8.507 9.214l-6.247 6.247a2.75 2.75 0 1 1-3.889-3.89l6.247-6.246A6.751 6.751 0 0 1 19.65 2.57a.75.75 0 0 1 .416.55Zm-2.107.434a5.253 5.253 0 0 0-5.957 7.371.75.75 0 0 1-.147.854l-6.605 6.606a1.25 1.25 0 0 0 1.767 1.767l6.606-6.605a.75.75 0 0 1 .853-.146 5.252 5.252 0 0 0 7.372-5.958l-2.456 2.456-.032.032c-.306.306-.586.587-.845.784-.286.218-.63.404-1.068.404-.437 0-.782-.186-1.067-.404-.26-.197-.54-.478-.846-.784l-.031-.032-.032-.031c-.306-.306-.587-.587-.784-.846-.218-.285-.404-.63-.404-1.067 0-.437.186-.782.404-1.068.197-.259.478-.54.784-.845l.032-.032 2.456-2.456Zm-2.176 4.4v.001"
        fill="currentColor"
      />
    </Icon>
  );
});
