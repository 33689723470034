import React, { memo } from 'react';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import { getPersistor } from '@rematch/persist';

import rootStore from '../root-store';

import type { PropsWithChildren } from 'react';
import type { Store } from 'redux';

const persistor = getPersistor();

type GateProps = {
  store?: Store;
};

const Gate = (props: PropsWithChildren<GateProps>) => {
  const { children, store } = props;

  return (
    <Provider store={store ?? rootStore}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </Provider>
  );
};

export default memo(Gate);
