import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ImageSlash = forwardRef(function ImageSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.47 1.47a.75.75 0 0 1 1.06 0L4.702 3.64l15.657 15.657 2.171 2.172a.75.75 0 1 1-1.06 1.06l-1.684-1.683c-.198.144-.409.273-.63.385-.55.281-1.154.403-1.868.461-.697.057-1.563.057-2.655.057H9.367c-1.092 0-1.958 0-2.655-.057-.714-.058-1.317-.18-1.868-.46a4.75 4.75 0 0 1-2.076-2.076c-.281-.552-.403-1.155-.46-1.869-.058-.697-.058-1.563-.058-2.655V9.367c0-1.092 0-1.958.057-2.655.058-.714.18-1.317.46-1.868a4.75 4.75 0 0 1 .386-.63L1.47 2.53a.75.75 0 0 1 0-1.06Zm2.764 3.824c-.046.075-.09.152-.13.23-.155.305-.251.69-.302 1.31-.051.63-.052 1.434-.052 2.566v5.2c0 1.117 0 1.914.05 2.54l.67-.67.671-.672.037-.037c.635-.635 1.165-1.165 1.642-1.529.504-.384 1.036-.654 1.68-.654.644 0 1.176.27 1.68.654.477.364 1.007.894 1.642 1.53l.037.036 1.671 1.672 2.5 2.5c.08.08.138.172.174.271.365-.007.681-.02.962-.043.62-.05 1.005-.147 1.31-.302.078-.04.155-.084.23-.13L4.234 5.294Zm9.955 14.956-1.72-1.72-1.67-1.671c-.682-.682-1.142-1.139-1.529-1.434-.37-.283-.586-.347-.77-.347-.184 0-.4.064-.77.347-.387.295-.846.752-1.528 1.434l-.672.671-1.25 1.25c.31.472.738.858 1.245 1.116.304.155.688.251 1.309.302.63.051 1.434.052 2.566.052h4.79Zm2.977-16.448c-.63-.051-1.434-.052-2.566-.052H8a.75.75 0 0 1 0-1.5h6.633c1.092 0 1.958 0 2.655.057.714.058 1.317.18 1.869.46a4.75 4.75 0 0 1 2.075 2.077c.281.55.403 1.154.461 1.868.057.697.057 1.563.057 2.655V16a.75.75 0 0 1-1.5 0V9.4c0-1.132 0-1.937-.052-2.566-.05-.62-.147-1.005-.302-1.31a3.25 3.25 0 0 0-1.42-1.42c-.305-.155-.69-.251-1.31-.302ZM16 6.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM13.25 8a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
