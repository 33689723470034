import React, { useEffect, useState } from 'react';
import { FRILL_WIDGET_KEY } from 'config/app-config';
import { FrillConfig, FrillWindow, FrillWidget } from './frill-widget-types';
import { ActionIcon, Indicator, Text } from '@good/ui/core';
import { NewRelease } from '@good/icons';

import './frill-widget.css';

const win = window as FrillWindow;

export const FrillWidgetButton: React.FC = () => {
  const [badgeCount, setBadgeCount] = useState(0);

  useEffect(() => {
    let widget: FrillWidget | undefined;
    const config: FrillConfig = {
      key: FRILL_WIDGET_KEY,
      callbacks: {
        onReady: (frillWidget) => {
          widget = frillWidget;
        },
        onBadgeCount: ({ count }) => {
          setBadgeCount(count);
        },
      },
    };
    // If Frill is loaded, use the config immediately. Otherwise, create Frill_Config array for Frill to use when it loads.
    if (win.Frill) widget = win.Frill.widget(config);
    else (win.Frill_Config = win.Frill_Config || []).push(config);
    return () => {
      widget?.destroy();
      win.Frill_Config = win.Frill_Config?.filter((c) => c !== config);
    };
  }, []);

  return (
    <Indicator
      offset={6}
      withBorder
      color='red'
      size={24}
      label={
        <Text size='xs' fw={700} px={4}>
          {badgeCount}
        </Text>
      }
      disabled={!badgeCount}
    >
      <ActionIcon variant='white' color='gray.8' size={36} fz={20} className='frill-widget' data-testid='frill-widget'>
        <NewRelease />
      </ActionIcon>
    </Indicator>
  );
};
