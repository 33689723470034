import React from 'react';

interface IConditionallyHiddenProps {
  hidden: boolean;
  children: React.ReactNode;
}

const ConditionallyHidden = ({ hidden, children }: IConditionallyHiddenProps) => {
  return (
    <div
      style={hidden ? { height: 0, overflow: 'hidden', visibility: 'hidden', opacity: 0 } : {}}
      aria-hidden={hidden ? 'true' : 'false'}
    >
      {children}
    </div>
  );
};

export default ConditionallyHidden;
