import { Col, Icon, Row } from 'antd';
import { Information } from 'common-components/alerts';
import { HyperlinkButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { SubTitle, Text } from 'common-components/typography';
import * as H from 'history';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { GroupServiceSessionStatus } from 'utilities/enum-utils';
import EditSessionAddressModal from 'views/group-services/session-details/components/EditSessionAddressModal';
import EditSessionCapacityModal from 'views/group-services/session-details/components/EditSessionCapacityModal';
import SessionEditScheduledTimesModal from 'views/group-services/session-details/components/SessionEditScheduledTimesModal';
import SessionOverviewWarningPanel from 'views/group-services/session-details/components/SessionOverviewWarningPanel';
import Map from 'views/team/details/tabs-panel/general/Map';

interface ISessionOverviewViewProps {
  history: H.History;
  goToTab: (selectedTab, options?: any) => void;
  selectedSession: typeof state.groupServiceStore.selectedSession;
}

interface ISessionOverviewViewState {
  isEditCapacityOpen: boolean;
  isEditScheduledTimesOpen: boolean;
  isEditLocationOpen: boolean;
}

class SessionOverviewView extends Component<ISessionOverviewViewProps, ISessionOverviewViewState> {
  state = {
    isEditCapacityOpen: false,
    isEditScheduledTimesOpen: false,
    isEditLocationOpen: false,
  };

  private _openEditCapacity = () => {
    this.setState({ isEditCapacityOpen: true });
  };

  private _closeEditCapacity = () => {
    this.setState({ isEditCapacityOpen: false });
  };

  private _openEditScheduleTimes = () => {
    this.setState({ isEditScheduledTimesOpen: true });
  };

  private _closeEditScheduleTimes = () => {
    this.setState({ isEditScheduledTimesOpen: false });
  };

  private _openEditLocation = () => {
    this.setState({ isEditLocationOpen: true });
  };

  private _closeEditLocation = () => {
    this.setState({ isEditLocationOpen: false });
  };

  render = () => {
    const { selectedSession, history } = this.props;
    const { isEditCapacityOpen, isEditScheduledTimesOpen } = this.state;

    const teamMemberAvailableSlots =
      selectedSession && selectedSession.totalShiftSlot
        ? selectedSession.totalShiftSlot - selectedSession.totalAssignedShiftSlot
        : 0;
    const customerAvailableSlots =
      selectedSession && selectedSession.capacity ? selectedSession.capacity - selectedSession.bookedCapacity : 0;

    const latitude = selectedSession && selectedSession.address && Number(selectedSession.address.geoLat);
    const longitude = selectedSession && selectedSession.address && Number(selectedSession.address.geoLng);

    return (
      <div>
        {/* Modals */}
        {selectedSession && (
          <>
            <EditSessionCapacityModal isOpen={isEditCapacityOpen} onClose={this._closeEditCapacity} />
            <SessionEditScheduledTimesModal isOpen={isEditScheduledTimesOpen} onClose={this._closeEditScheduleTimes} />
            <EditSessionAddressModal
              closeModal={this._closeEditLocation}
              isOpen={this.state.isEditLocationOpen}
              address={selectedSession && selectedSession.address}
            />
          </>
        )}

        {/* Is loading... */}
        {!selectedSession ? (
          <SpinningLoader size={100} message={'Fetching session data...'} />
        ) : selectedSession.sessionStatus === GroupServiceSessionStatus.CANCELLED ? (
          <Information
            content={
              <Text>
                This session has been <b>cancelled</b>
              </Text>
            }
            displayType={'block'}
          />
        ) : (
          <>
            {/*{selectedSession.sessionStatus === GroupServiceSessionStatus.SCHEDULED && (*/}
            {/*  <Information*/}
            {/*    content={*/}
            {/*      <>*/}
            {/*        <Text>*/}
            {/*          This session is scheduled to start at{' '}*/}
            {/*          <b>*/}
            {/*            {moment*/}
            {/*              .tz(selectedSession.startDateTime, selectedSession.timezone)*/}
            {/*              .format('dddd, DD MMMM YYYY, hh:mm A')}*/}
            {/*            .*/}
            {/*          </b>*/}
            {/*        </Text>*/}
            {/*        <br />*/}
            {/*        {selectedSession.scheduleType === ScheduleType.SCHEDULE && (*/}
            {/*          <HyperlinkButton onClick={this._openEditScheduleTimes}>Edit start time...</HyperlinkButton>*/}
            {/*        )}*/}
            {/*      </>*/}
            {/*    }*/}
            {/*    displayType={'block'}*/}
            {/*    className={'mb-large'}*/}
            {/*  />*/}
            {/*)}*/}

            {/*{selectedSession.sessionStatus === GroupServiceSessionStatus.INPROGRESS && (*/}
            {/*  <Information*/}
            {/*    content={*/}
            {/*      <>*/}
            {/*        <Text>*/}
            {/*          This session is scheduled to end at{' '}*/}
            {/*          {moment*/}
            {/*            .tz(selectedSession.endDateTime, selectedSession.timezone)*/}
            {/*            .format('dddd, DD MMMM YYYY, hh:mm A')}*/}
            {/*          .*/}
            {/*        </Text>*/}
            {/*      </>*/}
            {/*    }*/}
            {/*    displayType={'block'}*/}
            {/*    className={'mb-large'}*/}
            {/*  />*/}
            {/*)}*/}

            {/* Main content for the session overview */}
            {/*<div className="mt-x10-large">Previous content</div>*/}

            <div className={'flex-column'}>
              <SessionOverviewWarningPanel history={history} />
            </div>

            <div className={'mb-large'}>
              <Row>
                <Col span={12} className={'pr-x2-large pv-medium'}>
                  <Text weight={'bold'}>Session start/end times</Text>
                  <br />
                  <Text>What time will the session start/end?</Text>
                </Col>
                <Col span={12} className={'bg-white shadow-container rounded-big p-medium'}>
                  <Row>
                    <Col span={12}>
                      <Text color={'secondary'}>Scheduled start time</Text>
                      <br />
                      <Text>
                        {moment.tz(selectedSession.startDateTime, selectedSession.timezone).format('DD MMMM YYYY,')}
                        <br />
                        {moment.tz(selectedSession.startDateTime, selectedSession.timezone).format('hh:mm A')}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Text color={'secondary'}>Scheduled end time</Text>
                      <br />
                      <Text>
                        {moment.tz(selectedSession.endDateTime, selectedSession.timezone).format('DD MMMM YYYY,')}
                        <br />
                        {moment.tz(selectedSession.endDateTime, selectedSession.timezone).format('hh:mm A')}
                      </Text>
                    </Col>
                  </Row>
                  {selectedSession.sessionStatus === GroupServiceSessionStatus.SCHEDULED && (
                    <Row>
                      <HyperlinkButton onClick={this._openEditScheduleTimes}>
                        Edit schedule start/end times
                      </HyperlinkButton>
                    </Row>
                  )}
                  <Row className={'mt-medium'}>
                    <Col span={12}>
                      <Text color={'secondary'}>Actual start time</Text>
                      <br />
                      {selectedSession.sessionStartDateTime ? (
                        <>
                          <Text>
                            {moment
                              .tz(selectedSession.sessionStartDateTime, selectedSession.timezone)
                              .format('DD MMMM YYYY,')}
                            <br />
                            {moment
                              .tz(selectedSession.sessionStartDateTime, selectedSession.timezone)
                              .format('hh:mm A')}
                          </Text>
                        </>
                      ) : (
                        <Text>-</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text color={'secondary'}>Actual end time</Text>
                      <br />
                      {selectedSession.sessionEndDateTime ? (
                        <>
                          <Text>
                            {moment
                              .tz(selectedSession.sessionEndDateTime, selectedSession.timezone)
                              .format('DD MMMM YYYY,')}
                            <br />
                            {moment.tz(selectedSession.sessionEndDateTime, selectedSession.timezone).format('hh:mm A')}
                          </Text>
                        </>
                      ) : (
                        <Text>-</Text>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className={'mb-large'}>
              <Row>
                <Col span={12} className={'pr-large'}>
                  <div className={'shadow-container rounded-big'}>
                    <div
                      className={'bg-white ph-medium pv-large width-full rounded-big-top'}
                      style={{ height: '120px' }}
                    >
                      <SubTitle>SHIFTS</SubTitle>
                      <div className={'flex-row align-center mt-small'}>
                        {selectedSession.totalShiftSlot ? (
                          <>
                            {teamMemberAvailableSlots === 0 && (
                              <Icon
                                type={'check-circle'}
                                theme={'filled'}
                                className={'text-color-green mr-small text-size-x2-large'}
                              />
                            )}{' '}
                            <Text size={'x-large'} weight="bold">
                              {selectedSession.totalAssignedShiftSlot ? selectedSession.totalAssignedShiftSlot : 0}
                              {selectedSession.totalShiftSlot && '/' + selectedSession.totalShiftSlot}{' '}
                              <span className="text-weight-regular text-color-secondary">shift(s) filled</span>
                            </Text>
                            <Text>
                              {' '}
                              <br />
                            </Text>
                          </>
                        ) : (
                          <Text size={'x-large'} color={'tertiary'}>
                            No shifts created.
                          </Text>
                        )}
                      </div>
                    </div>
                    <div className={'bg-quaternary ph-medium pv-small rounded-big-bottom'}>
                      <HyperlinkButton onClick={() => this.props.goToTab('TEAM-MEMBERS')}>View shifts</HyperlinkButton>
                    </div>
                  </div>
                </Col>
                <Col span={12} className={''}>
                  <div className={'shadow-container rounded-big'}>
                    <div
                      className={'bg-white ph-medium pv-large width-full rounded-big-top'}
                      style={{ height: '120px' }}
                    >
                      <SubTitle>Bookings</SubTitle>
                      <div className={'flex-row align-center mt-small'}>
                        {selectedSession.capacity ? (
                          <>
                            {customerAvailableSlots === 0 && (
                              <Icon
                                type={'check-circle'}
                                theme={'filled'}
                                className={'text-color-green mr-small text-size-x2-large'}
                              />
                            )}{' '}
                            <Text size={'x-large'} weight="bold">
                              {selectedSession.bookedCapacity ? selectedSession.bookedCapacity : 0}
                              {selectedSession.capacity && '/' + selectedSession.capacity}{' '}
                              <span className="text-weight-regular text-color-secondary">
                                customers in this session
                              </span>
                            </Text>
                          </>
                        ) : (
                          <Text size={'x-large'}>
                            {selectedSession.bookedCapacity && selectedSession.bookedCapacity > 0 ? (
                              selectedSession.bookedCapacity +
                              ` total customer${selectedSession.bookedCapacity !== 1 ? 's' : ''}`
                            ) : (
                              <span className={'text-color-tertiary'}>No bookings created.</span>
                            )}
                          </Text>
                        )}
                      </div>
                      <HyperlinkButton onClick={this._openEditCapacity}>
                        {selectedSession.capacity ? 'Edit capacity...' : 'Add max capacity...'}
                      </HyperlinkButton>
                    </div>
                    <div className={'bg-quaternary ph-medium pv-small rounded-big-bottom'}>
                      <HyperlinkButton onClick={() => this.props.goToTab('CUSTOMERS')}>View bookings</HyperlinkButton>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className={'mb-large'}>
              <Row className={'bg-white p-medium shadow-container rounded-big'}>
                <Col span={12}>
                  <div className={'flex-row'}>
                    <div>
                      <Icon type={'pushpin'} className={'text-color-secondary mh-small'} />
                    </div>
                    <div>
                      <SubTitle>Session location</SubTitle>
                      <div className={'mv-small'}>
                        <Text color={'secondary'}>This session will take place at</Text>
                      </div>
                      <Text style={{ whiteSpace: 'pre-line' }}>
                        {selectedSession &&
                          selectedSession.address &&
                          CommonUtils.formatFullAddress(selectedSession.address)}
                      </Text>
                      <br />
                      <HyperlinkButton onClick={this._openEditLocation}>Edit location...</HyperlinkButton>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div>{latitude !== 0 && longitude !== 0 && <Map lattitude={latitude} longitude={longitude} />}</div>
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>
    );
  };
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
});

export default connect(mapState, null)(SessionOverviewView);
