import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Avatar, Col, Row, Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'react-datepicker';
import _ from 'lodash';

import { SubTitle, Text } from 'common-components/typography';
import ActionModal from 'common-components/modal/ActionModal';
import { Stack } from 'common-components/stack';
import TimeInput from 'common-components/time-input/TimeInput';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ICustomer } from 'interfaces/customer-interfaces';
import {
  IGroupServiceSession,
  IGroupServiceCustomerRatio,
  IGroupServiceCustomRatio,
} from 'interfaces/service-interfaces';
import CommonUtils from 'utilities/common-utils';
import { EditRatios, IEditRatiosSubscribe } from 'views/group-services/components/edit-ratios';

import EditCustomerRatioPanel from './EditCustomerRatioPanel';

interface IEditSessionDetailModalProps extends FormComponentProps {
  isModalOpen: boolean;
  selectedCustomer: ICustomer;
  selectedSession: IGroupServiceSession;
  defaultRatioValue: string;
  timezone: string;
  onCloseViewModal: () => void;
  onSubmit: (data: { session: IGroupServiceSession; customerRatio: IGroupServiceCustomerRatio }) => void;
}

interface BookingDateTime {
  startDateTime: moment.Moment | Date;
  endDateTime: moment.Moment | Date;
  timezone: string;
}

function EditSessionDetailModal(props: IEditSessionDetailModalProps) {
  const { pinc1004NonTimeBoundRatios } = useFlags();
  const [ratiosForm, setRatiosForm] = useState<IEditRatiosSubscribe>(null);
  const { isModalOpen, selectedCustomer, selectedSession, defaultRatioValue, timezone, onCloseViewModal, onSubmit } =
    props;
  const { firstName, lastName, attachmentUrl } = selectedCustomer;

  const customerRatioPanelRef = useRef(null);
  const [customerRatioPanelState, setCustomerRatioPanelState] = useState('');
  const [currentCustomerRatio, setCurrentCustomerRatio] = useState<IGroupServiceCustomerRatio>(
    selectedSession.customerRatio || {},
  );
  const [updatedCustomRatio, setUpdatedCustomRatio] = useState<IGroupServiceCustomRatio[]>(
    selectedSession.customerRatio?.customRatio || [],
  );
  const [bookingDateTime, setBookingDateTime] = useState<BookingDateTime>({
    startDateTime: selectedSession.startDateTime,
    endDateTime: selectedSession.endDateTime,
    timezone,
  });
  const { startDateTime, endDateTime, errors } = currentCustomerRatio;

  const onChangeBookingDateTime = useCallback(
    (value, fieldName: 'bookingStartDateTime' | 'bookingEndDateTime') => {
      const roundedDate = CommonUtils.formatCeilingDateTime(value);
      const bookingDateTime_ = {
        startDateTime: fieldName === 'bookingStartDateTime' ? roundedDate : startDateTime,
        endDateTime: fieldName === 'bookingEndDateTime' ? roundedDate : endDateTime,
      };

      setBookingDateTime((prev) => ({ ...prev, ...bookingDateTime_ }));

      const isErrorEndBeforeStartTime = !moment
        .tz(bookingDateTime_.startDateTime, timezone)
        .isBefore(moment.tz(bookingDateTime_.endDateTime, timezone));
      const isMultipleDateRange = !moment
        .tz(bookingDateTime_.startDateTime, timezone)
        .isSame(moment.tz(bookingDateTime_.endDateTime, timezone), 'days');

      setCurrentCustomerRatio({
        ...currentCustomerRatio,
        ...bookingDateTime_,
        errors: {
          isStartAfterEndTime: isErrorEndBeforeStartTime,
          isMultipleDateRange,
        },
      });
    },
    [currentCustomerRatio, startDateTime, endDateTime, timezone],
  );

  const validateErrors = useCallback(() => {
    const isErrorEndBeforeStartTime = !moment.tz(startDateTime, timezone).isBefore(moment.tz(endDateTime, timezone));

    const isCustomRatioTimeErrors = customerRatioPanelRef.current?.validateCustomRatios(updatedCustomRatio);
    return isErrorEndBeforeStartTime || isCustomRatioTimeErrors;
  }, [startDateTime, endDateTime, timezone, updatedCustomRatio, customerRatioPanelRef, pinc1004NonTimeBoundRatios]);

  const submitForm = useCallback(() => {
    let data: { session: IGroupServiceSession; customerRatio: IGroupServiceCustomerRatio };

    if (pinc1004NonTimeBoundRatios) {
      const customerRatio = ratiosForm?.getRatio();
      data = {
        session: selectedSession,
        customerRatio: {
          ...customerRatio,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        },
      };
    } else {
      if (!validateErrors()) {
        const { customerRatio } = selectedSession;
        const updatedCustomerRatio = customerRatioPanelRef.current.getCustomerRatio();
        data = {
          session: selectedSession,
          customerRatio: {
            ...customerRatio,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            isCustomRatio: updatedCustomerRatio.isCustomRatio,
            customRatio: updatedCustomerRatio.customRatio,
          },
        };
      } else {
        setCustomerRatioPanelState(uuidv4());
        return;
      }
    }

    onSubmit(data);
    onCloseViewModal();
  }, [
    selectedSession,
    startDateTime,
    endDateTime,
    validateErrors,
    onSubmit,
    onCloseViewModal,
    pinc1004NonTimeBoundRatios,
    ratiosForm,
  ]);

  const sessionStartDateTime = useMemo(
    () => moment.tz(selectedSession.startDateTime, timezone),
    [selectedSession.startDateTime, timezone],
  );

  const sessionEndDateTime = useMemo(
    () => moment.tz(selectedSession.endDateTime, timezone),
    [selectedSession.endDateTime, timezone],
  );

  const dateLineText = useMemo(
    () =>
      !sessionStartDateTime.isSame(sessionEndDateTime, 'day')
        ? !sessionStartDateTime.isSame(sessionEndDateTime, 'month')
          ? !sessionStartDateTime.isSame(sessionEndDateTime, 'year')
            ? sessionStartDateTime.format("ddd D MMM 'YY") + ' - ' + sessionEndDateTime.format("ddd D MMM 'YY")
            : sessionStartDateTime.format('ddd D MMM') + ' - ' + sessionEndDateTime.format("ddd D MMM 'YY")
          : sessionStartDateTime.format('ddd D') + ' - ' + sessionEndDateTime.format("ddd D MMM 'YY")
        : sessionStartDateTime.format("ddd D MMM 'YY"),
    [sessionStartDateTime, sessionEndDateTime],
  );

  useEffect(() => {
    if (startDateTime && endDateTime) {
      const isMultipleDateRange = !moment.tz(startDateTime, timezone).isSame(moment.tz(endDateTime, timezone), 'days');
      setCurrentCustomerRatio({
        ...currentCustomerRatio,
        errors: {
          isMultipleDateRange,
        },
      });
    }
  }, []);

  return (
    <ActionModal title="Edit booking time" isOpen={isModalOpen} onClose={onCloseViewModal}>
      <Form>
        <Stack gap="xl">
          <div className="bg-tertiary pv-12 ph-medium rounded">
            <Row>
              <Col span={6}>
                <Text className="text-uppercase mb-small block" weight="bolder" color="secondary" size="small">
                  SELECTED CUSTOMER
                </Text>
                <Avatar size={32} src={attachmentUrl} shape="circle" />{' '}
                <Text className="ml-small" color="primary">
                  {firstName + ' ' + lastName}
                </Text>
              </Col>
              <Col span={6}>
                <Text className="text-uppercase mb-small block" weight="bolder" color="secondary" size="small">
                  SESSION DATE
                </Text>
                <div className="block">
                  <Text className="whitespace-nowrap" weight="bold" size="regular">
                    {dateLineText}
                    {selectedSession.isInHoliday && ' (Public holiday)'}
                  </Text>
                  <br />
                  <div className="flex-row">
                    <Text className="whitespace-nowrap" size="large" color="secondary">
                      {moment(sessionStartDateTime, timezone).format('h:mmA') +
                        ' – ' +
                        moment(sessionEndDateTime, timezone).format('h:mmA')}
                    </Text>
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <Text className="text-uppercase mb-small block" weight="bolder" color="secondary" size="small">
                  CAPACITY
                </Text>
                <Text>
                  {selectedSession.capacity && selectedSession.bookedCapacity
                    ? selectedSession.bookedCapacity + '/' + selectedSession.capacity
                    : selectedSession.capacity
                    ? selectedSession.capacity
                    : 'No capacity'}
                </Text>
              </Col>
              <Col span={6}>
                <Text className="text-uppercase mb-small block" weight="bolder" color="secondary" size="small">
                  SCHEDULE
                </Text>
                <Text>{selectedSession.scheduleName}</Text>
              </Col>
            </Row>
          </div>

          <div className="row">
            <Text className="mb-small block" weight="bolder" color="secondary">
              Booking time
            </Text>
            <div className="rounded-big bordered border-secondary">
              <div className="mv-large mh-x-large">
                <div className="flex-row ">
                  <div className="width-half mr-large">
                    <SubTitle>Start date/time</SubTitle>
                    <div className="mt-x2-small flex-row">
                      <DatePicker
                        className={`gh-datepicker mr-medium rounded ${
                          errors?.isStartAfterEndTime && 'border-red-dark'
                        }`}
                        calendarClassName="gh-datepicker-calendar"
                        onChange={(value) => onChangeBookingDateTime(value, 'bookingStartDateTime')}
                        dateFormat="dd/MM/yyyy"
                        selected={moment.tz(startDateTime, timezone).toDate()}
                        isClearable={false}
                      />
                      <TimeInput
                        size="large"
                        className={`mr-small ${errors?.isStartAfterEndTime && 'border-red-dark'}`}
                        value={moment.tz(startDateTime, timezone)}
                        onChange={(value) => onChangeBookingDateTime(value, 'bookingStartDateTime')}
                      />
                    </div>
                  </div>
                  <div className="width-half">
                    <SubTitle>End date/time</SubTitle>
                    <div className="mt-x2-small flex-row">
                      <DatePicker
                        className={`gh-datepicker mr-medium rounded ${
                          errors?.isStartAfterEndTime && 'border-red-dark'
                        }`}
                        calendarClassName="gh-datepicker-calendar"
                        onChange={(value) => onChangeBookingDateTime(value, 'bookingEndDateTime')}
                        dateFormat="dd/MM/yyyy"
                        selected={moment.tz(endDateTime, timezone).toDate()}
                        isClearable={false}
                      />
                      <TimeInput
                        size="large"
                        className={`mr-small ${errors?.isStartAfterEndTime && 'border-red-dark'}`}
                        value={moment.tz(endDateTime, timezone)}
                        onChange={(value) => onChangeBookingDateTime(value, 'bookingEndDateTime')}
                      />
                    </div>
                  </div>
                </div>
                {errors?.isStartAfterEndTime && <Text color="red">Start time cannot be after end time</Text>}
              </div>
            </div>
          </div>
          <div className="row">
            {pinc1004NonTimeBoundRatios ? (
              <EditRatios
                type="create"
                subscribe={(ratioForm) => setRatiosForm(ratioForm)}
                sessionTimes={bookingDateTime}
                initialRatio={currentCustomerRatio}
              />
            ) : (
              <EditCustomerRatioPanel
                ref={customerRatioPanelRef}
                customerRatio={currentCustomerRatio}
                defaultRatioValue={defaultRatioValue}
                timezone={timezone}
                disableCustomByHourOption={errors?.isMultipleDateRange}
                onChange={(data: IGroupServiceCustomRatio[]) => {
                  setUpdatedCustomRatio(data);
                  setCustomerRatioPanelState(uuidv4());
                }}
              />
            )}
          </div>
          <ActionModalFooter className="flex-row">
            <div className="flex flex-grow"></div>
            <SecondaryButton color="secondary" size="large" onClick={onCloseViewModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              className="ml-medium"
              size="large"
              onClick={submitForm}
              disabled={(customerRatioPanelState && validateErrors()) || ratiosForm?.hasValidationError}
            >
              Save changes
            </PrimaryButton>
          </ActionModalFooter>
        </Stack>
      </Form>
    </ActionModal>
  );
}

export default Form.create<IEditSessionDetailModalProps>()(EditSessionDetailModal);
