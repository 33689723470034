import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const User = forwardRef(function User(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 7a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0ZM12 2.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5ZM5.75 19A3.25 3.25 0 0 1 9 15.75h6A3.25 3.25 0 0 1 18.25 19c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25ZM9 14.25A4.75 4.75 0 0 0 4.25 19 2.75 2.75 0 0 0 7 21.75h10A2.75 2.75 0 0 0 19.75 19 4.75 4.75 0 0 0 15 14.25H9Z"
        fill="currentColor"
      />
    </Icon>
  );
});
