import React from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { Icon } from 'antd';
import { Text } from '@good/components';
import { useTranslation } from 'react-i18next';
import { SupportWorkerAlertType } from 'utilities//enum-utils';
import { supportWorkerAlertTypeToTranslationMap } from 'views/team/details/components/TeamAlert';

export type TeamMemberAlert = unknown & { supportWorkerAlertType: SupportWorkerAlertType; numberOfAlerts: number };

type TeamListingAlertsProps = {
  alerts: TeamMemberAlert[];
};

const getAlertIconFromType = (type: SupportWorkerAlertType): React.ReactNode => {
  if (type === SupportWorkerAlertType.ComplianceDocumentExpired || type === SupportWorkerAlertType.DocumentExpired)
    return <Icon type='minus-circle' theme='filled' className='text-size-x2-large text-color-red mr-x-small' />;

  return <Icon type='warning' theme='filled' className='text-size-x2-large text-color-warning-orange mr-x-small' />;
};

export const TeamListingAlert: React.FC<{ alert: TeamMemberAlert }> = ({
  alert: { numberOfAlerts, supportWorkerAlertType },
}) => {
  const { t } = useTranslation('', { keyPrefix: 'alerts.team.description' });

  return (
    <div className='flex align-center gap-2'>
      {getAlertIconFromType(supportWorkerAlertType)}
      <Text>{t(supportWorkerAlertTypeToTranslationMap[supportWorkerAlertType], { count: numberOfAlerts })}</Text>
    </div>
  );
};

const TeamListingAlertsPopover: React.FC<TeamListingAlertsProps> = ({ alerts }) => (
  <div className='ph-x-large pv-medium' style={{ width: '400px' }}>
    <div className='mb-medium'>
      <Text size='x2-large text-weight-bold'>Alerts</Text>
    </div>
    {alerts.map((alert, index) => {
      return <TeamListingAlert key={alert.supportWorkerAlertType + index} alert={alert} />;
    })}
  </div>
);

const TeamListingAlerts: React.FC<TeamListingAlertsProps> = ({ alerts }) => {
  if (alerts.length === 0) return null;

  const firstTwoAlerts = alerts.slice(0, 2);

  return (
    <div className='flex-row align-center'>
      {firstTwoAlerts.map((alert, index) => (
        <TeamListingAlert key={alert.supportWorkerAlertType + index} alert={alert} />
      ))}
      {/* Any more than the first two alerts get rendered as 'X more...' i.e. a total of 5 alerts would show '3 more...' */}
      {alerts.length > 2 && (
        <Text className='text-weight-bold ml-small'>
          {alerts.length - 2}
          <Popover2 content={<TeamListingAlertsPopover alerts={alerts} />} interactionKind='hover' position='top'>
            more...
          </Popover2>
        </Text>
      )}
    </div>
  );
};

export default TeamListingAlerts;
