enum OptimisticStatus {
  update = '__updating__',
  create = '__creating__',
}

type OptimisticStatusKey = keyof typeof OptimisticStatus;

export type ItemWithStatus<T extends object> = T & {
  [key in OptimisticStatus]?: string;
};

export function isItemWithStatus<T extends object>(
  item: T | ItemWithStatus<T>,
  type: OptimisticStatusKey,
): item is ItemWithStatus<T> {
  return OptimisticStatus[type] in item;
}

export function addStatus<T extends object>(item: T, type: OptimisticStatusKey, value: string): ItemWithStatus<T> {
  const itemWithStatus = { ...item } as ItemWithStatus<T>;
  itemWithStatus[OptimisticStatus[type]] = value;
  return itemWithStatus;
}

export function removeStatus<T extends object>(item: ItemWithStatus<T>, type: OptimisticStatusKey): T {
  const itemWithoutStatus = { ...item };

  switch (type) {
    case 'create':
      delete itemWithoutStatus.__creating__;
      break;
    case 'update':
      delete itemWithoutStatus.__updating__;
      break;
  }

  return itemWithoutStatus as T;
}

export function matchStatus<T extends object>(
  item: T | ItemWithStatus<T>,
  type: OptimisticStatusKey,
  value: string,
): boolean {
  if (!isItemWithStatus(item, type)) {
    return false;
  }
  return item[OptimisticStatus[type]] === value;
}

export function hasStatus<T extends object>(item: T | ItemWithStatus<T>, type: OptimisticStatusKey): boolean {
  return isItemWithStatus(item, type);
}

export function isMutating<T extends object>(item: T | ItemWithStatus<T>): boolean {
  return hasStatus(item, 'update') || hasStatus(item, 'create');
}

export function isUpdating<T extends object>(item: T | ItemWithStatus<T>): boolean {
  return hasStatus(item, 'update');
}

export function isCreating<T extends object>(item: T | ItemWithStatus<T>): boolean {
  return hasStatus(item, 'create');
}
