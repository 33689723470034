import React from 'react';
import { Avatar, Skeleton } from '@good/ui/core';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { ProfileMenu } from './profile-menu';
import { ProfileButton } from './profile-button';

export const Profile = () => {
  const portalUser = usePortalUser();

  return (
    <ProfileMenu>
      <ProfileButton>
        <Skeleton visible={portalUser === null}>
          <Avatar src={portalUser?.attachmentUrl} size={38} />
        </Skeleton>
      </ProfileButton>
    </ProfileMenu>
  );
};
