import React from 'react';

import { Icon } from 'antd';

import { Text } from 'common-components/typography';
import { FaHashtag } from 'react-icons/fa';
import { IExtendedMessage } from 'interfaces/message-interfaces';

export function NewChannelTag({ message }: { message: IExtendedMessage }) {
  const { currentChannelName } = message;

  return (
    <div className="flex-row justify-center align-center">
      <Icon type="customer-service" className="text-color-tertiary text-size-regular mr-x-small" />

      <Text size="small" color="tertiary">
        Started a conversation with <b>{message.currentChannelName}</b>
      </Text>
    </div>
  );
}
