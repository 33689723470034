import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Avatar, Card, Col, Icon, Row } from 'antd';
import TextTag from 'common-components/tags/TextTag';
import { Text } from 'common-components/typography';
import * as H from 'history';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { NoteVisibleType } from 'utilities/enum-utils';
import { GhostButton, HyperlinkButton } from '../buttons';
import { downloadFileFromUrl } from 'utilities/file-utils';

interface IDocumentCardItemProps {
  documentType: string;
  documentItem: any;
  serviceName: string;
  bookingId: string;
  onPressEditDocument: (document) => void;
  onPressDeleteDocument: (document) => void;
  bookingStartDateTime?: any;
  timezone: string;
  history?: H.History;
  isBookingArchived?: boolean;
  selectedCustomerUserId?: string;
}

class DocumentCardItem extends Component<IDocumentCardItemProps, any> {
  private element = null;
  state = {
    readMore: false,
    hasTextOverflown: true,
    textOffsetHeight: 0,
  };

  private _getPopoverContent = () => {
    const { documentItem, onPressEditDocument, onPressDeleteDocument } = this.props;
    return (
      <Menu>
        <MenuItem
          label={''}
          className='hover-bg-blue-lightest mv-medium'
          text={documentItem.noteDetail ? 'Edit note' : 'Edit Attachment'}
          onClick={() => onPressEditDocument(documentItem)}
        />
        <MenuItem
          label={''}
          className='hover-bg-blue-lightest text-color-red-dark mv-medium'
          text={documentItem.noteDetail ? 'Delete note' : 'Delete Attachment'}
          onClick={() => onPressDeleteDocument(documentItem)}
        />
      </Menu>
    );
  };

  componentDidMount() {
    const { element } = this;
    const hasTextOverflown = element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
    this.setState({
      hasTextOverflown,
      textOffsetHeight: element.offsetHeight,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.documentItem.noteDetail?.body !== this.props.documentItem.noteDetail?.body) {
      const { element } = this;
      const hasTextOverflown =
        this.state.textOffsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
      this.setState({
        hasTextOverflown,
        readMore: false,
      });
    }
  }

  render() {
    const { documentItem, documentType, serviceName, bookingStartDateTime } = this.props;
    return (
      <Card className='mb-small'>
        <Row>
          <div>
            <Col span={8}>
              <div className='flex-row align-start mb-small' key={documentItem.createdOn}>
                <span title={documentItem.documentName}>
                  <Text
                    color={
                      documentItem.status === 'SCANNING'
                        ? 'secondary'
                        : documentItem.status === 'SCANNED'
                        ? 'blue'
                        : 'red'
                    }
                  >
                    {documentItem.status === 'SCANNED' ? (
                      <HyperlinkButton
                        onClick={() =>
                          downloadFileFromUrl({
                            documentUrl: documentItem.documentUrl,
                            documentName: documentItem.documentName,
                          })
                        }
                      >
                        <Icon type={'download'} className='mr-x-small text-color-blue' />
                        {documentItem.documentName}
                      </HyperlinkButton>
                    ) : (
                      <>
                        <Tooltip2
                          content={
                            documentItem.status === 'SCANNING'
                              ? 'This document is being scanned.'
                              : 'This document failed to upload.'
                          }
                        >
                          <Icon
                            type='question-circle'
                            className={`text-size-x-large mr-x-small text-color-${
                              documentItem.status === 'SCANNING' ? 'blue' : 'red'
                            }`}
                          />
                        </Tooltip2>
                        {documentItem.documentName}
                      </>
                    )}
                  </Text>
                </span>
              </div>
            </Col>
          </div>
          <div>
            <Col span={14}>
              <div className='ml-x2-large width-full rounded'>
                <div className='mb-medium flex-row flex-wrap'>
                  {documentType === 'BOOKING' ? (
                    <div>
                      <TextTag
                        rounded={true}
                        color='tertiary'
                        content={
                          <>
                            <Icon type='calendar' className='mr-x-small' />
                            <Text size='regular' weight='bold'>
                              {serviceName} &nbsp;
                            </Text>
                            <Text size='regular' weight='regular' className='mr-small'>
                              @{' '}
                              {moment
                                .tz(
                                  documentItem.startDateTime ? documentItem.startDateTime : bookingStartDateTime,
                                  this.props.timezone,
                                )
                                .format('DD MMM YYYY, hh:mma')}
                            </Text>
                          </>
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <TextTag
                        rounded={true}
                        color='tertiary'
                        content={
                          <Text size='regular' weight='bold' className='mr-small'>
                            General
                          </Text>
                        }
                      />
                    </div>
                  )}
                  {documentItem.noteId && (
                    <div className='ml-small'>
                      <TextTag
                        rounded={true}
                        color='blue-lightest'
                        content={
                          <div>
                            <Text size='regular' weight='bold'>
                              Linked to note
                            </Text>
                          </div>
                        }
                      />
                    </div>
                  )}
                  {(documentItem.visibleType === NoteVisibleType.RESTRICTED_TO_SERVICE ||
                    documentItem.visibleType === NoteVisibleType.RESTRICTED_TO_SERVICE_PORTAL_AND_APP) && (
                    <div className='ml-small'>
                      <TextTag
                        rounded={true}
                        color='blue-lightest'
                        content={
                          <div>
                            <Icon theme='twoTone' twoToneColor='black' type='lock' className='mr-x-small' />
                            <Text size='regular' weight='bold'>
                              Restricted to service
                            </Text>
                          </div>
                        }
                      />
                    </div>
                  )}
                  {documentItem.visibleType === NoteVisibleType.PRIVATE_PORTAL_ONLY && (
                    <div className='ml-small'>
                      <TextTag
                        rounded={true}
                        color='blue-lightest'
                        content={
                          <div>
                            <Icon theme='twoTone' twoToneColor='black' type='lock' className='mr-x-small' />
                            <Text size='regular' weight='bold'>
                              Private to author
                            </Text>
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>

                {documentItem.noteDetail ? (
                  <div className='mt-large bg-tertiary rounded-big'>
                    <div className='p-medium'>
                      <Text size='small' color='secondary' lineHeight={100} className='mb-small'>
                        Notes
                      </Text>
                      <br />
                      <div className='mb-small'>
                        <div
                          ref={(ref) => (this.element = ref)}
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'pre-line',
                            height: this.state.readMore || !this.state.hasTextOverflown ? null : '3.6em',
                          }}
                          className='text-size-large line-height-120'
                        >
                          {documentItem.noteDetail.body}
                        </div>
                        {this.state.hasTextOverflown && (
                          <div
                            style={{ color: '#0083FF' }}
                            className='cursor-pointer mt-small'
                            onClick={() => this.setState({ readMore: !this.state.readMore })}
                          >
                            {this.state.readMore ? 'Read less' : 'Read more'}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    ref={(ref) => (this.element = ref)}
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'pre-line',
                    }}
                    className='text-size-large line-height-120'
                  >
                    {documentItem.description}
                  </div>
                )}

                {/* ADDED/UPDATED BY  */}
                <div className='mt-medium'>
                  {/* display updated by */}
                  {documentItem.noteDetail && documentItem.noteDetail.updatedByWorkerFirstName && (
                    <div className=' flex-row align-center'>
                      <Avatar
                        src={documentItem.noteDetail.updatedByWorkerImage}
                        alt='avatar'
                        size={15}
                        shape={'square'}
                      />
                      <Text size='regular' color='secondary' className='mh-small'>
                        Edited By &nbsp;
                        <b>
                          {documentItem.noteDetail.updatedByWorkerFirstName}{' '}
                          {documentItem.noteDetail.updatedByWorkerLastName}
                        </b>
                        &nbsp; on &nbsp;
                        <b>
                          {moment
                            .tz(documentItem.noteDetail.updatedOn, this.props.timezone)
                            .format('DD MMM YYYY, hh:mm A')}
                        </b>
                      </Text>
                    </div>
                  )}

                  {/* display added by */}
                  <div className='flex-row align-center'>
                    <Avatar
                      src={
                        documentItem.noteDetail ? documentItem.noteDetail.authorImage : documentItem.documentAuthorImage
                      }
                      alt='avatar'
                      size={15}
                      shape={'square'}
                    />
                    <Text size='regular' color='secondary' className='mh-small'>
                      Added By &nbsp;
                      <b>
                        {documentItem.noteDetail ? documentItem.noteDetail.firstName : documentItem.firstName}{' '}
                        {documentItem.noteDetail ? documentItem.noteDetail.lastName : documentItem.lastName}
                      </b>
                      &nbsp; on &nbsp;
                      <b>
                        {moment
                          .tz(
                            documentItem.noteDetail ? documentItem.noteDetail.createdOn : documentItem.createdOn,
                            this.props.timezone,
                          )
                          .format('DD MMM YYYY, hh:mm A')}
                      </b>
                    </Text>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={2}>
              <div className='flex justify-end' style={{ flex: 'auto' }}>
                {!this.props.isBookingArchived && (
                  <Popover2
                    content={this._getPopoverContent()}
                    popoverClassName={'mb-medium'}
                    position={'bottom'}
                    interactionKind='click'
                  >
                    <GhostButton icon={'ellipsis'} />
                  </Popover2>
                )}
              </div>
            </Col>
          </div>
        </Row>
      </Card>
    );
  }
}

export default DocumentCardItem;
