import React from 'react';
import { type RouteComponentProps } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { PageHeader } from '@good/ui/templates';
import { Bell, Users } from '@good/icons';
import { Stack, Card, Tabs } from '@good/ui/core';
import { Notifications } from './notifications';
import NotificationsLandingView from './NotificationsLandingView';
import { CustomerNotifications } from './customer-notifications';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import PermissionUtils from 'utilities/permission-utils';

type Props = RouteComponentProps;

const NotificationsView = (props: Props) => {
  const { roo285NotificationsV2: isNotificationsV2 } = useFlags();
  const portalUser = usePortalUser();
  const canViewCustomerNotifications =
    portalUser && PermissionUtils.validatePermission('ViewCustomerListing', portalUser.permissions.permissionRoles);

  return (
    <>
      <PageHeader title='Notifications' subtitle='All your notifications.' />
      <Tabs defaultValue='all'>
        <Tabs.List>
          <Tabs.Tab value='all' leftSection={<Bell />}>
            All
          </Tabs.Tab>
          {canViewCustomerNotifications && (
            <Tabs.Tab value='customer' leftSection={<Users />}>
              Customers
            </Tabs.Tab>
          )}
        </Tabs.List>

        <Tabs.Panel value='all' pt='xl'>
          {isNotificationsV2 ? <Notifications /> : <NotificationsLandingView {...props} />}
        </Tabs.Panel>

        {canViewCustomerNotifications && (
          <Tabs.Panel value='customer' pt='xl'>
            <Stack gap='xs'>
              <Card withBorder px='md' py='lg'>
                <CustomerNotifications />
              </Card>
            </Stack>
          </Tabs.Panel>
        )}
      </Tabs>
    </>
  );
};

export default NotificationsView;
