import React from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { Avatar, notification, Skeleton } from 'antd';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import moment, { Moment } from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { EditRatios, IEditRatiosSubscribe } from 'views/group-services/components/edit-ratios';

interface IEditCustomerScheduleModalProps {
  doUpdateGroupBookingScheduledStartEndTime: typeof dispatch.groupBookingsStore.doUpdateGroupBookingScheduledStartEndTime;
  doUpdateGroupBookingCheckInCheckOutTime: typeof dispatch.groupBookingsStore.doUpdateGroupBookingCheckInCheckOutTime;
  isOpen: boolean;
  onClose: any;
  booking: any;
  isEditSchedule: any;
}

interface IEditCustomerScheduleModalState {
  startDateTime: Date | null;
  endDateTime: Date | null;
  showDateError: boolean;
  isLoading: boolean;
  ratioForm: IEditRatiosSubscribe | undefined;
}

class EditCustomerScheduleModal extends React.Component<
  IEditCustomerScheduleModalProps,
  IEditCustomerScheduleModalState
> {
  constructor(props: IEditCustomerScheduleModalProps) {
    super(props);
    this.state = {
      startDateTime: null,
      endDateTime: null,
      showDateError: false,
      isLoading: false,
      ratioForm: undefined,
    };
  }

  private _resetState = () => {
    this.setState({
      startDateTime: null,
      endDateTime: null,
      showDateError: false,
    });
  };

  onCancel = () => {
    this.props.onClose();
  };

  onSave = async () => {
    this.setState({
      isLoading: true,
    });
    const { endDateTime, startDateTime, ratioForm } = this.state;
    const {
      booking,
      doUpdateGroupBookingScheduledStartEndTime,
      isEditSchedule,
      doUpdateGroupBookingCheckInCheckOutTime,
    } = this.props;
    if (moment.tz(endDateTime, booking.timezone).isSameOrBefore(moment.tz(startDateTime, booking.timezone))) {
      this.setState({
        showDateError: true,
      });
      this.setState({
        isLoading: false,
      });
    } else {
      try {
        if (isEditSchedule) {
          const ratio = ratioForm?.getRatio();
          await doUpdateGroupBookingScheduledStartEndTime({
            bookingId: booking.bookingId,
            startDateTime,
            endDateTime,
            ratio,
          });
        } else {
          await doUpdateGroupBookingCheckInCheckOutTime({
            serviceId: booking.serviceId,
            serviceDateTimeId: booking.serviceDateTimeId,
            attendanceId: booking.bookingId,
            checkInDateTime: startDateTime,
            checkOutDateTime: endDateTime,
            isRecalculateBilling: true,
          });
        }

        this.setState({
          isLoading: false,
        });
        notification.success({ message: 'Successfully changed booking scheduled start and end time' });
        this._resetState();
        this.props.onClose();
      } catch (e) {
        this.setState({
          isLoading: false,
        });
        notification.error({ message: 'Oops, something went wrong, please try again.' });
      }
    }
  };

  private _onChangeStartTime = (date: Date) => {
    const { booking } = this.props;
    let startDateTime = moment.tz(CommonUtils.formatCeilingDateTime(date), booking.timezone);
    const dateDifference = startDateTime.diff(this.state.startDateTime, 'minutes');

    this.setState({
      startDateTime: startDateTime.toDate(),
      endDateTime: moment.tz(this.state.endDateTime, booking.timezone).add(dateDifference, 'minutes').toDate(),
    });
  };

  private _onChangeEndTime = (date: Date) => {
    const { booking } = this.props;
    let endDateTime = moment.tz(CommonUtils.formatCeilingDateTime(date), booking.timezone);

    this.setState({
      endDateTime: endDateTime.toDate(),
    });
  };

  private _renderDuration = (startDateTime: Moment, endDateTime: Moment) => {
    return CommonUtils.formatDurationString(startDateTime, endDateTime);
  };

  private _updateStartDate = (event: Date) => {
    this.setState({
      startDateTime: event,
    });
  };

  private _updateEndDate = (event: Date) => {
    this.setState({ endDateTime: event });
  };

  private _updateTimes = () => {
    const { booking, isEditSchedule } = this.props;
    if (booking) {
      const toBeUpdatedStartDateTime = isEditSchedule ? booking.startDateTime : booking.customerCheckedInDateTime;
      const toBeUpdatedEndDateTime = isEditSchedule ? booking.endDateTime : booking.customerCheckedOutDateTime;
      this.setState({
        startDateTime: moment
          .tz(CommonUtils.formatCeilingDateTime(new Date(toBeUpdatedStartDateTime)), booking.timezone)
          .toDate(),
        endDateTime: moment
          .tz(CommonUtils.formatCeilingDateTime(new Date(toBeUpdatedEndDateTime)), booking.timezone)
          .toDate(),
      });
    }
  };

  override componentDidUpdate(prevProps: IEditCustomerScheduleModalProps) {
    const hasOpened = prevProps.isOpen !== this.props.isOpen;
    const hasBookingChanged = this.props.booking !== prevProps.booking;

    if (hasOpened || hasBookingChanged) {
      this._updateTimes();
    }
  }

  override render() {
    const { isOpen, booking, isEditSchedule } = this.props;

    if (booking) {
      const { startDateTime, endDateTime, timezone: displayTimezone } = booking;
      return (
        <ActionModal
          isOpen={isOpen}
          title={isEditSchedule ? "Edit customer's scheduled attendance" : 'Edit customers start/finish time'}
          width="large"
          onClose={this.onCancel}
        >
          {/* Modal label */}
          <section className="mb-large line-height-120">
            <Text lineHeight={120}>
              {isEditSchedule
                ? "Edit this customer's scheduled attendance for this booking."
                : 'Modify the customers start and finish time.'}
            </Text>
          </section>

          {/* Customer display */}
          <section className="mb-large line-height-120">
            <FieldLabel text={'CUSTOMER'} />
            <div className="mt-x2-small flex-row">
              <Avatar src={booking.attachmentUrl} icon="user" className="mr-small" />
              <Text>
                {booking.firstName} {booking.lastName}
              </Text>
            </div>
          </section>

          <section className="mb-large line-height-150">
            <FieldLabel text={'SCHEDULED BOOKING TIME'} />
            {!isEditSchedule && <Text color="secondary">This booking was scheduled for:</Text>}
            <div />
            <div>
              {moment.tz(startDateTime, displayTimezone).isSame(moment.tz(endDateTime, displayTimezone), 'day') ? (
                <>
                  <Text weight="bold">{moment.tz(startDateTime, displayTimezone).format('dddd, DD MMM YYYY')}</Text>
                  <br />
                  <div className="mb-x-small">
                    <Text className="text-color-secondary" size="large">
                      {moment.tz(startDateTime, displayTimezone).format('LT')} -{' '}
                      {moment.tz(endDateTime, displayTimezone).format('LT')}
                      &nbsp;(
                      {this._renderDuration(
                        moment.tz(startDateTime, displayTimezone),
                        moment.tz(endDateTime, displayTimezone),
                      )}
                      )
                    </Text>
                  </div>
                </>
              ) : (
                <>
                  <Text>
                    {moment.tz(startDateTime, displayTimezone).format('dddd, DD MMM YYYY')} -{' '}
                    <Text className="text-color-secondary">
                      {moment.tz(startDateTime, displayTimezone).format('LT')}
                    </Text>
                  </Text>
                  <br />
                  <Text>
                    {moment.tz(endDateTime, displayTimezone).format('dddd, DD MMM YYYY')} -{' '}
                    <Text className="text-color-secondary">{moment.tz(endDateTime, displayTimezone).format('LT')}</Text>
                  </Text>
                  <br />
                  <div className="mb-x-small">
                    <Text className="text-color-secondary">
                      {moment.tz(endDateTime, displayTimezone).diff(moment.tz(startDateTime, displayTimezone), 'hours')}{' '}
                      hour
                    </Text>
                  </div>
                </>
              )}
            </div>
          </section>

          <section className="mb-large">
            <FieldLabel text={isEditSchedule ? 'NEW BOOKING TIME' : 'Start/finish time'} />
            <div className="mt-small line-height-120 bg-quaternary p-medium bordered border-standard-gray rounded-big">
              <div className="flex-row">
                <div className="mr-large flex-1">
                  <FieldLabel text={'START TIME'} />
                  <div className="mt-x2-small flex-row">
                    <DatePicker
                      dateFormat="d/M/yyyy"
                      className="gh-datepicker mr-small rounded"
                      calendarClassName="gh-datepicker-calendar"
                      placeholderText="Start date"
                      onChange={this._updateStartDate}
                      selected={this.state.startDateTime}
                    />
                    <TimeInput
                      size="large"
                      className="bg-white"
                      value={moment.tz(this.state.startDateTime, displayTimezone)}
                      onChange={this._onChangeStartTime}
                    />
                  </div>
                </div>

                <div className="flex-1">
                  <FieldLabel text={'END TIME'} />
                  <div className="mt-x2-small flex-row">
                    <DatePicker
                      dateFormat="d/M/yyyy"
                      className="gh-datepicker mr-small rounded"
                      calendarClassName="gh-datepicker-calendar"
                      placeholderText="End date"
                      onChange={this._updateEndDate}
                      selected={this.state.endDateTime}
                    />
                    <TimeInput
                      size="large"
                      className="bg-white"
                      value={moment.tz(this.state.endDateTime, displayTimezone)}
                      onChange={this._onChangeEndTime}
                    />
                  </div>
                  {this.state.showDateError && <Text color="red">End time must be after start time</Text>}
                </div>
              </div>
            </div>
          </section>

          {isEditSchedule && (
            <section>
              <EditRatios
                type="edit"
                sessionTimes={{
                  startDateTime: this.state.startDateTime ?? new Date(),
                  endDateTime: this.state.endDateTime ?? new Date(),
                  timezone: booking.timezone,
                }}
                subscribe={(ratioForm) => this.setState({ ratioForm })}
                groupBookingId={booking.bookingId}
              />
            </section>
          )}

          <ActionModalFooter align="right">
            <SecondaryButton size="large" className="mr-medium" onClick={this.onCancel} disabled={this.state.isLoading}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size="large"
              className="mr-medium"
              onClick={this.onSave}
              disabled={this.state.isLoading || this.state.ratioForm?.hasValidationError}
            >
              Save
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      );
    } else {
      return (
        <ActionModal
          isOpen={isOpen}
          title={isEditSchedule ? "Edit customer's scheduled attendance" : 'Edit customers start/finish time'}
          width="large"
          onClose={this.onCancel}
        >
          <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className="anim-slide-left" />
        </ActionModal>
      );
    }
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateGroupBookingScheduledStartEndTime: dispatch.groupBookingsStore.doUpdateGroupBookingScheduledStartEndTime,
  doUpdateGroupBookingCheckInCheckOutTime: dispatch.groupBookingsStore.doUpdateGroupBookingCheckInCheckOutTime,
});

export default connect(null, mapDispatch)(EditCustomerScheduleModal);
