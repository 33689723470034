import { Divider, Icon } from 'antd';
import { Popover } from 'antd/es';
import { HyperlinkButton } from 'common-components/buttons';
import { Paragraph, Text } from 'common-components/typography';
import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { LogoShape } from '../../utils/constants';

interface IProps {
  shape: LogoShape;
  previewable: boolean;
  onPreviewInvoice(): void;
}

const UploadLogoDescriptionSection = (props: IProps) => {
  const { onPreviewInvoice, previewable, shape } = props;

  const isSquareLogo = shape === LogoShape.SQUARE_LOGO;

  if (isSquareLogo) {
    return (
      <>
        <Paragraph>
          A simplified icon to represent your brand, displayed in a square. We don’t use this logo format just yet, but
          you can upload it now so that you’re good to go when we launch the GoodHuman customer app.
        </Paragraph>
        <ul className="ph-large">
          <li>
            May be displayed very small, so use a symbol or simplified shape that represents your brand (not your full
            logo or workmark)
          </li>
          <li>
            Position your icon in the center of the box, leaving as little padding as possible without cropping your
            icon
          </li>
          <li>For best results upload an image with transparency</li>
        </ul>
        <Paragraph color="secondary" size="x-small" className="mb-none">
          PNG, GIF, JPG or BMP. Square (1:1 aspect ratio) at least 400 x 400 pixels. Maximum 5 MB.
        </Paragraph>
      </>
    );
  }
  return (
    <>
      <Paragraph>Uploading a new logo will replace your current one across the entire GoodHuman platform.</Paragraph>
      <ul className="ph-large">
        <li>Horizontal and rectangular logos or wordmarks with an aspect ratio of 16:9 tend to work best</li>
        <li>For best results upload an image with a transparent or white background</li>
      </ul>
      <Paragraph color="secondary" size="x-small" className="mb-none">
        PNG, GIF, JPG or BMP. Minimum width 800 pixels. Maximum 10 MB.
      </Paragraph>
      <div style={{ width: 100 }}>
        <Divider className="bg-text-tertiary" />
      </div>
      <HyperlinkButton
        className="flex align-center"
        color={previewable ? 'blue-action' : 'secondary'}
        hoverUnderline={previewable}
        {...(previewable && { onClick: onPreviewInvoice })}
      >
        <FaExternalLinkAlt className="text-size-large mr-small" />
        <Text color={previewable ? 'blue-action' : 'secondary'} weight="bolder" size="regular">
          Preview an invoice with your logo
        </Text>
        {!previewable && (
          <Popover
            content={
              <Paragraph className="mv-none" style={{ width: 323 }}>
                Upload your company logo to preview a sample invoice.
              </Paragraph>
            }
            placement="bottomLeft"
          >
            <Icon type="question-circle" className="font-size-small ml-small" />
          </Popover>
        )}
      </HyperlinkButton>
    </>
  );
};

export default UploadLogoDescriptionSection;
