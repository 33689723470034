import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Heart = forwardRef(function Heart(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.438 6.975a3.969 3.969 0 1 0-5.676 5.55l7.1 7.26a.194.194 0 0 0 .277 0l7.1-7.26a3.97 3.97 0 0 0-5.677-5.55l-1.025 1.05a.75.75 0 0 1-1.073 0l-1.026-1.05ZM3.69 5.926a5.469 5.469 0 0 1 7.82 0l.49.501.49-.5a5.469 5.469 0 0 1 7.82 7.646l-7.099 7.26c-.664.68-1.758.68-2.422 0l-7.1-7.26a5.469 5.469 0 0 1 0-7.647Z"
        fill="currentColor"
      />
    </Icon>
  );
});
