import { Col, Icon, Tooltip } from 'antd';
import React from 'react';
import { TeamStatus } from 'utilities/enum-utils';
import { Text } from '@good/components';
import { Paragraph } from 'common-components/typography';
import moment from 'moment';

type TmMgmtStatusColumnParams = {
  status: TeamStatus;
  removedOn?: Date;
};

export const TmMgmtStatusColumn = ({ status, removedOn }: TmMgmtStatusColumnParams) => {
  const textColor = `text-color-${
    status !== TeamStatus.ENABLED
      ? status !== TeamStatus.BLOCKED
        ? status !== TeamStatus.DRAFT
          ? 'red-dark'
          : 'tertiary'
        : 'black'
      : 'green'
  }`;

  return (
    <Col span={3}>
      <Text className={textColor}>{status.toUpperCase()}</Text>
      {removedOn && status === TeamStatus.ENABLED && (
        <Tooltip
          title={
            <Paragraph>
              This team member is scheduled to be removed on{' '}
              <Text className='font-bold'>{moment(removedOn).format('DD/MM/YYYY')}</Text>
            </Paragraph>
          }
          overlayClassName='white-tooltip'
        >
          <Icon type='exclamation-circle' className='ml-small text-color-warning-orange' theme='filled' />
        </Tooltip>
      )}
    </Col>
  );
};
