import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Avatar, notification } from 'antd';
import { Paragraph, FieldLabel, Text } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import moment from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../stores/rematch/root-store';
import { TransportAttendanceType } from '../../../../utilities/enum-utils';

interface StartGroupTransportBookingModalProps {
  isOpen: any;
  onClose: any;
  booking: any;
  doStartGroupTransportBooking: typeof dispatch.groupBookingsStore.doStartGroupTransportBooking;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  attendanceType: TransportAttendanceType;
}
interface StartGroupTransportBookingModalState {
  step: number;
  canManuallyClose: boolean;
  title: string;
  startDateTime: Date;
}

class StartGroupTransportBookingModal extends Component<
  StartGroupTransportBookingModalProps,
  StartGroupTransportBookingModalState
> {
  state = { step: 1, canManuallyClose: true, title: 'Start transport booking', startDateTime: null };

  private _updateStartDate = (event) => {
    this.setState({
      startDateTime: event,
    });
  };

  private _onChangeStartTime = async (date) => {
    const { booking } = this.props;
    const startDateTime = moment.tz(CommonUtils.formatCeilingDateTime(date), booking.timezone);

    this.setState({
      startDateTime: startDateTime.toDate(),
    });
  };

  renderView = () => {
    const { step } = this.state;
    const { selectedGroupBookingItem } = this.props;
    const { firstName, lastName, attachmentUrl, timezone } = selectedGroupBookingItem;
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>Please input the date/time this transport booking started.</Paragraph>

          <Paragraph>This time will be used for thr calculation of the billing for this transport booking</Paragraph>

          <div className="mb-medium">
            <div className="flex-row align-center">
              <Avatar src={attachmentUrl} icon="user" className="mr-small" />
              <Text>{`${firstName} ${lastName}`}</Text>
            </div>
          </div>

          <div className="mt-small line-height-120 bg-quaternary p-medium bordered border-standard-gray rounded-big">
            <div className="flex-row">
              <div className="flex-1 mr-large">
                <div className="flex-row mt-x2-small">
                  <div>
                    <FieldLabel text={'start date'} />
                    <DatePicker
                      dateFormat="d/M/yyyy"
                      className="gh-datepicker rounded mr-small"
                      calendarClassName="gh-datepicker-calendar"
                      placeholderText="Start date"
                      onChange={this._updateStartDate}
                      selected={this.state.startDateTime}
                    />
                  </div>
                  <div>
                    <FieldLabel text={'customer start time'} />
                    <TimeInput
                      size="large"
                      className="bg-white"
                      value={moment.tz(this.state.startDateTime, timezone)}
                      onChange={this._onChangeStartTime}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this.onAccept}>
              Start booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>
            You have started{' '}
            <b>
              {firstName} {lastName}
            </b>{' '}
            booking for this session
          </Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen && this.props.booking) {
      const { booking } = this.props;
      this.setState({
        step: 1,
        title: 'Transport booking started',
        startDateTime: moment
          .tz(CommonUtils.formatCeilingDateTime(new Date(booking.startDateTime)), booking.timezone)
          .toDate(),
      });
    }
  }

  onAccept = async () => {
    const { booking, selectedGroupBookingItem, attendanceType } = this.props;
    const { startDateTime } = this.state;
    try {
      this.setState({ canManuallyClose: false });
      await this.props.doStartGroupTransportBooking({
        bookingId: selectedGroupBookingItem.bookingId,
        transportBookingId: booking.attendanceId,
        startDateTime: startDateTime ? startDateTime : booking.startDateTime,
        attendanceType,
      });
      this.setState({ step: 2, canManuallyClose: true, title: 'Booking started' });
    } catch (e) {
      this.setState({ canManuallyClose: true });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: 'Start transport booking', startDateTime: null }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doStartGroupTransportBooking: dispatch.groupBookingsStore.doStartGroupTransportBooking,
});

export default connect(mapState, mapDispatch)(StartGroupTransportBookingModal);
