import { ServiceAgreement } from '../types';

export const formatTableData = (serviceAgreements: ServiceAgreement[]) => {
  const tableData = serviceAgreements.map((serviceAgreement) => {
    return {
      userServiceAgreementId: serviceAgreement.userServiceAgreementId,
      name: serviceAgreement.name,
      signedStatus: serviceAgreement.signedStatus,
      startDate: serviceAgreement.startDate,
      endDate: serviceAgreement.endDate,
      createdBy: '-',
      lastModified: '2022-09-29T00:00:00.000Z', // Use fake dates for now,
      subRows: serviceAgreement.UserServiceAgreementServices.map((service) => {
        return {
          userServiceAgreementServiceId: service.userServiceAgreementServiceId,
          name: 'Service Name',
          startDate: service.startDate,
          endDate: service.endDate,
          signedStatus: 'DRAFT',
          createdBy: '-', // need the user.attachmentUrl here for the avatar
          lastModified: '2022-09-25T00:00:00.000Z', // need to get the last modified date returned here, should always be one
        };
      }),
    };
  });

  return tableData;
};
