import React, { useMemo } from 'react';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { notifications } from '@good/ui/notifications';

import { SilServiceContextResolved, useInlineForm, useSilServiceContext } from 'views/sil-service/components';
import { useUpdateService } from 'views/sil-service/hooks';

const maxInputWidth = 375;

const startFinishTimeThresholdOptions = [0, 5, 10, 15, 20, 25, 30];

export enum DraftShiftReminder {
  '24 hours before' = '24_HOURS',
}

export const ShiftSettingsForm = () => {
  const { service } = useSilServiceContext() as SilServiceContextResolved;
  const updateService = useUpdateService();
  const { t } = useTranslation('', { keyPrefix: 'silService.settingsTabs.general.shiftSettingsForm' });
  const { t: tMessages } = useTranslation('', { keyPrefix: 'silService.messages' });

  const schema = useMemo(
    () =>
      z.object({
        startFinishTimeThreshold: z.string().refine((val) => startFinishTimeThresholdOptions.includes(Number(val)), {
          message: t('fields.startFinishTimeThreshold.validation.invalidType'),
        }),
        draftShiftReminder: z.nativeEnum(DraftShiftReminder, {
          required_error: t('fields.draftShiftReminder.validation.required'),
          invalid_type_error: t('fields.draftShiftReminder.validation.invalidType'),
        }),
        shiftTimeModification: z.boolean(),
      }),
    [t],
  );
  const InlineForm = useInlineForm<z.infer<typeof schema>>({
    values: {
      startFinishTimeThreshold: service.workerTimeLeeway.toString(),
      draftShiftReminder: DraftShiftReminder['24 hours before'],
      shiftTimeModification: service.canWorkerEditCheckInCheckOutTime,
    },
    resolver: zodResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    try {
      await updateService.mutateAsync({
        serviceId: service.serviceId,
        serviceProviderId: service.serviceProviderId,
        data: {
          workerTimeLeeway: Number(values.startFinishTimeThreshold),
          // TODO: hook this up when the API is ready
          // draftShiftReminder: values.draftShiftReminder,
          canWorkerEditCheckInCheckOutTime: values.shiftTimeModification,
        },
      });
    } catch (e) {
      notifications.show({
        color: 'var(--color-red-main)',
        message: tMessages('updateService.error'),
      });
      throw e;
    }
    notifications.show({
      color: 'var(--color-green-main)',
      message: tMessages('updateService.success'),
    });
  };

  return (
    <InlineForm title={t('title')} onSubmit={onSubmit} timezone={service.timezone}>
      <InlineForm.SelectField
        name='startFinishTimeThreshold'
        label={t('fields.startFinishTimeThreshold.label')}
        staticLabel={t('fields.startFinishTimeThreshold.staticLabel')}
        description={t('fields.startFinishTimeThreshold.description')}
        placeholder={t('fields.startFinishTimeThreshold.placeholder')}
        data={startFinishTimeThresholdOptions.map((numMinutes) => ({
          value: numMinutes.toString(),
          label: `${numMinutes} mins`,
        }))}
        allowDeselect={false}
        maxInputWidth={maxInputWidth}
      />
      <InlineForm.SelectField
        name='draftShiftReminder'
        label={t('fields.draftShiftReminder.label')}
        staticLabel={t('fields.draftShiftReminder.staticLabel')}
        description={t('fields.draftShiftReminder.description')}
        placeholder={t('fields.draftShiftReminder.placeholder')}
        data={Object.entries(DraftShiftReminder).map(([label, value]) => ({ value, label }))}
        allowDeselect={false}
        maxInputWidth={maxInputWidth}
      />
      <InlineForm.SwitchField
        name='shiftTimeModification'
        label={t('fields.shiftTimeModification.label')}
        staticLabel={t('fields.shiftTimeModification.staticLabel')}
        description={t('fields.shiftTimeModification.description')}
        switchOptions={{
          label: t('fields.shiftTimeModification.switchOptions.label'),
          checkedValue: t('fields.shiftTimeModification.switchOptions.checkedValue'),
          uncheckedValue: t('fields.shiftTimeModification.switchOptions.uncheckedValue'),
        }}
      />
    </InlineForm>
  );
};
