import React, { useEffect } from 'react';
import { Check, ChevronDown } from '@good/icons';

import { useRichTextEditor } from '../rich-text-editor-context';
import { Button, Menu, rem, Text } from '@good/ui/core';

export const FontSizeDropdown = () => {
  const { editor, disabled, fontSizes } = useRichTextEditor();

  const activeEditorSize = fontSizes.find((fontSize) => editor.isActive('textStyle', { fontSize: `${fontSize}px` }));
  const isDisabled =
    disabled ||
    !editor
      .can()
      .chain()
      .focus()
      .setFontSize(`${activeEditorSize ?? fontSizes[0] ?? 14}px`)
      .run();

  useEffect(() => {
    editor
      .chain()
      .focus()
      .setFontSize(`${fontSizes[0] ?? 14}px`)
      .run();
  }, [editor, fontSizes]);

  const onChange = (fontSize: number) => {
    editor.chain().focus().setFontSize(`${fontSize}px`).run();
  };

  return (
    <Menu shadow='md' width={200} position='bottom-start'>
      <Menu.Target>
        <Button
          size='xs'
          variant='outline'
          color='neutral'
          aria-label='font-size'
          disabled={isDisabled}
          leftSection={
            <Text size='xs' fw={700} w={rem(30)} pl={rem(8)}>
              {activeEditorSize ?? '-'}
            </Text>
          }
          rightSection={
            <Text size='xs' c='var(--brand-body-dark-2)' w={rem(30)} pr={rem(8)}>
              <ChevronDown />
            </Text>
          }
          w={80}
        />
      </Menu.Target>
      <Menu.Dropdown>
        {fontSizes.map((fontSize) => (
          <Menu.Item
            key={fontSize}
            onClick={() => onChange(fontSize)}
            leftSection={
              activeEditorSize === fontSize ? (
                <Text size='xs' c='var(--brand-body-dark-2)'>
                  <Check />
                </Text>
              ) : undefined
            }
          >
            {fontSize}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
