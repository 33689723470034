import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const GlassWine = forwardRef(function GlassWine(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.222 2.25c-1.089 0-1.972.883-1.972 1.972V6a8.75 8.75 0 0 0 8 8.718v5.532H8a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5h-3.25v-5.532a8.75 8.75 0 0 0 8-8.718V4.222c0-1.089-.883-1.972-1.972-1.972H5.222ZM4.75 4.222c0-.26.212-.472.472-.472h13.556c.26 0 .472.211.472.472V6a7.25 7.25 0 1 1-14.5 0V4.222Z"
        fill="currentColor"
      />
    </Icon>
  );
});
