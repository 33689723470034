import React, { Component } from 'react';
import { Paragraph } from 'common-components/typography';
import ActionModal from 'common-components/modal/ActionModal';
import { PrimaryButton } from 'common-components/buttons';
import { Col, Row } from 'antd';

interface ISessionFullModalProps {
  isOpen: boolean;
  history: any;
  onClose: () => void;
}

interface ISessionFullModalState {}

class SessionFullModal extends Component<ISessionFullModalProps, ISessionFullModalState> {
  state = {};

  private _onCloseModal = () => {
    this.props.onClose();
  };

  render() {
    return (
      <>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._onCloseModal}
          title={'Session at full capacity'}
          showCloseButton={true}
        >
          <Paragraph>This session is at full capacity and no additional customers can be added.</Paragraph>
          <div className={'mb-small'}>
            <Row type={'flex'} justify={'end'}>
              <Col>
                <PrimaryButton onClick={this._onCloseModal} size={'large'}>
                  Close
                </PrimaryButton>
              </Col>
            </Row>
          </div>
        </ActionModal>
      </>
    );
  }
}

export default SessionFullModal;
