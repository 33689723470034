import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { capitalize } from 'lodash';

/**
 * Custom hook that updates the page title based on the current location and attaches a suffix.
 * @returns The updated page title without the suffix
 */
export function useUpdatePageTitle(suffix?: string): string {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState<string>('');

  const updatePageTitle = useCallback(() => {
    const title = location.pathname
      .replace(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi, '')
      .replace(/[-]/g, ' ')
      .split('/')
      .slice(1)
      .filter((section) => section.trim() !== '')
      .map((section) => capitalize(section))
      .join(' | ');
    document.title = [title, suffix].filter(Boolean).join(' | ');
    setPageTitle(title);
  }, [location, suffix]);

  useEffect(() => {
    updatePageTitle();
  }, [updatePageTitle, location]);

  return pageTitle;
}
