import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { Input, notification } from 'antd';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';

interface IExportSettingsNDIADebtorIdModalProps {
  isOpen: any;
  onClose: () => void;
  doUpdateNDIADebtorId: typeof dispatch.accountStore.doUpdateNDIADebtorId;
  onUpdateNDIADebtorId: (newNdiaDebtorId) => void;
  ndiaDebtorId: string;
}

interface IExportSettingsNDIADebtorIdModalState {
  isLoading: boolean;
  newNdiaDebtorId: string;
}

class ExportSettingsNDIADebtorIdModal extends Component<
  IExportSettingsNDIADebtorIdModalProps,
  IExportSettingsNDIADebtorIdModalState
> {
  state = {
    isLoading: false,
    newNdiaDebtorId: null,
  };

  componentDidUpdate = async (prevProps: Readonly<IExportSettingsNDIADebtorIdModalProps>) => {
    const { ndiaDebtorId } = this.props;
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ newNdiaDebtorId: ndiaDebtorId });
    }
  };

  private _onCloseModal = () => {
    this.setState({ isLoading: false });
    this.props.onClose();
  };

  private _onChangeNdiaDebtorId = (e) => {
    this.setState({ newNdiaDebtorId: e.target.value });
  };

  private _onSave = async () => {
    const { newNdiaDebtorId } = this.state;
    const { doUpdateNDIADebtorId, onUpdateNDIADebtorId } = this.props;
    this.setState({ isLoading: true });
    try {
      const result = await doUpdateNDIADebtorId({ ndiaDebtorInfo: newNdiaDebtorId });
      if (result) {
        onUpdateNDIADebtorId(newNdiaDebtorId);
        this._onCloseModal();
      }
    } catch (error) {
      if (error.meta.message === 'The provided debtor number already exists') {
        notification.error({ message: 'This debtor ID already exists' });
      } else {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isOpen } = this.props;
    const { isLoading, newNdiaDebtorId } = this.state;

    return (
      <ActionModal title={`Configure NDIA debtor ID`} isOpen={isOpen} onClose={this._onCloseModal} width="small">
        <div>
          <Text>
            This debtor ID will appear in finance exports. Ensure NDIA-managed line items are correctly imported to your
            finance system by configuring the ID below.
          </Text>

          <div className="mt-medium">
            <SubTitle>NDIA Debtor id</SubTitle>
            <Input
              size={'large'}
              style={{ width: '300px' }}
              value={newNdiaDebtorId}
              onChange={this._onChangeNdiaDebtorId}
              placeholder={`Enter ID`}
              maxLength={50}
            />
          </div>
        </div>
        <ActionModalFooter>
          <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal} disabled={isLoading}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._onSave} loading={isLoading}>
            Save
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateNDIADebtorId: dispatch.accountStore.doUpdateNDIADebtorId,
});

export default connect(mapState, mapDispatch)(ExportSettingsNDIADebtorIdModal);
