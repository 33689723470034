// Inform that target user is owner or primary owner
import React from 'react';
import { Information } from 'common-components/alerts';

export function TargetUserIsOwnerRow({ isTargetUserPrimaryOwner, isPrimaryOwner }) {
  return (
    <Information
      content={`This user is a${!isTargetUserPrimaryOwner ? 'n' : ''} ${
        isTargetUserPrimaryOwner ? 'primary owner' : 'owner'
      }, ${
        isPrimaryOwner
          ? isTargetUserPrimaryOwner
            ? 'You cannot change your permissions.'
            : " if you want to edit the permissions, please change the user's role to Team member."
          : ' their permissions can only be edited by the primary owner.'
      }`}
    />
  );
}
