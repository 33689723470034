import React from 'react';
import DatePicker from 'react-datepicker';
import { Icon } from 'antd';

interface IDateTimePickerProps {
  selected?: Date;
  placeholderText?: string;
  onChange: (date: Date | null, event: React.SyntheticEvent<unknown> | undefined) => void;
}

const DateTimePicker = ({ selected, placeholderText, onChange }: IDateTimePickerProps) => {
  return (
    <span className="position-relative">
      <DatePicker
        className="gh-datepicker rounded"
        calendarClassName="gh-datepicker-calendar"
        dateFormat="d/M/yyyy"
        onChange={onChange}
        placeholderText={placeholderText}
        isClearable={true}
        selected={selected}
      />
      {!selected ? (
        <span className="position-absolute" style={{ top: 6, right: -4 }}>
          <Icon type="calendar" className="ant-calendar-picker-icon" />
        </span>
      ) : null}
    </span>
  );
};

export default DateTimePicker;
