import { useCallback } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

import { IGroupServiceCustomerRatio } from 'interfaces/service-interfaces';
import { convertToDate } from 'utilities/date-utils';
import { useInitialiseForm } from './use-initialise-form';
import { useValidate } from './use-validate';
import { getDurationAndUnit } from './get-duration-and-unit';
import { ISessionTimesParsed } from '../types';
import { EditRatiosSchema } from '../schema';

export function useRatioForm(
  form: UseFormReturn<EditRatiosSchema>,
  initialRatios: IGroupServiceCustomerRatio | null,
  sessionTimes: ISessionTimesParsed | null,
) {
  const {
    fields: ratios,
    append,
    remove: removeForm,
  } = useFieldArray({
    control: form.control,
    name: 'ratios',
  });

  const hasValidationError = useValidate(form, sessionTimes);
  useInitialiseForm(form, append, removeForm, initialRatios, sessionTimes);

  const add = useCallback(() => {
    const startDateTime =
      ratios?.at(-1)?.customRatio?.endDateTime ?? convertToDate(sessionTimes?.startDateTime ?? new Date());
    const endDateTime = convertToDate(sessionTimes?.endDateTime ?? new Date());
    const { duration, durationUnit } = getDurationAndUnit(startDateTime, endDateTime);

    append({
      isExactTime: true,
      teamMemberCustomerRatio: form.getValues('teamMemberCustomerRatio'),
      customRatio: {
        startDateTime,
        endDateTime,
      },
      durationRatio: {
        duration,
        unit: durationUnit,
      },
    });
  }, [sessionTimes, ratios, form]);

  const remove = useCallback(
    (index: number) => {
      removeForm(index);
    },
    [form],
  );

  return { ratios, add, remove, hasValidationError };
}
