import React, { Component } from 'react';
import ActionModal from '../modal/ActionModal';
import { Spinner } from '@blueprintjs/core';
import { Paragraph } from '../typography';

interface ISpinningLoadingActionModelProps {
  isOpen: boolean;
  message?: string;
  verticalAlignment?: 'highest' | 'high' | 'center' | 'low' | 'lowest' | string;
}

class SpinningLoadingActionModel extends Component<ISpinningLoadingActionModelProps, any> {
  render() {
    const { isOpen, message = 'Loading .....', verticalAlignment = 'center' } = this.props;
    return (
      <ActionModal
        canCloseOutside={false}
        verticalAlignment={verticalAlignment}
        showHeader={true}
        isOpen={isOpen}
        showCloseButton={false}
        width={'small'}
      >
        <div style={{ minHeight: 300 }} className="flex-column justify-center align-center">
          <div className="text-align-center">
            <div className="pv-medium">
              <Spinner size={120} intent={'primary'} />
            </div>

            <div className="mb-medium">
              <Paragraph>{message}</Paragraph>
            </div>
          </div>
        </div>
      </ActionModal>
    );
  }
}

export default SpinningLoadingActionModel;
