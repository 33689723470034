import React, { useState } from 'react';
import { getStorage } from 'firebase/storage';
import { FirebaseError } from 'firebase/app';
import { Group, Text, Progress, CloseButton, Button, Flex, rem, Box } from '@good/ui/core';
import { Dropzone, FileWithPath } from '@good/ui/dropzone';
import { notifications } from '@good/ui/notifications';
import { Check, Cross } from '@good/icons';

import { useNewRelicBrowser } from 'integrations/new-relic-browser';
import { useHandleUploadImage } from './use-handle-upload-image';
import { Thumbnail } from './thumbnail';
import { DropzoneContent } from './dropzone-content';

type ImageDropzoneProps = {
  disabled?: boolean;
  imageUploadUrl: string;
  onChange: (value: string[]) => void;
  onUploadFinish?: (status: 'success' | 'error') => void;
  onUploadStart?: () => void;
  value: string[];
};

export const ImageDropzone = ({
  disabled,
  imageUploadUrl,
  onUploadStart,
  onUploadFinish,
  onChange,
  value,
}: ImageDropzoneProps): JSX.Element => {
  const [storage] = useState(() => getStorage());
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [imageName, setImageName] = useState<string>('');
  const [imageSrc, setImageSrc] = useState(value.length ? value : '');
  const newRelic = useNewRelicBrowser();
  const [cancelUploadAction, setCancelUploadAction] = useState<(() => void) | undefined>(undefined);
  const handleUploadImage = useHandleUploadImage(imageUploadUrl, storage, {
    onUploadStart: (file) => {
      onUploadStart?.();
      setIsUploading(true);
      setProgress(0);
      setImageName(file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
      };
    },
    onProgressChanged: (progress) => {
      setProgress(progress);
    },
    onUploadError: (error, location) => {
      if (error instanceof Error || typeof error === 'string') {
        if (error instanceof FirebaseError && error.message.includes('storage/canceled')) {
          notifications.show({
            color: 'yellow',
            message: 'Upload cancelled',
          });
        } else {
          newRelic?.noticeError(error, { location });
          notifications.show({
            color: 'red',
            message: `There was an issue with uploading your file.`,
          });
        }
      } else {
        newRelic?.noticeError(new Error('Unknown error type'), { location });
        notifications.show({
          color: 'red',
          message: `There was an issue with uploading your file.`,
        });
      }

      setProgress(100);
      setIsUploading(false);
      setCancelUploadAction(undefined);
      onUploadFinish?.('error');
    },
    onUploadSuccess: (downloadURL) => {
      notifications.show({ color: 'var(--color-green-ref)', message: 'Image uploaded successfully' });
      setProgress(100);
      onChange([downloadURL]);
      setIsUploading(false);
      setCancelUploadAction(undefined);
      onUploadFinish?.('success');
    },
  });

  const onDrop = (files: FileWithPath[]) => {
    const uploadTask = handleUploadImage(files);
    if (uploadTask) {
      setCancelUploadAction(() => () => uploadTask.cancel());
    }
  };

  const onReject = () => {
    notifications.show({
      color: 'red',
      message: 'File not supported.',
    });
  };

  if (isUploading) {
    return (
      <div className='w-full flex gap-4 align-center p-4'>
        <Thumbnail src={imageSrc} />
        <div className='flex flex-col w-full gap-1'>
          <div className='flex flex-row justify-between'>
            <Text size='sm'>{imageName}</Text>
            <CloseButton onClick={cancelUploadAction} />
          </div>
          <div className='flex flex-col gap-1 w-full'>
            <Text c='var(--brand-body-dark-1)' size='sm'>
              {progress}%
            </Text>
            <Progress size='md' color='var(--color-lavender-ref)' value={progress} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Dropzone
      onDrop={onDrop}
      onReject={onReject}
      maxSize={1 * 2644 * 1983}
      accept={['image/jpeg', 'image/png']}
      tabIndex={-1}
      disabled={disabled}
      className='rounded-2xl focus:outline-ocean'
    >
      <Group mih={220} style={{ pointerEvents: 'none' }} className='flex flex-col p-8 justify-center'>
        <Dropzone.Accept>
          <Flex direction='column' align='center' gap={rem(24)}>
            <DropzoneContent title='Drop image' />
            <Box h={rem(32)}>
              <Text c='var(--color-green-ref)'>
                <Check />
              </Text>
            </Box>
          </Flex>
        </Dropzone.Accept>
        <Dropzone.Reject>
          <Flex direction='column' align='center' gap={rem(24)}>
            <DropzoneContent title='Invalid file' />
            <Box h={rem(32)}>
              <Text c='red'>
                <Cross />
              </Text>
            </Box>
          </Flex>
        </Dropzone.Reject>
        <Dropzone.Idle>
          <Flex direction='column' align='center' gap={rem(24)}>
            <DropzoneContent />
            <Button size='sm' variant='outline'>
              Upload image
            </Button>
          </Flex>
        </Dropzone.Idle>
      </Group>
    </Dropzone>
  );
};
