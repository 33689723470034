import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { Divider } from 'antd';
import { connect } from 'react-redux';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

import { Text } from 'common-components/typography';
import { ReactComponent as EmptyFormImage } from 'assets/Icons/form-builder-icons/empty-form.svg';

import {
  Header,
  MultiChoice,
  InlineEditInput,
  Dropdown,
  ParagraphElement,
  LongText,
  SingleChoiceElement,
  CheckboxElement,
  CurrencyInput,
  NumberInputElement,
  DateTimeElement,
  AttachmentInput,
  AddressLookup,
  PhoneNumber,
  FullName,
} from './form-elements';
import { ConditionType, FormElementType, IntakeFormElementType, PropKey } from '../shared/form-enum';
import { IFormElement } from '../shared/form-interface';
import FormControl from './form-elements/FromControl/FormControl';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import Conditional from './form-elements/Conditional/Conditional';
import { getAllFormElementsWithChildren, isRelatedConditionalElement } from '../shared/form-builder-utils';

const EmptyFormArea = () => (
  <div className="p-x4-large bg-blue-action-lightest align-center flex-column bordered border-cobalt-lighter rounded-big flex-1 border-dashed">
    <div className="mb-medium">
      <EmptyFormImage />
    </div>
    <Text size="x3-large" color="secondary" weight="bold">
      Your form is empty.
    </Text>
    <Text color="secondary" size="x-large" className="mt-small text-align-center">
      Begin building your form by dragging a field <br /> from the left to the form canvas!
    </Text>
  </div>
);

const dragPlaceholder = (item) => (
  <Text color="blue-lighter">
    {item && (
      <Text color="blue-lighter" weight="bold">
        {item && item.name}{' '}
      </Text>
    )}
    will be placed here
  </Text>
);

type IFormDroppableAreaProps = {
  provided: any;
  snapshot: any;
  list: IFormElement[];
  key: string;
  onSelectItem: (item: IFormElement) => void;
  selectedItem: IFormElement;
  setElements: (items: IFormElement[]) => void;
  isFormDisabled: boolean;
  dragItem: IFormElement;
  dragItemIndex: number;
  updateConditionalConfiguration: (conditionalElement: IFormElement) => void;
  checkDraggingPlaceholder: boolean;
};

type IFormDroppableAreaState = {
  conditionListSelected: string[];
};

class FormDroppableArea extends Component<IFormDroppableAreaProps, IFormDroppableAreaState> {
  state = {
    conditionListSelected: [],
  };

  private _renderComponent = (item: IFormElement, isExplanationCondition?: boolean) => {
    switch (item.type) {
      case FormElementType.HEADER:
        return (
          <Header
            key={item.id}
            headingText={item.properties.appearance.find((prop) => prop.key === 'headingText').value}
            subHeadingText={item.properties.appearance.find((prop) => prop.key === 'subHeadingText')?.value || ''}
          />
        );
      case FormElementType.MULTI_CHOICE:
        return (
          <MultiChoice
            key={item.id}
            title={item.properties.general[0].value}
            caption={item.properties.appearance[0].value}
            options={item.properties.configuration[0].value}
            defaultValue={[item.properties.configuration[1].value]}
            isInCreate={true}
            isRequired={item.properties.general.find((prop) => prop.key === 'require').value}
          />
        );
      case FormElementType.DROPDOWN:
        return (
          <Dropdown
            key={item.id}
            title={item.properties.general[0].value}
            caption={item.properties.appearance[0].value}
            options={item.properties.configuration[0].value}
            defaultValue={item.properties.configuration?.[1]?.value || []}
            placeholder={item.properties.appearance[1]?.value}
            isRequired={item.properties.general.find((prop) => prop.key === 'require').value}
          />
        );
      case FormElementType.SHORT_TEXT:
        return (
          <InlineEditInput
            key={item.id}
            fieldTitle={item.properties.general.find((prop) => prop.key === 'fieldTitle').value}
            placeholder={item.properties.appearance.find((prop) => prop.key === 'placeholderText').value}
            caption={item.properties.appearance.find((prop) => prop.key === 'captionText').value}
            isRequired={item.properties.general.find((prop) => prop.key === 'require').value}
            fieldType={item.properties.general.find((prop) => prop.key === 'fieldTitle')?.fieldType || ''}
          />
        );
      case FormElementType.DIVIDER:
        return <Divider key={item.id} />;
      case FormElementType.PARAGRAPH:
        return (
          <ParagraphElement
            key={item.id}
            value={item.properties.appearance[0].value}
            placeholder={item.properties.appearance[0].placeholder}
          />
        );
      case FormElementType.LONG_TEXT:
        return (
          <LongText
            key={item.id}
            fieldTitle={item.properties.general.find((prop) => prop.key === 'fieldTitle').value}
            placeholder={item.properties.appearance.find((prop) => prop.key === 'placeholderText').value}
            caption={item.properties.appearance.find((prop) => prop.key === 'captionText').value}
            isRequired={item.properties.general.find((prop) => prop.key === 'require').value}
          />
        );
      case FormElementType.SINGLE_CHOICE:
        return (
          <SingleChoiceElement
            key={item.id}
            title={item.properties.general[0].value}
            caption={item.properties.appearance[0].value}
            options={item.properties.configuration[0].value}
            defaultValue={item.properties.configuration?.[1]?.value || ''}
            isInCreate={true}
            isRequired={item.properties.general.find((prop) => prop.key === 'require').value}
          />
        );
      case FormElementType.CHECKBOX:
        return (
          <CheckboxElement
            key={item.id}
            title={item.properties.general[0].value}
            caption={item.properties.appearance[1].value}
            text={item.properties.appearance[0].value}
            defaultValue={item.properties.configuration[0].defaultChecked}
            isRequired={item.properties.general.find((prop) => prop.key === 'require').value}
          />
        );
      case FormElementType.CURRENCY:
        return (
          <CurrencyInput
            key={item.id}
            fieldTitle={item.properties.general.find((prop) => prop.key === 'fieldTitle').value}
            placeholder={item.properties.appearance.find((prop) => prop.key === 'placeholderText').value}
            caption={item.properties.appearance.find((prop) => prop.key === 'captionText').value}
            value={
              item.properties.configuration.find((prop) => prop.key === 'default').value
                ? item.properties.configuration.find((prop) => prop.key === 'defaultValue').value
                : ''
            }
            isRequired={item.properties.general.find((prop) => prop.key === 'require').value}
          />
        );
      case FormElementType.NUMBER:
        return (
          <NumberInputElement
            key={item.id}
            fieldTitle={item.properties.general.find((prop) => prop.key === 'fieldTitle').value}
            placeholder={item.properties.appearance.find((prop) => prop.key === 'placeholderText').value}
            caption={item.properties.appearance.find((prop) => prop.key === 'captionText').value}
            value={
              item.properties.configuration.find((prop) => prop.key === 'default').value
                ? item.properties.configuration.find((prop) => prop.key === 'defaultValue').value
                : ''
            }
            isRequired={item.properties.general.find((prop) => prop.key === 'require').value}
          />
        );

      case FormElementType.DATE_TIME: {
        const fieldDateOfBirth = item.properties.general.find((prop) => prop.key === PropKey.FIELD_TITLE);
        const isDateOfBirth = fieldDateOfBirth.fieldType === IntakeFormElementType.GENERAL_INFORMATION_DATE_OF_BIRTH;
        return (
          <DateTimeElement
            key={item.id}
            fieldTitle={item.properties.general.find((prop) => prop.key === 'fieldTitle').value}
            caption={item.properties.appearance.find((prop) => prop.key === 'captionText').value}
            dateTimeType={item.properties.configuration.find((prop) => prop.key === 'dateTimeType').value}
            constraint={item.properties.configuration.find((prop) => prop.key === 'constraint').value}
            isRequired={item.properties.general.find((prop) => prop.key === 'require').value}
            timezone={moment.tz.guess()}
            isDateOfBirth={isDateOfBirth}
          />
        );
      }

      case FormElementType.FILE_UPLOAD:
        return (
          <AttachmentInput
            key={item.id}
            element={item}
            isRequired={item.properties.general.find((prop) => prop.key === 'require').value}
          />
        );

      case FormElementType.DROPDOWN_MULTI_SELECT:
        return (
          <Dropdown
            key={item.id}
            title={item.properties.general[0].value}
            options={item.properties.configuration[0].value}
            defaultValue={item.properties.configuration?.[1]?.value || []}
            isRequired={item.properties.general.find((prop) => prop.key === PropKey.REQUIRE).value}
            isMultiSelect={true}
            caption={item.properties.appearance.find((prop) => prop.key === PropKey.CAPTION_TEXT).value}
            placeholder={item.properties.appearance.find((prop) => prop.key === PropKey.PLACEHOLDER_TEXT)?.value}
          />
        );

      case FormElementType.ADDRESS_LOOKUP:
        return (
          <AddressLookup
            key={item.id}
            fieldTitle={item.properties.general.find((prop) => prop.key === PropKey.FIELD_TITLE).value}
            placeholder={item.properties.appearance.find((prop) => prop.key === PropKey.PLACEHOLDER_TEXT).value}
            caption={item.properties.appearance.find((prop) => prop.key === PropKey.CAPTION_TEXT).value}
            isRequired={item.properties.general.find((prop) => prop.key === PropKey.REQUIRE).value}
          />
        );

      case FormElementType.PHONE_NUMBER:
        return (
          <PhoneNumber
            key={item.id}
            fieldTitle={item.properties.general.find((prop) => prop.key === PropKey.FIELD_TITLE).value}
            caption={item.properties.appearance.find((prop) => prop.key === PropKey.CAPTION_TEXT).value}
            isRequired={item.properties.general.find((prop) => prop.key === PropKey.REQUIRE).value}
          />
        );

      case FormElementType.FIRST_LAST_NAME:
        return (
          <FullName
            fieldTitleFirstName={item.properties.general[0].value}
            fieldTitleLastName={item.properties.general[1].value}
            placeholderFirstName={item.properties.appearance[0].value}
            placeholderLastName={item.properties.appearance[1].value}
            caption={item.properties.appearance.find((prop) => prop.key === PropKey.CAPTION_TEXT).value}
            isRequired={item.properties.general.find((prop) => prop.key === PropKey.REQUIRE).value}
          />
        );

      case FormElementType.CONDITIONAL: {
        this.props.updateConditionalConfiguration(item);
        const targetElement = item.properties.configuration.find((element) => element.key === PropKey.TARGET_ELEMENT);
        const affectedElementIds = item.properties.configuration.find(
          (element) => element.key === PropKey.AFFECT_TO_ELEMENT,
        )?.value;
        const elementOptions = item.properties.configuration.find(
          (element) => element.key === PropKey.AFFECT_TO_ELEMENT,
        )?.options;
        const affectedElements = elementOptions?.filter((element) => affectedElementIds?.includes(element.id));
        const targetOptions = item.properties.configuration.find((element) => element.key === PropKey.TARGET_OPTION);
        const eventConfigurations = item.properties.configuration.find((element) => element.key === PropKey.EVENT);
        const conditionalComparatorValue = item.properties.configuration.find(
          (element) => element.key === PropKey.CONDITIONAL_COMPARATOR,
        )?.value;
        const repeatable = item.properties.configuration.find((element) => element.key === PropKey.REPEATABLE);

        const { parentId } = item;
        const { list } = this.props;
        let targetOptionName = undefined;
        if (targetOptions.options?.length) {
          const selectedOption = targetOptions.options.find(
            (opt) => opt === targetOptions.value || opt?.value === targetOptions.value,
          );
          if (selectedOption) {
            targetOptionName = selectedOption?.name ?? selectedOption;
          }
        }
        const parentName = list.find((element) => element.id === parentId)?.name;

        return (
          <Conditional
            key={item.id}
            event={eventConfigurations?.value}
            elementTarget={targetElement?.options?.find((el) => el.id === targetElement?.value)}
            affectedElements={affectedElements}
            comparatorValue={conditionalComparatorValue}
            optionTarget={targetOptionName}
            repeatable={repeatable?.value}
            repeatableCaption={parentName}
            isExplanationCondition={isExplanationCondition}
            isShowQuestion={this.state.conditionListSelected.includes(item.id)}
            handleClickShowQuestion={(e) => this._handleClickShowQuestion(e, item)}
          />
        );
      }
      default:
        return item.type;
    }
  };

  private _isSelectedItem = (item) =>
    this.props.selectedItem && item.id === this.props.selectedItem.id && !this.props.snapshot?.isDraggingOver;

  private _handleSetChildrenElements = (item: IFormElement, elements: IFormElement[]) => {
    const { list, setElements } = this.props;

    const elementList = list.map((element) => {
      if (element.id === item.id) {
        return { ...element, children: elements };
      }
      return element;
    });

    setElements(elementList);
  };

  private _handleClickShowQuestion = (e: React.MouseEvent, item: IFormElement) => {
    e.stopPropagation();

    const { id } = item;
    const conditionListSelected = cloneDeep(this.state.conditionListSelected);
    const index = conditionListSelected.findIndex((condition) => condition === id);

    if (index > -1) {
      conditionListSelected.splice(index, 1);
    } else {
      conditionListSelected.push(id);
    }

    this.setState({ conditionListSelected });
  };

  private _checkShowQuestion = (item: IFormElement) => {
    const { list } = this.props;
    const { conditionId } = item;
    if (!conditionId || item.type === FormElementType.CONDITIONAL) {
      return false;
    }

    const { conditionListSelected } = this.state;
    const allElements = getAllFormElementsWithChildren(list);
    const conditionElement = allElements.find(
      (element) => conditionListSelected.includes(element.id) && element?.conditionId === conditionId,
    );

    return Boolean(!conditionElement);
  };

  private _renderChildren = ({
    listChilds,
    parentElement,
    provided,
    snapshot,
    level,
  }: {
    listChilds: IFormElement[];
    parentElement: IFormElement;
    provided?: DraggableProvided;
    snapshot?: DraggableStateSnapshot;
    level: number;
  }) => {
    const { list, selectedItem, onSelectItem, isFormDisabled } = this.props;
    const { conditionListSelected } = this.state;

    return listChilds.map((formChildren) => {
      const isConditionForm = formChildren.type === FormElementType.CONDITIONAL;
      return formChildren.children?.length ? (
        this._renderListFormElementWithChilds({ listElements: [formChildren], level })
      ) : (
        <div key={formChildren.id} hidden={this._checkShowQuestion(formChildren)}>
          {this._isSelectedItem(formChildren) && (
            <FormControl
              selectedElement={selectedItem}
              elements={listChilds}
              setElements={(elements) => this._handleSetChildrenElements(parentElement, elements)}
              onSelectItem={onSelectItem}
              isFormDisabled={isFormDisabled}
              provided={provided}
              editable={false}
            />
          )}
          <div
            className={`item flex-column children-item ${snapshot?.isDragging && 'dragging'} ${
              this._isSelectedItem(formChildren) && 'selected'
            } ${isRelatedConditionalElement(list, formChildren) ? 'conditional' : ''}`}
            onClick={(event) => {
              event.stopPropagation();
              this.props.onSelectItem(formChildren);
            }}
            onBlur={() => this.props.onSelectItem(null)}
          >
            <div
              key={formChildren.id}
              className="item-container"
              style={{ pointerEvents: isConditionForm ? 'all' : 'none' }}
            >
              {this._renderComponent(formChildren)}
            </div>
          </div>
          {isConditionForm && conditionListSelected.includes(formChildren.id)
            ? this._renderExplainationCondition(formChildren, listChilds)
            : null}
        </div>
      );
    });
  };

  private _renderListFormElementWithChilds = ({
    listElements,
    level = 1,
  }: {
    listElements: IFormElement[];
    level: number;
  }) => {
    const {
      list,
      setElements,
      selectedItem,
      onSelectItem,
      isFormDisabled,
      dragItem,
      dragItemIndex,
      checkDraggingPlaceholder,
    } = this.props;

    return listElements.map((item: IFormElement, index: number) => {
      const isMultipleElemets = item.type === FormElementType.MULTIPLE_ELEMENT;

      return level === 1 ? (
        <Draggable key={item.id} draggableId={item.id} index={index}>
          {(provided, snapshot) => (
            <div hidden={this._checkShowQuestion(item)} {...provided.dragHandleProps}>
              {this._isSelectedItem(item) && (
                <FormControl
                  selectedElement={selectedItem}
                  elements={list}
                  setElements={setElements}
                  onSelectItem={onSelectItem}
                  isFormDisabled={isFormDisabled}
                  provided={provided}
                  isIntakeForm={selectedItem?.isIntakeForm}
                  editable={level === 1}
                />
              )}
              <div
                className={`item ${snapshot.isDragging && 'dragging'} ${this._isSelectedItem(item) && 'selected'} ${
                  isMultipleElemets ? 'bordered' : ''
                } ${isRelatedConditionalElement(list, item) ? 'conditional' : ''}`}
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={{
                  ...provided.draggableProps.style,
                  alignItems: 'initial',
                  flexDirection: 'column',
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  this.props.onSelectItem(item);
                }}
                onBlur={() => this.props.onSelectItem(null)}
              >
                {item.children?.length ? (
                  this._renderChildren({
                    listChilds: item.children,
                    parentElement: item,
                    provided,
                    snapshot,
                    level: level + 1,
                  })
                ) : (
                  <div key={item.id} className="item-container">
                    {this._renderComponent(item)}
                  </div>
                )}
              </div>
              {this.props.snapshot.isDraggingOver && level === 1 ? (
                <div
                  className={`p-x-large bg-blue-action-lightest align-center flex-column bordered border-cobalt-lighter rounded-big flex-1 border-dashed ${
                    dragItemIndex === listElements.length ? '' : 'drag-element-placeholder'
                  }`}
                  hidden={checkDraggingPlaceholder ? index !== dragItemIndex : index !== dragItemIndex - 1}
                >
                  {dragPlaceholder(dragItem)}
                </div>
              ) : null}
            </div>
          )}
        </Draggable>
      ) : (
        <>
          <div hidden={this._checkShowQuestion(item)}>
            {this._isSelectedItem(item) && (
              <FormControl
                selectedElement={selectedItem}
                elements={list}
                setElements={setElements}
                onSelectItem={onSelectItem}
                isFormDisabled={isFormDisabled}
                provided={{}}
                isIntakeForm={selectedItem?.isIntakeForm}
                editable={level === 1}
              />
            )}
            <div
              className={`item ${this._isSelectedItem(item) && 'selected'} ${isMultipleElemets ? 'bordered' : ''} ${
                isRelatedConditionalElement(list, item) ? 'conditional' : ''
              }`}
              style={{
                alignItems: 'initial',
                flexDirection: 'column',
              }}
              onClick={(event) => {
                event.stopPropagation();
                this.props.onSelectItem(item);
              }}
              onBlur={() => this.props.onSelectItem(null)}
            >
              {item.children?.length ? (
                this._renderChildren({
                  listChilds: item.children,
                  parentElement: item,
                  level: level + 1,
                })
              ) : (
                <div key={item.id} className="item-container">
                  {this._renderComponent(item)}
                </div>
              )}
            </div>
          </div>
        </>
      );
    });
  };

  private _renderExplainationCondition = (conditionalItem: IFormElement, items: IFormElement[]) => {
    const isRepeatableCondition = conditionalItem.properties.configuration.find(
      (config) => config.key === PropKey.REPEATABLE,
    ).value;

    if (isRepeatableCondition) {
      const newItems = cloneDeep(items)
        .splice(1)
        .map((item) => ({ ...item, id: item.id + ConditionType.DUPLICATION }));
      return newItems.map((item) => (
        <div
          key={item.id}
          className={`item ${this._isSelectedItem(item) && 'selected'} conditional`}
          onClick={(event) => {
            event.stopPropagation();
            this.props.onSelectItem(item);
          }}
          onBlur={() => this.props.onSelectItem(null)}
          style={{ flexDirection: 'column' }}
        >
          <div className="item-container">{this._renderComponent(item, true)}</div>
        </div>
      ));
    }
  };

  render() {
    const { provided, snapshot, list, dragItem, dragItemIndex, checkDraggingPlaceholder } = this.props;

    return (
      <div
        className={`dropable-area ${
          snapshot.isDraggingOver && dragItemIndex !== list.length ? 'dragging-element' : ''
        } m-small mb-large container`}
        ref={provided.innerRef}
      >
        {dragItemIndex === 0 && snapshot.isDraggingOver && !checkDraggingPlaceholder && (
          <div
            className={`p-x-large bg-blue-action-lightest align-center flex-column bordered border-cobalt-lighter rounded-big flex-1 border-dashed ${
              !list.length ? '' : 'drag-element-placeholder'
            }`}
          >
            {dragPlaceholder(dragItem)}
          </div>
        )}
        <div className={`${snapshot.isDraggingOver && 'dragging-over'}`}>
          {list.length
            ? this._renderListFormElementWithChilds({ listElements: list, level: 1 })
            : !snapshot.isDraggingOver && <EmptyFormArea />}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedElement: state.formBuilderStore.selectedElement,
});

const mapDispatch = (dispatch: IRootDispatch) => ({});

export default connect(mapState, mapDispatch)(FormDroppableArea);
