import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Cut = forwardRef(function Cut(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.555 2.495a.75.75 0 0 0-1.11 1.01l4 4.4a.75.75 0 0 0 1.11-1.01l-4-4.4Zm12 1.01a.75.75 0 0 0-1.11-1.01l-6 6.6-4 4.4a.747.747 0 0 0-.027.032 3.75 3.75 0 1 0 1.258.845L12 10.715l3.325 3.657a3.75 3.75 0 1 0 1.258-.845.818.818 0 0 0-.028-.032L13.014 9.6l5.541-6.095ZM18 14.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm-12 0a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
