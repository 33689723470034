import { Avatar } from 'antd';
import { PrimaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import { IVersionHistoryWorkflowTemplate, IWorkflowTemplateStep } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import StepViewListItem from '../../steps/components/StepViewListItem';

interface IWorkflowTemplateDetailVersionHistoryModalProps {
  isOpenModal: boolean;
  workflowVersionHistory: IVersionHistoryWorkflowTemplate | null;
  onCloseModal: () => void;
  doGetWorkflowTemplateSteps: typeof dispatch.workflowStore.doGetWorkflowTemplateSteps;
}

interface IWorkflowTemplateDetailVersionHistoryModalState {
  workflowVersionHistorySteps: IWorkflowTemplateStep[];
}

class WorkflowTemplateDetailVersionHistoryModal extends PureComponent<
  IWorkflowTemplateDetailVersionHistoryModalProps,
  IWorkflowTemplateDetailVersionHistoryModalState
> {
  state = {
    workflowVersionHistorySteps: null,
  };

  private _onCloseModal = () => {
    const { onCloseModal } = this.props;

    this.setState({ workflowVersionHistorySteps: null });
    onCloseModal();
  };

  componentDidUpdate = async (prevProps) => {
    const { workflowVersionHistory, doGetWorkflowTemplateSteps, isOpenModal } = this.props;

    if (isOpenModal && workflowVersionHistory && !_.isEqual(prevProps.workflowVersionHistory, workflowVersionHistory)) {
      const workflowVersionHistorySteps: any = await doGetWorkflowTemplateSteps({
        workflowTemplateId: workflowVersionHistory.workflowTemplateId,
      });

      this.setState({ workflowVersionHistorySteps: workflowVersionHistorySteps });
    }
  };

  render() {
    const { isOpenModal, workflowVersionHistory } = this.props;
    const { workflowVersionHistorySteps } = this.state;

    return (
      workflowVersionHistory && (
        <ActionModal
          isOpen={isOpenModal}
          title={`Workflow version history (Version ${workflowVersionHistory.version})`}
          verticalAlignment="highest"
          onClose={this._onCloseModal}
        >
          <SubTitle containerClassName="mb-x-small">Date Created</SubTitle>
          <Text>{moment(workflowVersionHistory.createdOn).format('DD/MM/YYYY')}</Text>
          <SubTitle containerClassName="mb-x-small mt-medium">created by</SubTitle>
          <div className="flex align-center mb-medium">
            <Avatar className="mr-small" icon="user" src={workflowVersionHistory.avatar} />
            <Text>{workflowVersionHistory.displayName}</Text>
          </div>

          {workflowVersionHistorySteps &&
            workflowVersionHistorySteps.map((step, index) => <StepViewListItem key={index} step={step} isViewState />)}

          <div className="flex justify-end mt-x4-large">
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </div>
        </ActionModal>
      )
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetWorkflowTemplateSteps: dispatch.workflowStore.doGetWorkflowTemplateSteps,
});

export default connect(null, mapDispatch)(WorkflowTemplateDetailVersionHistoryModal);
