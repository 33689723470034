import { message } from 'antd';
import * as H from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { dispatch } from 'stores/rematch/root-store';
import CancelChangeWorkflowTemplateModal from './CancelChangeWorkflowTemplateModal';
import firebaseApp from 'stores/firebase-app';

interface Props {
  when?: boolean;
  shouldBlockNavigation: (location: H.Location) => boolean;
  navigate(path: string): void;
  setIsSignOut: typeof dispatch.authStore.setIsSignOut;
  setCurrentUser: typeof dispatch.authStore.setCurrentUser;
  setCurrentPortalUser: typeof dispatch.authStore.setCurrentPortalUser;
  setPortalUserList: typeof dispatch.authStore.setPortalUserList;
  setIsForgetPassword: typeof dispatch.authStore.setIsForgetPassword;
}

export class RouteLeavingGuard extends React.Component<Props> {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
  };

  showModal = (location) =>
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });

  closeModal = (callback) =>
    this.setState(
      {
        modalVisible: false,
      },
      callback,
    );

  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;

    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { navigate } = this.props;
      const { lastLocation } = this.state;

      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          async () => {
            // Navigate to the previous blocked location with your navigate function
            navigate(lastLocation.pathname);

            if (lastLocation.pathname === '/sign-out') {
              this.props.setIsSignOut(true);
              this.props.setIsForgetPassword(false);
              await message.loading('Signing out...', 1);
              await firebaseApp.auth.signOut();

              this.props.setCurrentUser(null);
              this.props.setCurrentPortalUser(null);
              this.props.setPortalUserList(null);

              message.success('Signed out.');
            }
          },
        );
      }
    });

  render() {
    const { when } = this.props;
    const { modalVisible } = this.state;

    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <CancelChangeWorkflowTemplateModal
          isOpen={modalVisible}
          onClose={() => this.setState({ modalVisible: false })}
          onConfirm={this.handleConfirmNavigationClick}
        />
      </>
    );
  }
}

const mapDispatch = (dispatch) => ({
  setIsSignOut: dispatch.authStore.setIsSignOut,
  setCurrentUser: dispatch.authStore.setCurrentUser,
  setCurrentPortalUser: dispatch.authStore.setCurrentPortalUser,
  setPortalUserList: dispatch.authStore.setPortalUserList,
  setIsForgetPassword: dispatch.authStore.setIsForgetPassword,
});
export default connect(null, mapDispatch)(RouteLeavingGuard);
