import React from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

import { FieldLabel, Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';

import { RatioTableRowItem } from 'views/group-services/manage-booking-modal/panels/customer-ratio/RatioTableRowItem';
import EditCustomerRatioModal from 'views/group-services/manage-booking-modal/panels/customer-ratio/EditCustomerRatioModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import moment, { Moment } from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { ModalLoading } from 'views/group-services/manage-booking-modal/common-components/ModalLoading';
import { PaymentStatus } from 'utilities/enum-utils';
import InfoPanel from 'common-components/alerts/InfoPanel';
import { EditRatios } from 'views/group-services/components/edit-ratios';

interface ICustomerRatioPanelProps {
  booking: typeof state.groupBookingsStore.selectedGroupBookingItem;
  groupBookingCustomerRatio: typeof state.groupBookingsStore.groupBookingCustomerRatio;
  isLoading: boolean;
  flags: Record<string, boolean>;
}

interface ICustomerRatioPanelState {
  isEditModalOpen: boolean;
  isLoading: boolean;
  bookingTimes: { bookingId: string; startDateTime: Moment; endDateTime: Moment } | null;
  ratios: any;
}

class CustomerRatioPanel extends React.Component<ICustomerRatioPanelProps, ICustomerRatioPanelState> {
  state = { isEditModalOpen: false, isLoading: false, bookingTimes: null, ratios: null };

  private _onEditRatio = () => {
    this._onOpenEditModal();
  };
  private _onOpenEditModal = () => this.setState({ isEditModalOpen: true });
  private _onCloseEditModal = () => this.setState({ isEditModalOpen: false });

  componentDidMount() {
    const { booking, groupBookingCustomerRatio } = this.props;

    const bookingStartDateTimeWithoutTimezone = moment(
      moment.tz(booking.startDateTime, booking.timezone).format('YYYY-MM-DD HH:mm'),
    );
    const bookingEndDateTimeWithoutTimezone = moment(
      moment.tz(booking.endDateTime, booking.timezone).format('YYYY-MM-DD HH:mm'),
    );
    this.setState({
      isLoading: false,
      bookingTimes: {
        bookingId: booking.bookingId,
        startDateTime: bookingStartDateTimeWithoutTimezone,
        endDateTime: bookingEndDateTimeWithoutTimezone,
      },
      ratios: groupBookingCustomerRatio
        ? groupBookingCustomerRatio.ratios.length === 1
          ? [
              {
                teamMemberCustomerRatio: groupBookingCustomerRatio.defaultRatio,
                startDateTime: bookingStartDateTimeWithoutTimezone,
                endDateTime: bookingEndDateTimeWithoutTimezone,
                isCustomRatio: false,
                customRatio: [
                  {
                    customTimeId: uuidv4(),
                    startDateTime: bookingStartDateTimeWithoutTimezone,
                    endDateTime: bookingEndDateTimeWithoutTimezone,
                    teamMemberCustomerRatio: groupBookingCustomerRatio.ratios[0].teamMemberCustomerRatio,
                  },
                ],
              },
            ]
          : [
              {
                teamMemberCustomerRatio: groupBookingCustomerRatio.defaultRatio,
                startDateTime: bookingStartDateTimeWithoutTimezone,
                endDateTime: bookingEndDateTimeWithoutTimezone,
                isCustomRatio: true,
                customRatio: _.map(groupBookingCustomerRatio && groupBookingCustomerRatio.ratios, (ratio) => {
                  return {
                    customTimeId: uuidv4(),
                    startDateTime: moment.tz(ratio.startDateTime, booking.timezone).format('YYYY-MM-DD HH:mm'),
                    endDateTime: moment.tz(ratio.endDateTime, booking.timezone).format('YYYY-MM-DD HH:mm'),
                    teamMemberCustomerRatio: ratio.teamMemberCustomerRatio,
                    comments: ratio.comments,
                  };
                }),
              },
            ]
        : null,
    });
  }

  componentDidUpdate(
    prevProps: Readonly<ICustomerRatioPanelProps>,
    prevState: Readonly<ICustomerRatioPanelState>,
    snapshot?: any,
  ) {
    const { booking, groupBookingCustomerRatio } = this.props;

    if (groupBookingCustomerRatio && prevProps.groupBookingCustomerRatio !== groupBookingCustomerRatio) {
      const bookingStartDateTimeWithoutTimezone = moment(
        moment.tz(booking.startDateTime, booking.timezone).format('YYYY-MM-DD HH:mm'),
      );
      const bookingEndDateTimeWithoutTimezone = moment(
        moment.tz(booking.endDateTime, booking.timezone).format('YYYY-MM-DD HH:mm'),
      );
      this.setState({
        isLoading: false,
        bookingTimes: {
          bookingId: booking.bookingId,
          startDateTime: bookingStartDateTimeWithoutTimezone,
          endDateTime: bookingEndDateTimeWithoutTimezone,
        },
        ratios: groupBookingCustomerRatio
          ? groupBookingCustomerRatio.ratios.length === 1
            ? [
                {
                  teamMemberCustomerRatio: groupBookingCustomerRatio.defaultRatio,
                  startDateTime: bookingStartDateTimeWithoutTimezone,
                  endDateTime: bookingEndDateTimeWithoutTimezone,
                  isCustomRatio: false,
                  customRatio: [
                    {
                      customTimeId: uuidv4(),
                      startDateTime: bookingStartDateTimeWithoutTimezone,
                      endDateTime: bookingEndDateTimeWithoutTimezone,
                      teamMemberCustomerRatio: groupBookingCustomerRatio.ratios[0].teamMemberCustomerRatio,
                    },
                  ],
                },
              ]
            : [
                {
                  teamMemberCustomerRatio: groupBookingCustomerRatio.defaultRatio,
                  startDateTime: bookingStartDateTimeWithoutTimezone,
                  endDateTime: bookingEndDateTimeWithoutTimezone,
                  isCustomRatio: true,
                  customRatio: _.map(groupBookingCustomerRatio && groupBookingCustomerRatio.ratios, (ratio) => {
                    return {
                      customTimeId: uuidv4(),
                      startDateTime: moment.tz(ratio.startDateTime, booking.timezone).format('YYYY-MM-DD HH:mm'),
                      endDateTime: moment.tz(ratio.endDateTime, booking.timezone).format('YYYY-MM-DD HH:mm'),
                      teamMemberCustomerRatio: ratio.teamMemberCustomerRatio,
                      comments: ratio.comments,
                    };
                  }),
                },
              ]
          : null,
      });
    }
  }

  render() {
    const { groupBookingCustomerRatio, booking, isLoading, flags } = this.props;

    if (isLoading) {
      return <ModalLoading text={'Fetching customer ratio...'} />;
    }

    const { bookingTimes, ratios } = this.state;

    if (
      !groupBookingCustomerRatio ||
      (_.isEmpty(groupBookingCustomerRatio.ratios) && !groupBookingCustomerRatio.teamMemberCustomerRatio)
    ) {
      return (
        <div className="anim-fade-in-fast">
          <div className="p-medium rounded-big bordered border-standard-gray">
            <Text color="secondary">No customer ratio defined for this booking.</Text>
          </div>
        </div>
      );
    }

    const isCustomRatio = groupBookingCustomerRatio && groupBookingCustomerRatio.ratios.length > 1;

    return (
      <>
        {booking.paymentStatus !== PaymentStatus.INITIAL &&
          booking.paymentStatus !== PaymentStatus.REQUIRES_APPROVAL && (
            <section className="mb-large">
              <InfoPanel text={'Customer ratio cannot be edited as this booking has been approved for payment.'} />
            </section>
          )}
        {isLoading ? (
          <SpinningLoader size={100} message={`Retrieving customer's ratios for this booking...`} />
        ) : (
          <>
            <div className="anim-fade-in-fast">
              {!flags.pinc1004NonTimeBoundRatios ? (
                <div className="p-medium rounded-big bordered border-standard-gray">
                  <section className="">
                    <FieldLabel text="CUSTOMER RATIO" />
                    <Text>
                      {isCustomRatio
                        ? 'Custom ratio'
                        : groupBookingCustomerRatio &&
                          groupBookingCustomerRatio.ratios &&
                          groupBookingCustomerRatio.ratios[0].teamMemberCustomerRatio}
                    </Text>
                  </section>

                  <section className="mt-small">
                    {isCustomRatio && (
                      <table className="width-full">
                        <thead>
                          <tr className="bordered-bottom border-standard-gray">
                            <th className="p-medium" style={{ width: '200px' }}>
                              <FieldLabel text="Time" />
                            </th>
                            <th className="p-medium" style={{ width: '132px' }}>
                              <FieldLabel text="Ratio" />
                            </th>
                            <th className="p-medium">
                              <FieldLabel text="Comments" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.map(groupBookingCustomerRatio.ratios, (item) => (
                            <RatioTableRowItem
                              customRatioItem={item}
                              timezone={booking.timezone ? booking.timezone : null}
                            />
                          ))}
                        </tbody>
                      </table>
                    )}
                    {(booking.paymentStatus === PaymentStatus.INITIAL ||
                      booking.paymentStatus === PaymentStatus.REQUIRES_APPROVAL) && (
                      <div className="mt-medium">
                        <HyperlinkButton onClick={this._onEditRatio}>Change customer ratio...</HyperlinkButton>
                      </div>
                    )}
                  </section>
                </div>
              ) : (
                <div className="mb-large p-medium rounded-big bordered border-quaternary bg-quaternary">
                  <div className="flex">
                    <section className="width-full">
                      <FieldLabel text="CUSTOMER RATIO" />
                      <Text>{groupBookingCustomerRatio?.defaultRatio}</Text>
                    </section>
                    <section className="width-full">
                      <FieldLabel text="BOOKING TIME" />
                      <Text>
                        {moment.tz(booking.startDateTime, booking.timezone).format('hh:mm A')}
                        {' - '}
                        {moment.tz(booking.endDateTime, booking.timezone).format('hh:mm A')}
                      </Text>
                    </section>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {!flags.pinc1004NonTimeBoundRatios ? (
          <EditCustomerRatioModal
            isOpen={this.state.isEditModalOpen}
            bookingTimes={bookingTimes}
            onClose={this._onCloseEditModal}
            timezone={booking && booking.timezone}
            currentRatio={ratios}
          />
        ) : (
          <EditRatios type="edit" showConfirmButton groupBookingId={booking?.bookingId} />
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupBookingCustomerRatio: state.groupBookingsStore.groupBookingCustomerRatio,
});

const mapDispatch = (dispatch: IRootDispatch) => ({});

export default connect(mapState, mapDispatch)(withLDConsumer()(CustomerRatioPanel));
