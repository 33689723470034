import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CloudLightning = forwardRef(function CloudLightning(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 9a6.25 6.25 0 0 1 12.322-1.488.75.75 0 0 0 .458.522 4.252 4.252 0 0 1 1.02 7.367.75.75 0 1 0 .9 1.199 5.75 5.75 0 0 0-1.021-9.813A7.75 7.75 0 0 0 4.261 8.578a4.751 4.751 0 0 0-.636 8.536.75.75 0 1 0 .75-1.298 3.252 3.252 0 0 1 .812-5.964.75.75 0 0 0 .564-.74 6.38 6.38 0 0 1 0-.112Zm5.06 7.25 2.72-2.72a.75.75 0 1 0-1.06-1.06l-4 4A.75.75 0 0 0 9 17.75h4.19l-2.72 2.72a.75.75 0 1 0 1.06 1.06l4-4a.75.75 0 0 0-.53-1.28h-4.19Z"
        fill="currentColor"
      />
    </Icon>
  );
});
