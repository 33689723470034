import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ShieldCheck = forwardRef(function ShieldCheck(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.454 3.756c1.441-.54 1.988-.734 2.546-.734.558 0 1.105.193 2.546.734l.869.325c1.725.647 2.375.907 2.829 1.34.319.304.573.67.745 1.076.247.577.261 1.276.261 3.12v1.35c0 1.583-.003 2.278-.12 2.915a7.25 7.25 0 0 1-2.236 4.033c-.477.436-1.065.808-2.407 1.646-.766.479-1.06.658-1.35.767a3.25 3.25 0 0 1-2.273 0c-.29-.109-.585-.288-1.35-.767-1.343-.838-1.93-1.21-2.408-1.646a7.25 7.25 0 0 1-2.236-4.033c-.117-.637-.12-1.332-.12-2.915v-1.35c0-1.844.015-2.543.26-3.12a3.25 3.25 0 0 1 .747-1.076c.453-.433 1.103-.693 2.829-1.34l.868-.325ZM9.06 2.3l-.132.05-.869.326-.164.061c-1.503.563-2.47.926-3.174 1.597a4.75 4.75 0 0 0-1.09 1.573c-.382.895-.381 1.928-.38 3.533v1.614c0 1.47-.001 2.311.144 3.099a8.75 8.75 0 0 0 2.698 4.867c.591.541 1.304.986 2.551 1.766l.074.046.073.045c.668.419 1.098.687 1.548.855 1.071.4 2.25.4 3.322 0 .45-.168.88-.436 1.549-.855l.072-.045.074-.046c1.247-.78 1.96-1.225 2.551-1.766a8.75 8.75 0 0 0 2.698-4.867c.145-.788.145-1.629.145-3.1V9.442c0-1.605 0-2.638-.38-3.533a4.75 4.75 0 0 0-1.09-1.573c-.705-.671-1.672-1.034-3.175-1.597l-.164-.061-.869-.326-.132-.05c-1.261-.473-2.077-.779-2.94-.779s-1.678.306-2.94.78Zm6.97 7.73a.75.75 0 1 0-1.06-1.061L11 12.94l-1.47-1.47a.75.75 0 0 0-1.06 1.06l2 2a.75.75 0 0 0 1.06 0l4.5-4.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
