import React from 'react';
import _ from 'lodash';

import { ShiftSlotGroupHeader } from 'views/group-services/session-details/team-members/shift-slot-table/ShiftSlotGroupHeader';
import { ShiftSlotRow } from 'views/group-services/session-details/team-members/shift-slot-table/ShiftSlotRow';
import { IShiftSlotActions } from 'views/group-services/session-details/team-members/SessionTeamMembersPanel';
import { Text } from 'common-components/typography';
import { ShiftSlotStatus } from 'utilities/enum-utils';
import { timeZone } from 'interfaces/timezone-type';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { Empty } from 'antd';
import { ISession } from 'interfaces/session-interfaces';

interface IShiftSlotRowsSectionProps {
  shiftSlots: IShiftSlot[];
  selectedShiftSlots: IShiftSlot[];
  actions: IShiftSlotActions;
  // checkAll: boolean;
  timezone: timeZone;
  session: ISession;
  onSelectShiftSlot: (v) => void;
  onDeselectShiftSlot: (v) => void;
  highlightedShift: string;
}

const ShiftSlotEmptyState = () => (
  <div className="flex-1 bg-white mt-x2-large align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No shift(s) found
    </Text>
  </div>
);

export class ShiftSlotRowsSection extends React.Component<IShiftSlotRowsSectionProps> {
  private _findInSelectedShiftSlots = (slot: IShiftSlot) => {
    return !!_.find(
      this.props.selectedShiftSlots,
      (selectedSlot) => selectedSlot.supportWorkerAttendanceId === slot.supportWorkerAttendanceId,
    );
  };

  // render helper function.
  renderShiftSlots = (shiftSlots: IShiftSlot[], status: ShiftSlotStatus, total = 0, actions) => {
    const filteredSlots = _.chain(shiftSlots)
      .filter((slot) => slot.shiftSlotStatus === status)
      .orderBy('shiftStartDateTime')
      .value();

    const count = !_.isEmpty(filteredSlots) ? filteredSlots.length : 0;

    if (!_.isEmpty(filteredSlots)) {
      return (
        <>
          <ShiftSlotGroupHeader status={status} count={count} total={total} />

          {_.map(filteredSlots, (slot) => (
            <ShiftSlotRow
              shiftSlot={slot}
              actions={actions}
              key={slot.supportWorkerAttendanceId}
              // checkAll={this.props.checkAll}
              timezone={this.props.timezone}
              session={this.props.session}
              isSelected={this._findInSelectedShiftSlots(slot)}
              onSelect={this.props.onSelectShiftSlot}
              onDeselect={this.props.onDeselectShiftSlot}
              isHighlighted={this.props.highlightedShift === slot.supportWorkerAttendanceId}
            />
          ))}
        </>
      );
    }
  };

  render() {
    let { shiftSlots, actions } = this.props;
    const total = !_.isEmpty(shiftSlots) ? shiftSlots.length : 0;

    if (_.isEmpty(shiftSlots)) {
      return <ShiftSlotEmptyState />;
    }

    return (
      <>
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.PENDING, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.CONFIRMED, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.CHECKED_IN, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.CHECKED_OUT, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.APPROVED, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.NO_SHOW, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.DECLINED, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.UNASSIGNED, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.ON_LEAVE, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.LEAVE_APPROVED, total, actions)}

        {/* Additional slot status */}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.CANCELLED, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.REJECTED, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.PUBLISHING, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.CLAIMED, total, actions)}
        {this.renderShiftSlots(shiftSlots, ShiftSlotStatus.SENT_TO_FINANCE, total, actions)}
      </>
    );
  }
}
