import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React, { PureComponent } from 'react';

interface Props {
  isOpen: boolean;
  isUpdating: boolean;
  onClose(): void;
  onConfirm(): void;
}

class ConfirmRemoveWorkflowTemplateModal extends PureComponent<Props> {
  render() {
    const { isOpen, isUpdating, onClose, onConfirm } = this.props;

    return (
      <ActionModal isOpen={isOpen} onClose={onClose} title="Delete step" verticalAlignment="high">
        <Paragraph className="mt-medium">Are you sure you want to delete this step?</Paragraph>

        <ActionModalFooter className={'mt-x-large'}>
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={onConfirm} loading={isUpdating}>
            Delete step
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default ConfirmRemoveWorkflowTemplateModal;
