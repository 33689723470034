import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Timer = forwardRef(function Timer(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 3a.75.75 0 0 1 .75-.75c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12a9.722 9.722 0 0 1 2.856-6.894.75.75 0 0 1 1.06 1.06 8.25 8.25 0 1 0 6.584-2.383V6a.75.75 0 0 1-1.5 0V3ZM8.47 8.47a.75.75 0 0 1 1.06 0l3 3a.75.75 0 1 1-1.06 1.06l-3-3a.75.75 0 0 1 0-1.06Z"
        fill="currentColor"
      />
    </Icon>
  );
});
