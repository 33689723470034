import { z } from 'zod';

export enum DobDisplayOptions {
  AGE = 'age',
  MONTH = 'MMMM',
  MONTH_YEAR = 'MMMM YYYY',
  DAY_MONTH_YEAR = 'D MMMM YYYY',
  HIDDEN = 'hidden',
}

export enum GenderOptions {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Non-binary',
  PREFER_NOT_TO_SAY = 'Not specified',
}

export enum GenderOptionsShow {
  Male = 'Male',
  Female = 'Female',
  'Non-binary' = 'Other',
  'Not specified' = 'Prefer not to say',
}

export enum SupportWorkerContactNumberType {
  WORK = 'Work',
  HOME = 'Home',
  MOBILE = 'Mobile',
  PERSONAL = 'Personal',
}

export enum EditRecurringMode {
  Current = 1, // current attendance
  CurrentAll, // current attendance and all future attendance from this booking.
  CurrentNext, // current attendance and number of attendance next
  Upcoming, // all attendance that booking status is CONFIRMED, PENDING or ACCEPTED after the current time
  AllWorkerUpcoming, // all attendance for all worker that booking status is CONFIRMED, PENDING or ACCEPTED
  AllWorkerCurrentAll, // all attendance for all worker that is after current booking
  Custom, // all attendance for each worker that is in request {bookingId , supportWorkerId}
}

export enum RecurringBookingPattern {
  EveryDay = 1,
  EveryWeek = 2,
  EveryFortnight = 3,
  EveryFourWeeks = 4,
  EveryThreeWeeks = 5,
  Monthly = 6,
}

export enum MaintenanceType {
  ConsumerApp = 1,
  WorkerApp,
  Portal,
}

export enum MaintenanceStatus {
  Scheduled = 1,
  InProgress,
  Completed,
}

export enum ActionMode {
  Register = 'Register',
  Update = 'Update',
}

export enum UserPermissionRole {
  PrimaryOwner = 1,
  Owner = 2,
  CustomerAccessManager = 3,
  CustomerAccessViewOnly = 4,
  TeamManager = 5,
  TeamViewOnly = 6,
  PaymentManager = 7,
  PaymentMember = 8,
  ServiceAdmin = 9,
  ServiceMember = 10,
  PlanManager = 11,
  DepartmentManager = 12,
  WorkflowBasic = 13,
  WorkflowElevated = 14,
  WorkflowManager = 15,
  FormBuilder = 16,
  SubscriptionBillingAllAccess = 17,
  SubscriptionBillingViewOnly = 18,
  CustomerViewAll = 19,
  CustomerViewServiceOnly = 20,
  TeamNoAccess = 21,
}

export enum NotesCreatedByType {
  Portal = 1,
  SupportWorker = 2,
}

export enum BillingAutoGeneratedType {
  ScheduledTime = 0,
  WorkerCheckinCheckOutTime = 1,
  BeforeBookingTravel = 2,
  DuringBookingTravel = 3,
  PortalCheckinCheckOutTime = 4,
  NonFaceToFaceAdditionalCharge = 5,
  AutoChargedBillingItemsAdditionalCharge = 6,
}

export enum MmmGroup {
  NonRemote = 0,
  Remote = 1,
  VeryRemote = 2,
}

export enum AustralianEasternStates {
  ACT = 'ACT',
  NSW = 'NSW',
  QLD = 'QLD',
  VIC = 'VIC',
}

export enum AustralianWesternStates {
  NT = 'NT',
  SA = 'SA',
  TAS = 'TAS',
  WA = 'WA',
}

export enum AustralianStates {
  ACT = 'ACT',
  NSW = 'NSW',
  QLD = 'QLD',
  VIC = 'VIC',
  NT = 'NT',
  SA = 'SA',
  TAS = 'TAS',
  WA = 'WA',
}

export enum SupportedFinanceExportConfigStyle {
  TENCIA = 'TENCIA',
  MYOB = 'MYOB',
  WISEFINANCE = 'WISEFINANCE',
  XERO = 'XERO',
  MD365 = 'MD365',
  QUICKBOOKS = 'QUICKBOOKS',
  KNOX_BROOKE_MYOB = 'KNOX_BROOKE_MYOB',
  XERO_FIGHTING_CHANCE = 'XERO_FIGHTING_CHANCE',
  CAREVIEW = 'CAREVIEW',
  NDIA = 'NDIA',
  RECKON = 'RECKON',
  GOODHUMAN_ALL = 'GOODHUMAN_ALL',
}

export enum FinanceCustomName {
  KNOX_BROOKE_MYOB = 'MYOB (custom)',
}

export const DEFAULT_ACCOUNT_CONFIG = {
  name: 'Xero',
  key: 'XERO',
};

export const DEFAULT_PAYROLL_CONFIG = {
  name: 'GoodHuman default',
  key: 'GOODHUMAN_DEFAULT',
};

export enum SupportedFinanceTimesheetExportStyle {
  ZEST_CARE = 'ZESTCARE-CONNX',
  EMPLOYMENT_HERO = 'EMPLOYMENT_HERO',
  GOODHUMAN_DEFAULT = 'GOODHUMAN_DEFAULT',
  KEYPAY = 'KEYPAY',
  XERO_UPSHEETS = 'XERO_UPSHEETS',
  INTERCHANCEOUTEREAST = 'INTERCHANCEOUTEREAST',
}

export enum SupportedNDISExportConfigStyle {
  NDIS = 'NDIS',
}

export enum BookingErrorType {
  PaymentMethodError = 1,
  BookingRecordedTimeWarning = 2,
  ServiceAgreementWarning = 3,
  FundingPackageWarning = 4,
  BookingActualTimeWarning = 5,
  BookingCheckinTimeWarning = 6,
  BookingCheckOutTimeWarning = 7,
  ServiceAgreementLineItemWarning = 8,
  SleepoverOutOfBookingTimeError = 9,
  SleepoverOutOfCheckinCheckoutError = 10,
  DisturbancOutOfSleepoverTimeError = 11,
  DisturbancOutOfBookingTimeError = 12,
  DisturbancOutOfCheckinCheckoutTimeError = 13,
}

export enum ShiftSlotErrorType {
  LateStartWarning = 1,
  LateFinishWarning = 2,
  EarlyStartWarning = 3,
  EarlyFinishWarning = 4,
  NoShowWarning = 5,
  ShiftUnfinishedWarning = 6,
}

export enum BookingErrorTimeSource {
  ACTUAL_TIME = 'ACTUAL_TIME',
  RECORD_TIME = 'RECORD_TIME',
}

export enum DateType {
  WKD = 'WKD',
  WKE = 'WKE',
  OVN = 'OVN',
  NSO = 'NSO',
  SAT = 'SAT',
  SUN = 'SUN',
  PBH = 'PBH',
  ABT = 'ABT',
  TR = 'TR',
  PTNLC = 'PTNLC',
  NONE = '',
}

export enum TransportAttendanceType {
  PRE_SESSION = 'PRE_SESSION',
  POST_SESSION = 'POST_SESSION',
}

export enum CustomerStatusType {
  ARCHIVED = 'ARCHIVED',
  ENQUIRY = 'ENQUIRY',
  ACTIVE = 'ACTIVE',
  SCHEDULED_TO_BE_ARCHIVED = 'SCHEDULED_TO_BE_ARCHIVED',
}

export enum CustomerAlertType {
  DocumentExpired = 0,
  DocumentExpiringSoon = 1,
  OverBudgetActual = 2,
  OverBudgetForecasted = 3,
  ServiceAgreementExpired = 4,
  ServiceAgreementExpiringSoon = 5,
  NoXeroContactForCustomer = 6,
}

export const SupportWorkerAlertTypeSchema = z.enum([
  'ComplianceDocumentExpired',
  'DocumentExpired',
  'ComplianceDocumentExpiringSoon',
  'DocumentExpiringSoon',
  'ScheduledToBeRemoved',
]);

export enum SupportWorkerAlertType {
  ComplianceDocumentExpired = 1,
  DocumentExpired = 2,
  ComplianceDocumentExpiringSoon = 3,
  DocumentExpiringSoon = 4,
  ScheduledToBeRemoved = 5,
}

export enum CustomViewType {
  PRIVATE = 1,
  SHARED = 2,
  ALL = 3,
}

export enum CustomViewCategory {
  Customer = 1,
  Booking = 2,
  Team = 3,
}

export enum CustomViewCategoryStringType {
  Customer = 'customer',
  Booking = 'booking',
  Team = 'team',
}

export enum DebtorType {
  SelfManager = 1,
  ExternalPlanManager = 2,
  InternalPlanManager = 3,
  CaseManager = 4,
}

export const DebtorTypeDisplay = {
  [DebtorType.SelfManager]: 'Self Manager',
  [DebtorType.ExternalPlanManager]: 'External Plan Manager',
  [DebtorType.InternalPlanManager]: 'Internal Plan Manager',
  [DebtorType.CaseManager]: 'Case Manager',
};

export enum AlertLevel {
  High = 1,
  Medium = 2,
  Low = 3,
}

export enum CareInformationType {
  PermanentCondition = 1,
  Medication = 2,
  SupportNeed = 3,
  Behaviour = 4,
  General = 5,
}

export enum ServiceType {
  Group = 'GROUP',
  Individual = 'INDIVIDUAL',
  Coordination = 'COORDINATION',
  IndependentLiving = 'INDEPENDENT_LIVING',
}

export const ServiceTypeEnum = z.nativeEnum(ServiceType);

export enum UnavailabilityType {
  OneOff = 1,
  Custom = 2,
}

export enum CustomUnavailabilityOption {
  UnavailableAllDay = 0,
  Available = 1,
  UnavailableSpecificHours = 2,
}

export enum OutsideAvailabilityType {
  OutsideGeneralAvailability = 'OUTSIDE_GENERAL_AVAILABILITY',
  Unavailability = 'UNAVAILABILITY',
}

export const WeekDays = { 0: 'SUN', 1: 'MON', 2: 'TUE', 3: 'WED', 4: 'THU', 5: 'FRI', 6: 'SAT' };

export enum PaymentSourceType {
  NDIS = 'NDIS',
  VCP = 'VCP',
}

export enum AvailabilityRequestCategory {
  GeneralAvailability = 1,
  Unavailability = 2,
}

export enum GroupSessionWarningType {
  ShiftClash = 'shiftClash',
  Caution = 'caution',
  Unavailable = 'unavailable',
  InSession = 'inSession',
}

export enum ShiftStatus {
  UPCOMING = 'UPCOMING',
  START = 'START',
  LATE_TO_START = 'LATE_TO_START',
  INPROGRESS = 'INPROGRESS',
  LATE_TO_FINISH = 'LATE_TO_FINISH',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELLED',
  CANCELED_BY_WORKSPACE_USER = 'CANCELED_BY_WORKSPACE_USER',
  DECLINED_BY_USER = 'DECLINED_BY_USER',
  UNFINISHED = 'UNFINISHED',
  REQUIRE = 'REQUIRE',
  PENDING = 'PENDING',
  NO_SHOW = 'NO_SHOW',
  APPLIED = 'APPLIED',
  CONFIRMED = 'CONFIRMED',
  AVAILABLE = 'AVAILABLE',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  DECLINED_BY_MANAGER = 'DECLINED_BY_MANAGER',
  ERROR = 'ERROR',
  ARCHIVED = 'ARCHIVED',
  SENT_TO_FINANCE = 'SENT_TO_FINANCE',
}

export enum InvoiceErrorType {
  SELF = 'SELF',
  NDIA = 'NDIA',
  PLAN = 'PLAN',
  NO_SERVICE_AGREEMENT = 'NO_SERVICE_AGREEMENT',
}

export enum FormDateType {
  TIME_ONLY = 'Time only',
  DATE_ONLY = 'Date only',
  DATE_TIME = 'Date & Time',
}

export enum BrandingType {
  LOGO = 'LOGO',
  SQUARE_ICON = 'SQUARE_ICON',
}
export enum NonFaceToFaceFrequency {
  PER_HOUR = 'hour',
  PER_BOOKING = 'booking',
}

export enum InvitationInitialExpiryDays {
  REMINDER = 14,
}

export enum INTAKE_FORM_ELEMENT_TAB {
  GENERAL_INFORMATION_TAB_ELEMENTS = 'GENERAL_INFORMATION_TAB_ELEMENTS',
  ENQUIRIES_ELEMENTS = 'ENQUIRIES_ELEMENTS',
  CONTACTS_TAB_ELEMENTS = 'CONTACTS_TAB_ELEMENTS',
  CARE_INFORMATION_TAB_ELEMENTS = 'CARE_INFORMATION_TAB_ELEMENTS',
  FUNDING_TAB_ELEMENTS = 'FUNDING_TAB_ELEMENTS',
}

export enum STRAITS_LANDER_STATUS {
  yes = 'Yes',
  no = 'No',
  preferNotToSay = 'Prefer not to say',
}

export enum GoalType {
  LONG_TERM = 'longTerm',
  SHORT_TERM = 'shortTerm',
}

export enum ExportFormType {
  USER_FORM = 'USER_FORM',
  ATTENDANCE_FORM = 'ATTENDANCE_FORM',
  SERVICE_DATETIME_FORM = 'SERVICE_DATETIME_FORM',
  WORKFLOW_FORM = 'WORKFLOW_FORM',
}

export enum ClaimType {
  DIRECT_SERVICE = 'STD',
  STANDARD_SERVICE = 'STD',
  CANCELLATION = 'CANC',
  TRAVEL = 'TRAN',
  REPORT_WRITING = 'REPW',
  NON_FACE_TO_FACE = 'NF2F',
  TELEHEALTH_SUPPORTS = 'THLT',
  IRREGULAR_SIL_SUPPORTS = 'IRSS',
}
