import { Inline, Text } from '@goodhuman-me/components';
import { ChevronRight } from 'design-components/icon';
import React from 'react';

interface IProps {
  label?: string;
  onPress?: () => void;
}

const OptionSelectRow: React.FC<IProps> = ({ label, onPress }) => {
  return (
    <div style={{ cursor: 'pointer', padding: 8 }} onClick={onPress}>
      <Inline gap="$xxlarge" justifyContent="space-between" alignItems="center">
        <Text color="$black" size="small" fontWeight="$normal">
          {label}
        </Text>
        <ChevronRight width="$size200" height="$size200" color="$bodyDark1" />
      </Inline>
    </div>
  );
};

export default OptionSelectRow;
