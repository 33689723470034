import _ from 'lodash';
import moment from 'moment-timezone';
import { BookingStatus, ShiftSlotStatus } from 'utilities/enum-utils';

/**
 * Extract a list of unique customers from a list of bookings.
 */
export function uniqueCustomers(bookingsArray) {
  return _.chain(bookingsArray)
    .map((b) => ({
      customerId: b.bookerUserId,
      customerFirstName: b.customerFirstName,
      customerLastName: b.customerLastName,
      customerAvatarUrl: b.attachmentUrl,
    }))
    .uniqBy((c) => c.customerId)
    .sortBy((c) => c.customerFirstName)
    .value();
}

/**
 * Extract a list of unique workers from a list of shifts.
 */
export function uniqueWorkers(shiftsArray) {
  return _.chain(shiftsArray)
    .map((s) => ({
      supportWorkerId: s.supportWorkerId,
      workerFirstName: s.workerFirstName,
      workerLastName: s.workerLastName,
      workerAvatarUrl: s.workerAttachmentUrl,
    }))
    .filter((s) => !_.isEmpty(s.supportWorkerId))
    .uniqBy((s) => s.supportWorkerId)
    .sortBy((s) => s.workerFirstName)
    .value();
}

// Local utility functions
/**
 * Generate an array of hours based on the start/end hour.
 * eg 0-23 = ['12 AM', '1 AM','2 AM'...'10 PM', '11 PM']
 */
export function generateHours(startHour, endHour, dateFormat = 'h A') {
  const hoursArray = [];

  // If an invalid start/end input is passed
  if (startHour > endHour) {
    hoursArray.push(startHour);
  } else {
    for (let i = startHour; i <= endHour; i++) {
      hoursArray.push(i);
    }
  }

  // Set the hours as 12 AM, 1 AM... etc
  return _.map(hoursArray, (hour) => moment().set('hour', hour).set('minute', 0).format(dateFormat));
}

// Retrieves the entire week for the day.
export function getEntireWeekForDay(day) {
  const startOfWeek = moment(day).startOf('isoWeek');

  return _.times(7, (i) => moment(startOfWeek).add(i, 'days').toDate());
}

export function collateDuplicateShifts(shifts) {
  // Group shifts into services
  const shiftsByServices = _.groupBy(shifts, function (shift) {
    return shift.serviceDateTimeId + '-' + shift.bookingId;
  });

  // get keys for the grouped services
  const services = _.keys(shiftsByServices);

  return _.chain(services)
    .map((service) => {
      // Finally, group everything inside by StartDateTime
      const groupByDates = _.groupBy(shiftsByServices[service], 'shiftStartDateTime');

      return _.chain(groupByDates)
        .keys()
        .map((key) => ({
          // Get the first occurring row of the object.
          ..._.head(groupByDates[key]),
          // Append a new property showing how many duplicates for this shift
          duplicateCount: _.size(groupByDates[key]),
        }))
        .value();
    })
    .flatten()
    .value();
}

// Groups the bookings into multiple arrays without overlaps.
export function groupWithoutOverlaps(data: any, { startDateKey = 'startDateTime', endDateKey = 'endDateTime' }) {
  // Local function to detect if there are any overlaps between start/end dates for two bookings.
  function isOverlapping(a, b) {
    return (
      (a[startDateKey] >= b[startDateKey] && a[startDateKey] <= b[endDateKey]) ||
      (a[endDateKey] >= b[startDateKey] && a[endDateKey] <= b[endDateKey]) ||
      (b[startDateKey] >= a[startDateKey] && b[startDateKey] <= a[endDateKey]) ||
      (b[endDateKey] >= a[startDateKey] && b[endDateKey] <= a[endDateKey])
    );
  }

  // Working stack
  const stack = _.sortBy([...data], [startDateKey]);

  // Final output
  let results = [];

  while (stack.length > 0) {
    const current = stack.shift();

    let insertedIntoGroup = false;

    for (let i = 0; i < results.length; i++) {
      // Check if there's a place to insert (no overlaps) inside the group.
      const noClashesInGroup = _.every(results[i], (item) => !isOverlapping(item, current));

      if (noClashesInGroup) {
        // No overlaps found! Inserting into this group.
        results[i].push(current);
        insertedIntoGroup = true;
        break;
      }
    }

    // Couldn't find a group to insert into. Create a new group.
    if (!insertedIntoGroup) {
      results = [...results, [current]];
    }
  }

  return results;
}

export function getBookingTheme(bookingStatus: BookingStatus) {
  let bgColor = 'bg-blue-lightest';
  let borderColor = 'border-blue-lighter';
  let textTagTheme = 'dark';
  let textTagColor = 'blue';
  let textTagWeight = 'regular';

  let statusLabel = _.chain(bookingStatus).capitalize().replace('_', ' ').replace('-', ' ').value();

  switch (bookingStatus) {
    case BookingStatus.PENDING:
      bgColor = 'bg-quaternary';
      borderColor = 'border-quaternary';
      textTagTheme = 'dark';
      textTagColor = 'gold';
      textTagWeight = 'bold';
      break;
    case BookingStatus.ACCEPTED:
      bgColor = 'bg-green-lightest';
      borderColor = 'border-green-lightest';
      textTagTheme = 'dark';
      textTagColor = 'green';
      textTagWeight = 'bold';
      break;
    case BookingStatus.CONFIRMED:
      bgColor = 'bg-blue-lightest';
      borderColor = 'border-blue-lightest';
      textTagTheme = 'dark';
      textTagColor = 'blue';
      textTagWeight = 'bold';
      break;
    case BookingStatus.INPROGRESS:
      bgColor = 'bg-orange-lightest';
      borderColor = 'border-orange-lightest';
      textTagTheme = 'dark';
      textTagColor = 'orange';
      textTagWeight = 'bold';
      statusLabel = 'In progress';
      break;
    case BookingStatus.COMPLETED:
      bgColor = 'bg-indigo-lightest';
      borderColor = 'border-indigo-lightest';
      textTagTheme = 'dark';
      textTagColor = 'indigo';
      textTagWeight = 'bold';
      statusLabel = 'Completed';
      break;
    case BookingStatus.CUSTOMER_CANCELLED:
      bgColor = 'bg-quaternary';
      borderColor = 'border-quaternary';
      textTagTheme = 'light';
      textTagColor = 'red';
      textTagWeight = 'bold';
      break;
    case BookingStatus.CUSTOMER_CANCELLED_WITH_FEE:
      bgColor = 'bg-quaternary';
      borderColor = 'border-quaternary';
      textTagTheme = 'light';
      textTagColor = 'red';
      textTagWeight = 'bold';
      statusLabel = 'Customer cancelled - fee';
      break;
    case BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE:
      bgColor = 'bg-quaternary';
      borderColor = 'border-quaternary';
      textTagTheme = 'light';
      textTagColor = 'red';
      textTagWeight = 'bold';
      statusLabel = 'Customer cancelled - No fee';
      break;
    case BookingStatus.BUSINESS_CANCELLED:
      bgColor = 'bg-quaternary';
      borderColor = 'border-quaternary';
      textTagTheme = 'light';
      textTagColor = 'rose';
      textTagWeight = 'bold';
      break;
    case BookingStatus.REJECTED:
      bgColor = 'bg-red-lightest';
      borderColor = 'border-quaternary';
      textTagTheme = 'light';
      textTagColor = 'red';
      textTagWeight = 'bold';
      break;
    case BookingStatus.ARCHIVED:
      bgColor = 'bg-tertiary';
      borderColor = 'border-tertiary';
      textTagTheme = 'light';
      textTagColor = 'secondary';
      break;
  }

  return { bgColor, borderColor, textTagColor, textTagTheme, textTagWeight, statusLabel };
}

export function getShiftSlotTheme(shiftStatus: ShiftSlotStatus) {
  let bgColor = 'bg-blue-lightest';
  let borderColor = 'border-blue-lighter';
  let textTagTheme = 'dark';
  let textTagColor = 'blue';
  let textTagWeight = 'regular';

  let statusLabel = _.chain(shiftStatus).capitalize().replace('_', ' ').replace('-', ' ').value();

  switch (shiftStatus) {
    case ShiftSlotStatus.PENDING:
      bgColor = 'bg-quaternary';
      borderColor = 'border-quaternary';
      textTagTheme = 'dark';
      textTagColor = 'gold';
      textTagWeight = 'bold';
      break;
    case ShiftSlotStatus.CONFIRMED:
      bgColor = 'bg-blue-lightest';
      borderColor = 'border-blue-lightest';
      textTagTheme = 'dark';
      textTagColor = 'blue';
      textTagWeight = 'bold';
      break;
    case ShiftSlotStatus.UNASSIGNED:
      bgColor = 'bg-orange-lightest';
      borderColor = 'border-orange-lighter border-dashed ';
      textTagTheme = 'dark';
      textTagColor = 'orange';
      textTagWeight = 'bold';
      break;
    case ShiftSlotStatus.CHECKED_IN:
      bgColor = 'bg-gold-lightest';
      borderColor = 'border-gold-lightest';
      textTagTheme = 'dark';
      textTagColor = 'gold';
      textTagWeight = 'bold';
      break;
    case ShiftSlotStatus.CHECKED_OUT:
      bgColor = 'bg-green-lightest';
      borderColor = 'border-green-lightest';
      textTagTheme = 'dark';
      textTagColor = 'green';
      textTagWeight = 'bold';
      break;
    case ShiftSlotStatus.CANCELLED:
      bgColor = 'bg-quaternary';
      borderColor = 'border-quaternary-lightest';
      textTagTheme = 'light';
      textTagColor = 'red';
      textTagWeight = 'bold';
      break;
    case ShiftSlotStatus.PUBLISHING:
      bgColor = 'bg-turquoise-lightest';
      borderColor = 'border-turquoise-lightest';
      textTagTheme = 'dark';
      textTagColor = 'turquoise';
      textTagWeight = 'bold';
      break;
    case ShiftSlotStatus.CLAIMED:
      bgColor = 'bg-cobalt-lightest';
      borderColor = 'border-cobalt-lightest';
      textTagTheme = 'dark';
      textTagColor = 'cobalt';
      textTagWeight = 'bold';
      break;
    case ShiftSlotStatus.REJECTED:
      bgColor = 'bg-rose-lightest';
      borderColor = 'border-rose-lightest';
      textTagTheme = 'dark';
      textTagColor = 'rose';
      textTagWeight = 'bold';
      break;
    case ShiftSlotStatus.APPROVED:
      bgColor = 'bg-cobalt-lightest';
      borderColor = 'border-cobalt-lightest';
      textTagTheme = 'dark';
      textTagColor = 'cobalt';
      textTagWeight = 'bold';
      break;
    case ShiftSlotStatus.NO_SHOW:
      bgColor = 'bg-red-lightest';
      borderColor = 'border-red-lightest';
      textTagTheme = 'dark';
      textTagColor = 'sepia';
      textTagWeight = 'bold';
      break;
    case ShiftSlotStatus.DECLINED:
      bgColor = 'bg-rose-lightest';
      borderColor = 'border-rose-lightest';
      textTagTheme = 'dark';
      textTagColor = 'rose';
      textTagWeight = 'bold';
      break;
    case ShiftSlotStatus.CANCELLED_PAID:
      bgColor = 'bg-quaternary';
      borderColor = 'border-quaternary';
      textTagTheme = 'light';
      textTagColor = 'blue';
      textTagWeight = 'bold';
      statusLabel = 'Cancelled - Paid';
      break;
    case ShiftSlotStatus.CANCELLED_UNPAID:
      bgColor = 'bg-quaternary';
      borderColor = 'border-quaternary';
      textTagTheme = 'light';
      textTagColor = 'vermilion';
      textTagWeight = 'bold';
      statusLabel = 'Cancelled - Unpaid';
      break;
    case ShiftSlotStatus.SENT_TO_FINANCE:
      bgColor = 'bg-blue-lightest';
      borderColor = 'border-blue-lightest';
      textTagTheme = 'light';
      textTagColor = 'blue';
      textTagWeight = 'bold';
      statusLabel = 'Sent to finance';
      break;
  }

  return { bgColor, borderColor, textTagColor, textTagTheme, textTagWeight, statusLabel };
}
