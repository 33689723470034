import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ArrowBottomRight = forwardRef(function ArrowBottomRight(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.47 5.47a.75.75 0 0 1 1.06 0l10.72 10.72V8.4a.75.75 0 0 1 1.5 0V18a.75.75 0 0 1-.75.75H8.4a.75.75 0 0 1 0-1.5h7.79L5.47 6.53a.75.75 0 0 1 0-1.06Z"
        fill="currentColor"
      />
    </Icon>
  );
});
