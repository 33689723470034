import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { GhostButton } from 'common-components/buttons';
import DisabledMenuItem from 'common-components/items/DisabledMenuItem';
import { Text } from 'common-components/typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface IScheduleListItemProps {
  scheduleName: string;
  schedulePeriodText: string;
  displayTimeSlots: string[];
  onDeleteSchedule?: () => void | undefined;
  onExtendSchedule?: () => void | undefined;
  disallowExtensionReason?: string;
}

const ScheduleListItem: React.FC<PropsWithChildren<IScheduleListItemProps>> = ({
  scheduleName,
  schedulePeriodText,
  displayTimeSlots,
  onDeleteSchedule,
  onExtendSchedule,
  disallowExtensionReason,
  children,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'schedules.item' });

  const { extendScheduleModal: extendingSchedulesEnabled } = useFlags();
  const canExtendSchedule = onExtendSchedule && extendingSchedulesEnabled && disallowExtensionReason === undefined;

  // Parse the time slot dates from the schedule config to a list of displayable time slots
  return (
    <div className="bg-white p-medium rounded-big mb-medium relative bordered border-standard-gray">
      <Text weight="bold" className="line-height-120">
        {scheduleName}
      </Text>
      <div className="mb-medium ">
        <Text className="line-height-120">{schedulePeriodText}</Text>
      </div>

      {displayTimeSlots.map((timeSlotText, index) => (
        <div
          className="bg-quaternary mb-small p-x-small rounded"
          style={{ width: 'min-content', whiteSpace: 'nowrap' }}
          key={index}
        >
          <Text size="regular">
            <span className="text-color-secondary">{t('every')}</span> {timeSlotText}
          </Text>
        </div>
      ))}

      {children}

      <div className="absolute" style={{ top: '16px', right: '16px' }}>
        {/* Only render out the popover menu if we're given some functionality for it */}
        {(onDeleteSchedule || onExtendSchedule) && (
          <Popover2
            content={
              <Menu className="pv-small ph-none shadow-container" style={{ width: '200px' }}>
                {canExtendSchedule && (
                  <MenuItem
                    label=""
                    className="hover-bg-secondary"
                    text={t('extendAction')}
                    onClick={onExtendSchedule}
                  />
                )}
                {disallowExtensionReason && <DisabledMenuItem label={disallowExtensionReason} />}
                {onDeleteSchedule && (
                  <MenuItem
                    label=""
                    className="hover-bg-secondary"
                    text={t('deleteAction')}
                    onClick={onDeleteSchedule}
                  />
                )}
              </Menu>
            }
            popoverClassName="mb-medium"
            position="bottom-right"
            interactionKind="click"
          >
            <div
              className="bordered border-standard-gray rounded text-align-center"
              style={{ width: '28px', height: '28px' }}
            >
              <GhostButton icon="ellipsis" size="small" paddingSize="x-small" />
            </div>
          </Popover2>
        )}
      </div>
    </div>
  );
};

export default ScheduleListItem;
