import React from 'react';
import { Split, Well } from '@goodhuman-me/components';

import { BudgetingPeriod } from './budgeting-period';
import { BudgetingSummary } from './budgeting-summary';

import type { Budget } from '../../../../budget';

export type ServiceBudgetingOverviewProps = Pick<Budget, 'budget'> & Pick<Budget['overview'], 'service'>;

export function ServiceBudgetingOverview(props: ServiceBudgetingOverviewProps): JSX.Element {
  const { budget, service } = props;

  return (
    <Well>
      <Split
        fraction={{
          '@initial': 'half',
          '@large': 'one-quarter',
        }}
        gap="$xlarge"
        collapseAt="768px"
      >
        <BudgetingPeriod {...service} padding="$xlarge" />
        <BudgetingSummary {...budget} {...service} />
      </Split>
    </Well>
  );
}
