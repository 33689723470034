import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Avatar } from '@good/ui/core';
import { Col, Icon, List } from 'antd';
import { Row } from 'antd/es';
import { GhostButton } from 'common-components/buttons';
import { Paragraph, Text } from 'common-components/typography';
import { IBillingAccessMember } from 'interfaces/subscription-interfaces';
import React from 'react';

interface Props {
  contact: IBillingAccessMember;
  hasRemovePermission: boolean;
  isPrimaryOwner?: boolean;
  onOpenRemoveBillingAccess(): void;
}

const BillingContactItem = (props: Props) => {
  const { contact, hasRemovePermission, isPrimaryOwner, onOpenRemoveBillingAccess } = props;

  return (
    <List.Item className='pv-medium'>
      <Row className='flex width-full align-center'>
        <Col span={3}>
          <Avatar src={contact.avatarUrl} />
        </Col>
        <Col span={hasRemovePermission ? 8 : 10} className='flex flex-column align-self-center'>
          <Text size='regular' weight='bolder'>
            {contact.firstName} {contact.lastName}
          </Text>
          {isPrimaryOwner && <Text size='regular'>Primary Owner</Text>}
        </Col>
        <Col span={11}>
          <Text size='regular' className='wrap-break-word'>
            {contact.email}
          </Text>
        </Col>
        {hasRemovePermission && !isPrimaryOwner && (
          <Col span={2}>
            <Popover2
              content={
                <Menu>
                  <MenuItem
                    text={
                      <Paragraph className='p-small m-none' size='regular' color='red-dark'>
                        Remove contact
                      </Paragraph>
                    }
                    onClick={onOpenRemoveBillingAccess}
                  />
                </Menu>
              }
              position={'bottom-left'}
              modifiers={{ arrow: { enabled: false } }}
            >
              <GhostButton paddingSize='small' color='secondary'>
                <Icon type='ellipsis' className='text-size-x3-large cursor-pointer' />
              </GhostButton>
            </Popover2>
          </Col>
        )}
      </Row>
    </List.Item>
  );
};

export default BillingContactItem;
