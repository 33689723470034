import React, { forwardRef } from 'react';
import { Select } from 'antd';
import { type SelectProps } from 'antd/es/select';

import { type CountryCode, useLocaleConfig } from 'providers/locale-provider';
import { states } from 'providers/locale-provider/states';

type SelectStateAntdProps = SelectProps<string> & {
  countryCode?: CountryCode;
};

/**
 * Antd component for selecting a country state.
 */
export const SelectStateAntd = forwardRef<Select<string>, SelectStateAntdProps>(
  ({ countryCode, ...selectProps }, ref) => {
    const { current: currentLocaleConfig } = useLocaleConfig();
    const currentCountryStates = states[countryCode ?? currentLocaleConfig.countryCode];

    return (
      <Select
        ref={(ref_) => {
          if (ref) {
            if (typeof ref === 'function') {
              ref(ref_);
            } else {
              ref_ = ref.current;
            }
          }
          return ref_;
        }}
        {...selectProps}
      >
        {currentCountryStates.map((state) => (
          <Select.Option value={state.abbreviation} key={state.abbreviation}>
            {state.name}
          </Select.Option>
        ))}
      </Select>
    );
  },
);

SelectStateAntd.displayName = 'SelectStateAntd';
