import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Chats = forwardRef(function Chats(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 11a7.25 7.25 0 0 1 13.976-2.711A6.75 6.75 0 1 0 16 21.75h6a.75.75 0 0 0 .596-1.206 21.872 21.872 0 0 1-.585-.8c-.133-.198-.18-.297-.2-.356a1.312 1.312 0 0 1-.055-.198 1.308 1.308 0 0 1-.006-.205c0-.042.022-.183.133-.537.105-.334.263-.781.483-1.404.216-.61.384-1.315.384-2.044a6.752 6.752 0 0 0-4.304-6.293A8.75 8.75 0 0 0 1.25 11c0 .942.197 1.863.455 2.668.251.787.435 1.36.558 1.79.128.446.169.663.173.765.009.228.005.323-.006.402a2.275 2.275 0 0 1-.105.388c-.087.264-.309.61-.943 1.563A.75.75 0 0 0 2 19.75h6a.75.75 0 0 0 0-1.5H3.38c.168-.284.292-.531.37-.768.075-.23.135-.43.166-.649.03-.22.028-.428.019-.67-.012-.305-.105-.683-.23-1.12-.13-.453-.32-1.047-.567-1.818l-.004-.013c-.227-.71-.384-1.471-.384-2.212Zm18.5 4A5.25 5.25 0 1 0 16 20.25h4.561a2.68 2.68 0 0 1-.288-.833 2.68 2.68 0 0 1-.023-.45c.004-.29.094-.623.202-.968.114-.363.281-.836.495-1.44l.005-.015c.18-.508.298-1.038.298-1.544Z"
        fill="currentColor"
      />
    </Icon>
  );
});
