import { Card, Collapse } from '@blueprintjs/core';
import { Avatar, Divider, Icon, List } from 'antd';
import { DragVerticalSVG } from 'assets/UndrawSVG';
import { HyperlinkButton, IconButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { WorkflowTemplateStepApprovalType, WorkflowTemplateStepType } from 'utilities/enum-utils';
import { getRoleDetail } from 'views/workflows/utils/workflow-utils';
import { IWorkflowTemplateStep } from '../../../../../../../../interfaces/workflow-interfaces';

interface Props {
  isViewState: boolean;
  step: IWorkflowTemplateStep;
  isManualTrigger?: boolean;
  amountStep?: number;
  onEditStep?: () => void;
  onDeleteStep?: () => void;
  onPreviewForm?: (step) => void;
}

interface State {
  isOpen: boolean;
}

class StepViewListItem extends PureComponent<Props, State> {
  state = {
    isOpen: false,
  };

  render() {
    const { step, amountStep, isViewState, isManualTrigger, onDeleteStep, onEditStep, onPreviewForm } = this.props;
    const { isOpen } = this.state;
    const enableDeleteButton = isManualTrigger ? amountStep > 1 : step.stepNumber > 0;

    return (
      <div className="mb-medium">
        <Card
          className={`flex align-center pl-none cursor-pointer pv-medium pr-medium ${
            isOpen ? 'rounded-big-top mb-x2-small' : 'rounded-big'
          }`}
          onClick={() => this.setState({ isOpen: !isOpen })}
        >
          {!isViewState && (
            <div className="flex align-center justify-center" style={{ minWidth: '40px' }}>
              {step.type === WorkflowTemplateStepType.STEP && <img src={DragVerticalSVG} alt="" />}
            </div>
          )}

          <Icon
            type="right"
            className="mh-12"
            style={{
              transition: 'all 0.3s',
              transform: isOpen ? 'rotate(90deg)' : undefined,
            }}
          />

          {step.type === WorkflowTemplateStepType.TRIGGER ? (
            <Text weight="bolder" size="large" className="flex-1 ml-12">
              Trigger step
            </Text>
          ) : (
            <Text weight="bolder" size="large" className="flex-1 ml-12">
              Step {step.stepNumber} - &nbsp;<Text weight="regular">{step.name}</Text>
            </Text>
          )}

          {!isViewState && (
            <div>
              {enableDeleteButton && (
                <IconButton
                  icon={'delete'}
                  shape="circle"
                  color="white"
                  size="small"
                  iconColor={'blue-action'}
                  className="mr-small"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onDeleteStep();
                  }}
                />
              )}

              <IconButton
                icon={'edit'}
                shape="circle"
                color="white"
                size="small"
                iconColor={'blue-action'}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onEditStep();
                }}
              />
            </div>
          )}
        </Card>

        <Collapse isOpen={isOpen} keepChildrenMounted>
          <div className="bg-tertiary p-12 rounded-big-bottom">
            <SubTitle color="secondary" size={'regular'}>
              STEP DETAILS
            </SubTitle>

            <div className="flex-column bg-white rounded-big p-medium mt-12">
              <SubTitle color="secondary" size={'regular'}>
                APPROVERS
              </SubTitle>

              {step.type === WorkflowTemplateStepType.TRIGGER ? (
                <Text>n/a</Text>
              ) : (
                <>
                  {step.approvalType === WorkflowTemplateStepApprovalType.ROLE && (
                    <>
                      <Text className="pt-medium">Approvers by role</Text>

                      <div
                        className="overflow-y-scroll bordered-top bordered-bottom mt-medium"
                        style={{ maxHeight: '200px' }}
                      >
                        <List
                          itemLayout="horizontal"
                          dataSource={step.stepApprovers}
                          renderItem={(item) => {
                            const roleDetail = getRoleDetail(item.approverPermissionRole);
                            if (!roleDetail) {
                              return <></>;
                            }

                            return (
                              <List.Item className="p-medium">
                                <Text size="large" weight="bolder">
                                  {roleDetail.name}

                                  {roleDetail.description && (
                                    <Text size="large" weight="regular">
                                      {roleDetail.description}
                                    </Text>
                                  )}
                                </Text>
                              </List.Item>
                            );
                          }}
                        />
                      </div>
                    </>
                  )}

                  {step.approvalType === WorkflowTemplateStepApprovalType.TEAM_MEMBER && (
                    <>
                      <Text className="pt-medium">Specific approvers</Text>

                      <div
                        className="overflow-y-scroll bordered-top bordered-bottom mt-medium"
                        style={{ maxHeight: '200px' }}
                      >
                        <List
                          itemLayout="horizontal"
                          dataSource={step.stepApprovers}
                          renderItem={(item) => {
                            return (
                              <List.Item>
                                <div className="flex align-center pl-medium">
                                  <Avatar icon="user" shape="circle" src={item.avatar} />
                                  <Text size="large" className="pl-small">
                                    {item.displayName}
                                  </Text>
                                </div>
                              </List.Item>
                            );
                          }}
                        />
                      </div>
                    </>
                  )}

                  {step.approvalType === WorkflowTemplateStepApprovalType.ANYONE && (
                    <Text className="pt-medium">Anyone can approve</Text>
                  )}
                </>
              )}

              <Divider className={'divider-medium'} />

              <SubTitle color="secondary" size={'regular'}>
                FORM
              </SubTitle>

              {!step.attachment && !step.formVersionId && <Text color="secondary">No form attached</Text>}

              {(step.attachment || step.formVersionId) && (
                <>
                  {step.attachment ? (
                    <Text className="mv-x-small">
                      <Text weight="bolder">External form/attachment:</Text>
                      &nbsp;{step.attachment.name}
                    </Text>
                  ) : (
                    <HyperlinkButton onClick={() => onPreviewForm(step)}>{step.formName}</HyperlinkButton>
                  )}
                </>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  currentForm: state.formBuilderStore.currentForm,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetFormDetailsByVersion: dispatch.formBuilderStore.doGetFormDetailsByVersion,
});

export default connect(mapState, mapDispatch)(StepViewListItem);
