import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const PresentationChart = forwardRef(function PresentationChart(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.236 3c0-.414.332-.75.742-.75H21.754c.41 0 .742.336.742.75s-.332.75-.742.75h-1.236v5.305c0 1.367 0 2.47-.115 3.337-.12.9-.376 1.658-.971 2.26-.595.602-1.345.86-2.235.982-.857.116-1.947.116-3.3.116h-1.289v1.825l4.584 2.782a.755.755 0 0 1 .254 1.029.737.737 0 0 1-1.018.257l-3.82-2.318V21c0 .414-.332.75-.742.75a.746.746 0 0 1-.742-.75v-1.675l-3.82 2.318a.737.737 0 0 1-1.018-.257.755.755 0 0 1 .255-1.03l4.583-2.78V15.75h-1.29c-1.352 0-2.442 0-3.3-.117-.89-.12-1.639-.38-2.234-.981-.595-.602-.851-1.36-.97-2.26-.116-.867-.116-1.97-.116-3.337V3.75H1.978A.746.746 0 0 1 1.236 3Zm10.63 11.25h1.978c1.419 0 2.409-.002 3.156-.103.725-.099 1.11-.28 1.383-.556.274-.277.452-.665.55-1.4.1-.755.102-1.756.102-3.191V3.75H4.697V9c0 1.435.002 2.437.102 3.192.098.734.276 1.122.55 1.399.274.277.658.457 1.383.556.747.101 1.737.103 3.156.103h1.978ZM8.652 9a.746.746 0 0 0-.741-.75.746.746 0 0 0-.742.75v2c0 .414.332.75.742.75s.741-.336.741-.75V9Zm3.214-2.75c.41 0 .742.336.742.75v4c0 .414-.332.75-.742.75a.746.746 0 0 1-.742-.75V7c0-.414.332-.75.742-.75ZM16.563 8a.746.746 0 0 0-.742-.75.746.746 0 0 0-.741.75v3c0 .414.332.75.741.75.41 0 .742-.336.742-.75V8Z"
        fill="currentColor"
      />
    </Icon>
  );
});
