import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { CompatRoute as Route } from 'react-router-dom-v5-compat';

// Services
import GroupServiceDetailsView from 'views/group-services/service-details/GroupServiceDetailsView';
import GroupServiceSessionView from 'views/group-services/session-details/GroupServiceSessionView';
import PrintSessionRosterView from 'views/group-services/session-details/print-rosters/PrintSessionRosterView';
import ServiceLandingView from 'views/services/ServiceLandingView';
import ServiceDetailsView from 'views/services/details/ServiceDetailsView';

// Team
import InviteTeamMember from 'views/team-v2/TeamViewV2';
import { TeamListingViewV2 } from 'views/team/TeamListingViewV2';
import TeamAllViewsV2 from 'views/team/all-views/TeamAllViewsV2';
import { TeamDetailsView } from 'views/team/details/TeamDetailsView';

// Bookings
import BookingAllViews from '../views/bookings/BookingViews/BookingAllViews';

// Customers
import CustomerLandingView from 'views/customers/CustomerLandingView';

// Notifications
import NotificationsLandingView from 'views/notifications';
// Billing review
import BookingApproveView from 'views/bookings/BookingApproveView';
import PDFPreviewView from '../views/pdf-viewer/PDFPreviewView';

// import BookingActualView from '../views/wip/design-wip/booking/BookingActualView';
import BookingDetailsViewV2 from '../views/bookings/details/BookingDetailsViewV2';

// Previous Booking Listings
// import BookingListingV2View from '../views/wip/design-wip/booking/BookingListingV2View';
import PayListBatchDetailsView from '../views/billings/BatchDetailView';
import { PaymentListingView } from '../views/billings/PaymentListingView';

//Customer Views
import CustomerAllViews from '../views/customers/CustomerViews/CustomerAllViews';
import { CustomerDetailPanel } from 'views/customers/details';

// Invite Users
import InviteUsersLandingView from 'views/invite-users/InviteUsersLandingView';
import PendingInvitationListingView from 'views/invite-users/listing/PendingInvitationListingView';

// -=== Messaging ===-
import MessagingLandingView from 'views/messaging/MessagingLandingView';
import RedirectToView from 'views/redirect-page/RedirectToView';

// -=== Account management ===-
import AccountAddMemberView from 'views/account-management/AccountAddMemberView';
import AccountExportDataView from 'views/account-management/AccountExportDataView';
import AccountLandingView from 'views/account-management/AccountLandingView';
import BrandingView from 'views/account-management/BrandingView';
import { CompanyDetailsView } from 'views/account-management/CompanyDetailsView/CompanyDetailsView';
import DebtorDetailsView from 'views/account-management/DebtorDetailsView';
import DebtorManagementView from 'views/account-management/DebtorManagementView';
import FeatureConfigurationView from 'views/account-management/FeatureConfigurationView';
import FinanceFileExportConfigurationView from 'views/account-management/FinanceFileExportConfigurationView';
import FundingAndPaymentView from 'views/account-management/FundingAndPaymentView';
import ServiceDepartmentsView from 'views/account-management/ServiceDepartmentsView';
import { TeamManagementView } from 'views/account-management/team-management-view/team-management-view';
import WorkflowTemplatesListView from 'views/account-management/WorkflowTemplatesListView';
import FormsManagementView from 'views/account-management/forms/FormsManagementView';
import TestPermissionView from 'views/wip/TestPermissionView';
import WorkflowTemplateDetailView from 'views/workflows/workflow-templates/view-edit/WorkflowTemplateDetailView';
import { CreateWorkflowTemplate } from '../views/account-management/WorkflowTemplatesCreateView';

import { AccountImportDataView } from 'views/account-management/imports';
import { AccountNewImportView } from 'views/account-management/imports/new';
import { AccountShowImportView } from 'views/account-management/imports/show';

// -=== Access denied ===-
import AccessDeniedView from 'views/exceptions/AccessDeniedView';

// -=== Plan Management ===-
import ServiceAgreementPaymentMethodsView from 'views/account-management/ServiceAgreementPaymentMethodsView';
import ServiceAgreementTemplatesView from 'views/account-management/ServiceAgreementTemplatesView';
import GroupSessionsListView from 'views/group-services/session-listings/GroupSessionsListingView';

// -=== Roster view ===-
import TimeBasedAlertsConfigureView from 'views/account-management/TimeBasedAlertsConfigureView';
import RosterLandingView from 'views/bookings/roster-view/RosterLandingView';

// -=== Form Builder ===-
import FormBuilder from 'views/form-builder/FormBuilder';
import FormDetailsView from 'views/form-builder/components/details/FormDetailsView';

// -=== Workflow ===-
import WorkflowListingView from 'views/workflows/listing/WorkflowListingView';
import WorkflowDetailsView from 'views/workflows/manage-workflow/details/WorkflowDetailsView';

// -=== Subscription view ===-
import SubscriptionManagementView from 'views/account-management/subscription-management/SubscriptionManagementView';

// -=== Budget ===-
import WorkLeaveTypesConfigurationView from 'views/account-management/WorkLeaveTypesConfigurationView/index';
import SelectFormTemplateView from 'views/form-builder/SelectFormTemplateView';
import SelectFormTypeView from 'views/form-builder/SelectFormTypeView';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { ConnectedInsightsTableView } from 'views/insights/components/insights-table-view/insights-table-view';
import { Detail as BudgetDetail } from '../views/budgets';
import { PayPeriodConfigurationView } from 'views/account-management/PayPeriodConfig';
import { SilServiceView } from 'views/sil-service/sil-service-view';
import { ServiceAgreementService } from '../views/service-agreements/pages/service-agreement-service';
import { TestTrpcView } from 'views/wip/test-trpc-view';
import { TestMantineView } from 'views/wip/test-mantine-view';
import { AccountRoutes } from 'views/account/routes';
import { PageContainer } from 'layouts/page-container';
import { BookingsView } from 'views/bookings/bookings-view';
import { useNavBarItems } from 'layouts/app-container/navbar/use-navbar-sections';

export const AuthorizedRoutes: React.FC = () => {
  const { boom284WorkforceInsightsTablePage, pinc1511SilService } = useFlags();
  const navItems = useNavBarItems();
  const landingLink = navItems[0]?.link ?? '/bookings';

  return (
    <>
      <Switch>
        <Redirect exact from='/home' to={landingLink} />

        <Route path='/bookings/calendar' component={RosterLandingView} />

        {/* Messaging */}
        <Route path='/messaging' component={MessagingLandingView} />

        {/* Billings */}
        <Route path='/payments' component={PaymentListingView} />

        <Route exact path='/group-service/details/:serviceId' component={GroupServiceDetailsView} />

        <Route
          exact
          path='/group-service/:serviceId/session/details/:serviceDateTimeId'
          component={GroupServiceSessionView}
        />

        <Route
          exact
          path='/group-service/:serviceId/session/details/:serviceDateTimeId/print-roster'
          component={PrintSessionRosterView}
        />

        <Route exact path='/bookings' component={BookingsView} />

        <PageContainer>
          {/* Services */}
          <Route path='/services' component={ServiceLandingView} />
          {/* /service/details/:serviceId detail page for support service and support coordination service */}
          <Route exact path='/service/details/:serviceId' component={ServiceDetailsView} />

          {pinc1511SilService && <Route path='/service/sil/:serviceId/:tabName' component={SilServiceView} />}

          {/* Session listings for group services */}
          <Route path='/group-service/sessions' component={GroupSessionsListView} />

          {/* Teams */}
          <Route exact path='/team' component={TeamListingViewV2} />
          <Route exact path='/team/invite-member' component={InviteTeamMember} />
          <Route exact path='/team/details/:workerId' component={TeamDetailsView} />
          <Route exact path='/team/all-views' component={TeamAllViewsV2} />
          {boom284WorkforceInsightsTablePage && (
            <Route exact path='/team/team-insights' component={ConnectedInsightsTableView} />
          )}

          {/* Bookings */}
          {/* TODO? */}
          <Route exact path='/bookings-approve' component={BookingApproveView} />

          <Route path='/bookings/all-views' component={BookingAllViews} />

          {/*<Route exact path={'/bookings/details/:bookingId'} component={BookingActualView} />*/}
          <Route exact path='/bookings/details/:bookingId' component={BookingDetailsViewV2} />

          <Route exact path='/batch/details/:batchId' component={PayListBatchDetailsView} />

          {/* Customers */}
          <Route exact path='/customers' component={CustomerLandingView} />
          <Route path='/customer/details/:userId' component={CustomerDetailPanel} />
          <Route
            exact
            path='/customers/:customerId/service-agreements/:serviceAgreementId/service-agreement-service/:serviceAgreementServiceId'
            component={ServiceAgreementService}
          />
          <Route
            exact
            path='/customers/:customerId/service-agreements/:serviceAgreementId/budget/:serviceId'
            component={BudgetDetail}
          />

          {/* Customer Views */}
          <Route exact path='/customers/all-views' component={CustomerAllViews} />

          {/* Notifications */}
          <Route path='/notifications' component={NotificationsLandingView} />

          {/* Invite users */}
          <Route path='/invite-users' component={InviteUsersLandingView} />
          <Route path='/pending-invitations' component={PendingInvitationListingView} />

          {/* Profile */}
          <AccountRoutes />
          <Route path='/company' component={CompanyDetailsView} />

          <Route path='/pdf' component={PDFPreviewView} />

          <Route path='/redirect' component={RedirectToView} />

          {/*   Added by Jir : Account Management */}
          {/* TODO : Change to a better url maybe */}
          <Route path='/account/landing' component={AccountLandingView} />

          {/* Account management */}
          <Route path='/account/add-user' component={AccountAddMemberView} />
          <Route path='/account/service-department' component={ServiceDepartmentsView} />
          <Route path='/account/team-management' component={TeamManagementView} />
          <Route path='/account/funding-and-payment' component={FundingAndPaymentView} />
          <Route path='/account/testPermissions' component={TestPermissionView} />
          <Route path='/account/branding' component={BrandingView} />
          <Route exact path='/account/debtor-management' component={DebtorManagementView} />
          <Route exact path='/account/debtor-management/:debtorId' component={DebtorDetailsView} />
          <Route exact path='/account/workflows' component={WorkflowTemplatesListView} />
          <Switch>
            <Route exact path='/account/workflows/create' component={CreateWorkflowTemplate} />
            <Route path='/account/workflows/:workflowTemplateId' component={WorkflowTemplateDetailView} />
          </Switch>
          <Route
            path='/account/features/service-agreement-templates/:paymentMethod'
            component={ServiceAgreementTemplatesView}
          />
          <Route
            exact
            path='/account/features/service-agreement-templates'
            component={ServiceAgreementPaymentMethodsView}
          />
          <Route
            path='/account/features/service-time-based-alerts-configure'
            component={TimeBasedAlertsConfigureView}
          />
          <Route
            exact
            path='/account/features/finance-file-export-configuration'
            component={FinanceFileExportConfigurationView}
          />
          <Route
            path='/account/features/finance-file-export-configuration/work-types-leave-types'
            component={WorkLeaveTypesConfigurationView}
          />
          <Route
            path='/account/features/finance-file-export-configuration/pay-period'
            component={PayPeriodConfigurationView}
          />
          <Route exact path='/account/features' component={FeatureConfigurationView} />
          <Route path='/account/export-data' component={AccountExportDataView} />

          <Switch>
            <Route exact path='/account/imports' component={AccountImportDataView} />
            <Route path='/account/imports/new' component={AccountNewImportView} />
            <Route path='/account/imports/:jobId' component={AccountShowImportView} />
          </Switch>

          <Route exact path='/account/forms' component={FormsManagementView} />
          <Route exact path='/account/forms/newform' component={SelectFormTypeView} />
          <Route exact path='/account/forms/templates/:formType' component={SelectFormTemplateView} />
          <Route exact path='/account/form/:formId' component={FormDetailsView} />
          <Route path='/account/forms/create' component={FormBuilder} />
          <Route path='/account/forms/copy/:formId' component={FormBuilder} />
          <Route path='/account/forms/edit/:formId' component={FormBuilder} />
          <Route path='/account/subscription-management' component={SubscriptionManagementView} />

          {/* Workflows */}
          <Route exact path='/workflows' component={WorkflowListingView} />
          <Route exact path='/workflows/details/:workflowId' component={WorkflowDetailsView} />

          <Route exact path='/trpc/test' component={TestTrpcView} />
          <Route exact path='/mantine/test' component={TestMantineView} />

          {/* Access denied */}
          <Route path='/access-denied' component={AccessDeniedView} />
        </PageContainer>
      </Switch>
    </>
  );
};
