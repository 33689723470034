import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import { ActiveWorkflowsStatus } from 'utilities/enum-utils';

interface IActiveWorkflowTemplateStatusTagsProps {
  status: string;
}

class ActiveWorkflowTemplateStatusTags extends Component<IActiveWorkflowTemplateStatusTagsProps, any> {
  render() {
    const { status } = this.props;
    let label, bgColor, textColor, borderColor;

    switch (status) {
      case ActiveWorkflowsStatus.COMPLETED:
        label = 'Completed';
        bgColor = 'bg-green';
        textColor = 'text-color-green-lightest';
        borderColor = 'border-green';
        break;
      case ActiveWorkflowsStatus.INPROGRESS:
        label = 'In progress';
        bgColor = 'bg-orange-lightest';
        textColor = 'text-color-orange-dark';
        borderColor = 'border-orange-lighter';
        break;
      case ActiveWorkflowsStatus.CLOSED:
        label = 'Closed';
        bgColor = 'bg-red';
        textColor = 'text-color-white';
        borderColor = 'border-red';
        break;
      default:
        break;
    }

    const containerClassNames = `text-align-center pv-x-small ph-small text-size-regular inline-block rounded ${bgColor} ${borderColor} bordered mr-medium`;
    const textClassNames = `${textColor}`;
    return (
      <div className={containerClassNames}>
        <Text size={'regular'} className={textClassNames}>
          {label}
        </Text>
      </div>
    );
  }
}

export default ActiveWorkflowTemplateStatusTags;
