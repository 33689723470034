import React from 'react';
import { Title } from '@good/ui/core';

import { HeaderButtons } from './header-buttons';
import { useInlineForm } from './inline-form-context';

export const Header = () => {
  const { title, isEditing } = useInlineForm();

  return (
    <div className={['align-center flex h-11 justify-between pt-5', isEditing ? 'pb-12' : 'pb-8'].join(' ')}>
      <Title className='font-normal' order={3}>
        {title}
      </Title>
      <HeaderButtons />
    </div>
  );
};
