import React, { Component } from 'react';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph, Text, Title } from 'common-components/typography';
import { Avatar, Icon, notification } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import _ from 'lodash';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import moment from 'moment';
import WorkerStatusTag from 'common-components/tags/WorkerStatusTag';
import { BookingType, EditRecurringMode, ShiftSlotStatus } from 'utilities/enum-utils';
import { Menu, MenuItem, Popover } from '@blueprintjs/core';

interface IViewApplicantsModalProps {
  isOpen: any;
  onClose: any;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  doAssignWorker: typeof dispatch.bookingsStore.doAssignWorker;
}

interface IViewApplicantsModalState {
  isLoading: boolean;
  isSuccess: boolean;
}

class ViewApplicantsModal extends Component<IViewApplicantsModalProps, IViewApplicantsModalState> {
  state = {
    isLoading: false,
    isSuccess: false,
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (this.state.isLoading) {
    } else {
      onClose();
    }
  };

  private _onSubmitAssign = async (workerId: string, shiftSlotStatus: string) => {
    const { doAssignWorker, selectedBookingItem, onClose } = this.props;
    let isRemovePendingShiftSlots: boolean;
    if (shiftSlotStatus === ShiftSlotStatus.CONFIRMED) {
      isRemovePendingShiftSlots = true;
    } else if (shiftSlotStatus === ShiftSlotStatus.PENDING) {
      isRemovePendingShiftSlots = false;
    }
    const data = {
      bookingId: selectedBookingItem.bookingId,
      supportWorkerId: workerId,
      isRecurring: selectedBookingItem.isRecurring,
      startDateTime: selectedBookingItem.startDateTime,
      selectFromPublish: true,
      shiftSlotStatus,
      isRemovePendingShiftSlots,
      editRecurringMode: EditRecurringMode.Current,
      bookingRequestId: selectedBookingItem.bookingId,
      bookingType: BookingType.BOOKING,
    };
    try {
      this.setState({ isLoading: true });
      await doAssignWorker(data);
      this.setState({ isLoading: false });
      notification.success({
        message: 'Worker assigned.',
      });
      onClose();
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong, please try again.',
      });
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isOpen, selectedBookingItem } = this.props;

    return (
      <ActionModal
        title="Applicants"
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="x2-large"
        verticalAlignment="center"
      >
        {!this.state.isLoading && !this.state.isSuccess && (
          <div>
            <Paragraph className="mb-large">
              Choose a worker to assign to this shift from the list of applicants.
              <br />
              Once the worker is confirmed all other applicants will be notified as unsuccessful.
            </Paragraph>
            <div style={{ maxHeight: 'calc(100vh - 250px)', overflow: 'auto' }}>
              {selectedBookingItem.activeApplicants.length === 0 &&
              selectedBookingItem.unsuccessfulApplicants.length === 0 ? (
                <Paragraph>No applicants for this booking.</Paragraph>
              ) : (
                <>
                  {selectedBookingItem.activeApplicants.length > 0 && (
                    <div className="mb-x-large">
                      <Title level={4}>Active applicants ({selectedBookingItem.activeApplicants.length})</Title>
                      <Paragraph>
                        These workers have applied for this booking. Choosing a workers will automatically confirm them
                        for this shift.
                      </Paragraph>
                      <ApplicantRows
                        applicantList={selectedBookingItem.activeApplicants}
                        onSubmitAssign={this._onSubmitAssign}
                        shiftSlotStatus={'CONFIRMED'}
                      />
                    </div>
                  )}
                  {selectedBookingItem.unsuccessfulApplicants.length > 0 && (
                    <div className="mb-x-large">
                      <Title level={4}>
                        Unsuccessful applicants ({selectedBookingItem.unsuccessfulApplicants.length})
                      </Title>
                      <Paragraph>
                        These workers previously applied and were notified as being unsuccessful. Choosing one of these
                        workers will require them to confirm their availability for the shift.
                      </Paragraph>
                      <ApplicantRows
                        applicantList={selectedBookingItem.unsuccessfulApplicants}
                        onSubmitAssign={this._onSubmitAssign}
                        shiftSlotStatus={'PENDING'}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
      </ActionModal>
    );
  }
}

class ApplicantRows extends Component<{
  shiftSlotStatus: string;
  applicantList: any;
  onSubmitAssign: (workerId, shiftSlotStatus) => void;
}> {
  private _workerAttributesText = (worker) => {
    let attributes = [];
    if (!worker.onRoster && !worker.previouslyUsed) {
      attributes.push('Manually added');
    }
    if (worker.onRoster) {
      attributes.push('On roster');
    }
    if (worker.previouslyUsed) {
      attributes.push('Previously used');
    }

    return _.join(attributes, ', ');
  };

  render() {
    const { applicantList, onSubmitAssign, shiftSlotStatus } = this.props;

    return (
      <table className="width-full">
        <tbody>
          {_.map(applicantList, (applicant, key) => (
            <tr className={`${Number(key) === 0 && 'bordered-top'} bordered-bottom pv-small`}>
              <td style={{ width: '50px' }}>
                <Avatar icon="user" size="large" shape="square" src={applicant.attachmentUrl} />
              </td>
              <td>
                <table className="width-full">
                  <tbody>
                    <tr>
                      <td>
                        <Text weight="bold">
                          {applicant.firstName} {applicant.lastName}
                        </Text>
                      </td>
                    </tr>
                    <tr>
                      <td className="width-1/4">
                        <WorkerStatusTag shiftSlotStatus={applicant.availability} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <Icon type="info-circle" className="text-color-secondary mr-x-small" />
                {this._workerAttributesText(applicant)}
              </td>
              <td>
                <Icon type="clock-circle" className="text-color-secondary mr-x-small" />
                Applied {moment(applicant.shiftAppliedDate).format('DD MMMM')}
              </td>
              <td style={{ width: '160px' }} className="text-align-center">
                {applicant.availability === 'AVAILABLE' && (
                  <PrimaryButton
                    size="large"
                    onClick={() => onSubmitAssign(applicant.supportWorkerId, this.props.shiftSlotStatus)}
                  >
                    Assign to Shift
                  </PrimaryButton>
                )}
                {(applicant.availability === 'SHIFT_CLASH' || applicant.availability === 'UNAVAILABLE') && (
                  <Popover
                    position={'bottom-right'}
                    usePortal={false}
                    content={
                      <Menu>
                        <MenuItem
                          label={''}
                          className="hover-bg-blue-lightest mv-medium"
                          text={'Override and assign'}
                          onClick={() => onSubmitAssign(applicant.supportWorkerId, this.props.shiftSlotStatus)}
                        />
                      </Menu>
                    }
                  >
                    <div>
                      <span style={{ marginRight: '5px', fontWeight: 400 }}>Unavailable</span>
                      <SecondaryButton icon={'ellipsis'}></SecondaryButton>
                    </div>
                  </Popover>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedBookingItem: state.bookingsStore.selectedBookingItem });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAssignWorker: dispatch.bookingsStore.doAssignWorker,
});

export default connect(mapState, mapDispatch)(ViewApplicantsModal);
