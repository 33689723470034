import React, { useMemo } from 'react';
import { reactTrpc } from 'utilities/react-trpc';
import { useTranslation } from 'react-i18next';
import { SideNav, TabContent } from 'views/sil-service/components/side-nav/side-nav';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import {
  useSilServiceContext,
  SilServiceContextResolved,
} from 'views/sil-service/components/sil-service-provider/sil-service-provider';
import { ServiceFilesTab } from './tabs/service-files-tab';
import { CustomerFilesTab } from './tabs/customer-files-tab';
import { BookingFilesTab } from './tabs/booking-files-tab';
import { useNewRelicBrowser } from 'integrations/new-relic-browser';
import { FilesTabErrorState } from './components/error-state';
import { FilesTabLoadingState } from './components/loading-state';

export const FilesTab = () => {
  const {
    service: { serviceId },
  } = useSilServiceContext() as SilServiceContextResolved;
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  const { t } = useTranslation('', { keyPrefix: 'silService.filesTabs' });
  const newRelicBrowser = useNewRelicBrowser();

  const serviceProviderId = portalUser?.serviceProviderId ?? '';

  const queryParameters = useMemo(
    () => ({
      serviceId,
      serviceProviderId,
    }),
    [serviceId, serviceProviderId],
  );

  const serviceDocumentQuery = reactTrpc.services.serviceDocuments.list.useQuery(queryParameters);
  const customerDocumentQuery = reactTrpc.services.customerDocuments.list.useQuery(queryParameters);
  const bookingDocumentQuery = reactTrpc.services.bookingDocuments.list.useQuery(queryParameters);

  const tabs: TabContent[] = [
    {
      id: 1,
      title: `${t('serviceFiles.tabName')}`,
      content: useMemo(() => {
        if (serviceDocumentQuery.error) {
          newRelicBrowser?.noticeError(serviceDocumentQuery.error.message);
          return <FilesTabErrorState />;
        }
        if (serviceDocumentQuery.isLoading || serviceDocumentQuery.isFetching) {
          return <FilesTabLoadingState />;
        }
        return <ServiceFilesTab data={serviceDocumentQuery.data} />;
      }, [
        serviceDocumentQuery.error,
        serviceDocumentQuery.isLoading,
        serviceDocumentQuery.isFetching,
        serviceDocumentQuery.data,
        newRelicBrowser,
      ]),
      count: serviceDocumentQuery.data?.length ?? 0,
    },
    {
      id: 2,
      title: `${t('customerFiles.tabName')}`,
      content: useMemo(() => {
        if (customerDocumentQuery.error) {
          newRelicBrowser?.noticeError(customerDocumentQuery.error.message);
          return <FilesTabErrorState />;
        }
        if (customerDocumentQuery.isLoading || customerDocumentQuery.isFetching) {
          return <FilesTabLoadingState />;
        }
        return <CustomerFilesTab data={customerDocumentQuery.data} />;
      }, [
        customerDocumentQuery.error,
        customerDocumentQuery.isLoading,
        customerDocumentQuery.isFetching,
        customerDocumentQuery.data,
        newRelicBrowser,
      ]),
      count: customerDocumentQuery.data?.length ?? 0,
    },
    {
      id: 3,
      title: `${t('bookingFiles.tabName')}`,
      content: useMemo(() => {
        if (bookingDocumentQuery.error) {
          newRelicBrowser?.noticeError(bookingDocumentQuery.error.message);
          return <FilesTabErrorState />;
        }
        if (bookingDocumentQuery.isLoading || bookingDocumentQuery.isFetching) {
          return <FilesTabLoadingState />;
        }
        return <BookingFilesTab data={bookingDocumentQuery.data} />;
      }, [
        bookingDocumentQuery.error,
        bookingDocumentQuery.isLoading,
        bookingDocumentQuery.isFetching,
        bookingDocumentQuery.data,
        newRelicBrowser,
      ]),
      count: bookingDocumentQuery.data?.length ?? 0,
    },
  ];

  return (
    <>
      <SideNav tabs={tabs} />
    </>
  );
};
