import React, { Dispatch, SetStateAction } from 'react';
import { Input, notification } from 'antd';
import { debounce } from 'lodash';

type TmMgmtSearchParams = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  searchText: (txt: string) => Promise<void>;
  defaultSearchText: string;
};

export const TmMgmtSearch = ({ setIsLoading, searchText, defaultSearchText, isLoading }: TmMgmtSearchParams) => {
  const { Search } = Input;

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value ?? '';
    setIsLoading(true);
    debounceSearch(searchString)?.catch(() => notification.error({ message: 'Error occured while searching' }));
  };

  const debounceSearch = debounce(searchText, 500);
  return (
    <Search
      size="large"
      key="invite"
      placeholder="Search by name"
      defaultValue={defaultSearchText}
      loading={isLoading}
      style={{ width: '400px' }}
      allowClear={true}
      className="mr-large"
      onChange={onSearchChange}
    />
  );
};
