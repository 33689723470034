import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ListOrdered2 = forwardRef(function ListOrdered2(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2.25a.75.75 0 1 0 0 1.5h.25v2.5H5a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 0-1.5h-.25V3A.75.75 0 0 0 6 2.25H5ZM10.25 5a.75.75 0 0 1 .75-.75h10a.75.75 0 1 1 0 1.5H11a.75.75 0 0 1-.75-.75Zm.75 6.25a.75.75 0 0 0 0 1.5h10a.75.75 0 1 0 0-1.5H11Zm0 7a.75.75 0 0 0 0 1.5h10a.75.75 0 1 0 0-1.5H11ZM4.25 10A.75.75 0 0 1 5 9.25h2a.75.75 0 0 1 .67 1.085L6.215 13.25H7a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.67-1.085l1.456-2.915H5a.75.75 0 0 1-.75-.75ZM5 16.25a.75.75 0 0 0 0 1.5h1.25v.5H5a.75.75 0 0 0 0 1.5h1.25v.5H5a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 .75-.75v-4a.75.75 0 0 0-.75-.75H5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
