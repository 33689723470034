import moment from 'moment';
import CommonUtils from 'utilities/common-utils';
import { FilterType, WorkflowsStatus } from 'utilities/enum-utils';

export const FILE_EXTENSION_UPLOAD_SUPPORT = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.pdf',
  '.txt',
  '.ppt',
  '.pptx',
  '.doc',
  '.docx',
  '.csv',
  '.xls',
  '.xlsx',
  '.xlsm',
];

export const FILE_EXTENSION_INVALID_ERROR_MESSAGE = `This file extension is not supported, please choose the following formats: ${FILE_EXTENSION_UPLOAD_SUPPORT.join(
  '/',
)}`;

export const WORKFLOW_TEMPLATE_DETAIL_TAB_KEY = {
  CONFIGURATION: 'CONFIGURATION',
  STEPS: 'STEPS',
  ACTIVE_WORKFLOWS: 'ACTIVE_WORKFLOWS',
  VERSION_HISTORY: 'VERSION_HISTORY',
};

export const WORKFLOW_TEMPLATE_DETAIL_TABS = [
  {
    title: 'Configuration',
    key: WORKFLOW_TEMPLATE_DETAIL_TAB_KEY.CONFIGURATION,
  },
  {
    title: 'Steps',
    key: WORKFLOW_TEMPLATE_DETAIL_TAB_KEY.STEPS,
  },
  {
    title: 'Active workflows',
    key: WORKFLOW_TEMPLATE_DETAIL_TAB_KEY.ACTIVE_WORKFLOWS,
  },
  {
    title: 'Version history',
    key: WORKFLOW_TEMPLATE_DETAIL_TAB_KEY.VERSION_HISTORY,
  },
];

export const WORKFLOW_LISTING_VIEW_TAB_KEY = {
  REQUIRE_YOUR_APPROVAL: 'REQUIRES_YOUR_APPROVAL',
  ALL_WORKFLOWS: 'ALL_WORKFLOWS',
};

export const WORKFLOW_LISTING_VIEW_TABS = [
  {
    title: 'Requires your approval',
    key: WORKFLOW_LISTING_VIEW_TAB_KEY.REQUIRE_YOUR_APPROVAL,
  },
  {
    title: 'All incidents',
    key: WORKFLOW_LISTING_VIEW_TAB_KEY.ALL_WORKFLOWS,
  },
];

export const WORKFLOW_DETAIL_TAB_KEY = {
  SUBMITTED_FORMS: 'Submitted forms',
  ATTACHMENTS: 'Attachments',
  COMMENTS: 'Comments',
  ACTIVITY_LOG: 'Activity log',
  LINKED_FORM: 'Linked form',
};

export const WORKFLOWS_LIST_TYPE = {
  MY_WORKFLOWS: 'MY_WORKFLOWS',
  ALL_WORKFLOWS: 'ALL_WORKFLOWS',
};

export const defaultWorkflowListingFilterParams = {
  page: 1,
  pageSize: 20,
};

export const availableWorkflowListingFilters = [FilterType.DATE_RANGE, FilterType.ALL_WORKFLOWS_STATUS];

export const defaultWorkflowListingFilters = [
  {
    filter: FilterType.DATE_RANGE,
    values: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
    selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
      moment().startOf('isoWeek'),
      moment().endOf('isoWeek'),
    ]),
  },
  {
    filter: FilterType.ALL_WORKFLOWS_STATUS,
    values: [WorkflowsStatus.COMPLETED, WorkflowsStatus.INPROGRESS, WorkflowsStatus.CLOSED],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.ALL_WORKFLOWS_STATUS).fullSelectionName,
  },
];
