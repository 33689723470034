import { Form, notification } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ObsoleteStandardModal from 'common-components/modal/ObsoleteStandardModal';
import { Text, Title } from 'common-components/typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import AvailabilityConflictTimes from './AvailablilityConflictTimes';

interface IUnavailableModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  timezone?: string;
  conflictShifts: any;
  doApproveRequest: typeof dispatch.teamStore.doApproveRequest;
  supportWorkerAvailabilityRequestId: string;
}

interface IUnavailableModalState {
  selectedShiftsToKeep: any[];
}

class UnavailableModal extends Component<IUnavailableModalProps, IUnavailableModalState> {
  state = {
    selectedShiftsToKeep: [],
  };
  private _onSubmit = async () => {
    try {
      await this.props.doApproveRequest({
        supportWorkerAvailabilityRequestId: this.props.supportWorkerAvailabilityRequestId,
        shiftsSelectedToBeKept: this.state.selectedShiftsToKeep,
      });
      this.props.onClose();
      notification.success({
        message: 'General availability updated',
        description: 'You have successfully updated this team members general availability',
      });
    } catch (error) {
      this.props.onClose();
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  private _onChangeShiftsToKeep = (shifts) => {
    this.setState({ selectedShiftsToKeep: shifts });
  };

  private _renderContent = () => {
    return (
      <>
        <Title level={3}>Review upcoming shifts</Title>
        <Text className="mt-large">
          This team member is currently assigned to shift(s) that fall outside their updated availability. The team
          member will be removed fromthese shifts. Please{' '}
          <Text weight="bold">select the shifts (if any) you wish to keep for this team member</Text> or continue
          without selecting any.
        </Text>
        <div className="bg-quaternary pv-x-large ph-12 mt-x-large">
          <AvailabilityConflictTimes
            conflicts={this.props.conflictShifts}
            timezone={this.props.timezone}
            onSelectShifts={this._onChangeShiftsToKeep}
          />
        </div>
        <div className="mt-x3-large flex justify-end">
          <SecondaryButton size="large" onClick={() => this.props.onClose()}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" className="ml-small" onClick={this._onSubmit}>
            Done
          </PrimaryButton>
        </div>
      </>
    );
  };

  render() {
    return (
      <ObsoleteStandardModal
        visible={this.props.isOpen}
        onCancel={() => this.props.onClose()}
        footer={null}
        width={'60vw'}
      >
        <div className="ph-medium mv-small">{this._renderContent()}</div>
      </ObsoleteStandardModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doApproveRequest: dispatch.teamStore.doApproveRequest,
});

export default connect(null, mapDispatch)(Form.create<IUnavailableModalProps>()(UnavailableModal));
