import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle } from 'common-components/typography';
import { Form, notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';

interface IMarkAsUnPaidActionModalProps {
  isOpen: any;
  onClose: any;
  doMarkAsUnPaidInvoice: typeof dispatch.billingsStore.doMarkAsUnPaidInvoice;
  selectedInvoice: typeof state.billingsStore.selectedInvoice;
  selectedInvoices: typeof state.billingsStore.selectedInvoices;
  markAsUnPaidType: typeof state.billingsStore.markAsUnPaidType;
  setSelectedInvoices: typeof dispatch.billingsStore.setSelectedInvoices;
}

class MarkAsUnPaidActionModal extends Component<IMarkAsUnPaidActionModalProps, any> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    datePaid: new Date(moment.tz().format('YYYY-MM-DD HH:mm')),
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    const { isLoading } = this.state;
    if (!isLoading) {
      this.setState({ isSuccess: false, isError: false });
      onClose();
    }
  };

  private _onSubmit = async () => {
    const { selectedInvoice, selectedInvoices, doMarkAsUnPaidInvoice, onClose, markAsUnPaidType, setSelectedInvoices } =
      this.props;

    const invoiceList = markAsUnPaidType === 'MULTIPLE' ? selectedInvoices : selectedInvoice;

    this.setState({ isLoading: true });
    const invoiceIds = _.uniq(_.map(invoiceList, (invoice) => _.get(invoice, 'invoiceId', '')));

    try {
      await doMarkAsUnPaidInvoice({ invoiceIds: invoiceIds });
      this.setState({ isLoading: false, isSuccess: true });
      onClose();
      if (markAsUnPaidType === 'MULTIPLE') {
        setSelectedInvoices([]);
      }
      notification.success({
        message: 'Invoice marked as unpaid',
        description: (
          <p>
            You have marked{' '}
            <span style={{ fontWeight: 'bold' }}>
              {invoiceIds.length} invoice{invoiceIds.length > 1 ? 's' : ''}
            </span>{' '}
            as unpaid.
          </p>
        ),
      });
    } catch (e) {
      this.setState({ isLoading: false, isError: true });
    }
  };

  render() {
    const { isOpen } = this.props;
    const { isLoading } = this.state;

    return (
      <ActionModal
        title="Mark invoice as unpaid"
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        <div className="text-align-left">
          <div className="mb-medium">
            <Paragraph>
              Marking an invoice as unpaid will mark all non-rejected line items as unpaid and move them back to the
              processed tab.
              <br /> <br />
              Rejected line items will remain in the rejected area of payments.
            </Paragraph>
          </div>
        </div>

        <ActionModalFooter>
          <SecondaryButton size="large" onClick={this._onCloseModal} className="mr-medium" disabled={isLoading}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._onSubmit} loading={isLoading}>
            Mark as unpaid
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedInvoice: state.billingsStore.selectedInvoice,
  selectedInvoices: state.billingsStore.selectedInvoices,
  markAsUnPaidType: state.billingsStore.markAsUnPaidType,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doMarkAsUnPaidInvoice: dispatch.billingsStore.doMarkAsUnPaidInvoice,
  setSelectedInvoices: dispatch.billingsStore.setSelectedInvoices,
});

export default connect(mapState, mapDispatch)(MarkAsUnPaidActionModal);
