import React from 'react';
import { Combobox, InputBase, Input, useCombobox, Group, Text } from '@good/ui/core';
import { Controller, FieldPath, useFormContext, FieldValues } from 'react-hook-form';

type Item = {
  value: string;
  description?: string;
}

function SelectOption({ value, description }: Item) {
  return (
    <Group>
      <div>
        <Text fz="sm" fw={500}>
          {value}
        </Text>
        {description && <Text fz="xs" opacity={0.6}>
          {description}
        </Text>}
      </div>
    </Group>
  );
}

// For options with both a label and description,
// pass in an array of strings. The first item
// will be the label, and the second, a description.
type Options = {
  value: string;
  label: string | [string, string];
}[]

type SelectOptionProps = {
  placeholder: string;
  options: Options,
  styles: React.CSSProperties,
  name: FieldPath<FieldValues>
  customOnChange?: (value: unknown) => void;
};

const createOptionList = (options: Options) => {
  return options.map((item) => {
    const option = item.label instanceof Array ?
      <SelectOption value={item.label[0]} description={item.label[1]} />
      :
      <SelectOption value={item.label} />;

    return(
      <Combobox.Option value={item.value} key={item.value}>
        {option}
      </Combobox.Option>
    )
  });
}

const findMatchingLabel = (options: Options, value: string) => {
  const label = options.find( (opt) => opt.value === value )?.label

  if(label instanceof Array) return label[0]

  return label ?? ''
}

export function MultiLineCombobox({ placeholder, options, styles, name, customOnChange }: SelectOptionProps) {
  const form = useFormContext<FieldValues>()
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const optionList = createOptionList(options);

  return (
    <div>
      <Controller
        name={name}
        control={form.control}
        render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
          <>
            <Combobox
              store={combobox}
              onOptionSubmit={(val) => {
                onChange(val)
                if(customOnChange) customOnChange(val)
                combobox.closeDropdown();
              }}
            >
              <Combobox.Target>
                <InputBase
                  component="button"
                  type="button"
                  pointer
                  rightSection={<Combobox.Chevron />}
                  onClick={() => combobox.toggleDropdown()}
                  rightSectionPointerEvents="none"
                  multiline
                  style={styles}
                  ref={ref}
                  value={value as string}
                >
                  {value ? (
                    <SelectOption value={findMatchingLabel(options, value as string)} description='' />
                  ) : (
                    <Input.Placeholder>{placeholder}</Input.Placeholder>
                  )}
                </InputBase>
              </Combobox.Target>

              <Combobox.Dropdown style={{ zIndex: 1000 }}>
                <Combobox.Options mah={240} style={{ overflowY: 'auto' }}>{optionList}</Combobox.Options>
              </Combobox.Dropdown>
            </Combobox>
            <Text c="red" pt="3px">{error?.message}</Text>
          </>
      )} />
    </div>
  );
}
