import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const mediasImage: CSS = {
  boxSizing: 'border-box',
  display: 'block',
  width: '$sizeFull',
  height: '$sizeFull',
  objectFit: 'cover',
  verticalAlign: 'middle',
  borderStyle: 'none',
};

export const variants = css({
  $$mediaBoxShadow: 'inset 0px 0px 0px 2.5px rgba(0,0,0,0.06)',
  $$mediaBorderRadiusSquare: '4px',
  // default styles
  display: 'inline-flex',
  boxSizing: 'border-box',
  position: 'relative',
  height: '$$mediaWidth',
  width: '$$mediaHeight',
  lineHeight: '$$mediaHeight',
  margin: '$space0',
  padding: '$space0',
  overflow: 'hidden',
  color: '#fff',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  verticalAlign: 'middle',
  // Pseudo for strokes
  '&::after': {
    position: 'absolute',
    content: '',
    borderRadius: '$$mediaBorderRadiusSquare',
    boxShadow: '$$mediaBoxShadow',
    top: '$space0',
    right: '$space0',
    width: '$sizeFull',
    height: '$sizeFull',
    backgroundColor: '$color-transparent',
  },

  variants: {
    stroked: {
      true: {},
      false: {
        $$mediaBoxShadow: 'none !important',
      },
    },
    strokeSizes: {
      x2small: {
        $$mediaBoxShadow: 'inset 0px 0px 0px 0.5px rgba(0,0,0,0.06)  !important',
      },
      xsmall: {
        $$mediaBoxShadow: 'inset 0px 0px 0px 0.75px rgba(0,0,0,0.06)  !important',
      },
      small: {
        $$mediaBoxShadow: 'inset 0px 0px 0px 1px rgba(0,0,0,0.06)   !important',
      },
      default: {
        $$mediaBoxShadow: 'inset 0px 0px 0px 1.25px rgba(0,0,0,0.06)  !important',
      },
      medium: {
        $$mediaBoxShadow: 'inset 0px 0px 0px 1.25px rgba(0,0,0,0.06)  !important',
      },
      large: {
        $$mediaBoxShadow: 'inset 0px 0px 0px 1.5px rgba(0,0,0,0.06) !important ',
      },
      xlarge: {
        $$mediaBoxShadow: 'inset 0px 0px 0px 1.75px rgba(0,0,0,0.06) !important ',
      },
      x2large: {
        $$mediaBoxShadow: 'inset 0px 0px 0px 2px rgba(0,0,0,0.06) !important ',
      },
      x3large: {
        $$mediaBoxShadow: 'inset 0px 0px 0px 2.5px rgba(0,0,0,0.06) !important',
      },
    },
    shape: {
      circle: {
        borderRadius: '9999px',

        '&::after': {
          borderRadius: '9999px',
        },
      },
      square: {
        borderRadius: '$$mediaBorderRadiusSquare ',
        '&::after': {
          borderRadius: '$$mediaBorderRadiusSquare',
        },
      },
    },
    size: {
      x2small: {
        $$mediaWidth: '16px',
        $$mediaHeight: '16px',
        $$mediaBoxShadow: 'inset 0px 0px 0px 0.5px rgba(0,0,0,0.06) ',
        $$mediaBorderRadiusSquare: '4px',
      },
      xsmall: {
        $$mediaWidth: '20px',
        $$mediaHeight: '20px',
        $$mediaBoxShadow: 'inset 0px 0px 0px 0.75px rgba(0,0,0,0.06) ',
        $$mediaBorderRadiusSquare: '6px ',
      },
      small: {
        $$mediaWidth: '24px',
        $$mediaHeight: '24px',
        $$mediaBoxShadow: 'inset 0px 0px 0px 1px rgba(0,0,0,0.06)  ',
        $$mediaBorderRadiusSquare: '6px',
      },
      default: {
        $$mediaWidth: '32px',
        $$mediaHeight: '32px',
        $$mediaBoxShadow: 'inset 0px 0px 0px 1.25px rgba(0,0,0,0.06) ',
        $$mediaBorderRadiusSquare: '8px',
      },
      medium: {
        $$mediaWidth: '32px',
        $$mediaHeight: '32px',
        $$mediaBoxShadow: 'inset 0px 0px 0px 1.25px rgba(0,0,0,0.06) ',
        $$mediaBorderRadiusSquare: '8px',
      },
      large: {
        $$mediaWidth: '40px',
        $$mediaHeight: '40px',
        $$mediaBoxShadow: 'inset 0px 0px 0px 1.5px rgba(0,0,0,0.06) ',
        $$mediaBorderRadiusSquare: '12px',
      },
      xlarge: {
        $$mediaWidth: '48px',
        $$mediaHeight: '48px',
        $$mediaBoxShadow: 'inset 0px 0px 0px 1.5px rgba(0,0,0,0.06) ',
        $$mediaBorderRadiusSquare: '16px',
      },
      x2large: {
        $$mediaWidth: '64px',
        $$mediaHeight: '64px',
        $$mediaBoxShadow: 'inset 0px 0px 0px 2px rgba(0,0,0,0.06) ',
        $$mediaBorderRadiusSquare: '20px',
      },
      x3large: {
        $$mediaWidth: '96px',
        $$mediaHeight: '96px',
        $$mediaBoxShadow: 'inset 0px 0px 0px 2.5px rgba(0,0,0,0.06) ',
        $$mediaBorderRadiusSquare: '28px ',
      },
    },
  },
  compoundVariants: [
    {
      shape: 'circle',
      size: 'large',
      css: {
        $$mediaBoxShadow: 'inset 0px 0px 0px 1.75px rgba(0,0,0,0.06) ',
      },
    },
    {
      shape: 'circle',
      size: 'xlarge',
      css: {
        $$mediaBoxShadow: 'inset 0px 0px 0px 1.75px rgba(0,0,0,0.06) ',
      },
    },
  ],
});

export type MediasVariants = Stitches.VariantProps<typeof variants>;
