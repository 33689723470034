import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import WorkflowTemplateStepNameSection from 'views/workflows/workflow-templates/components/WorkflowTemplateStepNameSection';

interface ICreateWorkflowTemplateNameStepPanelProps {
  addWorkflowTemplateWizard: typeof state.workflowStore.addWorkflowTemplateWizard;
  onNextStep(): void;
  setAddWorkflowTemplateWizard: typeof dispatch.workflowStore.setAddWorkflowTemplateWizard;
}

interface ICreateWorkflowTemplateNameStepPanelState {
  isExisted: boolean;
  isChecking: boolean;
}

class CreateWorkflowTemplateNameStepPanel extends PureComponent<
  ICreateWorkflowTemplateNameStepPanelProps,
  ICreateWorkflowTemplateNameStepPanelState
> {
  state = {
    isExisted: false,
    isChecking: false,
  };

  private _onSetWorkflowName = (values) => {
    const { setAddWorkflowTemplateWizard, addWorkflowTemplateWizard, onNextStep } = this.props;

    setAddWorkflowTemplateWizard({ ...addWorkflowTemplateWizard, ...values });
    onNextStep();
  };

  componentDidMount() {
    const { setAddWorkflowTemplateWizard } = this.props;

    setAddWorkflowTemplateWizard({
      name: '',
      isAllowApproverFlagIssue: true,
      isNotifyWorkflowCompleted: true,
      isNotifyWorkflowFlagged: true,
      steps: [],
    });
  }

  render() {
    const { addWorkflowTemplateWizard } = this.props;

    return (
      <div>
        <WorkflowTemplateStepNameSection
          addWorkflowTemplateWizard={addWorkflowTemplateWizard}
          onSubmit={this._onSetWorkflowName}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  addWorkflowTemplateWizard: state.workflowStore.addWorkflowTemplateWizard,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddWorkflowTemplateWizard: dispatch.workflowStore.setAddWorkflowTemplateWizard,
});

export default connect(mapState, mapDispatch)(CreateWorkflowTemplateNameStepPanel);
