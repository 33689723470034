import { forwardRef } from 'react';
import { Illustration } from './illustration';
import type { IllustrationProps } from './illustration';
import type { Ref } from 'react';
export const Circle = forwardRef(function Circle(props: IllustrationProps, ref: Ref<SVGSVGElement>) {
  return (
    <Illustration {...props} ref={ref}>
      <path
        d="M20.48 64.882c.068-4.833.51-8.793 1.636-12.634.736-2.514 1.555-5.002 2.795-7.343a76.756 76.756 0 0 1 3.49-5.926c1.828-2.808 4.187-5.128 6.532-7.474 1.636-1.63 3.494-3.013 5.39-4.253 3.682-2.4 7.617-4.38 11.876-5.628 2.556-.748 5.145-1.345 7.794-1.593a48.183 48.183 0 0 1 6.188-.156c3.607.13 7.138.643 10.598 1.598 2.278.63 4.447 1.576 6.558 2.694a115.02 115.02 0 0 1 5.292 3.01c.892.533 1.72 1.18 2.518 1.849 1.426 1.19 2.86 2.379 4.192 3.665.908.874 1.673 1.895 2.468 2.879.664.824 1.311 1.66 1.92 2.53 1.14 1.635 2.296 3.25 3.074 5.107.323.773.874 1.454 1.194 2.232.958 2.353 1.963 4.699 2.505 7.196.374 1.719.622 3.463.954 5.195.261 1.353.425 2.715.379 4.098-.042 1.298-.093 2.597-.139 3.9-.025.69-.025 1.383-.076 2.068-.117 1.538-.311 3.06-.71 4.556-.286 1.076-.471 2.177-.748 3.258-.597 2.294-1.375 4.526-2.46 6.645-.613 1.193-1.131 2.442-1.799 3.602-.702 1.223-1.526 2.375-2.308 3.547a38.52 38.52 0 0 1-1.4 1.997c-.651.857-1.282 1.723-2.19 2.362-.357.248-.534.74-.85 1.067a84.433 84.433 0 0 1-2.215 2.211c-.277.27-.571.53-.882.761-1.186.874-2.418 1.694-3.57 2.614-1.47 1.177-3.123 2.043-4.788 2.892-.496.252-1.021.441-1.526.677-.815.382-1.601.832-2.442 1.152-.92.348-1.921.512-2.834.882-2.215.9-4.59 1.072-6.873 1.648-.69.172-1.35.113-2.014.181-1.463.151-2.942.138-4.414.172-1.303.029-2.606.046-3.91.038-.344 0-.689-.126-1.038-.185-1.135-.189-2.278-.324-3.396-.58-1.66-.383-3.334-.765-4.948-1.303a40.22 40.22 0 0 1-4.839-1.963c-1.736-.849-3.422-1.816-5.066-2.833-2.955-1.82-5.545-4.12-7.928-6.616-1.333-1.39-2.476-2.971-3.615-4.53a44.983 44.983 0 0 1-2.69-4.12 29.692 29.692 0 0 1-1.842-3.808c-.736-1.862-1.362-3.77-2.005-5.67a34.382 34.382 0 0 1-.917-3.076 19.02 19.02 0 0 1-.412-2.623c-.21-2.261-.374-4.527-.492-5.964l-.017-.005Zm62.995 13.164c-.067-.016-.134-.037-.201-.054-.089.24-.173.48-.261.714.046.017.093.038.139.055.11-.24.214-.475.323-.715Zm-35.4.64.13.05c.055-.147.11-.29.164-.437-.042-.017-.084-.03-.126-.047-.055.143-.114.29-.172.433h.004Z"
        fill="currentColor"
      />
    </Illustration>
  );
});
