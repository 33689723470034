import React from 'react';
import { Input, InputWrapperProps } from '@good/ui/core';

import { useInlineForm } from './inline-form-context';

const getInputWrapperClassName = ({
  label,
  description,
  error,
}: Pick<InputWrapperProps, 'label' | 'error' | 'description'>) => {
  const className = [];

  if (label || description) {
    className.push('mt-2');
  }

  if (error) {
    className.push('mb-2');
  }

  return className.join(' ');
};

type InlineFieldInputWrapperProps = {
  name: string;
  render: (labelledBy: string | undefined) => React.ReactNode;
  maxInputWidth?: React.CSSProperties['maxWidth'];
  useFormMaxWidth?: boolean;
} & InputWrapperProps;

export const InlineFieldInputWrapper = (props: InlineFieldInputWrapperProps) => {
  const { description, error, label, maxInputWidth, name, render, useFormMaxWidth = true, ...wrapperProps } = props;
  const { maxWidth, title } = useInlineForm();
  const fieldId = `${title.replace(/\s+/g, '-').toLowerCase()}-${name.replace(/\s+/g, '-').toLowerCase()}`;

  if (!label && !description && !error) {
    return (
      <div style={{ maxWidth: useFormMaxWidth ? maxWidth : undefined }}>
        <div style={{ maxWidth: maxInputWidth }}>{render(undefined)}</div>
      </div>
    );
  }

  return (
    <Input.Wrapper
      label={label}
      description={description}
      style={{ maxWidth: useFormMaxWidth ? maxWidth : undefined }}
      id={fieldId}
      error={error}
      {...wrapperProps}
    >
      <div
        className={getInputWrapperClassName({
          label,
          description,
          error,
        })}
        style={{ maxWidth: maxInputWidth }}
      >
        {render(`${fieldId}-label`)}
      </div>
    </Input.Wrapper>
  );
};
