import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { reactTrpc } from 'utilities/react-trpc';
import { IRootState } from 'stores/rematch/root-store';
import { useSilServiceContext } from 'views/sil-service/components/sil-service-provider/sil-service-provider';

import { SideNav, TabContent } from 'views/sil-service/components/side-nav/side-nav';
import { ActiveCustomersTab } from './tabs/active-customers';
import { ArchivedCustomerTab } from './tabs/archived-customer';
import { Loader2Adjustable } from '@good/icons';
import { Text } from '@good/ui/core';

export const CustomersTab = () => {
  const { service } = useSilServiceContext();
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);

  const { t } = useTranslation('', { keyPrefix: 'silService.serviceCustomersTabs' });

  const {
    data: customers,
    isLoading,
    error,
    isFetching,
  } = reactTrpc.sil.customers.fetch.useQuery({
    serviceId: service.serviceId,
    serviceProviderId: portalUser?.serviceProviderId ?? '',
  });

  if (error) throw new Error(`failed to fetch sil customers: ${error.message}`);
  if (isLoading && isFetching)
    return (
      <div className='flex flex-col content-center align-center'>
        <Loader2Adjustable className='animate-spin' />
        <Text size='md'>Loading customers</Text>
      </div>
    );

  if (!customers) return <div></div>;

  const activeItems = customers.filter((item) => item.status === 'ACTIVE' || item.status === 'EXPIRING_SOON');
  const pendingItems = customers.filter((item) => item.status === 'UPCOMING');
  const archivedItems = customers.filter((item) => item.status === 'EXPIRED');

  const activeCustomerCount = activeItems.length + pendingItems.length;
  const archivedCustomerCount = archivedItems.length;

  const tabs: TabContent[] = [
    {
      id: 1,
      title: `${t('Customers')}`,
      content: (
        <ActiveCustomersTab
          activeCustomers={activeItems}
          upcomingCustomers={pendingItems}
          customerCount={activeCustomerCount}
        />
      ),
      count: activeCustomerCount,
    },
    {
      id: 2,
      title: `${t('Archived')}`,
      content: <ArchivedCustomerTab archivedCustomers={archivedItems} customerCount={archivedCustomerCount} />,
      count: archivedCustomerCount,
    },
  ];

  return (
    <>
      <SideNav tabs={tabs} />
    </>
  );
};
