import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';
import { EndSpacer } from 'common-components/roster-control/roster-daily/timeline-pane/common/EndSpacer';
import React from 'react';

interface WorkersTimelineHeaderProps {
  rowWidth: number;
  markers?: any;
}

export function WorkersTimelineHeader({ rowWidth, markers }: WorkersTimelineHeaderProps) {
  const { CONTENT_GROUP_HEIGHT } = ROSTER_DAILY_CONFIG;

  return (
    <div className="flex-row">
      <div
        className="bg-tertiary width-full bordered-bottom bordered-top position-relative"
        style={{ height: CONTENT_GROUP_HEIGHT, minWidth: rowWidth, maxWidth: rowWidth }}
      >
        {markers}
      </div>

      {/* Spacer*/}
      <EndSpacer height={56} />
    </div>
  );
}
