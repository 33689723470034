import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import type { NavLinkItem, NavSection } from '../types';

/**
 * Returns the matched navigation item based on the current location pathname.
 * Exact matches are prioritized over partial matches.
 *
 * @param navSections - An array of navigation sections.
 * @param callback - An optional callback function to be called with the matched navigation item.
 * @returns The matched navigation item or `undefined` if no match is found.
 */
export function useMatchedNavItem(navSections: NavSection[], callback?: (navItem: NavLinkItem | undefined) => void) {
  const location = useLocation();
  return useMemo(() => {
    const navLinksItems = navSections.flatMap((section) => section.items);
    const pathname = location.pathname;

    const matchNavLinkItem = (navLinkItem: NavLinkItem, exact: boolean) =>
      [navLinkItem.link, ...(navLinkItem.matchPath ?? [])].some((path) => {
        return matchPath(pathname, { path, exact });
      });

    const exactMatchedLink = navLinksItems.find((navLinkItem) => {
      return matchNavLinkItem(navLinkItem, true);
    });

    if (exactMatchedLink) {
      // eslint-disable-next-line callback-return -- callback is optional
      callback?.(exactMatchedLink);
      return exactMatchedLink;
    }

    const matchedLink = navLinksItems.find((navLinkItem) => {
      return matchNavLinkItem(navLinkItem, false);
    });

    // eslint-disable-next-line callback-return -- callback is optional
    callback?.(matchedLink);
    return matchedLink;
  }, [callback, location.pathname, navSections]);
}
