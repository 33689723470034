import { state } from 'stores/rematch/root-store';

export const formatFilterQuery = (appliedFilters: typeof state.billingsStore.paymentsFilter) => {
  const requestFilter: object = {};

  appliedFilters.forEach((filter) => {
    if (filter.values.length > 0) {
      switch (filter.filter) {
        case 'startDate':
          requestFilter.startDate = filter.values[0].toDate();
          requestFilter.endDate = filter.values[1].toDate();
          break;
        case 'paymentMethods':
          requestFilter.paymentMethods = filter.values;
          break;
        case 'customerUserIds':
          requestFilter.customerUserIds = filter.values.map((customer) => {
            return customer.value;
          });
          break;
        case 'search':
          requestFilter.search = filter.values;
          break;
        case 'serviceIds':
          requestFilter.serviceIds = filter.values;
          break;
        case 'serviceType':
          requestFilter.serviceTypes = filter.values;
          break;
        case 'ndisCategories':
          requestFilter.supportCategoryNumbers = filter.values;
          break;
        case 'userLocationByState':
          requestFilter.bookingLocationStates = filter.values;
          break;
        case 'paymentWarnings':
          requestFilter.errorTypes = filter.values;
          break;
        case 'debtorIds':
          requestFilter.debtorIds = filter.values.map((debtor) => debtor.value);
          break;
      }
    }
  });
  return requestFilter;
};
