import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { IGroupServiceTimesheetShiftSlot } from 'interfaces/service-interfaces';
import _ from 'lodash';
import moment from 'moment';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { FieldLabel, Text } from 'common-components/typography';
import FilterSection from 'common-components/filter/FilterSection';
import { FilterType, ShiftSlotStatus } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import { Col, Row } from 'antd/es';
import { GridHeader } from 'common-components/grids';
import { Checkbox, Empty, Skeleton } from 'antd';
import TimesheetSessionRow from './TimesheetSessionRow';
import TimesheetShiftSlotRow from './TimesheetShiftSlotRow';
import GridRow from 'common-components/grids/GridRow';
import RevokeTimesheetModal from './RevokeTimesheetModal';
import RevokeIndividualTimesheetModal from './RevokeIndividualTimesheetModal';

interface IServiceTimesheetApprovedPanelProps {
  timesheetSessionList: typeof state.groupServiceStore.timesheetSessionList;
  doFetchGroupServiceTimesheetSessions: typeof dispatch.groupServiceStore.doFetchGroupServiceTimesheetSessions;
  timesheetShiftSlotList: typeof state.groupServiceStore.timesheetShiftSlotList;
  doFetchGroupServiceTimesheetShiftSlots: typeof dispatch.groupServiceStore.doFetchGroupServiceTimesheetShiftSlots;
  setTimesheetShiftSlotList: typeof dispatch.groupServiceStore.setTimesheetShiftSlotList;
  serviceId: string;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  portalUser: typeof state.authStore.portalUser;
}

interface IServiceTimesheetApprovedPanelState {
  selectedSessionId: string;
  isLoadingWorker: boolean;
  selectedShiftSlots: IGroupServiceTimesheetShiftSlot[];
  schedule: number;
  actual: number;
  diff: number;
  isSelectAll: boolean;
  shiftSlotFilter: any[];
  isLoadingSession: boolean;
  isOpenBulkRevoke: boolean;
  selectedShiftSlot: IGroupServiceTimesheetShiftSlot;
  isOpenRevoke: boolean;
}

const shiftSlotStatus = ShiftSlotStatus.SENT_TO_FINANCE;
class ServiceTimesheetApprovedPanel extends Component<
  IServiceTimesheetApprovedPanelProps,
  IServiceTimesheetApprovedPanelState
> {
  state = {
    selectedSessionId: '',
    isLoadingWorker: false,
    selectedShiftSlots: [],
    schedule: 0,
    actual: 0,
    diff: 0,
    isSelectAll: false,
    shiftSlotFilter: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName,
      },
    ],
    isLoadingSession: false,
    isOpenBulkRevoke: false,
    selectedShiftSlot: null,
    isOpenRevoke: false,
  };

  private _doRefresh = async () => {
    const { doFetchGroupServiceTimesheetSessions } = this.props;
    this.setState({ isLoadingSession: true });

    await doFetchGroupServiceTimesheetSessions({
      serviceId: this.props.serviceId,
      filters: this.state.shiftSlotFilter,
      shiftSlotStatus,
    });

    if (!_.isEmpty(this.props.timesheetSessionList)) {
      await this._onSelectSession(this.props.timesheetSessionList[0].serviceDateTimeId);
    }

    this.setState({ isLoadingSession: false });
  };

  async componentDidMount() {
    await this._doRefresh();
  }

  private _onSelectSession = async (sessionId) => {
    const { doFetchGroupServiceTimesheetShiftSlots } = this.props;
    this.setState({
      selectedSessionId: sessionId,
      isLoadingWorker: true,
      selectedShiftSlots: [],
      schedule: 0,
      actual: 0,
      diff: 0,
    });

    await doFetchGroupServiceTimesheetShiftSlots({ serviceId: this.props.serviceId, sessionId, shiftSlotStatus });

    const { schedule, actual, diff } = _.reduce(
      this.props.timesheetShiftSlotList,
      (acc, shiftSlot) => {
        const checkInMoment = moment(shiftSlot.workerCheckedInDateTime);
        const checkOutMoment = moment(shiftSlot.workerCheckedOutDateTime);
        const shiftStartMoment = moment(shiftSlot.shiftStartDateTime);
        const shiftEndMoment = moment(shiftSlot.shiftEndDateTime);

        const scheduleDiff = shiftEndMoment.diff(shiftStartMoment);
        const actualDiff = checkOutMoment.diff(checkInMoment);

        const different = Math.abs(scheduleDiff - actualDiff);

        return {
          schedule: acc.schedule + scheduleDiff,
          actual: acc.actual + actualDiff,
          diff: acc.diff + different,
        };
      },
      { schedule: 0, actual: 0, diff: 0 },
    );

    this.setState({ isLoadingWorker: false, schedule, actual, diff, isSelectAll: false, selectedShiftSlots: [] });
  };

  private _getHoverContent = (shiftSlot: IGroupServiceTimesheetShiftSlot) => {
    return (
      <ActionMenu>
        <ActionMenuItem text={'Revoke Approval'} onClick={() => this._onOpenRevokeShiftSlot(shiftSlot)} />
      </ActionMenu>
    );
  };

  private _onOpenRevokeShiftSlot = (shiftSlot: IGroupServiceTimesheetShiftSlot) => {
    this.setState({ selectedShiftSlot: shiftSlot, isOpenRevoke: true });
  };

  private _onCloseRevokeShiftSlot = async (isRefresh: boolean) => {
    this.setState({ isOpenRevoke: false, selectedShiftSlots: null });
    if (isRefresh) {
      await this._onSelectSession(this.state.selectedSessionId);
    }
  };

  private _onCloseBulkRevokeShiftSlot = async (isRefresh: boolean) => {
    this.setState({ isOpenBulkRevoke: false });
    if (isRefresh) {
      await this._onSelectSession(this.state.selectedSessionId);
    }
  };

  private _onOpenBulkRevokeShiftSlotModal = () => {
    this.setState({ isOpenBulkRevoke: true });
  };

  private _onCheckShiftSlot = (shiftSlot: IGroupServiceTimesheetShiftSlot) => {
    const { timesheetShiftSlotList, setTimesheetShiftSlotList } = this.props;

    const newList = _.map(timesheetShiftSlotList, (timesheetShift) => {
      if (timesheetShift.supportWorkerAttendanceId === shiftSlot.supportWorkerAttendanceId) {
        timesheetShift.isChecked = !timesheetShift.isChecked;
      }
      return timesheetShift;
    });

    const selectedShiftSlots = _.filter(newList, (item) => item.isChecked);

    setTimesheetShiftSlotList(newList);

    if (!_.isEmpty(selectedShiftSlots) && selectedShiftSlots.length === timesheetShiftSlotList.length) {
      this.setState({ selectedShiftSlots, isSelectAll: true });
    } else {
      this.setState({ selectedShiftSlots });
    }
  };

  private _onCheckAllShift = () => {
    const { timesheetShiftSlotList, setTimesheetShiftSlotList } = this.props;
    if (this.state.isSelectAll === true) {
      const newList = _.map(timesheetShiftSlotList, (timesheetShift) => {
        timesheetShift.isChecked = false;
        return timesheetShift;
      });

      setTimesheetShiftSlotList(newList);
      this.setState({ selectedShiftSlots: [], isSelectAll: false });
    } else {
      const newList = _.map(timesheetShiftSlotList, (timesheetShift) => {
        timesheetShift.isChecked = true;
        return timesheetShift;
      });

      setTimesheetShiftSlotList(newList);
      this.setState({ selectedShiftSlots: timesheetShiftSlotList, isSelectAll: true });
    }
  };

  private _onDeselectAllShift = () => {
    const { timesheetShiftSlotList, setTimesheetShiftSlotList } = this.props;

    const newList = _.map(timesheetShiftSlotList, (timesheetShift) => {
      timesheetShift.isChecked = false;
      return timesheetShift;
    });

    setTimesheetShiftSlotList(newList);
    this.setState({ selectedShiftSlots: [], isSelectAll: false });
  };

  private _onChangeFilter = async (filter) => {
    const { doFetchGroupServiceTimesheetSessions } = this.props;
    this.setState({ shiftSlotFilter: filter, isLoadingSession: true }, async () => {
      await doFetchGroupServiceTimesheetSessions({
        serviceId: this.props.serviceId,
        filters: filter,
        shiftSlotStatus,
      });

      this.setState({ isLoadingSession: false });
    });
  };

  render() {
    const { timesheetSessionList, timesheetShiftSlotList, selectedGroupService, portalUser } = this.props;
    const { selectedSessionId, selectedShiftSlots } = this.state;

    return (
      <div className='mt-medium'>
        {this.state.isOpenBulkRevoke && (
          <RevokeTimesheetModal
            isOpen={this.state.isOpenBulkRevoke}
            onClose={this._onCloseBulkRevokeShiftSlot}
            selectedShiftSlots={selectedShiftSlots}
            selectedSessionId={this.state.selectedSessionId}
          />
        )}
        {this.state.isOpenRevoke && (
          <RevokeIndividualTimesheetModal
            isOpen={this.state.isOpenRevoke}
            onClose={this._onCloseRevokeShiftSlot}
            selectedShiftSlot={this.state.selectedShiftSlot}
            selectedSessionId={this.state.selectedSessionId}
          />
        )}
        <div className=' mt-large'>
          <div className='flex-row'>
            <FilterSection
              availableFilters={[FilterType.DATE_RANGE]}
              filters={this.state.shiftSlotFilter}
              onChangeFilter={this._onChangeFilter}
              displayTimezone={selectedGroupService.timezone}
              containerClassName='flex-row justify-between'
              displayMoreFilter={false}
            />
          </div>
          <div className='mt-large'>
            <div className=' rounded-big bordered flex-column'>
              <Row className='bordered-bottom'>
                <Col span={3} className='bordered-right'>
                  <GridHeader bordered={false} gutter={0}>
                    <Col span={24} style={{ padding: 0, marginLeft: 8 }}>
                      <FieldLabel text='session date' />
                    </Col>
                  </GridHeader>
                </Col>
                <Col span={21}>
                  <GridHeader bordered={false} gutter={16}>
                    <Col span={7}>
                      <div className='pl-medium'>
                        <FieldLabel text='team member' />
                      </div>
                    </Col>
                    <Col span={3}>
                      <FieldLabel text='in' />
                    </Col>
                    <Col span={3}>
                      <FieldLabel text='out' />
                    </Col>
                    <Col span={3} className='text-align-right'>
                      <FieldLabel text='scheduled' />
                    </Col>
                    <Col span={4} className='text-align-right'>
                      <FieldLabel text='actual' />
                    </Col>
                    <Col span={4} className='text-align-right'>
                      <FieldLabel text='diff.' />
                    </Col>
                  </GridHeader>
                </Col>
              </Row>
              <Row>
                <Col span={3} className='bordered-right' style={{ minHeight: '30vh' }}>
                  {this.state.isLoadingSession ? (
                    <>
                      <Skeleton paragraph={{ rows: 1, width: '100%' }} active={true} className='anim-slide-left' />
                      <Skeleton paragraph={{ rows: 1, width: '100%' }} active={true} className='anim-slide-left' />
                      <Skeleton paragraph={{ rows: 1, width: '100%' }} active={true} className='anim-slide-left' />
                    </>
                  ) : _.isEmpty(timesheetSessionList) ? (
                    <></>
                  ) : (
                    _.map(timesheetSessionList, (session) => {
                      return (
                        <TimesheetSessionRow
                          session={session}
                          isSelected={session.serviceDateTimeId === selectedSessionId}
                          onSelectSession={this._onSelectSession}
                          timezone={selectedGroupService.timezone}
                        />
                      );
                    })
                  )}
                </Col>
                <Col span={21} style={{ minHeight: '30vh' }}>
                  {this.state.selectedSessionId === '' ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  ) : this.state.isLoadingWorker ? (
                    <div className='p-large'>
                      <Skeleton
                        paragraph={{ rows: 1, width: '100%' }}
                        active={true}
                        className='anim-slide-left'
                        avatar={true}
                      />
                      <Skeleton
                        paragraph={{ rows: 1, width: '100%' }}
                        active={true}
                        className='anim-slide-left'
                        avatar={true}
                      />
                      <Skeleton
                        paragraph={{ rows: 1, width: '100%' }}
                        active={true}
                        className='anim-slide-left'
                        avatar={true}
                      />
                    </div>
                  ) : _.isEmpty(timesheetSessionList) ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  ) : (
                    <>
                      {_.map(timesheetShiftSlotList, (shiftSlot, index) => {
                        return (
                          <TimesheetShiftSlotRow
                            key={shiftSlot.supportWorkerAttendanceId}
                            shiftSlot={shiftSlot}
                            onCheckboxCheck={this._onCheckShiftSlot}
                            odd={index % 2 === 1}
                            hoverContent={this._getHoverContent(shiftSlot)}
                            timezone={selectedGroupService.timezone}
                          />
                        );
                      })}
                      <GridRow gutter={16} containerClassName='bg-tertiary' bordered={false}>
                        <Col span={13}>
                          <FieldLabel text={'total'} />
                        </Col>
                        <Col span={3} className='text-align-right'>
                          {TimesheetShiftSlotRow.formatDuration(moment.duration(this.state.schedule, 'ms'))}
                        </Col>
                        <Col span={3} className='text-align-right'>
                          {TimesheetShiftSlotRow.formatDuration(moment.duration(this.state.actual, 'ms'))}
                        </Col>
                        <Col span={3} className='text-align-right'>
                          {this.state.diff !== 0 ? (
                            TimesheetShiftSlotRow.formatDuration(moment.duration(this.state.diff, 'ms'))
                          ) : (
                            <>
                              <Text>
                                <b>0</b>
                              </Text>
                              <Text color='secondary'> hrs</Text>
                            </>
                          )}
                        </Col>
                        <Col span={2} className='text-align-right' />
                      </GridRow>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  timesheetSessionList: state.groupServiceStore.timesheetSessionList,
  timesheetShiftSlotList: state.groupServiceStore.timesheetShiftSlotList,
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceTimesheetSessions: dispatch.groupServiceStore.doFetchGroupServiceTimesheetSessions,
  doFetchGroupServiceTimesheetShiftSlots: dispatch.groupServiceStore.doFetchGroupServiceTimesheetShiftSlots,
  setTimesheetShiftSlotList: dispatch.groupServiceStore.setTimesheetShiftSlotList,
});

export default connect(mapState, mapDispatch)(ServiceTimesheetApprovedPanel);
