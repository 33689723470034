import React from 'react';

import { Table, TableColumn } from 'design-components';
import { useFetchTimesheets } from 'stores/hooks/query-hooks/use-query-fetch-timesheets';
import { ITimesheetsRequest } from 'stores/queries/payments/payment-queries';
import { TableEmpty } from './TableEmpty';
import { useTableColumns } from './use-table-columns';

type ITimesheetsProps = {
  filters: ITimesheetsRequest;
};

export const TimesheetsTable = ({ filters }: ITimesheetsProps): JSX.Element => {
  const timesheets = useFetchTimesheets({
    ...filters,
  });
  const { data, isLoading } = timesheets;
  const dataSources = data?.map((shift, index) => ({
    ...shift,
    key: shift.supportWorkerAttendanceId,
    index,
  }));
  const columns: TableColumn<unknown>[] = useTableColumns();
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);

  return (
    <div id="timesheets-table" className="mt-8 pr-8">
      <Table
        columns={columns}
        dataSources={dataSources ?? []}
        emptyState={<TableEmpty />}
        isLoading={isLoading}
        hasHorizontalBorder={false}
        selectStyles={{ width: 52, padding: '0 15px' }}
        enableSelect
        selectedKeys={selectedRows}
        onSelectionChange={(keys) => setSelectedRows(keys)}
      />
    </div>
  );
};
