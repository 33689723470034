import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Avatar, notification } from 'antd';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IShiftSlot } from 'interfaces/shift-interfaces';

interface IBulkRemoveTeamMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTaskSuccess: () => void;
  toBeRemovedShiftSlots: IShiftSlot[];
  serviceId: string;
  serviceDateTimeId: string;
  doRemoveShiftSlot: typeof dispatch.groupServiceStore.doRemoveShiftSlot;
}

interface IBulkRemoveTeamMemberModalState {
  isLoading: boolean;
  step: number;
}

class BulkRemoveTeamMemberModal extends Component<IBulkRemoveTeamMemberModalProps, IBulkRemoveTeamMemberModalState> {
  state = {
    isLoading: false,
    step: 1,
  };

  private _reset = () => {
    this.setState({
      isLoading: false,
      step: 1,
    });
  };

  private _onRemoveTeamMember = async () => {
    const { toBeRemovedShiftSlots, serviceId, serviceDateTimeId, doRemoveShiftSlot, onTaskSuccess } = this.props;
    const attendenceIds = _.map(toBeRemovedShiftSlots, (shiftSlot) => shiftSlot.supportWorkerAttendanceId);
    this.setState({ isLoading: true });
    try {
      await doRemoveShiftSlot({
        serviceId,
        serviceDateTimeId,
        supportWorkerAttendanceIds: attendenceIds,
        isRemoveTeamMemberOnly: true,
      });
      this.setState({ step: 2 });
      onTaskSuccess();
    } catch (e) {
      console.error(e);
      notification.error({
        message: <Text weight="bold">Bulk actions failed.</Text>,
        description: (
          <Text className="mt-medium">
            Bulk actions for <b>Removing team members</b> complete has encounter an error. Please try again.
          </Text>
        ),
      });
    }
    this.setState({ isLoading: false });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen && !prevProps.isOpen) {
      this._reset();
    }
  };

  render() {
    const { step } = this.state;
    const modalTitle = step === 1 ? 'Remove team members' : 'Team members removed';
    const itemCount = this.props.toBeRemovedShiftSlots.length;

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={modalTitle}
        onClose={this.props.onClose}
        canCloseOutside={false}
        width={'medium'}
      >
        {step === 1 && (
          <div>
            <Text>
              You are removing{' '}
              <b>
                {itemCount} team member{itemCount === 1 ? '' : 's'}
              </b>{' '}
              from shift(s). Are you sure you want to continue?
            </Text>
            <SubTitle containerClassName="mt-medium mb-small">Team members removed</SubTitle>
            {_.map(this.props.toBeRemovedShiftSlots, (shiftSlot) => (
              <div className="flex-row align-center mb-medium">
                <Avatar icon={'user'} shape={'circle'} src={shiftSlot.attachmentUrl} />
                <Text className="ml-small">{shiftSlot.firstName || '' + ' ' + shiftSlot.lastName || ''}</Text>
              </div>
            ))}
            <Text>Are you sure you want to continue?</Text>
            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this.props.onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onRemoveTeamMember} loading={this.state.isLoading}>
                Remove team members
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {step === 2 && (
          <div>
            <Text>
              You have removed{' '}
              <b>
                {itemCount} team member{itemCount === 1 ? '' : 's'}
              </b>{' '}
              from shift(s).
            </Text>
            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.props.onClose} className="rounded-left">
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveShiftSlot: dispatch.groupServiceStore.doRemoveShiftSlot,
});

export default connect(null, mapDispatch)(BulkRemoveTeamMemberModal);
