import React, { Component } from 'react';
import { SubTitle, Text, Title } from 'common-components/typography';
import { Avatar, Checkbox, Col, Form, Icon, Input, Row } from 'antd';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import BookingAssignWorkerModal from 'views/bookings/components/BookingAssignWorkerModal';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { INewBookingData } from 'interfaces/booking-interfaces';
import _ from 'lodash';
import PublishBookingSettingsModal from 'views/bookings/components/PublishBookingSettingsModal';
import { Information, Warning } from 'common-components/alerts';
import NumberInput from 'common-components/inputs/NumberInput';
import { FormComponentProps } from 'antd/es/form';
import { BookingType, ShiftSlotStatus } from 'utilities/enum-utils';
import * as H from 'history';
interface Step4BookingInstructionsPanelProps extends FormComponentProps {
  onNextStep: any;
  onPreviousStep: any;
  newBookingData: INewBookingData | any;
  doSetNewBookingData: any;
  history: H.History;
}

interface Step4BookingInstructionsPanelState {
  openSelectWorker: boolean;
  openPublishSettings: boolean;
  isTravelBeforeBooking: boolean;
  isTravelDuringBooking: boolean;
  bookingCustomNote: string;
  selectedWorker: any;
}

class Step4BookingInstructionsPanel extends Component<
  Step4BookingInstructionsPanelProps,
  Step4BookingInstructionsPanelState
> {
  state = {
    openSelectWorker: false,
    openPublishSettings: false,
    isTravelBeforeBooking: false,
    isTravelDuringBooking: false,
    bookingCustomNote: this.props.newBookingData.bookCustomNote,
    selectedWorker: null,
  };

  private _onEnterInstructions = async (e) => {
    const { newBookingData, doSetNewBookingData } = this.props;
    this.setState({ bookingCustomNote: e.target.value });
    doSetNewBookingData({ ...newBookingData, bookCustomNote: e.target.value });
  };

  private _renderDescription = (isFutureBooking) =>
    isFutureBooking ? (
      <>
        Select a <b>team member</b> or publish shift, and leave <b>instructions</b> for the worker.
      </>
    ) : (
      <>
        Select a <b>team member</b> and leave a <b>booking note</b>.
      </>
    );

  private _renderTitle = (isFutureBooking) =>
    isFutureBooking ? (
      <>
        <span className="text-weight-regular">Assign a </span>team member
        <span className="text-weight-regular"> and leave </span> instructions
      </>
    ) : (
      <>
        <span className="text-weight-regular">Assign a </span>team member
        <span className="text-weight-regular"> and leave a </span> booking note
      </>
    );

  private _openSelectWorkerModal = () => {
    this.setState({ openSelectWorker: true });
  };

  private _onSelectSupportWorker = async ({
    selectedWorker,
    shiftSlotStatus,
    isRemovePendingShiftSlots,
    conflicts = null,
    bookingOutSideCanBeAssign = [],
    ignoreShiftClashShiftIds = [],
    ignoreShiftClashServiceDateTimeIds = [],
  }) => {
    const { newBookingData, doSetNewBookingData } = this.props;
    const timeSlotsOutSideCanBeAssign = _.map(bookingOutSideCanBeAssign, (booking) => booking.serviceDateTimeId);
    await doSetNewBookingData({
      ...newBookingData,
      selectedWorkerId: selectedWorker ? selectedWorker.supportWorkerId : null,
      selectedWorker,
      shiftSlotStatus,
      isRemovePendingShiftSlots,
      conflictWorkerBookings: conflicts,
      timeSlotsOutSideCanBeAssign,
      ignoreShiftClashShiftIds,
      ignoreShiftClashServiceDateTimeIds,
    });
    return 4;
  };

  private _removeSelectedWorker = async () => {
    await this.props.doSetNewBookingData({
      ...this.props.newBookingData,
      selectedWorkerId: null,
      selectedWorker: null,
      conflictWorkerBookings: null,
      timeSlotsOutSideCanBeAssign: [],
    });
  };
  private _savePublishList = async (publishList) => {
    const { newBookingData, doSetNewBookingData } = this.props;
    await doSetNewBookingData({
      ...newBookingData,
      supportWorkers: publishList.supportWorkers ? publishList.supportWorkers : null,
      workerGroups: publishList.workerGroups ? publishList.workerGroups : null,
      isShiftPublished: true,
    });
  };

  private _removePublish = async () => {
    const { newBookingData, doSetNewBookingData } = this.props;
    await doSetNewBookingData({
      ...newBookingData,
      supportWorkers: null,
      workerGroups: null,
      isShiftPublished: false,
    });
  };

  private _openPublishSettingsModal = () => {
    this.setState({ openPublishSettings: true });
  };

  private _closePublishSettingsModal = () => this.setState({ openPublishSettings: false });

  private _closeSelectWorkerModal = () => this.setState({ openSelectWorker: false });

  private _isValidStep = () => {
    const { newBookingData } = this.props;

    const { isFutureBooking, bookCustomNote } = newBookingData;

    // If it's a future booking, checkout note is not mandatory.
    if (isFutureBooking) {
      return true;
    } else {
      // Selected Worker must not be empty
      if (_.isEmpty(newBookingData.selectedWorkerId)) {
        return false;
      }

      return _.isEmpty(bookCustomNote) ? 0 : bookCustomNote.length >= 5;
    }
  };

  private _handleTravelBeforeBooking = (event) => {
    this.props.doSetNewBookingData({
      ...this.props.newBookingData,
      isTravelBeforeBooking: event.target.checked,
    });
  };

  private _handleTravelDuringBooking = (event) => {
    this.props.doSetNewBookingData({
      ...this.props.newBookingData,
      isTravelDuringBooking: event.target.checked,
    });
  };

  private _onNextStep = () => {
    const { doSetNewBookingData, newBookingData, form } = this.props;
    let isFormValid = true;
    if (!newBookingData.isFutureBooking) {
      form.validateFields((err) => {
        if (err) {
          isFormValid = false;
        }
      });
    }
    if (isFormValid) {
      doSetNewBookingData({
        ...newBookingData,
        travelDistanceBeforeBooking: form.getFieldValue('travelDistanceBeforeBooking'),
        travelTimeBeforeBooking: form.getFieldValue('travelTimeBeforeBooking'),
        additionalCostBeforeBooking: form.getFieldValue('additionalCostBeforeBooking'),
        travelDistanceDuringBooking: form.getFieldValue('travelDistanceDuringBooking'),
        travelTimeDuringBooking: form.getFieldValue('travelTimeDuringBooking'),
        additionalCostDuringBooking: form.getFieldValue('additionalCostDuringBooking'),
      });
      this.props.onNextStep();
    }
  };

  render() {
    const { onPreviousStep, newBookingData, form } = this.props;
    const { bookCustomNote = '', isFutureBooking } = newBookingData;
    const { getFieldDecorator } = form;

    const isValidStep = this._isValidStep();

    return (
      <div className="anim-slide-left">
        {this.state.openPublishSettings && (
          <PublishBookingSettingsModal
            isOpen={this.state.openPublishSettings}
            onClose={this._closePublishSettingsModal}
            selectedBookingItem={newBookingData}
            isCreateNewBooking={true}
            savePublishList={this._savePublishList}
            removePublish={this._removePublish}
          />
        )}
        {this.state.openSelectWorker && (
          <BookingAssignWorkerModal
            isOpen={this.state.openSelectWorker}
            onClose={this._closeSelectWorkerModal}
            onSubmitAssign={this._onSelectSupportWorker}
            serviceId={newBookingData.selectedServiceId}
            isRecurring={newBookingData.isRecurring}
            address={
              newBookingData.bookLocation
                ? {
                    ...newBookingData.bookLocation,
                    geoLat: Number(newBookingData.bookLocation.geoLat),
                    geoLng: Number(newBookingData.bookLocation.geoLng),
                  }
                : null
            }
            sessions={newBookingData.timeSlots}
            startDateTime={newBookingData.bookStartDate}
            endDateTime={newBookingData.bookEndDate}
            customerUserIds={[newBookingData.selectedCustomerId]}
            displayTimezone={newBookingData.selectedService.timezone}
            history={this.props.history}
            bookingType={BookingType.BOOKING}
          />
        )}
        <div className="mb-x-large">
          <Title level={2}>{this._renderTitle(isFutureBooking)}</Title>
          <Text>{this._renderDescription(isFutureBooking)}</Text>
        </div>

        <div>
          <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 'calc(100vh - 300px)' }}>
            <div className="mb-x-large">
              <Row>
                <Col span={4}>
                  <SubTitle>Team member</SubTitle>
                </Col>
                <Col span={20}>
                  {newBookingData.selectedWorkerId && (
                    <>
                      <Row type="flex" align="middle">
                        <Col span={2} style={{ minWidth: '25px' }}>
                          <Avatar
                            icon="user"
                            className="avatar-medium mr-small"
                            shape="square"
                            src={newBookingData.selectedWorker.attachmentUrl}
                          />
                        </Col>
                        <Col span={6}>
                          {newBookingData.selectedWorker.firstName + ' ' + newBookingData.selectedWorker.lastName}
                          <br />
                          {newBookingData.shiftSlotStatus &&
                          newBookingData.shiftSlotStatus === ShiftSlotStatus.CONFIRMED ? (
                            <Text color={'green'}>
                              <Icon type={'check'} /> Confirmed
                            </Text>
                          ) : (
                            <Text color={'blue'}>
                              <Icon type={'clock-circle'} /> Pending
                            </Text>
                          )}
                        </Col>
                        <Col className="flex justify-start flex-wrap">
                          <HyperlinkButton className="ml-small" color="red" onClick={this._removeSelectedWorker}>
                            <Icon type="user-delete" className="text-color-black" /> Remove Team member
                          </HyperlinkButton>
                        </Col>
                      </Row>
                    </>
                  )}
                  {!newBookingData.selectedWorkerId &&
                    !newBookingData.isShiftPublished &&
                    (isFutureBooking ? (
                      <>
                        <div className="mb-medium">
                          <Icon type="warning" className="text-color-warning-orange mr-x-small" theme={'filled'} /> No
                          worker selected
                        </div>
                        <div>You can assign a Team member to this booking at a later time, or</div>
                        <div className="text-size-regular mt-x-small">
                          <HyperlinkButton onClick={this._openSelectWorkerModal} fontSize="regular">
                            Select Team member
                          </HyperlinkButton>{' '}
                          {!newBookingData.isRecurring && (
                            <>
                              or{' '}
                              <HyperlinkButton onClick={this._openPublishSettingsModal} fontSize="regular">
                                Publish to Team members
                              </HyperlinkButton>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mb-medium">
                          <Icon type="warning" className="text-color-warning-orange mr-x-small" theme={'filled'} /> No
                          team member selected
                        </div>
                        <div>A Team member must be selected for past bookings.</div>
                        <div className="text-size-regular mt-x-small">
                          <HyperlinkButton onClick={this._openSelectWorkerModal} fontSize="regular">
                            Select Team member
                          </HyperlinkButton>
                        </div>
                      </>
                    ))}
                  {newBookingData.isShiftPublished && (
                    <>
                      <div>
                        <Text className="mr-x2-large">
                          <Icon type="wifi" className="text-color-violet-light mr-x-small" />
                          To be published to team members
                        </Text>
                        <HyperlinkButton onClick={this._openPublishSettingsModal} className="mr-large">
                          <Icon type="setting" className="mr-x-small text-color-secondary" />
                          Settings
                        </HyperlinkButton>
                        <HyperlinkButton color="red" onClick={this._removePublish}>
                          <Icon type="close-circle" className="mr-x-small text-color-secondary" />
                          Remove Publish
                        </HyperlinkButton>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </div>

            {!isFutureBooking &&
              (newBookingData.selectedService.isChargeTransportBeforeBooking ||
                newBookingData.selectedService.isChargeTransportDuringBooking) && (
                <div className="mb-x-large">
                  <Row>
                    <Col span={4}>
                      <SubTitle>Travel claims</SubTitle>
                    </Col>
                    <Col span={20}>
                      {newBookingData.selectedService.isChargeTransportBeforeBooking && (
                        <div className="bordered border-standard-gray bg-quaternary ph-large pv-medium">
                          <Checkbox
                            checked={newBookingData.isTravelBeforeBooking}
                            onChange={this._handleTravelBeforeBooking}
                          >
                            <Text
                              color={'secondary'}
                              weight={'bold'}
                              size="small"
                              className="text-uppercase letter-spacing-wide"
                            >
                              Claim for travel to and from the booking
                            </Text>
                          </Checkbox>
                          {newBookingData.isTravelBeforeBooking && (
                            <>
                              <Row className={'mt-small'}>
                                <Col span={8}>
                                  <Text color={'secondary'} size={'small'}>
                                    DISTANCE TRAVELLED
                                  </Text>
                                </Col>
                                <Col span={8}>
                                  <Text color={'secondary'} size={'small'}>
                                    TRAVEL TIME
                                  </Text>
                                </Col>
                                <Col span={8}>
                                  <Text color={'secondary'} size={'small'}>
                                    ADDITIONAL EXPENSES
                                  </Text>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={8}>
                                  <Text color={'secondary'}>
                                    <Form.Item className="width-full">
                                      {getFieldDecorator('travelDistanceBeforeBooking', {
                                        initialValue: newBookingData.travelDistanceBeforeBooking,
                                        rules: [{ required: true, message: 'Please enter a distance.' }],
                                      })(
                                        <NumberInput
                                          size={'large'}
                                          min={0}
                                          max={10000}
                                          className={'mr-small'}
                                          addonAfter={'km'}
                                          style={{ width: '150px' }}
                                        />,
                                      )}
                                    </Form.Item>
                                  </Text>
                                </Col>
                                <Col span={8}>
                                  <Text color={'secondary'}>
                                    <Form.Item className="width-full">
                                      {getFieldDecorator('travelTimeBeforeBooking', {
                                        initialValue: newBookingData.travelTimeBeforeBooking,
                                        rules: [{ required: true, message: 'Please enter a travel time.' }],
                                      })(
                                        <NumberInput
                                          size={'large'}
                                          min={0}
                                          max={10000}
                                          precision={0}
                                          className={'mr-small'}
                                          addonAfter={'minutes'}
                                          style={{ width: '150px' }}
                                        />,
                                      )}
                                    </Form.Item>
                                  </Text>
                                </Col>
                                <Col span={8}>
                                  <Text color={'secondary'}>
                                    <Form.Item className="width-full">
                                      {getFieldDecorator('additionalCostBeforeBooking', {
                                        initialValue: newBookingData.additionalCostBeforeBooking,
                                      })(
                                        <NumberInput
                                          size={'large'}
                                          min={0}
                                          max={10000}
                                          className={'mr-small'}
                                          addonBefore={'$'}
                                          style={{ width: '150px' }}
                                        />,
                                      )}
                                    </Form.Item>
                                  </Text>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                      )}
                      {newBookingData.selectedService.isChargeTransportDuringBooking && (
                        <div className="mt-large bordered border-standard-gray bg-quaternary ph-large pv-medium">
                          <Checkbox
                            checked={newBookingData.isTravelDuringBooking}
                            onChange={this._handleTravelDuringBooking}
                          >
                            <Text
                              color={'secondary'}
                              weight={'bold'}
                              size="small"
                              className="text-uppercase letter-spacing-wide"
                            >
                              Claims for travels during the booking
                            </Text>
                          </Checkbox>
                          {newBookingData.isTravelDuringBooking && (
                            <>
                              <Row className={'mt-small'}>
                                <Col span={8}>
                                  <Text color={'secondary'} size={'small'}>
                                    DISTANCE TRAVELLED
                                  </Text>
                                </Col>
                                <Col span={8} />
                                <Col span={8}>
                                  <Text color={'secondary'} size={'small'}>
                                    ADDITIONAL EXPENSES
                                  </Text>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={8}>
                                  <Text color={'secondary'}>
                                    <Form.Item className="width-full">
                                      {getFieldDecorator('travelDistanceDuringBooking', {
                                        initialValue: newBookingData.travelDistanceDuringBooking,
                                        rules: [{ required: true, message: 'Please enter a distance.' }],
                                      })(
                                        <NumberInput
                                          size={'large'}
                                          min={0}
                                          max={10000}
                                          className={'mr-small'}
                                          addonAfter={'km'}
                                          style={{ width: '150px' }}
                                        />,
                                      )}
                                    </Form.Item>
                                  </Text>
                                </Col>
                                <Col span={8} />
                                <Col span={8}>
                                  <Text color={'secondary'}>
                                    <Form.Item className="width-full">
                                      {getFieldDecorator('additionalCostDuringBooking', {
                                        initialValue: newBookingData.additionalCostDuringBooking,
                                      })(
                                        <NumberInput
                                          size={'large'}
                                          min={0}
                                          max={10000}
                                          className={'mr-small'}
                                          addonBefore={'$'}
                                          style={{ width: '150px' }}
                                        />,
                                      )}
                                    </Form.Item>
                                  </Text>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              )}

            <div className="mb-large">
              <Row>
                <Col span={4}>
                  <SubTitle>{isFutureBooking ? `Instructions\n(Optional)` : 'Booking note'}</SubTitle>
                </Col>
                <Col span={20}>
                  {isFutureBooking && (
                    <Text>
                      Leave any miscellaneous details about the booking that you’d like the team member(s) to know
                      about.{' '}
                    </Text>
                  )}
                  <Input.TextArea
                    placeholder={isFutureBooking ? 'Write instructions here.' : 'Write a booking note here.'}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    value={this.state.bookingCustomNote}
                    onChange={this._onEnterInstructions}
                  />

                  <div className="text-align-right">
                    <Text color="secondary" size="regular">
                      {bookCustomNote.length} character(s)
                    </Text>
                  </div>
                  {isFutureBooking && (
                    <Information
                      content={
                        <Text>
                          <b>Hint:</b> You can also update your customer’s care information on their profile to include
                          details related to this customer’s support needs.
                        </Text>
                      }
                    />
                  )}
                </Col>
              </Row>
            </div>

            {newBookingData.isRecurring && newBookingData.timeSlots && (
              <Row className={'mb-large'}>
                <Col span={4} />
                <Col span={20}>
                  <div className="mt-medium">
                    The worker(s) and instructions here will be applied to all ({newBookingData.timeSlots.length})
                    booking
                    {newBookingData.timeSlots.length !== 1 && 's'} being created.
                  </div>
                </Col>
              </Row>
            )}
          </div>

          <div className="flex-row justify-between align-center mt-large">
            <SecondaryButton size="large" onClick={onPreviousStep} icon="left">
              Previous
            </SecondaryButton>

            <PrimaryButton
              size="large"
              onClick={this._onNextStep}
              icon="right"
              iconPosition="right"
              disabled={!isValidStep}
            >
              Next
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  newBookingData: state.bookingsStore.newBookingData,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doSetNewBookingData: dispatch.bookingsStore.doSetNewBookingData,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<Step4BookingInstructionsPanelProps>()(Step4BookingInstructionsPanel));
