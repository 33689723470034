// noinspection DuplicatedCode
import React, { Component } from 'react';
import { Avatar, notification } from 'antd';
import moment from 'moment-timezone';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core';
import { ISession } from 'interfaces/session-interfaces';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { ShiftClashContent } from 'common-components/shift-clash';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import CommonUtils from 'utilities/common-utils';

interface IConfirmShiftSlotModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot;
  onClose: any;
  doShiftConfirmSlot: typeof dispatch.groupServiceStore.doShiftConfirmSlot;
  doRemoveShiftSlot: typeof dispatch.groupServiceStore.doRemoveShiftSlot;
  session: ISession;
  shiftClashConflicts: typeof state.groupServiceStore.shiftClashConflicts;
  doFetchConflictShiftSlots: typeof dispatch.groupServiceStore.doFetchConflictShiftSlots;
}

interface IConfirmShiftSlotModalState {
  step: number;
  canManuallyClose: boolean;
  isLoading: boolean;
  isConflict: boolean;
  shiftSlot: IShiftSlot;
  refreshShiftSlots: boolean;
}

class ConfirmShiftSlotModal extends Component<IConfirmShiftSlotModalProps, IConfirmShiftSlotModalState> {
  state = {
    step: 1,
    canManuallyClose: true,
    shiftSlot: this.props.shiftSlot,
    isLoading: false,
    isConflict: this.props.shiftClashConflicts && this.props.shiftClashConflicts.length > 0,
    refreshShiftSlots: false,
  };

  reset = () =>
    this.setState({
      step: 1,
      canManuallyClose: true,
      isLoading: false,
      shiftSlot: this.props.shiftSlot,
      isConflict: this.props.shiftClashConflicts && this.props.shiftClashConflicts.length > 0,
      refreshShiftSlots: false,
    });

  onConfirm = async (ignoreShiftClashShiftIds: string[]) => {
    const { doShiftConfirmSlot, session, shiftSlot } = this.props;
    const { serviceDateTimeId, serviceId } = session;
    const { supportWorkerAttendanceId } = shiftSlot;
    this.setState({ step: 2, canManuallyClose: false });

    try {
      const request = {
        serviceDateTimeId,
        serviceId,
        supportWorkerAttendanceIds: [supportWorkerAttendanceId],
        ignoreShiftClashShiftIds,
      };
      await doShiftConfirmSlot(request);
      this.setState({ canManuallyClose: true, refreshShiftSlots: true });
      this.onClose();
      notification.success({
        message: 'Shift confirmed',
        description: 'You have successfully confirmed a shift',
      });
    } catch (err) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: err.message });
    }
  };
  onClose = () => {
    const { onClose } = this.props;
    onClose({ targetFlag: 'isConfirmShiftSlotOpen' }, this.state.refreshShiftSlots);
    this.reset();
  };

  componentDidUpdate = async (prevProps) => {
    // Check conflicts every time the modal is open.
    if (this.props.isOpen && !prevProps.isOpen) {
      const { session, shiftSlot, doFetchConflictShiftSlots } = this.props;
      const { serviceDateTimeId, serviceId } = session;
      const { supportWorkerAttendanceId } = shiftSlot;
      const payload = { serviceDateTimeId, serviceId, supportWorkerAttendanceIds: [supportWorkerAttendanceId] };
      this.setState({ isLoading: true });
      await doFetchConflictShiftSlots(payload);
      this.setState({ isLoading: false });
    }
    if (this.props.shiftClashConflicts !== prevProps.shiftClashConflicts) {
      this.setState({ isConflict: this.props.shiftClashConflicts && this.props.shiftClashConflicts.length > 0 });
    }
    if (this.props.shiftSlot !== prevProps.shiftSlot) {
      this.setState({ shiftSlot: this.props.shiftSlot });
    }
  };

  render() {
    const { session, shiftClashConflicts } = this.props;
    const { step, isLoading, isConflict, shiftSlot } = this.state;

    if (!shiftSlot) {
      return <></>;
    }
    const totalShiftClashes = CommonUtils.calculateTotalShiftClashes(shiftClashConflicts);

    const { shiftStartDateTime, shiftEndDateTime } = shiftSlot;
    const { timezone } = session;
    const startDateTime = moment.tz(shiftStartDateTime, timezone);
    const endDateTime = moment.tz(shiftEndDateTime, timezone);
    const displayDateTime = `${startDateTime.format('hh:mm A')} - ${endDateTime.format(
      'hh:mm A',
    )}, ${endDateTime.format('D MMMM YYYY')}`;
    const workerFullName = `${shiftSlot.firstName || ''} ${shiftSlot.lastName || ''}`;
    const modalTitle =
      step === 3
        ? 'Shift confirmed'
        : step === 1 && isConflict
        ? 'Hold on, this team member is already working'
        : 'Confirm shift';

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={modalTitle}
        onClose={this.onClose}
        canCloseOutside={this.state.canManuallyClose}
      >
        {isLoading ? (
          <SpinningLoader size={150} message={'Loading...'} />
        ) : (
          <>
            {step === 1 && !isConflict && (
              <div>
                <div className="mb-medium">
                  <Text>You are about to confirm the following shift : </Text>
                </div>

                <div className="line-height-135 mb-medium">
                  <FieldLabel text={'SHIFT TIME'} />
                  <Text lineHeight={135}>{displayDateTime}</Text>
                </div>

                <div className="line-height-135 mb-medium">
                  <FieldLabel text={'ASSIGNED TO'} />
                  <div className="flex-row line-height-135 align-center">
                    <Avatar icon="user" src={shiftSlot.attachmentUrl} className="mr-small" />
                    <Text lineHeight={135}>{workerFullName}</Text>
                  </div>
                </div>

                <ActionModalFooter align="right">
                  <div className="flex-row align-center justify-end">
                    <SecondaryButton size="large" className="mr-medium" onClick={this.onClose}>
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton size="large" onClick={() => this.onConfirm([])}>
                      Confirm shift
                    </PrimaryButton>
                  </div>
                </ActionModalFooter>
              </div>
            )}

            {step === 1 && isConflict && (
              <>
                <ShiftClashContent
                  shiftClashConflicts={shiftClashConflicts}
                  description={
                    <Paragraph>
                      This team member is assigned to{' '}
                      <b>
                        {totalShiftClashes} shift{totalShiftClashes > 1 ? 's ' : ' '}
                      </b>
                      that conflict{totalShiftClashes === 1 ? 's' : ''} with this booking time. Be sure to double-check
                      their availability before confirming.
                    </Paragraph>
                  }
                  firstColumnTitle="Shift slot being assigned"
                  okButtonText="Confirm anyway"
                  isLoading={this.state.isLoading}
                  onCancel={this.onClose}
                  onOk={this.onConfirm}
                />
              </>
            )}

            {step === 2 && (
              <div className="line-height-135 anim-slide-left">
                <div className="flex-column align-center mv-large justify-center">
                  <div className="mb-medium">
                    <Spinner size={80} />
                  </div>
                  <div className="text-align-center">
                    <Text color="secondary" weight="bold">
                      Confirming shift...
                    </Text>
                    <br />
                    <Text color="secondary">This won't take long.</Text>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  shiftClashConflicts: state.groupServiceStore.shiftClashConflicts,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doShiftConfirmSlot: dispatch.groupServiceStore.doShiftConfirmSlot,
  doFetchConflictShiftSlots: dispatch.groupServiceStore.doFetchConflictShiftSlots,
  doRemoveShiftSlot: dispatch.groupServiceStore.doRemoveShiftSlot,
});

export default connect(mapState, mapDispatch)(ConfirmShiftSlotModal);
