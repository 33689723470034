import React, { createContext } from 'react';

import type { PropsWithChildren, Ref } from 'react';
import { LinkContext, LinkProviderProps } from '../link/link-provider';
import { AlertProps } from './';
import { AlertVariants } from './alert.css';

type AlertElement = Ref<HTMLDivElement>;
export type AlertProviderProps = AlertProps & {
  alertRef?: AlertElement;
};

export const AlertContext = createContext<AlertProviderProps>({} as AlertProviderProps);

export function AlertProvider(props: AlertProviderProps) {
  const { children, ...otherProps } = props;

  return <AlertContext.Provider value={otherProps}>{children}</AlertContext.Provider>;
}

export type AlertActionsProviderProps = PropsWithChildren<LinkProviderProps & AlertVariants>;

export function AlertActionsProvider(props: AlertActionsProviderProps) {
  const { children, tone, ...otherProps } = props;

  let linkKind;
  switch (tone) {
    case 'hero':
      linkKind = 'accent' as const;
      break;
    case 'success':
      linkKind = 'success' as const;
      break;
    case 'warning':
      linkKind = 'warning' as const;
      break;
    case 'danger':
      linkKind = 'destructive' as const;
      break;
    default:
      linkKind = 'accent' as const;
      break;
  }
  const providerProps = {
    underline: true,
    fontWeight: '$bold',
    kind: linkKind,
    ...otherProps,
  };

  return <LinkContext.Provider value={providerProps}>{children}</LinkContext.Provider>;
}
