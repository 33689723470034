import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const LogIn = forwardRef(function LogIn(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 3.75c1.19 0 2.02.001 2.656.073.62.07.962.199 1.214.392.156.12.295.26.415.415.193.252.322.594.392 1.214.072.636.073 1.466.073 2.656v7c0 1.19-.001 2.02-.073 2.656-.07.62-.199.962-.392 1.214a2.25 2.25 0 0 1-.415.415c-.252.193-.594.322-1.214.392-.636.072-1.466.073-2.656.073H14a.75.75 0 0 0 0 1.5h.545c1.135 0 2.05 0 2.779-.082.754-.085 1.403-.266 1.959-.693.26-.2.493-.432.692-.692.427-.556.608-1.205.693-1.96.082-.729.082-1.643.082-2.778v-7.09c0-1.135 0-2.05-.082-2.779-.085-.754-.266-1.403-.693-1.959a3.75 3.75 0 0 0-.692-.692c-.556-.427-1.205-.608-1.96-.693-.729-.082-1.643-.082-2.778-.082H14a.75.75 0 0 0 0 1.5h.5ZM9.53 7.47a.75.75 0 0 0-1.06 1.06l2.72 2.72H4a.75.75 0 0 0 0 1.5h7.19l-2.72 2.72a.75.75 0 1 0 1.06 1.06l4-4a.75.75 0 0 0 0-1.06l-4-4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
