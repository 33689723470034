import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@good/components';
import { Error } from '@good/illustrations';

const TableError = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'accountManagement.workLeaveTypesPage.table' });

  return (
    <div className="flex-column align-center m-large flex justify-center gap-4">
      <Text>
        <Error size="md" />
      </Text>
      <Text>{t('errorMessage')}</Text>
    </div>
  );
};

export default TableError;
