import React, { useState } from 'react';
import { ExpandSectionRow } from 'design-components/timeline';
import { TimelineSingleRow } from './timeline-rows';
import { Button, Text } from '@good/components';
import { ChevronUp } from '@good/icons';

export const TimelineExpandSectionRow = ({
  section,
  isLastSection,
}: {
  section: ExpandSectionRow;
  isLastSection: boolean;
}) => {
  const { label, rows } = section;

  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="flex flex-col">
      <div className="relative flex border-b border-gray-light-1 bg-gray-light-2">
        <div className="sticky left-0 flex items-center gap-1 px-1 py-1">
          <Button
            onClick={() => setIsExpanded(!isExpanded)}
            emphasis="quiet"
            tone="neutral"
            size="sm"
            className="border-transparent bg-transparent"
          >
            {isExpanded ? (
              <ChevronUp className="text-black transition-all" />
            ) : (
              <ChevronUp className="rotate-180 text-black transition-all" />
            )}
          </Button>
          <Text className="text-weak" size="sm">
            {label}
          </Text>
        </div>
      </div>
      {isExpanded &&
        rows.map((row, index) => {
          const isLastElement = rows.length - 1 === index;
          return (
            <TimelineSingleRow key={`section-row-${index}`} row={row} isLastElement={isLastElement && isLastSection} />
          );
        })}
    </div>
  );
};
