import React, { Component } from 'react';
import _ from 'lodash';
import { Input, Skeleton, Empty, Avatar } from 'antd';
import { SubTitle, Text, Title } from 'common-components/typography';
import { FilterType, InvoiceStatus } from 'utilities/enum-utils';
import { FilterSection } from 'common-components/filter';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import CommonUtils from 'utilities/common-utils';
import TextTag from 'common-components/tags/TextTag';
import moment from 'moment';
import { HyperlinkButton } from 'common-components/buttons';
import { Link } from 'react-router-dom';
import { InfiniteScroll } from 'components';

const { Search } = Input;
const availableFilters = [FilterType.CUSTOMER, FilterType.INVOICE_STATUS, FilterType.INVOICE_DATE];
const defaultFilters = [
  {
    filter: FilterType.CUSTOMER,
    values: [],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName,
  },
  {
    filter: FilterType.INVOICE_STATUS,
    values: [InvoiceStatus.PAID, InvoiceStatus.PROCESSED],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.INVOICE_STATUS).fullSelectionName,
  },
  {
    filter: FilterType.INVOICE_DATE,
    values: [],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.INVOICE_DATE).fullSelectionName,
  },
];

const ProcessedEmptyState = () => (
  <div className='flex-1 bg-white  align-center flex-column'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No Invoices found.
    </Text>{' '}
    <br /> <br />
    <Text color='secondary'>All invoices under this filter will appear here.</Text>
    <Text color='secondary'>Try adjusting your filter, or refresh the page.</Text>
  </div>
);

interface IDebtorInvoicesPanelProps {
  debtor: any;
  portalUser: typeof state.authStore.portalUser;
  debtorInvoices: typeof state.accountStore.debtorInvoices;
  doViewDebtorInvoiceList: typeof dispatch.accountStore.doViewDebtorInvoiceList;
}

interface IDebtorInvoicesPanelState {
  isSearching: boolean;
  isLoading: boolean;
  isLoadingInfiniteScrolling: boolean;
  invoiceFilters: any;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  topHeight: number;
  searchString;
}

class DebtorInvoicesPanel extends Component<IDebtorInvoicesPanelProps, IDebtorInvoicesPanelState> {
  state = {
    isSearching: false,
    isLoading: false,
    isLoadingInfiniteScrolling: false,
    invoiceFilters: [],
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    topHeight: 0,
    searchString: '',
  };

  private _formatFilterQuery = (appliedFilters) => {
    const requestFilter: any = {};
    _.forEach(appliedFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'invoiceDate':
            requestFilter.issueDate = [filter.values[0].toDate(), filter.values[1].toDate()];
            break;
          case 'invoiceStatus':
            requestFilter.invoiceStatus = filter.values;
            break;
          case 'paymentMethods':
            requestFilter.paymentMethods = filter.values;
            break;
          case 'customerUserIds':
            requestFilter.customerUserIds = _.map(filter.values, (customer) => {
              return customer.value;
            });
            break;
          case 'search':
            requestFilter.search = filter.values;
            break;
        }
      }
    });
    return requestFilter;
  };

  async componentDidMount() {
    const { doViewDebtorInvoiceList, debtor } = this.props;
    const { invoiceFilters, page, pageSize, pageTimestamp } = this.state;
    this.setState({ isLoading: true, invoiceFilters: defaultFilters });
    await doViewDebtorInvoiceList({
      ...this._formatFilterQuery(invoiceFilters),
      debtorId: debtor.debtorId,
      page: page,
      pageSize: pageSize,
      pageTimestamp: pageTimestamp,
    });
    this.setState({ isLoading: false });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { doViewDebtorInvoiceList, debtor } = this.props;
    const { invoiceFilters } = this.state;

    if (!_.isEqual(prevState.invoiceFilters, this.state.invoiceFilters)) {
      this.setState({ isLoading: true });
      const pageTimestamp = new Date();
      this.setState({ isLoading: true, page: 1, pageTimestamp });

      await doViewDebtorInvoiceList({
        ...this._formatFilterQuery(invoiceFilters),
        debtorId: debtor.debtorId,
        page: 1,
        pageSize: this.state.pageSize,
        pageTimestamp,
      });

      this.setState({ isLoading: false });
    }
  };

  private _applySearchFilter = async (searchStr) => {
    const { invoiceFilters } = this.state;
    let newPlanPaymentsFilter = _.clone(invoiceFilters);
    const existingSearchIndex = _.findIndex(newPlanPaymentsFilter, (filter: any) => filter.filter === 'search');
    if (existingSearchIndex > -1) {
      if (searchStr === '') {
        newPlanPaymentsFilter.splice(existingSearchIndex, 1);
      } else {
        newPlanPaymentsFilter[existingSearchIndex].values = searchStr;
      }
    } else {
      newPlanPaymentsFilter.push({ filter: 'search', values: searchStr });
    }

    this.setState({ invoiceFilters: newPlanPaymentsFilter, isSearching: false });
  };

  private _searchText = (txt) => {
    this._applySearchFilter(txt);
    this.setState({ isSearching: false, searchString: txt });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    this._debounceSearch(e.target.value);
  };

  private _onChangeFilter = (filters: Array<any>) => {
    console.log(filters);
    this.setState({ invoiceFilters: filters });
  };

  private _fetchMoreInvoices = async () => {
    const { doViewDebtorInvoiceList } = this.props;
    const { invoiceFilters, page, pageSize, pageTimestamp } = this.state;
    const nextPage = page + 1;
    this.setState({ isLoadingInfiniteScrolling: true, page: nextPage });

    await doViewDebtorInvoiceList({
      filters: invoiceFilters,
      page: nextPage,
      pageSize: pageSize,
      pageTimestamp: pageTimestamp,
    });

    this.setState({ isLoadingInfiniteScrolling: false });
  };

  render() {
    const { topHeight, isSearching, isLoading, page, pageSize, invoiceFilters } = this.state;
    const { portalUser, debtorInvoices } = this.props;
    const thTableStyle = { top: `${topHeight}px` };

    return (
      <>
        <div>
          <div>
            <Title level={2} className={'m-none'}>
              Invoices
            </Title>
            <Text type={'secondary'}>All invoices addressed to this debtor.</Text>
          </div>

          <div className='flex-row pb-medium align-center'>
            <div className='mr-x2-large' style={{ minWidth: '300px' }}>
              <Search
                onChange={this._onEnterSearchText}
                loading={isSearching}
                placeholder='Search by name, invoice or batch'
              />
            </div>
            <FilterSection
              availableFilters={availableFilters}
              filters={invoiceFilters}
              onChangeFilter={this._onChangeFilter}
              displayTimezone={portalUser.timezone}
              displayMoreFilter={false}
            />
          </div>
        </div>

        <table className='payment-listing ph-none'>
          <thead>
            <tr>
              <th style={thTableStyle} className='nowrap'>
                <SubTitle>INVOICE DATE</SubTitle>
              </th>
              <th style={thTableStyle} className='nowrap'>
                <SubTitle>INVOICE NUMBER</SubTitle>
              </th>
              <th style={thTableStyle} className='nowrap'>
                <SubTitle>CUSTOMER</SubTitle>
              </th>
              <th style={thTableStyle} className='nowrap'>
                <SubTitle>STATUS</SubTitle>
              </th>
              <th style={thTableStyle} className='nowrap'>
                <SubTitle>BATCH</SubTitle>
              </th>
              <th style={thTableStyle} className='nowrap text-align-right'>
                <SubTitle containerClassName='text-align-right'>Total</SubTitle>
              </th>
            </tr>
          </thead>

          <tbody>
            {!isLoading && _.isEmpty(debtorInvoices) && (
              <tr style={{ cursor: 'default' }}>
                <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                  <ProcessedEmptyState />
                </td>
              </tr>
            )}

            <InfiniteScroll
              hasMore={debtorInvoices.length >= page * pageSize}
              loadMore={this._fetchMoreInvoices}
              asTableRow
            >
              {_.map(debtorInvoices, (invoiceItem) => (
                <tr
                  key={invoiceItem.invoiceNumber}
                  className={`anim-fade-in bg-white no-border`}
                  style={{ cursor: 'auto' }}
                >
                  <td style={{ width: '150px' }} className='fluid-cell'>
                    {moment(invoiceItem.exportedDate).format('DD/MM/YYYY')}
                  </td>
                  <td style={{ width: '200px' }} className='fluid-cell'>
                    <HyperlinkButton
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(`/pdf?type=view-invoice&invoiceId=${invoiceItem.invoiceId}`, '_blank');
                      }}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {invoiceItem.invoiceNumber}
                    </HyperlinkButton>
                  </td>
                  <td style={{ width: '270px' }} className='fluid-cell'>
                    <div className='flex-row align-center'>
                      <div>
                        <Avatar
                          className='mr-medium'
                          size='large'
                          icon='user'
                          src={invoiceItem.customerAttachmentUrl}
                        />
                      </div>
                      <div
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'pre-line',
                        }}
                        className='mr-medium'
                      >
                        <Text>{invoiceItem.firstName + ' ' + invoiceItem.lastName}</Text>
                      </div>
                    </div>
                  </td>
                  <td style={{ width: '200px' }} className='fluid-cell'>
                    <TextTag
                      theme={'light'}
                      color={invoiceItem.invoiceStatus === InvoiceStatus.PAID ? 'green' : 'blue'}
                      content={invoiceItem.invoiceStatus === InvoiceStatus.PAID ? 'Paid' : 'Unpaid'}
                    />
                  </td>
                  <td style={{ width: '200px' }} className='fluid-cell'>
                    <Link to={`/batch/details/${invoiceItem.batchId}`}>
                      <HyperlinkButton>{invoiceItem.batchNumber}</HyperlinkButton>
                    </Link>
                  </td>
                  <td className='fluid-cell' style={{ textAlign: 'right' }}>
                    <Text>{`$${invoiceItem.invoiceTotal}`}</Text>
                  </td>
                </tr>
              ))}
            </InfiniteScroll>
            {isLoading && (
              <tr style={{ borderBottom: '0px solid !important' }}>
                <td colSpan={7}>
                  <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  debtorInvoices: state.accountStore.debtorInvoices,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doViewDebtorInvoiceList: dispatch.accountStore.doViewDebtorInvoiceList,
});

export default connect(mapState, mapDispatch)(DebtorInvoicesPanel);
