import Search from 'antd/lib/input/Search';
import { FilterSection } from 'common-components/filter';
import { timeZone } from 'interfaces/timezone-type';
import React, { PureComponent } from 'react';
import { availableWorkflowListingFilters } from '../../utils/constants';

interface Props {
  isSearching: boolean;
  workflowFilters: any;
  timezone: timeZone;
  onChangeFilter(filters: Array<any>): void;
  onSearchText(value: string): void;
}

class WorkflowListingFilterSection extends PureComponent<Props> {
  render() {
    const { workflowFilters, isSearching, timezone, onChangeFilter } = this.props;

    return (
      <div className="flex align-baseline">
        <Search
          placeholder="Search for a workflow"
          onChange={(e) => this.props.onSearchText(e.target.value)}
          loading={isSearching}
          style={{ width: '320px', height: '32px' }}
          allowClear={true}
          className="mr-medium"
        />

        <FilterSection
          availableFilters={availableWorkflowListingFilters}
          filters={workflowFilters}
          onChangeFilter={onChangeFilter}
          displayTimezone={timezone || null}
          containerClassName="pv-none"
          displayMoreFilter={false}
        />
      </div>
    );
  }
}

export default WorkflowListingFilterSection;
