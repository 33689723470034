import React, { Component } from 'react';
import { Avatar, notification, Icon } from 'antd';
import { FieldLabel, Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { Warning } from 'common-components/alerts';
import { IGroupBookingDetails } from 'views/group-services/manage-booking-modal/interfaces/group-booking-interfaces';
import { BookingStatusSteps } from 'views/group-services/manage-booking-modal/common-components/BookingStatusSteps';
import { BookingActionButtons } from 'views/group-services/manage-booking-modal/common-components/BookingActionButtons';
import EditCustomerScheduleModal from 'views/group-services/manage-booking-modal/panels/overview/EditCustomerScheduleModal';
import moment, { Moment } from 'moment-timezone';
import _ from 'lodash';
import { BookingStatus, PaymentSources, PaymentStatus } from 'utilities/enum-utils';
import FundingUtils from 'utilities/funding-utils';
import { withRouter, WithRouterProps } from 'utilities/with-router';
import BookingCancelActionModel from 'views/bookings/components/BookingCancellationModals/BookingCancelActionModal';
import BookingCancelChangeToCustomerCancelledModal from 'views/bookings/components/BookingCancelChangeToCustomerCancelledModal';
import BookingWarning from 'views/bookings/details/sections/content-section/tabs-panel/components/BookingWarning';
import CommonUtils from 'utilities/common-utils';
import { AcceptGroupBookingModal } from 'views/group-services/booking-modals/AcceptGroupBookingModal';
import { StartGroupBookingModal } from 'views/group-services/booking-modals/StartGroupBookingModal';
import { ConfirmGroupBookingModal } from 'views/group-services/booking-modals/ConfirmGroupBookingModal';
import { DeclineGroupBookingModal } from 'views/group-services/booking-modals/DeclineGroupBookingModal';
import { FinishGroupBookingModal } from 'views/group-services/booking-modals/FinishGroupBookingModal';
import { WaiveGroupBookingModal } from 'views/group-services/booking-modals/WaiveGroupBookingModal';
import { ReinstateGroupBookingModal } from 'views/group-services/booking-modals/ReinstateGroupBookingModal';
import { UnchargeGroupBookingCancellationFeeModal } from 'views/group-services/booking-modals/UnchargeGroupBookingCancellationFeeModal';
import { ChargeGroupBookingCancellationFeeModal } from 'views/group-services/booking-modals/ChargeGroupBookingCancellationFeeModal';
import { UndoGroupBookingModal } from 'views/group-services/booking-modals/UndoGroupBookingModal';
import { ChangeGroupBookingToBusinessCancelModal } from 'views/group-services/booking-modals/ChangeGroupBookingToBusinessCancelModal';
import { ApproveGroupBookingModal } from 'views/group-services/booking-modals/ApproveGroupBookingModal';
import { RevokeGroupBookingApprovalModal } from 'views/group-services/booking-modals/RevokeGroupBookingApprovalModal';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { ModalLoading } from '../../common-components/ModalLoading';

interface IBookingOverviewPanelProps extends WithRouterProps {
  booking: typeof state.groupBookingsStore.selectedGroupBookingItem;
  doFetchGroupBookingOverview: typeof dispatch.groupBookingsStore.doFetchGroupBookingOverview; // booking: IGroupBookingDetails;
  doAcceptGroupBooking: typeof dispatch.groupBookingsStore.doAcceptGroupBooking; // booking: IGroupBookingDetails;
  doConfirmGroupBooking: typeof dispatch.groupBookingsStore.doConfirmGroupBooking;
  doStartGroupBooking: typeof dispatch.groupBookingsStore.doStartGroupBooking;
  doDeclineGroupBooking: typeof dispatch.groupBookingsStore.doDeclineGroupBooking;
  doFinishGroupBooking: typeof dispatch.groupBookingsStore.doFinishGroupBooking;
  doCustomerWaiveGroupBooking: typeof dispatch.groupBookingsStore.doCustomerWaiveGroupBooking;
  doCustomerReinstateGroupBooking: typeof dispatch.groupBookingsStore.doCustomerReinstateGroupBooking;
  doChargeGroupBookings: typeof dispatch.groupBookingsStore.doChargeGroupBookings;
  doUndoGroupBookings: typeof dispatch.groupBookingsStore.doUndoGroupBookings;
  doUnchargeGroupBookingsCancellationFee: typeof dispatch.groupBookingsStore.doUnchargeGroupBookingsCancellationFee;
  doChangeGroupBookingToBusinessCancel: typeof dispatch.groupBookingsStore.doChangeGroupBookingToBusinessCancel;
  doApproveGroupBookingsPayment: typeof dispatch.groupBookingsStore.doApproveGroupBookingsPayment;
  doRevokeGroupBookingsApproval: typeof dispatch.groupBookingsStore.doRevokeGroupBookingsApproval;
  doDeleteGroupBookingSingleBillingItem: typeof dispatch.groupBookingsStore.doDeleteGroupBookingSingleBillingItem;
  onNavigate: ({ key, name }) => void;
  isLoading: boolean;
}

interface IBookingOverviewPanelState {
  isEditCustomerScheduleOpen: boolean;
  isAcceptModalOpen: boolean;
  isStartBookingModalOpen: boolean;
  isConfirmBookingModalOpen: boolean;
  isDeclineBookingModalOpen: boolean;
  isFinishBookingModalOpen: boolean;
  isWaiveBookingModalOpen: boolean;
  isReinstateBookingModalOpen: boolean;
  isChangeToCustomerCancelModalOpen: boolean;
  isUnchargeCancellationFeeModalOpen: boolean;
  isUndoBookingModalOpen: boolean;
  isRevokeGroupBookingApprovalModalOpen: boolean;
  isChargeCancellationFeeModalOpen: boolean;
  isApproveCustomerBookingOpen: boolean;
  isChangeToBusinessCancelModalOpen: boolean;
  isEditSchedule: boolean;
  isOpenBookingCancelActionModel: boolean;
}

class BookingOverviewPanel extends React.Component<IBookingOverviewPanelProps, IBookingOverviewPanelState> {
  state = {
    isEditCustomerScheduleOpen: false,
    isAcceptModalOpen: false,
    isStartBookingModalOpen: false,
    isConfirmBookingModalOpen: false,
    isDeclineBookingModalOpen: false,
    isFinishBookingModalOpen: false,
    isWaiveBookingModalOpen: false,
    isReinstateBookingModalOpen: false,
    isChargeCancellationFeeModalOpen: false,
    isUnchargeCancellationFeeModalOpen: false,
    isRevokeGroupBookingApprovalModalOpen: false,
    isUndoBookingModalOpen: false,
    isChangeToCustomerCancelModalOpen: false,
    isChangeToBusinessCancelModalOpen: false,
    isEditSchedule: false,
    isOpenBookingCancelActionModel: false,
    isApproveCustomerBookingOpen: false,
  };

  private _onAcceptBooking = () => {
    this.setState({ isAcceptModalOpen: true });
  };

  private _onCloseAcceptBooking = () => {
    this.setState({ isAcceptModalOpen: false });
  };

  private _onOpenStartBookingModal = () => {
    this.setState({ isStartBookingModalOpen: true });
  };

  private _onCloseStartBookingModal = () => {
    this.setState({ isStartBookingModalOpen: false });
  };

  private _onOpenConfirmBookingModalOpen = () => {
    this.setState({ isConfirmBookingModalOpen: true });
  };

  private _onCloseConfirmBookingModal = () => {
    this.setState({ isConfirmBookingModalOpen: false });
  };

  private _onOpenDeclineBookingModalOpen = () => {
    this.setState({ isDeclineBookingModalOpen: true });
  };

  private _onCloseDeclineBookingModal = () => {
    this.setState({ isDeclineBookingModalOpen: false });
  };

  private _onOpenFinishBookingModalOpen = () => {
    this.setState({ isFinishBookingModalOpen: true });
  };

  private _onCloseFinishBookingModal = () => {
    this.setState({ isFinishBookingModalOpen: false });
  };

  private _onOpenReinstateBookingModalOpen = () => {
    this.setState({ isReinstateBookingModalOpen: true });
  };

  private _onCloseReinstateBookingModal = () => {
    this.setState({ isReinstateBookingModalOpen: false });
  };

  private _onOpenWaiveBookingModalOpen = () => {
    this.setState({ isWaiveBookingModalOpen: true });
  };

  private _onCloseWaiveBookingModal = () => {
    this.setState({ isWaiveBookingModalOpen: false });
  };

  private _onOpenChangeToCustomerCancelModalOpen = () => {
    this.setState({ isChangeToCustomerCancelModalOpen: true });
  };

  private _onCloseChangeToCustomerCancelModal = () => {
    this.setState({ isChangeToCustomerCancelModalOpen: false });
  };

  private _onCloseChargeCancellationFeeModel = () => {
    this.setState({ isChargeCancellationFeeModalOpen: false });
  };

  private _onOpenChargeCancellationFeeModel = () => {
    this.setState({ isChargeCancellationFeeModalOpen: true });
  };

  private _onCloseRevokeGroupBookingApprovalModal = () => {
    this.setState({ isRevokeGroupBookingApprovalModalOpen: false });
  };

  private _onOpenRevokeGroupBookingApprovalModal = () => {
    this.setState({ isRevokeGroupBookingApprovalModalOpen: true });
  };

  private _onCloseUnchargeCancellationFeeModel = () => {
    this.setState({ isUnchargeCancellationFeeModalOpen: false });
  };

  private _onOpenUnchargeCancellationFeeModel = () => {
    this.setState({ isUnchargeCancellationFeeModalOpen: true });
  };

  private _onCloseBookingCancelActionModel = () => {
    this.setState({ isOpenBookingCancelActionModel: false });
  };

  private _onOpenBookingCancelActionModel = () => {
    this.setState({ isOpenBookingCancelActionModel: true });
  };

  private _onCloseUndoBookingModel = () => {
    this.setState({ isUndoBookingModalOpen: false });
  };

  private _onOpenChangeToBusinesModel = () => {
    this.setState({ isChangeToBusinessCancelModalOpen: true });
  };

  private _onCloseChangeToBusinessModel = () => {
    this.setState({ isChangeToBusinessCancelModalOpen: false });
  };

  private _onOpenApproveGroupBookingModal = () => {
    this.setState({ isApproveCustomerBookingOpen: true });
  };

  private _onCloseApproveGroupBookingModal = () => {
    this.setState({ isApproveCustomerBookingOpen: false });
  };

  private _onOpenUndoBookingModel = () => {
    this.setState({ isUndoBookingModalOpen: true });
  };

  private _onDeclineBooking = () => {
    this._onOpenDeclineBookingModalOpen();
  };

  private _onEditCustomerSchedule = (isEditSchedule = true) => {
    this.onOpenEditCustomerSchedule(isEditSchedule);
  };

  private _goToCustomer = async (customerUserId) => {
    const { history } = this.props;
    history.push(`/customer/details/${customerUserId}`);
  };

  private _getBillingErrors = () => {
    const { booking } = this.props;

    const lineItems = booking.billingLineItems;

    const isNoPaymentMethodItems = _.countBy(
      lineItems,
      (item) => !item.paymentMethod && item.paymentSourceType === PaymentSources.NDIS,
    ).true;

    const fundedLineItems = _.countBy(
      lineItems,
      (item) =>
        item.paymentSourceType === PaymentSources.NDIS &&
        !FundingUtils.doesLineItemFundingCategoryExistInFundingPackage(
          item.supportType,
          item.supportCategoryNumber,
          item.supportItemNumber,
          booking.fundedCategories,
        ),
    ).true;

    return { isNoPaymentMethodItems, fundedLineItems };
  };

  private _renderDuration = (startDateTime: Moment, endDateTime: Moment) => {
    const duration = CommonUtils.formatDuration(startDateTime, endDateTime);
    let durationDisplays = [];

    if (duration.days) {
      durationDisplays.push(
        <>
          <b>{duration.days.value}</b> {`${duration.days.label} `}
        </>,
      );
    }
    if (duration.hours) {
      durationDisplays.push(
        <>
          <b>{duration.hours.value}</b> {`${duration.hours.label} `}
        </>,
      );
    }

    if (duration.minutes) {
      durationDisplays.push(
        <>
          <b>{duration.minutes.value}</b> {duration.minutes.label}
        </>,
      );
    }
    return (
      <Text color="secondary">
        {_.map(durationDisplays, (display) => {
          return display;
        })}
      </Text>
    );
  };

  //endregion

  onOpenEditCustomerSchedule = (isEditSchedule) => this.setState({ isEditCustomerScheduleOpen: true, isEditSchedule });
  onCloseEditCustomerSchedule = () => this.setState({ isEditCustomerScheduleOpen: false });

  render() {
    const { booking, onNavigate, isLoading } = this.props;

    // Loading
    if (isLoading) {
      return <ModalLoading text={'Fetching booking overview...'} />;
    }

    const { firstName, lastName, attachmentUrl, status, paymentStatus } = booking;

    const isInProgressOrCompleted = _.includes([BookingStatus.INPROGRESS, BookingStatus.COMPLETED], status);
    const displayTimezone = booking.timezone;

    const billingErrors = this._getBillingErrors();

    return (
      <>
        {/* Main Overview Panel */}
        <div className="anim-fade-in-fast">
          {/* Avatar user section */}
          <section className="flex-row mb-large">
            <Avatar icon="user" size="large" src={attachmentUrl} />
            <div className="line-height-100 flex-column ml-small">
              <Text lineHeight={100} size="x-large">
                {firstName} {lastName}
              </Text>

              {/* TODO @ Jir Link to View full profile */}
              <HyperlinkButton className="mt-x-small" onClick={() => this._goToCustomer(booking.userId)}>
                View full profile
              </HyperlinkButton>
            </div>
          </section>

          {/* Warnings/errors section */}

          {/* Show the following section only when there are errors/warnings */}

          <section className="mb-large">
            {/* TODO @ Jir - Add actual warnings. */}

            {!_.isEmpty(booking.bookingErrors) && (
              <>
                {_.chain(booking.bookingErrors)
                  .orderBy('bookingErrorType', 'asc')
                  .map((bookingError, index) => {
                    return (
                      <BookingWarning
                        key={index}
                        bookingError={bookingError}
                        selectedBookingItem={booking}
                        serviceType={'GROUP'}
                      />
                    );
                  })
                  .value()}
              </>
            )}
          </section>

          {/* Scheduled time section */}
          <section className="p-large bordered rounded-big border-standard-gray mb-large">
            <div className="line-height-100 flex-row">
              {/* Scheduled attendance */}
              <div className="flex-1">
                <Text color="secondary" lineHeight={150} className="select-none">
                  Scheduled attendance
                </Text>
                <br />
                {/* TODO @ Jir Replace this with actual time */}
                {moment
                  .tz(booking.startDateTime, displayTimezone)
                  .isSame(moment.tz(booking.endDateTime, displayTimezone), 'day') ? (
                  <>
                    <Text size="large">
                      {moment.tz(booking.startDateTime, displayTimezone).format('LT')} -{' '}
                      {moment.tz(booking.endDateTime, displayTimezone).format('LT')}
                    </Text>
                    <br />
                    <Text>{moment.tz(booking.startDateTime, displayTimezone).format('dddd, DD MMM YYYY')}</Text>
                  </>
                ) : (
                  <>
                    <Text size="large">
                      {moment.tz(booking.startDateTime, displayTimezone).format('dddd, DD MMM YYYY')} -{' '}
                      {moment.tz(booking.startDateTime, displayTimezone).format('LT')}
                    </Text>
                    <br />
                    <Text size="large">
                      {moment.tz(booking.endDateTime, displayTimezone).format('dddd, DD MMM YYYY')} -{' '}
                      {moment.tz(booking.endDateTime, displayTimezone).format('LT')}
                    </Text>
                  </>
                )}
                <br />

                {!isInProgressOrCompleted && (
                  <>
                    <br />
                    <HyperlinkButton onClick={() => this._onEditCustomerSchedule(true)}>
                      Edit scheduled attendance...
                    </HyperlinkButton>
                  </>
                )}
              </div>

              {/* Actual attendance - will only be shown when IN PROGRESS / COMPLETED */}
              {isInProgressOrCompleted && (
                <div className="flex-1 mr-medium">
                  <Text color="secondary" lineHeight={150} className="select-none">
                    Actual attendance
                  </Text>
                  <br />
                  {/* TODO @ Jir Replace this with actual time */}
                  {status === BookingStatus.INPROGRESS && (
                    <>
                      <Text size="large" lineHeight={120}>
                        {moment.tz(booking.customerCheckedInDateTime, displayTimezone).format('LT')} -{' '}
                        <Text color="secondary">tbc</Text>
                      </Text>
                      <br />
                      <Text>
                        {moment.tz(booking.customerCheckedInDateTime, displayTimezone).format('dddd, DD MMM YYYY')}
                      </Text>
                    </>
                  )}
                  {status === BookingStatus.COMPLETED && (
                    <Text size="x-large" lineHeight={120}>
                      {moment
                        .tz(booking.customerCheckedInDateTime, displayTimezone)
                        .isSame(moment.tz(booking.customerCheckedOutDateTime, displayTimezone), 'day') ? (
                        <>
                          <Text size="large">
                            {moment.tz(booking.customerCheckedInDateTime, displayTimezone).format('LT')} -{' '}
                            {moment.tz(booking.customerCheckedOutDateTime, displayTimezone).format('LT')}
                          </Text>
                          <br />
                          <Text>
                            {moment.tz(booking.customerCheckedInDateTime, displayTimezone).format('dddd, DD MMM YYYY')}
                          </Text>
                        </>
                      ) : (
                        <>
                          <Text size="large">
                            {moment.tz(booking.customerCheckedInDateTime, displayTimezone).format('dddd, DD MMM YYYY')}{' '}
                            - {moment.tz(booking.customerCheckedInDateTime, displayTimezone).format('LT')}
                          </Text>
                          <br />
                          <Text size="large">
                            {moment.tz(booking.customerCheckedOutDateTime, displayTimezone).format('dddd, DD MMM YYYY')}{' '}
                            - {moment.tz(booking.customerCheckedOutDateTime, displayTimezone).format('LT')}
                          </Text>
                        </>
                      )}
                    </Text>
                  )}

                  {status === BookingStatus.COMPLETED &&
                    (booking.paymentStatus === PaymentStatus.INITIAL ||
                      booking.paymentStatus === PaymentStatus.REQUIRES_APPROVAL) && (
                      <>
                        <br />
                        <HyperlinkButton onClick={() => this._onEditCustomerSchedule(false)}>
                          Edit actual attendance...
                        </HyperlinkButton>
                      </>
                    )}
                </div>
              )}
            </div>
          </section>

          <section className="p-large bordered border-standard-gray rounded-big">
            <div className="mb-large">
              <FieldLabel text={'BOOKING STATUS'} />
              <BookingStatusSteps bookingStatus={status} />
            </div>

            <div className="mb-large">
              <FieldLabel text={'PAYMENT STATUS'} />

              {/* TODO @ Jir Replace this with a proper tag */}
              <PaymentStatusTag paymentStatus={booking.paymentStatus} />

              <div className="mt-small">
                <HyperlinkButton onClick={() => onNavigate({ key: 'billings', name: 'Billings' })}>
                  View billings
                </HyperlinkButton>
              </div>
            </div>

            {/* Booking actions */}

            <BookingActionButtons
              booking={booking}
              onAcceptBooking={this._onAcceptBooking}
              onDeclineBooking={this._onDeclineBooking}
              onStartBooking={this._onOpenStartBookingModal}
              onConfirmBooking={this._onOpenConfirmBookingModalOpen}
              onFinishBooking={this._onOpenFinishBookingModalOpen}
              onCancelBooking={this._onOpenBookingCancelActionModel}
              onWaiveBooking={this._onOpenWaiveBookingModalOpen}
              onReinstateBooking={this._onOpenReinstateBookingModalOpen}
              onChangeToCustomerCancel={this._onOpenChangeToCustomerCancelModalOpen}
              onChargeCancellationFee={this._onOpenChargeCancellationFeeModel}
              onRevertToConfirm={this._onOpenUndoBookingModel}
              onApprove={this._onOpenApproveGroupBookingModal}
              onRevokePayment={this._onOpenRevokeGroupBookingApprovalModal}
              onRemoveCancelCharge={this._onOpenUnchargeCancellationFeeModel}
              onBusinessCancel={this._onOpenChangeToBusinesModel}
              hasBillingErrors={billingErrors.isNoPaymentMethodItems >= 1 || booking.billingLineItems.length === 0}
            />
          </section>
        </div>

        <BookingCancelActionModel
          isOpen={this.state.isOpenBookingCancelActionModel}
          onClose={this._onCloseBookingCancelActionModel}
          serviceType={'GROUP'}
        />

        <EditCustomerScheduleModal
          isOpen={this.state.isEditCustomerScheduleOpen}
          isEditSchedule={this.state.isEditSchedule}
          onClose={this.onCloseEditCustomerSchedule}
          booking={this.props.booking}
        />
        <AcceptGroupBookingModal
          isOpen={this.state.isAcceptModalOpen}
          booking={this.props.booking}
          onClose={this._onCloseAcceptBooking}
          doAcceptGroupBooking={this.props.doAcceptGroupBooking}
        />
        <StartGroupBookingModal
          doStartGroupBooking={this.props.doStartGroupBooking}
          isOpen={this.state.isStartBookingModalOpen}
          onClose={this._onCloseStartBookingModal}
          booking={this.props.booking}
        />
        <ConfirmGroupBookingModal
          doConfirmGroupBooking={this.props.doConfirmGroupBooking}
          isOpen={this.state.isConfirmBookingModalOpen}
          onClose={this._onCloseConfirmBookingModal}
          booking={this.props.booking}
        />
        <DeclineGroupBookingModal
          doDeclineGroupBooking={this.props.doDeclineGroupBooking}
          isOpen={this.state.isDeclineBookingModalOpen}
          onClose={this._onCloseDeclineBookingModal}
          booking={this.props.booking}
        />
        <FinishGroupBookingModal
          doFinishGroupBooking={this.props.doFinishGroupBooking}
          isOpen={this.state.isFinishBookingModalOpen}
          onClose={this._onCloseFinishBookingModal}
          booking={this.props.booking}
        />
        <WaiveGroupBookingModal
          doWaiveGroupBooking={this.props.doCustomerWaiveGroupBooking}
          isOpen={this.state.isWaiveBookingModalOpen}
          onClose={this._onCloseWaiveBookingModal}
          booking={this.props.booking}
        />
        <ReinstateGroupBookingModal
          doReinstateGroupBooking={this.props.doCustomerReinstateGroupBooking}
          isOpen={this.state.isReinstateBookingModalOpen}
          onClose={this._onCloseReinstateBookingModal}
          booking={this.props.booking}
        />
        <BookingCancelChangeToCustomerCancelledModal
          isOpen={this.state.isChangeToCustomerCancelModalOpen}
          onClose={this._onCloseChangeToCustomerCancelModal}
          serviceType={'GROUP'}
        />

        <ChargeGroupBookingCancellationFeeModal
          doChargeGroupBookings={this.props.doChargeGroupBookings}
          isOpen={this.state.isChargeCancellationFeeModalOpen}
          onClose={this._onCloseChargeCancellationFeeModel}
          booking={this.props.booking}
        />
        <UnchargeGroupBookingCancellationFeeModal
          doUnchargeGroupBookingsCancellationFee={this.props.doUnchargeGroupBookingsCancellationFee}
          isOpen={this.state.isUnchargeCancellationFeeModalOpen}
          onClose={this._onCloseUnchargeCancellationFeeModel}
          booking={this.props.booking}
        />
        <UndoGroupBookingModal
          doUndoGroupBookings={this.props.doUndoGroupBookings}
          isOpen={this.state.isUndoBookingModalOpen}
          onClose={this._onCloseUndoBookingModel}
          booking={this.props.booking}
        />
        <ChangeGroupBookingToBusinessCancelModal
          doChangeGroupBookingToBusinessCancel={this.props.doChangeGroupBookingToBusinessCancel}
          isOpen={this.state.isChangeToBusinessCancelModalOpen}
          onClose={this._onCloseChangeToBusinessModel}
          booking={this.props.booking}
        />
        <ApproveGroupBookingModal
          doApproveGroupBookingsPayment={this.props.doApproveGroupBookingsPayment}
          isOpen={this.state.isApproveCustomerBookingOpen}
          onClose={this._onCloseApproveGroupBookingModal}
          booking={this.props.booking}
        />
        <RevokeGroupBookingApprovalModal
          doRevokeGroupBookingsApproval={this.props.doRevokeGroupBookingsApproval}
          isOpen={this.state.isRevokeGroupBookingApprovalModalOpen}
          onClose={this._onCloseRevokeGroupBookingApprovalModal}
          booking={this.props.booking}
        />
      </>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupBookingOverview: dispatch.groupBookingsStore.doFetchGroupBookingOverview,
  doAcceptGroupBooking: dispatch.groupBookingsStore.doAcceptGroupBooking,
  doConfirmGroupBooking: dispatch.groupBookingsStore.doConfirmGroupBooking,
  doStartGroupBooking: dispatch.groupBookingsStore.doStartGroupBooking,
  doDeclineGroupBooking: dispatch.groupBookingsStore.doDeclineGroupBooking,
  doFinishGroupBooking: dispatch.groupBookingsStore.doFinishGroupBooking,
  doCustomerWaiveGroupBooking: dispatch.groupBookingsStore.doCustomerWaiveGroupBooking,
  doCustomerReinstateGroupBooking: dispatch.groupBookingsStore.doCustomerReinstateGroupBooking,
  doChargeGroupBookings: dispatch.groupBookingsStore.doChargeGroupBookings,
  doUnchargeGroupBookingsCancellationFee: dispatch.groupBookingsStore.doUnchargeGroupBookingsCancellationFee,
  doUndoGroupBookings: dispatch.groupBookingsStore.doUndoGroupBookings,
  doChangeGroupBookingToBusinessCancel: dispatch.groupBookingsStore.doChangeGroupBookingToBusinessCancel,
  doRevokeGroupBookingsApproval: dispatch.groupBookingsStore.doRevokeGroupBookingsApproval,
  doApproveGroupBookingsPayment: dispatch.groupBookingsStore.doApproveGroupBookingsPayment,
  doDeleteGroupBookingSingleBillingItem: dispatch.groupBookingsStore.doDeleteGroupBookingSingleBillingItem,
});

const mapState = (state: IRootState) => ({
  booking: state.groupBookingsStore.selectedGroupBookingItem,
});

export default connect(mapState, mapDispatch)(withRouter(BookingOverviewPanel));

class PaymentStatusTag extends Component<{ paymentStatus: string }> {
  render() {
    const { paymentStatus } = this.props;

    const tag =
      paymentStatus === PaymentStatus.REQUIRES_APPROVAL ? (
        'Awaiting Approval'
      ) : paymentStatus === PaymentStatus.READY_FOR_BILLING ? (
        'Approved'
      ) : paymentStatus === PaymentStatus.SEND_TO_FINANCE ? (
        'Sent for Payment'
      ) : paymentStatus === PaymentStatus.PROCESSED ? (
        'Processed'
      ) : paymentStatus === PaymentStatus.REJECTED ? (
        'Declined'
      ) : paymentStatus === PaymentStatus.WAIVED ? (
        'Waived'
      ) : paymentStatus === PaymentStatus.BILLING_FAILED ? (
        <>
          <Icon type="warning" className="text-color-orange mr-x-small" /> Billing Issue
        </>
      ) : paymentStatus === PaymentStatus.NO_CHARGE ? (
        'Not charged'
      ) : (
        ''
      );

    return <Text>{tag}</Text>;
  }
}
