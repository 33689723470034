import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { SecondaryButton, PrimaryButton } from 'common-components/buttons';
import { Form, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { SubTitle } from 'common-components/typography';

const FormItem = Form.Item;

interface IFormPropertiesModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  title: {
    formTitle: string;
    formDescription: string;
  };
}

interface IFormPropertiesModalState {}

class FormPropertiesModal extends Component<IFormPropertiesModalProps, IFormPropertiesModalState> {
  render() {
    const {
      form,
      title: { formTitle, formDescription },
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <ActionModal title="Add form details" isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <FormItem>
          <SubTitle textClassName="pb-x-small">FORM TITLE</SubTitle>
          {getFieldDecorator('form-title', {
            initialValue: !!formTitle ? formTitle : 'Form title',
            rules: [
              { required: true, message: 'Please add a form title' },
              {
                max: 50,
                message: 'Max length of characters is 50',
              },
            ],
          })(<Input size="large" placeholder="Enter form title" />)}
        </FormItem>
        <FormItem>
          <SubTitle textClassName="pb-x-small">FORM DESCRIPTION (OPTIONAL)</SubTitle>
          {getFieldDecorator('form-description', {
            initialValue: !!formDescription ? formDescription : '',
            rules: [
              {
                max: 500,
                message: 'Max length of characters is 500',
              },
            ],
          })(<Input.TextArea rows={6} style={{ resize: 'none' }} placeholder="Add a description..." />)}
        </FormItem>

        <ActionModalFooter>
          <SecondaryButton className="mr-medium" size="large" onClick={this.props.onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this.props.onSave}>
            Save
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default Form.create<IFormPropertiesModalProps>()(FormPropertiesModal);
