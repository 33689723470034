import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Image = forwardRef(function Image(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.944 2.25h2.112c1.838 0 3.294 0 4.433.153 1.172.158 2.121.49 2.87 1.238.748.749 1.08 1.698 1.238 2.87.153 1.14.153 2.595.153 4.433v2.112c0 1.838 0 3.294-.153 4.433-.158 1.172-.49 2.121-1.238 2.87-.749.748-1.698 1.08-2.87 1.238-1.14.153-2.595.153-4.433.153h-2.112c-1.838 0-3.294 0-4.433-.153-1.172-.158-2.121-.49-2.87-1.238-.748-.749-1.08-1.698-1.238-2.87-.153-1.14-.153-2.595-.153-4.433v-2.112c0-1.838 0-3.294.153-4.433.158-1.172.49-2.121 1.238-2.87.749-.748 1.698-1.08 2.87-1.238 1.14-.153 2.595-.153 4.433-.153ZM6.71 3.89c-1.006.135-1.586.389-2.009.812-.423.423-.677 1.003-.812 2.009-.138 1.028-.14 2.382-.14 4.289v2c0 1.778.001 3.076.114 4.076l.606-.606 1.671-1.672.037-.037c.635-.635 1.165-1.165 1.642-1.529.504-.384 1.036-.654 1.68-.654.644 0 1.176.27 1.68.654.477.364 1.007.894 1.642 1.53l.037.036L14 15.94c.496-.493.926-.907 1.32-1.208.504-.384 1.036-.654 1.68-.654.644 0 1.176.27 1.68.654.448.342.943.83 1.528 1.415.041-.848.042-1.874.042-3.147v-2c0-1.907-.002-3.261-.14-4.29-.135-1.005-.389-1.585-.812-2.008-.423-.423-1.003-.677-2.009-.812-1.027-.138-2.382-.14-4.289-.14h-2c-1.907 0-3.261.002-4.29.14Zm13.26 14.14h-.001l-.672-.671c-.681-.682-1.14-1.139-1.528-1.434-.37-.283-.586-.347-.77-.347-.184 0-.4.064-.77.347-.315.24-.678.587-1.169 1.075l2.97 2.97c.573-.148.96-.365 1.267-.672.307-.306.524-.695.672-1.267Zm-3.824 2.178L13.47 17.53l-1.672-1.671c-.681-.682-1.14-1.139-1.528-1.434-.37-.283-.586-.347-.77-.347-.184 0-.4.064-.77.347-.387.295-.846.752-1.528 1.434L5.53 17.53l-1.227 1.228c.112.21.244.385.399.54.423.423 1.003.677 2.009.812 1.028.138 2.382.14 4.29.14h2c1.272 0 2.299 0 3.146-.042ZM15 7.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM12.25 9a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
