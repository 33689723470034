import { Text as IconText, Icons, CurrencyFormatter } from '@goodhuman-me/components';
import { Checkbox, Col, Form, Icon, Row, Select, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { GhostButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Title, Text, Paragraph, SubTitle } from 'common-components/typography';
import { cloneDeep, filter, groupBy, isEmpty, map, some, split } from 'lodash';
import React, { Component, createContext, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { ICustomer } from 'interfaces/customer-interfaces';
import { IRootState } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { INDISItem } from 'variables/data/ndis-helper';
import { MmmGroup, PaymentMethods } from 'utilities/enum-utils';
import { ndisHelper } from 'variables/data-helpers';
import NumberInput from 'common-components/inputs/NumberInput';
import { FundingNdisSupportCategory } from 'src/interfaces/funding-interfaces';

interface ISupportTypeProps {
  supportTypeName: string;
  canAddCategory: boolean;
  children: JSX.Element;
  disabled: boolean;
}

const SupportType = ({ supportTypeName, canAddCategory, children, disabled }: ISupportTypeProps) => {
  const {
    updateCategory,
    form,
    getCategory,
    categoryExists,
    addCategory,
    removeCategoriesWith,
    setNewSupportCategory,
  } = useFundingCategoriesContext();

  const supportType = getCategory(supportTypeName);
  const isActivated = categoryExists({ supportType: supportTypeName });

  const handleSupportTypeSelect = (value: boolean, supportType: FundingNdisSupportCategory) => {
    if (!value) {
      removeCategoriesWith(supportType.supportType);
      return;
    }

    addCategory({ ...supportType, allocated: 0.0 });
    setNewSupportCategory({ ...supportType });
  };

  const handleAddSupportCategory = (supportCategory: FundingNdisSupportCategory) => {
    if (!supportCategory.supportType) {
      return;
    }

    let supportTypeIsSelected = categoryExists({ supportType: supportCategory.supportType });
    if (!supportTypeIsSelected) {
      addCategory({ supportType: supportCategory.supportType });
    }

    setNewSupportCategory(supportCategory);
  };

  const { getFieldDecorator } = form;

  const validateFundingNumber = (rule, value, callback) => {
    try {
      if (value === null) {
        throw Error('Allocated should not be empty');
      } else if (value > 999999.0) {
        throw Error(`Allocated can't exceed $999,999.00.`);
      } else if (typeof value !== 'number') {
        throw Error('Allocated must be a number');
      }
    } catch (e) {
      callback(e);
    }
    callback();
  };

  return (
    <div className="bordered mv-medium">
      <div className={`pv-medium ph-medium width-full flex justify-between ${isActivated ? 'bordered-bottom' : ''}`}>
        <div className="flex align-center">
          <Checkbox
            checked={isActivated}
            className="mr-medium"
            onChange={(e) => handleSupportTypeSelect(e.target.checked, { supportType: supportTypeName })}
            disabled={disabled}
          />
          <Title level={4} className="mb-none">
            {supportTypeName}
          </Title>
          {canAddCategory && (
            <HyperlinkButton
              className="ml-medium"
              onClick={() => handleAddSupportCategory({ supportType: supportTypeName })}
            >
              <Icon type="plus" className="mr-x-small" />
              Add a category
            </HyperlinkButton>
          )}
        </div>
      </div>
      {isActivated && (
        <div className="pv-medium ph-medium">
          <Row>
            <Form.Item className="m-none" style={{ marginBottom: '1px' }}>
              <SubTitle>Allocated Funding</SubTitle>
              {getFieldDecorator(`allocated_${supportType.supportType}`, {
                initialValue: supportType.allocated,
                rules: [{ validator: validateFundingNumber }],
              })(
                <NumberInput
                  addonBefore="$"
                  precision={2}
                  min={0}
                  style={{ width: '120px', textAlign: 'right' }}
                  max={999999.0}
                  placeholder="0"
                  onChange={(event) =>
                    updateCategory({ supportType: supportTypeName, ...(supportType ?? {}), allocated: event })
                  }
                />,
              )}
            </Form.Item>
          </Row>

          {children}
        </div>
      )}
    </div>
  );
};

interface ISupportCategoryProps {
  supportCategory: FundingNdisSupportCategory;
  nameOverride?: string;
  children?: JSX.Element;
}

const SupportCategory = ({ supportCategory, nameOverride, children }: ISupportCategoryProps): JSX.Element => {
  const {
    categoryData,
    setNewSupportLineItem,
    updateCategory,
    updateCategories,
    showDeleteConfirmPrompt,
    getSupportLineItems,
    form,
  } = useFundingCategoriesContext();

  const handleChangePaymentMethod = (payload: FundingNdisSupportCategory) => {
    let allLineItems = getSupportLineItems(supportCategory.supportType, supportCategory.supportCategoryNumber);

    const updatedLineItems = allLineItems.map((lineItem) => ({ ...lineItem, paymentMethod: payload.paymentMethod }));

    updateCategories([payload, ...updatedLineItems]);
  };

  const allWithCategory = categoryData.filter(
    (category) => category.supportCategoryNumber === supportCategory.supportCategoryNumber,
  );

  const totalBudget = allWithCategory.reduce((total, category) => total + category.funding, 0);

  const { getFieldDecorator } = form;

  const validateFundingNumber = (rule, value, callback) => {
    try {
      if (value === null) {
        throw Error('Budget should not be empty');
      } else if (value > 999999.0) {
        throw Error(`Budget can't exceed $999,999.00.`);
      } else if (typeof value !== 'number') {
        throw Error('Budget must be a number');
      }
    } catch (e) {
      callback(e);
    }
    callback();
  };

  return (
    <>
      <div className="pv-medium">
        <Row>
          <Col span={12}>
            <SubTitle>Support Category</SubTitle>
          </Col>
          <Col span={5}>
            <SubTitle>Budget</SubTitle>
          </Col>
          <Col span={5}>
            <SubTitle>Payment Method</SubTitle>
          </Col>
        </Row>
        <Row>
          <Col span={12} className="pt-small">
            <Text>{nameOverride ?? supportCategory.supportCategoryName}</Text>
          </Col>
          <Col span={5} className="pt-x-small">
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator(
                `funding_${supportCategory.supportItemNumber ?? supportCategory.supportCategoryNumber}`,
                {
                  initialValue: supportCategory.funding,
                  rules: [{ validator: validateFundingNumber }],
                },
              )(
                <NumberInput
                  className="flex"
                  addonBefore="$"
                  precision={2}
                  min={0}
                  placeholder="0"
                  style={{ width: '120px', textAlign: 'right' }}
                  max={999999.0}
                  onChange={(event) => updateCategory({ ...supportCategory, funding: event })}
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item className="m-none">
              {getFieldDecorator(`paymentMethod_${supportCategory.supportCategoryNumber}`, {
                initialValue: supportCategory.paymentMethod,
                rules: [{ required: true, message: 'Please select payment method' }],
              })(
                <Select
                  value={supportCategory.paymentMethod}
                  placeholder="Payment Method"
                  style={{ width: '150px' }}
                  filterOption
                  optionFilterProp={'children'}
                  onChange={(paymentMethod: string) => handleChangePaymentMethod({ ...supportCategory, paymentMethod })}
                >
                  <option key={supportCategory.supportCategoryNumber} disabled value={null}>
                    <Text color="$muted">Payment Method</Text>
                  </option>
                  <option key={`${supportCategory.supportCategoryNumber}_1`} value={PaymentMethods.NDIA}>
                    NDIA-managed
                  </option>
                  <option key={`${supportCategory.supportCategoryNumber}_2`} value={PaymentMethods.PLAN}>
                    PLAN-managed
                  </option>
                  <option key={`${supportCategory.supportCategoryNumber}_3`} value={PaymentMethods.SELF}>
                    SELF-managed
                  </option>
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={2} className="flex justify-end">
            <Tooltip title="Delete Category and related Stated Supports funds.">
              <GhostButton paddingSize="small" color="red" onClick={() => showDeleteConfirmPrompt(supportCategory)}>
                <IconText color="$critical" size="small">
                  <Icons.Bin width="1em"></Icons.Bin>
                </IconText>
              </GhostButton>
            </Tooltip>
          </Col>
        </Row>
      </div>
      <Row className="ph-small pb-small bordered-bottom">
        <Col span={10}>
          <SubTitle weight="normal">Support Item</SubTitle>
        </Col>
        <Col span={2}>
          <SubTitle weight="normal">Unit</SubTitle>
        </Col>
        <Col span={5}>
          <SubTitle weight="normal">Price</SubTitle>
        </Col>
        <Col span={3}>
          <SubTitle weight="normal">Quantity</SubTitle>
        </Col>
        <Col span={3} className="text-align-right">
          <SubTitle weight="normal">Total Price</SubTitle>
        </Col>
      </Row>
      <Row className="ph-small">{children}</Row>
      <Row className="mh-small mv-medium">
        <HyperlinkButton
          onClick={() =>
            setNewSupportLineItem({
              supportType: supportCategory.supportType,
              supportCategoryNumber: supportCategory.supportCategoryNumber,
              supportCategoryName: supportCategory.supportCategoryName,
            })
          }
        >
          <Icon type="plus" className="mr-x-small" />
          Add support item
        </HyperlinkButton>
      </Row>
      <Row className="flex justify-end bg-tertiary ph-medium pv-small mt-small">
        <Title level={4}>
          {`Category budget total:`}
          <span className="pl-small">
            <CurrencyFormatter value={totalBudget}></CurrencyFormatter>
          </span>
        </Title>
      </Row>
    </>
  );
};

interface ISupportLineItemProps {
  supportLineItem: FundingNdisSupportCategory;
}

const SupportLineItem = ({ supportLineItem }: ISupportLineItemProps): JSX.Element => {
  const { updateCategory, showDeleteConfirmPrompt, form } = useFundingCategoriesContext();

  const lineItem = ndisHelper.getBySupportItemNumber(supportLineItem.supportItemNumber);

  const { getFieldDecorator } = form;
  const validatePriceNumber = (rule, value, callback) => {
    try {
      if (value === null) {
        throw Error('Price should not be empty');
      } else if (value > 999999.0) {
        throw Error(`Price can't exceed $999,999.00.`);
      } else if (typeof value !== 'number') {
        throw Error('Price must be a number');
      }
    } catch (e) {
      callback(e);
    }
    callback();
  };

  const validateQuantityNumber = (rule, value, callback) => {
    try {
      if (value === null) {
        throw Error('Quantity should not be empty');
      } else if (value > 999999.0) {
        throw Error(`Quantity can't exceed $999,999.00.`);
      } else if (typeof value !== 'number') {
        throw Error('Quantity must be a number');
      }
    } catch (e) {
      callback(e);
    }
    callback();
  };

  return (
    <Row className="mv-x-small">
      <Col span={10}>
        <Text size="small">{lineItem.SupportItem}</Text>
        <br />
        <Text weight="bold" size="small">
          {supportLineItem.supportItemNumber}
        </Text>
      </Col>
      <Col span={2} className="pt-small">
        <Text weight="bold" size="small">
          {lineItem.UnitOfMeasure}
        </Text>
      </Col>
      <Col span={5} className="pt-x-small">
        <Form.Item>
          {getFieldDecorator(`price_${supportLineItem.supportItemNumber}`, {
            initialValue: supportLineItem.price,
            rules: [{ validator: validatePriceNumber }],
          })(
            <NumberInput
              addonBefore="$"
              precision={2}
              min={0}
              placeholder="0"
              style={{ width: '120px', textAlign: 'right' }}
              max={999999.0}
              onChange={(evt) => updateCategory({ ...supportLineItem, price: evt })}
            />,
          )}
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item className="m-none">
          {getFieldDecorator(`quantity_${supportLineItem.supportItemNumber}`, {
            initialValue: supportLineItem.quantity,
            rules: [{ validator: validateQuantityNumber }],
          })(
            <NumberInput
              placeholder="0"
              precision={0}
              min={0}
              max={999999.0}
              onChange={(event) => updateCategory({ ...supportLineItem, quantity: event })}
            />,
          )}
        </Form.Item>
      </Col>
      <Col span={3} className="text-align-right pt-small">
        <Text size="small">
          <CurrencyFormatter value={supportLineItem.funding}></CurrencyFormatter>
        </Text>
      </Col>
      <Col span={1}>
        <div className="pt-x-small" style={{ paddingLeft: '12px' }}>
          <Tooltip title="Delete Category and related Stated Supports funds.">
            <GhostButton paddingSize="small" color="red" onClick={() => showDeleteConfirmPrompt(supportLineItem)}>
              <IconText color="$critical" size="small">
                <Icons.Bin width="1em"></Icons.Bin>
              </IconText>
            </GhostButton>
          </Tooltip>
        </div>
      </Col>
    </Row>
  );
};

const DeleteCategoryConfirmationModal = ({
  category,
  isOpen,
  onClose,
  onDelete,
}: {
  category: FundingNdisSupportCategory;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}): JSX.Element => {
  return (
    <ActionModal isOpen={isOpen} onClose={onClose} title={'Confirmation'} showCloseButton={true}>
      {category && (
        <>
          {!category.supportType ? (
            <>
              <Paragraph className={'mb-medium'}>
                This {!category.supportItemNumber ? 'Category' : 'Stated Support Item'} will be removed from this
                Funding package.
              </Paragraph>
              {!category.supportItemNumber && (
                <Paragraph className={'mb-medium'}>
                  All Stated Supports linked to this Category will be deleted as well.
                </Paragraph>
              )}
            </>
          ) : (
            <>
              <Paragraph className={'mb-medium'}>
                If you unselected funding for {category.supportType}, <b>all Categories linked to it will be deleted</b>{' '}
                as well.
              </Paragraph>
            </>
          )}
          <Paragraph className={'mb-medium'}>Do you want to proceed?</Paragraph>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={onClose}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={onDelete}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </>
      )}
    </ActionModal>
  );
};

const SupportCategorySelectRow = ({
  newSupportCategory,
}: {
  newSupportCategory: FundingNdisSupportCategory;
}): JSX.Element => {
  const { categoryData, form, removeCategoriesWith, setNewSupportCategory, addCategory } =
    useFundingCategoriesContext();

  const { getFieldDecorator } = form;

  const supportCategoryListFull = ndisHelper.getByPurposeType(newSupportCategory.supportType);
  // Group By Categories to get the list for this Support Types
  const supportCategoryList = groupBy(supportCategoryListFull, (b) => {
    return b.SupportCategories;
  });

  // For each categories create the Options object;
  const supportCategoryOptions = map(supportCategoryList, (category, key) => {
    const isAlreadySelected = some(
      categoryData,
      (data) => data.supportCategoryNumber === category[0].SupportCategoryNumber,
    );
    return (
      <option
        key={key}
        disabled={isAlreadySelected}
        value={category[0].SupportCategoryNumber + '|' + category[0].SupportCategories}
      >
        {key}
      </option>
    );
  });

  const handleSupportCategorySelect = (value: string, supportCategory: FundingNdisSupportCategory) => {
    const data = split(value, '|');
    const updatedSupportCategory = {
      ...supportCategory,
      supportCategoryNumber: Number(data[0]),
      supportCategoryName: data[1],
    };

    addCategory(updatedSupportCategory);
    setNewSupportCategory(null);
  };

  const handleCancelNewSupportCategory = () => {
    // remove support type category if the support type is empty (ie it does not have any supportCategories)
    let supportType = newSupportCategory.supportType;
    let categoriesWithSupportType = categoryData.filter((category) => category.supportType === supportType);
    if (categoriesWithSupportType.length === 1 && !categoriesWithSupportType[0].supportCategoryNumber) {
      removeCategoriesWith(supportType);
    }
    setNewSupportCategory(null);
  };
  return (
    <div className="mv-medium">
      <Row>
        <Col span={14}>
          <SubTitle>Support Category</SubTitle>
        </Col>
      </Row>
      <Row className="flex align-center">
        <Col span={14}>
          <Form.Item className="m-none">
            {getFieldDecorator('statedSupportsFunding', {
              rules: [{ required: true, message: 'Please select support category' }],
            })(
              <Select
                placeholder="Select a Support Category"
                className="width-4/5"
                showSearch
                filterOption
                optionFilterProp={'children'}
                onChange={(val: string) => handleSupportCategorySelect(val, newSupportCategory)}
              >
                {supportCategoryOptions}
              </Select>,
            )}
          </Form.Item>
        </Col>
        <Col span={10} className="text-align-right">
          <HyperlinkButton className="ml-medium" onClick={handleCancelNewSupportCategory}>
            Cancel
          </HyperlinkButton>
        </Col>
      </Row>
    </div>
  );
};

const SupportLineItemSelectRow = ({
  newSupportLineItem,
}: {
  newSupportLineItem: FundingNdisSupportCategory;
}): JSX.Element => {
  const { form, addCategory, selectedCustomer, setNewSupportLineItem, getCategory, categoryData } =
    useFundingCategoriesContext();

  const getLineItemPrice = (lineItem: INDISItem): number => {
    let mmmGroup = MmmGroup.NonRemote;

    if (selectedCustomer?.mmmGroup) {
      mmmGroup = selectedCustomer.mmmGroup;
    }

    let price = Number(lineItem.NationalNonRemote);
    switch (mmmGroup) {
      case MmmGroup.NonRemote:
        price = Number(CommonUtils.findStandardPrice(lineItem, selectedCustomer.state, 'JSON'));
        break;
      case MmmGroup.Remote:
        price = Number(lineItem.NationalRemote);
        break;
      case MmmGroup.VeryRemote:
        price = Number(lineItem.NationalVeryRemote);
        break;
    }

    return price;
  };

  const handleSupportLineItemSelect = (value: string, supportLineItem: FundingNdisSupportCategory) => {
    let updatedLineItem = {
      ...supportLineItem,
    };

    const data = split(value, '|');
    const categoryName = ndisHelper.getBySupportItemNumber(data[1]).SupportCategories;
    updatedLineItem = {
      ...updatedLineItem,
      supportCategoryNumber: Number(data[0]),
      supportItemNumber: data[1],
      supportCategoryName: categoryName,
      price: 0,
    };

    const lineItem = ndisHelper.getBySupportItemNumber(updatedLineItem.supportItemNumber);
    if (lineItem) {
      updatedLineItem.price = getLineItemPrice(lineItem);
    }

    const supportCategory = getCategory(supportLineItem.supportType, supportLineItem.supportCategoryNumber);
    if (supportCategory) {
      updatedLineItem.paymentMethod = supportCategory.paymentMethod;
    }

    addCategory(updatedLineItem);
    setNewSupportLineItem(null);
  };

  const supportCategoryListFull = ndisHelper.getByPurposeType(newSupportLineItem.supportType);

  // For each categories create the Options object;
  const supportLineItemOptions = filter(supportCategoryListFull, (lineItem) => {
    if (newSupportLineItem.supportType === 'Core') {
      return lineItem.SupportPurposeType === newSupportLineItem.supportType;
    }

    return (
      lineItem.SupportPurposeType === newSupportLineItem.supportType &&
      lineItem.SupportCategoryNumber === newSupportLineItem.supportCategoryNumber
    );
  }).map((lineItem, key) => {
    const isDisabled = categoryData.some((category) => category.supportItemNumber === lineItem.SupportItemNumber);
    return (
      <option
        title={lineItem.SupportItemNumber + ' - ' + lineItem.SupportItem}
        key={key}
        disabled={isDisabled}
        value={lineItem.SupportCategoryNumber + '|' + lineItem.SupportItemNumber + '|' + lineItem.SupportItem}
      >
        {lineItem.SupportItemNumber + ' - ' + lineItem.SupportItem}
      </option>
    );
  });

  const { getFieldDecorator } = form;

  return (
    <Row className="flex align-center pv-small">
      <Col span={18}>
        <Form.Item className="m-none" style={{ marginBottom: '1px' }}>
          {getFieldDecorator('statedSupportsFunding', {
            rules: [{ required: true, message: 'Please select line item' }],
          })(
            <Select
              placeholder="Select line item"
              className="width-4/5"
              showSearch
              filterOption
              optionFilterProp={'children'}
              onChange={(val: string) => handleSupportLineItemSelect(val, newSupportLineItem)}
            >
              {supportLineItemOptions}
            </Select>,
          )}
        </Form.Item>
      </Col>
      <Col span={6} className="text-align-right">
        <HyperlinkButton className="ml-medium" onClick={() => setNewSupportLineItem(null)}>
          Cancel
        </HyperlinkButton>
      </Col>
    </Row>
  );
};

const FundingCategoriesSummary = () => {
  const supportTypes = ndisHelper.supportPurposeTypes.filter((data) => !isEmpty(data));
  const { categoryData } = useFundingCategoriesContext();

  let totalAllocated = 0;
  let totalBudgeted = 0;

  const rowClasses = 'pt-small';

  const renderSupportTypeSummary = (supportType: string) => {
    let allCategories = categoryData.filter((category) => category.supportType === supportType);

    const allocated = allCategories
      .filter((category) => category.allocated)
      .reduce((total, category) => total + category.allocated, 0);
    const budgeted = allCategories
      .filter((category) => category.funding)
      .reduce((total, category) => total + category.funding, 0);

    totalAllocated += allocated;
    totalBudgeted += budgeted;

    return (
      <Row key={supportType} className={rowClasses}>
        <Col span={14}>
          <Text>{`${supportType} allocated funding`}</Text>
        </Col>
        <Col span={5} className="text-align-right">
          <Text>
            <CurrencyFormatter value={allocated}></CurrencyFormatter>
          </Text>
        </Col>
        <Col span={5} className="text-align-right">
          <Text>
            <CurrencyFormatter value={budgeted}></CurrencyFormatter>
          </Text>
        </Col>
      </Row>
    );
  };

  return (
    <div className="bg-tertiary ph-medium pv-medium">
      <Row>
        <Col span={14}></Col>
        <Col span={5} className="text-align-right">
          <Text weight="bold">Allocated</Text>
        </Col>
        <Col span={5} className="text-align-right">
          <Text weight="bold">Budgeted</Text>
        </Col>
      </Row>
      {supportTypes.map((supportType) => renderSupportTypeSummary(supportType))}
      <Row className={rowClasses}>
        <Col span={14}>
          <Title level={4}>Total allocated funding</Title>
        </Col>
        <Col span={5} className="text-align-right">
          <Title level={4}>
            <CurrencyFormatter value={totalAllocated}></CurrencyFormatter>
          </Title>
        </Col>
        <Col span={5} className="text-align-right">
          <Title level={4}>
            <CurrencyFormatter value={totalBudgeted}></CurrencyFormatter>
          </Title>
        </Col>
      </Row>
    </div>
  );
};

interface FundingCategoriesProviderProps extends FormComponentProps {
  categoryData: FundingNdisSupportCategory[];
  newSupportLineItem: FundingNdisSupportCategory;
  selectedCustomer: ICustomer;
  getCategory: (
    supportType: string,
    supportCategoryNumber?: number,
    supportItemNumber?: string,
  ) => FundingNdisSupportCategory;
  updateCategory: (updated: FundingNdisSupportCategory) => void;
  updateCategories: (updated: FundingNdisSupportCategory[]) => void;
  categoryExists: (payload: FundingNdisSupportCategory) => boolean;
  addCategory: (payload: FundingNdisSupportCategory) => void;
  removeCategoriesWith: (supportType: string, supportCategoryNumber?: number, supportItemNumber?: string) => void;
  setNewSupportCategory: (payload: FundingNdisSupportCategory) => void;
  setNewSupportLineItem: (payload: FundingNdisSupportCategory) => void;
  showDeleteConfirmPrompt: (payload: FundingNdisSupportCategory) => void;
  getSupportLineItems: (supportType?: string, supportCategoryNumber?: number) => FundingNdisSupportCategory[];
}

const FundingEditCategoriesPanelContext = createContext<FundingCategoriesProviderProps>(null);

const useFundingCategoriesContext = () => {
  let context = useContext(FundingEditCategoriesPanelContext);
  return context;
};
interface FundingEditCategoriesPanelProps extends FormComponentProps {
  categoryData: FundingNdisSupportCategory[];
  selectedCustomer: ICustomer;
  updateCategoryData: (categoryData: FundingNdisSupportCategory[]) => void;
}

export const FundingEditCategoriesPanel = (props: FundingEditCategoriesPanelProps): JSX.Element => {
  let { categoryData, selectedCustomer, updateCategoryData, form } = props;

  let [newSupportCategory, setNewSupportCategory] = useState<FundingNdisSupportCategory>(null);
  let [newSupportLineItem, setNewSupportLineItem] = useState<FundingNdisSupportCategory>(null);

  let [showDeleteModal, setDeleteModal] = useState(false);
  let [categoryToDelete, setCategoryToDelete] = useState<FundingNdisSupportCategory>(null);

  const findCategoryIndex = (category: FundingNdisSupportCategory) => {
    const index = categoryData.findIndex(
      (c) =>
        c.supportType === category.supportType &&
        c.supportCategoryNumber === category.supportCategoryNumber &&
        c.supportItemNumber === category.supportItemNumber,
    );
    return index;
  };

  const addCategory = (category: FundingNdisSupportCategory) => {
    const existingIndex = findCategoryIndex(category);
    if (existingIndex !== -1) {
      return;
    }

    let newCategoryData = cloneDeep(categoryData);

    newCategoryData.push({ funding: 0, allocated: 0, ...category });
    updateCategoryData(newCategoryData);
  };

  const removeCategories = (categories: FundingNdisSupportCategory[]) => {
    let newCategoryData = cloneDeep(categoryData);
    categories.forEach((category) => {
      let existingIndex = newCategoryData.findIndex(
        (c) =>
          c.supportType === category.supportType &&
          c.supportCategoryNumber === category.supportCategoryNumber &&
          c.supportItemNumber === category.supportItemNumber,
      );

      if (existingIndex === -1) {
        return;
      }

      newCategoryData.splice(existingIndex, 1);
    });

    updateCategoryData(newCategoryData);
  };

  const removeCategoriesWith = (supportType: string, supportCategoryNumber?: number, supportItemNumber?: string) => {
    let categories = categoryData.filter((category) => category.supportType === supportType);
    let remainingOfSupportType = cloneDeep(categories);

    if (supportCategoryNumber != null) {
      categories = categories.filter((category) => category.supportCategoryNumber === supportCategoryNumber);
      remainingOfSupportType = remainingOfSupportType.filter(
        (category) => category.supportCategoryNumber !== supportCategoryNumber,
      );
    }

    if (supportItemNumber != null) {
      categories = categories.filter((category) => category.supportItemNumber === supportItemNumber);
      remainingOfSupportType = remainingOfSupportType.filter(
        (category) => category.supportItemNumber !== supportItemNumber,
      );
    }

    // remove last category if it is a support type
    if (remainingOfSupportType.length === 1 && !remainingOfSupportType[0].supportCategoryNumber) {
      categories.push(remainingOfSupportType[0]);
    }

    removeCategories(categories);
  };

  const showDeleteConfirmPrompt = (category: FundingNdisSupportCategory) => {
    setCategoryToDelete(category);
    setDeleteModal(true);
  };

  const deletePromptConfirmed = () => {
    setDeleteModal(false);
    removeCategoriesWith(
      categoryToDelete.supportType,
      categoryToDelete.supportCategoryNumber,
      categoryToDelete.supportItemNumber,
    );
    setNewSupportCategory(null);
    setNewSupportLineItem(null);
  };

  const updateCategory = (updatedCategory: FundingNdisSupportCategory) => {
    updateCategories([updatedCategory]);
  };

  const updateCategories = (updatedCategories: FundingNdisSupportCategory[]) => {
    let newCategoryData = cloneDeep(categoryData);

    updatedCategories.forEach((updatedCategory) => {
      let index = findCategoryIndex(updatedCategory);
      if (index === -1) {
        return;
      }

      let shouldGenerateFunding = Boolean(updatedCategory.supportItemNumber);
      if (shouldGenerateFunding) {
        updatedCategory.funding = (updatedCategory.quantity ?? 0) * (updatedCategory.price ?? 0);
      }

      newCategoryData.splice(index, 1, updatedCategory);
    });

    updateCategoryData(newCategoryData);
  };

  const getCategory = (
    supportType: string,
    supportCategoryNumber?: number,
    supportItemNumber?: string,
  ): FundingNdisSupportCategory => {
    const category = categoryData?.find((c) => {
      let match = c.supportType === supportType;

      if (supportCategoryNumber) {
        match = match && c.supportCategoryNumber === supportCategoryNumber;
      }

      if (supportItemNumber) {
        match = match && c.supportItemNumber === supportItemNumber;
      }

      return match;
    });

    if (!category) {
      return null;
    }

    return { ...category };
  };

  const categoryExists = (category: FundingNdisSupportCategory): boolean => {
    const index = categoryData.findIndex((c) => {
      let match = c.supportType === category.supportType;

      if (category.supportCategoryNumber) {
        match = match && c.supportCategoryNumber === category.supportCategoryNumber;
      }

      if (category.supportItemNumber) {
        match = match && c.supportItemNumber === category.supportItemNumber;
      }

      return match;
    });
    return index !== -1;
  };

  const supportTypes = ndisHelper.supportPurposeTypes.filter((data) => !isEmpty(data));
  const getSupportCategories = (supportType: string): FundingNdisSupportCategory[] => {
    if (!categoryData) {
      return [];
    }

    let supportCategories = categoryData.filter(
      (category) =>
        category.supportType === supportType && category.supportCategoryNumber && !category.supportItemNumber,
    );

    return cloneDeep(supportCategories);
  };

  const getSupportLineItems = (supportType?: string, supportCategoryNumber?: number): FundingNdisSupportCategory[] => {
    if (!categoryData) {
      return [];
    }

    let supportLineItems = categoryData.filter((category) => category.supportItemNumber);

    if (supportType) {
      supportLineItems = supportLineItems.filter((category) => category.supportType === supportType);
    }

    if (supportCategoryNumber) {
      supportLineItems = supportLineItems.filter(
        (category) => category.supportCategoryNumber === supportCategoryNumber,
      );
    }

    return cloneDeep(supportLineItems);
  };

  let isCreatingAnyCategory = newSupportCategory !== null || newSupportLineItem !== null;

  return (
    <FundingEditCategoriesPanelContext.Provider
      value={{
        categoryData,
        newSupportLineItem,
        selectedCustomer,
        updateCategory,
        updateCategories,
        getCategory,
        categoryExists,
        addCategory,
        removeCategoriesWith,
        setNewSupportCategory,
        setNewSupportLineItem,
        showDeleteConfirmPrompt,
        getSupportLineItems,
        form,
      }}
    >
      <DeleteCategoryConfirmationModal
        isOpen={showDeleteModal}
        category={categoryToDelete}
        onClose={() => setDeleteModal(false)}
        onDelete={deletePromptConfirmed}
      ></DeleteCategoryConfirmationModal>

      {supportTypes.map((supportType: string) => {
        const categorySupportType = getCategory(supportType);

        return (
          <SupportType
            key={supportType}
            supportTypeName={supportType}
            canAddCategory={supportType !== 'Core'}
            disabled={isCreatingAnyCategory}
          >
            <>
              {supportType === 'Core' && categoryExists({ supportType }) && (
                <SupportCategory
                  key={supportType + 'Category'}
                  supportCategory={categorySupportType}
                  nameOverride="All Core Categories"
                >
                  <>
                    {getSupportLineItems(supportType).map((supportLineItem) => (
                      <SupportLineItem
                        key={supportLineItem.supportItemNumber}
                        supportLineItem={supportLineItem}
                      ></SupportLineItem>
                    ))}

                    {newSupportLineItem?.supportType === supportType && (
                      <SupportLineItemSelectRow newSupportLineItem={newSupportLineItem}></SupportLineItemSelectRow>
                    )}
                  </>
                </SupportCategory>
              )}

              {supportType !== 'Core' && (
                <>
                  {getSupportCategories(supportType).map((supportCategory) => (
                    <SupportCategory key={supportCategory.supportCategoryNumber} supportCategory={supportCategory}>
                      <>
                        {getSupportLineItems(supportType, supportCategory.supportCategoryNumber).map(
                          (supportLineItem) => (
                            <SupportLineItem
                              key={supportLineItem.supportItemNumber}
                              supportLineItem={supportLineItem}
                            ></SupportLineItem>
                          ),
                        )}

                        {newSupportLineItem?.supportType === supportType &&
                          newSupportLineItem.supportCategoryNumber === supportCategory.supportCategoryNumber && (
                            <SupportLineItemSelectRow
                              newSupportLineItem={newSupportLineItem}
                            ></SupportLineItemSelectRow>
                          )}
                      </>
                    </SupportCategory>
                  ))}

                  {newSupportCategory?.supportType === supportType && (
                    <SupportCategorySelectRow newSupportCategory={newSupportCategory}></SupportCategorySelectRow>
                  )}
                </>
              )}
            </>
          </SupportType>
        );
      })}

      <FundingCategoriesSummary></FundingCategoriesSummary>
    </FundingEditCategoriesPanelContext.Provider>
  );
};

const mapState = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer,
});

interface IFundingEditCategoriesPanelWrapperProps extends FormComponentProps {
  categoryData: FundingNdisSupportCategory;
  updateCategoryData: (categoryData) => void;
}
class FundingEditCategoriesPanelWrapper extends Component<IFundingEditCategoriesPanelWrapperProps, unknown> {
  render() {
    return <FundingEditCategoriesPanel {...this.props}></FundingEditCategoriesPanel>;
  }
}

export default connect(
  mapState,
  null,
)(Form.create<IFundingEditCategoriesPanelWrapperProps>({})(FundingEditCategoriesPanelWrapper));
