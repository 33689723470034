import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { Form, Icon, Input, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import _ from 'lodash';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { IServiceDepartmentList } from 'interfaces/service-interfaces';

interface IEditServiceDepartmentModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  selectedServiceDepartment: any;
  serviceDepartmentList: IServiceDepartmentList[];
  doEditServiceDepartment: typeof dispatch.servicesStore.doEditServiceDepartment;
}

interface IEditServiceDepartmentModalState {
  title: any;
  isEdited: boolean;
  serviceDepartmentName: string;
}

class EditServiceDepartmentModal extends Component<IEditServiceDepartmentModalProps, IEditServiceDepartmentModalState> {
  state = { title: 'Edit service department', isEdited: false, serviceDepartmentName: null };

  private _onCloseModal = () => {
    this.setState({ title: 'Edit service department', isEdited: false });
    this.props.onClose();
  };

  private _editDepartment = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      const newServiceDepartmentName = form.getFieldValue('serviceDepartmentName');
      try {
        await this.props.doEditServiceDepartment({
          serviceDepartmentId: this.props.selectedServiceDepartment.serviceDepartmentId,
          serviceDepartmentName: newServiceDepartmentName,
        });
        this.setState({
          title: (
            <>
              Service department created successfully
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isEdited: true,
          serviceDepartmentName: newServiceDepartmentName,
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  private _validateName = (rule, value, callback) => {
    const { serviceDepartmentList } = this.props;
    try {
      if (_.isEmpty(value)) {
        throw Error('Please enter a name.');
      }
      if (
        _.find(serviceDepartmentList, (department) => {
          return department.serviceDepartmentName.toLowerCase() === value.toLowerCase();
        })
      ) {
        throw new Error('You already have a department with this name.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  render() {
    const { isOpen, selectedServiceDepartment, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isEdited ? (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <SubTitle>New service department name</SubTitle>
                <Title level={4}>{this.state.serviceDepartmentName}</Title>
              </div>

              <ActionModalFooter>
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  Done
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        ) : (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <SubTitle>Current service department name</SubTitle>
                <Title level={4}>
                  {selectedServiceDepartment ? selectedServiceDepartment.serviceDepartmentName : ''}
                </Title>

                <SubTitle>new service department name</SubTitle>
                <Form.Item className="width-full">
                  {getFieldDecorator('serviceDepartmentName', { rules: [{ validator: this._validateName }] })(
                    <Input size={'large'} style={{ width: '400px' }} placeholder="Enter name" />,
                  )}
                </Form.Item>
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._editDepartment}>
                  Confirm
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditServiceDepartment: dispatch.servicesStore.doEditServiceDepartment,
});

export default connect(null, mapDispatch)(Form.create<IEditServiceDepartmentModalProps>()(EditServiceDepartmentModal));
