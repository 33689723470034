import moment from 'moment';
import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';

const { HOUR_BLOCK_WIDTH, TIMELINE_CARD_PADDING } = ROSTER_DAILY_CONFIG;

/*
  roundUp - Takes an input, and rounds up to the nearest interval.
  If the rounded number is less than the minimum, it'll return the minimum instead.
  eg 0 -> 30, 7 -> 30, 15 -> 30, 30 -> 30, 31 -> 45, 45 -> 45 ... and so forth.
*/
function roundUp(x, interval = 15, minimum = 15) {
  let final = 0;

  if (x === 0) {
    final = 0;
  } else if (x % interval > 0) {
    final = (Math.floor(x / interval) + 1) * interval;
  } else {
    final = x;
  }

  return final < minimum ? minimum : final;
}

/*
  roundDown - Takes an input, and rounds down to the nearest interval.
  If the rounded number is less than the minimum, it'll return the minimum instead.
  eg 0 -> 30, 15 -> 30, 30 -> 30, 31 -> 30, 45 -> 45, 58 -> 45, 60 -> 60 ... and so forth.
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function roundDown(x, interval = 15, minimum = 15) {
  let final = 0;

  if (x === 0) {
    final = 0;
  } else if (x % interval > 0) {
    final = Math.floor(x / interval) * interval;
  } else {
    final = x;
  }

  return final < minimum ? minimum : final;
}

/* Calculates the offset position for a card based on a date. */
export function getTimelineOffset(date) {
  const mStartDate = moment(date);
  const minutes = roundUp(mStartDate.hours() * 60 + mStartDate.minutes(), 1, 0);

  // Rounded function. Doesn't work too well; to figure this out later.
  //   const minutes = roundDown(mStartDate.hours() * 60 + mStartDate.minutes(), 15, 0);

  return (minutes / 60) * HOUR_BLOCK_WIDTH + TIMELINE_CARD_PADDING;
}

/* Calculates the width of a position based on start/end dates.  */
export function getTimelineCardWidth(startDate, endDate, baseWidth = HOUR_BLOCK_WIDTH) {
  const mStartDate = moment(startDate);
  let mEndDate = moment(endDate);

  if (!mStartDate.isSame(mEndDate, 'day')) {
    // There's a cross over...
    mEndDate = moment(mStartDate).endOf('day');
  }

  let minutesDiff = 0;

  minutesDiff = roundUp(mEndDate.diff(mStartDate, 'minutes'), 1, 13);

  // Rounded function. Doesn't work too well; to figure this out later.
  // minutesDiff = roundUp(mEndDate.diff(mStartDate, 'minutes'), 15, 15);

  return (minutesDiff / 60) * baseWidth - TIMELINE_CARD_PADDING * 2;
}
