import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Avatar, Icon } from 'antd';
import { getBookingTheme } from 'common-components/roster-control/common/roster-utilities';
import { IRosterBooking } from 'common-components/roster-control/interfaces/roster-interfaces';
import BookingCardPopover from 'common-components/roster-control/roster-weekly/customers/BookingCardPopover';
import { ROSTER_WEEKLY_CONFIG } from 'common-components/roster-control/roster-weekly/roster-weekly-config';
import { BookingTypeIcon, StatusTag } from 'common-components/tags';
import { Text } from 'common-components/typography';
import * as H from 'history';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { FaUserTimes } from 'react-icons/fa';
import CommonUtils from 'utilities/common-utils';
import { BookingStatus, BookingType, ServiceType, ShiftSlotStatus } from 'utilities/enum-utils';

interface BookingCardProps {
  booking: IRosterBooking;
  refreshData: () => void;
  history: H.History;
}

const { ITEM_CARD_HEIGHT } = ROSTER_WEEKLY_CONFIG;

export function BookingCard({ booking, refreshData, history }: BookingCardProps) {
  const { startDateTime, endDateTime, status, serviceName, scheduleName } = booking;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [canClosePopover, setCanClosePopover] = useState<boolean>(true);

  const mStartDate = moment(startDateTime);
  const mEndDate = moment(endDateTime);

  const startDateDisplay = mStartDate.format('h:mm a');
  const endDateDisplay = mEndDate.format('h:mm a');

  const { bgColor, borderColor } = getBookingTheme(status);

  const openClosePopover = (nextOpenState) => {
    if (canClosePopover) {
      setIsOpen(nextOpenState);
    }
  };

  const refreshAndCloseData = () => {
    setIsOpen(false);
    refreshData();
  };

  const isCancelled = !!_.find(
    [
      BookingStatus.BUSINESS_CANCELLED,
      BookingStatus.CUSTOMER_CANCELLED,
      BookingStatus.CUSTOMER_CANCELLED_WITH_FEE,
      BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE,
    ],
    (status) => status === booking.status,
  );

  return (
    <div className="p-x-small" style={{ width: '100%' }}>
      <Popover2
        content={
          <BookingCardPopover
            booking={booking}
            setCanClosePopover={setCanClosePopover}
            refreshAndCloseData={refreshAndCloseData}
            history={history}
          />
        }
        targetTagName={'div'}
        position="right-bottom"
        isOpen={isOpen}
        onInteraction={(nextOpenState) => openClosePopover(nextOpenState)}
      >
        <div
          className={`rounded-big p-small line-height-120 cursor-pointer overflow-hidden anim-fade-in ${bgColor} ${borderColor}`}
          style={{ width: '100%', minHeight: `${ITEM_CARD_HEIGHT}px` }}
        >
          <div className={'flex-row justify-between align-center '}>
            <div className="whitespace-nowrap overflow-hidden inline-block">
              <StatusTag status={status} size="small" />
            </div>
            {/*
            // @ts-ignore */}
            {booking.serviceType === ServiceType.INDIVIDUAL && (
              <div className={'ml-small'}>
                <Tooltip2
                  content={
                    <Text color={'white'}>
                      {booking.workerId
                        ? booking.workerFirstName + ' ' + booking.workerLastName
                        : booking.shiftSlotStatus === ShiftSlotStatus.PUBLISHING
                        ? 'Published'
                        : 'No team member assigned'}
                    </Text>
                  }
                  position={'bottom-right'}
                >
                  <div className={'flex-row align-center'}>
                    {booking.workerId ? (
                      <>
                        <Avatar
                          size={20}
                          className={'text-white'}
                          style={{
                            backgroundColor: CommonUtils.getBackgroundColor(
                              booking.workerFirstName + booking.workerLastName,
                            ),
                          }}
                          shape="circle"
                        >
                          {booking.workerFirstName && booking.workerFirstName[0].toUpperCase()}{' '}
                          {booking.workerLastName && booking.workerLastName[0].toUpperCase()}
                        </Avatar>
                        <Icon
                          type={booking.shiftSlotStatus === ShiftSlotStatus.PENDING ? 'clock-circle' : 'check-circle'}
                          className={`${
                            booking.shiftSlotStatus === ShiftSlotStatus.PENDING
                              ? 'text-color-warning-orange'
                              : 'text-color-blue-lighter'
                          } text-size-small`}
                          theme={'filled'}
                          style={{ marginTop: '1px', marginLeft: '1px' }}
                        />
                      </>
                    ) : booking.shiftSlotStatus === ShiftSlotStatus.PUBLISHING ? (
                      <Icon type="wifi" className="text-color-violet-light mr-x-small text-size-regular" />
                    ) : !isCancelled ? (
                      <FaUserTimes className={'text-color-red text-size-large'} />
                    ) : (
                      <></>
                    )}
                  </div>
                </Tooltip2>
              </div>
            )}
          </div>

          <div
            className="line-height-120 whitespace-nowrap overflow-hidden text-overflow-ellipsis"
            style={{ width: '100%' }}
          >
            <Text size="regular" lineHeight={120}>
              <b>{startDateDisplay}</b> - {endDateDisplay}
            </Text>
            <br />
            <div className={'flex-row align-center'}>
              <div style={{ minWidth: '16px' }}>
                <BookingTypeIcon
                  type={
                    booking.bookingType === BookingType.ACTIVITY_RECORD
                      ? BookingType.ACTIVITY_RECORD
                      : booking.serviceType
                  }
                  className={'mr-x-small'}
                  style={{ minWidth: '16px' }}
                />
              </div>
              <Text size="regular" lineHeight={120}>
                {booking.serviceType === ServiceType.GROUP && scheduleName ? scheduleName : serviceName}
              </Text>
            </div>
          </div>
        </div>
      </Popover2>
    </div>
  );
}
