/* eslint-disable */
import React, { PureComponent } from 'react';
import CommonUtils from '../../utilities/common-utils';
import TextTag from 'common-components/tags/TextTag';

interface IServiceStatusTagProps {
  status: string;
  size?: 'small' | 'default' | 'large';
  textSize?: string;
  className?: any;
  textClassName?: any;
  rounded?: boolean;
}

export default class ServiceStatusTag extends PureComponent<IServiceStatusTagProps, any> {
  render() {
    const { status } = this.props;

    let tagColor = 'blue';
    let theme = 'dark';

    switch (status) {
      case 'DRAFT':
        tagColor = 'blue';
        theme = 'light';
        break;
      case 'PUBLISHED':
        tagColor = 'blue';
        theme = 'dark';
        break;
      case 'UNPUBLISHED':
        tagColor = 'gold';
        theme = 'light';
        break;
      case 'ARCHIVED':
        tagColor = 'secondary';
        theme = 'dark';
        break;
    }

    return (
      <TextTag
        content={CommonUtils.formatServiceStatusString(status)}
        textSize="large"
        theme={theme}
        color={tagColor}
      />
    );
  }
}
