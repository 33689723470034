import { forwardRef } from 'react';
import { Illustration } from './illustration';
import type { IllustrationProps } from './illustration';
import type { Ref } from 'react';
export const OpenDoor = forwardRef(function OpenDoor(props: IllustrationProps, ref: Ref<SVGSVGElement>) {
  return (
    <Illustration {...props} ref={ref}>
      <path
        d="M6.72 100.775c3.434-.38 6.861-.83 10.303-1.122 4.543-.385 9.103-.577 13.638-1.05.535-.056 1.303-1.321 1.35-2.065.331-5.343.378-10.707.755-16.046.602-8.535 1.584-17.047 2.042-25.588.483-8.98.244-18.006.89-26.97.413-5.74 1.817-11.413 2.896-17.094.103-.534 1.029-1.227 1.64-1.306 9.127-1.147 18.25-2.374 27.407-3.228 6.987-.651 14.018-.871 21.034-1.152 2.907-.118 5.828.105 8.743.169 1.252.028 2.139.304 2.175 1.878.126 5.487.416 10.97.535 16.457.316 14.65.47 29.304.897 43.95.265 9.061.92 18.11 1.383 27.167.074 1.485-.098 3 .149 4.45.1.597 1.003 1.509 1.545 1.515 4.632.03 9.265-.095 13.897-.236.461-.012.895-.575 1.368-.623.607-.064 1.291.02 1.838.266.255.112.491.984.355 1.114-.391.368-.972.733-1.486.749-5.239.14-10.481.352-15.718.265-6.874-.112-13.743-.48-20.617-.73-.74-.029-1.483-.003-2.679-.003v6.432c0 4.245.036 8.487-.007 12.732-.02 2.049-1.023 2.673-2.918 1.773-8.759-4.163-17.569-8.236-26.217-12.619-5.187-2.629-10.082-5.816-15.175-8.64-1.056-.588-2.406-.979-3.601-.938-5.975.199-11.946.537-17.916.907-2.231.138-4.447.527-6.676.734-.591.056-1.208-.136-1.812-.212l-.016-.925-.002-.011Zm71.623 19.795c.152-2.052.422-3.733.378-5.41-.38-15.116-.064-30.208.88-45.304.75-12.023 1.09-24.07 1.706-36.101.082-1.613-.36-2.51-1.846-3.07-2.866-1.08-5.779-2.09-8.542-3.395-6.96-3.29-13.83-6.757-20.77-10.09-3.202-1.538-6.48-2.926-10.238-4.612-.715 6.43-1.875 12.453-1.957 18.491-.182 13.409-.666 26.774-2.1 40.116-.738 6.875-.722 13.833-1.514 20.7-.556 4.82 1.354 7.288 5.576 9.386 8.488 4.222 16.614 9.157 25.031 13.527 4.203 2.182 8.717 3.771 13.396 5.76v.002Z"
        fill="currentColor"
      />
      <path
        d="M67.574 75.39c-3.272.048-6.45-2.784-6.545-5.83-.095-3.051 2.563-5.67 5.723-5.64 3.46.03 6.468 2.686 6.553 5.78.08 2.93-2.656 5.646-5.73 5.69Zm-.146-9.743c-2.383.085-3.949 1.794-3.903 4.258.039 2.05 1.612 3.792 3.384 3.751 2.04-.048 3.958-2.174 3.935-4.36-.023-2.095-1.54-3.712-3.416-3.646v-.003Z"
        fill="currentColor"
      />
    </Illustration>
  );
});
