import { Col } from 'antd';
import React from 'react';
import { Pill } from '@good/ui/core';

type TmMgmtInvitedTypeColumn = {
  invitedType: string;
};

const TeamPill = ({ invitedType }: TmMgmtInvitedTypeColumn) => {
  if (invitedType === 'NEW') {
    return <Pill className='bg-positive-weak text-green-dark-2 font-semibold'>New</Pill>;
  }
  if (invitedType === 'RE-ENABLED') {
    return <Pill className='bg-blue-light-1 text-blue-dark-2 font-semibold'>Re-enabled</Pill>;
  }
  return null;
};

// Define the TmMgmtInvitedTypeColumn component correctly
export const TmMgmtInvitedTypeColumn = ({ invitedType }: TmMgmtInvitedTypeColumn) => (
  <Col span={4} className='bg-white'>
    <div className='align-center flex-row'>
      <TeamPill invitedType={invitedType} />
    </div>
  </Col>
);
