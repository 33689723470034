import { useRef, useState } from 'react';
import { Box, Button, Flex, Modal as MantineModal, ScrollArea, Text } from '@mantine/core';
import { ArrowLeft } from '@good/icons';
import { ModalFooter } from './modal-footer';
import { useIsScrollable } from '../../hooks/is-scrollable';
const getFooterVariant = (variant, footerVariant) => {
    if (footerVariant === 'centered') {
        if (variant === 'compact') {
            console.warn("Modal: the combination footerVariant 'centered' and variant 'compact' does not follow guidelines.");
        }
        return 'centered';
    }
    switch (variant) {
        case 'compact':
            return 'compact';
        default:
            return 'regular';
    }
};
export const Modal = (props) => {
    const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });
    const scrollAreaRef = useRef(null);
    const bodyRef = useRef(null);
    const isScrollable = useIsScrollable(scrollAreaRef, bodyRef);
    const { backProps, children, footerVariant, maxHeight = 500, size, subHeader, title, variant, cancelProps, confirmProps, subFooter, tertiaryLinkProps, titleDescription, ...modalRootProps } = props;
    const footerProps = {
        cancelProps,
        confirmProps,
        subFooter,
        tertiaryLinkProps,
    };
    const headerBoxShadow = '0px 2px 0px -1px rgba(0, 0, 0, 0.01), 0px 4px 6px 0px rgba(0, 0, 0, 0.04)';
    return (<MantineModal.Root {...modalRootProps} variant={variant} size={size}>
      <MantineModal.Overlay />
      <MantineModal.Content>
        <Box style={{
            boxShadow: scrollPosition.y > 0 ? headerBoxShadow : undefined,
        }}>
          <Flex direction='column' w='100%'>
            <MantineModal.Header style={{ alignItems: 'start' }}>
              <Flex gap={12} align='center'>
                {backProps && (<Button {...backProps} variant='outline' color='neutral' pl='0.5rem' pr='0.5rem'>
                    <ArrowLeft />
                  </Button>)}
                <Flex direction='column' gap='0.25rem'>
                  <MantineModal.Title>{title}</MantineModal.Title>
                  {titleDescription && (<Text size='sm' c='gray'>
                      {titleDescription}
                    </Text>)}
                </Flex>
              </Flex>
              <MantineModal.CloseButton />
            </MantineModal.Header>
            {subHeader && (<Box pl='var(--mb-padding)' pr='var(--mb-padding)' pb='1rem'>
                {subHeader}
              </Box>)}
          </Flex>
        </Box>

        <ScrollArea>
          <ScrollArea.Autosize mah={maxHeight} onScrollPositionChange={onScrollPositionChange} ref={scrollAreaRef}>
            <MantineModal.Body ref={bodyRef}>{children}</MantineModal.Body>
          </ScrollArea.Autosize>
        </ScrollArea>

        <ModalFooter {...footerProps} variant={getFooterVariant(variant, footerVariant)} hasBorderTop={isScrollable}/>
      </MantineModal.Content>
    </MantineModal.Root>);
};
