//region Default variables/enums
export enum ROSTER_TAB {
  DAILY = 'daily',
  WEEKLY = 'weekly',
}

export enum ROSTER_DISPLAY_MODE {
  CUSTOMERS = 'customers',
  TEAM_MEMBERS = 'team-members',
  ALL = 'all',
}
