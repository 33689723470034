import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Layers = forwardRef(function Layers(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.695 2.315a.75.75 0 0 1 .61 0l9 4a.75.75 0 0 1 0 1.37l-9 4a.75.75 0 0 1-.61 0l-9-4a.75.75 0 0 1 0-1.37l9-4ZM4.847 7 12 10.18 19.153 7 12 3.82 4.847 7Zm-2.532 4.695a.75.75 0 0 1 .99-.38L12 15.179l8.695-3.864a.75.75 0 0 1 .61 1.37l-9 4a.75.75 0 0 1-.61 0l-9-4a.75.75 0 0 1-.38-.99Zm.99 4.62a.75.75 0 0 0-.61 1.37l9 4a.75.75 0 0 0 .61 0l9-4a.75.75 0 0 0-.61-1.37L12 20.179l-8.695-3.864Z"
        fill="currentColor"
      />
    </Icon>
  );
});
