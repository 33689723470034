import React from 'react';
import { Table, Text, Avatar, rem } from '@good/ui/core';
import { StatusPill } from './status-pill';
import { ServiceType } from 'utilities/enum-utils';
import { useHistory } from 'react-router-dom';
import { ServiceItem } from 'utilities/react-trpc';
import { Home, Note2Text, User, Users } from '@good/icons';
import { FilterItem } from '@good/ui/templates';

const serviceTypes: FilterItem[] = [
  { icon: <Users />, label: 'Group service', key: ServiceType.GROUP },
  { icon: <User />, label: 'Support service', key: ServiceType.INDIVIDUAL },
  { icon: <Note2Text />, label: 'Support coordination', key: ServiceType.COORDINATION },
  { icon: <Home />, label: 'Supported independent living', key: ServiceType.INDEPENDENT_LIVING },
];

export const ServiceRow = ({ service }: { service: ServiceItem }) => {
  const history = useHistory();
  const handleItemClick = (serviceId: string, serviceType: ServiceType) => {
    if (serviceType === ServiceType.GROUP) return history.push(`/group-service/details/${serviceId}`);
    if (serviceType === ServiceType.INDEPENDENT_LIVING) return history.push(`/service/sil/${serviceId}/schedule`);

    return history.push(`/service/details/${serviceId}`);
  };
  const getDefaultServiceImage = (serviceType: ServiceType) => {
    switch (serviceType) {
      case ServiceType.GROUP:
        return '/assets/services/group-service.png';
      case ServiceType.INDIVIDUAL:
        return '/assets/services/support-service.png';
      case ServiceType.COORDINATION:
        return '/assets/services/support-coordination-service.png';
      case ServiceType.INDEPENDENT_LIVING:
        return '/assets/services/SIL.png';
      default:
        return '';
    }
  };

  const serviceDisplay = (serviceType: string, serviceName: string, serviceDescription: string, url?: string) => {
    return (
      <div className='inline-flex gap-2'>
        <div className=''>
          <Avatar src={url?.length ? url : getDefaultServiceImage(serviceType as ServiceType)} radius='md' size='lg' />
        </div>
        <div className='px-2 hover:text-accent'>
          <Text size='lg' fw={600}>
            {serviceName}
          </Text>
          <Text c='dimmed' size='sm' className='line-clamp-2'>
            {serviceDescription}
          </Text>
        </div>
      </div>
    );
  };

  const getServiceDetails = (type: ServiceType) => {
    const service = serviceTypes.find((service) => service.key === type);
    if (!service) return null;
    
    return (
      <div className='inline-flex gap-1'>
        <Text size='sm'>
          {service.icon} {service.label}
        </Text>
      </div>
    );
  };

  return (
    <Table.Tr
      key={service.serviceId}
      onClick={() => handleItemClick(service.serviceId, service.serviceType as ServiceType)}
    >
      <Table.Td maw={rem(500)} className='cursor-pointer'>
        {serviceDisplay(
          service.serviceType,
          service.serviceName,
          service.serviceDescription ?? '',
          service.serviceAttachmentUrl ?? '',
        )}
      </Table.Td>
      <Table.Td>
        <StatusPill status={service.status} />
      </Table.Td>
      <Table.Td>{getServiceDetails(service.serviceType as ServiceType)}</Table.Td>
      <Table.Td>{service.serviceDepartmentName}</Table.Td>
    </Table.Tr>
  );
};
