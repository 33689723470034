import React, { ForwardedRef, forwardRef } from 'react';

type CardProps = {
  children: React.ReactNode;
  width?: number | string;
  className?: string;
  style?: React.CSSProperties;
} & React.HTMLProps<HTMLDivElement>;

const Card = forwardRef(function Card(
  { children, width, className: classNameProp, style: styleProp, ...props }: CardProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const style: React.CSSProperties = {
    boxShadow: '0px 4px 8px -4px rgba(0, 0, 0, 0.08)',
    width,
    ...styleProp,
  };

  const className = ['border border-gray-light-2 rounded-lg bg-white flex flex-col', classNameProp].join(' ');

  return (
    <div ref={ref} className={className} style={style} {...props}>
      {children}
    </div>
  );
});

export default Card;
