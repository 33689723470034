import { Stack, Text } from '@goodhuman-me/components';
import { SubTitle } from 'common-components/typography';
import { Checkbox } from 'design-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendScheduleCopyOptions } from 'stores/hooks/query-hooks/use-mutation-extend-group-session-schedule';

type ExtendScheduleOptionsFormProps = {
  sessionOptions: ExtendScheduleCopyOptions;
  updateSessionOptions: (updates: Partial<ExtendScheduleCopyOptions>) => void;
};

const ExtendScheduleOptionsForm: React.FC<ExtendScheduleOptionsFormProps> = ({
  sessionOptions,
  updateSessionOptions,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'schedules.advanced' });

  return (
    <Stack gap="$space200">
      <Text size="small">{t('promptText')}</Text>
      <Stack
        gap="$space100"
        padding="$medium"
        borderRadius="$medium"
        borderWidth="$border100"
        borderColor="$neutralLight1"
      >
        <SubTitle>{t('formHeader')}</SubTitle>
        <Checkbox
          isSelected={sessionOptions.copyBookings}
          onChange={(isSelected) => updateSessionOptions({ copyBookings: isSelected })}
        >
          {t('bookingsLabel')}
        </Checkbox>
        <Checkbox
          isSelected={sessionOptions.copyActivityGroups}
          onChange={(isSelected) => updateSessionOptions({ copyActivityGroups: isSelected })}
        >
          {t('activityGroupsLabel')}
        </Checkbox>
        <Checkbox
          isSelected={sessionOptions.copyShifts}
          onChange={(isSelected) => updateSessionOptions({ copyShifts: isSelected })}
        >
          {t('shiftsLabel')}
        </Checkbox>
      </Stack>
    </Stack>
  );
};

export default ExtendScheduleOptionsForm;
