import React from 'react';
import { Button, Stack, Text, Table, Loader, Center } from '@good/ui/core';
import { PaperPlane } from '@good/illustrations';
import { RouterOutputs } from 'utilities/react-trpc';
import { useDisclosure } from '@good/ui/hooks';
import { format } from 'date-fns';
import { RevokeAccessTokenModal } from './revoke-access-token-modal';

type Props = {
  tokens?: RouterOutputs['account']['accessTokens']['list'];
  isLoading?: boolean;
  error?: string;
  portalUser: { serviceProviderId: string };
};

export const AccessTokensList = ({ tokens, isLoading, error }: Props) => {
  const header = (
    <Table.Thead>
      <Table.Tr>
        <Table.Th>Name</Table.Th>
        <Table.Th>Description</Table.Th>
        <Table.Th>Expires at</Table.Th>
        <Table.Th>Revoked at</Table.Th>
        <Table.Th>Actions</Table.Th>
      </Table.Tr>
    </Table.Thead>
  );

  return (
    <Table>
      {header}
      <Table.Tbody>
        {isLoading ? (
          <Table.Tr>
            <Table.Td colSpan={5}>
              <Center p='xl'>
                <Loader aria-label='Loading...' />
              </Center>
            </Table.Td>
          </Table.Tr>
        ) : error ? (
          <Table.Tr>
            <Table.Td colSpan={5}>
              <Stack align='center' gap={0}>
                <PaperPlane />
                <Text size='md' ta='center'>
                  {error}
                </Text>
              </Stack>
            </Table.Td>
          </Table.Tr>
        ) : tokens && tokens.length > 0 ? (
          tokens.map((accessToken) => (
            <AccessTokenListRow key={accessToken.personalAccessTokenId} accessToken={accessToken} />
          ))
        ) : (
          <Table.Tr>
            <Table.Td colSpan={5}>
              <Stack align='center' gap={0}>
                <PaperPlane />
                <Text size='md' ta='center'>
                  No personal access tokens
                </Text>
              </Stack>
            </Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </Table>
  );
};

type AccessTokenListRowProps = {
  accessToken: RouterOutputs['account']['accessTokens']['list'][0];
};

const AccessTokenListRow = ({ accessToken }: AccessTokenListRowProps) => {
  const [revokeModalOpened, { open: openRevokeModal, close: closeRevokeModal }] = useDisclosure(false);

  return (
    <Table.Tr key={accessToken.personalAccessTokenId}>
      <Table.Td>{accessToken.name}</Table.Td>
      <Table.Td>{accessToken.description}</Table.Td>
      <Table.Td>
        {accessToken.expiresOn ? format(accessToken.expiresOn, 'hh:mm:aa, dd/MM/yyyy') : 'Never expires'}
      </Table.Td>
      <Table.Td>
        {accessToken.revokedOn ? format(accessToken.revokedOn, 'hh:mm:aa, dd/MM/yyyy') : 'Not revoked'}
      </Table.Td>
      <Table.Td>
        <Button size='sm' variant='default' c='red' onClick={openRevokeModal} disabled={accessToken.revokedOn}>
          Revoke
        </Button>
        <RevokeAccessTokenModal opened={revokeModalOpened} close={closeRevokeModal} accessToken={accessToken} />
      </Table.Td>
    </Table.Tr>
  );
};
