import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ClockZzz = forwardRef(function ClockZzz(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.53 2.53a.75.75 0 0 0-1.06-1.06l-3 3a.75.75 0 0 0 1.06 1.06l3-3ZM3.75 12a8.25 8.25 0 1 1 16.5 0 8.25 8.25 0 0 1-16.5 0ZM12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm6.47-.78a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06-1.06l-3-3a.75.75 0 0 0-1.06 0ZM10 8.25a.75.75 0 1 0 0 1.5H12.6l-3.223 4.834A.75.75 0 0 0 10 15.75h4a.75.75 0 0 0 0-1.5h-2.599l3.223-4.834A.75.75 0 0 0 14 8.25h-4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
