import React from 'react';

// Payment Permissions
import { PermissionRowBase } from 'views/account-management/add-new-member/panels/permissions-panel/portal/PermissionRowBase';
import { IPermissionRole, IPermissions } from 'interfaces/account-interfaces';
import PaymentsSectionModal from 'views/account-management/components/PaymentsSectionModal';
import { getPaymentPermissionName, getPaymentRole } from 'views/account-management/utils/permission-utils';
import { UserPermissionRole } from 'utilities/enum-utils';

interface PaymentPermissionsRowProps {
  permissions: IPermissions;
  onChangePermissions: any;
  actionsDisabled: boolean;
  hasAllAccess: boolean;
}

interface PaymentPermissionsRowState {
  paymentModalOpen: boolean;
}

export class PaymentPermissionsRow extends React.Component<PaymentPermissionsRowProps, PaymentPermissionsRowState> {
  state = { paymentModalOpen: false };

  // Payments
  onOpenPayment = () => this.setState({ paymentModalOpen: true });
  onClosePayment = () => this.setState({ paymentModalOpen: false });

  // Triggered whenever a selection is made.
  onSelectPayment = (result) => {
    // This should be moved to Redux store ideally.
    const { permissions, onChangePermissions } = this.props;
    const { permissionRoles } = permissions;

    // filter out payment permissions first
    const filteredRoles = permissionRoles.filter(
      // (role) => ![UserPermissionRole.PaymentMember, UserPermissionRole.PaymentManager].includes(role.permissionRole)
      (role) =>
        role.permissionRole !== UserPermissionRole.PaymentMember &&
        role.permissionRole !== UserPermissionRole.PaymentManager,
    );

    // re-map back from permission name to role
    const targetRole = getPaymentRole(result.paymentPermission);

    // create the new role
    const newPaymentRole: IPermissionRole = {
      permissionRole: targetRole,
      serviceDepartmentId: null,
      serviceId: null,
    };

    // only update the permissions if it's a valid value (not null)
    const updatedPermissions =
      targetRole === null
        ? { ...permissions, permissionRoles: [...filteredRoles] }
        : { ...permissions, permissionRoles: [...filteredRoles, newPaymentRole] };

    // update in redux store.
    onChangePermissions(updatedPermissions);
  };

  render() {
    let { permissions } = this.props;

    const paymentPermissionName = getPaymentPermissionName(permissions, this.props.hasAllAccess);

    return (
      <>
        {/* Display row */}
        <PermissionRowBase
          iconName={'bank'}
          title={'Payments'}
          description={'Processing of customer payments'}
          currentFlag={paymentPermissionName}
          onClickModify={this.onOpenPayment}
          actionsDisabled={this.props.actionsDisabled}
        />

        {/* Modify dialog modal*/}

        <PaymentsSectionModal
          permissions={permissions}
          isOpen={this.state.paymentModalOpen}
          onClose={this.onClosePayment}
          setSelectedOption={this.onSelectPayment}
        />
      </>
    );
  }
}
