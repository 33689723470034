import { Checkbox, Icon } from 'antd';
import { ReactComponent as NonBillableIcon } from 'assets/Icons/billing-icons/non-billable_icon.svg';
import { HyperlinkButton } from 'common-components/buttons';
import { PaymentMethodTag } from 'common-components/tags';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IRootState, state } from 'src/stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import PermissionUtils from 'utilities/permission-utils';
import PaymentErrorIndicator from '../PaymentErrorIndicator';
import PaymentItemRow from './PaymentItemRow';
import moment from 'moment';

interface IPaymentCustomerRow {
  paymentItem?: any;
  history?: any;
  openActionModal?: any;
  onCheckCustomer?: (customerId) => void;
  onCheckItem?: (bookingBillingLineItemId) => void;
  canProcess: boolean;
  portalUser: typeof state.authStore.portalUser;
  isNonBillable: boolean;
  changeBillableLineItem?: (lineItem) => void;
}

class PaymentCustomerRow extends Component<IPaymentCustomerRow> {
  _checkbox = null;
  state = {
    userId: null,
    showSubLines: false,
    openAction: false,
  };

  _onCheckCustomer = (e, disabledCheckbox: boolean) => {
    e.stopPropagation();
    e.preventDefault();
    const { onCheckCustomer } = this.props;

    // cant select if error
    if (disabledCheckbox) return;

    onCheckCustomer(this.state.userId);
  };

  _onCheckItem = (bookingBillingLineItemId) => {
    const { onCheckItem } = this.props;

    onCheckItem(bookingBillingLineItemId);
  };

  _onClickOpen = async () => {
    this.setState({ showSubLines: true });
  };

  _onClickClose = async () => {
    this.setState({ showSubLines: false });
  };

  _onClickCustomer = (userId) => {
    const { history } = this.props;
    history.push(`/customer/details/${userId}`);
  };

  componentDidMount = () => {
    const { paymentItem } = this.props;

    if (!_.isEmpty(paymentItem)) {
      const { userId } = paymentItem;
      this.setState({ userId });
    }
  };

  componentDidUpdate(prevProps: Readonly<IPaymentCustomerRow>) {
    const { paymentItem } = this.props;
    if (prevProps.paymentItem !== paymentItem && paymentItem && paymentItem.userId) {
      this.setState({ userId: paymentItem.userId });
    }
  }

  render() {
    const { paymentItem, canProcess, portalUser, isNonBillable, changeBillableLineItem } = this.props;

    if (_.isEmpty(paymentItem)) {
      return <></>;
    }

    const {
      billingTotal,
      firstName,
      lastName,
      userId,
      numberOfItems,
      numberOfBooking,
      billingItems,
      paymentMethods,
      checkAllIndicator,
      indeterminateCheck,
      errorTypes,
      warningTypes,
      earliestStartDate,
      latestStartDate,
    } = paymentItem;

    const displayBorder = this.state.showSubLines ? 'bordered-none' : '';

    const numberOfErrors = errorTypes.length;

    const numberOfLinesWithErrors = _.countBy(paymentItem.billingItems, (line) => {
      return line.isError || line.isNoNDISNumberError;
    });

    const disabledCheckbox = numberOfLinesWithErrors.true >= numberOfItems;

    const earliestStartDateText = moment(earliestStartDate).format('D/M/YYYY');
    const latestStartDateText = moment(latestStartDate).format('D/M/YYYY');

    const dateRangeText = `
          ${earliestStartDateText}
          ${earliestStartDateText !== latestStartDateText ? ' - ' + latestStartDateText : ''}
          `.trim();

    const numberOfItemsText = `
      ${numberOfItems} item${numberOfItems > 1 ? 's' : ''}
      (${numberOfBooking} booking${numberOfBooking > 1 ? 's' : ''})
      `.trim();

    return (
      <>
        <tr
          onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}
          className={`${!checkAllIndicator && 'hover-bg-tertiary'} anim-fade-in ${
            checkAllIndicator ? 'bg-blue-lightest' : 'bg-white'
          }`}
        >
          <td
            className={`${displayBorder} fluid-cell check-all `}
            onClick={(e) => this._onCheckCustomer(e, disabledCheckbox)}
            onMouseEnter={() => this._checkbox.focus()}
            onMouseLeave={() => this._checkbox.blur()}
          >
            {canProcess && (
              <Checkbox
                checked={checkAllIndicator}
                disabled={disabledCheckbox}
                indeterminate={indeterminateCheck}
                ref={(c) => (this._checkbox = c)}
              />
            )}
          </td>
          <td className={`${displayBorder} ellipsis width-1/4`}>
            <div className="pr-small">
              <Text style={{ whiteSpace: 'nowrap' }} weight="bold" size="regular">
                {PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles) ? (
                  <HyperlinkButton onClick={() => this._onClickCustomer(userId)} color="black">
                    {firstName} {lastName}
                  </HyperlinkButton>
                ) : (
                  firstName + ' ' + lastName
                )}
              </Text>
            </div>
          </td>
          <td className={`${displayBorder} ellipsis align-start`} colSpan={2}>
            <div className="align-center text-size-large flex-row">
              <div className="mr-small">{numberOfItemsText}</div>
              {isNonBillable ? (
                <Icon component={NonBillableIcon} style={{ color: 'transparent', fontSize: 24 }} className="ml-small" />
              ) : (
                paymentMethods.map((paymentMethod) => (
                  <PaymentMethodTag
                    key={paymentMethod}
                    paymentMethod={paymentMethod}
                    className="ml-x-small"
                    rounded={true}
                    size="small"
                  />
                ))
              )}
            </div>
            <div className="text-color-secondary mt-x-small text-size-regular">{dateRangeText}</div>
          </td>
          <td className={`${displayBorder} ellipsis`}>
            <div className="text-align-right">
              <PaymentErrorIndicator errorTypes={errorTypes} warningTypes={warningTypes} billingItems={billingItems} />
              <Text style={{ whiteSpace: 'nowrap' }} weight="bold" size="regular">
                {CommonUtils.formatPrice(billingTotal)}
              </Text>
            </div>
          </td>
          <td
            className={`${displayBorder} fluid-cell `}
            onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}
          >
            <div className="pr-small">
              {this.state.showSubLines ? (
                <Icon className="size-x-large" type="up" />
              ) : (
                <Icon className="size-x-large" type="down" />
              )}
            </div>
          </td>
        </tr>
        <>
          {this.state.showSubLines && (
            <>
              <>
                <>
                  {_.map(billingItems, (billingItem, key) => (
                    <PaymentItemRow
                      lineItem={billingItem}
                      onCheckItem={this._onCheckItem}
                      history={this.props.history}
                      key={billingItem.bookingBillingLineItemId}
                      openActionModal={this.props.openActionModal}
                      customerId={userId}
                      errorTypes={errorTypes}
                      warningTypes={warningTypes}
                      isLast={billingItems.length === key + 1}
                      canProcess={canProcess}
                      isNonBillable={isNonBillable}
                      changeBillableLineItem={changeBillableLineItem}
                    />
                  ))}
                </>
              </>
            </>
          )}
        </>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

export default connect(mapState, null)(PaymentCustomerRow);
