import React, { ForwardedRef, forwardRef } from 'react';
import * as styles from './card.css';

export type CardProps = {
  children: React.ReactNode;
  width?: number | string;
  className?: string;
  style?: React.CSSProperties;
  attributes?: React.HTMLAttributes<unknown>;
  displayBoxShadow?: boolean;
} & React.HTMLProps<HTMLDivElement>;

const Card = forwardRef(
  (
    { children, className: classNameProp, attributes: attrProps, displayBoxShadow = false }: CardProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { card } = styles;

    const boxShadowVariant = displayBoxShadow ? 'xs' : 'none';

    return (
      <div ref={ref} className={card({ class: classNameProp ?? '', boxShadow: boxShadowVariant })} {...attrProps}>
        {children}
      </div>
    );
  },
);

Card.displayName = 'Card';

export { Card };
