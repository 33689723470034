import { Box, Inline } from '@goodhuman-me/components';
import { Button, Divider, Dropdown, Link, Text } from 'design-components';
import React, { useState } from 'react';
import { Member, OptionType, PermissionRole } from '../../utils/form-utils';
import DropdownOverview from './DropdownOverview';
import InviteToApp from './InviteToApp';
import PermissionCheckBoxList from './PermissionCheckBoxList';

interface IProps {
  teamMemberList?: Member[];
  removeSelected?: () => void;
  updateTeamMemberPermissions?: (roles: string[], permissions: PermissionRole[]) => void;
  deleteMember?: (indexes: number | number[]) => void;
  updateStatusInviteToApp?: (value: boolean) => void;
}

const SelectedCustomerSection: React.FC<IProps> = ({
  removeSelected,
  deleteMember,
  updateTeamMemberPermissions,
  teamMemberList,
  updateStatusInviteToApp,
}) => {
  const [overlay, setOverlay] = useState<OptionType>('OVERVIEW');
  const [visibleDropdown, setVisibleDropdown] = useState<boolean>(false);

  const teamMembersSelected = teamMemberList.filter((member) => member.isChecked).length;

  if (!teamMembersSelected) {
    return null;
  }

  const onClose = () => {
    setVisibleDropdown(false);
    setOverlay('OVERVIEW');
  };

  const onRemoveMember = () => {
    const indexes = [];
    teamMemberList.forEach((member, index) => {
      if (member.isChecked) {
        indexes.push(index);
      }
    });
    deleteMember(indexes);
    onClose();
  };

  const renderOverlay = () => {
    switch (overlay) {
      case 'OVERVIEW': {
        return (
          <DropdownOverview
            onRemoveMember={onRemoveMember}
            teamMembersSelected={teamMembersSelected}
            setViewDropdown={setOverlay}
          />
        );
      }
      case 'PERMISSION': {
        return <PermissionCheckBoxList onCancel={onClose} updateTeamMemberPermissions={updateTeamMemberPermissions} />;
      }
      case 'INVITE_TO_APP': {
        return (
          <InviteToApp
            onCancel={onClose}
            teamMemberList={teamMemberList}
            updateStatusInviteToApp={updateStatusInviteToApp}
          />
        );
      }
    }
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box padding="$space200" borderRadius="$medium" backgroundColor="$oceanLight2">
        <Inline alignItems="center">
          <Box asChild>
            <Text emphasis="semibold">
              {teamMembersSelected} team member{teamMembersSelected === 1 ? '' : 's'} selected
            </Text>
          </Box>
          <Divider type="vertical" marginX="$space150" />
          <Link kind="accent" textDecoration="none !important" onPress={removeSelected} marginRight="$space300">
            <Text emphasis="regular">Clear selection</Text>
          </Link>
          <Dropdown
            visible={visibleDropdown}
            onVisibleChange={(status) => {
              setVisibleDropdown(status);
            }}
            onClose={() => setOverlay('OVERVIEW')}
            onBack={() => setOverlay('OVERVIEW')}
            isShowBackButton={overlay !== 'OVERVIEW'}
            isShowCloseButton={false}
            label={
              overlay === 'OVERVIEW'
                ? 'Edit options'
                : overlay === 'PERMISSION'
                ? 'Workspace permission'
                : 'Invite to app'
            }
            overlay={renderOverlay()}
            placement="bottom right"
          >
            <Button kind="accent" emphasis="filled" color="$white" size="medium">
              Edit
            </Button>
          </Dropdown>
        </Inline>
      </Box>
    </Box>
  );
};

export default SelectedCustomerSection;
