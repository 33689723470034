import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Heading, Label, Card } from '@good/components';
import { SubscriptionBillingSection } from './subscription-billing-section';
import { SubscriptionBillingConfigModal } from './subscription-billing-config-modal';
import { reactTrpc } from 'utilities/react-trpc';
import ndisHelper from '../../../variables/data/ndis-helper'
import { RecurringBookingPattern } from '@utils/enum-utils';
import { SubscriptionBillingDetails } from 'interfaces/sil-service-agreement-interfaces';
import { AlertCircle } from '@good/icons';
import { Text } from '@good/ui/core';


type FundingDetailsCardProps = {
  userServiceAgreementServiceId: string;
  customerId: string;
  serviceProviderId: string;
};

const ErrorNotification = () => {
  const { t } = useTranslation('', { keyPrefix: 'serviceAgreements.silServiceAgreement.fundingDetails.subscriptionDetails.errors.failedToFetchSubscription' });

  return (
    <Card className="flex flex-row items-center">
      <div className="flex w-full flex-col space-y-2 pl-8 pt-2 pb-2" data-testid='subscription-request-error-notification'>
        <Heading><AlertCircle color='red' /> {t('header')}</Heading>
        <Text size="md">
          {t('body')}
        </Text>
      </div>
    </Card>
  )
};

const FundingSource = () => {
  const { t } = useTranslation('', { keyPrefix: 'serviceAgreements.silServiceAgreement.fundingDetails.fundingSource' });

  return (
    <div className="flex flex-row pt-4">
      <Header size="sm" className="w-1/4">
        <Heading>{t('heading')}</Heading>
      </Header>
      <Label size="md" tone="lilac" emphasis="outline">
        {t('labels.ndis')}
      </Label>
    </div>
  );
};

const formatSubscriptionFrequencyText = (frequency: RecurringBookingPattern): SubscriptionBillingDetails['frequency'] => {
  const splitEnumKey = RecurringBookingPattern[frequency].toString().split(/(?=[A-Z])/) as [string, ...string[]];

  if(splitEnumKey.length < 2) {
    return splitEnumKey[0] as 'Every week';
  }

  const frequencyDescriptors =  splitEnumKey.slice(1, splitEnumKey.length)
          .map((text) => text.toLowerCase())
          .join(" ")

  return `${splitEnumKey[0]} ${frequencyDescriptors}` as SubscriptionBillingDetails['frequency']
}

export const FundingDetailsCard = ({ customerId, userServiceAgreementServiceId, serviceProviderId }: FundingDetailsCardProps) => {
  const { t } = useTranslation('', { keyPrefix: 'serviceAgreements.silServiceAgreement.fundingDetails' });
  const { data, isLoading, isError, refetch } = reactTrpc.billingSubscriptions.fetch.useQuery({
    userServiceAgreementServiceId,
    serviceProviderId,
  }, {
    keepPreviousData: false,
  });
  const subscriptionBillingDetails = data?.subscription
    ?
  {
    ...data.subscription,
    customerId,
    userServiceAgreementServiceId,
    frequency: formatSubscriptionFrequencyText(data.subscription.frequency),
    startDate: data.subscription.startDate,
    endDate: data.subscription.endDate,
    lineItems: data.subscription.lineItems ?? [],
  }
   :
  undefined;
  const onCreate = () => {
    setSubscriptionModalToggle(true);
  };
  const [subscriptionModalToggle, setSubscriptionModalToggle] = useState(false)
  const subscriptionBillingModalProps = {
    isOpen: subscriptionModalToggle,
    toggleModal: setSubscriptionModalToggle,
    billingSubscriptionId: data?.subscription.billingSubscriptionId,
    existingLineItems: data?.subscription.lineItems?.map(lineItem => ({
      ...lineItem,
      supportItemName: ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber).SupportItem,
    })),
    serviceProviderId,
    customerId,
    userServiceAgreementServiceId,
    isLoading,
    refetch,
  }

  if(isError) return <ErrorNotification />;

  return (
    <Card className="flex flex-row items-center">
      <div className="flex w-full flex-col space-y-8 pl-12 pt-8">
        <Header size="lg">
          <Heading>{t('heading')}</Heading>
        </Header>
        <FundingSource />
        <SubscriptionBillingConfigModal {...subscriptionBillingModalProps}/>
        <SubscriptionBillingSection
          subscriptionBillingDetails={subscriptionBillingDetails}
          onCreate={onCreate}
        />
      </div>
    </Card>
  );
};
