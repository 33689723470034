import React from 'react';
import { PAYMENT_STATUS_DISPLAY } from '../utils/constants';
import { Text } from 'common-components/typography';
import { Icon } from 'antd';

const BillingLineItemStatus = ({ paymentStatus }: { paymentStatus: string }) => {
  return (
    <div>
      <Text weight="bold" size="x-large" color={PAYMENT_STATUS_DISPLAY[paymentStatus]?.iconColor} className="mr-medium">
        <Icon
          type={PAYMENT_STATUS_DISPLAY[paymentStatus]?.icon}
          theme={PAYMENT_STATUS_DISPLAY[paymentStatus]?.iconTheme}
          className="mr-x-small text-size-x2-large"
        />
        {PAYMENT_STATUS_DISPLAY[paymentStatus]?.statusText}
      </Text>
    </div>
  );
};

export default BillingLineItemStatus;
