import React from 'react';
import { Text, Modal } from '@good/ui/core';
import { useTranslation } from 'react-i18next';

type IRemoveTeamMemberModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
  name: string;
};

export const RemoveTeamMemberModal = ({
  isOpen,
  onClose,
  onRemove,
  name,
}: IRemoveTeamMemberModalProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceTeamTabs.removeModal' });

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      size={600}
      centered
      maxHeight={600}
      title={t('heading', { name })}
      confirmProps={{
        label: t('remove'),
        onClick: onRemove,
        'aria-label': 'Remove',
        variant: 'filled',
        color: 'red',
        // @ts-expect-error -- TODO: fix this
        'data-testid': 'remove-button',
      }}
      cancelProps={{
        label: t('cancel'),
        onClick: onClose,
        'aria-label': 'Cancel',
        // @ts-expect-error -- TODO: fix this
        'data-testid': 'cancel-button',
      }}
    >
      <Text c='dimmed'>{t('description')}</Text>
    </Modal>
  );
};
