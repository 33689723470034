import React, { forwardRef, PropsWithChildren, useRef } from 'react';
import { useCheckbox, AriaCheckboxProps } from '@react-aria/checkbox';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { compose, css, BaseStyleProps, HTMLProps, useStyleProps, CSS, useContextProps } from '..';
import { mergeProps } from '@react-aria/utils';
import { useFocusRing } from '@react-aria/focus';
import { useToggleState } from '@react-stately/toggle';
import { IconChecked, IconProps } from '../icon';
import { CheckboxContext, CheckboxIconProvider, CheckboxProviderProps } from './checkbox-provider';

import * as styles from './checkbox.css';
import { usePress } from '@react-aria/interactions';

import { WrapperVariants, CheckboxVariants, LabelTextVariants, SupportTextVariants } from './checkbox.css';

export type CheckboxProps = PropsWithChildren<
  BaseStyleProps &
    Omit<HTMLProps<HTMLInputElement>, 'onChange'> &
    AriaCheckboxProps &
    Omit<IconProps, 'onChange'> &
    WrapperVariants &
    CheckboxVariants &
    LabelTextVariants &
    SupportTextVariants
> & {
  /**
   * Text support
   */
  support?: string;
  /**
   * disabled status
   */
  isDisabled?: boolean;
  /**
   * indeterminate status
   */
  isIndeterminate?: boolean;
  /**
   * Wrapper styles
   */
  wrapperStyles?: CSS;
  /**
   * Text styles
   */
  textStyles?: CSS;
};

/**
 * @name
 * Checkbox
 *
 * @description
 * The Checkbox HTML element is an interactive element activated by
 * a user.
 *
 * @example
 * <Checkbox
 *   size="medium"
 *   emphasis="bold"
 *   label="Label"
 *   support="Support"
 *   defaultSelected={false}
 *   isSelected={value}
 *   onChange={(value) => console.log(value)}
 * />
 */

// eslint-disable-next-line react/display-name
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const contextProps = useContextProps<CheckboxProps & CheckboxProviderProps>(CheckboxContext, props);
  const {
    support,
    size = 'small',
    emphasis = 'regular',
    isDisabled,
    isIndeterminate,
    wrapperStyles,
    textStyles,
  } = contextProps;
  ref = useRef(null);
  const state = useToggleState(props);
  let { inputProps } = useCheckbox(props, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing(props);
  const { pressProps, isPressed } = usePress({});
  inputProps = mergeProps(inputProps, focusProps);
  const isChecked = isIndeterminate ? false : inputProps.checked;
  const { styleProps } = useStyleProps(props);

  return (
    <label {...pressProps} className={compose(css(styles.reset, wrapperStyles))}>
      <VisuallyHidden>
        <input {...inputProps} ref={ref} />
      </VisuallyHidden>
      <div
        className={compose(css(styles.wrapper), styles.wrapperVariants({ size, isFocused: isFocusVisible, isPressed }))}
      >
        {/*  VIRTUAL CHECKBOX */}
        <div
          className={compose(
            css(styles.checkbox),
            styles.checkboxVariants({
              size,
              isDisabled,
              isPressed,
              isChecked,
              isFocused: isFocusVisible,
              isIndeterminate,
            }),
            css(styleProps),
          )}
        >
          {/* icon checked */}
          {isChecked && (
            <CheckboxIconProvider size={size}>
              <IconChecked color={isDisabled ? '$bodyDark1' : '$white'} />
            </CheckboxIconProvider>
          )}
          {/* icon indeterminate */}
          {isIndeterminate && (
            <span
              className={compose(
                css(styles.iconIndeterminate),
                styles.iconIndeterminateVariants({ size, isDisabled, isIndeterminate }),
              )}
            ></span>
          )}
        </div>
      </div>
      {/* TEXT GROUP */}
      <span className={css(styles.textGroup, textStyles)()}>
        <span className={compose(css(styles.labelText), styles.labelTextVariants({ size, emphasis, isDisabled }))}>
          {props.children}
        </span>
        {support && (
          <span className={compose(css(styles.supportText), styles.supportTextVariants({ size }))}>{support}</span>
        )}
      </span>
    </label>
  );
});
