import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const LetterSpacing = forwardRef(function LetterSpacing(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3.25a.75.75 0 0 1 .75.75v16a.75.75 0 0 1-1.5 0V4A.75.75 0 0 1 2 3.25Zm20 0a.75.75 0 0 1 .75.75v16a.75.75 0 0 1-1.5 0V4a.75.75 0 0 1 .75-.75Zm-9.33 4.415a.75.75 0 0 0-1.34 0l-2.5 5-1.5 3a.75.75 0 1 0 1.34.67l1.294-2.585h4.072l1.293 2.585a.75.75 0 1 0 1.342-.67l-1.5-3-2.5-5ZM12 9.677l1.287 2.573h-2.574L12 9.677Z"
        fill="currentColor"
      />
    </Icon>
  );
});
