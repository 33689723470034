import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Avatar, Checkbox, Form, Icon, notification, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import _ from 'lodash';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Information } from 'common-components/alerts';

type IAssignNewPrimaryOwnerProps = FormComponentProps & {
  isOpen: boolean;
  onClose: () => void;
  goToLandingPage: () => void;
  doAssignNewPrimaryOwner: typeof dispatch.companyStore.doAssignNewPrimaryOwner;
  doFetchOwnerList: typeof dispatch.companyStore.doFetchOwnerList;
  ownerList: typeof state.companyStore.ownerList;
  currentPrimaryOwner: object;
};

type IAssignNewPrimaryOwnerState = {
  title: string;
  isCreated: boolean;
  selectedOwner: object;
  isLoading: boolean;
};

class AssignNewPrimaryOwnerModal extends Component<IAssignNewPrimaryOwnerProps, IAssignNewPrimaryOwnerState> {
  state = { title: 'Change Primary Owner', isCreated: false, selectedOwner: null, isLoading: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Change Primary Owner', isCreated: false, selectedOwner: null });
    if (this.state.isCreated) {
      this.props.goToLandingPage();
    } else {
      this.props.onClose();
    }
  };

  private _validateAcknowledge = (rule, value, callback) => {
    try {
      if (!value) {
        throw Error('In order to continue you must acknowledge this statement.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _changeSelectedPrimaryOwner = (event) => {
    this.setState({ selectedOwner: _.find(this.props.ownerList, (owner) => owner.supportWorkerId === event) });
  };

  private _assignNewPrimaryOwner = async () => {
    const { form } = this.props;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      this.setState({ isLoading: true });
      try {
        await this.props.doAssignNewPrimaryOwner({ supportWorkerId: this.state.selectedOwner.supportWorkerId });
        this.setState({
          title: (
            <>
              Change Primary Owner successful
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isCreated: true,
          isLoading: false,
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  componentDidMount() {
    this.props.doFetchOwnerList({});
  }

  render() {
    const { isOpen, currentPrimaryOwner, form } = this.props;
    const { getFieldDecorator } = form;
    const { selectedOwner } = this.state;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isCreated && selectedOwner ? (
          <>
            <div className="mv-medium anim-slide-left">
              <SubTitle>New primary owner</SubTitle>
              <div className={'align-center mt-small flex-row'}>
                <Avatar size="large" shape={'square'} src={selectedOwner.attachmentUrl} className={'mr-medium'} />
                <div>
                  <Text>
                    {selectedOwner.firstName} {selectedOwner.lastName}
                  </Text>
                </div>
              </div>

              <div className={'mt-large'}>
                <SubTitle>New owner (Your permissions)</SubTitle>
                <div className={'align-center mt-small flex-row'}>
                  <Avatar
                    size="large"
                    shape={'square'}
                    src={currentPrimaryOwner.attachmentUrl}
                    className={'mr-medium'}
                  />
                  <div>
                    <Text>
                      {currentPrimaryOwner.firstName} {currentPrimaryOwner.lastName}
                    </Text>
                  </div>
                </div>
                <div className={'mt-medium'}>
                  <Text>Your permissions have now changed.</Text>
                  <br />
                  <Text>
                    You have been assigned as a standard <b>Owner</b>.
                  </Text>
                </div>
              </div>
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Done
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <Paragraph>
                  The Primary Owner is ultimately responsible for this GoodHuman account and can also perform
                  destructive actions, such as deleting the account.
                </Paragraph>
                <Information
                  content={
                    <div>
                      <b>There can only be one Primary Owner per account</b>
                      <br />
                      You can choose to transfer the Primary Owner role to another Owner; doing so will make you a
                      standard Owner.
                    </div>
                  }
                />
                {currentPrimaryOwner && (
                  <div className={'mt-large'}>
                    <SubTitle>current primary owner</SubTitle>
                    <div className={'align-center mt-small flex-row'}>
                      <Avatar
                        size="large"
                        shape={'square'}
                        src={currentPrimaryOwner.attachmentUrl}
                        className={'mr-medium'}
                      />
                      <div>
                        <Text>
                          {currentPrimaryOwner.firstName} {currentPrimaryOwner.lastName}
                        </Text>
                      </div>
                    </div>
                  </div>
                )}
                <div className={'mt-large'}>
                  <SubTitle>New primary owner</SubTitle>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('newPrimaryOwner', {
                      rules: [{ required: true, message: 'Please select an Owner.' }],
                    })(
                      <Select
                        className={'x-large-select'}
                        style={{ width: '500px' }}
                        onChange={this._changeSelectedPrimaryOwner}
                      >
                        {_.map(this.props.ownerList, (owner, key) => (
                          <Select.Option key={key} value={owner.supportWorkerId}>
                            <div className={'align-center pv-x-small flex-row'}>
                              <Avatar size="large" shape={'square'} src={owner.attachmentUrl} className={'mr-medium'} />
                              <div>
                                <Text>
                                  {owner.firstName} {owner.lastName}
                                </Text>
                              </div>
                            </div>
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </div>
                <Form.Item className="width-full">
                  {getFieldDecorator('acknowledgment', { rules: [{ validator: this._validateAcknowledge }] })(
                    <Checkbox>I understand that this action is permanent and cannot be undone</Checkbox>,
                  )}
                </Form.Item>
              </div>

              <ActionModalFooter>
                <SecondaryButton
                  size="large"
                  disabled={this.state.isLoading}
                  className="mr-medium"
                  onClick={this._onCloseModal}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" loading={this.state.isLoading} onClick={this._assignNewPrimaryOwner}>
                  Change Primary Owner
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  ownerList: state.companyStore.ownerList,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchOwnerList: dispatch.companyStore.doFetchOwnerList,
  doAssignNewPrimaryOwner: dispatch.companyStore.doAssignNewPrimaryOwner,
});

export default connect(mapState, mapDispatch)(Form.create<IAssignNewPrimaryOwnerProps>()(AssignNewPrimaryOwnerModal));
