import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const LightningSlash = forwardRef(function LightningSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.217 1.282A.75.75 0 0 1 14.75 2v7.25H19a.75.75 0 0 1 .624 1.166l-2 3a.75.75 0 1 1-1.248-.832l1.223-1.834H14a.75.75 0 0 1-.75-.75V4.477l-1.96 2.939a.75.75 0 0 1-1.247-.832l3.333-5a.75.75 0 0 1 .841-.302ZM2.47 2.47a.75.75 0 0 1 1.06 0l6.2 6.2 6.2 6.2 5.6 5.6a.75.75 0 1 1-1.06 1.06l-4.953-4.953-3.893 5.839A.75.75 0 0 1 10.25 22v-7.25H6a.75.75 0 0 1-.624-1.166l2.859-4.288L2.47 3.53a.75.75 0 0 1 0-1.06Zm6.847 7.907L7.4 13.25h3.6a.75.75 0 0 1 .75.75v5.523l2.684-4.027-5.118-5.119Z"
        fill="currentColor"
      />
    </Icon>
  );
});
