import { notification, Row } from 'antd';
import { PrimaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { IWorkflow, IWorkflowComment } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import ListingEmptyState from '../../ListingEmptyState';
import LoadingProcess from '../../LoadingProcess';
import AddEditCommentModal from './components/AddEditCommentModal';
import ConfirmRemoveCommentModal from './components/ConfirmRemoveCommentModal';
import WorkflowCommentRowItem from './components/WorkflowCommentRowItem';

interface IWorkflowCommentsPanelProps {
  selectedWorkflow: IWorkflow;
  isDisableAction: boolean;
  workflowCommentList: typeof state.workflowStore.workflowCommentList;
  doGetWorkflowComments: typeof dispatch.workflowStore.doGetWorkflowComments;
  doCreateNewWorkflowComment: typeof dispatch.workflowStore.doCreateNewWorkflowComment;
  doRemoveWorkflowComment: typeof dispatch.workflowStore.doRemoveWorkflowComment;
  doUpdateWorkflowComment: typeof dispatch.workflowStore.doUpdateWorkflowComment;
}

interface IWorkflowCommentsPanelState {
  isLoading: boolean;
  isUpdating: boolean;
  isOpenAddEditCommentModal: boolean;
  isOpenConfirmRemoveCommentModal: boolean;
  commentDetail: IWorkflowComment;
}

class WorkflowCommentsPanel extends PureComponent<IWorkflowCommentsPanelProps, IWorkflowCommentsPanelState> {
  state = {
    isLoading: false,
    isUpdating: false,
    isOpenAddEditCommentModal: false,
    isOpenConfirmRemoveCommentModal: false,
    commentDetail: null,
  };

  private _onOpenAddEditModal = (values: IWorkflowComment) => {
    this.setState({ isOpenAddEditCommentModal: true, commentDetail: values });
  };

  private _onOpenAddModal = () => this.setState({ isOpenAddEditCommentModal: true, commentDetail: null });

  private _onOpenDeleteModal = (values: IWorkflowComment) => {
    this.setState({ isOpenConfirmRemoveCommentModal: true, commentDetail: values });
  };

  private _onCloseAddEditCommentModal = () => this.setState({ isOpenAddEditCommentModal: false, commentDetail: null });

  private _onSaveAddEditCommentModal = async (values: IWorkflowComment) => {
    const { commentDetail } = this.state;
    const { doUpdateWorkflowComment, doCreateNewWorkflowComment, selectedWorkflow } = this.props;
    this.setState({ isUpdating: true });
    const payload: IWorkflowComment = { ...values, workflowId: selectedWorkflow.workflowId };

    const response: any = commentDetail
      ? await doUpdateWorkflowComment(payload)
      : await doCreateNewWorkflowComment(payload);

    if (response && response.workflowCommentId) {
      notification.success({
        message: `Comment ${commentDetail ? 'edited' : 'added'}`,
        description: `You have successfully ${
          commentDetail ? 'edited a comment.' : 'added a comment to this workflow.'
        }`,
      });
      this.setState({ isOpenAddEditCommentModal: false, commentDetail: null });
    } else {
      notification.error({ message: response.meta.message });
    }

    this.setState({ isUpdating: false });
  };

  private _onGetComments = async () => {
    const { selectedWorkflow, doGetWorkflowComments } = this.props;
    this.setState({ isLoading: true });

    await doGetWorkflowComments(selectedWorkflow.workflowId);

    this.setState({ isLoading: false });
  };

  private _onConfirmRemoveComment = async () => {
    const { commentDetail } = this.state;
    const { doRemoveWorkflowComment } = this.props;
    this.setState({ isUpdating: true });

    const response: any = await doRemoveWorkflowComment(commentDetail);

    if (response.isDeleted) {
      notification.success({
        message: 'Comment deleted',
        description: 'You have successfully deleted a comment.',
      });
      this.setState({ isOpenConfirmRemoveCommentModal: false });
    } else {
      notification.error({ message: response.meta.message });
    }
    this.setState({ isUpdating: false });
  };

  private _onCloseConfirmSaveChangeModal = () => this.setState({ isOpenConfirmRemoveCommentModal: false });

  componentDidMount = () => {
    this._onGetComments();
  };

  render() {
    const { workflowCommentList, isDisableAction } = this.props;
    const { isOpenAddEditCommentModal, isOpenConfirmRemoveCommentModal, isLoading, isUpdating, commentDetail } =
      this.state;

    return (
      <div className="bg-white p-x-large">
        <Row type="flex" align="middle" justify="space-between">
          <div className="flex-column flex-1">
            <Text size="x3-large" weight="bolder">
              Comments
            </Text>
            <Text color="secondary">View and manage all team member comments related to this workflow.</Text>
          </div>

          {!isDisableAction && (
            <PrimaryButton size="large" icon={'plus'} onClick={this._onOpenAddModal}>
              Add comment
            </PrimaryButton>
          )}
        </Row>

        <div className="bg-quaternary flex-column mv-x-large">
          {isLoading && !commentDetail ? (
            <LoadingProcess />
          ) : !_.isEmpty(workflowCommentList) ? (
            workflowCommentList.map((comment, index) => (
              <WorkflowCommentRowItem
                commentDetail={comment}
                key={index}
                onEditComment={() => this._onOpenAddEditModal(comment)}
                onDeleteComment={() => this._onOpenDeleteModal(comment)}
                isDisableAction={isDisableAction}
              />
            ))
          ) : (
            <ListingEmptyState title="No comment found." description="All comments will appear here." />
          )}
        </div>

        <AddEditCommentModal
          isOpen={isOpenAddEditCommentModal}
          commentDetail={commentDetail}
          isUpdating={isUpdating}
          onClose={this._onCloseAddEditCommentModal}
          onUpdateComment={this._onSaveAddEditCommentModal}
        />

        <ConfirmRemoveCommentModal
          isOpen={isOpenConfirmRemoveCommentModal}
          isUpdating={isUpdating}
          onClose={this._onCloseConfirmSaveChangeModal}
          onConfirm={this._onConfirmRemoveComment}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  workflowCommentList: state.workflowStore.workflowCommentList,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetWorkflowComments: dispatch.workflowStore.doGetWorkflowComments,
  doCreateNewWorkflowComment: dispatch.workflowStore.doCreateNewWorkflowComment,
  doRemoveWorkflowComment: dispatch.workflowStore.doRemoveWorkflowComment,
  doUpdateWorkflowComment: dispatch.workflowStore.doUpdateWorkflowComment,
});

export default connect(mapState, mapDispatch)(WorkflowCommentsPanel);
