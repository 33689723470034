import React from 'react';

export function Users(): JSX.Element {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 7a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0ZM8 2.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5Zm6 0a.75.75 0 0 0 0 1.5 3.25 3.25 0 0 1 0 6.5.75.75 0 0 0 0 1.5 4.75 4.75 0 1 0 0-9.5Zm-8 13.5A3.25 3.25 0 0 0 2.75 19c0 .69.56 1.25 1.25 1.25h8c.69 0 1.25-.56 1.25-1.25A3.25 3.25 0 0 0 10 15.75H6ZM1.25 19A4.75 4.75 0 0 1 6 14.25h4A4.75 4.75 0 0 1 14.75 19 2.75 2.75 0 0 1 12 21.75H4A2.75 2.75 0 0 1 1.25 19ZM16 14.25a.75.75 0 0 0 0 1.5h2A3.25 3.25 0 0 1 21.25 19c0 .69-.56 1.25-1.25 1.25h-4a.75.75 0 0 0 0 1.5h4A2.75 2.75 0 0 0 22.75 19 4.75 4.75 0 0 0 18 14.25h-2Z"
        fill="#266c59"
      />
    </svg>
  );
}
