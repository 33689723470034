import React from 'react';
import { Helmet } from 'react-helmet';
import { APP_CONFIG } from '../config/app-config';

const { PRODUCTION_ENV: env, MODE, NAME, VERSION } = APP_CONFIG.meta;
const newRelicEnvConfigMapping = {
  development: 'development',
  staging: 'staging',
  preprod: 'production',
  production: 'production',
};

export function NewRelic() {
  const configEnvironment = MODE === 'development' ? 'development' : env;
  const configFile = newRelicEnvConfigMapping[configEnvironment] || 'test';
  const configSrc = `/config/new-relic/${configFile}.js`;

  return (
    <Helmet>
      <script type="text/javascript" id="new-relic" src={configSrc} />
      <script type="text/javascript">
        {`var waitForGlobal = function (key, callback) {
          if (window[key]) {
            callback();
          } else {
            setTimeout(function () {
              waitForGlobal(key, callback);
            }, 100);
          }
        };

        waitForGlobal('newrelic', function () {
          newrelic.addRelease('${NAME || 'workspace'}', '${VERSION || 'v0.0.0'}');
        });`}
      </script>
    </Helmet>
  );
}
