/* eslint-disable eslint-comments/disable-enable-pair  -- will be refactored by another card*/

import React, { ReactElement } from 'react';
import { Avatar } from 'antd';
import { Text } from '@good/components';
import { GhostButton } from 'common-components/buttons';
import { Popover2 } from '@blueprintjs/popover2';
import { UserPermissionRole } from 'utilities/enum-utils';
import { TmMgmtTeamTableActionMenu } from '../team-management-team-tab/team-management-team-table/tm-mgmt-team-table-action-menu';
import { PortalUser } from 'stores/rematch/models/auth-store';

type TmMgmtUninvitedTableColumn = {
  title: string | ReactElement;
  dataIndex: string;
  key: string;
  width: number;
  marginLeft?: string;
  render: (_: unknown, record: object) => ReactElement;
};

export const TmMgmtUninvitedTableColumns = (
  portalUserOwnerRole:
    | UserPermissionRole.PrimaryOwner
    | UserPermissionRole.Owner
    | UserPermissionRole.TeamManager
    | 999,
  portalUser: PortalUser,
  onOpenModal: (selectedModal: string, modalData?: null) => void,
): TmMgmtUninvitedTableColumn[] => [
  {
    title: 'TEAM MEMBER',
    dataIndex: 'teamMember',
    key: 'teamMember',
    width: 200,
    marginLeft: '$space200',
    render: (_: unknown, record: object) => (
      <div className="ml-4 px-3 py-4">
        <div className="align-center flex-row">
          <div className="mr-medium align-center flex-row">
            <Avatar size="large" shape="square" icon="user" src={record.attachmentUrl} />
          </div>
          <div>
            <Text size="md" className="font-base font-bold">{`${record.firstName} ${record.lastName}`}</Text>
            <br />
            <Text>{`${record.locality ?? 'Locality not set'} · ${record.email ?? 'Email not set'}`}</Text>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: 'WORKSPACE',
    dataIndex: 'workSpace',
    key: 'workSpace',
    width: 200,
    marginLeft: '$space200',
    render: (_: unknown, record: object) => {
      let appAccessText = '';
      switch (record.ownerRole) {
        case 1:
          appAccessText = 'Primary owner';
          break;
        case 2:
          appAccessText = 'Owner';
          break;
        case 5:
          appAccessText = 'Team manager';
          break;
        default:
          appAccessText = '';
      }

      return (
        <div className="ml-4 px-3 py-4">
          <Text>{appAccessText}</Text>
        </div>
      );
    },
  },
  {
    title: 'APP ACCESS',
    dataIndex: 'appAccess',
    key: 'appAccess',
    width: 200,
    marginLeft: '$space200',
    render: (_: unknown, record: object) => (
      <div className="ml-4 px-3 py-4">
        <Text>{record.hasPortalAccess ? 'Yes' : 'No'}</Text>
      </div>
    ),
  },
  {
    title: '',
    dataIndex: 'menuOptions',
    key: 'menuOptions',
    width: 10,
    marginLeft: '$space200',
    render: (_: unknown, record: object) => (
      <div className="ml-4 px-3 py-4">
        <Popover2
          content={
            <TmMgmtTeamTableActionMenu
              worker={record}
              portalUserOwnerRole={portalUserOwnerRole}
              portalUser={portalUser}
              onOpenModal={onOpenModal}
            />
          }
          popoverClassName="mb-medium"
          position="bottom"
          interactionKind="click"
        >
          <GhostButton className="border-2 border-solid border-[#ebebeb]" icon="ellipsis" />
        </Popover2>
      </div>
    ),
  },
];
