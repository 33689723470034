import React, { Component, type HTMLAttributes, type ErrorInfo, type ReactNode } from 'react';
import { Heading, IllustratedMessage, Link, Text } from '@good/components';
import { SpillCoffee } from '@good/illustrations';

type ErrorBoundaryProps = {
  children?: ReactNode;
  error: () => ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const { children, error } = this.props;

    if (this.state.hasError) return error();
    return children;
  }
}

export type GlobalErrorMessageProps = HTMLAttributes<HTMLDivElement>;

export const GlobalErrorMessage = (props: GlobalErrorMessageProps) => (
  <div {...props}>
    <IllustratedMessage>
      <div style={{ marginBottom: '-80px' }}>
        <SpillCoffee size="lg" />
      </div>

      <Heading>Oops, we{`'`}re only human!</Heading>

      <Text>
        An unexpected error occurred. Try reloading the page or contact{' '}
        <Link emphasis="no-underline">
          <a href="https://goodhuman.zendesk.com/hc/en-au">support</a>
        </Link>
        .
      </Text>
    </IllustratedMessage>
  </div>
);
