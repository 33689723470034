import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { IBooking } from 'interfaces/booking-interfaces';
import { Schema } from '../schema';
import fields from '../fields';

const useIsInputValid = ({
  form,
  isRecurringBooking,
  setIsInputValid,
  booking,
}: {
  form: UseFormReturn<Schema>;
  isRecurringBooking: boolean;
  setIsInputValid: (isValid: boolean) => void;
  booking: IBooking;
}) => {
  const { watch } = form;

  const otherCancellationReason = watch(fields.otherCancellationReason.name);
  const cancelledBy = watch(fields.cancelledBy.name);
  const cancellationReason = watch(fields.customerCancellationReason.name);
  const cancelRecurringBookingOption = watch(fields.cancelRecurringBooking.name);
  const numberOfRecurringBookingsToDelete = watch(fields.numberOfRecurringBookingsToDelete.name);

  useEffect(() => {
    const cancellingOnBehalfOfBusiness = cancelledBy === fields.cancelledBy.options.business;
    const cancellingOnBehalfOfCustomer = cancelledBy === fields.cancelledBy.options.customer;

    const validConditions = [
      cancellingOnBehalfOfBusiness && otherCancellationReason?.length,
      cancellingOnBehalfOfCustomer &&
        cancellationReason &&
        cancellationReason !== fields.customerCancellationReason.options.other,
      cancellingOnBehalfOfCustomer &&
        cancellationReason === fields.customerCancellationReason.options.other &&
        otherCancellationReason?.length,
    ];

    const recurringBookingValidConditions = [
      !isRecurringBooking,
      isRecurringBooking &&
        cancelRecurringBookingOption &&
        cancelRecurringBookingOption !== fields.cancelRecurringBooking.options.specified,
      isRecurringBooking &&
        cancelRecurringBookingOption === fields.cancelRecurringBooking.options.specified &&
        numberOfRecurringBookingsToDelete > 0 &&
        numberOfRecurringBookingsToDelete <= booking.numberOfBookingLeft,
    ];

    const isInputValid =
      validConditions.some((condition) => condition) && recurringBookingValidConditions.some((condition) => condition);
    setIsInputValid(isInputValid);
  }, [
    cancelledBy,
    cancellationReason,
    otherCancellationReason,
    cancelRecurringBookingOption,
    numberOfRecurringBookingsToDelete,
  ]);
};

export default useIsInputValid;
