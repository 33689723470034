import React from 'react';

export function Happy(): JSX.Element {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM3.75 12a8.25 8.25 0 1 1 16.5 0 8.25 8.25 0 0 1-16.5 0Zm3.25.25a.75.75 0 0 0-.716.974L7 13l-.716.224v.001l.001.002.002.005.004.013a3.235 3.235 0 0 0 .069.193 8.016 8.016 0 0 0 1.143 2.024c.87 1.113 2.316 2.288 4.497 2.288s3.628-1.175 4.497-2.288a8.02 8.02 0 0 0 1.197-2.173l.008-.024.007-.02.004-.013.002-.005v-.002L17 13l.716.224A.75.75 0 0 0 17 12.25H7Zm1.685 2.288a6.329 6.329 0 0 1-.524-.788h7.678a6.43 6.43 0 0 1-.524.788c-.693.887-1.746 1.712-3.315 1.712s-2.622-.825-3.315-1.712ZM10 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="#fff"
      />
    </svg>
  );
}
