import React, { useState, useEffect } from 'react';
import { getStorage, ref } from 'firebase/storage';
import { Text, Anchor, Alert } from '@good/ui/core';

import { ImageDropzone } from './image-dropzone';
import { Thumbnail } from './thumbnail';
import { CrossCircle } from '@good/icons';

type ImageUploaderProps = {
  disabled?: boolean;
  imageUploadUrl: string;
  onChange: (value: string[]) => void;
  onUploadFinish?: (status: 'success' | 'error') => void;
  onUploadStart?: () => void;
  value: string[];
};

export const ImageUploader = ({
  disabled,
  imageUploadUrl,
  onChange,
  onUploadFinish,
  onUploadStart,
  value = [],
}: ImageUploaderProps) => {
  const [showDropzone, setShowDropzone] = useState(false);
  const [imageName, setImageName] = useState<string>('');
  const [storage] = useState(() => getStorage());
  const imageSrc = value[0];

  useEffect(() => {
    if (imageSrc) {
      const httpsReference = ref(storage, imageSrc);
      setImageName(httpsReference.name);
    }
  }, [imageSrc, storage]);

  if (!imageUploadUrl) {
    // eslint-disable-next-line no-console -- don't want to throw error, would rather fail gracefully
    console.warn('Image uploader requires an image upload url.');
    return (
      <Alert color='red' title='Oops' w={400} icon={<CrossCircle />}>
        <Text>There was an error displaying the image uploader.</Text>
      </Alert>
    );
  }

  return (
    <div className='w-full border border-gray-light-1 rounded-2xl'>
      {showDropzone || !imageSrc ? (
        <ImageDropzone
          imageUploadUrl={imageUploadUrl}
          onUploadStart={onUploadStart}
          onUploadFinish={(status) => {
            setShowDropzone(false);
            onUploadFinish?.(status);
          }}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      ) : (
        <div className='w-full flex justify-between items-center gap-4 p-4'>
          <div className='flex items-center gap-4'>
            <Thumbnail src={imageSrc} />
            <Text size='sm'>{imageName}</Text>
          </div>
          <div className='px-4'>
            <Anchor
              component='button'
              className='group'
              onClick={() => !disabled && setShowDropzone(true)}
              disabled={disabled}
              type='button'
            >
              <Text
                size='sm'
                fw={600}
                className='group-disabled:text-weak group-disabled:cursor-not-allowed'
                td={disabled ? 'none' : undefined}
              >
                Change photo
              </Text>
            </Anchor>
          </div>
        </div>
      )}
    </div>
  );
};
