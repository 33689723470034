import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const HashtagCircle = forwardRef(function HashtagCircle(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.843-4.744a.75.75 0 0 1 .651.837l-.207 1.657h1.488l.23-1.843a.75.75 0 1 1 1.49.186l-.208 1.657H16a.75.75 0 0 1 0 1.5h-1.65l-.188 1.5H15.5a.75.75 0 0 1 0 1.5h-1.525l-.23 1.843a.75.75 0 1 1-1.49-.186l.208-1.657h-1.488l-.23 1.843a.75.75 0 1 1-1.49-.186l.208-1.657H8a.75.75 0 0 1 0-1.5h1.65l.188-1.5H8.5a.75.75 0 0 1 0-1.5h1.525l.23-1.843a.75.75 0 0 1 .838-.651Zm.07 5.494h1.487l.188-1.5H11.35l-.188 1.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
