import { Item, SelectField } from '@good/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RangeOption } from 'views/sil-service/tabs/schedule/components/range-selector';

export const RangeSelector = ({
  range,
  onRangeChange,
}: {
  range: RangeOption;
  onRangeChange: (range: RangeOption) => void;
}) => {
  const { t } = useTranslation('', { keyPrefix: 'rostering.rangeSelectorOptions' });

  return (
    <SelectField selectedKey={range} onSelectionChange={(evt) => onRangeChange(evt as RangeOption)} className='flex'>
      <Item id='day'>{t('day')}</Item>
      <Item id='week'>{t('week')}</Item>
      <Item id='fortnight'>{t('fortnight')}</Item>
    </SelectField>
  );
};
