import React from 'react';

export function Icon(props) {
  let { children, size = 20, style, ...otherProps } = props;
  let dimensions = { width: size, height: size };

  return (
    <svg
      {...otherProps}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      {...dimensions}
      style={{ ...style, minWidth: size, minHeight: size }}
      role="presentation"
    >
      {children}
    </svg>
  );
}

export function CheckCircle(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="m172 104-58.7 56L84 132"
      />
      <circle
        cx={128}
        cy={128}
        r={96}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Icon>
  );
}

export function ChevronDown(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="m208 96-80 80-80-80"
      />
    </Icon>
  );
}

export function ChevronUp(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="m48 160 80-80 80 80"
      />
    </Icon>
  );
}

export function DragHandle(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <circle cx={92} cy={60} r={12} fill="currentColor" />
      <circle cx={164} cy={60} r={12} fill="currentColor" />
      <circle cx={92} cy={128} r={12} fill="currentColor" />
      <circle cx={164} cy={128} r={12} fill="currentColor" />
      <circle cx={92} cy={196} r={12} fill="currentColor" />
      <circle cx={164} cy={196} r={12} fill="currentColor" />
    </Icon>
  );
}

export function File(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        d="M200 224H56a8 8 0 0 1-8-8V40a8 8 0 0 1 8-8h96l56 56v128a8 8 0 0 1-8 8Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="M152 32v56h56"
      />
    </Icon>
  );
}

export function Plus(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="M40 128h176M128 40v176"
      />
    </Icon>
  );
}

export function Warning(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="M128 104v40M114.2 40l-88 152A16 16 0 0 0 40 216h176a16 16 0 0 0 13.8-24l-88-152a15.9 15.9 0 0 0-27.6 0Z"
      />
      <circle cx={128} cy={180} r={12} fill="currentColor" />
    </Icon>
  );
}

export function XCircle(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <circle cx={128} cy={128} r={96} fill="none" stroke="currentColor" strokeMiterlimit={10} strokeWidth={16} />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="m160 96-64 64M160 160 96 96"
      />
    </Icon>
  );
}

export function Link(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="M94.1 161.9 161.9 94M145 178.9l-28.3 28.3a48 48 0 0 1-67.9-67.9L77.1 111M178.9 145l28.3-28.3a48 48 0 0 0-67.9-67.9L111 77.1"
      />
    </Icon>
  );
}

export function FileUpload(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="m86 82 42-42 42 42M128 152V40M216 152v56a8 8 0 0 1-8 8H48a8 8 0 0 1-8-8v-56"
      />
    </Icon>
  );
}

export function Stamp(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="M40 224h176M112 136 94.2 53a24 24 0 0 1 23.5-29h20.6a24 24 0 0 1 23.5 29L144 136"
      />
      <rect
        x={40}
        y={136}
        width={176}
        height={56}
        rx={8}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Icon>
  );
}

export function WarningOctagon(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="M128 80v56"
      />
      <path
        d="M164.5 32h-73a7.9 7.9 0 0 0-5.6 2.3L34.3 85.9a7.9 7.9 0 0 0-2.3 5.6v73a7.9 7.9 0 0 0 2.3 5.6l51.6 51.6a7.9 7.9 0 0 0 5.6 2.3h73a7.9 7.9 0 0 0 5.6-2.3l51.6-51.6a7.9 7.9 0 0 0 2.3-5.6v-73a7.9 7.9 0 0 0-2.3-5.6l-51.6-51.6a7.9 7.9 0 0 0-5.6-2.3Z"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={16}
      />
      <circle cx={128} cy={172} r={12} fill="currentColor" />
    </Icon>
  );
}

export function Users(props) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <circle cx={88} cy={108} r={52} fill="none" stroke="currentColor" strokeMiterlimit={10} strokeWidth={16} />
      <path
        d="M155.4 57.9a54.5 54.5 0 0 1 14.1-1.9 52 52 0 0 1 0 104M16 197.4a88 88 0 0 1 144 0"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <path
        d="M169.5 160a87.9 87.9 0 0 1 72 37.4"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Icon>
  );
}
