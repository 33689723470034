import TagManager from 'react-gtm-module';
import { GTM_CLIENT_ID } from '../config/app-config';

const tagManagerArgs = {
  gtmId: GTM_CLIENT_ID,
};

TagManager.initialize(tagManagerArgs);

type WindowWithGTM = Window &
  typeof globalThis & {
    dataLayer: Record<string, unknown>[];
  };

declare const window: WindowWithGTM;

export enum GTMEventName {
  GENERATE_TIMESHEET_EXPORT_FILE = 'generateTimesheetExportFile',
}

export type GTMEvent = {
  event: GTMEventName;
  payload: Record<string, unknown>;
};

export const pushGTMEvent = ({ event, payload }: GTMEvent) => {
  try {
    window.dataLayer.push({ event, ...payload });
  } catch (e) {
    console.error('Error pushing event to dataLayer', e);
  }
};
