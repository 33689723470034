import React, { useEffect } from 'react';
import { Icon, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { WrappedFormUtils } from 'antd/lib/form/Form';

import { Box, Group, Stack, type StackProps } from '@good/ui/core';
import { SubTitle } from 'common-components/typography';
import { type CountryName, LocaleConfigProvider, useLocaleConfig } from 'providers/locale-provider';
import { countryNameToCode } from 'providers/locale-provider/countries';
import { SelectCountryAntd } from '../select-country';
import { SelectStateAntd } from '../select-state';

type AddressInput = {
  streetAddress1: string;
  streetAddress2: string;
  locality: string;
  state: string;
  postcode: string;
  country: string;
};

const defaultFormMapping: AddressInput = {
  streetAddress1: 'streetAddress1',
  streetAddress2: 'streetAddress2',
  locality: 'locality',
  state: 'state',
  postcode: 'postcode',
  country: 'country',
};

type AddressInputManualAntdProps = StackProps & {
  form: WrappedFormUtils<AddressInput>;
  initialValues?: AddressInput;
  formMapping?: Partial<AddressInput>;
  required?: boolean;
};

export const AddressInputManualAntd = (props: AddressInputManualAntdProps) => {
  const { current, available } = useLocaleConfig();

  return (
    <LocaleConfigProvider
      countryCode={current.countryCode}
      availableCountryCodes={available.map((config) => config.countryCode)}
    >
      <AddressInputManualAntdForm {...props} />
    </LocaleConfigProvider>
  );
};

const AddressInputManualAntdForm = ({
  form,
  initialValues,
  formMapping: formMappingProp,
  required,
  ...stackProps
}: AddressInputManualAntdProps) => {
  const formMapping: AddressInput = {
    ...defaultFormMapping,
    ...formMappingProp,
  };
  const {
    validatePostcode,
    current: currentLocaleConfig,
    tFixed: t,
    changeConfig: setLocaleConfig,
  } = useLocaleConfig();
  const country = form.getFieldValue(formMapping.country) as CountryName | undefined;

  useEffect(() => {
    if (country) {
      const countryCode = countryNameToCode(country);
      if (countryCode) {
        setLocaleConfig({ countryCode });
      }
      if (form.isFieldTouched(formMapping.country)) {
        form.setFieldsValue({
          [formMapping.state]: undefined,
        });
      }
    }
  }, [country, setLocaleConfig]);

  return (
    <Stack maw={800} {...stackProps}>
      <div>
        <SubTitle containerClassName='mb-x-small'>{t('address.streetAddress1')}</SubTitle>
        <FormItem className='m-none pr-small'>
          {form.getFieldDecorator(formMapping.streetAddress1, {
            initialValue: initialValues?.streetAddress1,
            rules: [
              {
                required,
                message: t('address.required.streetAddress1'),
              },
            ],
          })(<Input size='large' placeholder={t('address.placeholder.streetAddress1')} />)}
        </FormItem>
      </div>

      <div>
        <SubTitle containerClassName='mb-x-small'>{t('address.streetAddress2')}</SubTitle>
        <FormItem className='m-none pr-small'>
          {form.getFieldDecorator(formMapping.streetAddress2, {
            initialValue: initialValues?.streetAddress2,
          })(<Input size='large' placeholder={t('address.placeholder.streetAddress2')} />)}
        </FormItem>
      </div>

      <div>
        <SubTitle containerClassName='mb-x-small'>{t('address.locality')}</SubTitle>
        <FormItem className='m-none pr-small'>
          {form.getFieldDecorator(formMapping.locality, {
            initialValue: initialValues?.locality,
            rules: [
              {
                required,
                message: t('address.required.locality'),
              },
            ],
          })(<Input size='large' placeholder={t('address.placeholder.locality')} />)}
        </FormItem>
      </div>

      <Group w='100%' align='start'>
        <Box flex={1}>
          <SubTitle containerClassName='mb-x-small'>{t('address.state')}</SubTitle>
          <div className='position-relative'>
            <Icon
              type='search'
              className='position-absolute text-color-tertiary flex justify-center'
              style={{ width: 40, top: 11, left: 0, fontSize: 17, zIndex: 1 }}
            />
            <FormItem className='m-none pr-small'>
              {form.getFieldDecorator(formMapping.state, {
                initialValue: initialValues?.state,
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required,
                    message: t('address.required.state'),
                  },
                ],
              })(
                <SelectStateAntd
                  placeholder='Search for...'
                  className='ant-select-with-prefix'
                  showSearch={true}
                  optionFilterProp='children'
                  size='large'
                  style={{ width: '100%' }}
                />,
              )}
            </FormItem>
          </div>
        </Box>

        <Box flex={1}>
          <SubTitle containerClassName='mb-x-small'>{t('address.postcode')}</SubTitle>
          <FormItem className='m-none pr-small'>
            {form.getFieldDecorator(formMapping.postcode, {
              initialValue: initialValues?.postcode,
              rules: [
                {
                  validator: (_rule, value: string, callback: () => void) =>
                    validatePostcode(value, {
                      callback,
                      required,
                    }),
                },
              ],
            })(<Input size='large' placeholder={t('address.placeholder.postcode')} />)}
          </FormItem>
        </Box>
      </Group>

      <div>
        <SubTitle containerClassName='mb-x-small'>{t('address.country')}</SubTitle>
        <FormItem className='m-none pr-small'>
          {form.getFieldDecorator(formMapping.country, {
            initialValue: initialValues?.country ?? currentLocaleConfig.name,
          })(
            <SelectCountryAntd size='large' placeholder={t('address.placeholder.country')} style={{ width: '100%' }} />,
          )}
        </FormItem>
      </div>
    </Stack>
  );
};
