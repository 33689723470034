import React from 'react';
import { Box, Card, Heading, Illustrations, Skeleton, Split, Stack, Text } from '@goodhuman-me/components';
import { useParams } from 'react-router-dom';

import { Budget } from './components';
import { useBudget } from './services';

function Error() {
  return (
    <Stack height="calc(100% - $size800)" gap="$space300" justifyContent="center">
      <Split
        alignItems="center"
        collapseAt="768px"
        fraction="two-thirds"
        gap={{ initial: '$space0', medium: '$space400' }}
      >
        <Stack justifyContent="center" gap="$space200">
          <Heading asChild size="xlarge">
            <h1>Oops! We{"'"}re only human</h1>
          </Heading>

          <Text>We{"'"}re not sure what went wrong. Please try again.</Text>
        </Stack>

        <Stack width={{ initial: '$size3600', medium: '$size36000', large: '$size5000' }} justifyContent="center">
          <Illustrations.SpiltCoffee />
        </Stack>
      </Split>
    </Stack>
  );
}

function Loading() {
  return (
    <Stack marginTop="$space100" gap="$space600">
      <Box width="$size4600" height="$size1600">
        <Skeleton.Root>
          <Skeleton.Text lines={1} />
          <Skeleton.Heading y="64" />
          <Skeleton.Text y="112" lines={1} width="50%" />
        </Skeleton.Root>
      </Box>

      <Card>
        <Box width="50%">
          <Skeleton.Root>
            <Skeleton.Heading size="small" width="50%" />
            <Skeleton.Text y="48" width="75%" />
          </Skeleton.Root>
        </Box>
      </Card>
    </Stack>
  );
}

export function Detail(): JSX.Element {
  let { serviceAgreementId, serviceId } = useParams<{
    customerId: string;
    serviceAgreementId: string;
    serviceId: string;
  }>();
  let { data, isError, isLoading, isSuccess } = useBudget(serviceAgreementId);

  return (
    <Box minHeight="100%" width="100%" backgroundColor="$white" paddingBottom="$xxlarge">
      <Box height="100%" maxWidth="1140px" marginX="auto" paddingX={{ initial: '$space400', xlarge: '$space0' }}>
        {(isSuccess || isLoading) && (
          <Box height="100%" width="100%" backgroundColor="$white" paddingTop="$space800">
            {isLoading && <Loading />}
            {isSuccess && data && <Budget {...data} serviceId={serviceId} />}
          </Box>
        )}

        {isError && <Error />}
      </Box>
    </Box>
  );
}
