import { useQuery } from '@tanstack/react-query';

import { fetchTeamMemberSchedule } from 'stores/queries/group-services/group-services-queries';

export function useFetchTeamMemberSchedule(payload, enabled, callBack) {
  const { data, remove, refetch, isFetching, isLoading, status, error } = useQuery(
    ['team-member-schedule', payload],
    () => fetchTeamMemberSchedule(payload),
    {
      enabled,
      onSuccess: callBack,
    },
  );

  return { data, remove, refetch, isFetching, isLoading, status, error };
}
