import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { connect } from 'react-redux';
import { GhostButton } from 'common-components/buttons';
import { IRootState } from 'stores/rematch/root-store';
import moment from 'moment';

interface IViewWaivedReasonActionModelProps {
  isOpen: boolean;
  selectedBillingLineItem: any;
  onClose: () => void;
}

interface IViewWaivedReasonActionModelState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

class ViewRejecReasonActionModel extends Component<
  IViewWaivedReasonActionModelProps,
  IViewWaivedReasonActionModelState
> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false,
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  render() {
    const { isOpen, selectedBillingLineItem } = this.props;

    return (
      selectedBillingLineItem && (
        <ActionModal
          title="Rejection Reason"
          isOpen={isOpen}
          onClose={this._onCloseModal}
          width="medium"
          verticalAlignment="center"
        >
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>
                <b>
                  Reject by{' '}
                  {selectedBillingLineItem.rejectedByFirstName + ' ' + selectedBillingLineItem.rejectedByLastName}
                </b>{' '}
                on{' '}
                <b>
                  {moment(selectedBillingLineItem.rejectedOn ? selectedBillingLineItem.rejectedOn : new Date()).format(
                    'DD/MM/YYYY',
                  )}
                </b>
              </Paragraph>
            </div>
            <div className="mb-medium">
              <Paragraph>"{selectedBillingLineItem.rejectReason}"</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <GhostButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Close
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
      )
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem,
});

export default connect(mapState, null)(ViewRejecReasonActionModel);
