import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Radio, Stack, RadioGroup } from 'design-components';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Inline, Text } from '@goodhuman-me/components';

import { Schema, SchemaFieldNames, SchemaOptions } from '../schema';
import { IFieldWithOptions } from '../fields';

interface ICancelBookingsRadioProps<T extends SchemaOptions> {
  field: IFieldWithOptions<SchemaFieldNames, string | undefined, T>;
  popover?: React.ReactNode;
  interpolations?: Record<string, string>;
}

const CancelBookingsRadio = <T extends SchemaOptions>({
  field,
  popover,
  interpolations,
}: ICancelBookingsRadioProps<T>): JSX.Element => {
  const keyPrefix = `bookings.modals.cancelBooking.${field.name}`;
  const { t, i18n } = useTranslation('', { keyPrefix });
  const { control } = useFormContext<Schema>();
  const optionInterpolations = field.optionInterpolations?.(interpolations);

  return (
    <>
      {i18n.exists(`${keyPrefix}.label`) && (
        <Box paddingBottom="$small">
          <Inline gap="$xxsmall" alignItems="center">
            <Text fontWeight={700}>{t(`label`)}</Text>
            {popover}
          </Inline>
        </Box>
      )}
      <Stack>
        <Controller
          name={field.name}
          defaultValue={field.defaultValue}
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <RadioGroup onChange={onChange} value={value?.toString()} onBlur={onBlur} aria-label={field.name}>
              {Object.values(field.options).map((option: string) => (
                <Radio
                  size="small"
                  label={
                    <Text fontWeight={500}>
                      <Trans t={t} i18nKey={`options.${option}`} values={optionInterpolations?.[option]} />
                    </Text>
                  }
                  value={option}
                  key={option}
                  aria-label={option}
                />
              ))}
            </RadioGroup>
          )}
        />
      </Stack>
    </>
  );
};

export default CancelBookingsRadio;
