import React from 'react';

import { Box, type BoxProps } from '@good/ui/core';

type InfiniteScrollEndProps = Omit<BoxProps, 'component'> & {
  asTableRow?: boolean;
};

export const InfiniteScrollEnd = ({ asTableRow, ...props }: InfiniteScrollEndProps) => {
  return <Box w='100%' h={24} {...props} component={asTableRow ? 'tr' : 'div'} />;
};
