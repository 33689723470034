import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const MapView = forwardRef(function MapView(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.845 2.355a.75.75 0 0 1 .367.645v14.727a.75.75 0 0 1-.39.659l-6 3.272a.75.75 0 0 1-.719 0l-5.64-3.076-5.642 3.076A.75.75 0 0 1 2.712 21V6.273a.75.75 0 0 1 .391-.659l6-3.272a.75.75 0 0 1 .718 0l5.641 3.076 5.641-3.076a.75.75 0 0 1 .742.013Zm-7.133 4.363-4.5-2.455v13.019l4.5 2.455V6.718Zm1.5 13.019 4.5-2.455V4.263l-4.5 2.455v13.019Zm-7.5-2.455V4.263l-4.5 2.455v13.019l4.5-2.455Z"
        fill="currentColor"
      />
    </Icon>
  );
});
