import { Box, Button, Inline } from '@goodhuman-me/components';
import { Radio, RadioGroup } from 'design-components';
import React, { useEffect, useState } from 'react';
import { INVITE_MEMBER_ROLE_TYPE } from 'views/team-v2/utils/constants';
import { Member } from '../../utils/form-utils';

interface IProps {
  onCancel?: () => void;
  teamMemberList: Member[];
  updateStatusInviteToApp: (value: boolean) => void;
}

const InviteToApp: React.FC<IProps> = ({ onCancel, teamMemberList, updateStatusInviteToApp }) => {
  const [valueChecked, setValueChecked] = useState<string>('NO');

  const checkRadioButton = () => {
    const checkedMembers = teamMemberList.filter((member) => member.isChecked);
    return checkedMembers.every((member) => member.roles.includes(INVITE_MEMBER_ROLE_TYPE.NO_ACCESS));
  };

  const onSubmit = () => {
    updateStatusInviteToApp(valueChecked === 'YES');
    onCancel?.();
  };

  useEffect(() => {
    if (teamMemberList && teamMemberList.length > 0) {
      const isAllMemberCheckedAndHasAccess = teamMemberList
        .filter((one) => one.isChecked)
        .every((one) => one.hasWorkerAppAccess);
      setValueChecked(isAllMemberCheckedAndHasAccess ? 'YES' : 'NO');
    } else {
      setValueChecked('NO');
    }
  }, [teamMemberList]);

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Box width={248}>
      <Box paddingX="$space200" paddingY="$space100">
        <RadioGroup onChange={(value) => setValueChecked(value)} value={valueChecked}>
          <Radio
            aria-label="has-app-access-yes"
            wrapperStyles={{ padding: '$space100' }}
            size="small"
            label="Yes"
            value="YES"
          />
          <Radio
            aria-label="has-app-access-no"
            wrapperStyles={{ padding: '$space100' }}
            size="small"
            label="No"
            value="NO"
            isDisabled={checkRadioButton()}
          />
        </RadioGroup>
      </Box>
      <Box padding="$space200">
        <Inline justifyContent="flex-end">
          <Button kind="accent" emphasis="quiet" onPress={handleCancel}>
            Cancel
          </Button>
          <Button kind="accent" emphasis="filled" marginLeft="$space150" onPress={onSubmit}>
            Apply
          </Button>
        </Inline>
      </Box>
    </Box>
  );
};

export default InviteToApp;
