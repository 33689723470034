import { UseFormReturn } from 'react-hook-form';

import { IGroupServiceCustomerRatio } from 'interfaces/service-interfaces';
import { EditRatiosSchema } from './schema';

export type IGroupServiceCustomerRatioForPayload = Pick<
  IGroupServiceCustomerRatio,
  'teamMemberCustomerRatio' | 'customRatio' | 'durationRatios'
> & {
  isCustomRatio: boolean;
};

export function getGroupServiceCustomerRatioFromForm(
  form: UseFormReturn<EditRatiosSchema>,
): IGroupServiceCustomerRatioForPayload {
  return getGroupServiceCustomerRatioFromFormValues(form.getValues());
}

export function getGroupServiceCustomerRatioFromFormValues(
  values: EditRatiosSchema,
): IGroupServiceCustomerRatioForPayload {
  const groupServiceCustomerRatio: IGroupServiceCustomerRatioForPayload = {
    teamMemberCustomerRatio: values.teamMemberCustomerRatio,
    isCustomRatio: values.ratios?.length > 0,
    customRatio: [],
    durationRatios: [],
  };

  values.ratios.forEach((ratio) => {
    if (ratio.isExactTime) {
      groupServiceCustomerRatio.customRatio.push({
        startDateTime: ratio.customRatio.startDateTime,
        endDateTime: ratio.customRatio.endDateTime,
        teamMemberCustomerRatio: ratio.teamMemberCustomerRatio,
        comments: '',
      });
    } else {
      groupServiceCustomerRatio.durationRatios.push({
        duration: ratio.durationRatio.duration * (ratio.durationRatio.unit === 'minutes' ? 1 : 60),
        teamMemberCustomerRatio: ratio.teamMemberCustomerRatio,
        comments: '',
      });
    }
  });

  return groupServiceCustomerRatio;
}
