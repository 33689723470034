import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ListUnordered5 = forwardRef(function ListUnordered5(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.03 2.97a.75.75 0 1 0-1.06 1.06l.97.97-.97.97a.75.75 0 0 0 1.06 1.06L5 6.06l.97.97a.75.75 0 0 0 1.06-1.06L6.06 5l.97-.97a.75.75 0 0 0-1.06-1.06L5 3.94l-.97-.97ZM10.25 5a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 0 1.5H11a.75.75 0 0 1-.75-.75Zm.75 6.25a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5H11Zm0 7a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5H11ZM2.97 9.97a.75.75 0 0 1 1.06 0l.97.97.97-.97a.75.75 0 1 1 1.06 1.06l-.97.97.97.97a.75.75 0 1 1-1.06 1.06L5 13.06l-.97.97a.75.75 0 0 1-1.06-1.06l.97-.97-.97-.97a.75.75 0 0 1 0-1.06Zm1.06 7a.75.75 0 0 0-1.06 1.06l.97.97-.97.97a.75.75 0 1 0 1.06 1.06l.97-.97.97.97a.75.75 0 0 0 1.06-1.06L6.06 19l.97-.97a.75.75 0 1 0-1.06-1.06l-.97.97-.97-.97Z"
        fill="currentColor"
      />
    </Icon>
  );
});
