import { TimelineProvider } from './components/timeline-provider';
import { TimelineLayout } from './components/timeline-layout';
import React from 'react';
import { TimelineProps } from 'design-components/timeline';

export const Timeline = (props: TimelineProps) => (
  <TimelineProvider {...props}>
    <TimelineLayout />
  </TimelineProvider>
);
