import { HyperlinkButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import React, { useState } from 'react';

interface IBottomActionSheetProps {
  itemCount?: number;
  label?: string;
  labelPlural?: string;
  children?: React.ReactNode;
  onDeselect?: () => void;
  className?: string;
}

const BottomActionSheetV2 = (props: IBottomActionSheetProps) => {
  const { itemCount, label = 'item', labelPlural = 'items', children, onDeselect, className } = props;

  const [isOpen, setIsOpen] = useState(true);

  const animationEnd = () => {
    if (!isOpen) onDeselect();
  };

  const animClass = isOpen ? 'action-anim-slide-in' : 'action-anim-slide-out';

  return (
    <div
      className={`${animClass} ${className}`}
      style={{ bottom: 0, position: 'sticky', zIndex: 20, width: 'inherit', marginTop: '-32px' }}
      onAnimationEnd={animationEnd}
    >
      <div
        className="bg-blue"
        style={{
          width: '12px',
          height: '12px',
          position: 'absolute',
          top: '-6px',
          left: '58px',
          transform: 'rotate(45deg)',
        }}
      />
      <div className="bg-blue pv-medium ph-large flex-row justify-between align-center">
        <div>
          <Text color="white" weight="bold" className="mr-medium">
            {itemCount} {itemCount !== 1 ? labelPlural : label} selected
          </Text>
          <HyperlinkButton color="white" className="dim" onClick={() => setIsOpen(false)}>
            Deselect all
          </HyperlinkButton>
        </div>
        {children && <>{children}</>}
      </div>
    </div>
  );
};

export default BottomActionSheetV2;
