import React, { useState, type HTMLAttributes } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';

import { Button, Heading, IllustratedMessage, StatusLight } from '@good/components';
import {
  ArrowRight,
  Calendar,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  DollarCircle,
  NoteText,
  Route,
} from '@good/icons';
import { Anchor, Group, Text } from '@good/ui/core';
import { DateFormatter } from '@goodhuman-me/components';
import { IPhoneInHandStars, SpillCoffee } from '@good/illustrations';
import { type Notification as NotificationType, type NotificationFilters } from '@good/schemas';
import { tv } from 'tailwind-variants';
import { useFetchCategories, useFetchNotifications } from 'stores/hooks/query-hooks/use-query-fetch-notifications';
import { CheckboxFilter } from './checkbox-filter';
import { useHistory } from 'react-router-dom';

/**********************************************************
 * Empty
 *********************************************************/

type EmptyProps = HTMLAttributes<HTMLDivElement>;
const Empty = (props: EmptyProps) => (
  <div className='flex grow basis-full flex-col gap-8'>
    <div className='flex flex-row items-center justify-center rounded-lg border border-base p-6'>
      <IllustratedMessage {...props}>
        <IPhoneInHandStars />
        <Heading>You{`'`}re all caught up</Heading>
      </IllustratedMessage>
    </div>
  </div>
);

/**********************************************************
 * Error
 *********************************************************/

type ErrorProps = HTMLAttributes<HTMLDivElement>;
const Error = (props: ErrorProps) => (
  <div className='flex grow basis-full flex-col gap-8'>
    <div className='flex flex-row items-center justify-center rounded-lg border border-base p-6'>
      <IllustratedMessage {...props} className='mt-[-40px]'>
        <div className='mb-[-40px]'>
          <SpillCoffee />
        </div>

        <Heading>Oops, we{`'`}re only human!</Heading>
        <Text>An unexpected error occurred. Try refreshing the page or contact support.</Text>
      </IllustratedMessage>
    </div>
  </div>
);

/**********************************************************
 * Loading
 *********************************************************/

const SkeletonNotification = () => {
  return (
    <div className='flex animate-pulse items-center space-x-4 p-4'>
      <div className='m-3 h-6 w-6 rounded-full bg-gray-light-1'></div>
      <div className='flex w-1/2 flex-col space-y-2 py-1'>
        <div className='h-2 rounded bg-gray-light-1'></div>
        <div className='h-2 w-1/2 rounded bg-gray-light-1 '></div>
      </div>
    </div>
  );
};

const Loading = () => {
  return (
    <div className='flex grow basis-full flex-col gap-8'>
      <div className='flex flex-col rounded-lg border border-base'>
        <SkeletonNotification />
        <SkeletonNotification />
      </div>
    </div>
  );
};

/**********************************************************
 * Notification
 *********************************************************/

const styles = tv({
  base: [],
  slots: {
    container: ['group', 'p-4'],
    timestamp: [],
    menu: ['m-1.5', 'relative'],
  },
  variants: {
    status: {
      read: {
        container: ['bg-base'],
        timestamp: ['text-weak'],
        icon: [],
      },
      unread: {
        container: ['bg-base'],
        timestamp: ['text-accent'],
        icon: [],
      },
      unseen: {
        container: ['bg-base'],
        timestamp: ['text-accent'],
        icon: [],
      },
    },
  },
});

type NotificationProps = NotificationType;
const Notification = (props: NotificationProps) => {
  const { category, createdOn, summary, status, description, meta } = props;
  const history = useHistory();

  const CategoryIcon = (
    {
      booking: Calendar,
      payment: DollarCircle,
      planManagement: NoteText,
      session: Calendar,
      workflow: Route,
    } as const
  )[category];

  const link: string | undefined = (() => {
    switch (category) {
      case 'booking':
        if ('bookingId' in meta) {
          return `/bookings/details/${meta['bookingId']}`;
        }
        break;
      case 'payment':
        break;
      case 'session':
        if ('serviceId' in meta && 'serviceDateTimeId' in meta) {
          return `/group-service/${meta['serviceId']}/session/details/${meta['serviceDateTimeId']}`;
        }
        break;
      case 'workflow':
        break;
      default:
        break;
    }

    return undefined;
  })();

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    if (link) {
      history.push(link);
    }
  };

  const { container, timestamp } = styles({ status });

  return (
    <div {...props} className={container()}>
      <div className='flex flex-row gap-4'>
        <div className='flex min-h-[48px] min-w-[48px] items-center justify-center text-b-xl'>
          <CategoryIcon />
        </div>

        <div className='width-full flex flex-row items-center justify-between'>
          <div className='flex flex-col justify-center gap-1'>
            <div>
              <Group>
                <Text fw={500}>{summary}</Text>

                {link && (
                  <>
                    <Text>|</Text>
                    <Group gap={0} align='center'>
                      <Anchor href={link} onClick={onClick} size='sm' fw={600}>
                        View details
                      </Anchor>
                      <Anchor href={link} onClick={onClick} size='xs' pl={4}>
                        <ArrowRight />
                      </Anchor>
                    </Group>
                  </>
                )}
              </Group>
              <Text size='sm' c='dimmed'>
                {description}
              </Text>
            </div>
            <Text size='xs' className={timestamp()}>
              <DateFormatter value={createdOn} kind='display' />
            </Text>
          </div>

          <div className='relative'>
            <div>{status !== 'read' && <StatusLight tone='canary' className='m-1.5' />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**********************************************************
 * Pagination
 *********************************************************/

type PaginationProps = {
  count: number;
  page: number;
} & Pick<NotificationHandlers, 'onChangePage'>;

const Pagination = (props: PaginationProps) => {
  const { count, onChangePage, page } = props;
  const pageSize = 10;
  const pageCount = Math.floor(count / pageSize);
  const isNextDisabled = !(page < pageCount);
  const isPrevDisabled = !(page > 1);

  return (
    <div className='flex flex-row items-center gap-4'>
      <Button emphasis='quiet' tone='neutral' onPress={() => onChangePage(1)}>
        <ChevronsLeft />
      </Button>
      <Button emphasis='quiet' tone='neutral' isDisabled={isPrevDisabled} onPress={() => onChangePage(page - 1)}>
        <ChevronLeft />
      </Button>
      <Text className='text-weak'>
        Page {page} of {pageCount} ({count} results)
      </Text>
      <Button emphasis='quiet' tone='neutral' isDisabled={isNextDisabled} onPress={() => onChangePage(page + 1)}>
        <ChevronRight />
      </Button>
      <Button emphasis='quiet' tone='neutral' onPress={() => onChangePage(pageCount)}>
        <ChevronsRight />
      </Button>
    </div>
  );
};

/**********************************************************
 * NotificationList
 *********************************************************/

type ListProps = HTMLAttributes<HTMLDivElement> & Pick<NotificationHandlers, 'onChangePage'> & NotificationFilters;

const List = (props: ListProps) => {
  const { onChangePage, page, status, category } = props;

  const { data, isError, isLoading, isSuccess } = useFetchNotifications({ page, status, category });

  if (isError) return <Error />;
  if (isLoading || !data?.notifications) return <Loading />;
  if (isSuccess) {
    const { notifications, total_count: totalCount } = data;
    const notificationsCount = notifications.length;
    const isEmpty = !notificationsCount;

    if (isEmpty) return <Empty />;

    return (
      <>
        <div className='shrink'></div>
        <div className='flex grow basis-full flex-col gap-8'>
          <div className='flex flex-col rounded-lg border border-base overflow-hidden'>
            <div {...props}>
              {notifications.map((notification) => (
                <Notification key={notification.id} {...notification} />
              ))}
            </div>
          </div>

          <Pagination count={totalCount} onChangePage={onChangePage} page={+page} />
        </div>
      </>
    );
  }
  return null;
};

/**********************************************************
 * Filters
 *********************************************************/

const Categories = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState<string[]>(
    searchParams.getAll('category')?.[0]?.split(',') ?? [],
  );

  const { data } = useFetchCategories();

  const handleOnChangeCategory = (selected: string[]) => {
    if (selected.length) {
      setSearchParams({ ...searchParams, category: selected.toString() });
    } else {
      searchParams.delete('category');
      setSearchParams({ ...searchParams });
    }
  };

  return (
    <CheckboxFilter
      headerLabel='Type'
      onApply={handleOnChangeCategory}
      items={data?.categories}
      selectedItems={selectedCategory}
      setSelectedItems={setSelectedCategory}
    />
  );
};

/**********************************************************
 * Notifications
 *********************************************************/

type NotificationHandlers = {
  onChangePage: (page: number) => void;
  onChangeStatus: (status: string) => void;
};

type NotificationsProps = HTMLAttributes<HTMLDivElement>;

export const Notifications = (props: NotificationsProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams({
    status: 'unread',
    category: [],
    page: '1',
  });

  const handleOnChangePage: NotificationHandlers['onChangePage'] = (page: number) =>
    setSearchParams((prev) => ({
      status: prev.get('status') ?? 'unread',
      category: prev.getAll('category') ?? [],
      page: page.toString(),
    }));

  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  const status = searchParams.get('status') ?? undefined;
  const category = searchParams.getAll('category');

  return (
    <>
      <main className='width-full'>
        <div className='flex flex-col'>
          <div className='flex flex-row flex-wrap items-center gap-4'>
            <div className='width-full grow ml-4'>
              <Categories />
            </div>
            <List onChangePage={handleOnChangePage} page={page} status={status} category={category} />
          </div>
        </div>
      </main>
    </>
  );
};
