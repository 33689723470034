/* eslint-disable */
import React, { PureComponent } from 'react';
import { Text } from 'common-components/typography';
import { MdChangeHistory, MdRadioButtonUnchecked } from 'react-icons/md';

interface IAlertLevelTagProps {
  alertLevel: string;
  size?: 'small' | 'default' | 'large';
  textSize?: string;
  className?: any;
}

export default class AlertLevelTag extends PureComponent<IAlertLevelTagProps, any> {
  BgLevelColors = {
    HIGH: 'bg-red',
    MEDIUM: 'bg-orange-light',
    LOW: 'bg-green-light',
  };
  LevelIcons = {
    HIGH: <MdChangeHistory className="text-size-small mr-x-small text-color-white" />,
    MEDIUM: <MdRadioButtonUnchecked className="text-size-small mr-x-small text-color-white" />,
    LOW: (
      <MdChangeHistory
        className="text-size-small mr-x-small text-color-white"
        style={{ transform: 'rotateX(180deg)' }}
      />
    ),
  };

  render() {
    const { alertLevel, className, textSize = 'regular', size = 'default' } = this.props;

    const bgColor = this.BgLevelColors[alertLevel];

    const displayLevel = alertLevel === 'HIGH' ? 'High' : alertLevel === 'MEDIUM' ? 'Med' : 'Low';

    let sizeClasses = '';

    if (size === 'default') {
      sizeClasses = 'pv-small ph-medium text-size-regular letter-spacing-wide';
    } else if (size === 'large') {
      sizeClasses = 'pv-medium ph-large text-size-large letter-spacing-wide';
    } else if (size === 'small') {
      sizeClasses = 'pv-x-small ph-x-small text-size-small letter-spacing-wide';
    }

    const containerClassNames = `text-align-center ${sizeClasses} inline-flex flex-row align-center rounded-full ${bgColor} ${className}`;
    const textClassNames = 'text-color-white';

    return (
      <div className={containerClassNames}>
        {this.LevelIcons[alertLevel]}
        <Text size={textSize} className={textClassNames} lineHeight={100}>
          {displayLevel}
        </Text>
      </div>
    );
  }
}
