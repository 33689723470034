import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { mergeProps, mergeRefs } from '@react-aria/utils';
import React, { forwardRef, useRef } from 'react';

import { compose, css, useContextProps, useStyleProps } from '..';
import { ButtonContext, ButtonIconProvider } from './button-provider';
import * as styles from './button.css';

import type { AriaButtonProps } from '@react-types/button';
import type { PropsWithChildren, RefObject } from 'react';
import { Loading } from 'design-components/icon';
import type { HTMLProps } from '..';
import { ButtonStyleProps, buttonStyleProps } from '../theme/theme';
import type { ButtonProviderProps } from './button-provider';
import type { ButtonVariants } from './button.css';

export type ButtonProps = PropsWithChildren<
  AriaButtonProps<'button'> & ButtonStyleProps & ButtonVariants & HTMLProps<HTMLButtonElement>
>;

/**
 * @name
 * Button
 *
 * @description
 * The <button> HTML element is an interactive element activated by
 * a user. Once activated, it then performs a programmable action,
 * such as submitting a form or opening a dialog.
 *
 * @see
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
 *
 * @example
 * <Button onPress={() => alert('i love turtles')}>
 *   Click me
 * </Button>
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
  const contextProps = useContextProps<ButtonProps & ButtonProviderProps>(ButtonContext, props);

  const {
    buttonRef = null,
    children,
    onlyIcon,
    emphasis,
    isDisabled,
    isLoading,
    kind = 'accent',
    size = 'medium',
    fitContent,
  } = contextProps;
  ref = mergeRefs(buttonRef, ref) || useRef(null);

  const { buttonProps, isPressed } = useButton(
    { ...contextProps, isDisabled: isDisabled || !!isLoading },
    ref as RefObject<HTMLButtonElement>,
  );

  const { styleProps } = useStyleProps(props, buttonStyleProps);
  const { hoverProps, isHovered } = useHover({ isDisabled: isDisabled || !!isLoading });
  const { focusProps, isFocusVisible } = useFocusRing(props);
  const mergedButtonProps = mergeProps(buttonProps, hoverProps, focusProps);

  return (
    <button
      {...mergedButtonProps}
      className={compose(
        css(styles.reset, styles.button),
        styles.variants({
          onlyIcon,
          fitContent,
          emphasis,
          kind,
          size,
          isDisabled,
          isFocused: isFocusVisible,
          isHovered,
          isPressed,
          isLoading,
        }),
        css(styleProps),
      )}
      ref={ref}
    >
      <ButtonIconProvider size={size}>
        {isLoading ? (
          <>
            <span className={css(styles.loadingIcon)()}>
              <Loading />
            </span>
            {!onlyIcon && children}
          </>
        ) : (
          <>{children}</>
        )}
      </ButtonIconProvider>
    </button>
  );
});
