import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '@good/components';

export const ServiceAgreementServiceBreadCrumbs = ({
  userId,
  displayName,
}: {
  userId: string;
  displayName: string;
}) => {
  const { t } = useTranslation('', { keyPrefix: 'serviceAgreements.service' });

  return (
    <Breadcrumbs>
      <Link to='/customers'>{t('labels.customers')}</Link>
      <Link to={`/customer/details/${userId}`}>{displayName}</Link>
      <Link to='#'>{t('labels.serviceAgreement')}</Link>
    </Breadcrumbs>
  );
};
