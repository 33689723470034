import React from 'react';
import { Text, Button } from '@good/ui/core';
import { Link } from '@good/components';
import { Edit } from '@good/icons';

import { useInlineForm } from './inline-form-context';

export const HeaderButtons = () => {
  const { disabled, hasAsyncTasks, isEditing, isSubmitting, onEdit, onCancel, editId } = useInlineForm();

  if (isEditing) {
    return (
      <div className='flex gap-2'>
        <Button onClick={onCancel} variant='outline' color='neutral' size='md' disabled={hasAsyncTasks || isSubmitting}>
          Cancel
        </Button>
        <Button type='submit' variant='filled' color='accent' size='md' disabled={hasAsyncTasks} loading={isSubmitting}>
          Save
        </Button>
      </div>
    );
  }

  return (
    <Link emphasis='no-underline' isDisabled={disabled}>
      <button onClick={onEdit} className='inline' type='button' id={editId}>
        <div className='flex h-full items-center justify-center'>
          <Text size='lg'>
            <Edit />
          </Text>
          <Text className='font-semibold ml-1' size='sm'>
            Edit
          </Text>
        </div>
      </button>
    </Link>
  );
};
