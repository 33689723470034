import React from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { Col, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

interface IConfirmSaveFormModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: () => void;
  isEditingForm?: boolean;
  isInuse?: boolean; //todo: TBD after having the workflow req
}

function ConfirmSaveFormModal({ isOpen, onCancel, onSave, isEditingForm, isInuse }: IConfirmSaveFormModalProps) {
  const content =
    isEditingForm && isInuse ? (
      <>
        <Paragraph>
          This form is in use in active workflows. Editing this form will create a new version of both the form and the
          workflows this form is deployed to. Currently in progress workflows will not be affected. Are you sure you
          want to proceed
        </Paragraph>
      </>
    ) : isEditingForm ? (
      <>
        <Paragraph>Doing so will create a new version of this form.</Paragraph>
        <Paragraph>Are you sure you want to save the changes made to this form?</Paragraph>
      </>
    ) : (
      <>
        <Paragraph>Are you sure you want to save this form?</Paragraph>
      </>
    );

  const title = isEditingForm ? 'Save changes' : 'Save form';
  return (
    <ActionModal verticalAlignment="high" width="small" isOpen={isOpen} title={title} onClose={onCancel}>
      {content}
      <ActionModalFooter className={'mt-large '}>
        <Row type={'flex'} className={'justify-end'}>
          <Col className="mr-medium">
            <SecondaryButton size="large" onClick={onCancel}>
              Cancel
            </SecondaryButton>
          </Col>
          <Col>
            <PrimaryButton onClick={onSave} size="large">
              Save {isEditingForm && 'changes'}
            </PrimaryButton>
          </Col>
        </Row>
      </ActionModalFooter>
    </ActionModal>
  );
}

export default ConfirmSaveFormModal;
