import { AvailabilityStatusTag } from 'common-components/tags';
import { Text } from 'common-components/typography';
import moment from 'moment-timezone';
import React from 'react';
import {
  AvailabilityUnavailabilityRequestModalType,
  AvailabilityUnavailabilityRequestTabType,
  UnavailabilityType,
} from 'utilities/enum-utils';

interface IAvailabilityUnavailabilityCardProps {
  tabType: AvailabilityUnavailabilityRequestTabType;
  type: AvailabilityUnavailabilityRequestModalType;
  cardIndex: number;
  cardItem: any;
  isSelected: boolean;
  onClick: (value) => void; //this function will change the selected item that reflect style and content on the right panel
}

function AvailabilityUnavailabilityCard({
  isSelected,
  onClick,
  cardIndex,
  cardItem,
  tabType,
  type,
}: IAvailabilityUnavailabilityCardProps) {
  function _handleOnCardClick() {
    onClick(cardIndex);
  }

  let [startDate, endDate] = ['', ''];
  let [startTime, endTime] = ['', ''];
  if (type === AvailabilityUnavailabilityRequestModalType.UNAVAILABILITY) {
    [startDate, endDate] = [cardItem.unavailabilityRequest.startDate, cardItem.unavailabilityRequest.endDate];
    [startTime, endTime] = [cardItem.unavailabilityRequest.startTime, cardItem.unavailabilityRequest.endTime];
  }

  let statusString = '';

  switch (tabType) {
    case AvailabilityUnavailabilityRequestTabType.UNAVAILABILITY_REQUESTS:
    case AvailabilityUnavailabilityRequestTabType.AVAILABILITY_REQUESTS: {
      statusString = 'Created';
      break;
    }
    case AvailabilityUnavailabilityRequestTabType.APPROVED: {
      statusString = 'Approved';
      break;
    }
    case AvailabilityUnavailabilityRequestTabType.DECLINED: {
      statusString = 'Declined';
      break;
    }
    case AvailabilityUnavailabilityRequestTabType.CANCELLED: {
      statusString = 'Canceled';
      break;
    }
    default: {
      break;
    }
  }

  let isOneOffAllday: boolean;
  type === AvailabilityUnavailabilityRequestModalType.UNAVAILABILITY &&
    cardItem.unavailabilityRequest.unavailabilityType === UnavailabilityType.ONE_OFF &&
    (isOneOffAllday = cardItem.unavailabilityRequest.isAllDay);

  return (
    <div
      className={`pv-12 ph-medium rounded-big mb-medium ${
        isSelected ? 'bg-info-blue bordered  border-info-blue' : 'bg-white'
      }`}
      style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05)', cursor: 'pointer' }}
      onClick={_handleOnCardClick}
    >
      <div className="flex-column justify-between">
        <div className="flex-row align-center mb-12">
          {type === AvailabilityUnavailabilityRequestModalType.AVAILABILITY ? (
            <Text className="text-size-large mr-12">General availability</Text>
          ) : (
            <>
              <Text className="text-size-large mr-12">Unavailability</Text>
            </>
          )}
          <AvailabilityStatusTag status={cardItem.status} size={'small'} />
        </div>
        {type === AvailabilityUnavailabilityRequestModalType.UNAVAILABILITY && (
          <>
            <Text className="text-size-medium mr-12" weight="bold">
              {moment.tz(startDate, cardItem.timezone).format('ddd, DD MMM YYYY')} -{' '}
              {moment.tz(endDate, cardItem.timezone).format('ddd, DD MMM YYYY')}
            </Text>
            <Text className="text-size-medium mr-12">
              {cardItem.unavailabilityRequest.unavailabilityType === UnavailabilityType.CUSTOM ? (
                'Custom time'
              ) : isOneOffAllday ? (
                'Unavailable all day'
              ) : (
                <>
                  {moment.tz(startTime, cardItem.timezone).format('hh:mm a')} -{' '}
                  {moment.tz(endTime, cardItem.timezone).format('hh:mm a')}
                </>
              )}
            </Text>
            {cardItem.unavailabilityRequest.comment && (
              <div className="description text-color-secondary text-size-regular">
                {cardItem.unavailabilityRequest.comment}
              </div>
            )}
          </>
        )}
      </div>
      {/* //todo: change with proper data */}
      <div className="description mt-small text-color-secondary text-size-regular">
        {statusString} by <b>{cardItem.updatedBy}</b> on{' '}
        <b>{moment.tz(cardItem.updatedDate, cardItem.timezone).format('DD MMM YYYY, hh:mm A')}</b>
      </div>
    </div>
  );
}

export default AvailabilityUnavailabilityCard;
