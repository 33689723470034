import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IGroupServiceTeamMember } from 'interfaces/service-interfaces';

interface IDeleteTeamMemberFromRosterModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedTeamMember: IGroupServiceTeamMember;
  onRemoveMember: () => void;
}

interface IDeleteTeamMemberFromRosterModalState {
  isOpen: boolean;
  step: number;
}

class DeleteTeamMemberFromRosterModal extends Component<
  IDeleteTeamMemberFromRosterModalProps,
  IDeleteTeamMemberFromRosterModalState
> {
  state = {
    isOpen: false,
    step: 1,
  };

  private _onNextStep = () => {
    this.setState({ step: 2 });
    this.props.onRemoveMember();
  };

  private _onCloseModal = () => {
    this.setState({ step: 1 });
    this.props.onClose();
  };

  render() {
    const { selectedTeamMember } = this.props;
    const { step } = this.state;
    const canRemoveMember = selectedTeamMember && selectedTeamMember.upcomingShiftSlotCount === 0;
    const fullName = selectedTeamMember
      ? `${selectedTeamMember.teamMemberFirstName} ${selectedTeamMember.teamMemberLastName}`
      : '';
    const title = !canRemoveMember
      ? 'Warning'
      : step === 1
      ? 'Remove team member from roster'
      : 'Team member successfully removed from roster';

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        onClose={this._onCloseModal}
        showCloseButton={true}
        width="medium"
        className="p-small"
        title={title}
      >
        {!canRemoveMember ? (
          <div className="flex-column anim-slide-left">
            <Text className="mb-small">
              <span className="text-weight-bold">{fullName}</span> cannot be removed from the roster as he/she is
              assigned to upcoming sessions.
            </Text>
            <Text>
              You must remove this team member from any upcoming sessions before you can remove them from the roster.
            </Text>
            <ActionModalFooter className="mt-large">
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        ) : step === 1 ? (
          <div className="flex-column anim-slide-left">
            <Text className="mb-small">
              Are you sure you want to remove <span className="text-weight-bold">{fullName}</span> from the roster for
              this service?{' '}
            </Text>
            <Text> Any assigned shifts will automatically be removed.</Text>
            <ActionModalFooter className="mt-large">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onNextStep}>
                Remove from roster
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        ) : (
          <div className="flex-column anim-slide-left">
            <Text>
              You have successfully removed <span className="text-weight-bold">{fullName}</span> from this roster.
            </Text>{' '}
            <ActionModalFooter className="mt-large">
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

export default DeleteTeamMemberFromRosterModal;
