import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { IServiceAgreement } from 'interfaces/customer-interfaces';
import { ServiceAgreementStatus, ServiceType } from 'utilities/enum-utils';
import ViewEditQuoteView from 'views/customers/details/tabs-panel/new-service-agreements/components/ViewEditQuoteView';
import _ from 'lodash';
import { Text } from 'common-components/typography';

interface IEditQuoteModalProps {
  closeEdit: () => void;
  isOpen: boolean;
  serviceAgreement: IServiceAgreement;
  doUpdateQuotation: typeof dispatch.customersStore.doUpdateQuotation;
}

interface IEditQuoteModalState {
  isLoading: boolean;
  isProceedModalOpen: boolean;
  quotations: any;
}

class EditQuoteModal extends Component<IEditQuoteModalProps, IEditQuoteModalState> {
  state = {
    isLoading: false,
    isProceedModalOpen: false,
    quotations:
      this.props.serviceAgreement && this.props.serviceAgreement.quotations
        ? this.props.serviceAgreement.quotations
        : [],
  };

  private _closeModal = () => {
    this.setState({ isLoading: false, isProceedModalOpen: false, quotations: null });
    this.props.closeEdit();
  };

  private _deleteServiceSchedule = (serviceId) => {
    this.setState({
      quotations: _.filter(this.state.quotations, (quotation) => quotation.serviceId !== serviceId),
    });
  };

  private _openProceedModal = () => {
    this.setState({ isProceedModalOpen: true });
  };
  private _closeProceedModal = () => {
    this.setState({ isProceedModalOpen: false });
  };

  private _addEditQuotation = (quotation) => {
    let newQuotations = _.clone(this.state.quotations);
    if (!_.find(this.state.quotations, (existingQuotation) => quotation.serviceId === existingQuotation.serviceId)) {
      newQuotations.push(quotation);
    } else {
      newQuotations = _.map(newQuotations, (existingQuotation) => {
        if (quotation.serviceId === existingQuotation.serviceId) {
          return { ...quotation };
        } else {
          return { ...existingQuotation };
        }
      });
    }
    this.setState({ quotations: newQuotations });
  };

  private _saveQuote = async () => {
    const { quotations } = this.state;
    this.setState({ isLoading: true });
    await this.props.doUpdateQuotation({
      serviceAgreementId: this.props.serviceAgreement.userServiceAgreementId,
      quotations: _.map(quotations, (quotation) => {
        return {
          ...quotation,
          isGroupService: quotation.isGroupService || quotation.serviceType === ServiceType.GROUP,
          additionalCost: Number(quotation.additionalCost),
        };
      }),
    });
    this._closeModal();
  };

  componentDidUpdate(
    prevProps: Readonly<IEditQuoteModalProps>,
    prevState: Readonly<IEditQuoteModalState>,
    snapshot?: any,
  ) {
    const { serviceAgreement } = this.props;
    if (prevProps.serviceAgreement !== serviceAgreement || (!prevProps.isOpen && this.props.isOpen)) {
      this.setState({
        quotations: serviceAgreement.quotations,
      });
    }
  }

  render() {
    const { isOpen, serviceAgreement } = this.props;
    const { quotations } = this.state;

    return (
      <>
        <ActionModal
          isOpen={this.state.isProceedModalOpen}
          onClose={this._closeProceedModal}
          title={'Editing a signed service agreement'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You are making a change to a <b>signed</b> service agreement. Doing so will revert the status of this
            service agreement to <b>unsigned. Are you sure you want to make this changes?</b>
          </Text>
          <br />
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._saveQuote}>
              Save changes
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={isOpen}
          onClose={this._closeModal}
          title={'Edit Quote'}
          showCloseButton={true}
          width={'x2-large'}
        >
          <Text>
            Edit the schedule for this customer and we'll provide an accurate quote for the services being delivered.
          </Text>
          <ViewEditQuoteView
            displayMode={'EDIT'}
            deleteServiceSchedule={this._deleteServiceSchedule}
            quotations={quotations}
            addEditQuotation={this._addEditQuotation}
            availableServices={serviceAgreement && serviceAgreement.services}
            paymentSourceType={serviceAgreement && serviceAgreement.paymentSourceType}
            isNewServiceAgreement={false}
            userServiceAgreementId={serviceAgreement && serviceAgreement.userServiceAgreementId}
          />
          <ActionModalFooter>
            <SecondaryButton size="large" onClick={this._closeModal} className="mr-medium">
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size="large"
              onClick={
                serviceAgreement && serviceAgreement.signedStatus === ServiceAgreementStatus.SIGNED
                  ? this._openProceedModal
                  : this._saveQuote
              }
              loading={this.state.isLoading}
            >
              Save
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateQuotation: dispatch.customersStore.doUpdateQuotation,
});

export default connect(null, mapDispatch)(EditQuoteModal);
