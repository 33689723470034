import { Inline, Split, Stack } from '@goodhuman-me/components';
import { Icon, Spin } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  subtitle?: JSX.Element | JSX.Element[];
  description?: string;
  actionLabelText: string;
  actionLabelColor?: 'blue-action';
  isActionLabelLoading?: boolean;
  onClick?: () => void;
  href?: string;
}

const ConfigCard = ({
  title,
  subtitle,
  description,
  actionLabelText,
  actionLabelColor,
  isActionLabelLoading = false,
  onClick,
  href,
}: Props) => {
  return (
    <LinkWrapper href={href} onClick={onClick}>
      <div className="bordered-top border-standard-gray hover-bg-quaternary">
        <Split fraction="three-quarters" paddingX="$small" paddingY="$medium" gap="$small">
          <Stack gap="$xsmall">
            <Text weight="bold">{title}</Text>
            {subtitle && <div>{subtitle}</div>}
            <Text color="secondary">{description}</Text>
          </Stack>
          <Inline alignItems="center" justifyContent="right">
            {isActionLabelLoading ? (
              <Spin />
            ) : (
              actionLabelText && <Text color={actionLabelColor}>{actionLabelText}</Text>
            )}
            <Icon type="right" className="ml-small" />
          </Inline>
        </Split>
      </div>
    </LinkWrapper>
  );
};

export default ConfigCard;

const LinkWrapper = ({
  href,
  onClick,
  children,
}: {
  href: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  children: JSX.Element | JSX.Element[];
}) => {
  return href ? (
    <Link to={href} className="underline-none" onClick={onClick}>
      {children}
    </Link>
  ) : (
    <a className="underline-none" onClick={onClick}>
      {children}
    </a>
  );
};
