import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Question = forwardRef(function Question(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM13 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-2.25-6a1.25 1.25 0 0 1 2.5 0v.121c0 .364-.144.713-.402.97L11.47 12.47a.75.75 0 1 0 1.06 1.06l1.38-1.378a2.871 2.871 0 0 0 .84-2.03V10a2.75 2.75 0 1 0-5.5 0v.5a.75.75 0 0 0 1.5 0V10Z"
        fill="currentColor"
      />
    </Icon>
  );
});
