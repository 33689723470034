import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LIMIT_MAX_CHARACTERS } from 'views/team-v2/utils/constants';
import { Member, MESSAGE_ERRORS, regexMobile } from '../../utils/form-utils';
import { FormComponents } from '../components/FormComponents';
import { Group } from '@good/ui/core';
import { SelectPhoneCountryCode } from 'components';
import { validatePhoneNumber } from 'utilities/validate-phone-number';

interface IProps {
  memberDetails: Member;
}

const PhoneNumberColumn: React.FC<IProps> = ({ memberDetails }) => {
  const { register, control, trigger, getValues } = useFormContext();
  const index = memberDetails.index;

  return (
    <Group wrap='nowrap' gap={4}>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        name={`teamMemberList.${index}.mobileCountryCode`}
        render={({ field: { onChange, onBlur, value } }) => (
          <SelectPhoneCountryCode value={value} onChange={onChange} onBlur={onBlur} variant='unstyled' pl='sm' />
        )}
      />
      <FormComponents.TextFieldItem
        name='mobile'
        placeholder='Mobile'
        control={control}
        gap={4}
        {...register(`teamMemberList.${index}.mobile` as const, {
          required: MESSAGE_ERRORS.MOBILE,
          pattern: {
            value: regexMobile,
            message: MESSAGE_ERRORS.MOBILE,
          },
          validate: (value) =>
            validatePhoneNumber(value, {
              countryCode: getValues()['teamMemberList'][index].mobileCountryCode,
            })
              ? true
              : MESSAGE_ERRORS.MOBILE,
          onBlur: () => trigger(`teamMemberList.${index}.mobile`),
        })}
        maxLength={LIMIT_MAX_CHARACTERS}
      />
    </Group>
  );
};

export default PhoneNumberColumn;
