import React from 'react';
import { Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import { TeamMemberCustomerRatio } from 'utilities/enum-utils';
import { EditRatiosSchema } from './schema';

interface IRatioColumnProps {
  index: number;
}

export function RatioColumn({ index }: IRatioColumnProps) {
  const form = useFormContext<EditRatiosSchema>();

  return (
    <div style={{ width: 115 }}>
      <Controller
        name={`ratios.${index}.teamMemberCustomerRatio`}
        control={form.control}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select onChange={onChange} style={{ width: '100%' }} size="large" value={value} onBlur={onBlur} ref={ref}>
            {Object.values(TeamMemberCustomerRatio).map((ratio_) => {
              return (
                <Select.Option value={ratio_} key={ratio_}>
                  {ratio_}
                </Select.Option>
              );
            })}
          </Select>
        )}
      />
    </div>
  );
}
