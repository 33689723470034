import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Minimize = forwardRef(function Minimize(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 4a.75.75 0 0 0-1.5 0v1c0 .964-.002 1.612-.066 2.095-.063.461-.17.659-.3.789-.13.13-.328.237-.79.3-.482.064-1.13.066-2.094.066H3a.75.75 0 0 0 0 1.5h2.052c.899 0 1.648 0 2.243-.08.627-.084 1.194-.27 1.65-.725.455-.456.64-1.023.725-1.65.08-.595.08-1.345.08-2.243V4ZM5 15.75c.964 0 1.612.002 2.095.066.461.063.659.17.789.3.13.13.237.328.3.79.064.482.066 1.13.066 2.094v1a.75.75 0 0 0 1.5 0v-1.052c0-.899 0-1.648-.08-2.242-.084-.628-.27-1.195-.725-1.65-.456-.456-1.023-.642-1.65-.726-.595-.08-1.344-.08-2.243-.08H3a.75.75 0 0 0 0 1.5h2Zm10-12.5a.75.75 0 0 1 .75.75v1c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.788.3.483.064 1.131.066 2.095.066h2a.75.75 0 0 1 0 1.5h-2.052c-.898 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.725-.456-.456-.642-1.023-.726-1.65-.08-.595-.08-1.345-.08-2.243V4a.75.75 0 0 1 .75-.75Zm4 11h-.052c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.726-.456.455-.642 1.022-.726 1.65-.08.594-.08 1.343-.08 2.242V20a.75.75 0 1 0 1.5 0v-1c0-.964.002-1.612.067-2.095.062-.461.169-.659.3-.789.13-.13.327-.237.788-.3.483-.064 1.131-.066 2.095-.066h2a.75.75 0 0 0 0-1.5h-2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
