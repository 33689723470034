import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FlagTriangle = forwardRef(function FlagTriangle(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1.25a.75.75 0 0 1 .75.75v1.471l9.089 3.246.058.021c.893.32 1.641.586 2.165.862.513.27 1.096.694 1.142 1.448.045.755-.482 1.245-.96 1.575-.486.337-1.197.692-2.045 1.116l-.056.028-9.393 4.697V22a.75.75 0 0 1-1.5 0V2A.75.75 0 0 1 6 1.25Zm.75 13.537 8.723-4.362c.918-.459 1.53-.767 1.919-1.036.178-.123.26-.204.295-.25a1.421 1.421 0 0 0-.323-.212c-.418-.22-1.063-.452-2.03-.797L6.75 5.064v9.723Zm10.968-5.608-.006-.012.006.012Zm-.01-.07c.002-.008.004-.012.005-.012 0 0 0 .004-.005.012Z"
        fill="currentColor"
      />
    </Icon>
  );
});
