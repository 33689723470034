import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Check = forwardRef(function Check(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.507 5.447a.75.75 0 0 1 .046 1.06l-11 12a.75.75 0 0 1-1.083.023l-5-5a.75.75 0 1 1 1.06-1.06l4.447 4.446 10.47-11.423a.75.75 0 0 1 1.06-.046Z"
        fill="currentColor"
      />
    </Icon>
  );
});
