import React from 'react';
import { Text } from '@good/components';
import clsx from 'clsx';

type Props = {
  className?: string;
  children: React.ReactNode;
} & React.ComponentPropsWithRef<typeof Text>;

export const CustomerNDISBadge = ({ children, className, ...props }: Props): JSX.Element => (
  <Text
    size="2xs"
    className={clsx('w-fit rounded-full bg-[#6773a7] px-2 py-1 text-center font-medium text-white', className)}
    {...props}
  >
    NDIS NO. {children}
  </Text>
);

CustomerNDISBadge.displayName = 'CustomerNDISBadge';
