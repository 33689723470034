import z from 'zod';

import apiClient from 'utilities/api-client';

/**
 * @name
 * fetchCheckFlowNameExists
 *
 * @description
 * Check if a workflow name already exists.
 *
 * @example
 * let doesExist = await fetchCheckWorkflowNameExists({ name: 'Test' })
 */
export async function fetchCheckWorkflowNameExists(name: string): Promise<boolean> {
  return apiClient
    .post(`/api/portal/workflow-templates/check-exist`, { name })
    .then(({ data }) => data)
    .then(({ isExisted }) => isExisted);
}

/**
 * @name
 * fetchPutWorkflow
 */
export async function fetchUpdateWorkflow(props) {
  let { workflowId, ...payload } = props;
  return apiClient.put(`/api/portal/workflow-templates/${workflowId}/configurations`, payload).then(({ data }) => data);
}

/********************************************************************
 * fetchCreateWorkflowTemplate
 *******************************************************************/

const createStepPayloadSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  stepNumber: z.number(),
  approvalType: z.literal('SPECIFIC').optional().default('SPECIFIC'),
  invalid: z.boolean().optional().default(false),
  type: z.enum(['STEP', 'TRIGGER']).optional().default('STEP'),
  // form
  formId: z.string().uuid().optional(),
  formName: z.string().optional(),
  formVersionId: z.string().uuid().optional(),
  // attachment
  attachment: z
    .object({
      file: z.object({
        uid: z.string(),
      }),
      name: z.string(),
      description: z.string().optional(),
    })
    .optional(),
  // approvers
  stepApprovers: z
    .array(
      z.object({
        displayName: z.string(),
        avatar: z.string().url().or(z.literal('')),
        approverUserId: z.string().uuid(),
      }),
    )
    .optional()
    .default([]),
});

const createWorkflowPayloadSchema = z.object({
  name: z.string(),
  // description: z.string().optional(),
  // workflowTemplateType: z.literal('PLATFORM').optional().default('PLATFORM'),
  // triggerType: z.literal('INCIDENT_NOTE').default('INCIDENT_NOTE'),
  // isAllowApproverFlagIssue: z.boolean(),
  // isNotifyWorkflowCompleted: z.boolean(),
  // isNotifyWorkflowFlagged: z.boolean(),
  // steps: z.array(createStepPayloadSchema),
});

type CreateWorkflowPayload = z.infer<typeof createWorkflowPayloadSchema>;

export async function fetchCreateWorkflowTemplate(payload: CreateWorkflowPayload) {}

// doUpdateWorkflowTemplateConfigurations: async (payload, rootState: IRootState) => {
//   try {
//     const { selectedWorkflowTemplate } = rootState.workflowStore;

//     const endPoint = `/api/portal/workflow-templates/${selectedWorkflowTemplate.workflowTemplateId}/configurations`;

//     const response = await apiClient.put(endPoint, {
//       ...selectedWorkflowTemplate,
//       ...payload,
//     });

//     if (response.status === 200) {
//       dispatch.workflowStore.setSelectedWorkflowTemplate({ ...selectedWorkflowTemplate, ...response.data });
//       return true;
//     }
//   } catch (e) {
//     return false;
//   }
// },

/**
 * @name
 * fetchAssignees
 *
 * @description
 * Fetch names of possible workflow step assignees.
 */
// export async function fetchAssignees(query: string) {
//   let payload = {
//     hasAppAccess: ['PORTAL'],
//     hasWorkflowPermission: true,
//     page: 1,
//     pageSize: 10,
//     pageTimestamp: new Date(),
//     supportWorkerStatus: [TeamStatus.ENABLED],
//     search: query,
//   };

//   let result = await apiClient.post(`/api/portal/support-worker/lite-list`, payload);

//   console.log({ result });

//   async function fetchWorkerListLite() {
//     const params = {
//       page: 1,
//       pageSize: 10,
//       pageTimestamp: new Date(),
//       search: '',
//     };

//     let result = await doFetchWorkerListLite({
//       ...params,
//       supportWorkerStatus: [TeamStatus.ENABLED],
//       hasWorkflowPermission: true,
//       hasAppAccess: ['PORTAL'],
//     });

//     //     const result = getWorkersByKeyword(query, workerListLite);
//   }

//   if (query.length >= 3 || query.length === 0) debounce(async () => {});

// if (query.length >= 3 || query.length === 0)
//   debounce(function() {
//     const { workerListLite } = this.props;

//     const params = {
//         page: 1,
//         pageSize: 10,
//         pageTimestamp: new Date(),
//         search: ''
//       };

//     let result = await doFetchWorkerListLite({
//         ...params,
//         supportWorkerStatus: [TeamStatus.ENABLED],
//         hasWorkflowPermission: true,
//         hasAppAccess: ['PORTAL']
//       });

//     const result = getWorkersByKeyword(query, workerListLite);
//   }, 300);
// }

// async function() {

// }

// if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
//   const workerList = _.map(result.data, (item) => {
//     let firstName;
//     let lastName;
//     if (item.firstName) {
//       firstName = item.firstName;
//       lastName = item.lastName;
//     } else {
//       firstName = item.firstName;
//       lastName = item.lastName;
//     }
//     return {
//       ...item,
//       firstName: firstName,
//       lastName: lastName,
//     };
//   });

//   if (payload.page > 1) {
//     dispatch.teamStore.setWorkerListLite([...rootState.teamStore.workerListLite, ...workerList]);
//   } else {
//     dispatch.teamStore.setWorkerListLite(workerList);
//   }
// } else {
//   dispatch.teamStore.setWorkerListLite([]);
// }
