import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IGroupServiceActivityGroup, IGroupServiceScheduleTimeSlot } from 'interfaces/service-interfaces';
import { IActivityGroupUsers } from 'interfaces/user-interfaces';
import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { ActivityGroupMemberType, ActivityGroupModalType } from 'utilities/enum-utils';
import SelectActivityGroups from '../../selectors/SelectActivityGroups';
import { Avatar, Col, notification, Row } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { timeZone } from 'interfaces/timezone-type';
import CommonUtils from 'utilities/common-utils';

interface IAssignToScheduleModalContentProps {
  serviceId: string;
  modalType: ActivityGroupModalType;
  memberType: ActivityGroupMemberType;
  activityGroup: IGroupServiceActivityGroup;
  selectedUser: IActivityGroupUsers;
  timeSlot?: IGroupServiceScheduleTimeSlot;
  onCloseViewModal: () => void;
  handleAction: (payload, actionType: ActivityGroupModalType) => Promise<boolean | void>;
  timeZone?: timeZone;
}

interface IAssignToScheduleModalContentState {
  isLoading: boolean;
  isDirty: boolean;
  addedActivityGroups: IGroupServiceActivityGroup[];
}

class AssignToScheduleModalContent extends Component<
  IAssignToScheduleModalContentProps,
  IAssignToScheduleModalContentState
> {
  state = {
    isLoading: false,
    isDirty: false,
    addedActivityGroups: [],
  };

  private _handleAlterStateWhenInteractWithSelectedItems = (addedItems: IGroupServiceActivityGroup[]) => {
    this.setState({ addedActivityGroups: addedItems, isDirty: true });
  };

  private _handleAssign = async () => {
    const { serviceId, selectedUser, modalType, memberType, timeSlot, handleAction } = this.props;
    const { isDirty, addedActivityGroups } = this.state;
    this.setState({ isLoading: true });
    try {
      const serviceActivityGroupIds = _.map(addedActivityGroups, (group) => group.serviceActivityGroupId);
      const updatedActivityGroups = _.chain(_.get(timeSlot, 'activityGroups', []))
        .map((activityGroup) =>
          Object.assign(activityGroup, {
            isAssignedTo: isDirty
              ? _.some(
                  addedActivityGroups,
                  (addItem) => addItem.serviceDateTimeActivityGroupId === activityGroup.serviceDateTimeActivityGroupId,
                )
              : activityGroup.isAssignedTo,
          }),
        )
        .value();
      const payload = {
        serviceId,
        userId: selectedUser?.userId,
        memberType,
        serviceActivityGroupIds,
        timeSlot,
        addedActivityGroups: updatedActivityGroups,
      };

      await handleAction(payload, modalType);
      this.props.onCloseViewModal();
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong! Please try again.',
      });
      console.log(e);
    }

    this.setState({ isLoading: false });
  };

  render() {
    const { serviceId, selectedUser, modalType, memberType, timeSlot, timeZone, onCloseViewModal } = this.props;
    const { upcomingSession, recurringPattern, startDateTime, endDateTime } = timeSlot;
    // rematch object to reuse common component
    const groupActivityGroups = _.map(_.get(timeSlot, 'activityGroups', []), (activityGroup) =>
      Object.assign(activityGroup, {
        serviceDateTimeActivityGroupId: _.get(
          activityGroup,
          'serviceScheduleActivityGroupId',
          activityGroup.serviceDateTimeActivityGroupId,
        ),
      }),
    );
    const selectedActivityGroups = _.chain(groupActivityGroups)
      .filter((activityGroup) => activityGroup.isAssignedTo)
      .value();

    const timeSlotStart = moment.tz(startDateTime, timeZone);
    const timeSlotEnd = moment.tz(endDateTime, timeZone);
    return (
      <div className="width-full">
        <div className="bg-tertiary rounded pv-12 ph-medium block ">
          <Row className="">
            {selectedUser && (
              <Col span={8}>
                <div className="mb-small">
                  <Text className="text-uppercase" weight="bold" color="secondary">
                    {memberType === ActivityGroupMemberType.CUSTOMER ? 'SELECTED CUSTOMER' : 'SELECTED TEAM MEMBER'}
                  </Text>
                </div>
                <div>
                  <Avatar
                    size={32}
                    src={selectedUser.attachmentUrl}
                    shape={modalType === ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SESSION ? 'circle' : 'square'}
                  />{' '}
                  <Text className="ml-small" color="primary" size="medium">
                    {selectedUser.firstName + ' ' + selectedUser.lastName}
                  </Text>
                </div>
              </Col>
            )}
            <Col span={8}>
              <div className="mb-small">
                <Text className="text-uppercase" weight="bold" color="secondary">
                  SCHEDULE
                </Text>
              </div>
              <div>
                <Text size="large" weight="bolder">
                  {CommonUtils.getRecurringPatternLabel(recurringPattern)} - {timeSlotStart.format('dddd')}
                </Text>
              </div>
              <div>
                <Text size="large" color="secondary">
                  {timeSlotStart.format('hh:mm A')} - {timeSlotEnd.format('hh:mm A')}
                </Text>
              </div>
            </Col>
            <Col span={8}>
              <div className="mb-small">
                <Text className="text-uppercase" weight="bold" color="secondary">
                  UPCOMING SESSIONS
                </Text>
              </div>
              <div>
                <Text size="large" weight="bolder">
                  {upcomingSession && (
                    <Col>
                      <b>{upcomingSession}</b> sessions
                    </Col>
                  )}
                </Text>
              </div>
            </Col>
          </Row>
        </div>

        <SelectActivityGroups
          serviceId={serviceId}
          modalType={
            memberType === ActivityGroupMemberType.CUSTOMER
              ? ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP
              : ActivityGroupModalType.ASSIGN_TEAM_MEMBER_TO_ACTIVITY_GROUP
          }
          groupSessionActivityGroups={groupActivityGroups}
          selectedActivityGroups={selectedActivityGroups}
          isRenderSessionCard={true}
          onInteractWithAddedItems={this._handleAlterStateWhenInteractWithSelectedItems}
          timezone={timeZone}
        />
        <div className="flex justify-end mt-medium">
          <SecondaryButton size="large" color="secondary" className="mr-12" onClick={onCloseViewModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._handleAssign} loading={this.state.isLoading}>
            Save changes
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export default AssignToScheduleModalContent;
