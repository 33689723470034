import { Icon, Steps } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';
import { BookingStatus, BookingStatusString } from 'utilities/enum-utils';

import _ from 'lodash';

// render steps based on state - complete / active / incomplete / cancelled.

//region Step renderers
function activeStep(statusName) {
  return (
    <Steps.Step
      icon={<Icon type="check-circle" className="text-color-blue-action" theme="filled" />}
      title={<Text className="text-color-blue-action text-weight-bold">{_.capitalize(statusName)}</Text>}
    />
  );
}

function completeStep(statusName) {
  return (
    <Steps.Step
      icon={<Icon type="check-circle" className="text-color-tertiary" theme="filled" />}
      title={<Text className="text-color-secondary text-weight-bold">{_.capitalize(statusName)}</Text>}
    />
  );
}

function incompleteStep(statusName) {
  return (
    <Steps.Step
      icon={<div className="bordered rounded-full" style={{ height: '24px', width: '24px', borderWidth: 'medium' }} />}
      title={_.capitalize(statusName)}
    />
  );
}

function cancelledStep(statusName, subStatus = '') {
  return (
    <Steps.Step
      icon={<Icon type="check-circle" className="text-color-red" theme="filled" />}
      title={
        <div className="line-height-120">
          <Text className="text-color-red text-weight-bold" lineHeight={120}>
            {_.capitalize(statusName)} <br />
          </Text>
          <Text lineHeight={120} color="red">
            {subStatus}
          </Text>
        </div>
      }
    />
  );
}
//endregion

function renderByStatus(statusName, previousStatus = null) {
  const stepsToRender = statusLookup[statusName];

  // TODO - Cancelled steps & archived not implemented yet.
  if (
    _.includes(
      [
        BookingStatus.BUSINESS_CANCELLED,
        BookingStatus.CUSTOMER_CANCELLED,
        BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE,
        BookingStatus.CUSTOMER_CANCELLED_WITH_FEE,
        BookingStatus.REJECTED,
      ],
      statusName,
    )
  ) {
    // do cancelled / archived logic here.
  }

  return _.map(stepsToRender, (renderStep) => renderStep);
}

// Main component
export function BookingStatusSteps({
  bookingStatus,
  previousStatuses,
}: {
  bookingStatus?: BookingStatus | string;
  previousStatuses?: any;
}) {
  return (
    <Steps current={0} size="small" className="mt-x-small p-x-small">
      {renderByStatus(bookingStatus, previousStatuses)}
    </Steps>
  );
}

/* --------------- Status lookups ------------------- */
let statusLookup = {};

// Edit the following to determine how to render.
statusLookup[BookingStatus.PENDING] = [
  activeStep(BookingStatusString.PENDING),
  incompleteStep(BookingStatusString.ACCEPTED),
  incompleteStep(BookingStatusString.CONFIRMED),
  incompleteStep('In progress'),
  incompleteStep(BookingStatusString.COMPLETED),
];

statusLookup[BookingStatus.ACCEPTED] = [
  completeStep(BookingStatusString.PENDING),
  activeStep(BookingStatusString.ACCEPTED),
  incompleteStep(BookingStatusString.CONFIRMED),
  incompleteStep('In progress'),
  incompleteStep(BookingStatusString.COMPLETED),
];

statusLookup[BookingStatus.CONFIRMED] = [
  completeStep(BookingStatusString.PENDING),
  completeStep(BookingStatusString.ACCEPTED),
  activeStep(BookingStatusString.CONFIRMED),
  incompleteStep('In progress'),
  incompleteStep(BookingStatusString.COMPLETED),
];

statusLookup[BookingStatus.INPROGRESS] = [
  completeStep(BookingStatusString.PENDING),
  completeStep(BookingStatusString.ACCEPTED),
  completeStep(BookingStatusString.CONFIRMED),
  activeStep('In progress'),
  incompleteStep(BookingStatusString.COMPLETED),
];

statusLookup[BookingStatus.COMPLETED] = [
  completeStep(BookingStatusString.PENDING),
  completeStep(BookingStatusString.ACCEPTED),
  completeStep(BookingStatusString.CONFIRMED),
  completeStep('In progress'),
  activeStep(BookingStatusString.COMPLETED),
];

statusLookup[BookingStatus.BUSINESS_CANCELLED] = [
  completeStep(BookingStatusString.PENDING),
  completeStep(BookingStatusString.ACCEPTED),
  cancelledStep(BookingStatusString.BUSINESS_CANCELLED),
];

statusLookup[BookingStatus.CUSTOMER_CANCELLED] = [
  completeStep(BookingStatusString.PENDING),
  completeStep(BookingStatusString.ACCEPTED),
  cancelledStep(BookingStatusString.CUSTOMER_CANCELLED),
];

statusLookup[BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE] = [
  completeStep(BookingStatusString.PENDING),
  completeStep(BookingStatusString.ACCEPTED),
  cancelledStep(BookingStatusString.CUSTOMER_CANCELLED_WITHOUT_FEE),
];

statusLookup[BookingStatus.CUSTOMER_CANCELLED_WITH_FEE] = [
  completeStep(BookingStatusString.PENDING),
  completeStep(BookingStatusString.ACCEPTED),
  cancelledStep(BookingStatusString.CUSTOMER_CANCELLED_WITH_FEE),
];

statusLookup[BookingStatus.REJECTED] = [
  completeStep(BookingStatusString.PENDING),
  completeStep(BookingStatusString.ACCEPTED),
  cancelledStep(BookingStatusString.REJECTED),
];
