import React, { Component } from 'react';
import { Col, Icon, Row } from 'antd';
import { FieldLabel, Paragraph, Text, Title } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ServiceItemInputModal from 'views/customers/details/tabs-panel/new-service-agreements/common-modals/ServiceItemInputModal';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import ViewServiceAgreementsLineItemsModal from '../../common-modals/ViewServiceAgreementLineItemsModal';
import { MmmGroup, NonFaceToFaceFrequency, ServiceLocationType, ServiceType } from 'utilities/enum-utils';

interface IInputServiceItemsPanelProps {
  onPrevStep: any;
  onNextStep: any;
  newServiceAgreement: typeof state.customersStore.newServiceAgreement;
  setNewServiceAgreement: typeof dispatch.customersStore.setNewServiceAgreement;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
}

interface IInputServiceItemsPanelState {
  isLoading: boolean;
  isAddModalOpen: boolean;
  isViewModalOpen: boolean;
  agreementServiceItems: Array<any>;
  isEdit: boolean;
  selectedService: any;
  isDeleteModalOpen: boolean;
  errorEmptyList: boolean;
  locationMmmGroup: number;
  locationState: string;
}

class InputServiceItemsPanel extends Component<IInputServiceItemsPanelProps, IInputServiceItemsPanelState> {
  state = {
    isLoading: false,
    isAddModalOpen: false,
    isViewModalOpen: false,
    agreementServiceItems:
      this.props.newServiceAgreement && this.props.newServiceAgreement.services
        ? this.props.newServiceAgreement.services
        : [],
    isEdit: false,
    selectedService: null,
    isDeleteModalOpen: false,
    errorEmptyList: false,
    locationMmmGroup: MmmGroup.NonRemote,
    locationState: null,
  };

  private _onOpenAddModal = () => this.setState({ isEdit: false, selectedService: null, isAddModalOpen: true });
  private _onCloseAddModal = () => this.setState({ isAddModalOpen: false, isEdit: false });

  private _onOpenDeleteModal = (service) => this.setState({ selectedService: service, isDeleteModalOpen: true });
  private _onCloseDeleteModal = () => this.setState({ selectedService: null, isDeleteModalOpen: false });

  private _addNewService = (service) => {
    this.setState({ agreementServiceItems: [...this.state.agreementServiceItems, service], errorEmptyList: false });
  };
  private _editService = (service) => {
    this.setState({
      agreementServiceItems: _.map(this.state.agreementServiceItems, (existingService) => {
        if (existingService.serviceId === service.serviceId) {
          return { ...service };
        } else {
          return { ...existingService };
        }
      }),
    });
  };

  private _deleteService = (service) => {
    const { newServiceAgreement, setNewServiceAgreement } = this.props;
    this.setState({
      agreementServiceItems: _.filter(
        this.state.agreementServiceItems,
        (existingService) => service.serviceId !== existingService.serviceId,
      ),
    });
    if (newServiceAgreement && newServiceAgreement.quotations) {
      const newCalculatedQuotes = newServiceAgreement.quotations
        ? _.filter(newServiceAgreement.quotations, (quotation) => service.serviceId !== quotation.serviceId)
        : [];
      setNewServiceAgreement({
        ...newServiceAgreement,
        quotations: newCalculatedQuotes,
      });
    }
    this._onCloseDeleteModal();
  };

  private _openEditService = (service) => {
    this.setState({ isEdit: true, selectedService: service, isAddModalOpen: true });
  };

  private _openViewModal = (service) => {
    this.setState({ isViewModalOpen: true, selectedService: service });
  };

  private _onCloseViewModal = () => {
    this.setState({ isViewModalOpen: false, selectedService: null });
  };

  private _goToNextStep = () => {
    const { setNewServiceAgreement, newServiceAgreement, onNextStep } = this.props;
    const { agreementServiceItems } = this.state;
    this.setState({ isLoading: true });
    if (_.isEmpty(agreementServiceItems)) {
      this.setState({ errorEmptyList: true });
    } else {
      setNewServiceAgreement({
        ...newServiceAgreement,
        services: agreementServiceItems,
      });
      onNextStep();
    }
    this.setState({ isLoading: false });
  };

  private _setLocationGroupAndState = () => {
    const { selectedCustomer } = this.props;
    const { selectedService } = this.state;

    let locationMmmGroup = MmmGroup.NonRemote;
    let locationState = null;
    if (selectedService) {
      if (selectedService.serviceDirection === ServiceLocationType.FIXEDVENUE) {
        locationMmmGroup = selectedService.mmmGroup ? selectedService.mmmGroup : MmmGroup.NonRemote;
        locationState = selectedService.state;
      } else {
        const customerPrimaryAddress = _.find(selectedCustomer.addresses, (address) => address.isPrimary);
        if (customerPrimaryAddress && customerPrimaryAddress.state) {
          locationMmmGroup = customerPrimaryAddress.mmmGroup ? customerPrimaryAddress.mmmGroup : MmmGroup.NonRemote;
          locationState = customerPrimaryAddress.state;
        }
      }
    }
    this.setState({ locationMmmGroup, locationState });
  };

  componentDidUpdate(
    prevProps: Readonly<IInputServiceItemsPanelProps>,
    prevState: Readonly<IInputServiceItemsPanelState>,
    snapshot?: any,
  ) {
    if (prevState.selectedService !== this.state.selectedService) {
      this._setLocationGroupAndState();
    }
  }

  render() {
    const { onPrevStep, newServiceAgreement } = this.props;
    const { agreementServiceItems, selectedService, locationMmmGroup, locationState } = this.state;

    const noItems = _.isEmpty(agreementServiceItems);

    return (
      <>
        <ActionModal
          isOpen={this.state.isDeleteModalOpen}
          onClose={this._onCloseDeleteModal}
          title={
            <>
              Delete <b>schedule of support</b> item
            </>
          }
          showCloseButton={true}
        >
          <Paragraph className={'mb-medium'}>This line item will be deleted from this service agreement.</Paragraph>
          <Paragraph className={'mb-medium'}>Do you want to proceed?</Paragraph>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseDeleteModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" color={'red'} onClick={() => this._deleteService(selectedService)}>
              Delete
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <ViewServiceAgreementsLineItemsModal
          selectedServiceLineItems={selectedService ? selectedService.lineItems : null}
          additionalChargeItems={selectedService ? selectedService.additionalCharges : null}
          isOpen={this.state.isViewModalOpen}
          onClose={this._onCloseViewModal}
          displayMode={'VIEW'}
          locationMmmGroup={locationMmmGroup}
          locationState={locationState}
          paymentSourceType={newServiceAgreement && newServiceAgreement.paymentSourceType}
        />
        <div className="anim-slide-left">
          <Title level={2}>
            <b>Select the services</b> you wish to provide under this Service Agreement
          </Title>
          <Text>Please add the services you wish to provide for this customer.</Text>

          <div className="mt-large mb-x2-large">
            {/* Content here*/}
            <div className="mb-medium">
              <HyperlinkButton onClick={this._onOpenAddModal}>
                <Icon type="plus" />
                &nbsp;Add service
              </HyperlinkButton>
            </div>

            <div className="bordered-bottom pb-medium">
              <Row gutter={8}>
                <Col span={6}>
                  <div className="ph-medium">
                    <FieldLabel text={'SERVICE NAME'} />
                  </div>
                </Col>
                <Col span={7}>
                  <div className="ph-medium">
                    <FieldLabel text={'LINE ITEM(S)'} />
                  </div>
                </Col>
                <Col span={7}>
                  <div className="ph-medium">
                    <FieldLabel text={'COMMENTS'} />
                  </div>
                </Col>
                <Col span={2} />
                <Col span={2} />
              </Row>
            </div>

            {noItems && (
              <div className="p-medium">
                <Text color={'secondary'}>No services added.</Text>
              </div>
            )}

            {!noItems && (
              <div>
                {_.map(agreementServiceItems, (agreementServiceItem, idx) => (
                  <AgreementServiceItem
                    service={agreementServiceItem}
                    openDeleteModal={this._onOpenDeleteModal}
                    openEditService={this._openEditService}
                    openViewModal={this._openViewModal}
                    key={idx}
                  />
                ))}
              </div>
            )}
            {this.state.errorEmptyList && (
              <Text color={'red-dark'} className={'ml-medium'}>
                Please add at least one one service to the list of services being provided to the customer.
              </Text>
            )}
          </div>
          <div className="mb-x2-large mt-x2-large flex-row justify-between">
            <SecondaryButton size="large" onClick={onPrevStep} className="mr-medium" icon="left" iconPosition={'left'}>
              Previous
            </SecondaryButton>

            <PrimaryButton
              size="large"
              onClick={this._goToNextStep}
              loading={this.state.isLoading}
              icon={'right'}
              iconPosition={'right'}
            >
              Next
            </PrimaryButton>
          </div>
        </div>
        <ServiceItemInputModal
          onClose={this._onCloseAddModal}
          isOpen={this.state.isAddModalOpen}
          addNewService={this._addNewService}
          editService={this._editService}
          isEdit={this.state.isEdit}
          existingServices={this.state.agreementServiceItems}
          serviceAgreementStartDate={newServiceAgreement && newServiceAgreement.startDate}
          serviceAgreementEndDate={newServiceAgreement && newServiceAgreement.endDate}
          selectedService={this.state.selectedService}
          paymentSourceType={newServiceAgreement && newServiceAgreement.paymentSourceType}
        />
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  newServiceAgreement: state.customersStore.newServiceAgreement,
  selectedCustomer: state.customersStore.selectedCustomer,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewServiceAgreement: dispatch.customersStore.setNewServiceAgreement,
});

export default connect(mapState, mapDispatch)(InputServiceItemsPanel);

function AgreementServiceItem({ service, openDeleteModal, openEditService, openViewModal }) {
  return (
    <div className="pv-medium evenodd">
      <Row gutter={16}>
        <Col span={6}>
          <div className="ph-medium">{service.serviceName}</div>
        </Col>
        <Col span={7}>
          <div className="ph-medium">
            {service.lineItems && service.lineItems.length > 0 ? (
              <HyperlinkButton onClick={() => openViewModal(service)}>
                {service.lineItems.length} line item
                {service.lineItems.length !== 1 ? 's' : ''} selected
              </HyperlinkButton>
            ) : (
              '0 line item selected'
            )}
            {service.serviceType === ServiceType.GROUP &&
            service.teamMemberCustomerRatio &&
            service.teamMemberCustomerRatio.ndis ? (
              <>
                <br />
                <b>{service.teamMemberCustomerRatio.ndis}</b> ratio
              </>
            ) : (
              ''
            )}
            {service.additionalCharges && service.additionalCharges.isChargeNonFaceToFace ? (
              <>
                <br />
                <b>{service.additionalCharges.nonFaceToFaceMinutes} minutes</b> non face-to-face per{' '}
                {service.additionalCharges.nonFaceToFaceFrequency || NonFaceToFaceFrequency.PER_BOOKING}
              </>
            ) : (
              ''
            )}
            {service.additionalCharges && service.additionalCharges.autoChargedBillingItems.length > 0 ? (
              <>
                <br />
                <b>{service.additionalCharges.autoChargedBillingItems.length}</b> additional line item
                {service.additionalCharges.autoChargedBillingItems.length > 1 ? 's ' : ' '}charged
              </>
            ) : (
              ''
            )}
          </div>
        </Col>
        <Col span={7}>
          <div className="ph-medium" title={service.comment ? service.comment : 'No comment.'}>
            <Paragraph ellipsis={true} className={'m-none'}>
              {service.comment ? service.comment : '-'}
            </Paragraph>
          </div>
        </Col>
        <Col span={2}>
          <HyperlinkButton onClick={() => openEditService(service)}>
            Edit <Icon type={'edit'} />
          </HyperlinkButton>
        </Col>
        <Col span={2}>
          <HyperlinkButton color={'red-dark'} onClick={() => openDeleteModal(service)}>
            Delete <Icon type={'delete'} />
          </HyperlinkButton>
        </Col>
      </Row>
    </div>
  );
}
