import React, { useState, useEffect } from 'react';
import { notification } from 'antd';

import { APP_CONFIG } from '../config/app-config';

const { PRODUCTION_ENV: env } = APP_CONFIG.meta;

type NoticeErrorCustomAttributes = Record<string, newrelic.SimpleType> | undefined;

export type NewRelicBrowser =
  | (typeof newrelic & {
      noticeErrorWithToast: (error: string | Error, customAttributes?: NoticeErrorCustomAttributes) => void;
    })
  | undefined;

type WithNewRelicBrowserProps = {
  newRelicBrowser: NewRelicBrowser;
};

export const getNewRelicBrowser = () => {
  if (global.newrelic) {
    return global.newrelic;
  }

  console.warn('New Relic Browser not available yet. Try waitForNewRelicBrowser');
  return undefined;
};

export const waitForNewRelicBrowser = async () => {
  return new Promise<typeof newrelic>((resolve) => {
    const interval = setInterval(() => {
      const newRelicBrowser = global.newrelic;

      if (newRelicBrowser) {
        clearInterval(interval);
        resolve(newRelicBrowser);
      }
    }, 100);
  });
};

export function WithNewRelicBrowser<P>(WrappedComponent: React.ComponentType<P & WithNewRelicBrowserProps>) {
  const component = (props: P) => {
    const newRelicBrowser = useNewRelicBrowser();

    return <WrappedComponent newRelicBrowser={newRelicBrowser} {...props} />;
  };

  return component;
}

export function useNewRelicBrowser() {
  const [newRelicBrowser, setNewRelicBrowser] = useState<NewRelicBrowser>(undefined);

  useEffect(() => {
    if (window.newrelic) {
      const noticeErrorProxy = new Proxy(window.newrelic.noticeError, {
        apply: function (
          target,
          thisArg,
          argumentsList: [error: string | Error, customAttributes?: NoticeErrorCustomAttributes],
        ) {
          if (env !== 'production') {
            const [error] = argumentsList;
            console.error('Reported to new relic\n', error);
          }

          return target.apply(thisArg, argumentsList);
        },
      });

      const noticeErrorWithToast = (
        error: string | Error,
        customAttributes?: NoticeErrorCustomAttributes,
        description?: string,
        callback?: () => void,
      ) => {
        noticeErrorProxy(error, customAttributes);
        // TODO: Update with new toast API from payment config work
        notification.error({
          message: error.toString(),
          description,
        });
        callback?.();
      };

      setNewRelicBrowser({
        ...window.newrelic,
        noticeError: noticeErrorProxy,
        noticeErrorWithToast,
      });
    }
  }, [window.newrelic]);

  return newRelicBrowser;
}
