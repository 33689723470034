import React, { Component } from 'react';
import { SubTitle, Text } from 'common-components/typography';
import { Avatar, Col, Row } from 'antd';
import { IMatchingUser } from 'interfaces/customer-interfaces';

class FoundDuplicateRow extends Component<{
  matchingUser: IMatchingUser;
}> {
  render() {
    const { matchingUser } = this.props;

    return (
      <>
        <Row className="bordered-bottom p-small">
          <Col style={{ width: '50px' }} span={2}>
            <Avatar icon="user" size="large" shape="circle" src={matchingUser.attachmentUrl} />
          </Col>
          <Col className="flex-column">
            <div className="mb-x2-small">
              <Text weight="bold">
                {matchingUser.firstName} {matchingUser.lastName}
              </Text>
            </div>

            <div className="flex-row justify-between">
              {matchingUser.matchType === 'guardian' ? (
                <Text size={'regular'} color="secondary" className="text-capitalize">
                  legal guardian
                </Text>
              ) : matchingUser.matchType === 'customer' ? (
                <Text size={'regular'} color="secondary" className="text-capitalize">
                  Customer
                </Text>
              ) : (
                <></>
              )}
              <SubTitle color={'tertiary'} weight={'regular'}>
                NAME MATCH
              </SubTitle>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default FoundDuplicateRow;
