import React, { Component } from 'react';

import { Empty } from 'antd';

import { Text, Title } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import ViewEditQuoteView from 'views/customers/details/tabs-panel/new-service-agreements/components/ViewEditQuoteView';

interface IQuotesItemPanelProps {
  onPrevStep: any;
  onNextStep: any;
  portalUser: typeof state.authStore.portalUser;
  newServiceAgreement: typeof state.customersStore.newServiceAgreement;
  setNewServiceAgreement: typeof dispatch.customersStore.setNewServiceAgreement;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
}

interface IQuotesItemPanelState {
  isLoading: boolean;
  quotations: any;
}

class QuotesItemPanel extends Component<IQuotesItemPanelProps, IQuotesItemPanelState> {
  state = {
    isLoading: false,
    quotations:
      this.props.newServiceAgreement && this.props.newServiceAgreement.quotations
        ? this.props.newServiceAgreement.quotations
        : [],
  };

  private _deleteServiceSchedule = (serviceId) => {
    this.setState({
      quotations: _.filter(this.state.quotations, (quotation) => quotation.serviceId !== serviceId),
    });
  };

  private _goToNextStep = async () => {
    const { onNextStep } = this.props;
    this.setState({ isLoading: true });
    await this._setQuotationsData();
    onNextStep();
    this.setState({ isLoading: false });
  };

  private _backToPreviousStep = async () => {
    const { onPrevStep } = this.props;
    this.setState({ isLoading: true });
    await this._setQuotationsData();
    onPrevStep();
    this.setState({ isLoading: false });
  };

  private _setQuotationsData = async () => {
    const { setNewServiceAgreement, newServiceAgreement } = this.props;
    const { quotations } = this.state;
    await setNewServiceAgreement({
      ...newServiceAgreement,
      quotations,
    });
  };

  private _addEditQuotation = (quotation) => {
    let newQuotations = _.clone(this.state.quotations);
    if (!_.find(this.state.quotations, (existingQuotation) => quotation.serviceId === existingQuotation.serviceId)) {
      newQuotations.push(quotation);
    } else {
      newQuotations = _.map(newQuotations, (existingQuotation) => {
        if (quotation.serviceId === existingQuotation.serviceId) {
          return { ...quotation };
        } else {
          return { ...existingQuotation };
        }
      });
    }
    this.setState({ quotations: newQuotations });
  };

  render() {
    const { onPrevStep, newServiceAgreement } = this.props;
    const { quotations } = this.state;

    return (
      <>
        <div className="anim-slide-left">
          <Title level={2}>
            Create a <b>Quote</b> (Optional)
          </Title>
          <Text>
            Create a schedule for this customer and we'll provide an accurate quote for the services being delivered.
          </Text>

          <ViewEditQuoteView
            displayMode={'ADD'}
            deleteServiceSchedule={this._deleteServiceSchedule}
            availableServices={newServiceAgreement.services}
            quotations={quotations}
            addEditQuotation={this._addEditQuotation}
            paymentSourceType={newServiceAgreement.paymentSourceType}
            isNewServiceAgreement={true}
          />

          <div className="mb-x2-large flex-row justify-between mt-x2-large">
            <SecondaryButton
              size="large"
              onClick={this._backToPreviousStep}
              className="mr-medium"
              icon="left"
              iconPosition={'left'}
            >
              Previous
            </SecondaryButton>

            <PrimaryButton
              size="large"
              onClick={this._goToNextStep}
              loading={this.state.isLoading}
              icon={'right'}
              iconPosition={'right'}
            >
              Next
            </PrimaryButton>
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  newServiceAgreement: state.customersStore.newServiceAgreement,
  selectedCustomer: state.customersStore.selectedCustomer,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewServiceAgreement: dispatch.customersStore.setNewServiceAgreement,
});

export default connect(mapState, mapDispatch)(QuotesItemPanel);
