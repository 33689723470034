import { AlertTag } from 'common-components/tags';
import { AlertSeverity } from 'common-components/tags/AlertTag';
import React, { HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerAlertType } from 'utilities/enum-utils';

export type CustomerAlertTagProps = {
  alertType: CustomerAlertType;
  numberOfAlerts: number;
  onClickView: () => void;
  onClickIgnore: () => void;
} & Omit<HTMLProps<HTMLSpanElement>, 'className'>;

export const alertTypeToSeverity = (type: CustomerAlertType): AlertSeverity => {
  switch (type) {
    case CustomerAlertType.OverBudgetActual:
    case CustomerAlertType.DocumentExpired:
      return 'error';
    default:
      return 'warning';
  }
};

const alertTypeToTranslationMap: {
  [key in CustomerAlertType]: string;
} = {
  [CustomerAlertType.DocumentExpired]: 'DocumentExpired',
  [CustomerAlertType.DocumentExpiringSoon]: 'DocumentExpiringSoon',
  [CustomerAlertType.OverBudgetActual]: 'OverBudgetActual',
  [CustomerAlertType.OverBudgetForecasted]: 'OverBudgetForecasted',
  [CustomerAlertType.ServiceAgreementExpired]: 'ServiceAgreementExpired',
  [CustomerAlertType.ServiceAgreementExpiringSoon]: 'ServiceAgreementExpiringSoon',
};

const CustomerAlert: React.FC<CustomerAlertTagProps> = ({
  alertType,
  numberOfAlerts,
  onClickView,
  onClickIgnore,
  ...htmlProps
}) => {
  const { t } = useTranslation('', { keyPrefix: 'alerts.customer.description' });
  const severity: AlertSeverity = alertTypeToSeverity(alertType);

  return (
    <AlertTag
      severity={severity}
      onClickView={onClickView}
      onClickIgnore={onClickIgnore}
      text={t(alertTypeToTranslationMap[alertType], { count: numberOfAlerts })}
      {...htmlProps}
    />
  );
};

export default CustomerAlert;
