// Workflow Permissions
import React, { Component } from 'react';

import { PermissionRowBase } from 'views/account-management/add-new-member/panels/permissions-panel/portal/PermissionRowBase';
import { IPermissionRole, IPermissions } from 'interfaces/account-interfaces';
import WorkflowSectionModal from 'views/account-management/components/WorkflowSectionModal';
import { getWorkflowRole, getWorkflowPermissionName } from 'views/account-management/utils/permission-utils';
import { UserPermissionRole } from 'utilities/enum-utils';

interface WorkflowPermissionRowProps {
  permissions: IPermissions;
  onChangePermissions: any;
  actionsDisabled: boolean;
  hasAllAccess: boolean;
}

interface WorkflowPermissionRowState {
  workflowModalOpen: boolean;
}

class WorkflowPermissionRow extends Component<WorkflowPermissionRowProps, WorkflowPermissionRowState> {
  state = { workflowModalOpen: false };

  // Workflows
  onOpenWorkflowModal = () => this.setState({ workflowModalOpen: true });
  onCloseWorkflowModal = () => this.setState({ workflowModalOpen: false });

  // Triggered whenever a selection is made.
  onSelectWorkflow = (result) => {
    const { permissions, onChangePermissions } = this.props;
    const { permissionRoles } = permissions;

    // filter out payment permissions first
    const filteredRoles = permissionRoles.filter(
      (role) =>
        role.permissionRole !== UserPermissionRole.WorkflowBasic &&
        role.permissionRole !== UserPermissionRole.WorkflowElevated &&
        role.permissionRole !== UserPermissionRole.WorkflowManager,
    );

    // Remap back to UserPermissionRole
    const targetRole = getWorkflowRole(result.workflowPermission);

    const newWorkflowRole: IPermissionRole = { permissionRole: targetRole, serviceDepartmentId: null, serviceId: null };

    const updatedPermissions = { ...permissions, permissionRoles: [...filteredRoles, newWorkflowRole] };

    onChangePermissions(updatedPermissions);
  };

  render() {
    const { permissions } = this.props;

    const workflowPermissionName = getWorkflowPermissionName(permissions, this.props.hasAllAccess);

    return (
      <>
        {/* Display Row */}
        <PermissionRowBase
          iconName={'share-alt'}
          title={'Workflow'}
          description={'Access to workflows'}
          currentFlag={workflowPermissionName}
          onClickModify={this.onOpenWorkflowModal}
          actionsDisabled={this.props.actionsDisabled}
        />

        {/* Modify Edit dialog */}
        <WorkflowSectionModal
          isOpen={this.state.workflowModalOpen}
          onClose={this.onCloseWorkflowModal}
          setSelectedOption={this.onSelectWorkflow}
          permissions={permissions}
        />
      </>
    );
  }
}

export default WorkflowPermissionRow;
