import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Apperture = forwardRef(function Apperture(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.151 9.452a8.244 8.244 0 0 0-.4 2.548c0 1.154.236 2.253.664 3.25H8.5l-2.09-2.786a.73.73 0 0 1-.02-.028L4.15 9.452Zm6.224 5.798-2.447-3.263 2.284-3.237h3.413l2.461 3.282-2.082 3.218h-3.629Zm3.633-8H11.27l2.342-3.317a.758.758 0 0 0 .015-.022 8.254 8.254 0 0 1 5.118 3.339h-4.738Zm1.492 1.5 2.09 2.786.02.028 2.239 2.985c.26-.803.401-1.66.401-2.549a8.223 8.223 0 0 0-.665-3.25H15.5ZM4.85 7.883a8.247 8.247 0 0 1 7.055-4.132l-4.923 6.975-2.133-2.844Zm12.195 5.427-4.477 6.92a8.25 8.25 0 0 0 6.583-4.113l-2.106-2.807Zm-6.217 6.857a.743.743 0 0 1 .042-.074l2.163-3.343H5.254a8.248 8.248 0 0 0 5.574 3.417ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Z"
        fill="currentColor"
      />
    </Icon>
  );
});
