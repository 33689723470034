import React, { Fragment } from 'react';

import _ from 'lodash';
import { GridLine } from 'common-components/roster-control/roster-daily/timeline-pane/common/GridLine';
import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';

interface GridLinesProps {
  hours: any[];
  rowCount?: number;
}

export function Gridlines({ hours, rowCount = 0 }: GridLinesProps) {
  const { TIMELINE_ROW_HEIGHT, HOUR_BLOCK_WIDTH } = ROSTER_DAILY_CONFIG;

  const height = `${TIMELINE_ROW_HEIGHT * rowCount}px`;

  return (
    <>
      {_.map(hours, (hour, idx) => (
        <Fragment key={idx}>
          <GridLine type="solid" offset={(idx + 1) * HOUR_BLOCK_WIDTH} height={height} />
          <GridLine type="dotted" offset={idx * HOUR_BLOCK_WIDTH + HOUR_BLOCK_WIDTH / 2} height={height} />
        </Fragment>
      ))}
    </>
  );
}
