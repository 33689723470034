import React, { useEffect } from 'react';
import { Container, Button, Anchor, Stack, Text, Title } from '@good/ui/core';
import { PageHeader } from '@good/ui/templates';
import { PlusSquare } from '@good/icons';
import { Link, useHistory } from 'react-router-dom';
import { reactTrpc } from 'utilities/react-trpc';
import { useDisclosure } from '@good/ui/hooks';
import { NewAccessTokenModal } from './components/new-access-token-modal';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import { AccessTokensList } from './components/access-tokens-list';

export const AccessTokensView = () => {
  const history = useHistory();
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  useEffect(() => {
    if (!portalUser) history.push('/access-denied');
  });

  const {
    data: tokens,
    isLoading,
    error,
  } = reactTrpc.account.accessTokens.list.useQuery(
    { serviceProviderId: portalUser?.serviceProviderId ?? '' },
    { enabled: Boolean(portalUser) },
  );
  const [newTokenModalOpened, { open: openNewTokenModal, close: closeNewTokenModal }] = useDisclosure(false);

  if (!portalUser) return null;

  return (
    <Container size='lg'>
      <PageHeader
        title='Personal access tokens'
        subtitle='Create personal access tokens to authenticate and use the GoodHuman Public API.'
        breadcrumbItems={[
          <Anchor key='profile' component={Link} to='/my-profile'>
            My profile
          </Anchor>,
          <Text span key='access-tokens'>
            Personal access tokens
          </Text>,
        ]}
        actions={
          <>
            <Button onClick={openNewTokenModal} size='md' leftSection={<PlusSquare />}>
              Create token
            </Button>
            <NewAccessTokenModal
              opened={newTokenModalOpened}
              close={closeNewTokenModal}
              serviceProviderId={portalUser.serviceProviderId}
            />
          </>
        }
      />

      <Stack>
        <Title order={2}>Tokens</Title>
        <AccessTokensList tokens={tokens} isLoading={isLoading} error={error?.message} portalUser={portalUser} />
      </Stack>
    </Container>
  );
};
