import { Col, Form, Input, notification, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle } from 'common-components/typography';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';

const { Option } = Select;

interface ICreateConnectionRequestActionModelProps extends FormComponentProps {
  isOpen: boolean;
  customer: any;
  onClose: () => void;
  createConnectionRequest: typeof dispatch.customersStore.createConnectionRequest;
}

interface ICreateConnectionRequestActionModelState {
  selectedPersonId: string;
  loading: boolean;
}

class CreateConnectionRequestActionModel extends Component<
  ICreateConnectionRequestActionModelProps,
  ICreateConnectionRequestActionModelState
> {
  state = {
    selectedPersonId: !this.props.customer.isDependent ? this.props.customer.userId : null,
    loading: false,
  };

  private _onCloseActionModel = () => {
    const { onClose } = this.props;
    onClose();
  };

  private _onSendConnectionRequest = async () => {
    const { form, customer, onClose, createConnectionRequest } = this.props;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      this.setState({ loading: true });
      const targetUserId = this.state.selectedPersonId;
      const mobile = form.getFieldValue('mobile');
      const mobileCountryCode = 'AU';
      const email = form.getFieldValue('email');
      const payload = {
        customerConnectionRequests: [
          {
            userId: customer.userId,
            targetUserId,
            email,
            mobile,
            mobileCountryCode,
          },
        ],
      };
      try {
        await createConnectionRequest(payload);
        notification.success({
          message: 'Connection request sent.',
        });
        this.setState({ loading: false });
        onClose();
      } catch (e) {
        notification.error({
          message: 'Oops, something went wrong, please try again.',
        });
        this.setState({ loading: false });
      }
    }
  };

  private _selectPerson = (value) => {
    this.setState({ selectedPersonId: value });
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';

    try {
      if (_.isEmpty(value)) {
        throw Error('Mobile Number is required');
      }
      const phoneNumber = parsePhoneNumberFromString(value, region);
      if (!phoneNumber || !phoneNumber.isValid()) {
        throw new Error('Invalid Mobile Number');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  render() {
    const { isOpen, customer, form } = this.props;
    const { selectedPersonId, loading } = this.state;
    const { getFieldDecorator } = form;

    const personOptions = customer.isDependent ? (
      _.map(customer.guardiansInfo, (guardian) => {
        if (guardian.connected === 'NOT_CONNECTED') {
          return (
            <Option value={guardian.guardianUserId}>
              {guardian.firstName} {guardian.lastName}
            </Option>
          );
        }
      })
    ) : (
      <Option value={customer.userId}>
        {customer.firstName} {customer.lastName}
      </Option>
    );

    const guardianInfos =
      selectedPersonId && customer.isDependent
        ? _.find(customer.guardiansInfo, (guardian) => guardian.guardianUserId === selectedPersonId)
        : null;

    return (
      <ActionModal isOpen={isOpen} title={'Create connection request'} showCloseButton={false}>
        <div style={{ minHeight: '300px' }}>
          <Paragraph>
            Invite your customers to download the GoodHuman app and connect to their customer record or accept your
            connection request.{' '}
          </Paragraph>
          <div className="mv-large">
            <Row className="mb-x-small">
              <Col span={24}>
                <div className="flex-row align-center">
                  <SubTitle>Person</SubTitle> {/*{!customer.isDependent && (*/}
                  {/*  <Tooltip*/}
                  {/*    title={*/}
                  {/*      <div className="p-medium">*/}
                  {/*        To add more Parent/Guardian, please add them in the Contact tab in the customer profile.*/}
                  {/*      </div>*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <Icon type="question-circle" theme="filled" className="text-color-blue ml-x-small" />*/}
                  {/*  </Tooltip>*/}
                  {/*)}*/}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item className={'m-none'}>
                  {getFieldDecorator('person', {
                    initialValue: selectedPersonId,
                    rules: [
                      {
                        required: true,
                        message: 'Please select a person to connect to',
                      },
                    ],
                  })(
                    <Select
                      size="large"
                      placeholder="Select a person"
                      className="width-3/4"
                      onChange={this._selectPerson}
                    >
                      {personOptions}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="mv-large">
            {!_.isEmpty(selectedPersonId) && (
              <div>
                <Row gutter={24} className="mb-x-small">
                  <Col span={10}>
                    <SubTitle>Mobile Number</SubTitle>
                  </Col>
                  <Col span={14}>
                    <div className="flex-row align-center">
                      <SubTitle>Email</SubTitle>
                    </div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={10}>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('mobile', {
                        initialValue: customer.isDependent
                          ? guardianInfos
                            ? guardianInfos.mobile
                            : null
                          : customer.mobile,
                        rules: [
                          {
                            validator: this._validateMobileNumber,
                          },
                        ],
                      })(
                        <Input className="width-full" size="large" addonBefore="+61(AU)" placeholder="Mobile number" />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={14}>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('email', {
                        initialValue: customer.isDependent
                          ? guardianInfos
                            ? guardianInfos.email
                            : null
                          : customer.email,
                        rules: [
                          {
                            required: true,
                            type: 'email',
                            message: 'Please enter valid Email',
                          },
                        ],
                      })(<Input className="width-full" size="large" placeholder="Enter Email" />)}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
        <ActionModalFooter>
          <SecondaryButton size={'large'} disabled={loading} className="mr-medium" onClick={this._onCloseActionModel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size={'large'} loading={loading} onClick={this._onSendConnectionRequest}>
            Send connection request
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  createConnectionRequest: dispatch.customersStore.createConnectionRequest,
});

export default connect(
  null,
  mapDispatch,
)(Form.create<ICreateConnectionRequestActionModelProps>()(CreateConnectionRequestActionModel));
