import React from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { Col, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

interface IDeleteWorkflowFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}
const DeleteWorkflowFormModal = ({ isOpen, onClose, onDelete }: IDeleteWorkflowFormModalProps) => {
  return (
    <>
      <ActionModal isOpen={isOpen} title={'Delete form'} onClose={onClose}>
        <Paragraph>Are you sure you want to delete this form?</Paragraph>
        <Paragraph>This action is permanent and cannot be undone.</Paragraph>

        <ActionModalFooter className={'mt-large'}>
          <Row type={'flex'} className={'justify-end'}>
            <Col className="mr-medium">
              <SecondaryButton size="large" onClick={onClose}>
                Cancel
              </SecondaryButton>
            </Col>
            <Col>
              <PrimaryButton onClick={onDelete} size="large">
                Delete
              </PrimaryButton>
            </Col>
          </Row>
        </ActionModalFooter>
      </ActionModal>
    </>
  );
};

export default DeleteWorkflowFormModal;
