/* eslint-disable */
import _, { isEmpty } from 'lodash';
import { Moment } from 'moment-timezone';
import apiClient from 'utilities/api-client';
import { BookingStatus, PaymentStatus, TransportAttendanceType } from 'utilities/enum-utils';

import { events } from 'integrations/appcues';

const groupBookingsStore = {
  state: {
    selectedGroupBookingItem: null, //overview and billing line items,
    groupBookingActivityLogs: [],
    groupBookingNotes: [],
    groupBookingCustomers: [],
    groupBookingCustomerRatio: null,
    sessionBookingsBilling: [],
    customerScheduleArchiveConflicts: null,
    groupBookingTimeSlotTeamMembers: [],
  },
  reducers: {
    setSelectedGroupBookingItem: (state, payload) => ({ ...state, selectedGroupBookingItem: payload }),
    updateSelectedGroupBookingItem: (state, payload) => ({
      ...state,
      selectedGroupBookingItem: { ...state.selectedGroupBookingItem, ...payload },
    }),
    setGroupBookingStartEndDateTime: (state, payload) => ({
      ...state,
      selectedGroupBookingItem: {
        ...state.selectedGroupBookingItem,
        startDateTime: payload.startDateTime,
        endDateTime: payload.endDateTime,
      },
    }),
    setGroupBookingTransportBookings: (state, payload) => ({
      ...state,
      selectedGroupBookingItem: {
        ...state.selectedGroupBookingItem,
        transportBeforeBooking: payload.transportBeforeBooking,
        transportAfterBooking: payload.transportAfterBooking,
        isTravelEnabled: payload.serviceClaimConfig.isChargeNdisTransportBeforeBooking,
        address: payload.address,
        userAddress: payload.userAddress,
      },
    }),
    setGroupBookingBeforeTransportBooking: (state, payload) => {
      let newPayload = payload;
      try {
        newPayload.address = typeof newPayload.address === 'string' ? JSON.parse(payload.address) : payload.address;
        newPayload.address2 = typeof newPayload.address2 === 'string' ? JSON.parse(payload.address2) : payload.address2;
      } catch (error) {
        newPayload.address = {};
        newPayload.address2 = {};
        console.error('address is not a object');
      }
      return {
        ...state,
        selectedGroupBookingItem: {
          ...state.selectedGroupBookingItem,
          transportBeforeBooking: newPayload,
        },
      };
    },
    setGroupBookingAfterTransportBooking: (state, payload) => {
      let newPayload = payload;
      try {
        newPayload.address = typeof newPayload.address === 'string' ? JSON.parse(payload.address) : payload.address;
        newPayload.address2 = typeof newPayload.address2 === 'string' ? JSON.parse(payload.address2) : payload.address2;
      } catch (error) {
        newPayload.address = {};
        newPayload.address2 = {};
        console.error('address is not a object');
      }
      return {
        ...state,
        selectedGroupBookingItem: {
          ...state.selectedGroupBookingItem,
          transportAfterBooking: newPayload,
        },
      };
    },
    setGroupBookingNotes: (state, payload) => ({ ...state, groupBookingNotes: payload }),
    setGroupBookingCustomers: (state, payload) => ({ ...state, groupBookingCustomers: payload }),
    setGroupBookingActivityLogs: (state, payload) => ({ ...state, groupBookingActivityLogs: payload }),
    setGroupBookingInstruction: (state, payload) => ({
      ...state,
      selectedGroupBookingItem: {
        ...state.selectedGroupBookingItem,
        instructions: payload,
      },
    }),
    setGroupBookingCustomerRatio: (state, payload) => ({ ...state, groupBookingCustomerRatio: payload }),
    setCustomerScheduleArchiveConflicts: (state, payload) => ({ ...state, customerScheduleArchiveConflicts: payload }),
    setSessionBookingsBilling: (state, payload) => ({ ...state, sessionBookingsBilling: payload }),
    updateSessionBookingsBilling: (state, payload) => {
      const newBookingsBilling = _.map(state.sessionBookingsBilling, (billing) => {
        if (billing.attendanceId === payload.attendanceId) {
          return { ...billing, billingLineItems: payload.newBookingLineItems };
        } else {
          return { ...billing };
        }
      });
      return { ...state, sessionBookingsBilling: newBookingsBilling };
    },
    setGroupBookingTimeSlotTeamMembers: (state, payload) => ({ ...state, groupBookingTimeSlotTeamMembers: payload }),
  },
  effects: (dispatch) => ({
    async doFetchGroupBookingOverview(payload, rootState) {
      const result = await apiClient.get(`/api/portal/group-bookings/${payload.bookingId}/overview`);
      try {
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          dispatch.groupBookingsStore.setSelectedGroupBookingItem(result.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateGroupBookingScheduledStartEndTime(payload, rootState) {
      const result = await apiClient.put(`/api/portal/group-bookings/${payload.bookingId}/start-end-time`, payload);
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doUpdateGroupBookingCheckInCheckOutTime(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/checkin-checkout`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doAcceptGroupBooking(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/accept`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;

          dispatch.groupBookingsStore.setSelectedGroupBookingItem({
            ...selectedGroupBookingItem,
            bookedCapacity: selectedGroupBookingItem.bookedCapacity + 1,
            status: BookingStatus.ACCEPTED,
          });
        }
      } catch (e) {
        throw e;
      }
    },
    async doConfirmGroupBooking(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/confirm`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;

          dispatch.groupBookingsStore.setSelectedGroupBookingItem({
            ...selectedGroupBookingItem,
            status: BookingStatus.CONFIRMED,
          });
        }
      } catch (e) {
        throw e;
      }
    },

    async doStartGroupBooking(
      payload: {
        serviceId: string;
        serviceDateTimeId: string;
        attendances: { attendanceId: string; startDateTime: Date | Moment };
      },
      rootState,
    ): Promise<boolean> {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/start`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doCompleteAndApproveBooking(payload) {
      try {
        const endPoint = `api/portal/group-bookings/${payload.attendanceId}/quick-actions/approve`;
        await apiClient.put(endPoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doDeclineGroupBooking(payload, rootState) {
      const result = await apiClient.delete(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/decline`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;

          dispatch.groupBookingsStore.setSelectedGroupBookingItem({
            ...selectedGroupBookingItem,
            status: BookingStatus.REJECTED,
          });
        }
      } catch (e) {
        throw e;
      }
    },
    async doFinishGroupBooking(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/end`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doBusinessCancelGroupBooking(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/business-cancel`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doFetchGroupBookingTransportBookings(payload, rootState) {
      try {
        const result = await apiClient.get(`/api/portal/group-bookings/${payload}/transport-bookings`);
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          dispatch.groupBookingsStore.setGroupBookingTransportBookings(result.data);
        }
      } catch (e) {
        throw e;
      }
    },
    async doCustomerCancelGroupBooking(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/customer-cancel`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doCustomerWaiveGroupBooking(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/waive`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doCustomerReinstateGroupBooking(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/reinstate`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doApproveGroupBookingsPayment(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/approve`,
        payload,
      );
      try {
        if (result?.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });

            events.trackApproveBooking({
              bookingId: selectedGroupBookingItem.bookingId,
              paymentStatus: PaymentStatus.SEND_TO_FINANCE,
              bookingReferenceId: selectedGroupBookingItem.bookingReferenceId,
            });
          }
        }
        return result?.data;
      } catch (e) {
        throw e;
      }
    },
    async doRevokeGroupBookingsApproval(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/revoke`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doChangeGroupBookingToCustomerCancel(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/change-to-customer-cancel`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doChangeGroupBookingToBusinessCancel(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/change-to-business-cancel`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doChargeGroupBookings(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/change-to-customer-cancel/charge`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doUnchargeGroupBookingsCancellationFee(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/change-to-customer-cancel/uncharge`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doUndoGroupBookings(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/revert-to-confirm`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doUpdateGroupBookingSingleBillingItem(payload, rootState) {
      const result = await apiClient.put(
        `/api/portal/group-bookings/${payload.bookingId}/billings/${payload.bookingBillingLineItemId}`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doUpdateGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
          await dispatch.groupBookingsStore.updateSessionBookingsBilling({
            attendanceId: payload.bookingId,
            newBookingLineItems: result.data.billingLineItems,
          });
        }
      } catch (e) {
        throw e;
      }
    },
    async doDeleteGroupBookingSingleBillingItem(payload, rootState) {
      const result = await apiClient.delete(
        `/api/portal/group-bookings/${payload.bookingId}/billings/${payload.bookingBillingLineItemId}`,
        payload,
      );
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doUpdateGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doCreateGroupBookingBillingItem(payload, rootState) {
      const result = await apiClient.post(`/api/portal/group-bookings/${payload.bookingId}/billings`, payload);
      try {
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doUpdateGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doCreateTransportBooking(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${rootState.groupBookingsStore.selectedGroupBookingItem.bookingId}/transport-bookings`;

      try {
        const result = await apiClient.post(endpoint, payload);
        const bookingId = result.data.attendanceId;
        const memberId = result.data?.supportWorkerId;
        if (memberId) events.trackAssignMemberToBooking({ serviceType: payload.type, memberId });
        events.trackCreateBooking({ serviceType: payload.type, bookingId });

        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          if (payload.type === TransportAttendanceType.PRE_SESSION) {
            dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(result.data);
          } else {
            dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(result.data);
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async doStartGroupTransportBooking(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/start`;
      try {
        await apiClient.put(endpoint, { startDateTime: payload.startDateTime });
        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.status = BookingStatus.INPROGRESS;
        booking.workerCheckedInDateTime = payload.startDateTime;
        booking.actualCheckedInDateTime = new Date();

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },
    async doFinishGroupTransportBooking(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/finish`;
      try {
        await apiClient.put(endpoint, {
          endDateTime: payload.endDateTime,
          travelDistanceDuringBooking: payload.travelDistanceDuringBooking,
          additionalCostDuringBooking: payload.additionalCostDuringBooking,
        });
        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.status = BookingStatus.COMPLETED;
        booking.workerCheckedOutDateTime = payload.endDateTime;
        booking.actualCheckedOutDateTime = new Date();
        booking.travelDistanceDuringBooking = payload.travelDistanceDuringBooking;
        booking.additionalCostDuringBooking = payload.additionalCostDuringBooking;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateTransportBookingDetails(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/transport-detail`;
      try {
        await apiClient.put(endpoint, {
          endDateTime: payload.endDateTime,
          travelDistanceDuringBooking: payload.travelDistanceDuringBooking,
          additionalCostDuringBooking: payload.additionalCostDuringBooking,
          startDateTime: payload.startDateTime,
        });
        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.workerCheckedOutDateTime = payload.endDateTime;
        booking.travelDistanceDuringBooking = payload.travelDistanceDuringBooking;
        booking.additionalCostDuringBooking = payload.additionalCostDuringBooking;
        booking.workerCheckedInDateTime = payload.startDateTime;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateTransportBooking(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/detail`;
      try {
        await apiClient.put(endpoint, {
          endDateTime: payload.endDateTime,
          startDateTime: payload.startDateTime,
          address: payload.address,
          address2: payload.address2,
          comment: payload.comment,
        });

        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.endDateTime = payload.endDateTime;
        booking.startDateTime = payload.startDateTime;
        booking.address = payload.address;
        booking.address2 = payload.address2;
        booking.comment = payload.comment;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },

    async doRevertTransportBookingToConfirm(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/revert-to-confirm`;
      try {
        await apiClient.put(endpoint);

        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.status = BookingStatus.CONFIRMED;
        booking.actualCheckedInDateTime = null;
        booking.actualCheckedOutDateTime = null;
        booking.workerCheckedOutDateTime = null;
        booking.travelDistanceDuringBooking = null;
        booking.additionalCostDuringBooking = null;
        booking.workerCheckedInDateTime = null;
        booking.portalCheckedInDateTime = null;
        booking.portalCheckedOutDateTime = null;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },

    async doCancelTransportBooking(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/cancel`;
      try {
        const requestBody = {
          isBusinessCancel: payload.isBusinessCancel,
          isChargeCancellation: payload.isChargeCancellation,
          isCustomerNoShow: payload.isCustomerNoShow,
          cancellationReason: payload.cancellationReason,
          cancellationCode: payload.cancellationCode,
        };
        await apiClient.put(endpoint, requestBody);

        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.status = payload.isBusinessCancel
          ? BookingStatus.BUSINESS_CANCELLED
          : payload.isCharge
          ? BookingStatus.CUSTOMER_CANCELLED_WITH_FEE
          : BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE;
        booking.cancellationCode = payload.cancellationCode;
        booking.cancellationReason = payload.cancellationReason;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },

    async doChangeTransportBookingToCustomerCancel(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/customer-cancel`;
      try {
        const requestBody = {
          isChargeCancellation: payload.isChargeCancellation,
          isCustomerNoShow: payload.isCustomerNoShow,
          cancellationReason: payload.cancellationReason,
          cancellationCode: payload.cancellationCode,
        };
        await apiClient.put(endpoint, requestBody);

        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.status = payload.isCharge
          ? BookingStatus.CUSTOMER_CANCELLED_WITH_FEE
          : BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE;
        booking.cancellationCode = payload.cancellationCode;
        booking.cancellationReason = payload.cancellationReason;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },

    async doCheckWorkerConflict(payload, rootState) {
      const endpoint = `/api/portal/bookings/${payload.transportBookingId}/worker-conflict`;
      try {
        const requestBody = {
          bookingRequestId: payload.bookingRequestId,
        };

        const result = await apiClient.post(endpoint, requestBody);

        return result.data;
      } catch (e) {
        throw e;
      }
    },
    async doReinstateTransportBooking(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/reinstate`;
      try {
        await apiClient.put(endpoint);
        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );
        booking.status = BookingStatus.CONFIRMED;
        booking.cancellationCode = null;
        booking.cancellationReason = null;
        booking.supportWorkerId = payload.workerConflict ? null : booking.supportWorkerId;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },
    async doWaiveTransportBooking(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/waive`;
      try {
        await apiClient.put(endpoint);
        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.paymentStatus = PaymentStatus.WAIVED;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },
    async doApproveTransportBooking(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/approve`;
      try {
        await apiClient.put(endpoint);
        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.paymentStatus = PaymentStatus.SEND_TO_FINANCE;

        events.trackApproveBooking({
          bookingId: booking.bookingId,
          paymentStatus: booking.paymentStatus,
        });

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },

    async doRevokeTransportBookingApproval(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/revoke-approval`;
      try {
        await apiClient.put(endpoint);
        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.paymentStatus = PaymentStatus.REQUIRES_APPROVAL;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },

    async doChangeTransportBookingToCustomerCancelWithFee(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/customer-cancel-fee`;
      try {
        await apiClient.put(endpoint);
        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.paymentStatus = PaymentStatus.REQUIRES_APPROVAL;
        booking.status = BookingStatus.CUSTOMER_CANCELLED_WITH_FEE;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },

    async doChangeTransportBookingToCustomerCancelNoFee(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/customer-cancel-no-fee`;
      try {
        await apiClient.put(endpoint);
        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.paymentStatus = PaymentStatus.NO_CHARGE;
        booking.status = BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },
    async doChangeTransportBookingToBusinessCancel(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/business-cancel`;
      try {
        await apiClient.put(endpoint);
        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.paymentStatus = PaymentStatus.NO_CHARGE;
        booking.status = BookingStatus.BUSINESS_CANCELLED;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },
    async doRemoveTransportBookingWorker(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/worker`;
      try {
        await apiClient.delete(endpoint);
        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.shiftSlotStatus = 'UNASSIGNED';
        booking.supportWorkerId = null;
        booking.firstName = null;
        booking.lastName = null;
        booking.attendanceUrl = null;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchSupportWorkerList(payload, rootState) {
      const endpoint = `/api/portal/group-services/${payload.serviceId}/search-worker`;
      try {
        const result = await apiClient.post(endpoint, payload);

        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doAssignTransportBookingWorker(payload, rootState) {
      const endpoint = `/api/portal/group-bookings/${payload.bookingId}/transport-bookings/${payload.transportBookingId}/worker`;
      try {
        const requestPayload = {
          workerId: payload.worker.supportWorkerId,
        };

        await apiClient.put(endpoint, requestPayload);
        const booking = _.cloneDeep(
          payload.attendanceType === TransportAttendanceType.PRE_SESSION
            ? rootState.groupBookingsStore.selectedGroupBookingItem.transportBeforeBooking
            : rootState.groupBookingsStore.selectedGroupBookingItem.transportAfterBooking,
        );

        booking.shiftSlotStatus = 'CONFIRMED';
        booking.supportWorkerId = payload.worker.supportWorkerId;
        booking.firstName = payload.worker.firstName;
        booking.lastName = payload.worker.lastName;
        booking.attendanceUrl = payload.worker.attachmentUrl;

        if (payload.attendanceType === TransportAttendanceType.PRE_SESSION) {
          dispatch.groupBookingsStore.setGroupBookingBeforeTransportBooking(booking);
        } else {
          dispatch.groupBookingsStore.setGroupBookingAfterTransportBooking(booking);
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupBookingActivityLogs(payload) {
      // bookingId is the actual attendenceId.
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/activity-logs`;
      try {
        const response = await apiClient.get(endpoint);
        dispatch.groupBookingsStore.setGroupBookingActivityLogs(response.data.bookingHistories);
      } catch (e) {
        throw e;
      }
    },

    async doIgnoreGroupBookingWarning(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/warnings`;
      const requestBody = {
        bookingErrors: [payload.bookingErrorType],
      };
      try {
        let result = await apiClient.delete(endpoint, requestBody);
        if (result && result.data) {
          const { selectedGroupBookingItem } = rootState.groupBookingsStore;
          if (selectedGroupBookingItem) {
            await dispatch.groupBookingsStore.doFetchGroupBookingOverview({
              bookingId: selectedGroupBookingItem.bookingId,
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupBookingInstructions(payload, rootState) {
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/instructions`;
      try {
        let result = await apiClient.get(endpoint);

        if (result && result.data) {
          await dispatch.groupBookingsStore.setGroupBookingInstruction(result.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateGroupBookingInstructions(payload, rootState) {
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/instructions/${payload.attendanceInstructionId}`;

      const requestPayload = {
        instruction: payload.instruction,
      };

      try {
        let result = await apiClient.put(endpoint, requestPayload);
        if (result && result.data) {
          let instructions = _.cloneDeep(rootState.groupBookingsStore.selectedGroupBookingItem.instructions);

          instructions = _.map(instructions, (instruction) => {
            if (instruction.attendanceInstructionId === payload.attendanceInstructionId) {
              instruction = result.data;
            }
            return instruction;
          });

          await dispatch.groupBookingsStore.setGroupBookingInstruction(instructions);
        }
      } catch (e) {
        throw e;
      }
    },

    async doDeleteGroupBookingInstructions(payload, rootState) {
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/instructions/${payload.attendanceInstructionId}`;

      try {
        let result = await apiClient.delete(endpoint);
        if (result && result.data) {
          let instructions = _.cloneDeep(rootState.groupBookingsStore.selectedGroupBookingItem.instructions);

          instructions = _.filter(
            instructions,
            (instruction) => instruction.attendanceInstructionId !== payload.attendanceInstructionId,
          );

          await dispatch.groupBookingsStore.setGroupBookingInstruction(instructions);
        }
      } catch (e) {
        throw e;
      }
    },

    async doAddGroupBookingInstructions(payload, rootState) {
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/instructions`;
      const requestPayload = {
        instruction: payload.instruction,
      };

      try {
        let result = await apiClient.post(endpoint, requestPayload);

        if (result && result.data) {
          const instructions = _.cloneDeep(rootState.groupBookingsStore.selectedGroupBookingItem.instructions);

          instructions.push(result.data);

          await dispatch.groupBookingsStore.setGroupBookingInstruction(instructions);
        }
      } catch (e) {
        throw e;
      }
    },

    /* Group Booking customer note start */
    async doFetchGroupBookingNotes(payload, rootState) {
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/booking-notes/list`;

      try {
        const result = await apiClient.post(endpoint, { ...payload, attendanceId: payload.bookingId });

        if (payload.page === 1) {
          dispatch.groupBookingsStore.setGroupBookingNotes(result.data.notes);
        } else {
          const newNotes = [...rootState.groupBookingsStore.groupBookingNotes, ...result.data.notes];
          dispatch.groupBookingsStore.setGroupBookingNotes(newNotes);
        }
      } catch (e) {
        throw e;
      }
    },

    async doAddGroupBookingNote(payload) {
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/booking-notes`;

      try {
        const result = await apiClient.post(endpoint, { ...payload, attendanceId: payload.bookingId });
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doEditGroupBookingNote(payload) {
      console.log('Calling editing booking enpoint with payload: ', payload);
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/booking-notes/${payload.noteId}`;

      try {
        const result = await apiClient.put(endpoint, { ...payload, attendanceId: payload.bookingId });
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doRemoveGroupBookingNote(payload, rootState) {
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/booking-notes/${payload.noteId}`;

      try {
        await apiClient.delete(endpoint);
        const newNotes = _.filter(rootState.groupBookingsStore.groupBookingNotes, (note) => {
          return note.noteId !== payload.noteId;
        });

        dispatch.groupBookingsStore.setGroupBookingNotes(newNotes);
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupBookingCustomer(payload) {
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/customers/list`;

      try {
        const response = await apiClient.post(endpoint, { ...payload, attendanceId: payload.bookingId });
        const newCustomers = [];
        _.map(response.data, (customer) => {
          const newUser = {
            taggedUserId: customer.customerUserId,
            taggedUserFirstName: customer.firstName,
            taggedUserLastName: customer.lastName,
            taggedUserAvatarUrl: customer.customerAvatarUrl,
          };

          newCustomers.push(newUser);
        });

        dispatch.groupBookingsStore.setGroupBookingCustomers(newCustomers);
      } catch (e) {
        throw e;
      }
    },
    /* Group Booking customer note end */

    /* Group Booking ratio start */

    async doFetchGroupBookingCustomerRatio(payload) {
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/ratio`;

      try {
        const response = await apiClient.get(endpoint, { ...payload, attendanceId: payload.bookingId });
        dispatch.groupBookingsStore.setGroupBookingCustomerRatio(response.data);
      } catch (e) {
        throw e;
      }
    },

    async doEditBookingCustomerRatio(payload) {
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/ratio`;

      try {
        await apiClient.post(endpoint, { ...payload, attendanceId: payload.bookingId });
        dispatch.groupBookingsStore.doFetchGroupBookingCustomerRatio({ bookingId: payload.bookingId });
      } catch (e) {
        throw e;
      }
    },

    async doUpdateGroupBookingOverview(payload) {
      const result = await apiClient.get(`/api/portal/group-bookings/${payload.bookingId}/overview`);
      try {
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          dispatch.groupBookingsStore.updateSelectedGroupBookingItem(result.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupBookingCustomerScheduleArchiveCheck(payload) {
      const result = await apiClient.post(`/api/portal/group-bookings/customer-schedule-archive-check`, payload);
      try {
        if (!_.isEmpty(result)) {
          dispatch.groupBookingsStore.setCustomerScheduleArchiveConflicts(result.data);
          return result.data;
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchSessionBookingBillings(payload, rootState) {
      const endpoint = `api/portal/group-bookings/${payload.bookingId}/billing`;
      try {
        const result = await apiClient.get(endpoint);
        let sessionBookingBilling = rootState.groupBookingsStore.sessionBookingsBilling;
        if (result && result.data) {
          sessionBookingBilling = _.filter(
            sessionBookingBilling,
            (booking) => booking.attendanceId !== payload.bookingId,
          );
          sessionBookingBilling.push(result.data);
        }
        dispatch.groupBookingsStore.setSessionBookingsBilling(sessionBookingBilling);
      } catch (e) {
        throw e;
      }
    },

    /* Group Booking ratio end */

    async doFetchGroupBookingTimeSlotTeamMembers(payload: {
      serviceId: string;
      serviceDateTimeId: string;
      attendanceId: string;
      attendanceTimeSlotId: string;
      startDateTime: Date | string;
      endDateTime: Date | string;
    }) {
      const endpoint = `/api/portal/group-bookings/${payload.attendanceId}/attendance-time-slots/${payload.attendanceTimeSlotId}/team-members/list`;
      try {
        const results = await apiClient.post(endpoint, payload);
        dispatch.groupBookingsStore.setGroupBookingTimeSlotTeamMembers(isEmpty(results.data) ? [] : results.data);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async assignTeamMemberToBookingTimeSlot(payload: {
      serviceId: string;
      serviceDateTimeId: string;
      attendanceId: string;
      attendanceTimeSlotId: string;
      supportWorkerId?: string;
      supportWorkerIds?: string[];
      customerUserId: string;
    }) {
      let endpoint = '';
      try {
        const { attendanceId, attendanceTimeSlotId, ...newPayload } = payload;
        if (!payload.supportWorkerIds) {
          endpoint = `/api/portal/group-bookings/${attendanceId}/attendance-time-slots/${attendanceTimeSlotId}/assign-team-member`;
        } else {
          endpoint = `/api/portal/group-bookings/${attendanceId}/attendance-time-slots/${attendanceTimeSlotId}/assign-multi-team-member`;
        }
        await apiClient.put(endpoint, newPayload);
      } catch (e) {
        throw e;
      }
    },

    async unAssignTeamMembersToBookingTimeSlot(payload: {
      attendanceId: string;
      attendanceTimeSlotId: string;
      serviceId: string;
      serviceDateTimeId: string;
    }) {
      try {
        const { attendanceId, attendanceTimeSlotId, ...newPayload } = payload;
        const endpoint = `api/portal/group-bookings/${attendanceId}/attendance-time-slots/${attendanceTimeSlotId}/unassign`;
        await apiClient.delete(endpoint, newPayload);
      } catch (e) {
        throw e;
      }
    },
  }),
};

export default groupBookingsStore;
