import React from 'react';
import { TextInput } from '@mantine/core';
import { Search } from '@good/icons';
export const FilterSearchInput = ({ value, onChange, ...props }) => {
    const onSearch = (event) => {
        const searchValue = event.currentTarget.value;
        onChange(searchValue);
    };
    return (<TextInput leftSection={<Search aria-label='magnifying glass'/>} aria-label='Search' value={value} onChange={onSearch} placeholder='Search' size='sm' {...props}/>);
};
