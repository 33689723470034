import React, { Component } from 'react';
import { Checkbox, Icon, Skeleton } from 'antd';
import { FieldLabel, Text } from 'common-components/typography';
import _ from 'lodash';
import { ndisHelper } from 'variables/data-helpers';
import NDISLineItemGroup from './NDISLineItemGroup';
import CommonUtils from 'utilities/common-utils';
import { HyperlinkButton } from 'common-components/buttons';
import NumberInput from 'common-components/inputs/NumberInput';
import { MmmGroup } from 'utilities/enum-utils';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
interface INDISLineItemGridProps {
  lineItems?: Array<any>;
  selectedLineItems?: Array<any>;
  displayMode: string;
  isSelectable?: boolean;
  onClickLineItemCheckbox?: any;
  isSequenceAdjustable?: boolean;
  onSequenceChange?: any;
  isDeletable?: boolean;
  onDelete?: any;
  isPriceAdjustable?: boolean;
  onPriceChange?: any;
  mmmGroup?: MmmGroup;
  state?: string;
  isLoading?: boolean;
  size?: 'small';
  isServiceAgreementLineItems?: boolean;
  lineItemTitle?: any;
  noMargin?: boolean;
  paymentSourceType?: string;
  onlyShowSpecificPrice?: boolean;
  vcpItems: any;
  doGetVCPItems: typeof dispatch.servicesStore.doGetVCPItems;
}

interface INDISLineItemGridState {
  displayLineItems: any;
  allSelectedLineItems: any;
  numberOfSelectedGroups: number;
}

const unitOptions = [
  { value: 'EA', label: 'Each' },
  { value: 'H', label: 'Hourly' },
  { value: 'D', label: 'Daily' },
  { value: 'WK', label: 'Weekly' },
  { value: 'MON', label: 'Monthly' },
  { value: 'YR', label: 'Yearly' },
];

class NDISLineItemGrid extends Component<INDISLineItemGridProps, INDISLineItemGridState> {
  state = {
    displayLineItems: [],
    allSelectedLineItems: [],
    numberOfSelectedGroups: 0,
  };

  // this function is used to group service line items into the display structure
  // display structure is a list of list: [[{}], [{}, {}], [{}], ...]
  // inner list: a list of service items that is the same group
  // inner list with 1 item inside means they does not belong to any group
  private _groupServiceLineItems = (lineItems) => {
    const { paymentSourceType = 'NDIS' } = this.props;
    const allSupportItems = _.map(lineItems, (lineItem) => {
      if (paymentSourceType === 'NDIS') {
        const supportItemInfo = ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber);
        return {
          ...lineItem,
          groupCode: supportItemInfo.GroupCode,
          groupName: supportItemInfo.GroupName,
          supportItem: supportItemInfo.SupportItem,
          supportCategoryNumber: supportItemInfo.SupportCategoryNumber,
        };
      } else {
        return lineItem;
      }
    });

    let previousSupportItemGroupCode = '';
    const groupedSupportItems: any = []; //the outmost list
    let previousSameGroupCodeSupportItems: any = []; //the inner list

    _.forEach(allSupportItems, (item, index) => {
      // if the current item doesnt have group code
      if (item.groupCode === '') {
        // append all previous groups if there are yet to be pushed
        if (previousSameGroupCodeSupportItems.length !== 0) {
          groupedSupportItems.push(previousSameGroupCodeSupportItems);
        }
        groupedSupportItems.push([item]);
        previousSameGroupCodeSupportItems = [];
        previousSupportItemGroupCode = '';
      } else if (
        index === 0 ||
        item.groupCode === previousSupportItemGroupCode ||
        previousSupportItemGroupCode === ''
      ) {
        // if item is first item or previous item has same GroupCode as the current item
        previousSameGroupCodeSupportItems.push(item);
        previousSupportItemGroupCode = item.groupCode;
      } else {
        // if the previous item has different GroupCode as the current item
        groupedSupportItems.push(previousSameGroupCodeSupportItems);
        previousSameGroupCodeSupportItems = [item];
        previousSupportItemGroupCode = item.groupCode;
      }
      // if current item is the last item in the list
      if (index === allSupportItems.length - 1 && previousSameGroupCodeSupportItems.length !== 0) {
        groupedSupportItems.push(previousSameGroupCodeSupportItems);
      }
    });

    // assign priority
    return _.map(groupedSupportItems, (groupedSupportItem, index) => {
      return _.map(groupedSupportItem, (item) => {
        return { ...item, priority: index + 1 };
      });
    });
  };

  // this function is used for reordering line items after we have click the checkbox
  // will move the selected item to the top of all unselected items below all selected items
  private _moveSelectedItemToLast = (newSelectedItems) => {
    const allGroupedLineItemsMap = new Map();
    const displayLineItems = this.props.lineItems;

    _.forEach(_.flatten(displayLineItems), (lineItem) => {
      if (lineItem.groupCode !== '') {
        if (!_.isEmpty(allGroupedLineItemsMap[lineItem.groupCode])) {
          allGroupedLineItemsMap[lineItem.groupCode].push(lineItem);
        } else {
          allGroupedLineItemsMap[lineItem.groupCode] = [lineItem];
        }
      }
    });

    const insertedGroupList = [];
    let newSelectedLineItems = [];
    let newUnselectedLineItems = [];
    _.forEach(_.flatten(displayLineItems), (lineItem) => {
      if (
        lineItem.groupCode === '' ||
        (lineItem.groupCode !== '' &&
          _.isEmpty(_.filter(insertedGroupList, (groupCode) => groupCode === lineItem.groupCode)))
      ) {
        if (lineItem.groupCode !== '') {
          // group line items
          const lineItemsToBeInserted = allGroupedLineItemsMap[lineItem.groupCode];
          let isSelected = false;
          _.forEach(lineItemsToBeInserted, (lineItem) => {
            if (
              _.find(newSelectedItems, (selectedItem) => {
                return selectedItem.supportItemNumber === lineItem.supportItemNumber;
              })
            ) {
              isSelected = true;
            }
          });
          if (isSelected) {
            newSelectedLineItems = [...newSelectedLineItems, ...lineItemsToBeInserted];
          } else {
            newUnselectedLineItems = [...newUnselectedLineItems, ...lineItemsToBeInserted];
          }
          insertedGroupList.push(lineItem.groupCode);
        } else {
          // single line items
          if (
            _.find(
              newSelectedItems,
              (selectedLineItem) => selectedLineItem.supportItemNumber === lineItem.supportItemNumber,
            )
          ) {
            newSelectedLineItems.push(lineItem);
          } else {
            newUnselectedLineItems.push(lineItem);
          }
        }
      }
    });
    return [...newSelectedLineItems, ...newUnselectedLineItems];
  };

  private _onClickLineItemCheckbox = (lineItem) => {
    let newSelectedLineItem;
    if (
      !_.find(
        this.state.allSelectedLineItems,
        (existingLineItem) => existingLineItem.supportItemNumber === lineItem.supportItemNumber,
      )
    ) {
      newSelectedLineItem = !_.isEmpty(this.state.allSelectedLineItems) ? _.clone(this.state.allSelectedLineItems) : [];
      newSelectedLineItem.push(lineItem);
    } else {
      newSelectedLineItem = _.filter(
        this.state.allSelectedLineItems,
        (existingLineItem) => existingLineItem.supportItemNumber !== lineItem.supportItemNumber,
      );
    }
    this.setState({ allSelectedLineItems: newSelectedLineItem });
    this.props.onClickLineItemCheckbox(newSelectedLineItem);

    const newGroupedDisplayLineItems = this._groupServiceLineItems(this.props.lineItems);
    this.props.onSequenceChange(_.flatten(newGroupedDisplayLineItems));
    const numberOfSelectedGroups = this._getNumberOfSelectedGroup(newGroupedDisplayLineItems, newSelectedLineItem);
    this.setState({ numberOfSelectedGroups: numberOfSelectedGroups, displayLineItems: newGroupedDisplayLineItems });
  };

  private _onClickGroupGeneralCheckbox = (groupLineItems) => {
    let newSelectedLineItem = _.clone(this.state.allSelectedLineItems);
    const groupIntersection = _.intersectionBy(this.state.allSelectedLineItems, groupLineItems, 'supportItemNumber');

    if (groupIntersection && groupIntersection.length === groupLineItems.length) {
      newSelectedLineItem = _.filter(this.state.allSelectedLineItems, (selectedLineItems) => {
        return !_.find(
          groupLineItems,
          (groupLineItem) => selectedLineItems.supportItemNumber === groupLineItem.supportItemNumber,
        );
      });
    } else if (_.isEmpty(groupIntersection)) {
      newSelectedLineItem = [...this.state.allSelectedLineItems, ...groupLineItems];
    } else {
      const toBeInserted = _.filter(groupLineItems, (groupLineItem) => {
        return !_.find(groupIntersection, (intersectedLineItem) => {
          return intersectedLineItem.supportItemNumber === groupLineItem.supportItemNumber;
        });
      });
      newSelectedLineItem = [...this.state.allSelectedLineItems, ...toBeInserted];
    }
    this.setState({ allSelectedLineItems: newSelectedLineItem });
    this.props.onClickLineItemCheckbox(newSelectedLineItem);

    const newGroupedDisplayLineItems = this._groupServiceLineItems(this.props.lineItems);
    this.props.onSequenceChange(_.flatten(newGroupedDisplayLineItems));
    const numberOfSelectedGroups = this._getNumberOfSelectedGroup(newGroupedDisplayLineItems, newSelectedLineItem);
    this.setState({ numberOfSelectedGroups: numberOfSelectedGroups, displayLineItems: newGroupedDisplayLineItems });
  };

  private _onClickGeneralCheckbox = (event) => {
    let newAllSelectedLineItems;
    if (event.target.checked) {
      newAllSelectedLineItems = _.flatten(this.state.displayLineItems);
    } else {
      newAllSelectedLineItems = [];
    }
    this.setState({ allSelectedLineItems: newAllSelectedLineItems });
    this.props.onClickLineItemCheckbox(newAllSelectedLineItems);
  };

  private _onClickSequenceUp = (e, lineItems) => {
    e.stopPropagation();
    e.preventDefault();
    const lineItem = lineItems[0];
    const { displayLineItems } = this.state;
    // first item cannot go any higher
    // grab first half, second half of the list
    // swap the middle part and combine them back
    if (lineItem.priority > 1) {
      const firstHalf = displayLineItems.slice(0, lineItem.priority - 2);
      const secondHalf = displayLineItems.slice(lineItem.priority);
      const previousItem = _.map(displayLineItems[lineItem.priority - 2], (item) => {
        const { priority, ...attributes } = item;
        return { ...attributes, priority: priority + 1 };
      });
      const currentItem = _.map(displayLineItems[lineItem.priority - 1], (item) => {
        const { priority, ...attributes } = item;
        return { ...attributes, priority: priority - 1 };
      });
      this.props.onSequenceChange(_.flatten([...firstHalf, currentItem, previousItem, ...secondHalf]));
    }
  };

  private _onClickSequenceDown = (e, lineItems) => {
    e.stopPropagation();
    e.preventDefault();
    const lineItem = lineItems[0];
    const { displayLineItems } = this.state;
    // last item cannot go any lower
    // grab first half, second half of the list
    // swap the middle part and combine them back
    if (lineItem.priority <= displayLineItems.length - 1) {
      const firstHalf = displayLineItems.slice(0, lineItem.priority - 1);
      const secondHalf = displayLineItems.slice(lineItem.priority + 1);
      const currentItem = _.map(displayLineItems[lineItem.priority - 1], (item) => {
        const { priority, ...attributes } = item;
        return { ...attributes, priority: priority + 1 };
      });
      const itemAfterCurrent = _.map(displayLineItems[lineItem.priority], (item) => {
        const { priority, ...attributes } = item;
        return { ...attributes, priority: priority - 1 };
      });

      this.props.onSequenceChange(_.flatten([...firstHalf, itemAfterCurrent, currentItem, ...secondHalf]));
    }
  };

  private _onDeleteGroupLineItem = (e, lineItemNumber) => {
    e.stopPropagation();
    e.preventDefault();
    const newDisplayLineItems = _.map(this.state.displayLineItems, (groupedLineItem) => {
      return _.filter(groupedLineItem, (lineItem) => lineItem.supportItemNumber !== lineItemNumber.supportItemNumber);
    });
    this.props.onDelete(_.flatten(newDisplayLineItems));
  };

  private _onClickDelete = (e, lineItems) => {
    e.stopPropagation();
    e.preventDefault();
    const newDisplayLineItems = _.chain(this.state.displayLineItems)
      .filter((lineItem) => {
        return lineItem[0].priority !== lineItems[0].priority;
      })
      .map((items, index) => {
        return _.map(items, (item) => {
          const { priority, ...attributes } = item;
          return { ...attributes, priority: index + 1 };
        });
      })
      .value();
    this.props.onDelete(_.flatten(newDisplayLineItems));
  };

  private _onEditLineItemPrice = (lineItemNumber, newPrice, priceLabel) => {
    const newDisplayLineItems = _.map(_.flatten(this.state.displayLineItems), (existingLineItem) => {
      if (lineItemNumber === existingLineItem.supportItemNumber) {
        existingLineItem.price[priceLabel] = newPrice;
        return { ...existingLineItem };
      } else {
        return { ...existingLineItem };
      }
    });
    this.props.onPriceChange(newDisplayLineItems);
  };

  private _getNumberOfSelectedGroup = (displayLineItems, allSelectedLineItems) => {
    let numberOfSelectedGroups = 0;
    _.forEach(displayLineItems, (lineItemGroup) => {
      let hasGroupSelected = false;
      _.forEach(lineItemGroup, (lineItem) => {
        const hasSelected = _.find(
          allSelectedLineItems,
          (selectedLineItems) => lineItem.supportItemNumber === selectedLineItems.supportItemNumber,
        );
        if (hasSelected) {
          hasGroupSelected = true;
        }
      });
      if (hasGroupSelected) {
        numberOfSelectedGroups += 1;
      }
    });
    return numberOfSelectedGroups;
  };

  private _getPriceLabel = (type) => {
    return (
      <Text color={'secondary'} size={'small'}>
        {type === 'act' ? '(ACT/QLD/VIC/NSW)' : '(NT/SA/WA/TAS)'}
      </Text>
    );
  };

  async componentDidMount() {
    const groupedSupportItems = this._groupServiceLineItems(this.props.lineItems);
    const numberOfSelectedGroups = this._getNumberOfSelectedGroup(groupedSupportItems, this.props.selectedLineItems);
    if (this.props.paymentSourceType === 'VCP' && _.isEmpty(this.props.vcpItems)) {
      await this.props.doGetVCPItems({});
    }
    this.setState({
      displayLineItems: groupedSupportItems,
      allSelectedLineItems: this.props.selectedLineItems,
      numberOfSelectedGroups: numberOfSelectedGroups,
    });
  }

  async componentDidUpdate(
    prevProps: Readonly<INDISLineItemGridProps>,
    prevState: Readonly<INDISLineItemGridState>,
    snapshot?: any,
  ) {
    if (prevProps.lineItems !== this.props.lineItems) {
      const groupedSupportItems = this._groupServiceLineItems(this.props.lineItems);
      const numberOfSelectedGroups = this._getNumberOfSelectedGroup(groupedSupportItems, this.props.selectedLineItems);
      if (this.props.paymentSourceType === 'VCP' && _.isEmpty(this.props.vcpItems)) {
        await this.props.doGetVCPItems({});
      }
      this.setState({
        displayLineItems: groupedSupportItems,
        allSelectedLineItems: this.props.selectedLineItems,
        numberOfSelectedGroups: numberOfSelectedGroups,
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      displayLineItems: [],
      allSelectedLineItems: [],
      numberOfSelectedGroups: 0,
    });
  }

  render() {
    const {
      isSelectable,
      isSequenceAdjustable,
      isDeletable,
      isPriceAdjustable,
      mmmGroup,
      state,
      isServiceAgreementLineItems = false,
      lineItemTitle = 'Line item',
      noMargin = false,
      paymentSourceType,
    } = this.props;
    const { displayLineItems, allSelectedLineItems, numberOfSelectedGroups } = this.state;
    const noItems = _.isEmpty(displayLineItems);
    const hasActionCol = !!(isSequenceAdjustable || isDeletable);
    const onlyShowSpecificPrice = isServiceAgreementLineItems || !isNaN(mmmGroup) || this.props.onlyShowSpecificPrice;

    return (
      <>
        {displayLineItems && (
          <div className={!noMargin && 'mb-large  pr-small'}>
            <div className="bordered-bottom pb-medium">
              <table className={'width-full'}>
                <tbody>
                  <tr>
                    {isSelectable && !noItems && (
                      <td className={'ph-medium'} style={{ width: '50px' }}>
                        <Checkbox
                          checked={
                            displayLineItems &&
                            allSelectedLineItems &&
                            _.flattenDeep(displayLineItems).length === allSelectedLineItems.length
                          }
                          indeterminate={
                            displayLineItems &&
                            allSelectedLineItems &&
                            _.flattenDeep(displayLineItems).length > allSelectedLineItems.length &&
                            allSelectedLineItems.length > 0
                          }
                          onClick={this._onClickGeneralCheckbox}
                        />
                      </td>
                    )}
                    <td className={'ph-medium'}>
                      <FieldLabel text={lineItemTitle} />
                    </td>
                    {onlyShowSpecificPrice ? (
                      <td style={{ width: '321px' }} className="text-align-right ph-medium">
                        <FieldLabel text={'Price'} />
                      </td>
                    ) : (
                      <>
                        <td style={{ width: '152px' }} className="text-align-right ph-medium">
                          <FieldLabel text={'Standard'} />
                        </td>
                        <td style={{ width: '152px' }} className="text-align-right ph-medium">
                          <FieldLabel text={'Remote'} />
                        </td>
                        <td style={{ width: '152px' }} className="text-align-right ph-medium">
                          <FieldLabel text={'Very remote'} />
                        </td>
                      </>
                    )}
                    <td style={{ width: '88px' }} className="text-align-right ph-medium">
                      <FieldLabel text={'UNIT'} />
                    </td>
                    {hasActionCol && <td style={{ width: '116px' }} className="ph-medium" />}
                  </tr>
                </tbody>
              </table>
            </div>

            {this.props.isLoading && (
              <div>
                <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
              </div>
            )}

            {noItems && !this.props.isLoading && (
              <div className="p-medium">
                <Text color={'secondary'}>
                  {isSelectable ? (
                    <>
                      No line item(s) found for this service.
                      <br />
                      <br />
                      If you would like to add a line item, please go to the Pricing section of this service.
                    </>
                  ) : (
                    'No line Item selected'
                  )}
                </Text>
              </div>
            )}
            {!this.props.isLoading && (
              <table className={'width-full'}>
                <tbody>
                  {_.map(this.state.displayLineItems, (groupedLineItems, index) => {
                    if (groupedLineItems.length === 1) {
                      const lineItem = groupedLineItems[0];
                      const isSelected = _.find(
                        this.state.allSelectedLineItems,
                        (selectedLineItem) => selectedLineItem.supportItemNumber === lineItem.supportItemNumber,
                      );
                      const lineItemData =
                        paymentSourceType === 'NDIS'
                          ? ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber)
                          : _.find(
                              this.props.vcpItems,
                              (item) => item.supportItemNumber === lineItem.supportItemNumber,
                            );
                      const isPriceControlled =
                        paymentSourceType === 'NDIS'
                          ? lineItemData && lineItemData.PriceControl === 'Y'
                          : lineItemData && lineItemData.priceControl === true;
                      return (
                        <tr className="bordered-bottom" style={{ verticalAlign: 'top' }}>
                          {isSelectable && (
                            <td style={{ width: '50px' }} className={'p-medium'}>
                              <Checkbox checked={isSelected} onClick={() => this._onClickLineItemCheckbox(lineItem)} />
                            </td>
                          )}
                          <td
                            className={'p-medium'}
                            title={lineItem.supportItemNumber}
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                          >
                            <>
                              {paymentSourceType === 'NDIS' && (
                                <>
                                  {lineItem.supportItemNumber}
                                  <br />
                                </>
                              )}
                              {paymentSourceType === 'NDIS' ? lineItemData.SupportItem : lineItem.groupName}
                            </>
                          </td>
                          {onlyShowSpecificPrice ? (
                            <td style={{ width: '321px' }} className={'text-align-right p-medium'}>
                              {isPriceAdjustable ? (
                                <>
                                  <NumberInput
                                    onChange={(event) =>
                                      this._onEditLineItemPrice(lineItem.supportItemNumber, event, 'nonRemotePrice')
                                    }
                                    value={lineItem.price.nonRemotePrice}
                                    addonBefore={'$'}
                                    min={0}
                                    max={9999.99}
                                    precision={2}
                                    style={{ width: '120px' }}
                                  />
                                </>
                              ) : isPriceControlled ? (
                                CommonUtils.formatPrice(
                                  isServiceAgreementLineItems && this.props.displayMode === 'VIEW'
                                    ? // &&
                                      // paymentSourceType === 'NDIS'
                                      Number(lineItem.agreementPrice)
                                    : paymentSourceType === 'VCP'
                                    ? lineItem.price.nonRemotePrice
                                    : mmmGroup === MmmGroup.NonRemote
                                    ? CommonUtils.findStandardPrice(lineItem, state, 'API')
                                    : mmmGroup === MmmGroup.Remote
                                    ? lineItem.price.remotePrice
                                    : lineItem.price.veryRemotePrice,
                                )
                              ) : (
                                CommonUtils.formatPrice(
                                  isServiceAgreementLineItems && this.props.displayMode === 'VIEW'
                                    ? //  &&
                                      // paymentSourceType === 'NDIS'
                                      Number(lineItem.agreementPrice)
                                    : lineItem.price.nonRemotePrice,
                                )
                              )}
                            </td>
                          ) : (
                            <>
                              <td style={{ width: '152px' }} className={'text-align-right p-medium'}>
                                {isPriceControlled &&
                                (!lineItemData.NationalNonRemote || lineItemData.NationalNonRemote === '') ? (
                                  <>
                                    <div className={'mb-large'}>
                                      {isPriceAdjustable ? (
                                        <NumberInput
                                          onChange={(event) =>
                                            this._onEditLineItemPrice(lineItem.supportItemNumber, event, 'actPrice')
                                          }
                                          value={lineItem.price.actPrice}
                                          addonBefore={'$'}
                                          min={0}
                                          max={9999.99}
                                          precision={2}
                                          style={{ width: '120px' }}
                                        />
                                      ) : (
                                        <div>{CommonUtils.formatPrice(lineItem.price.actPrice)}</div>
                                      )}
                                      {this._getPriceLabel('act')}
                                    </div>
                                    {isPriceAdjustable ? (
                                      <NumberInput
                                        onChange={(event) =>
                                          this._onEditLineItemPrice(lineItem.supportItemNumber, event, 'ntPrice')
                                        }
                                        value={lineItem.price.ntPrice}
                                        addonBefore={'$'}
                                        min={0}
                                        max={9999.99}
                                        precision={2}
                                        style={{ width: '120px' }}
                                      />
                                    ) : (
                                      <div>{CommonUtils.formatPrice(lineItem.price.ntPrice)}</div>
                                    )}
                                    {this._getPriceLabel('nt')}
                                  </>
                                ) : isPriceAdjustable ? (
                                  <NumberInput
                                    onChange={(event) =>
                                      this._onEditLineItemPrice(lineItem.supportItemNumber, event, 'nonRemotePrice')
                                    }
                                    value={lineItem.price.nonRemotePrice}
                                    addonBefore={'$'}
                                    min={0}
                                    max={9999.99}
                                    precision={2}
                                    style={{ width: '120px' }}
                                  />
                                ) : (
                                  CommonUtils.formatPrice(lineItem.price.nonRemotePrice)
                                )}
                              </td>
                              <td style={{ width: '152px' }} className={'text-align-right p-medium'}>
                                {isPriceControlled &&
                                  (isPriceAdjustable ? (
                                    <NumberInput
                                      onChange={(event) =>
                                        this._onEditLineItemPrice(lineItem.supportItemNumber, event, 'remotePrice')
                                      }
                                      value={lineItem.price.remotePrice}
                                      addonBefore={'$'}
                                      min={0}
                                      max={9999.99}
                                      precision={2}
                                      style={{ width: '120px' }}
                                    />
                                  ) : (
                                    CommonUtils.formatPrice(lineItem.price.remotePrice)
                                  ))}
                              </td>
                              <td style={{ width: '152px' }} className={'text-align-right p-medium'}>
                                {isPriceControlled &&
                                  (isPriceAdjustable && isPriceControlled ? (
                                    <NumberInput
                                      onChange={(event) =>
                                        this._onEditLineItemPrice(lineItem.supportItemNumber, event, 'veryRemotePrice')
                                      }
                                      value={lineItem.price.veryRemotePrice}
                                      addonBefore={'$'}
                                      min={0}
                                      max={9999.99}
                                      precision={2}
                                      style={{ width: '120px' }}
                                    />
                                  ) : (
                                    CommonUtils.formatPrice(lineItem.price.veryRemotePrice)
                                  ))}
                              </td>
                            </>
                          )}
                          <td style={{ width: '88px' }} className={'text-align-right p-medium'}>
                            {unitOptions.find((i) => i.value === lineItem.unit).label}
                          </td>
                          {hasActionCol && (
                            <td
                              style={{ width: '116px', display: 'table-cell' }}
                              className="p-medium flex-row align-center text-align-right"
                            >
                              {((isSelectable && isSequenceAdjustable && isSelected) ||
                                (!isSelectable && isSequenceAdjustable)) && (
                                <>
                                  {index > 0 && (
                                    <HyperlinkButton
                                      className={'mh-x-small'}
                                      onClick={(e) => this._onClickSequenceUp(e, [lineItem])}
                                    >
                                      <Icon type="arrow-up" />
                                    </HyperlinkButton>
                                  )}
                                  {((isSelectable && index < numberOfSelectedGroups - 1) ||
                                    (!isSelectable && index < this.state.displayLineItems.length - 1)) && (
                                    <HyperlinkButton
                                      className={'mh-x-small'}
                                      onClick={(e) => this._onClickSequenceDown(e, [lineItem])}
                                    >
                                      <Icon type="arrow-down" />
                                    </HyperlinkButton>
                                  )}
                                </>
                              )}
                              {isDeletable && (
                                <HyperlinkButton
                                  className={'mh-x-small'}
                                  onClick={(e) => this._onClickDelete(e, [lineItem])}
                                >
                                  <Icon type="cross" />
                                </HyperlinkButton>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    } else {
                      return (
                        <NDISLineItemGroup
                          onClickLineItem={this._onClickLineItemCheckbox}
                          onClickGeneralCheckbox={this._onClickGroupGeneralCheckbox}
                          displayMode={this.props.displayMode}
                          groupedLineItems={groupedLineItems}
                          selectedLineItems={allSelectedLineItems}
                          isSelectable={isSelectable}
                          isSequenceAdjustable={isSequenceAdjustable}
                          onClickSequenceUp={this._onClickSequenceUp}
                          onClickSequenceDown={this._onClickSequenceDown}
                          index={index}
                          numberOfDisplayLineItems={displayLineItems.length}
                          numberOfSelectedGroups={numberOfSelectedGroups}
                          isDeletable={isDeletable}
                          onDelete={this._onClickDelete}
                          onDeleteGroupLineItem={this._onDeleteGroupLineItem}
                          isPriceAdjustable={isPriceAdjustable}
                          onEditLineItemPrice={this._onEditLineItemPrice}
                          getPriceLabel={this._getPriceLabel}
                          mmmGroup={mmmGroup}
                          state={this.props.state}
                          size={this.props.size}
                          isServiceAgreementLineItems={this.props.isServiceAgreementLineItems}
                          paymentSourceType={this.props.paymentSourceType}
                          onlyShowSpecificPrice={onlyShowSpecificPrice}
                          vcpItems={this.props.vcpItems}
                        />
                      );
                    }
                  })}
                </tbody>
              </table>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  vcpItems: state.servicesStore.vcpItems,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetVCPItems: dispatch.servicesStore.doGetVCPItems,
});

export default connect(mapState, mapDispatch)(NDISLineItemGrid);
