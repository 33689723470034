import { cleanEnv, str } from 'envalid';

const FIXED_ENVIRONMENTS = {
  development: 'development',
  staging: 'staging',
  preprod: 'preprod',
  production: 'production',
};

export const metaEnvs = cleanEnv(import.meta.env, {
  MODE: str({ choices: ['test', 'development', 'production'], devDefault: 'development' }),
  VITE_NAME: str({ default: 'workspace' }),
  VITE_VERSION: str({ default: '0' }),
  VITE_PRODUCTION_ENV: str({
    devDefault: FIXED_ENVIRONMENTS.development,
  }),
});

const envs = cleanEnv(import.meta.env, {
  VITE_API_URL: str({ devDefault: 'http://localhost:8080' }),
  VITE_TRPC_URL: str({ devDefault: 'http://localhost:8093' }),
  VITE_CLOUD_FUNCTION_URL: str({ devDefault: 'http://localhost:8080' }),
  VITE_LOGIN_URL: str({ devDefault: 'http://localhost:3006/login?redirect=http://localhost:3000' }),

  // FIREBASE
  VITE_FIREBASE_API_KEY: str({ devDefault: 'AIzaSyCyrAovQB7iimDvbgB52TLhwKQuf_QnhQo' }),
  VITE_FIREBASE_AUTH_DOMAIN: str({ devDefault: 'gh-dev-718d6.firebaseapp.com' }),
  VITE_FIREBASE_PROJECT_ID: str({ devDefault: 'gh-dev-718d6' }),
  VITE_FIREBASE_STORAGE_BUCKET: str({ devDefault: 'gh-dev-718d6.appspot.com' }),
  VITE_FIREBASE_MESSAGING_SENDER_ID: str({ devDefault: '1090046020222' }),

  // OTHER
  VITE_GOOGLE_MAPS_API_KEY: str({ devDefault: 'AIzaSyCtoN5Lcy8yHrrY5UpPOLjTFQjENdf7qKI' }),
  VITE_LAUNCH_DARKLY_CLIENT_ID: str({ devDefault: '62df5ffb58fc9111310b3528' }),
  VITE_PUBNUB_API_KEY: str({ devDefault: 'sub-c-75282fce-4dee-11ea-814d-0ecb550e9de2' }),
  VITE_STRIPE_PUBLISHABLE_KEY: str({
    default:
      'pk_test_51J5lImGkPa1sNzMQwqTwhUxvoZxFpWBmfeJDiXI0oCsKvEKZrrGipkn8cuuBXHFVBvm52kEROLQeD1dptSQ65Vky00GzRFbANT',
  }),
  VITE_GTM_CLIENT_ID: str({ devDefault: 'GTM-N5KW2G3' }),
  VITE_FRILL_WIDGET_KEY: str({ devDefault: '8650a6d7-c682-4256-9eeb-47f7fad621dd' }),
});

export const APP_CONFIG = {
  API_URL: envs.VITE_API_URL,
  TRPC_URL: (envs.VITE_TRPC_URL as string | undefined) ?? envs.VITE_API_URL,
  CLOUD_FUNCTION_URL: envs.VITE_CLOUD_FUNCTION_URL,
  LOGIN_URL: envs.VITE_LOGIN_URL,
  meta: {
    MODE: metaEnvs.MODE,
    NAME: metaEnvs.VITE_NAME,
    PRODUCTION_ENV: metaEnvs.VITE_PRODUCTION_ENV,
    VERSION: metaEnvs.VITE_VERSION,
  },
};

export const FIREBASE_CONFIG = {
  apiKey: envs.VITE_FIREBASE_API_KEY,
  authDomain: envs.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: envs.VITE_FIREBASE_PROJECT_ID,
  storageBucket: envs.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: envs.VITE_FIREBASE_MESSAGING_SENDER_ID,
};

export const GOOGLE_MAPS = {
  API_KEY: envs.VITE_GOOGLE_MAPS_API_KEY,
  MAP_URL: `https://maps.googleapis.com/maps/api/js?key=${envs.VITE_GOOGLE_MAPS_API_KEY}&libraries=places`,
};

export const LAUNCH_DARKLY_CLIENT_ID = envs.VITE_LAUNCH_DARKLY_CLIENT_ID;
export const PUBNUB_API_KEY = envs.VITE_PUBNUB_API_KEY;
export const STRIPE_PUBLISHABLE_KEY = envs.VITE_STRIPE_PUBLISHABLE_KEY;
export const GTM_CLIENT_ID = envs.VITE_GTM_CLIENT_ID;
export const FRILL_WIDGET_KEY = envs.VITE_FRILL_WIDGET_KEY;

export function isProduction(env: string): boolean {
  return env === FIXED_ENVIRONMENTS.production;
}

export function loginRedirectUrl(): string {
  const loginUrl = new URL(APP_CONFIG.LOGIN_URL);
  loginUrl.searchParams.set('redirect', document.location.href);
  return loginUrl.toString();
}
