// TODO (Mitch): Replace with DS
import * as RadixDialog from '@radix-ui/react-dialog';
import { keyframes, styled } from '@stitches/react';

/*****************
 ** Content
 *****************/

const contentAnimation = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const Content = styled(RadixDialog.Content, {
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '450px',
  maxHeight: '85vh',
  animation: `${contentAnimation} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  '&:focus': { outline: 'none' },
});

/*****************
 ** Overlay
 *****************/

const overlayAnimation = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

export const Overlay = styled(RadixDialog.Overlay, {
  backgroundColor: 'rgba(0, 0, 0, 0.44)',
  position: 'fixed',
  inset: 0,
  animation: `${overlayAnimation} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

export const Close = RadixDialog.Close;
export const Description = RadixDialog.Description;
export const Portal = RadixDialog.Portal;
export const Root = RadixDialog.Root;
export const Title = RadixDialog.Title;
export const Trigger = RadixDialog.Trigger;
