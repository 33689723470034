import React, { useState } from 'react';
import { Icon } from 'antd';

import { RatioColumn } from './ratio-column';
import { TimeDurationColumns } from './time-duration-columns';
import { TimeDurationError } from './time-duration-error';
import { EditRatiosSchema } from './schema';

interface ICustomRatioRowProps {
  ratio: EditRatiosSchema['ratios'][0];
  onRemoveTimeSlot: () => void;
  index: number;
}

export function CustomRatioRow({ ratio, onRemoveTimeSlot, index }: ICustomRatioRowProps) {
  const [errorOffset, setErrorOffset] = useState(0);

  return (
    <div className="flex-column flex" style={{ gap: 2 }} key={ratio.id}>
      <div className="align-center flex" style={{ gap: 16 }} key={ratio.id}>
        <RatioColumn index={index} />
        <TimeDurationColumns setErrorOffset={setErrorOffset} index={index} />
        <Icon type="close" onClick={onRemoveTimeSlot} className="text-color-blue-action cursor-pointer" />
      </div>
      <div style={{ marginLeft: errorOffset - 16 }}>
        <TimeDurationError index={index} />
      </div>
    </div>
  );
}
