import { Inline, Text } from '@goodhuman-me/components';
import { Dropdown } from 'design-components';
import { ChevronDown, ChevronUp } from 'design-components/icon';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { INVITE_MEMBER_ROLE_TYPE, OWNER_PERMISSION, ROLE_PERMISSION_LIST } from 'views/team-v2/utils/constants';
import { Member, PermissionRole } from '../../utils/form-utils';
import { FormComponents } from '../components/FormComponents';
import PermissionCheckBoxList from '../components/PermissionCheckBoxList';

interface IProps {
  memberDetails: Member;
  teamMemberList: Member[];
}

const WorkspacePermissionColumn: React.FC<IProps> = ({ memberDetails, teamMemberList }) => {
  const { setValue } = useFormContext();

  const [visiblePopup, setVisiblePopup] = useState<boolean>(false);

  const workspacePermissionName = (roles: string[]) => {
    if (!roles.length && memberDetails?.advancedPermissions) return 'Advanced permissions';
    if (roles.includes(INVITE_MEMBER_ROLE_TYPE.OWNER)) {
      return OWNER_PERMISSION;
    }
    if (roles.length === 1) {
      const indexOfRole = ROLE_PERMISSION_LIST.findIndex((item) => item.role == roles[0]);
      return ROLE_PERMISSION_LIST[indexOfRole].label;
    }
    return `${roles.length} permissions`;
  };

  const updateTeamMemberPermissions = (roles: string[], permissions: PermissionRole[]) => {
    const index = teamMemberList.findIndex((member) => member.memberId === memberDetails?.memberId);
    const isSelectNoAccess = roles.includes(INVITE_MEMBER_ROLE_TYPE.NO_ACCESS);
    if (isSelectNoAccess) {
      setValue(`teamMemberList.${index}.hasWorkerAppAccess`, true);
    }
    setValue(`teamMemberList.${index}.roles`, roles);
    setValue(`teamMemberList.${index}.permissions`, permissions);
  };

  return (
    <FormComponents.BoxItem>
      <Dropdown
        asPseudoButton
        visible={visiblePopup}
        onVisibleChange={(status) => setVisiblePopup(status)}
        label="Workspace permissions"
        isShowCloseButton={false}
        overlay={
          <PermissionCheckBoxList
            memberSelected={memberDetails}
            onCancel={() => setVisiblePopup(false)}
            updateTeamMemberPermissions={updateTeamMemberPermissions}
          />
        }
      >
        <Inline justifyContent="space-between" padding="$space125">
          <Text fontWeight="$semi" size="small">
            {workspacePermissionName(memberDetails.roles)}
          </Text>
          {visiblePopup ? (
            <ChevronUp width={18} height={17} color="$black" />
          ) : (
            <ChevronDown width={18} height={17} color="$black" />
          )}
        </Inline>
      </Dropdown>
    </FormComponents.BoxItem>
  );
};

export default WorkspacePermissionColumn;
