import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Swatchbook = forwardRef(function Swatchbook(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2.25h-.052c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.08.595-.08 1.345-.08 2.243v10.104c0 .899 0 1.648.08 2.242.084.628.27 1.195.725 1.65.456.456 1.023.642 1.65.726.595.08 1.345.08 2.243.08h10.104c.899 0 1.648 0 2.242-.08.628-.084 1.195-.27 1.65-.726.456-.455.642-1.022.726-1.65.08-.594.08-1.343.08-2.242v-.104c0-.899 0-1.648-.08-2.242-.084-.628-.27-1.195-.726-1.65-.455-.456-1.022-.642-1.65-.726a7.329 7.329 0 0 0-.514-.049l.5-.5.035-.035c.599-.6 1.099-1.099 1.447-1.548.366-.474.63-.973.657-1.578.003-.08.003-.16 0-.24-.027-.605-.291-1.104-.657-1.578-.349-.449-.848-.949-1.448-1.548l-.034-.034-.035-.035c-.599-.6-1.099-1.099-1.548-1.447-.473-.366-.972-.63-1.578-.657a2.751 2.751 0 0 0-.24 0c-.605.026-1.104.29-1.577.657-.45.348-.95.848-1.548 1.447l-.035.035-.971.971c-.005-.57-.022-1.066-.078-1.485-.084-.628-.27-1.195-.726-1.65-.455-.456-1.022-.642-1.65-.726-.594-.08-1.344-.08-2.242-.08H7ZM10.25 8V7c0-.964-.002-1.612-.066-2.095-.063-.461-.17-.659-.3-.789-.13-.13-.328-.237-.79-.3C8.613 3.753 7.965 3.75 7 3.75s-1.612.002-2.095.067c-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788C3.753 5.388 3.75 6.036 3.75 7v10c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.788.3.483.064 1.131.066 2.095.066.964 0 1.612-.002 2.095-.067.461-.062.659-.169.789-.3.13-.13.237-.327.3-.788.064-.483.066-1.131.066-2.095V8Zm1.5.31 2.03-2.03c.643-.642 1.075-1.073 1.44-1.356.35-.27.554-.337.725-.344.036-.002.073-.002.109 0 .171.007.375.074.725.344.365.283.797.714 1.44 1.356.643.643 1.074 1.076 1.356 1.44.271.35.338.554.345.726.002.036.002.072 0 .108-.007.172-.074.376-.345.725-.282.365-.713.798-1.356 1.44l-6.469 6.47V8.31Zm-.096 11.097c-.047.297-.12.58-.236.843H17c.964 0 1.612-.002 2.095-.067.461-.062.659-.169.789-.3.13-.13.237-.327.3-.788.064-.483.066-1.131.066-2.095 0-.964-.002-1.612-.067-2.095-.062-.461-.169-.659-.3-.789-.13-.13-.327-.237-.788-.3-.43-.057-.989-.065-1.784-.066l-5.657 5.657ZM8 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
