import React, { useMemo } from 'react';
import { Box, DateFormatter, Heading, Inline, Stack, Text } from '@goodhuman-me/components';

import { DurationFormatter } from '../../../../../components/duration-formatter';
import { ProgressBar } from '../../../../../components';

import type { BoxProps, InlineProps } from '@goodhuman-me/components';
import type { Budget } from '../../../../../budget';
import type { ReactNode } from 'react';

export function getProgress({ startDate, endDate }: { startDate: Date; endDate: Date }): {
  value: number;
  formatted: string;
} {
  const currentTime = new Date().getTime();
  const endTime = endDate.getTime();
  const startTime = startDate.getTime();

  const total = endTime - startTime;
  const trackingTime = Math.max(currentTime, startTime + 1) - startTime;
  const formatted = new Intl.NumberFormat('en-AU', { style: 'percent', maximumFractionDigits: 0 }).format(
    Math.min(trackingTime / total, 1),
  );

  return { formatted, value: Math.min(trackingTime / total, 1) * 100 };
}

type BudgetingPeriodDetailProps = {
  label: string;
  value: string | ReactNode;
} & InlineProps;

function BudgetingPeriodDetail(props: BudgetingPeriodDetailProps) {
  const { label, value, ...otherProps } = props;

  return (
    <Inline justifyContent="space-between" gap="$space100" {...otherProps} asChild>
      {/** TODO (Mitch): Add flex wrap to <Inline> */}
      <div style={{ flexWrap: 'wrap' }}>
        <Text size="small" textAlign="left">
          {label}
        </Text>
        <Text fontWeight="$bold" size="small" textAlign="right">
          {value}
        </Text>
      </div>
    </Inline>
  );
}

export type BudgetingPeriodProps = Pick<Budget['overview']['service'], 'startDate' | 'endDate'> & BoxProps;

export function BudgetingPeriod(props: BudgetingPeriodProps): JSX.Element {
  const { startDate, endDate, ...otherProps } = props;
  const progress = useMemo(() => getProgress({ startDate, endDate }), [startDate, endDate]);

  return (
    <Box {...otherProps} maxHeight="314px">
      <Stack gap="$space800" height="100%" justifyContent="space-between">
        <Heading size="xsmall" fontWeight="$semi">
          Agreement period
        </Heading>
        <Stack gap="$space100">
          <BudgetingPeriodDetail label="Start on" value={<DateFormatter kind="display" value={startDate} />} />
          <BudgetingPeriodDetail label="End on" value={<DateFormatter kind="display" value={endDate} />} />
          <BudgetingPeriodDetail label="Total duration" value={<DurationFormatter value={{ startDate, endDate }} />} />
        </Stack>

        <ProgressBar
          color="$lavender"
          label="Total duration"
          value={progress.value}
          valueLabel={`Elapsed ${progress.formatted}`}
          width="100%"
        />
      </Stack>
    </Box>
  );
}
