/* BOOM-475 - fix linting errors */
import React from 'react';
import { Box, Modal, Text } from 'design-components';
import { Button } from '@good/components';

export interface IModalShortMessageProps {
  isOpen: boolean;
  onClose?: () => void;
  modalType: ModalType;
  onPressPrimaryButton?: () => void;
  onPressSecondaryButton?: () => void;
  isLoading: boolean;
}

export enum ModalType {
  REMOVE_PROFILE_PICTURE = 'REMOVE_PROFILE_PICTURE',
  UNSAVED_CHANGES = 'UNSAVED_CHANGES',
  DELETE_PICTURE = 'DELETE_PICTURE',
  FILE_SIZE_LIMIT = 'FILE_SIZE_LIMIT',
}

export const ModalShortMessage = (props: IModalShortMessageProps) => {
  const { isOpen, modalType, isLoading, onClose, onPressPrimaryButton, onPressSecondaryButton } = props;

  const ModalExtensions = {
    [ModalType.REMOVE_PROFILE_PICTURE]: {
      title: 'Remove profile picture',
      body: [
        'Do you want to remove this customer’s profile picture?',
        'You will still be able to access this picture in Uploads.',
      ],
      modalWidth: 600,
      modalHeight: 252,
      showCloseIcon: true,
      secondaryButton: {
        title: 'Cancel',
        width: 102,
        titleWidth: 54,
      },
      primaryButton: {
        title: 'Remove',
        width: 110,
        titleWidth: 62,
      },
    },
    [ModalType.UNSAVED_CHANGES]: {
      title: 'Unsaved changes',
      body: ['Do you want to discard the changes you’ve made?', 'If you’d like to save changes press “Keep editing”.'],
      modalWidth: 600,
      modalHeight: 252,
      showCloseIcon: false,
      secondaryButton: {
        title: 'Discard changes',
        width: 177,
        titleWidth: 129,
      },
      primaryButton: {
        title: 'Keep editing',
        width: 145,
        titleWidth: 97,
      },
    },
    [ModalType.DELETE_PICTURE]: {
      title: 'Delete picture',
      body: ['Do you want to permanently delete this picture?', 'You will not be able to recover it.'],
      modalWidth: 600,
      modalHeight: 252,
      showCloseIcon: false,
      secondaryButton: {
        title: 'Cancel',
        width: 102,
        titleWidth: 54,
      },
      primaryButton: {
        title: 'Delete',
        width: 98,
        titleWidth: 50,
      },
    },
    [ModalType.FILE_SIZE_LIMIT]: {
      title: 'File size limit',
      body: ['The file you selected is too large. Please select a file smaller than 25 MB.'],
      modalWidth: 600,
      modalHeight: 252,
      showCloseIcon: true,
      secondaryButton: null,
      primaryButton: {
        title: 'Close',
        width: 92,
        titleWidth: 44,
      },
    },
  };

  const modalData = ModalExtensions[modalType];

  const isCriticalPrimaryButton =
    modalType === ModalType.REMOVE_PROFILE_PICTURE || modalType === ModalType.DELETE_PICTURE;

  return (
    <Modal
      isLockScroll
      height={modalData.modalHeight}
      maxWidth={modalData.modalWidth}
      header={
        <Text emphasis="bold" size="medium" color={'$black'}>
          {modalData.title}
        </Text>
      }
      headerStyles={{
        padding: '26px 24px',
        height: '80px',
      }}
      onClose={onClose}
      size={'large'}
      bodyStyles={{
        flex: 1,
        overflowY: 'hidden',
        display: 'flex',
        paddingTop: '8px',
        paddingBottom: '8px',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      isOpen={isOpen}
      customCloseButtonStyles={{
        width: '32px',
        height: '32px',
      }}
      footer={
        <div className="p-small flex justify-end">
          {modalData?.secondaryButton && (
            <Button emphasis="quiet" size="lg" color="$bodyDark2" onPress={onPressSecondaryButton}>
              {modalData.secondaryButton?.title}
            </Button>
          )}

          {modalData?.primaryButton && (
            <Button
              emphasis="fill"
              tone={isCriticalPrimaryButton ? 'critical' : 'accent'}
              size="lg"
              onPress={onPressPrimaryButton}
              isDisabled={isLoading}
            >
              {modalData.primaryButton?.title}
            </Button>
          )}
        </div>
      }
      closable={modalData.showCloseIcon}
    >
      {modalData.body.map((item, index) => (
        <Box key={item} justifyContent="center" alignItems="center">
          <Text key={index} emphasis="regular" color={'$bodyDark2'} fontSize={'$font100 !important'}>
            {item}
          </Text>
        </Box>
      ))}
    </Modal>
  );
};
