import { IShiftClashConflict } from 'interfaces/service-interfaces';
import _ from 'lodash';

class ConflictCheckUtils {
  static filterOutsideAvailabilityShiftsGroupedByWorkerAfterSelectingShiftClashShifts = (
    ignoreShiftClashShiftIds: string[],
    allShiftClashConflicts: IShiftClashConflict[],
    shiftsOutsideGeneralAvailability: any[],
  ) => {
    const shiftClashShiftIds = _.reduce(
      allShiftClashConflicts,
      (shiftIds: string[], worker) => {
        shiftIds = shiftIds.concat(
          _.map(worker.selectedShifts, (selectedShift) => selectedShift.supportWorkerAttendanceId),
        );

        return shiftIds;
      },
      [],
    );

    const remainedOutsideAvailabilityShifts = _.reduce(
      shiftsOutsideGeneralAvailability,
      (shifts: any[], shift) => {
        const remainingShifts = _.filter(shift.shifts, (selectedShift) => {
          return (
            _.some(ignoreShiftClashShiftIds, (shiftId) => shiftId === selectedShift.supportWorkerAttendanceId) ||
            !_.some(shiftClashShiftIds, (shiftId) => shiftId === selectedShift.supportWorkerAttendanceId)
          );
        });

        if (!_.isEmpty(remainingShifts)) {
          shifts.push({ ...shift, shifts: remainingShifts });
        }

        return shifts;
      },
      [],
    );

    return remainedOutsideAvailabilityShifts;
  };

  static filterOutsideAvailabilityShiftsAfterSelectingShiftClashShiftById(
    shiftClashShifts: IShiftClashConflict[],
    ignoreShiftClashShiftIds: string[],
    shiftsOutsideGeneralAvailability: any[],
  ) {
    const shiftClashShiftIds = _.reduce(
      shiftClashShifts,
      (shiftIds: string[], worker) => {
        shiftIds = shiftIds.concat(
          _.map(
            worker.selectedShifts,
            (selectedShift) => selectedShift.supportWorkerAttendanceId || selectedShift.serviceDateTimeId,
          ),
        );

        return shiftIds;
      },
      [],
    );

    const remainedOutsideAvailabilityShifts = _.filter(shiftsOutsideGeneralAvailability, (shift) => {
      return (
        _.some(ignoreShiftClashShiftIds, (shiftId) =>
          shift.supportWorkerAttendanceId
            ? shiftId === shift.supportWorkerAttendanceId
            : shiftId === shift.serviceDateTimeId,
        ) ||
        !_.some(shiftClashShiftIds, (shiftId) =>
          shift.supportWorkerAttendanceId
            ? shiftId === shift.supportWorkerAttendanceId
            : shiftId === shift.serviceDateTimeId,
        )
      );
    });

    return remainedOutsideAvailabilityShifts;
  }

  static filterIgnoredConflictedShiftIds(
    allConflictingShiftIds: string[],
    ignoredShiftClashShiftIds: string[] = [],
    ignoredUnavailableShiftIds: string[] = [],
    shiftClashShiftIds: string[],
    unavailableShiftIds: string[],
  ): string[] {
    let shifts = _.values(allConflictingShiftIds);

    _.remove(
      shifts,
      (supportWorkerAttendanceId) =>
        _.includes(ignoredUnavailableShiftIds, supportWorkerAttendanceId) ||
        _.includes(ignoredShiftClashShiftIds, supportWorkerAttendanceId),
    );

    const intersectingConflictShiftIds = _.filter(
      shiftClashShiftIds,
      (supportWorkerAttendanceId) =>
        _.includes(unavailableShiftIds, supportWorkerAttendanceId) &&
        (!_.includes(ignoredUnavailableShiftIds, supportWorkerAttendanceId) ||
          !_.includes(ignoredShiftClashShiftIds, supportWorkerAttendanceId)),
    );

    shifts = _.union(shifts, intersectingConflictShiftIds);

    return shifts;
  }
}

export default ConflictCheckUtils;
