import React, { useMemo } from 'react';
import { FieldValues, UseFormProps } from 'react-hook-form';

import { InlineFormProvider } from './inline-form-context';
import { Header } from './header';
import { InlineTextField, InlineTextFieldProps } from './inline-text-field';
import { InlineTextareaField, InlineTextareaFieldProps } from './inline-textarea-field';
import { InlineNumberField, InlineNumberFieldProps } from './inline-number-field';
import { InlineSelectField, InlineSelectFieldProps } from './inline-select-field';
import { InlineImageField, InlineImageFieldProps } from './inline-image-field';
import { InlineAddressField, InlineAddressFieldProps } from './inline-address-field';
import { InlineDatePickerField, InlineDatePickerFieldProps } from './inline-date-picker-field';
import { InlineSwitchField, InlineSwitchFieldProps } from './inline-switch-field';
import { InlineSwitch, InlineSwitchProps } from './inline-switch';
import { InlineStaticField, InlineStaticFieldProps } from './inline-static-field';
import { InlineRichTextEditorField, InlineRichTextEditorFieldProps } from './inline-rich-text-editor-field';
import { useFreezeFormProps } from './use-freeze-form-props';

const classNameDefault = 'border-b border-gray-light-2 pb-6';
const classNameEditingDefault = 'rounded-2xl border p-6 shadow-inline-form';
const classNameInnerDefault = 'flex flex-col gap-6';

type InlineFormProps<T extends FieldValues> = {
  children: React.ReactNode;
  className?: string;
  classNameEditing?: string;
  classNameInner?: string;
  disabled?: boolean;
  loading?: boolean;
  maxWidth?: React.CSSProperties['maxWidth'];
  onSubmit: (values: T) => void | Promise<void>;
  timezone?: string;
  title: string;
};

export const useInlineForm = <T extends FieldValues>(formProps?: UseFormProps<T, unknown> | undefined) => {
  const formPropsFrozen = useFreezeFormProps(formProps);

  return useMemo(() => {
    const InlineForm = ({
      children,
      className,
      classNameEditing,
      classNameInner,
      disabled,
      loading,
      maxWidth,
      onSubmit,
      timezone,
      title,
    }: InlineFormProps<T>) => {
      return (
        <InlineFormProvider<T>
          className={className ?? classNameDefault}
          classNameEditing={classNameEditing ?? classNameEditingDefault}
          disabled={disabled}
          formProps={formPropsFrozen}
          loading={loading}
          maxWidth={maxWidth}
          onSubmit={onSubmit}
          timezone={timezone}
          title={title}
        >
          <Header />
          <div className={classNameInner ?? classNameInnerDefault}>{children}</div>
        </InlineFormProvider>
      );
    };

    function TextFieldComponent(props: InlineTextFieldProps<T>): JSX.Element {
      return <InlineTextField<T> {...props} />;
    }

    function TextareaFieldComponent(props: InlineTextareaFieldProps<T>): JSX.Element {
      return <InlineTextareaField<T> {...props} />;
    }

    function NumberFieldComponent(props: InlineNumberFieldProps<T>): JSX.Element {
      return <InlineNumberField<T> {...props} />;
    }

    function SelectFieldComponent(props: InlineSelectFieldProps<T>): JSX.Element {
      return <InlineSelectField<T> {...props} />;
    }

    function ImageFieldComponent(props: InlineImageFieldProps<T>): JSX.Element {
      return <InlineImageField<T> {...props} />;
    }

    function AddressFieldComponent(props: InlineAddressFieldProps<T>): JSX.Element {
      return <InlineAddressField<T> {...props} />;
    }

    function DatePickerFieldComponent(props: InlineDatePickerFieldProps<T>): JSX.Element {
      return <InlineDatePickerField<T> {...props} />;
    }

    function SwitchFieldComponent(props: InlineSwitchFieldProps<T>): JSX.Element {
      return <InlineSwitchField<T> {...props} />;
    }

    function SwitchComponent(props: InlineSwitchProps<T>): JSX.Element {
      return <InlineSwitch<T> {...props} />;
    }

    function StaticFieldComponent(props: InlineStaticFieldProps): JSX.Element {
      return <InlineStaticField {...props} />;
    }

    function RichTextEditorComponent(props: InlineRichTextEditorFieldProps<T>): JSX.Element {
      return <InlineRichTextEditorField<T> {...props} />;
    }

    TextFieldComponent.displayName = 'InlineForm.TextField';
    TextareaFieldComponent.displayName = 'InlineForm.TextareaField';
    NumberFieldComponent.displayName = 'InlineForm.NumberField';
    SelectFieldComponent.displayName = 'InlineForm.SelectField';
    ImageFieldComponent.displayName = 'InlineForm.ImageField';
    AddressFieldComponent.displayName = 'InlineForm.AddressField';
    DatePickerFieldComponent.displayName = 'InlineForm.DatePickerField';
    SwitchFieldComponent.displayName = 'InlineForm.SwitchField';
    SwitchComponent.displayName = 'InlineForm.Switch';
    StaticFieldComponent.displayName = 'InlineForm.StaticField';
    RichTextEditorComponent.displayName = 'InlineForm.RichTextEditor';
    InlineForm.TextField = TextFieldComponent;
    InlineForm.TextareaField = TextareaFieldComponent;
    InlineForm.NumberField = NumberFieldComponent;
    InlineForm.SelectField = SelectFieldComponent;
    InlineForm.ImageField = ImageFieldComponent;
    InlineForm.AddressField = AddressFieldComponent;
    InlineForm.DatePickerField = DatePickerFieldComponent;
    InlineForm.SwitchField = SwitchFieldComponent;
    InlineForm.Switch = SwitchComponent;
    InlineForm.StaticField = StaticFieldComponent;
    InlineForm.RichTextEditor = RichTextEditorComponent;

    return InlineForm;
  }, [formPropsFrozen]);
};
