import { Text } from 'common-components/typography';
import React, { PureComponent } from 'react';

interface ILocationDisplayProps {
  location: any;
}

class LocationDisplay extends PureComponent<ILocationDisplayProps, any> {
  render() {
    const { location } = this.props;
    return <Text style={{ whiteSpace: 'pre-line' }}>{location}</Text>;
  }
}
export default LocationDisplay;
