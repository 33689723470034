import { notification } from 'antd';
import { Text } from 'common-components/typography';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { DurationUnit, PaymentStatus, ShiftSlotStatus } from 'utilities/enum-utils';
import { PrimaryButton, SecondaryButton } from '../../../common-components/buttons/index';
import ActivityRecordTimeSelectSection, { ITimeSelectionOnChangeData } from './ActivityRecordTimeSelectSection';

interface IActivityRecordEditTimeActionModalV2Props {
  isOpen: boolean;
  onClose: () => void;
  booking: any;
  timezone: string;
  doEditActivityRecordHours: typeof dispatch.bookingsStore.doEditActivityRecordHours;
  isSentToTimeSheet: boolean;
}

export const ActivityRecordEditTimeActionModalV2 = (props: IActivityRecordEditTimeActionModalV2Props) => {
  const { booking, timezone, isOpen, onClose } = props;
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment.tz(timezone));
  const [endDate, setEndDate] = useState(moment.tz(timezone));
  const [duration, setDuration] = useState(0);
  const [durationUnit, setDurationUnit] = useState(DurationUnit.MINUTE);
  const [includeStartEndTime, setIncludeStartEndTime] = useState(booking.includeStartEndTime);

  useEffect(() => {
    const { startDateTime, endDateTime, durationUnit } = props.booking;
    setStartDate(startDateTime);
    setEndDate(endDateTime);
    setDurationUnit(durationUnit);
    const durationData = moment.tz(endDateTime, timezone).diff(moment.tz(startDateTime, timezone), 'minutes');
    setDuration(durationData);
  }, [props.booking]);

  const _handleOnChangedData = (data: ITimeSelectionOnChangeData) => {
    const { dateTime, duration, durationUnit, includeStartEndTime } = data;
    duration === 0 ? setIsDisabled(true) : setIsDisabled(false);
    const { startDate, endDate } = dateTime;
    setStartDate(moment.tz(startDate, timezone));
    setEndDate(moment.tz(endDate, timezone));
    setDuration(Number(duration));
    setDurationUnit(durationUnit);
    setIncludeStartEndTime(includeStartEndTime);
  };

  const _onSave = useCallback(async () => {
    setisLoading(true);
    const { booking, doEditActivityRecordHours, isSentToTimeSheet } = props;
    const { paymentStatus } = booking;

    const updatedStartDateTime = moment.tz(moment(startDate), timezone);
    const updatedEndDateTime = moment.tz(moment(endDate), timezone);

    const shiftSlotStatusForSentToTimeSheet =
      isSentToTimeSheet && paymentStatus === PaymentStatus.REQUIRES_APPROVAL
        ? ShiftSlotStatus.CHECKED_OUT
        : ShiftSlotStatus.SENT_TO_FINANCE;

    try {
      await doEditActivityRecordHours({
        bookingId: booking.bookingId,
        startDateTime: updatedStartDateTime,
        endDateTime: updatedEndDateTime,
        shiftSlotStatus: isSentToTimeSheet ? shiftSlotStatusForSentToTimeSheet : booking.shiftSlotStatus,
        isDuration: true,
        unit: durationUnit,
        includeStartEndTime: includeStartEndTime,
      });
      isSentToTimeSheet
        ? notification.success({
            message: 'Hours added to timesheet',
            description: 'This activity record has been added to the team members timesheet pending record approved.',
          })
        : notification.success({
            message: 'Time updated',
            description: 'You have successfully update the time of this record.',
          });
      props.onClose();
      setisLoading(false);
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong, please try again.',
      });
      props.onClose();
    }
  }, [props.booking, props.doEditActivityRecordHours, startDate, endDate, durationUnit]);

  return (
    <div>
      <ActionModal
        title={[
          <Text key={''} size="x2-large" weight="bold">
            {props.isSentToTimeSheet ? 'Add hours to timesheet' : 'Edit time'}
          </Text>,
        ]}
        isOpen={isOpen}
        className="ant-modal-custom p-12"
        onClose={onClose}
        width="medium"
      >
        <div>
          {props.isSentToTimeSheet ? (
            <Text>
              Are your sure you want to add this activity record to the assigned team members timesheet? (Pending record
              approval)
            </Text>
          ) : (
            <div className="mt-medium">
              <ActivityRecordTimeSelectSection
                onChange={_handleOnChangedData}
                startDate={startDate}
                endDate={endDate}
                duration={duration}
                durationTimeBlock={booking.defaultActivityRecordIncrement}
                durationUnit={durationUnit}
                includeStartEndTime={includeStartEndTime}
                timezone={timezone}
              />
            </div>
          )}

          <ActionModalFooter>
            <SecondaryButton size={'large'} onClick={onClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size={'large'}
              className={'ml-medium'}
              disabled={props.isSentToTimeSheet ? false : isDisabled}
              loading={isLoading}
              onClick={_onSave}
            >
              {props.isSentToTimeSheet ? 'Add to timesheet' : 'Save'}
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    </div>
  );
};

const mapState = (state: IRootState) => ({ selectedBookingItem: state.bookingsStore.selectedBookingItem });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditActivityRecordHours: dispatch.bookingsStore.doEditActivityRecordHours,
});

export default connect(mapState, mapDispatch)(ActivityRecordEditTimeActionModalV2);
