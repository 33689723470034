import React, { Component } from 'react';
import InfoPanel from 'common-components/alerts/InfoPanel';
import { FieldLabel, Text } from 'common-components/typography';
import FilterSection from 'common-components/filter/FilterSection';
import { Col, Row } from 'antd/es';
import { GridHeader } from 'common-components/grids';
import { Checkbox, Skeleton, Empty } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';
import TimesheetSessionRow from './TimesheetSessionRow';
import { FilterType, ShiftSlotStatus } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import TimesheetShiftSlotRow from './TimesheetShiftSlotRow';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { IGroupServiceTimesheetShiftSlot } from 'interfaces/service-interfaces';
import moment from 'moment';
import GridRow from 'common-components/grids/GridRow';
import TimesheetBulkActionSheet from './TimesheetBulkActionSheet';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ApproveTimesheetModal from './ApproveTimesheetModal';
import BulkApproveTimesheetModal from './BulkApproveTimesheetModal';
import EditTimeSheetModal from './EditTimeSheetModal';
import BulkEditTimesheetModal from './BulkEditTimesheetModal';
import ApproveAllPendingTimesheetModal from './ApproveAllPendingTimesheetModal';

interface IServiceTimesheetPendingPanelProps {
  timesheetSessionList: typeof state.groupServiceStore.timesheetSessionList;
  doFetchGroupServiceTimesheetSessions: typeof dispatch.groupServiceStore.doFetchGroupServiceTimesheetSessions;
  timesheetShiftSlotList: typeof state.groupServiceStore.timesheetShiftSlotList;
  doFetchGroupServiceTimesheetShiftSlots: typeof dispatch.groupServiceStore.doFetchGroupServiceTimesheetShiftSlots;
  setTimesheetShiftSlotList: typeof dispatch.groupServiceStore.setTimesheetShiftSlotList;
  serviceId: string;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  doGetAllPendingShiftSlots: typeof dispatch.groupServiceStore.doGetAllPendingShiftSlots;
  portalUser: typeof state.authStore.portalUser;
}

interface IServiceTimesheetPendingPanelState {
  selectedSessionId: string | null;
  isLoadingWorker: boolean;
  selectedShiftSlots: IGroupServiceTimesheetShiftSlot[];
  schedule: number;
  actual: number;
  diff: number;
  isSelectAll: boolean;
  shiftSlotFilter: any[];
  isLoadingSession: boolean;
  isBulkApproveOpen: boolean;
  isApproveOpen: boolean;
  isBulkEditOpen: boolean;
  isEditOpen: boolean;
  selectedShiftSlot: IGroupServiceTimesheetShiftSlot;
  isApproveAllOpen: boolean;
}

const shiftSlotStatus = ShiftSlotStatus.APPROVED;

class ServiceTimesheetPendingPanel extends Component<
  IServiceTimesheetPendingPanelProps,
  IServiceTimesheetPendingPanelState
> {
  state = {
    selectedSessionId: '',
    isLoadingWorker: false,
    selectedShiftSlots: [],
    schedule: 0,
    actual: 0,
    diff: 0,
    isSelectAll: false,
    shiftSlotFilter: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName,
      },
    ],
    isLoadingSession: false,
    isBulkApproveOpen: false,
    isApproveOpen: false,
    isBulkEditOpen: false,
    isEditOpen: false,
    selectedShiftSlot: null,
    isApproveAllOpen: false,
  };

  private _doRefresh = async () => {
    const { doFetchGroupServiceTimesheetSessions, doGetAllPendingShiftSlots } = this.props;
    this.setState({ isLoadingSession: true });

    await Promise.all([
      doFetchGroupServiceTimesheetSessions({
        serviceId: this.props.serviceId,
        filters: this.state.shiftSlotFilter,
        shiftSlotStatus,
      }),
      doGetAllPendingShiftSlots({ serviceId: this.props.serviceId }),
    ]);

    if (!_.isEmpty(this.props.timesheetSessionList)) {
      await this._onSelectSession(this.props.timesheetSessionList[0].serviceDateTimeId);
    }

    this.setState({ isLoadingSession: false });
  };

  async componentDidMount() {
    await this._doRefresh();
  }

  private _onSelectSession = async (sessionId) => {
    const { doFetchGroupServiceTimesheetShiftSlots } = this.props;
    this.setState({
      selectedSessionId: sessionId,
      isLoadingWorker: true,
      selectedShiftSlots: [],
      schedule: 0,
      actual: 0,
      diff: 0,
    });

    await doFetchGroupServiceTimesheetShiftSlots({ serviceId: this.props.serviceId, sessionId, shiftSlotStatus });

    const { schedule, actual, diff } = _.reduce(
      this.props.timesheetShiftSlotList,
      (acc, shiftSlot) => {
        const checkInMoment = moment(shiftSlot.workerCheckedInDateTime);
        const checkOutMoment = moment(shiftSlot.workerCheckedOutDateTime);
        const shiftStartMoment = moment(shiftSlot.shiftStartDateTime);
        const shiftEndMoment = moment(shiftSlot.shiftEndDateTime);

        const scheduleDiff = shiftEndMoment.diff(shiftStartMoment);
        const actualDiff = checkOutMoment.diff(checkInMoment);

        const different = Math.abs(scheduleDiff - actualDiff);

        return {
          schedule: acc.schedule + scheduleDiff,
          actual: acc.actual + actualDiff,
          diff: acc.diff + different,
        };
      },
      { schedule: 0, actual: 0, diff: 0 },
    );

    this.setState({ isLoadingWorker: false, schedule, actual, diff, isSelectAll: false, selectedShiftSlots: [] });
  };

  private _getHoverContent = (shiftSlot: IGroupServiceTimesheetShiftSlot) => {
    return (
      <ActionMenu>
        <ActionMenuItem text={'Edit shift'} onClick={() => this._onOpenEditShiftSlot(shiftSlot)} />
        <ActionMenuItem text={'Approve shift'} onClick={() => this._onOpenApproveShiftSlot(shiftSlot)} />
      </ActionMenu>
    );
  };

  private _onOpenEditShiftSlot = (shiftSlot: IGroupServiceTimesheetShiftSlot) => {
    this.setState({ selectedShiftSlot: shiftSlot, isEditOpen: true });
  };

  private _onCloseEditShiftSlot = async (isRefresh: boolean) => {
    this.setState({ isEditOpen: false, selectedShiftSlot: null });
    if (isRefresh) {
      await this._onSelectSession(this.state.selectedSessionId);
    }
  };

  private _onOpenApproveShiftSlot = (shiftSlot: IGroupServiceTimesheetShiftSlot) => {
    this.setState({ selectedShiftSlot: shiftSlot, isApproveOpen: true });
  };

  private _onCloseApproveShiftSlot = async (isRefresh: boolean) => {
    this.setState({ isApproveOpen: false });
    if (isRefresh) {
      await this._onSelectSession(this.state.selectedSessionId);
    }
  };

  private _onOpenBulkEditShiftSlotModal = () => {
    this.setState({ isBulkEditOpen: true });
  };

  private _onCloseBulkEditShiftSlotModal = async (isRefresh: boolean) => {
    this.setState({ isBulkEditOpen: false });
    if (isRefresh) {
      await this._onSelectSession(this.state.selectedSessionId);
    }
  };

  private _onOpenBulkApproveShiftSlotsModal = () => {
    this.setState({ isBulkApproveOpen: true });
  };

  private _onCloseBulkApproveShiftSlotModal = async (isRefresh) => {
    this.setState({ isBulkApproveOpen: false });
    if (isRefresh) {
      await this._onSelectSession(this.state.selectedSessionId);
    }
  };

  private _onOpenApproveAllModel = () => {
    this.setState({ isApproveAllOpen: true });
  };

  private _onCloseApproveAllModel = async (isRefresh: boolean) => {
    this.setState({ isApproveAllOpen: false });
    if (isRefresh) {
      await this._doRefresh();
    }
  };

  private _onCheckShiftSlot = (shiftSlot: IGroupServiceTimesheetShiftSlot) => {
    const { timesheetShiftSlotList, setTimesheetShiftSlotList } = this.props;

    const newList = _.map(timesheetShiftSlotList, (timesheetShift) => {
      if (timesheetShift.supportWorkerAttendanceId === shiftSlot.supportWorkerAttendanceId) {
        timesheetShift.isChecked = !timesheetShift.isChecked;
      }
      return timesheetShift;
    });

    const selectedShiftSlots = _.filter(newList, (item) => item.isChecked);

    setTimesheetShiftSlotList(newList);

    if (!_.isEmpty(selectedShiftSlots) && selectedShiftSlots.length === timesheetShiftSlotList.length) {
      this.setState({ selectedShiftSlots, isSelectAll: true });
    } else {
      this.setState({ selectedShiftSlots });
    }
  };

  private _onCheckAllShift = () => {
    const { timesheetShiftSlotList, setTimesheetShiftSlotList } = this.props;
    if (this.state.isSelectAll === true) {
      const newList = _.map(timesheetShiftSlotList, (timesheetShift) => {
        timesheetShift.isChecked = false;
        return timesheetShift;
      });

      setTimesheetShiftSlotList(newList);
      this.setState({ selectedShiftSlots: [], isSelectAll: false });
    } else {
      const newList = _.map(timesheetShiftSlotList, (timesheetShift) => {
        timesheetShift.isChecked = true;
        return timesheetShift;
      });

      setTimesheetShiftSlotList(newList);
      this.setState({ selectedShiftSlots: timesheetShiftSlotList, isSelectAll: true });
    }
  };

  private _onDeselectAllShift = () => {
    const { timesheetShiftSlotList, setTimesheetShiftSlotList } = this.props;

    const newList = _.map(timesheetShiftSlotList, (timesheetShift) => {
      timesheetShift.isChecked = false;
      return timesheetShift;
    });

    setTimesheetShiftSlotList(newList);
    this.setState({ selectedShiftSlots: [], isSelectAll: false });
  };

  private _onChangeFilter = (filter) => {
    const { doFetchGroupServiceTimesheetSessions } = this.props;
    this.setState({ shiftSlotFilter: filter, isLoadingSession: true }, async () => {
      await doFetchGroupServiceTimesheetSessions({
        serviceId: this.props.serviceId,
        filters: filter,
        shiftSlotStatus,
      });

      this.setState({ isLoadingSession: false, selectedSessionId: null });
    });
  };

  render() {
    const { timesheetSessionList, timesheetShiftSlotList, selectedGroupService, portalUser } = this.props;
    const { selectedSessionId, selectedShiftSlots } = this.state;

    const infoContent = (
      <>
        <Text color='secondary'>
          The following timesheet have not been <b>approved</b>.
        </Text>
        <br />
        <Text color='secondary'>
          Only <b>Approved</b> timesheet will appear when you export your timesheets out for wages.
        </Text>
      </>
    );

    return (
      <div className='mt-medium'>
        {this.state.isApproveOpen && (
          <ApproveTimesheetModal
            isOpen={this.state.isApproveOpen}
            onClose={this._onCloseApproveShiftSlot}
            selectedShiftSlot={this.state.selectedShiftSlot}
            selectedSessionId={this.state.selectedSessionId}
          />
        )}
        {this.state.isBulkApproveOpen && (
          <BulkApproveTimesheetModal
            isOpen={this.state.isBulkApproveOpen}
            onClose={this._onCloseBulkApproveShiftSlotModal}
            selectedShiftSlots={this.state.selectedShiftSlots}
            selectedSessionId={this.state.selectedSessionId}
          />
        )}
        {this.state.isEditOpen && (
          <EditTimeSheetModal
            isOpen={this.state.isEditOpen}
            onClose={this._onCloseEditShiftSlot}
            selectedShiftSlot={this.state.selectedShiftSlot}
            selectedSessionId={this.state.selectedSessionId}
          />
        )}
        {this.state.isBulkEditOpen && (
          <BulkEditTimesheetModal
            isOpen={this.state.isBulkEditOpen}
            onClose={this._onCloseBulkEditShiftSlotModal}
            selectedShiftSlots={selectedShiftSlots}
            selectedSession={
              _.find(timesheetSessionList, (session) => session.serviceDateTimeId === selectedSessionId)!
            }
          />
        )}
        {this.state.isApproveAllOpen && (
          <ApproveAllPendingTimesheetModal
            isOpen={this.state.isApproveAllOpen}
            onClose={this._onCloseApproveAllModel}
          />
        )}
        <InfoPanel text={infoContent} />

        <div className=' mt-large'>
          <div className='flex-row justify-between'>
            <FilterSection
              availableFilters={[FilterType.DATE_RANGE]}
              filters={this.state.shiftSlotFilter}
              onChangeFilter={this._onChangeFilter}
              displayTimezone={selectedGroupService.timezone}
              containerClassName='flex-row justify-between'
              displayMoreFilter={false}
            />
            <PrimaryButton size='large' onClick={this._onOpenApproveAllModel}>
              Approve all pending
            </PrimaryButton>
          </div>
          <div className='mt-large'>
            <div className=' rounded-big bordered flex-column'>
              <Row className='bordered-bottom'>
                <Col span={3} className='bordered-right'>
                  <GridHeader bordered={false} gutter={0}>
                    <Col span={24} style={{ padding: 0, marginLeft: 8 }}>
                      <FieldLabel text='session date' />
                    </Col>
                  </GridHeader>
                </Col>
                <Col span={21}>
                  <GridHeader bordered={false} gutter={16}>
                    <Col span={1}>
                      <Checkbox
                        onClick={this._onCheckAllShift}
                        indeterminate={
                          !_.isEmpty(this.state.selectedShiftSlots) &&
                          this.state.selectedShiftSlots.length !== this.props.timesheetShiftSlotList.length
                        }
                        checked={
                          !_.isEmpty(this.state.selectedShiftSlots) &&
                          this.state.selectedShiftSlots.length === this.props.timesheetShiftSlotList.length
                        }
                      />
                    </Col>
                    <Col span={6}>
                      <div className='pl-medium'>
                        <FieldLabel text='team member' />
                      </div>
                    </Col>
                    <Col span={3}>
                      <FieldLabel text='in' />
                    </Col>
                    <Col span={3}>
                      <FieldLabel text='out' />
                    </Col>
                    <Col span={3} className='text-align-right'>
                      <FieldLabel text='scheduled' />
                    </Col>
                    <Col span={3} className='text-align-right'>
                      <FieldLabel text='actual' />
                    </Col>
                    <Col span={3} className='text-align-right'>
                      <FieldLabel text='diff.' />
                    </Col>
                    <Col span={2} />
                  </GridHeader>
                </Col>
              </Row>
              <Row>
                <Col span={3} className='bordered-right' style={{ minHeight: '30vh' }}>
                  {this.state.isLoadingSession ? (
                    <>
                      <Skeleton paragraph={{ rows: 1, width: '100%' }} active={true} className='anim-slide-left' />
                      <Skeleton paragraph={{ rows: 1, width: '100%' }} active={true} className='anim-slide-left' />
                      <Skeleton paragraph={{ rows: 1, width: '100%' }} active={true} className='anim-slide-left' />
                    </>
                  ) : _.isEmpty(timesheetSessionList) ? (
                    <></>
                  ) : (
                    _.map(timesheetSessionList, (session) => {
                      return (
                        <TimesheetSessionRow
                          session={session}
                          isSelected={session.serviceDateTimeId === selectedSessionId}
                          onSelectSession={this._onSelectSession}
                          timezone={selectedGroupService.timezone}
                          key={session.serviceDateTimeId}
                        />
                      );
                    })
                  )}
                </Col>
                <Col span={21} style={{ minHeight: '30vh' }}>
                  {this.state.selectedSessionId === '' ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  ) : this.state.isLoadingWorker ? (
                    <div className='p-large'>
                      <Skeleton
                        paragraph={{ rows: 1, width: '100%' }}
                        active={true}
                        className='anim-slide-left'
                        avatar={true}
                      />
                      <Skeleton
                        paragraph={{ rows: 1, width: '100%' }}
                        active={true}
                        className='anim-slide-left'
                        avatar={true}
                      />
                      <Skeleton
                        paragraph={{ rows: 1, width: '100%' }}
                        active={true}
                        className='anim-slide-left'
                        avatar={true}
                      />
                    </div>
                  ) : _.isEmpty(timesheetSessionList) ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  ) : (
                    <>
                      {_.map(timesheetShiftSlotList, (shiftSlot, index) => {
                        return (
                          <TimesheetShiftSlotRow
                            key={shiftSlot.supportWorkerAttendanceId}
                            shiftSlot={shiftSlot}
                            onCheckboxCheck={this._onCheckShiftSlot}
                            odd={index % 2 === 1}
                            hoverContent={this._getHoverContent(shiftSlot)}
                            timezone={selectedGroupService.timezone}
                          />
                        );
                      })}
                      <GridRow gutter={16} containerClassName='bg-tertiary' bordered={false}>
                        <Col span={13}>
                          <FieldLabel text={'total'} />
                        </Col>
                        <Col span={3} className='text-align-right'>
                          {TimesheetShiftSlotRow.formatDuration(moment.duration(this.state.schedule, 'ms'))}
                        </Col>
                        <Col span={3} className='text-align-right'>
                          {TimesheetShiftSlotRow.formatDuration(moment.duration(this.state.actual, 'ms'))}
                        </Col>
                        <Col span={3} className='text-align-right'>
                          {this.state.diff !== 0 ? (
                            TimesheetShiftSlotRow.formatDuration(moment.duration(this.state.diff, 'ms'))
                          ) : (
                            <>
                              <Text>
                                <b>0</b>
                              </Text>
                              <Text color='secondary'> hrs</Text>
                            </>
                          )}
                        </Col>
                        <Col span={2} className='text-align-right' />
                      </GridRow>
                      {!_.isEmpty(selectedShiftSlots) && <></>}
                    </>
                  )}
                  {!_.isEmpty(selectedShiftSlots) && (
                    <TimesheetBulkActionSheet
                      selectedShiftSlots={selectedShiftSlots}
                      onDeselect={this._onDeselectAllShift}
                      actionButtons={
                        <>
                          <PrimaryButton
                            size='large'
                            className='mt-medium'
                            onClick={this._onOpenBulkApproveShiftSlotsModal}
                          >
                            Approve...
                          </PrimaryButton>
                          <SecondaryButton
                            size='large'
                            className='mt-medium'
                            onClick={this._onOpenBulkEditShiftSlotModal}
                          >
                            Edit...
                          </SecondaryButton>
                        </>
                      }
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  timesheetSessionList: state.groupServiceStore.timesheetSessionList,
  timesheetShiftSlotList: state.groupServiceStore.timesheetShiftSlotList,
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceTimesheetSessions: dispatch.groupServiceStore.doFetchGroupServiceTimesheetSessions,
  doFetchGroupServiceTimesheetShiftSlots: dispatch.groupServiceStore.doFetchGroupServiceTimesheetShiftSlots,
  setTimesheetShiftSlotList: dispatch.groupServiceStore.setTimesheetShiftSlotList,
  doGetAllPendingShiftSlots: dispatch.groupServiceStore.doGetAllPendingShiftSlots,
});

export default connect(mapState, mapDispatch)(ServiceTimesheetPendingPanel);
