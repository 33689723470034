import { RadioGroup } from 'design-components';
import React from 'react';
import { UserPermissionRole } from 'utilities/enum-utils';
import { ADVANCED_PERMISSION_ROLE_TYPE } from 'views/team-v2/utils/constants';
import ActionSelectedItem from '../ActionSelectedItem';
import AdvancedPermissionRow from '../AdvancedPermissionRow';

interface IProps {
  permissionRoleId: number;
  changePermissionForEachRole?: (value: number, permissionKey: string) => void;
  typePermissionPresent: string;
  handleSetTypePermissionPresent: (value: string) => void;
}

const PermissionWorkflowSection: React.FC<IProps> = ({
  permissionRoleId = UserPermissionRole.WorkflowBasic,
  changePermissionForEachRole,
  typePermissionPresent,
  handleSetTypePermissionPresent,
}) => {
  return (
    <AdvancedPermissionRow
      title="Workflow"
      description="Access to workflows"
      permissionRoleId={permissionRoleId}
      type={ADVANCED_PERMISSION_ROLE_TYPE.WORKFLOW}
      typePermissionPresent={typePermissionPresent}
      handleSetTypePermissionPresent={handleSetTypePermissionPresent}
      extendPermissions={
        <RadioGroup
          aria-label="workflow"
          value={permissionRoleId}
          onChange={(value) => changePermissionForEachRole(value, 'workflow')}
        >
          <ActionSelectedItem
            ariaLabel="workflow-basic"
            label="Basic, can view and manage workflows they are assigned to"
            value={UserPermissionRole.WorkflowBasic}
            selectedPermission={permissionRoleId}
          />
          <ActionSelectedItem
            ariaLabel="workflow-elevated"
            label="Elevated, can view and manage all workflows"
            value={UserPermissionRole.WorkflowElevated}
            selectedPermission={permissionRoleId}
          />
          <ActionSelectedItem
            ariaLabel="workflow-manager"
            label="Manager, can view, manage and create workflows"
            value={UserPermissionRole.WorkflowManager}
            selectedPermission={permissionRoleId}
          />
        </RadioGroup>
      }
    />
  );
};

export default PermissionWorkflowSection;
