import React from 'react';
import { Col } from 'antd';
import { Text } from '@good/components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IRootState } from 'stores/rematch/root-store';
import { SupportWorkerAlertType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import TeamListingAlerts from './TeamListingAlerts';
import { GridRow } from 'common-components/grids';
import { Avatar, Badge, Group, Skeleton } from '@good/ui/core';

type WorkerItem = {
  firstName: string;
  lastName: string;
  attachmentUrl: string | null;
  supportWorkerId: string;
  userLocationByState: string | null;
  availabilityChangeRequested?: number | null;
  alerts: { supportWorkerAlertType: SupportWorkerAlertType; numberOfAlerts: number }[];
};

export const TeamMemberListRowSkeleton = () => (
  <GridRow>
    <Col span={7}>
      <Group gap='md' wrap='nowrap'>
        <Skeleton circle w={38} h={38} style={{ flexShrink: 0 }} data-testid='avatar-skeleton' />
        <Skeleton h={20} data-testid='name-skeleton' />
      </Group>
    </Col>
    <Col span={3}>
      <Skeleton h={20} />
    </Col>
    <Col span={6}>
      <Skeleton h={20} />
    </Col>
    <Col span={8}>
      <Skeleton h={20} />
    </Col>
  </GridRow>
);

export const TeamMemberListRow = ({ teamMember }: { teamMember: WorkerItem }) => {
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);

  const teamMemberDetailsHref =
    portalUser && PermissionUtils.validatePermission('ViewTeamMemberProfile', portalUser.permissions.permissionRoles)
      ? `/team/details/${teamMember.supportWorkerId}`
      : '#';

  return (
    <Link to={teamMemberDetailsHref}>
      <GridRow>
        <Col span={7}>
          <Group align='center' gap='xs'>
            <Avatar src={teamMember.attachmentUrl} />
            <Text className='font-bold'>{`${teamMember.firstName} ${teamMember.lastName}`}</Text>
          </Group>
        </Col>

        <Col span={3}>
          <Text>{teamMember.userLocationByState}</Text>
        </Col>

        <Col span={6}>
          {teamMember.availabilityChangeRequested ? (
            <Badge variant='light' color='orange'>
              {teamMember.availabilityChangeRequested > 1
                ? `${teamMember.availabilityChangeRequested} pending requests`
                : 'Pending request'}
            </Badge>
          ) : null}
        </Col>

        <Col span={8}>
          <TeamListingAlerts alerts={teamMember.alerts} />
        </Col>
      </GridRow>
    </Link>
  );
};
