import React, { Component } from 'react';
import _ from 'lodash';
import * as H from 'history';
import { Input, Tabs } from 'antd';
import { Text, Title } from 'common-components/typography';
import { PrimaryButton } from 'common-components/buttons';
import GroupServiceCustomerListingSection from './GroupServiceCustomerListingSection';
import ViewCustomerScheduleModal from './ViewCustomerScheduleModal';
import { IGroupServiceCustomerListItem } from 'interfaces/customer-interfaces';

const { TabPane } = Tabs;
const { Search } = Input;

interface IGroupServiceViewCustomerPanelProps {
  history: H.History;
  serviceId: string;
  isNeedFetchCustomerList: boolean;
  clearIsNeedFetchCustomerList: () => void;
  setIsNeedFetchCustomerList: () => void;
  onOpenAddCustomerModal: () => void;
}

interface IGroupServiceViewCustomerPanelState {
  selectedTab: string;
  isOpenCustomerScheduleModal: boolean;
  isSearching: boolean;
  searchText: string;
  selectedCustomer: IGroupServiceCustomerListItem;
}

export default class GroupServiceViewCustomerPanel extends Component<
  IGroupServiceViewCustomerPanelProps,
  IGroupServiceViewCustomerPanelState
> {
  state = {
    selectedTab: 'Active',
    isOpenCustomerScheduleModal: false,
    isSearching: false,
    searchText: null,
    selectedCustomer: null,
  };

  private _onChangeTab = (e) => {
    this.setState({ selectedTab: e });
  };

  private _onOpenCustomerScheduleModal = (customer) => {
    this.setState({ selectedCustomer: customer, isOpenCustomerScheduleModal: true });
  };

  private _onCloseCustomerScheduleModal = () => {
    this.setState({ selectedCustomer: null, isOpenCustomerScheduleModal: false });
    this.props.setIsNeedFetchCustomerList();
  };

  private _setSearchText = async (txt) => {
    this.setState({ searchText: txt });
  };

  private _debounceSearch = _.debounce(this._setSearchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    this._debounceSearch(e.target.value);
  };

  private _setSearchDone = () => {
    this.setState({ isSearching: false });
  };

  render() {
    return (
      <div className="pl-medium pt-x-large width-full pb-x3-large">
        {this.state.selectedCustomer?.customerUserId && (
          <ViewCustomerScheduleModal
            isOpen={this.state.isOpenCustomerScheduleModal}
            onClose={this._onCloseCustomerScheduleModal}
            selectedCustomer={this.state.selectedCustomer}
          />
        )}

        {this.state.selectedTab === 'Active' && (
          <div
            className="bg-white mt-medium ph-large pt-large pb-x2-large shadow-container"
            style={{ borderRadius: '16px' }}
          >
            <div className="flex-row justify-between align-center mb-large">
              <div>
                <Text weight="bold">Customers</Text> <br />
                <Text>Customers with sessions for this service</Text>
              </div>
              <div>
                <PrimaryButton size="large" onClick={this.props.onOpenAddCustomerModal} icon={'plus'}>
                  {' '}
                  Create customer schedule
                </PrimaryButton>
              </div>
            </div>

            <Search
              placeholder="Search for ...."
              size="large"
              onChange={this._onEnterSearchText}
              loading={this.state.isSearching}
              allowClear
            />

            <GroupServiceCustomerListingSection
              serviceId={this.props.serviceId}
              searchText={this.state.searchText}
              onSearchDone={this._setSearchDone}
              history={this.props.history}
              onOpenCustomerScheduleModal={this._onOpenCustomerScheduleModal}
              isNeedFetchCustomerList={this.props.isNeedFetchCustomerList}
              clearIsNeedFetchCustomerList={this.props.clearIsNeedFetchCustomerList}
            />
          </div>
        )}
      </div>
    );
  }
}
