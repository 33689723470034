import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { IRootDispatch, IRootState, dispatch } from 'stores/rematch/root-store';
import { Text } from 'common-components/typography';
import { Col, Row, Form, Input, notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import _ from 'lodash';
import { ICustomer, IGuardiansInfo } from 'src/interfaces/customer-interfaces';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';

const FormItem = Form.Item;

interface IGuardianEditRelationshipModalProps extends FormComponentProps {
  isEditRelationshipModalOpen: boolean;
  closeEditRelationshipModal: () => void;
  selectedGuardianId: string;
  selectedCustomer: ICustomer;
  doUpdateGuardianRelationship: typeof dispatch.customersStore.doUpdateGuardianRelationship;
}

interface IGuardianEditRelationshipModalState {
  selectedGuardian: IGuardiansInfo;
  isLoading: boolean;
}
class GuardianEditRelationshipModal extends Component<
  IGuardianEditRelationshipModalProps,
  IGuardianEditRelationshipModalState
> {
  state = {
    selectedGuardian: undefined,
    isLoading: false,
  };

  private _closeModal = () => {
    this.props.closeEditRelationshipModal();
  };

  private _onEditRelationship = async () => {
    try {
      this.setState({ isLoading: true });
      let isFormValid = true;

      this.props.form.validateFields((err, value) => {
        if (err) {
          isFormValid = false;
        }
      });

      if (isFormValid) {
        const { userId } = this.props.selectedCustomer;
        const guardianUserId = this.props.selectedGuardianId;
        const relationship = this.props.form.getFieldValue('relationToUser');
        const { doUpdateGuardianRelationship } = this.props;
        const request = {
          customerUserId: userId,
          guardianUserId: guardianUserId,
          relationship: relationship,
        };
        await doUpdateGuardianRelationship(request);

        notification.open({
          message: <span className={'text-weight-bolder'}>Relationship updated</span>,
          description: <span>You have updated a legal guardians relationship.</span>,
        });
      }
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    } finally {
      this.setState({ isLoading: false });
      this._closeModal();
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedGuardianId !== prevProps.selectedGuardianId) {
      let foundGuardian = _.filter(
        this.props.selectedCustomer.guardiansInfo,
        (guardian) => guardian.guardianUserId === this.props.selectedGuardianId,
      )[0];
      this.setState({ selectedGuardian: foundGuardian });
    }
  }

  render() {
    const { selectedGuardian } = this.state;
    const { isEditRelationshipModalOpen, form } = this.props;
    const { getFieldDecorator } = form;
    const title = 'Edit relationship';

    return (
      <div>
        <ActionModal isOpen={isEditRelationshipModalOpen} title={title} onClose={this._closeModal}>
          <SpinningLoadingActionModel isOpen={this.state.isLoading} />
          <div className="">
            <Text color="secondary" size="regular" className="text-uppercase mb-x-small text-weight-bolder">
              Relationship
            </Text>
            <Row>
              <Col span={11}>
                <FormItem className={'m-none'}>
                  {getFieldDecorator('relationToUser', {
                    initialValue:
                      !_.isEmpty(selectedGuardian) && selectedGuardian !== 'undefined'
                        ? selectedGuardian.relationToDependent
                        : 'Other',
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a relationship',
                      },
                    ],
                  })(<Input placeholder="Please enter a relationship" size="large" />)}
                </FormItem>
              </Col>
            </Row>
          </div>

          <ActionModalFooter className={'mt-large'}>
            <Row type={'flex'} className={'justify-end'}>
              <Col className="mr-medium">
                <SecondaryButton size="large" onClick={this._closeModal}>
                  Cancel
                </SecondaryButton>
              </Col>
              <Col>
                <PrimaryButton onClick={this._onEditRelationship} size="large">
                  Save
                </PrimaryButton>
              </Col>
            </Row>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateGuardianRelationship: dispatch.customersStore.doUpdateGuardianRelationship,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IGuardianEditRelationshipModalProps>()(GuardianEditRelationshipModal));
