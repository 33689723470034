import React from 'react';
import _ from 'lodash';
import { ActivityGroupMemberType } from 'utilities/enum-utils';
import { IActivityGroupUsers } from 'src/interfaces/user-interfaces';
import { Avatar, Button, Icon } from 'antd';
import { SubTitle, Text } from 'common-components/typography';
import { Popover2 } from '@blueprintjs/popover2';
import { Stack } from '../../../common-components/stack';
import styled from 'styled-components';

const StyledDiv = styled.div({
  padding: '16px',
  '> div': {
    borderBottom: '1px #eeeeee solid',
  },
  'div:last-child': {
    borderBottom: 'none',
    paddingBottom: '0px !important',
  },
});

const StyledIcon = styled(Icon)({
  '> svg': {
    display: 'block',
  },
});

interface IActivityGroupMemberListingProps {
  members: IActivityGroupUsers[];
  type: ActivityGroupMemberType;
  PopoverContent: React.ComponentType<{ member: IActivityGroupUsers; type: ActivityGroupMemberType }>;
  popoverContentProps: object;
}

function MemberListing(props: IActivityGroupMemberListingProps) {
  const { members, type, PopoverContent, popoverContentProps } = props;
  const avatarShape = type === ActivityGroupMemberType.TEAM_MEMBER ? 'square' : 'circle';
  if (_.isEmpty(members)) return <></>;

  return (
    <StyledDiv>
      <span>
        <SubTitle containerClassName="mb-small">
          {ActivityGroupMemberType.CUSTOMER === type ? 'CUSTOMERS' : 'TEAM MEMBERS'} ({members.length})
        </SubTitle>
      </span>

      {_.map(members, (member: IActivityGroupUsers) => {
        return (
          <div className="flex-row justify-between pv-small bordered-bottom" key={member.userId}>
            <Stack dir="row" gap="md" style={{ alignItems: 'center' }}>
              <Avatar size={32} src={member.attachmentUrl} shape={avatarShape} style={{ flexGrow: 1, flexShrink: 0 }} />
              <Text weight="regular">
                {member.firstName} {member.lastName}
              </Text>
            </Stack>

            <Popover2
              position="bottom-right"
              interactionKind="click"
              content={<PopoverContent member={member} type={type} {...popoverContentProps} />}
            >
              <Button
                className="ph-small border-standard-gray"
                style={{
                  height: '28px',
                  width: '28px',
                  display: 'flex',
                  padding: '0 !important',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <StyledIcon className="text-color-blue-light text-weight-bolder" type="ellipsis" theme="outlined" />
              </Button>
            </Popover2>
          </div>
        );
      })}
    </StyledDiv>
  );
}
export default MemberListing;
