import React from 'react';

interface EndSpacerProps {
  height?: number;
  width?: number;
}

export function EndSpacer({ height = 16, width = 16 }: EndSpacerProps) {
  return (
    <div className="bg-secondary" style={{ minWidth: `${width}px`, maxWidth: `${width}px`, height: `${height}px` }} />
  );
}
