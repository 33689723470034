import React, { useState } from 'react';
import { Plus, Trash, ChevronDown } from '@good/icons';
import { capitalize } from 'lodash';
import {
  AccordionContentProps,
  AccordionPaneProps,
  DailySchedule,
  DaysOfWeek,
  FormFieldProps,
  TimeSlot,
} from './types';
import { useForm, useFieldArray, FormProvider, UseFieldArrayReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Text, StatusMessage, Card, Header, Heading } from '@good/components';
import { useTranslation } from 'react-i18next';
import { FormSchema } from './form-schema';
import { SupportTypeCombobox } from './components/support-type-combobox';
import { RatioCombobox } from './components/ratio-combobox';
import { TimeInput } from './components/time-input';

export const DAYS_OF_WEEK: DaysOfWeek[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const AccordionPane = (props: AccordionPaneProps) => {
  const { index, isAccordionOpen, onPress } = props;
  return (
    <div className='flex flex-col'>
      <Button className='flex justify-between rounded-none p-6 border-none' onPress={async () => onPress(index)}>
        <Text>{capitalize(DAYS_OF_WEEK[index])}</Text>
        {isAccordionOpen ? <ChevronDown /> : <Plus />}
      </Button>
    </div>
  );
};

const AccordionContent = (props: AccordionContentProps) => {
  const { dayField, dayIndex, errors, addTimeSlot } = props;
  const day = DAYS_OF_WEEK[dayIndex]!;

  return (
    <div className='bg-white rounded-none p-3'>
      <div className='flex flex-col gap-2 relative'>
        <FormFields dayField={dayField} dayIndex={dayIndex} errors={errors} />
        {dayField.fields.length === 0 && <div className='h-12' />}
        <div className='absolute top-0 right-0 mt-2 mb-5 mr-2'>
          <Button
            data-testid={`add-time-slot-${day}`}
            className='rounded-full'
            size='sm'
            onPress={() => addTimeSlot(dayField, day)}
          >
            <Plus />
          </Button>
        </div>
      </div>
    </div>
  );
};

const FormFields = (props: FormFieldProps) => {
  const { dayField, dayIndex, errors } = props;

  return (
    <>
      {dayField.fields.map((_, index) => {
        const day = DAYS_OF_WEEK[dayIndex]!;
        const rowPath = `${day}.${index}` as const;

        return (
          <div key={index} className='flex gap-2'>
            <div className='flex flex-col gap-2'>
              <div className='flex gap-2'>
                <TimeInput rowPath={`${rowPath}.startTime`} />
                <div className='flex justify-center items-center'>-</div>
                <TimeInput rowPath={`${rowPath}.endTime`} />
                <RatioCombobox rowPath={`${rowPath}.ratio`} />
                <SupportTypeCombobox rowPath={`${rowPath}.supportType`} />
                <Button className='border-none' onPress={() => dayField.remove(index)}>
                  <Trash />
                </Button>
              </div>
              {errors[day]?.[index] && <StatusMessage tone='critical'>{errors[day]?.[index]?.message}</StatusMessage>}
            </div>
          </div>
        );
      })}
    </>
  );
};


export const ScheduleOfSupports = () => {
  const { t } = useTranslation('', { keyPrefix: 'serviceAgreements.silServiceAgreement.scheduleOfSupports' });
  const methods = useForm<DailySchedule>({
    mode: 'onChange',
    resolver: zodResolver(FormSchema),
    defaultValues: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    trigger,
  } = methods;

  const daysOfWeekFields = [];
  for (const day of DAYS_OF_WEEK) {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- builds up field array context - order of execution doesn't matter
    const fields = useFieldArray({
      control,
      name: day,
    });
    daysOfWeekFields.push(fields);
  }

  const [openPanes, setOpenPanes] = useState<boolean[]>(Array(DAYS_OF_WEEK.length).fill(false));

  const togglePane = (index: number) => {
    setOpenPanes((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleClick = async (dayIndex: number) => {
    const dayOfWeek = DAYS_OF_WEEK[dayIndex]!;
    const fieldValues = getValues();
    const dayToValidate = fieldValues[dayOfWeek].map((_, index) => `${dayOfWeek}.${index}`) as DaysOfWeek[];
    const validationResults = await trigger(dayToValidate);
    if (validationResults) {
      togglePane(dayIndex);
    }
  };

  const calculateEndTime = (time: string | undefined) => {
    if (!time) return '';

    const [hours, minutes] = time.split(':').map(Number) as [number, number];
    const totalMinutes = hours * 60 + minutes;
    const updatedTotalMinutes = totalMinutes + 60;
    const updatedHours = (Math.floor(updatedTotalMinutes / 60) % 24).toString();
    const updatedMinutes = (updatedTotalMinutes % 60).toString();

    return `${updatedHours.padStart(2, '0')}:${updatedMinutes.padStart(2, '0')}`;
  };

  const addTimeSlot = (dayField: UseFieldArrayReturn<DailySchedule, DaysOfWeek>, dayOfWeek: DaysOfWeek) => {
    const fieldValues = getValues();
    const lastEntry: TimeSlot | undefined = fieldValues[dayOfWeek].at(-1);

    const startTime = lastEntry?.endTime ?? '';
    const endTime = calculateEndTime(startTime);
    const ratio = lastEntry?.ratio ?? '1:1';
    const supportType = lastEntry?.supportType ?? 'Regular';

    dayField.append({
      startTime,
      endTime,
      ratio,
      supportType,
    });
  };

  const submitForm = (data: DailySchedule) => {
    // eslint-disable-next-line no-console -- log out data for now until we link it up to request
    console.log(data);
  };

  return (
    <Card className='flex flex-row items-center'>
      <div className='flex w-full flex-col space-y-8 pl-12 pt-8'>
        <Header size='lg'>
          <Heading>{t('heading')}</Heading>
        </Header>

        <div className='flex flex-row'>
          <div className='flex w-1/3 flex-col'>
            <Header size='sm' className=' mt-6'>
              <Heading>{t('weeklySchedule.heading')}</Heading>
            </Header>
            <Text>{t('weeklySchedule.content')}</Text>
          </div>
          <div
            className='mb-1 mr-1 min-h-[8rem] w-full grow bg-gray-light-3'
            style={{
              boxSizing: 'border-box',
              borderRadius: '8px',
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submitForm)} noValidate className='p-5 flex flex-col'>
                {daysOfWeekFields.map((dayField, index) => {
                  const isAccordionOpen = openPanes[index];

                  return (
                    <div key={index} className='border-b-2 border-gray-light-1'>
                      <AccordionPane index={index} onPress={handleClick} isAccordionOpen={isAccordionOpen} />
                      {isAccordionOpen && (
                        <AccordionContent
                          dayField={dayField}
                          dayIndex={index}
                          errors={errors}
                          addTimeSlot={addTimeSlot}
                        />
                      )}
                    </div>
                  );
                })}
                <div className='flex-row-reverse'>
                  <Button type='submit' emphasis='fill' className='mt-5 w-44'>
                    {t('actions.save')}
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </Card>
  );
};
