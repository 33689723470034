import { modularScale } from 'polished';

/**
 * @name
 * createScale
 *
 * @example
 * createScale(1.5, 16)(1)
 */
function createScale(ratio: number, base: number) {
  return function (steps: number) {
    return `${modularScale(steps, base, ratio)}px`;
  };
}

/********************************************************************
 * Dimension Values
 *******************************************************************/
export const sizeValues = {
  size0: '0px',
  size10: '1px',
  size25: '2px',
  size40: '3px',
  size50: '4px',
  size65: '5px',
  size75: '6px',
  size85: '7px',
  size100: '8px',
  size115: '9px',
  size125: '10px',
  size130: '11px',
  size150: '12px',
  size160: '13px',
  size175: '14px',
  size200: '16px',
  size225: '18px',
  size250: '20px',
  size275: '22px',
  size300: '24px',
  size350: '28px',
  size400: '32px',
  size450: '36px',
  size500: '40px',
  size550: '44px',
  size600: '48px',
  size675: '54px',
  size700: '56px',
  size800: '64px',
  size900: '72px',
  size1000: '80px',
  size1100: '88px',
  size1200: '96px',
  size1250: '100px',
  size1450: '116px',
  size1600: '128px',
  size1700: '136px',
  size2000: '160px',
  size2400: '192px',
  size3000: '240px',
  size3400: '272px',
  size3600: '288px',
  size4600: '368px',
  size5000: '400px',
  size6000: '480px',
  size8000: '600px',
  sizeFull: '100%',

  none: '$size0',
  xxsmall: '$size50',
  xsmall: '$size100',
  small: '$size150',
  medium: '$size200',
  large: '$size300',
  xlarge: '$size500',
  xxlarge: '$size1200',
};

export const spaceValues = {
  space0: '0px',
  space10: '1px',
  space20: '2px',
  space30: '3px',
  space50: '4px',
  space65: '5px',
  space75: '6px',
  space85: '7px',
  space100: '8px',
  space115: '9px',
  space125: '10px',
  space130: '11px',
  space150: '12px',
  space160: '13px',
  space175: '14px',
  space200: '16px',
  space225: '18px',
  space250: '20px',
  space275: '22px',
  space300: '24px',
  space350: '28px',
  space400: '32px',
  space450: '36px',
  space500: '40px',
  space550: '44px',
  space600: '48px',
  space700: '56px',
  space800: '64px',
  space900: '72px',
  space1000: '80px',
  space1100: '88px',
  space1200: '96px',
  space1400: '100px',
  space1600: '128px',
  space1800: '144px',
  space2000: '160px',
  space2200: '176px',
  space2400: '192px',
  space2600: '208px',
  space2800: '224px',
  space3000: '240px',
  space3200: '256px',
  space3600: '288px',
  space4000: '320px',
  space4800: '384px',

  none: '$space0',
  xxsmall: '$space50',
  xsmall: '$space100',
  small: '$space150',
  medium: '$space200',
  large: '$space300',
  xlarge: '$space400',
  xxlarge: '$space500',
};

/********************************************************************
 * Border Values
 *******************************************************************/
const borderRadiusScale = createScale(1.5, 4);

export const borderRadiusValues = {
  none: '0',
  xsmall: borderRadiusScale(0), // 4
  small: borderRadiusScale(1), // 6
  medium: borderRadiusScale(2), // 9
  large: borderRadiusScale(3), // 13.5
  xlarge: borderRadiusScale(4), // 20.25
  full: '9999px',
};

export const borderStyleValues = {
  solid: 'solid',
  dashed: 'dashed',
  dotted: 'dotted',
  double: 'double',
  none: 'none',
};

export const borderWidthValues = {
  border0: '0px',
  border100: '1px',
  border125: '1.25px',
  border150: '1.5px',
  border175: '1.75px',
  border200: '2px',
  border300: '3px',
  border400: '4px',
  border500: '5px',
  border600: '6px',
  border700: '7px',
  border800: '8px',

  /////////////
  // Aliases
  /////////////
  none: '$border0',
  thin: '$border100',
  thick: '$border200',
  thicker: '$border400',
  thickest: '$border800',
};

/********************************************************************
 * Outline Values
 *******************************************************************/

export const outlineStyleValues = {
  solid: 'solid',
  dashed: 'dashed',
  dotted: 'dotted',
  double: 'double',
  none: 'none',
};

export const outlineWidthValues = {
  outline0: '0px',
  outline100: '1px',
  outline200: '2px',
  outline300: '3px',
  outline400: '4px',
  outline500: '5px',
  outline600: '6px',
  outline700: '7px',
  outline800: '8px',

  /////////////
  // Aliases
  /////////////
  none: '$outline0',
  thin: '$outline100',
  thick: '$outline200',
  thicker: '$outline400',
  thickest: '$outline800',
};

/********************************************************************
 * Typography Values
 *******************************************************************/

export const fontFamilyValues = {
  sans: '"Messina Sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";',
  mono: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;',

  ////////////
  // Aliases
  ////////////
  primary: '$sans',
};

const fontSizeScale = createScale(1.125, 16);

export const fontSizeValues = {
  font25: fontSizeScale(-3), // 11.23
  font50: fontSizeScale(-2), // 12.64
  font75: fontSizeScale(-1), // 14.22
  font100: fontSizeScale(0), // 16
  font200: fontSizeScale(1), // 18
  font300: fontSizeScale(2), // 20.25
  font400: fontSizeScale(3), // 22.78
  font500: fontSizeScale(4), // 25.62
  font600: fontSizeScale(5), // 28.83
  font700: fontSizeScale(6), // 32.43
  font800: fontSizeScale(7), // 36.49
  font900: fontSizeScale(8), // 41.05
  font1000: fontSizeScale(9), // 46.18

  ////////////
  // Aliases
  ////////////
  xsmall: '$font50',
  small: '$font75',
  medium: '$font100',
  large: '$font300',
  xlarge: '$font500',
  xxlarge: '$font700',
};

const lineHeightScale = createScale(1.125, 16);

export const lineHeightValues = {
  leading100: lineHeightScale(0), // 16
  leading200: lineHeightScale(1), // 18
  leading300: fontSizeScale(2), // 20.25
  leading400: fontSizeScale(3), //22.78
  leading500: fontSizeScale(4), // 25.62
  leading600: fontSizeScale(5), // 28.83
  leading650: fontSizeScale(5.5), // 30.58
  leading700: fontSizeScale(6), // 32.43
  leading800: fontSizeScale(7), // 36.49
  leading900: fontSizeScale(8), // 41.05
  leading950: fontSizeScale(8.5), // 43.5
  leading1000: fontSizeScale(9), // 46.18

  xsmall: '$leading100',
  small: '$leading400',
  medium: '$leading600',
  large: '$leading700',
  xlarge: '$leading900',
};

export const fontWeightValues = {
  light: 400,
  normal: 500,
  semibold: 600,
  bold: 700,
};

/********************************************************************
 * Color Values
 *******************************************************************/

export const colorValues = {
  // Primary //
  black: '#000000',
  current: 'currentColor',
  transparent: 'transparent',
  white: '#FFFFFF',
  yellow: '#ffd52e',
  // Accent //
  ocean: '#206E8D',
  oceanDark1: '#1A5870',
  oceanDark2: '#164D62',
  oceanLight1: '#E9F1F4',
  oceanLight2: '#F4F8F9',
  // Neutral //
  body: '#CCCAC6',
  bodyDark1: '#706F6D',
  bodyDark2: '#3D3D3B',
  bodyLight: '#E0DFDD',
  bodyLight1: '#EBEAE8',
  bodyLight2: '#F5F4F4',
  bodyLight2V2: '#F7F7F6',
  // Lavender //
  lavender: '#6773A7',
  lavenderDark1: '#56608C',
  lavenderDark2: '#1A2859',
  lavenderLight1: '#EAECF5',
  lavenderLight2: '#F1F2F8',
  // Canary //
  canary: '#557480',
  canaryDark1: '#47616B',
  canaryDark2: '#384E55',
  canaryLight1: '#E8F3F7',
  canaryLight2: '#F4F9FB',
  // Lilac //
  lilac: '#8B66A2',
  lilacDark1: '#7A5A8E',
  lilacDark2: '#574066',
  lilacLight1: '#EFE6F5',
  lilacLight2: '#F7F2FA',
  // Blue //
  blue: '#0073E6',
  blueDark1: '#0066CC',
  blueDark2: '#004D99',
  blueDark3: '#206E8C',
  blueLight1: '#E6F2FF',
  blueLight2: '#F2F9FF',
  // Green //
  green: '#2B7B66',
  greenDark1: '#266C59',
  greenDark2: '#1B4D40',
  greenLight1: '#C3E1D9',
  greenLight2: '#EBF5F2',
  // Orange //
  orange: '#B25719',
  orangeDark1: '#9A4B15',
  orangeDark2: '#773A11',
  orangeLight1: '#FBE3D3',
  orangeLight2: '#FDF1E9',
  // Red //
  red: '#C33232',
  redV2: '#C13232',
  redDark1: '#962727',
  redDark2: '#802121',
  redDark3: '#AB2C2C',
  redLight1: '#F7D7D7',
  redLight2: '#FBEBEB',
  // Transparent
  blackTransparent: 'rgba(0,0,0,0.5)',

  ////////////
  // Aliases
  ////////////

  // Primary //
  primary: '$yellow',
  // Secondary //
  accent: '$ocean',
  accentHover: '$oceanDark1',
  accentPressed: '$oceanDark2',
  accentBackground: '$oceanLight1',
  accentBackgroundSecondary: '$oceanLight2',
  accentBorder: '$ocean',
  // Neutral //
  neutral: '$body',
  neutralDark1: '$bodyDark1',
  neutralDark2: '$bodyDark2',
  neutralLight: '$bodyLight',
  neutralLight1: '$bodyLight1',
  neutralLight2: '$bodyLight2',
  // Text //
  text: '$bodyDark2',
  default: '$bodyDark2',
  textSecondary: '$bodyDark1',
  muted: '$bodyDark1',
  neutralLightBackground: '$neutralLight2',
  neutralLightBackgroundHover: '$neutralLight1',
  neutralLightBackgroundPressed: 'neutral',
  // Info //
  info: '$lavender',
  infoHover: '$lavenderDark1',
  infoPressed: '$lavenderDark2',
  infoBackground: '$lavenderLight1',
  infoBackgroundHover: '$lavenderLight1',
  infoBackgroundSecondary: '$lavenderLight2',
  // Progress //
  progress: '$blue',
  progressHover: '$blueDark1',
  progressPressed: '$blueDark2',
  progressBackground: '$blueLight1',
  progressBackgroundHover: '$blueLight1',
  progressBackgroundSecondary: '$blueLight2',
  // Positive //
  positive: '$green',
  positiveHover: '$greenDark1',
  positivePressed: '$greenDark2',
  positiveBackground: '$greenLight1',
  positiveBackgroundHover: '$greenLight1',
  positiveBackgroundSecondary: '$greenLight2',
  // Notice //
  notice: '$orange',
  noticeHover: '$orangeDark1',
  noticePressed: '$orangeDark2',
  noticeBackground: '$orangeLight1',
  noticeBackgroundHover: '$orangeLight1',
  noticeBackgroundPressed: '$orangeLight2',
  // Critical //
  critical: '$red',
  criticalHover: '$redDark1',
  criticalPressed: '$redDark2',
  criticalBackound: '$redLight1',
  criticalBackgroundHover: '$redLight1',
  criticalBackgroundPressed: '$redLight2',
  criticalFocusOutlined: '$redV2',
};

export const textColorValues = {
  $default: colorValues.default,
  $muted: colorValues.textSecondary,
  $positive: colorValues.positive,
  $critical: colorValues.critical,
  $accent: colorValues.accent,
  $progress: colorValues.progress,
  $notice: colorValues.notice,
  $black: colorValues.black,
  $white: colorValues.white,
  $bodyDark2: colorValues.bodyDark2,
};

/********************************************************************
 * Shadow Values
 *******************************************************************/
export const shadowValues = {
  xsmall: '0px 1px 2px rgba(0, 0, 0, 0.03)',
  small: '0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 2px -1px rgba(0, 0, 0, 0.06)',
  medium: '0px 4px 6px -1px rgba(0, 0, 0, 0.06), 0px 2px 4px -2px rgba(0, 0, 0, 0.06)',
  large: '0px 10px 15px -3px rgba(0, 0, 0, 0.06), 0px 4px 6px -4px rgba(0, 0, 0, 0.06)',
  xlarge: '0px 20px 25px -5px rgba(0, 0, 0, 0.06), 0px 8px 10px -6px rgba(0, 0, 0, 0.06)',
  xxlarge: '0px 25px 50px -12px rgba(0, 0, 0, 0.12)',

  activeShadow: '0 0 0 2px #206E8C',
  errorShadow: '0 0 0 2px #C13232',
  hoverShadow: '0 0 0 2px #E0DEDC',
};

/********************************************************************
 * Transition Values
 *******************************************************************/
export const transitionValues = {
  transition100: '100ms all ease-in-out',
  transition150: '150ms all ease-in-out',
  transition200: '200ms all ease-in-out',
  transition250: '250ms all ease-in-out',
  transition300: '300ms all ease-in-out',
};
