import React, { Component } from 'react';
import { SubTitle, FieldLabel, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';
import { Checkbox, Col, Empty, Icon, Row, Select, Form } from 'antd';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { ICustomerScheduleSlotItem } from 'interfaces/customer-interfaces';
import { PaymentSourceType, RecurringBookingPattern } from 'utilities/enum-utils';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import moment from 'moment-timezone';
import DayPicker from 'common-components/inputs/DayPicker';
import NumberInput from 'common-components/inputs/NumberInput';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import EditQuotationSelectedBillingItemModel from 'views/customers/details/components/EditQuotationSelectedBillingItemModel';
import { FormComponentProps } from 'antd/es/form';
import { ErrorBanner } from 'common-components/alerts';
import CommonUtils from 'utilities/common-utils';

interface IAddEditScheduleToQuoteModalProps extends FormComponentProps {
  onClose: any;
  isOpen: boolean;
  isEdit?: boolean;
  doGetQuotation: typeof dispatch.customersStore.doGetQuotation;
  quotation?: any;
  selectedService?: any;
  addEditQuotation: (quotation) => void;
  paymentSourceType: string;
  isNewServiceAgreement: boolean;
  userServiceAgreementId?: string;
}

interface IAddEditScheduleToQuoteModalState {
  step: number;
  title: any;
  timeSlots: Array<ICustomerScheduleSlotItem>;
  // selectedServiceId: string;
  additionalCost: number;
  isCalculating: boolean;
  excludePublicHolidays: boolean;
  isSelectedBillingItemModelOpen: boolean;
  selectedTimeSlot: ICustomerScheduleSlotItem;
  selectedIndex: number;
  isAdditionalCostIncludeGst: boolean;
}

class AddEditScheduleToQuoteModal extends Component<
  IAddEditScheduleToQuoteModalProps,
  IAddEditScheduleToQuoteModalState
> {
  state = {
    step: 1,
    title: 'Create a schedule for a service to calculate the quote',
    timeSlots: this.props.quotation
      ? _.map(this.props.quotation.timeSlots, (timeSlot) => {
          return {
            ...timeSlot,
            endDateTime: moment(timeSlot.endDateTime),
            startDateTime: moment(timeSlot.startDateTime),
            recurringTo: moment(timeSlot.recurringTo),
          };
        })
      : [],
    // selectedServiceId: this.props.quotation ? this.props.quotation.serviceId : null,
    additionalCost: this.props.quotation ? this.props.quotation.additionalCost : 0,
    isCalculating: false,
    excludePublicHolidays: this.props.quotation ? this.props.quotation.excludePublicHolidays : false,
    isSelectedBillingItemModelOpen: false,
    selectedTimeSlot: null,
    selectedIndex: 0,
    isAdditionalCostIncludeGst:
      this.props.paymentSourceType === PaymentSourceType.VCP
        ? this.props.quotation
          ? this.props.quotation.isAdditionalCostIncludeGst
          : true
        : false,
  };

  private _onClose = () => {
    this.setState({
      step: 1,
      title: 'Create a schedule for a service to calculate the quote',
      // selectedServiceId: null,
      additionalCost: 0,
      isCalculating: false,
      timeSlots: [],
    });
    this.props.onClose();
  };

  private _goToPrevious = () => {
    this.setState({ step: 1 });
  };

  private getScheduleEmptyState = (serviceSelected: boolean) => {
    return (
      <div className="flex-1 bg-white mt-x2-large align-center flex-column">
        <div className="">
          <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
        </div>
        <Text size="x2-large" color="secondary" weight="bold">
          No schedule added.
        </Text>
        <Text color="secondary">
          {!serviceSelected
            ? 'Select a service in order to add a time slot.'
            : `Press 'Add a service schedule' to add a schedule.`}
        </Text>
      </div>
    );
  };

  private _goToNext = () => {
    const { selectedService } = this.props;
    const { step, timeSlots } = this.state;
    if (step === 1) {
      let hasAnyError = false;
      const newTimeSlots = _.map(timeSlots, (timeSlot) => {
        let hasError = false;
        let additionalErrorText = [];
        if (!timeSlot.startDateTime || !timeSlot.endDateTime || !timeSlot.recurringPattern || !timeSlot.recurringTo) {
          hasError = true;
          additionalErrorText.push('Please fill in all fields in order to calculate the quote amount.');
        } else {
          if (moment(timeSlot.recurringTo).startOf('day') < moment(timeSlot.startDateTime).startOf('day')) {
            hasError = true;
            additionalErrorText.push('The start date of the schedule must be before the end date.');
          } else if (moment(timeSlot.endDateTime).isBefore(moment(timeSlot.startDateTime))) {
            hasError = true;
            additionalErrorText.push('The start time of the bookings must be before the end time for the same days.');
          } else if (
            timeSlot.recurringPattern === RecurringBookingPattern.EveryDay &&
            (timeSlot.endDateTime.diff(timeSlot.startDateTime, 'hours') >= 24 ||
              timeSlot.endDateTime.diff(timeSlot.startDateTime, 'hours') < 0)
          ) {
            hasError = true;
          }
        }
        this.props.form.validateFields((err) => {
          if (err) {
            hasError = true;
          }
        });
        if (hasError) {
          hasAnyError = true;
        }
        return {
          ...timeSlot,
          hasError,
          additionalErrorText: _.join(additionalErrorText, ' '),
          isTransportBeforeBooking:
            selectedService.serviceClaimConfig.ndisClaims &&
            !selectedService.serviceClaimConfig.isChargeNdisTransportBeforeBooking
              ? false
              : timeSlot.isTransportBeforeBooking,
          isTransportDuringBooking:
            selectedService.serviceClaimConfig.ndisClaims &&
            !selectedService.serviceClaimConfig.isChargeNdisTransportDuringBooking
              ? false
              : timeSlot.isTransportDuringBooking,
        };
      });
      if (!hasAnyError) {
        this.setState({ step: 2, timeSlots: newTimeSlots, title: 'Add additional costs for this service' });
      } else {
        this.setState({ timeSlots: newTimeSlots });
      }
    }
  };

  private _addTimeSlot = () => {
    const { selectedService } = this.props;
    const timeSlots = _.clone(this.state.timeSlots);
    if (selectedService.paymentSourceType === PaymentSourceType.NDIS) {
      timeSlots.push({
        priceBeforeBooking:
          selectedService.serviceClaimConfig &&
          selectedService.serviceClaimConfig.ndisClaims &&
          selectedService.serviceClaimConfig.ndisClaims.transportPriceBeforeBooking,
        priceDuringBooking:
          selectedService.serviceClaimConfig &&
          selectedService.serviceClaimConfig.ndisClaims &&
          selectedService.serviceClaimConfig.ndisClaims.transportPriceDuringBooking,
        additionalCostBeforeBooking: 0.0,
        additionalCostDuringBooking: 0.0,
      });
    } else {
      timeSlots.push({});
    }
    this.setState({ timeSlots });
  };

  private _changeEndDateTimeDay = (key, e) => {
    const { timeSlots } = this.state;
    const currentStartDateDay = moment(timeSlots[key].startDateTime).isoWeekday();
    if (e) {
      this.setState({
        timeSlots: _.map(this.state.timeSlots, (timeSlot, index) => {
          if (key === index) {
            return {
              ...timeSlot,
              endDateTime:
                currentStartDateDay <= e
                  ? moment(timeSlot.startDateTime)
                      .isoWeekday(e)
                      .set({
                        hours: moment(timeSlot.endDateTime).hours(),
                        minutes: moment(timeSlot.endDateTime).minutes(),
                        seconds: 0,
                      })
                  : moment(timeSlot.endDateTime)
                      .add(1, 'weeks')
                      .isoWeekday(e)
                      .set({
                        hours: moment(timeSlot.endDateTime).hours(),
                        minutes: moment(timeSlot.endDateTime).minutes(),
                        seconds: 0,
                      }),
            };
          } else {
            return { ...timeSlot };
          }
        }),
      });
    }
  };

  private _changeStartDate = (key, e) => {
    const { timeSlots } = this.state;
    const currentEndDateDay = moment(timeSlots[key].endDateTime).isoWeekday();
    const newWeekDay = moment(e).isoWeekday();
    if (e) {
      this.setState({
        timeSlots: _.map(timeSlots, (timeSlot, index) => {
          if (key === index) {
            return {
              ...timeSlot,
              startDateTime: moment(e),
              endDateTime: timeSlot.endDateTime
                ? timeSlot.recurringPattern === RecurringBookingPattern.EveryDay
                  ? moment(e).set({
                      hours: moment(timeSlot.endDateTime).hours(),
                      minutes: moment(timeSlot.endDateTime).minutes(),
                      seconds: 0,
                    })
                  : newWeekDay <= currentEndDateDay
                  ? moment(e)
                      .isoWeekday(moment(timeSlot.endDateTime).isoWeekday())
                      .set({
                        hours: moment(timeSlot.endDateTime).hours(),
                        minutes: moment(timeSlot.endDateTime).minutes(),
                        seconds: 0,
                      })
                  : moment(e)
                      .isoWeekday(moment(timeSlot.endDateTime).isoWeekday())
                      .add(1, 'weeks')
                      .set({
                        hours: moment(timeSlot.endDateTime).hours(),
                        minutes: moment(timeSlot.endDateTime).minutes(),
                        seconds: 0,
                      })
                : moment(e).add(1, 'hours'),
            };
          } else {
            return { ...timeSlot };
          }
        }),
      });
    }
  };

  private _changeRecurringTo = (key, e) => {
    this.setState({
      timeSlots: _.map(this.state.timeSlots, (timeSlot, index) => {
        if (key === index) {
          return {
            ...timeSlot,
            recurringTo: moment(e).endOf('day'),
          };
        } else {
          return { ...timeSlot };
        }
      }),
    });
  };

  private _changeEndDateTime = (key, e) => {
    this.setState({
      timeSlots: _.map(this.state.timeSlots, (timeSlot, index) => {
        if (key === index) {
          return { ...timeSlot, endDateTime: moment(e) };
        } else {
          return { ...timeSlot };
        }
      }),
    });
  };

  private _changeRecurringPattern = (key, e) => {
    this.setState({
      timeSlots: _.map(this.state.timeSlots, (timeSlot, index) => {
        if (key === index) {
          return { ...timeSlot, recurringPattern: e };
        } else {
          return { ...timeSlot };
        }
      }),
    });
  };

  private _changeTransportItem = (key, label, value) => {
    this.setState({
      timeSlots: _.map(this.state.timeSlots, (timeSlot, index) => {
        if (key === index) {
          return { ...timeSlot, [label]: value };
        } else {
          return { ...timeSlot };
        }
      }),
    });
  };

  private _changeExcludePublicHolidays = (e) => {
    this.setState({ excludePublicHolidays: e.target.checked });
  };

  private _changeIncludeGST = (e) => {
    this.setState({ isAdditionalCostIncludeGst: e.target.checked });
  };

  private _deleteTimeSlot = (index) => {
    this.setState({ timeSlots: _.filter(this.state.timeSlots, (timeSlot, key) => index !== key) });
  };

  private _onChangeAdditionalCosts = (e) => {
    this.setState({ additionalCost: e });
  };

  private _onUpdateSelectedBillingItems = (key, lineItem) => {
    this.setState({
      timeSlots: _.map(this.state.timeSlots, (timeSlot, index) => {
        if (key === index) {
          return { ...timeSlot, selectedBillingItems: lineItem };
        } else {
          return { ...timeSlot };
        }
      }),
    });
  };

  private _calculateQuote = async () => {
    const { selectedService } = this.props;
    const { timeSlots, excludePublicHolidays } = this.state;
    // const selectedService = _.find(this.props.availableServices, (service) => service.serviceId === selectedServiceId);
    this.setState({ title: 'Calculating quote amount...', step: 3, isCalculating: true });
    const timeSlotsWithTimezone = _.map(timeSlots, (timeSlot) => {
      return {
        ...timeSlot,
        endDateTime: new Date(moment(timeSlot.endDateTime).tz(selectedService.timezone).format('YYYY/MM/DD HH:mm')),
        startDateTime: new Date(moment(timeSlot.startDateTime).tz(selectedService.timezone).format('YYYY/MM/DD HH:mm')),
        recurringTo: new Date(moment(timeSlot.recurringTo).tz(selectedService.timezone).format('YYYY/MM/DD HH:mm')),
      };
    });
    const result: any = await this.props.doGetQuotation({
      serviceId: selectedService.serviceId,
      serviceBillingItems: selectedService.lineItems,
      timeSlots: timeSlotsWithTimezone,
      paymentSourceType: this.props.paymentSourceType,
      isGroupService: false,
      excludePublicHolidays,
      additionalCharges: selectedService.additionalCharges,
      isNewServiceAgreement: this.props.isNewServiceAgreement,
      userServiceAgreementId: this.props.userServiceAgreementId,
    });

    const isVCPPaymentSourceType = this.props.paymentSourceType === PaymentSourceType.VCP;
    const isAdditionalCostIncludeGst = isVCPPaymentSourceType ? this.state.isAdditionalCostIncludeGst : false;
    let additionalCost = Number(this.state.additionalCost);
    let gstValue = 0;

    if (isAdditionalCostIncludeGst) {
      const newGstPrice = CommonUtils.calcGstPriceByTotal(additionalCost, result.gst);
      additionalCost = newGstPrice.priceValue;
      gstValue = newGstPrice.gstValue;
    }

    if (result.subQuotes.length > 0 && isVCPPaymentSourceType) {
      _.forEach(result.subQuotes, (subQuote) => {
        gstValue += _.sumBy(subQuote.quoteItems, (quoteItem: any) => Number(quoteItem.gstValue));
      });
    }

    this.props.addEditQuotation({
      ...result,
      additionalCost: Number(this.state.additionalCost),
      timeSlots: timeSlotsWithTimezone,
      serviceId: selectedService.serviceId,
      quoteAmount: Number(result.quoteAmount) + Number(additionalCost) + Number(gstValue),
      isGroupService: false,
      excludePublicHolidays,
      isAdditionalCostIncludeGst,
      isVCPPaymentSourceType,
    });
    this._onClose();
  };

  private _validateDistance = (rule, value, callback) => {
    try {
      if (!value) {
        throw Error('Please enter a value.');
      } else if (value <= 0) {
        throw Error('Must be more than 0.');
      }
    } catch (e) {
      callback(e);
    }
  };

  componentDidUpdate = async (prevProps: Readonly<IAddEditScheduleToQuoteModalProps>) => {
    const { quotation } = this.props;
    if (
      (prevProps.quotation !== quotation && this.props.isEdit && this.props.isOpen) ||
      (!prevProps.isOpen && this.props.isOpen)
    ) {
      this.setState({
        timeSlots: quotation
          ? _.map(quotation.timeSlots, (timeSlot) => {
              return {
                ...timeSlot,
                endDateTime: moment(timeSlot.endDateTime),
                startDateTime: moment(timeSlot.startDateTime),
                recurringTo: moment(timeSlot.recurringTo),
              };
            })
          : [],
        // selectedServiceId: quotation ? quotation.serviceId : null,
        additionalCost: this.props.quotation ? this.props.quotation.additionalCost : 0,
        excludePublicHolidays: quotation ? quotation.excludePublicHolidays : false,
        title: this.props.isEdit
          ? 'Edit service schedule for a service'
          : 'Create a schedule for a service to calculate the quote',
        step: 1,
        isAdditionalCostIncludeGst:
          this.props.paymentSourceType === PaymentSourceType.VCP
            ? this.props.quotation
              ? this.props.quotation.isAdditionalCostIncludeGst
              : true
            : false,
      });
    }
  };

  render() {
    const { isOpen, selectedService, paymentSourceType, form } = this.props;

    const {
      timeSlots,
      additionalCost,
      title,
      excludePublicHolidays,
      step,
      isSelectedBillingItemModelOpen,
      isAdditionalCostIncludeGst,
    } = this.state;
    const { getFieldDecorator } = form;

    const isTransportBeforeEnabled =
      selectedService &&
      (paymentSourceType === PaymentSourceType.NDIS || selectedService.paymentSourceType === PaymentSourceType.NDIS) &&
      selectedService.serviceClaimConfig &&
      selectedService.serviceClaimConfig.isChargeNdisTransportBeforeBooking;
    const isTransportDuringBookingEnabled =
      selectedService &&
      (paymentSourceType === PaymentSourceType.NDIS || selectedService.paymentSourceType === PaymentSourceType.NDIS) &&
      selectedService.serviceClaimConfig &&
      selectedService.serviceClaimConfig.isChargeNdisTransportDuringBooking;
    const isVCPPayment = paymentSourceType === PaymentSourceType.VCP;

    return (
      <ActionModal isOpen={isOpen} onClose={this._onClose} width={'x2-large'} title={title} canCloseOutside={false}>
        {isVCPPayment && (
          <Text>
            If you would like you can add a nominal amount to this quote to account for additional costs such as
            transport. Please indicate whether this amount is GST inclusive or not.
          </Text>
        )}
        {step === 1 && (
          <>
            <div className="mb-medium">
              <Text>
                Create a schedule. Once you have added the schedule, we will calculate an accurate quote for the
                schedule.
              </Text>
            </div>
            <EditQuotationSelectedBillingItemModel
              selectedService={selectedService}
              isOpen={isSelectedBillingItemModelOpen}
              index={this.state.selectedIndex}
              onClose={() => this.setState({ isSelectedBillingItemModelOpen: false })}
              onSaveSelection={(lineItem, index) => this._onUpdateSelectedBillingItems(index, lineItem)}
              paymentSourceType={paymentSourceType}
              timeSlot={this.state.selectedTimeSlot}
            />
            <Checkbox checked={excludePublicHolidays} onClick={this._changeExcludePublicHolidays}>
              Exclude public holidays (The quote will not generate bookings that fall on public holidays)
            </Checkbox>
            <div className="mt-large mb-x2-large" style={{ minHeight: '150px' }}>
              {/* Content here*/}
              <div className="mb-medium">
                {selectedService ? (
                  <HyperlinkButton onClick={this._addTimeSlot}>
                    <Icon type="plus" />
                    &nbsp;Add time slot
                  </HyperlinkButton>
                ) : (
                  <Text color={'secondary'}>
                    <Icon type={'plus'} />
                    &nbsp;Add time slot
                  </Text>
                )}
              </div>
              {timeSlots && timeSlots.length > 0
                ? _.map(timeSlots, (timeSlot, index) => {
                    const isTransportBeforeDisabled = !isTransportBeforeEnabled && timeSlot.isTransportBeforeBooking;
                    const isTransportDuringDisabled =
                      !isTransportDuringBookingEnabled && timeSlot.isTransportDuringBooking;
                    return (
                      <div
                        className={`rounded-big bordered ${
                          timeSlot.hasError ? 'border-red-dark' : 'border-standard-gray shadow-box'
                        } p-medium mb-large`}
                        key={`quotationSchedule${index}`}
                      >
                        <div className={'flex-row align-center justify-between'}>
                          <div>
                            {timeSlot.hasError ? (
                              <Text color={'red-dark'}>
                                <Text color={'red-dark'} weight={'bold'}>
                                  Schedule {index + 1}:
                                </Text>{' '}
                                {timeSlot.additionalErrorText}
                              </Text>
                            ) : (
                              <Text size={'x-large'} weight={'bold'}>
                                Schedule {index + 1}
                              </Text>
                            )}
                          </div>
                          <div>
                            <GhostButton
                              icon={'delete'}
                              color={'red-dark'}
                              onClick={() => this._deleteTimeSlot(index)}
                            />
                          </div>
                        </div>
                        <div className={'width-full'}>
                          <div className={'flex-row align-center mv-small'}>
                            <Text color={'secondary'}>From</Text>{' '}
                            <DatePicker
                              className="gh-datepicker rounded mh-small"
                              calendarClassName="gh-datepicker-calendar"
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) => this._changeStartDate(index, e)}
                              placeholderText={'Start date'}
                              minDate={moment(selectedService?.startDate).startOf('day').toDate()}
                              maxDate={moment(selectedService?.endDate).endOf('day').toDate()}
                              selected={timeSlot.startDateTime && moment(timeSlot.startDateTime).toDate()}
                            />{' '}
                            <Text color={'secondary'}>to</Text>
                            <DatePicker
                              className="gh-datepicker rounded mh-small"
                              calendarClassName="gh-datepicker-calendar"
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) => this._changeRecurringTo(index, e)}
                              placeholderText={'End date'}
                              disabled={!timeSlot.startDateTime}
                              minDate={moment(timeSlot.startDateTime ? timeSlot.startDateTime : null).toDate()}
                              maxDate={moment(selectedService?.endDate).endOf('day').toDate()}
                              selected={timeSlot.recurringTo && moment(timeSlot.recurringTo).toDate()}
                            />
                          </div>
                          <div className={'flex-row align-center mv-medium'}>
                            {timeSlot.startDateTime && (
                              <Select
                                size={'large'}
                                className={'mr-small'}
                                placeholder={'Select pattern'}
                                style={{ width: '200px' }}
                                value={timeSlot.recurringPattern ? timeSlot.recurringPattern : undefined}
                                onChange={(e) => this._changeRecurringPattern(index, e)}
                              >
                                <Select.Option key={0} value={RecurringBookingPattern.EveryDay}>
                                  Every day
                                </Select.Option>
                                <Select.Option key={0} value={RecurringBookingPattern.EveryWeek}>
                                  Every week
                                </Select.Option>
                                <Select.Option key={0} value={RecurringBookingPattern.EveryFortnight}>
                                  Every fortnight
                                </Select.Option>
                                <Select.Option key={0} value={RecurringBookingPattern.EveryFourWeeks}>
                                  Every 4 weeks
                                </Select.Option>
                              </Select>
                            )}
                            {timeSlot.recurringPattern && (
                              <>
                                <Text color={'secondary'}>from</Text>

                                <DayPicker
                                  value={moment(timeSlot.startDateTime).isoWeekday()}
                                  className={'mh-small'}
                                  isDisabled={true}
                                  size={'large'}
                                />

                                <TimeInput
                                  size={'large'}
                                  className={'mh-small'}
                                  value={moment(timeSlot.startDateTime)}
                                  onChange={(e) => this._changeStartDate(index, e)}
                                />
                                <Text color={'secondary'}>until</Text>

                                <DayPicker
                                  value={moment(timeSlot.endDateTime).isoWeekday()}
                                  className={'mh-small'}
                                  size={'large'}
                                  onChange={(e) => this._changeEndDateTimeDay(index, e)}
                                />

                                <TimeInput
                                  size={'large'}
                                  className={'mh-small'}
                                  value={
                                    timeSlot.endDateTime
                                      ? moment(timeSlot.endDateTime)
                                      : moment().add(1, 'hours').toDate()
                                  }
                                  onChange={(e) => this._changeEndDateTime(index, e)}
                                />
                              </>
                            )}
                          </div>
                          {}
                          {timeSlot.recurringPattern === RecurringBookingPattern.EveryDay &&
                            (timeSlot.endDateTime.diff(timeSlot.startDateTime, 'hours') >= 24 ||
                              timeSlot.endDateTime.diff(timeSlot.startDateTime, 'hours') < 0) && (
                              <div className="text-color-red-dark">
                                Recurring bookings that occur everyday should be less than 24 hours
                              </div>
                            )}
                          <div className={'flex-row align-center mv-medium '}>
                            <div className={'bg-tertiary p-medium'}>
                              <FieldLabel text={'LINE ITEM(S) BEING CHARGED'} />
                              <Text>
                                {!_.isEmpty(timeSlot.selectedBillingItems)
                                  ? timeSlot.selectedBillingItems.length
                                  : selectedService.lineItems.length}{' '}
                                Line items been used for quote calculation
                              </Text>
                              <div>
                                <HyperlinkButton
                                  onClick={() =>
                                    this.setState({
                                      isSelectedBillingItemModelOpen: true,
                                      selectedIndex: index,
                                      selectedTimeSlot: timeSlot,
                                    })
                                  }
                                >
                                  View/Edit...
                                </HyperlinkButton>
                              </div>
                            </div>
                          </div>
                          {isTransportBeforeDisabled && (
                            <ErrorBanner
                              className={'mb-small'}
                              content={
                                <Text>
                                  Since the creation of this quote, travel to/from a booking has been disabled for this
                                  service. As a result the provider travel section of this quote has been removed.
                                </Text>
                              }
                            />
                          )}
                          {(isTransportBeforeEnabled || timeSlot.isTransportBeforeBooking) && (
                            <div
                              className={'mv-large'}
                              style={{
                                opacity: isTransportBeforeDisabled ? 0.4 : 1,
                              }}
                            >
                              <div>
                                <Checkbox
                                  checked={timeSlot.isTransportBeforeBooking}
                                  disabled={isTransportBeforeDisabled}
                                  onChange={(e) =>
                                    this._changeTransportItem(index, 'isTransportBeforeBooking', e.target.checked)
                                  }
                                >
                                  Add estimates for travel by team members to/from bookings that is billed to customers.{' '}
                                  <b>(Provider travel)</b>
                                </Checkbox>
                              </div>
                              {timeSlot.isTransportBeforeBooking && (
                                <Row className={'mt-medium'} gutter={24}>
                                  <Col span={8}>
                                    <SubTitle>Cost per KM</SubTitle>
                                    <Form.Item className={'m-none'}>
                                      {getFieldDecorator('priceBeforeBooking_' + index, {
                                        initialValue: timeSlot.priceBeforeBooking
                                          ? timeSlot.priceBeforeBooking
                                          : undefined,
                                        rules: [{ validator: this._validateDistance }],
                                      })(
                                        <NumberInput
                                          addonBefore={'$'}
                                          size={'large'}
                                          style={{ width: '100%', textAlign: 'right' }}
                                          precision={2}
                                          placeholder={'0.00'}
                                          disabled={isTransportBeforeDisabled}
                                          min={0.0}
                                          onChange={(e) => this._changeTransportItem(index, 'priceBeforeBooking', e)}
                                        />,
                                      )}
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <SubTitle>Approximate kilometres travelled</SubTitle>
                                    <Form.Item className={'m-none'}>
                                      {getFieldDecorator('distanceBeforeBooking_' + index, {
                                        initialValue: timeSlot.distanceBeforeBooking
                                          ? timeSlot.distanceBeforeBooking
                                          : undefined,
                                        rules: [{ validator: this._validateDistance }],
                                      })(
                                        <NumberInput
                                          min={0.0}
                                          style={{ width: '100%' }}
                                          size={'large'}
                                          disabled={isTransportBeforeDisabled}
                                          placeholder={'Input approx. km..'}
                                          onChange={(e) => this._changeTransportItem(index, 'distanceBeforeBooking', e)}
                                        />,
                                      )}
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <SubTitle>Approximate additional costs</SubTitle>
                                    <Form.Item className={'m-none'}>
                                      {getFieldDecorator('additionalCostBeforeBooking_' + index, {
                                        initialValue: timeSlot.additionalCostBeforeBooking
                                          ? timeSlot.additionalCostBeforeBooking
                                          : 0.0,
                                      })(
                                        <NumberInput
                                          addonBefore={'$'}
                                          precision={2}
                                          placeholder={'0.00'}
                                          disabled={isTransportBeforeDisabled}
                                          size={'large'}
                                          style={{ width: '100%', textAlign: 'right' }}
                                          min={0.0}
                                          onChange={(e) =>
                                            this._changeTransportItem(index, 'additionalCostBeforeBooking', e)
                                          }
                                        />,
                                      )}
                                    </Form.Item>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          )}
                          {isTransportDuringDisabled && (
                            <ErrorBanner
                              className={'mb-small'}
                              content={
                                <Text>
                                  Since the creation of this quote, <b>travel during a booking</b> has been disabled for
                                  this service. As a result the <b>participant transport</b> section of this quote has
                                  been removed.
                                </Text>
                              }
                            />
                          )}
                          {(isTransportDuringBookingEnabled || timeSlot.isTransportDuringBooking) && (
                            <div
                              className={'mb-large'}
                              style={{
                                opacity: isTransportDuringDisabled ? 0.4 : 1,
                              }}
                            >
                              <div>
                                <Checkbox
                                  checked={timeSlot.isTransportDuringBooking}
                                  onChange={(e) =>
                                    this._changeTransportItem(index, 'isTransportDuringBooking', e.target.checked)
                                  }
                                >
                                  Add estimates for transport provided during bookings. <b>(Participant transport)</b>
                                </Checkbox>
                              </div>
                              {timeSlot.isTransportDuringBooking && (
                                <Row className={'mt-medium'} gutter={24}>
                                  <Col span={8}>
                                    <SubTitle>Cost per KM</SubTitle>
                                    <Form.Item className={'m-none'}>
                                      {getFieldDecorator('priceDuringBooking_' + index, {
                                        initialValue: timeSlot.priceDuringBooking
                                          ? timeSlot.priceDuringBooking
                                          : undefined,
                                        rules: [{ validator: this._validateDistance }],
                                      })(
                                        <NumberInput
                                          addonBefore={'$'}
                                          size={'large'}
                                          style={{ width: '100%', textAlign: 'right' }}
                                          precision={2}
                                          disabled={isTransportDuringDisabled}
                                          placeholder={'0.00'}
                                          min={0.0}
                                          onChange={(e) => this._changeTransportItem(index, 'priceDuringBooking', e)}
                                        />,
                                      )}
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <SubTitle>Approximate kilometres travelled</SubTitle>
                                    <Form.Item className={'m-none'}>
                                      {getFieldDecorator('distanceDuringBooking_' + index, {
                                        initialValue: timeSlot.distanceDuringBooking
                                          ? timeSlot.distanceDuringBooking
                                          : undefined,
                                        rules: [{ validator: this._validateDistance }],
                                      })(
                                        <NumberInput
                                          min={0.0}
                                          style={{ width: '100%' }}
                                          disabled={isTransportDuringDisabled}
                                          size={'large'}
                                          placeholder={'Input approx. km'}
                                          onChange={(e) => this._changeTransportItem(index, 'distanceDuringBooking', e)}
                                        />,
                                      )}
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <SubTitle>Approximate additional costs</SubTitle>
                                    <Form.Item className={'m-none'}>
                                      {getFieldDecorator('additionalCostDuringBooking_' + index, {
                                        initialValue: timeSlot.additionalCostDuringBooking
                                          ? timeSlot.additionalCostDuringBooking
                                          : 0.0,
                                      })(
                                        <NumberInput
                                          addonBefore={'$'}
                                          precision={2}
                                          placeholder={'0.00'}
                                          disabled={isTransportDuringDisabled}
                                          size={'large'}
                                          style={{ width: '100%', textAlign: 'right' }}
                                          min={0.0}
                                          onChange={(e) =>
                                            this._changeTransportItem(index, 'additionalCostDuringBooking', e)
                                          }
                                        />,
                                      )}
                                    </Form.Item>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
                : this.getScheduleEmptyState(!!selectedService)}
            </div>
            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                Close
              </SecondaryButton>
              <PrimaryButton
                size="large"
                className="mr-medium"
                disabled={!timeSlots || timeSlots.length <= 0}
                onClick={this._goToNext}
              >
                Next
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 2 && (
          <>
            <div className={'mv-large flex-row align-center'}>
              <div className={'mr-x-large'}>Additional cost (Optional)</div>
              <NumberInput
                size={'large'}
                value={additionalCost ? additionalCost : '0.00'}
                className={'mr-large'}
                precision={2}
                style={{ width: '200px' }}
                addonBefore={'$'}
                onChange={this._onChangeAdditionalCosts}
                max={99999.99}
              />
            </div>
            {isVCPPayment && (
              <div className={'mv-large flex-row align-center'}>
                <Checkbox checked={isAdditionalCostIncludeGst} onChange={this._changeIncludeGST}>
                  Amount includes GST
                </Checkbox>
              </div>
            )}
            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._goToPrevious}>
                Previous
              </SecondaryButton>
              <PrimaryButton size="large" className="mr-medium" onClick={this._calculateQuote}>
                Calculate quote
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 3 && (
          <div>
            <SpinningLoader size={150} message={'Calculating quote amount. Please wait.'} />
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetQuotation: dispatch.customersStore.doGetQuotation,
});

export default connect(
  null,
  mapDispatch,
)(Form.create<IAddEditScheduleToQuoteModalProps>()(AddEditScheduleToQuoteModal));
