import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { Icon, notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { SuccessSVG } from 'assets/UndrawSVG';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import moment, { Moment } from 'moment-timezone';
import DatePicker from 'react-datepicker';
import CommonUtils from 'utilities/common-utils';
import _ from 'lodash';
import TimeInput from 'common-components/time-input/TimeInput';
import { TimezoneIndicator } from 'common-components/timezone';
import { BookingStatus } from 'utilities/enum-utils';
import { Label } from '@blueprintjs/core';

interface IBookingEditWorkerCheckedInOutDatesActionModelProps {
  isOpen: any;
  onClose: any;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  doUpdateBookingWorkerCheckInDateTime: typeof dispatch.bookingsStore.doUpdateBookingWorkerCheckInDateTime;
  doUpdateBookingWorkerCheckInOutDateTime: typeof dispatch.bookingsStore.doUpdateBookingWorkerCheckInOutDateTime;
  displayTimezone: any;
}

interface IEditWorkerCheckedInOutDatesActionModalState {
  isLoading: boolean;
  isSuccess: boolean;
  checkInDateTime: Moment;
  checkOutDateTime: Moment;
  hasCheckOutDateTime: boolean;
  isErrorEndDate: boolean;
}

class BookingEditWorkerCheckedInOutDatesActionModel extends Component<
  IBookingEditWorkerCheckedInOutDatesActionModelProps,
  IEditWorkerCheckedInOutDatesActionModalState
> {
  state = {
    isLoading: false,
    isSuccess: false,
    checkInDateTime: moment(),
    checkOutDateTime: moment(),
    hasCheckOutDateTime: false,
    isErrorEndDate: false,
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false });
      onClose();
    }
  };

  private _onSubmitEdit = async () => {
    const { doUpdateBookingWorkerCheckInDateTime, doUpdateBookingWorkerCheckInOutDateTime, displayTimezone } =
      this.props;
    const { checkInDateTime, checkOutDateTime, hasCheckOutDateTime } = this.state;

    this.setState({ isLoading: true });

    try {
      hasCheckOutDateTime
        ? await doUpdateBookingWorkerCheckInOutDateTime({
            checkInDateTime: moment.tz(moment(checkInDateTime).format('YYYY-MM-DD HH:mm'), displayTimezone),
            checkOutDateTime: moment.tz(moment(checkOutDateTime).format('YYYY-MM-DD HH:mm'), displayTimezone),
          })
        : await doUpdateBookingWorkerCheckInDateTime({
            checkInDateTime: moment.tz(moment(checkInDateTime).format('YYYY-MM-DD HH:mm'), displayTimezone),
          });
      this.setState({ isLoading: false, isSuccess: true });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isLoading: false });
    }
  };

  private _onChangeCheckInDateTime = (date) => {
    const checkInDateTime = moment(CommonUtils.formatCeilingDateTime(date));
    let newcheckOutDateTime = this.state.checkOutDateTime;
    if (checkInDateTime >= newcheckOutDateTime) {
      newcheckOutDateTime = moment(checkInDateTime).add(1, 'hour');
    }
    this.setState({ checkInDateTime, checkOutDateTime: newcheckOutDateTime, isErrorEndDate: false });
  };

  private _onChangeCheckOutDateTime = (date) => {
    const { checkInDateTime } = this.state;

    let isErrorEndDate = false;

    let checkOutDateTime = moment(CommonUtils.formatCeilingDateTime(date));

    if (checkOutDateTime <= checkInDateTime) {
      checkOutDateTime = moment(checkInDateTime).add(1, 'hour');
      isErrorEndDate = true;
    }
    this.setState({ checkOutDateTime, isErrorEndDate: isErrorEndDate });
  };

  private _renderDuration = (checkInDateTime: Moment, checkOutDateTime: Moment) => {
    const duration = CommonUtils.formatDuration(checkInDateTime, checkOutDateTime);
    let durationDisplays = [];

    if (duration.days) {
      durationDisplays.push(
        <>
          <b>{duration.days.value}</b> {`${duration.days.label} `}
        </>,
      );
    }

    if (duration.hours) {
      durationDisplays.push(
        <>
          <b>{duration.hours.value}</b> {`${duration.hours.label} `}
        </>,
      );
    }

    if (duration.minutes) {
      durationDisplays.push(
        <>
          <b>{duration.minutes.value}</b> {duration.minutes.label}
        </>,
      );
    }

    return (
      <div className="mt-large">
        For a duration of{' '}
        {_.map(durationDisplays, (display, index) => {
          return <span key={index}>{display}</span>;
        })}
      </div>
    );
  };

  private _generateTitle() {
    const { status } = this.props.selectedBookingItem;
    const { hasCheckOutDateTime, isSuccess } = this.state;
    if (status === BookingStatus.INPROGRESS) {
      return isSuccess ? 'Team member start time successfully edited' : 'Edit team member start time';
    }
    if (status === BookingStatus.COMPLETED) {
      return isSuccess ? 'Team member start time/finish successfully edited' : 'Edit team member start/finish time';
    }
    return hasCheckOutDateTime ? 'Edit Check-in and Check-out Dates' : 'Edit Check-in Dates';
  }

  componentDidMount() {
    const { selectedBookingItem, displayTimezone } = this.props;

    this.setState({
      isSuccess: false,
      isLoading: false,
      checkInDateTime: selectedBookingItem.portalCheckedInDateTime
        ? moment(moment.tz(selectedBookingItem.portalCheckedInDateTime, displayTimezone).format('YYYY-MM-DD HH:mm'))
        : selectedBookingItem.workerCheckedInDateTime
        ? moment(moment.tz(selectedBookingItem.workerCheckedInDateTime, displayTimezone).format('YYYY-MM-DD HH:mm'))
        : moment(moment.tz(selectedBookingItem.startDateTime, displayTimezone).format('YYYY-MM-DD HH:mm')),
      checkOutDateTime: selectedBookingItem.portalCheckedOutDateTime
        ? moment(moment.tz(selectedBookingItem.portalCheckedOutDateTime, displayTimezone).format('YYYY-MM-DD HH:mm'))
        : selectedBookingItem.workerCheckedOutDateTime
        ? moment(moment.tz(selectedBookingItem.workerCheckedOutDateTime, displayTimezone).format('YYYY-MM-DD HH:mm'))
        : moment(moment.tz(selectedBookingItem.endDateTime, displayTimezone).format('YYYY-MM-DD HH:mm')),
      hasCheckOutDateTime: !!(
        selectedBookingItem.portalCheckedInDateTime || selectedBookingItem.workerCheckedInDateTime
      ),
    });
  }

  render() {
    const { isOpen, selectedBookingItem } = this.props;
    const { hasCheckOutDateTime, checkInDateTime, checkOutDateTime } = this.state;

    return (
      <ActionModal
        title={this._generateTitle()}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
        canCloseOutside={false}
      >
        {!this.state.isLoading && !this.state.isSuccess && (
          <div className="">
            <div className="mb-medium">
              <Paragraph>
                {selectedBookingItem.status === BookingStatus.INPROGRESS
                  ? 'Edit the time the team member started this booking:'
                  : 'Edit the time the team member start/finish time for this booking:'}
              </Paragraph>
            </div>

            <div className="flex-row align-center justify-space-between mb-small">
              <Label className="width-fit-content">
                <Text className="text-uppercase text-weight-bold text-size-small text-color-secondary" size="small">
                  {hasCheckOutDateTime && selectedBookingItem.status === BookingStatus.INPROGRESS
                    ? 'Date'
                    : 'Start Date'}
                </Text>
                <div className="block width-full">
                  <DatePicker
                    name="startDate"
                    className="gh-datepicker rounded width-full"
                    calendarClassName="gh-datepicker-calendar"
                    dateFormat="d/M/yyyy"
                    selected={moment(checkInDateTime).toDate()}
                    onChange={this._onChangeCheckInDateTime}
                  />
                </div>
              </Label>
              <Text className="mh-medium">at</Text>
              <Label className="width-fit-content">
                <Text className="text-uppercase text-weight-bold text-size-small text-color-secondary" size="small">
                  {hasCheckOutDateTime && selectedBookingItem.status === BookingStatus.INPROGRESS
                    ? 'Time'
                    : 'Start Time'}
                </Text>
                <div className="block width-full">
                  <TimeInput size="large" value={checkInDateTime} onChange={this._onChangeCheckInDateTime} />
                </div>
              </Label>
            </div>

            {hasCheckOutDateTime && selectedBookingItem.status !== BookingStatus.INPROGRESS && (
              <>
                <div className="flex-row align-center justify-space-between">
                  <Label className="width-fit-content">
                    <Text className="text-uppercase text-weight-bold text-size-small text-color-secondary" size="small">
                      Finish Date
                    </Text>
                    <div className="block width-full">
                      <DatePicker
                        name="finishDate"
                        className="gh-datepicker rounded width-full"
                        calendarClassName="gh-datepicker-calendar"
                        dateFormat="d/M/yyyy"
                        selected={moment(checkOutDateTime).toDate()}
                        onChange={this._onChangeCheckOutDateTime}
                      />
                    </div>
                  </Label>
                  <Text className="mh-medium">at</Text>
                  <Label className="width-fit-content">
                    <Text className="text-uppercase text-weight-bold text-size-small text-color-secondary" size="small">
                      Finish Time
                    </Text>
                    <div className="block width-full">
                      <TimeInput size="large" value={checkOutDateTime} onChange={this._onChangeCheckOutDateTime} />
                    </div>
                  </Label>
                </div>
                {this.state.isErrorEndDate && (
                  <div className="mt-small text-color-red">
                    <Icon type="exclamation-circle" className="mr-x-small" />
                    End time cannot be before Start time{' '}
                  </div>
                )}
                <div className="mb-x-large" />
              </>
            )}

            <div className=" mb-medium">
              <Text size="small" color="secondary" className="text-capitalize">
                Current Timezone{' '}
                <span className="ml-small">
                  <TimezoneIndicator
                    hasIcon={false}
                    showTzName={false}
                    bordered={false}
                    timezone={this.props.displayTimezone}
                  />
                </span>
              </Text>
            </div>
          </div>
        )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading && this.state.isSuccess && (
          <div>
            <div className="mb-medium">
              <Paragraph>
                You have successfully edited the start
                {selectedBookingItem.status === BookingStatus.COMPLETED && '/finish'} time of the team member for this
                booking.
              </Paragraph>
            </div>
          </div>
        )}

        {!this.state.isSuccess && (
          <ActionModalFooter>
            <SecondaryButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._onSubmitEdit} loading={this.state.isLoading}>
              Save Changes
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {this.state.isSuccess && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedBookingItem: state.bookingsStore.selectedBookingItem });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateBookingWorkerCheckInDateTime: dispatch.bookingsStore.doUpdateBookingWorkerCheckInDateTime,
  doUpdateBookingWorkerCheckInOutDateTime: dispatch.bookingsStore.doUpdateBookingWorkerCheckInOutDateTime,
});

export default connect(mapState, mapDispatch)(BookingEditWorkerCheckedInOutDatesActionModel);
