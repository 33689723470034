import { css } from '../theme';
import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';
import { keyframes } from '@stitches/react';

export const wrapper: CSS = {
  display: 'flex',
  flexDirection: 'column',
  gap: '$space100',
};

export const label: CSS = {
  fontFamily: '$primary',
  fontSize: '$font75',
  fontWeight: '$bold',
  lineHeight: '$leading400',
};

export const searchField: CSS = {
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  cursor: 'text',
  padding: '$space0',
  position: 'relative',
  backgroundColor: '$white',
};

export const searchFieldVariants = css({
  variants: {
    size: {
      small: {
        height: '$size400',
      },
      medium: {
        height: '$size550',
      },
    },
  },
});

export const prefixComponent = css({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  left: '$space150',
});

export const input: CSS = {
  width: '100%',
  height: '100%',
  fontFamily: '$primary',
  color: '$black',
  backgroundColor: 'inherit',
  fontSize: '$small',
  fontWeight: '$normal',
  lineHeight: '$leading400',
  outline: 'none !important',
  margin: '$space0',
  padding: '$space0 $space450',
  '&::placeholder': {
    color: '$neutralDark1',
  },
};

export const inputVariants = css({
  borderRadius: '$small',
  borderWidth: '$border100',
  borderStyle: 'solid',
  borderColor: '$neutral',

  variants: {
    isHovered: {
      true: {
        borderColor: '$neutralDark1',
      },
    },
    isFocused: {
      true: {
        borderWidth: '$border200',
        borderColor: '#206E8C',
      },
    },
  },
});

export const suffixComponent: CSS = {
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  right: '$space150',
};

// Loading icon
const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const loadingIcon: CSS = {
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  animation: `${spin} 1.5s linear infinite;`,
};

export const buttonClear: CSS = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  backgroundColor: '$transparent',
  padding: '$space0',
};

export type SearchFieldVariants = Stitches.VariantProps<typeof searchFieldVariants>;
