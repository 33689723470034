import React from 'react';
import { Box, Flex, Anchor } from '@mantine/core';
import { ModalFooterButtons } from './modal-footer-buttons';
export const ModalFooter = (props) => {
    const { tertiaryLinkProps, subFooter, hasBorderTop, variant, ...footerButtonsProps } = props;
    const borderTop = hasBorderTop ? '1px solid var(--brand-body-light-2)' : undefined;
    return (<Box pos='sticky' bottom={0} bg='var(--mantine-color-white)' style={{ borderTop }}>
      {subFooter && (<Box pl='var(--mb-padding)' pr='var(--mb-padding)'>
          {subFooter}
        </Box>)}
      <Box p='var(--mb-padding)'>
        <Flex justify={variant === 'centered' ? 'center' : 'space-between'} align='center'>
          <div>
            {tertiaryLinkProps && (<Anchor className='focus:outline-none focus:underline' {...tertiaryLinkProps} component='button' type='button'>
                {tertiaryLinkProps.label ?? 'Tertiary'}
              </Anchor>)}
          </div>
          {variant !== 'centered' && <ModalFooterButtons {...footerButtonsProps} variant={variant}/>}
        </Flex>
      </Box>
    </Box>);
};
