import React, { Component } from 'react';
import { Form, Input, notification } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import { ICustomer } from 'interfaces/customer-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import CareLevelSelector from 'views/customers/details/components/CareLevelSelector';
import Utils from 'utilities/Utils';
import { AlertLevel, CareInformationType } from 'utilities/enum-utils';
const { TextArea } = Input;

interface ICreateEditMedicationModalProps extends FormComponentProps {
  closeCreateEditMedicationModal: () => void;
  isOpen: boolean;
  isNew: boolean;
  selectedCustomer: ICustomer;
  selectedMedication: any;
  doCreateCustomerCareInfo: typeof dispatch.customersStore.doCreateCustomerCareInfo;
  doUpdateCustomerCareInfo: typeof dispatch.customersStore.doUpdateCustomerCareInfo;
}

interface ICreateEditMedicationModalState {
  isLoadingFilter: boolean;
  isActionModalOpen: boolean;
  selectedLevel: string;
  isLoading: boolean;
}

class CreateEditMedicationModal extends Component<ICreateEditMedicationModalProps, ICreateEditMedicationModalState> {
  state = {
    isLoadingFilter: false,
    isActionModalOpen: false,
    selectedLevel: '',
    isLoading: false,
  };

  private _closeWithActionModal = () => {
    const { form, selectedMedication, isNew } = this.props;
    const { selectedLevel } = this.state;
    let hasChanged = false;
    const formValues = form.getFieldsValue();

    // Check if it's an Add or Edit modal
    if (isNew) {
      if (
        selectedLevel ||
        !Utils.isEmpty(formValues.medicationName) ||
        !Utils.isEmpty(formValues.medicationTreatment) ||
        !Utils.isEmpty(formValues.description)
      ) {
        hasChanged = true;
      }
    } else if (
      formValues.medicationName !== selectedMedication.medicationName ||
      formValues.medicationTreatment !== selectedMedication.treatmentFor ||
      selectedLevel !== selectedMedication.alertLevel ||
      formValues.description !== selectedMedication.description
    ) {
      hasChanged = true;
    }

    if (hasChanged) {
      this.setState({ isActionModalOpen: true });
    } else {
      this._closeActionCreateModal();
    }
  };

  private _closeActionCreateModal = () => {
    this.setState({
      isActionModalOpen: false,
      isLoadingFilter: false,
      isLoading: false,
      selectedLevel: _.isEmpty(this.props.selectedMedication)
        ? ''
        : AlertLevel[this.props.selectedMedication.alertLevel],
    });
    this.props.closeCreateEditMedicationModal();
  };

  private _changeLevel = (level) => {
    const { form } = this.props;
    form.setFieldsValue({ alertLevel: level });
    this.setState({ selectedLevel: level });
  };

  private _submitMedication = async () => {
    const { form, doCreateCustomerCareInfo, doUpdateCustomerCareInfo, selectedCustomer, selectedMedication, isNew } =
      this.props;
    const { selectedLevel } = this.state;

    this.setState({ isLoading: true });

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      const formValues = form.getFieldsValue();
      try {
        const payload = isNew
          ? {
              careInformationType: CareInformationType.MEDICATION,
              name: formValues.medicationName,
              treatmentFor: formValues.medicationTreatment,
              alertLevel: AlertLevel[selectedLevel],
              description: formValues.description,
              userId: selectedCustomer.userId,
            }
          : {
              careInformationType: CareInformationType.MEDICATION,
              careInformationId: selectedMedication.careInformationId,
              alertLevel: AlertLevel[selectedLevel],
              name: formValues.medicationName,
              treatmentFor: formValues.medicationTreatment,
              description: formValues.description,
              userId: selectedCustomer.userId,
            };
        isNew ? await doCreateCustomerCareInfo(payload) : await doUpdateCustomerCareInfo(payload);
        notification.success({ message: `Medication ${isNew ? 'added' : 'updated'} successfully.` });
        this._closeActionCreateModal();
      } catch (e) {
        notification.error({ message: 'Oops something went wrong! Please try again.' });
      }
    }

    this.setState({ isLoading: false });
  };

  componentDidUpdate(
    prevProps: Readonly<ICreateEditMedicationModalProps>,
    prevState: Readonly<ICreateEditMedicationModalState>,
    snapshot?: any,
  ) {
    if (prevProps.selectedMedication !== this.props.selectedMedication) {
      this.setState({
        selectedLevel: this.props.selectedMedication ? AlertLevel[this.props.selectedMedication.alertLevel] : '',
      });
    }
  }

  render() {
    const { form, selectedMedication, isNew } = this.props;
    const { selectedLevel, isLoading } = this.state;
    const { getFieldDecorator } = form;

    return (
      <div>
        <ActionModal
          isOpen={this.state.isActionModalOpen}
          onClose={this._closeActionCreateModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton
              className="mr-medium"
              size="large"
              onClick={() => {
                this.setState({ isActionModalOpen: false });
              }}
            >
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._closeActionCreateModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._closeWithActionModal}
          title={
            isNew ? (
              <>
                Add a <b>Medication</b>
              </>
            ) : (
              <>
                Edit a <b>Medication</b>
              </>
            )
          }
          showCloseButton={true}
          verticalAlignment={'center'}
          width={'medium'}
        >
          {isNew && (
            <div className="mb-large">
              <Text>
                Add a <b>medication</b> that the customer has informed you about.
              </Text>
            </div>
          )}
          <div className="mb-large">
            <SubTitle>Medication name</SubTitle>
            <Form.Item className="mb-large">
              {getFieldDecorator('medicationName', {
                rules: [{ required: true, message: 'Medication name required' }],
                initialValue: selectedMedication ? selectedMedication.name : null,
              })(<Input placeholder="Enter a medication name" style={{ width: '400px' }} />)}
            </Form.Item>
          </div>
          <div className="mb-large">
            <SubTitle>For the treatment for</SubTitle>
            <Form.Item className="mb-large">
              {getFieldDecorator('medicationTreatment', {
                rules: [{ required: true, message: 'Treatment reason is required' }],
                initialValue: selectedMedication ? selectedMedication.additionalInfo.treatmentFor : null,
              })(<Input placeholder="Enter a treatment here" style={{ width: '400px' }} />)}
            </Form.Item>
          </div>
          <div className="mb-large">
            <div className="flex-row align-center mb-x-small">
              <SubTitle>ALERT LEVEL</SubTitle>
              <Text className="ml-x-small" size="regular">
                (Select one)
              </Text>
            </div>
            <CareLevelSelector selectedLevel={selectedLevel} changeLevel={this._changeLevel} />
            <Form.Item className="p-none">
              {getFieldDecorator('alertLevel', {
                rules: [{ required: true, message: 'Choose one alert level' }],
                initialValue: selectedLevel,
              })(<Input type="hidden" />)}
            </Form.Item>
          </div>
          <div className="mb-x-large">
            <div className="flex-row align-center mb-x-small">
              <SubTitle>Description</SubTitle>
              <Text className="ml-x-small" size="regular">
                (Optional)
              </Text>
            </div>
            <Form.Item className="mb-large">
              {getFieldDecorator('description', {
                rules: [],
                initialValue: selectedMedication ? selectedMedication.description : null,
              })(<TextArea placeholder={'Describe this medication, or leave a remark about this medication here.'} />)}
            </Form.Item>
          </div>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._closeWithActionModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._submitMedication} loading={isLoading}>
              {isNew ? 'Add Medication' : 'Save Medication'}
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateCustomerCareInfo: dispatch.customersStore.doCreateCustomerCareInfo,
  doUpdateCustomerCareInfo: dispatch.customersStore.doUpdateCustomerCareInfo,
});

export default connect(null, mapDispatch)(Form.create<ICreateEditMedicationModalProps>()(CreateEditMedicationModal));
