import React, { useRef } from 'react';

import { useOption } from '@react-aria/listbox';
import { ListState } from '@react-stately/list';
import type { Node } from '@react-types/shared';
import { compose, css } from '../..';
import type { OptionsVariants } from './list-box-option.css';
import * as styles from './list-box-option.css';
import { mergeProps, useHover } from 'react-aria';

export type ListBoxOptionProps = OptionsVariants & {
  item: Node<unknown>;
  state: ListState<unknown>;
};

/**
 *
 * @name
 * ListBoxOption
 *
 * @description
 * Component used in ListBox
 *
 */
export function ListBoxOption(props: ListBoxOptionProps) {
  const { item, state } = props;
  const ref = useRef<HTMLLIElement>(null);

  const { optionProps, isSelected, isPressed, isDisabled } = useOption(
    {
      key: item.key,
    },
    state,
    ref,
  );
  const { hoverProps, isHovered } = useHover({ isDisabled });

  return (
    <li
      ref={ref}
      {...optionProps}
      {...hoverProps}
      className={compose(
        css(styles.reset, styles.option),
        styles.optionVariants({
          isSelected,
          isPressed,
          isDisabled,
          isHovered,
        }),
      )}
    >
      {item.props.prefix ?? ''}
      {item.rendered}
    </li>
  );
}
