import React from 'react';
import { Text, Skeleton } from '@good/ui/core';

import { useInlineForm } from './inline-form-context';
import { StaticValue } from './static-value';

export type StaticFieldProps = {
  emptyValue?: string;
  html?: boolean;
  label?: string;
  skeletonProps?: React.ComponentProps<typeof Skeleton>;
  value: string | number | Date | null;
};

export const StaticField = ({ emptyValue, html, label, skeletonProps, value }: StaticFieldProps) => {
  const { editId } = useInlineForm();

  if (!label) {
    return <StaticValue value={value} skeletonProps={skeletonProps} emptyValue={emptyValue} html={html} />;
  }

  const labelId = `label-${label.replace(/\s+/g, '-').toLowerCase()}`;

  return (
    <div className='flex flex-col'>
      <Text className='text-weak' id={labelId} size='sm'>
        {label}
      </Text>
      <span aria-readonly aria-labelledby={labelId} aria-describedby={editId}>
        <StaticValue value={value} skeletonProps={skeletonProps} emptyValue={emptyValue} html={html} />
      </span>
    </div>
  );
};
