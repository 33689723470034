import { Text } from '@good/components';
import { NumberFormatter, Separator } from '@goodhuman-me/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveTeamMembers } from 'views/insights/insights';

export type ActiveTeamMembersBreakdownProps = {
  activeTeamMembers: ActiveTeamMembers;
};

const employmentTypesInOrder = ['FullTime', 'PartTime', 'Casual', 'Volunteer', 'Other', 'NotSet'] as const;
type EmploymentType = (typeof employmentTypesInOrder)[number];

export const ActiveTeamMembersBreakdown = ({ activeTeamMembers }: ActiveTeamMembersBreakdownProps) => {
  const { t } = useTranslation('', { keyPrefix: 'insights.summary.activeTeamMembers' });

  const totalTeamMembers = Object.values(activeTeamMembers).reduce((acc, curr) => acc + curr, 0);

  return (
    <div className="flex flex-col space-y-4">
      <div>
        <Text className="text-b-xs text-gray-dark-1">
          <p>{t('label')}</p>
        </Text>
        <Text className="text-b-xl font-semibold">
          <span className="font-base" aria-valuetext={`${totalTeamMembers} active team members`}>
            <NumberFormatter value={totalTeamMembers} />
          </span>
        </Text>
      </div>
      <div className="flex-col space-y-2">
        {employmentTypesInOrder.map((employmentType: EmploymentType, index) => {
          const employees = activeTeamMembers[employmentType];
          const isLastElement = employmentTypesInOrder.length - 1 === index;

          return (
            <div key={employmentType}>
              <div className="flex justify-between">
                <Text className="text-b-xs">{t(`types.${employmentType}`)}</Text>
                <Text className="text-b-xs font-semibold">
                  <span aria-valuetext={`${employees ?? 0} ${t(`types.${employmentType}`)} team members`}>
                    <NumberFormatter value={employees ?? 0} />
                  </span>
                </Text>
              </div>
              {!isLastElement && <Separator thickness="thick" color="$backgroundSupport" />}
            </div>
          );
        })}
      </div>
    </div>
  );
};
