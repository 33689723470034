import React, { forwardRef, useRef } from 'react';
import { mergeRefs } from '@react-aria/utils';
import { useCheckbox } from '@react-aria/checkbox';
import { useToggleState } from '@react-stately/toggle';

import type { ReactNode, RefCallback, RefObject } from 'react';
import type { AriaCheckboxProps } from '@react-types/checkbox';

export type CheckboxProps = AriaCheckboxProps & {
  children: ReactNode | string;
  inputRef?: RefCallback<'input'>;
};

/**
 *
 * @name
 * Checkbox
 *
 * @description
 *
 * @example
 *
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(props, ref) {
  let { children, inputRef, ...otherProps } = props;
  let state = useToggleState(props);
  let mergedRefs = mergeRefs(ref, inputRef, useRef(null));
  let { inputProps } = useCheckbox(otherProps, state, mergedRefs as RefObject<HTMLInputElement>);

  return (
    <label style={{ display: 'flex', gap: '8px', alignItems: 'stretch' }}>
      <input {...inputProps} ref={ref} style={{ minHeight: '22px', flex: '0 0 auto' }} />
      {children}
    </label>
  );
});
