import { Checkbox, Col, Form, Icon, Input, notification, Row } from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import { IGroupServiceActivityGroup, IGroupServiceSession } from 'interfaces/service-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { IActivityGroupUsers } from 'interfaces/user-interfaces';
import { ActivityGroupMemberType, ActivityGroupModalType, CustomerActiveType } from 'utilities/enum-utils';
import RemoveMemberSelectedModalContent from './RemoveMemberSelectedModalContent';
import ActivityGroupsModal from '../ActivityGroupsModal';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import CommonUtils from 'utilities/common-utils';
import { Warning } from 'common-components/alerts';
import TimeInput from 'common-components/time-input/TimeInput';

const { TextArea } = Input;

type IEditModalContentProps = {
  serviceId: string;
  session?: IGroupServiceSession;
  activityGroup: IGroupServiceActivityGroup;
  form: WrappedFormUtils;
  isOpen: boolean;
  handleAction: (payload, actionType: ActivityGroupModalType) => Promise<boolean | void>;
  onCloseViewModal: () => void;
  doGetGroupServiceCustomers: typeof dispatch.customersStore.doGetGroupServiceCustomers;
  setGroupServiceCustomers: typeof dispatch.customersStore.setGroupServiceCustomers;
  groupServiceCustomers: typeof state.customersStore.groupServiceCustomers;
  doFetchGroupServiceTeamMembers: typeof dispatch.servicesStore.doFetchGroupServiceTeamMembers;
  setGroupServiceWorkerList: typeof dispatch.servicesStore.setGroupServiceWorkerList;
  groupServiceWorkerList: typeof state.servicesStore.groupServiceWorkerList;
};

interface IEditModalContentState {
  activityGroup: IGroupServiceActivityGroup;
  isLoading: boolean;

  isTeamMember: boolean;

  isAddModalOpen: boolean;
  isRemoveAllModalOpen: boolean;

  isCollapseCustomer: boolean;
  isCollapseTeamMember: boolean;

  isRemoveMemberSelectedModalOpen: boolean;

  isAllRowsSelected: boolean;
  customersSelected: IActivityGroupUsers[];
  teamMembersSelected: IActivityGroupUsers[];
  isModalOpen: boolean;
  currentModalType: ActivityGroupModalType;
  currentMember: IActivityGroupUsers;
  currentMemberType: ActivityGroupMemberType;

  isUsedSessionTime: boolean;
  isTimeWithinSessionTime: boolean;
  isDateTimeError: boolean;
}

class EditModalContent extends Component<IEditModalContentProps, IEditModalContentState> {
  state = {
    activityGroup: _.cloneDeep(this.props.activityGroup),
    isLoading: false,
    isCollapseCustomer: false,
    isCollapseTeamMember: false,
    isAddModalOpen: false,
    isRemoveMemberSelectedModalOpen: false,
    isTeamMember: false,
    isRemoveAllModalOpen: false,
    isCustomerRemoveMemberSelected: ActivityGroupMemberType.CUSTOMER,
    isAllRowsSelected: false,
    customersSelected: [],
    teamMembersSelected: [],
    isModalOpen: false,
    currentModalType: ActivityGroupModalType.CREATE,
    currentMember: null,
    currentMemberType: ActivityGroupMemberType.CUSTOMER,
    startDateTime: this.props.activityGroup.startDateTime
      ? moment.tz(this.props.activityGroup.startDateTime, this.props.session.timezone).toDate()
      : null,
    endDateTime: this.props.activityGroup.endDateTime
      ? moment.tz(this.props.activityGroup.endDateTime, this.props.session.timezone).toDate()
      : null,
    isUsedSessionTime:
      this.props.activityGroup.startDateTime && this.props.activityGroup.startDateTime
        ? this._isSameTimeAsSessionTime(this.props.activityGroup.startDateTime, this.props.activityGroup.endDateTime)
        : false,
    isTimeWithinSessionTime:
      this.props.activityGroup.startDateTime && this.props.activityGroup.startDateTime
        ? this._isTimeWithinSessionTime(this.props.activityGroup.startDateTime, this.props.activityGroup.endDateTime)
        : false,
    isDateTimeError: false,
  };

  private _isSelectedMember = () =>
    this.state.customersSelected.length > 0 || this.state.teamMembersSelected.length > 0;

  private _handleCheckGroupMember = () => {
    if (this.state.isAllRowsSelected) {
      this.setState({
        customersSelected: [],
        teamMembersSelected: [],
        isAllRowsSelected: false,
      });
    } else {
      this.setState({
        customersSelected: this.props.activityGroup.customers,
        teamMembersSelected: this.props.activityGroup.teamMembers,
        isAllRowsSelected: true,
      });
    }
  };

  private _onCustomersSelectedChange = (selectedValues: IActivityGroupUsers[]) => {
    const totalRowSelected = selectedValues.length + this.state.teamMembersSelected.length;
    const totalMember = this.props.activityGroup.teamMembers.length + this.props.activityGroup.customers.length;

    this.setState({
      customersSelected: selectedValues,
      isAllRowsSelected: totalMember == totalRowSelected,
    });
  };

  private _onTeamMembersSelectedChange = (selectedValues: IActivityGroupUsers[]) => {
    const totalRowSelected = selectedValues.length + this.state.customersSelected.length;
    const totalMember = this.props.activityGroup.teamMembers.length + this.props.activityGroup.customers.length;

    this.setState({
      teamMembersSelected: selectedValues,
      isAllRowsSelected: totalMember == totalRowSelected,
    });
  };

  private _onSubmitForm = async () => {
    const { form, handleAction, onCloseViewModal, session } = this.props;
    const { activityGroup } = this.state;
    this.setState({ isLoading: true });

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    const { startDateTime, endDateTime } = this.state.activityGroup;
    if (
      startDateTime &&
      endDateTime &&
      moment.tz(endDateTime, session.timezone).isBefore(moment.tz(startDateTime, session.timezone))
    ) {
      isFormValid = false;
      this.setState({ isDateTimeError: true });
    } else {
      this.setState({ isDateTimeError: false });
    }

    if (isFormValid) {
      let formValues = form.getFieldsValue();
      if (startDateTime && endDateTime) {
        formValues = { ...formValues, startDateTime, endDateTime };
      }

      try {
        const payload = {
          ...activityGroup,
          ...formValues,
          startDateTime,
          endDateTime,
        };
        await handleAction(payload, ActivityGroupModalType.EDIT);
        notification.success({
          message: 'Activity group updated',
          description: `You have successfully updated "${activityGroup.name}"`,
        });
        onCloseViewModal();
      } catch (e) {
        notification.error({
          message: 'Oops, something went wrong! Please try again.',
        });
      }
    }

    this.setState({ isLoading: false });
  };

  private _openModal = (
    modalType: ActivityGroupModalType,
    memberType = ActivityGroupMemberType.CUSTOMER,
    member: IActivityGroupUsers = null,
  ) => {
    this.setState({
      currentModalType: modalType,
      isModalOpen: true,
      currentMember: member,
      currentMemberType: memberType,
    });
  };

  private _handleMemberSelectedInRemoveModal = (
    customersUnRemove: IActivityGroupUsers[],
    teamMemberUnRemove: IActivityGroupUsers[],
  ) => {
    const customers = _.filter(this.state.activityGroup.customers, (cus) => {
      return !_.some(customersUnRemove, (cusRemove) => cusRemove.userId === cus.userId);
    });
    const teamMembers = _.filter(this.state.activityGroup.teamMembers, (cus) => {
      return !_.some(teamMemberUnRemove, (cusRemove) => cusRemove.userId === cus.userId);
    });
    this.setState({
      activityGroup: {
        ...this.state.activityGroup,
        customers: customers,
        teamMembers: teamMembers,
      },
      customersSelected: [],
      teamMembersSelected: [],
    });
  };

  private _handleModalAction = async (payload, actionType: ActivityGroupModalType) => {
    const { serviceId, groupServiceCustomers, groupServiceWorkerList } = this.props;
    const { memberType, customers, teamMembers } = payload;
    switch (actionType) {
      case ActivityGroupModalType.REMOVE_ALL:
        if (memberType === ActivityGroupMemberType.TEAM_MEMBER)
          this.setState({ activityGroup: { ...this.state.activityGroup, teamMembers: [] } });
        else this.setState({ activityGroup: { ...this.state.activityGroup, customers: [] } });
        break;
      case ActivityGroupModalType.ADD_CUSTOMER:
        const allCustomers: IActivityGroupUsers[] = _.unionBy(
          _.map(_.cloneDeep(groupServiceCustomers), (cus) => ({
            userId: cus.customerUserId,
            firstName: cus.customerFirstName,
            lastName: cus.customerLastName,
            attachmentUrl: cus.customerAvatarUrl,
          })),
          (c) => c.userId,
        );

        const newCustomers: any = _.filter(allCustomers, (cus) => (customers || []).includes(cus.userId));
        this.setState({ activityGroup: { ...this.state.activityGroup, customers: newCustomers } });
        break;
      case ActivityGroupModalType.ADD_TEAM_MEMBER:
        const allTeamMembers: IActivityGroupUsers[] = _.unionBy(
          _.map(_.cloneDeep(groupServiceWorkerList), (cus) => ({
            userId: cus.teamMemberUserId,
            firstName: cus.teamMemberFirstName,
            lastName: cus.teamMemberLastName,
            attachmentUrl: cus.teamMemberAvatarUrl,
          })),
          (c) => c.userId,
        );
        const newTeamMembers: any = _.filter(allTeamMembers, (cus) => (teamMembers || []).includes(cus.userId));
        this.setState({ activityGroup: { ...this.state.activityGroup, teamMembers: newTeamMembers } });
        break;
    }
  };

  private _renderCardPopoverContent = () => {
    return (
      <div>
        <Row
          className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
          onClick={() => {
            this._openModal(ActivityGroupModalType.ADD_CUSTOMER);
          }}
        >
          Add new customers
        </Row>
        <Row
          className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
          onClick={() => {
            this._openModal(ActivityGroupModalType.REMOVE_ALL, ActivityGroupMemberType.CUSTOMER);
          }}
        >
          <Text color="red">Remove all customers</Text>
        </Row>
        <div className="bordered-top mv-small" />

        <Row
          className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
          onClick={() => {
            this._openModal(ActivityGroupModalType.ADD_TEAM_MEMBER);
          }}
        >
          Add new team members
        </Row>
        <Row
          className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
          onClick={() => {
            this._openModal(ActivityGroupModalType.REMOVE_ALL, ActivityGroupMemberType.TEAM_MEMBER);
          }}
        >
          <Text color="red">Remove all team members</Text>
        </Row>
      </div>
    );
  };

  private _roundDate = (date) => moment(CommonUtils.formatCeilingDateTime(date));

  private _isTimeWithinSessionTime(currentStartDateTime: Date, currentEndDateTime: Date) {
    const { startDateTime, endDateTime, timezone } = this.props.session;
    return (
      moment
        .tz(currentStartDateTime, timezone)
        .isBetween(moment.tz(startDateTime, timezone), moment.tz(endDateTime, timezone), null, '[]') &&
      moment
        .tz(currentEndDateTime, timezone)
        .isBetween(moment.tz(startDateTime, timezone), moment.tz(endDateTime, timezone), null, '[]')
    );
  }

  private _isSameTimeAsSessionTime(currentStartDateTime: Date, currentEndDateTime: Date) {
    const { startDateTime, endDateTime, timezone } = this.props.session;
    return (
      moment.tz(currentStartDateTime, timezone).isSame(moment.tz(startDateTime, timezone)) &&
      moment.tz(currentEndDateTime, timezone).isSame(moment.tz(endDateTime, timezone))
    );
  }

  private _handleChangeStartDateTime = (value: Date) => {
    this.setState((prevState) => ({
      isUsedSessionTime: this._isSameTimeAsSessionTime(value, prevState.activityGroup.endDateTime),
      isTimeWithinSessionTime: this._isTimeWithinSessionTime(value, prevState.activityGroup.endDateTime),
      activityGroup: {
        ...prevState.activityGroup,
        startDateTime: this._roundDate(moment(value)).toDate(),
      },
    }));
  };

  private _handleChangeEndDateTime = (value: Date) => {
    this.setState((prevState) => ({
      isUsedSessionTime: this._isSameTimeAsSessionTime(prevState.activityGroup.startDateTime, value),
      isTimeWithinSessionTime: this._isTimeWithinSessionTime(prevState.activityGroup.startDateTime, value),
      activityGroup: {
        ...prevState.activityGroup,
        endDateTime: this._roundDate(moment(value)).toDate(),
      },
    }));
  };

  private _handleUseSessionTime = (e) => {
    this.setState((prevState) => {
      let newState = { ...prevState, isUsedSessionTime: !prevState.isUsedSessionTime };
      if (e.target.checked) {
        const { startDateTime, endDateTime, timezone } = this.props.session;
        newState = {
          ...newState,
          isTimeWithinSessionTime: true,
          activityGroup: {
            ...prevState.activityGroup,
            startDateTime: this._roundDate(moment.tz(startDateTime, timezone)).toDate(),
            endDateTime: this._roundDate(moment.tz(endDateTime, timezone)).toDate(),
          },
        };
      }

      return newState;
    });
  };

  componentDidMount = async () => {
    await this.props.doGetGroupServiceCustomers({
      serviceId: this.props.serviceId,
      customerType: CustomerActiveType.ACTIVE,
    });
    await this.props.doFetchGroupServiceTeamMembers({
      serviceId: this.props.serviceId,
    });
  };

  componentWillUnmount(): void {
    // reset store data
    // due to fetching is appending...
    const { setGroupServiceCustomers, setGroupServiceWorkerList } = this.props;
    setGroupServiceCustomers([]);
    setGroupServiceWorkerList([]);
  }

  render() {
    const { form, onCloseViewModal, session } = this.props;
    const {
      activityGroup,
      isLoading,
      customersSelected,
      teamMembersSelected,
      isAllRowsSelected,
      currentModalType,
      currentMember,
      currentMemberType,
      isModalOpen,
      isUsedSessionTime,
      isTimeWithinSessionTime,
      isDateTimeError,
    } = this.state;
    const { name, description, customers, teamMembers, startDateTime, endDateTime } = activityGroup;
    const { getFieldDecorator } = form;

    return (
      <div>
        <Row>
          {startDateTime && endDateTime && !isTimeWithinSessionTime && (
            <Warning
              color='orange'
              borderNone={true}
              className='mb-small bg-orange-lightest rounded-big bordered border-orange-lighter'
              content={
                <>
                  <Text weight='bold' className='block'>
                    Activity time is outside the session time
                  </Text>
                  <Text>
                    This activity group doesn&apos;t fit within the session timeframe. Please make sure your activity
                    runs within the session.
                  </Text>
                </>
              }
            />
          )}
          <SubTitle>Group name</SubTitle>
          <Form.Item>
            {getFieldDecorator('name', {
              initialValue: name,
              rules: [
                { required: true, message: 'Please enter a name for this group' },
                {
                  max: 50,
                  message: 'Please enter less than 50 characters',
                },
              ],
            })(<Input placeholder='Enter a group name' className='mt-x-small' />)}
          </Form.Item>
        </Row>
        <Row>
          <SubTitle>Description (optional)</SubTitle>
          <Form.Item>
            {getFieldDecorator('description', {
              initialValue: description,
              rules: [
                {
                  max: 2500,
                  message: 'Please enter less than 2500 characters',
                },
              ],
            })(<TextArea placeholder='Tell people a bit about this group...' autoSize={{ minRows: 5 }} />)}
          </Form.Item>
        </Row>
        {startDateTime && endDateTime && (
          <>
            <Row>
              <Col span={6} className='mr-large'>
                <SubTitle>Start Date</SubTitle>
                <Form.Item>
                  {getFieldDecorator('startDateTime', {
                    initialValue: moment.tz(startDateTime, session.timezone),
                    rules: [{ required: true, message: 'Please select an start date for this group' }],
                  })(
                    <DatePicker
                      className={`gh-datepicker rounded p-none ph-small ${isDateTimeError && 'border-red-dark'}`}
                      calendarClassName='gh-datepicker-calendar'
                      dateFormat='EEE, dd MMM yyyy'
                      onChange={this._handleChangeStartDateTime}
                      isClearable={false}
                      selected={moment.tz(startDateTime, session.timezone).toDate()}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={10}>
                <SubTitle>End Date</SubTitle>
                <Form.Item>
                  {getFieldDecorator('endDateTime', {
                    initialValue: moment.tz(endDateTime, session.timezone),
                    rules: [{ required: true, message: 'Please select an end date for this group' }],
                  })(
                    <DatePicker
                      className={`gh-datepicker rounded p-none ph-small ${isDateTimeError && 'border-red-dark'}`}
                      calendarClassName='gh-datepicker-calendar'
                      dateFormat='EEE, dd MMM yyyy'
                      onChange={this._handleChangeEndDateTime}
                      isClearable={false}
                      selected={moment.tz(endDateTime, session.timezone).toDate()}
                      minDate={moment.tz(startDateTime, session.timezone).startOf('day').toDate()}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <SubTitle>Start Time</SubTitle>
                <div className='flex align-center'>
                  <TimeInput
                    size='large'
                    onChange={this._handleChangeStartDateTime}
                    value={moment.tz(startDateTime, session.timezone)}
                    className={`${isDateTimeError && 'border-red-dark'}`}
                  />
                  <Text color='secondary' className='mh-medium'>
                    to
                  </Text>
                </div>
              </Col>
              <Col span={6}>
                <SubTitle>End Time</SubTitle>
                <TimeInput
                  size='large'
                  onChange={this._handleChangeEndDateTime}
                  value={moment.tz(endDateTime, session.timezone)}
                  className={`${isDateTimeError && 'border-red-dark'}`}
                />
              </Col>
            </Row>
            {isDateTimeError && (
              <Row>
                <Col span={24}>
                  <Text size='large' color='red-dark'>
                    End date time must be after the start date time
                  </Text>
                </Col>
              </Row>
            )}
            <Row className='mt-12 mb-x-large'>
              {!isTimeWithinSessionTime && (
                <div className='mb-small'>
                  <Icon type='warning' theme='filled' className='mr-small text-color-warning-orange' />
                  <Text type='warning'>Activity time doesn’t fit within the session timeframe</Text>
                </div>
              )}
              <Checkbox
                className='ant-typography text-size-large line-height-135'
                checked={isUsedSessionTime}
                onChange={this._handleUseSessionTime}
              >
                Make same as session time
              </Checkbox>
            </Row>
          </>
        )}

        {/** TODO (Mitch): 15/07/22 - Remove checkbox selection
         * Deemed redundant and buggy.
         * Revert this commit if we want the checkbox selection back.
         * Delete this TODO if it makes it to production
         * */}

        <ActionModalFooter className='flex-row'>
          <div className='flex flex-grow'></div>
          <SecondaryButton size='large' color='secondary' onClick={onCloseViewModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton className='ml-medium' size='large' loading={isLoading} onClick={this._onSubmitForm}>
            Save changes
          </PrimaryButton>
        </ActionModalFooter>
        {this.state.isRemoveMemberSelectedModalOpen && (
          <RemoveMemberSelectedModalContent
            activityGroupName={name}
            serviceId={this.props.serviceId}
            customersSelected={customersSelected}
            teamMembersSelected={teamMembersSelected}
            onInteractWithRemovedMember={this._handleMemberSelectedInRemoveModal}
            onClose={() => {
              this.setState({ isRemoveMemberSelectedModalOpen: false });
            }}
          />
        )}

        {this.state.isModalOpen && (
          <ActivityGroupsModal
            serviceId={this.props.serviceId}
            activityGroup={activityGroup}
            modalType={currentModalType}
            member={currentMember}
            memberType={currentMemberType}
            isOpen={isModalOpen}
            handleAction={this._handleModalAction}
            onCloseViewModal={() => {
              this.setState({ isModalOpen: false });
            }}
            selectedUser={currentMember}
            ignoredAlert={true}
          />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceCustomers: state.customersStore.groupServiceCustomers,
  groupServiceWorkerList: state.servicesStore.groupServiceWorkerList,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetGroupServiceCustomers: dispatch.customersStore.doGetGroupServiceCustomers,
  setGroupServiceCustomers: dispatch.customersStore.setGroupServiceCustomers,
  doFetchGroupServiceTeamMembers: dispatch.servicesStore.doFetchGroupServiceTeamMembers,
  setGroupServiceWorkerList: dispatch.servicesStore.setGroupServiceWorkerList,
});
export default connect(mapState, mapDispatch)(EditModalContent);
