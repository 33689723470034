import React, { Component } from 'react';
import { Col, Divider, Row } from 'antd';
import { Text } from 'common-components/typography';
import { Title, Avatar } from '@good/ui/core';
import { HyperlinkButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import PortalEditNameActionModel from 'views/account/components/PortalEditNameActionModel';
import PortalEditAvatarActionModel from 'views/account/components/PortalEditAvatarActionModel';
import _ from 'lodash';
import { TimezoneIndicator } from 'common-components/timezone';
import PortalEditTimezoneActionModal from 'views/account/components/PortalEditTimezoneActionModal';
import ChangePasswordModel from 'views/account/components/ChangePasswordModel';
import { Link } from 'react-router-dom';
import { Flag } from 'common-components/utils/launchdarkly/flag';

type MyProfileViewState = {
  isEditNameModelOpen: boolean;
  isEditAvatarModelOpen: boolean;
  isEditTimezoneOpen: boolean;
  isChangePasswordModelOpen: boolean;
};

type MyProfileViewProps = {
  doGetPortalUserDetail: typeof dispatch.authStore.doGetPortalUserDetail;
  portalUser: typeof state.authStore.portalUser;
};

class MyProfileView extends Component<MyProfileViewProps, MyProfileViewState> {
  override state = {
    isEditNameModelOpen: false,
    isEditAvatarModelOpen: false,
    isChangePasswordModelOpen: false,
    isEditTimezoneOpen: false,
  };

  private _onCloseEditNameModel = () => {
    this.setState({ isEditNameModelOpen: false });
  };

  private _onOpenEditNameModel = () => {
    this.setState({ isEditNameModelOpen: true });
  };

  private _onCloseEditAvatarModel = () => {
    this.setState({ isEditAvatarModelOpen: false });
  };

  private _onOpenEditAvatarModel = () => {
    this.setState({ isEditAvatarModelOpen: true });
  };

  private _onCloseChangePasswordModel = () => {
    this.setState({ isChangePasswordModelOpen: false });
  };

  private _onOpenChangePasswordModel = () => {
    this.setState({ isChangePasswordModelOpen: true });
  };

  private _onCloseEditTimezoneModal = () => {
    this.setState({ isEditTimezoneOpen: false });
  };

  private _onOpenEditTimezoneModel = () => {
    this.setState({ isEditTimezoneOpen: true });
  };

  private _getRolesText = (roles: string[]) => {
    if (_.isEmpty(roles)) return 'Not set';
    if (_.isArray(roles)) {
      const formattedRoles = _.reduce(
        roles,
        (acc, r) => {
          if (r === 'SupportWorker') {
            acc.push('Support worker');
          } else {
            acc.push(_.capitalize(r));
          }
          return acc;
        },
        [] as string[],
      );
      return _.join(formattedRoles, ', ');
    }
    return roles;
  };

  override async componentDidMount() {
    const { doGetPortalUserDetail } = this.props;

    await doGetPortalUserDetail({});
  }

  override render() {
    const { portalUser } = this.props;

    return (
      portalUser && (
        <>
          <PortalEditNameActionModel
            isOpen={this.state.isEditNameModelOpen}
            onCloseModel={this._onCloseEditNameModel}
          />
          <ChangePasswordModel
            isOpen={this.state.isChangePasswordModelOpen}
            onClose={this._onCloseChangePasswordModel}
          />
          <PortalEditAvatarActionModel
            isOpen={this.state.isEditAvatarModelOpen}
            onCloseModel={this._onCloseEditAvatarModel}
          />
          <PortalEditTimezoneActionModal
            isOpen={this.state.isEditTimezoneOpen}
            onCloseModal={this._onCloseEditTimezoneModal}
          />
          <Title order={1}>Your Profile</Title>
          <div>
            <Row>
              <Col>
                <Row>
                  <Title order={3} className='mt-x2-large'>
                    General
                  </Title>
                </Row>
                <Row className='mt-large justify-between'>
                  <Col span={8}>
                    <Text size='x-large' className='text-align'>
                      Profile Picture
                    </Text>
                  </Col>
                  <Col span={8}>
                    <div className='flex justify-center'>
                      <Avatar src={portalUser.attachmentUrl} size={120} />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className='text-align-right'>
                      <HyperlinkButton onClick={this._onOpenEditAvatarModel}>Change Photo</HyperlinkButton>
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row className='mt-large'>
                  <Col span={8}>
                    <Text size='x-large' className='text-align'>
                      Name
                    </Text>
                  </Col>
                  <Col span={8}>
                    <Text size='x-large' className='text-align'>
                      {portalUser.firstName} {portalUser.lastName}
                    </Text>
                  </Col>
                  <Col span={8}>
                    <div className='text-align-right'>
                      <HyperlinkButton onClick={this._onOpenEditNameModel}>Edit</HyperlinkButton>
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row className='mt-large'>
                  <Col span={8}>
                    <Text size='x-large' className='text-align'>
                      Role(s)
                    </Text>
                  </Col>
                  <Col span={8}>
                    <Text size='x-large' className='text-align'>
                      {this._getRolesText(portalUser.roles)}
                    </Text>
                  </Col>
                  <Col span={8} />
                </Row>
                <Divider />
                <Row className='mt-large'>
                  <Col span={8}>
                    <Text size='x-large' className='text-align'>
                      Timezone
                    </Text>
                  </Col>
                  <Col span={14}>
                    <div className='mb-x-small'>
                      <Text>
                        Timezone set as{' '}
                        {portalUser.displayTimezoneSetting && !portalUser.displayTimezoneSetting.autoDetect ? (
                          <b>Manual</b>
                        ) : (
                          <b>Automatic</b>
                        )}
                      </Text>
                    </div>
                    <TimezoneIndicator timezone={portalUser.timezone} />
                  </Col>
                  <Col span={2}>
                    <div className='text-align-right'>
                      <HyperlinkButton onClick={this._onOpenEditTimezoneModel}>Edit</HyperlinkButton>
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Title order={3} className='mt-x2-large'>
                    Account
                  </Title>
                </Row>
                <Row className='mt-large justify-between'>
                  <Col span={8}>
                    <Text size='x-large' className='text-align'>
                      Login
                    </Text>
                  </Col>
                  <Col span={8}>
                    <Text size='small' className='text-align' color='secondary'>
                      {portalUser.email}
                      <br />
                      Managed by {portalUser.serviceProviderName}
                    </Text>
                  </Col>
                  <Col span={8}>
                    <div className='text-align-right'>
                      <HyperlinkButton onClick={this._onOpenChangePasswordModel}>Change Password</HyperlinkButton>
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Flag
                  flag='publicApiPersonalAccessTokens'
                  on={
                    <>
                      <Row className='mt-large justify-between'>
                        <Col span={8}>
                          <Text size='x-large' className='text-align'>
                            Access Tokens
                          </Text>
                        </Col>
                        <Col span={8}>
                          <ActiveTokenCount />
                        </Col>
                        <Col span={8}>
                          <div className='text-align-right'>
                            <Link to='/my-profile/access-tokens' className='text-blue-ref hover:text-blue-ref'>
                              Manage Tokens
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                    </>
                  }
                />
              </Col>
            </Row>
          </div>
        </>
      )
    );
  }
}

const ActiveTokenCount = () => {
  return (
    <Text size='small' className='text-align' color='secondary'>
      No active tokens
    </Text>
  );
};

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });
const mapDispatch = (dispatch: IRootDispatch) => ({ doGetPortalUserDetail: dispatch.authStore.doGetPortalUserDetail });

export default connect(mapState, mapDispatch)(MyProfileView);
