import React, { useState } from 'react';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { Box, Icons, Inline, Stack, Separator, Text } from '@goodhuman-me/components';
import { useTranslation } from 'react-i18next';

import * as styles from './categories.css';
import { BurnChart } from './burn-chart';
import { Header } from './header';
import { Metrics } from './stats';
import { Pace } from './pace';
import { SupportItems } from './support-items';

import type { Budget } from '../../../../budget';
import type { ToggleGroupSingleProps } from '@radix-ui/react-toggle-group';

export type CategoriesProps = Pick<Budget['overview'], 'service'> & Pick<Budget['budget'], 'categories'>;

export function Categories(props: CategoriesProps): JSX.Element {
  const { categories, service } = props;
  let categoriesCount = categories.length;

  let { t } = useTranslation('', { keyPrefix: 'budget.category.header' });

  return (
    <Stack gap="$space800">
      {categories.map((c, i) => {
        let [displayMode, setDisplayMode] = useState(c.supportCoordinationTracking ? 'hours' : 'dollars');
        let isLastCategory = i + 1 === categoriesCount;
        let supportItems = Object.entries(c.supportItemTracking);
        let supportItemsCount = supportItems.length;
        let displayInHours = displayMode === 'hours';
        let isShowPace = c.supportCoordinationTracking && c.status !== 'NONE';

        function handleOnChange(value: string) {
          if (value) setDisplayMode(value);
        }

        return (
          <Stack gap="$space600" key={c.id}>
            <Stack>
              <Inline collapseAt="768px" gap="$large">
                <Stack gap="$space200" flex="1">
                  <Header {...c} {...service} displayInHours={displayInHours} />

                  {c.supportCoordinationTracking && (
                    <Stack gap="$space200">
                      <Toggle value={displayMode} onValueChange={handleOnChange} />

                      {displayInHours && (
                        <Box
                          backgroundColor="$bodyLight2"
                          borderRadius="$small"
                          padding="$space100"
                          width="fit-content"
                        >
                          <Text size="xsmall">
                            <Inline gap="$space50" alignItems="center">
                              <Icons.Notice width="1em" /> {t('approximationWarning')}
                            </Inline>
                          </Text>
                        </Box>
                      )}
                    </Stack>
                  )}

                  <Box marginTop="$space100">
                    <Metrics {...c} displayInHours={displayInHours} />
                  </Box>
                </Stack>

                <Box marginTop="calc($space1000 + $space50)">
                  <BurnChart {...c} key={`burn-chart-${c.id}-${displayInHours}`} displayInHours={displayInHours} />
                </Box>
              </Inline>

              <Stack>
                {isShowPace && <Pace service={service} {...c} />}
                {supportItemsCount > 0 && <SupportItems {...c} />}
              </Stack>
            </Stack>

            {!isLastCategory && <Separator orientation="horizontal" thickness="thicker" />}
          </Stack>
        );
      })}
    </Stack>
  );
}

type ToggleProps = Pick<ToggleGroupSingleProps, 'value' | 'onValueChange'>;

function Toggle(props: ToggleProps) {
  let { value, onValueChange } = props;

  return (
    <Box borderStyle="solid" borderWidth="$thin" borderColor="$body" borderRadius="$medium" width="fit-content">
      <ToggleGroup.Root type="single" value={value} onValueChange={onValueChange}>
        <ToggleGroup.Item value="dollars" asChild>
          <button className={styles.toggleButton()}>
            <Text size="small" fontWeight="$semi">
              $
            </Text>
          </button>
        </ToggleGroup.Item>

        <ToggleGroup.Item value="hours" asChild>
          <button className={styles.toggleButton()}>
            <Text size="small" fontWeight="$semi">
              Hr
            </Text>
          </button>
        </ToggleGroup.Item>
      </ToggleGroup.Root>
    </Box>
  );
}
