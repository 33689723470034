import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '@goodhuman-me/components';
import { Text } from '@good/components';
import { Archive } from '@good/icons';

import { TableColumn } from 'design-components';
import { WorkTypeCategory, WorkTypeState } from 'utilities/enum-utils';
import { IWorkLeaveType } from 'interfaces/account-interfaces';
import { MeatBallsDropdown, Popover } from '../components';
import useWorkTypePermissions from './use-work-type-permissions';

type ITextCellProps = {
  text: string;
  record: IWorkLeaveType;
  textClass?: string;
};

const TextCell = ({ text, record, textClass }: ITextCellProps) => {
  const isArchived = WorkTypeState[record.state] === WorkTypeState.INACTIVE;

  return (
    <div className="pl-medium pr-medium" style={{ paddingTop: 15, paddingBottom: 15 }}>
      <Text className={[textClass, 'break-words', isArchived ? 'text-weak' : ''].join(' ')}>
        <span style={{ wordBreak: 'break-word' }}>{text}</span>
      </Text>
    </div>
  );
};

function useTableColumns(
  category: WorkTypeCategory,
  onEdit?: (workLeaveType: IWorkLeaveType) => void,
  onArchive?: (workLeaveType: IWorkLeaveType) => void,
  onRestore?: (workLeaveType: IWorkLeaveType) => void,
): TableColumn<unknown>[] {
  const { t } = useTranslation('', { keyPrefix: `accountManagement.workLeaveTypesPage.table.${category}` });
  const { canUpdate: canUpdateWorkType } = useWorkTypePermissions();
  const tableColumns = [];

  tableColumns.push({
    title: <Text className="text-uppercase pl-x-small pr-x-small font-semibold">{t('workTypeColumn.heading')}</Text>,
    dataIndex: 'name',
    key: 'name',
    width: 288,
    render: (text: string, record: IWorkLeaveType) => (
      <TextCell text={text} record={record} textClass="font-semibold" />
    ),
  });

  tableColumns.push({
    title: (
      <div className="align-center pl-x-small pr-x-small flex" style={{ gap: 2 }}>
        <Text className="text-uppercase font-semibold">{t('workTypeExternalIdColumn.heading')}</Text>
        <Popover content={t('workTypeExternalIdColumn.popover.content')} />
      </div>
    ),
    width: 288,
    dataIndex: 'workTypeExternalId',
    key: 'workTypeExternalId',
    render: (text: string, record: IWorkLeaveType) => <TextCell text={text} record={record} />,
  });

  if (category === WorkTypeCategory.Leave) {
    tableColumns.push({
      title: '',
      dataIndex: 'state',
      key: 'state',
      width: '100%',
      render: (state: IWorkLeaveType['state']) =>
        WorkTypeState[state] === WorkTypeState.INACTIVE ? (
          <div className="align-center flex justify-center">
            <div className="pl-small pr-small pt-medium pb-medium">
              <Label size="xsmall" emphasis="outlined" tone="neutral" data-testid="archived-label">
                <div className="align-center flex" style={{ gap: 2 }}>
                  <Archive />
                  {WorkTypeState[state]}
                </div>
              </Label>
            </div>
          </div>
        ) : null,
    });
  }

  if (canUpdateWorkType) {
    tableColumns.push({
      title: '',
      dataIndex: '',
      key: 'meatballs',
      width: 54,
      render: (_text: string, record: IWorkLeaveType) => (
        <div className="align-center flex justify-center">
          <MeatBallsDropdown workLeaveType={record} onEdit={onEdit} onArchive={onArchive} onRestore={onRestore} />
        </div>
      ),
    });
  }

  return tableColumns;
}

export default useTableColumns;
