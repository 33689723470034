import React from 'react';

import _ from 'lodash';
import moment from 'moment';

import { Avatar, Icon } from 'antd';
import { Popover } from '@blueprintjs/core';

import { Text } from 'common-components/typography';
import { IconButton } from 'common-components/buttons';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';

import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';

import {
  IRosterShift,
  IRosterWorker,
  IRosterWorkerWithShifts,
} from 'common-components/roster-control/interfaces/roster-interfaces';
import CommonUtils from 'utilities/common-utils';
import { useHistory } from 'react-router-dom';

interface WorkerContentRowProps {
  worker: IRosterWorker;
  assignedShifts?: IRosterWorkerWithShifts[];
  // totalDuration: number;
}

const { TIMELINE_ROW_HEIGHT } = ROSTER_DAILY_CONFIG;

export function WorkersColumn({ worker, assignedShifts }: WorkerContentRowProps) {
  const history = useHistory();
  const { workerFirstName, workerLastName, supportWorkerId, workerAvatarUrl } = worker;

  const workerWithShifts = _.find(assignedShifts, (shifts) => shifts.supportWorkerId === supportWorkerId);

  const totalDuration = getTotalDurationForShifts(workerWithShifts);

  const rowHeight =
    workerWithShifts && !_.isEmpty(workerWithShifts.shifts)
      ? _.size(workerWithShifts.shifts) * TIMELINE_ROW_HEIGHT
      : TIMELINE_ROW_HEIGHT;

  const fullName = `${workerFirstName} ${workerLastName}`;

  // TODO : Detect warnings
  const hasWarnings = false;

  const goToProfile = (supportWorkerId) => {
    history.push(`/team/details/${supportWorkerId}`);
  };

  return (
    <div
      className="bg-quaternary pv-medium pl-x-large pr-medium flex-row justify-between bordered-right bordered-bottom anim-fade-in-fast"
      style={{ height: rowHeight }}
    >
      <div className="flex-row align-center">
        <div className="mr-12">
          <Avatar src={workerAvatarUrl} size="large" shape="square" icon="user" />
        </div>

        <div className="line-height-135 flex-column" style={{ width: '180px' }}>
          <div className="whitespace-nowrap overflow-hidden text-overflow-ellipsis">
            <Text>{fullName}</Text>
          </div>

          <div className="line-height-120 flex-row align-center">
            <div className="whitespace-nowrap overflow-hidden text-overflow-ellipsis">
              <Text size="regular" color="secondary" lineHeight={120}>
                {CommonUtils.formatDurationFromSecondsString(totalDuration)}
              </Text>
            </div>

            {hasWarnings && <Icon type="warning" theme="filled" className="text-color-warning-orange ml-small" />}
          </div>
        </div>
      </div>
      <Popover
        content={
          <ActionMenu>
            <ActionMenuItem text={'View profile'} onClick={() => goToProfile(supportWorkerId)} />
          </ActionMenu>
        }
        position="bottom-left"
      >
        <IconButton icon="ellipsis" size="small" className="bg-white" bordered={true} iconColor="secondary" />
      </Popover>
    </div>
  );
}

function getTotalDurationForShifts(workerShifts) {
  const shiftGroups = _.flatten(workerShifts.shifts);

  return _.reduce(
    shiftGroups,
    (acc, shift: IRosterShift) => {
      const { shiftStartDateTime, shiftEndDateTime } = shift;

      // difference of shiftStartDateTime & shiftEndDateTime in seconds
      const duration = moment(shiftEndDateTime).diff(moment(shiftStartDateTime), 'seconds');

      return acc + duration;
    },
    0,
  );
}
