import fileDownload from 'js-file-download';
import { isString } from 'lodash';
import moment from 'moment';
import {
  ConfigStyleDisplayName,
  IBatch,
  IData,
  SupportedFinanceExportConfigStyle,
} from 'views/billings/utils/constants';
import { parse } from 'json2csv/dist/json2csv.umd';
import { ExportBatchFormatMapper } from 'views/billings/utils/export-batch-format-mapper';
import { notification } from 'antd';

const formatBatchToCsv = (data: string | IData[]) => {
  if (isString(data)) {
    return data;
  }

  if ((data?.length ?? 0) < 1) {
    throw new Error('No data to export');
  }

  return parse(data);
};

const exportBatchToCsv = (unprocessedData: IData[] | string, styleKey: SupportedFinanceExportConfigStyle) => {
  const type = styleKey === SupportedFinanceExportConfigStyle.MD365 ? 'tsv' : 'csv';
  const styleDisplayName = ConfigStyleDisplayName[styleKey] || styleKey;
  const exportedDate = moment().format('DDMMYY');
  if (unprocessedData) {
    const processedDataToCsv = formatBatchToCsv(unprocessedData);
    fileDownload(processedDataToCsv, `GoodHuman-${styleDisplayName}Export-${exportedDate}.${type}`);
  }
};

const exportBatch = async (props: { styleKey: SupportedFinanceExportConfigStyle; batches: IBatch[] }) => {
  try {
    const { styleKey, batches } = props;
    const unprocessedData = ExportBatchFormatMapper.formatBatches(batches, styleKey);
    exportBatchToCsv(unprocessedData, styleKey);
  } catch (e) {
    notification.error({ message: e?.message || 'Oops, something went wrong! Please try again.' });
  }
};

export default exportBatch;
