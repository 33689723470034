import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CommentAlert = forwardRef(function CommentAlert(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.945 3.25H15.055c1.367 0 2.47 0 3.337.117.9.12 1.658.38 2.26.981.602.602.86 1.36.982 2.26.116.867.116 1.97.116 3.337V20a.75.75 0 0 1-1.085.67l-2.311-1.155c-.871-.435-1.203-.596-1.552-.678-.348-.082-.717-.087-1.69-.087H8.944c-1.367 0-2.47 0-3.337-.116-.9-.122-1.658-.38-2.26-.982-.602-.602-.86-1.36-.981-2.26-.117-.867-.117-1.97-.117-3.337V9.945c0-1.367 0-2.47.117-3.337.12-.9.38-1.658.981-2.26.602-.602 1.36-.86 2.26-.981.867-.117 1.97-.117 3.337-.117ZM5.808 4.853c-.734.099-1.122.28-1.399.556-.277.277-.457.665-.556 1.4-.101.754-.103 1.756-.103 3.191v2c0 1.435.002 2.437.103 3.192.099.734.28 1.122.556 1.399.277.277.665.457 1.4.556.755.101 1.756.103 3.191.103h6.201c.854 0 1.407 0 1.946.127.54.127 1.033.375 1.798.757l.08.04 1.225.613V10c0-1.435-.002-2.437-.103-3.192-.099-.734-.28-1.122-.556-1.399-.277-.277-.665-.457-1.4-.556-.754-.101-1.755-.103-3.19-.103H9c-1.436 0-2.437.002-3.193.103ZM13 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-.25-7a.75.75 0 0 0-1.5 0v4a.75.75 0 1 0 1.5 0V7Z"
        fill="currentColor"
      />
    </Icon>
  );
});
