import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Procent = forwardRef(function Procent(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.398 3.364a.75.75 0 0 1 .238 1.034l-10 16a.75.75 0 1 1-1.272-.795l10-16a.75.75 0 0 1 1.034-.239ZM6 4.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM2.25 7a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Zm13.5 10a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM18 13.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
