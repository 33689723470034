import { Text } from 'common-components/typography';
import React from 'react';
import { ROSTER_WEEKLY_CONFIG } from 'common-components/roster-control/roster-weekly/roster-weekly-config';
import CommonUtils from 'utilities/common-utils';

import { useSelector } from 'react-redux';
import { IRootState } from 'src/stores/rematch/root-store';

const { CONTENT_PANEL_WIDTH, GROUP_ROW_HEIGHT, SIDEBAR_WIDTH } = ROSTER_WEEKLY_CONFIG;

interface HoursWorkedColumnProps {
  showCost?: boolean;
  hoursTotal: number;
  costTotal: number;
  day: Date;
}

export function HoursWorkedColumn({ hoursTotal, costTotal, showCost }: HoursWorkedColumnProps) {
  const navigationStore = useSelector((state: IRootState) => state.navigationStore);

  const { sideMenuCollapsed } = navigationStore;

  const width = sideMenuCollapsed
    ? `calc((100vw - ${CONTENT_PANEL_WIDTH}px) / 7)`
    : `calc((100vw - ${CONTENT_PANEL_WIDTH + SIDEBAR_WIDTH}px) / 7)`;

  const height = `${GROUP_ROW_HEIGHT}px`;

  return (
    <div className="bg-tertiary pv-small ph-small flex-column bordered-right bordered-bottom" style={{ width, height }}>
      {showCost && (
        <div className="overflow-hidden text-overflow-ellipsis whitespace-nowrap line-height-120">
          <Text color="secondary" size="small">
            COST
          </Text>
          <br />
          <Text size="regular">
            {CommonUtils.formatPrice(costTotal)} • {hoursTotal} hours
          </Text>
        </div>
      )}
    </div>
  );
}
