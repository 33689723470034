import React, { Component } from 'react';
import ActionModal from 'common-components/modal/ActionModal';
import _ from 'lodash';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Checkbox, Col, notification, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import moment from 'moment-timezone';
import { StatusTag } from 'common-components/tags';
import InfoPanel from 'common-components/alerts/InfoPanel';

interface IRemoveCustomerFromScheduleModalProps {
  isOpen: boolean;
  onClose: () => void;
  customerUserId: string;
  selectedSession: any;
  removeModalType: string;
  doRemoveCustomerFromSchedule: typeof dispatch.groupServiceStore.doRemoveCustomerFromSchedule;
  doGetCustomerSessionToBeRemoveList: typeof dispatch.groupServiceStore.doGetCustomerSessionToBeRemoveList;
  customerSessionToBeRemoveList: typeof state.groupServiceStore.customerSessionToBeRemoveList;
}

interface IRemoveCustomerFromScheduleModalState {
  isLoading: boolean;
  isProcessing: boolean;
  step: number;
  selectedSessions: Array<string>;
}

class RemoveCustomerFromScheduleModal extends Component<
  IRemoveCustomerFromScheduleModalProps,
  IRemoveCustomerFromScheduleModalState
> {
  state = {
    isLoading: false,
    isProcessing: false,
    step: 1,
    selectedSessions: null,
  };

  private _loadContent = async () => {
    const { customerUserId, selectedSession, customerSessionToBeRemoveList } = this.props;
    this.setState({ isLoading: true });
    await this.props.doGetCustomerSessionToBeRemoveList({
      customerUserId,
      serviceScheduleId: selectedSession.serviceScheduleId,
      serviceId: selectedSession.serviceId,
      removeAllNotStarted: true,
    });
    this.setState({
      isLoading: false,
    });
  };

  private _onCloseModal = () => {
    this.props.onClose();
    this.setState({ step: 1, isLoading: false });
  };

  private _removeFromCurrentSession = async () => {
    const { customerUserId, selectedSession, doRemoveCustomerFromSchedule } = this.props;
    this.setState({ isProcessing: true });
    try {
      const payload = {
        customerUserId,
        serviceScheduleId: selectedSession.serviceScheduleId,
        serviceId: selectedSession.serviceId,
        attendanceIds: [selectedSession.attendanceId],
      };
      await doRemoveCustomerFromSchedule(payload);
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isProcessing: false });
    this._onCloseModal();
  };

  private _removeFromSelectedSession = async () => {
    const { customerUserId, selectedSession, doRemoveCustomerFromSchedule } = this.props;
    const { selectedSessions } = this.state;
    this.setState({ isProcessing: true });
    try {
      const payload = {
        customerUserId,
        serviceScheduleId: selectedSession.serviceScheduleId,
        serviceId: selectedSession.serviceId,
        attendanceIds: selectedSessions,
      };
      await doRemoveCustomerFromSchedule(payload);
      notification.success({ message: 'Customer successfully removed from selected sessions.' });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isProcessing: false });
    this._onCloseModal();
  };

  private _handleSessionSelection = (attendanceId) => {
    let newSelectedSessions = this.state.selectedSessions;
    if (!!_.find(newSelectedSessions, (selectedAttendanceId) => selectedAttendanceId === attendanceId)) {
      newSelectedSessions = _.filter(
        newSelectedSessions,
        (selectedAttendanceId) => selectedAttendanceId !== attendanceId,
      );
    } else {
      newSelectedSessions.push(attendanceId);
    }
    this.setState({ selectedSessions: newSelectedSessions });
  };

  private _handleSelectAll = () => {
    const { selectedSessions } = this.state;
    const { customerSessionToBeRemoveList } = this.props;
    this.setState({
      selectedSessions:
        selectedSessions && selectedSessions.length < customerSessionToBeRemoveList.length
          ? _.map(this.props.customerSessionToBeRemoveList, (session) => session.attendanceId)
          : [],
    });
  };

  componentDidMount = async () => {
    if (this.props.removeModalType === 'schedule') {
      await this._loadContent();
    }
  };

  componentDidUpdate = async (prevProps) => {
    const { customerSessionToBeRemoveList, removeModalType } = this.props;
    if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen && removeModalType === 'schedule') {
      await this._loadContent();
    }
    if (customerSessionToBeRemoveList !== prevProps.customerSessionToBeRemoveList && removeModalType === 'schedule') {
      this.setState({ selectedSessions: _.map(customerSessionToBeRemoveList, (session) => session.attendanceId) });
    }
  };

  render() {
    const { removeModalType, customerUserId, selectedSession, customerSessionToBeRemoveList } = this.props;
    const { step, isLoading, isProcessing, selectedSessions } = this.state;
    const title =
      removeModalType === 'individual' ? 'Remove customer from this session' : 'Remove customer from this schedule';

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        onClose={this._onCloseModal}
        width={'large'}
        className="p-small"
        title={title}
      >
        {isLoading ? (
          <SpinningLoader size={150} message={'Fetching data...'} />
        ) : (
          <>
            {step === 1 && removeModalType === 'individual' && (
              <div className="flex-column anim-slide-left">
                <Paragraph>You’re about to remove the customer from this session.</Paragraph>
                <Paragraph>Are you sure?</Paragraph>

                <Row type="flex" justify="end" className="mt-x2-large">
                  <SecondaryButton size="large" onClick={this._onCloseModal} className="mr-large" disabled={isLoading}>
                    Cancel
                  </SecondaryButton>

                  <PrimaryButton size="large" loading={isProcessing} onClick={this._removeFromCurrentSession}>
                    Remove
                  </PrimaryButton>
                </Row>
              </div>
            )}

            {step === 1 &&
              removeModalType === 'schedule' &&
              customerSessionToBeRemoveList &&
              customerSessionToBeRemoveList.length > 0 && (
                <div className="flex-column anim-slide-left">
                  <Paragraph>
                    Remove the customer from selected sessions or the entire schedule by selecting all.
                    <br />
                    <Text weight={'bold'}>Please be aware, this can’t be undone.</Text>
                  </Paragraph>

                  {selectedSession?.scheduleName && (
                    <div className={'mb-medium'}>
                      <Text size={'x2-large'}>{selectedSession.scheduleName}</Text>
                    </div>
                  )}

                  <div>
                    <Row className={'mv-small pv-small'} type={'flex'} align={'middle'}>
                      <Col span={2} className={'text-align-center'}>
                        <Checkbox
                          checked={selectedSessions && selectedSessions.length === customerSessionToBeRemoveList.length}
                          indeterminate={
                            selectedSessions &&
                            selectedSessions.length > 0 &&
                            selectedSessions &&
                            selectedSessions.length < customerSessionToBeRemoveList.length
                          }
                          onClick={this._handleSelectAll}
                        />
                      </Col>
                      <Col span={8}>
                        <SubTitle containerClassName={'m-none'}>Session times</SubTitle>
                      </Col>
                      <Col span={6}>
                        <SubTitle>Booking's status</SubTitle>
                      </Col>
                      <Col span={8} className={'text-align-right'}>
                        <Text color={'secondary'}>
                          <b>{selectedSessions ? selectedSessions.length : 0}</b>/{customerSessionToBeRemoveList.length}{' '}
                          selected sessions
                        </Text>
                      </Col>
                    </Row>
                    <div className={'bordered-top'} style={{ maxHeight: 'calc(50vh)', overflow: 'auto' }}>
                      {_.map(customerSessionToBeRemoveList, (session) => {
                        const isClickedSession = selectedSession?.attendanceId === session.attendanceId;
                        return (
                          <Row
                            className={`pv-small ${
                              isClickedSession && 'bordered border-width-medium'
                            } evenodd cursor-pointer`}
                            type={'flex'}
                            align={'middle'}
                            onClick={() => this._handleSessionSelection(session.attendanceId)}
                          >
                            <Col span={2} className={'text-align-center'}>
                              <Checkbox
                                checked={
                                  !!_.find(
                                    selectedSessions,
                                    (selectedSession) => selectedSession === session.attendanceId,
                                  )
                                }
                              />
                            </Col>
                            <Col span={8}>
                              <Text>
                                {moment.tz(session.startDateTime, session.timezone).format('ddd, D MMM YYYY')}
                              </Text>
                              <br />
                              <Text type="secondary">
                                {moment.tz(session.startDateTime, session.timezone).format('HH:mm A')} -{' '}
                                {moment.tz(session.endDateTime, session.timezone).format('HH:mm A')}
                              </Text>
                            </Col>
                            <Col span={6}>
                              <StatusTag status={session.status} size="small" />
                            </Col>
                            <Col span={8} />
                          </Row>
                        );
                      })}
                    </div>
                  </div>

                  <Row type="flex" justify="end" className="mt-x2-large">
                    <SecondaryButton
                      size="large"
                      onClick={this._onCloseModal}
                      className="mr-large"
                      disabled={isLoading}
                    >
                      Cancel
                    </SecondaryButton>

                    <PrimaryButton
                      size="large"
                      disabled={!selectedSessions || selectedSessions.length === 0}
                      loading={isProcessing}
                      onClick={this._removeFromSelectedSession}
                    >
                      Remove selected
                    </PrimaryButton>
                  </Row>
                </div>
              )}

            {step === 1 &&
              removeModalType === 'schedule' &&
              (!customerSessionToBeRemoveList || customerSessionToBeRemoveList.length === 0) && (
                <div className="flex-column anim-slide-left">
                  <InfoPanel
                    text={
                      "No removable booking found. Only bookings that hasn't been started, cancelled or completed can be removed."
                    }
                  />
                  <Row type="flex" justify="end" className="mt-x2-large">
                    <PrimaryButton size="large" onClick={this._onCloseModal} disabled={isLoading}>
                      Close
                    </PrimaryButton>
                  </Row>
                </div>
              )}
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  workerListLite: state.teamStore.workerListLite,
  customerSessionToBeRemoveList: state.groupServiceStore.customerSessionToBeRemoveList,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchWorkerListLite: dispatch.teamStore.doFetchWorkerListLite,
  doAddTeamMemberToRoster: dispatch.servicesStore.doAddTeamMemberToRoster,
  doRemoveCustomerFromSchedule: dispatch.groupServiceStore.doRemoveCustomerFromSchedule,
  doGetCustomerSessionToBeRemoveList: dispatch.groupServiceStore.doGetCustomerSessionToBeRemoveList,
});

export default connect(mapState, mapDispatch)(RemoveCustomerFromScheduleModal);
