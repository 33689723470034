import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Lock = forwardRef(function Lock(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.948 1.25h.104c.899 0 1.648 0 2.242.08.628.084 1.195.27 1.65.725.456.456.642 1.023.726 1.65.08.595.08 1.345.08 2.243v1.438c.719.104 1.338.296 1.889.665.519.346.964.791 1.31 1.31.434.648.623 1.39.713 2.277.088.866.088 1.951.088 3.32v.084c0 1.369 0 2.454-.088 3.32-.09.888-.28 1.629-.712 2.277a4.75 4.75 0 0 1-1.311 1.31c-.648.434-1.39.623-2.277.713-.866.088-1.951.088-3.32.088H10.959c-1.37 0-2.455 0-3.321-.088-.888-.09-1.629-.28-2.277-.712a4.751 4.751 0 0 1-1.31-1.311c-.434-.648-.623-1.39-.713-2.277-.088-.866-.088-1.951-.088-3.32v-.084c0-1.369 0-2.454.088-3.32.09-.888.28-1.629.713-2.277a4.75 4.75 0 0 1 1.31-1.31c.552-.369 1.17-.56 1.89-.665V5.948C7.25 5.05 7.25 4.3 7.33 3.706c.084-.628.27-1.195.726-1.65.455-.456 1.022-.642 1.65-.726.594-.08 1.344-.08 2.242-.08ZM15.25 6v1.27c-.64-.02-1.37-.02-2.208-.02H10.959c-.838 0-1.57 0-2.209.02V6c0-.964.002-1.612.067-2.095.062-.461.169-.659.3-.789.13-.13.327-.237.788-.3.484-.064 1.131-.066 2.095-.066.964 0 1.612.002 2.095.067.461.062.659.169.789.3.13.13.237.327.3.788.065.483.066 1.131.066 2.095ZM7.79 8.83c-.77.079-1.235.227-1.596.468a3.25 3.25 0 0 0-.896.896c-.241.361-.39.827-.468 1.596-.079.781-.08 1.79-.08 3.21s.001 2.429.08 3.21c.079.77.227 1.235.468 1.596.237.354.542.66.896.896.361.241.827.39 1.596.468.781.08 1.79.08 3.21.08h2c1.42 0 2.429 0 3.21-.08.77-.079 1.235-.227 1.596-.468a3.25 3.25 0 0 0 .896-.896c.241-.361.39-.827.468-1.596.08-.781.08-1.79.08-3.21s0-2.429-.08-3.21c-.079-.77-.227-1.235-.468-1.596a3.25 3.25 0 0 0-.896-.896c-.361-.241-.827-.39-1.596-.468-.781-.08-1.79-.08-3.21-.08h-2c-1.42 0-2.429 0-3.21.08ZM12.75 14a.75.75 0 0 0-1.5 0v2a.75.75 0 1 0 1.5 0v-2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
