import React from 'react';
import { Group, Button, rem, Box } from '@mantine/core';
import { ChevronDown, ChevronUp } from '@good/icons';
/**
 * The button attached to the filter component.
 */
export const FilterButton = React.forwardRef(({ label, selectedLabel, opened, ...buttonProps }, ref) => {
    return (<Button ref={ref} size='md' rightSection={<Box ml={-6}>{opened ? <ChevronUp aria-label='opened'/> : <ChevronDown aria-label='closed'/>}</Box>} {...buttonProps} style={{ ...buttonProps.style, borderWidth: rem(1.5) }} aria-label={`filter by ${label}`}>
        <Group gap={6}>
          <span style={{ fontWeight: 600 }}>{label}</span>
          {selectedLabel && <span style={{ opacity: 0.32 }}>|</span>}
          {selectedLabel && <span style={{ fontWeight: 500 }}>{selectedLabel}</span>}
        </Group>
      </Button>);
});
