import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Dashboard = forwardRef(function Dashboard(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 5V3.784a8.213 8.213 0 0 1 4.53 1.876l-.81.81a.75.75 0 1 0 1.06 1.06l.81-.81a8.214 8.214 0 0 1 1.876 4.53H19a.75.75 0 0 0 0 1.5h1.216a8.214 8.214 0 0 1-1.876 4.53l-.81-.81a.75.75 0 1 0-1.06 1.06l.81.81A8.217 8.217 0 0 1 12 20.25a8.217 8.217 0 0 1-5.28-1.91l.81-.81a.75.75 0 1 0-1.06-1.06l-.81.81a8.213 8.213 0 0 1-1.876-4.53H5a.75.75 0 0 0 0-1.5H3.784A8.213 8.213 0 0 1 5.66 6.72l.81.81a.75.75 0 0 0 1.06-1.06l-.81-.81a8.213 8.213 0 0 1 4.53-1.876V5a.75.75 0 0 0 1.5 0ZM12 2.25c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25ZM12 9l.446-.603a.75.75 0 0 0-.893 0L12 9Zm.413 1.466a5.605 5.605 0 0 0-.413-.463c-.128.129-.27.284-.413.463-.45.566-.837 1.293-.837 2.09 0 .658.199 1.078.427 1.325.23.25.529.369.823.369.294 0 .593-.119.823-.369.228-.248.427-.667.427-1.325 0-.797-.388-1.524-.837-2.09Zm2.337 2.09c0-1.278-.612-2.33-1.163-3.022a7.14 7.14 0 0 0-1.105-1.11l-.024-.018-.007-.005-.003-.002v-.001h-.001L12 9l-.447-.602h-.002l-.002.003-.008.005-.023.018-.078.062a7.15 7.15 0 0 0-1.028 1.047c-.55.694-1.162 1.745-1.162 3.023 0 .971.3 1.774.823 2.34.52.565 1.221.854 1.927.854s1.407-.289 1.927-.853c.522-.568.823-1.37.823-2.341Z"
        fill="currentColor"
      />
    </Icon>
  );
});
