import React from 'react';
import { Text } from '@good/components';
import { Avatar } from 'antd';
import { CustomerNDISBadge } from './customer-detail-ndis-badge';
import { useCustomer } from 'stores/hooks/query-hooks/use-query-fetch-customer';
import { Skeleton } from 'common-components/skeleton';

type Props = {
  customerUserId: string;
  className?: string;
};

export const CustomerViewHeader = ({ customerUserId, ...props }: Props): JSX.Element => {
  const { data: customer } = useCustomer({ customerUserId });

  const primaryAddress = customer?.addresses.find((address) => address.isPrimary);

  return (
    <div className="flex flex-row gap-14 p-8" {...props}>
      {customer?.attachmentUrl ? (
        <Avatar icon="user" shape="circle" size={124} src={customer.attachmentUrl} />
      ) : (
        <Skeleton className="h-32 w-32 rounded-full" />
      )}
      <div className="flex flex-col space-y-2 py-1">
        {customer?.displayName ? (
          <Text size="xl" className="font-bold">
            {customer.displayName}
          </Text>
        ) : (
          <Skeleton className="h-6 w-64" />
        )}

        {primaryAddress && (
          <Text size="sm" className="text-color-secondary">
            {primaryAddress.fullAddress}
          </Text>
        )}

        {customer?.funding.NDISNumber ? (
          <CustomerNDISBadge>{customer.funding.NDISNumber}</CustomerNDISBadge>
        ) : (
          <Skeleton className="h-6 w-32" />
        )}
      </div>
    </div>
  );
};
