import React, { Suspense } from 'react';

import { APP_CONFIG, isProduction } from '../app-config';

const { PRODUCTION_ENV: env } = APP_CONFIG.meta;

let Helmet;
if (!isProduction(env)) Helmet = React.lazy(() => import('./helmet'));

export function Favicon() {
  if (isProduction(env)) return null;

  return (
    <Suspense fallback={null}>
      <Helmet>
        <link rel="icon" href={`/${env}-favicon.png`} />
      </Helmet>
    </Suspense>
  );
}
