import React, { HTMLAttributes, PropsWithChildren, useRef } from 'react';
import clsx from 'clsx';
import { AriaButtonProps } from '@react-types/button';
import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';

type BadgeProps = PropsWithChildren<AriaButtonProps & HTMLAttributes<HTMLDivElement>>;

export function Badge(props: BadgeProps) {
  let { children, className, ...otherProps } = props;

  let ref = useRef(null);
  let { buttonProps } = useButton(otherProps, ref);
  let { isFocusVisible, focusProps } = useFocusRing();

  return (
    <div
      className={clsx('bg-light-gray-light', 'rounded', className)}
      style={{
        alignItems: 'stretch',
        display: 'inline-flex',
        flexDirection: 'row',
        fontSize: '12px',
        gap: '4px',
        padding: '4px',
        width: 'fit-content',
        outline: 'none',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        wordBreak: 'break-all',
        overflow: 'hidden',
        ...(isFocusVisible && { outline: '2px black solid', outlineOffset: '0px' }),
        maxWidth: '192px',
      }}
      {...(props.onPress ? { ...buttonProps, ...focusProps, tabIndex: 0 } : {})}
    >
      {children}
    </div>
  );
}
