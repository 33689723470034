import { Card, Title, Anchor, Group, Button, Stack } from '@good/ui/core';
import { useDisclosure } from '@good/ui/hooks';
import { Pen } from 'design-components/icon';
import React from 'react';
import { BillingEmailModal } from './BillingEmailModal';

type Props = {
  serviceProviderId: string;
  billingEmail: string;
};

const BillingEmailCard = ({ serviceProviderId, billingEmail }: Props) => {
  const [opened, { open, close }] = useDisclosure();

  return (
    <Card p='xl' withBorder style={{ minHeight: 128 }}>
      <Group justify='space-between' align='start'>
        <Stack>
          <Title order={3}>Billing email</Title>
          <Anchor href={`mailto:${billingEmail}`}>{billingEmail}</Anchor>
        </Stack>

        <Button variant='outline' leftSection={<Pen />} onClick={open}>
          Update
        </Button>
      </Group>

      <BillingEmailModal
        serviceProviderId={serviceProviderId}
        billingEmail={billingEmail}
        opened={opened}
        close={close}
      />
    </Card>
  );
};

export default BillingEmailCard;
