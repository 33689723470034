import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { BookingSVG, ConfirmedSVG, ErrorSVG } from 'assets/UndrawSVG';
import { Paragraph } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Spinner } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState, state, dispatch } from 'stores/rematch/root-store';
import { FormComponentProps } from 'antd/es/form';
import { Form, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';

interface IBookingWaiveWholeBookingActionModelProps extends FormComponentProps {
  selectedBooking: typeof state.bookingsStore.selectedBookingItem;
  doWaiveWholeBooking: typeof dispatch.bookingsStore.doWaiveWholeBooking;
  isOpen: boolean;
  onClose: () => void;
}

interface IBookingWaiveWholeBookingActionModelState {
  canManuallyClose: boolean;
  isLoading: boolean;
  title: string;
}

class BookingWaiveWholeBookingActionModel extends Component<
  IBookingWaiveWholeBookingActionModelProps,
  IBookingWaiveWholeBookingActionModelState
> {
  state = { canManuallyClose: true, isLoading: false, title: 'Waive booking' };

  private _onSubmit = async () => {
    const { doWaiveWholeBooking, selectedBooking, form } = this.props;
    this.setState({ isLoading: true, canManuallyClose: false });
    try {
      const waivedReason = form.getFieldValue('waivedReason');
      await doWaiveWholeBooking({ bookingId: selectedBooking.bookingId, waivedReason });
      this.setState({ isLoading: false, canManuallyClose: true }, this._onCloseModal);
    } catch (e) {
      this.setState({ isLoading: false, canManuallyClose: true });
      notification.error({ message: 'Error', description: 'Oops, something went wrong, please try again later.' });
    }
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    // Reset back to step 1
    this.setState({ title: 'Waive booking' });
    onClose();
  };

  render() {
    let { isOpen, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width="medium"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div className="anim-fade-in">
          <div className="mt-medium">
            <Paragraph>Are you sure you want to waive this booking?</Paragraph>
          </div>
          <div className="mt-large">
            <Paragraph>
              Waiving the booking will move all line items attached to this booking to the ‘Waived’ section of payments.
              These line items can be un-waived and processed if you wish to in the future
            </Paragraph>
          </div>
          <div className="mt-large mb-large">
            <Paragraph>Please provide a reason for waiving this booking (Optional)</Paragraph>
            <Form.Item className="mv-none">
              {getFieldDecorator('waivedReason', { initialValue: '' })(
                <TextArea placeholder={'Waive reason(s)'} autoSize={{ maxRows: 4, minRows: 4 }} />,
              )}
            </Form.Item>
          </div>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this._onCloseModal}>
              Not now
            </GhostButton>
            <PrimaryButton size="large" onClick={this._onSubmit}>
              Waive
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doWaiveWholeBooking: dispatch.bookingsStore.doWaiveWholeBooking,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IBookingWaiveWholeBookingActionModelProps>()(BookingWaiveWholeBookingActionModel));
