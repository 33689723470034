import React from 'react';
import { Select } from 'antd';
import { useFormContext } from 'react-hook-form';

import { Field, Forms, Stack } from '../ui-components';

/**
 * @name
 * WorkflowNameForm
 *
 * @description
 * Edit a workflow's `name` or `description`. Must be wrapped in a
 * `<FormProvider>`.
 *
 * @example
 * let formMethods = useForm({ defaultValues: { name: 'Hello, GoodHuman!' }})
 *
 * return (
 *   <FormProvider {...formMethods}>
 *     <WorkflowNameForm />
 *   </FormProvider>
 * )
 */
export function WorkflowNameForm() {
  let { control, register } = useFormContext();

  return (
    <Stack gap="xl">
      <Field width="288px">
        <Forms.SelectField control={control} label="Workflow for" {...register('triggerType')} disabled>
          <Select.Option value="INCIDENT_NOTE">Incident reported</Select.Option>
        </Forms.SelectField>
      </Field>

      <Field width="288px">
        <Forms.TextField
          type="text"
          description="E.g. Team member assault, Customer illness, 
          Near miss (accident)."
          label="Name"
          control={control}
          {...register('name', {
            required: 'Name is required.',
            minLength: {
              value: 2,
              message: 'Name is too short.',
            },
            maxLength: {
              value: 50,
              message: 'Name is too long.',
            },
          })}
        />
      </Field>

      <Field width="400px">
        <Forms.TextField
          type="text"
          multiline
          rows={2}
          label="Description (optional)"
          control={control}
          {...register('description', {
            maxLength: {
              value: 2000,
              message: 'Description is too long.',
            },
          })}
        />
      </Field>
    </Stack>
  );
}
