import React, { Component } from 'react';
import { Col, Form, Row } from 'antd';
import { Text } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

import FullScreenModal, { FullScreenModalFooter } from 'common-components/modal/FullScreenModal';
import Steps from 'common-components/steps/Steps';
// Panels
import Step1SelectCustomerPanel from './wizard-steps/Step1SelectCustomerPanel';
import Step2ServiceDetailsPanel from './wizard-steps/Step2ServiceDetailsPanel';
import Step3BookingLocationPanel from './wizard-steps/Step3BookingLocationPanel';
import Step4BookingInstructionsPanel from './wizard-steps/Step4BookingInstructionsPanel';
import Step5PaymentDetailPanel from './wizard-steps/Step5PaymentDetailPanel';
import Step6SummaryPanel from './wizard-steps/Step6SummaryPanel';
import Step7ConfirmationPanel from './wizard-steps/Step7ConfirmationPanel';
import { INewBookingData } from 'interfaces/booking-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';

interface ICreateNewBookingModalProps extends FormComponentProps {
  closeCreateBookingModal: () => void;
  newBookingData: INewBookingData | any;
  doSetNewBookingData?: typeof dispatch.bookingsStore.doSetNewBookingData;
  setBookSelectedService: typeof dispatch.servicesStore.setBookSelectedService;
  bookingProcessStatus: boolean;
  isOpen: boolean;
  history: any;

  // Added by Jir : Ability to pre-select a customer for the create booking modal
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  doGetCustomer?: typeof dispatch.customersStore.doGetCustomer;
  incomingCustomer?: any;
  incomingStartDateTime?: Date;
}

interface ICreateNewBookingModalState {
  step: number;
  totalStep: number;
}

class CreateNewBookingModal extends Component<ICreateNewBookingModalProps, ICreateNewBookingModalState> {
  state = {
    step: 1,
    totalStep: 7,
  };

  private _onNextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  private _onPreviousStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  private _closeCreateBookingModal = async () => {
    this.setState({ step: 1 });
    this.props.doSetNewBookingData({
      bookCustomNote: '',
      bookStartDate: null,
      bookStartTime: null,
      bookEndDate: null,
      bookEndTime: null,
      bookLocation: null,
      hasMileageClaims: false,
      isCancelledBooking: false,
      cancellationReason: '',
      cancellationCode: 'NSDT',
      isFutureBooking: true,
      mileageTravelled: 0,
      selectedCustomer: null,
      selectedCustomerId: null,
      selectedService: null,
      selectedServiceId: null,
      selectedWorker: null,
      selectedWorkerId: null,
      serviceDateTimeId: null,
    });
    this.props.setBookSelectedService({});
    this.props.closeCreateBookingModal();
  };

  private _renderStepContent = () => {
    const { step } = this.state;
    if (step === 1) {
      // Select Customer
      return <Step1SelectCustomerPanel onNextStep={this._onNextStep} />;
    } else if (step === 2) {
      // Select Date/Service
      return <Step2ServiceDetailsPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />;
    } else if (step === 3) {
      // Select Additional Info/Location
      return (
        <Step3BookingLocationPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          history={this.props.history}
        />
      );
    } else if (step === 4) {
      // Enter Instructions
      return (
        <Step4BookingInstructionsPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          history={this.props.history}
        />
      );
    } else if (step === 5) {
      // Summary
      return <Step5PaymentDetailPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />;
    } else if (step === 6) {
      // Summary
      return <Step6SummaryPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />;
    } else if (step === 7) {
      // Success
      return (
        <Step7ConfirmationPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          onCloseModal={this._closeCreateBookingModal}
          history={this.props.history}
        />
      );
    }
  };

  async componentDidUpdate(
    prevProps: Readonly<ICreateNewBookingModalProps>,
    prevState: Readonly<ICreateNewBookingModalState>,
    snapshot?: any,
  ) {
    const { isOpen, incomingCustomer, doGetCustomer, newBookingData, doSetNewBookingData, incomingStartDateTime } =
      this.props;

    if (this.props.isOpen !== prevProps.isOpen) {
      // If there's an incoming customer, select that customer.
      if (isOpen && !_.isEmpty(incomingCustomer)) {
        const { userId: customerId } = incomingCustomer;
        // If there's an incoming date, use that, else default to today.
        const startDateTime = incomingStartDateTime || new Date();
        const endDateTime = moment(startDateTime).add(1, 'hour').toDate();

        this.setState({ step: 2 });

        doSetNewBookingData({
          ...newBookingData,
          selectedCustomerId: customerId,
          selectedCustomer: incomingCustomer,
          bookStartDate: startDateTime,
          bookEndDate: endDateTime,
          bookStartTime: startDateTime,
          bookEndTime: endDateTime,
        });
      }
    }
  }

  render() {
    let bottomText = 'Customer';

    if (this.state.step === 2) {
      bottomText = 'Booking Time/Service';
    } else if (this.state.step === 3) {
      bottomText = 'Location';
    } else if (this.state.step === 4) {
      bottomText = 'Team member details';
    } else if (this.state.step === 5) {
      bottomText = 'Booking summary';
    } else if (this.state.step === 6) {
      bottomText = 'Done!';
    }

    let steps = [];
    for (let i = 1; i <= this.state.totalStep; i++) {
      let stepObj = {};
      stepObj['stepNumber'] = i;
      stepObj['stepLabel'] = i;
      steps.push(stepObj);
    }

    return (
      <FullScreenModal
        isOpen={this.props.isOpen}
        onClose={this._closeCreateBookingModal}
        width={'large'}
        canClose={this.props.bookingProcessStatus}
        footerContent={
          <FullScreenModalFooter align={'left'}>
            <Row gutter={0} type='flex' align='middle'>
              <Col span={8} />
              <Col span={8}>
                <div className='justify-center flex-row'>
                  <Text size='regular' className='mb-medium'>
                    <span className='mr-medium text-weight-bold'>
                      Step {this.state.step} of {this.state.totalStep}
                    </span>
                    <span className=''>{bottomText}</span>
                  </Text>
                </div>
                <Steps currentStep={this.state.step} steps={steps} />
              </Col>
              <Col span={8}>
                <div className='text-align-right'>
                  <div className='text-align-right'>
                    <Text>
                      <span className='text-weight-bold'>Hint</span> - You can exit this screen by pressing{' '}
                      <code>Esc</code>
                    </Text>
                  </div>
                </div>
              </Col>
            </Row>
          </FullScreenModalFooter>
        }
      >
        {this._renderStepContent()}
      </FullScreenModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  bookingProcessStatus: state.bookingsStore.bookingProcessStatus,
  newBookingData: state.bookingsStore.newBookingData,
  selectedCustomer: state.customersStore.selectedCustomer,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doSetNewBookingData: dispatch.bookingsStore.doSetNewBookingData,
  doGetCustomer: dispatch.customersStore.doGetCustomer,
  setBookSelectedService: dispatch.servicesStore.setBookSelectedService,
});

export default connect(mapState, mapDispatch)(Form.create<ICreateNewBookingModalProps>()(CreateNewBookingModal));
