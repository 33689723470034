import apiClient, { pickData } from '../utilities/api-client';

/**
 * @name sendToZendeskPortal
 *
 * @description
 * Request a valid JWT from our API, then send the logged in user to
 * the Zendesk help center.
 */
export async function sendToZendeskPortal(redirectUrl?: string): Promise<void> {
  apiClient
    .get(`/api/portal/zendesk/support?redirect=${redirectUrl}`)
    .then(pickData)
    .then(({ redirectUrl }) => window.open(`/redirect?to=zendesk&url=${redirectUrl}`, '_blank'));
}
