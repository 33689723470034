import React from 'react';
import clsx from 'clsx';
import { Col, Row, Tag } from 'antd';
import { Email, Call } from '@good/icons';
import { Heading, Inline, Stack, Text, css } from '@goodhuman-me/components';
import { Popover } from '@blueprintjs/core';
import { WomanWithDog } from '@good/illustrations';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import CreateEditContactModal from '../CreateEditContactModal';
import DeleteContactModal from '../DeleteContactModal';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { CollapsibleText } from '../collapsible-text/collapsible-text';
import { FieldLabel } from 'common-components/typography';
import { ICustomer } from 'interfaces/customer-interfaces';
import { IconButton, PrimaryButton } from 'common-components/buttons';

import type { CountryCode } from 'libphonenumber-js';

const modalActions = {
  UPSERT: 'UPSERT',
  DELETE: 'DELETE',
} as const;

type ModalActions = keyof typeof modalActions;

type CustomerDetailsContactsPanelProps = {
  hasEditPermission?: boolean;
  selectedCustomer: ICustomer;
};

export function CustomerDetailsContactsPanel(props: CustomerDetailsContactsPanelProps) {
  const { selectedCustomer, hasEditPermission: canEdit = false } = props;
  const { emergencyContacts: contacts } = selectedCustomer;
  const hasContacts = contacts?.length;

  const [modalOptions, setModalOptions] = React.useState<{
    isOpen: boolean;
    contactId?: string;
    type: ModalActions;
  }>({
    isOpen: false,
    contactId: undefined,
    type: undefined,
  });

  function handleOnToggle(options?: Omit<typeof modalOptions, 'isOpen'>) {
    setModalOptions((prev) => {
      return {
        ...(options ?? prev),
        isOpen: !prev.isOpen,
      };
    });
  }

  const handleOnCreate = () => handleOnToggle({ type: modalActions.UPSERT });
  const handleOnDelete = (contactId: string) => handleOnToggle({ type: modalActions.DELETE, contactId });
  const handleOnEdit = (contactId: string) => handleOnToggle({ type: modalActions.UPSERT, contactId });
  const isUpserting = modalOptions.isOpen && modalOptions.type === modalActions.UPSERT;
  const isDeleting = modalOptions.isOpen && modalOptions.type === modalActions.DELETE;

  return (
    <>
      <Stack gap="$space400">
        <Header>
          {canEdit && (
            <Stack alignItems="center">
              <PrimaryButton icon="user" onClick={handleOnCreate}>
                Add contact
              </PrimaryButton>
            </Stack>
          )}
        </Header>

        {hasContacts ? (
          <List contacts={contacts} {...(canEdit && { onDelete: handleOnDelete, onEdit: handleOnEdit })} />
        ) : (
          <Empty />
        )}
      </Stack>

      {isUpserting && (
        <CreateEditContactModal
          closeCreateEditContactModal={handleOnToggle}
          isOpen={isUpserting}
          selectedContactId={modalOptions.contactId}
          selectedCustomer={selectedCustomer}
        />
      )}

      {isDeleting && (
        <DeleteContactModal
          customerUserId={selectedCustomer.userId}
          isOpen={isDeleting}
          onClose={handleOnToggle}
          userContactId={modalOptions.contactId}
        />
      )}
    </>
  );
}

function Header(props: { children?: React.ReactNode }) {
  const { children } = props;

  return (
    <Inline justifyContent="space-between" alignItems="center">
      <Stack gap="$space100">
        <Heading size="large">Contacts</Heading>
        <Text color="$muted">Primary and other contacts of the customer.</Text>
      </Stack>

      {children}
    </Inline>
  );
}

function Empty() {
  return (
    <Stack alignItems="center">
      <Stack gap="$space200" alignItems="center">
        <WomanWithDog />
        <Heading size="small">No contacts found</Heading>
      </Stack>
    </Stack>
  );
}

type Contact = {
  email?: string;
  fullName: string;
  isPrimaryCarer: boolean;
  note?: string;
  phoneNumber?: string;
  phoneNumberCountryCode?: CountryCode;
  relationToUser?: string;
  userContactId: string;
};

function List(props: { contacts: Contact[]; onDelete: (userId: string) => void; onEdit: (options: string) => void }) {
  const { contacts, onDelete, onEdit } = props;

  return (
    <>
      {contacts.map((contact: Contact, i) => {
        const {
          email,
          fullName,
          isPrimaryCarer,
          note,
          phoneNumber,
          phoneNumberCountryCode,
          relationToUser: relationship,
          userContactId: id,
        } = contact;

        return (
          <Row
            key={id}
            className={clsx([i === 0 && 'bordered-top', 'bordered-bottom flex pv-medium ph-large'])}
            gutter={24}
            style={{ alignItems: 'flex-start' }}
          >
            <ContactName fullName={fullName} isPrimaryCarer={isPrimaryCarer} />
            <ContactDetails email={email} phoneNumber={phoneNumber} phoneNumberCountryCode={phoneNumberCountryCode} />
            <ContactRelationship relationship={relationship} />
            <ContactNote note={note} />
            {onEdit && onDelete && <ContactActions onDelete={() => onDelete(id)} onEdit={() => onEdit(id)} />}
          </Row>
        );
      })}
    </>
  );
}

const getTextColor = (value?: string) => (value ? '$default' : '$muted');

function ContactName(props: { fullName: string; isPrimaryCarer?: boolean }) {
  const { fullName, isPrimaryCarer } = props;

  return (
    <Col span={5}>
      <Stack gap="$space50" alignItems="baseline">
        <FieldLabel text="Name" />
        <Text size="small" asChild>
          <span style={{ wordBreak: 'break-all' }}>{fullName}</span>
        </Text>
        {isPrimaryCarer ? <Tag>Primary contact</Tag> : null}
      </Stack>
    </Col>
  );
}

const iconContainerStyles = css({
  alignItems: 'center',
  display: 'flex',
  height: '22.78px',
  justifyItems: 'center',
  minHeight: '1.25em',
  minWidth: '1.25em',
});

function ContactDetails(props: Pick<Contact, 'email' | 'phoneNumber' | 'phoneNumberCountryCode'>) {
  const { phoneNumber, phoneNumberCountryCode, email } = props;

  const formattedEmail = email ?? 'Not set';
  const formattedPhoneNumber = phoneNumber
    ? parsePhoneNumberFromString(phoneNumber, phoneNumberCountryCode).format('INTERNATIONAL')
    : 'Not set';

  return (
    <Col span={7}>
      <Stack gap="$space50">
        <FieldLabel text="Details" />
        <Inline alignItems="text-start" gap="$space50">
          <div className={iconContainerStyles()}>
            <Call className="text-size-regular" />
          </div>
          <Text color={getTextColor(phoneNumber)} size="small">
            <span style={{ wordBreak: 'break-all' }}>{formattedPhoneNumber}</span>
          </Text>
        </Inline>

        <Inline alignItems="flex-start" gap="$space50">
          <div className={iconContainerStyles()}>
            <Email className="text-size-regular" />
          </div>
          <Text color={getTextColor(email)} size="small" asChild>
            <span style={{ wordBreak: 'break-all' }}>{formattedEmail}</span>
          </Text>
        </Inline>
      </Stack>
    </Col>
  );
}

function ContactRelationship(props: { relationship?: Contact['relationToUser'] }) {
  const { relationship } = props;
  const formattedRelationship = relationship ?? 'Not set';

  return (
    <Col span={4}>
      <Stack gap="$space50">
        <FieldLabel text="Relationship" />
        <Text color={getTextColor(relationship)} size="small">
          {formattedRelationship}
        </Text>
      </Stack>
    </Col>
  );
}

function ContactNote(props: Pick<Contact, 'note'>) {
  const { note } = props;
  const formattedNote = note ?? 'Not set';

  return (
    <Col span={7}>
      <Stack gap="$space50">
        <FieldLabel text="Notes" />
        <Text color={getTextColor(note)} size="small">
          <CollapsibleText text={formattedNote} />
        </Text>
      </Stack>
    </Col>
  );
}

function ContactActions(props: { onDelete: () => void; onEdit: () => void }) {
  const { onDelete, onEdit } = props;

  return (
    <Col span={1}>
      <Popover
        position="bottom-right"
        content={
          <ActionMenu>
            <ActionMenuItem text="Edit" onClick={onEdit} />
            <ActionMenuItem text="Delete" className="text-color-red" onClick={onDelete} />
          </ActionMenu>
        }
      >
        <IconButton
          icon="ellipsis"
          size="default"
          iconColor="blue-action"
          color="white"
          className="border-standard-gray"
          bordered
        />
      </Popover>
    </Col>
  );
}

export default CustomerDetailsContactsPanel;
