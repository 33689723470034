import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Power = forwardRef(function Power(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 2a.75.75 0 0 0-1.5 0v10a.75.75 0 0 0 1.5 0V2ZM6.815 5.583a.75.75 0 1 0-.944-1.166c-.408.33-.788.692-1.138 1.083A9.717 9.717 0 0 0 2.25 12c0 5.385 4.365 9.75 9.75 9.75s9.75-4.365 9.75-9.75c0-2.497-.94-4.776-2.483-6.5-.35-.39-.73-.753-1.138-1.083a.75.75 0 0 0-.943 1.166c.345.28.667.586.964.917a8.25 8.25 0 1 1-11.335-.917Z"
        fill="currentColor"
      />
    </Icon>
  );
});
