import { Radio } from 'antd';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { IExportFileStyle } from 'interfaces/account-interfaces';
import React from 'react';
import { ExportFileConfigType, FINANCE_FILE_EXPORTS_LINK } from '../../utils/account-management-constants';

interface Props {
  isOpen?: boolean;
  type?: ExportFileConfigType;
  selectedStyle?: string;
  styleOptions?: IExportFileStyle[];
  isSetDefaultFirstTime?: boolean;
  onClose: () => void;
  onSave: () => void;
  onSelect?: (styleKey: string) => void;
}

const SelectDefaultFileExportModal = (props: Props) => {
  const { type, selectedStyle, styleOptions, isOpen, onClose, onSave, onSelect, isSetDefaultFirstTime } = props;

  const isAccountSystem = type === ExportFileConfigType.ACCOUNT_SYSTEM;

  return (
    <ActionModal
      width="small"
      isOpen={isOpen}
      onClose={onClose}
      title={`Set your default ${isAccountSystem ? 'finance' : 'timesheet'} export`}
    >
      <Paragraph>Select an accounting system for your finance export:</Paragraph>
      <Radio.Group value={selectedStyle} onChange={(e) => onSelect(e.target.value)}>
        {styleOptions.map((option: IExportFileStyle) => (
          <Radio key={option.key} value={option.key} className="mv-small" style={{ display: 'block' }}>
            {option.name}
          </Radio>
        ))}
      </Radio.Group>

      <Paragraph className="mt-medium">
        Can’t find your {isAccountSystem ? 'accounting' : 'payroll'} system in the list above? Suggest a system to
        us&nbsp;
        <HyperlinkButton onClick={() => window.open(FINANCE_FILE_EXPORTS_LINK.SUGGEST_SYSTEM, '_blank')}>
          here
        </HyperlinkButton>
        .
      </Paragraph>

      <ActionModalFooter>
        <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
          {isSetDefaultFirstTime ? 'Discard' : 'Cancel'}
        </SecondaryButton>
        <PrimaryButton size="large" onClick={onSave}>
          Save as default
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};

export default SelectDefaultFileExportModal;
