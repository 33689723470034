import React from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { Button } from '@good/components';

type TmMgmtUninvitedInviteModalParams = {
  selectedTeamMemberCount: number;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
};

export const TmMgmtUninvitedInviteModal = ({
  selectedTeamMemberCount,
  isLoading,
  isOpen,
  onClose,
  onSave,
}: TmMgmtUninvitedInviteModalParams) => {
  const pluralise = selectedTeamMemberCount > 1 ? 's' : '';
  const modalTitle = `Invite user${pluralise}`;

  return (
    <ActionModal title={modalTitle} isOpen={isOpen} onClose={onClose} width="large" canCloseOutside={false}>
      <div className="mv-medium anim-slide-left">
        <div>
          <>
            <Paragraph>
              {`Are you sure you want to invite the selected team member${pluralise}? This will enable their
              profile and allow them to be assigned to rosters and bookings.`}
            </Paragraph>
            <Paragraph>The invite will be sent to their email</Paragraph>
          </>
        </div>

        <ActionModalFooter>
          <Button className="mr-3" emphasis="quiet" color="black" onClick={onClose} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button emphasis="fill" isDisabled={isLoading} onClick={onSave}>
            {`Invite team member${pluralise}`}
          </Button>
        </ActionModalFooter>
      </div>
    </ActionModal>
  );
};
