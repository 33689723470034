import * as Collapsible from '@radix-ui/react-collapsible';
import * as Separator from '@radix-ui/react-separator';
import React, { createContext, PropsWithChildren, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Button, Select } from 'antd';
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { FieldArrayWithId, FormProvider, useFormContext, UseFormHandleSubmit } from 'react-hook-form';
import { useToggleState } from '@react-stately/toggle';

import AttachFormModalWrapper from '../../../form-builder/components/AttachFormModal';
import WorkflowTemplateAddEditAttachmentModal from '../components/WorkflowTemplateAddEditAttachmentModal';
import WorkflowTemplateAddTeamMemberModal from '../components/CreateWorkflowTemplateAddTeamMemberModal';

import {
  Badge,
  ChevronDown,
  ChevronUp,
  ControlledHiddenField,
  DragHandle,
  File,
  FileUpload,
  Heading,
  Link,
  Users,
  Warning,
  WarningOctagon,
  XCircle,
} from '../create/components';
import { Field, Forms, Label, Stack } from '../ui-components';
import { SecondaryButton } from '../../../../common-components/buttons';

import {
  ActionType,
  Approver,
  approverSchema,
  Attachment,
  createAttachmentFactory,
  Form,
  ServerApprover,
  Step,
} from '../../workflow-template';

/********************************************************************
 * Styles
 *******************************************************************/

const DraggableContainer = styled.div((props: { isDragging: boolean; isDraggingOver: boolean }) => ({
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderColor: props.isDragging ? (props.isDraggingOver ? '#0073E6' : '#d8e1e8') : 'transparent',
  padding: props.isDragging ? '8px 4px' : '0px',
  gap: '16px',
  display: 'flex',
  borderRadius: '4px',
  flexDirection: 'column' as any, // TODO (Mitch): not sure why `flexDirection` is being weird here.
  transition: '150ms border-color ease-in-out, 150ms padding ease-out',
  margin: '-2px',
}));

const StepContainer = styled.div({
  backgroundColor: '#f5f8fa',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px',
});

const StepContentContainer = styled(Collapsible.Content)({
  backgroundColor: 'white',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  padding: '24px',
  '&[data-state="open"]': { display: 'initial' },
  '&[data-state="closed"]': { display: 'none' },
});

const StyledSeparator = styled(Separator.Root)({
  border: '1px solid #ebf1f5',
});

const IconButton = styled.button({
  alignItems: 'center',
  appearance: 'none',
  background: 'transparent',
  border: 'none',
  display: 'flex',
  height: '20px',
  justifyContent: 'center',
  margin: 0,
  padding: 0,
  width: '20px',
});

const Alert = styled.div({
  backgroundColor: '#FFFAE5',
  padding: '8px',
  width: 'fit-content',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

/********************************************************************
 * WorkflowSteps
 *******************************************************************/

type WorkflowStepsProps = {
  onAdd: () => void;
  onDragEnd: (result: DropResult) => void;
  onRemove: (index: number) => void;
  onSubmit: UseFormHandleSubmit<{
    steps: (Step & {
      defaultOpen: boolean;
    })[];
  }>;
  onUpdate: (
    index: number,
    args: {
      approvers?: Approver[];
      attachment?: Attachment;
      form?: Form;
    },
  ) => void;
  steps: FieldArrayWithId<
    {
      steps: (Step & {
        defaultOpen: boolean;
      })[];
    },
    'steps',
    'id'
  >[];
};

// Skip the fixed first step.
const SKIP_FIRST_STEP = 1;

export function WorkflowSteps(props: WorkflowStepsProps) {
  let { onAdd, onDragEnd, onRemove, onSubmit, onUpdate, steps } = props;
  let formMethods = useFormContext();
  let [firstStep, ...otherSteps] = steps;

  return (
    <StepContainer className="bg-white" style={{ padding: 0, gap: 0 }}>
      <Heading>Choose actions for each step of your workflow</Heading>
      <Stack gap="xl" dir="column">
        {steps?.length > 0 && (
          <FormProvider {...formMethods}>
            <Stack gap="xl">
              <WorkflowFirstStepForm {...firstStep} stepNumber={0} onUpdate={onUpdate} />

              {steps?.length > 1 && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, { isDraggingOver, draggingFromThisWith }) => {
                      let isDragging = !!draggingFromThisWith;

                      return (
                        <DraggableContainer
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          isDragging={isDragging}
                          isDraggingOver={isDraggingOver}
                        >
                          {otherSteps.map((step: Step & { defaultOpen: boolean }, index: number) => (
                            <Draggable key={step.id} draggableId={step.id} index={index}>
                              {(provided) => {
                                let { innerRef, draggableProps, dragHandleProps } = provided;

                                return (
                                  <div ref={innerRef} {...draggableProps} tabIndex={-1}>
                                    <WorkflowStep
                                      defaultOpen={step.defaultOpen}
                                      dragHandleProps={dragHandleProps}
                                      name={step.name}
                                      onRemove={onRemove}
                                      onUpdate={onUpdate}
                                      stepNumber={index + SKIP_FIRST_STEP}
                                    >
                                      <WorkflowStepForm
                                        key={step.id}
                                        stepNumber={index + SKIP_FIRST_STEP}
                                        onUpdate={onUpdate}
                                      />
                                    </WorkflowStep>
                                  </div>
                                );
                              }}
                            </Draggable>
                          ))}

                          {provided.placeholder}
                        </DraggableContainer>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
              )}
            </Stack>
          </FormProvider>
        )}
      </Stack>

      <Button id="add-new-step" style={{ alignSelf: 'start', marginTop: '16px' }} onClick={onSubmit(onAdd)}>
        Add new step
      </Button>
    </StepContainer>
  );
}

/********************************************************************
 * Step
 *******************************************************************/

type WorkflowStepProps = {
  defaultOpen: boolean;
  dragHandleProps: DraggableProvidedDragHandleProps;
  stepNumber: number;
} & Step &
  Pick<WorkflowStepsProps, 'onRemove' | 'onUpdate'>;

export function WorkflowStep(props: WorkflowStepProps) {
  let { children, defaultOpen, dragHandleProps, id, name, onRemove, onUpdate, stepNumber } = props;
  let { isSelected: isOpen, toggle } = useToggleState({ defaultSelected: true });
  let { formState } = useFormContext();
  let isError = !!formState.errors.steps?.[stepNumber];
  let isShowError = isError && !isOpen;

  return (
    <Collapsible.Root open={isOpen} onOpenChange={toggle}>
      <StepContainer style={isShowError ? { backgroundColor: '#F7D7D7', borderLeft: '4px solid #C13232' } : {}}>
        <Collapsible.Trigger asChild>
          <Stack dir="row" className="justify-between align-center ">
            <Stack gap="md" dir="row" className="align-center">
              {dragHandleProps && (
                <div className="flex" {...dragHandleProps} style={isShowError ? { marginLeft: '-4px' } : {}}>
                  <DragHandle />
                </div>
              )}
              {isShowError && <WarningOctagon style={{ color: '#C13232', marginLeft: '-4px' }} />}
              <span className="text-weight-bolder">Step {stepNumber + 1}</span>
              <span>{name}</span>
            </Stack>

            <IconButton>{isOpen ? <ChevronUp /> : <ChevronDown />}</IconButton>
          </Stack>
        </Collapsible.Trigger>

        {/* <Collapsible.Content forceMount> */}
        <StepContentContainer forceMount data-testid="hello">
          {children}
          <div style={{ marginTop: '32px', display: 'flex', gap: '32px', flexDirection: 'column' }}>
            <StyledSeparator decorative />
            <div style={{ alignSelf: 'end' }}>
              <SecondaryButton
                color="red"
                disabled={!onRemove}
                onClick={useCallback(() => onRemove(stepNumber), [onRemove, stepNumber])}
              >
                Delete step
              </SecondaryButton>
            </div>
          </div>
        </StepContentContainer>

        {/* </Collapsible.Content> */}
      </StepContainer>
    </Collapsible.Root>
  );
}

type StepFormProps = Pick<WorkflowStepProps, 'stepNumber' | 'onUpdate'>;

/********************************************************************
 * StepForm
 *******************************************************************/

export function WorkflowFirstStepForm(props: StepFormProps) {
  let { stepNumber } = props;
  let { control, register, watch } = useFormContext();
  let actionType: ActionType = watch(`steps.${stepNumber}.details.actionType`);

  return (
    <WorkflowStep stepNumber={0}>
      <Stack gap="xl">
        <Field width="33%">
          <Forms.SelectField label="Action" {...register(`steps.${stepNumber}.details.actionType`)}>
            <Select.Option value="form">Fill a form</Select.Option>
            <Select.Option value="attachment">Upload document</Select.Option>
          </Forms.SelectField>
        </Field>

        {
          {
            approval: null,
            attachment: <AttachmentField {...props} />,
            form: <FormField {...props} />,
          }[actionType]
        }

        <Field width="33%">
          <Forms.TextField
            type="text"
            label="Step name"
            description="E.g. Review incident or Manager 
            approval."
            control={control}
            {...register(`steps.${stepNumber}.details.name`, {
              required: 'Name is required.',
              minLength: {
                value: 2,
                message: 'Name is too short.',
              },
            })}
          />
        </Field>

        <Field width="33%">
          <Forms.TextField
            label="Instruction note (optional)"
            multiline
            rows={2}
            control={control}
            {...register(`steps.${stepNumber}.details.description`, {
              maxLength: {
                value: 250,
                message: 'Instruction note is too long.',
              },
            })}
          />
        </Field>

        <Label style={{ marginBottom: '-4px' }}>Assign to</Label>
        <Alert>
          <Warning />
          <span>
            <span className="text-weight-bold">Step 1</span> must be completed by the person reporting the incident.
          </span>
        </Alert>
      </Stack>
    </WorkflowStep>
  );
}

/********************************************************************
 * StepForm
 *******************************************************************/

export function WorkflowStepForm(props: StepFormProps) {
  let { stepNumber } = props;
  let { control, register, watch } = useFormContext();
  let actionType: ActionType = watch(`steps.${stepNumber}.details.actionType`);

  return (
    <>
      <Stack gap="xl">
        <Field width="33%">
          <Forms.SelectField label="Action" {...register(`steps.${stepNumber}.details.actionType`)}>
            <Select.Option value="form">Fill a form</Select.Option>
            <Select.Option value="attachment">Upload document</Select.Option>
            <Select.Option value="approval">Get approval</Select.Option>
          </Forms.SelectField>
        </Field>

        {
          {
            approval: null,
            attachment: <AttachmentField {...props} />,
            form: <FormField {...props} />,
          }[actionType]
        }

        <Field width="33%">
          <Forms.TextField
            type="text"
            label="Step name"
            description="E.g. Review incident or Manager 
            approval."
            control={control}
            {...register(`steps.${stepNumber}.details.name`, {
              required: 'Name is required.',
              minLength: {
                value: 2,
                message: 'Name is too short.',
              },
            })}
          />
        </Field>

        <Field width="33%">
          <Forms.TextField
            label="Instruction note (optional)"
            multiline
            rows={2}
            control={control}
            {...register(`steps.${stepNumber}.details.description`, {
              maxLength: {
                value: 250,
                message: 'Instruction note is too long.',
              },
            })}
          />
        </Field>

        <Label style={{ marginBottom: '-4px' }}>Assign to</Label>
        <ApprovalField {...props} />
      </Stack>
    </>
  );
}

/********************************************************************
 * Action
 *******************************************************************/

const ActionContext = createContext<{ onToggle: () => void; isOpen: boolean }>(null);

type ActionProps = PropsWithChildren<StepFormProps>;

/**
 * @name Action
 *
 * @description
 * Wrapper to provide common behaviour to custom form properties:
 * Approval; Attachment; Form.
 */
export function Action(props: ActionProps) {
  let { children } = props;
  let { isSelected: isOpen, toggle: onToggle } = useToggleState({ defaultSelected: false });

  return (
    <ActionContext.Provider
      value={{
        isOpen,
        onToggle,
      }}
    >
      {children}
    </ActionContext.Provider>
  );
}

type ActionContentProps = PropsWithChildren<{}>;

export function ActionContent(props: ActionContentProps) {
  let { children } = props;
  let { isOpen, onToggle } = useContext(ActionContext);
  return React.cloneElement(children, { isOpen, onClose: onToggle });
}

type ActionTriggerProps = PropsWithChildren<{}>;

export function ActionTrigger(props: ActionTriggerProps) {
  let { children } = props;
  let { onToggle } = useContext(ActionContext);

  return (
    <Button
      type="link"
      onClick={onToggle}
      style={{ marginLeft: '-16px', marginTop: '-8px', marginBottom: '-8px', alignSelf: 'start' }}
    >
      <div style={{ display: 'inline-flex', gap: '4px', alignItems: 'center' }}>{children}</div>
    </Button>
  );
}

/********************************************************************
 * ApprovalField
 *******************************************************************/

function ApprovalField(props: ActionProps) {
  let { onUpdate, stepNumber } = props;
  let { getValues, register, trigger, watch } = useFormContext();
  let approvers = watch(`steps.${stepNumber}.details.approvers`);

  function handleOnAddApprovers(selectedApprovers: ServerApprover[]) {
    let approvers = selectedApprovers.map((a) =>
      approverSchema.parse({
        id: a.approverUserId,
        avatarUrl: a.avatar,
        name: a.displayName,
      } as Approver),
    );

    onUpdate(stepNumber, { approvers });
    trigger(`steps.${stepNumber}.details.approvers`);
  }

  return (
    <Stack gap="lg">
      <Action>
        <ActionContent>
          <WorkflowTemplateAddTeamMemberModal
            onChange={handleOnAddApprovers}
            approvers={(approvers ?? []).map((a) => ({
              approverUserId: a.id,
              avatar: a.avatarUrl,
              displayName: a.name,
            }))}
          />
        </ActionContent>
        <ActionTrigger>
          {/*TODO update assignee label */}
          <Users /> Select team members
        </ActionTrigger>
      </Action>

      {approvers?.length > 0 && (
        <Field width="50%">
          <Stack gap="sm" dir="row" style={{ flexWrap: 'wrap' }}>
            {approvers.map((a: Approver) => {
              return (
                <Badge key={a.id}>
                  <img alt="" src={a.avatarUrl} style={{ height: 16, width: 16, borderRadius: '9999px' }} />
                  <span>{a.name}</span>
                </Badge>
              );
            })}
          </Stack>
        </Field>
      )}

      <ControlledHiddenField
        {...register(`steps.${stepNumber}.approvers`, {
          validate: {
            required() {
              let approvers: Approver[] = getValues(`steps.${stepNumber}.details.approvers`) ?? [];
              if (!approvers.length) return 'One or more assignees are required.';
            },
          },
        })}
        style={{ marginTop: '-4px' }}
      />
    </Stack>
  );
}

/********************************************************************
 * AttachmentField
 *******************************************************************/

function AttachmentField(props: ActionProps) {
  let { onUpdate, stepNumber } = props;
  let { register, trigger, watch } = useFormContext();
  let attachment = watch(`steps.${stepNumber}.details.attachment`) as Attachment;

  function handleOnAddAttachment(attachmentArgs: {
    attachment: { description: string; name: string; file: { uid: string } };
  }) {
    let {
      attachment: {
        description,
        name,
        file: { uid },
      },
    } = attachmentArgs;

    let attachment: Attachment = { description, id: uid, name, url: '' };
    onUpdate(stepNumber, { attachment });
    trigger(`steps.${stepNumber}.details.attachment.id`);
  }

  const handleOnRemoveAttachment = useCallback(
    function handleOnRemoveAttachment() {
      onUpdate(stepNumber, { attachment: createAttachmentFactory({}) });
    },
    [onUpdate, stepNumber],
  );

  return (
    <Stack gap="lg">
      <ControlledHiddenField
        {...register(`steps.${stepNumber}.details.attachment.id`, { required: 'An attachment is required.' })}
        style={{ marginTop: '-4px' }}
      />

      {attachment?.name && (
        <div style={{ userSelect: 'none' }}>
          <Badge onPress={handleOnRemoveAttachment}>
            <File size={16} /> {attachment.name} <XCircle size={16} />
          </Badge>
        </div>
      )}

      <Action>
        <ActionContent>
          {/** @ts-ignore TODO (Mitch): Clean up WorkflowTemplateAddEditAttachmentModal` types */}
          <WorkflowTemplateAddEditAttachmentModal onUpdateStep={handleOnAddAttachment} selectedStep={null} />
        </ActionContent>
        <ActionTrigger>
          <FileUpload /> {attachment?.name ? 'Upload a different document' : 'Upload document'}
        </ActionTrigger>
      </Action>
    </Stack>
  );
}

/********************************************************************
 * FormField
 *******************************************************************/

function FormField(props: ActionProps) {
  let { onUpdate, stepNumber } = props;
  let { register, trigger, watch } = useFormContext();
  let form = watch(`steps.${stepNumber}.details.form`);

  function handleOnAddForm(form: Form) {
    onUpdate(stepNumber, { form });
    trigger(`steps.${stepNumber}.details.form.id`);
  }

  function handleOnRemoveForm() {
    onUpdate(stepNumber, { form: { id: '', name: '', versionId: '' } });
  }

  return (
    <Stack gap="lg">
      <ControlledHiddenField
        {...register(`steps.${stepNumber}.details.form.id`, { required: 'A form is required.' })}
        style={{ marginTop: '-4px' }}
      />

      {form?.name && (
        <div style={{ userSelect: 'none' }}>
          <Badge onPress={handleOnRemoveForm}>
            <File size={16} /> {form.name} <XCircle size={16} />
          </Badge>
        </div>
      )}

      <Action>
        <ActionContent>
          <AttachFormModalWrapper
            isAddedFormOnly
            onSave={useCallback(
              (id: string, _: unknown, name: string, versionId: string) => handleOnAddForm({ id, name, versionId }),
              [],
            )}
            isWorkflowForm
          />
        </ActionContent>
        <ActionTrigger>
          <Link /> {form?.name ? 'Select a different form' : 'Select form'}
        </ActionTrigger>
      </Action>
    </Stack>
  );
}
