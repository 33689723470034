import React, { Component } from 'react';
import { Text } from 'common-components/typography';

interface IGuardianConnectionStatusTagProps {
  guardianStatus: string;
}

class GuardianConnectionStatusTag extends Component<IGuardianConnectionStatusTagProps, any> {
  render() {
    const { guardianStatus } = this.props;
    let label, bgColor, textColor, borderColor;
    switch (guardianStatus) {
      case 'CONNECTED':
        label = 'Connected';
        bgColor = 'bg-green';
        textColor = 'text-color-white';
        borderColor = '#0F9960';
        break;
      case 'PENDING':
        label = 'Invitation sent';
        bgColor = 'bg-orange-lightest';
        textColor = 'text-color-orange-dark ';
        borderColor = '#FFB366';
        break;
      case 'NOT_CONNECTED':
        label = 'Not connected';
        bgColor = 'bg-quaternary';
        textColor = 'text-color-secondary';
        borderColor = '#696969';
        break;
    }

    const containerClassNames = `text-align-center pv-x-small ph-small text-size-regular inline-block rounded ${bgColor} bordered`;
    const textClassNames = `${textColor}`;
    return (
      <div
        className={containerClassNames}
        style={
          borderColor
            ? {
                borderColor: borderColor,
              }
            : {}
        }
      >
        <Text size={'regular'} className={textClassNames}>
          {label}
        </Text>
      </div>
    );
  }
}

export default GuardianConnectionStatusTag;
