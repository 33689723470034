import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Tag, TimePicker } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { IBillingLineItem } from 'interfaces/booking-interfaces';
import { bookingStatusColors } from 'theme/theme-variables';
import _ from 'lodash';
import moment from 'moment';

// import BillingReviewEditLineItem from './BillingReviewEditLineItem';

interface IBookingApproveEditLineItemProps extends FormComponentProps {
  // serviceAgreementLineItem: IServiceAgreementLineItem;
  // showSidebar: boolean;
  index: number;
  // supportCategoryOptions: any[];
  isEdit: boolean;
  bookingBillingLineItem: IBillingLineItem;
  billingSupportItems: any[];
  isValidate: boolean;
  // services: IServiceListItem[];
  onRemoveLineItem?: (index: number) => void;
  onChangeLineItem: (index: number, billingLineItem: any) => void;
}

interface IBookingApproveEditLineItemState {
  modalVisible: boolean;
  isEdit1: boolean;
  isRemove: boolean;
  isExpand: boolean;
  supportItemOptions: any[];
}

const unitOptions = [
  { value: 'EA', label: 'Each' },
  { value: 'H', label: 'Hourly' },
];

const paymentOptions = [
  { value: 'NDIA', label: 'NDIA Managed' },
  { value: 'SELF', label: 'Self Managed' },
  { value: 'PLAN', label: 'Plan Managed' },
];

class BookingApproveEditLineItem extends Component<IBookingApproveEditLineItemProps, IBookingApproveEditLineItemState> {
  state = {
    modalVisible: false,
    isEdit1: false,
    isRemove: false,
    isExpand: false,
    supportItemOptions: [],
  };

  _onClickEdit = () => {
    this.setState({ isEdit1: true });
  };

  _onClickRemove = () => {
    this.setState({ isRemove: true });
  };

  _onEditSave = () => {
    this.setState({ isEdit1: false });
  };

  _onEditCancel = () => {
    this.setState({ isEdit1: false });
  };

  _onClickExpand = () => {
    this.setState({ isExpand: !this.state.isExpand });
  };

  _onChangeSupportItemNumber = (value) => {
    // ! find supportItem
    const supportItem = this.props.billingSupportItems.find((supportItem) => supportItem.supportItemNumber === value);

    this.props.form.setFieldsValue({
      unitPrice: supportItem.price,
      unit: supportItem.unit === 'EA' ? 'EA' : 'H',
    });
  };

  _renderFormField = (editValue, displayValue) => {
    return this.props.isEdit && this.props.bookingBillingLineItem.paymentStatus !== 'READY_FOR_BILLING' ? (
      editValue
    ) : (
      <Text>{displayValue}</Text>
    );
  };

  _onChangePaymentMethod = (value) => {
    const billingLineItem = this.props.form.getFieldsValue();

    this.props.form.setFieldsValue({
      paymentMethod: value,
    });

    billingLineItem.paymentMethod = value;
    delete billingLineItem.endDate;
    delete billingLineItem.endTime;
    delete billingLineItem.startDate;
    delete billingLineItem.startTime;

    if (this.props.onChangeLineItem) {
      this.props.onChangeLineItem(this.props.index, billingLineItem);
    }
  };

  _onChangeCalculateTotalPrice = () => {
    const billingLineItem = this.props.form.getFieldsValue();

    // merge startDate and startTime to  startDateTime
    billingLineItem.startDate.set({
      hour: billingLineItem.startTime.get('hour'),
      minute: billingLineItem.startTime.get('minute'),
    });
    billingLineItem.startDateTime = moment(billingLineItem.startDate);
    delete billingLineItem.startDate;
    delete billingLineItem.startTime;

    // merge endDate and endTime to  endDateTime
    billingLineItem.endDate.set({
      hour: billingLineItem.endTime.get('hour'),
      minute: billingLineItem.endTime.get('minute'),
    });
    billingLineItem.endDateTime = moment(billingLineItem.endDate);
    delete billingLineItem.endDate;
    delete billingLineItem.endTime;

    let lineTotal = 0;

    if (billingLineItem.unitPrice && billingLineItem.qty) {
      lineTotal = parseFloat(billingLineItem.unitPrice) * billingLineItem.qty;
    }
    billingLineItem.total = parseFloat(lineTotal.toFixed(2));

    if (this.props.onChangeLineItem) {
      this.props.onChangeLineItem(this.props.index, billingLineItem);
    }
  };

  componentDidMount = () => {
    let supportItemOptions = _.map(this.props.billingSupportItems, (billingSupportItem) => {
      return {
        value: billingSupportItem.supportItemNumber,
        label: `${billingSupportItem.supportItemNumber} ${billingSupportItem.supportItem}`,
      };
    });
    this.setState({ supportItemOptions });
  };

  render() {
    const { index, form, bookingBillingLineItem, onRemoveLineItem, isEdit } = this.props;
    const startDateTime = moment(bookingBillingLineItem.startDateTime);
    const endDateTime = moment(bookingBillingLineItem.endDateTime);
    const { getFieldDecorator } = form;
    const selectedUnitOption = unitOptions.find((i) => i.value === bookingBillingLineItem.unit);

    const leftSpan = 5;
    const rightSpan = 7;

    return (
      <div className={'mb-large'}>
        <div className="flex-row align-center mb-small">
          <Text className="text-color-secondary mv-none pv-none pr-large text-size-large">
            Billing Line Item {index + 1}
          </Text>
          <Tag color={bookingStatusColors[_.toLower(bookingBillingLineItem.paymentStatus)]}>
            {bookingBillingLineItem.paymentStatus}
          </Tag>
          {bookingBillingLineItem.paymentStatus === 'REMOVED' && <div> {bookingBillingLineItem.rejectReason}</div>}
          {isEdit && bookingBillingLineItem.paymentStatus !== 'READY_FOR_BILLING' && (
            <Button
              type={'link'}
              icon={'delete'}
              size={'small'}
              onClick={() => {
                onRemoveLineItem(index);
              }}
            >
              Delete
            </Button>
          )}
        </div>
        <Row type={'flex'} justify={'start'} gutter={32}>
          <BookingBillingLineItemRow
            leftSpan={leftSpan}
            rightSpan={rightSpan}
            label={'Start Time'}
            isDisplayFlex={true}
          >
            {this._renderFormField(
              <>
                <Form.Item>
                  {getFieldDecorator('startDate', {
                    initialValue: startDateTime,
                    rules: [
                      {
                        required: true,
                        message: 'Please select a start date',
                      },
                    ],
                  })(<DatePicker format="DD-MM-YYYY" className={'mr-small'} />)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('startTime', {
                    initialValue: startDateTime,
                    rules: [
                      {
                        required: true,
                        message: 'Please select a start time',
                      },
                    ],
                  })(<TimePicker format="hh:mma" allowClear={false} use12Hours={true} minuteStep={15} />)}
                </Form.Item>
              </>,
              startDateTime.format('DD-MM-YYYY hh:mm A'),
            )}
          </BookingBillingLineItemRow>
          <BookingBillingLineItemRow leftSpan={leftSpan} rightSpan={rightSpan} label={'End Time'} isDisplayFlex={true}>
            {this._renderFormField(
              <>
                <Form.Item>
                  {getFieldDecorator('endDate', {
                    initialValue: endDateTime,
                    rules: [
                      {
                        required: true,
                        message: 'Please select an end date',
                      },
                    ],
                  })(<DatePicker format="DD-MM-YYYY" style={{ marginRight: '8px' }} />)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('endTime', {
                    initialValue: endDateTime,
                    rules: [
                      {
                        required: true,
                        message: 'Please select an end time',
                      },
                    ],
                  })(<TimePicker format="hh:mma" allowClear={false} use12Hours={true} minuteStep={15} />)}
                </Form.Item>
              </>,
              endDateTime.format('DD-MM-YYYY hh:mm A'),
            )}
          </BookingBillingLineItemRow>
          <BookingBillingLineItemRow leftSpan={leftSpan} rightSpan={24 - leftSpan} label={'Support Item Number'}>
            {this._renderFormField(
              <Form.Item>
                {getFieldDecorator('supportItemNumber', {
                  initialValue: bookingBillingLineItem.supportItemNumber,
                  rules: [
                    {
                      required: true,
                      message: 'Please select a support item number',
                    },
                  ],
                })(
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    onChange={this._onChangeSupportItemNumber}
                    filterOption={true}
                    optionFilterProp={'children'}
                  >
                    {this.state.supportItemOptions &&
                      this.state.supportItemOptions.map((option) => {
                        return (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        );
                      })}
                  </Select>,
                )}
              </Form.Item>,
              bookingBillingLineItem.supportItemNumber ? bookingBillingLineItem.supportItemNumber : '-',
            )}
          </BookingBillingLineItemRow>
          <BookingBillingLineItemRow leftSpan={leftSpan} rightSpan={rightSpan} label={'Unit Price'}>
            {this._renderFormField(
              <>
                <Form.Item>
                  {getFieldDecorator('unitPrice', {
                    initialValue: bookingBillingLineItem.unitPrice,
                    rules: [
                      {
                        required: true,
                        message: 'Please input unit price',
                      },
                    ],
                  })(<InputNumber precision={2} onBlur={this._onChangeCalculateTotalPrice} />)}
                </Form.Item>
              </>,
              bookingBillingLineItem.unitPrice ? `$ ${bookingBillingLineItem.unitPrice}` : '-',
            )}
          </BookingBillingLineItemRow>
          <BookingBillingLineItemRow leftSpan={leftSpan} rightSpan={rightSpan} label={'Unit'}>
            {this._renderFormField(
              <Form.Item>
                {getFieldDecorator('unit', {
                  initialValue: bookingBillingLineItem.unit,
                  rules: [
                    {
                      required: true,
                      message: 'Please select unit',
                    },
                  ],
                })(
                  <Select style={{ minWidth: '100px' }}>
                    {unitOptions.map((option) => {
                      return (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>,
              selectedUnitOption ? selectedUnitOption.label : '-',
            )}
          </BookingBillingLineItemRow>
          <BookingBillingLineItemRow leftSpan={leftSpan} rightSpan={rightSpan} label={'Quantity'}>
            {this._renderFormField(
              <>
                <Form.Item>
                  {getFieldDecorator('qty', {
                    initialValue: bookingBillingLineItem.qty,
                    rules: [
                      {
                        required: true,
                        message: 'Please input quantity',
                      },
                    ],
                  })(<InputNumber precision={2} onBlur={this._onChangeCalculateTotalPrice} />)}
                </Form.Item>
              </>,
              bookingBillingLineItem.qty ? bookingBillingLineItem.qty : '-',
            )}
          </BookingBillingLineItemRow>
          <BookingBillingLineItemRow leftSpan={leftSpan} rightSpan={rightSpan} label={'Total'}>
            <Text>$ {bookingBillingLineItem.total}</Text>
            {getFieldDecorator('bookingBillingLineItemId', {
              initialValue: bookingBillingLineItem.bookingBillingLineItemId,
            })}
            {getFieldDecorator('billingQty', { initialValue: bookingBillingLineItem.qty })}
          </BookingBillingLineItemRow>
          <BookingBillingLineItemRow leftSpan={leftSpan} rightSpan={24 - leftSpan} label={'Description'}>
            {this._renderFormField(
              <Form.Item>
                <Input.TextArea rows={2} />
              </Form.Item>,
              bookingBillingLineItem.description ? bookingBillingLineItem.description : '-',
            )}
          </BookingBillingLineItemRow>
          <BookingBillingLineItemRow leftSpan={leftSpan} rightSpan={24 - leftSpan} label={'Payment Method'}>
            {this._renderFormField(
              <>
                <Form.Item>
                  {getFieldDecorator('paymentMethod', {
                    initialValue: bookingBillingLineItem.paymentMethod,
                    rules: [
                      {
                        required: true,
                        message: 'Please select payment method',
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      onChange={this._onChangePaymentMethod}
                      filterOption={true}
                      optionFilterProp={'children'}
                    >
                      {paymentOptions.map((option) => {
                        return (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        );
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </>,
              bookingBillingLineItem.paymentMethod ? bookingBillingLineItem.paymentMethod : '-',
            )}
          </BookingBillingLineItemRow>
        </Row>

        <Divider className="divider-medium" />
      </div>
    );
  }
}

const BookingBillingLineItemRow = ({ leftSpan, rightSpan, label, isDisplayFlex = false, children }) => (
  <>
    <Col span={leftSpan} className={'mb-large'}>
      <Text>
        <b>{label}</b>
      </Text>
    </Col>
    <Col span={rightSpan} style={isDisplayFlex && { display: 'flex' }}>
      {children}
    </Col>
  </>
);

export default Form.create<IBookingApproveEditLineItemProps>()(BookingApproveEditLineItem);
