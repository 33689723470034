import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const AlignMiddle = forwardRef(function AlignMiddle(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m12.53 9.53 3-3a.75.75 0 0 0-1.06-1.06l-1.72 1.72V2a.75.75 0 0 0-1.5 0v5.19L9.53 5.47a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0ZM12 22.75a.75.75 0 0 0 .75-.75v-5.19l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72V22c0 .414.336.75.75.75Zm-8-11.5a.75.75 0 0 0 0 1.5h16a.75.75 0 0 0 0-1.5H4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
