import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FilmTape = forwardRef(function FilmTape(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.25h-.055l-.899.001a.764.764 0 0 0-.1.001c-.918.006-1.693.028-2.338.115-.9.12-1.658.38-2.26.981-.601.602-.86 1.36-.981 2.26-.049.36-.077.762-.094 1.206a.751.751 0 0 0-.01.327c-.013.541-.013 1.142-.013 1.804V15.055c0 .662 0 1.263.013 1.804a.755.755 0 0 0 .01.327c.017.444.045.845.094 1.206.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.645.086 1.42.108 2.338.114a.818.818 0 0 0 .1 0c.286.002.586.002.9.002h6.109l.9-.001a.78.78 0 0 0 .1 0c.917-.007 1.692-.029 2.337-.115.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.048-.36.076-.762.093-1.206a.749.749 0 0 0 .01-.327c.013-.541.013-1.142.013-1.804V8.945c0-.663 0-1.263-.013-1.804a.752.752 0 0 0-.01-.327 12.69 12.69 0 0 0-.093-1.206c-.121-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981-.645-.087-1.42-.11-2.337-.115a.763.763 0 0 0-.1 0c-.287-.002-.587-.002-.9-.002H9Zm-5.193 15.5c.012.156.028.303.046.442.099.734.28 1.122.556 1.399.277.277.665.457 1.4.556.4.054.872.08 1.441.092V17.75H3.807Zm3.443-1.5H3.754c-.004-.376-.004-.791-.004-1.25v-2.25h3.5v3.5Zm1.5.75v3.25h6.5v-7.5h-6.5V17Zm11.5-2c0 .459 0 .874-.004 1.25H16.75v-3.5h3.5V15Zm-.057 2.75H16.75v2.489c.57-.012 1.04-.038 1.442-.092.734-.099 1.122-.28 1.4-.556.276-.277.456-.665.555-1.4a8.31 8.31 0 0 0 .046-.441Zm0-11.5a8.306 8.306 0 0 0-.046-.442c-.099-.734-.28-1.122-.556-1.399-.277-.277-.665-.457-1.399-.556-.402-.054-.873-.08-1.442-.092V6.25h3.443ZM15.25 7V3.75h-6.5v7.5h6.5V7Zm1.5.75h3.496c.004.376.004.791.004 1.25v2.25h-3.5v-3.5ZM3.75 9c0-.459 0-.874.004-1.25H7.25v3.5h-3.5V9Zm.057-2.75H7.25V3.761c-.569.012-1.04.038-1.442.092-.734.099-1.122.28-1.399.556-.277.277-.457.665-.556 1.4a8.274 8.274 0 0 0-.046.441Z"
        fill="currentColor"
      />
    </Icon>
  );
});
