import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { Steps } from './components';
import { CreateWorkflowProvider } from './create-workflow-provider';
import { WorkflowBuild, WorkflowName, WorkflowReview, WorkflowSettings } from './workflow-steps';
// import { WorkflowName } from './workflow-steps';

/********************************************************************
 * Types
 *******************************************************************/

export type StepProps = {
  onBack?: () => void;
  onNext?: () => void;
};

/********************************************************************
 * Styles
 *******************************************************************/

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  '@media (max-width: 1024px)': {
    margin: '16px',
  },
});

/********************************************************************
 * CreateWorkflow
 *******************************************************************/

// const steps = [WorkflowName, WorkflowBuild, WorkflowSettings, WorkflowReview];
const steps = [WorkflowName, WorkflowBuild, WorkflowSettings, WorkflowReview];
const stepDisplayNames = ['Name workflow', 'Build workflow', 'Settings', 'Review and finish!'];

export function CreateWorkflow() {
  let [step, setStep] = useState(0);
  let Step = steps[step];

  const handleBack = useCallback(
    function handlePrevious() {
      setStep(step - 1);
    },
    [step],
  );

  const handleNext = useCallback(
    function handleNext() {
      setStep(step + 1);
    },
    [step],
  );

  return (
    <CreateWorkflowProvider>
      <Container>
        <h1 className="m-none text-weight-bold">Create workflow</h1>
        <Steps steps={stepDisplayNames} currentStep={step} />
        <Step key={step} onBack={handleBack} onNext={handleNext} />
      </Container>
    </CreateWorkflowProvider>
  );
}
