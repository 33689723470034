import { Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Text, SubTitle } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import { IFormElement } from 'views/form-builder/shared/form-interface';
import {
  convertRulesRequiredOfBundle,
  getRules,
  getValueFromPropertyByKey,
} from 'views/form-builder/shared/form-builder-utils';
import FormItem from 'antd/es/form/FormItem';
import { FormElementType } from 'views/form-builder/shared/form-enum';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import type { FormModeType } from '../../EditValueFormModal';

type IParagraphProps = {
  value?: string;
  fieldTitle?: string;
  placeholder?: string;
  caption?: string;
  isRequired?: boolean;
};

export default function LongText({ fieldTitle: fieldText, placeholder, value, caption, isRequired }: IParagraphProps) {
  const fieldTextFormat = (
    <>
      {fieldText}
      {isRequired ? <Text color='red'> *</Text> : ''}
    </>
  );

  return (
    <div className='flex-column width-full'>
      <SubTitle weight='bolder'>{fieldTextFormat}</SubTitle>
      <Input.TextArea className='rounded pt-small' rows={6} style={{ resize: 'none' }} placeholder={placeholder} />
      <Text className='pt-small' size='small' color='secondary' lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
}

type IEditParagraphProps = {
  value?: string;
  element: IFormElement;
  formMode?: FormModeType;
} & FormComponentProps;

export const EditLongText = ({ value, element, form, formMode }: IEditParagraphProps) => {
  const { elementsBundleOptional } = useSelector((state: IRootState) => state.formBuilderStore);
  const { id, parentId } = element;
  const placeholder = getValueFromPropertyByKey(element.properties.appearance, 'placeholderText');
  const caption = getValueFromPropertyByKey(element.properties.appearance, 'captionText');
  let rules = getRules(element, false);
  const { getFieldDecorator, setFieldsValue, validateFields, getFieldError } = form;
  const isRequired = getValueFromPropertyByKey(element.properties.general, 'require');
  let fieldText = getValueFromPropertyByKey(element.properties.general, 'fieldTitle');
  const [messageError, setMessageError] = useState(getFieldError(`${id},value`)?.join(' '));
  const isRequiredBundle = !elementsBundleOptional.includes(id);

  if (isRequired) {
    rules = convertRulesRequiredOfBundle(isRequiredBundle, rules);

    fieldText = (
      <>
        {fieldText}
        {isRequiredBundle && <Text color='red'> *</Text>}
      </>
    );
  }

  const changeValue = useCallback((value) => {
    const fieldName = `${id},value`;

    setFieldsValue({
      [fieldName]: value,
    });

    validateFields([fieldName]);
  }, []);

  const debounceChangeValue = debounce(changeValue, 300);

  const onChangeValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    debounceChangeValue(value);
  };

  useEffect(() => {
    const fieldError = getFieldError(`${id},value`)?.join(' ');
    if (fieldError !== messageError) {
      setMessageError(fieldError);
    }
  }, [elementsBundleOptional]);

  return (
    <div className='flex-column width-full pointer-events-auto'>
      <SubTitle weight='bolder'>{fieldText}</SubTitle>
      <FormItem className='m-none' validateStatus={messageError ? 'error' : ''} help={messageError}>
        <Input.TextArea
          id={`${id},value`}
          defaultValue={form.getFieldValue(`${id},value`) ?? value}
          className='rounded pt-small'
          rows={6}
          placeholder={placeholder}
          onChange={onChangeValue}
          readOnly={formMode === 'VIEW'}
        />
      </FormItem>
      <FormItem className='m-none hide'>
        {getFieldDecorator([id, 'value'] as never, {
          initialValue: value,
          rules: rules,
        })(<Input.TextArea />)}
      </FormItem>
      <FormItem className='m-none'>
        {getFieldDecorator([id, 'type'] as never, {
          initialValue: FormElementType.LONG_TEXT,
        })(<Input type='hidden' />)}
      </FormItem>
      <FormItem className='m-none'>
        {getFieldDecorator([id, 'parentElementId'] as never, {
          initialValue: parentId,
        })(<Input type='hidden' />)}
      </FormItem>
      <Text className='pt-small' size='small' color='secondary' lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
};
