import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const History = forwardRef(function History(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25c-3.223 0-5.637 1.346-7.23 2.674-.387.322-.727.645-1.02.948V4a.75.75 0 0 0-1.5 0v4c0 .414.336.75.75.75h3.5a.75.75 0 0 0 0-1.5H4.518c.318-.358.723-.766 1.212-1.174C7.137 4.904 9.223 3.75 12 3.75A8.231 8.231 0 0 1 20.25 12a8.25 8.25 0 0 1-16.5 0 .75.75 0 0 0-1.5 0c0 5.385 4.365 9.75 9.75 9.75s9.75-4.365 9.75-9.75A9.731 9.731 0 0 0 12 2.25ZM12.75 8a.75.75 0 0 0-1.5 0v4c0 .25.125.485.334.624l3 2a.75.75 0 1 0 .832-1.248l-2.666-1.777V8Z"
        fill="currentColor"
      />
    </Icon>
  );
});
