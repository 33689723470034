import { useState } from 'react';

export function usePopoverMessage() {
  const [popoverMessage, setPopoverMessage] = useState<string | undefined>(undefined);

  const showPopoverMessage = (message: string) => {
    setPopoverMessage(message);
  };

  const hidePopoverMessage = () => {
    setPopoverMessage(undefined);
  };

  return {
    hidePopoverMessage,
    popoverMessage,
    showPopoverMessage,
  };
}
