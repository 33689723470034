import { IGroupServiceSession } from '../../../../interfaces/service-interfaces';
import React from 'react';
import moment from 'moment-timezone';
import { GridRow } from '../../../../common-components/grids';
import { Col, Popover } from 'antd';
import { GhostButton, HyperlinkButton } from '../../../../common-components/buttons';
import { Text } from '../../../../common-components/typography';
import { StatusTag } from '../../../../common-components/tags';
import { ActionMenu, ActionMenuItem } from '../../../../common-components/action-menu';
import { BookingStatus } from '../../../../utilities/enum-utils';
import { Link } from 'react-router-dom';

interface CustomerScheduleRowProps {
  session: IGroupServiceSession;
  timezone: string;
  openRemoveModal: (session, isRemoveSelectedBookingOnly?: boolean) => void;
}

const CustomerScheduleRow: React.FC<CustomerScheduleRowProps> = ({ session, timezone, openRemoveModal }) => {
  const date = moment.tz(session.startDateTime, timezone);
  const startDate = moment.tz(session.startDateTime, timezone);
  const endTime = moment.tz(session.endDateTime, timezone);
  const capacityStr = session.capacity ? `${session.bookedCapacity}/${session.capacity}` : '-';

  return (
    <GridRow>
      <Col span={6}>
        <HyperlinkButton>
          <Link to={`/group-service/${session.serviceId}/session/details/${session.serviceDateTimeId}`}>
            {date.format('ddd, D MMM YYYY')}
          </Link>
        </HyperlinkButton>
        <br />
        <Text type="secondary">
          {startDate.format('HH:mm A')} - {endTime.format('HH:mm A')}
        </Text>
      </Col>
      <Col span={4}>
        <StatusTag status={session.status} size="small" />
      </Col>
      <Col span={4}>{capacityStr}</Col>
      <Col span={6}>{session.scheduleName}</Col>
      <Col span={4} className="text-align-right pr-large">
        <Popover
          placement="bottomRight"
          className="cursor-pointer"
          content={
            <ActionMenu>
              {[BookingStatus.PENDING, BookingStatus.ACCEPTED, BookingStatus.CONFIRMED].includes(session.status) && (
                <ActionMenuItem text="Remove from this session" onClick={() => openRemoveModal(session, true)} />
              )}
              <ActionMenuItem text="Remove from schedule" onClick={() => openRemoveModal(session, false)} />
            </ActionMenu>
          }
        >
          <div
            className="bordered border-standard-gray rounded text-align-center"
            style={{ width: '28px', height: '28px', float: 'right' }}
          >
            <GhostButton icon="ellipsis" size="small" paddingSize="x-small" />
          </div>
        </Popover>
      </Col>
    </GridRow>
  );
};

export default CustomerScheduleRow;
