import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const reset: CSS = {
  margin: 0,
  padding: 0,
  overflowWrap: 'break-word',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  textRendering: 'optimizeLegibility',
};

export const heading: CSS = {
  color: '$black',
  fontFamily: '$primary',
};

export const variants = css({
  variants: {
    level: {
      '1': {
        fontSize: '$font500',
        lineHeight: '$leading700',

        '@medium': {
          fontSize: '$font700',
          lineHeight: '$leading900',
        },
      },
      '2': {
        fontSize: '$font400',
        lineHeight: '$leading600',

        '@medium': {
          fontSize: '$font500',
          lineHeight: '$leading800',
        },
      },
      '3': {
        fontSize: '$font200',
        lineHeight: '$leading600',

        '@medium': {
          fontSize: '$font300',
          lineHeight: '$leading700',
        },
      },
      '4': {
        fontSize: '$font100',
        lineHeight: '$leading500',

        '@medium': {
          lineHeight: '$leading600',
        },
      },
      '5': {
        fontSize: '$font75',
        lineHeight: '$leading300',
      },
      '6': {
        fontSize: '$font50',
        lineHeight: '$leading100',

        '@medium': {
          lineHeight: '$leading200',
        },
      },
    },
    emphasis: {
      semibold: {
        fontWeight: '$semibold',
      },
      bold: {
        fontWeight: '$bold',
      },
      regular: {
        fontWeight: '$normal',
      },
      light: {
        fontWeight: '$light',
      },
    },
    uppercase: {
      true: {
        textTransform: 'uppercase',
      },
    },
    capitalize: {
      true: {
        textTransform: 'capitalize',
      },
    },
    lowercase: {
      true: {
        textTransform: 'lowercase',
      },
    },
  },
});

export type HeadingVariants = Stitches.VariantProps<typeof variants>;
