import { Text } from 'common-components/typography';
import { ProgressBar } from '@blueprintjs/core';
import React from 'react';

export function ModalLoading({ text }) {
  return (
    <div className="p-large bordered rounded-big shadow-box">
      <div className="mb-12">
        <Text color="secondary">Fetching data...</Text>
      </div>
      <ProgressBar />
    </div>
  );
}
