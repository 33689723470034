import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';
import { SubTitle, Text } from 'common-components/typography';
import { Col, Row } from 'antd';
import CommonUtils from 'utilities/common-utils';

interface IViewBudgetTrackingDetailModalProps {
  closeEdit: () => void;
  isOpen: boolean;
  selectedTracking: any;
}

class ViewBudgetTrackingDetailModal extends Component<IViewBudgetTrackingDetailModalProps> {
  render() {
    const { isOpen, closeEdit, selectedTracking } = this.props;

    return (
      <>
        <ActionModal
          isOpen={isOpen}
          onClose={closeEdit}
          title={
            <>
              View <b>budget</b> details
            </>
          }
          showCloseButton={true}
          width={'x2-large'}
        >
          <Text>View the details of this service schedule and track spending and estimate future spending.</Text>
          <div className={'mt-x-large'}>
            <Row className="pb-small">
              <Col span={7} className="ph-medium">
                <SubTitle>Service/Schedule</SubTitle>
              </Col>
              <Col span={4} className="ph-medium text-align-right">
                <SubTitle>Quote amount</SubTitle>
              </Col>
              <Col span={4} className="ph-medium text-align-right">
                <SubTitle>Amount spent</SubTitle>
              </Col>
              <Col span={5} className="ph-medium text-align-right">
                <SubTitle>Forecasted spend</SubTitle>
              </Col>
              <Col span={4} className="ph-medium text-align-right">
                <SubTitle>Estimated diff.</SubTitle>
              </Col>
            </Row>
            {selectedTracking && (
              <>
                <Row className={'pv-small text-weight-bold'}>
                  <Col span={7} className={'ph-medium'}>
                    {selectedTracking.serviceName}
                  </Col>
                  <Col span={4} className={'text-align-right ph-medium'}>
                    {CommonUtils.formatPrice(selectedTracking.quoteAmount)}
                  </Col>
                  <Col span={4} className={'text-align-right ph-medium'}>
                    {CommonUtils.formatPrice((selectedTracking.spentAmount || 0) + (selectedTracking.amountSpent || 0))}
                  </Col>
                  <Col span={5} className={'text-align-right ph-medium'}>
                    {CommonUtils.formatPrice(selectedTracking.forecastedSpend)}
                  </Col>
                  <Col span={4} className={'text-align-right ph-medium'}>
                    <Text
                      color={
                        selectedTracking.estimatedDifference > 0
                          ? 'green'
                          : selectedTracking.estimatedDifference < 0
                          ? 'red-dark'
                          : 'black'
                      }
                    >
                      {selectedTracking.estimatedDiff > 0 && '+'}
                      {CommonUtils.formatPrice(selectedTracking.estimatedDifference)}
                    </Text>
                  </Col>
                </Row>
                <Row className={'bg-quaternary bordered p-medium mt-small'}>
                  <Row className={'pb-medium'}>
                    <Col span={16} />
                    <Col span={4} className={'text-align-right ph-medium'}>
                      <SubTitle>Amount spent</SubTitle>
                    </Col>
                    <Col span={4} className={'text-align-right ph-medium'}>
                      <SubTitle>Forecast spend</SubTitle>
                    </Col>
                  </Row>
                  <div className={'bg-white rounded-big bordered border-standard-gray'}>
                    {_.map(selectedTracking.supportCategories, (category) => {
                      return (
                        <Row className="pv-small">
                          <Col span={16} className={'ph-medium'}>
                            <Text color={'green'}>{category.supportType}</Text>
                            <br />
                            <Text>{category.supportCategoryName}</Text>
                          </Col>
                          <Col span={4} className={'text-align-right ph-medium'}>
                            {CommonUtils.formatPrice((category.spentAmount || 0) + (category.amountSpent || 0))}
                          </Col>
                          <Col span={4} className={'text-align-right ph-medium'}>
                            {CommonUtils.formatPrice(category.forecastedSpend)}
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                  <Row className={'mt-small pv-small bg-tertiary'}>
                    <Col span={16} className={'ph-medium'}>
                      <Text weight={'bold'}>Total</Text>
                    </Col>
                    <Col span={4} className={'text-align-right ph-medium'}>
                      {CommonUtils.formatPrice(selectedTracking.spentAmount + selectedTracking.amountSpent)}
                    </Col>
                    <Col span={4} className={'text-align-right ph-medium'}>
                      {CommonUtils.formatPrice(selectedTracking.forecastedSpend)}
                    </Col>
                  </Row>
                </Row>
              </>
            )}
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.props.closeEdit} className="mr-medium">
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </>
    );
  }
}

export default ViewBudgetTrackingDetailModal;
