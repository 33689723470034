import { FaGlobeAsia } from 'react-icons/fa';
import { Text } from 'common-components/typography';
import moment from 'moment-timezone';
import { Icon } from 'antd';
import React, { Component } from 'react';
import { Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { timeZone } from 'interfaces/timezone-type';
import { TimezoneInput } from 'common-components/timezone';
import { TimezoneSelectorMode } from 'utilities/enum-utils';

function TimezoneSelectorItem({ name, description, icon, onClick, value }) {
  return (
    <MenuItem
      label={''}
      className="hover-bg-blue-lightest"
      text={
        <div className="flex-row">
          <div className="mt-x-small">
            <Icon type={icon} className="ml-small mr-medium text-color-tertiary text-size-x2-large" />
          </div>
          <div>
            <Text size="x-large">{name}</Text>
            <br />
            <Text size="regular" color={'secondary'}>
              {description}
            </Text>
          </div>
        </div>
      }
      onClick={() => onClick(value)}
    />
  );
}

interface ITimezoneSelectorProps {
  onChange?: (type, value) => void;
  mode?: string;
  userTimezone: timeZone;
  customTimezone?: timeZone;
  bordered?: boolean;
}

interface ITimezoneSelectorState {
  mode: string;
  customTimezone: timeZone;
  isPopoverOpen: boolean;
  isModalOpen: boolean;
}

export default class TimezoneSelector extends Component<ITimezoneSelectorProps, ITimezoneSelectorState> {
  state = {
    mode: this.props.mode ? this.props.mode : TimezoneSelectorMode.MyTimezone,
    customTimezone: this.props.customTimezone ? this.props.customTimezone : this.props.userTimezone,
    isPopoverOpen: false,
    isModalOpen: false,
  };

  private _openPopover = () => {
    this.setState({ isPopoverOpen: true });
  };
  private _closePopover = () => {
    this.setState({ isPopoverOpen: false });
  };

  private _openModal = () => {
    this.setState({ isModalOpen: true });
  };
  private _closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  private _setMode = (mode) => {
    this.props.onChange('mode', mode);
    this.setState({ mode });
  };

  private _setTimezone = (customTimezone) => {
    this.setState({ customTimezone });
  };

  private _saveCustomTimezone = () => {
    this.props.onChange('customTimezone', this.state.customTimezone);
    this._closeModal();
  };
  private _handleSelectItem = (mode) => {
    console.log(mode);
    this._setMode(mode);
    if (mode === TimezoneSelectorMode.CustomTimezone) {
      this._openModal();
    }
  };

  render() {
    const { userTimezone, bordered = true } = this.props;
    const { mode, customTimezone, isModalOpen, isPopoverOpen } = this.state;

    const popoverContent = (
      <Menu>
        <MenuDivider title={<Text>Switch timezone display to</Text>} />

        <TimezoneSelectorItem
          name={'My preferred timezone'}
          description={
            <>
              Show dates in <b>your preferred timezone</b> format
            </>
          }
          icon={'global'}
          onClick={this._handleSelectItem}
          value={TimezoneSelectorMode.MyTimezone}
        />

        <TimezoneSelectorItem
          name={'Booking timezone'}
          description={
            <>
              Show dates in the <b>booking's timezone</b>
            </>
          }
          icon={'clock-circle'}
          onClick={this._handleSelectItem}
          value={TimezoneSelectorMode.BookingTimezone}
        />

        <TimezoneSelectorItem
          name={'Custom'}
          description={
            <>
              Select a <b>custom</b> timezone
            </>
          }
          icon={'sliders'}
          onClick={this._handleSelectItem}
          value={TimezoneSelectorMode.CustomTimezone}
        />
      </Menu>
    );

    return (
      <>
        {/* Main component with popover */}
        <Popover
          content={popoverContent}
          popoverClassName={'mb-medium'}
          position={'bottom'}
          isOpen={isPopoverOpen}
          onClose={this._closePopover}
        >
          <div
            className={`flex-row align-center inline-block rounded hover-bg-tertiary cursor-pointer select-none ${
              bordered && 'bordered'
            }`}
            onClick={this._openPopover}
          >
            <div className="inline-block ph-x-small  mr-x-small">
              <FaGlobeAsia className="text-size-small text-color-tertiary" />
            </div>
            <Text color="secondary" weight="bold" size="regular">
              {mode === TimezoneSelectorMode.MyTimezone
                ? 'My preferred timezone'
                : mode === TimezoneSelectorMode.BookingTimezone
                ? 'Booking timezone'
                : 'Custom'}
            </Text>
            {mode === TimezoneSelectorMode.MyTimezone && (
              <Text size="small" color="secondary" className="">
                {' '}
                - UTC
                {moment.tz(userTimezone).format('Z')}
              </Text>
            )}
            {mode === TimezoneSelectorMode.CustomTimezone && (
              <Text size="small" color="secondary" className="">
                {' '}
                - UTC
                {moment.tz(customTimezone).format('Z')}
              </Text>
            )}

            <Icon type="caret-down" className="ml-small mr-small text-size-small" />
          </div>
        </Popover>

        {/* Action Modal for custom timezone */}
        <ActionModal
          isOpen={isModalOpen}
          width={'small'}
          title={'Custom timezone'}
          verticalAlignment={'high'}
          onClose={this._closeModal}
        >
          <div className="mb-large">
            <Text>Select a custom timezone to apply to this view.</Text>
            <br />
            <br />
            <Text weight="bold" size="regular">
              Timezone
            </Text>
            <br />
            <TimezoneInput value={customTimezone} onChange={this._setTimezone} usePortal={false} />
          </div>

          <ActionModalFooter align="right">
            <GhostButton size="large" className="mr-medium" onClick={this._closeModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this._saveCustomTimezone}>
              Apply
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </>
    );
  }
}
