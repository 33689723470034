import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Text } from '@good/components';
import { Plus } from '@good/icons';

import { WorkTypeCategory } from 'utilities/enum-utils';
import useWorkTypePermissions from './use-work-type-permissions';

type AddWorkLeaveTypeInlineButtonProps = {
  category: WorkTypeCategory;
  addWorkLeaveType?: (() => void) | undefined;
  className?: string;
  style?: React.CSSProperties;
};

function AddWorkLeaveTypeInlineButton({
  category,
  addWorkLeaveType,
  className,
  style,
}: AddWorkLeaveTypeInlineButtonProps): JSX.Element | null {
  const { t } = useTranslation('', { keyPrefix: `accountManagement.workLeaveTypesPage.table.${category}` });
  const { canCreate } = useWorkTypePermissions();
  const { pinc762Stage2PayCodeConfigurationWithArchive } = useFlags();
  const shouldShow = Boolean(
    category === WorkTypeCategory.Leave &&
      canCreate &&
      addWorkLeaveType &&
      pinc762Stage2PayCodeConfigurationWithArchive,
  );

  if (!shouldShow) {
    return null;
  }

  return (
    <button
      className={['align-center inline-flex cursor-pointer justify-center', className].join(' ')}
      style={style}
      onClick={addWorkLeaveType}
    >
      <Text className="text-accent" asChild>
        <Plus />
      </Text>
      <Text className="font-bold text-accent">{t('add')}</Text>
    </button>
  );
}

export default AddWorkLeaveTypeInlineButton;
