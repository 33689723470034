import { Form, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { SubTitle, Text, Title } from 'common-components/typography';
import { IAddWorkflowTemplateWizard, IWorkflowTemplate } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';

interface IWorkflowTemplateStepNameSectionProps extends FormComponentProps {
  addWorkflowTemplateWizard: IAddWorkflowTemplateWizard | IWorkflowTemplate;
  isEdit?: boolean;
  isEditing?: boolean;
  onSubmit(values: any): void;
  onClose?: () => void;
  doCheckWorkflowTemplateNameExist: typeof dispatch.workflowStore.doCheckWorkflowTemplateNameExist;
}

interface IWorkflowTemplateStepNameSectionState {
  isChecking: boolean;
  isExisted: boolean;
}

class WorkflowTemplateStepNameSection extends PureComponent<
  IWorkflowTemplateStepNameSectionProps,
  IWorkflowTemplateStepNameSectionState
> {
  state = {
    isChecking: false,
    isExisted: false,
  };
  private _validateBeforeNextStep = async () => {
    const { form } = this.props;

    this.setState({ isChecking: true });

    const isNameExist = await this._checkWorkflowTemplateNameExist(form.getFieldValue('name'));

    if (!isNameExist) {
      form.validateFields((errors, values) => {
        if (!errors) {
          this.props.onSubmit({
            name: values.name.trim(),
            description: values.description ? values.description.trim() : '',
          });
        }
      });
    }
  };

  private _nameInputValidator = (rule, value, callback) => {
    try {
      this.setState({ isExisted: false, isChecking: false });
      if (!value || _.trim(value).length === 0) {
        throw Error('Please enter a name');
      }
      if (_.trim(value).length < 2) {
        throw Error('Please enter at least 2 characters');
      }
      if (_.trim(value).length > 50) {
        throw Error('Please enter less than 50 characters');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _checkWorkflowTemplateNameExist = async (name) => {
    const { doCheckWorkflowTemplateNameExist, addWorkflowTemplateWizard, isEdit } = this.props;

    if (name) {
      try {
        if (addWorkflowTemplateWizard && isEdit && name === addWorkflowTemplateWizard.name) {
          this.setState({ isChecking: false, isExisted: false });
          return false;
        }
        const response: any = await doCheckWorkflowTemplateNameExist({ name });

        this.setState({ isChecking: false, isExisted: response.isExisted });

        return response.isExisted;
      } catch (e) {
        this.setState({ isChecking: false });
      }
      return false;
    }

    this.setState({ isChecking: false, isExisted: false });
    return false;
  };

  render() {
    const { addWorkflowTemplateWizard, form, isEdit, isEditing } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        <div className={isEdit ? '' : `bg-white p-large width-full rounded-big bordered shadow-container`}>
          {!isEdit && (
            <>
              <Title level={4} className="mb-large" weight="regular" color="gray-13">
                Create a workflow
              </Title>
              <Text weight="regular" color="gray-13">
                Add the basic details of the workflow here. You cannot change a workflow name once you have created it.
              </Text>
            </>
          )}

          <div className="mv-medium">
            <SubTitle>Workflow Name</SubTitle>
            <Form.Item
              className="mv-none"
              help={this.state.isExisted ? 'The workflow name must be unique' : undefined}
              validateStatus={this.state.isExisted ? 'error' : undefined}
            >
              {getFieldDecorator('name', {
                initialValue: addWorkflowTemplateWizard ? addWorkflowTemplateWizard.name : '',
                rules: [{ validator: this._nameInputValidator }],
              })(<Input placeholder="Workflow name here...." maxLength={50} />)}
            </Form.Item>
          </div>

          <SubTitle>WORKFLOW DESCRIPTION (Optional)</SubTitle>
          <Form.Item className="mv-none">
            {getFieldDecorator('description', {
              initialValue: addWorkflowTemplateWizard ? addWorkflowTemplateWizard.description : '',
              rules: [{ max: 2000, message: 'Please enter less than 2000 characters.' }],
            })(<TextArea placeholder="Workflow description here..." autoSize={{ minRows: 5 }} />)}
          </Form.Item>
        </div>

        <div className={`flex justify-end ${isEdit ? 'mt-x2-large' : 'mt-large'}`}>
          {isEdit && (
            <SecondaryButton className="mr-medium" size="large" onClick={this.props.onClose}>
              Cancel
            </SecondaryButton>
          )}

          <PrimaryButton
            className="mr-large"
            size="large"
            disabled={form.getFieldError('name')}
            onClick={this._validateBeforeNextStep}
            loading={isEditing || this.state.isChecking}
          >
            {isEdit ? 'Save' : 'Next'}
          </PrimaryButton>
        </div>
      </>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCheckWorkflowTemplateNameExist: dispatch.workflowStore.doCheckWorkflowTemplateNameExist,
});

export default connect(
  null,
  mapDispatch,
)(Form.create<IWorkflowTemplateStepNameSectionProps>()(WorkflowTemplateStepNameSection));
