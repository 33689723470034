import { twMerge } from '../utils';

export const sharedInputStyles = twMerge(
  'w-full',
  'h-11',
  'bg-base',
  'border',
  'border-gray',
  'hover:border-gray-dark-1',
  'rounded-md',
  'text-b-sm',
  'leading-b-sm',
  'text-strong',
  'placeholder:text-weak',
  'py-[10px]',
  'px-3',
  'outline-none',
  'transition-colors',
  'focus:border-accent',
  'focus-visible:outline-accent',
  'focus-visible:outline-offset-[-1px]',
  'aria-invalid:border-critical',
  'aria-invalid:hover:border-critical',
  'aria-invalid:focus:border-critical',
  'aria-invalid:focus-visible:outline-critical',
  'disabled:bg-[#F7F7F6]',
  'disabled:border-[#F7F7F6]',
);
