import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseButton, Combobox, Input, InputBase, Text, useCombobox } from '@good/ui/core';
import { ChevronDown } from '@good/icons';
import { usePayPeriodFormContext } from '../formContext';

type Item = {
  key: string;
  value: string;
  description: string;
};

export const SelectTime = () => {
  const { t } = useTranslation('', { keyPrefix: 'accountManagement.payPeriodPage.durations' });
  const form = usePayPeriodFormContext();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const onDurationSelect = (duration?: string) => {
    form.setFieldValue('payPeriodDuration', duration ?? null);
  };

  const payPeriodDurations: Item[] = [
    { key: 'WEEKLY', value: t('weeklyValue'), description: t('weeklyDescription') },
    { key: 'FORTNIGHTLY', value: t('fortnightlyValue'), description: t('fortnightlyDescription') },
    { key: 'TWICEMONTHLY', value: t('twiceMonthlyValue'), description: t('twiceMonthlyDescription') },
    { key: 'FOURWEEKLY', value: t('fourWeeklyValue'), description: t('fourWeeklyDescription') },
    { key: 'MONTHLY', value: t('monthlyValue'), description: t('monthlyDescription') },
    { key: 'QUARTERLY', value: t('quarterlyValue'), description: t('quarterlyDescription') },
  ];

  const selectedOption = payPeriodDurations.find((item) => item.key === form.values.payPeriodDuration);

  const onDurationClear = () => {
    form.setFieldValue('payPeriodDuration', null);
  };
  const SelectOption = ({ value, description }: Item) => (
    <>
      <Text fz='sm' fw={500}>
        {value}
      </Text>
      <Text fz='xs' opacity={0.6}>
        {description}
      </Text>
    </>
  );

  const options = payPeriodDurations.map((item) => (
    <Combobox.Option value={item.key} key={item.key}>
      <SelectOption {...item} />
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(selectedValue) => {
        onDurationSelect(selectedValue);
        combobox.closeDropdown();
      }}
      size='md'
    >
      <Combobox.Target>
        <InputBase
          component='button'
          type='button'
          pointer
          rightSection={
            selectedOption ? (
              <CloseButton
                size='sm'
                onClick={() => onDurationClear()}
                onMouseDown={(event) => event.preventDefault()}
                aria-label='Clear value'
              />
            ) : (
              <ChevronDown />
            )
          }
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents={!selectedOption ? 'none' : 'all'}
          multiline
          label={t('payPeriodDuration')}
          size='md'
          w='324'
        >
          {selectedOption ? (
            <SelectOption {...selectedOption} />
          ) : (
            <Input.Placeholder>{t('placeholder')}</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};


