import React, { Component } from 'react';
import { IGroupServiceNote } from 'interfaces/service-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

interface IDeleteGroupNoteModal {
  isOpen: boolean;
  targetNote: IGroupServiceNote;
  onClose: any;
  onDeleteNoteAction: any;
}

export class DeleteGroupNoteModal extends Component<IDeleteGroupNoteModal> {
  onCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  onDelete = () => {
    const { onDeleteNoteAction, targetNote, onClose } = this.props;

    onDeleteNoteAction({ targetNote });
    onClose();
  };

  render() {
    let { isOpen, targetNote, onClose } = this.props;
    return (
      <ActionModal isOpen={isOpen} onClose={onClose} title={'Delete note'}>
        <div className="mt-small">
          <Text>
            You are about to delete this note from this booking.
            <br />
            <br />
            Are you sure you want to continue?
          </Text>
        </div>
        <ActionModalFooter align={'right'}>
          <SecondaryButton onClick={this.onCancel} className="mr-medium" size="large">
            Cancel
          </SecondaryButton>

          <PrimaryButton color="red" onClick={this.onDelete} size="large">
            Delete
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}
