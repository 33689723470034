import { ITimesheetShiftItem } from 'interfaces/booking-interfaces';
import apiClient from 'utilities/api-client';

export type ITimesheetsRequest = {
  endDate: Date;
  startDate: Date;
  serviceIds?: string[];
  serviceDepartmentIds?: string[];
  teamMemberUserIds?: string[];
};

export type IFetchTimesheetsResponse = {
  status: number;
  data: ITimesheetShiftItem[];
};

export async function fetchTimesheets(request?: ITimesheetsRequest): Promise<IFetchTimesheetsResponse> {
  return await apiClient.post(`api/portal/timesheets/shifts`, request);
}
