import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';

// eslint-disable-next-line no-shadow-restricted-names
// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
export const Infinity = forwardRef(function Infinity(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.75 12a4.25 4.25 0 0 0 6.8 3.4.75.75 0 1 1 .9 1.2 5.75 5.75 0 1 1 2.3-4.6 4.25 4.25 0 1 0 1.7-3.4.75.75 0 0 1-.901-1.2A5.75 5.75 0 1 1 11.25 12a4.25 4.25 0 1 0-8.5 0Z'
        fill='currentColor'
      />
    </Icon>
  );
});
