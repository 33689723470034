import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';
import NDISLineItemGrid from 'common-components/line-items/NDISLineItemGrid';

interface IServiceItemViewModalProps {
  onClose: any;
  isOpen: boolean;
  selectedServiceLineItems?: Array<any>;
  paymentSourceType: string;
}

interface IServiceItemViewModalState {}

const unitOptions = [
  { value: 'EA', label: 'Each' },
  { value: 'H', label: 'Hourly' },
  { value: 'D', label: 'Daily' },
  { value: 'WK', label: 'Weekly' },
  { value: 'MON', label: 'Monthly' },
  { value: 'YR', label: 'Yearly' },
];

class ServiceItemViewModal extends Component<IServiceItemViewModalProps, IServiceItemViewModalState> {
  state = {};

  private _onClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, selectedServiceLineItems, paymentSourceType } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        onClose={this._onClose}
        width={'x2-large'}
        title={
          <>
            View <b>line items</b> of this <b>Service</b>
          </>
        }
      >
        <div className="mb-large">
          <Text>
            The following <b>line items</b> will be charged for this service in this <b>Service Agreement</b>
          </Text>
        </div>

        {selectedServiceLineItems && (
          <NDISLineItemGrid
            lineItems={selectedServiceLineItems}
            displayMode={'VIEW'}
            isServiceAgreementLineItems={true}
            paymentSourceType={paymentSourceType}
          />
        )}

        <ActionModalFooter align="right">
          <PrimaryButton size="large" className="mr-medium" onClick={this._onClose}>
            Close
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default ServiceItemViewModal;
