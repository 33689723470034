import { IBooking } from 'interfaces/booking-interfaces';
import { CancelRecurringBooking } from './schema';

function getNumberOfCancelledBookings(
  booking: IBooking,
  cancelRecurringBooking: CancelRecurringBooking,
  numberOfRecurringBookingsToDelete: number,
): number | undefined {
  if (!booking.isRecurring) {
    return 1;
  }

  return {
    this: 1,
    all: booking.numberOfBookingLeft + 1,
    specified: numberOfRecurringBookingsToDelete + 1,
  }[cancelRecurringBooking];
}

export default getNumberOfCancelledBookings;
