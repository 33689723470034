import { useCallback } from 'react';

import { AddressInputFieldErrors, AddressInputValues, FieldError, ValidationFns, ValidationRules } from '../types';
import { emptyFieldErrors } from '../defaults';
import { hasErrors } from './use-errors';

type ValidationRulesFlat = {
  fieldName: keyof AddressInputFieldErrors;
  rules: ValidationFns;
}[];

const flattenValidationRules = (validationRules: Partial<ValidationRules>): ValidationRulesFlat => {
  return Object.entries(validationRules).map(([field, rules]) => {
    return {
      fieldName: field as keyof AddressInputFieldErrors,
      rules,
    };
  });
};

const filterValidationRules = (
  validationRules: ValidationRulesFlat,
  fieldName: keyof AddressInputFieldErrors | undefined,
  isManualInput: boolean,
): ValidationRulesFlat => {
  if (isManualInput) {
    validationRules = validationRules.filter((rule) => rule.fieldName.startsWith('manualInput.'));
  } else {
    validationRules = validationRules.filter((rule) => !rule.fieldName.startsWith('manualInput.'));
  }

  if (!fieldName) {
    return validationRules;
  }

  return validationRules.filter((rule) => rule.fieldName === fieldName);
};

const applyValidationRules = (
  rulesFlat: ValidationRulesFlat,
  inputValues: AddressInputValues,
  onValidationError?: (fieldName: keyof AddressInputFieldErrors, fieldError: FieldError) => void,
) =>
  rulesFlat.forEach((validationRule) => {
    const { fieldName, rules } = validationRule;
    Object.entries(rules).forEach(([ruleName, rule]) => {
      const ruleResult = rule(inputValues[fieldName]);

      if (ruleResult !== true) {
        const fieldError: FieldError = { type: ruleName, message: ruleName };

        if (typeof ruleResult === 'string') {
          fieldError.message = ruleResult;
        }

        onValidationError?.(fieldName, fieldError);
      }
    });
  });

export function useValidate(
  addInternalError: (field: keyof AddressInputFieldErrors, error: FieldError) => void,
  clearInternalErrors: (field?: keyof AddressInputFieldErrors) => void,
  inputValues: AddressInputValues,
  validationRulesProp: Partial<ValidationRules>,
  isManualInput: boolean,
  hasValidAutocompleteResult: boolean,
): (fieldName?: keyof AddressInputFieldErrors) => { errors: AddressInputFieldErrors; hasErrors: boolean } {
  const validate = useCallback(
    (fieldName?: keyof AddressInputFieldErrors) => {
      clearInternalErrors(fieldName);
      let validationRules = flattenValidationRules(validationRulesProp);
      validationRules = filterValidationRules(validationRules, fieldName, isManualInput);

      const errors: AddressInputFieldErrors = { ...emptyFieldErrors };

      if (!isManualInput && !hasValidAutocompleteResult) {
        const invalidResultFieldError: FieldError = { type: 'INVALID_RESULT', message: 'Address not found' };
        errors.automaticInput = invalidResultFieldError;
        addInternalError('automaticInput', invalidResultFieldError);
      }

      if (!validationRules.length) {
        return {
          errors,
          hasErrors: hasErrors(errors),
        };
      }

      applyValidationRules(validationRules, inputValues, (fieldName, fieldError) => {
        errors[fieldName] = fieldError;
        addInternalError(fieldName, fieldError);
      });

      return {
        errors,
        hasErrors: hasErrors(errors),
      };
    },
    [
      addInternalError,
      clearInternalErrors,
      hasValidAutocompleteResult,
      inputValues,
      isManualInput,
      validationRulesProp,
    ],
  );

  return validate;
}
