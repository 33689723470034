import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { zodResolver } from "@hookform/resolvers/zod";
import { reactTrpc } from "utilities/react-trpc";
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { findDuplicateIndices } from 'utilities/array-utils';
import z from "zod";
import ndisHelper from 'variables/data/ndis-helper';
import { Button, Title, Alert } from '@good/ui/core';
import { Info, Plus, Trash } from '@good/icons'
import { ControlledPriceInput } from './shared/controlled-price-input';
import { ControlledQuantityInput } from './shared/controlled-quantity-input';
import { HiddenField } from './shared/hidden-field';
import { MultiLineCombobox } from './shared/multiline-combobox';
import { TFunction } from 'react-i18next';
import { UseQueryResult } from '@tanstack/react-query';
import { InLineIconButton } from './shared/inline-icon-button';
import { RecurringBookingPattern } from 'utilities/enum-utils';

type BillingSubscriptionLineItem = {
  billingSubscriptionLineItemId?: string;
  supportItemNumber: string;
  claimType: string;
  paymentMethod: string;
  unit: string;
  price: string;
  qty: string;
}

type SubscriptionLineItemsForm = {
  billingSubscriptionId: string;
  subscriptionLineItems: BillingSubscriptionLineItem[]
}

export type SubscriptionBillingFormProps = {
  billingSubscriptionId?: string;
  existingLineItems?: BillingSubscriptionLineItem[] | null;
  toggleModal: Dispatch<SetStateAction<boolean>>;
  refetch: (options: { throwOnError: boolean, cancelRefetch: boolean }) => Promise<UseQueryResult>
  customerId: string;
  serviceProviderId: string;
  userServiceAgreementServiceId: string;
}

const SubscriptionBillingFormSchema = z.object({
  subscriptionLineItems: z.array(
    z.object({
      supportItemNumber: z.string().min(1, { message: 'Support Item must be selected' }),
      price: z.coerce.number().gt(0, { message: 'Price must be greater than zero'}).transform((val) => val.toString()),
      qty: z.coerce.number().gt(0, { message: 'Quantity must be greater than zero'}).transform((val) => val.toString()),
      billingSubscriptionLineItemId: z.string().or(z.undefined()),
      claimType: z.string(),
      paymentMethod: z.string(),
      unit: z.string(),
    })
  ).superRefine((lineItems, ctx) => {
    findDuplicateIndices(
      lineItems.map(lineItem => lineItem.supportItemNumber)
    )
    .forEach((duplicateIndex) => {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'No duplicate line items allowed.',
        path: [`[${duplicateIndex}].supportItemNumber`]
      });
    })
  })
})

export const claimTypeOptions = [
  { value: 'STD', label: 'Standard Service' },
  { value: 'TRAN', label: 'Travel' },
  { value: 'REPW', label: 'Report Writing' },
  { value: 'NF2F', label: 'Non-face-to-face' },
  { value: 'IRSS', label: 'Irregular SIL Supports' },
];

const unitOptions = [
  { value: 'EA', label: 'Each' },
  { value: 'H', label: 'Hourly' },
  { value: 'WK', label: 'Weekly' },
];

const paymentMethodOptions = [
  { value: 'NDIA', label: 'NDIA Managed' },
  { value: 'SELF', label: 'Self Managed' },
  { value: 'PLAN', label: 'Plan Managed' },
];

export const SubscriptionBillingForm = ({
  existingLineItems,
  billingSubscriptionId,
  serviceProviderId,
  customerId,
  userServiceAgreementServiceId,
  refetch,
  toggleModal,
  t,
}: SubscriptionBillingFormProps &  { t: TFunction; }) => {
    const defaultRowValues = {
    supportItemNumber: '',
    unit: 'EA',
    paymentMethod: 'NDIA',
    qty: '1',
    claimType: 'STD',
    price: '0.00'
  };
  const existingBillingSubscription = existingLineItems?.length
  const form = useForm<SubscriptionLineItemsForm>({
    defaultValues: {
      billingSubscriptionId,
      subscriptionLineItems: existingBillingSubscription ? existingLineItems : [defaultRowValues]
    },
    resolver: zodResolver(SubscriptionBillingFormSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "subscriptionLineItems"
  });
  const createBillingSubscription = reactTrpc.billingSubscriptions.create.useMutation({ onSuccess: () => refetch({ cancelRefetch: true, throwOnError: true }) });
  const editBillingSubscription = reactTrpc.billingSubscriptions.update.useMutation({ onSuccess: () => refetch({ cancelRefetch: true, throwOnError: true }) });

  const onSubmit = async (data: SubscriptionLineItemsForm) => {
    if(existingBillingSubscription) {
      const lineItems = data.subscriptionLineItems.map((lineItem) => ({
        ...lineItem,
        billingSubscriptionLineItemId: lineItem.billingSubscriptionLineItemId === '' ? undefined : lineItem.billingSubscriptionLineItemId,
      }))

      await editBillingSubscription.mutateAsync({
        billingSubscriptionId: billingSubscriptionId as unknown as string,
        serviceProviderId,
        userServiceAgreementServiceId,
        customerUserId: customerId,
        lineItems,
      });
    }
    else {
      await createBillingSubscription.mutateAsync({
        serviceProviderId,
        frequency: RecurringBookingPattern.EveryWeek,
        userServiceAgreementServiceId,
        customerUserId: customerId,
        lineItems: data.subscriptionLineItems,
      })
    }
    toggleModal(false)
  };
  const { data } = reactTrpc.billingSubscriptions.fetchSelectableLineItems.useQuery({
    userServiceAgreementServiceId,
    serviceProviderId,
  });
  const lineItems = useMemo(() => {
    return data?.map((lineItem) => ({
      value: lineItem.supportItemNumber,
      label: [lineItem.supportItemNumber, ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber).SupportItem],
      id: lineItem.supportItemNumber,
    })) ?? [];
  }, [data]);
  const findPrice = useCallback((supportItemNumber: string) => {
    return data?.find((lineItem) => lineItem.supportItemNumber === supportItemNumber)
        ?.price
        .toFixed(2) ?? '0.00'
  }, [data])

  return (
    <div style={{ padding: '10px 20px 10px 15px' }}>
      <Alert icon={<Info />}>
        Any changes to billing will be reflected in the next scheduled charge. Any historic charges already generated will not be affected.
      </Alert>
      <FormProvider {...form}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', marginTop: '20px' }}>
          <div style={{ minWidth: '250px', marginRight: '20px' }}><Title order={5}>{t('lineItemHeaders.lineItems')}</Title></div>
          <div style={{ minWidth: '210px', marginRight: '20px' }}><Title order={5}>{t('lineItemHeaders.claimType')}</Title></div>
          <div style={{ minWidth: '200px', marginRight: '20px' }}><Title order={5}>{t('lineItemHeaders.management')}</Title></div>
          <div style={{ minWidth: '120px', marginRight: '20px' }}><Title order={5}>{t('lineItemHeaders.unit')}</Title></div>
          <div style={{ minWidth: '112px', marginRight: '20px' }}><Title order={5}>{t('lineItemHeaders.price')}</Title></div>
          <div style={{ minWidth: '120px', marginRight: '20px' }}><Title order={5}>{t('lineItemHeaders.quantity')}</Title></div>
          <div style={{ minWidth: '20px', paddingLeft: '16px', marginRight: '20px' }}></div>
        </div>
        <form data-testid="subscription-line-items-form">
          <HiddenField name="billingSubscriptionId" />
          {fields.map((field, index) => (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', marginTop: '5px' }} key={field.id} data-testid={`subscription-billing-item-row-${index}`}>
              <HiddenField name={`subscriptionLineItems.${index}.billingSubscriptionLineItemId`} />
              <MultiLineCombobox
                placeholder="Support Item Number"
                options={lineItems as { value: string; label: [string, string]; id: string; }[]}
                styles={{ minWidth: '250px', marginRight: '20px' }}
                name={`subscriptionLineItems.${index}.supportItemNumber`}
                customOnChange={(value) => {
                  form.setValue(`subscriptionLineItems.${index}.price`, findPrice(value as string));
                } } />
              <MultiLineCombobox
                placeholder="Claim type"
                options={claimTypeOptions}
                styles={{ minWidth: '210px', marginRight: '20px' }}
                name={`subscriptionLineItems.${index}.claimType`} />
              <MultiLineCombobox
                placeholder="Management type"
                options={paymentMethodOptions}
                styles={{ minWidth: '200px', marginRight: '20px' }}
                name={`subscriptionLineItems.${index}.paymentMethod`} />
              <MultiLineCombobox
                placeholder="Unit"
                options={unitOptions}
                styles={{ minWidth: '120px', marginRight: '20px' }}
                name={`subscriptionLineItems.${index}.unit`} />
              <ControlledPriceInput name={`subscriptionLineItems.${index}.price`} style={{ minWidth: '112px', maxWidth: '112px', marginRight: '20px' }} />
              <ControlledQuantityInput name={`subscriptionLineItems.${index}.qty`} style={{ minWidth: '120px', maxWidth: '120px', marginRight: '20px' }} />
              {index > 0 && <span style={{ lineHeight: '31px', minWidth: '20px', cursor: 'pointer' }}>
                <Trash onClick={() => { remove(index); } } data-testid={`remove-row-${index}`} />
              </span>}
            </div>
          ))}
        </form>
        <InLineIconButton
          className="mt-6"
          size="md"
          label={t('addAdditionalLineItems')}
          icon={<Plus />}
          onClick={() => { append(defaultRowValues); } } />
      </FormProvider>
      <br />
      <div style={{ textAlign: 'right' }}>
        <Button onClick={form.handleSubmit(onSubmit)} style={{ marginTop: '15px' }}>Save</Button>
      </div>
    </div>
  )
}
