import React, { Component } from 'react';
import Title from 'antd/lib/typography/Title';
import { Warning } from 'common-components/alerts';
import { Text } from 'common-components/typography';

interface IConnectWarningHeaderProps {
  className?: string;
}

class ConnectWarningHeader extends Component<IConnectWarningHeaderProps, any> {
  state = {};

  render() {
    const { className = '' } = this.props;
    return (
      <div className={className}>
        <Title level={4}>
          Get the full benefit of GoodHuman by connecting your internal record to your Customer’s account!
        </Title>
        <ul>
          <li>
            Connecting allows Customers to manage their own profile across different providers and keeps your business
            up-to-date with Customer data changes.
          </li>
          <li>
            Connecting does not impact your businesses ability to create a Customer record or store private data
            pertaining to a Customer.
          </li>
        </ul>
        {/*<Warning*/}
        {/*  content={*/}
        {/*    <>*/}
        {/*      <div>*/}
        {/*        <Text>*/}
        {/*          <b>*/}
        {/*            Invite your customers to download the GoodHuman app and connect as soon as possible to ensure your*/}
        {/*            records are accurate.*/}
        {/*          </b>*/}
        {/*          <br />*/}
        {/*          Customer’s profile information will always take precedence to your own. To initiate a connect request*/}
        {/*          you must know the Customer’s NDIS number.*/}
        {/*        </Text>*/}
        {/*      </div>*/}
        {/*      <div>*/}
        {/*        /!* <Text>*/}
        {/*          Visit the{' '}<a>Help Center</a> to learn more.*/}
        {/*        </Text> *!/*/}
        {/*      </div>*/}
        {/*    </>*/}
        {/*  }*/}
        {/*/>*/}
      </div>
    );
  }
}

export default ConnectWarningHeader;
