import React from 'react';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Button } from 'design-components';
import { Paragraph } from 'common-components/typography';
import { Form, Input, Checkbox, Row, Col } from 'antd';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { Alert, Heading, Text } from '@good/components';
import { Door } from '@good/illustrations';
import { TeamMember } from 'interfaces/team-member-interface';
import { WrappedFormUtils } from 'antd/lib/form/Form';

type IPermanentlyRemoveUserModalStep1 = {
  worker: TeamMember;
  form: WrappedFormUtils;
  isSelectFutureDate: boolean;
  isLoading: boolean;
  onCheckBoxChange: (e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => void;
  onChangeRemovalDate: (date: object) => void;
  onClose: () => void;
  onSave: () => Promise<void>;
};

export const PermanentlyRemoveUserModalStep1 = ({
  worker,
  form,
  isSelectFutureDate,
  isLoading,
  onCheckBoxChange,
  onChangeRemovalDate,
  onClose,
  onSave,
}: IPermanentlyRemoveUserModalStep1) => (
  <>
    <div className="mv-medium">
      <div className="mb-medium">
        <Alert tone="critical">
          <Heading>
            This user will be removed from all upcoming shifts, sessions, and rosters that they have been assigned to.
            All private notes will be made visible to the primary owner.
          </Heading>
        </Alert>
      </div>
      <Row className="mb-medium" style={{ display: 'flex', alignItems: 'center' }}>
        <Col span={18} push={5}>
          <div>
            <div className="mb-medium">
              <Paragraph size="regular">
                All historical data related to this user will be maintained. This account can be reinstated at a later
                date.
              </Paragraph>
            </div>
            {!worker.removedOn && (
              <Checkbox onChange={onCheckBoxChange}>
                <Text>I want to remove this user at a future date</Text>
              </Checkbox>
            )}
            {isSelectFutureDate && (
              <Form.Item className="width-full mb-none mt-medium">
                {form.getFieldDecorator('removalDate', {
                  initialValue: new Date(moment().add(1, 'days').format('YYYY/MM/DD HH:mm')),
                  rules: [{ required: true, message: 'Please select removal date' }],
                })(
                  <DatePicker
                    className="gh-datepicker-regular"
                    dateFormat="dd/MM/yyyy"
                    onChange={onChangeRemovalDate}
                    selected={form.getFieldValue('removalDate')}
                    minDate={new Date(moment().add(1, 'days').format('YYYY/MM/DD HH:mm'))}
                  />,
                )}
              </Form.Item>
            )}
          </div>
        </Col>
        <Col span={5} pull={18}>
          <Door />
        </Col>
      </Row>
      <div className="mb-x2-small">
        {/* <FieldLabel text="Removal reason" /> */}
        <Text className="mb-medium text-weight-bold">Removal reason</Text>
      </div>
      <div>
        <Form.Item className="width-full">
          {form.getFieldDecorator('removeReason', {
            rules: [{ required: true, min: 5, message: 'Please enter at least 5 characters.' }],
          })(<Input.TextArea rows={1} placeholder="Enter reason for removing user..." />)}
        </Form.Item>
      </div>
      <ActionModalFooter>
        <Button marginRight="14px" emphasis="quiet" color="black" onClick={onClose} isLoading={isLoading}>
          Cancel
        </Button>
        <Button kind="critical" emphasis="filled" isLoading={isLoading} onClick={onSave}>
          Remove
        </Button>
      </ActionModalFooter>
    </div>
  </>
);
