import React, { useEffect } from 'react';
import PermissionUtils from 'utilities/permission-utils';
import { Button, Breadcrumbs, Header, Heading, Text } from '@good/components';
import { IRootState } from 'stores/rematch/root-store';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CsvImportsListing } from './components/csv-imports-listing';

export const AccountImportDataView = () => {
  const history = useHistory();
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);

  useEffect(() => {
    if (
      !portalUser ||
      !PermissionUtils.validatePermission('ImportPlatformData', portalUser.permissions.permissionRoles)
    )
      history.push('/access-denied');
  });
  if (!portalUser) return null;

  return (
    <>
      <div className='flex-column pb-large relative flex gap-6' id='imports-view'>
        <Breadcrumbs>
          <Link to='/account/landing'>Account management</Link>
          <Text>Import data</Text>
        </Breadcrumbs>

        <div className='flex justify-between align-center'>
          <Header size='xl'>
            <Heading>Import data</Heading>
            <Text>Import your customer, team, and services data from other products into GoodHuman.</Text>
          </Header>

          <Button emphasis='fill' className='flex-shrink-0' onClick={() => history.push('/account/imports/new')}>
            New import
          </Button>
        </div>

        <Header size='md'>
          <Heading>Recent imports</Heading>
        </Header>

        <CsvImportsListing serviceProviderId={portalUser.serviceProviderId} />
      </div>
    </>
  );
};
