import { Text } from 'common-components/typography';
import React from 'react';

import { ServiceAgreementStatus } from 'utilities/enum-utils';

interface IAgreementStatusTagProps {
  status: string;
}

export function AgreementStatusTag({ status }: IAgreementStatusTagProps) {
  let label;
  let bgColor;
  switch (status) {
    case ServiceAgreementStatus.DRAFT:
      label = 'DRAFT';
      bgColor = 'bg-text-tertiary';
      break;
    case ServiceAgreementStatus.UNDER_CUSTOMER_REVIEW:
      label = 'REVIEW';
      bgColor = 'bg-orange-light';
      break;
    case ServiceAgreementStatus.SIGNED:
      label = 'SIGNED';
      bgColor = 'bg-blue-light';
      break;
    case ServiceAgreementStatus.ACTIVE:
      label = 'ACTIVE';
      bgColor = 'bg-green-light';
      break;
    case ServiceAgreementStatus.EXPIRING_SOON:
      label = 'ACTIVE';
      bgColor = 'bg-green-light';
      break;
    case ServiceAgreementStatus.UPCOMING:
      label = 'UPCOMING';
      bgColor = 'bg-orange-light';
      break;
    case ServiceAgreementStatus.EXPIRED:
      label = 'EXPIRED';
      bgColor = 'bg-text-tertiary';
      break;
    default:
      label = '';
      bgColor = 'bg-white';
      break;
  }

  return (
    <div className={`rounded inline ph-x-small ${bgColor}`}>
      <Text lineHeight={100} color={'white'} className="text-uppercase">
        {label}
      </Text>
    </div>
  );
}
