import React, { Component } from 'react';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Text, Title } from 'common-components/typography';
import { Col, Icon, Row, Skeleton, Tabs } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { ProgressBar } from '@blueprintjs/core';
import { RouteComponentProps, Link } from 'react-router-dom';
import DebtorGeneralPanel from './components/DebtorManagementComponents/DebtorGeneralPanel';
import DebtorCustomersPanel from './components/DebtorManagementComponents/DebtorCustomersPanel';
import DebtorInvoicesPanel from './components/DebtorManagementComponents/DebtorInvoicesPanel';
import * as H from 'history';
import { Pill } from '@good/ui/core';
import { Debtor } from './DebtorManagementView';

const { TabPane } = Tabs;

type IDebtorUrlParams = {
  debtorId: string;
};

interface IDebtorDetailsViewProps extends RouteComponentProps<IDebtorUrlParams> {
  history: H.History;
  debtor: typeof state.accountStore.debtor;
  doViewDebtorGeneralInfo: typeof dispatch.accountStore.doViewDebtorGeneralInfo;
}

type IDebtorDetailsViewState = {
  isLoading: boolean;
  activeKey: string;
};

class DebtorDetailsView extends Component<IDebtorDetailsViewProps, IDebtorDetailsViewState> {
  state = {
    isLoading: false,
    activeKey: 'generalinfo',
  };

  async componentDidMount() {
    const { match, doViewDebtorGeneralInfo } = this.props;
    const { params } = match;
    const selectedDebtorId = params.debtorId;
    this.setState({ isLoading: true });
    await doViewDebtorGeneralInfo({ debtorId: selectedDebtorId });

    this.setState({ isLoading: false });
  }
  private changeTab = (key) => {
    this.setState({
      activeKey: key,
    });
  };

  render() {
    const { debtor, history } = this.props;

    const statusTag = (debtor: Debtor) => {
      if (debtor.inactive) {
        return <Pill className='bg-notice-weak text-notice font-semibold'> Inactive </Pill>;
      }
      if (debtor.isArchived) {
        return <Pill className='bg-critical-weak text-critical font-semibold'> Archived </Pill>;
      }
      return '';
    };

    if (this.state.isLoading) {
      return (
        <>
          <div className='item-container'>
            <div className='pb-medium'>
              <Text>Fetching team member details...</Text>
            </div>
            <ProgressBar />
          </div>
          <Skeleton loading={this.state.isLoading} active avatar title paragraph />
        </>
      );
    }

    return (
      <>
        <div className='mb-x7-large pb-large' id='scroll'>
          <Row>
            <div className='mb-medium'>
              <Link to='/account/debtor-management'>
                <HyperlinkButton>
                  <Icon type='left' /> Debtor Listing
                </HyperlinkButton>
              </Link>
            </div>
          </Row>
          <Title level={2}>Debtor Details</Title>
          {debtor && (
            <Row className='mv-medium' type='flex' align='middle'>
              <Col className='mr-medium'>
                <Title level={3} weight='regular' className='mv-none'>
                  {debtor ? debtor.debtorName : ''}
                </Title>
              </Col>
              <Col>{statusTag(debtor)}</Col>
            </Row>
          )}
          <div className='mv-x-large  align-center'>
            <Row className='mt-medium'>
              <Col>
                <Tabs type='card' className='customer-tab' activeKey={this.state.activeKey} onChange={this.changeTab}>
                  <TabPane key='generalinfo' tab='General Information'>
                    <DebtorGeneralPanel history={history} debtor={debtor} />
                  </TabPane>
                  {debtor && !debtor.isArchived && (
                    <TabPane key='customers' tab='Customers'>
                      <DebtorCustomersPanel debtor={debtor} history={this.props.history} />
                    </TabPane>
                  )}

                  <TabPane key='inovices' tab='Invoices'>
                    <DebtorInvoicesPanel debtor={debtor} history={history} />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({ debtor: state.accountStore.debtor });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doViewDebtorGeneralInfo: dispatch.accountStore.doViewDebtorGeneralInfo,
});

export default connect(mapState, mapDispatch)(DebtorDetailsView);
