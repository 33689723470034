import { Text } from 'common-components/typography';
import { SecondaryButton } from 'common-components/buttons';
import { Divider, Icon } from 'antd';
import React from 'react';
import { IGroupServiceSession } from 'interfaces/service-interfaces';

import _ from 'lodash';

import moment from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import SessionStatusTag from 'common-components/tags/SessionStatusTag';
import { timeZone } from 'interfaces/timezone-type';
import { useHistory } from 'react-router-dom';

export function CardPopover({ session, timezone }: { session: IGroupServiceSession; timezone: timeZone }) {
  const history = useHistory();
  if (_.isEmpty(session)) {
    return <></>;
  }

  const {
    serviceName,
    serviceId,
    serviceDateTimeId,
    startDateTime,
    endDateTime,
    scheduleTimeSlotId,
    sessionStatus,
    totalShiftSlot,
    totalAssignedShiftSlot,
    capacity,
    bookedCapacity,
    scheduleName,
  } = session;

  const navigateToService = () => history.push(`/group-service/details/${serviceId}`);
  const navigateToSession = () => history.push(`/group-service/${serviceId}/session/details/${serviceDateTimeId}`);

  const momentStartTime = moment.tz(startDateTime, timezone);
  const momentEndTime = moment.tz(endDateTime, timezone);

  const timeDisplay = (
    <>
      <b>{momentStartTime.format('h:mm A')}</b> - {momentEndTime.format('h:mm A')}
    </>
  );
  const dateDisplay = `${momentStartTime.format('DD MMMM YYYY')}`;

  const duration = CommonUtils.formatDurationString(momentStartTime, momentEndTime);

  return (
    <div className="rounded overflow-hidden" style={{ maxWidth: '320px' }}>
      <div className="p-large flex-column line-height-120" style={{ minWidth: '300px' }}>
        {/* Status */}
        <div className="inline-block mb-small">
          <SessionStatusTag status={sessionStatus} size="small" textSize="regular" />
        </div>

        {/* Service name */}
        <div className="mb-x2-small">
          <Text lineHeight={120} size="x-large">
            {serviceName}
          </Text>
        </div>

        <div className="mb-medium">
          <Text lineHeight={120} size="large" weight="light">
            {scheduleName}
          </Text>
        </div>

        {/* Date  */}
        <div className="mb-medium flex-column line-height-120">
          <Text lineHeight={120} size="x-large">
            {timeDisplay}
          </Text>
          <Text lineHeight={120}>{dateDisplay}</Text>
          <Text lineHeight={135} size="regular" color="secondary">
            {duration}
          </Text>
        </div>

        {/* START Temporary team members count. To expand into avatar displays later */}

        <div className="flex-row">
          <Icon type="team" className="mr-x-small" />

          <Text lineHeight={120} size="regular">
            <b>
              {totalAssignedShiftSlot}/{totalShiftSlot}
            </b>{' '}
            team members
          </Text>
        </div>

        <div className="mb-medium">
          <Icon type="team" className="mr-x-small" />

          <Text lineHeight={120} size="regular">
            <b>
              {bookedCapacity}/{capacity}
            </b>{' '}
            customers
          </Text>
        </div>

        <Divider className="mt-small mb-large" />

        {/* END Temporary team members count. To expand into avatar displays later */}

        {/* Action buttons */}
        <div className="">
          <SecondaryButton className="mr-medium" onClick={navigateToSession}>
            View session
          </SecondaryButton>
          <SecondaryButton onClick={navigateToService}>View service</SecondaryButton>
        </div>

        {/*<Divider className="mv-medium" />*/}

        {/* Team member section */}
        {/*<div>*/}
        {/*  <div className="mb-12">*/}
        {/*    <Text lineHeight={120} size="regular">*/}
        {/*      <b>*/}
        {/*        {totalAssignedShiftSlot}/{totalShiftSlot}*/}
        {/*      </b>{' '}*/}
        {/*      team members*/}
        {/*    </Text>*/}
        {/*  </div>*/}

        {/*  <div className="flex-row mb-small align-center">*/}
        {/*    <Avatar size="default" className="mr-x-small" src="https://randomuser.me/api/portraits/men/46.jpg" />*/}
        {/*    <Avatar size="default" className="mr-x-small" src="https://randomuser.me/api/portraits/men/46.jpg" />*/}
        {/*    <Avatar size="default" className="mr-x-small" src="https://randomuser.me/api/portraits/men/46.jpg" />*/}
        {/*    <Avatar size="default" className="mr-x-small" src="https://randomuser.me/api/portraits/men/46.jpg" />*/}

        {/*    <Text size="regular" className="ml-x-small" color="secondary">*/}
        {/*      4 others*/}
        {/*    </Text>*/}
        {/*  </div>*/}

        {/*  <div className="line-height-100 mb-12">*/}
        {/*    <Text lineHeight={100} size="regular" color="secondary">*/}
        {/*      <b>10</b> confirmed, <b>5</b> pending, <b>1</b> declined*/}
        {/*    </Text>*/}
        {/*  </div>*/}

        {/*  <div>*/}
        {/*    <HyperlinkButton fontSize="regular">*/}
        {/*      See all <b>{totalShiftSlot}</b> team members*/}
        {/*    </HyperlinkButton>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<Divider className="mv-medium" />*/}

        {/*<div>*/}
        {/*  <div className="mb-12">*/}
        {/*    <Text lineHeight={120} size="regular">*/}
        {/*      <b>*/}
        {/*        {bookedCapacity}/{capacity}*/}
        {/*      </b>{' '}*/}
        {/*      customers*/}
        {/*    </Text>*/}
        {/*  </div>*/}

        {/*  <div className="flex-row mb-small align-center">*/}
        {/*    <Avatar size="default" className="mr-x-small" src="https://randomuser.me/api/portraits/men/46.jpg" />*/}
        {/*    <Avatar size="default" className="mr-x-small" src="https://randomuser.me/api/portraits/men/46.jpg" />*/}
        {/*    <Avatar size="default" className="mr-x-small" src="https://randomuser.me/api/portraits/men/46.jpg" />*/}
        {/*    <Avatar size="default" className="mr-x-small" src="https://randomuser.me/api/portraits/men/46.jpg" />*/}

        {/*    <Text size="regular" className="ml-x-small" color="secondary">*/}
        {/*      4 others*/}
        {/*    </Text>*/}
        {/*  </div>*/}

        {/*  <div className="line-height-100 mb-12">*/}
        {/*    <Text lineHeight={100} size="regular" color="secondary">*/}
        {/*      <b>10</b> confirmed, <b>5</b> pending, <b>1</b> declined*/}
        {/*    </Text>*/}
        {/*  </div>*/}

        {/*  <div>*/}
        {/*    <HyperlinkButton fontSize="regular">See all customers</HyperlinkButton>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
