import React from 'react';
import { Divider, Flex, rem } from '@good/ui/core';

import { BoldButton } from './bold-button';
import { ItalicButton } from './italic-button';
import { UnderlineButton } from './underline-button';
import { OrderedListButton } from './ordered-list-button';
import { UnorderedListButton } from './unordered-list-button';
import { UndoButton } from './undo-button';
import { RedoButton } from './redo-button';
import { AlignLeftButton } from './align-left-button';
import { AlignCenterButton } from './align-center-button';
import { AlignRightButton } from './align-right-button';
import { AlignJustifyButton } from './align-justify-button';
import { FontSizeDropdown } from './font-size-dropdown';

export const MenuBar = () => {
  return (
    <Flex gap={rem(8)}>
      <UndoButton />
      <RedoButton />
      <Divider orientation='vertical' color='var(--brand-body-light-1)' />
      <FontSizeDropdown />
      <Divider orientation='vertical' color='var(--brand-body-light-1)' />
      <BoldButton />
      <ItalicButton />
      <UnderlineButton />
      <Divider orientation='vertical' color='var(--brand-body-light-1)' />
      <OrderedListButton />
      <UnorderedListButton />
      <Divider orientation='vertical' color='var(--brand-body-light-1)' />
      <AlignLeftButton />
      <AlignCenterButton />
      <AlignRightButton />
      <AlignJustifyButton />
    </Flex>
  );
};
