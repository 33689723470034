import React from 'react';
import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function Search(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.12451 9.16667C3.12451 5.82995 5.82946 3.125 9.16618 3.125C12.5029 3.125 15.2078 5.82995 15.2078 9.16667C15.2078 12.5034 12.5029 15.2083 9.16618 15.2083C5.82946 15.2083 3.12451 12.5034 3.12451 9.16667ZM9.16618 1.875C5.1391 1.875 1.87451 5.13959 1.87451 9.16667C1.87451 13.1937 5.1391 16.4583 9.16618 16.4583C10.9547 16.4583 12.5928 15.8144 13.8614 14.7457L16.2242 17.1086C16.4683 17.3527 16.864 17.3527 17.1081 17.1086C17.3522 16.8645 17.3522 16.4688 17.1081 16.2247L14.7452 13.8619C15.8139 12.5933 16.4578 10.9552 16.4578 9.16667C16.4578 5.13959 13.1933 1.875 9.16618 1.875Z"
        fill="currentColor"
      />
    </Icon>
  );
}
