import { forwardRef } from 'react';
import { filterDOMProps } from '@react-aria/utils';

import * as styles from './status-light.css';
import { twMerge } from '../utils';

import type { HTMLProps } from '../types';
import type { StatusLightVariants } from './status-light.css';

export type StatusLightProps = StatusLightVariants & HTMLProps<HTMLDivElement>;

export const StatusLight = forwardRef<HTMLDivElement, StatusLightProps>(function StatusLight(props, ref) {
  const { className, onBackground = false, size = 'md', tone, ...otherProps } = props;

  return (
    <div
      {...filterDOMProps(otherProps)}
      className={twMerge(styles.statusLight({ onBackground, size, tone }), className)}
      ref={ref}
    />
  );
});
