import React, { forwardRef, PropsWithChildren, useContext, useRef } from 'react';
import { compose, css, BoxStyleProps, boxStyleProps, HTMLProps, CSS, useStyleProps } from '..';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { RadioContext } from './radio-context';
import { usePress } from '@react-aria/interactions';
import { AriaRadioProps, useRadio } from '@react-aria/radio';

import { OuterCircleVariants, InnerCircleVariants, LabelTextVariants, SupportTextVariants } from './radio.css';

import * as styles from './radio.css';
import { IconChecked } from '../icon';

export type RadioProps = PropsWithChildren<
  BoxStyleProps &
    Omit<AriaRadioProps, 'value'> &
    HTMLProps<HTMLInputElement> &
    OuterCircleVariants &
    InnerCircleVariants &
    LabelTextVariants &
    SupportTextVariants
> & {
  /**
   * The value of the radio button, used when submitting an HTML form
   */
  value?: string | number;
  /**
   * Text label
   */
  label: string | React.ReactNode;
  /**
   * Text support
   */
  support?: string;
  /**
   * Disabled status
   */
  isDisabled?: boolean;
  /**
   * Styles for wrapper
   */
  wrapperStyles?: CSS;
};

/**
 * @name
 * Radio
 *
 * @description
 * The Radio element is an interactive element activated by
 * a user.
 *
 * @example
 *
 * Radio is wrapped by RadioGroup
 * <RadioGroup>
 *      <Radio size="medium" emphasis="bold" label="Female" value="female" support="Support" />
 *      <Radio size="small" label="Male" value="male" />
 * </RadioGroup>
 *
 */

// eslint-disable-next-line react/display-name
export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { label, support, emphasis = 'regular', size = 'small', isDisabled, wrapperStyles } = props;
  ref = useRef(null);
  const { state, isCheckMark } = useContext(RadioContext);
  let { inputProps } = useRadio(props, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing(props);
  const { pressProps, isPressed } = usePress({ isDisabled });
  inputProps = mergeProps(inputProps, focusProps);
  const isChecked = inputProps.checked;

  const { styleProps } = useStyleProps(wrapperStyles, boxStyleProps);
  return (
    <label className={compose(css(styles.reset, styleProps))}>
      <VisuallyHidden>
        <input {...inputProps} ref={ref} />
      </VisuallyHidden>
      <div
        {...pressProps}
        className={compose(
          css(styles.outerCircle),
          styles.outerCircleVariants({
            size,
            isCheckMark,
            isChecked,
            isDisabled,
            isPressed,
            isFocused: isFocusVisible,
          }),
        )}
      >
        {isChecked &&
          (isCheckMark ? (
            <IconChecked
              position="absolute"
              width="60%"
              height="60%"
              minWidth="$none"
              color="$white"
              top="20%"
              left="20%"
            />
          ) : (
            <div
              className={compose(
                css(styles.innerCircle),
                styles.innerCircleVariants({ size, isChecked, isPressed, isDisabled }),
              )}
            ></div>
          ))}
        {isDisabled && (
          <div className={compose(css(styles.innerCircle), styles.innerCircleVariants({ isDisabled }))}></div>
        )}
      </div>
      {/* TEXT GROUP */}
      <span className={css(styles.textGroup)()}>
        <span className={compose(css(styles.labelText), styles.labelTextVariants({ size, emphasis, isDisabled }))}>
          {label}
        </span>
        <span className={compose(css(styles.supportText), styles.supportTextVariants({ size }))}>{support}</span>
      </span>
    </label>
  );
});
