import React, { useState, useEffect } from 'react';
import { Table } from 'design-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { IUseFetchWorkTypesResult } from 'stores/hooks/query-hooks/use-query-fetch-work-types-per-category';
import {
  TableSkeleton,
  TableError,
  TableEmpty,
  SelectedWorkLeaveTypeStates,
  WorkLeaveTypesStateFilter,
} from '../components';
import { WorkTypeCategory, WorkTypeState } from 'utilities/enum-utils';
import { IWorkLeaveType } from 'interfaces/account-interfaces';
import AddWorkLeaveTypeInlineButton from './add-work-leave-type-inline-button';
import useTableColumns from './use-table-columns';
import useDataSources from './use-data-sources';

export type WorkLeaveTypeTableOptions = {
  selectedWorkLeaveTypeStates?: SelectedWorkLeaveTypeStates;
};

type WorkLeaveTypesTableProps = {
  workLeaveTypes: IUseFetchWorkTypesResult;
  category: WorkTypeCategory;
  options: WorkLeaveTypeTableOptions | undefined;
  saveOptions: (options: WorkLeaveTypeTableOptions) => void;
  editWorkLeaveType?: (workLeaveType: IWorkLeaveType) => void;
  addWorkLeaveType?: () => void;
  archiveWorkLeaveType?: (workLeaveType: IWorkLeaveType) => void;
  restoreWorkLeaveType?: (workLeaveType: IWorkLeaveType) => void;
};

const WorkLeaveTypesTable = ({
  workLeaveTypes,
  category,
  options,
  saveOptions,
  editWorkLeaveType,
  addWorkLeaveType,
  archiveWorkLeaveType,
  restoreWorkLeaveType,
}: WorkLeaveTypesTableProps): JSX.Element => {
  const { pinc762Stage2PayCodeConfigurationWithArchive } = useFlags();
  const { data, isLoading, error } = workLeaveTypes;
  const [selectedWorkLeaveTypeStates, setSelectedWorkLeaveTypeStates] = useState<SelectedWorkLeaveTypeStates>(
    options?.selectedWorkLeaveTypeStates ?? {
      [WorkTypeState.ACTIVE]: true,
      [WorkTypeState.INACTIVE]: true,
    },
  );
  const dataSources = useDataSources(data, selectedWorkLeaveTypeStates);
  const columns = useTableColumns(category, editWorkLeaveType, archiveWorkLeaveType, restoreWorkLeaveType);
  const isTableEmpty = !dataSources.length;

  useEffect(() => {
    saveOptions({ selectedWorkLeaveTypeStates });
  }, [selectedWorkLeaveTypeStates, saveOptions]);

  if (isLoading) {
    return <TableSkeleton />;
  }

  if (error) {
    return <TableError />;
  }

  if (isTableEmpty) {
    return (
      <>
        {pinc762Stage2PayCodeConfigurationWithArchive && category === WorkTypeCategory.Leave && (
          <div className="mb-large">
            <WorkLeaveTypesStateFilter
              selectedStates={selectedWorkLeaveTypeStates}
              setSelectedStates={setSelectedWorkLeaveTypeStates}
            />
          </div>
        )}
        <TableEmpty category={category} addWorkLeaveType={addWorkLeaveType} />
      </>
    );
  }

  return (
    <>
      {pinc762Stage2PayCodeConfigurationWithArchive && category === WorkTypeCategory.Leave && (
        <div className="mb-large">
          <WorkLeaveTypesStateFilter
            selectedStates={selectedWorkLeaveTypeStates}
            setSelectedStates={setSelectedWorkLeaveTypeStates}
          />
        </div>
      )}
      <div id="work-leave-types-table">
        <Table columns={columns} dataSources={dataSources} />
        <AddWorkLeaveTypeInlineButton
          category={category}
          addWorkLeaveType={addWorkLeaveType}
          style={{ marginTop: 42, marginBottom: 26 }}
        />
      </div>
    </>
  );
};

export default WorkLeaveTypesTable;
