import React from 'react';

import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';

const { FOOTER_BLOCK_HEIGHT } = ROSTER_DAILY_CONFIG;

interface FooterSpacerProps {
  width: number;
  height?: number;
}

export function FooterSpacer({ width, height = FOOTER_BLOCK_HEIGHT }: FooterSpacerProps) {
  return <div className="bg-secondary bordered-top" style={{ width: `${width}px`, height }} />;
}
