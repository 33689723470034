import { Heading, IllustratedMessage, Text } from '@good/components';
import { Cat } from '@good/illustrations';
import { format } from 'date-fns';
import moment from 'moment-timezone';
import React, { Key, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useFetchServicesLite } from 'stores/hooks/query-hooks/use-query-fetch-services-lite';
import { useFetchInsightsSummary } from 'stores/hooks/query-hooks/use-query-fetch-team-insights-summary';
import { useFetchInsightsTable } from 'stores/hooks/query-hooks/use-query-fetch-team-insights-table';
import { IRootState, state } from 'stores/rematch/root-store';
import { TimePeriod, getDatesForTimePeriod } from 'views/insights/insights';
import { ExportDataModal } from '../export-data-modal/export-data-modal';
import { InsightsBreadcrumbs } from '../insights-breadcrumbs/insights-breadcrumbs';
import { InsightsSummaryWidget } from '../insights-summary-widget/insights-summary-widget';
import { InsightsTableOptions } from '../insights-table-options/insights-table-options';
import { InsightsTablePageControl } from '../insights-table-page-control/insights-table-page-control';
import { InsightsTable } from '../insights-table/insights-table';

type DateRange = { start: Date; end: Date };

const InsightsViewHeader: React.FC = () => {
  const { t } = useTranslation('', { keyPrefix: 'insights' });

  return (
    <div className='mt-8'>
      <Text className='mb-2 block text-h-lg font-bold text-black'>{t('table.pageHeading')}</Text>
      <Text className='block text-gray-dark-1'>
        Service type: {t('summary.filters.teamMembersOptions.supportCoordinators')}
      </Text>
    </div>
  );
};

type InsightsTableViewProperties = {
  portalUser: typeof state.authStore.portalUser;
};

const InsightsTableNothingFound: React.FC = () => {
  const { t } = useTranslation('', { keyPrefix: 'insights.table.nothingFound' });

  return (
    <IllustratedMessage className='max-w-full'>
      <Cat size='lg' />
      <Heading size='xl'>{t('header')}</Heading>
      <Text>{t('sting')}</Text>
    </IllustratedMessage>
  );
};

const InsightsTableView: React.FC<InsightsTableViewProperties> = ({ portalUser }) => {
  const [page, setPage] = useState<number>(1);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);

  const [timePeriod, setTimePeriod] = useState<TimePeriod & Key>('thisWeek');
  const [selectedServiceIds, setSelectedServiceIds] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string | undefined>();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const { current, previous }: { current: DateRange; previous: DateRange } = useMemo(() => {
    const {
      dateLower: startIsoString,
      dateUpper: endIsoString,
      previousDateLower: previousStartIsoString,
      previousDateUpper: previousEndIsoString,
    } = getDatesForTimePeriod(timePeriod, portalUser?.timezone ?? 'Australia/Melbourne');

    return {
      current: { start: new Date(startIsoString), end: new Date(endIsoString) },
      previous: { start: new Date(previousStartIsoString), end: new Date(previousEndIsoString) },
    };
  }, [portalUser?.timezone, timePeriod]);

  const { data: services } = useFetchServicesLite({
    serviceType: ['COORDINATION'],
  });

  const { data: tableData, status: fetchTableDataStatus } = useFetchInsightsTable({
    page: page,
    limit: resultsPerPage,
    serviceIds: selectedServiceIds,
    searchByName: searchText,
    startDate: current.start,
    endDate: current.end,
  });

  const { data: summaryData } = useFetchInsightsSummary({
    teamMemberGroup: 'SUPPORT_COORDINATORS',
    dateLower: current.start.toISOString(),
    dateUpper: current.end.toISOString(),
    previousDateLower: previous.start.toISOString(),
    previousDateUpper: previous.end.toISOString(),
  });

  const totalItems = tableData?.totalItems ?? 0;

  return (
    <>
      <ExportDataModal
        startDate={moment(current.start)}
        endDate={moment(current.end)}
        serviceIds={selectedServiceIds}
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
      />
      <div className='relative'>
        <InsightsBreadcrumbs />
        <div className='align-end mb-10 flex flex-row justify-between'>
          <InsightsViewHeader />
          <InsightsTableOptions
            services={services}
            timePeriod={timePeriod}
            setTimePeriod={setTimePeriod}
            selectedServiceIds={selectedServiceIds}
            setSelectedServiceIds={setSelectedServiceIds}
            searchText={searchText}
            setSearchText={setSearchText}
            setIsExportModalOpen={setIsExportModalOpen}
          />
        </div>
        <Text className='text-lg mb-6 block font-bold'>
          {format(current.start, `eee d MMMM ${current.start.getFullYear === current.end.getFullYear ? '' : 'yyyy'}`)} -{' '}
          {format(current.end, 'eee d MMMM yyyy')}
        </Text>
        <InsightsSummaryWidget {...summaryData} />
        <div className='pt-6'>
          {/* If we're loading, or if we have data, attempt to render out the table. */}
          {fetchTableDataStatus !== 'success' || tableData.results.length > 0 ? (
            <>
              <InsightsTable
                resultsPerPage={resultsPerPage}
                rows={tableData?.results}
                dataStatus={fetchTableDataStatus}
              />
              <InsightsTablePageControl
                currentPage={page}
                setPage={setPage}
                totalResults={totalItems}
                resultsPerPage={resultsPerPage}
                setResultsPerPage={setResultsPerPage}
              />
            </>
          ) : (
            <InsightsTableNothingFound />
          )}
        </div>
      </div>
    </>
  );
};

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });
export const ConnectedInsightsTableView = connect(mapState)(InsightsTableView);
