import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Title, Text, SubTitle } from 'common-components/typography';
import { Col, Icon, notification, Row, Select } from 'antd';
import * as H from 'history';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';

const { Option } = Select;
interface ICreateCopyFormModalProps {
  isOpen: boolean;
  history: H.History;
  onClose: () => void;
  formListOptions: typeof state.formBuilderStore.formListOptions;
  doFetchFormListOptions: typeof dispatch.formBuilderStore.doFetchFormListOptions;
  setCurrentForm: typeof dispatch.formBuilderStore.setCurrentForm;
  setFormListOptions: typeof dispatch.formBuilderStore.setFormListOptions;
}
interface ICreateCopyFormModalState {
  isCopy: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isLoadingScrolling: boolean;
  formId: string;
}

class CreateCopyFormModal extends Component<ICreateCopyFormModalProps, ICreateCopyFormModalState> {
  state = {
    isCopy: false,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    isLoadingScrolling: false,
    formId: null,
  };

  private _onCreateForm = () => {
    this.props.history.push('/account/forms/create');
  };

  private _onCloseModal = () => {
    this.setState({ isCopy: false });
    this.props.onClose();
  };

  private _onToggleCopyForm = () => {
    this.setState({ isCopy: !this.state.isCopy });
  };

  private _fetchMoreFormOptions = async (e) => {
    try {
      const element = e.target;
      if (element.scrollHeight === element.clientHeight + element.scrollTop) {
        this.setState({ isLoadingScrolling: true, page: this.state.page + 1 });
        await this.props.doFetchFormListOptions({
          page: this.state.page,
          pageSize: this.state.pageSize,
          pageTimestamp: this.state.pageTimestamp,
        });
        this.setState({ isLoadingScrolling: false });
      }
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  private _onHandleOptionChange = (value: string) => {
    this.setState({ formId: value });
  };

  private _onCopyHandle = async () => {
    this.props.history.push(`/account/forms/copy/${this.state.formId}`);
  };

  private _renderCopyContent = () => {
    const { formListOptions } = this.props;

    return (
      <>
        <Paragraph>Select which form you would like to copy</Paragraph>
        <SubTitle>EXISTING FORM </SubTitle>
        <Select
          loading={this.state.isLoadingScrolling}
          onPopupScroll={this._fetchMoreFormOptions}
          onChange={this._onHandleOptionChange}
          style={{ width: 320 }}
          placeholder="Select..."
          optionFilterProp="children"
          suffixIcon={<Icon type="caret-down" />}
          size="large"
        >
          {_.map(formListOptions, (formOpt, i) => {
            return (
              <Option key={formOpt.formId} value={formOpt.formId}>
                {formOpt.name}
              </Option>
            );
          })}
        </Select>
        <ActionModalFooter className={'mt-large '}>
          <Row type={'flex'} className={'justify-end'}>
            <Col className="mr-medium">
              <SecondaryButton size="large" onClick={this._onToggleCopyForm}>
                Go back
              </SecondaryButton>
            </Col>
            <Col>
              <PrimaryButton onClick={this._onCopyHandle} size="large">
                Copy
              </PrimaryButton>
            </Col>
          </Row>
        </ActionModalFooter>
      </>
    );
  };

  async componentDidUpdate(
    prevProps: Readonly<ICreateCopyFormModalProps>,
    prevState: Readonly<ICreateCopyFormModalState>,
    snapshot?: any,
  ): Promise<void> {
    if (this.state.isCopy && !prevState.isCopy && this.state.isCopy === true) {
      await this.props.doFetchFormListOptions({
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      });
    }
  }

  render() {
    const title = !this.state.isCopy ? 'Create new form' : 'Copy from existing';
    return (
      <ActionModal isOpen={this.props.isOpen} title={title} onClose={this._onCloseModal}>
        {!this.state.isCopy ? (
          <>
            <Paragraph>Start with a blank form or duplicate an existing form and make edits.</Paragraph>
            <Row gutter={24} justify="center">
              <Col md={12}>
                <div
                  className="flex-column align-center justify-center pv-large rounded-big bordered border-width-small border-secondary cursor-pointer"
                  onClick={this._onCreateForm}
                >
                  <Title level={4} className="text-color-blue-action mb-x2-small">
                    Build a form
                  </Title>
                  <Text className="text-color-secondary whitespace-nowrap">
                    Start from scratch with a blank template
                  </Text>
                </div>
              </Col>
              <Col md={12}>
                <div
                  className="flex-column align-center justify-center pv-large rounded-big bordered border-width-small border-secondary cursor-pointer"
                  onClick={this._onToggleCopyForm}
                >
                  <Title level={4} className="text-color-blue-action mb-x2-small">
                    Duplicate an existing form
                  </Title>
                  <Text className="text-color-secondary whitespace-nowrap">
                    Create your form using an existing template
                  </Text>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          this._renderCopyContent()
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  formListOptions: state.formBuilderStore.formListOptions,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchFormListOptions: dispatch.formBuilderStore.doFetchFormListOptions,
  setCurrentForm: dispatch.formBuilderStore.setCurrentForm,
  setFormListOptions: dispatch.formBuilderStore.setFormListOptions,
});

export default connect(mapState, mapDispatch)(CreateCopyFormModal);
