import React from 'react';
import { useTranslation } from 'react-i18next';

import { SideNav, TabContent } from 'views/sil-service/components';
import { RecentActivity } from './recent-activity';
import { ServiceActivity } from './service-activity';
import { CustomerActivity } from './customer-activity';
import { ShiftActivity } from './shift-activity';

export const ActivityTab = () => {
  const { t } = useTranslation('', { keyPrefix: 'silService.activityTabs' });
  const tabs: TabContent[] = [
    { id: 1, title: `${t('recentActivity.tabName')}`, content: <RecentActivity /> },
    {
      id: 2,
      title: `${t('serviceActivity.tabName')}`,
      content: <ServiceActivity />,
    },
    {
      id: 3,
      title: `${t('customerActivity.tabName')}`,
      content: <CustomerActivity />,
    },
    {
      id: 4,
      title: `${t('shiftActivity.tabName')}`,
      content: <ShiftActivity />,
    },
  ];

  return <SideNav tabs={tabs} />;
};
