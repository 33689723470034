import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Modules = forwardRef(function Modules(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 6a3.75 3.75 0 0 1 7.425-.75H12a.75.75 0 0 1 0 1.5H9.675A3.751 3.751 0 0 1 2.25 6ZM6 3.75a2.25 2.25 0 1 1 0 4.5 2.25 2.25 0 0 1 0-4.5ZM6.75 12a.75.75 0 0 0-1.5 0v2.325A3.751 3.751 0 0 0 6 21.75a3.75 3.75 0 0 0 .75-7.425V12Zm12-2.325A3.751 3.751 0 0 0 18 2.25a3.75 3.75 0 0 0-.75 7.425V12a.75.75 0 0 0 1.5 0V9.675ZM18 8.25a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm-6 9a.75.75 0 0 0 0 1.5h2.325A3.751 3.751 0 0 0 21.75 18a3.75 3.75 0 0 0-7.425-.75H12Zm3.75.75a2.25 2.25 0 1 0 4.5 0 2.25 2.25 0 0 0-4.5 0ZM6 15.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
