import React from 'react';
import { useCustomerBudgetStatus } from '../../../../budgets/pages/detail/services';
import { SubTitle, Text, Title } from '../../../../../common-components/typography';
import BudgetingDetailRow from './BudgetingDetailRow';
import Skeleton from 'antd/lib/skeleton';
import Empty from 'antd/lib/empty';
import { useTranslation } from 'react-i18next';

const BudgetingPanel = (props: { customerId: string }): JSX.Element => {
  const { data, isSuccess, isError, isLoading } = useCustomerBudgetStatus(props.customerId);
  const { t } = useTranslation('', { keyPrefix: 'budget.budgetingPanel' });

  const emptyResult = () => (
    <div>
      <Text weight="bold" size="x2-large" color="secondary">
        {t('noActiveServiceAgreement.title')}
      </Text>
      <br />
      <Text color="secondary">{t('noActiveServiceAgreement.instructions')}</Text>
    </div>
  );

  return (
    <>
      {isError ||
        (!data?.activeServiceAgreementId && isSuccess && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyResult()} />
        ))}

      {isLoading && (
        <div>
          <Skeleton paragraph={{ rows: 2, width: '100%' }} active={true} className="anim-slide-left" />
        </div>
      )}

      {isSuccess && data?.activeServiceAgreementId && (
        <>
          <div className="flex-row justify-between align-end pb-large">
            <div>
              <Title level={2} className="mv-none">
                {t('title')}
              </Title>
              <Text color="secondary">{t('description')}</Text>
            </div>
          </div>

          <table className="width-full">
            <thead>
              <tr className="bordered-bottom border-standard-gray">
                <th className="ph-medium pv-small">
                  <SubTitle>{t('service')}</SubTitle>
                </th>
                <th className="ph-medium pv-small">
                  <SubTitle>{t('budget')}</SubTitle>
                </th>
                <th className="ph-medium pv-small">
                  <SubTitle>{t('agreementPeriod')}</SubTitle>
                </th>
                <th className="ph-small pv-small">
                  <SubTitle>{t('timeElapsed')}</SubTitle>
                </th>
                <th className="ph-small pv-small">
                  <SubTitle>{t('endingIn')}</SubTitle>
                </th>
                <th className="ph-small pv-small"></th>
              </tr>
            </thead>
            <BudgetingDetailRow budgetingDetails={data} customerId={props.customerId} />
          </table>
        </>
      )}
    </>
  );
};

export default BudgetingPanel;
