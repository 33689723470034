import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment-timezone';
import _ from 'lodash';

import { useFetchPrintSessionRoster } from 'stores/hooks/query-hooks/use-query-fetch-print-session-roster';
import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { ICrumb } from 'interfaces/common-interface';
import { ISessionRosterActivityGroups } from 'interfaces/session-interfaces';
import clientEnvironment from 'utilities/environment-utils';
import { BrowserType } from 'utilities/enum-utils';

import PrintSessionRosterHeader from './components/PrintSessionRosterHeader';
import RosterActivityGroupListing from './components/RosterActivityGroupListing';
import { PageContainer } from 'layouts/page-container';

interface IPrintSessionRosterViewUrlParams {
  serviceId: string;
  serviceDateTimeId: string;
}

interface IPrintSessionRosterViewProps extends RouteComponentProps<IPrintSessionRosterViewUrlParams> {}

function PrintSessionRosterView(props: IPrintSessionRosterViewProps) {
  const { match, history } = props;
  const { serviceId, serviceDateTimeId } = match.params;
  const { data: sessionRoster, isLoading } = useFetchPrintSessionRoster({
    serviceId,
    serviceDateTimeId,
  });
  const [activityGroups, setActivityGroups] = useState<ISessionRosterActivityGroups[]>([]);
  const [isPrintBefore, setIsPrintBefore] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [showPrintWarningModal, setShowPrintWarningModal] = useState(false);

  const crumbs: ICrumb[] = sessionRoster
    ? [
        {
          title: 'Services',
          target: '/services',
        },
        {
          title: sessionRoster.serviceName,
          target: `/group-service/details/${serviceId}`,
        },
        {
          title:
            moment.tz(sessionRoster.startDateTime, sessionRoster.timezone).format('DD MMMM YYYY hh:mm A') +
            ' - ' +
            moment.tz(sessionRoster.endDateTime, sessionRoster.timezone).format('hh:mm A'),
          target: `/group-service/${serviceId}/session/details/${serviceDateTimeId}`,
        },
        {
          title: 'Print roster view',
        },
      ]
    : [];

  const handlePrintAction = useCallback(() => {
    const doesSafariIssueMayHappen = isPrintBefore && clientEnvironment.browser.type === BrowserType.SAFARI;
    if (!doesSafariIssueMayHappen) {
      setIsPrinting(true);
    } else {
      setShowPrintWarningModal(true);
    }
  }, [clientEnvironment, isPrintBefore]);

  const handleContinueToPrintAction = () => {
    setShowPrintWarningModal(false);
    setIsPrinting(true);
  };

  const handleCloseWarningModal = () => {
    setShowPrintWarningModal(false);
  };

  const print = () => {
    if (clientEnvironment.browser.type === BrowserType.SAFARI) {
      // According to https://discussions.apple.com/thread/252821086:
      // window.print() does not work on Safari,
      // document.execCommand() has been deprecated but it's the best solution to deal with Safari so far
      document.execCommand('print', false, null);
    } else {
      window.print();
    }

    setIsPrintBefore(true);
  };

  const handleBack = useCallback(() => {
    history.push(`/group-service/${serviceId}/session/details/${serviceDateTimeId}`);
  }, [history, serviceId, serviceDateTimeId]);

  useEffect(() => {
    if (sessionRoster) {
      const { activityGroups: rosterActivityGroups, unassignedToActivityGroupMember } = sessionRoster;
      const newActivityGroups: ISessionRosterActivityGroups[] = [...rosterActivityGroups];
      if (
        !_.isEmpty(sessionRoster.unassignedToActivityGroupMember.customers) ||
        !_.isEmpty(sessionRoster.unassignedToActivityGroupMember.teamMembers)
      ) {
        newActivityGroups.push({
          serviceDateTimeActivityGroupId: '',
          name: 'No Activity Group',
          ...unassignedToActivityGroupMember,
        });
      }

      setActivityGroups(newActivityGroups);
    }
  }, [sessionRoster]);

  useEffect(() => {
    if (isPrinting) {
      print();
      setIsPrinting(false);
    }
  }, [isPrinting]);

  return (
    <div className='printable-screen overflow-auto'>
      {isLoading ? (
        <div className='ph-large'>
          <SpinningLoader size={50} message={'Fetching rosters...'} />
        </div>
      ) : (
        sessionRoster && (
          <div>
            <PageContainer>
              <BreadcrumbNav className='invisible-print-mode' icon='home' theme='filled' crumbs={crumbs} />
              <div className='content vh-large'>
                <PrintSessionRosterHeader
                  isPrinting={isPrinting}
                  session={sessionRoster}
                  onPrint={handlePrintAction}
                  onBack={handleBack}
                />
                <RosterActivityGroupListing rosterActivityGroups={activityGroups} timezone={sessionRoster.timezone} />
              </div>
            </PageContainer>
            <ActionModal
              isOpen={showPrintWarningModal}
              showCloseButton
              canCloseOutside={false}
              onClose={handleCloseWarningModal}
              width='small'
              title='Oops!'
            >
              <div className='mt-small mb-small'>
                <Text>There was a loading error...</Text>
                <br />
                <br />
                <Text>Please click refresh and try again.</Text>
              </div>
              <ActionModalFooter>
                <PrimaryButton
                  className='ml-medium'
                  size='large'
                  onClick={handleContinueToPrintAction}
                  loading={isLoading}
                >
                  Continue
                </PrimaryButton>
              </ActionModalFooter>
            </ActionModal>
          </div>
        )
      )}
    </div>
  );
}

export default PrintSessionRosterView;
