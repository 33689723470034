import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Text, Button } from '@good/components';

import { WorkTypeState } from 'utilities/enum-utils';
import { Checkbox } from 'design-components';
import { SelectedWorkLeaveTypeStates } from './WorkLeaveTypeStateFilter';
import Card from './Card';

type IStatusFilterCardProps = {
  onClose: () => void;
  selectedStates: SelectedWorkLeaveTypeStates;
  setSelectedStates: Dispatch<SetStateAction<SelectedWorkLeaveTypeStates>>;
};

const WorkLeaveTypeStateFilterCard = ({
  onClose,
  selectedStates: savedStates,
  setSelectedStates: setSavedStates,
}: IStatusFilterCardProps): JSX.Element => {
  const [selectedStates, setSelectedStates] = useState<{ [key in WorkTypeState]: boolean }>(savedStates);
  const [hasSelectedAll, setHasSelectedAll] = useState<boolean>(false);

  useEffect(() => {
    const allSelected = Object.keys(selectedStates).every((state) => selectedStates[state as WorkTypeState]);
    setHasSelectedAll(allSelected);
  }, [selectedStates]);

  const onApply = () => {
    setSavedStates(selectedStates);
    onClose();
  };

  return (
    <Card width={322} data-testid="state-filter-card">
      <input
        type="text"
        style={{ opacity: 0, height: 0, padding: 0, margin: 0, overflow: 'hidden' }}
        aria-hidden="true"
      />
      <div
        className="flex-column pl-medium pr-medium pt-medium pb-medium mt-x-small align-start flex"
        style={{ gap: 24 }}
      >
        <button
          onClick={() =>
            setSelectedStates((prev) => {
              return Object.keys(prev).reduce<SelectedWorkLeaveTypeStates>(
                (acc, state: string) => {
                  acc[state as WorkTypeState] = !hasSelectedAll;
                  return acc;
                },
                {
                  Active: true,
                  Archived: true,
                },
              );
            })
          }
          className="inline-flex"
        >
          <Text className={'text-canary'}>{hasSelectedAll ? 'Deselect all' : 'Select all'}</Text>
        </button>
        <div className="flex-column flex gap-4">
          {Object.keys(selectedStates).map((workLeaveTypeState) => (
            <Checkbox
              isSelected={selectedStates[workLeaveTypeState as WorkTypeState]}
              onChange={(isSelected) => setSelectedStates((prev) => ({ ...prev, [workLeaveTypeState]: isSelected }))}
              name={workLeaveTypeState}
              key={workLeaveTypeState}
            >
              {workLeaveTypeState}
            </Checkbox>
          ))}
        </div>
      </div>
      <div className="pl-medium pr-medium pt-medium pb-medium flex justify-end">
        <div className="inline-flex">
          <Button onPress={onClose} className="mr-3">
            Cancel
          </Button>
          <Button emphasis="fill" onPress={onApply}>
            Apply
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default WorkLeaveTypeStateFilterCard;
