/* BOOM-475 - fix linting errors */
import { Upload } from 'antd';
import React from 'react';

import { Box, Button, Text } from 'design-components';
import { Identity } from '@good/illustrations';
const { Dragger } = Upload;

export interface IUploadNewPictureSectionProps {
  beforeUpload?: (file: File) => boolean;
  errorMessageFile: string;
  isChangeProfilePicture?: boolean;
}

const UploadNewPictureSection = (props: IUploadNewPictureSectionProps) => {
  const { beforeUpload, errorMessageFile, isChangeProfilePicture } = props;

  return (
    <Box
      height={'$sizeFull'}
      marginX={isChangeProfilePicture ? '0' : '-8px !important'}
      paddingTop={isChangeProfilePicture ? '0' : '$space200'}
    >
      <Dragger
        openFileDialogOnClick={false}
        showUploadList={false}
        beforeUpload={beforeUpload}
        className={`profile-picture-dragger align-center flex justify-center  ${
          errorMessageFile ? 'border-red-dark' : 'border-secondary'
        } bordered`}
      >
        <Box width={'$sizeFull'} display={'flex'} flexDirection="column" justifyContent="center" alignItems={'center'}>
          <Identity />
          <Text size={'medium'} emphasis="semibold" color={'$oceanDark2'} marginTop="$space50">
            Drag photo here
          </Text>
          <Box marginTop="$space250" display={'flex'} flexDirection="row" justifyContent="center" alignItems={'center'}>
            <div style={{ width: '25px', height: '1px' }} className="bg-ocean-dark-2"></div>
            <Text size={'small'} emphasis="semibold" color={'$oceanDark2'} marginX="$space125">
              OR
            </Text>
            <div style={{ width: '25px', height: '1px' }} className="bg-ocean-dark-2"></div>
          </Box>
          {!!errorMessageFile && (
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
              <Text size="small" emphasis="semibold" color="$red">
                {errorMessageFile}
              </Text>
            </Box>
          )}
          <Button
            emphasis="filled"
            kind={'accent'}
            size="medium"
            borderWidth={'0 !important'}
            marginTop="$space250"
            paddingX={'$none'}
            onClick={(e) => e.preventDefault()}
          >
            <Upload showUploadList={false} beforeUpload={beforeUpload} accept="image/*">
              <Text size={'small'} emphasis={'semibold'} color={'$white'}>
                Browse files
              </Text>
            </Upload>
          </Button>
        </Box>
      </Dragger>
    </Box>
  );
};

export default UploadNewPictureSection;
