import React from 'react';

import _ from 'lodash';
import moment from 'moment';

import { CustomerDetailsColumn } from 'common-components/roster-control/roster-weekly/customers/CustomerDetailsColumn';
import { DayColumn } from 'common-components/roster-control/roster-weekly/common/DayColumn';
import { BookingCard } from 'common-components/roster-control/roster-weekly/customers/BookingCard';

import { IRosterBooking, IRosterCustomer } from 'common-components/roster-control/interfaces/roster-interfaces';
import { NoShiftIndicator } from 'common-components/roster-control/roster-weekly/common/NoShiftIndicator';
import { BookingStatus } from 'utilities/enum-utils';
import * as H from 'history';

interface CustomerItemRowProps {
  customer: IRosterCustomer;
  bookings: IRosterBooking[];
  days: Date[];
  history: H.History;
  refreshData: () => void;
}

export function CustomerItemRow({ customer, bookings, days, refreshData, history }: CustomerItemRowProps) {
  const { customerLastName, customerFirstName, customerAvatarUrl } = customer;

  const customerFullName = `${customerFirstName} ${customerLastName}`;

  const hasBookings = true;
  const revenueAmount = _.reduce(
    _.filter(
      bookings,
      (booking) =>
        booking.bookerUserId === customer.customerId &&
        !_.find(
          [
            BookingStatus.CUSTOMER_CANCELLED,
            BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE,
            BookingStatus.BUSINESS_CANCELLED,
          ],
          (filteredStatus) => booking.status === filteredStatus,
        ),
    ),
    (acc, item) => {
      return acc + Number(item.total);
    },
    0,
  );

  return (
    <div className="flex-row">
      {/* (Left) Name cell  */}
      <CustomerDetailsColumn
        customerId={customer.customerId}
        customerName={customerFullName}
        revenueAmount={revenueAmount}
        avatarUrl={customerAvatarUrl}
        history={history}
      />

      {/* (Right) Day cells */}
      <div className="flex-row flex-1 position-relative">
        {!hasBookings && <NoShiftIndicator />}

        {_.map(days, (day, idx) => (
          <DayColumn
            content={
              <BookingCardList
                customer={customer}
                day={day}
                bookings={bookings}
                refreshData={refreshData}
                history={history}
              />
            }
            hasAddAction={false}
            key={idx}
          />
        ))}
      </div>
    </div>
  );
}

function BookingCardList({
  customer,
  bookings,
  day,
  refreshData,
  history,
}: {
  customer: IRosterCustomer;
  bookings: IRosterBooking[];
  day: Date;
  refreshData: () => void;
  history: H.History;
}) {
  const customerBookings = _.chain(bookings)
    .filter(
      (booking) => booking.bookerUserId === customer.customerId && moment(booking.startDateTime).isSame(day, 'day'),
    )
    .orderBy(['startDateTime'], ['asc'])
    .value();

  return (
    <>
      {_.map(customerBookings, (booking, idx) => (
        <BookingCard booking={booking} key={idx} refreshData={refreshData} history={history} />
      ))}
    </>
  );
}
