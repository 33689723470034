import { Card, Col, Row } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import {
  AvailabilityUnavailabilityRequestModalType,
  AvailabilityUnavailabilityRequestStatus,
} from 'utilities/enum-utils';
import AvailabilityUnavailabilityRequestModal from './AvailabilityUnavailabilityRequestModal';

interface IAvailabilityAndUnavailabilityRequestSectionProps {
  memberAvailabilities: typeof state.teamStore.memberAvailabilities;
  doGetTimeAvailability: typeof dispatch.teamStore.doGetTimeAvailability;
}

const AvailabilityAndUnavailabilityRequestSection = (props: IAvailabilityAndUnavailabilityRequestSectionProps) => {
  const { memberAvailabilities, doGetTimeAvailability } = props;

  const [openedRequestModalType, setOpenedRequestModalType] =
    useState<AvailabilityUnavailabilityRequestModalType>(null);

  const timeAvailabilityPendingRequestLength = _.filter(
    memberAvailabilities.timeAvailabilityRequest,
    (item) => item.status === AvailabilityUnavailabilityRequestStatus.PENDING_APPROVAL,
  ).length;

  const timeUnavailabilityPendingRequestLength = _.filter(
    memberAvailabilities.scheduledUnavailabilityRequest,
    (item) => item.status === AvailabilityUnavailabilityRequestStatus.PENDING_APPROVAL,
  ).length;

  const timeUnavailabilityCancelRequestLength = _.filter(
    memberAvailabilities.scheduledUnavailabilityRequest,
    (item) => item.status === AvailabilityUnavailabilityRequestStatus.REQUEST_CANCELLATION,
  ).length;

  useLayoutEffect(() => {
    //this will override the default styling of antd card
    //we have to select out the cardContext so that it won't affect to other ant-card-actions
    const cardContext = document.querySelector('#availability-unavailability-request-modal');
    const cardActions = Array.from(cardContext.querySelectorAll('.ant-card-actions'));
    if (cardActions.length > 0) {
      _.forEach(cardActions, (action) => {
        action.children[0].setAttribute('style', 'text-align: left');
        action.children[0].setAttribute('style', 'padding-left: 24px');
      });
    }
  }, []);

  useLayoutEffect(() => {
    const isExpandSection =
      timeAvailabilityPendingRequestLength > 0 ||
      timeUnavailabilityPendingRequestLength > 0 ||
      timeUnavailabilityCancelRequestLength > 0;

    const cardBody = Array.from(document.getElementsByClassName('ant-card-body'));
    if (cardBody.length > 0) {
      _.forEach(cardBody, (c) => {
        if (isExpandSection) {
          c.setAttribute('style', 'min-height: 130px');
        } else c.removeAttribute('style');
      });
    }
  }, [
    timeAvailabilityPendingRequestLength,
    timeUnavailabilityPendingRequestLength,
    timeUnavailabilityCancelRequestLength,
  ]);

  return (
    <>
      {openedRequestModalType && (
        <AvailabilityUnavailabilityRequestModal
          type={openedRequestModalType}
          memberAvailabilities={memberAvailabilities}
          onClose={() => {
            setOpenedRequestModalType(null);
            doGetTimeAvailability({});
          }}
        />
      )}

      <Row type="flex" gutter={32} id="availability-unavailability-request-modal">
        <Col span={11}>
          <Card
            className="rounded-big height-full"
            actions={[
              <HyperlinkButton
                key={'view-availability-request'}
                onClick={() => setOpenedRequestModalType(AvailabilityUnavailabilityRequestModalType.AVAILABILITY)}
              >
                View availability request
              </HyperlinkButton>,
            ]}
          >
            <div>
              <Text size="large" weight="bold">
                General availability
              </Text>
            </div>
            {timeAvailabilityPendingRequestLength > 0 ? (
              <div>
                <Text size="x4-large" weight="bold">
                  {timeAvailabilityPendingRequestLength}
                </Text>
                <div>
                  <Text size="large" color="secondary">
                    Pending approval
                  </Text>
                </div>
              </div>
            ) : (
              <div>
                <Text color="secondary">No requests</Text>
              </div>
            )}
          </Card>
        </Col>
        <Col span={11}>
          <Card
            className="rounded-big"
            actions={[
              <HyperlinkButton
                key={'view-unavailability-request'}
                onClick={() => setOpenedRequestModalType(AvailabilityUnavailabilityRequestModalType.UNAVAILABILITY)}
              >
                View unavailability requests
              </HyperlinkButton>,
            ]}
          >
            <div>
              <Text size="large" weight="bold">
                Scheduled unavailability
              </Text>
            </div>
            <Row type="flex">
              {timeUnavailabilityPendingRequestLength > 0 || timeUnavailabilityCancelRequestLength > 0 ? (
                <>
                  {timeUnavailabilityPendingRequestLength > 0 && (
                    <Col span={12}>
                      <div>
                        <Text size="x4-large" weight="bold">
                          {timeUnavailabilityPendingRequestLength}
                        </Text>
                        <div>
                          <Text size="large" color="secondary">
                            Pending approval
                          </Text>
                        </div>
                      </div>
                    </Col>
                  )}
                  {timeUnavailabilityCancelRequestLength > 0 && (
                    <Col span={12}>
                      <div>
                        <Text size="x4-large" weight="bold">
                          {timeUnavailabilityCancelRequestLength}
                        </Text>
                        <div>
                          <Text size="large" color="secondary">
                            Cancellation request
                          </Text>
                        </div>
                      </div>
                    </Col>
                  )}
                </>
              ) : (
                <div>
                  <Text color="secondary">No requests</Text>
                </div>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  memberAvailabilities: state.teamStore.memberAvailabilities,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doGetTimeAvailability: dispatch.teamStore.doGetTimeAvailability,
});

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityAndUnavailabilityRequestSection);
