import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { IGroupServiceCustomerRatio, IGroupServiceServiceAgreement } from 'interfaces/service-interfaces';
import { filterHiddenCustomRatios } from './filter-hidden-ratios';
import { ISessionTimesParsed } from '../types';

function getRatio(
  sessionTimes: { startDateTime: Date; endDateTime: Date; timezone: string },
  groupServiceServiceAgreements: IGroupServiceServiceAgreement[],
): IGroupServiceCustomerRatio {
  return {
    teamMemberCustomerRatio:
      groupServiceServiceAgreements[0]?.teamMemberCustomerRatio?.ndis ??
      groupServiceServiceAgreements[0]?.teamMemberCustomerRatio,
    startDateTime: moment(
      moment.tz(sessionTimes.startDateTime, sessionTimes.timezone).format('YYYY-MM-DD HH:mm'),
    ).toDate(),
    endDateTime: moment(moment.tz(sessionTimes.endDateTime, sessionTimes.timezone).format('YYYY-MM-DD HH:mm')).toDate(),
    isCustomRatio: false,
    customRatio: [],
    durationRatios: [],
  };
}
export function useRatioFromServiceAgreement(
  sessionTimes: ISessionTimesParsed | null,
  serviceId: string | undefined,
  customerUserId: string | undefined,
  serviceDateTimeIds: string[] | undefined,
) {
  const {
    groupServiceStore: { doFetchGroupServiceServiceAgreements },
  } = useDispatch<IRootDispatch>();
  const groupServiceServiceAgreements = useSelector(
    (state: IRootState) => state.groupServiceStore.groupServiceServiceAgreements,
  );
  const [ratio, setRatio] = useState<IGroupServiceCustomerRatio | null>(null);

  const doFetchGroupServiceServiceAgreementsCallback = useCallback(async () => {
    if (serviceId && customerUserId && sessionTimes) {
      await doFetchGroupServiceServiceAgreements({
        serviceId,
        customerUserId,
        serviceDateTimeIds,
      });
    }
  }, [serviceId, customerUserId, sessionTimes]);

  useEffect(() => {
    void doFetchGroupServiceServiceAgreementsCallback();
  }, [doFetchGroupServiceServiceAgreementsCallback]);

  useEffect(() => {
    if (sessionTimes && groupServiceServiceAgreements) {
      let ratio_ = getRatio(sessionTimes, groupServiceServiceAgreements);
      ratio_ = filterHiddenCustomRatios(ratio_);
      setRatio(ratio_);
    }
  }, [sessionTimes, groupServiceServiceAgreements]);

  return ratio;
}
