import { VariantProps, tv } from 'tailwind-variants';

const baseItem = tv(
  {
    slots: {
      small: ['h-8', 'px-3', 'py-1'],
      medium: ['h-11', 'px-3', 'py-2.5'],
    },
  },
  { twMerge: false },
);

export const selectField = tv({
  slots: {
    container: ['flex', 'flex-col', 'min-w-[192px]', 'sm:min-w-[300px]', 'w-fit'],
    button: [
      'flex',
      'items-center',
      'content-center',
      'bg-base',
      'text-strong',
      'border',
      'min-w-full',
      'rounded-md',
      'disabled:bg-[#F7F7F6]',
      'disabled:border-[#F7F7F6]',
      'mb-2',
      'outline-none',
      'data-[focus-visible=true]:border-2',
      'data-[focus-visible=true]:border-accent',
      'data-[hovered=true]:data-[focus-visible=true]:border-accent',
      'aria-[expanded="true"]:border-2',
      'aria-[expanded="true"]:border-accent',
      'data-[hovered=true]:border-neutral',
      'group',
    ],

    popover: [
      'p-2',
      'bg-base',
      'rounded-md',
      'shadow-[0_8px_16px_-4px_rgba(0,0,0,0.08),0_4px_8px_-4px_rgba(0,0,0,0.08)]',
      'border',
      'border-neutral-weak',
    ],
    listbox: ['data-[focus-visible=true]:outline-none', 'rounded-md', 'text-b-sm', 'leading-b-sm', 'outline-none'],
    value: ['mr-auto'],
    chevron: ['text-weak', 'flex'],
    item: [
      'flex',
      'items-center',
      'content-center',
      'justify-between',
      'bg-base',
      'text-strong',
      'focus-visible:outline-accent',
      'rounded-md',
      'hover:bg-neutral-weak',
      'active:bg-accent-weak',
      'active:bg-accent-weak',
      'active:bg-accent-weak',
      'data-[pressed="true"]:bg-accent-weak',
      'min-w-[174px]',
      'sm:min-w-[282px]',
    ],
  },
  variants: {
    size: {
      small: {
        button: baseItem.slots.small,
        item: baseItem.slots.small,
      },
      medium: {
        button: baseItem.slots.medium,
        item: baseItem.slots.medium,
      },
    },
    isValid: {
      true: {
        button: ['border-gray'],
      },
      false: {
        button: ['data-[hovered=true]:border-[#802121]', 'border-critical', 'border-2'],
      },
    },
    isDisabled: {
      true: {
        value: ['text-neutral'],
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

type Variants = Required<VariantProps<typeof selectField>>;

export type SelectVariants = {
  /**
   * Size of select
   * @default medium
   */
  size?: Variants['size'];
};
