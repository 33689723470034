import { RadioGroup } from 'design-components';
import React, { useState } from 'react';
import { UserPermissionRole } from 'utilities/enum-utils';
import { ADVANCED_PERMISSION_ROLE_TYPE } from 'views/team-v2/utils/constants';
import ActionSelectedItem from '../ActionSelectedItem';
import AdvancedPermissionRow from '../AdvancedPermissionRow';
import { Text } from '@good/components';

type PermissionTeamSectionProps = {
  permissionRoleId: number;
  changePermissionForEachRole: (value: number, permissionKey: string) => void;
  typePermissionPresent: string;
  handleSetTypePermissionPresent: (value: string) => void;
};

export const PermissionTeamSection: React.FC<PermissionTeamSectionProps> = ({
  permissionRoleId = UserPermissionRole.TeamNoAccess,
  changePermissionForEachRole,
  typePermissionPresent,
  handleSetTypePermissionPresent,
}) => {
  const allTeamMembers = -999;
  const [haveAccessTo, setHaveAccessTo] = useState<UserPermissionRole | string | number>(
    permissionRoleId === UserPermissionRole.TeamNoAccess ? permissionRoleId : allTeamMembers,
  );

  const onHaveAccessToChange = (value: string | React.FormEvent<HTMLInputElement>) => {
    const numValue = Number(value);
    setHaveAccessTo(value);
    changePermissionForEachRole(numValue === allTeamMembers ? UserPermissionRole.TeamViewOnly : numValue, 'team');
  };

  return (
    <AdvancedPermissionRow
      title="Team"
      description="Team member profiles and data"
      type={ADVANCED_PERMISSION_ROLE_TYPE.TEAM}
      permissionRoleId={permissionRoleId}
      typePermissionPresent={typePermissionPresent}
      handleSetTypePermissionPresent={handleSetTypePermissionPresent}
      extendPermissions={
        <div className="ml-4">
          <Text className="pl-2 font-bold">Who do they have access to?</Text>
          <RadioGroup aria-label="team" value={haveAccessTo} onChange={onHaveAccessToChange}>
            <ActionSelectedItem
              ariaLabel="team-no-access"
              label="No access"
              value={UserPermissionRole.TeamNoAccess}
              selectedPermission={haveAccessTo}
            />
            <ActionSelectedItem
              ariaLabel="team-all-team-members"
              label="All team members"
              value={allTeamMembers}
              selectedPermission={haveAccessTo}
            />
          </RadioGroup>
          {haveAccessTo !== UserPermissionRole.TeamNoAccess && (
            <div className="pt-4">
              <Text className="pl-2 font-bold">What are their permissions?</Text>
              <RadioGroup
                aria-label="team"
                value={permissionRoleId}
                onChange={(value) => changePermissionForEachRole(value, 'team')}
              >
                <ActionSelectedItem
                  ariaLabel="team-view-only"
                  label="View only"
                  value={UserPermissionRole.TeamViewOnly}
                  selectedPermission={permissionRoleId}
                />
                <ActionSelectedItem
                  ariaLabel="team-manager"
                  label="Manage"
                  value={UserPermissionRole.TeamManager}
                  selectedPermission={permissionRoleId}
                  tooltipText="Managers have edit access to all team member profiles, and can also add and remove team members."
                />
              </RadioGroup>
            </div>
          )}
        </div>
      }
    />
  );
};
