import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { Col, Row, notification, Checkbox, Avatar, Modal } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { Tab, Tabs } from '@blueprintjs/core';
import { connect } from 'react-redux';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { IRootDispatch, dispatch, IRootState, state } from 'stores/rematch/root-store';
import { IServiceDateTimeForm, IServiceDateTimeFormUser } from 'interfaces/session-interfaces';
import { Paragraph, Text, Title } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { withRouter } from 'utilities/with-router';

import type { WithRouterProps } from 'utilities/with-router';

interface IRenderCustomerList extends WithRouterProps {
  selectedSession: typeof state.groupServiceStore.selectedSession;
  involvedCustomers: IServiceDateTimeFormUser[];
  onChange: (checkedValues: CheckboxValueType[]) => void;
}
const RenderCustomerList: React.FC<IRenderCustomerList> = ({ selectedSession, involvedCustomers, onChange }) => {
  const customers = useMemo(() => _.unionBy(selectedSession.customers, involvedCustomers, 'userId'), []);
  const involvedSessionCustomerIds = _.map(involvedCustomers, (c) => c.userId);

  return (
    <div className="border-top">
      <Checkbox.Group onChange={onChange} defaultValue={involvedSessionCustomerIds}>
        {!_.isEmpty(customers) ? (
          <>
            {_.map(customers, (c: IServiceDateTimeFormUser) => (
              <React.Fragment key={c.userId}>
                <Paragraph>
                  <Checkbox className="mr-small" value={c.userId}>
                    <Avatar className="mr-medium" size="default" icon="user" src={c.attachmentUrl} />
                    <Text>{`${c.userName}`}</Text>
                    {c.isNotPartOfSession && <Text color="secondary">{` (No longer part of session)`}</Text>}
                  </Checkbox>
                </Paragraph>
              </React.Fragment>
            ))}
          </>
        ) : (
          <Text color="secondary">No customer in this session</Text>
        )}
      </Checkbox.Group>
    </div>
  );
};

interface IRenderTeamMemberList {
  selectedSession: typeof state.groupServiceStore.selectedSession;
  involvedMembers: IServiceDateTimeFormUser[];
  onChange: (checkedValues: CheckboxValueType[]) => void;
}

const RenderTeamMemberList = ({ selectedSession, involvedMembers, onChange }: IRenderTeamMemberList) => {
  const supportWorkers = useMemo(() => _.unionBy(selectedSession.supportWorkers, involvedMembers, 'userId'), []);
  const involvedSessionMembersIds = _.map(involvedMembers, (m) => m.userId);

  return (
    <div className="border-top">
      <Checkbox.Group onChange={onChange} defaultValue={involvedSessionMembersIds}>
        {!_.isEmpty(supportWorkers) ? (
          <>
            {_.map(supportWorkers, (w: IServiceDateTimeFormUser) => (
              <Paragraph key={w.userId}>
                <Checkbox className="mr-small" value={w.userId}>
                  <Avatar className="mr-medium" size="default" icon="user" src={w.attachmentUrl} />
                  <Text>{`${w.userName}`}</Text>
                  {w.isNotPartOfSession && <Text color="secondary">{` (No longer part of session)`}</Text>}
                </Checkbox>
              </Paragraph>
            ))}
          </>
        ) : (
          <Text color="secondary">No team member in this session</Text>
        )}
      </Checkbox.Group>
    </div>
  );
};

interface IEditTeamMemberCustomerUrlParams {
  serviceId: string;
  serviceDateTimeId: string;
}

interface IEditCustomersTeamMembersModalProps
  extends RouteComponentProps<IEditTeamMemberCustomerUrlParams>,
    WithRouterProps {
  selectedSession: typeof state.groupServiceStore.selectedSession;
  attendanceForm: IServiceDateTimeForm;
  isOpen: boolean;
  onClose: () => void;
  doEditTeamMemberCustomerForm: typeof dispatch.groupServiceStore.doEditTeamMemberCustomerForm;
  fetchFormList: () => void;
}

const EditCustomersTeamMembersModal = ({
  selectedSession,
  isOpen,
  onClose,
  doEditTeamMemberCustomerForm,
  fetchFormList,
  attendanceForm,
  ...rest
}: IEditCustomersTeamMembersModalProps) => {
  const [selectedTabId, setSelectedTabId] = useState<string>('customers');

  const currentInvolvedCustomerIds = useMemo(
    () => _.map(attendanceForm.involvedCustomers, (c) => c.userId),
    [attendanceForm.involvedCustomers],
  );

  const currentInvolvedMemberIds = useMemo(
    () => _.map(attendanceForm.involvedMembers, (c) => c.userId),
    [attendanceForm.involvedMembers],
  );

  const [involvedCustomerIds, setInvolvedCustomerIds] = useState<string[]>(currentInvolvedCustomerIds);
  const [involvedMemberIds, setInvolvedMemberIds] = useState<string[]>(currentInvolvedMemberIds);

  const _onChangeCustomer = (checkValues) => {
    checkValues = _.isEmpty(checkValues) ? [] : checkValues;
    setInvolvedCustomerIds(checkValues);
  };

  const _onChangeInvolveMembers = (checkValues) => {
    checkValues = _.isEmpty(checkValues) ? [] : checkValues;
    setInvolvedMemberIds(checkValues);
  };

  const _onSave = async () => {
    try {
      const { serviceId, serviceDateTimeId } = rest.match.params;
      const { serviceDateTimeFormId } = attendanceForm;
      await doEditTeamMemberCustomerForm({
        serviceId,
        serviceDateTimeId,
        serviceDateTimeFormId,
        involvedCustomerIds,
        involvedMemberIds,
      });
      fetchFormList();
      onClose();
      notification.open({
        message: <span className={'text-weight-bolder'}>Linked profiles updated</span>,
        description: (
          <div>
            You have successfully updated the <br />
            team members and customers linked to this form.
          </div>
        ),
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  return (
    <>
      <ActionModal isOpen={isOpen} title={'Link people to this form'} onClose={onClose}>
        <Paragraph>
          Select the customer or team member profiles you’d like to link to this form, or continue without selecting
          any.
        </Paragraph>

        <Tabs
          large
          animate
          id="team-member-tabs"
          selectedTabId={selectedTabId}
          onChange={(tabId: string) => setSelectedTabId(tabId)}
          className="pv-medium"
        >
          <Tab
            id="customers"
            title="Customers"
            panel={
              <RenderCustomerList
                selectedSession={selectedSession}
                involvedCustomers={attendanceForm.involvedCustomers}
                onChange={_onChangeCustomer}
              />
            }
          />
          <Tab
            id="team"
            title="Team members"
            panel={
              <RenderTeamMemberList
                selectedSession={selectedSession}
                involvedMembers={attendanceForm.involvedMembers}
                onChange={_onChangeInvolveMembers}
              />
            }
          />
        </Tabs>

        <ActionModalFooter className={'mt-large'}>
          <Row type={'flex'} className={'justify-end'}>
            <Col className="mr-medium">
              <SecondaryButton size="large" onClick={onClose}>
                Cancel
              </SecondaryButton>
            </Col>
            <Col>
              <PrimaryButton onClick={_onSave} size="large">
                Save
              </PrimaryButton>
            </Col>
          </Row>
        </ActionModalFooter>
      </ActionModal>
    </>
  );
};

interface ISelectCustomersTeamMembersModalProps {
  selectedSession: typeof state.groupServiceStore.selectedSession;
  sessionInvolveUsers: typeof state.groupServiceStore.sessionInvolveUsers;
  selectedSessionInvolveUserIds: typeof state.groupServiceStore.selectedSessionInvolveUserIds;
  isOpen: boolean;
  onClose: () => void;
  onSave: any;
  setSelectedSessionInvolveUserIds: typeof dispatch.groupServiceStore.setSelectedSessionInvolveUserIds;
}

const SelectCustomersTeamMembersModal = ({
  selectedSession,
  sessionInvolveUsers,
  selectedSessionInvolveUserIds,
  isOpen,
  onClose,
  setSelectedSessionInvolveUserIds,
  onSave,
}: ISelectCustomersTeamMembersModalProps) => {
  const [selectedTabId, setSelectedTabId] = useState<string>('customers');

  const _onChangeInvolveMembers = (checkValues) => {
    checkValues = _.isEmpty(checkValues) ? [] : checkValues;
    setSelectedSessionInvolveUserIds({ ...selectedSessionInvolveUserIds, involvedMemberIds: checkValues });
  };

  const _onChangeCustomer = (checkValues) => {
    checkValues = _.isEmpty(checkValues) ? [] : checkValues;
    setSelectedSessionInvolveUserIds({ ...selectedSessionInvolveUserIds, involvedCustomerIds: checkValues });
  };

  const _onSave = () => {
    try {
      onSave();
      onClose();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  return (
    <>
      <Modal
        centered
        visible={isOpen}
        title={
          <Title level={4} className="mv-none">
            Link people to this form
          </Title>
        }
        onCancel={onClose}
        width="720px"
        footer={null}
      >
        <Paragraph>
          Select the customer or team member profiles you’d like to link to this form, or continue without selecting
          any.
        </Paragraph>

        <Tabs
          large
          animate
          id="team-member-tabs"
          selectedTabId={selectedTabId}
          onChange={(tabId: string) => setSelectedTabId(tabId)}
          className="pv-medium"
        >
          <Tab
            id="customers"
            title="Customers"
            panel={
              <RenderCustomerList
                selectedSession={selectedSession}
                involvedCustomers={sessionInvolveUsers.involvedCustomers}
                onChange={_onChangeCustomer}
              />
            }
          />
          <Tab
            id="team"
            title="Team members"
            panel={
              <RenderTeamMemberList
                selectedSession={selectedSession}
                involvedMembers={sessionInvolveUsers.involvedMembers}
                onChange={_onChangeInvolveMembers}
              />
            }
          />
        </Tabs>

        <ActionModalFooter className={'mt-large'}>
          <Row type={'flex'} className={'justify-end'}>
            <Col className="mr-medium">
              <SecondaryButton size="large" onClick={onClose}>
                Cancel
              </SecondaryButton>
            </Col>
            <Col>
              <PrimaryButton onClick={_onSave} size="large">
                Save
              </PrimaryButton>
            </Col>
          </Row>
        </ActionModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  selectedSessionInvolveUserIds: state.groupServiceStore.selectedSessionInvolveUserIds,
  selectedSession: state.groupServiceStore.selectedSession,
  sessionInvolveUsers: state.groupServiceStore.sessionInvolveUsers,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doEditTeamMemberCustomerForm: dispatch.groupServiceStore.doEditTeamMemberCustomerForm,
  setSelectedSessionInvolveUserIds: dispatch.groupServiceStore.setSelectedSessionInvolveUserIds,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditCustomersTeamMembersModal));

const SelectCustomersTeamMembersModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectCustomersTeamMembersModal);

export { SelectCustomersTeamMembersModalConnected as SelectCustomersTeamMembersModal };
