import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Battery2 = forwardRef(function Battery2(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.25h-.055c-1.367 0-2.47 0-3.337.117-.9.12-1.658.38-2.26.981-.602.602-.86 1.36-.981 2.26-.117.867-.117 1.97-.117 3.337v.11c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116H14.055c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.521-.522.785-1.16.925-1.907.168-.007.319-.02.46-.048a2.75 2.75 0 0 0 2.16-2.16c.053-.268.053-.571.053-.964v-1.146c0-.393 0-.696-.053-.963a2.75 2.75 0 0 0-2.16-2.161 2.904 2.904 0 0 0-.46-.048c-.14-.746-.404-1.385-.925-1.907-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117H8Zm12.725 4.52c.025.635.025 1.358.025 2.175v.11c0 .817 0 1.54-.025 2.174a1.25 1.25 0 0 0 1-.986c.021-.102.025-.242.025-.743v-1c0-.5-.004-.641-.024-.744a1.25 1.25 0 0 0-1-.985ZM3.41 7.41c.277-.277.665-.457 1.4-.556C5.563 6.752 6.564 6.75 8 6.75h6c1.435 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4.102.754.103 1.756.103 3.191 0 1.435-.002 2.437-.103 3.192-.099.734-.28 1.122-.556 1.399-.277.277-.665.457-1.4.556-.754.101-1.755.103-3.19.103H8c-1.436 0-2.437-.002-3.193-.103-.734-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191 0-1.435.002-2.437.103-3.192.099-.734.28-1.122.556-1.399ZM6.75 10a.75.75 0 0 0-1.5 0v4a.75.75 0 0 0 1.5 0v-4ZM11 9.25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0v-4a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
