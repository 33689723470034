// ? ApiClient for accessing the API endpoints
import axios, { AxiosRequestConfig, type AxiosInstance, AxiosResponse } from 'axios';
import { getAuth, signOut } from 'firebase/auth';
import history from '../globalHistory';
import firebaseApp from '../stores/firebase-app';
import rootStore from '../stores/rematch/root-store';
import { APP_CONFIG } from '../config/app-config';
import Utils from './Utils';
import type { LegacyStructuredResponseBody } from '@utils/new-common-utils';
import { backOff, BackoffOptions } from 'exponential-backoff';

const { isEmpty } = Utils;

const apiBaseUrl = APP_CONFIG.API_URL;

type InterceptedResBody<TRes> = TRes extends LegacyStructuredResponseBody<infer Data> ? Data : TRes;
type InterceptedAxiosRes<TRes, TReq> = AxiosResponse<InterceptedResBody<TRes>, TReq>;

const backoffOptions: BackoffOptions = {
  maxDelay: 3000,
  numOfAttempts: 6,
  startingDelay: 1000,
  timeMultiple: 2,
  retry: (e) => e?.status === 502, // retry only on 502 errors
};

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */

const getClient = (baseUrl = apiBaseUrl) => {
  // ? Use interceptors instead as the token will keep changing
  const client = axios.create({
    baseURL: baseUrl,
  });

  // Add a request interceptor
  client.interceptors.request.use(
    async (requestConfig) => {
      const { currentUser } = getAuth();
      if (!currentUser) {
        // eslint-disable-next-line no-console -- no consolidated logging approach yet
        console.warn('Warning - no current user for Firebase. No Authorization Bearer token will be passed in');
        return requestConfig;
      }

      if (!requestConfig.headers) requestConfig.headers = {};

      // TODO note: This is assuming that getIdToken will cache and refresh as necessary. Need to test it out.
      const idToken = await currentUser.getIdToken();
      requestConfig.headers['Authorization'] = `Bearer ${idToken}`;

      const authStore = rootStore.getState().authStore;
      const serviceProviderId =
        authStore.serviceProviderId ??
        authStore.portalUser?.serviceProviderId ??
        window.sessionStorage.getItem('serviceProviderId');
      if (serviceProviderId) requestConfig.headers['serviceproviderid'] = serviceProviderId;

      return requestConfig;
    },
    (requestError) => {
      // TODO : Send this over to a logging facility
      console.error(requestError); // eslint-disable-line no-console -- no consolidated logging approach yet
      throw requestError;
    },
  );

  /**
   * ! Responses are (sometimes) wrapped with the following object format:
   * ```
   *    {
   *      status   : http status code
   *      data     : actual data
   *      meta     : meta response
   *      message  : error message (only for errors)
   *    }
   * ```
   * @see LegacyStructuredResponseBody (and `resStructure` in the same file, which wraps the response)
   */
  client.interceptors.response.use(
    (response) => {
      const { status, data: rawData } = response;
      let data, meta;

      // Prevent undefined errors later...
      if (isEmpty(rawData)) {
        data = {};
        meta = {};
      } else if (rawData?.data) {
        data = rawData.data;
        meta = rawData.meta;
      } else {
        data = rawData;
        meta = rawData?.meta ?? {};
      }

      return { status, data, meta };
    },
    async (error) => {
      // Handle exceptions/invalid logs here
      const { status, data: rawData } = error.response;

      let data, meta;

      if (status === 401) {
        rootStore.dispatch.authStore.setIsUserBlocked(true);
        rootStore.dispatch.authStore.setIsSignOut(false);
        await signOut(firebaseApp.auth);
      }

      // Prevent undefined errors later...
      if (isEmpty(rawData)) {
        data = {};
        meta = {};
      } else {
        data = rawData.data;
        meta = rawData.meta;
      }

      if (meta && meta.message === 'Access Denied') {
        history.push('/access-denied');
      }

      throw { status, data, meta, message: error.message };
    },
  );

  return client;
};

export class ApiClient {
  readonly client: AxiosInstance;

  constructor(baseUrl?: string) {
    this.client = getClient(baseUrl);
  }

  async get<TRes, TReq extends never = never>(url: string, conf?: AxiosRequestConfig<TReq>) {
    return await backOff(
      () => this.client.get<InterceptedResBody<TRes>, InterceptedAxiosRes<TRes, TReq>, TReq>(url, conf),
      backoffOptions,
    );
  }

  async delete<TRes, TReq = never>(url: string, data?: TReq, conf?: AxiosRequestConfig<TReq>) {
    return await backOff(
      () =>
        this.client.delete<InterceptedResBody<TRes>, InterceptedAxiosRes<TRes, TReq>, TReq>(url, {
          data,
          ...conf,
        }),
      backoffOptions,
    );
  }

  async head<TRes, TReq extends never = never>(url: string, conf?: AxiosRequestConfig<TReq>) {
    return await backOff(
      () => this.client.head<InterceptedResBody<TRes>, InterceptedAxiosRes<TRes, TReq>, TReq>(url, conf),
      backoffOptions,
    );
  }

  async options<TRes, TReq extends never = never>(url: string, conf?: AxiosRequestConfig) {
    return await backOff(
      () => this.client.options<InterceptedResBody<TRes>, InterceptedAxiosRes<TRes, TReq>, TReq>(url, conf),
      backoffOptions,
    );
  }

  async post<TRes, TReq = unknown>(url: string, data?: TReq, conf?: AxiosRequestConfig<TReq>) {
    return await backOff(
      () => this.client.post<InterceptedResBody<TRes>, InterceptedAxiosRes<TRes, TReq>, TReq>(url, data, conf),
      backoffOptions,
    );
  }

  async put<TRes, TReq = unknown>(url: string, data?: TReq, conf?: AxiosRequestConfig<TReq>) {
    return await backOff(
      () => this.client.put<InterceptedResBody<TRes>, InterceptedAxiosRes<TRes, TReq>, TReq>(url, data, conf),
      backoffOptions,
    );
  }

  async patch<TRes, TReq = unknown>(url: string, data?: TReq, conf?: AxiosRequestConfig<TReq>) {
    return await backOff(
      () => this.client.patch<InterceptedResBody<TRes>, InterceptedAxiosRes<TRes, TReq>, TReq>(url, data, conf),
      backoffOptions,
    );
  }
}

/**
 * ? Default imports an instantiated client.
 * ? Alternatively, construct it yourself if there's another url that you need to access.
 *
 * TODO: remove default export and only use named export.
 *
 * @example const client = new ApiClient('baseUrl');
 */
export const apiClient = new ApiClient();
export default apiClient; // eslint-disable-line import/no-default-export -- retaining for legacy

export function pickData<T>({ data }: { data: T }): T {
  return data;
}

export const apiUrl = (path: string) => `${APP_CONFIG.API_URL}/api/portal/${path}`;

export const apiCloudFunctionClient = new ApiClient(APP_CONFIG.CLOUD_FUNCTION_URL);
