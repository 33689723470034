import { Slot } from '@radix-ui/react-slot';
import { filterDOMProps, mergeRefs } from '@react-aria/utils';
import { createContext, forwardRef, useRef } from 'react';

import * as styles from './heading.css';
import { twMerge, useContextProps } from '../utils';

import type { HTMLProps, SlotProps } from '../types';
import type { HeadingVariants } from './heading.css';
import type { PropsWithChildren, Ref } from 'react';

export type HeadingProps = PropsWithChildren<HeadingVariants & SlotProps & HTMLProps<HTMLHeadingElement>>;

export type HeadingProviderProps = PropsWithChildren<{
  headingRef?: Ref<HTMLHeadingElement>;
}> &
  HeadingProps;

export const HeadingContext = createContext<HeadingProviderProps>({} as HeadingProviderProps);

export function HeadingProvider(props: HeadingProviderProps) {
  const { children, ...value } = props;
  return <HeadingContext.Provider value={value}>{children}</HeadingContext.Provider>;
}

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(function Heading(props, ref) {
  const {
    asChild,
    children,
    className,
    headingRef = null,
    size = 'md',
    ...otherProps
  } = useContextProps<HeadingProviderProps>(HeadingContext, props);

  const ElementType = asChild ? Slot : 'h3';
  const mergedRef = mergeRefs(headingRef, ref) || useRef(null);

  return (
    <ElementType
      {...filterDOMProps(otherProps)}
      className={twMerge(styles.heading({ size }), className)}
      ref={mergedRef}
    >
      {children}
    </ElementType>
  );
});
