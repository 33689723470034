import { notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { WorkflowTemplateStatus } from 'utilities/enum-utils';

const titleWorkflowTemplateActionModal = {
  [WorkflowTemplateStatus.ACTIVE]: 'De-activate workflow',
  [WorkflowTemplateStatus.INACTIVE]: 'Activate workflow',
};

const updateStatusWorkflowNotifications = {
  [WorkflowTemplateStatus.ACTIVE]: {
    message: 'Workflow activated',
    description: 'You have successfully activated this workflow',
  },
  [WorkflowTemplateStatus.INACTIVE]: {
    message: 'Workflow de-activated',
    description: 'You have successfully de-activated this workflow',
  },
};

interface IWorkflowTemplateActionModalState {
  isLoading: boolean;
}

interface IWorkflowTemplateActionModalProps {
  isOpen: any;
  onClose: any;
  selectedWorkflowTemplate: typeof state.workflowStore.selectedWorkflowTemplate;
  doUpdateWorkflowTemplateConfigurations: typeof dispatch.workflowStore.doUpdateWorkflowTemplateConfigurations;
}

class WorkflowTemplateActionModal extends PureComponent<
  IWorkflowTemplateActionModalProps,
  IWorkflowTemplateActionModalState
> {
  state = {
    isLoading: false,
  };

  private _onCloseModal = () => {
    this.props.onClose();
  };

  private _onClickWorkflowAction = async () => {
    const { selectedWorkflowTemplate, doUpdateWorkflowTemplateConfigurations } = this.props;

    this.setState({ isLoading: true });
    const status = selectedWorkflowTemplate.status;

    if (status) {
      const newStatus =
        status === WorkflowTemplateStatus.ACTIVE ? WorkflowTemplateStatus.INACTIVE : WorkflowTemplateStatus.ACTIVE;
      const isUpdatedSuccessfully = await doUpdateWorkflowTemplateConfigurations({ status: newStatus });

      isUpdatedSuccessfully
        ? notification.success({ ...updateStatusWorkflowNotifications[newStatus] })
        : notification.error({ message: 'Updated failed' });
    }

    this.setState({ isLoading: false });
    this.props.onClose();
  };

  render() {
    const { isLoading } = this.state;
    const { selectedWorkflowTemplate } = this.props;

    return (
      <ActionModal
        title={
          <Text className={'text-size-x2-large'}>
            {titleWorkflowTemplateActionModal[selectedWorkflowTemplate.status]}
          </Text>
        }
        isOpen={this.props.isOpen}
        onClose={this._onCloseModal}
        width="medium"
      >
        <div className="anim-slide-left">
          {selectedWorkflowTemplate.status === WorkflowTemplateStatus.ACTIVE ? (
            <>
              <Paragraph>Are you sure you want to de-activate this workflow?</Paragraph>
              <Paragraph>
                Currently active workflows will not be affected but new workflows will not be able to be generated.
              </Paragraph>
            </>
          ) : (
            <Paragraph>Are you sure you want to activate this workflow?</Paragraph>
          )}

          <ActionModalFooter>
            <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" loading={isLoading} onClick={this._onClickWorkflowAction}>
              {titleWorkflowTemplateActionModal[selectedWorkflowTemplate.status]}
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedWorkflowTemplate: state.workflowStore.selectedWorkflowTemplate,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateWorkflowTemplateConfigurations: dispatch.workflowStore.doUpdateWorkflowTemplateConfigurations,
});

export default connect(mapState, mapDispatch)(WorkflowTemplateActionModal);
