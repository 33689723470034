import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FirstLine1 = forwardRef(function FirstLine1(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3.25h-.034c-.206 0-.427 0-.615.025-.219.03-.5.105-.735.341a1.239 1.239 0 0 0-.341.735c-.025.188-.025.41-.025.615V8.034c0 .206 0 .427.025.615.03.219.105.5.341.735.236.236.516.312.735.341.188.025.41.025.615.025H8.034c.206 0 .427 0 .615-.025.219-.03.5-.105.735-.341.236-.236.312-.516.341-.735.025-.188.025-.41.025-.615V4.966c0-.206 0-.427-.025-.615-.03-.219-.105-.5-.341-.735a1.239 1.239 0 0 0-.735-.341c-.188-.025-.41-.025-.615-.025H5ZM4.75 5l.001-.249L5 4.75h3l.249.001L8.25 5v3l-.001.249L8 8.25H5l-.249-.001L4.75 8V5Zm7.5-1a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm-9 15a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75ZM13 8.25a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7ZM3.25 14a.75.75 0 0 1 .75-.75h16a.75.75 0 1 1 0 1.5H4a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
