import { message } from 'antd';
import { ICustomView } from 'interfaces/custom-views-interface';
import {
  IAddCustomerDetailWizard,
  IAddCustomerDetailWizardState,
  IAddCustomerGuardianDetailWizard,
  ICareInfo,
  ICustomer,
  ICustomerBookingAttachments,
  ICustomerDocument,
  ICustomerForm,
  ICustomerListItem,
  ICustomerNotes,
  IGroupServiceCustomerListItem,
  IMatchingUser,
  IPinnedAlert,
  ISelectedCustomerState,
} from 'interfaces/customer-interfaces';
import _ from 'lodash';
import moment from 'moment';
import rootStore, { IRootState } from 'stores/rematch/root-store';
import { apiClient, apiCloudFunctionClient } from 'utilities/api-client';
import asyncDelay from 'utilities/asyncDelay';
import {
  BookingStatus,
  CareInformationType,
  CustomerStatusType,
  CustomViewsType,
  DocumentExpiryStatus,
  DocumentFileType,
  FilterType,
} from 'utilities/enum-utils';
import Utils from 'utilities/Utils';
import { events } from 'integrations/appcues';
import { withBackwardsCompatibleChildElements } from 'views/form-builder/shared/form-builder-utils';

const formatNoteFilters = (payload) => {
  let body = payload;
  // Transform the filters array into objects and remove the empty filters.
  const filters = _.chain(_.filter(body, (filter) => !Utils.isEmpty(filter.values)))
    .keyBy('filter')
    .mapValues('values')
    .value();
  // Merge back the filters into the payload
  body = { ...payload, ...filters };

  if (body[FilterType.DATE_RANGE] && !_.isEmpty(body[FilterType.DATE_RANGE])) {
    body['endDate'] = body[FilterType.DATE_RANGE][1];
    body['startDate'] = body[FilterType.DATE_RANGE][0];
  }

  if (body[FilterType.IS_INCIDENT]) {
    const isIncidentTrue = !!_.find(body[FilterType.IS_INCIDENT], (filter) => filter === 'YES');
    const isIncidentFalse = !!_.find(body[FilterType.IS_INCIDENT], (filter) => filter === 'NO');
    // If both yes and no are selected, remove the filter to get the full list
    if (isIncidentTrue && isIncidentFalse) {
      delete body[FilterType.IS_INCIDENT];
    } else {
      body[FilterType.IS_INCIDENT] = isIncidentTrue;
    }
  }

  if (body[FilterType.NOTE_TYPE]) {
    if (body[FilterType.NOTE_TYPE].length < 2) {
      body[FilterType.NOTE_TYPE] = body[FilterType.NOTE_TYPE][0];
    } else {
      delete body[FilterType.NOTE_TYPE];
    }
  }

  // Change specific names (noteService, noteAuthorIds) to generic names for API
  if (body[FilterType.NOTE_SERVICE]) {
    body.serviceIds = body[FilterType.NOTE_SERVICE];
    delete body[FilterType.NOTE_SERVICE];
  }

  if (body[FilterType.NOTE_AUTHOR]) {
    body.authorIds = body[FilterType.NOTE_AUTHOR];
    delete body[FilterType.NOTE_AUTHOR];
  }

  return body;
};

const formatCustomerListingFilter = (payload) => {
  let body = { ...payload };

  if (body && body.filters) {
    // Transform the filters array into objects and remove the empty filters.
    const filters = _.chain(_.filter(body.filters, (filter) => !Utils.isEmpty(filter.values)))
      .keyBy('filter')
      .mapValues('values')
      .value();
    // Merge back the filters into the payload
    body = { ...body, ...filters };
    // Delete the 'filters' props to have a lighter payload.
    delete body.filters;

    if (body[FilterType.CUSTOMER]) {
      body[FilterType.CUSTOMER] = _.map(body[FilterType.CUSTOMER], (item) => item.value);
    }

    if (body[FilterType.PREFERRED_SUPPORT_WORKER]) {
      body[FilterType.PREFERRED_SUPPORT_WORKER] = _.map(
        body[FilterType.PREFERRED_SUPPORT_WORKER],
        (item) => item.value,
      );
    }

    if (body[FilterType.BLOCKED_SUPPORT_WORKER]) {
      body[FilterType.BLOCKED_SUPPORT_WORKER] = _.map(body[FilterType.BLOCKED_SUPPORT_WORKER], (item) => item.value);
    }

    if (body[FilterType.PINNED_ALERTS]) {
      const isPinnedAlertTrue = !!_.find(body[FilterType.PINNED_ALERTS], (filter) => filter === 'YES');
      const isPinnedAlertFalse = !!_.find(body[FilterType.PINNED_ALERTS], (filter) => filter === 'NO');
      // If both yes and no are selected, remove the filter to get the full list
      if (isPinnedAlertTrue && isPinnedAlertFalse) {
        delete body[FilterType.PINNED_ALERTS];
      } else {
        body[FilterType.PINNED_ALERTS] = isPinnedAlertTrue;
      }
    }

    if (body[FilterType.CUSTOMER_MANAGED_BY]) {
      const isDependent = !!_.find(body[FilterType.CUSTOMER_MANAGED_BY], (filter) => filter === 'YES');
      const isIndependent = !!_.find(body[FilterType.CUSTOMER_MANAGED_BY], (filter) => filter === 'NO');
      // If both yes and no are selected, remove the filter to get the full list
      if (isDependent && isIndependent) {
        delete body[FilterType.CUSTOMER_MANAGED_BY];
      } else {
        body[FilterType.CUSTOMER_MANAGED_BY] = isDependent;
      }
    }

    if (body[FilterType.CASE_MANAGER]) {
      body[FilterType.CASE_MANAGER] = _.map(body[FilterType.CASE_MANAGER], (item) => item.value);
    }

    if (body[FilterType.SUPPORT_COORDINATOR]) {
      body[FilterType.SUPPORT_COORDINATOR] = _.map(body[FilterType.SUPPORT_COORDINATOR], (item) => item.value);
    }
  }
  return body;
};

const mapCustomerViewsFilterPayload = (filterValue) => {
  // ignore search object in filterValue
  // re map filter type customer
  const payload =
    filterValue &&
    _.cloneDeep(filterValue)
      .filter((filter) => !Object.keys(filter).includes('search'))
      .map((filter) => {
        if (Object.keys(filter).includes(FilterType.CUSTOMER)) {
          filter[FilterType.CUSTOMER] = _.map(filter[FilterType.CUSTOMER], (customer) => {
            const { name, value, displayText } = customer;
            return { displayText: name || displayText, value };
          });
        }
        return filter;
      });
  return payload;
};

interface ICustomerStoreState {
  customers: ICustomerListItem[];
  customersLite: ICustomerListItem[];
  addCustomerDetailWizard: IAddCustomerDetailWizard;
  addCustomerDetailWizardState: IAddCustomerDetailWizardState;
  addLegalGuardianDetailWizard: IAddCustomerGuardianDetailWizard[];
  filteredCustomers: ICustomerListItem[];
  filteredCustomersLite: ICustomerListItem[];
  selectedCustomer: ICustomer;
  selectedCustomerUserId: string;
  selectedCustomerState: ISelectedCustomerState;
  bookSelectedCustomer: ICustomer;
  bookSelectedCustomerId: string;
  customerFilter: any;
  customerFilterLite: any;
  relationships: any;
  fundingData: any;
  addedCustomerUserId: string;
  customerDocuments: ICustomerDocument[];
  customerBookingAttachments: ICustomerBookingAttachments[];
  serviceAgreementSearchServiceResult: any;
  userServiceAgreementBillingLineItems: any;
  supportNeedCategories: any;
  supportNeedCategoriesNeeds: any;
  customersAtAGlance: any;
  newServiceAgreement: any;
  customerServiceAgreements: any;
  customerNotes: ICustomerNotes[];
  customerNotesFilters: Array<any>;
  customerNotePage: number;
  customerNotePageSize: number;
  customerNotePageTimeStamp: Date;
  customerNoteAuthors: any;
  customerNoteServices: any;
  customerForms: ICustomerForm[];
  // TODO: serviceAgreementHistory & serviceAgreementHistories interface
  customerServiceAgreementHistories: any;
  selectedCustomerServiceAgreementHistory: any;
  selectedCustomersForFilter: any;
  customerPinnedAlerts: IPinnedAlert[];
  groupServiceCustomers: IGroupServiceCustomerListItem[];
  disabilityInfoLiteList: any;
  hasCustomerFilterChanged: boolean;
  displayedCustomerListingTabs: ICustomView[];
  customerListingActiveTab: ICustomView;
  customerViews: ICustomView[];
  defaultCustomerViews: ICustomView[];
  selectedCustomerCareInfoList: ICareInfo[];
  supportCoordinatorFilter: { search?: string; serviceType?: string };
  supportCoordinators: Array<any>;
  caseManagerFilter: string;
  caseManagers: Array<any>;
  refreshList: boolean;
  isAddingCustomerAddress: boolean;
}

const initialState: ICustomerStoreState = {
  customers: [],
  customersLite: [],
  addCustomerDetailWizard: null,
  addCustomerDetailWizardState: {
    isManualAddress: false,
    addressLookup: '',
  },
  addLegalGuardianDetailWizard: [],
  filteredCustomers: [],
  filteredCustomersLite: [],
  selectedCustomer: null,
  selectedCustomerUserId: null,
  selectedCustomerState: {
    isCreatePackageModalOpen: false,
  },
  bookSelectedCustomer: null,
  bookSelectedCustomerId: null,
  customerFilter: [],
  customerFilterLite: [],
  relationships: [],
  fundingData: [],
  addedCustomerUserId: null,
  customerDocuments: [],
  customerBookingAttachments: [],
  serviceAgreementSearchServiceResult: [],
  userServiceAgreementBillingLineItems: [],
  supportNeedCategories: [],
  supportNeedCategoriesNeeds: [],
  customersAtAGlance: null,
  newServiceAgreement: null,
  customerServiceAgreements: [],
  customerNotes: [],
  customerNotesFilters: [],
  customerNotePage: 1,
  customerNotePageSize: 10,
  customerNotePageTimeStamp: new Date(),
  customerNoteAuthors: [],
  customerNoteServices: [],
  customerForms: [],
  customerServiceAgreementHistories: [],
  selectedCustomerServiceAgreementHistory: null,
  selectedCustomersForFilter: null,
  customerPinnedAlerts: [],
  groupServiceCustomers: [],
  disabilityInfoLiteList: [],
  hasCustomerFilterChanged: false,
  refreshList: false,
  customerViews: [],
  displayedCustomerListingTabs: [],
  customerListingActiveTab: null,
  selectedCustomerCareInfoList: [],
  defaultCustomerViews: [
    {
      customViewId: 'CUSTOMERS',
      name: 'Customers',
      isPinned: true,
      isDefault: true,
      isCustomer: true,
      viewType: CustomViewsType.EVERYONE,
      filterValue: [
        { [FilterType.CUSTOMER]: [] },
        { [FilterType.SERVICE]: [] },
        { [FilterType.CUSTOMER_STATUS]: [CustomerStatusType.ACTIVE] },
      ],
    },
    {
      customViewId: 'PARENT_GUARDIANS',
      name: 'Parents/Guardians',
      isPinned: true,
      isDefault: true,
      isCustomer: false,
      viewType: CustomViewsType.EVERYONE,
      filterValue: [{ [FilterType.CUSTOMER_CONNECTION_STATUS]: [] }, { [FilterType.CUSTOMER]: [] }],
    },
    {
      customViewId: 'ENQUIRIES',
      name: 'Enquiries',
      isPinned: true,
      isDefault: true,
      isCustomer: true,
      viewType: CustomViewsType.EVERYONE,
      filterValue: [
        { [FilterType.CUSTOMER]: [] },
        { [FilterType.SERVICE]: [] },
        { [FilterType.CUSTOMER_STATUS]: ['ENQUIRY'] },
      ],
    },
  ],
  supportCoordinatorFilter: {},
  supportCoordinators: [],
  caseManagerFilter: null,
  caseManagers: [],
  isAddingCustomerAddress: false,
};

const customersStore = {
  state: initialState,
  reducers: {
    setCustomers: (state, payload) => ({ ...state, customers: payload }),
    setCustomersLite: (state, payload) => ({ ...state, customersLite: payload }),
    setFilteredCustomers: (state, payload) => ({ ...state, filteredCustomers: payload }),
    setFilteredCustomersLite: (state, payload) => ({ ...state, filteredCustomersLite: payload }),
    setSelectedCustomer: (state, payload) => ({ ...state, selectedCustomer: payload }),
    setSelectedCustomerUserId: (state, payload) => ({ ...state, selectedCustomerUserId: payload.customerUserId }),
    setSelectedCustomerCareInfoList: (state, payload) => ({ ...state, selectedCustomerCareInfoList: payload }),
    addServiceToServiceAgreement: (state, payload) => {
      const serviceAgreement = state.selectedCustomer.serviceAgreement;
      serviceAgreement[0].services.push(payload);
      const newServiceAgreement = [
        {
          ...serviceAgreement[0],
          services: serviceAgreement[0].services,
        },
      ];
      const newSelectedCustomer = { ...state.selectedCustomer, serviceAgreement: newServiceAgreement };
      return { ...state, selectedCustomer: newSelectedCustomer };
    },
    deleteServiceFromServiceAgreement: (state, payload) => {
      const serviceAgreement = state.selectedCustomer.serviceAgreement;
      const newServiceAgreement = [
        {
          ...serviceAgreement[0],
          services: _.filter(serviceAgreement[0].services, (service) => service.serviceId !== payload),
        },
      ];
      const newSelectedCustomer = { ...state.selectedCustomer, serviceAgreement: newServiceAgreement };
      return { ...state, selectedCustomer: newSelectedCustomer };
    },
    setUserServiceAgreementTermsConditions: (state, payload) => {
      const serviceAgreement = state.selectedCustomer.serviceAgreement;
      const newServiceAgreement = [
        {
          ...serviceAgreement[0],
          termsConditions: payload.termsConditions,
        },
      ];
      const newSelectedCustomer = { ...state.selectedCustomer, serviceAgreement: newServiceAgreement };
      return { ...state, selectedCustomer: newSelectedCustomer };
    },
    setUserNdisInfo: (state, payload) => {
      const newState = { ...state };
      newState.selectedCustomer.ndis = payload;

      return newState;
    },
    setBookSelectedCustomer: (state, payload) => ({ ...state, bookSelectedCustomer: payload }),
    setBookSelectedCustomerId: (state, payload) => ({ ...state, bookSelectedCustomerId: payload }),
    setCustomerFilter: (state, payload) => ({ ...state, customerFilter: payload }),
    setHasCustomerFilterChanged: (state, payload) => ({ ...state, hasCustomerFilterChanged: payload }),
    setRefreshList: (state, payload) => ({ ...state, refreshList: payload }),

    setCustomerFilterLite: (state, payload) => ({ ...state, customerFilterLite: payload }),
    setCustomerRelationships: (state, payload) => ({ ...state, relationships: payload }),
    setFundingData: (state, payload) => ({ ...state, fundingData: payload }),
    setSupportNeedCategories: (state, payload) => ({ ...state, supportNeedCategories: payload }),
    setCustomersAtAGlance: (state, payload) => ({ ...state, customersAtAGlance: payload }),
    setNewServiceAgreement: (state, payload) => ({ ...state, newServiceAgreement: payload }),
    setCustomerServiceAgreements: (state, payload) => ({
      ...state,
      customerServiceAgreements: payload,
    }),
    setSupportNeedCategoriesNeeds: (state, payload) => ({
      ...state,
      supportNeedCategoriesNeeds: state.supportNeedCategoriesNeeds.concat([payload]),
    }),
    setAddCustomerDetailWizard: (state, payload) => ({ ...state, addCustomerDetailWizard: payload }),
    setAddCustomerDetailWizardState: (state, payload: Partial<IAddCustomerDetailWizardState>) => ({
      ...state,
      addCustomerDetailWizardState: { ...state.addCustomerDetailWizardState, ...payload },
    }),
    setAddLegalGuardianDetailWizard: (state, payload) => ({ ...state, addLegalGuardianDetailWizard: payload }),

    setAddedCustomerUserId: (state, payload) => ({ ...state, addedCustomerUserId: payload }),
    setServiceAgreementSearchServiceResult: (state, payload) => ({
      ...state,
      serviceAgreementSearchServiceResult: payload,
    }),
    resetUserServiceAgreementBillingLineItems: (state, payload) => ({
      ...state,
      userServiceAgreementBillingLineItems: [],
    }),
    setCustomerDocuments: (state, payload) => ({ ...state, customerDocuments: payload }),
    setCustomerBookingAttachments: (state, payload) => ({ ...state, customerBookingAttachments: payload }),
    setCustomerNotes: (state, payload) => ({ ...state, customerNotes: payload }),
    setCustomerNotesFilters: (state, payload) => ({ ...state, customerNotesFilters: payload }),
    setCustomerNotesPageInfo: (state, payload) => ({
      ...state,
      customerNotePage: payload.customerNotePage,
      customerNotePageSize: payload.customerNotePageSize,
      customerNotePageTimeStamp: payload.customerNotePageTimeStamp,
    }),
    resetCustomerNotesPageInfo: (state, payload) => ({
      ...state,
      customerNotePage: 1,
      customerNotePageSize: 10,
      customerNotePageTimeStamp: new Date(),
    }),
    setCustomerForms: (state, payload) => ({ ...state, customerForms: payload }),
    setCustomerNoteAuthors: (state, payload) => ({ ...state, customerNoteAuthors: payload }),
    setCustomerNoteServices: (state, payload) => ({ ...state, customerNoteServices: payload }),
    removeCustomerDocument: (state, payload) => ({
      ...state,
      customerDocuments: _.filter(state.customerDocuments, (document) => document.documentId !== payload),
    }),
    archiveCustomerDocument: (state, payload) => ({
      ...state,
      customerDocuments: _.map(state.customerDocuments, (document) => {
        if (document.documentId === payload) {
          return { ...document, isArchived: true };
        } else {
          return { ...document };
        }
      }),
    }),
    addCustomerDocument: (state, payload) => {
      const existingDocuments = state.customerDocuments;
      const addedDocument = [
        {
          documentId: payload.documentId,
          documentName: payload.documentName,
          description: payload.description,
          createdOn: new Date(),
          documentUrl: null,
          status: 'SCANNING',
          firstName: payload.firstName,
          lastName: payload.lastName,
          expiryDate: payload.expiryDate,
          expiryStatus: payload.expiryStatus,
          isArchived: false,
          isViewableOnApp: payload.isViewableOnApp,
        },
      ];
      return { ...state, customerDocuments: addedDocument.concat(existingDocuments) };
    },
    editCustomerDocument: (state, payload) => {
      return {
        ...state,
        customerDocuments: _.map(state.customerDocuments, (document) => {
          if (document.documentId === payload.documentId) {
            return {
              ...document,
              description: payload.description,
              expiryDate: payload.expiryDate,
              expiryStatus: !payload.expiryDate
                ? DocumentExpiryStatus.NOT_EXPIRED
                : payload.expiryDate < moment().startOf('day')
                ? DocumentExpiryStatus.EXPIRED
                : moment(payload.expiryDate).startOf('day').diff(moment().startOf('day'), 'days') <= 7
                ? DocumentExpiryStatus.EXPIRING_SOON
                : DocumentExpiryStatus.NOT_EXPIRED,
            };
          } else {
            return { ...document };
          }
        }),
      };
    },
    appendCustomerDocuments: (state, payload) => {
      const existingDocuments = state.customerDocuments;
      return { ...state, customerDocuments: existingDocuments.concat(payload) };
    },
    appendCustomerBookingAttachments: (state, payload) => {
      const existingCustomerBookingAttachments = state.customerBookingAttachments;
      return { ...state, customerBookingAttachments: existingCustomerBookingAttachments.concat(payload) };
    },
    addUserGuardiansInfo: (state, payload) => {
      const selectedCustomer = { ...state.selectedCustomer };

      selectedCustomer.guardiansInfo = selectedCustomer.guardiansInfo.concat(payload);

      selectedCustomer.isDependent = true;
      selectedCustomer.isIndependent = false;

      return { ...state, selectedCustomer };
    },
    cancelCustomerConnection: (state, payload) => {
      const customer = { ...state.selectedCustomer };
      customer.customerConnectionRequests = _.map(customer.customerConnectionRequests, (connection) => {
        if (connection.customerConnectionRequestId === payload.customerConnectionRequestId) {
          connection.status = 'CANCELLED';
        }
        return connection;
      });
      return { ...state, selectedCustomer: customer };
    },
    setCustomerDocumentStatus: (state, payload) => {
      const updatedDocuments = _.map(state.customerDocuments, (document) => {
        if (document.documentId === payload.documentId) {
          return {
            ...document,
            status: payload.status,
            documentUrl: payload.documentUrl,
          };
        } else {
          return { ...document };
        }
      });
      return { ...state, customerDocuments: updatedDocuments };
    },
    setCustomerServiceAgreementHistories: (state, payload) => {
      return { ...state, customerServiceAgreementHistories: payload };
    },
    setSelectedCustomerServiceAgreementHistory: (state, payload) => {
      return { ...state, selectedCustomerServiceAgreementHistory: payload };
    },
    setSelectedCustomerServiceAgreement: (state, payload) => {
      const newCustomerServiceAgreements = _.map(state.customerServiceAgreements, (serviceAgreement) => {
        if (serviceAgreement.userServiceAgreementId === payload.userServiceAgreementId) {
          return { ...serviceAgreement, ...payload };
        }
        return { ...serviceAgreement };
      });
      return { ...state, customerServiceAgreements: newCustomerServiceAgreements };
    },
    setSelectedCustomersForFilter: (state, payload) => ({ ...state, selectedCustomersForFilter: payload }),
    updateCustomerPreference: (state, payload) => {
      let newPreferences = state.selectedCustomer.preferences;
      if (payload.preference === 'PREFERRED') {
        const preferredSupportWorkers = _.map(newPreferences.preferredSupportWorkers, (preference) => {
          if (preference.carerPreferenceId === payload.carerPreferenceId) {
            return { ...preference, description: payload.description };
          }
          return { ...preference };
        });
        newPreferences = { ...newPreferences, preferredSupportWorkers };
      } else {
        const blockedSupportWorkers = _.map(newPreferences.blockedSupportWorkers, (preference) => {
          if (preference.carerPreferenceId === payload.carerPreferenceId) {
            return { ...preference, description: payload.description };
          }
          return { ...preference };
        });
        newPreferences = { ...newPreferences, blockedSupportWorkers };
      }
      return { ...state, selectedCustomer: { ...state.selectedCustomer, preferences: newPreferences } };
    },
    deleteCustomerPreference: (state, payload) => {
      let newPreferences = state.selectedCustomer.preferences;
      if (payload.preference === 'PREFERRED') {
        const newPreferredSupportWorkers = _.filter(
          newPreferences.preferredSupportWorkers,
          (preference) => preference.carerPreferenceId !== payload.carerPreferenceId,
        );
        newPreferences = {
          ...newPreferences,
          preferredSupportWorkers: newPreferredSupportWorkers ? newPreferredSupportWorkers : [],
        };
      } else {
        const newBlockedSupportWorkers = _.filter(
          newPreferences.blockedSupportWorkers,
          (preference) => preference.carerPreferenceId !== payload.carerPreferenceId,
        );
        newPreferences = {
          ...newPreferences,
          blockedSupportWorkers: newBlockedSupportWorkers ? newBlockedSupportWorkers : [],
        };
      }
      return {
        ...state,
        selectedCustomer: { ...state.selectedCustomer, preferences: newPreferences },
      };
    },
    setCustomerPinnedAlerts: (state, payload) => ({ ...state, customerPinnedAlerts: payload }),
    updateCustomerPinnedAlert: (state, payload) => {
      const customerPinnedAlerts = _.map(state.customerPinnedAlerts, (alert) => {
        if (alert.pinnedAlertId === payload.pinnedAlertId) {
          return { ...alert, description: payload.description, title: payload.title };
        }
        return { ...alert };
      });
      return { ...state, customerPinnedAlerts };
    },
    addToCustomerPinnedAlert: (state) => {
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          pinnedAlertNumber: state.selectedCustomer.pinnedAlertNumber + 1,
        },
      };
    },
    deleteCustomerPinnedAlert: (state, payload) => {
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          pinnedAlertNumber: state.selectedCustomer.pinnedAlertNumber - 1,
        },
        customerPinnedAlerts: _.filter(
          state.customerPinnedAlerts,
          (alert) => alert.pinnedAlertId !== payload.pinnedAlertId,
        ),
      };
    },
    setGroupServiceCustomers: (state, payload) => ({ ...state, groupServiceCustomers: payload }),
    setDisabilityInfoLiteList: (state, payload) => ({ ...state, disabilityInfoLiteList: payload }),
    setCustomerViews: (state, payload) => ({ ...state, customerViews: payload }),
    setDisplayedCustomerListingTabs: (state, payload) => {
      const { customerViews } = state;
      const pinnedViews = customerViews.filter((view) => view.isPinned);
      const views = [...pinnedViews, ...payload];
      const uniqueTabs = _.uniqBy(views, 'customViewId');
      return { ...state, displayedCustomerListingTabs: uniqueTabs };
    },
    setCustomerListingActiveTab: (state, payload) => ({ ...state, customerListingActiveTab: payload }),
    updateGroupServiceCustomerUpcomingCounts: (state, payload) => {
      const upcomingSessionRemoved = _.filter(
        payload.attendanceIds,
        (attendance) =>
          !_.find(
            [BookingStatus.PENDING, BookingStatus.ACCEPTED, BookingStatus.CONFIRMED],
            (completedStatus) => completedStatus === attendance.status,
          ),
      );
      const newGroupedCustomers = _.map(state.groupServiceCustomers, (customer) => {
        if (customer.customerUserId === payload.customerUserId) {
          const newCount = upcomingSessionRemoved
            ? Number(customer.upcomingBookingCount) - upcomingSessionRemoved.length
            : Number(customer.upcomingBookingCount);
          return { ...customer, upcomingBookingCount: newCount <= 0 ? 0 : newCount };
        } else {
          return { ...customer };
        }
      });
      return { ...state, groupServiceCustomers: newGroupedCustomers };
    },
    setSupportCoordinatorFilter: (state, payload) => ({ ...state, supportCoordinatorFilter: payload }),
    setSupportCoordinators: (state, payload) => ({ ...state, supportCoordinators: payload }),
    setCaseManagerFilter: (state, payload) => ({ ...state, caseManagerFilter: payload }),
    setCaseManagers: (state, payload) => ({ ...state, caseManagers: payload }),
    setIsAddingCustomerAddress: (state, payload) => ({ ...state, isAddingCustomerAddress: payload }),
    setDefaultCustomerViews: (state, payload) => ({ ...state, defaultCustomerViews: payload }),
    setIsCreatePackageModalOpen: (state, payload: boolean) => ({
      ...state,
      selectedCustomerState: { ...state.selectedCustomerState, isCreatePackageModalOpen: payload },
    }),
  },
  effects: (dispatch) => ({
    async doGetCustomers(payload, rootState) {
      try {
        const portalUser = rootStore.getState().authStore.portalUser;
        const filterList = rootState.customersStore.customerFilter;
        const filterSearch = filterList.find((filter) => filter['search']);
        if (payload && !payload.search && filterSearch && filterSearch.search) {
          payload.search = filterSearch.search;
        }
        if (!payload || !payload.userType) {
          payload.userType =
            !_.isEmpty(filterList.userType) && _.isString(filterList.userType) ? filterList.userType : 'ALL';
        }

        let requestPayload = formatCustomerListingFilter(payload);
        if (payload.isViewAssignedCustomers) {
          requestPayload.isViewAssignedCustomers = payload.isViewAssignedCustomers;
          if (!requestPayload[FilterType.SUPPORT_COORDINATOR]?.length) {
            requestPayload[FilterType.SUPPORT_COORDINATOR] = [];
          }
          if (!requestPayload[FilterType.SUPPORT_COORDINATOR].includes(portalUser.supportWorkerId)) {
            requestPayload[FilterType.SUPPORT_COORDINATOR].push(portalUser.supportWorkerId);
          }
        }
        delete requestPayload.isViewAssignedCustomers;
        requestPayload.hasSupportCoordinationCustomersView = payload.hasSupportCoordinationCustomersView;
        const response = await apiClient.post(`/api/portal/customers/list`, requestPayload);
        let customers = response.data;
        if (payload.page > 1) {
          customers = rootState.customersStore.customers.concat(customers);
        }
        dispatch.customersStore.setCustomers(customers);
        dispatch.customersStore.setFilteredCustomers(customers);
      } catch (e) {
        throw e;
      }
    },

    async doGetCustomerParentGuardiansList(payload, rootState) {
      try {
        const filterList = rootState.customersStore.customerFilter;
        const filterSearch = filterList.find((filter) => filter['search']);
        if (payload && !payload.search && filterSearch && filterSearch.search) {
          payload.search = filterSearch.search;
        }
        if (!payload || !payload.userType) {
          payload.userType = filterList.userType ? filterList.userType : 'PARENT_GUARDIANS';
        }
        let requestPayload = formatCustomerListingFilter(payload);
        const response = await apiClient.post(`/api/portal/customers/parent-guardians/list`, requestPayload);
        let customers = response.data;
        if (payload.page > 1) {
          customers = rootState.customersStore.customers.concat(customers);
        }
        dispatch.customersStore.setCustomers(customers);
        dispatch.customersStore.setFilteredCustomers(customers);
      } catch (e) {
        throw e;
      }
    },

    async doGetCustomersLite(payload, rootState) {
      try {
        const filterList = rootState.customersStore.customerFilterLite;
        if (payload && !payload.search && filterList.search) {
          payload.search = filterList.search;
        }
        if (!payload || !payload.userType) {
          payload.userType = 'ALL';
        }
        const response = await apiClient.post(`/api/portal/customers/lite-list`, payload);
        let customers = response.data;
        if (payload.page > 1) {
          customers = _.unionBy(rootState.customersStore.customersLite, customers, 'userId');
        }
        dispatch.customersStore.setCustomersLite(customers);
        dispatch.customersStore.setFilteredCustomersLite(customers);
      } catch (e) {
        throw e;
      }
    },

    async doFetchGuardianLite(
      payload: { page: number; pageSize: number; pageTimestamp: typeof Date; search: string },
      rootState: IRootState,
    ) {
      try {
        const response = await apiClient.post(`/api/portal/customers/parent-guardians/lite-list`, payload);
        return response.data;
      } catch (e) {
        throw e;
      }
    },

    async doCheckCustomerEmailConflict(payload: { email: string }, rootState: IRootState) {
      try {
        const response = await apiClient.post(`/api/portal/user/email-conflict-check`, payload);
        return response.data;
      } catch (e) {
        throw e;
      }
    },

    //* NEW API since Add customer Story
    async doCreateCustomer(payload: IAddCustomerDetailWizard, rootState: IRootState) {
      try {
        const response = await apiClient.post(`/api/portal/user/customers`, payload);
        const customerUserId = response.data.userId;
        const { userId } = rootState.authStore.portalUser;

        events.trackAddCustomer(userId, {
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          mobilePhone: payload.mobile,
          customerUserId,
        });

        return customerUserId;
      } catch (e) {
        throw e;
      }
    },

    async doUpdateConnectionRequest(
      payload: { userId: string; targetUserId: string; customerConnectionRequestId: string; email: string },
      rootState: IRootState,
    ) {
      try {
        const response = await apiClient.put(
          `/api/portal/user/customer-connections/${payload.customerConnectionRequestId}`,
          payload,
        );
        dispatch.customersStore.doGetCustomer({ userId: payload.userId });
      } catch (e) {
        throw e;
      }
    },

    async doRemoveGuardian(payload: { customerUserId: string; guardianUserId: string }, rootState: IRootState) {
      try {
        const { customerUserId, guardianUserId } = payload;
        const response = await apiClient.delete(
          `/api/portal/customers/${customerUserId}/parent-guardians/${guardianUserId}`,
        );
        if (response.data) {
          dispatch.customersStore.doGetCustomer({ userId: customerUserId });
        }
        return response.data;
      } catch (e) {
        throw e;
      }
    },

    async doRemoveCustomerFromGuardian(
      payload: { customerUserId: string; guardianUserId: string },
      rootState: IRootState,
    ) {
      try {
        const { customerUserId, guardianUserId } = payload;
        const response = await apiClient.delete(
          `/api/portal/customers/${customerUserId}/parent-guardians/${guardianUserId}`,
        );
        if (response.data) {
          dispatch.customersStore.doGetCustomer({ userId: guardianUserId });
        }
        return response.data;
      } catch (e) {
        throw e;
      }
    },

    async doAddGuardiansToCustomer(payload: IAddCustomerGuardianDetailWizard, rootState: IRootState) {
      try {
        const customerUserId = rootState.customersStore.selectedCustomer.userId;
        const response = await apiClient.post(`/api/portal/customers/${customerUserId}/parent-guardians`, payload);
        dispatch.customersStore.doGetCustomer({ userId: customerUserId });
      } catch (e) {
        throw e;
      }
    },

    async doSetSelectedCustomer(payload, rootState) {
      try {
        dispatch.customersStore.setBookSelectedCustomer(payload.selectedCustomer);
        dispatch.customersStore.setBookSelectedCustomerId(payload.selectedCustomerId);
      } catch (e) {
        throw e;
      }
    },

    async doGetCustomer(payload: { userId: string }) {
      const { userId } = payload;
      const response = await apiClient.get(`/api/portal/customers/${userId}`);
      const customer = response.data;

      dispatch.customersStore.setSelectedCustomer(customer);
      return customer;
    },

    async doCreateCustomerMedicalCondition(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/medical-conditions`;
        const result = await apiClient.post(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerMedicalCondition(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/medical-conditions/${payload.medicalConditionId}`;
        const result = await apiClient.put(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doDeleteCustomerMedicalCondition(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/medical-conditions/${payload.medicalConditionId}`;
        const result = await apiClient.delete(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doCreateCustomerDisability(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/disability-infos`;
        const result = await apiClient.post(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerDisability(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/disability-infos/${payload.disabilityInfoId}`;
        const result = await apiClient.put(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doDeleteCustomerDisability(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/disability-infos/${payload.disabilityInfoId}`;
        const result = await apiClient.delete(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchCustomerDisabilityList(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/disability-infos/list`;
        const result = await apiClient.post(endpoint, payload);
        return result.data.disabilities;
      } catch (e) {
        throw e;
      }
    },

    async doFetchDisabilityList(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/disability-options`;
        const result = await apiClient.post(endpoint, payload);
        return result.data.disabilities;
      } catch (e) {
        throw e;
      }
    },

    async doCreateCustomerSensitivity(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/sensitivity-infos`;
        const result = await apiClient.post(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerSensitivity(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/sensitivity-infos/${payload.sensitivityInfoId}`;
        const result = await apiClient.put(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doDeleteCustomerSensitivity(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/sensitivity-infos/${payload.sensitivityInfoId}`;
        const result = await apiClient.delete(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doCreateCustomerBehaviour(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/behavioural-infos`;
        const result = await apiClient.post(endpoint, payload);
        if (result.status === 200) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerBehaviour(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/behavioural-infos/${payload.behaviouralInfoId}`;
        const result = await apiClient.put(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doDeleteCustomerBehaviour(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/behavioural-infos/${payload.behaviouralInfoId}`;
        const result = await apiClient.delete(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchCareInfoList(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/care-information`;
        const result = await apiClient.get(endpoint);
        const listDisabilities = result.data
          ?.filter((item) => item.careInformationType === CareInformationType.PERMANENT)
          .map((item) => ({
            ...item.additionalInfo,
            disabilityName: item.name,
            alertLevel: item.alertLevel,
            description: item.description,
          }));
        dispatch.customersStore.setSelectedCustomerCareInfoList(result.data);
        dispatch.customersStore.setSelectedCustomer({
          ...rootStore.customersStore.selectedCustomer,
          disabilities: listDisabilities,
        });
      } catch (e) {
        throw e;
      }
    },

    async doCreateCustomerCareInfo(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/care-information`;
        const result = await apiClient.post(endpoint, payload);
        if (result.data) {
          this.doFetchCareInfoList({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerCareInfo(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/care-information/${payload.careInformationId}`;
        const result = await apiClient.put(endpoint, payload);
        if (result.data) {
          this.doFetchCareInfoList({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doDeleteCustomerCareInfo(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/care-information/${payload.careInformationId}`;
        const result = await apiClient.delete(endpoint, payload);
        if (result.data) {
          this.doFetchCareInfoList({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doCreateCustomerContact(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/contact`;
        const result = await apiClient.post(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doCreateCustomerGuardian(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/create-guardian`;
        const result = await apiClient.post(endpoint, payload);
        if (result.data) {
          this.addUserGuardiansInfo(result.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerContact(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/contact`;
        const result = await apiClient.put(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doGetRelationshipLists(payload, rootStore) {
      try {
        const endpoint = `/api/portal/connection/relation`;
        const result = await apiClient.get(endpoint, payload);
        dispatch.customersStore.setCustomerRelationships(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doSubmitCustomerSetting(payload, rootState: IRootState) {
      try {
        const { userId } = payload;
        const endPoint = `/api/portal/customers/${userId}`;
        await apiClient.put(endPoint, { isWorkerSelectable: payload.isWorkerSelectable });
        const customer = rootState.customersStore.selectedCustomer;
        customer.isWorkerSelectable = payload.isWorkerSelectable;
        dispatch.customersStore.setSelectedCustomer(customer);
      } catch (e) {
        throw e;
      }
    },

    async doCreateUserServiceAgreement(payload, rootStore) {
      try {
        const endpoint = `/api/portal/service-provider/service-agreement`;
        const result = await apiClient.post(endpoint, payload);

        dispatch.customersStore.setUserServiceAgreement(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doUpdateUserServiceAgreement(payload, rootStore) {
      try {
        const endpoint = `/api/portal/service-provider/service-agreement/${payload.userServiceAgreementId}`;
        const result = await apiClient.put(endpoint, payload);

        await dispatch.customersStore.setUserServiceAgreement(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doCreateCustomerSupportNeeds(payload, rootState) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/support-needs`;
        const result = await apiClient.post(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerSupportNeeds(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/support-needs/${payload.supportNeedId}`;
        const result = await apiClient.put(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doDeleteCustomerSupportNeed(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/${payload.userId}/support-needs/${payload.supportNeedId}`;
        const result = await apiClient.delete(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchSupportNeedCategories(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/support-categories/list`;
        const result = await apiClient.get(endpoint, payload);
        if (result.data) {
          this.setSupportNeedCategories(result.data.supportCategories);
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchSupportNeedCategoryNeeds(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/support-categories/${payload.supportCategoryLookupId}/support-needs/list`;
        const result = await apiClient.get(endpoint, payload);
        if (result.data) {
          this.setSupportNeedCategoriesNeeds({
            supportCategoryLookupId: payload.supportCategoryLookupId,
            supportNeeds: result.data.supportNeeds,
          });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerHealthCareInfo(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/healthcare`;
        const result = await apiClient.put(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchCustomerServiceAgreementPDF(payload, rootStore) {
      try {
        const endpoint = '/viewpdf/portal/service-agreement';
        const result = await apiCloudFunctionClient.post(endpoint, payload);

        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doCreateCustomerFundingData(payload, rootStore) {
      try {
        const endpoint = `/api/portal/user/funding-packages/ndis`;
        const result = await apiClient.post(endpoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doDeleteFundingPackage(payload, rootStore) {
      try {
        const endpoint = `api/portal/user/funding-packages/ndis/${payload.fundingPackageId}`;

        const data = {
          customerUserId: rootStore.customersStore.selectedCustomerUserId,
        };
        const result = await apiClient.delete(endpoint, data);
        if (result.data) {
          dispatch.customersStore.setSelectedCustomer({
            ...rootStore.customersStore.selectedCustomer,
            funding: result.data.funding,
          });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerNDISInfo(payload, rootStore) {
      try {
        const endpoint = '/api/portal/user/ndis-info';

        const data = {
          userId: rootStore.customersStore.selectedCustomerUserId,
          ...payload,
        };

        const result = await apiClient.put(endpoint, data);
        if (result.data) {
          dispatch.customersStore.setUserNdisInfo(result.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async updateCustomerProfile(payload: { userId: string }) {
      const endpoint = '/api/portal/user/profile';
      const result = await apiClient.put(endpoint, payload);

      if (result.data) {
        await this.doGetCustomer({ userId: payload.userId });
        await this.doFetchCareInfoList({ userId: payload.userId });
      }
    },

    /* Send invitations to Consumer */

    async doSendConsumerInvitation(payload, rootState) {
      const endPoint = `/api/portal/user/invite`;
      const body = payload;

      try {
        const result = await apiClient.post(endPoint, body);
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          dispatch.teamStore.setSentConsumerInvitations(result.data);
        } else {
          return result;
        }
      } catch (e) {
        message.error(e.message);
        throw e;
      }
    },

    /* Update General Funding Info */

    async doUpdateFundingGeneralInfo(payload, rootState) {
      const endPoint = `/api/portal/user/${payload.userId}/funding-info`;

      try {
        const result = await apiClient.put(endPoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        message.error(e.message);
        throw e;
      }
    },

    /* Delete sone of the General Funding Info */

    async doDeleteFundingGeneralInfo(payload, rootState) {
      const endPoint = `/api/portal/user/${payload.userId}/funding-info/${payload.numberType}`;

      try {
        const result = await apiClient.delete(endPoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        message.error(e.message);
        throw e;
      }
    },

    /* Update General Funding package Info */

    async doUpdateFundingPackageGeneralInfo(payload, rootState) {
      const endPoint = `/api/portal/user/funding-packages`;

      try {
        const result = await apiClient.put(endPoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        message.error(e.message);
        throw e;
      }
    },

    /* Update General Funding package Info */

    async doUpdateFundingPackageGoal(payload, rootState) {
      const endPoint = `/api/portal/user/funding-packages/ndis/${payload.fundingPackageId}/goals`;

      try {
        const result = await apiClient.put(endPoint, { goals: payload.goals, userId: payload.userId });
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        message.error(e.message);
        throw e;
      }
    },

    /* Update Funding package contact Info */

    async doUpdateFundingPackageContact(payload, rootState) {
      const endPoint = `/api/portal/user/funding-packages/${payload.fundingPackageId}/contacts`;

      try {
        const result = await apiClient.put(endPoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        message.error(e.message);
        throw e;
      }
    },

    /* Update Funding package dates Info */

    async doUpdateFundingPackageDates(payload, rootState) {
      const endPoint = `/api/portal/user/funding-packages/${payload.fundingPackageId}`;

      try {
        const result = await apiClient.put(endPoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        throw e;
      }
    },

    /* Update Funding package category Info */

    async doUpdateFundingPackageCategory(payload, rootState) {
      const endPoint = `/api/portal/user/funding-packages/ndis/${payload.fundingPackageId}/support-categories`;

      try {
        const result = await apiClient.put(endPoint, payload);
        if (result.data) {
          this.doGetCustomer({ userId: payload.userId });
        }
      } catch (e) {
        message.error(e.message);
        throw e;
      }
    },

    /* Add a Customer */

    async doAddCustomer(payload) {
      try {
        const endpoint = `/api/portal/user/add-customer`;
        const result = await apiClient.post(endpoint, payload);
        this.setAddedCustomerUserId(result.data.userId);
      } catch (e) {
        throw e;
      }
    },

    /* Add a Customer */
    //! Old api
    async createConnectionRequest(payload) {
      try {
        const endpoint = `/api/portal/user/create-customer-connection`;
        const result = await apiClient.post(endpoint, payload);
        const userId = payload.customerConnectionRequests[0].userId;
        await this.doGetCustomer({ userId: userId });
      } catch (e) {
        throw e;
      }
    },

    /* Get add customer fuzzy matching */

    async doFetchAddCustomerFuzzyMatching(payload: { firstName: string; lastName: string }, rootState) {
      try {
        let matches: IMatchingUser[] = [];
        const endpoint = `/api/portal/user/fuzzy-match-customer`;
        const result = await apiClient.post(endpoint, payload);
        if (result.data) {
          matches = result.data.matchingUsers
            ? _.orderBy(result.data?.matchingUsers, ['highCorrelation'], ['desc'])
            : [];
        }
        return matches;
      } catch (e) {
        throw e;
      }
    },

    async doFetchCustomerConnectRequest(payload, rootState) {
      try {
        const endpoint = `/api/portal/user/view-customer-connection`;
        const request = {
          userId: payload.userId,
        };
        const result = await apiClient.post(endpoint, request);
        const customer = rootState.customersStore.selectedCustomer;
        if (result.data) {
          customer.customerConnectionRequests = result.data;
        } else {
          customer.customerConnectionRequests = [];
        }
        dispatch.customersStore.setSelectedCustomer(customer);
      } catch (e) {
        throw e;
      }
    },

    //* UPDATED API since Add customer Story
    async doCancelCustomerConnectionRequest(payload: { customerConnectionRequestId: string }, rootState: IRootState) {
      //payload: customerConnectionRequestId
      try {
        const endpoint = `/api/portal/user/customer-connections/${payload.customerConnectionRequestId}`;
        const result = await apiClient.delete(endpoint);

        dispatch.customersStore.cancelCustomerConnection(payload);
        dispatch.customersStore.doGetCustomer({ userId: rootState.customersStore.selectedCustomer.userId });
      } catch (e) {
        throw e;
      }
    },

    async doUpdateGuardianRelationship(payload, rootState: IRootState) {
      try {
        const { customerUserId, guardianUserId, relationship } = payload;
        const response = await apiClient.put(
          `/api/portal/customers/${customerUserId}/parent-guardians/${guardianUserId}`,
          { relationship: relationship },
        );
        if (response.data) {
          this.doGetCustomer({ userId: customerUserId });
        }
      } catch (e) {
        throw e;
      }
    },

    //! Old api
    async doCreateCustomerConnection(payload, rootState) {
      try {
        const endpoint = `/api/portal/user/create-customer-connection`;
        await apiClient.post(endpoint, payload);
        const userId = payload.customerConnectionRequests[0].userId;
        await this.doGetCustomer({ userId: userId });
        return true;
      } catch (e) {
        throw e;
      }
    },

    //* New API for create connection request since Add customer Story
    async doCreateConnectionRequest(
      payload: { userId: string; targetUserId: string; email: string }[],
      rootState: IRootState,
    ) {
      try {
        const endpoint = `/api/portal/user/customer-connections`;
        const response = await apiClient.post(endpoint, { customerConnectionRequests: payload });

        if (response.data) {
          const userId = payload[0].userId;
          dispatch.customersStore.doGetCustomer({ userId: userId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doAcceptEnquiry(payload, rootState) {
      try {
        const endpoint = `/api/portal/user/${payload.customerUserId}/enquiry/accept`;
        await apiClient.post(endpoint, payload);
        await this.setSelectedCustomer({
          ...rootState.customersStore.selectedCustomer,
          isEnquiry: false,
          customerStatus: CustomerStatusType.ACTIVE,
        });
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doRevertEnquiry(payload, rootState) {
      try {
        const endpoint = `/api/portal/user/${payload.customerUserId}/enquiry/revert`;
        await apiClient.post(endpoint, payload);
        await this.setSelectedCustomer({
          ...rootState.customersStore.selectedCustomer,
          isEnquiry: true,
          customerStatus: CustomerStatusType.ENQUIRY,
        });
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doSaveCustomerSource(payload, rootState) {
      try {
        const endpoint = `/api/portal/customers/${payload.customerUserId}/source`;
        const data = {
          customerSource: payload.customerSource,
          customerSourceId: payload.customerSourceId,
        };
        await apiClient.put(endpoint, data);
        await this.setSelectedCustomer({
          ...rootState.customersStore.selectedCustomer,
          customerSource: payload.customerSource,
          customerSourceId: payload.customerSourceId,
        });
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doArchiveCustomer(payload, rootState) {
      try {
        const endpoint = `/api/portal/customers/${payload.customerUserId}/archive`;
        await apiClient.put(endpoint, payload);
        await this.setSelectedCustomer({
          ...rootState.customersStore.selectedCustomer,
          customerStatus: payload.isScheduleArchive
            ? CustomerStatusType.SCHEDULED_TO_BE_ARCHIVED
            : CustomerStatusType.ARCHIVED,
          archivedOn: payload.archiveDate ? payload.archiveDate : moment(),
          archiveReason: payload.archiveReason,
        });
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doReactivateCustomer(payload, rootState) {
      try {
        const endpoint = `/api/portal/customers/${payload.customerUserId}/reactivate`;
        await apiClient.put(endpoint, payload);
        await this.setSelectedCustomer({
          ...rootState.customersStore.selectedCustomer,
          customerStatus: CustomerStatusType.ACTIVE,
          archivedOn: null,
        });
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doCancelScheduledArchive(payload, rootState) {
      try {
        const endpoint = `/api/portal/customers/${payload.customerUserId}/cancel-schedule-archive`;
        const { isEnquiry } = rootState.customersStore.selectedCustomer;
        await apiClient.put(endpoint, payload);
        await this.setSelectedCustomer({
          ...rootState.customersStore.selectedCustomer,
          customerStatus: isEnquiry ? CustomerStatusType.ENQUIRY : CustomerStatusType.ACTIVE,
          archivedOn: null,
        });
        return true;
      } catch (e) {
        throw e;
      }
    },

    // set all funding data in object

    async doSetFundingData(payload, rootState) {
      let prevData = rootState.customersStore.fundingData;
      let fundData;

      if (payload[0] !== undefined) {
        fundData = {
          agencyName:
            payload[0].generalFundData !== undefined ? payload[0].generalFundData.agencyName : prevData.agencyName,
          startDate:
            payload[0].generalFundData !== undefined ? payload[0].generalFundData.startDate : prevData.startDate,
          expiryDate:
            payload[0].generalFundData !== undefined ? payload[0].generalFundData.expiryDate : prevData.expiryDate,
          agencyAddress:
            payload[0].generalFundData !== undefined
              ? payload[0].generalFundData.agencyAddress
              : prevData.agencyAddress,
          phoneNumberCountryCode:
            payload[0].generalFundData !== undefined
              ? payload[0].generalFundData.phoneNumberCountryCode
              : prevData.phoneNumberCountryCode,
          agencyNumber:
            payload[0].generalFundData !== undefined ? payload[0].generalFundData.agencyNumber : prevData.agencyNumber,
          agencyEmail:
            payload[0].generalFundData !== undefined ? payload[0].generalFundData.agencyEmail : prevData.agencyEmail,
          keyContacts: payload[0].keyContacts !== undefined ? payload[0].keyContacts : prevData.keyContacts,
          goalFundData: payload[0].goalFundData !== undefined ? payload[0].goalFundData : prevData.goalFundData,
          categoryData: payload[0].categoryData !== undefined ? payload[0].categoryData : prevData.categoryData,
        };
      } else {
        fundData = payload;
      }
      dispatch.customersStore.setFundingData(fundData);
    },

    // SERVICE AGREEMENT

    async doUpdateTermsConditions(payload, rootState) {
      try {
        const endpoint = `/api/portal/user/${payload.customerUserId}/service-agreement/${payload.serviceAgreementId}/terms-conditions/`;
        await apiClient.post(endpoint, payload);
        dispatch.customersStore.setUserServiceAgreementTermsConditions({ termsConditions: payload.termsConditions });
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doSearchServicesForServiceAgreement(payload, rootState) {
      try {
        const endpoint = `/api/portal/services/lite-list`;
        const result = await apiClient.post(endpoint, payload);
        dispatch.customersStore.setServiceAgreementSearchServiceResult(result.data);
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doAddServicesToServiceAgreement(payload, rootState) {
      try {
        const endpoint = `/api/portal/user/${payload.customerUserId}/service-agreement/${payload.serviceAgreementId}/services/${payload.serviceId}`;
        await apiClient.post(endpoint, payload);
        dispatch.customersStore.addServiceToServiceAgreement({
          serviceId: payload.serviceId,
          serviceName: payload.serviceName,
          mmmGroup: payload.mmmGroup,
          state: payload.state,
        });
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doFetchServiceAgreementServiceBillingLineItems(payload, rootState) {
      try {
        const endpoint = `/api/portal/services/${payload.serviceId}/billing-line-item`;
        const result = await apiClient.post(endpoint, payload);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doRemoveServiceFromServiceAgreement(payload, rootState) {
      try {
        const endpoint = `/api/portal/user/${payload.customerUserId}/service-agreement/${payload.serviceAgreementId}/services/${payload.serviceId}`;
        await apiClient.delete(endpoint, payload);
        dispatch.customersStore.deleteServiceFromServiceAgreement(payload.serviceId);
        return true;
      } catch (e) {
        throw e;
      }
    },

    // DOCUMENT STORAGE

    // Customer profile > Files > Customer Documents
    async doFetchCustomerDocuments(payload, rootState) {
      try {
        payload.documentType = DocumentFileType.CUSTOMER_DOCUMENT;
        const result = await apiClient.post(`/api/portal/user/${payload.userId}/documents/list`, payload);
        dispatch.customersStore.setCustomerDocuments(result.data.documents);
      } catch (e) {
        throw e;
      }
    },

    // Customer profile > Files > Customer Documents
    async doFetchMoreCustomerDocuments(payload, rootState) {
      payload.documentType = DocumentFileType.CUSTOMER_DOCUMENT;
      let result = await apiClient.post(`/api/portal/user/${payload.userId}/documents/list`, payload);

      try {
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          dispatch.customersStore.appendCustomerDocuments(result.data.documents);
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    // Customer profile > Files > Booking Attachments
    async doFetchCustomerBookingAttachments(
      payload: {
        userId: string;
        currentPage: number;
        pageSize: number;
        startDate: Date;
        documentType: DocumentFileType;
      },
      rootState,
    ) {
      payload.documentType = DocumentFileType.BOOKING_DOCUMENT;
      try {
        const result = await apiClient.post(`/api/portal/user/${payload.userId}/documents/list`, payload);
        dispatch.customersStore.setCustomerBookingAttachments(result.data.documents);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    // Customer profile > Files > Booking Attachments
    async doFetchMoreCustomerBookingAttachments(
      payload: {
        userId: string;
        currentPage: number;
        pageSize: number;
        startDate: Date;
        documentType: DocumentFileType;
      },
      rootState,
    ) {
      payload.documentType = DocumentFileType.BOOKING_DOCUMENT;

      try {
        let result = await apiClient.post(`/api/portal/user/${payload.userId}/documents/list`, payload);
        if (!!result?.data) {
          dispatch.customersStore.appendCustomerBookingAttachments(result.data.documents);
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async doAddCustomerDocument(payload, rootState) {
      try {
        const portalUser = rootStore.getState().authStore.portalUser;
        const request = { ...payload, addedBy: portalUser.userId };
        const result = await apiClient.post(`/api/portal/user/${payload.customerUserId}/documents/add`, request);
        dispatch.customersStore.addCustomerDocument({
          ...payload,
          documentId: result.data.documentId,
          firstName: portalUser.firstName,
          lastName: portalUser.lastName,
          expiryDate: payload.expiryDate,
          expiryStatus: !payload.expiryDate
            ? DocumentExpiryStatus.NOT_EXPIRED
            : payload.expiryDate < moment().startOf('day')
            ? DocumentExpiryStatus.EXPIRED
            : moment(payload.expiryDate).startOf('day').diff(moment().startOf('day'), 'days') <= 7
            ? DocumentExpiryStatus.EXPIRING_SOON
            : DocumentExpiryStatus.NOT_EXPIRED,
        });
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doEditCustomerDocument(payload, rootState) {
      try {
        const result = await apiClient.put(
          `/api/portal/user/${payload.userId}/documents/${payload.documentId}`,
          payload,
        );
        dispatch.customersStore.editCustomerDocument({
          ...payload,
          documentId: payload.documentId,
          expiryDate: payload.expiryDate,
        });
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doRemoveCustomerDocument(payload, rootState) {
      try {
        await apiClient.delete(`/api/portal/user/${payload.userId}/documents/${payload.documentId}`);
        dispatch.customersStore.removeCustomerDocument(payload.documentId);
      } catch (e) {
        throw e;
      }
    },

    async doArchiveCustomerDocument(payload, rootState) {
      try {
        await apiClient.delete(`/api/portal/user/${payload.userId}/documents/${payload.documentId}/archive`);
        // dispatch.customersStore.archiveCustomerDocument(payload.documentId);
      } catch (e) {
        throw e;
      }
    },

    async doUnArchiveCustomerDocument(payload, rootState) {
      try {
        await apiClient.put(`/api/portal/user/${payload.userId}/documents/${payload.documentId}/un-archive`);
        dispatch.customersStore.archiveCustomerDocument(payload.documentId);
      } catch (e) {
        throw e;
      }
    },

    async doFetchCustomersAtAGlance(rootState) {
      try {
        const url = `/api/portal/service-provider/view/dashboard/document-expiry-warnings`;
        const result = await apiClient.get(url);
        if (!_.isEmpty(result)) {
          dispatch.customersStore.setCustomersAtAGlance(result.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchServiceAgreements(payload, rootState) {
      try {
        const url = `/api/portal/customers/${
          payload.userId ? payload.userId : rootState.customersStore.selectedCustomer.userId
        }/service-agreements`;
        const result = await apiClient.get(url, payload);
        let serviceAgreements = result.data;
        if (payload.page > 1) {
          serviceAgreements = rootState.customersStore.customerServiceAgreements.concat(serviceAgreements);
        }
        if (!_.isEmpty(result)) {
          dispatch.customersStore.setCustomerServiceAgreements(serviceAgreements);
        }
        return result.data;
      } catch (e) {
        throw e;
      }
    },
    async doFetchServiceAgreementDetails(payload, rootState) {
      try {
        const userId = payload.customerUserId
          ? payload.customerUserId
          : rootState.customersStore.selectedCustomer.userId;
        const url = `/api/portal/customers/${userId}/service-agreements/${payload.serviceAgreementId}`;
        const result = await apiClient.get(url, payload);
        const serviceAgreement = result.data;
        dispatch.customersStore.setSelectedCustomerServiceAgreement(serviceAgreement);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doCreateServiceAgreement(payload, rootState) {
      try {
        const url = `/api/portal/customers/${payload.customerUserId}/service-agreements`;
        const result = await apiClient.post(url, payload);
        if (!_.isEmpty(result)) {
          await this.doFetchServiceAgreements({});

          const { customerName, paymentSourceType, userServiceAgreementId } = result.data;
          const { customerUserId, startDate, endDate } = payload;

          events.trackCreateServiceAgreement({
            customerId: customerUserId,
            customerName,
            paymentSourceType,
            startDate,
            endDate,
          });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateServiceAgreement(payload, rootState) {
      try {
        const url = `/api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/service-agreements/${payload.serviceAgreementId}`;
        const result = await apiClient.put(url, payload);
        if (!_.isEmpty(result)) {
          await this.doFetchServiceAgreements({});
          await this.doFetchServiceAgreementDetails({
            serviceAgreementId: payload.serviceAgreementId,
          });
        }
      } catch (e) {
        throw e;
      }
    },
    async doUpdateServiceAgreementDate(payload, rootState) {
      try {
        const url = `/api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/service-agreements/${payload.serviceAgreementId}/date`;
        const result = await apiClient.put(url, payload);
        if (!_.isEmpty(result)) {
          await this.doFetchServiceAgreements({});
          await this.doFetchServiceAgreementDetails({
            serviceAgreementId: payload.serviceAgreementId,
          });
        }
      } catch (e) {
        throw e;
      }
    },
    async doUpdateServiceAgreementTermsCondition(payload, rootState) {
      try {
        const url = `/api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/service-agreements/${payload.serviceAgreementId}/terms`;
        const result = await apiClient.put(url, payload);
        if (!_.isEmpty(result)) {
          await this.doFetchServiceAgreements({});
          await this.doFetchServiceAgreementDetails({
            serviceAgreementId: payload.serviceAgreementId,
          });
        }
      } catch (e) {
        throw e;
      }
    },
    async doUpdateServiceAgreementServiceLineItems(payload, rootState) {
      try {
        const url = `/api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/service-agreements/${payload.serviceAgreementId}/line-items`;
        const result = await apiClient.put(url, payload);
        if (!_.isEmpty(result)) {
          await this.doFetchServiceAgreements({});
          await this.doFetchServiceAgreementDetails({
            serviceAgreementId: payload.serviceAgreementId,
          });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateServiceAgreementStatus(payload, rootState) {
      try {
        const url = `/api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/service-agreements/${payload.serviceAgreementId}/sign`;
        const result = await apiClient.put(url, payload);
        if (!_.isEmpty(result)) {
          await this.doFetchServiceAgreements({});
          await this.doFetchServiceAgreementDetails({
            serviceAgreementId: payload.serviceAgreementId,
          });
        }
      } catch (e) {
        throw e;
      }
    },

    async doDeleteServiceAgreement(payload, rootState) {
      try {
        const url = `/api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/service-agreements/${payload.serviceAgreementId}`;
        const result = await apiClient.delete(url, payload);
        if (!_.isEmpty(result)) {
          await this.doFetchServiceAgreements({});
        }
      } catch (e) {
        throw e;
      }
    },

    async dofetchCustomerNotes(payload, rootState) {
      const { customerUserId } = payload;
      const filterList = rootState.customersStore.customerNotesFilters;
      const filterSearch = filterList.find((filter) => filter.search);
      const endpoint = `api/portal/customers/${customerUserId}/notes/list`;
      try {
        let updatedPayload = {
          page: rootState.customersStore.customerNotePage,
          pageSize: rootState.customersStore.customerNotePageSize,
          pageTimestamp: rootState.customersStore.customerNotePageTimeStamp,
          ...formatNoteFilters(filterList),
        };
        if (filterSearch?.search) {
          updatedPayload = { ...updatedPayload, search: filterSearch.search };
        }
        let result = await apiClient.post(endpoint, updatedPayload);

        dispatch.customersStore.setCustomerNotes(result.data);
      } catch (e) {
        throw e;
      }
    },
    async dofetchMoreCustomerNotes(payload, rootState) {
      const { customerUserId } = payload;
      const filterList = rootState.customersStore.customerNotesFilters;
      const filterSearch = filterList.find(({ search }) => search);

      const endpoint = `api/portal/customers/${customerUserId}/notes/list`;
      try {
        let updatedPayload = {
          page: rootState.customersStore.customerNotePage,
          pageSize: rootState.customersStore.customerNotePageSize,
          pageTimestamp: rootState.customersStore.customerNotePageTimeStamp,
          ...formatNoteFilters(filterList),
          search: payload.search,
        };
        if (!!payload?.search && filterSearch?.search) {
          updatedPayload = { ...updatedPayload, search: filterSearch.search };
        }
        let result = await apiClient.post(endpoint, updatedPayload);
        const existingCustomerNotes = rootState.customersStore.customerNotes;
        dispatch.customersStore.setCustomerNotes(existingCustomerNotes.concat(result.data));
      } catch (e) {
        throw e;
      }
    },
    async doAddCustomerNotes(payload, rootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/notes`;
      try {
        let result = await apiClient.post(endpoint, payload);
        await asyncDelay(1000);
        dispatch.customersStore.setCustomerNotesPageInfo({
          customerNotePage: 1,
          customerNotePageSize: rootState.customersStore.customerNotePageSize,
          customerNotePageTimeStamp: new Date(),
        });
        await dispatch.customersStore.dofetchCustomerNotes({
          customerUserId: payload.customerUserId,
        });
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doEditCustomerNote(payload, rootState) {
      const { customerUserId, noteId, ...request } = payload;
      const endpoint = `api/portal/customers/${customerUserId}/notes/${noteId}`;
      const result = await apiClient.put(endpoint, request);
      await dispatch.customersStore.dofetchCustomerNotes({
        customerUserId,
      });
      return result.data;
    },

    async doDeleteCustomerNote(payload, rootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/notes/${payload.noteId}`;
      try {
        const result = await apiClient.delete(endpoint);
        await dispatch.customersStore.dofetchCustomerNotes({
          customerUserId: payload.customerUserId,
        });
      } catch (e) {
        throw e;
      }
    },

    async doExportCustomerNotes(payload, rootState) {
      const { customerUserId } = payload;
      const filterList = rootState.customersStore.customerNotesFilters;
      const endpoint = `api/portal/customers/${customerUserId}/notes/export`;
      try {
        let requestBody = {};
        if (payload.selectedOption === 'CURRENT_FILTERS') {
          requestBody = { ...formatNoteFilters(filterList) };
        }

        let result = await apiClient.post(endpoint, requestBody);
      } catch (e) {
        throw e;
      }
    },

    async doFetchCustomerNoteAuthors(payload, rootState) {
      const endpoint = `api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/notes/authors/list`;
      try {
        let result = await apiClient.post(endpoint, payload);
        dispatch.customersStore.setCustomerNoteAuthors(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doFetchCustomerNoteServices(payload, rootState) {
      const endpoint = `api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/notes/services/list`;
      try {
        let result = await apiClient.post(endpoint, payload);
        dispatch.customersStore.setCustomerNoteServices(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doCheckServiceAgreementDateValidity(payload, rootState) {
      const endpoint = `api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/service-agreements/check-datetime`;
      try {
        let result = await apiClient.post(endpoint, payload);
        const validityData = result.data;
        const passedValidityChecks = {
          isOverlap: false,
          overlappingStartDate: null,
          overlappingEndDate: null,
          hasGap: false,
          serviceDateConflicts: null,
          quoteChange: validityData.quoteChange,
        };

        let validityChecks = passedValidityChecks;

        if (validityData.conflict) {
          validityChecks = {
            ...validityChecks,
            isOverlap: true,
            overlappingStartDate: validityData.conflict.startDateTime,
            overlappingEndDate: validityData.conflict.endDateTime,
            quoteChange: validityData.quoteChange,
          };
        }

        if (validityData.gap) {
          validityChecks = {
            ...validityChecks,
            hasGap: true,
            quoteChange: validityData.quoteChange,
          };
        }

        if (Boolean(validityData.serviceDateConflicts?.length) && payload.serviceDatesFlagEnabled) {
          validityChecks = {
            ...validityChecks,
            quoteChange: validityData.quoteChange,
            serviceDateConflicts: validityData.serviceDateConflicts,
          };
        }
        return validityChecks;
      } catch (e) {
        throw e;
      }
    },

    async doGetServiceAgreementHistories(payload, rootState) {
      const endpoint = `api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/service-agreements/${payload.serviceAgreementId}/histories`;
      try {
        let result = await apiClient.get(endpoint);
        dispatch.customersStore.setCustomerServiceAgreementHistories(result.data);
      } catch (e) {
        throw e;
      }
    },
    async doGetServiceAgreementHistoryDetail(payload, rootState) {
      const endpoint = `api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/service-agreements/${payload.serviceAgreementId}/histories/${payload.userServiceAgreementHistoryId}`;
      try {
        let result = await apiClient.get(endpoint);
        dispatch.customersStore.setSelectedCustomerServiceAgreementHistory(result.data);
      } catch (e) {
        throw e;
      }
    },
    async doGetQuotation(payload, rootState) {
      const endpoint = `api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/service-agreements/quotations`;
      try {
        let result = await apiClient.post(endpoint, payload);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doUpdateQuotation(payload, rootState) {
      const endpoint = `api/portal/customers/${rootState.customersStore.selectedCustomer.userId}/service-agreements/${payload.serviceAgreementId}/quotations`;
      try {
        let [quotations, manualQuotations] = _.partition(payload.quotations, (quote) => !quote.isManualQuote);

        manualQuotations = _.map(manualQuotations, (manualQuote) => {
          return { ...manualQuote, manualQuotationDetail: manualQuote.subQuotes };
        });

        const requestBody = {
          ...payload,
          quotations,
          manualQuotations,
        };

        let result = await apiClient.put(endpoint, requestBody);
        if (result.data) {
          this.doFetchServiceAgreementDetails({ serviceAgreementId: payload.serviceAgreementId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doAddCustomerPreference(payload, rootStore) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/preferences`;
      try {
        let result = await apiClient.post(endpoint, payload);
        if (result.data) {
          this.setSelectedCustomer({ ...rootStore.customersStore.selectedCustomer, preferences: result.data });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerPreference(payload, rootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/preferences/${payload.carerPreferenceId}`;
      try {
        let result = await apiClient.put(endpoint, payload);
        this.updateCustomerPreference(payload);
      } catch (e) {
        throw e;
      }
    },

    async doDeleteCustomerPreference(payload, rootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/preferences/${payload.carerPreferenceId}`;
      try {
        let result = await apiClient.delete(endpoint, payload);
        this.deleteCustomerPreference(payload);
      } catch (e) {
        throw e;
      }
    },

    async doFetchCustomerPinnedAlerts(payload, rootStore) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/pinned-alerts`;
      try {
        let result = await apiClient.get(endpoint, payload);
        if (result.data) {
          this.setCustomerPinnedAlerts(result.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async doAddCustomerPinnedAlerts(payload, rootStore) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/pinned-alerts`;
      try {
        let result = await apiClient.post(endpoint, payload);
        if (result.data) {
          const customerPinnedAlerts = rootStore.customersStore.customerPinnedAlerts;
          customerPinnedAlerts.push(result.data);
          this.setCustomerPinnedAlerts(customerPinnedAlerts);
          this.addToCustomerPinnedAlert();
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerPinnedAlerts(payload, rootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/pinned-alerts/${payload.pinnedAlertId}`;
      try {
        let result = await apiClient.put(endpoint, payload);
        this.updateCustomerPinnedAlert(payload);
      } catch (e) {
        throw e;
      }
    },

    async doDeleteCustomerPinnedAlert(payload, rootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/pinned-alerts/${payload.pinnedAlertId}`;
      try {
        let result = await apiClient.delete(endpoint, payload);
        this.deleteCustomerPinnedAlert(payload);
      } catch (e) {
        throw e;
      }
    },

    async doGetGroupServiceCustomers(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/customers`;
      try {
        let newGroupServiceCustomers;
        const response = await apiClient.post(endpoint, payload);
        if (payload.page === 1) {
          newGroupServiceCustomers = response.data.customers;
        } else {
          newGroupServiceCustomers = [...rootState.customersStore.groupServiceCustomers, ...response.data.customers];
        }

        dispatch.customersStore.setGroupServiceCustomers(newGroupServiceCustomers);
      } catch (e) {
        throw e;
      }
    },

    async doCheckConflictWorkerForEditAddress(payload, rootState) {
      const endpoint = `api/portal/user/check-primary-address-conflict`;
      try {
        const result = await apiClient.post(endpoint, payload);
        return result;
      } catch (e) {
        throw e;
      }
    },

    async doFetchDisabilityInfoLiteList(payload, rootState) {
      const endpoint = `api/portal/user/disability-list-lite`;
      try {
        const response = await apiClient.post(endpoint, payload);
        dispatch.customersStore.setDisabilityInfoLiteList(response.data.disabilityInfo);
      } catch (e) {
        throw e;
      }
    },

    async doFetchCustomerViews(payload, rootState) {
      const endpoint = `api/portal/custom-views/customer/list`;
      const { displayedCustomerListingTabs } = rootState.customersStore;
      try {
        const response = await apiClient.post(endpoint, payload);
        await dispatch.customersStore.setCustomerViews(response.data);
        await dispatch.customersStore.setDisplayedCustomerListingTabs([...displayedCustomerListingTabs]);
      } catch (e) {
        throw e;
      }
    },

    async doAddCustomerView({ sharedWith, filterValue, customViewId, ...payload }, rootState) {
      const endpoint = `/api/portal/custom-views/customer`;
      const { customerViews, displayedCustomerListingTabs } = rootState.customersStore;
      try {
        const sharedWithIds = sharedWith.map((user) => user && user.supportWorkerId);

        payload = {
          ...payload,
          sharedWithIds,
          filterValue: mapCustomerViewsFilterPayload(filterValue),
        };

        const response = await apiClient.post(endpoint, payload);
        const newTab = {
          ...payload,
          ...response.data,
          sharedWith,
          isOwner: true,
          isPinned: false,
        };
        dispatch.customersStore.setCustomerViews([...customerViews, newTab]);
        await dispatch.customersStore.setDisplayedCustomerListingTabs([...displayedCustomerListingTabs, newTab]);
        dispatch.customersStore.setCustomerListingActiveTab(newTab);
      } catch (e) {
        throw e;
      }
    },

    async doDuplicateCustomerView({ sharedWith, filterValue, customViewId, ...payload }, rootState) {
      const endpoint = `/api/portal/custom-views/customer/${customViewId}/duplicate`;
      const { customerViews, displayedCustomerListingTabs } = rootState.customersStore;
      try {
        const sharedWithIds = sharedWith.map((user) => user && user.supportWorkerId);
        payload = {
          ...payload,
          sharedWithIds,
        };

        const response = await apiClient.post(endpoint, payload);
        const newTab = {
          ...payload,
          ...response.data,
          sharedWith,
          isOwner: true,
          isPinned: false,
        };
        dispatch.customersStore.setCustomerViews([...customerViews, newTab]);
        await dispatch.customersStore.setDisplayedCustomerListingTabs([...displayedCustomerListingTabs, newTab]);
        dispatch.customersStore.setCustomerListingActiveTab(newTab);
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerView({ sharedWith, filterValue, customViewId, isTogglePinned, ...payload }, rootState) {
      const endpoint = `/api/portal/custom-views/customer/${customViewId}`;
      const { customerViews, displayedCustomerListingTabs } = rootState.customersStore;
      try {
        let requestPayload = { ...payload };
        if (isTogglePinned) {
          requestPayload = {
            isPinned: payload.isPinned,
          };
        } else {
          if (sharedWith) {
            const sharedWithIds = sharedWith.map((user) => user && user.supportWorkerId);
            requestPayload = {
              ...payload,
              sharedWithIds,
              filterValue: mapCustomerViewsFilterPayload(filterValue),
            };
          }
        }

        await apiClient.put(endpoint, requestPayload);
        const newView = {
          ...payload,
          customViewId,
          filterValue,
          sharedWith,
        };
        const newCustomerViews = customerViews.map((view) => {
          return view.customViewId === newView.customViewId ? { ...view, ...newView } : view;
        });

        await dispatch.customersStore.setCustomerViews(newCustomerViews);

        // if current view is displayed, update the displayed view
        const displayedIndex = displayedCustomerListingTabs.findIndex(
          (view) => view.customViewId === newView.customViewId,
        );

        if (displayedIndex > -1) {
          displayedCustomerListingTabs[displayedIndex] = newView;
          await dispatch.customersStore.setDisplayedCustomerListingTabs([...displayedCustomerListingTabs]);
        }

        dispatch.customersStore.setCustomerListingActiveTab(newView);
      } catch (e) {
        throw e;
      }
    },

    async doDeleteCustomerView({ customViewId, ...payload }, rootState) {
      const endpoint = `/api/portal/custom-views/customer/${customViewId}`;
      const { customerViews, displayedCustomerListingTabs, defaultCustomerViews } = rootState.customersStore;
      try {
        await apiClient.delete(endpoint);
        const newView = {
          ...payload,
          customViewId,
        };
        const newCustomerViews = customerViews.filter((view) => view.customViewId !== newView.customViewId);

        await dispatch.customersStore.setCustomerViews(newCustomerViews);

        // if current view is displayed, update the displayed view
        const displayedIndex = displayedCustomerListingTabs.findIndex(
          (view) => view.customViewId === newView.customViewId,
        );

        if (displayedIndex > -1) {
          displayedCustomerListingTabs.splice(displayedIndex, 1);
          await dispatch.customersStore.setDisplayedCustomerListingTabs([...displayedCustomerListingTabs]);
        }

        dispatch.customersStore.setCustomerListingActiveTab(_.first(defaultCustomerViews));
      } catch (e) {
        throw e;
      }
    },

    async doAddCustomerDebtor(payload, rootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/debtors`;
      try {
        const response = await apiClient.post(endpoint, payload);

        if (response.data) {
          this.doGetCustomer({ userId: payload.customerUserId });
          return response.data.debtor.debtorId;
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateIgnoreAnAlert(payload, rootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/ignoreAlert`;
      try {
        const requestBody = { alertType: payload.alertType };
        await apiClient.put(endpoint, requestBody);
      } catch (e) {
        throw e;
      }
    },

    async doRemoveCustomerDebtor(payload, rootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/debtors/${payload.debtorId}`;
      try {
        const requestBody = { debtorType: payload.debtorType };
        const response = await apiClient.delete(endpoint, requestBody);
        if (response.data) {
          this.doGetCustomer({ userId: payload.customerUserId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchSupportCoordinators(payload, rootState) {
      try {
        const filter = rootState.customersStore.supportCoordinatorFilter;
        const endpoint = 'api/portal/support-worker/list';

        const response = await apiClient.post(endpoint, filter);
        if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
          const supportCoordinators = response.data.map((item) => ({
            fullName: `${item.firstName} ${item.lastName}`,
            supportCoordinatorId: item.supportWorkerId,
            attachmentUrl: item.attachmentUrl,
          }));
          dispatch.customersStore.setSupportCoordinators(supportCoordinators);
          return supportCoordinators;
        } else {
          dispatch.customersStore.setSupportCoordinators([]);
          return [];
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchCaseManagers(payload, rootState) {
      try {
        const filter = rootState.customersStore.caseManagerFilter;
        const endpoint = '/api/portal/user/case-manager/list';

        const response = await apiClient.post(endpoint, { search: filter });
        if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
          dispatch.customersStore.setCaseManagers(response.data);
        } else {
          dispatch.customersStore.setCaseManagers();
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchCustomerForms(payload: { customerUserId: string }, rootState: IRootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/forms/list`;
      try {
        const response = await apiClient.get(endpoint);
        if (response.data) {
          dispatch.customersStore.setCustomerForms(response.data.forms);
          return true;
        }
      } catch (e) {
        throw e;
      }
    },

    async doAddCustomerForm(
      payload: { customerId: string; formId: string; formData: string; status: string; isSyncCustomerData: boolean },
      rootState,
    ) {
      try {
        const { customerId, formId, formData, status, isSyncCustomerData } = payload;
        const endpoint = `api/portal/customers/${customerId}/forms`;
        const { data } = await apiClient.post(endpoint, { formId, formData, status, isSyncCustomerData });
        return data;
      } catch (e) {
        throw e;
      }
    },

    async doFetchFormDetailsByUserFormId(payload: { customerId: string; userFormId: string }, rootState) {
      try {
        const { customerId, userFormId } = payload;
        const endpoint = `api/portal/customers/${customerId}/forms/${userFormId}`;
        const { data } = await apiClient.get(endpoint);

        if (data) {
          const formattedData = {
            formContent: {
              title: { formTitle: data.formName, formDescription: data.formDescription },
              elements: withBackwardsCompatibleChildElements(data.formElements),
              formType: data.formType,
            },
            formData: data.formData,
          };
          dispatch.formBuilderStore.setCurrentForm(formattedData.formContent);
          return formattedData;
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateCustomerForm(
      payload: {
        customerId: string;
        userFormId: string;
        formData: string;
        status: string;
        isSyncCustomerData: boolean;
      },
      rootState,
    ) {
      try {
        const { customerId, userFormId, formData, status, isSyncCustomerData } = payload;
        const endPoint = `api/portal/customers/${customerId}/forms/${userFormId}/form-data`;
        const { data } = await apiClient.put(endPoint, { formData, status, isSyncCustomerData });
        return data;
      } catch (e) {
        throw e;
      }
    },

    async doDeleteCustomerForm(payload: { customerId: string; userFormId: string }, rootState: IRootState) {
      try {
        const { customerId, userFormId } = payload;
        const endpoint = `api/portal/customers/${customerId}/forms/${userFormId}`;
        const { data } = await apiClient.delete(endpoint);
        return data;
      } catch (e) {
        throw e;
      }
    },

    async doGetCountriesList() {
      try {
        const response = await apiClient.get(`/api/portal/user/countries`);
        const countries = response.data;

        return countries;
      } catch (e) {
        throw e;
      }
    },

    async doAssignSupportCoordinator(payload, rootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/support-coordinators/assign`;
      try {
        const requestBody = { supportCoordinatorIds: payload.supportCoordinatorIds };
        const response = await apiClient.post(endpoint, requestBody);
        if (response.data) {
          dispatch.authStore.setCurrentPortalUser({
            ...rootState.authStore.portalUser,
            hasSupportCoordinatorCustomer: response.data.currentUserHasSupportCoordinatorCustomer,
          });
          await this.doGetCustomer({ userId: payload.customerUserId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doRemoveSupportCoordinator(payload, rootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/support-coordinators`;
      try {
        const requestBody = { supportCoordinatorId: payload.supportCoordinatorId };
        const response = await apiClient.delete(endpoint, requestBody);
        if (response.data) {
          dispatch.authStore.setCurrentPortalUser({
            ...rootState.authStore.portalUser,
            hasSupportCoordinatorCustomer: response.data.currentUserHasSupportCoordinatorCustomer,
          });
          await this.doGetCustomer({ userId: payload.customerUserId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateAssignSupportCoordinator(payload) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/support-coordinators/assign`;
      try {
        const requestBody = { supportCoordinatorIds: payload.supportCoordinatorIds };
        const response = await apiClient.put(endpoint, requestBody);
        if (response.data) {
          this.doGetCustomer({ userId: payload.customerUserId });
        }
      } catch (e) {
        throw e;
      }
    },

    async doRemoveContact(payload) {
      try {
        const endpoint = `api/portal/customers/${payload.customerUserId}/contacts/${payload.userContactId}`;
        const response = await apiClient.delete(endpoint);

        if (response.data) {
          this.doGetCustomer({ userId: payload.customerUserId });
        }
      } catch (e) {
        throw e;
      }
    },
    async doGetCustomerProfilePicture(payload) {
      const endpoint = `/api/portal/customers/${payload.customerUserId}/avatar`;
      const response = await apiClient.get(endpoint);

      return response.data;
    },

    async doUploadCustomerAvatar({
      customerUserId,
      payload,
      customerAttachmentId,
    }: {
      customerUserId: string;
      customerAttachmentId?: string;
      payload: {
        avatarUrl: string;
        avatarFilePath: string;
        originalUrl?: string;
        originalFilePath?: string;
        originalFileName?: string;
      };
    }) {
      const endpoint = `/api/portal/customers/${customerUserId}/avatar${
        customerAttachmentId ? `/${customerAttachmentId}` : ''
      }`;
      const response = await apiClient.put(endpoint, payload);

      return response.data;
    },
    async doRemoveOrDeleteCustomerProfilePicture(payload) {
      const endpoint = `/api/portal/customers/${payload.customerUserId}/avatar`;
      const response = await apiClient.delete(endpoint, {
        customerAttachmentId: payload.customerAttachmentId,
        isRemovePermanent: payload.isRemovePermanent,
      });

      return response.data;
    },
  }),
};

export default customersStore;
