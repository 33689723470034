import { Tabs } from 'antd';
import React, { PureComponent } from 'react';
import { WORKFLOW_TEMPLATE_DETAIL_TABS } from 'views/workflows/utils/constants';
import '../../../css/workflow-styles.css';

const { TabPane } = Tabs;

interface IWorkflowTemplateDetailTabPanelProps {
  selectedTab: string;
  onChangeTab(tab: string): void;
}

class WorkflowTemplateDetailTabPanel extends PureComponent<IWorkflowTemplateDetailTabPanelProps> {
  render() {
    const { selectedTab, onChangeTab } = this.props;

    return (
      <div className="bg-white">
        <Tabs activeKey={selectedTab} animated={true} onChange={onChangeTab} className={'workflow-detail-tab'}>
          {WORKFLOW_TEMPLATE_DETAIL_TABS.map((tab) => (
            <TabPane tab={tab.title} key={tab.key} />
          ))}
        </Tabs>
      </div>
    );
  }
}

export default WorkflowTemplateDetailTabPanel;
