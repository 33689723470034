import React, { Component } from 'react';
import _ from 'lodash';
import KeyContactDetailRow from './KeyContactDetailRow';
import { GhostButton, PrimaryButton } from '../../../../../../common-components/buttons';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/es/form';
import { Form, notification } from 'antd';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { Spinner } from '@blueprintjs/core';
import { Paragraph, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from '../../../../../../common-components/modal/ActionModal';

interface IFundEditKeyContactsPanelProps extends FormComponentProps {
  closeEditContactModal: () => void;
  packageId: string;
  selectedCustomer: any;
  doUpdateFundingPackageContact: typeof dispatch.customersStore.doUpdateFundingPackageContact;
}

interface IFundEditKeyContactsPanelState {
  isLoading: boolean;
  keyContactsInputs: any[];
  rowID: number;
  isSaving: boolean;
  isCancelActionModalOpen: boolean;
  isDeleteActionModalOpen: boolean;
}

class FundEditKeyContactsPanel extends Component<IFundEditKeyContactsPanelProps, IFundEditKeyContactsPanelState> {
  private keyContactInputRefs: any[] = [];

  state = {
    isLoading: false,
    rowID: -1,
    keyContactsInputs: [],
    isSaving: false,
    isCancelActionModalOpen: false,
    isDeleteActionModalOpen: false,
  };

  componentWillMount() {
    const { selectedCustomer, packageId } = this.props;
    let fundpackages = selectedCustomer.funding.NDISFundingPackages;
    const selectedPackage = _.find(fundpackages, (fundpackage) => fundpackage.fundingPackageId === packageId);
    let contacts = selectedPackage.fundingContacts;
    _.map(contacts, (contact) => {
      this.state.keyContactsInputs.push({
        name: contact.name,
        role: contact.role,
        email: contact.email,
        mobileCountryCode: contact.mobileCountryCode,
        mobileNumber: contact.mobileNumber,
        officeCountryCode: contact.officeCountryCode,
        officeNumber: contact.officeNumber,
      });
    });
  }

  private _closeCancelModalWithAction = () => {
    const { selectedCustomer, packageId } = this.props;
    let fundpackages = selectedCustomer.funding.NDISFundingPackages;
    const selectedPackage = _.find(fundpackages, (fundpackage) => fundpackage.fundingPackageId === packageId);
    let prevContacts = selectedPackage.fundingContacts;

    let isSame =
      prevContacts.length === this.state.keyContactsInputs.length &&
      prevContacts.every(
        (e, i) =>
          e.name === this.state.keyContactsInputs[i].name &&
          e.role === this.state.keyContactsInputs[i].role &&
          e.email === this.state.keyContactsInputs[i].email &&
          e.mobileNumber === this.state.keyContactsInputs[i].mobileNumber &&
          e.officeNumber === this.state.keyContactsInputs[i].officeNumber,
      );

    if (!isSame) {
      this.setState({ isCancelActionModalOpen: true });
    } else {
      this.props.closeEditContactModal();
    }
  };

  private _closeCancelActionModal = () => {
    this.setState({ isCancelActionModalOpen: false });
  };

  private _handleInputData = (event, key) => {
    const keyContactsInputs = this.state.keyContactsInputs;
    keyContactsInputs[key][event.target.name] = event.target.value;
    this.setState({ keyContactsInputs });
  };

  private _handleDropDownClick = (value, name, key) => {
    const keyContactsInputs = this.state.keyContactsInputs;
    keyContactsInputs[key][name] = value;
    this.setState({ keyContactsInputs });
  };

  private _removeKeyContactInputRow = (rowID) => {
    this.setState({ isDeleteActionModalOpen: true });
    this.setState({ rowID });
  };

  private _proceedDeleteActionModal = () => {
    let rowID = this.state.rowID;
    if (rowID >= 0) {
      const keyContactsInputs = [...this.state.keyContactsInputs];
      _.remove(keyContactsInputs, (item, index) => {
        return index === rowID;
      });
      this.setState({
        keyContactsInputs,
      });
    }
    this.setState({ isDeleteActionModalOpen: false });
  };

  private _closeDeleteActionModal = () => {
    this.setState({
      isDeleteActionModalOpen: false,
    });
  };

  private _keyContactSection = () => {
    return this.state.keyContactsInputs.map((el, i) => (
      <KeyContactDetailRow
        rowID={i}
        key={i}
        keyContactsInputs={el}
        wrappedComponentRef={this._setRef}
        removeKeyContactInputRow={this._removeKeyContactInputRow}
        handleInputData={(event) => {
          this._handleInputData(event, i);
        }}
        handleDropDownClick={(value, name) => {
          this._handleDropDownClick(value, name, i);
        }}
      />
    ));
  };

  private _addKeyContactInputRow = () => {
    this.setState((prevState) => ({
      keyContactsInputs: [
        ...prevState.keyContactsInputs,
        {
          name: '',
          role: '',
          email: '',
          mobileCountryCode: 'AU',
          mobileNumber: '',
          officeCountryCode: 'AU',
          officeNumber: '',
        },
      ],
    }));
  };

  private _setRef = (ref) => {
    if (ref) {
      this.keyContactInputRefs.push(ref);
    }
  };

  private _onSave = async () => {
    const { selectedCustomer, packageId, doUpdateFundingPackageContact } = this.props;
    let isFormValid = true;

    if (this.keyContactInputRefs.length > 0) {
      _.forEach(this.keyContactInputRefs, (keyContactInputRef) => {
        keyContactInputRef.props.form.validateFields((err) => {
          if (err) {
            isFormValid = false;
          }
        });
      });
    }

    if (isFormValid) {
      if (this.state.keyContactsInputs.length > 0) {
        let payload = {
          userId: selectedCustomer.userId,
          fundingPackageId: packageId,
          contacts: this.state.keyContactsInputs,
        };
        this.setState({ isSaving: true });
        await doUpdateFundingPackageContact(payload);
        this.setState({ isSaving: false });
        this.props.closeEditContactModal();
      } else {
        notification.error({ message: 'Validate', description: 'At least one contact is required.' });
      }
    }
  };

  render() {
    return (
      <div>
        {this.state.isSaving ? (
          <div>
            <div className="mt-x-large">
              <div className="flex-row align-center mb-x-large">
                <div className="mr-x-large">
                  <Spinner size={120} />
                </div>
                <div>
                  <Paragraph>Loading.......</Paragraph>
                  <Paragraph weight="bold">Editing Contacts.</Paragraph>
                  <Paragraph>This won't take long.</Paragraph>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <ActionModal
              isOpen={this.state.isDeleteActionModalOpen}
              onClose={this._closeDeleteActionModal}
              title={'Confirmation'}
              showCloseButton={true}
            >
              <Text className={'mb-medium'}>You are deleting a contact.</Text>
              <br />
              <Text className={'mb-medium'}>Do you want to proceed?</Text>
              <ActionModalFooter>
                <PrimaryButton className="mr-medium" size="large" onClick={this._closeDeleteActionModal}>
                  Cancel
                </PrimaryButton>
                <GhostButton size="large" onClick={this._proceedDeleteActionModal}>
                  Proceed
                </GhostButton>
              </ActionModalFooter>
            </ActionModal>
            <ActionModal
              isOpen={this.state.isCancelActionModalOpen}
              onClose={this._closeCancelActionModal}
              title={'Confirmation'}
              showCloseButton={true}
            >
              <Text className={'mb-medium'}>You are closing the editing page without saving.</Text>
              <br />
              <Text className={'mb-medium'}>Do you want to proceed?</Text>
              <ActionModalFooter>
                <PrimaryButton className="mr-medium" size="large" onClick={this._closeCancelActionModal}>
                  Cancel
                </PrimaryButton>
                <GhostButton size="large" onClick={this.props.closeEditContactModal}>
                  Proceed
                </GhostButton>
              </ActionModalFooter>
            </ActionModal>
            <div className={'mt-small customScrollDiv'}>{this._keyContactSection()}</div>
            <div className="mb-x-large">
              <GhostButton icon={'plus-circle'} onClick={this._addKeyContactInputRow}>
                Add a contact
              </GhostButton>
            </div>
            <div className={'mt-x2-large flex-row justify-end align-center'}>
              <GhostButton className={'mr-small'} onClick={this._closeCancelModalWithAction}>
                Cancel
              </GhostButton>
              <PrimaryButton size="large" icon={'save'} onClick={this._onSave}>
                Save Changes
              </PrimaryButton>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateFundingPackageContact: dispatch.customersStore.doUpdateFundingPackageContact,
});

export default connect(null, mapDispatch)(Form.create<IFundEditKeyContactsPanelProps>()(FundEditKeyContactsPanel));
