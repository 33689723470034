import React, { useState } from 'react';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { notifications } from '@good/ui/notifications';

import defaultBanner from 'assets/banner/SIL.png';
import { Service } from 'utilities/react-trpc';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import {
  ErrorAlert,
  SilServiceContextResolved,
  useInlineForm,
  useSilServiceContext,
} from 'views/sil-service/components';
import { useUpdateService } from 'views/sil-service/hooks';

const schema = z.object({
  coverImage: z.array(z.string()),
});

type Schema = z.infer<typeof schema>;

const getBannerAttachmentFromService = (service: Service): Partial<Service['attachments'][number]> | undefined => {
  return service.attachments.find((attachment) => attachment.attachmentType === 'BANNER');
};

export const CoverPhotoForm = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceDetailsTabs.gallery.coverPhotoForm' });
  const { t: tMessages } = useTranslation('', { keyPrefix: 'silService.messages' });
  const portalUser = usePortalUser();
  const { service } = useSilServiceContext() as SilServiceContextResolved;
  const updateService = useUpdateService();
  const [bannerAttachment, setBannerAttachment] = useState<Partial<Service['attachments'][number]> | undefined>(() =>
    getBannerAttachmentFromService(service),
  );

  const InlineForm = useInlineForm<Schema>({
    values: {
      coverImage: bannerAttachment?.attachmentUrl ? [bannerAttachment.attachmentUrl] : [],
    },
    resolver: zodResolver(schema),
  });

  if (!portalUser) {
    return (
      <InlineForm
        title={t('title')}
        onSubmit={() => {
          return;
        }}
        disabled
        maxWidth={546}
      >
        <ErrorAlert message={tMessages('portalUser.error')} />
      </InlineForm>
    );
  }

  const onSubmit = async (values: z.infer<typeof schema>) => {
    try {
      const updatedService = await updateService.mutateAsync({
        serviceId: service.serviceId,
        serviceProviderId: service.serviceProviderId,
        data: {
          attachments: [
            {
              serviceAttachmentId: bannerAttachment?.serviceAttachmentId,
              attachmentUrl: values.coverImage[0],
              attachmentPath: `serviceProvider/${portalUser.serviceProviderId}/service/${service.serviceId}/coverImage`,
              attachmentType: 'BANNER',
            },
          ],
        },
      });

      const updatedAttachment = getBannerAttachmentFromService(updatedService as Service);
      setBannerAttachment(updatedAttachment);
    } catch (e) {
      notifications.show({
        color: 'var(--color-red-main)',
        message: tMessages('updateService.error'),
      });
      throw e;
    }
    notifications.show({
      color: 'var(--color-green-main)',
      message: tMessages('updateService.success'),
    });
  };

  return (
    <InlineForm title={t('title')} onSubmit={onSubmit} maxWidth={546}>
      <InlineForm.ImageField
        name='coverImage'
        label={t('fields.coverImage.label')}
        description={t('fields.coverImage.description')}
        imageUploadUrl={`serviceProvider/${portalUser.serviceProviderId}/service/${service.serviceId}/coverImage`}
        alt={t('fields.coverImage.alt')}
        defaultImageUrl={defaultBanner as string}
      />
    </InlineForm>
  );
};
