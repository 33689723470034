import React from 'react';

export function Wrench(): JSX.Element {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.336 3.119a.75.75 0 0 1-.209.657L15.833 7.07c-.348.348-.555.557-.684.726a.709.709 0 0 0-.095.155l-.001.003v.002l.004.011c.01.022.033.067.092.144.13.17.336.378.684.727.348.348.557.555.726.684a.709.709 0 0 0 .155.095l.003.001h.003a.703.703 0 0 0 .155-.095c.169-.13.378-.337.726-.685l3.294-3.294a.75.75 0 0 1 1.207.207 6.753 6.753 0 0 1-8.508 9.214l-6.246 6.247a2.75 2.75 0 1 1-3.89-3.89l6.247-6.246A6.75 6.75 0 0 1 18.92 2.57a.75.75 0 0 1 .416.55Zm-2.108.434a5.253 5.253 0 0 0-5.957 7.371.75.75 0 0 1-.146.854l-6.606 6.606a1.25 1.25 0 0 0 1.768 1.767l6.606-6.605a.75.75 0 0 1 .853-.146 5.252 5.252 0 0 0 7.371-5.958l-2.455 2.456-.032.032c-.306.306-.587.587-.846.784-.285.218-.63.404-1.067.404-.437 0-.782-.186-1.067-.404-.26-.197-.54-.478-.846-.784l-.031-.032-.032-.031c-.306-.306-.587-.587-.784-.846-.218-.285-.404-.63-.404-1.067 0-.437.186-.782.403-1.068.198-.259.479-.54.785-.845l.032-.032 2.455-2.456Zm-2.175 4.4v.001"
        fill="#fff"
      />
    </svg>
  );
}
