import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ChatSlash = forwardRef(function ChatSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53 1.47a.75.75 0 0 0-1.06 1.06l2.606 2.606A9.721 9.721 0 0 0 2.25 12c0 1.047.22 2.073.507 2.973.31.972.536 1.677.682 2.209.15.55.188.824.181.964-.006.113-.01.16-.015.199-.006.04-.015.085-.04.195-.036.157-.121.36-.317.695-.197.335-.474.752-.866 1.34A.75.75 0 0 0 3 21.75h9a9.722 9.722 0 0 0 6.864-2.824l2.606 2.605a.75.75 0 0 0 1.06-1.06l-19-19Zm14.273 16.394L6.136 6.197A8.221 8.221 0 0 0 3.75 12c0 .846.179 1.712.436 2.517l.006.018c.303.95.539 1.685.693 2.25.151.55.254 1.032.233 1.438-.014.276-.03.387-.092.656-.087.375-.26.731-.484 1.115-.048.082-.1.167-.155.256H12c2.263 0 4.312-.91 5.803-2.386Zm-7.637-13.91a8.25 8.25 0 0 1 9.615 10.796.75.75 0 0 0 1.414.5A9.689 9.689 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75a9.78 9.78 0 0 0-3.212.542.75.75 0 0 0 .494 1.416c.287-.1.583-.185.884-.254Z"
        fill="currentColor"
      />
    </Icon>
  );
});
