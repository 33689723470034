import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group } from '@good/ui/core';
import { PageHeader } from '@good/ui/templates';
import { DatePickerInput } from '@good/ui/dates';
import { Calendar } from '@good/icons';
import { PayPeriodFormProvider, PayPeriodSchema, usePayPeriodForm } from './formContext';
import { SelectTime } from './components/SelectTime';
import { ConfirmModal } from './components/ConfirmModal';
import { RemoveModal } from './components/RemoveModal';
import { useDisclosure } from '@good/ui/hooks';
import { ServiceProvider, reactTrpc } from 'utilities/react-trpc';
import { notifications } from '@good/ui/notifications';
import { TRPCClientError } from '@trpc/client';
import { IRootState } from 'stores/rematch/root-store';
import { useSelector } from 'react-redux';

type PayPeriodFormProps = {
  initialValues: ServiceProvider;
  isLoading: boolean;
};

export const PayPeriodForm = ({ initialValues, isLoading }: PayPeriodFormProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'accountManagement.payPeriodPage' });
  const utils = reactTrpc.useUtils();
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  const form = usePayPeriodForm({
    initialValues: {
      payPeriodStartDate: initialValues.payPeriodStartDate,
      payPeriodDuration: initialValues.payPeriodDuration,
    },
  });

  const [isOpenConfirmModal, { open: onOpenConfirmModal, close: onCloseConfirmModal }] = useDisclosure();
  const [isOpenRemoveModal, { open: onOpenRemoveModal, close: onCloseRemoveModal }] = useDisclosure();

  const updatePayPeriodEndpoint = reactTrpc.serviceProvider.payPeriod.update.useMutation({
    onSuccess: () => {
      void utils.serviceProvider.payPeriod.fetch.invalidate({
        serviceProviderId: portalUser?.serviceProviderId ?? '',
      });
    },
  });

  const handleSubmit = form.onSubmit((values: PayPeriodSchema) => {
    if (!portalUser) return;

    const { payPeriodStartDate, payPeriodDuration } = values;

    updatePayPeriodEndpoint.mutate(
      {
        serviceProviderId: portalUser.serviceProviderId,
        payPeriodStartDate,
        payPeriodDuration: payPeriodDuration ?? null,
      },
      {
        onSuccess: () => {
          form.setInitialValues({ ...values });
          form.reset();
          notifications.show({
            title: t('confirmSuccess'),
            message: '',
            color: 'green',
          });
          onCloseConfirmModal();
        },
        onError: (e) => {
          notifications.show({
            title: t('confirmError'),
            message: e instanceof TRPCClientError ? e.message : 'Something went wrong. Try again later.',
            color: 'red',
          });
        },
      },
    );
  });

  const confirmRemove = () => {
    if (!portalUser) return;

    updatePayPeriodEndpoint.mutate(
      {
        serviceProviderId: portalUser.serviceProviderId,
        payPeriodStartDate: null,
        payPeriodDuration: null,
      },
      {
        onSuccess: () => {
          form.setInitialValues({
            payPeriodStartDate: null,
            payPeriodDuration: null,
          });
          form.reset();
          notifications.show({
            title: t('removeSuccess'),
            message: '',
            color: 'green',
          });
          onCloseRemoveModal();
        },
        onError: (e) => {
          notifications.show({
            title: t('removeError'),
            message: e instanceof TRPCClientError ? e.message : 'Something went wrong. Try again later.',
            color: 'red',
          });
        },
      },
    );
  };

  const handleSave = (e) => {
    e.preventDefault();
    initialValues?.payPeriodStartDate === null ? handleSubmit() : onOpenConfirmModal();
  };

  const handleCancel = () => {
    form.setValues({
      payPeriodStartDate: initialValues?.payPeriodStartDate,
      payPeriodDuration: initialValues?.payPeriodDuration,
    });
  };

  const formDirty = form.isDirty();

  const isFormValid = form.values.payPeriodStartDate !== null && form.values.payPeriodDuration !== null;

  const Actions = () => (
    <Group>
      {formDirty && (
        <Button type='reset' variant='default' onClick={handleCancel}>
          {t('cancel')}
        </Button>
      )}
      {initialValues?.payPeriodStartDate !== null && !formDirty && (
        <Button type='button' variant='default' onClick={onOpenRemoveModal}>
          {t('remove')}
        </Button>
      )}
      <Button type='submit' disabled={!isFormValid || !formDirty}>
        {t('save')}
      </Button>
    </Group>
  );

  return (
    <PayPeriodFormProvider form={form}>
      <form onSubmit={handleSave}>
        <PageHeader title={t('heading')} subtitle={t('description')} actions={<Actions />} size='h2' />
        {!isLoading && (
          <div className='flex-column row-gap-x-large'>
            <DatePickerInput
              label={t('payPeriodStartDate')}
              placeholder={t('payPeriodDatePlaceholder')}
              rightSection={!form.values.payPeriodStartDate && <Calendar />}
              clearable
              clearButtonProps={{ 'aria-label': 'Clear date' }}
              size='md'
              w='324'
              {...form.getInputProps('payPeriodStartDate')}
              onChange={(date) => {
                form.getInputProps('payPeriodStartDate').onChange(date);
                if (!date) {
                  form.reset();
                }
              }}
            />
            <SelectTime />
          </div>
        )}
      </form>
      <ConfirmModal isOpen={isOpenConfirmModal} onClose={onCloseConfirmModal} onConfirm={handleSubmit} />
      <RemoveModal isOpen={isOpenRemoveModal} onClose={onCloseRemoveModal} onConfirm={confirmRemove} />
    </PayPeriodFormProvider>
  );
};
