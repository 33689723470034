import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Checkbox, Input } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { TimezoneInput } from 'common-components/timezone';
import moment from 'moment-timezone';

interface IEditTimezoneActionModalProps extends FormComponentProps {
  isOpen: boolean;
  portalUser: typeof state.authStore.portalUser;
  doUpdatePortalUserTimezone: typeof dispatch.authStore.doUpdatePortalUserTimezone;
  doUpdatePortalUserSettingTimezone: typeof dispatch.authStore.doUpdatePortalUserSettingTimezone;
  onCloseModal: () => void;
}

interface IEditTimezoneActionModalState {
  isLoading: boolean;
  disabledTimezoneInput: boolean;
}

class EditTimezoneActionModal extends Component<IEditTimezoneActionModalProps, IEditTimezoneActionModalState> {
  state = {
    isLoading: false,
    disabledTimezoneInput: this.props.portalUser.displayTimezoneSetting
      ? this.props.portalUser.displayTimezoneSetting.autoDetect
      : true,
  };

  private _onSaveChange = () => {
    const { form, portalUser, doUpdatePortalUserTimezone, doUpdatePortalUserSettingTimezone, onCloseModal } =
      this.props;
    form.validateFieldsAndScroll(async (err, value) => {
      if (!err) {
        this.setState({ isLoading: true });
        try {
          if (value.autoDetect !== portalUser.displayTimezoneSetting.autoDetect) {
            await doUpdatePortalUserSettingTimezone({ autoDetect: value.autoDetect, timezone: value.timezone });
          } else if (value.timezone && value.timezone !== portalUser.timezone) {
            await doUpdatePortalUserTimezone({ timezone: value.timezone });
          }
          this.setState({ isLoading: false });
          //TODO: close modal
          onCloseModal();
        } catch (e) {
          this.setState({ isLoading: false });
        }
      }
    });
  };

  private _onCancelChange = () => {
    const { onCloseModal } = this.props;
    onCloseModal();
  };

  private _updateDisplayTimezoneInput = (event) => {
    this.setState({ disabledTimezoneInput: event.target.checked });
    if (event.target.checked) {
      // Set the timezone to the user's machine timezone.
      this.props.form.setFieldsValue({ timezone: moment.tz.guess() });
    }
  };

  componentDidUpdate(
    prevProps: Readonly<IEditTimezoneActionModalProps>,
    prevState: Readonly<IEditTimezoneActionModalState>,
    snapshot?: any,
  ) {
    if (prevProps.portalUser !== this.props.portalUser) {
      this.setState({
        disabledTimezoneInput: this.props.portalUser.displayTimezoneSetting
          ? this.props.portalUser.displayTimezoneSetting.autoDetect
          : true,
      });
    }
  }

  render() {
    const { isOpen, form, onCloseModal, portalUser } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ActionModal
        isOpen={isOpen}
        title="Edit Timezone Settings"
        onClose={onCloseModal}
        width={'medium'}
        verticalAlignment={'high'}
      >
        <div>
          <Text>
            Your timezone is currently set as{' '}
            {portalUser.displayTimezoneSetting && !portalUser.displayTimezoneSetting.autoDetect ? (
              <b>Manual</b>
            ) : (
              <b>Automatic</b>
            )}
            . <br />
            Time zones will follow your browser settings.
          </Text>
          <br /> <br />
          <div>
            <Form.Item className={'m-none'}>
              {getFieldDecorator('autoDetect', {
                valuePropName: 'checked',
                initialValue: portalUser.displayTimezoneSetting ? portalUser.displayTimezoneSetting.autoDetect : true,
              })(
                <Checkbox onClick={this._updateDisplayTimezoneInput}>
                  <Text size="regular" weight="bold">
                    Set time zone automatically
                  </Text>
                </Checkbox>,
              )}
            </Form.Item>
          </div>
          <Form.Item className={'m-none'}>
            {getFieldDecorator('timezone', {
              initialValue: portalUser.timezone,
            })(<TimezoneInput disabled={this.state.disabledTimezoneInput} size={'large'} usePortal={false} />)}
          </Form.Item>
        </div>
        <ActionModalFooter>
          <GhostButton size="large" onClick={this._onCancelChange} disabled={this.state.isLoading}>
            Cancel
          </GhostButton>
          <PrimaryButton className="mr-medium" size="large" onClick={this._onSaveChange} loading={this.state.isLoading}>
            Save changes
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });
const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdatePortalUserTimezone: dispatch.authStore.doUpdatePortalUserTimezone,
  doUpdatePortalUserSettingTimezone: dispatch.authStore.doUpdatePortalUserSettingTimezone,
});

export default connect(mapState, mapDispatch)(Form.create<IEditTimezoneActionModalProps>()(EditTimezoneActionModal));
