import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { Text } from '@good/components';
import { ChevronDown, Filter } from '@good/icons';
import { useClickAway } from 'react-use';
import clsx from 'clsx';

type FilterItem = {
  name: string;
  title: string;
};

type CheckboxFilterProperties = {
  items: FilterItem[] | undefined;
  selectedItems: string[];
  setSelectedItems: (items: string[]) => void;
  onApply: (items: string[]) => void;
  headerLabel: string;
};

type CheckboxFilterItemProperties = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  labelText: string;
};

export const CheckboxFilterItem: React.FC<CheckboxFilterItemProperties> = ({ onChange, checked, labelText }) => {
  return (
    <label className='align-center flex w-full flex-row justify-between space-x-2 whitespace-nowrap'>
      <Text className='select-none'>{labelText}</Text>
      <div className='align-center min-w-4 max-w-4 min-h-4 relative flex h-4 max-h-4 w-4 justify-center [&>*]:absolute'>
        <input
          type='checkbox'
          onChange={onChange}
          checked={checked}
          className='min-w-4 max-w-4 min-h-4 h-4 max-h-4 w-4 rounded-full border-2 border-neutral transition-colors checked:border-ocean checked:!accent-ocean enabled:hover:border-ocean'
        />
      </div>
    </label>
  );
};

export const CheckboxFilter: React.FC<CheckboxFilterProperties> = ({
  items,
  selectedItems,
  setSelectedItems,
  onApply,
  headerLabel,
}) => {
  const [open, isOpen] = useState<boolean>(false);
  const [temporaryIds, setTemporaryIds] = useState<string[]>(selectedItems);
  const clickOutsideReference = useRef<HTMLDivElement>(null);

  const onClose = useCallback(() => {
    setTemporaryIds(selectedItems);
    isOpen(false);
  }, [selectedItems]);

  const onApplyClick = () => {
    setSelectedItems(temporaryIds);
    isOpen(false);
    onApply(temporaryIds);
  };

  const onToggleOpen = () => (open ? onClose() : isOpen(true));

  const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>, itemId: string): void => {
    const { checked } = event.target;

    if (!checked) {
      setTemporaryIds(temporaryIds.filter((id) => id !== itemId));
      return;
    }

    setTemporaryIds([...temporaryIds, itemId]);
    return;
  };

  const onClear = () => setTemporaryIds([]);
  useClickAway(clickOutsideReference, onClose);

  return (
    <div ref={clickOutsideReference}>
      <div className='relative h-full'>
        <button
          onClick={onToggleOpen}
          className='align-center bg-light-gray-lighter border-light-gray-light flex h-full min-h-[40px] cursor-pointer select-none flex-row justify-center space-x-2 rounded-lg border-[1px] px-5 text-ocean-dark-1 hover:border-ocean-dark-1'
        >
          <Filter />
          <Text className='max-w-[250px] truncate'>{headerLabel}</Text>
          {open ? (
            <div className={clsx('transition-all', { 'rotate-180': open })}>
              <ChevronDown />
            </div>
          ) : (
            <div className='transition-all'>
              <ChevronDown />
            </div>
          )}
        </button>
        {open && (
          <div className='align-start absolute top-full z-10 mt-1 flex w-fit max-w-max flex-col space-y-3 border border-neutral-weak bg-white px-4 pt-6 pb-4 rounded-lg shadow-md'>
            <div className='flex max-h-60 flex-col space-y-3 overflow-auto px-4 hover:overflow-scroll pb-3'>
              {items?.map((item, index) => (
                <CheckboxFilterItem
                  key={`${index}-${item.name}`}
                  onChange={(event) => onCheckboxChange(event, item.name)}
                  checked={temporaryIds.includes(item.name)}
                  labelText={item.title}
                />
              ))}
            </div>
            <div className='flex h-full w-full flex-row justify-end gap-6'>
              <button
                onClick={onClear}
                className='border-none pl-4 text-ocean-dark-1 outline-none transition-colors hover:text-ocean'
              >
                <Text>Clear</Text>
              </button>
              <button
                onClick={onApplyClick}
                className='align-center flex flex-row space-x-2 rounded-lg bg-ocean px-4 py-2 text-white transition-colors hover:bg-ocean-dark-1'
              >
                <Text>Apply</Text>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
