import { Avatar, Col } from 'antd';
import React from 'react';
import { Text } from '@good/components';

type TmMgmtInvitedByColumnParams = {
  invitedByAttachmentUrl: string;
  invitedByFirstName: string;
  invitedByLastName: string;
};

export const TmMgmtInvitedByColumn = ({
  invitedByAttachmentUrl,
  invitedByFirstName,
  invitedByLastName,
}: TmMgmtInvitedByColumnParams) => (
  <Col span={4}>
    <div className='align-center flex-row'>
      <Avatar size='default' className='mr-medium' shape='square' icon='user' src={invitedByAttachmentUrl} />
      <Text>{`${invitedByFirstName} ${invitedByLastName}`}</Text>
    </div>
  </Col>
);
