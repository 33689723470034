import { AlertCircle, AlertTriangle, CheckCircle, Info } from '@good/icons';
import { forwardRef } from 'react';

import * as styles from './status-message.css';
import { Text } from '../text';
import { twMerge } from '../utils';

import type { StatusMessageVariants } from './status-message.css';
import type { TextProps } from '../text';

export type StatusMessageProps = Omit<TextProps, 'asChild' | 'size'> & StatusMessageVariants;

export const StatusMessage = forwardRef<HTMLElement, StatusMessageProps>(function StatusMessage(props, ref) {
  const { tone, children, className, ...otherProps } = props;

  const IconElement = {
    critical: AlertCircle,
    info: Info,
    neutral: null,
    notice: AlertTriangle,
    positive: CheckCircle,
  }[tone];

  return (
    <Text {...otherProps} className={twMerge(styles.statusMessage({ tone }), className)} ref={ref}>
      {IconElement && <IconElement className={styles.icon()} />}
      {children}
    </Text>
  );
});
