import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Avatar, Col, Empty, Icon, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { ActionMenu, ActionMenuDivider, ActionMenuItem } from 'common-components/action-menu';
import InfoPanel from 'common-components/alerts/InfoPanel';
import { HyperlinkButton, IconButton, PrimaryButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { ICustomer, IGuardiansInfo } from 'interfaces/customer-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IRootState, state } from 'src/stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';
import AddLegalGuardianWizardModal from './components/AddLegalGuardianWizardModal';
import GuardianConnectionStatusTag from './components/GuardianConnectionStatusTag';
import GuardianEditRelationshipModal from './components/GuardianEditRelationshipModal';
import InviteReinviteCustomerModal from './components/InviteReinviteCustomerModal';
import RemoveGuardianModal from './components/RemoveGuardianModal';
import RevokeInvitationModal from './components/RevokeInvitationModal';

const GuardianEmptyPanel = () => (
  <div className="flex-1 bg-white align-center flex-column">
    <div className="mb-small">
      <Empty className="m-none" description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No legal guardians
    </Text>
    <Text color="secondary">This customer has no legal guardians</Text>
  </div>
);
interface ICustomerDetailsLinkedAccountsPanelProps {
  selectedCustomer: ICustomer;
  history: any;
  // hasEditPermission: boolean;
  portalUser: typeof state.authStore.portalUser;
}

interface ICustomerDetailsLinkedAccountsPanelState {
  isInviteReinviteModalOpen: boolean;
  isInviteReinviteModalTypeReinvite: boolean;
  isRevokeModalOpen: boolean;
  openAddLegalGuardianWizardModal: boolean;
  isGuardianModal: boolean;
  selectedGuardian: IGuardiansInfo;
  isRemoveGuardianModalOpen: boolean;
  isEditRelationshipModalOpen: boolean;
  selectedGuardianId: string;
}

class CustomerDetailsLinkedAccountsPanel extends Component<
  ICustomerDetailsLinkedAccountsPanelProps,
  ICustomerDetailsLinkedAccountsPanelState
> {
  state = {
    isInviteReinviteModalOpen: false,
    isInviteReinviteModalTypeReinvite: false,
    isRevokeModalOpen: false,
    openAddLegalGuardianWizardModal: false,
    isGuardianModal: false,
    selectedGuardian: null,
    isRemoveGuardianModalOpen: false,
    isEditRelationshipModalOpen: false,
    selectedGuardianId: null,
  };

  private _resetState() {
    this.setState({
      isInviteReinviteModalTypeReinvite: false,
      isGuardianModal: false,
      selectedGuardian: null,
      selectedGuardianId: null,
    });
  }

  private _renderBanner() {
    const { connected, guardiansInfo } = this.props.selectedCustomer;
    switch (connected) {
      case 'NOT_CONNECTED':
        return !_.isEmpty(guardiansInfo) ? (
          <InfoPanel
            // text={
            //   <div>
            //     This customer has a <span className={'text-weight-bolder'}>legal guardian</span> that manages their
            //     care.You can invite your customer’s legal guardian to the{' '}
            //     <span className={'text-weight-bolder'}> GoodHuman customer app </span> to help manage their account.
            //   </div>
            // }
            text={
              <div>
                The <span className={'text-weight-bolder'}> GoodHuman customer app </span> isn’t ready just yet. We’re
                working on the best experience for your customers. Hold tight, you’ll be able to invite them here soon.
              </div>
            }
          />
        ) : (
          // <InfoPanel
          //   hasLink={true}
          //   linkText="Invite to app"
          //   linkAction={this._openInviteReinviteModal}
          //   text={
          //     <div className={'mb-medium'}>
          //       This customer <span className={'text-weight-bolder'}>does not have access </span> to the GoodHuman app.
          //       If you would like to invite them please press the button below
          //     </div>
          //   }
          // />
          <InfoPanel
            text={
              <div>
                The <span className={'text-weight-bolder'}> GoodHuman customer app </span> isn’t ready just yet. We’re
                working on the best experience for your customers. Hold tight, you’ll be able to invite them here soon.
              </div>
            }
          />
        );
      case 'PENDING':
        return (
          // <InviteSentBanner
          //   onModify={() => this._openInviteReinviteModal('RE-INVITE')}
          //   onRevoke={this._openRevokeModal}
          //   content={
          //     <div>
          //       An invite has been sent to <span className={'text-weight-bolder'}>{emailDisplay}</span> inviting this
          //       customer to the GoodHuman app. The invite is awaiting the customers response
          //     </div>
          //   }
          // />
          <InfoPanel
            text={
              <div>
                The <span className={'text-weight-bolder'}> GoodHuman customer app </span> isn’t ready just yet. We’re
                working on the best experience for your customers. Hold tight, you’ll be able to invite them here soon.
              </div>
            }
          />
        );
      case 'CONNECTED':
        return (
          <InfoPanel
            text={
              <div>
                The <span className={'text-weight-bolder'}> GoodHuman customer app </span> isn’t ready just yet. We’re
                working on the best experience for your customers. Hold tight, you’ll be able to invite them here soon.
              </div>
            }
          />
        );
      //<SuccessBanner content={'This customer has access to the GoodHuman app'} />;

      default:
        return <div></div>;
    }
  }

  private _canNotAddLegalGuardian = () => {
    const { connected } = this.props.selectedCustomer;
    if (connected === 'CONNECTED') return true;
    else if (connected === 'PENDING') return true;
    else return false;
  };

  private _goToCustomer = async (userId) => {
    const { history } = this.props;
    history.push(`/customer/details/${userId}`);
  };

  private _closeInviteReinviteModal = () => {
    this.setState({ isInviteReinviteModalOpen: false });
    this._resetState();
  };

  private _openInviteReinviteModal = (actionType?: string) => {
    this.setState({ isInviteReinviteModalOpen: true });
    actionType === 'RE-INVITE' && this.setState({ isInviteReinviteModalTypeReinvite: true });
  };

  private _closeRevokeModal = () => {
    this.setState({ isRevokeModalOpen: false });
    this._resetState();
  };

  private _openRevokeModal = () => {
    this.setState({ isRevokeModalOpen: true });
  };

  private _openAddLegalGuardianWizardModal = () => {
    this.setState({ openAddLegalGuardianWizardModal: true });
  };

  private _closeAddLegalGuardianWizardModal = () => {
    this.setState({ openAddLegalGuardianWizardModal: false });
    this._resetState();
  };

  private _openRevokeGuardianModal = (guardian) => {
    this.setState({ selectedGuardian: guardian });
    this.setState({ isGuardianModal: true });
    this._openRevokeModal();
  };

  private _inviteGuardianToApp = (guardian?) => {
    this.setState({ isGuardianModal: true, selectedGuardian: guardian });
    this._openInviteReinviteModal();
  };

  private _modifyGuardianInvitation = (guardian) => {
    this.setState({ selectedGuardian: guardian, isGuardianModal: true });
    this._openInviteReinviteModal('RE-INVITE');
  };

  private _openRemoveGuardianModal = (guardian) => {
    this.setState({ isRemoveGuardianModalOpen: true });
    this.setState({ selectedGuardian: guardian });
  };

  private _closeRemoveGuardianModal = () => {
    this.setState({ isRemoveGuardianModalOpen: false });
    this._resetState();
  };

  private _openEditRelationshipModal = (guardianUserId: string) => {
    this.setState({ selectedGuardianId: guardianUserId });
    this.setState({ isEditRelationshipModalOpen: true });
  };

  private _closeEditRelationshipModal = () => {
    this.setState({ isEditRelationshipModalOpen: false });
    this._resetState();
  };

  render() {
    const { selectedCustomer, portalUser } = this.props;
    const { selectedGuardian, openAddLegalGuardianWizardModal } = this.state;

    return (
      <>
        <InviteReinviteCustomerModal
          isInviteReinviteModalOpen={this.state.isInviteReinviteModalOpen}
          closeInviteReinviteModal={this._closeInviteReinviteModal}
          selectedCustomer={selectedCustomer}
          selectedGuardian={selectedGuardian}
          actionType={this.state.isInviteReinviteModalTypeReinvite ? 'RE-INVITE' : null}
          isGuardianModal={this.state.isGuardianModal}
        />

        <AddLegalGuardianWizardModal
          isOpen={openAddLegalGuardianWizardModal}
          closeAddLegalGuardianWizardModal={this._closeAddLegalGuardianWizardModal}
          selectedCustomer={selectedCustomer}
        />

        <RevokeInvitationModal
          isRevokeModalOpen={this.state.isRevokeModalOpen}
          closeRevokeModal={this._closeRevokeModal}
          selectedCustomer={selectedCustomer}
          isGuardianModal={this.state.isGuardianModal}
          selectedGuardian={selectedGuardian}
        />

        <RemoveGuardianModal
          isRemoveGuardianModalOpen={this.state.isRemoveGuardianModalOpen}
          closeRemoveGuardianModal={this._closeRemoveGuardianModal}
          selectedGuardian={selectedGuardian}
          selectedCustomer={selectedCustomer}
        />

        <GuardianEditRelationshipModal
          isEditRelationshipModalOpen={this.state.isEditRelationshipModalOpen}
          closeEditRelationshipModal={this._closeEditRelationshipModal}
          selectedGuardianId={this.state.selectedGuardianId}
          selectedCustomer={selectedCustomer}
        />

        <Row type={'flex'} justify={'space-between'} align={'bottom'} className="mb-x-large">
          <Col>
            <Title level={2} className={'m-none'}>
              Linked accounts + App
            </Title>
            <Text type={'secondary'}>
              Manage the linked accounts for this customer and their access to the GoodHuman app.
            </Text>
          </Col>
        </Row>

        <Row type={'flex'} justify={'space-between'} align={'bottom'} className="mb-12">
          <Col>
            <Title level={3} className={'m-none'}>
              Customer app access
            </Title>
            <Text type={'secondary'}>Manage the customers access to the GoodHuman app.</Text>
          </Col>
        </Row>

        <Row type={'flex'} justify={'center'} align={'middle'} className="mb-x3-large">
          <Col span={24}>{this._renderBanner()}</Col>
        </Row>

        <Row type={'flex'} justify={'space-between'} align={'bottom'} className="mb-x-large">
          <Col>
            <Title level={2} className={'m-none'}>
              Legal Guardians
            </Title>
            <Text type={'secondary'}>Manage the customers Legal Guardians and their access to the GoodHuman app.</Text>
          </Col>
          <Col className={'align-center'}>
            <div className={'flex align-center'}>
              <PrimaryButton
                size="large"
                onClick={this._openAddLegalGuardianWizardModal}
                disabled={this._canNotAddLegalGuardian()}
              >
                + Add a legal guardian
              </PrimaryButton>
              {this._canNotAddLegalGuardian() && (
                <Tooltip2
                  content="Legal guardians cannot be invited as the customer has been invited to the app"
                  position="top-right"
                >
                  <Icon type="question-circle" className="text-size-x2-large ml-12" />
                </Tooltip2>
              )}
            </div>
          </Col>
        </Row>

        <>
          {!_.isEmpty(selectedCustomer.guardiansInfo) ? (
            <div className="mb-x2-large ">
              <Row className="pv-small">
                <Col span={8}>
                  <SubTitle>Legal Guardians</SubTitle>
                </Col>
                <Col span={8}>
                  <SubTitle>STATUS</SubTitle>
                </Col>
                <Col span={4}>
                  <SubTitle>RELATIONSHIP</SubTitle>
                </Col>
                <Col span={4} />
              </Row>
              {_.map(selectedCustomer.guardiansInfo, (guardian) => (
                <Row className="bordered-top pv-medium">
                  <Col span={8}>
                    <Avatar className="mr-medium" size="large" icon="user" src={guardian.attachmentUrl} />
                    {PermissionUtils.validatePermission(
                      'ViewCustomerProfile',
                      portalUser.permissions.permissionRoles,
                    ) ? (
                      <HyperlinkButton onClick={() => this._goToCustomer(guardian.guardianUserId)}>
                        {guardian.firstName} {guardian.lastName}
                      </HyperlinkButton>
                    ) : (
                      guardian.firstName + ' ' + guardian.lastName
                    )}
                  </Col>
                  <Col span={8}>
                    <GuardianConnectionStatusTag guardianStatus={guardian.connected} />
                  </Col>
                  <Col span={4}>
                    <Text size="large" color="dark" className="text-capitalized">
                      {guardian.relationToDependent}
                    </Text>
                  </Col>
                  <Col span={4} className="text-align-right pr-small">
                    <Popover2
                      position="bottom-right"
                      content={
                        <ActionMenu>
                          {/* //todo: re-open comments when invitation back */}
                          {/* {guardian.connected === 'NOT_CONNECTED' && (
                            <ActionMenuItem
                              text={'Invite to app'}
                              onClick={() => this._inviteGuardianToApp(guardian)}
                            />
                          )} */}
                          {guardian.connected === 'PENDING' && (
                            <>
                              <ActionMenuItem
                                text={'Modify Invitation'}
                                onClick={() => this._modifyGuardianInvitation(guardian)}
                              />
                              <ActionMenuItem
                                text={'Revoke Invitation'}
                                onClick={() => this._openRevokeGuardianModal(guardian)}
                              />
                              <ActionMenuDivider />
                            </>
                          )}
                          <ActionMenuItem
                            text={'View profile'}
                            onClick={() => this._goToCustomer(guardian.guardianUserId)}
                          />
                          <ActionMenuItem
                            text={'Edit Relationship'}
                            onClick={() => this._openEditRelationshipModal(guardian.guardianUserId)}
                          />
                          <ActionMenuDivider />
                          <ActionMenuItem
                            text={'Remove legal guardian'}
                            className="text-color-red"
                            onClick={() => this._openRemoveGuardianModal(guardian)}
                          />
                        </ActionMenu>
                      }
                    >
                      <IconButton
                        icon="ellipsis"
                        size="small"
                        className="bg-white"
                        bordered={true}
                        iconColor="secondary"
                        onClick={() => {}}
                      />
                    </Popover2>
                  </Col>
                </Row>
              ))}
            </div>
          ) : (
            <div className="mb-x10-large mt-x4-large">
              <GuardianEmptyPanel />
            </div>
          )}
        </>
      </>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

export default connect(mapStateToProps, null)(CustomerDetailsLinkedAccountsPanel);
