import { Box, Text } from '@goodhuman-me/components';
import { Tooltip } from 'design-components';
import { Placement } from 'design-components/utils/overlay';
import React, { ReactNode } from 'react';

interface ITooltipInfoProps {
  isShow?: boolean;
  message?: ReactNode;
  content: ReactNode;
  placement?: Placement;
}

const TooltipInfo: React.FC<ITooltipInfoProps> = ({ isShow, message, content, placement = 'top right' }) => {
  if (!isShow) return null;

  return (
    <Tooltip
      delay={0}
      placement={placement}
      content={
        <Box minWidth="max-content" backgroundColor="$infoPressed" padding="$space75 $space100" borderRadius="$small">
          <Text color="white" fontWeight="$normal" fontSize="$font50 !important">
            {message}
          </Text>
        </Box>
      }
    >
      <div style={{ cursor: 'pointer' }}>{content}</div>
    </Tooltip>
  );
};

export default TooltipInfo;
