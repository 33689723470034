import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Bookmarks = forwardRef(function Bookmarks(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1.75c.953 0 1.357.002 1.685.045a5.25 5.25 0 0 1 4.52 4.52c.044.329.045.732.045 1.685v11a.75.75 0 0 0 1.5 0V7.93c0-.862 0-1.372-.058-1.811a6.75 6.75 0 0 0-5.81-5.811C15.441.25 14.931.25 14.07.25H10a3.75 3.75 0 0 0-3.705 3.166c-.764.137-1.416.402-1.947.932-.601.602-.86 1.36-.981 2.26-.117.867-.117 1.97-.117 3.337V22a.75.75 0 0 0 1.122.651l5.016-2.866c.497-.284.823-.47 1.091-.59.253-.113.398-.142.521-.142s.269.03.521.142c.268.12.595.306 1.092.59l5.015 2.866A.75.75 0 0 0 18.75 22V9.945c0-1.367 0-2.47-.116-3.337-.121-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117H9.945c-.775 0-1.464 0-2.074.021A2.251 2.251 0 0 1 10 1.75h4ZM5.41 5.409c.276-.277.664-.457 1.398-.556.756-.101 1.757-.103 3.192-.103h2c1.435 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4.102.754.103 1.756.103 3.191v10.708l-3.893-2.225-.03-.017c-.46-.263-.85-.486-1.192-.64-.365-.163-.727-.273-1.135-.273-.408 0-.77.11-1.135.274-.342.153-.733.376-1.192.639l-.03.017-3.893 2.225V10c0-1.435.002-2.437.103-3.192.099-.734.28-1.122.556-1.399Z"
        fill="currentColor"
      />
    </Icon>
  );
});
