import React, { Component } from 'react';
import { ServDetailsSectionHeader } from '../common/ServDetailsSectionHeader';
import { ServDetailsContentRow } from '../common/ServDetailsContentRow';
import { Text } from 'common-components/typography';
import { Button, Checkbox, Form, InputNumber, notification, Select } from 'antd';
import _ from 'lodash';
import { dispatch, state } from 'stores/rematch/root-store';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import CommonUtils from 'utilities/common-utils';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/es/form';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import NumberInput from 'common-components/inputs/NumberInput';

const { Option } = Select;

const servicePriceFrequenciesLabel = [
  { tag: 'PEREACH', label: 'flat fee' },
  { tag: 'PERHOUR', label: 'per hour' },
  { tag: 'PERDAY', label: 'per day' },
];

interface IServicePricingTab extends FormComponentProps {
  selectedService?: typeof state.servicesStore.selectedService;
  doUpdateServicePricing?: typeof dispatch.servicesStore.doUpdateServicePricing;
  hasEditPermission: boolean;
}

class ServicePricingTab extends Component<IServicePricingTab> {
  state = {
    isEdit: false,
    isOpen: false,
    isLoading: false,
    price: null,
    priceFrequency: null,
    isProceedModalOpen: false,
    isWarningTravelPriceBefore: false,
    isWarningTravelPriceDuring: false,
  };

  private _formatData = async () => {
    const { selectedService } = this.props;

    this.setState({
      price: selectedService.price,
      priceFrequency: selectedService.priceFrequency,
    });
  };

  componentDidMount() {
    this._formatData();
  }

  private _changeView = () => {
    this.setState({ isEdit: !this.state.isEdit, isProceedModalOpen: false });
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedModalOpen: false });
  };

  private _discardChanges = () => {
    const { selectedService } = this.props;

    const hasUnsavedData =
      Number(this.state.price) !== Number(selectedService.price) ||
      this.state.priceFrequency !== selectedService.priceFrequency;

    if (hasUnsavedData) {
      this.setState({ isProceedModalOpen: true });
    } else {
      this._changeView();
    }
  };

  private _onChangeTravelPriceBefore(event) {
    if (event > 0 && event > 0.85) {
      this.setState({ isWarningTravelPriceBefore: true });
    } else {
      this.setState({ isWarningTravelPriceBefore: false });
    }
  }

  private _onChangeTravelPriceDuring(event) {
    if (event > 0 && event > 0.85) {
      this.setState({ isWarningTravelPriceDuring: true });
    } else {
      this.setState({ isWarningTravelPriceDuring: false });
    }
  }

  private _onSubmitChange = async () => {
    const { doUpdateServicePricing, form } = this.props;

    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      this.setState({ isLoading: true });

      const payload = {
        price: _.toString(this.state.price) ? +this.state.price : null,
        priceFrequency: _.toString(this.state.price) ? this.state.priceFrequency : null,
      };
      try {
        await doUpdateServicePricing(payload);
        notification.success({
          message: 'Price updated successfully',
        });
        this.setState({ isLoading: false, isEdit: false });
      } catch (e) {
        notification.error({
          message: 'Update failed',
          description: 'Please try again.',
        });
        this.setState({ isLoading: false });
        throw e;
      }
    }
  };

  private _handleProceedDiscardChange = () => {
    const { selectedService } = this.props;

    this.setState({
      isEdit: false,
      isProceedModalOpen: false,
      price: Number(selectedService.price),
      priceFrequency: selectedService.priceFrequency,
    });
  };

  render() {
    const { selectedService, form } = this.props;
    const { isEdit } = this.state;
    const { getFieldDecorator } = form;

    const frequency = _.find(
      servicePriceFrequenciesLabel,
      (frequency) => frequency.tag === selectedService.priceFrequency,
    );

    if (!isEdit) {
      return (
        <div className="mt-small">
          <ServDetailsSectionHeader
            title={'Pricing'}
            subtitle={'Pricing settings for this service.'}
            hasEditButton={this.props.hasEditPermission}
            editAction={this._changeView}
          />

          <ServDetailsContentRow
            label={'Approximate Price'}
            subLabel={'Please indicate the approximate cost per unit of time for this service.'}
            leftColSpan={8}
            rightColSpan={16}
          >
            {_.toString(selectedService.price) ? (
              <Text>
                {CommonUtils.formatPrice(Number(selectedService.price))}{' '}
                {!!frequency ? frequency.label : selectedService.priceFrequency}
              </Text>
            ) : (
              <Text>Not set</Text>
            )}
          </ServDetailsContentRow>
        </div>
      );
    } else {
      return (
        <div className="mt-small">
          <ActionModal
            isOpen={this.state.isProceedModalOpen}
            onClose={this._closeProceedModal}
            title={'Discard changes'}
            showCloseButton={true}
          >
            <Text className={'mb-medium'}>
              You have <b>unsaved data</b>, proceeding will discard these changes.
            </Text>
            <br />
            <Text className={'mb-medium'}>Do you want to proceed?</Text>
            <ActionModalFooter>
              <PrimaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
                Cancel
              </PrimaryButton>
              <GhostButton size="large" onClick={this._handleProceedDiscardChange}>
                Proceed
              </GhostButton>
            </ActionModalFooter>
          </ActionModal>
          <ServDetailsSectionHeader title={'Pricing'} subtitle={'Pricing settings for this service.'} />

          <ServDetailsContentRow
            label={'Approximate price'}
            subLabel={'Please indicate the approximate cost per unit of time for this service.'}
            leftColSpan={8}
            rightColSpan={16}
          >
            <Form.Item className={'m-none'}>
              ${' '}
              {getFieldDecorator('serviceApproxPrice', {
                initialValue: this.state.price,
              })(
                <InputNumber
                  precision={2}
                  min={0}
                  max={100000}
                  onChange={(value) => this.setState({ price: value })}
                />,
              )}
              {getFieldDecorator('servicePriceFrequency', {
                initialValue: this.state.priceFrequency,
                rules: [
                  {
                    required: !!_.toString(this.state.price),
                    message: 'Please enter a frequency.',
                  },
                ],
              })(
                <Select
                  style={{ width: '150px' }}
                  className="ml-small"
                  // value={this.state.priceFrequency}
                  onChange={(value) => this.setState({ priceFrequency: value })}
                >
                  <Option key={1} value="PEREACH">
                    Flat Fee
                  </Option>
                  <Option key={2} value="PERHOUR">
                    Hourly
                  </Option>
                </Select>,
              )}
            </Form.Item>
          </ServDetailsContentRow>

          {/*<ServDetailsContentRow*/}
          {/*  label={'Pricing Currency'}*/}
          {/*  subLabel={'What national currency do you want to charge for this service?'}*/}
          {/*  leftColSpan={8}*/}
          {/*  rightColSpan={16}*/}
          {/*>*/}
          {/*  {selectedService.currency}*/}
          {/*</ServDetailsContentRow>*/}

          {/*/!* Billing Frequency *!/*/}
          {/*<ServDetailsContentRow*/}
          {/*  label={'Billing frequency'}*/}
          {/*  subLabel={'How often do would you like to bill the customer for this service?'}*/}
          {/*  leftColSpan={8}*/}
          {/*  rightColSpan={16}*/}
          {/*>*/}
          {/*  <span className="mr-small">Billed to the customer every</span>*/}

          {/*  <Select style={{ width: '100px' }} value="perhour">*/}
          {/*    <Select.Option value={'perhour'}>Per Hour</Select.Option>*/}
          {/*    <Select.Option value={'perday'}>Per Day</Select.Option>*/}
          {/*    <Select.Option value={'perweek'}>Per Week</Select.Option>*/}
          {/*    <Select.Option value={'pervisit'}>Per Visit</Select.Option>*/}
          {/*    <Select.Option value={'pervisit'}>Custom</Select.Option>*/}
          {/*  </Select>*/}
          {/*</ServDetailsContentRow>*/}

          {/*<ServDetailsContentRow*/}
          {/*  label={'NDIS Eligibility'}*/}
          {/*  leftColSpan={8}*/}
          {/*  rightColSpan={16}*/}
          {/*  subLabel={'Is this service an NDIS funding eligible service?'}*/}
          {/*>*/}
          {/*  This service is eligible for NDIS claims.*/}
          {/*  <span className="ml-medium">*/}
          {/*    <Switch*/}
          {/*      checkedChildren={<Icon type="check" />}*/}
          {/*      unCheckedChildren={<Icon type="close" />}*/}
          {/*      defaultChecked*/}
          {/*    />*/}
          {/*  </span>*/}
          {/*</ServDetailsContentRow>*/}

          <ServDetailsContentRow align={'right'} leftColSpan={8} rightColSpan={16}>
            <Button.Group>
              <GhostButton onClick={this._discardChanges}>Discard Changes</GhostButton>

              <PrimaryButton loading={this.state.isLoading} icon={'save'} onClick={this._onSubmitChange}>
                Save
              </PrimaryButton>
            </Button.Group>
          </ServDetailsContentRow>
        </div>
      );
    }
  }
}

export default connect()(Form.create<IServicePricingTab>()(ServicePricingTab));
