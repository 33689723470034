import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { Checkbox, Form, Icon, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Information } from 'common-components/alerts';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IArchiveServiceModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  selectedService: any;
  doArchiveService: typeof dispatch.servicesStore.doArchiveService;
}

interface IArchiveServiceModalState {
  title: any;
  isArchived: boolean;
}

class ArchiveServiceModal extends Component<IArchiveServiceModalProps, IArchiveServiceModalState> {
  state = { title: 'Archive service', isArchived: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Archive service', isArchived: false });
    this.props.onClose();
  };

  private _archiveService = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        await this.props.doArchiveService({
          serviceId: this.props.selectedService.serviceId,
        });
        this.setState({
          title: (
            <>
              Service archived successfully
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isArchived: true,
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  private _validateAcknowledge = (rule, value, callback) => {
    try {
      if (!value) {
        throw Error('In order to continue you must acknowledge this statement.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  render() {
    const { isOpen, selectedService, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isArchived ? (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <SubTitle>Service name</SubTitle>
                <Title level={4}>{selectedService.serviceName}</Title>
                <Paragraph>This service has been archived.</Paragraph>
              </div>

              <ActionModalFooter>
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  Done
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        ) : selectedService && selectedService.hasFutureBookings ? (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <Information
                  content={
                    <>
                      <Paragraph weight={'bold'}>You cannot archive a service which has upcoming bookings.</Paragraph>
                      <Paragraph>
                        In order to archive this service please cancel or complete all upcoming bookings. Upcoming
                        bookings are bookings with the status Requested, Accepted or Confirmed.
                      </Paragraph>
                    </>
                  }
                />
              </div>

              <ActionModalFooter>
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  Ok
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        ) : (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <Text>
                  You are choosing to archive <b>{selectedService ? selectedService.serviceName : ''}</b>.
                </Text>

                <Form.Item className="width-full">
                  {getFieldDecorator('acknowledgment', { rules: [{ validator: this._validateAcknowledge }] })(
                    <Checkbox>
                      I understand that archiving a service means bookings can no longer be created for it.
                    </Checkbox>,
                  )}
                </Form.Item>
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._archiveService}>
                  Archive service
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doArchiveService: dispatch.servicesStore.doArchiveService,
});

export default connect(null, mapDispatch)(Form.create<IArchiveServiceModalProps>()(ArchiveServiceModal));
