import { Col, DatePicker, Icon, Input, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import TimeInput from 'common-components/time-input/TimeInput';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { timeZone } from 'interfaces/timezone-type';
import CommonUtils from 'utilities/common-utils';
import { BookingStatus, DisturbanceErrorType, SleepoverType } from 'utilities/enum-utils';
import { IDisturbance } from 'src/interfaces/booking-interfaces';

interface IAddEditDisturbanceModalProps {
  onClose: () => void;
  isOpen: boolean;
  onSave: (payload: {
    startDateTime: string;
    endDateTime: string;
    comment: string;
    bookingId: string;
    disturbanceId: string;
  }) => void;
  bookingSelectedItem: any;
  disturbanceSelected: IDisturbance;
  isLoading: boolean;
  displayTimezone: timeZone;
}

function AddEditDisturbanceModal({
  onClose,
  isOpen,
  onSave,
  bookingSelectedItem,
  disturbanceSelected,
  isLoading,
  displayTimezone,
}: IAddEditDisturbanceModalProps) {
  const [disturbance, _setDisturbance] = useState({
    startDateTime: null,
    endDateTime: null,
    comment: '',
    bookingTimezone: displayTimezone ? displayTimezone : bookingSelectedItem?.timezone,
    errorType: null,
  });

  const setDisturbance = (data) => {
    _setDisturbance({
      ...disturbance,
      ...data,
    });
  };

  useEffect(() => {
    const { sleepoverType, sleepoverTimeSlots, startDateTime, endDateTime } = bookingSelectedItem;
    const { bookingTimezone } = disturbance;
    if (disturbanceSelected) {
      const startDateDisturbance = moment.tz(disturbanceSelected.startDateTime, bookingTimezone);
      const endDateDisturbance = moment.tz(disturbanceSelected.endDateTime, bookingTimezone);
      setDisturbance({
        startDateTime: startDateDisturbance,
        endDateTime: endDateDisturbance,
        comment: disturbanceSelected.comment,
        errorType: disturbanceSelected.errorType,
      });
    } else {
      const startDate =
        sleepoverType === SleepoverType.PARTIAL
          ? moment.tz(sleepoverTimeSlots[0].startDateTime, bookingTimezone)
          : moment.tz(startDateTime, bookingTimezone);
      const endDate =
        sleepoverType === SleepoverType.PARTIAL
          ? moment.tz(sleepoverTimeSlots[0].endDateTime, bookingTimezone)
          : moment.tz(endDateTime, bookingTimezone);
      const endDateCustom = startDate.clone().add(1, 'h');
      const endDateEarlier = endDateCustom.isBefore(endDate) ? endDateCustom : endDate;
      setDisturbance({
        startDateTime: startDate,
        endDateTime: endDateEarlier,
        errorType: validateDisturbanceTime({
          startDateTime: startDate,
          endDateTime: endDateEarlier,
        }),
      });
    }
  }, []);

  const onCloseModal = () => {
    onClose();
  };
  const onSaveDisturbance = async () => {
    const payload = {
      startDateTime: disturbance.startDateTime.toDate(),
      endDateTime: disturbance.endDateTime.toDate(),
      comment: disturbance.comment,
      bookingId: bookingSelectedItem?.bookingId,
      disturbanceId: disturbanceSelected?.disturbanceId,
    };
    await onSave(payload);
    onCloseModal();
  };

  const validateDisturbanceTime = (timeSlot) => {
    const {
      status,
      portalCheckedInDateTime,
      portalCheckedOutDateTime,
      sleepoverTimeSlots,
      sleepoverType,
      workerCheckedInDateTime,
      workerCheckedOutDateTime,
      disturbances,
      startDateTime,
      endDateTime,
    } = bookingSelectedItem;
    const checkedInDateTime = portalCheckedInDateTime || workerCheckedInDateTime;
    const checkedOutDateTime = portalCheckedOutDateTime || workerCheckedOutDateTime;
    const { bookingTimezone } = disturbance;
    const sleepoverTimeSlotsFully =
      sleepoverType === SleepoverType.ENTIRE
        ? status === BookingStatus.COMPLETED
          ? [{ startDateTime: checkedInDateTime, endDateTime: checkedOutDateTime }]
          : [{ startDateTime, endDateTime }]
        : sleepoverTimeSlots;
    //check timeSlot outside checkIn checkOut time
    const isFitCheckInOutDate =
      status === BookingStatus.COMPLETED
        ? moment.tz(checkedInDateTime, bookingTimezone).isSameOrBefore(timeSlot.startDateTime) &&
          moment.tz(checkedOutDateTime, bookingTimezone).isSameOrAfter(timeSlot.endDateTime)
        : true;
    //check timeSlot outside sleepover time
    const isFitSleepOverTimeSlot = sleepoverTimeSlotsFully?.length
      ? _.some(
          sleepoverTimeSlotsFully,
          (slot) =>
            moment.tz(slot.startDateTime, bookingTimezone).isSameOrBefore(timeSlot.startDateTime) &&
            moment.tz(slot.endDateTime, bookingTimezone).isSameOrAfter(timeSlot.endDateTime),
        )
      : true;
    //check overlap disturbances
    const isValidOverLap = disturbances?.length
      ? _.every(
          disturbances,
          (slot) =>
            moment.tz(slot.endDateTime, bookingTimezone).isBefore(moment.tz(timeSlot.startDateTime, bookingTimezone)) ||
            moment.tz(slot.startDateTime, bookingTimezone).isAfter(moment.tz(timeSlot.endDateTime, bookingTimezone)) ||
            disturbanceSelected?.disturbanceId === slot.disturbanceId,
        )
      : true;
    //set errorType
    let errorType = null;
    if (!isValidOverLap) {
      errorType = DisturbanceErrorType.OVERLAP;
    }
    if (!isFitSleepOverTimeSlot) {
      errorType = DisturbanceErrorType.OUT_OF_SLEEPOVER_TIME;
    }
    if (!isFitCheckInOutDate) {
      errorType = DisturbanceErrorType.OUT_OF_CHECKIN_CHECKOUT_TIME;
    }
    return errorType;
  };

  const onChangeDateTime = (value, fieldName: 'startDateTime' | 'endDateTime') => {
    const { bookingTimezone, startDateTime, endDateTime } = disturbance;

    const roundedDate = CommonUtils.formatCeilingDateTime(value);
    const timeSlot = {
      startDateTime: fieldName === 'startDateTime' ? roundedDate : startDateTime,
      endDateTime: fieldName === 'endDateTime' ? roundedDate : endDateTime,
    };
    if (
      moment.tz(timeSlot.startDateTime, bookingTimezone).isSameOrAfter(moment.tz(timeSlot.endDateTime, bookingTimezone))
    ) {
      timeSlot.endDateTime = moment.tz(timeSlot.startDateTime, bookingTimezone).add(1, 'hour');
    }
    const errorType = validateDisturbanceTime(timeSlot);
    setDisturbance({ ...timeSlot, errorType });
  };

  const onEnterComment = (event) => {
    setDisturbance({
      comment: event.target.value,
    });
  };

  const renderErrorMessage = (errorType) => {
    const { portalCheckedInDateTime, portalCheckedOutDateTime, workerCheckedInDateTime, workerCheckedOutDateTime } =
      bookingSelectedItem;
    const checkedInDateTime = portalCheckedInDateTime || workerCheckedInDateTime;
    const checkedOutDateTime = portalCheckedOutDateTime || workerCheckedOutDateTime;
    switch (errorType) {
      case DisturbanceErrorType.OUT_OF_SLEEPOVER_TIME:
        return 'Disturbance must occur within the allocated sleepover period for this booking';
      case DisturbanceErrorType.OUT_OF_CHECKIN_CHECKOUT_TIME:
        return (
          <>
            <Text color="red">
              Disturbance must occur during the team member&apos;s start and finish time <br />
              <Text color="red" weight="bold">
                {' ('}
                {moment.tz(checkedInDateTime, disturbance.bookingTimezone).format('h:mm a, DD/MM/YYYY')}
                {' - '}
                {moment.tz(checkedOutDateTime, disturbance.bookingTimezone).format('h:mm a, DD/MM/YYYY')}
                {')'}
              </Text>
            </Text>
          </>
        );
      case DisturbanceErrorType.OVERLAP:
        return 'Disturbances cannot overlap';
      default:
        return null;
    }
  };

  const { startDateTime, endDateTime, comment, bookingTimezone, errorType } = disturbance;
  const titleModal = !disturbanceSelected ? 'Add a sleepover disturbance' : 'Edit sleepover disturbance';
  const subTitleModal = !disturbanceSelected
    ? 'Enter details of any sleep disturbances reported by the team member during this booking.'
    : 'Update details of sleep disturbances reported by the team member during this booking.';

  return (
    <div>
      <ActionModal
        isOpen={isOpen}
        title={titleModal}
        onClose={onCloseModal}
        width="x-large"
        verticalAlignment="center"
        enforceFocus={false}
      >
        <div className="anim-slide-left">
          <Paragraph>
            <Text>{subTitleModal}</Text>
          </Paragraph>
          <Row className="mb-large">
            <SubTitle>Time of disturbance</SubTitle>
            <div className="mt-small">
              <div className="flex align-center">
                <DatePicker
                  allowClear={false}
                  className={'mr-small' + (errorType ? ' error-date-picker' : '')}
                  format="DD/MM/YYYY"
                  value={moment.tz(startDateTime, bookingTimezone)}
                  onChange={(value) => onChangeDateTime(value, 'startDateTime')}
                />
                <TimeInput
                  timezone={bookingTimezone}
                  value={moment.tz(startDateTime, bookingTimezone)}
                  onChange={(value) => onChangeDateTime(value, 'startDateTime')}
                  className={errorType && 'error-date-picker'}
                />
                <span className="mh-medium">to</span>
                <DatePicker
                  allowClear={false}
                  className={'mr-small' + (errorType ? ' error-date-picker' : '')}
                  format="DD/M/YYYY"
                  value={moment.tz(endDateTime, bookingTimezone)}
                  onChange={(value) => onChangeDateTime(value, 'endDateTime')}
                />
                <TimeInput
                  className={errorType && 'error-date-picker'}
                  value={moment.tz(endDateTime, bookingTimezone)}
                  onChange={(value) => onChangeDateTime(value, 'endDateTime')}
                  timezone={bookingTimezone}
                />
              </div>
              {errorType && (
                <div className="text-color-red mt-small flex align-start">
                  <Icon
                    type="close-circle"
                    theme="filled"
                    className="text-color-red-light mr-small mt-x2-small"
                    style={{ fontSize: 20 }}
                  />
                  {renderErrorMessage(errorType)}
                </div>
              )}
            </div>
          </Row>
          <Row className="mb-large">
            <SubTitle containerClassName="line-height-100 mb-x-small">comments</SubTitle>
            <Input.TextArea
              placeholder="Include any additional info about this disturbance..."
              autoSize={{ minRows: 4, maxRows: 4 }}
              value={comment}
              onChange={onEnterComment}
            />
          </Row>
          <div className={'mb-small pt-large'}>
            <Row type={'flex'} justify={'end'}>
              <Col>
                <SecondaryButton size={'large'} onClick={onCloseModal} className="mr-medium">
                  Cancel
                </SecondaryButton>
              </Col>
              <Col>
                <PrimaryButton size={'large'} onClick={onSaveDisturbance} disabled={errorType} loading={isLoading}>
                  Save
                </PrimaryButton>
              </Col>
            </Row>
          </div>
        </div>
      </ActionModal>
    </div>
  );
}

export default AddEditDisturbanceModal;
