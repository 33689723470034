import { DeepPartial } from 'react-hook-form';

import { minutesBetween } from 'utilities/date-utils';
import { EditRatiosSchema } from '../../schema';
import { ISessionTimesParsed, DurationErrors } from '../../types';

export function getDurationErrors(
  sessionTimes: ISessionTimesParsed,
  values: DeepPartial<EditRatiosSchema>,
): DurationErrors {
  const totalDuration = minutesBetween(sessionTimes.startDateTime, sessionTimes.endDateTime);
  const selectedDuration = values.ratios?.reduce((acc, curr) => {
    if (!curr) {
      return acc;
    }

    if (curr?.isExactTime && curr.customRatio?.startDateTime && curr.customRatio?.endDateTime) {
      return acc + minutesBetween(curr.customRatio.startDateTime, curr.customRatio.endDateTime);
    }

    if (curr.durationRatio?.duration) {
      return acc + curr.durationRatio.duration * (curr.durationRatio.unit === 'minutes' ? 1 : 60);
    }

    return acc;
  }, 0);

  return {
    durationExceedsSessionLength: selectedDuration ? selectedDuration > totalDuration : false,
  };
}
