import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FolderCompressed = forwardRef(function FolderCompressed(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2.25h-.116c-.818 0-1.376 0-1.855.128a3.75 3.75 0 0 0-2.651 2.651c-.128.479-.128 1.037-.128 1.855V14.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.601 1.36.86 2.26.981.867.117 1.97.117 3.337.117h8.11c1.367 0 2.47 0 3.337-.117.9-.12 1.658-.38 2.26-.982.602-.601.86-1.36.982-2.26.116-.866.116-1.969.116-3.336V11.945c0-1.368 0-2.47-.116-3.337-.122-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.982-.36-.048-.762-.076-1.206-.093A.752.752 0 0 0 18 5.25H15.121c-.907 0-1.216-.007-1.495-.086a2.249 2.249 0 0 1-.477-.198c-.254-.141-.477-.355-1.119-.996l-.07-.072c-.544-.543-.92-.92-1.38-1.175a3.752 3.752 0 0 0-.795-.33C9.28 2.25 8.748 2.25 7.98 2.25H6Zm7.25 4.366-.035-.01a3.751 3.751 0 0 1-.796-.33c-.459-.255-.835-.631-1.378-1.175l-.071-.07c-.642-.643-.865-.856-1.119-.997a2.25 2.25 0 0 0-.477-.198c-.279-.08-.588-.086-1.495-.086H6c-.978 0-1.32.006-1.582.077a2.25 2.25 0 0 0-1.591 1.59c-.07.263-.077.605-.077 1.583v7c0 1.435.002 2.436.103 3.192.099.733.28 1.122.556 1.399.277.277.665.457 1.4.556.755.101 1.756.103 3.191.103h8c1.436 0 2.437-.002 3.192-.103.734-.099 1.122-.28 1.399-.556.277-.277.457-.666.556-1.4.102-.755.103-1.756.103-3.191v-2c0-1.435-.002-2.437-.103-3.192-.099-.734-.28-1.122-.556-1.4-.277-.276-.665-.456-1.4-.555a8.276 8.276 0 0 0-.441-.046V7a2.75 2.75 0 1 1-5.5 0v-.384Zm3.073.134c.333 0 .64 0 .927.004V7a1.25 1.25 0 1 1-2.5 0v-.25h1.573ZM16 11.25a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-1.5 0v-1a.75.75 0 0 1 .75-.75Zm.75 4.75a.75.75 0 1 0-1.5 0v1a.75.75 0 0 0 1.5 0v-1Z"
        fill="currentColor"
      />
    </Icon>
  );
});
