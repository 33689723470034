import { Empty } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';
import { FaHashtag } from 'react-icons/fa';

import _ from 'lodash';

interface IEmptyChannelProps {
  serviceName: string;
}

const NoServices = () => (
  <Text color="secondary" weight="regular" size="regular" className="select-none">
    No messages found.
  </Text>
);

const EmptyServices = ({ serviceName }) => (
  <Text color="secondary" weight="regular" size="regular" className="select-none">
    No messages found for <br />
    <FaHashtag className="text-size-small text-color-blue-dark mr-x2-small" />
    <span className="text-color-blue text-weight-bold">{serviceName}.</span>
  </Text>
);

export function EmptyChannel({ serviceName }: IEmptyChannelProps) {
  const hasServiceName = !_.isEmpty(serviceName) && serviceName.length > 0;

  return (
    <div className="ph-medium pv-medium anim-fade-in">
      <div className="text-align-center pt-x-large">
        {
          <Empty
            description={hasServiceName ? <EmptyServices serviceName={serviceName} /> : <NoServices />}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        }
      </div>
    </div>
  );
}
