import React, { Component } from 'react';
import { SubTitle } from 'common-components/typography';
import { LeftNavItem } from 'common-components/navigation/LeftNavItem';

interface IGroupServiceSessionNavigationPanelProps {
  selectedTab?: string;
  changeSelectedTab: (selectedTab) => void;
}

interface IGroupServiceSessionNavigationPanelState {}

class GroupServiceSessionNavigationPanel extends Component<
  IGroupServiceSessionNavigationPanelProps,
  IGroupServiceSessionNavigationPanelState
> {
  state = {};

  render = () => {
    const { selectedTab = 'OVERVIEW', changeSelectedTab } = this.props;

    return (
      <div className={'pb-medium pr-large'} style={{ width: '200px' }}>
        <div className="pv-small">
          <SubTitle color={'tertiary'}>SESSION DETAILS</SubTitle>
        </div>
        <div className="">
          <div>
            <LeftNavItem
              title={'Overview'}
              value={'OVERVIEW'}
              onClick={changeSelectedTab}
              isSelected={selectedTab === 'OVERVIEW'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title={'Bookings'}
              value={'CUSTOMERS'}
              onClick={changeSelectedTab}
              isSelected={selectedTab === 'CUSTOMERS'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title={'Shifts'}
              value={'TEAM-MEMBERS'}
              onClick={changeSelectedTab}
              isSelected={selectedTab === 'TEAM-MEMBERS'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title={'Activity groups'}
              value={'ACTIVITY_GROUPS'}
              onClick={changeSelectedTab}
              isSelected={selectedTab === 'ACTIVITY_GROUPS'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title={'Notes'}
              value={'NOTES'}
              onClick={changeSelectedTab}
              isSelected={selectedTab === 'NOTES'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title={'Forms'}
              value={'FORMS'}
              onClick={changeSelectedTab}
              isSelected={selectedTab === 'FORMS'}
              size={'small'}
            />
          </div>
          <div>
            <LeftNavItem
              title={'Activity log'}
              value={'ACTIVITY-LOG'}
              onClick={changeSelectedTab}
              isSelected={selectedTab === 'ACTIVITY-LOG'}
              size={'small'}
            />
          </div>
        </div>
      </div>
    );
  };
}

export default GroupServiceSessionNavigationPanel;
