import React from 'react';
import { reactTrpc } from 'utilities/react-trpc';
import { useTranslation } from 'react-i18next';
import { SideNav, TabContent } from 'views/sil-service/components/side-nav/side-nav';
import { RosterTab } from './tabs/roster-tab';
import { ManagersTab } from './tabs/managers-tab';
import { MembersTab } from './tabs/members-tab';
import { useSilServiceContext } from 'views/sil-service/components/sil-service-provider/sil-service-provider';
import { useSelector } from 'react-redux';
import { Loader2Adjustable } from '@good/icons';
import { IRootState } from 'stores/rematch/root-store';
import { useNewRelicBrowser } from 'integrations/new-relic-browser';
import { Text } from '@good/ui/core';
export const TeamTab = () => {
  const { service } = useSilServiceContext();
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceTeamTabs' });
  const newRelicBrowser = useNewRelicBrowser();

  const {
    data: teamMembers,
    isLoading,
    error,
    isFetching,
    refetch,
  } = reactTrpc.sil.roster.fetch.useQuery({
    serviceId: service.serviceId,
    serviceProviderId: portalUser?.serviceProviderId ?? '',
  });

  if (error) {
    newRelicBrowser?.noticeError(error.message);
    return (
      <div className='flex flex-col gap-2 align-center pt-8'>
        <Text>{t('error.title')}</Text>
        <Text>{t('error.message')}</Text>
      </div>
    );
  }
  if (isLoading || isFetching)
    return (
      <div className='flex flex-col content-center align-center'>
        <Loader2Adjustable className='animate-spin' />
      </div>
    );

  if (!teamMembers) return <div></div>;

  const managers = teamMembers.filter((teamMember) => teamMember.isManager);
  const members = teamMembers.filter((teamMember) => !teamMember.isManager);

  const teamMemberCount = teamMembers.length;
  const managerCount = managers.length;
  const membersCount = members.length;

  const tabs: TabContent[] = [
    {
      id: 1,
      title: `${t('Roster')}`,
      content: <RosterTab teamMembers={teamMembers} teamMemberCount={teamMemberCount} refetch={refetch} />,
      count: teamMemberCount,
    },
    {
      id: 2,
      title: `${t('Managers')}`,
      content: <ManagersTab managers={managers} managersCount={managerCount} />,
      count: managerCount,
    },
    {
      id: 3,
      title: `${t('Members')}`,
      content: <MembersTab members={members} membersCount={membersCount} />,
      count: membersCount,
    },
  ];

  return (
    <>
      <SideNav tabs={tabs} />
    </>
  );
};
