import React from 'react';

import { useInlineForm } from './inline-form-context';
import { InlineFieldInputWrapper } from './inline-field-input-wrapper';
import { StaticField } from './static-field';
import { StaticValue } from './static-value';

export type InlineStaticFieldProps = {
  label: string;
  description?: string;
  value: string | number | Date | null;
};

export const InlineStaticField = (props: InlineStaticFieldProps) => {
  const { isEditing } = useInlineForm();
  const { label, description, value } = props;

  if (isEditing) {
    return (
      <InlineFieldInputWrapper
        label={label}
        name={label}
        description={description}
        render={() => <StaticValue value={value} />}
      />
    );
  }

  return <StaticField label={label} value={value} />;
};
