// Customer Permissions
import React, { useState } from 'react';
import { PermissionRowBase } from 'views/account-management/add-new-member/panels/permissions-panel/portal/PermissionRowBase';
import { IPermissionRole, IPermissions } from 'interfaces/account-interfaces';
import { getCustomerPermissionName, getCustomerRole } from 'views/account-management/utils/permission-utils';
import { UserPermissionRole } from 'utilities/enum-utils';
import CustomerPermissionsModal from 'views/account-management/components/CustomerPermissionsModal';

interface CustomerPermissionRowProps {
  permissions: IPermissions;
  onChangePermissions: (payload: IPermissions) => void;
  actionsDisabled: boolean;
  hasAllAccess: boolean;
}

const CustomerPermissionRow = (props: CustomerPermissionRowProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);

  // Customers
  const onOpenCustomer = () => setOpen(true);
  const onCloseCustomer = () => setOpen(false);

  // Triggered whenever a selection is made.
  const onSelectCustomer = (result) => {
    const { permissions, onChangePermissions } = props;
    const { permissionRoles } = permissions;

    // filter out payment permissions first
    const filteredRoles = permissionRoles.filter(
      (role) =>
        role.permissionRole !== UserPermissionRole.CustomerAccessViewOnly &&
        role.permissionRole !== UserPermissionRole.CustomerAccessManager,
    );

    // Remap back to UserPermissionRole
    const targetRole = getCustomerRole(result.customerPermission);

    const newCustomerRole: IPermissionRole = { permissionRole: targetRole, serviceDepartmentId: null, serviceId: null };

    const updatedPermissions = { ...permissions, permissionRoles: [...filteredRoles, newCustomerRole] };

    onChangePermissions(updatedPermissions);
  };

  const { permissions } = props;
  const customerPermissionName = getCustomerPermissionName(permissions, props.hasAllAccess);

  return (
    <>
      <PermissionRowBase
        iconName="contacts"
        title="Customer"
        description="Customer profiles and data"
        currentFlag={customerPermissionName}
        onClickModify={onOpenCustomer}
        actionsDisabled={props.actionsDisabled}
      />
      <CustomerPermissionsModal
        key={`customerPermissionsModal-${isOpen}`}
        isOpen={isOpen}
        onClose={onCloseCustomer}
        permissions={permissions}
        onChangePermissions={props.onChangePermissions}
      />
    </>
  );
};

export default CustomerPermissionRow;
