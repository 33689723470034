import { useCallback, useEffect, useState } from 'react';

import { Address, AddressInputValues } from '../types';
import { emptyAddress, emptyInputValues } from '../defaults';
import { processFullAddress } from '../process-full-address';

const mergeInitialInputValues = (
  defaultAddress: Partial<Address> | undefined,
  controlledAddress: Address | null | undefined,
): AddressInputValues => ({
  automaticInput: defaultAddress?.fullAddress ?? controlledAddress?.fullAddress ?? emptyInputValues.automaticInput,
  'manualInput.streetAddress1':
    defaultAddress?.streetAddress1 ??
    controlledAddress?.streetAddress1 ??
    emptyInputValues['manualInput.streetAddress1'],
  'manualInput.streetAddress2':
    defaultAddress?.streetAddress2 ??
    controlledAddress?.streetAddress2 ??
    emptyInputValues['manualInput.streetAddress2'],
  'manualInput.locality':
    defaultAddress?.locality ?? controlledAddress?.locality ?? emptyInputValues['manualInput.locality'],
  'manualInput.state': defaultAddress?.state ?? controlledAddress?.state ?? emptyInputValues['manualInput.state'],
  'manualInput.postcode':
    defaultAddress?.postcode ?? controlledAddress?.postcode ?? emptyInputValues['manualInput.postcode'],
});

const mergeInitialAddress = (
  defaultAddress: Partial<Address> | undefined,
  controlledAddress: Address | null | undefined,
): Address => ({
  fullAddress: defaultAddress?.fullAddress ?? controlledAddress?.fullAddress ?? emptyAddress.fullAddress,
  streetAddress1: defaultAddress?.streetAddress1 ?? controlledAddress?.streetAddress1 ?? emptyAddress.streetAddress1,
  streetAddress2: defaultAddress?.streetAddress2 ?? controlledAddress?.streetAddress2 ?? emptyAddress.streetAddress2,
  locality: defaultAddress?.locality ?? controlledAddress?.locality ?? emptyAddress.locality,
  state: defaultAddress?.state ?? controlledAddress?.state ?? emptyAddress.state,
  postcode: defaultAddress?.postcode ?? controlledAddress?.postcode ?? emptyAddress.postcode,
  geoLat: defaultAddress?.geoLat ?? controlledAddress?.geoLat ?? emptyAddress.geoLat,
  geoLng: defaultAddress?.geoLng ?? controlledAddress?.geoLng ?? emptyAddress.geoLng,
});

export function useInputAndAddress(
  controlledAddress: Address | null | undefined,
  defaultAddress: Partial<Address> | undefined,
  onChangeAddressCallback: ((address: Address) => void) | undefined,
  clearInternalErrors: () => void,
) {
  const [innerInputValues, setInnerInputValues] = useState<AddressInputValues>(
    mergeInitialInputValues(defaultAddress, controlledAddress),
  );
  const [innerAddress, setInnerAddress] = useState<Address>(mergeInitialAddress(defaultAddress, controlledAddress));

  const isAddressControlled = controlledAddress !== undefined;
  const address = isAddressControlled ? controlledAddress : innerAddress;

  const resetInputValues = useCallback(
    (resetToDefault = false) => {
      if (resetToDefault) {
        setInnerInputValues(mergeInitialInputValues(defaultAddress, null));
        setInnerAddress({ ...emptyAddress, ...defaultAddress });
        return;
      }

      setInnerInputValues(emptyInputValues);
      setInnerAddress(emptyAddress);
    },
    [defaultAddress],
  );

  useEffect(() => {
    onChangeAddressCallback?.(innerAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Cannot guarantee onChangeProp will be callback or setState dispatch
  }, [innerAddress]);

  useEffect(() => {
    const isAddressControlled = controlledAddress !== undefined;

    if (isAddressControlled) {
      if (controlledAddress === null) {
        resetInputValues(true);
        clearInternalErrors();
      } else {
        syncInputValuesWithAddess(controlledAddress);
      }
    }
  }, [controlledAddress, resetInputValues, clearInternalErrors]);

  const onChangeInnerInputValue = (field: keyof AddressInputValues, innerValue: string | undefined | null) => {
    setInnerInputValues((prevState) => {
      const isManualInput = field.startsWith('manualInput.');
      const isAutomaticInput = field === 'automaticInput';

      if (isAutomaticInput && innerValue) {
        setInnerAddress((prev) => ({ ...prev, fullAddress: innerValue }));
      }

      if (isManualInput) {
        const manualAddressFieldKey = field.split('.')[1];
        if (manualAddressFieldKey) {
          setInnerAddress((prev) => processFullAddress({ ...prev, [manualAddressFieldKey]: innerValue }));
        }
      }

      return {
        ...prevState,
        [field]: innerValue,
      };
    });
  };

  const onChangeInnerAddress = (address: Address) => {
    setInnerAddress(address);
    syncInputValuesWithAddess(address);
  };

  const syncInputValuesWithAddess = (address: Address) => {
    setInnerInputValues({
      automaticInput: address.fullAddress,
      'manualInput.streetAddress1': address.streetAddress1,
      'manualInput.streetAddress2': address.streetAddress2,
      'manualInput.locality': address.locality,
      'manualInput.state': address.state,
      'manualInput.postcode': address.postcode,
    });
  };

  return {
    inputValues: innerInputValues,
    onChangeInputValue: onChangeInnerInputValue,
    address: address,
    onChangeAddress: onChangeInnerAddress,
    resetValues: resetInputValues,
  };
}
