import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchTimesheets, IFetchTimesheetsResponse, ITimesheetsRequest } from 'stores/queries/payments/payment-queries';

export type IUseFetchTimesheetsResult = Omit<
  UseQueryResult<IFetchTimesheetsResponse['data']>,
  | 'isError'
  | 'isIdle'
  | 'isFetched'
  | 'isLoadingError'
  | 'isRefetchError'
  | 'isSuccess'
  | 'dataUpdatedAt'
  | 'errorUpdatedAt'
  | 'failureCount'
  | 'errorUpdateCount'
  | 'errorUpdatedAt'
  | 'failureCount'
  | 'errorUpdateCount'
  | 'isFetchedAfterMount'
  | 'isPlaceholderData'
  | 'isPreviousData'
  | 'isStale'
  | 'isRefetching'
>;

export function useFetchTimesheets(payload?: ITimesheetsRequest): IUseFetchTimesheetsResult {
  const { data, remove, refetch, isFetching, isLoading, status, error } = useQuery(
    ['timesheets', payload],
    async () => (await fetchTimesheets(payload)).data,
  );

  return {
    data,
    remove,
    refetch,
    isFetching,
    isLoading,
    status,
    error,
  };
}
