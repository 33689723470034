import React, { Component } from 'react';
import { Col, Form, Icon, Row, Tabs, Checkbox, notification } from 'antd';
import { Text, Title } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Utils from 'utilities/Utils';
import NDISLineItemGrid from 'common-components/line-items/NDISLineItemGrid';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import FullScreenModal, { FullScreenModalFooter } from 'common-components/modal/FullScreenModal';
import { INewBookingData } from 'interfaces/booking-interfaces';
import NumberInput from 'common-components/inputs/NumberInput';
import _ from 'lodash';
import CommonUtils from 'utilities/common-utils';
import { ServDetailsSectionHeader } from 'views/services/details/sections/content-section/common/ServDetailsSectionHeader';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import AddVCPLineItemsModal from 'views/services/listing/components/AddVCPLineItemsModal';

const { TabPane } = Tabs;

interface IServiceVCPLineItemsModal extends FormComponentProps {
  isOpen: boolean;
  onClose: any;
  selectedService: typeof state.servicesStore.selectedService;
  doUpdateServicePayment: typeof dispatch.servicesStore.doUpdateServicePayment;
  vcpItems: typeof state.servicesStore.vcpItems;
  hasEditPermission: boolean;
  doGetVCPItems: typeof dispatch.servicesStore.doGetVCPItems;
  //   history: any;
}

interface IServiceVCPLineItemsModalState {
  selectedTab: string;
  isAddModalOpen: boolean;
  serviceBillingItems: any;
  isWarningTravelPriceDuring: boolean;
  isEdit: boolean;
  isDiscardChangesModalOpen: boolean;
}

class ServiceVCPLineItemsModal extends Component<IServiceVCPLineItemsModal, IServiceVCPLineItemsModalState> {
  state = {
    selectedTab: 'lineItems',
    isAddModalOpen: false,
    serviceBillingItems: this.props.selectedService.vcpServiceBillingItems
      ? _.cloneDeep(this.props.selectedService.vcpServiceBillingItems)
      : [],
    isWarningTravelPriceDuring: false,
    isEdit: false,
    isDiscardChangesModalOpen: false,
  };

  private _setSelectedTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  private _closeLineItemModal = () => {
    this.setState({ isAddModalOpen: false });
  };

  private _openLineItemModal = () => {
    this.setState({ isAddModalOpen: true });
  };

  private _saveLineItem = (billingItems) => {
    let newBillingItems = _.clone(this.state.serviceBillingItems);
    _.forEach(billingItems, (lineItem, index) => {
      const isNewLineItemAlreadyExist = _.find(newBillingItems, (item) => item.groupCode === lineItem.groupCode);
      if (!isNewLineItemAlreadyExist) {
        const groupBillingItems = _.filter(this.props.vcpItems, (vcpItem) => vcpItem.groupCode === lineItem.groupCode);
        _.forEach(groupBillingItems, (groupBillingItem) => {
          newBillingItems.push({
            supportItemNumber: groupBillingItem.supportItemNumber,
            supportCategoryNumber: 1,
            price: groupBillingItem.price,
            tax: 0,
            unit: groupBillingItem.unit,
            dateType: groupBillingItem.dateType,
            billingProvider: 'VCP',
            label: groupBillingItem.supportItemName,
            priceControl: groupBillingItem.priceControl,
            priority: index + 1,
            mileagePrice: 0,
            groupCode: groupBillingItem.groupCode,
            groupName: groupBillingItem.groupName,
            customerSupportLevel: '1', //TODO: Decide with product team if customerSupportLevel should still be used for automation.
          });
        });
      }
    });
    newBillingItems = _.filter(newBillingItems, (item) => {
      const isItemRemainInList = _.find(billingItems, (billingItem) => item.groupCode === billingItem.groupCode);
      return !!isItemRemainInList;
    });

    this.setState({ isAddModalOpen: false, serviceBillingItems: newBillingItems });
  };

  private _handleOnDelete = (billingLineItems) => {
    this.setState({ serviceBillingItems: billingLineItems });
  };

  private _handleSequenceChange = (billingLineItems) => {
    this.setState({ serviceBillingItems: billingLineItems });
  };

  private _handleOnPriceChange = (billingLineItems) => {
    this.setState({ serviceBillingItems: _.clone(billingLineItems) });
  };

  private _onChangeTravelPriceDuring(event) {
    if (event > 0 && event > 0.85) {
      this.setState({ isWarningTravelPriceDuring: true });
    } else {
      this.setState({ isWarningTravelPriceDuring: false });
    }
  }

  private _changeView = () => {
    this.setState({ isEdit: !this.state.isEdit });
  };

  private _closeDiscardChangesModal = () => {
    this.setState({ isDiscardChangesModalOpen: false });
  };

  private _onSaveServicePricing = async () => {
    const { doUpdateServicePayment, form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        const payload = {
          paymentSourceType: 'VCP',
          vcpClaims: {
            isChargeTransportDuringBooking: form.getFieldValue('isChargeTransportDuringBooking'),
            transportPriceDuringBooking: form.getFieldValue('transportPriceDuringBooking'),
          },
          vcpServiceBillingItems: _.map(this.state.serviceBillingItems, (item) => {
            return { ...item, tax: Number(item.tax), mileagePrice: Number(item.mileagePrice) };
          }),
        };
        await doUpdateServicePayment(payload);
        notification.success({ message: 'Successfully updated service pricing.' });
        this.setState({ isEdit: false });
      } catch (e) {
        notification.error({ message: 'Oops, an error has occurred, please try again.' });
      }
    }
  };

  private _cancelAllChanges = () => {
    this.setState({
      isEdit: false,
      isDiscardChangesModalOpen: false,
      serviceBillingItems: this.props.selectedService.vcpServiceBillingItems
        ? _.cloneDeep(this.props.selectedService.vcpServiceBillingItems)
        : [],
    });
  };

  private _onCloseModal = () => {
    this.setState({
      selectedTab: 'lineItems',
      isWarningTravelPriceDuring: false,
      isEdit: false,
      isDiscardChangesModalOpen: false,
    });
    this.props.onClose();
  };

  async componentDidMount() {
    if (_.isEmpty(this.props.vcpItems)) {
      await this.props.doGetVCPItems({});
    }
  }

  render() {
    const { selectedService, form } = this.props;
    const { serviceBillingItems, isEdit } = this.state;
    const { getFieldDecorator } = form;
    return (
      <FullScreenScrollableModal isOpen={this.props.isOpen} onClose={this._onCloseModal} width={'large'} canClose>
        <ActionModal
          isOpen={this.state.isDiscardChangesModalOpen}
          onClose={this._closeDiscardChangesModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeDiscardChangesModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._cancelAllChanges}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <AddVCPLineItemsModal
          closeLineItemModal={this._closeLineItemModal}
          isOpen={this.state.isAddModalOpen}
          saveLineItem={this._saveLineItem}
          serviceBillingItems={this.state.serviceBillingItems}
        />
        <div>
          <Title level={2} weight="regular" lineHeight={100} className="mb-x-large">
            VCP
          </Title>
          <Tabs
            type="card"
            className={'customer-tab'}
            activeKey={this.state.selectedTab}
            onChange={this._setSelectedTab}
          >
            <TabPane key="lineItems" tab="VCP Items" disabled={isEdit}>
              <Row>
                <Col span={24}>
                  <ServDetailsSectionHeader
                    title={'Line items'}
                    hasEditButton={this.props.hasEditPermission}
                    editAction={isEdit ? this._onSaveServicePricing : this._changeView}
                    hasCancelButton={isEdit}
                    editButtonTitle={isEdit ? 'Save' : 'Edit information'}
                    cancelAction={() => this.setState({ isDiscardChangesModalOpen: true })}
                    subtitle={'Edit what can be claimed under the VCP for this service. These are GST exclusive'}
                    displayPrimaryButtonIcon={!isEdit}
                  />
                </Col>
              </Row>
              {isEdit && (
                <Row>
                  <Col span={24}>
                    <GhostButton icon="plus" onClick={this._openLineItemModal}>
                      Edit items
                    </GhostButton>
                  </Col>
                </Row>
              )}

              <Row className={'mt-medium'}>
                <NDISLineItemGrid
                  displayMode={isEdit ? 'EDIT' : 'VIEW'}
                  isDeletable={false}
                  isSequenceAdjustable={false}
                  onDelete={this._handleOnDelete}
                  onSequenceChange={this._handleSequenceChange}
                  isPriceAdjustable={isEdit}
                  onPriceChange={this._handleOnPriceChange}
                  lineItems={serviceBillingItems}
                  paymentSourceType="VCP"
                  onlyShowSpecificPrice={true}
                />
              </Row>
            </TabPane>
            <TabPane key="claims" tab="VCP Claims" disabled={isEdit}>
              <Row>
                <Col span={24}>
                  <ServDetailsSectionHeader
                    title={'Claims'}
                    hasEditButton={this.props.hasEditPermission}
                    editAction={isEdit ? this._onSaveServicePricing : this._changeView}
                    subtitle={'Edit what can be claimed under the VCP for this service. These are GST exclusive'}
                    hasCancelButton={isEdit}
                    editButtonTitle={isEdit ? 'Save' : 'Edit information'}
                    cancelAction={() => this.setState({ isDiscardChangesModalOpen: true })}
                    displayPrimaryButtonIcon={!isEdit}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {isEdit ? (
                    <>
                      <Col span={24}>
                        <div className={'mt-medium mb-large'}>
                          {getFieldDecorator('isChargeTransportDuringBooking', {
                            valuePropName: 'checked',
                            initialValue:
                              selectedService.serviceClaimConfig &&
                              selectedService.serviceClaimConfig.isChargeVcpTransportDuringBooking
                                ? selectedService.serviceClaimConfig.isChargeVcpTransportDuringBooking
                                : false,
                          })(
                            <Checkbox>
                              Claim for worker travel <b>during</b> a booking.
                            </Checkbox>,
                          )}
                          <br />
                          {form.getFieldValue('isChargeTransportDuringBooking') && (
                            <Form.Item className={'m-none ml-large'}>
                              <div>
                                {getFieldDecorator('transportPriceDuringBooking', {
                                  initialValue:
                                    selectedService.serviceClaimConfig &&
                                    selectedService.serviceClaimConfig.vcpClaims.transportPriceDuringBooking
                                      ? selectedService.serviceClaimConfig.vcpClaims.transportPriceDuringBooking
                                      : null,
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Please enter a price.',
                                    },
                                  ],
                                })(
                                  <NumberInput
                                    min={0}
                                    step={0.05}
                                    max={9999}
                                    onChange={(event) => this._onChangeTravelPriceDuring(event)}
                                    className={'ml-x-small'}
                                    addonBefore={'$'}
                                    style={{ width: '120px' }}
                                  />,
                                )}{' '}
                                price per km.
                                <br />
                                {this.state.isWarningTravelPriceDuring && (
                                  <div
                                    className={'text-color-orange text-size-regular'}
                                    style={{ lineHeight: 'initial' }}
                                  >
                                    The DCJ recommends that cost per km does not exceed $1.00
                                  </div>
                                )}
                              </div>
                            </Form.Item>
                          )}
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      <div>
                        {!selectedService.serviceClaimConfig.isChargeVcpTransportDuringBooking ? (
                          <Text>
                            <b>During</b>: Travel during a booking{' '}
                            <Text weight={'bold'} color="red-dark">
                              cannot
                            </Text>{' '}
                            be claimed for this service.
                          </Text>
                        ) : (
                          <Text>
                            <b>During</b>: Travel during a booking <Text weight={'bold'}>can</Text> be claimed for this
                            service.
                            <br />
                            <b>
                              {CommonUtils.formatPrice(
                                selectedService.serviceClaimConfig.vcpClaims.transportPriceDuringBooking,
                              )}
                            </b>{' '}
                            per km.
                          </Text>
                        )}
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </FullScreenScrollableModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedService: state.servicesStore.selectedService,
  vcpItems: state.servicesStore.vcpItems,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateServicePayment: dispatch.servicesStore.doUpdateServicePayment,
  doGetVCPItems: dispatch.servicesStore.doGetVCPItems,
});

export default connect(mapState, mapDispatch)(Form.create<IServiceVCPLineItemsModal>()(ServiceVCPLineItemsModal));
