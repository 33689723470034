import React from 'react';
import { InvitationStatus, TeamStatus, UserPermissionRole } from 'utilities/enum-utils';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import PermissionUtils from 'utilities/permission-utils';
import { PortalUser } from 'stores/rematch/models/auth-store';
import { useHistory } from 'react-router-dom';

type TmMgmtTeamTableActionMenuParams = {
  portalUser: PortalUser;
  worker: object;
  portalUserOwnerRole:
    | UserPermissionRole.PrimaryOwner
    | UserPermissionRole.Owner
    | UserPermissionRole.TeamManager
    | 999;
  onOpenModal: (selectedModal: string, modalData?: null) => void;
};

export const TmMgmtTeamTableActionMenu = ({
  worker,
  portalUserOwnerRole,
  portalUser,
  onOpenModal,
}: TmMgmtTeamTableActionMenuParams) => {
  const history = useHistory();
  const goToTeamProfile = (workerId: string) => {
    history.push(`/team/details/${workerId}`);
  };

  return (
    <Menu>
      <MenuDivider title='VIEW' className='text-color-secondary bordered-top pt-large' />

      {PermissionUtils.validatePermission('ViewTeamMemberProfile', portalUser.permissions.permissionRoles) && (
        <MenuItem
          label=''
          className='hover-bg-blue-lightest mv-medium'
          text='View profile'
          onClick={() => goToTeamProfile(worker.supportWorkerId)}
        />
      )}

      {PermissionUtils.validatePermission('ViewTeamMemberPermissions', portalUser.permissions.permissionRoles) &&
        worker.status !== TeamStatus.REMOVED && (
          <MenuItem
            label=''
            className='hover-bg-blue-lightest mv-medium'
            text='Edit permissions'
            onClick={() => onOpenModal('EditPermissionModal', worker)}
          />
        )}

      {(worker.status === TeamStatus.REMOVED || worker.removedOn) && (
        <MenuItem
          label=''
          className='hover-bg-blue-lightest mv-medium'
          text='View removal reason'
          onClick={() => onOpenModal('ViewRemovalReason', worker)}
        />
      )}

      {(portalUserOwnerRole < worker.ownerRole ||
        worker.status === TeamStatus.DRAFT ||
        (worker.status === TeamStatus.ENABLED && worker.invitationStatus === InvitationStatus.PENDING)) && (
        <MenuDivider title='MODIFY STATUS' className='text-color-secondary bordered-top pt-large' />
      )}
      {(worker.status === TeamStatus.BLOCKED ||
        (worker.status === TeamStatus.ENABLED &&
          (worker.invitationStatus === InvitationStatus.ACCEPTED ||
            worker.invitationStatus === InvitationStatus.DELETED))) &&
        worker.removedOn &&
        PermissionUtils.validatePermission('EditRemovalDate', portalUser.permissions.permissionRoles) &&
        portalUserOwnerRole < worker.ownerRole && (
          <MenuItem
            label=''
            className='hover-bg-blue-lightest mv-medium'
            text='Edit removal date'
            onClick={() => onOpenModal('EditRemovalDateModal', worker)}
          />
        )}
      {worker.status === TeamStatus.REMOVED && (
        <MenuItem
          label=''
          className='hover-bg-blue-lightest mv-medium text-color-green'
          text='Re-enable user'
          onClick={() => onOpenModal('ReEnableUser', worker)}
        />
      )}

      {worker.status === TeamStatus.ENABLED &&
        (worker.invitationStatus === InvitationStatus.ACCEPTED || worker.invitationStatus === null) &&
        PermissionUtils.validatePermission('BlockTeamMember', portalUser.permissions.permissionRoles) &&
        portalUserOwnerRole < worker.ownerRole && (
          <MenuItem
            label=''
            className='hover-bg-blue-lightest mv-medium'
            text='Block access'
            onClick={() => onOpenModal('BlockAccessModal', worker)}
          />
        )}

      {worker.status === TeamStatus.BLOCKED &&
        PermissionUtils.validatePermission('UnblockTeamMember', portalUser.permissions.permissionRoles) &&
        portalUserOwnerRole < worker.ownerRole && (
          <MenuItem
            label=''
            className='hover-bg-blue-lightest mv-medium'
            text='Unblock access'
            onClick={() => onOpenModal('UnblockAccessModal', worker)}
          />
        )}

      {worker.status === TeamStatus.DRAFT &&
        PermissionUtils.validatePermission('InviteDraftTeamMember', portalUser.permissions.permissionRoles) && (
          <MenuItem
            label=''
            className='hover-bg-blue-lightest mv-medium'
            text='Invite user'
            onClick={() => onOpenModal('InviteUserModal', worker)}
          />
        )}

      {worker.status === TeamStatus.DRAFT &&
        PermissionUtils.validatePermission('DeleteDraftTeamMember', portalUser.permissions.permissionRoles) && (
          <MenuItem
            label=''
            className='hover-bg-blue-lightest text-color-red-dark mv-medium'
            text='Remove'
            onClick={() => onOpenModal('DeleteDraftUserModal', worker)}
          />
        )}
      {(worker.status === TeamStatus.BLOCKED ||
        (worker.status === TeamStatus.ENABLED &&
          (worker.invitationStatus === InvitationStatus.ACCEPTED ||
            worker.invitationStatus === InvitationStatus.DELETED ||
            worker.invitationStatus === null))) &&
        worker.removedOn &&
        PermissionUtils.validatePermission('CancelRemovalDate', portalUser.permissions.permissionRoles) &&
        portalUserOwnerRole < worker.ownerRole && (
          <MenuItem
            label=''
            className='hover-bg-blue-lightest mv-medium'
            text='Cancel removal'
            onClick={() => onOpenModal('CancelRemovalDateModal', worker)}
          />
        )}
      {(worker.status === TeamStatus.BLOCKED ||
        (worker.status === TeamStatus.ENABLED &&
          (worker.invitationStatus === InvitationStatus.ACCEPTED ||
            worker.invitationStatus === InvitationStatus.DELETED ||
            worker.invitationStatus === null))) &&
        PermissionUtils.validatePermission('RemoveTeamMember', portalUser.permissions.permissionRoles) &&
        portalUserOwnerRole < worker.ownerRole && (
          <MenuItem
            label=''
            className='hover-bg-blue-lightest text-color-red-dark mv-medium'
            text='Remove'
            onClick={() => onOpenModal('PermanentlyRemoveUserModal', worker)}
          />
        )}
    </Menu>
  );
};
