import React, { Component, CSSProperties } from 'react';
import { Icon } from 'antd';

interface ILeftNavItemProps {
  title?: string;
  value?: string;
  isSelected?: boolean;
  hasWarning?: boolean;
  onClick?: (string) => void;
  filterConfig?: any;
  counter?: any;
  size?: 'default' | 'small' | string;
}

export class LeftNavItem extends Component<ILeftNavItemProps> {
  render() {
    const {
      title,
      value,
      filterConfig,
      isSelected = false,
      hasWarning = false,
      onClick,
      counter = undefined,
      size = 'default',
    } = this.props;

    const textClass =
      (counter && counter.count > 0) || !counter
        ? 'text-color-black'
        : counter && counter.count === -1
        ? 'text-color-black'
        : 'text-color-secondary';

    const paddingClass =
      size === 'default' ? 'ph-medium pv-small' : size === 'small' ? 'pv-small line-height-100' : 'ph-medium pv-small';

    const styleObj =
      size === 'default'
        ? {}
        : size === 'small'
        ? { paddingLeft: '12px', paddingRight: '12px', whiteSpace: 'nowrap' }
        : { whiteSpace: 'nowrap' };

    const finalStyle = { ...styleObj } as CSSProperties;

    return (
      <div
        className={`${
          isSelected && 'bg-secondary text-weight-bold'
        } cursor-pointer ${paddingClass} select-none rounded flex-row justify-between ${textClass}`}
        onClick={() => onClick(value ? value : filterConfig.key)}
        style={finalStyle}
      >
        <span>{title ? title : filterConfig.title}</span>
        <span>
          {hasWarning && <Icon type="warning" theme="filled" className="text-size-large text-color-gold mr-x-small" />}
        </span>
        <span>
          {counter !== undefined && counter.count}{' '}
          {counter !== undefined && counter.isNew && <span className="text-color-red text-weight-bold">•</span>}
        </span>
      </div>
    );
  }
}
