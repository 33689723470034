import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Paragraph } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { ErrorSVG } from 'assets/UndrawSVG';

interface IBookingRevertCheckInModalProps {
  onClose: () => void;
  isOpen: boolean;
  doRevertBookingToConfirmed: typeof dispatch.bookingsStore.doRevertBookingToConfirmed;
  selectedBooking: typeof state.bookingsStore.selectedBookingItem;
}

interface IBookingRevertCheckInModalState {
  step: number;
  canManuallyClose: boolean;
  title: string;
  isLoading: boolean;
}

class BookingRevertCheckInModel extends Component<IBookingRevertCheckInModalProps, IBookingRevertCheckInModalState> {
  state = { step: 1, canManuallyClose: true, title: 'Revert to confirmed', isLoading: false };

  private renderView = () => {
    const { step } = this.state;

    if (step === 1) {
      return (
        <div className="anim-fade-in" style={{ height: '100%' }}>
          <div className="text-align-left pt-small">
            <Paragraph>
              Doing this will return the booking status to <b>Confirmed.</b>
            </Paragraph>
            <Paragraph>
              Any start times recorded by assigned team members will be deleted along with any claims (Travel,
              additional expense etc...).
            </Paragraph>
            <Paragraph>All notes will be retained.</Paragraph>
            <Paragraph>
              Are your sure you want to revert this booking to <b>confirmed.</b>
            </Paragraph>
          </div>
          <div className="justify-end align-end">
            <ActionModalFooter>
              <GhostButton
                className="mr-medium"
                size="large"
                onClick={this.onCloseModal}
                disabled={this.state.isLoading}
              >
                Cancel
              </GhostButton>
              <PrimaryButton size="large" onClick={this.onRevert} loading={this.state.isLoading}>
                Revert to confirmed
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-fade-in" style={{ height: '100%' }}>
          <div className="text-align-left pt-small">
            <Paragraph>You have successfully undone the completion of booking.</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Save
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Oops something has gone wrong, please try again</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Go Back to Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  private onError = () => {
    this.setState({ step: 3, canManuallyClose: true, isLoading: false });
  };

  private onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, title: 'Revert to confirmed', isLoading: false, canManuallyClose: true }, onClose);
  };

  private onComplete = () => {
    this.setState({ step: 2, canManuallyClose: true, title: 'Check-in successfully undone', isLoading: false });
  };

  private onRevert = async () => {
    const { doRevertBookingToConfirmed, selectedBooking } = this.props;
    this.setState({ canManuallyClose: false, isLoading: true });
    try {
      await doRevertBookingToConfirmed({ bookingId: selectedBooking.bookingId });
      this.onComplete();
    } catch (e) {
      this.onError();
    }
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div className="flex-column">{this.renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doRevertBookingToConfirmed: dispatch.bookingsStore.doRevertBookingToConfirmed,
});

export default connect(mapState, mapDispatch)(BookingRevertCheckInModel);
