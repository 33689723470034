import { FieldLabel, StatusMessage } from '@good/components';
import { FileUpload } from '@good/icons';
import React from 'react';
import { humanFileSize } from 'utilities/firebase-storage';

type CsvImportDropzoneProps = {
  file: File | null;
  onFileSelected: (file: File | null) => void;
  error?: string;
};

export const CsvImportDropzone = ({ file, onFileSelected, error }: CsvImportDropzoneProps) => {
  return (
    <div className="flex flex-col gap-2">
      <FieldLabel isRequired requirementIndicator="label">
        CSV file
      </FieldLabel>

      <label
        htmlFor="csv-file-dropzone"
        className="flex flex-col items-center justify-center w-full h-48 border border-dashed rounded-lg cursor-pointer bg-gray-light-3 hover:bg-canary-light-1 hover:border-canary transition-all"
      >
        <div className="flex flex-col items-center justify-center pt-4 pb-5">
          <div style={{ fontSize: 'xx-large' }} className={file && error ? 'text-red' : undefined}>
            <FileUpload />
          </div>

          {file ? (
            <p className={error ? 'text-red' : undefined}>
              {file.name} ({humanFileSize(file.size)})
            </p>
          ) : (
            <>
              <p className="my-2">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p style={{ fontSize: 'small' }}>Files must be in CSV format (max. 5MB)</p>
            </>
          )}
        </div>

        <input
          id="csv-file-dropzone"
          type="file"
          className="hidden"
          accept=".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/plain"
          onChange={(e) => {
            const { files } = e.target;
            const file = files && files.length > 0 ? files[0] : undefined;
            onFileSelected(file ?? null);
          }}
        />
      </label>

      {error && <StatusMessage tone="critical">{error}</StatusMessage>}
    </div>
  );
};
