import React, { Component } from 'react';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import ActionTypeStepPanel from './ActionTypeStepPanel';
import { Title } from 'common-components/typography';
import SelectSessionsStepPanel from './SelectSessionsStepPanel';
import SelectTeamMembersStepPanel from './SelectTeamMembersStepPanel';
import AssignmentSummaryStepPanel from './AssignmentSummaryStepPanel';
import { ScheduleType } from 'utilities/enum-utils';
import SelectScheduleStepPanel from './SelectScheduleStepPanel';
import ManageSessionStepPanel from './ManageSessionStepPanel';
import {
  IGroupServiceSession,
  IGroupServiceSupportWorkersWithConflicts,
  IGroupServiceTimeSlot,
} from 'interfaces/service-interfaces';
import { IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

interface IAssignTeamMemberToSessionModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
}

interface IAssignTeamMemberToSessionModalState {
  step: number;
  scheduleType: ScheduleType;
  selectedWorkers: IGroupServiceSupportWorkersWithConflicts[];
  selectedSessions: IGroupServiceSession[];
  selectedTimeSlot: IGroupServiceTimeSlot;
}

class AssignTeamMemberToSessionModal extends Component<
  IAssignTeamMemberToSessionModalProps,
  IAssignTeamMemberToSessionModalState
> {
  state = {
    step: 1,
    scheduleType: null,
    selectedWorkers: [],
    selectedSessions: [],
    selectedTimeSlot: null,
  };

  private _reset = () => {
    this.setState({
      step: 1,
      scheduleType: null,
      selectedWorkers: [],
      selectedSessions: [],
      selectedTimeSlot: null,
    });
  };

  private _onClose = () => {
    this._reset();
    this.props.onClose();
  };

  private _onSetScheduleType = (scheduleType) => {
    if (scheduleType !== this.state.scheduleType) {
      this.setState({ scheduleType, selectedSessions: [], selectedTimeSlot: null });
    }
  };

  private _onSetSelectedSessions = (selectedSessions) => {
    this.setState({ selectedSessions });
  };

  private _onSetSelectedTimeSlot = (selectedTimeSlot) => {
    const timezone = _.get(this.props, 'selectedGroupService.timezone');
    const { serviceDateTimes } = _.cloneDeep(selectedTimeSlot);
    const selectedSessions = _.chain(serviceDateTimes)
      .filter((session) => moment().isBefore(session.startDateTime))
      .map((session) => {
        const newSession = _.cloneDeep(session);
        const sessionStartDateTime = moment.tz(session.startDateTime, timezone);
        const sessionEndDateTime = moment.tz(session.endDateTime, timezone);
        const startDateTime = _.get(selectedTimeSlot, 'timeslot.startDateTime', sessionStartDateTime);
        const endDateTime = _.get(selectedTimeSlot, 'timeslot.endDateTime', sessionEndDateTime);
        const date = {
          date: sessionStartDateTime.get('date'),
          month: sessionStartDateTime.get('month'),
          year: sessionStartDateTime.get('year'),
        };

        _.set(newSession, 'startDateTime', moment.tz(startDateTime, timezone).set(date));
        _.set(newSession, 'endDateTime', moment.tz(endDateTime, timezone).set(date));

        return newSession;
      })
      .value();

    this.setState({ selectedTimeSlot, selectedSessions });
  };

  private _onSetSelectedWorkers = (selectedWorkers) => {
    this.setState({ selectedWorkers });
  };

  private _onNextStep = () => {
    const newStep = this.state.step + 1;
    this.setState({ step: newStep });
  };

  private _onPreviousStep = () => {
    const newStep = this.state.step - 1;
    this.setState({ step: newStep });
  };

  private _renderStepContent = () => {
    const { selectedGroupService } = this.props;
    const { step, scheduleType, selectedWorkers, selectedSessions, selectedTimeSlot } = this.state;
    const isSchedule = this.state.scheduleType === ScheduleType.SCHEDULE;

    if (step === 1) {
      return (
        <ActionTypeStepPanel
          onNextStep={this._onNextStep}
          onSetScheduleType={this._onSetScheduleType}
          scheduleType={this.state.scheduleType}
        />
      );
    } else if (step === 2) {
      return isSchedule ? (
        <SelectScheduleStepPanel
          selectedGroupService={selectedGroupService}
          selectedTimeSlot={selectedTimeSlot}
          onPreviousStep={this._onPreviousStep}
          onNextStep={this._onNextStep}
          onSetSelectedTimeSlot={this._onSetSelectedTimeSlot}
        />
      ) : (
        <SelectSessionsStepPanel
          onPreviousStep={this._onPreviousStep}
          onSetSelectedSessions={this._onSetSelectedSessions}
          onNextStep={this._onNextStep}
          selectedGroupService={selectedGroupService}
        />
      );
    } else if (step === 3) {
      return (
        <SelectTeamMembersStepPanel
          selectedTimeSlot={selectedTimeSlot}
          selectedSessions={selectedSessions}
          selectedGroupService={selectedGroupService}
          onPreviousStep={this._onPreviousStep}
          onNextStep={this._onNextStep}
          onSetSelectedWorkers={this._onSetSelectedWorkers}
          scheduleType={this.state.scheduleType}
        />
      );
    } else if (step === 4) {
      return (
        <ManageSessionStepPanel
          onPreviousStep={this._onPreviousStep}
          onNextStep={this._onNextStep}
          onSetSelectedWorkers={this._onSetSelectedWorkers}
          scheduleType={scheduleType}
          selectedGroupService={selectedGroupService}
          selectedSessions={selectedSessions}
          selectedTimeSlot={selectedTimeSlot}
          selectedWorkers={selectedWorkers}
          onSetSelectedSessions={this._onSetSelectedSessions}
        />
      );
    } else if (step === 5) {
      return (
        <AssignmentSummaryStepPanel
          selectedGroupService={selectedGroupService}
          onPreviousStep={this._onPreviousStep}
          onClose={this._onClose}
          selectedWorkers={selectedWorkers}
          scheduleType={scheduleType}
        />
      );
    }
  };

  private _buildSteps = () => {
    const steps = [];
    const titles = ['Action type', 'Select sessions', 'Select team members', 'Manage sessions', 'Review'];

    for (let i = 0; i < titles.length; i++) {
      const stepObj = {
        stepNumber: i + 1,
        stepLabel: i + 1,
        stepTitle: titles[i],
      };

      steps.push(stepObj);
    }

    return steps;
  };

  render() {
    let steps = this._buildSteps();
    let headerTitle = (
      <Title level={2} className="m-none">
        Assign team member to sessions
      </Title>
    );

    return (
      <FullScreenScrollableModal
        isOpen={this.props.isOpen}
        onClose={this._onClose}
        width={'full'}
        headerTitle={headerTitle}
        bgColor="tertiary"
        canCloseEsc={false}
        fixedHeight={false}
        noMarginBottom={true}
        contentPadding={false}
        hasFooter={false}
        hasSteps={true}
        currentStep={this.state.step}
        steps={steps}
      >
        {this._renderStepContent()}
      </FullScreenScrollableModal>
    );
  }
}
const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
});

export default connect(mapState, null)(AssignTeamMemberToSessionModal);
