import React from 'react';

import { PrimaryOwnerPermissionRow } from 'views/account-management/add-new-member/panels/permissions-panel/portal/permission-rows/PrimaryOwnerPermissonRow';
import { PaymentPermissionsRow } from 'views/account-management/add-new-member/panels/permissions-panel/portal/permission-rows/PaymentPermissionsRow';
import { TeamPermissionsRow } from 'views/account-management/add-new-member/panels/permissions-panel/portal/permission-rows/TeamPermissionsRow';
import CustomerPermissionsRow from 'views/account-management/add-new-member/panels/permissions-panel/portal/permission-rows/CustomerPermissionRow';
import { ServicePermissionsRow } from 'views/account-management/add-new-member/panels/permissions-panel/portal/permission-rows/ServicePermissionRow';
import { IPermissions, IServiceDepartment } from 'interfaces/account-interfaces';
import { TargetUserIsOwnerRow } from 'views/account-management/add-new-member/panels/permissions-panel/portal/permission-rows/TargetUserIsOwnerRow';
import PlanManagementPermissionRow from './permission-rows/PlanManagementPermissionRow';
import FormBuilderPermissionRow from './permission-rows/FormBuilderPermissionRow';
import WorkflowPermissionRow from './permission-rows/WorkflowPermissionRow';
import SubscriptionPermissionRow from './permission-rows/SubscriptionPermissionRow';

interface ExtendedPortalPermissions {
  permissions: IPermissions;
  serviceDepartments: IServiceDepartment[];
  onChangePermissions?: any;
  onChangeRole?: any;
  isPrimaryOwner: boolean;
  isTargetUserPrimaryOwner: boolean;
  isTargetUserOwner: boolean;
  actionsDisabled: boolean;
  hasPlanManagement: boolean;
  hasSubscriptionPermission: boolean;
}

export function ExtendedPortalPermissions({
  permissions,
  serviceDepartments,
  onChangePermissions,
  onChangeRole,
  isPrimaryOwner,
  isTargetUserPrimaryOwner,
  isTargetUserOwner,
  actionsDisabled,
  hasPlanManagement,
  hasSubscriptionPermission,
}: ExtendedPortalPermissions) {
  // TODO : isOwner = true when the current user is an owner.
  return (
    <div>
      {/* Grey Divider */}
      <div className="bg-tertiary width-full" style={{ height: '4px' }} />

      {/* Container */}
      <div className="p-large">
        {/* Primary Owner Permissions */}
        {isPrimaryOwner && !isTargetUserPrimaryOwner && (
          <PrimaryOwnerPermissionRow isTargetUserOwner={isTargetUserOwner} onChangeRole={onChangeRole} />
        )}

        {(isTargetUserOwner || isTargetUserPrimaryOwner) && (
          <TargetUserIsOwnerRow isTargetUserPrimaryOwner={isTargetUserPrimaryOwner} isPrimaryOwner={isPrimaryOwner} />
        )}

        {/* Payments Permissions */}
        <PaymentPermissionsRow
          permissions={permissions}
          onChangePermissions={onChangePermissions}
          actionsDisabled={actionsDisabled}
          hasAllAccess={isTargetUserPrimaryOwner || isTargetUserOwner}
        />

        {/* Team Permissions */}
        <TeamPermissionsRow
          permissions={permissions}
          onChangePermissions={onChangePermissions}
          actionsDisabled={actionsDisabled}
          hasAllAccess={isTargetUserPrimaryOwner || isTargetUserOwner}
        />

        {/* Customer Permissions */}
        <CustomerPermissionsRow
          permissions={permissions}
          onChangePermissions={onChangePermissions}
          actionsDisabled={actionsDisabled}
          hasAllAccess={isTargetUserPrimaryOwner || isTargetUserOwner}
        />

        {/* Form Builder Permissions */}
        <FormBuilderPermissionRow
          permissions={permissions}
          onChangePermissions={onChangePermissions}
          actionsDisabled={actionsDisabled}
          hasAllAccess={isTargetUserPrimaryOwner || isTargetUserOwner}
        />

        <WorkflowPermissionRow
          permissions={permissions}
          onChangePermissions={onChangePermissions}
          actionsDisabled={actionsDisabled}
          hasAllAccess={isTargetUserPrimaryOwner || isTargetUserOwner}
        />

        {hasPlanManagement && (
          <PlanManagementPermissionRow
            permissions={permissions}
            onChangePermissions={onChangePermissions}
            actionsDisabled={actionsDisabled}
            hasAllAccess={isTargetUserPrimaryOwner || isTargetUserOwner}
          />
        )}

        {/* Service Permissions */}
        <ServicePermissionsRow
          serviceDepartments={serviceDepartments}
          permissions={permissions}
          onChangePermissions={onChangePermissions}
          actionsDisabled={actionsDisabled}
          hasAllAccess={isTargetUserPrimaryOwner || isTargetUserOwner}
        />
        {hasSubscriptionPermission && (
          <SubscriptionPermissionRow
            permissions={permissions}
            isPrimaryOwner={isPrimaryOwner}
            isTargetUserPrimaryOwner={isTargetUserPrimaryOwner}
            onChangePermissions={onChangePermissions}
          />
        )}
      </div>
    </div>
  );
}
