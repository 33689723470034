import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Airplay = forwardRef(function Airplay(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.25h-.055c-1.367 0-2.47 0-3.337.117-.9.12-1.658.38-2.26.981-.602.602-.86 1.36-.981 2.26-.117.867-.117 1.97-.117 3.337v3.091c0 .904 0 1.633.053 2.222.055.606.172 1.136.45 1.617a3.75 3.75 0 0 0 1.372 1.373c.481.277 1.011.394 1.617.449.589.053 1.318.053 2.222.053H7v-1.5c-.948 0-1.61 0-2.122-.047-.502-.046-.788-.13-1.003-.254a2.25 2.25 0 0 1-.824-.824c-.124-.215-.208-.5-.254-1.003-.046-.512-.047-1.174-.047-2.122V9c0-1.435.002-2.437.103-3.192.099-.734.28-1.122.556-1.399.277-.277.665-.457 1.4-.556C5.563 3.752 6.564 3.75 8 3.75h8c1.436 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4.102.754.103 1.756.103 3.191v3c0 .948 0 1.61-.047 2.122-.046.502-.13.788-.254 1.003a2.25 2.25 0 0 1-.824.824c-.215.124-.5.208-1.003.254-.512.046-1.174.047-2.122.047v1.5h.036c.904 0 1.633 0 2.222-.053.606-.055 1.136-.172 1.617-.45a3.75 3.75 0 0 0 1.373-1.372c.277-.481.394-1.011.449-1.617.053-.589.053-1.318.053-2.222V8.945c0-1.367 0-2.47-.116-3.337-.122-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117H8Zm4.53 13.22a.75.75 0 0 0-1.06 0l-5 5A.75.75 0 0 0 7 21.75h10a.75.75 0 0 0 .53-1.28l-5-5ZM12 17.06l3.19 3.19H8.81L12 17.06Z"
        fill="currentColor"
      />
    </Icon>
  );
});
