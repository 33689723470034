import clsx from 'clsx';
import { modularScale } from 'polished';

import { baseStyleProps } from './theme';

import type { CssComponent } from '@stitches/react/types/styled-component';

/**
 * @name
 * compose
 *
 * @example
 * compose(css({ fontFamily: '$sans' }), css({ fontSize: '$font100' }))
 * // c-hFqOnR c-PJLV
 */
export function compose(...args: (CssComponent | { className: string })[]) {
  return clsx(
    args.map((arg) => {
      if (typeof arg === 'function') return arg().className;
      else return arg.className;
    }),
  );
}

/**
 * @name
 * createScale
 *
 * @example
 * createScale(1.5, 16)(1)
 */
export function createScale(ratio: number, base: number) {
  return function (steps: number) {
    return `${modularScale(steps, base, ratio)}px`;
  };
}

/**
 * @name
 * useStyleProps
 *
 * @example
 * useStyleProps({ width: "$size100" }, { width: "width" })
 */
export function useStyleProps(
  props: Record<string, any>,
  handlers: Record<string, string | Record<string, string>> = baseStyleProps,
) {
  const styleProps = {} as Record<string, any>;
  const otherProps = {} as Record<string, any>;

  for (const key in props) {
    const isFound = !!handlers[key];
    const value = props[key];

    if (isFound)
      if (typeof value === 'object')
        for (const responsiveKey in value) {
          const prefix = '@';
          const responsiveValue = value[responsiveKey];

          styleProps[prefix + responsiveKey] = {
            ...styleProps[responsiveKey],
            [key]: responsiveValue,
          };
        }
      else styleProps[key] = value;
    else otherProps[key] = value;
  }

  return { styleProps, ...otherProps };
}
