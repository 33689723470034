import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import { WorkflowsStatus } from 'utilities/enum-utils';

type IWorkflowsStatusTag = {
  status: WorkflowsStatus | string;
  total?: number;
  className?: string;
};

class WorkflowsStatusTag extends Component<IWorkflowsStatusTag> {
  render() {
    const { status, total, className } = this.props;
    let label, bgColor, textColor, borderColor;

    switch (status) {
      case WorkflowsStatus.COMPLETED:
        label = 'Completed';
        bgColor = 'bg-green';
        textColor = 'text-color-green-lightest';
        borderColor = 'border-green';
        break;
      case WorkflowsStatus.INPROGRESS:
        label = 'In progress';
        bgColor = 'bg-orange-lightest';
        textColor = 'text-color-orange-dark';
        borderColor = 'border-orange-lighter';
        break;
      case WorkflowsStatus.FLAGGED:
        label = 'Issues flagged';
        bgColor = 'bg-red';
        textColor = 'text-color-red-lightest';
        borderColor = 'border-red';
        break;
      case WorkflowsStatus.CLOSED:
        label = 'Closed';
        bgColor = 'bg-red';
        textColor = 'text-color-red-lightest';
        borderColor = 'border-red';
        break;
      default:
        break;
    }

    const containerClassNames = `${
      className || ''
    } text-align-center ph-small inline-block rounded ${bgColor} ${borderColor} bordered`;
    const textClassNames = `${textColor}`;

    return (
      <div className={containerClassNames}>
        <Text size={'regular'} weight={total && 'bolder'} className={textClassNames}>
          {total !== undefined ? `${total} ${label}` : label}
        </Text>
      </div>
    );
  }
}

export default WorkflowsStatusTag;
