import React, { Component } from 'react';
import * as H from 'history';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState, dispatch } from 'stores/rematch/root-store';
import { ICustomView, IFilterValue } from 'interfaces/custom-views-interface';
import CustomAllViews from 'common-components/custom-views/all-views';
import { FilterType } from 'utilities/enum-utils';

interface ICustomerAllViewsProps {
  doFetchCustomerViews: typeof dispatch.customersStore.doFetchCustomerViews;
  setCustomerListingActiveTab: typeof dispatch.customersStore.setCustomerListingActiveTab;
  doAddCustomerView: typeof dispatch.customersStore.doAddCustomerView;
  doUpdateCustomerViewTab: typeof dispatch.customersStore.doUpdateCustomerView;
  doDuplicateCustomerView: typeof dispatch.customersStore.doDuplicateCustomerView;
  doDeleteCustomerView: typeof dispatch.customersStore.doDeleteCustomerView;
  setCustomerFilter: typeof dispatch.customersStore.setCustomerFilter;
  customerViews: ICustomView[];
  customerFilter: IFilterValue[];
  customerListingActiveTab: ICustomView;
  createdByMeViews: ICustomView[];
  createdByOthersViews: ICustomView[];
  defaultCustomerViews: ICustomView[];
  history: H.History;
}

const defaultFilterValue = [
  { [FilterType.CUSTOMER]: [] },
  { [FilterType.SERVICE]: [] },
  { [FilterType.CUSTOMER_STATUS]: [] },
];

class CustomerAllViews extends Component<ICustomerAllViewsProps, null> {
  render() {
    const {
      defaultCustomerViews,
      customerViews,
      customerFilter,
      customerListingActiveTab,
      doFetchCustomerViews,
      doAddCustomerView,
      doUpdateCustomerViewTab,
      doDuplicateCustomerView,
      doDeleteCustomerView,
      setCustomerFilter,
      setCustomerListingActiveTab,
    } = this.props;

    return (
      <CustomAllViews
        pageName="customers"
        pageNavigation="/customers"
        pageViews={customerViews}
        pageFilter={customerFilter}
        pageListingActiveTab={customerListingActiveTab}
        defaultViews={defaultCustomerViews}
        defaultFilterValue={defaultFilterValue}
        doFetchPageViews={doFetchCustomerViews}
        setPageListingActiveTab={setCustomerListingActiveTab}
        doAddView={doAddCustomerView}
        doDeleteView={doDeleteCustomerView}
        doUpdateViewTab={doUpdateCustomerViewTab}
        doDuplicateView={doDuplicateCustomerView}
        setPageFilter={setCustomerFilter}
        {...this.props}
      ></CustomAllViews>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  defaultCustomerViews: state.customersStore.defaultCustomerViews,
  createdByMeViews:
    state.customersStore.customerViews && state.customersStore.customerViews.filter((view) => view.isOwner === true),
  createdByOthersViews:
    state.customersStore.customerViews && state.customersStore.customerViews.filter((view) => view.isOwner === false),
  displayedCustomerListingTabs: state.customersStore.displayedCustomerListingTabs,
  customerViews: state.customersStore.customerViews,
  customerFilter: state.customersStore.customerFilter,
  customerListingActiveTab: state.customersStore.customerListingActiveTab,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doFetchCustomerViews: dispatch.customersStore.doFetchCustomerViews,
  doAddCustomerView: dispatch.customersStore.doAddCustomerView,
  doUpdateCustomerViewTab: dispatch.customersStore.doUpdateCustomerView,
  doDuplicateCustomerView: dispatch.customersStore.doDuplicateCustomerView,
  doDeleteCustomerView: dispatch.customersStore.doDeleteCustomerView,
  setCustomerFilter: dispatch.customersStore.setCustomerFilter,
  setDisplayedCustomerListingTabs: dispatch.customersStore.setDisplayedCustomerListingTabs,
  setCustomerListingActiveTab: dispatch.customersStore.setCustomerListingActiveTab,
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAllViews);
