import { BreadcrumbItem, Breadcrumbs } from '@goodhuman-me/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const InsightsBreadcrumbs: React.FC = () => {
  const { t } = useTranslation('', { keyPrefix: 'insights' });

  return (
    <div className="flex">
      <Breadcrumbs>
        <BreadcrumbItem>
          <Link to="/team">{t('summary.filters.teamMembersLabel')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <span>{t('table.breadcrumbTitle')}</span>
        </BreadcrumbItem>
      </Breadcrumbs>
    </div>
  );
};
