import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import { UserPermissionLookup } from 'interfaces/user-permission-type';
import PermissionUtils from 'utilities/permission-utils';

export const usePermissionCheck = (userPermission: UserPermissionLookup) => {
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  const permissionRoles = portalUser?.permissions.permissionRoles ?? [];
  return PermissionUtils.validatePermission(userPermission, permissionRoles);
};
