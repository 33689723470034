import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { TmMgmtSearch } from '../tm-mgmt-search';

type TmMgmtUninvitedSearchParams = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
};

export const TmMgmtUninvitedSearch = ({
  isLoading,
  setIsLoading,
  page,
  pageSize,
  pageTimestamp,
}: TmMgmtUninvitedSearchParams) => {
  const { uninvitedSearchText } = useSelector((state: IRootState) => state.teamStore);
  const {
    teamStore: { doFetchUninvited, setUninvitedTeamFilter },
  } = useDispatch<IRootDispatch>();

  const searchText = async (txt: string) => {
    setUninvitedTeamFilter(txt);
    await doFetchUninvited({
      page: page,
      pageSize: pageSize,
      pageTimestamp: pageTimestamp,
    });
    setIsLoading(false);
  };

  return (
    <div className="pb-medium flex-row bg-white">
      <TmMgmtSearch
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        defaultSearchText={uninvitedSearchText}
        searchText={searchText}
      />
    </div>
  );
};
