import { Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React, { PureComponent } from 'react';

interface Props {
  loading: boolean;
  isOpen: boolean;
  onClose(): void;
  onResolve(): void;
}

class SendBackModal extends PureComponent<Props> {
  render() {
    const { isOpen, loading, onClose, onResolve } = this.props;

    return (
      <ActionModal width="medium" isOpen={isOpen} onClose={onClose} title="Resolve issue" verticalAlignment="center">
        <Paragraph>Are you sure want to resolve the issue with this workflow.</Paragraph>

        <Paragraph>This will allow the workflow step to be approved.</Paragraph>

        <Row type="flex" justify="end" className="mt-x2-large">
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>

          <PrimaryButton size="large" loading={loading} onClick={onResolve}>
            Resolve issue
          </PrimaryButton>
        </Row>
      </ActionModal>
    );
  }
}

export default SendBackModal;
