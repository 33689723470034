import React, { useState } from 'react';
import { ISession } from 'src/interfaces/session-interfaces';
import { IShiftSlot } from 'src/interfaces/shift-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import moment from 'moment-timezone';
import { IRootDispatch } from 'src/stores/rematch/root-store';
import { useDispatch } from 'react-redux';
import { useFetchWorkTypes } from 'stores/hooks/query-hooks/use-query-fetch-work-types-per-category';
import { WorkTypeCategory } from 'utilities/enum-utils';

interface IApproveLeaveModal {
  isOpen: boolean;
  shiftSlot: IShiftSlot;
  onClose: (targetFlag, refreshShiftSlots?: boolean) => void;
  session: ISession;
}

const ApproveLeaveModal = (props: IApproveLeaveModal) => {
  const dispatch = useDispatch<IRootDispatch>();
  const { isOpen, onClose, session, shiftSlot } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: leaveData } = useFetchWorkTypes({ workTypeCategory: WorkTypeCategory.Leave });
  const leaveType =
    leaveData && leaveData.length > 0 && leaveData?.find((workType) => workType.workTypeId === shiftSlot?.workTypeId);

  const shiftStartDateTime = shiftSlot?.shiftStartDateTime;
  const shiftEndDateTime = shiftSlot?.shiftEndDateTime;

  const approveShift = async (request) => {
    await dispatch.groupServiceStore.doApproveLeaveShiftSlots(request);
  };

  const shiftDatesWithoutTimezone = {
    shiftStartDateTime: moment(moment.tz(shiftStartDateTime, session.timezone).format('YYYY-MM-DD HH:mm')),
    shiftEndDateTime: moment(moment.tz(shiftEndDateTime, session.timezone).format('YYYY-MM-DD HH:mm')),
  };

  const Duration = () => {
    const startTime = moment(moment.tz(shiftStartDateTime, session.timezone).format('YYYY-MM-DD HH:mm'));
    const endTime = moment(moment.tz(shiftEndDateTime, session.timezone).format('YYYY-MM-DD HH:mm'));
    const hoursDiff = endTime.diff(startTime, 'hours');
    const duration = hoursDiff === 1 ? 'hour' : 'hours ';
    return (
      <Text>
        {hoursDiff} {duration}
      </Text>
    );
  };

  const request = {
    serviceId: session.serviceId,
    sessionId: session.serviceDateTimeId,
    shiftSlots: [shiftSlot],
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    await approveShift(request);
    setIsSubmitting(false);
    handleClose();
  };

  const handleClose = () => {
    onClose({ targetFlag: 'isApproveLeaveOpen' }, true);
  };

  if (!leaveData) {
    return <></>;
  }

  return (
    <ActionModal
      isOpen={isOpen}
      title={'Approve leave'}
      onClose={handleClose}
      width="medium"
      canCloseOutside={!isSubmitting}
    >
      <div>
        <div className="mb-large line-height-135">
          <Text>This will appear on {shiftSlot?.firstName}&apos;s timesheet.</Text>
        </div>

        <div className="mb-large line-height-135">
          <FieldLabel text={'TEAM MEMBER'} />
          <Text>
            {shiftSlot?.firstName} {shiftSlot?.lastName}
          </Text>
        </div>

        <div className="mb-large line-height-135">
          <FieldLabel text={'LEAVE TYPE'} />
          <Text>{leaveType?.name}</Text>
        </div>

        <div className="mb-large line-height-135">
          <FieldLabel text={'SHIFT TIME'} />
          <Text lineHeight={135}>
            {shiftDatesWithoutTimezone.shiftStartDateTime.format('hh:mm A, DD MMMM YYYY - ')}
            {shiftDatesWithoutTimezone.shiftEndDateTime.format('hh:mm A, DD MMMM YYYY')}
          </Text>
        </div>

        <div className="mb-large line-height-135">
          <FieldLabel text={'DURATION'} />
          <Duration />
        </div>

        <ActionModalFooter align="right">
          <SecondaryButton size="large" className="mr-medium" onClick={handleClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={handleSave} loading={isSubmitting}>
            Approve Leave
          </PrimaryButton>
        </ActionModalFooter>
      </div>
    </ActionModal>
  );
};

export default React.memo(ApproveLeaveModal);
