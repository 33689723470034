import clsx from 'clsx';
import React from 'react';

import { CustomerViewExpansionPanel } from '../../components/customer-view-expansion-panel';
import { CustomerViewPanel } from '../../components/customer-view-panel';

type Props = {
  title: string;
  className?: string;
};

export const CustomerDetailAboutView = ({ title, className, ...props }: Props): JSX.Element => (
  <div className={clsx('flex gap-4', className)} {...props}>
    <CustomerViewExpansionPanel title={title}>Menu Placeholder</CustomerViewExpansionPanel>
    <CustomerViewPanel className="w-full">Content Placeholder</CustomerViewPanel>
  </div>
);
