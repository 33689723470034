import React, { Component } from 'react';
import { Form } from 'antd';
import { Text, Title } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';

// Panels
import AddLegalGuardianStepCustomerDetail from './wizard-steps/AddLegalGuardianStepSelectGuardian';
import AddLegalGuardianStepInviteToApp from './wizard-steps/AddLegalGuardianStepInviteToApp';
import AddLegalGuardianSuccessStep from './wizard-steps/AddLegalGuardianSuccessStep';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';

import _ from 'lodash';
import { ICustomer } from 'src/interfaces/customer-interfaces';

interface IAddLegalGuardianWizardModalProps extends FormComponentProps {
  closeAddLegalGuardianWizardModal: (serviceData?: any) => void;
  setAddLegalGuardianDetailWizard?: typeof dispatch.customersStore.setAddLegalGuardianDetailWizard;
  addLegalGuardianDetailWizard: typeof state.customersStore.addLegalGuardianDetailWizard;
  isOpen: boolean;
  selectedCustomer: ICustomer;
  authStore: typeof state.authStore;
}

interface IAddLegalGuardianWizardModalState {
  step: number;
  isProceedOpen: boolean;
}

const steps = [
  { stepNumber: 1, stepLabel: 1, stepTitle: 'Select legal guardian' },
  { stepNumber: 2, stepLabel: 2, stepTitle: 'Invite to app' },
  { stepNumber: 3, stepLabel: 3, stepTitle: 'Done!' },
];

class AddLegalGuardianWizardModal extends Component<
  IAddLegalGuardianWizardModalProps,
  IAddLegalGuardianWizardModalState
> {
  state = {
    step: 1,
    isProceedOpen: false,
  };

  private _onNextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  private _onPreviousStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  private _closeAddLegalGuardianModal = async () => {
    const { addLegalGuardianDetailWizard } = this.props;

    if (!_.isEmpty(addLegalGuardianDetailWizard)) {
      this.setState({ isProceedOpen: true });
    } else {
      this._resetAndCloseModal();
    }
  };

  private _resetAndCloseModal = () => {
    this.props.setAddLegalGuardianDetailWizard([]);
    this.setState({ isProceedOpen: false, step: 1 });
    this.props.closeAddLegalGuardianWizardModal();
  };

  private _renderStepContent = () => {
    const { step } = this.state;
    if (step === 1) {
      return (
        <AddLegalGuardianStepCustomerDetail
          onNextStep={this._onNextStep}
          selectedCustomer={this.props.selectedCustomer}
        />
      );
    } else if (step === 2) {
      return <AddLegalGuardianStepInviteToApp onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />;
    } else if (step === 3) {
      return (
        <AddLegalGuardianSuccessStep
          selectedCustomer={this.props.selectedCustomer}
          resetAndCloseModal={this._resetAndCloseModal}
        />
      );
    }
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedOpen: false });
  };

  componentDidMount() {
    this.props.setAddLegalGuardianDetailWizard([]);
  }

  render() {
    let headerTitle = (
      <Title level={2} className="m-none">
        Create workflow
      </Title>
    );

    return (
      <>
        <ActionModal
          isOpen={this.state.isProceedOpen}
          onClose={this._closeProceedModal}
          title={'Cancelling creating customer'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, are you sure you want to <b>cancel</b> now ?
          </Text>
          <br />
          <Text className={'mb-medium'} weight={'bold'}>
            Your progress will not be saved.
          </Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
              Back
            </PrimaryButton>
            <GhostButton size="large" onClick={this._resetAndCloseModal}>
              Cancel
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <FullScreenScrollableModal
          isOpen={this.props.isOpen}
          onClose={this._closeAddLegalGuardianModal}
          width={'full'}
          headerTitle={headerTitle}
          headerStyle="pt-x2-large pl-x3-large"
          bgColor="tertiary"
          canCloseEsc={false}
          fixedHeight={false}
          noMarginBottom={true}
          hasFooter={false}
          contentPadding={false}
          steps={steps}
          hasSteps={true}
          currentStep={this.state.step}
          stepStyle={'ph-x3-large pt-medium'}
        >
          {this._renderStepContent()}
        </FullScreenScrollableModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  authStore: state.authStore,
  addLegalGuardianDetailWizard: state.customersStore.addLegalGuardianDetailWizard,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddLegalGuardianDetailWizard: dispatch.customersStore.setAddLegalGuardianDetailWizard,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IAddLegalGuardianWizardModalProps>()(AddLegalGuardianWizardModal));
