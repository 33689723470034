import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TmMgmtUninvitedSearch } from './tm-mgmt-uninvited-search';
import { Table } from 'design-components';
import { TmMgmtUninvitedTableColumns } from './tm-mgmt-uninvited-table-columns';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { TmMgmtTableEmpty } from '../team-management-team-tab/tm-mgmt-table-empty';
import { notification } from 'antd';
import { UserPermissionRole } from 'utilities/enum-utils';
import { TmMgmtUninvitedBatchInvite } from './tm-mgmt-uninvited-batch-invite';
import { TmMgmtUninvitedInviteModal } from './tm-mgmt-uninvited-invite-modal';

type TmMgmtUninvitedTabParams = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setModalData: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedModal: Dispatch<SetStateAction<string>>;
};

export const TmMgmtUninvitedTab = ({
  isLoading,
  setIsLoading,
  setModalData,
  setIsOpen,
  setSelectedModal,
}: TmMgmtUninvitedTabParams) => {
  const { uninvitedTeamList } = useSelector((state: IRootState) => state.teamStore);
  const { portalUser } = useSelector((state: IRootState) => state.authStore);
  const {
    teamStore: { doFetchUninvited, doInviteTeamMember },
  } = useDispatch<IRootDispatch>();
  const [selectedTeamMemberIds, setSelectedTeamMemberIds] = useState<string[]>([]);

  const [page] = useState(1);
  const [pageSize] = useState(20);
  const [pageTimestamp] = useState(new Date());
  const [openBatchInviteModal, setOpenBatchInviteModal] = useState(false);

  const refreshUninvitedList = useCallback(() => {
    doFetchUninvited({
      page: page,
      pageSize: pageSize,
      pageTimestamp: pageTimestamp,
    }).catch(() => {
      notification.error({ message: 'Error while fetching invited team members' });
    });
  }, [doFetchUninvited, page, pageSize, pageTimestamp]);

  if (uninvitedTeamList.length === 0) {
    return <TmMgmtTableEmpty />;
  }

  const onOpenModal = (selectedModal: string, modalData = '') => {
    setIsOpen(true);
    setModalData(modalData);
    setSelectedModal(selectedModal);
  };

  const onTableSelectionChange = (memberIds: string[]) => {
    setSelectedTeamMemberIds(memberIds);
  };

  const onBatchInvite = () => {
    setOpenBatchInviteModal(true);
  };

  const onModalClose = () => {
    setOpenBatchInviteModal(false);
  };

  const onModalSave = async () => {
    setIsLoading(true);

    const memberInvitePromises = selectedTeamMemberIds.map((teamMemberId) =>
      doInviteTeamMember({ supportWorkerId: teamMemberId }),
    );

    await Promise.all(memberInvitePromises)
      .then(() => {
        refreshUninvitedList();
        notification.info({
          message: `${selectedTeamMemberIds.length} invite${
            selectedTeamMemberIds.length > 1 ? 's' : ''
          } sent successfully`,
        });
        setSelectedTeamMemberIds([]);
        return;
      })
      .catch(() =>
        notification.error({
          message: `Error encountered while inviting team member${selectedTeamMemberIds.length > 1 ? 's' : ''}`,
        }),
      )
      .finally(() => {
        setOpenBatchInviteModal(false);
        setIsLoading(false);
      });
  };

  const portalUserOwnerRole = portalUser.permissions.permissionRoles.find(
    (role) => role.permissionRole === UserPermissionRole.PrimaryOwner,
  )
    ? UserPermissionRole.PrimaryOwner
    : portalUser.permissions.permissionRoles.find((role) => role.permissionRole === UserPermissionRole.Owner)
    ? UserPermissionRole.Owner
    : portalUser.permissions.permissionRoles.find((role) => role.permissionRole === UserPermissionRole.TeamManager)
    ? UserPermissionRole.TeamManager
    : 999;

  return (
    <>
      <TmMgmtUninvitedSearch
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        page={page}
        pageSize={pageSize}
        pageTimestamp={pageTimestamp}
      />
      {selectedTeamMemberIds.length > 0 && (
        <TmMgmtUninvitedBatchInvite selectedTeamMemberIds={selectedTeamMemberIds} onBatchInvite={onBatchInvite} />
      )}
      <Table
        enableSelect
        hasHorizontalBorder={false}
        columns={TmMgmtUninvitedTableColumns(portalUserOwnerRole, portalUser, onOpenModal)}
        selectedKeys={selectedTeamMemberIds}
        selectStyles={{ width: '20px', padding: '0 15px' }}
        dataSources={uninvitedTeamList.map((tm, index) => ({ ...tm, key: tm.supportWorkerId, index }))}
        onSelectionChange={onTableSelectionChange}
      />
      <TmMgmtUninvitedInviteModal
        isLoading={isLoading}
        isOpen={openBatchInviteModal}
        onClose={onModalClose}
        onSave={onModalSave}
        selectedTeamMemberCount={selectedTeamMemberIds.length}
      />
    </>
  );
};
