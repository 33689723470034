import { Calendar as CalendarIcon, Cross } from '@good/icons';
import {
  DateInput,
  DatePicker,
  DateSegment,
  Dialog,
  Group,
  Popover,
  Text,
  type DatePickerProps,
  type DateValue,
} from 'react-aria-components';
import { forwardRef } from 'react';

import * as styles from './date-picker-field.css';
import type { InputProps, LabelProps } from '../types';
import { Button } from '../button';
import { Calendar } from '../calendar';
import { FieldLabel } from '../field-label';
import { StatusMessage } from '../status-message';
import { twMerge } from '../utils';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- using interface features
export interface DatePickerFieldProps<T extends DateValue> extends DatePickerProps<T>, InputProps, LabelProps {}

export const DatePickerField = forwardRef<HTMLDivElement, DatePickerFieldProps<DateValue>>((props, ref) => {
  const {
    description,
    errorMessage,
    hideTimeZone = true,
    hourCycle = 12,
    isDisabled,
    isRequired,
    label,
    requirementIndicator,
    validationState,
    ...otherProps
  } = props;

  const isDescription = Boolean(description);
  const isErrorMessage = Boolean(errorMessage);
  const isInvalid = validationState === 'invalid';
  const showErrorMessage = isInvalid && isErrorMessage;
  const showDescription = isDescription && !showErrorMessage;
  const { container, input, segment, group } = styles.datePickerField();

  return (
    <DatePicker
      {...otherProps}
      className={container()}
      hideTimeZone={hideTimeZone}
      hourCycle={hourCycle}
      isDisabled={isDisabled}
      ref={ref}
    >
      <FieldLabel requirementIndicator={requirementIndicator} isRequired={isRequired}>
        {label}
      </FieldLabel>

      <Group className={group()}>
        <DateInput className={twMerge(input())}>
          {(dateSegment) => <DateSegment segment={dateSegment} className={segment()} />}
        </DateInput>

        <Button className="group" emphasis="quiet" isDisabled={isDisabled} size="xs" tone="neutral">
          <span className="mx-[-3.5px] inline group-aria-[expanded=true]:hidden">
            <CalendarIcon />
          </span>

          <span className="mx-[-3.5px] hidden group-aria-[expanded=true]:inline">
            <Cross />
          </span>
        </Button>
      </Group>

      <Popover>
        <Dialog>
          <Calendar className="shadow-lg" />
        </Dialog>
      </Popover>

      {showDescription && (
        <Text slot="description">
          <StatusMessage tone="neutral">{description}</StatusMessage>
        </Text>
      )}

      {showErrorMessage && (
        <Text slot="errorMessage">
          <StatusMessage tone="critical">{errorMessage}</StatusMessage>
        </Text>
      )}
    </DatePicker>
  );
});

DatePickerField.displayName = 'DatePickerField';
