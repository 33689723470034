import { Empty, Skeleton, notification } from 'antd';
import { Text } from 'common-components/typography';
import { IGroupServiceSchedule, IGroupServiceScheduleTimeSlot } from 'interfaces/service-interfaces';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState, dispatch } from 'stores/rematch/root-store';
import { useMutationExtendGroupSessionSchedule } from '../../../../stores/hooks/query-hooks/use-mutation-extend-group-session-schedule';
import { RecurringBookingPattern } from '../../../../utilities/enum-utils';
import DeleteScheduleModal from './DeleteScheduleModal';
import ExtendScheduleModal from './ExtendScheduleModal';
import ScheduleListItem from './ScheduleListItem';
import { InfiniteScroll } from 'components';

interface IGroupServiceScheduleListingPanelProps {
  groupServiceSchedules: IGroupServiceSchedule[];
  timezone: string;
  serviceId: string;
  doSearchGroupServiceSchedules: typeof dispatch.servicesStore.doSearchGroupServiceSchedules;
  setGroupServiceSchedules: typeof dispatch.servicesStore.setGroupServiceSchedules;
}

const parseRecurringPattern = (recurringPattern: RecurringBookingPattern) => {
  switch (recurringPattern) {
    case RecurringBookingPattern.EveryWeek:
      return '';
    case RecurringBookingPattern.EveryFortnight:
      return '2nd';
    case RecurringBookingPattern.EveryFourWeeks:
      return '4th';
    case RecurringBookingPattern.EveryThreeWeeks:
      return '3rd';
    default:
      return 'N/A';
  }
};

/**
 * Takes in a recurring booking pattern and parses it into its respective period in weeks.
 * This would be great to have just on the enum or as an object or as a dict
 * @param timeSlot
 */
export const parseTimeSlotPatternToWeeks = (timeSlot: IGroupServiceScheduleTimeSlot): number => {
  switch (timeSlot.recurringPattern) {
    case RecurringBookingPattern.EveryWeek:
      return 1;
    case RecurringBookingPattern.EveryFortnight:
      return 2;
    case RecurringBookingPattern.EveryThreeWeeks:
      return 3;
    case RecurringBookingPattern.EveryFourWeeks:
    case RecurringBookingPattern.Monthly:
      return 4;
    default:
      return 1;
  }
};

/**
 * Takes in a IGroupServiceSchedule and returns the longest recurring period for its time slots.
 * i.e. If given a schedule with [weekly, fortnightly, fourth weekly], this function will return 4.
 * @param scheduleItem The IGroupServiceSchedule to check
 * @default 1
 */
export const getLongestSchedulePeriod = (scheduleItem?: IGroupServiceSchedule): number => {
  if (scheduleItem === null || scheduleItem === undefined) {
    return 1;
  }

  const schedulePeriods: number[] = scheduleItem.scheduleConfig.timeSlots.map(parseTimeSlotPatternToWeeks);
  return Math.max(...schedulePeriods);
};

export const parseSchedulePeriod = (
  timezone: string,
  scheduleStartDate?: Date | moment.Moment,
  scheduleEndDate?: Date | moment.Moment,
): string => {
  if (scheduleStartDate === null || scheduleEndDate === null) {
    return '';
  }

  const startDate = moment.tz(scheduleStartDate, timezone).clone();
  const endDate = moment.tz(scheduleEndDate, timezone).clone();

  // If the two dates fall on different years, then clarify which year both are in.
  const startDateFormat = startDate.year() === endDate.year() ? 'DD MMMM' : 'DD MMMM YYYY';

  const startDateString = startDate.format(startDateFormat);
  const endDateString = endDate.format('DD MMMM YYYY');

  return `${startDateString} - ${endDateString}`;
};

export const parseTimeSlotToDisplayText = (timeSlot: IGroupServiceScheduleTimeSlot): string => {
  const recurringPattern = parseRecurringPattern(timeSlot.recurringPattern);
  const weekDay = moment.tz(timeSlot.startDateTime, timeSlot.timezone).format('dddd');
  const startTime = moment.tz(timeSlot.startDateTime, timeSlot.timezone).format('hh:mm A');
  const endTime = moment.tz(timeSlot.endDateTime, timeSlot.timezone).format('hh:mm A');
  return `${recurringPattern} ${weekDay} - ${startTime} to ${endTime}`;
};

export const parseTimeSlots = (timeSlots: IGroupServiceScheduleTimeSlot[]): string[] => {
  if (timeSlots === null || timeSlots === undefined) {
    return [];
  }

  return timeSlots.map(parseTimeSlotToDisplayText);
};

const pageSize = 20;
const pageTimestamp: Date = new Date();

const GroupServiceScheduleListingPanel: React.FC<IGroupServiceScheduleListingPanelProps> = ({
  groupServiceSchedules,
  timezone,
  serviceId,
  doSearchGroupServiceSchedules,
  setGroupServiceSchedules,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'schedules.panel' });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [isOpenDeleteScheduleModal, setIsOpenDeleteScheduleModal] = useState<boolean>(false);
  const [isOpenExtendScheduleModal, setIsOpenExtendScheduleModal] = useState<boolean>(false);
  const [selectedSchedule, setSelectedSchedule] = useState<IGroupServiceSchedule | null>(null);

  const fetchSchedules = async (newPage: number = page) => {
    setIsLoading(true);
    closeExtendScheduleModal();
    await doSearchGroupServiceSchedules({ serviceId, page: newPage, pageSize, pageTimestamp });
    setPage(newPage); // Ensure that setPage uses the correct newPage
    setIsLoading(false);
  };

  const fetchMoreSchedules = async () => {
    await fetchSchedules(page + 1);
  };

  // This method is only used to fetch fresh content.
  const loadContent = async () => setGroupServiceSchedules([]) && (await fetchSchedules(1));

  const closeDeleteScheduleModal = () => {
    setSelectedSchedule(null);
    setIsOpenDeleteScheduleModal(false);
  };

  const closeExtendScheduleModal = () => {
    setSelectedSchedule(null);
    setIsOpenExtendScheduleModal(false);
  };

  const onDeleteSchedule = (schedule: IGroupServiceSchedule) => {
    setSelectedSchedule(schedule);
    setIsOpenDeleteScheduleModal(true);
  };

  const onExtendSchedule = (schedule: IGroupServiceSchedule) => {
    setSelectedSchedule(schedule);
    setIsOpenExtendScheduleModal(true);
  };

  /**
   * Some schedules are not extendable.
   * Those in the past, or those containing 'every third week' time slots are invalid for extension.
   * This function is for conditionally allowing schedule extension on ScheduleListItems if they are eligible.
   * @param schedule
   */
  const getDisallowExtensionReason = (schedule: IGroupServiceSchedule): string | undefined => {
    const scheduleHasNoEndDate = schedule?.scheduleConfig?.scheduleEndDate === null;
    const scheduleHasEnded = moment(schedule?.scheduleConfig?.scheduleEndDate).isSameOrBefore(moment());
    const scheduleHasThreeWeeklyTimeSlot = schedule?.scheduleConfig?.timeSlots.some(
      (timeSlot: IGroupServiceScheduleTimeSlot) =>
        timeSlot.recurringPattern === RecurringBookingPattern.EveryThreeWeeks,
    );

    if (scheduleHasNoEndDate) return 'Cannot extend schedules that do not end.';
    if (scheduleHasEnded) return 'Cannot extend schedules which have ended.';
    if (scheduleHasThreeWeeklyTimeSlot) return 'Cannot extend schedules with sessions repeating every three weeks.';
    return undefined;
  };

  const { mutate: mutateSchedule, isLoading: isExtendLoading } = useMutationExtendGroupSessionSchedule({
    onSettled: async () => {
      await loadContent();
      closeExtendScheduleModal();
    },
    onSuccess: () => notification.success({ message: t('extendAction.successMessage') }),
    onError: () => notification.error({ message: t('extendAction.errorMessage') }),
  });

  useEffect(() => {
    loadContent().catch(console.error);
  }, []);

  return (
    <div className='bg-quaternary ph-medium pt-medium bordered border-standard-gray'>
      <DeleteScheduleModal
        isOpen={isOpenDeleteScheduleModal}
        serviceId={serviceId}
        serviceScheduleId={selectedSchedule?.serviceScheduleId}
        scheduleName={selectedSchedule?.scheduleName}
        schedulePeriodText={parseSchedulePeriod(
          timezone,
          selectedSchedule?.scheduleStartDate,
          selectedSchedule?.scheduleEndDate,
        )}
        displayTimeSlots={parseTimeSlots(selectedSchedule?.scheduleConfig.timeSlots)}
        resetScheduleList={loadContent}
        onClose={closeDeleteScheduleModal}
      />

      <ExtendScheduleModal
        key={selectedSchedule?.serviceScheduleId}
        isOpen={isOpenExtendScheduleModal}
        onClose={closeExtendScheduleModal}
        scheduleStartDate={moment(selectedSchedule?.scheduleConfig.scheduleStartDate)}
        scheduleEndDate={moment(selectedSchedule?.scheduleConfig.scheduleEndDate)}
        portalTimezone={timezone}
        scheduleTimezone={selectedSchedule?.timezone}
        scheduleName={selectedSchedule?.scheduleName}
        schedulePeriodText={parseSchedulePeriod(
          timezone,
          selectedSchedule?.scheduleStartDate,
          selectedSchedule?.scheduleEndDate,
        )}
        scheduleTimeSlots={selectedSchedule?.scheduleConfig.timeSlots}
        longestPeriodWeeks={getLongestSchedulePeriod(selectedSchedule)}
        submitExtendSchedule={mutateSchedule}
        isLoading={isExtendLoading}
        scheduleId={selectedSchedule?.serviceScheduleId}
        serviceId={selectedSchedule?.serviceId}
      />

      <InfiniteScroll hasMore={groupServiceSchedules.length >= page * pageSize} loadMore={fetchMoreSchedules}>
        {groupServiceSchedules.map((schedule) => (
          <ScheduleListItem
            key={'schedule' + schedule.serviceScheduleId}
            scheduleName={schedule.scheduleName}
            schedulePeriodText={parseSchedulePeriod(timezone, schedule.scheduleStartDate, schedule.scheduleEndDate)}
            displayTimeSlots={parseTimeSlots(schedule.scheduleConfig.timeSlots)}
            onDeleteSchedule={() => onDeleteSchedule(schedule)}
            onExtendSchedule={() => onExtendSchedule(schedule)}
            disallowExtensionReason={getDisallowExtensionReason(schedule)}
          />
        ))}
      </InfiniteScroll>

      {isLoading && <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />}
      {!isLoading && !groupServiceSchedules && (
        <div className='flex-1 mv-x2-large align-center flex-column'>
          <div className=''>
            <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className='mv-none' />
          </div>
          <div className='text-align-center' style={{ width: '223px' }}>
            <Text size='x2-large' color='secondary' weight='bold'>
              {' '}
              {t('emptyPrimary')}
            </Text>{' '}
            <Text color='secondary'>{t('emptySecondary')}</Text>
          </div>
        </div>
      )}
    </div>
  );
};

const mapState = (state: IRootState) => ({
  groupServiceSchedules: state.servicesStore.groupServiceSchedules,
  timezone: state.groupServiceStore.selectedGroupService && state.groupServiceStore.selectedGroupService.timezone,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doSearchGroupServiceSchedules: dispatch.servicesStore.doSearchGroupServiceSchedules,
  setGroupServiceSchedules: dispatch.servicesStore.setGroupServiceSchedules,
});

export default connect(mapState, mapDispatch)(GroupServiceScheduleListingPanel);
