import { z } from 'zod';

/********************************************************************
 * Actions
 *******************************************************************/

export type ActionType = 'approval' | 'attachment' | 'form';

// Approver //

const serverApproverSchema = z.object({
  approverUserId: z.string(),
  avatar: z.string().nullable(),
  displayName: z.string(),
});

export type ServerApprover = z.infer<typeof serverApproverSchema>;

export const approverSchema = z.object({
  id: z.string(),
  avatarUrl: z.optional(z.string().url().or(z.literal('')).or(z.null())),
  name: z.string(),
});

export type Approver = z.infer<typeof approverSchema>;

// Attachment //

const serverAttachmentSchema = z.object({
  description: z.string(),
  documentId: z.string().uuid().optional(),
  name: z.string(),
  url: z.string(),
  workflowTemplateStepAttachmentId: z.string().optional().default(''),
});

export type ServerAttachment = z.infer<typeof serverAttachmentSchema>;

export const attachmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  url: z.string(),
});

export type Attachment = z.infer<typeof attachmentSchema>;

// Form //

const serverFormSchema = z.object({
  formId: z.string(),
  formName: z.string(),
  formVersionId: z.string(),
});

export type ServerForm = z.infer<typeof serverFormSchema>;

export const formSchema = z.object({
  id: z.string(),
  name: z.string(),
  versionId: z.string(),
});

export type Form = z.infer<typeof formSchema>;

/********************************************************************
 * Steps
 *******************************************************************/

// Response //

export const serverStepSchema = z.object({
  id: z.string(),
  stepNumber: z.number(),
  name: z.string(),
  description: z.string().optional(),
  formId: z.string().optional(),
  formName: z.string().optional(),
  formVersionId: z.string().optional(),
  attachment: serverAttachmentSchema.optional(),
  stepApprovers: z.array(serverApproverSchema).optional(),
  type: z.literal('STEP').optional().default('STEP'),
  approvalType: z.literal('ANYONE').optional().default('ANYONE'),
});

export type ServerStep = z.infer<typeof serverStepSchema>;

// Step //

export const stepSchema = z.object({
  id: z.string().default(''),
  details: z.discriminatedUnion('actionType', [
    z.object({
      actionType: z.literal('approval'),
      name: z.string(),
      description: z.string(),
      approvers: z.array(approverSchema),
    }),
    z.object({
      actionType: z.literal('attachment'),
      name: z.string(),
      description: z.string(),
      approvers: z.array(approverSchema).default([]),
      attachment: attachmentSchema,
    }),
    z.object({
      actionType: z.literal('form'),
      name: z.string(),
      description: z.string(),
      approvers: z.array(approverSchema).default([]),
      form: formSchema,
    }),
  ]),
});

export type Step = z.infer<typeof stepSchema>;

/**
 * @name toStep
 * @description Turn a `ServerStep` to a `Step`.
 */
export function toStep(serverStep: ServerStep) {
  function getActionType(): ActionType {
    let isAttachment = !!serverStep?.attachment;
    let isForm = !!serverStep?.formId;
    let isApproval = serverStep?.stepApprovers?.length > 0;

    // Check if is `form` or `attachment`, then check if is `approval`,
    // otherwise default to `form`.
    let actionType = (isForm && 'form') || ((isAttachment && 'attachment') as ActionType);
    return actionType ?? ((isApproval ? 'approval' : 'form') as ActionType);
  }

  return stepSchema.parse({
    id: serverStep.id,
    details: {
      actionType: getActionType(),
      name: serverStep.name,
      description: serverStep.description,
      stepApprovers: (serverStep.stepApprovers ?? []).map((a) =>
        approverSchema.parse({
          id: a.approverUserId,
          avatarUrl: a.avatar,
          name: a.displayName,
        } as Approver),
      ),
      form: formSchema.parse({
        id: serverStep.formId,
        name: serverStep.formName,
        versionId: serverStep.formVersionId,
      } as Form),
      attachment: attachmentSchema.parse({
        id: serverStep.attachment.documentId,
        description: serverStep.description,
        name: serverStep.attachment.name,
        url: serverStep.attachment.url,
      } as Attachment),
    },
  } as Step);
}

/********************************************************************
 * Workflow
 *******************************************************************/

const serverWorkflowSchema = z.object({
  status: z.enum(['ACTIVE', 'INACTIVE', 'ARCHIVED']).default('ACTIVE'),
  version: z.number().default(1),
  workflowTemplateId: z.string().default(''),
  steps: z.array(serverStepSchema).default([]),

  name: z.string(),
  description: z.string().optional(),
  workflowTemplateType: z.literal('PLATFORM').optional().default('PLATFORM'),
  triggerType: z.literal('INCIDENT_NOTE').default('INCIDENT_NOTE'),
  isAllowApproverFlagIssue: z.boolean(),
  isNotifyWorkflowCompleted: z.boolean(),
  isNotifyWorkflowFlagged: z.boolean(),
});

const createWorkflowPayload = z.object({
  name: z.string(),
  description: z.string().optional(),
  workflowTemplateType: z.literal('PLATFORM').optional().default('PLATFORM'),
  triggerType: z.literal('INCIDENT_NOTE').default('INCIDENT_NOTE'),
  isAllowApproverFlagIssue: z.boolean(),
  isNotifyWorkflowCompleted: z.boolean(),
  isNotifyWorkflowFlagged: z.boolean(),
  steps: z.array(
    z.object({
      name: z.string(),
      description: z.string().optional(),
      stepNumber: z.number(),
      approvalType: z.literal('SPECIFIC').optional().default('SPECIFIC'),
      invalid: z.boolean().optional().default(false),
      type: z.enum(['STEP', 'TRIGGER']).optional().default('STEP'),
      // form
      formId: z.string().uuid().optional(),
      formName: z.string().optional(),
      formVersionId: z.string().uuid().optional(),
      // attachment
      attachment: z
        .object({
          file: z.object({
            uid: z.string(),
          }),
          name: z.string(),
          description: z.string().optional(),
        })
        .optional(),
      // approvers
      stepApprovers: z.array(
        z.object({
          displayName: z.string(),
          avatar: z.string().url().or(z.literal('')),
          approverUserId: z.string().uuid(),
        }),
      ),
    }),
  ),
});

export const createStepPayload = z.object({
  id: z.string(),
  stepNumber: z.number(),
  name: z.string(),
  description: z.string().optional(),
  formId: z.string().optional(),
  formName: z.string().optional(),
  formVersionId: z.string().optional(),
  attachment: serverAttachmentSchema.optional(),
  stepApprovers: z.array(serverApproverSchema).optional(),
  type: z.literal('STEP').optional().default('STEP'),
  approvalType: z.literal('ANYONE').optional().default('ANYONE'),
});

export type ServerWorkflow = z.infer<typeof serverWorkflowSchema>;

export const workflowSchema = z.object({
  id: z.string(),
  version: z.string(),
  status: z.enum(['ACTIVE', 'INACTIVE', 'ARCHIVED']).default('ACTIVE'),
  details: z.object({
    name: z.string(),
    description: z.string(),
    steps: z.array(stepSchema).default([]),
    triggerType: z.literal('INCIDENT_NOTE').default('INCIDENT_NOTE'),
  }),
  settings: z.object({
    allowFlagging: z.boolean().default(true),
    notifyOnDone: z.boolean().default(true),
    notifyOnFlagged: z.boolean().default(true),
  }),
});

export type Workflow = z.infer<typeof workflowSchema>;

/**
 * @name toWorkflow
 * @description Turn a `ServerWorkflow` to a `Workflow`
 */
export function toWorkflow(response: ServerWorkflow): Workflow {
  let workflowResponse = serverWorkflowSchema.parse(response);

  return workflowSchema.parse({
    id: workflowResponse.workflowTemplateId,
    status: workflowResponse.status,
    version: workflowResponse.version.toString(),
    details: {
      description: workflowResponse.description,
      name: workflowResponse.name,
      steps: workflowResponse.steps ?? [],
      triggerType: workflowResponse.triggerType,
    },
    settings: {
      allowFlagging: workflowResponse.isAllowApproverFlagIssue,
      notifyOnDone: workflowResponse.isNotifyWorkflowCompleted,
      notifyOnFlagged: workflowResponse.isNotifyWorkflowFlagged,
    },
  } as Workflow);
}

export function fromWorkflow(workflow: Workflow): ServerWorkflow {
  return serverWorkflowSchema.parse({
    isAllowApproverFlagIssue: workflow.settings.allowFlagging,
    isNotifyWorkflowCompleted: workflow.settings.notifyOnDone,
    isNotifyWorkflowFlagged: workflow.settings.notifyOnFlagged,

    name: workflow.details.name ?? '',
    description: workflow.details.description ?? '',
    version: Number(workflow.version),
    status: workflow.status,
    steps: workflow.details.steps.map((step, index) =>
      serverStepSchema.parse({
        id: step.id,
        stepNumber: index,
        name: step.details.name,
        formId: undefined,
        formName: undefined,
        formVersionId: undefined,
        ...(step.details.actionType === 'form' && {
          formId: step.details.form.id,
          formName: step.details.form.name,
          formVersionId: step.details.form.versionId,
        }),
        ...(step.details.actionType === 'attachment' && {
          attachment: {
            description: step.details.attachment.description,
            // documentId: step.details.attachment.id,
            name: step.details.attachment.name,
            url: step.details.attachment.url,
          },
        }),
      } as ServerStep),
    ),
  } as ServerWorkflow);
}

/********************************************************************
 * Helpers
 *******************************************************************/

export function hasFormsOrAttachments(steps: Workflow['details']['steps']) {
  return (
    steps.filter((s) => {
      if (s.details.actionType === 'form') return !!s.details.form;
      else if (s.details.actionType === 'attachment') return !!s.details.attachment;
      return false;
    }).length > 0
  );
}

export function statusDisplay(status: Workflow['status']) {
  return {
    ACTIVE: 'Active',
    ARCHIVED: 'Archived',
    INACTIVE: 'Inactive',
  }[status];
}

export function stepCountDisplay(steps: Workflow['details']['steps']) {
  let count = steps.length;
  return `${count} step${count > 1 ? 's' : ''}`;
}

export function triggerTypeDisplay(triggerType: Workflow['details']['triggerType']) {
  return {
    INCIDENT_NOTE: 'Incident note',
  }[triggerType];
}

/********************************************************************
 * Factories
 *******************************************************************/

export function createApproverFactory(approver: Approver): Approver {
  return approverSchema.parse({
    id: approver.id ?? '',
    name: approver.name ?? '',
    avatarUrl: approver.avatarUrl ?? '',
  });
}

export function createAttachmentFactory(attachment: Attachment): Attachment {
  return attachmentSchema.parse({
    id: attachment.id ?? '',
    description: attachment.description ?? '',
    name: attachment.name ?? '',
    url: attachment.url ?? '',
  });
}

export function createFormFactory(form: Form): Form {
  return formSchema.parse({
    id: form.id ?? '',
    name: form.name ?? '',
    versionId: form.versionId ?? '',
  } as Form);
}

export function createStepFactory(step: Step): Step {
  return stepSchema.parse({
    id: step.id ?? '',
    details: {
      name: step.details.name ?? '',
      description: step.details.description ?? '',
      approvers: step.details.approvers ?? [],
      ...step.details,
    },
  });
}
