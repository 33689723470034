import { IGroupServiceCustomerRatio, GroupServiceDurationRatio } from 'interfaces/service-interfaces';

export function filterHiddenCustomRatios(ratio: IGroupServiceCustomerRatio) {
  const filteredRatio = { ...ratio };

  const ratiosToShow = ['1:1', '2:1'];

  const filterRatios = <T extends GroupServiceDurationRatio | IGroupServiceCustomerRatio>(ratios: T[]): T[] =>
    ratios?.filter(
      (ratio_) =>
        ratiosToShow.includes(ratio_.teamMemberCustomerRatio) ||
        ratio_.teamMemberCustomerRatio !== ratio.teamMemberCustomerRatio,
    ) ?? [];

  filteredRatio.customRatio = filterRatios(ratio.customRatio);
  filteredRatio.durationRatios = filterRatios(ratio.durationRatios);

  return filteredRatio;
}
