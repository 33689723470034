import React from 'react';
import { GridHeader } from 'common-components/grids';
import { FieldLabel } from 'common-components/typography';
import { Col } from 'antd';

export const TmMgmtInviteTableHeader = () => (
  <GridHeader bordered containerClassName='border-width-medium border-secondary'>
    <Col span={10} className=' bg-white'>
      <FieldLabel text='sent to' />
    </Col>
    <Col span={4} className=' bg-white'>
      <FieldLabel text='sent on' />
    </Col>
    <Col span={4} className=' bg-white'>
      <FieldLabel text='invited by' />
    </Col>
    <Col span={4} className=' bg-white'>
      <FieldLabel text='invite type' />
    </Col>
    <Col span={2} className=' bg-white'>
      <FieldLabel text='' />
    </Col>
  </GridHeader>
);
