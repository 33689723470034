import { useEffect, useRef } from 'react';
import moment from 'moment';
import { UseFieldArrayAppend, UseFieldArrayRemove, UseFormReturn } from 'react-hook-form';

import { IGroupServiceCustomerRatio } from 'interfaces/service-interfaces';
import { getDurationAndUnit, convertDurationAndUnit } from './get-duration-and-unit';
import { useHasTouchedSessionStartTime } from './use-has-touched-session-start-time';
import { EditRatiosSchema } from '../schema';
import { ISessionTimesParsed } from '../types';

function getEarliestStartDateTime(initialRatios: IGroupServiceCustomerRatio) {
  return initialRatios.customRatio?.reduce((earliest, current) => {
    if (moment(current.startDateTime).isBefore(earliest)) {
      return current.startDateTime;
    }

    return earliest;
  }, initialRatios.customRatio?.[0]?.startDateTime);
}

function initialiseCustomRatios(
  initialRatios: IGroupServiceCustomerRatio,
  sessionTimes: ISessionTimesParsed,
  sessionStartTimeDifference: number,
  append: UseFieldArrayAppend<EditRatiosSchema, 'ratios'>,
) {
  initialRatios.customRatio?.forEach((customRatio) => {
    const { duration, durationUnit } = getDurationAndUnit(customRatio.startDateTime, customRatio.endDateTime);

    const shiftedStartDateTime = moment(customRatio.startDateTime).add(sessionStartTimeDifference, 'minutes').toDate();
    const shiftedEndDateTime = moment(customRatio.endDateTime).add(sessionStartTimeDifference, 'minutes').toDate();

    const isShiftedStartDateTimeAfterEndOfSession = moment(shiftedStartDateTime).isAfter(sessionTimes.endDateTime);
    if (isShiftedStartDateTimeAfterEndOfSession) {
      return;
    }

    const isShiftedEndDateTimeAfterEndOfSession = moment(shiftedEndDateTime).isAfter(sessionTimes.endDateTime);

    if (customRatio.teamMemberCustomerRatio) {
      append({
        isExactTime: true,
        teamMemberCustomerRatio: customRatio.teamMemberCustomerRatio,
        customRatio: {
          startDateTime: shiftedStartDateTime,
          endDateTime: isShiftedEndDateTimeAfterEndOfSession ? sessionTimes.endDateTime : shiftedEndDateTime,
        },
        durationRatio: {
          duration,
          unit: durationUnit,
        },
      });
    }
  });
}

function initialiseDurationRatios(
  initialRatios: IGroupServiceCustomerRatio,
  sessionTimes: ISessionTimesParsed,
  append: UseFieldArrayAppend<EditRatiosSchema, 'ratios'>,
) {
  initialRatios.durationRatios?.forEach((durationRatio) => {
    const { duration, durationUnit } = convertDurationAndUnit(durationRatio.duration);

    append({
      isExactTime: false,
      teamMemberCustomerRatio: durationRatio.teamMemberCustomerRatio,
      customRatio: {
        startDateTime: sessionTimes.startDateTime,
        endDateTime: moment(sessionTimes.startDateTime).add(durationRatio.duration, 'minutes').toDate(),
      },
      durationRatio: {
        duration,
        unit: durationUnit,
      },
    });
  });
}

function setInitialRatioToForm(
  form: UseFormReturn<EditRatiosSchema>,
  append: UseFieldArrayAppend<EditRatiosSchema, 'ratios'>,
  remove: UseFieldArrayRemove,
  initialRatios: IGroupServiceCustomerRatio,
  sessionTimes: ISessionTimesParsed,
  hasTouchedSessionStartTime: boolean,
) {
  if (initialRatios.teamMemberCustomerRatio) {
    form.setValue(`teamMemberCustomerRatio`, initialRatios.teamMemberCustomerRatio);
  }

  remove();

  const earliestStartDateTime = getEarliestStartDateTime(initialRatios);

  let sessionStartTimeDifference = 0;
  if (hasTouchedSessionStartTime) {
    sessionStartTimeDifference = moment(sessionTimes.startDateTime).diff(moment(earliestStartDateTime), 'minutes') ?? 0;
  }

  initialiseCustomRatios(initialRatios, sessionTimes, sessionStartTimeDifference, append);
  initialiseDurationRatios(initialRatios, sessionTimes, append);
}

export function useInitialiseForm(
  form: UseFormReturn<EditRatiosSchema>,
  append: UseFieldArrayAppend<EditRatiosSchema, 'ratios'>,
  remove: UseFieldArrayRemove,
  initialRatios: IGroupServiceCustomerRatio | null,
  sessionTimes: ISessionTimesParsed | null,
) {
  const hasInitialised = useRef(false);
  const hasTouchedSessionStartTime = useHasTouchedSessionStartTime(sessionTimes);

  useEffect(() => {
    hasInitialised.current = false;
  }, [JSON.stringify(initialRatios), sessionTimes?.startDateTime, sessionTimes?.endDateTime]);

  useEffect(() => {
    if (!hasInitialised.current && initialRatios && sessionTimes) {
      hasInitialised.current = true;
      setInitialRatioToForm(form, append, remove, initialRatios, sessionTimes, hasTouchedSessionStartTime);
    }
  }, [initialRatios, sessionTimes?.startDateTime, sessionTimes?.endDateTime]);
}
