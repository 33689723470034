import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const LineHeight = forwardRef(function LineHeight(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 3A.75.75 0 0 1 4 2.25h16a.75.75 0 0 1 0 1.5H4A.75.75 0 0 1 3.25 3Zm0 18a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75Zm9.42-13.335a.75.75 0 0 0-1.34 0l-2.5 5-1.5 3a.75.75 0 1 0 1.34.67l1.294-2.585h4.072l1.293 2.585a.75.75 0 1 0 1.342-.67l-1.5-3-2.5-5ZM12 9.677l1.287 2.573h-2.574L12 9.677Z"
        fill="currentColor"
      />
    </Icon>
  );
});
