import React, { Component } from 'react';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment';
import { SubTitle, Text, Title } from 'common-components/typography';
import _ from 'lodash';
import { Icon, Button, Row, Col } from 'antd';
import { FullScreenModalFooter } from 'common-components/modal/FullScreenModal';
import { ServiceAgreementDetails } from './ServiceAgreementDetails';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { ServiceAgreementStatus } from 'utilities/enum-utils';

interface IServiceAgreementHistoryListModalProps {
  serviceAgreementId: string;
  isOpen: boolean;
  customerServiceAgreementHistories: any;
  selectedCustomerServiceAgreementHistory: any;
  openViewModal: any;
  onClose: any;
  companyDataLite: typeof state.companyStore.companyDataLite;
  doGetServiceAgreementHistories: typeof dispatch.customersStore.doGetServiceAgreementHistories;
  doGetServiceAgreementHistoryDetail: typeof dispatch.customersStore.doGetServiceAgreementHistoryDetail;
  onClickActionModal: (item, action, value) => void;
}

interface IServiceAgreementHistoryListModalState {
  isLoading: boolean;
  step: number;
}

class ServiceAgreementHistoryListModal extends Component<
  IServiceAgreementHistoryListModalProps,
  IServiceAgreementHistoryListModalState
> {
  state = {
    isLoading: false,
    step: 1,
  };

  private _closeModal = () => {
    if (this.state.step === 1) {
      this.setState({
        isLoading: false,
        step: 1,
      });
      this.props.onClose();
    } else {
      this.setState({ step: 1 });
    }
  };

  private _onClickViewServiceAgreementHistory = async (userServiceAgreementHistoryId) => {
    await this.props.doGetServiceAgreementHistoryDetail({
      serviceAgreementId: this.props.serviceAgreementId,
      userServiceAgreementHistoryId,
    });

    this.setState({ step: 2 });
  };

  async componentDidMount() {
    await this.props.doGetServiceAgreementHistories({
      serviceAgreementId: this.props.serviceAgreementId,
    });
  }

  async componentDidUpdate(
    prevProps: Readonly<IServiceAgreementHistoryListModalProps>,
    prevState: Readonly<IServiceAgreementHistoryListModalState>,
    snapshot?: any,
  ) {
    if (prevProps.serviceAgreementId !== this.props.serviceAgreementId) {
      await this.props.doGetServiceAgreementHistories({
        serviceAgreementId: this.props.serviceAgreementId,
      });
    }
  }

  render() {
    const { isOpen, customerServiceAgreementHistories, selectedCustomerServiceAgreementHistory, openViewModal } =
      this.props;

    const { step, isLoading } = this.state;
    return (
      <FullScreenScrollableModal
        isOpen={isOpen}
        onClose={this._closeModal}
        width={'large'}
        headerStyle={'pv-large ph-medium'}
        hasFooter
        footerContent={
          step === 2 ? (
            <FullScreenModalFooter>
              <Button
                // todo: change url
                href={`/pdf?type=service-agreement&agreement=${
                  selectedCustomerServiceAgreementHistory
                    ? selectedCustomerServiceAgreementHistory.userServiceAgreementId
                    : ''
                }&historyId=${
                  selectedCustomerServiceAgreementHistory
                    ? selectedCustomerServiceAgreementHistory.userServiceAgreementHistoryId
                    : ''
                }`}
                target="_blank"
                icon={'eye'}
                type={'default'}
                className={'text-color-blue-action border-blue-action mr-medium'}
              >
                Download as PDF
              </Button>
              <Button
                type={'default'}
                onClick={this._closeModal}
                className={'text-color-blue-action border-blue-action mr-medium'}
              >
                Back
              </Button>
            </FullScreenModalFooter>
          ) : null
        }
        canClose={!isLoading}
      >
        {step === 1 && (
          <div>
            <div>
              <Title level={2} weight="regular" lineHeight={100} className="mb-x-large">
                Version History
              </Title>
            </div>
            <Text>
              Each time a service agreement is edited we store a version of the Service Agreement for auditing purposes.
              View all versions of this Service Agreement below.
            </Text>
            <Row className="bordered-bottom border-standard-gray p-small mt-large">
              <Col span={2} />
              <Col span={4}>
                <SubTitle>DATE</SubTitle>
              </Col>
              <Col span={4}>
                <SubTitle>AUTHOR</SubTitle>
              </Col>
              <Col span={8}>
                <SubTitle>CHANGES MADE</SubTitle>
              </Col>
              <Col span={4}>
                <SubTitle>Signed?</SubTitle>
              </Col>
              <Col span={2} />
            </Row>
            {_.map(customerServiceAgreementHistories, (history) => {
              return (
                <div>
                  <Row className="text-weight-bold bordered-bottom border-standard-gray p-small pv-medium">
                    <Col span={2}>
                      {history.description === 'New Service Agreement created' && (
                        <Icon className="text-color-blue-action" type="file-add" />
                      )}
                      {history.description !== 'New Service Agreement created' && <Icon type="file-exclamation" />}
                    </Col>
                    <Col span={4}>{moment(history.createdOn).format('DD/MM/YYYY')}</Col>
                    <Col span={4}>
                      {history.createdByFirstName} {history.createdByLastName}
                    </Col>
                    <Col span={8}>{history.description}</Col>
                    <Col span={4}>{history.signedStatus === ServiceAgreementStatus.SIGNED && 'Signed'}</Col>
                    <Col className="text-color-blue-action" span={2}>
                      <div
                        className="cursor-pointer"
                        onClick={() => this._onClickViewServiceAgreementHistory(history.userServiceAgreementHistoryId)}
                      >
                        View...
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        )}
        {step === 2 && (
          <div className="mb-medium">
            <div>
              <Title level={2} weight="regular" lineHeight={100} className="mb-x-large">
                Version History of Service Agreement
              </Title>
              <Text>
                This version of the customer service agreement was{' '}
                {selectedCustomerServiceAgreementHistory.description === 'New Service Agreement created'
                  ? 'created'
                  : 'edited'}{' '}
                by{' '}
                <b>
                  {selectedCustomerServiceAgreementHistory.createdByFirstName}{' '}
                  {selectedCustomerServiceAgreementHistory.createdByLastName}
                </b>{' '}
                on {moment(selectedCustomerServiceAgreementHistory.updatedOn).format('DD/MM/YYYY')}
              </Text>
              {selectedCustomerServiceAgreementHistory ? (
                <ServiceAgreementDetails
                  item={selectedCustomerServiceAgreementHistory}
                  openViewModal={openViewModal}
                  isHistory
                  hasEditPermission={false}
                  onClickActionModal={this.props.onClickActionModal}
                  companyData={this.props.companyDataLite}
                  customerId={selectedCustomerServiceAgreementHistory.userId}
                />
              ) : (
                <SpinningLoader size={50} message={'Editing...'} />
              )}
            </div>
          </div>
        )}
      </FullScreenScrollableModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  customerServiceAgreementHistories: state.customersStore.customerServiceAgreementHistories,
  selectedCustomerServiceAgreementHistory: state.customersStore.selectedCustomerServiceAgreementHistory,
  companyDataLite: state.companyStore.companyDataLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetServiceAgreementHistories: dispatch.customersStore.doGetServiceAgreementHistories,
  doGetServiceAgreementHistoryDetail: dispatch.customersStore.doGetServiceAgreementHistoryDetail,
});

export default connect(mapState, mapDispatch)(ServiceAgreementHistoryListModal);
