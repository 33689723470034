import React, { PureComponent } from 'react';
import { Text } from 'common-components/typography';

interface SubSectionHeaderTextProps {
  text?: string;
}

class SubSectionHeaderText extends PureComponent<SubSectionHeaderTextProps, any> {
  render() {
    const { text } = this.props;

    return (
      <div>
        <Text
          weight={'regular'}
          size={'x-small'}
          className="letter-spacing-widest"
          style={{ textTransform: 'uppercase' }}
          color="tertiary"
        >
          {text}
        </Text>
      </div>
    );
  }
}

export default SubSectionHeaderText;
