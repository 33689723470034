import { Card } from '@blueprintjs/core';
import { Col, Icon, Row } from 'antd';
import { GhostButton } from 'common-components/buttons';
import { Text, Title } from 'common-components/typography';
import { IActivityGroup } from 'interfaces/service-interfaces';
import _ from 'lodash';
import moment from 'moment';
import React, { memo, useCallback, useState } from 'react';
import EditActivityGroupRowItemModal from '../modals/EditActivityGroupRowItemModal';
import RemoveActivityGroupRowItemModal from '../modals/RemoveActivityGroupRowItemModal';

interface IActivityGroupRowItemProps {
  item: IActivityGroup;
  scheduleEndDate: any;
  scheduleStartDate: any;
  onChange: (list: IActivityGroup[]) => void;
  listItem: IActivityGroup[];
  selectedIndex: number;
  isInSchedule?: boolean;
  recurringPattern?: string;
}

const ActivityGroupRowItem = ({
  item,
  scheduleEndDate,
  scheduleStartDate,
  listItem,
  onChange,
  selectedIndex,
  isInSchedule,
  recurringPattern,
}: IActivityGroupRowItemProps) => {
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const { name, description, startDateTime, endDateTime } = item;

  const _onConfirmRemoveActivityItem = useCallback(() => {
    onChange(
      _.filter(listItem, (activity: IActivityGroup, index: number) =>
        activity.serviceActivityGroupId
          ? item.serviceActivityGroupId !== activity.serviceActivityGroupId
          : index !== selectedIndex,
      ),
    );
    setIsRemoveModalOpen(false);
  }, [listItem]);

  const _onConfirmEditActivityItem = useCallback(
    (newActivity: IActivityGroup) => {
      listItem = _.map(listItem, (activity, index: number) => {
        if (
          activity.serviceActivityGroupId
            ? item.serviceActivityGroupId === activity.serviceActivityGroupId
            : index === selectedIndex
        ) {
          return { ...activity, ...newActivity };
        }
        return activity;
      });

      onChange(listItem);
      setIsEditModalOpen(false);
    },
    [listItem],
  );

  const isDateNotMatchScheduleTime =
    !moment(startDateTime).isSame(scheduleStartDate) || !moment(endDateTime).isSame(scheduleEndDate);

  const isDateWithinScheduleTime =
    moment(startDateTime).isSameOrAfter(scheduleStartDate) && moment(endDateTime).isSameOrBefore(scheduleEndDate);

  const isStartDateSameEndDate = moment(startDateTime).startOf('day').isSame(moment(endDateTime).startOf('day'));

  return (
    <>
      <RemoveActivityGroupRowItemModal
        activityGroupName={name}
        onClose={() => setIsRemoveModalOpen(false)}
        onConfirm={_onConfirmRemoveActivityItem}
        isOpen={isRemoveModalOpen}
      />
      {isEditModalOpen && (
        <EditActivityGroupRowItemModal
          item={item}
          onClose={() => setIsEditModalOpen(false)}
          isOpen={isEditModalOpen}
          scheduleEndDate={scheduleEndDate}
          scheduleStartDate={scheduleStartDate}
          onConfirm={(newActivity) => _onConfirmEditActivityItem(newActivity)}
          isInSchedule={isInSchedule}
          recurringPattern={recurringPattern}
        />
      )}

      <Card className="mt-small p-small">
        <Row className="mb-x-small">
          <Col span={16}>
            <Title level={4} weight="700">
              {name}
            </Title>
          </Col>
          <Col span={8} className="text-align-right">
            <GhostButton onClick={() => setIsEditModalOpen(true)}>Edit</GhostButton>
            <GhostButton color="red" onClick={() => setIsRemoveModalOpen(true)}>
              Remove
            </GhostButton>
          </Col>
        </Row>
        <Row className="mb-x-small">
          <Col span={3}>
            <Text color="secondary">When</Text>
          </Col>
          <Col span={21}>
            {!isInSchedule ? (
              <>
                {isDateNotMatchScheduleTime ? (
                  isStartDateSameEndDate ? (
                    <Text weight="400" className="block">
                      {moment(startDateTime).format('ddd, DD MMMM')}
                      <br></br>
                      {moment(startDateTime).format('hh:mm A')} – {moment(endDateTime).format('hh:mm A')}
                    </Text>
                  ) : (
                    <Text weight="400" className="block">
                      {moment(startDateTime).format('ddd, DD MMMM')} – {moment(endDateTime).format('ddd, DD MMMM')}
                      <br></br>
                      {moment(startDateTime).format('hh:mm A')} – {moment(endDateTime).format('hh:mm A')}
                    </Text>
                  )
                ) : (
                  <Text color="secondary">Same as session time</Text>
                )}
              </>
            ) : (
              <Text weight="400" className="block">
                {moment(startDateTime).format('dddd')}
                <br></br>
                {moment(startDateTime).format('hh:mm A')} – {moment(endDateTime).format('hh:mm A')}
              </Text>
            )}

            {!isDateWithinScheduleTime && (
              <>
                <Icon type="warning" theme="filled" className="mr-small text-color-warning-orange" />
                <Text type="warning">Activity time doesn’t fit within the session timeframe</Text>
              </>
            )}
          </Col>
        </Row>
        <Row className="mb-x-small">
          <Col span={3}>
            <Text color="secondary">Description</Text>
          </Col>
          <Col span={21}>
            <Text color="secondary">{description}</Text>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default memo(ActivityGroupRowItem);
