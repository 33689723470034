import { notification } from 'antd';
import { Box, Button, Modal, Radio, RadioGroup, Text } from 'design-components';
import { SearchField } from 'design-components/search-field';
import { Textarea } from 'design-components/textarea';
import { ICareInfo, ICustomer, IDisability } from 'interfaces/customer-interfaces';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';
import { AlertLevel, CareInformationType } from 'utilities/enum-utils';

interface IAddEditPermanentConditionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCustomer: ICustomer;
  selectedCareInfo?: ICareInfo;
  selectedDisability?: IDisability;
  isNew: boolean;
  isNotSave?: boolean;
  disabilitiesExisted?: IDisability[];
  onSubmitPermanentCondition?: (disability: IDisability) => void;
}

interface ICondition {
  disabilityName: string;
  disabilityLookupId: string;
  tagId: string;
}

const AddEditPermanentConditionsModal = (props: IAddEditPermanentConditionsModalProps): JSX.Element => {
  const {
    isOpen,
    onClose,
    isNew,
    selectedCareInfo,
    selectedDisability,
    selectedCustomer,
    isNotSave,
    onSubmitPermanentCondition,
    disabilitiesExisted = [],
  } = props;
  const dispatch = useDispatch<IRootDispatch>();

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showConditionList, setShowConditionList] = useState<boolean>(false);
  const [conditionList, setConditionList] = useState<ICondition[]>([]);
  const [conditionSelected, setConditionSelected] = useState<ICondition>();
  const [searchText, setSearchText] = useState<string>('');
  const [level, setLevel] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const onSearchText = async (text: string) => {
    const result = await dispatch.customersStore.doFetchCustomerDisabilityList({
      userId: selectedCustomer.userId,
      searchString: text,
    });

    setShowConditionList(true);
    setConditionList(result);
    setIsSearching(false);
  };

  const debounceSearch = useCallback(debounce(onSearchText, 500), []);

  const handleSearchCondition = (value: string) => {
    setSearchText(value);

    if (value.length >= 1) {
      setIsSearching(true);
      debounceSearch(value);
    } else if (value.length === 0) {
      setConditionList([]);
      setConditionSelected(null);
      setShowConditionList(false);
    }
  };

  const handleBlurSearchCondition = () => {
    if (!conditionSelected) {
      setShowConditionList(false);
      if (!isSearching) {
        setSearchText('');
      }
    }
    setShowConditionList(conditionList.length > 0);
  };

  const handleChangeAlertLevel = (value) => {
    setLevel(value);
  };

  const handleClearCondition = () => {
    setSearchText('');
    setConditionSelected(null);
    setConditionList([]);
  };

  const handleChangeCondition = (selectedCondition: ICondition) => {
    setSearchText(selectedCondition.disabilityName);
    setConditionSelected(selectedCondition);
    setConditionList([]);
    setShowConditionList(false);
  };

  const handleChangeDescription = (value: string) => {
    setDescription(value);
  };

  const handleSubmit = async () => {
    if (isNotSave) {
      const payload: IDisability = {
        alertLevel: AlertLevel[level],
        description: description,
        disabilityName: conditionSelected?.disabilityName,
        disabilityLookupId: conditionSelected?.disabilityLookupId,
        tagId: conditionSelected?.tagId,
      };

      if (!isNew) {
        payload.tagId = selectedDisability.tagId;
        payload.disabilityLookupId = selectedDisability.disabilityLookupId;
        payload.disabilityName = selectedDisability.disabilityName;
      }

      onSubmitPermanentCondition(payload);
    } else {
      setIsSubmitting(true);
      try {
        const payload: {
          careInformationType: CareInformationType;
          alertLevel: AlertLevel;
          description: string;
          userId: string;
          disabilityLookupId?: string;
          careInformationId?: string;
        } = {
          careInformationType: CareInformationType.PERMANENT,
          alertLevel: AlertLevel[level],
          description: description,
          userId: selectedCustomer.userId,
        };
        if (isNew) {
          payload.disabilityLookupId = conditionSelected.disabilityLookupId;
          await dispatch.customersStore.doCreateCustomerCareInfo(payload);
        } else {
          payload.careInformationId = selectedCareInfo.careInformationId;
          await dispatch.customersStore.doUpdateCustomerCareInfo(payload);
        }
        notification.success({ message: `Disability ${isNew ? 'added' : 'updated'} successfully.` });
      } catch (e) {
        notification.error({ message: 'Oops something went wrong! Please try again.' });
      }
    }

    setIsSearching(false);
    onClose();
  };

  useEffect(() => {
    const alertLevel = selectedCareInfo?.alertLevel || selectedDisability?.alertLevel;
    const description = selectedCareInfo?.description || selectedDisability?.description;

    setShowConditionList(false);
    setIsSubmitting(false);
    setConditionList([]);
    setSearchText('');
    setLevel(alertLevel ? AlertLevel[alertLevel] : '');
    setDescription(description || '');
  }, [isOpen, selectedCareInfo, selectedDisability]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Text size="small" fontWeight="$bold !important" color="$black">
          {isNew ? 'Add' : 'Edit'} a permanent condition
        </Text>
      }
      footer={
        <Box display="flex" justifyContent="flex-end" paddingX="$xsmall">
          <Button kind="accent" emphasis="quiet" size="large" marginRight={16} onClick={onClose}>
            Cancel
          </Button>
          <Button
            kind="accent"
            emphasis="filled"
            size="large"
            isDisabled={!(conditionSelected || selectedCareInfo || selectedDisability) || !level}
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            {isNew ? 'Add condition' : 'Apply changes'}
          </Button>
        </Box>
      }
      bodyStyles={{ padding: '0 16px 4px 16px', overflow: 'hidden' }}
    >
      {isNew ? (
        <Text color="$muted" size="small" display="block" marginX="$xsmall" marginBottom={10} fontWeight="$normal">
          Search and select a condition from the list provided
        </Text>
      ) : (
        <Text
          size="small"
          fontWeight="$semibold !important"
          color="$black"
          display="block"
          marginX="$xsmall"
          marginBottom={10}
        >
          Permanent condition
        </Text>
      )}
      <Box marginBottom="$medium">
        {isNew ? (
          <SearchField
            placeholder="Enter condition name"
            isLoading={isSearching}
            onChange={handleSearchCondition}
            onClear={handleClearCondition}
            onBlur={handleBlurSearchCondition}
            value={searchText}
            padding="40px !important"
          />
        ) : (
          <Text color="$bodyDark2" display="block" marginX="$xsmall">
            {selectedCareInfo?.name || selectedDisability?.disabilityName}
          </Text>
        )}
      </Box>
      {showConditionList && conditionList.length > 0 && (
        <Box maxHeight={170} overflow="auto">
          {conditionList
            .filter((condition) => !disabilitiesExisted.find((disability) => condition.tagId === disability.tagId))
            .map((condition) => (
              <div
                key={condition.disabilityLookupId}
                className="pv-small ph-small cursor-pointer hover-bg-tertiary"
                onClick={() => handleChangeCondition(condition)}
              >
                {condition.disabilityName}
              </div>
            ))}
        </Box>
      )}
      <Box padding="18px" paddingX="$xsmall">
        <Text size="small" fontWeight="$semibold !important" color="$black" display="block" marginBottom="$medium">
          Select one alert level
        </Text>
        <RadioGroup value={level} onChange={handleChangeAlertLevel}>
          <Radio value="HIGH" label="High" />
          <Box marginY="$small">
            <Radio value="MEDIUM" label="Medium" />
          </Box>
          <Radio value="LOW" label="Low" />
        </RadioGroup>
      </Box>
      <Text size="small" fontWeight="$bold !important" display="block" color="$black" marginBottom="$xsmall">
        Description
      </Text>
      <Textarea
        placeholder="Describe or leave a note about this condition"
        value={description}
        onChange={handleChangeDescription}
      />
    </Modal>
  );
};

export default AddEditPermanentConditionsModal;
