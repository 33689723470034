import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Heading, Text, DateFormatter, I18nProvider } from '@good/components';
import { ArrowRight, Plus } from '@good/icons';
import { SubscriptionBillingDetails } from 'interfaces/sil-service-agreement-interfaces';
import ndisHelper from 'variables/data/ndis-helper';
import { claimTypeOptions } from './subscription-billing-form';
import { InLineIconButton } from './shared/inline-icon-button';

const formatClaimTypeLabel = (claimTypeValue: string) =>
  claimTypeOptions.find( (claimTypeOption) => claimTypeOption.value === claimTypeValue)?.label;

type SubscriptionBillingSectionProps = {
  subscriptionBillingDetails?: SubscriptionBillingDetails | null;
  onCreate: () => void;
}

const SubscriptionDetailsCard = ({
  subscriptionBillingDetails,
  startDate,
  endDate,
  onEdit
}: {
  subscriptionBillingDetails: SubscriptionBillingDetails;
  startDate: Date;
  endDate: Date;
  onEdit: () => void;
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'serviceAgreements.silServiceAgreement.fundingDetails.subscriptionDetails',
  });
  const silServiceRange = (
    <>
      <DateFormatter value={startDate} /> - <DateFormatter value={endDate} />
    </>
  );

  return (
    <div className="flex flex-col p-4">
      <I18nProvider locale="en-AU">
        <Text size="lg" className="font-bold">
          {subscriptionBillingDetails.frequency} from {silServiceRange}
        </Text>
      </I18nProvider>
      {subscriptionBillingDetails.lineItems?.map((billingItem, index) => (
        <div className="flex flex-col pt-8" key={`billingItem-${index + 1}`}>
          <Text size="md" className="font-semibold">
            {`${ndisHelper.getBySupportItemNumber(billingItem.supportItemNumber).SupportItem} (${billingItem.supportItemNumber})`}
          </Text>
          <Text size="sm">{t('billingItemContent.price', { price: parseFloat(billingItem.price).toFixed(2) })}</Text>
          <Text size="sm">{t('billingItemContent.quantity', { quantity: parseFloat(billingItem.qty).toFixed(2) })}</Text>
          <Text size="sm">{t('billingItemContent.claimType', { claimType: formatClaimTypeLabel(billingItem.claimType) })}</Text>
          <Text size="sm">
            {t('billingItemContent.managementMethod', { managementMethod: `${billingItem.paymentMethod}` })}
          </Text>
        </div>
      ))}
      <div className="flex flex-row">
        <InLineIconButton className="mt-6" size="md" label={t('actions.edit')} icon={<ArrowRight />} onClick={onEdit} />
      </div>
    </div>
  );
};

export const SubscriptionBillingSection = ({
  subscriptionBillingDetails,
  onCreate,
}: SubscriptionBillingSectionProps) => {
  const { t } = useTranslation('', {
    keyPrefix: 'serviceAgreements.silServiceAgreement.fundingDetails.subscriptionDetails',
  });

  return (
    <div className="flex flex-row">
      <div className="flex w-1/3 flex-col">
        <Header size="sm" className=" mt-6">
          <Heading>{t('heading')}</Heading>
        </Header>
        <Text>{t('content')}</Text>
      </div>
      <div
        className="mb-1 mr-1 min-h-[8rem] w-full grow bg-gray-light-3"
        style={{
          boxSizing: 'border-box',
          borderRadius: '8px',
        }}
      >
        {subscriptionBillingDetails?.lineItems?.length ? (
          <SubscriptionDetailsCard
            subscriptionBillingDetails={subscriptionBillingDetails}
            startDate={subscriptionBillingDetails.startDate}
            endDate={subscriptionBillingDetails.endDate}
            onEdit={onCreate}
          />
        ) : (
          <InLineIconButton
            className="ml-6 mt-6"
            size="md"
            label={t('actions.add')}
            icon={<Plus />}
            onClick={onCreate}
          />
        )}
      </div>
    </div>
  );
};
