import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const MicSlash = forwardRef(function MicSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25A3.75 3.75 0 0 0 8.25 5v.5a.75.75 0 0 0 1.5 0V5a2.25 2.25 0 1 1 4.5 0v6a.75.75 0 1 0 1.5 0V5A3.75 3.75 0 0 0 12 1.25ZM3.53 2.47a.75.75 0 0 0-1.06 1.06l5.78 5.78V12A3.75 3.75 0 0 0 12 15.75h2.69l1.226 1.227c-1.187.875-2.652 1.273-3.916 1.273-1.35 0-2.926-.454-4.15-1.456-1.2-.982-2.1-2.519-2.1-4.794a.75.75 0 0 0-1.5 0c0 2.725 1.1 4.688 2.65 5.956 1.3 1.063 2.888 1.617 4.35 1.758V22a.75.75 0 1 0 1.5 0v-2.287c1.41-.136 2.938-.656 4.21-1.646a.709.709 0 0 0 .026-.02l3.484 3.483a.75.75 0 0 0 1.06-1.06l-6-6-6-6-6-6ZM9.75 12v-1.19l3.44 3.44H12A2.25 2.25 0 0 1 9.75 12Zm10 0a.75.75 0 0 0-1.5 0 7.3 7.3 0 0 1-.216 1.814.75.75 0 1 0 1.453.372c.171-.67.263-1.398.263-2.186Z"
        fill="currentColor"
      />
    </Icon>
  );
});
