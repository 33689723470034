import { notification, Skeleton } from 'antd';
import * as H from 'history';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { SubscriptionStatus } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import SubscriptionStatusCard from 'views/account-management/components/SubscriptionStatusCard';
import { CANCEL_SUBSCRIPTION_LINKS, MAIL_SUBJECT_HEADERS } from '../../../utils/constants';
import CancelledSubscriptionBanner from './components/CancelledSubscriptionBanner';
import NoAccessTeamManagementModal from './components/NoAccessTeamManagementModal';
import BillingEmailCard from './components/BillingEmail/BillingEmailCard';
import SupportCard from './components/SupportCard';
import { SimpleGrid } from '@good/ui/core';

interface IOverviewPanelProps {
  history: H.History;
}

const OverviewPanel: React.FC<IOverviewPanelProps> = ({ history }) => {
  const dispatch = useDispatch<IRootDispatch>();

  const { portalUser, subscriptionOverview } = useSelector((state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    subscriptionOverview: state.subscriptionStore.subscriptionOverview,
  }));

  const [loading, setLoading] = useState<boolean>(false);
  const [visibleNoAccessTeamManagement, setVisibleNoAccessTeamManagement] = useState(false);

  const getSubscriptionOverview = async () => {
    setLoading(true);
    try {
      await dispatch.subscriptionStore.doGetSubscriptionOverview();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e });
    }
    setLoading(false);
  };

  const onClickHyperLink = (status: SubscriptionStatus) => {
    if (status === SubscriptionStatus.CANCELED) {
      window.open(`mailto:${CANCEL_SUBSCRIPTION_LINKS.SALES_MAIL_LINK}?subject=${MAIL_SUBJECT_HEADERS.REACTIVATE}`);
      return;
    }

    const hasPermission = PermissionUtils.validatePermission(
      'ViewTeamManagement',
      portalUser.permissions.permissionRoles,
    );

    if (hasPermission) {
      history.push('/account/team-management');
    } else {
      setVisibleNoAccessTeamManagement(true);
    }
  };

  useEffect(() => {
    getSubscriptionOverview();
  }, []);

  return loading || !subscriptionOverview ? (
    <div>
      <Skeleton active title />
      <Skeleton active title />
    </div>
  ) : (
    <>
      {subscriptionOverview.alertBannerWarning?.isAlertBannerWarning && (
        <CancelledSubscriptionBanner
          alertBannerWarning={subscriptionOverview.alertBannerWarning}
          portalUser={portalUser}
        />
      )}

      <SimpleGrid cols={{ base: 1, lg: 2 }} spacing='lg' mt='lg'>
        <SubscriptionStatusCard
          subscriptionStatusInfo={subscriptionOverview.subscriptionInfo}
          currentScreen='Subscription'
          onClickHyperLink={onClickHyperLink}
        />

        {/* <ActivityReportsCard subscriptionOverview={subscriptionOverview} /> */}

        <BillingEmailCard
          serviceProviderId={portalUser!.serviceProviderId}
          billingEmail={subscriptionOverview.billingEmail}
        />

        <SupportCard />
      </SimpleGrid>

      <NoAccessTeamManagementModal
        visible={visibleNoAccessTeamManagement}
        onClose={() => setVisibleNoAccessTeamManagement(false)}
      />
    </>
  );
};

export default OverviewPanel;
