import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Avatar, Col, Divider, Form, notification, Row, Select } from 'antd';

import CommonUtils from 'utilities/common-utils';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';
import ActionModal from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Text, SubTitle, FieldLabel } from 'common-components/typography';
import TimeInput from 'common-components/time-input/TimeInput';
import { ISession } from 'interfaces/session-interfaces';
import DatePicker from 'react-datepicker';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { ShiftSlotStatus, WorkTypeCategory } from 'utilities/enum-utils';
import { useFetchWorkTypes } from 'stores/hooks/query-hooks/use-query-fetch-work-types-per-category';

interface IMarkAsOnLeaveModalModalProps {
  isOpen: boolean;
  onClose: (targetFlag, refreshShiftSlots?: boolean) => void;
  shiftSlot: IShiftSlot;
  session: ISession;
}

const MarkAsOnLeaveModal = (props: IMarkAsOnLeaveModalModalProps) => {
  const dispatch = useDispatch<IRootDispatch>();
  const { isOpen, onClose, session, shiftSlot } = props;
  const [hasError, setHasError] = useState<boolean>(false);
  const [leaveId, setLeaveId] = React.useState('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const defaultStartTime = moment(
    moment.tz(shiftSlot?.shiftStartDateTime, session.timezone).format('YYYY-MM-DD HH:mm'),
  );
  const currentTime = moment.tz(moment(new Date()).format('YYYY-MM-DD HH:mm'), session.timezone);

  const [startDateTime, setStartDateTime] = useState<moment.Moment>(
    moment.tz(moment(shiftSlot?.shiftStartDateTime).format('YYYY-MM-DD HH:mm'), session.timezone),
  );

  const [endDateTime, setEndDateTime] = useState<moment.Moment>(
    moment.tz(moment(shiftSlot?.shiftEndDateTime).format('YYYY-MM-DD HH:mm'), session.timezone),
  );

  const initialStartTime =
    shiftSlot?.shiftSlotStatus === ShiftSlotStatus.CHECKED_IN
      ? startDateTime
      : moment.tz(moment(shiftSlot?.shiftStartDateTime).format('YYYY-MM-DD HH:mm'), session.timezone);

  const initialEndTime = moment.tz(moment(shiftSlot?.shiftEndDateTime).format('YYYY-MM-DD HH:mm'), session.timezone);

  const { data: leaveData, isLoading: leaveLoading } = useFetchWorkTypes({
    workTypeCategory: WorkTypeCategory.Leave,
    state: 'ACTIVE',
  });
  const serviceId = session.serviceId;
  const serviceDateTimeId = session.serviceDateTimeId;
  const supportWorkerAttendanceId = shiftSlot?.supportWorkerAttendanceId;
  const timezone = session.timezone;

  const editShiftTimeRequest = {
    serviceId,
    serviceDateTimeId,
    supportWorkerAttendanceId,
    startDateTime: moment.tz(moment(startDateTime).format('YYYY-MM-DD HH:mm'), session.timezone),
    endDateTime: moment.tz(moment(endDateTime).format('YYYY-MM-DD HH:mm'), session.timezone),
    keepTeamMember: true,
    workTypeId: leaveId,
  };

  const handleStartDateTime = (value) => {
    setStartDateTime(CommonUtils.formatCeilingDateTime(value));
    if (endDateTime.isSameOrBefore(value)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  const handleEndDateTime = (value) => {
    setEndDateTime(CommonUtils.formatCeilingDateTime(value));
    if (startDateTime.isSameOrAfter(value)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  const handleClose = () => {
    onClose({ targetFlag: 'isMarkAsOnLeaveOpen' }, true);
  };

  const validateLeaveIsActive = (leaveId: string) => {
    const leave = leaveData?.find((item) => item.workTypeId === leaveId);
    return leave?.state === 'ACTIVE';
  };

  const handleSave = async () => {
    if (leaveId) {
      if (!validateLeaveIsActive(leaveId)) {
        notification.error({ message: 'Leave type is archived' });
        return;
      }
      setIsSubmitting(true);

      await createDuplicateShift();
      if (shiftSlot?.shiftSlotStatus === ShiftSlotStatus.CHECKED_IN) {
        await checkedInActions();
      } else {
        await confirmedActions();
      }
      setIsSubmitting(false);
      handleClose();
    } else {
      notification.error({ message: 'Please select a type of leave' });
    }
  };

  const createDuplicateShift = async () => {
    const numberOfSlots = 1;
    await dispatch.groupServiceStore.doAddShiftSlots({
      serviceId,
      serviceDateTimeId,
      numberOfSlots,
      startDateTime: moment.tz(moment(shiftSlot?.shiftStartDateTime).format('YYYY-MM-DD HH:mm'), session.timezone),
      endDateTime: initialEndTime,
    });
  };

  const numberOfSlots = 1;

  const finishShiftrequest = {
    serviceId,
    serviceDateTimeId,
    supportWorkerAttendanceId,
    endDateTime: startDateTime,
  };

  const confirmedActions = async () => {
    await dispatch.teamStore.doUpdateWorkTypes({
      serviceId,
      serviceDateTimeId,
      supportWorkerAttendanceId,
      workTypeId: leaveId,
      timezone,
      startDateTime,
      endDateTime,
    });
    await dispatch.groupServiceStore.doEditShiftTimes(editShiftTimeRequest);
  };

  const checkedInActions = async () => {
    await dispatch.groupServiceStore.doFinishShift(finishShiftrequest);
    await dispatch.groupServiceStore.doAddShiftSlots({
      serviceId,
      serviceDateTimeId,
      numberOfSlots,
      startDateTime: initialStartTime,
      endDateTime: initialEndTime,
      shiftSlotStatus: ShiftSlotStatus.ON_LEAVE,
      workTypeId: leaveId,
      supportWorkerIds: [shiftSlot?.supportWorkerId],
    });
  };

  useEffect(() => {
    if (isOpen) {
      shiftSlot?.shiftSlotStatus === ShiftSlotStatus.CHECKED_IN
        ? setEndDateTime(currentTime)
        : setStartDateTime(defaultStartTime);

      setEndDateTime(moment(moment.tz(shiftSlot?.shiftEndDateTime, session.timezone).format('YYYY-MM-DD HH:mm')));
    }
  }, [isOpen]);

  const handleChange = (value: string) => {
    setLeaveId(value);
  };

  const Duration = () => {
    const duration = CommonUtils.formatDuration(startDateTime, endDateTime);
    let durationTime;
    let durationLabel;

    if (duration.hours) {
      durationTime = duration.hours.value;
      durationLabel = duration.hours.label;
    } else if (duration.minutes) {
      durationTime = duration.minutes.value;
      durationLabel = duration.minutes.label;
    }

    return (
      <>
        <FieldLabel text={'DURATION'} />
        <Text>
          <b>{durationTime}</b> {durationLabel}
        </Text>
      </>
    );
  };

  return (
    <ActionModal
      isOpen={isOpen}
      title="Mark as on leave"
      showCloseButton={true}
      onClose={handleClose}
      width="large"
      canCloseOutside={!isSubmitting}
    >
      <Row>
        <Text>Leave will show up on team member&apos;s timesheet once it&apos;s approved</Text>
      </Row>
      <Row>
        <Col span={10}>
          <div className="align-left" style={{ marginTop: '15px' }}>
            <FieldLabel text={'TEAM MEMBER'} />
            <Avatar icon="user" className="mr-small" src={shiftSlot?.attachmentUrl} shape="square" />
            <Text size="medium">{`${shiftSlot?.firstName} ${shiftSlot?.lastName}`}</Text>
          </div>
        </Col>
      </Row>
      <div style={{ marginTop: '20px', marginBottom: '-20px' }}>
        <Row>
          <Form wrapperCol={{ span: 10 }} layout="vertical">
            <Form.Item>
              <FieldLabel text={'LEAVE TYPE'} />
              <Select placeholder="Select leave type" onChange={handleChange}>
                {leaveLoading ? (
                  <SpinningLoader size={20} message={'Fetching leave...'} />
                ) : (
                  leaveData &&
                  leaveData.length > 0 &&
                  leaveData.map((item) => (
                    <Select.Option key={item.workTypeId} value={item.workTypeId}>
                      {item.name}
                    </Select.Option>
                  ))
                )}
              </Select>
            </Form.Item>
          </Form>
        </Row>
      </div>
      <Divider />
      <Row>
        <Row>
          <div className="flex">
            <Col span={6}>
              <SubTitle>LEAVE START DATE</SubTitle>
              <div style={{ width: '150px' }}>
                <DatePicker
                  selected={startDateTime.toDate()}
                  isClearable={false}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(event) => handleStartDateTime(event)}
                  className="gh-datepicker mr-medium rounded"
                  calendarClassName="gh-datepicker-calendar"
                />
              </div>
            </Col>
            <Col span={6}>
              <div style={{ width: '150px' }}>
                <SubTitle>LEAVE START TIME</SubTitle>
                <TimeInput size="large" value={startDateTime} onChange={(event) => handleStartDateTime(event)} />
              </div>
              {hasError && <Text color="red">Start time cannot be same or after end time</Text>}
            </Col>
            <Col span={6}>
              <SubTitle>LEAVE END DATE</SubTitle>
              <div style={{ width: '150px' }}>
                <DatePicker
                  className="gh-datepicker mr-medium rounded"
                  selected={endDateTime.toDate()}
                  isClearable={false}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(event) => handleEndDateTime(event)}
                  calendarClassName="gh-datepicker-calendar"
                />
              </div>
            </Col>
            <Col span={6}>
              <div style={{ width: '150px' }}>
                <SubTitle>LEAVE END TIME</SubTitle>
                <TimeInput size="large" value={endDateTime} onChange={(event) => handleEndDateTime(event)} />
              </div>
            </Col>
          </div>
        </Row>
      </Row>
      <div style={{ marginTop: '20px' }}>
        <Row>
          <Duration />
        </Row>
      </div>
      <div className="mt-x3-large flex justify-end">
        <SecondaryButton size="large" className="mr-medium" onClick={handleClose}>
          Cancel
        </SecondaryButton>
        <PrimaryButton size="large" onClick={handleSave} disabled={hasError} loading={isSubmitting}>
          Mark as on Leave
        </PrimaryButton>
      </div>
    </ActionModal>
  );
};

export default React.memo(MarkAsOnLeaveModal);
