import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Modal, notification } from 'antd';

import asyncDelay from 'utilities/asyncDelay';
import firebaseApp from 'stores/firebase-app';
import { Title } from 'common-components/typography';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { BookingType, WorkflowTemplateTriggerType, WorkflowTriggerModuleType } from 'utilities/enum-utils';

import EmbeddedFormPanel from './components/EmbeddedFormPanel';
import SelectWorkflowPanel from './components/SelectWorkflowPanel';
import TriggerWorkflowManually from './components/TriggerWorkflowManually';
import CustomerTeamInvolvedPanel from './components/CustomerMemberInvolvedPanel';

interface IProps {
  timezone?: string;
  isManual?: boolean;
  isOpen: boolean;
  onClose: (isReload?: boolean) => void;
  screenData?: {
    noteData?: {
      isIncident: boolean;
      visibleType?: any;
      noteContent?: any;
      body?: any;
      bookingId?: string;
      customerId: string;
      [key: string]: any;
    };
    involved?: {
      customers: [];
      workers: [];
      [key: string]: any;
    };
    isEdit?: boolean;
    [key: string]: any;
  };
  moduleType?: string;

  onSaveNoteAction?: () => void;

  workflowSelected: typeof state.workflowStore.workflowSelected;
  workflowTemplates: typeof state.workflowStore.workflowTemplates;

  // Add note
  doAddBookingNote: typeof dispatch.bookingsStore.doAddNote;
  doAddGroupBookingNote: typeof dispatch.groupBookingsStore.doAddGroupBookingNote;
  doAddGroupServiceSessionNote: typeof dispatch.groupServiceStore.doAddGroupServiceSessionNote;
  doAddCustomerNotes: typeof dispatch.customersStore.doAddCustomerNotes;
  doTriggerManualWorkflow: typeof dispatch.workflowStore.doTriggerManualWorkflow;

  // Edit note
  doEditBookingNote: typeof dispatch.bookingsStore.doEditNote;
  doEditCustomerNote: typeof dispatch.customersStore.doEditCustomerNote;
  doEditGroupServiceSessionNote: typeof dispatch.groupServiceStore.doEditGroupServiceSessionNote;
  doEditGroupBookingNote: typeof dispatch.groupBookingsStore.doEditGroupBookingNote;

  // Fetch note
  doFetchSingleBooking: typeof dispatch.bookingsStore.doFetchSingleBooking;
  dofetchCustomerNotes: typeof dispatch.customersStore.dofetchCustomerNotes;
  resetCustomerNotesPageInfo: typeof dispatch.customersStore.resetCustomerNotesPageInfo;
}

interface IState {
  step: number;
  isLoading: boolean;
}

class AddIncidentNoteModal extends PureComponent<IProps, IState> {
  state = {
    step: 0,
    isLoading: false,
  };

  private _data: { [key: string]: any } = {};
  private _validStep?: any = null;
  private _steps =
    this.props.isManual === true
      ? [
          {
            name: 'workflowTemplateId',
            title: 'Trigger workflow manually',
            component: <SelectWorkflowPanel isManual={this.props.isManual} />,
          },
          {
            name: 'confirm',
            title: 'Trigger workflow manually',
            component: <TriggerWorkflowManually />,
          },
        ]
      : [
          { name: 'workflowTemplateId', title: 'Record an incident', component: <SelectWorkflowPanel /> },
          { name: 'formData', component: <EmbeddedFormPanel /> },
          { name: 'involved', title: 'Customers and team members involved', component: <CustomerTeamInvolvedPanel /> },
        ];

  private _closeNoteModal = (isReload = false) => {
    this._data = {};
    this.setState({ step: 0, isLoading: false });
    this.props.onClose(isReload);
  };

  private _onAddIncidentNote = async (isIncident = false) => {
    const {
      moduleType,
      screenData,
      onSaveNoteAction,
      doAddBookingNote,
      doAddGroupBookingNote,
      doAddGroupServiceSessionNote,
      doAddCustomerNotes,
      doEditBookingNote,
      doEditGroupBookingNote,
      doEditGroupServiceSessionNote,
      doEditCustomerNote,
      doFetchSingleBooking,
      dofetchCustomerNotes,
      resetCustomerNotesPageInfo,
    } = this.props;
    try {
      let result = null;
      const data = screenData.noteData;
      this.setState({ ...this.state, isLoading: true });

      if (isIncident) {
        await this._nextStep(false, this._steps[this.state.step].name);
        data.workflow = {
          triggerType: WorkflowTemplateTriggerType.INCIDENT,
          workflowTemplateId: this._data.workflowTemplateId,
          formData: this._data.formData,
          customers: (this._data.involved.customers || []).map((e) => e.customerId),
          workers: (this._data.involved.workers || []).map((e) => e.workerId),
        };
      }

      if (screenData.isEdit) {
        if (moduleType === BookingType.BOOKING || moduleType === BookingType.ACTIVITY_RECORD) {
          result = await doEditBookingNote(data);

          if (screenData.noteData.customerUserId) {
            resetCustomerNotesPageInfo({});
            await dofetchCustomerNotes({
              customerUserId: screenData.noteData.customerUserId,
            });
          } else {
            await doFetchSingleBooking({
              bookingId: screenData.noteData.bookingId,
            });
          }
        } else if (moduleType === WorkflowTriggerModuleType.GROUP_BOOKING) {
          result = await doEditGroupBookingNote(data);
        } else if (moduleType === WorkflowTriggerModuleType.SESSION_NOTE) {
          result = await doEditGroupServiceSessionNote(data);
        } else {
          result = await doEditCustomerNote(data);
        }
      } else {
        if (moduleType === BookingType.BOOKING || moduleType === BookingType.ACTIVITY_RECORD) {
          result = await doAddBookingNote(data);
        } else if (moduleType === WorkflowTriggerModuleType.GROUP_BOOKING) {
          result = await doAddGroupBookingNote(data);
        } else if (moduleType === WorkflowTriggerModuleType.SESSION_NOTE) {
          result = await doAddGroupServiceSessionNote(data);
        } else {
          result = await doAddCustomerNotes(data);
        }
      }

      if (onSaveNoteAction) {
        await asyncDelay(1000);
        await onSaveNoteAction();
      }

      if (!_.isEmpty(screenData.selectedFiles)) {
        await this._uploadFile(result);
      }
    } catch (e) {
      console.log(e);
      notification.error({ message: 'Add note failed! Please try again.' });
    }
    this.setState({ isLoading: false });
    this._closeNoteModal(true);
  };

  private _onManuallyAddWorkflow = async () => {
    const { doTriggerManualWorkflow } = this.props;
    try {
      this.setState({ ...this.state, isLoading: true });
      await doTriggerManualWorkflow({
        triggerType: WorkflowTemplateTriggerType.MANUAL,
        workflowTemplateId: this._data.workflowTemplateId,
      });
    } catch (e) {
      notification.error({ message: 'Manually add a workflow fail! Please try again.' });
    }
    this.setState({ isLoading: false });
    this._closeNoteModal(true);
  };

  private _nextStep = async (isNext?: boolean, nameField?: string) => {
    const { workflowSelected, isManual } = this.props;

    const data = this._validStep != null && (await this._validStep().catch(() => undefined));
    if ([null, undefined].includes(data)) return;
    this._data.workflowTemplate = this.state.step === 0 ? data : this._data?.workflowTemplate;
    const workflowTemplate = this._data.workflowTemplate;
    this._data = {
      ...this._data,
      [nameField]: data,
      workflowTemplateId: this._data?.workflowTemplate?.workflowTemplateId,
    };

    if (!isManual && nameField === this._steps[0].name) {
      this._data.formData = data;
      this._steps[1].title = workflowTemplate.name;
    }
    if (workflowSelected && workflowSelected.triggerType === WorkflowTemplateTriggerType.MANUAL) {
      await this._onManuallyAddWorkflow();
      return;
    }

    if (isNext === true) {
      let step = this.state.step + 1;
      if (!isManual && workflowTemplate && !workflowTemplate.formId) {
        ++step;
      }
      this.setState({ step });
      return;
    }
  };

  private _uploadFile = async (result) => {
    //need to be specify which type uploading for : BOOKING or CUSTOMER
    const { screenData } = this.props;
    const { noteId, uploadDocumentsResult } = result;
    const { uploadBucketUrl, documentIds } = uploadDocumentsResult;

    this.setState({ isLoading: false });
    const promise = [];
    let numberOfUploadedFile = 0;
    try {
      for (let index = 0; index < screenData.selectedFiles.length; index++) {
        const metadata = {
          customMetadata: {
            documentId: documentIds[index],
            serviceProviderId: screenData.portalUser.serviceProviderId,
            attendanceId: screenData.noteData.bookingId,
            noteId: noteId,
          },
        };

        const storageRef = ref(firebaseApp.storage, `${uploadBucketUrl}/${screenData.selectedFiles[index].name}`);
        const uploadFile = uploadBytesResumable(storageRef, screenData.selectedFiles[index], metadata);

        uploadFile.on(
          'state_changed',
          () => void 0,
          (error) => {
            notification.error({ message: 'Upload failed! Please try again.', description: error });
          },
          () => {
            ++numberOfUploadedFile;
          },
        );

        promise.push(uploadFile);
      }

      this.setState({ isLoading: true });
      await Promise.all(promise)
        .then(() => {
          notification.success({
            message:
              numberOfUploadedFile === 1
                ? 'Attachment is currently being scanned.'
                : `${numberOfUploadedFile} attachments are currently being scanned.`,
          });
        })
        .catch((err) =>
          notification.error({ message: 'All attachments upload failed! Please try again.', description: err }),
        );

      await new Promise((r) => setTimeout(r, 1000)); // nothing but smooth UX
      this.setState({ isLoading: false });
    } catch (e) {
      notification.error({ message: 'Upload failed! Please try again.', description: 'Failed to upload files.' });
      console.log(e, 'error catch');
      this.setState({ isLoading: false });
    }
  };

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.screenData && this.props.screenData && this.props.screenData !== prevProps.screenData) {
      this._data.involved = { ...this.props.screenData.involved };
    }
  }

  render() {
    const { isLoading } = this.state;
    const { isManual, isOpen, screenData } = this.props;

    const currentStep = this._steps.find((e, index) => e != null && index == this.state.step);
    return (
      <Modal
        visible={isOpen}
        title={
          currentStep == null ? (
            ''
          ) : (
            <Title level={4} className="mv-none">
              {currentStep.title}
            </Title>
          )
        }
        width="720px"
        onCancel={() => this._closeNoteModal(false)}
        footer={null}
      >
        {this._steps
          .filter((e, index) => e != null && index == this.state.step)
          .map((e, index) =>
            React.cloneElement(e.component, {
              key: index,
              onGetValues: (valid) => (this._validStep = valid),
              initData: this._data[e.name] || undefined,
              timezone: this.props.timezone,
            }),
          )}
        <ActionModalFooter>
          <div style={{ marginTop: 27 }} className="flex justify-end">
            <SecondaryButton size="large" className="mr-medium" onClick={this._closeNoteModal} loading={isLoading}>
              Cancel
            </SecondaryButton>

            {this.state.step == 0 && isManual !== true && (
              <SecondaryButton
                size="large"
                className="mr-medium"
                loading={isLoading}
                onClick={() => this._onAddIncidentNote(false)}
              >
                {`${screenData.isEdit ? 'Edit' : 'Add'} note without triggering workflow`}
              </SecondaryButton>
            )}

            {this.state.step < this._steps.length - 1 ? (
              <PrimaryButton
                size="large"
                className="mr-medium"
                loading={isLoading}
                onClick={() =>
                  this._nextStep(this.state.step < this._steps.length - 1, this._steps[this.state.step].name)
                }
              >
                {isManual ? 'Select workflow' : 'Next'}
              </PrimaryButton>
            ) : !isManual ? (
              <PrimaryButton
                size="large"
                className="mr-medium"
                onClick={() => this._onAddIncidentNote(true)}
                loading={isLoading}
              >
                Finish
              </PrimaryButton>
            ) : (
              <PrimaryButton
                size="large"
                className="mr-medium"
                onClick={() => this._onManuallyAddWorkflow()}
                loading={isLoading}
              >
                Start workflow
              </PrimaryButton>
            )}
          </div>
        </ActionModalFooter>
      </Modal>
    );
  }
}

const mapState = (state: IRootState) => ({
  workflowSelected: state.workflowStore.workflowSelected,
  workflowTemplates: state.workflowStore.workflowTemplates,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddBookingNote: dispatch.bookingsStore.doAddNote,
  doAddGroupBookingNote: dispatch.groupBookingsStore.doAddGroupBookingNote,
  doAddGroupServiceSessionNote: dispatch.groupServiceStore.doAddGroupServiceSessionNote,
  doAddCustomerNotes: dispatch.customersStore.doAddCustomerNotes,
  doEditBookingNote: dispatch.bookingsStore.doEditNote,
  doEditCustomerNote: dispatch.customersStore.doEditCustomerNote,
  doEditGroupServiceSessionNote: dispatch.groupServiceStore.doEditGroupServiceSessionNote,
  doEditGroupBookingNote: dispatch.groupBookingsStore.doEditGroupBookingNote,
  doFetchSingleBooking: dispatch.bookingsStore.doFetchSingleBooking,
  dofetchCustomerNotes: dispatch.customersStore.dofetchCustomerNotes,
  resetCustomerNotesPageInfo: dispatch.customersStore.resetCustomerNotesPageInfo,
  doTriggerManualWorkflow: dispatch.workflowStore.doTriggerManualWorkflow,
});

export default connect(mapState, mapDispatch)(AddIncidentNoteModal);
