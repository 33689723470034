import { Popover2 } from '@blueprintjs/popover2';
import { notification } from 'antd';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu/index';
import { IconButton, PrimaryButton } from 'common-components/buttons';
import { IExportFileStyle } from 'interfaces/account-interfaces';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';
import ChangeDefaultSuccessModal from 'views/account-management/components/FinanceExportConfigurationModals/ChangeDefaultSuccessModal';
import ConfirmChangeDefaultModal from 'views/account-management/components/FinanceExportConfigurationModals/ConfirmChangeDefaultModal';
import SelectDefaultFileExportModal from 'views/account-management/components/FinanceExportConfigurationModals/SelectDefaultFileExportModal';
import { ExportFileConfigType } from 'views/account-management/utils/account-management-constants';
import { BulkActionExtensions, PAYMENT_TAB } from '../../utils/constants';
import ActionSelectItem from './details/ActionSelectItem';

interface IProps {
  tabType?: string;
  batchId?: string;
  options?: IExportFileStyle[];
  downloadExportDefaultCsv?: (styleKey: string) => void;
}

const BulkActionButton = (props: IProps) => {
  const { options, tabType, downloadExportDefaultCsv } = props;

  const dispatch = useDispatch<IRootDispatch>();

  const { portalUser, financeStyleState, timeSheetStyleState, defaultExportFileState } = useSelector(
    (state: IRootState) => ({
      selectedBatchLineItems: state.billingsStore.selectedBatchLineItems,
      shiftHoursFilter: state.billingsStore.shiftHoursFilter,
      portalUser: state.authStore.portalUser,
      financeStyleState: state.accountStore.financeStyleState,
      timeSheetStyleState: state.accountStore.timeSheetStyleState,
      defaultExportFileState: state.accountStore.defaultExportFileState,
    }),
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedStyleKey, setSelectedStyleKey] = useState<string>('');
  const [isSelectModalVisible, setIsSelectModalVisible] = useState(false);
  const [isSuccessChangedModalVisible, setIsSuccessChangedModalVisible] = useState(false);
  const [isConfirmChangeModalVisible, setIsConfirmChangeModalVisible] = useState(false);

  const { borderRadius, borderColor, sizeButton, titleButton, positionPopover, iconSize, icon, hasBorder } =
    BulkActionExtensions[tabType];

  const isShiftHoursTab = tabType === PAYMENT_TAB.SHIFT_HOURS;
  const hasPermission = PermissionUtils.validatePermission(
    'SetDefaultFinanceFileExportConfiguration',
    portalUser.permissions.permissionRoles,
  );
  const styleDefault = isShiftHoursTab ? defaultExportFileState.payrollSystem : defaultExportFileState.accountSystem;
  const configType = isShiftHoursTab ? ExportFileConfigType.PAYROLL_SYSTEM : ExportFileConfigType.ACCOUNT_SYSTEM;
  const isSetDefaultFirstTime =
    hasPermission && (isShiftHoursTab ? !timeSheetStyleState.useDefaultStyle : !financeStyleState.useDefaultStyle);
  const buttonName = !isSetDefaultFirstTime && !isShiftHoursTab ? `Export ${styleDefault.name} file` : titleButton;

  const onCheckVisibleChangeDefaultModal = (option: IExportFileStyle) => {
    if (hasPermission && !option.isDefault) {
      return isShiftHoursTab
        ? timeSheetStyleState.isShowTimeSheetSetupModal
        : financeStyleState.isShowFinanceExportSetupModal;
    }
    return false;
  };

  const sortOptions = () => {
    const optionDefault = options?.filter((item) => item.isDefault);
    const other = options?.filter((item) => !item.isDefault);
    return [...optionDefault, ...other];
  };

  const onFetchLatestOptions = async () => {
    try {
      dispatch.accountStore.doGetFinanceExportDefaultConfigs();
      if (isShiftHoursTab) {
        dispatch.accountStore.doGetPayrollSystemList();
      } else {
        dispatch.accountStore.doGetAccountingSystemList();
      }
    } catch (e) {
      notification.error({ message: e?.message || 'Oops, something went wrong! Please try again.' });
    }
  };

  const onSubmitGoodHumanDefault = async (styleKey: string, isSetDefaultType: boolean) => {
    downloadExportDefaultCsv(styleKey);
    if (isSetDefaultType) dispatch.accountStore.doUpdateDefaultPayrollSystem({ styleKey });
  };

  const onSubmit = async (styleKey: string, isSetDefaultType = false) => {
    await onSubmitGoodHumanDefault(styleKey, isSetDefaultType);

    if (isSetDefaultType) {
      setIsSuccessChangedModalVisible(true);
    }
  };

  const onDefaultExport = async () => {
    setIsProcessing(true);
    await onSubmit(styleDefault.key);
    setIsProcessing(false);
  };

  const onVisibleConfirmChangeDefaultModal = async (styleKey: string) => {
    await onSubmit(styleKey);
    setSelectedStyleKey(styleKey);
    setIsConfirmChangeModalVisible(true);
  };

  const onVisibleSelectDefaultModal = () => {
    setSelectedStyleKey(styleDefault.key);
    setIsSelectModalVisible(true);
  };

  const onSaveDefaultConfig = async () => {
    await onSubmit(selectedStyleKey, true);
    setIsSelectModalVisible(false);
  };

  const onChangeDefault = async (data, isShowConfirm: boolean) => {
    try {
      setIsProcessing(isShowConfirm);
      if (isShiftHoursTab) {
        await dispatch.accountStore.doUpdateDefaultPayrollSystem({
          ...data,
          isShowTimeSheetSetupModal: isShowConfirm,
        });
      } else {
        await dispatch.accountStore.doUpdateDefaultAccountingSystem({
          ...data,
          isShowFinanceExportSetupModal: isShowConfirm,
        });
      }
      if (isShowConfirm) {
        setIsSuccessChangedModalVisible(true);
      } else {
        onFetchLatestOptions();
      }
    } catch (error) {
      notification.error({ message: error?.message || 'Oops, something went wrong! Please try again.' });
    } finally {
      setIsProcessing(false);
      setIsConfirmChangeModalVisible(false);
    }
  };

  return (
    <>
      <div className={`flex-row ${borderRadius}`}>
        {isShiftHoursTab ? (
          <PrimaryButton
            loading={isProcessing}
            className={`${borderRadius} ${borderColor}`}
            size={sizeButton}
            onClick={onDefaultExport}
          >
            Export as CSV
          </PrimaryButton>
        ) : (
          <>
            <PrimaryButton
              loading={isProcessing}
              className={`${borderRadius}-left ${borderColor}`}
              size={sizeButton}
              onClick={isSetDefaultFirstTime ? onVisibleSelectDefaultModal : onDefaultExport}
            >
              {buttonName}
            </PrimaryButton>
            <div style={{ width: 1 }}></div>
            <Popover2
              disabled={isProcessing}
              content={
                <ActionMenu style={{ width: 230 }}>
                  {sortOptions().map((option: IExportFileStyle) => {
                    return (
                      <ActionMenuItem
                        key={option.key}
                        text={
                          <ActionSelectItem
                            option={option}
                            hasPermission={hasPermission}
                            positionPopover={positionPopover}
                          />
                        }
                        onClick={
                          onCheckVisibleChangeDefaultModal(option)
                            ? () => onVisibleConfirmChangeDefaultModal(option.key)
                            : () => onSubmit(option.key)
                        }
                        className={`${option?.isDefault ? 'bg-quaternary' : ''} ph-medium`}
                      />
                    );
                  })}
                </ActionMenu>
              }
              position={positionPopover}
            >
              <IconButton
                icon={icon}
                size={iconSize}
                bordered={hasBorder}
                className={`${borderRadius}-right ${borderColor} drop-arrow-bulk-action`}
              />
            </Popover2>
          </>
        )}
      </div>

      <SelectDefaultFileExportModal
        type={configType}
        isSetDefaultFirstTime
        isOpen={isSelectModalVisible}
        styleOptions={options}
        selectedStyle={selectedStyleKey}
        onClose={() => setIsSelectModalVisible(false)}
        onSelect={setSelectedStyleKey}
        onSave={onSaveDefaultConfig}
      />

      <ConfirmChangeDefaultModal
        type={configType}
        isUpdating={isProcessing}
        hasAskAgainButton
        isOpen={isConfirmChangeModalVisible}
        configName={options.find((option) => option.key === selectedStyleKey)?.name}
        onClose={() => setIsConfirmChangeModalVisible(false)}
        onClickDoNotAskMe={() => onChangeDefault({}, false)}
        onConfirm={() => onChangeDefault({ styleKey: selectedStyleKey }, true)}
      />

      <ChangeDefaultSuccessModal
        type={configType}
        isSetDefaultFirstTime={isSetDefaultFirstTime}
        isOpen={isSuccessChangedModalVisible}
        onClose={() => {
          setIsSuccessChangedModalVisible(false);
          onFetchLatestOptions();
        }}
      />
    </>
  );
};

export default BulkActionButton;
