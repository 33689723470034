import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';

export function LoadingDuplicatesSection() {
  return (
    <div className="flex-column p-x-large align-center">
      <div className="mb-medium">
        <Icon type={'loading'} /> <br />
      </div>
      <Text size="x2-large">Checking for duplicates</Text> <br />
      <Text size="large">One moment...</Text>
    </div>
  );
}
