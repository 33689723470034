import type { CSS } from '../../theme';

export const reset: CSS = {
  boxSizing: 'border-box',
  outline: 'none',
};

export const dropdownHeader: CSS = {
  height: '$size700',
  backgroundColor: '$white',

  display: 'flex',
  fontFamily: '$primary',
  fontSize: '$font75',
  alignItems: 'center',
  padding: '$space200',

  borderTopLeftRadius: '$medium',
  borderTopRightRadius: '$medium',
};
