import { Heading } from '@good/components';
import { CheckCircle, CrossCircle } from '@good/icons';
import { Stack, Text } from '@goodhuman-me/components';
import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendScheduleCopyOptions } from 'stores/hooks/query-hooks/use-mutation-extend-group-session-schedule';
import { Alert } from '../../../../design-components';
import { IGroupServiceScheduleTimeSlot } from '../../../../interfaces/service-interfaces';
import { ExtendScheduleFormValue } from './ExtendScheduleModal';
import {
  parseSchedulePeriod,
  parseTimeSlotPatternToWeeks,
  parseTimeSlotToDisplayText,
  parseTimeSlots,
} from './GroupServiceScheduleListingPanel';
import ScheduleListItem from './ScheduleListItem';

interface ExtendScheduleReviewProps {
  existingSchedulePeriodText: string;
  existingScheduleTimeSlots: IGroupServiceScheduleTimeSlot[];
  timezone: string;
  formData: ExtendScheduleFormValue;
  sessionOptions: ExtendScheduleCopyOptions;
}

/**
 * Function will take in a time slot and a date range given in moments, and return the number of times that time slot
 * will occur.
 *
 * i.e. If there is a time slot that runs weekly on Monday, and given the range between the first and last Mondays of
 * a month, this function will return 4.
 * @param timeSlot The time slot to get occurrences for
 * @param startDate The start of the time range to check
 * @param endDate The end of the time range to check
 * @param timezone The timezone the time slot is set in
 */
export const getTimeSlotOccurrencesInRange = (
  timeSlot: IGroupServiceScheduleTimeSlot,
  startDate: moment.Moment,
  endDate: moment.Moment,
  timezone: string,
): number => {
  // Start from the first instance of that particular day in the date range
  const dayOfWeek: number = moment.tz(timeSlot.startDateTime, timezone).isoWeekday();

  // Get the first day of the week that falls within the date range.
  const firstIsoWeekday: moment.Moment = startDate.clone().isoWeekday(dayOfWeek);

  // Moment.isoWeekday() gets the first instance of that day of the week in that week going backwards
  // So it could fall outside the date range. Need to check that explicitly.
  const firstValidDay: moment.Moment = firstIsoWeekday.isSameOrAfter(startDate)
    ? firstIsoWeekday
    : firstIsoWeekday.clone().add(1, 'weeks');

  // Then find the total count of weeks between there and the end date
  const totalRangeWeeks: number = endDate.clone().diff(firstValidDay, 'weeks');

  // Finally divide by the cadence, flooring the value (there's no such thing as a half occurrence).
  // Then count the first occurrence as well.
  const cadenceWeeks: number = parseTimeSlotPatternToWeeks(timeSlot);
  return Math.floor(totalRangeWeeks / cadenceWeeks) + 1;
};

const SingleCopyOptionPreview: React.FC<{ label: string; value: boolean }> = ({ label, value }) => {
  const statusIcon = value ? <CheckCircle /> : <CrossCircle />;
  // TODO: Replace this with Tailwind or the inbuilt CSS variables.
  const labelColor = value ? '#2B7B66' : '#706F6D';

  return (
    <li style={{ color: labelColor, margin: 'auto', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      {statusIcon}
      <div>{label}</div>
    </li>
  );
};

export const CopyOptionsPreview: React.FC<{ sessionOptions: ExtendScheduleCopyOptions }> = ({ sessionOptions }) => {
  const { t } = useTranslation('', { keyPrefix: 'schedules.review' });

  return (
    <div>
      <Heading size="sm">Items to be copied into new sessions</Heading>
      <ul>
        <SingleCopyOptionPreview label={t('bookingsPreview')} value={sessionOptions.copyBookings} />
        <SingleCopyOptionPreview label={t('activityGroupsPreview')} value={sessionOptions.copyActivityGroups} />
        <SingleCopyOptionPreview label={t('shiftsPreview')} value={sessionOptions.copyShifts} />
      </ul>
    </div>
  );
};

const ExtendScheduleReview: React.FC<ExtendScheduleReviewProps> = ({
  existingSchedulePeriodText,
  existingScheduleTimeSlots,
  timezone,
  formData,
  sessionOptions,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'schedules.review' });
  const { startDate, endDate }: ExtendScheduleFormValue = formData;

  const newDisplayTimeSlots: string[] = existingScheduleTimeSlots.map((ts) => {
    const occurrences: number = getTimeSlotOccurrencesInRange(ts, startDate, endDate, timezone);
    const displayTextBase: string = parseTimeSlotToDisplayText(ts);

    return `${displayTextBase} (${occurrences} sessions)`;
  });

  return (
    <Stack gap="$space200">
      <Text>{t('primaryCallToAction')}</Text>
      <ScheduleListItem
        scheduleName={t('existingScheduleName')}
        schedulePeriodText={existingSchedulePeriodText}
        displayTimeSlots={parseTimeSlots(existingScheduleTimeSlots)}
      />
      <ScheduleListItem
        scheduleName={t('newScheduleName')}
        schedulePeriodText={parseSchedulePeriod(timezone, startDate, endDate)}
        displayTimeSlots={newDisplayTimeSlots}
      >
        <CopyOptionsPreview sessionOptions={sessionOptions} />
      </ScheduleListItem>
      <Alert tone="warning" title={t('alert.title')} kind="inline" closeable={false}>
        <Text>
          {t('alert.body')} <b>{t('alert.strongBody')}</b>{' '}
        </Text>
      </Alert>
    </Stack>
  );
};

export default ExtendScheduleReview;
