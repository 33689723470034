import React, { Component } from 'react';
import { get, debounce, isEmpty, map } from 'lodash';
import { FieldLabel, Text, Title } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Col, Empty, Skeleton, Input } from 'antd';
import { GridHeader, GridRow } from 'common-components/grids';
import * as H from 'history';
import { ProgressBar } from '@blueprintjs/core';
import moment from 'moment';
import PermissionUtils from 'utilities/permission-utils';
import FormStatusTag from 'common-components/tags/FormStatusTag';
import CreateCopyFormModal from 'views/form-builder/components/CreateCopyFormModal';
import { FilterSection } from 'common-components/filter';
import { FilterType, FormActions, FormStatus } from 'utilities/enum-utils';
import { STATUS as defaultFilters } from 'views/form-builder/shared/constants';
import { ICrumb } from 'interfaces/common-interface';
import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import { IFormListResponse } from '../../form-builder/shared/form-interface';
import ArchiveActiveFormModal from 'views/form-builder/components/details/components/ArchiveActiveFormModal';
import FormManagementActionButton from './FormManagementActionButton';
import { Inline, Label } from '@goodhuman-me/components';
import FormTypeTag from 'common-components/tags/FormTypeTag';
import { Link, Redirect } from 'react-router-dom';
import { InfiniteScroll } from 'components';

const { Search } = Input;

interface IFormsManagementViewProps {
  portalUser: typeof state.authStore.portalUser;
  history: H.History;
  formList: typeof state.formBuilderStore.formList;
  doFetchFormList: typeof dispatch.formBuilderStore.doFetchFormList;
  formSearchFilter: typeof state.formBuilderStore.formSearchFilter;
  setFormSearchFilter: typeof dispatch.formBuilderStore.setFormSearchFilter;
  statusFilter: typeof state.formBuilderStore.statusFilter;
  setStatusFilter: typeof dispatch.formBuilderStore.setStatusFilter;
  doArchiveForm: typeof dispatch.formBuilderStore.doArchiveForm;
  doReActivateForm: typeof dispatch.formBuilderStore.doReActivateForm;
  copyForm: typeof dispatch.formBuilderStore.copyForm;
  setFormList: typeof dispatch.formBuilderStore.setFormList;
}

interface IFormsManagementViewState {
  isLoading: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isLoadingInfiniteScrolling: boolean;
  isCreateCopyFormModalOpen: boolean;
  filters: { status: string[]; type: string[] };
  selectedForm: IFormListResponse;
  isOpenArchiveActiveModal: boolean;
}

const ListEmptyState = () => (
  <div className='flex-1 bg-white align-center flex-column mt-x-large'>
    <div className='mb-small'>
      <Empty className='m-none' description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x3-large' color='secondary' weight='bold'>
      No form found.
    </Text>{' '}
    <Text color='secondary'>All form under this filter will appear here.</Text>
  </div>
);

const availableFilters = [FilterType.FORM_STATUS, FilterType.FORM_TYPE];
class FormsManagementView extends Component<IFormsManagementViewProps, IFormsManagementViewState> {
  state = {
    isLoading: true,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    isLoadingInfiniteScrolling: false,
    isCreateCopyFormModalOpen: false,
    filters: { status: [FormStatus.ACTIVE, FormStatus.DRAFT], type: [] },
    selectedForm: null,
    isOpenArchiveActiveModal: false,
  };

  private _onEnterSearchText = (e) => {
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this.setState({ isLoading: true });
      this._debounceSearch(e.target.value);
    }
  };

  private _searchText = async (txt) => {
    const { setFormSearchFilter, doFetchFormList } = this.props;

    this.setState({ page: 1 });
    setFormSearchFilter(txt);
    await doFetchFormList({
      search: this.props.formSearchFilter,
      status: this.state.filters.status,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      formType: this.state.filters.type,
    });

    this.setState({ isLoading: false });
  };

  private _debounceSearch = debounce(this._searchText, 500);

  private _fetchMoreForms = async () => {
    this.setState({ isLoadingInfiniteScrolling: true, page: this.state.page + 1 });

    await this.props.doFetchFormList({
      page: this.state.page,
      status: this.state.filters.status,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      formType: this.state.filters.type,
    });
    this.setState({ isLoadingInfiniteScrolling: false });
  };

  private _openCreateCopyFormModal = () => {
    this.props.history.push('forms/newform');
  };

  private _closeCreateCopyFormModal = () => {
    this.setState({ isCreateCopyFormModalOpen: false });
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setStatusFilter(filters);
    this.setState({
      filters: {
        status: filters.find((f) => f.filter === FilterType.FORM_STATUS).values,
        type: filters.find((f) => f.filter === FilterType.FORM_TYPE).values,
      },
    });
  };

  private _copyForm = async (formItem: IFormListResponse) => {
    const copiedForm = await this.props.copyForm(formItem);
    const newFormId = copiedForm.formId;
    return this.props.history.push(`/account/forms/edit/${newFormId}`);
  };

  private _handleSelectAction = async (formItem: IFormListResponse, action: FormActions) => {
    switch (action) {
      case FormActions.ARCHIVE:
      case FormActions.RE_ACTIVATE:
        return this.setState({ selectedForm: formItem, isOpenArchiveActiveModal: true });
      case FormActions.EDIT:
        return this.props.history.push(`/account/forms/edit/${formItem.formId}`);
      case FormActions.COPY:
        return await this._copyForm(formItem);
      default:
        return;
    }
  };

  private _handleConfirmArchiveActiveModal = async () => {
    const { selectedForm } = this.state;
    const { formList, doArchiveForm, doReActivateForm, setFormList } = this.props;

    const isActiveAction = [FormStatus.ARCHIVED, FormStatus.DRAFT].includes(selectedForm.status);
    isActiveAction ? await doReActivateForm(selectedForm) : await doArchiveForm(selectedForm);

    // update status form in formlist
    const newFormList = formList.map((form) =>
      form.formId === selectedForm.formId
        ? { ...selectedForm, status: isActiveAction ? FormStatus.ACTIVE : FormStatus.ARCHIVED }
        : form,
    );
    setFormList(newFormList);

    this._handleCloseArchiveActiveModal();
  };

  private _handleCloseArchiveActiveModal = () => {
    this.setState({ isOpenArchiveActiveModal: false });
  };

  private _checkIsArchiveAction = (selectedForm: IFormListResponse): boolean => {
    return get(selectedForm, 'status', '') !== FormStatus.ARCHIVED;
  };

  componentDidMount = async () => {
    this.props.setStatusFilter(defaultFilters);
    const formList = await this.props.doFetchFormList({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      status: this.state.filters.status,
      formType: this.state.filters.type,
    });
    if (formList.length === 0) {
      this.props.history.push('forms/newform');
    }
    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevState.filters !== this.state.filters) {
      const resetPage = 1;

      this.setState({ isLoading: true, page: resetPage });
      await this.props.doFetchFormList({
        page: resetPage,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
        status: this.state.filters.status,
        formType: this.state.filters.type,
      });
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { formList, portalUser, history } = this.props;
    const { isCreateCopyFormModalOpen, selectedForm, isOpenArchiveActiveModal } = this.state;

    const crumbs: ICrumb[] = [
      {
        title: 'Account Management',
        target: '/account/landing',
      },
      {
        title: 'Forms',
      },
    ];
    return (
      <div id='content-container'>
        <BreadcrumbNav icon='home' theme='filled' crumbs={crumbs} isBordered={false} />

        <>
          <CreateCopyFormModal
            isOpen={isCreateCopyFormModalOpen}
            history={history}
            onClose={this._closeCreateCopyFormModal}
          />

          <div className='flex-row justify-between'>
            <Title level={3}>Forms</Title>
            {PermissionUtils.validatePermission('AccessToFormBuilder', portalUser.permissions.permissionRoles) && (
              <PrimaryButton size='large' icon='plus' onClick={this._openCreateCopyFormModal}>
                Create new form
              </PrimaryButton>
            )}
          </div>

          <div className='mb-x-large' style={{ position: 'sticky', top: '0px', zIndex: 10 }}>
            <div className='bg-white'>
              <>
                <div className='flex-row pb-medium bg-white'>
                  <Search
                    placeholder='Search for a form...'
                    loading={this.state.isLoading}
                    defaultValue={this.props.formSearchFilter}
                    style={{ width: '240px' }}
                    allowClear={true}
                    className='mr-large'
                    onChange={this._onEnterSearchText}
                  />
                  <FilterSection
                    availableFilters={availableFilters}
                    filters={this.props.statusFilter}
                    onChangeFilter={this._onChangeFilter}
                    displayTimezone={portalUser.timezone}
                    containerClassName='flex-row justify-between'
                    displayMoreFilter={false}
                  />
                </div>
                <GridHeader bordered containerClassName='border-width-medium border-secondary'>
                  <Col span={8} className=' bg-white'>
                    <FieldLabel text='FORM NAME' />
                  </Col>
                  <Col span={3} className=' bg-white'>
                    <FieldLabel text='STATUS' />
                  </Col>
                  <Col span={3} className=' bg-white'>
                    <FieldLabel text='TYPE' />
                  </Col>
                  <Col span={5} className=' bg-white'>
                    <FieldLabel text='CONNECTED TO WORKFLOW' />
                  </Col>
                  <Col span={3} className=' bg-white'>
                    <FieldLabel text='LAST UPDATED' />
                  </Col>
                  <Col span={2} className=' bg-white'>
                    <FieldLabel text='' />
                  </Col>
                </GridHeader>
                {this.state.isLoading ? (
                  <div className=''>
                    <div className='pv-large'>
                      <ProgressBar />
                    </div>
                    <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                    <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                    <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                  </div>
                ) : !isEmpty(formList) ? (
                  <InfiniteScroll
                    hasMore={formList.length >= this.state.page * this.state.pageSize}
                    loadMore={this._fetchMoreForms}
                  >
                    {map(formList, (formItem, index) => {
                      return (
                        <GridRow key={index} containerClassName='bordered-none evenodd'>
                          <Col span={8}>
                            <Inline gap='$space100'>
                              <Link to={`/account/form/${formItem.formId}`}>
                                <HyperlinkButton className='select-none'>{formItem.name}</HyperlinkButton>
                              </Link>
                              {formItem.version > 1 && (
                                <Label emphasis='light' tone='neutral' size='xsmall'>
                                  v{formItem.version}
                                </Label>
                              )}
                            </Inline>
                          </Col>
                          <Col span={3}>
                            <FormStatusTag status={formItem.status} />
                          </Col>
                          <Col span={3}>
                            <FormTypeTag formType={formItem.formType} />
                          </Col>
                          <Col span={5}>
                            <Text>{formItem.isUsedInActiveWorkFlow ? 'Yes' : 'No'}</Text>
                          </Col>
                          <Col span={3}>
                            <Text size='regular' color='secondary'>
                              {moment(formItem.updatedOn).format('Do MMM YY')}
                            </Text>
                          </Col>
                          <Col span={2} className='text-align-right pr-x2-large'>
                            <FormManagementActionButton
                              formItem={formItem}
                              handleSelection={this._handleSelectAction}
                            />
                          </Col>
                        </GridRow>
                      );
                    })}
                  </InfiniteScroll>
                ) : (
                  <div style={{ borderBottom: '0px solid' }}>
                    <ListEmptyState />
                  </div>
                )}
                {this.state.isLoadingInfiniteScrolling && (
                  <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
                )}
              </>
            </div>
          </div>

          <ArchiveActiveFormModal
            statusForm={selectedForm?.status}
            isArchive={this._checkIsArchiveAction(selectedForm)} //action not current status
            isOpen={isOpenArchiveActiveModal}
            onClose={this._handleCloseArchiveActiveModal}
            onArchiveOrActivate={this._handleConfirmArchiveActiveModal}
          />
        </>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  formList: state.formBuilderStore.formList,
  formSearchFilter: state.formBuilderStore.formSearchFilter,
  statusFilter: state.formBuilderStore.statusFilter,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchFormList: dispatch.formBuilderStore.doFetchFormList,
  setFormSearchFilter: dispatch.formBuilderStore.setFormSearchFilter,
  setStatusFilter: dispatch.formBuilderStore.setStatusFilter,
  doArchiveForm: dispatch.formBuilderStore.doArchiveForm,
  doReActivateForm: dispatch.formBuilderStore.doReActivateForm,
  copyForm: dispatch.formBuilderStore.copyForm,
  setFormList: dispatch.formBuilderStore.setFormList,
});

export default connect(mapState, mapDispatch)(FormsManagementView);
