import { css } from '@goodhuman-me/components';

export const reset = css({
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'visible !important',
});

export const svg = css({
  marginTop: '$none',
  marginBottom: '$space600',
  marginLeft: '$space1200',
});

export const axis = css({
  '& line': {
    stroke: '$bodyLight1',
    strokeWidth: '2px',
  },

  '& path': {
    stroke: '$bodyLight1',
    strokeWidth: '2px',
  },

  '& text': {
    fontSize: '$xxsmall',
    lineHeight: '$xxsmall',
    stroke: '$bodyDark1',
    fontWeight: 'lighter',
  },
});
