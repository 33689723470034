import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Center, Flex, Loader, Text, rem } from '@good/ui/core';
import { Error } from '@good/illustrations';

import { useSilServiceContext } from '../sil-service-provider/sil-service-provider';

type SilServicePanelPageProps = {
  element: React.ReactNode;
};

export const SilServicePanelPage = ({ element }: SilServicePanelPageProps) => {
  const { isLoading, service, isError, refetch, isFetching } = useSilServiceContext();
  const { t } = useTranslation('', { keyPrefix: 'silService.messages' });

  if (isLoading) {
    return (
      <Center h={rem(512)}>
        <Flex direction='column' gap={rem(28)} align='center' p={rem(32)}>
          <Loader color='var(--color-lavender-main)' size={rem(48)} />
          <Text size='md' fw={700}>
            {t('fetchService.loading')}
          </Text>
        </Flex>
      </Center>
    );
  }

  if (isError) {
    return (
      <Center h={rem(512)}>
        <Flex direction='column' gap={rem(24)} align='center' p={rem(32)} w={rem(385)}>
          <Error />
          <Flex direction='column' align='center'>
            <Text size='md' fw={700} ta='center'>
              {t('fetchService.error')}
            </Text>
            <Text size='sm' mt={rem(8)} ta='center' c='var(--brand-body-dark-1)'>
              {t('fetchService.errorDescription')}
            </Text>
            <Button onClick={refetch} size='md' mt={rem(16)} w={rem(200)} variant='filled' loading={isFetching}>
              {t('fetchService.retry')}
            </Button>
          </Flex>
        </Flex>
      </Center>
    );
  }

  if (!service) {
    return (
      <Center h={rem(512)}>
        <Flex direction='column' gap={rem(24)} align='center' p={rem(32)} w={rem(385)}>
          <Error />
          <Flex direction='column' align='center'>
            <Text size='md' fw={700} ta='center'>
              {t('fetchService.error')}
            </Text>
            <Text size='sm' mt={rem(8)} ta='center' c='var(--brand-body-dark-1)'>
              {t('fetchService.notFound')}
            </Text>
          </Flex>
        </Flex>
      </Center>
    );
  }

  return <>{element}</>;
};
