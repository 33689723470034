import React from 'react';
import { SubTitle, Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';

type ICompanyDetailsWidgetProps = {
  title: string;
  subTitle?: string;
  panelClassName?: string;
  content: string | React.ReactElement;
  hyperlinkCaption?: string;
  onHyperlinkClick?: () => void;
  testId?: string;
};

export const CompanyDetailsWidget = ({
  title,
  subTitle,
  panelClassName,
  content,
  hyperlinkCaption,
  onHyperlinkClick,
  testId,
}: ICompanyDetailsWidgetProps) => {
  const _panelClassName = `bordered p-medium border-standard-gray mt-large ${panelClassName ?? ''} `;
  const linkTestId = testId ? `${testId}-link` : undefined;

  return (
    <div className="mt-large">
      <SubTitle>{title}</SubTitle>
      {subTitle && (
        <div className="line-height-100">
          <Text>{subTitle}</Text>
        </div>
      )}

      <div className={_panelClassName} style={{ width: '500px' }}>
        {content}
        {onHyperlinkClick && (
          <div className="mt-small">
            <HyperlinkButton data-testId={linkTestId} onClick={onHyperlinkClick}>
              {hyperlinkCaption ?? 'Modify'}
            </HyperlinkButton>
          </div>
        )}
      </div>
    </div>
  );
};
