import React from 'react';
import {
  CurrencyFormatter,
  Inline,
  NumberFormatter,
  PercentFormatter,
  Separator,
  Stack,
  Text,
} from '@goodhuman-me/components';

export enum StatKind {
  Currency = 'currency',
  Percentage = 'percentage',
  Integer = 'integer',
}

interface StatProps {
  value: number | undefined;
  kind: StatKind;
}

const Stat = ({ value, kind }: StatProps): JSX.Element => {
  if (value !== undefined && kind === StatKind.Percentage) {
    return (
      <Text size="xsmall">
        <PercentFormatter value={value} />
      </Text>
    );
  }

  if (value !== undefined && kind === StatKind.Integer) {
    return (
      <Text size="xsmall">
        <NumberFormatter value={value} />
      </Text>
    );
  }

  if (typeof value === 'number' && kind === StatKind.Currency) {
    return (
      <Text size="xsmall">
        <CurrencyFormatter currency="AUD" value={value} />
      </Text>
    );
  }

  return <Text size="xsmall">N/A</Text>;
};

export interface BudgetStatProps extends StatProps {
  label: string;
}

export const BudgetStat = ({ label, value, kind }: BudgetStatProps): JSX.Element => {
  return (
    <Inline>
      <Text size="xsmall" flex="1">
        {label}
      </Text>
      <Stat value={value} kind={kind} />
    </Inline>
  );
};

export interface BudgetStatStackProps {
  budgetStats: readonly BudgetStatProps[];
}

export const BudgetStatStack = ({ budgetStats }: BudgetStatStackProps): JSX.Element => {
  return (
    <Stack gap="$space100" width="50%">
      {budgetStats.map((budgetStat, idx) => {
        const isLastItem = budgetStats.length - 1 === idx;

        return (
          <React.Fragment key={idx}>
            <BudgetStat {...budgetStat} />
            {!isLastItem && <Separator orientation="horizontal" thickness="thin" />}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};
