import React from 'react';
import { Text } from 'common-components/typography';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import { GridRow } from 'common-components/grids';
import { TmMgmtSentToColumn } from '../../team-management-columns/tm-mgmt-sent-to-column';
import { TmMgmtCreatedOnColumn } from '../../team-management-columns/tm-mgmt-created-on-column';
import { TmMgmtInvitedByColumn } from '../../team-management-columns/tm-mgmt-invited-by-column';
import { TmMgmtOptionMenuColumn } from '../../team-management-columns/tm-mgmt-option-menu-column';
import { TmMgmtInviteTableActionMenu } from './tm-mgmt-invite-table-action-menu';
import { PortalUser } from 'stores/rematch/models/auth-store';
import { TmMgmtInvitedTypeColumn } from '../../team-management-columns/tm-mgmt-invited-type-column';

type TmMgmtInviteTablePendingParams = {
  portalUser: PortalUser;
  onOpenModal: (status: string, worker?: object | null | undefined) => void;
};

export const TmMgmtInviteTablePending = ({ portalUser, onOpenModal }: TmMgmtInviteTablePendingParams) => {
  const { invitesTeamList } = useSelector((state: IRootState) => state.teamStore);
  const { pendingList } = invitesTeamList;

  return (
    <>
      <div className="mv-large">
        <Text>Pending invites</Text>
      </div>
      {pendingList.map((worker, index: number) => {
        return (
          <GridRow key={index}>
            <TmMgmtSentToColumn
              attachmentUrl={worker.attachmentUrl}
              firstName={worker.firstName}
              lastName={worker.lastName}
              email={worker.email}
            />
            <TmMgmtCreatedOnColumn createdOn={worker.createdOn} />
            <TmMgmtInvitedByColumn
              invitedByAttachmentUrl={worker.invitedByAttachmentUrl}
              invitedByFirstName={worker.invitedByFirstName}
              invitedByLastName={worker.invitedByLastName}
            />
            <TmMgmtInvitedTypeColumn invitedType={worker.invitationType} />
            <TmMgmtOptionMenuColumn
              menu={
                <TmMgmtInviteTableActionMenu
                  status={worker.invitationStatus}
                  portalUser={portalUser}
                  worker={worker}
                  onOpenModal={onOpenModal}
                />
              }
            />
          </GridRow>
        );
      })}
    </>
  );
};
