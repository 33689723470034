import { BoxStyleProps, css } from '../theme';
import type { CSS } from '../theme';

export const container: CSS = {
  position: 'relative',
};

export const wrapper: CSS = {
  position: 'relative',
  width: '100%',
  height: 'max-content',
};

export const trigger: CSS = {
  position: 'absolute',
  zIndex: '1',
  width: '100%',
  height: '100%',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  cursor: 'pointer',
  opacity: '0',
};

export const triggerVariants = css({
  variants: {
    isDisabled: {
      true: {
        cursor: 'not-allowed !important',
      },
    },
  },
});

export const dropdownPopover: BoxStyleProps = {
  display: 'fex',
  flexDirection: 'column',

  backgroundColor: '$white',

  borderRadius: '$medium',
  boxShadow: '0px 8px 16px -4px rgba(0, 0, 0, 0.08), 0px 4px 8px -4px rgba(0, 0, 0, 0.08)',
  borderWidth: '$border100',
  borderStyle: '$solid',
  borderColor: '$neutralLight2',
};
