import { Box, Label } from '@goodhuman-me/components';
import React, { ReactNode } from 'react';
import {
  CustomerSupportWorkerPreference,
  PublishShiftApplicationAvailability,
  ShiftSlotStatus,
} from 'utilities/enum-utils';
import { Icon, Tooltip } from 'antd';
import { Paragraph } from 'common-components/typography';

interface IWorkerStatusTooltipProps {
  title?: string | ReactNode;
  content?: string | ReactNode;
  children?: ReactNode;
}

const WorkerStatusTooltip = (props: IWorkerStatusTooltipProps): JSX.Element => {
  const { title, content, children } = props;
  return (
    <Tooltip
      title={
        <div className="p-medium rounded-big" style={{ backgroundColor: '#1A285B' }}>
          {title && (
            <Paragraph size="regular" color="white" weight="bold mb-small">
              {title}
            </Paragraph>
          )}
          {content && (
            <Paragraph size="x-small" color="white" className="mb-none">
              {content}
            </Paragraph>
          )}
        </div>
      }
      placement="right"
      overlayClassName="worker-status-tag-v2-tooltip"
    >
      {children}
    </Tooltip>
  );
};

interface IWorkerStatusTagV2Props {
  preference?: string;
  shiftSlotStatus?: string;
  hasTooltip?: boolean;
  tag?: string;
  tooltipTitle?: string | ReactNode;
  tooltipContent?: string | ReactNode;
  emphasis?: 'light' | 'outlined' | 'heavy' | 'ghost';
  tone?: 'canary' | 'blue' | 'lilac' | 'neutral' | 'info' | 'positive' | 'notice' | 'critical';
  size?: 'small' | 'large' | 'xsmall' | 'medium';
  icon?: string;
}

const WorkerStatusTagV2 = (props: IWorkerStatusTagV2Props): JSX.Element => {
  const { preference, shiftSlotStatus, icon, hasTooltip = true } = props;
  let tag = '';
  let tooltipContent = null;
  let tooltipTitle = null;
  let emphasis: 'light' | 'outlined' | 'heavy' | 'ghost' = 'light';
  let tone: 'canary' | 'blue' | 'lilac' | 'neutral' | 'info' | 'positive' | 'notice' | 'critical' = 'positive';
  let size: 'small' | 'large' | 'xsmall' | 'medium' = 'xsmall';

  if (preference === CustomerSupportWorkerPreference.BLOCKED) {
    tag = 'Blocked';
    tone = 'critical';
    tooltipTitle = 'Blocked';
    tooltipContent = 'Team member has been blocked for this customer. You cannot select this team member.';
  } else if (shiftSlotStatus === PublishShiftApplicationAvailability.AVAILABLE) {
    tag = 'Available';
    tone = 'positive';
    tooltipTitle = 'Available';
    tooltipContent = 'This shift is inside team member’s general availability';
  } else if (shiftSlotStatus === PublishShiftApplicationAvailability.OUTSIDE_GENERAL_AVAILABILITY) {
    tag = 'Outside general availability';
    tone = 'info';
    tooltipTitle = 'Outside general availability (can be overridden)';
    tooltipContent = 'This shift is outside of team member’s general availability';
  } else if (shiftSlotStatus === PublishShiftApplicationAvailability.SHIFT_CLASH) {
    tag = 'Shift clash';
    tone = 'notice';
    tooltipTitle = 'Shift clash (can be overridden)';
    tooltipContent = 'Team member has another shift at an overlaping time';
  } else if (shiftSlotStatus === PublishShiftApplicationAvailability.UNAVAILABLE_CAN_BE_OVERRIDDEN) {
    tag = 'Unavailable';
    tone = 'notice';
    tooltipTitle = 'Unavailable (can be overridden)';
    tooltipContent = 'Team member has indicated unavailability during the time of this shift';
  } else if (shiftSlotStatus === ShiftSlotStatus.ON_LEAVE) {
    tag = 'On leave';
    tone = 'critical';
    tooltipTitle = 'On leave';
    tooltipContent = 'Team member is on leave during the time of this shift. You cannot select this team member.';
  } else {
    tag = 'Unavailable';
    tone = 'notice';
    tooltipTitle = 'Unavailable';
    tooltipContent = 'Team member has indicated unavailability during the time of this shift';
  }

  tag = props.tag ?? tag;
  tooltipTitle = props.tooltipTitle ?? tooltipTitle;
  tooltipContent = props.tooltipContent ?? tooltipContent;
  emphasis = props.emphasis ?? emphasis;
  tone = props.tone ?? tone;
  size = props.size ?? size;

  if (hasTooltip) {
    return (
      <WorkerStatusTooltip title={tooltipTitle} content={tooltipContent}>
        <div className="width-fit-content cursor-pointer">
          <Label emphasis={emphasis} tone={tone} size={size}>
            {tag}
            {icon && <Icon type={icon} className="ml-x-small" />}
          </Label>
        </div>
      </WorkerStatusTooltip>
    );
  }

  return (
    <Label emphasis={emphasis} tone={tone} size={size}>
      {tag}
      {icon && <Icon type={icon} className="ml-x-small" />}
    </Label>
  );
};

export { WorkerStatusTooltip };
export default WorkerStatusTagV2;
