import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Route = forwardRef(function Route(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM15.25 5a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM6 17.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm2.646.5a2.751 2.751 0 1 0 0 1.5H16.5a4.25 4.25 0 0 0 0-8.5h-9a2.75 2.75 0 1 1 0-5.5h2.69l-.72.72a.75.75 0 0 0 1.06 1.06l2-2a.75.75 0 0 0 0-1.06l-2-2a.75.75 0 1 0-1.06 1.06l.72.72H7.5a4.25 4.25 0 0 0 0 8.5h9a2.75 2.75 0 1 1 0 5.5H8.646Z"
        fill="currentColor"
      />
    </Icon>
  );
});
