import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const SortTextReverse = forwardRef(function SortTextReverse(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 3a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 .624 1.166L16.401 8.25H19a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.624-1.166l3.223-4.834H15a.75.75 0 0 1-.75-.75ZM7 5.25a.75.75 0 0 1 .75.75v10.19l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l1.72 1.72V6A.75.75 0 0 1 7 5.25Zm10 7A2.75 2.75 0 0 0 14.25 15v4a.75.75 0 0 0 1.5 0v-1.5h2.5V19a.75.75 0 0 0 1.5 0v-4A2.75 2.75 0 0 0 17 12.25ZM18.25 15v1h-2.5v-1a1.25 1.25 0 1 1 2.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
