import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ChartPie = forwardRef(function ChartPie(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25a.75.75 0 0 0-.75.75v9c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75c0-5.385-4.365-9.75-9.75-9.75Zm.75 9V3.784a8.252 8.252 0 0 1 7.466 7.466H12.75ZM9.282 4.208a.75.75 0 0 0-.495-1.416A9.75 9.75 0 0 0 2.25 12c0 5.385 4.365 9.75 9.75 9.75a9.754 9.754 0 0 0 9.195-6.5.75.75 0 1 0-1.414-.5A8.254 8.254 0 0 1 12 20.25 8.25 8.25 0 0 1 9.282 4.208Z"
        fill="currentColor"
      />
    </Icon>
  );
});
