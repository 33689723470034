import React, { PropsWithChildren, createContext, useContext } from 'react';
import { addDays, addHours, isAfter, isBefore } from 'date-fns';
import { TimelineProps } from 'design-components/timeline';
import { differenceInDaysNotRounded, differenceInHoursNotRounded } from 'utilities/date-utils';

export const DEFAULT_CELL_WIDTH = 12;
export const DEFAULT_CELL_HEIGHT = 9;
export const DEFAULT_HEADER_HEIGHT = 3;

type TimelineContextProps = PropsWithChildren<Required<TimelineProps>> & {
  unitsInRange: Date[];
  totalTimelineWidth: number;
  getPositionFromLeftBasedOnDate: (date: Date) => number;
  differenceInUnit: (dateLeft: Date, dateRight: Date) => number;
  isOutOfRange: (date: Date) => boolean;
};

const TimelineContext = createContext<TimelineContextProps>({} as TimelineContextProps);

export const TimelineProvider = ({ children, ...props }: PropsWithChildren<TimelineProps>) => {
  const {
    dateRange,
    calendarUnit = 'days',
    roundBlocksToNearestUnit = calendarUnit === 'days' ? true : false,
    cellWidth = DEFAULT_CELL_WIDTH,
    cellHeight = DEFAULT_CELL_HEIGHT,
    headerHeight = DEFAULT_HEADER_HEIGHT,
    searchBar = <></>,
    footer = <></>,
    showCurrentTimeIndicator = false,
  } = props;

  const getPositionFromLeftBasedOnDate = (date: Date) => {
    const dayDifference = differenceInUnit(date, dateRange.start);

    return (1 + dayDifference) * cellWidth;
  };

  const differenceInUnit = (dateLeft: Date, dateRight: Date) => {
    const differenceNotRounded = (() => {
      switch (calendarUnit) {
        case 'days':
          return Math.ceil(differenceInDaysNotRounded(dateLeft, dateRight));
        case 'hours':
          return Math.ceil(differenceInHoursNotRounded(dateLeft, dateRight));
        default:
          throw new Error('Invalid calendar date units');
      }
    })();

    if (roundBlocksToNearestUnit) {
      return Math.ceil(differenceNotRounded);
    }

    return differenceNotRounded;
  };

  const addDateUnits = (date: Date, unitsToAdd: number): Date => {
    switch (calendarUnit) {
      case 'days':
        return addDays(date, unitsToAdd);
      case 'hours':
        return addHours(date, unitsToAdd);
      default:
        throw new Error('Invalid calendar date units');
    }
  };

  const isOutOfRange = (date: Date) => isBefore(date, dateRange.start) || isAfter(date, dateRange.end);

  const numDays = differenceInUnit(dateRange.end, dateRange.start);
  const unitsInRange = [...Array(numDays)].map((_, index) => addDateUnits(dateRange.start, index));
  const totalTimelineWidth = (numDays + 1) * cellWidth;

  return (
    <TimelineContext.Provider
      value={{
        ...props,
        getPositionFromLeftBasedOnDate,
        differenceInUnit,
        isOutOfRange,
        unitsInRange,
        totalTimelineWidth,
        calendarUnit,
        roundBlocksToNearestUnit,
        cellWidth,
        cellHeight,
        headerHeight,
        searchBar,
        showCurrentTimeIndicator,
        footer,
      }}
    >
      {children}
    </TimelineContext.Provider>
  );
};

export const useTimelineContext = () => useContext(TimelineContext);
