import React, { PureComponent } from 'react';

import { Col, Row } from 'antd';
import { Colors } from '@blueprintjs/core';
import { Text } from 'common-components/typography';
import HoverCard from 'common-components/cards/HoverCard';
import moment from 'moment';
import styles from 'views/customers/styles/customer-details.module.css';
import { ICustomerBooking } from 'interfaces/customer-interfaces';
import { StatusTag } from 'common-components/tags';
import { RouteComponentProps } from 'react-router-dom';
import { ServiceType } from 'utilities/enum-utils';

interface ICustomerDetailsBookingItemProps extends RouteComponentProps {
  booking: ICustomerBooking;
}
interface ICustomerDetailsBookingItemState {}

class CustomerDetailsBookingItem extends PureComponent<
  ICustomerDetailsBookingItemProps,
  ICustomerDetailsBookingItemState
> {
  ///:bookingId/overview
  onClickRow = () => {
    const { booking, history } = this.props;

    booking.serviceType === 'GROUP'
      ? history.push(`/group-service/${booking.serviceId}/session/details/${booking.serviceDateTimeId}`)
      : history.push(`/bookings/details/${booking.attendanceId}`);
  };

  render() {
    const { booking, history } = this.props;
    const bookingStartDateTime = moment(booking.startDateTime);
    return (
      <HoverCard borderColor={Colors.LIGHT_GRAY1} onClick={this.onClickRow}>
        <Row type={'flex'} justify={'space-between'} gutter={24}>
          <Col span={2}>
            <ul className={'flat-container'} style={{ textAlign: 'center' }}>
              <li className={styles['booking-list-display-day']}>{bookingStartDateTime.format('DD')}</li>
              <li className={styles['booking-list-display-month']}>{bookingStartDateTime.format('MMM')}</li>
            </ul>
          </Col>

          <Col span={16}>
            <Row gutter={16}>
              <Col span={8}>
                {/* Day*/}
                <Text>{`${bookingStartDateTime.format('dddd')}`}</Text>
                <br />

                <Text strong>{`${bookingStartDateTime.format('D MMMM YYYY')}`}</Text>
                <br />

                {/* */}
                <Text>{`${bookingStartDateTime.format('h:mma')} - ${moment(booking.endDateTime).format(
                  'h:mma',
                )}`}</Text>
                <br />
              </Col>

              <Col span={9}>
                <Text>{booking.serviceName}</Text>
              </Col>

              <Col span={7}>
                <Text className={'small-text'}>
                  {booking.workerName ? (
                    `Assigned to ${booking.workerName}`
                  ) : booking.serviceType === ServiceType.GROUP ? (
                    <Text> Group Service </Text>
                  ) : (
                    <Text type={'secondary'}> No Assigned Worker</Text>
                  )}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={5}>
            <div className="flex-row justify-end">
              <StatusTag status={booking.bookingStatus} />
            </div>
          </Col>
        </Row>
      </HoverCard>
    );
  }
}

export default CustomerDetailsBookingItem;
