import { IRootState } from 'stores/rematch/root-store';
import apiClient from 'utilities/api-client';
import { isEmpty } from 'lodash';
import {
  IFormCreatePayload,
  IFormElement,
  IFormListPayload,
  IFormListResponse,
  IFormContent,
  IFormUpdatePayload,
  IFormLiteListResponse,
  IFormUsagePayload,
  IFormTemplateResponse,
  IFormTemplate,
} from 'views/form-builder/shared/form-interface';
import { IFilter } from 'interfaces/filter-interfaces';
import { FormType } from 'utilities/enum-utils';
import { withBackwardsCompatibleChildElements } from 'views/form-builder/shared/form-builder-utils';

interface IFormBuilderStoreState {
  currentForm: IFormContent;
  selectedElement: IFormElement;
  formList: IFormListResponse[];
  formTemplateList: IFormTemplate[];
  formListOptions: IFormListResponse[]; //for Copy form dropdown
  statusFilter: IFilter[];
  formSearchFilter: string; // for search form input
  formLiteList: IFormLiteListResponse[]; //for form lite list in Attach Form modal
  elementsBundleOptional: string[];
  isLoadingFormData: boolean;
}

const initialState: IFormBuilderStoreState = {
  currentForm: {
    title: { formTitle: '', formDescription: '' },
    elements: [],
    formType: FormType.CUSTOM,
  },
  selectedElement: null,
  formList: [],
  formTemplateList: [],
  formListOptions: [],
  statusFilter: [],
  formSearchFilter: '',
  formLiteList: [],
  elementsBundleOptional: [],
  isLoadingFormData: false,
};

const formBuilderStore = {
  state: initialState,
  reducers: {
    setCurrentForm: (state, payload) => ({ ...state, currentForm: payload }),
    setSelectedElement: (state, payload) => ({ ...state, selectedElement: payload }),
    setFormList: (state, payload) => ({ ...state, formList: payload }),
    setFormTemplateList: (state, payload) => ({ ...state, formTemplateList: payload }),
    setFormLiteList: (state, payload) => ({ ...state, formLiteList: payload }),
    setFormListOptions: (state, payload) => ({ ...state, formListOptions: payload }),
    setStatusFilter: (state, payload) => ({ ...state, statusFilter: payload }),
    setFormSearchFilter: (state, payload) => ({ ...state, formSearchFilter: payload }),
    setElementsBundleOptional: (state, payload) => ({ ...state, elementsBundleOptional: payload }),
    setLoadingFormData: (state, payload) => ({ ...state, isLoadingFormData: payload }),
  },
  effects: (dispatch) => ({
    async doFetchFormList(payload: IFormListPayload, rootState: IRootState) {
      try {
        const { data } = await apiClient.post('/api/portal/account/forms/list', payload);
        if (payload.page > 1) {
          dispatch.formBuilderStore.setFormList(rootState.formBuilderStore.formList.concat(data.forms));
        } else dispatch.formBuilderStore.setFormList(data.forms);
        return data.forms;
      } catch (error) {
        throw error;
      }
    },
    async doFetchFormLiteList(payload: { search: string }, rootState: IRootState) {
      try {
        const { data } = await apiClient.post('/api/portal/account/forms/lite-list', payload);
        if (!isEmpty(data.forms)) {
          dispatch.formBuilderStore.setFormLiteList(data.forms);
        } else {
          dispatch.formBuilderStore.setFormLiteList([]);
        }

        return data.forms;
      } catch (error) {
        throw error;
      }
    },

    async doFetchFormListOptions(payload: IFormListPayload, rootState: IRootState) {
      try {
        //to change URL if needed
        const { data } = await apiClient.post('/api/portal/account/forms/list', payload);
        if (!isEmpty(data.forms)) {
          if (payload.page > 1) {
            dispatch.formBuilderStore.setFormListOptions(rootState.formBuilderStore.formListOptions.concat(data.forms));
          } else dispatch.formBuilderStore.setFormListOptions(data.forms);
        }
      } catch (error) {
        throw error;
      }
    },

    async doCreateForm(payload: IFormCreatePayload, rootState: IRootState) {
      try {
        const { data } = await apiClient.post('/api/portal/account/forms', payload);
      } catch (error) {
        throw error;
      }
    },
    async doUpdateForm(payload: IFormUpdatePayload, rootState: IRootState) {
      try {
        const { data } = await apiClient.put(`/api/portal/account/forms/${payload.formId}`, payload);
      } catch (error) {
        throw error;
      }
    },

    async doGetFormDetails(payload: { formId: string }, rootState: IRootState) {
      try {
        const { data } = await apiClient.get('/api/portal/account/forms/' + payload.formId + '/latest');
        if (data) {
          const title = { formTitle: data.name, formDescription: data.description };
          const elements = withBackwardsCompatibleChildElements(JSON.parse(data.formElements));
          const status = data.status;
          const version = data.version;
          const formUsedInActiveWorkFlow = data.formUsedInActiveWorkFlow;
          const formType = data.formType;
          const formContent = { title, elements, status, version, formUsedInActiveWorkFlow, formType };
          dispatch.formBuilderStore.setCurrentForm(formContent);
          return formContent;
        }
      } catch (error) {
        throw error;
      }
    },

    async doGetFormVersionHistory(payload: { formId: string }, rootState: IRootState) {
      try {
        const {
          data: { formRes },
        } = await apiClient.get('/api/portal/account/forms/' + payload.formId + '/versions');
        if (formRes) {
          return formRes;
        }
      } catch (error) {
        throw error;
      }
    },

    async doGetFormDetailsByVersion(
      payload: { formId: string; versionId: string; isWorkflowFormVersion: boolean },
      rootState: IRootState,
    ) {
      try {
        const { data } = await apiClient.get(
          `/api/portal/account/forms/${payload.formId}/versions/${
            payload.versionId
          }?isWorkflowFormVersion=${!!payload.isWorkflowFormVersion}`,
        );
        if (data) {
          const title = { formTitle: data.name, formDescription: data.description };
          const elements = withBackwardsCompatibleChildElements(JSON.parse(data.formElements));
          const status = data.status;
          const version = data.version;
          const formType = data.formType;
          const formContent = { title, elements, status, version, formType };
          dispatch.formBuilderStore.setCurrentForm(formContent);
          return formContent;
        }
      } catch (error) {
        throw error;
      }
    },

    async doArchiveForm(payload: { formId: string }, rootState: IRootState) {
      try {
        const { data } = await apiClient.put('/api/portal/account/forms/' + payload.formId + '/archive');
        if (data) {
          return data;
        }
      } catch (error) {
        throw error;
      }
    },

    async copyForm(payload: { formId: string }) {
      const { data } = await apiClient.post(`/api/portal/account/forms/${payload.formId}/copy`);
      return data.form;
    },

    async doReActivateForm(payload: { formId: string }, rootState: IRootState) {
      try {
        const { data } = await apiClient.put('/api/portal/account/forms/' + payload.formId + '/active');
        if (data) {
          return data;
        }
      } catch (error) {
        throw error;
      }
    },

    async doFetchWorkflowsFormUsage(payload: IFormUsagePayload, rootState: IRootState) {
      try {
        const { formId, ...restPayload } = payload;
        const {
          data: { workflows },
        } = await apiClient.post(`api/portal/account/forms/${payload.formId}/workflows`, restPayload);
        return workflows;
      } catch (error) {
        throw error;
      }
    },

    async getDocumentAndFile(documents: string[]) {
      try {
        return apiClient.post('/api/portal/account/forms/documents/add', {
          documents: documents,
        });
      } catch (error) {
        throw error;
      }
    },

    async fetchFormTemplateList(payload: { formType: FormType }) {
      const response: { data: (IFormTemplateResponse & { formElements: string })[] } = await apiClient.get(
        `api/portal/account/forms/templates/list?formType=${payload.formType}`,
      );

      return response.data.map((template) => ({
        ...template,
        formElements: withBackwardsCompatibleChildElements(template.formElements),
      }));
    },
  }),
};

export default formBuilderStore;
