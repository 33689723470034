import React, { Component } from 'react';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { Row } from 'antd';

interface IBookingRevokeApprovalModalProps {
  isOpen: boolean;
  onClose: () => void;
  doRevokeBookingApproval: typeof dispatch.bookingsStore.doRevokeBookingApproval;
}

interface IBookingRevokeApprovalModalState {
  canManuallyClose: boolean;
  isLoading: boolean;
}

class BookingRevokeApprovalModal extends Component<IBookingRevokeApprovalModalProps, IBookingRevokeApprovalModalState> {
  state = {
    canManuallyClose: true,
    isLoading: false,
  };

  private _onRevoke = async () => {
    this.setState({ isLoading: true });
    const { doRevokeBookingApproval } = this.props;
    await doRevokeBookingApproval({});
    this.props.onClose();
    this.setState({ isLoading: false });
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    let { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={'Revoke approval'}
        width="medium"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div className="anim-slide-left">
          <Paragraph>Are you sure you want to revoke approval for this booking?</Paragraph>

          <Paragraph>
            This will remove all line items attached to the booking from payments. The booking can be re-approved at a
            later date.
          </Paragraph>
        </div>
        <div className={'mt-large'}>
          <Row type={'flex'} justify={'end'}>
            <SecondaryButton
              className="mr-medium"
              size="large"
              onClick={this._onCloseModal}
              loading={this.state.isLoading}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._onRevoke} loading={this.state.isLoading}>
              Revoke
            </PrimaryButton>
          </Row>
        </div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRevokeBookingApproval: dispatch.bookingsStore.doRevokeBookingApproval,
});

export default connect(mapState, mapDispatch)(BookingRevokeApprovalModal);
