import { useQuery } from '@tanstack/react-query';
import { fetchCustomerNoteGoals } from 'stores/queries/note/note-goals-queries';
import type { NoteCardItemProps } from 'common-components/notes/note-card-item';

export const useNoteGoals = ({
  noteType,
  customerId,
  noteId,
}: Pick<NoteCardItemProps, 'noteType'> & {
  customerId: string | undefined;
  noteId: string;
}) =>
  useQuery(['note-goals', noteType, customerId, noteId], () => {
    if (!customerId) return null; // if no customerId, maybe portalUser hasn't loaded?

    if (noteType !== 'GENERAL') return null; // if note type is not customer, note type is not yet supported.

    return fetchCustomerNoteGoals({ customerId, noteId });
  });
