import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notifications } from '@good/ui/notifications';
import { Loader } from '@good/ui/core';
import { CheckCircle, CrossCircle } from '@good/icons';
import firebaseApp from 'stores/firebase-app';
import { type IRootState, type IRootDispatch } from 'stores/rematch/root-store';
import { useNewRelicBrowser } from 'integrations/new-relic-browser';

/**
 * Custom hook for logging out the user.
 * @returns A function that logs out the user and returns a Promise that resolves when the logout is complete.
 */
export function useLogout(): () => Promise<void> {
  const {
    authStore: { setIsSignOut, setCurrentUser, setCurrentPortalUser, setPortalUserList, setIsForgetPassword },
  } = useDispatch<IRootDispatch>();
  const notSaveStepData = useSelector((state: IRootState) => state.workflowStore.notSaveStepData);
  const newRelicBrowser = useNewRelicBrowser();

  const logout = useCallback(async () => {
    if (!notSaveStepData) {
      setIsForgetPassword(false);
      try {
        setIsSignOut(true);
        notifications.show({
          message: 'Signing out...',
          color: 'blue',
          icon: <Loader />,
          id: 'sign-out',
        });

        await firebaseApp.auth.signOut();
        notifications.update({
          id: 'sign-out',
          message: 'Signed out.',
          color: 'green',
          icon: <CheckCircle />,
        });

        setCurrentUser(null);
        setCurrentPortalUser(null);
        setPortalUserList(null);
      } catch (e) {
        newRelicBrowser?.noticeError(e);
        notifications.update({
          id: 'sign-out',
          message: 'Signed out failed.',
          color: 'red',
          icon: <CrossCircle />,
        });
      } finally {
        setIsSignOut(false);
      }
    }
  }, [
    newRelicBrowser,
    notSaveStepData,
    setCurrentPortalUser,
    setCurrentUser,
    setIsForgetPassword,
    setIsSignOut,
    setPortalUserList,
  ]);

  return logout;
}
