import { AlertCircle, AlertTriangle, CheckCircle, Info } from '@good/icons';
import { Children, forwardRef, isValidElement } from 'react';
import { filterDOMProps } from '@react-aria/utils';

import * as styles from './alert.css';
import { Heading, HeadingProvider } from '../heading';
import { Text, TextProvider } from '../text';
import { twMerge } from '../utils';

import type { AlertVariants } from './alert.css';
import type { HTMLProps } from '../types';
import type { ReactNode } from 'react';

export type AlertProps = AlertVariants & HTMLProps<HTMLDivElement>;

export const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  const { className, emphasis = 'fill', tone, ...otherProps } = props;
  let heading;
  let text;
  const other: ReactNode[] = [];

  const IconElement = {
    critical: AlertCircle,
    info: Info,
    notice: AlertTriangle,
    positive: CheckCircle,
  }[tone];

  Children.forEach(props.children, (child) => {
    if (!isValidElement(child)) return;
    if (child.type === Heading) heading = child;
    else if (child.type === Text) text = child;
    else other.push(child);
  });

  const { container, content, icon } = styles.alert({ emphasis, tone });

  return (
    <div {...filterDOMProps(otherProps)} className={twMerge(container(), className)} ref={ref}>
      {IconElement && <IconElement className={icon()} />}

      {!!(heading || text) && (
        <div className={content()}>
          <HeadingProvider size="xs" className="leading-[22px]">
            {heading}
          </HeadingProvider>

          <TextProvider size="sm">{text}</TextProvider>
        </div>
      )}

      {/*
        TODO (Mitch): add actions when link and link provider are
        merged
        {other.length > 0 && <div className={actions()}>{other}</div>}
      */}
    </div>
  );
});
