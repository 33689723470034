import React, { Component } from 'react';
import _ from 'lodash';
import { Empty } from 'antd';

import { FieldLabel, Text } from 'common-components/typography';
import { IBillingLineItem } from 'interfaces/booking-interfaces';
import { LineItemRow } from 'views/group-services/manage-booking-modal/panels/billings/LineItemRow';
import CommonUtils from 'utilities/common-utils';
import type { FundingNdisSupportCategory } from 'interfaces/funding-interfaces';

interface ILineItemsTableProps {
  lineItems: IBillingLineItem[];
  onEditLineItem: (lineItem: IBillingLineItem, attendanceId: string) => void;
  onDeleteLineItem: (lineItem: IBillingLineItem, attendanceId: string) => void;
  fundedCategories: FundingNdisSupportCategory[];
  canAddEditLineItem: boolean;
  tabType: string;
  onChangeBillable?: (lineItem: IBillingLineItem, attendanceId: string) => void;
}

export class LineItemsTable extends Component<ILineItemsTableProps> {

  render() {
    const { lineItems, onEditLineItem, onDeleteLineItem, fundedCategories, onChangeBillable } = this.props;

    const hasLineItems = !_.isEmpty(lineItems);

    const currentBillingTotal = _.reduce(
      lineItems,
      (acc, lineItem) => acc + Number(lineItem.billingTotal) * (1 - Number(lineItem.discount)),
      0,
    );

    return (
      <div>
        <table className="width-full">
          <thead>
            <tr className="bordered-bottom border-standard-gray">
              <th className="ph-medium pv-small" style={{ width: '288px' }}>
                <FieldLabel text={'Line item'} />
              </th>
              <th className="ph-medium pv-small">
                <FieldLabel text={'Type'} />
              </th>
              <th className="ph-medium pv-small">
                <FieldLabel text={'Cost'} />
              </th>
              <th className="ph-x-small pv-small">
                <FieldLabel text={''} />
              </th>
              <th className="ph-medium pv-small text-align-right" style={{ width: '128px' }}>
                <FieldLabel text={'Total'} />
              </th>
              <th className="ph-medium pv-small" style={{ width: '60px' }}>
                {/*<FieldLabel text={'Time'} />*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {/* No line items */}
            {!hasLineItems && (
              <td colSpan={5}>
                <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE}>
                  <Text color="secondary" size="large">
                    No line items found for this booking.
                  </Text>
                </Empty>
              </td>
            )}

            {/* Has line items */}
            {hasLineItems &&
              lineItems.map((lineItem) => (
                <>
                  <LineItemRow
                    key={lineItem.bookingBillingLineItemId}
                    tabType={this.props.tabType}
                    lineItem={lineItem}
                    fundedCategories={fundedCategories}
                    onEditLineItem={onEditLineItem}
                    onDeleteLineItem={onDeleteLineItem}
                    onChangeBillable={onChangeBillable}
                    canAddEditLineItem={this.props.canAddEditLineItem}
                  />
                </>
              ))}
          </tbody>
        </table>

        <div className="bg-tertiary p-medium bordered border-standard-gray flex-row align-center rounded mt-x2-large">
          <div className="flex-1 line-height-100">
            <Text lineHeight={100} color={'tertiary'} size={'small'} weight="bold">
              TOTAL
            </Text>
          </div>
          <div>
            <Text>{CommonUtils.formatPrice(currentBillingTotal)}</Text>
          </div>

          {/* Spacer */}
          <div style={{ width: '64px' }} />
        </div>
      </div>
    );
  }
}
