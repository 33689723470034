import React, { useEffect, useState } from 'react';
import { isEmpty, cloneDeep, map } from 'lodash';
import moment from 'moment';
import * as H from 'history';
import { connect } from 'react-redux';
import { Popover } from '@blueprintjs/core';
import { Avatar, Col, Empty, notification, Row, Skeleton } from 'antd';

import { ICustomerForm } from 'interfaces/customer-interfaces';
import { SubTitle, Text, Title } from 'common-components/typography';
import { IConflictFormData, IElementValue } from 'views/form-builder/shared/form-interface';
import DeleteCustomerFormModal from './components/DeleteCustomerFormModal';
import EditValueFormModal from 'views/form-builder/components/EditValueFormModal';
import AttachFormModalWrapper from 'views/form-builder/components/AttachFormModal';
import { HyperlinkButton, IconButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ActionMenu, ActionMenuDivider, ActionMenuItem } from 'common-components/action-menu';
import { StatusTag } from 'common-components/tags';
import ConflictFormDataModal from 'views/form-builder/components/ConflictFormDataModal';
import { IntakeFormComponentsTabs } from 'views/form-builder/shared/form-enum';
import { CustomerDetailsTabKey } from 'utilities/enum-utils';

const FormEmptyPanel = () => (
  <>
    <Row className="pv-medium bordered-bottom">
      <Col span={8}>
        <SubTitle>FORM NAME</SubTitle>
      </Col>
      <Col span={8}>
        <SubTitle>DATE ADDED</SubTitle>
      </Col>
      <Col span={8}>
        <SubTitle>ADDED BY</SubTitle>
      </Col>
    </Row>
    <div className="align-center flex-column pt-x2-large flex-1 bg-white">
      <div className="mb-small">
        <Empty className="m-none" description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      <Text size="x2-large" color="secondary" weight="bold">
        Nothing here yet.
      </Text>
      <Text color="secondary">Select ‘Add form’ to get started.</Text>
    </div>
  </>
);

interface ICustomerFormsPanelProps {
  history: H.History;
  isNotGuardian: boolean;
  currentForm: typeof state.formBuilderStore.currentForm;
  customerForms: typeof state.customersStore.customerForms;
  selectedCustomerUserId: typeof state.customersStore.selectedCustomerUserId;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  doAddCustomerForm: typeof dispatch.customersStore.doAddCustomerForm;
  doFetchFormDetailsByUserFormId: typeof dispatch.customersStore.doFetchFormDetailsByUserFormId;
  doUpdateCustomerForm: typeof dispatch.customersStore.doUpdateCustomerForm;
  doFetchCustomerForms: typeof dispatch.customersStore.doFetchCustomerForms;
  doDeleteCustomerForm: typeof dispatch.customersStore.doDeleteCustomerForm;
  doGetCustomer: typeof dispatch.customersStore.doGetCustomer;
  doFetchSupportCoordinators: typeof dispatch.customersStore.doFetchSupportCoordinators;
  doFetchCareInfoList: typeof dispatch.customersStore.doFetchCareInfoList;

  doUpdateBookingForm: typeof dispatch.bookingsStore.doUpdateBookingForm;
  doDeleteBookingForm: typeof dispatch.bookingsStore.doDeleteBookingForm;
  doFetchFormDetailByAttendanceFormId: typeof dispatch.bookingsStore.doFetchFormDetailByAttendanceFormId;

  doUpdateSessionForm: typeof dispatch.groupServiceStore.doUpdateSessionForm;
  doFetchFormDetailByServiceDateTimeFormId: typeof dispatch.groupServiceStore.doFetchFormDetailByServiceDateTimeFormId;
  doDeleteSessionForm: typeof dispatch.groupServiceStore.doDeleteSessionForm;
}

const CustomerFormsPanel = ({
  history,
  isNotGuardian,
  currentForm,
  customerForms,
  selectedCustomerUserId,
  selectedCustomer,
  doUpdateCustomerForm,
  doFetchFormDetailsByUserFormId,
  doFetchCustomerForms,
  doAddCustomerForm,
  doUpdateBookingForm,
  doUpdateSessionForm,
  doFetchFormDetailByAttendanceFormId,
  doFetchFormDetailByServiceDateTimeFormId,
  doDeleteBookingForm,
  doDeleteSessionForm,
  doDeleteCustomerForm,
  doGetCustomer,
  doFetchSupportCoordinators,
  doFetchCareInfoList,
}: ICustomerFormsPanelProps) => {
  const [isOpenAddFormModal, setIsOpenAddFormModal] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isViewFormModalOpen, setIsViewFormModalOpen] = useState<boolean>(false);
  const [selectedCustomerForm, setSelectedCustomerForm] = useState<ICustomerForm>(null);
  const [fetchedAttachForm, setFetchedAttachForm] = useState<any>(null);
  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  const [isConflictFormDataModalOpen, setIsConflictFormDataModalOpen] = useState<boolean>(false);
  const [conflictFormData, setConflictFormData] = useState<{ [formElementId: string]: IConflictFormData }>(null);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);

  const _goToBookingDetail = (bookingId) => {
    history.push(`/bookings/details/${bookingId}`);
  };

  const _goToServiceDetail = (serviceId) => {
    history.push(`/group-service/details/${serviceId}`);
  };

  const _handleOpenAddFormModal = () => {
    setIsOpenAddFormModal(true);
  };

  const _handleCloseAddFormModal = () => {
    setIsOpenAddFormModal(false);
  };

  const _handleCloseViewEditFormModal = () => {
    setIsViewFormModalOpen(false);
    setIsEditForm(false);
    setFetchedAttachForm(null);
  };

  const _handleCloseDeleteModal = async () => {
    setIsDeleteModalOpen(false);
    handleLoadFormList();
  };

  const handleCloseConflictFormDataModal = () => {
    setIsConflictFormDataModalOpen(false);
  };

  const _handleViewFormAttach = async (customerForm: ICustomerForm) => {
    let data;
    if (customerForm.booking) {
      data = await doFetchFormDetailByAttendanceFormId({
        bookingId: customerForm.booking.bookingId,
        attendanceFormId: customerForm.id,
      });
    } else if (customerForm.session) {
      data = await doFetchFormDetailByServiceDateTimeFormId({
        serviceId: customerForm.session.serviceId,
        serviceDateTimeId: customerForm.session.serviceDateTimeId,
        serviceDateTimeFormId: customerForm.id,
      });
    } else {
      const payload = {
        userFormId: customerForm.id,
        customerId: selectedCustomerUserId,
      };
      data = await doFetchFormDetailsByUserFormId(payload);
    }

    setIsEditForm(false);
    setSelectedCustomerForm(customerForm);
    setFetchedAttachForm(data);
    setIsViewFormModalOpen(true);
  };

  const _handleEditFormAttach = async (customerForm: ICustomerForm) => {
    let data;
    if (customerForm.booking) {
      data = await doFetchFormDetailByAttendanceFormId({
        bookingId: customerForm.booking.bookingId,
        attendanceFormId: customerForm.id,
      });
    } else if (customerForm.session) {
      data = await doFetchFormDetailByServiceDateTimeFormId({
        serviceId: customerForm.session.serviceId,
        serviceDateTimeId: customerForm.session.serviceDateTimeId,
        serviceDateTimeFormId: customerForm.id,
      });
    } else {
      const payload = {
        userFormId: customerForm.id,
        customerId: selectedCustomerUserId,
      };
      data = await doFetchFormDetailsByUserFormId(payload);
    }

    setIsEditForm(true);
    setSelectedCustomerForm(customerForm);
    setFetchedAttachForm(data);
    setIsViewFormModalOpen(true);
  };

  const _handleExportPdf = (customerForm) => {
    if (customerForm.booking) {
      window.open(`/pdf?type=view-form&attendanceFormId=${customerForm.id}`, '_blank');
    } else if (customerForm.session) {
      window.open(`/pdf?type=view-form&serviceDateTimeFormId=${customerForm.id}`, '_blank');
    } else {
      window.open(`/pdf?type=view-form&userFormId=${customerForm.id}`, '_blank');
    }
  };

  const _handleDeleteFormAttach = (customerForm: ICustomerForm) => {
    setSelectedCustomerForm(customerForm);
    setIsDeleteModalOpen(true);
  };

  const getTabDataAfterCompleteAndSyncForm = async (tabSyncDataSuccess: IntakeFormComponentsTabs[]) => {
    if (isEmpty(tabSyncDataSuccess)) {
      return;
    }

    try {
      await doGetCustomer({ userId: selectedCustomerUserId });
      if (tabSyncDataSuccess.includes(IntakeFormComponentsTabs.FUNDING_TAB_ELEMENTS)) {
        await doFetchSupportCoordinators({ userId: selectedCustomerUserId });
      }
      if (tabSyncDataSuccess.includes(IntakeFormComponentsTabs.CARE_INFORMATION_TAB_ELEMENTS)) {
        await doFetchCareInfoList(selectedCustomer);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const _doSave = async (
    formId: string,
    elementValues: IElementValue[],
    formName: string,
    versionId: string,
    status: string,
    isSyncCustomerData: boolean,
  ) => {
    try {
      const payload = {
        customerId: selectedCustomerUserId,
        formId: formId,
        formData: elementValues,
        status,
        isSyncCustomerData,
      };
      const data = await doAddCustomerForm(payload);
      notification.open({
        message: <span className={'text-weight-bolder'}>Form attached</span>,
        description: <span>You have attached a form to this customer’s profile.</span>,
      });

      if (isSyncCustomerData) {
        if (!isEmpty(data.conflictData)) {
          setConflictFormData(data.conflictData);
          setIsConflictFormDataModalOpen(true);
        }
        if (!isEmpty(data.tabSyncDataSuccess)) {
          getTabDataAfterCompleteAndSyncForm(data.tabSyncDataSuccess);
        }
      }
      handleLoadFormList();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  const _doSaveEditFormAttach = async (
    id: string,
    elementValues: IElementValue[],
    status: string,
    isSyncCustomerData: boolean,
  ) => {
    try {
      let data = null;

      if (selectedCustomerForm.booking) {
        const payload = {
          bookingId: selectedCustomerForm.booking.bookingId,
          attendanceFormId: selectedCustomerForm.id,
          formData: elementValues,
          status,
        };
        await doUpdateBookingForm(payload);
      } else if (selectedCustomerForm.session) {
        const payload = {
          serviceId: selectedCustomerForm.session.serviceId,
          serviceDateTimeId: selectedCustomerForm.session.serviceDateTimeId,
          serviceDateTimeFormId: selectedCustomerForm.id,
          formData: elementValues,
          status,
        };
        await doUpdateSessionForm(payload);
      } else {
        const payload = {
          customerId: selectedCustomerUserId,
          userFormId: selectedCustomerForm.id,
          formData: elementValues,
          status,
          isSyncCustomerData,
        };
        data = await doUpdateCustomerForm(payload);
      }
      _handleCloseViewEditFormModal();
      notification.open({
        message: <span className={'text-weight-bolder'}>Form updated</span>,
        description: <span>You have successfully updated a form on this customer’s profile.</span>,
      });
      if (isSyncCustomerData) {
        if (!isEmpty(data.conflictData)) {
          setConflictFormData(data.conflictData);
          setIsConflictFormDataModalOpen(true);
        }
        if (!isEmpty(data.tabSyncDataSuccess)) {
          getTabDataAfterCompleteAndSyncForm(data.tabSyncDataSuccess);
        }
      }
      await handleLoadFormList();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  const _doDeleteFormAttach = async () => {
    try {
      if (selectedCustomerForm.booking) {
        const payload = {
          bookingId: selectedCustomerForm.booking.bookingId,
          attendanceFormId: selectedCustomerForm.id,
        };
        await doDeleteBookingForm(payload);
      } else if (selectedCustomerForm.session) {
        const payload = {
          serviceId: selectedCustomerForm.session.serviceId,
          serviceDateTimeId: selectedCustomerForm.session.serviceDateTimeId,
          serviceDateTimeFormId: selectedCustomerForm.id,
        };
        await doDeleteSessionForm(payload);
      } else {
        const payload = {
          customerId: selectedCustomerUserId,
          userFormId: selectedCustomerForm.id,
        };
        await doDeleteCustomerForm(payload);
      }

      _handleCloseDeleteModal();
      notification.open({
        message: <span className={'text-weight-bolder'}>Form removed</span>,
        description: <span>You have successfully removed a form from this customer’s profile.</span>,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  const _displayFormFrom = (form: ICustomerForm) => {
    if (form.booking) {
      return (
        <Text>
          Booking:{' '}
          <HyperlinkButton onClick={() => _goToBookingDetail(form.booking.bookingId)}>
            {form.booking.batchNumber}
            {!form.booking.batchNumber && `TB${form.booking.bookingId.slice(0, 5)}`}
          </HyperlinkButton>
        </Text>
      );
    }
    if (form.session) {
      return (
        <Text>
          Session:{' '}
          <HyperlinkButton onClick={() => _goToServiceDetail(form.session.serviceId)}>Group Services</HyperlinkButton>
        </Text>
      );
    }
    return <Text>Profile</Text>;
  };

  const handleLoadFormList = async () => {
    try {
      setIsLoadingForm(true);
      await doFetchCustomerForms({ customerUserId: selectedCustomerUserId });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingForm(false);
    }
  };

  useEffect(() => {
    handleLoadFormList();
    if (history.location.state?.selectedTab === CustomerDetailsTabKey.FORMS && customerForms.length < 1) {
      _handleOpenAddFormModal();
      const state = cloneDeep(history.location.state);
      delete state.selectedTab;
      window.history.replaceState(state, '');
    }
  }, []);

  return (
    <>
      <AttachFormModalWrapper
        isNotGuardian={isNotGuardian}
        isCustomerForm
        timezone={moment.tz.guess()}
        isOpen={isOpenAddFormModal}
        onClose={_handleCloseAddFormModal}
        onSave={_doSave}
      />

      {(isViewFormModalOpen || isEditForm) && fetchedAttachForm != null && (
        <EditValueFormModal
          formId={selectedCustomerForm.id}
          formElements={currentForm}
          elementValues={fetchedAttachForm.formData}
          isOpen={isViewFormModalOpen}
          onClose={_handleCloseViewEditFormModal}
          onSave={_doSaveEditFormAttach}
          mode={isEditForm ? 'EDIT' : 'VIEW'}
        />
      )}

      {isDeleteModalOpen && selectedCustomerForm && (
        <DeleteCustomerFormModal
          isOpen={isDeleteModalOpen}
          onDelete={_doDeleteFormAttach}
          onClose={_handleCloseDeleteModal}
        />
      )}

      {conflictFormData && (
        <ConflictFormDataModal
          isOpen={isConflictFormDataModalOpen}
          onClose={handleCloseConflictFormDataModal}
          conflicts={conflictFormData}
        />
      )}

      <Row type={'flex'} justify={'space-between'} align={'bottom'} className="mb-x-large">
        <Col>
          <Title level={2} className={'m-none'}>
            Forms
          </Title>
          <Text type={'secondary'}>Add and manage all forms related to this team member.</Text>
        </Col>
        <Col className={'align-center'}>
          <div className="align-center flex">
            <PrimaryButton size="large" onClick={_handleOpenAddFormModal}>
              + Add form
            </PrimaryButton>
          </div>
        </Col>
      </Row>

      <div className="mb-x2-large">
        {isLoadingForm ? (
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
        ) : !isEmpty(customerForms) ? (
          <>
            <Row className="pv-medium bordered-bottom">
              <Col span={5}>
                <SubTitle>FORM NAME</SubTitle>
              </Col>
              <Col span={4}>
                <SubTitle>DATE ADDED</SubTitle>
              </Col>
              <Col span={5}>
                <SubTitle>ADDED FROM</SubTitle>
              </Col>
              <Col span={3}>
                <SubTitle>STATUS</SubTitle>
              </Col>
              <Col span={6}>
                <SubTitle>ADDED BY</SubTitle>
              </Col>
              <Col span={1} />
            </Row>

            {map(customerForms, (form: ICustomerForm, index) => (
              <Row type="flex" align="middle" className="pv-medium" key={index}>
                <Col span={5}>
                  <HyperlinkButton onClick={() => _handleViewFormAttach(form)}>{form.formName}</HyperlinkButton>
                </Col>
                <Col span={4}>{moment(form.addedOn).format('DD MMM YYYY')}</Col>
                <Col span={5}>
                  <Text>{_displayFormFrom(form)}</Text>
                </Col>
                <Col span={3}>
                  <StatusTag status={form.status} />
                </Col>
                <Col span={6}>
                  <Avatar className="mr-medium" size="default" icon="user" src={form.addedByAvatarUrl} />
                  <Text>{form.addedByName}</Text>
                </Col>
                <Col span={1} className="text-align-right pr-small">
                  <Popover
                    content={
                      <ActionMenu>
                        <>
                          <ActionMenuItem text="View form" onClick={() => _handleViewFormAttach(form)} />
                          <ActionMenuItem text="Edit form" onClick={() => _handleEditFormAttach(form)} />
                          <ActionMenuDivider />
                          <ActionMenuItem text="Export as PDF" onClick={() => _handleExportPdf(form)} />
                          <ActionMenuItem
                            text="Remove"
                            className="text-color-red"
                            onClick={() => _handleDeleteFormAttach(form)}
                          />
                        </>
                      </ActionMenu>
                    }
                  >
                    <IconButton
                      icon="ellipsis"
                      size="small"
                      className="bg-white"
                      bordered={true}
                      iconColor="secondary"
                      onClick={() => {}}
                    />
                  </Popover>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <FormEmptyPanel />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentForm: state.formBuilderStore.currentForm,
  customerForms: state.customersStore.customerForms,
  selectedCustomerUserId: state.customersStore.selectedCustomerUserId,
  selectedCustomer: state.customersStore.selectedCustomer,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doAddCustomerForm: dispatch.customersStore.doAddCustomerForm,
  doFetchCustomerForms: dispatch.customersStore.doFetchCustomerForms,
  doFetchFormDetailsByUserFormId: dispatch.customersStore.doFetchFormDetailsByUserFormId,
  doUpdateCustomerForm: dispatch.customersStore.doUpdateCustomerForm,
  doDeleteCustomerForm: dispatch.customersStore.doDeleteCustomerForm,
  doGetCustomer: dispatch.customersStore.doGetCustomer,
  doFetchSupportCoordinators: dispatch.customersStore.doFetchSupportCoordinators,
  doFetchCareInfoList: dispatch.customersStore.doFetchCareInfoList,

  doUpdateBookingForm: dispatch.bookingsStore.doUpdateBookingForm,
  doFetchFormDetailByAttendanceFormId: dispatch.bookingsStore.doFetchFormDetailByAttendanceFormId,
  doDeleteBookingForm: dispatch.bookingsStore.doDeleteBookingForm,

  doFetchFormDetailByServiceDateTimeFormId: dispatch.groupServiceStore.doFetchFormDetailByServiceDateTimeFormId,
  doUpdateSessionForm: dispatch.groupServiceStore.doUpdateSessionForm,
  doDeleteSessionForm: dispatch.groupServiceStore.doDeleteSessionForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFormsPanel);
