import { Icon } from 'antd';
import moment from 'moment-timezone';
import React from 'react';

import { Text } from 'common-components/typography';
import { IAssignedWorker } from 'interfaces/assign-customer-interfaces';
import { IBookingRatio } from 'interfaces/booking-interfaces';
import { IBookingTimeSlot } from 'interfaces/session-interfaces';

interface IUnassignedRatioRowProps {
  ratio: IBookingRatio;
  supportWorkers?: IAssignedWorker[];
  onChangeTeamMember: (timeSlot: IBookingTimeSlot) => void;
  timezone: string;
}

const UnassignedRatioRow = (props: IUnassignedRatioRowProps): JSX.Element => {
  const { ratio, supportWorkers, onChangeTeamMember, timezone } = props;
  const { startDateTime, endDateTime, teamMemberCustomerRatio, attendanceTimeSlotId } = ratio;
  const momentStartDateTime = moment.tz(startDateTime, timezone).format('h:mm A');
  const momentEndDateTime = moment.tz(endDateTime, timezone).format('h:mm A');
  const supportWorkerIds = supportWorkers?.map((supportWorker) => supportWorker.supportWorkerUserId) || [];
  const timeSlot = {
    startDateTime,
    endDateTime,
    attendanceTimeSlotId,
    teamMemberCustomerRatio,
    supportWorkerIds,
  };

  return (
    <>
      <div>
        <Icon type="warning" theme="filled" className="text-color-warning-orange mr-small ph-x-small" />
        <Text size="regular">
          <span>{`Unassigned ${teamMemberCustomerRatio} between ${momentStartDateTime} - ${momentEndDateTime}`}</span>
        </Text>
      </div>
      <div>
        <Text size="regular" className="cursor-pointer text-color-blue-action">
          <span onClick={() => onChangeTeamMember(timeSlot)}>Assign team member</span>
        </Text>
      </div>
    </>
  );
};

export default UnassignedRatioRow;
