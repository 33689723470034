import z from 'zod';
import { useQuery } from '@tanstack/react-query';

import apiClient, { pickData } from '../../../../utilities/api-client';
import { budgetQueryKeys } from '../../query-keys';
import { CustomerBudgetTracking, fetchBudgetTrackingForCustomer } from '../../services';

import type { BudgetKeys } from '../../query-keys';
import type { QueryFunctionContext, UseQueryResult } from '@tanstack/react-query';
import { supportCoordinationTrackingSchema } from '../../budget';

const budgetStatusSchema = z.enum([
  'NONE',
  'ON_TRACK',
  'OVERBUDGET',
  'WITHIN_BUDGET',
  'SIGNIFICANTLY_AHEAD',
  'SIGNIFICANTLY_BEHIND',
  'SLIGHTLY_AHEAD',
  'SLIGHTLY_BEHIND',
]);

export const BUDGET_STATUS = budgetStatusSchema.enum;
export type BudgetStatus = z.infer<typeof budgetStatusSchema>;

const customerSchema = z.object({
  userId: z.string().uuid(),
  avatarUrl: z.optional(z.string().url().or(z.literal('')).or(z.null())),
  displayName: z.string(),
});

const paceSchema = z.object({
  actual: z.number(),
  ideal: z.number(),
  planned: z.number(),
});

const serviceAgreementSchema = z.object({
  startDate: z.string().transform((d) => new Date(d)),
  endDate: z.string().transform((d) => new Date(d)),
  status: z.enum(['ACTIVE', 'EXPIRING_SOON']),
});

const budgetTrackingResponseSchema = z.object({
  serviceId: z.string().uuid(),
  serviceName: z.string(),
  trackingStatus: budgetStatusSchema,
  startDate: z.string().transform((d) => new Date(d)),
  endDate: z.string().transform((d) => new Date(d)),
  categoryTracking: z.object({
    quoted: z.number(),
    spent: z.number(),
    balance: z.number(),
    status: budgetStatusSchema,
    supportCategories: z.array(
      z.object({
        balance: z.number(),
        projectedBalance: z.number(),
        projectedSpend: z.number(),
        quoted: z.number(),
        spent: z.number(),
        spentPercentage: z.number().nullable(),
        status: budgetStatusSchema,
        supportCategoryNumber: z.number(),
        tracking: z.number(),
        spendGraph: z.unknown(),
        pace: z.object({
          DAY: paceSchema,
          WEEK: paceSchema,
          MONTH: paceSchema,
          YEAR: paceSchema,
          QUARTER: paceSchema,
        }),
        supportItemTracking: z.unknown(),
        supportCoordinationTracking: supportCoordinationTrackingSchema.optional(),
      }),
    ),
  }),
});

export type BudgetTrackingResponse = z.infer<typeof budgetTrackingResponseSchema>;

export const budgetResponseSchema = z.object({
  budgetTrackingByService: z.record(z.string().uuid(), budgetTrackingResponseSchema),
  customer: customerSchema,
  serviceAgreement: serviceAgreementSchema,
});

export type BudgetResponse = z.infer<typeof budgetResponseSchema>;

/**
 * @name
 * validateBudgetResponse
 *
 * @description
 * Validates API response against expected schema.
 *
 * @example
 * Promise.then(({ data }) => data).then(validateBudgetResponse)
 */
function validateBudgetResponse(data: unknown): BudgetResponse {
  try {
    return budgetResponseSchema.parse(data);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * @name
 * fetchBudget
 *
 * @description
 * Fetch budget information for a customer's service agreement.
 */
export async function fetchBudget(ctx: QueryFunctionContext<BudgetKeys['budget']>): Promise<BudgetResponse> {
  let { queryKey } = ctx;
  let [budgetsKey, budgetKey, agreementId] = queryKey;

  return apiClient
    .get(`/api/portal/budgeting/service-agreements/${agreementId}`)
    .then(pickData)
    .then(validateBudgetResponse);
}

/**
 * @name
 * useBudget
 *
 * @description
 * Thin wrapper around `useQuery` to fetch budget information.
 *
 * @example
 *  let { data, isError, isLoading, isSuccess } = useBudget(agreementId)
 */
export function useBudget(agreementId: string): UseQueryResult<BudgetResponse> {
  return useQuery(budgetQueryKeys.budget(agreementId), fetchBudget);
}

/**
 * @name
 * useCustomerBudgetStatus
 */
export function useCustomerBudgetStatus(customerId: string): UseQueryResult<CustomerBudgetTracking> {
  return useQuery(budgetQueryKeys.customerBudget(customerId), fetchBudgetTrackingForCustomer);
}
