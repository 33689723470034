import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FaceId = forwardRef(function FaceId(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.473 2.25H8a.75.75 0 0 1 0 1.5h-.5c-.71 0-1.203 0-1.59.027-.38.026-.602.074-.771.144A2.25 2.25 0 0 0 3.921 5.14c-.07.169-.118.39-.144.77-.027.388-.027.882-.027 1.591V8a.75.75 0 0 1-1.5 0v-.527c0-.676 0-1.222.03-1.666.032-.456.097-.86.256-1.242a3.75 3.75 0 0 1 2.029-2.03c.382-.158.786-.224 1.243-.255.444-.03.99-.03 1.665-.03ZM18.09 3.777c-.386-.027-.88-.027-1.59-.027H16a.75.75 0 1 1 0-1.5h.527c.676 0 1.222 0 1.666.03.456.031.86.097 1.242.255a3.75 3.75 0 0 1 2.03 2.03c.158.382.224.786.255 1.242.03.444.03.99.03 1.666V8a.75.75 0 0 1-1.5 0v-.5c0-.71 0-1.203-.027-1.59-.026-.38-.074-.602-.144-.771a2.25 2.25 0 0 0-1.218-1.218c-.169-.07-.39-.118-.77-.144ZM3 15.25a.75.75 0 0 1 .75.75v.5c0 .71 0 1.203.027 1.59.026.38.074.602.144.771.229.551.667.99 1.218 1.218.17.07.39.118.77.144.388.027.882.027 1.591.027H8a.75.75 0 0 1 0 1.5h-.527c-.676 0-1.221 0-1.665-.03-.457-.031-.86-.097-1.243-.255a3.75 3.75 0 0 1-2.03-2.03c-.158-.382-.224-.786-.255-1.242-.03-.445-.03-.99-.03-1.666V16a.75.75 0 0 1 .75-.75Zm18 0a.75.75 0 0 1 .75.75v.527c0 .676 0 1.221-.03 1.665-.031.457-.097.86-.255 1.243a3.75 3.75 0 0 1-2.03 2.03c-.382.158-.786.224-1.242.255-.444.03-.99.03-1.666.03H16a.75.75 0 1 1 0-1.5h.5c.71 0 1.204 0 1.59-.027.38-.026.602-.074.771-.144a2.25 2.25 0 0 0 1.218-1.218c.07-.169.118-.39.144-.77.027-.388.027-.882.027-1.591V16a.75.75 0 0 1 .75-.75Zm-9-8a.75.75 0 0 1 .75.75v4A1.75 1.75 0 0 1 11 13.75a.75.75 0 0 1 0-1.5.25.25 0 0 0 .25-.25V8a.75.75 0 0 1 .75-.75ZM8.75 8a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0V8ZM16 7.25a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0V8a.75.75 0 0 1 .75-.75Zm-7.4 7.3a.75.75 0 0 0-1.2.9 5.742 5.742 0 0 0 4.6 2.3 5.742 5.742 0 0 0 4.6-2.3.75.75 0 0 0-1.199-.9 4.242 4.242 0 0 1-3.4 1.7 4.243 4.243 0 0 1-3.401-1.7Z"
        fill="currentColor"
      />
    </Icon>
  );
});
