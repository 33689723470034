import { useDateFormatter as _useDateFormatter, type DateFormatterOptions } from 'react-aria';

export const useDateFormatter = _useDateFormatter;

type Options = Required<Pick<DateFormatterOptions, 'day' | 'month' | 'year'>>;
type DisplayKinds = 'calendar' | 'display';

export type DateFormatterProps = {
  value: Date;
  /**
   * @name kind
   *
   * @description
   * `calendar` - i.e. 15/08/2022
   * `display` - i.e. 15 Aug 2022
   *
   * @default
   * calendar
   */
  kind?: DisplayKinds;
};

export const DateFormatter = function DateTimeFormatter(props: DateFormatterProps) {
  const { value, kind = 'calendar' } = props;

  const options: Record<DisplayKinds, Options> = {
    calendar: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    },
    display: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
  } as const;

  const formatter = useDateFormatter(options[kind]);
  return <>{formatter.format(value)}</>;
};
