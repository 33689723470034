/* eslint-disable */
import React, { PureComponent } from 'react';
import CommonUtils from '../../utilities/common-utils';
import TextTag from 'common-components/tags/TextTag';

type AvailabilityRequestStatusType =
  | 'PENDING_APPROVAL'
  | 'APPROVED'
  | 'DECLINED'
  | 'REQUEST_CANCELLATION'
  | 'CANCELLED'
  | 'COMPLETED';

interface AvailabilityStatusTagProps {
  status: AvailabilityRequestStatusType;
  size?: 'small' | 'default' | 'large';
  textSize?: string;
  className?: any;
  textClassName?: any;
  rounded?: boolean;
  theme?: 'dark' | 'light';
  isFixedWidth?: boolean;
  width?: number;
}

export default class AvailabilityStatusTag extends PureComponent<AvailabilityStatusTagProps, any> {
  StatusColors = {
    PENDING_APPROVAL: { color: 'orange', theme: 'light' },
    APPROVED: { color: 'green', theme: 'light' },
    DECLINED: { color: 'secondary', theme: 'dark' },
    REQUEST_CANCELLATION: { color: 'turquoise', theme: 'light' },
    CANCELLED: { color: 'rose', theme: 'light' },
    COMPLETED: { color: 'indigo', theme: 'light' },
  };

  render() {
    const {
      status,
      className,
      textSize = 'large',
      size = 'default',
      rounded = true,
      isFixedWidth = false,
      width = 120,
    } = this.props;

    const color = this.StatusColors[status] ? this.StatusColors[status].color : 'blue';
    const theme = this.StatusColors[status] ? this.StatusColors[status].theme : 'dark';

    return (
      <TextTag
        theme={theme}
        color={color}
        textSize={textSize}
        weight="bold"
        size={size}
        className={className}
        content={CommonUtils.formatStatusString(status)}
        rounded={rounded}
        isFixedWidth={isFixedWidth}
        width={width}
      />
    );
  }
}
