import React from 'react';
import { Row } from 'antd';
import { ActivityGroupMemberType, ActivityGroupModalType } from 'utilities/enum-utils';
import { IGroupServiceActivityGroup } from 'src/interfaces/service-interfaces';
import { IActivityGroupUsers } from 'src/interfaces/user-interfaces';
import { openModalFuncType } from '../SessionActivityGroupsPanel';

interface IMemberPopoverContentProps {
  activityGroup: IGroupServiceActivityGroup;
  isUnAssigned?: boolean;
  member: IActivityGroupUsers;
  type: ActivityGroupMemberType;
  openModal: openModalFuncType;
}

function MemberPopoverContent(props: IMemberPopoverContentProps) {
  const { activityGroup, isUnAssigned, member, type, openModal } = props;
  const isCustomer = ActivityGroupMemberType.CUSTOMER === type;
  const memberText = isCustomer ? 'customer' : 'team member';

  return (
    <div>
      {isUnAssigned && (
        <>
          <Row
            className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
            onClick={() =>
              openModal(
                null,
                isCustomer
                  ? ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP
                  : ActivityGroupModalType.ASSIGN_TEAM_MEMBER_TO_ACTIVITY_GROUP,
                isCustomer ? ActivityGroupMemberType.CUSTOMER : ActivityGroupMemberType.TEAM_MEMBER,
                member,
              )
            }
          >
            Assign to activity group
          </Row>
          {/* <div className="bordered-top mv-small" /> */}
        </>
      )}

      {/* {isCustomer && !isUnAssigned && (
        <>
          <Row className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest">View schedule</Row>
          <div className="bordered-top mv-small" />
        </>
      )} */}

      {/* <Row className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest">View {memberText} profile</Row> */}

      {!isUnAssigned && (
        <>
          {/* <div className="bordered-top mv-small" /> */}
          <Row
            className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest text-color-red"
            onClick={() => openModal(activityGroup, ActivityGroupModalType.REMOVE, type, member)}
          >
            Remove {memberText}
          </Row>
        </>
      )}
    </div>
  );
}

export default MemberPopoverContent;
