import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FileDraft = forwardRef(function FileDraft(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 3.753c-.21-.002-.452-.003-.735-.003H11c-1.435 0-2.437.002-3.192.103-.734.099-1.122.28-1.399.556-.277.277-.457.665-.556 1.4C5.752 6.562 5.75 7.564 5.75 9v3a.75.75 0 0 1-1.5 0V8.945c0-1.367 0-2.47.117-3.337.12-.9.38-1.658.981-2.26.602-.602 1.36-.86 2.26-.981.867-.117 1.97-.117 3.337-.117H12.657c1.088 0 1.844 0 2.535.285.692.287 1.226.822 1.995 1.591l.1.101.486.485.1.101c.77.769 1.305 1.303 1.591 1.995.287.691.287 1.447.286 2.535V12a.75.75 0 0 1-1.5 0V9.485c0-.283 0-.526-.003-.735h-.299c-.899 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.725-.456-.456-.642-1.023-.726-1.65-.08-.595-.08-1.344-.08-2.243v-.299Zm4.767 3.497c-.174-.332-.5-.673-1.305-1.477l-.485-.485c-.804-.805-1.145-1.131-1.477-1.305V4c0 .964.002 1.612.066 2.095.063.461.17.659.3.789.13.13.328.238.79.3.482.065 1.13.066 2.094.066h.017ZM5.75 15a.75.75 0 0 0-1.5 0v1.036c0 .904 0 1.633.053 2.222.055.606.172 1.136.45 1.617a3.75 3.75 0 0 0 1.372 1.373c.481.277 1.011.394 1.617.449.589.053 1.318.053 2.222.053h.536a.75.75 0 0 0 0-1.5H10c-.948 0-1.61 0-2.122-.047-.502-.046-.788-.13-1.003-.254a2.25 2.25 0 0 1-.824-.824c-.124-.215-.208-.5-.254-1.003-.046-.512-.047-1.174-.047-2.122v-1Zm14 0a.75.75 0 0 0-1.5 0v1c0 .948 0 1.61-.047 2.122-.046.503-.13.788-.254 1.003a2.25 2.25 0 0 1-.824.824c-.215.124-.5.208-1.003.254-.512.046-1.174.047-2.122.047h-.5a.75.75 0 0 0 0 1.5h.536c.904 0 1.633 0 2.222-.053.606-.055 1.136-.171 1.617-.45a3.75 3.75 0 0 0 1.373-1.372c.277-.481.394-1.011.449-1.617.053-.589.053-1.318.053-2.222V15Z"
        fill="currentColor"
      />
    </Icon>
  );
});
