import { Form, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { SubTitle } from 'common-components/typography';
import { IWorkflowComment } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import React, { PureComponent } from 'react';

const { TextArea } = Input;

interface IAddEditCommentModalProps extends FormComponentProps {
  isOpen: boolean;
  isUpdating: boolean;
  commentDetail: IWorkflowComment;
  onClose(): void;
  onUpdateComment(values: IWorkflowComment): void;
}

interface IAddEditCommentModalState {
  isNothingChange: boolean;
}

class AddEditCommentModal extends PureComponent<IAddEditCommentModalProps, IAddEditCommentModalState> {
  state = {
    isNothingChange: true,
  };

  private _commentInputValidator = (rule, value, callback) => {
    try {
      if (!value || _.trim(value).length === 0) {
        throw Error('Please enter a comment');
      }
      if (_.trim(value).length > 50000) {
        throw Error('Please enter less than 50000 characters');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _onSave = () => {
    const { form, onUpdateComment } = this.props;

    form.validateFields(async (err, values) => {
      if (!err) {
        const commentDetail = {
          ...this.props.commentDetail,
          content: values.comment.trim(),
        };
        onUpdateComment(commentDetail);
      }
    });
  };
  render() {
    const { isOpen, form, onClose, commentDetail, isUpdating } = this.props;

    const { getFieldDecorator } = form;

    return (
      <ActionModal
        isOpen={isOpen}
        verticalAlignment="highest"
        title={commentDetail && commentDetail.workflowCommentId ? 'Edit comment' : 'Add comment'}
        onClose={onClose}
      >
        <SubTitle color="secondary" size={'regular'} containerClassName="mt-12">
          Comment
        </SubTitle>
        <Form className="align-center">
          <Form.Item>
            {getFieldDecorator('comment', {
              initialValue: commentDetail && commentDetail.content ? commentDetail.content : '',
              rules: [{ validator: this._commentInputValidator }],
            })(<TextArea placeholder="Add comment here" autoSize={{ minRows: 7, maxRows: 25 }} maxLength={50000} />)}
          </Form.Item>
        </Form>
        <ActionModalFooter className={'mt-x-large'}>
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            size="large"
            disabled={form.getFieldError('comment') || !form.getFieldValue('comment')}
            loading={isUpdating}
            onClick={this._onSave}
          >
            {commentDetail && commentDetail.workflowCommentId ? 'Save' : 'Add comment'}
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default Form.create<IAddEditCommentModalProps>()(AddEditCommentModal);
