import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text, Button } from '@good/components';
import { Error, Cogs } from '@good/illustrations';

import { Modal } from 'design-components';
import { IWorkLeaveType } from 'interfaces/account-interfaces';
import { WorkTypeCategory } from 'utilities/enum-utils';
import { useMutationArchiveWorkType } from 'stores/hooks/query-hooks/use-mutation-archive-work-type';
import { useMutationRestoreWorkType } from 'stores/hooks/query-hooks/use-mutation-restore-work-type';

export const testId = 'restore-archive-work-leave-types-modal';

type ArchiveRestoreWorkLeaveTypeModalProps = {
  workLeaveType: IWorkLeaveType | null;
  onClose: () => void;
  type: 'archive' | 'restore';
};

export const ArchiveRestoreWorkLeaveTypeModal = ({
  workLeaveType,
  onClose: onCloseProp,
  type,
}: ArchiveRestoreWorkLeaveTypeModalProps): JSX.Element => {
  const keyPrefix = `accountManagement.workLeaveTypesPage.modal.${type}.${
    workLeaveType?.category ?? WorkTypeCategory.Leave
  }`;
  const { t } = useTranslation('', { keyPrefix });

  const archiveWorkType = useMutationArchiveWorkType(workLeaveType?.category ?? WorkTypeCategory.Leave);
  const restoreWorkType = useMutationRestoreWorkType(workLeaveType?.category ?? WorkTypeCategory.Leave);

  const onArchive = () => {
    if (workLeaveType !== null) {
      type === 'archive'
        ? archiveWorkType.mutate({
            workTypeId: workLeaveType.workTypeId,
          })
        : restoreWorkType.mutate({
            workTypeId: workLeaveType.workTypeId,
          });
    }

    onCloseProp();
  };

  const onClose = () => {
    onCloseProp();
  };

  return (
    <Modal
      maxWidth={600}
      header={
        <Text className="font-bold" size="md">
          {t('heading')}
        </Text>
      }
      isOpen={workLeaveType !== null}
      onClose={onClose}
      isDismissable={false}
      id="archive-restore-work-leave-type-modal"
      bodyStyles={{ overflowY: 'unset', position: 'relative', padding: 16 }}
      footer={
        <div className="p-small">
          <Button emphasis="quiet" className="mr-3" onPress={onClose}>
            {t('cancel')}
          </Button>
          <Button emphasis="fill" onPress={onArchive}>
            {t('confirm')}
          </Button>
        </div>
      }
    >
      <div className="flex-column align-center flex" style={{ gap: 24 }} data-testid={testId}>
        <Text>{type === 'archive' ? <Error size="md" /> : <Cogs size="md" />}</Text>
        <Text className="text-center">
          {t('support', { name: workLeaveType?.name, interpolation: { escapeValue: false } })}
        </Text>
        <Text className="text-center">
          <Trans t={t} i18nKey="text" values={{ name: workLeaveType?.name }} shouldUnescape />
        </Text>
      </div>
    </Modal>
  );
};
