import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { AriaSearchFieldProps, useSearchField } from '@react-aria/searchfield';
import { filterDOMProps, mergeProps } from '@react-aria/utils';
import { useSearchFieldState } from '@react-stately/searchfield';
import React, { forwardRef, PropsWithChildren, RefObject, useRef } from 'react';
import { compose, css, CSS, inputStyleProps, InputStylesProps, useContextProps, useStyleProps } from '..';
import { IconClose, Loading, Search } from '../icon';
import { SearchFieldContext, SearchFieldIconProvider, SearchFieldProviderProps } from './search-field-provider';
import * as styles from './search-field.css';
import { SearchFieldVariants } from './search-field.css';

export type SearchFieldProps = PropsWithChildren<
  AriaSearchFieldProps &
    InputStylesProps &
    SearchFieldVariants & {
      /**
       * Status loading
       */
      isLoading?: boolean;
      /**
       * Custom styles
       */
      customStyles?: CSS;
    }
>;

/**
 * @name
 * Search Field
 *
 * @description
 * <SearchField> is HTML Input Element, allows users enter value with common input props.
 *
 * @example
 * <SearchField
 *   size="medium"
 *   label="First Name"
 *   onChange={(value) => console.log(value)}
 * />
 */

// eslint-disable-next-line react/display-name
export const SearchField = forwardRef<HTMLDivElement, SearchFieldProps>((props, ref) => {
  const contextProps = useContextProps<SearchFieldProps & SearchFieldProviderProps>(SearchFieldContext, props);
  const inputRef = useRef(null);
  const buttonRef = useRef(null);
  const state = useSearchFieldState(contextProps);
  const { size = 'medium', isLoading, label, placeholder, customStyles } = contextProps;

  let { labelProps, inputProps, clearButtonProps } = useSearchField(contextProps, state, inputRef);

  const { focusProps, isFocusVisible, isFocused } = useFocusRing(inputProps);
  const { hoverProps, isHovered } = useHover({});
  const { styleProps, ...otherProps } = useStyleProps(inputProps, inputStyleProps);
  inputProps = mergeProps(inputProps, focusProps);

  const { buttonProps } = useButton(clearButtonProps, buttonRef);

  return (
    <div className={css(styles.wrapper)()}>
      {!!label && (
        <label {...labelProps} className={css(styles.label)()}>
          {label}
        </label>
      )}
      <SearchFieldIconProvider size={size}>
        <div
          ref={ref}
          {...filterDOMProps(otherProps)}
          {...hoverProps}
          className={compose(
            css(styles.searchField, customStyles),
            styles.searchFieldVariants({
              size,
            }),
            css(styleProps),
          )}
        >
          <div className={compose(styles.prefixComponent())}>
            <Search color="$neutralDark1" />
          </div>
          <input
            ref={inputRef as RefObject<HTMLInputElement>}
            {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
            className={compose(
              css(styles.input),
              styles.inputVariants({
                isHovered,
                isFocused: isFocused || isFocusVisible,
              }),
              css(styleProps),
            )}
            placeholder={placeholder}
          />
          <div className={css(styles.suffixComponent)()}>
            {isLoading ? (
              <span className={css(styles.loadingIcon)()}>
                <Loading color="$neutralDark1" />
              </span>
            ) : (
              state.value && (
                <button ref={buttonRef} {...buttonProps} className={css(styles.buttonClear)()}>
                  <IconClose />
                </button>
              )
            )}
          </div>
        </div>
      </SearchFieldIconProvider>
    </div>
  );
});
