import { Avatar, Form, Icon, Select } from 'antd';
import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ICustomerListItem } from 'interfaces/customer-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Text } from 'common-components/typography';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { CustomerType } from 'utilities/enum-utils';

const { Option } = Select;

interface IActivityRecordCustomerSelectorProps {
  selectedCustomer: ICustomerListItem;
  prefilledCustomer: any;
  customers: ICustomerListItem[];
  doGetCustomersLite: typeof dispatch.customersStore.doGetCustomersLite;
  setCustomerFilterLite: typeof dispatch.customersStore.setCustomerFilterLite;
  customerFilterLite: typeof state.customersStore.customerFilterLite;
  pageSize: number;
  dropdownClassName?: string;
  onChange: (userId: string) => void;
  form: WrappedFormUtils<any>;
}

interface IActivityRecordCustomerSelectorState {
  page: number;
  isFetchingCustomers: boolean;
  pageTimestamp: Date;
}

const ActivityRecordCustomerSelector = ({
  selectedCustomer,
  prefilledCustomer,
  pageSize,
  doGetCustomersLite,
  setCustomerFilterLite,
  form,
  customerFilterLite,
  customers,
  dropdownClassName = 'ant-select-dropdown-menu-5-item',
  onChange,
}: IActivityRecordCustomerSelectorProps) => {
  const [state, setState] = useState<IActivityRecordCustomerSelectorState>({
    page: 1,
    isFetchingCustomers: false,
    pageTimestamp: new Date(),
  });

  const { getFieldDecorator } = form;

  const handleScrollSelectorCustomers = async ({ target }) => {
    const pageyOffset = 1;
    if (!state.isFetchingCustomers && target.scrollTop + target.clientHeight + pageyOffset >= target.scrollHeight) {
      setState({ ...state, isFetchingCustomers: true });
      await doGetCustomersLite({
        page: state.page + 1,
        pageSize: pageSize,
        pageTimestamp: state.pageTimestamp,
        sortByRelevance: customerFilterLite?.sort ? true : false,
        userType: CustomerType.CUSTOMERS,
      });

      setState({ ...state, page: state.page + 1, isFetchingCustomers: false });
    }
  };

  const handleChangeSearchTextCustomer = async (value: string) => {
    if (!_.isEmpty(value)) {
      const payload = {
        search: value,
        sort: [
          ['firstName', 'asc'],
          ['lastName', 'asc'],
        ],
        userType: CustomerType.CUSTOMERS,
      };

      setState({ ...state, isFetchingCustomers: true, page: 1 });
      setCustomerFilterLite(payload);
      await doGetCustomersLite({
        page: state.page,
        pageSize: pageSize,
        pageTimestamp: state.pageTimestamp,
        sortByRelevance: true,
        userType: CustomerType.CUSTOMERS,
      });
      setState({ ...state, isFetchingCustomers: false });
    } else {
      setCustomerFilterLite({});
      await doGetCustomersLite({
        page: state.page,
        pageSize: pageSize,
        pageTimestamp: state.pageTimestamp,
        userType: CustomerType.CUSTOMERS,
      });
    }
  };

  const _renderCustomerItem = (customer: ICustomerListItem) => {
    return (
      <Option value={customer.userId} key={customer.userId}>
        <Avatar size="small" icon="user" className="mr-medium" src={customer.attachmentUrl} />
        <Text>
          {customer.firstName} {customer.lastName}
        </Text>
      </Option>
    );
  };

  useEffect(() => {
    doGetCustomersLite({
      page: 1,
      pageSize: pageSize,
      pageTimestamp: state.pageTimestamp,
      userType: CustomerType.CUSTOMERS,
    });
  }, []);

  return (
    <>
      {_.isEmpty(selectedCustomer) ? (
        <Form.Item className={'m-none'}>
          {getFieldDecorator('customer', {
            initialValue: prefilledCustomer.userId || undefined,
            rules: [
              {
                required: true,
                message: (
                  <div className="flex align-center mt-small">
                    <Icon type="close-circle" theme="filled" className="text-size-regular mr-x-small" />
                    <Text type="danger" size="x-small">
                      Customer is required
                    </Text>
                  </div>
                ),
              },
            ],
          })(
            <Select
              placeholder="Search for customer"
              className="width-full"
              size="large"
              allowClear
              showSearch
              autoClearSearchValue
              onSearch={handleChangeSearchTextCustomer}
              filterOption={(input, option) => {
                const customer = _.find(customers, (customer) => customer.userId === option.props.value);
                return customer
                  ? `${customer.firstName} ${customer.lastName}`.toLowerCase().includes(input.toLowerCase())
                  : false;
              }}
              onPopupScroll={handleScrollSelectorCustomers}
              showArrow={false}
              loading={state.isFetchingCustomers}
              dropdownClassName={dropdownClassName}
              onChange={(value: string) => onChange(value)}
            >
              {!_.isEmpty(prefilledCustomer) && _renderCustomerItem(prefilledCustomer)}

              {customers
                .filter((customer) => customer.userId !== prefilledCustomer?.userId)
                .map((customer) => _renderCustomerItem(customer))}

              {state.isFetchingCustomers && <Option value="fetching">Fetching customers...</Option>}
            </Select>,
          )}
        </Form.Item>
      ) : (
        <div>
          <Avatar size="small" icon="user" className="mr-medium" src={selectedCustomer.attachmentUrl} />
          <Text>
            {selectedCustomer.firstName} {selectedCustomer.lastName}
          </Text>
        </div>
      )}
    </>
  );
};

const mapState = (state: IRootState) => ({
  customers: state.customersStore.customersLite,
  customerFilterLite: state.customersStore.customerFilterLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomersLite: dispatch.customersStore.doGetCustomersLite,
  doGetCustomer: dispatch.customersStore.doGetCustomer,
  setCustomerFilterLite: dispatch.customersStore.setCustomerFilterLite,
  setCustomersLite: dispatch.customersStore.setCustomersLite,
});

export default connect(mapState, mapDispatch)(memo(ActivityRecordCustomerSelector));
