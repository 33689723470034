import React, { Component } from 'react';
import { Col, Icon, notification, Row } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { Paragraph, Text } from 'common-components/typography';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';

interface IDeleteDocumentModalProps {
  closeDeleteDocumentModal: () => void;
  isOpen: boolean;
  selectedDocument: any;
  selectedItemId: string;
  itemType: string;
  doRemoveCustomerDocument: typeof dispatch.customersStore.doRemoveCustomerDocument;
  doRemoveWorkerDocument: typeof dispatch.teamStore.doRemoveWorkerDocument;
  doRemoveBookingDocument: typeof dispatch.bookingsStore.doRemoveBookingDocument;
  resetDocumentList: () => void;
  useAttachmentText: boolean;
  isDocumentLinkedToNote?: boolean;
}

class DeleteDocumentModal extends Component<IDeleteDocumentModalProps> {
  state = { isLoading: false, title: 'Delete document', step: 1 };

  private _deleteDocument = async () => {
    const {
      selectedDocument,
      selectedItemId,
      doRemoveCustomerDocument,
      doRemoveWorkerDocument,
      doRemoveBookingDocument,
      useAttachmentText,
    } = this.props;
    try {
      this.props.itemType === 'customer'
        ? await doRemoveCustomerDocument({ userId: selectedItemId, documentId: selectedDocument.documentId })
        : this.props.itemType === 'worker'
        ? await doRemoveWorkerDocument({ supportWorkerId: selectedItemId, documentId: selectedDocument.documentId })
        : await doRemoveBookingDocument({ bookingId: selectedItemId, documentId: selectedDocument.documentId });
      notification.success({ message: 'Document removed successfully.' });
      this.props.resetDocumentList();
      this.setState({
        title: (
          <>
            {useAttachmentText ? 'Attachment' : 'Document'} successfully deleted
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        step: 2,
      });
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
    }
  };

  private _closeModal = () => {
    this.setState({ title: `Delete ${this.props.useAttachmentText ? 'attachment' : 'document'}`, step: 1 });
    this.props.closeDeleteDocumentModal();
  };

  componentDidUpdate(prevProps: Readonly<IDeleteDocumentModalProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.isDocumentLinkedToNote !== this.props.isDocumentLinkedToNote) {
      this.setState({
        title: this.props.isDocumentLinkedToNote
          ? 'Remove attachment'
          : `Delete ${this.props.useAttachmentText ? 'attachment' : 'document'}`,
        step: this.props.isDocumentLinkedToNote ? 3 : 1,
      });
    }
  }

  render() {
    const { selectedDocument, useAttachmentText } = this.props;
    const { step } = this.state;
    return (
      <div>
        <ActionModal title={this.state.title} isOpen={this.props.isOpen} onClose={this._closeModal} width="medium">
          {step === 1 && (
            <div className="anim-slide-left">
              <SpinningLoadingActionModel isOpen={this.state.isLoading} verticalAlignment={'highest'} />
              <Paragraph>
                Are you sure you want to delete this {useAttachmentText ? 'attachment' : 'document'}?
              </Paragraph>
              {!_.isEmpty(selectedDocument) && (
                <Row type={'flex'} align="middle" className="mv-x2-large">
                  <Col span={6} className="text-align-right">
                    <Icon type="file" className="mr-medium text-size-x4-large" />
                  </Col>
                  <Col span={18}>
                    {selectedDocument.documentName}
                    <br />
                    <b>Uploaded by:</b> {selectedDocument.firstName} {selectedDocument.lastName}
                  </Col>
                </Row>
              )}
              <div className={'mb-small'}>
                <Row type={'flex'} className={'justify-end'}>
                  <Col className="mr-large">
                    <GhostButton size="large" onClick={this.props.closeDeleteDocumentModal}>
                      Cancel
                    </GhostButton>
                  </Col>
                  <Col>
                    <PrimaryButton size="large" color="red" onClick={this._deleteDocument}>
                      Delete
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="anim-slide-left">
              <div className="anim-slide-left">
                <Paragraph>
                  You have successfully deleted this {useAttachmentText ? 'attachment' : 'document'}.
                </Paragraph>
                <div className={'mb-small'}>
                  <Row type={'flex'} className={'justify-end'}>
                    <Col>
                      <PrimaryButton size="large" onClick={this._closeModal}>
                        Close
                      </PrimaryButton>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )}
          {step === 3 && (
            <div>
              <Text size="large">This attachment is related to a note for this activity record.</Text>
              <br />
              <br />
              <Text size="large">
                Deleting this attachment will remove the attachment from the note but will not delete the note.
              </Text>
              <div className={'mb-small mt-large'}>
                <Row type={'flex'} className={'justify-end'}>
                  <Col className="mr-large">
                    <GhostButton size="large" onClick={this.props.closeDeleteDocumentModal}>
                      Cancel
                    </GhostButton>
                  </Col>
                  <Col>
                    <PrimaryButton size="large" color="red" onClick={this._deleteDocument}>
                      Remove attachment
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveCustomerDocument: dispatch.customersStore.doRemoveCustomerDocument,
  doRemoveWorkerDocument: dispatch.teamStore.doRemoveWorkerDocument,
  doRemoveBookingDocument: dispatch.bookingsStore.doRemoveBookingDocument,
});

export default connect(null, mapDispatch)(DeleteDocumentModal);
