import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Avatar, Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';

interface ILegalGuardianItemRowProps extends FormComponentProps {
  attachmentUrl: string;
  legalGuardianName: string;
  email: string;
  legalGuardianId: string;
  setSelectedGuardians: (legalGuardianId: string) => void;
}

class LegalGuardianItemRow extends Component<ILegalGuardianItemRowProps, any> {
  private _setCurrentLegalGuardian = async (userId) => {
    this.props.setSelectedGuardians(userId);
  };

  render() {
    const { legalGuardianName, attachmentUrl, email } = this.props;

    return (
      <div
        className="bordered-bottom ph-medium pv-medium flex-row align-center justify-between hover-bg-tertiary cursor-pointer"
        onClick={() => this._setCurrentLegalGuardian(this.props.legalGuardianId)}
      >
        <div className="flex-row align-center">
          <Avatar size="large" icon="user" className="mr-medium" src={attachmentUrl} />
          <div className="flex-column">
            <Text weight="bold">{legalGuardianName}</Text>
            <Text color="secondary" className="text-size-x-small">
              {email}
            </Text>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create<ILegalGuardianItemRowProps>()(LegalGuardianItemRow);
