import React from 'react';
import { Text, Modal } from '@good/ui/core';

type UnsavedChangesModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const UnsavedChangesModal = ({ isOpen, onCancel, onConfirm }: UnsavedChangesModalProps) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onCancel}
      title='You have unsaved changes'
      cancelProps={{
        label: 'Keep editing',
        onClick: onCancel,
      }}
      confirmProps={{
        label: 'Discard',
        onClick: onConfirm,
        color: 'danger',
      }}
    >
      <Text size='sm'>These changes will be lost if you proceed.</Text>
    </Modal>
  );
};
