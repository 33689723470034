import React, { createContext } from 'react';

import { IconContext } from '../icon/icon-provider';

import type { ButtonVariants } from './button.css';
import type { ButtonProps, IconProps } from '..';
import type { PropsWithChildren, Ref } from 'react';

type ButtonElement = Ref<HTMLButtonElement>;
export type ButtonProviderProps = ButtonProps & {
  buttonRef?: ButtonElement;
};

export const ButtonContext = createContext<ButtonProviderProps>({} as ButtonProviderProps);

export function ButtonProvider(props: ButtonProviderProps) {
  const { children, ...otherProps } = props;

  return <ButtonContext.Provider value={otherProps}>{children}</ButtonContext.Provider>;
}

export type ButtonIconProviderProps = PropsWithChildren<IconProps & ButtonVariants>;

export function ButtonIconProvider(props: ButtonIconProviderProps) {
  const { children, ...otherProps } = props;

  return <IconContext.Provider value={otherProps}>{children}</IconContext.Provider>;
}
