import { tv } from 'tailwind-variants';

export const dateField = tv(
  {
    slots: {
      container: ['flex', 'flex-col', 'gap-2', 'min-w-[192px]', 'w-fit'],
      input: [
        'font-base',
        'font-normal',
        'appearance-none',
        'bg-base',
        'border',
        'border-gray',
        'hover:border-gray-dark-1',
        'rounded-md',
        'text-b-sm',
        'leading-b-sm',
        'text-strong',
        'placeholder:text-weak',
        'py-[10px]',
        'px-3',
        'outline-none',
        'transition-colors',
        'focus-within:border-accent',
        'data-[focus-visible=true]:outline',
        'data-[focus-visible=true]:outline-offset-[-1px]',
        'data-[focus-visible=true]:outline-accent',
        'aria-[disabled="true"]:bg-gray-light-2',
        'aria-[disabled="true"]:border-gray-light-2',
        'inline-flex',
        'flex-row',
        'gap-1',
      ],
      segment: [
        'p-[1px]',
        'outline-none',
        'focus:rounded',
        'focus:bg-accent',
        'focus:text-on-background',
        'focus-within:bg-accent',
        'aria-invalid:text-critical',
        'aria-invalid:focus:bg-critical',
        'aria-invalid:focus:text-on-background',
        'data-[placeholder="true"]:text-weak',
        'data-[type="literal"]:text-weak',
        'data-[placeholder="true"]:focus:text-on-background',
      ],
    },
    variants: {
      validationState: {
        invalid: {
          input: [
            'border-critical',
            'focus-within:border-critical',
            'data-[hovered=false]:border-critical',
            'data-[hovered=true]:border-critical',
            'data-[focused=true]:border-critical',
            'data-[focus-visible=true]:outline-critical',
          ],
        },
        valid: {},
      },
    },
  },

  { twMerge: false },
);
