import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const SignIn = forwardRef(function SignIn(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.53 7.47a.75.75 0 1 0-1.06 1.06l2.72 2.72H3a.75.75 0 0 0 0 1.5h12.19l-2.72 2.72a.75.75 0 1 0 1.06 1.06l4-4a.75.75 0 0 0 0-1.06l-4-4ZM15 2.75c1.436 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4.102.754.103 1.756.103 3.191v8c0 1.435-.002 2.436-.103 3.192-.099.734-.28 1.122-.556 1.399-.277.277-.665.457-1.399.556-.755.101-1.756.103-3.192.103h-2.5c-1.19 0-2.02-.001-2.656-.073-.62-.07-.962-.199-1.214-.392a2.253 2.253 0 0 1-.415-.415c-.193-.252-.322-.594-.392-1.214-.072-.636-.073-1.466-.073-2.656V16a.75.75 0 0 0-1.5 0v.545c0 1.135 0 2.05.082 2.779.085.754.266 1.403.693 1.959.2.26.432.493.692.692.557.427 1.205.608 1.96.693.729.082 1.643.082 2.778.082h2.6c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337v-8.11c0-1.367 0-2.47-.116-3.337-.122-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117h-2.6c-1.135 0-2.05 0-2.779.082-.754.085-1.403.266-1.959.693-.26.2-.492.432-.692.692-.427.556-.608 1.205-.693 1.96-.082.729-.082 1.643-.082 2.778V8a.75.75 0 1 0 1.5 0v-.5c0-1.19.001-2.02.073-2.656.07-.62.199-.962.392-1.214.12-.156.26-.295.415-.415.252-.193.594-.322 1.214-.392.636-.072 1.466-.073 2.656-.073H15Z"
        fill="currentColor"
      />
    </Icon>
  );
});
