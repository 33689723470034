import _ from 'lodash';
import { useSelector } from 'react-redux';

import rootStore from 'stores/rematch/root-store';
import { UserPermissionRole } from '../enum-utils';
import { userPermissions } from 'interfaces/user-permission-type';

import type { IRootState } from 'stores/rematch/root-store';

class PermissionUtils {
  // TODO: refactor to interface
  static validatePermission = (
    userPermission: string,
    userPermissionRoles: {
      permissionRole: UserPermissionRole;
      serviceDepartmentId: string | null;
      serviceId: string | null;
    }[],
    serviceDepartmentId?: string,
    serviceId?: string,
  ): boolean => {
    const permission = userPermissions[userPermission];

    if (permission.requireValidateServiceAccess) {
      const matchedUserPermissionRole = _.find(userPermissionRoles, (userPermissionRole) => {
        // First, try to match primary owner or owner role
        let matchedPermission =
          _.findIndex(
            permission.permissionRoles,
            (permissionRole) =>
              permissionRole <= UserPermissionRole.Owner && permissionRole === userPermissionRole.permissionRole,
          ) >= 0;

        return matchedPermission
          ? matchedPermission
          : _.findIndex(permission.permissionRoles, (permissionRole) => {
              return (
                ((userPermissionRole.serviceDepartmentId === serviceDepartmentId &&
                  userPermissionRole.serviceId === serviceId) ||
                  (userPermissionRole.serviceDepartmentId === serviceDepartmentId &&
                    userPermissionRole.serviceId === null)) &&
                permissionRole === userPermissionRole.permissionRole
              );
            }) >= 0;
      });

      return !!matchedUserPermissionRole;
    } else {
      // Filter out required permission
      const matchedUserPermissionRole = _.find(userPermissionRoles, (userPermissionRole) => {
        // return matched permission
        return (
          _.findIndex(
            permission.permissionRoles,
            (permissionRole) => permissionRole === userPermissionRole.permissionRole,
          ) >= 0
        );
      });

      return !!matchedUserPermissionRole;
    }
  };

  static async getServiceProviderFundingAndPaymentSource(paymentSource: string) {
    const result: any = await rootStore.dispatch.companyStore.doFetchCompanyFundingAndPaymentLite();
    const VCPConfig = result
      ? _.find(result.paymentSources, (funding) => funding.paymentSourceType === paymentSource)
      : null;
    return VCPConfig;
  }

  static async getIsServiceProviderVCPEnabled() {
    const result: any = await rootStore.dispatch.companyStore.doFetchCompanyFundingAndPaymentLite();
    const VCPConfig = result ? _.find(result.paymentSources, (funding) => funding.paymentSourceType === 'VCP') : null;
    return VCPConfig ? VCPConfig.paymentSourceConfiguration.vcpEnabled : false;
  }

  static formatPermissionRoles(permissionRoles) {
    const result = {};

    const roleIds = _.map(permissionRoles, (one) => one.permissionRole);

    for (const item in UserPermissionRole) {
      if (isNaN(Number(item))) {
        result[item] = roleIds.includes(UserPermissionRole[item]);
      }
    }

    return result;
  }
}

export default PermissionUtils;

/**
 * @name useHasPermission
 *
 * @description
 * React hook to check if a user's role includes the given roles.
 *
 * @example
 * const isPermitted = useHasPermission([UserPermissionRole.Owner, UserPermissionRole.TeamManager]);
 */
export function useHasPermission(roles: UserPermissionRole[]) {
  const { portalUser } = useSelector(({ authStore }: IRootState) => authStore);
  return Boolean(portalUser.permissions.permissionRoles.filter((r) => roles?.includes(r.permissionRole))?.length);
}
