import React, { forwardRef, useEffect, useLayoutEffect, useState } from 'react';

type Props = {
  styles?: React.CSSProperties;
  trackHeight: number;
  targetRef: React.RefObject<HTMLDivElement>;
  dragging: boolean;
  onChangeDragging: (drag: boolean) => void;
};

export const CustomScrollBar = forwardRef<HTMLDivElement, Props>(
  ({ styles, trackHeight, targetRef, onChangeDragging, dragging }, ref: React.MutableRefObject<HTMLDivElement>) => {
    const [scrollHeight, setScrollHeight] = useState<number>(0);
    const [initialPosition, setInitialPosition] = useState<{
      top: number;
      right: number;
    }>({
      top: 0,
      right: 0,
    });

    const setPositionOfScrollBar = () => {
      const list = document.querySelector('.list-picture');
      const { x, y } = list.getBoundingClientRect();
      const right = x - targetRef.current?.offsetLeft;
      const top = y + 5;
      const currentPosition = {
        right,
        top,
      };
      setInitialPosition(currentPosition);
    };

    useLayoutEffect(() => {
      window.addEventListener('resize', setPositionOfScrollBar);

      return () => {
        window.removeEventListener('resize', setPositionOfScrollBar);
      };
    }, []);

    useEffect(() => {
      setScrollHeight(targetRef.current?.scrollHeight);
      setPositionOfScrollBar();
    }, [targetRef]);

    return (
      <div
        className="custom-scroll-bar"
        ref={ref}
        style={{
          ...styles,
          height: trackHeight,
          ...initialPosition,
        }}
        onScroll={(e) => {
          e.preventDefault();
          if (dragging) {
            targetRef.current.scrollTop = e.currentTarget.scrollTop;
            e.currentTarget.scrollTop -= 3;
          }
        }}
        onMouseDownCapture={() => {
          onChangeDragging(true);
        }}
        onMouseUpCapture={() => {
          onChangeDragging(false);
        }}
      >
        <div
          style={{
            height: scrollHeight,
          }}
        ></div>
      </div>
    );
  },
);
