import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Tv = forwardRef(function Tv(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45 1.4a.75.75 0 1 0-.9 1.2l2.2 1.65c-1.277.003-2.321.018-3.175.119-1.03.122-1.88.377-2.588.96a4.75 4.75 0 0 0-.659.658c-.582.709-.837 1.557-.96 2.588-.118 1.005-.118 2.274-.118 3.876v.098c0 1.602 0 2.872.119 3.876.122 1.03.377 1.88.96 2.588.197.24.417.461.658.659.709.582 1.557.837 2.588.96.084.01.17.018.257.027l-.503 1.006a.75.75 0 0 0 1.342.67l.798-1.596c.596.011 1.255.011 1.982.011h3.098c.727 0 1.386 0 1.982-.011l.798 1.596a.75.75 0 0 0 1.342-.67l-.503-1.006.257-.028c1.03-.122 1.88-.377 2.588-.96.241-.197.461-.417.659-.658.582-.709.837-1.557.96-2.588.118-1.004.118-2.274.118-3.876v-.098c0-1.602 0-2.872-.119-3.876-.122-1.03-.377-1.88-.96-2.588a4.751 4.751 0 0 0-.657-.659c-.71-.582-1.558-.837-2.59-.96-.852-.1-1.897-.115-3.174-.118l2.2-1.65a.75.75 0 1 0-.9-1.2L12 4.063 8.45 1.4Zm3.552 4.35H10.5c-1.662 0-2.843.001-3.749.108-.889.105-1.415.304-1.813.63a3.25 3.25 0 0 0-.45.45c-.326.398-.524.924-.63 1.813-.107.906-.108 2.087-.108 3.749s.001 2.843.108 3.749c.106.89.304 1.415.63 1.813.135.164.286.315.45.45.398.326.924.525 1.813.63.906.107 2.087.108 3.75.108h3c1.661 0 2.842-.001 3.748-.108.89-.105 1.416-.303 1.813-.63a3.25 3.25 0 0 0 .45-.45c.326-.398.525-.924.63-1.813.107-.906.108-2.087.108-3.749s-.001-2.843-.108-3.749c-.105-.889-.303-1.415-.63-1.813a3.256 3.256 0 0 0-.45-.45c-.398-.326-.924-.525-1.813-.63-.906-.107-2.087-.108-3.749-.108h-1.498Z"
        fill="currentColor"
      />
    </Icon>
  );
});
