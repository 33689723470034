import React, { useState, useRef, useCallback } from 'react';
import { Text } from '@good/components';
import { MoreHoriz } from '@good/icons';

import { IWorkLeaveType } from 'interfaces/account-interfaces';
import { isMutating } from 'stores/hooks/query-hooks/optimistic-mutation/optimistic-status';
import Dropdown from './Dropdown';
import { Spinner } from '@blueprintjs/core';
import useMeatBallsDropdownItems from './use-meat-balls-dropdown-items';

type MeatBallsDropdownProps = {
  workLeaveType: IWorkLeaveType;
  onEdit?: ((workLeaveType: IWorkLeaveType) => void) | undefined;
  onArchive?: ((workLeaveType: IWorkLeaveType) => void) | undefined;
  onRestore?: ((workLeaveType: IWorkLeaveType) => void) | undefined;
};

const MeatBallsDropdown = ({ workLeaveType, onEdit, onArchive, onRestore }: MeatBallsDropdownProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const isWorkLeaveTypeMutating = isMutating(workLeaveType);
  const toggleButton = useRef<HTMLButtonElement>(null);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const dropdownItems = useMeatBallsDropdownItems(workLeaveType, onClose, onEdit, onArchive, onRestore);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div data-testid="meatball-menu" className="relative inline-block">
      <button onClick={toggleOpen} disabled={isWorkLeaveTypeMutating} ref={toggleButton}>
        <Text className="text-weak" size="sm">
          {isWorkLeaveTypeMutating ? <Spinner size={16} /> : <MoreHoriz className="cursor-pointer" />}
        </Text>
      </button>
      <Dropdown
        isOpen={isOpen}
        onClose={onClose}
        shouldCloseOnInteractOutside={(e) => {
          if (toggleButton.current?.contains(e)) return false;
          return true;
        }}
        items={dropdownItems}
      />
    </div>
  );
};

export default MeatBallsDropdown;
