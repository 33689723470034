import React from 'react';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import { useInlineForm } from './inline-form-context';
import { StaticField } from './static-field';
import { InlineFieldInputWrapper } from './inline-field-input-wrapper';
import { Address, AddressInput, AddressInputProps, rhfRegisterControlled } from '../address-input';
import { OptionalLabel } from './optional-label';

export type InlineAddressFieldProps<T extends FieldValues> = {
  description?: string;
  emptyValue?: string;
  label?: string;
  maxInputWidth?: React.CSSProperties['maxWidth'];
  name: Path<T>;
  showOptional?: boolean;
  staticLabel?: string;
} & Omit<AddressInputProps, 'name' | 'width' | 'ref'>;

export const InlineAddressField = <T extends FieldValues>(props: InlineAddressFieldProps<T>) => {
  const form = useFormContext<T>();
  const { isEditing, isSubmitting, registerAddressFieldRef } = useInlineForm();
  const {
    description,
    disabled,
    emptyValue,
    label,
    maxInputWidth,
    name,
    showOptional,
    staticLabel,
    ...addressInputProps
  } = props;

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field }) => {
        const value = field.value as Address | null;
        return isEditing ? (
          <InlineFieldInputWrapper
            label={label ? <OptionalLabel label={label} optional={showOptional} /> : undefined}
            description={description}
            name={name}
            maxInputWidth={maxInputWidth}
            render={(labelledBy) => {
              return (
                <AddressInput
                  {...rhfRegisterControlled(form, field, name)}
                  {...addressInputProps}
                  width='100%'
                  aria-labelledby={labelledBy}
                  ref={(ref) => {
                    if (!ref) return;
                    registerAddressFieldRef(name, ref);
                  }}
                  disabled={isSubmitting || disabled}
                />
              );
            }}
          />
        ) : (
          <StaticField label={staticLabel ?? label} value={value?.fullAddress ?? ''} emptyValue={emptyValue} />
        );
      }}
    />
  );
};
