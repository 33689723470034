import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Card, notification } from 'antd';

import firebaseApp from 'stores/firebase-app';
import { Title } from 'common-components/typography';
import { PrimaryButton } from 'common-components/buttons';
import { WorkflowTemplateType } from 'utilities/enum-utils';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { IWorkflowTemplateStep } from 'interfaces/workflow-interfaces';
import PreviewFormModal from 'views/form-builder/components/PreviewFormModal';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';

import WorkflowTemplateSummarySection from './WorkflowTemplateSummarySection';

interface ICreateWorkflowTemplateSummeryReviewStepCardProps {
  addWorkflowTemplateWizard: typeof state.workflowStore.addWorkflowTemplateWizard;
  portalUser: typeof state.authStore.portalUser;
  onNextStep(): void;
  onPreviousStep(): void;
  doCreateNewWorkflowTemplate: typeof dispatch.workflowStore.doCreateNewWorkflowTemplate;
  doGetFormDetailsByVersion: typeof dispatch.formBuilderStore.doGetFormDetailsByVersion;
  currentForm: typeof state.formBuilderStore.currentForm;
  providerTimezone: string;
}
interface ICreateWorkflowTemplateSummeryReviewStepCardState {
  isLoading: boolean;
  isOpenPreviewModal: boolean;
}

class CreateWorkflowTemplateSummeryReviewStepCard extends PureComponent<
  ICreateWorkflowTemplateSummeryReviewStepCardProps,
  ICreateWorkflowTemplateSummeryReviewStepCardState
> {
  state = {
    isLoading: false,
    isOpenPreviewModal: false,
  };

  private _createWorkflow = async () => {
    const { addWorkflowTemplateWizard, doCreateNewWorkflowTemplate, onNextStep } = this.props;

    try {
      this.setState({ isLoading: true });

      const payload = {
        ...addWorkflowTemplateWizard,
        triggerType:
          addWorkflowTemplateWizard.workflowTemplateType === WorkflowTemplateType.MANUAL
            ? WorkflowTemplateType.MANUAL
            : addWorkflowTemplateWizard.triggerType,
      };

      const response: any = await doCreateNewWorkflowTemplate(payload);

      const workflowStepResult = response.workflowTemplate.steps;

      const uploadTask = _.map(payload.steps, (step, index) => {
        if (step.attachment && step.attachment.file) {
          const metaData = {
            customMetadata: {
              documentId: workflowStepResult[index].attachment.documentId,
              workflowAttachmentId: workflowStepResult[index].attachment.workflowAttachmentId,
              serviceProviderId: this.props.portalUser.serviceProviderId,
              workflowTemplateId: response.workflowTemplate.workflowTemplateId,
            },
          };

          const storageRef = ref(firebaseApp.storage, `${response.uploadBucketUrl}/${step.attachment.file.name}`);
          const uploadFile = uploadBytesResumable(storageRef, step.attachment.file, metaData);

          return uploadFile.on(
            'state_changed',
            () => ({}),
            (error) => {
              this.setState({ isLoading: false });
              notification.error({ message: 'Upload failed! Please try again.', description: error });
            },
            () => {
              this.setState({ isLoading: false });
              notification.success({ message: 'Document is currently scanning.' });
            },
          );
        }
      });

      try {
        await Promise.all(uploadTask);

        onNextStep();
      } catch (e) {
        notification.error({ message: 'Upload failed! Please try again.' });
      }
    } catch (e) {
      this.setState({ isLoading: false });
      console.log(e);

      if (e.meta && e.meta.message) {
        notification.error({
          message: 'Error',
          description: e.meta.message,
        });
      } else {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  private _onOpenPreviewFormModal = async (workflowTemplateValue: IWorkflowTemplateStep) => {
    await this.props.doGetFormDetailsByVersion({
      formId: workflowTemplateValue?.formId,
      versionId: workflowTemplateValue?.formVersionId,
      isWorkflowFormVersion: true,
    });

    this.setState({
      isOpenPreviewModal: true,
    });
  };

  private _onClosePreviewFormModal = () => {
    this.setState({
      isOpenPreviewModal: false,
    });
  };

  render() {
    const { addWorkflowTemplateWizard, currentForm, providerTimezone } = this.props;
    const { isOpenPreviewModal } = this.state;

    if (!addWorkflowTemplateWizard) {
      return <></>;
    }

    return (
      <>
        {isOpenPreviewModal && (
          <PreviewFormModal
            timezone={providerTimezone}
            formContent={currentForm}
            isOpen={isOpenPreviewModal}
            onClose={this._onClosePreviewFormModal}
          />
        )}
        <Card bordered={false} className="shadow-container rounded-big">
          <Title level={4} weight="regular" className="mb-large">
            Summary review
          </Title>

          <WorkflowTemplateSummarySection
            addWorkflowTemplateWizard={addWorkflowTemplateWizard}
            onOpenPreviewFormModal={this._onOpenPreviewFormModal}
          />
        </Card>

        <div className="justify-end flex p-large">
          <PrimaryButton
            disabled={this.state.isLoading}
            loading={this.state.isLoading}
            size="large"
            onClick={this._createWorkflow}
          >
            Create workflow
          </PrimaryButton>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  addWorkflowTemplateWizard: state.workflowStore.addWorkflowTemplateWizard,
  portalUser: state.authStore.portalUser,
  currentForm: state.formBuilderStore.currentForm,
  providerTimezone: state.companyStore.companyDataLite.timezone,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateNewWorkflowTemplate: dispatch.workflowStore.doCreateNewWorkflowTemplate,
  doGetFormDetailsByVersion: dispatch.formBuilderStore.doGetFormDetailsByVersion,
});

export default connect(mapState, mapDispatch)(CreateWorkflowTemplateSummeryReviewStepCard);
