import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@goodhuman-me/components';
import { useTranslation } from 'react-i18next';

import { Text } from 'design-components';
import { IBooking } from 'interfaces/booking-interfaces';
import { Schema } from '../schema';
import useGetText from './use-get-text';
import getNumberOfCancelledBookings from '../get-number-of-cancelled-bookings';

export const testId = 'booking-cancel-action-modal-success-view';

interface IBookingCancelActionModalSuccessViewProps {
  setTitle: (title: string) => void;
  setContinueButtonLabel: (label: string) => void;
  booking: IBooking;
  isVisible: boolean;
  isEditing: boolean;
}

const BookingCancelActionModalSuccessView = ({
  setTitle,
  setContinueButtonLabel,
  booking,
  isVisible,
  isEditing,
}: IBookingCancelActionModalSuccessViewProps) => {
  const { t } = isEditing
    ? useTranslation('', { keyPrefix: 'bookings.modals.cancelBooking.editSuccessStep' })
    : useTranslation('', { keyPrefix: 'bookings.modals.cancelBooking.successStep' });
  const form = useFormContext<Schema>();

  useEffect(() => {
    if (isVisible) {
      const { numberOfRecurringBookingsToDelete, cancelRecurringBooking } = form.getValues();
      const numberOfCancelledBookings = getNumberOfCancelledBookings(
        booking,
        cancelRecurringBooking,
        numberOfRecurringBookingsToDelete,
      );
      if (isEditing) {
        setTitle(t('title'));
        setContinueButtonLabel(t('continue'));
      } else {
        setTitle(t('title', { count: numberOfCancelledBookings ?? 1 }));
        setContinueButtonLabel(t('continue'));
      }
    }
  }, [isVisible, form, isEditing]);

  const { summary, body } = useGetText(form, booking, isEditing);

  return (
    <Box data-testid={testId}>
      <Text>{summary}</Text>
      <Box marginTop="$small">
        <ul style={{ marginBottom: 0 }}>
          {body.map((text) => (
            <li key={text}>
              <Text>{text}</Text>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export default BookingCancelActionModalSuccessView;
