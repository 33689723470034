import React, { HTMLProps } from 'react';
import { Text } from 'common-components/typography';
import { ICrumb } from 'interfaces/common-interface';
import { Icon } from 'antd';
import { ThemeType } from 'antd/es/icon';
import _ from 'lodash';
import { HyperlinkButton } from 'common-components/buttons';
import { Link, useHistory } from 'react-router-dom';

interface IBreadcrumbNavProps extends HTMLProps<HTMLDivElement> {
  crumbs: ICrumb[];
  icon: string;
  theme?: ThemeType;
  disabled?: boolean;
  isHidden?: boolean;
  isBordered?: boolean;
}

export default function BreadcrumbNav(props: IBreadcrumbNavProps) {
  const {
    crumbs,
    icon = null,
    theme = null,
    disabled = false,
    isHidden = false,
    isBordered = true,
    className = '',
    ...otherProps
  } = props;

  const history = useHistory();

  return (
    !isHidden && (
      <div className={`pb-medium ${isBordered ? '' : ''} ${className}`} {...otherProps}>
        {icon && <Icon type={icon} className='mr-small text-color-tertiary' theme={theme} />}
        {_.map(crumbs, (crumb, index) => {
          const isCurrentPage = index === crumbs.length - 1;

          return (
            <Text color='secondary' weight={isCurrentPage ? 'regular' : 'regular'} size='large' key={index}>
              {crumb.icon && <Icon type={crumb.icon} className='mr-medium' theme={crumb.theme} />}{' '}
              {crumb.disabled || disabled || !crumb.target ? (
                crumb.title
              ) : crumb.isGoBack ? (
                <HyperlinkButton
                  fontSize='large'
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {crumb.title}
                </HyperlinkButton>
              ) : (
                <Link to={crumb.target}>
                  <HyperlinkButton fontSize='large'>{crumb.title}</HyperlinkButton>
                </Link>
              )}
              {!isCurrentPage && ' / '}
            </Text>
          );
        })}
      </div>
    )
  );
}
