import { ArrowBottom, ArrowTop } from '@good/icons';
import { NumberFormatter } from '@goodhuman-me/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InsightsSummaryResponse } from 'views/insights/insights';

const getPercentColorClass = (percentChange: number): string => {
  if (percentChange === 0) {
    return 'text-gray-dark-1';
  }

  return percentChange > 0 ? 'text-green-dark-1' : 'text-red-dark-1';
};

type HoursChangedProps = Pick<InsightsSummaryResponse, 'totalHoursChangedPercent'>;
export const HoursChanged = ({ totalHoursChangedPercent }: HoursChangedProps) => {
  const { t } = useTranslation('', { keyPrefix: 'insights.summary.totalHours' });

  if (typeof totalHoursChangedPercent !== 'number') {
    return null;
  }

  const percentChangeColorClass = getPercentColorClass(totalHoursChangedPercent);

  return (
    <div className="align-center flex flex-row space-x-1">
      <div className={`${percentChangeColorClass} align-center flex flex-col text-b-2xs`}>
        <span
          className="font-base"
          aria-valuetext={`hours worked changed ${totalHoursChangedPercent}% from last period`}
        >
          <NumberFormatter value={Math.abs(totalHoursChangedPercent)} />%{totalHoursChangedPercent > 0 && <ArrowTop />}
          {totalHoursChangedPercent < 0 && <ArrowBottom />}
          {t('fromLastPeriod')}
        </span>
      </div>
    </div>
  );
};
