import { Icon } from 'antd';
import { ReactComponent as CheckboxIcon } from 'assets/Icons/form-builder-icons/checkbox-icon.svg';
import { ReactComponent as SingleSelectionIcon } from 'assets/Icons/form-builder-icons/single-selection-icon.svg';
import { ReactComponent as DividerIcon } from 'assets/Icons/form-builder-icons/divider-icon.svg';
import { ReactComponent as MultiSelectionIcon } from 'assets/Icons/form-builder-icons/multi-selection-icon.svg';
import { Text } from 'common-components/typography';
import React from 'react';

export const LabelOption = (
  <div className="pv-x-small ph-12 bg-quaternary bordered rounded whitespace-nowrap">
    <Text color="secondary" font="lato">
      Options <Icon type="caret-down" className="ml-small" />
    </Text>
  </div>
);

export const LabelSingleSelection = <SingleSelectionIcon style={{ minWidth: 16 }} />;

export const LabelMultiSelection = <MultiSelectionIcon />;

export const LabelCheckBox = <CheckboxIcon />;

export const LabelConditional = (
  <Text color="secondary" weight="bold">
    ?
  </Text>
);

export const LabelDivider = <DividerIcon />;

export const LabelHeader = (
  <Text size="x2-large" color="secondary" weight="bold">
    HEADER
  </Text>
);

export const LabelParagraph = (
  <Text color="secondary" className="text-italic">
    Ipsum lorem...
  </Text>
);

export const LabelShortText = (
  <div className="pv-x-small ph-12 bg-quaternary bordered rounded whitespace-nowrap">
    <Text color="secondary" font="lato">
      Text here
    </Text>
  </div>
);

export const LabelLongText = (
  <div className="pv-x-small ph-12 bg-quaternary bordered rounded whitespace-nowrap">
    <Text color="secondary" font="lato">
      Paragraph
    </Text>
  </div>
);

export const LabelNumber = (
  <div className="pv-x-small ph-12 bg-quaternary bordered rounded whitespace-nowrap">
    <Text color="secondary" font="lato">
      123456
    </Text>
  </div>
);

export const LabelCurrency = (
  <div className=" bg-quaternary bordered rounded whitespace-nowrap">
    <Text color="secondary" font="lato" className="bordered-right pv-x-small ph-small">
      $
    </Text>
    <Text color="secondary" font="lato" className="pv-x-small ph-12">
      0.00
    </Text>
  </div>
);

export const LabelAttachment = <Icon type="file-image" />;

export const LabelCalendar = <Icon type="calendar" />;

export const LabelPhoneNumber = (
  <div className="pv-x-small ph-12 bg-quaternary bordered rounded whitespace-nowrap">
    <Text color="secondary" font="lato">
      +610000...
    </Text>
  </div>
);

export const LabelMultipleElements = (
  <div className="pv-x-small ph-12 bg-quaternary bordered rounded whitespace-nowrap">
    <Text color="secondary" font="lato">
      Bundle
    </Text>
  </div>
);
