import React from 'react';
import { Text } from 'common-components/typography';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import { GridRow } from 'common-components/grids';
import { TmMgmtSentToColumn } from '../../team-management-columns/tm-mgmt-sent-to-column';
import { Col } from 'antd';
import { TmMgmtOptionMenuColumn } from '../../team-management-columns/tm-mgmt-option-menu-column';
import { TmMgmtInviteTableActionMenu } from './tm-mgmt-invite-table-action-menu';
import { InvitationStatus } from 'utilities/enum-utils';
import { PortalUser } from 'stores/rematch/models/auth-store';

type TmMgmtInviteTableRevokedParams = {
  portalUser: PortalUser;
  onOpenModal: (status: string, worker?: object | null | undefined) => void;
};

export const TmMgmtInviteTableRevoked = ({ portalUser, onOpenModal }: TmMgmtInviteTableRevokedParams) => {
  const { invitesTeamList } = useSelector((state: IRootState) => state.teamStore);
  const { revokedList } = invitesTeamList;

  return (
    <>
      <div className="mv-large">
        <Text>Revoked invites</Text>
      </div>
      {(revokedList ?? []).map((worker, index: number) => (
        <GridRow key={index}>
          <TmMgmtSentToColumn
            attachmentUrl={worker.attachmentUrl}
            firstName={worker.firstName}
            lastName={worker.lastName}
            email={worker.email}
          />
          <Col span={4} />
          <Col span={8} />
          <TmMgmtOptionMenuColumn
            menu={
              <TmMgmtInviteTableActionMenu
                status={InvitationStatus.REJECTED}
                portalUser={portalUser}
                worker={worker}
                onOpenModal={onOpenModal}
              />
            }
          />
        </GridRow>
      ))}
    </>
  );
};
