import React, { useMemo } from 'react';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { reactTrpc } from 'utilities/react-trpc';
import { notifications } from '@good/ui/notifications';

import {
  ErrorAlert,
  SilServiceContextResolved,
  useInlineForm,
  useSilServiceContext,
} from 'views/sil-service/components';
import { useUpdateService } from 'views/sil-service/hooks';

export const AboutTheServiceForm = () => {
  const { service } = useSilServiceContext() as SilServiceContextResolved;
  const {
    data: serviceDepartments,
    isLoading: isLoadingServiceDepartments,
    isError: isErrorServiceDepartments,
    isFetching: isFetchingServiceDepartments,
    refetch: refetchServiceDepartments,
  } = reactTrpc.serviceProvider.serviceDepartments.list.useQuery({
    serviceProviderId: service.serviceProviderId,
  });
  const updateService = useUpdateService();
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceDetailsTabs.general.aboutTheServiceForm' });
  const { t: tMessages } = useTranslation('', { keyPrefix: 'silService.messages' });
  const schema = useMemo(() => {
    return z.object({
      name: z.string().nonempty({ message: t('fields.name.validation.required') }),
      description: z.string().nonempty({ message: t('fields.description.validation.required') }),
      serviceDepartment: z.string().nonempty({ message: t('fields.serviceDepartment.validation.required') }),
    });
  }, [t]);
  const InlineForm = useInlineForm<z.infer<typeof schema>>({
    values: {
      name: service.serviceName,
      description: service.serviceDescription,
      serviceDepartment: service.serviceDepartmentId,
    },
    resolver: zodResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    try {
      await updateService.mutateAsync({
        serviceId: service.serviceId,
        serviceProviderId: service.serviceProviderId,
        data: {
          serviceName: values.name,
          serviceDescription: values.description,
          serviceDepartmentId: values.serviceDepartment,
        },
      });
    } catch (e) {
      notifications.show({
        color: 'var(--color-red-main)',
        message: tMessages('updateService.error'),
      });
      throw e;
    }
    notifications.show({
      color: 'var(--color-green-main)',
      message: tMessages('updateService.success'),
    });
  };

  if (isErrorServiceDepartments) {
    return (
      <InlineForm
        title={t('title')}
        disabled
        onSubmit={() => {
          return;
        }}
      >
        <ErrorAlert
          message={tMessages('listServiceDepartments.error')}
          onRetry={refetchServiceDepartments}
          isRetrying={isFetchingServiceDepartments}
        />
      </InlineForm>
    );
  }

  return (
    <InlineForm title={t('title')} onSubmit={onSubmit} maxWidth={548} loading={isLoadingServiceDepartments}>
      <InlineForm.StaticField label={t('fields.type.label')} value={t('fields.type.value')} />
      <InlineForm.TextField label={t('fields.name.label')} description={t('fields.name.description')} name='name' />
      <InlineForm.TextareaField
        label={t('fields.description.label')}
        description={t('fields.description.description')}
        name='description'
      />
      <InlineForm.SelectField
        label={t('fields.serviceDepartment.label')}
        description={t('fields.serviceDepartment.description')}
        name='serviceDepartment'
        data={
          serviceDepartments?.map(({ serviceDepartmentName, serviceDepartmentId }) => ({
            value: serviceDepartmentId,
            label: serviceDepartmentName ?? '',
          })) ?? []
        }
      />
    </InlineForm>
  );
};
