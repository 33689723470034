import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const SortNumberReverse = forwardRef(function SortNumberReverse(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2.25a.75.75 0 0 0-.75.75v3c0 .414.336.75.75.75h3.25v1.5H15a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75h-4Zm3.25 1.5v1.5h-2.5v-1.5h2.5ZM7 5.25a.75.75 0 0 1 .75.75v10.19l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l1.72 1.72V6A.75.75 0 0 1 7 5.25Zm10.287 7.057a.75.75 0 0 1 .463.693v5.25H19a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1 0-1.5h1.25v-3.44l-.72.72a.75.75 0 1 1-1.06-1.06l2-2a.75.75 0 0 1 .817-.163Z"
        fill="currentColor"
      />
    </Icon>
  );
});
