import React from 'react';
import { useTranslation } from 'react-i18next';
import { rem } from '@good/ui/core';
import { PageHeader } from '@good/ui/templates';

import { ServiceSettingsForm } from './service-settings-form';
import { ShiftSettingsForm } from './shift-settings-form';

export const GeneralSettings = () => {
  const { t } = useTranslation('', { keyPrefix: 'silService.settingsTabs.general' });

  return (
    <>
      <PageHeader title={t('title')} subtitle={t('description')} size='h2' mb={rem(24)} />
      <div className='flex flex-col gap-6 w-full'>
        <ServiceSettingsForm />
        <ShiftSettingsForm />
      </div>
    </>
  );
};
