import React, { Component } from 'react';
import { notification, Row } from 'antd';
import { connect } from 'react-redux';
import { Paragraph } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { GroupServiceSessionStatus } from 'utilities/enum-utils';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

interface IRevertSessionModalProps {
  closeModal: () => void;
  isOpen: boolean;
  revertedToStatus: GroupServiceSessionStatus;
  doRevertSessionStatus: typeof dispatch.groupServiceStore.doRevertSessionStatus;
  selectedSession: typeof state.groupServiceStore.selectedSession;
}

interface IRevertSessionModalState {
  isLoading: boolean;
  isSuccess: boolean;
}

class RevertSessionModal extends Component<IRevertSessionModalProps, IRevertSessionModalState> {
  state = {
    isLoading: false,
    isSuccess: false,
  };

  private _closeModal = () => {
    this.setState({ isLoading: false, isSuccess: false });
    this.props.closeModal();
  };

  private _revertSession = async () => {
    const { revertedToStatus, doRevertSessionStatus, selectedSession } = this.props;
    this.setState({ isLoading: true });
    try {
      await doRevertSessionStatus({
        serviceId: selectedSession.serviceId,
        serviceDateTimeId: selectedSession.serviceDateTimeId,
        sessionStatus: revertedToStatus,
      });
      this.setState({ isSuccess: true });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { selectedSession, revertedToStatus } = this.props;
    const { isLoading, isSuccess } = this.state;

    const isRevertToInProgress = revertedToStatus === GroupServiceSessionStatus.INPROGRESS;

    return (
      <div>
        <ActionModal
          isOpen={this.props.isOpen}
          title={`Revert to ${isRevertToInProgress ? 'in progress' : 'scheduled'}`}
          onClose={this._closeModal}
          width={'large'}
        >
          {selectedSession && (
            <>
              {!isSuccess ? (
                <>
                  <div>
                    <Paragraph>
                      This session is{' '}
                      <b>
                        {selectedSession.sessionStatus === GroupServiceSessionStatus.COMPLETED
                          ? 'completed'
                          : 'in progress'}
                      </b>
                      . Are you sure you want to revert it to{' '}
                      <b>{isRevertToInProgress ? 'in progress' : 'scheduled'}?</b>
                    </Paragraph>
                  </div>
                  <div className={'mt-large'}>
                    <Row type={'flex'} justify={'end'}>
                      <SecondaryButton className="mr-medium" size="large" onClick={this._closeModal}>
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton size="large" loading={isLoading} onClick={this._revertSession}>
                        Revert to {isRevertToInProgress ? 'in progress' : 'scheduled'}
                      </PrimaryButton>
                    </Row>
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-large">
                    <Paragraph>
                      This session status has been reverted to {isRevertToInProgress ? 'in progress' : 'scheduled'}
                    </Paragraph>
                  </div>
                  <div className={'mt-large'}>
                    <Row type={'flex'} justify={'end'}>
                      <PrimaryButton size="large" onClick={this._closeModal}>
                        Close
                      </PrimaryButton>
                    </Row>
                  </div>
                </>
              )}
            </>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRevertSessionStatus: dispatch.groupServiceStore.doRevertSessionStatus,
});

export default connect(mapState, mapDispatch)(RevertSessionModal);
