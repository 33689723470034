import React from 'react';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';

type HiddenFieldProps= {
  style?: Record<string, string>;
  name: FieldPath<FieldValues>;
};

export const HiddenField = ({ name, style }: HiddenFieldProps) => {
  const form = useFormContext<FieldValues>()

  return (
    <div style={style}>
      <input {...form.register(name)} type='hidden'/>
    </div>
  )
}
