import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IRootState } from 'stores/rematch/root-store';
import { convertToDate } from 'utilities/date-utils';
import { ISessionTimes, ISessionTimesParsed, IGroupBookingTimes } from '../types';

function parseSessionTimes(sessionTimes: ISessionTimes | undefined): ISessionTimesParsed | null {
  return sessionTimes
    ? {
        startDateTime: convertToDate(sessionTimes.startDateTime),
        endDateTime: convertToDate(sessionTimes.endDateTime),
        timezone: sessionTimes.timezone,
      }
    : null;
}

export function useSessionTimes(type: 'create' | 'edit', sessionTimesCustom: ISessionTimes | undefined) {
  const [sessionTimes, setSessionTimes] = useState<ISessionTimesParsed | null>(null);

  const booking = useSelector(
    (state: IRootState) => state.groupBookingsStore.selectedGroupBookingItem,
  ) as unknown as IGroupBookingTimes;

  const session = useSelector((state: IRootState) => state.groupServiceStore.selectedSession);

  useEffect(() => {
    const sessionTimesFromStore = type === 'create' ? session : booking;
    const sessionTimes_: ISessionTimesParsed | null = parseSessionTimes(sessionTimesCustom ?? sessionTimesFromStore);

    setSessionTimes(sessionTimes_);
  }, [
    sessionTimesCustom?.startDateTime,
    sessionTimesCustom?.endDateTime,
    sessionTimesCustom?.timezone,
    booking,
    session,
  ]);

  return sessionTimes;
}
