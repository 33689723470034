import { Col, Form, notification, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import TimeInput from 'common-components/time-input/TimeInput';
import { SubTitle } from 'common-components/typography';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { BookingStatus } from 'utilities/enum-utils';
import MarkAttendancePanel from 'views/group-services/session-details/components/MarkAttendancePanel';

interface IStartSessionModalProps extends FormComponentProps {
  closeModal: (resetSessionList) => void;
  isOpen: boolean;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  doEndSession: typeof dispatch.groupServiceStore.doEndSession;
}

interface IStartSessionModalState {
  title: string;
  isLoading: boolean;
  isMarkAttendanceStep: boolean;
  selectedCustomerToStart: any;
  endDateTime: Moment;
}

class StartSessionModal extends Component<IStartSessionModalProps, IStartSessionModalState> {
  state = {
    title: 'End session',
    isLoading: false,
    isMarkAttendanceStep: false,
    selectedCustomerToStart: null,
    endDateTime: null,
  };

  private _closeModal = (resetSessionList = false) => {
    this.setState({
      title: 'End session',
      isLoading: false,
      isMarkAttendanceStep: false,
      selectedCustomerToStart: null,
      endDateTime: moment(),
    });
    this.props.closeModal(resetSessionList);
  };

  private _endSession = async (selectedCustomersTimes) => {
    const { selectedSession } = this.props;
    this.setState({ isLoading: true, isMarkAttendanceStep: false });
    try {
      await this.props.doEndSession({
        serviceId: selectedSession.serviceId,
        serviceDateTimeId: selectedSession.serviceDateTimeId,
        customerAttendances: _.map(selectedCustomersTimes, (customerTime) => {
          return {
            attendanceId: customerTime.attendanceId,
            endDateTime: moment.tz(moment(customerTime.dateTime).format('YYYY-MM-DD HH:mm'), selectedSession.timezone),
          };
        }),
        sessionEndDateTime: moment.tz(
          moment(this.state.endDateTime).format('YYYY-MM-DD HH:mm'),
          selectedSession.timezone,
        ),
      });
      this._closeModal(true);
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isLoading: false });
    }
  };

  private _onChangeEndDate = (event) => {
    this.setState({ endDateTime: CommonUtils.formatCeilingDateTime(event) });
  };

  private _goToMarkAttendanceStep = () => {
    this.setState({ isMarkAttendanceStep: true, title: 'Mark customer attendance (Optional)' });
  };

  private _goBackFromMarkAttendance = () => {
    this.setState({ isMarkAttendanceStep: false, title: 'End session' });
  };

  componentDidUpdate = async (
    prevProps: Readonly<IStartSessionModalProps>,
    prevState: Readonly<IStartSessionModalState>,
    snapshot?: any,
  ) => {
    const { selectedSession } = this.props;
    if (!prevProps.isOpen && this.props.isOpen && this.props.selectedSession) {
      this.setState({
        endDateTime: moment(
          moment.tz(selectedSession.endDateTime, selectedSession.timezone).format('YYYY-MM-DD HH:mm'),
        ),
      });
    }
  };

  render() {
    const { selectedSession } = this.props;
    const { title, isLoading, isMarkAttendanceStep, endDateTime } = this.state;

    return (
      <ActionModal isOpen={this.props.isOpen} title={title} onClose={() => this._closeModal(false)} width={'large'}>
        {selectedSession && (
          <>
            {isMarkAttendanceStep ? (
              <MarkAttendancePanel
                type={'endDateTime'}
                saveTimes={this._endSession}
                goBack={this._goBackFromMarkAttendance}
                selectedTime={endDateTime}
                displayedStatus={[BookingStatus.INPROGRESS]}
              />
            ) : (
              <div className="anim-slide-left">
                <SubTitle>Session end time</SubTitle>
                <div className="flex-row align-left mb-x-large">
                  <DatePicker
                    className="gh-datepicker rounded mr-small"
                    calendarClassName="gh-datepicker-calendar"
                    dateFormat="d/M/yyyy"
                    isClearable={false}
                    selected={moment(endDateTime).toDate()}
                    onChange={(event) => this._onChangeEndDate(event)}
                  />
                  <TimeInput
                    size="large"
                    value={moment(endDateTime)}
                    onChange={(event) => this._onChangeEndDate(event)}
                  />
                </div>

                <div className={'mb-small'}>
                  <Row type={'flex'} justify={'end'}>
                    <Col>
                      <GhostButton size="large" onClick={() => this._closeModal(false)}>
                        Cancel
                      </GhostButton>
                    </Col>
                    <Col>
                      <PrimaryButton loading={isLoading} onClick={this._goToMarkAttendanceStep} size="large">
                        Next
                      </PrimaryButton>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEndSession: dispatch.groupServiceStore.doEndSession,
});

export default connect(mapState, mapDispatch)(Form.create<IStartSessionModalProps>()(StartSessionModal));
