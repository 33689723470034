import { useButton } from '@react-aria/button';
import { useHover } from '@react-aria/interactions';
import { HiddenSelect, useSelect } from '@react-aria/select';
import { mergeProps } from '@react-aria/utils';
import { Item } from '@react-stately/collections';
import { useSelectState } from '@react-stately/select';
import type { AriaSelectProps } from '@react-types/select';
import type { ItemProps } from '@react-types/shared';
import React, { useContext, useLayoutEffect, useState } from 'react';
export { Item } from '@react-stately/collections';

import { OverlayContainer } from '@react-aria/overlays';
import { useFocusRing } from 'react-aria';
import { BoxStyleProps, compose, css, CSS, inputStyleProps, InputStylesProps, ListBox, useStyleProps } from '..';
import { ChevronDown } from '../icon/icons/chevron';
import { InputGroupContext } from '../input-group/input-group-providers';
import { Popover } from '../popover';
import type { IValidations } from '../types';
import { useMultiValidation } from '../utils';
import { flipOutOfViewContent, getOverlayStyles, Placement } from '../utils/overlay';
import type { WrapperVariants } from './select.css';
import * as styles from './select.css';

export type SelectProps = AriaSelectProps<Record<string, unknown>> &
  WrapperVariants &
  InputStylesProps & {
    /**
     * Whether dropdown flip when overflow view
     */
    isFlip?: boolean;
    /**
     * Distance between button and dropdown content
     */
    offset?: number;

    /**
     * Whether show only prefix of options list
     * NOTE: prefix must be provided in Item
     */
    isOnlyShowPrefix?: boolean;

    /**
     * Render rules of field (status, rule)
     */
    validations?: IValidations;

    /**
     * Container styles
     */
    containerStyles?: CSS;

    /**
     * Trigger element styles
     */
    triggerStyles?: CSS;

    /**
     * Overlay element styles
     */
    overlayStyles?: BoxStyleProps;

    /**
     * Wrapper element styles
     */
    wrapperStyles?: BoxStyleProps;

    /**
     * Placement overlay
     */
    placement?: Placement;

    /**
     * Prefix before selected item
     */
    prefix?: JSX.Element;
  };

/**
 *
 * @name
 * Select
 *
 * @props
 * selectedKey: selected value ( must be string )
 *
 * onSelectionChange(): handle selection change
 *
 * @description
 * The element represents a control that provides a menu of options.
 * This component must use with Select.Item
 *
 * @example
 * <Select items={options}>
 *   {(item: typeof options[number]) => <Select.Item key={item.id}>{item.name}</Select.Item>}
 * </Select>
 *
 * Or
 * <Select >
 *    {options.map((item)=>(<Select.Item key={item.id}><p>{item.name}<p></Select.Item>))}
 * </Select>
 *
 * Render with only prefix in `TextField`
 * <TextField
 *    name="Input"
 *    label="First Name"
 *    placeholder="Enter your first name"
 *    prefixComponent={
 *       <Select isOnlyShowPrefix items={options} selectedKey=>
 *        {(item: typeof options[number]) => (
 *          <Select.Item key={item.id} prefix={<image src={item.logoSrc} />}>
 *            {item.name}
 *          </Select.Item>
 *        )}
 *      </Select>
 *    }
 *  />
 */
export function Select(props: SelectProps): JSX.Element {
  const {
    isInsideGroupInput = false,
    isDisabled: isContextDisabled,
    ...inputGroupProps
  } = useContext(InputGroupContext);

  const {
    isFlip = true,
    placeholder = 'Select',
    offset = 5,
    isOnlyShowPrefix = false,
    label = 'Select',
    name,
    isDisabled,
    validationState,
    containerStyles,
    triggerStyles,
    overlayStyles,
    wrapperStyles,
    validations,
    placement = 'bottom left',
    prefix,
  } = props;

  const ref = React.useRef(null);
  const containerRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const [portalPopoverStyles, setPortalPopoverStyles] = useState<BoxStyleProps>({
    position: 'fixed',
    top: 0,
    left: 0,
  });
  const state = useSelectState(props);
  const { triggerProps, valueProps, menuProps } = useSelect({ ...props, label }, state, ref);
  const { selectedItem, close } = state;
  const { isOpen } = state;

  const { styleProps } = useStyleProps(props, inputStyleProps);
  const { buttonProps } = useButton(triggerProps, ref);
  const { isFocusVisible, focusProps } = useFocusRing(props);
  const { hoverProps, isHovered } = useHover(props);

  const selectProps = mergeProps(buttonProps, focusProps, hoverProps);
  const iconSize = isInsideGroupInput ? '$size200 !important' : '$size275';
  const isSelected = !!selectedItem;

  const isShowOutline = isHovered || isFocusVisible || isOpen || validationState === 'invalid';
  const { validationFields, isInvalid } = useMultiValidation({ validations });

  useLayoutEffect(() => {
    if (isOpen) {
      const triggerPosition = containerRef.current?.getBoundingClientRect();
      const overlayStyles = getOverlayStyles({
        placement,
        triggerPosition,
        offset: { top: offset, bottom: offset },
        isFullWidth: true,
      });
      setPortalPopoverStyles(overlayStyles);
    }
  }, [isOpen]);

  useLayoutEffect(
    function flipOverlayPosition() {
      if (!isFlip) {
        return;
      }
      const triggerPosition = containerRef.current?.getBoundingClientRect();
      const popoverPosition = popoverRef.current?.getBoundingClientRect();
      if (popoverPosition) {
        popoverRef.current.style.transform = flipOutOfViewContent(popoverPosition, triggerPosition, {
          top: offset,
          bottom: offset,
        });
      }
    },
    [portalPopoverStyles],
  );

  return (
    <div className={css(containerStyles)()} style={{ zIndex: Number(isShowOutline) }}>
      <div
        ref={containerRef}
        className={compose(
          css(styles.wrapper),
          styles.wrapperVariants({
            isFocused: !isInvalid && (isFocusVisible || isOpen),
            isHovered: !isInvalid && isHovered,
            isDisabled: isDisabled || isContextDisabled,
            isErrored: validationState === 'invalid' || isInvalid,
          }),
          css(styleProps),
          css(inputGroupProps as CSS),
          css(wrapperStyles),
        )}
      >
        <HiddenSelect state={state} triggerRef={ref} label={label} name={name} />
        <button
          {...selectProps}
          ref={ref}
          className={compose(
            css(styles.triggerElementReset, styles.triggerElement),
            styles.triggerElementVariants({
              isInsideGroupInput,
              isNonSelected: !isSelected,
              isDisabled: isDisabled || isContextDisabled,
            }),
            css(triggerStyles),
          )}
        >
          {prefix && <span>{prefix} </span>}
          <span {...valueProps} className={css(styles.selectedValue)()}>
            {isSelected ? (
              <>
                {selectedItem.props.prefix} {isOnlyShowPrefix ? '' : selectedItem.rendered}
              </>
            ) : (
              placeholder
            )}
          </span>
          <div className={css(styles.selectIcon)()} style={{ transform: isOpen ? `rotate(180deg)` : '' }}>
            <ChevronDown width={iconSize} height={iconSize} color="inherit" />
          </div>
        </button>
        {isOpen && !(isDisabled || isContextDisabled) && (
          <OverlayContainer>
            <Popover
              popoverRef={popoverRef}
              {...styles.overlayStyles}
              {...portalPopoverStyles}
              {...overlayStyles}
              isOpen={isOpen}
              onClose={close}
            >
              <ListBox {...menuProps} state={state} />
            </Popover>
          </OverlayContainer>
        )}
      </div>
      {!isInsideGroupInput && validationFields()}
    </div>
  );
}

type SelectItem = ItemProps<unknown> & {
  /**
   * Indicator or shorthand append in front of option
   */
  prefix?: React.ReactNode;
};

/**
 * @props
 * key: unique value which is passed as args to `onSelectionChange()`
 *
 * prefix: Indicator or shorthand append in front of option
 */
Select.Item = Item as React.FunctionComponent<SelectItem>;
