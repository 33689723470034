import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Avatar, Icon, Switch } from 'antd';
import _ from 'lodash';
import WorkerStatusTag from 'common-components/tags/WorkerStatusTag';
import { CustomerSupportWorkerPreference, PublishShiftApplicationAvailability } from 'utilities/enum-utils';
import { Tooltip2 } from '@blueprintjs/popover2';
import WorkerStatusTagV2, { WorkerStatusTooltip } from 'common-components/tags/WorkerStatusTagV2';
class PublishWorkerRow extends Component<{
  worker: any;
  changeIsIncluded: (event, supportWorkerId) => void;
  isSearch: boolean;
}> {
  render() {
    const { worker, changeIsIncluded } = this.props;

    const workerApplied = worker.applicationStatus === 'APPLIED';
    const workerPreferenceBlocked = worker.preference === CustomerSupportWorkerPreference.BLOCKED;
    const workerPreferencePreferred = worker.preference === CustomerSupportWorkerPreference.PREFERRED;
    const isShowPreviouslyUsed = worker.previouslyUsed && !workerPreferenceBlocked;
    const isShowSkillMatched = worker.skillMatched && !workerPreferenceBlocked;

    return (
      <tr className="bordered-bottom">
        <td className="text-align-center pv-medium">
          {worker.availability !== PublishShiftApplicationAvailability.NOT_AVAILABLE && (
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              disabled={workerApplied || workerPreferenceBlocked}
              checked={worker.isIncluded}
              onChange={(event) => changeIsIncluded(event, worker.supportWorkerId)}
            />
          )}
        </td>
        <td style={{ width: '50px' }}>
          <Avatar
            icon="user"
            size="large"
            shape="square"
            src={worker.attachmentUrl}
            style={{ opacity: workerPreferenceBlocked ? 0.5 : 1 }}
          />
        </td>
        <td>
          <table className="width-full">
            <tbody>
              <tr>
                <td colSpan={2}>
                  <Text weight="bold" style={{ opacity: workerPreferenceBlocked ? 0.5 : 1 }}>
                    {worker.firstName} {worker.lastName}
                  </Text>
                  {workerPreferencePreferred && (
                    <Tooltip2 content={'This worker is preferred by the customer'} position="top">
                      <Icon type="star" className="text-color-warning-orange ml-x-small" theme="filled" />
                    </Tooltip2>
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ width: '150px' }}>
                  <WorkerStatusTagV2
                    shiftSlotStatus={workerApplied ? worker.applicationStatus : worker.availability}
                    preference={worker.preference}
                    icon="info-circle"
                  />
                </td>
                <td>
                  {(isShowPreviouslyUsed || isShowSkillMatched) && (
                    <div style={{ opacity: workerPreferenceBlocked ? 0.5 : 1 }} className="flex-row">
                      {isShowPreviouslyUsed && (
                        <WorkerStatusTooltip
                          title="Previously used"
                          content="Team member has previously worked with this customer"
                        >
                          <div className="cursor-pointer">
                            <Icon type="info-circle" className="text-color-secondary mr-x-small" />
                            Previously used
                          </div>
                        </WorkerStatusTooltip>
                      )}
                      {isShowSkillMatched && (
                        <WorkerStatusTooltip
                          title="Skill match"
                          content="Team member’s skills match the customer’s care needs"
                        >
                          <div className="ml-x-small cursor-pointer">
                            <Icon type="info-circle" className="text-color-secondary mr-x-small" />
                            Skill match
                          </div>
                        </WorkerStatusTooltip>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

export default PublishWorkerRow;
