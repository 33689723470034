import styled from 'styled-components';

export const Field = styled.div<{ width?: string }>((props) => ({
  display: 'flex',
  gap: '4px',
  flexDirection: 'column',
  width: props.width ?? 'fit-content',
  '@media (max-width: 768px)': {
    minWidth: '100%',
  },
}));
