import { Avatar } from 'antd';
import moment from 'moment-timezone';
import React from 'react';

import { Text } from 'common-components/typography';
import { IAssignedWorker } from 'interfaces/assign-customer-interfaces';
import { IBookingRatio } from 'interfaces/booking-interfaces';
import { IBookingTimeSlot } from 'interfaces/session-interfaces';

interface IAssignedRatioRowProps {
  ratio: IBookingRatio;
  assignedWorker: IAssignedWorker;
  supportWorkers?: IAssignedWorker[];
  onChangeTeamMember: (timeSlot: IBookingTimeSlot) => void;
  timezone: string;
}

const AssignedRatioRow = (props: IAssignedRatioRowProps): JSX.Element => {
  const { ratio, assignedWorker, supportWorkers, onChangeTeamMember, timezone } = props;
  const { startDateTime, endDateTime, teamMemberCustomerRatio, attendanceTimeSlotId } = ratio;
  const {
    supportWorkerFirstName,
    supportWorkerLastName,
    supportWorkerAttachmentUrl,
    supportWorkerAttendanceId,
    supportWorkerUserId,
  } = assignedWorker;
  const momentStartDateTime = moment.tz(startDateTime, timezone).format('h:mm A');
  const momentEndDateTime = moment.tz(endDateTime, timezone).format('h:mm A');
  const supportWorkerIds = supportWorkers?.map((supportWorker) => supportWorker.supportWorkerUserId) || [];
  const timeSlot = {
    startDateTime,
    endDateTime,
    attendanceTimeSlotId,
    teamMemberCustomerRatio,
    supportWorkerAttendanceId,
    supportWorkerId: supportWorkerUserId,
    supportWorkerIds,
  };

  return (
    <>
      <div>
        <Avatar icon="user" size="small" className="mr-small" src={supportWorkerAttachmentUrl} />
        <Text size="regular">
          <span>{`${supportWorkerFirstName} ${supportWorkerLastName} is assigned ${teamMemberCustomerRatio} between ${momentStartDateTime} - ${momentEndDateTime}`}</span>
        </Text>
      </div>
      <div>
        <Text size="regular" className="cursor-pointer text-color-blue-action">
          <span onClick={() => onChangeTeamMember(timeSlot)}>Edit team member</span>
        </Text>
      </div>
    </>
  );
};

export default AssignedRatioRow;
