import { Avatar, Input, message, notification, Select, Upload } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, SubTitle, Text } from 'common-components/typography';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { IGroupServiceCustomer, IGroupServiceNote } from 'interfaces/service-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAttachmentsNote } from 'src/interfaces/common-interface';
import asyncDelay from 'utilities/asyncDelay';
import firebaseApp from 'stores/firebase-app';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { NoteVisibleType, WorkflowTemplateStatus, WorkflowTemplateTriggerType } from 'utilities/enum-utils';
import TagNoteCustomersModal from 'views/group-services/components/booking-notes/TagNoteCustomersModal';
import { getNoteVisibleOption } from '../../../../utilities/booking-utils';

const { Option } = Select;

function IncidentSelector({ onSelectIncident, isSelected }: { onSelectIncident: any; isSelected: boolean }) {
  return (
    <div className="flex-row line-height-100">
      <div
        className={`rounded pv-small mr-medium cursor-pointer select-none ${
          isSelected ? 'bg-white border-standard-gray bordered ' : 'bg-blue-action'
        }`}
        style={{ paddingLeft: '12px', paddingRight: '12px' }}
        onClick={() => onSelectIncident(false)}
      >
        <Text lineHeight={100} color={isSelected ? 'secondary' : 'white'}>
          No
        </Text>
      </div>
      <div
        className={`rounded pv-small cursor-pointer select-none ${
          isSelected ? 'bg-red' : 'border-standard-gray bordered'
        }`}
        style={{ paddingLeft: '12px', paddingRight: '12px' }}
        onClick={() => onSelectIncident(true)}
      >
        <Text color={isSelected ? 'white' : 'secondary'} lineHeight={100}>
          Yes - This note relates to an incident
        </Text>
      </div>
    </div>
  );
}

interface IAddEditNoteModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  onSaveNoteAction: () => void;
  noteMode: string;
  isAllowSelectedCustomer: boolean;
  selectedSession?: typeof state.groupServiceStore.selectedSession;
  booking?: typeof state.groupBookingsStore.selectedGroupBookingItem;
  selectedCustomers?: IGroupServiceCustomer[];
  currentCustomer?: IGroupServiceCustomer;
  targetNote: IGroupServiceNote;
  defaultVisibleType?: NoteVisibleType;
  doAddGroupServiceSessionNote: typeof dispatch.groupServiceStore.doAddGroupServiceSessionNote;
  doEditGroupServiceSessionNote: typeof dispatch.groupServiceStore.doEditGroupServiceSessionNote;
  doAddGroupBookingNote: typeof dispatch.groupBookingsStore.doAddGroupBookingNote;
  doEditGroupBookingNote: typeof dispatch.groupBookingsStore.doEditGroupBookingNote;
  getListWorkflowTemplates: typeof dispatch.workflowStore.getListWorkflowTemplates;
  handleSubmitIncidentNote?: (data) => void;
  portalUser: typeof state.authStore.portalUser;
}

interface IAddEditNoteModalState {
  isSaving: boolean;
  isTagCustomerModalOpen: boolean;
  isNoTagCustomerWarning: boolean;
  incidentSelected: boolean;
  visibleType: NoteVisibleType;
  selectedCustomers: IGroupServiceCustomer[];
  selectedFiles: Array<File>;
  selectedDocumentsName: IAttachmentsNote[];
  removedFiles: Array<any>;
  isLoading: boolean;
  isInitialise: boolean;
  progress: number;
}

class AddEditGroupNoteModal extends Component<IAddEditNoteModalProps, IAddEditNoteModalState> {
  static defaultProps = {
    defaultVisibleType: NoteVisibleType.PORTAL,
  };

  state = {
    isSaving: false,
    isNoTagCustomerWarning: false,
    isTagCustomerModalOpen: false,
    incidentSelected:
      this.props.noteMode === 'add' ? false : this.props.targetNote ? this.props.targetNote.isIncident : false,
    selectedCustomers:
      this.props.noteMode === 'add'
        ? this.props.selectedCustomers
        : this.props.targetNote
        ? this.props.targetNote.taggedTo
        : [],
    visibleType:
      this.props.noteMode === 'add'
        ? this.props.defaultVisibleType
        : NoteVisibleType[_.get(this.props.targetNote, 'visibleType', this.props.defaultVisibleType)],
    selectedFiles: [],
    selectedDocumentsName: [],
    removedFiles: [],
    isLoading: false,
    isInitialise: false,
    progress: 0,
  };

  onChangeVisibleType = (e) => {
    this.setState({ visibleType: e });
  };

  //region Action handlers
  // Handler for whenever the incident flag is selected.
  onSelectIncident = (incidentSelected) => {
    this.setState({ incidentSelected });
  };

  onSelectCustomer = (customers) => {
    this.setState({ selectedCustomers: customers });
  };

  validateForm = () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (!this.state.selectedCustomers?.length && !this.props.currentCustomer) {
      isFormValid = false;
      this.setState({ isNoTagCustomerWarning: true });
    }

    return isFormValid;
  };

  onSave = async () => {
    if (!this.validateForm()) return;
    const {
      onSaveNoteAction,
      currentCustomer,
      noteMode,
      form,
      targetNote,
      doAddGroupServiceSessionNote,
      doEditGroupServiceSessionNote,
      doAddGroupBookingNote,
      doEditGroupBookingNote,
      selectedSession,
      booking,
      getListWorkflowTemplates,
      handleSubmitIncidentNote,
    } = this.props;
    const { incidentSelected, visibleType, selectedCustomers, selectedFiles, selectedDocumentsName } = this.state;

    let userIds = _.map(selectedCustomers, (customer) => customer.taggedUserId);
    const newSelectedCustomers = selectedCustomers;
    if (currentCustomer) {
      userIds.push(currentCustomer.taggedUserId);
      newSelectedCustomers.push(currentCustomer);
    }

    const payload = {
      isIncident: incidentSelected,
      visibleType,
      taggedTo: userIds,
      body: form.getFieldValue('noteBody'),
    };

    let finalPayload;
    if (selectedSession) {
      const { serviceId, serviceDateTimeId } = selectedSession;
      finalPayload = { ...payload, serviceId, serviceDateTimeId };
    } else {
      const { bookingId } = booking;
      finalPayload = { ...payload, bookingId };
    }

    const documents = _.map(selectedFiles, (document) => {
      return {
        documentName: document.name,
        description: `${document.size} Bytes`,
      };
    });

    finalPayload = {
      ...finalPayload,
      documents: [...documents, ...selectedDocumentsName],
    };

    let result = null;
    this.setState({ isSaving: true });

    // Handle submit an incident note
    const isEdit: boolean = noteMode === 'edit';
    if (handleSubmitIncidentNote && incidentSelected && (!isEdit || (isEdit && targetNote && !targetNote.isIncident))) {
      const incidentWorkflowList: any = await getListWorkflowTemplates({
        page: 1,
        pageSize: 5,
        triggerType: [WorkflowTemplateTriggerType.INCIDENT],
        status: [WorkflowTemplateStatus.ACTIVE],
      });
      if (incidentWorkflowList.length > 0) {
        handleSubmitIncidentNote({
          noteData: isEdit ? { ...finalPayload, noteId: targetNote.noteId } : finalPayload,
          involved: {
            customers:
              newSelectedCustomers.length > 0
                ? newSelectedCustomers.map((customer) => ({
                    customerId: customer.taggedUserId,
                    displayName: `${customer.taggedUserFirstName} ${customer.taggedUserLastName}`,
                    avatar: customer.taggedUserAvatarUrl,
                  }))
                : [],
            workers: [],
          },
          isEdit,
        });
        this.setState({ isSaving: false, selectedCustomers: [] });
        return;
      }
    }

    if (noteMode === 'add' && selectedSession) {
      result = await doAddGroupServiceSessionNote(finalPayload);
    } else if (noteMode === 'add' && booking) {
      result = await doAddGroupBookingNote(finalPayload);
    } else if (noteMode === 'edit' && selectedSession) {
      result = await doEditGroupServiceSessionNote({ ...finalPayload, noteId: targetNote.noteId });
    } else if (noteMode === 'edit' && booking) {
      result = await doEditGroupBookingNote({ ...finalPayload, noteId: targetNote.noteId });
    }

    if (!_.isEmpty(selectedFiles)) {
      await this._uploadFile(result);
    }

    await asyncDelay(1000);
    this.setState({ isSaving: false, selectedCustomers: [] });
    onSaveNoteAction();
  };

  //endregion

  private _uploadFile = async (result) => {
    //need to be specify which type uploading for : BOOKING or CUSTOMER
    const { portalUser, selectedSession, booking } = this.props;
    const { selectedFiles } = this.state;

    const { noteId, uploadDocumentsResult } = result;
    const { uploadBucketUrl, documentIds } = uploadDocumentsResult;

    this.setState({ isInitialise: true, isLoading: false });
    const promise = [];
    let numberOfFiles = 0;

    try {
      for (let index = 0; index < selectedFiles.length; index++) {
        const metadata = {
          customMetadata: {
            serviceProviderId: portalUser.serviceProviderId,
            documentId: documentIds[index],
            serviceDateTimeId: _.get(selectedSession, 'serviceDateTimeId'),
            attendanceId: _.get(booking, 'bookingId'),
            noteId: noteId,
          },
        };

        const storageRef = ref(firebaseApp.storage, `${uploadBucketUrl}/${selectedFiles[index].name}`);
        const uploadFile = uploadBytesResumable(storageRef, selectedFiles[index], metadata);

        uploadFile.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
          },
          (error) => {
            notification.error({ message: 'Upload failed! Please try again.', description: error });
          },
          () => {
            numberOfFiles++;
          },
        );
        promise.push(uploadFile);
      }

      this.setState({ isInitialise: false, isLoading: true });
      await Promise.all(promise)
        .then(() => {
          notification.success({
            message:
              numberOfFiles === 1
                ? 'Attachment is currently being scanned.'
                : `${numberOfFiles} attachments are currently being scanned.`,
          });
        })
        .catch((err) =>
          notification.error({ message: 'All attachments upload failed! Please try again.', description: err }),
        );
    } catch (e) {
      notification.error({ message: 'Upload failed! Please try again.', description: 'Failed to upload files.' });
      console.log(e, 'error catch');
    }

    this.setState({ isInitialise: false, isLoading: false });
  };

  //region Modal handlers
  onOpenTagModal = () => this.setState({ isTagCustomerModalOpen: true, isNoTagCustomerWarning: false });
  onCloseTagModal = () => this.setState({ isTagCustomerModalOpen: false });
  //endregion

  private _hasExtension = (file) => {
    return new RegExp(
      '(' +
        [
          '.jpeg',
          '.jpg',
          '.png',
          '.gif',
          '.pdf',
          '.txt',
          '.ppt',
          '.pptx',
          '.doc',
          '.docx',
          '.csv',
          '.xls',
          '.xlsx',
          '.xlsm',
        ]
          .join('|')
          .replace(/\./g, '\\.') +
        ')$',
    ).test(file.name.toLowerCase());
  };

  private _validateFile = (file) => {
    const isValidType = this._hasExtension(file);
    if (!isValidType) {
      message.error('This file extension is not supported, please choose another format.');
    }
    const isLessThan25Mb = file.size / 1024 / 1024 < 25;
    if (!isLessThan25Mb) {
      message.error('File must be smaller than 25MB.');
    }

    return false;
  };

  private _validateSingleFile = (file) => {
    const isValidType = this._hasExtension(file);
    const isLessThan25Mb = file.size / 1024 / 1024 < 25;

    return isLessThan25Mb && isValidType;
  };

  private _onChangeFiles = (info: any) => {
    const { selectedFiles, selectedDocumentsName, removedFiles } = this.state;

    const fileList = _.map(info.fileList, (file) => file.originFileObj);
    const newFiles = _.filter(fileList, (file) => {
      return (
        this._validateSingleFile(file) &&
        !_.some(removedFiles, ['uid', file.uid]) &&
        !_.some(selectedFiles, ['uid', file.uid])
      );
    });

    const amountCurrentFiles = selectedFiles.length;
    if (amountCurrentFiles <= 5) {
      const newFilesToAttach = _.take(newFiles, 5 - amountCurrentFiles - selectedDocumentsName.length);
      const newFilesNotToAttach = _.filter(newFiles, (file) => !_.some(newFilesToAttach, ['uid', file.uid]));
      this.setState({
        selectedFiles: [...selectedFiles, ...newFilesToAttach],
        removedFiles: [...removedFiles, ...newFilesNotToAttach],
      });
    }
  };

  private _onRemoveFile = (removeFile: any) => {
    const { selectedFiles, removedFiles } = this.state;
    if (removeFile) {
      removedFiles.push(removeFile);
      _.remove(selectedFiles, (file) => {
        return _.isEqual(file, removeFile);
      });

      this.setState({ selectedFiles: selectedFiles, removedFiles: removedFiles });
    }
  };

  private _onRemoveFileName = (removeFile: string) => {
    const { selectedDocumentsName } = this.state;
    if (removeFile) {
      _.remove(selectedDocumentsName, (file) => {
        return _.isEqual(file, removeFile);
      });
      this.setState({ selectedDocumentsName: selectedDocumentsName });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        isSaving: false,
        isNoTagCustomerWarning: false,
        isTagCustomerModalOpen: false,
        incidentSelected:
          this.props.noteMode === 'add' ? false : this.props.targetNote ? this.props.targetNote.isIncident : false,
        selectedCustomers:
          this.props.noteMode === 'add'
            ? this.props.selectedCustomers
            : this.props.targetNote
            ? this.props.targetNote.taggedTo
            : null,
        visibleType:
          this.props.noteMode === 'add'
            ? this.props.defaultVisibleType
            : NoteVisibleType[_.get(this.props.targetNote, 'visibleType', this.props.defaultVisibleType)],
        selectedFiles: [],
        selectedDocumentsName: this.props.noteMode === 'add' ? [] : _.cloneDeep(this.props.targetNote.attachments),
      });
    }
    if (this.props.selectedCustomers && this.props.selectedCustomers !== prevProps.selectedCustomers) {
      this.setState({ selectedCustomers: this.props.selectedCustomers });
    }
  };

  render() {
    const {
      noteMode,
      isOpen,
      onClose,
      isAllowSelectedCustomer,
      form,
      targetNote,
      currentCustomer,
      selectedSession,
      defaultVisibleType,
    } = this.props;
    const { selectedCustomers, isSaving, selectedFiles, selectedDocumentsName } = this.state;
    const { getFieldDecorator } = form;
    const modalTitle = noteMode === 'add' ? 'Add Note' : noteMode === 'edit' ? 'Edit note' : '';
    const defaultNoteVisibleType = noteMode === 'add' ? defaultVisibleType : targetNote && targetNote.visibleType;

    return (
      <>
        <ActionModal isOpen={isOpen} onClose={onClose} title={modalTitle} canCloseOutside={true} width="x-large">
          {isSaving ? (
            <SpinningLoader size={150} message="Saving..." />
          ) : (
            <>
              {/* Incident options section */}
              <section className="mb-large">
                <div className="mb-medium">
                  <Text>Does this note relate to an incident?</Text>
                </div>

                <IncidentSelector onSelectIncident={this.onSelectIncident} isSelected={this.state.incidentSelected} />
              </section>

              {/* Notes section */}
              <section className="mb-large">
                <FieldLabel text="Note" />
                <Form.Item>
                  {getFieldDecorator('noteBody', {
                    initialValue: noteMode === 'edit' && (targetNote?.body ?? targetNote?.noteBody),
                    rules: [{ required: true, min: 5, message: 'Please enter at least 5 characters.' }],
                  })(<Input.TextArea rows={3} placeholder="Enter your note content here..." />)}
                </Form.Item>
              </section>

              {/* Privacy section */}
              <section className="mb-large">
                <FieldLabel text="PRIVACY" />

                <Select
                  size="large"
                  className="width-full"
                  optionLabelProp="label"
                  defaultValue={defaultNoteVisibleType}
                  onChange={this.onChangeVisibleType}
                >
                  {_.map(Object.keys(NoteVisibleType), (key) => {
                    const { label, value, description } = getNoteVisibleOption(NoteVisibleType[key]);
                    return (
                      <Option value={value} label={label}>
                        <Text style={{ whiteSpace: 'pre-wrap' }} lineHeight={100}>
                          <b>{label}</b>
                          <br />
                          <Text size="regular" color="secondary">
                            {description}
                          </Text>
                        </Text>
                      </Option>
                    );
                  })}
                </Select>
              </section>

              <section className="mb-large">
                <div className="mt-medium">
                  <div className="mb-x-small">
                    <Text size="small" color="secondary" lineHeight={100}>
                      Tagged to
                    </Text>
                  </div>
                  <div className="p-medium bordered border-standard-gray bg-quaternary rounded-big line-height-100">
                    {/* Customers */}
                    {((selectedCustomers && selectedCustomers.length > 0) || currentCustomer) && (
                      <div className="flex-row align-center">
                        {currentCustomer && (
                          <div className="mr-medium">
                            {' '}
                            <Avatar className="mr-small" size="small" src={currentCustomer.taggedUserAvatarUrl || ''} />
                            <Text size="regular">{`${currentCustomer.taggedUserFirstName || ''} ${
                              currentCustomer.taggedUserLastName || ''
                            }`}</Text>
                          </div>
                        )}

                        {_.map(selectedCustomers, (customer) => (
                          <div className="mr-medium">
                            {' '}
                            <Avatar className="mr-small" size="small" src={customer.taggedUserAvatarUrl || ''} />
                            <Text size="regular">{`${customer.taggedUserFirstName || ''} ${
                              customer.taggedUserLastName || ''
                            }`}</Text>
                          </div>
                        ))}

                        {isAllowSelectedCustomer && (
                          <HyperlinkButton className="ml-small" fontSize="regular" onClick={this.onOpenTagModal}>
                            {currentCustomer ? 'Add other customers to note' : 'Edit customers'}
                          </HyperlinkButton>
                        )}
                      </div>
                    )}{' '}
                    {!currentCustomer && (!selectedCustomers || selectedCustomers.length === 0) && (
                      <>
                        <Text>No one</Text>
                        {selectedSession && (
                          <HyperlinkButton className="ml-small" fontSize="regular" onClick={this.onOpenTagModal}>
                            Select customers
                          </HyperlinkButton>
                        )}
                      </>
                    )}
                    {!isAllowSelectedCustomer && (
                      <div>
                        <Text size="regular" color="secondary">
                          {`You cannot tag additional customers to a note that is created from the manage customer modal. 
                          Please go to the 'Notes' section of the session to create a note and tag multiple 
                          customers.`}
                        </Text>
                      </div>
                    )}
                  </div>
                  {this.state.isNoTagCustomerWarning && (
                    <div className="mt-small">
                      <Text color="red-dark">Please select a customer.</Text>
                    </div>
                  )}
                </div>
              </section>
              <section className="mt-large">
                <SubTitle>Attachment</SubTitle>

                <div className="mt-medium">
                  <>
                    {!_.isEmpty(selectedFiles) &&
                      _.map(selectedFiles, (file) => (
                        <div className="mb-12 pv-medium ph-12 bg-tertiary rounded-big border-gray-darker flex-row justify-between">
                          {file.name}
                          <HyperlinkButton
                            className="ml-small"
                            onClick={this._onRemoveFile.bind(null, file)}
                            color="red-dark"
                          >
                            Remove
                          </HyperlinkButton>
                        </div>
                      ))}
                    {!_.isEmpty(selectedDocumentsName) &&
                      _.map(selectedDocumentsName, (file) => (
                        <div className="mb-12 pv-medium ph-12 bg-tertiary rounded-big border-gray-darker flex-row justify-between">
                          {file.documentName}
                          <HyperlinkButton
                            className="ml-small"
                            onClick={this._onRemoveFileName.bind(null, file)}
                            color="red-dark"
                          >
                            Remove
                          </HyperlinkButton>
                        </div>
                      ))}
                  </>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {selectedFiles.length + selectedDocumentsName.length >= 5 && (
                      <Text type="danger" className="text-color-warning-orange mb-12">
                        Maximum number of attachments reached (5)
                      </Text>
                    )}
                    <Upload
                      multiple={true}
                      beforeUpload={this._validateFile}
                      showUploadList={false}
                      onChange={this._onChangeFiles}
                    >
                      <SecondaryButton disabled={selectedFiles.length + selectedDocumentsName.length >= 5} icon="plus">
                        Add attachment
                      </SecondaryButton>
                    </Upload>
                  </div>
                </div>
              </section>

              <ActionModalFooter align="right">
                <SecondaryButton size="large" className="mr-medium" onClick={this.props.onClose}>
                  Cancel
                </SecondaryButton>
                {noteMode === 'edit' ? (
                  <PrimaryButton size="large" onClick={this.onSave}>
                    Save
                  </PrimaryButton>
                ) : (
                  <PrimaryButton size="large" onClick={this.onSave}>
                    Add note
                  </PrimaryButton>
                )}
              </ActionModalFooter>
            </>
          )}
        </ActionModal>
        <TagNoteCustomersModal
          isOpen={this.state.isTagCustomerModalOpen}
          onSelectCustomer={this.onSelectCustomer}
          selectedCustomers={this.state.selectedCustomers}
          onClose={this.onCloseTagModal}
          selectedSession={this.props.selectedSession}
          booking={this.props.booking}
          currentCustomer={this.props.currentCustomer}
        />
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddGroupServiceSessionNote: dispatch.groupServiceStore.doAddGroupServiceSessionNote,
  doEditGroupServiceSessionNote: dispatch.groupServiceStore.doEditGroupServiceSessionNote,
  doAddGroupBookingNote: dispatch.groupBookingsStore.doAddGroupBookingNote,
  doEditGroupBookingNote: dispatch.groupBookingsStore.doEditGroupBookingNote,
  getListWorkflowTemplates: dispatch.workflowStore.getListWorkflowTemplates,
});

export default connect(mapState, mapDispatch)(Form.create<IAddEditNoteModalProps>()(AddEditGroupNoteModal));
