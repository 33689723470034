import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import CustomerListingView from './involved/CustomerListingView';
import TeamMemberListingView from './involved/TeamMemberListingView';

interface Props extends FormComponentProps {
  onGetValues?: (_onValues) => any;
  initData?: any;
}

class CustomerMemberInvolvedPanel extends Component<Props> {
  private _onValidForm = () => {
    const { form } = this.props;
    return new Promise((resolve) => {
      form.validateFields((errors, values) => {
        [null, undefined].includes(errors) && console.log('errors', errors);
        resolve([null, undefined].includes(errors) ? values : undefined);
      });
    });
  };

  componentDidMount = async () => {
    const { onGetValues } = this.props;
    onGetValues(this._onValidForm);
  };

  render() {
    const {
      initData,
      form,
      form: { getFieldDecorator },
    } = this.props;
    const { customers = [], workers = [] } = initData || {};
    return (
      <div className="flex-column anim-slide-left mb-medium">
        <Text className="mb-large mt-x-small" size="large">
          {' '}
          {'Select which customers and team members were involved in this incident.'}{' '}
        </Text>
        <Form.Item>
          {getFieldDecorator('customers', {
            initialValue: customers,
            // rules: [
            //   {
            //     validator: (rule, value: any[], callback, source, options) => {
            //       try {
            //         if (value == null || value.length == 0) throw new Error('Customers is not empty.');
            //         callback();
            //       } catch (error) {
            //         callback(error);
            //       }
            //     }
            //   }
            // ]
          })(<CustomerListingView form={form} />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('workers', {
            initialValue: workers,
            // rules: [
            //   {
            //     validator: (rule, value: any[], callback, source, options) => {
            //       try {
            //         if (value == null || value.length == 0) throw new Error('Team members select is not empty.');
            //         callback();
            //       } catch (error) {
            //         callback(error);
            //       }
            //     }
            //   }
            // ]
          })(<TeamMemberListingView form={form} />)}
        </Form.Item>
      </div>
    );
  }
}

export default Form.create<Props>()(CustomerMemberInvolvedPanel);
