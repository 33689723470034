import { Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { Text } from 'common-components/typography';
import 'cropperjs/dist/cropper.css';
import React, { Ref } from 'react';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { LogoShape, LogoShapeExtensions } from '../../utils/constants';

const { Dragger } = Upload;

interface IProps {
  imageUrl: string;
  shape: LogoShape;
  errorMessage?: string;
  cropperRef: Ref<ReactCropperElement>;
  onDropFile: (file: File) => void;
}

const CropperElement = (props: IProps) => {
  const { imageUrl, errorMessage, cropperRef, onDropFile, shape } = props;

  const { cropperElementStyles, aspectRatio, minCropBoxWidth, nonFileUploadedTextStyles } = LogoShapeExtensions[shape];

  return (
    <div className="flex-column justify-center align-center">
      <div className="cropper-element" style={{ ...cropperElementStyles, marginTop: 10, marginBottom: 10 }}>
        <Dragger
          style={{ boxSizing: 'content-box' }}
          className={`flex align-center justify-center bg-text-tertiary bordered border-secondary ${
            errorMessage ? 'border-red-dark' : 'border-secondary'
          }`}
          showUploadList={false}
          customRequest={() => undefined}
          openFileDialogOnClick={false}
          onChange={(instance: UploadChangeParam<any>) => onDropFile(instance?.file.originFileObj)}
        >
          {imageUrl ? (
            <Cropper
              viewMode={0}
              checkOrientation={false}
              checkCrossOrigin={false}
              aspectRatio={aspectRatio}
              autoCropArea={1}
              crossOrigin="anonymous"
              minCropBoxWidth={minCropBoxWidth}
              style={cropperElementStyles}
              src={imageUrl}
              ref={cropperRef}
              dragMode="move"
              center
              guides
              background
              responsive
            />
          ) : (
            <Text color="white" size="large" lineHeight={150} style={nonFileUploadedTextStyles}>
              Drag and drop elements to build your form.
            </Text>
          )}
        </Dragger>
      </div>

      {!!errorMessage && (
        <div className="text-align-center mb-medium">
          <Text size="x-small" color="red" weight="bold">
            {errorMessage}
          </Text>
        </div>
      )}
    </div>
  );
};

export default CropperElement;
