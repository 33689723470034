import React, { Component } from 'react';
import { Form, Row, Col, Select, Icon, Checkbox, Empty, Radio } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { Text, Paragraph, SubTitle } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import ndisHelper from 'variables/data/ndis-helper';
import _ from 'lodash';
import { GhostButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import Search from 'antd/es/input/Search';
import { FilterItemMenu } from 'common-components/filter';
import { FilterType, NDISLineItemUnit } from 'utilities/enum-utils';
import { Popover } from '@blueprintjs/core';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import NumberInput from 'common-components/inputs/NumberInput';
import Utils from 'utilities/Utils';
import AddNDISGroupLineItemsModal from 'views/services/listing/components/AddNDISGroupLineItemsModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

interface IAddVCPLineItemsModalProps extends FormComponentProps {
  closeLineItemModal: () => void;
  isOpen: boolean;
  saveLineItem: (lineItems: any) => void;
  serviceBillingItems: any;
  showPrice?: boolean;
  vcpItems: typeof state.servicesStore.vcpItems;
  doGetVCPItems: typeof dispatch.servicesStore.doGetVCPItems;
}

interface IAddVCPLineItemsModalState {
  isFilterOpen: boolean;
  isLoadingList: boolean;
  isActionModalOpen: boolean;
  lineItems: Array<any>;
  selectedSupportCategorySupportItems: Array<any>;
  isProceedOpen: boolean;
  SupportCategory: any;
  vcpGroups: any;
  page: number;
  pageSize: number;
  categoriesFilter: any;
  searchFilter: string;
  selectedLineItem: any;
  groupLineItems: any;
}

function LineItemEmptyState({ type }) {
  return (
    <div className='flex-1 bg-white mt-x2-large align-center flex-column'>
      <div className=''>
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className='mv-none' />
      </div>
      <Text size='x2-large' color='secondary' weight='bold'>
        No line Item {type === 'search' ? 'found' : 'selected'}.
      </Text>
      <Text color='secondary'>
        {type === 'search' ? 'Try to change the filters.' : 'Select one or more from the left.'}
      </Text>
    </div>
  );
}

class AddVCPLineItemsModal extends Component<IAddVCPLineItemsModalProps, IAddVCPLineItemsModalState> {
  state = {
    isFilterOpen: false,
    isLoadingList: true,
    isActionModalOpen: false,
    lineItems: [],
    selectedSupportCategorySupportItems: [],
    isProceedOpen: false,
    SupportCategory: 1,
    vcpGroups: [],
    page: 1,
    pageSize: 10,
    categoriesFilter: [],
    searchFilter: null,
    selectedLineItem: null,
    groupLineItems: null,
  };

  private _saveLineItems = async () => {
    const { form } = this.props;

    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      this.props.saveLineItem(this.state.selectedSupportCategorySupportItems);
      this._closeLineItemCheckModal();
    }
  };

  private _closeLineItemCheckModal = () => {
    const { lineItems } = this.state;

    if (lineItems.length > 0) {
      this.setState({ isProceedOpen: true });
    } else {
      this._closeLineItemModal();
    }
  };

  private _closeLineItemModal = () => {
    this.setState({
      isFilterOpen: false,
      isLoadingList: true,
      isActionModalOpen: false,
      lineItems: [],
      selectedSupportCategorySupportItems: [],
      isProceedOpen: false,
      SupportCategory: 1,
      vcpGroups: [],
      page: 1,
      pageSize: 10,
      categoriesFilter: [],
      searchFilter: null,
    });
    this.props.closeLineItemModal();
  };

  private _onChangePrice = (lineItemNumber, event) => {
    const newSelectedLineItems = _.map(this.state.selectedSupportCategorySupportItems, (lineItem) => {
      if (lineItem.SupportItemNumber === lineItemNumber) {
        return { ...lineItem, NationalNonRemote: event };
      } else {
        return { ...lineItem };
      }
    });
    this.setState({ selectedSupportCategorySupportItems: newSelectedLineItems });
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedOpen: false });
  };

  private _setFilterOpen = (nextOpenState) => {
    this.setState({ isFilterOpen: nextOpenState });
  };

  private _onEnterSearchText = async (e) => {
    const txt = e.target.value;
    this.setState({ isLoadingList: true, searchFilter: txt }, () => this._debounceSearch(txt));
  };

  private _searchText = async (txt) => {
    await this.props.doGetVCPItems({
      searchString: txt,
    });
    this.setState({ isLoadingList: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _checkForGroup = (item) => {
    this._addSelection([item]);
  };

  private _addSelection = (items) => {
    const { selectedSupportCategorySupportItems } = this.state;
    let newSelections = selectedSupportCategorySupportItems ? selectedSupportCategorySupportItems : [];
    _.forEach(items, (item) => {
      const isChecked = !_.find(
        this.state.selectedSupportCategorySupportItems,
        (lineItem) => lineItem.groupCode === item.groupCode,
      );

      if (isChecked) {
        newSelections.push(item);
      } else {
        newSelections = _.filter(
          selectedSupportCategorySupportItems,
          (lineItem) => lineItem.groupCode !== item.groupCode,
        );
      }
    });
    this.setState({ selectedSupportCategorySupportItems: [...newSelections] });
  };

  componentDidMount = async () => {
    await this.props.doGetVCPItems({});
    const selectedLineItemsGroup = _.chain(this.props.serviceBillingItems)
      .uniqBy('groupCode')
      .map((item) => {
        return {
          groupName: item.groupName,
          groupCode: item.groupCode,
          unit: item.unit,
        };
      })
      .value();

    const vcpGroups = _.chain(this.props.vcpItems)
      .uniqBy('groupCode')
      .map((item) => {
        return {
          groupName: item.groupName,
          groupCode: item.groupCode,
        };
      })
      .value();
    this.setState({
      vcpGroups,
      selectedSupportCategorySupportItems: selectedLineItemsGroup,
      isLoadingList: false,
    });
  };

  componentDidUpdate = async (
    prevProps: Readonly<IAddVCPLineItemsModalProps>,
    prevState: Readonly<IAddVCPLineItemsModalState>,
    snapshot?: any,
  ) => {
    if (!prevProps.isOpen && this.props.isOpen) {
      const selectedLineItemsGroup = _.chain(this.props.serviceBillingItems)
        .uniqBy('groupCode')
        .map((item) => {
          return {
            groupName: item.groupName,
            groupCode: item.groupCode,
            unit: item.unit,
          };
        })
        .value();

      await this.props.doGetVCPItems({});
      const vcpGroups = _.chain(this.props.vcpItems)
        .uniqBy('groupCode')
        .map((item) => {
          return {
            groupName: item.groupName,
            groupCode: item.groupCode,
            unit: item.unit,
          };
        })
        .value();

      this.setState({
        vcpGroups,
        selectedSupportCategorySupportItems: selectedLineItemsGroup,
        isLoadingList: false,
      });
    }
    if (this.props.vcpItems !== prevProps.vcpItems) {
      this.setState({
        isLoadingList: true,
      });
      const vcpGroups = _.chain(this.props.vcpItems)
        .uniqBy('groupCode')
        .map((item) => {
          return {
            groupName: item.groupName,
            groupCode: item.groupCode,
            unit: item.unit,
          };
        })
        .value();
      this.setState({
        vcpGroups,
        isLoadingList: false,
      });
    }
  };

  render() {
    const {
      isFilterOpen,
      searchFilter,
      isLoadingList,
      categoriesFilter,
      vcpGroups,
      selectedSupportCategorySupportItems,
      page,
      pageSize,
    } = this.state;
    const { form, serviceBillingItems, showPrice } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div>
        <ActionModal
          isOpen={this.state.isProceedOpen}
          onClose={this._closeProceedModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className='mr-medium' size='large' onClick={this._closeProceedModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size='large' onClick={this._closeLineItemModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          title={<b>Add VCP Items</b>}
          onClose={this._closeLineItemCheckModal}
          width={'x3-large'}
        >
          <div className='anim-slide-left'>
            <Paragraph>Please select the VCP Items you wish to charge for this service</Paragraph>

            <Row gutter={24}>
              <Col span={12}>
                <Row type={'flex'} align={'middle'}>
                  <Search
                    onChange={this._onEnterSearchText}
                    loading={isLoadingList}
                    placeholder='Search for an item...'
                    allowClear
                    style={{ width: '300px' }}
                    value={searchFilter}
                  />
                </Row>
              </Col>
              <Col span={12}>
                <div>
                  <Text weight={'bold'}>Selected items</Text>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Row style={{ height: '350px', overflow: 'auto' }} className={'bordered mt-small'} id={'scroll'}>
                  {isLoadingList ? (
                    <SpinningLoader size={50} message={'Retrieving items...'} />
                  ) : vcpGroups && vcpGroups.length > 0 ? (
                    _.map(vcpGroups, (lineItem, key) => {
                      const isChecked = !!_.find(
                        selectedSupportCategorySupportItems,
                        (selection) => selection.groupCode === lineItem.groupCode,
                      );
                      return (
                        <Row
                          className={`pv-small bordered-bottom cursor-pointer ${isChecked && 'bg-blue-lightest'}`}
                          key={key}
                          onClick={() => this._checkForGroup(lineItem)}
                        >
                          <Col span={2} className='text-align-center'>
                            <Checkbox checked={isChecked} value={lineItem.groupCode} />
                          </Col>
                          <Col span={22}>
                            <Text>{lineItem.groupName}</Text>
                          </Col>
                        </Row>
                      );
                    })
                  ) : (
                    <LineItemEmptyState type='search' />
                  )}
                </Row>
              </Col>
              <Col span={12}>
                <div style={{ height: '350px', overflow: 'auto' }} className={'bordered mt-small'}>
                  <Row className={'pv-small bordered-bottom border-quaternary'}>
                    <Col span={showPrice ? 13 : 19} className={'ph-small'}>
                      <SubTitle>Line item</SubTitle>
                    </Col>
                    {showPrice && (
                      <Col span={6}>
                        <SubTitle>Price</SubTitle>
                      </Col>
                    )}
                    <Col span={3}>
                      <SubTitle>Unit</SubTitle>
                    </Col>
                    <Col span={2} />
                  </Row>
                  {selectedSupportCategorySupportItems && selectedSupportCategorySupportItems.length > 0 ? (
                    _.map(selectedSupportCategorySupportItems, (lineItem, key) => {
                      return (
                        <Row className={'bordered-bottom pv-small'} type={'flex'} align={'top'} key={key}>
                          <Col span={showPrice ? 13 : 19} className={'ph-small'}>
                            <Text>{lineItem.groupName}</Text>
                          </Col>
                          {showPrice && (
                            <Col span={6}>
                              <Form.Item className='m-none'>
                                {getFieldDecorator('price_' + key, {
                                  initialValue:
                                    lineItem.PriceControl === 'Y' ? Number(lineItem.NationalNonRemote) : null,
                                  rules: [{ required: true, message: 'Invalid price.' }],
                                })(
                                  <NumberInput
                                    precision={2}
                                    addonBefore={'$'}
                                    step={0.01}
                                    min={0}
                                    max={9999.99}
                                    style={{ width: '120px' }}
                                    onChange={(event) => this._onChangePrice(lineItem.SupportItemNumber, event)}
                                  />,
                                )}
                              </Form.Item>
                            </Col>
                          )}
                          <Col span={3} className={'mt-x-small'}>
                            {NDISLineItemUnit[lineItem.unit]}
                          </Col>
                          <Col span={2} className={'text-align-center mt-x-small'} title={'Remove line item'}>
                            <HyperlinkButton onClick={() => this._addSelection([lineItem])}>
                              <Icon type={'close'} />
                            </HyperlinkButton>
                          </Col>
                        </Row>
                      );
                    })
                  ) : (
                    <LineItemEmptyState type={'selection'} />
                  )}
                </div>
              </Col>
            </Row>

            <div className={'mb-small mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <Col>
                  <GhostButton size={'large'} onClick={this._closeLineItemCheckModal}>
                    Close
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton size={'large'} onClick={this._saveLineItems}>
                    Save
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetVCPItems: dispatch.servicesStore.doGetVCPItems,
});

const mapState = (state: IRootState) => ({
  vcpItems: state.servicesStore.vcpItems,
});

export default connect(mapState, mapDispatch)(Form.create<IAddVCPLineItemsModalProps>()(AddVCPLineItemsModal));
