import { forwardRef } from 'react';
import { Illustration } from './illustration';
import type { IllustrationProps } from './illustration';
import type { Ref } from 'react';
export const Rectangle = forwardRef(function Rectangle(props: IllustrationProps, ref: Ref<SVGSVGElement>) {
  return (
    <Illustration {...props} ref={ref}>
      <path
        d="m92.8 13.45-.49 100.643c-.314.038-.496.077-.675.077-1.376 0-2.753-.034-4.124 0-1.352.033-2.7.148-4.052.201-1.199.048-2.397.053-3.596.072-2.878.048-5.751.125-8.63.13-1.722 0-3.44-.12-5.163-.154-1.675-.029-3.355.029-5.03-.038-1.655-.063-3.301-.274-4.952-.346-1.574-.072-3.153-.053-4.731-.062-1.001-.005-2.007-.039-3.008.019-1.79.101-3.58.201-5.362.398-2.19.24-4.365.562-6.579.423-.327-.019-.664.038-1.03.067-.072-.264-.178-.475-.178-.687.164-33.482.332-66.968.5-100.59 1.064-.014 2.07-.067 3.071-.038 3.09.086 6.185.226 9.275.302 1.458.039 2.916-.019 4.38-.019.336 0 .673.043 1.015.067.447.034.895.106 1.338.087 1.468-.058 2.936-.197 4.403-.212 2.787-.023 5.574-.019 8.36.039 1.695.034 3.384.26 5.073.288 2.325.043 4.654-.01 6.984-.034 1.761-.019 3.523 0 5.28-.1 1.982-.116 3.955-.36 5.933-.519.626-.048 1.257-.01 1.988-.01v-.004ZM58.037 82.684l-.188-.072c-.048.159-.096.317-.14.476.044.014.092.028.136.038.067-.149.13-.298.197-.446l-.005.004Z"
        fill="currentColor"
      />
    </Illustration>
  );
});
