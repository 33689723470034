import { Checkbox, Col, DatePicker, Icon, Modal, Row } from 'antd';
import { Warning } from 'common-components/alerts';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import TimeInput from 'common-components/time-input/TimeInput';
import { SubTitle, Text, Title } from 'common-components/typography';
import { IActivityGroup } from 'interfaces/service-interfaces';
import moment, { Moment } from 'moment-timezone';
import React, { memo, useEffect, useState } from 'react';
import CommonUtils from 'utilities/common-utils';
import { RecurringBookingPattern } from 'utilities/enum-utils';

interface IEditActivityGroupRowItemModalProps {
  onClose: () => void;
  isOpen: boolean;
  item: IActivityGroup;
  scheduleEndDate: Date | string;
  scheduleStartDate: Date | string;
  onConfirm: (newItem: IActivityGroup) => void;
  isInSchedule?: boolean;
  recurringPattern?: string;
}

interface IEditActivityGroupRowItemModalState {
  currentStartDate: string | Date | Moment;
  currentEndDate: string | Date | Moment;
  isSameTime: boolean;
  isChecked: boolean;
  isEndTimeBeforeStartTimeError: boolean;
  recurringPatternDisplay: string;
  isLoading: boolean;
}

function EditActivityGroupModal({
  item,
  isOpen,
  onClose,
  scheduleEndDate,
  scheduleStartDate,
  onConfirm,
  isInSchedule,
  recurringPattern,
}: IEditActivityGroupRowItemModalProps) {
  const { startDateTime, endDateTime } = item;

  const initState: IEditActivityGroupRowItemModalState = {
    currentStartDate: startDateTime,
    currentEndDate: endDateTime,
    isSameTime:
      moment(startDateTime).isSameOrAfter(scheduleStartDate) && moment(endDateTime).isSameOrBefore(scheduleEndDate),
    isChecked: moment(startDateTime).isSame(scheduleStartDate) && moment(endDateTime).isSame(scheduleEndDate),
    isEndTimeBeforeStartTimeError: false,
    recurringPatternDisplay: '',
    isLoading: false,
  };

  const [state, setState] = useState<IEditActivityGroupRowItemModalState>(initState);

  const _roundDate = (date) => moment(CommonUtils.formatCeilingDateTime(date));

  const _handleChangeStartingDate = (startingDate) => {
    const roundedValue = _roundDate(moment(startingDate));
    setState({ ...state, currentStartDate: moment(roundedValue).toDate() });
  };

  const _handleChangeEndingDate = (endingDate) => {
    const roundedValue = _roundDate(moment(endingDate));
    setState({ ...state, currentEndDate: moment(roundedValue).toDate() });
  };

  const _handleCloseModal = () => {
    setState(initState);
    onClose();
  };

  const _handleSaveChange = () => {
    setState({ ...state, isLoading: true });
    if (!state.isEndTimeBeforeStartTimeError) {
      const newItem = {
        ...item,
        startDateTime: state.currentStartDate,
        endDateTime: state.currentEndDate,
      };
      onConfirm(newItem);
    }

    setState({ ...state, isLoading: false });
  };

  const _handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      setState({
        ...state,
        currentStartDate: scheduleStartDate,
        currentEndDate: scheduleEndDate,
        isChecked: e.target.checked,
      });
    } else {
      setState({ ...state, isChecked: e.target.checked });
    }
  };

  useEffect(() => {
    const { currentStartDate, currentEndDate } = state;

    setState({
      ...state,
      isSameTime:
        moment(currentStartDate).isSameOrAfter(scheduleStartDate) &&
        moment(currentEndDate).isSameOrBefore(scheduleEndDate),
      isChecked: moment(currentStartDate).isSame(scheduleStartDate) && moment(currentEndDate).isSame(scheduleEndDate),
      isEndTimeBeforeStartTimeError: moment(currentEndDate).isBefore(currentStartDate),
    });
  }, [state.currentStartDate, state.currentEndDate]);

  useEffect(() => {
    setState({
      ...state,
      currentStartDate: startDateTime,
      currentEndDate: endDateTime,
      isSameTime:
        moment(startDateTime).isSameOrAfter(scheduleStartDate) && moment(endDateTime).isSameOrBefore(scheduleEndDate),
      isChecked: moment(startDateTime).isSame(scheduleStartDate) && moment(endDateTime).isSame(scheduleEndDate),
    });
  }, [scheduleStartDate, scheduleEndDate]);

  const { isSameTime, isChecked, currentEndDate, currentStartDate, isEndTimeBeforeStartTimeError, isLoading } = state;

  const _handleClassifyPatternDisplay = () => {
    switch (parseInt(recurringPattern)) {
      case RecurringBookingPattern.EveryWeek: {
        setState({ ...state, recurringPatternDisplay: 'Every Week' });
        break;
      }
      case RecurringBookingPattern.EveryFortnight: {
        setState({ ...state, recurringPatternDisplay: 'Every 2 Weeks' });
        break;
      }
      case RecurringBookingPattern.EveryThreeWeeks: {
        setState({ ...state, recurringPatternDisplay: 'Every 3 Weeks' });
        break;
      }
      case RecurringBookingPattern.EveryFourWeeks: {
        setState({ ...state, recurringPatternDisplay: 'Every 4 Weeks' });
        break;
      }
      default: {
        setState({ ...state, recurringPatternDisplay: 'None' });
        break;
      }
    }
  };

  useEffect(() => {
    _handleClassifyPatternDisplay();
  }, [recurringPattern, isOpen]);

  return (
    <Modal
      visible={isOpen}
      onCancel={_handleCloseModal}
      title={<Title level={4}>Edit activity group</Title>}
      footer={null}
      style={{ minWidth: '720px' }}
      className="ant-modal-custom"
    >
      {!isSameTime && (
        <Warning
          color="orange"
          borderNone={true}
          className="mb-small bg-orange-lightest rounded-big bordered border-orange-lighter"
          content={
            <>
              <Text weight="bold" className="block">
                Activity time is outside the session time
              </Text>
              <Text>
                This activity group doesn’t fit within the session timeframe. Please make sure your activity runs within
                the session.
              </Text>
            </>
          }
        />
      )}

      {isInSchedule ? (
        <Row className="mb-medium bg-quaternary rounded p-medium mt-medium">
          <Row>
            <Col span={8} className="flex-column">
              <SubTitle size="medium">Occurs</SubTitle>
              <Text>{state.recurringPatternDisplay}</Text>
            </Col>
            <Col span={8} className="flex-column">
              <SubTitle size="medium">Day</SubTitle>
              <Text>{moment(scheduleStartDate).format('dddd')}</Text>
            </Col>
            <Col span={8} className="flex-column">
              <SubTitle size="medium">Session Time</SubTitle>
              <Text>
                {moment(scheduleStartDate).format('LT')} - {moment(scheduleEndDate).format('LT')}
              </Text>
            </Col>
          </Row>
          <Row className="mt-medium">
            <SubTitle size="medium">Activity Group</SubTitle>
            <Text weight="bold" className="block">
              {item.name}
            </Text>
            <Text color="secondary" className="block">
              {item.description}
            </Text>
          </Row>
        </Row>
      ) : (
        <Row className="mb-medium bg-quaternary rounded p-medium mt-medium" style={{ maxWidth: '380px' }}>
          <Text weight="bold" className="block">
            {item.name}
          </Text>
          <Text color="secondary">{item.description}</Text>
        </Row>
      )}

      {!isInSchedule && (
        <div className="flex-row align-center">
          <div className="mr-large mb-large">
            <SubTitle>Start Date</SubTitle>
            <div className="flex-row align-left">
              <DatePicker
                size="large"
                format={'DD/MM/YYYY'}
                allowClear={false}
                value={moment(currentStartDate)}
                disabledDate={(current) => {
                  return current < moment().startOf('day');
                }}
                onChange={(event) => _handleChangeStartingDate(event)}
                className="mr-small"
              />
            </div>
          </div>
          <div className=" mb-large">
            <SubTitle>End Date</SubTitle>
            <div className="flex-row align-left">
              <DatePicker
                size="large"
                value={moment(currentEndDate)}
                allowClear={false}
                format={'DD/MM/YYYY'}
                disabledDate={(current) => {
                  return current < moment(currentStartDate).startOf('day');
                }}
                onChange={(event) => _handleChangeEndingDate(event)}
                className="mr-small"
              />
            </div>
          </div>
        </div>
      )}

      <div className="flex-row align-center">
        <div className="mr-large mb-small">
          <SubTitle>Start Time</SubTitle>
          <div className="flex-row align-left">
            <TimeInput
              size="large"
              value={moment(currentStartDate)}
              onChange={(event) => _handleChangeStartingDate(event)}
            />
          </div>
        </div>
        <div className="mb-small">
          <SubTitle>End Time</SubTitle>
          <div className="flex-row align-left">
            <TimeInput
              size="large"
              value={moment(currentEndDate)}
              onChange={(event) => _handleChangeEndingDate(event)}
            />
          </div>
        </div>
      </div>

      {isEndTimeBeforeStartTimeError && (
        <>
          <Text color={'red-dark'} style={{ lineHeight: '200%' }} className="block">
            End time must be after the start time.
          </Text>
        </>
      )}

      {!isSameTime && (
        <div>
          <Icon type={'warning'} theme={'filled'} className="mr-small text-color-warning-orange" />
          <Text type="warning">Activity time doesn’t fit within the session timeframe</Text>
        </div>
      )}

      <Checkbox checked={isChecked} onChange={_handleChangeCheckbox}>
        Make same as session time
      </Checkbox>
      <ActionModalFooter>
        <SecondaryButton size="large" onClick={_handleCloseModal}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          className="ml-medium"
          size="large"
          onClick={_handleSaveChange}
          disabled={isEndTimeBeforeStartTimeError}
          loading={isLoading}
        >
          Save changes
        </PrimaryButton>
      </ActionModalFooter>
    </Modal>
  );
}

export default memo(EditActivityGroupModal);
