import { faker } from '@faker-js/faker';
import moment from 'moment';
import { z } from 'zod';

/*******************************************************************
 ** Insights Summary Filters
 ******************************************************************/

export const insightsSummaryFiltersSchema = z.object({
  teamMemberGroup: z.enum(['ALL_MEMBERS', 'SUPPORT_COORDINATORS']),
  dateLower: z.string().datetime(),
  dateUpper: z.string().datetime(),
  previousDateLower: z.string().datetime(),
  previousDateUpper: z.string().datetime(),
});
export type InsightsSummaryFilters = z.infer<typeof insightsSummaryFiltersSchema>;

/*******************************************************************
 ** ActiveTeamMembers
 ******************************************************************/

export const activeTeamMembersSchema = z.object({
  FullTime: z.number().min(0).optional(),
  PartTime: z.number().min(0).optional(),
  Casual: z.number().min(0).optional(),
  Volunteer: z.number().min(0).optional(),
  Other: z.number().min(0).optional(),
  NotSet: z.number().min(0).optional(),
});
export type ActiveTeamMembers = z.infer<typeof activeTeamMembersSchema>;

/*******************************************************************
 ** Insights Summary Response
 ******************************************************************/

export const insightsSummaryResponseSchema = z.object({
  totalShifts: z.number().min(0),
  totalHours: z.number().min(0),
  totalHoursChangedPercent: z.number().min(-100).nullable(),
  overallUtilisationPercent: z.number().min(0).nullable(),
  activeTeamMembers: activeTeamMembersSchema,
  billableHours: z.number().min(0),
  nonBillableHours: z.number().min(0),
});
export type InsightsSummaryResponse = z.infer<typeof insightsSummaryResponseSchema>;

/*******************************************************************
 ** Insights Table Filters
 ******************************************************************/

export const insightsTableFilters = z.object({
  serviceIds: z.array(z.string().uuid()).optional(),
  searchByName: z.string().optional(),
  startDate: z.date(),
  endDate: z.date(),
});
export type InsightsTableFilters = z.infer<typeof insightsTableFilters>;

/*******************************************************************
 ** Insights Table Request
 ******************************************************************/

export const pagingRequest = z.object({
  page: z.number().min(0),
  limit: z.number().min(0).optional(),
});
export type PagingRequest = z.infer<typeof pagingRequest>;
export type InsightsTableRequest = PagingRequest & InsightsTableFilters;

/*******************************************************************
 ** Insights Table Response
 ******************************************************************/

export const insightsTableResponseRow = z.object({
  supportWorkerId: z.string().uuid(),
  attachmentUrl: z.string().url().or(z.literal('')),
  displayName: z.string().nonempty(),
  contractedHours: z.number().min(0).optional().nullable(),
  contractedHoursUnit: z.enum(['weekly', 'fortnightly']).nullable(),
  billedHours: z.number().min(0).nullable(),
  nonBilledHours: z.number().min(0).nullable(),
  utilisation: z.object({
    allTimePercent: z.number().min(0).optional().nullable(),
    toTodayPercent: z.number().min(0).optional().nullable(),
  }),
});
export type InsightsTableResponseRow = z.infer<typeof insightsTableResponseRow>;

const HOURS_UNITS = ['weekly', 'fortnightly'] as const;
export type ContractedHoursUnit = (typeof HOURS_UNITS)[number];
export const generateFakeInsightsRow = (): InsightsTableResponseRow => ({
  supportWorkerId: faker.datatype.uuid(),
  attachmentUrl: faker.image.avatar(),
  displayName: faker.name.fullName(),
  contractedHours: faker.datatype.number({ min: 0, max: 40 }),
  contractedHoursUnit: 'weekly',
  billedHours: faker.datatype.number({ min: 0, max: 20 }),
  nonBilledHours: faker.datatype.number({ min: 0, max: 10 }),
  utilisation: {
    allTimePercent: faker.datatype.number({ min: 0, max: 100, precision: 1 }),
    toTodayPercent: faker.datatype.number({ min: 0, max: 100, precision: 1 }),
  },
});

export const insightsTableResponseSchema = z.object({
  page: z.number().min(0),
  limit: z.number().min(0).optional(),
  totalItems: z.number().min(0),
  maxPage: z.number().min(0),
  results: z.array(insightsTableResponseRow),
});
export type InsightsTableResponse = z.infer<typeof insightsTableResponseSchema>;

export const TIME_PERIODS = [
  'today',
  'thisWeek',
  'lastWeek',
  'thisFortnight',
  'thisMonth',
  'thisQuarter',
  'thisYear',
] as const;
export type TimePeriod = (typeof TIME_PERIODS)[number];

export type DateFilters = Pick<
  InsightsSummaryFilters,
  'dateLower' | 'dateUpper' | 'previousDateUpper' | 'previousDateLower'
>;

/**
 * @name
 * getSingleUnitDateRange
 *
 * @description
 * Generates the date range and comparison date range used for insight filters
 *
 * @param unit which the date range will be based around e.g. day, week, month, quarter etc
 * @param unitRange number of units that date range contains e.g. 1 week or 2 weeks
 * @param differenceUnit used only  when the Moment difference type is different from the unit type
 * e.g week units must have an isoWeek difference unit (because we start days on monday)
 * @param timezone which dates are based on (usually ServiceProviderTimezone)
 * @param differenceFromNow where we want the date range to begin - defaults to 0
 * e.g differenceFromNow = 0, comparison will start at this day/week/month
 * differenceFromNow = -1, comparison will start at last day/week/month
 *
 * @example
 * // For a fortnightly (last week and this current week) date comparison
 * getSingleUnitDateRange({ unit: 'isoWeek', unitRange: 2, differenceUnit: 'week', timezone: 'Australia/Melbourne', differenceFromNow: -1 })
 */
export const getSingleUnitDateRange = ({
  unit,
  unitRange = 1,
  differenceUnit,
  timezone,
  differenceFromNow = 0,
}: {
  unit: string;
  unitRange?: number;
  differenceUnit?: string;
  differenceFromNow?: number;
  timezone: string;
}) => {
  // lower and upper date range/s will be based around this date
  const baseDate = () =>
    moment.tz(timezone).add(differenceFromNow, (differenceUnit ?? unit) as moment.unitOfTime.DurationConstructor);

  const baseComparisonDate = () =>
    baseDate()
      // travel back in time by {unitRange} number of {units} e.g. for a fortnightly comparison we want to go back 2 weeks
      .add(-1 * unitRange, (differenceUnit ?? unit) as moment.unitOfTime.DurationConstructor);

  return {
    dateLower: baseDate()
      .startOf(unit as moment.unitOfTime.StartOf)
      .toISOString(),
    dateUpper: baseDate()
      // increase range of upper date
      .add(unitRange - 1, (differenceUnit ?? unit) as moment.unitOfTime.DurationConstructor)
      .endOf(unit as moment.unitOfTime.StartOf)
      .toISOString(),
    previousDateLower: baseComparisonDate()
      .startOf(unit as moment.unitOfTime.StartOf)
      .toISOString(),
    previousDateUpper: baseComparisonDate()
      // increase range of upper date
      .add(unitRange - 1, (differenceUnit ?? unit) as moment.unitOfTime.DurationConstructor)
      .endOf(unit as moment.unitOfTime.StartOf)
      .toISOString(),
  };
};

export const getDatesForTimePeriod = (timePeriod: TimePeriod, givenTimezone?: string): DateFilters => {
  // If the user's timezone is not given from the Redux store, then grab it from the browser.
  const timezone: string = givenTimezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  switch (timePeriod) {
    case 'today':
      return getSingleUnitDateRange({ unit: 'day', timezone });
    case 'thisWeek':
      return getSingleUnitDateRange({ unit: 'isoWeek', differenceUnit: 'week', timezone });
    case 'lastWeek':
      return getSingleUnitDateRange({ unit: 'isoWeek', differenceUnit: 'week', differenceFromNow: -1, timezone });
    case 'thisFortnight':
      return getSingleUnitDateRange({
        unit: 'isoWeek',
        differenceUnit: 'week',
        differenceFromNow: -1,
        unitRange: 2,
        timezone,
      });
    case 'thisMonth':
      return getSingleUnitDateRange({ unit: 'month', timezone });
    case 'thisQuarter':
      return getSingleUnitDateRange({ unit: 'quarter', timezone });
    case 'thisYear':
      return getSingleUnitDateRange({ unit: 'year', timezone });
    default:
      return getSingleUnitDateRange({ unit: 'isoWeek', differenceUnit: 'week', timezone });
  }
};
