import { Card, Collapse } from '@blueprintjs/core';
import { Form, Icon, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { IconButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { IWorkflowTemplateStep } from 'interfaces/workflow-interfaces';
import React, { Component } from 'react';
import { DragVerticalSVG } from 'assets/UndrawSVG';
import { WorkflowTemplateStepType, WorkflowTemplateType } from 'utilities/enum-utils';
import StepItemDetail from './StepItemDetail';

interface IStepListItemProps extends FormComponentProps {
  step: IWorkflowTemplateStep;
  workflowTemplateType: WorkflowTemplateType;
  amountStep: number;
  isDisableButtonDelete: boolean;
  onUpdateStep(values: IWorkflowTemplateStep): void;
  onRemoveStep(): void;
  onSetEditStep: (editType: string) => void;
}

interface IStepListItemState {
  isOpen: boolean;
  isEditStepName: boolean;
}

class StepListItem extends Component<IStepListItemProps, IStepListItemState> {
  state = {
    isOpen: false,
    isEditStepName: !this.props.step.name,
  };

  private _onSaveStepName = () => {
    const { form } = this.props;

    form.validateFields(async (err, value) => {
      if (!err) {
        this.setState({ isEditStepName: false });

        const stepDetail = { ...this.props.step, name: value.name ? value.name.trim() : '' };

        this.props.onUpdateStep(stepDetail);
      }
    });
  };

  private _changeEditStep = () => this.setState({ isEditStepName: !this.state.isEditStepName });

  private _validateStepName = (rule, value, callback) => {
    try {
      if (!value || value.trim() === '') {
        throw Error('Please add a step name');
      }
    } catch (e) {
      callback(e);
      return;
    }
    callback();
  };

  componentDidMount() {
    this.setState({ isEditStepName: !this.props.step.name });
  }

  render() {
    const { isOpen, isEditStepName } = this.state;
    const {
      form,
      step,
      workflowTemplateType,
      onRemoveStep,
      onUpdateStep,
      onSetEditStep,
      amountStep,
      isDisableButtonDelete,
    } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const showRemoveStepIcon = step.stepNumber > (workflowTemplateType === WorkflowTemplateType.MANUAL ? 1 : 0);

    return (
      <div className="mb-large">
        <Card
          className={`flex align-center pl-none cursor-pointer ${
            isEditStepName ? 'pv-12 pr-12' : 'pv-medium pr-medium'
          } 
          ${step && step.invalid ? 'bg-red-lightest' : ''}
          ${isOpen ? 'rounded-big-top' : 'rounded-big'}`}
          onClick={() => !isEditStepName && this.setState({ isOpen: !isOpen })}
        >
          <div className="flex align-center justify-center" style={{ minWidth: '40px' }}>
            {step.type === WorkflowTemplateStepType.STEP && <img src={DragVerticalSVG} alt="" />}
          </div>
          <Icon
            type="right"
            className="mh-12"
            style={{
              transition: 'all 0.3s',
              transform: isOpen ? 'rotate(90deg)' : undefined,
            }}
          />
          <Text weight="bolder" size="large">
            {step.type === WorkflowTemplateStepType.TRIGGER ? 'Trigger' : `Step ${step.stepNumber} - `}
          </Text>

          {step.type === WorkflowTemplateStepType.STEP &&
            (isEditStepName || step.name === '' ? (
              <>
                <Form layout="inline" className="align-center pl-12">
                  <Form.Item className={`${!!getFieldError('name') && 'mt-medium'} mb-none`}>
                    {getFieldDecorator('name', {
                      initialValue: step.name,
                      rules: [
                        {
                          validator: this._validateStepName,
                        },
                      ],
                    })(<Input placeholder="Enter step name here..." style={{ minWidth: '352px' }} maxLength={50} />)}
                  </Form.Item>
                </Form>

                <IconButton
                  icon={'save'}
                  shape="circle"
                  color="white"
                  size="small"
                  iconColor={'blue-action'}
                  onClick={this._onSaveStepName}
                />
              </>
            ) : (
              <>
                <Text className="flex-1">{step.name}</Text>
                <div>
                  {(showRemoveStepIcon || (amountStep > 1 && !isDisableButtonDelete) || amountStep > 2) && (
                    <IconButton
                      icon={'delete'}
                      shape="circle"
                      color="white"
                      size="small"
                      iconColor={'blue-action'}
                      className="mr-small"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onRemoveStep();
                      }}
                    />
                  )}

                  <IconButton
                    icon={'edit'}
                    shape="circle"
                    color="white"
                    size="small"
                    iconColor={'blue-action'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this._changeEditStep();
                    }}
                  />
                </div>
              </>
            ))}
        </Card>

        <Collapse isOpen={isOpen} keepChildrenMounted>
          <div className="bg-tertiary p-12 rounded-big-bottom">
            <SubTitle color={'tertiary'} size={'regular'} containerClassName="mb-12">
              STEP DETAILS
            </SubTitle>

            <StepItemDetail
              moduleType="create"
              stepDetail={step}
              onUpdateStep={onUpdateStep}
              onSetEditStep={onSetEditStep}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

export default Form.create<IStepListItemProps>()(StepListItem);
