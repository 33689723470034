import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import { ITotalTimeOfTimeSlotStaffingStatus } from 'interfaces/service-interfaces';
import React from 'react';
import CommonUtils from 'utilities/common-utils';
import { StaffingStatus } from 'utilities/enum-utils';

interface IStaffingStatusItemProps {
  isSingleStaffingStatus: boolean;
  timeOfStatus: number;
  staffingStatus: StaffingStatus;
}

const StaffingStatusItem: React.FunctionComponent<IStaffingStatusItemProps> = (props) => {
  const { staffingStatus, timeOfStatus, isSingleStaffingStatus } = props;
  let status = 'optimally staffed';
  let iconType = 'check';
  let iconClass = 'text-color-green';
  let bgIconClass = 'bg-green-lightest';

  switch (staffingStatus) {
    case StaffingStatus.OVERSTAFFED:
      status = 'overstaffed';
      iconType = 'arrow-up';
      iconClass = 'text-color-red';
      bgIconClass = 'bg-red-lightest';
      break;
    case StaffingStatus.UNDERSTAFFED:
      status = 'understaffed';
      iconType = 'arrow-down';
      iconClass = 'text-color-orange';
      bgIconClass = 'bg-orange-lightest';
      break;
    case StaffingStatus.CORRECTLY_STAFFED:
      status = 'optimally staffed';
      iconType = 'check';
      iconClass = 'text-color-green';
      bgIconClass = 'bg-green-lightest';
      break;
  }

  return (
    <div className="p-x-small flex align-center">
      <div className={`rounded-full align-center justify-center flex mr-small p-x-small ${bgIconClass}`}>
        <Icon type={iconType} className={`text-size-x-small ${iconClass}`} />
      </div>
      <Text size="large">
        <b>{isSingleStaffingStatus ? 'Entire session' : CommonUtils.formatDurationFromMinutes(timeOfStatus)}</b>{' '}
        {status}
      </Text>
    </div>
  );
};

interface IStaffingStatusProps {
  totalTimeOfTimeSlotStaffingStatus: ITotalTimeOfTimeSlotStaffingStatus;
  isSingleStaffingStatus: boolean;
}

const TooltipStaffingStatus: React.FunctionComponent<IStaffingStatusProps> = (props) => {
  const { totalTimeOfTimeSlotStaffingStatus, isSingleStaffingStatus } = props;

  return (
    <div className="bg-white p-12 rounded-big">
      {totalTimeOfTimeSlotStaffingStatus[StaffingStatus.OVERSTAFFED] && (
        <StaffingStatusItem
          staffingStatus={StaffingStatus.OVERSTAFFED}
          isSingleStaffingStatus={isSingleStaffingStatus}
          timeOfStatus={totalTimeOfTimeSlotStaffingStatus[StaffingStatus.OVERSTAFFED]}
        />
      )}

      {totalTimeOfTimeSlotStaffingStatus[StaffingStatus.UNDERSTAFFED] && (
        <StaffingStatusItem
          staffingStatus={StaffingStatus.UNDERSTAFFED}
          isSingleStaffingStatus={isSingleStaffingStatus}
          timeOfStatus={totalTimeOfTimeSlotStaffingStatus[StaffingStatus.UNDERSTAFFED]}
        />
      )}

      {totalTimeOfTimeSlotStaffingStatus[StaffingStatus.CORRECTLY_STAFFED] && (
        <StaffingStatusItem
          staffingStatus={StaffingStatus.CORRECTLY_STAFFED}
          isSingleStaffingStatus={isSingleStaffingStatus}
          timeOfStatus={totalTimeOfTimeSlotStaffingStatus[StaffingStatus.CORRECTLY_STAFFED]}
        />
      )}
    </div>
  );
};

export default TooltipStaffingStatus;
