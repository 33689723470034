import { FilterConfig } from 'types/filters';
import moment from 'moment';

// all possibilities of filters:

// status: [x,y,z]
// paymentStatus: [x,y,z]
// workerStatus: [x,y,z]
// startDate: [startDate, endDate]
// serviceName: ''
// sort: []
// sort filter format is [col,asc] eg ['desc','asc']

// this serves as the filter configurations. Should expand this later to a proper interface
// to add a filter item, add here as well as BookingListingNavigation.

export enum PaymentListFilterKey {
  WAITING_TO_BE_PROCESSED = 'WAITING_TO_BE_PROCESSED',
  PROCESSED = 'PROCESSED',
  PAID = 'PAID',
  BATCH = 'BATCH',
  REJECTED = 'REJECTED',
  WAIVED = 'WAIVED',
  SHIFT_HOURS = 'SHIFT_HOURS',
  NON_BILLABLE = 'NON_BILLABLE',
}

type PaymentListFilterConfigMap = {
  [K in PaymentListFilterKey]: FilterConfig<K>;
};

export const PAYMENTLIST_FILTERCONFIGS = {
  [PaymentListFilterKey.WAITING_TO_BE_PROCESSED]: {
    title: 'Waiting to be processed',
    description: 'Payments that are awaiting to be processed.',
    filters: [],
    key: PaymentListFilterKey.WAITING_TO_BE_PROCESSED,
    type: PaymentListFilterKey.WAITING_TO_BE_PROCESSED,
  },

  [PaymentListFilterKey.PROCESSED]: {
    title: 'Processed',
    description: 'All processed payments.',
    filters: [],
    key: PaymentListFilterKey.PROCESSED,
    type: PaymentListFilterKey.PROCESSED,
  },

  [PaymentListFilterKey.PAID]: {
    title: 'Paid',
    description: 'Invoices which have been paid by the recipient',
    filters: [],
    key: PaymentListFilterKey.PAID,
    type: PaymentListFilterKey.PAID,
  },

  [PaymentListFilterKey.BATCH]: {
    title: 'Batches',
    description: 'All processed batches.',
    filters: [],
    key: PaymentListFilterKey.BATCH,
    type: PaymentListFilterKey.BATCH,
  },

  [PaymentListFilterKey.REJECTED]: {
    title: 'Rejected items',
    description: 'All rejected line items & invoices.',
    filters: [],
    key: PaymentListFilterKey.REJECTED,
    type: PaymentListFilterKey.REJECTED,
  },

  [PaymentListFilterKey.WAIVED]: {
    title: 'Waived line items',
    description: 'All line items that has been waived for payments.',
    filters: [],
    key: PaymentListFilterKey.WAIVED,
    type: PaymentListFilterKey.WAIVED,
  },

  [PaymentListFilterKey.SHIFT_HOURS]: {
    title: 'Timesheets',
    description: 'Hours worked by your team.',
    filters: {
      serviceIds: [],
      startDate: moment().add(-4, 'weeks').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
    },
    key: PaymentListFilterKey.SHIFT_HOURS,
    type: PaymentListFilterKey.SHIFT_HOURS,
  },

  [PaymentListFilterKey.NON_BILLABLE]: {
    title: 'Non-billable',
    customTitle: 'Non-billable items',
    description: 'Payments that are waiting to be processed',
    filters: [],
    key: PaymentListFilterKey.NON_BILLABLE,
    type: PaymentListFilterKey.NON_BILLABLE,
  },
} as const satisfies PaymentListFilterConfigMap;

export type PaymentListFilterConfig = (typeof PAYMENTLIST_FILTERCONFIGS)[keyof typeof PAYMENTLIST_FILTERCONFIGS];
