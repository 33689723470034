import { forwardRef } from 'react';
import { Illustration } from './illustration';
import type { IllustrationProps } from './illustration';
import type { Ref } from 'react';
export const AvatarNeutral = forwardRef(function AvatarNeutral(props: IllustrationProps, ref: Ref<SVGSVGElement>) {
  return (
    <Illustration {...props} ref={ref}>
      <path
        d="M97.302 102.938c-.947-5.323-1.55-10.73-2.842-15.968-1.822-7.373-5.57-13.59-12.45-17.48-.592-.335-2.159-1.675-2.936-2.428-2.413-1.403-6.327-2.83-5.84-4.878 2.148-9.008 4.16-7.041 8.047-10.614 3.459-3.181 5.508-6.521 3.571-11.037-1.175-2.742-1.092-10.743-1.739-12.066-.972-1.99-4.793-8.623-5.36-9.497-3.946-6.044-12.277-8.203-18.193-8.086-2.571.05-5.885 1.505-9.273 2.973-5.187 2.253-6.778 5.776-9.005 10.574-1.517 3.268-2.399 4.233-1.44 9.181.093.477.063.973-.094 1.433-1.281 3.828-2.012 7.64-2.514 9.78-.846 3.597 4.147 6.578 7.08 8.885.816.64 5.527 1.345 6.167 7.795.13 1.307-3.494 3.439-6.589 5.348-.715.951-2.788 2.343-3.297 2.823-2.665 2.523-4.601 5.534-6.121 8.85-3.766 8.211-4.76 17.028-5.667 25.867-.03.296.044.603.03.902-.134 3.315 1.589 5.491 4.495 6.598 3.218 1.224 6.499 2.46 9.864 3.068 7.546 1.368 15.184 2 22.866 2.143 9.076.17 17.99-1.036 26.875-2.702 2.478-.465 4.508-1.523 5.943-3.381-.553-2.825-1.106-5.447-1.578-8.085v.002ZM44.651 43.309c.813-.844 1.276-4.852 1.224-5.09-.835-3.79-.548-5.08 1.728-8.615 2.624-4.08 6.699-7.521 11.505-8.965.23.685 2.159 4.49 2.717 4.945 3.366 2.76 7.009 2.748 9.884 5.266 3.84 3.362 1.63 7.889 5.552 11.155 1.281 1.068 2.555 2.093 2.88 4.104-3.839 3.324-6.567 7.593-8.87 12.166-.471.934-1.098 1.791-1.665 2.677-3.462 5.4-10.114 6.027-14.493 1.358-2.191-2.334-3.988-4.975-4.7-8.099-.655-2.874-1.879-4.858-4.828-5.893-2.405-.847-2.72-3.162-.931-5.014l-.003.005Zm44.8 69.907c-7.751 1.011-15.474 2.365-23.342 2.2-8.129-.17-16.216-.805-24.233-2.129-4.675-.772-6.707-1.685-10.092-3.923-.421-2.135-.66-4.362-.334-6.623.6-4.167 1.101-8.351 1.846-12.494 1.049-5.828 3.234-11.294 6.105-16.431 2.927-5.245 7.865-7.629 13.597-8.629 1.088-.189 2.063-.074 3.049.43 3.897 2 7.81 1.773 11.735.036.956-.422 1.972-.704 3.141-1.112 3.218 2.033 6.088 3.959 9.065 5.699 6.058 3.54 9.832 8.732 11.484 15.503.819 3.359 1.649 6.72 2.284 10.115.638 3.4 1.065 6.842 1.542 10.272.205 1.475.301 2.962.44 4.362-1.864 1.88-4.102 2.434-6.29 2.718l.003.006Z"
        fill="currentColor"
      />
    </Illustration>
  );
});
