/* eslint-disable */
import ndisJsonData0731 from "./ndis-nsw-vic-qld-2020-07-31.json";
import ndisJsonData0701 from "./ndis-nsw-vic-qld-2020-07-01.json";
import ndisJsonData04 from "./ndis-nsw-vic-qld-2020-04.json";
import ndisJsonData20210301 from "./ndis-nsw-vic-qld-2021-03-01.json";
import ndisJsonData20210701 from "./ndis-2021-07-01.json";
import ndisJsonData20220419 from "./ndis-2022-04-19.json";
import ndisJsonData20220101 from "./ndis-2022-01-01.json";
import ndisJsonData20220701 from "./ndis-2022-07-01.json";
import ndisJsonData20230701 from "./ndis-2023-07-01.json";
import ndisJsonData20240701 from "./ndis-2024-07-01.json";

import _ from "lodash";

// NDIS Item interface
export type INDISItem = {
  SupportItemNumber: string;
  SupportItem: string;
  SupportPurposeType: string;
  SupportCategoryNumber: number;
  SupportCategories: string;
  RegistrationGroup: string;
  UnitOfMeasure: string;
  Quote: string;
  DateType: string;
  NTPrice: string; //NT SA TAS WA (MM 1-5)
  ACTPrice: string; //ACT NSW QLD VIC (MM 1-5)
  NationalNonRemote: string;
  NationalRemote: string;
  NationalVeryRemote: string;
  RegistrationGroupNumber: number;
  PriceControl: string;
  GroupName?: string;
  GroupCode?: string;
};

// Shorthand for select unique column
const _selectUnique = (column) => {
  return _.uniq(ndisJsonData20240701.map((item) => item[column])).sort();
};

// Memoized for performance based on column name
const selectUnique = _.memoize(_selectUnique);

// Full data
const allData = ndisJsonData20240701;

//region Individual fields
const unitOfMeasures = selectUnique`UnitOfMeasure`;
const supportPurposeTypes = selectUnique`SupportPurposeType`;
const supportCategoryNumber = selectUnique`SupportCategoryNumber`;
const registrationGroup = selectUnique`RegistrationGroup`;
const supportItemDescription = selectUnique`supportItemDescription`;
// const intensity = selectUnique`intensity`;
const quote = selectUnique`Quote`;
const priceControl = selectUnique`PriceControl`;
//endregion Individual Fields

//region Get by property Functions
const getByPurposeType: (purposeType: string) => INDISItem[] = (purposeType) => {
  return ndisJsonData20240701.filter((item) => item.SupportPurposeType === purposeType);
};

const getByCategoryNumber: (catNumber: number) => INDISItem[] = (catNumber) => {
  return ndisJsonData20240701.filter((item) => item.SupportCategoryNumber === catNumber);
};

const getBySupportItemNumber: (itemNumber: string) => INDISItem = (itemNumber) => {
  let result = ndisJsonData20240701.find((item) => item.SupportItemNumber === itemNumber);
  // need to assign backupResult because result has GroupName and GroupCode
  // reassigning will throw type error

  let backupResult;
  if (!result) {
    backupResult = ndisJsonData20230701.find((item) => item.SupportItemNumber === itemNumber);
  }
  if (!backupResult) {
    backupResult = ndisJsonData20220701.find((item) => item.SupportItemNumber === itemNumber);
  }
  if (!backupResult) {
    backupResult = ndisJsonData20220419.find((item) => item.SupportItemNumber === itemNumber);
  }
  if (!backupResult) {
    backupResult = ndisJsonData20220101.find((item) => item.SupportItemNumber === itemNumber);
  }
  if (!backupResult) {
    backupResult = ndisJsonData20210701.find((item) => item.SupportItemNumber === itemNumber);
  }
  if (!backupResult) {
    backupResult = ndisJsonData20210301.find((item) => item.SupportItemNumber === itemNumber);
  }
  if (!backupResult) {
    backupResult = ndisJsonData0731.find((item) => item.SupportItemNumber === itemNumber);
  }
  if (!backupResult) {
    backupResult = ndisJsonData0701.find((item) => item.SupportItemNumber === itemNumber);
  }
  if (!backupResult) {
    backupResult = ndisJsonData04.find((item) => item.SupportItemNumber === itemNumber);
  }

  const ndisItem = result ?? backupResult;
  return ndisItem as INDISItem;
};

const getByGroupCode: (groupCode: string) => INDISItem[] = (groupCode) => {
  return ndisJsonData20240701.filter((item) => item.GroupCode === groupCode);
};

const getByDateType: (dateType: string) => INDISItem[] = (dateType) => {
  return ndisJsonData20240701.filter((item) => item.DateType === dateType);
};

const getAllCategories: () => any = () => {
  return _.map(_.uniqBy(ndisJsonData20240701, 'SupportCategoryNumber'), (category) => {
    return { supportCategoryNumber: category.SupportCategoryNumber, supportCategory: category.SupportCategories };
  });
};

const getLineItems: (page: number, pageSize: number, searchTxt: string, categories: Array<number>) => INDISItem[] = (
  page = null,
  pageSize = null,
  searchTxt = null,
  categories = null
) => {
  let result;
  if (!_.isEmpty(categories)) {
    result = ndisJsonData20240701.filter((item) => _.find(categories, (cat) => cat === item.SupportCategoryNumber));
  }
  if (searchTxt && searchTxt !== "") {
    const searchArray = _.split(searchTxt, " ");
    result = ndisJsonData20240701.filter((item) => {
      return (
        !!_.find(searchArray, (search) => _.includes(item.SupportItem.toLowerCase(), search.toLowerCase())) ||
        !!_.find(searchArray, (search) => _.includes(item.SupportItemNumber.toLowerCase(), search.toLowerCase()))
      );
    });
  }
  return page && pageSize ? _.slice(result, page * pageSize - pageSize, pageSize * page) : result;
};

const ndisHelper = {
  // Properties
  unitOfMeasures,
  supportPurposeTypes,
  supportCategoryNumber,
  registrationGroup,
  supportItemDescription,
  // intensity,
  quote,
  priceControl,
  allData,

  // Get Methods
  getByPurposeType,
  getByCategoryNumber,
  getBySupportItemNumber,
  getByDateType,
  getAllCategories,
  getLineItems,
  getByGroupCode,
};

export default ndisHelper;
