import React, { Component } from 'react';
import { ITransportBookingDetails } from 'views/group-services/manage-booking-modal/interfaces/group-booking-interfaces';
import { FieldLabel, Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { IRootState, state } from '../../../../../stores/rematch/root-store';
import moment from 'moment-timezone';
import { BookingStatus } from '../../../../../utilities/enum-utils';

interface IScheduledDetailsTabSectionProps {
  onEditDetails: any;
  transportLabel: string;
  transportDetails: ITransportBookingDetails;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  transportType: 'before' | 'after' | string;
}

class ScheduledDetailsTabSection extends Component<IScheduledDetailsTabSectionProps, any> {
  render() {
    const { onEditDetails, transportDetails, transportLabel, selectedGroupBookingItem, transportType } = this.props;

    return (
      <div>
        {/* Pick up / drop off times section */}
        <section className="flex-row mb-large mt-small">
          <div className="mr-medium flex-1 line-height-120">
            <div className="mb-x2-small">
              <FieldLabel text="SCHEDULED PICK UP" />
            </div>
            <Text lineHeight={100}>
              <Text size="x-large" lineHeight={100}>
                {moment.tz(transportDetails.startDateTime, selectedGroupBookingItem.timezone).format('h:mm A')}
              </Text>
              <br />
              <Text size="large" lineHeight={100}>
                {moment.tz(transportDetails.startDateTime, selectedGroupBookingItem.timezone).format('DD MMMM YYYY')}
              </Text>
            </Text>
          </div>
          <div className="flex-1 line-height-120">
            <FieldLabel text="SCHEDULED DROP OFF" />
            <Text lineHeight={120}>
              <Text size="x-large" lineHeight={100}>
                {moment.tz(transportDetails.endDateTime, selectedGroupBookingItem.timezone).format('h:mm A')}
              </Text>
              <br />
              <Text size="large" lineHeight={100}>
                {moment.tz(transportDetails.endDateTime, selectedGroupBookingItem.timezone).format('DD MMMM YYYY')}
              </Text>
            </Text>
          </div>
        </section>

        {/* From/to location section */}
        <section className="flex-row mb-medium">
          <div className="mr-medium flex-1 line-height-120">
            <div className="mb-x2-small">
              <FieldLabel text="FROM LOCATION" />
            </div>
            <Text lineHeight={120}>
              <pre>{transportDetails.address.fullAddress}</pre>
            </Text>
          </div>
          <div className="flex-1 line-height-120">
            <div className="mb-x2-small">
              <FieldLabel text="TO LOCATION" />
            </div>
            <div>
              <Text lineHeight={120}>
                <pre>{transportDetails.address2.fullAddress}</pre>
              </Text>
            </div>
          </div>
        </section>

        {/* Comments section */}
        <section className="">
          <div className="flex-1 line-height-120 mb-medium">
            <div className="mb-x2-small">
              <FieldLabel text="COMMENTS" />
            </div>
            <Text lineHeight={120}>{transportDetails.comment}</Text>
          </div>

          <div>
            {transportDetails.status === BookingStatus.CONFIRMED && (
              <HyperlinkButton
                onClick={() =>
                  onEditDetails({
                    editMode: 'edit',
                    editType: transportType,
                    editDetails: transportDetails,
                  })
                }
              >
                Edit {transportLabel} details...
              </HyperlinkButton>
            )}
          </div>
        </section>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem,
});

export default connect(mapState, null)(ScheduledDetailsTabSection);
