import { Icon } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { Paragraph, Text } from 'common-components/typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';

const PaymentFailedBanner: React.FC = () => {
  const { portalUser, subscriptionOverview } = useSelector((state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    subscriptionOverview: state.subscriptionStore.subscriptionOverview,
  }));

  const isViewRetryButton = PermissionUtils.validatePermission(
    'AccessRetryPaymentLink',
    portalUser.permissions.permissionRoles,
  );

  if (!subscriptionOverview?.alertPaymentFailed.isPaymentFailed) {
    return null;
  }

  return (
    <div className='mv-large'>
      <div className='flex align-center mb-12'>
        <Icon type='warning' className='text-color-red mr-x-small text-size-x-large' />{' '}
        <Text color='red-dark' size='small' weight='bold' className='text-uppercase'>
          Payment Failed
        </Text>
      </div>
      <div className='bordered rounded border-red-dark border-width-medium'>
        <div className='pv-medium ph-large'>
          <Paragraph weight='bolder' size='large'>
            Recent payment unsuccessful
          </Paragraph>
          <Text>
            Your last payment of <b>${subscriptionOverview.alertPaymentFailed.total}</b> didn&apos;t go through. There
            are a number of reasons this can happen: credit card expired, insufficient funds or a spend limit being
            reached for the day. To resolve this fast, update your payment method and select <b>Retry payment now.</b>
          </Text>
        </div>
        <div className='bg-secondary'>
          <div className='flex justify-between align-center mh-large ' style={{ minHeight: 59 }}>
            <Text weight='bolder'>
              GoodHuman will automatically retry payment again using your primary payment method.
            </Text>
            {isViewRetryButton && (
              <HyperlinkButton
                onClick={() => window.open(subscriptionOverview.alertPaymentFailed.linkRetryPayment, '_blank')}
              >
                Retry payment now
              </HyperlinkButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailedBanner;
