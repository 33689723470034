import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Ruler = forwardRef(function Ruler(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.73 3.425c-.387.295-.846.752-1.528 1.434l-8.343 8.343c-.682.681-1.139 1.14-1.434 1.528-.283.37-.347.586-.347.77 0 .184.064.4.347.77.295.387.752.846 1.434 1.528l1.343 1.343c.682.682 1.14 1.139 1.528 1.434.37.283.586.347.77.347.185 0 .4-.064.77-.347.196-.15.41-.34.663-.58L8.47 18.53a.75.75 0 1 1 1.06-1.06L11 18.94 12.94 17l-2.97-2.97a.75.75 0 1 1 1.06-1.06L14 15.94 15.94 14l-1.47-1.47a.75.75 0 0 1 1.06-1.06L17 12.94 18.94 11l-2.97-2.97a.75.75 0 0 1 1.06-1.06l2.964 2.963a8.23 8.23 0 0 0 .581-.663c.283-.37.347-.586.347-.77 0-.184-.064-.4-.347-.77-.295-.387-.752-.846-1.434-1.528l-1.343-1.343c-.681-.682-1.14-1.139-1.528-1.434-.37-.283-.586-.347-.77-.347-.184 0-.4.064-.77.347Zm5.509 8.397.283-.283.008-.009.01-.01c.503-.505.923-.94 1.228-1.34.384-.504.654-1.036.654-1.68 0-.644-.27-1.176-.654-1.68-.364-.477-.894-1.007-1.53-1.642l-.036-.037-1.343-1.343-.037-.037c-.635-.635-1.165-1.165-1.642-1.529-.504-.384-1.036-.654-1.68-.654-.644 0-1.176.27-1.68.654-.477.364-1.007.894-1.642 1.53l-.037.036-8.343 8.343-.037.037c-.635.635-1.165 1.165-1.529 1.642-.384.504-.654 1.036-.654 1.68 0 .644.27 1.176.654 1.68.364.477.894 1.007 1.53 1.642l.036.037 1.344 1.343.036.037c.636.635 1.165 1.165 1.642 1.529.504.384 1.036.654 1.68.654.644 0 1.176-.27 1.68-.654.4-.306.838-.728 1.344-1.231l.006-.007.004-.004.288-.287.037-.037 8.343-8.343.037-.037Z"
        fill="currentColor"
      />
    </Icon>
  );
});
