import { Row } from 'antd';
import WorkflowStatusTag from 'common-components/tags/WorkflowsStatusTag';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IWorkflowListItem } from 'src/interfaces/workflow-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { defaultWorkflowListingFilterParams, defaultWorkflowListingFilters } from 'views/workflows/utils/constants';
import { formatWorkflowListingFilters } from 'views/workflows/utils/workflow-utils';
import WorkflowListingFilterSection from '../../WorkflowListingFilterSection';
import WorkflowListingItem from '../../WorkflowListingItem';

interface IAllWorkflowsItemPanelProps {
  portalUser: typeof state.authStore.portalUser;
  allWorkflows: typeof state.workflowStore.allWorkflows;
  numberWorkflowByStatus: typeof state.workflowStore.numberWorkflowByStatus;
  doGetAllWorkflows: typeof dispatch.workflowStore.doGetAllWorkflows;
  doGetNumberWorkflowByStatus: typeof dispatch.workflowStore.doGetNumberWorkflowByStatus;
  onClickRow(workflow: IWorkflowListItem): void;
  reloadTime?: number;
}

interface IAllWorkflowsItemPanelState {
  isSearching: boolean;
  isLoading: boolean;
  isLoadingInfiniteScrolling: boolean;
  searchString: string;
  workflowFilters: any;
  page: number;
  pageSize: number;
}

class AllWorkflowsItemPanel extends PureComponent<IAllWorkflowsItemPanelProps, IAllWorkflowsItemPanelState> {
  state = {
    isSearching: false,
    isLoading: false,
    isLoadingInfiniteScrolling: false,
    searchString: '',
    workflowFilters: defaultWorkflowListingFilters,
    page: 1,
    pageSize: 20,
  };

  private _onChangeFilters = async (filters: Array<any>) => {
    this.setState({ workflowFilters: filters });
  };

  private _searchText = async (searchString) => {
    const { workflowFilters } = this.state;
    const filters = formatWorkflowListingFilters(workflowFilters);

    this.setState({ isSearching: true, isLoading: true, searchString });

    await this.props.doGetAllWorkflows({
      ...defaultWorkflowListingFilterParams,
      searchString,
      ...filters,
    });
    await this.props.doGetNumberWorkflowByStatus({ searchString, ...filters });

    this.setState({ isSearching: false, page: 1, isLoading: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onSearchText = (value) => {
    this.setState({ isSearching: true });
    this._debounceSearch(value);
  };

  private _fetchMoreAllWorkflows = async () => {
    this.setState({ isLoadingInfiniteScrolling: true });

    const { page, pageSize, searchString, workflowFilters } = this.state;

    const nextPage = page + 1;

    const formattedFilter = formatWorkflowListingFilters(workflowFilters);

    await this.props.doGetAllWorkflows({
      page: nextPage,
      pageSize,
      searchString: searchString,
      ...formattedFilter,
    });

    this.setState({ page: nextPage, isLoadingInfiniteScrolling: false });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { workflowFilters, searchString } = this.state;
    const { doGetNumberWorkflowByStatus, reloadTime } = this.props;

    if (!_.isEqual(prevState.workflowFilters, workflowFilters) || prevProps.reloadTime !== reloadTime) {
      this.setState({ isLoading: true, ...defaultWorkflowListingFilterParams });

      const newFilters = formatWorkflowListingFilters(workflowFilters);

      await this.props.doGetAllWorkflows({ ...defaultWorkflowListingFilterParams, searchString, ...newFilters });
      await doGetNumberWorkflowByStatus({ searchString, ...newFilters });
      this.setState({ isLoading: false });
    }
  };

  componentDidMount = async () => {
    const { doGetAllWorkflows, doGetNumberWorkflowByStatus } = this.props;
    const { searchString } = this.state;

    this.setState({ isLoading: true });

    const workflowFilters = formatWorkflowListingFilters(defaultWorkflowListingFilters);

    await doGetAllWorkflows({ ...defaultWorkflowListingFilterParams, ...workflowFilters });

    await doGetNumberWorkflowByStatus({ searchString, ...workflowFilters });

    this.setState({ isLoading: false });
  };

  render() {
    const { portalUser, allWorkflows, numberWorkflowByStatus } = this.props;
    const { workflowFilters, isSearching, isLoading, page, pageSize } = this.state;

    return (
      <div className="mt-small">
        <WorkflowListingFilterSection
          isSearching={isSearching}
          timezone={portalUser.timezone}
          onSearchText={this._onSearchText}
          workflowFilters={workflowFilters}
          onChangeFilter={this._onChangeFilters}
        />

        <Row type="flex" className="mv-x-large">
          {numberWorkflowByStatus &&
            numberWorkflowByStatus.map((workflow, index) => {
              return (
                <WorkflowStatusTag
                  key={index}
                  status={workflow.status}
                  total={workflow.numberOfWorkflows}
                  className="mr-medium"
                />
              );
            })}
        </Row>

        <WorkflowListingItem
          isLoading={isLoading}
          isLoadingInfiniteScrolling={this.state.isLoadingInfiniteScrolling}
          page={page}
          pageSize={pageSize}
          workflows={allWorkflows}
          timezone={portalUser.timezone}
          onClickRow={this.props.onClickRow}
          onFetchMore={this._fetchMoreAllWorkflows}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  allWorkflows: state.workflowStore.allWorkflows,
  numberWorkflowByStatus: state.workflowStore.numberWorkflowByStatus,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetAllWorkflows: dispatch.workflowStore.doGetAllWorkflows,
  doGetNumberWorkflowByStatus: dispatch.workflowStore.doGetNumberWorkflowByStatus,
});

export default connect(mapState, mapDispatch)(AllWorkflowsItemPanel);
