/* eslint-disable */
import { Col, Tabs } from 'antd';
import { Text } from 'common-components/typography';
import { IServiceDetailsItem, IServiceTeamMember } from 'interfaces/service-interfaces';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { ServicePublishStatus } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import ServiceManagersTab from 'views/services/details/sections/content-section/manager/ServiceManagersTab';
import ServicePaymentTab from 'views/services/details/sections/content-section/pricing/ServicePaymentTab';
import { ServiceAvailabilitiesTab } from './content-section/availabilities/ServiceAvailabilitiesTab';
import { ServiceBookingTab } from './content-section/booking/ServiceBookingTab';
import ServiceDetailsTab from './content-section/details/ServiceDetailsTab';
import ServicePricingTab from './content-section/pricing/ServicePricingTab';
import { ServiceRosterTab } from './content-section/roster/ServiceRosterTab';
import { ServiceSettingsTab } from './content-section/settings/ServiceSettingsTab';

const { TabPane } = Tabs;

interface ServiceDetailsContentPanelProps {
  selectedService: IServiceDetailsItem;
  doUpdateIndividualServiceTime: typeof dispatch.servicesStore.doUpdateIndividualServiceTime;
  doUpdateIndividualServiceDetail: typeof dispatch.servicesStore.doUpdateIndividualServiceDetail;
  doRemoveWorkerFromRoster: typeof dispatch.servicesStore.doRemoveWorkerFromRoster;
  teamList: IServiceTeamMember[];
  doAddKeyContactIntoService: typeof dispatch.servicesStore.doAddKeyContactIntoService;
  doRemoveContactFromService: typeof dispatch.servicesStore.doRemoveContactFromService;
  doUpdateContactForService: typeof dispatch.servicesStore.doUpdateContactForService;
  doAddManagerIntoService: typeof dispatch.servicesStore.doAddManagerIntoService;
  doRemoveManagerFromService: typeof dispatch.servicesStore.doRemoveManagerFromService;
  doUpdateServicePricing: typeof dispatch.servicesStore.doUpdateServicePricing;
  setFilteredServices: typeof dispatch.servicesStore.setFilteredServices;
  setSelectedService: typeof dispatch.servicesStore.setSelectedService;
  filteredServices: typeof state.servicesStore.filteredServices;
  portalUser: typeof state.authStore.portalUser;
  doUpdateServicePaymentSources: typeof dispatch.servicesStore.doUpdateServicePaymentSources;
}

interface ServiceDetailsContentPanelState {}

class ServiceDetailsContentSection extends Component<ServiceDetailsContentPanelProps, ServiceDetailsContentPanelState> {
  render() {
    const {
      selectedService,
      doUpdateIndividualServiceTime,
      doUpdateIndividualServiceDetail,
      doRemoveWorkerFromRoster,
      doUpdateServicePricing,
      doAddManagerIntoService,
      setFilteredServices,
      setSelectedService,
      doRemoveManagerFromService,
      filteredServices,
      portalUser,
    } = this.props;

    const hasEditPermission =
      selectedService.status !== ServicePublishStatus.ARCHIVED &&
      PermissionUtils.validatePermission(
        'EditServiceDetail',
        this.props.portalUser.permissions.permissionRoles,
        selectedService.serviceDepartmentId,
        selectedService.serviceId,
      );

    if (selectedService) {
      return (
        <div className="mt-large">
          <Tabs tabPosition={'top'} defaultActiveKey={'details'} animated={true}>
            {/* Details */}
            <TabPane key={'details'} tab={'Details'}>
              <ServiceDetailsTab
                key={'details'}
                selectedService={selectedService}
                doUpdateIndividualServiceDetail={doUpdateIndividualServiceDetail}
                setFilteredServices={setFilteredServices}
                setSelectedService={setSelectedService}
                filteredServices={filteredServices}
                hasEditPermission={hasEditPermission}
              />
            </TabPane>

            {/* Settings */}
            <TabPane key={'settings'} tab="Settings">
              <ServiceSettingsTab
                selectedService={selectedService}
                doUpdateIndividualServiceDetail={doUpdateIndividualServiceDetail}
                hasEditPermission={hasEditPermission}
                portalUser={portalUser}
              />
            </TabPane>

            <TabPane key={'manager'} tab="Managers">
              <ServiceManagersTab
                doAddManagerIntoService={doAddManagerIntoService}
                selectedService={selectedService}
                doRemoveManagerFromService={doRemoveManagerFromService}
                hasEditPermission={hasEditPermission}
              />
            </TabPane>

            {/*<TabPane key={'contacts'} tab="Contacts">*/}
            {/*  <ServiceContactsTab*/}
            {/*    teamList={teamList}*/}
            {/*    doAddKeyContactIntoService={doAddKeyContactIntoService}*/}
            {/*    selectedService={selectedService}*/}
            {/*    doRemoveContactFromService={doRemoveContactFromService}*/}
            {/*    doUpdateContactForService={doUpdateContactForService}*/}
            {/*  />*/}
            {/*</TabPane>*/}

            {/* Availabilities */}
            <TabPane key={'availabilities'} tab="Service times">
              <ServiceAvailabilitiesTab
                selectedService={selectedService}
                doUpdateIndividualServiceTime={doUpdateIndividualServiceTime}
                hasEditPermission={hasEditPermission}
              />
            </TabPane>

            {/* Location */}
            {/*<TabPane key={'location'} tab="Location">*/}
            {/*  <ServiceLocationTab selectedService={selectedService} />*/}
            {/*</TabPane>*/}

            {/* Pricing */}
            <TabPane key={'pricing'} tab="Pricing">
              <ServicePricingTab
                selectedService={selectedService}
                doUpdateServicePricing={doUpdateServicePricing}
                hasEditPermission={hasEditPermission}
              />
            </TabPane>

            <TabPane key={'payment'} tab="Payment">
              <ServicePaymentTab
                selectedService={selectedService}
                doUpdateServicePricing={doUpdateServicePricing}
                hasEditPermission={hasEditPermission}
                doUpdateServicePaymentSources={this.props.doUpdateServicePaymentSources}
              />
            </TabPane>

            {/* NDIS */}
            {/*<TabPane key={'ndis'} tab="NDIS">*/}
            {/*  <ServiceNDISTab selectedService={selectedService} />*/}
            {/*</TabPane>*/}

            {/* Roster */}
            <TabPane key={'roster'} tab="Roster">
              <ServiceRosterTab
                selectedService={selectedService}
                doRemoveWorkerFromRoster={doRemoveWorkerFromRoster}
                hasEditPermission={hasEditPermission}
              />
            </TabPane>

            {/* Insights */}
            {/*<TabPane key={'insights'} tab="Insights">*/}
            {/*  <ServiceStatisticsTab selectedService={selectedService} />*/}
            {/*</TabPane>*/}
          </Tabs>
        </div>
      );
    } else {
      return (
        <Col span={20}>
          <Text type={'secondary'}>Warning - no service found.</Text>
        </Col>
      );
    }
  }
}
const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  selectedService: state.servicesStore.selectedService,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateServicePaymentSources: dispatch.servicesStore.doUpdateServicePaymentSources,
});
export default connect(mapState, mapDispatch)(ServiceDetailsContentSection);
