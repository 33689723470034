import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@good/components';
import { Button } from '@goodhuman-me/components';
import { Error, GroupOf3People } from '@good/illustrations';
import { Plus } from '@good/icons';

import { WorkTypeCategory } from 'utilities/enum-utils';
import useWorkTypePermissions from '../WorkLeaveTypesTable/use-work-type-permissions';
import { useFlags } from 'launchdarkly-react-client-sdk';

type TableEmptyProps = {
  category: WorkTypeCategory;
  addWorkLeaveType?: (() => void) | undefined;
};

const TableEmpty = ({ category, addWorkLeaveType }: TableEmptyProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: `accountManagement.workLeaveTypesPage.table.${category}` });
  const { canCreate } = useWorkTypePermissions();
  const { pinc762Stage2PayCodeConfigurationWithArchive } = useFlags();
  const shouldShowAddButton = Boolean(canCreate && addWorkLeaveType && pinc762Stage2PayCodeConfigurationWithArchive);

  if (category === WorkTypeCategory.Leave) {
    return (
      <div className="flex-column align-center m-large flex justify-center gap-4">
        <Text>
          <GroupOf3People size="lg" />
        </Text>

        {shouldShowAddButton ? (
          <Button emphasis="filled" onClick={addWorkLeaveType}>
            <Plus />
            {t('addLeaveType')}
          </Button>
        ) : (
          <Text>{t('emptyMessage')}</Text>
        )}
      </div>
    );
  }

  return (
    <div className="flex-column align-center m-large flex justify-center gap-4">
      <Text>
        <Error size="md" />
      </Text>
      <Text>{t('emptyMessage')}</Text>
    </div>
  );
};

export default TableEmpty;
