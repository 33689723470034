import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Key = forwardRef(function Key(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.53 2.47a.75.75 0 0 1 0 1.06l-.47.47 2.47 2.47a.75.75 0 0 1 0 1.06l-3.5 3.5a.75.75 0 0 1-1.06 0L15.5 8.56 11.562 12.5a5.75 5.75 0 1 1-1.06-1.06l4.468-4.47 3.5-3.5 1-1a.75.75 0 0 1 1.06 0ZM16.56 7.5l1.94 1.94L20.94 7 19 5.06 16.56 7.5ZM7 11.75a4.25 4.25 0 1 0 0 8.5 4.25 4.25 0 0 0 0-8.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
