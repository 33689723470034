import React from 'react';
import { ExternalLink } from '@good/icons';
import { Anchor, Card, Group, Stack, Text, Title } from '@good/ui/core';

const SupportCard: React.FC = () => {
  return (
    <Card p='xl' withBorder style={{ minHeight: 128 }}>
      <Stack>
        <Title order={3}>Get support</Title>
        <Anchor href='https://goodhuman.zendesk.com/hc/en-au/requests/new' target='_blank'>
          <Group gap='xs' wrap='nowrap'>
            <Text>Message us, we&apos;re here to help</Text>
            <ExternalLink />
          </Group>
        </Anchor>
      </Stack>
    </Card>
  );
};

export default SupportCard;
