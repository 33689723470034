import { Checkbox, notification } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';

interface IDeleteNoteModalProps {
  noteItem: any;
  isOpen: boolean;
  doDeleteBookingNote: typeof dispatch.bookingsStore.doDeleteBookingNote;
  doDeleteCustomerNote: typeof dispatch.customersStore.doDeleteCustomerNote;
  dofetchCustomerNotes: typeof dispatch.customersStore.dofetchCustomerNotes;
  doCloseDeleteNoteModal: () => void;
  isCustomerDetailsScreen?: boolean;
  customerUserId?: string;
  resetDocumentList?: () => Promise<void>;
}

interface IDeleteNoteModalState {
  isCheckboxTicked: boolean;
  isLoading: boolean;
  isValidDelete: boolean;
}

class DeleteNoteModal extends Component<IDeleteNoteModalProps, IDeleteNoteModalState> {
  state = { isCheckboxTicked: false, isValidDelete: true, isLoading: false };

  private _onClickDelete = async () => {
    //   just another condition check here just in case
    this.setState({ isLoading: true });
    if (this.state.isCheckboxTicked) {
      try {
        const { noteItem, customerUserId } = this.props;

        if (noteItem.attendanceId) {
          await this.props.doDeleteBookingNote({
            bookingId: noteItem.attendanceId,
            noteId: noteItem.noteId,
          });
          if (this.props.isCustomerDetailsScreen) {
            await this.props.dofetchCustomerNotes({
              customerUserId: this.props.customerUserId,
            });
          }
        } else {
          await this.props.doDeleteCustomerNote({
            customerUserId: customerUserId,
            noteId: noteItem.noteId,
          });
        }

        this.props.resetDocumentList && (await this.props.resetDocumentList());
        this._onCloseModal();
      } catch (e) {
        if (e.meta.message === 'Cannot delete note, There 1 or less notes for this booking.') {
          this.setState({ isValidDelete: false });
        } else {
          notification.error({ message: 'Delete note failed! Please try again.' });
        }
      }
    }
    this.setState({ isLoading: false });
  };

  private _onCloseModal = () => {
    this.setState({ isCheckboxTicked: false, isLoading: false, isValidDelete: true });
    this.props.doCloseDeleteNoteModal();
  };

  render() {
    const { noteItem } = this.props;
    const { isValidDelete } = this.state;
    return (
      <div>
        <ActionModal
          title={isValidDelete ? 'Delete note' : 'Cannot delete this note'}
          isOpen={this.props.isOpen}
          onClose={this._onCloseModal}
          width="medium"
        >
          {isValidDelete && noteItem ? (
            <div>
              <Text size="large">You are deleting a note written by </Text>
              <Text weight="bold" size="large">
                {noteItem.firstName} {noteItem.lastName}.
              </Text>
              <br />
              <br />
              <Text size="large">
                This note will be <span className="text-weight-bolder">permanently removed</span> from this customer's
                profile, and the team will no longer be able to view this information.
              </Text>
              <div className="mt-x-large">
                <Checkbox
                  checked={this.state.isCheckboxTicked}
                  onChange={(event) => this.setState({ isCheckboxTicked: event.target.checked })}
                >
                  <Text>I want to permanently delete this note</Text>
                </Checkbox>
              </div>

              <div className="flex justify-end mt-x4-large">
                <div style={{ marginRight: 11 }}>
                  <GhostButton size="large" onClick={this.props.doCloseDeleteNoteModal} color="red-dark">
                    Cancel
                  </GhostButton>
                </div>
                <PrimaryButton
                  size="large"
                  onClick={() => this._onClickDelete()}
                  color="red-dark"
                  disabled={!this.state.isCheckboxTicked}
                  loading={this.state.isLoading}
                >
                  <Text color="white" size="x-large">
                    Delete
                  </Text>
                </PrimaryButton>
              </div>
            </div>
          ) : (
            <div>
              <Text size="large">This note cannot be deleted as it is the only note for this booking.</Text>
              <br />
              <br />
              <Text size="large">
                All completed bookings require at least one note. If you would like to delete this note, add another
                note to the booking first.
              </Text>
              <div className="flex justify-end mt-x4-large">
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  <Text size="x-large" color="white">
                    OK
                  </Text>
                </PrimaryButton>
              </div>
            </div>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doDeleteBookingNote: dispatch.bookingsStore.doDeleteBookingNote,
  doDeleteCustomerNote: dispatch.customersStore.doDeleteCustomerNote,
  dofetchCustomerNotes: dispatch.customersStore.dofetchCustomerNotes,
});

export default connect(null, mapDispatch)(DeleteNoteModal);
