import React from 'react';

import { IAssignedWorker } from 'interfaces/assign-customer-interfaces';
import { IBookingRatio } from 'interfaces/booking-interfaces';
import { IBookingTimeSlot } from 'interfaces/session-interfaces';
import AssignedRatioRow from './AssignedRatioRow';
import UnassignedRatioRow from './UnassignedRatioRow';

interface ITwoToOneBookingRatioPanelProps {
  ratio: IBookingRatio;
  onChangeTeamMember: (timeSlot: IBookingTimeSlot) => void;
  timezone: string;
  supportWorkers: IAssignedWorker[];
  isTheLastBookingRatio: boolean;
  className?: string;
}

const TwoToOneBookingRatioPanel = (props: ITwoToOneBookingRatioPanelProps): JSX.Element => {
  const { ratio, onChangeTeamMember, timezone, supportWorkers, isTheLastBookingRatio = false, className } = props;
  const hasWorker = supportWorkers?.length > 0;
  const hasMoreThanOneWorker = supportWorkers?.length > 1;

  return (
    <div
      className={`bordered-bottom ${
        hasMoreThanOneWorker ? 'border-green-lighter bg-green-lightest' : 'border-orange-lighter bg-orange-lightest'
      } flex-column justify-between ${className}`}
    >
      <div
        className={`flex bordered-left bordered-right justify-between pv-small ph-medium ${
          hasWorker ? 'border-green-lighter bg-green-lightest' : 'border-orange-lighter bg-orange-lightest'
        }`}
      >
        {hasWorker ? (
          <AssignedRatioRow
            ratio={ratio}
            timezone={timezone}
            assignedWorker={supportWorkers[0]}
            onChangeTeamMember={onChangeTeamMember}
            supportWorkers={supportWorkers}
          />
        ) : (
          <UnassignedRatioRow
            ratio={ratio}
            timezone={timezone}
            onChangeTeamMember={onChangeTeamMember}
            supportWorkers={supportWorkers}
          />
        )}
      </div>

      <div
        className={`flex bordered-left bordered-right justify-between pv-small ph-medium ${
          hasMoreThanOneWorker ? 'border-green-lighter bg-green-lightest' : 'border-orange-lighter bg-orange-lightest'
        } ${isTheLastBookingRatio ? 'rounded-big-bottom' : ''}`}
      >
        {hasMoreThanOneWorker ? (
          <AssignedRatioRow
            ratio={ratio}
            timezone={timezone}
            assignedWorker={supportWorkers[1]}
            onChangeTeamMember={onChangeTeamMember}
            supportWorkers={supportWorkers}
          />
        ) : (
          <UnassignedRatioRow
            ratio={ratio}
            timezone={timezone}
            onChangeTeamMember={onChangeTeamMember}
            supportWorkers={supportWorkers}
          />
        )}
      </div>
    </div>
  );
};

export default TwoToOneBookingRatioPanel;
