import { Box, BreadcrumbItem, Breadcrumbs, Heading, Inline, Stack, Text } from '@goodhuman-me/components';
import { Announce } from 'design-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';
import { AddTeamMemberFormTable } from './team-details/AddTeamMemberFormTable';
import InfoWorkspacePermission from './team-details/components/InfoWorkspacePermission';
import TooltipInfo from './team-details/components/TooltipInfo';
import { Link, useHistory } from 'react-router-dom';

const InviteTeamMember: React.FC = () => {
  const dispatch = useDispatch<IRootDispatch>();
  const history = useHistory();

  const { ny1616 } = useFlags();

  const { portalUser } = useSelector((state: IRootState) => ({
    portalUser: state.authStore.portalUser,
  }));

  const fetchData = async () => {
    try {
      await dispatch.servicesStore.doFetchServiceDepartmentsLite({});
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!ny1616 || !PermissionUtils.validatePermission('AddNewTeamMember', portalUser.permissions.permissionRoles)) {
      history.push('/access-denied');
    } else {
      fetchData();
    }
  }, []);

  return (
    <Box position='relative' overflow='auto'>
      <Inline marginBottom='$space400' gap='$space200'>
        <Breadcrumbs>
          <BreadcrumbItem>
            <Link to='/team'>Team members</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <span>Add team members</span>
          </BreadcrumbItem>
        </Breadcrumbs>
      </Inline>
      <Stack gap='$xsmall'>
        <Heading size='xlarge'>Add team members</Heading>
        <Inline gap='$space75' alignItems='center'>
          <Text size='small' color='#6F6E6D' fontWeight='$normal'>
            You can <b>invite</b> team members now to access the workspace and business app, or add them as a{' '}
            <b>draft</b> and invite them later.
          </Text>
          <TooltipInfo
            isShow
            placement='top left'
            message={
              <InfoWorkspacePermission
                title='Inviting new team members'
                description='Team members that you invite to the workspace will receive an invitation to sign up to GoodHuman via their email and phone number. '
                linkUrl='https://goodhuman.zendesk.com/hc/en-au/articles/9734578292505-Invite-your-team-members#invite-your-team-members-0-0'
              />
            }
            content={
              <Inline alignItems='center'>
                <Announce color='$neutralDark1' width='$medium' height='$medium' />
              </Inline>
            }
          />
        </Inline>
      </Stack>
      <AddTeamMemberFormTable />
    </Box>
  );
};

export default InviteTeamMember;
