import { Box, Button, Modal, Text } from 'design-components';
import { IconWarning } from 'design-components/icon';
import React from 'react';
import { IConflictFormData } from '../shared/form-interface';

enum SpecificBundleName {
  MANAGEMENT_METHOD = 'MANAGEMENT METHOD',
  CUSTOMER_SOURCE = 'CUSTOMER SOURCE',
}

enum SpecificFieldName {
  SUPPORT_COORDINATOR = 'CHOOSE FROM TEAM MEMBERS',
  LOCAL_GOVERNMENT_AREA = 'LOCAL GOVERNMENT AREA',
  ABORIGINAL_OR_TORRES_STRAIT_ISLANDER_STATUS = 'ABORIGINAL OR TORRES STRAIT ISLANDER STATUS',
}

interface IConflictFormDataProps {
  conflict: IConflictFormData;
}

const ConflictFormDataComponent = ({ conflict }: IConflictFormDataProps) => {
  const { currentData: conflictData, newData: conflictNewData, fieldName, bundleName } = conflict;
  const currentData = typeof conflictData == 'boolean' ? (conflictData ? 'Yes' : 'No') : conflictData;
  const newData = typeof conflictNewData == 'boolean' ? (conflictNewData ? 'Yes' : 'No') : conflictNewData;
  const hasBundleName = Boolean(bundleName);
  let description: JSX.Element = null;

  if (hasBundleName) {
    switch (bundleName.toUpperCase()) {
      case SpecificBundleName.MANAGEMENT_METHOD:
        description = (
          <Text>
            The <b>{newData}</b> data hasn&apos;t been synced to customer profile because the <b>{currentData}</b> has
            already been added.
          </Text>
        );
        break;
      case SpecificBundleName.CUSTOMER_SOURCE:
        description = (
          <Text>
            The {bundleName.toLowerCase()} of <b>{newData}</b> is different to the existing of <b>{currentData}</b>
          </Text>
        );
        break;
      default:
        description = (
          <Text>
            The {bundleName.toLowerCase()} with <b>{fieldName.toLowerCase() + ' ' + newData}</b> already exist.
          </Text>
        );
        break;
    }
  } else {
    switch (fieldName.toUpperCase()) {
      case SpecificFieldName.SUPPORT_COORDINATOR:
        description = (
          <Text>
            The support coordinator data hasn&apos;t been synced to customer profile because of the limitation of
            support coordinators we can add
          </Text>
        );
        break;
      default: {
        const customFieldName = [
          SpecificFieldName.LOCAL_GOVERNMENT_AREA,
          SpecificFieldName.ABORIGINAL_OR_TORRES_STRAIT_ISLANDER_STATUS,
        ].includes(fieldName.toUpperCase() as SpecificFieldName)
          ? fieldName
          : fieldName.toLowerCase();

        description = (
          <Text>
            The {customFieldName} of <b>{newData}</b> is different to the existing of <b>{currentData}</b>
          </Text>
        );
        break;
      }
    }
  }

  return (
    <Box
      padding="16px 20px"
      marginTop={24}
      borderLeftColor="$orange"
      borderLeftStyle="$solid"
      borderLeftWidth={4}
      borderRadius={6}
      backgroundColor="$orangeLight2"
    >
      <Box display="flex" alignItems="center">
        <IconWarning fill="#B25719" />
        <Text marginLeft={6} fontWeight="$bold !important">
          {bundleName || fieldName}
        </Text>
      </Box>

      <Box marginTop={8}>{description}</Box>
    </Box>
  );
};

interface IConflictFormDataModalProps {
  isOpen: boolean;
  onClose: () => void;
  conflicts: { [formElementId: string]: IConflictFormData };
}

const ConflictFormDataModal = ({ isOpen, onClose, conflicts }: IConflictFormDataModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Text fontSize="$font100 !important" fontWeight="$bold !important">
          We found data conflicts
        </Text>
      }
      footer={
        <Box display="flex" justifyContent="flex-end" alignItems="center" padding="$xsmall">
          <Button kind="accent" emphasis="filled" size="large" onClick={onClose}>
            Done
          </Button>
        </Box>
      }
      isDismissable={false}
      bodyStyles={{ paddingTop: 8, paddingBottom: 8 }}
      width={600}
    >
      <Text>These fields have data already. Visit the customer profile to update these fields.</Text>

      {Object.keys(conflicts).map((conflict) => (
        <ConflictFormDataComponent key={conflict} conflict={conflicts[conflict]} />
      ))}
    </Modal>
  );
};
export default ConflictFormDataModal;
