import React, { Component } from 'react';
import CareInformationRow from 'views/customers/details/components/CareInformationRow';
import { ICareInfo, ICustomer } from 'interfaces/customer-interfaces';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { AlertLevel, CareInformationType } from 'utilities/enum-utils';
import DeleteCareInfoModal from './components/DeleteCareInfoModal';
import _, { isEmpty } from 'lodash';
import CreateEditDisabilityModal from '../../../disabilities/CreateEditDisabilityModal';
import CreateEditMedicationModal from '../../../medications/CreateEditMedicationModal';
import CreateEditSupportNeedModal from '../../../supportneeds/CreateEditSupportNeedModal';
import CreateEditSensitivityModal from '../../../sensitivities/CreateEditSensitivityModal';
import CreateEditBehaviourModal from '../../../behaviour/CreateEditBehaviourModal';
import AddEditPermanentConditionsModal from '../../../general/AddEditPermanentConditionsModal';

interface IAllCareInformationPanelProps {
  selectedCustomer: ICustomer;
  hasEditPermission: boolean;
  levelFilter: AlertLevel;
  careInfo: ICareInfo[];
  showAddCareInfoModal: CareInformationType;
  isActiveTab: boolean;
  onCloseModal: () => void;
}

interface IAllCareInformationPanelState {
  // filteredCareInfo: ICareInfo[];
  openedRow: string;
  showDeleteModal: boolean;
  selectedCareInfo: ICareInfo;
  openAddEditModal: CareInformationType;
}

class AllCareInformationPanel extends Component<IAllCareInformationPanelProps, IAllCareInformationPanelState> {
  state = {
    openedRow: '',
    showDeleteModal: false,
    selectedCareInfo: null,
    openAddEditModal: null,
  };

  private _filterCareInfo(careInfo, levelFilter): ICareInfo[] {
    if (levelFilter === AlertLevel.ALL) return careInfo;
    return careInfo
      .filter((info) => info.alertLevel === levelFilter)
      .sort((a: ICareInfo, b: ICareInfo) => a.careInformationType - b.careInformationType);
  }

  private _handleRowClick = (careInfoId) => {
    this.setState({ openedRow: this.state.openedRow === careInfoId ? '' : careInfoId });
  };

  private _onCloseAddEditModal = () => {
    this.setState({ openAddEditModal: null, openedRow: '' });
    this.props.onCloseModal();
  };

  private _onOpenAddEditModal = (type: CareInformationType, item?: ICareInfo) => {
    if (!_.isEmpty(item)) {
      this.setState({ openAddEditModal: type, selectedCareInfo: item });
    } else {
      this.setState({ openAddEditModal: type, selectedCareInfo: null });
    }
  };

  private _onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, selectedCareInfo: null });
  };

  private _onOpenDeleteModal = (careInfo: ICareInfo) => {
    this.setState({ showDeleteModal: true, selectedCareInfo: careInfo });
  };

  componentDidUpdate(
    prevProps: Readonly<IAllCareInformationPanelProps>,
    prevState: Readonly<IAllCareInformationPanelState>,
  ) {
    if (prevProps.showAddCareInfoModal !== this.props.showAddCareInfoModal) {
      if (this.props.showAddCareInfoModal) {
        this._onOpenAddEditModal(this.props.showAddCareInfoModal);
      } else {
        this._onCloseAddEditModal();
      }
    }
  }

  render() {
    const { careInfo, levelFilter, hasEditPermission, selectedCustomer, isActiveTab } = this.props;
    const { openedRow, showDeleteModal, selectedCareInfo, openAddEditModal } = this.state;
    const filteredCareInfo = this._filterCareInfo(careInfo, levelFilter);
    return (
      <div className="mb-medium">
        {isActiveTab && (
          <>
            <DeleteCareInfoModal
              isOpen={showDeleteModal}
              selectedCareInfo={selectedCareInfo}
              onClose={this._onCloseDeleteModal}
            />
            <AddEditPermanentConditionsModal
              isOpen={openAddEditModal === CareInformationType.PERMANENT}
              onClose={this._onCloseAddEditModal}
              selectedCustomer={selectedCustomer}
              selectedCareInfo={selectedCareInfo}
              isNew={isEmpty(selectedCareInfo)}
            />
            {/* <CreateEditDisabilityModal
              closeCreateEditDisabilityModal={this._onCloseAddEditModal}
              selectedCustomer={selectedCustomer}
              isOpen={openAddEditModal === CareInformationType.PERMANENT}
              selectedDisability={selectedCareInfo}
              isNew={_.isEmpty(selectedCareInfo)}
            /> */}
            <CreateEditMedicationModal
              closeCreateEditMedicationModal={this._onCloseAddEditModal}
              selectedCustomer={selectedCustomer}
              isOpen={openAddEditModal === CareInformationType.MEDICATION}
              selectedMedication={selectedCareInfo}
              isNew={_.isEmpty(selectedCareInfo)}
            />
            <CreateEditSupportNeedModal
              closeCreateEditSupportNeedModal={this._onCloseAddEditModal}
              selectedCustomer={selectedCustomer}
              isOpen={openAddEditModal === CareInformationType.SUPPORTNEEDS}
              selectedSupportNeed={selectedCareInfo}
              isNew={_.isEmpty(selectedCareInfo)}
            />
            <CreateEditSensitivityModal
              closeCreateEditSensitivityModal={this._onCloseAddEditModal}
              selectedCustomer={selectedCustomer}
              isOpen={openAddEditModal === CareInformationType.GENERAL}
              selectedSensitivity={selectedCareInfo}
              isNew={_.isEmpty(selectedCareInfo)}
            />
            <CreateEditBehaviourModal
              closeCreateEditBehaviourModal={this._onCloseAddEditModal}
              selectedCustomer={selectedCustomer}
              isOpen={openAddEditModal === CareInformationType.BEHAVIOUR}
              selectedBehaviour={selectedCareInfo}
              isNew={_.isEmpty(selectedCareInfo)}
            />
          </>
        )}
        {filteredCareInfo.length > 0 ? (
          _.map(filteredCareInfo, (careInfo) => {
            return (
              <CareInformationRow
                key={careInfo.careInformationId}
                openedRow={openedRow}
                careInformation={careInfo}
                handleRowSlider={this._handleRowClick}
                openDeleteItemModal={() => this._onOpenDeleteModal(careInfo)}
                openCreateItemEditModal={this._onOpenAddEditModal}
                hasEditPermission={hasEditPermission}
              />
            );
          })
        ) : (
          <div className="mt-x7-large">No medical information available.</div>
        )}
      </div>
    );
  }
}

export default AllCareInformationPanel;
