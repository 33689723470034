import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Shield = forwardRef(function Shield(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.022c-.558 0-1.105.193-2.546.734l-.868.325c-1.726.647-2.376.907-2.83 1.34a3.25 3.25 0 0 0-.745 1.076c-.246.577-.26 1.276-.26 3.12v1.35c0 1.583.002 2.278.12 2.915a7.25 7.25 0 0 0 2.235 4.033c.477.436 1.065.808 2.407 1.646.766.479 1.06.658 1.35.767a3.25 3.25 0 0 0 2.274 0c.29-.109.584-.288 1.35-.767 1.342-.838 1.93-1.21 2.407-1.646a7.25 7.25 0 0 0 2.236-4.033c.117-.637.12-1.332.12-2.915v-1.35c0-1.844-.014-2.543-.26-3.12a3.249 3.249 0 0 0-.746-1.076c-.454-.433-1.104-.693-2.83-1.34l-.868-.325c-1.44-.54-1.988-.734-2.546-.734Zm-2.94-.72c1.262-.474 2.077-.78 2.94-.78s1.679.306 2.94.78c.044.016.088.032.132.05l.87.325.163.061c1.503.563 2.47.926 3.174 1.597.466.445.837.98 1.09 1.573.382.895.382 1.928.381 3.533v1.614c0 1.47 0 2.311-.145 3.099a8.75 8.75 0 0 1-2.698 4.867c-.59.541-1.304.986-2.55 1.766l-.075.046-.072.045c-.67.419-1.098.687-1.549.855-1.071.4-2.25.4-3.322 0-.45-.168-.88-.436-1.548-.855l-.073-.045-.074-.046c-1.247-.78-1.96-1.225-2.55-1.766a8.75 8.75 0 0 1-2.699-4.867c-.145-.788-.145-1.629-.145-3.1V9.442c0-1.605 0-2.638.381-3.533a4.75 4.75 0 0 1 1.09-1.573c.704-.671 1.671-1.034 3.174-1.597l.164-.061.869-.326.132-.05Z"
        fill="currentColor"
      />
    </Icon>
  );
});
