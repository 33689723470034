import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import _ from 'lodash';
import { TeamMemberTimezoneIndicator } from 'common-components/timezone';
import SwitchCycleModal from '../SwitchCycleModal';
import { Spinner } from '@blueprintjs/core';

interface Timezone {
  type: 'PREFERRED' | 'CUSTOM';
  value: string;
}
interface IGeneralAvailabilityProps {
  hasEditPermission: boolean;
  availableTimes: any[];
  onEdit?: () => void;
  timezone: Timezone;
  cycle: string;
  activeWeek: number;
  activeDate: any;
  isLoading: boolean;
}

interface IGeneralAvailabilityState {
  showSwitchCycleModal: boolean;
}

class GeneralAvailability extends Component<IGeneralAvailabilityProps, IGeneralAvailabilityState> {
  state = {
    showSwitchCycleModal: false,
  };

  private _isNoon = (time) => {
    const timeString = moment(time).format('HH:mm a');
    const noonTime = moment(12, 'hh').format('HH:mm a');
    return timeString === noonTime;
  };

  private _renderAbilityItem = (availableTime, isActive?: boolean) => {
    return (
      availableTime && (
        <div className="pv-large">
          {availableTime.isAvailableAllDay ? (
            <Text className="text-color-green text-size-regular">Available 24 hours</Text>
          ) : availableTime.timeRange.length > 0 ? (
            _.map(availableTime.timeRange, (time, index) => (
              <Text
                className="block mb-x-small text-size-regular"
                key={`${availableTime.day}-${index + 1}`}
                color={isActive ? 'secondary' : 'primary'}
              >
                {moment(time.startDateTime).format('h:mm a')} - {moment(time.endDateTime).format('h:mm a')}{' '}
                {this._isNoon(time.endDateTime) && (
                  <Text className="text-color-secondary text-size-regular">(noon)</Text>
                )}
              </Text>
            ))
          ) : (
            <Text className="text-color-orange-dark text-size-regular">Unavailable</Text>
          )}
        </div>
      )
    );
  };

  private _renderWeekly = (availableTimes) => {
    return availableTimes.map((availableTime, index) => (
      <Row className="bordered-bottom" key={index}>
        <Col span={8}>
          <div className="pv-large pl-medium">
            <Text className="text-size-regular">{availableTime.day}</Text>
          </div>
        </Col>
        <Col span={14}>{this._renderAbilityItem(availableTime)}</Col>
      </Row>
    ));
  };

  private _activeWeekBorder = (position, isActive) => {
    switch (position) {
      case 'top':
        return isActive ? 'bordered-left bordered-right bordered-top border-blue-action rounded-big-top bg-white' : '';
      case 'middle':
        return isActive ? ' bordered-left bordered-right border-blue-action' : 'bg-quaternary';
      case 'bottom':
        return isActive
          ? 'bordered-left bordered-right bordered-bottom border-blue-action rounded-big-bottom bg-white'
          : '';
      default:
        return '';
    }
  };

  private _renderActiveDate = (isActive) => {
    const { activeDate } = this.props;

    return (
      // isActive && <Text className="text-size-regular">Week starting: {moment(activeDate).format('DD MMM YYYY')}</Text>
      // Change request by KMS-1033
      isActive && (
        <Text className="text-size-regular">Week starting: {moment().startOf('isoweek').format('DD MMM YYYY')}</Text>
      )
    );
  };

  private _renderFortnight = (availableTimes) => {
    const { activeWeek } = this.props;

    const weekOne = _.filter(availableTimes, (item) => item.week === 1);
    const weekTwo = _.filter(availableTimes, (item) => item.week === 2);

    return (
      <div>
        <Row className="bordered-bottom" gutter={[20, 0]}>
          <Col span={6} className="bg-quaternary">
            <div className="pv-large pl-medium"></div>
          </Col>
          <Col span={9} className={this._activeWeekBorder('top', activeWeek === 1)}>
            <div className="flex-column pv-large">
              <Text className="text-size-large" weight="bold">
                Week 1
              </Text>
              {this._renderActiveDate(activeWeek === 1)}
            </div>
          </Col>
          <Col span={9} className={this._activeWeekBorder('top', activeWeek === 2)}>
            <div className="flex-column pv-large">
              <Text className="text-size-large" weight="bold">
                Week 2
              </Text>
              {this._renderActiveDate(activeWeek === 2)}
            </div>
          </Col>
        </Row>
        {weekOne.map((availableTime, index) => (
          <Row className="bordered-bottom evenodd" key={index} gutter={[20, 0]} type="flex">
            <Col span={6} className="bg-quaternary">
              <div className="pv-large pl-medium">
                <Text className="text-size-regular">{availableTime.day}</Text>
              </div>
            </Col>
            <Col span={9} className={this._activeWeekBorder('middle', activeWeek === 1)}>
              {this._renderAbilityItem(availableTime, activeWeek === 1)}
            </Col>
            <Col span={9} className={this._activeWeekBorder('middle', activeWeek === 2)}>
              {this._renderAbilityItem(weekTwo[index], activeWeek === 2)}
            </Col>
          </Row>
        ))}
        <Row gutter={[20, 0]}>
          <Col span={6} className="bg-quaternary">
            <div className="pv-large pl-medium"></div>
          </Col>
          <Col span={9} style={{ height: '15px' }} className={this._activeWeekBorder('bottom', activeWeek === 1)}></Col>
          <Col span={9} style={{ height: '15px' }} className={this._activeWeekBorder('bottom', activeWeek === 2)}></Col>
        </Row>
      </div>
    );
  };

  private _onSwitchCycle = () => {
    this.setState({ showSwitchCycleModal: true });
  };

  render() {
    const { hasEditPermission, availableTimes, onEdit, timezone, cycle, isLoading } = this.props;

    return (
      <div>
        <SwitchCycleModal
          isOpen={this.state.showSwitchCycleModal}
          onClose={() => {
            this.setState({ showSwitchCycleModal: false });
          }}
          type={cycle === 'weekly' ? 'fortnightly' : 'weekly'}
          availabilities={availableTimes}
          timezone={timezone}
        />
        <Title level={4} className="m-none mb-x-small">
          General availability
        </Title>
        <Text type="secondary" className="text-size-regular">
          View and edit a team member's general availability across a fortnight.
        </Text>
        {isLoading ? (
          <Spinner size={80} />
        ) : (
          <div className="bg-quaternary p-medium mt-large">
            <div className="flex justify-between mb-large">
              <div className="flex">
                <div className="text-color-secondary text-size-small">CYCLE</div>
                <div className="flex-column ml-medium">
                  <Text weight="bold" className="text-size-regular">
                    {cycle === 'weekly' ? 'Weekly' : 'Fortnightly'}
                  </Text>
                  {hasEditPermission && (
                    <div className="flex">
                      {/* For Availability enhancement : Temporarily hide switch */}
                      {/* <div
                        className="text-color-blue-action cursor-pointer text-size-regular"
                        onClick={this._onSwitchCycle}
                      >
                        {cycle === 'weekly' ? 'Switch to fortnightly' : 'Switch to weekly'}
                      </div> */}
                      <div className="text-color-blue-action ml-12 cursor-pointer text-size-regular" onClick={onEdit}>
                        Edit
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <TeamMemberTimezoneIndicator type={timezone.type} timezone={timezone.value} />
            </div>
            {cycle === 'weekly' ? this._renderWeekly(availableTimes) : this._renderFortnight(availableTimes)}
          </div>
        )}
      </div>
    );
  }
}

export default GeneralAvailability;
