import { tv, type VariantProps } from 'tailwind-variants';

export const calendar = tv(
  {
    slots: {
      container: ['flex', 'w-fit', 'flex-col', 'gap-4', 'rounded-md', 'bg-base', 'p-4', 'isolate'],
      header: ['mx-1.5', 'flex', 'flex-row', 'items-center', 'justify-between'],
      heading: ['font-base', 'text-b-sm', 'font-bold', 'leading-b-md'],
      gridContainer: ['flex', 'flex-row', 'items-start', 'gap-4'],
      grid: ['group', 'table-auto', 'border-collapse'],
      gridHeaderCell: ['font-base', 'text-b-xs', 'font-bold', 'leading-b-xs', 'text-weak'],
      gridBodyCell: [
        'group',
        'relative',
        'inline-flex',
        'h-9',
        'w-9',
        'flex-col',
        'items-center',
        'justify-center',
        'rounded-full',
        'p-0',
        'outline-none',
        'data-[disabled=true]:cursor-default',
        'data-[focus-visible=true]:bg-neutral-weak',
        'data-[hovered=true]:bg-neutral-weak',
        'data-[outside-month=true]:hidden',
        'data-[pressed=true]:bg-gray-light-1',
        'data-[unavailable=true]:cursor-default',
        'data-[unavailable=true]:data-[hovered=true]:bg-base',

        // unselected focus
        'data-[focus-visible=true]:after:absolute',
        'data-[focus-visible=true]:after:z-20',
        'data-[focus-visible=true]:after:h-9',
        'data-[focus-visible=true]:after:w-9',
        'data-[focus-visible=true]:after:rounded-full',
        'data-[focus-visible=true]:after:ring-2',
        'data-[focus-visible=true]:after:ring-accent',
        'data-[focus-visible=true]:after:content-[""]',
      ],
      cellText: [
        'z-40',
        'text-b-sm',
        'text-base',
        'leading-b-sm',
        'group-data-[selected=true]:font-bold',
        'group-data-[disabled=true]:text-neutral-weak',
        'group-data-[unavailable=true]:line-through',
        'group-data-[unavailable=true]:text-neutral-weak',
        'relative',
        'font-base',
        'font-normal',
      ],
      todayMarker: ['absolute', 'bottom-1.5', 'left-[5px]', 'top-5', 'z-40', 'h-1', 'w-1', 'rounded-full', 'bg-accent'],
    },
    variants: {
      selectionMode: {
        single: {
          gridBodyCell: [
            // selected
            'data-[selected=true]:bg-accent',
            'data-[selected=true]:data-[hovered=true]:bg-ocean-dark-1',
            'data-[selected=true]:data-[pressed=true]:data-[hovered=true]:bg-ocean-dark-2',
            'data-[selected=true]:data-[focus-visible=true]:bg-ocean-dark-1',
            // invalid
            'data-[selected=true]:aria-invalid:bg-critical',
            'data-[selected=true]:data-[hovered=true]:aria-invalid:bg-red-dark-1',
            'data-[selected=true]:data-[pressed=true]:aria-invalid:bg-red-dark-2',

            // selected focus
            'data-[focus-visible=true]:data-[selected=true]:bg-ocean-dark-1',
            'data-[focus-visible=true]:data-[selected=true]:after:ring-2',
            'data-[focus-visible=true]:data-[selected=true]:after:ring-offset-2',
            //invalid
            'data-[focus-visible=true]:data-[selected=true]:aria-invalid:bg-red-dark-1',
          ],
          cellText: ['group-data-[selected=true]:text-on-background'],
          todayMarker: ['group-data-[selected=true]:bg-base'],
        },
        range: {
          gridBodyCell: [
            // selection start indicator
            'data-[selection-start=true]:rounded-l-full',
            'data-[selection-start=true]:after:content-[""]',
            'data-[selection-start=true]:after:absolute',
            'data-[selection-start=true]:after:bg-accent',
            'data-[selection-start=true]:after:w-8',
            'data-[selection-start=true]:after:h-8',
            'data-[selection-start=true]:after:rounded-full',
            'data-[selection-start=true]:after:z-20',
            'data-[selection-start=true]:data-[hovered=true]:after:bg-ocean-dark-1',
            'data-[selection-start=true]:data-[pressed=true]:after:bg-ocean-dark-2',

            // selection start focus
            'data-[selected=true]:data-[selection-start=true]:data-[focus-visible=true]:before:content-[""]',
            'data-[selected=true]:data-[selection-start=true]:data-[focus-visible=true]:before:w-8',
            'data-[selected=true]:data-[selection-start=true]:data-[focus-visible=true]:before:h-8',
            'data-[selected=true]:data-[selection-start=true]:data-[focus-visible=true]:before:absolute',
            'data-[selected=true]:data-[selection-start=true]:data-[focus-visible=true]:before:ring-2',
            'data-[selected=true]:data-[selection-start=true]:data-[focus-visible=true]:before:ring-offset-2',
            'data-[selected=true]:data-[selection-start=true]:data-[focus-visible=true]:before:ring-accent',
            'data-[selected=true]:data-[selection-start=true]:data-[focus-visible=true]:before:z-30',
            'data-[selected=true]:data-[selection-start=true]:data-[focus-visible=true]:before:bg-transparent',
            'data-[selected=true]:data-[selection-start=true]:data-[focus-visible=true]:before:rounded-full',
            'data-[selected=true]:data-[selection-start=true]:data-[focus-visible=true]:after:bg-ocean-dark-2',

            // selection end indicator
            'data-[selection-end=true]:rounded-r-full',
            'data-[selection-end=true]:after:content-[""]',
            'data-[selection-end=true]:after:absolute',
            'data-[selection-end=true]:after:bg-accent',
            'data-[selection-end=true]:after:w-8',
            'data-[selection-end=true]:after:h-8',
            'data-[selection-end=true]:after:rounded-full',
            'data-[selection-end=true]:after:z-20',
            'data-[selection-end=true]:data-[hovered=true]:after:bg-ocean-dark-1',
            'data-[selection-end=true]:data-[pressed=true]:after:bg-ocean-dark-2',

            // selection end focus
            'data-[selected=true]:data-[selection-end=true]:data-[focus-visible=true]:before:content-[""]',
            'data-[selected=true]:data-[selection-end=true]:data-[focus-visible=true]:before:w-8',
            'data-[selected=true]:data-[selection-end=true]:data-[focus-visible=true]:before:h-8',
            'data-[selected=true]:data-[selection-end=true]:data-[focus-visible=true]:before:absolute',
            'data-[selected=true]:data-[selection-end=true]:data-[focus-visible=true]:before:ring-2',
            'data-[selected=true]:data-[selection-end=true]:data-[focus-visible=true]:before:ring-offset-2',
            'data-[selected=true]:data-[selection-end=true]:data-[focus-visible=true]:before:ring-accent',
            'data-[selected=true]:data-[selection-end=true]:data-[focus-visible=true]:before:z-30',
            'data-[selected=true]:data-[selection-end=true]:data-[focus-visible=true]:before:bg-transparent',
            'data-[selected=true]:data-[selection-end=true]:data-[focus-visible=true]:before:rounded-full',
            'data-[selected=true]:data-[selection-end=true]:data-[focus-visible=true]:after:bg-ocean-dark-2',

            // selected range
            'data-[selected=true]:bg-accent-weak',
            'data-[selected=true]:rounded-none',

            // selected range focus
            'data-[selected=true]:data-[focus-visible=true]:after:content-[""]',
            'data-[selected=true]:data-[focus-visible=true]:after:w-8',
            'data-[selected=true]:data-[focus-visible=true]:after:h-8',
            'data-[selected=true]:data-[focus-visible=true]:after:absolute',
            'data-[selected=true]:data-[focus-visible=true]:after:ring-2',
            'data-[selected=true]:data-[focus-visible=true]:after:ring-accent',
            'data-[selected=true]:data-[focus-visible=true]:after:rounded-full',
          ],
          cellText: [
            'group-data-[selection-start=true]:text-on-background',
            'group-data-[selection-end=true]:text-on-background',
          ],
          todayMarker: [
            'group-data-[selected=true]:group-data-[selection-start=false]:bg-accent',
            'group-data-[selection-start=true]:bg-base',
            'group-data-[selection-end=true]:bg-base',
          ],
        },
      },
    },
    defaultVariants: {
      selectionMode: 'single',
    },
  },
  { twMerge: false },
);

type Variants = Required<VariantProps<typeof calendar>>;

export type CalendarVariants = {
  /**
   * Change the style of gridBodyCell.
   * @default single
   */
  selectionMode?: Variants['selectionMode'];
};
