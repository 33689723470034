import React, { PropsWithChildren, createContext, useContext } from 'react';

import { Service, reactTrpc } from 'utilities/react-trpc';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';

export type SilServiceContextUnresolved = PropsWithChildren<{
  service: Service | undefined | null;
  isError: boolean;
  isLoading: boolean;
  refetch: () => void;
  isFetching: boolean;
}>;

export type SilServiceContextResolved = PropsWithChildren<{
  service: Service;
  isError: false;
  isLoading: false;
  refetch: () => void;
  isFetching: boolean;
}>;

type SilServiceContext = SilServiceContextResolved | SilServiceContextUnresolved;

const SilServiceContext = createContext<SilServiceContext>({} as SilServiceContext);
export const useSilServiceContext = () => useContext(SilServiceContext);

type SilServiceProviderProps = PropsWithChildren<{ serviceId: string }>;

export const SilServiceProvider = ({ children, serviceId }: SilServiceProviderProps) => {
  const portalUser = usePortalUser();
  const {
    data: service,
    isLoading,
    isError,
    refetch,
    isFetching,
  } = reactTrpc.services.service.fetch.useQuery({
    serviceProviderId: portalUser?.serviceProviderId ?? '',
    serviceId,
  });

  return (
    <SilServiceContext.Provider
      value={{
        service,
        isError,
        isLoading,
        refetch,
        isFetching,
      }}
    >
      {children}
    </SilServiceContext.Provider>
  );
};
