import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const SlidersVert = forwardRef(function SlidersVert(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 6.354a2.751 2.751 0 1 0 1.5 0V3a.75.75 0 0 0-1.5 0v3.354ZM5 7.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM5.75 14a.75.75 0 0 0-1.5 0v7a.75.75 0 0 0 1.5 0v-7Zm14-11a.75.75 0 0 0-1.5 0v3.354a2.751 2.751 0 1 0 1.5 0V3Zm0 11a.75.75 0 0 0-1.5 0v7a.75.75 0 0 0 1.5 0v-7ZM12 2.25a.75.75 0 0 1 .75.75v7a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75Zm-.75 15.396V21a.75.75 0 0 0 1.5 0v-3.354a2.751 2.751 0 1 0-1.5 0ZM19 7.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM10.75 15a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
