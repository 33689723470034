import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Mouse = forwardRef(function Mouse(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25A7.75 7.75 0 0 0 4.25 10v4a7.75 7.75 0 0 0 15.5 0v-4A7.75 7.75 0 0 0 12 2.25ZM5.75 10a6.25 6.25 0 0 1 12.5 0v4a6.25 6.25 0 0 1-12.5 0v-4Zm7-2a.75.75 0 0 0-1.5 0v2a.75.75 0 1 0 1.5 0V8Z"
        fill="currentColor"
      />
    </Icon>
  );
});
