import { Children, forwardRef, isValidElement } from 'react';
import { filterDOMProps } from '@react-aria/utils';

import * as styles from './illustrated-message.css';
import { Heading, HeadingProvider } from '../heading';
import { Text, TextProvider } from '../text';
import { twMerge } from '../utils';

import type { HTMLProps } from '../types';
import type { PropsWithChildren, ReactNode } from 'react';

export type IllustratedMessageProps = PropsWithChildren<HTMLProps<HTMLDivElement>>;

export const IllustratedMessage = forwardRef<HTMLDivElement, IllustratedMessageProps>(function IllustratedMessage(
  props,
  ref,
) {
  const { className, ...otherProps } = props;
  let heading;
  let text;
  const other: ReactNode[] = [];

  Children.forEach(props.children, (child) => {
    if (!isValidElement(child)) return;
    if (child.type === Heading) heading = child;
    else if (child.type === Text) text = child;
    else other.push(child);
  });

  const { container, content } = styles.illustratedMessage();

  return (
    <div {...filterDOMProps(otherProps)} className={twMerge(container(), className)} ref={ref}>
      {other}

      {!!(heading || text) && (
        <div className={content()}>
          <HeadingProvider size="sm">{heading}</HeadingProvider>
          <TextProvider size="sm">{text}</TextProvider>
        </div>
      )}
    </div>
  );
});
