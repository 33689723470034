import { Label } from '@good/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PeriodStatus } from 'views/sil-service/tabs/schedule/components/roster-period-status';

const STATUS_TO_LABEL_PROPS = {
  active: { tone: 'positive', emphasis: 'strong' },
  archive: { tone: 'blue', emphasis: 'weak' },
  pending: { tone: 'info', emphasis: 'strong' },
} as const;

export const RosterPeriodStatus = ({ status }: { status: PeriodStatus }) => {
  const { t } = useTranslation('', { keyPrefix: 'rostering.rosterPeriod' });

  return (
    <Label {...STATUS_TO_LABEL_PROPS[status]} size="md">
      {t('label', { statusOption: t(`statusOptions.${status}`) })}
    </Label>
  );
};
