import React, { Component } from 'react';
import { Paragraph, Title } from 'common-components/typography';
import { Form, Icon } from 'antd';
import { PrimaryButton } from 'common-components/buttons';
import { IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { SecondaryButton } from 'common-components/buttons';
import PermissionUtils from 'utilities/permission-utils';

interface IConfirmationStepPanelProps {
  onPreviousStep: () => void;
  goToNewService: () => void;
  restartServiceCreation: () => void;
  newService: typeof state.servicesStore.newService;
  portalUser: typeof state.authStore.portalUser;
}

class ConfirmationStepPanel extends Component<IConfirmationStepPanelProps> {
  state = { isLoading: true };

  render() {
    const { newService, goToNewService, restartServiceCreation, portalUser } = this.props;

    return (
      <div>
        <div className="anim-slide-left">
          <Title level={2} weight="bolder" className="line-height-100 mb-large" color="blue-action">
            <Icon type="check" className="mr-small text-color-green-dark" />
            Service <span className="text-weight-regular">successfully created</span>
          </Title>

          <Paragraph>Boom!</Paragraph>
          <Paragraph>
            Your service has been published. All you have to do now is put your feet up and wait for the bookings to
            roll in!
          </Paragraph>

          <div className="flex-row justify-start mt-large">
            <SecondaryButton size="large" onClick={restartServiceCreation} icon="left" className="mr-medium">
              Create another Service
            </SecondaryButton>

            {PermissionUtils.validatePermission('ViewTeamMemberProfile', portalUser.permissions.permissionRoles) && (
              <PrimaryButton size="large" onClick={goToNewService} icon="close">
                Go to the Service
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

export default connect(mapState, null)(connect()(ConfirmationStepPanel));
