import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import NDISLineItemGrid from 'common-components/line-items/NDISLineItemGrid';
import { EditRecurringMode, MmmGroup } from 'utilities/enum-utils';
import _ from 'lodash';
import { Paragraph, Text } from 'common-components/typography';
import { Col, Radio, Row } from 'antd';
import { state } from 'stores/rematch/root-store';
import moment from 'moment-timezone';
interface ISelectLineItemToChargeModalProps {
  onClose: any;
  isOpen: boolean;
  billingPeriods?: Array<any>;
  locationMmmGroup?: MmmGroup;
  locationState?: string;
  getServiceAgreementTitle?: (billingPeriod) => any;
  onSaveSelection: (selectedLineItems, selectedOption) => void;
  displayRecurringModeStep?: boolean;
  paymentSourceType: string;
  bookingTimezone?: string;
  recurringBookingList?: typeof state.bookingsStore.recurringBookingList;
}

interface ISelectLineItemToChargeModalState {
  selectedLineItems: any;
  isRecurringModeStep: boolean;
  isConfirmationModalOpen: boolean;
  selectedOption: any;
}

class SelectLineItemToChargeModal extends Component<
  ISelectLineItemToChargeModalProps,
  ISelectLineItemToChargeModalState
> {
  state = {
    selectedLineItems: [],
    isRecurringModeStep: false,
    isConfirmationModalOpen: false,
    selectedOption: EditRecurringMode.Current,
  };

  private _onClose = () => {
    this.props.onClose();
    this.setState({ isConfirmationModalOpen: false, isRecurringModeStep: false });
  };

  private _onSaveSelection = () => {
    const { recurringBookingList, bookingTimezone } = this.props;
    const selectedBillingItems = this.props.displayRecurringModeStep
      ? _.map(this.state.selectedLineItems, (selectedLineItem) => {
          return {
            ...selectedLineItem,
            startDateTime: moment.tz(recurringBookingList[0].startDateTime, bookingTimezone).startOf('day').toDate(),
            endDateTime: moment
              .tz(recurringBookingList.slice(-1)[0].endDateTime, bookingTimezone)
              .endOf('day')
              .toDate(),
          };
        })
      : this.state.selectedLineItems;
    this.props.onSaveSelection(selectedBillingItems, this.state.selectedOption);
    this._onClose();
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _displayRecurringModeMenu = () => {
    this.setState({ isRecurringModeStep: true });
  };
  private _openConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: true });
  };

  private _closeConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  private _onClickLineItemCheckbox = (lineItems, billingPeriod) => {
    const { selectedLineItems } = this.state;
    let newSelectedLineItem = !_.isEmpty(selectedLineItems) ? _.clone(selectedLineItems) : [];

    if (
      !_.find(selectedLineItems, (existingLineItem) => existingLineItem.startDateTime === billingPeriod.startDateTime)
    ) {
      newSelectedLineItem.push({
        endDateTime: billingPeriod.endDateTime,
        startDateTime: billingPeriod.startDateTime,
        selectedLineItems: lineItems,
      });
    } else if (_.isEmpty(lineItems)) {
      newSelectedLineItem = _.filter(
        selectedLineItems,
        (period) => period.startDateTime !== billingPeriod.startDateTime,
      );
    } else {
      newSelectedLineItem = _.map(selectedLineItems, (period) => {
        if (period.startDateTime === billingPeriod.startDateTime) {
          return { ...period, selectedLineItems: lineItems };
        } else {
          return { ...period };
        }
      });
    }
    this.setState({ selectedLineItems: newSelectedLineItem });
  };

  render() {
    const {
      isOpen,
      billingPeriods,
      locationMmmGroup,
      locationState,
      getServiceAgreementTitle,
      displayRecurringModeStep = false,
    } = this.props;
    const { selectedLineItems, isRecurringModeStep, isConfirmationModalOpen } = this.state;

    return (
      <ActionModal
        isOpen={isOpen}
        onClose={this._onClose}
        width={'x3-large'}
        title={isRecurringModeStep ? 'Edit billing' : 'Select which line items you wish to charge for this booking'}
      >
        <ActionModal
          isOpen={isConfirmationModalOpen}
          onClose={this._closeConfirmationModal}
          title={'Changing line items'}
          canCloseOutside={false}
          width={'medium'}
        >
          <div>
            <Paragraph>
              Editing the line you wish to charge for the selected bookings will replace all automatically generated
              line items in the bookings you have elected to edit.
            </Paragraph>
            <Paragraph>Any manually added line items will not be affected.</Paragraph>
            <div className={'mb-small mt-x2-large'}>
              <Row type={'flex'} className={'justify-end'}>
                <Col className="mr-large">
                  <GhostButton size="large" onClick={this._closeConfirmationModal}>
                    Cancel
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton size="large" onClick={this._onSaveSelection}>
                    Save
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
        {!isRecurringModeStep ? (
          <>
            <Paragraph>
              We will automatically calculate the exact value for the line items based on the details of the booking.
            </Paragraph>
            <div className={'mt-large'} style={{ maxHeight: 'calc(60vh)', overflow: 'auto' }}>
              {_.map(billingPeriods, (billingPeriod, index) => {
                const periodSelectedLineItems = _.find(
                  selectedLineItems,
                  (period) => period.startDateTime === billingPeriod.startDateTime,
                );
                return (
                  <>
                    <div className={'mb-small'}>{getServiceAgreementTitle(billingPeriod)}</div>
                    <div className={'mb-x2-large pt-medium bordered rounded-big shadow-container'} key={index}>
                      <NDISLineItemGrid
                        lineItems={
                          !_.isEmpty(billingPeriod.serviceAgreementBillingItems)
                            ? billingPeriod.serviceAgreementBillingItems
                            : !_.isEmpty(billingPeriod.serviceBillingItems)
                            ? billingPeriod.serviceBillingItems
                            : null
                        }
                        displayMode={'VIEW'}
                        isSelectable={true}
                        selectedLineItems={periodSelectedLineItems ? periodSelectedLineItems.selectedLineItems : []}
                        isServiceAgreementLineItems={!_.isEmpty(billingPeriod.serviceAgreementBillingItems)}
                        onClickLineItemCheckbox={(lineItems) => this._onClickLineItemCheckbox(lineItems, billingPeriod)}
                        onSequenceChange={() => false}
                        mmmGroup={locationMmmGroup}
                        state={locationState}
                        noMargin={true}
                        paymentSourceType={this.props.paymentSourceType}
                      />
                    </div>
                  </>
                );
              })}
            </div>
            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                className="mr-medium"
                disabled={_.isEmpty(selectedLineItems)}
                onClick={displayRecurringModeStep ? this._displayRecurringModeMenu : this._onSaveSelection}
              >
                Save selection
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : (
          <>
            <div className="anim-slide-left">
              <Paragraph>
                The selected booking is part of a recurring booking series. Please select one of the following options
                for editing this booking
              </Paragraph>
            </div>
            <div>
              <div className="mb-small">
                <Text weight="bold">Edit billing for:</Text>
              </div>
              <Radio.Group
                value={this.state.selectedOption}
                onChange={this._onChangeOption}
                className="ml-medium flex-wrap"
              >
                <Radio
                  value={EditRecurringMode.Current}
                  className={`${
                    this.state.selectedOption === EditRecurringMode.Current && 'text-weight-bold'
                  } mb-small `}
                >
                  <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                    This booking only.
                  </div>
                </Radio>
                {/* KMS-424 : Temporary hide option all upcoming  */}
                {/* <br />
                <Radio
                  value={EditRecurringMode.Upcoming}
                  className={`${this.state.selectedOption === EditRecurringMode.Upcoming &&
                    'text-weight-bold'} mb-small `}
                >
                  <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                    This booking and all upcoming bookings.
                  </div>
                </Radio> */}
                <br />
                <Radio
                  value={EditRecurringMode.CurrentAll}
                  className={`${
                    this.state.selectedOption === EditRecurringMode.CurrentAll && 'text-weight-bold'
                  } mb-small `}
                >
                  <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                    This booking and all following bookings.
                  </div>
                </Radio>
              </Radio.Group>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._openConfirmationModal}>
                  Continue
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

export default SelectLineItemToChargeModal;
