import CommonUtils from 'utilities/common-utils';
import { minutesBetween, AnyDateType } from 'utilities/date-utils';

export function getDurationAndUnit(startDateTime: AnyDateType, endDateTime: AnyDateType) {
  const duration = minutesBetween(startDateTime, endDateTime);
  return convertDurationAndUnit(duration);
}

export function convertDurationAndUnit(duration: number) {
  let durationUnit: 'minutes' | 'hours' = 'minutes';

  if (duration >= 60) {
    const durationHours = duration / 60;
    const numberOfDecimals = CommonUtils.getNumberOfDecimals(durationHours);

    if (numberOfDecimals <= 2) {
      duration = durationHours;
      durationUnit = 'hours';
    }
  }

  return { duration, durationUnit };
}
