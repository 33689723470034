import { extendTailwindMerge } from 'tailwind-merge';
import { mergeProps } from '@react-aria/utils';
import { useContext } from 'react';

import type { Context } from 'react';

/**
 * @name useContextProps
 *
 * @description
 * Merge props with incoming context.
 *
 * @example
 * props = useContextProps<ButtonProviderProps>(ButtonContext, props)
 */
export function useContextProps<T>(context: Context<T>, props: Record<string, unknown>) {
  const contextProps = useContext<T>(context) ?? ({} as Context<T>);
  return mergeProps(contextProps, props);
}

export const twMerge = extendTailwindMerge({
  classGroups: {
    borderColor: [
      'base',
      'accent',
      'critical',
      'critical-weak',
      'info',
      'info-weak',
      'neutral',
      'neutral-weak',
      'notice',
      'notice-weak',
      'positive',
      'positive-weak',
      'blue',
      'canary',
      'lilac',
    ],
    color: [
      'text-strong',
      'text-base',
      'text-weak',
      'text-on-background',
      'text-inherit',
      'text-accent',
      'text-accent-strong',
      'text-critical',
      'text-critical-strong',
      'text-info',
      'text-info-strong',
      'text-neutral',
      'text-neutral-strong',
      'text-positive',
      'text-positive-strong',
      'text-blue',
      'text-blue-strong',
      'text-canary',
      'text-canary-strong',
      'text-lilac',
      'text-lilac-strong',
    ],
    fontSize: [
      'text-b-xl',
      'text-h-xl',
      'text-b-lg',
      'text-h-lg',
      'text-b-md',
      'text-h-md',
      'text-b-sm',
      'text-h-sm',
      'text-b-xs',
      'text-h-xs',
      'text-b-2xs',
      'text-h-2xs',
    ],
    lineHeight: [
      'leading-b-xl',
      'leading-h-xl',
      'leading-b-lg',
      'leading-h-lg',
      'leading-b-md',
      'leading-h-md',
      'leading-b-sm',
      'leading-h-sm',
      'leading-b-xs',
      'leading-h-xs',
      'leading-b-2xs',
      'leading-h-2xs',
      'leading-[22px]',
    ],
  },
});
