import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FolderOpen = forwardRef(function FolderOpen(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2.25h-.116c-.818 0-1.376 0-1.855.128a3.75 3.75 0 0 0-2.651 2.651c-.129.479-.128 1.037-.128 1.855V14.055c0 1.367 0 2.47.116 3.337.121.9.38 1.657.982 2.26.602.601 1.36.86 2.26.981.36.049.762.077 1.206.094A.75.75 0 0 0 6 20.75H18a.75.75 0 0 0 .214-.031c.254-.014.49-.033.711-.06.77-.094 1.427-.295 1.993-.759.566-.464.892-1.07 1.135-1.805.234-.706.415-1.61.637-2.724l.01-.047.078-.393.012-.056c.212-1.059.386-1.932.425-2.631.04-.732-.058-1.414-.528-1.987-.47-.574-1.12-.804-1.845-.908a6.109 6.109 0 0 0-.094-.013c-.005-.534-.029-.943-.126-1.307a3.75 3.75 0 0 0-2.651-2.651c-.479-.129-1.037-.129-1.855-.128H13.12c-.907 0-1.216-.007-1.495-.086a2.25 2.25 0 0 1-.477-.198c-.254-.141-.477-.355-1.119-.997l-.257-.257-.042-.042c-.318-.318-.537-.537-.786-.716a3.75 3.75 0 0 0-1.58-.655c-.303-.05-.614-.05-1.063-.05H6Zm13.246 7.004c-.006-.438-.024-.656-.073-.837a2.25 2.25 0 0 0-1.59-1.59c-.263-.07-.605-.077-1.583-.077H13.02c-.769 0-1.3 0-1.806-.144a3.75 3.75 0 0 1-.796-.33c-.459-.255-.835-.631-1.378-1.175l-.071-.07-.258-.258c-.375-.375-.505-.502-.642-.6a2.25 2.25 0 0 0-.948-.394c-.167-.027-.349-.03-.88-.03H6c-.978 0-1.32.007-1.582.077a2.25 2.25 0 0 0-1.591 1.591c-.07.263-.077.605-.077 1.583v7c0 1.435.002 2.436.103 3.192.099.733.28 1.122.556 1.399.277.276.665.457 1.399.556.18.024.375.043.586.057l.906-4.528.01-.047c.222-1.114.403-2.018.636-2.724.244-.736.57-1.341 1.136-1.805.566-.464 1.224-.665 1.993-.76.738-.09 1.66-.09 2.795-.09h5.309c.379 0 .735 0 1.067.004Zm-2.744 9.996H8c-.393 0-.753 0-1.085-.003l.856-4.277c.234-1.173.398-1.985.6-2.594.194-.588.398-.9.662-1.116.263-.216.609-.355 1.225-.43.636-.079 1.465-.08 2.66-.08h5.203c1.152 0 1.934.002 2.508.084.553.08.768.215.898.373.13.159.221.396.19.954-.032.579-.183 1.347-.41 2.476l-.078.393c-.235 1.173-.398 1.985-.6 2.594-.195.588-.398.9-.662 1.116-.264.216-.61.355-1.225.43-.558.07-1.266.078-2.24.08Z"
        fill="currentColor"
      />
    </Icon>
  );
});
