import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Import = forwardRef(function Import(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        d="M12.85 3.75a.75.75 0 0 0-1.5 0v8.21L9.29 9.9a.75.75 0 1 0-1.06 1.06l3.34 3.34a.75.75 0 0 0 1.06 0l3.34-3.34a.75.75 0 0 0-1.06-1.06l-2.06 2.06V3.75Z"
        fill="currentColor"
      />
      <path
        d="M4.5 13.77a.75.75 0 0 0-1.5 0v.449c0 1.029 0 1.845.05 2.503.053.673.16 1.244.411 1.77a4.75 4.75 0 0 0 2.247 2.247c.526.25 1.097.359 1.77.41.659.052 1.475.052 2.504.052h4.237c1.029 0 1.845 0 2.503-.051.673-.052 1.244-.16 1.77-.41a4.75 4.75 0 0 0 2.247-2.248c.25-.526.359-1.097.41-1.77.052-.658.052-1.474.052-2.503v-.449a.75.75 0 0 0-1.5 0v.418c0 1.067 0 1.825-.047 2.419-.045.586-.13.95-.269 1.24a3.25 3.25 0 0 1-1.538 1.538c-.29.138-.654.224-1.24.27-.594.045-1.352.046-2.42.046h-4.174c-1.067 0-1.825 0-2.42-.047-.585-.045-.95-.13-1.24-.269a3.25 3.25 0 0 1-1.537-1.538c-.139-.29-.224-.654-.27-1.24-.045-.594-.046-1.352-.046-2.42v-.417Z"
        fill="currentColor"
      />
    </Icon>
  );
});
