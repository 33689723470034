import { BoxStyleProps, css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';
import { shadowValues } from 'design-components/theme/tokens';

export const selectedValue: CSS = {
  flex: 1,
  display: 'flex',
  gap: '$xxsmall',
  alignItems: 'center',
  marginRight: '$xxsmall',
  fontSize: '$small',
  fontWeight: '$normal',
};

export const triggerElementReset: CSS = {
  boxSizing: 'border-box',
  cursor: 'default',
  position: 'relative',
  textAlign: 'unset',

  '&:focus': {
    outline: 'none',
  },
};

export const triggerElement: CSS = {
  backgroundColor: '$white',
  transition: '$transition150',
  fontFamily: '$primary',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '$space50',
  flexGrow: 1,

  padding: '$space0',
  margin: '$space0 $space225',

  border: 'none',
};

export const triggerElementVariants = css({
  /**
   * Whether Select used as standalone component or child component
   */
  variants: {
    isInsideGroupInput: {
      true: {
        marginLeft: '$space0',
        marginRight: '$space0',
      },
    },
    isNonSelected: {
      true: {
        color: '$bodyDark1',
      },
    },
    isDisabled: {
      true: {
        backgroundColor: '$bodyLight2 !important',
        cursor: 'not-allowed !important',
      },
    },
  },
});

export const wrapper: CSS = {
  position: 'relative',
  display: 'flex',
  padding: '$space0',
  lineHeight: '$leading400',
};

export const wrapperVariants = css({
  backgroundColor: '$white',
  color: '$black',

  borderRadius: '$small',
  borderStyle: '$solid',
  borderColor: '$neutral',
  borderWidth: '$border100',

  outlineOffset: '-1px',

  variants: {
    isHovered: {
      true: {
        borderColor: 'transparent',
        boxShadow: shadowValues.activeShadow,
      },
    },
    isFocused: {
      true: {
        borderColor: 'transparent',
        boxShadow: shadowValues.activeShadow,
      },
    },
    isPressed: {
      true: {
        borderColor: 'transparent',
        boxShadow: shadowValues.activeShadow,
      },
    },
    isErrored: {
      true: {
        borderColor: 'transparent',
        boxShadow: shadowValues.errorShadow,
      },
    },
    isDisabled: {
      true: {
        borderColor: 'transparent !important',
        outline: 'transparent !important',
        backgroundColor: '$bodyLight2 !important',
        cursor: 'not-allowed !important',
      },
    },
  },
});

export type WrapperVariants = Stitches.VariantProps<typeof wrapperVariants>;

export const selectIcon: CSS = {
  display: 'flex',
  alignItems: 'center',
  transition: '$transition200',
};

export const overlayStyles: BoxStyleProps = {
  boxShadow: '0 2px 8px rgb(0 0 0 / 15%)',
  top: '100%',
  minWidth: 'fit-content',
  borderRadius: '$small',
};
