import { ProgressBar } from '@blueprintjs/core';
import { Skeleton } from 'antd';
import React, { PureComponent } from 'react';

class LoadingProcess extends PureComponent {
  render() {
    return (
      <div>
        <div className="pv-large">
          <ProgressBar />
        </div>
        <Skeleton active title={true} paragraph={{ rows: 1 }} />
        <Skeleton active title={true} paragraph={{ rows: 1 }} />
        <Skeleton active title={true} paragraph={{ rows: 1 }} />
      </div>
    );
  }
}

export default LoadingProcess;
