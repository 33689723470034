import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const reset: CSS = {
  boxSizing: 'border-box',
  position: 'relative',
  margin: 0,
  padding: 0,
};

export const split: CSS = {
  display: 'grid',
};

export const variants = css({
  gridTemplateColumns: '$$fraction',

  variants: {
    fraction: {
      'one-quarter': {
        $$fraction: '1fr 3fr',
      },
      'one-third': {
        $$fraction: '1fr 2fr',
      },
      half: {
        $$fraction: '1fr 1fr',
      },
      'two-thirds': {
        $$fraction: '2fr 1fr',
      },
      'three-quarters': {
        $$fraction: '3fr 1fr',
      },
      'auto-start': {
        $$fraction: 'auto 1fr',
      },
      'auto-end': {
        $$fraction: '1fr auto',
      },
    },
  },
  defaultVariants: {
    fraction: 'half',
  },
});

export type SplitVariants = Stitches.VariantProps<typeof variants>;
