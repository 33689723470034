import { Button, Space } from '@good/ui/core';
import { PageHeader } from '@good/ui/templates';
import React, { useState } from 'react';
import { Plus } from '@good/icons';
import { useTranslation } from 'react-i18next';
import { AddTeamMemberModal } from '../modals/add-team-member-modal';
import { SilTable } from 'views/sil-service/components/table';
import { teamMemberColumns } from './team-manager-helper';
import { SilRosterData } from 'utilities/react-trpc';
import { Collaboration8 } from '@good/illustrations';
import { EmptyTablePlaceholder } from 'views/sil-service/components/empty-table-placeholder';
import { UseQueryResult } from '@tanstack/react-query';
import { useNewRelicBrowser } from 'integrations/new-relic-browser';

type IRosterTabProps = {
  teamMembers: SilRosterData;
  teamMemberCount: number;
  refetch: (options: { throwOnError: boolean; cancelRefetch: boolean }) => Promise<UseQueryResult>;
};

export const RosterTab = ({ teamMembers, teamMemberCount, refetch }: IRosterTabProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceTeamTabs' });
  const [isAddTeamMemberModalOpen, setIsAddTeamMemberModalOpen] = useState<boolean>(false);
  const newRelicBrowser = useNewRelicBrowser();
  
  const handleClose = async () => {
    setIsAddTeamMemberModalOpen(false);
    try {
      await refetch({ throwOnError: true, cancelRefetch: true });
    } catch (e) {
      newRelicBrowser?.noticeError(`Unable to refetch rostered team members `);
    }
  };

  return (
    <div data-testid='roster-tab'>
      <PageHeader
        title={t('RosterHeading', { count: teamMemberCount })}
        subtitle={t('RosterDescription')}
        actions={
          <Button leftSection={<Plus />} onClick={() => setIsAddTeamMemberModalOpen(true)}>
            {t('AddToRoster')}
          </Button>
        }
        size='h2'
      />
      {teamMemberCount === 0 ? (
        <>
          <SilTable data={teamMembers} columns={teamMemberColumns} />
          <Space h='md' />
          <EmptyTablePlaceholder
            image={<Collaboration8 />}
            heading={t('empty.teamMemberHeading')}
            description={t('empty.teamMemberDescription')}
          />
        </>
      ) : (
        <SilTable data={teamMembers} columns={teamMemberColumns} isSelectable />
      )}
      <AddTeamMemberModal isOpen={isAddTeamMemberModalOpen} onClose={handleClose} rosteredTeamMembers={teamMembers} />
    </div>
  );
};


  