import React, { useState } from 'react';
import _ from 'lodash';
import { ActivityGroupMemberType } from 'utilities/enum-utils';
import { IActivityGroupUsers } from 'src/interfaces/user-interfaces';
import { openModalFuncType } from '../GroupServiceActivityGroupsPanel';
import { Card, Icon } from 'antd';
import { Text } from 'common-components/typography';
import { Warning } from 'common-components/alerts';
import MemberListing from 'common-components/activity-groups/listing/MemberListing';
import MemberPopoverContent from './MemberPopoverContent';
import { Stack } from '../../../../common-components/stack';

interface IUnAssignedMemberListingProps {
  customers: IActivityGroupUsers[];
  teamMembers: IActivityGroupUsers[];
  openModal: openModalFuncType;
}

function UnAssignedMemberListing(props: IUnAssignedMemberListingProps) {
  const { customers, teamMembers, openModal } = props;
  const [isClosed, setIsClosed] = useState(false);
  if (_.isEmpty(customers) && _.isEmpty(teamMembers)) return <></>;

  return (
    <div className="rounded bg-quaternary p-medium" style={{ width: '350px' }}>
      <div className="mb-medium">
        <Text weight="bold" style={{ fontSize: '18px' }}>
          Unassigned
        </Text>
      </div>

      <div className={`bg-orange-lightest rounded-big ${isClosed && 'hide'}`} style={{ border: '1px solid #FFB366' }}>
        <Warning
          borderNone={true}
          content={
            <Stack gap="md" style={{ marginRight: '-32px' }}>
              <div className="flex justify-between align-center" style={{ gap: '4px' }}>
                <Text color="orange" weight="bold">
                  Don&apos;t forget anyone!
                </Text>
                <Icon
                  type="close"
                  className="text-size-regular cursor-pointer"
                  theme="outlined"
                  onClick={() => setIsClosed(true)}
                />
              </div>
              {!_.isEmpty(customers) && !_.isEmpty(teamMembers) ? (
                <Text>
                  Some customers and team members haven&apos;t been added to an activity group yet. If you want, add
                  them now.
                </Text>
              ) : _.isEmpty(customers) ? (
                <Text>
                  Some team members haven&apos;t been added to an activity group yet. If you want, add them now.
                </Text>
              ) : (
                <Text>Some customers haven&apos;t been added to an activity group yet. If you want, add them now.</Text>
              )}
            </Stack>
          }
        />
      </div>
      <div className="bg-white bordered rounded mt-large">
        <MemberListing
          members={customers}
          type={ActivityGroupMemberType.CUSTOMER}
          PopoverContent={MemberPopoverContent}
          popoverContentProps={{
            activityGroup: null,
            isUnAssigned: true,
            openModal,
          }}
        />
      </div>

      <div className="bg-white bordered rounded mt-medium">
        <MemberListing
          members={teamMembers}
          type={ActivityGroupMemberType.TEAM_MEMBER}
          PopoverContent={MemberPopoverContent}
          popoverContentProps={{
            activityGroup: null,
            isUnAssigned: true,
            openModal,
          }}
        />
      </div>
    </div>
  );
}

export default UnAssignedMemberListing;
