import React, { Component } from 'react';
import { Paragraph, Text, Title } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Col, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { ICustomer } from 'interfaces/customer-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Spinner } from '@blueprintjs/core';
import _ from 'lodash';
import FundingViewCategoriesPanel from 'views/customers/details/tabs-panel/funding/components/FundingViewCategoriesPanel';

interface IFundSummaryPanelProps {
  onNextStep: (stepData: any) => void;
  onPreviousStep: (stepData: any) => void;
  closeCreatePackageModal: () => void;
  selectedCustomer: ICustomer;
  fundingData: typeof state.customersStore.fundingData;
  doCreateCustomerFundingData: typeof dispatch.customersStore.doCreateCustomerFundingData;
}

interface FundSummaryPanelState {
  isLoading: boolean;
}

class FundSummaryPanel extends Component<IFundSummaryPanelProps, FundSummaryPanelState> {
  state = { isLoading: false };

  private _savePackageData = async () => {
    const { doCreateCustomerFundingData, selectedCustomer, fundingData } = this.props;

    try {
      this.setState({ isLoading: true });
      let payload = {
        userId: selectedCustomer.userId,
        startDate: fundingData.startDate,
        expiredDate: fundingData.expiryDate,
        agency: fundingData.agencyName !== '' ? fundingData.agencyName : null,
        agencyAddress: fundingData.agencyAddress !== '' ? fundingData.agencyAddress : null,
        agencyContactNumber: fundingData.agencyNumber !== '' ? fundingData.agencyNumber : null,
        agencyContactCountryCode: fundingData.phoneNumberCountryCode,
        agencyEmail: fundingData.agencyEmail !== '' ? fundingData.agencyEmail : null,
        fundingContacts: [],
        fundingNDISGoals: fundingData.goalFundData,
        fundingNDISSupportCategories: fundingData.categoryData,
      };

      await doCreateCustomerFundingData(payload);
      this.setState({ isLoading: false });
      this.props.closeCreatePackageModal();
    } catch (error) {
      this.setState({ isLoading: false });
      notification.error({
        description: error.meta.message,
        message: error.message,
      });
    }
  };

  private _formatPaymentMethod = (paymentMethod: string): string => {
    switch (paymentMethod) {
      case 'NDIA':
        return 'NDIA Managed';
      case 'PLAN':
        return 'Plan Managed';
      case 'SELF':
        return 'Self Managed';
      default:
        return 'N/A';
    }
  };

  private _formatGoalTerm = (term: string): string => {
    switch (term) {
      case 'shortTerm':
        return 'Short Term';
      case 'longTerm':
        return 'Medium/Long Term';
      default:
        return term;
    }
  };

  private _onPreviousStep = () => {
    const { fundingData, onPreviousStep } = this.props;

    onPreviousStep(fundingData);
  };

  render() {
    const { fundingData } = this.props;
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div>
            <Title level={2} weight="regular">
              Create new <b>Funding Package</b>
            </Title>
            <div className="mt-x-large">
              <div className="flex-row align-center mb-x-large">
                <div className="mr-x-large">
                  <Spinner size={120} />
                </div>
                <div>
                  <Paragraph>Loading.......</Paragraph>
                  <Paragraph weight="bold">Adding Funding Package.</Paragraph>
                  <Paragraph>This won't take long.</Paragraph>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="anim-slide-left">
            {' '}
            <Title level={2} className="line-height-100 mb-x-medium">
              Funding <b>Summary</b>
            </Title>
            <Paragraph>Please confirm the Details below before saving this funding package</Paragraph>
            {/*<Row gutter={24} className="mb-small">*/}
            {/*  <Col span={12}>*/}
            {/*    <FieldLabel text={'Agency'} />*/}
            {/*    <FieldValueText text={fundingData.agencyName} />*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row gutter={24} className="mb-small">*/}
            {/*  <Col span={12}>*/}
            {/*    <FieldLabel text={'Start Date'} />*/}
            {/*    <FieldValueText text={moment(fundingData.startDate).format('DD MMMM YYYY')} />*/}
            {/*  </Col>*/}
            {/*  <Col span={12}>*/}
            {/*    <FieldLabel text={'End Date'} />*/}
            {/*    <FieldValueText text={moment(fundingData.expiryDate).format('DD MMMM YYYY')} />*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <Row gutter={24} className="mb-small">
              <Col span={24}>
                <div>
                  <Text size="x-large" weight={'bolder'}>
                    Support Categories funded
                  </Text>
                </div>
                <FundingViewCategoriesPanel categoryData={fundingData.categoryData} />
              </Col>
              <Col span={24}>
                <div className="mt-large">
                  <Text size="x-large" weight={'bolder'}>
                    Goals
                  </Text>
                </div>
                {_.map(fundingData.goalFundData, (goals, index) => {
                  console.log(goals);
                  return (
                    <div className="mv-small">
                      <div className="mb-none">
                        <Text size={'large'}>{'Goal ' + (index + 1)}</Text>
                      </div>
                      <div className="mt-none">
                        <Text size={'small'} color={'secondary'}>
                          {this._formatGoalTerm(goals.type) + ': ' + goals.goalTitle}
                        </Text>
                      </div>
                    </div>
                  );
                })}
              </Col>
            </Row>
            {/*<Row gutter={24} className="mb-small">*/}
            {/*  <Col span={12}>*/}
            {/*    <FieldLabel text={'Support Categories'} />*/}
            {/*    <FieldValueText*/}
            {/*      text={*/}
            {/*        fundingData.categoryData !== undefined*/}
            {/*          ? fundingData.categoryData.length + ' Categories of support funded'*/}
            {/*          : 0 + ' Categories of support funded'*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*  <Col span={12}>*/}
            {/*    <FieldLabel text={'Total Funding'} />*/}
            {/*    <FieldValueText text={'$ ' + budget} />*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <div className={'mt-x2-large flex-row justify-between align-center '}>
              <GhostButton onClick={this._onPreviousStep}>I would like to change some details</GhostButton>
              <PrimaryButton size="large" onClick={this._savePackageData}>
                Create Funding Package
              </PrimaryButton>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateCustomerFundingData: dispatch.customersStore.doCreateCustomerFundingData,
});

const mapState = (state: IRootState) => ({
  fundingData: state.customersStore.fundingData,
});

export default connect(mapState, mapDispatch)(FundSummaryPanel);
