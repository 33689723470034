import { Popover2 } from '@blueprintjs/popover2';
import { Button, Card, Icon } from 'antd';
import { GhostButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { IGroupServiceActivityGroup } from 'src/interfaces/service-interfaces';
import { timeZone } from 'src/interfaces/timezone-type';
import { IActivityGroupUsers } from 'src/interfaces/user-interfaces';
import { ActivityGroupMemberType } from 'utilities/enum-utils';
import MemberListing from './MemberListing';
import { Stack } from '../../../common-components/stack';

interface IActivityGroupProps {
  activityGroup: IGroupServiceActivityGroup;
  activityGroupPopoverContent: any;
  MemberPopoverContent: React.ComponentType<{ member: IActivityGroupUsers; type: ActivityGroupMemberType }>;
  memberPopoverContentProps: object;
  addMember: () => void;
  timezone?: timeZone;
}

function ActivityGroup(props: IActivityGroupProps) {
  const {
    activityGroup,
    activityGroupPopoverContent,
    MemberPopoverContent,
    memberPopoverContentProps,
    addMember,
    timezone,
  } = props;
  const { name, description, customers, teamMembers, startDateTime, endDateTime } = activityGroup;

  return (
    <div className="rounded bg-quaternary p-medium" style={{ width: '350px' }}>
      <div className="flex-row justify-between mb-x-small" style={{ columnGap: '20px' }}>
        <div className="text-overflow-ellipsis overflow-hidden whitespace-nowrap" style={{ minWidth: 0 }}>
          <Stack gap="md">
            <Text weight="bold" style={{ fontSize: '18px' }}>
              {name}
            </Text>
            <Stack gap="xs">
              {startDateTime && endDateTime && (
                <>
                  <Text size="large">
                    {`${moment.tz(startDateTime, timezone).format('hh:mm a')} -
                  ${moment.tz(endDateTime, timezone).format('hh:mm a')}`}
                  </Text>
                  <Text size="large">
                    {`${moment.tz(startDateTime, timezone).format('ddd, DD MMM')} -
                  ${moment.tz(endDateTime, timezone).format('ddd, DD MMM')}`}
                  </Text>
                </>
              )}
              <Text size="regular" color="secondary">
                {description}
              </Text>
            </Stack>
          </Stack>
        </div>
        <div style={{ minWidth: 34 }}>
          <Popover2 position="bottom-right" content={activityGroupPopoverContent}>
            <Button className="ph-small border-standard-gray">
              <Icon className="text-color-blue-light text-weight-bolder" type="ellipsis" theme="outlined" />
            </Button>
          </Popover2>
        </div>
      </div>

      {_.isEmpty(customers) && _.isEmpty(teamMembers) && (
        <div className="mv-medium text-align-center">
          <Stack gap="md" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Text color="secondary">No group members yet.</Text>
            <GhostButton icon="plus" onClick={addMember}>
              Add people
            </GhostButton>
          </Stack>
        </div>
      )}
      {!_.isEmpty(customers) && (
        <div className="bg-white bordered rounded mt-large">
          <MemberListing
            members={customers}
            type={ActivityGroupMemberType.CUSTOMER}
            PopoverContent={MemberPopoverContent}
            popoverContentProps={memberPopoverContentProps}
          />
        </div>
      )}

      {!_.isEmpty(teamMembers) && (
        <div className="bg-white bordered rounded mt-large">
          <MemberListing
            members={teamMembers}
            type={ActivityGroupMemberType.TEAM_MEMBER}
            PopoverContent={MemberPopoverContent}
            popoverContentProps={memberPopoverContentProps}
          />
        </div>
      )}
    </div>
  );
}

export default ActivityGroup;
