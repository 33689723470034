import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as H from 'history';
import { Col, Empty, Skeleton } from 'antd';
import _ from 'lodash';
import { Text, FieldLabel } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { IGroupServiceCustomerListItem } from 'interfaces/customer-interfaces';
import { CustomerActiveType } from 'utilities/enum-utils';
import CustomerListItem from './CustomerListItem';
import { GridHeader } from 'common-components/grids';
import { InfiniteScroll } from 'components';

interface IGroupServiceCustomerListingSectionProps {
  history: H.History;
  groupServiceCustomers: IGroupServiceCustomerListItem[];
  isNeedFetchCustomerList: boolean;
  clearIsNeedFetchCustomerList: () => void;
  serviceId: string;
  searchText: string;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  doGetGroupServiceCustomers: typeof dispatch.customersStore.doGetGroupServiceCustomers;
  setGroupServiceCustomers: typeof dispatch.customersStore.setGroupServiceCustomers;
  onSearchDone: () => void;
  onOpenCustomerScheduleModal: (v) => void;
}

interface IGroupServiceCustomerListingSectionState {
  isLoading: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
}

class GroupServiceCustomerListingSection extends Component<
  IGroupServiceCustomerListingSectionProps,
  IGroupServiceCustomerListingSectionState
> {
  state = {
    isLoading: false,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
  };

  private _onOpenCustomerScheduleModal = (customer) => {
    this.props.onOpenCustomerScheduleModal(customer);
  };

  private _loadFreshCustomers = () => {
    const newPage = 1;
    this.props.setGroupServiceCustomers([]);
    this.setState({ page: newPage, pageTimestamp: new Date() }, async () => {
      const payload = this._buildPayload(newPage);
      await this._fetchContent(payload);
    });
  };

  private _loadMoreCustomers = async () => {
    const newPage = this.state.page + 1;
    this.setState({ page: newPage });
    const payload = this._buildPayload(newPage);

    await this._fetchContent(payload);
  };

  private _buildPayload = (page: number) => {
    const { searchText, serviceId } = this.props;
    const { pageSize, pageTimestamp } = this.state;

    const basicPayload = {
      serviceId,
      page,
      pageSize,
      pageTimestamp,
      customerType: CustomerActiveType.ACTIVE,
    };

    return _.isEmpty(searchText) ? basicPayload : { ...basicPayload, searchString: searchText };
  };

  private _fetchContent = async (payload) => {
    this.setState({ isLoading: true });
    await this.props.doGetGroupServiceCustomers(payload);
    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    await this._loadFreshCustomers();
  };

  componentDidUpdate = async (prevProp) => {
    if (!_.isEqual(this.props.searchText, prevProp.searchText)) {
      await this._loadFreshCustomers();
      this.props.onSearchDone();
    } else if (
      this.props.isNeedFetchCustomerList &&
      this.props.isNeedFetchCustomerList !== prevProp.isNeedFetchCustomerList
    ) {
      await this._loadFreshCustomers();
      this.props.clearIsNeedFetchCustomerList();
    }
  };

  componentWillUnmount(): void {
    // reset store data
    // due to fetching is appending...
    this.props.setGroupServiceCustomers([]);
  }

  render() {
    const { groupServiceCustomers } = this.props;

    const CustomerEmptyState = () => (
      <div className='flex-1 mv-x2-large align-center flex-column'>
        <div className=''>
          <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className='mv-none' />
        </div>
        <div className='text-align-center' style={{ width: '223px' }}>
          <Text size='x2-large' color='secondary' weight='bold'>
            No customers found.
          </Text>
        </div>
      </div>
    );

    return (
      <div className='height-full bg-white mt-medium' style={{ overflow: 'hidden' }}>
        <GridHeader bordered containerClassName='border-width-small border-secondary'>
          <Col span={6} className=' bg-white'>
            <FieldLabel text='Customer' />
          </Col>
          <Col span={3} className=' bg-white'>
            <FieldLabel text='UPCOMING' />
          </Col>
          <Col span={3} className=' bg-white'>
            <FieldLabel text='COMPLETED' />
          </Col>
          <Col span={2} className=' bg-white'>
            <FieldLabel text='STATUS' />
          </Col>
          <Col span={4} className=' bg-white'>
            <FieldLabel text='CUSTOMER TYPE' />
          </Col>
          <Col span={4} className=' bg-white'>
            <FieldLabel text='ACTIVITY GROUPS' />
          </Col>
          <Col span={2} className=' bg-white'>
            <FieldLabel text=' ' />
          </Col>
        </GridHeader>

        <InfiniteScroll
          hasMore={groupServiceCustomers.length >= this.state.page * this.state.pageSize}
          loadMore={this._loadMoreCustomers}
        >
          {groupServiceCustomers.map((customer, index) => (
            <CustomerListItem
              key={`${customer?.customerUserId}-${index}`}
              customerItem={customer}
              history={this.props.history}
              serviceId={this.props.serviceId}
              selectedGroupService={this.props.selectedGroupService}
              onOpenCustomerScheduleModal={() => this._onOpenCustomerScheduleModal(customer)}
            />
          ))}
          <div id='scroll' />
        </InfiniteScroll>

        {this.state.isLoading && (
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
        )}
        {groupServiceCustomers.length || <CustomerEmptyState />}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceCustomers: state.customersStore.groupServiceCustomers,
  selectedGroupService: state.groupServiceStore.selectedGroupService,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetGroupServiceCustomers: dispatch.customersStore.doGetGroupServiceCustomers,
  setGroupServiceCustomers: dispatch.customersStore.setGroupServiceCustomers,
});

export default connect(mapState, mapDispatch)(GroupServiceCustomerListingSection);
