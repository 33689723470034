import React, { useState } from 'react';
import _ from 'lodash';
import { Icon } from 'antd';
import moment from 'moment-timezone';
import { SubTitle } from '../../../../../common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { ServiceType, SleepoverType } from 'utilities/enum-utils';
import { state } from 'src/stores/rematch/root-store';
import { ITimesheetShiftItem } from 'interfaces/booking-interfaces';

interface IShiftItemRowRow {
  shiftItem?: any;
  portalUser: typeof state.authStore.portalUser;
  history?: any;
  onClickDetailShiftHour: (shiftItem) => void;
}

const ShiftItemRow = (props: IShiftItemRowRow) => {
  const { shiftItem, history, onClickDetailShiftHour } = props;
  const [showSubLines, setshowSubLines] = useState<boolean>(false);

  const onClickBooking = (attendanceId: string) => {
    history.push(`/bookings/details/${attendanceId}`);
  };

  const onClickOpen = async () => {
    setshowSubLines(true);
  };

  const onClickClose = async () => {
    setshowSubLines(false);
  };

  const onClickWorker = (userId: string) => {
    history.push(`/team/details/${userId}`);
  };

  if (shiftItem.isEmpty) {
    return <></>;
  }

  const { firstName, lastName, items, supportWorkerId } = shiftItem;

  const totalShifts = items.length;

  const totalHours = _.sumBy(items, function (item: any) {
    return item.duration;
  });

  const totalKmTravelled = _.sumBy(items, function (item: ITimesheetShiftItem) {
    return item.travelBreakdown?.travelDistanceTotal || 0;
  });

  const timeType = (item: ITimesheetShiftItem) => {
    if (item.shiftComponents?.length === 1) {
      return item.shiftComponents[0]?.workType.name;
    } else return;
  };

  const shiftComponent = (
    <>
      <tr
        onClick={showSubLines ? onClickClose : onClickOpen}
        className={`anim-fade-in bg-white ${showSubLines && 'no-border'}`}
      >
        <td className='fluid-cell'>
          <HyperlinkButton color='black' onClick={() => onClickWorker(supportWorkerId)}>
            {firstName} {lastName}
          </HyperlinkButton>
        </td>
        <td className='fluid-cell' style={{ textAlign: 'right' }}>
          {totalShifts}
        </td>
        <td className='fluid-cell' style={{ textAlign: 'right' }}>
          {totalKmTravelled}
        </td>
        <td className='fluid-cell' style={{ textAlign: 'right' }}>
          {totalHours.toFixed(2)} hour{totalHours !== 1 && 's'}
        </td>
        <td style={{ width: '20px' }} onClick={showSubLines ? onClickClose : onClickOpen}>
          <div className='pr-small text-align-right'>
            {showSubLines ? <Icon className='size-x-large' type='up' /> : <Icon className='size-x-large' type='down' />}
          </div>
        </td>
      </tr>
      <tr>
        {showSubLines && (
          <td colSpan={7} className='p-none mh-12 bg-tertiary'>
            <table className='subLines m-medium' style={{ width: '98%' }}>
              <thead>
                <tr>
                  <th>
                    <SubTitle>Booking Ref</SubTitle>
                  </th>
                  <th>
                    <SubTitle>Service</SubTitle>
                  </th>
                  <th>
                    <SubTitle>Start</SubTitle>
                  </th>
                  <th>
                    <SubTitle>End</SubTitle>
                  </th>
                  <th>
                    <SubTitle>Time Type</SubTitle>
                  </th>

                  <th style={{ textAlign: 'right', width: '112px' }}>
                    <SubTitle>KM</SubTitle>
                  </th>
                  <th colSpan={2} style={{ textAlign: 'right' }}>
                    <SubTitle>Hours</SubTitle>
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((shiftItem, key: number) => (
                  <tr
                    className={`text-align-middle ${items.length === key + 1 && 'no-border'} subLines-border`}
                    key={key}
                  >
                    <td className='subitem-width subLines-bgcolor'>
                      {shiftItem.serviceDetails?.serviceType === ServiceType.INDIVIDUAL ? (
                        <HyperlinkButton onClick={() => onClickBooking(shiftItem.attendanceId)}>
                          {shiftItem.referenceId}
                        </HyperlinkButton>
                      ) : shiftItem.serviceDetails?.serviceType === ServiceType.GROUP ? (
                        <div className='ml-medium'> - </div>
                      ) : null}
                    </td>
                    <td className='subLines-bgcolor' style={{ width: '400px' }}>
                      {shiftItem.serviceDetails?.serviceName}
                    </td>
                    <td className='subitem-width subLines-bgcolor'>
                      {' '}
                      {shiftItem.startDateTime
                        ? moment
                            .tz(shiftItem.startDateTime, shiftItem.serviceDetails?.timezone)
                            .format('D/MM/YYYY, hh:mm a')
                        : 0}
                    </td>
                    <td className='subitem-width subLines-bgcolor'>
                      {' '}
                      {shiftItem.endDateTime
                        ? moment
                            .tz(shiftItem.endDateTime, shiftItem.serviceDetails?.timezone)
                            .format('D/MM/YYYY, hh:mm a')
                        : 0}
                    </td>
                    <td className='subitem-width subLines-bgcolor'>{timeType(shiftItem)}</td>
                    <td className='subitem-width subLines-bgcolor' style={{ textAlign: 'right' }}>
                      {shiftItem.travelBreakdown.travelDistanceTotal > 0
                        ? `${shiftItem.travelBreakdown.travelDistanceTotal.toString()}`
                        : '-'}
                    </td>
                    <td colSpan={2} className='subLines-bgcolor' style={{ textAlign: 'right' }}>
                      {!shiftItem.sleepoverType || shiftItem.sleepoverType === SleepoverType.NONE ? (
                        `${shiftItem.duration.toFixed(2)}`
                      ) : (
                        <HyperlinkButton onClick={() => onClickDetailShiftHour(shiftItem)}>View</HyperlinkButton>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        )}
      </tr>
    </>
  )
  return shiftComponent;
};

export default React.memo(ShiftItemRow);
