// Shift status tag
import _ from 'lodash';
// import { ShiftStatus } from 'views/group-services/session-details/team-members/team-members-dummy-data';
import TextTag from 'common-components/tags/TextTag';
import React from 'react';
import { ShiftSlotStatus } from 'utilities/enum-utils';

// TODO Jir - Move this into common-components / tags
interface IShiftStatusTag {
  shiftStatus: any;
  changeUnassigned: boolean;
}

export function ShiftStatusTag({ shiftStatus, changeUnassigned = true }: IShiftStatusTag) {
  const shiftStatusLabel =
    shiftStatus === 'UNASSIGNED' && changeUnassigned
      ? 'Open shift'
      : _.chain(shiftStatus.replaceAll('_', ' ')).capitalize().value();

  let tagProps = {};

  switch (shiftStatus) {
    case ShiftSlotStatus.UNASSIGNED:
      tagProps = { color: 'secondary', theme: 'light' };
      break;

    case ShiftSlotStatus.PENDING:
      tagProps = { color: 'tertiary', theme: 'light' };
      break;

    case ShiftSlotStatus.CONFIRMED:
      tagProps = { color: 'blue', theme: 'light' };
      break;

    case ShiftSlotStatus.CHECKED_IN:
      tagProps = { color: 'orange', theme: 'light' };
      break;

    case ShiftSlotStatus.CHECKED_OUT:
      tagProps = { color: 'green', theme: 'light' };
      break;

    case ShiftSlotStatus.APPROVED:
      tagProps = { color: 'blue', theme: 'dark' };
      break;

    case ShiftSlotStatus.NO_SHOW:
      tagProps = { color: 'red', theme: 'dark' };
      break;

    case ShiftSlotStatus.DECLINED:
      tagProps = { color: 'rose', theme: 'dark' };
      break;

    // Additional tags
    case ShiftSlotStatus.CANCELLED:
      tagProps = { color: 'rose', theme: 'dark' };
      break;

    case ShiftSlotStatus.REJECTED:
      tagProps = { color: 'rose', theme: 'dark' };
      break;

    case ShiftSlotStatus.PUBLISHING:
      tagProps = { color: 'forest', theme: 'light' };
      break;

    case ShiftSlotStatus.CLAIMED:
      tagProps = { color: 'violet', theme: 'light' };
      break;

    case ShiftSlotStatus.ON_LEAVE:
      tagProps = { color: 'dark-gray-darkest', theme: 'dark' };
      break;
    case ShiftSlotStatus.LEAVE_APPROVED:
      tagProps = { color: 'blue', theme: 'dark' };
      break;
  }

  return <TextTag content={shiftStatusLabel} {...tagProps} />;
}
