import { Card, Form, Radio, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Title } from 'common-components/typography';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import {
  WorkflowTemplateStepApprovalType,
  WorkflowTemplateStepType,
  WorkflowTemplateTriggerType,
  WorkflowTemplateType,
} from 'utilities/enum-utils';

interface ICreateWorkflowTemplateTriggerStepPanelProps extends FormComponentProps {
  onNextStep(): void;
  onPreviousStep(): void;
  setAddWorkflowTemplateWizard: typeof dispatch.workflowStore.setAddWorkflowTemplateWizard;
  addWorkflowTemplateWizard: typeof state.workflowStore.addWorkflowTemplateWizard;
}

class CreateWorkflowTemplateTriggerStepPanel extends PureComponent<ICreateWorkflowTemplateTriggerStepPanelProps> {
  private _onValidateBeforeNextStep = () => {
    const { form, setAddWorkflowTemplateWizard, onNextStep } = this.props;

    form.validateFields((errors, values) => {
      if (!errors) {
        const isWorkflowTypePlatform = values.workflowTemplateType === WorkflowTemplateType.PLATFORM;

        const addWorkflowTemplateWizard = {
          ...this.props.addWorkflowTemplateWizard,
          ...values,
          steps: [
            {
              name: isWorkflowTypePlatform ? WorkflowTemplateStepType.TRIGGER : '',
              type: isWorkflowTypePlatform ? WorkflowTemplateStepType.TRIGGER : WorkflowTemplateStepType.STEP,
              stepNumber: isWorkflowTypePlatform ? 0 : 1,
              approvalType: WorkflowTemplateStepApprovalType.ANYONE,
              attachment: null,
              stepApprovers: [],
            },
          ],
        };

        setAddWorkflowTemplateWizard(addWorkflowTemplateWizard);
        onNextStep();
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    return (
      <>
        <Card bordered={false} className="shadow-container rounded-big">
          <Title level={4} weight="regular" className="mb-large">
            Create a workflow
          </Title>
          <Paragraph size="large">How would you like to trigger this workflow</Paragraph>

          <Form.Item className="mb-small">
            {getFieldDecorator('workflowTemplateType', {
              initialValue: '',
              rules: [{ required: true, message: 'Please choose an option' }],
            })(
              <Radio.Group className="flex-column">
                <Radio className="align-center" style={{ display: 'flex' }} value={WorkflowTemplateType.MANUAL}>
                  Manually trigger
                </Radio>
                <Radio
                  className="align-center mt-small"
                  style={{ display: 'flex' }}
                  value={WorkflowTemplateType.PLATFORM}
                >
                  In platform action
                </Radio>
              </Radio.Group>,
            )}
          </Form.Item>

          {!!getFieldValue('workflowTemplateType') &&
            getFieldValue('workflowTemplateType') !== WorkflowTemplateType.MANUAL && (
              <div>
                <SubTitle>TRIGGER</SubTitle>
                <Form.Item>
                  {getFieldDecorator('triggerType', {
                    initialValue: WorkflowTemplateTriggerType.INCIDENT,
                    rules: [{ required: true, message: 'Please select a trigger' }],
                  })(
                    <Select placeholder="Select a trigger" className="width-full">
                      <Select.Option value={WorkflowTemplateTriggerType.INCIDENT}>Incident reported</Select.Option>
                    </Select>,
                  )}
                </Form.Item>
              </div>
            )}
        </Card>

        <div className="justify-end flex p-large">
          <PrimaryButton
            disabled={!getFieldValue('workflowTemplateType')}
            size="large"
            onClick={this._onValidateBeforeNextStep}
          >
            Next
          </PrimaryButton>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  addWorkflowTemplateWizard: state.workflowStore.addWorkflowTemplateWizard,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddWorkflowTemplateWizard: dispatch.workflowStore.setAddWorkflowTemplateWizard,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<ICreateWorkflowTemplateTriggerStepPanelProps>()(CreateWorkflowTemplateTriggerStepPanel));
