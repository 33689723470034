import React, { Component } from 'react';
import moment from 'moment-timezone';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text, Title } from 'common-components/typography';
import { Form, notification } from 'antd';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import CommonUtils from 'utilities/common-utils';
import { TimezoneIndicator } from 'common-components/timezone';
import { timeZone } from 'interfaces/timezone-type';
import { CalendarOutlined } from '@ant-design/icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

interface IBookingCheckinActionModelProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  doCheckinBooking: typeof dispatch.bookingsStore.doCheckinBooking;
  displayTimezone: timeZone;
  flags: { [key: string]: boolean };
}

interface CheckInActionModalState {
  isLoading: boolean;
  isSuccess: boolean;
  title: string;
}

class BookingCheckinActionModel extends Component<IBookingCheckinActionModelProps, CheckInActionModalState> {
  state = {
    isLoading: false,
    isSuccess: false,
    title: 'Start booking',
  };
  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ title: 'Start booking' });
    if (!this.state.isLoading) {
      onClose();
    }

    // Do any manual clean up.
  };

  _onSubmitCheckin = () => {
    const { form, doCheckinBooking, displayTimezone } = this.props;
    form.validateFields(async (err, value) => {
      if (!err) {
        this.setState({ isLoading: true });

        try {
          await doCheckinBooking({
            checkInTime: moment.tz(moment(value.Date).format('YYYY-MM-DD HH:mm'), displayTimezone).toISOString(),
          });
          // await asyncDelay(4000);
          this.setState({ isLoading: false, isSuccess: true, title: 'Booking successfully started' });
          notification.open({
            message: <b>Booking started</b>,
            description: 'You have successfully started this booking',
          });
        } catch (e) {
          this.setState({ isLoading: false });
        }
      }
    });
  };

  private _onChangeDate = (value) => {
    const { form } = this.props;

    const newTime = moment(form.getFieldValue('Time'));
    const newDate = moment(value);

    newDate.set('hour', newTime.hours());
    newDate.set('minutes', newTime.minutes());

    setTimeout(() => {
      form.setFieldsValue({
        Time: newDate.toDate(),
        Date: newDate.toDate(),
      });
    }, 0);
  };

  private _roundTime = (value) => {
    const { form } = this.props;
    const roundedTime = CommonUtils.formatCeilingDateTime(value);
    setTimeout(() => {
      form.setFieldsValue({
        Time: roundedTime.toDate(),
        Date: roundedTime.toDate(),
      });
    }, 0);
  };

  componentDidMount() {
    this.setState({
      isSuccess: false,
      isLoading: false,
    });
  }

  render() {
    const { isOpen, form, selectedBookingItem, displayTimezone, flags } = this.props;
    const { getFieldDecorator } = form;
    const { ny2047 } = flags;
    const { startDateTime } = selectedBookingItem;
    const roundedDate = moment(moment.tz(startDateTime, displayTimezone).format('YYYY-MM-DD HH:mm'));

    return (
      <ActionModal
        title={
          <Title weight="bolder" level={4} style={{ color: 'black' }}>
            {this.state.title}
          </Title>
        }
        isOpen={isOpen}
        onClose={this.onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        {!this.state.isLoading && !this.state.isSuccess && (
          <>
            {ny2047 ? (
              <div className="text-align-left mt-small">
                <div className="mb-medium">
                  <Paragraph size="large" style={{ color: 'black' }}>
                    You are starting this booking on behalf of{' '}
                    <span className="text-weight-bold">
                      {selectedBookingItem.workerFirstName + ' ' + selectedBookingItem.workerLastName}.
                    </span>
                  </Paragraph>
                </div>

                <div className="flex mb-large mt-x-small">
                  <div style={{ color: 'black' }} className="flex-column align-left mr-medium position-relative">
                    <Text size="small" color="secondary" className="mb-x-small">
                      Start date
                    </Text>
                    {getFieldDecorator('Date', {
                      initialValue: roundedDate.toDate(),
                      valuePropName: 'selected',
                      rules: [{ required: true, message: 'Please enter a check-in date!' }],
                    })(
                      <DatePicker
                        className="gh-datepicker rounded pv-small ph-12 text-size-large"
                        calendarClassName="gh-datepicker-calendar"
                        dateFormat="d/M/yyyy"
                        onChange={this._onChangeDate}
                      />,
                    )}
                    <CalendarOutlined
                      className="position-absolute disabled"
                      style={{ left: 123, bottom: 12, color: '#9D9D9D' }}
                    />
                  </div>
                  <div className="flex-column align-left position-relative">
                    <Text size="small" color="secondary" className="mb-x-small">
                      Start time
                    </Text>
                    {getFieldDecorator('Time', { initialValue: roundedDate })(
                      <TimeInput
                        onChange={this._roundTime}
                        className="pv-small ph-12 line-height-100"
                        containerStyle={{ width: 129, color: 'black' }}
                      />,
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-align-left">
                <div className="mb-medium">
                  <Paragraph>
                    You are starting this booking on behalf of{' '}
                    <span className="text-weight-bold">
                      {selectedBookingItem.workerFirstName + ' ' + selectedBookingItem.workerLastName}
                    </span>{' '}
                    with the following date {'&'} time.
                  </Paragraph>
                </div>

                <div className="flex-row align-center justify-center mb-medium">
                  {getFieldDecorator('Date', {
                    initialValue: roundedDate.toDate(),
                    valuePropName: 'selected',
                    rules: [{ required: true, message: 'Please enter a check-in date!' }],
                  })(
                    <DatePicker
                      className="gh-datepicker rounded"
                      calendarClassName="gh-datepicker-calendar"
                      dateFormat="d/M/yyyy"
                      onChange={this._onChangeDate}
                    />,
                  )}

                  <Text className="mh-medium">at</Text>
                  {getFieldDecorator('Time', { initialValue: roundedDate })(
                    <TimeInput size="large" onChange={this._roundTime} />,
                  )}
                </div>

                <div className="text-align-center">
                  <Text size="small" color="secondary">
                    current timezone{' '}
                    <span className="ml-small">
                      <TimezoneIndicator
                        hasIcon={false}
                        showTzName={false}
                        bordered={false}
                        timezone={displayTimezone}
                      />
                    </span>
                  </Text>
                </div>
              </div>
            )}
          </>
        )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading && this.state.isSuccess && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>
                You have successfully started this booking on behalf of{' '}
                <span className="text-weight-bold">
                  {selectedBookingItem.workerFirstName + ' ' + selectedBookingItem.workerLastName}
                </span>{' '}
                with the following date {'&'} time.
              </Paragraph>
            </div>

            <div className="flex-row align-center justify-left">
              <Paragraph size={'large'} weight="bold">
                {moment
                  .tz(selectedBookingItem.workerCheckedInDateTime, displayTimezone)
                  .format('dddd DD MMMM YYYY h:mm A')}
                <TimezoneIndicator showTzName={false} timezone={displayTimezone} />
              </Paragraph>
            </div>
          </div>
        )}

        {!this.state.isSuccess && (
          <ActionModalFooter>
            {ny2047 ? (
              <SecondaryButton
                size="large"
                onClick={this.onCloseModal}
                className="mr-medium"
                disabled={this.state.isLoading}
              >
                Cancel
              </SecondaryButton>
            ) : (
              <GhostButton
                size="large"
                onClick={this.onCloseModal}
                className="mr-medium"
                disabled={this.state.isLoading}
              >
                Not now
              </GhostButton>
            )}

            <PrimaryButton size="large" onClick={this._onSubmitCheckin} loading={this.state.isLoading}>
              Start booking
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {this.state.isSuccess && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedBookingItem: state.bookingsStore.selectedBookingItem });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCheckinBooking: dispatch.bookingsStore.doCheckinBooking,
});

export default connect(
  mapState,
  mapDispatch,
)(withLDConsumer()(Form.create<IBookingCheckinActionModelProps>()(BookingCheckinActionModel)));
