import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const AlertTriangle = forwardRef(function AlertTriangle(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4.456c-.485.61-1.03 1.548-1.813 2.899l-4.062 7.017c-.785 1.356-1.329 2.299-1.617 3.027-.286.722-.237 1.026-.122 1.225.115.2.354.395 1.122.509.774.115 1.863.117 3.43.117h8.124c1.567 0 2.656-.002 3.43-.117.769-.114 1.007-.31 1.122-.509.115-.199.165-.503-.122-1.225-.288-.728-.832-1.671-1.617-3.027l-4.062-7.017c-.783-1.351-1.328-2.289-1.814-2.9-.481-.605-.769-.713-.999-.713-.23 0-.518.108-1 .714Zm-1.173-.934c.576-.724 1.25-1.28 2.173-1.28s1.597.556 2.173 1.28c.564.708 1.163 1.744 1.905 3.025l.033.057 4.062 7.016.033.057c.744 1.286 1.346 2.325 1.68 3.17.343.862.49 1.725.028 2.526-.463.802-1.283 1.107-2.201 1.244-.898.133-2.1.133-3.585.133H7.873c-1.486 0-2.688 0-3.586-.133-.917-.137-1.738-.442-2.2-1.244-.463-.801-.316-1.664.026-2.527.335-.844.937-1.883 1.681-3.17l.033-.056L7.89 6.604l.033-.057c.742-1.281 1.342-2.317 1.905-3.025ZM12 8.25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75ZM12 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
