import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import { ICrumb } from 'interfaces/common-interface';
import * as H from 'history';
import { Col, notification, Row, Skeleton } from 'antd';
import { SubTitle, Title, Text } from 'common-components/typography';
import { SecondaryButton } from 'common-components/buttons';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { FormStatus, FormType } from 'utilities/enum-utils';
import { ProgressBar, Tab, Tabs } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { RouteComponentProps } from 'react-router-dom';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { isEmpty } from 'lodash';
import { IFormContent } from '../../shared/form-interface';
import FormStatusTag from 'common-components/tags/FormStatusTag';
import FormDetailsPanel from './FormDetailsPanel';
import ArchiveActiveFormModal from './components/ArchiveActiveFormModal';
import VersionHistoryPanel from './VersionHistoryPanel';
import WorkflowsUsagePanel from './WorkflowsUsagePanel';
import PermissionUtils from 'utilities/permission-utils';

interface IFormDetailsViewUrlParams {
  formId: string;
}

interface IFormDetailsViewProps extends RouteComponentProps<IFormDetailsViewUrlParams> {
  history: H.History;
  doGetFormDetails: typeof dispatch.formBuilderStore.doGetFormDetails;
  doArchiveForm: typeof dispatch.formBuilderStore.doArchiveForm;
  doReActivateForm: typeof dispatch.formBuilderStore.doReActivateForm;
  currentForm: typeof state.formBuilderStore.currentForm;
  portalUser: typeof state.authStore.portalUser;
}

export const FormDetailsView = ({
  history,
  match,
  doGetFormDetails,
  doArchiveForm,
  doReActivateForm,
  currentForm,
  portalUser,
}: IFormDetailsViewProps) => {
  const [selectedTabId, setSelectedTabId] = useState<string>('form');
  const [formDetails, setFormDetails] = useState<IFormContent>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isArchiveOrActivate, setIsArchiveOrActivate] = useState<boolean>(false); //if true: archive, false: activate
  const [isArchiveOrActivateModalOpen, setIsArchiveOrActivateModalOpen] = useState<boolean>(false);

  const crumbs: ICrumb[] = [
    {
      title: 'Account Management',
      target: '/account/landing',
    },
    {
      title: 'Forms',
      target: '/account/forms',
    },
    {
      title: formDetails ? formDetails.title.formTitle : '',
    },
  ];

  const _getActionMenu = () => {
    return (
      <ActionMenu>
        {formDetails.status === FormStatus.ACTIVE && (
          <ActionMenuItem
            text={'Archive'}
            className='text-color-red-dark'
            onClick={() => _onArchiveOrActivateOpen(formDetails.status)}
          />
        )}
        {formDetails.status === FormStatus.ARCHIVED && (
          <ActionMenuItem text={'Re-active'} onClick={() => _onArchiveOrActivateOpen(formDetails.status)} />
        )}
      </ActionMenu>
    );
  };

  const _fetchFormDetails = async () => {
    setIsLoading(true);
    doGetFormDetails({ formId: match.params.formId })
      .then((result) => setFormDetails(result as any))
      .then(() => setIsLoading(false));
  };
  const _onArchiveOrActivateModalClose = () => {
    setIsArchiveOrActivateModalOpen(false);
  };

  const _onArchiveOrActivateOpen = (status: FormStatus) => {
    if (status === FormStatus.ACTIVE) {
      setIsArchiveOrActivate(true);
    } else if (status === FormStatus.ARCHIVED) {
      setIsArchiveOrActivate(false);
    }
    setIsArchiveOrActivateModalOpen(true);
  };

  const _onArchiveOrActivateConfirm = async () => {
    try {
      let result = undefined;
      if (isArchiveOrActivate) {
        //divided into 2 case, not inuse and inuse
        //1.if not inuse, go to step 3
        //2.if inuse, display 1 more confirm content on modal
        //3.then call api to archive & call api to get form details
        result = await doArchiveForm({ formId: match.params.formId });
      } else {
        //todo: call api to activate form
        result = await doReActivateForm({ formId: match.params.formId });
      }
      if (result) {
        const message = isArchiveOrActivate ? 'Form archived' : 'Form re-activated';
        notification.open({
          message: <span className={'text-weight-bolder'}>{message}</span>,
          description: `You have successfully ${isArchiveOrActivate ? 'archived' : 're-activated'} this form.`,
        });

        _fetchFormDetails();
      }
      setIsArchiveOrActivateModalOpen(false);
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  useEffect(() => {
    _fetchFormDetails();
  }, []);
  if (formDetails === null || isLoading) {
    return (
      <>
        <div className='item-container'>
          <ProgressBar />
        </div>
        <Skeleton loading={isLoading} />
      </>
    );
  } else
    return (
      <div className='flex-column justify-start'>
        <ArchiveActiveFormModal
          isArchive={isArchiveOrActivate}
          isOpen={isArchiveOrActivateModalOpen}
          onClose={_onArchiveOrActivateModalClose}
          onArchiveOrActivate={_onArchiveOrActivateConfirm}
        />

        <BreadcrumbNav icon='home' theme='filled' crumbs={crumbs} isBordered={false} />

        <div className='flex-row justify-between align-center pb-x7-large'>
          <div>
            <div className='flex-column'>
              <SubTitle>Form Details</SubTitle>
              <Title level={2}>{formDetails.title.formTitle}</Title>
              <div className='flex-row align-center'>
                <Text size='regular' className='mr-medium'>
                  Version {formDetails.version}
                </Text>
                <FormStatusTag status={formDetails.status} />
              </div>
            </div>
          </div>
          <div>
            {!formDetails.formUsedInActiveWorkFlow && formDetails.status !== FormStatus.DRAFT && (
              <Popover2
                content={_getActionMenu()}
                popoverClassName={'mb-medium'}
                position={'bottom'}
                interactionKind='click'
              >
                <SecondaryButton>Form action...</SecondaryButton>
              </Popover2>
            )}
          </div>
        </div>

        <Row style={{ top: '-40px' }}>
          <Col span={24}>
            <Tabs
              large
              animate
              id='form-tabs'
              selectedTabId={selectedTabId}
              onChange={(tabId: string) => setSelectedTabId(tabId)}
              className='ph-x-large'
            >
              <Tab
                id='form'
                title='Forms'
                panel={<FormDetailsPanel formDetails={formDetails} history={history} formId={match.params.formId} />}
              />
              {PermissionUtils.validatePermission('ViewWorkflows', portalUser.permissions.permissionRoles) &&
                formDetails.formType !== FormType.INTAKE && (
                  <Tab
                    id='usage'
                    title='Workflows'
                    panel={<WorkflowsUsagePanel formId={match.params.formId} history={history} />}
                  />
                )}

              <Tab id='history' title='Version history' panel={<VersionHistoryPanel formId={match.params.formId} />} />
            </Tabs>
          </Col>
        </Row>
      </div>
    );
};

const mapStateToProps = (state: IRootState) => ({
  currentForm: state.formBuilderStore.currentForm,
  portalUser: state.authStore.portalUser,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doGetFormDetails: dispatch.formBuilderStore.doGetFormDetails,
  doArchiveForm: dispatch.formBuilderStore.doArchiveForm,
  doReActivateForm: dispatch.formBuilderStore.doReActivateForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(FormDetailsView);
