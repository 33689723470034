import React from 'react';

import { Text } from '@good/ui/core';

type IEmptyTablePlaceholderProps = {
  image: JSX.Element;
  heading: string;
  description: string;
};

export const EmptyTablePlaceholder = ({ image, heading, description }: IEmptyTablePlaceholderProps): JSX.Element => {
  return (
    <div className='flex flex-col align-center height-full gap-2 pt-8'>
      {image}
      <Text fw={700}>{heading}</Text>
      <Text c='dimmed'> {description}</Text>
    </div>
  );
};
