import React, { Component } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { SubTitle } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import { GhostButton } from 'common-components/buttons';
import _ from 'lodash';
const { Option } = Select;

interface IFundGoalDetailRowProps extends FormComponentProps {
  goal: any;
  rowIndex: number;
  onRemoveRow: (key: number) => void;
}

interface IFundGoalDetailRowState {}

class FundGoalDetailRow extends Component<IFundGoalDetailRowProps, IFundGoalDetailRowState> {
  private _validateGoalDescription = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('You must add a goal description.');
      } else if (_.trim(value).length < 5) {
        throw Error('Please enter at least 5 characters.');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _validateGoalTitle = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('You must add a goal title');
      } else if (_.trim(value).length < 5) {
        throw Error('Please enter at least 5 characters');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  render() {
    const { form, goal, rowIndex, onRemoveRow } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Row gutter={16}>
        <Col span={6}>
          <SubTitle>Goal Type</SubTitle>
          <Form.Item>
            {getFieldDecorator('type', {
              initialValue: goal.type,
              rules: [{ required: true, message: 'You must select the goal type' }],
            })(
              <Select style={{ width: '100%' }}>
                <Option value={'shortTerm'}>Short Term</Option>
                <Option value={'longTerm'}>Medium/Long Term</Option>
              </Select>,
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <SubTitle>Goal Title</SubTitle>
          <Form.Item>
            {getFieldDecorator('goalTitle', {
              initialValue: goal.goalTitle,
              rules: [{ validator: this._validateGoalTitle }],
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={9}>
          <SubTitle>Goal Description</SubTitle>
          <Form.Item>
            {getFieldDecorator('goalDescription', {
              initialValue: goal.goalDescription,
              rules: [{ validator: this._validateGoalDescription }],
            })(
              <TextArea
                placeholder={'Goal Description'}
                name={goal.type + 'GoalDESC'}
                autoSize={{ minRows: 3, maxRows: 3 }}
              />,
            )}
          </Form.Item>
        </Col>
        <Col span={3}>
          <GhostButton
            icon="close"
            size="medium"
            paddingSize="medium"
            color="black"
            onClick={() => onRemoveRow(rowIndex)}
          />
        </Col>
      </Row>
    );
  }
}

export default Form.create<IFundGoalDetailRowProps>()(FundGoalDetailRow);
