import { UseFormReturn } from 'react-hook-form';

import { Schema } from '../schema';
import fields from '../fields';

function getWatchConditions(form: UseFormReturn<Schema>) {
  const { watch } = form;

  const cancelledBy = watch(fields.cancelledBy.name);
  const cancellationReason = watch(fields.customerCancellationReason.name);
  const selectedCancelRecurringBookingOption = watch(fields.cancelRecurringBooking.name);

  const cancellingOnBehalfOfBusiness = cancelledBy === fields.cancelledBy.options.business;
  const cancellingOnBehalfOfCustomer = cancelledBy === fields.cancelledBy.options.customer;

  return {
    cancellingOnBehalfOfBusiness,
    cancellingOnBehalfOfCustomer,
    cancellationReason,
    selectedCancelRecurringBookingOption,
  };
}

export default getWatchConditions;
