import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { ISession } from 'interfaces/session-interfaces';
import { GroupServiceSessionStatus } from 'utilities/enum-utils';

interface IBulkApproveShiftSlotModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTaskSuccess: () => void;
  toBeApprovedShiftSlots: IShiftSlot[];
  serviceId: string;
  serviceDateTimeId: string;
  doBatchApproveShifts: typeof dispatch.groupServiceStore.doBatchApproveShifts;
  session: ISession;
}

interface IBulkApproveShiftSlotModalState {
  step: number;
  isLoading: boolean;
}

class BulkApproveShiftSlotModal extends Component<IBulkApproveShiftSlotModalProps, IBulkApproveShiftSlotModalState> {
  state = {
    step: this.props.session && this.props.session.sessionStatus === GroupServiceSessionStatus.COMPLETED ? 1 : 3,
    isLoading: false,
  };

  private _onApproveShiftSlots = async () => {
    const { toBeApprovedShiftSlots, serviceId, serviceDateTimeId, doBatchApproveShifts, onTaskSuccess } = this.props;
    const attendenceIds = _.map(toBeApprovedShiftSlots, (shiftSlot) => shiftSlot.supportWorkerAttendanceId);

    this.setState({ isLoading: true });
    try {
      await doBatchApproveShifts({
        serviceId,
        serviceDateTimeId,
        supportWorkerAttendanceIds: attendenceIds,
      });
      this.setState({ step: 2 });
      onTaskSuccess();
    } catch (e) {
      console.error(e);
      notification.error({
        message: <Text weight="bold">Bulk actions failed.</Text>,
        description: (
          <Text className="mt-medium">
            Error with the <b>Approving Shifts</b> action. Please try again.
          </Text>
        ),
      });
    }
    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (_prevProps, prevState) => {
    const newStep =
      this.props.session && this.props.session.sessionStatus === GroupServiceSessionStatus.COMPLETED ? 1 : 3;
    if (prevState.step !== newStep && prevState.step !== 2) {
      this.setState({
        step: newStep,
      });
    }
  };

  render() {
    const { step } = this.state;
    const modalTitle = step === 1 ? 'Approve shifts' : step === 2 ? 'Shifts approved' : 'Cannot approve shifts';
    const itemCount = this.props.toBeApprovedShiftSlots.length;

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={modalTitle}
        onClose={this.props.onClose}
        canCloseOutside={false}
        width={'medium'}
      >
        {step === 1 && (
          <div>
            <Text>
              You are approving{' '}
              <b>
                {itemCount} shift{itemCount === 1 ? '' : 's'}
              </b>
              .
              <br />
              Are you sure you want to do this?
            </Text>
            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this.props.onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                onClick={this._onApproveShiftSlots}
                className="rounded-left"
                loading={this.state.isLoading}
              >
                Approve
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {step === 2 && (
          <div>
            <Text>
              You have approved{' '}
              <b>
                {itemCount} shift{itemCount === 1 ? '' : 's'}
              </b>
              .
            </Text>
            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.props.onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {step === 3 && (
          <div>
            <div className="mb-medium line-height-135">
              <Text lineHeight={135}>Shifts cannot be approved until the session has been completed.</Text>
            </div>
            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.props.onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doBatchApproveShifts: dispatch.groupServiceStore.doBatchApproveShifts,
});

export default connect(null, mapDispatch)(BulkApproveShiftSlotModal);
