import { PortalUser } from 'stores/rematch/models/auth-store';

/**
 * Function takes in a given portal user or null (the output of the usePortalUser hook), and will return that user's designated timezone.
 * If the user does not have a designated timezone, or a portal user was not passed in, this hook will default to the current system timezone.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions
 * @param user The current portal user to grab the timezone from.
 * @returns A valid IANA timezone string.
 * @example
 *
 * const user = usePortalUser();
 * const timezone = useUserTimezone(user);
 *
 * return <div>User {user?.firstName} is in {timezone}!</div>
 */
export const useUserTimezone = (user: PortalUser | null): string => {
  // If we have the user's timezone, use that.
  if (user?.timezone) {
    return user.timezone;
  }

  // Otherwise, default to the browser's timezone.
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
