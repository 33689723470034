import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { Avatar, Divider, Icon, Input } from 'antd';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import moment from 'moment-timezone';
import AddAddressModal from '../../../../../common-components/addresses/AddAddressModal';
import { TransportAttendanceType } from '../../../../../utilities/enum-utils';
import AssignTeamMemberModal from './AssignTeamMemberModal';
import { Moment } from 'moment';

interface IAddEditTransportModalProps {
  isOpen: boolean;
  onClose: any;
  editMode: 'add' | 'edit' | string;
  editType: 'before' | 'after' | string;
  editDetails: any;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  doCreateTransportBooking: typeof dispatch.groupBookingsStore.doCreateTransportBooking;
  doUpdateTransportBooking: typeof dispatch.groupBookingsStore.doUpdateTransportBooking;
}

interface IAddEditTransportModalState {
  isLocationModalOpen: boolean;
  isAssignWorkerModalOpen: boolean;
  currentStep: number;
  pickupAddress: any;
  dropOffAddress: any;
  pickupTime: Moment;
  dropOffTime: Moment;
  comment: string;
  locationType: string;
  assignedTeamMember: any;
  isEndTimeBeforeStartTimeError: boolean;
  isLoading: boolean;
}

class EditTransportBookingModal extends Component<IAddEditTransportModalProps, IAddEditTransportModalState> {
  state = {
    currentStep: 1,
    isLocationModalOpen: false,
    isAssignWorkerModalOpen: false,
    pickupAddress: null,
    dropOffAddress: null,
    pickupTime: moment(),
    dropOffTime: moment(),
    comment: '',
    locationType: '',
    assignedTeamMember: null,
    isEndTimeBeforeStartTimeError: false,
    isLoading: false,
  };

  resetModal = () =>
    this.setState({
      currentStep: 1,
      isLocationModalOpen: false,
      isAssignWorkerModalOpen: false,
      comment: '',
      isLoading: false,
    });

  initializeModal = () => {
    const { editMode, editType, selectedGroupBookingItem } = this.props;

    if (editMode && selectedGroupBookingItem) {
      const displayPickUpAddress =
        editMode === 'add'
          ? editType === 'before'
            ? selectedGroupBookingItem.userAddress
            : selectedGroupBookingItem.address
          : editType === 'before'
          ? selectedGroupBookingItem.transportBeforeBooking?.address
          : selectedGroupBookingItem.transportAfterBooking?.address;
      const displayDropOffAddress =
        editMode === 'add'
          ? editType === 'before'
            ? selectedGroupBookingItem.address
            : selectedGroupBookingItem.userAddress
          : editType === 'before'
          ? selectedGroupBookingItem.transportBeforeBooking?.address2
          : selectedGroupBookingItem.transportAfterBooking?.address2;

      const displayStartDateTime =
        editMode === 'add'
          ? editType === 'before'
            ? moment(
                moment
                  .tz(selectedGroupBookingItem.startDateTime, selectedGroupBookingItem.timezone)
                  .add(-30, 'minutes')
                  .format('YYYY-MM-DD HH:mm'),
              )
            : moment(
                moment
                  .tz(selectedGroupBookingItem.endDateTime, selectedGroupBookingItem.timezone)
                  .format('YYYY-MM-DD HH:mm'),
              )
          : editType === 'before'
          ? moment(
              moment
                .tz(selectedGroupBookingItem.transportBeforeBooking.startDateTime, selectedGroupBookingItem.timezone)
                .format('YYYY-MM-DD HH:mm'),
            )
          : moment(
              moment
                .tz(selectedGroupBookingItem.transportAfterBooking.startDateTime, selectedGroupBookingItem.timezone)
                .format('YYYY-MM-DD HH:mm'),
            );

      const displayEndDateTime =
        editMode === 'add'
          ? editType === 'before'
            ? moment(
                moment
                  .tz(selectedGroupBookingItem.startDateTime, selectedGroupBookingItem.timezone)
                  .format('YYYY-MM-DD HH:mm'),
              )
            : moment(
                moment
                  .tz(selectedGroupBookingItem.endDateTime, selectedGroupBookingItem.timezone)
                  .add(30, 'minutes')
                  .format('YYYY-MM-DD HH:mm'),
              )
          : editType === 'before'
          ? moment(
              moment
                .tz(selectedGroupBookingItem.transportBeforeBooking.endDateTime, selectedGroupBookingItem.timezone)
                .format('YYYY-MM-DD HH:mm'),
            )
          : moment(
              moment
                .tz(selectedGroupBookingItem.transportAfterBooking.endDateTime, selectedGroupBookingItem.timezone)
                .format('YYYY-MM-DD HH:mm'),
            );

      const displayComments =
        editMode === 'add'
          ? ''
          : editType === 'before'
          ? selectedGroupBookingItem.transportBeforeBooking.comment
          : selectedGroupBookingItem.transportAfterBooking.comment;

      this.setState({
        pickupAddress: displayPickUpAddress,
        dropOffAddress: displayDropOffAddress,
        pickupTime: displayStartDateTime,
        dropOffTime: displayEndDateTime,
        comment: displayComments,
      });
    }
  };

  //region Action handlers
  onCancel = () => {
    this.resetModal();
    this.props.onClose();
  };

  onBack = () => {
    this.setState({ currentStep: 1, assignedTeamMember: null });
  };

  onNext = () => {
    const { pickupTime, dropOffTime } = this.state;

    if (pickupTime.isSameOrAfter(dropOffTime)) {
      this.setState({ isEndTimeBeforeStartTimeError: true });
    } else {
      this.setState({ currentStep: 2, isEndTimeBeforeStartTimeError: false });
    }
  };

  onCreate = async () => {
    const {
      editMode,
      editType,
      doCreateTransportBooking,
      doUpdateTransportBooking,
      editDetails,
      selectedGroupBookingItem,
    } = this.props;
    this.setState({ isLoading: true });

    if (editMode === 'add') {
      const payload = {
        type: editType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION,
        startDateTime: moment
          .tz(this.state.pickupTime.format('YYYY-MM-DD HH:mm'), selectedGroupBookingItem.timezone)
          .toISOString(),
        endDateTime: moment
          .tz(this.state.dropOffTime.format('YYYY-MM-DD HH:mm'), selectedGroupBookingItem.timezone)
          .toISOString(),
        pickupAddress: this.state.pickupAddress,
        dropOffAddress: this.state.dropOffAddress,
        workerId: this.state.assignedTeamMember ? this.state.assignedTeamMember.supportWorkerId : null,
        comment: this.state.comment,
      };

      await doCreateTransportBooking(payload);
      this.setState({ currentStep: 3, isLoading: false });
    } else {
      const payload = {
        attendanceType:
          editType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION,
        startDateTime: moment
          .tz(this.state.pickupTime.format('YYYY-MM-DD HH:mm'), selectedGroupBookingItem.timezone)
          .toISOString(),
        endDateTime: moment
          .tz(this.state.dropOffTime.format('YYYY-MM-DD HH:mm'), selectedGroupBookingItem.timezone)
          .toISOString(),
        address: this.state.pickupAddress,
        address2: this.state.dropOffAddress,
        comment: this.state.comment,
        transportBookingId: editDetails.attendanceId,
        bookingId: selectedGroupBookingItem.bookingId,
      };

      await doUpdateTransportBooking(payload);
      this.onComplete();
    }
  };

  onComplete = () => {
    this.resetModal();
    this.props.onClose();
  };

  // Location
  onEditLocation = ({ locationType }: { locationType: 'pickup' | 'dropoff' | string }) => {
    this.setState({ locationType: locationType });
    this.onOpenLocationModal();
  };

  // Assign team member
  onAssignTeamMember = () => {
    this.onOpenAssignWorkerModal();
  };

  private _onRemoveTeamMember = () => {
    this.setState({ assignedTeamMember: null });
  };

  //endregion

  //region Modal handlers
  onOpenLocationModal = () => this.setState({ isLocationModalOpen: true });
  onCloseLocationModal = () => this.setState({ isLocationModalOpen: false });

  onOpenAssignWorkerModal = () => this.setState({ isAssignWorkerModalOpen: true });
  onCloseAssignWorkerModal = () => this.setState({ isAssignWorkerModalOpen: false });

  componentDidMount() {
    if (this.props.editMode === 'add') {
      this.resetModal();
    } else {
      this.initializeModal();
    }
  }

  componentDidUpdate(prevProps: Readonly<IAddEditTransportModalProps>) {
    if (
      this.props.isOpen &&
      (prevProps.editType !== this.props.editType || prevProps.editMode !== this.props.editMode)
    ) {
      this.initializeModal();
    }
  }

  private _onChangeStartDate = async (date) => {
    this.setState({
      pickupTime: CommonUtils.formatCeilingDateTime(date),
    });
  };

  private _onChangeEndDate = async (date) => {
    this.setState({
      dropOffTime: CommonUtils.formatCeilingDateTime(date),
    });
  };

  private _onSelectWorker = (worker) => {
    this.setState({ assignedTeamMember: worker });
  };

  private _onSaveAddress = async (address) => {
    if (this.state.locationType === 'pickup') {
      this.setState({ pickupAddress: address });
    } else if (this.state.locationType === 'dropoff') {
      this.setState({ dropOffAddress: address });
    }
  };

  private _onCloseLocationModel = () => {
    this.setState({ isLocationModalOpen: false, locationType: '' });
  };

  private _onUpdateComment = (event) => {
    this.setState({ comment: event.target.value });
  };
  //endregion

  render() {
    const { isOpen, editMode, editType, selectedGroupBookingItem } = this.props;
    const { pickupAddress, dropOffAddress, dropOffTime, pickupTime, isEndTimeBeforeStartTimeError } = this.state;

    const editTypeLabel = editType === 'before' ? 'transport before' : 'after' ? 'transport after' : '';
    const editModeLabel = editMode === 'add' ? 'Add' : 'edit' ? 'Edit' : '';

    const modalTitle = `${editModeLabel} ${editTypeLabel} - booking details`;

    return (
      <>
        {/* Main modal */}
        <ActionModal isOpen={isOpen} onClose={this.onCancel} title={modalTitle} width="x-large">
          {/* First step - START */}
          {this.state.currentStep === 1 && (
            <div className="line-height-120 anim-fade-in-fast">
              {/* Top label */}
              <section className="mb-medium">
                <Text lineHeight={120}>Please fill in the {editTypeLabel} - booking details below.</Text>
              </section>

              {/* User*/}
              <section className="mb-large">
                <FieldLabel text={'CUSTOMER'} />
                <div className="flex-row align-center mt-x2-small">
                  <Avatar src={selectedGroupBookingItem.attachmentUrl} icon="user" className="mr-small" />
                  <Text
                    lineHeight={120}
                  >{`${selectedGroupBookingItem.firstName} ${selectedGroupBookingItem.lastName}`}</Text>
                </div>
              </section>

              {/* Details */}
              <section className="p-medium bordered border-standard-gray rounded-big bg-quaternary mb-medium">
                {/* Pick up / drop off time */}
                <div className="flex-row">
                  {/* Pick up time */}
                  <div className="mr-medium flex-1">
                    <FieldLabel text="PICK-UP TIME" />
                    <div className="mt-x2-small flex-row">
                      <DatePicker
                        className="gh-datepicker rounded mr-small"
                        calendarClassName="gh-datepicker-calendar"
                        dateFormat="d/M/yyyy"
                        selected={pickupTime.toDate()}
                        onChange={this._onChangeStartDate}
                      />
                      <TimeInput
                        size="large"
                        className="bg-white"
                        value={pickupTime}
                        onChange={this._onChangeStartDate}
                      />
                    </div>
                  </div>

                  {/* Drop off time */}
                  <div className="flex-1">
                    <FieldLabel text="DROP-OFF TIME" />
                    <div className="mt-x2-small flex-row">
                      <DatePicker
                        className="gh-datepicker rounded mr-small"
                        calendarClassName="gh-datepicker-calendar"
                        dateFormat="d/M/yyyy"
                        selected={dropOffTime.toDate()}
                        onChange={this._onChangeEndDate}
                      />
                      <TimeInput
                        size="large"
                        className={'bg-white'}
                        value={dropOffTime}
                        onChange={this._onChangeEndDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-medium">
                  {isEndTimeBeforeStartTimeError && <Text color={'red-dark'}>End time must be after start time.</Text>}
                </div>

                <Divider className="divider-medium" />

                {/* From/to location */}
                <div className="mb-medium flex-row">
                  {/* From location */}
                  <div className="flex-1 mr-medium">
                    <FieldLabel text="FROM LOCATION" />
                    <div>
                      <Text size="regular" color="secondary" lineHeight={120}>
                        Default: {editType === 'before' ? 'Customer address' : 'Venue location'}
                      </Text>
                      <br />
                      <Text lineHeight={120}>{pickupAddress ? pickupAddress.fullAddress : ''}</Text>
                    </div>

                    <div className="mt-small">
                      <HyperlinkButton onClick={() => this.onEditLocation({ locationType: 'pickup' })}>
                        Edit pick-up location...
                      </HyperlinkButton>
                    </div>
                  </div>

                  {/* To location */}
                  <div className="flex-1">
                    <FieldLabel text="TO LOCATION" />
                    <div>
                      <Text size="regular" color="secondary" lineHeight={120}>
                        Default: {editType === 'before' ? 'Venue location' : 'Customer address'}
                      </Text>
                      <br />
                      <Text lineHeight={120}>{dropOffAddress ? dropOffAddress.fullAddress : ''}</Text>
                    </div>

                    <div className="mt-small">
                      <HyperlinkButton onClick={() => this.onEditLocation({ locationType: 'dropoff' })}>
                        Edit drop-off location...
                      </HyperlinkButton>
                    </div>
                  </div>
                </div>

                <Divider className="divider-medium" />

                {/* Comments */}
                <div className="mb-medium">
                  <div className="flex-1">
                    <FieldLabel text="COMMENTS" />
                    <div className="mt-x-small">
                      <Input
                        size="large"
                        placeholder="Comments here..."
                        onChange={this._onUpdateComment}
                        value={this.state.comment}
                      />
                    </div>
                  </div>
                </div>
              </section>

              <ActionModalFooter align="right">
                <div>
                  <SecondaryButton
                    size="large"
                    className="mr-medium"
                    onClick={this.onCancel}
                    disabled={this.state.isLoading}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    size="large"
                    onClick={editMode === 'add' ? this.onNext : this.onCreate}
                    loading={this.state.isLoading}
                  >
                    {editMode === 'add' ? 'Next' : 'Save'}
                  </PrimaryButton>
                </div>
              </ActionModalFooter>
            </div>
          )}
          {/* First step - END */}

          {this.state.currentStep === 2 && (
            <div className="line-height-120 anim-fade-in-fast">
              {/* Top label */}
              <section className="mb-medium">
                <Text lineHeight={120}>Please fill in the {editTypeLabel} - booking details below.</Text>
              </section>

              {/* User*/}
              <section className="mb-large">
                <FieldLabel text={'CUSTOMER'} />
                <div className="flex-row align-center mt-x2-small">
                  <Avatar src={selectedGroupBookingItem.attachmentUrl} icon="user" className="mr-small" />
                  <Text
                    lineHeight={120}
                  >{`${selectedGroupBookingItem.firstName} ${selectedGroupBookingItem.lastName}`}</Text>
                </div>
              </section>

              <section className="mb-large p-medium line-height-120 bg-quaternary bordered border-standard-gray rounded-big">
                <div className="mb-medium">
                  <div className="mb-x2-small">
                    <FieldLabel text={'Assign a team member'} />
                  </div>
                  {this.state.assignedTeamMember ? (
                    <div className="flex-row align-center">
                      <Avatar src={this.state.assignedTeamMember.attachmentUrl} icon="user" className="mr-small" />
                      <Text>
                        {this.state.assignedTeamMember.firstName} {this.state.assignedTeamMember.lastName}
                      </Text>
                    </div>
                  ) : (
                    <Text color="secondary">Not assigned</Text>
                  )}
                  {/* Not assigned */}
                  {/*<Text color="secondary">Not assigned</Text>*/}

                  {/* Assigned to team member*/}
                  {/*<div className="flex-row align-center">*/}
                  {/*  <Avatar src="https://i.pravatar.cc/300?img=33" icon="user" className="mr-small" />*/}
                  {/*  <Text>Adrianna Fadel</Text>*/}
                  {/*</div>*/}
                </div>
                {!this.state.assignedTeamMember ? (
                  <div>
                    <HyperlinkButton onClick={this.onAssignTeamMember}>
                      <Icon type="plus" className="mr-small" />
                      Assign team member...
                    </HyperlinkButton>
                    <br />
                    <Text size="regular" color="secondary">
                      You may opt to assign a team member later
                    </Text>
                  </div>
                ) : (
                  <div>
                    <HyperlinkButton onClick={this.onAssignTeamMember}>Select another team member...</HyperlinkButton>
                    <HyperlinkButton onClick={this._onRemoveTeamMember} color={'red'} className="ml-large">
                      Remove team member...
                    </HyperlinkButton>
                  </div>
                )}
              </section>

              <ActionModalFooter align="right">
                <div>
                  <SecondaryButton size="large" className="mr-medium" onClick={this.onBack}>
                    Back
                  </SecondaryButton>
                  <PrimaryButton size="large" onClick={this.onCreate}>
                    {editMode === 'add' ? 'Create transport booking' : 'Save'}
                  </PrimaryButton>
                </div>
              </ActionModalFooter>
            </div>
          )}

          {this.state.currentStep === 3 && (
            <div className="line-height-120 anim-fade-in-fast">
              <section className="mb-large">
                You’ve successfully created a transport booking for{' '}
                <b>{`${selectedGroupBookingItem.firstName} ${selectedGroupBookingItem.lastName}`}</b> with the following
                details:
              </section>

              {/* Details */}
              <section className="p-medium bordered border-standard-gray rounded-big mb-medium">
                {/* Avatar */}
                <div className="mb-large flex-row">
                  <div className="flex-1">
                    <FieldLabel text={'ASSIGNED TO'} />
                    {this.state.assignedTeamMember ? (
                      <div className="flex-row align-center mt-x2-small">
                        <Avatar src={this.state.assignedTeamMember.attachmentUrl} icon="user" className="mr-small" />
                        <Text lineHeight={120}>
                          {this.state.assignedTeamMember.firstName} {this.state.assignedTeamMember.lastName}
                        </Text>
                      </div>
                    ) : (
                      <Text color="secondary">Not assigned</Text>
                    )}
                  </div>
                </div>

                <div className="mb-large flex-row">
                  {/* From location */}
                  <div className="flex-1 mr-medium">
                    <FieldLabel text="Pick up time" />
                    <div>
                      <Text lineHeight={120}>{this.state.pickupTime.format('D/M/YYYY h:mm A')}</Text>
                    </div>
                  </div>

                  {/* To location */}
                  <div className="flex-1">
                    <FieldLabel text="Drop off time" />
                    <div>
                      <Text lineHeight={120}>{this.state.dropOffTime.format('D/M/YYYY h:mm A')}</Text>
                    </div>
                  </div>
                </div>

                <div className="mb-large flex-row">
                  {/* From location */}
                  <div className="flex-1 mr-medium">
                    <FieldLabel text="FROM LOCATION" />
                    <div>
                      <Text lineHeight={120}>{this.state.pickupAddress.fullAddress}</Text>
                    </div>
                  </div>

                  {/* To location */}
                  <div className="flex-1">
                    <FieldLabel text="TO LOCATION" />
                    <div>
                      <Text lineHeight={120}>{this.state.dropOffAddress.fullAddress}</Text>
                    </div>
                  </div>
                </div>
              </section>

              <ActionModalFooter align="right">
                <div>
                  <PrimaryButton size="large" onClick={this.onComplete}>
                    Close
                  </PrimaryButton>
                </div>
              </ActionModalFooter>
            </div>
          )}
        </ActionModal>

        {/* Edit modals */}
        {/* TODO - Replace this with the address lookup modal */}
        <AddAddressModal
          closeCreateEditAddressModal={this._onCloseLocationModel}
          isOpen={this.state.isLocationModalOpen}
          address={
            this.state.locationType === 'pickup'
              ? pickupAddress
              : this.state.locationType === 'dropoff'
              ? dropOffAddress
              : null
          }
          saveCustomerAddress={this._onSaveAddress}
        />

        <AssignTeamMemberModal
          isOpen={this.state.isAssignWorkerModalOpen}
          onClose={this.onCloseAssignWorkerModal}
          onSelectWorker={this._onSelectWorker}
          transportBooking={{
            startDateTime: this.state.pickupTime,
            endDateTime: this.state.dropOffTime,
            address: this.state.pickupAddress,
          }}
          modelType={'edit'}
        />
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateTransportBooking: dispatch.groupBookingsStore.doCreateTransportBooking,
  doUpdateTransportBooking: dispatch.groupBookingsStore.doUpdateTransportBooking,
});

export default connect(mapState, mapDispatch)(EditTransportBookingModal);
