import React, { useMemo } from 'react';
import { DateRange } from 'views/sil-service/tabs/schedule/components/rostering-daterange-selector';
import { RangeOption } from 'views/sil-service/tabs/schedule/components/range-selector';
import { addDays, addWeeks, endOfDay, endOfWeek, isEqual, startOfDay, startOfWeek } from 'date-fns';
import { Button, Text } from '@good/components';
import { Check } from '@good/icons';

const getStartAndEndDayFromDate = (date: Date): DateRange => ({ start: startOfDay(date), end: endOfDay(date) });
const getStartAndEndWeekFromDate = (date: Date): DateRange => ({
  start: startOfWeek(date, { weekStartsOn: 1 }),
  end: endOfWeek(date, { weekStartsOn: 1 }),
});
const getStartAndEndFortnightFromDate = (date: Date): DateRange => ({
  start: startOfWeek(date, { weekStartsOn: 1 }),
  end: endOfWeek(addWeeks(date, 1), { weekStartsOn: 1 }),
});
const getStartAndEndNDaysFromDate = (date: Date, numberOfDays: number): DateRange => ({
  start: startOfDay(date),
  end: endOfDay(addDays(date, numberOfDays)),
});

const getSelectedRangeOptions = (rangeOption: RangeOption): SelectedRangeOption[] => {
  const today = new Date();

  switch (rangeOption) {
    case 'day':
      return [
        {
          label: 'Today',
          key: 'today',
          dateRange: getStartAndEndDayFromDate(today),
        },
        {
          label: 'Yesterday',
          key: 'yesterday',
          dateRange: getStartAndEndDayFromDate(addDays(today, -1)),
        },
        {
          label: 'Tomorrow',
          key: 'tomorrow',
          dateRange: getStartAndEndDayFromDate(addDays(today, 1)),
        },
      ];
    case 'week':
      return [
        {
          label: 'This week',
          key: 'this-week',
          dateRange: getStartAndEndWeekFromDate(today),
        },
        {
          label: 'Next week',
          key: 'next-week',
          dateRange: getStartAndEndWeekFromDate(addWeeks(today, 1)),
        },
        {
          label: 'Last week',
          key: 'last-week',
          dateRange: getStartAndEndWeekFromDate(addWeeks(today, -1)),
        },
        {
          label: 'Next 7 days',
          key: 'next-7-days',
          dateRange: getStartAndEndNDaysFromDate(today, 7),
        },
        {
          label: 'Previous 7 days',
          key: 'previous-7-days',
          dateRange: getStartAndEndNDaysFromDate(addDays(today, -7), 7),
        },
      ];
    case 'fortnight':
      return [
        {
          label: 'This fortnight',
          key: 'this-fortnight',
          dateRange: getStartAndEndFortnightFromDate(today),
        },
        {
          label: 'Next fortnight',
          key: 'next-fortnight',
          dateRange: getStartAndEndFortnightFromDate(addWeeks(today, 2)),
        },
        {
          label: 'Last fortnight',
          key: 'last-fortnight',
          dateRange: getStartAndEndFortnightFromDate(addWeeks(today, -2)),
        },
        {
          label: 'Next 14 days',
          key: 'next-14-days',
          dateRange: getStartAndEndNDaysFromDate(today, 14),
        },
        {
          label: 'Previous 14 days',
          key: 'previous-14-days',
          dateRange: getStartAndEndNDaysFromDate(addDays(today, -14), 14),
        },
      ];
    default:
      throw Error('Invalid range option');
  }
};

type SelectedRangeOption = {
  label: string;
  key: string;
  dateRange: DateRange;
};

export const SelectedRangeOptions = ({
  rangeOption,
  dateRange,
  onDateRangeChange,
}: {
  rangeOption: RangeOption;
  dateRange: DateRange;
  onDateRangeChange: (range: DateRange) => void;
}) => {
  const options = useMemo(() => getSelectedRangeOptions(rangeOption), [rangeOption]);

  const isCustomDateRange = useMemo(() => {
    const isASelectedRangeOption = options.some((option) => {
      return isEqual(option.dateRange.start, dateRange.start) && isEqual(option.dateRange.end, dateRange.end);
    });

    return !isASelectedRangeOption;
  }, [options, dateRange]);

  const isSelectedRange = (option: SelectedRangeOption) => {
    return isEqual(option.dateRange.start, dateRange.start) && isEqual(option.dateRange.end, dateRange.end);
  };

  return (
    <div className='flex flex-col gap-1'>
      {options.map((option) => (
        <Button
          key={option.key}
          aria-label={`Select ${option.label}`}
          onPress={() => onDateRangeChange(option.dateRange)}
          size='sm'
          className='justify-between'
          emphasis='quiet'
        >
          <span className='text-black font-light'>{option.label}</span>
          {isSelectedRange(option) && <Check />}
        </Button>
      ))}
      {isCustomDateRange && (
        <Text className='flex justify-between items-center px-4' size='sm'>
          <span>Custom</span>
          <div className='text-blue'>
            <Check />
          </div>
        </Text>
      )}
    </div>
  );
};
