// ---===[ Components ]===---
import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';

export function RosterLoadingIndicator() {
  return (
    <div
      className="position-fixed bg-white bordered rounded-big ph-medium pv-12 flex-row anim-fade-in-fast shadow-container"
      style={{ top: '190px', left: '43vw', zIndex: 100 }}
    >
      <div className="flex-row align-center">
        <Icon type="loading" className="text-color-blue-lighter text-size-small mr-small" />
        <Text size="large">Fetching data...</Text>
      </div>
    </div>
  );
}
