import { Children, forwardRef, isValidElement } from 'react';

import * as styles from './header.css';
import { Heading, HeadingProvider } from '../heading';
import { Text, TextProvider } from '../text';
import { twMerge } from '../utils';

import type { HTMLProps } from '../types';
import type { PropsWithChildren, ReactNode } from 'react';

const sizes = {
  xl: { heading: { size: 'xl' }, text: { size: 'sm' } },
  lg: { heading: { size: 'lg' }, text: { size: 'sm' } },
  md: { heading: { size: 'md' }, text: { size: 'sm' } },
  sm: { heading: { size: 'sm' }, text: { size: 'sm' } },
  xs: { heading: { size: 'xs' }, text: { size: 'xs' } },
  '2xs': { heading: { size: '2xs' }, text: { size: 'xs' } },
} as const;

type Size = keyof typeof sizes;

export type HeaderProps = PropsWithChildren<HTMLProps<HTMLDivElement>> & {
  /**
   * Controls the size of the components within the `Header`.
   */
  size: Size;
};

export const Header = forwardRef<HTMLDivElement, HeaderProps>(function Header(props, ref) {
  const { size, className, ...otherProps } = props;
  let heading;
  let text;
  const other: ReactNode[] = [];

  Children.forEach(props.children, (child) => {
    if (!isValidElement(child)) return;
    if (child.type === Heading) heading = child;
    else if (child.type === Text) text = child;
    else other.push(child);
  });

  const { heading: headingProps, text: textProps } = sizes[size];
  const { container, content } = styles.header();

  return (
    <section {...otherProps} ref={ref} className={twMerge(container(), className)}>
      <div className={content()}>
        <HeadingProvider {...headingProps}>{heading}</HeadingProvider>
        <TextProvider {...textProps} className="text-weak">
          {text}
        </TextProvider>
      </div>
      {other && <div>{other}</div>}
    </section>
  );
});
