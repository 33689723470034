import React, { forwardRef, PropsWithChildren, useRef } from 'react';
import { mergeProps } from '@react-aria/utils';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useToggleState } from '@react-stately/toggle';
import { useFocusRing } from '@react-aria/focus';
import { useSwitch, AriaSwitchProps } from '@react-aria/switch';
import { compose, css, BaseStyleProps, HTMLProps, useStyleProps, useContextProps } from '..';
import * as styles from './switch.css';
import { SwitchVariants } from './switch.css';
import { SwitchContext, SwitchIconProvider, SwitchProviderProps } from './switch-provider';
import { IconChecked } from '../icon';

export type SwitchProps = PropsWithChildren<
  BaseStyleProps & HTMLProps<HTMLInputElement> & AriaSwitchProps & SwitchVariants
> & {
  /**
   * disabled status
   */
  isDisabled?: boolean;

  /**
   * show icon check
   */
  isShowCheckedIcon?: boolean;
};

/**
 * @name
 * Switch
 *
 * @description
 * The Switch element is an interactive element activated by
 * a user.
 *
 * @example
 * <Switch
 *   size="medium"
 * />
 */

// eslint-disable-next-line react/display-name
export const Switch = forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
  const contextProps = useContextProps<SwitchProps & SwitchProviderProps>(SwitchContext, props);
  const { size = 'medium', isDisabled = false, isShowCheckedIcon = true } = contextProps;
  ref = useRef(null);
  const state = useToggleState(props);
  let { inputProps } = useSwitch(props, state, ref);
  const { focusProps } = useFocusRing();
  const isChecked = inputProps.checked;
  inputProps = mergeProps(inputProps, focusProps);
  const { styleProps } = useStyleProps(contextProps);
  return (
    <label style={{ position: 'relative' }}>
      <VisuallyHidden>
        <input {...inputProps} ref={ref} />
      </VisuallyHidden>
      <div
        className={compose(
          css(styles.switchCheckbox),
          styles.variants({
            size,
            isDisabled,
            isChecked,
          }),
          css(styleProps),
        )}
      >
        <span
          className={compose(
            styles.iconSwitch({
              isChecked,
              isDisabled,
            }),
            css(styleProps),
          )}
        >
          <SwitchIconProvider size={size}>
            {isShowCheckedIcon && (
              <IconChecked
                color={isChecked && !isDisabled ? '$accent' : isDisabled ? '$bodyLight2' : '$neutralDark1'}
              />
            )}
          </SwitchIconProvider>
        </span>
      </div>
    </label>
  );
});
