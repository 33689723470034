import { Icon, Input, notification, Upload } from 'antd';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { IWorkflowAttachment } from 'interfaces/workflow-interfaces';
import _, { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebaseApp from 'stores/firebase-app';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { FILE_EXTENSION_INVALID_ERROR_MESSAGE } from 'views/workflows/utils/constants';
import { validFileExtension } from 'views/workflows/utils/workflow-utils';

interface Props {
  isOpen: boolean;
  attachment?: IWorkflowAttachment;
  portalUser: typeof state.authStore.portalUser;
  workflowId: string;
  onClose: () => void;
  doCreateAttachment: typeof dispatch.workflowStore.doCreateAttachment;
  doUpdateAttachment: typeof dispatch.workflowStore.doUpdateAttachment;
  onRefreshAttachments(): void;
}

interface State {
  attachmentFile: any;
  description: string;
  errorMessage: string;
  isSubmitting: boolean;
}

class AddEditWorkflowAttachmentModal extends Component<Props, State> {
  state = {
    attachmentFile: null,
    description: '',
    errorMessage: null,
    isSubmitting: false,
  };

  private _onCloseModal = () => {
    this.setState({
      attachmentFile: null,
      description: '',
      errorMessage: null,
      isSubmitting: false,
    });
    this.props.onClose();
  };

  private _validateFile = (file) => {
    const isValidType = validFileExtension(file.name);

    this.setState({ errorMessage: null });
    if (!isValidType) {
      this.setState({
        errorMessage: FILE_EXTENSION_INVALID_ERROR_MESSAGE,
        attachmentFile: null,
      });
      return;
    } else {
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        this.setState({ errorMessage: 'File must be smaller than 25MB!' });
        return;
      } else {
        this.setState({ attachmentFile: file });
        return false;
      }
    }
  };

  private _onSubmitAttachment = async () => {
    const { attachment, portalUser, workflowId, doCreateAttachment, doUpdateAttachment, onRefreshAttachments } =
      this.props;
    const { attachmentFile, description } = this.state;
    const isEdit = !isEmpty(attachment);

    if (!attachmentFile) {
      this.setState({ errorMessage: 'Please attach an attachment' });
      return;
    }

    const payload = {
      workflowId,
      workflowAttachmentId: isEdit ? attachment.workflowAttachmentId : undefined,
      description: description.trim(),
      name: isEdit ? undefined : attachmentFile.name,
    };

    this.setState({ isSubmitting: true });

    if (isEdit) {
      const response: any = await doUpdateAttachment(payload);
      if (response.name) {
        notification.success({
          message: 'Attachment updated',
          description: 'You have successfully updated an attachment.',
        });
        onRefreshAttachments();
        this._onCloseModal();
      } else {
        notification.error({ message: response.meta.message });
      }
      this.setState({ isSubmitting: false });
      return;
    }

    const response: any = await doCreateAttachment(payload);

    if (response.workflowAttachmentDto.name) {
      notification.success({
        message: 'Attachment added',
        description: 'You have successfully added an attachment to this workflow.',
      });

      const metaData = {
        customMetadata: {
          documentId: response.workflowAttachmentDto.documentId,
          workflowAttachmentId: response.workflowAttachmentDto.workflowAttachmentId,
          serviceProviderId: portalUser.serviceProviderId,
          workflowId,
        },
      };

      const storageRef = ref(firebaseApp.storage, `${response.uploadBucketUrl}/${payload.name}`);
      const uploadFile = uploadBytesResumable(storageRef, attachmentFile, metaData);

      await uploadFile.on(
        'state_changed',
        () => ({}),
        (error) => {
          this.setState({ isSubmitting: false });
          notification.error({ message: 'Upload failed! Please try again.', description: error });
        },
        () => {
          this.setState({ isSubmitting: false });
          notification.success({ message: 'Document is currently scanning.' });
        },
      );

      this._onCloseModal();
      onRefreshAttachments();
    } else {
      notification.error({ message: response.meta.message });
      this.setState({ isSubmitting: false });
    }
  };

  private _onChangeDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  componentDidUpdate(prevProps: Props) {
    const { attachment } = this.props;

    if (!_.isEqual(prevProps.attachment, attachment)) {
      this.setState({
        attachmentFile: attachment ? attachment.name : '',
        description: attachment ? attachment.description : '',
      });
    }
  }

  render() {
    const { isOpen, attachment } = this.props;
    const { attachmentFile, errorMessage, description, isSubmitting } = this.state;

    const isEdit = !isEmpty(attachment);

    return (
      <ActionModal
        isOpen={isOpen}
        title={`${isEdit ? 'Edit' : 'Add'} attachment`}
        width="medium"
        onClose={this._onCloseModal}
      >
        <FieldLabel text="ATTACHMENT" />
        <div className="mt-small">
          {!attachmentFile && !isEdit && <Text type={'secondary'}>No attachment</Text>}
          {attachmentFile && <Text>{!isEdit ? attachmentFile.name : attachmentFile}</Text>}
        </div>
        {!isEdit && (
          <div className="mt-small">
            <Upload multiple={false} beforeUpload={this._validateFile} showUploadList={false}>
              {!attachmentFile && (
                <SecondaryButton icon={'upload'} iconPosition={'left'}>
                  Select file...
                </SecondaryButton>
              )}
              {attachmentFile && (
                <HyperlinkButton>
                  <Icon type="edit" /> Select a different file...
                </HyperlinkButton>
              )}
            </Upload>
          </div>
        )}
        {errorMessage && (
          <div className="mt-medium">
            <Text color="red">{errorMessage}</Text>
          </div>
        )}

        <div className="mt-medium">
          <FieldLabel text="DESCRIPTION" />
          <Input
            maxLength={1000}
            placeholder="Attachment description here..."
            onChange={this._onChangeDescription}
            value={description}
            className="mt-small pv-medium"
          />
        </div>

        <ActionModalFooter className="mt-x-large">
          <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._onSubmitAttachment} loading={isSubmitting}>
            {isEdit ? 'Save' : 'Add attachment'}
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateAttachment: dispatch.workflowStore.doCreateAttachment,
  doUpdateAttachment: dispatch.workflowStore.doUpdateAttachment,
});

export default connect(mapState, mapDispatch)(AddEditWorkflowAttachmentModal);
