import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Paragraph } from 'common-components/typography';
import ActionModal from 'common-components/modal/ActionModal';
import { PrimaryButton } from 'common-components/buttons';

interface IDuplicateModalProps {
  onClose: () => void;
  isOpen: boolean;
  duplicateMode?: string;
}

interface IDuplicateModalState {
  isLoading: boolean;
}

class DuplicateModal extends Component<IDuplicateModalProps, IDuplicateModalState> {
  state = {
    isLoading: false,
  };

  render() {
    const { duplicateMode } = this.props;
    return (
      <div>
        <ActionModal title={'Line Item already exists'} isOpen={this.props.isOpen} onClose={this.props.onClose}>
          <div className={'mt-small mb-x2-large'}>
            {duplicateMode === 'TRAVEL_ABT' ? (
              <Paragraph>
                You cannot add a Activity Based Transport line item to a booking if this line item already exists.
              </Paragraph>
            ) : duplicateMode === 'TRAVEL_PTNLC' ? (
              <Paragraph>
                You cannot add a Provider travel - non-labour costs line item to a booking if this line item already
                exists.
              </Paragraph>
            ) : (
              <>
                <Paragraph>
                  You cannot add a line item to a booking if a line item with the same Support Item and claim type
                  already exists.
                </Paragraph>
                <Paragraph>Please try again with a different support item/claim type.</Paragraph>
              </>
            )}
          </div>
          <div className={'mb-small'}>
            <Row type={'flex'} justify={'end'}>
              <Col>
                <PrimaryButton size={'large'} onClick={this.props.onClose}>
                  Close
                </PrimaryButton>
              </Col>
            </Row>
          </div>
        </ActionModal>
      </div>
    );
  }
}

export default DuplicateModal;
