/* BOOM-475 - fix linting errors */
import type { FocusableElement } from '@react-types/shared';
import type { DOMAttributes, PropsWithChildren } from 'react';
import React from 'react';
import type { BaseStyleProps, CSS, HTMLProps } from '../..';
import { compose, css, Text, useStyleProps } from '../..';
import * as styles from './modal-header.css';
import { Button } from '@good/components';
import { Cross } from '@good/icons';

export type ModalHeaderProps = PropsWithChildren<BaseStyleProps & HTMLProps<HTMLDivElement>> & {
  /**
   * Title props of react-aria - useDialog()
   */
  titleProps?: DOMAttributes<FocusableElement>;

  /**
   *  Whether a close (x) button is visible on top right of the modal dialog or not
   */
  closable?: boolean;

  /**
   * Close handle when trigger close button
   */
  onClose?(): void;

  /**
   * Custom close icon for modal.
   * Ex: <Modal {...otherProps} closeIcon={<Icon/>} >{...children}</Modal>
   */
  closeIcon?: React.ReactNode;

  /**
   * Header styles
   */
  headerStyles?: CSS;

  customCloseButtonStyles?: CSS;
};

/**
 * @name
 * Modal Header
 *
 * @example
 * <ModalHeader closable onClose={()=>{}} >
 *    Modal title
 * </ModalHeader>
 */
export const ModalHeader = (props: ModalHeaderProps) => {
  const { children, closable, titleProps, closeIcon, headerStyles, customCloseButtonStyles, onClose } = props;

  const { styleProps } = useStyleProps(props);

  // if (!children) {
  //   return null;
  // }

  return (
    <div {...titleProps} className={compose(css(styles.reset, styles.modalHeader), css(styleProps, headerStyles))}>
      {typeof children === 'string' ? (
        <Text color="$black" size="large" emphasis="bold" lineHeight={closable ? '' : '$leading950 !important'}>
          {children}
        </Text>
      ) : (
        children
      )}
      {closable && (
        <div className="flex flex-grow justify-end">
          <Button
            onPress={onClose}
            paddingLeft="$space100"
            paddingRight="$space100"
            emphasis="quiet"
            marginLeft="auto"
            onlyIcon
            size="md"
            kind="neutral"
            customStyles={customCloseButtonStyles}
          >
            {closeIcon ?? <Cross className="text-h-md text-gray-dark-2" />}
          </Button>
        </div>
      )}
    </div>
  );
};
