import React from 'react';
import { Route } from 'react-router-dom';
import MyProfileView from '.';
import { AccessTokensRoutes } from './access-tokens/routes';

export const MyProfileRoutes = () => (
  <>
    <Route exact path='/my-profile' component={MyProfileView} />
    <AccessTokensRoutes />
  </>
);
