import { IntakeFormComponentsTabs } from '../form-enum';
import { GENERAL_INFORMATION_TAB_ELEMENTS } from './general-information-constants';
import { ENQUIRIES_ELEMENTS } from './enquiries-constants';
import { CONTACTS_TAB_ELEMENTS } from './contact-constants';
import { CARE_INFORMATION_TAB_ELEMENTS } from './care-infomation-constants';
import { FUNDING_TAB_ELEMENTS } from './funding-constants';

export const INTAKE_FORM = [
  ...GENERAL_INFORMATION_TAB_ELEMENTS.map((value) => ({
    ...value,
    tab: IntakeFormComponentsTabs.GENERAL_INFORMATION_TAB_ELEMENTS,
  })),
  ...ENQUIRIES_ELEMENTS.map((value) => ({
    ...value,
    tab: IntakeFormComponentsTabs.ENQUIRIES_ELEMENTS,
  })),
  ...CONTACTS_TAB_ELEMENTS.map((value) => ({
    ...value,
    tab: IntakeFormComponentsTabs.CONTACTS_TAB_ELEMENTS,
  })),
  ...CARE_INFORMATION_TAB_ELEMENTS.map((value) => ({
    ...value,
    tab: IntakeFormComponentsTabs.CARE_INFORMATION_TAB_ELEMENTS,
  })),
  ...FUNDING_TAB_ELEMENTS.map((value) => ({
    ...value,
    tab: IntakeFormComponentsTabs.FUNDING_TAB_ELEMENTS,
  })),
];
