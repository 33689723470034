import React, { Component } from 'react';
import { Select } from 'antd';

interface IDayPickerProps {
  value?: number;
  size?: 'default' | 'small' | 'large';
  className?: string;
  isDisabled?: boolean;
  onChange?: any;
  width?: any;
}

class DayPicker extends Component<IDayPickerProps> {
  render() {
    const {
      value = null,
      size = 'default',
      className = '',
      onChange = null,
      isDisabled = false,
      width = '150px',
    } = this.props;
    return (
      <Select
        size={size}
        className={className}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        style={{ width }}
      >
        <Select.Option value={1}>Monday</Select.Option>
        <Select.Option value={2}>Tuesday</Select.Option>
        <Select.Option value={3}>Wednesday</Select.Option>
        <Select.Option value={4}>Thursday</Select.Option>
        <Select.Option value={5}>Friday</Select.Option>
        <Select.Option value={6}>Saturday</Select.Option>
        <Select.Option value={7}>Sunday</Select.Option>
      </Select>
    );
  }
}

export default DayPicker;
