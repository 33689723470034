import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook that determines if the current screen is printable.
 * It checks if there is only one element with the class 'printable-screen' in the document.
 * @returns A boolean indicating whether the current screen is printable.
 */
export function useIsPrintableScreen(): boolean {
  const location = useLocation();
  const [isPrintableScreen, setIsPrintableScreen] = useState(true);

  const updatePrintableScreen = useCallback(() => {
    const printableScreenElements = document.getElementsByClassName('printable-screen');
    setIsPrintableScreen(!(printableScreenElements.length > 1));
  }, []);

  useEffect(() => {
    updatePrintableScreen();
  }, [updatePrintableScreen, location]);

  return isPrintableScreen;
}
