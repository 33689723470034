import { ProgressBar } from '@blueprintjs/core';
import { Button, Icon, notification, Skeleton, Tabs, Upload } from 'antd';
import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import { SubTitle, Text } from 'common-components/typography';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { ICrumb } from 'interfaces/common-interface';
import { IUserInvolved, IWorkflowIssue } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import React, { PureComponent, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import firebaseApp from 'stores/firebase-app';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../stores/rematch/root-store';
import { ActiveWorkflowsStatus, WorkflowTemplateStepApprovalType } from 'utilities/enum-utils';
import '../../css/workflow-styles.css';
import { WORKFLOW_DETAIL_TAB_KEY } from '../../utils/constants';
import { checkManagerRole, getActiveStep, getIndividualMembers, getStepAvailable } from '../../utils/workflow-utils';
import AddUsersModal from './components/modals/AddUsersModal';
import ApproveWorkflowStepModal from './components/modals/ApproveWorkflowStepModal';
import CloseWorkflowModal from './components/modals/CloseWorkflowModal';
import ConfirmAddApproversModal from './components/modals/ConfirmAddApproversModal';
import CurrentApproversModal from './components/modals/CurrentApproversModal';
import FlagIssueModal from './components/modals/FlagIssueModal';
import ReopenWorkflowModal from './components/modals/ReopenWorkflowModal';
import ResolveIssueModal from './components/modals/ResolveIssueModal';
import SendBackModal from './components/modals/SendBackModal';
import UndoApprovalModal from './components/modals/UndoApprovalModal';
import CustomerAndTeamMemberModal from './components/side-bar/CustomerAndTeamMemberModal';
import WorkflowDetailsRightSideBar from './components/side-bar/WorkflowDetailsRightSideBar';
import WorkflowActivityLogPanel from './components/tab-panel/activity-log/WorkflowActivityLogPanel';
import WorkflowAttachmentsPanel from './components/tab-panel/attachments/WorkflowAttachmentsPanel';
import WorkflowCommentsPanel from './components/tab-panel/comments/WorkflowCommentsPanel';
import WorkflowLinkedFormPanel, {
  WorkflowLinkedForm,
  WorkflowLinkedAttachment,
} from './components/tab-panel/form-to-be-filled/WorkflowFormFilledPanel';
import WorkflowSubmittedFormPanel from './components/tab-panel/submitted-forms/WorkflowSubmittedFormPanel';
import WorkflowDetailsAlertSection from './components/WorkflowDetailsAlertSection';
import WorkflowDetailsCurrentApprovers from './components/WorkflowDetailsCurrentApprovers';
import WorkflowDetailsHeader, { WorkflowDetailsHeaderActions } from './components/WorkflowDetailsHeader';
import WorkflowDetailsSteps from './components/WorkflowDetailsSteps';
import moment from 'moment-timezone';

import styled from 'styled-components';
import { z } from 'zod';
import EditValueFormModal from '../../../form-builder/components/EditValueFormModal';
import { HyperlinkButton } from '../../../../common-components/buttons';
import { FILE_EXTENSION_INVALID_ERROR_MESSAGE } from 'views/workflows/utils/constants';
import { validFileExtension } from 'views/workflows/utils/workflow-utils';
import * as SeparatorPrimitive from '@radix-ui/react-separator';
import { FORM_STATUS } from 'views/account-management/utils/constants';
import { downloadFileFromUrl } from 'utilities/file-utils';

const Separator = styled(SeparatorPrimitive.Root)({
  background: '#ebebeb',
  height: 1,
  width: '100%',
});

const StepLabelContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '0.8fr 4.2fr',
  gap: '16px',
});

const ApproversContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  flexWrap: 'wrap',
});

function StepLabel(props: { label: string; children: React.ReactChild }) {
  let { label, children } = props;

  return (
    <StepLabelContainer>
      <div className='text-size-small text-weight-bold text-color-secondary'>{label}</div>
      <div>{children}</div>
    </StepLabelContainer>
  );
}

// //
// SCHEMA
// //

const approverSchema = z.object({
  id: z.string(),
  avatarUrl: z.optional(z.string().url().or(z.literal('')).or(z.null())),
  name: z.string(),
});

const attachmentSchema = z.object({
  name: z.string(),
  description: z.string().optional().default(''),
  url: z.string().nullable(),
});

const formSchema = z.object({
  id: z.optional(z.string()),
  name: z.optional(z.string()),
  data: z.optional(z.unknown()),
  elements: z.optional(z.array(z.unknown())),
});

let partialStepSchema = z.object({
  id: z.string(),
  details: z.object({
    name: z.string(),
    description: z.string().optional().default(''),
    stepNumber: z.number(),
    approvers: z.array(approverSchema).optional().default([]),
  }),
});

const attachmentActionSchema = z.object({
  actionType: z.literal('attachment'),
  attachment: attachmentSchema,
});

const formActionSchema = z.object({
  actionType: z.literal('form'),
  form: formSchema,
});

const stepActionSchema = z.discriminatedUnion('actionType', [
  z.object({
    actionType: z.literal('approval'),
  }),
  attachmentActionSchema,
  formActionSchema,
]);

const workflowSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string().optional().default(''),
});

type FormAction = z.infer<typeof formActionSchema>;
type AttachmentAction = z.infer<typeof attachmentActionSchema>;
type Form = z.infer<typeof formSchema>;
type Attachment = z.infer<typeof attachmentSchema>;
type Approver = z.infer<typeof approverSchema>;
type StepAction = z.infer<typeof stepActionSchema>;
type PartialStep = z.infer<typeof partialStepSchema>;
type Step = PartialStep & { details: StepAction };
type Workflow = z.infer<typeof workflowSchema>;

const StepContainer = styled.div({});

function ApproverBadge(props: Approver) {
  let { avatarUrl, id, name } = props;

  return (
    <div key={id} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
      <img src={avatarUrl} alt='' style={{ width: '32px', height: '32px', borderRadius: '4px' }} />
      <span style={{ fontSize: '14px' }}>{name}</span>
    </div>
  );
}

interface Props extends RouteComponentProps<{ workflowId: string }> {
  selectedWorkflow: typeof state.workflowStore.selectedWorkflow;
  selectedWorkflowAction: typeof state.workflowStore.selectedWorkflowAction;
  workflowLinkedForm: typeof state.workflowStore.workflowLinkedForm;
  portalUser: typeof state.authStore.portalUser;
  selectedSideNavMenuKeys: typeof state.navigationStore.selectedSideNavMenuKeys;

  setSelectedWorkflow: typeof dispatch.workflowStore.setSelectedWorkflow;
  setWorkflowLinkedForm: typeof dispatch.workflowStore.setWorkflowLinkedForm;
  doGetWorkflowDetail: typeof dispatch.workflowStore.doGetWorkflowDetail;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
  doAddUsersInvolved: typeof dispatch.workflowStore.doAddUsersInvolved;
  doAddApprovers: typeof dispatch.workflowStore.doAddApprovers;
  doUndoCompletionWorkflow: typeof dispatch.workflowStore.doUndoCompletionWorkflow;
  doReopenWorkflow: typeof dispatch.workflowStore.doReopenWorkflow;
  doCloseWorkflow: typeof dispatch.workflowStore.doCloseWorkflow;
  doApproveWorkflowStep: typeof dispatch.workflowStore.doApproveWorkflowStep;
  doFlagIssueWorkflow: typeof dispatch.workflowStore.doFlagIssueWorkflow;
  doResolveIssueWorkflow: typeof dispatch.workflowStore.doResolveIssueWorkflow;
  doSendBackStepWorkflow: typeof dispatch.workflowStore.doSendBackStepWorkflow;
  doAddLinkedFormAttachment: typeof dispatch.workflowStore.doAddLinkedFormAttachment;
  doGetLinkedFormAttachment: typeof dispatch.workflowStore.doGetLinkedFormAttachment;
  timezoneProvider: string;
}

interface State {
  isLoading: boolean;
  isUpdating: boolean;
  selectedTab: string;
  modalType?: 'CUSTOMER' | 'TEAM_MEMBER' | 'APPROVER';
  isOpenAddUsersModal: boolean;
  isOpenConfirmAddApproversModal: boolean;
  isOpenUndoApprovalModal: boolean;
  isOpenCloseWorkflowModal: boolean;
  isOpenReopenWorkflowModal: boolean;
  isOpenApproveWorkflowStepModal: boolean;
  isOpenFlagIssueModal: boolean;
  isOpenResolveIssueModal: boolean;
  isOpenSendBackModal: boolean;
  currentUsersInvolved: any[];
  currentApprovers: any[];
  attachment: any;
  defaultSelectedTab: string;
  timezone: string;
}

class WorkflowDetailsView extends PureComponent<Props, State> {
  state = {
    isLoading: false,
    isUpdating: false,
    selectedTab: WORKFLOW_DETAIL_TAB_KEY.SUBMITTED_FORMS,
    modalType: undefined,
    isOpenAddUsersModal: false,
    currentUsersInvolved: [],
    currentApprovers: [],
    isOpenConfirmAddApproversModal: false,
    isOpenFlagIssueModal: false,
    isOpenResolveIssueModal: false,
    isOpenSendBackModal: false,
    isOpenUndoApprovalModal: false,
    isOpenCloseWorkflowModal: false,
    isOpenReopenWorkflowModal: false,
    isOpenApproveWorkflowStepModal: false,
    attachment: null,
    defaultSelectedTab: 'form',
    timezone: moment.tz.guess(),
  };

  private _fetchSelectedWorkflow = async () => {
    this.setState({ isLoading: true });

    await this.props.doGetWorkflowDetail(this.props.match.params.workflowId);

    this.setState({ isLoading: false });
  };

  private _onChangeTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  private _onOpenViewCurrentApproverModal = () => this.setState({ modalType: 'APPROVER' });
  private _onCloseViewCurrentApproverModal = () => this.setState({ modalType: undefined });

  private _onOpenUsersInvolvedListModal = (modalType) => {
    this.setState({ modalType });
  };

  private _onCloseUsersInvolvedModal = () => {
    this.setState({
      modalType: undefined,
      currentUsersInvolved: this.props.selectedWorkflow.userInvolved || [],
    });
  };

  private _onToggleAddUsersModal = () => {
    this.setState({ isOpenAddUsersModal: !this.state.isOpenAddUsersModal });
  };

  private _onCloseConfirmAddApproversModal = () => {
    this.setState({
      isOpenConfirmAddApproversModal: !this.state.isOpenConfirmAddApproversModal,
      isOpenAddUsersModal: false,
    });
  };

  private _onSaveUsersInvolvedModal = async (users) => {
    this.setState({ isUpdating: true });

    const { modalType } = this.state;
    const isCustomersModal = modalType === 'CUSTOMER';
    const labelNotification = isCustomersModal ? 'Customers' : 'Team members';
    const { match, doAddUsersInvolved } = this.props;
    const workflowId = match.params.workflowId;
    const usersIds = users.map((user) => user.userId);
    const data = {
      members: usersIds,
      type: isCustomersModal ? 'customer' : 'worker',
      workflowId: workflowId,
    };

    const response: any = await doAddUsersInvolved(data);
    if (!!response.length || response.length === 0) {
      notification.success({
        message: `${labelNotification} involved edited`,
        description: `You have  successfully edited the ${labelNotification.toLowerCase()} involved in this workflow.`,
      });
      this._fetchSelectedWorkflow();
      this.setState({ isUpdating: false, modalType: undefined });
      return;
    }
    notification.error({ message: response.meta.message });
    this.setState({ isUpdating: false });
  };

  private _onRemoveUser = (user: IUserInvolved) => {
    const { currentUsersInvolved } = this.state;

    const newUsers = currentUsersInvolved.filter((item) => item.userId !== user.userId);

    this.setState({ currentUsersInvolved: newUsers });
  };

  private _onAddUsers = (users) => {
    if (this.state.modalType === 'APPROVER') {
      this.setState({
        currentApprovers: users,
        isOpenConfirmAddApproversModal: true,
      });
      return;
    }
    this.setState({ currentUsersInvolved: [...this.state.currentUsersInvolved, ...users], isOpenAddUsersModal: false });
  };

  private _onConfirmAddApprovers = async () => {
    this.setState({ isUpdating: true });

    const { selectedWorkflow, match, doAddApprovers } = this.props;
    const { currentApprovers } = this.state;
    const activeStep = selectedWorkflow && getActiveStep(selectedWorkflow.steps);
    const individualMembers = activeStep && getIndividualMembers(activeStep.approvers);
    const individualMembersIds = individualMembers && individualMembers.map((member) => member.userId);
    const currentApproversIds = currentApprovers.map((approver) => approver.userId);

    const workflowId = match.params.workflowId;
    const data = {
      approvers: [...individualMembersIds, ...currentApproversIds],
      workflowStepId: activeStep && activeStep.workflowStepId,
      stepNumber: activeStep && activeStep.stepNumber,
      workflowId: workflowId,
    };

    const response: any = await doAddApprovers(data);
    if (response.length) {
      notification.success({
        message: 'Approvers added',
        description: 'You have successfully added the approvers in this workflows.',
      });
      this._fetchSelectedWorkflow();
    } else {
      notification.error({ message: response.meta.message });
    }
    this.setState({ isUpdating: false, isOpenConfirmAddApproversModal: false, isOpenAddUsersModal: false });
  };

  private _getIndividualMembersOfActiveStep = (workflow) => {
    const activeStep = workflow ? getActiveStep(workflow.steps) : null;
    const isRoleApprovalType = activeStep && activeStep.approvalType === WorkflowTemplateStepApprovalType.ROLE;

    return (activeStep && getIndividualMembers(activeStep.approvers, isRoleApprovalType)) || [];
  };

  private _onOpenUndoApprovalModal = () => this.setState({ isOpenUndoApprovalModal: true });

  private _onCloseUndoApprovalModal = () => this.setState({ isOpenUndoApprovalModal: false });

  private _onConfirmUndoApprovalModal = async () => {
    const { doUndoCompletionWorkflow, selectedWorkflow } = this.props;

    this.setState({ isUpdating: true });

    const response: any = await doUndoCompletionWorkflow(selectedWorkflow.workflowId);

    if (response && response.isUndid) {
      notification.success({
        message: <Text weight='bolder'>Workflow undid</Text>,
        description: 'You have successfully undone this workflow',
      });
      this.setState({ isOpenUndoApprovalModal: false, isUpdating: false });
      this._fetchSelectedWorkflow();
      return;
    }

    notification.error({ message: response.meta.message });
    this.setState({ isUpdating: false });
  };

  private _onOpenCloseWorkflowModal = () => this.setState({ isOpenCloseWorkflowModal: true });

  private _onCloseCloseWorkflowModal = () => this.setState({ isOpenCloseWorkflowModal: false });

  private _onConfirmCloseWorkflowModal = async (value) => {
    const { doCloseWorkflow, selectedWorkflow } = this.props;

    const payload = {
      workflowId: selectedWorkflow.workflowId,
      closeReason: value,
    };

    this.setState({ isUpdating: true });

    const response: any = await doCloseWorkflow(payload);

    if (response && response.isClosed) {
      notification.success({
        message: <Text weight='bolder'>Workflow closed</Text>,
        description: 'You have successfully closed this workflow',
      });
      this.setState({ isOpenCloseWorkflowModal: false, isUpdating: false });
      this._fetchSelectedWorkflow();
      return;
    }

    notification.error({ message: response.meta.message });
    this.setState({ isUpdating: false });
  };

  private _onOpenReopenWorkflowModal = () => this.setState({ isOpenReopenWorkflowModal: true });

  private _onCloseReopenWorkflowModal = () => this.setState({ isOpenReopenWorkflowModal: false });

  private _onConfirmReopenWorkflowModal = async () => {
    const { selectedWorkflow, doReopenWorkflow } = this.props;

    this.setState({ isUpdating: true });

    const response: any = await doReopenWorkflow(selectedWorkflow.workflowId);

    if (response && response.isClosed) {
      notification.success({
        message: <Text weight='bolder'>Workflow opened</Text>,
        description: 'You have successfully opened this workflow',
      });
      this.setState({ isOpenReopenWorkflowModal: false, isUpdating: false });
      this._fetchSelectedWorkflow();
      return;
    }

    notification.error({ message: response.meta.message });
    this.setState({ isUpdating: false });
  };

  private _onOpenApproveWorkflowStepModal = () => this.setState({ isOpenApproveWorkflowStepModal: true });

  private _onCloseApproveWorkflowStepModal = () => this.setState({ isOpenApproveWorkflowStepModal: false });

  private _onConfirmApproveWorkflowStepModal = async () => {
    const { selectedWorkflow, doApproveWorkflowStep } = this.props;

    const activeStep = getActiveStep(selectedWorkflow.steps);

    const payload = {
      workflowId: selectedWorkflow.workflowId,
      workflowStepId: activeStep.workflowStepId,
      stepNumber: activeStep.stepNumber,
    };

    this.setState({ isUpdating: true });

    const isUploadSuccessfully = await this._upLoadFileLinkedForm();

    if (isUploadSuccessfully) {
      const response: any = await doApproveWorkflowStep(payload);

      if (response && response.isApproved) {
        notification.success({
          message: 'Workflow step approved',
          description: 'You have successfully approved this step of the workflow.',
        });

        this.setState({ isUpdating: false, isOpenApproveWorkflowStepModal: false });
        this._fetchSelectedWorkflow();
        return;
      }

      notification.error({ message: response.meta.message });
      this.setState({ isUpdating: false });
    }
  };

  private _upLoadFileLinkedForm = async () => {
    const { attachment } = this.state;
    const { portalUser, selectedWorkflow, doAddLinkedFormAttachment } = this.props;

    if (!attachment) {
      return true;
    }

    const activeStep = getActiveStep(selectedWorkflow.steps);

    const documentType = attachment.name ? 'attachment' : 'form';
    if (documentType === 'form') {
      return true;
    }

    try {
      const payload = {
        type: documentType,
        name: attachment.name,
        workflowStepId: activeStep.workflowStepId,
        workflowId: selectedWorkflow && selectedWorkflow.workflowId,
      };

      const response: any = await doAddLinkedFormAttachment(payload);

      if (response && response.attachmentData) {
        const metadata = {
          customMetadata: {
            documentId: response.attachmentData.documentId,
            serviceProviderId: portalUser.serviceProviderId,
            workflowAttachmentId: response.attachmentData.workflowAttachmentId,
            workflowStepId: response.attachmentData.workflowStepId,
            workflowId: selectedWorkflow && selectedWorkflow.workflowId,
          },
        };

        const storageRef = ref(firebaseApp.storage, `${response.uploadBucketUrl}/${attachment.name}`);
        const uploadFile = uploadBytesResumable(storageRef, attachment, metadata);

        await uploadFile.on(
          'state_changed',
          () => ({}),
          (error) => {
            notification.error({ message: 'Upload failed! Please try again.', description: error });
          },
          () => {
            notification.success({
              message: `Attachment is currently scanning.`,
            });
          },
        );
      }
    } catch (e) {
      notification.error({ message: 'Upload failed! Please try again.', description: e });
      return false;
    }

    this.setState({ attachment: null });
    return true;
  };

  private _onFlagIssue = async (issue: IWorkflowIssue) => {
    const { selectedWorkflow, doFlagIssueWorkflow } = this.props;

    const activeStep = selectedWorkflow && getActiveStep(selectedWorkflow.steps);

    const payload = {
      workflowId: selectedWorkflow.workflowId,
      reportedOnStep: activeStep.workflowStepId,
      issueMessage: issue.message,
    };

    this.setState({ isUpdating: true });

    const response: any = await doFlagIssueWorkflow(payload);

    if (response && response.isFlagged) {
      notification.success({
        message: <Text weight='bolder'>Issue flagged</Text>,
        description: 'You have successfully flagged the issue with this workflow.',
      });
      this.setState({ isUpdating: false, isOpenFlagIssueModal: false });
      this._fetchSelectedWorkflow();
      return;
    }

    notification.error({ message: response.meta.message });
    this.setState({ isUpdating: false });
  };

  private _onResolveIssue = async () => {
    const { selectedWorkflow, doResolveIssueWorkflow } = this.props;

    const payload = {
      workflowId: selectedWorkflow.workflowId,
      workflowIssueId: selectedWorkflow.issue.workFlowIssueId,
    };

    this.setState({ isUpdating: true });

    const response: any = await doResolveIssueWorkflow(payload);

    if (response && response.isResolved) {
      notification.success({
        message: <Text weight='bolder'>Workflow issue resolved</Text>,
        description: 'You have successfully resolved the issue with this workflow',
      });

      this.setState({ isUpdating: false, isOpenResolveIssueModal: false });
      this._fetchSelectedWorkflow();
      return;
    }

    notification.error({ message: response.meta.message });
    this.setState({ isUpdating: false });
  };

  private _onSendBack = async (payload) => {
    const { selectedWorkflow, doSendBackStepWorkflow } = this.props;

    this.setState({ isUpdating: true });

    const response: any = await doSendBackStepWorkflow(payload);

    if (response && response.isSentBack) {
      const stepDetail = selectedWorkflow.steps.find((step) => step.workflowStepId === payload.sendBackToStep);

      notification.success({
        message: <Text weight='bolder'>Workflow sent back</Text>,
        description: (
          <Text>
            You have successfully sent this workflow back to <Text weight='bolder'>Step {stepDetail.stepNumber}.</Text>
          </Text>
        ),
      });

      this.setState({ isUpdating: false, isOpenSendBackModal: false });
      this._fetchSelectedWorkflow();
      return;
    }

    notification.error({ message: response.meta.message });
    this.setState({ isUpdating: false });
  };

  private _checkIsApproverStep = () => {
    const { selectedWorkflow, portalUser } = this.props;
    const activeStep = selectedWorkflow ? getActiveStep(selectedWorkflow.steps) : null;

    return (
      activeStep &&
      (activeStep.approvers.some((one) => one.userId === portalUser.userId) ||
        activeStep.approvalType === WorkflowTemplateStepApprovalType.ANYONE)
    );
  };

  private _disableLinkedFormTab = () => {
    const { selectedWorkflow, portalUser } = this.props;

    if (
      !selectedWorkflow ||
      !selectedWorkflow.steps.length ||
      selectedWorkflow.status !== ActiveWorkflowsStatus.INPROGRESS
    ) {
      return true;
    }

    return !this._checkIsApproverStep() && !checkManagerRole(portalUser);
  };

  private _disableTooltip = () => {
    const { workflowLinkedForm } = this.props;
    const isAttachment = !!this.state.attachment;

    if (
      workflowLinkedForm &&
      ((workflowLinkedForm.formData && workflowLinkedForm.status === FORM_STATUS.COMPLETED) ||
        (!workflowLinkedForm.formVersionId && !workflowLinkedForm.documentId))
    ) {
      return true;
    }

    if (_.isEmpty(workflowLinkedForm)) {
      return true;
    }

    if (workflowLinkedForm.uploadedAttachment) {
      return true;
    }

    if (isAttachment) {
      return true;
    }
  };

  async componentDidUpdate(prevProps) {
    const { selectedWorkflow, doGetLinkedFormAttachment, match, timezoneProvider, history } = this.props;
    const { state } = this.props.location;
    const previousMatch = prevProps.match;

    if ((previousMatch && previousMatch.params.workflowId !== match.params.workflowId) || (state && state.refresh)) {
      this.setState({ isLoading: true });

      if (state && state.refresh) {
        history.replace(this.props.location.pathname, {});
      }
      await doGetLinkedFormAttachment({});

      if (state && state.selectedTab) {
        this.setState({ selectedTab: state.selectedTab });
      }
      this.setState({ isLoading: false });
    }

    if (!_.isEqual(prevProps.selectedWorkflow, selectedWorkflow)) {
      const individualMembers = this._getIndividualMembersOfActiveStep(selectedWorkflow);

      await doGetLinkedFormAttachment({});

      this.setState({
        currentUsersInvolved: selectedWorkflow.userInvolved || [],
        currentApprovers: individualMembers,
        attachment: null,
      });

      if (selectedWorkflow?.bookingDetail?.attendanceId) {
        this.setState({
          timezone: selectedWorkflow.bookingDetail.timezone,
        });
      } else if (selectedWorkflow?.triggerType === 'MANUAL') {
        this.setState({
          timezone: timezoneProvider,
        });
      }
    }
  }

  async componentDidMount() {
    const { state } = this.props.location;
    const { timezoneProvider, selectedWorkflow } = this.props;

    this.props.setSelectedSideNavMenuKeys(['/workflows']);

    await this._fetchSelectedWorkflow();

    const isDisableLinkedFormTab = this._disableLinkedFormTab();

    this.setState({
      selectedTab:
        state && state.selectedTab
          ? state.selectedTab
          : isDisableLinkedFormTab
          ? WORKFLOW_DETAIL_TAB_KEY.SUBMITTED_FORMS
          : WORKFLOW_DETAIL_TAB_KEY.SUBMITTED_FORMS,
    });

    if (selectedWorkflow?.bookingDetail?.attendanceId) {
      this.setState({
        timezone: selectedWorkflow.bookingDetail.timezone,
      });
    } else if (selectedWorkflow?.triggerType === 'MANUAL') {
      this.setState({
        timezone: timezoneProvider,
      });
    }
  }

  componentWillUnmount() {
    this.props.setSelectedWorkflow({ workflowDto: null, action: null });
    this.props.setWorkflowLinkedForm(null);
  }

  render() {
    const { history, selectedWorkflow, selectedWorkflowAction, workflowLinkedForm, portalUser } = this.props;

    const {
      isLoading,
      isUpdating,
      selectedTab,
      modalType,
      isOpenAddUsersModal,
      currentUsersInvolved,
      isOpenConfirmAddApproversModal,
      currentApprovers,
      isOpenFlagIssueModal,
      isOpenResolveIssueModal,
      isOpenSendBackModal,
      isOpenUndoApprovalModal,
      isOpenCloseWorkflowModal,
      isOpenReopenWorkflowModal,
      isOpenApproveWorkflowStepModal,
      attachment,
      timezone,
    } = this.state;

    const customerInvolved = currentUsersInvolved && currentUsersInvolved.filter((user) => user.type === 'customer');
    const teamMemberInvolved = currentUsersInvolved && currentUsersInvolved.filter((user) => user.type === 'worker');
    const customerInvolvedProps =
      selectedWorkflow &&
      selectedWorkflow.userInvolved &&
      selectedWorkflow.userInvolved.filter((user) => user.type === 'customer');
    const teamMemberInvolvedProps =
      selectedWorkflow &&
      selectedWorkflow.userInvolved &&
      selectedWorkflow.userInvolved.filter((user) => user.type === 'worker');

    const isCustomerInvolvedModal = modalType === 'CUSTOMER';
    const usersDataModal = isCustomerInvolvedModal ? customerInvolved : teamMemberInvolved;
    const usersDataProps = isCustomerInvolvedModal ? customerInvolvedProps : teamMemberInvolvedProps;
    const disabledEditUsersModal = _.isEqual(usersDataProps || [], usersDataModal || []);
    const individualMembers = this._getIndividualMembersOfActiveStep(selectedWorkflow);

    const editableUsers = selectedWorkflowAction && selectedWorkflowAction.canChangeMember;
    const isDisableAction = selectedWorkflow && selectedWorkflow.status !== ActiveWorkflowsStatus.INPROGRESS;
    const isDisableLinkedFormTab = this._disableLinkedFormTab();

    if (isLoading || !selectedWorkflow) {
      return (
        <div className='view-container'>
          <div className='pb-medium'>
            <div className='pb-medium'>
              <Text>Fetching workflow details configuration...</Text>
            </div>
            <ProgressBar />
          </div>
          <Skeleton loading={isLoading} />
        </div>
      );
    }

    const crumbs: ICrumb[] = [
      {
        title: 'Incident workflows',
        target: '/workflows',
        isGoBack: history.location.state && history.location.state.backableToList,
      },
      {
        title: selectedWorkflow.name,
      },
    ];

    const activeStep = getActiveStep(selectedWorkflow.steps);
    const stepAvailable = getStepAvailable(selectedWorkflow.steps);

    let step: Step;
    let workflow: Workflow = {};

    if (selectedWorkflow) {
      console.log({ selectedWorkflow });

      workflow = workflowSchema.parse({
        description: selectedWorkflow.description,
        id: selectedWorkflow.workflowId,
        name: selectedWorkflow.name,
      } as Workflow);
    }

    if (workflowLinkedForm && activeStep) {
      let isAttachment = !!workflowLinkedForm.documentId && 'attachment';
      let isForm = !!workflowLinkedForm.formVersionId && 'form';
      let isApproval = !isAttachment && !isForm && 'approval';
      let actionType = isAttachment || isForm || isApproval;

      const partialStep = partialStepSchema.parse({
        id: activeStep.workflowStepId,
        details: {
          name: activeStep.name,
          approvers: (activeStep.approvers ?? []).map((a) =>
            approverSchema.parse({
              id: a.userId,
              name: a.name,
              avatarUrl: a.avatar,
            } as Approver),
          ),
          stepNumber: activeStep.stepNumber,
          // TODO (Mitch):
          // Add description when available on step.
        },
      } as PartialStep);

      console.log({ workflowLinkedForm });

      const action = stepActionSchema.parse({
        actionType,
        form: {
          id: workflowLinkedForm.uniqueId,
          name: workflowLinkedForm.formName,
          data: workflowLinkedForm.formData,
          elements: workflowLinkedForm.formElements,
          versionId: workflowLinkedForm.formVersionId,
        } as Form,
        attachment: {
          name: workflowLinkedForm.name,
          url: workflowLinkedForm.url,
        } as Attachment,
      } as StepActionSchema);

      step = { ...partialStep, details: { ...partialStep.details, ...action } };
    }

    console.log({ step });

    // if (!step) {
    //   return null;
    // }

    return (
      <div
        style={{ overflow: 'auto', height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}
        id='content-container'
      >
        <BreadcrumbNav icon='home' theme='filled' crumbs={crumbs} isBordered={false} className='pl-small' />

        <div className='width-full flex-column flex-1'>
          {/* Header */}
          <div className='pv-small ph-large bg-white'>
            <WorkflowDetailsHeader
              activeStep={activeStep}
              workflowDetail={selectedWorkflow}
              selectedWorkflowAction={selectedWorkflowAction}
              disableTooltip={this._disableTooltip()}
              timezone={portalUser.timezone}
              onFlagIssue={() => this.setState({ isOpenFlagIssueModal: true })}
              onResolveIssue={() => this.setState({ isOpenResolveIssueModal: true })}
              onSendBack={() => this.setState({ isOpenSendBackModal: true })}
              onUndoCompletion={this._onOpenUndoApprovalModal}
              onCloseWorkflow={this._onOpenCloseWorkflowModal}
              onReopen={this._onOpenReopenWorkflowModal}
              onApprove={this._onOpenApproveWorkflowStepModal}
              workflowLinkedForm={workflowLinkedForm}
            />

            <StepContainer className='shadow-down rounded'>
              <WorkflowDetailsSteps workflowDetail={selectedWorkflow} />
              <div style={{ padding: '24px 0' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: '0 24px',
                    }}
                  >
                    {step && (
                      <span style={{ fontSize: '16px', maxWidth: '52ch' }}>
                        <span className='text-weight-bold'>Step {step.details.stepNumber}: </span>
                        {activeStep.name}
                      </span>
                    )}

                    <WorkflowDetailsAlertSection selectedWorkflow={selectedWorkflow} activeStep={activeStep} />

                    <div>
                      <WorkflowDetailsHeaderActions
                        activeStep={activeStep}
                        workflowDetail={selectedWorkflow}
                        selectedWorkflowAction={selectedWorkflowAction}
                        disableTooltip={this._disableTooltip()}
                        timezone={portalUser.timezone}
                        onFlagIssue={() => this.setState({ isOpenFlagIssueModal: true })}
                        onResolveIssue={() => this.setState({ isOpenResolveIssueModal: true })}
                        onSendBack={() => this.setState({ isOpenSendBackModal: true })}
                        onUndoCompletion={this._onOpenUndoApprovalModal}
                        onCloseWorkflow={this._onOpenCloseWorkflowModal}
                        onReopen={this._onOpenReopenWorkflowModal}
                        onApprove={this._onOpenApproveWorkflowStepModal}
                        workflowLinkedForm={workflowLinkedForm}
                      />
                    </div>
                  </div>

                  {step && step.details.description && (
                    <div>
                      <Separator />
                      <div className='p-large'>
                        <StepLabel label='Instructions'>
                          <div style={{ maxWidth: '52ch' }}>{step.details.description}</div>
                        </StepLabel>
                      </div>
                      <Separator />
                    </div>
                  )}

                  {step && step.details.actionType === 'form' && (
                    <div>
                      <Separator />
                      <div className='p-large'>
                        <StepLabel label='Fill a form'>
                          <WorkflowLinkedForm
                            isDisableAction={isDisableAction}
                            attachmentFile={attachment}
                            workflowLinkedForm={workflowLinkedForm}
                            selectedWorkflow={selectedWorkflow}
                            selectedWorkflowAction={selectedWorkflowAction}
                            addAttachmentFile={(file) => this.setState({ attachment: file })}
                            timezone={timezone}
                          />
                        </StepLabel>
                      </div>
                      <Separator />
                    </div>
                  )}

                  {step && step.details.actionType === 'attachment' && (
                    <div>
                      <Separator />
                      <div className='p-large'>
                        <StepLabel label='Upload Document'>
                          <WorkflowLinkedAttachment
                            isDisableAction={isDisableAction}
                            attachmentFile={attachment}
                            workflowLinkedForm={workflowLinkedForm}
                            selectedWorkflow={selectedWorkflow}
                            selectedWorkflowAction={selectedWorkflowAction}
                            addAttachmentFile={(file) => this.setState({ attachment: file })}
                          />
                        </StepLabel>
                      </div>
                      <Separator />
                    </div>
                  )}

                  {step && step.details.approvers.length > 0 && (
                    <div style={{ padding: '0 24px' }}>
                      <StepLabel label='People who approve'>
                        <ApproversContainer>
                          {step.details.approvers.map((a) => (
                            <ApproverBadge {...a} />
                          ))}
                        </ApproversContainer>
                      </StepLabel>
                    </div>
                  )}
                </div>
              </div>
            </StepContainer>
          </div>

          <div className='bg-white'>
            <Tabs
              activeKey={selectedTab}
              animated={true}
              onChange={this._onChangeTab}
              className={'workflow-detail-tab'}
            >
              {/* {!isDisableLinkedFormTab && (
                <Tabs.TabPane tab={WORKFLOW_DETAIL_TAB_KEY.LINKED_FORM} key={WORKFLOW_DETAIL_TAB_KEY.LINKED_FORM} />
              )} */}
              <Tabs.TabPane
                tab={WORKFLOW_DETAIL_TAB_KEY.SUBMITTED_FORMS}
                key={WORKFLOW_DETAIL_TAB_KEY.SUBMITTED_FORMS}
              />
              <Tabs.TabPane tab={WORKFLOW_DETAIL_TAB_KEY.ATTACHMENTS} key={WORKFLOW_DETAIL_TAB_KEY.ATTACHMENTS} />
              <Tabs.TabPane tab={WORKFLOW_DETAIL_TAB_KEY.COMMENTS} key={WORKFLOW_DETAIL_TAB_KEY.COMMENTS} />
              <Tabs.TabPane tab={WORKFLOW_DETAIL_TAB_KEY.ACTIVITY_LOG} key={WORKFLOW_DETAIL_TAB_KEY.ACTIVITY_LOG} />
            </Tabs>
          </div>

          {/* Content */}

          <div className='bg-quaternary flex flex-1 flex-wrap'>
            <div className='m-large flex-1'>
              {/* {selectedTab === WORKFLOW_DETAIL_TAB_KEY.LINKED_FORM && !isDisableLinkedFormTab && (
                <WorkflowLinkedFormPanel
                  isDisableAction={isDisableAction}
                  attachmentFile={attachment}
                  workflowLinkedForm={workflowLinkedForm}
                  selectedWorkflow={selectedWorkflow}
                  selectedWorkflowAction={selectedWorkflowAction}
                  addAttachmentFile={(file) => this.setState({ attachment: file })}
                  timezone={timezone}
                />
              )} */}

              {selectedTab === WORKFLOW_DETAIL_TAB_KEY.SUBMITTED_FORMS && (
                <WorkflowSubmittedFormPanel
                  selectedWorkflow={selectedWorkflow}
                  selectedWorkflowAction={selectedWorkflowAction}
                  isDisableAction={isDisableAction}
                  timezone={timezone}
                />
              )}

              {selectedTab === WORKFLOW_DETAIL_TAB_KEY.ATTACHMENTS && (
                <WorkflowAttachmentsPanel
                  isDisableAction={isDisableAction}
                  workflowId={selectedWorkflow.workflowId}
                  activeStepNumber={stepAvailable ? stepAvailable.stepNumber : undefined}
                />
              )}

              {selectedTab === WORKFLOW_DETAIL_TAB_KEY.COMMENTS && (
                <WorkflowCommentsPanel isDisableAction={isDisableAction} selectedWorkflow={selectedWorkflow} />
              )}

              {selectedTab === WORKFLOW_DETAIL_TAB_KEY.ACTIVITY_LOG && (
                <WorkflowActivityLogPanel selectedWorkflow={selectedWorkflow} />
              )}
            </div>

            <WorkflowDetailsRightSideBar
              selectedWorkflow={selectedWorkflow}
              customerInvolved={customerInvolvedProps}
              teamMemberInvolved={teamMemberInvolvedProps}
              timezone={portalUser.timezone}
              editableUsers={editableUsers}
              onOpenUsersListModal={this._onOpenUsersInvolvedListModal}
              isDisableAction={isDisableAction}
            />
          </div>
        </div>

        <CurrentApproversModal
          isOpen={modalType === 'APPROVER'}
          step={activeStep}
          editable={editableUsers}
          onClose={this._onCloseViewCurrentApproverModal}
          onAddApprover={this._onToggleAddUsersModal}
        />

        <CustomerAndTeamMemberModal
          isOpen={modalType && modalType !== 'APPROVER'}
          editable={editableUsers}
          modalType={modalType}
          users={usersDataModal || []}
          isAddingUsers={isUpdating}
          disabled={disabledEditUsersModal}
          onOpenAddUsersModal={this._onToggleAddUsersModal}
          onClose={this._onCloseUsersInvolvedModal}
          onConfirmModal={this._onSaveUsersInvolvedModal}
          onRemoveUser={this._onRemoveUser}
        />

        {modalType && (
          <AddUsersModal
            users={modalType !== 'APPROVER' ? usersDataModal : individualMembers}
            userType={modalType}
            isOpen={isOpenAddUsersModal}
            onClose={this._onToggleAddUsersModal}
            onConfirm={this._onAddUsers}
          />
        )}

        {isOpenAddUsersModal && (
          <ConfirmAddApproversModal
            isOpen={isOpenConfirmAddApproversModal}
            approvers={currentApprovers}
            isAdding={isUpdating}
            onClose={this._onCloseConfirmAddApproversModal}
            onConfirm={this._onConfirmAddApprovers}
          />
        )}
        <FlagIssueModal
          isOpen={isOpenFlagIssueModal}
          issue={selectedWorkflow.issue}
          loading={isUpdating}
          onClose={() => this.setState({ isOpenFlagIssueModal: false })}
          onFlagIssue={this._onFlagIssue}
        />

        <ResolveIssueModal
          isOpen={isOpenResolveIssueModal}
          loading={isUpdating}
          onClose={() => this.setState({ isOpenResolveIssueModal: false })}
          onResolve={this._onResolveIssue}
        />

        <SendBackModal
          isOpen={isOpenSendBackModal}
          selectedWorkflow={selectedWorkflow}
          activeStep={activeStep}
          loading={isUpdating}
          onClose={() => this.setState({ isOpenSendBackModal: false })}
          onSendBack={this._onSendBack}
        />

        <UndoApprovalModal
          isOpen={isOpenUndoApprovalModal}
          workflowDetail={selectedWorkflow}
          isLoading={isUpdating}
          onClose={this._onCloseUndoApprovalModal}
          onConfirm={this._onConfirmUndoApprovalModal}
        />

        <CloseWorkflowModal
          isOpen={isOpenCloseWorkflowModal}
          isLoading={isUpdating}
          onClose={this._onCloseCloseWorkflowModal}
          onConfirm={this._onConfirmCloseWorkflowModal}
        />

        <ReopenWorkflowModal
          isOpen={isOpenReopenWorkflowModal}
          isLoading={isUpdating}
          onClose={this._onCloseReopenWorkflowModal}
          onConfirm={this._onConfirmReopenWorkflowModal}
        />

        <ApproveWorkflowStepModal
          isOpen={isOpenApproveWorkflowStepModal}
          isLoading={isUpdating}
          onClose={this._onCloseApproveWorkflowStepModal}
          onConfirm={this._onConfirmApproveWorkflowStepModal}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  selectedWorkflow: state.workflowStore.selectedWorkflow,
  selectedWorkflowAction: state.workflowStore.selectedWorkflowAction,
  workflowLinkedForm: state.workflowStore.workflowLinkedForm,
  selectedSideNavMenuKeys: state.navigationStore.selectedSideNavMenuKeys,
  timezoneProvider: state.companyStore.companyDataLite?.timezone,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedWorkflow: dispatch.workflowStore.setSelectedWorkflow,
  setWorkflowLinkedForm: dispatch.workflowStore.setWorkflowLinkedForm,
  doGetWorkflowDetail: dispatch.workflowStore.doGetWorkflowDetail,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
  doAddUsersInvolved: dispatch.workflowStore.doAddUsersInvolved,
  doAddApprovers: dispatch.workflowStore.doAddApprovers,
  doUndoCompletionWorkflow: dispatch.workflowStore.doUndoCompletionWorkflow,
  doReopenWorkflow: dispatch.workflowStore.doReopenWorkflow,
  doCloseWorkflow: dispatch.workflowStore.doCloseWorkflow,
  doApproveWorkflowStep: dispatch.workflowStore.doApproveWorkflowStep,
  doFlagIssueWorkflow: dispatch.workflowStore.doFlagIssueWorkflow,
  doResolveIssueWorkflow: dispatch.workflowStore.doResolveIssueWorkflow,
  doSendBackStepWorkflow: dispatch.workflowStore.doSendBackStepWorkflow,
  doAddLinkedFormAttachment: dispatch.workflowStore.doAddLinkedFormAttachment,
  doGetLinkedFormAttachment: dispatch.workflowStore.doGetLinkedFormAttachment,
});

export default connect(mapState, mapDispatch)(WorkflowDetailsView);

type FormStepProps = {
  step: PartialStep & { details: FormAction };
  workflow: Workflow;
};

function FormStep(props: FormStepProps) {
  let {
    workflowStore: { doAddLinkedFormAttachment },
  } = useDispatch<IRootDispatch>();

  let { step, workflow } = props;
  let [isOpen, setOpen] = useState(false);

  function handleOnToggleModal() {
    setOpen((o) => !o);
  }

  async function handleOnSave(id: string, elementValues: unknown, status: string) {
    await doAddLinkedFormAttachment({
      type: 'form',
      formData: elementValues,
      formVersionId: step.details.form.id,
      workflowStepId: step.id,
      workflowId: workflow.id,
      status,
    });

    handleOnToggleModal();
  }

  if (step.details?.actionType === 'form') {
    let formContent = {
      title: { formTitle: step.details.form.name, formDescription: '' },
      elements: step.details.form.elements,
    };

    return (
      <>
        <EditValueFormModal
          isOpen={isOpen}
          onClose={handleOnToggleModal}
          mode='EDIT'
          formElements={formContent}
          elementValues={step.details.form.data}
          formId={null}
          onSave={handleOnSave}
        />

        <StepLabel label='Fill a form'>
          <div style={{ fontSize: '14px', display: 'flex', flexDirection: 'column' }}>
            {step.details.form.name}

            <Button
              type='link'
              style={{ alignSelf: 'start', fontSize: '14px', marginLeft: '-8px', color: '#106BA3 !important' }}
              size='small'
              onClick={handleOnToggleModal}
              color='#106BA3'
            >
              Edit form
            </Button>
          </div>
        </StepLabel>
      </>
    );
  } else return null;
}

// function validateFile(file: any)  {
//   const isValidType = validFileExtension(file.name);

//   if (!isValidType) {
//     this.setState({
//       errorMessage: FILE_EXTENSION_INVALID_ERROR_MESSAGE,
//     });
//     return false;
//   } else {
//     const isLt2M = file.size / 1024 / 1024 < 25;
//     if (!isLt2M) {
//       handleAddError({ errorMessage: 'File must be smaller than 25MB!' });
//       return false;
//     } else {
//       addAttachmentFile(file);
//       return true;
//     }
//   }
// };

type AttachmentStepProps = {
  step: PartialStep & { details: AttachmentAction };
  workflow: Workflow;
  handleAddAttachment: (attachment: unknown) => void;
  handleAddError: () => void;
};

function AttachmentStep(props: AttachmentStepProps) {
  let { step, workflow } = props;

  async function handleOnDownload() {
    downloadFileFromUrl({
      documentUrl: step.details.attachment.url,
      documentName: step.details.attachment.name,
    });
  }

  function handleOnBeforeUpload(file: { name: string; size: number }) {
    let { name, size } = file;
    let isExtensionValid = validFileExtension(name);
    let isSizeValid = size / 1024 / 1024 < 25;

    if (!isExtensionValid) return { success: false, message: FILE_EXTENSION_INVALID_ERROR_MESSAGE };
    if (!isSizeValid) return { success: false, message: 'File must be smaller than 25mb.' };
    return { success: true };
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <HyperlinkButton onClick={handleOnDownload} className='word-break-all'>
        <Icon type='download' className='text-color-blue-action mr-small' />
        <span>{details.attachment.name}</span>
      </HyperlinkButton>
      <Upload multiple={false} beforeUpload={handleOnBeforeUpload} showUploadList={false}>
        <HyperlinkButton>
          <Icon type='plus' className='mr-small' />
          <span className='text-size-regular'>Upload completed document</span>
        </HyperlinkButton>
      </Upload>
    </div>
  );
}

/* <div className="flex-column mt-large">
  <Text size="small" color="secondary" weight="bolder">
    ATTACHMENT
  </Text>

  <HyperlinkButton onClick={() => this._onDownloadAttachment(workflowLinkedForm)} className="word-break-all">
    <Icon type="download" className="text-color-blue-action mr-small" />
    {workflowLinkedForm.name}
  </HyperlinkButton>
</div>; */

/* <div>
<a download={step.details.attachment.id} href={step.details.attachment.url}>
  {step.details.attachment.name}
</a>
<AttachmentStep />
</div> */

/* <HyperlinkButton onClick={() => this._onDownloadAttachment(workflowLinkedForm)} className="word-break-all">
  <Icon type="download" className="text-color-blue-action mr-small" />
  {workflowLinkedForm.name}
</HyperlinkButton>; */
