import { Collapse, Popover, ProgressBar } from '@blueprintjs/core';
import { SubTitle, Text } from 'common-components/typography';
import _ from 'lodash';
import { Avatar, Empty } from 'antd';
import { IconButton } from 'common-components/buttons';
import React from 'react';
import { ISessionCustomer } from 'interfaces/session-interfaces';

import moment from 'moment-timezone';
import { StatusTag } from 'common-components/tags';
import { PaymentStatusLabel } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { IGroupServiceSession } from 'interfaces/service-interfaces';
import { timeZone } from 'interfaces/timezone-type';

function CustomerBookingItem({
  booking,
  onNavigate,
  session,
  timezone,
}: {
  booking: ISessionCustomer;
  onNavigate: any;
  session: IGroupServiceSession;
  timezone: timeZone;
}) {
  const { customerAvatarUrl, firstName, lastName, status, startDateTime, endDateTime, paymentStatus, billingTotal } =
    booking;

  const { serviceId, serviceDateTimeId } = session;

  const timeDisplay = `${moment.tz(startDateTime, timezone).format('h:mm A')} - ${moment
    .tz(endDateTime, timezone)
    .format('h:mm A')}`;

  const goToSession = () => onNavigate(`/group-service/${serviceId}/session/details/${serviceDateTimeId}`);

  return (
    <div className="bg-white rounded-big overflow-hidden bordered line-height-100 mb-12">
      <div className="flex-row align-center">
        <div className="p-medium">
          <Avatar src={customerAvatarUrl} />
        </div>

        <div className="p-medium flex-1">
          <Text lineHeight={100}>
            {firstName} {lastName}
          </Text>
        </div>

        <div className="p-medium flex-1">
          <Text lineHeight={100}>{timeDisplay}</Text>
        </div>
        <div className="p-medium flex-1">
          {/*<TextTag content="Confirmed" color={'blue'} theme="light" />*/}
          <StatusTag status={status} size={'small'} />
        </div>
        <div className="p-medium flex-1">
          {/*<TextTag content="Paid" color={'blue'} theme="light" />*/}
          {paymentStatus ? PaymentStatusLabel[paymentStatus] : '-'}
        </div>

        <div className="p-medium flex-1">
          <Text lineHeight={100}>{billingTotal ? CommonUtils.formatPrice(billingTotal) : '-'}</Text>
        </div>

        <div className="p-medium">
          <Popover
            position="bottom-right"
            content={
              <ActionMenu>
                <ActionMenuItem text="View session..." onClick={goToSession} />
              </ActionMenu>
            }
          >
            <IconButton
              icon={'ellipsis'}
              size="default"
              iconColor={'blue-action'}
              color={'white'}
              bordered={true}
              className="border-standard-gray"
              onClick={() => {}}
            />
          </Popover>
        </div>
      </div>
    </div>
  );
}

interface IBookingsForSessionPanelProps {
  isCustomersPanelOpen: boolean;
  isFetching: boolean;
  sessionBookings?: {
    serviceDateTimeId: string;
    bookings: ISessionCustomer[];
  };
  session: IGroupServiceSession;
  onNavigate: any;
  timezone: timeZone;
}

interface IBookingsForSessionState {
  isCustomersPanelOpen: boolean;
  isFetching: boolean;
  sessionBookings: ISessionCustomer;
}

export class BookingsForSessionPanel extends React.Component<IBookingsForSessionPanelProps, IBookingsForSessionState> {
  state = { isCustomersPanelOpen: false, isFetching: false, sessionBookings: null };

  render() {
    const { isCustomersPanelOpen, isFetching, sessionBookings, onNavigate, session, timezone } = this.props;
    const bookings: ISessionCustomer[] = _.get(sessionBookings, 'bookings');

    return (
      <>
        <Collapse isOpen={isCustomersPanelOpen}>
          <div className="bg-secondary p-medium bordered-top bordered-left bordered-right">
            {isFetching && (
              <div
                className="p-large bg-quaternary anim-slide-down rounded-big shadow-box"
                style={{
                  maxWidth: '1200px',
                  margin: 'auto',
                }}
              >
                <div className="mb-small">
                  <Text color="secondary">Fetching bookings...</Text>
                </div>
                <ProgressBar />
              </div>
            )}

            {!isFetching && (
              <div
                className="p-medium bg-quaternary rounded-big shadow-box anim-slide-down"
                style={{
                  maxWidth: '1200px',
                  margin: 'auto',
                }}
              >
                {!_.isEmpty(bookings) && (
                  <>
                    <div className="mb-medium">
                      <SubTitle>Customers in this session</SubTitle>
                    </div>

                    {_.map(bookings, (booking) => (
                      <CustomerBookingItem
                        booking={booking}
                        key={booking.attendanceId}
                        onNavigate={onNavigate}
                        session={session}
                        timezone={timezone}
                      />
                    ))}
                  </>
                )}

                {_.isEmpty(bookings) && (
                  <div className="text-align-center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} className="mv-small" />
                    <Text color="secondary">No bookings found for this session.</Text>
                  </div>
                )}
              </div>
            )}
          </div>
        </Collapse>
      </>
    );
  }
}
