import React, { Component } from 'react';
import { Col, Form, Icon, Input, notification, Radio, Row, Select, Spin } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { ICustomer } from 'interfaces/customer-interfaces';
import { DebtorType, PlanManagerType } from 'utilities/enum-utils';
import { FormComponentProps } from 'antd/es/form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CommonUtils from 'utilities/common-utils';
import { validateEmailFormat } from 'utilities/validator-utils';
import { type NewRelicBrowser, WithNewRelicBrowser } from 'integrations/new-relic-browser';

const searchAutocompleteOptions = {
  componentRestrictions: { country: ['au'] },
};

interface IAddEditPlanManagerModalProps extends FormComponentProps {
  closeModal: () => void;
  isOpen: boolean;
  isEdit: boolean;
  selectedCustomer: ICustomer;
  debtors: typeof state.accountStore.debtors;
  goDebtorHomepage: (id) => void;
  setDebtors: typeof dispatch.accountStore.setDebtors;
  doFetchDebtorList: typeof dispatch.accountStore.doFetchDebtorList;
  doAddCustomerDebtor: typeof dispatch.customersStore.doAddCustomerDebtor;
  doEditDebtorGeneralInfo: typeof dispatch.accountStore.doEditDebtorGeneralInfo;
  doGetCustomer: typeof dispatch.customersStore.doGetCustomer;
  newRelicBrowser: NewRelicBrowser;
}

interface IAddEditPlanManagerModalState {
  isLoading: boolean;
  planManagerType: string;
  step: number;
  isSearching: boolean;
  selectedPlanManager: any;
  debtorName: string;
  organisationName: string;
  debtorNumber: string;
  email: string;
  contactNumber: string;
  address: string;
  selectedLattitude: any;
  selectedLongitude: any;
  selectedAddress: any;
  debtorNameError: boolean;
  debtorNumberError: boolean;
  contactNumberError: boolean;
  emailError: string | undefined;
}

class AddEditPlanManagerModal extends Component<IAddEditPlanManagerModalProps, IAddEditPlanManagerModalState> {
  state = {
    isLoading: false,
    planManagerType: null,
    step: 1,
    isSearching: false,
    selectedPlanManager: null,
    debtorName: null,
    organisationName: null,
    debtorNumber: null,
    email: null,
    contactNumber: null,
    address: '',
    selectedLattitude: null,
    selectedLongitude: null,
    selectedAddress: null,
    debtorNameError: false,
    debtorNumberError: false,
    contactNumberError: false,
    emailError: undefined,
  };

  override async componentDidMount(): Promise<void> {
    await this.props.doFetchDebtorList({ search: '', sortByAlphabet: true, hideArchived: true, hideInactive: true });
  }

  componentDidUpdate(
    prevProps: Readonly<IAddEditPlanManagerModalProps>,
    prevState: Readonly<IAddEditPlanManagerModalState>,
    snapshot?: any,
  ) {
    const { selectedCustomer } = this.props;

    if (prevProps.isOpen !== this.props.isOpen && this.props.isEdit) {
      let newStep = 1;
      if (this.props.isEdit === true && selectedCustomer.planManager.type === DebtorType.EXTERNAL_PLAN) {
        newStep = 3;
      }
      this.setState({
        debtorName: selectedCustomer.planManager ? selectedCustomer.planManager.name : '',
        organisationName: selectedCustomer.planManager ? selectedCustomer.planManager.organisationName : '',
        debtorNumber: selectedCustomer.planManager ? selectedCustomer.planManager.debtorNumber : '',
        email: selectedCustomer.planManager ? selectedCustomer.planManager.email : '',
        contactNumber: selectedCustomer.planManager ? selectedCustomer.planManager.contactNumber : '',
        address:
          selectedCustomer.planManager && selectedCustomer.planManager.address
            ? selectedCustomer.planManager.address
            : '',
        step: newStep,
      });
    } else if (prevProps.isOpen !== this.props.isOpen && !this.props.isEdit) {
      this.setState({
        debtorName: null,
        organisationName: null,
        debtorNumber: null,
        email: null,
        contactNumber: null,
        address: '',
        step: 1,
      });
    }
  }

  private _closeModal = () => {
    this.setState({
      isLoading: false,
      planManagerType: null,
      step: 1,
      isSearching: false,
      selectedPlanManager: null,
      selectedAddress: null,
      debtorNameError: false,
      debtorNumberError: false,
      contactNumberError: false,
      emailError: undefined,
    });
    this.props.closeModal();
  };

  private _handleSelectChange = (value) => {
    this.setState({ selectedPlanManager: value });
  };

  private _onClickSave = async () => {
    const {
      address,
      selectedLattitude,
      selectedLongitude,
      selectedAddress,
      contactNumber,
      debtorName,
      organisationName,
      email,
      debtorNumber,
      planManagerType,
      selectedPlanManager,
    } = this.state;
    const { doAddCustomerDebtor, doEditDebtorGeneralInfo, doGetCustomer, selectedCustomer, isEdit } = this.props;
    let hasError = false;
    this.setState({
      debtorNameError: false,
      contactNumberError: false,
      debtorNumberError: false,
      emailError: undefined,
    });
    if (selectedPlanManager) {
      const payload = {
        customerUserId: selectedCustomer.userId,
        debtorId: selectedPlanManager,
        debtorType: DebtorType.EXTERNAL_PLAN,
        isNewDebtor: false,
      };
      try {
        await doAddCustomerDebtor(payload);
        notification.success({
          message: `Plan manager added to profile`,
          description: (
            <>
              <p>You have successfully associated an existing plan manager with this customer.</p>
              <GhostButton
                paddingSize='none'
                size='large'
                onClick={() => this.props.goDebtorHomepage(selectedPlanManager)}
              >
                Go to debtor profile
              </GhostButton>
            </>
          ),
        });
        this._closeModal();
      } catch {
        notification.error({ message: 'Oops, an error has occurred, please try again.' });
      }
    } else if (planManagerType === PlanManagerType.INTERNAL) {
      try {
        await doAddCustomerDebtor({
          customerUserId: selectedCustomer.userId,
          debtorType: DebtorType.INTERNAL_PLAN,
          isNewDebtor: true,
        });
        notification.success({
          message: `Customer now internally managed`,
          description: <p>This customer is now managed by your internal plan management team.</p>,
        });
        this._closeModal();
      } catch (e) {
        notification.error({ message: 'Oops, an error has occurred, please try again.' });
      }
    } else {
      if (!debtorName) {
        this.setState({ debtorNameError: true });
        hasError = true;
      }
      if (!email) {
        this.setState({ emailError: 'Please enter a valid email address' });
        hasError = true;
      }
      if (email) {
        if (!validateEmailFormat(email)) {
          this.setState({ emailError: 'Please enter a valid email address' });
          hasError = true;
        }
      }
      if (!this._validateMobileNumber(contactNumber)) {
        this.setState({ contactNumberError: true });
        hasError = true;
      }
      let debtorAddress = {};
      if (selectedAddress) {
        debtorAddress = {
          ...selectedAddress,
          geoLat: selectedLattitude,
          geoLng: selectedLongitude,
          fullAddress: address,
        };
      }

      if (!hasError) {
        if (!isEdit) {
          const payload = {
            customerUserId: selectedCustomer.userId,
            newDebtor: {
              debtorName: debtorName,
              organisationName: organisationName,
              debtorNumber: debtorNumber,
              debtorContactNumber: contactNumber,
              debtorEmail: email,
              debtorCountryCode: contactNumber ? 'AU' : null,
              debtorAddress: debtorAddress,
            },
            debtorType: DebtorType.EXTERNAL_PLAN,
            isNewDebtor: true,
          };

          try {
            const debtorId = await doAddCustomerDebtor(payload);
            notification.success({
              message: `Plan manager created`,
              description: (
                <>
                  <p>You have successfully created a new plan manager and associated it with this customer.</p>
                  <GhostButton paddingSize='none' size='large' onClick={() => this.props.goDebtorHomepage(debtorId)}>
                    Go to debtor profile
                  </GhostButton>
                </>
              ),
            });
            this._closeModal();
          } catch (e) {
            this.handleError(e);
          }
        } else {
          const payload = {
            debtorId: selectedCustomer.planManager.debtorId,
            debtorName: debtorName,
            organisationName: organisationName,
            debtorNumber: debtorNumber,
            contactNumber: contactNumber,
            email: email,
            contactNumberCountryCode: contactNumber ? 'AU' : null,
            address: debtorAddress,
          };

          try {
            const result = await doEditDebtorGeneralInfo(payload);
            if (result) {
              await doGetCustomer({ userId: selectedCustomer.userId });
              notification.success({
                message: `Details successfully changed`,
                description: (
                  <>
                    <p>You have successfully changed the contact details of this manager.</p>
                  </>
                ),
              });
              this._closeModal();
            }
          } catch (e) {
            this.handleError(e);
          }
        }
      }
    }
  };

  private handleError = (e) => {
    let capturedKnownError = false;
    if ('meta' in e && 'message' in e.meta) {
      if (e.meta.message.includes('The provided debtor email already exists')) {
        capturedKnownError = true;
        this.setState({ emailError: 'This debtor already exists. Please enter a different email address.' });
      }

      if (e.meta.message.includes('The provided debtor number already exists')) {
        capturedKnownError = true;
        this.setState({ debtorNumberError: true });
      }
    }

    if (!capturedKnownError) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
      this.props.newRelicBrowser?.noticeError(e);
    }
  };

  private _handleChange = (address) => {
    this.setState({ address });
  };

  private _handleSelect = (address) => {
    this.setState({ address: address });
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          selectedAddress: CommonUtils.createAddressFromGoogleMap(results[0].address_components),
        });
      })
      .catch((error) => console.error('Error', error));
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => this.setState({ selectedLattitude: lat, selectedLongitude: lng }))
      .catch((error) => console.error('Error', error));
  };

  private _validateMobileNumber = (value) => {
    const region = 'AU';

    if (!_.isEmpty(value)) {
      let phoneNumber = parsePhoneNumberFromString(value, region);
      if (phoneNumber === undefined || !phoneNumber || !phoneNumber.isValid()) {
        return false;
      }
    }
    return true;
  };

  private _onChangeOption = (event) => {
    this.setState({ planManagerType: event.target.value });
  };

  private _searchText = async (txt) => {
    const { doFetchDebtorList } = this.props;
    //this is where we search for planManagers
    try {
      await doFetchDebtorList({ search: txt, sortByAlphabet: true, hideArchived: true, hideInactive: true });
      this.setState({ isSearching: false });
    } catch (e) {
      console.log(e);
    }
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    if (e.length >= 1) {
      this.setState({ isSearching: true });
      this._debounceSearch(e);
    } else if (e.length === 0) {
      this.props.setDebtors([]);
    }
  };

  render() {
    const { debtors, isEdit } = this.props;
    const {
      isLoading,
      planManagerType,
      step,
      isSearching,
      selectedPlanManager,
      debtorName,
      organisationName,
      debtorNumber,
      email,
      contactNumber,
      debtorNameError,
      contactNumberError,
      emailError,
      debtorNumberError,
    } = this.state;
    return (
      <div>
        <ActionModal
          title={`${isEdit ? 'Edit' : 'Add'} plan manager`}
          isOpen={this.props.isOpen}
          onClose={this._closeModal}
          width={step === 4 || step === 5 || step === 6 || step === 7 ? 'small' : 'medium'}
        >
          {step === 1 && (
            <div>
              <div className='mt-medium'>
                <SubTitle>Plan Manager type</SubTitle>
                <Radio.Group value={planManagerType} onChange={this._onChangeOption} style={{ width: '100%' }}>
                  <Row className='mv-medium' type='flex' align='middle'>
                    <Col span={2} className='pv-medium'>
                      <Radio value={PlanManagerType.EXTERNAL}></Radio>
                    </Col>
                    <Col span={22}>
                      <Text weight='bold'>External plan manager</Text>
                      <br />
                      <Text>Select this option if the customer has an external plan manager.</Text>
                    </Col>
                  </Row>
                  <Row className='mv-medium' type='flex' align='middle'>
                    <Col span={2} className='pv-medium'>
                      <Radio value={PlanManagerType.INTERNAL}></Radio>
                    </Col>
                    <Col span={22}>
                      <Text weight='bold'>Internal plan manager</Text>
                      <br />
                      <Text>Select this option if the customer’s plan manager is your business.</Text>
                    </Col>
                  </Row>
                </Radio.Group>
              </div>
              <div className={'mb-small mt-large'}>
                <Row type={'flex'} className={'justify-end'}>
                  <Col className='mr-large'>
                    <GhostButton size='large' onClick={this._closeModal}>
                      Cancel
                    </GhostButton>
                  </Col>
                  <Col>
                    <PrimaryButton
                      size='large'
                      loading={isLoading}
                      disabled={!planManagerType}
                      onClick={
                        planManagerType === PlanManagerType.INTERNAL
                          ? this._onClickSave
                          : () => this.setState({ step: 2 })
                      }
                    >
                      {planManagerType === PlanManagerType.INTERNAL ? 'Save' : 'Continue'}
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {step === 2 && (
            <>
              <Row className='mt-medium'>
                <SubTitle>Select a plan manager</SubTitle>
              </Row>
              <Row className='mt-small'>
                <Text>Choose from existing plan managers in your GoodHuman workspace.</Text>
              </Row>
              <Row className='mt-small'>
                <Select
                  showSearch={true}
                  placeholder='Select an existing plan manager'
                  notFoundContent={isSearching ? <Spin size='small' /> : null}
                  onSearch={this._onEnterSearchText}
                  onChange={this._handleSelectChange}
                  optionLabelProp={'title'}
                  filterOption={false}
                  style={{ width: '400px' }}
                >
                  {_.map(debtors, (manager) => (
                    <Select.Option value={manager.debtorId} title={manager.debtorName} key={manager.debtorId}>
                      <div>
                        <Row>
                          <Text>{manager.debtorName}</Text>
                        </Row>
                        <Row>
                          <Text color={'secondary'}>{manager.debtorEmail}</Text>
                        </Row>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Row>

              <Row className='mt-medium'>
                <GhostButton
                  size={'large'}
                  icon={'plus'}
                  paddingSize={'none'}
                  onClick={() => this.setState({ step: 3, selectedPlanManager: null })}
                >
                  Add plan manager details
                </GhostButton>
              </Row>
              <Row type={'flex'} className={'justify-end mt-x-large'}>
                <Col className='mr-large'>
                  <GhostButton size='large' onClick={() => this.setState({ step: 1 })}>
                    Go back
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton
                    size='large'
                    loading={isLoading}
                    disabled={!selectedPlanManager}
                    onClick={this._onClickSave}
                  >
                    Save
                  </PrimaryButton>
                </Col>
              </Row>
            </>
          )}
          {step === 3 && (
            <>
              <div className={'mt-small'}>
                <div>
                  <SubTitle>Plan Manager Name</SubTitle>
                  <div className='mt-x2-small'>
                    <Input
                      style={{ width: '50%' }}
                      placeholder={'Enter name'}
                      size={'large'}
                      value={debtorName}
                      onChange={(e) => this.setState({ debtorName: e.target.value })}
                    />
                  </div>
                  {debtorNameError && (
                    <Text size='regular' color='red' className='mt-small'>
                      Please enter a plan manager name
                    </Text>
                  )}
                </div>
                <div className='mt-medium'>
                  <div className='flex'>
                    <SubTitle>Organisation Name</SubTitle>
                    <Text color='secondary' size='small' className='ml-small'>
                      (Optional)
                    </Text>
                  </div>
                  <div className='mt-x2-small'>
                    <Input
                      style={{ width: '50%' }}
                      placeholder={'Enter organisation name'}
                      size={'large'}
                      value={organisationName}
                      onChange={(e) => this.setState({ organisationName: e.target.value })}
                    />
                  </div>
                </div>
                <div className='mt-medium'>
                  <div className='flex'>
                    <SubTitle>Plan Manager ID</SubTitle>
                    <Text color='secondary' size='small' className='ml-small'>
                      (Optional)
                    </Text>
                  </div>
                  <div className='mt-x2-small'>
                    <Input
                      style={{ width: '50%' }}
                      placeholder={'Enter ID'}
                      size={'large'}
                      value={debtorNumber}
                      onChange={(e) => this.setState({ debtorNumber: e.target.value })}
                      className={debtorNumberError ? 'border-red' : undefined}
                    />
                  </div>
                  {debtorNumberError && (
                    <Text size='regular' color='red' className='mt-small'>
                      This debtor ID already exists, please use a unique ID.
                    </Text>
                  )}
                </div>
                <div className='mt-medium'>
                  <SubTitle>Email</SubTitle>
                  <div className='mt-x2-small'>
                    <Input
                      style={{ width: '50%' }}
                      placeholder={'Enter email'}
                      size={'large'}
                      value={email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      className={emailError ? 'border-red' : undefined}
                    />
                  </div>
                  {emailError && (
                    <Text size='regular' color='red' className='mt-small'>
                      {emailError}
                    </Text>
                  )}
                </div>

                <div className='mt-medium'>
                  <div className='flex'>
                    <SubTitle>Contact Number</SubTitle>
                    <Text color='secondary' size='small' className='ml-small'>
                      (Optional)
                    </Text>
                  </div>
                  <div className={'flex-row mt-x2-small'}>
                    <Select disabled size='large' className='mr-small' style={{ width: '110px' }} defaultValue='AU'>
                      <Select.Option value={'AU'}>+61 (AU)</Select.Option>
                    </Select>

                    <Input
                      size={'large'}
                      placeholder={'Enter number'}
                      style={{ width: '200px' }}
                      value={contactNumber}
                      onChange={(e) => this.setState({ contactNumber: e.target.value })}
                      className={contactNumberError ? 'border-red' : undefined}
                    />
                  </div>
                  {contactNumberError && (
                    <Text size='regular' color='red' className='mt-small'>
                      Please enter a valid phone number
                    </Text>
                  )}
                </div>
                <div className='mt-medium mb-large'>
                  <div className='flex'>
                    <SubTitle>Address</SubTitle>
                    <Text color='secondary' size='small' className='ml-small'>
                      (Optional)
                    </Text>
                  </div>
                  <div className={'mt-x2-small'}>
                    <PlacesAutocomplete
                      value={this.state.address}
                      onSelect={this._handleSelect}
                      onChange={this._handleChange}
                      shouldFetchSuggestions={this.state.address.length > 2}
                      searchOptions={searchAutocompleteOptions}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <Input
                            value={this.state.address}
                            size={'large'}
                            {...getInputProps({
                              placeholder: 'Search for an address ...',
                              className: 'location-search-input',
                            })}
                          />
                          <div className='autocomplete-dropdown-container'>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                              const style = suggestion.active
                                ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer',
                                    borderTop: '1px ridge grey',
                                    borderLeft: '1px ridge grey',
                                    borderRight: '1px ridge grey',
                                    padding: '10px',
                                  }
                                : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer',
                                    borderTop: '1px ridge grey',
                                    borderLeft: '1px ridge grey',
                                    borderRight: '1px ridge grey',
                                    padding: '10px',
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>
                                    <Icon type={'environment'} /> {suggestion.description}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <Row type={'flex'} className={'justify-end mt-x-large'}>
                  <Col className='mr-large'>
                    <GhostButton size='large' loading={isLoading} onClick={() => this.setState({ step: 2 })}>
                      Go back
                    </GhostButton>
                  </Col>
                  <Col>
                    <PrimaryButton
                      disabled={!debtorName || !email}
                      size='large'
                      loading={isLoading}
                      onClick={this._onClickSave}
                    >
                      Save
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setDebtors: dispatch.accountStore.setDebtors,
  doFetchDebtorList: dispatch.accountStore.doFetchDebtorList,
  doAddCustomerDebtor: dispatch.customersStore.doAddCustomerDebtor,
  doEditDebtorGeneralInfo: dispatch.accountStore.doEditDebtorGeneralInfo,
  doGetCustomer: dispatch.customersStore.doGetCustomer,
});

const mapState = (state: IRootState) => ({
  debtors: state.accountStore.debtors,
});

export default WithNewRelicBrowser(
  connect(mapState, mapDispatch)(Form.create<IAddEditPlanManagerModalProps>()(AddEditPlanManagerModal)),
);
