import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Box2 = forwardRef(function Box2(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.49 3.173c-.261.114-.581.291-1.068.562l-5 2.778c-.475.263-.799.444-1.04.605l3 1.59L14.957 4.5l-1.378-.765c-.487-.27-.807-.448-1.069-.562-.247-.108-.389-.135-.509-.135s-.262.027-.51.135Zm5.368.668-2.551-1.417-.03-.017c-.45-.25-.832-.462-1.167-.609-.358-.156-.712-.26-1.11-.26-.398 0-.752.104-1.11.26-.335.147-.718.36-1.167.61l-.03.016-5 2.777-.03.018c-.476.264-.88.488-1.196.708-.337.233-.625.494-.837.855-.213.361-.301.74-.342 1.147-.038.383-.038.846-.038 1.39V14.681c0 .543 0 1.006.038 1.389.041.408.13.786.342 1.147.212.36.5.622.837.855.316.22.72.444 1.195.709l.031.017 5 2.777.03.017c.45.25.832.462 1.167.609.358.156.712.26 1.11.26.398 0 .752-.104 1.11-.26.335-.147.718-.36 1.167-.61l.03-.015 5-2.778.03-.017c.476-.265.88-.489 1.196-.709.337-.233.625-.494.837-.855.212-.361.301-.739.342-1.147.038-.383.038-.846.038-1.39V9.319c0-.543 0-1.006-.038-1.389-.041-.408-.13-.786-.342-1.147-.212-.36-.5-.622-.837-.855-.316-.22-.72-.444-1.195-.708l-.031-.018-2.436-1.353-.013-.007ZM16.5 5.358 8.964 9.544 12 11.151l7.618-4.033a16.404 16.404 0 0 0-1.04-.605L16.5 5.358ZM3.75 9.354c0-.355 0-.636.007-.87l7.493 3.968v8.261a24.38 24.38 0 0 1-.828-.448l-5-2.778c-.515-.285-.853-.474-1.1-.646-.234-.162-.335-.275-.4-.384-.063-.108-.113-.252-.141-.535-.03-.3-.031-.687-.031-1.276V9.354Zm9.828 10.911c-.348.194-.61.34-.828.448v-8.261l7.493-3.967c.007.233.007.514.007.869v5.292c0 .59 0 .976-.03 1.276-.03.283-.079.427-.143.535-.064.109-.165.222-.399.384-.247.172-.585.36-1.1.646l-5 2.778Z"
        fill="currentColor"
      />
    </Icon>
  );
});
