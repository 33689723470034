import React from 'react';
import { Message } from 'design-components/message';
import { EValidationStatus, IValidations } from 'design-components/types';

interface Props {
  /**
   * Rules of validation
   */
  validations: IValidations;
}

export const useMultiValidation = (props: Props) => {
  const { validations } = props;

  const messageTone = {
    [EValidationStatus.Uncheck]: 'normal',
    [EValidationStatus.Valid]: 'positive',
    [EValidationStatus.Invalid]: 'critical',
  };

  const isInvalid = validations?.some((validate) => validate.status === 'invalid');

  const validationFields = (isPasswordMessage?: boolean) => {
    if (!validations?.length) {
      return null;
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {validations?.length &&
          validations.map((error, index) =>
            error.message ? (
              <Message
                isPasswordMessage={isPasswordMessage}
                marginTop={index === 0 ? '$space125' : '$space50'}
                tone={messageTone[error.status] as 'default' | 'positive' | 'critical' | 'normal'}
                key={error.message}
              >
                {error.message}
              </Message>
            ) : (
              <></>
            ),
          )}
      </div>
    );
  };

  return { validationFields, isInvalid };
};
