import React, { useEffect, useState } from 'react';
import { Text } from 'common-components/typography';
import { TeamPermissionType } from 'utilities/enum-utils';
import { getTeamPermissionName } from 'views/account-management/utils/permission-utils';
import { IPermissions } from 'interfaces/account-interfaces';
import { Modal, Button, RadioGroup } from 'design-components';
import ActionSelectedItem from 'views/team-v2/team-details/advanced-permission/ActionSelectedItem';

type setSelectedOptionProps = {
  teamPermission: string;
};

type TeamSectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  setSelectedOption: ({ teamPermission }: setSelectedOptionProps) => void;
  permissions: IPermissions;
};

export const TeamSectionModal = ({ isOpen, onClose, setSelectedOption, permissions }: TeamSectionModalProps) => {
  const TeamPermissionTypeAllTeamMembers = 'All Team Members';
  const [haveAccessTo, setHaveAccessTo] = useState<TeamPermissionType | string>(TeamPermissionType.NO_ACCESS);
  const [theirPermission, setTheirPermission] = useState<TeamPermissionType | string | undefined>(undefined);

  useEffect(() => {
    const currentPermission = getTeamPermissionName(permissions);

    setHaveAccessTo(
      currentPermission === TeamPermissionType.NO_ACCESS ? currentPermission : TeamPermissionTypeAllTeamMembers,
    );
    setTheirPermission(currentPermission !== TeamPermissionType.NO_ACCESS ? currentPermission : undefined);
  }, [permissions]);

  const onHaveAccessToChange = (value: string | React.FormEvent<HTMLInputElement>) => {
    setHaveAccessTo(value);
    setTheirPermission(value === TeamPermissionTypeAllTeamMembers ? TeamPermissionType.VIEW_ONLY : undefined);
  };

  const onSave = () => {
    setSelectedOption({ teamPermission: theirPermission ?? haveAccessTo });
    onClose();
  };

  return (
    <Modal
      maxHeight={602}
      maxWidth={600}
      size="large"
      header="Team members"
      onClose={onClose}
      isOpen={isOpen}
      footer={
        <div className="flex flex-nowrap gap-4">
          <Button size="medium" emphasis="quiet" kind="accent" onClick={onClose}>
            Cancel
          </Button>
          <Button size="medium" emphasis="filled" kind="accent" onClick={onSave}>
            Save changes
          </Button>
        </div>
      }
    >
      <div className="mv-medium anim-slide-left">
        <div className="mb-x-large">
          <Text size="md" className="w-full">
            Control access to team member profiles and data
          </Text>
          <div className="pt-4">
            <Text size="md" className="pl-2 font-bold">
              Who do they have access to?
            </Text>
            <RadioGroup aria-label="team" value={haveAccessTo} onChange={onHaveAccessToChange}>
              <ActionSelectedItem
                ariaLabel="team-no-access"
                label="No access"
                value={TeamPermissionType.NO_ACCESS}
                selectedPermission={haveAccessTo}
              />
              <ActionSelectedItem
                ariaLabel="team-all-team-members"
                label="All team members"
                value={TeamPermissionTypeAllTeamMembers}
                selectedPermission={haveAccessTo}
              />
            </RadioGroup>
          </div>
          {haveAccessTo !== TeamPermissionType.NO_ACCESS.toString() && (
            <div className="pt-4">
              <Text size="md" className="pl-2 font-bold">
                What are their permissions?
              </Text>
              <RadioGroup aria-label="team" value={theirPermission} onChange={setTheirPermission}>
                <ActionSelectedItem
                  ariaLabel="team-view-only"
                  label="View only"
                  value={TeamPermissionType.VIEW_ONLY}
                  selectedPermission={theirPermission}
                />
                <ActionSelectedItem
                  ariaLabel="team-manager"
                  label="Manage"
                  value={TeamPermissionType.MANAGE}
                  selectedPermission={theirPermission}
                  tooltipText="Managers have edit access to all team member profiles, and can also add and remove team members."
                />
              </RadioGroup>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
