import { Checkbox, Col, Row } from 'antd';
import { SHIFT_SLOT_COLUMN_WIDTHS } from 'views/group-services/session-details/team-members/shift-slot-table/shift-table-common-constants';
import { FieldLabel } from 'common-components/typography';
import React from 'react';

interface IShiftSlotTableHeaderProps {
  checkAll: boolean;
  indeterminate: boolean;
  onToggleCheckAll: () => void;
}

interface IShiftSlotTableHeaderState {}

export class ShiftSlotTableHeader extends React.Component<IShiftSlotTableHeaderProps, IShiftSlotTableHeaderState> {
  render() {
    const { checkAll, onToggleCheckAll } = this.props;

    return (
      <Row type="flex" className="mb-medium" style={{ flexWrap: 'nowrap' }}>
        <Col
          className="pt-small pb-medium ph-medium bordered-bottom hover-bg-quaternary cursor-pointer"
          style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.CHECKBOX }}
        >
          <Checkbox checked={checkAll} onChange={onToggleCheckAll} indeterminate={this.props.indeterminate} />
        </Col>
        <Col
          className="pt-small pb-medium ph-medium bordered-bottom"
          style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.SCHEDULED }}
        >
          <FieldLabel text={'SCHEDULED'} />
        </Col>
        <Col
          className="pt-small pb-medium ph-medium bordered-bottom"
          style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.TEAM_MEMBER }}
        >
          <FieldLabel text={'TEAM MEMBER'} />
        </Col>
        <Col
          className="pt-small pb-medium ph-medium bordered-bottom"
          style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.STATUS }}
        >
          <FieldLabel text={'STATUS'} />
        </Col>
        <Col className="pt-small pb-medium ph-medium bordered-bottom" style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.START }}>
          <FieldLabel text={'START'} />
        </Col>
        <Col
          className="pt-small pb-medium ph-medium bordered-bottom"
          style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.FINISH }}
        >
          <FieldLabel text={'FINISH'} />
        </Col>
        <Col className="pt-small pb-medium ph-medium bordered-bottom" style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.HOURS }}>
          <FieldLabel text={'HOURS'} />
        </Col>
        <Col
          className="pt-small pb-medium ph-medium bordered-bottom"
          style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.WARNINGS }}
        >
          <FieldLabel text={'WARNINGS'} />
        </Col>
        <Col
          className="pt-small pb-medium ph-medium bordered-bottom"
          style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.ACTION_ICON }}
        >
          &nbsp;
        </Col>
      </Row>
    );
  }
}
