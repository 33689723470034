import { useTranslation } from 'react-i18next';
import { QueryClient, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

import { IWorkLeaveType } from 'interfaces/account-interfaces';
import {
  ArchiveWorkTypeRequest,
  ArchiveWorkTypeResponse,
  archiveWorkType,
} from 'stores/queries/work-types/work-type-queries';
import { WorkTypeCategory } from 'utilities/enum-utils';
import { OptimisticUpdate } from './optimistic-mutation/optimistic-update';
import { Toast } from './optimistic-mutation/toast';
import { ClientError } from 'interfaces/common-interface';
import { getNewRelicBrowser } from 'integrations/new-relic-browser';

function getWorkTypeName(
  queryClient: QueryClient,
  newWorkType: ArchiveWorkTypeRequest,
  workTypeCategory: WorkTypeCategory,
) {
  const previousWorkTypes = queryClient.getQueryData<IWorkLeaveType[]>(['work-types', workTypeCategory]);
  return previousWorkTypes?.find((workType) => workType.workTypeId === newWorkType.workTypeId)?.name;
}

type Context = {
  name: string | undefined;
  optimisticUpdate: OptimisticUpdate<IWorkLeaveType | ArchiveWorkTypeRequest>;
};

export function useMutationArchiveWorkType(
  workTypeCategory: WorkTypeCategory,
): UseMutationResult<ArchiveWorkTypeResponse, ClientError, ArchiveWorkTypeRequest, Context> {
  const queryClient = useQueryClient();
  const { t } = useTranslation('', {
    keyPrefix: `accountManagement.workLeaveTypesPage.notification.archive.${workTypeCategory}`,
  });
  const toast = new Toast();

  return useMutation<ArchiveWorkTypeResponse, ClientError, ArchiveWorkTypeRequest, Context>({
    mutationFn: archiveWorkType,
    mutationKey: ['work-types', workTypeCategory],
    onMutate: async (newWorkType) => {
      const name = getWorkTypeName(queryClient, newWorkType, workTypeCategory);

      const optimisticUpdate = new OptimisticUpdate<IWorkLeaveType | ArchiveWorkTypeRequest>(
        queryClient,
        'work-types',
        [workTypeCategory],
        (item) => item.workTypeId === newWorkType.workTypeId,
      );

      await optimisticUpdate.start({ ...newWorkType, state: 'INACTIVE' }, newWorkType.workTypeId);

      return {
        name,
        optimisticUpdate,
      };
    },

    onError: (err, _newWorkType, context) => {
      toast.error(t('error'), context?.name, err.meta.message);
      context?.optimisticUpdate.rollback();
      const newRelicBrowser = getNewRelicBrowser();
      newRelicBrowser?.noticeError({ ...err, name: err.message }, { location: 'useMutationUpdateWorkType' });
    },

    onSuccess: (_response, _newWorkType, context) => {
      context?.optimisticUpdate.finish();
      toast.success(t('success'), context?.name);
    },
  });
}
