import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const SlidersHoriz2 = forwardRef(function SlidersHoriz2(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 7a3.75 3.75 0 0 1 7.425-.75H21a.75.75 0 0 1 0 1.5h-3.325A3.751 3.751 0 0 1 10.25 7ZM14 4.75a2.25 2.25 0 1 1 0 4.5 2.25 2.25 0 0 1 0-4.5ZM3 6.25a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5H3Zm0 10a.75.75 0 0 0 0 1.5h3.325A3.751 3.751 0 0 0 13.75 17a3.75 3.75 0 0 0-7.425-.75H3Zm4.75.75a2.25 2.25 0 1 0 4.5 0 2.25 2.25 0 0 0-4.5 0Zm8.25-.75a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5h-5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
