import { Text } from '@good/components';
import { Export } from '@good/icons';
import { SearchField, Select } from 'design-components';
import { IServiceListItemLite } from 'interfaces/service-interfaces';
import React, { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { TIME_PERIODS, TimePeriod } from 'views/insights/insights';
import { MultiSelectDropdown } from '../multi-select-dropdown/multi-select-dropdown';
import { useFlags } from 'launchdarkly-react-client-sdk';

type InsightsTableOptionsProperties = {
  services: IServiceListItemLite[] | undefined;
  timePeriod: TimePeriod;
  setTimePeriod: (value: TimePeriod) => void;
  selectedServiceIds: string[];
  setSelectedServiceIds: (ids: string[]) => void;
  searchText: string | undefined;
  setSearchText: (value: string | undefined) => void;
  setIsExportModalOpen: (open: boolean) => void;
};

export const InsightsTableOptions: React.FC<InsightsTableOptionsProperties> = ({
  services,
  timePeriod,
  setTimePeriod,
  selectedServiceIds,
  setSelectedServiceIds,
  searchText,
  setSearchText,
  setIsExportModalOpen,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'insights' });
  const { boom281TeamInsightsCsvExportModal } = useFlags<{ boom281TeamInsightsCsvExportModal: boolean }>();

  // The select field takes in a function which accepts a Key.
  // Key is a superset of TimePeriod, so this is a safe cast.
  const onTimePeriodChange = setTimePeriod as (value: Key) => void;

  return (
    <div className="[&>*]:h-100 flex max-h-fit flex-row space-x-2">
      <SearchField placeholder="Search" onChange={setSearchText} value={searchText ?? ''} />
      <MultiSelectDropdown items={services} selectedIds={selectedServiceIds} setSelectedIds={setSelectedServiceIds} />
      <Select label="" height="100%" selectedKey={timePeriod} onSelectionChange={onTimePeriodChange}>
        {TIME_PERIODS.map((option) => (
          <Select.Item aria-label={option} key={option}>
            {t(`summary.filters.dateOptions.${option}`)}
          </Select.Item>
        ))}
      </Select>
      {boom281TeamInsightsCsvExportModal && (
        <button
          onClick={() => setIsExportModalOpen(true)}
          className="align-center flex flex-row space-x-2 rounded-lg bg-ocean px-4 py-2 text-white transition-colors hover:bg-ocean-dark-1"
        >
          <Text className="text-sm font-base font-bold">{t('table.exportButtonLabel')}</Text>
          <Export />
        </button>
      )}
    </div>
  );
};
