import { Tooltip2 } from '@blueprintjs/popover2';
import { DatePicker, Empty, notification, Select, Skeleton } from 'antd';
import { IconButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import ActionModal from 'common-components/modal/ActionModal';
import { ItemCountSheet } from 'common-components/Sheets/ItemCountSheet';
import { find, isEmpty, map } from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ISelectedShiftHour, IShiftItem } from '../../../../interfaces/shift-interfaces';

import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import ShiftItemRow from 'views/billings/payments/components/details/ShiftItemRow';
import { SubTitle, Text, Title } from '../../../../common-components/typography';
import { PAYMENT_TAB } from '../../utils/constants';
import BulkActionButton from '../components/BulkActionButton';
import ViewShiftHoursModal from '../components/ViewShiftHoursModal';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { ITimesheetShiftItem } from 'interfaces/booking-interfaces';
import { fetchWorkTypes } from 'stores/queries/work-types/work-type-queries';
import { WorkTypeCategory } from 'utilities/enum-utils';
import { ITimesheetExportType, IWorkLeaveType } from 'interfaces/account-interfaces';
import { GTMEventName, pushGTMEvent } from 'integrations/gtm';

const { RangePicker } = DatePicker;

type IShiftHoursSectionProps = {
  shiftHoursList?: typeof state.billingsStore.shiftHoursList;
  shiftHoursFilter?: typeof state.billingsStore.shiftHoursFilter;
  timesheetShifts?: typeof state.billingsStore.timesheetShifts;
  currentFilterConfig?: any;
  history?: any;

  doFetchShiftHours: typeof dispatch.billingsStore.doFetchShiftHours;
  setShiftHours?: typeof dispatch.billingsStore.setShiftHours;
  setShiftHoursFilter?: typeof dispatch.billingsStore.setShiftHoursFilter;
  doFetchShiftHourDetail?: typeof dispatch.billingsStore.doFetchShiftHourDetail;
  doFetchTimesheetHours?: typeof dispatch.billingsStore.doFetchTimesheetHours;
  setTimesheetHours?: typeof dispatch.billingsStore.setTimesheetHours;

  doFetchServicesLite: typeof dispatch.servicesStore.doFetchServicesLite;
  servicesLite: typeof state.servicesStore.servicesLite;
  portalUser: typeof state.authStore.portalUser;
  doFetchCustomTimesheet: typeof dispatch.billingsStore.doFetchCustomTimesheet;
  doFetchTimesheet: typeof dispatch.billingsStore.doFetchTimesheet;
  setZetCareTimesheet: typeof dispatch.billingsStore.setZestCareTimesheet;
  timesheet: typeof state.billingsStore.timesheet;
  entitlements: typeof state.billingsStore.entitlements;
  timeSheetStyleState: typeof state.accountStore.timeSheetStyleState;
  doGetPayrollSystemList: typeof dispatch.accountStore.doGetPayrollSystemList;
  doGetFinanceExportDefaultConfigs: typeof dispatch.accountStore.doGetFinanceExportDefaultConfigs;
  flags: { [key: string]: boolean };
};
type IShiftHoursSectionState = {
  topHeight: number;
  showFilters: boolean;
  isLoading: boolean;
  isLoadingShiftModal: boolean;
  isSearching: boolean;
  filters: any;
  isExporting: boolean;
  isExportTimesheet: boolean;
  showShiftHoursModal: boolean;
  shiftItem: IShiftItem;
  timesheetItem: ITimesheetShiftItem;
  selectedShiftHour: ISelectedShiftHour;
  workTypes: IWorkLeaveType[];
  isLoadingWorkTypes: boolean;
};

const ShiftHoursEmptyState = () => (
  <div className='align-center flex-column flex-1 bg-white'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No Shifts found.
    </Text>{' '}
    <br /> <br />
    <Text color='secondary'>All shifts under this filter will appear here.</Text>
    <Text color='secondary'>Try adjusting your filter.</Text>
  </div>
);

class ShiftHoursSection extends PureComponent<IShiftHoursSectionProps, IShiftHoursSectionState> {
  // topHeight is used to control sticky
  state: IShiftHoursSectionState = {
    topHeight: 0,
    showFilters: false,
    isLoading: false,
    isSearching: false,
    filters: [],
    isExporting: false,
    isExportTimesheet: false,
    showShiftHoursModal: false,
    shiftItem: {},
    timesheetItem: null,
    selectedShiftHour: null,
    isLoadingShiftModal: false,
    workTypes: [],
    isLoadingWorkTypes: true,
  };

  _headerElement = null;

  private _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  private _addfilterDateRange = async (e) => {
    const { setShiftHoursFilter, shiftHoursFilter, doFetchShiftHours, setZetCareTimesheet, doFetchTimesheetHours } =
      this.props;
    this.setState({ isLoading: true });
    const newFilters = { ...shiftHoursFilter };
    if (e[0] && e[1]) {
      newFilters['startDate'] = e[0].startOf('day').toDate().toISOString();
      newFilters['endDate'] = e[1].endOf('day').toDate().toISOString();
    }

    await setShiftHoursFilter(newFilters);
    setZetCareTimesheet({ workerShiftData: [], workerTransportData: [] });
    await doFetchShiftHours(this.props.shiftHoursFilter);
    await doFetchTimesheetHours(this.props.shiftHoursFilter);
    this.setState({ isLoading: false }, () => this._handleHeaderHeight());
  };

  private _addfilterServices = async (e) => {
    const { setShiftHoursFilter, shiftHoursFilter, doFetchShiftHours, setZetCareTimesheet, doFetchTimesheetHours } =
      this.props;
    const newFilters = { ...shiftHoursFilter };
    if (e !== 'ALL') {
      newFilters['serviceIds'] = [e];
    } else {
      newFilters['serviceIds'] = [];
    }
    await setShiftHoursFilter(newFilters);
    setZetCareTimesheet({ workerShiftData: [], workerTransportData: [] });
    await doFetchShiftHours(this.props.shiftHoursFilter);
    await doFetchTimesheetHours(this.props.shiftHoursFilter);
    this.setState({ isLoading: false }, () => this._handleHeaderHeight());
  };

  private _refreshListings = async () => {
    const {
      doFetchShiftHours,
      shiftHoursFilter,
      setShiftHours,
      setZetCareTimesheet,
      doFetchTimesheetHours,
      setTimesheetHours,
    } = this.props;
    this.setState({ isLoading: true });

    setTimesheetHours([]);
    setShiftHours([]);
    setZetCareTimesheet({ workerShiftData: [], workerTransportData: [] });
    await doFetchShiftHours({
      ...shiftHoursFilter,
    });
    await doFetchTimesheetHours({
      ...shiftHoursFilter,
    });
    this.setState({ isLoading: false });
  };

  private _downloadTimesheet = async (styleKey: string) => {
    const { shiftHoursFilter, doFetchTimesheet } = this.props;

    const resp = await doFetchTimesheet({ ...shiftHoursFilter, styleKey });
    const downloadResources: ITimesheetExportType[] = resp.exportedResources;
    downloadResources.forEach((download) => window.open(download.resourceUrl, '_blank'));
  };

  private _downloadCSVFile = async (styleKey: string) => {
    pushGTMEvent({
      event: GTMEventName.GENERATE_TIMESHEET_EXPORT_FILE,
      payload: {
        timesheetExportParams: {
          styleKey: styleKey,
          isV2Enabled: true,
          filter: {
            ...this.props.shiftHoursFilter,
            startDate: moment(this.props.shiftHoursFilter.startDate).toISOString(),
            endDate: moment(this.props.shiftHoursFilter.endDate).toISOString(),
          },
        },
        serviceProviderId: this.props.portalUser?.serviceProviderId,
        userId: this.props.portalUser?.userId,
      },
    });

    try {
      await this._downloadTimesheet(styleKey);
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong! Please try again.',
      });
    }
  };

  //region Component Lifecycle Methods
  componentDidMount = async () => {
    // Automatically set the top height for the top panel. This is required for sticky.
    this._handleHeaderHeight();
    const {
      doFetchShiftHours,
      currentFilterConfig,
      shiftHoursFilter,
      setShiftHoursFilter,
      doFetchServicesLite,
      setZetCareTimesheet,
      doGetPayrollSystemList,
      doGetFinanceExportDefaultConfigs,
      doFetchTimesheetHours,
    } = this.props;

    const appliedFilters = isEmpty(shiftHoursFilter) ? currentFilterConfig.filters : shiftHoursFilter;

    if (isEmpty(shiftHoursFilter)) {
      setShiftHoursFilter(currentFilterConfig.filters);
    }
    this.setState({ isLoading: true });
    setZetCareTimesheet({ workerShiftData: [], workerTransportData: [] });
    await Promise.all([
      doFetchShiftHours(appliedFilters),
      doFetchServicesLite({}),
      doGetPayrollSystemList(),
      doGetFinanceExportDefaultConfigs(),
      doFetchTimesheetHours(appliedFilters),
    ]);

    try {
      const workTypes = await fetchWorkTypes({
        workTypeCategory: WorkTypeCategory.WorkType,
      });

      this.setState({
        workTypes: workTypes.data,
        isLoadingWorkTypes: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({ isLoadingWorkTypes: false });
      notification.error({
        message: 'Oops! We could not load your work and leave types.',
        description: 'They may not appear in the time-sheet export.',
      });
    }

    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps) => {
    const { currentFilterConfig } = this.props;

    if (prevProps.currentFilterConfig.key !== currentFilterConfig.key) {
      this.setState({
        isLoading: true,
        showFilters: false,
      });
    }
  };

  private _onClickDetailShiftHour = async (shiftItem) => {
    this.setState({ showShiftHoursModal: true, shiftItem, isLoadingShiftModal: true });

    this.setState({ isLoadingShiftModal: false, timesheetItem: shiftItem });
  };

  private _onCloseViewShiftHoursModal = () => {
    this.setState({ showShiftHoursModal: false });
  };

  render() {
    const {
      shiftHoursList,
      timesheetShifts,
      shiftHoursFilter,
      servicesLite,
      history,
      currentFilterConfig,
      timeSheetStyleState,
    } = this.props;
    const { isExporting, isExportTimesheet, isLoadingWorkTypes } = this.state;
    const groupedList = [];

    map(timesheetShifts, (shift) => {
      const user = find(groupedList, (group) => group.userId === shift.supportWorkerDetails.userId);
      if (user) {
        user.items.push(shift);
      } else {
        groupedList.push({
          firstName: shift.supportWorkerDetails.firstName,
          lastName: shift.supportWorkerDetails.lastName,
          userId: shift.supportWorkerDetails.userId,
          supportWorkerId: shift.supportWorkerDetails.supportWorkerId,
          items: [shift],
        });
      }
    });

    return (
      <div className='width-full flex-column flex-1 bg-white' style={{ position: 'relative' }}>
        <ActionModal
          title={`Export ${isExportTimesheet ? 'timesheet' : 'entitlements'}`}
          isOpen={isExporting}
          width='medium'
          showCloseButton={false}
        >
          {/* <div className="anim-slide-left"> */}

          <SpinningLoader size={150} message={'Exporting...'} />
        </ActionModal>
        <ViewShiftHoursModal
          isOpen={this.state.showShiftHoursModal}
          shiftItem={this.state.shiftItem}
          timesheetItem={this.state.timesheetItem}
          onClose={this._onCloseViewShiftHoursModal}
          selectedShiftHour={this.state.selectedShiftHour}
          isLoading={this.state.isLoadingShiftModal}
        />
        <div>
          <div className='booking-header' ref={(com) => (this._headerElement = com)}>
            <div className='align-center flex-row justify-between'>
              <div>
                <Title level={3} className='mv-none' lineHeight={150}>
                  {currentFilterConfig.title}
                </Title>
                <Text color='secondary'>{currentFilterConfig.description}</Text>
              </div>
              <div className='flex-column align-center'>
                <div className='pr-x-small'>
                  <Tooltip2 content='Refresh this view' position='top'>
                    <IconButton
                      color='white'
                      onClick={this._refreshListings}
                      iconColor='black'
                      size='large'
                      icon='reload'
                    />
                  </Tooltip2>
                </div>
              </div>
            </div>

            <div className='align-center mt-large flex-row justify-between'>
              <div className='align-center flex-row flex-wrap'>
                <div className='mr-x2-large mb-small'>
                  <Text weight='bold'>Date Range</Text>
                  <br />
                  <RangePicker
                    showTime={{
                      hideDisabledOptions: true,
                    }}
                    format='DD/MM/YYYY'
                    allowClear={false}
                    defaultValue={
                      shiftHoursFilter.startDate && shiftHoursFilter.endDate
                        ? [moment(shiftHoursFilter.startDate), moment(shiftHoursFilter.endDate)]
                        : [moment().add(-4, 'weeks').startOf('day'), moment().endOf('day')]
                    }
                    onChange={this._addfilterDateRange}
                  />
                </div>
                <div className='mr-large mb-small'>
                  <Text weight='bold'>Services</Text>
                  <br />
                  <Select
                    defaultValue={shiftHoursFilter.serviceIds?.length > 0 ? shiftHoursFilter.serviceIds[0] : 'ALL'}
                    className='mr-small'
                    onChange={this._addfilterServices}
                    style={{ width: '300px' }}
                  >
                    <Select.Option value='ALL'>All services</Select.Option>
                    {map(servicesLite, (service) => (
                      <Select.Option key={service.serviceId} value={service.serviceId}>
                        {service.serviceName}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div className='mt-12'>
                  {!isLoadingWorkTypes && (
                    <BulkActionButton
                      options={timeSheetStyleState?.configTypes}
                      tabType={PAYMENT_TAB.SHIFT_HOURS}
                      downloadExportDefaultCsv={this._downloadCSVFile}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <table className='payment-listing'>
            <thead>
              <tr>
                <th style={{ top: `${this.state.topHeight}px` }} className='ellipsis header'>
                  <SubTitle>Team Member</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px`, textAlign: 'right' }} className='header nowrap'>
                  <SubTitle>Number of Shift</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px`, textAlign: 'right' }} className='header nowrap'>
                  <SubTitle>Total KM&apos;s</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px`, textAlign: 'right' }} className='header nowrap'>
                  <SubTitle>Total Shift Hours</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px`, width: '50px' }} />
              </tr>
            </thead>

            <tbody>
              {this.state.isLoading && (
                <tr style={{ borderBottom: '0px solid !important' }}>
                  <td colSpan={7}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
                  </td>
                </tr>
              )}

              {!this.state.isLoading && isEmpty(groupedList) && (
                <tr style={{ cursor: 'default' }}>
                  <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                    <ShiftHoursEmptyState />
                  </td>
                </tr>
              )}

              {!this.state.isLoading &&
                map(groupedList, (shiftItem) => (
                  <ShiftItemRow
                    shiftItem={shiftItem}
                    history={history}
                    key={shiftItem.userId}
                    onClickDetailShiftHour={this._onClickDetailShiftHour}
                    portalUser={undefined}
                  />
                ))}
            </tbody>
          </table>
        </div>

        {/* Filler */}
        <div className='flex-1 bg-white'>&nbsp;</div>

        {!this.state.isLoading && shiftHoursList.length > 0 && (
          <ItemCountSheet itemCount={shiftHoursList.length} itemLabel='Shift' />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  servicesLite: state.servicesStore.servicesLite,
  portalUser: state.authStore.portalUser,
  timesheet: state.billingsStore.timesheet,
  entitlements: state.billingsStore.entitlements,
  timeSheetStyleState: state.accountStore.timeSheetStyleState,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServicesLite: dispatch.servicesStore.doFetchServicesLite,
  doFetchCustomTimesheet: dispatch.billingsStore.doFetchCustomTimesheet,
  doFetchTimesheet: dispatch.billingsStore.doFetchTimesheet,
  setZetCareTimesheet: dispatch.billingsStore.setZestCareTimesheet,
  doGetPayrollSystemList: dispatch.accountStore.doGetPayrollSystemList,
  doGetFinanceExportDefaultConfigs: dispatch.accountStore.doGetFinanceExportDefaultConfigs,
  doFetchShiftHourDetail: dispatch.billingsStore.doFetchShiftHourDetail,
});

export default connect(mapState, mapDispatch)(withLDConsumer()(ShiftHoursSection));
