import { forwardRef } from 'react';
import { Illustration } from './illustration';
import type { IllustrationProps } from './illustration';
import type { Ref } from 'react';
export const IPhone2 = forwardRef(function IPhone2(props: IllustrationProps, ref: Ref<SVGSVGElement>) {
  return (
    <Illustration {...props} ref={ref}>
      <path
        d="M98.608 10.663c-.137-2.299-.594-4.541-1.249-6.752-.52-1.76-1.711-2.72-3.417-3.152-.277-.069-.554-.158-.837-.186C91.077.36 89.048-.003 87.02 0c-4.694.009-9.391.14-14.083.33-3.754.151-7.502.466-11.254.707-1.425.091-2.854.157-4.277.277-3.094.264-6.183.527-9.297.467-1.792-.034-3.592.097-5.377.278-2.692.275-5.346.759-7.826 1.918-1.6.748-2.503 2.042-2.814 3.757-.575 3.158-.523 6.36-.612 9.552-.08 2.784-.143 5.57-.343 8.344-.431 5.922-.985 11.835-1.422 17.756-1.395 18.893-.646 37.737 1.382 56.553.606 5.617 1.098 11.25 1.68 16.874.135 1.299.337 2.605.677 3.865.646 2.391 2.035 4.258 4.232 5.466a11.482 11.482 0 0 0 5.383 1.495c2.083.066 4.166.143 6.246.209 6.331.195 12.663.338 18.974-.464 1.374-.174 2.771-.212 4.157-.275 3.389-.154 6.777-.272 10.166-.432 2.291-.109 4.566-.372 6.777-1.022.82-.241 1.634-.539 2.406-.908 2.22-1.068 3.66-2.815 4.38-5.183.571-1.872.588-3.797.588-5.721-.011-11.837.149-23.672.537-35.503.266-8.104.375-16.213.552-24.322.14-6.388.131-12.782.466-19.159.322-6.182.474-12.358.468-18.543 0-1.884-.066-3.769-.177-5.65v-.003Zm-1.326 21.954c-.348 6.912-.485 13.836-.671 20.754-.226 8.312-.334 16.628-.626 24.937a966.65 966.65 0 0 0-.594 34.897c0 1.351-.032 2.706-.149 4.048-.323 3.64-2.3 5.931-5.777 6.961-2.131.633-4.314.937-6.526 1.057-1.917.106-3.84.169-5.757.246-4.411.178-8.808.521-13.211.848-4.189.309-8.415.177-12.62.16-2.492-.011-4.98-.212-7.472-.266-2.131-.049-4.134-.481-6.037-1.718-2.188-.796-3.217-2.612-3.608-4.891-.138-.807-.278-1.618-.355-2.431-.66-6.849-1.28-13.704-1.965-20.553-2.066-20.554-2.495-41.119-.42-61.71.657-6.52 1.094-13.057 1.1-19.623 0-2.488.345-4.979.52-7.467.088-1.243.788-2.068 1.854-2.534 1.417-.625 2.845-1.292 4.337-1.658a29.046 29.046 0 0 1 6.303-.845c7.026-.149 14.051-.34 21.066-.827 5.211-.361 10.442-.453 15.665-.662 1.346-.054 2.695-.06 4.04-.146a18.824 18.824 0 0 1 6.78.767 9.374 9.374 0 0 1 2.526 1.209c.417.286.692.902.849 1.423.76 2.525 1.111 5.123 1.145 7.76.092 6.76-.06 13.512-.4 20.27l.003-.006Z"
        fill="currentColor"
      />
      <path
        d="M92.52 11.743c-.138-1.025-.55-2.065-1.046-2.981-.64-1.18-1.632-2.087-3.06-2.22-3.009-.277-6.023-.492-9.032-.76-1.1-.101-1.897.271-2.428 1.253-.44.81-1.129 1.275-2.063 1.343-2.606.192-5.212.516-7.82.544-3.1.035-6.2-.18-9.3-.292-1.44-.051-1.617-.043-2.246-1.75-.434-1.173-.397-1.193-1.629-1.087-3.137.272-6.268.635-9.408.784-2.554.12-4.637 1.151-6.557 2.706-.566.458-.995.982-1.117 1.701-.226 1.326-.546 2.652-.606 3.986-.215 4.664-.452 9.332-.474 14-.11 22.448-.158 44.897-.215 67.346-.011 4.839.18 9.673.815 14.469.234 1.775.751 3.522 1.248 5.249.489 1.689 1.657 2.98 2.777 4.292.532.621 1.143 1.065 1.9 1.277.98.278 1.977.521 2.98.69 6.66 1.131 13.335 1.123 20.026.252 2.309-.301 4.632-.507 6.954-.659 3.06-.197 6.12-.294 9.149-.85 1.243-.226 2.531-.198 3.774-.421 1.4-.252 2.775-.641 4.152-1.011.257-.068.508-.275.688-.481.592-.675 1.152-1.38 1.635-1.967.54-1.185.965-2.259.914-3.433a86.08 86.08 0 0 0-.343-4.776c-.334-3.356-.063-6.712-.023-10.068.083-7.044.137-14.088.28-21.129.089-4.381.34-8.756.486-13.137.097-2.904.166-5.81.197-8.716.02-1.842-.071-3.686-.071-5.53 0-8.069.791-16.112.694-24.195-.06-4.86-.592-9.644-1.232-14.432v.003Zm-45.732 8.65a94.996 94.996 0 0 1 1.934-2.356c.617-.736.989-1.527 2.032-2.429.771-.62 1.745-1.74 1.905-1.351a.893.893 0 0 1-.117.89c-1.517 1.985-4.623 5.953-6.103 7.883-.177.232-1.154 1.146-1.194-.008.231-1.257.966-1.901 1.54-2.632l.003.003Zm11.126-5.57c-1.643 1.93-3.592 3.695-5.143 5.685-1.66 2.13-3.795 4.017-5.363 6.213-1.586 2.22-3.089 4.502-4.752 6.698-.251-.647-.023-1.186.28-1.675.995-1.598 1.94-3.239 3.046-4.756 3.234-4.43 6.686-8.688 10.46-12.674.214-.226 1.452-1.595 2.029-1.177.391.284-.352 1.44-.56 1.684l.003.003Z"
        fill="currentColor"
      />
    </Illustration>
  );
});
