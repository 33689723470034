import { useCallback } from 'react';

import { useNewRelicBrowser } from 'integrations/new-relic-browser';
import { AddressInputFieldErrors, FieldError } from '../types';
import { InvalidAddressResultError } from '../invalid-address-result-error';

export function useHandleAutoInputError(
  addInternalError: (field: keyof AddressInputFieldErrors, error: FieldError) => void,
) {
  const newRelicBrowser = useNewRelicBrowser();

  const handleError = useCallback(
    (e: unknown, location: string) => {
      // known error
      if (
        e instanceof window.google.maps.MapsRequestError &&
        e.code === window.google.maps.GeocoderStatus.ZERO_RESULTS
      ) {
        addInternalError('automaticInput', { type: e.code, message: 'Address not found' });
        return;
      }

      if (e instanceof InvalidAddressResultError) {
        addInternalError('automaticInput', { type: e.message, message: 'Address not found' });
        return;
      }

      // unknown error
      let error: Error;
      if (e instanceof Error) {
        error = e;
      } else if (typeof e === 'string') {
        error = new Error(e);
      } else {
        error = new Error('Unknown Error');
      }
      newRelicBrowser?.noticeError(error, { message: `Unexpected error in AddressInput > ${location}` });
    },
    [addInternalError, newRelicBrowser],
  );

  return handleError;
}
