import React from 'react';
import moment from 'moment-timezone';
import DOMPurify from 'dompurify';
import { Text, Skeleton } from '@good/ui/core';
import { DateFormatter } from '@good/components';

import { useInlineForm } from './inline-form-context';

import '../rich-text-editor/prose-mirror.css';

type StaticValueProps = {
  emptyValue?: string | number;
  hidden?: boolean;
  html?: boolean;
  skeletonProps?: React.ComponentProps<typeof Skeleton>;
  value?: string | number | Date | null;
};

export const StaticValue = ({ emptyValue, hidden, html, skeletonProps, value }: StaticValueProps) => {
  const { loading, maxWidth, timezone } = useInlineForm();
  const isValueEmpty = value === null || value === undefined || value === '' || (html && value === '<p></p>');

  if (hidden) {
    return <div aria-hidden='true' className='hidden' />;
  }

  if (value instanceof Date) {
    let date = value;
    if (timezone) {
      date = new Date(moment(value).tz(timezone).format('YYYY-MM-DDTHH:mm:ss'));
    }

    return (
      <Skeleton visible={loading ?? false} width={maxWidth} {...skeletonProps}>
        <Text size='sm' style={{ whiteSpace: 'pre-line' }}>
          <DateFormatter value={date} kind='display' />
          {loading && '...'} {/* force content for loading skeleton */}
        </Text>
      </Skeleton>
    );
  }

  if (html && typeof value === 'string') {
    return (
      <Skeleton visible={loading ?? false} width={maxWidth} {...skeletonProps}>
        {isValueEmpty ? (
          <Text size='sm' style={{ whiteSpace: 'pre-line' }}>
            {emptyValue}
          </Text>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} className='ProseMirror' />
        )}
        {loading && !value ? '...' : null}
      </Skeleton>
    );
  }

  return (
    <Skeleton visible={loading ?? false} width={maxWidth} {...skeletonProps}>
      <Text size='sm' style={{ whiteSpace: 'pre-line' }}>
        {isValueEmpty ? emptyValue : value}
        {loading && !value ? '...' : null} {/* force content for loading skeleton */}
      </Text>
    </Skeleton>
  );
};
