import { Col, Row } from 'antd';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import { Title } from 'common-components/typography';
import * as H from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import CreateWorkflowTemplateGuideBox from './CreateWorkflowTemplateGuideBox';
import CreateWorkflowTemplateCompletedStepCard from './wizard-steps/CreateWorkflowTemplateCompletedStepCard';
import CreateWorkflowTemplateConfigurationStepPanel from './wizard-steps/CreateWorkflowTemplateConfigurationStepPanel';
import CreateWorkflowTemplateNameStepPanel from './wizard-steps/CreateWorkflowTemplateNameStepPanel';
import CreateWorkflowTemplateStepsStepPanel from './wizard-steps/CreateWorkflowTemplateStepsStepPanel';
import CreateWorkflowTemplateSummeryReviewStepCard from './wizard-steps/CreateWorkflowTemplateSummeryReviewStepCard';
import CreateWorkflowTemplateTriggerStepPanel from './wizard-steps/CreateWorkflowTemplateTriggerStepPanel';

interface ICreateWorkflowTemplateModalProps {
  onClose(): void;
  onCreateSuccess(): void;
  isOpen: boolean;
  history: H.History;
  addWorkflowTemplateWizard: typeof state.workflowStore.addWorkflowTemplateWizard;
  setAddWorkflowTemplateWizard: typeof dispatch.workflowStore.setAddWorkflowTemplateWizard;
}

interface ICreateWorkflowTemplateModalState {
  step: number;
  totalStep: number;
}

const steps = [
  { stepNumber: 1, stepLabel: 1, stepTitle: 'Name/Description' },
  { stepNumber: 2, stepLabel: 2, stepTitle: 'Trigger' },
  { stepNumber: 3, stepLabel: 3, stepTitle: 'Steps' },
  { stepNumber: 4, stepLabel: 4, stepTitle: 'Configuration' },
  { stepNumber: 5, stepLabel: 5, stepTitle: 'Review' },
  { stepNumber: 6, stepLabel: 6, stepTitle: 'Done!' },
];

class CreateWorkflowTemplateModal extends Component<
  ICreateWorkflowTemplateModalProps,
  ICreateWorkflowTemplateModalState
> {
  state = {
    step: 1,
    totalStep: 6,
  };

  private _onNextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  private _onPreviousStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  private _onClose = () => {
    const { setAddWorkflowTemplateWizard, onClose, onCreateSuccess } = this.props;
    const isStepDone = this.state.step === 6;

    onClose();
    isStepDone && onCreateSuccess();
    this.setState({ step: 1 }, () => setAddWorkflowTemplateWizard(null));
  };

  private _renderStepContent = () => {
    const { step } = this.state;

    if (step === 1) {
      return <CreateWorkflowTemplateNameStepPanel onNextStep={this._onNextStep} />;
    } else if (step === 2) {
      return (
        <CreateWorkflowTemplateTriggerStepPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />
      );
    } else if (step === 3) {
      return (
        <CreateWorkflowTemplateStepsStepPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />
      );
    } else if (step === 4) {
      return (
        <CreateWorkflowTemplateConfigurationStepPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
        />
      );
    } else if (step === 5) {
      return (
        <CreateWorkflowTemplateSummeryReviewStepCard
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
        />
      );
    } else if (step === 6) {
      return <CreateWorkflowTemplateCompletedStepCard onClose={this._onClose} />;
    }
  };

  render() {
    const { isOpen, addWorkflowTemplateWizard } = this.props;
    const { step } = this.state;

    return (
      <>
        {/* Full wizard modal */}
        <FullScreenScrollableModal
          isOpen={isOpen}
          onClose={this._onClose}
          bgColor={'tertiary'}
          headerTitle={
            <Title level={2} className="m-none">
              Create workflow
            </Title>
          }
          width={'full'}
          canCloseEsc={false}
          hasSteps
          steps={steps}
          currentStep={step}
        >
          <div className="anim-slide-left pt-x3-large">
            <Row className="ph-x3-large">
              <Col span={6} style={{ minHeight: 'calc(100vh - 88px)' }}>
                <CreateWorkflowTemplateGuideBox step={step} addWorkflowTemplateWizard={addWorkflowTemplateWizard} />
              </Col>

              <Col span={18} className="pl-x-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
                {this._renderStepContent()}
              </Col>
            </Row>
          </div>
        </FullScreenScrollableModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  addWorkflowTemplateWizard: state.workflowStore.addWorkflowTemplateWizard,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddWorkflowTemplateWizard: dispatch.workflowStore.setAddWorkflowTemplateWizard,
});

export default connect(mapState, mapDispatch)(CreateWorkflowTemplateModal);
