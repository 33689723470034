import { tv } from 'tailwind-variants';

import type { VariantProps } from 'tailwind-variants';

export const link = tv(
  {
    base: [
      'text-accent',
      'outline-none',
      'data-[disabled=true]:text-[#CCCAC6]',
      'data-[disabled=true]:cursor-default',
      'data-[focus-visible=true]:decoration-double',
      'inline-flex',
      'flex-row',
      'items-center',
      'gap-1',
    ],
    variants: {
      emphasis: {
        underline: ['underline'],
        'no-underline': ['no-underline', 'data-[hovered=true]:underline', 'data-[focus-visible=true]:underline'],
      },
    },
    defaultVariants: {
      tone: 'accent',
      emphasis: 'underline',
    },
  },
  { twMerge: false },
);

type Variants = Required<VariantProps<typeof link>>;

export type LinkVariants = {
  /**
   * Visual importance of the link.
   * @default underline
   */
  emphasis?: Variants['emphasis'];
};
