import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CallMissed = forwardRef(function CallMissed(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3.25a.75.75 0 0 0-.75.75v4a.75.75 0 1 0 1.5 0V5.81l.891.892.037.037c.635.635 1.165 1.165 1.642 1.529.504.384 1.036.654 1.68.654.644 0 1.176-.27 1.68-.654.477-.364 1.007-.894 1.642-1.53l.037-.036L17.03 5.03a.75.75 0 1 0-1.06-1.06L14.298 5.64c-.681.682-1.14 1.139-1.528 1.434-.37.283-.586.347-.77.347-.184 0-.4-.064-.77-.347-.387-.295-.846-.752-1.528-1.434L8.81 4.75H11a.75.75 0 1 0 0-1.5H7Zm5 8c-1.36 0-2.786.214-4.224.663-1.93.604-3.884 1.632-5.737 3.139l-.015.012c-.147.12-.265.215-.407.372-.375.416-.589.954-.66 1.48-.07.526-.007 1.101.245 1.602.096.19.209.347.35.546l.023.03.032.046c.176.247.312.437.46.589a2.75 2.75 0 0 0 3.052.606 5.05 5.05 0 0 0 .65-.369l.047-.03.702-.438.079-.049c.451-.282.807-.503 1.077-.814a2.75 2.75 0 0 0 .471-.765c.157-.38.195-.798.244-1.328l.009-.093.302-3.244a12.528 12.528 0 0 1 3.3-.455c1.057 0 2.167.146 3.3.455l.302 3.245.009.092c.049.53.087.947.244 1.329.114.278.273.537.47.764.271.31.627.532 1.078.814l.08.05.701.438.047.03c.257.16.455.284.65.368a2.75 2.75 0 0 0 3.052-.606c.148-.152.284-.342.46-.589l.032-.045.023-.031c.141-.198.254-.357.35-.546.252-.5.316-1.076.245-1.602-.071-.526-.284-1.064-.66-1.48a3.256 3.256 0 0 0-.407-.372l-.015-.012c-1.853-1.507-3.806-2.535-5.737-3.139A14.175 14.175 0 0 0 12 11.25Zm-9.015 4.966c1.36-1.106 2.765-1.923 4.16-2.485l-.24 2.58c-.063.666-.087.843-.148.991a1.251 1.251 0 0 1-.214.348c-.105.12-.252.222-.82.576l-.702.439c-.326.204-.419.259-.494.291a1.25 1.25 0 0 1-1.387-.275c-.057-.059-.121-.145-.345-.458-.173-.242-.216-.305-.253-.38a1.275 1.275 0 0 1-.098-.727c.038-.281.148-.521.286-.674.06-.066.095-.096.255-.226Zm14.111.094-.24-2.58c1.394.563 2.8 1.38 4.159 2.486.16.13.195.16.255.226.138.153.248.393.286.674.038.282-.005.543-.098.727-.037.075-.08.138-.253.38-.224.313-.288.4-.345.458a1.25 1.25 0 0 1-1.387.275c-.075-.032-.168-.087-.494-.291l-.702-.439c-.567-.354-.715-.456-.82-.576a1.253 1.253 0 0 1-.214-.348c-.06-.148-.085-.325-.147-.992Z"
        fill="currentColor"
      />
    </Icon>
  );
});
