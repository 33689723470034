import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CallCalling = forwardRef(function CallCalling(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.462 2.25h.125c.332 0 .59 0 .83.042A2.75 2.75 0 0 1 8.45 3.879c.099.222.161.472.242.795l.014.058.414 1.655.009.036c.14.56.259 1.036.316 1.438.06.43.063.84-.074 1.26-.137.42-.38.75-.681 1.061-.284.292-.66.607-1.103.977l-.029.024-1.612 1.348a13.897 13.897 0 0 0 5.523 5.523l1.348-1.612.024-.029c.37-.443.685-.82.977-1.103.31-.301.64-.544 1.061-.68.42-.138.83-.136 1.26-.075.402.057.878.176 1.438.316l.036.01 1.655.413.058.014c.323.08.573.143.795.242a2.75 2.75 0 0 1 1.587 2.034c.042.238.042.497.042.829v.125c0 .383 0 .686-.066.983-.242 1.084-1.245 1.995-2.348 2.13-.302.038-.562.012-.885-.02l-.047-.004c-2.64-.258-5.015-.917-7.085-1.957-3.074-1.545-5.444-3.915-6.99-6.99-1.039-2.069-1.698-4.444-1.956-7.084L2.37 5.55c-.032-.323-.058-.583-.02-.885.135-1.102 1.046-2.106 2.13-2.348.297-.066.6-.066.983-.066Zm7.396 16.482c1.68.72 3.582 1.196 5.691 1.402.387.038.483.043.604.029.473-.059.963-.504 1.067-.969.026-.116.03-.243.03-.721 0-.42-.003-.537-.019-.629a1.25 1.25 0 0 0-.722-.924c-.085-.038-.197-.069-.605-.171l-1.654-.414c-.607-.151-1.006-.25-1.322-.295-.3-.042-.458-.025-.584.015-.126.042-.265.122-.481.332-.23.222-.494.537-.895 1.017l-1.11 1.328Zm-7.59-7.59 1.328-1.11c.48-.4.795-.665 1.017-.895.21-.216.29-.355.331-.48.042-.127.058-.286.016-.585-.045-.316-.144-.715-.295-1.322L7.25 5.096c-.102-.408-.133-.52-.17-.605a1.25 1.25 0 0 0-.925-.722c-.092-.016-.208-.02-.629-.02-.478 0-.605.005-.721.031-.465.104-.91.594-.969 1.067-.014.121-.009.217.03.604.205 2.11.681 4.01 1.401 5.691ZM13.25 7a.75.75 0 0 1 .75-.75c1.027 0 1.934.639 2.547 1.294.344.367.635.78.844 1.186.201.392.359.842.359 1.27a.75.75 0 1 1-1.5 0c0-.09-.044-.293-.193-.584a3.852 3.852 0 0 0-.605-.847c-.496-.53-1.034-.82-1.452-.82a.75.75 0 0 1-.75-.75ZM14 3.25a.75.75 0 0 0 0 1.5c2.9 0 5.25 2.35 5.25 5.25a.75.75 0 1 0 1.5 0A6.75 6.75 0 0 0 14 3.25Z"
        fill="currentColor"
      />
    </Icon>
  );
});
