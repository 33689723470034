import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Icon } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import PopoverContainer from 'common-components/modal/PopoverContainer';
import { StatusTag } from 'common-components/tags';
import { SubTitle, Text } from 'common-components/typography';
import _ from 'lodash';
import React, { Component } from 'react';
import { ACTIONS, AVAILABLE_ACTIONS } from './actions';
import { ActionButton } from './SingleActionHandler';

class StatusActionItem extends Component<{ status: string; count: number; paymentStatuses?: any; onClick?: any }> {
  render() {
    const { status, count, paymentStatuses, onClick } = this.props;

    const mode = !_.isEmpty(paymentStatuses) && paymentStatuses.length > 1 ? 'PAYMENT_STATUS' : 'ACTION';
    const paymentStatus = !_.isEmpty(paymentStatuses) && paymentStatuses.length === 1 ? paymentStatuses[0].name : '';

    return (
      <div
        className="flex-row justify-between p-medium bordered-top hover-bg-tertiary cursor-pointer"
        onClick={() => onClick(status, mode, paymentStatus, count, paymentStatuses)}
      >
        {/* TODO Clean up status tags */}
        <StatusTag status={status} size={'small'} />
        {/* {statusTags[1] && <StatusTag status={statusTags[1]} size={'small'} />} */}
        {/*<span className="bg-secondary ph-small text-color-black rounded text-weight-bold">{status}</span>*/}
        <div>
          <Text className="mr-small">{count} items</Text>
          <Icon type="right" />
        </div>
      </div>
    );
  }
}

class PaymentStatusActionItem extends Component<{ status: string; count: number; paymentStatus?: any; onClick?: any }> {
  render() {
    const { status, count, paymentStatus, onClick } = this.props;

    return (
      <div
        className="flex-row justify-between p-medium bordered-top hover-bg-tertiary cursor-pointer"
        onClick={() => onClick(status, 'ACTION', paymentStatus, count, [])}
      >
        {/* TODO Clean up status tags */}
        <StatusTag status={paymentStatus} size={'small'} />
        {/* {statusTags[1] && <StatusTag status={statusTags[1]} size={'small'} />} */}
        {/*<span className="bg-secondary ph-small text-color-black rounded text-weight-bold">{status}</span>*/}
        <div>
          <Text className="mr-small">{count} items</Text>
          <Icon type="right" />
        </div>
      </div>
    );
  }
}

class StatusSelectSheet extends Component<{ statuses: any; onClick: any }> {
  render() {
    const { statuses, onClick } = this.props;

    return (
      <div className="anim-slide-left">
        <div className="pt-medium pb-small ph-medium flex-row align-center justify-center">
          <SubTitle>Actionable Bookings</SubTitle>
          <Tooltip2
            position="bottom"
            content={
              <div className="p-medium">
                <Text className="text-size-regular text-color-white">
                  <b>ACTIONABLE BOOKINGS</b>
                  <br />
                  <br />
                  Only bookings with available bulk actions will appears here.
                  <br />
                  Only one bulk action for your selection and a specific status can be done at a time.
                  <br />
                  <br />
                  Tip: Only some actions can be performed in bulk:
                  <ul>
                    <li>
                      <b>ACCEPT</b>, <b>ACCEPT & CONFIRM</b> and <b>REJECT</b>{' '}
                      <StatusTag status="PENDING" textSize="small" size="small" /> Bookings
                    </li>
                    <li>
                      <b>CONFIRM</b> <StatusTag status="ACCEPTED" textSize="small" size="small" /> Bookings
                    </li>
                    <li>
                      <b>APPROVE</b> <StatusTag status="COMPLETED" textSize="small" size="small" /> or{' '}
                      <StatusTag status="CUSTOMER_CANCELLED_WITH_FEE" textSize="small" size="small" /> Bookings that{' '}
                      <StatusTag status="REQUIRES_APPROVAL" textSize="small" size="small" />
                    </li>
                    <li>
                      <b>SEND FOR PAYMENT</b> <StatusTag status="COMPLETED" textSize="small" size="small" /> or{' '}
                      <StatusTag status="CUSTOMER_CANCELLED_WITH_FEE" textSize="small" size="small" /> Bookings that are{' '}
                      <StatusTag status="READY_FOR_BILLING" textSize="small" size="small" />
                    </li>
                  </ul>
                  For other actions, please go to the Bookings you want to act on.
                </Text>
              </div>
            }
            autoFocus={false}
            openOnTargetFocus={false}
          >
            <Icon type="question-circle" className="mr-x-small text-size-x-large ml-small text-color-blue" />
          </Tooltip2>
        </div>

        {/* Render status selections */}
        {statuses.length > 0 &&
          _.map(statuses, (status, key) => (
            <StatusActionItem
              key={key}
              status={status.bookingStatus}
              count={status.count}
              paymentStatuses={status.paymentStatuses ? status.paymentStatuses : undefined}
              onClick={onClick}
            />
          ))}
        {statuses.length === 0 && (
          <div className="p-medium">
            <Text color="secondary">No actions available.</Text>
          </div>
        )}
      </div>
    );
  }
}

class PaymentStatusSelectSheet extends Component<{
  status: string;
  paymentStatuses: any;
  onClick: any;
  isBackEnable: boolean;
  onClickFullBack: () => void;
}> {
  render() {
    const { status, paymentStatuses, onClick, onClickFullBack, isBackEnable } = this.props;

    return (
      <>
        <div className="anim-slide-left">
          <div className="pt-medium pb-medium ph-medium bg-tertiary text-align-center ">
            {isBackEnable && (
              <div style={{ position: 'absolute', left: 16, top: 16 }}>
                <div className="p-x-small bg-transparent rounded-full cursor-pointer" onClick={onClickFullBack}>
                  <Icon type="arrow-left" />
                </div>
              </div>
            )}
            <div className="text-align-center">
              <StatusTag status={status} size="small" />
              <br />
              <Text size="regular" color="secondary">
                Select a Payment Status
              </Text>
            </div>
          </div>

          {/* Render status selections */}
          {paymentStatuses.length > 0 &&
            _.map(paymentStatuses, (paymentStatus, key) => (
              <PaymentStatusActionItem
                key={key}
                status={status}
                paymentStatus={paymentStatus.name}
                count={paymentStatus.count}
                onClick={onClick}
              />
            ))}
          {paymentStatuses.length === 0 && (
            <div className="p-medium">
              <Text color="secondary">No actions available.</Text>
            </div>
          )}
        </div>
      </>
    );
  }
}

class ActionSelectSheet extends Component<{
  onClickBack: () => void;
  status: string;
  paymentStatus: string;
  itemCount: number;
  onClickAction: any;
  bookingsCanRemoveTeamMember: any;
}> {
  _renderActionButton = (action) => {
    const { onClickAction } = this.props;

    const actionProperties = ACTIONS[action];

    if (_.isEmpty(actionProperties)) {
      return null;
    }
    const { key, label, type } = actionProperties;

    const TargetButton =
      type === 'PRIMARY'
        ? PrimaryButton
        : //
        type === 'SECONDARY'
        ? SecondaryButton
        : // Default
          PrimaryButton;

    return (
      <React.Fragment key={key}>
        <TargetButton
          key={key}
          onClick={() => onClickAction(key)}
          className="width-full mb-medium"
          size="large"
          color="blue"
        >
          {label}
        </TargetButton>
        <br />
      </React.Fragment>
    );
  };

  render() {
    const { onClickBack, paymentStatus, status, itemCount, bookingsCanRemoveTeamMember } = this.props;

    const fullAction = !_.isEmpty(paymentStatus) ? status + '__' + paymentStatus : status;

    const actions = [...(AVAILABLE_ACTIONS[fullAction] || [])];

    const filterStatusBookingsCanRemoveTeamMember = _.filter(
      bookingsCanRemoveTeamMember,
      (booking) => booking.status === status,
    );
    if (_.isEmpty(filterStatusBookingsCanRemoveTeamMember) && actions.includes('REMOVE_TEAM_MEMBER'))
      _.remove(actions, (action) => action === 'REMOVE_TEAM_MEMBER');

    return (
      <div className="anim-slide-right">
        <div className="pt-medium pb-medium ph-medium bg-tertiary text-align-center ">
          <div style={{ position: 'absolute', left: 16, top: 16 }}>
            <div className="p-x-small bg-transparent rounded-full cursor-pointer" onClick={onClickBack}>
              <Icon type="arrow-left" />
            </div>
          </div>
          <div className="text-align-center">
            <StatusTag status={status} size="small" className="ml-large" />
            <br />
            <Text size="regular" color="secondary">
              {itemCount} items in selection
            </Text>
          </div>
        </div>
        <div className="p-medium">
          <div className="mb-medium text-align-center">
            <Text color="secondary" size="regular">
              {_.isEmpty(actions) ? 'No Available Actions' : 'Available Actions'}
            </Text>
          </div>

          {_.map(actions, (action) => this._renderActionButton(action))}
        </div>
      </div>
    );
  }
}

interface IMultiActionsContainerProps {
  statuses?: any[];
  onTriggerAction?: any;
  bookingsCanRemoveTeamMember: any;
}

interface IMultiActionsContainerState {
  mode: 'STATUS' | 'ACTION' | 'PAYMENT_STATUS' | string;
  prevMode: string;
  initialMode: string;
  status: string;
  paymentStatus: string;
  itemCount: number;
  paymentStatusList: any;
}

export class MultiActionsContainer extends Component<IMultiActionsContainerProps, IMultiActionsContainerState> {
  state = {
    mode: 'STATUS',
    prevMode: 'STATUS',
    initialMode: 'STATUS',
    status: '',
    paymentStatus: '',
    itemCount: 0,
    paymentStatusList: [],
  };

  _handleSelectStatus = (status, mode, paymentStatus, itemCount, paymentStatusList = []) => {
    const prevMode = this.state.mode;
    this.setState({
      mode: mode,
      status,
      paymentStatus,
      itemCount,
      prevMode: prevMode,
    });
    if (mode === 'PAYMENT_STATUS') this.setState({ paymentStatusList: paymentStatusList });
  };

  _handleSelectAction = (action) => {
    const { status } = this.state;
    const { onTriggerAction } = this.props;
    onTriggerAction({ action, status });
  };

  _onClickBack = () => this.setState({ mode: this.state.prevMode });

  _onClickFullBack = () => this.setState({ mode: this.state.initialMode });

  render() {
    // statuses = { PENDING: 0, ACCEPTED: 2, ...}
    const { statuses, bookingsCanRemoveTeamMember } = this.props;

    const availableActions = _.keys(AVAILABLE_ACTIONS);

    const listOfStatuses = _.chain(statuses).keys().intersectionWith(availableActions).value();

    const rawBookingStatusList = _.map(listOfStatuses, (list) => {
      const splittedStatuses = list.split('__');
      return {
        bookingStatus: splittedStatuses[0],
        count: statuses[list],
        paymentStatus: !_.isEmpty(splittedStatuses[1]) ? splittedStatuses[1] : '',
      };
    });

    const bookingStatusList = [];

    _.map(rawBookingStatusList, (status) => {
      const bookingStatusIndex = _.findIndex(bookingStatusList, (o) => {
        return o.bookingStatus === status.bookingStatus;
      });
      if (bookingStatusIndex >= 0) {
        bookingStatusList[bookingStatusIndex]['count'] = bookingStatusList[bookingStatusIndex]['count'] + status.count;
        if (!_.isEmpty(status.paymentStatus)) {
          if (!bookingStatusList[bookingStatusIndex]['paymentStatuses'])
            bookingStatusList[bookingStatusIndex]['paymentStatuses'] = [
              {
                name: status.paymentStatus,
                count: status.count,
              },
            ];
          else
            bookingStatusList[bookingStatusIndex]['paymentStatuses'].push({
              name: status.paymentStatus,
              count: status.count,
            });
        }
      } else {
        if (!_.isEmpty(status.paymentStatus)) {
          bookingStatusList.push({
            bookingStatus: status.bookingStatus,
            count: status.count,
            paymentStatuses: [{ name: status.paymentStatus, count: status.count }],
          });
        } else {
          bookingStatusList.push({
            bookingStatus: status.bookingStatus,
            count: status.count,
          });
        }
      }
    });

    if (
      this.state.mode === 'STATUS' &&
      bookingStatusList.length === 1 &&
      bookingStatusList[0].paymentStatuses &&
      bookingStatusList[0].paymentStatuses.length > 1
    ) {
      this.setState({
        mode: 'PAYMENT_STATUS',
        prevMode: 'PAYMENT_STATUS',
        initialMode: 'PAYMENT_STATUS',
        status: bookingStatusList[0].bookingStatus,
        paymentStatusList: bookingStatusList[0].paymentStatuses,
      });
    }

    // null check.
    if (_.isEmpty(statuses)) {
      return <PopoverContainer showHeader={false}>No actions available</PopoverContainer>;
    }

    return (
      <div className="" style={{ minWidth: '300px', minHeight: '350px' }}>
        {/* Status Select Sheet */}
        {this.state.mode === 'STATUS' && (
          <StatusSelectSheet statuses={bookingStatusList} onClick={this._handleSelectStatus} />
        )}

        {/* Status Select Sheet */}
        {this.state.mode === 'PAYMENT_STATUS' && (
          <PaymentStatusSelectSheet
            status={this.state.status}
            paymentStatuses={this.state.paymentStatusList}
            onClick={this._handleSelectStatus}
            onClickFullBack={this._onClickFullBack}
            isBackEnable={this.state.initialMode === 'STATUS'}
          />
        )}

        {/* Action Select Sheet */}
        {this.state.mode === 'ACTION' && (
          <ActionSelectSheet
            onClickBack={this._onClickBack}
            onClickAction={this._handleSelectAction}
            status={this.state.status}
            paymentStatus={this.state.paymentStatus}
            itemCount={this.state.itemCount}
            bookingsCanRemoveTeamMember={bookingsCanRemoveTeamMember}
          />
        )}
      </div>
    );
  }
}

export class MultiActionHandler extends Component<{
  statusList: any[];
  onTriggerAction: any;
  onSetPopover: any;
  showPopover: boolean;
  bookingsCanRemoveTeamMember: any;
}> {
  _closePopover = () => this.props.onSetPopover(false);
  _openPopover = () => this.props.onSetPopover(true);

  render() {
    const { statusList, onTriggerAction, showPopover, bookingsCanRemoveTeamMember } = this.props;

    const availableActions = _.keys(AVAILABLE_ACTIONS);
    const availableStatus = _.chain(statusList).keys().intersectionWith(availableActions).value();

    // No actions available; don't bother showing the button.
    if (availableStatus.length < 1) {
      return (
        <Text color="white" size="large">
          No actions available.
        </Text>
      );
    }

    const popoverContent = (
      <MultiActionsContainer
        statuses={statusList}
        onTriggerAction={onTriggerAction}
        bookingsCanRemoveTeamMember={bookingsCanRemoveTeamMember}
      />
    );

    return (
      <>
        <Popover2 isOpen={showPopover} onClose={this._closePopover} content={popoverContent}>
          <ActionButton onClick={this._openPopover}>Selection actions...</ActionButton>
        </Popover2>
      </>
    );
  }
}
