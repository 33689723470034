import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const NoteText = forwardRef(function NoteText(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.944 2.25h2.112c1.838 0 3.294 0 4.433.153 1.172.158 2.121.49 2.87 1.238.748.749 1.08 1.698 1.238 2.87.153 1.14.153 2.595.153 4.433v3.338c0 1.048 0 1.724-.182 2.363-.1.35-.24.689-.417 1.008-.323.58-.802 1.059-1.543 1.8l-.078.077-.078.078c-.74.741-1.218 1.22-1.8 1.543a4.744 4.744 0 0 1-1.007.417c-.64.182-1.315.182-2.363.182h-3.338c-1.838 0-3.294 0-4.433-.153-1.172-.158-2.121-.49-2.87-1.238-.748-.749-1.08-1.698-1.238-2.87-.153-1.14-.153-2.595-.153-4.433v-2.112c0-1.838 0-3.294.153-4.433.158-1.172.49-2.121 1.238-2.87.749-.748 1.698-1.08 2.87-1.238 1.14-.153 2.595-.153 4.433-.153ZM6.71 3.89c-1.006.135-1.586.389-2.01.812-.422.423-.676 1.003-.811 2.009-.138 1.028-.14 2.382-.14 4.289v2c0 1.907.002 3.262.14 4.29.135 1.005.389 1.585.812 2.008.423.423 1.003.677 2.009.812 1.028.138 2.382.14 4.289.14h3.172c.453 0 .8-.001 1.078-.009v-.293c0-.899 0-1.648.08-2.242.084-.628.27-1.195.725-1.65.456-.456 1.023-.642 1.65-.726.595-.08 1.345-.08 2.243-.08h.293c.008-.278.009-.625.009-1.078V11c0-1.907-.002-3.261-.14-4.29-.135-1.005-.389-1.585-.812-2.008-.423-.423-1.003-.677-2.009-.812-1.027-.138-2.382-.14-4.289-.14h-2c-1.907 0-3.261.002-4.29.14ZM19.93 16.75c-.926 0-1.553.003-2.025.067-.461.061-.659.169-.789.3-.13.13-.237.327-.3.788-.063.472-.066 1.1-.066 2.025.059-.028.116-.058.173-.09.376-.209.701-.525 1.547-1.37.845-.846 1.161-1.171 1.37-1.547.032-.057.062-.114.09-.173ZM6.25 7A.75.75 0 0 1 7 6.25h8a.75.75 0 0 1 0 1.5H7A.75.75 0 0 1 6.25 7ZM7 10.25a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5H7ZM6.25 15a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
