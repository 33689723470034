import { Form, Icon, notification, Switch } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import FormItem from 'antd/lib/form/FormItem';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { IWorkflowTemplate } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';

interface IUpdateWorkflowTemplateManagementModalProps extends FormComponentProps {
  isOpen: boolean;
  workflowTemplate: IWorkflowTemplate;
  onClose: () => void;
  doUpdateWorkflowTemplateConfigurations: typeof dispatch.workflowStore.doUpdateWorkflowTemplateConfigurations;
}

interface IUpdateWorkflowTemplateManagementModalState {
  isUpdating: boolean;
}

class UpdateWorkflowTemplateManagementModal extends PureComponent<
  IUpdateWorkflowTemplateManagementModalProps,
  IUpdateWorkflowTemplateManagementModalState
> {
  state = {
    isUpdating: false,
  };

  private _onUpdateWorkflowManagement = () => {
    const { form, onClose, doUpdateWorkflowTemplateConfigurations } = this.props;

    form.validateFields(async (errors, values) => {
      if (!errors) {
        this.setState({ isUpdating: true });

        const isUpdateSuccess = await doUpdateWorkflowTemplateConfigurations(values);

        this.setState({ isUpdating: false });

        if (isUpdateSuccess) {
          onClose();

          notification.success({
            message: 'Workflow updated',
            description: 'You have successfully updated this workflow',
          });

          return;
        }

        notification.error({ message: 'Updated failed' });
      }
    });
  };

  render() {
    const { isOpen, onClose, workflowTemplate, form } = this.props;
    const { isUpdating } = this.state;

    return (
      <div>
        {workflowTemplate && (
          <ActionModal isOpen={isOpen} onClose={onClose} title="Workflow management" verticalAlignment="high">
            <Text>Settings relating to workflow management.</Text>

            <FormItem className="flex align-center">
              {form.getFieldDecorator('isAllowApproverFlagIssue', {
                initialValue: workflowTemplate.isAllowApproverFlagIssue,
                valuePropName: 'checked',
              })(<Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />}></Switch>)}
              <Text className="ml-small">Allow approvers to flag issues</Text>
            </FormItem>

            <div className="mt-x2-large flex justify-end">
              <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onUpdateWorkflowManagement} loading={isUpdating}>
                Save
              </PrimaryButton>
            </div>
          </ActionModal>
        )}
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateWorkflowTemplateConfigurations: dispatch.workflowStore.doUpdateWorkflowTemplateConfigurations,
});

export default connect(
  null,
  mapDispatch,
)(Form.create<IUpdateWorkflowTemplateManagementModalProps>()(UpdateWorkflowTemplateManagementModal));
