import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const AlignHorizontally = forwardRef(function AlignHorizontally(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 3a.75.75 0 0 0-1.5 0v4.25H7.948c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.08.595-.08 1.345-.08 2.243v.104c0 .899 0 1.648.08 2.242.084.628.27 1.195.726 1.65.455.456 1.022.642 1.65.726.594.08 1.344.08 2.242.08h3.302V21a.75.75 0 1 0 1.5 0v-4.25h3.302c.899 0 1.648 0 2.243-.08.627-.084 1.194-.27 1.65-.726.455-.455.64-1.022.725-1.65.08-.594.08-1.344.08-2.242v-.104c0-.899 0-1.648-.08-2.242-.084-.628-.27-1.195-.725-1.65-.456-.456-1.023-.642-1.65-.726-.595-.08-1.344-.08-2.243-.08H12.75V3ZM12 15.25h4c.964 0 1.612-.002 2.095-.066.461-.063.659-.17.789-.3.13-.13.238-.328.3-.79.065-.482.066-1.13.066-2.094s-.001-1.612-.066-2.095c-.062-.461-.17-.659-.3-.789-.13-.13-.328-.237-.79-.3-.482-.064-1.13-.066-2.094-.066H8c-.964 0-1.611.002-2.094.067-.462.062-.66.169-.79.3-.13.13-.237.327-.3.788-.064.483-.066 1.131-.066 2.095 0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.789.3.483.064 1.13.066 2.094.066h4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
