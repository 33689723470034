import { Icon, Popover } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { Paragraph, Text } from 'common-components/typography';
import { IAlertBannerWarning } from 'interfaces/subscription-interfaces';
import React from 'react';
import { state } from 'stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';
import {
  CANCEL_SUBSCRIPTION_LINKS,
  MAIL_SUBJECT_HEADERS,
} from 'views/account-management/subscription-management/utils/constants';

interface ICancelSubscriptionBannerProps {
  alertBannerWarning: IAlertBannerWarning;
  portalUser: typeof state.authStore.portalUser;
}

const CancelledSubscriptionBanner: React.FC<ICancelSubscriptionBannerProps> = ({ alertBannerWarning, portalUser }) => {
  const canResumeSubscription = PermissionUtils.validatePermission(
    'ResumeSubscriptionAccess',
    portalUser?.permissions?.permissionRoles,
  );
  const numberOfDays = alertBannerWarning.untilDate;

  return (
    <div
      className="rounded ph-x-large mb-medium mt-large"
      style={{
        backgroundColor: 'rgba(214, 52, 52, 0.05)',
        border: '2px solid rgba(194, 48, 48, 0.6)',
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <Text weight="bolder">Your GoodHuman subscription is coming to an end!</Text>
      <Paragraph size="regular" className="mt-medium">
        Access to your organisation's GoodHuman account will be revoked for all users in&nbsp;
        <Text size="regular" weight="bolder">
          {numberOfDays === 0 ? 'today' : `${numberOfDays} day${numberOfDays === 1 ? '' : 's'}`}
        </Text>
      </Paragraph>
      <div className="flex justify-between">
        <Text size="regular">
          Learn how to export your data&nbsp;
          <HyperlinkButton
            fontSize="regular"
            onClick={() => window.open(CANCEL_SUBSCRIPTION_LINKS.EXPORT_DATA_LINK, '_blank')}
          >
            here
          </HyperlinkButton>
          .
        </Text>
        <div className="flex align-center">
          <a
            className={`text-size-regular text-color-${
              canResumeSubscription ? 'blue-action' : 'gray-dark cursor-default'
            } `}
            href={
              canResumeSubscription &&
              `mailto:${CANCEL_SUBSCRIPTION_LINKS.SALES_MAIL_LINK}?subject=${MAIL_SUBJECT_HEADERS.RESUME}`
            }
          >
            Resume my subscription
          </a>
          {!canResumeSubscription && (
            <Popover
              content={<div>Contact the Primary Owner to resume your subscription.</div>}
              placement="bottomRight"
              overlayClassName="rounded-big"
            >
              <Icon type="question-circle" className="text-color-gray-dark ml-x-small" style={{ fontSize: '13px' }} />
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
};

export default CancelledSubscriptionBanner;
