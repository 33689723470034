import {
  Book,
  Box,
  Briefcase,
  Bulb,
  Car,
  Comments,
  Dumbell,
  Happy,
  Home,
  Processor,
  Route,
  Star,
  Sun,
  Users,
  Wrench,
} from './icons';

export const supportCategories = {
  1: {
    id: 1,
    name: 'Assistance With Daily Life',
    type: 'Core Supports',
    color: '#AE80CB', // $lilacRef
    icon: Star,
  },
  2: {
    id: 2,
    name: 'Transport',
    type: 'Core Supports',
    color: '#004D99', // $blueDark2
    icon: Car,
  },
  3: {
    id: 3,
    name: 'Consumables',
    type: 'Core Supports',
    color: '#369B80', // $greenRef
    icon: Box,
  },
  4: {
    id: 4,
    name: 'Assistance With Social and Community Participation',
    type: 'Core Supports',
    color: '#0073E6', // $blue
    icon: Happy,
  },
  5: {
    id: 5,
    name: 'Assistive Technology',
    type: 'Capital Supports',
    color: '#6F6E6D', // $bodyDark1
    icon: Processor,
  },
  6: {
    id: 6,
    name: 'Home Modifications',
    type: 'Capital Supports',
    color: '#1B4B3E', // $greenDark2
    icon: Wrench,
  },
  7: {
    id: 7,
    name: 'Support Coordination',
    type: 'Capacity Building Supports',
    color: '#8CC1D4', // $canaryRef
    icon: Route,
  },
  8: {
    id: 8,
    type: 'Capacity Building Supports',
    name: 'Improved Living Arrangements',
    color: '#567481', // $canary
    icon: Home,
  },
  9: {
    id: 9,
    type: 'Capacity Building Supports',
    name: 'Increased Social and Community Participation',
    color: '#795A8C', // $lilacDark1
    icon: Comments,
  },
  10: {
    id: 10,
    type: 'Capacity Building Supports',
    name: 'Finding and Keeping a Job',
    color: '#6773A8', // $lavendarDark1
    icon: Briefcase,
  },
  11: {
    id: 11,
    type: 'Capacity Building Supports',
    name: 'Improved Relationships',
    color: '#C2E0D8', // $canaryLight1
    icon: Users,
  },
  12: {
    id: 12,
    type: 'Capacity Building Supports',
    name: 'Improved Health and Wellbeing',
    color: '#0066CC', // $lilacRef
    icon: Dumbell,
  },
  13: {
    id: 13,
    type: 'Capacity Building Supports',
    name: 'Improved Learning',
    color: '#384F56', // $blueDark1
    icon: Book,
  },
  14: {
    id: 14,
    type: 'Capacity Building Supports',
    name: 'Improved Life Choices',
    color: '#555F8B', // $greenLight1
    icon: Bulb,
  },
  15: {
    id: 15,
    type: 'Capacity Building Supports',
    name: 'Improved Daily Living Skills',
    color: '#2C7D67', // $canaryDark1
    icon: Sun,
  },
};
