import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import _ from 'lodash';
import { Spinner } from '@blueprintjs/core';
import { CustomUnavailabilityOption } from 'utilities/enum-utils';

interface IRenderAvailabilityProps {
  unavailableTimes: any[];
  cycle: string;
  activeWeek: number;
  activeDate: any;
  isLoading: boolean;
  timezone: string;
}

interface IRenderAvailabilityState {
  showSwitchCycleModal: boolean;
}

class RenderUnavailability extends Component<IRenderAvailabilityProps, IRenderAvailabilityState> {
  state = {
    showSwitchCycleModal: false,
  };

  private _isNoon = (time) => {
    const timeString = moment(time).format('hh:mm a');
    const noonTime = moment(12, 'hh').format('hh:mm a');
    return timeString === noonTime;
  };

  private _renderAbilityItem = (availableTime, isActive?: boolean) => {
    return (
      availableTime && (
        <div className="pv-large">
          {availableTime.unavailabilityOption === CustomUnavailabilityOption.AvailableAllDay ? (
            <Text className="text-color-green text-size-regular">Available 24 hours</Text>
          ) : availableTime.timeRange.length > 0 ? (
            _.map(availableTime.timeRange, (time, index) => (
              <Text
                className="block mb-x-small text-size-regular"
                key={`${availableTime.day}-${index + 1}`}
                color={isActive ? 'secondary' : 'primary'}
              >
                {moment.tz(time.startDateTime, this.props.timezone).format('h:mm a')} -{' '}
                {moment.tz(time.endDateTime, this.props.timezone).format('h:mm a')}{' '}
                {this._isNoon(time.endDateTime) && (
                  <Text className="text-color-secondary text-size-regular">(noon)</Text>
                )}
              </Text>
            ))
          ) : (
            <Text className="text-color-orange-dark text-size-regular">Unavailable 24 hours</Text>
          )}
        </div>
      )
    );
  };

  private _renderWeekly = (availableTimes) => {
    return availableTimes.map((availableTime, index) => (
      <Row className="bordered-bottom bg-white" key={index}>
        <Col span={8} className="bg-white">
          <div className="pv-large pl-medium">
            <Text className="text-size-regular">{availableTime.day}</Text>
          </div>
        </Col>
        <Col span={14} className="bg-white">
          {this._renderAbilityItem(availableTime)}
        </Col>
      </Row>
    ));
  };

  render() {
    const { unavailableTimes, isLoading } = this.props;

    return (
      <div>
        {isLoading ? (
          <Spinner size={80} />
        ) : (
          <div className="bg-quaternary" style={{ maxHeight: '560px', overflowY: 'auto' }}>
            {this._renderWeekly(unavailableTimes)}
          </div>
        )}
      </div>
    );
  }
}

export default RenderUnavailability;
