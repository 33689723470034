import React, { useState } from 'react';
import _ from 'lodash';
import { Text, SubTitle } from 'common-components/typography';
import { Button, Col, Icon, Input, Row, Upload } from 'antd';
import { FormComponentProps, ValidationRule } from 'antd/es/form';
import { IFormElement } from 'views/form-builder/shared/form-interface';
import { getValueFromPropertyByKey } from 'views/form-builder/shared/form-builder-utils';
import FormItem from 'antd/es/form/FormItem';
import { FormElementType } from 'views/form-builder/shared/form-enum';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { HyperlinkButton } from 'common-components/buttons';
import { Image } from '@good/ui/core';

type IAttachmentInputProps = {
  element: IFormElement;
  isRequired?: boolean;
};

enum FormModeType {
  EDIT,
  VIEW,
  ADD,
}

export const AttachmentInput = ({ element, isRequired }: IAttachmentInputProps) => {
  const fieldText = element.properties.general.find((x) => x.key === 'fieldTitle').value;
  const caption = element.properties.appearance.find((x) => x.key === 'captionText').value;

  const fieldTextFormat = (
    <>
      {fieldText}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bold">{fieldTextFormat}</SubTitle>
      <Upload className="mb-medium mt-x-small">
        <Button>
          <Icon type="plus" /> Upload files
        </Button>
      </Upload>
      <CaptionText caption={caption} />
    </div>
  );
};
export type AttachmentInfo = {
  name: string;
  documentId: string;
  documentUrl?: string;
};
interface IEditAttachmentInputProps extends FormComponentProps {
  value?: AttachmentInfo[];
  element: IFormElement;
  formMode: FormModeType;
}

export const EditAttachmentInput = ({ value, element, form, formMode }: IEditAttachmentInputProps) => {
  const [selectedFiles, setSelectedFiles] = useState<RcFile[]>([]);
  const [oldDocument, setOldDocument] = useState<AttachmentInfo[]>(value || []);

  const { id } = element;
  const { getFieldDecorator } = form;
  let fieldText = getValueFromPropertyByKey(element.properties.general, 'fieldTitle');
  const multipleFile = getValueFromPropertyByKey(element.properties.configuration, 'multipleAttachment');
  const caption = getValueFromPropertyByKey(element.properties.appearance, 'captionText');
  const maxFiles = multipleFile
    ? getValueFromPropertyByKey(element.properties.configuration, 'multipleAttachmentValue') || 1
    : 1;

  const totalFile = () => (selectedFiles || []).length + oldDocument.length;

  const helperText: string =
    totalFile() === 0 ? 'EMPTY' : multipleFile ? `${totalFile()} OUT OF ${maxFiles} FILES UPLOADED` : 'UPLOADED';

  const rules: ValidationRule[] = [];

  const isRequired = getValueFromPropertyByKey(element.properties.general, 'require');
  if (isRequired) {
    fieldText = (
      <>
        {fieldText}
        <Text color='red'> *</Text>
      </>
    );

    rules.push({
      validator: (rule: any, value: UploadChangeParam, cb: (msg?: string) => void) => {
        if (_.isEmpty(oldDocument) && (_.isEmpty(value) || _.isEmpty(value.fileList))) {
          cb('This field is required.');
        } else {
          cb();
        }
      },
    });
  }

  rules.push({
    validator: (rule: any, value: any, cb: (msg?: string) => void) => {
      if (!value || !value.fileList || !value.fileList) {
        cb();
      } else {
        let tempFile = [];
        let tempOldDocument = [];
        if (value) {
          tempFile = value.fileList || [];
          tempOldDocument = value.oldDocument;
        }
        tempOldDocument = tempOldDocument === undefined ? oldDocument : tempOldDocument;

        const files = [...tempOldDocument, ...tempFile];
        const countFile = files.length;
        countFile > maxFiles ? cb(`Maximum files is ${maxFiles}`) : cb();
      }
    },
  });

  rules.push({
    validator: (rule: any, value: UploadChangeParam, cb: (msg?: string) => void) => {
      if (!value || !value.fileList || !value.fileList) {
        cb();
      } else {
        const isValidType = value.fileList.every((file: any) => _hasExtension(file));
        !isValidType ? cb(`This file extension is not supported, please choose another format.`) : cb();
      }
    },
  });

  rules.push({
    validator: (rule: any, value: UploadChangeParam, cb: (msg?: string) => void) => {
      if (!value || !value.fileList || !value.fileList) {
        cb();
      } else {
        const isLt2M = value.fileList.every((file: any) => file.size / 1024 / 1024 < 25);
        !isLt2M ? cb(`Files must be smaller than 25MB.`) : cb();
      }
    },
  });

  const _hasExtension = (file: RcFile) => {
    return new RegExp(
      '(' +
        [
          '.jpeg',
          '.jpg',
          '.png',
          '.gif',
          '.pdf',
          '.txt',
          '.ppt',
          '.pptx',
          '.doc',
          '.docx',
          '.csv',
          '.xls',
          '.xlsx',
          '.xlsm',
        ]
          .join('|')
          .replace(/\./g, '\\.') +
        ')$',
    ).test(file.name.toLowerCase());
  };

  const _validateFile = (file: RcFile, fileList: RcFile[]) => {
    setSelectedFiles([...selectedFiles, ...fileList]);
    return false;
  };

  const _removeFile = (file: RcFile) => {
    const newList = selectedFiles.filter((f) => f.uid !== file.uid);
    setSelectedFiles([...newList]);
  };

  const _onClickRemoveSelectedFiles = (file: RcFile) => {
    const newList = selectedFiles.filter((f) => f.uid !== file.uid);
    _removeFile(file);
    if (_.isEmpty(newList) && _.isEmpty(oldDocument)) {
      form.setFields({
        [`${id},value`]: {
          value: null,
          errors: isRequired ? [new Error('This field is required.')] : [],
        },
      });
    } else {
      form.setFields({
        [`${id},value`]: {
          value: {
            oldDocument: oldDocument,
            fileList: [...newList],
          },
        },
      });
      form.validateFields([`${id},value`]);
    }
  };

  const _onClickRemoveOldDocument = (index) => {
    const newList = _.cloneDeep(oldDocument);
    newList.splice(index, 1);
    setOldDocument([...newList]);
    if (_.isEmpty(newList) && _.isEmpty(selectedFiles)) {
      form.setFields({
        [`${id},value`]: {
          value: null,
          errors: isRequired ? [new Error('This field is required.')] : [],
        },
      });
    } else {
      form.setFields({
        [`${id},value`]: {
          value: {
            oldDocument: newList,
            fileList: [...selectedFiles],
          },
        },
      });
      form.validateFields([`${id},value`]);
    }
  };

  return (
    <div className='flex-column width-full'>
      <SubTitle weight='bold'>
        {fieldText} ({helperText})
      </SubTitle>

      {(selectedFiles || []).map((file, index) => (
        <Row key={index}>
          <Col md={6}>
            <Icon type='file' className='mr-x-small' />
            <b>{file.name}</b>
          </Col>
          <Col>
            <HyperlinkButton className='ml-x-small' onClick={() => _onClickRemoveSelectedFiles(file)}>
              Remove...
            </HyperlinkButton>
          </Col>
        </Row>
      ))}

      {oldDocument.map((doc, index) => (
        <>
          <FormItem className='m-none'>
            {getFieldDecorator([id, `oldDocument[${index}]`] as never, {
              initialValue: oldDocument[index],
            })(<Input type='hidden' />)}
          </FormItem>
          <Row>
            <Col md={6}>
              <Icon type='file' className='mr-x-small' />
              <b>{doc.name}</b>
              {doc.documentUrl && (
                <div
                  className='!pointer-events-auto'
                  onClick={() => window.open(doc.documentUrl)}
                  onKeyDown={() => window.open(doc.documentUrl)}
                  role='button'
                  tabIndex={0}
                >
                  <Image className='p-2' radius='md' src={doc.documentUrl} />
                </div>
              )}
            </Col>
            <Col>
              {formMode !== 'VIEW' && (
                <HyperlinkButton className='ml-x-small' onClick={() => _onClickRemoveOldDocument(index)}>
                  Remove...
                </HyperlinkButton>
              )}
            </Col>
          </Row>
        </>
      ))}

      <FormItem className='m-none'>
        {getFieldDecorator([id, 'value'] as never, {
          rules: rules,
        })(
          <Upload
            className='mb-medium mt-x-small'
            multiple={multipleFile}
            fileList={selectedFiles}
            beforeUpload={_validateFile}
            showUploadList={false}
            accept={
              '.csv,text/plain,application/pdf,application/,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,image/*'
            }
            onRemove={_removeFile}
          >
            {totalFile() < (multipleFile ? maxFiles : 1) && (
              <Button>
                <Icon type='plus' /> Upload files
              </Button>
            )}
          </Upload>,
        )}
      </FormItem>

      <FormItem className='m-none'>
        {getFieldDecorator([id, 'type'] as never, {
          initialValue: FormElementType.FILE_UPLOAD,
        })(<Input type='hidden' />)}
      </FormItem>

      <CaptionText totalFile={`${totalFile()}/${multipleFile ? maxFiles : 1}`} caption={caption} />
    </div>
  );
};

interface ICaptionTextProps {
  totalFile?: string;
  caption: string;
}

const CaptionText = ({ totalFile, caption }: ICaptionTextProps) => {
  return (
    <>
      <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
        {totalFile ? `${totalFile} files * ` : ''}Max size for attachment -{' '}
        <Text className="pt-small" size="x-small" color="secondary" lineHeight={100} weight="bold">
          25MB
        </Text>
      </Text>
      <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
        Accepted file types include -{' '}
        <Text className="pt-small" size="x-small" color="secondary" lineHeight={100} weight="bold">
          .csv, .txt, .pdf, .ppt, .xlsx, .jpeg, .jpg, .gif
        </Text>
      </Text>
      <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </>
  );
};
