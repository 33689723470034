import { OpenSlotsDetailsColumn } from 'common-components/roster-control/roster-weekly/workers/OpenSlotsDetailsColumn';
import _ from 'lodash';
import moment from 'moment';
import * as H from 'history';

import { DayColumn } from 'common-components/roster-control/roster-weekly/common/DayColumn';
import { OpenSlotCard } from 'common-components/roster-control/roster-weekly/workers/OpenSlotCard';
import React, { useMemo } from 'react';
import { IRosterShift } from 'common-components/roster-control/interfaces/roster-interfaces';
import { collateDuplicateShifts } from '../../common/roster-utilities';

// TODO : To roll up/group together open slots that has the same date/time into a single cell.
export function OpenSlotsRow({
  openShifts,
  days,
  refreshData,
  history,
}: {
  openShifts?: any;
  refreshData: () => void;
  days: Date[];
  history: H.History;
}) {
  const numberOfSlots = _.size(openShifts);

  return (
    <div className="flex-row flex-1 position-relative">
      {/* (Left) slots column */}
      <OpenSlotsDetailsColumn numberOfSlots={numberOfSlots} />

      {_.map(days, (day, idx) => (
        <DayColumn
          content={<OpenSlotsList day={day} shifts={openShifts} refreshData={refreshData} history={history} />}
          key={idx}
        />
      ))}
    </div>
  );
}

function OpenSlotsList({
  shifts,
  day,
  refreshData,
  history,
}: {
  shifts: IRosterShift[];
  refreshData: () => void;
  day: Date;
  canClosePopover?: boolean;
  history: H.History;
}) {
  const shiftsForDay = _.chain(shifts)
    .filter((shift) => moment(shift.shiftStartDateTime).isSame(day, 'day'))
    .orderBy(['serviceName', 'startDateTime'], ['asc', 'asc'])
    .value();

  // TODO : Maybe this can be optimised to be grouped by the API instead of the frontend.
  const collatedShifts = useMemo(() => collateDuplicateShifts(shiftsForDay), [shiftsForDay]);

  return (
    <>
      {_.map(collatedShifts, (shift, idx) => (
        <OpenSlotCard shift={shift} key={idx} refreshData={refreshData} history={history} />
      ))}
    </>
  );
}
