import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  FetchWorkTypeRequest,
  fetchWorkTypes,
  FetchWorkTypesResponse,
} from 'stores/queries/work-types/work-type-queries';
import { WorkTypeCategory } from 'utilities/enum-utils';

export type IUseFetchWorkTypesResult = Omit<
  UseQueryResult<FetchWorkTypesResponse['data'], unknown>,
  | 'isError'
  | 'isIdle'
  | 'isFetched'
  | 'isLoadingError'
  | 'isRefetchError'
  | 'isSuccess'
  | 'dataUpdatedAt'
  | 'errorUpdatedAt'
  | 'failureCount'
  | 'errorUpdateCount'
  | 'errorUpdatedAt'
  | 'failureCount'
  | 'errorUpdateCount'
  | 'isFetchedAfterMount'
  | 'isPlaceholderData'
  | 'isPreviousData'
  | 'isStale'
  | 'isRefetching'
>;

export function useFetchWorkTypes(payload?: FetchWorkTypeRequest, skipCache?: boolean): IUseFetchWorkTypesResult {
  const cacheOptions = skipCache ? { staleTime: Infinity, cacheTime: Infinity } : {};
  const queryKey = ['work-types', payload?.workTypeCategory ?? WorkTypeCategory.WorkType];
  if (payload?.state) {
    queryKey.push(payload.state);
  }

  const { data, remove, refetch, isFetching, isLoading, status, error } = useQuery({
    queryFn: async () => (await fetchWorkTypes(payload))?.data,
    queryKey,
    ...cacheOptions,
    onError: (err) => {
      global.newrelic?.noticeError(err, { location: 'useFetchWorkTypes' });
    },
  });

  return {
    data,
    remove,
    refetch,
    isFetching,
    isLoading,
    status,
    error,
  };
}
