import React from 'react';

export function Briefcase(): JSX.Element {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.837 5.25a3.251 3.251 0 0 1 6.326 0H8.837Zm-1.53.014a4.751 4.751 0 0 1 9.386 0c.65.015 1.225.045 1.732.105 1.03.122 1.88.377 2.588.96.24.197.461.417.659.658.582.709.837 1.557.96 2.588.118 1.004.118 2.274.118 3.876v.098c0 1.602 0 2.872-.119 3.876-.122 1.03-.377 1.88-.96 2.588-.197.24-.417.461-.658.659-.709.582-1.557.837-2.588.96-1.004.118-2.274.118-3.876.118H9.45c-1.602 0-2.872 0-3.876-.119-1.03-.122-1.88-.377-2.588-.96a4.751 4.751 0 0 1-.659-.658c-.582-.709-.837-1.557-.96-2.588-.118-1.005-.118-2.274-.118-3.876v-.098c0-1.602 0-2.872.119-3.876.122-1.03.377-1.88.96-2.588a4.75 4.75 0 0 1 .658-.659c.709-.582 1.557-.837 2.588-.96a18.68 18.68 0 0 1 1.732-.104ZM5.75 6.858c-.889.105-1.415.304-1.813.63a3.25 3.25 0 0 0-.45.45c-.326.398-.525.924-.63 1.813-.02.177-.038.365-.051.565l.006.002.034.015.142.06a26.944 26.944 0 0 0 2.592.886c.508.145 1.069.29 1.669.421V11a.75.75 0 0 1 1.5 0v.983a21.02 21.02 0 0 0 3.25.267 21.02 21.02 0 0 0 3.25-.267V11a.75.75 0 0 1 1.5 0v.7a27.702 27.702 0 0 0 3.705-1.09 18.973 18.973 0 0 0 .698-.277l.034-.015.006-.002c-.014-.2-.03-.388-.051-.565-.105-.889-.304-1.415-.63-1.813a3.256 3.256 0 0 0-.45-.45c-.398-.326-.924-.525-1.813-.63-.906-.107-2.087-.108-3.749-.108h-5c-1.662 0-2.843.001-3.749.108Zm9.499 6.643V14a.75.75 0 0 0 1.5 0v-.766a28.957 28.957 0 0 0 4.495-1.32c.005.475.005 1 .005 1.586 0 1.662-.001 2.843-.108 3.749-.105.889-.304 1.415-.63 1.813a3.262 3.262 0 0 1-.45.45c-.398.326-.924.525-1.813.63-.906.107-2.087.108-3.749.108h-5c-1.662 0-2.843-.001-3.749-.108-.889-.105-1.415-.304-1.813-.63a3.256 3.256 0 0 1-.45-.45c-.326-.398-.525-.924-.63-1.813-.107-.906-.108-2.087-.108-3.749 0-.585 0-1.11.005-1.586a28.36 28.36 0 0 0 2.414.807c.621.178 1.322.356 2.081.513V14a.75.75 0 0 0 1.5 0v-.5c1.028.153 2.125.25 3.25.25s2.222-.097 3.25-.25Z"
        fill="#fff"
      />
    </svg>
  );
}
