import { Icon } from 'antd';
import React from 'react';

interface IRecommendedStatusIconProps {
  recommendStatus?: 'recommended' | 'under' | 'over' | string;
  size?: string;
}

function RecommendedStatusIcon({ recommendStatus, size = '24px' }: IRecommendedStatusIconProps) {
  let iconType = 'check';
  let bgClass = 'bg-green-lightest';
  let txtClass = 'text-color-green';

  switch (recommendStatus) {
    case 'recommended':
      iconType = 'check';
      bgClass = 'bg-green-lightest';
      txtClass = 'text-color-green';
      break;
    case 'under':
      iconType = 'arrow-down';
      bgClass = 'bg-orange-lightest';
      txtClass = 'text-color-orange';
      break;
    case 'over':
      iconType = 'arrow-up';
      bgClass = 'bg-red-lightest';
      txtClass = 'text-color-red';
      break;
  }

  return (
    <div
      className={`inline-block rounded-full flex-column align-center justify-center text-align-center line-height-135 mr-small ${bgClass}`}
      style={{ height: size, width: size }}
    >
      <Icon type={iconType} className={txtClass} style={{ fontSize: '12px' }} />
    </div>
  );
}

export default RecommendedStatusIcon;
