import { Check, Copy } from '@good/icons';
import {
  ActionIcon,
  Button,
  CopyButton,
  Group,
  MantineModal as Modal,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
  Tooltip,
} from '@good/ui/core';
import { DateTimePicker } from '@good/ui/dates';
import { useForm, zodResolver } from '@good/ui/form';
import { addDays } from 'date-fns';
import React, { useState } from 'react';
import { reactTrpc } from 'utilities/react-trpc';
import z from 'zod';

type Props = {
  opened: boolean;
  close: () => void;
  serviceProviderId: string;
};

const schema = z
  .object({
    name: z
      .string()
      .max(255, { message: 'Name should have at most 255 characters' })
      .min(2, { message: 'Name should have at least 2 characters' }),
    description: z.string().max(1024, { message: 'Name should have at most 1024 characters' }).optional(),
    expiryOption: z.enum(['7-days', '30-days', '60-days', '90-days', 'custom', 'never'], {
      errorMap: () => ({ message: 'An expiration option must be selected' }),
    }),
    expiresOn: z
      .date()
      .optional()
      .refine((date) => {
        if (!date) return true;
        return date > new Date(Date.now());
      }, 'The date must be in the future'),
  })
  .superRefine((values, ctx) => {
    if (values.expiryOption === 'custom' && !values.expiresOn)
      ctx.addIssue({ code: 'invalid_date', path: ['expiresOn'], message: 'The custom expiry date must be set' });
  });

export const NewAccessTokenModal = ({ opened, close, serviceProviderId }: Props) => {
  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      expiryOption: '',
      expiresOn: undefined,
    },
    validate: zodResolver(schema),
    transformValues(values) {
      if (values.expiryOption === 'never') return { ...values, expiresOn: undefined };
      if (values.expiryOption === '7-days') return { ...values, expiresOn: addDays(new Date(), 7) };
      if (values.expiryOption === '30-days') return { ...values, expiresOn: addDays(new Date(), 30) };
      if (values.expiryOption === '60-days') return { ...values, expiresOn: addDays(new Date(), 60) };
      if (values.expiryOption === '90-days') return { ...values, expiresOn: addDays(new Date(), 90) };
      return values;
    },
  });

  const trpcUtils = reactTrpc.useContext();
  const [generatedToken, setGeneratedToken] = useState<string>();
  const createTokenMutation = reactTrpc.account.accessTokens.create.useMutation();

  const onSubmit = form.onSubmit(async (values) => {
    const { token } = await createTokenMutation.mutateAsync({ ...values, serviceProviderId });
    setGeneratedToken(token);
    await trpcUtils.account.accessTokens.list.invalidate();
    form.reset();
  });

  return (
    <Modal title='New access token' opened={opened} onClose={close} closeButtonProps={{ 'aria-label': 'Close modal' }}>
      {generatedToken ? (
        <Stack>
          <Text>This is your new access token.</Text>

          <Group wrap='nowrap' gap='xs'>
            <TextInput readOnly value={generatedToken} style={{ flexGrow: 1 }} />
            <CopyButton value={generatedToken} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position='right'>
                  <ActionIcon size='xl' color={copied ? 'teal' : 'gray'} variant='subtle' onClick={copy}>
                    {copied ? <Check /> : <Copy />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Group>

          <Text size='sm'>
            Make sure to copy it now and keep it safe. You won&apos;t be able to see it again after closing this modal.
          </Text>

          <Button
            onClick={() => {
              setGeneratedToken(undefined);
              close();
            }}
          >
            Done
          </Button>
        </Stack>
      ) : (
        <form onSubmit={onSubmit}>
          <Stack>
            <TextInput withAsterisk label='Name' {...form.getInputProps('name')} />
            <Textarea
              label='Description'
              description='What will the token be used for?'
              {...form.getInputProps('description')}
            />

            <Stack gap='xs'>
              <Select
                label='Expiration'
                placeholder='Select an expiration time'
                description='The token will automatically expire after this time'
                data={[
                  { value: '7-days', label: '7 days' },
                  { value: '30-days', label: '30 days' },
                  { value: '60-days', label: '60 days' },
                  { value: '90-days', label: '90 days' },
                  { value: 'custom', label: 'Custom' },
                  { value: 'never', label: 'Never' },
                ]}
                {...form.getInputProps('expiryOption')}
              />
              {form.values.expiryOption === 'never' ? (
                <Text size='sm' c='orange'>
                  ⚠ A non-expiring token can be dangerous. Ensure you to delete the token when it is no longer needed.
                </Text>
              ) : null}
            </Stack>

            {form.values.expiryOption === 'custom' ? (
              <DateTimePicker label='Expires on' {...form.getInputProps('expiresOn')} size='md' />
            ) : null}

            <Button type='submit' loading={createTokenMutation.isLoading}>
              Create token
            </Button>
          </Stack>
        </form>
      )}
    </Modal>
  );
};
