import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TmMgmtSearchFilter } from './tm-mgmt-search-filter';
import { TmMgmtSearch } from '../tm-mgmt-search';

type TmMgmtSearchParams = {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
};

export const TmMgmtTeamSearch = ({
  isLoading,
  setIsLoading,
  setPage,
  page,
  pageSize,
  pageTimestamp,
}: TmMgmtSearchParams) => {
  const {
    teamStore: { doFetchAccountTeamList, setAccountTeamFilter },
  } = useDispatch<IRootDispatch>();
  const { accountTeamFilter } = useSelector((state: IRootState) => state.teamStore);

  const searchText = async (txt: string) => {
    setPage(1);
    setAccountTeamFilter({ ...accountTeamFilter, search: txt });
    await doFetchAccountTeamList({
      page: page,
      pageSize: pageSize,
      pageTimestamp: pageTimestamp,
    });

    setIsLoading(false);
  };

  const onUpdateFilter = async (event: CheckboxChangeEvent, category: string, filterName: string) => {
    setIsLoading(true);
    setPage(1);
    const newFilter = { ...accountTeamFilter };

    newFilter.filter[category][filterName] = event.target.checked;
    setAccountTeamFilter({ ...accountTeamFilter, filter: newFilter.filter });
    await doFetchAccountTeamList({
      page: page,
      pageSize: pageSize,
      pageTimestamp: pageTimestamp,
    });

    setIsLoading(false);
  };

  return (
    <div className="pb-medium flex-row bg-white">
      <TmMgmtSearch
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        defaultSearchText={accountTeamFilter.search ?? ''}
        searchText={searchText}
      />
      <TmMgmtSearchFilter onUpdateFilter={onUpdateFilter} />
    </div>
  );
};
