import { IPermissions, IServiceDepartment } from 'interfaces/account-interfaces';
import { Text } from 'common-components/typography';
import { SegmentedItem } from 'views/account-management/add-new-member/panels/permissions-panel/portal/SegmentedItem';
import { ExtendedPortalPermissions } from 'views/account-management/add-new-member/panels/permissions-panel/portal/ExtendedPortalPermissions';
import React from 'react';

interface PortalPermissionsPanelProps {
  permissions: IPermissions;
  serviceDepartments: IServiceDepartment[];
  onChangePermissions: any;
  onChangeRole: any;
  isPrimaryOwner: boolean;
  isTargetUserPrimaryOwner: boolean;
  isTargetUserOwner: boolean;
  hasEditPermission: boolean;
  hasPlanManagement: boolean;
  hasSubscriptionPermission: boolean;
}

export function PortalPermissionsPanel({
  permissions,
  serviceDepartments,
  onChangePermissions,
  onChangeRole,
  isPrimaryOwner,
  isTargetUserPrimaryOwner,
  isTargetUserOwner,
  hasEditPermission,
  hasPlanManagement,
  hasSubscriptionPermission,
}: PortalPermissionsPanelProps) {
  const { hasPortalAccess } = permissions;

  const selectPortalAccess = (value) => {
    const updatedPermissions: IPermissions = { ...permissions, hasPortalAccess: value };
    onChangePermissions(updatedPermissions);
  };

  const actionsDisabled = !hasEditPermission || isTargetUserPrimaryOwner || isTargetUserOwner;

  return (
    <div className="bg-white bordered border-standard-gray rounded mb-large">
      <div className="p-large">
        <div className="flex-row justify-between">
          <div>
            <div className="mb-medium">
              <Text size="x2-large">Workspace</Text>
            </div>
            <Text>
              Can this person access the Workspace and perform <br /> administration functions?
            </Text>
          </div>

          {/* Selection : Allowed / not allowed */}
          <div className="flex-row">
            {(!actionsDisabled || hasPortalAccess) && (
              <SegmentedItem text={'Allowed'} isSelected={hasPortalAccess} onSelect={() => selectPortalAccess(true)} />
            )}

            {(!actionsDisabled || !hasPortalAccess) && (
              <SegmentedItem
                text={'Not Allowed'}
                isSelected={!hasPortalAccess}
                onSelect={() => selectPortalAccess(false)}
              />
            )}
          </div>
        </div>
      </div>

      {/* Extended selection for portal */}
      <div>
        {hasPortalAccess && (
          <ExtendedPortalPermissions
            hasPlanManagement={hasPlanManagement}
            hasSubscriptionPermission={hasSubscriptionPermission}
            permissions={permissions}
            serviceDepartments={serviceDepartments}
            onChangePermissions={onChangePermissions}
            onChangeRole={onChangeRole}
            isPrimaryOwner={isPrimaryOwner}
            isTargetUserPrimaryOwner={isTargetUserPrimaryOwner}
            isTargetUserOwner={isTargetUserOwner}
            actionsDisabled={actionsDisabled}
          />
        )}
      </div>
    </div>
  );
}
