import { RadioGroupState } from '@react-stately/radio';
import React from 'react';
import { RadioGroupProps } from './radio-group';

export type RadioProviderProps = RadioGroupProps & {
  state: RadioGroupState;
};

export const RadioContext = React.createContext<RadioProviderProps>({} as RadioProviderProps);

export function RadioProvider(props: RadioProviderProps) {
  const { children, ...otherProps } = props;

  return <RadioContext.Provider value={otherProps}>{children}</RadioContext.Provider>;
}
