import { MessageChannelType } from 'utilities/enum-utils';
import { timeZone } from 'interfaces/timezone-type';

export interface IAssignedService {
  serviceId: string;
  serviceDepartmentId: string;
  serviceName: string;
  sum: number;
}

export interface ISubscriber {
  messageChannelId: string;
  userId: string;
  isAdmin?: boolean;
  joinedDate?: Date;
  subscriberType?: MessageSubscriberType;
  subscriberId?: string;
  isDeleted?: boolean;
  firstName: string;
  lastName?: string;
  attachmentUrl?: string;
}

export interface IMessage {
  content: IMessageContent;
  messageId: string;
  messageChannelId?: string; // Only when receiving
  createdOn: Date;
  authorUserId: string;
  messageType: MessageType;
  isAnonymous?: boolean;
  serviceName?: string;

  // local variables
  sent?: boolean;
  isLocal?: boolean;
  animateIn?: boolean; // Used to indicate whether to run entry animation for this message or not.
}

export interface IMessageContent {
  text: string;
  attachments: any[]; // to expand on this later once we figure out the attachments structure.
}

export interface IChannel {
  messageChannelId: string;
  serviceProviderId?: string;
  attendanceId?: string;
  serviceId?: string;
  channelType: MessageChannelType;
  channelName?: string;
  lastMessage?: IMessageContent;
  lastAuthorUserId?: string;
  lastMessagedOn?: Date;
  lastMessageAnonymous?: boolean;
  serviceProviderName?: string;
  serviceName?: string;
  referenceId?: string;
  unreadCount?: number;
  address?: any;
  attachmentUrl?: string;
  lastAuthorName?: string;
  subscribers?: ISubscriber[];
  status?: number;

  // Local variables
  lastAccessed?: Date;
  hasUnread?: boolean;
}

export enum MessageSubscriberType {
  PortalUser = 1,
  Customer,
  SupportWorker,
  None,
  ServiceProvider,
}

export enum MessageChannelStatus {
  Active = 1,
  Inactive,
  Expired,
}

export enum MessageAttachmentType {
  Booking = 1,
  Service,
  Users,
  Reply,
  Video,
  Image,
  File,
}

export enum MessagingNotificationStatus {
  Failed = -1,
  Pending,
  Success,
}

export enum MessageType {
  NewMessage = 1,
  DeleteMessage,
  NewSubscriber,
  DeleteSubscriber,
  LeaveChannel,
  NewChannel,
}

export enum MessageOrigin {
  Notification = 1,
  BookingDetailsCustomer,
  BookingDetailsWorker,
}

export interface CreateChannelRequest {
  channelType: MessageChannelType;
  subscriberType: MessageSubscriberType;
  serviceProviderId: string;
  serviceId?: string;
  serviceDepartmentId?: string;
  bookingId?: string;
  subscribers: any[];
  message: { content: { text: string; attachments: [] }; isAnonymous: boolean };
}

// This has extended attributes that are used for local UI states
export interface IExtendedMessage extends IMessage {
  // local index
  index?: number;

  // current User
  currentUserId?: string;
  currentUserName?: string;
  currentUserAvatar?: string;

  // current Channel Name
  currentChannelName?: string;

  // Full subscriber object
  subscriber: ISubscriber;

  // Not in use
  received?: boolean;

  // message options
  previousMessage?: IMessage;
  nextMessage?: IMessage;

  // Animate flag
  animateIn?: boolean;

  // Timezone
  preferredTimezone?: timeZone;
}

export interface IServiceRosterUser {
  supportWorkerId: string;
  firstName: string;
  lastName: string;
  userId: string;
  attachmentPath: string;
  attachmentUrl: string;
}
