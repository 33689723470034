import React, { useMemo } from 'react';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { parseDate, today } from '@internationalized/date';
import { zodResolver } from '@hookform/resolvers/zod';
import { notifications } from '@good/ui/notifications';

import { ZodDateValue } from 'utilities/date-utils';
import { SilServiceContextResolved, useInlineForm, useSilServiceContext } from 'views/sil-service/components';
import { useUpdateService, useTimezonesForSelect, timezoneWithOffsetAndAbbrFormatter } from 'views/sil-service/hooks';

const maxInputWidth = 375;

export enum RosterPeriod {
  '1 week' = '1_WEEK',
  '2 weeks' = '2_WEEKS',
  '4 weeks' = '4_WEEKS',
  '8 weeks' = '8_WEEKS',
}

export const ServiceSettingsForm = () => {
  const { service } = useSilServiceContext() as SilServiceContextResolved;
  const updateService = useUpdateService();
  const { t } = useTranslation('', { keyPrefix: 'silService.settingsTabs.general.serviceSettingsForm' });
  const { t: tMessages } = useTranslation('', { keyPrefix: 'silService.messages' });

  const timezonesAustralia = useTimezonesForSelect('Australia', timezoneWithOffsetAndAbbrFormatter);
  const timezonesOther = useTimezonesForSelect((tz) => !tz.includes('Australia'), timezoneWithOffsetAndAbbrFormatter);
  const todayMemo = useMemo(() => {
    return today(service.timezone);
  }, [service.timezone]);

  const schema = useMemo(() => {
    return z.object({
      rosterPeriod: z.nativeEnum(RosterPeriod, {
        required_error: t('fields.rosterPeriod.validation.required'),
        invalid_type_error: t('fields.rosterPeriod.validation.invalidType'),
      }),
      rosterStartDate: ZodDateValue,
      serviceTimeZone: z.string().nonempty({ message: t('fields.serviceTimeZone.validation.required') }),
      serviceCode: z.string().nullable(),
    });
  }, [t]);
  const InlineForm = useInlineForm<z.infer<typeof schema>>({
    values: {
      rosterPeriod: (service.rosterPeriod as RosterPeriod | null) ?? RosterPeriod['2 weeks'],
      rosterStartDate: service.rosterStartDate
        ? parseDate(moment(service.rosterStartDate).tz(service.timezone).format('YYYY-MM-DD'))
        : todayMemo,
      serviceTimeZone: service.timezone,
      serviceCode: service.siteCode ?? '',
    },
    resolver: zodResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    try {
      await updateService.mutateAsync({
        serviceId: service.serviceId,
        serviceProviderId: service.serviceProviderId,
        data: {
          rosterPeriod: values.rosterPeriod,
          rosterStartDate: values.rosterStartDate.toDate(values.serviceTimeZone),
          timezone: values.serviceTimeZone,
          siteCode: values.serviceCode,
        },
      });
    } catch (e) {
      notifications.show({
        color: 'var(--color-red-main)',
        message: tMessages('updateService.error'),
      });
      throw e;
    }
    notifications.show({
      color: 'var(--color-green-main)',
      message: tMessages('updateService.success'),
    });
  };

  return (
    <InlineForm title={t('title')} onSubmit={onSubmit} timezone={service.timezone}>
      <InlineForm.SelectField
        name='rosterPeriod'
        label={t('fields.rosterPeriod.label')}
        description={t('fields.rosterPeriod.description')}
        placeholder={t('fields.rosterPeriod.placeholder')}
        data={Object.entries(RosterPeriod).map(([label, value]) => ({ value, label }))}
        allowDeselect={false}
        maxInputWidth={maxInputWidth}
      />
      <InlineForm.DatePickerField
        name='rosterStartDate'
        label={t('fields.rosterStartDate.label')}
        description={t('fields.rosterStartDate.description')}
        placeholder={t('fields.rosterStartDate.placeholder')}
        maxInputWidth={maxInputWidth}
      />
      <InlineForm.SelectField
        name='serviceTimeZone'
        label={t('fields.serviceTimeZone.label')}
        description={t('fields.serviceTimeZone.description')}
        placeholder={t('fields.serviceTimeZone.placeholder')}
        data={[
          { group: 'Australia', items: timezonesAustralia },
          { group: 'Other', items: timezonesOther },
        ]}
        allowDeselect={false}
        searchable
        maxInputWidth={maxInputWidth}
      />
      <InlineForm.StaticField label={t('fields.publishServiceDate.label')} value={service.createdOn} />
      <InlineForm.TextField
        name='serviceCode'
        label={t('fields.serviceCode.label')}
        description={t('fields.serviceCode.description')}
        placeholder={t('fields.serviceCode.placeholder')}
        emptyValue={t('fields.serviceCode.emptyValue')}
        showOptional
        maxInputWidth={maxInputWidth}
      />
    </InlineForm>
  );
};
