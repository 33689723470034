import React, { useState } from 'react';
import { Tabs, TabList, Tab, TabPanel, Collection, Key } from 'react-aria-components';
import { Text } from '@good/components';
import { Count } from './count';

export type TabContent = {
  id: number;
  title: string;
  content: string | React.ReactElement;
  count?: number;
};

type SideNavProps = {
  tabs: TabContent[];
  selectedKey?: Key;
  onChangeSelectedKey?: (key: Key) => void;
};

type TabContainerProps = {
  children: React.ReactNode;
};

export const TabContainer = ({ children }: TabContainerProps) => {
  return (
    <div className='h-full w-full outline-none pl-24'>
      <div style={{ maxWidth: 880 }}>{children}</div>
    </div>
  );
};

export const SideNav = ({ tabs, selectedKey: selectedKeyProp, onChangeSelectedKey }: SideNavProps): JSX.Element => {
  const [selectedKeyInternal, setSelectedKeyInternal] = useState<Key | undefined>(undefined);

  const selectedKey = selectedKeyProp ?? selectedKeyInternal;

  const onChangeSelectedKeyInternal = (key: Key) => {
    setSelectedKeyInternal(key);
    onChangeSelectedKey?.(key);
  };

  return (
    <>
      <Tabs
        className='orientation-[horizontal] flex flex-row border-b-gray-light-2 w-full'
        selectedKey={selectedKey}
        onSelectionChange={onChangeSelectedKeyInternal}
      >
        <TabList className='flex w-72 flex-col' aria-label='Sil tabs' items={tabs}>
          {(item) => (
            <Tab
              className=' 
              group
              flex-row
              my-0.5
              w-[300px]
              content-center
              gap-2
              rounded-md
              px-3
              py-2.5
              outline-none
              text-weak
              hover:bg-gray-light-2
              hover:text-base
              active:bg-ocean-light-2
              active:text-accent
              focus-visible:outline-offset-0
              focus-visible:outline-1
              focus-visible:outline-[#EBEAE8]
              focus-visible:shadow-[0_0_0_2px_#ffffff,0_0_0_3.5px_#206E8C]
              focus-visible:text-accent
              aria-selected:outline-none
              aria-selected:text-accent
              aria-selected:bg-ocean-light-2
              aria-selected:hover:bg-ocean-light-1
              aria-selected:active:bg-ocean-light-1
              aria-selected:active:shadow-[0_0_0_1.5px_#206E8C]
              aria-selected:focus-visible:outline-none
              focus-visible:aria-selected:shadow-[0_0_0_2px_#206E8C]
              aria-selected:focus-visible:bg-white
              '
            >
              <Text className='text-[14px] font-semibold'> {item.title} </Text>
              {(item.count || item.count === 0) && <Count count={item.count} />}
            </Tab>
          )}
        </TabList>
        <Collection items={tabs}>
          {(item) => (
            <TabPanel className='w-full'>
              <TabContainer>{item.content}</TabContainer>
            </TabPanel>
          )}
        </Collection>
      </Tabs>
    </>
  );
};
