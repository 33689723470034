import { Tooltip } from 'antd';
import { FaGlobeAsia } from 'react-icons/fa';
import { Text } from 'common-components/typography';
import React from 'react';
import moment from 'moment-timezone';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { timeZone } from 'interfaces/timezone-type';

interface ITimezoneIndicatorProps {
  timezone?: timeZone;
  bordered?: boolean;
  hasIcon?: boolean;
  showTzName?: boolean;
  showAbbr?: boolean;
  placement?: TooltipPlacement;
}

export default function TimezoneIndicator({
  timezone = moment.tz.guess(),
  bordered = true,
  hasIcon = true,
  showTzName = true,
  showAbbr = false,
  placement = 'top',
}: ITimezoneIndicatorProps) {
  return (
    <Tooltip
      title={moment.tz(timezone).zoneAbbr() + ' ' + timezone + ' ' + moment.tz(timezone).format('Z')}
      placement={placement}
    >
      <div className={`flex-row align-center inline-block rounded select-none ${bordered && 'bordered'}`}>
        {hasIcon && (
          <div
            className={`inline-block ph-x-small ${bordered && 'bg-tertiary bordered-right'}`}
            style={{ backgroundColor: `${bordered ? '#FAFAFA' : '#ffffff'}` }}
          >
            <FaGlobeAsia className="text-size-small text-color-tertiary" />
          </div>
        )}
        {showTzName && (
          <Text color="secondary" weight="bold" size="regular" className="ml-x-small">
            {showAbbr ? moment.tz(timezone).zoneAbbr() : timezone.replace('_', ' ')}
            {' (' + moment.tz(timezone).zoneAbbr() + ')'}
          </Text>
        )}
        <Text
          size="small"
          color="secondary"
          className={`${(showTzName || hasIcon || bordered) && 'ml-small'} mr-small`}
        >
          UTC
          {moment.tz(timezone).format('Z')}
        </Text>
      </div>
    </Tooltip>
  );
}
