import React from 'react';

import { Icon } from '../icon';

import type { IconProps } from '../icon';

export function IconClose(props: IconProps) {
  return (
    <Icon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect width="16" height="16" rx="8" fill="#F5F4F4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.27903 5.27903C5.40107 5.15699 5.59893 5.15699 5.72097 5.27903L8 7.55806L10.279 5.27903C10.4011 5.15699 10.5989 5.15699 10.721 5.27903C10.843 5.40107 10.843 5.59893 10.721 5.72097L8.44194 8L10.721 10.279C10.843 10.4011 10.843 10.5989 10.721 10.721C10.5989 10.843 10.4011 10.843 10.279 10.721L8 8.44194L5.72097 10.721C5.59893 10.843 5.40107 10.843 5.27903 10.721C5.15699 10.5989 5.15699 10.4011 5.27903 10.279L7.55806 8L5.27903 5.72097C5.15699 5.59893 5.15699 5.40107 5.27903 5.27903Z"
        fill="#706F6D"
      />
    </Icon>
  );
}
