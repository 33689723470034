import { Avatar, Col, Row } from 'antd';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import * as H from 'history';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IRootState, state } from 'stores/rematch/root-store';
import { ApprovalBookingErrorType } from 'utilities/enum-utils';

interface IBulkApproveWarningModalProps {
  isOpen: boolean;
  missingPaymentMethodBookings: any;
  onCloseModal: () => void;
  hasApprovedBookings: boolean;
  portalUser: typeof state.authStore.portalUser;
  history: H.History;
  bookingErrors: any;
}

class BulkApproveWarningModal extends PureComponent<IBulkApproveWarningModalProps> {
  private _getTextError = (errorType) => {
    switch (errorType) {
      case ApprovalBookingErrorType.SLEEPOVER:
        return 'Sleepover error';
      case ApprovalBookingErrorType.BILLING:
        return 'Billing error';
      case ApprovalBookingErrorType.DISTURBANCE:
        return 'Disturbance error';
      case ApprovalBookingErrorType.MULTIPLE:
        return 'Multiple errors';
      default:
        return '';
    }
  };

  private _onClickDetail = (conflict) => {
    this.props.history.push(`/bookings/details/${conflict.attendanceId}`);
  };

  render() {
    const { isOpen, onCloseModal, portalUser, bookingErrors } = this.props;

    const timezone = portalUser?.timezone;

    return (
      <ActionModal
        title="Bookings can&#39;t be approved for payment"
        isOpen={isOpen}
        onClose={onCloseModal}
        width="x-large"
        verticalAlignment="highest"
      >
        {bookingErrors && (
          <>
            <div className="anim-slide-left">
              <Text>Some bookings have errors and couldn&apos;t be approved for payment.</Text>
              <Row className="text-weight-bold bordered-bottom border-standard-gray p-small mt-large">
                <Col span={8}>
                  <SubTitle>BOOKING DETAIL</SubTitle>
                </Col>
                <Col span={8}>
                  <SubTitle>CUSTOMER</SubTitle>
                </Col>
                <Col span={8}>
                  <SubTitle>ERROR</SubTitle>
                </Col>
              </Row>
              <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}>
                {!bookingErrors?.length && (
                  <Row className="pv-medium">
                    <Text>No booking found.</Text>
                  </Row>
                )}
                {_.map(bookingErrors, (conflict) => (
                  <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                    <Col span={8} className="pr-x2-small">
                      {moment
                        .tz(conflict.startDateTime, timezone)
                        .startOf('day')
                        .isSame(moment.tz(conflict.endDateTime, timezone).startOf('day')) ? (
                        <HyperlinkButton onClick={() => this._onClickDetail(conflict)}>
                          {conflict.serviceName}
                          <br />
                          {moment.tz(conflict.startDateTime, timezone).format('DD/MM/YYYY')}
                          {moment.tz(conflict.startDateTime, timezone).format('hh:mm a')} -{' '}
                          {moment.tz(conflict.endDateTime, timezone).format('hh:mm a')}
                        </HyperlinkButton>
                      ) : (
                        <HyperlinkButton onClick={() => this._onClickDetail(conflict)}>
                          {conflict.serviceName}
                          <br />
                          {moment.tz(conflict.startDateTime, timezone).format('DD/MM/YYYY')}{' '}
                          {moment.tz(conflict.startDateTime, timezone).format('hh:mm a')}
                          {' - '}
                          {moment.tz(conflict.endDateTime, timezone).format('DD/MM/YYYY')}{' '}
                          {moment.tz(conflict.endDateTime, timezone).format('hh:mm a')}
                        </HyperlinkButton>
                      )}
                    </Col>
                    <Col
                      span={8}
                      title={conflict.shiftSlotStatus}
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      className="pr-x2-small"
                    >
                      <Text>
                        <Avatar className="mr-medium" size={'default'} src={conflict.attachmentUrl} />{' '}
                        {conflict.firstName} {conflict.lastName}
                      </Text>
                    </Col>
                    <Col span={8} className="pr-x2-small">
                      {this._getTextError(conflict?.errorType)}
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
            <div className={'mt-x-large'}>
              <Row type={'flex'} justify={'end'}>
                <PrimaryButton size="large" onClick={onCloseModal}>
                  Okay
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

export default connect(mapState, null)(BulkApproveWarningModal);
