import type { PropsWithChildren } from 'react';
import React from 'react';
import type { BaseStyleProps, HTMLProps, ButtonProps, CSS } from '../..';
import { Button, compose, css } from '../..';
import { ButtonProvider } from '../../button/button-provider';
import * as styles from './modal-footer.css';

export type ModalFooterProps = PropsWithChildren<
  BaseStyleProps &
    HTMLProps<HTMLDivElement> & {
      /**
       * Text for default 'OK button'
       */
      okText?: string;

      /**
       *  Text for default 'Cancel button'
       */
      cancelText?: string;

      /**
       * Specify a function that will be called when a user clicks the default OK button
       */
      onOk?(): void;

      /**
       *  Specify a function that will be called when a user clicks the default Cancel button
       *  Default is 'onClose'
       */
      onCancel?(): void;

      /**
       *  Default value is false
       */
      isLoading?: boolean;

      /**
       * Footer styles
       */
      footerStyles?: CSS;

      /**
       * Specifies whether modal content is confirmable or not. Enabling this will gray out the OK button.
       */
      isDisabled?: boolean;
    }
>;

/**
 * @name
 * Modal Footer
 *
 * @example
 * <ModalFooter>
 *    <div> Footer </div>
 * </ModalFooter>
 */
export const ModalFooter = (props: ModalFooterProps) => {
  const { children, okText, cancelText, onOk, onCancel, isLoading = false, footerStyles, isDisabled } = props;

  const buttonStyles: ButtonProps = {
    size: {
      '@initial': 'large',
      '@small': 'medium',
    },
  };

  if (children === null) {
    return null;
  }

  const renderFooter = (
    <div className={css(styles.modalFooterDefaultActions)()}>
      {children ?? (
        <>
          <Button emphasis="quiet" onPress={onCancel} isDisabled={isLoading}>
            {cancelText ?? 'Cancel'}
          </Button>
          <Button emphasis="filled" onPress={onOk} isLoading={isLoading} isDisabled={isDisabled}>
            {okText ?? 'OK'}
          </Button>
        </>
      )}
    </div>
  );

  return (
    <div className={compose(css(styles.reset, styles.modalFooter, footerStyles))}>
      <ButtonProvider {...buttonStyles}>{renderFooter}</ButtonProvider>
    </div>
  );
};
