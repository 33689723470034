import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import { ActiveWorkflowType } from 'utilities/enum-utils';

interface IActiveWorkflowTemplateStatusCountTagProps {
  type: ActiveWorkflowType | string;
  total: number;
  className?: string;
}

class ActiveWorkflowTemplateStatusCountTag extends Component<IActiveWorkflowTemplateStatusCountTagProps, any> {
  render() {
    const { type, total, className } = this.props;
    let label, bgColor, textColor, borderColor;

    switch (type) {
      case ActiveWorkflowType.COMPLETED:
        label = ' Completed';
        bgColor = 'bg-green';
        textColor = 'text-color-green-lightest';
        borderColor = 'border-green';
        break;
      case ActiveWorkflowType.INPROGRESS:
        label = ' In progress';
        bgColor = 'bg-orange-lightest';
        textColor = 'text-color-orange-dark';
        borderColor = 'border-orange-lighter';
        break;
      case ActiveWorkflowType.FLAGGED:
        label = ' Issues flagged';
        bgColor = 'bg-red';
        textColor = 'text-color-red-lightest';
        borderColor = 'border-red';
        break;
      default:
        break;
    }

    const containerClassNames = `${
      className || ''
    } text-align-center pv-x-small ph-small text-size-regular inline-block rounded ${bgColor} ${borderColor} bordered`;
    const textClassNames = `${textColor}`;
    return (
      <div className={containerClassNames}>
        <Text size={'regular'} weight="bolder" className={textClassNames}>
          {total + label}
        </Text>
      </div>
    );
  }
}

export default ActiveWorkflowTemplateStatusCountTag;
