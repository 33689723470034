import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const tableWrapper: CSS = {
  width: '$sizeFull',
};

export const tableHeader: CSS = {
  borderBottomWidth: '$border200 ',
  borderBottomColor: '$bodyLight2',
  borderBottomStyle: '$solid',
};

export const tableColumnHeader: CSS = {
  lineHeight: '100%',
  padding: '$space250 $space150',
  borderBottomWidth: '$border100 ',
  borderBottomColor: '$bodyLight2',
  borderBottomStyle: '$solid',
};

export const tableRowVariants = css({
  height: '1px',
  borderBottomWidth: '$border100 ',
  borderBottomColor: '$bodyLight2',
  borderBottomStyle: '$solid',

  variants: {
    isSelected: {
      true: {
        backgroundColor: '$oceanLight2',
      },
    },
    isError: {
      true: {
        '& > td:first-child': {
          boxShadow: 'inset 4px 0 0 #C33232',
        },
      },
    },
  },
});

export const selectCell: CSS = {
  textAlign: 'center',

  '& > label': {
    marginTop: '$space50',
  },
};

export const tableCell = css({
  height: 'inherit',

  '&.table-cell ~ .table-cell': {
    borderLeftWidth: '$border100 ',
    borderLeftColor: '$bodyLight2',
    borderLeftStyle: '$solid',
  },

  variants: {
    isHavePadding: {
      true: {
        padding: '23px $space200',
      },
    },
  },
});

export const wrapperVariants = css({
  backgroundColor: '$white',
  color: '$black',
});

export type WrapperVariants = Stitches.VariantProps<typeof wrapperVariants>;
