import { Checkbox, Col, DatePicker, Form, Input, notification, Radio, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import Title from 'antd/lib/typography/Title';
import ActivityGroupRowItem from 'common-components/activity-groups/components/ActivityGroupRowItem';
import AddActivityGroupModal from 'common-components/activity-groups/modals/AddActivityGroupModal';
import AddAddressModal from 'common-components/addresses/AddAddressModal';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import NumberInput from 'common-components/inputs/NumberInput';
import TimeInput from 'common-components/time-input/TimeInput';
import { TimezoneIndicator } from 'common-components/timezone';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { IServiceAddress } from 'interfaces/address-interfaces';
import { IActivityGroup } from 'interfaces/service-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { ActivityGroupsAddingType, ServiceLocationType } from 'utilities/enum-utils';
import { v4 as uuidv4 } from 'uuid';

const Option = Select.Option;

interface ISessionDetailsStepPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewGroupServiceSchedule?: typeof dispatch.servicesStore.setNewGroupServiceSchedule;
  newGroupServiceSchedule: typeof state.servicesStore.newGroupServiceSchedule;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  doFetchActivityGroup: typeof dispatch.groupServiceStore.doFetchActivityGroup;
  listActivityGroups: typeof state.groupServiceStore.activityGroupsList;
}

interface ISessionDetailsStepPanelState {
  isLoading: boolean;
  isErrorRequireActivityGroup: boolean;
  scheduleStartDate: any;
  scheduleEndDate: any;
  scheduleDirection: string;
  address: IServiceAddress;
  isEditLocationModalOpen: boolean;
  hasFixedCapacity: boolean;
  isStartingTimeBeforeNowError: boolean;
  isEndTimeBeforeStartTimeError: boolean;
  activityGroupsAddingType: string;
  isAddActivityGroupModalOpen: boolean;
  listCurrentActivityGroups: IActivityGroup[];
}

class SessionDetailsStepPanel extends Component<ISessionDetailsStepPanelProps, ISessionDetailsStepPanelState> {
  state = {
    isLoading: false,
    isErrorRequireActivityGroup: false,
    scheduleStartDate: this.props.newGroupServiceSchedule.scheduleStartDate
      ? moment(this.props.newGroupServiceSchedule.scheduleStartDate)
      : moment().add(1, 'day').set('hours', 8).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
    scheduleEndDate: this.props.newGroupServiceSchedule.scheduleEndDate
      ? moment(this.props.newGroupServiceSchedule.scheduleEndDate)
      : moment().add(1, 'day').set('hours', 9).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
    // scheduleDirection:
    //   this.props.newGroupServiceSchedule && this.props.newGroupServiceSchedule.scheduleDirection
    //     ? this.props.newGroupServiceSchedule.scheduleDirection
    //     : this.props.selectedGroupService && this.props.selectedGroupService.serviceDirection,
    scheduleDirection: ServiceLocationType.FIXEDVENUE,
    address:
      this.props.newGroupServiceSchedule && this.props.newGroupServiceSchedule.address
        ? this.props.newGroupServiceSchedule.address
        : this.props.selectedGroupService && this.props.selectedGroupService.location,
    isEditLocationModalOpen: false,
    hasFixedCapacity:
      this.props.newGroupServiceSchedule && this.props.newGroupServiceSchedule.sessions
        ? this.props.newGroupServiceSchedule.sessions[0].capacity > 0
        : this.props.selectedGroupService && this.props.selectedGroupService.capacity > 0,
    isStartingTimeBeforeNowError: false,
    isEndTimeBeforeStartTimeError: false,
    activityGroupsAddingType: _.get(
      this.props,
      'newGroupServiceSchedule.activityGroupsAddingType',
      ActivityGroupsAddingType.NO,
    ),
    isAddActivityGroupModalOpen: false,
    listCurrentActivityGroups: _.get(this.props, 'newGroupServiceSchedule.sessions[0].activityGroups', []),
  };

  private _roundDate(date) {
    return moment(CommonUtils.formatCeilingDateTime(date));
  }

  private _selectLocationType = (serviceDirection) => {
    this.setState({ scheduleDirection: serviceDirection });
  };

  private _onChangeStartingDate = (startingDate) => {
    const roundedValue = this._roundDate(moment(startingDate));
    let newEndingDate = moment(this.state.scheduleEndDate);
    if (newEndingDate < roundedValue) {
      newEndingDate = moment(roundedValue).add(1, 'hour');
    }
    this.setState({ scheduleStartDate: moment(roundedValue), scheduleEndDate: newEndingDate }, () =>
      this._checkTimeValidity(),
    );
  };

  private _onChangeEndingDate = (endingDate) => {
    const roundedValue = this._roundDate(moment(endingDate));
    this.setState({ scheduleEndDate: roundedValue }, () => this._checkTimeValidity());
  };

  private _openLocationModal = () => {
    this.setState({ isEditLocationModalOpen: true });
  };

  private _closeLocationModal = () => {
    this.setState({ isEditLocationModalOpen: false });
  };

  private _saveSessionAddress = async (addressLocation) => {
    this.setState({ address: addressLocation });
  };

  private _onChangeHasFixedCapacity = async (e) => {
    this.setState({ hasFixedCapacity: e.target.checked });
  };

  private _checkTimeValidity = () => {
    const { scheduleStartDate, scheduleEndDate } = this.state;
    let areDatesValid = true;

    if (
      moment
        .tz(moment(scheduleStartDate).format('YYYY-MM-DD HH:mm'), this.props.selectedGroupService.timezone)
        .isBefore(moment.tz(this.props.selectedGroupService.timezone))
    ) {
      this.setState({ isStartingTimeBeforeNowError: true });
      areDatesValid = false;
    } else {
      this.setState({ isStartingTimeBeforeNowError: false });
    }

    if (scheduleStartDate && scheduleEndDate && moment(scheduleEndDate).isBefore(scheduleStartDate)) {
      this.setState({ isEndTimeBeforeStartTimeError: true });
      areDatesValid = false;
    } else {
      this.setState({ isEndTimeBeforeStartTimeError: false });
    }
    return areDatesValid;
  };

  private _hasErrors = () => {
    const { form } = this.props;
    const { listCurrentActivityGroups, activityGroupsAddingType } = this.state;
    let hasErrors = false;
    form.validateFields((err) => {
      if (err) {
        hasErrors = true;
      }
    });

    if (!this._checkTimeValidity()) {
      hasErrors = true;
    }

    if (activityGroupsAddingType === ActivityGroupsAddingType.CREATE_NEW && listCurrentActivityGroups.length === 0) {
      this.setState({ isErrorRequireActivityGroup: true });
      hasErrors = true;
    }

    return hasErrors;
  };

  private _goToNext = async () => {
    const { setNewGroupServiceSchedule, newGroupServiceSchedule, form } = this.props;
    const { scheduleStartDate, scheduleEndDate, scheduleDirection, address, activityGroupsAddingType } = this.state;

    if (!this._hasErrors()) {
      try {
        await setNewGroupServiceSchedule({
          ...newGroupServiceSchedule,
          scheduleStartDate,
          scheduleEndDate,
          scheduleDirection,
          address: scheduleDirection === ServiceLocationType.ONLINE ? null : address,
          scheduleName: form.getFieldValue('description'),
          activityGroupsAddingType: activityGroupsAddingType,
          sessions: [
            {
              scheduleTimeSlotId: uuidv4(),
              startDateTime: scheduleStartDate,
              endDateTime: scheduleEndDate,
              capacity: form.getFieldValue('capacity'),
              description: form.getFieldValue('description'),
              timezone: this.props.selectedGroupService.timezone,
              activityGroups: this.state.listCurrentActivityGroups,
            },
          ],
        });
        this.props.onNextStep();
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  private _onChangeActivityGroup = async (activityType: ActivityGroupsAddingType) => {
    if (activityType === ActivityGroupsAddingType.APPLY_FROM_SERVICE) {
      this.setState({ isLoading: true });
      await this.props.doFetchActivityGroup({ serviceId: this.props.selectedGroupService.serviceId });
      this.setState({
        activityGroupsAddingType: activityType,
        isLoading: false,
        listCurrentActivityGroups: this.props.listActivityGroups.map((activity) => ({
          ...activity,
          startDateTime: this.state.scheduleStartDate,
          endDateTime: this.state.scheduleEndDate,
        })),
      });
    } else {
      this.setState({
        activityGroupsAddingType: activityType,
        isLoading: false,
        listCurrentActivityGroups: [],
      });
    }

    this.setState({ isErrorRequireActivityGroup: false });
  };

  private _handleRedirectToHelpCenter = () => {
    window.open(
      'https://goodhuman.zendesk.com/hc/en-au/articles/900007727203-Viewing-and-creating-group-sessions#h_01F7Q9CKPWJZXX75ANQ1B4Q4K0',
      '_blank',
    );
  };

  private _onOpenAddActivityGroupModal = () => {
    this.setState({ isAddActivityGroupModalOpen: true });
  };

  private _onCloseAddActivityGroupModal = () => {
    this.setState({ isAddActivityGroupModalOpen: false });
  };

  private _onConfirmAddActivityGroup = (listAddedActivity: IActivityGroup[]) => {
    const updatedActivityGroups = [...listAddedActivity, ...this.state.listCurrentActivityGroups];
    if (_.filter(updatedActivityGroups, (activity) => _.isEmpty(activity.serviceActivityGroupId)).length > 0) {
      this.setState({
        activityGroupsAddingType: ActivityGroupsAddingType.CREATE_NEW,
      });
      this.props.form.setFieldsValue({ activityGroups: ActivityGroupsAddingType.CREATE_NEW });
    }

    this.setState({
      listCurrentActivityGroups: updatedActivityGroups,
      isAddActivityGroupModalOpen: false,
      isErrorRequireActivityGroup: _.isEmpty(updatedActivityGroups),
    });
  };

  private _onChangeActivityGroupList = (list: IActivityGroup[]) => {
    const { activityGroupsAddingType } = this.state;
    const customGroupExist = _.some(list, (activityGroup) => !activityGroup.serviceActivityGroupId);

    if (!_.isEmpty(list)) {
      if (!customGroupExist && activityGroupsAddingType === ActivityGroupsAddingType.CREATE_NEW) {
        this.setState({
          listCurrentActivityGroups: list,
          activityGroupsAddingType: ActivityGroupsAddingType.APPLY_FROM_SERVICE,
        });
        this.props.form.setFieldsValue({
          activityGroups: ActivityGroupsAddingType.APPLY_FROM_SERVICE,
        });
      } else {
        this.setState({
          listCurrentActivityGroups: list,
        });
      }
    } else {
      this.setState({
        listCurrentActivityGroups: [],
        activityGroupsAddingType: ActivityGroupsAddingType.NO,
      });
      this.props.form.setFieldsValue({
        activityGroups: ActivityGroupsAddingType.NO,
      });
    }
  };

  render() {
    const { newGroupServiceSchedule, selectedGroupService, form } = this.props;
    const {
      isLoading,
      isErrorRequireActivityGroup,
      scheduleStartDate,
      scheduleEndDate,
      scheduleDirection,
      address,
      hasFixedCapacity,
      activityGroupsAddingType,
      listCurrentActivityGroups,
    } = this.state;
    const { getFieldDecorator } = form;
    return (
      <div className="anim-slide-left">
        <AddAddressModal
          closeCreateEditAddressModal={this._closeLocationModal}
          isOpen={this.state.isEditLocationModalOpen}
          address={address}
          saveCustomerAddress={this._saveSessionAddress}
        />

        <AddActivityGroupModal
          isOpen={this.state.isAddActivityGroupModalOpen}
          onClose={this._onCloseAddActivityGroupModal}
          scheduleEndDate={scheduleEndDate}
          scheduleStartDate={scheduleStartDate}
          currentActivityGroupsList={listCurrentActivityGroups}
          onConfirm={(listAddedActivity) => this._onConfirmAddActivityGroup(listAddedActivity)}
        />

        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Create a one-off session</Title>
              <Paragraph>{"Next, let's continue with the details for this session."}</Paragraph>

              <Paragraph>
                The <b>start & end date/time</b> represents when the session will start,and when the session will be
                closed.
              </Paragraph>

              <Paragraph>
                The <b>designated location</b> will be where this session will take place at.
              </Paragraph>

              <Paragraph>
                <b>Session capacity</b> signifies the number of customers for this session. You can choose to cap the
                session capacity limit, or allow unlimited capacity for this session by unchecking the capacity limit
                option.
              </Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
              <Title level={4}>Session details</Title>
              <Paragraph>Please enter the session details below.</Paragraph>
              <div className="flex-row align-center">
                <div className={`mr-large ${!this.state.isStartingTimeBeforeNowError && 'mb-large'}`}>
                  <SubTitle>Start Date/Time</SubTitle>
                  <div className="flex-row align-left">
                    <DatePicker
                      size="large"
                      format={'DD/MM/YYYY'}
                      allowClear={false}
                      value={scheduleStartDate}
                      disabledDate={(current) => {
                        return current < moment().startOf('day');
                      }}
                      onChange={(event) => this._onChangeStartingDate(event)}
                      className="mr-small"
                    />
                    <TimeInput
                      size="large"
                      value={moment(scheduleStartDate)}
                      onChange={(event) => this._onChangeStartingDate(event)}
                    />
                  </div>
                  {this.state.isStartingTimeBeforeNowError && (
                    <Text color={'red-dark'} style={{ lineHeight: '200%' }}>
                      Session cannot start in the past
                      {
                        <TimezoneIndicator
                          timezone={selectedGroupService.timezone}
                          showTzName={false}
                          hasIcon={false}
                        />
                      }
                    </Text>
                  )}
                </div>
                <div className={!this.state.isEndTimeBeforeStartTimeError && ' mb-large'}>
                  <SubTitle>End Date/Time</SubTitle>
                  <div className="flex-row align-left">
                    <DatePicker
                      size="large"
                      value={scheduleEndDate}
                      allowClear={false}
                      format={'DD/MM/YYYY'}
                      disabledDate={(current) => {
                        return current < moment(scheduleStartDate).startOf('day');
                      }}
                      onChange={(event) => this._onChangeEndingDate(event)}
                      className="mr-small"
                    />
                    <TimeInput
                      size="large"
                      value={moment(scheduleEndDate)}
                      onChange={(event) => this._onChangeEndingDate(event)}
                    />
                  </div>
                  {this.state.isEndTimeBeforeStartTimeError && (
                    <Text color={'red-dark'} style={{ lineHeight: '200%' }}>
                      End time must be after the start time.
                    </Text>
                  )}
                </div>
              </div>
              <SubTitle>Location</SubTitle>
              <div
                className={`mb-medium bordered border-standard-gray rounded-big shadow-container cursor-pointer p-medium flex-row ${
                  scheduleDirection === ServiceLocationType.FIXEDVENUE && 'bg-blue-lightest'
                }`}
                onClick={() => this._selectLocationType(ServiceLocationType.FIXEDVENUE)}
                style={{ maxWidth: '744px' }}
              >
                <div className={'pr-medium'}>
                  <Radio
                    checked={scheduleDirection === ServiceLocationType.FIXEDVENUE}
                    value={ServiceLocationType.FIXEDVENUE}
                  />
                </div>
                <div>
                  <b>Designated location</b>
                  <br />
                  This session will be held at the designated location below:
                  <br />
                  <br />
                  <SubTitle>Location</SubTitle>
                  {address ? (
                    <Text style={{ whiteSpace: 'pre-line' }}>{CommonUtils.formatFullAddress(address)}</Text>
                  ) : (
                    <Text color={'secondary'}>No address set</Text>
                  )}
                  <br />
                  <HyperlinkButton onClick={this._openLocationModal}>Edit location...</HyperlinkButton>
                </div>
              </div>
              {/*<div*/}
              {/*  className={`bordered border-standard-gray rounded-big shadow-box cursor-pointer p-medium flex-row ${scheduleDirection ===*/}
              {/*    ServiceLocationType.ONLINE && 'bg-blue-lightest'}`}*/}
              {/*  onClick={() => this._selectLocationType(ServiceLocationType.ONLINE)}*/}
              {/*  style={{ maxWidth: '600px' }}*/}
              {/*>*/}
              {/*  <div className={'pr-medium'}>*/}
              {/*    <Radio*/}
              {/*      checked={scheduleDirection === ServiceLocationType.ONLINE}*/}
              {/*      value={ServiceLocationType.ONLINE}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <b>Online</b>*/}
              {/*    <br />*/}
              {/*    This session will be held online.*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className={'mt-large'}>
                <SubTitle>Capacity</SubTitle>
                <div className="pt-x-small">
                  <Checkbox checked={hasFixedCapacity} onChange={this._onChangeHasFixedCapacity}>
                    This session has a <b>capacity limit</b>.
                  </Checkbox>
                </div>

                {hasFixedCapacity && (
                  <div className={'mt-small p-medium rounded bg-quaternary'} style={{ maxWidth: '744px' }}>
                    <Form.Item className="m-none">
                      {getFieldDecorator('capacity', {
                        rules: [
                          {
                            required: true,
                            type: 'number',
                            min: 1,
                            max: 500,
                            message: 'Please enter a number between 1 and 500.',
                          },
                        ],
                        initialValue:
                          newGroupServiceSchedule &&
                          newGroupServiceSchedule.sessions &&
                          newGroupServiceSchedule.sessions[0].capacity
                            ? newGroupServiceSchedule.sessions[0].capacity
                            : selectedGroupService && selectedGroupService.capacity
                            ? selectedGroupService.capacity
                            : null,
                      })(<NumberInput size={'large'} precision={0} min={1} max={500} className={'mr-small'} />)}{' '}
                      customers in session
                    </Form.Item>
                  </div>
                )}
              </div>
              <div className={'mt-large'}>
                <SubTitle>Description</SubTitle>
                <Form.Item className="m-none">
                  {getFieldDecorator('description', {
                    rules: [{ required: true, message: 'Please enter a description' }],
                    initialValue:
                      newGroupServiceSchedule && newGroupServiceSchedule.sessions
                        ? newGroupServiceSchedule.sessions[0].description
                        : null,
                  })(
                    <Input
                      type={'text'}
                      placeholder={'Description'}
                      maxLength={250}
                      size={'large'}
                      style={{ maxWidth: '744px' }}
                    />,
                  )}
                </Form.Item>
              </div>
              <div className={'mt-large'}>
                <SubTitle>Add activity groups to this session</SubTitle>
                <Form.Item>
                  {getFieldDecorator('activityGroups', { initialValue: activityGroupsAddingType })(
                    <Select size="large" style={{ width: '337px' }} onChange={this._onChangeActivityGroup}>
                      {_.map(ActivityGroupsAddingType, (item) => (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </div>
              {activityGroupsAddingType !== ActivityGroupsAddingType.NO && (
                <>
                  <div
                    className={`mt-small p-medium rounded bg-quaternary ${isErrorRequireActivityGroup && 'has-errors'}`}
                    style={{ maxWidth: '744px' }}
                  >
                    {listCurrentActivityGroups.length === 0 ? (
                      <>
                        <Text color="secondary">No activity groups added.</Text>
                        <br></br>
                        <GhostButton className="bg-quaternary" onClick={this._onOpenAddActivityGroupModal}>
                          + Add activity group
                        </GhostButton>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col span={12}>
                            <Text>Activity groups added ({listCurrentActivityGroups.length})</Text>
                          </Col>
                          <Col className="text-align-right" span={12}>
                            <GhostButton className="bg-quaternary" onClick={this._onOpenAddActivityGroupModal}>
                              + Add activity group
                            </GhostButton>
                          </Col>
                        </Row>
                        {listCurrentActivityGroups.map((activity, index: number) => (
                          <ActivityGroupRowItem
                            listItem={listCurrentActivityGroups}
                            key={index}
                            item={activity}
                            scheduleEndDate={scheduleEndDate}
                            scheduleStartDate={scheduleStartDate}
                            onChange={this._onChangeActivityGroupList}
                            selectedIndex={index}
                          />
                        ))}
                      </>
                    )}
                  </div>
                  {isErrorRequireActivityGroup && (
                    <div className="mt-small text-color-red-dark">Activity Group is required</div>
                  )}
                </>
              )}
            </div>
            <div
              className="pv-medium width-full"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" disabled={isLoading} onClick={this.props.onPreviousStep}>
                    Back
                  </SecondaryButton>
                </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton
                    size="large"
                    loading={isLoading}
                    disabled={scheduleDirection === ServiceLocationType.FIXEDVENUE && !address}
                    onClick={this._goToNext}
                  >
                    Next
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewGroupServiceSchedule: dispatch.servicesStore.setNewGroupServiceSchedule,
  doFetchActivityGroup: dispatch.groupServiceStore.doFetchActivityGroup,
});

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  newGroupServiceSchedule: state.servicesStore.newGroupServiceSchedule,
  listActivityGroups: state.groupServiceStore.activityGroupsList,
});

export default connect(mapState, mapDispatch)(Form.create<ISessionDetailsStepPanelProps>()(SessionDetailsStepPanel));
