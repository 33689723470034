import { HyperlinkButton } from 'common-components/buttons';
import { Paragraph, Text } from 'common-components/typography';
import moment from 'moment';
import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { IConfirmCancelInfo } from 'interfaces/subscription-interfaces';
import { CANCEL_SUBSCRIPTION_LINKS } from 'views/account-management/subscription-management/utils/constants';
import { SubscriptionStatus } from 'utilities/enum-utils';

interface IProps {
  timezone: string;
  confirmCancelInfo: IConfirmCancelInfo;
  subscriptionStatus: SubscriptionStatus;
}

const CancelSubscriptionInfo: React.FC<IProps> = ({ confirmCancelInfo, timezone, subscriptionStatus }) => {
  const hasMoreThanTenBookingsAndInvoices =
    confirmCancelInfo.totalUnfinishedBookings >= 10 && confirmCancelInfo.totalUnprocessedInvoices >= 10;

  const bookingLink =
    subscriptionStatus === SubscriptionStatus.ACTIVE
      ? `mailto:${CANCEL_SUBSCRIPTION_LINKS.SUPPORT_MAIL_LINK}`
      : `mailto:${CANCEL_SUBSCRIPTION_LINKS.SALES_MAIL_LINK}`;

  return (
    <div className="pt-large">
      {hasMoreThanTenBookingsAndInvoices ? (
        <Paragraph style={{ fontSize: 18 }}>
          You still have{' '}
          <Text weight="bold" style={{ fontSize: 18 }}>
            {confirmCancelInfo.totalUnfinishedBookings} unfinished bookings
          </Text>{' '}
          and
          <Text weight="bold" style={{ fontSize: 18 }}>
            {' '}
            {confirmCancelInfo.totalUnprocessedInvoices} unprocessed invoices
          </Text>{' '}
          in GoodHuman. Are you sure you want to cancel?
        </Paragraph>
      ) : (
        <Paragraph style={{ fontSize: 18 }}>
          It looks like you haven’t gotten quite set up. Are you sure you want to cancel?
        </Paragraph>
      )}
      <Paragraph style={{ fontSize: 18 }}>
        If you cancel your subscription today, you and your team will be able to continue accessing your GoodHuman
        account until{' '}
        <Text weight="bold" style={{ fontSize: 18 }}>
          {moment.tz(confirmCancelInfo.untilDate, timezone).format('D MMM YYYY')}.
        </Text>
      </Paragraph>
      <div
        className="flex justify-between align-center bordered rounded-big pv-x-large ph-large mv-large"
        style={{ borderColor: '#AAAAAA' }}
      >
        <Paragraph style={{ width: 347, fontSize: 18 }} className="mb-none">
          <Text weight="bold" style={{ fontSize: 18 }}>
            We’re here to help!
          </Text>
          <br />
          If you’re unsure about cancelling your account, we’d love to chat.
        </Paragraph>
        <HyperlinkButton style={{ fontSize: 18 }} onClick={() => window.open(bookingLink, '_blank')}>
          <FaExternalLinkAlt className="text-size-large mr-small" /> Book time with a GoodHuman expert.
        </HyperlinkButton>
      </div>
    </div>
  );
};

export default CancelSubscriptionInfo;
