import { Avatar, Col, Row, Tooltip } from 'antd';
import TimeInput from 'common-components/time-input/TimeInput';
import { Text } from 'common-components/typography';
import { IGroupServiceTimesheetShiftSlot } from 'interfaces/service-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import moment, { Moment } from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import TimesheetShiftSlotRow from './TimesheetShiftSlotRow';

interface IEditTimesheetRowsProps {
  timezone: timeZone;
  selectedShiftSlot: IGroupServiceTimesheetShiftSlot;
  index: number;
  updateShiftSlot: (shiftSlot: IGroupServiceTimesheetShiftSlot) => void;
  onValidateUpdateDateTime: (shiftSlotId: string, isError: boolean) => void;
}

interface IEditTimesheetRowsState {
  startDateTime: Moment;
  endDateTime: Moment;
  inputError: boolean;
}

class EditTimesheetRows extends Component<IEditTimesheetRowsProps, IEditTimesheetRowsState> {
  state = {
    startDateTime: moment(),
    endDateTime: moment(),
    inputError: false,
  };

  componentDidMount() {
    const { timezone, selectedShiftSlot } = this.props;

    const startDateTime = moment(
      moment.tz(selectedShiftSlot.workerCheckedInDateTime, timezone).format('YYYY-MM-DD HH:mm'),
    );

    const endDateTime = moment(
      moment.tz(selectedShiftSlot.workerCheckedOutDateTime, timezone).format('YYYY-MM-DD HH:mm'),
    );

    this.setState({ startDateTime, endDateTime });
  }

  private _onChangeStartDate = (value) => {
    const { selectedShiftSlot, timezone, updateShiftSlot, onValidateUpdateDateTime } = this.props;
    const { endDateTime } = this.state;
    const isValidatedDate = moment(value).isSameOrAfter(endDateTime);
    this.setState({ inputError: isValidatedDate, startDateTime: moment(value) });

    onValidateUpdateDateTime(selectedShiftSlot.supportWorkerAttendanceId, isValidatedDate);

    updateShiftSlot({
      ...selectedShiftSlot,
      workerCheckedInDateTime: moment.tz(moment(value).format('YYYY-MM-DD HH:mm'), timezone).toDate(),
      portalCheckedInDateTime: moment.tz(moment(value).format('YYYY-MM-DD HH:mm'), timezone).toDate(),
      workerCheckedOutDateTime: moment.tz(endDateTime.format('YYYY-MM-DD HH:mm'), timezone).toDate(),
      portalCheckedOutDateTime: moment.tz(endDateTime.format('YYYY-MM-DD HH:mm'), timezone).toDate(),
    });
  };

  private _onChangeEndDate = (value) => {
    const { selectedShiftSlot, timezone, updateShiftSlot, onValidateUpdateDateTime } = this.props;
    const { startDateTime } = this.state;
    const isValidatedDate = moment(value).isSameOrBefore(startDateTime);

    this.setState({ inputError: isValidatedDate, endDateTime: moment(value) });

    onValidateUpdateDateTime(selectedShiftSlot.supportWorkerAttendanceId, isValidatedDate);

    updateShiftSlot({
      ...selectedShiftSlot,
      workerCheckedInDateTime: moment.tz(startDateTime.format('YYYY-MM-DD HH:mm'), timezone).toDate(),
      portalCheckedInDateTime: moment.tz(startDateTime.format('YYYY-MM-DD HH:mm'), timezone).toDate(),
      workerCheckedOutDateTime: moment.tz(moment(value).format('YYYY-MM-DD HH:mm'), timezone).toDate(),
      portalCheckedOutDateTime: moment.tz(moment(value).format('YYYY-MM-DD HH:mm'), timezone).toDate(),
    });
  };

  render() {
    const { timezone, selectedShiftSlot } = this.props;
    const { startDateTime, endDateTime, inputError } = this.state;

    const shiftStartMoment = moment(
      moment.tz(selectedShiftSlot.shiftStartDateTime, timezone).format('YYYY-MM-DD HH:mm'),
    );
    const shiftEndMoment = moment(moment.tz(selectedShiftSlot.shiftEndDateTime, timezone).format('YYYY-MM-DD HH:mm'));

    const isInWarning = !startDateTime.isSame(shiftStartMoment);
    const isOutWarning = !endDateTime.isSame(shiftEndMoment);
    const scheduleDiff = shiftEndMoment.diff(shiftStartMoment);
    const scheduleDifference = moment.duration(scheduleDiff);
    const actualDiff = endDateTime.diff(startDateTime);
    const actualDifference = moment.duration(actualDiff);
    const isActualDiffWarning = scheduleDiff !== actualDiff;
    const differences = moment.duration(Math.abs(scheduleDiff - actualDiff), 'ms');
    let containerClassName = 'text-size-x-large align-center pv-medium';
    if (isInWarning || isOutWarning || isActualDiffWarning) {
      containerClassName += ' bg-orange-lightest';
    }

    const title = (
      <span>
        scheduled time:
        <br /> {moment.tz(selectedShiftSlot.shiftStartDateTime, timezone).format('h:mm A')} -{' '}
        {moment.tz(selectedShiftSlot.shiftEndDateTime, timezone).format('h:mm A')}
      </span>
    );
    return (
      <Row className={containerClassName} type="flex">
        <Col span={4} className="ph-medium">
          <Avatar src={selectedShiftSlot.attachmentUrl} icon="user" className="mr-small" />
          <Text weight="bold">
            {selectedShiftSlot.firstName} {selectedShiftSlot.lastName}
          </Text>
        </Col>
        <Col span={7} className="ph-medium flex">
          <DatePicker
            allowClear={false}
            className={`gh-datepicker rounded mr-small ${inputError && 'text-color-red'}`}
            calendarClassName="gh-datepicker-calendar"
            format="DD/MM/YYYY"
            selected={startDateTime.toDate()}
            onChange={this._onChangeStartDate}
            maxDate={moment.tz(moment(endDateTime), timezone).toDate()}
          />
          <TimeInput
            size="large"
            className={isInWarning ? ' text-color-orange' : ''}
            value={startDateTime}
            onChange={this._onChangeStartDate}
            displayIcon={false}
          />
        </Col>
        <Col span={7} className="ph-medium flex">
          <DatePicker
            allowClear={false}
            className={`gh-datepicker rounded mr-small ${inputError && 'text-color-red'}`}
            calendarClassName="gh-datepicker-calendar"
            format="DD/MM/YYYY"
            selected={endDateTime.toDate()}
            onChange={this._onChangeEndDate}
            maxDate={moment.tz(timezone).toDate()}
            minDate={moment.tz(moment(selectedShiftSlot.workerCheckedInDateTime), timezone).toDate()}
          />
          <TimeInput
            size="large"
            className={isOutWarning ? ' text-color-orange' : ''}
            value={endDateTime}
            onChange={this._onChangeEndDate}
            displayIcon={false}
          />
        </Col>
        <Col span={2} className="ph-medium text-align-right">
          <Tooltip title={title}>
            <Text>{TimesheetShiftSlotRow.formatDuration(scheduleDifference)}</Text>
          </Tooltip>
        </Col>
        <Col span={2} className="ph-medium text-align-right">
          <Text>{TimesheetShiftSlotRow.formatDuration(actualDifference, isActualDiffWarning)}</Text>
        </Col>
        <Col span={2} className="ph-medium text-align-right">
          {isActualDiffWarning ? (
            TimesheetShiftSlotRow.formatDuration(differences, isActualDiffWarning)
          ) : (
            <>
              <Text>
                <b>0</b>
              </Text>
              <Text color="secondary"> hrs</Text>
            </>
          )}
        </Col>
        {inputError && (
          <Col span={24} className="pl-medium pt-small">
            <Text color="red">Start date cannot be same or after end date</Text>
          </Col>
        )}
      </Row>
    );
  }
}

export default EditTimesheetRows;
