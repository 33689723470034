import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const BookPlus = forwardRef(function BookPlus(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 .25a.75.75 0 0 1 .75.75v2.25H21a.75.75 0 0 1 0 1.5h-2.25V7a.75.75 0 0 1-1.5 0V4.75H15a.75.75 0 1 1 0-1.5h2.25V1A.75.75 0 0 1 18 .25Zm-8.055 3H11.5a.75.75 0 0 1 0 1.5H10c-1.435 0-2.436.002-3.192.103-.734.099-1.122.28-1.399.556-.277.277-.457.665-.556 1.4-.101.754-.103 1.756-.103 3.191v6c0 1.435.002 2.436.103 3.192.099.734.28 1.122.556 1.399.277.277.665.457 1.4.556.755.101 1.756.103 3.191.103h2c1.435 0 2.437-.002 3.192-.103.734-.099 1.122-.28 1.4-.556.276-.277.456-.665.555-1.4.102-.755.103-1.756.103-3.191v-5.5a.75.75 0 1 1 1.5 0v5.555c0 1.367 0 2.47-.116 3.337-.122.9-.38 1.658-.982 2.26-.602.602-1.36.86-2.26.982-.867.116-1.97.116-3.337.116H9.945c-1.367 0-2.47 0-3.337-.116-.9-.122-1.658-.38-2.26-.982-.601-.602-.86-1.36-.981-2.26-.117-.867-.117-1.97-.117-3.337V9.945c0-1.367 0-2.47.117-3.337.12-.9.38-1.658.981-2.26.602-.602 1.36-.86 2.26-.981.867-.117 1.97-.117 3.337-.117ZM8 7.25a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5H8ZM7.25 12a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75ZM8 15.25a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 0-1.5H8Z"
        fill="currentColor"
      />
    </Icon>
  );
});
