import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { RcFile } from 'antd/es/upload';
import { Form, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import firebaseApp from 'stores/firebase-app';
import InfoPanel from 'common-components/alerts/InfoPanel';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { FormElementType } from 'views/form-builder/shared/form-enum';
import { IElementValue, ITime } from 'views/form-builder/shared/form-interface';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import FormEditElementsRendered from 'views/form-builder/components/FormEditElementsRendered';
import { AttachmentInfo } from 'views/form-builder/components/form-elements/AttachmentInput/AttachmentInput';
import { FormModeType } from 'views/form-builder/components/EditValueFormModal';

interface IProps extends FormComponentProps {
  onGetValues?: (_onValues) => any;
  initData?: any;
  portalUser: typeof state.authStore.portalUser;
  workflowTemplates: typeof state.workflowStore.workflowTemplates;
  currentForm: typeof state.formBuilderStore.currentForm;
  doGetFormDetailsByVersion: typeof dispatch.formBuilderStore.doGetFormDetailsByVersion;
  getDocumentAndFile: typeof dispatch.formBuilderStore.getDocumentAndFile;
  timezone: string;
}
interface IState {
  formId: string;
  formVersionId: string;
  elementValues: IElementValue[];
  times: ITime[];
}

class EmbeddedFormPanel extends PureComponent<IProps, IState> {
  state = {
    formId: '',
    formVersionId: '',
    elementValues: [],
    times: [],
  };

  private _uploadFileBase = async (document, file) => {
    const { formId } = this.state;
    const { portalUser } = this.props;
    const metadata = {
      customMetadata: {
        documentId: document.documentId,
        serviceProviderId: portalUser.serviceProviderId,
        formId: formId,
      },
    };

    const storageRef = ref(firebaseApp.storage, `${document.uploadBucketUrl}/${file.name}`);
    const uploadFile = uploadBytesResumable(storageRef, file.originFileObj, metadata);

    await uploadFile.on(
      'state_changed',
      () => ({}),
      (error) => {
        notification.error({ message: 'Upload file failed.', description: error });
      },
    );
  };

  private _handleFileUpload = async (attachmentValue, oldDocument: AttachmentInfo[]): Promise<AttachmentInfo[]> => {
    const { getDocumentAndFile } = this.props;
    const { fileList } = attachmentValue || { fileList: [] };
    let newDocument: AttachmentInfo[] = [];

    if (fileList && fileList.length > 0) {
      const documents = fileList.map((file: RcFile) => file.name);
      const {
        data: { documents: documentsResult },
      } = (await getDocumentAndFile(documents)) as any;

      newDocument = documentsResult.map((doc, index) => {
        const file = fileList[index];
        const result: AttachmentInfo = {
          name: file.name,
          documentId: doc.documentId,
        };
        return result;
      });

      await Promise.all(
        documentsResult.map(async (doc, index) => {
          const file = fileList[index];
          await this._uploadFileBase(doc, file);
        }),
      );
    }

    return [...(oldDocument || []), ...newDocument];
  };

  private _mapFormValueToElementValue = async (formValues): Promise<IElementValue[]> => {
    if (_.isEmpty(formValues)) return [];

    const { currentForm } = this.props;
    const { elements } = currentForm;

    const values = await Promise.all(
      _.keys(formValues)
        .filter((x) => {
          const [id] = x.split(',');
          return formValues[`${id},value`] && formValues[`${id},time`]
            ? x.endsWith(',value')
            : x.endsWith(',value') || x.endsWith(',time');
        })
        .map(async (key) => {
          const [id] = key.split(',');
          const elementType = formValues[`${id},type`];
          let value = formValues[key];
          if (elementType === FormElementType.DATE_TIME) {
            const formElement = elements.find(e => e.id === id)
            const propertyType = formElement.properties.general.find(g => g.key === 'fieldTitle').value.toLowerCase()
            value = propertyType === 'date only' ? [formValues[`${id},value`], null] : [formValues[`${id},value`], formValues[`${id},time`]];
          } else if (elementType === FormElementType.FILE_UPLOAD) {
            const attachmentValue = formValues[key];
            const oldDocument: AttachmentInfo[] = (formValues[`${id},oldDocument`] || []) as AttachmentInfo[];
            value = (await this._handleFileUpload(attachmentValue, oldDocument)) as AttachmentInfo[];
          }
          const result: IElementValue = {
            elementId: id,
            elementData: value,
            elementType: elementType,
            timezone: this.props.timezone,
          };
          return result;
        }),
    );
    return values;
  };

  private _onValidForm = () => {
    const { form } = this.props;
    return new Promise((resolve) => {
      form.validateFields(async (errors, values) => {
        const latestDataForm = Object.assign({}, ...this.state.times);

        [null, undefined].includes(errors) && console.log('errors', errors);
        resolve(
          [null, undefined].includes(errors)
            ? await this._mapFormValueToElementValue({ ...values, ...latestDataForm })
            : undefined,
        );
      });
    });
  };

  private _onSetTime = (timeChange: ITime[]) => {
    this.setState({
      times: timeChange,
    });
  };

  async componentDidMount() {
    try {
      const { onGetValues, doGetFormDetailsByVersion, initData } = this.props;
      const workflowTemplate = initData;
      onGetValues(this._onValidForm);
      await doGetFormDetailsByVersion({
        formId: workflowTemplate?.formId,
        versionId: workflowTemplate?.formVersionId,
        isWorkflowFormVersion: true,
      });
      this.setState({
        formId: workflowTemplate?.formId,
        formVersionId: workflowTemplate?.formVersionId,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  }

  render() {
    const { elementValues } = this.state;
    const { currentForm, form, timezone, initData } = this.props;
    const { name: workflowName } = initData;

    return (
      <div>
        <InfoPanel
          className="align-center"
          text={
            <div>
              The workflow <span className={'text-weight-bolder'}>{workflowName}</span> requires you to fill in the
              following form.
            </div>
          }
        />
        <div className="flex-column mt-medium">
          <FormEditElementsRendered
            form={form}
            timezone={timezone}
            setTimes={this._onSetTime}
            formContent={currentForm}
            elementValues={elementValues}
            formMode={FormModeType.EDIT}
          />
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  workflowTemplates: state.workflowStore.workflowTemplates,
  currentForm: state.formBuilderStore.currentForm,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetFormDetailsByVersion: dispatch.formBuilderStore.doGetFormDetailsByVersion,
  getDocumentAndFile: dispatch.formBuilderStore.getDocumentAndFile,
});

export default connect(mapState, mapDispatch)(Form.create<IProps>()(EmbeddedFormPanel));
