import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom-v5-compat';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const CustomerViewTabs = ({ className, children, ...props }: Props): JSX.Element => {
  return (
    <div className={clsx('flex px-9', className)} {...props}>
      {children}
    </div>
  );
};

const Tab = ({
  to,
  children,
  className,
  ...props
}: {
  to: string;
  children?: React.ReactNode;
  className?: string;
}): JSX.Element => (
  <NavLink
    to={to}
    end
    className={({ isActive }) =>
      clsx(
        'nowrap whitespace-nowrap p-6 font-base text-b-sm font-semibold leading-b-sm no-underline',
        { 'border-b-2 border-accent text-accent': isActive, 'text-color-secondary': !isActive },
        className,
      )
    }
    {...props}
  >
    {children}
  </NavLink>
);

CustomerViewTabs.displayName = 'CustomerViewTabs';
CustomerViewTabs.Tab = Tab;
