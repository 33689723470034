import { Row } from 'antd';
import React from 'react';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';

interface IDeclineApproveRequestModalProps {
  title: string;
  content: JSX.Element;
  actionColor?: string;
  primaryButtonText: string;
  isLoading?: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

//todo add loading for this component
function DeclineApproveRequestModal(props: IDeclineApproveRequestModalProps) {
  return (
    <ActionModal isOpen onClose={props.onClose} title={props.title}>
      <Row>{props.content}</Row>
      <ActionModalFooter>
        <SecondaryButton color={props.actionColor} size="large" onClick={props.onClose}>
          Back
        </SecondaryButton>
        <PrimaryButton color={props.actionColor} className="ml-medium" size="large" onClick={props.onConfirm}>
          {props.primaryButtonText}
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
}

export default DeclineApproveRequestModal;
