import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Form, Icon, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import _ from 'lodash';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CommonUtils from 'utilities/common-utils';
import Utils from 'utilities/Utils';
import { AddressInputManualAntd, PlacesAutocompleteWithLocale } from 'components';

type IEditCompanyAddressModalProps = FormComponentProps & {
  isOpen: boolean;
  onClose: () => void;
  doEditCompanyAddress: typeof dispatch.companyStore.doEditCompanyAddress;
  address?: object;
};

type IEditCompanyAddressModalState = {
  title: string;
  isCreated: boolean;
  selectedLocation: boolean;
  selectedLattitude: number;
  selectedLongitude: number;
  address_components: object[];
  inputAddress: string;
  savedInputAddress: string;
  addressLocation: object;
  isManual: boolean;
  isSuccessful: boolean;
};

class EditCompanyAddressModal extends Component<IEditCompanyAddressModalProps, IEditCompanyAddressModalState> {
  state = {
    title: 'Change business address',
    isCreated: false,
    selectedLocation: false,
    selectedLattitude: null,
    selectedLongitude: null,
    address_components: null,
    inputAddress: '',
    savedInputAddress: '',
    addressLocation: null,
    isManual: false,
    isSuccessful: false,
  };

  private _onCloseModal = () => {
    this.setState({ title: 'Change business address', isCreated: false, isManual: false, isSuccessful: false });
    this.props.onClose();
  };

  private _editBusinessAddress = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        const addressLocation = await this._generateAddress();
        await this.props.doEditCompanyAddress(addressLocation);
        this.setState({
          title: 'Change address successful',
          isSuccessful: true,
          isCreated: true,
          addressLocation,
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  private _handleSelect = async (inputAddress) => {
    try {
      let selectedLocation = null;
      await geocodeByAddress(inputAddress)
        .then((results) => (selectedLocation = results[0]))
        .catch(() => this.setState({ selectedLocation: false }));

      if (selectedLocation) {
        const latLng = await getLatLng(selectedLocation);
        this.setState({
          selectedLocation: true,
          savedInputAddress: inputAddress,
          selectedLattitude: latLng.lat,
          selectedLongitude: latLng.lng,
          address_components: selectedLocation.address_components,
        });
      }
    } catch {
      this.setState({ selectedLocation: false });
    }
    this.setState({ inputAddress: inputAddress });
  };

  private _generateAddress = async () => {
    const { form } = this.props;
    if (this.state.isManual) {
      const streetAddress1 = form.getFieldValue('streetaddress');
      const streetAddress2 = form.getFieldValue('streetaddress2') ? form.getFieldValue('streetaddress2') : '';
      const suburb = form.getFieldValue('suburb');
      const state = form.getFieldValue('state');
      const postcode = form.getFieldValue('postcode');
      //TODO: temporarily hardcode as country dropdown is disabled
      const country = 'Australia';
      const fulladdress = streetAddress1 + ' ' + streetAddress2 + ' , ' + suburb + ' , ' + state + ' , ' + country;

      const latlong = await CommonUtils.getLatLongByAddress(fulladdress);
      return {
        state: state,
        geoLat: latlong.lat,
        geoLng: latlong.lng,
        country: country,
        locality: suburb,
        postcode: postcode,
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1,
          streetAddress2,
          locality: suburb,
          state,
          postcode,
          country,
        }),
        streetAddress1: streetAddress1,
        streetAddress2: streetAddress2,
      };
    } else {
      const { selectedLattitude, selectedLongitude, address_components } = this.state;
      const postal_code = _.find(address_components, (address) => {
        return address.types[0] === 'postal_code';
      });

      const country = _.find(address_components, (address) => {
        return address.types[0] === 'country';
      });

      const province = _.find(address_components, (address) => {
        return address.types[0] === 'administrative_area_level_1';
      });

      const locality = _.find(address_components, (address) => {
        return address.types[0] === 'locality';
      });

      const street_number = _.find(address_components, (address) => {
        return address.types[0] === 'street_number';
      });

      const route = _.find(address_components, (address) => {
        return address.types[0] === 'route';
      });

      const subpremise = _.find(address_components, (address) => {
        return address.types[0] === 'subpremise';
      });

      const streetAddress1 = route
        ? `${subpremise ? subpremise.long_name.replace(/\D/g, '') + '/' : ''}${
            street_number ? street_number.long_name : ''
          } ${route.long_name}`
        : '';

      return {
        state: province !== undefined ? province.short_name : '',
        geoLat: selectedLattitude,
        geoLng: selectedLongitude,
        country: country !== undefined ? country.long_name : '',
        locality: locality !== undefined ? locality.long_name : '',
        postcode: postal_code !== undefined ? postal_code.long_name : '',
        streetAddress1,
        streetAddress2: '',
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1,
          streetAddress2: '',
          locality: locality !== undefined ? locality.long_name : '',
          state: province !== undefined ? province.short_name : '',
          postcode: postal_code !== undefined ? postal_code.long_name : '',
          country: country !== undefined ? country.long_name : '',
        }),
      };
    }
  };

  private _handleChange = (inputAddress) => {
    this.setState({ inputAddress: inputAddress });
    if (inputAddress === '') {
      this.setState({ selectedLocation: false });
    }
  };

  private _switchToManual = () => {
    this.setState({ isManual: true, selectedLocation: false, inputAddress: '' });
  };

  private _switchToAuto = () => {
    this.setState({ isManual: false, selectedLocation: false, inputAddress: '' });
  };

  render() {
    const { isOpen, address, form } = this.props;
    const { address_components } = this.state;
    const { getFieldDecorator } = form;

    const defaultStyles = {
      autocompleteItem: {
        backgroundColor: '#000',
        padding: '10px',
        color: '#555555',
        cursor: 'pointer',
      },
      autocompleteItemActive: {
        backgroundColor: '#fafafa',
      },
    };

    const isIncorrectSelectedAddress =
      _.isEmpty(
        _.find(address_components, (address) => {
          return address.types[0] === 'postal_code';
        }),
      ) ||
      _.isEmpty(
        _.find(address_components, (address) => {
          return address.types[0] === 'locality';
        }),
      ) ||
      _.isEmpty(
        _.find(address_components, (address) => {
          return address.types[0] === 'route';
        }),
      );

    return (
      <ActionModal
        title={
          this.state.isSuccessful ? (
            <>
              {this.state.title}
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ) : (
            this.state.title
          )
        }
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width='large'
      >
        {this.state.isCreated ? (
          <>
            <div className='mv-medium anim-slide-left'>
              <SubTitle>Your new business address is</SubTitle>
              <Text size={'x-large'} style={{ whiteSpace: 'pre-line' }}>
                {CommonUtils.formatFullAddress(this.state.addressLocation)}
              </Text>
            </div>

            <ActionModalFooter>
              <PrimaryButton size='large' onClick={this._onCloseModal}>
                Done
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : (
          <>
            <div className='mv-medium anim-slide-left'>
              <div>
                <Paragraph>
                  Change your businesses primary address. This will be used in invoices and other templated documents.
                </Paragraph>
                {!this.state.isManual ? (
                  <>
                    <div className='mt-large' style={{ minHeight: '300px' }}>
                      <SubTitle>Lookup address</SubTitle>
                      <Form.Item className='width-full m-none'>
                        <PlacesAutocompleteWithLocale
                          value={this.state.inputAddress}
                          onSelect={this._handleSelect}
                          onChange={this._handleChange}
                          shouldFetchSuggestions={this.state.inputAddress.length > 2}
                          styles={defaultStyles}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <input
                                value={this.state.inputAddress}
                                {...getInputProps({
                                  placeholder: 'Type your address ...',
                                  className: 'location-search-input width-full pl-small',
                                })}
                              />
                              <div className='autocomplete-dropdown-container'>
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: '#fafafa',
                                        cursor: 'pointer',
                                        borderTop: '1px ridge grey',
                                        borderLeft: '1px ridge grey',
                                        borderRight: '1px ridge grey',
                                      }
                                    : {
                                        backgroundColor: '#ffffff',
                                        cursor: 'pointer',
                                        borderTop: '1px ridge grey',
                                        borderLeft: '1px ridge grey',
                                        borderRight: '1px ridge grey',
                                      };
                                  return (
                                    <div
                                      key={index}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>
                                        <Icon type={'environment'} /> {suggestion.description}
                                      </span>
                                    </div>
                                  );
                                })}
                                {suggestions.length === 0 &&
                                  !_.isEmpty(this.state.inputAddress) &&
                                  !this.state.selectedLocation && <div>No results found.</div>}
                              </div>
                            </div>
                          )}
                        </PlacesAutocompleteWithLocale>
                      </Form.Item>
                    </div>
                    <div>
                      <SubTitle>Selected address</SubTitle>
                      <Text>
                        {!Utils.isEmpty(this.state.savedInputAddress)
                          ? this.state.savedInputAddress
                          : 'No address selected yet.'}
                      </Text>
                      {!Utils.isEmpty(this.state.savedInputAddress) && isIncorrectSelectedAddress && (
                        <>
                          <br />
                          <Text color={'red-dark'}>This address have an incorrect format.</Text>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <React.Fragment>
                    <div style={{ minHeight: '50vh', overflow: 'auto' }}>
                      <AddressInputManualAntd
                        required
                        initialValues={address}
                        form={form}
                        formMapping={{
                          streetAddress1: 'streetaddress',
                          streetAddress2: 'streetaddress2',
                          locality: 'suburb',
                        }}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>

              <ActionModalFooter>
                {!this.state.isManual ? (
                  <HyperlinkButton onClick={this._switchToManual} style={{ float: 'left' }} className={'mt-small'}>
                    Enter the address manually
                  </HyperlinkButton>
                ) : (
                  <HyperlinkButton onClick={this._switchToAuto} style={{ float: 'left' }} className={'mt-small'}>
                    Address lookup tool
                  </HyperlinkButton>
                )}
                <SecondaryButton size='large' className='mr-medium' onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size='large'
                  onClick={this._editBusinessAddress}
                  disabled={!this.state.isManual && (!this.state.selectedLocation || isIncorrectSelectedAddress)}
                >
                  Change address
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditCompanyAddress: dispatch.companyStore.doEditCompanyAddress,
});

export default connect(null, mapDispatch)(Form.create<IEditCompanyAddressModalProps>()(EditCompanyAddressModal));
