import { Col, Row, Skeleton } from 'antd';
import { PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { ITimesheetShiftItem } from 'interfaces/booking-interfaces';
import { ISelectedShiftHour, IShiftItem } from 'interfaces/shift-interfaces';
import moment from 'moment-timezone';
import React from 'react';
import { TimeType } from 'utilities/enum-utils';

type IViewShiftHoursModalProps = {
  isOpen: boolean;
  shiftItem: IShiftItem;
  timesheetItem: ITimesheetShiftItem;
  selectedShiftHour?: ISelectedShiftHour;
  isLoading: boolean;
  onClose: () => void;
};

const ViewShiftHoursModal: React.FC<IViewShiftHoursModalProps> = (props) => {
  const { isOpen, timesheetItem, isLoading, onClose } = props;

  const _onCloseModal = () => {
    if (!isLoading) {
      onClose();
    }
  };

  const _getTimeType = (timeType: string) => {
    switch (timeType) {
      case TimeType.NORMAL:
        return 'Normal hours';
      case TimeType.SLEEPOVER:
        return 'Sleepover hours';
      case TimeType.DISTURBANCE:
        return 'Disturbance';
      default:
        return '';
    }
  };

  return (
    <ActionModal
      title={`Shift breakdown - ${timesheetItem?.referenceId}`}
      isOpen={isOpen}
      onClose={_onCloseModal}
      width='large'
      verticalAlignment='center'
    >
      {isLoading ? (
        <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} />
      ) : (
        <>
          <div className='anim-slide-left mb-large'>
            <Paragraph>
              <SubTitle>Total shift duration</SubTitle>
              <Text>
                {timesheetItem?.startDateTime
                  ? moment
                      .tz(timesheetItem?.startDateTime, timesheetItem?.serviceDetails?.timezone)
                      .format('hh:mm a, D MMM YYYY')
                  : 0}{' '}
                -
                <br />
                {timesheetItem?.endDateTime
                  ? moment
                      .tz(timesheetItem?.endDateTime, timesheetItem?.serviceDetails?.timezone)
                      .format('hh:mm a, D MMM YYYY')
                  : 0}
              </Text>
            </Paragraph>
            <Row className='text-weight-bold bordered-bottom border-standard-gray p-small mt-large'>
              <Col span={6}>
                <SubTitle>TIMETYPE</SubTitle>
              </Col>
              <Col span={8}>
                <SubTitle>START</SubTitle>
              </Col>
              <Col span={8}>
                <SubTitle>END</SubTitle>
              </Col>
              <Col span={2} className='text-align-right'>
                <SubTitle>HOURS</SubTitle>
              </Col>
            </Row>
            <div
              style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}
              className='bordered-bottom border-standard-gray'
            >
              {timesheetItem?.shiftComponents.length
                ? timesheetItem?.shiftComponents.map((shiftComponent, index) => (
                    <Row key={index} type='flex' align='middle' className='p-small border-secondary evenodd'>
                      <Col span={6}>
                        <Text weight='bold'>{_getTimeType(shiftComponent.workType.shiftType)}</Text>
                      </Col>
                      <Col span={8}>
                        <Text>
                          {shiftComponent.startDateTime
                            ? moment
                                .tz(shiftComponent.startDateTime, timesheetItem?.serviceDetails?.timezone)
                                .format('hh:mm a, D MMM YYYY')
                            : 0}
                        </Text>
                      </Col>
                      <Col span={8}>
                        <Text>
                          {shiftComponent.endDateTime
                            ? moment
                                .tz(shiftComponent.endDateTime, timesheetItem?.serviceDetails?.timezone)
                                .format('hh:mm a, D MMM YYYY')
                            : 0}
                        </Text>
                      </Col>
                      <Col span={2} className='text-align-right'>
                        {shiftComponent.duration.toFixed(2)}
                      </Col>
                    </Row>
                  ))
                : null}
            </div>
          </div>

          <ActionModalFooter>
            <PrimaryButton size='large' onClick={_onCloseModal} className='mr-medium' disabled={isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </>
      )}
    </ActionModal>
  );
};

export default ViewShiftHoursModal;
