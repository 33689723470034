import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { extendGroupSessionSchedule } from '../../queries/group-services/group-services-queries';

export type ExtendScheduleCopyOptions = null | {
  copyActivityGroups: boolean;
  copyBookings: boolean;
  copyShifts: boolean;
};

export type ExtendGroupSessionScheduleRequest = {
  extendStartDateTime: Date;
  extendEndDateTime: Date;
  customCopyDate: Date;
  serviceScheduleId: string;
  serviceId: string;
  sessionOptions: ExtendScheduleCopyOptions;
};

// Don't allow mutationFn in the options, since that's the only part we want to maintain.
type ExtendScheduleOptions = Omit<
  UseMutationOptions<unknown, unknown, ExtendGroupSessionScheduleRequest>,
  'mutationFn'
>;

export function useMutationExtendGroupSessionSchedule({ ...options }: ExtendScheduleOptions) {
  return useMutation({
    ...options,
    mutationFn: (request: ExtendGroupSessionScheduleRequest) => extendGroupSessionSchedule(request),
  });
}
