import apiClient from 'utilities/api-client';
import { INVITE_MEMBER_ROLE_TYPE } from 'views/team-v2/utils/constants';
import { Member, PermissionRole } from 'views/team-v2/utils/form-utils';

type InviteTeamMemberStateParams = {
  isCheckingEmail: boolean;
  memberSelected: Member | null;
  advancedPermissionModalVisible: boolean;
  permissionRolesDepartmentRedux: PermissionRole[];
};

type SetCheckingEmailStateValues = InviteTeamMemberStateParams & { isCheckingEmail: boolean };
type SetMemberSelectedValues = InviteTeamMemberStateParams & { memberSelected: Member };
type SetAdvancedPermissionModalVisibleValues = InviteTeamMemberStateParams & {
  advancedPermissionModalVisible: boolean;
};
type SetPermissionRolesDepartmentReduxValues = InviteTeamMemberStateParams & {
  permissionRolesDepartmentRedux: PermissionRole[];
};

type SaveTeamMemberArgs = {
  members: Member[];
  skipSendingInvitation: boolean;
};
const saveTeamMembers = async ({ members, skipSendingInvitation = false }: SaveTeamMemberArgs) => {
  const payload = members.map(
    ({ firstName, lastName, email, mobile, mobileCountryCode, hasWorkerAppAccess, permissions, roles }) => ({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim().toLowerCase(),
      mobile,
      mobileCountryCode,
      hasPortalAccess: !roles.includes(INVITE_MEMBER_ROLE_TYPE.NO_ACCESS),
      hasWorkerAppAccess,
      permissionRoles: permissions,
      isDraft: skipSendingInvitation,
    }),
  );
  await apiClient.post('/api/portal/account/bulk-workers', {
    members: payload,
    skipSendingInvitation: skipSendingInvitation,
  });
};

const initialState: InviteTeamMemberStateParams = {
  isCheckingEmail: false,
  memberSelected: null,
  advancedPermissionModalVisible: false,
  permissionRolesDepartmentRedux: [],
};

const teamV2Store = {
  state: initialState,
  reducers: {
    setCheckingEmailState: (state: InviteTeamMemberStateParams, payload: boolean): SetCheckingEmailStateValues => ({
      ...state,
      isCheckingEmail: payload,
    }),
    setMemberSelected: (state: InviteTeamMemberStateParams, payload: Member): SetMemberSelectedValues => ({
      ...state,
      memberSelected: payload,
    }),
    setAdvancedPermissionModalVisible: (
      state: InviteTeamMemberStateParams,
      payload: boolean,
    ): SetAdvancedPermissionModalVisibleValues => ({
      ...state,
      advancedPermissionModalVisible: payload,
    }),
    setPermissionRolesDepartmentRedux: (
      state: InviteTeamMemberStateParams,
      payload: PermissionRole[],
    ): SetPermissionRolesDepartmentReduxValues => ({
      ...state,
      permissionRolesDepartmentRedux: payload,
    }),
  },
  effects: () => ({
    async doCheckEmailAlreadyExist(payload: { email: string }): Promise<string | undefined> {
      try {
        const result = await apiClient.post<{ isAvailable: boolean; message: string | undefined; email: string }>(
          '/api/portal/account/workers/email-checks',
          payload,
        );

        return result.data.isAvailable
          ? undefined
          : result.data.message ?? 'Email is already associated with a GoodHuman account';
      } catch (error) {
        return error.message ?? 'Email is already associated with a GoodHuman account';
      }
    },

    async doInviteTeamMember(members: Member[]): Promise<void> {
      await saveTeamMembers({ members, skipSendingInvitation: false });
    },

    async doSkipInviteTeamMember(members: Member[]): Promise<void> {
      await saveTeamMembers({ members, skipSendingInvitation: true });
    },
  }),
};

export default teamV2Store;
