import _ from 'lodash';
import React, { useState } from 'react';
import moment from 'moment-timezone';
import { IShiftClashConflict } from 'src/interfaces/service-interfaces';
import { Avatar, Button, Checkbox, Col, Icon, Popover, Row, Tooltip } from 'antd';
import { Popover as BlueprintPopover } from '@blueprintjs/core';
import { Text } from 'common-components/typography';
import ShiftClashTooltipContent from './ShiftClashTooltipContent';
import { ActionMenu, ActionMenuItem } from '../action-menu';

interface IShiftClashItemProps {
  shiftClashConflict: IShiftClashConflict;
  selectedIds: string[];
  hasActionButton?: boolean;
  hasKeepInShift?: boolean;
  isBlueprintLibUsed?: boolean;
  handleKeepInShift: (isChecked: boolean, supportWorkerAttendanceIds: string[]) => void;
}

function ShiftClashItem(props: IShiftClashItemProps) {
  const { shiftClashConflict, selectedIds, handleKeepInShift, hasKeepInShift, hasActionButton, isBlueprintLibUsed } =
    props;
  const [visiblePopoverContent, setVisiblePopoverContent] = useState<boolean>(false);
  const { firstName, lastName, attachmentUrl, selectedShifts } = shiftClashConflict;
  const ids = _.map(shiftClashConflict.selectedShifts, ({ supportWorkerAttendanceId, serviceDateTimeId }) =>
    supportWorkerAttendanceId ? supportWorkerAttendanceId : serviceDateTimeId,
  );
  const acctionButtonConst: { keepAll: string; removeAll: string; selectOne: string } = {
    keepAll: 'Keep in ALL bookings',
    removeAll: 'Remove from ALL bookings',
    selectOne: 'Keep in shift',
  };
  return (
    <>
      <Row className="p-medium bg-quaternary">
        <Col span={10}>
          <Avatar size={32} src={attachmentUrl} shape="square" />
          <Text weight="regular" className="ml-small">
            <b>
              {firstName} {lastName} ({selectedShifts.length})
            </b>
          </Text>
        </Col>
        <Col span={8}></Col>
        <Col span={5}></Col>
        <Col span={1}>
          {hasActionButton && !isBlueprintLibUsed && (
            <Popover
              placement="bottomRight"
              visible={visiblePopoverContent}
              onVisibleChange={(newVisible) => setVisiblePopoverContent(newVisible)}
              trigger="click"
              content={
                <div style={{ margin: '-4px -16px' }}>
                  <Row
                    className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
                    onClick={() => {
                      handleKeepInShift(true, ids);
                      setVisiblePopoverContent(false);
                    }}
                  >
                    {acctionButtonConst.keepAll}
                  </Row>
                  <Row
                    className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
                    onClick={() => {
                      handleKeepInShift(false, ids);
                      setVisiblePopoverContent(false);
                    }}
                  >
                    {acctionButtonConst.removeAll}
                  </Row>
                </div>
              }
            >
              <Button className="ph-small bordered-none shadow-none bg-quaternary">
                <Icon
                  className="text-color-blue-light text-weight-bolder text-size-x4-large"
                  type="ellipsis"
                  theme="outlined"
                />
              </Button>
            </Popover>
          )}
          {hasActionButton && isBlueprintLibUsed && (
            <BlueprintPopover
              content={
                <ActionMenu>
                  <ActionMenuItem text={acctionButtonConst.keepAll} onClick={() => handleKeepInShift(true, ids)} />
                  <ActionMenuItem text={acctionButtonConst.removeAll} onClick={() => handleKeepInShift(false, ids)} />
                </ActionMenu>
              }
              position="bottom-right"
              usePortal={false}
            >
              <Button className="ph-small bordered-none shadow-none bg-quaternary">
                <Icon
                  className="text-color-blue-light text-weight-bolder text-size-x4-large"
                  type="ellipsis"
                  theme="outlined"
                />
              </Button>
            </BlueprintPopover>
          )}
        </Col>
      </Row>
      {_.map(
        selectedShifts,
        ({
          supportWorkerAttendanceId,
          serviceDateTimeId,
          timezone,
          serviceName,
          shiftStartDateTime,
          shiftEndDateTime,
          totalConflicts,
          conflicts,
        }) => {
          const isSameDate = moment(shiftStartDateTime).isSame(shiftEndDateTime, 'date');
          const id = supportWorkerAttendanceId ? supportWorkerAttendanceId : serviceDateTimeId;
          return (
            <Row
              key={id}
              type="flex"
              className={`p-medium align-center bg-${_.includes(selectedIds, id) ? 'blue-action-lightest' : 'white'}`}
            >
              <Col span={10}>
                <Text className="text-size-regular block">
                  <b>{serviceName}:</b>
                </Text>
                <Text className="text-size-regular block">
                  {`${moment.tz(shiftStartDateTime, timezone).format('hh:mm a')} -
                  ${moment.tz(shiftEndDateTime, timezone).format('hh:mm a')}`}
                  ,{' '}
                  {`${moment.tz(shiftStartDateTime, timezone).format('DD MMMM YYYY')}${
                    !isSameDate ? ` - ${moment.tz(shiftEndDateTime, timezone).format('DD MMMM YYYY')}` : ''
                  }
                  `}
                </Text>
              </Col>
              <Col span={8} className="flex justify-start">
                <div>
                  <Tooltip
                    placement="bottomLeft"
                    title={() => (
                      <ShiftClashTooltipContent
                        timezone={timezone}
                        conflicts={conflicts}
                        totalConflicts={totalConflicts}
                      />
                    )}
                    overlayStyle={{ maxWidth: '600px' }}
                  >
                    <Text className="text-size-regular whitespace-nowrap text-color-orange-dark">
                      {totalConflicts} clash{totalConflicts > 1 ? 'es' : ''}{' '}
                      <Icon className="text-size-regular text-color-secondary" type="info-circle" theme="outlined" />
                    </Text>
                  </Tooltip>
                </div>
              </Col>
              <Col span={5}>
                {hasKeepInShift && (
                  <Checkbox
                    checked={_.includes(selectedIds, id)}
                    onClick={(e: any) => handleKeepInShift(e.target.checked, [id])}
                  >
                    {acctionButtonConst.selectOne}
                  </Checkbox>
                )}
              </Col>
              <Col span={1}></Col>
            </Row>
          );
        },
      )}
    </>
  );
}

export default React.memo(ShiftClashItem);
