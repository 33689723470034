import React from 'react';
import { Row } from '@tanstack/react-table';
import { Label } from '@goodhuman-me/components';
import { ServiceAgreementTableData } from '../types';

type SignedStatuses = 'SIGNED' | 'UNSIGNED' | 'SENT' | 'DRAFT';

export const SignedStatusCell = (props: { row: Row<ServiceAgreementTableData> }) => {
  const { row } = props;
  const { signedStatus } = row.original;

  const labelProps = {
    SIGNED: { tone: 'positive', tag: 'Signed' },
    UNSIGNED: { tone: 'notice', tag: 'Unsigned' },
    SENT: { tone: 'blue', tag: 'Sent' },
    DRAFT: { tone: 'neutral', tag: 'Draft' },
  } as const;

  const label = labelProps[signedStatus as SignedStatuses];

  return (
    <Label emphasis="outlined" tone={label.tone} size="small">
      {label.tag}
    </Label>
  );
};
