import type { PropsWithChildren, Ref } from 'react';
import React, { createContext } from 'react';
import type { IconProps } from '..';
import { IconContext } from '../icon/icon-provider';
import { SwitchProps } from './switch';
import type { SwitchVariants } from './switch.css';

type SwitchElement = Ref<HTMLInputElement>;
export type SwitchProviderProps = SwitchProps & {
  switchRef?: SwitchElement;
};

export const SwitchContext = createContext<SwitchProviderProps>({} as SwitchProviderProps);

export const SwitchProvider = (props: SwitchProviderProps) => {
  const { children, ...otherProps } = props;

  return <SwitchContext.Provider value={otherProps}>{children}</SwitchContext.Provider>;
};

export type SwitchIconProviderProps = PropsWithChildren<IconProps & SwitchVariants>;

export function SwitchIconProvider(props: SwitchIconProviderProps) {
  const { children, size, ...otherProps } = props;
  let height;
  let width;

  switch (size) {
    case 'medium':
      width = '$size130';
      height = '$size130';
      break;
  }

  const providerProps = { ...otherProps, height, width };

  return <IconContext.Provider value={providerProps}>{children}</IconContext.Provider>;
}
