import React, { HTMLAttributes, PropsWithChildren } from 'react';

type HeadingProps = PropsWithChildren<HTMLAttributes<HTMLHeadingElement>>;

export function Heading(props: HeadingProps) {
  let { children, className, ...otherProps } = props;

  return (
    <h2 className="m-none text-size-x-large text-weight-bold mb-large" {...otherProps}>
      {children}
    </h2>
  );
}
