import React from 'react';
import { RouteObject } from 'react-router-dom-v5-compat';
import { ScheduleTab } from './tabs/schedule/schedule-tab';
import { CustomersTab } from './tabs/customers/customers-tab';
import { DetailsTab } from './tabs/details/details-tab';
import { TeamTab } from './tabs/team/team-tab';
import { SettingsTab } from './tabs/settings/settings-tab';
import { useTranslation } from 'react-i18next';
import { ActivityTab } from './tabs/activity/activity-tab';
import { FilesTab } from './tabs/files/files-tab';

export type SilUrlParams = {
  tabName: string;
  serviceId: string;
};

const SIL_ROUTES = {
  SCHEDULE: {
    path: 'schedule',
    link: `/service/sil/:serviceId/schedule`,
  },
  DETAILS: {
    path: 'details',
    link: `/service/sil/:serviceId/details`,
  },
  CUSTOMERS: {
    path: 'customers',
    link: `/service/sil/:serviceId/customers`,
  },
  TEAM: {
    path: 'team',
    link: `/service/sil/:serviceId/team`,
  },
  ACTIVITY: {
    path: 'activity',
    link: `/service/sil/:serviceId/activity`,
  },
  FILES: {
    path: 'files',
    link: `/service/sil/:serviceId/files`,
  },
  SETTINGS: {
    path: 'settings',
    link: `/service/sil/:serviceId/settings`,
  },
};
type SIL_ROUTE_KEYS = keyof typeof SIL_ROUTES;

export const useSilServiceRoutes = () => {
  const { t } = useTranslation('', { keyPrefix: 'silService.tabTitles' });

  const routes = Object.keys(SIL_ROUTES).map((routeKey) => {
    return {
      ...SIL_ROUTES[routeKey as SIL_ROUTE_KEYS],
      title: t(routeKey),
    };
  });

  return { routes };
};

export const routeDefs: RouteObject[] = [
  {
    path: SIL_ROUTES.SCHEDULE.path,
    element: <ScheduleTab />,
  },
  {
    path: SIL_ROUTES.DETAILS.path,
    element: <DetailsTab />,
  },
  {
    path: SIL_ROUTES.CUSTOMERS.path,
    element: <CustomersTab />,
  },
  {
    path: SIL_ROUTES.TEAM.path,
    element: <TeamTab />,
  },
  {
    path: SIL_ROUTES.ACTIVITY.path,
    element: <ActivityTab />,
  },
  {
    path: SIL_ROUTES.FILES.path,
    element: <FilesTab />,
  },
  {
    path: SIL_ROUTES.SETTINGS.path,
    element: <SettingsTab />,
  },
];
