import { tv } from 'tailwind-variants';

export const numberField = tv(
  {
    slots: {
      container: ['flex', 'flex-col', 'gap-2', 'min-w-[192px]', 'w-fit', 'relative', 'isolate', 'group/container'],
      inputContainer: ['relative', 'block', 'h-11', 'group/input-container'],
      input: [
        'absolute',
        'appearance-none',
        'bg-base',
        'border',
        'border-gray',
        'hover:border-gray-dark-1',
        'disabled:bg-neutral-weak',
        'disabled:border-neutral-weak',
        'enabled:aria-invalid:border-critical',
        'enabled:aria-invalid:data-[focus-visible=true]:outline-critical',
        'enabled:aria-invalid:data-[focused=true]:border-critical',
        'enabled:aria-invalid:data-[hovered=false]:border-critical',
        'enabled:data-[focus-visible=true]:outline',
        'enabled:data-[focus-visible=true]:outline-accent',
        'enabled:data-[focus-visible=true]:outline-offset-[-1px]',
        'enabled:data-[focused=true]:border-accent',
        'enabled:data-[focused=true]:data-[hovered=false]:border-accent',
        'inset-0',
        'leading-b-sm',
        'outline-none',
        'placeholder:text-weak',
        'px-3',
        'py-2.5',
        'rounded-md',
        'text-b-sm',
        'text-strong',
        'disabled:text-weak',
        'transition-colors',
        'w-full',
      ],
      stepContainer: ['absolute', 'bottom-[1px]', 'right-0', 'top-[1px]', 'flex', 'flex-col'],
      stepButton: [
        'bg-base',
        'absolute',
        'right-[1px]',
        'inline-flex',
        'h-[21.5px]',
        'w-[22px]',
        'appearance-none',
        'items-center',
        'justify-center',
        'border-l',
        'border-l-gray-light-1',
        'disabled:border-l-neutral-weak',
        'text-b-xs',
        'disabled:text-weak',
        'data-hovered:bg-neutral-weak',
        'data-pressed:bg-gray-light-1',
        'transition-colors',
        'group-aria-[disabled=true]/input-container:bg-gray-light-1',
        'data-pressed:z-10',
        'first:border-b',
        'first:border-b-gray-light-1',
        'first:data-pressed:border-l-gray',
        'first:data-pressed:border-b-gray',
        'first:rounded-tr-md',
        'first:top-0',
        'last:border-t',
        'last:border-t-gray-light-1',
        'last:bottom-0',
        'last:data-pressed:border-l-gray',
        'last:data-pressed:border-t-gray',
        'last:rounded-br-md',
      ],
    },
  },
  { twMerge: false },
);
