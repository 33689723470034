import React, { memo } from 'react';
import moment from 'moment-timezone';
import { Icon } from 'antd';

import { Title, Text } from 'common-components/typography';
import { SecondaryButton, PrimaryButton } from 'common-components/buttons';
import SessionStatusTag from 'common-components/tags/SessionStatusTag';
import { IPrintSessionRoster } from 'interfaces/session-interfaces';
import CommonUtils from 'utilities/common-utils';

interface IPrintSessionRosterHeaderProps {
  isPrinting: boolean;
  session: IPrintSessionRoster;
  onPrint: () => void;
  onBack: () => void;
}

function PrintSessionRosterHeader(props: IPrintSessionRosterHeaderProps) {
  const { isPrinting, session, onPrint, onBack } = props;

  const startDateTime = session && moment.tz(session.startDateTime, session.timezone);
  const endDateTime = session && moment.tz(session.endDateTime, session.timezone);

  const momentStartTime = startDateTime;
  const momentEndTime = endDateTime;

  const duration = CommonUtils.formatDurationString(momentStartTime, momentEndTime);

  const timeDisplay = (
    <>
      {momentStartTime.format('h:mm A')} - {momentEndTime.format('h:mm A')}{' '}
      <span className="ml-small text-color-secondary">{duration}</span>
    </>
  );

  return (
    <div className="flex-row height-full bg-white bordered-bottom">
      <div className="width-full">
        <div className="pt-large pb-x-large">
          <div className="ph-none flex-row align-center justify-between">
            {session && (
              <>
                <div className="flex-row width-full justify-between">
                  <div className="pl-x2-large line-height-135">
                    <div className="mb-x-small flex-row align-center">
                      <Title level={2} className="mt-none mb-none mr-medium">
                        {session.serviceName}
                      </Title>

                      <SessionStatusTag status={session.sessionStatus} />
                    </div>

                    <div className="flex-row flex-1">
                      <div className="line-height-135">
                        <Text className="mr-large" size="x-large" lineHeight={135}>
                          {momentStartTime.format('dddd, D MMMM YYYY')}
                        </Text>
                        <Text className="mr-large block" size="x-large" lineHeight={135}>
                          {timeDisplay}
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="roster-action flex-row pr-x2-large invisible-print-mode">
                    <PrimaryButton className="mr-large" size="large" onClick={onPrint}>
                      <Icon type="printer" className="text-size-large" /> Print
                    </PrimaryButton>
                    <SecondaryButton size="large" onClick={onBack}>
                      Back
                    </SecondaryButton>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(PrintSessionRosterHeader);
