import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const switchCheckbox: CSS = {
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
};

// Switch checkbox variants
export const variants = css({
  backgroundColor: '$neutralLight',
  variants: {
    size: {
      medium: {
        padding: '$space50',
        width: '$size550',
        height: '$size300',
        borderRadius: '$large',
      },
    },
    isChecked: {
      true: {
        backgroundColor: '$accent',
      },
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        backgroundColor: '$bodyLight',
      },
    },
  },
});

export const iconSwitch = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '$transition200',
  borderRadius: '$full',
  width: '$size225',
  height: '$size225',
  backgroundColor: '$neutralDark1',
  variants: {
    isChecked: {
      true: {},
    },
    isDisabled: {
      true: {},
    },
  },
  compoundVariants: [
    {
      isChecked: true,
      isDisabled: true,
      css: {
        transform: 'translateX(100%)',
      },
    },
    {
      isChecked: true,
      isDisabled: false,
      css: {
        backgroundColor: '$white',
        transform: 'translateX(100%)',
      },
    },
  ],
});

export type SwitchVariants = Stitches.VariantProps<typeof variants>;
