import React from 'react';
import { PageHeader } from '@good/ui/templates';
import { useTranslation } from 'react-i18next';
import { ServiceCustomerDocumentData } from 'utilities/react-trpc';
import { EmptyTablePlaceholder } from 'views/sil-service/components/empty-table-placeholder';
import { SilTable } from 'views/sil-service/components/table';
import { customerFileColumns } from './customer-files-helper';
import { LaptopDesk } from '@good/illustrations';

type ICustomerFilesTabProps = {
  data: ServiceCustomerDocumentData[];
};

export const CustomerFilesTab = ({ data }: ICustomerFilesTabProps) => {
  const { t } = useTranslation('', { keyPrefix: 'silService.filesTabs.customerFiles' });

  const fileCount = data.length;

  return (
    <div data-testid='customer-files-tab' className='w-full'>
      <PageHeader title={t('title')} subtitle={t('description')} size='h2' />
      <SilTable data={data} columns={customerFileColumns} isSelectable={false} />
      {fileCount === 0 ? (
        <EmptyTablePlaceholder
          image={<LaptopDesk />}
          heading={t('empty.heading')}
          description={t('empty.description')}
        />
      ) : null}
    </div>
  );
};
