import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Edit = forwardRef(function Edit(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.16 4.903a1.25 1.25 0 0 1 1.68 0c.02.017.044.042.13.127.085.086.11.11.127.13a1.25 1.25 0 0 1 0 1.68 4.649 4.649 0 0 1-.127.13l-.807.807a1.903 1.903 0 0 1-1.94-1.94l.808-.807.128-.127Zm-2.208 2.205-7.855 7.855c-.807.807-1.107 1.117-1.313 1.48-.206.365-.317.782-.594 1.889l-.16.637.638-.16c1.107-.276 1.524-.387 1.888-.593.364-.206.674-.507 1.48-1.313l7.856-7.855a3.428 3.428 0 0 1-1.94-1.94Zm4.898-3.314a2.75 2.75 0 0 0-3.868.163l-.012.013-9.933 9.933-.074.073c-.708.708-1.166 1.166-1.485 1.729-.318.563-.475 1.19-.718 2.162l-.025.1-.463 1.851a.75.75 0 0 0 .91.91l1.85-.463.101-.025c.972-.243 1.6-.4 2.162-.718.563-.32 1.02-.777 1.729-1.485l.073-.073L20.03 8.03l.013-.012c.067-.068.12-.12.163-.168a2.75 2.75 0 0 0-.163-3.868l-.012-.012-.013-.013a5.119 5.119 0 0 0-.168-.163Z"
        fill="currentColor"
      />
    </Icon>
  );
});
