import { Col, Row } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import * as H from 'history';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFetchNDIADebtorId } from 'stores/hooks/query-hooks/use-query-fetch-NDIA-debtor-id';
import { IRootState } from 'stores/rematch/root-store';
import { ExportModals } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import { AccountExportContentBox } from './components/AccountExportDataComponents/account-export-content-box';
import ExportSettingsNDIADebtorIdModal from './components/export-data-modals/ExportSettingsNDIADebtorIdModal';
import { ExportDataModals } from './components/export-data-modals/export-data-modals';

const AccountExportDataView = ({ history }: { history: H.History }) => {
  const [openModal, setOpenModal] = useState<ExportModals>();

  const [isExportSettingsNDIADebtorIdModalOpen, setIsExportSettingsNDIADebtorIdModalOpen] = useState<boolean>(false);

  const { data: NDIADebtorQuery, isSuccess } = useFetchNDIADebtorId();
  const [ndiaDebtorId, setNDIADebtorId] = useState<string>('');
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);

  const goToLandingPage = () => {
    history.push('/account/landing');
  };

  useEffect(() => {
    if (!PermissionUtils.validatePermission('ExportPlatformData', portalUser.permissions.permissionRoles)) {
      history.push('/access-denied');
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      const { ndiaDebtorId: fetchedNdiaDebtorId } = NDIADebtorQuery.data;
      setNDIADebtorId(fetchedNdiaDebtorId);
    }
  }, [isSuccess]);

  const exportDataSets = [
    {
      title: 'Customers',
      content: 'Export data related to customers',
      key: ExportModals.CUSTOMER,
    },
    {
      title: 'Bookings',
      content: `Export data related to bookings`,
      key: ExportModals.BOOKING,
    },
    {
      title: 'Team Members: General Information',
      content: `Export data related to team members' general information`,
      key: ExportModals.TEAM_MEMBER_GENERAL,
    },
    {
      title: 'Team Members: Availabilities',
      content: `Export data related to team members' availabilities`,
      key: ExportModals.TEAM_MEMBER_AVAILABILITIES,
    },
    {
      title: 'Team Members: Tags',
      content: `Export data related to team members' tags`,
      key: ExportModals.TEAM_MEMBER_TAGS,
    },
    {
      title: 'Team Members: Documents',
      content: `Export data related to team members' documents`,
      key: ExportModals.TEAM_MEMBER_DOCUMENTS,
    },
    {
      title: 'Staff Additional Claims',
      content: `Export data related to staff additional claims.`,
      key: ExportModals.STAFF_ADDTIONAL_CLAIMS,
    },
    {
      title: 'Incidents',
      content: 'Export data related to incidents',
      key: ExportModals.INCIDENT,
    },
    {
      title: 'Pinned Alerts',
      content: 'Export data related to customer pinned alerts',
      key: ExportModals.PINNED_ALERTS,
    },
  ];

  return (
    <>
      <ExportSettingsNDIADebtorIdModal
        ndiaDebtorId={ndiaDebtorId}
        isOpen={isExportSettingsNDIADebtorIdModalOpen}
        onClose={() => setIsExportSettingsNDIADebtorIdModalOpen(false)}
        onUpdateNDIADebtorId={setNDIADebtorId}
      />
      {openModal && <ExportDataModals openModal={openModal} setOpenModal={setOpenModal} />}
      <div className='pb-x4-large'>
        <div className='mb-medium'>
          <Title level={3} weight='regular' className='mv-none'>
            <button
              className='text-color-blue-action cursor-pointer'
              onClick={goToLandingPage}
              onKeyDown={goToLandingPage}
            >
              Account {'>'}
            </button>{' '}
            Export Data
          </Title>
        </div>

        <div className='mt-x-large sm:w-2/3'>
          <Paragraph>
            Export customer, team or services data from GoodHuman to use with other products or to keep as a backup.
            Files are exported as .CSV.
          </Paragraph>
          <Title level={4} weight='regular'>
            Export Settings
          </Title>
          <Paragraph>
            Configure the below fields to ensure exports meet the import requirements of your organisations&apos;
            operational systems.
          </Paragraph>
          <div className='bordered p-medium width-half rounded'>
            <Row type='flex' className='align-center justify-between'>
              <Col>
                <div>
                  <div className='mb-small'>
                    <SubTitle weight='bold'>NDIA debtor ID</SubTitle>
                  </div>
                  <Text>{ndiaDebtorId ? ndiaDebtorId : 'Not set...'}</Text>
                </div>
              </Col>
              <Col>
                <HyperlinkButton onClick={() => setIsExportSettingsNDIADebtorIdModalOpen(true)}>
                  Edit...
                </HyperlinkButton>
              </Col>
            </Row>
          </div>
          <div className='mv-x-large'>
            <Title level={4} weight='regular'>
              Export Datasets
            </Title>
            {exportDataSets.map(({ title, content, key }) => (
              <AccountExportContentBox key={title} title={title} content={content} onClick={() => setOpenModal(key)} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountExportDataView;
