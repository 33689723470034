import FullCalendar, { EventClickArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
class BookingListingCalendar extends Component<any, any> {
  private _onClickEvent = (event: EventClickArg) => {
    const { history } = this.props;
    history.push(`/bookings/details/${event.event.id}`);
  };

  private _onHoverEvent = (event) => {
    // var tooltip = new Tooltip(event.el, {
    //   title: event.event.extendedProps.description,
    //   placement: 'top',
    //   trigger: 'hover',
    //   container: 'body'
    // });
  };

  render() {
    const { bookingsList, displayTimezone } = this.props;

    const newBookingList = _.map(bookingsList, (booking) => {
      const timezone = displayTimezone ? displayTimezone : booking.timezone;
      const start = moment.tz(booking.startDateTime, timezone);
      const end = moment.tz(booking.endDateTime, timezone);
      const title = `${start.format('hh:mm A')} - ${end.format('hh:mm A')} ${booking.serviceName}`;

      return {
        ...booking,
        start: start.toISOString(),
        end: end.toISOString(),
        title,
        id: booking.bookingId,
      };
    });

    return (
      <div className="mh-x-large">
        <FullCalendar
          events={newBookingList}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          initialView="dayGridMonth"
          weekends={true}
          dayMaxEventRows={4}
          eventClick={this._onClickEvent}
          handleWindowResize={true}
          // eventContent={renderEventContent}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin]}
          eventDisplay={'block'}
          displayEventTime={false}
          showNonCurrentDates={false}
          eventDidMount={this._onHoverEvent}
          eventOrder={[{ func: sortMultipleDate }, { field: 'allDay' }, { field: 'start' }, { field: 'title' }]}
        />
      </div>
    );
  }
}

const sortMultipleDate = (a, b) => {
  if (a.duration > 24 * 3600 * 1000) {
    return -1;
  }

  return 1;
};

export default BookingListingCalendar;
