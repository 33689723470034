import React, { Component } from 'react';
import { IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Text, Title } from 'common-components/typography';
import { Warning } from 'common-components/alerts';
import { SecondaryButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';

interface IGroupServiceOverviewPanelProps {
  serviceId: string;
  groupServiceOverviewWarnings: typeof state.groupServiceStore.groupServiceOverviewWarnings;
  onOpenCreateSessionModal: () => void;
  onAddCustomerToSession: () => void;
  goToTab: (selectedTab, data?: any) => void;
}

class GroupServiceOverviewPanel extends Component<IGroupServiceOverviewPanelProps> {
  render = () => {
    const { groupServiceOverviewWarnings } = this.props;

    const hasWarnings =
      groupServiceOverviewWarnings &&
      (!_.isEmpty(groupServiceOverviewWarnings.completedSessionsNotClosed) ||
        groupServiceOverviewWarnings.noUpcomingSessionsWarning ||
        groupServiceOverviewWarnings.noWorkerInRosterWarning);

    return (
      <div className="pt-x-large pl-medium width-full pb-large">
        <div className="mb-medium">
          <Title level={4} className="mv-none">
            Group service overview
          </Title>
        </div>
        {hasWarnings && (
          <div className={'mb-medium'}>
            {groupServiceOverviewWarnings.noUpcomingSessionsWarning && (
              <>
                <Warning
                  className={'mb-small'}
                  content={
                    <>
                      This service doesn't have any upcoming sessions.
                      <br />
                      <HyperlinkButton onClick={this.props.onOpenCreateSessionModal}>Create session</HyperlinkButton>
                    </>
                  }
                />
                <br />
              </>
            )}
            {groupServiceOverviewWarnings.noWorkerInRosterWarning && (
              <>
                <Warning
                  className={'mb-small'}
                  content={
                    <>
                      This service does not have any team members assigned to the roster.
                      <br />
                      <HyperlinkButton onClick={this.props.onAddCustomerToSession}>Add team members</HyperlinkButton>
                    </>
                  }
                />
                <br />
              </>
            )}
            {groupServiceOverviewWarnings.completedSessionsNotClosed &&
              groupServiceOverviewWarnings.completedSessionsNotClosed.length > 0 && (
                <>
                  <Warning
                    className={'mb-small'}
                    content={
                      <>
                        This service has {groupServiceOverviewWarnings.completedSessionsNotClosed.length} completed
                        session
                        {groupServiceOverviewWarnings.completedSessionsNotClosed.length !== 1 && 's'} that have not been
                        closed.
                        <br />
                        <HyperlinkButton onClick={() => this.props.goToTab('SESSIONS', 'NOT_CLOSED_SESSIONS')}>
                          View session{groupServiceOverviewWarnings.completedSessionsNotClosed.length !== 1 && 's'}
                        </HyperlinkButton>
                      </>
                    }
                  />
                  <br />
                </>
              )}
          </div>
        )}
        <div className={'mv-large bg-white rounded-big shadow-container p-large flex-row align-center justify-between'}>
          <div>
            <Text weight={'bold'}>Create sessions</Text>
            <br />
            <Text>Create sessions for this service.</Text>
          </div>
          <div>
            <SecondaryButton icon={'plus'} onClick={this.props.onOpenCreateSessionModal} size="large">
              Create sessions
            </SecondaryButton>
          </div>
        </div>
        <div className={'mv-large bg-white rounded-big shadow-container p-large flex-row align-center justify-between'}>
          <div>
            <Text weight={'bold'}>Add team members to your roster</Text>
            <br />
            <Text>Add team members that belong to this service.</Text>
          </div>
          <div>
            <SecondaryButton icon={'plus'} onClick={() => this.props.goToTab('TEAM_MEMBERS')} size="large">
              Add team member
            </SecondaryButton>
          </div>
        </div>
        <div className={'mv-large bg-white rounded-big shadow-container p-large flex-row align-center justify-between'}>
          <div>
            <Text weight={'bold'}>Add customers to your sessions</Text>
            <br />
            <Text>Assign customer to the sessions of this service.</Text>
          </div>
          <div>
            <SecondaryButton icon={'plus'} onClick={this.props.onAddCustomerToSession} size="large">
              Add customers
            </SecondaryButton>
          </div>
        </div>
      </div>
    );
  };
}

const mapState = (state: IRootState) => ({
  groupServiceOverviewWarnings: state.groupServiceStore.groupServiceOverviewWarnings,
});

export default connect(mapState, null)(GroupServiceOverviewPanel);
