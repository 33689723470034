import { notification } from 'antd';
import SelectMembers from 'common-components/activity-groups/selectors/SelectMembers';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IGroupServiceActivityGroup } from 'interfaces/service-interfaces';
import { IActivityGroupUsers } from 'interfaces/user-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { ActivityGroupMemberType, ActivityGroupModalType } from 'utilities/enum-utils';

interface IAddMembersModalContentProps {
  serviceId: string;
  modalType: ActivityGroupModalType;
  memberType: ActivityGroupMemberType;
  activityGroup: IGroupServiceActivityGroup;
  ignoredAlert?: boolean;
  onCloseViewModal: () => void;
  handleAction: (payload, actionType: ActivityGroupModalType) => Promise<boolean | void>;
}

interface IAddMembersModalContentState {
  isLoading: boolean;
  addedMembers: IActivityGroupUsers[];
  membersInActivityGroup: IActivityGroupUsers[];
}

class AddMembersModalContent extends Component<IAddMembersModalContentProps, IAddMembersModalContentState> {
  state = {
    isLoading: false,
    addedMembers: [],
    membersInActivityGroup: [],
  };

  private _handleClassifyMembers = () => {
    const { activityGroup, modalType } = this.props;
    if (modalType === ActivityGroupModalType.ADD_CUSTOMER) {
      this.setState({
        membersInActivityGroup: activityGroup.customers,
        addedMembers: activityGroup.customers,
      });
    }
    if (modalType === ActivityGroupModalType.ADD_TEAM_MEMBER) {
      this.setState({
        membersInActivityGroup: activityGroup.teamMembers,
        addedMembers: activityGroup.teamMembers,
      });
    }
  };

  private _handleAlterStateWhenInteractWithSelectedMember = (addedMembers: IActivityGroupUsers[]) => {
    this.setState({ addedMembers: addedMembers });
  };

  private _handleAddMember = async () => {
    const { serviceId, activityGroup, modalType, memberType } = this.props;
    const { addedMembers } = this.state;
    this.setState({ isLoading: true });
    try {
      const payload = {
        serviceId,
        activityGroupId: activityGroup.serviceActivityGroupId,
        serviceDateTimeActivityGroupId: activityGroup.serviceDateTimeActivityGroupId,
      };
      let isDataChanged = true;
      if (modalType === ActivityGroupModalType.ADD_CUSTOMER) {
        payload['customers'] = _.map(addedMembers, (member) => member.userId);
        isDataChanged = (await this.props.handleAction(payload, this.props.modalType)) as boolean;
      }
      if (modalType === ActivityGroupModalType.ADD_TEAM_MEMBER) {
        payload['teamMembers'] = _.map(addedMembers, (member) => member.userId);
        isDataChanged = (await this.props.handleAction(payload, this.props.modalType)) as boolean;
      }

      if (!this.props.ignoredAlert && isDataChanged) {
        notification.success({
          message: `${
            ActivityGroupMemberType.CUSTOMER === memberType ? 'Customers' : 'Team members'
          } added to activity group`,
          description: `You have succesfully added ${
            ActivityGroupMemberType.CUSTOMER === memberType ? 'customers' : 'team members'
          } to "${activityGroup.name}" `,
        });
      }
      this.props.onCloseViewModal();
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong! Please try again.',
      });
      console.log(e);
    }

    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    await this._handleClassifyMembers();
  };

  render() {
    return (
      <div>
        <SelectMembers
          membersInActivityGroup={this.state.membersInActivityGroup}
          activityGroup={this.props.activityGroup}
          serviceId={this.props.serviceId}
          activityGroupAddType={this.props.modalType}
          onInteractWithAddedMembers={this._handleAlterStateWhenInteractWithSelectedMember}
          onClose={this.props.onCloseViewModal}
        />
        <div className="flex justify-end mt-medium">
          <SecondaryButton size="large" color="secondary" className="mr-12" onClick={this.props.onCloseViewModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._handleAddMember} loading={this.state.isLoading}>
            Save
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export default AddMembersModalContent;
