import React from 'react';
import { Title } from 'common-components/typography';
import { Button, Link, Text } from '@good/components';
import PermissionUtils from 'utilities/permission-utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';

type TmMgmtHeaderParams = {
  onAddTeamMemberButtonClicked: () => void;
};

export const TmMgmtHeader = ({ onAddTeamMemberButtonClicked }: TmMgmtHeaderParams) => {
  const { ny1616 } = useFlags();

  const { portalUser } = useSelector((state: IRootState) => state.authStore);
  return (
    <div className="flex-row justify-between">
      <Title level={3} weight="regular" className="mv-none">
        <Text size="xl">
          <Link emphasis="no-underline">
            <a href="/account/landing">Account</a>
          </Link>
          {' / Team Management'}
        </Text>
      </Title>
      {PermissionUtils.validatePermission('AddNewTeamMember', portalUser?.permissions?.permissionRoles ?? []) && (
        <Button size="md" emphasis="fill" onClick={onAddTeamMemberButtonClicked}>
          {`+ Add team member${ny1616 ? 's' : ''}`}
        </Button>
      )}
    </div>
  );
};
