import { Icon, Tabs } from 'antd';
import React, { PureComponent } from 'react';
import { IYearAndMonthFilter } from 'interfaces/filter-interfaces';
import moment, { Moment } from 'moment';
import _ from 'lodash';

interface IYearAndMonthFilterProps {
  filter: IYearAndMonthFilter;
  startDateTime: Moment;
  endDateTime: Moment;
  updateFilter: (filter) => void;
  hideEmptyDates?: boolean;
  dateList?: any;
  filterWarnings?: Array<string> | null;
}

class YearAndMonthFilter extends PureComponent<IYearAndMonthFilterProps, null> {
  private _updateYear = (e) => {
    if (e !== this.props.filter.year) {
      const firstSessionOfSelectedYear = _.find(
        this.props.dateList,
        (date) => moment(date.startDateTime).year().toString() === e,
      );

      if (firstSessionOfSelectedYear) {
        this.props.updateFilter({
          year: Number(e),
          month: Number(moment(firstSessionOfSelectedYear.startDateTime).month()),
        });
      } else {
        this.props.updateFilter({ ...this.props.filter, year: Number(e) });
      }
    }
  };

  private _updateMonth = (e) => {
    this.props.updateFilter({ ...this.props.filter, month: Number(e) });
  };

  render() {
    const {
      filter,
      startDateTime,
      endDateTime,
      hideEmptyDates = false,
      dateList = null,
      filterWarnings = null,
    } = this.props;

    const years = [];
    for (let i = moment(startDateTime).year(); i <= moment(endDateTime).year(); i++) {
      years.push(i);
    }
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    return (
      <>
        <Tabs
          animated={true}
          size="large"
          className="customer-tab inner-tab"
          defaultActiveKey={filter.year.toString()}
          onChange={this._updateYear}
        >
          {_.map(years, (year) => {
            const displayWarning =
              filterWarnings && _.find(filterWarnings, (warning) => _.includes(warning, year.toString()));
            return (
              <Tabs.TabPane
                tab={
                  displayWarning ? (
                    <>
                      <Icon type={'warning'} theme={'filled'} className={'mr-small text-color-warning-orange'} />
                      {year}
                    </>
                  ) : (
                    year
                  )
                }
                key={year}
              />
            );
          })}
        </Tabs>
        <Tabs
          animated={true}
          size="large"
          className="customer-tab inner-tab"
          activeKey={filter.month.toString()}
          onChange={this._updateMonth}
        >
          {_.map(months, (month) => {
            const displayWarning =
              filterWarnings &&
              _.find(
                filterWarnings,
                (warning) => warning === (month + 1).toString().padStart(2, '0') + '-' + filter.year.toString(),
              );
            if (
              !hideEmptyDates ||
              !!_.find(
                dateList,
                (date) =>
                  moment(date.startDateTime).year() === filter.year && moment(date.startDateTime).month() === month,
              )
            ) {
              return (
                <Tabs.TabPane
                  tab={
                    displayWarning ? (
                      <>
                        <Icon type={'warning'} theme={'filled'} className={'mr-small text-color-warning-orange'} />
                        {moment().month(month).format('MMM')}
                      </>
                    ) : (
                      moment().month(month).format('MMM')
                    )
                  }
                  key={month.toString()}
                />
              );
            }
          })}
        </Tabs>
      </>
    );
  }
}

export default YearAndMonthFilter;
