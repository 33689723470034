import { Form, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle } from 'common-components/typography';
import { IWorkflowIssue } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';

interface Props extends FormComponentProps {
  issue?: IWorkflowIssue;
  loading: boolean;
  isOpen: boolean;
  onFlagIssue(issue: IWorkflowIssue): void;
  onClose(): void;
}

class FlagIssueModal extends PureComponent<Props> {
  private _onFlagIssue = async () => {
    const { form, issue, onFlagIssue } = this.props;

    form.validateFields((errors, values) => {
      if (!errors) {
        onFlagIssue({
          ...issue,
          message: values.message ? values.message.trim() : '',
        });
      }
    });
  };

  render() {
    const { issue, isOpen, loading, onClose, form } = this.props;

    const { getFieldDecorator, getFieldValue } = form;

    return (
      <ActionModal width="medium" isOpen={isOpen} onClose={onClose} title="Flag issue" verticalAlignment="center">
        <Paragraph>Flagging an issue will prevent the workflow from being approved at its current step.</Paragraph>

        <Paragraph>Would you like to flag an issue to this workflow?</Paragraph>

        <SubTitle>ISSUE</SubTitle>
        <Form.Item className="mv-none">
          {getFieldDecorator('message', {
            initialValue: issue && issue.isFlagged ? issue.message : '',
            rules: [
              { required: true, whitespace: true, message: 'Please enter a reason for flagging the issue' },
              { max: 2500, message: 'Please enter less than 2500 characters.' },
            ],
          })(<Input.TextArea placeholder="Enter issue description..." autoSize={{ minRows: 3 }} />)}
        </Form.Item>

        <Row type="flex" justify="end" className="mt-x2-large">
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>

          <PrimaryButton
            size="large"
            loading={loading}
            disabled={!getFieldValue('message')}
            onClick={this._onFlagIssue}
          >
            Flag issue
          </PrimaryButton>
        </Row>
      </ActionModal>
    );
  }
}

export default Form.create<Props>()(FlagIssueModal);
