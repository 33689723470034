import React, { Component } from 'react';
import { Col, Icon, Input, notification, Row, Select, Spin } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { ICustomer } from 'interfaces/customer-interfaces';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CommonUtils from 'utilities/common-utils';
import { DebtorType } from 'utilities/enum-utils';
import { validateEmailFormat } from 'utilities/validator-utils';
import { type NewRelicBrowser, WithNewRelicBrowser } from 'integrations/new-relic-browser';

const searchAutocompleteOptions = {
  componentRestrictions: { country: ['au'] },
};

interface IAddEditSelfAndCaseManagerModalProps {
  closeModal: () => void;
  isOpen: boolean;
  isEdit: boolean;
  selectedCustomer: ICustomer;
  debtors: typeof state.accountStore.debtors;
  goDebtorHomepage: (id) => void;
  setDebtors: typeof dispatch.accountStore.setDebtors;
  doUpdateFundingGeneralInfo: typeof dispatch.customersStore.doUpdateFundingGeneralInfo;
  doFetchDebtorList: typeof dispatch.accountStore.doFetchDebtorList;
  doAddCustomerDebtor: typeof dispatch.customersStore.doAddCustomerDebtor;
  doEditDebtorGeneralInfo: typeof dispatch.accountStore.doEditDebtorGeneralInfo;
  doGetCustomer: typeof dispatch.customersStore.doGetCustomer;
  debtorType: DebtorType;
  newRelicBrowser: NewRelicBrowser;
}

interface IAddEditSelfAndCaseManagerModalState {
  isLoading: boolean;
  isCancelVerificationModalOpen: boolean;
  step: number;
  isSearching: boolean;
  selectedManager: any;
  debtorName: string;
  organisationName: string;
  debtorNumber: string;
  email: string;
  contactNumber: string;
  address: string;
  selectedLattitude: any;
  selectedLongitude: any;
  selectedAddress: any;
  debtorNameError: boolean;
  debtorNumberError: boolean;
  contactNumberError: boolean;
  emailError: string | undefined;
}

class AddEditSelfAndCaseManagerModal extends Component<
  IAddEditSelfAndCaseManagerModalProps,
  IAddEditSelfAndCaseManagerModalState
> {
  state = {
    isLoading: false,
    isCancelVerificationModalOpen: false,
    step: 1,
    isSearching: false,
    selectedManager: null,
    debtorName: null,
    organisationName: null,
    debtorNumber: null,
    email: null,
    contactNumber: null,
    address: '',
    selectedLattitude: null,
    selectedLongitude: null,
    selectedAddress: null,
    debtorNameError: false,
    debtorNumberError: false,
    contactNumberError: false,
    emailError: undefined,
  };

  componentDidUpdate(
    prevProps: Readonly<IAddEditSelfAndCaseManagerModalProps>,
    prevState: Readonly<IAddEditSelfAndCaseManagerModalState>,
    snapshot?: any,
  ) {
    const { selectedCustomer, debtorType, isEdit } = this.props;
    let step = 1;
    if (isEdit) {
      step = 2;
    }
    if (prevProps.isOpen !== this.props.isOpen || prevProps.debtorType !== this.props.debtorType) {
      if (debtorType === DebtorType.SELF) {
        this.setState({
          debtorName: selectedCustomer.selfManager ? selectedCustomer.selfManager.name : '',
          organisationName: selectedCustomer.selfManager ? selectedCustomer.selfManager.organisationName : '',
          debtorNumber: selectedCustomer.selfManager ? selectedCustomer.selfManager.debtorNumber : '',
          email: selectedCustomer.selfManager ? selectedCustomer.selfManager.email : '',
          contactNumber: selectedCustomer.selfManager ? selectedCustomer.selfManager.contactNumber : '',
          address:
            selectedCustomer.selfManager && selectedCustomer.selfManager.address
              ? selectedCustomer.selfManager.address
              : '',
          step: step,
        });
      } else if (debtorType === DebtorType.VCP) {
        this.setState({
          debtorName: selectedCustomer.vcpManager ? selectedCustomer.vcpManager.name : '',
          organisationName: selectedCustomer.vcpManager ? selectedCustomer.vcpManager.organisationName : '',
          debtorNumber: selectedCustomer.vcpManager ? selectedCustomer.vcpManager.debtorNumber : '',
          email: selectedCustomer.vcpManager ? selectedCustomer.vcpManager.email : '',
          contactNumber: selectedCustomer.vcpManager ? selectedCustomer.vcpManager.contactNumber : '',
          address:
            selectedCustomer.vcpManager && selectedCustomer.vcpManager.address
              ? selectedCustomer.vcpManager.address
              : '',
          step: step,
        });
      }
    } else if (prevProps.isOpen != this.props.isOpen && !this.props.isEdit) {
      this.setState({
        debtorName: null,
        organisationName: null,
        debtorNumber: null,
        email: null,
        contactNumber: null,
        address: '',
        step: 1,
      });
    }
  }
  private _handleSelectChange = (value) => {
    this.setState({ selectedManager: value });
  };
  private _closeModalCheck = () => {
    this.setState({ isCancelVerificationModalOpen: true });
  };

  private _closeModal = () => {
    this.setState({
      isLoading: false,
      isCancelVerificationModalOpen: false,
      step: 1,
      isSearching: false,
      selectedManager: null,
      selectedAddress: null,
      debtorNameError: false,
      debtorNumberError: false,
      contactNumberError: false,
      emailError: undefined,
    });
    this.props.closeModal();
  };

  private _closeCancelVerificationModal = () => {
    this.setState({ isCancelVerificationModalOpen: false });
  };

  private _validateMobileNumber = (value) => {
    const region = 'AU';

    if (!_.isEmpty(value)) {
      let phoneNumber = parsePhoneNumberFromString(value, region);
      if (phoneNumber === undefined || !phoneNumber || !phoneNumber.isValid()) {
        return false;
      }
    }
    return true;
  };

  private _handleChange = (address) => {
    this.setState({ address });
  };

  private _handleSelect = (address) => {
    this.setState({ address: address });
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          selectedAddress: CommonUtils.createAddressFromGoogleMap(results[0].address_components),
        });
      })
      .catch((error) => console.error('Error', error));
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => this.setState({ selectedLattitude: lat, selectedLongitude: lng }))
      .catch((error) => console.error('Error', error));
  };

  private _searchText = async (txt) => {
    const { doFetchDebtorList } = this.props;
    //this is where we search for planManagers
    try {
      await doFetchDebtorList({ search: txt, sortByAlphabet: true, hideArchived: true, hideInactive: true });
      this.setState({ isSearching: false });
    } catch (e) {
      console.log(e);
    }
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    if (e.length >= 1) {
      this.setState({ isSearching: true });
      this._debounceSearch(e);
    } else if (e.length === 0) {
      this.props.setDebtors([]);
    }
  };
  private _onClickSave = async () => {
    const {
      address,
      selectedLattitude,
      selectedLongitude,
      selectedAddress,
      contactNumber,
      organisationName,
      debtorName,
      email,
      debtorNumber,
      selectedManager,
    } = this.state;
    const { doAddCustomerDebtor, doEditDebtorGeneralInfo, doGetCustomer, selectedCustomer, isEdit, debtorType } =
      this.props;
    let hasError = false;
    this.setState({
      debtorNameError: false,
      emailError: undefined,
      contactNumberError: false,
      debtorNumberError: false,
    });
    if (selectedManager) {
      const payload = {
        customerUserId: selectedCustomer.userId,
        debtorId: selectedManager,
        debtorType: debtorType,
        isNewDebtor: false,
      };
      try {
        await doAddCustomerDebtor(payload);
        notification.success({
          message: `${debtorType === DebtorType.SELF ? 'Self' : 'Case'} manager added to profile`,
          description: (
            <>
              <p>
                {`You have successfully associated an existing ${
                  debtorType === DebtorType.SELF ? 'self' : 'case'
                } manager with this customer.`}
              </p>
              <GhostButton paddingSize='none' size='large' onClick={() => this.props.goDebtorHomepage(selectedManager)}>
                Go to debtor profile
              </GhostButton>
            </>
          ),
        });
        this._closeModal();
      } catch {
        notification.error({ message: 'Oops, an error has occurred, please try again.' });
      }
    } else {
      if (!debtorName) {
        this.setState({ debtorNameError: true });
        hasError = true;
      }
      if (!email) {
        this.setState({ emailError: 'Please enter a valid email address' });
        hasError = true;
      }
      if (email) {
        if (!validateEmailFormat(email)) {
          this.setState({ emailError: 'Please enter a valid email address' });
          hasError = true;
        }
      }
      if (!this._validateMobileNumber(contactNumber)) {
        this.setState({ contactNumberError: true });
        hasError = true;
      }
      let debtorAdress = {};
      if (selectedAddress) {
        debtorAdress = {
          ...selectedAddress,
          geoLat: selectedLattitude,
          geoLng: selectedLongitude,
          fullAddress: address,
        };
      }

      if (!hasError) {
        if (!isEdit) {
          const payload = {
            customerUserId: selectedCustomer.userId,
            newDebtor: {
              debtorName: debtorName,
              organisationName: organisationName,
              debtorNumber: debtorNumber,
              debtorContactNumber: contactNumber,
              debtorEmail: email,
              debtorCountryCode: contactNumber ? 'AU' : null,
              debtorAddress: debtorAdress,
            },
            debtorType: debtorType,
            isNewDebtor: true,
          };

          try {
            const debtorId = await doAddCustomerDebtor(payload);
            notification.success({
              message: `${debtorType === DebtorType.SELF ? 'Self' : 'Case'} manager created`,
              description: (
                <>
                  <p>
                    {`You have successfully created a new ${
                      debtorType === DebtorType.SELF ? 'self' : 'case'
                    } manager and associated it with this customer.`}
                  </p>
                  <GhostButton paddingSize='none' size='large' onClick={() => this.props.goDebtorHomepage(debtorId)}>
                    Go to debtor profile
                  </GhostButton>
                </>
              ),
            });
            this._closeModal();
          } catch (e) {
            this.handleError(e);
          }
        } else {
          const payload = {
            debtorId:
              debtorType === DebtorType.SELF
                ? selectedCustomer.selfManager.debtorId
                : selectedCustomer.vcpManager.debtorId,
            debtorName: debtorName,
            organisationName: organisationName,
            debtorNumber: debtorNumber,
            contactNumber: contactNumber,
            email: email,
            contactNumberCountryCode: contactNumber ? 'AU' : null,
            address: debtorAdress,
          };

          try {
            const result = await doEditDebtorGeneralInfo(payload);
            if (result) {
              await doGetCustomer({ userId: selectedCustomer.userId });
              notification.success({
                message: `Details successfully changed`,
                description: (
                  <>
                    <p>You have successfully changed the contact details of this manager.</p>
                  </>
                ),
              });
              this._closeModal();
            }
          } catch (e) {
            this.handleError(e);
          }
        }
      }
    }
  };

  private handleError = (e) => {
    let capturedKnownError = false;
    if ('meta' in e && 'message' in e.meta) {
      if (e.meta.message.includes('The provided debtor email already exists')) {
        capturedKnownError = true;
        this.setState({ emailError: 'This debtor already exists. Please enter a different email address.' });
      }

      if (e.meta.message.includes('The provided debtor number already exists')) {
        capturedKnownError = true;
        this.setState({ debtorNumberError: true });
      }
    }

    if (!capturedKnownError) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
      this.props.newRelicBrowser?.noticeError(e);
    }
  };

  render() {
    const { isEdit, debtorType, debtors } = this.props;
    const {
      isLoading,
      isCancelVerificationModalOpen,
      step,
      isSearching,
      selectedManager,
      debtorName,
      organisationName,
      debtorNumber,
      email,
      contactNumber,
      debtorNameError,
      contactNumberError,
      emailError,
      debtorNumberError,
    } = this.state;

    return (
      <div>
        <ActionModal
          isOpen={isCancelVerificationModalOpen}
          onClose={this._closeCancelVerificationModal}
          title={'Cancel changes'}
          showCloseButton={true}
          width={'small'}
        >
          <Text className={'mb-medium'}>Your changes hasn't been saved, proceeding will discard it.</Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className='mr-medium' size='large' onClick={this._closeCancelVerificationModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size='large' onClick={this._closeModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          title={`${isEdit ? 'Edit' : 'Add'} ${debtorType === DebtorType.SELF ? 'self' : 'case'} manager`}
          isOpen={this.props.isOpen}
          onClose={this._closeModalCheck}
          width={step === 3 || step === 4 || step === 5 ? 'small' : 'medium'}
        >
          {step === 1 && !isEdit && (
            <>
              <Row className='mt-medium'>
                <SubTitle>Select a {debtorType === DebtorType.SELF ? 'self' : 'case'} manager</SubTitle>
              </Row>
              <Row className='mt-small'>
                <Text>
                  Choose from existing {debtorType === DebtorType.SELF ? 'self' : 'case'} managers in your GoodHuman
                  workspace.
                </Text>
              </Row>
              <Row className='mt-small'>
                <Select
                  showSearch={true}
                  placeholder={`Select an existing ${debtorType === DebtorType.SELF ? 'self' : 'case'} manager`}
                  notFoundContent={isSearching ? <Spin size='small' /> : null}
                  onSearch={this._onEnterSearchText}
                  onChange={this._handleSelectChange}
                  optionLabelProp={'title'}
                  filterOption={false}
                  style={{ width: '400px' }}
                >
                  {_.map(debtors, (manager) => (
                    <Select.Option value={manager.debtorId} title={manager.debtorName} key={manager.debtorId}>
                      <div>
                        <Row>
                          <Text>{manager.debtorName}</Text>
                        </Row>
                        <Row>
                          <Text color={'secondary'}>{manager.debtorEmail}</Text>
                        </Row>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Row>
              <Row className='mt-medium'>
                <GhostButton
                  size={'large'}
                  icon={'plus'}
                  paddingSize={'none'}
                  onClick={() => this.setState({ step: 2, selectedManager: null })}
                >
                  Add {debtorType === DebtorType.SELF ? 'self' : 'case'} manager details
                </GhostButton>
              </Row>
              <Row type={'flex'} className={'justify-end mt-x-large'}>
                <Col className='mr-large'>
                  <GhostButton size='large' onClick={this._closeModalCheck}>
                    Cancel
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton
                    size='large'
                    loading={isLoading}
                    disabled={!selectedManager}
                    onClick={this._onClickSave}
                  >
                    Save
                  </PrimaryButton>
                </Col>
              </Row>
            </>
          )}
          {step === 2 && (
            <>
              <div className={'mt-small'}>
                <div>
                  <SubTitle>{debtorType === DebtorType.SELF ? 'Self' : 'Case'} Manager Name</SubTitle>
                  <div className='mt-x2-small'>
                    <Input
                      style={{ width: '50%' }}
                      placeholder={'Enter name'}
                      size={'large'}
                      value={debtorName}
                      onChange={(e) => this.setState({ debtorName: e.target.value })}
                    />
                  </div>
                  {debtorNameError && (
                    <Text size='regular' color='red' className='mt-small'>
                      Please enter a {debtorType === DebtorType.SELF ? 'self' : 'case'} manager name
                    </Text>
                  )}
                </div>
                <div className='mt-medium'>
                  <div className='flex'>
                    <SubTitle>Organisation Name</SubTitle>
                    <Text color='secondary' size='small' className='ml-small'>
                      (Optional)
                    </Text>
                  </div>
                  <div className='mt-x2-small'>
                    <Input
                      style={{ width: '50%' }}
                      placeholder={'Enter organisation name'}
                      size={'large'}
                      value={organisationName}
                      onChange={(e) => this.setState({ organisationName: e.target.value })}
                    />
                  </div>
                </div>
                <div className='mt-medium'>
                  <div className='flex'>
                    <SubTitle>{debtorType === DebtorType.SELF ? 'Self' : 'Case'} Manager ID</SubTitle>
                    <Text color='secondary' size='small' className='ml-small'>
                      (Optional)
                    </Text>
                  </div>
                  <div className='mt-x2-small'>
                    <Input
                      style={{ width: '50%' }}
                      placeholder={'Enter ID'}
                      size={'large'}
                      value={debtorNumber}
                      onChange={(e) => this.setState({ debtorNumber: e.target.value })}
                      className={debtorNumberError ? 'border-red' : undefined}
                    />
                  </div>
                  {debtorNumberError && (
                    <Text size='regular' color='red' className='mt-small'>
                      This debtor ID already exists, please use a unique ID.
                    </Text>
                  )}
                </div>
                <div className='mt-medium'>
                  <SubTitle>Email</SubTitle>
                  <div className='mt-x2-small'>
                    <Input
                      type={'email'}
                      style={{ width: '50%' }}
                      placeholder={'Enter email'}
                      size={'large'}
                      value={email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      className={emailError ? 'border-red' : undefined}
                    />
                  </div>
                  {emailError && (
                    <Text size='regular' color='red' className='mt-small'>
                      {emailError}
                    </Text>
                  )}
                </div>

                <div className='mt-medium'>
                  <div className='flex'>
                    <SubTitle>Contact Number</SubTitle>
                    <Text color='secondary' size='small' className='ml-small'>
                      (Optional)
                    </Text>
                  </div>
                  <div className={'flex-row mt-x2-small'}>
                    <Select disabled size='large' className='mr-small' style={{ width: '110px' }} defaultValue='AU'>
                      <Select.Option value={'AU'}>+61 (AU)</Select.Option>
                    </Select>

                    <Input
                      size={'large'}
                      placeholder={'Enter number'}
                      style={{ width: '200px' }}
                      value={contactNumber}
                      onChange={(e) => this.setState({ contactNumber: e.target.value })}
                      className={contactNumberError ? 'border-red' : undefined}
                    />
                  </div>
                  {contactNumberError && (
                    <Text size='regular' color='red' className='mt-small'>
                      Please enter a valid phone number
                    </Text>
                  )}
                </div>
                <div className='mt-medium mb-large'>
                  <div className='flex'>
                    <SubTitle>Address</SubTitle>
                    <Text color='secondary' size='small' className='ml-small'>
                      (Optional)
                    </Text>
                  </div>
                  <div className={'mt-x2-small'}>
                    <PlacesAutocomplete
                      value={this.state.address}
                      onSelect={this._handleSelect}
                      onChange={this._handleChange}
                      shouldFetchSuggestions={this.state.address.length > 2}
                      searchOptions={searchAutocompleteOptions}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <Input
                            value={this.state.address}
                            size={'large'}
                            {...getInputProps({
                              placeholder: 'Search for an address ...',
                              className: 'location-search-input',
                            })}
                          />
                          <div className='autocomplete-dropdown-container'>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                              const style = suggestion.active
                                ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer',
                                    borderTop: '1px ridge grey',
                                    borderLeft: '1px ridge grey',
                                    borderRight: '1px ridge grey',
                                    padding: '10px',
                                  }
                                : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer',
                                    borderTop: '1px ridge grey',
                                    borderLeft: '1px ridge grey',
                                    borderRight: '1px ridge grey',
                                    padding: '10px',
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>
                                    <Icon type={'environment'} /> {suggestion.description}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <Row type={'flex'} className={'justify-end mt-x-large'}>
                  <Col className='mr-large'>
                    {isEdit ? (
                      <GhostButton size='large' loading={isLoading} onClick={this._closeModalCheck}>
                        Cancel
                      </GhostButton>
                    ) : (
                      <GhostButton size='large' loading={isLoading} onClick={() => this.setState({ step: 1 })}>
                        Go back
                      </GhostButton>
                    )}
                  </Col>
                  <Col>
                    <PrimaryButton
                      disabled={!debtorName || !email}
                      size='large'
                      loading={isLoading}
                      onClick={this._onClickSave}
                    >
                      Save
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setDebtors: dispatch.accountStore.setDebtors,
  doUpdateFundingGeneralInfo: dispatch.customersStore.doUpdateFundingGeneralInfo,
  doFetchDebtorList: dispatch.accountStore.doFetchDebtorList,
  doAddCustomerDebtor: dispatch.customersStore.doAddCustomerDebtor,
  doEditDebtorGeneralInfo: dispatch.accountStore.doEditDebtorGeneralInfo,
  doGetCustomer: dispatch.customersStore.doGetCustomer,
});

const mapState = (state: IRootState) => ({
  debtors: state.accountStore.debtors,
});

export default WithNewRelicBrowser(connect(mapState, mapDispatch)(AddEditSelfAndCaseManagerModal));
