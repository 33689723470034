import { forwardRef } from 'react';
import {
  DateField as _DateField,
  DateInput,
  DateSegment,
  Text,
  type DateFieldProps as _DateFieldProps,
  type DateValue,
} from 'react-aria-components';

import * as styles from './date-time-field.css';
import type { InputProps, LabelProps } from '../types';
import { FieldLabel } from '../field-label';
import { StatusMessage } from '../status-message';
import { twMerge } from '../utils';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- using interface features
export interface DateFieldProps<T extends DateValue> extends _DateFieldProps<T>, InputProps, LabelProps {}

export const DateField = forwardRef<HTMLDivElement, DateFieldProps<DateValue>>((props, ref) => {
  const {
    description,
    errorMessage,
    hideTimeZone = true,
    hourCycle = 12,
    isRequired,
    label,
    requirementIndicator,
    validationState,
    ...otherProps
  } = props;

  const isDescription = Boolean(description);
  const isErrorMessage = Boolean(errorMessage);
  const isInvalid = validationState === 'invalid';
  const showErrorMessage = isInvalid && isErrorMessage;
  const showDescription = isDescription && !showErrorMessage;
  const { container, input, segment } = styles.dateField({ validationState });

  return (
    <_DateField {...otherProps} hourCycle={hourCycle} hideTimeZone={hideTimeZone} className={container()} ref={ref}>
      <FieldLabel requirementIndicator={requirementIndicator} isRequired={isRequired}>
        {label}
      </FieldLabel>

      <DateInput className={twMerge(input())}>
        {(dateSegment) => <DateSegment segment={dateSegment} className={segment()} />}
      </DateInput>

      {showDescription && (
        <Text slot="description">
          <StatusMessage tone="neutral">{description}</StatusMessage>
        </Text>
      )}

      {showErrorMessage && (
        <Text slot="errorMessage">
          <StatusMessage tone="critical">{errorMessage}</StatusMessage>
        </Text>
      )}
    </_DateField>
  );
});

DateField.displayName = 'DateField';
