import React, { Component } from 'react';
import { Row, Col, Icon, Tabs, Checkbox, notification } from 'antd';
import { Text } from 'common-components/typography';
import ActionModal from 'common-components/modal/ActionModal';
import _, { forEach } from 'lodash';
import { HyperlinkButton, SecondaryButton } from 'common-components/buttons';
import moment from 'moment-timezone';
import {
  BookingStatus,
  EditRecurringMode,
  FilterType,
  RecurringBookingPattern,
  ShiftSlotStatus,
} from 'utilities/enum-utils';
import { TimezoneIndicator } from 'common-components/timezone';
import { timeZone } from 'interfaces/timezone-type';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import ExtendRecurringBookingsModal from 'views/bookings/listings/components/ExtendRecurringBookingsModal';
import { BottomActionSheet } from 'common-components/Sheets/BottomActionSheet';
import { StatusTag } from 'common-components/tags';
import UserAvatar from 'common-components/user-avatar';
import { FilterSection } from 'common-components/filter';
import { BOOKINGLIST_FILTERCONFIGS } from '../../new-listings/components/bookListFilterConfig';
import CancelBulkBookingsModal from './CancelBulkBookingsModal';
import BookingBulkRemoveTeamMemberModal from '../../components/BookingBulkRemoveTeamMemberModal';
import { Link } from 'react-router-dom';
import { IRecurringPattern } from 'interfaces/booking-interfaces';

const availableFilters = [FilterType.DATE_RANGE, FilterType.WORKER, FilterType.SHIFT_SLOT_STATUS];

interface IRecurringBookingListModalProps {
  isOpen: boolean;
  bookingItem: any;
  displayTimezone: timeZone;
  closeRecurringBookingListModal: () => void;
  doFetchRecurringBookingsList: typeof dispatch.bookingsStore.doFetchRecurringBookingsList;
  recurringBookingList: typeof state.bookingsStore.recurringBookingList;
  bookingRecurringPattern: typeof state.bookingsStore.bookingRecurringPattern;

  selectedBookings: typeof state.bookingsStore.selectedBookings;
  setSelectedBookings: typeof dispatch.bookingsStore.setSelectedBookings;
  doBulkCancelBookingsRecurringModal: typeof dispatch.bookingsStore.doBulkCancelBookingsRecurringModal;
  portalUser: typeof state.authStore.portalUser;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  doBulkRemoveTeamMemberFromRecurringListing: typeof dispatch.bookingsStore.doBulkRemoveTeamMemberFromRecurringListing;
}

interface IRecurringBookingListModalState {
  isLoading: boolean;
  bookingInRecurrenceList: any;
  recurringPattern: IRecurringPattern | null;
  selectedTab: string;
  isExtendRecurringModalOpen: boolean;

  selectedTabRecurrenceList: any[];

  checkAllIndicator: boolean;
  indeterminateCheck: boolean;
  showActionSheet: boolean;
  bookingsFilter: any[];
  isCancelBookingOpen: boolean;
  isBookingBulkRemoveTeamMemberModalOpen: boolean;
  removeTeamMemberBookingList: any[];
  bookingStatus: string;
}

class RecurringBookingListModal extends Component<IRecurringBookingListModalProps, IRecurringBookingListModalState> {
  state = {
    isLoading: false,
    filters: { startDate: null, endDate: null },
    bookingInRecurrenceList: this.props.recurringBookingList || [],
    recurringPattern: this.props.bookingRecurringPattern ? this.props.bookingRecurringPattern : null,
    selectedTab: 'UPCOMING',
    isExtendRecurringModalOpen: false,

    selectedTabRecurrenceList: [],

    checkAllIndicator: false,
    indeterminateCheck: false,
    showActionSheet: false,
    bookingsFilter: [],
    isCancelBookingOpen: false,
    isBookingBulkRemoveTeamMemberModalOpen: false,
    removeTeamMemberBookingList: [],
    bookingStatus: '',
    displayTimezone: this.props.portalUser.timezone,
  };

  private _changeTab = (event) => {
    this.setState({ selectedTab: event, showActionSheet: false, checkAllIndicator: false, indeterminateCheck: false });
  };

  private _openExtendRecurringModal = () => {
    this.setState({ isExtendRecurringModalOpen: true });
  };

  private _closeExtendRecurringModal = () => {
    this.setState({ isExtendRecurringModalOpen: false });
  };

  private _onCloseCancelBookingModal = () => {
    this.setState({ isCancelBookingOpen: false });
  };

  private _onCloseBookingBulkRemoveTeamMemberModal = () => {
    this.setState({ isBookingBulkRemoveTeamMemberModalOpen: false });
  };

  // Check all handler
  private _onCheckAll = () => {
    const { checkAllIndicator, selectedTabRecurrenceList } = this.state;

    const newCheckAll = !checkAllIndicator;
    const newSelectedTabRecurrenceList = selectedTabRecurrenceList.map((b) => ({
      ...b,
      isChecked: newCheckAll,
    }));

    this.setState({
      selectedTabRecurrenceList: newSelectedTabRecurrenceList,
      checkAllIndicator: newCheckAll,
      showActionSheet: newCheckAll,
      indeterminateCheck: false,
    });
  };

  // Deselect action handler
  private _onActionDeselect = () => {
    const { selectedTabRecurrenceList } = this.state;

    // Unselect all
    const newSelectedTabRecurrenceList = selectedTabRecurrenceList.map((b) => ({ ...b, isChecked: false }));

    // reset everything
    this.setState({
      showActionSheet: false,
      indeterminateCheck: false,
      checkAllIndicator: false,
      selectedTabRecurrenceList: newSelectedTabRecurrenceList,
    });
  };

  private _isBookingChecked = (recurrence: any): boolean => {
    const { selectedTabRecurrenceList } = this.state;

    const selectedBooking = _.find(selectedTabRecurrenceList, (b) => b.attendanceId === recurrence.attendanceId);
    return selectedBooking ? selectedBooking.isChecked : false;
  };

  // Checkbox handler
  private _onCheckBooking = (recurrence: any) => {
    const { selectedTabRecurrenceList } = this.state;

    const newSelectedTabRecurrenceList = selectedTabRecurrenceList.map((b) => {
      return b.attendanceId === recurrence.attendanceId ? { ...b, isChecked: !b.isChecked } : { ...b };
    });
    const checkBookings = _.filter(newSelectedTabRecurrenceList, (b) => b.isChecked === true);
    this.setState({
      selectedTabRecurrenceList: newSelectedTabRecurrenceList,
      showActionSheet: checkBookings.length > 0,
      checkAllIndicator: checkBookings.length === newSelectedTabRecurrenceList.length,
      indeterminateCheck: checkBookings.length > 0 && checkBookings.length < newSelectedTabRecurrenceList.length,
    });
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.setState({ bookingsFilter: filters });
  };

  private _onFilterSelectedTabRecurrenceList = () => {
    //filter by filter section
    const displayTimezone = this.props.displayTimezone ? this.props.displayTimezone : this.props.bookingItem.timezone;
    const filteredRecurrenceList = _.filter(this.state.bookingInRecurrenceList, (booking) => {
      let isValidDateTime = false,
        isValidTeamMember = false,
        isValidTeamMemberStatus = false;
      this.state.bookingsFilter.forEach((filter) => {
        switch (filter.filter) {
          case FilterType.DATE_RANGE:
            const filterStartTime = filter.values[0];
            const filterEndTime = filter.values[1];
            if (filter.values.length === 0) {
              isValidDateTime = true;
            } else if (
              moment.tz(booking.endDateTime, displayTimezone) >= filterStartTime &&
              moment.tz(booking.endDateTime, displayTimezone) <= filterEndTime
            ) {
              isValidDateTime = true;
            }
            break;
          case FilterType.WORKER:
            if (filter.values.length === 0) {
              isValidTeamMember = true;
            } else {
              filter.values.forEach((f) => {
                if (_.includes(f, booking.supportWorkerId)) {
                  isValidTeamMember = true;
                }
              });
            }
            break;
          case FilterType.SHIFT_SLOT_STATUS:
            if (filter.values.length === 0) {
              isValidTeamMemberStatus = true;
            } else {
              filter.values.forEach((f) => {
                if (_.includes(f, booking.shiftSlotStatus)) {
                  isValidTeamMemberStatus = true;
                }
              });
            }
            break;
          default:
            break;
        }
      });
      return isValidDateTime && isValidTeamMember && isValidTeamMemberStatus;
    });

    //filter by selected tab
    // TODO: Add the new cancellation status
    const newSelectedTabRecurrenceList =
      this.state.selectedTab === 'UPCOMING'
        ? _.filter(
            filteredRecurrenceList,
            (recurrence) =>
              recurrence.status !== BookingStatus.COMPLETED &&
              recurrence.status !== BookingStatus.CUSTOMER_CANCELLED &&
              recurrence.status !== BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE &&
              recurrence.status !== BookingStatus.CUSTOMER_CANCELLED_WITH_FEE &&
              recurrence.status !== BookingStatus.BUSINESS_CANCELLED,
          )
        : _.filter(
            filteredRecurrenceList,
            (recurrence) =>
              recurrence.status === BookingStatus.COMPLETED ||
              recurrence.status === BookingStatus.CUSTOMER_CANCELLED ||
              recurrence.status === BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE ||
              recurrence.status === BookingStatus.CUSTOMER_CANCELLED_WITH_FEE ||
              recurrence.status === BookingStatus.BUSINESS_CANCELLED,
          );

    this.setState({
      selectedTabRecurrenceList: newSelectedTabRecurrenceList,
      showActionSheet: false,
      indeterminateCheck: false,
      checkAllIndicator: false,
    });
  };

  private _onOpenBookingBulkActionModal = ({ action }) => {
    if (action === 'PROCESS') {
      //Remove team member
      this.setState({ isBookingBulkRemoveTeamMemberModalOpen: true });
    } else if (action === 'PROCESS_ADDITIONAL') {
      // Cancel Booking
      this.setState({ isCancelBookingOpen: true });
    }
  };

  private _onBulkRemoveTeamMemberBookingListing = async () => {
    try {
      const removeWorkersFromBookings = [];
      _.forEach(this.state.removeTeamMemberBookingList, (booking) => {
        removeWorkersFromBookings.push({
          bookingId: booking.attendanceId,
          supportWorkerId: booking.supportWorkerId,
        });
      });

      const payload = {
        bookingId: this.props.bookingItem.bookingId,
        bookingRequestId: this.props.selectedBookingItem.bookingRequestId,
        bookingType: 'BOOKING',
        editRecurringMode: EditRecurringMode.Custom,
        removeWorkersFromBookings: removeWorkersFromBookings,
      };

      await this.props.doBulkRemoveTeamMemberFromRecurringListing(payload);

      notification.success({
        message: <Text weight="bold">Bulk actions successfully completed.</Text>,
        description: (
          <Text className="mt-medium">
            Bulk actions for <span className="text-weight-bold">Remove Team Members</span> complete.
          </Text>
        ),
        duration: 3,
      });
    } catch (error) {
      notification.error({
        message: <Text weight="bold">Bulk actions failed.</Text>,
        description: (
          <Text className="mt-medium">
            Bulk actions for <span className="text-weight-bold">Remove Team Members</span> complete has encounter an
            error. Please try again.
          </Text>
        ),
      });
    }
  };

  private _onBulkCancelBookingListing = async (data): Promise<void> => {
    const selectedBookings = this.state.selectedTabRecurrenceList.filter((r) => r.isChecked);
    const bookingIdsToCancel = selectedBookings.map((selectedBooking) => selectedBooking.attendanceId);

    const payload = {
      bookingId: this.props.bookingItem.bookingId,
      bookingRequestId: this.props.bookingItem.bookingRequestId,
      editRecurringMode: EditRecurringMode.Custom,
      cancelBookingIds: bookingIdsToCancel,
      ...data,
    };
    await this.props.doBulkCancelBookingsRecurringModal(payload);
  };

  componentDidMount = async () => {
    if (this.props.bookingItem) {
      this.setState({ isLoading: true });
      await this.props.doFetchRecurringBookingsList({
        bookingId: this.props.bookingItem.bookingId,
      });
      const currentFilterConfig = BOOKINGLIST_FILTERCONFIGS['ALL'].filters.filter(({ filter }) => {
        if (filter === 'sort') {
          return false;
        }
        return availableFilters.includes(filter);
      });

      this.setState({ bookingsFilter: currentFilterConfig });
      this._onFilterSelectedTabRecurrenceList();
    }
  };

  componentDidUpdate = async (
    prevProps: Readonly<IRecurringBookingListModalProps>,
    prevState: Readonly<IRecurringBookingListModalState>,
  ) => {
    if (this.props.bookingItem && prevProps.bookingItem !== this.props.bookingItem) {
      this.setState({ isLoading: true });
      await this.props.doFetchRecurringBookingsList({
        bookingId: this.props.bookingItem.bookingId,
      });
      this.setState(
        {
          bookingInRecurrenceList: this.props.recurringBookingList,
          recurringPattern: this.props.bookingRecurringPattern,
          isLoading: false,
        },
        () => this._onFilterSelectedTabRecurrenceList(),
      );
    }

    if (
      prevProps.bookingRecurringPattern !== this.props.bookingRecurringPattern ||
      prevProps.recurringBookingList !== this.props.recurringBookingList
    ) {
      this.setState({
        bookingInRecurrenceList: this.props.recurringBookingList,
        recurringPattern: this.props.bookingRecurringPattern,
        isLoading: false,
      });
    }

    if (prevState.selectedTab !== this.state.selectedTab || prevState.bookingsFilter !== this.state.bookingsFilter) {
      // TODO: Add the new cancellation status
      this._onFilterSelectedTabRecurrenceList();
    }

    if (
      this.state.isBookingBulkRemoveTeamMemberModalOpen &&
      this.state.isBookingBulkRemoveTeamMemberModalOpen !== prevState.isBookingBulkRemoveTeamMemberModalOpen
    ) {
      // The filteredRecurringBookingList will be replace with selectedBookings.
      const filterPreRemoveModal = _.filter(this.state.selectedTabRecurrenceList, (booking) => {
        return (
          (booking.shiftSlotStatus === ShiftSlotStatus.CONFIRMED ||
            booking.shiftSlotStatus === ShiftSlotStatus.PENDING) &&
          booking.isChecked
        );
      });

      const refactorBookingList = _.map(filterPreRemoveModal, (booking) => {
        return {
          ...booking,
          serviceName: this.props.selectedBookingItem.serviceName,
          timezone: this.props.selectedBookingItem.timezone,
          customerFirstName: this.props.selectedBookingItem.firstName,
          customerLastName: this.props.selectedBookingItem.lastName,
          attachmentUrl: this.props.selectedBookingItem.attachmentUrl,
          pinnedAlertNumber: this.props.selectedBookingItem.pinnedAlertNumber,
          bookerUserId: this.props.selectedBookingItem.bookerUserId,
          workerAttachmentUrl: booking.attachmentUrl,
          workerFirstName: booking.firstName,
          workerLastName: booking.lastName,
          shiftSlotStatus: booking.shiftSlotStatus,
        };
      });

      this.setState({ removeTeamMemberBookingList: refactorBookingList });
    }

    const modals = document.getElementsByClassName('bp3-portal');
    if (modals) {
      forEach(modals, (element) => element.setAttribute('style', 'z-index:1;'));
    }
  };

  render() {
    const { bookingItem } = this.props;
    const { bookingInRecurrenceList, recurringPattern, selectedTab, isLoading, selectedTabRecurrenceList } = this.state;

    const displayTimezone = this.props.displayTimezone
      ? this.props.displayTimezone
      : this.props.bookingItem
      ? this.props.bookingItem.timezone
      : null;

    const recurrenceSummaryText = (() => {
      if (!recurringPattern) {
        return null;
      }
      switch (recurringPattern.recurringPattern) {
        case RecurringBookingPattern.EveryDay:
          return 'day';
        case RecurringBookingPattern.EveryWeek:
          return 'week';
        case RecurringBookingPattern.EveryFortnight:
          return 'two weeks';
        case RecurringBookingPattern.EveryThreeWeeks:
          return 'three weeks';
        case RecurringBookingPattern.EveryFourWeeks:
          return 'four weeks';
        case RecurringBookingPattern.Monthly:
          return 'month';
      }
    })();

    const selectedItems = selectedTabRecurrenceList.filter((r) => r.isChecked);

    return (
      <div>
        <BookingBulkRemoveTeamMemberModal
          isOpen={this.state.isBookingBulkRemoveTeamMemberModalOpen}
          bookingList={this.state.removeTeamMemberBookingList}
          onClose={this._onCloseBookingBulkRemoveTeamMemberModal}
          onRemove={this._onBulkRemoveTeamMemberBookingListing}
          topHeight={0}
          page={1}
          pageSize={20}
          pageTimestamp={new Date()}
          displayTimezone={this.props.displayTimezone}
          isLoading={this.state.isLoading}
        />

        <CancelBulkBookingsModal
          isOpen={this.state.isCancelBookingOpen}
          onClose={this._onCloseCancelBookingModal}
          onCancel={this._onBulkCancelBookingListing}
          selectedBookings={selectedItems}
          amountItems={selectedItems.length}
        />

        <ExtendRecurringBookingsModal
          isOpen={this.state.isExtendRecurringModalOpen}
          bookingRecurringPattern={this.props.bookingRecurringPattern}
          bookingItem={bookingItem}
          onCloseModal={this._closeExtendRecurringModal}
          displayTimezone={displayTimezone}
          recurringBookings={this.props.recurringBookingList}
        />

        <ActionModal
          isOpen={this.props.isOpen}
          title={'Bookings in this recurring series'}
          onClose={this.props.closeRecurringBookingListModal}
          verticalAlignment={'center'}
          width="x-large"
        >
          {bookingItem && (
            <>
              <div className="anim-slide-left">
                <Row className={'mb-medium'}>
                  <Col span={8}>
                    <Text size={'x-large'}>{bookingItem.serviceName}</Text>
                  </Col>
                  <Col span={16} className="text-align-right">
                    {displayTimezone && <TimezoneIndicator timezone={displayTimezone} />}
                  </Col>
                </Row>
                <div className="mb-small">
                  {isLoading ? (
                    <SpinningLoader size={20} message={''} />
                  ) : recurringPattern ? (
                    <Text>
                      This booking will happen <b>every {recurrenceSummaryText}</b>, starting on the{' '}
                      <b>{moment.tz(recurringPattern.startDateTime, displayTimezone).format('DD/MM/YYYY')}</b> and
                      finishing on the{' '}
                      <b>{moment.tz(recurringPattern.recurringTo, displayTimezone).format('DD/MM/YYYY')}</b> for a total
                      of <b>{bookingInRecurrenceList && bookingInRecurrenceList.length}</b> booking
                      {bookingInRecurrenceList && bookingInRecurrenceList.length !== 1 ? 's' : ''}.
                    </Text>
                  ) : (
                    ''
                  )}
                </div>
                <div className="mb-none">
                  <SecondaryButton onClick={this._openExtendRecurringModal}>Extend recurring series</SecondaryButton>
                </div>
                <div>
                  <FilterSection
                    containerClassName="pv-medium"
                    availableFilters={availableFilters}
                    filters={this.state.bookingsFilter}
                    onChangeFilter={this._onChangeFilter}
                    displayTimezone={this.props.displayTimezone}
                    displayMoreFilter={false}
                  />
                </div>
                <Tabs defaultActiveKey={selectedTab} animated={true} onChange={this._changeTab} className={''}>
                  <Tabs.TabPane tab="Upcoming bookings in this series" key="UPCOMING" />
                  <Tabs.TabPane tab="Completed bookings in this series" key="COMPLETED" />
                </Tabs>
                <Row className="text-weight-bold bordered-bottom border-standard-gray p-small">
                  <Col span={1}>
                    <Checkbox
                      onClick={this._onCheckAll}
                      checked={this.state.checkAllIndicator}
                      indeterminate={this.state.indeterminateCheck}
                    />
                  </Col>
                  <Col span={5}>Date</Col>
                  <Col span={6}>Status</Col>
                  <Col span={8}>Team member</Col>
                  <Col span={4} />
                </Row>
                <div
                  style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}
                  className="bordered-bottom border-standard-gray"
                >
                  {isLoading ? (
                    <SpinningLoader size={100} message={'Fetching bookings in recurrence...'} />
                  ) : (
                    <>
                      {(!selectedTabRecurrenceList || selectedTabRecurrenceList.length === 0) && (
                        <Row className="pv-medium">
                          <Text>No recurrences found.</Text>
                        </Row>
                      )}
                      {_.map(selectedTabRecurrenceList, (recurrence) => {
                        if (!recurrence.hideRow) {
                          return (
                            <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                              <Col span={1} onClick={() => this._onCheckBooking(recurrence)}>
                                <Checkbox checked={this._isBookingChecked(recurrence)} />
                              </Col>
                              <Col span={5}>
                                {moment
                                  .tz(recurrence.startDateTime, displayTimezone)
                                  .startOf('day')
                                  .isSame(moment.tz(recurrence.endDateTime, displayTimezone).startOf('day')) ? (
                                  <Text>
                                    <b>{moment.tz(recurrence.startDateTime, displayTimezone).format('DD/MM/YYYY')}</b>
                                    <br />
                                    <Text>
                                      {moment.tz(recurrence.startDateTime, displayTimezone).format('hh:mm a')} -{' '}
                                      {moment.tz(recurrence.endDateTime, displayTimezone).format('hh:mm a')}
                                    </Text>
                                  </Text>
                                ) : (
                                  <Text>
                                    <b>{moment.tz(recurrence.startDateTime, displayTimezone).format('DD/MM/YYYY')}</b>{' '}
                                    {moment.tz(recurrence.startDateTime, displayTimezone).format('hh:mm a')}
                                    <br />
                                    <b>
                                      {moment.tz(recurrence.endDateTime, displayTimezone).format('DD/MM/YYYY')}
                                    </b>{' '}
                                    {moment.tz(recurrence.endDateTime, displayTimezone).format('hh:mm a')}
                                  </Text>
                                )}
                              </Col>
                              <Col span={6}>
                                <StatusTag status={recurrence.status} size="small" />
                              </Col>
                              <Col
                                span={8}
                                title={recurrence.shiftSlotStatus}
                                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                              >
                                {recurrence.shiftSlotStatus === ShiftSlotStatus.UNASSIGNED ||
                                recurrence.supportWorkerId === null ? (
                                  <Text color={'red-dark'}>No worker assigned</Text>
                                ) : recurrence.shiftSlotStatus === ShiftSlotStatus.PUBLISHING ? (
                                  <Text color={'violet-light'}>
                                    <Icon type="wifi" className="mr-x-small" />
                                    Published
                                  </Text>
                                ) : (
                                  <div className="align-start flex justify-start">
                                    <UserAvatar
                                      className="mh-small"
                                      avatarSrc={recurrence.attachmentUrl}
                                      icon={
                                        recurrence.shiftSlotStatus === ShiftSlotStatus.CONFIRMED
                                          ? 'check-circle'
                                          : recurrence.shiftSlotStatus === ShiftSlotStatus.PENDING
                                          ? 'clock-circle'
                                          : null
                                      }
                                      iconClassName={
                                        recurrence.shiftSlotStatus === ShiftSlotStatus.CONFIRMED
                                          ? 'text-color-blue-lighter'
                                          : 'text-color-warning-orange'
                                      }
                                      tooltipContent={
                                        <>
                                          {recurrence.firstName} {recurrence.lastName} has{' '}
                                          {recurrence.shiftSlotStatus === ShiftSlotStatus.CONFIRMED ? (
                                            <b>confirmed</b>
                                          ) : (
                                            <b>not confirmed</b>
                                          )}{' '}
                                          this shift.
                                        </>
                                      }
                                    />
                                    {recurrence.firstName} {recurrence.lastName}
                                  </div>
                                )}
                              </Col>
                              <Col span={4}>
                                <Link to={`/bookings/details/${recurrence.attendanceId}`}>
                                  <HyperlinkButton>Go to booking...</HyperlinkButton>
                                </Link>
                              </Col>
                            </Row>
                          );
                        }
                      })}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="flex-1 bg-white">&nbsp;</div>
          {this.state.showActionSheet && (
            <BottomActionSheet
              paymentsList={selectedItems}
              onDeselect={this._onActionDeselect}
              openActionModal={this._onOpenBookingBulkActionModal}
              hasPrimaryButton={selectedItems.some((r) => r.supportWorkerId !== null)}
              primaryButtonTitle={`Remove team members`}
              hasAdditionalButton={true}
              additionalButtonLabel={`Cancel bookings`}
              isHaveActions={selectedTab === 'UPCOMING'}
              isActionProcessing={this.state.isBookingBulkRemoveTeamMemberModalOpen || this.state.isCancelBookingOpen}
            />
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  recurringBookingList: state.bookingsStore.recurringBookingList,
  bookingRecurringPattern: state.bookingsStore.bookingRecurringPattern,

  selectedBookings: state.bookingsStore.selectedBookings,
  portalUser: state.authStore.portalUser,
  selectedBookingItem: state.bookingsStore.selectedBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchRecurringBookingsList: dispatch.bookingsStore.doFetchRecurringBookingsList,

  setSelectedBookings: dispatch.bookingsStore.setSelectedBookings,
  doBulkCancelBookingsRecurringModal: dispatch.bookingsStore.doBulkCancelBookingsRecurringModal,
  doBulkRemoveTeamMemberFromRecurringListing: dispatch.bookingsStore.doBulkRemoveTeamMemberFromRecurringListing,
});

export default connect(mapState, mapDispatch)(RecurringBookingListModal);
