import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CreditCard = forwardRef(function CreditCard(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.945 3.25h8.11c1.367 0 2.47 0 3.337.117.9.12 1.658.38 2.26.981.602.602.86 1.36.982 2.26.048.36.076.762.093 1.206a.754.754 0 0 1 .01.327c.013.541.013 1.142.013 1.804V14.055c0 1.367 0 2.47-.116 3.337-.122.9-.38 1.658-.982 2.26-.602.602-1.36.86-2.26.982-.867.116-1.97.116-3.337.116h-8.11c-1.367 0-2.47 0-3.337-.116-.9-.122-1.658-.38-2.26-.982-.602-.602-.86-1.36-.981-2.26-.117-.867-.117-1.97-.117-3.337V9.945c0-.662 0-1.263.013-1.804a.754.754 0 0 1 .01-.327c.017-.444.045-.845.094-1.206.12-.9.38-1.658.981-2.26.602-.602 1.36-.86 2.26-.981.867-.117 1.97-.117 3.337-.117Zm13.202 3.558c.019.139.034.286.046.442H2.807c.012-.156.028-.303.046-.442.099-.734.28-1.122.556-1.399.277-.277.665-.457 1.4-.556C5.563 4.752 6.564 4.75 8 4.75h8c1.436 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4ZM2.75 10c0-.459 0-.874.004-1.25h18.492c.004.376.004.791.004 1.25v4c0 1.435-.002 2.437-.103 3.192-.099.734-.28 1.122-.556 1.4-.277.276-.665.456-1.4.555-.754.102-1.755.103-3.19.103H8c-1.436 0-2.437-.002-3.193-.103-.734-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.754-.103-1.755-.103-3.19v-4ZM6 11.25a.75.75 0 1 0 0 1.5h4a.75.75 0 0 0 0-1.5H6Z"
        fill="currentColor"
      />
    </Icon>
  );
});
