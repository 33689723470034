import React, { useMemo } from 'react';
import { Heading, Inline, InlineProps, PercentFormatter, Stack, Text } from '@goodhuman-me/components';

import { BudgetStatusLabel, ProgressBar } from '../../../../../components';

import { Budget, BudgetCategory, BudgetStatus, statusToColors } from '../../../../../budget';
import { ProgressBarProps } from 'views/budgets/components/progress-bar/progress-bar';
import { getProgress } from '../../service-budgeting-overview/budgeting-period/budgeting-period';

/*******************************************************************
 ** CategoryTypeIcons
 ******************************************************************/

type CategoryTypeIconProps = Pick<BudgetCategory['meta'], 'color' | 'icon'>;

function CategoryTypeIcon(props: CategoryTypeIconProps) {
  let { color, icon: IconElement } = props;

  return (
    <Inline
      width="$size600"
      height="$size600"
      backgroundColor={color}
      borderRadius="$medium"
      padding="$xsmall"
      alignItems="center"
      justifyContent="center"
    >
      {/** TODO (Mitch): Fix types */}
      <IconElement />
    </Inline>
  );
}

/*******************************************************************
 ** BudgetElapsedProgressBar
 ******************************************************************/

type BudgetElapsedProgressBarProps = {
  status: BudgetStatus;
  valueLabelProps?: InlineProps;
  projectedValue?: number;
} & ProgressBarProps;

function BudgetElapsedProgressBar(props: BudgetElapsedProgressBarProps): JSX.Element {
  const { label, status, value, projectedValue, valueLabelProps = {}, ...otherProps } = props;

  return (
    <ProgressBar
      width="100%"
      {...otherProps}
      {...statusToColors(status)}
      label={label}
      value={value}
      projectedValue={projectedValue}
      valueLabel={
        <Inline gap="$space50" {...valueLabelProps}>
          <Text color="$default" size="small">
            Spent:
          </Text>
          <Text fontWeight="$bold" color="$default" size="small">
            <PercentFormatter value={value} minimumFractionDigits={0} maximumFractionDigits={0} />
          </Text>
        </Inline>
      }
    />
  );
}

/*******************************************************************
 ** Header
 ******************************************************************/

export type HeaderProps = Pick<BudgetCategory, 'actuals' | 'meta' | 'name' | 'status' | 'type'> &
  Pick<Budget['overview']['service'], 'startDate' | 'endDate'> & {
    displayInHours?: boolean;
  };

export function Header(props: HeaderProps): JSX.Element {
  const { actuals, endDate, meta, name, startDate, status, type } = props;
  const {
    spent: { percent: spentPercent },
  } = actuals;

  const elapsedTimePercent = useMemo(() => getProgress({ startDate, endDate })?.value ?? 0, [startDate, endDate]);

  return (
    <Stack gap="$space200">
      <Stack gap="$space400">
        <Inline gap="$space200" alignItems="center">
          <CategoryTypeIcon {...meta} />

          <Stack gap="$space20">
            <Heading fontWeight="$normal" size="medium">
              {name}
            </Heading>

            <Text size="xsmall" color="$muted">
              {type}
            </Text>
          </Stack>
        </Inline>

        <Stack>
          <BudgetStatusLabel status={status} marginBottom="-22px" />
          <BudgetElapsedProgressBar
            aria-label={`Status ${status}`}
            showValueLabel
            status={status}
            value={spentPercent}
            projectedValue={elapsedTimePercent}
            valueLabelProps={{
              marginBottom: '$space100',
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
