import { Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import moment from 'moment';
import React, { PureComponent } from 'react';
import CommonUtils from 'utilities/common-utils';
import { RecurringBookingPattern } from 'utilities/enum-utils';

interface IViewSubscriptionQuotationItemViewProps {
  subscriptionPrice: number;
  recurringPattern: number;
  startDateTime: Date;
  endDateTime: Date;
  multiplier: number;
}

class ViewSubscriptionQuotationItemView extends PureComponent<IViewSubscriptionQuotationItemViewProps, null> {
  private _generateItemTitle = () => {
    const { recurringPattern, subscriptionPrice } = this.props;
    let recurringUnit;
    switch (recurringPattern) {
      case RecurringBookingPattern.EveryDay:
        recurringUnit = 'per day';
        break;
      case RecurringBookingPattern.EveryWeek:
        recurringUnit = 'per week';
        break;
      case RecurringBookingPattern.EveryFortnight:
        recurringUnit = 'per 2 weeks';
        break;
      case RecurringBookingPattern.EveryFourWeeks:
        recurringUnit = 'per 4 weeks';
        break;
      case RecurringBookingPattern.Monthly:
        recurringUnit = 'per month';
        break;
    }

    const itemTitle = `${CommonUtils.formatPrice(subscriptionPrice)} ${recurringUnit}`;
    return itemTitle;
  };

  render() {
    const { startDateTime, endDateTime, multiplier, subscriptionPrice } = this.props;
    const title = this._generateItemTitle();
    return (
      <Row className="pv-small bordered-bottom bordered-left bordered-right" type="flex" align="middle">
        <Col span={1} />
        <Col span={8} className="pl-medium">
          <Row>
            <Text weight="bold">{title}</Text>
          </Row>
          <Row>
            <Text>{`${moment(startDateTime).format('Do of MMMM yyyy')} - ${moment(endDateTime).format(
              'Do of MMMM yyyy',
            )}`}</Text>
          </Row>
        </Col>
        <Col span={8} className="text-align-right">
          <Row>
            <Text weight="bold">{CommonUtils.formatPrice(subscriptionPrice * multiplier)}</Text>
          </Row>
          <Row>
            <Text color="secondary" size="small">{`${multiplier} x ${CommonUtils.formatPrice(
              subscriptionPrice,
            )}`}</Text>
          </Row>
        </Col>
        <Col span={3} />
      </Row>
    );
  }
}

export default ViewSubscriptionQuotationItemView;
