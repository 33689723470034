/* eslint-disable eslint-comments/disable-enable-pair -- TEAM_MANAMGEMENT_REFACTOR -- will be refactored by another card*/

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddNewMemberModal from '../add-new-member/AddNewMemberModal';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';
import TeamManagementModals from '../components/TeamManagementModals/TeamManagementModals';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TmMgmtTabs } from './tm-mgmt-tabs';
import { TmMgmtHeader } from './tm-mgmt-header';
import { TmMgmtTeamTab } from './team-management-team-tab/tm-mgmt-team-tab';
import { TEAM_MANANGEMENT_TABS } from 'views/team-v2/utils/constants';
import { TmMgmtInvitesTab } from './team-management-invites-tab/tm-mgmt-invites-tab';
import { TmMgmtUninvitedTab } from './team-management-uninvited-tab/tm-mgmt-uninvited-tab';

export const TeamManagementView = () => {
  const history = useHistory();
  const { ny1616 } = useFlags();

  const {
    teamStore: { doFetchAccountTeamList, doFetchInvitations, doFetchUninvited },
  } = useDispatch<IRootDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const [pageTimestamp, setPageTimestamp] = useState(new Date());
  const [selectedTab, setSelectedTab] = useState<TEAM_MANANGEMENT_TABS>(TEAM_MANANGEMENT_TABS.TEAM);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState('');
  const [modalData, setModalData] = useState(Object);

  const refreshData = async () => {
    switch (selectedTab) {
      case TEAM_MANANGEMENT_TABS.INVITES:
        await doFetchInvitations({});
        break;
      case TEAM_MANANGEMENT_TABS.UNINVITED:
        await doFetchUninvited({
          page: page,
          pageSize: pageSize,
          pageTimestamp: pageTimestamp,
        });
        break;
      default:
        await doFetchAccountTeamList({
          page: page,
          pageSize: pageSize,
          pageTimestamp: pageTimestamp,
        });
    }
  };

  const onAddNewMemberCloseModal = async () => {
    setPage(1);
    setPageTimestamp(new Date());
    setIsLoading(true);
    await refreshData();
    setIsModalOpen(false);
    setIsLoading(false);
  };

  const onTeamManagementModalsCloseModal = async () => {
    setIsOpen(false);
    setSelectedModal('');
    setModalData(null);
    await refreshData();
    setIsLoading(false);
  };

  const onAddTeamMemberButtonClicked = () => {
    ny1616 ? history.push('/team/invite-member') : setIsModalOpen(true);
  };

  return (
    <>
      <AddNewMemberModal onClose={onAddNewMemberCloseModal} isOpen={isModalOpen} />
      <TeamManagementModals
        isOpen={isOpen}
        onClose={onTeamManagementModalsCloseModal}
        selectedModal={selectedModal}
        data={modalData}
      />
      <TmMgmtHeader onAddTeamMemberButtonClicked={onAddTeamMemberButtonClicked} />
      <div className='mb-x-large' style={{ position: 'sticky', top: '0px', zIndex: 10 }}>
        <div className='bg-white'>
          <TmMgmtTabs setSelectedTab={setSelectedTab} setIsLoading={setIsLoading} />
          {selectedTab === TEAM_MANANGEMENT_TABS.TEAM && (
            <TmMgmtTeamTab
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setPage={setPage}
              page={page}
              pageSize={pageSize}
              pageTimestamp={pageTimestamp}
              setModalData={setModalData}
              setIsOpen={setIsOpen}
              setSelectedModal={setSelectedModal}
            />
          )}
          {selectedTab === TEAM_MANANGEMENT_TABS.INVITES && (
            <TmMgmtInvitesTab
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setIsOpen={setIsOpen}
              setModalData={setModalData}
              setSelectedModal={setSelectedModal}
            />
          )}
          {selectedTab === TEAM_MANANGEMENT_TABS.UNINVITED && (
            <TmMgmtUninvitedTab
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setModalData={setModalData}
              setIsOpen={setIsOpen}
              setSelectedModal={setSelectedModal}
            />
          )}
        </div>
      </div>
    </>
  );
};
