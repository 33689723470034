import { Divider, Empty, notification } from 'antd';
import { Warning } from 'common-components/alerts';
import { PrimaryButton } from 'common-components/buttons';
import { FilterSection } from 'common-components/filter';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { Text } from 'common-components/typography';
import { InfiniteScroll } from 'components';
import { IFilter } from 'interfaces/filter-interfaces';
import { IGroupServiceNote } from 'interfaces/service-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { FilterType, NoteVisibleType, WorkflowTriggerModuleType } from 'utilities/enum-utils';
import EditValueFormModal from 'views/form-builder/components/EditValueFormModal';
import { IElementValue } from 'views/form-builder/shared/form-interface';
import AddEditGroupNoteModal from 'views/group-services/components/booking-notes/AddEditGroupNoteModal';
import BookingNoteItem from 'views/group-services/components/booking-notes/BookingNoteItem';
import { DeleteGroupNoteModal } from 'views/group-services/components/booking-notes/DeleteGroupNoteModal';
import AddIncidentNoteModal from 'views/workflows/trigger/AddIncidentNoteModal';

//region Default parameters
const availableFilters = [FilterType.DATE_RANGE, FilterType.CUSTOMER, FilterType.IS_INCIDENT];

const defaultFilters: IFilter[] = [
  {
    filter: FilterType.CUSTOMER,
    values: [],
    selectionLabel: 'All',
  },
  {
    filter: FilterType.IS_INCIDENT,
    values: [],
    selectionLabel: 'All',
  },
];
//endregion

interface ICustomerNotesPanelProps {
  selectedSession: typeof state.groupServiceStore.selectedSession;
  groupServiceSessionNotes: typeof state.groupServiceStore.groupServiceSessionNotes;
  workflowForm: typeof state.workflowStore.workflowForm;
  doFetchGroupServiceSessionNotes: typeof dispatch.groupServiceStore.doFetchGroupServiceSessionNotes;
  doRemoveGroupServiceSessionNote: typeof dispatch.groupServiceStore.doRemoveGroupServiceSessionNote;
  doCheckViewWorkflowDetails: typeof dispatch.workflowStore.doCheckViewWorkflowDetails;
  doGetWorkflowFormDetail: typeof dispatch.workflowStore.doGetWorkflowFormDetail;
  doUpdateWorkflowForm: typeof dispatch.workflowStore.doUpdateWorkflowForm;
}

interface ICustomerNotesPanelState {
  filters: IFilter[];
  isDeleteNoteOpen: boolean;
  isEditNoteOpen: boolean;
  isLoading: boolean;
  noteMode: 'add' | 'edit' | string;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  targetNote?: IGroupServiceNote;
  sessionData: object;
  isAddIncidentNoteModalOpen: boolean;
  isOpenFormModal: boolean;
}

class SessionCustomerNotesPanel extends Component<ICustomerNotesPanelProps, ICustomerNotesPanelState> {
  state = {
    filters: [...defaultFilters], // TODO replace with actual default filters
    isDeleteNoteOpen: false,
    isEditNoteOpen: false,
    isLoading: false,
    noteMode: 'add',
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    targetNote: null,
    sessionData: {},
    isAddIncidentNoteModalOpen: false,
    isOpenFormModal: false,
  };

  //region Event handlers
  private _onChangeFilter = async (filters: Array<any>) => {
    this.setState({ filters });

    const newPage = 1;
    this.setState({ page: 1 });
    const payload = this._buildPayload(filters, newPage);
    await this._fetchContent(payload);
  };

  private _formatFilterQuery = (filters) => {
    const requestFilter: any = {};
    _.forEach(filters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'customerUserIds':
            requestFilter.customerUserIds = _.map(filter.values, (v) => v.value);
            break;
          case 'isIncident':
            requestFilter.isIncident = _.map(filter.values, (value) => {
              return value === 'YES';
            });
            break;
          default:
            break;
        }
      }
    });

    return requestFilter;
  };

  // Add note action handler
  onAddNote = () => {
    this.onOpenEditNoteModal({ noteMode: 'add', targetNote: null });
  };

  // Edit note action handler
  onEditNote = ({ targetNote }) => {
    this.onOpenEditNoteModal({ noteMode: 'edit', targetNote });
  };

  // Delete note action handler
  onDeleteNote = ({ targetNote }) => {
    this.onOpenDeleteNoteModal({ targetNote });
  };

  //region Action handlers

  // The following actions trigger whenever the action has been confirmed from the modal.

  onSaveNoteAction = () => {
    this.setState({ isEditNoteOpen: false });
    this._loadFreshNotes();
  };

  onDeleteNoteAction = ({ targetNote }) => {
    const { selectedSession } = this.props;
    const payload = {
      serviceId: selectedSession.serviceId,
      serviceDateTimeId: selectedSession.serviceDateTimeId,
      noteId: targetNote.noteId,
    };
    this.props.doRemoveGroupServiceSessionNote(payload);
  };
  //endregion

  //endregion

  //region Button handlers
  onOpenEditNoteModal = ({ targetNote, noteMode }) => this.setState({ isEditNoteOpen: true, targetNote, noteMode });
  onCloseEditNoteModal = () => this.setState({ isEditNoteOpen: false, targetNote: null });

  onOpenDeleteNoteModal = ({ targetNote }) => this.setState({ isDeleteNoteOpen: true, targetNote });
  onCloseDeleteNoteModal = () => this.setState({ isDeleteNoteOpen: false });
  //endregion

  openAddIncidentNoteModal = () =>
    this.setState({ isAddIncidentNoteModalOpen: true, isEditNoteOpen: false, targetNote: null });
  closeAddIncidentNoteModal = () => this.setState({ isAddIncidentNoteModalOpen: false });

  private _buildPayload = (filters, page) => {
    const { selectedSession } = this.props;
    const { pageSize } = this.state;
    const pageTimestamp = new Date();
    this.setState({ pageTimestamp });
    const requestFilter = filters ? this._formatFilterQuery(filters) : null;

    const payload = {
      serviceId: selectedSession.serviceId,
      serviceDateTimeId: selectedSession.serviceDateTimeId,
      page,
      pageSize,
      pageTimestamp,
    };

    return requestFilter ? { ...payload, ...requestFilter } : payload;
  };

  private _loadFreshNotes = async () => {
    const newPage = 1;
    this.setState({ page: newPage });
    const { filters } = this.state;
    const payload = this._buildPayload(filters, newPage);
    await this._fetchContent(payload);
  };

  private _loadMoreNotes = async () => {
    const { doFetchGroupServiceSessionNotes } = this.props;

    const newPage = this.state.page + 1;
    const payload = this._buildPayload(this.state.filters, newPage);
    this.setState({ page: newPage });
    await doFetchGroupServiceSessionNotes(payload);
  };

  private _fetchContent = async (payload) => {
    const { selectedSession, doFetchGroupServiceSessionNotes } = this.props;
    if (!selectedSession) return;

    this.setState({ isLoading: true });
    await doFetchGroupServiceSessionNotes(payload);
    this.setState({ isLoading: false });
  };

  private _showFormModal = async (note) => {
    try {
      const { doGetWorkflowFormDetail } = this.props;
      await doGetWorkflowFormDetail({ workflowFormId: note.workflowFormId, isFromNote: true });
      this.setState({ isOpenFormModal: true });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  private _closeFormModal = () => {
    this.setState({ isOpenFormModal: false });
  };

  private _onSaveFormModal = async (id: string, elementValues: IElementValue[]) => {
    try {
      const { doUpdateWorkflowForm } = this.props;
      await doUpdateWorkflowForm({ workflowFormId: id, isFromNote: true, formData: elementValues });
      this.setState({ isOpenFormModal: false });
      notification.success({
        message: 'Update workflow form success',
        description: 'You have successfully update workflow form',
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  componentDidMount = async () => {
    await this._loadFreshNotes();
  };

  render() {
    const { isLoading, isOpenFormModal } = this.state;
    const { groupServiceSessionNotes, selectedSession, workflowForm } = this.props;
    const warnings = selectedSession ? selectedSession.numberOfIncidentNotes : 0;
    const defaultNotePrivacyVisible = selectedSession
      ? selectedSession.defaultNotePrivacyVisible
      : NoteVisibleType.PORTAL;

    const NotesEmptyState = () => (
      <div className='align-center flex-column  flex-1 bg-white'>
        <div className=''>
          <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
        <Text size='x2-large' color='secondary' weight='bold'>
          No customer notes found.
        </Text>
      </div>
    );

    return (
      <>
        {/* Main content section */}
        <div className='pv-medium pl-small'>
          {/* Warnings, if any*/}
          {warnings > 0 && (
            <div className='mb-large'>
              <Warning
                displayType='block'
                content={
                  <Text>
                    There {warnings === 1 ? 'is' : 'are'} <b>{warnings}</b> incident{warnings === 1 ? '' : 's'} reported
                    during this session.
                  </Text>
                }
              />
            </div>
          )}

          {/* Content container  */}
          <div className='ph-large pt-large pb-x2-large rounded-big shadow-container flex-1 bg-white'>
            {/* Header section */}
            <section className='line-height-120 align-center flex-row justify-between'>
              {/* Title */}
              <div>
                <div className='mb-x-small'>
                  <Text lineHeight={120} weight='bold'>
                    Notes
                  </Text>
                </div>

                <Text lineHeight={120}>Customer notes for this session</Text>
              </div>

              {/* Action buttons */}
              <div>
                <PrimaryButton icon='plus' size='large' onClick={this.onAddNote}>
                  Add note
                </PrimaryButton>
              </div>
            </section>

            <Divider className='mt-large mb-none' />

            {/* Filters*/}
            <section className=''>
              <FilterSection
                availableFilters={availableFilters}
                filters={this.state.filters}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={''}
              />
            </section>

            {isLoading ? (
              <SpinningLoader size={150} message={'Loading...'} />
            ) : _.isEmpty(groupServiceSessionNotes) ? (
              <NotesEmptyState />
            ) : (
              <section className='bg-quaternary bordered border-standard-gray ph-medium pt-medium'>
                <InfiniteScroll
                  hasMore={groupServiceSessionNotes.length >= this.state.page * this.state.pageSize}
                  loadMore={this._loadMoreNotes}
                >
                  {_.map(groupServiceSessionNotes, (note) => (
                    <BookingNoteItem
                      onEditNote={() => this.onEditNote({ targetNote: note })}
                      onDeleteNote={() => this.onDeleteNote({ targetNote: note })}
                      note={note}
                      timezone={selectedSession.timezone}
                      doCheckViewWorkflowDetails={this.props.doCheckViewWorkflowDetails}
                      showFormModal={this._showFormModal}
                    />
                  ))}
                </InfiniteScroll>
              </section>
            )}
          </div>
        </div>

        {/* Action modals */}

        {/* Add / edit note modal */}
        <AddEditGroupNoteModal
          isOpen={this.state.isEditNoteOpen}
          onClose={this.onCloseEditNoteModal}
          onSaveNoteAction={this.onSaveNoteAction}
          noteMode={this.state.noteMode}
          targetNote={this.state.targetNote}
          isAllowSelecteCustomer={true}
          selectedSession={this.props.selectedSession}
          handleSubmitIncidentNote={(noteData) => {
            this.setState({ ...this.state, sessionData: noteData });
            this.openAddIncidentNoteModal();
          }}
          defaultVisibleType={defaultNotePrivacyVisible}
        />

        <AddIncidentNoteModal
          isOpen={this.state.isAddIncidentNoteModalOpen}
          onClose={this.closeAddIncidentNoteModal}
          screenData={this.state.sessionData}
          moduleType={WorkflowTriggerModuleType.SESSION_NOTE}
          isManual={false}
          onSaveNoteAction={this._loadFreshNotes}
          timezone={moment.tz.guess()}
        />

        {/* Delete note modal  */}
        <DeleteGroupNoteModal
          isOpen={this.state.isDeleteNoteOpen}
          targetNote={this.state.targetNote}
          onClose={this.onCloseDeleteNoteModal}
          onDeleteNoteAction={this.onDeleteNoteAction}
        />

        {isOpenFormModal && (
          <EditValueFormModal
            isOpen={isOpenFormModal}
            mode='VIEW'
            formId={workflowForm.workflowFormId}
            formElements={workflowForm.formContent}
            elementValues={workflowForm.formData}
            canEditForm={workflowForm.canEditForm}
            onSave={this._onSaveFormModal}
            onClose={this._closeFormModal}
          />
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceSessionNotes: state.groupServiceStore.groupServiceSessionNotes,
  workflowForm: state.workflowStore.workflowForm,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceSessionNotes: dispatch.groupServiceStore.doFetchGroupServiceSessionNotes,
  doRemoveGroupServiceSessionNote: dispatch.groupServiceStore.doRemoveGroupServiceSessionNote,
  doCheckViewWorkflowDetails: dispatch.workflowStore.doCheckViewWorkflowDetails,
  doGetWorkflowFormDetail: dispatch.workflowStore.doGetWorkflowFormDetail,
  doUpdateWorkflowForm: dispatch.workflowStore.doUpdateWorkflowForm,
});

export default connect(mapState, mapDispatch)(SessionCustomerNotesPanel);
