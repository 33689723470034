import clsx from 'clsx';
import React from 'react';

type Props = {
  width?: string | number;
  height?: string | number;
  className?: string;
};

export const Skeleton = ({ className, ...props }: Props): JSX.Element => {
  return (
    <div
      className={clsx(
        'from-bg-neutral-weak to-bg-info-weak block animate-pulse rounded bg-neutral-weak bg-gradient-to-r',
        className,
      )}
      {...props}
    ></div>
  );
};

Skeleton.displayName = 'Skeleton';
