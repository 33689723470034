/* eslint @typescript-eslint/ban-types: 0 -- empty interface extending multiple types */
import {
  Calendar as _SingleCalendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody as _CalendarGridBody,
  CalendarGridHeader as _CalendarGridHeader,
  CalendarHeaderCell,
  Heading,
  RangeCalendar as _RangeCalendar,
  type CalendarGridBodyProps as _CalendarGridBodyProps,
  type CalendarProps as _SingleCalendarProps,
  type DateValue,
  type RangeCalendarProps as _RangeCalendarProps,
} from 'react-aria-components';
import { ChevronLeft, ChevronRight } from '@good/icons';
import { getLocalTimeZone, isSameDay, today } from '@internationalized/date';

import * as styles from './calendar.css';
import { Button, type ButtonProps } from '../button';
import { twMerge } from '../utils';
import type { CalendarVariants } from './calendar.css';
import { forwardRef } from 'react';

const Header = (props: Pick<ButtonProps, 'isDisabled'>) => {
  const { isDisabled } = props;
  const { header, heading } = styles.calendar();

  return (
    <header className={header()}>
      <Button slot="previous" size="xs" emphasis="quiet" tone="neutral" isDisabled={isDisabled}>
        <ChevronLeft />
      </Button>

      <Heading className={heading()} />

      <Button slot="next" size="xs" emphasis="quiet" tone="neutral" isDisabled={isDisabled}>
        <ChevronRight />
      </Button>
    </header>
  );
};

const CalendarGridHeader = () => {
  const { gridHeaderCell } = styles.calendar();

  return (
    <_CalendarGridHeader>
      {(day) => (
        <CalendarHeaderCell>
          <span className={gridHeaderCell()}>{day}</span>
        </CalendarHeaderCell>
      )}
    </_CalendarGridHeader>
  );
};

type CalendarGridBodyProps = Omit<_CalendarGridBodyProps, 'children'> & CalendarVariants;

const CalendarGridBody = (props: CalendarGridBodyProps) => {
  const { selectionMode, ...otherProps } = props;
  const { cellText, gridBodyCell, todayMarker } = styles.calendar({ selectionMode });

  return (
    <_CalendarGridBody {...otherProps}>
      {(date) => {
        const isToday = isSameDay(date, today(getLocalTimeZone()));

        return (
          <CalendarCell date={date} className={twMerge(gridBodyCell())} {...(isToday && { 'data-today': true })}>
            {({ date }) => (
              <span className={cellText()}>
                {date.day}
                {isToday && <span className={todayMarker()} />}
              </span>
            )}
          </CalendarCell>
        );
      }}
    </_CalendarGridBody>
  );
};

type _CalendarProps = {
  /**
   * The amount of months that will be displayed at once.
   * @default 1
   */
  visibleMonths?: number;
} & Pick<_SingleCalendarProps<DateValue>, 'isDisabled'> &
  Pick<CalendarVariants, 'selectionMode'>;

const _Calendar = (props: _CalendarProps) => {
  const { visibleMonths = 1, isDisabled, selectionMode } = props;
  const { grid, gridContainer } = styles.calendar();
  const isMultiMonth = visibleMonths > 1;

  return (
    <>
      <Header isDisabled={isDisabled} />
      <div className={gridContainer()}>
        {[...Array(visibleMonths).keys()].map((i) => (
          <CalendarGrid
            className={twMerge(grid())}
            key={i}
            offset={{ months: i }}
            {...(isMultiMonth && { 'data-multi-month': true })}
          >
            <CalendarGridHeader />
            <CalendarGridBody selectionMode={selectionMode} />
          </CalendarGrid>
        ))}
      </div>
    </>
  );
};

export type CalendarProps<T extends DateValue> = _SingleCalendarProps<T> & Pick<_CalendarProps, 'visibleMonths'>;

export const Calendar = forwardRef<HTMLDivElement, CalendarProps<DateValue>>((props, ref) => {
  const { className, visibleMonths = 1 } = props;
  const { container } = styles.calendar();

  return (
    <_SingleCalendar
      {...props}
      className={twMerge(container(), className as string)}
      ref={ref}
      visibleDuration={{ months: visibleMonths }}
    >
      <_Calendar {...props} visibleMonths={visibleMonths} />
    </_SingleCalendar>
  );
});

Calendar.displayName = 'Calendar';

export type RangeCalendarProps<T extends DateValue> = _RangeCalendarProps<T> & Pick<_CalendarProps, 'visibleMonths'>;

export const RangeCalendar = forwardRef<HTMLDivElement, RangeCalendarProps<DateValue>>((props, ref) => {
  const { className, visibleMonths = 1 } = props;
  const { container } = styles.calendar();

  return (
    <_RangeCalendar
      {...props}
      className={twMerge(container(), className as string)}
      ref={ref}
      visibleDuration={{ months: visibleMonths }}
    >
      <_Calendar selectionMode="range" visibleMonths={visibleMonths} />
    </_RangeCalendar>
  );
});

RangeCalendar.displayName = 'RangeCalendar';
