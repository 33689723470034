import React from 'react';
import { Tag, Icon } from 'antd';
import moment from 'moment-timezone';

import { Text } from 'common-components/typography';
import {
  IGroupServiceCustomRatio,
  IGroupServiceCustomerRatio,
  GroupServiceDurationRatio,
} from 'interfaces/service-interfaces';

type IRatioPopoverProps = {
  customerRatio: IGroupServiceCustomerRatio;
  timezone: string;
};

const RatioPopoverContent = ({ customerRatio, timezone }: IRatioPopoverProps) => {
  return (
    <div className="ph-large pv-medium" style={{ width: '226px', maxHeight: '362px', overflowX: 'hidden' }}>
      {customerRatio?.teamMemberCustomerRatio && (
        <div className="mb-medium">
          <div className="mb-small">
            <Text className={'text-weight-bold'}>Default ratio</Text>
          </div>
          <div>
            <div className="whitespace-nowrap">
              <Text>{customerRatio?.teamMemberCustomerRatio}</Text>
            </div>
          </div>
        </div>
      )}
      <div className="mb-small">
        <Text className={'text-weight-bold'}>Custom ratio</Text>
      </div>
      <div className="flex-column space-between" style={{ rowGap: '6px' }}>
        {customerRatio.customRatio.map((customRatio: IGroupServiceCustomRatio, index) => {
          const { startDateTime, endDateTime, teamMemberCustomerRatio } = customRatio;
          const startDateTimeMoment = moment.tz(startDateTime, timezone);
          const endDateTimeMoment = moment.tz(endDateTime, timezone);

          return (
            <div key={`customRatio-${index}`}>
              <div className="whitespace-nowrap">
                <Tag className="tag-icon bg-tertiary bordered-none flex-row align-center">
                  <Icon type="calendar" className="text-size-regular" />
                  <Text>
                    {startDateTimeMoment.format('hh:mm a')} - {endDateTimeMoment.format('hh:mm a')}
                  </Text>
                </Tag>
              </div>
              <div className="whitespace-nowrap">
                <Text>{teamMemberCustomerRatio}</Text>
              </div>
            </div>
          );
        })}
        {customerRatio.durationRatios.map((durationRatio: GroupServiceDurationRatio, index) => {
          const { duration, teamMemberCustomerRatio } = durationRatio;

          return (
            <div key={`duration-${index}`}>
              <div className="whitespace-nowrap">
                <Tag className="tag-icon bg-tertiary bordered-none flex-row align-center">
                  <Icon type="clock-circle" className="text-size-regular" />
                  <Text>{duration} Minutes</Text>
                </Tag>
              </div>
              <div className="whitespace-nowrap">
                <Text>{teamMemberCustomerRatio}</Text>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RatioPopoverContent;
