import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const reset: CSS = {
  boxSizing: 'border-box',
  outline: 'none',
};

export const alert: CSS = {
  position: 'relative',
  display: 'flex',
  borderRadius: '$small',
  fontFamily: '$primary',
  fontSize: '$font75',
  fontWeight: '$normal',
};

export const variants = css({
  color: '$$alertTextColor',

  backgroundColor: '$$alertBackgroundColor',
  borderStyle: '$$alertBorderStyle',
  borderColor: '$$alertBorderColor',
  borderWidth: '$$alertBorderWidth',
  borderLeftWidth: '$$alertBorderLeftWidth',

  paddingLeft: '$$alertPaddingX',
  paddingRight: '$$alertPaddingX',
  paddingTop: '$$alertPaddingY',
  paddingBottom: '$$alertPaddingY',

  variants: {
    /** What kind of alert? */
    kind: {
      inline: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '$space250',
      },
      hero: {
        justifyContent: 'center',
        alignItems: 'center',
        $$alertBorderLeftWidth: '$borderWidths-none',

        borderLeftWidth: '$none',
      },
    },
    /** Which type of alert ? */
    emphasis: {
      filled: {
        $$alertPaddingX: '$space-space250',
        $$alertPaddingY: '$space-space200',
        $$alertBorderStyle: '$borderStyles-solid',
        $$alertBorderLeftWidth: '$borderWidths-border400',
        $$alertBorderWidth: '$borderWidths-border0',
        $$alertTextColor: '$colors-bodyDark2 !important',

        paddingLeft: '$space200',
      },
      outlined: {
        $$alertPaddingX: '$space-space250',
        $$alertPaddingY: '$space-space250',
        $$alertBorderStyle: '$borderStyles-solid',
        $$alertBorderWidth: '$borderWidths-border100',
        $$alertBorderColor: '$colors-bodyLight',
        $$alertTextColor: '$colors-bodyDark2 !important',
        $$alertBackgroundColor: '$colors-white',

        borderColor: '$bodyLight',
        borderLeftWidth: '$border100',
        backgroundColor: '$white',
      },
      ghost: {
        $$alertPaddingX: '$space-space0',
        $$alertPaddingY: '$space-space0',

        backgroundColor: '$none',
      },
    },
    /** What tone of alert ? */
    tone: {
      hero: {
        $$alertBackgroundColor: '$colors-lavenderLight2',
        $$alertBorderColor: '$colors-lavender',
      },
      success: {
        $$alertBackgroundColor: '$colors-greenLight2',
        $$alertBorderColor: '$colors-green',
        $$alertTextColor: '$colors-positive',
      },
      warning: {
        $$alertBackgroundColor: '$colors-orangeLight2',
        $$alertBorderColor: '$colors-orange',
        $$alertTextColor: '$colors-notice',
      },
      danger: {
        $$alertBackgroundColor: '$colors-redLight2',
        $$alertBorderColor: '$colors-red',
        $$alertTextColor: '$colors-critical',
      },
    },
    /** Do alert align horizontal center */
    middle: {
      true: {
        textAlign: 'center',
      },
    },
    /** Whether alert's title is bold */
    boldTitle: {
      true: {},
    },
    missingActionFilledAlert: {
      true: {
        $$alertPaddingX: '$space-space250',
        $$alertPaddingY: '$space-space200',
      },
    },
  },
  compoundVariants: [
    {
      tone: 'hero',
      emphasis: 'ghost',
      css: {
        color: '$bodyDark1',
      },
    },
    {
      tone: 'success',
      emphasis: 'ghost',
      css: {
        color: '$positive',
      },
    },
    {
      tone: 'warning',
      emphasis: 'ghost',
      css: {
        color: '$notice',
      },
    },
    {
      tone: 'danger',
      emphasis: 'ghost',
      css: {
        color: '$critical',
      },
    },
  ],
  defaultVariants: {
    kind: 'inline',
    emphasis: 'filled',
    tone: 'hero',
  },
});

export type AlertVariants = Stitches.VariantProps<typeof variants>;

export const contentVariants = css({
  order: 0,
  minWidth: 0,
  flexGrow: 1,
  variants: {
    kind: {
      inline: {},
      hero: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '$space85',
      },
    },
  },
});

export const actions: CSS = {
  display: 'flex',
  gap: '$space200',
};

export const actionsVariants = css({
  variants: {
    kind: {
      inline: {
        flexWrap: 'nowrap',
        marginTop: '$space150',
      },
      hero: {
        marginLeft: '$space65',
      },
    },
    emphasis: {
      ghost: {
        marginTop: '$space100',
      },
      filled: {},
      outlined: {},
    },
    middle: {
      true: {
        justifyContent: 'center',
      },
    },
  },
});
