import React, { Component } from 'react';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import * as H from 'history';
import { Col, Empty, notification, Row } from 'antd';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment-timezone';
import { Menu, MenuItem, Popover } from '@blueprintjs/core';
import _ from 'lodash';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import ServiceAgreementTemplateModal from 'views/account-management/components/ServiceAgreementTemplateModal';
import SpinningLoader from 'common-components/loading/SpinningLoader';

interface IPaymentMethodUrlParams {
  paymentMethod: string;
}

interface IServiceAgreementTemplatesViewProps extends FormComponentProps, RouteComponentProps<IPaymentMethodUrlParams> {
  portalUser: typeof state.authStore.portalUser;
  serviceAgreementTemplates: typeof state.accountStore.serviceAgreementTemplates;
  doGetServiceAgreementTemplates: typeof dispatch.accountStore.doGetServiceAgreementTemplates;
  doDeleteServiceAgreementTemplates: typeof dispatch.accountStore.doDeleteServiceAgreementTemplates;
  history: H.History;
}

interface IServiceAgreementTemplatesViewState {
  isLoading: boolean;
  isTemplateModalOpen: boolean;
  isDeleteModalOpen: boolean;
  selectedTemplate: any;
  modalDisplayMode: string;
}

const TemplatesEmptyState = () => (
  <div className='flex-1 bg-white mt-x2-large align-center flex-column'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className='mv-none' />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No templates found.
    </Text>{' '}
    <Text color='secondary'>
      Click on <b>Create a template</b> above to add one.
    </Text>
  </div>
);

class ServiceAgreementTemplatesView extends Component<
  IServiceAgreementTemplatesViewProps,
  IServiceAgreementTemplatesViewState
> {
  state = {
    isLoading: true,
    isTemplateModalOpen: false,
    isDeleteModalOpen: false,
    selectedTemplate: null,
    modalDisplayMode: 'VIEW',
  };

  private _goToLandingPage = () => {
    this.props.history.push('/account/landing');
  };

  private _goToFeatureConfiguration = () => {
    this.props.history.push('/account/features');
  };

  private _goToServiceAgreementTemplates = () => {
    this.props.history.push('/account/features/service-agreement-templates');
  };

  private _openTemplateModal = (template, modalDisplayMode) => {
    this.setState({ selectedTemplate: template, isTemplateModalOpen: true, modalDisplayMode: modalDisplayMode });
  };

  private _closeTemplateModal = () => {
    this.setState({ selectedTemplate: null, isTemplateModalOpen: false, modalDisplayMode: 'VIEW' });
  };

  private _openDeleteModal = (template) => {
    this.setState({ selectedTemplate: template, isDeleteModalOpen: true });
  };

  private _closeDeleteModal = () => {
    this.setState({ selectedTemplate: null, isDeleteModalOpen: false });
  };

  private _getPopoverContent = (template) => {
    return (
      <Menu>
        <MenuItem
          label=''
          className='hover-bg-blue-lightest mv-medium'
          text='Edit'
          onClick={() => this._openTemplateModal(template, 'EDIT')}
        />
        <MenuItem
          label=''
          className='hover-bg-blue-lightest text-color-red-dark mv-medium'
          text='Delete'
          onClick={() => this._openDeleteModal(template)}
        />
      </Menu>
    );
  };

  private _deleteTemplate = async () => {
    try {
      await this.props.doDeleteServiceAgreementTemplates({
        templateId: this.state.selectedTemplate ? this.state.selectedTemplate.termsConditionsTemplateId : null,
      });
      this.setState({ selectedTemplate: null, isDeleteModalOpen: false });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
    }
  };

  componentDidMount = async () => {
    if (this.props.match.params.paymentMethod === 'ndis') {
      await this.props.doGetServiceAgreementTemplates({});
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { paymentMethod } = this.props.match.params;
    const { timezone } = this.props.portalUser;
    const { serviceAgreementTemplates } = this.props;
    const { selectedTemplate, modalDisplayMode, isLoading } = this.state;

    return (
      <>
        <ServiceAgreementTemplateModal
          isOpen={this.state.isTemplateModalOpen}
          onClose={this._closeTemplateModal}
          template={selectedTemplate}
          displayMode={modalDisplayMode}
          existingNames={_.map(serviceAgreementTemplates, (template) => {
            return template.name;
          })}
        />
        <ActionModal
          isOpen={this.state.isDeleteModalOpen}
          onClose={this._closeDeleteModal}
          title='Delete service agreement template'
          showCloseButton={false}
          canCloseOutside={false}
        >
          <Paragraph>Are you sure you want to delete this template?</Paragraph>
          <Paragraph>
            You will no longer be able to apply this template to new service agreements. All existing service agreements
            will not be affected.
          </Paragraph>
          <ActionModalFooter>
            <SecondaryButton className='mr-medium' size='large' onClick={this._closeDeleteModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size='large' onClick={this._deleteTemplate} color='red-dark'>
              Delete
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <div className='pb-x4-large'>
          <div className='mb-medium'>
            <Title level={3} weight='regular' className='mv-none'>
              <span
                className='cursor-pointer text-color-blue-action'
                role='button'
                onClick={this._goToLandingPage}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this._goToLandingPage();
                  }
                }}
                tabIndex={0}
              >
                Account <span className='text-color-secondary mr-x-small'>{'>'}</span>
              </span>
              <span
                className='cursor-pointer text-color-blue-action'
                role='button'
                onClick={this._goToFeatureConfiguration}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this._goToFeatureConfiguration();
                  }
                }}
                tabIndex={0}
              >
                Feature Configuration <span className='text-color-secondary mr-x-small'>{'>'}</span>
              </span>
              {/*<span className={'cursor-pointer text-color-blue-action'} onClick={this._goToServiceAgreementTemplates}>*/}
              {/*  Service agreement templates <span className={'text-color-secondary mr-x-small'}>></span>*/}
              {/*</span>{' '}*/}
              {/*{paymentMethod}*/}
              Terms & conditions templates
            </Title>
          </div>
          <Paragraph>
            Create and edit service agreement templates for your <b>{paymentMethod} service agreements</b>.
          </Paragraph>
          <div>
            <PrimaryButton icon='plus' onClick={() => this._openTemplateModal(null, 'CREATE')}>
              Create a template
            </PrimaryButton>
          </div>
          <div>
            <div className='mt-x-large'>
              <Row className='pb-small bordered-bottom'>
                <Col span={6} className='pl-medium'>
                  <SubTitle>Template name</SubTitle>
                </Col>
                <Col span={5}>
                  <SubTitle>Last edited on</SubTitle>
                </Col>
                <Col span={6}>
                  <SubTitle>Created by</SubTitle>
                </Col>
                <Col span={3} />
                <Col span={4} />
              </Row>
              {isLoading ? (
                <SpinningLoader size={20} message='Fetching templates...' />
              ) : serviceAgreementTemplates && serviceAgreementTemplates.length > 0 ? (
                _.map(serviceAgreementTemplates, (template, index) => {
                  return (
                    <Row key={index} className='pv-small evenodd'>
                      <Col span={6} className='pl-medium'>
                        {template.name}
                      </Col>
                      <Col span={5}>{moment.tz(template.updatedOn, timezone).format('DD/MM/YYYY')}</Col>
                      <Col span={6}>{template.createdBy}</Col>
                      <Col span={3} className='text-align-right'>
                        <HyperlinkButton onClick={() => this._openTemplateModal(template, 'VIEW')}>
                          View
                        </HyperlinkButton>
                      </Col>
                      <Col span={4} className='text-align-center'>
                        <Popover
                          content={this._getPopoverContent(template)}
                          popoverClassName='mb-medium'
                          position='bottom-right'
                          interactionKind='click'
                        >
                          <GhostButton icon='ellipsis' />
                        </Popover>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <TemplatesEmptyState />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  serviceAgreementTemplates: state.accountStore.serviceAgreementTemplates,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetServiceAgreementTemplates: dispatch.accountStore.doGetServiceAgreementTemplates,
  doDeleteServiceAgreementTemplates: dispatch.accountStore.doDeleteServiceAgreementTemplates,
});

export default connect(mapState, mapDispatch)(ServiceAgreementTemplatesView);
