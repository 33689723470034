import React, { Component } from 'react';
import { MenuDivider } from '@blueprintjs/core';
import { MenuDividerProps } from '@blueprintjs/core/src/components/menu/menuDivider';

class ActionMenuDivider extends Component<MenuDividerProps, any> {
  render() {
    const { className } = this.props;

    return <MenuDivider className={`mh-none border-standard-gray mv-small ${className}`} {...this.props} />;
  }
}

export default ActionMenuDivider;
