import React from 'react';
import { GridHeader } from 'common-components/grids';
import { FieldLabel } from 'common-components/typography';
import { Col } from 'antd';

export const TmMgmtTeamTableHeader = () => {
  return (
    <GridHeader bordered containerClassName="border-width-medium border-secondary">
      <Col span={8} className=" bg-white">
        <FieldLabel text="person" />
      </Col>
      <Col span={3} className=" bg-white">
        <FieldLabel text="status" />
      </Col>
      <Col span={3} className=" bg-white">
        <FieldLabel text="business app" />
      </Col>
      <Col span={4} className=" bg-white">
        <FieldLabel text="workspace" />
      </Col>
      <Col span={4} className=" bg-white">
        <FieldLabel text="last access" />
      </Col>
      <Col span={2} className=" bg-white">
        <FieldLabel text="" />
      </Col>
    </GridHeader>
  );
};
