import React, { Component, ReactNode } from 'react';
import ActionModal, { ActionModalFooter } from '../../../../common-components/modal/ActionModal';
import { ErrorSVG } from '../../../../assets/UndrawSVG';
import { Paragraph, Text } from '../../../../common-components/typography';
import SpinningLoader from '../../../../common-components/loading/SpinningLoader';
import { PrimaryButton, SecondaryButton } from '../../../../common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _, { uniqBy } from 'lodash';
import { parse } from 'json2csv/dist/json2csv.umd';
import PreviewInvoicesModal from 'views/billings/payments/components/PreviewInvoicesModal';
import { PaymentSources } from 'utilities/enum-utils';
import Utils from 'utilities/Utils';
import { ICreatingInvoiceNote } from 'interfaces/booking-interfaces';
import { PreviewInvoicesModalType } from '../../utils/constants';

import fileDownload from 'js-file-download';

interface IProcessPaymentActionModalProps {
  isOpen: any;
  onClose: any;
  deselect: any;
  paymentsList: typeof state.billingsStore.paymentsList;
  selectedBillingLineItem: typeof state.billingsStore.selectedBillingLineItem;
  paymentsSelectedAll: typeof state.billingsStore.paymentsSelectedAll;
  doProcessPayments: typeof dispatch.billingsStore.doProcessPayments;
  setPaymentsSelectedAll: typeof dispatch.billingsStore.setPaymentsSelectedAll;
  paymentSourceType: string;
  isNonBillable?: boolean;
}

interface IProcessPaymentActionModalState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  counts: any;
  invoiceNotes: ICreatingInvoiceNote[];
  title: string;
  isPreviewModalOpen: boolean;
}

class ProcessPaymentActionModal extends Component<IProcessPaymentActionModalProps, IProcessPaymentActionModalState> {
  state: IProcessPaymentActionModalState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    counts: {},
    invoiceNotes: [],
    title: '',
    isPreviewModalOpen: false,
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({
        isLoading: false,
        isSuccess: false,
        isError: false,
        invoiceNotes: [],
      });
      onClose();
    }
  };

  private _openPreviewModal = () => {
    this.setState({ isPreviewModalOpen: true });
  };

  private _closePreviewModal = () => {
    this.setState({ isPreviewModalOpen: false });
  };

  private _onSubmitProcess = async () => {
    const { doProcessPayments, deselect, selectedBillingLineItem, paymentSourceType, isNonBillable } = this.props;
    const { invoiceNotes } = this.state;

    const customerIds = uniqBy(selectedBillingLineItem, 'userId').map(
      (lineItem: (typeof selectedBillingLineItem)[number]) => lineItem.userId,
    );

    const notes = invoiceNotes.filter((note) => customerIds.includes(note.userId));

    this.setState({ isLoading: true });

    try {
      const resp = await doProcessPayments({
        billingLineItems: selectedBillingLineItem,
        paymentSourceType,
        notes: Utils.isEmpty(notes) ? null : notes,
        isNonBillable,
      });

      if (!_.isEmpty(resp.csvData) && paymentSourceType === PaymentSources.NDIS && !isNonBillable) {
        const csvData = parse(resp.csvData, { quote: '' });
        fileDownload(csvData, `ndia_export.csv`);
      }

      deselect();
      const newCreatingInvoiceNotes = invoiceNotes.filter((note) => !customerIds.includes(note.userId));
      this.setState({
        isLoading: false,
        counts: 0,
        isSuccess: true,
        title: 'Line items successfully processed',
        invoiceNotes: newCreatingInvoiceNotes,
      });
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false, isError: true });
    }
  };

  private _setInvoiceNotes = (invoiceNotes: ICreatingInvoiceNote[]) => {
    this.setState({ invoiceNotes });
  };

  private _renderNDIAInfo = (numberOfNDIALineItems = 0): ReactNode => {
    if (numberOfNDIALineItems <= 0) return;

    return (
      <Paragraph>
        A CSV with{' '}
        <b>
          {numberOfNDIALineItems} NDIS line item{numberOfNDIALineItems > 1 && 's'}
        </b>{' '}
        will be downloaded.
      </Paragraph>
    );
  };

  private _renderPlanInfo = (numberOfPlanManagedInvoices = 0): ReactNode => {
    if (numberOfPlanManagedInvoices <= 0) return;

    return (
      <Paragraph>
        <b>
          {numberOfPlanManagedInvoices} invoice{numberOfPlanManagedInvoices > 1 && 's'}
        </b>{' '}
        will be created and sent to the relevant <b>plan manager{numberOfPlanManagedInvoices > 1 && 's'}</b>.
      </Paragraph>
    );
  };

  private _renderSelfInfo = (numberOfSelfManagedInvoices = 0): ReactNode => {
    if (numberOfSelfManagedInvoices <= 0) return;

    return (
      <Paragraph>
        <b>
          {numberOfSelfManagedInvoices} invoice{numberOfSelfManagedInvoices > 1 && 's'}
        </b>{' '}
        will be created and sent to relevant <b>self manager{numberOfSelfManagedInvoices > 1 && 's'}</b>.
      </Paragraph>
    );
  };

  private _renderVCPInfo = (numberOfCaseManagedLineItems = 0): ReactNode => {
    if (numberOfCaseManagedLineItems <= 0) return;

    return (
      <Paragraph>
        <b>
          {numberOfCaseManagedLineItems} invoice{numberOfCaseManagedLineItems > 1 && 's'}
        </b>{' '}
        will be generated and sent to relevant <b>case manager{numberOfCaseManagedLineItems > 1 && 's'}</b>.
      </Paragraph>
    );
  };

  componentDidMount(): void {
    this.setState({
      title: this.props.isNonBillable ? 'Process non-billable items' : 'Process line items',
    });
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isNonBillable !== this.props.isNonBillable) {
      this.setState({
        title: this.props.isNonBillable ? 'Process non-billable items' : 'Process line items',
      });
    }
  };

  render() {
    const { isOpen, selectedBillingLineItem, paymentSourceType, isNonBillable } = this.props;

    const numberOfLineItems = selectedBillingLineItem.length;

    const NDIALineItems = selectedBillingLineItem.filter((line) => {
      return line.paymentMethod === 'NDIA';
    });

    const numberOfNDIALineItems = NDIALineItems.length;

    const numberOfNDIAInvoices = NDIALineItems ? _.uniqBy(NDIALineItems, 'userId').length : 0;

    const planManagedLineItems = selectedBillingLineItem.filter((line) => {
      return line.paymentMethod === 'PLAN';
    });
    const numberOfPlanManagedInvoices = planManagedLineItems ? _.uniqBy(planManagedLineItems, 'userId').length : 0;

    const selfManagedLineItems = selectedBillingLineItem.filter((line) => {
      return line.paymentMethod === 'SELF';
    });
    const numberOfSelfManagedInvoices = selfManagedLineItems ? _.uniqBy(selfManagedLineItems, 'userId').length : 0;

    const isVCP = paymentSourceType === PaymentSources.VCP;

    const numberOfCaseManagedLineItems = isVCP && _.uniqBy(selectedBillingLineItem, 'userId').length;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        <PreviewInvoicesModal
          isOpen={this.state.isPreviewModalOpen}
          onClose={this._closePreviewModal}
          selectedBillingLineItem={selectedBillingLineItem}
          invoiceNotes={this.state.invoiceNotes}
          onModifyInvoiceNotes={this._setInvoiceNotes}
          modalType={PreviewInvoicesModalType.PREVIEW_OR_EDIT}
        />

        {/* no line item to process */}
        {numberOfLineItems === 0 && (
          <>
            <Paragraph>No line items to process</Paragraph>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Go back
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}

        {/* processing */}
        {!this.state.isSuccess && !this.state.isError && numberOfLineItems !== 0 && (
          <>
            {this.state.isLoading ? (
              <SpinningLoader size={100} message={'Loading'} />
            ) : (
              <div className="text-align-left">
                {!isNonBillable ? (
                  <div className="mb-medium">
                    <Paragraph>
                      You are about to process {numberOfLineItems} {paymentSourceType} item
                      {numberOfLineItems > 1 && 's'}.
                    </Paragraph>

                    {isVCP
                      ? this._renderVCPInfo(numberOfCaseManagedLineItems)
                      : [
                          this._renderNDIAInfo(numberOfNDIALineItems),
                          this._renderPlanInfo(numberOfPlanManagedInvoices),
                          this._renderSelfInfo(numberOfSelfManagedInvoices),
                        ]}
                  </div>
                ) : (
                  <div className="mb-medium">
                    <Paragraph>
                      As these items are non-billable{' '}
                      <Text weight="bold">invoices will not be sent to the customer.</Text>
                    </Paragraph>
                    <Paragraph>
                      Invoices will be generated and marked as paid and can be viewed in the &lsquo;Paid&rsquo; tab of
                      payments
                    </Paragraph>
                  </div>
                )}
              </div>
            )}

            <ActionModalFooter>
              {isNonBillable ? (
                <SecondaryButton
                  size="large"
                  onClick={this._onCloseModal}
                  className="mr-medium"
                  disabled={this.state.isLoading}
                >
                  Cancel
                </SecondaryButton>
              ) : (
                <SecondaryButton
                  size="large"
                  onClick={this._openPreviewModal}
                  loading={this.state.isLoading}
                  className="mr-medium"
                >
                  Preview or edit invoices
                </SecondaryButton>
              )}
              <PrimaryButton size="large" onClick={this._onSubmitProcess} loading={this.state.isLoading}>
                Process {isNonBillable ? 'selected' : 'line'} item{numberOfLineItems > 1 && 's'}
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}

        {/* sucess */}
        {!this.state.isLoading && this.state.isSuccess && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>
                You have successfully processed {numberOfLineItems} {paymentSourceType} item
                {numberOfLineItems > 1 && 's'}{' '}
                {isVCP && `for ${numberOfCaseManagedLineItems} customer${numberOfCaseManagedLineItems > 1 ? 's' : ''}`}.
              </Paragraph>
              {!isNonBillable && !isVCP && numberOfNDIALineItems > 0 && (
                <Paragraph>
                  A CSV with{' '}
                  <b>
                    {numberOfNDIALineItems} {paymentSourceType} line item{numberOfNDIALineItems > 1 && 's'}
                  </b>{' '}
                  will be downloaded shortly.{' '}
                  {paymentSourceType === PaymentSources.NDIS && 'Please upload this CSV file to PRODA'}.
                </Paragraph>
              )}
              {!isVCP && numberOfPlanManagedInvoices > 0 && (
                <Paragraph>
                  <b>
                    {numberOfPlanManagedInvoices} invoice{numberOfPlanManagedInvoices > 1 && 's'}
                  </b>{' '}
                  {numberOfPlanManagedInvoices > 1 ? 'have' : 'has'}{' '}
                  {isNonBillable ? 'been created and has been marked as' : 'been created and sent to the relevant'}{' '}
                  <b>{isNonBillable ? 'paid' : 'plan managers'}</b>.
                </Paragraph>
              )}
              {!isVCP && numberOfSelfManagedInvoices > 0 && (
                <Paragraph>
                  <b>
                    {numberOfSelfManagedInvoices} invoice{numberOfSelfManagedInvoices > 1 && 's'}
                  </b>{' '}
                  {numberOfSelfManagedInvoices > 1 ? 'have' : 'has'} been created and will be send to the relevant{' '}
                  <b>self managers</b>.
                </Paragraph>
              )}
            </div>
          </div>
        )}

        {/* error */}
        {!this.state.isLoading && this.state.isError && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>An error has occured.</Paragraph>
            </div>
          </div>
        )}

        {/* success and error footer */}
        {(this.state.isError || this.state.isSuccess) && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  paymentsList: state.billingsStore.paymentsList,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doProcessPayments: dispatch.billingsStore.doProcessPayments,
});

export default connect(mapState, mapDispatch)(ProcessPaymentActionModal);
