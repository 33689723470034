import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Emails = forwardRef(function Emails(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.945 2.25H16.055c1.367 0 2.47 0 3.337.117.9.12 1.658.38 2.26.981.602.602.86 1.36.982 2.26.116.867.116 1.97.116 3.337V11.055c0 1.367 0 2.47-.116 3.337-.122.9-.38 1.658-.982 2.26-.531.53-1.183.794-1.947.932A3.75 3.75 0 0 1 16 20.75H9.972c-1.374 0-2.444 0-3.306-.059-.875-.06-1.591-.182-2.25-.455a6.75 6.75 0 0 1-3.652-3.653C.49 15.925.369 15.21.309 14.334.25 13.472.25 12.402.25 11.028V6a.75.75 0 0 1 1.5 0v5c0 1.408 0 2.427.055 3.232.055.798.16 1.332.345 1.777A5.25 5.25 0 0 0 4.99 18.85c.445.185.979.29 1.777.345.805.055 1.824.055 3.232.055h6a2.25 2.25 0 0 0 2.13-1.521c-.61.021-1.3.021-2.075.021H9.945c-1.367 0-2.47 0-3.337-.116-.9-.122-1.658-.38-2.26-.982-.602-.602-.86-1.36-.981-2.26-.117-.867-.117-1.97-.117-3.337V8.945c0-1.367 0-2.47.117-3.337.12-.9.38-1.658.981-2.26.602-.602 1.36-.86 2.26-.981.867-.117 1.97-.117 3.337-.117ZM6.808 3.853c-.734.099-1.122.28-1.399.556-.277.277-.457.665-.556 1.4C4.752 6.562 4.75 7.564 4.75 9v2c0 1.435.002 2.437.103 3.192.099.734.28 1.122.556 1.399.277.277.665.457 1.4.556.755.101 1.756.103 3.191.103h6c1.436 0 2.437-.002 3.192-.103.734-.099 1.122-.28 1.4-.556.276-.277.456-.665.555-1.4.102-.755.103-1.756.103-3.191V9c0-1.435-.002-2.437-.103-3.192-.099-.734-.28-1.122-.556-1.399-.277-.277-.665-.457-1.4-.556-.754-.101-1.755-.103-3.19-.103h-6c-1.436 0-2.437.002-3.193.103Zm1.578 2.504a.75.75 0 1 0-.772 1.286l5 3a.75.75 0 0 0 .772 0l5-3a.75.75 0 1 0-.772-1.286L13 9.125 8.386 6.357Z"
        fill="currentColor"
      />
    </Icon>
  );
});
