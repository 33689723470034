import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from 'antd';

import { Text } from 'common-components/typography';
import NumberInput from 'common-components/inputs/NumberInput';
import { EditRatiosSchema } from './schema';

type DurationColumnProps = {
  index: number;
};

export function DurationColumn({ index }: DurationColumnProps) {
  const form = useFormContext<EditRatiosSchema>();
  const unit = form.watch(`ratios.${index}.durationRatio.unit`);

  return (
    <>
      <Controller
        name={`ratios.${index}.durationRatio.duration`}
        control={form.control}
        render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
          <NumberInput
            size="large"
            defaultValue={30}
            style={{ width: 115 }}
            onChange={onChange}
            value={value?.toString() ?? 0}
            ref={ref}
            className={error && 'border-red-dark'}
            min={0}
            precision={unit === 'minutes' ? 0 : 2}
          />
        )}
      />
      <Controller
        name={`ratios.${index}.durationRatio.unit`}
        control={form.control}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            size="large"
            defaultValue="minutes"
            style={{ width: 115 }}
            onChange={onChange}
            value={value}
            ref={ref}
          >
            <Select.Option value="minutes">
              <Text>minutes</Text>
            </Select.Option>
            <Select.Option value="hours">
              <Text>hours</Text>
            </Select.Option>
          </Select>
        )}
      />
    </>
  );
}
