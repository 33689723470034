import React from 'react';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { DateValue } from 'react-aria-components';
import { DatePickerField, DatePickerFieldProps } from '@good/components';

import { convertToDate } from 'utilities/date-utils';
import { useInlineForm } from './inline-form-context';
import { StaticField } from './static-field';
import { InlineFieldInputWrapper } from './inline-field-input-wrapper';
import { OptionalLabel } from './optional-label';

import './inline-date-picker-field.css';

export type InlineDatePickerFieldProps<T extends FieldValues> = {
  description?: string;
  emptyValue?: string;
  error?: string;
  fullWidth?: boolean;
  label?: string;
  maxInputWidth?: React.CSSProperties['maxWidth'];
  name: Path<T>;
  rules?: Omit<RegisterOptions<T, Path<T>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  showOptional?: boolean;
  staticLabel?: string;
} & Omit<DatePickerFieldProps<DateValue>, 'errorMessage' | 'name' | 'label' | 'description'>;

export const InlineDatePickerField = <T extends FieldValues>(props: InlineDatePickerFieldProps<T>) => {
  const { control } = useFormContext<T>();
  const { isEditing, isSubmitting, timezone } = useInlineForm();
  const {
    description,
    emptyValue,
    error: errorProp,
    fullWidth = true,
    isDisabled,
    label,
    maxInputWidth,
    name,
    rules,
    showOptional,
    staticLabel,
    ...DatePickerFieldProps
  } = props;

  if (!timezone) {
    throw new Error('Provide a timezone to the InlineForm component if you want to use the InlineDatePickerField.');
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
        return isEditing ? (
          <InlineFieldInputWrapper
            label={label ? <OptionalLabel label={label} optional={showOptional} /> : undefined}
            description={description}
            name={name}
            error={errorProp ?? error?.message}
            className={[
              'inline-date-picker-field',
              fullWidth ? 'inline-date-picker-field__full-width' : undefined,
              error?.message ? 'inline-date-picker-field__error' : undefined,
            ].join(' ')}
            maxInputWidth={maxInputWidth}
            render={(labelledBy) => {
              return (
                <DatePickerField
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  aria-labelledby={labelledBy}
                  label=''
                  isDisabled={isSubmitting || isDisabled}
                  {...DatePickerFieldProps}
                />
              );
            }}
          />
        ) : (
          <StaticField
            label={staticLabel ?? label}
            value={value ? convertToDate(value, timezone) : null}
            emptyValue={emptyValue}
          />
        );
      }}
    />
  );
};
