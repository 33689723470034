import React, { useRef } from 'react';
import { Skeleton, Image, rem } from '@good/ui/core';

import SupportedIndependentLivingServiceImage from 'assets/banner/SIL.png';
import { useImageLoaded } from 'views/sil-service/hooks';
import { useSilServiceContext } from '../sil-service-provider/sil-service-provider';

export const SilServiceImage = () => {
  const { service, isLoading: isLoadingService, isError } = useSilServiceContext();
  const imageRef = useRef<HTMLImageElement>(null);
  const imageLoaded = useImageLoaded(imageRef);

  const src = (() => {
    if (isError) {
      return undefined;
    }

    if (isLoadingService) {
      return undefined;
    }

    if (service) {
      const url = service.attachments?.find((attachment) => attachment.attachmentType === 'BANNER')?.attachmentUrl;

      if (url === '' || !url) {
        return SupportedIndependentLivingServiceImage as string;
      }

      return url;
    }

    return undefined;
  })();

  const hasImage = src !== undefined;
  const isLoadingImage = hasImage && !imageLoaded;
  const imageReady = hasImage && imageLoaded;

  return (
    <Skeleton
      visible={!imageReady}
      animate={isLoadingService || isLoadingImage}
      miw={rem(64)}
      w={rem(64)}
      height={rem(64)}
      radius={rem(20)}
      style={{
        outline: '2px solid rgba(0, 0, 0, 0.06)',
        outlineOffset: '-2px',
      }}
    >
      <Image
        className='object-cover'
        alt='Service banner'
        src={src}
        ref={imageRef}
        radius={rem(20)}
        h={rem(64)}
        w={rem(64)}
        miw={rem(64)}
        bg='var(--brand-body-light-2)'
      />
    </Skeleton>
  );
};
