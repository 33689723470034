import { Popover2 } from '@blueprintjs/popover2';
import { Avatar, Checkbox, Tooltip } from 'antd';
import { Col } from 'antd/es';
import { GhostButton } from 'common-components/buttons';
import GridRow from 'common-components/grids/GridRow';
import { Text } from 'common-components/typography';
import { IGroupServiceTimesheetShiftSlot } from 'interfaces/service-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import moment, { Duration } from 'moment-timezone';
import React, { Component } from 'react';

interface ITimesheetShiftSlotRowProps {
  onCheckboxCheck: (shiftSlot: IGroupServiceTimesheetShiftSlot) => void;
  shiftSlot: IGroupServiceTimesheetShiftSlot;
  odd: boolean;
  hoverContent: any;
  timezone: timeZone;
}

interface ITimesheetShiftSlotRowState {}

class TimesheetShiftSlotRow extends Component<ITimesheetShiftSlotRowProps, ITimesheetShiftSlotRowState> {
  static formatDuration(duration: Duration, isWarning: boolean = false) {
    const durationFormatter: any = {};

    if (duration.years() > 0) {
      durationFormatter.hours = {
        value: duration.asHours(),
        label: 'hrs',
      };
    } else if (duration.months() > 0) {
      durationFormatter.hours = {
        value: duration.asHours(),
        label: 'hrs',
      };
    } else if (duration.days() > 0) {
      durationFormatter.hours = {
        value: duration.asHours(),
        label: 'hrs',
      };
    } else if (duration.hours() > 0) {
      if (durationFormatter.hours) {
        durationFormatter.hours.value += duration.hours();
      } else {
        durationFormatter.hours = {
          value: duration.hours(),
          label: 'hrs',
        };
      }
    }

    if (duration.minutes() > 0) {
      durationFormatter.minutes = {
        value: duration.minutes(),
        label: 'min',
      };
    }

    const display = [];

    if (durationFormatter.days) {
      if (display.length === 0) {
        display.push(
          <>
            <Text className={isWarning ? 'text-color-orange' : ''}>
              <b>{durationFormatter.days.value}</b>
            </Text>{' '}
            <Text
              className={isWarning ? 'text-color-orange' : ''}
              color="secondary"
            >{`${durationFormatter.days.label}`}</Text>
          </>,
        );
      } else {
        display.push(
          <>
            <br />
            <Text className={isWarning ? 'text-color-orange' : ''}>
              <b>{durationFormatter.days.value}</b>
            </Text>{' '}
            <Text
              className={isWarning ? 'text-color-orange' : ''}
              color="secondary"
            >{`${durationFormatter.days.label} `}</Text>
          </>,
        );
      }
    }

    if (durationFormatter.hours) {
      if (display.length === 0) {
        display.push(
          <>
            <Text className={isWarning ? 'text-color-orange' : ''}>
              <b>{Math.floor(durationFormatter.hours.value)}</b>
            </Text>{' '}
            <Text
              className={isWarning ? 'text-color-orange' : ''}
              color="secondary"
            >{`${durationFormatter.hours.label}`}</Text>
          </>,
        );
      } else {
        display.push(
          <>
            <br />
            <Text className={isWarning ? 'text-color-orange' : ''}>
              <b>{Math.floor(durationFormatter.hours.value)}</b>
            </Text>{' '}
            <Text
              className={isWarning ? 'text-color-orange' : ''}
              color="secondary"
            >{`${durationFormatter.hours.label}`}</Text>
          </>,
        );
      }
    }

    if (durationFormatter.minutes) {
      if (display.length === 0) {
        display.push(
          <>
            <Text className={isWarning ? 'text-color-orange' : ''}>
              <b>{durationFormatter.minutes.value}</b>
            </Text>{' '}
            <Text
              className={isWarning ? 'text-color-orange' : ''}
              color="secondary"
            >{`${durationFormatter.minutes.label}`}</Text>
          </>,
        );
      } else {
        display.push(
          <>
            <br />
            <Text className={isWarning ? 'text-color-orange' : ''}>
              <b>{durationFormatter.minutes.value}</b>
            </Text>{' '}
            <Text
              className={isWarning ? 'text-color-orange' : ''}
              color="secondary"
            >{`${durationFormatter.minutes.label}`}</Text>
          </>,
        );
      }
    }

    return (
      <>
        {_.map(display, (durationDisplay) => {
          return durationDisplay;
        })}
      </>
    );
  }

  render() {
    const { onCheckboxCheck, shiftSlot, odd, timezone } = this.props;

    const checkinMoment = moment.tz(shiftSlot.workerCheckedInDateTime, timezone);
    const checkOutMoment = moment.tz(shiftSlot.workerCheckedOutDateTime, timezone);
    const shiftStartMoment = moment.tz(shiftSlot.shiftStartDateTime, timezone);
    const shiftEndMoment = moment.tz(shiftSlot.shiftEndDateTime, timezone);

    const isInWarning = !checkinMoment.isSame(shiftStartMoment);
    const isOutWarning = !checkOutMoment.isSame(shiftEndMoment);

    const scheduleDiff = shiftEndMoment.diff(shiftStartMoment);
    const scheduleDifference = moment.duration(scheduleDiff);

    const actualDiff = checkOutMoment.diff(checkinMoment);
    const actualDifference = moment.duration(actualDiff);

    const isActualDiffWarning = scheduleDiff !== actualDiff;

    const differences = moment.duration(Math.abs(scheduleDiff - actualDiff), 'ms');

    let containerClassName = '';

    if (odd) {
      containerClassName = 'bg-quaternary';
    }

    if (isInWarning || isOutWarning || isActualDiffWarning) {
      containerClassName = 'bg-orange-lightest';
    }

    return (
      <GridRow gutter={16} containerClassName={containerClassName} bordered={false}>
        <Col span={7}>
          <div className="flex-row justify-start align-center">
            <div>
              <Avatar src={shiftSlot.attachmentUrl} size={32} />
            </div>
            <Text className="ml-small" ellipsis={true}>
              {shiftSlot.firstName} {shiftSlot.lastName}
            </Text>
          </div>
        </Col>
        <Col span={3}>
          <Text className={isInWarning ? 'text-color-orange' : ''}>{checkinMoment.format('h:mm A')}</Text>
        </Col>
        <Col span={3}>
          <Text className={isOutWarning ? 'text-color-orange' : ''}>{checkOutMoment.format('h:mm A')}</Text>
        </Col>
        <Tooltip
          title={
            <div>
              Schedule time: <br /> {shiftStartMoment.format('h:mm A')} - {shiftEndMoment.format('h:mm A')}
            </div>
          }
        >
          <Col span={3} className="text-align-right">
            {TimesheetShiftSlotRow.formatDuration(scheduleDifference)}
          </Col>
        </Tooltip>
        <Col span={4} className="text-align-right">
          {TimesheetShiftSlotRow.formatDuration(actualDifference, isActualDiffWarning)}
        </Col>
        <Col span={4} className="text-align-right">
          {isActualDiffWarning ? (
            TimesheetShiftSlotRow.formatDuration(differences, isActualDiffWarning)
          ) : (
            <>
              <Text>
                <b>0</b>
              </Text>
              <Text color="secondary"> hrs</Text>
            </>
          )}
        </Col>
      </GridRow>
    );
  }
}

export default TimesheetShiftSlotRow;
