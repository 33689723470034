import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const PaperclipSlash = forwardRef(function PaperclipSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.75c-.825 0-1.576.306-2.15.812a.75.75 0 0 1-.992-1.124A4.75 4.75 0 0 1 14.75 6v4a.75.75 0 0 1-1.5 0V6A3.25 3.25 0 0 0 10 2.75Zm-7.53-.28a.75.75 0 0 1 1.06 0l3 3 4 4 4 4 3.743 3.743 3.257 3.257a.75.75 0 1 1-1.06 1.06l-2.458-2.458A6.75 6.75 0 0 1 5.25 16V6.31L2.47 3.53a.75.75 0 0 1 0-1.06Zm4.28 5.34V16a5.25 5.25 0 0 0 10.13 1.94l-2.13-2.13V16a2.75 2.75 0 1 1-5.5 0v-5.69l-2.5-2.5Zm4 4V16a1.25 1.25 0 1 0 2.5 0v-1.69l-2.5-2.5ZM18 4.25a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-1.5 0V5a.75.75 0 0 1 .75-.75ZM9.25 6a.75.75 0 0 1 1.5 0v.5a.75.75 0 0 1-1.5 0V6Z"
        fill="currentColor"
      />
    </Icon>
  );
});
