import React, { useRef } from 'react';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { Select, SelectProps } from '@good/ui/core';

import { useInlineForm } from './inline-form-context';
import { SelectStaticField } from './select-static-field';
import { OptionalLabel } from './optional-label';

export type InlineSelectFieldProps<T extends FieldValues> = {
  children?: React.ReactNode;
  emptyValue?: string;
  label?: string;
  maxInputWidth?: React.CSSProperties['maxWidth'];
  name: Path<T>;
  rules?: Omit<RegisterOptions<T, Path<T>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  showOptional?: boolean;
  staticLabel?: string;
} & Omit<SelectProps, 'name' | 'label'>;

export const InlineSelectField = <T extends FieldValues>(props: InlineSelectFieldProps<T>) => {
  const { control } = useFormContext<T>();
  const { isEditing, isSubmitting, maxWidth } = useInlineForm();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    children,
    disabled,
    emptyValue,
    label,
    maxInputWidth,
    name,
    rules,
    showOptional,
    staticLabel,
    ...selectFieldProps
  } = props;

  return (
    <div className='flex flex-col gap-2'>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
          return isEditing ? (
            <>
              <Select
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={(ref_) => {
                  ref(ref_);
                  inputRef.current = ref_;
                }}
                disabled={isSubmitting || disabled}
                error={error?.message}
                style={{ maxWidth }}
                label={label ? <OptionalLabel label={label} optional={showOptional} /> : undefined}
                inputContainer={(children) => <div style={{ maxWidth: maxInputWidth }}>{children}</div>}
                {...selectFieldProps}
              />
            </>
          ) : (
            <SelectStaticField
              label={staticLabel ?? label}
              value={value}
              emptyValue={emptyValue}
              data={selectFieldProps.data}
            />
          );
        }}
      />
      {children}
    </div>
  );
};
