import React, { useState } from 'react';
import ActionModal from 'common-components/modal/ActionModal';
import { Col, notification, Row } from 'antd';
import { Paragraph } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { ISupportCoordinator } from 'interfaces/customer-interfaces';
import { IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';

type IRemoveSupportCoordinatorModal = IMapDispatchToProps & {
  isOpen: boolean;
  closeModal: () => void;
  supportCoordinator?: ISupportCoordinator;
  customerUserId: string;
};

const RemoveSupportCoordinatorModal = ({
  isOpen,
  closeModal,
  supportCoordinator,
  customerUserId,
  doRemoveSupportCoordinator,
}: IRemoveSupportCoordinatorModal) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onHandleCloseModal = () => {
    setLoading(false);
    closeModal();
  };

  const onClickRemove = async () => {
    if (customerUserId && supportCoordinator) {
      const payload = {
        customerUserId,
        supportCoordinatorId: supportCoordinator.supportCoordinatorId,
      };
      try {
        setLoading(true);
        await doRemoveSupportCoordinator(payload);
        notification.open({
          message: <b>Support Coordinator Removed</b>,
          description: (
            <p>
              You have successfully removed <b>{supportCoordinator.fullName}</b> as the support coordinator for this
              customer.
            </p>
          ),
        });
        onHandleCloseModal();
      } catch {
        setLoading(false);
        notification.error({ message: 'Oops, an error has occurred, please try again.' });
      }
    }
  };

  return (
    <ActionModal title="Remove support coordinator" isOpen={isOpen} onClose={onHandleCloseModal}>
      <div className="anim-slide-left">
        <Paragraph>
          Are you sure you want to remove <b>{supportCoordinator?.fullName}</b> as this customers support coordinator?
        </Paragraph>
        <div className={'mb-small mt-x2-large'}>
          <Row type={'flex'} className={'justify-end'}>
            <Col className="mr-large">
              <GhostButton size="large" onClick={onHandleCloseModal}>
                Cancel
              </GhostButton>
            </Col>
            <Col>
              <PrimaryButton size="large" onClick={onClickRemove} loading={loading}>
                Remove
              </PrimaryButton>
            </Col>
          </Row>
        </div>
      </div>
    </ActionModal>
  );
};

type IMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doRemoveSupportCoordinator: dispatch.customersStore.doRemoveSupportCoordinator,
});

export default connect(null, mapDispatchToProps)(RemoveSupportCoordinatorModal);
