import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Brush = forwardRef(function Brush(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99 1.25a2.74 2.74 0 0 0-2.74 2.74v6.93c0 1.554.815 2.994 2.147 3.793l1.642.985a1.948 1.948 0 0 1 .92 1.99l-.206 1.23a3.291 3.291 0 1 0 6.494 0l-.205-1.23a1.948 1.948 0 0 1 .919-1.99l1.642-.985a4.423 4.423 0 0 0 2.147-3.793V3.99a2.74 2.74 0 0 0-2.74-2.74H6.99Zm11.26 8V3.99a1.24 1.24 0 0 0-1.24-1.24h-3.26V4a.75.75 0 0 1-1.5 0V2.75h-2.5V6a.75.75 0 0 1-1.5 0V2.75H6.99a1.24 1.24 0 0 0-1.24 1.24v5.26h12.5Zm-12.5 1.5h12.5v.17a2.923 2.923 0 0 1-1.42 2.507l-1.64.985a3.448 3.448 0 0 0-1.628 3.523l.205 1.23a1.791 1.791 0 1 1-3.534 0l.205-1.23a3.448 3.448 0 0 0-1.627-3.523l-1.642-.985A2.923 2.923 0 0 1 5.75 10.92v-.17Z"
        fill="currentColor"
      />
    </Icon>
  );
});
