import React from 'react';
import { Icon } from 'antd';
import { Text } from 'common-components/typography';

import { HyperlinkButton } from 'common-components/buttons';

function InfoPanel({
  text = '',
  hasLink = false,
  linkAction = () => console.log('not implemented'),
  linkText = '',
  className = '', // add by dkamphuoc 01/08/2022
}: {
  text: React.ReactNode;
  hasLink?: boolean;
  linkAction?: any;
  linkText?: React.ReactNode;
  className?: string; // add by dkamphuoc 01/08/2022
}) {
  return (
    <div
      className={`p-medium bordered border-standard-gray rounded-big bg-quaternary line-height-135 flex-row${
        !['', null, undefined].includes(className) ? ` ${className}` : ''
      }`}
    >
      <Icon type="info-circle" className="mr-small pt-x-small text-color-tertiary" />
      <div className="flex-column">
        <div>
          <Text size="large" lineHeight={135}>
            {text}
          </Text>
        </div>

        {hasLink && (
          <div>
            <HyperlinkButton onClick={linkAction}>{linkText}</HyperlinkButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default InfoPanel;
