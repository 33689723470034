import { css } from '@stitches/react';
import type { CSS } from '../theme';

export const reset: CSS = {
  margin: 0,
  heading: 0,
  overflowWrap: 'break-word',
  textRendering: 'optimizeLegibility',
};

export const base = css({
  display: 'inline-flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '$size75',
});

export const label = css({
  display: 'inline-flex',
  alignItems: 'center',
  fontFamily: '$primary',
  fontSize: '$font75',
  lineHeight: '$leading300',
  color: '$black',
  fontWeight: '$bold',
  columnGap: '$xxsmall',
});

export const description: CSS = {
  fontFamily: '$primary',
  color: '$textSecondary',
  lineHeight: '$leading300',
  fontSize: '$small',
  fontWeight: '$normal',
};

export const optional = css({
  fontFamily: '$primary',
  fontSize: '$font50',
  lineHeight: '$leading300',
  fontWeight: '$normal',
  color: '$muted',
});
