import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { FieldLabel, Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { Avatar, Col, Form, Icon, notification, Radio, Row, Switch } from 'antd';
import { HyperlinkButton, IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { ErrorSVG } from 'assets/UndrawSVG';
import ActionModal from 'common-components/modal/ActionModal';
import { Warning } from 'common-components/alerts';
import moment from 'moment-timezone';
import { BookingActionType, BookingType, EditRecurringMode, FilterType, ShiftSlotStatus } from 'utilities/enum-utils';
import WorkerStatusTag from 'common-components/tags/WorkerStatusTag';
import SelectWorkerSearchModal from 'views/bookings/components/SelectWorkerSearchModal';
import FoundWorkerRow from 'views/bookings/components/FoundWorkerRow';
import { FilterSection } from 'common-components/filter';
import { Popover } from '@blueprintjs/core';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import CommonUtils from 'utilities/common-utils';
import AvailabilityConflictTimes from 'views/team/details/tabs-panel/availability/components/AvailablilityConflictTimes';
import { IShiftClashConflict } from 'interfaces/service-interfaces';
import { ShiftClashContent } from 'common-components/shift-clash';
import ConflictCheckUtils from 'utilities/conflict-check-utils';
import BookingActionSuccessNotificationContent from './BookingActionSuccessNotificationContent';
import WorkerStatusTagV2 from 'common-components/tags/WorkerStatusTagV2';

interface IAssignSessionButtonProps {
  selectedWorker: unknown;
  isBookingCancelled: boolean;
  onAssignWorker: (shiftSlotStatus: ShiftSlotStatus) => void;
}

export const AssignSessionButton: React.FunctionComponent<IAssignSessionButtonProps> = ({
  selectedWorker,
  isBookingCancelled,
  onAssignWorker,
}) => {
  return (
    <>
      <PrimaryButton
        disabled={!selectedWorker}
        onClick={() => onAssignWorker(ShiftSlotStatus.CONFIRMED)}
        size="large"
        className="ml-large"
      >
        Assign as Confirmed
      </PrimaryButton>

      {!isBookingCancelled && (
        <Popover
          content={
            <ActionMenu>
              <ActionMenuItem text="Assign as pending" onClick={() => onAssignWorker(ShiftSlotStatus.PENDING)} />
            </ActionMenu>
          }
          position="bottom-left"
          usePortal={false}
          disabled={!selectedWorker}
        >
          <IconButton className="rounded-right ml-x2-small" icon="down" size="large" disabled={!selectedWorker} />
        </Popover>
      )}
    </>
  );
};

interface IBookingAssignWorkerModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: (refreshData?: boolean) => void;
  onSubmitAssign: (payload: {
    selectedWorker: any;
    editRecurringMode: any;
    shiftSlotStatus: ShiftSlotStatus;
    isRemovePendingShiftSlots: boolean;
    startDateTime: Date | any;
    endDateTime: Date | any;
    conflicts?: any;
    bookingOutSideCanBeAssign?: any;
    bookingOutsideAvailability?: any;
    ignoreShiftClashShiftIds: string[];
    ignoreShiftClashServiceDateTimeIds?: string[];
    shiftClashConflicts?: IShiftClashConflict[];
  }) => Promise<any>;
  serviceId: string;
  bookingId?: string;
  bookingRequestId?: string;
  doFetchServiceRoster: typeof dispatch.bookingsStore.doFetchServiceRoster;
  doCheckWorker: typeof dispatch.bookingsStore.doCheckWorker;
  doCheckAssignWorker: typeof dispatch.bookingsStore.doCheckAssignWorker;
  isRecurring?: boolean;
  sessions?: any;
  address?: any;
  displayTimezone?: any;
  isEditingBooking?: boolean;
  startDateTime?: Date;
  endDateTime?: Date;
  customerUserIds?: Array<string>;
  noValidationStep?: boolean;
  isAssignedTeamMemberConfirmed?: boolean;
  bookingType: string;
  isBookingCancelled: boolean;
}

interface IBookingAssignWorkerModalState {
  error: any;
  selectedWorker: any;
  step: number;
  now: any;
  conflicts: any;
  selectedOption: number;
  conflictErrorMessage: string;
  isSearchOpen: boolean;
  isLoadingFoundWorkers: boolean;
  filteredWorkers: any;
  activeFilters: {
    previouslyUsed: boolean;
    isSkillMatched: boolean;
    isAvailable: boolean;
    isCustomerPreferred: boolean;
  };
  listFilters: any;
  isLoading: boolean;
  shiftSlotStatus: ShiftSlotStatus;
  isRemovePendingShiftSlots: boolean;
  addTimesheet: any;
  workerTimesheetStartTime: Date;
  workerTimesheetEndTime: Date;
  scheduledRemoveConflict: any;
  showUnavailableBookings: boolean;
  shiftsOutsideGeneralAvailability: any;
  selectedShiftOutSideCanBeAssign: any;
  shiftClashConflicts: IShiftClashConflict[];
  ignoreShiftClashShiftIds: string[];
}

const availableFilters = [
  FilterType.QUALIFICATIONS,
  FilterType.RELIGIONS,
  FilterType.GENDER,
  FilterType.SKILLS,
  FilterType.LANGUAGES,
  FilterType.INTEREST,
];

class BookingAssignWorkerModal extends Component<IBookingAssignWorkerModalProps, IBookingAssignWorkerModalState> {
  static defaultProps = {
    isBookingCancelled: false,
  };

  state = {
    error: null,
    selectedWorker: null,
    step: 1,
    now: new Date(),
    conflicts: null,
    selectedOption: null,
    conflictErrorMessage: null,
    isSearchOpen: false,
    isLoadingFoundWorkers: false,
    filteredWorkers: [],
    activeFilters: {
      previouslyUsed: false,
      isSkillMatched: false,
      isAvailable: true,
      isCustomerPreferred: false,
    },
    listFilters: null,
    isLoading: false,
    shiftSlotStatus: ShiftSlotStatus.CONFIRMED,
    isRemovePendingShiftSlots: null,
    addTimesheet: null,
    workerTimesheetStartTime: moment(
      moment.tz(this.props.startDateTime, this.props.displayTimezone).format('YYYY-MM-DD HH:mm'),
    ).toDate(),
    workerTimesheetEndTime: moment(
      moment.tz(this.props.endDateTime, this.props.displayTimezone).format('YYYY-MM-DD HH:mm'),
    ).toDate(),
    scheduledRemoveConflict: null,
    showUnavailableBookings: false,
    shiftsOutsideGeneralAvailability: null,
    selectedShiftOutSideCanBeAssign: [],
    shiftClashConflicts: [],
    ignoreShiftClashShiftIds: [],
  };

  private _onClose = async () => {
    const { onClose, startDateTime, endDateTime } = this.props;
    const refreshData = this.state.step === 4 || this.state.step === 12;
    this.setState({
      error: null,
      step: 2,
      conflicts: null,
      activeFilters: {
        previouslyUsed: false,
        isSkillMatched: false,
        isAvailable: true,
        isCustomerPreferred: false,
      },
      shiftSlotStatus: ShiftSlotStatus.CONFIRMED,
      isRemovePendingShiftSlots: null,
      workerTimesheetStartTime: moment(
        moment.tz(startDateTime, this.props.displayTimezone).format('YYYY-MM-DD HH:mm'),
      ).toDate(),
      workerTimesheetEndTime: moment(
        moment.tz(endDateTime, this.props.displayTimezone).format('YYYY-MM-DD HH:mm'),
      ).toDate(),
      scheduledRemoveConflict: null,
      showUnavailableBookings: false,
    });

    onClose(refreshData);
  };

  private _onSelectWorker = (selectedWorker) => {
    this.setState({ selectedWorker });
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _checkForConflict = async () => {
    const {
      doCheckWorker,
      doCheckAssignWorker,
      isEditingBooking,
      bookingRequestId,
      startDateTime,
      sessions,
      serviceId,
      bookingId,
      address,
      isRecurring,
      bookingType,
      customerUserIds,
    } = this.props;

    this.setState({ isLoading: true });

    try {
      const result: any = isEditingBooking
        ? await doCheckAssignWorker({
            bookingRequestId,
            bookingId,
            editRecurringMode: this.state.selectedOption ? this.state.selectedOption : EditRecurringMode.Current,
            workerId: this.state.selectedWorker.supportWorkerId,
            startDateTime,
          })
        : bookingType === BookingType.BOOKING
        ? await doCheckWorker({
            serviceId: serviceId,
            workerId: this.state.selectedWorker.supportWorkerId,
            address: address ? { ...address, geoLat: Number(address.geoLat), geoLng: Number(address.geoLng) } : null,
            sessions: sessions,
            customerUserId: customerUserIds && customerUserIds[0],
          })
        : { data: {} };

      const isSingleBooking = this._checkIsSingleBooking();
      const removedData = result.data.sessionsAfterRemovedWorker || result.data.shiftsAfterRemovedWorker;
      const removedConflicts = removedData.sessions || removedData.shiftSlots || [];

      if (isRecurring && result && !_.isEmpty(removedConflicts)) {
        if (this.state.selectedOption === 1 && isEditingBooking) {
          this.setState({
            step: 15,
            isLoading: false,
            scheduledRemoveConflict: removedData,
          });
        } else {
          this.setState({
            step: 14,
            isLoading: false,
            scheduledRemoveConflict: removedData,
          });
        }
      } else if (result && result.data && !_.isEmpty(result.data.shiftClashConflicts)) {
        this.setState({
          step: 17,
          shiftClashConflicts: result.data.shiftClashConflicts,
          shiftsOutsideGeneralAvailability: !_.isEmpty(result.data.shiftsOutsideGeneralAvailability)
            ? result.data.shiftsOutsideGeneralAvailability
            : null,
          isLoading: false,
        });
      } else if (
        result &&
        result.data &&
        !_.isEmpty(result.data.shiftsOutsideGeneralAvailability) &&
        !isSingleBooking &&
        isRecurring
      ) {
        this.setState({
          step: 16,
          shiftsOutsideGeneralAvailability: result.data.shiftsOutsideGeneralAvailability,
          isLoading: false,
        });
      } else {
        this._onSubmitAssign(this.state.shiftSlotStatus === ShiftSlotStatus.CONFIRMED, result.data.conflicts);
      }
    } catch (e) {
      this.setState({ step: 5 });
    }
  };

  private _checkIsSingleBooking = () => {
    const { isRecurring, isEditingBooking } = this.props;
    const { selectedOption } = this.state;
    return isEditingBooking ? selectedOption === EditRecurringMode.Current || !isRecurring : !isRecurring;
  };

  private _onSubmitAssign = async (
    isRemovePendingShiftSlots = null,
    conflicts = null,
    ignoreShiftClashShiftIds: string[] = [],
  ) => {
    this.setState({ isLoading: true, isRemovePendingShiftSlots });
    const isSingleBooking = this._checkIsSingleBooking();
    try {
      const bookingOutSideCanBeAssign = !_.isEmpty(this.state.shiftsOutsideGeneralAvailability)
        ? isSingleBooking
          ? [_.first(this.state.shiftsOutsideGeneralAvailability)]
          : this.state.selectedShiftOutSideCanBeAssign
        : [];

      const payload = {
        selectedWorker: this.state.selectedWorker,
        editRecurringMode: this.state.selectedOption ? this.state.selectedOption : EditRecurringMode.Current,
        shiftSlotStatus: this.state.shiftSlotStatus,
        isRemovePendingShiftSlots: isRemovePendingShiftSlots
          ? isRemovePendingShiftSlots
          : this.state.shiftSlotStatus === ShiftSlotStatus.CONFIRMED,
        startDateTime: moment.tz(
          moment(this.state.workerTimesheetStartTime).format('YYYY-MM-DD HH:mm'),
          this.props.displayTimezone,
        ),
        endDateTime: moment.tz(
          moment(this.state.workerTimesheetEndTime).format('YYYY-MM-DD HH:mm'),
          this.props.displayTimezone,
        ),
        conflicts,
        bookingOutSideCanBeAssign,
        bookingOutsideAvailability: this.state.shiftsOutsideGeneralAvailability,
        ignoreShiftClashShiftIds: _.isEmpty(ignoreShiftClashShiftIds)
          ? this.state.ignoreShiftClashShiftIds
          : ignoreShiftClashShiftIds,
      };

      let assignedShiftsCount = 0;
      let currentShiftStatus = ShiftSlotStatus.UNASSIGNED;

      if (this.props.isEditingBooking && this.props.bookingType !== BookingType.ACTIVITY_RECORD) {
        const result = await this.props.onSubmitAssign(payload);
        assignedShiftsCount = result.assignedShiftsCount;
        currentShiftStatus = result.currentShiftStatus;
      } else {
        await this.props.onSubmitAssign(payload);
      }

      if (assignedShiftsCount > 0) {
        notification.open({
          message: <b>Team member assigned</b>,
          description: (
            <BookingActionSuccessNotificationContent
              action={BookingActionType.ASSIGN}
              shiftsCount={assignedShiftsCount}
              currentShiftStatus={currentShiftStatus}
              workerName={`${this.state.selectedWorker.firstName} ${this.state.selectedWorker.lastName}`}
            />
          ),
        });
      }

      this.props.noValidationStep
        ? this._onClose()
        : this.props.bookingType === BookingType.BOOKING
        ? // ? this.setState({ step: 4 })
          this._onClose() // this will not display successful modal anymore, temporary solution
        : this.setState({ step: 12 });
    } catch (e) {
      if (e.meta.message === 'Cannot assign worker, worker conflicts exist') {
        this.setState({
          conflictErrorMessage: 'This Team member is already assigned to another booking and cannot be selected.',
          step: 8,
        });
      } else if (e.meta.message.includes('Cannot assign worker,')) {
        this.setState({
          conflictErrorMessage:
            'This Team member is already assigned to a completed or in progress booking, please select another Team member.',
          step: 8,
        });
      } else {
        this.setState({ step: 5 });
      }
      this.setState({ isLoading: false });
    }
  };

  private _onOpenSearchModal = () => {
    this.setState({ selectedWorker: null, isSearchOpen: true });
  };

  private _onCloseSearchModal = () => {
    this.setState({ isSearchOpen: false });
  };

  private _onChangeListFilter = (filters: Array<any>) => {
    this.setState({ listFilters: filters });
  };

  private _onAssignWorker = async (shiftSlotStatus?, selectedIgnoreShiftClashShiftIds: string[] = []) => {
    const { sessions, onSubmitAssign } = this.props;
    const {
      shiftsOutsideGeneralAvailability,
      shiftClashConflicts,
      ignoreShiftClashShiftIds,
      selectedShiftOutSideCanBeAssign,
      selectedOption,
      isRemovePendingShiftSlots,
      workerTimesheetStartTime,
      workerTimesheetEndTime,
      conflicts,
    } = this.state;

    let selectedWorker = { ...this.state.selectedWorker };
    let newShiftSlotStatus = ShiftSlotStatus.CONFIRMED;

    if (!_.isEmpty(shiftsOutsideGeneralAvailability) || !_.isEmpty(shiftClashConflicts)) {
      const allShiftIds = _.map(sessions, (session) => session.serviceDateTimeId);
      const outsideAvailabilityShiftIds = _.map(shiftsOutsideGeneralAvailability, (shift) => shift.serviceDateTimeId);
      const shiftClashShiftIds = !_.isEmpty(shiftClashConflicts)
        ? _.map(shiftClashConflicts[0].selectedShifts, (shift) => shift.serviceDateTimeId)
        : [];

      const ignoreOutsideAvailabilityShiftIds = _.map(
        selectedShiftOutSideCanBeAssign,
        (shift) => shift.serviceDateTimeId,
      );

      const conflictingShiftIds = _.union(outsideAvailabilityShiftIds, shiftClashShiftIds);
      const nonassignableShiftIds = ConflictCheckUtils.filterIgnoredConflictedShiftIds(
        conflictingShiftIds,
        _.isEmpty(selectedIgnoreShiftClashShiftIds) ? ignoreShiftClashShiftIds : selectedIgnoreShiftClashShiftIds,
        ignoreOutsideAvailabilityShiftIds,
        shiftClashShiftIds,
        outsideAvailabilityShiftIds,
      );

      const assignableShiftIds = allShiftIds;

      _.remove(assignableShiftIds, (serviceDateTimeId) => _.includes(nonassignableShiftIds, serviceDateTimeId));

      if (_.isEmpty(assignableShiftIds)) {
        newShiftSlotStatus = ShiftSlotStatus.UNASSIGNED;
        selectedWorker = null;
      }
    }

    this.setState({ shiftSlotStatus: shiftSlotStatus || newShiftSlotStatus });

    if (this.props.isRecurring && this.props.isEditingBooking) {
      this.setState({ step: 6 });
    } else {
      onSubmitAssign({
        selectedWorker,
        editRecurringMode: selectedOption ? selectedOption : EditRecurringMode.Current,
        shiftSlotStatus: shiftSlotStatus || newShiftSlotStatus,
        isRemovePendingShiftSlots: isRemovePendingShiftSlots
          ? isRemovePendingShiftSlots
          : shiftSlotStatus === ShiftSlotStatus.CONFIRMED,
        startDateTime: moment.tz(
          moment(workerTimesheetStartTime).format('YYYY-MM-DD HH:mm'),
          this.props.displayTimezone,
        ),
        endDateTime: moment.tz(moment(workerTimesheetEndTime).format('YYYY-MM-DD HH:mm'), this.props.displayTimezone),
        conflicts,
        bookingOutSideCanBeAssign: selectedShiftOutSideCanBeAssign,
        bookingOutsideAvailability: shiftsOutsideGeneralAvailability,
        shiftClashConflicts,
        ignoreShiftClashShiftIds: _.isEmpty(selectedIgnoreShiftClashShiftIds)
          ? ignoreShiftClashShiftIds
          : selectedIgnoreShiftClashShiftIds,
      });
      this._onClose();
    }
  };

  private _onClickAssign(shiftSlotStatus) {
    this.setState({ shiftSlotStatus });

    if (this.props.isRecurring && this.props.isEditingBooking) {
      this.setState({ step: 6 });
    } else {
      this._checkForConflict();
    }
  }

  private _ChooseNewWorker = () => {
    this.setState({ error: null, selectedWorker: null, step: 2, conflicts: null });
  };

  private _onChangeAddTimesheet = (e) => {
    this.setState({ addTimesheet: e.target.value });
  };

  private _onOpenAddTimesheet = () => {
    this.setState({ step: 11 });
  };

  private _onOpenEditTimesheet = () => {
    this.setState({ step: 13, addTimesheet: null, shiftSlotStatus: ShiftSlotStatus.CHECKED_OUT });
  };

  private _onChangeFilter = (filterLabel, newValue) => {
    this.setState({ activeFilters: { ...this.state.activeFilters, [filterLabel]: newValue } });
  };

  private _onChangeActivityDate = (date) => {
    const { workerTimesheetStartTime, workerTimesheetEndTime } = this.state;
    const newStartDate = moment(date)
      .set('hour', moment(workerTimesheetStartTime).hour())
      .set('minute', moment(workerTimesheetStartTime).minute());

    const newEndDate = moment(date)
      .set('hour', moment(workerTimesheetEndTime).hour())
      .set('minute', moment(workerTimesheetEndTime).minute());

    this.setState({ workerTimesheetStartTime: newStartDate.toDate(), workerTimesheetEndTime: newEndDate.toDate() });
  };
  private _onChangeActivityStartTime = (date) => {
    const newStartTime = CommonUtils.formatCeilingDateTime(moment(date));
    if (newStartTime.isAfter(moment(this.state.workerTimesheetEndTime))) {
      this.setState({
        workerTimesheetEndTime: moment(newStartTime).add(1, 'hour').toDate(),
      });
    }

    this.setState({
      workerTimesheetStartTime: newStartTime.toDate(),
    });
  };
  private _onChangeActivityEndTime = (date) => {
    let newEndTime = CommonUtils.formatCeilingDateTime(moment(date));
    if (newEndTime.isBefore(moment(this.state.workerTimesheetStartTime))) {
      newEndTime = moment(this.state.workerTimesheetStartTime).add(1, 'hour');
    }
    this.setState({
      workerTimesheetEndTime: newEndTime.toDate(),
    });
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.state.listFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        requestFilter[filter.filter] = filter.values;
      }
    });
    return requestFilter;
  };

  private _getCurrentBookingDateTime = () => {
    const { startDateTime, endDateTime, sessions } = this.props;

    const bookingStartDateTime = !_.isEmpty(sessions) ? sessions[0].startDateTime : startDateTime;
    const bookingEndDateTime = !_.isEmpty(sessions) ? sessions[0].endDateTime : endDateTime;

    return {
      startDateTime: bookingStartDateTime,
      endDateTime: bookingEndDateTime,
    };
  };

  private _applyFilter = async () => {
    const { doFetchServiceRoster, address, customerUserIds, serviceId } = this.props;
    const { startDateTime, endDateTime } = this._getCurrentBookingDateTime();
    this.setState({ isLoadingFoundWorkers: true });

    const requestFilters = this._formatFilterQuery();
    const filteredWorkers = await doFetchServiceRoster({
      startDateTime,
      endDateTime,
      address,
      customerUserIds,
      serviceId,
      ...this.state.activeFilters,
      ...requestFilters,
    });
    this.setState({ isLoadingFoundWorkers: false, filteredWorkers });
  };

  private _showUnavailableBookings = () => {
    this.setState({ showUnavailableBookings: !this.state.showUnavailableBookings });
  };

  private _onChangeSelectedShiftToBeKept = (shifts) => {
    this.setState({ selectedShiftOutSideCanBeAssign: shifts });
  };

  private _onSubmitShiftClash = (ignoreShiftClashShiftIds) => {
    const { shiftClashConflicts, shiftsOutsideGeneralAvailability } = this.state;
    const { isRecurring, isEditingBooking } = this.props;

    const remainedShiftsOutsideGeneralAvailability =
      ConflictCheckUtils.filterOutsideAvailabilityShiftsAfterSelectingShiftClashShiftById(
        shiftClashConflicts,
        ignoreShiftClashShiftIds,
        shiftsOutsideGeneralAvailability,
      );

    if (!_.isEmpty(remainedShiftsOutsideGeneralAvailability) && !this._checkIsSingleBooking()) {
      this.setState({
        step: 16,
        isLoading: false,
        ignoreShiftClashShiftIds,
        shiftsOutsideGeneralAvailability: remainedShiftsOutsideGeneralAvailability,
      });
    } else {
      if (!isEditingBooking && isRecurring) {
        this._onAssignWorker(null, ignoreShiftClashShiftIds);
      } else {
        this._onSubmitAssign(null, null, ignoreShiftClashShiftIds);
      }
    }
  };

  async componentDidMount() {
    const { serviceId, address, customerUserIds, doFetchServiceRoster, bookingType } = this.props;
    this.setState({ step: 1 });
    try {
      if (bookingType === BookingType.ACTIVITY_RECORD) {
        const result: any = await doFetchServiceRoster({
          address,
          customerUserIds,
          serviceId,
          ...this.state.activeFilters,
        });
        this.setState({ step: 2, filteredWorkers: result });
      } else {
        const { startDateTime, endDateTime } = this._getCurrentBookingDateTime();

        const result: any = await doFetchServiceRoster({
          startDateTime,
          endDateTime,
          address,
          customerUserIds,
          serviceId,
          ...this.state.activeFilters,
        });
        this.setState({ step: 2, filteredWorkers: result });
      }
    } catch (e) {
      this.setState({ step: 5 });
    }
  }

  componentDidUpdate = async (
    prevProps: Readonly<IBookingAssignWorkerModalProps>,
    prevState: Readonly<IBookingAssignWorkerModalState>,
  ) => {
    const { address, customerUserIds, serviceId, doFetchServiceRoster, bookingType } = this.props;
    const { activeFilters } = this.state;
    if (prevState.activeFilters !== activeFilters) {
      this.setState({ isLoadingFoundWorkers: true });
      if (bookingType === BookingType.ACTIVITY_RECORD) {
        const filteredWorkers = await doFetchServiceRoster({
          address,
          customerUserIds,
          serviceId,
          ...activeFilters,
        });
        this.setState({ isLoadingFoundWorkers: false, filteredWorkers });
      } else {
        const { startDateTime, endDateTime } = this._getCurrentBookingDateTime();

        const filteredWorkers = await doFetchServiceRoster({
          startDateTime,
          endDateTime,
          address,
          customerUserIds,
          serviceId,
          ...activeFilters,
        });
        this.setState({ isLoadingFoundWorkers: false, filteredWorkers });
      }
    }
    if (prevState.listFilters !== this.state.listFilters) {
      await this._applyFilter();
    }
  };

  renderContent = () => {
    const { displayTimezone, isRecurring, isEditingBooking, isBookingCancelled } = this.props;
    const {
      selectedWorker,
      isSearchOpen,
      isLoadingFoundWorkers,
      filteredWorkers,
      activeFilters,
      listFilters,
      isLoading,
      isRemovePendingShiftSlots,
      scheduledRemoveConflict,
      shiftClashConflicts,
    } = this.state;

    const isShiftStatusPending = this.state.shiftSlotStatus === ShiftSlotStatus.PENDING;
    if (this.state.step === 1) {
      return (
        <>
          <Paragraph>
            Which support worker would you like to assign to this <b>booking</b>?
          </Paragraph>
          <div className="mt-x5-large">
            <SpinningLoader size={150} message={'Fetching support worker data ...'} />
          </div>
        </>
      );
    }
    if (this.state.step === 2) {
      return (
        <>
          <div>
            <Paragraph className="mb-large">
              Filter and select a team member for this{' '}
              {this.props.bookingType === BookingType.BOOKING ? 'booking' : 'activity record'}.
              {this.props.isAssignedTeamMemberConfirmed && ' Once assigned, the team member will become confirmed.'}
            </Paragraph>
          </div>
          <SelectWorkerSearchModal
            isOpen={isSearchOpen}
            selectedWorker={selectedWorker}
            bookingId={this.props.bookingId}
            startDateTime={this.props.startDateTime}
            endDateTime={this.props.endDateTime}
            address={this.props.address}
            customerUserIds={this.props.customerUserIds}
            serviceId={this.props.serviceId}
            onCloseSearchModal={this._onCloseSearchModal}
            selectWorker={this._onSelectWorker}
            onAssignWorker={this._onAssignWorker}
            isBookingCancelled={isBookingCancelled}
          />
          <table className="width-full">
            <tbody>
              <tr style={{ verticalAlign: 'top' }}>
                <td style={{ width: '45%' }}>
                  <div className="bg-tertiary p-medium mb-large">
                    <Title level={4} className={'mb-x-small'}>
                      Filters
                    </Title>
                    <Text>Show team members who...</Text>
                    <div className="ph-small pv-small">
                      <div>
                        <Switch
                          checked={activeFilters && activeFilters.isAvailable}
                          size="small"
                          className="mr-small"
                          style={{ backgroundColor: activeFilters?.isAvailable ? '#1890FF' : '' }}
                          onChange={(e) => this._onChangeFilter('isAvailable', e)}
                        />
                        <Text size="x-small" lineHeight={200}>
                          Available to work this booking
                        </Text>
                      </div>
                      <div className="mt-12">
                        <Switch
                          checked={activeFilters && activeFilters.isSkillMatched}
                          size="small"
                          className="mr-small"
                          style={{ backgroundColor: activeFilters?.isSkillMatched ? '#1890FF' : '' }}
                          onChange={(e) => this._onChangeFilter('isSkillMatched', e)}
                        />
                        <Text size="x-small" lineHeight={200}>
                          Skill matched for the customer(s) care needs
                        </Text>
                      </div>
                      <div className="mt-12">
                        <Switch
                          checked={activeFilters && activeFilters.previouslyUsed}
                          size="small"
                          className="mr-small"
                          style={{ backgroundColor: activeFilters?.previouslyUsed ? '#1890FF' : '' }}
                          onChange={(e) => this._onChangeFilter('previouslyUsed', e)}
                        />
                        <Text size="x-small" lineHeight={200}>
                          Worked with the customer(s) before
                        </Text>
                      </div>
                      <div className="mt-12">
                        <Switch
                          checked={activeFilters && activeFilters.isCustomerPreferred}
                          size="small"
                          className="mr-small"
                          style={{ backgroundColor: activeFilters?.isCustomerPreferred ? '#1890FF' : '' }}
                          onChange={(e) => this._onChangeFilter('isCustomerPreferred', e)}
                        />
                        <Text size="x-small" lineHeight={200}>
                          Preferred team members by the customer
                        </Text>
                      </div>
                      <FilterSection
                        availableFilters={availableFilters}
                        filters={listFilters}
                        onChangeFilter={this._onChangeListFilter}
                        displayTimezone={null}
                        usePortal={false}
                        verticalDisplay={true}
                      />
                    </div>
                  </div>
                </td>
                <td style={{ width: '32px' }} />
                <td className="p-medium">
                  <div className="mb-x-small">
                    <div className="flex-row justify-between align-center ">
                      <Title level={4}>Team members on roster ({filteredWorkers ? filteredWorkers.length : 0})</Title>
                      <HyperlinkButton onClick={this._onOpenSearchModal}>
                        <Icon type="search" className="mr-x-small" />
                        Find Team member
                      </HyperlinkButton>
                    </div>
                    <div style={{ minHeight: '300px', height: 'calc(100vh - 600px)', overflow: 'auto' }}>
                      {this.state.isLoadingFoundWorkers && <SpinningLoader size={100} message={'Loading'} />}
                      {_.isEmpty(filteredWorkers) && !isLoadingFoundWorkers && (
                        <div className="mt-x2-large text-align-center" style={{ margin: 'auto' }}>
                          <Text weight="bold">No Team member found</Text>
                          <br />
                          <Text>Select a filter or find Team member to select.</Text>
                        </div>
                      )}
                      {filteredWorkers && filteredWorkers.length > 0 && !isLoadingFoundWorkers && (
                        <table className="width-full">
                          <tbody>
                            {_.map(filteredWorkers, (worker) => {
                              return (
                                <FoundWorkerRow
                                  worker={worker}
                                  isSelected={
                                    selectedWorker && worker.supportWorkerId === selectedWorker.supportWorkerId
                                  }
                                  isSearch={false}
                                  selectWorker={this._onSelectWorker}
                                />
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {this.props.bookingType === BookingType.ACTIVITY_RECORD ? (
                      <div className="flex-row justify-end mt-large">
                        <PrimaryButton
                          disabled={!selectedWorker}
                          onClick={
                            !isEditingBooking
                              ? () => this._onAssignWorker(ShiftSlotStatus.CONFIRMED)
                              : () => this._onOpenAddTimesheet()
                          }
                          size="large"
                          className="ml-large"
                        >
                          Assign Team member
                        </PrimaryButton>
                      </div>
                    ) : (
                      <div className="flex-row justify-end mt-large">
                        {!isEditingBooking && isRecurring ? (
                          <PrimaryButton
                            disabled={!selectedWorker}
                            onClick={() => this._checkForConflict()}
                            size="large"
                            className="ml-large"
                          >
                            Assign Team member
                          </PrimaryButton>
                        ) : (
                          <AssignSessionButton
                            selectedWorker={selectedWorker}
                            onAssignWorker={(shiftSlotStatus) => this._onClickAssign(shiftSlotStatus)}
                            isBookingCancelled={isBookingCancelled}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }
    if (this.state.step === 3) {
      return <SpinningLoader size={150} message={'Assigning worker ... '} />;
    }
    if (this.state.step === 4) {
      return (
        <div style={{ minHeight: 317 }} className="flex-colum">
          <div className="flex-column">
            <Paragraph>
              {`You have successfully assigned ${
                selectedWorker.firstName + ' ' + selectedWorker.lastName
              } to this shift${!isShiftStatusPending ? ' as confirmed' : ''}`}
              {isRemovePendingShiftSlots ? ' and removed them from any conflicting shifts' : ''}
              {this.props.isRecurring &&
                this.state.selectedOption === EditRecurringMode.CurrentAll &&
                ', and all following bookings in this recurring series where there were no workers assigned'}
              .
            </Paragraph>
            {isShiftStatusPending && (
              <Paragraph>
                {selectedWorker.firstName + ' ' + selectedWorker.lastName} will have to confirm this shift, or have the
                shift confirmed on her behalf before the booking can be started.
              </Paragraph>
            )}
          </div>
          <div className="text-align-center pt-large pb-x-large">
            <div className="mt-medium">
              <Avatar icon="user" size={120} className="mb-small" shape="square" src={selectedWorker.attachmentUrl} />
              <br />
              <b>
                <Text size="x-large">
                  {selectedWorker.firstName} {selectedWorker.lastName}
                </Text>
              </b>
              <div className="mt-small">
                <WorkerStatusTag
                  shiftSlotStatus={!isShiftStatusPending ? ShiftSlotStatus.CONFIRMED : ShiftSlotStatus.PENDING}
                  size="x-large"
                />
              </div>
            </div>
          </div>

          <div className="text-align-right">
            <PrimaryButton size="large" shape="rounded" onClick={this._onClose}>
              Close
            </PrimaryButton>
          </div>
        </div>
      );
    }
    if (this.state.step === 5) {
      return (
        <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
          <div className="pv-medium flex-column justify-center">
            <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
          </div>

          <div className="mb-medium flex-column justify-center ">
            <Paragraph>Oops something went wrong, please try again.</Paragraph>
          </div>
        </div>
      );
    }
    if (this.state.step === 8) {
      return (
        <>
          <div className="mt-large mb-x2-large">
            <Warning
              content={
                this.state.conflictErrorMessage
                  ? this.state.conflictErrorMessage
                  : 'Oops! Something  went wrong, please try again.'
              }
            />
          </div>
          <div className={'mt-large'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._ChooseNewWorker}>
                Choose another worker
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 6) {
      return (
        <>
          <div className="anim-slide-left">
            <Paragraph>
              The booking you are editing is part of a recurring booking series. Please select one of the following
              options for editing this bookings.
            </Paragraph>
          </div>
          <div>
            <Radio.Group value={this.state.selectedOption} onChange={this._onChangeOption} className="ml-medium">
              <Radio
                value={EditRecurringMode.Current}
                className={`${this.state.selectedOption === EditRecurringMode.Current && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Select {selectedWorker.firstName + ' ' + selectedWorker.lastName} as the worker for this booking only.
                </div>
              </Radio>
              <br />
              <Radio
                value={EditRecurringMode.CurrentAll}
                className={`${
                  this.state.selectedOption === EditRecurringMode.CurrentAll && 'text-weight-bold'
                } mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Select {selectedWorker.firstName + ' ' + selectedWorker.lastName} as the worker for this booking and
                  all following bookings where there is no worker assigned.
                </div>
              </Radio>
              <br />
              {/* KMS-424 : Temporary hide option all upcoming  */}
              {/* <Radio
                value={EditRecurringMode.Upcoming}
                className={`${this.state.selectedOption === EditRecurringMode.Upcoming &&
                  'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Select {selectedWorker.firstName + ' ' + selectedWorker.lastName} as the worker for this booking and
                  all upcoming bookings where there is no worker assigned.
                </div>
              </Radio> */}
            </Radio.Group>
          </div>
          <div className={'mt-large'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" disabled={isLoading} size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                loading={isLoading}
                onClick={this._checkForConflict}
                disabled={
                  ![EditRecurringMode.Current, EditRecurringMode.CurrentAll, EditRecurringMode.Upcoming].includes(
                    this.state.selectedOption,
                  )
                }
              >
                Continue
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 11) {
      const { addTimesheet } = this.state;
      return (
        <>
          <div className="mt-small mb">
            <Paragraph>Do you want to add this activity record to the team members timesheet?</Paragraph>
          </div>
          <div>
            <Row>
              <Radio.Group onChange={this._onChangeAddTimesheet}>
                <Radio value={true}>Add hours to timesheet</Radio>
                <Radio className="mt-x-small" value={false}>
                  <b>Do not</b> add hours to timesheet
                </Radio>
              </Radio.Group>
            </Row>
          </div>
          <div className="mt-medium">
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                loading={isLoading}
                disabled={addTimesheet === null}
                onClick={addTimesheet === true ? this._onOpenEditTimesheet : () => this._onSubmitAssign(true)}
              >
                Finish
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 12) {
      return (
        <>
          <div className="mt-small">
            <Paragraph>
              You have successfully assigned{' '}
              <b>
                {this.state.selectedWorker.firstName} {this.state.selectedWorker.lastName}
              </b>{' '}
              as team member for this {this.props.bookingType === BookingType.BOOKING ? 'booking' : 'activity record'}
              {this.state.shiftSlotStatus === ShiftSlotStatus.CHECKED_OUT &&
                ` and changed the time of this ${
                  this.props.bookingType === BookingType.ACTIVITY_RECORD ? 'activity record' : 'booking'
                }`}
              .
            </Paragraph>
          </div>
        </>
      );
    }
    if (this.state.step === 13) {
      return (
        <>
          <div className="mt-medium">
            <Paragraph>
              In order to add this activity record to the team members timesheet you must indicate a start and finish
              time for the activity.
            </Paragraph>
          </div>
          <div className="mt-medium">
            <SubTitle>ACTIVITY DATE</SubTitle>
            <DatePicker
              onChange={this._onChangeActivityDate}
              selected={moment(this.state.workerTimesheetStartTime).toDate()}
              className="gh-datepicker gh-datepicker-flat rounded"
              calendarClassName="gh-datepicker-calendar"
              dateFormat="dd/MM/yyyy"
              placeholderText={'Select a date'}
            />
          </div>
          <div className="mt-medium">
            <Row type="flex" align="middle" className="mt-medium" gutter={24}>
              <Col span={9}>
                <SubTitle>START TIME </SubTitle>
                <TimeInput
                  size="large"
                  value={moment(this.state.workerTimesheetStartTime)}
                  onChange={this._onChangeActivityStartTime}
                />
              </Col>
              <Col span={9}>
                <SubTitle>END TIME</SubTitle>
                <TimeInput
                  size="large"
                  value={moment(this.state.workerTimesheetEndTime)}
                  onChange={this._onChangeActivityEndTime}
                />
              </Col>
            </Row>
          </div>
          <div className="mt-x3-large">
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" loading={isLoading} onClick={() => this._onSubmitAssign('CHECKED_OUT')}>
                Add to timesheet
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 14) {
      const removalDate = scheduledRemoveConflict ? scheduledRemoveConflict.removedOn : '';
      const sessions = scheduledRemoveConflict
        ? scheduledRemoveConflict.sessions || scheduledRemoveConflict.shiftSlots
        : [];
      const name = selectedWorker ? selectedWorker.firstName + ' ' + selectedWorker.lastName : '';

      return (
        <>
          <Paragraph>
            The selected team member is unavailable for some booking you are attempting to assign them to. The team
            member will not be assigned to the following
          </Paragraph>
          <div
            className="p-medium bordered border-standard-gray rounded-big bg-quaternary line-height-135 flex-column"
            style={{ maxHeight: '300px', overflow: 'auto' }}
          >
            <div className="flex-row">
              <div className="bg-red-lightest text-color-red text-weight-bold">
                TEAM MEMBER SCHEDULED TO LEAVE COMPANY
              </div>
              <div className="ml-large flex-row">
                <Avatar icon="user" size={25} shape="square" src={selectedWorker.attachmentUrl} />
                <b className="ml-small">
                  <Text className="text-color-tertiary text-size-regular">{name}</Text>
                </b>
              </div>
            </div>
            <Paragraph>
              <Text weight="bold">{name}</Text> is scheduled to leave the company on{' '}
              {moment.tz(removalDate, displayTimezone).format('Do of MMMM')} and will not be assigned to the following{' '}
              <Text weight="bold">
                {sessions.length} booking{sessions.length > 1 ? 's' : ''}
              </Text>
            </Paragraph>
            <HyperlinkButton onClick={this._showUnavailableBookings}>
              {this.state.showUnavailableBookings ? 'Hide bookings...' : 'Show bookings...'}
            </HyperlinkButton>
            {this.state.showUnavailableBookings && (
              <div className="mt-large">
                {sessions.map((session) => (
                  <Paragraph key={session.serviceDateTimeId}>
                    <Text weight="bold">
                      {moment.tz(session.startDateTime, displayTimezone).format('DD MMMM YYYY')}
                    </Text>
                    <Text className="ml-small">
                      {moment.tz(session.startDateTime, displayTimezone).format('HH:mm A')} -{' '}
                      {moment.tz(session.endDateTime, displayTimezone).format('HH:mm A')}
                    </Text>
                  </Paragraph>
                ))}
              </div>
            )}
          </div>
          <div className="mt-x3-large">
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                className="ml-small"
                onClick={() => this._onSubmitAssign(!isShiftStatusPending)}
              >
                Continue
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 15) {
      const removalDate = scheduledRemoveConflict ? scheduledRemoveConflict.removedOn : '';
      const name = selectedWorker ? selectedWorker.firstName + ' ' + selectedWorker.lastName : '';
      const bookings = scheduledRemoveConflict.sessions || scheduledRemoveConflict.shiftSlots || [];

      return (
        <>
          <Paragraph>
            The selected team member, <Text weight="bold">{name}</Text>, is scheduled to be remove on or before the
            scheduled booking time
          </Paragraph>
          <Paragraph className="mt-small">As a result {name} will not be assigned to this booking</Paragraph>
          <div className="mb-x2-small">
            <FieldLabel text={'TEAM MEMBER SCHEDULE TO BE REMOVE ON...'} />
          </div>
          <Paragraph>{moment.tz(removalDate, displayTimezone).format('DD MMMM YYYY')}</Paragraph>

          <div className="mb-x2-small">
            <FieldLabel text={'BOOKING DATE'} />
          </div>
          <div className="flex-column">
            <Text>{moment.tz(bookings[0].startDateTime, displayTimezone).format('DD MMMM YYYY')}</Text>
            <Text>
              {moment.tz(bookings[0].startDateTime, displayTimezone).format('HH:mm A')} -{' '}
              {moment.tz(bookings[1].endDateTime, displayTimezone).format('HH:mm A')}
            </Text>
          </div>
          <div className="mt-x3-large">
            <Row type={'flex'} justify={'end'}>
              <PrimaryButton size="large" onClick={this._onClose}>
                Confirm
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 16) {
      const { shiftsOutsideGeneralAvailability, shiftSlotStatus } = this.state;

      return (
        <>
          <Paragraph>
            The new session time conflicts with the team member’s <Text weight="bold">General availability</Text>.
            Please select which shifts (if any) you wish to keep for the team member or continue without selecting any.
          </Paragraph>
          <div className="bg-quaternary pv-x-large ph-12 mt-x-large">
            <AvailabilityConflictTimes
              conflicts={shiftsOutsideGeneralAvailability}
              timezone={displayTimezone}
              onSelectShifts={this._onChangeSelectedShiftToBeKept}
              shiftSlotStatus={shiftSlotStatus}
            />
          </div>
          <div className="mt-x3-large">
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                className="ml-small"
                onClick={() => (!isEditingBooking && isRecurring ? this._onAssignWorker() : this._onSubmitAssign())}
              >
                Continue and assign to selected shifts
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 17) {
      const totalShiftClashes = CommonUtils.calculateTotalShiftClashes(shiftClashConflicts);
      let description = (
        <Paragraph>
          This team member is assigned to{' '}
          <b>
            {totalShiftClashes} shift{totalShiftClashes > 1 ? 's ' : ' '}
          </b>
          that conflict{totalShiftClashes === 1 ? 's' : ''} with this booking time. Be sure to double-check their
          availability before assigning.
        </Paragraph>
      );
      let firstColumnTitle = 'Booking being assigned';
      let okButtonText = 'Assign anyway';
      let hasActionButton = false;
      let hasKeepInShift = false;
      if (
        isRecurring &&
        (this.state.selectedOption === null || this.state.selectedOption !== EditRecurringMode.Current)
      ) {
        description = (
          <Paragraph>
            This team member is assigned to shifts that conflict with sessions in this recurring series. Choose whether
            you’d like to keep them in the following bookings.
          </Paragraph>
        );
        firstColumnTitle = 'Bookings';
        okButtonText = 'Confirm';
        hasActionButton = true;
        hasKeepInShift = true;
      }

      return (
        <ShiftClashContent
          shiftClashConflicts={shiftClashConflicts}
          description={description}
          firstColumnTitle={firstColumnTitle}
          okButtonText={okButtonText}
          hasKeepInShift={hasKeepInShift}
          hasActionButton={hasActionButton}
          isLoading={isLoading}
          onCancel={() => {
            this.setState({
              error: null,
              selectedWorker: null,
              step: 2,
            });
          }}
          onOk={this._onSubmitShiftClash}
        />
      );
    }
  };

  render() {
    const { isOpen, bookingType } = this.props;
    const { shiftSlotStatus } = this.state;

    return (
      <ActionModal
        title={
          this.state.step === 14 || this.state.step === 16
            ? 'Team member unavailable for bookings'
            : this.state.step === 4 && shiftSlotStatus === ShiftSlotStatus.CONFIRMED
            ? 'Team member assigned and confirmed'
            : this.state.step === 4
            ? 'Team member assigned'
            : this.state.step === 7
            ? 'Team member assigned to conflicting shift(s)'
            : this.state.step === 6
            ? 'Select Workers'
            : this.state.step === 11
            ? 'Add hours to timesheet'
            : this.state.step === 12
            ? 'Team member assigned'
            : this.state.step === 13
            ? 'Edit activity record'
            : bookingType === BookingType.ACTIVITY_RECORD && this.state.step === 2
            ? 'Select team member for this activity record'
            : this.state.step === 17
            ? 'Hold on, this team member is already working'
            : 'Select team member for this booking'
        }
        isOpen={isOpen}
        width={
          this.state.step === 4 || this.state.step === 7
            ? 'large'
            : this.state.step === 11 || this.state.step === 12
            ? 'small'
            : this.state.step === 13
            ? 'medium'
            : this.state.step === 17
            ? 'x-large'
            : 'x3-large'
        }
        onClose={this._onClose}
      >
        <div>{this.renderContent()}</div>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServiceRoster: dispatch.bookingsStore.doFetchServiceRoster,
  doCheckWorker: dispatch.bookingsStore.doCheckWorker,
  doCheckAssignWorker: dispatch.bookingsStore.doCheckAssignWorker,
});

export default connect(null, mapDispatch)(Form.create<IBookingAssignWorkerModalProps>()(BookingAssignWorkerModal));
