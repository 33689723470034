import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const GpsSlash = forwardRef(function GpsSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25a.75.75 0 0 1 .75.75v1.282a8.752 8.752 0 0 1 7.968 7.968H22a.75.75 0 0 1 0 1.5h-1.282a8.707 8.707 0 0 1-.604 2.531.75.75 0 1 1-1.39-.562c.339-.839.526-1.756.526-2.719a7.25 7.25 0 0 0-9.969-6.723.75.75 0 1 1-.562-1.39 8.687 8.687 0 0 1 2.531-.605V2a.75.75 0 0 1 .75-.75ZM3.53 2.47a.75.75 0 0 0-1.06 1.06l2.835 2.836a8.715 8.715 0 0 0-2.023 4.884H2a.75.75 0 0 0 0 1.5h1.282a8.752 8.752 0 0 0 7.968 7.968V22a.75.75 0 0 0 1.5 0v-1.282a8.715 8.715 0 0 0 4.884-2.023l2.836 2.835a.75.75 0 1 0 1.06-1.06l-3.343-3.343L6.873 5.813 3.53 2.47ZM12 19.25a7.217 7.217 0 0 0 4.569-1.62l-2.501-2.501a3.75 3.75 0 0 1-5.197-5.197l-2.5-2.5A7.25 7.25 0 0 0 12 19.25Zm.97-5.22-3-3a2.25 2.25 0 0 0 3.001 3.001Z"
        fill="currentColor"
      />
    </Icon>
  );
});
