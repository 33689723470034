import { SubscriptionStatus } from 'utilities/enum-utils';

export const SUBSCRIPTION_MANAGEMENT_TAB_KEY = {
  OVERVIEW: 'OVERVIEW',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  BILLING_HISTORY: 'BILLING_HISTORY',
};

export const SUBSCRIPTION_MANAGEMENT_TABS = [
  {
    title: 'Overview',
    key: SUBSCRIPTION_MANAGEMENT_TAB_KEY.OVERVIEW,
  },
  {
    title: 'Payment method',
    key: SUBSCRIPTION_MANAGEMENT_TAB_KEY.PAYMENT_METHOD,
  },
  {
    title: 'Billing history',
    key: SUBSCRIPTION_MANAGEMENT_TAB_KEY.BILLING_HISTORY,
  },
];

export const YEAR_INCREASE = 20;

export const EDIT_CARD_FORM_ELEMENT = {
  EXPIRY_MONTH: 'expiryMonth',
  EXPIRY_YEAR: 'expiryYear',
};

export const ADD_BILLING_ACCESS_FORM_ELEMENT = {
  USER_IDS: 'userIds',
};

export enum COMMON_MODAL_ACTION {
  REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD',
  REMOVE_SUCCESS = 'REMOVE_SUCCESS',
  SET_DEFAULT_PAYMENT_METHOD = 'SET_DEFAULT_PAYMENT_METHOD',
  ADD_DEFAULT_PAYMENT_METHOD = 'ADD_DEFAULT_PAYMENT_METHOD',
}

export enum SUPPORT_CARDS {
  AMERICAN_EXPRESS = 'amex',
  MASTER_CARD = 'mastercard',
  VISA = 'visa',
  JCB = 'jcb',
  UNION_PAY = 'unionpay',
}

export interface IActionModalOptions {
  title: string;
  description: string;
  actions: string[];
}

export interface IActionModalExtensions {
  [key: string]: IActionModalOptions;
}

export enum ACTION_BUTTON {
  CLOSE = 'CLOSE',
  CANCEL = 'CANCEL',
  REMOVE = 'REMOVE',
}

export const ActionModalExtensions: IActionModalExtensions = {
  [COMMON_MODAL_ACTION.REMOVE_PAYMENT_METHOD]: {
    title: 'Remove payment method',
    description:
      'This card will no longer be used to pay your GoodHuman subscription. If you change your mind, you can always add it again later.',
    actions: [ACTION_BUTTON.CANCEL, ACTION_BUTTON.REMOVE],
  },
  [COMMON_MODAL_ACTION.REMOVE_SUCCESS]: {
    title: 'Payment method removed',
    description: 'Your payment method has successfully been removed.',
    actions: [ACTION_BUTTON.CLOSE],
  },
  [COMMON_MODAL_ACTION.SET_DEFAULT_PAYMENT_METHOD]: {
    title: 'Set a new default payment method to continue',
    description:
      'You cannot remove a default payment method. Please set a new default payment method before removing this card.',
    actions: [ACTION_BUTTON.CLOSE],
  },
  [COMMON_MODAL_ACTION.ADD_DEFAULT_PAYMENT_METHOD]: {
    title: 'Add a new default payment method to continue',
    description:
      'This card cannot be removed as it is the only payment method. Please add a new default payment method to remove this card.',
    actions: [ACTION_BUTTON.CLOSE],
  },
};

export const StatusTagExtensions = {
  [SubscriptionStatus.TRIALING]: {
    label: 'FREE TRIAL ACTIVE',
    color: 'forest',
    bgColor: '',
    borderColor: 'forest',
    labelDetail: 'ACTIVE',
    statusLabel: 'Free trial',
  },
  [SubscriptionStatus.ACTIVE]: {
    label: 'SUBSCRIPTION ACTIVE',
    color: 'forest',
    bgColor: '',
    borderColor: 'forest',
    labelDetail: 'ACTIVE',
    statusLabel: 'Full access',
  },
  [SubscriptionStatus.CANCELED]: {
    label: 'CANCELLED',
    color: 'red-dark',
    bgColor: '',
    borderColor: 'red-dark',
    labelDetail: 'CANCELLED',
    statusLabel: 'Cancelled',
  },
  [SubscriptionStatus.PAUSED]: {
    label: 'CANCELLED',
    color: 'red-dark',
    bgColor: '',
    borderColor: 'red-dark',
    labelDetail: 'INACTIVE',
    statusLabel: 'Cancelled',
  },
  [SubscriptionStatus.NON_PAYG]: {
    label: 'ACTIVE',
    color: 'forest',
    bgColor: '',
    borderColor: 'forest',
    labelDetail: 'ACTIVE',
    statusLabel: 'ACTIVE',
  },
};

export const BILLING_TABLE_HEADER = [
  {
    key: 'date',
    name: 'Date',
    span: 4,
  },
  {
    key: 'invoice',
    name: 'Invoice',
    span: 7,
  },
  {
    key: 'amount',
    name: 'Amount',
    span: 6,
  },
  {
    key: 'paid',
    name: 'Paid',
    span: 4,
  },
  {
    key: 'download',
    name: 'Download',
    span: 3,
  },
];

export interface IFeedbackSurveyState {
  selectedReasons?: string[];
  noteMissingFunctionalities?: string;
  otherNote?: string;
  additionalNote?: string;
}

export const REASON_CANCELLATION_LIST = [
  {
    label: 'Too difficult to use',
    field: 'isTooDifficultToUse',
  },
  {
    label: 'Too expensive',
    field: 'isTooExpensive',
  },
  {
    label: 'Didn’t have time to get set up',
    field: 'isNotHaveTimeToGetSetUp',
  },
  {
    label: 'Missing functionalities',
    field: 'isMissingFunctionalities',
    subField: 'noteMissingFunctionalities',
  },
  {
    label: 'Using other product',
    field: 'isUsingOtherProduct',
  },
  {
    label: 'This is temporary, I’ll be back!',
    field: 'isTemporary',
  },
  {
    label: 'Something else',
    field: 'isOtherReason',
    subField: 'otherNote',
  },
];

export const CANCEL_SUBSCRIPTION_STEP = {
  START_CANCEL: 'START_CANCEL',
  FEEDBACK_SURVEY: 'FEEDBACK_SURVEY',
};

export const CANCEL_SUBSCRIPTION_LINKS = {
  GET_SUPPORT: 'https://goodhuman.zendesk.com/hc/en-au/requests/new',
  EXPORT_DATA_LINK: 'https://goodhuman.zendesk.com/hc/en-au/articles/900005890506-Exporting-workspace-data',
  SUPPORT_MAIL_LINK: 'support@goodhuman.me',
  SALES_MAIL_LINK: 'sales@goodhuman.me',
};

export const MAIL_SUBJECT_HEADERS = {
  RESUME: 'Resume my GoodHuman subscription',
  REACTIVATE: 'Reactivate my GoodHuman subscription',
};
