import React, { Component } from 'react';
import { Row, Col, Icon, Radio, Form } from 'antd';
import { Text, Paragraph } from 'common-components/typography';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import _ from 'lodash';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import Search from 'antd/es/input/Search';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { InfiniteScroll } from 'components';

interface IAddVCPLineItemModalProps {
  closeLineItemModal: () => void;
  isOpen: boolean;
  onChangeSupportItemNumber: (value: string, isExternalVCPLineItem: boolean) => void;
  vcpItems: typeof state.servicesStore.vcpItems;
  doGetVCPItems: typeof dispatch.servicesStore.doGetVCPItems;
}

interface IAddVCPLineItemModalState {
  isLoadingFilter: boolean;
  isActionModalOpen: boolean;
  selectedLineItem: any;
  isProceedOpen: boolean;
  isFilterOpen: boolean;
  searchFilter: string;
  isLoadingList: boolean;
  page: number;
  pageSize: number;
  billingLineItemList: any;
}

class AddVCPLineItemModal extends Component<IAddVCPLineItemModalProps, IAddVCPLineItemModalState> {
  state = {
    isLoadingFilter: false,
    isActionModalOpen: false,
    selectedLineItem: null,
    isProceedOpen: false,
    isFilterOpen: false,
    searchFilter: null,
    isLoadingList: true,
    page: 1,
    pageSize: 10,
    billingLineItemList: [],
  };

  private _saveLineItem = async () => {
    let isFormValid = true;

    if (!this.state.selectedLineItem) {
      isFormValid = false;
    }

    if (isFormValid) {
      this.setState({
        isActionModalOpen: false,
        selectedLineItem: null,
      });
      this.props.onChangeSupportItemNumber(this.state.selectedLineItem, true);
      this._closeLineItemModal();
    }
  };

  private _closeLineItemCheckModal = () => {
    const { selectedLineItem } = this.state;

    if (selectedLineItem) {
      this.setState({ isProceedOpen: true });
    } else {
      this._closeLineItemModal();
    }
  };

  private _closeLineItemModal = () => {
    this.setState({ isProceedOpen: false, selectedLineItem: null });
    this.props.closeLineItemModal();
  };

  private _onChangeSupportItemNumber = (event) => {
    this.setState({ selectedLineItem: event });
  };

  private _onEnterSearchText = async (e) => {
    const txt = e.target.value;
    this.setState({ isLoadingList: true, searchFilter: txt }, () => this._debounceSearch(txt));
  };

  private _searchText = async (txt) => {
    await this.props.doGetVCPItems({ searchString: txt });
    this.setState({ isLoadingList: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _closeProceedModal = () => {
    this.setState({ isProceedOpen: false });
  };

  // TODO: See if we want a lazy loading or not
  private _fetchMoreLineItem = async () => {
    this.setState({ isLoadingList: true, page: this.state.page + 1 });
    // const newBillingLineItemList = this.state.billingLineItemList.concat(
    //   ndisHelper.getLineItems(
    //     this.state.page,
    //     this.state.pageSize,
    //     this.state.searchFilter,
    //     this.state.categoriesFilter
    //   )
    // );
    this.setState({
      // billingLineItemList: newBillingLineItemList,
      isLoadingList: false,
    });
  };

  componentDidMount = async () => {
    this.setState({ isLoadingList: true });
    await this.props.doGetVCPItems({});
    this.setState({ isLoadingList: false });
  };

  render() {
    const { vcpItems } = this.props;
    const { isLoadingList, searchFilter, page, pageSize, selectedLineItem, billingLineItemList } = this.state;

    return (
      <div>
        <ActionModal
          isOpen={this.state.isProceedOpen}
          onClose={this._closeProceedModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className='mr-medium' size='large' onClick={this._closeProceedModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size='large' onClick={this._closeLineItemModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          title={
            <>
              Add new <b>VCP Line Item</b>
            </>
          }
          onClose={this._closeLineItemCheckModal}
        >
          <div className='anim-slide-left'>
            <SpinningLoadingActionModel isOpen={this.state.isLoadingFilter} verticalAlignment={'highest'} />
            <Paragraph>Please select a VCP Line Item to be added to the Service.</Paragraph>

            <Row type={'flex'} align={'middle'}>
              <Search
                onChange={this._onEnterSearchText}
                loading={isLoadingList}
                placeholder='Search for a line item...'
                allowClear
                style={{ width: '350px' }}
                value={searchFilter}
              />
            </Row>

            <Row style={{ height: '350px', overflow: 'auto' }} className={'bordered mt-small'} id={'scroll'}>
              {isLoadingList ? (
                <SpinningLoader size={50} message={'Retrieving line items..'} />
              ) : (
                <InfiniteScroll hasMore={vcpItems.length >= page * pageSize} loadMore={this._fetchMoreLineItem}>
                  {_.map(vcpItems, (lineItem, key) => {
                    const isChecked =
                      selectedLineItem && selectedLineItem.supportItemNumber === lineItem.supportItemNumber;
                    const isDisabled = false;
                    return (
                      <Row
                        className={`pv-small bordered-bottom ${
                          isDisabled ? 'bg-secondary' : 'hover-bg-tertiary cursor-pointer'
                        } ${isChecked && 'bg-blue-lightest'}`}
                        key={key}
                        onClick={() => (!isDisabled ? this._onChangeSupportItemNumber(lineItem) : false)}
                      >
                        <Col span={2} className={'text-align-center'}>
                          {!isDisabled && <Radio checked={isChecked} value={lineItem.supportItemNumber} />}
                        </Col>
                        <Col span={22}>
                          {lineItem.supportItemNumber}{' '}
                          {isDisabled && <Text weight={'bold'}>(Cannot change item to this type.)</Text>}
                          <br />
                          <Text size={'regular'}>{lineItem.supportItemName}</Text>
                        </Col>
                      </Row>
                    );
                  })}
                </InfiniteScroll>
              )}
            </Row>
            <div className={'mb-small mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <Col>
                  <GhostButton size={'large'} onClick={this._closeLineItemCheckModal}>
                    Cancel
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton size={'large'} disabled={!selectedLineItem} onClick={this._saveLineItem}>
                    Add Line Item
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  vcpItems: state.servicesStore.vcpItems,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetVCPItems: dispatch.servicesStore.doGetVCPItems,
});

export default connect(mapState, mapDispatch)(AddVCPLineItemModal);
