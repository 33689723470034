import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { Checkbox, Col, Form, Icon, Row, Tag } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { tagColors } from 'theme/theme-variables';
import _ from 'lodash';
import SpinningLoader from 'common-components/loading/SpinningLoader';

interface IViewServicesInDepartmentModalProps {
  isOpen: any;
  onClose: any;
  selectedServiceDepartment: any;
  doFetchServiceDepartmentDetails: typeof dispatch.servicesStore.doFetchServiceDepartmentDetails;
}

interface IViewServicesInDepartmentModalState {
  serviceDepartment: any;
  isLoading: boolean;
}

class ViewServicesInDepartmentModal extends Component<
  IViewServicesInDepartmentModalProps,
  IViewServicesInDepartmentModalState
> {
  state = {
    serviceDepartment: null,
    isLoading: false,
  };

  componentDidUpdate = async (
    prevProps: Readonly<IViewServicesInDepartmentModalProps>,
    prevState: Readonly<IViewServicesInDepartmentModalState>,
    snapshot?: any,
  ) => {
    if (prevProps.selectedServiceDepartment !== this.props.selectedServiceDepartment) {
      this.setState({ isLoading: true });
      const result: any = await this.props.doFetchServiceDepartmentDetails({
        serviceDepartmentId: this.props.selectedServiceDepartment.serviceDepartmentId,
      });
      this.setState({
        serviceDepartment: result ? result.data : [],
        isLoading: false,
      });
    }
  };

  render() {
    const { isOpen, selectedServiceDepartment } = this.props;
    const services = this.state.serviceDepartment ? this.state.serviceDepartment.services : [];

    return (
      <ActionModal
        title={selectedServiceDepartment ? selectedServiceDepartment.serviceDepartmentName : ''}
        isOpen={isOpen}
        onClose={this.props.onClose}
        width="x-large"
      >
        <div className="mv-medium anim-slide-left">
          <div>
            <Paragraph>
              View all services in the {selectedServiceDepartment ? selectedServiceDepartment.departmentName : ''}{' '}
              department.
            </Paragraph>
          </div>

          <div className="bordered-bottom pb-medium">
            <Row className="mt-small">
              <Col span={12}>
                <b>Service name</b>
              </Col>
              <Col span={6}>
                <b>Staff on roster</b>
              </Col>
              <Col span={6}>
                <b>Status</b>
              </Col>
            </Row>
          </div>
          <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '45vh' }} className="mb-large">
            {this.state.isLoading ? (
              <SpinningLoader size={100} message={'Fetching services...'} />
            ) : services && services.length > 0 ? (
              _.map(services, (service, key) => {
                return (
                  <Row key={key} className="bordered-top bordered-bottom pv-small">
                    <Col span={12} title={service.serviceName} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {service.serviceName}
                    </Col>
                    <Col span={6}>{service.staffOnRoster}</Col>
                    <Col span={6}>
                      <Tag
                        color={
                          service.status === 'DRAFT'
                            ? tagColors.ORANGE
                            : service.status === 'PUBLISHED'
                            ? tagColors.GREEN
                            : tagColors.COBALT
                        }
                      >
                        {service.status}
                      </Tag>
                    </Col>
                  </Row>
                );
              })
            ) : (
              <Row className="bordered-top bordered-bottom pv-small">
                <Col span={24}>No services assigned to this department.</Col>
              </Row>
            )}
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.props.onClose}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServiceDepartmentDetails: dispatch.servicesStore.doFetchServiceDepartmentDetails,
});

export default connect(null, mapDispatch)(ViewServicesInDepartmentModal);
