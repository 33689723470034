import React from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { PrimaryButton, GhostButton } from 'common-components/buttons';

interface IDiscardChangesModalProps {
  isOpen: boolean;
  onProceed: () => void;
  onCancel: () => void;
  [restProps: string]: any;
}

const DiscardChangesModal = ({ isOpen, onCancel, onProceed, ...restProps }: IDiscardChangesModalProps) => {
  return (
    <ActionModal isOpen={isOpen} onClose={onCancel} title={'Discard changes'} showCloseButton={true} {...restProps}>
      <Text className={'mb-medium'}>
        You have <b>unsaved data</b>, proceeding will discard these changes.
      </Text>
      <br />
      <Text className={'mb-medium'}>Do you want to proceed?</Text>

      <ActionModalFooter>
        <PrimaryButton className="mr-medium" size="large" onClick={onCancel}>
          Cancel
        </PrimaryButton>

        <GhostButton size="large" onClick={onProceed}>
          Proceed
        </GhostButton>
      </ActionModalFooter>
    </ActionModal>
  );
};
export default DiscardChangesModal;
