import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Grid4Mixed = forwardRef(function Grid4Mixed(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2.25h-.052c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.08.595-.08 1.345-.08 2.243v10.104c0 .899 0 1.648.08 2.242.084.628.27 1.195.726 1.65.455.456 1.022.642 1.65.726.594.08 1.344.08 2.242.08h10.104c.899 0 1.648 0 2.243-.08.627-.084 1.194-.27 1.65-.726.455-.455.64-1.022.725-1.65.08-.594.08-1.343.08-2.242V6.948c0-.898 0-1.648-.08-2.242-.084-.628-.27-1.195-.725-1.65-.456-.456-1.023-.642-1.65-.726-.595-.08-1.344-.08-2.243-.08H7Zm13.25 12v-4.5H9.75v4.5h10.5Zm-10.5 1.5h10.5V17c0 .964-.002 1.612-.066 2.095-.063.461-.17.659-.3.789-.13.13-.328.237-.79.3-.482.064-1.13.066-2.094.066H9.75v-4.5ZM8.25 15V9.75h-4.5V17c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.789.3.483.064 1.13.066 2.094.066h1.25V15Zm-4.5-6.75h16.5V7c0-.964-.002-1.612-.066-2.095-.063-.461-.17-.659-.3-.789-.13-.13-.328-.237-.79-.3-.482-.064-1.13-.066-2.094-.066H7c-.964 0-1.611.002-2.094.067-.462.062-.66.169-.79.3-.13.13-.237.327-.3.788C3.753 5.388 3.75 6.036 3.75 7v1.25Z"
        fill="currentColor"
      />
    </Icon>
  );
});
