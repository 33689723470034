import React, { useEffect, useRef } from 'react';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { NumberInput, NumberInputProps } from '@good/ui/core';

import { useInlineForm } from './inline-form-context';
import { StaticField } from './static-field';
import { OptionalLabel } from './optional-label';

export type InlineNumberFieldProps<T extends FieldValues> = {
  children?: React.ReactNode;
  emptyValue?: string;
  label?: string;
  maxInputWidth?: React.CSSProperties['maxWidth'];
  name: Path<T>;
  rules?: Omit<RegisterOptions<T, Path<T>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  showOptional?: boolean;
  staticLabel?: string;
} & Omit<NumberInputProps, 'name' | 'label'>;

export const InlineNumberField = <T extends FieldValues>(props: InlineNumberFieldProps<T>) => {
  const { control, watch, setValue } = useFormContext<T>();
  const { isEditing, isSubmitting, maxWidth } = useInlineForm();
  const inputRef = useRef<HTMLDivElement | null>(null);
  const {
    children,
    disabled,
    emptyValue,
    label,
    max,
    maxInputWidth,
    min,
    name,
    rules,
    showOptional,
    staticLabel,
    ...numberFieldProps
  } = props;
  const enteredValue = watch(name);

  useEffect(() => {
    // @ts-expect-error - for some reason PathValue<T, Path<T>> type cannot be cast to number in a generic context without dropping protection for the name property which is ultimitely more important
    if (min && enteredValue < min) setValue(name, min);
    // @ts-expect-error - same as above
    if (max && enteredValue > max) setValue(name, max);
  }, [enteredValue, max, min, name, setValue]);

  return (
    <div className='flex flex-col gap-2'>
      <Controller
        name={name}
        control={control}
        rules={{ ...rules, min, max }}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
          return isEditing ? (
            <>
              <NumberInput
                onChange={(value: string | number) => {
                  if (value === '') {
                    onChange(null);
                    return;
                  }
                  onChange(Number(value));
                }}
                onBlur={onBlur}
                value={value}
                ref={(ref_) => {
                  ref(ref_);
                  inputRef.current = ref_;
                }}
                disabled={isSubmitting || disabled}
                error={error?.message}
                style={{ maxWidth }}
                label={label ? <OptionalLabel label={label} optional={showOptional} /> : undefined}
                inputContainer={(children) => <div style={{ maxWidth: maxInputWidth }}>{children}</div>}
                min={min}
                max={max}
                {...numberFieldProps}
              />
            </>
          ) : (
            <StaticField label={staticLabel ?? label} value={value} emptyValue={emptyValue} />
          );
        }}
      />
      {children}
    </div>
  );
};
