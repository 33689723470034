import { Popover } from '@blueprintjs/core';
import { Icon, Row } from 'antd';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { SecondaryButton } from 'common-components/buttons';
import { SubTitle, Text, Title } from 'common-components/typography';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { WorkflowTemplateStatus } from 'utilities/enum-utils';
import WorkflowTemplateActionModal from './WorkflowTemplateActionModal';

const dropdownWorkflowActionsLabel = {
  [WorkflowTemplateStatus.ACTIVE]: 'De-activate workflow',
  [WorkflowTemplateStatus.INACTIVE]: 'Activate workflow',
};

const workflowTemplateStatusLabel = {
  [WorkflowTemplateStatus.ACTIVE]: 'Active',
  [WorkflowTemplateStatus.INACTIVE]: 'Inactive',
  [WorkflowTemplateStatus.ARCHIVED]: 'Archive',
};

interface IWorkflowTemplateDetailHeaderState {
  isOpenWorkflowTemplateActionModal: boolean;
}

interface IWorkflowTemplateDetailHeaderProps {
  disabledWorkflowAction: boolean;
  selectedWorkflowTemplate: typeof state.workflowStore.selectedWorkflowTemplate;
  doUpdateWorkflowTemplateConfigurations: typeof dispatch.workflowStore.doUpdateWorkflowTemplateConfigurations;
}

class WorkflowTemplateDetailHeader extends PureComponent<
  IWorkflowTemplateDetailHeaderProps,
  IWorkflowTemplateDetailHeaderState
> {
  state = {
    isOpenWorkflowTemplateActionModal: false,
  };

  private _onOpenWorkflowTemplateActionModal = () => {
    this.setState({ isOpenWorkflowTemplateActionModal: true });
  };

  private _onCloseWorkflowTemplateActionModal = () => {
    this.setState({ isOpenWorkflowTemplateActionModal: false });
  };

  render() {
    const { isOpenWorkflowTemplateActionModal } = this.state;
    const { selectedWorkflowTemplate, disabledWorkflowAction } = this.props;
    const menuWorkflowActions = selectedWorkflowTemplate && (
      <ActionMenu>
        <ActionMenuItem
          text={dropdownWorkflowActionsLabel[selectedWorkflowTemplate.status]}
          onClick={this._onOpenWorkflowTemplateActionModal}
        />
      </ActionMenu>
    );

    if (!selectedWorkflowTemplate) {
      return <></>;
    }

    return (
      <>
        <WorkflowTemplateActionModal
          isOpen={isOpenWorkflowTemplateActionModal}
          onClose={this._onCloseWorkflowTemplateActionModal}
        />
        <div className="height-full bg-white p-large">
          <SubTitle color="tertiary" size="small" weight="bolder">
            Workflow details
          </SubTitle>

          <div className="flex-row justify-between mv-x-small align-center">
            <Title level={2} weight="bolder">
              {selectedWorkflowTemplate.name}
            </Title>

            <Popover content={menuWorkflowActions} position={'bottom'}>
              <SecondaryButton size="large" disabled={disabledWorkflowAction}>
                Workflow actions...
              </SecondaryButton>
            </Popover>
          </div>

          <Row>
            <Text size="large">Version {selectedWorkflowTemplate.version}</Text>

            <Text
              size="large"
              className="ml-medium"
              color={
                selectedWorkflowTemplate && selectedWorkflowTemplate.status === WorkflowTemplateStatus.INACTIVE
                  ? 'red'
                  : 'green-dark'
              }
            >
              <Icon
                type={
                  selectedWorkflowTemplate && selectedWorkflowTemplate.status === WorkflowTemplateStatus.INACTIVE
                    ? 'close-circle'
                    : 'check'
                }
                theme={'outlined'}
                className="mr-x-small green-dark"
              />
              {workflowTemplateStatusLabel[selectedWorkflowTemplate.status]}
            </Text>
          </Row>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedWorkflowTemplate: state.workflowStore.selectedWorkflowTemplate,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateWorkflowTemplateConfigurations: dispatch.workflowStore.doUpdateWorkflowTemplateConfigurations,
});

export default connect(mapState, mapDispatch)(WorkflowTemplateDetailHeader);
