import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Iframe = forwardRef(function Iframe(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2.25h-.052c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.08.595-.08 1.345-.08 2.243v10.34c0 .576.004 1.087.034 1.532.011.167.026.326.046.474.084.628.27 1.195.726 1.65.455.456 1.022.642 1.65.726.594.08 1.344.08 2.242.08h10.104c.899 0 1.648 0 2.243-.08.627-.084 1.194-.27 1.65-.726.455-.455.64-1.022.725-1.65.08-.594.08-1.343.08-2.242V6.948c0-.898 0-1.648-.08-2.242-.084-.628-.27-1.195-.725-1.65-.456-.456-1.023-.642-1.65-.726-.595-.08-1.344-.08-2.243-.08H7Zm4.418 18H17c.964 0 1.612-.002 2.095-.067.461-.062.659-.169.789-.3.13-.13.238-.327.3-.788.064-.483.066-1.131.066-2.095V7c0-.964-.002-1.612-.066-2.095-.062-.461-.17-.659-.3-.789-.13-.13-.328-.237-.79-.3-.482-.064-1.13-.066-2.094-.066H7c-.964 0-1.611.002-2.094.067-.462.062-.66.169-.79.3-.13.13-.237.327-.3.788C3.753 5.388 3.75 6.036 3.75 7v5.582c.296-.131.617-.207.956-.252.594-.08 1.344-.08 2.242-.08h.104c.899 0 1.648 0 2.243.08.627.084 1.194.27 1.65.726.455.455.64 1.022.725 1.65.08.594.08 1.343.08 2.242v.104c0 .899 0 1.648-.08 2.242-.045.34-.12.66-.252.956ZM3.75 16.965V17c0 .843.001 1.445.045 1.905.006.066.014.13.022.19.046.346.118.543.206.675.03.044.06.081.093.114.122.122.304.224.706.287.027.005.055.009.084.012.452.061 1.05.067 1.917.067H7c.964 0 1.612-.002 2.095-.067.461-.062.659-.169.789-.3.13-.13.238-.327.3-.788.065-.483.066-1.131.066-2.095 0-.964-.002-1.612-.066-2.095-.062-.461-.17-.659-.3-.789-.13-.13-.328-.237-.79-.3-.482-.064-1.13-.066-2.094-.066s-1.611.002-2.094.066c-.462.063-.66.17-.79.3-.13.13-.237.328-.3.79-.063.476-.066 1.114-.066 2.059Z"
        fill="currentColor"
      />
    </Icon>
  );
});
