import React, { Component } from 'react';
import _ from 'lodash';
import { Text, Title, Paragraph } from 'common-components/typography';
import { ICustomer } from 'interfaces/customer-interfaces';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import FundingEditCategoriesPanel from 'views/customers/details/tabs-panel/funding/components/FundingEditCategoriesPanel';

interface IFundSupportCategoryPanelProps extends FormComponentProps {
  onNextStep: (stepData: any) => void;
  onPreviousStep: (stepData: any) => void;
  closeCreatePackageModal: () => void;
  selectedCustomer: ICustomer;
  fundingData: typeof state.customersStore.fundingData;
}

interface IFundSupportCategoryPanelState {
  isLoading: boolean;
  categoryData: any[];
  isActionModalOpen: boolean;
  rowID: number;
  errorType: string;
}

class FundSupportCategoryPanel extends Component<IFundSupportCategoryPanelProps, IFundSupportCategoryPanelState> {
  private categoryDataRefs: any[] = [];

  state = {
    isLoading: false,
    isActionModalOpen: false,
    isAddNewCategory: false,
    rowID: -1,
    categoryData: this.props.fundingData.categoryData !== undefined ? this.props.fundingData.categoryData : [],
    errorType: null,
  };

  private _closeActionModal = () => {
    this.setState({
      isActionModalOpen: false,
    });
  };

  private _onNextStep = () => {
    const { categoryData } = this.state;
    let isFormValid = true;

    if (categoryData.length === 0) {
      isFormValid = false;
      this.setState({ isActionModalOpen: true, errorType: 'empty' });
    } else {
      const supportTypes = _.groupBy(categoryData, (data) => data.supportType);
      _.map(supportTypes, (supportType) => {
        const funding = _.sumBy(supportType, (data) => data.funding);
        // @ts-ignore
        if (funding === 0 || funding === '' || funding === '0') {
          isFormValid = false;
          this.setState({ isActionModalOpen: true, errorType: 'funding' });
        }
      });
    }

    if (this.categoryDataRefs.length > 0) {
      _.forEach(this.categoryDataRefs, (supportCategoryInputRef) => {
        supportCategoryInputRef.props.form.validateFields((err, value) => {
          if (err) {
            isFormValid = false;
          }
        });
      });
    }

    if (isFormValid) {
      this.props.onNextStep([{ categoryData: this.state.categoryData }]);
    }
  };

  private _setRef = (ref) => {
    if (ref) {
      this.categoryDataRefs.push(ref);
    }
  };

  private _onPreviousStep = () => {
    const { categoryData } = this.state;

    let payload = [];
    payload.push({ categoryData });
    this.props.onPreviousStep(payload);
  };

  private _updateCategoryData = (categoryData) => {
    this.setState({ categoryData });
  };

  render() {
    const { categoryData } = this.state;

    return (
      <React.Fragment>
        <ActionModal
          isOpen={this.state.isActionModalOpen}
          onClose={this._closeActionModal}
          title={'Invalid Funding Data'}
          showCloseButton={true}
        >
          {this.state.errorType === 'empty' && (
            <>
              {' '}
              <Paragraph className={'mb-medium'}>The funding package needs to have an allocated budget.</Paragraph>
              <Paragraph className={'mb-medium'}>Please add a least one category with a positive budget.</Paragraph>
            </>
          )}
          {this.state.errorType === 'funding' && (
            <>
              <Paragraph className={'mb-medium'}>
                All selected support Type needs to have a budget greater than 0.
              </Paragraph>
              <Paragraph className={'mb-medium'}>
                Note: A category can have a budget at 0 if it has at least 1 stated support with a positive budget.
              </Paragraph>
            </>
          )}
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeActionModal}>
              Ok
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <div className="anim-slide-left">
          {' '}
          <Title level={2} className="text-weight-regular">
            Create a New <b>Funding Package</b>
          </Title>
        </div>
        <div>
          <Text>
            Please add the Support Categories that the participant is funded for the NDIS package, as well as the
            payment method of each category
          </Text>
        </div>

        <FundingEditCategoriesPanel
          categoryData={categoryData}
          updateCategoryData={this._updateCategoryData}
          wrappedComponentRef={this._setRef}
        />

        <div className={'mt-x2-large flex-row justify-end align-center'}>
          <GhostButton className={'mr-small'} onClick={this._onPreviousStep}>
            Back
          </GhostButton>
          <PrimaryButton size="large" icon={'right'} onClick={this._onNextStep}>
            Next
          </PrimaryButton>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = (state: IRootState) => ({
  fundingData: state.customersStore.fundingData,
});

export default connect(mapState, null)(Form.create<IFundSupportCategoryPanelProps>()(FundSupportCategoryPanel));
