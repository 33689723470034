import React, { useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import FormItem from 'antd/es/form/FormItem';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { SubTitle, Text } from 'common-components/typography';
import { FormElementType, PropKey } from 'views/form-builder/shared/form-enum';
import { IFormElement } from 'views/form-builder/shared/form-interface';
import { getValueFromPropertyByKey } from 'views/form-builder/shared/form-builder-utils';
import { debounce, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import './phone-number.css';

interface IPhoneNumberProps {
  fieldTitle?: string;
  caption?: string;
  isRequired?: boolean;
}
export default function PhoneNumber({ fieldTitle: fieldText, caption, isRequired }: IPhoneNumberProps) {
  const fieldTextFormat = (
    <>
      {fieldText}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bold">{fieldTextFormat}</SubTitle>
      <Input
        type="number"
        size="large"
        className="mb-medium mt-x-small phone-number-form-builder"
        addonBefore="+61(AU)"
        placeholder="000 000 000"
      />
      <Text color="secondary">{caption}</Text>
    </div>
  );
}

interface IEditParagraphProps extends FormComponentProps {
  value?: string;
  element: IFormElement;
}

export const EditPhoneNumber = ({ value, element, form }: IEditParagraphProps) => {
  const { elementsBundleOptional } = useSelector((state: IRootState) => state.formBuilderStore);
  const { id, parentId } = element;
  const caption = getValueFromPropertyByKey(element.properties.appearance, PropKey.CAPTION_TEXT);
  const { getFieldDecorator, setFieldsValue, validateFields, getFieldError } = form;
  const isRequired = getValueFromPropertyByKey(element.properties.general, PropKey.REQUIRE);
  let fieldText = getValueFromPropertyByKey(element.properties.general, PropKey.FIELD_TITLE);
  const isRequiredBundle = !elementsBundleOptional.includes(id);
  const [messageError, setMessageError] = useState(getFieldError(`${id},value`)?.join(' '));

  const validatePhoneNumber = (rule, value, callback) => {
    const region = 'AU';
    try {
      if (isEmpty(value) && isRequired) {
        if (!isRequiredBundle) return true;

        throw Error('This is a required field');
      }
      const regPhoneNumber = /^[0-9]+$/;
      if (!regPhoneNumber.test(value) && !!value.length) {
        throw new Error('Invalid Phone Number');
      }
      const phoneNumber = parsePhoneNumberFromString(value, region);
      if ((!phoneNumber || !phoneNumber.isValid()) && isRequired) {
        throw new Error('Invalid Phone Number');
      }
      if (!!value && !phoneNumber?.isValid() && !isRequired) {
        throw new Error('Invalid Phone Number');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  if (isRequired) {
    fieldText = (
      <>
        {fieldText}
        {isRequiredBundle && <Text color="red"> *</Text>}
      </>
    );
  }

  const changeValue = useCallback((value) => {
    const fieldName = `${id},value`;

    setFieldsValue({
      [fieldName]: value,
    });

    validateFields([fieldName]);
  }, []);

  const debounceChangeValue = debounce(changeValue, 300);

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    debounceChangeValue(value);
  };

  useEffect(() => {
    const fieldError = getFieldError(`${id},value`)?.join(' ');
    if (fieldError !== messageError) {
      setMessageError(fieldError);
    }
  }, [elementsBundleOptional]);

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bolder">{fieldText}</SubTitle>
      <FormItem className="m-none" validateStatus={messageError ? 'error' : ''} help={messageError}>
        <Input
          id={`${id},value`}
          defaultValue={form.getFieldValue(`${id},value`) ?? (value || '')}
          size="large"
          addonBefore="+61(AU)"
          placeholder="000 000 000"
          className="phone-number-form-builder"
          onChange={onChangeValue}
        />
      </FormItem>
      <FormItem className="m-none hide">
        {getFieldDecorator([id, 'value'] as never, {
          initialValue: value || '',
          rules: [
            {
              validator: validatePhoneNumber,
            },
          ],
        })(<Input type="hidden" />)}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'type'] as never, {
          initialValue: FormElementType.PHONE_NUMBER,
        })(<Input type="hidden" />)}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'parentElementId'] as never, {
          initialValue: parentId,
        })(<Input type="hidden" />)}
      </FormItem>
      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
};
