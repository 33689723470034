import React from 'react';
import { FieldLabel } from '../field-label';
import { StatusMessage } from '../status-message';
import { twMerge } from '../utils';
import { sharedInputStyles } from '../styles';
import { Text } from '../text';
import { useElementIds } from './use-element-ids';

export type TextInputProps = {
  label: string;
  labelHint?: string;
  description?: string;
  error?: string;
  required?: boolean;
  optional?: boolean;
  className?: string;
} & React.HTMLProps<HTMLInputElement>;

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, labelHint, description, error, required, optional, className, ...inputProps }, ref) => {
    const { id, labelId, errorId, descriptionId, labelHintId } = useElementIds(inputProps.id, inputProps.name, label);
    const labelAriaDescribedBy = [labelHint ? labelHintId : '', description ? descriptionId : '', error ? errorId : ''].join(' ');

    return (
      <div className='flex flex-col gap-2'>
        <span className='flex flex-col'>
          {label && (
            <FieldLabel
              id={labelId}
              htmlFor={id}
              isRequired={required}
              requirementIndicator={required || optional ? 'label' : undefined}
            >
              {label}
            </FieldLabel>
          )}

          {labelHint && (
            <Text id={labelHintId} className='text-weak'>
              {labelHint}
            </Text>
          )}
        </span>

        <input
          id={id}
          ref={ref}
          className={twMerge(sharedInputStyles, className)}
          aria-invalid={Boolean(error)}
          aria-required={required}
          aria-readonly={inputProps.readOnly}
          aria-labelledby={labelId}
          aria-describedby={labelAriaDescribedBy}
          {...inputProps}
        />

        {error && (
          <StatusMessage tone='critical' id={errorId}>
            {error}
          </StatusMessage>
        )}

        {description && !error && (
          <StatusMessage tone='neutral' id={descriptionId}>
            {description}
          </StatusMessage>
        )}
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';
