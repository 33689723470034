import { useCallback } from 'react';

import { IWorkLeaveType } from 'interfaces/account-interfaces';
import useWorkLeaveTypes from '../use-work-leave-types';

function useValidateDuplicateExternalId(
  workTypeId?: string | undefined,
): (workTypeExternalId?: string) => string | true {
  const { workTypes, leaveTypes } = useWorkLeaveTypes(true);

  const validateDuplicateExternalId = useCallback(
    (workTypeExternalId?: string): string | true => {
      if (workTypeExternalId === '' || workTypeExternalId === undefined) {
        return true;
      }

      const hasDuplicateExternalId = (workType: IWorkLeaveType) =>
        workType.workTypeExternalId === workTypeExternalId &&
        (workTypeId == undefined || workType.workTypeId !== workTypeId);

      const workType = workTypes.data?.some(hasDuplicateExternalId);
      const leaveType = leaveTypes.data?.some(hasDuplicateExternalId);

      if (workType || leaveType) {
        return 'Another item has the same external ID';
      }

      return true;
    },
    [workTypes, leaveTypes, workTypeId],
  );

  return validateDuplicateExternalId;
}

export default useValidateDuplicateExternalId;
