import React, { Children, cloneElement, forwardRef, isValidElement, useRef } from 'react';
import { Button as _Button, type ButtonProps as _ButtonProps } from 'react-aria-components';
import { mergeRefs } from '@react-aria/utils';

import * as styles from './button.css';
import { ButtonContext, type ButtonProviderProps } from './button-provider';
import { type ButtonVariants } from './button.css';
import { type HTMLProps } from '../types';
import { useContextProps, twMerge } from '../utils';
import { Loader2Adjustable } from '@good/icons';

export type ButtonProps = {
  /**
   * The content to display in the button.
   */
  children?: React.ReactNode;
  /**
   * Whether the button is disabled.
   * @default false
   */
  isDisabled?: _ButtonProps['isDisabled'];
  /**
   * Whether the action performed by the button is currently loading. This will also disable the button.
   * @default false
   */
  isLoading?: boolean;
} & Omit<_ButtonProps, 'children'> &
  ButtonVariants &
  HTMLProps<HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- this is intentional
  const sanitizedProps = { ...props, isDisabled: (props.isDisabled || props.isLoading) ?? undefined };
  const contextProps = useContextProps<ButtonProps & ButtonProviderProps>(ButtonContext, {
    ...sanitizedProps,
  });

  const {
    buttonRef = null,
    children,
    className,
    emphasis = 'outline',
    isDisabled = false,
    isLoading = false,
    size = 'md',
    tone = 'accent',
  } = contextProps;

  // eslint-disable-next-line react-hooks/rules-of-hooks -- in general yes, but the refs won't change.
  const mergedRef = mergeRefs(buttonRef, ref) ?? useRef(null);

  return (
    <_Button
      {...sanitizedProps}
      className={twMerge(
        styles.button({
          emphasis,
          size,
          tone,
        }),
        className,
      )}
      data-disabled={isDisabled}
      ref={mergedRef}
    >
      {isLoading ? <Loader2Adjustable className='animate-spin' /> : null}
      {Children.map(children, (child) => {
        if (isValidElement<ButtonProps>(child))
          return cloneElement<ButtonProps>(child, {
            ...child.props,
            className: twMerge(child.props.className, styles.icon({ size })),
          });

        return <span>{child}</span>;
      })}
    </_Button>
  );
});

Button.displayName = 'Button';
