import { Divider, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import FormItem from 'antd/es/form/FormItem';
import { Paragraph } from 'common-components/typography';
import { isArray, isEmpty, uniq } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchTagsLanguages } from 'stores/hooks/query-hooks/use-query-fetch-tags';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { ServiceType } from 'utilities/enum-utils';
import customerSource from 'variables/data/customer-source.json';
import hobbies from 'variables/data/hobbies.json';
import religion from 'variables/data/religion.json';
import { MAX_SELECTED_OPTIONS } from '../shared/constants';
import {
  cloneElement,
  getAllFormElementsWithChildren,
  getCloneLevel,
  getConditionalElementConfigProperties,
  getElementConfigurationByKey,
  getElementValueById,
  getOriginalId,
  getValueFromPropertyByKey,
  mapFormValueToElementValue
} from '../shared/form-builder-utils';
import {
  DateTimeType,
  FormElementType,
  IntakeFormElementType,
  PropKey,
  UserActionType,
  numberInputElementTypes,
  textInputElementTypes,
  validNumberOperators,
  validTextOperators
} from '../shared/form-enum';
import { IDropdownOptions, IElementValue, IFormContent, IFormElement, ITime } from '../shared/form-interface';
import { FormModeType } from './EditValueFormModal';
import { Header } from './form-elements';
import { EditAddressLookup } from './form-elements/AddressLookup';
import { EditAttachmentInput } from './form-elements/AttachmentInput/AttachmentInput';
import { EditCheckboxElement } from './form-elements/Checkbox/CheckboxElement';
import { EditCurrencyInput } from './form-elements/CurrencyInput/CurrencyInput';
import { EditDateTimeElement } from './form-elements/DateTimeElement/DateTimeElement';
import { EditDropdown } from './form-elements/Dropdown/Dropdown';
import { EditFirstAndLastNameInput } from './form-elements/FullName';
import { EditInlineEditInput } from './form-elements/InlineEditInput';
import { EditLongText } from './form-elements/LongText/LongText';
import { EditMultiChoice } from './form-elements/MultiChoice/MultiChoice';
import { EditNumberInput } from './form-elements/NumberInput/NumberInputElement';
import { EditPhoneNumber } from './form-elements/PhoneNumber/PhoneNumber';
import { EditSingleChoiceElement } from './form-elements/SingleChoice/SingleChoiceElement';

type IFormEditElementsRenderedProps = {
  formContent: IFormContent;
  elementValues: IElementValue[]; // Tracks changed form values
  setTimes: (time: ITime[]) => void;
  timezone: string;
  setPrevTimezone?: (prevTimezone: string) => void;
  formMode: FormModeType;
  onChangeFormElements: (formContent: IFormContent) => void;
} & FormComponentProps;

const FormEditElementsRendered = ({
  formContent,
  form,
  elementValues,
  setTimes,
  timezone,
  setPrevTimezone,
  formMode,
  onChangeFormElements,
}: IFormEditElementsRenderedProps) => {
  const { formBuilderStore: formBuilderDispatch } = useDispatch<IRootDispatch>();
  const { setElementsBundleOptional } = formBuilderDispatch;
  const [conditionalChanged, setConditionalChanged] = useState({});
  const [fieldsInvalid, setFieldsInvalid] = useState([]);

  useEffect(() => {
    checkBundleElements();
  }, [form]);

  useEffect(() => {
    if (!fieldsInvalid.length) return;

    form.resetFields(fieldsInvalid);
  }, [fieldsInvalid]);

  const checkBundleElements = () => {
    const { elements } = formContent;
    const idsElementBundleOptional = [];

    elements.forEach((element) => {
      checkEmptyValuesBundle(idsElementBundleOptional, element);
    });
    setElementsBundleOptional(idsElementBundleOptional);

    const fieldsError = Object.keys(form.getFieldsError() || {}).map((key) => key.replace(',value', '').trim());

    setFieldsInvalid(
      idsElementBundleOptional
        .filter(
          (id) => fieldsError.includes(id) && form.getFieldError(`${id},value`) && !form.getFieldValue(`${id},value`),
        )
        .map((item) => `${item},value`),
    );
  };

  const checkEmptyValuesBundle = (idsElementBundleOptional: string[], element: IFormElement) => {
    const { type, children } = element;
    const isRequired = getValueFromPropertyByKey(element.properties.general, PropKey.REQUIRE);

    if (type === FormElementType.MULTIPLE_ELEMENT && !isRequired) {
      const formValues = mapFormValueToElementValue(form.getFieldsValue());

      const isEmptyValuesBundle = (formValues || [])
        .filter((field) => children.some((children) => children.id === field.elementId))
        .every((field) => {
          const { elementData } = field;

          if (Array.isArray(elementData)) {
            return !elementData.filter((item) => item).length;
          }

          return !elementData;
        });

      if (isEmptyValuesBundle) {
        idsElementBundleOptional.push(...children.map((children) => children.id));
      }
    }

    if (children?.length) {
      children.forEach((item) => {
        checkEmptyValuesBundle(idsElementBundleOptional, item);
      });
    }
  };

  const _renderHeader = (element: IFormElement) => {
    const heading = getValueFromPropertyByKey(element.properties.appearance, 'headingText');
    const subHeading = getValueFromPropertyByKey(element.properties.appearance, 'subHeadingText');
    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <Header key={element.id} headingText={heading} subHeadingText={subHeading} />
        </Row>
      )
    );
  };

  const _renderParagraph = (element: IFormElement) => {
    const value = getValueFromPropertyByKey(element.properties.appearance, 'paragraphText');
    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <Paragraph key={element.id} className='whitespace-pre-line'>
            {value}
          </Paragraph>
        </Row>
      )
    );
  };

  const _renderDivider = () => {
    return (
      <Row>
        <Divider />
      </Row>
    );
  };

  const _renderInlineInput = (element: IFormElement) => {
    const value = getElementValueById(elementValues, element.id);
    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <EditInlineEditInput key={element.id} value={value} form={form} element={element} />
        </Row>
      )
    );
  };

  const _renderLongText = (element: IFormElement, formMode: FormModeType = FormModeType.EDIT) => {
    const value = getElementValueById(elementValues, element.id);
    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <EditLongText key={element.id} value={value} form={form} element={element} formMode={formMode} />
        </Row>
      )
    );
  };

  const _renderNumberInput = (element: IFormElement) => {
    const defaultValue = getValueFromPropertyByKey(element.properties.configuration, 'default')
      ? getValueFromPropertyByKey(element.properties.configuration, 'defaultValue')
      : '';
    let value = getElementValueById(elementValues, element.id);
    if (value === null) {
      value = defaultValue;
    }
    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <EditNumberInput key={element.id} value={value} form={form} element={element} />
        </Row>
      )
    );
  };

  const renderPhoneNumber = (element: IFormElement) => {
    const value = getElementValueById(elementValues, element.id);

    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <EditPhoneNumber key={element.id} value={value} form={form} element={element} />
        </Row>
      )
    );
  };

  const _renderCurrency = (element: IFormElement) => {
    const defaultValue = getValueFromPropertyByKey(element.properties.configuration, 'default')
      ? getValueFromPropertyByKey(element.properties.configuration, 'defaultValue')
      : '';
    let value = getElementValueById(elementValues, element.id);
    if (value === null) {
      value = defaultValue;
    }
    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <EditCurrencyInput key={element.id} value={value} form={form} element={element} />
        </Row>
      )
    );
  };

  const _renderDropdown = (element: IFormElement) => {
    return (
      shouldElementBeRendered(element) && (
        <DropdownElement
          key={element.id}
          element={element}
          form={form}
          elementValues={elementValues}
          isMultiSelect={element.type === FormElementType.DROPDOWN_MULTI_SELECT}
          {...getUserActionToShowElementByConditional(element)}
        />
      )
    );
  };

  const _renderMultiChoices = (element: IFormElement) => {
    let defaultValue = getValueFromPropertyByKey(element.properties.configuration, 'defaultOption');
    let value = getElementValueById(elementValues, element.id);
    if (value === null) {
      if (defaultValue === 'No default') {
        defaultValue = '';
      }
      if (!Array.isArray(defaultValue)) {
        if (!defaultValue) {
          defaultValue = [];
        } else {
          defaultValue = [defaultValue];
        }
      }
      value = defaultValue || [];
    }
    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <EditMultiChoice
            key={element.id}
            value={value}
            form={form}
            element={element}
            {...getUserActionToShowElementByConditional(element)}
          />
        </Row>
      )
    );
  };

  const _renderSingleChoice = (element: IFormElement) => {
    const defaultValue = getValueFromPropertyByKey(element.properties.configuration, 'defaultOption');

    let value = getElementValueById(elementValues, element.id);
    if (value === null) {
      value = defaultValue === 'No default' ? '' : defaultValue;
    }

    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <EditSingleChoiceElement
            key={element.id}
            value={value}
            form={form}
            element={element}
            {...getUserActionToShowElementByConditional(element)}
          />
        </Row>
      )
    );
  };

  const _renderCheckbox = (element: IFormElement) => {
    const defaultValue = element.properties.configuration.find(
      (item) => item.key === PropKey.CHECKBOX_OPTIONS,
    ).defaultChecked;
    const fieldType = element.properties.general.find((item) => item.key === PropKey.FIELD_TITLE)?.fieldType;
    let value = getElementValueById(elementValues, element.id);

    if (value === null) {
      value = defaultValue || false;
    }

    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <EditCheckboxElement
            key={element.id}
            value={value}
            form={form}
            element={element}
            onChange={(isChecked) => onChangeCheckbox(element.id, isChecked, fieldType)}
          />
        </Row>
      )
    );
  };

  const _renderDateTime = (element: IFormElement) => {
    const value = getElementValueById(elementValues, element.id) || [];
    if (formMode === 'EDIT' || formMode === 'VIEW') {
      const prevTimezone = elementValues.find((value) => value.elementId === element.id);
      prevTimezone && setPrevTimezone(prevTimezone.timezone);
    }

    const updateTime = (e: Date) => {
      const newFormValue = {
        [`${element.id},completeTime`]: moment(e).format('YYYY-MM-DD HH:mm'),
      };

      form.setFieldsValue(newFormValue);
    };

    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <FormItem className="m-none">
            {form.getFieldDecorator(`${element.id},completeTime`, {
              initialValue: value,
            })(<Input type="hidden" />)}
          </FormItem>
          <EditDateTimeElement
            key={element.id}
            elements={formContent.elements}
            onDateValueChanged={updateTime}
            setTimes={setTimesLocal}
            value={value}
            form={form}
            formMode={formMode}
            element={element}
            timezone={timezone}
          />
        </Row>
      )
    );
  };

  const _renderFileUpload = (element: IFormElement) => {
    const value = getElementValueById(elementValues, element.id) || [];
    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <EditAttachmentInput key={element.id} value={value} form={form} element={element} formMode={formMode} />
        </Row>
      )
    );
  };

  const _renderAddressLookup = (element: IFormElement) => {
    const value = getElementValueById(elementValues, element.id);

    return (
      shouldElementBeRendered(element) && (
        <Row className='mt-large'>
          <EditAddressLookup key={element.id} value={value} form={form} element={element} />
        </Row>
      )
    );
  };

  const onChangeCheckbox = (elementId: string, isChecked: boolean, fieldType?: IntakeFormElementType) => {
    const { elements } = formContent;
    const { setFieldsValue } = form;

    if (isChecked && fieldType === IntakeFormElementType.CONTACTS_CHECK_PRIMARY_CONTACT) {
      elements.forEach((element: IFormElement) => {
        const fieldType = element.properties.general.find((item) => item.key === 'fieldTitle')?.fieldType;

        if (fieldType === IntakeFormElementType.CONTACTS_CHECK_PRIMARY_CONTACT && elementId !== element.id) {
          setFieldsValue({
            [`${element.id},value`]: !isChecked,
          });
        }

        (element?.children || []).forEach((item) => {
          const fieldType = item.properties.general.find((item) => item.key === 'fieldTitle')?.fieldType;

          if (fieldType === IntakeFormElementType.CONTACTS_CHECK_PRIMARY_CONTACT && elementId !== item.id) {
            setFieldsValue({
              [`${item.id},value`]: !isChecked,
            });
          }
        });
      });
    }

    const element = getAllFormElementsWithChildren(formElements).find((elem) => elem.id === elementId);
    if (element) {
      const userAction = getUserActionToShowElementByConditional(element);
      if (typeof userAction.onChange === 'function') {
        userAction.onChange(isChecked);
      }
    }
  };

  // Defines effect on value change depending on the useractiontype
  const getUserActionToShowElementByConditional = (
    element: IFormElement,
  ): { onChange?: (value: boolean | string | string[]) => void } => {
    const conditionalElementList = getConditionalParentOfElement(element, PropKey.TARGET_ELEMENT);
    const eventHandler = {};
    if (!conditionalElementList?.length) {
      return eventHandler;
    }

    // List of conditionalElement.Event.value properties that target this element
    const listEventConfiguration = uniq(
      conditionalElementList.map((el) => getElementConfigurationByKey(el, PropKey.EVENT)?.value),
    );
    listEventConfiguration.forEach((event) => {
      // TODO: This could probably be genericised?
      switch (event) {
        case FormConditionalOperator.IS_SELECTED:
        case FormConditionalOperator.IS_NOT_SELECTED:
          eventHandler['onChange'] = (value: boolean) => {
            conditionalElementList.forEach((conditionalElement) =>
              handleUserActionForConditionalElement(conditionalElement, value),
            );
            setConditionalChanged(conditionalChanged);
          };
          break;
        case UserActionType.ON_CHECK:
          eventHandler['onChange'] = (value: boolean) => {
            conditionalElementList.forEach((conditionalElement) =>
              handleUserActionForConditionalElement(conditionalElement, value),
            );
            setConditionalChanged(conditionalChanged);
          };
          break;
        case UserActionType.ON_SELECT:
          eventHandler['onChange'] = (value: string | string[]) => {
            conditionalElementList.forEach((conditionalElement) =>
              handleUserActionForConditionalElement(conditionalElement, value),
            );
            setConditionalChanged(conditionalChanged);
          };
          break;
        default:
          eventHandler['onChange'] = () => {
            /* no op */
          };
      }
    });

    return eventHandler;
  };

  // Updates the value for conditional elements
  // Value should be equal to the ACTUAL value of the element that has been changed
  const handleUserActionForConditionalElement = (
    conditionalElement: IFormElement,
    value: boolean | string | string[],
  ) => {
    const targetOptions = conditionalElement.properties.configuration.find(
      (config) => config.key === PropKey.TARGET_OPTION,
    );

    const affectedElement = conditionalElement.properties.configuration.find(
      (config) => config.key === PropKey.AFFECT_TO_ELEMENT,
    );

    if (targetOptions.value) {
      affectedElement.isShow = isArray(value) ? value.includes(targetOptions.value) : value === targetOptions.value;

      if (!affectedElement.isShow) {
        const allElements = getAllFormElementsWithChildren(formContent.elements);
        allElements
          .filter((el) => el.type === FormElementType.CONDITIONAL && el.id !== conditionalElement.id)
          .forEach((conditionalEl) => {
            const target = conditionalEl.properties.configuration.find(
              (config) => config.key === PropKey.TARGET_ELEMENT,
            );
            const targetElement = target?.options?.find((opt) => opt.id === target?.value);
            const affect = conditionalEl.properties.configuration.find(
              (config) => config.key === PropKey.AFFECT_TO_ELEMENT,
            );

            if (
              affectedElement.value?.includes(targetElement?.id) ||
              affectedElement.value?.includes(targetElement?.parentId)
            ) {
              affect.isShow = affectedElement.isShow;
            }
          });
      }
    } else {
      affectedElement.isShow = Boolean(value);
    }

    if (affectedElement.value?.includes(conditionalElement.parentId)) {
      handleShowElementWhenTriggerConditionRepeatable(conditionalElement, affectedElement.isShow);
    }

    if (!conditionalChanged[conditionalElement.id]) {
      // defines whether or not the element of ID has changed
      conditionalChanged[conditionalElement.id] = true;
    }

    if (affectedElement.isShow) {
      updateConditionalChangedObjWhenChangeParentConditional(conditionalElement);
    }
  };

  const handleShowElementWhenTriggerConditionRepeatable = (conditionalElement: IFormElement, isShow: boolean) => {
    const parentElement = formContent.elements.find((el) => el.id === conditionalElement.parentId);
    if (parentElement) {
      let listNewElement = [...formContent.elements];
      if (isShow) {
        let isContinueAddClonedElement = false;
        let elementForClone = { ...parentElement };
        do {
          const parenElementClone = cloneElement(elementForClone);
          const findHeading = (parenElementClone?.children || [])[0]?.type === FormElementType.HEADER;

          if (findHeading) parenElementClone?.children.shift();

          const parentElementIndex = listNewElement.findIndex((item) => item.id === elementForClone.id);
          listNewElement.splice(parentElementIndex + 1, 0, parenElementClone);

          const conditionalElement = parenElementClone.children.find(
            (child) => child.type === FormElementType.CONDITIONAL,
          );
          const targetElement = conditionalElement?.properties.configuration.find(
            (config) => config.key === PropKey.TARGET_ELEMENT,
          );
          const targetOptions = conditionalElement?.properties.configuration.find(
            (config) => config.key === PropKey.TARGET_OPTION,
          );
          isContinueAddClonedElement =
            targetOptions?.value && getElementValueById(elementValues, targetElement?.value) === targetOptions?.value;
          elementForClone = { ...parenElementClone };
        } while (isContinueAddClonedElement);
      } else {
        listNewElement = listNewElement.filter((el) => {
          if (getOriginalId(parentElement.id) === getOriginalId(el.id)) {
            const elementCloneLevel = getCloneLevel(el.id);
            const parentCloneLevel = getCloneLevel(parentElement.id);

            return elementCloneLevel <= parentCloneLevel;
          }

          return true;
        });
      }

      onChangeFormElements({
        ...formContent,
        elements: listNewElement,
      });
    }
  };

  const getListAllElementWithLevel = () => {
    const allElements: (IFormElement & { level: number })[] = [];
    formContent.elements.forEach((element) => {
      const stack = [{ ...element, level: 1 }];
      while (stack.length) {
        const currentElement = stack.shift();
        allElements.push(currentElement);
        currentElement.children?.forEach((child) => {
          stack.push({ ...child, level: currentElement.level + 1 });
        });
      }
    });

    return allElements;
  };

  const updateConditionalChangedObjWhenChangeParentConditional = (currentConditinalElement: IFormElement) => {
    const allConditionalElements = getListAllElementWithLevel().filter((el) => el.type === FormElementType.CONDITIONAL);
    const isMultiLevelConditionalElement = checkMultiLevelConditionalElement(currentConditinalElement);
    const currentElementLevel = allConditionalElements.find((el) => el.id === currentConditinalElement.id)?.level;

    allConditionalElements.forEach((el) => {
      const isDependentCondition = allConditionalElements.some((item) => {
        const targetConfiguration = el.properties.configuration.find((config) => config.key === PropKey.TARGET_ELEMENT);
        const affectConfiguration = item.properties.configuration.find(
          (config) => config.key === PropKey.AFFECT_TO_ELEMENT,
        );

        return affectConfiguration.value?.includes(targetConfiguration.value);
      });
      if (
        conditionalChanged[el.id] &&
        el.id !== currentConditinalElement.id &&
        ((el.level > currentElementLevel && isMultiLevelConditionalElement) ||
          (el.level === currentElementLevel && isDependentCondition))
      ) {
        conditionalChanged[el.id] = false;
      }
    });
  };

  const checkMultiLevelConditionalElement = (currentConditinalElement: IFormElement) => {
    for (const element of formContent.elements) {
      let haveConditional = false;
      let isContainCurrentConditional = false;
      const stack = [element];
      while (stack.length) {
        const currentElement = stack.shift();
        if (currentElement.type === FormElementType.CONDITIONAL && currentConditinalElement.id !== currentElement.id) {
          haveConditional = true;
        }

        if (currentElement.id === currentConditinalElement.id) {
          isContainCurrentConditional = true;
        }

        if (currentElement.children?.length) {
          stack.push(...currentElement.children);
        }
      }

      if (isContainCurrentConditional && haveConditional) {
        return true;
      }
    }

    return false;
  };

  const conditionalNumberCompare = (operation: FormConditionalOperator, value: number, compareToInput: number) => {
    switch (operation) {
      case FormConditionalOperator.IS_EQUAL_TO:
      case FormConditionalOperator.IS: {
        return value === compareToInput;
      }
      case FormConditionalOperator.IS_LESS_THAN: {
        return value < compareToInput;
      }
      case FormConditionalOperator.IS_GREATER_THAN: {
        return value > compareToInput;
      }
      case FormConditionalOperator.IS_NOT: {
        return value !== compareToInput;
      }
      default:
        return false;
    }
  };

  const conditionalTextCompare = (operation: FormConditionalOperator, value: string, compareToInput: string) => {
    switch (operation) {
      case FormConditionalOperator.IS_EQUAL_TO:
      case FormConditionalOperator.IS: {
        return value?.localeCompare(compareToInput, undefined, { sensitivity: 'accent' }) === 0;
      }
      case FormConditionalOperator.CONTAINS: {
        return value?.includes(compareToInput);
      }
      case FormConditionalOperator.DOES_NOT_CONTAIN: {
        return !value?.includes(compareToInput);
      }
      case FormConditionalOperator.IS_NOT: {
        return value?.toLocaleLowerCase() !== compareToInput.toLocaleLowerCase();
      }
      default:
        return false;
    }
  };

  // Evaluated on each render element for editing form
  const shouldElementBeRendered = (element: IFormElement) => {
    const conditionalElementsOfCurrentElement = getConditionalParentOfElement(element, PropKey.AFFECT_TO_ELEMENT);

    if (conditionalElementsOfCurrentElement.length === 0) {
      return true;
    }

    const conditionalResults: boolean[] = [];

    conditionalElementsOfCurrentElement.forEach((conditionalElementOfCurrentElement) =>
      conditionalResults.push(getConditionalTestResult(conditionalElementOfCurrentElement, element)),
    );

    const conditionalContainsTrueResult = conditionalResults.includes(true);

    return conditionalContainsTrueResult;
  };

  enum FormConditionalOperator {
    IS = 'is',
    IS_NOT = 'is not',
    CONTAINS = 'contains',
    DOES_NOT_CONTAIN = 'does not contain',
    IS_GREATER_THAN = 'is greater than',
    IS_LESS_THAN = 'is less than',
    IS_UPLOADED = 'is uploaded',
    IS_NOT_UPLOADED = 'is not uploaded',
    IS_SELECTED = 'is selected',
    IS_NOT_SELECTED = 'is not selected',
    IS_EQUAL_TO = 'is equal to',
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- its an any, get over it.
  const setTimesLocal = (t: any) => {
    const dateValueShouldBeInKey = t[0];

    const objectKey = Object.keys(dateValueShouldBeInKey)[0];
    const objectValue = Object.values(dateValueShouldBeInKey)[0] as moment;
    const elementId = objectKey?.replace(',time', '');
    const foundDateValue = form.getFieldValue(`${elementId},value`);

    const dateElementMomentValue = moment(foundDateValue);

    const newDateTime = new Date();
    newDateTime.setDate(dateElementMomentValue.date());
    newDateTime.setHours(objectValue.hour());
    newDateTime.setMinutes(objectValue.minute());
    newDateTime.setSeconds(0);
    newDateTime.setMilliseconds(0);

    const newDateTimeMoment = moment(newDateTime);

    const dumbTimeFormat = [{ [`${elementId},time`]: newDateTimeMoment }];

    setTimes(dumbTimeFormat);
  };

  const getConditionalTestResult = (
    formConditionalControlElement: IFormElement,
    controlledElement: IFormElement,
  ): boolean => {
    // If the current element has no related conditional parent, then return true
    // eslint-disable-next-line prefer-const -- breaks it, get over it.
    let conditionalConfigProperties = getConditionalElementConfigProperties(formConditionalControlElement);

    // Gets the value of the element to compare against
    const valueSearchKey = `${conditionalConfigProperties.targetElement?.value  },value`;

    const targetElementValue =
      form.getFieldsValue()[valueSearchKey] ??
      getElementValueById(elementValues, conditionalConfigProperties.targetElement?.value);

    // const possibleOperations = formConditionalControlElement.properties.configuration.find(
    //   (configItem) => configItem.key === PropKey.EVENT,
    // ).options;

    const conditionalOperator = formConditionalControlElement.properties.configuration.find(
      (configItem) => configItem.key === PropKey.EVENT,
    ).value;

    const targetElementTriggerValue = conditionalConfigProperties.targetOptions.value;

    if (conditionalOperator !== null) {
      const typedOperatorString = conditionalOperator as keyof typeof FormConditionalOperator;
      const typedOperator = typedOperatorString as FormConditionalOperator;

      // Get the field type
      const typeSearchKey = `${conditionalConfigProperties.targetElement?.value  },type`;
      const targetElementType = form.getFieldsValue()[typeSearchKey];

      // If driving element is of a valid number type (eg. number, currency)
      if (numberInputElementTypes.includes(targetElementType)) {
        // Reject invalid comparison operator
        if (!validNumberOperators.includes(typedOperator)) {
          // eslint-disable-next-line no-console -- its a legitimate error
          console.error(`Invalid operator type for target element type of 'number'`);
          return false;
        }
        const expectedValue = conditionalConfigProperties.comparator?.value;

        if (expectedValue === null) {
          // eslint-disable-next-line no-console -- its a legitimate error
          console.error(
            `Operator (${typedOperator}) found, but invalid expected value for field ${conditionalConfigProperties.comparator?.key}`,
          );
          return false;
        }

        return conditionalNumberCompare(typedOperator, parseFloat(targetElementValue), parseFloat(expectedValue));
      }

      if (textInputElementTypes.includes(targetElementType)) {
        // Reject invalid comparison operator
        if (!validTextOperators.includes(typedOperator)) {
          // eslint-disable-next-line no-console -- its a legitimate error
          console.error(`Invalid operator type for target element type of '${typedOperator}'`);
          return false;
        }
        const expectedValue = conditionalConfigProperties.comparator?.value;

        if (expectedValue === null) {
          // eslint-disable-next-line no-console -- its a legitimate error
          console.error(
            `Operator (${typedOperator}) found, but invalid expected value for field ${conditionalConfigProperties.comparator?.key}`,
          );
          return false;
        }

        return conditionalTextCompare(typedOperator, targetElementValue, expectedValue);
      }

      // TODO: Needs lots of cleanup/refactoring/simplification
      if (targetElementType === FormElementType.DATE_TIME) {
        const valueSearchKey = `${conditionalConfigProperties.targetElement?.value  },completeTime`;

        const targetElementTimeValue = form.getFieldsValue()[valueSearchKey];

        const targetElement = formContent.elements.find(
          (el) => el.id === conditionalConfigProperties.targetElement?.value,
        );

        const targetElementDateTimeType = targetElement?.properties.configuration.find(
          (x) => x.key === 'dateTimeType',
        ).value;

        const expectedValue = conditionalConfigProperties.comparatorTime?.value;
        let targetElementValueMilli = Date.parse(targetElementTimeValue);
        const expectedValueMilli = Date.parse(expectedValue);

        if (targetElementDateTimeType === DateTimeType.DATE_ONLY) {
          const parsedDate = new Date(targetElementValueMilli);
          const expectedDate = new Date(expectedValueMilli);

          parsedDate.setHours(expectedDate.getHours());
          parsedDate.setMinutes(expectedDate.getMinutes());
          parsedDate.setSeconds(expectedDate.getSeconds());

          targetElementValueMilli = Date.parse(parsedDate.toString());
        }

        if (targetElementDateTimeType === DateTimeType.TIME_ONLY) {
          const parsedDate = new Date(targetElementValueMilli);
          const expectedDate = new Date(expectedValueMilli);

          parsedDate.setDate(expectedDate.getDate());

          targetElementValueMilli = Date.parse(parsedDate.toString());
        }

        return conditionalNumberCompare(typedOperator, targetElementValueMilli, expectedValueMilli);
      }

      switch (typedOperator) {
        case FormConditionalOperator.IS_NOT_UPLOADED: {
          const oldDocumentFormKey = `${conditionalConfigProperties.targetElement?.value  },oldDocument`;
          const oldDocumentValue = form.getFieldsValue()[oldDocumentFormKey];

          // Does the upload element contain a file
          if (
            (targetElementValue === null || targetElementValue === undefined) &&
            (oldDocumentValue === null || oldDocumentValue === undefined)
          ) {
            return true;
          } 
          return false;
        }
        case FormConditionalOperator.IS_UPLOADED: {
          const oldDocumentFormKey = `${conditionalConfigProperties.targetElement?.value  },oldDocument`;
          const oldDocumentValue = form.getFieldsValue()[oldDocumentFormKey];

          // Does the upload element contain a file
          if (
            (targetElementValue === null || targetElementValue === undefined) &&
            (oldDocumentValue === null || oldDocumentValue === undefined)
          ) {
            return false;
          } 
          return true;
        }
        case FormConditionalOperator.IS_SELECTED: {
          // Checkboxes DO NOT populate the targetEleementTriggerValue, whereas multiple/single choice DOES
          const expectedValue = targetElementTriggerValue || true;

          switch (targetElementType) {
            case FormElementType.MULTI_CHOICE:
            case FormElementType.DROPDOWN:
            case FormElementType.DROPDOWN_MULTI_SELECT: {
              return targetElementValue?.includes(expectedValue);
            }
            case FormElementType.CHECKBOX:
            case FormElementType.SINGLE_CHOICE: {
              const result = targetElementValue === expectedValue;
              return result;
            }
          }
          // Catch all - not supported
          // eslint-disable-next-line no-console -- its a legitimate error
          console.error(`Operator (${typedOperator}) not supported for element type ${targetElementType}`);
          return false;
        }
        case FormConditionalOperator.IS_NOT_SELECTED: {
          // Checkboxes DO NOT populate the targetEleementTriggerValue, whereas multiple/single choice DOES
          const expectedValue = targetElementTriggerValue || true;

          switch (targetElementType) {
            case FormElementType.MULTI_CHOICE:
            case FormElementType.DROPDOWN:
            case FormElementType.DROPDOWN_MULTI_SELECT: {
              const result = !targetElementValue?.includes(expectedValue);
              return result;
            }
            case FormElementType.CHECKBOX:
            case FormElementType.SINGLE_CHOICE: {
              const result = targetElementValue !== expectedValue;
              return result;
            }
          }
          // Catch all - not supported
          // eslint-disable-next-line no-console -- its a legitimate error
          console.error(`Operator (${typedOperator}) not supported for element type ${targetElementType}`);
          return false;
        }
      }
    }

    // ** OLD Form Controls **
    // Leaving these for compatibility. Could possibly be removed? *shrug*

    // CHECKBOX
    const controllingElement = conditionalConfigProperties.targetElement.options?.[0];
    if (typeof targetElementValue === 'boolean' || controllingElement?.type === FormElementType.CHECKBOX) {
      if (conditionalChanged[formConditionalControlElement.id])
        return conditionalConfigProperties.affectedElement?.isShow;

      const defaultValue = controllingElement.properties.configuration.find(
        (item) => item.key === PropKey.CHECKBOX_OPTIONS,
      )?.defaultChecked;

      return targetElementValue ?? (defaultValue || false);
    }

    // MULTI + SINGLE SELECT
    if (targetElementValue && !conditionalChanged[formConditionalControlElement.id]) {
      const listElementValue = isArray(targetElementValue) ? targetElementValue : [targetElementValue];

      const showElement =
        (listElementValue.includes(conditionalConfigProperties.targetOptions?.value) &&
          !conditionalConfigProperties.repeatable?.value &&
          shouldElementBeRendered(
            conditionalConfigProperties.targetElement.options?.find(
              (opt) => opt.id === conditionalConfigProperties.targetElement?.value,
            ),
          )) ||
        (conditionalConfigProperties.affectedElement.value?.includes(controlledElement.parentId) &&
          conditionalConfigProperties.repeatable?.value);

      return showElement;
    } else {
      return (
        (conditionalConfigProperties.affectedElement.value?.includes(controlledElement.parentId) &&
          conditionalConfigProperties.repeatable?.value) ||
        conditionalConfigProperties.affectedElement?.isShow
      );
    }
  };

  const getConditionalParentOfElement = (element: IFormElement, type: string) => {
    const { elements } = formContent;
    const allElements = getAllFormElementsWithChildren(elements);
    const allConditionalElements = allElements.filter((el) => el.type === FormElementType.CONDITIONAL);
    if (!allConditionalElements.length) {
      return [];
    }

    const filterConditional =
      type === PropKey.AFFECT_TO_ELEMENT
        ? (conditionalElement) =>
            getElementConfigurationByKey(conditionalElement, type)?.value?.includes(element.id) ||
            getElementConfigurationByKey(conditionalElement, type)?.value?.includes(element.parentId)
        : (conditionalElement) =>
            getElementConfigurationByKey(conditionalElement, type)?.value === element.id ||
            getElementConfigurationByKey(conditionalElement, type)?.value === element.parentId;

    return allConditionalElements.filter((conditionalElement) => filterConditional(conditionalElement));
  };

  const printChildren = (element: IFormElement, listAllElements: IFormElement[]) => {
    if (element.type === FormElementType.MULTIPLE_ELEMENT) {
      listAllElements.push(...element.children);
      element.children.forEach((item) => {
        printChildren(item, listAllElements);
      });
    } else {
      if (!listAllElements.some((el) => el.id === element.id)) {
        listAllElements.push(element);
      }
    }
  };

  const renderFirstAndLastName = (element: IFormElement) => {
    const value = getElementValueById(elementValues, element.id);

    return (
      <Row className='mt-large'>
        <EditFirstAndLastNameInput value={value} form={form} element={element} />
      </Row>
    );
  };

  const formElements = useMemo(() => {
    const { elements } = formContent;
    const newElements = [];

    elements.forEach((element) => {
      if (element.type === FormElementType.MULTIPLE_ELEMENT) {
        printChildren(element, newElements);
      } else {
        newElements.push(element);
      }
    });

    return newElements;
  }, [formContent]);

  const _renderElements = () => {
    const { elements } = formContent;
    if (isEmpty(elements)) return null;

    return (
      <>
        {formElements.map((element) => {
          switch (element.type) {
            case FormElementType.HEADER:
              return _renderHeader(element);
            case FormElementType.SHORT_TEXT:
              return _renderInlineInput(element);
            case FormElementType.MULTI_CHOICE:
              return _renderMultiChoices(element);
            case FormElementType.PARAGRAPH:
              return _renderParagraph(element);
            case FormElementType.DIVIDER:
              return _renderDivider();
            case FormElementType.LONG_TEXT:
              return _renderLongText(element, formMode);
            case FormElementType.SINGLE_CHOICE:
              return _renderSingleChoice(element);
            case FormElementType.CHECKBOX:
              return _renderCheckbox(element);
            case FormElementType.CURRENCY:
              return _renderCurrency(element);
            case FormElementType.NUMBER:
              return _renderNumberInput(element);
            case FormElementType.DATE_TIME:
              return _renderDateTime(element);
            case FormElementType.FILE_UPLOAD:
              return _renderFileUpload(element);
            case FormElementType.DROPDOWN:
            case FormElementType.DROPDOWN_MULTI_SELECT:
              return _renderDropdown(element);
            case FormElementType.PHONE_NUMBER:
              return renderPhoneNumber(element);
            case FormElementType.ADDRESS_LOOKUP:
              return _renderAddressLookup(element);
            case FormElementType.FIRST_LAST_NAME:
              return renderFirstAndLastName(element);
            default:
              return <></>;
          }
        })}
        <Divider className='divider-large' />
      </>
    );
  };
  return _renderElements();
};

export default FormEditElementsRendered;

type IDropdownElementProps = {
  element: IFormElement;
  elementValues: IElementValue[];
  isMultiSelect: boolean;
  onChange?: (value) => void;
} & FormComponentProps;

const DropdownElement = ({ element, elementValues, isMultiSelect, form, onChange }: IDropdownElementProps) => {
  const { customersStore: customersDispatch } = useDispatch<IRootDispatch>();
  const { servicesStore: servicesDispatch } = useDispatch<IRootDispatch>();
  const { accountStore: accountDispatch } = useDispatch<IRootDispatch>();
  const { formBuilderStore: formBuilderDispatch } = useDispatch<IRootDispatch>();
  const { relationships } = useSelector((state: IRootState) => state.customersStore);
  const [isFormLoading, setLoading] = useState<boolean>(false);
  const defaultValue = getValueFromPropertyByKey(element.properties.configuration, PropKey.DEFAULT_OPTION);
  const [value, setValue] = useState(getElementValueById(elementValues, element.id));
  const fieldType = element.properties.general?.[0]?.fieldType;
  const options = element.properties.configuration[0].value;
  const [listOptions, setListOptions] = useState<IDropdownOptions[]>(options);
  const [listSelectedOptions, setListSelectedOptions] = useState<string | string[]>(value);

  // Dynamically load useQuery if relevant
  let queryLookup: Function = undefined;
  switch (fieldType) {
    case IntakeFormElementType.GENERAL_INFORMATION_LANGUAGES:
    case IntakeFormElementType.GENERAL_INFORMATION_TRANSLATOR_LANGUAGE:
      queryLookup = useFetchTagsLanguages;
  }

  const { data, isLoading, error } = queryLookup
    ? queryLookup()
    : { data: undefined, isLoading: undefined, error: undefined };

  const onChangeValueDropdownOptions = (value) => {
    setListSelectedOptions(value);
  };

  useEffect(() => {
    if (!Object.keys(MAX_SELECTED_OPTIONS || {}).includes(fieldType)) return;

    if (listSelectedOptions?.length < MAX_SELECTED_OPTIONS[fieldType]) {
      const initOptions = options.map((item) => {
        return { ...item, disabled: false };
      });
      setListOptions(initOptions);
    }

    if (listSelectedOptions?.length === MAX_SELECTED_OPTIONS[fieldType]) {
      const newOptionsList = options.map((item) => {
        if (listSelectedOptions.includes(item.value)) return { ...item, disabled: false };
        return { ...item, disabled: true };
      });
      setListOptions(newOptionsList);
    }
  }, [listSelectedOptions?.length, options.length]);

  const getCountries = async () => {
    const { doGetCountriesList } = customersDispatch;
    const countries = await doGetCountriesList();

    element.properties.configuration[0].value = countries.map((item) => ({
      value: item.countryId,
      name: item.countryName,
    }));
  };

  const getRequestedServices = async () => {
    const { doGetLiteList } = servicesDispatch;
    const liteList = await doGetLiteList({
      status: ['PUBLISHED'],
      isGetAll: true,
    });
    element.properties.configuration[0].value = [
      ...liteList.map((item) => ({
        value: item.serviceId,
        name: item.serviceName,
      })),
    ];
  };

  const getRelationship = async () => {
    if (relationships.length) return;

    const { doGetRelationshipLists } = customersDispatch;
    await doGetRelationshipLists({});
  };

  const getListDebtors = async (isPlan = true) => {
    const { doFetchDebtorList } = accountDispatch;
    const listDebtors = await doFetchDebtorList({ sortByAlphabet: true, hideArchived: true, hideInactive: true });

    const otherOption = isPlan
      ? {
          value: 'ADD_PLAN_MANAGER',
          name: 'Add plan manager details',
        }
      : {
          value: 'ADD_SELF_MANAGER',
          name: 'Add self manager details',
        };

    element.properties.configuration[0].value = listDebtors.map((item) => ({
      value: item.debtorId,
      name: item.debtorName,
    }));
    element.properties.configuration[0].value.unshift(otherOption);
  };

  const getPermanentConditions = async () => {
    const { doFetchDisabilityList } = customersDispatch;
    const disabilities = await doFetchDisabilityList({});
    const optionsSelected = getOptionsSelected();

    element.properties.configuration[0].value = disabilities.map((item) => ({
      value: item.tagId,
      name: item.disabilityName,
      disabled: optionsSelected.includes(item.tagId),
    }));

    if (optionsSelected.includes(value)) setValue(null);

    setListOptions(element.properties.configuration[0].value);
  };

  const getListSupportCoordinators = async () => {
    const { doFetchSupportCoordinators, setSupportCoordinatorFilter } = customersDispatch;
    setSupportCoordinatorFilter({ supportWorkerServiceType: ServiceType.COORDINATION });

    const newOptionsList = await doFetchSupportCoordinators({});

    element.properties.configuration[0].value = newOptionsList.map((item) => ({
      value: item.supportCoordinatorId,
      name: item.fullName,
    }));
  };

  const checkDataDropdown = (isOpen: boolean) => {
    if (!isOpen || fieldType !== IntakeFormElementType.CARE_INFORMATION_PERMANENT_CONDITION) return;

    const optionsSelected = getOptionsSelected();

    const newOptions = element.properties.configuration[0]?.value?.map((option) => ({
      ...option,
      disabled: optionsSelected.includes(option.value),
    }));

    setListOptions(newOptions);
  };

  const getOptionsSelected = () => {
    const formValues = form.getFieldsValue();
    const mapForm = mapFormValueToElementValue(formValues);
    const optionsSelected = [];

    mapForm
      .filter((item) => [FormElementType.DROPDOWN, FormElementType.DROPDOWN_MULTI_SELECT].includes(item.elementType))
      .forEach((item) => {
        if (item.elementId === element.id) return;

        if (Array.isArray(item.elementData)) {
          optionsSelected.push(...item.elementData);
        } else {
          optionsSelected.push(item.elementData);
        }
      });

    return optionsSelected;
  };

  const updateElement = async () => {
    const fieldType = element.properties.general?.[0]?.fieldType;
    if (!fieldType) return;

    setLoading(true);
    formBuilderDispatch.setLoadingFormData(true);
    switch (fieldType) {
      case IntakeFormElementType.GENERAL_INFORMATION_HOBBIES:
        element.properties.configuration[0].value = hobbies.map((item) => ({ value: item.tagId, name: item.tag }));
        break;
      case IntakeFormElementType.GENERAL_INFORMATION_LANGUAGES:
      case IntakeFormElementType.GENERAL_INFORMATION_TRANSLATOR_LANGUAGE:
        // Moved to Languages useEffect queryOptions
        return;
      case IntakeFormElementType.GENERAL_INFORMATION_RELIGION:
        element.properties.configuration[0].value = religion.map((item) => ({ value: item.tagId, name: item.tag }));
        break;
      case IntakeFormElementType.GENERAL_INFORMATION_COUNTRY_OF_BIRTH:
        await getCountries();
        break;
      case IntakeFormElementType.ENQUIRIES_CUSTOMER_SOURCE:
        element.properties.configuration[0].value = customerSource.map((item) => ({
          value: item.tagId,
          name: item.tag,
        }));
        break;
      case IntakeFormElementType.ENQUIRIES_REQUESTED_SERVICES:
        await getRequestedServices();
        break;
      case IntakeFormElementType.CONTACTS_ADDITIONAL_CONTACTS_RELATIONSHIP:
      case IntakeFormElementType.CONTACTS_LEGAL_GUARDIAN_RELATIONSHIP:
        getRelationship();
        break;
      case IntakeFormElementType.CARE_INFORMATION_PERMANENT_CONDITION:
        await getPermanentConditions();
        break;
      case IntakeFormElementType.SELECT_EXIST_OR_ADD_PLAN_MANAGER:
      case IntakeFormElementType.SELECT_EXIST_OR_ADD_SELF_MANAGER:
        await getListDebtors(fieldType === IntakeFormElementType.SELECT_EXIST_OR_ADD_PLAN_MANAGER);
        break;
      case IntakeFormElementType.FUNDING_SUPPORT_COORDINATOR:
        await getListSupportCoordinators();
        break;
    }

    setListOptions(element.properties.configuration[0].value);
    formBuilderDispatch.setLoadingFormData(false);
    setLoading(false);
  };

  useEffect(() => {
    if (value === null) {
      setValue(defaultValue === 'No default' ? '' : defaultValue);
    }

    updateElement();
  }, []);

  useEffect(() => {
    if (
      [
        IntakeFormElementType.CONTACTS_ADDITIONAL_CONTACTS_RELATIONSHIP,
        IntakeFormElementType.CONTACTS_LEGAL_GUARDIAN_RELATIONSHIP,
      ].includes(element.properties.general?.[0]?.fieldType)
    ) {
      const options = relationships.map((relationGroup) => {
        const relationsOfGroup = !isEmpty(relationGroup.relations)
          ? relationGroup.relations.map((relation) => relation.relationTypeName).sort()
          : [];

        return {
          groupName: relationGroup.relationGroupName,
          options: [...relationsOfGroup],
        };
      });

      setListOptions(options);
    }
  }, [relationships]);

  // Assign element options if query relevant and not loading/error
  useEffect(() => {
    if (data && !isLoading && !error) {
      const queryOptions = data.data.map((item) => ({ value: item.tagId, name: item.tag }));

      formBuilderDispatch.setLoadingFormData(false);
      setListOptions(queryOptions);
      setLoading(false);
    }
  }, [isLoading]);

  return (
    <Row className='mt-large'>
      <EditDropdown
        value={value}
        form={form}
        options={listOptions}
        element={element}
        isMultiSelect={isMultiSelect}
        placeholder={element.properties.appearance.find((prop) => prop.key === PropKey.PLACEHOLDER_TEXT)?.value}
        onChange={(value) => (typeof onChange === 'function' ? onChange(value) : onChangeValueDropdownOptions(value))}
        isLoading={isFormLoading}
        onDropdownVisibleChange={checkDataDropdown}
      />
    </Row>
  );
};
