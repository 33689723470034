import { useMemo } from 'react';

import { replaceUnderscoreFormatter } from './formatters';
import { TimezoneFilter, TimezoneFormatter } from './types';

const filterTimezones = (timezones: string[], filter: TimezoneFilter) => {
  if (!filter) {
    return timezones;
  }

  if (typeof filter === 'string') {
    return timezones.filter((timezone) => {
      return timezone.toLowerCase().includes(filter.toLowerCase());
    });
  }

  if (typeof filter === 'function') {
    return timezones.filter(filter);
  }

  return timezones;
};

export function useTimezones(filter?: TimezoneFilter, formatTimezone: TimezoneFormatter = replaceUnderscoreFormatter) {
  return useMemo(() => {
    const allTimezones = Intl.supportedValuesOf('timeZone');
    const filteredTimezones = filterTimezones(allTimezones, filter);

    return {
      unformatted: filteredTimezones,
      formatted: filteredTimezones.map(formatTimezone),
    };
  }, [filter, formatTimezone]);
}

export function useTimezonesForSelect(
  filter?: TimezoneFilter,
  formatTimezone: TimezoneFormatter = replaceUnderscoreFormatter,
) {
  const timezones = useTimezones(filter, formatTimezone);

  return useMemo(() => {
    const timezonesForSelect = timezones.unformatted.map((timezone, index) => {
      return {
        label: timezones.formatted[index] ?? timezone,
        value: timezone,
      };
    });

    return timezonesForSelect;
  }, [timezones]);
}
