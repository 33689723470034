import React from 'react';
import { Icon } from 'antd';
import { Text, Inline } from '@goodhuman-me/components';

import { colorValues } from 'design-components/theme/tokens';

interface ICancelBookingFieldErrorProps {
  message: string;
}

const CancelBookingFieldError = ({ message }: ICancelBookingFieldErrorProps) => {
  if (!message) {
    return null;
  }

  return (
    <Inline alignItems="center" gap="$xxsmall" data-testid="cancel-booking-field-error">
      <Icon type="info-circle" className="text-size-default mr-x-small" style={{ color: colorValues.redDark1 }} />
      <Text fontSize="default" fontWeight={500} color="$redDark1">
        {message}
      </Text>
    </Inline>
  );
};

export default CancelBookingFieldError;
