import React from 'react';
import PlacesAutocomplete, { PropTypes } from 'react-places-autocomplete';

import { useLocaleConfig, type CountryCode } from 'providers/locale-provider';

export const PlacesAutocompleteWithLocale = ({ children, ...props }: PropTypes) => {
  const { available: availableLocaleConfigs } = useLocaleConfig();
  const country: CountryCode[] = availableLocaleConfigs.map((locale) => locale.countryCode);

  return (
    <PlacesAutocomplete
      {...props}
      searchOptions={{
        ...props.searchOptions,
        componentRestrictions: {
          ...props.searchOptions?.componentRestrictions,
          country,
        },
      }}
    >
      {children}
    </PlacesAutocomplete>
  );
};
