import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const TextSlash = forwardRef(function TextSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 4.75c.493 0 .787.002.997.03a.704.704 0 0 1 .177.042l.003.001.001.003a.703.703 0 0 1 .042.177c.028.21.03.504.03.997h1.5v-.045c0-.433 0-.83-.043-1.152-.048-.356-.16-.731-.47-1.04-.309-.31-.684-.422-1.04-.47-.323-.043-.72-.043-1.152-.043H8.5a.75.75 0 1 0 0 1.5h2.75V8a.75.75 0 0 0 1.5 0V4.75H17Zm-4.25 9.06v5.44H16a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1 0-1.5h3.25v-6.94L3.47 4.53a.75.75 0 0 1 1.06-1.06l16 16a.75.75 0 1 1-1.06 1.06l-6.72-6.72Z"
        fill="currentColor"
      />
    </Icon>
  );
});
