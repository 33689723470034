import { notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { AvailabilityStatusTag } from 'common-components/tags';
import { Paragraph, Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import {
  AvailabilityUnavailabilityRequestModalType,
  AvailabilityUnavailabilityRequestStatus,
  AvailabilityUnavailabilityRequestTabType,
  UnavailabilityType,
} from 'utilities/enum-utils';
import { calculateCurrentWeekForAvailability } from '../utils/availability-utils';
import RenderAvailability from './Availability/RenderAvailability';
import RenderUnavailability from './Availability/RenderUnavailability';
import DeclineApproveRequestModal from './DeclineApproveRequestModal';
import UnavailableModal from './UnavailableModal';

interface IAvailabilityUnavailabilityDetailsProps {
  tabType: AvailabilityUnavailabilityRequestTabType;
  doGetTimeAvailability: typeof dispatch.teamStore.doGetTimeAvailability;
  memberAvailabilities: typeof state.teamStore.memberAvailabilities;
  requestDetail: any;
  doCheckAvailabilityConflict: typeof dispatch.teamStore.doCheckAvailabilityConflict;
  doApproveRequest: typeof dispatch.teamStore.doApproveRequest;
  doDeclineRequest: typeof dispatch.teamStore.doDeclineRequest;
  type: AvailabilityUnavailabilityRequestModalType;
  onClose: () => void;
  doCancelRequest: typeof dispatch.teamStore.doCancelRequest;
}

const AvailabilityUnavailabilityDetails = ({
  doGetTimeAvailability,
  memberAvailabilities,
  tabType,
  requestDetail,
  doCheckAvailabilityConflict,
  doApproveRequest,
  doDeclineRequest,
  type,
  onClose,
  doCancelRequest,
}: IAvailabilityUnavailabilityDetailsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isUnavailableModalOpen, setIsUnavailableModalOpen] = useState(false);
  const [conflictShifts, setConflictShifts] = useState(null);
  const [isUndoModalOpen, setIsUndoModalOpen] = useState(false);
  const [mappedAvailabilities, setMappedAvailabilities] = useState([]);

  const _weekDayString = (day) => {
    switch (day) {
      case 'MON':
      case 1:
        return 'Monday';
      case 'TUE':
      case 2:
        return 'Tuesday';
      case 'WED':
      case 3:
        return 'Wednesday';
      case 'THU':
      case 4:
        return 'Thursday';
      case 'FRI':
      case 5:
        return 'Friday';
      case 'SAT':
      case 6:
        return 'Saturday';
      case 'SUN':
      case 0:
        return 'Sunday';
      default:
        return '';
    }
  };

  const _availabilitiesMapping = (availabilities) => {
    const { availabilityTimezone } = memberAvailabilities;

    const mappedList = availabilities
      ? _.map(availabilities, (item) => ({
          day: _weekDayString(item.day),
          number: item.number,
          timeRange: _.map(item.timeRange, (time) => ({
            startDateTime: moment.tz(time.startTime, availabilityTimezone),
            endDateTime: moment.tz(time.endTime, availabilityTimezone),
            error: null,
          })),
          isAvailableAllDay: item.isAllDay,
          week: item.weeklyCycle,
        }))
      : [];
    const sunday = _.filter(mappedList, (item) => item.number === 0);
    const withoutSundayList = _.filter(mappedList, (item) => item.number !== 0);
    const sortedList = _.sortBy(withoutSundayList, (item) => item.number);

    return sortedList.concat(sunday);
  };

  const _unavailabilitiesMapping = (unavailabilities) => {
    const { availabilityTimezone } = memberAvailabilities;
    const mappedList = unavailabilities
      ? _.map(unavailabilities, (item) => ({
          day: _weekDayString(item.weekDayId),
          number: item.weekDayId,
          timeRange: _.map(item.timeRange, (time) => ({
            startDateTime: moment.tz(time.startTime, availabilityTimezone),
            endDateTime: moment.tz(time.endTime, availabilityTimezone),
            error: null,
          })),
          unavailabilityOption: item.unavailabilityOption,
        }))
      : [];

    return mappedList;
  };

  const _getTimeAvailability = async () => {
    setIsLoading(true);
    try {
      await doGetTimeAvailability({});
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const _generateSuccessNotif = () =>
    type === AvailabilityUnavailabilityRequestModalType.AVAILABILITY
      ? {
          message: 'General availability updated',
          description: 'You have successfully updated this team members general availability',
        }
      : {
          message: 'Unavailability approved',
          description: 'You have successfully approved this unavailability',
        };

  const _approveRequest = async () => {
    const result = await doCheckAvailabilityConflict(
      type === AvailabilityUnavailabilityRequestModalType.AVAILABILITY
        ? { timeAvailability: requestDetail.availabilityRequests, availabilityTimezone: requestDetail.timezone }
        : {
            unavailability: {
              ...requestDetail.unavailabilityRequest,
              customUnavailabilityWeekDays: requestDetail.unavailabilityRequest.customUnavailability,
              timezone: requestDetail.timezone,
            },
          },
    );
    if (!_.isEmpty(result)) {
      setConflictShifts(result);
      setIsUnavailableModalOpen(true);
      return result;
    } else {
      await doApproveRequest({
        supportWorkerAvailabilityRequestId: requestDetail.supportWorkerAvailabilityRequestId,
        shiftsSelectedToBeKept: [],
      });
    }
  };

  const _onConfirmApprove = async () => {
    try {
      let result;
      if (requestDetail.status === AvailabilityUnavailabilityRequestStatus.REQUEST_CANCELLATION) {
        await doCancelRequest({
          supportWorkerAvailabilityRequestId: requestDetail.supportWorkerAvailabilityRequestId,
        });
      } else {
        result = await _approveRequest();
      }
      setIsApproveModalOpen(false);
      setIsUndoModalOpen(false);
      if (_.isEmpty(result)) {
        notification.success(_generateSuccessNotif());
        onClose();
      }
    } catch (e) {
      setIsApproveModalOpen(false);
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  const _onConfirmDecline = async () => {
    if (requestDetail.status === AvailabilityUnavailabilityRequestStatus.REQUEST_CANCELLATION) {
      const result = await _approveRequest();
      setIsDeclineModalOpen(false);
      if (_.isEmpty(result)) {
        notification.success(_generateSuccessNotif());
        onClose();
      }
      return;
    }
    try {
      await doDeclineRequest({
        supportWorkerAvailabilityRequestId: requestDetail.supportWorkerAvailabilityRequestId,
      });
      setIsDeclineModalOpen(false);
      notification.success(_generateSuccessNotif());
      onClose();
    } catch (e) {
      setIsDeclineModalOpen(false);
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  const _generateApproveContent = (): { title: string; content } => {
    if (type === AvailabilityUnavailabilityRequestModalType.AVAILABILITY) {
      const totalAvailabilityRequestPending = _.filter(
        memberAvailabilities.timeAvailabilityRequest,
        (item) => item.status === AvailabilityUnavailabilityRequestStatus.PENDING_APPROVAL,
      ).length;
      return {
        title: 'Approve request',
        content: (
          <>
            <Paragraph>You are about to approve this request.</Paragraph>
            {totalAvailabilityRequestPending > 1 && (
              <Paragraph>
                {
                  'This team member has multiple availbiltiy change requests pending approval. Approving this request will decline all other pending requests.'
                }
              </Paragraph>
            )}
            <Paragraph>
              {'Once approved you will see the changes reflected on the team members general availability'}
            </Paragraph>
            <Paragraph>Ready to approve?</Paragraph>
          </>
        ),
      };
    } else {
      return {
        title:
          requestDetail.status === AvailabilityUnavailabilityRequestStatus.PENDING_APPROVAL
            ? 'Approve request'
            : 'Approve cancellation request',
        content: (
          <Paragraph>{`You’re about to approve this ${
            requestDetail.status === AvailabilityUnavailabilityRequestStatus.REQUEST_CANCELLATION ? 'cancellation' : ''
          } request.`}</Paragraph>
        ),
      };
    }
  };

  const _generateDeclineContent = (): { title: string; content } => {
    if (type === AvailabilityUnavailabilityRequestModalType.AVAILABILITY) {
      return {
        title: 'Decline request',
        content: (
          <>
            <Paragraph>You are about to decline this request. This action cannot be undone.</Paragraph>
            <Paragraph>Are you sure?</Paragraph>
          </>
        ),
      };
    } else {
      return {
        title:
          requestDetail.status === AvailabilityUnavailabilityRequestStatus.PENDING_APPROVAL
            ? 'Decline request'
            : 'Decline cancellation request',
        content: (
          <>
            {requestDetail.status === AvailabilityUnavailabilityRequestStatus.PENDING_APPROVAL ? (
              <Paragraph>You are about to decline this request. This action cannot be undone.</Paragraph>
            ) : (
              <Paragraph>
                You are about to decline this request. The unavailability will return to ‘Approved’ status.
              </Paragraph>
            )}
            <Paragraph>Do you want to proceed?</Paragraph>
          </>
        ),
      };
    }
  };

  const description = {
    action:
      requestDetail.status === AvailabilityUnavailabilityRequestStatus.CANCELLED
        ? 'Cancelled'
        : requestDetail.status === AvailabilityUnavailabilityRequestStatus.DECLINED
        ? 'Declined'
        : requestDetail.status === AvailabilityUnavailabilityRequestStatus.APPROVED
        ? 'Approved'
        : 'Created',
    name: requestDetail.updatedBy,
    time: moment.tz(requestDetail.updatedDate, requestDetail.timezone).format('DD MMM YYYY, hh:mm A'),
  };

  const renderAvailableUnavailableDateTime = (item) => {
    if (AvailabilityUnavailabilityRequestModalType.AVAILABILITY === type) {
      const isTwoWeekCycle = item.length === 14;
      const isEvenWeekNow = moment().isoWeek() % 2 === 0;
      const currentWeek = _.find(item, (availability) => availability.isEvenWeek === isEvenWeekNow);
      const currentWeekCycle = currentWeek ? currentWeek.weeklyCycle : 1;

      const { startDateTime, endDateTime } = calculateCurrentWeekForAvailability(isTwoWeekCycle, currentWeekCycle);

      return (
        <>
          {moment
            .tz(isTwoWeekCycle ? startDateTime[0] : startDateTime, requestDetail.timezone)
            .format('ddd, DD MMM YYYY')}{' '}
          -{' '}
          {moment.tz(isTwoWeekCycle ? endDateTime[1] : endDateTime, requestDetail.timezone).format('ddd, DD MMM YYYY')}
        </>
      );
    }
    return (
      <>
        {moment.tz(item.startDate, requestDetail.timezone).format('ddd, DD MMM YYYY')} -{' '}
        {moment.tz(item.endDate, requestDetail.timezone).format('ddd, DD MMM YYYY')}
      </>
    );
  };

  useEffect(() => {
    _getTimeAvailability();
  }, []);

  useEffect(() => {
    if (type === AvailabilityUnavailabilityRequestModalType.AVAILABILITY) {
      setMappedAvailabilities(_availabilitiesMapping(requestDetail.availabilityRequests));
    } else if (type === AvailabilityUnavailabilityRequestModalType.UNAVAILABILITY) {
      setMappedAvailabilities(_unavailabilitiesMapping(requestDetail.unavailabilityRequest.customUnavailability));
    }
  }, [requestDetail]);

  return (
    <div className="bg-quaternary p-large">
      {isDeclineModalOpen && (
        <DeclineApproveRequestModal
          {..._generateDeclineContent()}
          actionColor="red"
          primaryButtonText="Decline request"
          onConfirm={_onConfirmDecline}
          onClose={() => setIsDeclineModalOpen(false)}
        />
      )}

      {isApproveModalOpen && (
        <DeclineApproveRequestModal
          {..._generateApproveContent()}
          primaryButtonText="Approve request"
          onConfirm={_onConfirmApprove}
          onClose={() => setIsApproveModalOpen(false)}
        />
      )}

      {isUndoModalOpen && (
        <DeclineApproveRequestModal
          title="Undo cancellation request"
          content={
            <>
              <Paragraph>You are about to undo this cancellation request.</Paragraph>
              <Paragraph>
                This will return the unvialbility to ‘Approved’ and may impact the team members shifts.
              </Paragraph>
            </>
          }
          primaryButtonText="Undo cancellation"
          onConfirm={_onConfirmApprove}
          onClose={() => setIsUndoModalOpen(false)}
        />
      )}

      {isUnavailableModalOpen && (
        <UnavailableModal
          isOpen={isUnavailableModalOpen}
          onClose={() => {
            setIsUnavailableModalOpen(false);
            setIsApproveModalOpen(false);
            setIsUndoModalOpen(false);
            onClose();
          }}
          conflictShifts={conflictShifts}
          supportWorkerAvailabilityRequestId={requestDetail.supportWorkerAvailabilityRequestId}
          timezone={memberAvailabilities.userTimezone}
        />
      )}

      <div className="flex-row justify-between">
        <div className="flex-row">
          <Text className="text-size-large mr-12">
            {type === AvailabilityUnavailabilityRequestModalType.AVAILABILITY ? 'General availability' : 'Unavailable'}
          </Text>
          <div>
            <AvailabilityStatusTag status={requestDetail.status} size={'small'} />
          </div>
        </div>

        {(tabType === AvailabilityUnavailabilityRequestTabType.AVAILABILITY_REQUESTS ||
          tabType === AvailabilityUnavailabilityRequestTabType.UNAVAILABILITY_REQUESTS) && (
          <div>
            <SecondaryButton className="mr-medium" color="red" onClick={() => setIsDeclineModalOpen(true)}>
              Decline
            </SecondaryButton>
            <PrimaryButton onClick={() => setIsApproveModalOpen(true)}>Approve</PrimaryButton>
          </div>
        )}

        {tabType === AvailabilityUnavailabilityRequestTabType.CANCELLED &&
          (requestDetail.status === AvailabilityUnavailabilityRequestStatus.DECLINED ? (
            <div>
              <PrimaryButton onClick={() => setIsApproveModalOpen(true)}>Approve</PrimaryButton>
            </div>
          ) : (
            <div>
              <PrimaryButton onClick={() => setIsUndoModalOpen(true)}>Undo</PrimaryButton>
            </div>
          ))}
      </div>
      <Text className="text-size-large mr-12" weight="bold">
        {type === AvailabilityUnavailabilityRequestModalType.UNAVAILABILITY
          ? renderAvailableUnavailableDateTime(requestDetail.unavailabilityRequest)
          : renderAvailableUnavailableDateTime(requestDetail.availabilityRequests)}
      </Text>
      <div className="description mt-small text-color-secondary text-size-regular">
        {description.action} by <b>{description.name}</b> on {description.time}
      </div>
      <div className="mt-medium">
        <Text color="secondary">TIME SLOTS</Text>
        {type === AvailabilityUnavailabilityRequestModalType.AVAILABILITY ? (
          <RenderAvailability
            availableTimes={mappedAvailabilities}
            cycle={memberAvailabilities.availabilityCycle}
            activeWeek={memberAvailabilities.availabilityActiveWeek}
            activeDate={memberAvailabilities.activeDate}
            isLoading={isLoading}
          />
        ) : requestDetail.unavailabilityRequest.unavailabilityType !== UnavailabilityType.ONE_OFF ? (
          <RenderUnavailability
            unavailableTimes={mappedAvailabilities}
            cycle={memberAvailabilities.availabilityCycle}
            activeWeek={memberAvailabilities.availabilityActiveWeek}
            activeDate={memberAvailabilities.activeDate}
            isLoading={isLoading}
            timezone={requestDetail.timezone}
          />
        ) : (
          ''
        )}
      </div>
      {type === AvailabilityUnavailabilityRequestModalType.UNAVAILABILITY && (
        <div className="mt-medium">
          <Text color="secondary">COMMENTS</Text>
          <Paragraph>{requestDetail.unavailabilityRequest.comment}</Paragraph>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  memberAvailabilities: state.teamStore.memberAvailabilities,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doGetTimeAvailability: dispatch.teamStore.doGetTimeAvailability,
  doCheckAvailabilityConflict: dispatch.teamStore.doCheckAvailabilityConflict,
  doApproveRequest: dispatch.teamStore.doApproveRequest,
  doDeclineRequest: dispatch.teamStore.doDeclineRequest,
  doCancelRequest: dispatch.teamStore.doCancelRequest,
});
export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityUnavailabilityDetails);
