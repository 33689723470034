import React from 'react';
import { Checkbox } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Controller, useFormContext } from 'react-hook-form';

import { Text } from 'common-components/typography';
import { TimeColumn } from './time-column';
import { DurationColumn } from './duration-column';
import { EditRatiosSchema } from './schema';

interface ITimeDurationColumnsProps {
  setErrorOffset: (offset: number) => void;
  index: number;
}

export function TimeDurationColumns({ setErrorOffset, index }: ITimeDurationColumnsProps) {
  const form = useFormContext<EditRatiosSchema>();
  const isExactTime = form.watch(`ratios.${index}.isExactTime`);
  const { pinc1008NonTimeBoundRatiosDuration } = useFlags();

  const onTimeDurationPositionChange = (node) => {
    setErrorOffset(node?.offsetLeft);
  };

  return (
    <div className="align-center flex" style={{ gap: 16 }}>
      <div>
        <Text>{isExactTime ? 'from' : 'for'}</Text>
      </div>
      <div className="align-left flex-column flex" style={{ gap: 4 }}>
        <div className="align-center flex" style={{ gap: 16 }} ref={onTimeDurationPositionChange}>
          {isExactTime ? <TimeColumn index={index} /> : <DurationColumn index={index} />}
          {pinc1008NonTimeBoundRatiosDuration && (
            <div>
              <Controller
                name={`ratios.${index}.isExactTime`}
                control={form.control}
                render={({ field: { onChange, value, ref } }) => (
                  <Checkbox value={value} ref={ref} onChange={onChange} checked={value}>
                    Set exact times
                  </Checkbox>
                )}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
