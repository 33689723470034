import { IServiceListItemLite } from 'interfaces/service-interfaces';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchServicesLite } from 'stores/queries/services/services-queries';

export type FetchServicesLiteRequest = {
  paymentSourceType?: string;
  serviceType?: string[];
  status?: string[];
  isGetAll?: boolean;
};

export const useFetchServicesLite = (request: FetchServicesLiteRequest): UseQueryResult<IServiceListItemLite[]> => {
  return useQuery(['fetchServicesLite', JSON.stringify(request)], () => fetchServicesLite(request));
};
