import React, { useState, useCallback } from 'react';

import ActionModal from 'common-components/modal/ActionModal';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { ISession } from 'interfaces/session-interfaces';
import EditLeaveForm from 'views/group-services/session-details/forms/EditLeaveForm';

interface IEditLeaveModalModalProps {
  isOpen: boolean;
  onClose: (targetFlag, refreshShiftSlots?: boolean) => void;
  session: ISession;
  shiftSlot: IShiftSlot;
}

const EditLeaveModal = (props: IEditLeaveModalModalProps) => {
  const { isOpen, onClose, session, shiftSlot } = props;
  const [canClose, setCanClose] = useState<boolean>(false);

  const handleClose = () => {
    onClose({ targetFlag: 'isEditLeaveOpen' }, true);
  };

  return (
    <ActionModal
      isOpen={isOpen}
      title="Edit leave"
      showCloseButton
      onClose={handleClose}
      width="large"
      canCloseOutside={canClose}
    >
      <EditLeaveForm
        shiftSlot={shiftSlot}
        session={session}
        onCancel={handleClose}
        onSuccess={handleClose}
        setCanCancel={useCallback(setCanClose, [setCanClose])}
      />
    </ActionModal>
  );
};

export default React.memo(EditLeaveModal);
