import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CallAdd = forwardRef(function CallAdd(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.527 2.25h-.065c-.383 0-.686 0-.983.066-1.084.242-1.995 1.246-2.13 2.348-.038.302-.012.562.02.885l.004.047c.258 2.64.917 5.015 1.957 7.085 1.545 3.074 3.915 5.444 6.99 6.99 2.069 1.039 4.444 1.698 7.084 1.956l.047.004c.323.032.583.058.885.02 1.103-.135 2.106-1.046 2.348-2.13.066-.297.066-.6.066-.983v-.125c0-.332 0-.59-.042-.83a2.75 2.75 0 0 0-1.587-2.033c-.222-.099-.472-.161-.795-.242l-.058-.014-1.655-.414-.036-.009c-.56-.14-1.036-.259-1.438-.316-.43-.06-.84-.063-1.26.074-.42.137-.75.38-1.061.681-.292.284-.607.66-.977 1.103l-.024.029-1.348 1.612a13.897 13.897 0 0 1-5.523-5.523l1.612-1.348.029-.024c.443-.37.82-.685 1.103-.977.301-.31.544-.64.68-1.061.138-.42.136-.83.075-1.26-.057-.402-.176-.878-.316-1.438l-.01-.036-.413-1.655-.014-.058c-.08-.323-.143-.573-.242-.795a2.75 2.75 0 0 0-2.034-1.587c-.238-.042-.497-.042-.829-.042h-.06ZM18.55 20.134c-2.109-.206-4.01-.682-5.691-1.402l1.11-1.328c.4-.48.665-.795.895-1.017.216-.21.355-.29.48-.332.127-.04.286-.057.585-.015.316.045.715.144 1.322.295l1.654.414c.408.102.52.133.605.17.38.17.65.516.722.925.016.092.02.208.02.629 0 .478-.005.605-.031.721-.104.465-.594.91-1.067.969-.121.014-.217.009-.604-.03ZM6.596 10.032l-1.328 1.11c-.72-1.68-1.196-3.582-1.402-5.691-.038-.387-.043-.483-.029-.604.059-.473.504-.963.969-1.067.116-.026.243-.03.721-.03.42 0 .537.003.629.019.409.072.755.343.924.722.038.085.069.197.171.605l.414 1.654c.151.607.25 1.006.295 1.322.042.3.026.458-.016.584-.04.126-.12.265-.33.481-.223.23-.538.494-1.018.895ZM17.75 4a.75.75 0 0 0-1.5 0v2.25H14a.75.75 0 0 0 0 1.5h2.25V10a.75.75 0 1 0 1.5 0V7.75H20a.75.75 0 0 0 0-1.5h-2.25V4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
