import React from 'react';
import { Box, Flex, MantineProvider, ScrollArea } from '@good/ui/core';

import { NavbarLinks } from './navbar-links';
import { ColorSwitch } from './color-switch';

type NavbarProps = {
  onLinkSelect: () => void;
  defaultColor?: 'light' | 'dark';
};

export function Navbar({ onLinkSelect, defaultColor = 'dark' }: NavbarProps) {
  return (
    <MantineProvider
      defaultColorScheme={defaultColor}
      getRootElement={() => document.getElementById('navbar-root') ?? undefined}
      cssVariablesSelector='#navbar-root'
    >
      <Flex
        h='100%'
        direction='column'
        py='md'
        id='navbar-root'
        data-testid='navbar-root'
        bg='var(--mantine-color-body)'
        justify='space-between'
      >
        <ScrollArea>
          <NavbarLinks onLinkSelect={onLinkSelect} />
        </ScrollArea>
        <Box px='md'>
          <ColorSwitch />
        </Box>
      </Flex>
    </MantineProvider>
  );
}
