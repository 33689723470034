import SpinningLoader from 'common-components/loading/SpinningLoader';
import { Paragraph, Title } from 'common-components/typography';
import * as H from 'history';
import { IBrand } from 'interfaces/account-interfaces';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import AdjustBrandingLogoModal from './components/BrandingPageComponents/AdjustBrandingLogoModal';
import LogoSection from './components/BrandingPageComponents/LogoSection';
import UploadLogoDescriptionSection from './components/BrandingPageComponents/UploadLogoDescriptionSection';
import { LogoShape, LogoShapeExtensions } from './utils/constants';

interface IProps {
  history: H.History;
}

const BrandingView = (props: IProps) => {
  const dispatch = useDispatch<IRootDispatch>();
  const brandInfo = useSelector((state: IRootState) => state.accountStore.brandInfo as IBrand | null);
  const companyName = useSelector((state: IRootState) => state.companyStore.companyDataLite?.serviceProviderName);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenAdjustLogoModal, setIsOpenAdjustLogoModal] = useState<boolean>(false);
  const [logoShape, setLogoShape] = useState<LogoShape>(undefined);

  const openAdjustLogoModal = (type: LogoShape) => {
    setLogoShape(type);
    setIsOpenAdjustLogoModal(true);
  };

  const closeAdjustLogoModal = () => {
    setIsOpenAdjustLogoModal(false);
  };

  const handlePreviewInvoice = () => {
    window.open(`/pdf?type=preview-brand-invoice-uploaded`, '_blank');
  };

  const fetchBrandInfo = async () => {
    setIsLoading(true);
    await dispatch.accountStore.doGetBrandInfo();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBrandInfo();
  }, []);

  const _renderLogoSection = (type: LogoShape) => {
    return (
      <>
        <Paragraph size='x2-large' style={{ marginBottom: type === LogoShape.FULL_LOGO ? 18 : 13 }}>
          {LogoShapeExtensions[type].title}
        </Paragraph>
        <div className='flex'>
          <div style={{ width: 342, flexShrink: 0 }}>
            {isLoading ? (
              <SpinningLoader size={100} message='Fetching logo' />
            ) : (
              <LogoSection
                shape={type}
                brandInfo={brandInfo}
                companyName={companyName}
                onOpenAdjustModal={() => openAdjustLogoModal(type)}
              />
            )}
          </div>
          <div className='flex-grow ml-large'>
            <UploadLogoDescriptionSection
              shape={type}
              onPreviewInvoice={handlePreviewInvoice}
              previewable={!!brandInfo?.attachmentUrl}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <>
        <Title level={3} weight='regular' className='mv-none mb-large'>
          <span className={'cursor-pointer text-color-blue-action'} onClick={() => props.history.push('landing')}>
            {'Account >'}
          </span>
          &nbsp; Branding
        </Title>
        <Paragraph className='mb-x4-large'>
          Customise how your brand appears on invoices, service agreements and more.
        </Paragraph>
        {_renderLogoSection(LogoShape.FULL_LOGO)}
        {_renderLogoSection(LogoShape.SQUARE_LOGO)}
      </>

      <AdjustBrandingLogoModal
        shape={logoShape}
        brandInfo={brandInfo}
        isOpen={isOpenAdjustLogoModal}
        onClose={closeAdjustLogoModal}
      />
    </>
  );
};

export default BrandingView;
