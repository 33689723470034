import { Card, Form, Icon } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton } from 'common-components/buttons';
import { Title } from 'common-components/typography';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IRootState, state } from 'src/stores/rematch/root-store';
import WorkflowTemplateSummarySection from './WorkflowTemplateSummarySection';

interface ICreateWorkflowTemplateCompletedStepCardProps extends FormComponentProps {
  onClose(): void;
  addWorkflowTemplateWizard: typeof state.workflowStore.addWorkflowTemplateWizard;
}

class CreateWorkflowTemplateCompletedStepCard extends PureComponent<ICreateWorkflowTemplateCompletedStepCardProps> {
  render() {
    const { addWorkflowTemplateWizard } = this.props;

    if (!addWorkflowTemplateWizard) {
      return <></>;
    }

    return (
      <>
        <Card bordered={false} className="shadow-container rounded-big">
          <Title level={4} weight="regular" className="flex align-center mb-large text-color-blue-action">
            <Icon type="check-circle" theme="filled" className="height-full mr-small" />
            Workflow successfully created
          </Title>

          <WorkflowTemplateSummarySection addWorkflowTemplateWizard={addWorkflowTemplateWizard} />
        </Card>

        <div className="justify-end flex p-large">
          <PrimaryButton size="large" onClick={this.props.onClose}>
            Close
          </PrimaryButton>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  addWorkflowTemplateWizard: state.workflowStore.addWorkflowTemplateWizard,
});

export default connect(
  mapState,
  null,
)(Form.create<ICreateWorkflowTemplateCompletedStepCardProps>()(CreateWorkflowTemplateCompletedStepCard));
