import React from 'react';
import { Text, Flex, rem } from '@good/ui/core';

import { ImageIcon } from './image-icon';

type DropzoneContentProps = {
  title?: string;
};

export const DropzoneContent = (props: DropzoneContentProps) => {
  const { title = 'Add image' } = props;

  return (
    <>
      <ImageIcon />
      <Flex direction='column' align='center' gap={rem(8)}>
        <Text fw={700} ta='center'>
          {title}
        </Text>
        <Flex direction='column' align='center'>
          <Text size='sm' c='var(--brand-body-dark-1)' ta='center'>
            400 x 400 pixels or higher
          </Text>
          <Text size='sm' c='var(--brand-body-dark-1)' ta='center'>
            Less than 15 MB
          </Text>
          <Text size='sm' c='var(--brand-body-dark-1)' ta='center'>
            JPEG or PNG
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
