import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const EyeSlash = forwardRef(function EyeSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l2.667 2.668a9.999 9.999 0 0 0-2.78 2.766C1.644 10.042 1.25 11.15 1.25 12c0 .662.24 1.47.663 2.284A9.355 9.355 0 0 0 3.866 16.8C5.627 18.426 8.308 19.75 12 19.75c2.18 0 4.011-.461 5.51-1.178l2.96 2.958a.75.75 0 0 0 1.06-1.06l-18-18Zm12.845 14.966-2.307-2.307A3.75 3.75 0 0 1 8.87 9.932l-2.649-2.65a8.555 8.555 0 0 0-2.614 2.51c-.62.937-.858 1.758-.858 2.208 0 .338.136.905.493 1.591.35.673.892 1.416 1.64 2.108 1.49 1.375 3.81 2.551 7.117 2.551 1.721 0 3.172-.319 4.375-.814ZM9.969 11.03a2.25 2.25 0 0 0 3.001 3.001l-3-3.001ZM12 5.75c-1.04 0-1.981.116-2.827.317a.75.75 0 0 1-.346-1.46A13.7 13.7 0 0 1 12 4.25c3.692 0 6.373 1.324 8.134 2.949a9.355 9.355 0 0 1 1.953 2.517c.424.814.663 1.622.663 2.284 0 1.21-.804 3.005-2.335 4.531a.75.75 0 1 1-1.059-1.062c1.36-1.355 1.894-2.79 1.894-3.469 0-.338-.136-.905-.493-1.591a7.86 7.86 0 0 0-1.64-2.108C17.626 6.926 15.306 5.75 12 5.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
