import { Avatar } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';
import { TeamStatus } from 'utilities/enum-utils';

export function DuplicateItem({ name, type, avatarImage, status }) {
  return (
    <div
      className="bg-white flex-column bordered-top bordered-bottom hover-bg-blue-lightest"
      style={{ height: '64px' }}
    >
      <div className="flex-column flex-1 justify-center ph-medium relative">
        <div className="flex-row">
          <div className="mr-medium">
            <Avatar size="large" shape="square" src={avatarImage} className="avatar-medium" />
          </div>
          <div className="flex-column justify-center">
            <Text lineHeight={120} size="large">
              {name}
            </Text>
            <Text size="regular" lineHeight={120} color={'secondary'}>
              {type}
            </Text>
          </div>

          <div className="absolute" style={{ bottom: '12px', right: '12px' }}>
            <Text
              color={`${
                status === TeamStatus.BLOCKED
                  ? 'black'
                  : status === TeamStatus.DRAFT
                  ? 'secondary'
                  : status === TeamStatus.REMOVED
                  ? 'red-dark'
                  : 'green'
              }`}
              size="x-small"
            >
              {status.toString().toUpperCase()}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
