import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ThumbsUp = forwardRef(function ThumbsUp(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.11 3.014a.772.772 0 0 1 1.291.813l-2.09 4.878A.75.75 0 0 0 13 9.75h2.762c1.226 0 2.063.002 2.673.09.59.086.81.233.939.401.128.168.212.42.14 1.012-.075.611-.294 1.419-.616 2.602l-.819 3c-.198.726-.33 1.207-.474 1.563-.136.336-.252.482-.375.575-.122.094-.293.168-.654.21-.38.046-.88.047-1.632.047H10c-.964 0-1.611-.002-2.094-.067-.462-.062-.66-.169-.79-.3-.13-.13-.237-.327-.3-.788-.064-.483-.066-1.131-.066-2.095v-4.497c0-.798.011-1.008.08-1.191.069-.183.199-.348.724-.95l5.556-6.348Zm.581-1.764c-.655 0-1.279.283-1.71.776L6.425 8.375l-.072.082c-.419.479-.75.857-.927 1.327-.177.47-.176.974-.176 1.61V16.051c0 .899 0 1.648.08 2.242.084.628.27 1.195.726 1.65.455.456 1.022.642 1.65.726.594.08 1.344.08 2.242.08h5.04c.698 0 1.286 0 1.765-.056.509-.06.974-.192 1.389-.509.415-.316.664-.73.855-1.206.18-.447.336-1.014.52-1.687l.01-.042.819-3 .015-.058c.303-1.11.553-2.024.642-2.757.094-.764.036-1.486-.436-2.104-.472-.619-1.153-.864-1.915-.975-.73-.106-1.678-.106-2.83-.106H14.138l1.643-3.832A2.273 2.273 0 0 0 13.69 1.25ZM3.751 10a.75.75 0 1 0-1.5 0v10a.75.75 0 0 0 1.5 0V10Z"
        fill="currentColor"
      />
    </Icon>
  );
});
