import { Divider, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Header } from 'views/form-builder/components/form-elements';
import { EditCheckboxElement } from 'views/form-builder/components/form-elements/Checkbox/CheckboxElement';
import { getElementValueById, getValueFromPropertyByKey } from 'views/form-builder/shared/form-builder-utils';
import { FormElementType } from 'views/form-builder/shared/form-enum';
import { IElementValue, IFormContent, IFormElement } from 'views/form-builder/shared/form-interface';

type ILinkedFormElementProps = {
  formContent: IFormContent;
  formData?: IElementValue[];
} & FormComponentProps

const TIME_FORMAT = 'HH:mm';
const DATE_FORMAT = 'DD-MM-YYYY';

function LinkedFormElement({ formContent, form, formData }: ILinkedFormElementProps) {
  const { title } = formContent;

  const _renderHeader = (element: IFormElement, index: number) => {
    const heading = getValueFromPropertyByKey(element.properties.appearance, 'headingText');
    const subHeading = getValueFromPropertyByKey(element.properties.appearance, 'subHeadingText');
    return (
      <Row className="mt-large" key={index}>
        <Header headingText={heading} subHeadingText={subHeading} />
      </Row>
    );
  };

  const _renderParagraph = (element: IFormElement, index: number) => {
    const value = getValueFromPropertyByKey(element.properties.appearance, 'paragraphText');
    return (
      <Row className="mt-large" key={index}>
        <Paragraph className="whitespace-pre-line">{value}</Paragraph>
      </Row>
    );
  };

  const _renderDivider = (element: IFormElement, index: number) => {
    return (
      <Row key={index}>
        <Divider />
      </Row>
    );
  };

  const _renderInlineInput = (element: IFormElement, index: number) => {
    let value = null;

    if (formData) {
      value = getElementValueById(formData, element.id);
    }
    return (
      <Row className="mt-large" key={index}>
        <SubTitle>{element.properties.general.find((prop) => prop.key === 'fieldTitle').value}</SubTitle>
        <Text>{value ? value : '-'}</Text>
      </Row>
    );
  };

  const _renderLongText = (element: IFormElement, index: number) => {
    let value = null;

    if (formData) {
      value = getElementValueById(formData, element.id);
    }
    return (
      <Row className="mt-large" key={index}>
        <SubTitle>{element.properties.general.find((prop) => prop.key === 'fieldTitle').value}</SubTitle>
        <Text>{value ? value : '-'}</Text>
      </Row>
    );
  };

  const _renderNumberInput = (element: IFormElement, index: number) => {
    const defaultValue = getValueFromPropertyByKey(element.properties.configuration, 'default')
      ? getValueFromPropertyByKey(element.properties.configuration, 'defaultValue')
      : '';
    let value = null;

    if (formData) {
      value = getElementValueById(formData, element.id);
    }
    if (value === null) {
      value = defaultValue;
    }
    return (
      <Row className="mt-large" key={index}>
        <SubTitle>{element.properties.general.find((prop) => prop.key === 'fieldTitle').value}</SubTitle>
        <Text>{value ? value : '-'}</Text>
      </Row>
    );
  };

  const _renderCurrency = (element: IFormElement, index: number) => {
    const defaultValue = getValueFromPropertyByKey(element.properties.configuration, 'default')
      ? getValueFromPropertyByKey(element.properties.configuration, 'defaultValue')
      : '';
    let value = null;

    if (formData) {
      value = getElementValueById(formData, element.id);
    }
    if (value === null) {
      value = defaultValue;
    }
    return (
      <Row className="mt-large" key={index}>
        <SubTitle>{element.properties.general.find((prop) => prop.key === 'fieldTitle').value}</SubTitle>
        <Text>{value ? value : '-'}</Text>
      </Row>
    );
  };

  const _renderDropdown = (element: IFormElement, index: number) => {
    const defaultValue = getValueFromPropertyByKey(element.properties.configuration, 'defaultOption');
    let value = null;
    if (formData) {
      value = getElementValueById(formData, element.id);
    }
    if (value === null) {
      value = defaultValue === 'No default' ? '' : defaultValue;
    }

    return (
      <Row className="mt-large" key={index}>
        <SubTitle>{element.properties.general.find((prop) => prop.key === 'fieldTitle').value}</SubTitle>
        <Text>{value ? value : '-'}</Text>
      </Row>
    );
  };

  const _renderMultiChoices = (element: IFormElement, index: number) => {
    let defaultValue = getValueFromPropertyByKey(element.properties.configuration, 'defaultOption');
    let values = null;
    if (formData) {
      values = getElementValueById(formData, element.id);
    }
    if (values === null) {
      if (defaultValue === 'No default') {
        defaultValue = '';
      }
      if (!Array.isArray(defaultValue)) {
        if (!defaultValue) {
          defaultValue = [];
        } else {
          defaultValue = [defaultValue];
        }
      }
      values = defaultValue || [];
    }

    return (
      <Row className="mt-large" key={index}>
        <SubTitle>{element.properties.general.find((prop) => prop.key === 'fieldTitle').value}</SubTitle>
        {values && values.length > 0
          ? values.map((value, i) => (
              <>
                <Text key={i}>{value}</Text>
                <br />
              </>
            ))
          : '-'}
      </Row>
    );
  };

  const _renderSingleChoice = (element: IFormElement, index: number) => {
    const defaultValue = getValueFromPropertyByKey(element.properties.configuration, 'defaultOption');
    let value = null;
    if (formData) {
      value = getElementValueById(formData, element.id);
    }
    if (value === null) {
      value = defaultValue === 'No default' ? '' : defaultValue;
    }

    return (
      <Row className="mt-large" key={index}>
        <SubTitle>{element.properties.general.find((prop) => prop.key === 'fieldTitle').value}</SubTitle>
        <Text>{value ? value : '-'}</Text>
      </Row>
    );
  };

  const _renderCheckbox = (element: IFormElement, index: number) => {
    const defaultValue = _.find(element.properties.configuration, { key: 'checkboxOptions' }).defaultChecked;
    let value = null;
    if (formData) {
      value = getElementValueById(formData, element.id);
    }
    if (value === null) {
      value = defaultValue || false;
    }

    return (
      <Row className="mt-large" key={index}>
        <EditCheckboxElement value={value} form={form} element={element} enableCaption={false} />
      </Row>
    );
  };

  const _renderDateTime = (element: IFormElement, index: number) => {
    let value = [];
    if (formData) {
      value = getElementValueById(formData, element.id);
    }

    return (
      <Row className="mt-large" key={index}>
        <SubTitle>{element.properties.general.find((prop) => prop.key === 'fieldTitle').value}</SubTitle>
        <Text>
          {value && value.length > 1 && value[1] && value[0]
            ? `${moment(value[0]).format(DATE_FORMAT)} ${moment(value[1]).format(TIME_FORMAT)}`
            : value && value.length > 1 && value[1]
            ? moment(value[1]).format(TIME_FORMAT)
            : value && value.length > 0 && value[0]
            ? moment(value[0]).format(DATE_FORMAT)
            : '-'}
        </Text>
      </Row>
    );
  };

  const _renderFileUpload = (element: IFormElement, index: number) => {
    let value = [];
    if (formData) {
      value = getElementValueById(formData, element.id);
    }

    return (
      <Row className="mt-large" key={index}>
        <SubTitle>{element.properties.general.find((prop) => prop.key === 'fieldTitle').value}</SubTitle>
        <Text>{value.length > 0 ? value[0].name : '-'}</Text>
      </Row>
    );
  };

  const _renderElements = () => {
    const { elements } = formContent;
    if (_.isEmpty(elements)) return null;
    else
      return (
        <>
          {elements.map((element, i) => {
            switch (element.type) {
              case FormElementType.HEADER:
                return _renderHeader(element, i);
              case FormElementType.SHORT_TEXT:
                return _renderInlineInput(element, i);
              case FormElementType.DROPDOWN:
                return _renderDropdown(element, i);
              case FormElementType.MULTI_CHOICE:
                return _renderMultiChoices(element, i);
              case FormElementType.PARAGRAPH:
                return _renderParagraph(element, i);
              case FormElementType.DIVIDER:
                return _renderDivider(element, i);
              case FormElementType.LONG_TEXT:
                return _renderLongText(element, i);
              case FormElementType.SINGLE_CHOICE:
                return _renderSingleChoice(element, i);
              case FormElementType.CHECKBOX:
                return _renderCheckbox(element, i);
              case FormElementType.CURRENCY:
                return _renderCurrency(element, i);
              case FormElementType.NUMBER:
                return _renderNumberInput(element, i);
              case FormElementType.DATE_TIME:
                return _renderDateTime(element, i);
              case FormElementType.FILE_UPLOAD:
                return _renderFileUpload(element, i);
            }
          })}
        </>
      );
  };

  return (
    <>
      <SubTitle>Form Name</SubTitle>
      <Text weight="bold">{title.formTitle}</Text>
      {title.formDescription && (
        <>
          <Paragraph className="mt-large whitespace-pre-line">{title.formDescription}</Paragraph>
          <Divider className="divider-large" />
        </>
      )}
      <div className="disabled">{_renderElements()}</div>
    </>
  );
}

export default LinkedFormElement;
