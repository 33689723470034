import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const LockSlash = forwardRef(function LockSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.75c.964 0 1.612.002 2.095.067.461.062.659.169.789.3.13.13.237.327.3.788.065.483.066 1.131.066 2.095v1.282a69.532 69.532 0 0 0-2.701-.032H12a.75.75 0 1 0 0 1.5h.5c1.662 0 2.843.001 3.749.108.89.105 1.415.304 1.813.63.164.135.315.286.45.45.326.398.525.924.63 1.813.107.906.108 2.087.108 3.749v.5a.75.75 0 1 0 1.5 0v-.549c0-1.602 0-2.872-.119-3.876-.122-1.03-.377-1.88-.96-2.588a4.752 4.752 0 0 0-.658-.659c-.633-.52-1.377-.779-2.263-.915V5.948c0-.898 0-1.648-.08-2.242-.084-.628-.27-1.195-.725-1.65-.456-.456-1.023-.642-1.65-.726-.595-.08-1.345-.08-2.243-.08H9.5A2.25 2.25 0 0 0 7.25 3.5V4a.75.75 0 0 0 1.5 0v-.5a.75.75 0 0 1 .75-.75H12Zm-8.47-.28a.75.75 0 0 0-1.06 1.06l3.979 3.98A4.752 4.752 0 0 0 3.25 12v3.042c0 1.369 0 2.454.088 3.32.09.888.28 1.629.713 2.277.346.519.791.964 1.31 1.31.648.434 1.39.623 2.277.713.866.088 1.951.088 3.32.088h4.324c1.048 0 1.724 0 2.363-.182.351-.1.689-.24 1.008-.417.453-.253.844-.6 1.346-1.092l1.47 1.471a.75.75 0 0 0 1.061-1.06l-2-2-12-12-5-5ZM4.75 12a3.25 3.25 0 0 1 2.953-3.237l11.235 11.235c-.475.464-.73.684-1.015.842a3.25 3.25 0 0 1-.689.285c-.413.118-.866.125-2.062.125H11c-1.42 0-2.429 0-3.21-.08-.77-.079-1.235-.227-1.596-.468a3.25 3.25 0 0 1-.896-.896c-.241-.361-.39-.827-.468-1.596-.079-.781-.08-1.79-.08-3.21v-3Z"
        fill="currentColor"
      />
    </Icon>
  );
});
