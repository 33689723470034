/* eslint-disable */

import React, { Component } from 'react';
import { Row, Col, notification, Icon, Form, Divider, Input } from 'antd';
import { Title, FieldLabel, Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, HyperlinkButton } from 'common-components/buttons';
import InviteSupportWorkerDetailRow from './details/InviteSupportWorkerDetailRow';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import _ from 'lodash';
import { IInviteWorker } from 'src/interfaces/user-interfaces';
import { FormComponentProps } from 'antd/es/form';
import FullScreenModal, { FullScreenModalFooter } from 'common-components/modal/FullScreenModal';
import { Spinner } from '@blueprintjs/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

interface IInviteSupportWorkerViewProps extends FormComponentProps {
  doSendInvitation: typeof dispatch.teamStore.doSendInvitation;
  closeInviteWorkerModal: () => void;
  SentWorkerInvitations?: IInviteWorker[];
  isOpen: boolean;
}

interface IInviteSupportWorkerViewState {
  workerInputs: any[];
  step: number;
  sentNoOfInvite: number;
  inviteWorkerList: any[];
  isLoading: boolean;
  additionalNote: string;
}

class InviteSupportWorkerView extends Component<IInviteSupportWorkerViewProps, IInviteSupportWorkerViewState> {
  private workerInputRefs: any[] = [];

  state = {
    workerInputs: [{ firstName: '', lastName: '', phoneNumberCountryCode: 'AU', phoneNumber: '', role: '', email: '' }],
    step: 1,
    sentNoOfInvite: 0,
    inviteWorkerList: [],
    isLoading: false,
    additionalNote: '',
  };

  private _onCloseModel = () => {
    const { closeInviteWorkerModal } = this.props;
    this.setState({
      workerInputs: [
        { firstName: '', lastName: '', phoneNumberCountryCode: 'AU', phoneNumber: '', role: '', email: '' },
      ],
      step: 1,
      sentNoOfInvite: 0,
      inviteWorkerList: [],
      isLoading: false,
    });
    closeInviteWorkerModal();
  };

  private _handleInputClick = (event, key) => {
    this.state.workerInputs[key][event.target.name] = event.target.value;
  };

  private _handleDropDownClick = (event, key, name) => {
    this.state.workerInputs[key][name] = event;
  };

  private _addWorkerInputRow = () => {
    this.setState((prevState) => ({
      workerInputs: [
        ...prevState.workerInputs,
        { firstName: '', lastName: '', phoneNumberCountryCode: 'AU', phoneNumber: '', role: '', email: '' },
      ],
    }));
  };

  private _createWorkerInputRow = () => {
    return this.state.workerInputs.map((el, i) => (
      <InviteSupportWorkerDetailRow
        rowKey={i}
        key={i}
        wrappedComponentRef={this._setRef}
        workerInputItem={el}
        handleDelete={this._handleDelete}
        handleInputClick={() => {
          this._handleInputClick(event, i);
        }}
        handleDropDownClick={this._handleDropDownClick}
      />
    ));
  };

  private _handleDelete = (rowID) => {
    const existingworkerInputs = [...this.state.workerInputs];

    _.remove(existingworkerInputs, (item, index) => {
      return index === rowID;
    });

    this.setState({
      workerInputs: existingworkerInputs,
    });
  };

  private _onClickSubmit = async () => {
    //validate and save
    const { doSendInvitation } = this.props;
    let isFormValid = true;

    if (this.workerInputRefs.length > 0) {
      _.forEach(this.workerInputRefs, (workerInputRef) => {
        workerInputRef.props.form.validateFields((err) => {
          if (err) {
            isFormValid = false;
          }
        });
      });
    }

    if (isFormValid && this.state.workerInputs.length > 0) {
      try {
        this.setState({ isLoading: true, step: 2 });
        const payload = {
          list: this.state.workerInputs,
          message: this.state.additionalNote,
        };
        await doSendInvitation(payload);
        const SentWorkerInvitations = this.props.SentWorkerInvitations;
        this.setState({
          workerInputs: [
            { firstName: '', lastName: '', phoneNumberCountryCode: '', phoneNumber: '', role: '', email: '' },
          ],
          sentNoOfInvite: SentWorkerInvitations.length,
          step: 3,
          inviteWorkerList: SentWorkerInvitations,
          isLoading: false,
        });
      } catch (error) {
        this.setState({ isLoading: false, step: 1 });
        notification.error({ description: error.meta.message, message: error.message });
      }
    } else {
      notification.error({ message: 'Validate', description: 'At least one invitation is required.' });
    }
  };

  private _setRef = (ref) => {
    if (ref) {
      this.workerInputRefs.push(ref);
    }
  };

  private _handleAdditionalNote = (event) => {
    this.setState({ additionalNote: event.target.value });
  };

  renderStep = () => {
    const { step } = this.state;

    if (step == 1) {
      return (
        <div>
          <Title level={2} weight='regular'>
            Invite new <b>Team members to GoodHuman</b>
          </Title>
          <Paragraph className='mb-x-large'>
            Portal users are users who can access this company & administrate various functions on this portal.
          </Paragraph>
          <div className='mb-medium'>
            <Row type='flex' align='middle' gutter={16} className='mb-small'>
              <Col span={4}>
                <FieldLabel text='First Name' />
              </Col>
              <Col span={4}>
                <FieldLabel text='Last Name' />
              </Col>
              <Col span={7}>
                <FieldLabel text='Mobile' />
              </Col>
              <Col span={4}>
                <FieldLabel text='Email' />
              </Col>
              <Col span={4}>
                <FieldLabel text='Role' />
              </Col>
            </Row>

            {/* inputs of invite workers */}
            {this._createWorkerInputRow()}
          </div>
          <div className='mb-x-large'>
            <HyperlinkButton onClick={this._addWorkerInputRow}>
              <Icon type='plus-circle' className='mr-small' /> Add more
            </HyperlinkButton>
          </div>

          <div className='mb-x-large'>
            <Paragraph color='secondary' size='regular'>
              Team Member(s) will receive and SMS inviting them to download the GoodHuman App and connect to and manage
              their bookings, customers, tasks and more.
            </Paragraph>
            <Input.TextArea
              rows={3}
              placeholder='Enter your custom invite message here'
              name='additionalNote'
              value={this.state.additionalNote}
              onChange={this._handleAdditionalNote}
            />
          </div>

          <div className='flex-row justify-end width-full'>
            <PrimaryButton
              size='large'
              icon='mail'
              iconPosition='right'
              onClick={this._onClickSubmit}
              loading={this.state.isLoading}
            >
              Send Invite(s)
            </PrimaryButton>
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <div>
          <Title level={2} weight='regular'>
            Invite new <b>Team members to GoodHuman</b>
          </Title>
          <div className='mt-x-large'>
            <div className='flex-row align-center mb-x-large'>
              <div className='mr-x-large'>
                <Spinner size={120} />
              </div>
              <div>
                <Paragraph>Loading.......</Paragraph>
                <Paragraph weight='bold'>Adding Team member.</Paragraph>
                <Paragraph>This won't take long.</Paragraph>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (step == 3) {
      const inviteWorkerList = this.state.inviteWorkerList;

      return (
        <div>
          <Row type={'flex'} justify={'center'}>
            <Col>
              <Title level={2} weight='bolder'>
                You have invited
              </Title>
            </Col>
          </Row>
          <Row type={'flex'} justify={'center'}>
            <Col>
              <Paragraph>
                <span className='text-weight-bold'>{this.state.sentNoOfInvite} new users</span> to GoodHuman
              </Paragraph>
            </Col>
          </Row>
          <div className='mb-x2-large'>
            <Row type={'flex'} justify={'center'}>
              <Col span={6}>
                <FieldLabel text='Mobile' />
              </Col>
              <Col span={6}>
                <FieldLabel text='Name' />
              </Col>
            </Row>
            <Row type={'flex'} justify={'center'}>
              <Col span={12}>
                <Divider className={'divider-medium'} style={{ background: 'grey' }} />
              </Col>
            </Row>
            <div className={'mb-large'}>
              {inviteWorkerList.map((inviteWorker) => (
                <Row type={'flex'} justify={'center'}>
                  <Col span={6}>{parsePhoneNumberFromString(inviteWorker.mobile, 'AU').number}</Col>
                  <Col span={6}>
                    {inviteWorker.firstName} {inviteWorker.lastName}
                  </Col>
                </Row>
              ))}
            </div>
            <Row type={'flex'} justify={'center'} className={'text-align-right'}>
              <Col span={12}>
                <PrimaryButton size='large' icon='check' iconPosition='right' onClick={this._onCloseModel}>
                  Done
                </PrimaryButton>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <FullScreenModal
        isOpen={isOpen}
        onClose={this._onCloseModel}
        width={'large'}
        footerContent={
          <FullScreenModalFooter>
            <Text>
              Hint : Press <code>Esc</code> to close this screen.
            </Text>
          </FullScreenModalFooter>
        }
        canClose={!this.state.isLoading}
      >
        <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '80vh' }}>{this.renderStep()}</div>
      </FullScreenModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  SentWorkerInvitations: state.teamStore.SentWorkerInvitations,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doSendInvitation: dispatch.teamStore.doSendInvitation,
});

export default connect(mapState, mapDispatch)(Form.create<IInviteSupportWorkerViewProps>()(InviteSupportWorkerView));
