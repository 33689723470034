import { MoreHoriz } from '@good/icons';
import { Menu } from '@good/ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';
import { IRootState } from 'stores/rematch/root-store';
import { reactTrpc } from 'utilities/react-trpc';
import { useSilServiceContext } from 'views/sil-service/components/sil-service-provider/sil-service-provider';
import { RemoveTeamMemberModal } from '../modals/remove-team-member-modal';
import { notifications } from '@good/ui/notifications';

type IMenuProps = {
  supportWorkerId: string;
  displayName: string;
};

export const MeatballsDropdown = ({ supportWorkerId, displayName }: IMenuProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceTeamTabs' });
  const { service } = useSilServiceContext();
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  const utils = reactTrpc.useContext();
  const [removeTeamMemberOpen, setRemoveTeamMemberOpen] = React.useState(false);

  const removeTeamMemberEndpoint = reactTrpc.sil.roster.remove.useMutation({
    onSuccess: async () => {
      await utils.sil.roster.fetch.invalidate({
        serviceId: service?.serviceId ?? '',
        serviceProviderId: portalUser?.serviceProviderId ?? '',
      });
    },
  });

  useEffect(() => {
    if (removeTeamMemberEndpoint.isSuccess) {
      notifications.show({
        title: `${displayName} is removed from roster`,
        message: '',
        color: 'var(--color-green-ref)',
      });
      removeTeamMemberEndpoint.reset();
    }
    if (removeTeamMemberEndpoint.isError) {
      notifications.show({
        title: `${displayName} failed to be removed from roster. Please try again`,
        message: '',
        color: 'red',
      });
      removeTeamMemberEndpoint.reset();
    }
}, [removeTeamMemberEndpoint.isSuccess, removeTeamMemberEndpoint.isError, displayName]);

  const handleOnClick = () => {
    setRemoveTeamMemberOpen(true);
  };

  const handleRemove = () => {
    removeTeamMemberEndpoint.mutate({
      serviceId: service?.serviceId ?? '',
      serviceProviderId: service?.serviceProviderId ?? '',
      supportWorkerIds: [supportWorkerId],
    });
    setRemoveTeamMemberOpen(false);
  };

  return (
    <>
      <Menu width={200} position='bottom-end'>
        <Menu.Target>
          <button aria-label='row options'>
            <MoreHoriz />
          </button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item component={ReactRouterLink} to={`/team/details/${supportWorkerId}`}>
            {t('View')}
          </Menu.Item>
          <Menu.Item c='red' onClick={() => handleOnClick()}>
            {t('Remove')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <RemoveTeamMemberModal
        isOpen={removeTeamMemberOpen}
        onClose={() => setRemoveTeamMemberOpen(false)}
        onRemove={handleRemove}
        name={displayName}
      />
    </>
  );
};
