import React, { Component } from 'react';
import { Col, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Paragraph } from 'common-components/typography';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { DebtorType } from 'utilities/enum-utils';

interface IRemoveCaseManagerModalProps {
  closeRemoveCaseManagerModal: () => void;
  isOpen: boolean;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  doRemoveCustomerDebtor: typeof dispatch.customersStore.doRemoveCustomerDebtor;
}

class RemoveCaseManagerModal extends Component<IRemoveCaseManagerModalProps> {
  state = { isLoading: false, title: 'Remove case manager', step: 1 };

  private _removeCaseManager = async () => {
    const { doRemoveCustomerDebtor, selectedCustomer } = this.props;
    try {
      this.setState({
        isLoading: true,
      });

      await doRemoveCustomerDebtor({
        customerUserId: selectedCustomer.userId,
        debtorType: DebtorType.VCP,
        debtorId: selectedCustomer.vcpManager.debtorId,
      });

      this.setState({
        isLoading: false,
        title: 'Case manager details successfully removed',
        step: 2,
      });
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
    }
  };

  private _closeModal = () => {
    this.setState({ title: 'Remove case manager', step: 1 });
    this.props.closeRemoveCaseManagerModal();
  };

  render() {
    const { step } = this.state;
    return (
      <div>
        <ActionModal title={this.state.title} isOpen={this.props.isOpen} onClose={this._closeModal} width="medium">
          {step === 1 && (
            <div className="anim-slide-left">
              <SpinningLoadingActionModel isOpen={this.state.isLoading} verticalAlignment={'highest'} />
              <Paragraph>Are you sure you want to remove this case manager from this customer profile?</Paragraph>
              <div className={'mb-small mt-x2-large'}>
                <Row type={'flex'} className={'justify-end'}>
                  <Col className="mr-large">
                    <GhostButton size="large" onClick={this.props.closeRemoveCaseManagerModal}>
                      Cancel
                    </GhostButton>
                  </Col>
                  <Col>
                    <PrimaryButton size="large" color="red" onClick={this._removeCaseManager}>
                      Remove
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="anim-slide-left">
              <Paragraph>You have successfully removed this case manager from the customer profile.</Paragraph>
              <div className={'mb-small mt-x2-large'}>
                <Row type={'flex'} className={'justify-end'}>
                  <Col>
                    <PrimaryButton size="large" onClick={this._closeModal}>
                      Close
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveCustomerDebtor: dispatch.customersStore.doRemoveCustomerDebtor,
});

export default connect(mapState, mapDispatch)(RemoveCaseManagerModal);
