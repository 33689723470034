import React, { Component } from 'react';
import { Col, Form, Icon, notification, Row, Spin, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { Paragraph, Text } from 'common-components/typography';
import { GhostButton, PrimaryButton, HyperlinkButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import _ from 'lodash';
import moment from 'moment-timezone';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { Popover2 } from '@blueprintjs/popover2';
import DefaultTooltips from 'common-components/tooltips/DefaultTooltips';
import ConditionalWrapper from 'common-components/ConditionalWrapper/ConditionalWrapper';
import TextTag from 'common-components/tags/TextTag';
import { BookingErrorType } from 'utilities/enum-utils';

interface ICloseSessionModalProps extends FormComponentProps {
  closeModal: (refreshList?) => void;
  isOpen: boolean;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  doCloseSession: typeof dispatch.groupServiceStore.doCloseSession;
  doCheckCloseSession: typeof dispatch.groupServiceStore.doCheckCloseSession;
  setSelectedSessionTabKey: typeof dispatch.groupServiceStore.setSelectedSessionTabKey;
  setCustomerBookingToOpen: typeof dispatch.groupServiceStore.setCustomerBookingToOpen;
  doStartGroupBooking: typeof dispatch.groupBookingsStore.doStartGroupBooking;
  doFinishGroupBooking: typeof dispatch.groupBookingsStore.doFinishGroupBooking;
  doStartShift: typeof dispatch.groupServiceStore.doStartShift;
  doRemoveShiftSlot: typeof dispatch.groupServiceStore.doRemoveShiftSlot;
  doApproveShift: typeof dispatch.groupServiceStore.doApproveShift;
  doFinishShift: typeof dispatch.groupServiceStore.doFinishShift;
  doCompleteAndApproveShift: typeof dispatch.groupServiceStore.doCompleteAndApproveShift;
  doApproveGroupBookingsPayment: typeof dispatch.groupBookingsStore.doApproveGroupBookingsPayment;
  doCompleteAndApproveBooking: typeof dispatch.groupBookingsStore.doCompleteAndApproveBooking;
}

type AttendanceErrorsProps = {
  bookingNotStarted?: ClosingErrorProps[];
  bookingNotFinished?: ClosingErrorProps[];
  bookingNotCompleted?: ClosingErrorProps[];
};

type BillingErrorsProps = {
  lineItemMissing?: ClosingErrorProps[];
  bookingNotApproved?: ClosingErrorProps[];
  isNoPaymentMethodItems?: ClosingErrorProps[];
};

type SupportWorkerErrorsProps = {
  shiftNotApproved?: ClosingErrorProps[];
  shiftNotStarted?: ClosingErrorProps[];
  shiftNotCompleted?: ClosingErrorProps[];
  shiftNotConfirmed?: ClosingErrorProps[];
};

type QuickActionType = keyof typeof quickActionTypes;

type ClosingErrorProps = {
  attendanceId: string;
  bookingErrors?: {
    number: Number;
    bookingErrorType: typeof BookingErrorType;
  };
  endDateTime?: string;
  startDateTime?: string;
  shiftStartDateTime?: string;
  shiftEndDateTime?: string;
  firstName?: string;
  lastName?: string;
  parentAttendanceId?: string;
  paymentAttendanceId?: string;
  paymentStatus?: string;
  serviceDateTimeId?: string;
  serviceId?: string;
  status?: string;
  userId?: string;
  supportWorkerAttendanceId?: string;
  numberOfLineItemError?: number;
};

type QuickActionHandlers = {
  goToTab: (
    selectedTab: string,
    selectedBookingId?: string,
    selectedBookingTab?: { key: string; name: string },
    parentAttendanceId?: string,
  ) => void;
  doSubAction?: (action: QuickActionType, error: ClosingErrorProps) => void;
};

interface ICloseSessionModalState {
  title: string;
  isLoading: boolean;
  isChecking: boolean;
  isSuccess: boolean;
  loadingActionLabel?: string;
  closeCheckErrors: {
    status: boolean;
    attendanceErrors: AttendanceErrorsProps;
    billingErrors: BillingErrorsProps;
    supportWorkerErrors: SupportWorkerErrorsProps;
  };
  selectedErrorTab: string;
}

const errorTabLabels = {
  attendances: 'attendances',
  billings: 'billings',
  incompleteShifts: 'incompleteShifts',
};

const quickActionTypes = {
  SHIFT_REMOVE: 'SHIFT_REMOVE',
  SHIFT_START: 'SHIFT_START',
  SHIFT_COMPLETE: 'SHIFT_COMPLETE',
  SHIFT_APPROVE: 'SHIFT_APPROVE',
  SHIFT_COMPLETE_APPROVE: 'SHIFT_COMPLETE_APPROVE',
  BOOKING_START: 'BOOKING_START',
  BOOKING_COMPLETE: 'BOOKING_COMPLETE',
  BOOKING_APPROVE: 'BOOKING_APPROVE',
  BOOKING_COMPLETE_APPROVE: 'BOOKING_COMPLETE_APPROVE',
} as const;

const quickActionLabels = {
  SHIFT_REMOVE: 'Remove shift',
  SHIFT_START: 'Start shift',
  SHIFT_COMPLETE: 'Complete shift',
  SHIFT_APPROVE: 'Approve shift',
  SHIFT_COMPLETE_APPROVE: 'Complete & approve shift',
  BOOKING_START: 'Start booking',
  BOOKING_COMPLETE: 'Complete booking',
  BOOKING_APPROVE: 'Approve booking',
  BOOKING_COMPLETE_APPROVE: 'Complete & approve booking',
};

class CloseSessionModal extends Component<ICloseSessionModalProps, ICloseSessionModalState> {
  state = {
    title: 'Close session',
    isLoading: false,
    isChecking: false,
    isSuccess: false,
    closeCheckErrors: null,
    selectedErrorTab: errorTabLabels.attendances,
  } as ICloseSessionModalState;

  private _closeModal = (refreshList: boolean = false) => {
    this.setState({
      title: 'Close session',
      isLoading: false,
      isChecking: false,
      isSuccess: false,
      closeCheckErrors: null,
      selectedErrorTab: errorTabLabels.attendances,
    });
    this.props.closeModal(refreshList);
  };

  private _closeSession = async () => {
    const { selectedSession } = this.props;
    this.setState({ isLoading: true });
    try {
      await this.props.doCloseSession({
        serviceId: selectedSession.serviceId,
        serviceDateTimeId: selectedSession.serviceDateTimeId,
      });
      this._closeModal();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isLoading: false });
    }
  };

  private _changeSelectedErrorTab = (errorTab: string) => {
    this.setState({ selectedErrorTab: errorTab });
  };

  private _goToTab = async (
    selectedTab,
    selectedBookingId?: string,
    selectedBookingTab: { key: string; name: string } = { key: 'overview', name: 'Overview' },
    parentAttendanceId?: string,
  ) => {
    if (selectedBookingId) {
      await Promise.all([
        this.props.setCustomerBookingToOpen({
          attendanceId: parentAttendanceId ? parentAttendanceId : selectedBookingId,
          selectedTab: parentAttendanceId
            ? { key: 'transport-options', name: 'Transport options' }
            : selectedBookingTab,
        }),
      ]);
    }
    await this.props.setSelectedSessionTabKey(selectedTab);
    this._closeModal();
  };

  private _doShiftSubAction = async (action: QuickActionType, error: ClosingErrorProps) => {
    const {
      selectedSession,
      doStartShift,
      doRemoveShiftSlot,
      doApproveShift,
      doFinishShift,
      doCompleteAndApproveShift,
    } = this.props;
    this.setState({ loadingActionLabel: 's_' + error?.supportWorkerAttendanceId });
    try {
      if (action === quickActionTypes.SHIFT_START) {
        await doStartShift({
          serviceDateTimeId: selectedSession.serviceDateTimeId,
          serviceId: selectedSession.serviceId,
          supportWorkerAttendanceId: error.supportWorkerAttendanceId,
          startDateTime: error.shiftStartDateTime,
        });
      } else if (action === quickActionTypes.SHIFT_REMOVE) {
        await doRemoveShiftSlot({
          serviceDateTimeId: selectedSession.serviceDateTimeId,
          serviceId: selectedSession.serviceId,
          supportWorkerAttendanceIds: [error.supportWorkerAttendanceId],
          isRemoveTeamMemberOnly: true,
        });
      } else if (action === quickActionTypes.SHIFT_APPROVE) {
        await doApproveShift({
          serviceId: selectedSession.serviceId,
          serviceDateTimeId: selectedSession.serviceDateTimeId,
          supportWorkerAttendanceId: error.supportWorkerAttendanceId,
        });
      } else if (action === quickActionTypes.SHIFT_COMPLETE) {
        await doFinishShift({
          serviceId: selectedSession.serviceId,
          serviceDateTimeId: selectedSession.serviceDateTimeId,
          supportWorkerAttendanceId: error.supportWorkerAttendanceId,
          endDateTime: error.shiftEndDateTime,
        });
      } else if (action === quickActionTypes.SHIFT_COMPLETE_APPROVE) {
        await doCompleteAndApproveShift({
          serviceId: selectedSession.serviceId,
          serviceDateTimeId: selectedSession.serviceDateTimeId,
          supportWorkerAttendanceId: error.supportWorkerAttendanceId,
        });
      }
      await this._loadErrorData();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ loadingActionLabel: '' });
    }
  };

  private _doBookingSubAction = async (action: QuickActionType, error: ClosingErrorProps) => {
    const {
      selectedSession,
      doStartGroupBooking,
      doFinishGroupBooking,
      doApproveGroupBookingsPayment,
      doCompleteAndApproveBooking,
    } = this.props;
    this.setState({ loadingActionLabel: 'b_' + error.attendanceId });
    try {
      if (action === quickActionTypes.BOOKING_START) {
        await doStartGroupBooking({
          serviceId: selectedSession.serviceId,
          serviceDateTimeId: error.serviceDateTimeId,
          attendances: [
            {
              attendanceId: error.attendanceId,
              startDateTime: error.startDateTime,
            },
          ],
        });
      } else if (action === quickActionTypes.BOOKING_COMPLETE) {
        await doFinishGroupBooking({
          serviceId: selectedSession.serviceId,
          serviceDateTimeId: error.serviceDateTimeId,
          attendances: [
            {
              attendanceId: error.attendanceId,
              endDateTime: error.endDateTime,
            },
          ],
        });
      } else if (action === quickActionTypes.BOOKING_APPROVE) {
        await doApproveGroupBookingsPayment({
          serviceId: selectedSession.serviceId,
          serviceDateTimeId: selectedSession.serviceDateTimeId,
          bookingIds: [error.attendanceId],
        });
      } else if (action === quickActionTypes.BOOKING_COMPLETE_APPROVE) {
        await doCompleteAndApproveBooking({
          serviceId: selectedSession.serviceId,
          attendanceId: error.attendanceId,
        });
      }
      await this._loadErrorData();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ loadingActionLabel: null });
    }
  };

  private _loadErrorData = async () => {
    const { selectedSession } = this.props;
    this.setState({ isChecking: true, loadingActionLabel: null });
    const result: any = await this.props.doCheckCloseSession({
      serviceId: selectedSession.serviceId,
      serviceDateTimeId: selectedSession.serviceDateTimeId,
    });
    this.setState({ isChecking: false, closeCheckErrors: result });
  };

  componentDidUpdate = async (prevProps: Readonly<ICloseSessionModalProps>) => {
    const { selectedSession } = this.props;
    if (!prevProps.isOpen && this.props.isOpen && this.props.selectedSession) {
      this.setState({ isChecking: true });
      const result: any = await this.props.doCheckCloseSession({
        serviceId: selectedSession.serviceId,
        serviceDateTimeId: selectedSession.serviceDateTimeId,
      });
      this.setState({ isChecking: false, closeCheckErrors: result });
    }
  };

  render() {
    const { selectedSession } = this.props;
    const { title, isLoading, isChecking, isSuccess, closeCheckErrors, selectedErrorTab, loadingActionLabel } =
      this.state;

    // Attendance errors
    const attendanceNotStartedErrors = closeCheckErrors?.attendanceErrors?.bookingNotStarted;
    const attendanceNotCompletedErrors = closeCheckErrors?.attendanceErrors?.bookingNotCompleted;
    const attendanceErrorCount = attendanceNotStartedErrors?.length + attendanceNotCompletedErrors?.length;
    // Billing errorsr
    const billingLineItemMissingErrors = closeCheckErrors?.billingErrors?.lineItemMissing;
    const billingNotApprovedErrors = closeCheckErrors?.billingErrors?.bookingNotApproved;
    const billingErrorCount = billingLineItemMissingErrors?.length + billingNotApprovedErrors?.length;
    // Support worker errors
    const workerShiftNotApprovedErrors = closeCheckErrors?.supportWorkerErrors?.shiftNotApproved;
    const workerShiftNotCompletedErrors = closeCheckErrors?.supportWorkerErrors?.shiftNotCompleted;
    const workerShiftNotStartedErrors = closeCheckErrors?.supportWorkerErrors?.shiftNotStarted;
    const workerShiftNotConfirmedErrors = closeCheckErrors?.supportWorkerErrors?.shiftNotConfirmed;
    const supportWorkerIncompleteShiftErrors =
      workerShiftNotApprovedErrors?.length +
      workerShiftNotCompletedErrors?.length +
      workerShiftNotStartedErrors?.length +
      workerShiftNotConfirmedErrors?.length;

    if (selectedErrorTab === errorTabLabels?.attendances && attendanceErrorCount === 0) {
      if (billingErrorCount > 0) {
        this.setState({ selectedErrorTab: errorTabLabels.billings });
      } else {
        this.setState({ selectedErrorTab: errorTabLabels.incompleteShifts });
      }
    }

    return (
      <div>
        <ActionModal isOpen={this.props.isOpen} title={title} onClose={() => this._closeModal(true)} width="large">
          {isChecking ? (
            <SpinningLoader size={100} message="Checking if the session can be closed..." />
          ) : (
            selectedSession && (
              <>
                {closeCheckErrors && !closeCheckErrors.status ? (
                  <>
                    <div>
                      <Paragraph>
                        There are unresolved issues detected. <br /> You'll need to resolve them before proceeding to
                        close this session.
                      </Paragraph>
                      <Row gutter={12}>
                        <Col span={7}>
                          <div className="mb-medium">
                            <Text weight="bold">Customer errors</Text>
                          </div>
                          <div className="mb-medium">
                            {selectedErrorTab === errorTabLabels.attendances ? (
                              <Text color="blue-action" weight="bold">
                                Attendances ({Number(attendanceErrorCount)})
                              </Text>
                            ) : (
                              <HyperlinkButton
                                onClick={() => this._changeSelectedErrorTab(errorTabLabels.attendances)}
                                color={Number(attendanceErrorCount) > 0 ? 'secondary' : 'tertiary'}
                              >
                                Attendances ({Number(attendanceErrorCount)})
                              </HyperlinkButton>
                            )}
                          </div>
                          <div className="mb-medium">
                            {selectedErrorTab === errorTabLabels.billings ? (
                              <Text color="blue-action" weight="bold">
                                Billings ({Number(billingErrorCount)})
                              </Text>
                            ) : (
                              <HyperlinkButton
                                onClick={() => this._changeSelectedErrorTab(errorTabLabels.billings)}
                                color={Number(billingErrorCount) > 0 ? 'secondary' : 'tertiary'}
                              >
                                Billings ({Number(billingErrorCount)})
                              </HyperlinkButton>
                            )}
                          </div>
                          <div className="mb-medium">
                            <Text weight="bold">Team member errors</Text>
                          </div>
                          <div className="mb-medium">
                            {selectedErrorTab === errorTabLabels.incompleteShifts ? (
                              <Text color="blue-action" weight="bold">
                                Incomplete shift ({Number(supportWorkerIncompleteShiftErrors)})
                              </Text>
                            ) : (
                              <HyperlinkButton
                                onClick={() => this._changeSelectedErrorTab(errorTabLabels.incompleteShifts)}
                                color={Number(supportWorkerIncompleteShiftErrors) > 0 ? 'secondary' : 'tertiary'}
                              >
                                Incomplete shift ({Number(supportWorkerIncompleteShiftErrors)})
                              </HyperlinkButton>
                            )}
                          </div>
                        </Col>
                        <Col span={17}>
                          {selectedErrorTab === errorTabLabels.attendances && (
                            <>
                              {attendanceNotStartedErrors?.length > 0 && (
                                <div>
                                  <div className="mb-small">
                                    <Text color="secondary">Booking not started</Text>
                                  </div>
                                  {_.map(attendanceNotStartedErrors, (error: ClosingErrorProps, index: number) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`mb-${
                                          Number(index) === attendanceNotStartedErrors.length - 1 ? 'medium' : 'small'
                                        }`}
                                      >
                                        <ErrorDisplayComponent
                                          error={error}
                                          errors={attendanceNotStartedErrors}
                                          actions={
                                            error.parentAttendanceId
                                              ? []
                                              : [
                                                  quickActionTypes.BOOKING_START,
                                                  quickActionTypes.BOOKING_COMPLETE_APPROVE,
                                                ]
                                          }
                                          mainText={
                                            <div>
                                              {error.parentAttendanceId && (
                                                <div>
                                                  <TextTag
                                                    content="Transport booking"
                                                    color="indigo"
                                                    icon="car"
                                                    size="small"
                                                    textSize="small"
                                                    theme="light"
                                                  />
                                                </div>
                                              )}
                                              <Text>
                                                <b>
                                                  {error.firstName} {error.lastName}
                                                </b>{' '}
                                                booking has not started.
                                              </Text>
                                            </div>
                                          }
                                          loadingActionLabel={loadingActionLabel}
                                          doSubAction={this._doBookingSubAction}
                                          goToHyperlink={
                                            <HyperlinkButton
                                              onClick={() =>
                                                this._goToTab(
                                                  'CUSTOMERS',
                                                  error.attendanceId,
                                                  null,
                                                  error.parentAttendanceId,
                                                )
                                              }
                                            >
                                              <Icon type="select" className="mr-small" />
                                              View booking
                                            </HyperlinkButton>
                                          }
                                          index={index}
                                          canApprove={true}
                                          updatingText="Updating booking..."
                                          type="booking"
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                              {attendanceNotCompletedErrors?.length > 0 && (
                                <div>
                                  <div className="mb-small">
                                    <Text color="secondary">Booking not completed</Text>
                                  </div>
                                  {_.map(attendanceNotCompletedErrors, (error: ClosingErrorProps, index: number) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`mb-${
                                          Number(index) === attendanceNotCompletedErrors.length - 1 ? 'medium' : 'small'
                                        }`}
                                      >
                                        <ErrorDisplayComponent
                                          error={error}
                                          errors={attendanceNotStartedErrors}
                                          actions={
                                            error.parentAttendanceId
                                              ? []
                                              : [
                                                  quickActionTypes.BOOKING_COMPLETE,
                                                  quickActionTypes.BOOKING_COMPLETE_APPROVE,
                                                ]
                                          }
                                          mainText={
                                            <div>
                                              {error.parentAttendanceId && (
                                                <div>
                                                  <TextTag
                                                    content="Transport booking"
                                                    color="indigo"
                                                    icon="car"
                                                    size="small"
                                                    textSize="small"
                                                    theme="light"
                                                  />
                                                </div>
                                              )}
                                              <Text>
                                                <b>
                                                  {error.firstName} {error.lastName}
                                                </b>{' '}
                                                booking has not been completed.
                                              </Text>
                                            </div>
                                          }
                                          loadingActionLabel={loadingActionLabel}
                                          doSubAction={this._doBookingSubAction}
                                          goToHyperlink={
                                            <HyperlinkButton
                                              onClick={() =>
                                                this._goToTab(
                                                  'CUSTOMERS',
                                                  error.attendanceId,
                                                  null,
                                                  error.parentAttendanceId,
                                                )
                                              }
                                            >
                                              <Icon type="select" className="mr-small" />
                                              View booking
                                            </HyperlinkButton>
                                          }
                                          index={index}
                                          updatingText="Updating booking..."
                                          type="booking"
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </>
                          )}
                          {selectedErrorTab === errorTabLabels.billings && (
                            <>
                              {billingLineItemMissingErrors?.length > 0 && (
                                <div>
                                  <div className="mb-small">
                                    <Text color="secondary">Line items missing management method</Text>
                                  </div>
                                  {_.map(billingLineItemMissingErrors, (error, index) => {
                                    return (
                                      <div
                                        className={`bordered flex-row rounded mb-${
                                          Number(index) === billingLineItemMissingErrors.length - 1 ? 'medium' : 'small'
                                        }`}
                                      >
                                        <div
                                          className="bg-red-lighter rounded-left"
                                          style={{
                                            width: '3px',
                                          }}
                                        />
                                        <div className={`p-medium`}>
                                          {error.parentAttendanceId && (
                                            <div>
                                              <TextTag
                                                content="Transport booking"
                                                color="indigo"
                                                icon="car"
                                                size="small"
                                                textSize="small"
                                                theme="light"
                                              />
                                            </div>
                                          )}
                                          <Text>
                                            <b>
                                              {error.firstName} {error.lastName}
                                            </b>{' '}
                                            has {error.numberOfLineItemError} line item
                                            {error.numberOfLineItemError !== 1 && 's'} missing management method.
                                          </Text>
                                          <div className="mt-small">
                                            <HyperlinkButton
                                              onClick={() =>
                                                this._goToTab(
                                                  'CUSTOMERS',
                                                  error.attendanceId,
                                                  {
                                                    key: 'billings',
                                                    name: 'Billings',
                                                  },
                                                  error.parentAttendanceId,
                                                )
                                              }
                                            >
                                              <Icon type="select" className="mr-small" />
                                              View booking's billing
                                            </HyperlinkButton>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                              {billingNotApprovedErrors?.length > 0 && (
                                <div>
                                  <div className="mb-small">
                                    <Text color="secondary">Booking not approved</Text>
                                  </div>
                                  {_.map(billingNotApprovedErrors, (error, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`mb-${
                                          Number(index) === billingNotApprovedErrors.length - 1 ? 'medium' : 'small'
                                        }`}
                                      >
                                        <ErrorDisplayComponent
                                          error={error}
                                          errors={attendanceNotStartedErrors}
                                          actions={error.parentAttendanceId ? [] : [quickActionTypes.BOOKING_APPROVE]}
                                          mainText={
                                            <div>
                                              {error.parentAttendanceId && (
                                                <div>
                                                  <TextTag
                                                    content="Transport booking"
                                                    color="indigo"
                                                    icon="car"
                                                    size="small"
                                                    textSize="small"
                                                    theme="light"
                                                  />
                                                </div>
                                              )}
                                              <Text>
                                                <b>
                                                  {error.firstName} {error.lastName}
                                                </b>{' '}
                                                booking has not been approved.
                                              </Text>
                                            </div>
                                          }
                                          loadingActionLabel={loadingActionLabel}
                                          doSubAction={this._doBookingSubAction}
                                          goToHyperlink={
                                            <HyperlinkButton
                                              onClick={() =>
                                                this._goToTab(
                                                  'CUSTOMERS',
                                                  error.attendanceId,
                                                  null,
                                                  error.parentAttendanceId,
                                                )
                                              }
                                            >
                                              <Icon type="select" className="mr-small" />
                                              View booking
                                            </HyperlinkButton>
                                          }
                                          index={index}
                                          canApprove={
                                            !_.find(
                                              billingLineItemMissingErrors,
                                              (lineItemError) => lineItemError.attendanceId === error.attendanceId,
                                            )
                                          }
                                          updatingText="Updating booking..."
                                          type="booking"
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </>
                          )}
                          {selectedErrorTab === errorTabLabels.incompleteShifts && (
                            <>
                              {workerShiftNotApprovedErrors?.length > 0 && (
                                <SupportWorkerErrorItem
                                  errors={workerShiftNotApprovedErrors}
                                  errorLabel="approved"
                                  timezone={selectedSession.timezone}
                                  goToTab={this._goToTab}
                                  loadingActionLabel={loadingActionLabel}
                                  doSubAction={this._doShiftSubAction}
                                  actions={[quickActionTypes.SHIFT_APPROVE, quickActionTypes.SHIFT_REMOVE]}
                                />
                              )}
                              {workerShiftNotCompletedErrors?.length > 0 && (
                                <SupportWorkerErrorItem
                                  errors={workerShiftNotCompletedErrors}
                                  errorLabel="completed"
                                  timezone={selectedSession.timezone}
                                  goToTab={this._goToTab}
                                  loadingActionLabel={loadingActionLabel}
                                  doSubAction={this._doShiftSubAction}
                                  actions={[
                                    quickActionTypes.SHIFT_COMPLETE,
                                    quickActionTypes.SHIFT_COMPLETE_APPROVE,
                                    quickActionTypes.SHIFT_REMOVE,
                                  ]}
                                />
                              )}
                              {workerShiftNotStartedErrors?.length > 0 && (
                                <SupportWorkerErrorItem
                                  errors={workerShiftNotStartedErrors}
                                  errorLabel="started"
                                  timezone={selectedSession.timezone}
                                  goToTab={this._goToTab}
                                  loadingActionLabel={loadingActionLabel}
                                  doSubAction={this._doShiftSubAction}
                                  actions={[
                                    quickActionTypes.SHIFT_START,
                                    quickActionTypes.SHIFT_COMPLETE_APPROVE,
                                    quickActionTypes.SHIFT_REMOVE,
                                  ]}
                                />
                              )}
                              {workerShiftNotConfirmedErrors?.length > 0 && (
                                <SupportWorkerErrorItem
                                  errors={workerShiftNotConfirmedErrors}
                                  errorLabel="confirmed"
                                  timezone={selectedSession.timezone}
                                  goToTab={this._goToTab}
                                  loadingActionLabel={loadingActionLabel}
                                  doSubAction={this._doShiftSubAction}
                                  actions={null}
                                />
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-large">
                      <Row type="flex" justify="end">
                        <PrimaryButton size="large" onClick={() => this._closeModal(true)}>
                          Close
                        </PrimaryButton>
                      </Row>
                    </div>
                  </>
                ) : isSuccess ? (
                  <>
                    <div className="mb-large">
                      <Paragraph>You have successfully closed this session.</Paragraph>
                    </div>
                    <div className="mt-large">
                      <Row type="flex" justify="end">
                        <PrimaryButton size="large" onClick={this._closeModal}>
                          Close
                        </PrimaryButton>
                      </Row>
                    </div>
                  </>
                ) : (
                  <div className="anim-slide-left">
                    <Paragraph>Are you sure you want to close this session?</Paragraph>

                    <div className="mb-small">
                      <Row type="flex" justify="end">
                        <Col>
                          <GhostButton size="large" onClick={this._closeModal}>
                            Cancel
                          </GhostButton>
                        </Col>
                        <Col>
                          <PrimaryButton loading={isLoading} onClick={this._closeSession} size="large">
                            Close session
                          </PrimaryButton>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </ActionModal>
      </div>
    );
  }
}

type SupportWorkerErrorItemProps = {
  errors: ClosingErrorProps[];
  errorLabel: string;
  timezone: string;
  loadingActionLabel?: string;
  actions: QuickActionType[];
} & QuickActionHandlers;

const SupportWorkerErrorItem = ({
  errors,
  errorLabel,
  timezone,
  goToTab,
  loadingActionLabel = '',
  doSubAction,
  actions,
}: SupportWorkerErrorItemProps) => (
  <div>
    <div className="mb-small">
      <Text color="secondary">Booking not {errorLabel}</Text>
    </div>
    {_.map(errors, (error, index) => {
      return (
        <ErrorDisplayComponent
          error={error}
          errors={errors}
          actions={actions}
          mainText={
            <>
              {error.parentAttendanceId && (
                <div>
                  <TextTag
                    content="Transport shift"
                    color="indigo"
                    icon="car"
                    size="small"
                    textSize="small"
                    theme="light"
                  />
                </div>
              )}
              <Paragraph>
                <b>
                  {error.firstName} {error.lastName}
                </b>{' '}
                shift has not been {errorLabel}.
              </Paragraph>
              <Paragraph className="mb-small">
                {moment.tz(error.shiftStartDateTime, timezone).format('ddd Do MMMM YYYY')}
                <br />
                {moment.tz(error.shiftStartDateTime, timezone).format('hh:mmA')} -{' '}
                {moment.tz(error.shiftEndDateTime, timezone).format('hh:mmA')}
              </Paragraph>
            </>
          }
          loadingActionLabel={loadingActionLabel}
          doSubAction={doSubAction}
          goToHyperlink={
            <HyperlinkButton onClick={() => goToTab('TEAM-MEMBERS', error.supportWorkerAttendanceId)}>
              <Icon type="select" className="mr-small" />
              View shifts
            </HyperlinkButton>
          }
          index={index}
          updatingText="Updating shift..."
          type="shift"
        />
      );
    })}
  </div>
);

type ErrorDisplayComponentProps = {
  errors: ClosingErrorProps[];
  error: ClosingErrorProps;
  loadingActionLabel: boolean;
  index: number;
  updatingText: string;
  mainText: React.ReactNode;
  actions: QuickActionType[];
  canApprove: boolean;
  type: string;
  goToHyperlink: React.ReactNode;
} & Pick<QuickActionHandlers, 'doSubAction'>;

const ErrorDisplayComponent = ({
  errors,
  error,
  loadingActionLabel,
  index,
  goToHyperlink,
  updatingText,
  mainText,
  actions = null,
  canApprove = true,
  doSubAction,
  type,
}): ErrorDisplayComponentProps => {
  const componentLoadingActionLabel =
    type === 'booking' ? 'b_' + error?.attendanceId : 's_' + error?.supportWorkerAttendanceId;
  let actionMenuItems = [];
  actionMenuItems =
    actions?.length > 1
      ? _.map(actions, (action, key) => {
          return (
            <ErrorQuickActionMenuItem
              quickActionType={action}
              doSubAction={doSubAction}
              error={error}
              canApprove={canApprove}
              textColor={action === quickActionTypes.SHIFT_REMOVE && 'red'}
              key={key}
            />
          );
        })
      : [];
  return (
    <ConditionalWrapper
      condition={loadingActionLabel && loadingActionLabel === componentLoadingActionLabel}
      wrapper={(children) => (
        <Spin
          tip={updatingText}
          indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          className="width-full bg-secondary text-size-regular text-color-secondary anim-fade-in-fast"
          style={{ opacity: 0.8 }}
        >
          {children}
        </Spin>
      )}
    >
      <div className={`bordered flex-row rounded mb-${Number(index) === errors.length - 1 ? 'medium' : 'small'}`}>
        <div className="bg-red-lighter rounded-left" style={{ width: '3px' }} />
        <div className={`p-medium width-full`}>
          {mainText}
          <div className="mt-medium flex-row">
            {actions?.length > 1 && actionMenuItems.length > 1 ? (
              <Popover2
                content={<ActionMenu>{actionMenuItems}</ActionMenu>}
                position="bottom-left"
                interactionKind="hover"
                usePortal={false}
              >
                <SecondaryButton className="mr-medium" size="small" icon="down" iconPosition="right">
                  Quick actions
                </SecondaryButton>
              </Popover2>
            ) : actions?.length === 1 && quickActionLabels[actions[0]] ? (
              <div className="mr-medium">
                <SecondaryButton
                  size="small"
                  onClick={() => (canApprove ? doSubAction(actions[0], error) : false)}
                  disabled={!canApprove}
                >
                  {quickActionLabels[actions[0]]}
                </SecondaryButton>
                <ErrorActionTooltips quickActionType={actions[0]} canApprove={canApprove} />
              </div>
            ) : null}
            <div>{goToHyperlink}</div>
          </div>
        </div>
      </div>
    </ConditionalWrapper>
  );
};

// Get action menu when there are multiple actions available
const ErrorQuickActionMenuItem = ({
  quickActionType,
  canApprove = true,
  doSubAction,
  error,
  className = '',
  textColor = 'black',
}) => {
  return (
    <ActionMenuItem
      text={
        <>
          <Text className={`${!canApprove ? 'text-color-tertiary cursor-not-allowed' : ''} text-color-${textColor}`}>
            {quickActionLabels?.[quickActionType]}
          </Text>
          <ErrorActionTooltips quickActionType={quickActionType} canApprove={canApprove} />
        </>
      }
      className={className}
      onClick={() => (canApprove ? doSubAction(quickActionType, error) : false)}
    />
  );
};

// Get tooltips for each quick action type if relevant
const ErrorActionTooltips = ({ quickActionType, canApprove = true }) => {
  let toolTipContent = null;
  if (quickActionType === quickActionTypes.SHIFT_START) {
    toolTipContent = (
      <DefaultTooltips
        content={
          <div className="p-medium">
            <p className="text-size-regular">
              <b color="warning-orange">Important:</b> This shift will need to be completed and approved as well before
              the session can be closed.
            </p>
            <p className="text-size-regular">
              Note that the shift will be marked as started at its scheduled start time.
            </p>
          </div>
        }
        className="ml-small"
        overlayClassName="bg-white"
        icon="info-circle"
        iconClassName="text-color-blue-action"
      />
    );
  } else if (quickActionType === quickActionTypes.SHIFT_COMPLETE) {
    toolTipContent = (
      <DefaultTooltips
        content={
          <div className="p-medium">
            <p className="text-size-regular">
              <b color="warning-orange">Important:</b> This shift will need to be approved as well before the session
              can be closed.
            </p>
            <p className="text-size-regular">
              Note that the shift will be marked as started and finished at its scheduled start and end time
              respectively.
            </p>
          </div>
        }
        className="ml-small"
        overlayClassName="bg-white"
        icon="info-circle"
        iconClassName="text-color-blue-action"
      />
    );
  } else if (quickActionType === quickActionTypes.SHIFT_COMPLETE_APPROVE) {
    toolTipContent = (
      <DefaultTooltips
        content={
          <div className="p-medium">
            <p className="text-size-regular">Note that the shift scheduled times will be used.</p>
          </div>
        }
        className="ml-small"
        overlayClassName="bg-white"
        icon="info-circle"
        iconClassName="text-color-tertiary"
      />
    );
  } else if (quickActionType === quickActionTypes.BOOKING_START) {
    toolTipContent = (
      <DefaultTooltips
        content={
          <div className="p-medium">
            <p className="text-size-regular">
              <b color="warning-orange">Important:</b> Booking will need to be completed and confirmed as well before
              the session can be closed.
            </p>
            <p className="text-size-regular">
              Note that the booking will be marked as started at it's scheduled start time.
            </p>
          </div>
        }
        className="ml-small"
        overlayClassName="bg-white"
        icon="info-circle"
        iconClassName="text-color-blue-action"
      />
    );
  } else if (quickActionType === quickActionTypes.BOOKING_COMPLETE) {
    toolTipContent = (
      <DefaultTooltips
        content={
          <div className="p-medium">
            <p className="text-size-regular">
              <b color="warning-orange">Important:</b> Booking will need to be confirmed as well before the session can
              be closed.
            </p>
            <p className="text-size-regular">
              Note that the booking will be marked as completed at it's scheduled end time.
            </p>
          </div>
        }
        className="ml-small"
        overlayClassName="bg-white"
        icon="info-circle"
        iconClassName="text-color-blue-action"
      />
    );
  } else if (quickActionType === quickActionTypes.BOOKING_APPROVE) {
    toolTipContent = !canApprove && (
      <DefaultTooltips
        content={
          <div className="p-medium">
            <>
              <p className="text-size-regular text-color-warning-orange">
                <b>Cannot be approved</b>
              </p>
              <p className="text-size-regular">
                This booking cannot be approved because there are errors on the billings line items. Please go to the
                booking by clicking on View booking to fix it.
              </p>
            </>
          </div>
        }
        className="ml-small"
        overlayClassName="bg-white"
        icon="exclamation-circle"
        iconClassName="text-color-warning-orange"
      />
    );
  } else if (quickActionType === quickActionTypes.BOOKING_COMPLETE_APPROVE) {
    toolTipContent = (
      <DefaultTooltips
        content={
          <div className="p-medium">
            {!canApprove ? (
              <>
                <p className="text-size-regular text-color-warning-orange">
                  <b>Cannot be approved</b>
                </p>
                <p className="text-size-regular">
                  This booking cannot be approved because there are errors on the billings line items. Please go to the
                  booking by clicking on View booking to fix it.
                </p>
              </>
            ) : (
              <p className="text-size-regular">
                Note that the booking will be marked as started and completed at it's scheduled start and end time
                respectively.
              </p>
            )}
          </div>
        }
        className="ml-small"
        overlayClassName="bg-white"
        icon={canApprove ? 'info-circle' : 'warning'}
        iconClassName={canApprove ? 'text-color-tertiary' : 'text-color-warning-orange'}
      />
    );
  }
  return toolTipContent;
};

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCloseSession: dispatch.groupServiceStore.doCloseSession,
  doCheckCloseSession: dispatch.groupServiceStore.doCheckCloseSession,
  setSelectedSessionTabKey: dispatch.groupServiceStore.setSelectedSessionTabKey,
  setCustomerBookingToOpen: dispatch.groupServiceStore.setCustomerBookingToOpen,
  doStartGroupBooking: dispatch.groupBookingsStore.doStartGroupBooking,
  doFinishGroupBooking: dispatch.groupBookingsStore.doFinishGroupBooking,
  doStartShift: dispatch.groupServiceStore.doStartShift,
  doRemoveShiftSlot: dispatch.groupServiceStore.doRemoveShiftSlot,
  doApproveShift: dispatch.groupServiceStore.doApproveShift,
  doFinishShift: dispatch.groupServiceStore.doFinishShift,
  doCompleteAndApproveShift: dispatch.groupServiceStore.doCompleteAndApproveShift,
  doApproveGroupBookingsPayment: dispatch.groupBookingsStore.doApproveGroupBookingsPayment,
  doCompleteAndApproveBooking: dispatch.groupBookingsStore.doCompleteAndApproveBooking,
});

export default connect(mapState, mapDispatch)(Form.create<ICloseSessionModalProps>()(CloseSessionModal));
