import React, { forwardRef } from 'react';
import { Cross, Search } from '@good/icons';
import {
  Input,
  SearchField as _SearchField,
  Text,
  type SearchFieldProps as _SearchFieldProps,
} from 'react-aria-components';

import * as styles from './search-field.css';
import { Button } from '../button';
import { FieldLabel } from '../field-label';
import { StatusMessage } from '../status-message';
import { twMerge } from '../utils';

import type { AriaTextFieldProps } from 'react-aria';
import type { HTMLProps, LabelProps } from '../types';

export type SearchFieldProps = HTMLProps<HTMLInputElement> & {
  /**
   * Text for the search field's description element, if any.
   * */
  description?: AriaTextFieldProps['description'];
  /**
   * Text for the search field's error message element.
   */
  errorMessage?: AriaTextFieldProps['errorMessage'];
  /**
   * Controls whether the search field is disabled.
   * @default false
   */
  isDisabled?: AriaTextFieldProps['isDisabled'];
  /**
   * Controls whether the search field is readonly.
   * @default false
   */
  isReadOnly?: AriaTextFieldProps['isReadOnly'];
  /**
   * Controls the search field's validation state.
   */
  validationState?: AriaTextFieldProps['validationState'];
} & LabelProps &
  _SearchFieldProps;

export const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>((props, ref) => {
  const {
    className,
    description,
    errorMessage,
    isRequired,
    label,
    requirementIndicator,
    validationState,
    ...otherProps
  } = props;

  const isDescription = Boolean(description);
  const isErrorMessage = Boolean(errorMessage);
  const isInvalid = validationState === 'invalid';
  const showErrorMessage = isInvalid && isErrorMessage;
  const showDescription = isDescription && !showErrorMessage;
  const { button, container, icon, input, inputContainer } = styles.searchField({ validationState });

  return (
    <_SearchField
      {...otherProps}
      className={twMerge(container(), className)}
      validationState={validationState}
      ref={ref}
    >
      <FieldLabel requirementIndicator={requirementIndicator} isRequired={isRequired}>
        {label}
      </FieldLabel>

      <div className={inputContainer()}>
        <Search className={icon()} />
        <Input className={input()} />
        <Button tone="neutral" emphasis="quiet" size="xs" className={button()}>
          <Cross />
        </Button>
      </div>

      {showDescription && (
        <Text slot="description">
          <StatusMessage tone="neutral">{description}</StatusMessage>
        </Text>
      )}

      {showErrorMessage && (
        <Text slot="errorMessage">
          <StatusMessage tone="critical">{errorMessage}</StatusMessage>
        </Text>
      )}
    </_SearchField>
  );
});

SearchField.displayName = 'SearchField';
