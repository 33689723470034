import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const BatterySlash = forwardRef(function BatterySlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l1.795 1.796a2.82 2.82 0 0 0-.235.052 3.75 3.75 0 0 0-2.652 2.651c-.128.48-.128 1.037-.128 1.855V12.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.982 2.26.601.602 1.36.86 2.26.982.866.116 1.969.116 3.336.116h9.745l2.78 2.78a.75.75 0 0 0 1.06-1.06l-3-3-12-12-3-3Zm.888 4.357c.232-.063.527-.075 1.272-.077l10.5 10.5H8c-1.435 0-2.436-.002-3.192-.103-.733-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191v-2c0-.978.007-1.32.077-1.582a2.25 2.25 0 0 1 1.59-1.591ZM14 6.75c1.436 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4.102.754.103 1.756.103 3.191v3c0 .414.336.75.75.75h.073c.393 0 .696 0 .964-.053a2.75 2.75 0 0 0 2.16-2.16c.053-.268.053-.571.053-.964v-1.146c0-.393 0-.696-.053-.963a2.75 2.75 0 0 0-2.16-2.161 2.905 2.905 0 0 0-.46-.048c-.14-.746-.404-1.385-.925-1.907-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117H10a.75.75 0 1 0 0 1.5h4Zm6.725 3.02c.025.635.025 1.358.025 2.175V14.225c.493-.1.878-.487.976-.981.02-.103.024-.243.024-.744v-1c0-.5-.003-.641-.024-.744a1.25 1.25 0 0 0-1-.985Z"
        fill="currentColor"
      />
    </Icon>
  );
});
