import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Stopwatch = forwardRef(function Stopwatch(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 .25a.75.75 0 1 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Zm2 4.5a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 13c0-5.385 4.365-9.75 9.75-9.75 2.422 0 4.639.884 6.344 2.346L19.97 3.97a.75.75 0 0 1 1.06 1.06l-1.626 1.626A9.715 9.715 0 0 1 21.75 13c0 5.385-4.365 9.75-9.75 9.75S2.25 18.385 2.25 13ZM12 9.25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0v-4a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
