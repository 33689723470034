import { Classes, Popover2 } from '@blueprintjs/popover2';
import { Avatar, Checkbox, Col, Icon, Popover, Row, Tag, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import {
  ActivityGroupMemberType,
  ActivityGroupModalType,
  OutsideAvailabilityType,
  ScheduleType,
  WorkerAssignmentWarningType,
} from 'utilities/enum-utils';
import {
  IGroupServiceActivityGroup,
  IGroupServiceOverview,
  IGroupServiceSession,
  IGroupServiceSessionWarning,
  IGroupServiceSupportWorkersWithConflicts,
  IGroupServiceTimeSlot,
} from 'interfaces/service-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component, Fragment, useMemo } from 'react';
import ActivityGroupsModal from 'common-components/activity-groups/modals/ActivityGroupsModal';
import { IActivityGroupUsers } from 'interfaces/user-interfaces';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import EditShiftTimeModal from './EditShiftTimeModal';

interface IManageSessionStepPanelProps {
  scheduleType: ScheduleType;
  selectedGroupService: IGroupServiceOverview;
  selectedWorkers: IGroupServiceSupportWorkersWithConflicts[];
  selectedSessions: IGroupServiceSession[];
  selectedTimeSlot: IGroupServiceTimeSlot;
  onPreviousStep: () => void;
  onNextStep: () => void;
  onSetSelectedWorkers: (v) => void;
  setGroupServiceActivityGroups: typeof dispatch.groupServiceStore.setGroupServiceActivityGroups;
  onSetSelectedSessions: (selectedSessions) => void;
  doEditWorkerShiftTimeConflictCheck: typeof dispatch.groupServiceStore.doEditWorkerShiftTimeConflictCheck;
}

interface IManageSessionStepPanelState {
  focusedWorker: IGroupServiceSupportWorkersWithConflicts;
  selectedWorkers: IGroupServiceSupportWorkersWithConflicts[];
  isLoading: boolean;
  isModalOpen: boolean;
  isEditShiftTimeModalOpen: boolean;
  currentEditSession: IGroupServiceSession;
  currentModalType: ActivityGroupModalType;
  currentMember: IActivityGroupUsers;
  currentMemberType: ActivityGroupMemberType;
  totalAssignedSessions: number;
  defaultSelectedSessions: IGroupServiceSession[];
}

class ManageSessionStepPanel extends Component<IManageSessionStepPanelProps, IManageSessionStepPanelState> {
  state = {
    focusedWorker: _.isEmpty(this.props.selectedWorkers) ? null : this.props.selectedWorkers[0],
    selectedWorkers: this.props.selectedWorkers,
    isLoading: false,
    isModalOpen: false,
    isEditShiftTimeModalOpen: false,
    currentEditSession: null,
    currentModalType: ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SESSION,
    currentMember: null,
    currentMemberType: ActivityGroupMemberType.TEAM_MEMBER,
    totalAssignedSessions: _.sumBy(this.props.selectedWorkers, ({ assignedSessions }) => assignedSessions.length),
    defaultSelectedSessions: _.cloneDeep(this.props.selectedSessions),
  };

  private _changeFocusWorker = (worker) => {
    this.setState((prevState) => {
      if (worker.userId !== prevState.focusedWorker.userId) {
        const newSelectedWorkers = _.map(prevState.selectedWorkers, (selectedWorker) =>
          selectedWorker.userId === prevState.focusedWorker.userId ? { ...prevState.focusedWorker } : selectedWorker,
        );
        return { focusedWorker: worker, selectedWorkers: newSelectedWorkers };
      }
    });
  };

  private _addSessionToWorker = (session: IGroupServiceSession) => {
    if (!this._findSessionInWorker(session)) {
      this.setState((prevState) => ({
        focusedWorker: {
          ...prevState.focusedWorker,
          assignedSessions: [...prevState.focusedWorker.assignedSessions, session],
        },
        totalAssignedSessions: prevState.totalAssignedSessions + 1,
      }));
    }
  };

  private _removeSessionFromWorker = (session: IGroupServiceSession) => {
    const { focusedWorker } = this.state;
    if (this._findSessionInWorker(session)) {
      const newSessions = focusedWorker.assignedSessions.filter(
        (assignedSession) => assignedSession.serviceDateTimeId !== session.serviceDateTimeId,
      );
      this.setState((prevState) => ({
        focusedWorker: {
          ...prevState.focusedWorker,
          assignedSessions: newSessions,
        },
        totalAssignedSessions: prevState.totalAssignedSessions - 1,
      }));
    }
  };

  private _findSessionInWorker = (session: IGroupServiceSession): boolean => {
    const { focusedWorker } = this.state;
    const findAssignedSessionInWorker = _.find(
      focusedWorker.assignedSessions,
      (assignedSession) => session.serviceDateTimeId === assignedSession.serviceDateTimeId,
    );

    return !!findAssignedSessionInWorker;
  };

  private _checkIsConflictSession = (
    session: IGroupServiceSession,
    worker: IGroupServiceSupportWorkersWithConflicts,
  ): boolean => {
    const findConflictSessionForWorker = _.find(worker.conflicts, (conflict) => {
      return conflict.serviceDateTimeId === session.serviceDateTimeId;
    });

    return !_.isEmpty(findConflictSessionForWorker);
  };

  private _onNextStep = () => {
    const { onSetSelectedWorkers, onNextStep } = this.props;
    const mappedSelectedWorkers = _.map(this.state.selectedWorkers, (worker) => {
      const { removedOn } = worker;
      const removeDate = moment(removedOn);

      let assignedSessions = worker.assignedSessions;
      if (worker.userId === this.state.focusedWorker.userId) {
        assignedSessions = { ...this.state.focusedWorker.assignedSessions };
      }

      return {
        ...worker,
        assignedSessions: _.filter(assignedSessions, (session) => {
          const diff = removeDate.diff(session.startDateTime, 'hours');
          return removedOn ? !(removedOn && diff < 0) : session;
        }),
      };
    });

    onSetSelectedWorkers(mappedSelectedWorkers);
    onNextStep();
  };

  private _onPreviousStep = () => {
    const { onSetSelectedSessions, onPreviousStep } = this.props;
    const { defaultSelectedSessions } = this.state;
    onSetSelectedSessions(defaultSelectedSessions);
    onPreviousStep();
  };

  private _handleOpenModal = (session) => {
    const { selectedSessions, setGroupServiceActivityGroups } = this.props;

    const selectedSession = _.find(
      selectedSessions,
      (selectedSession) => selectedSession.serviceDateTimeId === session.serviceDateTimeId,
    );
    setGroupServiceActivityGroups(_.get(selectedSession, 'activityGroups', []));

    this.setState({ isModalOpen: true, currentEditSession: session });
  };

  private _handleCloseModal = () => {
    this.setState({ isModalOpen: false, currentEditSession: null });
  };

  private _handleModalAction = async (payload, actionType: ActivityGroupModalType) => {
    const { focusedWorker, currentEditSession } = this.state;

    const { addedActivityGroups } = payload;

    if (actionType === ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SESSION) {
      const newFocusedWorker = _.cloneDeep(focusedWorker);
      const newSession = _.find(
        newFocusedWorker.assignedSessions,
        (ss) => ss.serviceDateTimeId === currentEditSession.serviceDateTimeId,
      );

      _.set(newSession, 'activityGroups', addedActivityGroups);
      this._handleAssignTeamMemberToSession(newFocusedWorker);
    }
  };

  private _handleOpenEditShiftTimeModal = (session) => {
    this.setState({ isEditShiftTimeModalOpen: true, currentEditSession: session });
  };

  private _handleSaveEditShiftTimeModal = async (startDateTime, endDateTime) => {
    const { selectedGroupService, doEditWorkerShiftTimeConflictCheck } = this.props;
    const { currentEditSession, focusedWorker, selectedWorkers } = this.state;
    const remainingShifts = _.chain(focusedWorker.sessions)
      .filter((session) => {
        const removeDate = moment(focusedWorker.removedOn);
        const diff = removeDate.diff(session.startDateTime, 'days');
        const isRemoved = focusedWorker.removedOn && diff < 0;
        return !isRemoved && session.serviceDateTimeId !== currentEditSession.serviceDateTimeId;
      })
      .map(({ serviceDateTimeId, startDateTime, endDateTime }) => ({
        serviceDateTimeId,
        shiftStartDateTime: startDateTime,
        shiftEndDateTime: endDateTime,
      }))
      .value();

    const payload = {
      serviceId: selectedGroupService.serviceId,
      supportWorkerId: focusedWorker.supportWorkerId,
      selectedShift: {
        serviceDateTimeId: currentEditSession.serviceDateTimeId,
        shiftStartDateTime: startDateTime,
        shiftEndDateTime: endDateTime,
      },
      remainingShifts,
      allWorkerConflicts: _.get(focusedWorker, 'conflicts', []),
    };

    const conflictSessions = await doEditWorkerShiftTimeConflictCheck(payload);
    const workerConflicts = _.get(conflictSessions, 'workerConflicts', []);

    const newFocusedWorker = _.cloneDeep(focusedWorker);
    newFocusedWorker.conflicts = workerConflicts;

    const foundAssignedSessionIndex = _.findIndex(
      newFocusedWorker.assignedSessions,
      (assignedSession) => assignedSession.serviceDateTimeId === currentEditSession.serviceDateTimeId,
    );
    if (foundAssignedSessionIndex !== -1) {
      _.set(newFocusedWorker, `assignedSessions[${foundAssignedSessionIndex}].startDateTime`, startDateTime);
      _.set(newFocusedWorker, `assignedSessions[${foundAssignedSessionIndex}].endDateTime`, endDateTime);
    }

    const foundSessionIndex = _.findIndex(
      newFocusedWorker.sessions,
      (session) => session.serviceDateTimeId === currentEditSession.serviceDateTimeId,
    );
    if (foundSessionIndex !== -1) {
      _.set(newFocusedWorker, `sessions[${foundSessionIndex}].startDateTime`, startDateTime);
      _.set(newFocusedWorker, `sessions[${foundSessionIndex}].endDateTime`, endDateTime);
    }

    const newSelectedWorkers = _.cloneDeep(selectedWorkers);
    const updatedWorker = _.find(
      newSelectedWorkers,
      (worker) => worker.supportWorkerId === newFocusedWorker.supportWorkerId,
    );
    if (updatedWorker) {
      updatedWorker.conflicts = workerConflicts;
    }

    this.setState({
      focusedWorker: newFocusedWorker,
      selectedWorkers: newSelectedWorkers,
    });
  };

  componentDidMount = () => {
    const { selectedSessions, selectedWorkers } = this.props;
    if (!selectedSessions || !selectedWorkers) return;
    this.setState({ isLoading: true });
    const newWorkers = _.map(selectedWorkers, (worker) => {
      const newSelectedSessions = _.cloneDeep(selectedSessions);
      _.forEach(newSelectedSessions, (session) => {
        _.forEach(session.activityGroups, (activityGroup) => {
          if (_.includes(activityGroup.teamMemberUserIds, worker.userId)) {
            _.set(activityGroup, 'isAssignedTo', true);
          }
        });
      });

      worker.assignedSessions = _.filter(
        newSelectedSessions,
        (session) => !this._checkIsConflictSession(session, worker),
      );
      worker.sessions = _.cloneDeep(newSelectedSessions);

      return worker;
    });

    this.setState({
      selectedWorkers: newWorkers,
      isLoading: false,
      totalAssignedSessions: _.sumBy(newWorkers, ({ assignedSessions }) => assignedSessions.length),
    });
  };

  private _handleAssignTeamMemberToSession = async (focusedWorker: IGroupServiceSupportWorkersWithConflicts) => {
    const { selectedWorkers } = this.state;

    const newSelectedWorkers = _.cloneDeep(selectedWorkers);
    _.forEach(newSelectedWorkers, (worker) => {
      if (worker.supportWorkerId === focusedWorker.supportWorkerId && worker.userId === focusedWorker.userId) {
        worker.assignedSessions = focusedWorker.assignedSessions;
      }
    });

    this.setState({ focusedWorker: focusedWorker, selectedWorkers: newSelectedWorkers });
  };

  private _handleRedirectToHelpCenter = () => {
    const targetUrl =
      this.props.scheduleType === ScheduleType.SCHEDULE
        ? 'https://goodhuman.zendesk.com/hc/en-au/articles/4403969405849-Assigning-team-members-to-multiple-group-sessions#step-4-confirm-team-member-selection-0-13'
        : 'https://goodhuman.zendesk.com/hc/en-au/articles/4403969405849-Assigning-team-members-to-multiple-group-sessions#step-4-confirm-team-member-selection-0-6';
    window.open(targetUrl, '_blank');
  };

  render() {
    const { focusedWorker, selectedWorkers, isLoading, currentEditSession } = this.state;

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={3}>Session preview</Title>
              <Paragraph>
                Here are all the team members you have selected and the sessions you want to assign them to.
              </Paragraph>
              <Paragraph>
                You can remove team members from any specific sessions by unchecking the box of the corresponding
                session
              </Paragraph>
              <Paragraph>
                Visit the <HyperlinkButton onClick={this._handleRedirectToHelpCenter}>Help Center</HyperlinkButton> to
                learn more.
              </Paragraph>
            </div>
          </Col>
          {isLoading ? (
            <SpinningLoader size={100} message="Loading..." />
          ) : (
            <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div className="bg-white ph-large pt-large pb-x2-large rounded-big" style={{ minWidth: '250px' }}>
                <Title level={4}>Team members assignment review</Title>
                <Paragraph className="mv-large">
                  Please review the following sessions for the assigned team members
                </Paragraph>
                <div className="bordered rounded-big mt-large">
                  <Row>
                    <Col span={8} className="flex-col  bordered-right" style={{ marginRight: '-1px' }}>
                      <Row style={{ height: '48px' }} type="flex" align="middle" className="pl-small bordered-bottom">
                        <Text size="regular" color="secondary">
                          {selectedWorkers.length} team member{selectedWorkers.length > 1 && 's'}
                        </Text>
                      </Row>
                      {_.map(selectedWorkers, (worker, index) => (
                        <WorkerItem
                          key={index}
                          worker={worker}
                          isFocus={focusedWorker.userId === worker.userId}
                          onChangeFocus={() => this._changeFocusWorker(worker)}
                        />
                      ))}
                    </Col>
                    <Col span={16} className="bordered-left">
                      <Row style={{ height: '48px' }} type="flex" align="middle" className="bordered-bottom ph-medium">
                        <Col span={2}></Col>
                        <Col span={7}>
                          <FieldLabel text="SHIFT DETAILS" />
                        </Col>
                        <Col span={6}>
                          <FieldLabel text="ACTIVITY GROUP" />
                        </Col>
                        <Col span={7}></Col>
                        <Col span={2}></Col>
                      </Row>
                      {_.chain(focusedWorker.sessions)
                        .orderBy([(session) => new Date(session.startDateTime)], ['asc'])
                        .map((session, index) => (
                          <Fragment key={index}>
                            <SessionItem
                              selectedGroupService={this.props.selectedGroupService}
                              session={session}
                              focusedWorker={focusedWorker}
                              isChecked={this._findSessionInWorker(session)}
                              onAddSession={this._addSessionToWorker}
                              onRemoveSession={this._removeSessionFromWorker}
                              onOpenEditModal={this._handleOpenModal}
                              onOpenEditShiftTimeModal={this._handleOpenEditShiftTimeModal}
                            />
                          </Fragment>
                        ))
                        .value()}
                      {!_.isEmpty(currentEditSession) && (
                        <EditShiftTimeModal
                          isOpen={this.state.isEditShiftTimeModalOpen}
                          onSave={this._handleSaveEditShiftTimeModal}
                          onCancel={() => this.setState({ isEditShiftTimeModalOpen: false })}
                          defaultStartDateTime={_.get(currentEditSession, 'startDateTime')}
                          defaultEndDateTime={_.get(currentEditSession, 'endDateTime')}
                          timezone={_.get(this.props.selectedGroupService, 'timezone')}
                          selectedUser={focusedWorker}
                        />
                      )}
                      {this.state.isModalOpen && (
                        <ActivityGroupsModal
                          serviceId={this.props.selectedGroupService.serviceId}
                          activityGroup={null}
                          modalType={this.state.currentModalType}
                          member={null}
                          memberType={this.state.currentMemberType}
                          isOpen={this.state.isModalOpen}
                          session={currentEditSession}
                          selectedUser={focusedWorker}
                          handleAction={this._handleModalAction}
                          onCloseViewModal={this._handleCloseModal}
                          timeZone={this.props.selectedGroupService.timezone}
                          ignoredAlert={true}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </div>

              <ActionModalFooter align="right" className="mt-small pr-large">
                <SecondaryButton size="large" className="mr-medium" onClick={this._onPreviousStep}>
                  Back
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  onClick={this._onNextStep}
                  disabled={this.state.totalAssignedSessions === 0}
                >
                  Next
                </PrimaryButton>
              </ActionModalFooter>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

interface IWorkerItemProps {
  worker: IGroupServiceSupportWorkersWithConflicts;
  isFocus: boolean;
  onChangeFocus: (v) => void;
}

function WorkerItem(props: IWorkerItemProps) {
  const { worker, isFocus, onChangeFocus } = props;
  const { conflicts: workerConflictSessions } = worker;

  const workerFullName = `${worker.firstName || ''} ${worker.lastName || ''}`;
  let style = 'flex-row justify-between align-center p-small';
  style += isFocus ? ' bg-blue-action-lightest' : ' cursor-pointer';

  const getWarningIcon = useMemo(() => {
    if (_.find(workerConflictSessions, (conflict) => conflict.warning === WorkerAssignmentWarningType.UNAVAILABLE)) {
      return <Icon type="warning" className="text-color-warning-orange mr-x-small" theme="filled" />;
    } else if (
      _.find(workerConflictSessions, (conflict) => conflict.warning === WorkerAssignmentWarningType.IN_SESSION)
    ) {
      return <Icon type="warning" className="text-color-secondary mr-x-small" theme="filled" />;
    } else if (
      _.find(workerConflictSessions, (conflict) => conflict.warning === WorkerAssignmentWarningType.SHIFT_CLASH)
    ) {
      return <Icon type="close-circle" className="text-color-red mr-x-small" theme="outlined" />;
    } else if (_.find(workerConflictSessions, (conflict) => conflict.warning === WorkerAssignmentWarningType.CAUTION)) {
      return <Icon type="info-circle" className="text-color-secondary mr-x-small" theme="outlined" />;
    } else {
      return null;
    }
  }, [workerConflictSessions]);

  return (
    <div style={{ height: '48px' }} className={style} onClick={onChangeFocus}>
      <div>
        <Avatar src={worker.attachmentUrl} icon="user" className="mr-small" />
        <Text>{workerFullName}</Text>
      </div>
      <div>{getWarningIcon}</div>
    </div>
  );
}

interface ISessionItemProps {
  session: IGroupServiceSession;
  isChecked: boolean;
  selectedGroupService: IGroupServiceOverview;
  focusedWorker: IGroupServiceSupportWorkersWithConflicts;
  onAddSession: (v) => void;
  onRemoveSession: (v) => void;
  onOpenEditModal: (session) => void;
  onOpenEditShiftTimeModal: (session) => void;
}

interface ISessionItemState {
  hoveredClash: boolean;
}

const NUMBER_OF_CONFLICTS = 3;

class SessionItem extends Component<ISessionItemProps, ISessionItemState> {
  state = {
    hoveredClash: false,
  };

  private _getWarningByPrecedence = (
    conflictSessions: IGroupServiceSessionWarning[],
  ): { warning: string; note?: string } => {
    let warnSession = null;
    if (_.find(conflictSessions, (conflict) => conflict.warning === WorkerAssignmentWarningType.UNAVAILABLE)) {
      warnSession = { warning: WorkerAssignmentWarningType.UNAVAILABLE };
    } else if (_.find(conflictSessions, (conflict) => conflict.warning === WorkerAssignmentWarningType.IN_SESSION)) {
      warnSession = { warning: WorkerAssignmentWarningType.IN_SESSION };
    } else if (_.find(conflictSessions, (conflict) => conflict.warning === WorkerAssignmentWarningType.SHIFT_CLASH)) {
      warnSession = { warning: WorkerAssignmentWarningType.SHIFT_CLASH };
    } else if (_.find(conflictSessions, (conflict) => conflict.warning === WorkerAssignmentWarningType.CAUTION)) {
      const warningNote = _.find(
        conflictSessions,
        (conflict) => conflict.warning === WorkerAssignmentWarningType.CAUTION,
      );
      warnSession = { warning: WorkerAssignmentWarningType.CAUTION, note: _.get(warningNote, 'note') };
    }

    return warnSession;
  };

  private _onChangeCheckStatus = (e) => {
    const { onAddSession, onRemoveSession, session } = this.props;
    if (e.target.checked) {
      onAddSession(session);
    } else {
      onRemoveSession(session);
    }
  };

  private _renderWarningTag = (isUnavailable = false) => {
    const { focusedWorker, session, selectedGroupService } = this.props;
    const { timezone } = selectedGroupService;
    let warnSession = null;
    if (isUnavailable) {
      warnSession = { warning: WorkerAssignmentWarningType.UNAVAILABLE };
    } else {
      const conflictWarnings = _.chain(focusedWorker.conflicts)
        .filter((conflict) => conflict.serviceDateTimeId === session.serviceDateTimeId)
        .map((conflict) => ({ warning: conflict.warning, note: conflict.note }))
        .value();
      warnSession = this._getWarningByPrecedence(conflictWarnings);
    }

    if (!warnSession) return null;

    const shiftClashData = focusedWorker.conflicts.filter(
      (conflict) =>
        conflict.warning === WorkerAssignmentWarningType.SHIFT_CLASH &&
        conflict.serviceDateTimeId === session.serviceDateTimeId,
    );
    const totalConflicts = shiftClashData.length;

    switch (warnSession.warning) {
      case WorkerAssignmentWarningType.UNAVAILABLE:
        return (
          <>
            <Tooltip title="Team member is scheduled to be removed before this shift" placement="top">
              <Icon type="warning" className="text-color-warning-orange mr-x-small" theme="filled" />
            </Tooltip>
            <Text size="regular">Unavailable</Text>
          </>
        );
      case WorkerAssignmentWarningType.IN_SESSION:
        return (
          <>
            <Icon type="warning" className="text-color-secondary mr-x-small" theme="filled" />
            <Text size="regular">In session</Text>
          </>
        );
      case WorkerAssignmentWarningType.CAUTION:
        return (
          <>
            <Tooltip
              title={
                warnSession.note && warnSession.note === OutsideAvailabilityType.UNAVAILABILITY
                  ? 'This session occurs during a time where the team member has indicated they are unavailable. You can still assign them to this shift.'
                  : `This session is outside the team member's general availability.`
              }
              placement="top"
            >
              <Icon type="info-circle" className="text-color-secondary mr-x-small" theme="outlined" />
            </Tooltip>
            <Text size="regular">Caution</Text>
          </>
        );
      case WorkerAssignmentWarningType.SHIFT_CLASH:
        return (
          <>
            <Popover
              content={
                <>
                  <Title level={4}>
                    {totalConflicts} shift clash{totalConflicts > 1 ? 'es' : ''}
                  </Title>
                  <Text size="regular" className="block">
                    This team member already assigned to the following shift(s):
                  </Text>
                  <Text size="regular" className="block">
                    Please double-check before assigning to the shift
                  </Text>
                  <ul className="mv-medium">
                    {_.chain(shiftClashData)
                      .take(NUMBER_OF_CONFLICTS)
                      .map(({ additionalData }, idx) => {
                        const isSameDate = moment(additionalData.shiftStartDateTime).isSame(
                          additionalData.shiftEndDateTime,
                          'date',
                        );
                        let displayDateTime = `${moment
                          .tz(additionalData.shiftStartDateTime, timezone)
                          .format('ddd DD MMM YYYY')} 
                         - ${moment.tz(additionalData.shiftEndDateTime, timezone).format('ddd DD MMM YYYY')}`;
                        if (isSameDate) {
                          displayDateTime = `${moment
                            .tz(additionalData.shiftStartDateTime, timezone)
                            .format('ddd DD MMM YYYY')} 
                          - ${moment.tz(additionalData.shiftStartDateTime, timezone).format('hh:mm a')}
                          - ${moment.tz(additionalData.shiftEndDateTime, timezone).format('hh:mm a')}
                          `;
                        }
                        return (
                          <li key={idx}>
                            <Text size="regular" className="block text-color-gray-darker">
                              {additionalData.serviceName}
                            </Text>
                            <Text size="regular" className="block text-color-gray-darker">
                              {displayDateTime} &nbsp;&nbsp;
                              {additionalData.shiftStatus ? `(${additionalData.shiftStatus.toLowerCase()})` : ''}
                            </Text>
                          </li>
                        );
                      })
                      .value()}
                  </ul>
                  {totalConflicts > NUMBER_OF_CONFLICTS && (
                    <Text size="regular" className="block text-italic">
                      and {totalConflicts - NUMBER_OF_CONFLICTS} more conflicts
                    </Text>
                  )}
                </>
              }
              trigger="hover"
              visible={this.state.hoveredClash}
              onVisibleChange={(visible) => this.setState({ hoveredClash: visible })}
            >
              <Icon type="close-circle" className="text-color-red mr-x-small" theme="outlined" />
            </Popover>
            <Text size="regular" color="red">
              Shift clash
            </Text>
          </>
        );
      default:
        return null;
    }
  };

  private _getActivityGroupPopoverContent = (activityGroups: IGroupServiceActivityGroup[]) => {
    return (
      <div className="ph-x-large pv-medium" style={{ minWidth: '327px', maxHeight: '246px', overflowX: 'hidden' }}>
        <div className="mb-medium">
          <Text className="text-weight-bold">Assigned to ({_.get(activityGroups, 'length')}) activity groups</Text>
        </div>
        <div className="flex-column space-between" style={{ rowGap: '12px' }}>
          {_.map(activityGroups, (item: IGroupServiceActivityGroup, index: number) => {
            return (
              <div className="whitespace-nowrap" key={index}>
                <Tag key={index} className="tag-icon bg-tertiary flex-row align-center" style={{ border: 'none' }}>
                  <Icon type="calendar" style={{ fontSize: '14px' }} />
                  <Text size="regular">{item.name}</Text>
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  private _getEditSchedulePopoverContent = () => {
    const { onOpenEditModal, onOpenEditShiftTimeModal, session } = this.props;
    return (
      <div className={`${Classes.POPOVER2_DISMISS} pv-small`}>
        <Row
          className="cursor-pointer ph-medium pv-small hover-bg-blue-lightest"
          onClick={() => onOpenEditShiftTimeModal(session)}
        >
          Edit shift times
        </Row>
        <Row
          className="cursor-pointer ph-medium pv-small hover-bg-blue-lightest"
          onClick={() => onOpenEditModal(session)}
        >
          Edit activity groups
        </Row>
      </div>
    );
  };

  render() {
    const { session, selectedGroupService, isChecked, focusedWorker } = this.props;
    const { startDateTime, endDateTime } = session;
    const { timezone } = selectedGroupService;
    const sessionStart = moment.tz(startDateTime, timezone);
    const sessionEnd = moment.tz(endDateTime, timezone);

    const isRemoved = focusedWorker.removedOn && moment(focusedWorker.removedOn).isSameOrBefore(moment(startDateTime));

    const conflictWarnings = _.chain(focusedWorker.conflicts)
      .filter((conflict) => conflict.serviceDateTimeId === session.serviceDateTimeId)
      .map((conflict) => ({ warning: conflict.warning, note: conflict.note }))
      .value();
    const warnSession = this._getWarningByPrecedence(conflictWarnings);

    const isDisabled =
      isRemoved ||
      _.get(warnSession, 'warning', '') === WorkerAssignmentWarningType.IN_SESSION ||
      _.get(warnSession, 'warning', '') === WorkerAssignmentWarningType.UNAVAILABLE;
    const opacity = isDisabled ? 0.5 : 1;

    const groupActivityGroups = _.get(session, 'activityGroups', []);
    const selectedActivityGroups = _.filter(groupActivityGroups, (activityGroup) => activityGroup.isAssignedTo);
    const activityGroupsLength = _.get(selectedActivityGroups, 'length', 0);

    return (
      <Row style={{ opacity }} type="flex" align="middle" className="ph-medium evenodd pv-medium">
        <Col span={2}>
          <Checkbox checked={isChecked && !isDisabled} onChange={this._onChangeCheckStatus} disabled={isDisabled} />
        </Col>
        <Col span={7}>
          <Text size="regular">{sessionStart.format('ddd, D MMMM YYYY')}</Text>
          <div>
            <Text size="regular">
              {sessionStart.format('h:mm A')} - {sessionEnd.format('h:mm A')}
            </Text>
          </div>
        </Col>
        <Col span={6}>
          {activityGroupsLength === 0 ? (
            <Text size="regular" color="secondary">
              Not added
            </Text>
          ) : (
            <Popover2
              content={this._getActivityGroupPopoverContent(selectedActivityGroups)}
              position="bottom-right"
              interactionKind="hover"
            >
              <Tag className="tag-icon bg-tertiary flex-row align-center bordered-none">
                <Icon type="calendar" className="text-size-regular" />
                <Text size="regular">
                  {activityGroupsLength} {activityGroupsLength >= 2 ? 'groups' : 'activity'}
                </Text>
              </Tag>
            </Popover2>
          )}
        </Col>
        <Col span={7}>{this._renderWarningTag(isRemoved)}</Col>
        <Col span={2} className="text-align-right">
          {isChecked && !isDisabled && (
            <Popover2 position="bottom-right" content={this._getEditSchedulePopoverContent()}>
              <Text className="cursor-pointer p-small text-color-blue-action">Edit</Text>
            </Popover2>
          )}
        </Col>
      </Row>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setGroupServiceActivityGroups: dispatch.groupServiceStore.setGroupServiceActivityGroups,
  doEditWorkerShiftTimeConflictCheck: dispatch.groupServiceStore.doEditWorkerShiftTimeConflictCheck,
});

export default connect(null, mapDispatch)(ManageSessionStepPanel);
