import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { Icon } from 'antd';
import ActionSelectItem from 'common-components/items/ActionSelectItem';
import { FormBuilderPermissionType } from 'utilities/enum-utils';
import { getFormBuilderPermissionName } from 'views/account-management/utils/permission-utils';

interface IFormBuilderSectionModalProps {
  isOpen: any;
  onClose: any;
  setSelectedOption: (servicePermission) => any;
  permissions?: any;
}

interface IFormBuilderSectionModalState {
  formBuilderPermission: string;
}

class FormBuilderSectionModal extends Component<IFormBuilderSectionModalProps, IFormBuilderSectionModalState> {
  state = { formBuilderPermission: FormBuilderPermissionType.NO_ACCESS };

  private _onCloseModal = () => {
    this.props.onClose();
  };

  private _setSelectedOption = async () => {
    await this.props.setSelectedOption({ formBuilderPermission: this.state.formBuilderPermission });
    this._onCloseModal();
  };

  private _changePermissionMode = (newMode) => {
    this.setState({ formBuilderPermission: newMode });
  };

  private _resetPermissions = () => {
    const formBuilderPermission = getFormBuilderPermissionName(this.props.permissions);
    this.setState({ formBuilderPermission });
  };

  componentDidMount() {
    this._resetPermissions();
  }

  componentDidUpdate(
    prevProps: Readonly<IFormBuilderSectionModalProps>,
    prevState: Readonly<IFormBuilderSectionModalState>,
    snapshot?: any,
  ) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        this._resetPermissions();
      }
    }
  }

  render() {
    const { formBuilderPermission } = this.state;

    return (
      <ActionModal
        title={
          <>
            <Icon type={'form'} className={'mr-small text-size-x2-large'} />
            Form builder
          </>
        }
        isOpen={this.props.isOpen}
        onClose={this._onCloseModal}
        width="medium"
      >
        <div className="mv-medium anim-slide-left">
          <div className={'mb-x-large'}>
            <div className={'mb-large'}>
              <Text size={'x-large'}>Permission level</Text>
            </div>

            <ActionSelectItem
              selectedAction={formBuilderPermission}
              value={FormBuilderPermissionType.NO_ACCESS}
              title={'No access'}
              description={'Cannot access form-builder'}
              onSetAction={this._changePermissionMode}
            />

            <ActionSelectItem
              selectedAction={formBuilderPermission}
              value={FormBuilderPermissionType.ACCESS}
              title={'Standard Access'}
              description={'Can access form-builder and create, view and edit forms'}
              onSetAction={this._changePermissionMode}
            />
          </div>

          <ActionModalFooter>
            <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._setSelectedOption}>
              Save changes
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    );
  }
}

export default FormBuilderSectionModal;
