import React from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { PrimaryButton, GhostButton } from 'common-components/buttons';

interface ICancelFormBuilderModalProps {
  isProceedOpen: boolean;
  onReset: () => void;
  closeProceedModal: () => void;
}

const CancelFormBuilderModal = ({ isProceedOpen, onReset, closeProceedModal }: ICancelFormBuilderModalProps) => {
  return (
    <ActionModal isOpen={isProceedOpen} onClose={closeProceedModal} title={'Reset changes'} showCloseButton={true}>
      <Text className={'mb-medium'}>
        Are you sure you want to reset changes to this form. This will discard any changes you have made to the form.
      </Text>

      <ActionModalFooter>
        <GhostButton className="mr-medium" size="large" onClick={closeProceedModal}>
          Back
        </GhostButton>
        <PrimaryButton size="large" onClick={onReset}>
          Reset
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};
export default CancelFormBuilderModal;
