import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import ActivityLogPanel from '../manage-booking-modal/panels/activity-log/ActivityLogPanel';
import { state } from 'stores/rematch/root-store';

interface ISessionActivityLogPanelProps {
  session: typeof state.groupServiceStore.selectedSession;
}

export default class SessionActivityLogPanel extends Component<ISessionActivityLogPanelProps> {
  render() {
    return (
      <div className="pv-medium pl-small">
        <div className="ph-large pt-large pb-x2-large rounded-big shadow-container bg-white flex-1">
          <div className="line-height-120 flex-row justify-between align-center">
            <div>
              <div className="mb-x-small">
                <Text lineHeight={120} weight="bold">
                  Activity log
                </Text>
              </div>
              <Text lineHeight={120}>Activity log for this session</Text>
            </div>
          </div>
          <ActivityLogPanel session={this.props.session} isLoading={false} />
        </div>
      </div>
    );
  }
}
