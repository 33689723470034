import { notification, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { IGroupServiceActivityGroup } from 'interfaces/service-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { ActivityGroupModalType } from 'utilities/enum-utils';

interface IDeleteModalContentProps {
  activityGroup: IGroupServiceActivityGroup;
  handleAction: (payload, actionType: ActivityGroupModalType) => Promise<boolean | void>;
  onCloseViewModal: () => void;
}

interface IDeleteModalContentState {
  isLoading: boolean;
}

const INITIAL_STATE: IDeleteModalContentState = {
  isLoading: false,
};

class DeleteModalContent extends Component<IDeleteModalContentProps, IDeleteModalContentState> {
  state = _.cloneDeep(INITIAL_STATE);

  private _onSubmitForm = async () => {
    const { activityGroup, handleAction, onCloseViewModal } = this.props;
    this.setState({ isLoading: true });

    try {
      const payload = { ...activityGroup };
      await handleAction(payload, ActivityGroupModalType.DELETE);
      notification.success({
        message: 'Activity group deleted',
        description: `You've successfully deleted "${activityGroup.name}".`,
      });
      onCloseViewModal();
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong! Please try again.',
      });
    }

    this.setState({ isLoading: false });
  };

  render() {
    const { onCloseViewModal, activityGroup } = this.props;
    const { isLoading } = this.state;
    return (
      <>
        <Row>
          <Paragraph>
            You are about to delete <b>{activityGroup.name}</b>.
          </Paragraph>
        </Row>
        <ActionModalFooter className="flex-row">
          <div className="flex flex-grow"></div>
          <SecondaryButton color="secondary" size="large" onClick={onCloseViewModal}>
            Cancel
          </SecondaryButton>

          <PrimaryButton
            color="red"
            className="ml-medium"
            size="large"
            loading={isLoading}
            onClick={this._onSubmitForm}
          >
            Delete activity group
          </PrimaryButton>
        </ActionModalFooter>
      </>
    );
  }
}

export default DeleteModalContent;
