import React from 'react';
import { Col, Row } from 'antd';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { Text, Title } from 'common-components/typography';
import { INewMemberData, IServiceDepartment } from 'interfaces/account-interfaces';
import PermissionsControlPanel from 'views/account-management/add-new-member/panels/permissions-panel/portal/PermissionsControlPanel';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { Warning } from 'common-components/alerts';

interface IPermissionsPanelProps {
  onStepNext: any;
  onStepPrevious: any;
  newMemberData?: INewMemberData;
  setNewMemberData?: any;
  serviceDepartmentList: typeof state.servicesStore.serviceDepartmentListLite;
  doFetchServiceDepartmentsLite: typeof dispatch.servicesStore.doFetchServiceDepartmentsLite;
}

class PermissionsPanel extends React.Component<IPermissionsPanelProps, {}> {
  onChangePermissions = (permissions) => {
    const { newMemberData, setNewMemberData } = this.props;
    const updatedData = { ...newMemberData, permissions };
    setNewMemberData(updatedData);
  };

  componentDidMount = async () => {
    await this.props.doFetchServiceDepartmentsLite({
      isArchived: false,
      page: 1,
      pageSize: 50,
      pageTimestamp: new Date(),
    });
  };

  render() {
    let { onStepPrevious, onStepNext, newMemberData, serviceDepartmentList } = this.props;
    const { permissions } = newMemberData;

    const isNoPermissionError =
      !newMemberData.permissions.hasPortalAccess && !newMemberData.permissions.hasWorkerAppAccess;

    return (
      <div className="anim-slide-left select-none">
        <Row className="ph-x2-large">
          {/* Left Column */}
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 142px)', overflow: 'auto' }}>
            <div style={{ maxWidth: '80%', minHeight: 'calc(100vh - 262px)' }}>
              {/* <-- ! Left Content Starts here ! --> */}

              {/* Left Title */}
              <Title level={3} weight="regular">
                Permissions
              </Title>

              {/* Left Description */}
              <Text size="large">
                Configure what this person can do in your business. <br />
                <br />
                {isNoPermissionError ? (
                  <Warning content={'Assign at least one permission to continue creating this team member.'} />
                ) : (
                  <Text size="large">Assign at least one permission to continue creating this team member.</Text>
                )}
                {/*<br />*/}
                {/*<br /> Visit the <HyperlinkButton>Help Center</HyperlinkButton> to learn more.*/}
              </Text>
            </div>

            {/* Left Button */}
            <div
              className="pv-x2-large width-full text-align-left"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <PrimaryButton size="large" onClick={onStepPrevious}>
                Back
              </PrimaryButton>
            </div>
          </Col>

          {/* Right Column */}
          <Col span={18} style={{ minHeight: 'calc(100vh - 142px)', maxWidth: '820px' }} className="pl-large">
            <div style={{ minHeight: 'calc(100vh - 262px)' }}>
              {/* <-- ! Right Content Starts here ! --> */}
              <PermissionsControlPanel
                permissions={permissions}
                serviceDepartments={serviceDepartmentList}
                onChangePermissions={this.onChangePermissions}
              />
            </div>

            {/* Right Button */}
            <div
              className="pv-x2-large width-full text-align-right"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <PrimaryButton size="large" disabled={isNoPermissionError} onClick={onStepNext}>
                Next
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  serviceDepartmentList: state.servicesStore.serviceDepartmentListLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServiceDepartmentsLite: dispatch.servicesStore.doFetchServiceDepartmentsLite,
});

export default connect(mapState, mapDispatch)(PermissionsPanel);
