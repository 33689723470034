import React from 'react';
import { Button, Box, Title, Text, Container, Divider, Group, Stack, TextInput } from '@good/ui/core';

// ! This page will be removed after testing
export const TestMantineView: React.FunctionComponent = () => {
  return (
    <Container size='md' px='xl' py='5rem'>
      <Stack gap='xl'>
        <Box>
          <Title mb='lg'>This is a Mantine test page</Title>
          <Divider mb='lg' />
          <Text>Nothing terribly interesting here yet.</Text>
          <Text className='underline'>If this line is underlined, then Tailwind works</Text>
        </Box>
        <Divider />
        <Box>
          <Title order={3} mb='lg'>
            Buttons
          </Title>
          <Stack gap='xl'>
            <Box>
              <Text mb='xs'>Filled variant</Text>
              <Group gap='md'>
                <Button variant='filled' color='accent'>
                  Accent
                </Button>
                <Button variant='filled' color='warning'>
                  Warning
                </Button>
                <Button variant='filled' color='danger'>
                  Danger
                </Button>
                <Button variant='filled' color='neutral'>
                  Neutral
                </Button>
              </Group>
            </Box>
            <Box>
              <Text mb='xs'>Outline variant</Text>
              <Group gap='md'>
                <Button variant='outline' color='accent'>
                  Accent
                </Button>
                <Button variant='outline' color='warning'>
                  Warning
                </Button>
                <Button variant='outline' color='danger'>
                  Danger
                </Button>
                <Button variant='outline' color='neutral'>
                  Neutral
                </Button>
              </Group>
            </Box>
            <Box>
              <Text mb='xs'>Transparent variant</Text>
              <Group gap='md'>
                <Button variant='transparent' color='accent'>
                  Accent
                </Button>
                <Button variant='transparent' color='warning'>
                  Warning
                </Button>
                <Button variant='transparent' color='danger'>
                  Danger
                </Button>
                <Button variant='transparent' color='neutral'>
                  Neutral
                </Button>
              </Group>
            </Box>
          </Stack>
        </Box>
        <Divider />
        <Box>
          <Title order={2} mb='lg'>
            Titles
          </Title>
          <Stack>
            <Title order={1}>Title 1</Title>
            <Title order={2}>Title 2</Title>
            <Title order={3}>Title 3</Title>
            <Title order={4}>Title 4</Title>
            <Title order={5}>Title 5</Title>
            <Title order={6}>Title 6</Title>
          </Stack>
          <Divider my='xl' />
          <Stack mt='xl'>
            <Title order={2} mb='lg'>
              Text
            </Title>
            <Text size='xs'>Text - size xs</Text>
            <Text size='sm'>Text - size sm</Text>
            <Text size='md'>Text - size md</Text>
            <Text size='lg'>Text - size lg</Text>
            <Text size='xl'>Text - size xl</Text>
          </Stack>
        </Box>
        <Divider />
        <Box>
          <Title order={2} mb='lg'>
            Inputs
          </Title>
          <Box>
            <TextInput placeholder='Default' />
          </Box>
        </Box>
      </Stack>
    </Container>
  );
};
