import React, { useState, useEffect, useCallback } from 'react';

import { useInViewport } from '@good/ui/hooks';
import { Box } from '@good/ui/core';
import { InfiniteScrollLoadingSkeleton } from './infinite-scroll-loading-skeleton';
import { InfiniteScrollEnd } from './infinite-scroll-end';

type InfiniteScrollProps = {
  hasMore: boolean;
  loadMore: (() => Promise<void>) | (() => void);
  customLoader?: React.ReactNode;
  children?: React.ReactNode;
  asTableRow?: boolean;
  loaderProps?: {
    h?: number;
    rows?: number;
  };
};

export const InfiniteScroll = ({
  hasMore,
  loadMore,
  children,
  asTableRow,
  customLoader,
  loaderProps,
}: InfiniteScrollProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { ref, inViewport } = useInViewport();

  const load = useCallback(async () => {
    try {
      setIsLoading(true);
      await loadMore();
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const loadMore = inViewport && hasMore && !isLoading;
    if (loadMore) {
      void load();
    }
  }, [inViewport, isLoading, hasMore, load]);

  return (
    <>
      {children}
      {hasMore ? (
        customLoader ?? <InfiniteScrollLoadingSkeleton loading={isLoading} asTableRow={asTableRow} {...loaderProps} />
      ) : (
        <InfiniteScrollEnd asTableRow={asTableRow} />
      )}
      <Box component={asTableRow ? 'tr' : 'div'} ref={ref} h={1} />
    </>
  );
};
