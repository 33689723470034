import { useState } from 'react';

import { Filters, DateFilter } from './types';

export const allDateFilter: DateFilter = {
  startDate: undefined,
  endDate: undefined,
  selectionLabel: 'All',
};

export function useFilters(): Filters {
  const [dateFilter, setDateFilter] = useState<DateFilter>(allDateFilter);
  const [serviceIds, setServiceIds] = useState<string[]>([]);

  return {
    dateFilter,
    setDateFilter,
    serviceIds,
    setServiceIds,
  };
}
