import apiClient from '../../utilities/api-client';
import { BudgetStatus, createBudget } from './budget';

import type { BudgetKeys } from './query-keys';
import type { QueryFunctionContext } from '@tanstack/react-query';

export type CustomerBudgetTracking = {
  budgetTrackingStatus: BudgetStatus;
  activeServiceAgreementId: string;
  services: ServiceBudget[];
};

type ServiceBudget = {
  serviceId: string;
  serviceName: string;
  hasPermissionToViewBudgetingPage: boolean;
  serviceType: string;
  startDate: string;
  endDate: string;
  budgetTrackingStatus: BudgetStatus;
};

/**
 * @name
 * fetchBudget
 *
 * @description
 * Fetch budget information for a customer's service agreement.
 */
export async function fetchBudget(ctx: QueryFunctionContext<BudgetKeys['budget']>) {
  const { queryKey } = ctx;
  const [budgetsKey, budgetKey, agreementId] = queryKey;

  return apiClient
    .get(`/api/portal/budgeting/service-agreements/${agreementId}`)
    .then(({ data }) => data)
    .then(createBudget);
}

export async function fetchBudgetTrackingForCustomer(ctx: QueryFunctionContext<BudgetKeys['customerBudget']>) {
  const { queryKey } = ctx;
  const [budgetsKey, budgetKey, customerId] = queryKey;

  return apiClient.get(`/api/portal/budgeting/customers/${customerId}`).then(({ data }) => data);
}
