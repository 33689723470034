import { Col, Icon, Input, notification, Radio, Row, Select } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import { Information, Warning } from 'common-components/alerts';
import ErrorBanner from 'common-components/alerts/ErrorBanner';
import Validation from 'common-components/alerts/Validation';
import { HyperlinkButton } from 'common-components/buttons';
import TimeInput from 'common-components/time-input/TimeInput';
import { SubTitle, Text } from 'common-components/typography';
import { IGroupServiceCustomerRatio, IGroupServiceSession } from 'interfaces/service-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import _moment from 'moment';
import { extendMoment } from 'moment-range';
import React, { Component } from 'react';
import CommonUtils from 'utilities/common-utils';
import { TeamMemberCustomerRatio } from 'utilities/enum-utils';
import { v4 as uuidv4 } from 'uuid';

const moment = extendMoment(_moment);

interface ICustomerRatioSelectionPanelProps extends FormComponentProps {
  groupServiceServiceAgreements: any;
  sessions: IGroupServiceSession[];
  saveRatios: (ratios) => void;
  timezone: timeZone;
  isBookingDisplay?: boolean;
  isEdit?: boolean;
  currentRatios?: IGroupServiceCustomerRatio[];
}

interface ICustomerRatioSelectionPanelState {
  isLoading: boolean;
  ratios: IGroupServiceCustomerRatio[];
  cacheValues: ICatchValue[];
}

interface ICatchValue {
  id?: string;
  key?: string;
  value?: any;
}

class CustomerRatioSelectionPanel extends Component<
  ICustomerRatioSelectionPanelProps,
  ICustomerRatioSelectionPanelState
> {
  state = {
    isLoading: false,
    ratios: [],
    cacheValues: [],
  };

  public _validateRatios = async () => {
    const { form, saveRatios } = this.props;
    const { ratios } = this.state;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (this._checkTimeErrors()) {
      isFormValid = false;
    }

    if (isFormValid) {
      try {
        saveRatios(ratios);
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  private _checkTimeErrors = () => {
    const { sessions } = this.props;
    const { ratios } = this.state;
    let isThereSomeErrors = false;
    this.setState({
      ratios: _.map(ratios, (ratio) => {
        const errors: any = { isAGap: false, isConflict: false };
        const orderedCustomTimes = _.sortBy(ratio.customRatio, 'startDateTime', 'ASC');
        const customRatio = _.map(orderedCustomTimes, (customTime, index) => {
          let isAGap = false;
          const isTheLast = index === orderedCustomTimes.length - 1;
          const isStartingBeforeGroupStart =
            index === 0 && moment(customTime.startDateTime).isBefore(moment(sessions[0].startDateTime));
          const isEndingAfterGroupEnd =
            isTheLast && moment(customTime.endDateTime).isAfter(moment(sessions[0].endDateTime));
          const isConflict = _.some(
            _.filter(orderedCustomTimes, (c) => c.customTimeId !== customTime.customTimeId),
            (filteredList) =>
              moment
                .range([customTime.startDateTime, customTime.endDateTime])
                .overlaps(moment.range([filteredList.startDateTime, filteredList.endDateTime])),
          );
          if (isConflict) {
            errors.isConflict = true;
          }
          if (index === 0 && !moment(customTime.startDateTime).isSameOrBefore(moment(sessions[0].startDateTime))) {
            isAGap = true;
          }
          if (isTheLast && !moment(customTime.endDateTime).isSameOrAfter(moment(sessions[0].endDateTime))) {
            isAGap = true;
          }
          if (
            !isTheLast &&
            moment(customTime.endDateTime).isBefore(moment(orderedCustomTimes[index + 1].startDateTime))
          ) {
            isAGap = true;
          }
          if (isAGap) {
            errors.isAGap = true;
          }
          if (isAGap || isStartingBeforeGroupStart || isEndingAfterGroupEnd || isConflict) {
            isThereSomeErrors = true;
          }
          return { ...customTime, timeErrors: { isStartingBeforeGroupStart, isEndingAfterGroupEnd } };
        });

        return { ...ratio, customRatio, errors };
      }),
    });
    return isThereSomeErrors;
  };

  private _setRatioType = (group, isCustomRatio) => {
    const { sessions } = this.props;
    this.setState({
      ratios: _.map(this.state.ratios, (currentGroup) => {
        if (
          group.startDateTime === currentGroup.startDateTime &&
          group.userServiceAgreementId === currentGroup.userServiceAgreementId
        ) {
          return {
            ...group,
            isCustomRatio,
            customRatio: [
              {
                customTimeId: uuidv4(),
                startDateTime: sessions[0].startDateTime,
                endDateTime: sessions[0].endDateTime,
                teamMemberCustomerRatio:
                  group.teamMemberCustomerRatio && group.teamMemberCustomerRatio.ndis
                    ? group.teamMemberCustomerRatio.ndis
                    : group.teamMemberCustomerRatio,
              },
            ],
            errors: null,
          };
        } else {
          return { ...group };
        }
      }),
    });
  };

  private _changeRatio = (value, group, customTimeId = null) => {
    const ratioData = group.isCustomRatio
      ? _.map(group.customRatio, (customTime) => {
          if (customTime.customTimeId === customTimeId) {
            return { ...customTime, teamMemberCustomerRatio: { ndis: value } };
          } else {
            return { ...customTime };
          }
        })
      : value;
    this.setState({
      ratios: _.map(this.state.ratios, (currentGroup) => {
        if (
          group.startDateTime === currentGroup.startDateTime &&
          group.userServiceAgreementId === currentGroup.userServiceAgreementId
        ) {
          return {
            ...group,
            customRatio: group.isCustomRatio
              ? ratioData
              : [
                  {
                    ...group.customRatio[0],
                    teamMemberCustomerRatio: ratioData,
                  },
                ],
          };
        } else {
          return { ...group };
        }
      }),
    });
  };

  private _onChangeComment = (event, group, customTimeId) => {
    this.setState({
      ratios: _.map(this.state.ratios, (currentGroup) => {
        if (
          group.startDateTime === currentGroup.startDateTime &&
          group.userServiceAgreementId === currentGroup.userServiceAgreementId
        ) {
          return {
            ...group,
            customRatio: _.map(group.customRatio, (customTime) => {
              if (customTime.customTimeId === customTimeId) {
                return { ...customTime, comments: event.target.value };
              } else {
                return { ...customTime };
              }
            }),
          };
        } else {
          return { ...group };
        }
      }),
    });
  };

  private _setDate = (event, group, formName, customTimeId, dateType) => {
    const roundedValue = CommonUtils.formatCeilingDateTime(moment(event));

    this.setState({
      cacheValues: [...this.state.cacheValues, { id: customTimeId, key: dateType, value: roundedValue }],
    });
  };

  private _deleteCustomTime = (group, customTimeId) => {
    this.setState({
      ratios: _.map(this.state.ratios, (currentGroup) => {
        if (
          group.startDateTime === currentGroup.startDateTime &&
          group.userServiceAgreementId === currentGroup.userServiceAgreementId
        ) {
          return {
            ...group,
            customRatio: _.filter(group.customRatio, (customTime) => customTime.customTimeId !== customTimeId),
          };
        } else {
          return { ...group };
        }
      }),
    });
  };

  private _addTimeSlot = (group) => {
    const { sessions } = this.props;
    const newCustomTimes = group.customRatio;
    const lastCustomTime = newCustomTimes[newCustomTimes.length - 1];
    newCustomTimes.push({
      customTimeId: uuidv4(),
      startDateTime: moment(lastCustomTime.endDateTime),
      endDateTime: sessions[0].endDateTime,
      teamMemberCustomerRatio: group.teamMemberCustomerRatio,
    });
    this.setState({
      ratios: _.map(this.state.ratios, (currentGroup) => {
        if (
          group.startDateTime === currentGroup.startDateTime &&
          group.userServiceAgreementId === currentGroup.userServiceAgreementId
        ) {
          return {
            ...group,
            customRatio: newCustomTimes,
          };
        } else {
          return { ...group };
        }
      }),
    });
  };

  componentDidUpdate = () => {
    const { cacheValues } = this.state;
    const ratios = _.cloneDeep(this.state.ratios);
    if (!_.isEmpty(cacheValues)) {
      _.map(cacheValues, (cache) => {
        _.map(ratios, (ratio) => {
          ratio.errors = null;
          _.map(ratio.customRatio, (customRatio) => {
            if (customRatio.customTimeId === cache.id) {
              customRatio[cache.key] = cache.value;
              customRatio.timeErrors = null;
            }
          });
        });
      });
      this.setState({ cacheValues: [], ratios: ratios });
    }
  };

  componentDidMount = () => {
    const { sessions, currentRatios = null, isEdit = false } = this.props;
    this.setState({
      isLoading: false,
      ratios: isEdit
        ? currentRatios
        : _.map(this.props.groupServiceServiceAgreements, (group) => {
            return {
              ...group,
              teamMemberCustomerRatio: group.teamMemberCustomerRatio && group.teamMemberCustomerRatio.ndis,
              startDateTime: moment(moment.tz(group.startDateTime, this.props.timezone).format('YYYY-MM-DD HH:mm')),
              endDateTime: moment(moment.tz(group.endDateTime, this.props.timezone).format('YYYY-MM-DD HH:mm')),
              isCustomRatio: false,
              customRatio: [
                {
                  customTimeId: uuidv4(),
                  startDateTime: sessions[0].startDateTime,
                  endDateTime: sessions[0].endDateTime,
                  teamMemberCustomerRatio:
                    group.teamMemberCustomerRatio && group.teamMemberCustomerRatio.ndis
                      ? group.teamMemberCustomerRatio.ndis
                      : group.teamMemberCustomerRatio,
                },
              ],
            };
          }),
    });
  };

  render() {
    const { sessions, isBookingDisplay = false, form } = this.props;
    const { ratios } = this.state;
    const { getFieldDecorator } = form;

    const areAllSessionTimeTheSame =
      sessions &&
      sessions.length > 0 &&
      _.every(
        sessions,
        (ratio) =>
          moment(ratio.startDateTime).format('HH:mm') ===
            moment(sessions[0] && sessions[0].startDateTime).format('HH:mm') &&
          moment(ratio.endDateTime).format('HH:mm') === moment(sessions[0] && sessions[0].endDateTime).format('HH:mm'),
      );
    const isOneOfTheSessionOnMultipleDays =
      sessions &&
      sessions.length > 0 &&
      _.some(sessions, (ratio) =>
        moment(ratio.startDateTime).startOf('day').isSame(moment(ratio.endDateTime).endOf('day')),
      );

    return (
      <>
        <div className={'mt-large'}>
          <>
            {ratios && !isBookingDisplay && (
              <div className={'mb-medium'}>
                {ratios.length === 1 && ratios[0].userServiceAgreementId && (
                  <Validation
                    content={'The customer has a service agreement for the dates of the sessions.'}
                    className={'mb-small'}
                  />
                )}
                {ratios.length > 1 && _.some(ratios, (group) => !group.userServiceAgreementId) && (
                  <Warning
                    content={
                      'The customer does not have a service agreement for certain dates of the sessions being added.'
                    }
                    className={'mb-small'}
                  />
                )}
                {ratios.length > 1 && _.every(ratios, (group) => group.userServiceAgreementId) && (
                  <Warning
                    content={'The selected sessions fall under multiple service agreements.'}
                    className={'mb-small'}
                  />
                )}
                {_.every(ratios, (group) => !group.userServiceAgreementId) && (
                  <Warning
                    content={'The customer does not have any service agreements for any of the sessions selected.'}
                    className={'mb-small'}
                  />
                )}
              </div>
            )}
            {!isBookingDisplay && !areAllSessionTimeTheSame && (
              <Information
                content={
                  <Text>
                    The ability to apply custom ratios is not available as you have selected sessions that have
                    differing start/end times.
                    <br />
                    To apply custom ratios, selected sessions must have the same start/end times.
                  </Text>
                }
              />
            )}
            {isOneOfTheSessionOnMultipleDays && (
              <Information
                content={
                  <Text>
                    Custom ratios cannot be applied if you have selected{' '}
                    {isBookingDisplay ? 'a booking that' : 'sessions which'} run over multiple days.
                  </Text>
                }
              />
            )}
            <div className={'mt-large'} style={{ maxWidth: '1000px' }}>
              {_.map(ratios, (group) => {
                return (
                  <div className={'mb-large rounded-big bordered border-secondary border-width-medium'}>
                    {!isBookingDisplay && (
                      <div className={'bg-secondary p-medium'}>
                        {ratios.length === 1 ? (
                          <Text>
                            Default ratio based on{' '}
                            <b>{group.userServiceAgreementId ? `customer's service agreement` : `service ratio`}</b>
                          </Text>
                        ) : (
                          <>
                            <Text>
                              Ratio for sessions from <b>{moment(group.startDateTime).format('DD/MM/YYYY')}</b> to{' '}
                              <b>{moment(group.endDateTime).format('DD/MM/YYYY')}</b>
                            </Text>
                            <br />
                            <Text size={'regular'}>
                              Based on {group.userServiceAgreementId ? `customer's service agreement` : `service ratio`}
                            </Text>
                          </>
                        )}
                      </div>
                    )}
                    <div className={'p-medium'}>
                      {group.errors && group.errors.isAGap && (
                        <ErrorBanner className={'mv-small'} content={'There cannot be any gap between timeslots'} />
                      )}
                      {group.errors && group.errors.isConflict && (
                        <ErrorBanner className={'mv-small'} content={'Timeslots cannot overlap'} />
                      )}
                      <div>
                        <Radio checked={!group.isCustomRatio} onClick={() => this._setRatioType(group, false)}>
                          Use single ratio for entire {isBookingDisplay ? 'booking' : 'session'}
                        </Radio>
                        {!group.isCustomRatio && (
                          <div className={'mt-x-small ml-large'}>
                            <SubTitle>Customer ratio</SubTitle>
                            <Form.Item>
                              {getFieldDecorator(group.scheduleId + '_default_ratio', {
                                initialValue: group.customRatio[0].teamMemberCustomerRatio,
                                rules: [
                                  {
                                    required: true,
                                    message: 'Select ratio',
                                  },
                                ],
                              })(
                                <Select
                                  onChange={(event) => this._changeRatio(event, group)}
                                  style={{ width: '100px' }}
                                >
                                  {_.map(TeamMemberCustomerRatio, (ratio) => {
                                    return <Select.Option value={ratio}>{ratio}</Select.Option>;
                                  })}
                                </Select>,
                              )}
                            </Form.Item>
                          </div>
                        )}
                      </div>
                      <div className={'mt-large'}>
                        <Radio
                          checked={group.isCustomRatio}
                          onClick={() => this._setRatioType(group, true)}
                          disabled={!areAllSessionTimeTheSame || isOneOfTheSessionOnMultipleDays}
                        >
                          Use custom ratio by hour
                        </Radio>
                        {group.isCustomRatio && (
                          <div className={'rounded-big mt-x-small ml-large bg-tertiary p-medium'}>
                            {_.map(group.customRatio, (custom, index) => {
                              const isATimeError =
                                custom.timeErrors &&
                                (custom.timeErrors.isStartingBeforeGroupStart ||
                                  custom.timeErrors.isStartingBeforeGroupStart);
                              return (
                                <div className={'bg-white rounded-big bordered border-standard-grey p-medium mb-large'}>
                                  <Row gutter={24}>
                                    <Col span={10}>
                                      <SubTitle>Time</SubTitle>
                                      <div className={'flex-row'}>
                                        <div className={'mr-small'}>
                                          <TimeInput
                                            size={'large'}
                                            defaultValue={custom.startDateTime}
                                            className={isATimeError && 'border-red-dark'}
                                            onChange={(event) =>
                                              this._setDate(
                                                event,
                                                group,
                                                group.scheduleId + '_startDateTime_' + index,
                                                custom.customTimeId,
                                                'startDateTime',
                                              )
                                            }
                                          />
                                        </div>
                                        <div>
                                          <TimeInput
                                            size={'large'}
                                            defaultValue={custom.endDateTime}
                                            className={isATimeError && 'border-red-dark'}
                                            onChange={(event) =>
                                              this._setDate(
                                                event,
                                                group,
                                                group.scheduleId + '_endDateTime_' + index,
                                                custom.customTimeId,
                                                'endDateTime',
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      {custom.timeErrors && custom.timeErrors.isStartingBeforeGroupStart && (
                                        <Text color={'red-dark'}>
                                          Start time cannot be before the {isBookingDisplay ? 'booking' : 'session'}
                                          &apos;s start.
                                          <br />
                                        </Text>
                                      )}
                                      {custom.timeErrors && custom.timeErrors.isEndingAfterGroupEnd && (
                                        <Text color={'red-dark'}>
                                          End time cannot be after the {isBookingDisplay ? 'booking' : 'session'}&apos;s
                                          end.
                                        </Text>
                                      )}
                                    </Col>
                                    <Col span={4}>
                                      <SubTitle>Ratio</SubTitle>
                                      <Form.Item className={'m-none'}>
                                        {getFieldDecorator(group.scheduleId + '_ratio_' + index, {
                                          initialValue: custom.teamMemberCustomerRatio,
                                          rules: [
                                            {
                                              required: true,
                                              message: 'Select ratio',
                                            },
                                          ],
                                        })(
                                          <Select
                                            onChange={(event) => this._changeRatio(event, group, custom.customTimeId)}
                                            style={{ width: '100px' }}
                                            size={'large'}
                                          >
                                            {_.map(TeamMemberCustomerRatio, (ratio) => {
                                              return <Select.Option value={ratio}>{ratio}</Select.Option>;
                                            })}
                                          </Select>,
                                        )}
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <SubTitle>Comments</SubTitle>
                                      <Form.Item className={'m-none'}>
                                        {getFieldDecorator(group.scheduleId + '_comment_' + index, {
                                          initialValue: custom.comments ? custom.comments : undefined,
                                        })(
                                          <Input
                                            type={'text'}
                                            size={'large'}
                                            maxLength={100}
                                            onChange={(event) =>
                                              this._onChangeComment(event, group, custom.customTimeId)
                                            }
                                            placeholder={'Add comments...'}
                                          />,
                                        )}
                                      </Form.Item>
                                    </Col>
                                    <Col span={2} className={'text-align-right'}>
                                      {group.customRatio.length > 1 &&
                                        Number(index) === group.customRatio.length - 1 && (
                                          <Icon
                                            type={'delete'}
                                            theme={'filled'}
                                            onClick={() => this._deleteCustomTime(group, custom.customTimeId)}
                                            className={'text-color-blue-action cursor-pointer'}
                                            style={{ marginTop: '32px' }}
                                          />
                                        )}
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                            {group.customRatio &&
                            moment(group.customRatio[group.customRatio.length - 1].endDateTime) >= group.endDateTime ? (
                              <Text color={'secondary'}>
                                <Icon type={'plus'} className={'mr-small'} /> Add slot
                              </Text>
                            ) : (
                              <HyperlinkButton onClick={() => this._addTimeSlot(group)}>
                                <Icon type={'plus'} className={'mr-small'} />
                                Add slot
                              </HyperlinkButton>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        </div>
      </>
    );
  }
}

export default Form.create<ICustomerRatioSelectionPanelProps>()(CustomerRatioSelectionPanel);
