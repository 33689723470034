import React from 'react';
import { formatDuration, intervalToDuration } from 'date-fns';

type DurationFormatterProps = {
  value: { startDate: Date; endDate: Date };
};

export function DurationFormatter(props: DurationFormatterProps): JSX.Element {
  let {
    value: { startDate, endDate },
  } = props;

  let duration = intervalToDuration({
    start: startDate,
    end: endDate,
  });

  let formattedDuration = formatDuration(duration, { zero: false, format: ['years', 'months', 'weeks', 'days'] });
  return <>{formattedDuration}</>;
}
