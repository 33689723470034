import { Popover } from '@blueprintjs/core';
import { Icon, Row } from 'antd';
import { Paragraph, Text } from 'common-components/typography';
import _ from 'lodash';
import React, { memo } from 'react';

interface IPaymentErrorIndicatorProps {
  errorTypes?: string[];
  billingItems?: { paymentMethod: string; isServiceAgreementWarning: boolean }[];
  warningTypes?: string[];
}

const PaymentErrorIndicator = ({ errorTypes, billingItems, warningTypes }: IPaymentErrorIndicatorProps) => {
  const _getErrorMessage = () => {
    const paymentErrorArray: string[] = [];
    billingItems.forEach((lineItem) => {
      if (
        errorTypes.some((o) => {
          return o === 'NDIA';
        })
      ) {
        paymentErrorArray.push('No NDIS number');
      } else if (
        errorTypes.some((o) => {
          return o === 'VCP';
        })
      ) {
        paymentErrorArray.push('No case manager');
      }

      if (lineItem.paymentMethod === 'PLAN' && errorTypes.some((et) => et === 'PLAN')) {
        paymentErrorArray.push('No plan manager');
      }

      if (lineItem.paymentMethod === 'SELF' && errorTypes.some((et) => et === 'SELF')) {
        paymentErrorArray.push('No self manager');
      }

      return paymentErrorArray;
    });
    return _.uniq(paymentErrorArray);
  };

  const _getWarningMessage = () => {
    const warnings: string[] = [];
    _.forEach(billingItems, (lineItem) => {
      if (
        _.some(warningTypes, (o) => {
          return o === 'SERVICE_AGREEMENT';
        }) &&
        lineItem.isServiceAgreementWarning
      ) {
        warnings.push('Line item not in service agreement');
      }
    });
    return _.uniq(warnings);
  };

  const _getPopoverContent = (errors: string[], warnings: string[]) => {
    let title = '';
    if (warnings.length && errors.length) {
      title = 'Warnings/errors';
    } else if (errors.length) {
      title = 'Errors';
    } else if (warnings.length) {
      title = 'Warnings';
    }
    return (
      <div className="ph-medium pv-medium" style={{ width: '290px' }}>
        <Paragraph weight="bold">{title}</Paragraph>
        {_.map(errors, (message, index) => (
          <Row className="mt-medium" key={message}>
            <Icon
              type="close-circle"
              theme="filled"
              className="text-color-red-light mr-x-small"
              style={{ fontSize: 18 }}
            />
            <Text className="mt-medium">{message}</Text>
          </Row>
        ))}
        {_.map(warnings, (message, index) => (
          <Row className="mt-medium" key={message}>
            <Icon
              type="warning"
              theme="filled"
              className="text-color-orange-lighter mr-x-small"
              style={{ fontSize: 18 }}
            />
            <Text className="mt-medium">{message}</Text>
          </Row>
        ))}
      </div>
    );
  };

  const errors = _getErrorMessage();
  const warnings = _getWarningMessage();
  return (
    <>
      {(errors.length > 0 || warnings.length > 0) && (
        <Popover content={_getPopoverContent(errors, warnings)} position={'top-right'} interactionKind="hover">
          <Icon
            type={errors.length > 0 ? 'close-circle' : 'warning'}
            theme="filled"
            className={`${errors.length > 0 ? 'text-color-red-light' : 'text-color-orange-lighter'} mr-12`}
            style={{ fontSize: 18 }}
          />
        </Popover>
      )}
    </>
  );
};

export default memo(PaymentErrorIndicator);
