import React from 'react';
import { CustomerConnectionStatus, CustomerStatusType } from '../../utilities/enum-utils';
import { Label, LabelProps } from '@goodhuman-me/components';

interface ICustomerStatusTagsProps {
  customerStatus: string;
}

type LabelAttributes = Pick<LabelProps, 'emphasis' | 'tone'> & { label: string };

const customerStatusTagDecorators = (statusType: string) => {
  const defaultDecoration = {
    label: 'Enquiry',
    tone: 'blue',
    emphasis: 'outlined',
  };

  return (
    {
      [`${CustomerStatusType.ARCHIVED}`]: {
        label: 'Archived',
        tone: 'critical',
        emphasis: 'light',
      },
      [`${CustomerConnectionStatus.CONNECTED}`]: {
        label: 'Active',
        tone: 'positive',
        emphasis: 'light',
      },
      [`${CustomerStatusType.ACTIVE}`]: {
        label: 'Active',
        tone: 'positive',
        emphasis: 'light',
      },
    }[statusType] || defaultDecoration
  );
};

const CustomerStatusTags = ({ customerStatus }: ICustomerStatusTagsProps): JSX.Element => {
  const { label, tone, emphasis } = customerStatusTagDecorators(customerStatus) as LabelAttributes;

  return (
    <Label emphasis={emphasis} tone={tone} size="small">
      {label}
    </Label>
  );
};

export default CustomerStatusTags;
