import React from 'react';
import { Text } from '@good/components';

export const InLineIconButton = ({
  label,
  icon,
  size,
  className,
  onClick,
}: {
  label: string;
  icon: JSX.Element;
  size?: string;
  className?: string;
  onClick?: () => void;
}) => {
  const classNameProp = ['align-center inline-flex cursor-pointer justify-center', className].join(' ');
  return (
    <button className={classNameProp} onClick={onClick}>
      <Text size={size} className="font-bold text-accent">
        {label}
      </Text>
      <Text size={size} className="ml-1 text-accent" asChild>
        {icon}
      </Text>
    </button>
  );
};
