import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Box, Inline, Text } from '@goodhuman-me/components';

import { IBooking } from 'interfaces/booking-interfaces';
import {
  ConditionallyHidden,
  CancelBookingSelect,
  CancelBookingsRadio,
  CancelBookingNumberInput,
  CancelBookingCheckbox,
} from '../components';
import useIsInputValid from './use-is-input-valid';
import useResetHiddenFields from './use-reset-hidden-fields';
import useFocusAndResetCustomDurationNumber from './use-focus-and-reset-custom-duration-number';
import getWatchConditions from './get-watch-conditions';
import { Schema } from '../schema';
import fields from '../fields';
import useBookingDuration from './use-booking-duration';
import useCustomDurationUnit from './use-custom-duration-unit';

export const testId = 'booking-cancel-action-modal-payment-view';

interface IBookingCancelActionModalBookingViewProps {
  setTitle: (title: string) => void;
  setContinueButtonLabel: (label: string) => void;
  setIsInputValid: (isValid: boolean) => void;
  serviceType: string;
  booking: IBooking;
  isVisible: boolean;
  isEditing: boolean;
}

const BookingCancelActionModalBookingView = ({
  setIsInputValid,
  booking,
  setTitle,
  setContinueButtonLabel,
  isVisible,
  isEditing,
}: IBookingCancelActionModalBookingViewProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'bookings.modals.cancelBooking' });
  const { workerId } = booking ?? { workerId: null };
  const isTeamMemberAssigned = Boolean(workerId);
  const duration = useBookingDuration(booking);

  useEffect(() => {
    if (isVisible) {
      if (isEditing) {
        setTitle(t('editPaymentStep.title'));
        setContinueButtonLabel(t('editPaymentStep.continue'));
      } else {
        setTitle(t('paymentStep.title'));
        setContinueButtonLabel(t('paymentStep.continue'));
      }
    }
  }, [isVisible, isEditing]);

  const form = useFormContext<Schema>();
  useCustomDurationUnit(form);
  useFocusAndResetCustomDurationNumber(form);
  useResetHiddenFields(form);
  useIsInputValid({
    form,
    isTeamMemberAssigned,
    setIsInputValid,
    maxDuration: parseInt(duration),
  });

  const {
    cancellingOnBehalfOfBusiness,
    shouldChargeCancellationFee,
    shouldPayTeamMember,
    teamMemberPaymentDuration,
    isCustomPaymentDurationHours,
  } = getWatchConditions(form);

  if (!booking) {
    return <Text data-testid={`${testId}-no-booking`}>Oops! No booking selected.</Text>;
  }

  return (
    <>
      <Box marginTop="$xsmall" marginBottom="$xsmall" data-testid={`${testId}`}>
        <Box width={375}>
          <ConditionallyHidden hidden={cancellingOnBehalfOfBusiness}>
            <Box marginBottom="$medium" data-testid={`${testId}-cancellation-fee`}>
              <Box paddingBottom={12}>
                <Text fontWeight={700}>{t('shouldChargeCancellationFee.label')}</Text>
              </Box>
              <Box paddingBottom={10}>
                <CancelBookingCheckbox
                  field={fields.shouldChargeCancellationFee}
                  label={t('shouldChargeCancellationFee.option')}
                />
              </Box>
              <ConditionallyHidden hidden={!shouldChargeCancellationFee || !isTeamMemberAssigned}>
                <Box paddingBottom="$xsmall">
                  <CancelBookingSelect field={fields.cancellationFeeReason} />
                </Box>
              </ConditionallyHidden>
            </Box>
          </ConditionallyHidden>
          <ConditionallyHidden hidden={!isTeamMemberAssigned}>
            <Box data-testid={`${testId}-pay-team-member`}>
              <Box paddingBottom={12}>
                <Text fontWeight={700}>{t('shouldPayTeamMember.label')}</Text>
              </Box>
              <Box paddingBottom="$xsmall">
                <CancelBookingCheckbox field={fields.shouldPayTeamMember} label={t('shouldPayTeamMember.option')} />
              </Box>
              <ConditionallyHidden hidden={!shouldPayTeamMember}>
                <Box paddingLeft="$large">
                  <CancelBookingsRadio
                    field={fields.teamMemberPaymentDuration}
                    interpolations={{
                      duration: duration,
                    }}
                  />
                  <ConditionallyHidden
                    hidden={teamMemberPaymentDuration !== fields.teamMemberPaymentDuration.options.customDuration}
                  >
                    <Box paddingLeft="$large" paddingTop="$xxsmall">
                      <CancelBookingNumberInput
                        field={fields.customTeamMemberPaymentDuration}
                        max={parseInt(duration) * (isCustomPaymentDurationHours ? 1 : 60)}
                        extra={
                          <CancelBookingSelect
                            field={fields.customTeamMemberPaymentDurationUnit}
                            size="default"
                            allowClear={false}
                          />
                        }
                        precision={isCustomPaymentDurationHours ? 2 : 0}
                        step={isCustomPaymentDurationHours ? 0.5 : 15}
                      />
                    </Box>
                  </ConditionallyHidden>
                </Box>
              </ConditionallyHidden>
            </Box>
          </ConditionallyHidden>
        </Box>
      </Box>
    </>
  );
};

export default BookingCancelActionModalBookingView;
