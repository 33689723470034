import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Checkbox, Col, Empty, Icon, Tag } from 'antd';
import { YearAndMonthFilter } from 'common-components/filter';
import { GridHeader, GridRow } from 'common-components/grids';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { FieldLabel, Text } from 'common-components/typography';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { IYearAndMonthFilter } from 'interfaces/filter-interfaces';
import {
  IActivityGroup,
  IGroupServiceSession,
  IGroupServiceCustomerRatio,
  IGroupServiceCustomRatio,
} from 'interfaces/service-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IRootState, state } from 'stores/rematch/root-store';
import { SessionStatus, ActivityGroupModalType } from 'utilities/enum-utils';
import RatioPopoverContent from '../customer-schedule/wizard-steps/ratio-popover-content';

type ISelectAllHandler = {
  onCheckAll: (filteredSessionList: IGroupServiceSession[]) => void;
  checkAllIndicator?: boolean;
  indeterminateCheck?: boolean;
};
type IGroupServiceSessionsPanelProps = {
  sessions: IGroupServiceSession[];
  groupServiceServiceAgreements: typeof state.groupServiceStore.groupServiceServiceAgreements;
  onSelect?: (session: IGroupServiceSession, filteredSessionList: IGroupServiceSession[]) => void;
  timezone: timeZone;
  displayTimeSlotDescription?: boolean;
  displayWarnings?: boolean;
  displayTeamMemberCustomerRatios?: boolean;
  hideEmptyDates?: boolean;
  filterWarnings?: Array<string> | null;
  onChangeFilter?: ({ filter, filteredSessionList }) => void;
  isLoading?: boolean;
  displayActivityGroup?: boolean;
  isEditAddCustomerToSessions?: boolean;
  onEditAddCustomerToSessions?: (session, actionType) => void;

  selectAllHandler?: ISelectAllHandler; //used for select/deselect all checkbox
};

type IGroupServiceSessionsPanelState = {
  filter: IYearAndMonthFilter;
  filteredSessionList: IGroupServiceSession[];
};

class GroupServiceSessionsPanel extends Component<IGroupServiceSessionsPanelProps, IGroupServiceSessionsPanelState> {
  state = {
    filter: this._getFilterFromSessionsList(),
    filteredSessionList: [],
  };

  private _getFilterFromSessionsList() {
    let startDateTime = this.props.sessions[0] && this.props.sessions[0].startDateTime;
    if (this.state && !_.isEmpty(this.state.filteredSessionList)) {
      startDateTime = this.state.filteredSessionList[0] && this.state.filteredSessionList[0].startDateTime;
    }

    const startDateTimeMoment = moment(startDateTime);
    return {
      year: startDateTimeMoment.year(),
      month: startDateTimeMoment.month(), // moment.month() is zero based it will return 0-11
    };
  }

  private _updateFilter = (filter) => {
    this.setState({ filter });
  };

  private _activityGroupPopoverContent = (activityGroups: IActivityGroup[]) => {
    const { timezone } = this.props;
    return (
      <div className='p-medium' style={{ minWidth: '327px', maxHeight: '246px', overflowX: 'hidden' }}>
        <Text weight='bold' className='m-small'>
          Assigned to ({activityGroups.length}) activity {activityGroups.length > 1 ? 'groups' : 'group'}
        </Text>
        {activityGroups.map((activity: IActivityGroup, index) => (
          <div key={index} className='m-small'>
            <Tag className='tag-icon bg-tertiary flex-row align-center' style={{ border: 'none' }}>
              <Icon type='calendar' style={{ fontSize: '14px' }} />
              <Text size='regular'>{activity.name}</Text>
            </Tag>
            <br></br>
            <Text>
              {moment.tz(activity.startDateTime, timezone).format('hh:mm A')} –{' '}
              {moment.tz(activity.endDateTime, timezone).format('hh:mm A')} •{' '}
              {moment.tz(activity.startDateTime, timezone).format('ddd, DD MMM')} –{' '}
              {moment.tz(activity.endDateTime, timezone).format('ddd, DD MMM YYYY')}
            </Text>
          </div>
        ))}
      </div>
    );
  };

  private _renderSessionWarning = (
    session,
    isStatusCannotAdd: boolean,
    isCapacityFull: boolean,
    hasServiceAgreement,
  ) => {
    if (session.alreadyInSession) {
      return (
        <Tooltip2 content='Customer is already in this session'>
          <Icon type='warning' theme='filled' className='mr-small text-color-warning-orange' />
        </Tooltip2>
      );
    }

    if (isStatusCannotAdd) {
      return (
        <Tooltip2 content={`Session ${session.sessionStatus === SessionStatus.CANCELLED ? 'cancelled' : 'closed'}`}>
          <Icon type='warning' theme='filled' className='mr-small text-color-tertiary' />
        </Tooltip2>
      );
    }

    if (isCapacityFull) {
      return (
        <Tooltip2 content='Session is at full capacity'>
          <Icon type='warning' theme='filled' className='mr-small text-color-red-dark' />
        </Tooltip2>
      );
    }

    if (session.isCustomerArchived) {
      return (
        <Tooltip2 content='Customer archived'>
          <Icon type='warning' theme='filled' className='mr-small text-color-red-dark' />
        </Tooltip2>
      );
    }

    if (!hasServiceAgreement) {
      return (
        <Tooltip2 content='Customer does not have a service agreement for this session'>
          <Icon type='warning' theme='filled' className='mr-small text-color-warning-orange' />
        </Tooltip2>
      );
    }

    return <></>;
  };

  private _handleMenuAction = (session, actionType = undefined) => {
    const { onEditAddCustomerToSessions } = this.props;
    if (onEditAddCustomerToSessions) {
      onEditAddCustomerToSessions(session, actionType);
    }
  };

  private _filterSessionList = () => {
    const { sessions } = this.props;
    const { filter } = this.state;
    const filteredSessionList = _.filter(
      sessions,
      (session) =>
        moment(session.startDateTime).year() === filter.year && moment(session.startDateTime).month() === filter.month,
    );

    this.setState({ filteredSessionList });
  };

  componentDidMount(): void {
    this._filterSessionList();
  }

  componentDidUpdate(
    prevProps: Readonly<IGroupServiceSessionsPanelProps>,
    prevState: Readonly<IGroupServiceSessionsPanelState>,
  ): void {
    if (prevState.filter !== this.state.filter) {
      this._filterSessionList();
    }

    if (!_.isEqual(prevProps.sessions, this.props.sessions)) {
      const { sessions } = this.props;

      const filter = this._getFilterFromSessionsList();
      const filteredSessionList = _.filter(
        sessions,
        (session) =>
          moment(session.startDateTime).year() === filter.year &&
          moment(session.startDateTime).month() === filter.month,
      );
      this.setState({ filter, filteredSessionList });
    }
  }

  private _onSessionSelected(isDisabled, session, filteredSessionList) {
    if (!isDisabled) {
      this.props.onSelect(session, filteredSessionList);
    }
  }

  render = () => {
    const {
      sessions,
      timezone,
      displayTimeSlotDescription = false,
      displayWarnings = false,
      hideEmptyDates = false,
      filterWarnings = null,
      onSelect = null,
      isLoading = false,
      displayActivityGroup = false,
      isEditAddCustomerToSessions = false,
      displayTeamMemberCustomerRatios,
    } = this.props;
    const { filter, filteredSessionList } = this.state;

    const isAllSelected = filteredSessionList.every((session) => session.isSelected);
    const isSomeSelected = filteredSessionList.some((session) => session.isSelected);

    const onCheckAll = this.props.selectAllHandler?.onCheckAll;

    const checkAllIndicator = this.props.selectAllHandler?.checkAllIndicator || isAllSelected;
    const indeterminateCheck = this.props.selectAllHandler?.indeterminateCheck || (isSomeSelected && !isAllSelected);

    const SessionEmptyState = () => (
      <div className='flex-1 bg-white mt-x2-large align-center flex-column'>
        <div className=''>
          <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className='mv-none' />
        </div>
        <Text size='x2-large' color='secondary' weight='bold'>
          No session found for the selected month.
        </Text>
      </div>
    );

    return (
      <div>
        {sessions && (
          <YearAndMonthFilter
            filter={filter}
            startDateTime={moment(sessions[0]?.startDateTime)}
            endDateTime={sessions.length > 0 && _.last(sessions) ? moment(_.last(sessions)?.endDateTime) : moment()}
            updateFilter={this._updateFilter}
            hideEmptyDates={hideEmptyDates}
            dateList={hideEmptyDates && sessions}
            filterWarnings={filterWarnings}
          />
        )}
        <GridHeader bordered containerClassName='border-width-medium border-secondary'>
          {!this.props.selectAllHandler && (
            <Col span={2} className='bg-white'>
              <FieldLabel text='' />
            </Col>
          )}
          {this.props.selectAllHandler && (
            <Col span={2} className='bg-white'>
              <Checkbox
                onClick={() => onCheckAll(filteredSessionList)}
                checked={checkAllIndicator}
                indeterminate={indeterminateCheck}
                disabled={_.isEmpty(filteredSessionList)}
              />
            </Col>
          )}
          <Col span={5} className='bg-white'>
            <FieldLabel text='Booking date' />
          </Col>
          <Col span={displayTimeSlotDescription ? 7 : 5} className='bg-white'>
            <FieldLabel text={displayTimeSlotDescription ? 'Time slot description' : 'Schedule'} />
          </Col>
          {displayTeamMemberCustomerRatios && (
            <Col span={4} className='bg-white'>
              <FieldLabel text='Ratio' />
            </Col>
          )}
          <Col span={displayTeamMemberCustomerRatios ? 8 : 10} className='bg-white'>
            <FieldLabel text='Extras' />
          </Col>
        </GridHeader>
        {isLoading ? (
          <SpinningLoader size={100} message='Generating the sessions...' />
        ) : filteredSessionList?.length === 0 ? (
          <SessionEmptyState />
        ) : (
          _.map(filteredSessionList, (session: any, index) => {
            const startDateTime = moment.tz(session.startDateTime, timezone);
            const endDateTime = moment.tz(session.endDateTime, timezone);

            const isCapacityFull =
              session.capacity && session.bookedCapacity !== undefined && session.bookedCapacity >= session.capacity;

            const statusCannotAdd =
              session.sessionStatus === SessionStatus.CANCELLED || session.sessionStatus === SessionStatus.CLOSED;

            const hasServiceAgreement = !!_.find(
              this.props.groupServiceServiceAgreements,
              (agreement) => agreement.serviceDateTimeId === session.serviceDateTimeId,
            )?.userServiceAgreementId;

            const isDisabled =
              session.isInHoliday ||
              (displayWarnings &&
                (isCapacityFull || session.alreadyInSession || statusCannotAdd || session.isCustomerArchived));

            const hasWarnings =
              session.alreadyInSession ||
              session.isCustomerArchived ||
              statusCannotAdd ||
              isCapacityFull ||
              !hasServiceAgreement;

            const dateLineText = !startDateTime.isSame(endDateTime, 'day')
              ? !startDateTime.isSame(endDateTime, 'month')
                ? !startDateTime.isSame(endDateTime, 'year')
                  ? startDateTime.format("ddd D MMM 'YY") + ' - ' + endDateTime.format("ddd D MMM 'YY")
                  : startDateTime.format('ddd D MMM') + ' - ' + endDateTime.format("ddd D MMM 'YY")
                : startDateTime.format('ddd D') + ' - ' + endDateTime.format("ddd D MMM 'YY")
              : startDateTime.format("ddd D MMM 'YY");

            const assignedActivityGroups = _.filter(
              _.get(session, 'activityGroups', []),
              (activityGroup) => activityGroup.isAssignedTo,
            );

            return (
              <GridRow key={index}>
                <Col span={2}>
                  {onSelect && (
                    <Checkbox
                      checked={!isDisabled && session.isSelected}
                      disabled={isDisabled}
                      onClick={() => this._onSessionSelected(isDisabled, session, filteredSessionList)}
                    />
                  )}
                </Col>
                <Col span={5} className={isDisabled ? 'text-color-tertiary' : ''}>
                  <div>
                    <Text
                      style={{ whiteSpace: 'nowrap' }}
                      color={isDisabled && 'tertiary'}
                      weight='bold'
                      size='regular'
                    >
                      {dateLineText}
                      {session.isInHoliday && ' (Public holiday)'}
                    </Text>
                    <br />
                    <div className='flex-row'>
                      <Text size='large' color={isDisabled ? 'tertiary' : 'secondary'} style={{ whiteSpace: 'nowrap' }}>
                        {startDateTime.format('h:mmA') + ' – ' + endDateTime.format('h:mmA')}
                      </Text>
                    </div>
                  </div>
                </Col>
                <Col span={displayTimeSlotDescription ? 7 : 5} className={isDisabled ? 'text-color-tertiary' : ''}>
                  <Tooltip2
                    className='width-full'
                    content={
                      displayTimeSlotDescription
                        ? session.description
                          ? session.description
                          : '-'
                        : session.scheduleName
                        ? session.scheduleName
                        : '-'
                    }
                  >
                    <Text className='width-full block whitespace-nowrap overflow-hidden text-overflow-ellipsis'>
                      {displayTimeSlotDescription
                        ? session.description
                          ? session.description
                          : '-'
                        : session.scheduleName
                        ? session.scheduleName
                        : '-'}
                    </Text>
                  </Tooltip2>
                </Col>
                {displayTeamMemberCustomerRatios && (
                  <Col span={4}>
                    {session.customerRatio ? (
                      session.customerRatio.isCustomRatio ? (
                        <>
                          <Text className='mv-none mr-small'>Custom</Text>
                          <Popover2
                            content={
                              <RatioPopoverContent customerRatio={session.customerRatio} timezone={session?.timezone} />
                            }
                            position='bottom-right'
                            interactionKind='hover'
                            usePortal={false}
                          >
                            <Icon type='info-circle' />
                          </Popover2>
                        </>
                      ) : (
                        session.customerRatio?.teamMemberCustomerRatio ??
                        session.customerRatio?.customRatio[0]?.teamMemberCustomerRatio
                      )
                    ) : (
                      session.teamMemberCustomerRatio
                    )}
                  </Col>
                )}
                {displayActivityGroup && (
                  <Col span={4} className='text-align-left'>
                    {assignedActivityGroups.length !== 0 ? (
                      <Popover2
                        content={this._activityGroupPopoverContent(assignedActivityGroups)}
                        position='bottom-left'
                        usePortal={false}
                        interactionKind='hover'
                      >
                        <Tag className='tag-icon bg-tertiary flex-row align-center'>
                          <Icon type='calendar' style={{ fontSize: '14px' }} />
                          <Text size='regular'>
                            {assignedActivityGroups.length} {assignedActivityGroups.length > 1 ? 'groups' : 'group'}
                          </Text>
                        </Tag>
                      </Popover2>
                    ) : (
                      <Text color='secondary'>Not added</Text>
                    )}
                  </Col>
                )}
                {displayWarnings && (
                  <Col span={1}>
                    {hasWarnings &&
                      this._renderSessionWarning(session, statusCannotAdd, isCapacityFull, hasServiceAgreement)}
                    {!hasWarnings && hasServiceAgreement && (
                      <Tooltip2 content='The customer has a service agreement for the dates of the sessions.'>
                        <Icon type='check-circle' theme='filled' className='mr-small text-color-green' />
                      </Tooltip2>
                    )}
                  </Col>
                )}
                {session.isSelected && isEditAddCustomerToSessions && (
                  <Col span={2} className='text-align-right'>
                    {!isDisabled && (
                      <Popover2
                        position='bottom-right'
                        content={
                          <ActionMenu>
                            <ActionMenuItem
                              text='Edit booking time/ratios'
                              onClick={() => this._handleMenuAction(session)}
                            />
                            <ActionMenuItem
                              text='Edit activity groups'
                              onClick={() =>
                                this._handleMenuAction(session, ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SESSION)
                              }
                            />
                          </ActionMenu>
                        }
                      >
                        <Text className='cursor-pointer p-small text-color-blue-action'>Edit</Text>
                      </Popover2>
                    )}
                  </Col>
                )}
              </GridRow>
            );
          })
        )}
      </div>
    );
  };
}

const mapState = (state: IRootState) => ({
  selectedService: state.servicesStore.selectedService,
  groupServiceServiceAgreements: state.groupServiceStore.groupServiceServiceAgreements,
});

export default connect(mapState, null)(GroupServiceSessionsPanel);
