import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Avatar, Icon } from 'antd';
import { getBookingTheme } from 'common-components/roster-control/common/roster-utilities';
import { IRosterBooking } from 'common-components/roster-control/interfaces/roster-interfaces';
import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';
import {
  getTimelineCardWidth,
  getTimelineOffset,
} from 'common-components/roster-control/roster-daily/timeline-pane/common/card-width-utils';
import CustomerBookingPopover from 'common-components/roster-control/roster-daily/timeline-pane/customers/CustomerBookingPopover';
import { BookingTypeIcon, StatusTag } from 'common-components/tags';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { FaUserTimes } from 'react-icons/fa';
import CommonUtils from 'utilities/common-utils';
import { BookingStatus, BookingType, ServiceType, ShiftSlotStatus } from 'utilities/enum-utils';

const { TIMELINE_CARD_HEIGHT, HOUR_BLOCK_WIDTH, TIMELINE_ROW_HEIGHT } = ROSTER_DAILY_CONFIG;

interface CustomerBookingCardProps {
  booking: IRosterBooking;
  rowIndex?: number;
  refreshData?: () => void;
}

/* This component takes a start/end time, and calculates the position/width within a timeline. */
export function CustomerBookingCard({ booking, rowIndex, refreshData }: CustomerBookingCardProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [canClosePopover, setCanClosePopover] = useState<boolean>(true);

  const { startDateTime, endDateTime, status: bookingStatus, serviceName, scheduleName } = booking;

  const mStartDate = moment(startDateTime);
  const mEndDate = moment(endDateTime);

  const startTimeDisplay = mStartDate.format('h:mm a');
  const endTimeDisplay = mEndDate.format('h:mm a');

  // Card properties
  const cardWidth = useMemo(() => getTimelineCardWidth(startDateTime, endDateTime), [startDateTime, endDateTime]);
  const topOffset = rowIndex === 0 ? 4 : rowIndex * TIMELINE_ROW_HEIGHT + 4;
  const leftOffset = useMemo(() => getTimelineOffset(startDateTime), [startDateTime]);

  // Determines whether this card is large enough to display text.
  const isSmallCard = cardWidth < HOUR_BLOCK_WIDTH / 2.6;

  const { bgColor, borderColor } = getBookingTheme(bookingStatus);

  const openClosePopover = (nextOpenState) => {
    if (canClosePopover) {
      setIsOpen(nextOpenState);
    }
  };

  const refreshAndCloseData = () => {
    setIsOpen(false);
    refreshData();
  };

  const isCancelled = !!_.find(
    [
      BookingStatus.BUSINESS_CANCELLED,
      BookingStatus.CUSTOMER_CANCELLED,
      BookingStatus.CUSTOMER_CANCELLED_WITH_FEE,
      BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE,
    ],
    (status) => status === booking.status,
  );

  return (
    <div
      className={`position-absolute rounded flex-column inline-block rounded-big cursor-pointer bordered anim-fade-in ${bgColor} ${borderColor}`}
      style={{
        width: `${cardWidth}px`,
        top: `${topOffset}px`,
        left: `${leftOffset}px`,
        height: `${TIMELINE_CARD_HEIGHT}px`,
        zIndex: 10,
      }}
    >
      {/* Content for timeline item */}
      <Popover2
        content={
          <CustomerBookingPopover
            booking={booking}
            setCanClosePopover={setCanClosePopover}
            refreshAndCloseData={refreshAndCloseData}
          />
        }
        position="right-bottom"
        key={booking.bookingId}
        isOpen={isOpen}
        onInteraction={(nextOpenState) => openClosePopover(nextOpenState)}
      >
        <div
          className={`${isSmallCard ? 'pv-x-small ph-x2-small' : 'ph-small pv-x-small'} overflow-hidden`}
          style={{ width: `${cardWidth}px`, height: `${TIMELINE_CARD_HEIGHT}px` }}
        >
          {isSmallCard && (
            <div className="text-align-center">
              <Text size="x2-small" color="secondary">
                {/*1:4*/}
              </Text>
            </div>
          )}

          {!isSmallCard && (
            <div className={'flex-row justify-start align-center '}>
              <StatusTag status={bookingStatus} size={'small'} isFixedWidth={true} width={cardWidth - 24} />
              {booking.serviceType === ServiceType.INDIVIDUAL && (
                <div className={'ml-small'}>
                  <Tooltip2
                    content={
                      <Text color={'white'}>
                        {booking.workerId
                          ? booking.workerFirstName + ' ' + booking.workerLastName
                          : booking.shiftSlotStatus === ShiftSlotStatus.PUBLISHING
                          ? 'Published'
                          : 'No team member assigned'}
                      </Text>
                    }
                    position={'bottom-right'}
                  >
                    <div className={'flex-row align-center'}>
                      {booking.workerId ? (
                        <>
                          <Avatar
                            size={20}
                            className={'text-white'}
                            style={{
                              backgroundColor: CommonUtils.getBackgroundColor(
                                booking.workerFirstName + booking.workerLastName,
                              ),
                            }}
                            shape="circle"
                          >
                            {booking.workerFirstName && booking.workerFirstName[0].toUpperCase()}{' '}
                            {booking.workerLastName && booking.workerLastName[0].toUpperCase()}
                          </Avatar>
                          <Icon
                            type={booking.shiftSlotStatus === ShiftSlotStatus.PENDING ? 'clock-circle' : 'check-circle'}
                            className={`${
                              booking.shiftSlotStatus === ShiftSlotStatus.PENDING
                                ? 'text-color-warning-orange'
                                : 'text-color-blue-lighter'
                            } text-size-small`}
                            theme={'filled'}
                            style={{ marginTop: '1px', marginLeft: '1px' }}
                          />
                        </>
                      ) : booking.shiftSlotStatus === ShiftSlotStatus.PUBLISHING ? (
                        <Icon type="wifi" className="text-color-violet-light mr-x-small text-size-regular" />
                      ) : !isCancelled ? (
                        <FaUserTimes className={'text-color-red text-size-large'} />
                      ) : (
                        <></>
                      )}
                    </div>
                  </Tooltip2>
                </div>
              )}
            </div>
          )}

          {!isSmallCard && (
            <div className="pt-x2-small flex-column line-height-100">
              <div className="text-overflow-ellipsis overflow-hidden whitespace-nowrap">
                <Text lineHeight={100} size="small" className="whitespace-nowrap">
                  <b>{startTimeDisplay}</b> - {endTimeDisplay}
                </Text>
              </div>
              <div className={'flex-row align-center'}>
                <BookingTypeIcon
                  type={
                    booking.bookingType === BookingType.ACTIVITY_RECORD
                      ? BookingType.ACTIVITY_RECORD
                      : booking.serviceType
                  }
                  className={'mr-x-small'}
                />
                <div className="text-overflow-ellipsis overflow-hidden whitespace-nowrap flex-row align-center">
                  <Text lineHeight={100} size="regular" className="whitespace-nowrap">
                    {booking.serviceType === ServiceType.GROUP && scheduleName ? scheduleName : serviceName}
                  </Text>
                </div>
              </div>
            </div>
          )}
        </div>
      </Popover2>
    </div>
  );
}
