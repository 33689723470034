import { Row } from 'antd';
import React, { useState } from 'react';

import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { ISession } from 'interfaces/session-interfaces';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';

interface ICancelLeaveModalProps {
  isOpen: boolean;
  onClose: (targetFlag, refreshShiftSlots?: boolean) => void;
  shiftSlot?: IShiftSlot;
  session: ISession;
}

const CancelLeaveModal = (props: ICancelLeaveModalProps) => {
  const dispatch = useDispatch<IRootDispatch>();
  const { isOpen, onClose, session, shiftSlot } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { serviceId, serviceDateTimeId, timezone } = session;
  const supportWorkerAttendanceId = shiftSlot?.supportWorkerAttendanceId;

  const handleClose = () => {
    onClose({ targetFlag: 'isCancelLeaveOpen' }, true);
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    await dispatch.teamStore.doCancelLeaveAvalibility({
      serviceId,
      serviceDateTimeId,
      supportWorkerAttendanceId,
    });
    setIsSubmitting(false);
    handleClose();
  };

  return (
    <ActionModal
      isOpen={isOpen}
      title="Cancel Leave"
      showCloseButton={true}
      onClose={handleClose}
      width="large"
      canCloseOutside={!isSubmitting}
    >
      <Row>
        <Text size="large">
          Do you want to cancel <b>{shiftSlot?.firstName}&apos;s</b> leave from{' '}
          <b>{moment.tz(shiftSlot?.shiftStartDateTime, timezone).format('h:mm A, DD MMMM YYYY')}</b> until{' '}
          <b>{moment.tz(shiftSlot?.shiftEndDateTime, timezone).format('h:mm A, DD MMMM YYYY')}</b>?
        </Text>
      </Row>
      <Row className="mt-medium">
        <Text size="large">You&apos;ll be able to assign {shiftSlot?.firstName} to shifts again.</Text>
      </Row>

      <div className="flex justify-end mt-x3-large">
        <SecondaryButton size="large" className="mr-medium" onClick={handleClose}>
          Cancel
        </SecondaryButton>
        <PrimaryButton size="large" onClick={handleConfirm} loading={isSubmitting}>
          Confirm
        </PrimaryButton>
      </div>
    </ActionModal>
  );
};

export default React.memo(CancelLeaveModal);
