import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Tag = forwardRef(function Tag(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.014 12.313a2.25 2.25 0 0 1 0-.626c.044-.31.194-.638.58-1.12.394-.492.971-1.072 1.802-1.902.984-.984 1.362-1.35 1.81-1.573a3.25 3.25 0 0 1 .42-.174c.475-.159 1.001-.168 2.393-.168h4.98c1.436 0 2.437.002 3.193.103.734.099 1.122.28 1.399.556.277.277.457.665.556 1.4.101.755.103 1.756.103 3.191 0 1.435-.002 2.437-.103 3.192-.099.734-.28 1.122-.556 1.399-.277.277-.665.457-1.4.556-.755.101-1.756.103-3.191.103h-4.981c-1.392 0-1.918-.009-2.393-.168a3.24 3.24 0 0 1-.42-.174c-.448-.223-.826-.589-1.81-1.573-.83-.83-1.408-1.41-1.803-1.903-.385-.481-.535-.81-.579-1.119Zm-1.485-.835a3.75 3.75 0 0 0 0 1.044c.096.685.425 1.262.893 1.847.452.566 1.09 1.204 1.881 1.995l.032.032.09.09c.862.863 1.419 1.419 2.112 1.765.198.099.403.183.613.254.735.245 1.522.245 2.74.245h5.165c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.601-.602.86-1.36.981-2.26.117-.867.117-1.97.117-3.337v-.11c0-1.367 0-2.47-.117-3.337-.12-.9-.38-1.658-.981-2.26-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117H9.89c-1.22 0-2.006 0-2.741.245a4.75 4.75 0 0 0-.613.254c-.693.346-1.25.903-2.111 1.765l-.091.09-.032.032c-.79.791-1.429 1.429-1.881 1.995-.468.585-.797 1.162-.893 1.847ZM8.75 12a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM10 9.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
