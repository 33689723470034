import { useCallback } from 'react';

import { validatePhoneNumber } from '../../utilities/validate-phone-number';
import type { CountryCode, ValidatePhoneNumberFunction } from './types';

export function useValidatePhoneNumber(countryCode: CountryCode): ValidatePhoneNumberFunction {
  return useCallback(
    (
      phoneNumber: string | null | undefined,
      opts?: {
        callback?: (err?: unknown) => void;
        countryCode?: CountryCode;
        required?: boolean;
      },
    ) => {
      return validatePhoneNumber(phoneNumber, {
        ...opts,
        countryCode: opts?.countryCode ?? countryCode,
      });
    },
    [countryCode],
  );
}
