import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Avatar, Icon, notification } from 'antd';
import _ from 'lodash';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IBlockAccessModalProps {
  isOpen: any;
  onClose: any;
  worker: any;
  doBlockTeamMember: typeof dispatch.teamStore.doBlockTeamMember;
}

interface IBlockAccessModalState {
  title: any;
  isDone: boolean;
  isSaving: boolean;
}

class BlockAccessModal extends Component<IBlockAccessModalProps, IBlockAccessModalState> {
  state = { title: 'Block Access', isDone: false, isSaving: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Block Access', isDone: false });
    this.props.onClose();
  };

  private _saveAction = async () => {
    try {
      this.setState({ isSaving: true });
      await this.props.doBlockTeamMember({ supportWorkerId: this.props.worker.supportWorkerId });
      this.setState({
        title: (
          <>
            User successfully blocked
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        isDone: true,
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isSaving: false });
  };

  render() {
    const { isOpen, worker } = this.props;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {!_.isEmpty(worker) &&
          (this.state.isDone ? (
            <>
              <div className="mv-medium ml-x4-large anim-slide-left">
                <div>
                  <SubTitle>User</SubTitle>
                  <div className={'flex-row align-center'}>
                    <Avatar shape={'square'} src={worker.attachmentUrl} className={'mr-medium'} />
                    <Text>{worker.firstName + ' ' + worker.lastName}</Text>
                    <div className={'ml-medium'}>
                      <Text size={'small'}>BLOCKED</Text>
                    </div>
                  </div>
                </div>

                <ActionModalFooter>
                  <PrimaryButton size="large" onClick={this._onCloseModal}>
                    Done
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ) : (
            <>
              <div className="mv-medium anim-slide-left">
                <div>
                  <Paragraph>
                    You are choosing to block access for <b>{worker.firstName + ' ' + worker.lastName}</b>. This user
                    will no longer be able to login to both the business app or the workspace.
                  </Paragraph>
                  <Paragraph>
                    The user will be removed from any upcoming booking or session they are assigned to and can be
                    unblocked at anytime.
                  </Paragraph>
                </div>

                <ActionModalFooter>
                  <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" loading={this.state.isSaving} onClick={this._saveAction}>
                    Block Access
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ))}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doBlockTeamMember: dispatch.teamStore.doBlockTeamMember,
});

export default connect(null, mapDispatch)(BlockAccessModal);
