import { Warning } from 'common-components/alerts';
import { HyperlinkButton } from 'common-components/buttons';
import { Paragraph, Text } from 'common-components/typography';
import React from 'react';
import { CANCEL_SUBSCRIPTION_LINKS } from 'views/account-management/subscription-management/utils/constants';

const WarningSection: React.FC = () => {
  return (
    <Warning
      color="red"
      className="mt-x-large bg-red-lightest"
      content={
        <>
          <Text weight="bold">Warning</Text>
          <Paragraph>
            By cancelling your subscription, all team members in your organisation will lose access to GoodHuman at the
            end of your billing cycle. We recommend you&nbsp;
            <HyperlinkButton
              color="blue-action"
              onClick={() => window.open(CANCEL_SUBSCRIPTION_LINKS.EXPORT_DATA_LINK, '_blank')}
            >
              export your data
            </HyperlinkButton>
            &nbsp;before cancelling.
          </Paragraph>
        </>
      }
    />
  );
};

export default WarningSection;
