import { Spinner } from '@blueprintjs/core';
import { Stack, Box, Button, Inline, Text, Icons } from '@goodhuman-me/components';
import { notification } from 'antd';
import { Modal } from 'design-components';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { AvailabilityUnavailabilityRequestStatus as UnavailabilityStatus } from 'utilities/enum-utils';
import DeclineApproveRequestModal from '../../../availability/components/DeclineApproveRequestModal';
import { MemberAvailabilities, UnavailableTime } from '../../availability';
import { UnavailabilityCard } from './unavailability-card';

type UpcomingUnavailabilityProps = {
  unavailableTimes: MemberAvailabilities['unavailableTimes'];
  doCancelRequest: typeof dispatch.teamStore.doCancelRequest;
  doGetTimeAvailability: typeof dispatch.teamStore.doGetTimeAvailability;
};

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetTimeAvailability: dispatch.teamStore.doGetTimeAvailability,
  doCancelRequest: dispatch.teamStore.doCancelRequest,
});

const AllUnavailabilityModal = ({
  unavailabilities,
  isOpen,
  onClose,
  cancelUnavailability,
  isLoading,
}: {
  unavailabilities: UnavailableTime[];
  isOpen: boolean;
  onClose: () => void;
  cancelUnavailability: (unavailability: UnavailableTime) => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation('', { keyPrefix: 'teamMember.workDetails.scheduledUnavailability' });
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedUnavailability, setSelectedItem] = useState<UnavailableTime>(null);
  return (
    <>
      {showCancelModal && (
        <DeclineApproveRequestModal
          title={t('cancelUnavailability.title')}
          content={<Text>{t('cancelUnavailability.content')}</Text>}
          actionColor="red"
          primaryButtonText={t('cancelUnavailability.primaryButton')}
          onConfirm={() => {
            cancelUnavailability(selectedUnavailability);
            setShowCancelModal(false);
          }}
          onClose={() => {
            setShowCancelModal(false);
            setSelectedItem(null);
          }}
        />
      )}
      <Modal
        maxHeight={600}
        maxWidth={500}
        header={t('upcoming.allModalTitle')}
        isOpen={isOpen || showCancelModal}
        onClose={() => (showCancelModal ? null : onClose())}
        footer={
          <Inline justifyContent="end" paddingRight="$small">
            <Button emphasis="filled" onClick={onClose}>
              {t('upcoming.allModalPrimaryButton')}
            </Button>
          </Inline>
        }
      >
        {isLoading ? (
          <Inline justifyContent="center" paddingTop="$medium">
            <Spinner size={80} />
          </Inline>
        ) : (
          <Stack gap="$small">
            {unavailabilities.map((unavailableTime, index) => {
              return (
                <UnavailabilityCard
                  key={index}
                  unavailableTime={unavailableTime}
                  onCancel={(item) => {
                    setShowCancelModal(true);
                    setSelectedItem(item);
                  }}
                />
              );
            })}

            {!unavailabilities.length && (
              <Text size="small" color="$bodyDark1">
                {t('upcoming.noUnavailability')}
              </Text>
            )}
          </Stack>
        )}
      </Modal>
    </>
  );
};

const unavailabilityStatusPriorityOrder = [
  UnavailabilityStatus.PENDING_APPROVAL,
  UnavailabilityStatus.APPROVED,
  UnavailabilityStatus.DECLINED,
  UnavailabilityStatus.REQUEST_CANCELLATION,
  UnavailabilityStatus.COMPLETED,
  UnavailabilityStatus.CANCELLED,
];

export const UpcomingUnavailability = connect(
  null,
  mapDispatch,
)(({ unavailableTimes, doCancelRequest, doGetTimeAvailability }: UpcomingUnavailabilityProps) => {
  const { t } = useTranslation('', { keyPrefix: 'teamMember.workDetails.scheduledUnavailability' });

  const upcomingStatuses = [UnavailabilityStatus.PENDING_APPROVAL, UnavailabilityStatus.APPROVED];

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showAllUnavailabilityModal, setShowAllUnavailabilityModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<UnavailableTime>(null);
  const [isLoading, setLoading] = useState(false);

  const sortedUnavailableTimes = unavailableTimes.sort(
    (a, b) =>
      // first sort by unavailabilityStatus
      unavailabilityStatusPriorityOrder.indexOf(a.status as UnavailabilityStatus) -
        unavailabilityStatusPriorityOrder.indexOf(b.status as UnavailabilityStatus) ||
      // if they're the same, sort by startDate/Time
      moment(a.startTime ?? a.startDate).valueOf() - moment(b.startTime ?? b.startDate).valueOf(),
  );

  const visibleUnavailability = sortedUnavailableTimes
    .filter((time) => upcomingStatuses.includes(time.status as UnavailabilityStatus))
    .slice(0, 2);
  const numberNotVisible = sortedUnavailableTimes.length - visibleUnavailability.length;

  const cancelUnavailability = async (unavailability: UnavailableTime) => {
    setLoading(true);

    try {
      const result = await doCancelRequest({
        supportWorkerAvailabilityRequestId: unavailability.supportWorkerAvailabilityRequestId,
      });
      notification.success({
        message: t('cancelUnavailability.successMessage'),
        description: t('cancelUnavailability.successDescription'),
      });

      if (result) {
        doGetTimeAvailability({});
      }
    } catch (e) {
      notification.error({ message: t('cancelUnavailability.errorMessage') });
    } finally {
      setShowCancelModal(false);
      setSelectedItem(null);
      setLoading(false);
    }
  };

  return (
    <>
      {showCancelModal && (
        <DeclineApproveRequestModal
          title={t('cancelUnavailability.title')}
          content={<Text>{t('cancelUnavailability.content')}</Text>}
          actionColor="red"
          primaryButtonText={t('cancelUnavailability.primaryButton')}
          onConfirm={() => cancelUnavailability(selectedItem)}
          onClose={() => {
            setShowCancelModal(false);
            setSelectedItem(null);
          }}
        />
      )}

      <AllUnavailabilityModal
        unavailabilities={sortedUnavailableTimes}
        isOpen={showAllUnavailabilityModal}
        onClose={() => setShowAllUnavailabilityModal(false)}
        cancelUnavailability={cancelUnavailability}
        isLoading={isLoading}
      />

      <Stack gap="$small">
        <Inline justifyContent="space-between">
          <Text fontWeight="600" size="small">
            {t('upcoming.title')}
          </Text>
          {numberNotVisible > 0 && <Text size="small" color="$bodyDark1">{`+ ${numberNotVisible} more`}</Text>}
        </Inline>
        {isLoading ? (
          <Inline justifyContent="center" paddingTop="$medium">
            <Spinner size={80} />
          </Inline>
        ) : (
          <Stack gap="$small">
            {visibleUnavailability.map((unavailableTime, index) => {
              return (
                <UnavailabilityCard
                  key={index}
                  unavailableTime={unavailableTime}
                  onCancel={(item) => {
                    setShowCancelModal(true);
                    setSelectedItem(item);
                  }}
                />
              );
            })}

            {!visibleUnavailability.length && sortedUnavailableTimes.length > 0 && (
              <Text size="small" color="$bodyDark1">
                {t('upcoming.noUpcoming')}
              </Text>
            )}

            {sortedUnavailableTimes.length > 0 && (
              <Box>
                <Button onClick={() => setShowAllUnavailabilityModal(true)} emphasis="quiet" marginLeft="-1rem">
                  {t('upcoming.viewAllButton')}
                  <Icons.ChevronRight width="1em"></Icons.ChevronRight>
                </Button>
              </Box>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
});
