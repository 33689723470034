/* eslint-disable */
import firebaseApp from 'stores/firebase-app';
import apiClient from 'utilities/api-client';
import {
  getAuth,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithCustomToken,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';
import { TeamStatus, UserPermissionRole } from 'src/utilities/enum-utils';

type PermissionRole = {
  serviceId: string | null;
  permissionRole: UserPermissionRole;
  serviceDepartmentId: string | null;
};

type PortalUserPermissions = {
  hasPortalAccess: boolean;
  hasWorkerAppAccess: boolean;
  permissionRoles: PermissionRole[];
};

export type PortalUser = {
  email: string;
  hasPortalInstruction: boolean;
  roles: string[];
  userId: string;
  supportWorkerId: string;
  serviceProviderId: string;
  firstName: string;
  lastName: string;
  displayName?: string | null;
  attachmentUrl: string;
  timezone: string;
  displayTimezoneSetting?: { autoDetect: boolean };
  serviceProviderName?: string;
  badgeCount: number;
  status: TeamStatus;
  permissions: PortalUserPermissions;
  hasPlanManagement: boolean;
  integrationConfig?: {
    ndisExport?: { style: string };
    financeExport?: { style: string };
    timesheetExport?: { style: string };
  };
  // Appcues
  createdOn?: string;
  isFirstLogin?: boolean;
  enabledUsers?: number;
  companyStatus?: string | null;
  paymentType?: string | null;
  hasSupportCoordinatorCustomer: boolean;
  customerViewsConfig: {
    displayAssignedCustomersView: boolean;
    displaySupportCoordinationCustomersView: boolean;
  };
};

export type AuthStoreState = {
  currentUser: null | unknown; // looks like we don't use this anymore?
  isAppLoaded: boolean;
  serviceProviderId: string | null;
  portalUser: PortalUser | null;
  portalUserList: PortalUser[] | null;
  invitation: null | unknown;
  isUserBlocked: boolean;
  isSignOut: boolean;
  isForgetPassword: boolean;
};

const initialState: AuthStoreState = {
  currentUser: null,
  isAppLoaded: false,
  serviceProviderId: null,
  portalUser: null,
  portalUserList: null,
  invitation: null,
  isUserBlocked: false,
  isSignOut: false,
  isForgetPassword: false,
};

const authStore = {
  state: initialState,
  reducers: {
    setCurrentUser: (state, payload) => ({ ...state, currentUser: payload }),
    setAppLoaded: (state, payload) => ({ ...state, isAppLoaded: payload }),
    setServiceProviderId: (state: AuthStoreState, payload: string) => {
      window.sessionStorage.setItem('serviceProviderId', payload);
      return { ...state, serviceProviderId: payload };
    },
    setPortalUserList: (state, payload: PortalUser[] | null) => ({ ...state, portalUserList: payload }),
    setCurrentPortalUser: (state, payload: PortalUser | null) => ({ ...state, portalUser: payload }),
    setInvitation: (state, payload) => ({ ...state, invitation: payload }),
    setIsUserBlocked: (state, payload) => ({ ...state, isUserBlocked: payload }),
    setIsSignOut: (state, payload) => ({ ...state, isSignOut: payload }),
    setIsForgetPassword: (state, payload) => ({ ...state, isForgetPassword: payload }),
  },
  effects: (dispatch) => ({
    async doSignInUsingToken(payload, state) {
      const { token } = payload;
      try {
        const results = await signInWithCustomToken(firebaseApp.auth, token);

        const { displayName, email, emailVerified, isAnonymous, metadata, phoneNumber, photoURL, uid } = results.user;

        return {
          displayName,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          phoneNumber,
          photoURL,
          uid,
        };
      } catch (e) {
        console.log(e);
        throw Error('invalid token');
      }
    },

    async doRegister(payload, state) {
      const { email, password, firstName, lastName, token, serviceProviderId, mobile } = payload;

      try {
        const results = await createUserWithEmailAndPassword(firebaseApp.auth, email, password);

        const {
          displayName,
          emailVerified,
          isAnonymous,
          metadata,
          phoneNumber,
          photoURL,
          // providerData,
          uid,
        } = results.user;

        const mobileCountryCode = 'AU';

        const dbUser = {
          serviceProviderId,
          firstName,
          lastName,
          email,
          token,
          // mobile: finalMobile,
          mobile,
          mobileCountryCode,
        };

        const result = await apiClient.post('/api/portal/service-provider/portal-user/new', dbUser);

        const currentUser = {
          displayName: `${firstName} ${lastName}`,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          phoneNumber,
          photoURL,
          uid,
        };

        return currentUser;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchPortalUserList(payload, state) {
      try {
        const result = await apiClient.get(`/api/portal/service-provider/portal-user/list`);
        dispatch.authStore.setPortalUserList(result.data);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doCheckValidateToken(payload, state) {
      try {
        const { token } = payload;
        const result = await apiClient.get(`/verify/service-provider/${token}`);
        dispatch.authStore.setInvitation(result.data);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doCheckActionToken(payload, state) {
      try {
        const { code } = payload;
        return await checkActionCode(firebaseApp.auth, code);
      } catch (e) {
        throw e;
      }
    },

    async doSetNewPassword(payload, state) {
      try {
        const { code, password } = payload;
        return await confirmPasswordReset(firebaseApp.auth, code, password);
      } catch (e) {
        throw e;
      }
    },

    async doReAuthenticate(payload, state) {
      try {
        const credential = EmailAuthProvider.credential(firebaseApp.auth.currentUser.email, payload.password);

        await reauthenticateWithCredential(getAuth().currentUser, credential);
      } catch (e) {
        throw e;
      }
    },

    async doGetPortalUserDetail(payload, state) {
      try {
        const endpoint = `api/portal/service-provider/profile`;
        const result = await apiClient.get(endpoint);
        dispatch.authStore.setCurrentPortalUser(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doUpdatePortalUserDetail(payload, state) {
      try {
        const endpoint = `api/portal/service-provider/profile`;
        const result = await apiClient.put(endpoint, payload);
        dispatch.authStore.setCurrentPortalUser(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doUpdatePortalUserSettingTimezone(payload, rootState) {
      try {
        const endpoint = `api/portal/user/timezone/preference`;
        const result = await apiClient.put(endpoint, payload);
        dispatch.authStore.setCurrentPortalUser({
          ...rootState.authStore.portalUser,
          ...result.data,
          timezone: payload.timezone,
        });
      } catch (e) {
        throw e;
      }
    },

    async doUpdatePortalUserTimezone(payload, rootState) {
      try {
        const endpoint = `api/portal/user/timezone`;
        const result = await apiClient.put(endpoint, payload);
        dispatch.authStore.setCurrentPortalUser({ ...rootState.authStore.portalUser, timezone: payload.timezone });
      } catch (e) {
        throw e;
      }
    },

    async doUpdatePortalUserAvatar(payload, state) {
      try {
        const endpoint = `api/portal/service-provider/avatar`;
        const result = await apiClient.put(endpoint, payload);

        dispatch.authStore.setCurrentPortalUser(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doSignOutUsers() {
      window.sessionStorage.removeItem('serviceProviderId');
      await firebaseApp.auth.signOut();
    },
  }),
};

export default authStore;
