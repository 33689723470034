import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';
import { ROSTER_WEEKLY_CONFIG } from 'common-components/roster-control/roster-weekly/roster-weekly-config';

const { CONTENT_PANEL_WIDTH, GROUP_ROW_HEIGHT } = ROSTER_WEEKLY_CONFIG;

export function GroupColumn({ setOpen, isOpen, text }) {
  return (
    <div
      className="flex-row bg-tertiary line-height-100 ph-x-large pv-medium cursor-pointer align-center bordered-right bordered-bottom"
      style={{
        minWidth: `${CONTENT_PANEL_WIDTH}px`,
        maxWidth: `${CONTENT_PANEL_WIDTH}px`,
        height: `${GROUP_ROW_HEIGHT}px`,
      }}
      onClick={() => setOpen(!isOpen)}
    >
      <Icon type={`${isOpen ? 'down' : 'up'}`} className="text-color-secondary mr-small" />
      <div className="flex-column line-height-100">
        <Text size="x-large" lineHeight={100}>
          {text}
        </Text>
      </div>
    </div>
  );
}
