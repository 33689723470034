import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@goodhuman-me/components';
import { Text } from 'common-components/typography';

type Props = {
  serviceDateConflicts: { serviceName: string }[];
  serviceDatesFlagEnabled: boolean;
  overrideServiceDateConflictError: boolean;
};

const ServiceDateConflictWarnings = ({
  serviceDateConflicts,
  serviceDatesFlagEnabled,
  overrideServiceDateConflictError,
}: Props): JSX.Element | null => {
  const { t } = useTranslation('', { keyPrefix: 'serviceAgreements.warnings' });
  const hasServiceDateConflictErrors = Boolean(serviceDateConflicts?.length);

  if (!serviceDatesFlagEnabled || !hasServiceDateConflictErrors || overrideServiceDateConflictError) return null;

  let warningText = '';

  if (serviceDateConflicts.length === 1) {
    warningText = t('serviceDateConflicts.single', {
      serviceName: serviceDateConflicts[0].serviceName,
    });
  } else {
    warningText = t('serviceDateConflicts.multiple', {
      numberOfConflicts: serviceDateConflicts.length,
    });
  }

  return (
    <Box paddingX={0} paddingTop="9px" paddingBottom="15px">
      <Text color="red-dark">{warningText}</Text>
    </Box>
  );
};

export default ServiceDateConflictWarnings;
