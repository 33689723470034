import React, { forwardRef } from 'react';
import { Select } from 'antd';
import { mergeRefs } from '@react-aria/utils';
import { useField } from '@react-aria/label';

import { Label, Stack } from '.';

import type { AriaFieldProps } from '@react-aria/label';
import type { PropsWithChildren, RefCallback } from 'react';
import type { SelectProps } from 'antd/es/select';
import type { SelectValue } from 'antd/lib/select';

export type SelectFieldProps = PropsWithChildren<
  AriaFieldProps & SelectProps & { inputRef?: RefCallback<Select<SelectValue>>; name: string }
>;

/**
 * @name
 * SelectField
 *
 * @description
 * The <select> HTML element represents a control that provides a menu of options.
 *
 * @example
 *
 */
export const SelectField = forwardRef<Select<SelectValue>, SelectFieldProps>(function SelectField(props, ref) {
  let { children, disabled, errorMessage, inputRef, label, onChange, value } = props;
  let { errorMessageProps, fieldProps, labelProps } = useField(props);
  let mergedRefs = mergeRefs(inputRef, ref);

  return (
    <Stack gap="sm">
      <Label {...labelProps}>{label}</Label>
      <Select ref={mergedRefs} {...fieldProps} value={value} onChange={onChange} disabled={disabled}>
        {children}
      </Select>

      {errorMessage && (
        <div {...errorMessageProps} style={{ color: '#C13232', fontSize: 14 }}>
          {errorMessage}
        </div>
      )}
    </Stack>
  );
});
