import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const TextStrikethrough = forwardRef(function TextStrikethrough(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 8A4.75 4.75 0 0 1 11 3.25h2A4.75 4.75 0 0 1 17.75 8a.75.75 0 0 1-1.5 0A3.25 3.25 0 0 0 13 4.75h-2a3.25 3.25 0 0 0-3.148 4.063.75.75 0 1 1-1.453.374A4.757 4.757 0 0 1 6.25 8Zm-4 4a.75.75 0 0 1 .75-.75h18a.75.75 0 0 1 0 1.5h-4.536a4.75 4.75 0 0 1-3.464 8h-2A4.75 4.75 0 0 1 6.25 16a.75.75 0 0 1 1.5 0A3.25 3.25 0 0 0 11 19.25h2a3.25 3.25 0 0 0 0-6.5H3a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
