import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Watch = forwardRef(function Watch(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.96 1.25h.08c.656 0 1.208 0 1.658.05.478.052.918.167 1.317.447.079.055.154.114.227.177.368.32.586.718.753 1.169.158.425.292.96.45 1.596l.01.04.273 1.09a.84.84 0 0 1 .01.048A7.737 7.737 0 0 1 19.75 12a7.737 7.737 0 0 1-3.012 6.134.837.837 0 0 1-.01.048l-.273 1.09-.01.039c-.159.635-.292 1.171-.45 1.596-.167.45-.385.85-.753 1.17a2.72 2.72 0 0 1-.227.176c-.4.28-.84.395-1.317.447-.45.05-1.002.05-1.658.05h-.08c-.656 0-1.208 0-1.658-.05-.478-.052-.917-.167-1.317-.447a2.727 2.727 0 0 1-.227-.177c-.368-.32-.586-.718-.753-1.169-.158-.425-.292-.96-.45-1.596l-.01-.04-.273-1.09a.767.767 0 0 1-.01-.047A7.737 7.737 0 0 1 4.25 12a7.737 7.737 0 0 1 3.012-6.133.763.763 0 0 1 .01-.049l.273-1.09.01-.039c.158-.635.292-1.17.45-1.596.167-.45.385-.85.753-1.17a2.75 2.75 0 0 1 .227-.176c.4-.28.84-.395 1.317-.447.45-.05 1.002-.05 1.658-.05ZM9.067 19.176c.136.539.236.918.344 1.21.12.322.223.464.33.558.034.028.068.055.104.08.116.082.28.148.621.185.359.04.828.041 1.534.041.706 0 1.175-.001 1.534-.04.341-.038.505-.104.621-.186.036-.025.07-.052.103-.08.108-.094.211-.236.33-.558.109-.292.21-.671.345-1.21A7.728 7.728 0 0 1 12 19.75a7.728 7.728 0 0 1-2.933-.574Zm5.521-15.562c.109.292.21.671.345 1.21A7.728 7.728 0 0 0 12 4.25a7.727 7.727 0 0 0-2.933.574c.136-.539.236-.918.344-1.21.12-.322.223-.464.33-.558.034-.028.068-.055.104-.08.116-.082.28-.148.621-.185.359-.04.828-.041 1.534-.041.706 0 1.175.001 1.534.04.341.038.505.104.621.186.036.025.07.052.103.08.108.094.211.236.33.558ZM5.75 12a6.25 6.25 0 1 1 12.5 0 6.25 6.25 0 0 1-12.5 0Zm7-2a.75.75 0 0 0-1.5 0v2c0 .25.125.485.334.624l1.5 1a.75.75 0 0 0 .832-1.248l-1.166-.777V10Z"
        fill="currentColor"
      />
    </Icon>
  );
});
