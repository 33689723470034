import React from 'react';
import { notification } from 'antd';
import { Text } from '@good/components';
import { ArgsProps as NotificationProps, NotificationPlacement } from 'antd/lib/notification';
import { v4 as uuid } from 'uuid';
import { Cross, CrossCircle, CheckCircle, Info as InfoCircle } from '@good/icons';

// TODO: migrate this entire API to design system Toast when available

type INotificationProps = {
  classNameIcon?: string;
} & NotificationProps;

const notificationProps = ({ ...args }: INotificationProps) => ({
  placement: 'bottomRight' as NotificationPlacement,
  ...args,
  message: (
    <div style={{ marginLeft: -15 }}>
      <Text className="text-weight-bolder">{args.message}</Text>
    </div>
  ),
  description: (
    <div style={{ marginLeft: -42 }}>
      <Text>{args.description}</Text>
    </div>
  ),
  style: {
    borderRadius: 6,
    fontSize: 14,
    width: 360,
    marginLeft: 25,
    border: '1px solid #e0dfdd',
    padding: '16px 16px 16px 12px',
    ...args.style,
  },
  closeIcon: (
    <div style={{ display: 'flex', alignItems: 'center', height: 28 }}>
      <Text size="md">
        <Cross />
      </Text>
    </div>
  ),
  icon: (
    <div style={{ display: 'flex', alignItems: 'center', height: 28 }}>
      <Text className={args.classNameIcon} asChild size="md">
        {args.icon}
      </Text>
    </div>
  ),
});

export class Toast {
  key: string;

  constructor() {
    this.key = uuid();
  }

  open(message: string, description?: string): void {
    notification.open(
      notificationProps({
        message,
        description,
        key: this.key,
        duration: 0,
        style: {
          borderLeft: '4px solid #6773A7',
        },
        icon: <InfoCircle />,
        classNameIcon: 'text-info',
      }),
    );
  }

  success(message: string, description?: string): void {
    notification.success(
      notificationProps({
        message,
        description,
        style: {
          borderLeft: '4px solid #2B7B66',
        },
        icon: <CheckCircle />,
        classNameIcon: 'text-positive',
      }),
    );
  }

  error(message: string, description?: string, error?: string): void {
    notification.error(
      notificationProps({
        message,
        description: error ? (
          <>
            {description}
            <br />
            {error}
          </>
        ) : (
          description
        ),
        style: {
          borderLeft: '4px solid #AB2C2C',
        },
        icon: <CrossCircle />,
        classNameIcon: 'text-critical',
      }),
    );
  }

  close(): void {
    notification.close(this.key);
  }
}
