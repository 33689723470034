import { SubscriptionPermissionType, UserPermissionRole } from 'utilities/enum-utils';

export const SUBSCRIPTION_PERMISSION_LIST = [
  {
    type: SubscriptionPermissionType.NO_ACCESS,
    label: 'No access (default)',
    description: 'Cannot view or access this section',
  },
  {
    type: SubscriptionPermissionType.VIEW_ONLY,
    role: UserPermissionRole.SubscriptionBillingViewOnly,
    label: 'View-only access',
    description: 'Can view but not perform any actions within this section',
  },
  {
    type: SubscriptionPermissionType.ALL_ACCESS,
    role: UserPermissionRole.SubscriptionBillingAllAccess,
    label: 'All access',
    description: 'Can see and perform key actions within this section',
  },
];

export enum LogoShape {
  FULL_LOGO = 'FULL_LOGO',
  SQUARE_LOGO = 'SQUARE_LOGO',
}

export interface ILogoShapeExtension {
  [key: string]: {
    title: string;
    cropperElementStyles: { width: number; height: number };
    logoSectionStyles: { width: number; height?: number };
    nonLogoSectionStyles: { width: number; height: number };
    nonFileUploadedTextStyles: { width?: number; display?: string; margin?: string };
    aspectRatio: number;
    minCropBoxWidth: number;
    maximumSizeOfImage: number;
  };
}

export const LogoShapeExtensions: ILogoShapeExtension = {
  [LogoShape.FULL_LOGO]: {
    title: 'Full logo',
    cropperElementStyles: {
      width: 527,
      height: 300,
    },
    nonLogoSectionStyles: {
      height: 164,
      width: 292,
    },
    logoSectionStyles: {
      width: 242,
    },
    nonFileUploadedTextStyles: {},
    aspectRatio: 16 / 9,
    minCropBoxWidth: 120,
    maximumSizeOfImage: 10,
  },
  [LogoShape.SQUARE_LOGO]: {
    title: 'Square icon',
    cropperElementStyles: {
      width: 294,
      height: 294,
    },
    nonLogoSectionStyles: {
      height: 72,
      width: 72,
    },
    logoSectionStyles: {
      height: 72,
      width: 72,
    },
    nonFileUploadedTextStyles: {
      display: 'block',
      margin: 'auto',
      width: 100,
    },
    aspectRatio: 1,
    minCropBoxWidth: 72,
    maximumSizeOfImage: 5,
  },
};

export const ADJUST_MODAL_BUTTONS = {
  SAVE: 'SAVE',
  PREVIEW: 'PREVIEW',
};

export const VALID_SIZE_IMAGE = {
  MIN_WIDTH: 800,
  MAX_WIDTH: 3200,
  MIN_HEIGHT: 50,
};

export const VALID_SIZE_SQUARE_LOGO = {
  MIN_WIDTH: 400,
  MIN_HEIGHT: 400,
};

export const IMAGE_MAX_SIZE = 10;

export const FORM_STATUS = {
  IN_PROGRESS: 'INPROGRESS',
  COMPLETED: 'COMPLETED',
};
