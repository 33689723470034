import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Col, Icon, Row, Tooltip } from 'antd';
import { GhostButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import React, { Component } from 'react';
import CommonUtils from 'utilities/common-utils';
import ViewQuotationAdditionalCostView from './ViewQuotationAdditionalCostView';
import ViewQuotationManualQuoteView from './ViewQuotationManualQuoteView';
import ViewQuotationManualTotalView from './ViewQuotationManualTotalView';
import ViewQuotationSubQuoteView from './ViewQuotationSubQuoteView';

interface IViewQuotationServiceViewProps {
  openEditServiceSchedule: (quotation) => void;
  onOpenDeleteModal: (quotation) => void;
  quotation: any;
  displayMode: string;
  isVCPPayment: boolean;
}

class ViewQuotationServiceView extends Component<IViewQuotationServiceViewProps, any> {
  state = {
    isOpen: false,
  };

  private _getPopoverContent = (quotation) => {
    const { openEditServiceSchedule, onOpenDeleteModal } = this.props;
    return (
      <Menu>
        <MenuItem
          label={''}
          className="hover-bg-blue-lightest mv-medium"
          text={'Edit'}
          onClick={() => openEditServiceSchedule(quotation)}
        />
        <MenuItem
          label={''}
          className="hover-bg-blue-lightest text-color-red-dark mv-medium"
          text={'Delete'}
          onClick={() => onOpenDeleteModal(quotation)}
        />
      </Menu>
    );
  };

  private _showServiceScheduleDetails = () => {
    this.setState({ isOpen: true });
  };

  private _hideServiceScheduleDetails = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    const { quotation, displayMode, isVCPPayment } = this.props;

    return (
      <>
        <Row className={`pv-medium bordered mt-small ph-medium`} type={'flex'} align={'middle'}>
          <Col span={1}>
            <Icon
              type={!isOpen ? 'right' : 'down'}
              onClick={!isOpen ? () => this._showServiceScheduleDetails() : () => this._hideServiceScheduleDetails()}
              className={'ml-medium'}
            />
          </Col>
          <Col span={11} className={'pl-medium'}>
            <Text weight={'bold'}>{quotation.serviceName}</Text>
          </Col>
          <Col span={9} className={'text-align-right'}>
            <Text weight={'bold'}>
              {CommonUtils.formatPrice(quotation.quoteAmount)}
              {quotation.excludePublicHolidays && (
                <Tooltip title={<div>Amount excludes public holidays</div>}>
                  <Icon
                    type="question-circle"
                    className="mr-x-small text-size-x-large ml-small text-color-tertiary cursor-pointer"
                  />
                </Tooltip>
              )}
            </Text>
          </Col>
          <Col span={2} className={'pr-medium text-align-right'}>
            {displayMode !== 'VIEW' && (
              <Popover2
                content={this._getPopoverContent(quotation)}
                popoverClassName={'mb-medium'}
                position={'bottom'}
                interactionKind="click"
                usePortal={false}
              >
                <GhostButton icon={'ellipsis'} />
              </Popover2>
            )}
          </Col>
        </Row>
        {isOpen &&
          (quotation.isManualQuote && quotation.isManualTotal ? (
            <ViewQuotationManualQuoteView quotation={quotation} />
          ) : (
            <ViewQuotationSubQuoteView
              quotation={quotation}
              isShowIncludeGST={displayMode === 'VIEW' && isVCPPayment}
            />
          ))}
        {isOpen && quotation.isManualQuote && !quotation.isManualTotal && (
          <ViewQuotationManualTotalView quotation={quotation} />
        )}
        {isOpen && (
          <ViewQuotationAdditionalCostView
            quotation={quotation}
            isShowGSTLineItem={(displayMode === 'ADD' || displayMode === 'EDIT') && isVCPPayment}
          />
        )}
      </>
    );
  }
}

export default ViewQuotationServiceView;
