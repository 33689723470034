import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ImageEdit = forwardRef(function ImageEdit(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.944 2.25H12a.75.75 0 0 1 0 1.5h-1c-1.907 0-3.261.002-4.29.14-1.005.135-1.585.389-2.008.812-.423.423-.677 1.003-.812 2.009-.138 1.028-.14 2.382-.14 4.289v2c0 1.778.001 3.076.114 4.076l.606-.606 1.671-1.672.037-.037c.635-.635 1.165-1.165 1.642-1.529.504-.384 1.036-.654 1.68-.654.644 0 1.176.27 1.68.654.477.364 1.007.894 1.642 1.53l.037.036L14 15.94c.496-.493.926-.907 1.32-1.208.504-.384 1.036-.654 1.68-.654.644 0 1.176.27 1.68.654.448.342.943.83 1.528 1.415.041-.848.042-1.874.042-3.147v-1a.75.75 0 1 1 1.5 0v1.056c0 1.838 0 3.294-.153 4.433-.158 1.172-.49 2.121-1.238 2.87-.749.748-1.698 1.08-2.87 1.238-1.14.153-2.595.153-4.433.153h-2.112c-1.838 0-3.294 0-4.433-.153-1.172-.158-2.121-.49-2.87-1.238-.748-.749-1.08-1.698-1.238-2.87-.153-1.14-.153-2.595-.153-4.433v-2.112c0-1.838 0-3.294.153-4.433.158-1.172.49-2.121 1.238-2.87.749-.748 1.698-1.08 2.87-1.238 1.14-.153 2.595-.153 4.433-.153ZM4.702 19.298a2.25 2.25 0 0 1-.399-.54L5.53 17.53l1.672-1.671c.682-.682 1.14-1.139 1.528-1.434.37-.283.586-.347.77-.347.184 0 .4.064.77.347.388.295.847.752 1.528 1.434l1.672 1.671 2.677 2.678c-.847.041-1.874.042-3.147.042h-2c-1.907 0-3.261-.002-4.29-.14-1.005-.135-1.585-.389-2.008-.812Zm14.596 0c-.306.307-.694.524-1.267.672L15.06 17c.491-.488.854-.835 1.17-1.075.37-.283.585-.347.77-.347.183 0 .399.064.769.347.387.295.846.752 1.528 1.434l.672.671c-.148.573-.365.962-.672 1.268ZM18.53 4.53a.664.664 0 1 1 .94.94l-1.803 1.802c-.865.865-1.197 1.187-1.59 1.4l-.03.017c-.266.14-.557.23-1.083.347.117-.526.207-.817.347-1.082l.017-.03c.213-.394.535-.726 1.4-1.59L18.53 4.53Zm2-1.06a2.164 2.164 0 0 0-3.06 0l-1.803 1.802-.079.08c-.76.758-1.25 1.248-1.58 1.856l-.023.045c-.323.612-.459 1.292-.67 2.344l-.021.11-.03.146a.75.75 0 0 0 .883.882l.146-.029.11-.022c1.053-.21 1.732-.346 2.344-.669l.045-.024c.608-.33 1.098-.82 1.857-1.579l.079-.079L20.53 6.53a2.164 2.164 0 0 0 0-3.06Z"
        fill="currentColor"
      />
    </Icon>
  );
});
