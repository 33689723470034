import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const VolumeSlash = forwardRef(function VolumeSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.53 1.47a.75.75 0 0 0-1.06 1.06L6.19 7.252c-.57.005-1.066.022-1.485.078-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.08.595-.08 1.345-.08 2.243v.104c0 .899 0 1.648.08 2.242.084.628.27 1.195.726 1.65.455.456 1.022.642 1.65.726.594.08 1.344.08 2.242.08h.179c.99 0 1.25.017 1.467.118.216.102.395.29 1.03 1.051l.263.317.04.048c.706.847 1.286 1.543 1.776 1.991.46.421 1.12.888 1.893.608.773-.28.98-1.061 1.065-1.679.09-.657.09-1.564.09-2.666V15.81l6.719 6.72a.75.75 0 0 0 1.06-1.061l-8-8-6-6-6-6ZM7 8.75h.69l5.56 5.56v2.166c0 1.179-.002 1.982-.076 2.527a1.795 1.795 0 0 1-.1.438 1.794 1.794 0 0 1-.359-.272c-.405-.371-.92-.987-1.675-1.893l-.264-.317-.088-.105c-.502-.604-.9-1.083-1.458-1.344-.558-.262-1.18-.26-1.967-.26H7c-.964 0-1.612-.002-2.095-.066-.461-.063-.659-.17-.789-.3-.13-.13-.237-.328-.3-.79-.064-.482-.066-1.13-.066-2.094s.002-1.612.067-2.095c.062-.461.169-.659.3-.789.13-.13.327-.237.788-.3C5.388 8.753 6.036 8.75 7 8.75Zm6.039 10.752.007-.008c-.004.007-.007.009-.007.008Zm.09-.038c.007 0 .01.001.01.002l-.01-.002Zm-.202-14.94a.077.077 0 0 1 .002-.002.25.25 0 0 1 .279.101v.004c.005.02.019.09.028.243.014.234.014.558.014 1.035V10a.75.75 0 1 0 1.5 0V5.88c0-.446 0-.815-.017-1.1-.016-.263-.05-.59-.203-.867a1.75 1.75 0 0 0-2.162-.783c-.294.114-.53.345-.711.537-.195.208-.432.491-.717.834l-.016.019-.833 1a.75.75 0 1 0 1.152.96l.833-1c.306-.367.513-.615.674-.786a1.74 1.74 0 0 1 .177-.17Zm4.45 1.06a.75.75 0 0 1 1.04-.208C20.436 6.725 21.75 9.207 21.75 12c0 1.174-.232 2.29-.648 3.289-.161.386-.35.755-.564 1.103a.75.75 0 1 1-1.278-.784c.173-.282.326-.581.457-.897.34-.816.533-1.735.533-2.711 0-2.335-1.099-4.33-2.666-5.376a.75.75 0 0 1-.208-1.04Zm-1 4a.75.75 0 0 1 1.04-.208c.823.55 1.333 1.54 1.333 2.624 0 .457-.09.894-.255 1.289a.75.75 0 1 1-1.384-.578c.088-.21.14-.452.14-.711 0-.626-.296-1.128-.668-1.376a.75.75 0 0 1-.207-1.04Z"
        fill="currentColor"
      />
    </Icon>
  );
});
