import { Title } from 'common-components/typography';
import { Steps } from 'antd';
import React from 'react';

const { Step } = Steps;

export function AddMemberSteps({ currentStep = 0 }) {
  return (
    <div style={{ width: '1024px' }} className="pb-small select-none">
      <div className="mb-large">
        <Title level={2} weight="regular" className="mv-none">
          Add Team Member
        </Title>
      </div>
      <Steps current={currentStep} size={'small'}>
        <Step title="Start" />
        <Step title="Duplicate Check" />
        <Step title="Permissions" />
        {/*<Step title="Profile" />*/}
        <Step title="Status and Invite" />
        <Step title="Done!" />
      </Steps>
    </div>
  );
}
