import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FirstLine3 = forwardRef(function FirstLine3(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 4a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm-9 15a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75ZM13 8.25a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7ZM3.25 14a.75.75 0 0 1 .75-.75h16a.75.75 0 1 1 0 1.5H4a.75.75 0 0 1-.75-.75ZM4 3.25a.75.75 0 0 0 0 1.5h1.75V9a.75.75 0 0 0 1.5 0V4.75H9a.75.75 0 1 0 0-1.5H4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
