import _ from 'lodash';
import React, { ReactNode, useState } from 'react';
import { IShiftClashConflict } from 'src/interfaces/service-interfaces';
import { Col, Row } from 'antd';
import { SubTitle } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from '../buttons';
import ShiftClashItem from './ShiftClashItem';

interface IShiftClashContentProps {
  shiftClashConflicts: IShiftClashConflict[];
  description: ReactNode;
  firstColumnTitle: string;
  okButtonText: string;
  hasKeepInShift?: boolean;
  hasActionButton?: boolean;
  isBlueprintLibUsed?: boolean;
  isLoading?: boolean;
  onCancel: () => void;
  onOk: (selectedSupportWorkerAttendanceIds: string[]) => void;
}

const ShiftClashContent = (props: IShiftClashContentProps) => {
  const {
    shiftClashConflicts,
    description,
    firstColumnTitle,
    okButtonText,
    hasKeepInShift,
    hasActionButton,
    isLoading,
    isBlueprintLibUsed,
    onCancel,
    onOk,
  } = props;
  const [selectedIds, setSelectedIds] = useState<string[]>(
    !hasKeepInShift
      ? _.map(
          _.flatMap(shiftClashConflicts, (shiftClashConflict) => shiftClashConflict.selectedShifts),
          ({ supportWorkerAttendanceId }) => supportWorkerAttendanceId,
        )
      : [],
  );

  const handleKeepInShift = (isChecked, supportWorkerAttendanceIds) => {
    if (isChecked) {
      setSelectedIds([...selectedIds, ...supportWorkerAttendanceIds]);
    } else {
      setSelectedIds(_.filter(selectedIds, (id) => _.indexOf(supportWorkerAttendanceIds, id) === -1));
    }
  };

  return (
    <>
      <div className="mb-medium">{description}</div>
      <Row className="bordered-bottom p-medium">
        <Col span={10}>
          <SubTitle>{firstColumnTitle}</SubTitle>
        </Col>
        <Col span={8}>
          <SubTitle>Shift conflicts</SubTitle>
        </Col>
        <Col span={5}>{hasKeepInShift && <SubTitle>Action</SubTitle>}</Col>
        <Col span={1}></Col>
      </Row>
      {_.map(shiftClashConflicts, (shiftClashConflict) => (
        <ShiftClashItem
          key={shiftClashConflict.supportWorkerId}
          shiftClashConflict={shiftClashConflict}
          selectedIds={selectedIds}
          hasActionButton={hasActionButton}
          hasKeepInShift={hasKeepInShift}
          handleKeepInShift={handleKeepInShift}
          isBlueprintLibUsed={isBlueprintLibUsed}
        />
      ))}
      <div className="flex mt-x2-large justify-end">
        <SecondaryButton size="large" onClick={onCancel}>
          Back
        </SecondaryButton>
        <PrimaryButton className="ml-medium" size="large" loading={isLoading} onClick={() => onOk(selectedIds)}>
          {okButtonText}
        </PrimaryButton>
      </div>
    </>
  );
};

export default React.memo(ShiftClashContent);
