import React, { HTMLProps, PureComponent, ReactNode } from 'react';
import { Avatar, Badge, Icon, Popover } from 'antd';

interface IUserAvatar extends HTMLProps<any> {
  avatarSrc?: string;
  count?: number | ReactNode;
  icon?: string;
  iconClassName?: string;
  tooltipContent?: string | ReactNode;
}

export default class UserAvatar extends PureComponent<IUserAvatar, any> {
  render() {
    const { className = '', avatarSrc, count = 0, icon, iconClassName, tooltipContent = '' } = this.props;

    return (
      <div className={`${className} flex-row`}>
        {count !== undefined || icon ? (
          <Popover content={tooltipContent} placement="bottom" style={{ left: '-31px' }}>
            <Badge
              count={
                icon ? (
                  <div
                    className="bg-white flex"
                    style={{ width: '14px', height: '14px', borderRadius: '100%', padding: '1px' }}
                  >
                    <Icon type={icon} theme="filled" className={iconClassName} style={{ fontSize: '12px' }} />
                  </div>
                ) : (
                  count
                )
              }
              offset={[1, 28]}
              style={{ zIndex: 1 }}
            >
              <div style={{ width: '1px', height: '40px' }}></div>
            </Badge>
          </Popover>
        ) : (
          <></>
        )}
        <Avatar icon="user" shape="square" src={avatarSrc} />
      </div>
    );
  }
}
