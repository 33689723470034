import { InputNumber, Select } from 'antd';
import React, { memo, useCallback, useState } from 'react';

import { INonFaceToFace } from 'interfaces/customer-interfaces';
import { NonFaceToFaceFrequency } from 'utilities/enum-utils';

interface INonFaceToFaceMinutesInputProps {
  onChange?: (value: INonFaceToFace) => void;
  value?: INonFaceToFace;
}

const NonFaceToFaceMinutesInput = ({ onChange, value }: INonFaceToFaceMinutesInputProps) => {
  const [nonFaceToFaceFrequency, setNonFaceToFaceFrequency] = useState(value.nonFaceToFaceFrequency);
  const [nonFaceToFaceMinutes, setNonFaceToFaceMinutes] = useState(value.nonFaceToFaceMinutes);

  const handleChangeInputNumber = useCallback(
    (minutes: number) => {
      setNonFaceToFaceMinutes(minutes);
      onChange({ nonFaceToFaceFrequency, nonFaceToFaceMinutes: minutes });
    },
    [nonFaceToFaceFrequency, onChange],
  );

  const handleChangeFrequency = useCallback(
    (frequency: NonFaceToFaceFrequency) => {
      setNonFaceToFaceFrequency(frequency);
      onChange({ nonFaceToFaceMinutes, nonFaceToFaceFrequency: frequency });
    },
    [nonFaceToFaceMinutes, onChange],
  );

  return (
    <div className="flex align-center">
      <InputNumber
        style={{ width: '70px' }}
        min={0}
        max={10000}
        onChange={handleChangeInputNumber}
        value={nonFaceToFaceMinutes}
      />
      <span className="m-x-small"> minutes per </span>
      <Select style={{ width: '95px' }} value={nonFaceToFaceFrequency} onChange={handleChangeFrequency}>
        <Select.Option value={NonFaceToFaceFrequency.PER_HOUR}>hour</Select.Option>
        <Select.Option value={NonFaceToFaceFrequency.PER_BOOKING}>booking</Select.Option>
      </Select>
    </div>
  );
};

export default memo(NonFaceToFaceMinutesInput);
