import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton } from 'common-components/buttons';
import { IServiceAgreement } from 'interfaces/customer-interfaces';
import ViewEditQuoteView from 'views/customers/details/tabs-panel/new-service-agreements/components/ViewEditQuoteView';
import { Text } from 'common-components/typography';

interface IViewQuoteModalProps {
  closeEdit: () => void;
  isOpen: boolean;
  serviceAgreement: IServiceAgreement;
}

class ViewQuoteModal extends Component<IViewQuoteModalProps> {
  render() {
    const { isOpen, closeEdit, serviceAgreement } = this.props;

    return (
      <>
        <ActionModal
          isOpen={isOpen}
          onClose={closeEdit}
          title={
            <>
              View <b>Quote</b>
            </>
          }
          showCloseButton={true}
          width={'x2-large'}
        >
          <Text>View the details of the quote for this service agreement.</Text>
          <ViewEditQuoteView
            displayMode={'VIEW'}
            quotations={serviceAgreement && serviceAgreement.quotations}
            paymentSourceType={serviceAgreement && serviceAgreement.paymentSourceType}
            isNewServiceAgreement={false}
          />
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.props.closeEdit}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </>
    );
  }
}

export default ViewQuoteModal;
