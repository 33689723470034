import { Text } from '@good/components';
import { Info } from '@good/icons';
import { NumberFormatter } from '@goodhuman-me/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InsightsSummaryResponse } from 'views/insights/insights';
import TooltipInfo from '../../../team-v2/team-details/components/TooltipInfo';

export type UtilisationProps = Pick<InsightsSummaryResponse, 'overallUtilisationPercent'>;
export const Utilisation = ({ overallUtilisationPercent }: UtilisationProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'insights.summary.utilisation' });

  const displayUtilisationPercent: string = overallUtilisationPercent?.toString() ?? '0';

  return (
    <div className="align-center flex flex-col justify-center">
      <Text className="text-b-xs text-gray-dark-1">
        <div className="flex flex-row space-x-1">
          <Text className="w-fit">{t('label')}</Text>
          <TooltipInfo
            placement="top left"
            isShow={true}
            message={<div className="w-72 p-3">{t('tooltipDescription')}</div>}
            content={
              <div className="h-4 w-4 text-black">
                <Info />
              </div>
            }
          />
        </div>
      </Text>
      <span aria-valuetext={displayUtilisationPercent} className="font-base text-h-lg font-semibold">
        <div className="flex flex-row">
          <NumberFormatter value={overallUtilisationPercent ?? 0} />%
        </div>
      </span>
    </div>
  );
};
