import { getApp } from 'firebase/app';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { FIREBASE_CONFIG } from 'config/app-config';

export { useFirebaseStorage } from './useFirebaseStorage';

export const humanFileSize = (size: number): string => {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  const unit = ['B', 'kB', 'MB', 'GB', 'TB'][i] ?? 'B';
  const sizeInUnit = Number((size / Math.pow(1024, i)).toFixed(2));
  return `${sizeInUnit} ${unit}`;
};

const tempStorageBucketUrl = `gs://${FIREBASE_CONFIG.projectId}-temp`;

export const uploadTempFileForProvider = async ({
  serviceProviderId,
  filePath,
  file,
}: { serviceProviderId: string; filePath: string; file: Blob }) =>
  await uploadTempFile({ filePath: `service-providers/${serviceProviderId}/${filePath}`, file });

const uploadTempFile = async ({ filePath, file }: { filePath: string; file: Blob }) => {
  const firebase = getApp();
  const tempStorage = getStorage(firebase, tempStorageBucketUrl);
  const storageRef = ref(tempStorage, filePath);
  return await uploadBytes(storageRef, file);
};
