/* BOOM-475 - fix linting errors */
import React from 'react';

import { ICustomerAttachment } from 'interfaces/customer-interfaces';
import { Medias } from 'design-components/medias';
import { Cross } from '@good/icons';
import { Button } from '@good/components';

type IPhotoItemProps = {
  item: ICustomerAttachment;
  onDelete: () => void;
  onSelect: (item: ICustomerAttachment) => void;
};

const PhotoItem = (props: IPhotoItemProps) => {
  const { item, onDelete, onSelect } = props;
  return (
    <span
      className="profile-photo-item relative"
      onClick={() => {
        onSelect(item);
      }}
    >
      <Medias stroked={false} shape={'square'} className="profile-avatar" src={item.attachmentUrl} />
      <Button type="button" size="xs" onClick={onDelete} className="absolute right-0 top-0 m-2">
        <Cross />
      </Button>
    </span>
  );
};

export default PhotoItem;
