import { tv } from 'tailwind-variants';

import type { VariantProps } from 'tailwind-variants';

export const label = tv(
  {
    base: [
      'inline-flex',
      'items-center',
      'rounded-full',
      'w-fit',
      'break-words',
      'font-base',
      'border-[1.5px]',
      'font-semibold',
    ],
    variants: {
      tone: {
        critical: [],
        info: [],
        neutral: [],
        notice: [],
        positive: [],
        blue: [],
        canary: [],
        lilac: [],
      },
      emphasis: {
        strong: [],
        weak: [],
        outline: [],
        quiet: [],
      },
      size: {
        xs: ['py-[1.5px]', 'px-1.5', 'text-b-2xs', 'leading-b-2xs'],
        sm: ['py-[2.5px]', 'px-2', 'text-b-xs', 'leading-b-xs'],
        md: ['py-[3.5px]', 'px-[18px]', 'text-b-sm', 'leading-b-md'],
        lg: ['py-[9.5px]', 'px-5', 'text-b-lg', 'leading-b-lg'],
      },
    },
    compoundVariants: [
      // neutral
      {
        tone: 'neutral',
        emphasis: 'strong',
        class: 'bg-neutral text-on-background',
      },
      {
        tone: 'neutral',
        emphasis: 'weak',
        class: 'bg-neutral-weak border-neutral-weak text-base',
      },
      {
        tone: 'neutral',
        emphasis: 'outline',
        class: 'bg-base text-neutral border-neutral',
      },
      {
        tone: 'neutral',
        emphasis: 'quiet',
        class: 'bg-transparent border-transparent text-neutral',
      },
      // info
      {
        tone: 'info',
        emphasis: 'strong',
        class: 'bg-info text-on-background border-info',
      },
      {
        tone: 'info',
        emphasis: 'weak',
        class: 'bg-info-weak text-info-strong border-info-weak',
      },
      {
        tone: 'info',
        emphasis: 'outline',
        class: 'bg-base text-info border-info',
      },
      {
        tone: 'info',
        emphasis: 'quiet',
        class: 'bg-transparent text-info border-transparent',
      },
      // positive
      {
        tone: 'positive',
        emphasis: 'strong',
        class: 'bg-positive text-on-background border-positive',
      },
      {
        tone: 'positive',
        emphasis: 'weak',
        class: 'bg-positive-weak text-positive-strong border-positive-weak',
      },
      {
        tone: 'positive',
        emphasis: 'outline',
        class: 'bg-base text-positive border-positive',
      },
      {
        tone: 'positive',
        emphasis: 'quiet',
        class: 'bg-transparent text-positive border-transparent',
      },
      // notice
      {
        tone: 'notice',
        emphasis: 'strong',
        class: 'bg-notice text-on-background border-notice',
      },
      {
        tone: 'notice',
        emphasis: 'weak',
        class: 'bg-notice-weak text-notice-strong border-notice-weak',
      },
      {
        tone: 'notice',
        emphasis: 'quiet',
        class: 'bg-transparent text-notice  border-transparent',
      },
      {
        tone: 'notice',
        emphasis: 'outline',
        class: 'bg-base text-notice border-notice',
      },
      // critical
      {
        tone: 'critical',
        emphasis: 'strong',
        class: 'bg-critical text-on-background border-critical',
      },
      {
        tone: 'critical',
        emphasis: 'weak',
        class: 'bg-critical-weak text-critical-strong border-critical-weak',
      },
      {
        tone: 'critical',
        emphasis: 'outline',
        class: 'bg-base text-critical border-critical',
      },
      {
        tone: 'critical',
        emphasis: 'quiet',
        class: 'bg-transparent text-critical border-transparent',
      },
      // blue
      {
        tone: 'blue',
        emphasis: 'strong',
        class: 'bg-blue text-on-background border-blue',
      },
      {
        tone: 'blue',
        emphasis: 'weak',
        class: 'bg-blue-weak text-blue-strong border-blue-weak',
      },
      {
        tone: 'blue',
        emphasis: 'outline',
        class: 'bg-base text-blue border-blue',
      },
      {
        tone: 'blue',
        emphasis: 'quiet',
        class: 'bg-transparent text-blue border-transparent',
      },
      // canary
      {
        tone: 'canary',
        emphasis: 'strong',
        class: 'bg-canary text-on-background border-canary',
      },
      {
        tone: 'canary',
        emphasis: 'weak',
        class: 'bg-canary-weak text-canary-strong border-canary-weak',
      },
      {
        tone: 'canary',
        emphasis: 'outline',
        class: 'bg-base text-canary border-canary',
      },
      {
        tone: 'canary',
        emphasis: 'quiet',
        class: 'bg-transparent text-canary border-transparent',
      },
      // lilac
      {
        tone: 'lilac',
        emphasis: 'strong',
        class: 'bg-lilac text-on-background border-lilac',
      },
      {
        tone: 'lilac',
        emphasis: 'weak',
        class: 'bg-lilac-weak text-lilac-strong border-lilac-weak',
      },
      {
        tone: 'lilac',
        emphasis: 'outline',
        class: 'bg-base text-lilac border-lilac',
      },
      {
        tone: 'lilac',
        emphasis: 'quiet',
        class: 'bg-transparent text-lilac border-transparent',
      },
    ],
    defaultVariants: {
      size: 'md',
      emphasis: 'weak',
      tone: 'neutral',
    },
  },
  {
    twMerge: false,
  },
);

type Variants = Required<VariantProps<typeof label>>;

export type LabelVariants = {
  /**
   * Visual importance of the label.
   * @default outline
   */
  emphasis?: Variants['emphasis'];
  /**
   * Change the size of the label.
   * @default md
   */
  size?: Variants['size'];
  /**
   * Change the color based on semantic meaning of the label.
   * @default neutral
   */
  tone?: Variants['tone'];
};
