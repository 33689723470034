import _ from 'lodash';
import { Text } from 'common-components/typography';
import React from 'react';
import { EndSpacer } from 'common-components/roster-control/roster-daily/timeline-pane/common/EndSpacer';
import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';

const { HOUR_BLOCK_WIDTH, HOUR_BLOCK_HEIGHT } = ROSTER_DAILY_CONFIG;

interface HourBlockProps {
  hour: any;
}

// Local component for blocks
function HourBlock({ hour }: HourBlockProps) {
  return (
    <div
      className="line-height-100 bordered-left bordered-bottom bordered-top text-align-center select-none flex-row align-center justify-center bg-quaternary"
      style={{ minWidth: `${HOUR_BLOCK_WIDTH}px`, maxWidth: `${HOUR_BLOCK_WIDTH}px`, height: HOUR_BLOCK_HEIGHT }}
    >
      <Text lineHeight={100} size="regular" color="secondary">
        {hour}
      </Text>
    </div>
  );
}

export function HoursDisplay({ hours }: { hours: any[] }) {
  return (
    <div>
      <div className="flex-row bg-quaternary">
        {/*<Text>Terst</Text>*/}

        {_.map(hours, (hour, idx) => (
          <HourBlock key={idx} hour={hour} />
        ))}

        {/*/!* End spacer *!/*/}
        <EndSpacer height={HOUR_BLOCK_HEIGHT} />
      </div>
    </div>
  );
}
