import React from 'react';
import { RouteObject, useRoutes, generatePath } from 'react-router-dom-v5-compat';
import { CustomerDetailAboutView } from './details/sections/about/customer-detail-about-view';

export const routes = {
  INDEX: {
    path: '/',
    title: 'Customer Details',
    link: '/customer/details/:userId',
  },
  ACTIVITY: {
    path: 'activity',
    title: 'Activity',
    link: `/customer/details/:userId/activity`,
  },
  SERVICES: {
    path: 'services',
    title: 'Services',
    link: '/customer/details/:userId/services',
  },
  CARE: {
    path: 'care',
    title: 'Care',
    link: '/customer/details/:userId/care',
  },
  CONTACTS: {
    path: 'contacts',
    title: 'Contacts',
    link: '/customer/details/:userId/contacts',
  },
  FILES: {
    path: 'files',
    title: 'Files',
    link: '/customer/details/:userId/files',
  },
  FUNDING: {
    path: 'funding',
    title: 'Funding',
    link: '/customer/details/:userId/funding',
  },
  ABOUT: {
    path: 'about',
    title: 'About',
    link: '/customer/details/:userId/about',
  },
};

const routeDefs: RouteObject[] = [
  {
    path: routes.INDEX.path,
    element: <CustomerDetailAboutView title={routes.INDEX.title} />,
  },
  {
    path: routes.ACTIVITY.path,
    element: <CustomerDetailAboutView title={routes.ACTIVITY.title} />,
  },
  {
    path: routes.SERVICES.path,
    element: <CustomerDetailAboutView title={routes.SERVICES.title} />,
  },
  {
    path: routes.CARE.path,
    element: <CustomerDetailAboutView title={routes.CARE.title} />,
  },
  {
    path: routes.CONTACTS.path,
    element: <CustomerDetailAboutView title={routes.CONTACTS.title} />,
  },
  {
    path: routes.FILES.path,
    element: <CustomerDetailAboutView title={routes.FILES.title} />,
  },
  {
    path: routes.FUNDING.path,
    element: <CustomerDetailAboutView title={routes.FUNDING.title} />,
  },
  {
    path: routes.ABOUT.path,
    element: <CustomerDetailAboutView title={routes.ABOUT.title} />,
  },
];

export const CustomerDetailNestedRoutes = (): JSX.Element | null => {
  return useRoutes(routeDefs);
};

export const getCustomerDetailLink = (userId: string): string => generatePath(routes.INDEX.link, { userId });
export const getCustomerDetailActivityLink = (userId: string): string => generatePath(routes.ACTIVITY.link, { userId });
export const getCustomerDetailServicesLink = (userId: string): string => generatePath(routes.SERVICES.link, { userId });
export const getCustomerDetailCareLink = (userId: string): string => generatePath(routes.CARE.link, { userId });
export const getCustomerDetailContactsLink = (userId: string): string => generatePath(routes.CONTACTS.link, { userId });
export const getCustomerDetailFilesLink = (userId: string): string => generatePath(routes.FILES.link, { userId });
export const getCustomerDetailFundingLink = (userId: string): string => generatePath(routes.FUNDING.link, { userId });
export const getCustomerDetailAboutLink = (userId: string): string => generatePath(routes.ABOUT.link, { userId });
