import { Button, Text } from '@good/components';
import { Empty, Skeleton } from 'antd';
import { Flyout, FlyoutProperties } from 'design-components/flyout/flyout';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useState } from 'react';
import { useDateFormatter } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFetchInsightsSummary } from 'stores/hooks/query-hooks/use-query-fetch-team-insights-summary';
import { IRootState } from 'stores/rematch/root-store';
import { InsightsSummaryFilters, getDatesForTimePeriod } from 'views/insights/insights';
import { InsightsFlyoutFilters } from '../insights-flyout-filters/insights-flyout-filters';
import { InsightsSummary } from '../insights-summary/insights-summary';

type InsightsFlyoutProperties = {
  timezone?: string;
} & Omit<FlyoutProperties, 'heading'>;

const UnconnectedInsightsFlyout: React.FC<InsightsFlyoutProperties> = ({ isOpen, timezone, ...properties }) => {
  const { t } = useTranslation('', { keyPrefix: 'insights.summary' });
  const { boom284WorkforceInsightsTablePage } = useFlags<{ boom284WorkforceInsightsTablePage: boolean }>();

  const [filters, setFilters] = useState<InsightsSummaryFilters>({
    teamMemberGroup: 'ALL_MEMBERS',
    ...getDatesForTimePeriod('thisWeek', timezone),
  });

  const { data, isError, isLoading, isSuccess } = useFetchInsightsSummary(filters, isOpen);

  const showUtilisation: boolean =
    boom284WorkforceInsightsTablePage && filters.teamMemberGroup === 'SUPPORT_COORDINATORS';

  const footer: JSX.Element | undefined = showUtilisation ? (
    <div className="flex w-full justify-center">
      <Link to="/team/team-insights">
        <Button>
          <Text>View more</Text>
        </Button>
      </Link>
    </div>
  ) : undefined;

  return (
    <Flyout {...properties} isOpen={isOpen} heading={t('heading')} footer={footer}>
      <div className="flex flex-col space-y-8">
        {isError && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {!isError && (
          <InsightsFlyoutFilters
            filters={filters}
            updateFilters={setFilters}
            timezone={timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone}
          />
        )}
        {isLoading && <Skeleton paragraph={{ width: '100%', rows: 8 }} title={false} active />}
        {isSuccess && (
          <div className="flex flex-col space-y-8">
            <DateFilterDisplay key={filters.dateUpper} dateLower={filters.dateLower} dateUpper={filters.dateUpper} />
            <InsightsSummary insights={data} showUtilisation={showUtilisation} />
          </div>
        )}
      </div>
    </Flyout>
  );
};

type DateFilterDisplay = Pick<InsightsSummaryFilters, 'dateUpper' | 'dateLower'>;
const DateFilterDisplay = ({ dateUpper, dateLower }: DateFilterDisplay) => {
  const dateFormatter = useDateFormatter({ day: 'numeric', month: 'long', year: 'numeric', weekday: 'short' });

  const momentDateUpper = moment(dateUpper);
  const momentDateLower = moment(dateLower);

  const isSameDay = Math.abs(momentDateUpper.diff(momentDateLower, 'day')) <= 2;
  if (isSameDay) {
    return <Text className="font-base text-b-md font-bold">{dateFormatter.format(momentDateLower.toDate())}</Text>;
  }

  return (
    <Text className="font-base text-b-md font-bold">
      {dateFormatter.format(momentDateLower.toDate())} - {dateFormatter.format(momentDateUpper.toDate())}
    </Text>
  );
};

const mapState = (state: IRootState) => ({
  // Evil unknown cast is necessary since the root store will return these as nevers.
  timezone: (state.companyStore.companyDataLite as unknown as { timezone?: string } | null)?.timezone,
});

export const InsightsFlyout = connect(mapState)(UnconnectedInsightsFlyout);
