import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { useHover } from '@react-aria/interactions';
import { AriaTextFieldProps, useTextField } from '@react-aria/textfield';

import { IValidations } from '../types';
import * as styles from './textarea.css';
import { useMultiValidation } from '../utils/multi-validation';
import { InputFieldContext } from '../input-field/input-field-provider';
import { InputGroupContext } from '../input-group/input-group-providers';
import React, { forwardRef, PropsWithChildren, useContext, useRef } from 'react';
import { compose, css, CSS, inputStyleProps, InputStylesProps, useStyleProps } from '..';

export type TextAreaProps = PropsWithChildren<
  AriaTextFieldProps &
    InputStylesProps & {
      /**
       * Render rules of field (status, rule)
       */
      validations?: IValidations;

      /**
       * Whether show text count
       */
      showCount?: boolean;

      /**
       * Container styles
       */
      containerStyles?: CSS;
    }
>;

/**
 * @name
 * Text Field
 *
 * @description
 * <Textarea> is HTML Input Element, allows users enter value with common input props.
 *
 * @example
 * <Textarea
 *   placeholder="Enter first name"
 *   label="First Name"
 *   onChange={(value) => console.log(value)}
 * />
 */

// eslint-disable-next-line react/display-name
export const Textarea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const inputRef = useRef(null);
  const { value, showCount, maxLength = 100, isDisabled, validations, containerStyles } = props;

  const inputGroupProps = useContext(InputGroupContext);
  const { fieldProps } = useContext(InputFieldContext);

  const { inputProps } = useTextField({ ...props, inputElementType: 'textarea' }, inputRef);
  const { focusProps, isFocusVisible, isFocused } = useFocusRing(props);
  const { hoverProps, isHovered } = useHover({ isDisabled: inputProps.disabled });

  const textAreaProps = mergeProps(inputProps, focusProps, hoverProps, fieldProps);
  const { styleProps } = useStyleProps(props, inputStyleProps);

  // validation
  const { validationFields, isInvalid } = useMultiValidation({ validations });

  return (
    <div className={css(styles.container, containerStyles)()}>
      <div
        className={compose(
          css(styles.wrapper, styleProps),
          styles.wrapperVariants({
            isHovered,
            isFocused: isFocused || isFocusVisible,
            isErrored: isInvalid,
            isDisabled,
          }),
        )}
      >
        <textarea ref={ref} {...textAreaProps} className={compose(css(styles.reset, styles.textarea))} />
      </div>
      {!inputGroupProps.isInsideGroupInput && (
        <>
          {showCount && (
            <div className={css(styles.charactersTotal)()}>
              <span>
                {value.length}/{maxLength}
              </span>
            </div>
          )}
          {validationFields()}
        </>
      )}
    </div>
  );
});
