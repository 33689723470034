import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Cloud = forwardRef(function Cloud(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 11a6.25 6.25 0 0 1 12.322-1.488.75.75 0 0 0 .458.522A4.252 4.252 0 0 1 17 18.25H6a3.25 3.25 0 0 1-.812-6.398.75.75 0 0 0 .563-.74V11ZM12 3.25a7.75 7.75 0 0 0-7.739 7.329 4.751 4.751 0 0 0 1.74 9.171h11a5.75 5.75 0 0 0 2.428-10.963A7.753 7.753 0 0 0 12 3.25Zm-.47 12.28 4-4a.75.75 0 1 0-1.06-1.06L11 13.94l-.97-.97a.75.75 0 0 0-1.06 1.06l1.5 1.5a.75.75 0 0 0 1.06 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
