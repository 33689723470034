import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { notification, Checkbox, Form, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Information } from 'common-components/alerts';

interface IDeleteServiceAgreementModalProps extends FormComponentProps {
  isOpen: boolean;
  serviceAgreement: any;
  onClose: () => void;
  doDeleteServiceAgreement: typeof dispatch.customersStore.doDeleteServiceAgreement;
}

interface IDeleteServiceAgreementModalState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

class DeleteServiceAgreementModal extends Component<
  IDeleteServiceAgreementModalProps,
  IDeleteServiceAgreementModalState
> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false,
  };

  private _validateAcknowledge = (rule, value, callback) => {
    try {
      if (!value) {
        throw Error(`\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Please check the box in order to continue.`);
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  private _onDeleteServiceAgreement = async () => {
    this.setState({ isLoading: true });
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        await this.props.doDeleteServiceAgreement({
          serviceAgreementId: this.props.serviceAgreement.userServiceAgreementId,
          status: this.props.serviceAgreement.status,
        });
        notification.success({ message: 'Service agreement deleted successfully.' });
        this.props.onClose();
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { isOpen, serviceAgreement, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      serviceAgreement && (
        <ActionModal
          title={<>Delete customer service agreement</>}
          showCloseButton={false}
          isOpen={isOpen}
          onClose={this._onCloseModal}
          width="large"
          verticalAlignment="center"
        >
          <div className="text-align-left">
            <div className="mb-medium">
              <Information
                content={
                  <Text size="regular">
                    <b>Warning: Deleting a service agreement is a permanent action and cannot be reversed</b>
                    <br />
                    If you would like you can edit a customers service agreement instead of deleting it. Deleting a
                    service agreement can have a significant impact on how a customer is billed. Please ensure you
                    understand these implications before you continue.
                  </Text>
                }
                className={'mb-large'}
              />
              <Form.Item className="width-full">
                {getFieldDecorator('acknowledgment', { rules: [{ validator: this._validateAcknowledge }] })(
                  <Checkbox>I understand the implications of deleting a customers service agreement</Checkbox>,
                )}
              </Form.Item>
            </div>
          </div>
          <ActionModalFooter>
            <SecondaryButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._onDeleteServiceAgreement} loading={this.state.isLoading}>
              Delete
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      )
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doDeleteServiceAgreement: dispatch.customersStore.doDeleteServiceAgreement,
});

export default connect(
  null,
  mapDispatch,
)(Form.create<IDeleteServiceAgreementModalProps>()(DeleteServiceAgreementModal));
