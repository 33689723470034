import { Label } from '@goodhuman-me/components';
import React from 'react';
import { FormType } from 'utilities/enum-utils';

interface IFormTypeTagProps {
  formType: FormType;
}

const FormTypeTag = (props: IFormTypeTagProps): JSX.Element => {
  const { formType } = props;
  let tagName = 'Custom';
  let theme: 'blue' | 'info' | 'critical' | 'canary' | 'lilac' | 'neutral' | 'positive' | 'notice' = 'info';

  switch (formType) {
    case FormType.CUSTOM:
      tagName = 'Custom';
      theme = 'info';
      break;
    case FormType.INCIDENT:
      tagName = 'Incident';
      theme = 'critical';
      break;
    case FormType.INTAKE:
      tagName = 'Intake';
      theme = 'blue';
      break;
    default:
      tagName = 'Custom';
      theme = 'info';
  }

  return (
    <Label emphasis="outlined" tone={theme} size="small" marginY={0}>
      {tagName}
    </Label>
  );
};

export default FormTypeTag;
