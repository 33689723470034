import { Card, Form, Switch } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton } from 'common-components/buttons';
import { Paragraph, Text, Title } from 'common-components/typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { IAddWorkflowTemplateWizard } from '../../../../../../interfaces/workflow-interfaces';

interface ICreateWorkflowTemplateConfigurationStepPanelProps extends FormComponentProps {
  onNextStep(): void;
  onPreviousStep(): void;
  setAddWorkflowTemplateWizard: typeof dispatch.workflowStore.setAddWorkflowTemplateWizard;
  addWorkflowTemplateWizard: typeof state.workflowStore.addWorkflowTemplateWizard;
}

interface ICreateWorkflowTemplateConfigurationStepPanelState {
  addWorkflowTemplateWizard: IAddWorkflowTemplateWizard;
}

class CreateWorkflowTemplateConfigurationStepPanel extends Component<
  ICreateWorkflowTemplateConfigurationStepPanelProps,
  ICreateWorkflowTemplateConfigurationStepPanelState
> {
  state = {
    addWorkflowTemplateWizard: this.props.addWorkflowTemplateWizard,
  };

  private _onChangeSwitch = (type, newValue) => {
    this.setState({
      addWorkflowTemplateWizard: {
        ...this.state.addWorkflowTemplateWizard,
        [type]: newValue,
      },
    });
  };

  private _onNextStep = () => {
    const { setAddWorkflowTemplateWizard, onNextStep } = this.props;

    setAddWorkflowTemplateWizard(this.state.addWorkflowTemplateWizard);

    onNextStep();
  };

  componentDidMount() {
    this.setState({ addWorkflowTemplateWizard: this.props.addWorkflowTemplateWizard });
  }

  render() {
    const { addWorkflowTemplateWizard } = this.state;

    return (
      <>
        <Card bordered={false} className="shadow-container rounded-big">
          <Title level={4} weight="regular" className="mb-large">
            Configure the settings of this workflow
          </Title>

          <Paragraph size="large">Workflow management</Paragraph>

          <div className="flex align-center">
            <Switch
              defaultChecked={addWorkflowTemplateWizard.isAllowApproverFlagIssue}
              size={'small'}
              className={'mr-x-small'}
              onChange={(e) => this._onChangeSwitch('isAllowApproverFlagIssue', e)}
            />
            &nbsp;
            <Text size="regular">Allow approvers to flag issues</Text>
          </div>

          <div className="mt-x-large">
            <Paragraph size="large">Notifications</Paragraph>

            <div className="flex align-center">
              <Switch
                defaultChecked={addWorkflowTemplateWizard.isNotifyWorkflowCompleted}
                size={'small'}
                className={'mr-x-small'}
                onChange={(e) => this._onChangeSwitch('isNotifyWorkflowCompleted', e)}
              />
              &nbsp;
              <Text size="regular">Notify all approvers when a workflow is completed</Text>
            </div>

            <div className="flex align-center mt-medium">
              <Switch
                defaultChecked={addWorkflowTemplateWizard.isNotifyWorkflowFlagged}
                size={'small'}
                className={'mr-x-small'}
                onChange={(e) => this._onChangeSwitch('isNotifyWorkflowFlagged', e)}
              />
              &nbsp;
              <Text size="regular">Notify all approvers when an issue is flagged</Text>
            </div>
          </div>
        </Card>

        <div className="justify-end flex p-large">
          <PrimaryButton size="large" onClick={this._onNextStep}>
            Next
          </PrimaryButton>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  addWorkflowTemplateWizard: state.workflowStore.addWorkflowTemplateWizard,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddWorkflowTemplateWizard: dispatch.workflowStore.setAddWorkflowTemplateWizard,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<ICreateWorkflowTemplateConfigurationStepPanelProps>()(CreateWorkflowTemplateConfigurationStepPanel));
