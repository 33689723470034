import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import React from 'react';

export function PotentialDuplicateSection() {
  return (
    <div className="flex-row p-x-large">
      <div className="mr-medium">
        <Icon type="copy" style={{ fontSize: '40px' }} className="text-color-tertiary" />
      </div>
      <div style={{ maxWidth: '536px' }}>
        <div className="mb-x-large">
          <Text size="x2-large">Potential Duplicates Found</Text>
        </div>
        <Text>
          There are some people in your business that match the data you’ve entered. <br /> <br />
          To keep your business running smoothly it’s important to avoid creating duplicate team members. <br /> <br />
          Click on person to view their details. If you’re certain this is someone new to your business you can create a
          new team member. <br /> <br />
          {/*<br />*/}
          {/*Visit the <HyperlinkButton>Help Center</HyperlinkButton> to learn more.*/}
        </Text>
      </div>
    </div>
  );
}
