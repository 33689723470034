import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React, { PureComponent } from 'react';

interface Props {
  isOpen: boolean;
  onClose(): void;
  onConfirm(): void;
}

class ConfirmChangeWorkflowTemplateModal extends PureComponent<Props> {
  render() {
    const { isOpen, onClose, onConfirm } = this.props;

    return (
      <ActionModal isOpen={isOpen} onClose={onClose} title="Save changes" verticalAlignment="high">
        <Paragraph>
          Saving the changes made to this workflow will create a new version of this workflow. All workflows created
          from this point onwards will be the updated version. All existing workflows will be unaffected and remain at
          the current version.
        </Paragraph>
        <Paragraph className="mt-medium">Are you sure you want to save the changes made to this workflow?</Paragraph>

        <div className="mt-x2-large flex justify-end">
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={onConfirm}>
            Save changes
          </PrimaryButton>
        </div>
      </ActionModal>
    );
  }
}

export default ConfirmChangeWorkflowTemplateModal;
