import React, { useState } from 'react';
import { RangeSelector } from './components/range-selector/range-selector';
import { RosterPeriodStatus } from './components/roster-period-status/roster-period-status';
import { Timeline } from 'design-components/timeline/timeline';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { useScheduleShifts } from './hooks/use-schedule-shifts';
import { useScheduleOfSupport } from './hooks/use-schedule-of-support';
import { RangeOption } from 'views/sil-service/tabs/schedule/components/range-selector';
import { RosteringDateRangeSelector } from './components/rostering-daterange-selector/rostering-daterange-selector';
import { differenceInDaysNotRounded } from 'utilities/date-utils';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { useUserTimezone } from 'utilities/react-hooks/use-user-timezone';
import { DateRange } from 'views/sil-service/tabs/schedule/components/rostering-daterange-selector';

const endOfFortnight = (date: Date) => endOfDay(addDays(date, 13));

const RANGE_TO_TIMELINE_PROPS = {
  day: {
    calendarUnit: 'hours',
    roundBlocksToNearestUnit: false,
    showCurrentTimeIndicator: true,
  },
  week: {
    calendarUnit: 'days',
    roundBlocksToNearestUnit: true,
    showCurrentTimeIndicator: false,
  },
  fortnight: {
    calendarUnit: 'days',
    roundBlocksToNearestUnit: true,
    showCurrentTimeIndicator: false,
  },
} as const;

export const ScheduleTab = () => {
  const [dateRange, setDateRange] = useState<DateRange>({ start: startOfDay(new Date()), end: endOfDay(new Date()) });

  const [scheduleRange, setScheduleRange] = useState<RangeOption>('day');
  const onScheduleRangeChange = (range: RangeOption) => {
    const differenceInDays = Math.ceil(differenceInDaysNotRounded(dateRange.end, dateRange.start));

    if (range === 'day' && differenceInDays > 1) {
      setDateRange({ start: dateRange.start, end: endOfDay(dateRange.start) });
    }

    if (range === 'week' && (differenceInDays <= 1 || differenceInDays > 7)) {
      setDateRange({ start: dateRange.start, end: endOfDay(addDays(dateRange.start, 6)) });
    }

    if (range === 'fortnight' && differenceInDays !== 14) {
      setDateRange({ start: dateRange.start, end: endOfFortnight(dateRange.start) });
    }

    setScheduleRange(range);
  };

  // TODO need confirmation on roster period status rules
  const rosterPeriodStatus = 'archive';

  const { rows: shiftRows } = useScheduleShifts();

  const { rows: scheduleOfSupportRows } = useScheduleOfSupport();

  const timezone = useUserTimezone(usePortalUser());

  return (
    <div className='py-5'>
      <div className='flex gap-4 pb-5'>
        <RangeSelector range={scheduleRange} onRangeChange={onScheduleRangeChange} />
        <RosteringDateRangeSelector
          dateRange={dateRange}
          onDateRangeChange={setDateRange}
          rangeOption={scheduleRange}
          timezone={timezone}
        />
        <div className='pt-1'>
          <RosterPeriodStatus status={rosterPeriodStatus} />
        </div>
      </div>

      <Timeline
        {...RANGE_TO_TIMELINE_PROPS[scheduleRange]}
        dateRange={dateRange}
        rows={[...scheduleOfSupportRows, ...shiftRows]}
      />
    </div>
  );
};
