import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ErrorSVG } from 'assets/UndrawSVG';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Col, Form, Row, InputNumber, Tooltip, Icon } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import CommonUtils from 'utilities/common-utils';
import moment, { Moment } from 'moment-timezone';
import { ShiftSlotStatus } from 'utilities/enum-utils';
import _ from 'lodash';

interface IBookingUpdateTeamMemberPayHoursModalState {
  canManuallyClose: boolean;
  isLoading: boolean;
  step: number;
  title: string;
  teamMemberPaidHours: number;
  isPaidShift: boolean;
}

interface IBookingUpdateTeamMemberPayHoursModalProps extends FormComponentProps {
  isOpen: boolean;
  selectedBooking: typeof state.bookingsStore.selectedBookingItem;
  onClose: () => void;
  isNextStep: boolean;
  doPayTeamMemberForCancelledShift: typeof dispatch.bookingsStore.doPayTeamMemberForCancelledShift;
  doUpdateTeamMemberShiftHoursForCancelledBooking: typeof dispatch.bookingsStore.doUpdateTeamMemberShiftHoursForCancelledBooking;
}

class BookingUpdateTeamMemberPayHoursModal extends Component<
  IBookingUpdateTeamMemberPayHoursModalProps,
  IBookingUpdateTeamMemberPayHoursModalState
> {
  state = {
    canManuallyClose: true,
    isLoading: false,
    step: 0,
    title: '',
    teamMemberPaidHours: 0,
    isPaidShift: false,
  };

  private _onChangeTeamMemberPay = async () => {
    this.setState({ isLoading: true });
    const { doPayTeamMemberForCancelledShift, doUpdateTeamMemberShiftHoursForCancelledBooking, selectedBooking, form } =
      this.props;
    let payHours = form.getFieldValue('payTeamMemberShiftHours');
    /*expected payload for /shift-slot-hours = shiftshiftSlots:[{supportWorkerId, isPaidShift, shiftHours }]*/
    try {
      await doPayTeamMemberForCancelledShift({
        shiftSlots: [
          {
            supportWorkerId: selectedBooking.workerId,
            isPaidShift: this.state.isPaidShift,
            shiftHours: payHours > 0 || payHours !== undefined ? payHours : 0,
          },
        ],
      });
      if (payHours > 0) {
        const { shiftStartTime, shiftEndTime } = this._calculateTeamMembersShiftTime(
          selectedBooking.startDateTime,
          selectedBooking.timezone,
          payHours,
        );
        doUpdateTeamMemberShiftHoursForCancelledBooking({
          portalCheckedInDateTime: shiftStartTime,
          portalCheckedOutDateTime: shiftEndTime,
        });
      } else {
        doUpdateTeamMemberShiftHoursForCancelledBooking({
          portalCheckedInDateTime: null,
          portalCheckedOutDateTime: null,
        });
      }
      this.setState({
        isLoading: false,
        step: 2,
        teamMemberPaidHours: payHours > 0 ? payHours : 0,
        title: ' Booking Successfully Updated',
      });
    } catch (err) {
      this.setState({ step: 3, canManuallyClose: true });
    }
  };

  private _renderDuration = (startDateTime: Moment, endDateTime: Moment) => {
    const duration = CommonUtils.formatDuration(startDateTime, endDateTime);
    let durationDisplays = [];

    if (duration.days) {
      durationDisplays.push(
        <>
          <b>{duration.days.value}</b> {`${duration.days.label} `}
        </>,
      );
    }
    if (duration.hours) {
      durationDisplays.push(
        <>
          <b>{duration.hours.value}</b> {`${duration.hours.label} `}
        </>,
      );
    }

    if (duration.minutes) {
      durationDisplays.push(
        <>
          <b>{duration.minutes.value}</b> {duration.minutes.label}
        </>,
      );
    }
    return (
      <Text color="tertiary">
        {_.map(durationDisplays, (display) => {
          return display;
        })}
      </Text>
    );
  };

  private _calculateTimeDifference = (startDateTime, endDateTime) => {
    return moment(endDateTime).diff(moment(startDateTime), 'hours', true);
  };

  private _onDoNotPayTeamMember = () => {
    this.setState({ isPaidShift: false }, () => this._onChangeTeamMemberPay());
  };

  private _onUpdateTeamMemberPaidHours = () => {
    const { form } = this.props;
    let isFormValid: boolean = true;
    form.validateFields((error, value) => {
      if (error) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      this.setState({ isPaidShift: true }, () => this._onChangeTeamMemberPay());
    }
  };

  private _calculateTeamMembersShiftTime = (startDateTime, timezone, value) => {
    let addedTime = moment(startDateTime).add(value, 'hours');

    let portalCheckedOutDateTime = moment.tz(addedTime, timezone).toISOString();
    return { shiftStartTime: startDateTime, shiftEndTime: portalCheckedOutDateTime };
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    this._reset();
    onClose();
  };

  private _reset = () =>
    this.setState({
      step: 1,
      title: '',
      canManuallyClose: true,
    });

  private _validateTeamMemberShiftHours = (rule, value, callback) => {
    const { startDateTime, endDateTime } = this.props.selectedBooking;
    const bookingTime: any = this._calculateTimeDifference(moment(startDateTime), moment(endDateTime));
    try {
      if (!value || value <= 0) {
        throw Error('Please enter a value');
      } else if (value > bookingTime) {
        throw Error('You cannot enter a value greater than the scheduled length of the booking');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _renderView = () => {
    const { step } = this.state;
    const { selectedBooking, form } = this.props;
    const { getFieldDecorator } = form;
    const { startDateTime, endDateTime, timezone } = selectedBooking;
    const bookingStartDateTime = moment.tz(startDateTime, timezone).format('hh:mm A , DD MMMM YYYY');
    const bookingEndDateTime = moment.tz(endDateTime, timezone).format('hh:mm A , DD MMMM YYYY');

    if (step === 1) {
      return (
        <>
          <div className="anim-slide-left">
            <Paragraph>How many hours do you want to pay the team member for this cancelled booking</Paragraph>
          </div>
          <div className={'mt-large'}>
            <Text className={'text-color-tertiary text-size-small'}>HOURS TEAM MEMBER IS TO BE PAID</Text>{' '}
            <Tooltip
              title={"Decimal values only. Eg: if 1hour 30minutes needs to be paid, please enter '1.5'"}
              placement="top"
              style={{ width: '239px', height: '52px' }}
            >
              <Icon type="question-circle" className="text-size-x-large text-color-blue ml-x-small" />
            </Tooltip>
          </div>
          <div>
            <Form.Item>
              {getFieldDecorator('payTeamMemberShiftHours', {
                initialValue:
                  selectedBooking.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID
                    ? this.state.teamMemberPaidHours
                    : '',
                rules: [{ validator: this._validateTeamMemberShiftHours }],
              })(<InputNumber style={{ width: '320px', height: '40px' }} placeholder={'Input decimal value...'} />)}
            </Form.Item>
          </div>
          <div className={'mt-large'}>
            <Text className={'text-color-tertiary text-size-small'}>BOOKING SCHEDULED TIME</Text>
          </div>
          <div>
            <Text>
              {bookingStartDateTime} - {bookingEndDateTime}
            </Text>
          </div>
          <div>{this._renderDuration(moment.tz(startDateTime, timezone), moment.tz(endDateTime, timezone))}</div>
          <div className={'mt-large'}>
            <Row type={'flex'}>
              <Col span={6}>
                <SecondaryButton className="mr-medium" size="large" color="red" onClick={this._onDoNotPayTeamMember}>
                  Do not pay team member
                </SecondaryButton>
              </Col>
              <Col span={8} push={10}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onUpdateTeamMemberPaidHours}>
                  Save
                </PrimaryButton>
              </Col>
            </Row>
          </div>
        </>
      );
    }

    if (step === 2 && this.props.isNextStep) {
      return (
        <>
          <div className={'mt-large'}>
            <Text>
              You have successfully indicated that the assigned team member for this shift
              {selectedBooking.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID ? ` needs ` : ` does not need `}
              to be paid {this.state.teamMemberPaidHours > 0 ? <b>{this.state.teamMemberPaidHours} hour(s)</b> : null}
            </Text>
            <Row className={'mt-x2-large'}>
              <Col span={4} push={20}>
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  Close
                </PrimaryButton>
              </Col>
            </Row>
          </div>
        </>
      );
    }

    if (step === 3) {
      return (
        <div>
          <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
            <div className="pv-medium flex-column justify-center">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium flex-column justify-center ">
              <Paragraph>Oops something has gone wrong, please try again</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Back to booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  componentDidMount() {
    let { selectedBooking } = this.props;
    let teamMemberPaidHours: number = 0;
    if (selectedBooking.portalCheckedInDateTime && selectedBooking.portalCheckedOutDateTime) {
      teamMemberPaidHours = this._calculateTimeDifference(
        moment(selectedBooking.portalCheckedInDateTime),
        moment(selectedBooking.portalCheckedOutDateTime),
      );
    }

    this.setState({
      title:
        selectedBooking.shiftSlotStatus !== ShiftSlotStatus.CANCELLED_PAID
          ? 'Pay team member for cancelled booking'
          : 'Edit hours team member is to be paid for this booking',
      step: 1,
      isPaidShift: selectedBooking.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID ? true : false,
      teamMemberPaidHours,
    });
  }

  render() {
    let { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width="large"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        {this._renderView()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doPayTeamMemberForCancelledShift: dispatch.bookingsStore.doPayTeamMemberForCancelledShift,
  doUpdateTeamMemberShiftHoursForCancelledBooking:
    dispatch.bookingsStore.doUpdateTeamMemberShiftHoursForCancelledBooking,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IBookingUpdateTeamMemberPayHoursModalProps>()(BookingUpdateTeamMemberPayHoursModal));
