import { useMemo } from 'react';

export const useElementIds = (idProp: string | undefined, name: string | undefined, label: string) => {
  return useMemo(() => {
    const id = idProp ?? name ?? label.replace(/\s+/g, '-').toLowerCase();
    const labelId = `label-${id}`;
    const errorId = `error-${id}`;
    const descriptionId = `description-${id}`;
    const labelHintId = `label-hint-${id}`;

    return { id, labelId, errorId, descriptionId, labelHintId };
  }, [idProp, name, label]);
};
