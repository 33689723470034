import React, { PureComponent, ReactNode } from 'react';
import { Icon } from 'antd';

interface IErrorBannerProps {
  className?: string;
  icon?: string;
  content: ReactNode;
}

class ErrorBanner extends PureComponent<IErrorBannerProps, any> {
  render() {
    const { className = '', icon = 'exclamation-circle', content } = this.props;
    let finalClassName = 'bordered-left border border-red-dark bg-red-lightest inline-block ';

    finalClassName += className;

    return (
      <div className={finalClassName}>
        <div className="flex p-small">
          <div>
            <Icon type={icon} theme="filled" className="text-size-x2-large text-color-red-dark" />
          </div>
          <div className="ml-small pr-x-large">{content}</div>
        </div>
      </div>
    );
  }
}

export default ErrorBanner;
