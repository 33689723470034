import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ChatCheck = forwardRef(function ChatCheck(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 12A8.25 8.25 0 1 1 12 20.25H4.387c.055-.09.107-.174.155-.256.225-.384.397-.74.484-1.115.063-.27.078-.38.092-.657.02-.405-.082-.886-.233-1.437a62.542 62.542 0 0 0-.693-2.25l-.006-.018c-.257-.805-.436-1.671-.436-2.517Zm-1.5 9c0-.158.049-.304.132-.424.392-.59.67-1.006.866-1.34.196-.336.28-.539.317-.696.025-.11.034-.156.04-.195.005-.04.01-.086.015-.199.007-.14-.03-.413-.181-.964a62.045 62.045 0 0 0-.682-2.208c-.288-.9-.507-1.927-.507-2.974 0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75H3a.75.75 0 0 1-.75-.75Zm13.78-10.97a.75.75 0 1 0-1.06-1.06L11 12.94l-1.47-1.47a.75.75 0 0 0-1.06 1.06l2 2a.75.75 0 0 0 1.06 0l4.5-4.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
