import React, { Component } from 'react';
import { Avatar, Col, Icon, Row } from 'antd';
import { connect } from 'react-redux';
import { Paragraph, SubTitle, Title } from 'common-components/typography';
import { PrimaryButton } from 'common-components/buttons';
import { IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

interface IAddCompletedStepPanelProps {
  closeWizard: () => void;
  goToSession: (serviceDateTimeId) => void;
  customerToSchedule: typeof state.servicesStore.customerToSchedule;
}

class AddCompletedStepPanel extends Component<IAddCompletedStepPanelProps> {
  render() {
    const { customerToSchedule } = this.props;
    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Customer successfully added to sessions.</Title>
              <Paragraph>Nice work!</Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
              <Title level={4}>
                <Icon type={'check-circle'} theme={'filled'} className={'mr-small text-color-blue-action'} />
                Customer successfully added
              </Title>
              <Paragraph>The following customer has been added to the following sessions:</Paragraph>
              <div className={'mt-large'}>
                <SubTitle>Customer</SubTitle>
                <Avatar
                  className={'mr-small'}
                  shape={'circle'}
                  icon={'user'}
                  src={customerToSchedule.selectedCustomer && customerToSchedule.selectedCustomer.attachmentUrl}
                />{' '}
                {customerToSchedule.selectedCustomer && customerToSchedule.selectedCustomer.firstName}{' '}
                {customerToSchedule.selectedCustomer && customerToSchedule.selectedCustomer.lastName}
              </div>
              <div className={'mt-large'}>
                <SubTitle>Sessions</SubTitle>
                {customerToSchedule.selectedCustomer && customerToSchedule.sessions.length} session
                {customerToSchedule.selectedCustomer && customerToSchedule.sessions.length !== 1 && 's'}
              </div>
            </div>
            <div
              className="pv-medium width-full"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <Row gutter={0} type="flex" align="middle" justify={'end'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <PrimaryButton
                    size="large"
                    className={'mr-large'}
                    onClick={() => this.props.goToSession(customerToSchedule.sessions[0].serviceDateTimeId)}
                  >
                    Go to {customerToSchedule.sessions && customerToSchedule.sessions.length > 1 ? 'first ' : ' '}{' '}
                    session
                  </PrimaryButton>
                  <PrimaryButton size="large" onClick={this.props.closeWizard}>
                    Close
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  customerToSchedule: state.servicesStore.customerToSchedule,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateNewSession: dispatch.servicesStore.doCreateNewSession,
});

export default connect(mapState, mapDispatch)(AddCompletedStepPanel);
