import React, { Component } from 'react';
import { ICustomer, ISupportCoordinator } from 'interfaces/customer-interfaces';
import { FieldValueText, SubTitle, Text, Title } from 'common-components/typography';
import { Avatar, Col, Icon, Row, Tabs, Skeleton } from 'antd';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import CustomerFundingPackageListPanel from './CustomerFundingPackageListPanel';
import EditNdisVcpNumberModal from 'views/customers/details/tabs-panel/funding/components/EditNdisVcpNumberModal';
import AddEditPlanManagerModal from 'views/customers/details/tabs-panel/funding/components/AddEditPlanManagerModal';
import { DebtorType, PlanManagerType, ServiceType } from 'utilities/enum-utils';
import RemoveSelfManagerModal from './components/RemoveSelfManagerModal';
import RemovePlanManagerModal from './components/RemovePlanManagerModal';
import RemoveCaseManagerModal from './components/RemoveCaseManagerModal';
import RemoveVCPNumbersModal from './components/RemoveVCPNumbersModal';
import TextTag from 'common-components/tags/TextTag';
import AddEditSelfAndCaseManagerModal from './components/AddEditSelfAndCaseManagerModal';
import * as H from 'history';
import PermissionUtils from 'utilities/permission-utils';
import AddSupportCoordinatorModal from './components/AddSupportCoordinatorModal';
import RemoveSupportCoordinatorModal from './components/RemoveSupportCoordinatorModal';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import CreateNewPackageModal from './components/CreateNewPackageModal';

type ICustomerDetailsFundingProps = {
  history: H.History;
  selectedCustomer: ICustomer;
  hasEditPermission: boolean;
  portalUser: any;
  setSupportCoordinatorFilter: typeof dispatch.customersStore.setSupportCoordinatorFilter;
  doFetchSupportCoordinators: typeof dispatch.customersStore.doFetchSupportCoordinators;
};
type ICustomerDetailsFundingState = {
  isOpenEditNdisVcpNumbers: boolean;
  isOpenAddEditPlanManager: boolean;
  isOpenAddEditSelfAndCaseManager: boolean;
  isOpenRemoveSelfManagerModal: boolean;
  isOpenRemovePlanManagerModal: boolean;
  isOpenRemoveCaseManagerModal: boolean;
  planManagerType: string;
  isEditModal: boolean;
  isLoading: boolean;
  isSaving: boolean;
  debtorType: DebtorType;
  numberType: string;
  isOpenRemoveVCPNumbers: boolean;
  activeTab: string;
  isOpenAddSupportCoordinator: boolean;
  isOpenRemoveSupportCoordinatorModal: boolean;
  selectedSupportCoordinator: ISupportCoordinator;
};

class CustomerDetailsFundingPanel extends Component<ICustomerDetailsFundingProps, ICustomerDetailsFundingState> {
  state = {
    isOpenEditNdisVcpNumbers: false,
    isOpenAddEditPlanManager: false,
    isOpenAddEditSelfAndCaseManager: false,
    isOpenRemoveSelfManagerModal: false,
    isOpenRemovePlanManagerModal: false,
    isOpenRemoveCaseManagerModal: false,
    planManagerType: '',
    isEditModal: false,
    isLoading: false,
    isSaving: false,
    debtorType: null,
    numberType: null,
    isOpenRemoveVCPNumbers: false,
    activeTab: this.props.history?.location?.state?.selectedChildTab ?? 'NDIS',
    isOpenAddSupportCoordinator: false,
    isOpenRemoveSupportCoordinatorModal: false,
    selectedSupportCoordinator: null,
  };

  private _showFundEditForm = (numberType) => {
    this.setState({ isOpenEditNdisVcpNumbers: true, numberType });
  };

  private _showAddEditPlanManager = (isEditModal = false) => {
    this.setState({ isOpenAddEditPlanManager: true, isEditModal });
  };

  private _showAddEditSelfManager = (isEditModal = false) => {
    this.setState({ isOpenAddEditSelfAndCaseManager: true, isEditModal, debtorType: DebtorType.SELF });
  };

  private _showAddEditCaseManager = (isEditModal = false) => {
    this.setState({ isOpenAddEditSelfAndCaseManager: true, isEditModal, debtorType: DebtorType.VCP });
  };

  private _showRemoveSelfManager = (isEditModal = false) => {
    this.setState({ isOpenRemoveSelfManagerModal: true, isEditModal });
  };

  private _showRemovePlanManager = (planManagerType) => {
    this.setState({ planManagerType: planManagerType, isOpenRemovePlanManagerModal: true });
  };

  private _showRemoveVCPNumbers = (numberType) => {
    this.setState({ numberType: numberType, isOpenRemoveVCPNumbers: true });
  };

  private _showRemoveCaseManager = () => {
    this.setState({ isOpenRemoveCaseManagerModal: true });
  };

  private _closeEditNDISModal = () => {
    this.setState({ isOpenEditNdisVcpNumbers: false });
  };

  private _closeAddEditPlanManagerModal = () => {
    this.setState({ isOpenAddEditPlanManager: false });
  };

  private _closeAddEditSelfManagerModal = () => {
    this.setState({ isOpenAddEditSelfAndCaseManager: false });
  };

  private _closeRemoveSelfManagerModal = () => {
    this.setState({ isOpenRemoveSelfManagerModal: false });
  };

  private _closeRemovePlanManagerModal = () => {
    this.setState({ isOpenRemovePlanManagerModal: false });
  };

  private _closeRemoveVCPNumbersModal = () => {
    this.setState({ isOpenRemoveVCPNumbers: false });
  };

  private _closeRemoveCaseManagerModal = () => {
    this.setState({ isOpenRemoveCaseManagerModal: false });
  };

  private _goDebtorHomepage = (id) => {
    const { history } = this.props;
    history.push(`/account/debtor-management/${id}`);
  };

  private _handleChangeTab = (tab) => {
    this.setState({ activeTab: tab });
    const { history } = this.props;
    if (history.location && history.location.state && history.location.state.selectedChildTab) {
      const state = { ...history.location.state };
      delete state.selectedChildTab;
      history.replace({ ...history.location, state });
    }
  };

  private _handleRemoveCoordinator = (coordinator) => {
    this.setState({
      ...this.state,
      selectedSupportCoordinator: coordinator,
      isOpenRemoveSupportCoordinatorModal: true,
    });
  };

  private _goToSupportCoordinatorDetail = (teamMemberId) => {
    const { history } = this.props;

    history.push(`/team/details/${teamMemberId}`);
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.props.setSupportCoordinatorFilter({ supportWorkerServiceType: ServiceType.COORDINATION });
    await this.props.doFetchSupportCoordinators({});
    this.setState({ isLoading: false });
  }

  async componentDidUpdate(prevProps) {
    const { history } = this.props;
    if (
      history.location &&
      history.location.state &&
      history.location.state.selectedChildTab &&
      history.location.state.selectedChildTab !== this.state.activeTab
    ) {
      this.setState({ activeTab: history.location.state.selectedChildTab });
    }
  }

  render() {
    const { selectedCustomer, hasEditPermission, portalUser } = this.props;
    const { isLoading } = this.state;
    const funding = selectedCustomer.funding;
    const managerFieldWidth = 5;
    const managerValueWidth = 19;

    const hasEditDebtorPermission = PermissionUtils.validatePermission(
      'EditCustomerDebtor',
      portalUser.permissions.permissionRoles,
    );

    return (
      <>
        <CreateNewPackageModal selectedCustomer={selectedCustomer} />
        {isLoading ? (
          <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className='anim-slide-left' />
        ) : (
          <>
            <EditNdisVcpNumberModal
              selectedCustomer={selectedCustomer}
              numberType={this.state.numberType}
              isOpen={this.state.isOpenEditNdisVcpNumbers}
              closeModal={this._closeEditNDISModal}
            />
            <AddEditPlanManagerModal
              selectedCustomer={selectedCustomer}
              isOpen={this.state.isOpenAddEditPlanManager}
              isEdit={this.state.isEditModal}
              closeModal={this._closeAddEditPlanManagerModal}
              goDebtorHomepage={this._goDebtorHomepage}
            />
            <AddEditSelfAndCaseManagerModal
              selectedCustomer={selectedCustomer}
              isOpen={this.state.isOpenAddEditSelfAndCaseManager}
              isEdit={this.state.isEditModal}
              closeModal={this._closeAddEditSelfManagerModal}
              debtorType={this.state.debtorType}
              goDebtorHomepage={this._goDebtorHomepage}
            />
            <AddSupportCoordinatorModal
              selectedCustomer={selectedCustomer}
              isOpen={this.state.isOpenAddSupportCoordinator}
              closeModal={() => this.setState({ isOpenAddSupportCoordinator: false })}
              supportCoordinatorExisted={selectedCustomer.supportCoordinator}
            />
            <RemoveSelfManagerModal
              isOpen={this.state.isOpenRemoveSelfManagerModal}
              closeRemoveSelfManagerModal={this._closeRemoveSelfManagerModal}
            />
            <RemovePlanManagerModal
              isOpen={this.state.isOpenRemovePlanManagerModal}
              closeRemovePlanManagerModal={this._closeRemovePlanManagerModal}
              planManagerType={
                this.props.selectedCustomer.planManager &&
                this.props.selectedCustomer.planManager.type === DebtorType.EXTERNAL_PLAN
                  ? PlanManagerType.EXTERNAL
                  : PlanManagerType.INTERNAL
              }
            />
            <RemoveVCPNumbersModal
              isOpen={this.state.isOpenRemoveVCPNumbers}
              closeRemoveVCPNumbersModal={this._closeRemoveVCPNumbersModal}
              numberType={this.state.numberType}
            />
            <RemoveCaseManagerModal
              isOpen={this.state.isOpenRemoveCaseManagerModal}
              closeRemoveCaseManagerModal={this._closeRemoveCaseManagerModal}
            />

            <RemoveSupportCoordinatorModal
              isOpen={this.state.isOpenRemoveSupportCoordinatorModal}
              closeModal={() => this.setState({ isOpenRemoveSupportCoordinatorModal: false })}
              supportCoordinator={this.state.selectedSupportCoordinator}
              customerUserId={selectedCustomer.userId}
            />

            <Row type='flex'>
              <Col>
                <Title level={2} className='m-none'>
                  Funding and goals
                </Title>
                <Text type='secondary'>
                  Add funding packages, benefits and goals for this customer in order to track their budgets and
                  progress over the course of their plan.
                </Text>
              </Col>
            </Row>
            <Tabs activeKey={this.state.activeTab} onChange={this._handleChangeTab}>
              <Tabs.TabPane tab='NDIS' key='NDIS'>
                <div className='mb-large'>
                  <Text weight='bold'>NDIS Number</Text>
                  <FieldValueText
                    className='text-color-secondary'
                    text={funding.NDISNumber !== null && funding.NDISNumber !== '' ? funding.NDISNumber : 'Not Set'}
                  />
                  <HyperlinkButton onClick={() => this._showFundEditForm('ndis')}>Edit...</HyperlinkButton>
                </div>
                <div className='mb-large'>
                  <div>
                    <Text className='mv-medium' weight='bold'>
                      Plan Manager details
                    </Text>
                  </div>
                  {selectedCustomer.planManager ? (
                    <div>
                      {selectedCustomer.planManager.type === DebtorType.EXTERNAL_PLAN && (
                        <Row className='mv-medium' type='flex' align='middle'>
                          <Col span={managerFieldWidth}>
                            <SubTitle>Name</SubTitle>
                          </Col>
                          <Col span={managerValueWidth}>
                            <HyperlinkButton
                              onClick={() => this._goDebtorHomepage(selectedCustomer.planManager.debtorId)}
                            >
                              {selectedCustomer.planManager.name}
                            </HyperlinkButton>
                          </Col>
                        </Row>
                      )}
                      <Row className='mv-medium' type='flex' align='middle'>
                        <Col span={managerFieldWidth}>
                          <SubTitle>Type</SubTitle>
                        </Col>
                        <Col span={managerValueWidth}>
                          <TextTag
                            theme='light'
                            color='green'
                            content={
                              selectedCustomer.planManager.type === DebtorType.INTERNAL_PLAN
                                ? 'Internal plan manager'
                                : 'External plan manager'
                            }
                          />
                        </Col>
                      </Row>
                      {selectedCustomer.planManager.type === DebtorType.EXTERNAL_PLAN && (
                        <>
                          <Row className='mv-medium' type='flex' align='middle'>
                            <Col span={managerFieldWidth}>
                              <SubTitle>Organisation</SubTitle>
                            </Col>
                            <Col span={managerValueWidth}>
                              <Text>
                                {selectedCustomer.planManager.organisationName ? (
                                  selectedCustomer.planManager.organisationName
                                ) : (
                                  <Text color='secondary'>Not set.</Text>
                                )}
                              </Text>
                            </Col>
                          </Row>
                          <Row className='mv-medium' type='flex' align='middle'>
                            <Col span={managerFieldWidth}>
                              <SubTitle>Plan Manager ID</SubTitle>
                            </Col>
                            <Col span={managerValueWidth}>
                              <Text>
                                {selectedCustomer.planManager.debtorNumber ? (
                                  selectedCustomer.planManager.debtorNumber
                                ) : (
                                  <Text color='secondary'>Not set.</Text>
                                )}
                              </Text>
                            </Col>
                          </Row>
                          <Row className='mv-medium' type='flex' align='middle'>
                            <Col span={managerFieldWidth}>
                              <SubTitle>Email</SubTitle>
                            </Col>
                            <Col span={managerValueWidth}>
                              <Text>
                                <Icon type='mail' className='mr-x-small' />
                                {selectedCustomer.planManager.email ? (
                                  selectedCustomer.planManager.email
                                ) : (
                                  <Text color='secondary'>Not set.</Text>
                                )}
                              </Text>
                            </Col>
                          </Row>
                          <Row className='mv-medium'>
                            <Col span={managerFieldWidth}>
                              <SubTitle>Number</SubTitle>
                            </Col>
                            <Col span={managerValueWidth}>
                              <Text>
                                <Icon type='phone' className='mr-x-small' />
                                {selectedCustomer.planManager.contactNumber ? (
                                  selectedCustomer.planManager.contactNumber
                                ) : (
                                  <Text color='secondary'>Not set.</Text>
                                )}
                              </Text>
                            </Col>
                          </Row>
                        </>
                      )}
                      {hasEditDebtorPermission && (
                        <Row className='mv-medium'>
                          {/* {selectedCustomer.planManager.type === DebtorType.EXTERNAL_PLAN && (
                        <Col span={managerFieldWidth}>
                          <HyperlinkButton onClick={() => this._showAddEditPlanManager(true)}>Edit...</HyperlinkButton>
                        </Col>
                      )} */}
                          <Col span={managerValueWidth}>
                            <HyperlinkButton
                              color='red'
                              onClick={() => this._showRemovePlanManager(selectedCustomer.planManager.type)}
                            >
                              Remove...
                            </HyperlinkButton>
                          </Col>
                        </Row>
                      )}
                    </div>
                  ) : (
                    hasEditDebtorPermission && (
                      <PrimaryButton
                        className='mt-medium'
                        size='large'
                        icon='plus'
                        onClick={() => this._showAddEditPlanManager()}
                      >
                        Add plan manager
                      </PrimaryButton>
                    )
                  )}
                </div>
                <div className='mb-large'>
                  <div>
                    <Text weight='bold'>Self Manager details</Text>
                  </div>
                  {selectedCustomer.selfManager ? (
                    <>
                      <div>
                        <Row className='mv-medium' type='flex' align='middle'>
                          <Col span={managerFieldWidth}>
                            <SubTitle>Name</SubTitle>
                          </Col>
                          <Col span={managerValueWidth}>
                            <HyperlinkButton
                              onClick={() => this._goDebtorHomepage(selectedCustomer.selfManager.debtorId)}
                            >
                              {selectedCustomer.selfManager.name}
                            </HyperlinkButton>
                          </Col>
                        </Row>
                        <Row className='mv-medium' type='flex' align='middle'>
                          <Col span={managerFieldWidth}>
                            <SubTitle>Organisation</SubTitle>
                          </Col>
                          <Col span={managerValueWidth}>
                            <Text>
                              {selectedCustomer.selfManager.organisationName ? (
                                selectedCustomer.selfManager.organisationName
                              ) : (
                                <Text color='secondary'>Not set.</Text>
                              )}
                            </Text>
                          </Col>
                        </Row>
                        <Row className='mv-medium' type='flex' align='middle'>
                          <Col span={managerFieldWidth}>
                            <SubTitle>Self Manager ID</SubTitle>
                          </Col>
                          <Col span={managerValueWidth}>
                            <Text>
                              {selectedCustomer.selfManager.debtorNumber ? (
                                selectedCustomer.selfManager.debtorNumber
                              ) : (
                                <Text color='secondary'>Not set.</Text>
                              )}
                            </Text>
                          </Col>
                        </Row>
                        <Row className='mv-medium' type='flex' align='middle'>
                          <Col span={managerFieldWidth}>
                            <SubTitle>Email</SubTitle>
                          </Col>
                          <Col span={managerValueWidth}>
                            <Text>
                              <Icon type='mail' className='mr-x-small' />
                              {selectedCustomer.selfManager.email ? (
                                selectedCustomer.selfManager.email
                              ) : (
                                <Text color='secondary'>Not set.</Text>
                              )}
                            </Text>
                          </Col>
                        </Row>
                        <Row className='mv-medium' type='flex' align='middle'>
                          <Col span={managerFieldWidth}>
                            <SubTitle>Number</SubTitle>
                          </Col>
                          <Col span={managerValueWidth}>
                            <Text>
                              <Icon type='phone' className='mr-x-small' />
                              {selectedCustomer.selfManager.contactNumber ? (
                                selectedCustomer.selfManager.contactNumber
                              ) : (
                                <Text color='secondary'>Not set.</Text>
                              )}
                            </Text>
                          </Col>
                        </Row>
                      </div>
                      {hasEditDebtorPermission && (
                        <Row className='mv-medium' type='flex' align='middle'>
                          {/* <Col span={managerFieldWidth}>
                        <HyperlinkButton onClick={() => this._showAddEditSelfManager(true)}>Edit...</HyperlinkButton>
                      </Col> */}
                          <Col span={managerValueWidth}>
                            <HyperlinkButton color='red' onClick={() => this._showRemoveSelfManager()}>
                              Remove...
                            </HyperlinkButton>
                          </Col>
                        </Row>
                      )}
                    </>
                  ) : (
                    hasEditDebtorPermission && (
                      <PrimaryButton
                        className='mt-medium'
                        size='large'
                        icon='plus'
                        onClick={() => this._showAddEditSelfManager(false)}
                      >
                        Add self manager
                      </PrimaryButton>
                    )
                  )}
                </div>
                <div className='mb-large'>
                  <div>
                    <Text weight='bold'>Support Coordinator</Text>
                  </div>
                  {selectedCustomer.supportCoordinator?.length ? (
                    <>
                      {selectedCustomer.supportCoordinator.map((supportCoordinator) => (
                        <Row className='mv-medium align-center flex' key={supportCoordinator.supportCoordinatorId}>
                          <Col span={4} className='align-center flex'>
                            <Avatar
                              className='mr-small'
                              size='default'
                              icon='user'
                              src={supportCoordinator.attachmentUrl}
                              style={{ minWidth: 32 }}
                            />
                            <HyperlinkButton
                              className='text-overflow-ellipsis overflow-hidden whitespace-nowrap'
                              onClick={() =>
                                this._goToSupportCoordinatorDetail(supportCoordinator.supportCoordinatorId)
                              }
                            >
                              {supportCoordinator.fullName}
                            </HyperlinkButton>
                          </Col>
                          <Col className='ml-small'>
                            <HyperlinkButton
                              color='red'
                              onClick={() => this._handleRemoveCoordinator(supportCoordinator)}
                            >
                              Remove...
                            </HyperlinkButton>
                          </Col>
                        </Row>
                      ))}

                      <Row className='mv-medium'>
                        <Col span={2}>
                          <HyperlinkButton onClick={() => this.setState({ isOpenAddSupportCoordinator: true })}>
                            + Add...
                          </HyperlinkButton>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <PrimaryButton
                      className='mt-medium'
                      size='large'
                      icon='plus'
                      onClick={() => this.setState({ isOpenAddSupportCoordinator: true })}
                    >
                      Add a support coordinator
                    </PrimaryButton>
                  )}
                </div>
                <div>
                  <Text weight='black' size='x-large'>
                    NDIS funding packages
                  </Text>
                </div>
                <div className='mt-small'>
                  <CustomerFundingPackageListPanel
                    selectedCustomer={selectedCustomer}
                    hasEditPermission={hasEditPermission}
                  />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab='VCP' key='VCP'>
                <div className='ml-x2-large mb-large'>
                  <div className='mb-large'>
                    <SubTitle>Claim Number</SubTitle>
                    <FieldValueText
                      className='text-color-secondary'
                      text={
                        funding.claimNumber !== null && funding.claimNumber !== '' ? funding.claimNumber : 'Not Set'
                      }
                    />
                    <HyperlinkButton onClick={() => this._showFundEditForm('claim')}>Edit...</HyperlinkButton>
                    {funding.claimNumber !== null && funding.claimNumber !== '' && (
                      <HyperlinkButton
                        className='ml-medium'
                        color='red'
                        onClick={() => this._showRemoveVCPNumbers('claim')}
                      >
                        Remove...
                      </HyperlinkButton>
                    )}
                  </div>
                  <div className='mb-large'>
                    <SubTitle>Approval Number</SubTitle>
                    <FieldValueText
                      className='text-color-secondary'
                      text={
                        funding.approvalNumber !== null && funding.approvalNumber !== ''
                          ? funding.approvalNumber
                          : 'Not Set'
                      }
                    />
                    <HyperlinkButton onClick={() => this._showFundEditForm('approval')}>Edit...</HyperlinkButton>
                    {funding.approvalNumber !== null && funding.approvalNumber !== '' && (
                      <HyperlinkButton
                        className='ml-medium'
                        color='red'
                        onClick={() => this._showRemoveVCPNumbers('approval')}
                      >
                        Remove...
                      </HyperlinkButton>
                    )}
                  </div>

                  <div className='mb-large'>
                    <SubTitle weight='bold'>Participant Number</SubTitle>
                    <FieldValueText
                      className='text-color-secondary'
                      text={
                        funding.participantNumber !== null && funding.participantNumber !== ''
                          ? funding.participantNumber
                          : 'Not Set'
                      }
                    />
                    <HyperlinkButton onClick={() => this._showFundEditForm('participant')}>Edit...</HyperlinkButton>
                    {funding.participantNumber !== null && funding.participantNumber !== '' && (
                      <HyperlinkButton
                        className='ml-medium'
                        color='red'
                        onClick={() => this._showRemoveVCPNumbers('participant')}
                      >
                        Remove...
                      </HyperlinkButton>
                    )}
                  </div>

                  <div>
                    <Text weight='bold'>Case manager details</Text>
                  </div>
                  {selectedCustomer.vcpManager ? (
                    <>
                      <div>
                        <Row className='mv-medium' type='flex' align='middle'>
                          <Col span={managerFieldWidth}>
                            <SubTitle>Name</SubTitle>
                          </Col>
                          <Col span={managerValueWidth}>
                            <HyperlinkButton
                              onClick={() => this._goDebtorHomepage(selectedCustomer.vcpManager.debtorId)}
                            >
                              {selectedCustomer.vcpManager.name}
                            </HyperlinkButton>
                          </Col>
                        </Row>
                        <Row className='mv-medium' type='flex' align='middle'>
                          <Col span={managerFieldWidth}>
                            <SubTitle>Organisation</SubTitle>
                          </Col>
                          <Col span={managerValueWidth}>
                            <Text>
                              {selectedCustomer.vcpManager.organisationName ? (
                                selectedCustomer.vcpManager.organisationName
                              ) : (
                                <Text color='secondary'>Not set.</Text>
                              )}
                            </Text>
                          </Col>
                        </Row>
                        <Row className='mv-medium' type='flex' align='middle'>
                          <Col span={managerFieldWidth}>
                            <SubTitle>Case Manager ID</SubTitle>
                          </Col>
                          <Col span={managerValueWidth}>
                            <Text>
                              {selectedCustomer.vcpManager.debtorNumber ? (
                                selectedCustomer.vcpManager.debtorNumber
                              ) : (
                                <Text color='secondary'>Not set.</Text>
                              )}
                            </Text>
                          </Col>
                        </Row>
                        <Row className='mv-medium' type='flex' align='middle'>
                          <Col span={managerFieldWidth}>
                            <SubTitle>Email</SubTitle>
                          </Col>
                          <Col span={managerValueWidth}>
                            <Text>
                              <Icon type='mail' className='mr-x-small' />
                              {selectedCustomer.vcpManager.email ? (
                                selectedCustomer.vcpManager.email
                              ) : (
                                <Text color='secondary'>Not set.</Text>
                              )}
                            </Text>
                          </Col>
                        </Row>

                        <Row className='mv-medium' type='flex' align='middle'>
                          <Col span={managerFieldWidth}>
                            <SubTitle>Number</SubTitle>
                          </Col>
                          <Col span={managerValueWidth}>
                            <Text>
                              <Icon type='phone' className='mr-x-small' />
                              {selectedCustomer.vcpManager.contactNumber ? (
                                selectedCustomer.vcpManager.contactNumber
                              ) : (
                                <Text color='secondary'>Not set.</Text>
                              )}
                            </Text>
                          </Col>
                        </Row>
                      </div>
                      {hasEditDebtorPermission && (
                        <Row className='mv-medium' type='flex' align='middle'>
                          <Col span={managerFieldWidth}>
                            {/* <HyperlinkButton onClick={() => this._showAddEditCaseManager(true)}>Edit...</HyperlinkButton> */}
                          </Col>
                          <Col span={managerValueWidth}>
                            <HyperlinkButton color='red' onClick={() => this._showRemoveCaseManager()}>
                              Remove...
                            </HyperlinkButton>
                          </Col>
                        </Row>
                      )}
                    </>
                  ) : (
                    hasEditDebtorPermission && (
                      <PrimaryButton
                        className='mt-medium'
                        size='large'
                        icon='plus'
                        onClick={() => this._showAddEditCaseManager(false)}
                      >
                        Add case manager details
                      </PrimaryButton>
                    )
                  )}
                </div>
              </Tabs.TabPane>
            </Tabs>
          </>
        )}
      </>
    );
  }
}

const mapState = () => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSupportCoordinators: dispatch.customersStore.doFetchSupportCoordinators,
  setSupportCoordinatorFilter: dispatch.customersStore.setSupportCoordinatorFilter,
});

export default connect(mapState, mapDispatch)(CustomerDetailsFundingPanel);
