/* BOOM-475 - fix linting errors */
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Icon } from 'antd';

import type { BoxStyleProps, HTMLProps } from '..';
import { boxStyleProps, compose, css, useStyleProps } from '..';
import * as styles from './medias.css';
import { MediasVariants } from './medias.css';
import { User } from '@good/icons';

type EMediasSizes =
  | 'x2small'
  | 'xsmall'
  | 'small'
  | 'default'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'x2large'
  | 'x3large'
  | 'x4large';

export type MediasProps = PropsWithChildren<BoxStyleProps & HTMLProps<HTMLDivElement>> &
  MediasVariants & {
    imageProps?: HTMLProps<HTMLImageElement>;
    stroked?: boolean;
    strokeSizes?: EMediasSizes;
    src?: string;
    shape?: 'circle' | 'square';
    size?: EMediasSizes | number;
    icon?: React.ReactNode | string;
    className?: string;
    style?: React.CSSProperties;
    alt?: string;
    onChangeImage?: () => void;
  };

/**
 * @name
 * Medias
 *
 * @description
 * The Medias element is a visual of image
 *
 * @example
 *   <Medias src={workflow.avatar} />
 *
 * @deprecated to be removed in BOOM-474
 */

// eslint-disable-next-line react/display-name
const Medias = (props: MediasProps) => {
  const {
    imageProps,
    src,
    shape = 'circle',
    size = 'default',
    icon = 'user',
    className,
    style,
    stroked = true,
    strokeSizes,
    alt,
    onChangeImage,
    ...passProps
  } = props;
  const { styleProps: containerStylesProps } = useStyleProps(passProps, boxStyleProps);
  const { styleProps: imageStylesProps } = useStyleProps(imageProps, boxStyleProps);

  const [isError, setIsError] = useState<boolean>(false);
  const containerRef = useRef(null);

  const defaultSize = typeof size === 'number' && size;
  let iconComp: React.ReactNode = typeof icon === 'string' ? <Icon type={icon} /> : icon;
  if (icon === 'user') {
    iconComp = <User className=" h-1/2 w-full" />;
  }

  const renderCustomStyle = () => {
    let customerStyle = {};
    if (style?.borderRadius) {
      customerStyle = {
        ...customerStyle,
        $$mediaBorderRadiusSquare: `${style?.borderRadius} !important`,
      };
    }

    if (style?.fontSize) return customerStyle;
  };

  useEffect(() => {
    onChangeImage?.();
    if (src) {
      setIsError(false);
    }
  }, [src]);

  return (
    <span
      ref={containerRef}
      className={`${compose(
        styles.variants({
          strokeSizes,
          shape,
          stroked,
          size,
        }),
        css(renderCustomStyle),
        css(containerStylesProps),
      )} ${className}`}
      style={{
        width: defaultSize,
        height: defaultSize,
        fontSize: '2rem',
        ...style,
      }}
    >
      {isError ? (
        <div className="flex h-full w-full items-center justify-center bg-gray-light-2 text-gray-dark-1">
          {iconComp}
        </div>
      ) : (
        <img
          onError={(e) => {
            setIsError(true);
            imageProps?.onError(e);
          }}
          onLoad={(e) => {
            setIsError(false);
            imageProps?.onLoad(e);
          }}
          src={src || ''}
          className={compose(css(styles.mediasImage), css(imageStylesProps))}
          style={{
            width: defaultSize,
            height: defaultSize,
          }}
          alt={alt}
        />
      )}
    </span>
  );
};

export default Medias;
