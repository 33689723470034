import React from 'react';
import { useTranslation } from 'react-i18next';

import { Title, Text } from '@good/ui/core';

export const SilNotFoundTab = () => {
  const { t } = useTranslation('', { keyPrefix: 'silService.notFoundTab' });

  return (
    <div className='flex flex-col align-center gap-2 my-8'>
      <Title order={3}>{t('title')}</Title>
      <Text>{t('description')}</Text>
    </div>
  );
};
