import { useCallback, useEffect, useRef, useState } from 'react';

// Should eventually use a common hooks library like react-use later on.
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useStateCallback = (initialState?: any) => {
  //add by dkamphuoc 01/08/2022
  const [state, setState] = useState({
    data: initialState,
  });
  const cbRef = useRef(null);

  const setStateCallback = useCallback((data: any, cb?: () => void) => {
    cbRef.current = cb;
    setState({
      data: data,
    });
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current != null && cbRef.current(state.data);
      cbRef.current = null;
    }
  }, [state]);

  return [state.data, setStateCallback];
};
