import React, { useState } from 'react';
import _ from 'lodash';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { Col, Row, notification, Checkbox, Avatar } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { Tab, Tabs } from '@blueprintjs/core';
import { connect } from 'react-redux';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { IAttendanceForm, IAttendanceFormUser } from 'interfaces/booking-interfaces';
import { IRootDispatch, dispatch, IRootState, state } from 'stores/rematch/root-store';
import { Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { withRouter } from 'utilities/with-router';

import type { WithRouterProps } from 'utilities/with-router';

interface IRenderCustomerList {
  selectedBookingItem: any;
  involvedCustomer: IAttendanceFormUser;
  onChange: (checkedValues: CheckboxValueType[]) => void;
}
const RenderCustomerList: React.FC<IRenderCustomerList> = ({ selectedBookingItem, involvedCustomer, onChange }) => {
  const { bookerUserId, firstName, lastName, attachmentUrl } = selectedBookingItem;

  return (
    <div className="border-top">
      <Checkbox.Group onChange={onChange} defaultValue={involvedCustomer ? [involvedCustomer.userId] : []}>
        <Paragraph>
          <Checkbox className="mr-small" value={bookerUserId}>
            <Avatar className="mr-medium" size="default" icon="user" src={attachmentUrl} />
            <Text>{`${firstName} ${lastName}`}</Text>
          </Checkbox>
        </Paragraph>
      </Checkbox.Group>
    </div>
  );
};

interface IRenderTeamMemberList {
  selectedBookingItem: any;
  involvedMembers: IAttendanceFormUser[];
  onChange: (checkedValues: CheckboxValueType[]) => void;
}

const RenderTeamMemberList = ({ selectedBookingItem, involvedMembers, onChange }: IRenderTeamMemberList) => {
  const { workerUserId, workerFirstName, workerLastName, workerAttachmentUrl } = selectedBookingItem;

  return (
    <div className="border-top">
      <Checkbox.Group onChange={onChange} defaultValue={_.map(involvedMembers, (member) => member.userId)}>
        {!_.isEmpty(workerUserId) && (
          <Paragraph>
            <Checkbox className="mr-small" value={workerUserId}>
              <Avatar className="mr-medium" size="default" icon="user" src={workerAttachmentUrl} />
              <Text>{`${workerFirstName} ${workerLastName}`}</Text>
            </Checkbox>
          </Paragraph>
        )}
        {_.map(involvedMembers, (member) => {
          if (member.userId !== workerUserId) {
            return (
              <Paragraph key={member.userId}>
                <Checkbox className="mr-small" value={member.userId}>
                  <Avatar className="mr-medium" size="default" icon="user" src={member.attachmentUrl} />
                  <Text>{`${member.userName}`}</Text>
                  {member.isNotPartOfBooking && <Text color="secondary"> {` (No longer part of booking)`}</Text>}
                </Checkbox>
              </Paragraph>
            );
          }
        })}
        {_.isEmpty(workerUserId) && _.isEmpty(involvedMembers) && (
          <Text color="secondary">No team member in this booking</Text>
        )}
      </Checkbox.Group>
    </div>
  );
};

interface IEditTeamMemberCustomerUrlParams {
  bookingId: string;
}

interface IEditCustomersTeamMembersModalProps
  extends RouteComponentProps<IEditTeamMemberCustomerUrlParams>,
    WithRouterProps {
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  attendanceForm: IAttendanceForm;
  isOpen: boolean;
  onClose: () => void;
  doEditTeamMemberCustomerForm: typeof dispatch.bookingsStore.doEditTeamMemberCustomerForm;
}

const EditCustomersTeamMembersModal = ({
  selectedBookingItem,
  isOpen,
  onClose,
  doEditTeamMemberCustomerForm,
  attendanceForm,
  ...rest
}: IEditCustomersTeamMembersModalProps) => {
  const [selectedTabId, setSelectedTabId] = useState<string>('customers');
  const [involvedMemberIds, setInvolvedMemberIds] = useState<string[]>(
    _.map(attendanceForm.involvedMembers, (member) => member.userId),
  );
  const [involvedCustomerId, setInvolvedCustomerId] = useState<string>(
    attendanceForm.involvedCustomer ? attendanceForm.involvedCustomer.userId : null,
  );

  const _onChangeInvolveMembers = (checkValues) => {
    setInvolvedMemberIds(checkValues);
  };

  const _onChangeCustomer = (checkValues) => {
    setInvolvedCustomerId(checkValues.length ? checkValues[0] : null);
  };

  const _onSave = () => {
    try {
      const { bookingId } = rest.match.params;
      doEditTeamMemberCustomerForm({
        bookingId,
        attendanceFormId: attendanceForm.attendanceFormId,
        involvedCustomerId,
        involvedMemberIds,
      });
      onClose();
      notification.open({
        message: <span className={'text-weight-bolder'}>Linked profiles updated</span>,
        description: (
          <div>
            You have successfully updated the team <br />
            members and customers linked to this form.
          </div>
        ),
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  return (
    <>
      <ActionModal isOpen={isOpen} title={'Link people to this form'} onClose={onClose}>
        <Paragraph>
          Select the customer or team member profiles you’d like to link to this form, or continue without selecting
          any.
        </Paragraph>

        <Tabs
          large
          animate
          id="team-member-tabs"
          selectedTabId={selectedTabId}
          onChange={(tabId: string) => setSelectedTabId(tabId)}
          className="pv-medium"
        >
          <Tab
            id="customers"
            title="Customers"
            panel={
              <RenderCustomerList
                selectedBookingItem={selectedBookingItem}
                involvedCustomer={attendanceForm.involvedCustomer}
                onChange={_onChangeCustomer}
              />
            }
          />
          <Tab
            id="team"
            title="Team members"
            panel={
              <RenderTeamMemberList
                selectedBookingItem={selectedBookingItem}
                involvedMembers={attendanceForm.involvedMembers}
                onChange={_onChangeInvolveMembers}
              />
            }
          />
        </Tabs>

        <ActionModalFooter className={'mt-large'}>
          <Row type={'flex'} className={'justify-end'}>
            <Col className="mr-medium">
              <SecondaryButton size="large" onClick={onClose}>
                Cancel
              </SecondaryButton>
            </Col>
            <Col>
              <PrimaryButton onClick={_onSave} size="large">
                Save
              </PrimaryButton>
            </Col>
          </Row>
        </ActionModalFooter>
      </ActionModal>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  selectedBookingItem: state.bookingsStore.selectedBookingItem,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doEditTeamMemberCustomerForm: dispatch.bookingsStore.doEditTeamMemberCustomerForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditCustomersTeamMembersModal));
