import type { CSS } from '../../theme';

export const reset: CSS = {
  boxSizing: 'border-box',
  outline: 'none',
};

export const modalHeader: CSS = {
  height: '76px',

  position: 'relative',
  display: 'flex',
  fontFamily: '$primary',
  fontSize: '$font75',
  alignItems: 'center',

  padding: '$space200 $space300',

  borderTopLeftRadius: '$xsmall',
  borderTopRightRadius: '$xsmall',
  borderBottom: 'none',
};
