// Note : this component is obsolete. Use the Action Modal instead.
// Standard Modal shortcut with some default options.
// See ant.design documentation for more info.

import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import React, { Component } from 'react';

interface StandardModalProps extends ModalProps {}

class ObsoleteStandardModal extends Component<StandardModalProps, any> {
  render() {
    const { children, width = '60vw', ...props } = this.props;
    return (
      <Modal centered maskClosable={false} keyboard={true} width={width} {...props}>
        {this.props.children}
      </Modal>
    );
  }
}

export default ObsoleteStandardModal;
