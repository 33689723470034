import { useSelector } from 'react-redux';

import { useShallowEffect } from '@good/ui/hooks';
import type { IRootState } from 'stores/rematch/root-store';
import type { CountryCode } from './types';

/**
 * Infers the region based on service provider country code and invokes the provided callback with the inferred region.
 * @param callback - The callback function to be invoked with the inferred region.
 * @param opts - Optional configuration options.
 * @param opts.skip - If true, the region inference will be skipped.
 * @returns void
 */
export function useInferLocale(
  callback: (countryCode: CountryCode) => void,
  opts?: {
    skip?: boolean;
  },
): void {
  const { skip = false } = opts ?? {};
  const serviceProviderLite = useSelector((state: IRootState) => state.companyStore.companyDataLite);
  const countryCode = serviceProviderLite?.phoneCountryCode;

  useShallowEffect(() => {
    if (skip || !countryCode) {
      return;
    }

    callback(countryCode);
  }, [callback, skip, countryCode]);
}
