import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { ERROR_TYPES, Member, MESSAGE_ERRORS, regexEmail } from '../../utils/form-utils';
import { FormComponents } from '../components/FormComponents';

interface IProps {
  memberDetails: Member;
  teamMemberList: Member[];
}

const EmailColumn: React.FC<IProps> = ({ memberDetails, teamMemberList }) => {
  const timeoutSearchEmail = useRef(null);
  const dispatch = useDispatch<IRootDispatch>();
  const isCheckingEmail = useSelector<IRootState>((state) => state.teamV2Store.isCheckingEmail);
  const index = memberDetails.index;

  const {
    register,
    control,
    setError,
    trigger,
    formState: { errors },
  } = useFormContext();

  const checkEmailExisted = async (email: string) => {
    const errorMessage = await dispatch.teamV2Store.doCheckEmailAlreadyExist({ email });
    if (errorMessage) {
      setError(`teamMemberList.${index}.email`, { type: ERROR_TYPES.EMAIL_EXISTS, message: errorMessage });
      return;
    }
    dispatch.teamV2Store.setCheckingEmailState(false);
  };

  const onEnterSearchEmail = (e) => {
    if (!isCheckingEmail) {
      dispatch.teamV2Store.setCheckingEmailState(true);
    }
    const value = e.target.value;
    if (timeoutSearchEmail.current) {
      clearTimeout(timeoutSearchEmail.current);
      timeoutSearchEmail.current = null;
    }
    timeoutSearchEmail.current = setTimeout(async () => {
      if (
        !errors.teamMemberList?.[index]?.email ||
        (errors.teamMemberList?.[index]?.email?.type as unknown as string) === ERROR_TYPES.EMAIL_EXISTS
      ) {
        const isExistEmail = teamMemberList.some(
          (member) => member.memberId !== memberDetails.memberId && member.email === value,
        );
        if (isExistEmail) {
          setError(`teamMemberList.${index}.email`, {
            type: ERROR_TYPES.DUPLICATE_EMAILS,
            message: MESSAGE_ERRORS.EMAIL_EXISTS,
          });
        } else {
          checkEmailExisted(value);
        }
      }
    }, 300);
  };

  const handleOnBlur = () => {
    if (!errors?.teamMemberList?.[index]?.email) {
      trigger(`teamMemberList.${index}.email`);
    }
  };

  return (
    <FormComponents.TextFieldItem
      name="email"
      control={control}
      placeholder="Enter email here"
      {...register(`teamMemberList.${index}.email`, {
        required: MESSAGE_ERRORS.EMAIL,
        maxLength: { value: 100, message: MESSAGE_ERRORS.EMAIL },
        pattern: { value: regexEmail, message: MESSAGE_ERRORS.EMAIL },
        onChange: onEnterSearchEmail,
        onBlur: handleOnBlur,
      })}
    />
  );
};

export default EmailColumn;
