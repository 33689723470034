import { Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import React from 'react';

export function ShiftSlotGroupHeader({ status, count = 0, total = 0 }) {
  return (
    <Row type="flex" style={{ flexWrap: 'nowrap' }} className="mb-medium line-height-100">
      <Col className="pv-x-small flex-1">
        <Text weight="bold" size="small" lineHeight={100}>
          {_.upperCase(status)} {total > 0 && `(${count}/${total})`}
        </Text>
      </Col>
    </Row>
  );
}
