import React from 'react';
import { Avatar, Pill, Text } from '@good/ui/core';
import { ColumnDef } from '@tanstack/react-table';
import { SilRosterData } from 'utilities/react-trpc';
import { getNationalFormatNumber } from '@utils/phone-number-utils';
import { MeatballsDropdown } from '../components/meatballs-dropdown';
import { Smartphone } from '@good/icons';

export const teamMemberColumns: ColumnDef<SilRosterData[number]>[] = [
  {
    id: 'teamMember',
    header: 'TEAM MEMBERS',
    accessorKey: 'displayName',
    sortingFn: 'alphanumeric',
    size: 200,
    cell: (ctx) => {
      const { avatarUrl, displayName } = ctx.row.original;
      return (
        <>
          <div className='flex items-center'>
            <Avatar radius='sm' className='mx-2' size={32} src={avatarUrl} />
            <Text size='sm'>{displayName}</Text>
          </div>
        </>
      );
    },
  },

  {
    id: 'employmentType',
    header: 'EMPLOYMENT TYPE',
    accessorKey: 'employmentType',
    size: 180,
    cell: (ctx) => {
      const { employmentType } = ctx.row.original;

      const formattedEmploymentType = () => {
        if (employmentType === 'PartTime') {
          return <Pill>Part Time</Pill>;
        }
        if (employmentType === 'FullTime') {
          return <Pill>Full Time</Pill>;
        }
        if (employmentType === 'Casual') {
          return <Pill>Casual</Pill>;
        }
        if (employmentType === 'Contract') {
          return <Pill>Contract</Pill>;
        }

        return (
          <Text size='sm' c='dimmed'>
            Not set
          </Text>
        );
      };

      return <>{formattedEmploymentType()}</>;
    },
  },

  {
    id: 'mobileNumber',
    header: 'PHONE NUMBER',
    accessorKey: 'mobile',
    cell: (ctx) => {
      const { mobile, mobileCountryCode } = ctx.row.original;

      const formatedNumber = getNationalFormatNumber(mobile, mobileCountryCode);
      return (
        <>
          <div className='flex-row'>
            <Text size='sm' c='dimmed'>
              <Smartphone />
            </Text>
            <Text size='sm' ml='xs'>
              {formatedNumber}
            </Text>
          </div>
        </>
      );
    },
  },
  {
    id: 'profile',
    header: '',
    accessorKey: 'userId',
    cell: (ctx) => {
      const { supportWorkerId, displayName } = ctx.row.original;
      return <>{MeatballsDropdown({ supportWorkerId, displayName })}</>;
    },
  },
];
