import React, { useMemo } from 'react';

import { ChatQuestion, Receipt, UserCircle, SignOut } from '@good/icons';
import { useLogout } from 'utilities/react-hooks/use-logout';
import { sendToZendeskPortal } from 'integrations/zendesk';
import type { NavSection } from '../../types';

export function useProfileMenuSections(): NavSection[] {
  const logout = useLogout();

  return useMemo(() => {
    const profileMenuSections: NavSection[] = [
      {
        items: [
          {
            link: '/my-profile',
            label: 'Profile',
            icon: <UserCircle />,
            permissions: [],
          },
        ],
        key: 'profile',
      },
      {
        title: 'Workspace',
        items: [
          {
            link: '/support',
            label: 'Support',
            icon: <ChatQuestion />,
            permissions: [],
            onClick: () => {
              void sendToZendeskPortal(
                'https://goodhuman.zendesk.com/hc/en-au/articles/900002375743-Workspace-Updates',
              );
            },
          },
          {
            link: '/whats-new',
            label: "What's New",
            icon: <Receipt />,
            permissions: [],
            onClick: () => {
              void sendToZendeskPortal();
            },
          },
        ],
        key: 'workspace',
      },
      {
        items: [
          {
            link: '#',
            label: 'Sign Out',
            icon: <SignOut />,
            permissions: [],
            onClick: logout,
            color: 'red',
          },
        ],
        key: 'sign-out',
      },
    ];

    return profileMenuSections;
  }, [logout]);
}
