import React, { Component } from 'react';
import _ from 'lodash';
import Title from 'antd/lib/typography/Title';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import { Avatar, Input, Checkbox, Switch, Icon, Empty, Col, Row, Skeleton } from 'antd';
import { FilterSection } from 'common-components/filter';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { FilterType, ScheduleType } from 'utilities/enum-utils';
import { IFilter } from 'interfaces/filter-interfaces';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import {
  IGroupServiceSession,
  IGroupServiceSupportWorkersWithConflicts,
  IGroupServiceTimeSlot,
} from 'interfaces/service-interfaces';

const availableFilters = [
  FilterType.QUALIFICATIONS,
  FilterType.SKILLS,
  FilterType.RELIGIONS,
  FilterType.LANGUAGES,
  FilterType.GENDER,
  FilterType.INTEREST,
  FilterType.SPECIALITIES,
];

interface ISelectTeamMembersStepPanelProps {
  selectedTimeSlot: IGroupServiceTimeSlot;
  selectedSessions: IGroupServiceSession[];
  onPreviousStep: () => void;
  onNextStep: () => void;
  onSetSelectedWorkers: (v) => void;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  groupServiceSupportWorkers: typeof state.groupServiceStore.groupServiceSupportWorkers;
  doFetchGroupServiceWorkerForAssignment: typeof dispatch.groupServiceStore.doFetchGroupServiceWorkerForAssignment;
  scheduleType: string;
}

interface ISelectTeamMembersStepPanelState {
  isLoading: boolean;
  isNoSelectedWorkerError: boolean;
  isSearchAvailable: boolean;
  isSearching: boolean;
  filters: IFilter[];
  groupServiceWorkers: IGroupServiceSupportWorkersWithConflicts[];
  selectedWorkers: IGroupServiceSupportWorkersWithConflicts[];
  searchString: string;
}

class SelectTeamMembersStepPanel extends Component<ISelectTeamMembersStepPanelProps, ISelectTeamMembersStepPanelState> {
  state = {
    isLoading: false,
    isNoSelectedWorkerError: false,
    isSearchAvailable: true,
    isSearching: false,
    filters: [],
    groupServiceWorkers: this.props.groupServiceSupportWorkers,
    selectedWorkers: [],
    searchString: null,
  };

  private _goToNext = () => {
    if (this.state.selectedWorkers.length === 0) {
      this.setState({ isNoSelectedWorkerError: true });
      return;
    }

    this.props.onSetSelectedWorkers(this.state.selectedWorkers);
    this.props.onNextStep();
  };

  private _onToggleSearchAvailable = () => {
    const newSearchAvailable = !this.state.isSearchAvailable;
    this.setState({ isSearchAvailable: newSearchAvailable }, this._onFetchWorker);
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.setState({ filters }, this._onFetchWorker);
  };

  private _searchText = async (txt) => {
    this.setState({ searchString: txt, isSearching: true });
    await this._onFetchWorker();
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _onAddWorker = (worker) => {
    const newWorkers = this.state.selectedWorkers;
    newWorkers.push(worker);
    this.setState({ selectedWorkers: newWorkers });
  };

  private _onRemoveWorker = (worker) => {
    const newWorkers = this.state.selectedWorkers.filter((w) => w.userId !== worker.userId);
    this.setState({ selectedWorkers: newWorkers });
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.state.filters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        requestFilter[filter.filter] = filter.values;
      }
    });
    return requestFilter;
  };

  private _onFetchWorker = async () => {
    const { selectedTimeSlot, selectedGroupService, selectedSessions } = this.props;
    const { searchString, isSearchAvailable } = this.state;
    const { serviceId } = selectedGroupService;
    const serviceDateTimeIds = selectedSessions.map((session) => session.serviceDateTimeId);
    const requestFilters = this._formatFilterQuery();
    let payload = {
      serviceId,
      serviceDateTimeIds,
      isAvailable: isSearchAvailable,
      ...requestFilters,
    };

    if (searchString) {
      payload = { ...payload, searchString };
    }

    if (!_.isEmpty(selectedTimeSlot)) {
      payload = {
        ...payload,
        startDateTime: _.get(selectedTimeSlot, 'timeslot.startDateTime'),
        endDateTime: _.get(selectedTimeSlot, 'timeslot.endDateTime'),
      };
    }

    this.setState({ isLoading: true });
    await this.props.doFetchGroupServiceWorkerForAssignment(payload);
    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    await this._onFetchWorker();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.groupServiceSupportWorkers &&
      this.props.groupServiceSupportWorkers !== prevProps.groupServiceSupportWorkers
    ) {
      this.setState({ groupServiceWorkers: this.props.groupServiceSupportWorkers, selectedWorkers: [] });
    }
  };

  private _handleRedirectToHelpCenter = () => {
    const targetUrl =
      this.props.scheduleType === ScheduleType.SCHEDULE
        ? 'https://goodhuman.zendesk.com/hc/en-au/articles/4403969405849-Assigning-team-members-to-multiple-group-sessions#step-3-select-team-members-0-11'
        : 'https://goodhuman.zendesk.com/hc/en-au/articles/4403969405849-Assigning-team-members-to-multiple-group-sessions#step-3-select-team-members-0-4';
    window.open(targetUrl, '_blank');
  };

  render() {
    const { isLoading, isNoSelectedWorkerError, groupServiceWorkers, selectedWorkers } = this.state;

    const WorkerEmptyState = () => (
      <div className="flex-1 bg-white mt-x2-large align-center flex-column">
        <div className="">
          <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
        </div>
        <Text size="x2-large" color="secondary" weight="bold">
          No workers found
        </Text>
      </div>
    );

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={3}>Select team members to add to sessions.</Title>
              <Paragraph>
                Select which team members you want to add to the sessions you selected in the previous step.
              </Paragraph>
              <Paragraph>
                Team members with limited availability can be selected and we will display in the next step which
                sessions they are not available for based on your selection
              </Paragraph>
              <Paragraph>
                Visit the <HyperlinkButton onClick={this._handleRedirectToHelpCenter}>Help Center</HyperlinkButton> to
                learn more.
              </Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white rounded-big ph-large pt-large pb-x4-large" style={{ minWidth: '250px' }}>
              <Title level={4}>Select team member</Title>
              <Paragraph>
                Select the <b>team members</b> you'd like to assign to the sessions.
              </Paragraph>
              {isNoSelectedWorkerError && <Text color={'red-dark'}>Please select at least one team member.</Text>}
              <div className="flex-row line-height-120 mb-medium">
                {/* Left panel */}
                <div className="bg-quaternary p-medium rounded mr-medium" style={{ width: '360px' }}>
                  <div className="mb-medium">
                    <FieldLabel text={'FILTERS'} />
                  </div>
                  <div className="mb-medium">
                    <Text lineHeight={120}>Show team members who...</Text>
                  </div>
                  <div className="mb-medium">
                    <Switch
                      size="small"
                      checked={this.state.isSearchAvailable}
                      onChange={this._onToggleSearchAvailable}
                    />
                    <Text lineHeight={120} className="ml-small" size="regular">
                      Are available for the scheduled shift(s)
                    </Text>
                  </div>

                  <div>
                    <FilterSection
                      availableFilters={availableFilters}
                      filters={this.state.filters}
                      onChangeFilter={this._onChangeFilter}
                      displayTimezone={''}
                      usePortal={false}
                    />
                  </div>
                </div>

                {/* Right panel */}
                <div className="flex-1">
                  <div className="mb-medium">
                    <Input.Search
                      size="large"
                      placeholder={'Search for team members...'}
                      onChange={this._onEnterSearchText}
                      loading={this.state.isSearching}
                      allowClear
                    />
                  </div>

                  <div
                    className="bordered border-standard-gray rounded"
                    style={{ minHeight: '30vh', maxHeight: '40vh', overflowY: 'auto' }}
                  >
                    {isLoading ? (
                      <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                    ) : _.isEmpty(groupServiceWorkers) ? (
                      <WorkerEmptyState />
                    ) : (
                      _.map(groupServiceWorkers, (worker, index) => {
                        const isSelected = selectedWorkers.filter((w) => w.userId === worker.userId).length > 0;
                        return (
                          <AssignWorkerItem
                            key={index}
                            worker={worker}
                            isSelected={isSelected}
                            onAddWorker={() => this._onAddWorker(worker)}
                            onRemoveWorker={() => this._onRemoveWorker(worker)}
                          />
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
            <ActionModalFooter align="right" className="mt-large pr-large">
              <SecondaryButton size="large" className="mr-medium" onClick={this.props.onPreviousStep}>
                Back
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._goToNext}>
                Next
              </PrimaryButton>
            </ActionModalFooter>
          </Col>
        </Row>
      </div>
    );
  }
}

interface IAssignWorkerItemProps {
  worker: IGroupServiceSupportWorkersWithConflicts;
  isSelected: boolean;
  onAddWorker: () => void;
  onRemoveWorker: () => void;
}

interface IAssignWorkerItemState {
  isChecked: boolean;
}

class AssignWorkerItem extends Component<IAssignWorkerItemProps, IAssignWorkerItemState> {
  state = {
    isChecked: this.props.isSelected,
  };

  onChangeCheck = () => {
    const newIsChecked = !this.state.isChecked;
    if (newIsChecked) {
      this.props.onAddWorker();
    } else {
      this.props.onRemoveWorker();
    }

    this.setState({ isChecked: newIsChecked });
  };

  render() {
    const { worker } = this.props;
    const isAvailable = worker.availability === 'AVAILABLE' || worker.availability === 'LIMITED_AVAILABILITY';

    const getWarningIcon = () => {
      switch (worker.availability) {
        case 'AVAILABLE':
          return (
            <>
              <Icon type="check-circle" className="text-color-green mr-x-small" theme={'filled'} />
              <Text>Available</Text>
            </>
          );
        case 'NOT_AVAILABLE':
          return (
            <>
              <Icon type="close-circle" className="text-color-red mr-x-small" theme={'filled'} />
              <Text>Not available</Text>
            </>
          );
        case 'LIMITED_AVAILABILITY':
          return (
            <>
              <Icon type="check-circle" className="text-color-orange mr-x-small" theme={'outlined'} />
              <Text>Limited availability</Text>
            </>
          );
        default:
          return null;
      }
    };

    return (
      <div
        className={`flex-row ph-medium align-center hover-bg-quaternary ${isAvailable && 'cursor-pointer'}`}
        style={{ paddingTop: '12px', paddingBottom: '12px' }}
      >
        <Checkbox checked={this.state.isChecked} disabled={!isAvailable} onChange={this.onChangeCheck} />
        <Avatar
          style={{ opacity: !isAvailable ? 0.5 : 1 }}
          src={worker.attachmentUrl}
          size="large"
          className="ml-medium mr-medium"
          shape={'square'}
        />
        <div style={{ width: '220px', opacity: !isAvailable ? 0.5 : 1 }} className="line-height-150">
          <Text lineHeight={150}>{`${worker.firstName} ${worker.lastName}`}</Text> <br />
        </div>
        <div className="ph-small">{getWarningIcon()}</div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceSupportWorkers: state.groupServiceStore.groupServiceSupportWorkers,
});

const mapDisptach = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceWorkerForAssignment: dispatch.groupServiceStore.doFetchGroupServiceWorkerForAssignment,
});

export default connect(mapState, mapDisptach)(SelectTeamMembersStepPanel);
