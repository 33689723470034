import React, { Component } from 'react';
import { Paragraph, Text, FieldLabel } from 'common-components/typography';
import { Avatar, Checkbox, Popover } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import _ from 'lodash';
import moment from 'moment-timezone';
import { ShiftStatusTag } from 'views/group-services/session-details/team-members/shift-slot-table/ShiftStatusTag';
import { BookingTypeIcon } from 'common-components/tags';
import { ServiceType, ShiftSlotStatus } from 'utilities/enum-utils';
interface IAvailabilityConflictTimesProps {
  conflicts: any[];
  timezone?: any;
  onSelectShifts?: (shifts) => void;
  shiftSlotStatus?: ShiftSlotStatus;
}

interface IAvailabilityConflictTimesState {
  selectedShifts: any[];
}

class AvailabilityConflictTimes extends Component<IAvailabilityConflictTimesProps, IAvailabilityConflictTimesState> {
  state = {
    selectedShifts: [],
  };

  private _getPopoverContent = (item) => {
    const { timezone, shiftSlotStatus } = this.props;
    const { customers } = item;
    const memberName = item.firstName + ' ' + item.lastName;
    const memberAvatar = item.attachmentUrl;

    return (
      <div className="flex-column">
        <div style={{ width: '80px' }}>
          <ShiftStatusTag shiftStatus={item.shiftSlotStatus ? item.shiftSlotStatus : shiftSlotStatus} />
        </div>
        <div className="flex-column mt-medium">
          <Text weight="bold" className="text-size-x-large">
            {moment.tz(item.shiftStartDateTime, timezone).format('dddd, DD MMM YYYY')}
          </Text>
          <Text weight="bold" className="text-size-x-large">
            {moment.tz(item.shiftStartDateTime, timezone).format('hh:mm A')} -{' '}
            {moment.tz(item.shiftEndDateTime, timezone).format('hh:mm A')}
          </Text>
          <Text className="text-size-x-large">{item.serviceProviderName}</Text>
        </div>
        <div className="flex-column mt-medium">
          <div className="flex align-center">
            <BookingTypeIcon type={item.serviceType} className={'mr-small'} />
            <div className="text-color-secondary">
              {item.serviceType === ServiceType.INDIVIDUAL ? 'Support service' : 'Group service'}
            </div>
          </div>
          <Text>{item.serviceName}</Text>
        </div>
        {!_.isEmpty(customers) && (
          <div className="flex-column mt-medium">
            <FieldLabel text="Customer" />
            {_.map(customers, (customer) => (
              <div className="flex align-center" key={customer.customerFirstName + ' ' + customer.customerLastName}>
                <Avatar icon="user" size={32} shape="circle" src={customer.customerAttachmentUrl} />
                <b className="ml-small">
                  <Text weight="regular" className="text-size-large">
                    {customer.customerFirstName + ' ' + customer.customerLastName}
                  </Text>
                </b>
              </div>
            ))}
          </div>
        )}
        <div className="flex-column mt-medium">
          <FieldLabel text="Team member" />
          <div className="flex align-center">
            <Avatar icon="user" size={32} shape="circle" src={memberAvatar} />
            <b className="ml-small">
              <Text weight="regular" className="text-size-large">
                {memberName}
              </Text>
            </b>
          </div>
        </div>
      </div>
    );
  };

  private _isChecked = (item) => {
    return _.findIndex(this.state.selectedShifts, item) !== -1;
  };

  private _mappedShifts = (shifts) =>
    !_.isEmpty(shifts)
      ? _.map(shifts, (item) => ({
          serviceDateTimeId: item.serviceDateTimeId,
          attendanceId: item.attendanceId,
          supportWorkerAttendanceId: item.supportWorkerAttendanceId,
        }))
      : [];

  private _onCheckboxChange = (e, item) => {
    const { selectedShifts } = this.state;
    let newSelectedShifts = [...selectedShifts];

    if (e.target.checked) {
      newSelectedShifts.push(item);
    } else {
      newSelectedShifts = _.filter(
        newSelectedShifts,
        (shift) => shift.supportWorkerAttendanceId !== item.supportWorkerAttendanceId,
      );
    }

    const mappedShifts = this._mappedShifts(newSelectedShifts);
    this.props.onSelectShifts(mappedShifts);
    this.setState({ selectedShifts: newSelectedShifts });
  };

  private _onCheckAll = () => {
    const { conflicts, onSelectShifts } = this.props;
    let newSelectedShift = [];

    if (this.state.selectedShifts.length < conflicts.length) {
      newSelectedShift = conflicts;
    }

    const mappedShifts = this._mappedShifts(newSelectedShift);

    onSelectShifts(mappedShifts);
    this.setState({ selectedShifts: newSelectedShift });
  };

  render() {
    const { conflicts = [], timezone } = this.props;
    const memberName = !_.isEmpty(conflicts) ? conflicts[0].firstName + ' ' + conflicts[0].lastName : '';
    const avatar = !_.isEmpty(conflicts) ? conflicts[0].attachmentUrl : '';

    return (
      <div
        className="p-medium bordered border-standard-gray rounded-big line-height-135 flex-column bg-white"
        style={{ maxHeight: '300px', overflow: 'auto' }}
      >
        <div className="flex-row">
          <div className="ml-large flex-row">
            <Avatar icon="user" size={25} shape="square" src={avatar} />
            <b className="ml-small">
              <Text className="text-color-tertiary text-size-regular">{memberName}</Text>
            </b>
          </div>
        </div>
        <Paragraph>Select which shifts you want to keep the team member in</Paragraph>
        <HyperlinkButton onClick={this._onCheckAll}>
          {this.state.selectedShifts.length === conflicts.length ? 'De-select all' : 'Select all'}
        </HyperlinkButton>
        <div className="mt-large bordered border-standard-gray rounded-big">
          {_.map(conflicts, (item) => {
            const shiftStartTimeAvblTz = moment.tz(item.shiftStartDateTime, timezone);
            const shiftEndTimeAvblTz = moment.tz(item.shiftEndDateTime, timezone);
            const isSameDay = shiftStartTimeAvblTz.isSame(shiftEndTimeAvblTz, 'days');
            return (
              <div key={item.bookingId} className="p-medium evenodd">
                <Checkbox
                  className="mr-small"
                  onChange={(e) => {
                    this._onCheckboxChange(e, item);
                  }}
                  checked={this._isChecked(item)}
                />
                <Text weight="bold">{item.serviceName}: </Text>
                <Popover content={this._getPopoverContent(item)} trigger="click" placement="right">
                  <Text className="text-underline text-color-blue-action cursor-pointer">
                    {shiftStartTimeAvblTz.format('dddd DD/MM/YY, ')} {shiftStartTimeAvblTz.format('hh:mm A')}
                    {' - '}
                    {!isSameDay && `${shiftEndTimeAvblTz.format('dddd DD/MM/YY, ')} `}
                    {shiftEndTimeAvblTz.format('hh:mm A')}
                  </Text>
                </Popover>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default AvailabilityConflictTimes;
