import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const MoreHoriz = forwardRef(function MoreHoriz(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 12A2.756 2.756 0 0 1 5 9.25 2.756 2.756 0 0 1 7.75 12 2.756 2.756 0 0 1 5 14.75 2.756 2.756 0 0 1 2.25 12ZM5 10.75c-.686 0-1.25.564-1.25 1.25s.564 1.25 1.25 1.25 1.25-.564 1.25-1.25-.564-1.25-1.25-1.25ZM16.25 12A2.756 2.756 0 0 1 19 9.25 2.756 2.756 0 0 1 21.75 12 2.756 2.756 0 0 1 19 14.75 2.756 2.756 0 0 1 16.25 12ZM19 10.75c-.686 0-1.25.564-1.25 1.25s.564 1.25 1.25 1.25 1.25-.564 1.25-1.25-.564-1.25-1.25-1.25ZM9.25 12A2.756 2.756 0 0 1 12 9.25 2.756 2.756 0 0 1 14.75 12 2.756 2.756 0 0 1 12 14.75 2.756 2.756 0 0 1 9.25 12ZM12 10.75c-.686 0-1.25.564-1.25 1.25s.564 1.25 1.25 1.25 1.25-.564 1.25-1.25-.564-1.25-1.25-1.25Z"
        fill="currentColor"
      />
    </Icon>
  );
});
