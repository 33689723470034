import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Archive = forwardRef(function Archive(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 2.25h-.022c-.214 0-.41 0-.576.011-.178.012-.373.04-.572.122a1.75 1.75 0 0 0-.947.947c-.082.199-.11.394-.122.572-.011.165-.011.362-.011.576v.044c0 .214 0 .41.011.576.012.178.04.373.122.572.178.428.519.77.947.947.199.082.394.11.572.122a5.9 5.9 0 0 0 .348.01v8.306c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116H14.055c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337V6.75a5.9 5.9 0 0 0 .348-.011c.178-.012.373-.04.572-.122a1.75 1.75 0 0 0 .947-.947c.082-.199.11-.394.122-.572.011-.165.011-.362.011-.576v-.044c0-.214 0-.41-.011-.576a1.781 1.781 0 0 0-.122-.572 1.75 1.75 0 0 0-.947-.947 1.781 1.781 0 0 0-.572-.122c-.165-.011-.362-.011-.576-.011H3.5Zm15.75 4.5H4.75V15c0 1.435.002 2.436.103 3.192.099.734.28 1.122.556 1.399.277.277.665.457 1.4.556.754.101 1.756.103 3.191.103h4c1.435 0 2.436-.002 3.192-.103.734-.099 1.122-.28 1.399-.556.277-.277.457-.665.556-1.4.101-.755.103-1.756.103-3.191V6.75Zm.75-1.5h.5c.243 0 .388 0 .496-.008a.642.642 0 0 0 .103-.012.25.25 0 0 0 .13-.131.63.63 0 0 0 .013-.103c.008-.108.008-.253.008-.496s0-.388-.008-.496a.63.63 0 0 0-.012-.103.25.25 0 0 0-.131-.13.642.642 0 0 0-.103-.013 8.29 8.29 0 0 0-.496-.008h-17c-.243 0-.388 0-.496.008a.64.64 0 0 0-.103.012.25.25 0 0 0-.13.131.64.64 0 0 0-.013.103 8.289 8.289 0 0 0-.008.496c0 .243 0 .388.008.496a.64.64 0 0 0 .012.103.25.25 0 0 0 .131.13.64.64 0 0 0 .103.013c.108.008.253.008.496.008H20ZM8.25 10A.75.75 0 0 1 9 9.25h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
