import { Text } from '@good/components';
import { Select, SelectProps } from 'design-components';
import React, { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InsightsSummaryFilters, TIME_PERIODS, TimePeriod, getDatesForTimePeriod } from 'views/insights/insights';

type InsightsFlyoutFiltersProps = {
  filters: InsightsSummaryFilters;
  updateFilters: (payload: InsightsSummaryFilters) => void;
  timezone: string;
};

export const InsightsFlyoutFilters = ({ filters, updateFilters, timezone }: InsightsFlyoutFiltersProps) => {
  const { t } = useTranslation('', { keyPrefix: 'insights.summary.filters' });

  const updateFiltersParam = (key: string, value: React.Key) =>
    updateFilters({
      ...filters,
      [key]: value,
    });

  const [timePeriod, setTimePeriod] = useState<TimePeriod>('thisWeek');

  const handleDateFilterSelect = (timePeriod: TimePeriod) => {
    setTimePeriod(timePeriod);
    updateFilters({
      ...filters,
      ...getDatesForTimePeriod(timePeriod, timezone),
    });
  };

  return (
    <div className="flex w-full flex-row">
      <div className="flex flex-wrap">
        <div className="mb-2 mr-4">
          <FilterSelect
            label="teamMembersSelect"
            onSelectionChange={(value) => updateFiltersParam('teamMemberGroup', value)}
            selectedKey={filters.teamMemberGroup}
            prefix={
              filters.teamMemberGroup !== 'ALL_MEMBERS' ? <Text className="font-bold">Team Members |</Text> : undefined
            }
          >
            <Select.Item key="ALL_MEMBERS">{t('teamMembersOptions.allMembers')}</Select.Item>
            <Select.Item key="SUPPORT_COORDINATORS">{t('teamMembersOptions.supportCoordinators')}</Select.Item>
          </FilterSelect>
        </div>

        <FilterSelect
          onSelectionChange={(key: Key) => {
            handleDateFilterSelect(key as TimePeriod);
          }}
          selectedKey={timePeriod}
        >
          {TIME_PERIODS.map((option) => (
            <Select.Item key={option}>{t(`dateOptions.${option}`)}</Select.Item>
          ))}
        </FilterSelect>
      </div>
    </div>
  );
};

const FilterSelect = (props: SelectProps) => {
  return (
    <Select
      {...props}
      containerStyles={{
        borderColor: 'transparent ',
      }}
      triggerStyles={{
        paddingY: '$xsmall',
        marginX: '$small',
        backgroundColor: '#F4F8F9 !important',
        color: '$accent',
      }}
      wrapperStyles={{ backgroundColor: '#F4F8F9', borderColor: 'transparent !important' }}
    />
  );
};
