import React from 'react';
import { PaperPlane } from '@good/illustrations';
import { Link, Text } from '@good/components';
import { reactTrpc } from 'utilities/react-trpc';
import { Loader2Adjustable } from '@good/icons';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import startCase from 'lodash/startCase';

const csvImportTableHeaders = ['Job ID', 'Job name', 'Category', 'Strategy', 'Status', 'Added by', 'Added on'];

type Props = {
  serviceProviderId: string;
};

export const CsvImportsListing = ({ serviceProviderId }: Props) => {
  const { data, error } = reactTrpc.imports.list.useQuery({ serviceProviderId });
  if (error) throw new Error(`failed to fetch imports: ${error.message}`);

  if (!data)
    return (
      <div className='flex flex-col align-center'>
        <Loader2Adjustable className='animate-spin' />
        <Text size='md'>Loading imports</Text>
      </div>
    );

  return (
    <div className='flex flex-col align-center'>
      <table className='w-full'>
        <thead>
          <tr>
            {csvImportTableHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.length > 0 ? (
            data.map((job) => (
              <tr key={job.jobId}>
                <td>
                  <RouterLink to={`/account/imports/${job.jobId}`}>
                    <Link>
                      <Text>{job.jobId}</Text>
                    </Link>
                  </RouterLink>
                </td>

                <td>
                  <RouterLink to={`/account/imports/${job.jobId}`}>
                    <Link>
                      <Text>{job.jobName}</Text>
                    </Link>
                  </RouterLink>
                </td>
                <td>
                  <Text>{startCase(job.category ?? '-')}</Text>
                </td>
                <td>
                  <Text>{startCase(job.data.processingStrategy)}</Text>
                </td>
                <td>
                  <Text>{job.status}</Text>
                </td>
                <td>
                  {job.addedByTeamMember ? (
                    <RouterLink to={`/team/details/${job.addedByTeamMember.teamMemberId}`}>
                      <Link>
                        <Text>{job.addedByTeamMember.name}</Text>
                      </Link>
                    </RouterLink>
                  ) : null}
                </td>
                <td>
                  <Text>{format(job.createdOn, 'hh:mm:aa, dd/MM/yyyy')}</Text>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={csvImportTableHeaders.length}>
                <div className='flex flex-col align-center'>
                  <PaperPlane />
                  <Text size='md'>No recent imports</Text>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
