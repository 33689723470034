import React, { useEffect, useState } from 'react';
import { Input, Form, Icon } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { SubTitle, Text } from 'common-components/typography';
import { IFormElement } from 'views/form-builder/shared/form-interface';
import {
  convertRulesRequiredOfBundle,
  getRules,
  getValueFromPropertyByKey,
} from 'views/form-builder/shared/form-builder-utils';
import { FormElementType } from 'views/form-builder/shared/form-enum';
import CommonUtils from 'utilities/common-utils';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';

const FormItem = Form.Item;

interface IAddressLookupProps {
  fieldTitle?: string;
  placeholder?: string;
  caption?: string;
  isRequired?: boolean;
}

export const AddressLookup = ({ fieldTitle: fieldText, placeholder, caption, isRequired }: IAddressLookupProps) => {
  const fieldTextFormat = (
    <>
      {fieldText}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bolder">{fieldTextFormat}</SubTitle>
      <Input
        size="large"
        className="mb-medium width-full mt-x-small bordered border-standard-gray"
        placeholder={placeholder}
      />
      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
};

interface IEditAddressLookupProps extends FormComponentProps {
  value?: {
    country: string;
    fullAddress: string;
    geoLat: number;
    geoLng: number;
    locality: string;
    postcode: string;
    state: string;
    streetAddress1: string;
    streetAddress2: string;
  };
  element: IFormElement;
}

const searchAutocompleteOptions = {
  componentRestrictions: { country: ['au'] },
};

export const EditAddressLookup = ({ value, element, form }: IEditAddressLookupProps) => {
  const { elementsBundleOptional } = useSelector((state: IRootState) => state.formBuilderStore);
  const { id, parentId } = element;
  const placeholder = getValueFromPropertyByKey(element.properties.appearance, 'placeholderText');
  const caption = getValueFromPropertyByKey(element.properties.appearance, 'captionText');
  let rules = getRules(element, false);
  const { getFieldDecorator, setFieldsValue } = form;
  const isRequired = getValueFromPropertyByKey(element.properties.general, 'require');
  let fieldText = getValueFromPropertyByKey(element.properties.general, 'fieldTitle');

  const [address, setAddress] = useState<string>(value?.fullAddress || '');
  const [temporarySelectedAddress, setTemporarySelectedAddress] = useState(null);

  if (isRequired) {
    const checkRequiredBundle = !(elementsBundleOptional || []).includes(id);
    rules = convertRulesRequiredOfBundle(checkRequiredBundle, rules);

    fieldText = (
      <>
        {fieldText}
        {checkRequiredBundle && <Text color="red"> *</Text>}
      </>
    );
  }

  const handleSelect = async (inputAddress: string) => {
    const addressSelected = await handleFetchGeocodeByAddress(inputAddress);

    setAddress(inputAddress);
    setFieldsValue({
      [`${id},value`]: addressSelected,
    });
  };

  const handleChange = (inputAddress) => {
    setAddress(inputAddress);
    if (!inputAddress) {
      setFieldsValue({
        [`${id},value`]: null,
      });
      setTemporarySelectedAddress(null);
    }
  };

  const handleFetchGeocodeByAddress = async (inputAddress: string) => {
    try {
      const selectedLocation = (await geocodeByAddress(inputAddress))?.[0];
      const latlong = await getLatLng(selectedLocation);

      const temporaryAddress = CommonUtils.createAddressFromGoogleMap(selectedLocation.address_components);
      setTemporarySelectedAddress(temporaryAddress);

      return { geoLat: latlong.lat, geoLng: latlong.lng, ...temporaryAddress };
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (value) {
      handleFetchGeocodeByAddress(value.fullAddress);
    }
  }, []);

  return (
    <div className="flex-column width-full" id={`${id},value`}>
      <SubTitle weight="bolder">{fieldText}</SubTitle>
      <FormItem className="m-none">
        <PlacesAutocomplete
          value={address}
          onSelect={handleSelect}
          onChange={handleChange}
          shouldFetchSuggestions={address.length > 2}
          searchOptions={searchAutocompleteOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                value={address}
                {...getInputProps({
                  placeholder: placeholder,
                  className: `location-search-input width-full pl-small ${
                    form.getFieldError([id, 'value'] as never) ? 'has-errors' : ''
                  }`,
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                  const style = suggestion.active
                    ? {
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                        borderTop: '1px ridge grey',
                        borderLeft: '1px ridge grey',
                        borderRight: '1px ridge grey',
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        borderTop: '1px ridge grey',
                        borderLeft: '1px ridge grey',
                        borderRight: '1px ridge grey',
                      };
                  return (
                    <div
                      key={index}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>
                        <Icon type="environment" /> {suggestion.description}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {getFieldDecorator([id, 'value'] as never, {
          initialValue: value,
          rules: [
            ...rules,
            {
              message: 'Please select a valid address',
              validator: (_, value) => {
                if (!value) return true;

                return (
                  Boolean(temporarySelectedAddress?.streetAddress1) || Boolean(temporarySelectedAddress?.streetAddress2)
                );
              },
            },
          ],
        })(<Input type="hidden" />)}
      </FormItem>
      <FormItem className="m-none">
        {getFieldDecorator([id, 'type'] as never, {
          initialValue: FormElementType.ADDRESS_LOOKUP,
        })(<Input type="hidden" />)}
      </FormItem>

      <FormItem className="m-none">
        {getFieldDecorator([id, 'parentElementId'] as never, {
          initialValue: parentId,
        })(<Input type="hidden" />)}
      </FormItem>

      <Text className="pt-small" size="small" color="secondary" lineHeight={100}>
        {caption}
      </Text>
    </div>
  );
};
