import React, { useCallback, useEffect, useState } from 'react';
import { Icon, Collapse, Form, Input, Switch, Select, Radio, Checkbox } from 'antd';
import { Text, SubTitle } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import _, { debounce } from 'lodash';
import {
  FormElementType,
  PropType,
  PropKey,
  ConditionType,
  validNumberOperators,
  validTextOperators,
} from '../shared/form-enum';
import { IFormElement, IFormElementProperty } from '../shared/form-interface';
import DynamicAddOptions from './form-elements/components/DynamicAddOptions';
import moment from 'moment';
import { SimpleEditDateTimeElement } from './form-elements/DateTimeElement/DateTimeElementSimple';

const { Panel } = Collapse;
const FormItem = Form.Item;

type IFormElementConfigurationPanelProps = {
  onRightPanelCollapse: () => void;
  selectedItem: IFormElement;
  save: (item: IFormElement, formStatus) => void;
} & FormComponentProps;

const FormElementConfigurationPanel = ({
  form,
  selectedItem,
  save,
  onRightPanelCollapse,
}: IFormElementConfigurationPanelProps) => {
  const { getFieldDecorator } = form;

  const [currentItem, setCurrentItem] = useState(selectedItem);

  const _validateForm = () => {
    form.validateFields((err, values) => {
      if (
        values &&
        Object.prototype.hasOwnProperty.call(values, 'configuration,multipleAttachment') &&
        !values['configuration,multipleAttachment']
      ) {
        values['configuration,multipleAttachmentValue'] = 2;
        err &&
          Object.prototype.hasOwnProperty.call(err, 'configuration,multipleAttachmentValue') &&
          delete err['configuration,multipleAttachmentValue'];
      }

      if (values && Object.prototype.hasOwnProperty.call(values, 'configuration,minimum') && !values['configuration,minimum']) {
        values['configuration,minimumValue'] = null;
        err && Object.prototype.hasOwnProperty.call(err, 'configuration,minimumValue') && delete err['configuration,minimumValue'];
      }

      if (values && Object.prototype.hasOwnProperty.call(values, 'configuration,maximum') && !values['configuration,maximum']) {
        values['configuration,maximumValue'] = null;
        err && Object.prototype.hasOwnProperty.call(err, 'configuration,maximumValue') && delete err['configuration,maximumValue'];
      }

      if (values && Object.prototype.hasOwnProperty.call(values, 'configuration,default') && !values['configuration,default']) {
        values['configuration,defaultValue'] = null;
        err && Object.prototype.hasOwnProperty.call(err, 'configuration,defaultValue') && delete err['configuration,defaultValue'];
      }

      if (!err || Object.keys(err).length === 0) {
        const properties = _.cloneDeep(currentItem.properties);
        _.forIn(values, (value, key) => {
          const keys = _.split(key, ',');
          //keys[0] = general || appearance || configuration
          if (_.keysIn(properties).includes(keys[0])) {
            const prop = properties[keys[0]];
            if (keys[1] === 'checkboxOptions') {
              const index = _.findIndex(prop, { key: keys[1] });
              prop[index].defaultChecked = value === 'Checked' ? true : false;
            } else {
              const index = _.findIndex(prop, { key: keys[1] });
              if (index > -1) {
                // Sets the value of the prop. eg for number input, set the value to that entered.
                prop[index].value = value;
              }
            }
          }

          // Reset the conditional comparator value to null if the event type is changed
          if (key === 'configuration,event') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- stop complaining and do the thing
            properties.configuration.find((item) => item.key === PropKey.CONDITIONAL_COMPARATOR)!.value = null;
          }
        });
        //condition for resetting the default value of options
        let isCheckDefaultOption = properties.configuration && properties.configuration.length > 1;

        if (isCheckDefaultOption) {
          if (Array.isArray(properties.configuration[1].value)) {
            isCheckDefaultOption = !properties.configuration[1].value.every((r) =>
              properties.configuration[0].value.includes(r),
            );
          } else {
            isCheckDefaultOption = !_.includes(properties.configuration[0].value, properties.configuration[1].value);
          }
        }

        if (isCheckDefaultOption) {
          if (properties.configuration[0].type === PropType.options) {
            if (properties.configuration[0].value) {
              properties.configuration[0].value = properties.configuration[0].value.filter((op) => op !== undefined);
            }
            if (Array.isArray(properties.configuration[1].value)) {
              properties.configuration[1].value = [];
            } else {
              properties.configuration[1].value = 'No default';
            }
          }
        }

        const newItem = { ...currentItem, properties };
        setCurrentItem(newItem);
        save(newItem, true);
      } else {
        save({ ...currentItem }, false);
      }
    });
  };

  const _onCollapse = () => {
    form.validateFields((err) => {
      if (!err) {
        onRightPanelCollapse();
        _validateForm();
      } else {
        save({ ...currentItem }, false);
      }
    });
  };

  const debounceChange = useCallback(
    debounce(() => {
      _validateForm();
    }, 200),
    [currentItem],
  );

  const renderProp = (
    prop: IFormElementProperty,
    prefix: string,
    props: IFormElementProperty[],
    isIntakeForm: boolean,
  ) => {
    const isDisabled = selectedItem.id.includes(ConditionType.DUPLICATION);
    const isCheckBoxType = selectedItem.type === FormElementType.CHECKBOX;
    const isBundleChildrenElement = Boolean(selectedItem?.parentId);

    if (prop.key === PropKey.RULES) return;
    if (prop.type === PropType.required) {
      return (
        <React.Fragment>
          <div
            className={`${
              isDisabled || (isCheckBoxType && isIntakeForm) || isBundleChildrenElement ? 'disabled dimmer' : ''
            }`}
          >
            <SubTitle>{prop.name}</SubTitle>
            <FormItem className="m-none">
              {getFieldDecorator([prefix, prop.key] as never, {
                initialValue: prop.value,
                rules: prop.rules,
                valuePropName: 'checked',
              })(
                <Switch
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                  onChange={debounceChange}
                />,
              )}
              <Text className="pl-small pt-small" size="x-small" color="secondary" lineHeight={100}>
                {prop.caption}
              </Text>
            </FormItem>
          </div>
        </React.Fragment>
      );
    }
    if (prop.type === PropType.checkbox) {
      return (
        <React.Fragment>
          <div className={`${isIntakeForm || isDisabled ? 'disabled dimmer' : ''}`}>
            <SubTitle>{prop.name}</SubTitle>
            <FormItem className="m-none">
              {getFieldDecorator([prefix, prop.key] as never, {
                initialValue: prop.value,
                rules: prop.rules,
                valuePropName: 'checked',
              })(<Checkbox onChange={debounceChange}>{prop.label}</Checkbox>)}
              <Text className="pl-small pt-small" size="x-small" color="secondary" lineHeight={100}></Text>
            </FormItem>
            <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
              {prop.caption}
            </Text>
          </div>
        </React.Fragment>
      );
    }

    if (prop.type === PropType.options) {
      return (
        <React.Fragment>
          <div className={`${isIntakeForm || isDisabled ? 'disabled dimmer' : ''}`}>
            <SubTitle>{prop.name}</SubTitle>
            <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
              {prop.caption}
            </Text>
            <DynamicAddOptions
              form={form}
              options={prop.value.filter((x) => x)}
              setProperties={debounceChange}
              prefix={prefix}
              isDisabled={isIntakeForm || isDisabled}
            />
          </div>
        </React.Fragment>
      );
    }
    if (prop.type === PropType.defaultOption) {
      const filteredOptions = currentItem.properties.configuration
        .find((prop) => prop.key === 'options')
        .value.filter((val) => !_.isEmpty(val));
      const defaultMultiSelect = currentItem.properties.configuration.find(
        (prop) => prop.key === 'defaultOption',
      ).value;
      const isMultiSelect = Array.isArray(defaultMultiSelect);
      const defaultOptions = Array.from(new Set(['No default', ...filteredOptions]));

      if (isMultiSelect) {
        defaultOptions.shift();
      }

      return (
        <React.Fragment>
          <div className={`${isIntakeForm || isDisabled ? 'disabled dimmer' : ''}`}>
            <SubTitle>{prop.name}</SubTitle>
            <FormItem>
              {getFieldDecorator([prefix, prop.key] as never, {
                initialValue: prop.value,
              })(
                <Select
                  mode={isMultiSelect ? 'multiple' : 'default'}
                  size="large"
                  style={{ width: '60%' }}
                  optionFilterProp="children"
                  suffixIcon={<Icon type="caret-down" />}
                  onChange={debounceChange}
                >
                  {defaultOptions.map((opt) => (
                    <Select.Option key={opt} value={opt}>
                      {opt}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>
            <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
              {prop.caption}
            </Text>
          </div>
        </React.Fragment>
      );
    }

    if (prop.type === PropType.inputDependencyDate) {
      const conditionalComparatorTargetElement = props.find((x) => x.key === PropKey.TARGET_ELEMENT);
      const conditionalComparatorDrivingElement = conditionalComparatorTargetElement?.options?.find(
        (x) => x.id === conditionalComparatorTargetElement?.value,
      );

      if (!conditionalComparatorDrivingElement) {
        return null;
      }

      const comparatorDriverElementType = conditionalComparatorDrivingElement.type as keyof typeof FormElementType;
      const typedOperator = comparatorDriverElementType as FormElementType;

      if (typedOperator !== FormElementType.DATE_TIME) {
        return null;
      }

      const drivingElementDateTimeType = conditionalComparatorDrivingElement?.properties?.configuration?.find(
        (x) => x.key === 'dateTimeType',
      ).value;

      // Returns the value of input element
      const getValueFromEvent = (e) => {
        const { value } = e.currentTarget;

        return value;
      };

      const value = [];

      const setTimes = (e: Date) => {
        // Only update value/debounce if the event value is a valid date
        if (e instanceof Date && isFinite(Number(e))) {
          const toDebounce = {
            [`${prefix},${prop.key}`]: moment(e).format('YYYY-MM-DD HH:mm'),
          };

          form.setFieldsValue(toDebounce);
          debounceChange();
        }
      };

      return (
        <React.Fragment>
          <div className={`${isIntakeForm || isDisabled ? 'disabled dimmer' : ''}`}>
            <SubTitle>{prop.label}</SubTitle>
            <FormItem>
              {getFieldDecorator([prefix, prop.key] as never, {
                getValueFromEvent,
                initialValue: prop.value,
              })(
                <SimpleEditDateTimeElement
                  key={prop.key}
                  onDateValueChanged={setTimes}
                  value={value}
                  form={form}
                  formMode="EDIT"
                  timezone={moment.tz.guess()}
                  dateTimeType={drivingElementDateTimeType}
                />,
              )}
            </FormItem>
            <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
              {prop.caption}
            </Text>
          </div>
        </React.Fragment>
      );
    }

    if (prop.type === PropType.inputDepend) {
      const parentItem = props.find((x) => x.key === prop.dependOn);
      if (!parentItem || !parentItem.value) return null;

      // Only render comparator inputDepend items when it is a valid operation type
      // Dont render for date-time number comparator

      const conditionalComparatorTargetElement = props.find((x) => x.key === PropKey.TARGET_ELEMENT);
      const conditionalComparatorDrivingElement = conditionalComparatorTargetElement?.options?.find(
        (x) => x.id === conditionalComparatorTargetElement?.value,
      );

      if (conditionalComparatorDrivingElement) {
        const comparatorDriverElementType = conditionalComparatorDrivingElement.type as keyof typeof FormElementType;
        const typedOperator = comparatorDriverElementType as FormElementType;

        if (typedOperator === FormElementType.DATE_TIME) {
          return null;
        }

        if (
          parentItem.type === PropType.Select &&
          !validNumberOperators.includes(parentItem.value) &&
          !validTextOperators.includes(parentItem.value)
        ) {
          return null;
        }
      }

      const maxValue = form.getFieldValue(`${prefix},maximumValue`);
      const minValue = form.getFieldValue(`${prefix},minimumValue`);
      let rules = _.cloneDeep(prop.rules);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- get over it
      let propValue: any = {};
      if (prop.addonAfter) {
        propValue.addonAfter = prop.addonAfter;
      }
      if (prop.style) {
        propValue.style = prop.style;
      }
      if (prop.key === 'defaultValue') {
        rules = [...prop.rules.filter((x) => x.required)];
        rules.push({
          validator: debounce((rule: any, value: string, cb: (msg?: string) => void) => {
            if (!value) {
              cb();
            } else if (isNaN(parseInt(value))) {
              cb(`Default value must be a number`);
            } else if (minValue && Number(value) < Number(minValue)) {
              cb(`Minimum is ${minValue}`);
            } else if (maxValue && Number(value) > Number(maxValue)) {
              cb(`Maximum is ${maxValue}`);
            } else {
              cb();
            }
          }, 300),
        });
      }
      if (prop.key === 'minimumValue') {
        rules = [...prop.rules.filter((x) => x.required)];
        rules.push({
          validator: debounce((rule: any, value: string, cb: (msg?: string) => void) => {
            if (!value) {
              cb();
            } else if (isNaN(parseInt(value))) {
              cb(`Minimum must be a number`);
            } else if (maxValue && Number(value) >= Number(maxValue)) {
              cb(`Maximum is ${maxValue}`);
            } else {
              cb();
            }
          }, 300),
        });
      }
      if (prop.key === 'maximumValue') {
        rules = [...prop.rules.filter((x) => x.required)];
        rules.push({
          validator: debounce((rule: any, value: string, cb: (msg?: string) => void) => {
            if (!value) {
              cb();
            } else if (isNaN(parseInt(value))) {
              cb(`Maximum must be a number`);
            } else if (minValue && Number(value) <= Number(minValue)) {
              cb(`Minimum is ${minValue}`);
            } else {
              cb();
            }
          }, 300),
        });
      }

      // works for <Input /> only
      // Returns the value of input element
      const getValueFromEvent = (e) => {
        const { value } = e.currentTarget;

        let isRegexElement = false;
        let inputRegex = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;

        // Check if input is a number value - applies regex. Otherwise returns the fieldValue of prefix,prop.key
        isRegexElement =
          prop.key === 'minimumValue' ||
          prop.key === 'maximumValue' ||
          prop.key === 'defaultValue' ||
          prop.key === 'multipleAttachmentValue';

        if (prop.key === PropKey.CONDITIONAL_COMPARATOR) {
          isRegexElement = true;

          const conditionalComparatorTargetElement = props.find((x) => x.key === PropKey.TARGET_ELEMENT);
          const conditionalComparatorDrivingElement = conditionalComparatorTargetElement?.options?.find(
            (x) => x.id === conditionalComparatorTargetElement?.value,
          );

          const comparatorDriverElementType = conditionalComparatorDrivingElement.type as keyof typeof FormElementType;
          const typedOperator = comparatorDriverElementType as FormElementType;

          switch (typedOperator) {
            case FormElementType.NUMBER:
              inputRegex = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
              break;
            case FormElementType.SHORT_TEXT:
              inputRegex = /^.{0,52}$/;
              break;
            case FormElementType.LONG_TEXT:
              inputRegex = /^.{0,128}$/;
              break;
            case FormElementType.CURRENCY:
              inputRegex = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
              break;
          }
        }

        if (selectedItem.type === FormElementType.CURRENCY) {
          isRegexElement = true;
          inputRegex = /^(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        }

        if (isRegexElement && inputRegex.test(value)) {
          return value;
        }
        return form.getFieldValue(`${prefix},${prop.key}`);
      };

      return (
        <React.Fragment>
          <div className={`${isIntakeForm || isDisabled ? 'disabled dimmer' : ''}`}>
            <SubTitle>{prop.label}</SubTitle>
            <FormItem className="m-none mv-small">
              {getFieldDecorator([prefix, prop.key] as never, {
                getValueFromEvent,
                initialValue: prop.value,
                rules: rules,
              })(
                <Input
                  autoFocus
                  autoComplete="off"
                  placeholder={prop.placeholder}
                  style={{ width: '100%' }}
                  className="bg-white"
                  {...propValue}
                />,
              )}
            </FormItem>
            <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
              {prop.caption}
            </Text>
          </div>
        </React.Fragment>
      );
    }

    // Determines the options available in the field 'MEETS THIS CONDITION' (when a user changes 'when' option)
    if (prop.type === PropType.selectDepend) {
      const parentItem = props.find((x) => x.key === prop.dependOn);
      if (!parentItem || !parentItem.value) return null;
      const options = _.cloneDeep(prop.options);
      if (prop.key === 'constraint') {
        if (parentItem.value === 'Time only') {
          return null;
        }
      }

      if (prop.key === PropKey.TARGET_OPTION) {
        const optionSelected = parentItem.options.find((opt) => opt.id === parentItem.value);
        if (
          ![
            FormElementType.SINGLE_CHOICE,
            FormElementType.MULTI_CHOICE,
            FormElementType.DROPDOWN,
            FormElementType.DROPDOWN_MULTI_SELECT,
          ].includes(optionSelected?.type)
        ) {
          return null;
        }
      }

      return (
        <React.Fragment>
          <div className={`${isIntakeForm || isDisabled ? 'disabled dimmer' : ''}`}>
            <SubTitle>{prop.label}</SubTitle>
            <FormItem>
              {getFieldDecorator([prefix, prop.key] as never, {
                initialValue: prop.value,
              })(
                <Select
                  size="large"
                  className="width-full"
                  optionFilterProp="children"
                  suffixIcon={<Icon type="caret-down" />}
                  onChange={debounceChange}
                >
                  {options
                    .filter((val) => !_.isEmpty(val))
                    .map((opt) => {
                      return (
                        <Select.Option key={opt?.value ?? opt} value={opt?.value ?? opt}>
                          {opt?.name ?? opt}
                        </Select.Option>
                      );
                    })}
                </Select>,
              )}
            </FormItem>
            <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
              {prop.caption}
            </Text>
          </div>
        </React.Fragment>
      );
    }

    if (prop.type === PropType.Select || prop.type === PropType.SelectMultiple) {
      const options = prop.options;
      return (
        <React.Fragment>
          <div className={`${isIntakeForm || isDisabled ? 'disabled dimmer' : ''}`}>
            <SubTitle>{prop.label}</SubTitle>
            <FormItem>
              {getFieldDecorator([prefix, prop.key] as never, {
                initialValue: prop.value,
              })(
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  suffixIcon={<Icon type="caret-down" />}
                  onChange={debounceChange}
                  mode={prop.type === PropType.Select ? 'default' : 'multiple'}
                >
                  {options
                    .filter((val) => !_.isEmpty(val))
                    .map((opt) => {
                      const optionLabel =
                        opt.properties?.general?.find((config) => config.key === PropKey.FIELD_TITLE)?.value ||
                        opt.properties?.appearance?.find(
                          (config) => config.key === PropKey.HEADING_TEXT || config.key === PropKey.PARAGRAPH_TEXT,
                        )?.value ||
                        opt.name;

                      return typeof opt === 'object' ? (
                        <Select.Option key={opt.id} value={opt.id}>
                          {optionLabel}
                        </Select.Option>
                      ) : (
                        <Select.Option key={opt} value={opt}>
                          {opt}
                        </Select.Option>
                      );
                    })}
                </Select>,
              )}
            </FormItem>
            <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
              {prop.caption}
            </Text>
          </div>
        </React.Fragment>
      );
    }

    if (prop.key === 'checkboxOptions') {
      return (
        <React.Fragment>
          <div className={`${isIntakeForm || isDisabled ? 'disabled dimmer' : ''}`}>
            <SubTitle>{prop.label}</SubTitle>
            <FormItem>
              {getFieldDecorator([prefix, prop.key] as never, {
                initialValue: prop.defaultChecked ? 'Checked' : 'Unchecked',
              })(
                <Radio.Group className="flex-column">
                  {currentItem.properties.configuration
                    .find((prop) => prop.key === PropKey.CHECKBOX_OPTIONS)
                    .value.map((opt) => {
                      return (
                        <Radio key={opt} value={opt}>
                          {opt}
                        </Radio>
                      );
                    })}
                </Radio.Group>,
              )}
            </FormItem>
            <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
              {prop.caption}
            </Text>
          </div>
        </React.Fragment>
      );
    }

    if (prop.key === PropKey.CAPTION_TEXT) {
      return (
        <React.Fragment>
          <div className={`${isDisabled ? 'disabled dimmer' : ''}`}>
            <SubTitle>{prop.label}</SubTitle>
            <FormItem className="m-none">
              {getFieldDecorator([prefix, prop.key] as never, {
                initialValue: prop.value,
                rules: prop.rules,
              })(<Input placeholder={prop.placeholder} />)}
            </FormItem>
            <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
              {prop.caption}
            </Text>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className={`${isIntakeForm || isDisabled ? 'disabled dimmer' : ''}`}>
          <SubTitle>{prop.label}</SubTitle>
          <FormItem className="m-none">
            {getFieldDecorator([prefix, prop.key] as never, {
              initialValue: prop.value,
              rules: prop.rules,
            })(<Input placeholder={prop.placeholder} />)}
          </FormItem>
          <Text className="pt-small" size="x-small" color="secondary" lineHeight={100}>
            {prop.caption}
          </Text>
        </div>
      </React.Fragment>
    );
  };

  const showProp = (prop: IFormElementProperty, prefix: string, props: IFormElementProperty[]) => {
    return (
      <div className={`${prop.name && 'mb-large'}`} key={prop.key}>
        {renderProp(prop, prefix, props, selectedItem.isIntakeForm)}
      </div>
    );
  };

  useEffect(() => {
    setCurrentItem(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    form.resetFields();
    debounce(() => {
      form.setFieldsValue({
        ...currentItem,
      });
    }, 300);
  }, [currentItem]);

  return (
    <div
      className="bg-quaternary bordered-left bordered-bottom"
      onChange={debounceChange}
      style={{ minHeight: '100vh' }}
    >
      <Text
        className="text-size-large align-center p-medium flex cursor-pointer justify-between"
        weight="bold"
        onClick={_onCollapse}
      >
        <span>{currentItem.name} properties</span>
        <Icon type={'close'} />
      </Text>
      <Collapse
        defaultActiveKey={['general', 'appearance', 'configuration']}
        bordered={false}
        expandIconPosition={'right'}
      >
        {!_.isEmpty(currentItem.properties.general) && (
          <Panel header="General" key="general">
            {currentItem.properties.general.map((prop: IFormElementProperty) =>
              showProp(prop, 'general', currentItem.properties.general),
            )}
          </Panel>
        )}
        {!_.isEmpty(currentItem.properties.appearance) && (
          <Panel header="Appearance" key="appearance">
            {currentItem.properties.appearance.map((prop: IFormElementProperty) =>
              showProp(prop, 'appearance', currentItem.properties.appearance),
            )}
          </Panel>
        )}
        {!_.isEmpty(currentItem.properties.configuration) && !currentItem?.isHideConfig && (
          <Panel header="Configuration" key="configuration">
            {currentItem.properties.configuration.map((prop: IFormElementProperty) =>
              showProp(prop, 'configuration', currentItem.properties.configuration),
            )}
          </Panel>
        )}
        {_.isEmpty(currentItem.properties.general) &&
          _.isEmpty(currentItem.properties.appearance) &&
          _.isEmpty(currentItem.properties.configuration) && (
            <Text color="secondary" className="pl-medium">
              No options.
            </Text>
          )}
      </Collapse>
    </div>
  );
};
export default Form.create<IFormElementConfigurationPanelProps>()(FormElementConfigurationPanel);
