import React from 'react';
import { Text } from '@good/components';
import { Badge } from 'antd';

type ITabPaneContent = {
  label: string;
  count?: number | undefined;
};

const WorkLeaveTypesTabPaneContent = ({ label, count }: ITabPaneContent): JSX.Element => (
  <div className="align-center flex" style={{ gap: 8 }}>
    <Text className="font-semibold">{label}</Text>
    {count != null && (
      <Badge
        count={count}
        showZero
        style={{
          color: 'inherit',
          background: 'none',
          fontWeight: 'bold',
          boxShadow: '0 0 0 1px #EBEAE8 inset',
          borderRadius: 6,
        }}
      />
    )}
  </div>
);

export default WorkLeaveTypesTabPaneContent;
