import { useState, useEffect } from 'react';
export function useIsScrollable(scrollAreaRef, bodyRef) {
    const [isScrollable, setIsScrollable] = useState(false);
    useEffect(() => {
        if (scrollAreaRef.current && bodyRef.current && bodyRef.current.clientHeight > scrollAreaRef.current.clientHeight) {
            setIsScrollable(true);
            return;
        }
        setIsScrollable(false);
    }, [scrollAreaRef.current, bodyRef.current]);
    return isScrollable;
}
