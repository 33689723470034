import { Button, Text } from '@good/components';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IRootState, state } from 'stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';
import EditGeneralAvailabilityModal from '../../availability/components/Availability/EditGeneralAvailabilityModal';
import { Availabilities, MemberAvailabilities } from '../availability';
import AvailabilityBreakdownWeekly from './general-availability/availability-breakdown-weekly';
import AvailabilityRequests from './general-availability/availability-requests';

type GeneralAvailabilityProps = {
  portalUser: typeof state.authStore.portalUser;
  memberAvailabilities: MemberAvailabilities;
  hasEditPermission: boolean;
};
const GeneralAvailability = ({ memberAvailabilities, hasEditPermission, portalUser }: GeneralAvailabilityProps) => {
  const [showModal, setModal] = useState(false);
  const { t } = useTranslation('', { keyPrefix: 'teamMember.workDetails.generalAvailability' });

  return (
    <>
      <EditGeneralAvailabilityModalWithDataMapping
        isOpen={showModal}
        onClose={() => setModal(false)}
        memberAvailabilities={memberAvailabilities}
      />

      <div className="flex flex-col space-y-4">
        <div className="flex w-full flex-row items-center justify-between space-x-3">
          <div className="flex flex-col">
            <Text size="md" className="pb-1 pt-1 font-bold" asChild>
              <h4>{t('label')}</h4>
            </Text>
            <Text size="md" className="font-light text-neutral" asChild>
              <p>{t('description')}</p>
            </Text>
          </div>
          <div className="pb-4">
            {hasEditPermission && (
              <Button onClick={() => setModal(true)} size="sm" tone="accent" emphasis="outline">
                {t('editAvailabilityButtonLabel')}
              </Button>
            )}
          </div>
        </div>

        <div className="flex flex-row justify-between space-x-8 rounded-2xl bg-gray-light-2 p-4">
          <div className="basis-1/2">
            <AvailabilityBreakdownWeekly memberAvailabilities={memberAvailabilities} />
          </div>

          {PermissionUtils.validatePermission('ViewTeamMemberPermissions', portalUser.permissions.permissionRoles) && (
            <div className="flex basis-1/2 flex-col justify-center rounded-2xl bg-white ">
              <AvailabilityRequests />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const weekDayString = {
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday',
};

// EditGeneralAvailabilityModal needs the availability data mapped in a specific way.
// isolating that here so we don't have to worry about it elsewhere
const EditGeneralAvailabilityModalWithDataMapping = ({
  memberAvailabilities,
  isOpen,
  onClose,
}: {
  memberAvailabilities: MemberAvailabilities;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const mapAvailabilities = (availabilities: Availabilities[], availabilityTimezone: string) => {
    const localTimeZone = moment.tz.guess();
    const mappedAvailabilities = availabilities.map((availabilitity) => ({
      day: weekDayString[availabilitity.day] ?? '',
      number: availabilitity.number,
      timeRange: availabilitity.timeRange.map((time) => ({
        startDateTime: moment.tz(time.startTime, availabilityTimezone),
        endDateTime: moment.tz(time.endTime, availabilityTimezone),
        localStartDateTime: moment.tz(time.startTime, availabilityTimezone).tz(localTimeZone, true),
        localEndDateTime: moment.tz(time.endTime, availabilityTimezone).tz(localTimeZone, true),
        error: null,
      })),
      isAvailableAllDay: availabilitity.isAllDay,
      week: availabilitity.weeklyCycle,
    }));

    return mappedAvailabilities.sort((a, b) => a.number - b.number);
  };

  const availabilities = useMemo(
    () => mapAvailabilities(memberAvailabilities.availabilities, memberAvailabilities.availabilityTimezone),
    [memberAvailabilities],
  );

  return (
    <EditGeneralAvailabilityModal
      key={isOpen}
      isOpen={isOpen}
      onClose={onClose}
      dateArray={availabilities}
      userTimezone={memberAvailabilities.userTimezone}
      availabilityTimezone={memberAvailabilities.availabilityTimezone}
      cycle={memberAvailabilities.availabilityCycle}
    />
  );
};

export default connect(
  (state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    memberAvailabilities: state.teamStore.memberAvailabilities,
  }),
  null,
)(GeneralAvailability);
