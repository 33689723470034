import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const BookQueue = forwardRef(function BookQueue(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.25h-.055c-1.367 0-2.47 0-3.337.117-.9.12-1.658.38-2.26.981-.601.602-.86 1.36-.981 2.26-.117.867-.117 1.97-.117 3.337V15.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116H10.055c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337V8.945c0-1.367 0-2.47-.116-3.337-.122-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117H8ZM3.41 4.409c.276-.277.664-.457 1.398-.556C5.564 3.752 6.565 3.75 8 3.75h2c1.435 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4.101.754.103 1.756.103 3.191v6c0 1.435-.002 2.436-.103 3.192-.099.734-.28 1.122-.556 1.399-.277.277-.665.457-1.4.556-.754.101-1.756.103-3.19.103H8c-1.436 0-2.437-.002-3.193-.103-.734-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191V9c0-1.435.002-2.437.103-3.192.099-.734.28-1.122.556-1.399ZM6 6.25a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5H6ZM5.25 11a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75ZM6 14.25a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 0-1.5H6Zm13-9a.75.75 0 0 1 .75.75v12a.75.75 0 0 1-1.5 0V6a.75.75 0 0 1 .75-.75ZM22.75 8a.75.75 0 0 0-1.5 0v8a.75.75 0 1 0 1.5 0V8Z"
        fill="currentColor"
      />
    </Icon>
  );
});
