import { Col, Icon, Popover } from 'antd';
import { Avatar } from 'antd/es';
import { GhostButton } from 'common-components/buttons';
import { GridRow } from 'common-components/grids';
import { FieldValueText, Text } from 'common-components/typography';
import { ICustomerConnectionRequest } from 'interfaces/customer-interfaces';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment';
import React, { Component } from 'react';

interface IActiveConnectionRowProps {
  connection: ICustomerConnectionRequest;
  openCancelConfirmation: (selectedConnection: string) => void;
}

interface IActiveConnectionRowState {}

class ActiveConnectionRow extends Component<IActiveConnectionRowProps, IActiveConnectionRowState> {
  render() {
    const { connection, openCancelConfirmation } = this.props;

    return (
      <GridRow containerClassName={'bg-white'}>
        <Col span={5}>
          <div className="flex-row align-center">
            <Avatar className="mr-medium" size="large" icon="user" src={connection.attachmentUrl} />
            <div>
              <div>
                <Text weight="bold">{connection.firstName + ' ' + connection.lastName}</Text>
              </div>
              {connection.isDependent && (
                <div>
                  <Text>Parent/Guardian</Text>
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col span={11}>
          <Col className="ph-none" span={10}>
            <FieldValueText
              text={parsePhoneNumberFromString(connection.mobile, connection.mobileCountryCode).format('INTERNATIONAL')}
            />
          </Col>
          <Col className="ph-none" span={14}>
            <FieldValueText text={connection.email} />
          </Col>
        </Col>
        <Col span={3}>
          <FieldValueText text={moment(connection.createdOn).format('D MMMM YYYY')} />
        </Col>
        <Col span={4}>
          {/*<Tooltip placement="bottomRight" overlay={<Text color="white">This is a tooltip</Text>}>*/}
          <Text className="">{connection.status === 'CONNECTED' ? 'Connected' : 'Pending'}</Text>
          {/*<Icon type="question-circle" theme={'filled'} style={{ color: '#0083FF' }} className="ml-small" />*/}
          <FieldValueText
            text={
              connection.status !== 'CONNECTED'
                ? 'Expires on ' + moment(connection.tokenExpireDate).format('D MMMM YYYY')
                : moment(connection.tokenExpireDate).format('D MMMM YYYY')
            }
          />
          {/*</Tooltip>*/}
        </Col>
        <Col span={1}>
          <Popover
            placement="bottomRight"
            className="cursor-pointer"
            content={
              <div>
                {/*{connection.status === 'CONNECTED' && <GhostButton>Cancel connection request</GhostButton>}*/}
                {connection.status !== 'CONNECTED' && (
                  <GhostButton
                    color={'red'}
                    onClick={() => openCancelConfirmation(connection.customerConnectionRequestId)}
                  >
                    Cancel connection request
                  </GhostButton>
                )}
              </div>
            }
          >
            <Icon type={'ellipsis'} />
          </Popover>
        </Col>
      </GridRow>
    );
  }
}

export default ActiveConnectionRow;
