import { tv } from 'tailwind-variants';

import type { VariantProps } from 'tailwind-variants';

export const heading = tv(
  {
    base: ['break-words', 'font-base', 'font-bold', 'm-0', 'text-strong'],
    variants: {
      size: {
        xl: ['leading-h-xl', 'text-h-xl'],
        lg: ['leading-h-lg', 'text-h-lg'],
        md: ['leading-h-md', 'text-h-md'],
        sm: ['leading-h-sm', 'text-h-sm'],
        xs: ['leading-h-xs', 'text-h-xs'],
        '2xs': ['leading-h-2xs', 'text-h-2xs', 'uppercase'],
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
  { twMerge: false },
);

type Variants = Required<VariantProps<typeof heading>>;

export type HeadingVariants = {
  /**
   * Change the `font-size` and `line-height` properties along our
   * typographic scale.
   * @default md
   */
  size?: Variants['size'];
};
