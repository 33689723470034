import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Schema } from '../schema';
import fields from '../fields';

function useFocusAndResetCustomDurationNumber(form: UseFormReturn<Schema>) {
  const { watch, resetField, setFocus } = form;

  const teamMemberPaymentDuration = watch(fields.teamMemberPaymentDuration.name);
  const customTeamMemberPaymentDuration = watch(fields.customTeamMemberPaymentDuration.name);

  useEffect(() => {
    if (teamMemberPaymentDuration === fields.teamMemberPaymentDuration.options.customDuration) {
      setFocus(fields.customTeamMemberPaymentDuration.name);
    } else {
      resetField(fields.customTeamMemberPaymentDuration.name, {
        defaultValue: fields.customTeamMemberPaymentDuration.defaultValue,
      });
    }
  }, [teamMemberPaymentDuration, customTeamMemberPaymentDuration]);
}

export default useFocusAndResetCustomDurationNumber;
