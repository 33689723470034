import React from 'react';
import { Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import { TeamMemberCustomerRatio } from 'utilities/enum-utils';
import { EditRatiosSchema } from './schema';

export function DefaultBookingRatioSelect() {
  const form = useFormContext<EditRatiosSchema>();

  return (
    <Controller
      name="teamMemberCustomerRatio"
      control={form.control}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Select onChange={onChange} style={{ width: '165px' }} size="large" value={value} onBlur={onBlur} ref={ref}>
          {Object.values(TeamMemberCustomerRatio).map((ratio) => {
            return (
              <Select.Option value={ratio} key={ratio}>
                {ratio}
              </Select.Option>
            );
          })}
        </Select>
      )}
    />
  );
}
