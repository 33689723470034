import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ClipboardCross = forwardRef(function ClipboardCross(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.905 3.78c.364-.027.82-.03 1.448-.03a2.751 2.751 0 0 0 2.647 2h2c1.259 0 2.32-.846 2.646-2 .629 0 1.084.003 1.449.03.398.028.629.08.805.158.52.227.935.642 1.162 1.162.077.176.13.407.159.805.028.405.029.923.029 1.666V15c0 1.435-.002 2.436-.103 3.192-.099.734-.28 1.122-.556 1.399-.277.277-.666.457-1.4.556-.755.101-1.756.103-3.191.103h-4c-1.436 0-2.437-.002-3.192-.103-.734-.099-1.122-.28-1.4-.556-.276-.277-.456-.665-.555-1.4-.102-.755-.103-1.756-.103-3.191V7.571c0-.743 0-1.26.03-1.666.028-.398.08-.629.158-.805A2.25 2.25 0 0 1 6.1 3.938c.176-.077.407-.13.805-.159Zm10.297-1.497c-.423-.03-.934-.033-1.556-.033A2.751 2.751 0 0 0 13 .25h-2c-1.259 0-2.32.846-2.647 2-.621 0-1.132.003-1.555.033-.478.034-.9.106-1.298.28A3.75 3.75 0 0 0 3.563 4.5c-.174.398-.246.82-.28 1.298-.033.465-.033 1.037-.033 1.745v7.512c0 1.367 0 2.47.116 3.337.121.9.38 1.658.982 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116H14.055c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.601-.602.86-1.36.981-2.26.117-.867.117-1.97.117-3.337V7.543c0-.708 0-1.28-.033-1.745-.034-.478-.107-.9-.28-1.298a3.75 3.75 0 0 0-1.938-1.937c-.397-.174-.82-.246-1.297-.28ZM11 1.75a1.25 1.25 0 0 0 0 2.5h2a1.25 1.25 0 1 0 0-2.5h-2Zm-.47 7.72a.75.75 0 0 0-1.06 1.06L10.94 12l-1.47 1.47a.75.75 0 1 0 1.06 1.06L12 13.06l1.47 1.47a.75.75 0 1 0 1.06-1.06L13.06 12l1.47-1.47a.75.75 0 1 0-1.06-1.06L12 10.94l-1.47-1.47Z"
        fill="currentColor"
      />
    </Icon>
  );
});
