import { HyperlinkButton } from 'common-components/buttons';
import { format } from 'date-fns';
import React from 'react';
import { ServiceType } from 'utilities/enum-utils';

type DateDisplayProps = {
  lineItem: { serviceType: ServiceType; startDateTime: string; attendanceId: string };
  onClick: (attendanceId: string) => void;
};

export const DateDisplay = ({ lineItem, onClick }: DateDisplayProps) => {
  const { serviceType, startDateTime, attendanceId } = lineItem;
  const formattedStartDate = format(new Date(startDateTime), 'dd/MM/yyyy');

  return (
    <>
      {[ServiceType.GROUP, ServiceType.INDEPENDENT_LIVING].includes(serviceType) ? (
        formattedStartDate
      ) : (
        <HyperlinkButton onClick={() => onClick(attendanceId)}>{formattedStartDate}</HyperlinkButton>
      )}
    </>
  );
};
