import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { DateCell } from '../components/DateCell';
import { ServiceNameCell } from '../components/ServiceNameCell';
import { SignedStatusCell } from '../components/SignedStatusCell';
import { ServiceAgreementTableData } from '../types';

export const useTableColumns = () =>
  useMemo<ColumnDef<ServiceAgreementTableData>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'SERVICES',
        size: 336,
        cell: ({ row }) => <ServiceNameCell row={row} />,
      },
      {
        accessorKey: 'signedStatus',
        header: 'STATUS',
        size: 200,
        cell: ({ row }) => <SignedStatusCell row={row} />,
      },
      {
        accessorKey: 'date',
        header: 'DATE',
        size: 239,
        cell: ({ row }) => <DateCell row={row} />,
      },
      {
        accessorKey: 'createdBy',
        header: 'CREATED BY',
        size: 200,
      },
      {
        accessorKey: 'lastModified',
        header: 'LAST MODIFIED',
        size: 200,
        cell: ({ getValue }) => {
          const lastModified = getValue() as string;
          const formattedEndDate = format(parseISO(lastModified), 'MMM do yyyy');

          return (
            <div className="text-size-x-small" style={{ color: '#706F6D' }}>
              {formattedEndDate}
            </div>
          );
        },
      },
    ],
    [],
  );
