import React from 'react';

import { Avatar } from 'antd';
import { Popover } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import { Text } from 'common-components/typography';
import { IconButton } from 'common-components/buttons';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import CommonUtils from 'utilities/common-utils';

import { ROSTER_WEEKLY_CONFIG } from 'common-components/roster-control/roster-weekly/roster-weekly-config';

const { CONTENT_PANEL_WIDTH } = ROSTER_WEEKLY_CONFIG;

export function CustomerDetailsColumn({
  customerId,
  customerName,
  revenueAmount,
  avatarUrl,
  history,
}: {
  customerId: string;
  customerName: string;
  revenueAmount: number;
  avatarUrl: string;
  history;
}) {
  return (
    <div
      className="flex-row pv-large pl-x-large pr-medium bg-quaternary justify-between bordered-right bordered-bottom"
      style={{
        minWidth: `${CONTENT_PANEL_WIDTH}px`,
        maxWidth: `${CONTENT_PANEL_WIDTH}px`,
      }}
    >
      <div className="flex-row flex-1">
        <Avatar shape="circle" size="large" icon="user" className="mr-small" src={avatarUrl} />
        <div
          className="line-height-135 block text-overflow-ellipsis overflow-hidden whitespace-nowrap"
          style={{ width: `${CONTENT_PANEL_WIDTH - 128}px` }}
        >
          <Text lineHeight={120}>{customerName}</Text>
          <br />
          <Text size="regular" color="secondary" lineHeight={135}>
            {CommonUtils.formatPrice(revenueAmount)}
          </Text>
        </div>
      </div>

      <Popover
        content={
          <ActionMenu>
            <Link to={`/customer/details/${customerId}`} className="underline-none">
              <ActionMenuItem text={'View customer profile'} />
            </Link>
          </ActionMenu>
        }
        position={'bottom-left'}
      >
        <IconButton
          icon="ellipsis"
          size="small"
          className="bg-white"
          bordered={true}
          iconColor="secondary"
          onClick={() => {}}
        />
      </Popover>
    </div>
  );
}
