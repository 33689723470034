import React, { useState } from 'react';
import { Paragraph } from 'common-components/typography';
import { notification } from 'antd';
import { Warning } from 'common-components/alerts';
import Utils from 'utilities/Utils';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';
import { Modal, Button } from 'design-components';

type InviteUserModalParam = {
  isOpen: boolean;
  onClose: () => void;
  worker: object;
};

export const InviteUserModal = ({ isOpen, onClose, worker }: InviteUserModalParam) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    teamStore: { doInviteTeamMember },
  } = useDispatch<IRootDispatch>();

  const onSave = async () => {
    try {
      setIsLoading(true);
      await doInviteTeamMember({ supportWorkerId: worker.supportWorkerId });

      notification.success({ message: 'Invite sent successfully' });
      setIsLoading(false);
      onClose();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  if (!worker?.supportWorkerId) {
    return <></>;
  }

  return (
    <Modal
      maxHeight={602}
      maxWidth={600}
      size="large"
      header="Invite User"
      onClose={onClose}
      isOpen={isOpen}
      footer={
        <div className=" flex flex-nowrap gap-4">
          <Button size="medium" emphasis="quiet" kind="accent" onClick={onClose}>
            Cancel
          </Button>
          {!Utils.isEmpty(worker.email) && (
            <Button size="medium" emphasis="filled" kind="accent" isLoading={isLoading} onClick={onSave}>
              Invite User
            </Button>
          )}
        </div>
      }
    >
      <div className="mv-medium">
        <div>
          {!Utils.isEmpty(worker.email) ? (
            <>
              <Paragraph>
                Are you sure you want to invite <b>{`${worker.firstName} ${worker.lastName}`}</b>. This will enable
                their profile and allow them to be assigned to rosters and bookings.
              </Paragraph>
              <Paragraph>
                The invite will be sent to the following email: <b>{worker.email}</b>
              </Paragraph>
            </>
          ) : (
            <Warning content="This user has no email set, please add an email to this user's profile before inviting them." />
          )}
        </div>
      </div>
    </Modal>
  );
};
