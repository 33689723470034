import { v4 as uuid } from 'uuid';
import {
  AFFECT_TO_ELEMENT_PROPERTY,
  CAPTION_TEXT_PROPERTY,
  EVENT_PROPERTY,
  FIELD_TITLE_PROPERTY,
  PLACEHOLDER_PROPERTY,
  REPEATABLE_PROPERTY,
  REQUIRED_PROPERTY,
  RULES_PROPERTY,
  TARGET_ELEMENT_PROPERTY,
  TARGET_OPTION_PROPERTY,
} from '../common-field-constants';
import { LabelMultipleElements } from '../common-label-constants';
import { FormElementType, IntakeFormElementType, PropKey, PropType, UserActionType } from '../form-enum';
import { IFormElement } from '../form-interface';

const ALERT_LEVEL_ELEMENT: IFormElement = {
  id: uuid(),
  name: 'Alert Level',
  type: FormElementType.SINGLE_CHOICE,
  properties: {
    general: [{ ...FIELD_TITLE_PROPERTY, value: 'Alert Level' }, REQUIRED_PROPERTY],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: PropKey.OPTIONS,
        name: 'OPTIONS',
        value: ['High', 'Medium', 'Low'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const DESCRIPTION_ELEMENT: IFormElement = {
  id: uuid(),
  name: 'Description',
  type: FormElementType.LONG_TEXT,
  properties: {
    general: [
      { ...FIELD_TITLE_PROPERTY, value: 'Description' },
      { ...REQUIRED_PROPERTY, value: false },
    ],
    appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter any additional information' }, CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};

//#region Medication
const ADD_ANOTHER_MEDICATION_ELEMENT_ID = uuid();
const ADDITIONAL_MEDICATION_ELEMENT_ID = uuid();

const RADIO_ADD_ANOTHER_MEDICATION: IFormElement = {
  id: ADD_ANOTHER_MEDICATION_ELEMENT_ID,
  parentId: ADDITIONAL_MEDICATION_ELEMENT_ID,
  name: 'Add another medication',
  type: FormElementType.SINGLE_CHOICE,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Would you like to add another medication?',
        fieldType: IntakeFormElementType.CARE_INFORMATION_CHECK_ADD_ANOTHER_MEDICATION,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: PropKey.OPTIONS,
        name: 'OPTIONS',
        value: ['Yes', 'No'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const ADD_MEDICATION_DETAIL: IFormElement[] = [
  {
    id: uuid(),
    parentId: ADDITIONAL_MEDICATION_ELEMENT_ID,
    name: 'Prescription and non prescription',
    type: FormElementType.HEADER,
    properties: {
      general: [],
      appearance: [
        {
          key: PropKey.HEADING_TEXT,
          name: 'HEADING TEXT',
          value: 'Medication management',
          rules: [
            { required: true, message: 'Please enter headline' },
            {
              max: 200,
              message: 'Max length of characters is 200',
            },
          ],
          placeholder: 'Heading Text',
          caption: 'Enter heading text for this field.',
        },
        {
          key: PropKey.SUBHEADING_TEXT,
          name: 'SUBHEADING TEXT (OPTIONAL)',
          value: '',
          rules: [
            {
              max: 1000,
              message: 'Max length of characters is 1000',
            },
          ],
          placeholder: 'Subheading Text',
          caption: 'Accompanying subheading text for this header.',
        },
      ],
      configuration: [],
    },
  },
  {
    id: uuid(),
    parentId: ADDITIONAL_MEDICATION_ELEMENT_ID,
    name: 'Medication name',
    type: FormElementType.SHORT_TEXT,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Medication name',
          fieldType: IntakeFormElementType.CARE_INFORMATION_MEDICATION_NAME,
        },
        REQUIRED_PROPERTY,
      ],
      appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter medication' }, CAPTION_TEXT_PROPERTY],
      configuration: [],
    },
  },
  {
    id: uuid(),
    parentId: ADDITIONAL_MEDICATION_ELEMENT_ID,
    name: 'For the treatment of',
    type: FormElementType.SHORT_TEXT,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'For the treatment of',
          fieldType: IntakeFormElementType.CARE_INFORMATION_TREATMENT,
        },
        REQUIRED_PROPERTY,
      ],
      appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter what medication is for' }, CAPTION_TEXT_PROPERTY],
      configuration: [],
    },
  },
  {
    ...DESCRIPTION_ELEMENT,
    properties: {
      ...DESCRIPTION_ELEMENT.properties,
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Description',
          fieldType: IntakeFormElementType.CARE_INFORMATION_MEDICATION_DESCRIPTION,
        },
        { ...REQUIRED_PROPERTY, value: false },
      ],
    },
    parentId: ADDITIONAL_MEDICATION_ELEMENT_ID,
  },
  {
    ...ALERT_LEVEL_ELEMENT,
    properties: {
      ...ALERT_LEVEL_ELEMENT.properties,
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Alert Level',
          fieldType: IntakeFormElementType.CARE_INFORMATION_MEDICATION_ALERT_LEVEL,
        },
        REQUIRED_PROPERTY,
      ],
    },
    parentId: ADDITIONAL_MEDICATION_ELEMENT_ID,
  },
  RADIO_ADD_ANOTHER_MEDICATION,
];

const MEDICATION_BUNDLE: IFormElement = {
  id: ADDITIONAL_MEDICATION_ELEMENT_ID,
  name: 'Medication',
  type: FormElementType.MULTIPLE_ELEMENT,
  label: LabelMultipleElements,
  isHideConfig: true,
  properties: {
    general: [REQUIRED_PROPERTY],
    appearance: [],
    configuration: [],
  },
  children: [],
};

const CONDITIONAL_ADD_MEDICATION: IFormElement = {
  id: uuid(),
  parentId: ADDITIONAL_MEDICATION_ELEMENT_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: ADD_ANOTHER_MEDICATION_ELEMENT_ID,
        options: [RADIO_ADD_ANOTHER_MEDICATION],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'Yes',
        options: ['Yes', 'No'],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [ADDITIONAL_MEDICATION_ELEMENT_ID],
        options: [MEDICATION_BUNDLE],
      },
      {
        ...REPEATABLE_PROPERTY,
        value: true,
      },
    ],
  },
};

MEDICATION_BUNDLE.children.push(...ADD_MEDICATION_DETAIL);
MEDICATION_BUNDLE.children.push(CONDITIONAL_ADD_MEDICATION);
//#endregion

//#region Support
const ADD_ANOTHER_SUPPORT_ELEMENT_ID = uuid();
const ADDITIONAL_SUPPORT_ELEMENT_ID = uuid();

const RADIO_ADD_ANOTHER_SUPPORT: IFormElement = {
  id: ADD_ANOTHER_SUPPORT_ELEMENT_ID,
  parentId: ADDITIONAL_SUPPORT_ELEMENT_ID,
  name: 'Add another support',
  type: FormElementType.SINGLE_CHOICE,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Would you like to add another support need?',
        fieldType: IntakeFormElementType.CARE_INFORMATION_CHECK_ADD_ANOTHER_SUPPORT_NEED,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: PropKey.OPTIONS,
        name: 'OPTIONS',
        value: ['Yes', 'No'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const ADD_SUPPORT_DETAIL: IFormElement[] = [
  {
    id: uuid(),
    parentId: ADDITIONAL_SUPPORT_ELEMENT_ID,
    name: 'Support needed header',
    type: FormElementType.HEADER,
    properties: {
      general: [],
      appearance: [
        {
          key: PropKey.HEADING_TEXT,
          name: 'HEADING TEXT',
          value:
            'Please add any support needs (such as assistance bathing, walking, changing clothes and eating) here.',
          rules: [
            { required: true, message: 'Please enter headline' },
            {
              max: 200,
              message: 'Max length of characters is 200',
            },
          ],
          placeholder: 'Heading Text',
          caption: 'Enter heading text for this field.',
        },
        {
          key: PropKey.SUBHEADING_TEXT,
          name: 'SUBHEADING TEXT (OPTIONAL)',
          value: '',
          rules: [
            {
              max: 1000,
              message: 'Max length of characters is 1000',
            },
          ],
          placeholder: 'Subheading Text',
          caption: 'Accompanying subheading text for this header.',
        },
      ],
      configuration: [],
    },
  },
  {
    id: uuid(),
    parentId: ADDITIONAL_SUPPORT_ELEMENT_ID,
    name: 'Support need',
    type: FormElementType.SHORT_TEXT,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Support need',
          fieldType: IntakeFormElementType.CARE_INFORMATION_SUPPORT_NEED,
        },
        REQUIRED_PROPERTY,
        {
          ...RULES_PROPERTY,
          rules: [
            { min: 3, message: 'Support need must be between 3 to 50 characters' },
            { max: 50, message: 'Support need must be between 3 to 50 characters' },
          ],
        },
      ],
      appearance: [
        {
          ...PLACEHOLDER_PROPERTY,
          value: 'Enter support here (eg. assistance bathing, walking, changing clothes and eating).',
        },
        CAPTION_TEXT_PROPERTY,
      ],
      configuration: [],
    },
  },
  {
    ...DESCRIPTION_ELEMENT,
    properties: {
      ...DESCRIPTION_ELEMENT.properties,
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Description',
          fieldType: IntakeFormElementType.CARE_INFORMATION_SUPPORT_NEED_DESCRIPTION,
        },
        { ...REQUIRED_PROPERTY, value: false },
      ],
    },
    parentId: ADDITIONAL_SUPPORT_ELEMENT_ID,
  },
  {
    ...ALERT_LEVEL_ELEMENT,
    properties: {
      ...ALERT_LEVEL_ELEMENT.properties,
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Alert Level',
          fieldType: IntakeFormElementType.CARE_INFORMATION_SUPPORT_NEED_ALERT_LEVEL,
        },
        REQUIRED_PROPERTY,
      ],
    },
    parentId: ADDITIONAL_SUPPORT_ELEMENT_ID,
  },
  RADIO_ADD_ANOTHER_SUPPORT,
];

const SUPPORT_BUNDLE: IFormElement = {
  id: ADDITIONAL_SUPPORT_ELEMENT_ID,
  name: 'Support needs',
  type: FormElementType.MULTIPLE_ELEMENT,
  label: LabelMultipleElements,
  isHideConfig: true,
  properties: {
    general: [REQUIRED_PROPERTY],
    appearance: [],
    configuration: [],
  },
  children: [],
};

const CONDITIONAL_ADD_SUPPORT: IFormElement = {
  id: uuid(),
  parentId: ADDITIONAL_SUPPORT_ELEMENT_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: ADD_ANOTHER_SUPPORT_ELEMENT_ID,
        options: [RADIO_ADD_ANOTHER_SUPPORT],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'Yes',
        options: ['Yes', 'No'],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [ADDITIONAL_SUPPORT_ELEMENT_ID],
        options: [SUPPORT_BUNDLE],
      },
      {
        ...REPEATABLE_PROPERTY,
        value: true,
      },
    ],
  },
};

SUPPORT_BUNDLE.children.push(...ADD_SUPPORT_DETAIL);
SUPPORT_BUNDLE.children.push(CONDITIONAL_ADD_SUPPORT);
//#endregion

//#region Behavior
const ADD_ANOTHER_BEHAVIOUR_ELEMENT_ID = uuid();
const ADDITIONAL_BEHAVIOUR_ELEMENT_ID = uuid();

const RADIO_ADD_ANOTHER_BEHAVIOR: IFormElement = {
  id: ADD_ANOTHER_BEHAVIOUR_ELEMENT_ID,
  parentId: ADDITIONAL_BEHAVIOUR_ELEMENT_ID,
  name: 'Add another behaviour',
  type: FormElementType.SINGLE_CHOICE,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Would you like to add another behaviour?',
        fieldType: IntakeFormElementType.CARE_INFORMATION_CHECK_ADD_ANOTHER_BEHAVIOUR,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: PropKey.OPTIONS,
        name: 'OPTIONS',
        value: ['Yes', 'No'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const ADD_BEHAVIOR_DETAIL: IFormElement[] = [
  {
    id: uuid(),
    parentId: ADDITIONAL_BEHAVIOUR_ELEMENT_ID,
    name: 'Behaviour management',
    type: FormElementType.HEADER,
    properties: {
      general: [],
      appearance: [
        {
          key: PropKey.HEADING_TEXT,
          name: 'HEADING TEXT',
          value: 'Behaviour and management strategies',
          rules: [
            { required: true, message: 'Please enter headline' },
            {
              max: 200,
              message: 'Max length of characters is 200',
            },
          ],
          placeholder: 'Heading Text',
          caption: 'Enter heading text for this field.',
        },
        {
          key: PropKey.SUBHEADING_TEXT,
          name: 'SUBHEADING TEXT (OPTIONAL)',
          value: '',
          rules: [
            {
              max: 1000,
              message: 'Max length of characters is 1000',
            },
          ],
          placeholder: 'Subheading Text',
          caption: 'Accompanying subheading text for this header.',
        },
      ],
      configuration: [],
    },
  },
  {
    id: uuid(),
    parentId: ADDITIONAL_BEHAVIOUR_ELEMENT_ID,
    name: 'Behaviour',
    type: FormElementType.SHORT_TEXT,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Behaviour',
          fieldType: IntakeFormElementType.CARE_INFORMATION_BEHAVIOUR,
        },
        REQUIRED_PROPERTY,
      ],
      appearance: [
        {
          ...PLACEHOLDER_PROPERTY,
          value: 'Behaviour type (e.g. Repetitive motor mannerism, insistence of same daily routine)',
        },
        CAPTION_TEXT_PROPERTY,
      ],
      configuration: [],
    },
  },
  {
    ...DESCRIPTION_ELEMENT,
    properties: {
      ...DESCRIPTION_ELEMENT.properties,
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Description',
          fieldType: IntakeFormElementType.CARE_INFORMATION_BEHAVIOUR_DESCRIPTION,
        },
        { ...REQUIRED_PROPERTY, value: false },
      ],
    },
    parentId: ADDITIONAL_BEHAVIOUR_ELEMENT_ID,
  },
  {
    id: uuid(),
    parentId: ADDITIONAL_BEHAVIOUR_ELEMENT_ID,
    name: 'Management strategy',
    type: FormElementType.SHORT_TEXT,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Management strategy',
          fieldType: IntakeFormElementType.CARE_INFORMATION_MANAGEMENT_STRATEGY,
        },
        REQUIRED_PROPERTY,
      ],
      appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter any management strategy here' }, CAPTION_TEXT_PROPERTY],
      configuration: [],
    },
  },
  {
    ...ALERT_LEVEL_ELEMENT,
    properties: {
      ...ALERT_LEVEL_ELEMENT.properties,
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Alert Level',
          fieldType: IntakeFormElementType.CARE_INFORMATION_BEHAVIOUR_ALERT_LEVEL,
        },
        REQUIRED_PROPERTY,
      ],
    },
    parentId: ADDITIONAL_BEHAVIOUR_ELEMENT_ID,
  },
  RADIO_ADD_ANOTHER_BEHAVIOR,
];

const BEHAVIOR_BUNDLE: IFormElement = {
  id: ADDITIONAL_BEHAVIOUR_ELEMENT_ID,
  name: 'Behaviour',
  type: FormElementType.MULTIPLE_ELEMENT,
  label: LabelMultipleElements,
  isHideConfig: true,
  properties: {
    general: [REQUIRED_PROPERTY],
    appearance: [],
    configuration: [],
  },
  children: [],
};

const CONDITIONAL_ADD_BEHAVIOR: IFormElement = {
  id: uuid(),
  parentId: ADDITIONAL_BEHAVIOUR_ELEMENT_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: ADD_ANOTHER_BEHAVIOUR_ELEMENT_ID,
        options: [RADIO_ADD_ANOTHER_BEHAVIOR],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'Yes',
        options: ['Yes', 'No'],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [ADDITIONAL_BEHAVIOUR_ELEMENT_ID],
        options: [BEHAVIOR_BUNDLE],
      },
      {
        ...REPEATABLE_PROPERTY,
        value: true,
      },
    ],
  },
};

BEHAVIOR_BUNDLE.children.push(...ADD_BEHAVIOR_DETAIL);
BEHAVIOR_BUNDLE.children.push(CONDITIONAL_ADD_BEHAVIOR);
//#endregion

//#region General care information
const ADD_ANOTHER_CARE_INFORMATION_ELEMENT_ID = uuid();
const ADDITIONAL_CARE_INFORMATION_ELEMENT_ID = uuid();

const RADIO_ADD_ANOTHER_CARE_INFORMATION: IFormElement = {
  id: ADD_ANOTHER_CARE_INFORMATION_ELEMENT_ID,
  parentId: ADDITIONAL_CARE_INFORMATION_ELEMENT_ID,
  name: 'Add more general care information',
  type: FormElementType.SINGLE_CHOICE,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Would you like to add more general care information?',
        fieldType: IntakeFormElementType.CARE_INFORMATION_CHECK_ADD_MORE_GENERAL_INFORMATION,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: PropKey.OPTIONS,
        name: 'OPTIONS',
        value: ['Yes', 'No'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const ADD_CARE_INFORMATION_DETAIL: IFormElement[] = [
  {
    id: uuid(),
    parentId: ADDITIONAL_CARE_INFORMATION_ELEMENT_ID,
    name: 'General care information',
    type: FormElementType.HEADER,
    properties: {
      general: [],
      appearance: [
        {
          key: PropKey.HEADING_TEXT,
          name: 'HEADING TEXT',
          value: 'Add any other important support information.',
          rules: [
            { required: true, message: 'Please enter headline' },
            {
              max: 200,
              message: 'Max length of characters is 200',
            },
          ],
          placeholder: 'Heading Text',
          caption: 'Enter heading text for this field.',
        },
        {
          key: PropKey.SUBHEADING_TEXT,
          name: 'SUBHEADING TEXT (OPTIONAL)',
          value: '',
          rules: [
            {
              max: 1000,
              message: 'Max length of characters is 1000',
            },
          ],
          placeholder: 'Subheading Text',
          caption: 'Accompanying subheading text for this header.',
        },
      ],
      configuration: [],
    },
  },
  {
    id: uuid(),
    parentId: ADDITIONAL_CARE_INFORMATION_ELEMENT_ID,
    name: 'General information',
    type: FormElementType.SHORT_TEXT,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'General information',
          fieldType: IntakeFormElementType.CARE_INFORMATION_GENERAL_INFORMATION,
        },
        REQUIRED_PROPERTY,
      ],
      appearance: [
        { ...PLACEHOLDER_PROPERTY, value: 'Enter any other important care information' },
        CAPTION_TEXT_PROPERTY,
      ],
      configuration: [],
    },
  },
  {
    ...DESCRIPTION_ELEMENT,
    properties: {
      ...DESCRIPTION_ELEMENT.properties,
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Description',
          fieldType: IntakeFormElementType.CARE_INFORMATION_GENERAL_INFORMATION_DESCRIPTION,
        },
        { ...REQUIRED_PROPERTY, value: false },
      ],
    },
    parentId: ADDITIONAL_CARE_INFORMATION_ELEMENT_ID,
  },
  {
    ...ALERT_LEVEL_ELEMENT,
    properties: {
      ...ALERT_LEVEL_ELEMENT.properties,
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Alert Level',
          fieldType: IntakeFormElementType.CARE_INFORMATION_GENERAL_INFORMATION_ALERT_LEVEL,
        },
        REQUIRED_PROPERTY,
      ],
    },
    parentId: ADDITIONAL_CARE_INFORMATION_ELEMENT_ID,
  },
  RADIO_ADD_ANOTHER_CARE_INFORMATION,
];

const CARE_INFORMATION_BUNDLE: IFormElement = {
  id: ADDITIONAL_CARE_INFORMATION_ELEMENT_ID,
  name: 'General care information',
  type: FormElementType.MULTIPLE_ELEMENT,
  label: LabelMultipleElements,
  isHideConfig: true,
  properties: {
    general: [REQUIRED_PROPERTY],
    appearance: [],
    configuration: [],
  },
  children: [],
};

const CONDITIONAL_ADD_CARE_INFORMATION: IFormElement = {
  id: uuid(),
  parentId: ADDITIONAL_CARE_INFORMATION_ELEMENT_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: ADD_ANOTHER_CARE_INFORMATION_ELEMENT_ID,
        options: [RADIO_ADD_ANOTHER_CARE_INFORMATION],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'Yes',
        options: ['Yes', 'No'],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [ADDITIONAL_CARE_INFORMATION_ELEMENT_ID],
        options: [CARE_INFORMATION_BUNDLE],
      },
      {
        ...REPEATABLE_PROPERTY,
        value: true,
      },
    ],
  },
};

CARE_INFORMATION_BUNDLE.children.push(...ADD_CARE_INFORMATION_DETAIL);
CARE_INFORMATION_BUNDLE.children.push(CONDITIONAL_ADD_CARE_INFORMATION);
//#endregion

//#region Permanent conditions
const ADD_ANOTHER_PERMANENT_CONDITIONS_ELEMENT_ID = uuid();
const ADDITIONAL_PERMANENT_CONDITIONS_ELEMENT_ID = uuid();

const RADIO_ADD_ANOTHER_PERMANENT_CONDITIONS: IFormElement = {
  id: ADD_ANOTHER_PERMANENT_CONDITIONS_ELEMENT_ID,
  parentId: ADDITIONAL_PERMANENT_CONDITIONS_ELEMENT_ID,
  name: 'Add another permanent condition',
  type: FormElementType.SINGLE_CHOICE,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Would you like to add another permanent condition?',
        fieldType: IntakeFormElementType.CARE_INFORMATION_CHECK_ADD_ANOTHER_PERMANENT_CONDTION,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: PropKey.OPTIONS,
        name: 'OPTIONS',
        value: ['Yes', 'No'],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
    ],
  },
};

const ADD_PERMANENT_CONDITIONS_DETAIL: IFormElement[] = [
  {
    id: uuid(),
    parentId: ADDITIONAL_PERMANENT_CONDITIONS_ELEMENT_ID,
    name: 'Permanent conditions',
    type: FormElementType.HEADER,
    properties: {
      general: [],
      appearance: [
        {
          key: PropKey.HEADING_TEXT,
          name: 'HEADING TEXT',
          value: 'Please share any permanent conditions.',
          rules: [
            { required: true, message: 'Please enter headline' },
            {
              max: 200,
              message: 'Max length of characters is 200',
            },
          ],
          placeholder: 'Heading Text',
          caption: 'Enter heading text for this field.',
        },
        {
          key: PropKey.SUBHEADING_TEXT,
          name: 'SUBHEADING TEXT (OPTIONAL)',
          value: '',
          rules: [
            {
              max: 1000,
              message: 'Max length of characters is 1000',
            },
          ],
          placeholder: 'Subheading Text',
          caption: 'Accompanying subheading text for this header.',
        },
      ],
      configuration: [],
    },
  },
  {
    id: uuid(),
    parentId: ADDITIONAL_PERMANENT_CONDITIONS_ELEMENT_ID,
    name: 'Permanent condition',
    type: FormElementType.DROPDOWN,
    isHideConfig: true,
    properties: {
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Permanent condition',
          fieldType: IntakeFormElementType.CARE_INFORMATION_PERMANENT_CONDITION,
        },
        REQUIRED_PROPERTY,
      ],
      appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Enter permanent condition' }],
      configuration: [
        {
          key: PropKey.OPTIONS,
          name: 'OPTIONS',
          value: [],
          caption: 'Option that will be shown for this input field.',
          type: PropType.options,
        },
      ],
    },
  },
  {
    ...DESCRIPTION_ELEMENT,
    properties: {
      ...DESCRIPTION_ELEMENT.properties,
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Description',
          fieldType: IntakeFormElementType.CARE_INFORMATION_PERMANENT_CONDITION_DESCRIPTION,
        },
        { ...REQUIRED_PROPERTY, value: false },
      ],
    },
    parentId: ADDITIONAL_PERMANENT_CONDITIONS_ELEMENT_ID,
  },
  {
    ...ALERT_LEVEL_ELEMENT,
    properties: {
      ...ALERT_LEVEL_ELEMENT.properties,
      general: [
        {
          ...FIELD_TITLE_PROPERTY,
          value: 'Alert Level',
          fieldType: IntakeFormElementType.CARE_INFORMATION_PERMANENT_CONDITION_ALERT_LEVEL,
        },
        REQUIRED_PROPERTY,
      ],
    },
    parentId: ADDITIONAL_PERMANENT_CONDITIONS_ELEMENT_ID,
  },
  RADIO_ADD_ANOTHER_PERMANENT_CONDITIONS,
];

const PERMANENT_CONDITIONS_BUNDLE: IFormElement = {
  id: ADDITIONAL_PERMANENT_CONDITIONS_ELEMENT_ID,
  name: 'Permanent conditions',
  type: FormElementType.MULTIPLE_ELEMENT,
  label: LabelMultipleElements,
  isHideConfig: true,
  properties: {
    general: [REQUIRED_PROPERTY],
    appearance: [],
    configuration: [],
  },
  children: [],
};

const CONDITIONAL_ADD_PERMANENT_CONDITIONS: IFormElement = {
  id: uuid(),
  parentId: ADDITIONAL_PERMANENT_CONDITIONS_ELEMENT_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: ADD_ANOTHER_PERMANENT_CONDITIONS_ELEMENT_ID,
        options: [RADIO_ADD_ANOTHER_PERMANENT_CONDITIONS],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 'Yes',
        options: ['Yes', 'No'],
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [ADDITIONAL_PERMANENT_CONDITIONS_ELEMENT_ID],
        options: [PERMANENT_CONDITIONS_BUNDLE],
      },
      {
        ...REPEATABLE_PROPERTY,
        value: true,
      },
    ],
  },
};

PERMANENT_CONDITIONS_BUNDLE.children.push(...ADD_PERMANENT_CONDITIONS_DETAIL);
PERMANENT_CONDITIONS_BUNDLE.children.push(CONDITIONAL_ADD_PERMANENT_CONDITIONS);
//#endregion

export const CARE_INFORMATION_TAB_ELEMENTS: IFormElement[] = [
  MEDICATION_BUNDLE,
  SUPPORT_BUNDLE,
  BEHAVIOR_BUNDLE,
  CARE_INFORMATION_BUNDLE,
  PERMANENT_CONDITIONS_BUNDLE,
];
