import { Form, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle } from 'common-components/typography';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import '../../../../css/workflow-styles.css';

const { TextArea } = Input;

interface Props extends FormComponentProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose(): void;
  onConfirm(values: any): void;
}

class CloseWorkflowModal extends PureComponent<Props> {
  private _commentInputValidator = (rule, value, callback) => {
    try {
      if (!value || _.trim(value).length === 0) {
        throw Error('Please enter a reason for closing the workflow');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _onSave = () => {
    const { form, onConfirm } = this.props;

    form.validateFields(async (err, values) => {
      if (!err) onConfirm(values.reason.trim());
    });
  };

  render() {
    const { isOpen, onClose, isLoading, form } = this.props;

    const { getFieldDecorator } = form;

    return (
      <ActionModal width="large" isOpen={isOpen} onClose={onClose} title="Close workflow" verticalAlignment="highest">
        <Paragraph size="x-large" className="mt-medium">
          Closing this workflow will stop its progress – it won’t move on to the next step.
        </Paragraph>
        <Paragraph size="x-large" className="mt-medium">
          Any data collected will be saved. You can open this workflow again anytime and keep going.
        </Paragraph>
        <SubTitle>Reason for closing</SubTitle>
        <Form className="align-center">
          <Form.Item>
            {getFieldDecorator('reason', {
              initialValue: '',
              rules: [{ validator: this._commentInputValidator }],
            })(
              <TextArea
                placeholder="Enter issue description..."
                autoSize={{ minRows: 4, maxRows: 10 }}
                maxLength={2500}
              />,
            )}
          </Form.Item>
        </Form>
        <ActionModalFooter className={'mt-x-large'}>
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._onSave} loading={isLoading}>
            Close workflow
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default connect()(Form.create<Props>()(CloseWorkflowModal));
