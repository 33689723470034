import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const CommentMinus = forwardRef(function CommentMinus(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.25h-.055c-1.367 0-2.47 0-3.337.117-.9.12-1.658.38-2.26.981-.602.602-.86 1.36-.981 2.26-.117.867-.117 1.97-.117 3.337V12.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116H15.111c.974 0 1.343.005 1.691.087.35.082.68.243 1.552.678l2.31 1.156A.75.75 0 0 0 21.75 20V9.945c0-1.367 0-2.47-.116-3.337-.122-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117H9ZM4.41 5.409c.276-.277.664-.457 1.398-.556C6.564 4.752 7.565 4.75 9 4.75h6c1.436 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4.102.754.103 1.756.103 3.191v8.787l-1.225-.613-.08-.04c-.765-.383-1.259-.63-1.798-.757-.54-.127-1.091-.127-1.947-.127H9c-1.435 0-2.436-.002-3.192-.103-.734-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191v-2c0-1.435.002-2.437.103-3.192.099-.734.28-1.122.556-1.399ZM9 10.25a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5H9Z"
        fill="currentColor"
      />
    </Icon>
  );
});
