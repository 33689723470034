import { Paragraph, Title } from 'common-components/typography';
import * as H from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TimeBasedAlertConfigType } from 'utilities/enum-utils';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import TimeBasedAlertsConfigurationModal from 'views/account-management/components/TimeBasedAlertsConfigurationModal';
import ConfigCard from './components/ConfigCard';

interface ITimeBasedAlertsConfigureViewProps {
  doFetchTimeBasedAlertConfiguration: typeof dispatch.accountStore.doFetchTimeBasedAlertConfiguration;
  portalUser: typeof state.authStore.portalUser;
  timeBasedAlertConfigure: typeof state.accountStore.timeBasedAlertConfigure;
  history: H.History;
}

interface ITimeBasedAlertsConfigureViewState {
  isLoading: boolean;
  isEditTimeBasedAlertsConfigOpen: boolean;
  configType: TimeBasedAlertConfigType;
}

class TimeBasedAlertsConfigureView extends Component<
  ITimeBasedAlertsConfigureViewProps,
  ITimeBasedAlertsConfigureViewState
> {
  state = {
    isLoading: true,
    isEditTimeBasedAlertsConfigOpen: false,
    configType: null,
  };

  private _goToLandingPage = () => {
    this.props.history.push('/account/landing');
  };

  private _goToFeatureConfiguration = () => {
    this.props.history.push('/account/features');
  };

  private _openEditTimeBasedConfig = () => {
    this.setState({ isEditTimeBasedAlertsConfigOpen: true });
  };

  private _closeEditTimeBasedConfig = async () => {
    await this.props.doFetchTimeBasedAlertConfiguration({});
    this.setState({ isEditTimeBasedAlertsConfigOpen: false });
  };

  componentDidMount = async () => {
    await this.props.doFetchTimeBasedAlertConfiguration({});
    this.setState({ isLoading: false });
  };

  render() {
    const { timeBasedAlertConfigure } = this.props;
    const documentExpiryDaysConfig = timeBasedAlertConfigure.alertConfiguration.documentExpiry;
    const serviceAgreementExpiryDaysConfig = timeBasedAlertConfigure.alertConfiguration.serviceAgreementExpiry;

    return (
      <>
        {this.state.isEditTimeBasedAlertsConfigOpen && (
          <TimeBasedAlertsConfigurationModal
            isOpen={this.state.isEditTimeBasedAlertsConfigOpen}
            onClose={this._closeEditTimeBasedConfig}
            configType={this.state.configType}
            dayConfigured={
              this.state.configType === TimeBasedAlertConfigType.DOCUMENT_EXPIRY
                ? documentExpiryDaysConfig
                : serviceAgreementExpiryDaysConfig
            }
          />
        )}
        <div className={'pb-x4-large'}>
          <div className='mb-medium'>
            <Title level={3} weight='regular' className='mv-none'>
              <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToLandingPage}>
                Account {'>'}
              </span>{' '}
              <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToFeatureConfiguration}>
                Feature Configuration {'>'}
              </span>{' '}
              Time based alerts
            </Title>
          </div>
          <Paragraph>
            Configure features for your Workspace. Any changes to features below will take effect for your entire
            organisation’s Workspace settings and behaviour.
          </Paragraph>
          <div className='mt-x-large bordered-bottom border-standard-gray'>
            <ConfigCard
              title='Document expiry'
              description="Configure the time before expiry where a document is considered 'Expiring soon'."
              actionLabelText={`${documentExpiryDaysConfig} ${documentExpiryDaysConfig === 1 ? 'day' : 'days'}`}
              actionLabelColor='blue-action'
              isActionLabelLoading={documentExpiryDaysConfig == null}
              onClick={() => {
                this.setState({ configType: TimeBasedAlertConfigType.DOCUMENT_EXPIRY });
                this._openEditTimeBasedConfig();
              }}
            />
            <ConfigCard
              title='Service agreement expiry'
              description="Configure the time before expiry where a service agreement is considered 'Expiring soon'."
              actionLabelText={`${serviceAgreementExpiryDaysConfig} ${
                serviceAgreementExpiryDaysConfig === 1 ? 'day' : 'days'
              }`}
              actionLabelColor='blue-action'
              isActionLabelLoading={serviceAgreementExpiryDaysConfig == null}
              onClick={() => {
                this.setState({ configType: TimeBasedAlertConfigType.SERVICE_AGREEMENT_EXPIRY });
                this._openEditTimeBasedConfig();
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPayment: state.companyStore.companyFundingAndPayment,
  portalUser: state.authStore.portalUser,
  timeBasedAlertConfigure: state.accountStore.timeBasedAlertConfigure,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchTimeBasedAlertConfiguration: dispatch.accountStore.doFetchTimeBasedAlertConfiguration,
});

export default connect(mapState, mapDispatch)(TimeBasedAlertsConfigureView);
