import React, { forwardRef } from 'react';
import { Select } from 'antd';
import { type SelectProps } from 'antd/es/select';

import { type CountryCode, useLocaleConfig } from 'providers/locale-provider';

/**
 * Antd component for selecting phone number country codes.
 */
export const SelectPhoneCountryCodeAntd = forwardRef<Select<CountryCode>, SelectProps<CountryCode>>((props, ref) => {
  const { available: availableLocaleConfigs, current: currentLocaleConfig } = useLocaleConfig();

  return (
    <Select
      ref={(ref_) => {
        if (ref) {
          if (typeof ref === 'function') {
            ref(ref_);
          } else {
            ref_ = ref.current;
          }
        }
        return ref_;
      }}
      defaultValue={currentLocaleConfig.countryCode}
      {...props}
    >
      {availableLocaleConfigs.map((localeConfig) => (
        <Select.Option value={localeConfig.countryCode} key={localeConfig.countryCode}>
          {localeConfig.phone.label}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectPhoneCountryCodeAntd.displayName = 'SelectPhoneCountryCodeAntd';
