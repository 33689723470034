import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Text } from 'common-components/typography';

import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';

import { AddMemberSteps } from 'views/account-management/add-new-member/AddMemberSteps';
import StartStepPanel from 'views/account-management/add-new-member/panels/start-step-panel/StartStepPanel';
import DuplicateCheckPanel from 'views/account-management/add-new-member/panels/duplicate-check-panel/DuplicateCheckPanel';
import PermissionsPanel from 'views/account-management/add-new-member/panels/permissions-panel/PermissionsPanel';
import StatusAndInvitePanel from 'views/account-management/add-new-member/panels/invite-panel/StatusAndInvitePanel';
import FinishedPanel from 'views/account-management/add-new-member/panels/finished-panel/FinishedPanel';

import { GhostButton, PrimaryButton } from 'common-components/buttons';

import { state, IRootDispatch, IRootState, dispatch } from 'stores/rematch/root-store';

import { INewMemberData } from 'interfaces/account-interfaces';
import PermissionUtils from 'utilities/permission-utils';
import _ from 'lodash';
import { UserPermissionRole } from 'utilities/enum-utils';
import { withRouter, WithRouterProps } from 'utilities/with-router';

interface AddNewMemberModalProps extends WithRouterProps {
  onClose: any;
  isOpen: any;
  newMemberData?: INewMemberData;
  setNewMemberData?: any;
  resetNewMemberData?: any;
  portalUser: typeof state.authStore.portalUser;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
}

interface AddNewMemberModalState {
  confirmModalOpen: boolean;
  step: number;
}

class AddNewMemberModal extends Component<AddNewMemberModalProps, AddNewMemberModalState> {
  state = { confirmModalOpen: false, step: 0 }; // steps start from 0, due to Ant Design Steps component

  onStepNext = () => this.setState({ step: this.state.step + 1 });

  onStepPrevious = () => this.setState({ step: this.state.step - 1 });

  openConfirmModal = () => {
    if (this.state.step !== 4) {
      this.setState({ confirmModalOpen: true });
    } else {
      // final step, no need to ask for confirmation.
      this.setState({ confirmModalOpen: false, step: 0 });
      this.props.resetNewMemberData();
      this.props.onClose();
    }
  };

  addAnotherTeamMember = () => {
    this.props.resetNewMemberData();
    this.setState({ step: 0 });
  };

  goToNewTeamMember = () => {
    const { newMemberData, history } = this.props;
    if (newMemberData.supportWorkerId) {
      history.push(`/team/details/${newMemberData.supportWorkerId}`);
    } else {
      return false;
    }
  };

  private _getOwnerRole = () => {
    const { newMemberData } = this.props;
    return !!_.find(
      newMemberData.permissions.permissionRoles,
      (role) => role.permissionRole === UserPermissionRole.PrimaryOwner,
    )
      ? UserPermissionRole.PrimaryOwner
      : !!_.find(newMemberData.permissions.permissionRoles, (role) => role.permissionRole === UserPermissionRole.Owner)
      ? UserPermissionRole.Owner
      : 999;
  };

  confirmClose = () => {
    this.setState({ confirmModalOpen: false, step: 0 });
    this.props.resetNewMemberData();
    this.props.onClose();
  };

  renderStepsContent = () => {
    const { newMemberData, setNewMemberData, portalUser } = this.props;

    const commonStepProps = {
      onStepNext: this.onStepNext,
      onStepPrevious: this.onStepPrevious,
      newMemberData,
      setNewMemberData,
    };

    const canAccessTeamMemberProfile = PermissionUtils.validatePermission(
      'ViewTeamMemberProfile',
      portalUser.permissions.permissionRoles,
    );

    if (this.state.step === 0) {
      return <StartStepPanel {...commonStepProps} />;
    } else if (this.state.step === 1) {
      return <DuplicateCheckPanel {...commonStepProps} />;
    } else if (this.state.step === 2) {
      return <PermissionsPanel {...commonStepProps} />;
      // } else if (this.state.step === 3) {
      // // Profile panel here - uncomment the following later on.
      // return <ProfilePanel onStepNext={this.onStepNext} onStepPrevious={this.onStepPrevious} />;
    } else if (this.state.step === 3) {
      return <StatusAndInvitePanel {...commonStepProps} />;
    } else if (this.state.step === 4) {
      return (
        <FinishedPanel
          {...commonStepProps}
          ownerRole={this._getOwnerRole()}
          addAnotherTeamMember={this.addAnotherTeamMember}
          goToNewTeamMember={this.goToNewTeamMember}
          canAccessTeamMemberProfile={canAccessTeamMemberProfile}
        />
      );
    }
  };

  componentDidMount() {
    this.props.resetNewMemberData();
    this.props.doFetchCompanyLite([]);
  }

  render() {
    const { isOpen, newMemberData, setNewMemberData } = this.props;

    return (
      <>
        {/* Confirm close dialog */}
        <ActionModal
          isOpen={this.state.confirmModalOpen}
          width="small"
          title="Cancel adding team member"
          canCloseOutside={true}
          onClose={() => this.setState({ confirmModalOpen: false })}
          verticalAlignment="high"
        >
          <div>
            <Text>If you cancel now, you will lose all data you have entered.</Text> <br /> <br />
            <Text>
              Are you sure you want to <b>cancel</b>?
            </Text>
          </div>
          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={() => this.setState({ confirmModalOpen: false })}>
              Go Back
            </GhostButton>
            <PrimaryButton size="large" onClick={this.confirmClose}>
              Confirm
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>

        {/* Full wizard modal */}
        <FullScreenScrollableModal
          isOpen={isOpen}
          onClose={this.openConfirmModal}
          bgColor={'quaternary'}
          headerTitle={<AddMemberSteps currentStep={this.state.step} />}
          width={'full'}
          canCloseEsc={false}
        >
          {this.renderStepsContent()}
        </FullScreenScrollableModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  newMemberData: state.accountStore.newMemberData,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewMemberData: dispatch.accountStore.setNewMemberData,
  resetNewMemberData: dispatch.accountStore.resetNewMemberData,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
});

export default connect(mapState, mapDispatch)(withRouter(AddNewMemberModal));
