import React, { useEffect } from 'react';
import PermissionUtils from 'utilities/permission-utils';
import { Alert, Breadcrumbs, Button, Header, Heading, Link, Text } from '@good/components';
import { IRootState } from 'stores/rematch/root-store';
import { useHistory, Link as RouterLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { reactTrpc } from 'utilities/react-trpc';
import { Loader2Adjustable } from '@good/icons';
import { JobStatusLabel } from './components/job-status-label';
import { format } from 'date-fns';
import startCase from 'lodash/startCase';

export const AccountShowImportView = () => {
  const history = useHistory();
  const { jobId } = useParams<{ jobId: string }>();

  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  useEffect(() => {
    if (
      !portalUser ||
      !PermissionUtils.validatePermission('ImportPlatformData', portalUser.permissions.permissionRoles)
    )
      history.push('/access-denied');
  });
  if (!portalUser) return null;

  const { data: job, error } = reactTrpc.imports.fetch.useQuery(
    { jobId, serviceProviderId: portalUser.serviceProviderId },
    { refetchInterval: 5000, refetchIntervalInBackground: true },
  );

  return (
    <>
      <div className='flex-column pb-large relative flex gap-6' id='imports-view'>
        <Breadcrumbs>
          <RouterLink to='/account/landing'>Account management</RouterLink>
          <RouterLink to='/account/imports'>Import data</RouterLink>
          <Text>Imported CSV</Text>
        </Breadcrumbs>

        {job ? (
          <>
            <div className='flex justify-between align-center'>
              <Header size='xl'>
                <Heading>{job.jobName ?? 'Imported CSV'}</Heading>
                <Text>
                  <JobStatusLabel status={job.status} />
                </Text>
              </Header>
            </div>

            <div className='flex justify-between'>
              <div className='flex flex-col'>
                <Text size='md'>
                  Added by:{' '}
                  {job.addedByTeamMember ? (
                    <RouterLink to={`/team/details/${job.addedByTeamMember.teamMemberId}`}>
                      <Link>
                        <Text size='md'>{job.addedByTeamMember.name}</Text>
                      </Link>
                    </RouterLink>
                  ) : null}
                </Text>
                <Text size='md'>Import type: {startCase(job.data.importType)}</Text>
                <Text size='md'>Filename: {job.data.filename}</Text>
                <Text size='md'>Processing strategy: {startCase(job.data.processingStrategy)}</Text>
              </div>

              <div className='flex flex-col text-right'>
                <Text size='md'>Added at {format(job.createdOn, 'hh:mm:aa, dd/MM/yyyy')}</Text>
                <Text size='md'>
                  Processed at {job.processedOn ? format(new Date(job.processedOn), 'hh:mm:aa, dd/MM/yyyy') : '-'}
                </Text>
                <Text size='md'>
                  Completed at {job.finishedOn ? format(new Date(job.finishedOn), 'hh:mm:aa, dd/MM/yyyy') : '-'}
                </Text>
              </div>
            </div>

            {job.status === 'Failed' && (
              <Alert tone={job.data.processingStrategy === 'dryRun' ? 'notice' : 'critical'}>
                <Heading>
                  {job.data.processingStrategy === 'dryRun'
                    ? 'The following issues were found in the CSV'
                    : 'We encountered an error while processing the CSV'}
                </Heading>
                <Text>
                  <div className='flex flex-col'>
                    {job.failedReason
                      .replace(/^"|"$/g, '')
                      .split('\\n')
                      .map((error, i) => (
                        <Text key={i}>{error}</Text>
                      ))}
                  </div>
                </Text>
              </Alert>
            )}

            {job.status === 'Completed' && (
              <Alert tone='positive'>
                <Heading>No issues were encountered while processing the CSV</Heading>
                {job.data.processingStrategy === 'dryRun' && (
                  <Text>
                    This job run in validation-only mode, and the data was{' '}
                    <i>
                      <b>not</b>
                    </i>{' '}
                    imported.
                  </Text>
                )}
              </Alert>
            )}
          </>
        ) : error ? (
          <div className='flex flex-col align-center'>
            <Alert tone='critical'>
              <Heading>Failed to load job</Heading>
              <Text>We had trouble loading the details of the import job.</Text>
            </Alert>

            <Button emphasis='fill' className='flex-shrink-0' onClick={() => history.push('/account/imports')}>
              Back to imports
            </Button>
          </div>
        ) : (
          <div className='flex flex-col align-center'>
            <Loader2Adjustable className='animate-spin' />
            <Text size='md'>Loading job details</Text>
          </div>
        )}
      </div>
    </>
  );
};
