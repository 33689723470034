import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ListUnordered4 = forwardRef(function ListUnordered4(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53 3.53a.75.75 0 0 0-1.06-1.06L4 4.94l-.47-.47a.75.75 0 1 0-1.06 1.06l1 1a.75.75 0 0 0 1.06 0l3-3ZM10.25 5a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 0 1.5H11a.75.75 0 0 1-.75-.75Zm.75 6.25a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5H11Zm0 7a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5H11ZM7.53 9.47a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 0 1-1.06 0l-1-1a.75.75 0 1 1 1.06-1.06l.47.47 2.47-2.47a.75.75 0 0 1 1.06 0Zm0 8.06a.75.75 0 1 0-1.06-1.06L4 18.94l-.47-.47a.75.75 0 0 0-1.06 1.06l1 1a.75.75 0 0 0 1.06 0l3-3Z"
        fill="currentColor"
      />
    </Icon>
  );
});
