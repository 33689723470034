import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import _ from 'lodash';
import React from 'react';
import {
  ActionModalExtensions,
  ACTION_BUTTON,
  COMMON_MODAL_ACTION,
} from 'views/account-management/subscription-management/utils/constants';

interface ICommonModalProps {
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onRemove: () => void;
  modalType: COMMON_MODAL_ACTION;
}

const ActionButton = (type: string, onClose: () => void, onRemove: () => void, loading: boolean) => {
  switch (type) {
    case ACTION_BUTTON.CANCEL: {
      return (
        <SecondaryButton size="large" onClick={onClose}>
          Cancel
        </SecondaryButton>
      );
    }
    case ACTION_BUTTON.CLOSE: {
      return (
        <PrimaryButton size="large" onClick={onClose}>
          Close
        </PrimaryButton>
      );
    }
    case ACTION_BUTTON.REMOVE: {
      return (
        <PrimaryButton color="red" size="large" onClick={onRemove} loading={loading}>
          Remove
        </PrimaryButton>
      );
    }
  }
};

const CommonModal = (props: ICommonModalProps) => {
  const { isOpen, modalType, onClose, onRemove, loading } = props;

  const modalExtensions = ActionModalExtensions[modalType];

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={modalExtensions?.title}
      canCloseOutside={true}
      verticalAlignment="center"
      width="medium"
    >
      <Paragraph className="mt-medium">{modalExtensions?.description}</Paragraph>

      <ActionModalFooter className="flex justify-end mt-medium">
        {_.map(modalExtensions?.actions, (action, key) => {
          return (
            <div className="ml-medium" key={key}>
              {ActionButton(action, onClose, onRemove, loading)}
            </div>
          );
        })}
      </ActionModalFooter>
    </ActionModal>
  );
};

export default CommonModal;
