import React from 'react';
import { format } from 'date-fns';
import { Button, Group, MantineModal as Modal, Paper, Stack, Table, Text } from '@good/ui/core';
import { notifications } from '@good/ui/notifications';
import { RouterOutputs, reactTrpc } from 'utilities/react-trpc';

type Props = {
  opened: boolean;
  close: () => void;
  accessToken: RouterOutputs['account']['accessTokens']['list'][0];
};

export const RevokeAccessTokenModal = ({ opened, close, accessToken }: Props) => {
  const trpcUtils = reactTrpc.useContext();
  const revokeTokenMutation = reactTrpc.account.accessTokens.revoke.useMutation();

  const onSubmit = async () => {
    await revokeTokenMutation.mutateAsync({
      serviceProviderId: accessToken.serviceProviderId,
      personalAccessTokenId: accessToken.personalAccessTokenId,
    });
    await trpcUtils.account.accessTokens.list.invalidate();
    notifications.show({
      message: 'The access token has been revoked',
      variant: 'success',
      color: 'green',
    });
    close();
  };

  return (
    <Modal
      title='Revoke access token'
      opened={opened}
      onClose={close}
      closeButtonProps={{ 'aria-label': 'Close modal' }}
    >
      <Stack>
        <Text>Are you sure you want to remove this access token?</Text>

        <Paper bg='gray.2' p='xs' withBorder>
          <Table>
            <Table.Tr>
              <Table.Td>Name</Table.Td>
              <Table.Td>{accessToken.name}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Description</Table.Td>
              <Table.Td>{accessToken.description}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Created on</Table.Td>
              <Table.Td>{format(accessToken.createdOn, 'hh:mm:aa, dd/MM/yyyy')}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Expires</Table.Td>
              <Table.Td>
                {accessToken.expiresOn ? format(accessToken.expiresOn, 'hh:mm:aa, dd/MM/yyyy') : 'Never'}
              </Table.Td>
            </Table.Tr>
          </Table>
        </Paper>

        <Text c='red' size='sm'>
          This action is irreversible and the token will no longer be valid after revoking.
        </Text>

        <Group grow>
          <Button onClick={close} variant='default'>
            Cancel
          </Button>
          <Button onClick={onSubmit} loading={revokeTokenMutation.isLoading} variant='filled' color='red'>
            Revoke token
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
