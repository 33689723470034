import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchInsightsSummary } from 'stores/queries/support-workers/support-workers-queries';
import { InsightsSummaryFilters, InsightsSummaryResponse } from 'views/insights/insights';

export const useFetchInsightsSummary = (
  request: InsightsSummaryFilters,
  enabled?: boolean,
): UseQueryResult<InsightsSummaryResponse> => {
  return useQuery(['fetchInsightsSummary', JSON.stringify(request)], () => fetchInsightsSummary(request), { enabled });
};
