import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Paragraph } from 'common-components/typography';

interface IEditFundingWarningModalProps {
  closeModal: () => void;
  onProceed: () => void;
  isOpen: boolean;
}

class EditFundingWarningModal extends Component<IEditFundingWarningModalProps> {
  state = {};

  render() {
    return (
      <ActionModal
        isOpen={this.props.isOpen}
        onClose={this.props.closeModal}
        title={'Edit Funding Package'}
        showCloseButton={true}
      >
        <Paragraph className="mt-large">
          Editing a customer's funding package may result in change to the line items on bookings created for the
          customer that have not been sent to payments.
        </Paragraph>
        <br />
        <Paragraph>Do you want to proceed?</Paragraph>
        <ActionModalFooter>
          <PrimaryButton className="mr-medium" size="large" onClick={this.props.closeModal}>
            Cancel
          </PrimaryButton>
          <GhostButton size="large" onClick={this.props.onProceed}>
            Proceed
          </GhostButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default EditFundingWarningModal;
