import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Text, Alert, Stack } from '@good/ui/core';
import { HyperlinkButton } from 'common-components/buttons';
import { DocumentExpiryStatus } from 'utilities/enum-utils';
import { IRootState } from 'stores/rematch/root-store';
import { CustomerNotificationRowItem } from './customer-notification-row-item';
import { Check } from '@good/icons';

type CustomerDocument = {
  attachmentUrl: string;
  documentName: string;
  expiryDate: string;
  expiryStatus: DocumentExpiryStatus;
  firstName: string;
  lastName: string;
  locality: string;
  userId: string;
};

type CustomersAtAGlance = {
  expiredDocuments: CustomerDocument[];
  expiringSoonDocuments: CustomerDocument[];
};

export function CustomerNotifications() {
  const customersAtAGlance = useSelector(
    (state: IRootState) => state.customersStore.customersAtAGlance as CustomersAtAGlance | undefined,
  );
  const serviceProviderLite = useSelector((state: IRootState) => state.companyStore.companyDataLite);
  const history = useHistory();
  const hasExpiredDocuments = customersAtAGlance && customersAtAGlance.expiredDocuments.length > 0;
  const hasExpiringSoonDocuments = customersAtAGlance && customersAtAGlance.expiringSoonDocuments.length > 0;
  const shouldRenderDocuments = hasExpiredDocuments || hasExpiringSoonDocuments;

  if (!serviceProviderLite) {
    return null;
  }

  return (
    <Stack>
      <Text>Outstanding items for customers.</Text>
      <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '500px' }}>
        {shouldRenderDocuments ? (
          <>
            {customersAtAGlance.expiredDocuments.map((expiredDocument, index) => (
              <CustomerNotificationRowItem
                key={index}
                documentData={expiredDocument}
                serviceProviderTimezone={serviceProviderLite.timezone}
              />
            ))}
            <>
              {customersAtAGlance.expiringSoonDocuments.map((expiringSoonDocument, index) => (
                <CustomerNotificationRowItem
                  key={index}
                  documentData={expiringSoonDocument}
                  serviceProviderTimezone={serviceProviderLite.timezone}
                />
              ))}
            </>
          </>
        ) : (
          <Alert color='green' icon={<Check />}>
            No customers with outstanding items.
          </Alert>
        )}
      </div>

      <div>
        <Text size='regular' c='dimmed'>
          For more alerts, visit{' '}
          <HyperlinkButton onClick={() => history.push('/customers')}>Customers listings</HyperlinkButton>.
        </Text>
      </div>
    </Stack>
  );
}
