import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';
import { Checkbox, Empty, notification, Select } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import NumberInput from 'common-components/inputs/NumberInput';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import CommonUtils from 'utilities/common-utils';
import { IGroupServiceSession, IGroupServiceScheduleTimeSlot } from 'interfaces/service-interfaces';
import GroupServiceSessionsPanel from 'views/group-services/service-details/components/GroupServiceSessionsPanel';
import { PaymentSources, TeamMemberCustomerRatio } from 'utilities/enum-utils';
import { Spinner } from '@blueprintjs/core';

interface IAddEditGroupServiceSessionsModalProps {
  onClose: any;
  isOpen: boolean;
  isEdit?: boolean;
  doGetQuotation: typeof dispatch.customersStore.doGetQuotation;
  quotation?: any;
  selectedService?: any;
  addEditQuotation: (quotation) => void;
  doFetchGroupServiceSchedules?: typeof dispatch.servicesStore.doFetchGroupServiceSchedules;
  doFetchGroupServiceSessions?: typeof dispatch.servicesStore.doFetchGroupServiceSessions;
  groupServiceSchedules: typeof state.servicesStore.groupServiceSchedules;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  isNewServiceAgreement: boolean;
  userServiceAgreementId?: string;
}

interface IAddEditGroupServiceSessionsModalState {
  step: number;
  title: any;
  isLoading: boolean;
  selectedScheduleTimeSlotIds: IGroupServiceScheduleTimeSlot[];
  sessions: IGroupServiceSession[];
  additionalCost: number;
  isCalculating: boolean;
  selectedIndex: number;
  selectedSessions: IGroupServiceSession[];
  selectedScheduledTimeSlotsWithRatio: IGroupServiceScheduleTimeSlot[];
  checkAllIndicator: boolean;
  indeterminateCheck: boolean;
  excludePublicHolidays: boolean;
}

const BookingEmptyState = () => (
  <div className="flex-1 bg-white mt-x2-large align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No schedule found.
    </Text>
    <Text color="secondary">{'Please add schedules to the service to create a quote.'}</Text>
  </div>
);

class AddEditGroupServiceSessionsModal extends Component<
  IAddEditGroupServiceSessionsModalProps,
  IAddEditGroupServiceSessionsModalState
> {
  state = {
    step: 1,
    isLoading: false,
    title: 'Select schedule(s) to add to quote',
    selectedScheduleTimeSlotIds:
      this.props.quotation && this.props.quotation.selectedScheduleTimeSlotIds
        ? this.props.quotation.selectedScheduleTimeSlotIds
        : [],
    sessions: [],
    additionalCost: this.props.quotation ? this.props.quotation.additionalCost : 0,
    isCalculating: false,
    selectedIndex: 0,
    selectedSessions: [],
    selectedScheduledTimeSlotsWithRatio: [],
    checkAllIndicator: false,
    indeterminateCheck: false,
    excludePublicHolidays: false,
  };

  private _onClose = () => {
    this.props.onClose();
    this.setState({
      step: 1,
      isLoading: false,
      title: 'Create a schedule for a service to calculate the quote',
      additionalCost: 0,
      isCalculating: false,
      selectedScheduleTimeSlotIds: [],
      selectedScheduledTimeSlotsWithRatio: [],
    });
  };

  private _goToPrevious = () => {
    const { step } = this.state;
    if (step === 2) {
      this.setState({ step: 1 });
    } else if (step === 3) {
      this.setState({ step: 2 });
    }
  };

  private _goToSessionStep = async () => {
    const { selectedService } = this.props;
    const { step } = this.state;
    if (step === 1) {
      this.setState({ isLoading: true });
      await this._getGroupServiceSessions();
      this.setState({ step: 2, title: 'Sessions for ' + selectedService.serviceName, isLoading: false });
    }
  };

  private _goToAdditionalCostStep = async () => {
    const { step } = this.state;
    if (step === 2) {
      this.setState({ step: 3, title: 'Add any additional costs for this service' });
    }
  };

  private _getGroupServiceSessions = async () => {
    const { selectedService, selectedCustomer } = this.props;
    const { selectedScheduleTimeSlotIds, selectedScheduledTimeSlotsWithRatio, excludePublicHolidays } = this.state;
    let sessions = await this.props.doFetchGroupServiceSessions({
      serviceId: selectedService.serviceId,
      timezone: selectedService.timezone,
      customerUserId: selectedCustomer.userId,
      isSchedule: true,
      scheduleTimeSlotIds: selectedScheduleTimeSlotIds,
      scheduleTimeSlots: selectedScheduledTimeSlotsWithRatio,
      excludePublicHolidays,
      startDateTime: selectedService.startDate,
      endDateTime: selectedService.endDate,
    });
    let sessionsWithoutTimezone = _.map(sessions, (session) => {
      return {
        ...session,
        startDateTime: moment.tz(
          moment.tz(session.startDateTime, selectedService.timezone).format('YYYY-MM-DD HH:mm'),
          selectedService.timezone,
        ),
        endDateTime: moment.tz(
          moment.tz(session.endDateTime, selectedService.timezone).format('YYYY-MM-DD HH:mm'),
          selectedService.timezone,
        ),
      };
    });
    this.setState({
      sessions: sessionsWithoutTimezone,
      selectedSessions: sessionsWithoutTimezone,
      isLoading: false,
    });
  };

  private _onChangeAdditionalCosts = (e) => {
    this.setState({ additionalCost: e });
  };

  private _calculateQuote = async () => {
    const { selectedService } = this.props;
    const { selectedSessions, selectedScheduleTimeSlotIds, excludePublicHolidays } = this.state;
    this.setState({ title: 'Calculating quote amount...', step: 4, isCalculating: true });
    let selectedSessionsClone = _.clone(selectedSessions);
    if (excludePublicHolidays) {
      selectedSessionsClone = _.filter(selectedSessions, (session) => !session.isInHoliday);
    }
    const serviceDateTimes = _.map(selectedSessionsClone, (session) => {
      return { serviceDateTimeId: session.serviceDateTimeId, teamMemberCustomerRatio: session.teamMemberCustomerRatio };
    });
    try {
      const result: any = await this.props.doGetQuotation({
        serviceId: selectedService.serviceId,
        serviceBillingItems: selectedService.lineItems,
        serviceDateTimes,
        isGroupService: true,
        paymentSourceType: PaymentSources.NDIS,
        excludePublicHolidays: excludePublicHolidays,
        teamMemberCustomerRatio: selectedService.teamMemberCustomerRatio.ndis,
        additionalCharges: selectedService.additionalCharges,
        isNewServiceAgreement: this.props.isNewServiceAgreement,
        userServiceAgreementId: this.props.userServiceAgreementId,
      });
      this.props.addEditQuotation({
        ...result,
        additionalCost: Number(this.state.additionalCost),
        serviceDateTimes,
        isGroupService: true,
        serviceId: selectedService.serviceId,
        quoteAmount: Number(result.quoteAmount) + Number(this.state.additionalCost),
        excludePublicHolidays: excludePublicHolidays,
        teamMemberCustomerRatio: selectedService.teamMemberCustomerRatio.ndis,
        selectedScheduleTimeSlotIds,
        isAdditionalCostIncludeGst: false,
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something wrong happened, please try again.' });
    }
    this._onClose();
  };

  private _selectTimeSlot = (scheduleTimeSlotId) => {
    let newSelectedTimeSlotIds = _.clone(this.state.selectedScheduleTimeSlotIds);
    if (!_.find(newSelectedTimeSlotIds, (selectedTimeSlotId) => selectedTimeSlotId === scheduleTimeSlotId)) {
      newSelectedTimeSlotIds.push(scheduleTimeSlotId);
      this._handleChangeRatio(scheduleTimeSlotId, this.props.selectedService.teamMemberCustomerRatio.ndis);
    } else {
      newSelectedTimeSlotIds = _.filter(
        this.state.selectedScheduleTimeSlotIds,
        (timeSlot) => timeSlot !== scheduleTimeSlotId,
      );
      const newSelectedTimeSlotsWithRatio = _.filter(
        this.state.selectedScheduledTimeSlotsWithRatio,
        (timeslot) => timeslot.scheduleTimeSlotId !== scheduleTimeSlotId,
      );
      this.setState({ selectedScheduledTimeSlotsWithRatio: newSelectedTimeSlotsWithRatio });
    }
    this.setState({ selectedScheduleTimeSlotIds: newSelectedTimeSlotIds });
  };

  private _onSelect = (session: IGroupServiceSession, filteredSessionList: IGroupServiceSession[]) => {
    const { selectedSessions } = this.state;
    let newSelectedSession = _.clone(selectedSessions);
    if (
      _.find(selectedSessions, (selectedSession) => selectedSession.serviceDateTimeId === session.serviceDateTimeId)
    ) {
      newSelectedSession = _.filter(
        selectedSessions,
        (selectedSession) => selectedSession.serviceDateTimeId !== session.serviceDateTimeId,
      );
    } else {
      newSelectedSession.push(session);
    }

    this.setState(
      {
        selectedSessions: newSelectedSession,
      },
      () => {
        this._onChangeFilter(filteredSessionList);
      },
    );
  };

  // Check all handler
  private _onCheckAll = (filteredSessionList: IGroupServiceSession[]) => {
    const { checkAllIndicator, selectedSessions } = this.state;
    let newSelectedSessions = _.clone(selectedSessions);
    const newCheckAll = !checkAllIndicator;
    if (newCheckAll) {
      newSelectedSessions.push(...filteredSessionList);
    } else {
      newSelectedSessions = _.differenceBy(newSelectedSessions, filteredSessionList, 'serviceDateTimeId');
    }

    this.setState({
      selectedSessions: newSelectedSessions,
      checkAllIndicator: newCheckAll,
      indeterminateCheck: false,
    });
  };

  //update checkAllIndicator when change filter year/month
  private _onChangeFilter = (filteredSessionList: IGroupServiceSession[]) => {
    const { selectedSessions } = this.state;
    const selectedSessionsInCurrentTab: IGroupServiceSession[] = _.filter(
      filteredSessionList,
      (filterItem) =>
        !!_.find(selectedSessions, (selectedItem) => selectedItem.serviceDateTimeId === filterItem.serviceDateTimeId),
    );
    this.setState({
      checkAllIndicator:
        selectedSessionsInCurrentTab.length === filteredSessionList.length && selectedSessionsInCurrentTab.length !== 0,
      indeterminateCheck:
        !!selectedSessionsInCurrentTab.length && selectedSessionsInCurrentTab.length < filteredSessionList.length,
    });
  };

  private _handleChangeRatio = (scheduleTimeSlotId, teamMemberCustomerRatio) => {
    const { selectedScheduledTimeSlotsWithRatio } = this.state;
    const newSelectedScheduledTimeSlots = _.filter(
      selectedScheduledTimeSlotsWithRatio,
      (timeslot) => timeslot.scheduleTimeSlotId !== scheduleTimeSlotId,
    );
    newSelectedScheduledTimeSlots.push({
      scheduleTimeSlotId,
      teamMemberCustomerRatio,
    });
    this.setState({ selectedScheduledTimeSlotsWithRatio: newSelectedScheduledTimeSlots });
  };

  private _onExcludePublicHolidays = () => {
    const { excludePublicHolidays } = this.state;
    this.setState({ excludePublicHolidays: !excludePublicHolidays });
  };

  componentDidUpdate = async (prevProps: Readonly<IAddEditGroupServiceSessionsModalProps>) => {
    const { quotation } = this.props;
    if (
      (prevProps.quotation !== quotation && this.props.isEdit && this.props.isOpen) ||
      (!prevProps.isOpen && this.props.isOpen)
    ) {
      this.setState({ isLoading: true });
      await this.props.doFetchGroupServiceSchedules({
        serviceId: this.props.selectedService && this.props.selectedService.serviceId,
      });
      this.setState({
        selectedScheduleTimeSlotIds: [],
        additionalCost: quotation ? quotation.additionalCost : 0,
        title: 'Select schedule(s) to add to quote',
        step: 1,
        isLoading: false,
      });
    }
  };

  render() {
    const { isOpen, selectedService, groupServiceSchedules } = this.props;
    const {
      selectedScheduleTimeSlotIds,
      additionalCost,
      title,
      step,
      isLoading,
      sessions,
      selectedSessions,
      excludePublicHolidays,
    } = this.state;

    const scheduleWithoutTz =
      selectedService &&
      _.map(groupServiceSchedules, (schedule) => {
        return {
          ...schedule,
          scheduleStartDate: moment.tz(
            moment.tz(schedule.scheduleStartDate, selectedService.timezone).format('YYYY-MM-DD HH:mm'),
            selectedService.timezone,
          ),
          scheduleEndDate: moment.tz(
            moment.tz(schedule.scheduleEndDate, selectedService.timezone).format('YYYY-MM-DD HH:mm'),
            selectedService.timezone,
          ),
          scheduleConfig: {
            ...schedule.scheduleConfig,
            timeSlots: _.map(schedule.scheduleConfig.timeSlots, (timeSlot) => {
              return {
                ...timeSlot,
                startDateTime: moment.tz(
                  moment.tz(timeSlot.startDateTime, selectedService.timezone).format('YYYY-MM-DD HH:mm'),
                  selectedService.timezone,
                ),
                endDateTime: moment.tz(
                  moment.tz(timeSlot.endDateTime, selectedService.timezone).format('YYYY-MM-DD HH:mm'),
                  selectedService.timezone,
                ),
              };
            }),
          },
        };
      });

    const selectAllHandler = {
      onCheckAll: this._onCheckAll,
      checkAllIndicator: this.state.checkAllIndicator,
      indeterminateCheck: this.state.indeterminateCheck,
    };

    return (
      <ActionModal isOpen={isOpen} onClose={this._onClose} width={'x2-large'} title={title} canCloseOutside={false}>
        {step === 1 && (
          <>
            <div className="mb-medium">
              <Text>
                Select the <b>schedule(s)</b> that you'd like to add the customer to for calculating a quote. If the
                selected service charges centre capital cost, these costs will be automatically added to the quote
                generated for this service.
              </Text>
            </div>
            <div className="flex-row" onClick={this._onExcludePublicHolidays}>
              <div>
                <Checkbox checked={excludePublicHolidays} className={'mr-medium'} />
              </div>
              <div>
                <Text>Exclude all sessions that fall on public holidays</Text>
              </div>
            </div>
            <div className={'mt-large'}>
              {isLoading ? (
                <SpinningLoader size={150} message={'Fetching schedules...'} />
              ) : scheduleWithoutTz && scheduleWithoutTz.length > 0 ? (
                _.map(scheduleWithoutTz, (schedule) => {
                  const timeSlots = schedule.scheduleConfig && schedule.scheduleConfig.timeSlots;
                  return (
                    <div className={'mb-large'}>
                      <div className={'p-medium bg-tertiary mb-x-small'}>
                        <Text weight={'bold'}>
                          {selectedService && selectedService.serviceName} - {schedule.scheduleName} (
                          {moment(schedule.scheduleStartDate).format('DD MMMM')} -{' '}
                          {moment(schedule.scheduleEndDate).format('DD MMMM')})
                        </Text>
                      </div>
                      {_.map(timeSlots, (timeSlot) => {
                        const isSelected = _.find(
                          selectedScheduleTimeSlotIds,
                          (selectedTimeSlot) => selectedTimeSlot === timeSlot.scheduleTimeSlotId,
                        );
                        return (
                          <div
                            className={`mb-x-small cursor-pointer block p-medium bordered bordered-standard-grey ${
                              isSelected && 'bg-blue-lightest'
                            }`}
                          >
                            <div className="flex-row" onClick={() => this._selectTimeSlot(timeSlot.scheduleTimeSlotId)}>
                              <div>
                                <Checkbox checked={isSelected} className={'mr-medium'} />
                              </div>
                              <div>
                                <Text>
                                  {CommonUtils.getRecurringPatternLabel(timeSlot.recurringPattern)} -{' '}
                                  {moment(timeSlot.startDateTime).format('dddd')},{' '}
                                  {moment(timeSlot.startDateTime).format('hh:mm A')} to{' '}
                                  {moment(timeSlot.endDateTime).format('hh:mm A')}
                                </Text>
                                <br />
                                <Text size={'regular'}>{timeSlot.description ? timeSlot.description : '-'}</Text>
                              </div>
                            </div>
                            {isSelected && (
                              <div className="ml-x-large width-full">
                                <Text color="secondary" weight="bold">
                                  RATIO (WILL BE APPLIED FOR ENTIRE SCHEDULED)
                                </Text>
                                <br />
                                <Select
                                  placeholder="Select.."
                                  style={{ width: '30%' }}
                                  onChange={(ratio) => {
                                    this._handleChangeRatio(timeSlot.scheduleTimeSlotId, ratio);
                                  }}
                                  defaultValue={
                                    selectedService.teamMemberCustomerRatio
                                      ? selectedService.teamMemberCustomerRatio.ndis
                                      : TeamMemberCustomerRatio.ONE_TO_ONE
                                  }
                                >
                                  {_.map(TeamMemberCustomerRatio, (ratio) => (
                                    <Select.Option value={ratio}>{ratio}</Select.Option>
                                  ))}
                                </Select>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <BookingEmptyState />
              )}
            </div>
            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                Close
              </SecondaryButton>
              <PrimaryButton
                size="large"
                className="mr-medium"
                disabled={!selectedScheduleTimeSlotIds || selectedScheduleTimeSlotIds.length <= 0 || isLoading}
                onClick={this._goToSessionStep}
                loading={isLoading}
              >
                Next
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 2 && (
          <>
            <div className="mb-medium">
              <Text>
                Based on your selections we will be using the following sessions to calculate the quote for the
                customer.
                <br />
                Only sessions that occur during the dates of this service agreement will be displayed
              </Text>
            </div>
            <div className={'mt-large'}>
              {isLoading ? (
                <SpinningLoader size={150} message={'Fetching sessions...'} />
              ) : (
                <GroupServiceSessionsPanel
                  sessions={_.map(sessions, (session) => {
                    return {
                      ...session,
                      isSelected: !!_.find(
                        selectedSessions,
                        (selectedSession) => session.serviceDateTimeId === selectedSession.serviceDateTimeId,
                      ),
                    };
                  })}
                  selectAllHandler={selectAllHandler}
                  onSelect={this._onSelect}
                  timezone={selectedService.timezone}
                  displayWarnings={false}
                  onChangeFilter={(filters) => this._onChangeFilter(filters.filteredSessionList)}
                  displayTeamMemberCustomerRatios={true}
                />
              )}
            </div>
            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                Close
              </SecondaryButton>
              <PrimaryButton
                size="large"
                className="mr-medium"
                disabled={!selectedScheduleTimeSlotIds || selectedScheduleTimeSlotIds.length <= 0}
                onClick={this._goToAdditionalCostStep}
              >
                Next
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 3 && (
          <>
            <div className={'mv-large flex-row align-center'}>
              <div className={'mr-x-large'}>Additional cost (Optional)</div>
              <NumberInput
                size={'large'}
                value={additionalCost ? additionalCost : '0.00'}
                className={'mr-large'}
                precision={2}
                style={{ width: '200px' }}
                addonBefore={'$'}
                onChange={this._onChangeAdditionalCosts}
                max={99999.99}
              />
            </div>
            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._goToPrevious}>
                Previous
              </SecondaryButton>
              <PrimaryButton size="large" className="mr-medium" onClick={this._calculateQuote}>
                Calculate quote
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 4 && (
          <div>
            <SpinningLoader size={150} message={'Calculating quote amount. Please wait.'} />
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceSchedules: state.servicesStore.groupServiceSchedules,
  selectedCustomer: state.customersStore.selectedCustomer,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetQuotation: dispatch.customersStore.doGetQuotation,
  doFetchGroupServiceSchedules: dispatch.servicesStore.doFetchGroupServiceSchedules,
  doFetchGroupServiceSessions: dispatch.servicesStore.doFetchGroupServiceSessions,
});

export default connect(mapState, mapDispatch)(AddEditGroupServiceSessionsModal);
