import React, { memo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Divider } from 'antd';

import { Text } from 'common-components/typography';
import { ISessionRosterActivityGroups } from 'interfaces/session-interfaces';

import RosterCustomerListing from './RosterCustomerListing';
import RosterTeamMembersListing from './RosterTeamMembersListing';

interface IRosterActivityGroupListingProps {
  rosterActivityGroups: ISessionRosterActivityGroups[];
  timezone: string;
}

function RosterActivityGroupListing(props: IRosterActivityGroupListingProps) {
  const { rosterActivityGroups, timezone } = props;

  return (
    <table className="width-full">
      <tbody>
        {_.map(rosterActivityGroups, (activityGroup) => {
          const hasActivityData = !_.isEmpty(activityGroup.customers) || !_.isEmpty(activityGroup.teamMembers);
          const { startDateTime, endDateTime } = activityGroup;
          const startTime = moment.tz(startDateTime, timezone).format('h:mm A');
          const endTime = moment.tz(endDateTime, timezone).format('h:mm A');

          return (
            hasActivityData && (
              <tr
                key={activityGroup.serviceDateTimeActivityGroupId}
                className="block mh-large pb-large"
                style={{ background: 'linear-gradient(to left, #FAFAFA 45.5%, white 0%)' }}
              >
                <Divider className="mv-none" style={{ height: '8px' }} />
                <div className="flex-row pv-large page-break-inside-avoid">
                  <div style={{ width: '55%' }}>
                    <Text className="mv-large" size="x3-large" weight="bold">
                      {activityGroup.name}{' '}
                      {startDateTime && endDateTime && (
                        <Text weight="regular" style={{ fontSize: 20 }}>
                          {startTime} - {endTime}
                        </Text>
                      )}
                    </Text>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="mr-small" style={{ width: '55%' }}>
                    <RosterCustomerListing customers={activityGroup.customers} timezone={timezone} />
                  </div>
                  <div className="mh-small" style={{ width: '45%' }}>
                    <RosterTeamMembersListing teamMembers={activityGroup.teamMembers} timezone={timezone} />
                  </div>
                </div>
              </tr>
            )
          );
        })}
      </tbody>
    </table>
  );
}

export default memo(RosterActivityGroupListing);
