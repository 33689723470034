/* eslint-disable */
import apiClient from 'utilities/api-client';

const profileStore = {
  state: {
    profilesList: [],
  },
  reducers: {
    // increment: (state, payload) => ({ ...state, currentCounter: state.currentCounter + payload })
    // setServices: (state, payload) => ({ ...state, services: payload })
  },
  effects: (dispatch) => ({
    async doFetchProfiles(payload, rootState) {
      // dispatch.userStore.increment(payload);
      // console.log('fetching services...');
      // // Mock data
      // dispatch.servicesStore.setServices(mockServicesData);
      // console.log('services fetched.');
    },
  }),
};

export default profileStore;
