import { Box, Inline, Skeleton, Text } from '@goodhuman-me/components';
import { Divider } from 'design-components';
import { IServiceDepartment } from 'interfaces/account-interfaces';
import React, { Fragment } from 'react';
import { PermissionRole } from 'views/team-v2/utils/form-utils';
import ServiceDepartmentDetailsLiteItem from './ServiceDepartmentDetailsLiteItem';

interface IProps {
  isLoading: boolean;
  serviceDepartmentDetailsLite: IServiceDepartment;
  serviceDepartmentId: string;
  setPermissionRolesDepartmentLiteItem: (permissionRole: PermissionRole) => void;
  permissionRolesDepartment: PermissionRole[];
}

const TextItem: React.FC<{ name: string }> = ({ name }) => {
  return (
    <Text color="$black" fontWeight="$semi">
      {name}
    </Text>
  );
};

const CustomServiceDepartmentPanel: React.FC<IProps> = ({
  isLoading,
  serviceDepartmentDetailsLite,
  serviceDepartmentId,
  setPermissionRolesDepartmentLiteItem,
  permissionRolesDepartment,
}) => {
  if (isLoading) {
    return (
      <Skeleton.Root>
        <Skeleton.Heading y="10" />
        <Skeleton.Text lines={2} y="60" />
      </Skeleton.Root>
    );
  }

  return (
    <Box padding="$space200">
      {!serviceDepartmentDetailsLite?.services?.length ? (
        <TextItem name="No Services" />
      ) : (
        <>
          <Inline paddingBottom="$space200 !important">
            <Box width="$size2400">
              <TextItem name="SERVICE" />
            </Box>
            <Box width="$size2400">
              <TextItem name="STATUS" />
            </Box>
            <Box width="$size3400">
              <TextItem name="ACCESS" />
            </Box>
          </Inline>
          <Divider />
          <div
            style={{
              maxHeight: 250,
              overflowY: 'scroll',
            }}
          >
            {serviceDepartmentDetailsLite?.services?.map((service) => {
              return (
                <Fragment key={service.serviceId}>
                  <ServiceDepartmentDetailsLiteItem
                    service={service}
                    serviceDepartmentId={serviceDepartmentId}
                    setPermissionRolesDepartmentLiteItem={setPermissionRolesDepartmentLiteItem}
                    permissionRolesDepartment={permissionRolesDepartment}
                  />
                </Fragment>
              );
            })}
          </div>
        </>
      )}
    </Box>
  );
};

export default CustomServiceDepartmentPanel;
