import { useEffect, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Schema } from '../schema';
import fields from '../fields';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  return ref.current;
}

function useCustomDurationUnit(form: UseFormReturn<Schema>) {
  const { watch } = form;
  const customTeamMemberPaymentDurationUnit = watch(fields.customTeamMemberPaymentDurationUnit.name);
  const customTeamMemberPaymentDuration = watch(fields.customTeamMemberPaymentDuration.name);
  const previousDurationUnit = usePrevious(customTeamMemberPaymentDurationUnit);
  const isCustomPaymentDurationHours =
    customTeamMemberPaymentDurationUnit === fields.customTeamMemberPaymentDurationUnit.options.hours;

  useEffect(() => {
    if (previousDurationUnit && customTeamMemberPaymentDurationUnit !== previousDurationUnit) {
      if (isCustomPaymentDurationHours) {
        form.setValue(fields.customTeamMemberPaymentDuration.name, customTeamMemberPaymentDuration / 60);
      } else {
        form.setValue(fields.customTeamMemberPaymentDuration.name, customTeamMemberPaymentDuration * 60);
      }
    }
  }, [customTeamMemberPaymentDurationUnit]);
}

export default useCustomDurationUnit;
