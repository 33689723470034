import React, { forwardRef, PropsWithChildren } from 'react';
import type { BoxStyleProps, HTMLProps } from '..';
import { boxStyleProps, compose, css, useStyleProps } from '..';
import * as styles from './divider.css';
import { DividerVariants } from './divider.css';

export type DividerProps = PropsWithChildren<BoxStyleProps & HTMLProps<HTMLDivElement>> &
  DividerVariants & {
    /**
     * The direction type of divider: horizontal or vertical
     */
    type?: string;
  };

/**
 * @name
 * Divider
 *
 * @description
 * The Divider element is a visual divider between
 * two groups of content
 *
 * @example
 * <Divider
 *   type="horizontal"
 * />
 */

// eslint-disable-next-line react/display-name
export const Divider = forwardRef<HTMLDivElement, DividerProps>((props, ref) => {
  const { type = 'horizontal' } = props;
  const { styleProps } = useStyleProps(props, boxStyleProps);
  return (
    <div
      ref={ref}
      className={compose(
        css(styles.divider),
        styles.variants({
          type,
        }),
        css(styleProps),
      )}
    ></div>
  );
});
