import { IRosterShift } from 'common-components/roster-control/interfaces/roster-interfaces';
import moment from 'moment';
import TextTag from 'common-components/tags/TextTag';
import { FieldLabel, Text } from 'common-components/typography';
import { Avatar } from 'antd';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import React from 'react';
import { BookingTypeIcon } from 'common-components/tags';
import { BookingType, ServiceType } from 'utilities/enum-utils';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import CommonUtils from 'utilities/common-utils';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { RecommendedPanel } from 'common-components/roster-control/common/RecommendedPanel';
import { useFetchSessionCapacity } from 'stores/hooks/query-hooks/use-query-fetch-session-capacity';
import { useHistory } from 'react-router-dom';

function OpenShiftPopover({
  shift,
  setSelectedSideNavMenuKeys,
}: {
  shift: IRosterShift;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
}) {
  const {
    // status,
    shiftStartDateTime,
    shiftEndDateTime,
    serviceName,
    customerFirstName,
    customerLastName,
    customerAvatarUrl,
    serviceId,
    serviceDateTimeId,
  } = shift;

  const customerFullName = `${customerFirstName} ${customerLastName}`;

  const { data: sessionData, isLoading: sessionIsLoading } = useFetchSessionCapacity(
    {
      serviceId,
      serviceDateTimeId,
    },
    shift.serviceType === ServiceType.GROUP,
  );
  const history = useHistory();

  const mStartDate = moment(shiftStartDateTime);
  const mEndDate = moment(shiftEndDateTime);

  const dateDisplay = (
    <>
      <b>{mStartDate.format('dddd, D MMMM YYYY')}</b>
    </>
  );
  const timeDisplay = (
    <>
      <b>{mStartDate.format('h:mm a')}</b> - {mEndDate.format('h:mm a')}
    </>
  );

  const goToShift = () => {
    if (shift.serviceType === ServiceType.GROUP) {
      history.push(`/group-service/${shift.serviceId}/session/details/${shift.serviceDateTimeId}`, {
        selectedTab: 'TEAM-MEMBERS',
      });
      setSelectedSideNavMenuKeys(['/services']);
    } else {
      history.push(`/bookings/details/${shift.bookingId}`, {
        from: { url: '/bookings/calendar', linkLabel: 'Back to Calendar' },
      });
      setSelectedSideNavMenuKeys(['/bookings']);
    }
  };

  const goToService = (serviceId, serviceType) => {
    setSelectedSideNavMenuKeys(['/services']);
    return serviceType === ServiceType.GROUP
      ? history.push(`/group-service/details/${serviceId}`)
      : history.push(`/service/details/${serviceId}`);
  };

  const goToSession = (serviceId, serviceDateTimeId) => {
    setSelectedSideNavMenuKeys(['/services']);
    return history.push(`/group-service/${serviceId}/session/details/${serviceDateTimeId}`);
  };

  return (
    <div className="p-large flex-column line-height-100 select-none" style={{ width: '320px' }}>
      <div className="inline-block mb-x-small line-height-100">
        {/* TODO : Change this with actual booking status tag */}
        <TextTag
          content={'Open slot'}
          theme="light"
          color="gold"
          size="small"
          weight="bold"
          backgroundColor={'white'}
        />
      </div>
      <div className="line-height-120 mb-medium">
        <Text size="x-large" lineHeight={135}>
          {dateDisplay}
        </Text>
        <br />
        <Text size="x-large" lineHeight={135}>
          {timeDisplay}
        </Text>
        <br />
        <Text size="x-large" lineHeight={135}>
          <Text size="x-large" color={'secondary'}>
            {CommonUtils.formatDurationString(moment(mStartDate), moment(mEndDate))}
          </Text>
        </Text>
        <br />
        <div className={'flex-row align-center mt-small'}>
          <BookingTypeIcon
            type={shift.bookingType === BookingType.ACTIVITY_RECORD ? BookingType.ACTIVITY_RECORD : shift.serviceType}
            className={'mr-small'}
          />
          <Text color={'tertiary'}>{CommonUtils.formatServiceTypeStatus(shift, true)}</Text>
        </div>
        {shift.serviceType === ServiceType.INDIVIDUAL || !shift.scheduleName ? (
          <HyperlinkButton color={'black'} onClick={() => goToService(shift.serviceId, shift.serviceType)}>
            {serviceName}
          </HyperlinkButton>
        ) : (
          <Text>
            {shift.scheduleName}{' '}
            <HyperlinkButton color={'secondary'} onClick={() => goToService(shift.serviceId, shift.serviceType)}>
              ({shift.serviceName})
            </HyperlinkButton>
          </Text>
        )}
        {shift.serviceType === ServiceType.GROUP && (
          <div>
            <HyperlinkButton onClick={() => goToSession(shift.serviceId, shift.serviceDateTimeId)}>
              Go to session
            </HyperlinkButton>
          </div>
        )}
      </div>

      {shift.serviceType === ServiceType.GROUP && (
        <div className={'mb-large'}>
          <div className="mt-x-small">
            {sessionIsLoading ? (
              <SpinningLoader size={20} message={'Fetching recommended capacity...'} />
            ) : (
              sessionData &&
              sessionData.data && (
                <RecommendedPanel
                  recommendedTotalShiftSlot={sessionData.data.recommendedTotalShiftSlot}
                  totalAssignedShiftSlot={sessionData.data.totalAssignedShiftSlot}
                />
              )
            )}
          </div>
        </div>
      )}

      {shift.bookingId && (
        <div className="mb-large">
          <FieldLabel text={'CUSTOMER'} />
          <div className="flex-row align-center mt-x-small">
            <Avatar icon="user" className="mr-small" shape="circle" src={customerAvatarUrl} />
            <Text color="secondary">{customerFullName}</Text>
          </div>
        </div>
      )}

      <div className="mb-large">
        <FieldLabel text={'TEAM MEMBER'} />
        <div className="flex-row align-center mt-x-small">
          <Avatar icon="user" className="mr-small" shape="square" />
          <Text color="secondary">Not assigned</Text>
        </div>
      </div>

      <div className="flex-row justify-end">
        <PrimaryButton onClick={goToShift}>View shift</PrimaryButton>
        {/*<IconButton*/}
        {/*  icon='ellipsis'*/}
        {/*  color='white'*/}
        {/*  iconColor='blue-action'*/}
        {/*  bordered={true}*/}
        {/*  className='border-blue-action'*/}
        {/*/>*/}
      </div>
    </div>
  );
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
});

export default connect(null, mapDispatch)(OpenShiftPopover);
