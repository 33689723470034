import type { CountryCode, CountryName } from '../types';

export const countries: Record<CountryCode, CountryName> = {
  AU: 'Australia',
  US: 'United States',
};

export const countryCodeToName = (countryCode: CountryCode) => {
  return countries[countryCode];
};

export const countryNameToCode = (countryName: CountryName) => {
  return (Object.keys(countries) as CountryCode[]).find((code) => countries[code] === countryName);
};
