import { Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import React, { Component } from 'react';
import CommonUtils from 'utilities/common-utils';

class ViewQuotationAdditionalCostView extends Component<any, any> {
  render() {
    const { quotation, isShowGSTLineItem } = this.props;

    let gstValue = 0;
    let additionalCost = Number(quotation.additionalCost);

    if (isShowGSTLineItem && quotation.subQuotes.length > 0) {
      const newGstPrice = CommonUtils.calcGstPriceByTotal(quotation.additionalCost, quotation.gst);
      gstValue = quotation.isAdditionalCostIncludeGst ? newGstPrice.gstValue : 0;
      additionalCost = quotation.isAdditionalCostIncludeGst ? newGstPrice.priceValue : Number(quotation.additionalCost);

      _.forEach(quotation.subQuotes, (subQuote) => {
        gstValue += _.sumBy(subQuote.quoteItems, (quoteItem: any) => quoteItem.gstValue);
      });
    }

    return (
      <>
        {additionalCost > 0 && (
          <Row
            className={
              'pv-medium bordered-bottom bordered-left bordered-right bg-blue-action-lightest border-standard-gray ph-medium'
            }
            type={'flex'}
            align={'middle'}
          >
            <Col span={12} className={'pl-medium'}>
              <Text weight={'bold'}>Additional cost</Text>
            </Col>
            <Col span={9} className={'text-align-right'}>
              <Text weight={'bold'}>{CommonUtils.formatPrice(additionalCost)}</Text>
            </Col>
            <Col span={3} />
          </Row>
        )}
        {isShowGSTLineItem && gstValue ? (
          <Row
            className={
              'pv-medium bordered-bottom bordered-left bordered-right bg-blue-action-lightest border-standard-gray ph-medium'
            }
            type={'flex'}
            align={'middle'}
          >
            <Col span={12} className={'pl-medium'}>
              <Text weight={'bold'}>GST</Text>
            </Col>
            <Col span={9} className={'text-align-right'}>
              <Text weight={'bold'}>{CommonUtils.formatPrice(gstValue)}</Text>
            </Col>
            <Col span={3} />
          </Row>
        ) : null}
      </>
    );
  }
}

export default ViewQuotationAdditionalCostView;
