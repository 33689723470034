import apiClient from 'utilities/api-client';
import { IPrintSessionRoster } from 'interfaces/session-interfaces';
import { IListBookingsResult } from 'interfaces/booking-interfaces';

/*
  Return response need to include page number from the request.
  This is used to determine the next page number by useInfiniteQuery from react-query.
  Format should be as follows :
 
  { data: [], page: request.page }
*/
function formatResponse<T>(result: { data: T[] }, request: { page: number }) {
  if (result && result.data) {
    const { data } = result;
    const { page } = request;
    return { data, page };
  } else {
    return { data: [], page: 1 };
  }
}

// TODO : Should expand out the actual request as a proper interface.
export async function fetchBookings(request) {
  const result: IListBookingsResult = await apiClient.post(`/api/portal/bookings/list`, request);
  return formatResponse({ data: result.data[0].bookingList }, request);
}

export async function fetchShifts(request) {
  const result = await apiClient.post(`/api/portal/support-worker/shift-slot/list`, request);
  return formatResponse(result, request);
}

export async function fetchCustomerRatio(request) {
  const result = await apiClient.get(`/api/portal/group-bookings/${request.bookingId}/ratio`);
  return formatResponse(result, request);
}

export async function fetchSessionCapacity(request) {
  const result = await apiClient.post(
    `api/portal/group-services/${request.serviceId}/sessions/${request.serviceDateTimeId}/shift-slots/list`,
  );
  return formatResponse(result, request);
}

export async function fetchPrintSessionRoster(request: {
  serviceId: string;
  serviceDateTimeId: string;
}): Promise<IPrintSessionRoster> {
  const { serviceDateTimeId, serviceId } = request;
  const result = await apiClient.get(
    `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/print-roster`,
  );

  return result?.data ?? null;
}
