import React, { useState, useCallback, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Col, Row, Icon, Select, Radio } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import _moment from 'moment';
import { extendMoment } from 'moment-range';
import { v4 as uuidv4 } from 'uuid';

import { SubTitle, Text } from 'common-components/typography';
import TimeInput from 'common-components/time-input/TimeInput';
import { HyperlinkButton } from 'common-components/buttons';
import { Stack } from 'common-components/stack';
import { ErrorBanner } from 'common-components/alerts';
import {
  IGroupServiceCustomRatio,
  IGroupServiceCustomerRatio,
  IGroupServiceCustomerRatioErrors,
} from 'interfaces/service-interfaces';
import { TeamMemberCustomerRatio } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';

const momentRange = extendMoment(_moment);

interface IEditCustomerRatioComponentProps {
  customerRatio: IGroupServiceCustomerRatio;
  timezone: string;
  defaultRatioValue: string;
  disableCustomByHourOption?: boolean;
  onChange?: (customRatios: IGroupServiceCustomRatio[]) => void;
}

const EditCustomerRatioPanel = forwardRef((props: IEditCustomerRatioComponentProps, ref) => {
  const { customerRatio, timezone, defaultRatioValue, disableCustomByHourOption, onChange } = props;
  const { startDateTime, endDateTime } = customerRatio;

  const [errors, setErrors] = useState<IGroupServiceCustomerRatioErrors>(customerRatio.errors);
  const [customRatios, setCustomRatios] = useState<IGroupServiceCustomRatio[]>(customerRatio.customRatio);
  const [isCustomRatio, setIsCustomRatio] = useState(customerRatio.isCustomRatio);

  useImperativeHandle(ref, () => ({
    validateCustomRatios(updatedCustomRatios: IGroupServiceCustomRatio[] = null): boolean {
      return checkTimeErrors(updatedCustomRatios);
    },
    getCustomerRatio(): IGroupServiceCustomerRatio {
      const updatedCustomRatios = isCustomRatio ? customRatios : [_.first(customRatios)];
      return {
        ...customerRatio,
        customRatio: updatedCustomRatios,
        isCustomRatio: isCustomRatio,
      };
    },
  }));

  const onDataChanged = useCallback(
    (customRatios: IGroupServiceCustomRatio[] = null) => {
      if (onChange) {
        onChange(customRatios);
      }
    },
    [onChange],
  );

  const checkCustomRatioErrors = (customRatio: IGroupServiceCustomRatio) => {
    const { timeErrors } = customRatio;
    return (
      timeErrors &&
      (timeErrors.isConflict ||
        timeErrors.isEndingAfterSessionEnd ||
        timeErrors.isStartingBeforeSessionStart ||
        timeErrors.isStartAfterEndTime)
    );
  };

  const checkTimeErrors = useCallback(
    (data: IGroupServiceCustomRatio[] = undefined) => {
      const bookingStartDateTime = moment.tz(startDateTime, timezone);
      const bookingEndDateTime = moment.tz(endDateTime, timezone);
      const customData: IGroupServiceCustomRatio[] = data ? data : customRatios;
      const orderedCustomTimes = _.sortBy(_.cloneDeep(customData), 'startDateTime', 'ASC');
      const checkedCustomRatios = _.map(customData, (customRatio) => {
        const isConflict = _.some(
          _.filter(orderedCustomTimes, (c) => c.customTimeId !== customRatio.customTimeId),
          (filteredList) =>
            momentRange
              .range([customRatio.startDateTime, customRatio.endDateTime])
              .overlaps(momentRange.range([filteredList.startDateTime, filteredList.endDateTime])),
        );
        const isStartingBeforeSessionStart = moment
          .tz(customRatio.startDateTime, timezone)
          .isBefore(bookingStartDateTime);
        const isEndingAfterSessionEnd = moment.tz(customRatio.endDateTime, timezone).isAfter(bookingEndDateTime);
        const isStartAfterEndTime = moment
          .tz(customRatio.startDateTime, timezone)
          .isSameOrAfter(moment.tz(customRatio.endDateTime, timezone));

        return {
          ...customRatio,
          timeErrors: { isConflict, isStartingBeforeSessionStart, isEndingAfterSessionEnd, isStartAfterEndTime },
        };
      });
      setCustomRatios(checkedCustomRatios);

      const hasCustomRatioErrors = _.some(checkedCustomRatios, (customRatio) => {
        return checkCustomRatioErrors(customRatio);
      });

      let isAnyGap = false;
      if (isCustomRatio && !hasCustomRatioErrors) {
        if (
          !moment.tz(_.first(orderedCustomTimes).startDateTime, timezone).isSame(bookingStartDateTime) ||
          !moment.tz(_.last(orderedCustomTimes).endDateTime, timezone).isSame(bookingEndDateTime)
        ) {
          isAnyGap = true;
        }

        const totalBookingInRangeMinutes =
          Number(bookingEndDateTime.get('hour') - bookingStartDateTime.get('hour')) * 60 +
          Number(bookingEndDateTime.get('minute') - bookingStartDateTime.get('minute'));
        const totalInRangeMinutes = _.reduce(
          orderedCustomTimes,
          (total, customTime) => {
            const momentStartDateTime = moment.tz(customTime.startDateTime, timezone);
            const momentEndDateTime = moment.tz(customTime.endDateTime, timezone);
            const totalHourInMinutes = Number(momentEndDateTime.get('hour') - momentStartDateTime.get('hour')) * 60;
            const totalMinutes = Number(momentEndDateTime.get('minute') - momentStartDateTime.get('minute'));
            return total + totalHourInMinutes + totalMinutes;
          },
          0,
        );

        if (totalBookingInRangeMinutes !== totalInRangeMinutes) {
          isAnyGap = true;
        }
      }

      setErrors({
        ...errors,
        isAnyGap,
      });

      return isCustomRatio && (isAnyGap || hasCustomRatioErrors);
    },
    [isCustomRatio, customRatios, errors, startDateTime, endDateTime, timezone],
  );

  const addCustomTimeSlot = useCallback(() => {
    const orderedCustomTimes = _.sortBy(customRatios, 'startDateTime', 'ASC');
    const latestCustomEndTime = orderedCustomTimes[orderedCustomTimes.length - 1].endDateTime;
    const updatedCustomRatios = [
      ...customRatios,
      {
        customTimeId: uuidv4(),
        startDateTime: moment.tz(latestCustomEndTime, timezone).toDate(),
        endDateTime: moment.tz(latestCustomEndTime, timezone).add(1, 'hour').toDate(),
        teamMemberCustomerRatio: defaultRatioValue,
      },
    ];
    setCustomRatios(updatedCustomRatios);
    onDataChanged(updatedCustomRatios);
  }, [customRatios, timezone, defaultRatioValue, onDataChanged]);

  const removeCustomTimeSlot = useCallback(
    (customTimeId: string) => {
      const updatedCustomRatios = _.filter(customRatios, (customRatio) => customRatio.customTimeId !== customTimeId);
      setCustomRatios(updatedCustomRatios);
      onDataChanged(updatedCustomRatios);
    },
    [customRatios, onDataChanged],
  );

  const changeRatio = useCallback(
    (value, customTimeId: string = '') => {
      const selectedCustomTimeId = !isCustomRatio ? _.first(customRatios).customTimeId : customTimeId;
      const updatedCustomRatios = _.map(customRatios, (customRatio) => {
        if (customRatio.customTimeId === selectedCustomTimeId) {
          _.set(customRatio, 'teamMemberCustomerRatio', value);
        }

        return customRatio;
      });
      setCustomRatios(updatedCustomRatios);
      onDataChanged(updatedCustomRatios);
    },
    [customRatios, isCustomRatio, onDataChanged],
  );

  const changeDate = useCallback(
    (value, customTimeId: string, dataType: 'startDateTime' | 'endDateTime') => {
      const roundedValue = CommonUtils.formatCeilingDateTime(moment(value));
      const updatedCustomRatios = _.map(customRatios, (customRatio) => {
        if (customRatio.customTimeId === customTimeId) {
          _.set(customRatio, dataType, roundedValue);
        }

        return customRatio;
      });
      setCustomRatios(updatedCustomRatios);
      onDataChanged(updatedCustomRatios);
    },
    [customRatios, onDataChanged],
  );

  const getErrorMessage = useCallback((customRatio: IGroupServiceCustomRatio) => {
    const { timeErrors } = customRatio;
    const { isStartAfterEndTime, isConflict, isEndingAfterSessionEnd, isStartingBeforeSessionStart } = timeErrors;
    if (isStartAfterEndTime) {
      return 'Start time cannot be after end time';
    } else if (isStartingBeforeSessionStart || isEndingAfterSessionEnd) {
      return 'Timeslot outside of booking hour';
    } else if (isConflict) {
      return 'Overlapping timeslots';
    }

    return '';
  }, []);

  useEffect(() => {
    let updatedDate: IGroupServiceCustomRatio[] = [];
    if (!isCustomRatio && customRatios.length === 1) {
      updatedDate = [
        {
          ...customRatios[0],
          startDateTime: moment.tz(startDateTime, timezone).toDate(),
          endDateTime: moment.tz(endDateTime, timezone).toDate(),
        },
      ];
    } else {
      updatedDate = _.map(customRatios, (customRatio) => {
        const momentStartDateTime = moment.tz(customRatio.startDateTime, timezone);
        const momentEndDateTime = moment.tz(customRatio.endDateTime, timezone);
        return {
          ...customRatio,
          startDateTime: moment
            .tz(startDateTime, timezone)
            .set({
              hour: momentStartDateTime.get('hour'),
              minute: momentStartDateTime.get('minute'),
            })
            .toDate(),
          endDateTime: moment
            .tz(endDateTime, timezone)
            .set({
              hour: momentEndDateTime.get('hour'),
              minute: momentEndDateTime.get('minute'),
            })
            .toDate(),
        };
      });
    }
    checkTimeErrors(updatedDate);
    onDataChanged(updatedDate);
  }, [startDateTime, endDateTime, timezone]);

  useEffect(() => {
    if (disableCustomByHourOption) {
      setIsCustomRatio(false);
    }
  }, [disableCustomByHourOption]);

  useEffect(() => {
    let updatedDate;
    if (!isCustomRatio && customRatios.length === 1) {
      updatedDate = [
        {
          ...customRatios[0],
          startDateTime: moment.tz(startDateTime, timezone).toDate(),
          endDateTime: moment.tz(endDateTime, timezone).toDate(),
        },
      ];
    }

    onDataChanged(updatedDate);
  }, [isCustomRatio]);

  return (
    <div>
      {errors?.isAnyGap && (
        <ErrorBanner
          className="mv-small"
          content={'There cannot be any gap between timeslots or with the service times'}
        />
      )}
      {!isCustomRatio && (
        <Text className="block mb-small" weight="bolder" color="secondary">
          Customer ratio
        </Text>
      )}
      <div className="bordered border-secondary">
        <div className="mv-large mh-large">
          <div>
            <Radio checked={!isCustomRatio} onClick={() => setIsCustomRatio(false)}>
              Use single ratio for entire session
            </Radio>
            {!isCustomRatio && !_.isEmpty(customRatios) && (
              <div className="mt-x-small ml-large">
                <SubTitle>Customer ratio</SubTitle>
                <Select
                  style={{ width: '100px' }}
                  value={_.first(customRatios).teamMemberCustomerRatio}
                  onChange={(value) => changeRatio(value)}
                >
                  {_.map(TeamMemberCustomerRatio, (ratio, index) => {
                    return (
                      <Select.Option key={index} value={ratio}>
                        {ratio}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            )}
          </div>
          <div className="mt-large">
            <Radio checked={isCustomRatio} disabled={disableCustomByHourOption} onClick={() => setIsCustomRatio(true)}>
              Use custom ratio by hour
            </Radio>
            {disableCustomByHourOption && (
              <Text className="block ml-large" color="red" size="regular">
                This is not possible for overnight bookings
              </Text>
            )}
            {isCustomRatio && (
              <div className="rounded-big mt-x-small ml-large bg-tertiary p-medium">
                <Row gutter={24}>
                  <Col span={12}>
                    <SubTitle>Time</SubTitle>
                  </Col>
                  <Col span={4}>
                    <SubTitle>Ratio</SubTitle>
                  </Col>
                </Row>
                <Stack className="mb-large" gap="md">
                  {_.map(customRatios, (custom) => {
                    return (
                      <div key={custom.customTimeId}>
                        <Row gutter={24}>
                          <Col span={12}>
                            <div className="flex-row">
                              <div className="mr-small">
                                <TimeInput
                                  size="large"
                                  className={`${checkCustomRatioErrors(custom) && 'border-red-dark'}`}
                                  value={moment.tz(custom.startDateTime, timezone)}
                                  onChange={(value) => changeDate(value, custom.customTimeId, 'startDateTime')}
                                />
                              </div>

                              <div>
                                <TimeInput
                                  size="large"
                                  className={`${checkCustomRatioErrors(custom) && 'border-red-dark'}`}
                                  value={moment.tz(custom.endDateTime, timezone)}
                                  onChange={(value) => changeDate(value, custom.customTimeId, 'endDateTime')}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col span={4}>
                            <Select
                              size="large"
                              style={{ width: '100px' }}
                              value={custom.teamMemberCustomerRatio}
                              onChange={(value) => changeRatio(value, custom.customTimeId)}
                            >
                              {_.map(TeamMemberCustomerRatio, (ratio, index) => {
                                return (
                                  <Select.Option key={index} value={ratio}>
                                    {ratio}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Col>
                          <Col span={4} className="text-align-right">
                            {customRatios.length > 1 && (
                              <Icon
                                type="delete"
                                theme="outlined"
                                className="text-color-red cursor-pointer mt-12"
                                onClick={() => removeCustomTimeSlot(custom.customTimeId)}
                              />
                            )}
                          </Col>
                        </Row>
                        {custom.timeErrors && (
                          <Row className="errors">
                            <Text color="red">{getErrorMessage(custom)}</Text>
                          </Row>
                        )}
                      </div>
                    );
                  })}
                </Stack>
                {/*In customRatio is available all session have the same start and end date.*/}
                {/*If the last customRatio endDate is equal or greater than the sessions' endDate, slot cannot be created.*/}
                {customRatios && customRatios[customRatios.length - 1].endDateTime >= endDateTime ? (
                  <Text color={'secondary'}>
                    <Icon type={'plus'} className={'mr-small'} /> Add slot
                  </Text>
                ) : (
                  <HyperlinkButton onClick={addCustomTimeSlot}>
                    <Icon type={'plus'} className={'mr-small'} />
                    Add slot
                  </HyperlinkButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditCustomerRatioPanel;
