import { notification } from 'antd';
import SelectMembers from 'common-components/activity-groups/selectors/SelectMembers';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IGroupServiceActivityGroup } from 'interfaces/service-interfaces';
import { IActivityGroupUsers } from 'interfaces/user-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { ActivityGroupMemberType, ActivityGroupModalType } from 'utilities/enum-utils';
import { Stack } from '../../../../common-components/stack';

interface IAddMembersToEmptyGroupModalContentProps {
  serviceId: string;
  modalType: ActivityGroupModalType;
  activityGroup: IGroupServiceActivityGroup;
  onCloseViewModal: () => void;
  handleAction: (payload, actionType: ActivityGroupModalType) => Promise<boolean | void>;
}

interface IAddMembersToEmptyGroupModalContentState {
  isLoading: boolean;
  addedCustomers: IActivityGroupUsers[];
  addedTeamMembers: IActivityGroupUsers[];
  membersInActivityGroup: IActivityGroupUsers[];
  activityGroupAddType: ActivityGroupMemberType;
}

class AddMembersToEmptyGroupModalContent extends Component<
  IAddMembersToEmptyGroupModalContentProps,
  IAddMembersToEmptyGroupModalContentState
> {
  state = {
    isLoading: false,
    addedCustomers: [],
    addedTeamMembers: [],
    membersInActivityGroup: [],
    activityGroupAddType: ActivityGroupMemberType.CUSTOMER,
  };

  private _handleClassifyMembers = () => {
    const { activityGroup, modalType } = this.props;
    if (modalType === ActivityGroupModalType.ADD_CUSTOMER) {
      this.setState({
        membersInActivityGroup: activityGroup.customers,
        activityGroupAddType: ActivityGroupMemberType.CUSTOMER,
      });
    }
    if (modalType === ActivityGroupModalType.ADD_TEAM_MEMBER) {
      this.setState({
        membersInActivityGroup: activityGroup.teamMembers,
        activityGroupAddType: ActivityGroupMemberType.TEAM_MEMBER,
      });
    }
  };

  private _handleAlterAddedCustomer = (addedMembers: IActivityGroupUsers[]) => {
    this.setState({ addedCustomers: addedMembers });
  };
  private _handleAlterAddedTeamMember = (addedMembers: IActivityGroupUsers[]) => {
    this.setState({ addedTeamMembers: addedMembers });
  };

  private _handleAddMember = async () => {
    const { serviceId, activityGroup } = this.props;
    const { addedCustomers, addedTeamMembers } = this.state;
    this.setState({ isLoading: true });
    try {
      const payload = {
        serviceId,
        activityGroupId: activityGroup.serviceActivityGroupId,
        serviceDateTimeActivityGroupId: activityGroup.serviceDateTimeActivityGroupId,
      };
      payload['customers'] = _.map(addedCustomers, (member) => member.userId);
      payload['teamMembers'] = _.map(addedTeamMembers, (member) => member.userId);
      const areNewMembersAdded = await this.props.handleAction(payload, this.props.modalType);
      if (areNewMembersAdded) {
        notification.success({
          message: 'People added to activity group',
          description: `You have succesfully added people to "${activityGroup.name}" `,
        });
      }
      this.props.onCloseViewModal();
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong! Please try again.',
      });
      console.log(e);
    }

    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    await this._handleClassifyMembers();
  };

  render() {
    return (
      <Stack gap="xl">
        <SelectMembers
          membersInActivityGroup={this.state.membersInActivityGroup}
          activityGroup={this.props.activityGroup}
          serviceId={this.props.serviceId}
          activityGroupAddType={ActivityGroupModalType.ADD_CUSTOMER}
          onInteractWithAddedMembers={this._handleAlterAddedCustomer}
          onClose={this.props.onCloseViewModal}
        />
        <SelectMembers
          membersInActivityGroup={this.state.membersInActivityGroup}
          activityGroup={this.props.activityGroup}
          serviceId={this.props.serviceId}
          activityGroupAddType={ActivityGroupModalType.ADD_TEAM_MEMBER}
          onInteractWithAddedMembers={this._handleAlterAddedTeamMember}
          onClose={this.props.onCloseViewModal}
        />
        <div className="flex justify-end mt-medium">
          <SecondaryButton size="large" color="secondary" className="mr-12" onClick={this.props.onCloseViewModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._handleAddMember} loading={this.state.isLoading}>
            Save
          </PrimaryButton>
        </div>
      </Stack>
    );
  }
}

export default AddMembersToEmptyGroupModalContent;
