import moment from 'moment-timezone';

import { TimezoneFormatter } from './types';

function getTimezoneOffset(timezone: string) {
  const now = moment().tz(timezone);
  return now.format('Z');
}

function getTimezoneAbbr(timezone: string) {
  const now = moment().tz(timezone);
  return now.tz(timezone).zoneAbbr();
}

export const replaceUnderscoreFormatter: TimezoneFormatter = (timezone) => {
  return timezone.split('_').join(' ');
};

export const timezoneWithOffsetFormatter: TimezoneFormatter = (timezone) => {
  const offset = getTimezoneOffset(timezone);

  return `${replaceUnderscoreFormatter(timezone)} (${offset})`;
};

export const timezoneWithOffsetAndAbbrFormatter: TimezoneFormatter = (timezone) => {
  const offset = getTimezoneOffset(timezone);
  const abbreviation = getTimezoneAbbr(timezone);

  if (/\d/.test(abbreviation)) {
    return `${replaceUnderscoreFormatter(timezone)} (${offset})`;
  }

  return `${replaceUnderscoreFormatter(timezone)} (${offset} ${abbreviation})`;
};
