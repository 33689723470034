import React from "react"
import { ScheduleOfSupports } from "../schedule-of-supports"
import { FundingDetailsCard } from "./funding-details-card"
import { ServiceOverviewCard } from "./service-overview-card"

type ServiceLandingProps = {
  serviceProviderId: string;
  serviceAgreementDetails: {
    serviceName: string;
    startDate: Date;
    endDate: Date;
    userServiceAgreementServiceId: string;
  };
  userDetails: {
    attachmentUrl: string;
    displayName: string;
    fullAddress: string;
    NDISNumber: string;
    userId: string;
  };
};

//TODO: Replace this with real value before SIL goes live.
const status = 'pending';

export const ServiceLanding = ({ userDetails, serviceAgreementDetails, serviceProviderId }: ServiceLandingProps) => {
  return (
    <div className="flex-column pl-x-large pr-x-large pt-small pb-large relative flex" style={{ gap: 24 }}>
      <ServiceOverviewCard
        customer={{
          attachmentUrl: userDetails.attachmentUrl,
          displayName: userDetails.displayName,
          address: userDetails.fullAddress,
          ndisNumber: userDetails.NDISNumber,
        }}
        silService={{
          name: serviceAgreementDetails.serviceName,
          status: status,
          startDate: new Date(serviceAgreementDetails.startDate),
          endDate: new Date(serviceAgreementDetails.endDate),
        }}
      />
      <FundingDetailsCard
        userServiceAgreementServiceId={serviceAgreementDetails.userServiceAgreementServiceId}
        customerId={userDetails.userId}
        serviceProviderId={serviceProviderId}
      />
      <ScheduleOfSupports />
    </div>
  )
}
