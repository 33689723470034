import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { reactTrpc } from '../utilities/react-trpc';
import { httpBatchLink } from '@trpc/client';
import { APP_CONFIG } from '../config/app-config';
import { getAuth } from 'firebase/auth';
import superjson from 'superjson';
import rootStore from 'stores/rematch/root-store';

export function WorkspaceApiTrpcProvider({ children }: { children: React.ReactNode }) {
  const [queryClient] = React.useState(() => new QueryClient());
  const [trpcClient] = React.useState(() =>
    reactTrpc.createClient({
      transformer: superjson,
      links: [
        httpBatchLink({
          url: `${APP_CONFIG.TRPC_URL}/trpc`,
          async headers() {
            const currentUser = getAuth().currentUser;
            const idToken = await currentUser?.getIdToken();
            const authStore = rootStore.getState().authStore;
            const serviceProviderId =
              authStore.serviceProviderId ??
              authStore.portalUser?.serviceProviderId ??
              window.sessionStorage.getItem('serviceProviderId') ??
              undefined;

            return {
              authorization: idToken ? `Bearer ${idToken}` : undefined,
              serviceProviderId,
            };
          },
        }),
      ],
    }),
  );

  return (
    <>
      <reactTrpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </reactTrpc.Provider>
    </>
  );
}
