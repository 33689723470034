import { RadioGroup } from 'design-components';
import React from 'react';
import { UserPermissionRole } from 'utilities/enum-utils';
import { ADVANCED_PERMISSION_ROLE_TYPE } from 'views/team-v2/utils/constants';
import ActionSelectedItem from '../ActionSelectedItem';
import AdvancedPermissionRow from '../AdvancedPermissionRow';

interface IProps {
  permissionRoleId: number;
  changePermissionForEachRole?: (value: number, permissionKey: string) => void;
  typePermissionPresent: string;
  handleSetTypePermissionPresent: (value: string) => void;
}

const PermissionPaymentsSection: React.FC<IProps> = ({
  permissionRoleId = UserPermissionRole.NoAccess,
  changePermissionForEachRole,
  typePermissionPresent,
  handleSetTypePermissionPresent,
}) => {
  return (
    <AdvancedPermissionRow
      title="Payments"
      description="Processing of customer payments"
      type={ADVANCED_PERMISSION_ROLE_TYPE.PAYMENT}
      permissionRoleId={permissionRoleId}
      typePermissionPresent={typePermissionPresent}
      handleSetTypePermissionPresent={handleSetTypePermissionPresent}
      extendPermissions={
        <RadioGroup
          aria-label="payment"
          value={permissionRoleId}
          onChange={(value) => changePermissionForEachRole(value, 'payments')}
        >
          <ActionSelectedItem
            ariaLabel="payment-no-access"
            label="No access, cannot see or access this section"
            value={UserPermissionRole.NoAccess}
            selectedPermission={permissionRoleId}
          />
          <ActionSelectedItem
            ariaLabel="payment-member"
            label="Member, can see and perform basic actions within this section"
            value={UserPermissionRole.PaymentMember}
            selectedPermission={permissionRoleId}
          />
          <ActionSelectedItem
            ariaLabel="payment-manager"
            label="Manager, can see and perform all actions within this section"
            value={UserPermissionRole.PaymentManager}
            selectedPermission={permissionRoleId}
          />
        </RadioGroup>
      }
    />
  );
};

export default PermissionPaymentsSection;
