import { Position } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Icon } from 'antd';
import { SubTitle, Text } from 'common-components/typography';
import { ICustomerListItem } from 'interfaces/customer-interfaces';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

interface ICustomerAdditionalInfosProps {
  customer: ICustomerListItem | any;
  icon?: string;
  color?: string;
  position?: Position;
  usePortal?: boolean;
  getOnlyContent?: boolean;
  noMarginTop?: boolean;
  hideInformation?: 'ndisNumber' | 'dateOfBirth' | 'locality'[];
}

function CustomerAdditionalInfos({
  customer,
  icon = 'info-circle',
  color = 'tertiary',
  position = Position.BOTTOM_LEFT,
  usePortal = true,
  getOnlyContent = false,
  noMarginTop = false,
  hideInformation = [],
}: ICustomerAdditionalInfosProps) {
  const displayTooltip = customer && (customer.locality || customer.dateOfBirth || customer.ndisNumber);

  return displayTooltip ? (
    !getOnlyContent ? (
      <div>
        <Tooltip2
          content={getContent(customer, noMarginTop, hideInformation)}
          position={position}
          usePortal={usePortal}
        >
          <div className={'cursor-help mh-small'}>
            <Icon type={icon} className={`text-color-${color}`} />
          </div>
        </Tooltip2>
      </div>
    ) : (
      getContent(customer, noMarginTop, hideInformation)
    )
  ) : (
    <></>
  );
}

function getContent(customer: ICustomerListItem, noMarginTop, hideInformation) {
  const age = moment().diff(moment(customer.dateOfBirth), 'years', false);
  return (
    <div className={`${!noMarginTop && 'pt-medium'} ph-small`} style={{ minWidth: '250px' }}>
      {customer.locality && !_.find(hideInformation, (label) => label === 'locality') && (
        <div className={'pb-medium'}>
          <SubTitle color={'tertiary'} size={'x-small'}>
            Location
          </SubTitle>
          {customer.locality}
        </div>
      )}
      {customer.dateOfBirth && !_.find(hideInformation, (label) => label === 'dateOfBirth') && (
        <div className={'mb-medium'}>
          <SubTitle color={'tertiary'} size={'x-small'}>
            Date of birth
          </SubTitle>
          {moment(customer.dateOfBirth).format('DD MMM YYYY')}
          <br />
          {age > 0 && (
            <Text color={'white'}> ({moment().diff(moment(customer.dateOfBirth), 'years', false)} years)</Text>
          )}
        </div>
      )}
      {customer.ndisNumber && !_.find(hideInformation, (label) => label === 'ndisNumber') && (
        <div className={'pb-medium'}>
          <SubTitle color={'tertiary'} size={'x-small'}>
            NDIS number
          </SubTitle>
          {customer.ndisNumber}
        </div>
      )}
    </div>
  );
}

export default CustomerAdditionalInfos;
