import React, { forwardRef, useRef } from 'react';
import { mergeRefs } from '@react-aria/utils';
import { useTextField } from '@react-aria/textfield';

import { Label, Stack } from '.';

import type { ElementType, InputHTMLAttributes, RefCallback, RefObject } from 'react';
import type { AriaTextFieldOptions } from '@react-aria/textfield';

export type TextFieldProps = AriaTextFieldOptions<'input' | 'textarea'> & {
  inputRef?: RefCallback<'input' | 'textarea'>;
  /** @default false */
  multiline?: boolean;
  /** @default 1 */
  rows?: number;
};

/**
 * @name
 * TextField
 *
 * @description
 * `TextField` is used to create interactive controls for web-based
 * forms in order to accept data from the user.
 *
 * @example
 * let [fullName, setFullName] = useState('GoodHuman')
 *
 * <TextField
 *   label="Label"
 *   name="fullName"
 *   value={fullName}
 *   onChange={(e) => setFullName(e.target.value)}
 * />
 */
export const TextField = forwardRef<HTMLTextAreaElement | HTMLInputElement, TextFieldProps>(function TextField(
  props,
  ref,
) {
  let { errorMessage, inputRef, label, multiline = false, rows = 1 } = props;
  let mergedRef = mergeRefs(ref, inputRef, useRef(null));
  let { descriptionProps, errorMessageProps, inputProps, labelProps } = useTextField(
    props,
    mergedRef as RefObject<HTMLInputElement | HTMLTextAreaElement>,
  );
  let ElementType: ElementType = props.multiline ? 'textarea' : 'input';

  return (
    <Stack gap="xs">
      <Label {...labelProps}>{label}</Label>

      <ElementType
        {...(inputProps as InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>)}
        ref={mergedRef}
        rows={multiline ? rows || 1 : undefined}
        style={{
          ...{
            appearance: 'none',
            borderRadius: '4px',
            border: '1px solid #d9d9d9',
            padding: '4px 11px',
            minHeight: '32px',
            maxHeight: '192px',
          },
          ...(errorMessage && { border: '1px solid #c13232' }),
        }}
      />

      {!errorMessage && props.description && (
        <div {...descriptionProps} style={{ color: '#696969', fontSize: 14 }}>
          {props.description}
        </div>
      )}

      {errorMessage && (
        <div {...errorMessageProps} style={{ color: '#C13232', fontSize: 14 }}>
          {errorMessage}
        </div>
      )}
    </Stack>
  );
});
