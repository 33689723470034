import React from 'react';
import { Label, Text } from '@goodhuman-me/components';

import { APP_CONFIG, isProduction } from 'config/app-config';

/**
 * Renders an environment tag based on the current environment.
 * If the environment is production, returns null.
 * Otherwise, renders a label with the environment name.
 *
 * @returns JSX.Element | null - The rendered environment tag.
 */
export const EnvTag = (): JSX.Element | null => {
  const { PRODUCTION_ENV: env } = APP_CONFIG.meta;

  if (isProduction(env)) return null;

  return (
    <Text size='xxsmall'>
      <Label tone='blue' size='xsmall'>
        {env}
      </Label>
    </Text>
  );
};
