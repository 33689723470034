import React, { Component } from 'react';
import { Radio } from 'antd';
import { dispatch, state } from 'stores/rematch/root-store';
import { Paragraph, Text, Title } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { GroupServiceClassification } from 'utilities/enum-utils';

interface IGroupServiceClassificationStepPanelProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
}

class GroupServiceClassificationStepPanel extends Component<IGroupServiceClassificationStepPanelProps> {
  state = {
    groupServiceClassification: this.props.newService.groupServiceClassification,
    isNoServiceClassificationError: false,
  };

  private _onChangeGroupServiceType = (newType) => {
    this.setState({ groupServiceClassification: newType });
  };

  private _onNextStep = async () => {
    const { newService, setNewService, onNextStep } = this.props;
    const groupServiceClassification = this.state.groupServiceClassification;

    if (!groupServiceClassification) {
      this.setState({ isNoServiceClassificationError: true });
      return;
    }

    await setNewService({
      ...newService,
      groupServiceClassification,
    });

    onNextStep();
  };

  render() {
    const { onPreviousStep } = this.props;
    const { groupServiceClassification, isNoServiceClassificationError } = this.state;
    const optionStyle =
      'mt-medium pv-medium ph-large bordered border-standard-gray rounded-big cursor-pointer hover-bg-tertiary flex-column align-center flex-grow';

    return (
      <div className="anim-slide-left mb-x-large">
        <Title level={2} weight="bolder" className="line-height-100">
          <span className="text-weight-regular">Service</span> Classification
        </Title>

        <Paragraph>How will this service be delivered?</Paragraph>

        <Radio.Group className="width-4/5" value={groupServiceClassification}>
          <div
            className={
              this.state.groupServiceClassification === GroupServiceClassification.CENTRE_BASED
                ? optionStyle + ' bg-blue-action-lightest'
                : optionStyle
            }
            onClick={() => this._onChangeGroupServiceType(GroupServiceClassification.CENTRE_BASED)}
          >
            <div className="width-full flex-row">
              <div style={{ width: '40px' }}>
                <Radio value={GroupServiceClassification.CENTRE_BASED} />
              </div>
              <div className="width-full">
                <Title level={4} style={{ marginBottom: '0' }}>
                  Centre-based
                </Title>
              </div>
            </div>
            <div className="width-full flex-row">
              <div style={{ width: '40px' }}></div>
              <div className="width-full">
                <Text>This service will be delivered in a centre settings.</Text>
              </div>
            </div>
          </div>

          <div
            className={
              this.state.groupServiceClassification === GroupServiceClassification.COMMUNITY
                ? optionStyle + ' bg-blue-action-lightest'
                : optionStyle
            }
            onClick={() => this._onChangeGroupServiceType(GroupServiceClassification.COMMUNITY)}
          >
            <div className="width-full flex-row">
              <div style={{ width: '40px' }}>
                <Radio value={GroupServiceClassification.COMMUNITY} />
              </div>
              <div className="width-full">
                <Title level={4} style={{ marginBottom: '0' }}>
                  Community
                </Title>
              </div>
            </div>
            <div className="width-full flex-row">
              <div style={{ width: '40px' }}></div>
              <div className="width-full">
                <Text>This service will be delivered in a general community setting.</Text>
              </div>
            </div>
          </div>
        </Radio.Group>
        {isNoServiceClassificationError && (
          <div style={{ marginTop: '12px' }}>
            <Text color="red-dark">Please select an option.</Text>
          </div>
        )}

        <div className="flex-row align-center mv-x2-large">
          <SecondaryButton size="large" icon="left" className="mr-large" onClick={onPreviousStep}>
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" icon="right" iconPosition="right" onClick={this._onNextStep}>
            Next
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export default GroupServiceClassificationStepPanel;
