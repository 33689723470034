import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { IGroupBookingCustomerRatio } from 'views/group-services/manage-booking-modal/interfaces/group-booking-interfaces';
import { IGroupServiceCustomerRatio } from 'interfaces/service-interfaces';
import { minutesBetween } from 'utilities/date-utils';
import { filterHiddenCustomRatios } from './filter-hidden-ratios';
import { ISessionTimesParsed } from '../types';

function getRatio(
  sessionTimes: { startDateTime: Date; endDateTime: Date; timezone: string },
  groupBookingCustomerRatio: IGroupBookingCustomerRatio,
): IGroupServiceCustomerRatio {
  const ratios = groupBookingCustomerRatio.ratios;
  const customRatios = ratios.filter((ratio) => !ratio.isDuration);
  const durationRatios = ratios.filter((ratio) => ratio.isDuration);

  return {
    teamMemberCustomerRatio:
      groupBookingCustomerRatio?.defaultAttendanceRatio ?? groupBookingCustomerRatio.defaultRatio,
    startDateTime: moment.tz(sessionTimes.startDateTime, sessionTimes.timezone).toDate(),
    endDateTime: moment.tz(sessionTimes.endDateTime, sessionTimes.timezone).toDate(),
    isCustomRatio: customRatios.length > 0 || durationRatios.length > 0,
    customRatio: customRatios?.map((ratio) => ({
      startDateTime: moment.tz(ratio.startDateTime, sessionTimes.timezone).toDate(),
      endDateTime: moment.tz(ratio.endDateTime, sessionTimes.timezone).toDate(),
      teamMemberCustomerRatio: ratio.teamMemberCustomerRatio,
      comments: ratio.comments,
    })),
    durationRatios: durationRatios?.map((ratio) => ({
      duration: minutesBetween(ratio.startDateTime, ratio.endDateTime),
      unit: 'minutes',
      teamMemberCustomerRatio: ratio.teamMemberCustomerRatio,
      comments: ratio.comments,
    })),
  };
}

export function useRatioFromGroupBooking(sessionTimes: ISessionTimesParsed | null, groupBookingId: string | undefined) {
  const {
    groupBookingsStore: { doFetchGroupBookingCustomerRatio },
  } = useDispatch<IRootDispatch>();
  const groupBookingCustomerRatio = useSelector(
    (state: IRootState) => state.groupBookingsStore.groupBookingCustomerRatio,
  ) as unknown as IGroupBookingCustomerRatio;
  const [ratio, setRatio] = useState<IGroupServiceCustomerRatio | null>(null);

  const doFetchGroupBookingCustomerRatioCallback = useCallback(async () => {
    if (groupBookingId) {
      await doFetchGroupBookingCustomerRatio({ bookingId: groupBookingId });
    }
  }, [groupBookingId]);

  useEffect(() => {
    void doFetchGroupBookingCustomerRatioCallback();
  }, [doFetchGroupBookingCustomerRatioCallback]);

  useEffect(() => {
    if (sessionTimes && groupBookingCustomerRatio) {
      let ratio_ = getRatio(sessionTimes, groupBookingCustomerRatio);
      ratio_ = filterHiddenCustomRatios(ratio_);
      setRatio(ratio_);
    }
  }, [sessionTimes, groupBookingCustomerRatio]);

  return ratio;
}
