import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import moment, { Moment } from 'moment-timezone';

import { Text } from 'common-components/typography';
import TimeInput from 'common-components/time-input/TimeInput';
import './date-time.css';
import { FormModeType } from '../../EditValueFormModal';

export enum TypeDateTime {
  DateTime = 'Date & time',
  TimeOnly = 'Time only',
  DateOnly = 'Date only',
}

type IEditDateTimeElementProps = {
  value: string;
  onDateValueChanged: (time: Date) => void;
  timezone: string;
  formMode?: FormModeType;
  fieldTitle: string;
  caption: string;
  dateTimeType: TypeDateTime;
  verticalStyling?: boolean;
} & FormComponentProps

const DATE_FORMAT = 'DD-MM-YYYY';

export const SimpleEditDateTimeElement = (props: IEditDateTimeElementProps) => {
  const [date, setDate] = useState(moment(props.value ?? props.timezone).toDate());
  const [time, setTime] = useState(moment(props.value ?? props.timezone).toDate());

  useEffect(() => {
    const joinedTime = new Date();
    joinedTime.setFullYear(date.getFullYear());
    joinedTime.setMonth(date.getMonth());
    joinedTime.setDate(date.getDate());
    joinedTime.setHours(time.getHours());
    joinedTime.setMinutes(time.getMinutes());

    props.onDateValueChanged(joinedTime);
  }, [date, time]);

  const onChangeTime = (newTime: Date) => {
    // Time picker returns TODAY w/ picked time
    props.onDateValueChanged(newTime);
    setTime(newTime);
  };

  // Date picker returns moment of date selected in number form
  const onChangeDate = (newDate: Moment | null) => {
    const dateFormatted = moment.tz(newDate, props.timezone).toDate();
    setDate(dateFormatted);
  };

  return (
    <div className="flex-column width-full">
      <div className={`mb-medium mt-x-small  align-center ${props.verticalStyling ? 'flex-row' : null}`}>
        {(props.dateTimeType === TypeDateTime.DateTime || props.dateTimeType === TypeDateTime.DateOnly) && (
          <DatePicker
            placeholder="Date"
            className="mr-medium date-picker-form-builder"
            format={DATE_FORMAT}
            value={moment(props.value)}
            size="large"
            onChange={onChangeDate}
          />
        )}
        {(props.dateTimeType === TypeDateTime.DateTime || props.dateTimeType === TypeDateTime.TimeOnly) && (
          <div className="mr-small">
            <TimeInput
              size="large"
              className="line-height-150 time-picker-form-builder"
              value={props.value}
              onChange={onChangeTime}
            />
          </div>
        )}
        <Text size="medium" color="secondary">
            UTC
            {moment.tz(props.timezone).format('Z')}
          </Text>
      </div>
      <Text color="secondary">{props.caption}</Text>
    </div>
  );
};
