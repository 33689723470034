import React from 'react';

import { Form, Heading, Stack, StepContainer } from '../components';
import { FormProvider, useForm } from 'react-hook-form';

import { PrimaryButton, SecondaryButton } from '../../../../../common-components/buttons';
import { StepProps } from '../create-workflow';
import { WorkflowSettingsForm } from '../../forms/workflow-settings';
import { useCreateWorkflow } from '../create-workflow-provider';

import type { SettingsPayload } from '../create-workflow-provider';

export function WorkflowSettings(props: StepProps) {
  let { onBack, onNext } = props;
  let { state, submitSettings } = useCreateWorkflow();
  let methods = useForm({
    defaultValues: {
      ...state.settings,
    },
  });
  let { handleSubmit } = methods;

  function _handleSubmit(data: SettingsPayload) {
    submitSettings(data);
    onNext();
  }

  return (
    <>
      <StepContainer>
        <Heading>Settings and notifications</Heading>
        <FormProvider {...methods}>
          <Form id="workflow-settings" onSubmit={handleSubmit(_handleSubmit)}>
            <WorkflowSettingsForm />
          </Form>
        </FormProvider>
      </StepContainer>

      <Stack dir="row" gap="md" style={{ alignSelf: 'end' }}>
        <SecondaryButton onClick={onBack}>Back</SecondaryButton>

        <PrimaryButton htmlType="submit" form="workflow-settings">
          Next
        </PrimaryButton>
      </Stack>
    </>
  );
}
