import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Popover } from '@blueprintjs/core';
import { Avatar, Col, Empty, notification, Row } from 'antd';
import { SubTitle, Text, Title } from 'common-components/typography';

import { ITeamMemberForm } from 'interfaces/supportWorker-interfaces';
import { IElementValue } from 'views/form-builder/shared/form-interface';
import DeleteTeamMemberFormModal from './components/DeleteTeamMemberFormModal';
import EditValueFormModal from 'views/form-builder/components/EditValueFormModal';
import AttachFormModalWrapper from 'views/form-builder/components/AttachFormModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { HyperlinkButton, IconButton, PrimaryButton } from 'common-components/buttons';
import { ActionMenu, ActionMenuDivider, ActionMenuItem } from 'common-components/action-menu';
import { StatusTag } from 'common-components/tags';
import { useHistory } from 'react-router-dom';

const FormEmptyPanel = () => (
  <>
    <Row className="pv-medium bordered-bottom">
      <Col span={8}>
        <SubTitle>FORM NAME</SubTitle>
      </Col>
      <Col span={8}>
        <SubTitle>DATE ADDED</SubTitle>
      </Col>
      <Col span={8}>
        <SubTitle>ADDED BY</SubTitle>
      </Col>
    </Row>
    <div className="flex-1 bg-white align-center flex-column pt-x2-large">
      <div className="mb-small">
        <Empty className="m-none" description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      <Text size="x2-large" color="secondary" weight="bold">
        Nothing here yet.
      </Text>
      <Text color="secondary">Select ‘Add form’ to get started.</Text>
    </div>
  </>
);

interface ITeamMemberFormsPanelProps {
  selectedWorkerUserId: string;
  portalUser: typeof state.authStore.portalUser;
  currentForm: typeof state.formBuilderStore.currentForm;
  teamMemberForms: typeof state.teamStore.teamMemberForms;
  doAddTeamMemberForm: typeof dispatch.teamStore.doAddTeamMemberForm;
  doUpdateTeamMemberForm: typeof dispatch.teamStore.doUpdateTeamMemberForm;
  doFetchTeamMemberForms: typeof dispatch.teamStore.doFetchTeamMemberForms;
  doFetchFormDetailsByUserFormId: typeof dispatch.teamStore.doFetchFormDetailsByUserFormId;
  doDeleteTeamMemberForm: typeof dispatch.teamStore.doDeleteTeamMemberForm;
  setFormStatus: typeof dispatch.teamStore.setFormStatus;

  doUpdateBookingForm: typeof dispatch.bookingsStore.doUpdateBookingForm;
  doDeleteBookingForm: typeof dispatch.bookingsStore.doDeleteBookingForm;
  doFetchFormDetailByAttendanceFormId: typeof dispatch.bookingsStore.doFetchFormDetailByAttendanceFormId;

  doUpdateSessionForm: typeof dispatch.groupServiceStore.doUpdateSessionForm;
  doDeleteSessionForm: typeof dispatch.groupServiceStore.doDeleteSessionForm;
  doFetchFormDetailByServiceDateTimeFormId: typeof dispatch.groupServiceStore.doFetchFormDetailByServiceDateTimeFormId;
}

const TeamMemberFormsPanel = ({
  currentForm,
  portalUser,
  teamMemberForms,
  selectedWorkerUserId,
  doAddTeamMemberForm,
  doUpdateTeamMemberForm,
  doDeleteTeamMemberForm,
  doFetchTeamMemberForms,
  doFetchFormDetailsByUserFormId,
  doUpdateBookingForm,
  doDeleteBookingForm,
  doFetchFormDetailByAttendanceFormId,
  doUpdateSessionForm,
  doDeleteSessionForm,
  doFetchFormDetailByServiceDateTimeFormId,
  setFormStatus,
}: ITeamMemberFormsPanelProps) => {
  const [isOpenAddFormModal, setIsOpenAddFormModal] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isViewFormModalOpen, setIsViewFormModalOpen] = useState<boolean>(false);
  const [selectedTeamMemberForm, setSelectedTeamMemberForm] = useState<ITeamMemberForm>(null);
  const [fetchedAttachForm, setFetchedAttachForm] = useState<any>(null);
  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  const history = useHistory();

  const _goToBookingDetail = (bookingId) => {
    history.push(`/bookings/details/${bookingId}`);
  };

  const _goToServiceDetail = (serviceId) => {
    history.push(`/group-service/details/${serviceId}`);
  };

  const _handleOpenAddFormModal = () => {
    setIsOpenAddFormModal(true);
  };

  const _handleCloseAddFormModal = () => {
    setIsOpenAddFormModal(false);
  };

  const _handleCloseViewEditFormModal = () => {
    setIsViewFormModalOpen(false);
    setIsEditForm(false);
    setFetchedAttachForm(null);
  };

  const _handleCloseDeleteModal = async () => {
    setIsDeleteModalOpen(false);
    await doFetchTeamMemberForms({ supportWorkerUserId: selectedWorkerUserId });
  };

  const _handleEditFormAttach = async (teamMemberForm: ITeamMemberForm) => {
    let data;
    if (teamMemberForm.booking) {
      const payload = {
        bookingId: teamMemberForm.booking.bookingId,
        attendanceFormId: teamMemberForm.id,
      };
      data = await doFetchFormDetailByAttendanceFormId(payload);
    } else if (teamMemberForm.session) {
      const payload = {
        serviceId: teamMemberForm.session.serviceId,
        serviceDateTimeId: teamMemberForm.session.serviceDateTimeId,
        serviceDateTimeFormId: teamMemberForm.id,
      };
      data = await doFetchFormDetailByServiceDateTimeFormId(payload);
    } else {
      const payload = {
        supportWorkerUserId: selectedWorkerUserId,
        userFormId: teamMemberForm.id,
      };
      data = await doFetchFormDetailsByUserFormId(payload);
    }

    setIsEditForm(true);
    setSelectedTeamMemberForm(teamMemberForm);
    setFetchedAttachForm(data);
    setIsViewFormModalOpen(true);
  };

  const _handleViewFormAttach = async (teamMemberForm: ITeamMemberForm) => {
    let data;
    if (teamMemberForm.booking) {
      const payload = {
        bookingId: teamMemberForm.booking.bookingId,
        attendanceFormId: teamMemberForm.id,
      };
      data = await doFetchFormDetailByAttendanceFormId(payload);
    } else if (teamMemberForm.session) {
      const payload = {
        serviceId: teamMemberForm.session.serviceId,
        serviceDateTimeId: teamMemberForm.session.serviceDateTimeId,
        serviceDateTimeFormId: teamMemberForm.id,
      };
      data = await doFetchFormDetailByServiceDateTimeFormId(payload);
    } else {
      const payload = {
        supportWorkerUserId: selectedWorkerUserId,
        userFormId: teamMemberForm.id,
      };
      data = await doFetchFormDetailsByUserFormId(payload);
    }

    setIsEditForm(false);
    setSelectedTeamMemberForm(teamMemberForm);
    setFetchedAttachForm(data);
    setIsViewFormModalOpen(true);
  };

  const _handleDeleteFormAttach = (teamMemberForm: ITeamMemberForm) => {
    setSelectedTeamMemberForm(teamMemberForm);
    setIsDeleteModalOpen(true);
  };

  const _onSaveEditFormAttach = async (id: string, elementValues: IElementValue[], status: string) => {
    let response = false;

    try {
      if (selectedTeamMemberForm.booking) {
        const payload = {
          bookingId: selectedTeamMemberForm.booking.bookingId,
          attendanceFormId: selectedTeamMemberForm.id,
          formData: elementValues,
          status,
        };
        response = await doUpdateBookingForm(payload);
      } else if (selectedTeamMemberForm.session) {
        const payload = {
          serviceId: selectedTeamMemberForm.session.serviceId,
          serviceDateTimeId: selectedTeamMemberForm.session.serviceDateTimeId,
          serviceDateTimeFormId: selectedTeamMemberForm.id,
          formData: elementValues,
          status,
        };
        response = await doUpdateSessionForm(payload);
      } else {
        const payload = {
          supportWorkerUserId: selectedWorkerUserId,
          userFormId: selectedTeamMemberForm.id,
          formData: elementValues,
          status,
        };
        response = await doUpdateTeamMemberForm(payload);
      }

      if (response) setFormStatus({ id, status });

      _handleCloseViewEditFormModal();
      notification.open({
        message: <span className={'text-weight-bolder'}>Form updated</span>,
        description: <span>You have successfully updated a form on this team member’s profile.</span>,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  const _handleExportPdf = (userForm) => {
    if (userForm.booking) {
      window.open(`/pdf?type=view-form&attendanceFormId=${userForm.id}`, '_blank');
    } else if (userForm.session) {
      window.open(`/pdf?type=view-form&serviceDateTimeFormId=${userForm.id}`, '_blank');
    } else {
      window.open(`/pdf?type=view-form&userFormId=${userForm.id}`, '_blank');
    }
  };

  const _doSave = async (
    formId: string,
    elementValues: IElementValue[],
    formName: string,
    versionId: string,
    status: string,
  ) => {
    try {
      const payload = {
        formId,
        supportWorkerUserId: selectedWorkerUserId,
        formData: elementValues,
        status,
      };
      await doAddTeamMemberForm(payload);
      notification.open({
        message: <span className={'text-weight-bolder'}>Form attached</span>,
        description: <span>You have successfully attached a form to this team member’s profile.</span>,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  const _doDeleteFormAttach = async () => {
    try {
      if (selectedTeamMemberForm.booking) {
        const payload = {
          bookingId: selectedTeamMemberForm.booking.bookingId,
          attendanceFormId: selectedTeamMemberForm.id,
        };
        await doDeleteBookingForm(payload);
      } else if (selectedTeamMemberForm.session) {
        const payload = {
          serviceId: selectedTeamMemberForm.session.serviceId,
          serviceDateTimeId: selectedTeamMemberForm.session.serviceDateTimeId,
          serviceDateTimeFormId: selectedTeamMemberForm.id,
        };
        await doDeleteSessionForm(payload);
      } else {
        const payload = {
          supportWorkerUserId: selectedWorkerUserId,
          userFormId: selectedTeamMemberForm.id,
        };
        await doDeleteTeamMemberForm(payload);
      }

      _handleCloseDeleteModal();
      notification.open({
        message: <span className={'text-weight-bolder'}>Form removed</span>,
        description: <span>You have successfully removed a form from this team member’s profile. </span>,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  const _displayFormFrom = (form: ITeamMemberForm) => {
    if (form.booking) {
      return (
        <Text>
          Booking:{' '}
          <HyperlinkButton onClick={() => _goToBookingDetail(form.booking.bookingId)}>
            {form.booking.batchNumber || `TB${form.booking.bookingId.slice(0, 5)}`}
          </HyperlinkButton>
        </Text>
      );
    }
    if (form.session) {
      return (
        <Text>
          Session:{' '}
          <HyperlinkButton onClick={() => _goToServiceDetail(form.session.serviceId)}>Group Services</HyperlinkButton>
        </Text>
      );
    }
    return <Text>Profile</Text>;
  };

  useEffect(() => {
    doFetchTeamMemberForms({ supportWorkerUserId: selectedWorkerUserId });
  }, []);

  return (
    <>
      <AttachFormModalWrapper
        timezone={moment.tz.guess()}
        isOpen={isOpenAddFormModal}
        onClose={_handleCloseAddFormModal}
        onSave={_doSave}
      />

      {(isViewFormModalOpen || isEditForm) && selectedTeamMemberForm && fetchedAttachForm && (
        <EditValueFormModal
          formId={selectedTeamMemberForm.id}
          formElements={currentForm}
          elementValues={fetchedAttachForm.formData}
          isOpen={isViewFormModalOpen}
          onClose={_handleCloseViewEditFormModal}
          onSave={_onSaveEditFormAttach}
          mode={isEditForm ? 'EDIT' : 'VIEW'}
        />
      )}
      {selectedTeamMemberForm && isDeleteModalOpen && (
        <DeleteTeamMemberFormModal
          isOpen={isDeleteModalOpen}
          onDelete={_doDeleteFormAttach}
          onClose={_handleCloseDeleteModal}
        />
      )}

      <Row type={'flex'} justify={'space-between'} align={'bottom'} className="mb-x-large">
        <Col>
          <Title level={2} className={'m-none'}>
            Forms
          </Title>
          <Text type={'secondary'}>Add and manage all forms related to this team member.</Text>
        </Col>
        <Col className={'align-center'}>
          <div className={'flex align-center'}>
            <PrimaryButton size="large" onClick={_handleOpenAddFormModal}>
              + Add form
            </PrimaryButton>
          </div>
        </Col>
      </Row>

      <div className="mb-x2-large">
        {!_.isEmpty(teamMemberForms) ? (
          <>
            <Row className="pv-medium bordered-bottom">
              <Col span={6}>
                <SubTitle>FORM NAME</SubTitle>
              </Col>
              <Col span={4}>
                <SubTitle>DATE ADDED</SubTitle>
              </Col>
              <Col span={4}>
                <SubTitle>ADDED FROM</SubTitle>
              </Col>
              <Col span={3}>
                <SubTitle>STATUS</SubTitle>
              </Col>
              <Col span={6}>
                <SubTitle>ADDED BY</SubTitle>
              </Col>
              <Col span={1} />
            </Row>

            {_.map(teamMemberForms, (form: ITeamMemberForm, index) => (
              <Row type="flex" align="middle" className="pv-medium" key={index}>
                <Col span={6}>
                  <HyperlinkButton onClick={() => _handleViewFormAttach(form)}>{form.formName}</HyperlinkButton>
                </Col>
                <Col span={4}>{moment(form.addedOn).format('DD MMM YYYY')}</Col>
                <Col span={4}>
                  <Text>{_displayFormFrom(form)}</Text>
                </Col>
                <Col span={3}>
                  <StatusTag status={form.status} />
                </Col>
                <Col span={6}>
                  <Avatar className="mr-medium" size="default" icon="user" src={form.addedByAvatarUrl} />
                  <Text>{form.addedByName}</Text>
                </Col>
                <Col span={1} className="text-align-right pr-small">
                  <Popover
                    content={
                      <ActionMenu>
                        <>
                          <ActionMenuItem text="View form" onClick={() => _handleViewFormAttach(form)} />
                          {portalUser && portalUser.userId === form.addedBy && (
                            <ActionMenuItem text="Edit form" onClick={() => _handleEditFormAttach(form)} />
                          )}
                          <ActionMenuDivider />
                          <ActionMenuItem text="Export as PDF" onClick={() => _handleExportPdf(form)} />
                          <ActionMenuItem
                            text="Remove"
                            className="text-color-red"
                            onClick={() => _handleDeleteFormAttach(form)}
                          />
                        </>
                      </ActionMenu>
                    }
                  >
                    <IconButton
                      icon="ellipsis"
                      size="small"
                      className="bg-white"
                      bordered={true}
                      iconColor="secondary"
                      onClick={() => {}}
                    />
                  </Popover>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <FormEmptyPanel />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  currentForm: state.formBuilderStore.currentForm,
  teamMemberForms: state.teamStore.teamMemberForms,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doAddTeamMemberForm: dispatch.teamStore.doAddTeamMemberForm,
  doUpdateTeamMemberForm: dispatch.teamStore.doUpdateTeamMemberForm,
  doDeleteTeamMemberForm: dispatch.teamStore.doDeleteTeamMemberForm,
  doFetchTeamMemberForms: dispatch.teamStore.doFetchTeamMemberForms,
  doFetchFormDetailsByUserFormId: dispatch.teamStore.doFetchFormDetailsByUserFormId,
  setFormStatus: dispatch.teamStore.setFormStatus,

  doUpdateBookingForm: dispatch.bookingsStore.doUpdateBookingForm,
  doDeleteBookingForm: dispatch.bookingsStore.doDeleteBookingForm,
  doFetchFormDetailByAttendanceFormId: dispatch.bookingsStore.doFetchFormDetailByAttendanceFormId,

  doUpdateSessionForm: dispatch.groupServiceStore.doUpdateSessionForm,
  doDeleteSessionForm: dispatch.groupServiceStore.doDeleteSessionForm,
  doFetchFormDetailByServiceDateTimeFormId: dispatch.groupServiceStore.doFetchFormDetailByServiceDateTimeFormId,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberFormsPanel);
