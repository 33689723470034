import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Row, Col, Input } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';

interface IPortalEditNameActionModelProps extends FormComponentProps {
  isOpen: boolean;
  portalUser: typeof state.authStore.portalUser;
  doUpdatePortalUserDetail: typeof dispatch.authStore.doUpdatePortalUserDetail;
  onCloseModel: () => void;
}

interface IPortalEditNameActionModelState {
  isLoading: boolean;
}

class PortalEditNameActionModel extends Component<IPortalEditNameActionModelProps, IPortalEditNameActionModelState> {
  state = {
    isLoading: false,
  };

  private _onSaveChange = () => {
    const { form, doUpdatePortalUserDetail, onCloseModel } = this.props;
    form.validateFieldsAndScroll(async (err, value) => {
      if (!err) {
        this.setState({ isLoading: true });
        try {
          await doUpdatePortalUserDetail(value);
          this.setState({ isLoading: false });
          //TODO: close modal
          onCloseModel();
        } catch (e) {
          this.setState({ isLoading: false });
        }
      }
    });
  };

  private _onCancelChange = () => {
    const { onCloseModel } = this.props;
    onCloseModel();
  };

  private _firstNameInputValidator = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('First name is required');
      }
      if (_.trim(value).length < 2) {
        throw Error('please enter at least 2 characters');
      }
      if (value.length > 50) {
        throw Error('please enter less than 50 characters');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _lastNameInputValidator = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('Last name is required');
      }
      if (_.trim(value).length < 2) {
        throw Error('please enter at least 2 characters');
      }
      if (value.length > 50) {
        throw Error('please enter less than 50 characters');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  render() {
    const { isOpen, form, portalUser } = this.props;
    const { getFieldDecorator } = form;
    return (
      <ActionModal isOpen={isOpen} title={'Edit Name'} onClose={this._onCancelChange}>
        <div style={{ minHeight: 366 }}>
          <Text className={'mb-medium'}>
            Please enter the preferred First & Last Name you wish to be displayed in the portal.
          </Text>
          <br />
          <Text className={'mb-medium'}>These changes will be viewed by both users of the app and portal.</Text>

          <Row className="mt-x3-large">
            <Col span={24}>
              <SubTitle>First Name</SubTitle>
              <Row>
                <Col span={10}>
                  <Form.Item>
                    {getFieldDecorator('firstName', {
                      initialValue: portalUser.firstName,
                      rules: [{ validator: this._firstNameInputValidator }],
                    })(<Input size="large" />)}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <SubTitle>Last Name</SubTitle>
              <Row>
                <Col span={10}>
                  <Form.Item>
                    {getFieldDecorator('lastName', {
                      initialValue: portalUser.lastName,
                      rules: [{ validator: this._lastNameInputValidator }],
                    })(<Input size={'large'} />)}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <ActionModalFooter>
          <GhostButton size="large" onClick={this._onCancelChange} disabled={this.state.isLoading}>
            Cancel
          </GhostButton>
          <PrimaryButton className="mr-medium" size="large" onClick={this._onSaveChange} loading={this.state.isLoading}>
            Save changes
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });
const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdatePortalUserDetail: dispatch.authStore.doUpdatePortalUserDetail,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IPortalEditNameActionModelProps>()(PortalEditNameActionModel));
