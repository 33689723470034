import React from 'react';

interface ICommonTagProps {
  content: string | React.ReactNode;
  color?: string;
  bgColor?: string;
  rounded?: boolean;
  borderColor?: string;
  containerClassName?: string;
  fontWeight?: string;
  fontSize?: string;
  onClick?: () => void;
}

const CommonTag: React.FC<ICommonTagProps> = ({
  content,
  color = 'black',
  bgColor = 'white',
  borderColor,
  rounded = true,
  containerClassName,
  fontWeight = 'bold',
  fontSize = 'small',
  onClick,
}) => {
  const className = `${containerClassName ?? ''} text-color-${color} bg-${bgColor} ${rounded ? 'rounded' : ''} ${
    borderColor ? `bordered border-${borderColor}` : ''
  } ph-medium text-weight-${fontWeight} pv-x-small text-size-${fontSize}`;

  return (
    <div className={className} onClick={onClick} style={{ width: 'fit-content' }}>
      {content}
    </div>
  );
};

export default CommonTag;
