/* eslint-disable */

import React, { Component } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { GhostButton } from 'common-components/buttons';
import { IInviteConsumer } from 'interfaces/user-interfaces';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const { Option } = Select;

interface IInviteConsumerDetailRowProps extends FormComponentProps {
  rowKey: number;
  consumerInputItem: IInviteConsumer;
  handleDelete: (rowKey: number) => void;
  handleInputClick: (string) => void;
  handleDropDownClick: (string, number, name: string) => void;
}

class InviteConsumerDetailRow extends Component<IInviteConsumerDetailRowProps, any> {
  componentDidUpdate = (prevProps) => {
    if (prevProps.consumerInputItem !== this.props.consumerInputItem) {
      const { consumerInputItem } = this.props;

      // set antd form to latest value
      const fieldValues: any = {
        firstName: consumerInputItem.firstName,
        lastName: consumerInputItem.lastName,
        email: consumerInputItem.email,
        phoneNumber: consumerInputItem.phoneNumber,
        phoneNumberCountryCode: consumerInputItem.phoneNumberCountryCode,
      };

      this.props.form.setFieldsValue(fieldValues);
    }
  };

  private _removeConsumerInputRow = (i) => {
    this.props.handleDelete(i);
  };

  private _handleInputClick = (event) => {
    this.props.handleInputClick(event);
  };

  private _handleCountryCodeClick = (event) => {
    this.props.handleDropDownClick(event, this.props.rowKey, 'phoneNumberCountryCode');
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';
    let phoneNumber = parsePhoneNumberFromString(value, region);
    try {
      if (!phoneNumber.isValid()) {
        throw new Error('Invalid Number');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  render() {
    const { form, consumerInputItem } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Row gutter={16}>
        <Col span={5}>
          <Form.Item>
            {getFieldDecorator('firstName', {
              rules: [{ required: true, message: 'First name is required' }],
              initialValue: consumerInputItem.firstName,
            })(<Input onChange={this._handleInputClick} name="firstName" size="large" style={{ fontSize: '16px' }} />)}
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item>
            {getFieldDecorator('lastName', {
              rules: [{ required: true, message: 'Last name is required' }],
              initialValue: consumerInputItem.lastName,
            })(<Input onChange={this._handleInputClick} name="lastName" size="large" style={{ fontSize: '16px' }} />)}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Row type={'flex'} gutter={4}>
            <Col span={9}>
              <Form.Item>
                {getFieldDecorator('phoneNumberCountryCode', {
                  rules: [{ required: true, message: 'Country code is required' }],
                  initialValue: consumerInputItem.phoneNumberCountryCode,
                })(
                  <Select
                    dropdownMatchSelectWidth={true}
                    onChange={this._handleCountryCodeClick}
                    disabled={true}
                    size="large"
                    style={{ fontSize: '16px' }}
                  >
                    <Option value={'AU'}>+61 (AU)</Option>
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={15}>
              <Form.Item>
                {getFieldDecorator('phoneNumber', {
                  initialValue: consumerInputItem.phoneNumber,
                  rules: [
                    { required: true, message: 'Mobile number is required' },
                    {
                      validator: this._validateMobileNumber,
                      message: 'Invalid Mobile number.',
                    },
                  ],
                })(
                  <Input
                    placeholder="Enter mobile number"
                    style={{ fontSize: '16px' }}
                    size="large"
                    className={'width-full'}
                    name={'phoneNumber'}
                    onChange={this._handleInputClick}
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={7}>
          <div className="flex-row">
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'Please enter valid Email',
                  },
                  {
                    required: true,
                    message: 'Email is required',
                  },
                ],
              })(<Input onChange={this._handleInputClick} name="email" size="large" style={{ fontSize: '16px' }} />)}
            </Form.Item>
            <Form.Item>
              <GhostButton
                icon="close"
                size="large"
                paddingSize="medium"
                className="ml-small"
                color="black"
                onClick={() => this._removeConsumerInputRow(this.props.rowKey)}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    );
  }
}

export default Form.create<IInviteConsumerDetailRowProps>()(InviteConsumerDetailRow);
