import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { ProgressBar } from '@blueprintjs/core';
import { Avatar, Col, Row, Skeleton } from 'antd';

import { SubTitle, Title, Text } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';

import PreviewFormModal from '../PreviewFormModal';
import { IFormContent, IFormVersion } from '../../shared/form-interface';

interface IVersionHistoryPanelProps {
  doGetFormVersionHistory: typeof dispatch.formBuilderStore.doGetFormVersionHistory;
  doGetFormDetailsByVersion: typeof dispatch.formBuilderStore.doGetFormDetailsByVersion;
  formId: string;
}
export const VersionHistoryPanel = ({
  formId,
  doGetFormVersionHistory,
  doGetFormDetailsByVersion,
}: IVersionHistoryPanelProps) => {
  const [formVersionList, setFormVersionList] = useState<IFormVersion[]>(null);
  const [selectedForm, setSelectedForm] = useState<IFormContent>(null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const _onPreviewFormOpen = async (formId, versionId) => {
    const formDetails = await doGetFormDetailsByVersion({ formId, versionId });
    setSelectedForm(formDetails as any);
    setIsPreviewModalOpen(true);
  };

  const _onPreviewFormClose = () => {
    setIsPreviewModalOpen(false);
    setSelectedForm(null);
  };

  useEffect(() => {
    setIsLoading(true);
    doGetFormVersionHistory({ formId })
      .then((res) => setFormVersionList(res as any))
      .then(() => setIsLoading(false));
  }, [formId]);

  if (formVersionList === null || isLoading) {
    return (
      <>
        <div className="item-container">
          <ProgressBar />
        </div>
        <Skeleton loading={isLoading} />
      </>
    );
  } else
    return (
      <div className="pv-x-large">
        {isPreviewModalOpen && (
          <PreviewFormModal
            timezone={moment.tz.guess()}
            formContent={selectedForm}
            isOpen={isPreviewModalOpen}
            onClose={_onPreviewFormClose}
          />
        )}
        <Title level={4}>Version history</Title>
        <div className="bg-white">
          <Row className="ph-medium pv-large">
            <Col span={8}>
              <SubTitle>VERSION</SubTitle>
            </Col>
            <Col span={8}>
              <SubTitle>DATE CREATED</SubTitle>
            </Col>
            <Col span={4}>
              <SubTitle>CREATED BY</SubTitle>
            </Col>
            <Col span={4} />
          </Row>
          {_.map(formVersionList, (formVersion: IFormVersion, index) => (
            <Row className="ph-medium pv-large bordered-top" key={index}>
              <Col span={8}>
                <Text
                  color="info-blue"
                  className="cursor-pointer"
                  onClick={() => _onPreviewFormOpen(formVersion.formId, formVersion.formVersionId)}
                >
                  V{formVersion.version}
                </Text>
              </Col>
              <Col span={8}>
                <Text>{moment(formVersion.createdOn).format('DD MMMM YYYY')}</Text>
              </Col>
              <Col span={8}>
                <Avatar className="mr-medium" size="large" icon="user" src={formVersion.attachmentUrl} />
                {formVersion.displayName}
              </Col>
            </Row>
          ))}
        </div>
      </div>
    );
};
const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doGetFormVersionHistory: dispatch.formBuilderStore.doGetFormVersionHistory,
  doGetFormDetailsByVersion: dispatch.formBuilderStore.doGetFormDetailsByVersion,
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionHistoryPanel);
