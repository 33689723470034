import React from 'react';
import _ from 'lodash';
import { Avatar, Checkbox, Col, Icon, Row, Tooltip, Popover as AntPopover } from 'antd';
import { Popover } from '@blueprintjs/core';
import { Paragraph, Text } from 'common-components/typography';
import { HyperlinkButton, IconButton } from 'common-components/buttons';
import TextTag from 'common-components/tags/TextTag';
import { SHIFT_SLOT_COLUMN_WIDTHS } from 'views/group-services/session-details/team-members/shift-slot-table/shift-table-common-constants';
import moment from 'moment-timezone';
import { IShiftSlotActions } from 'views/group-services/session-details/team-members/SessionTeamMembersPanel';
import { ShiftSlotActionButtons } from 'views/group-services/session-details/team-members/shift-slot-table/ShiftSlotActionButtons';
import { ShiftStatusTag } from 'views/group-services/session-details/team-members/shift-slot-table/ShiftStatusTag';
import { GroupServiceSessionStatus, ShiftSlotStatus, ShiftWarningType } from 'utilities/enum-utils';
import { timeZone } from 'interfaces/timezone-type';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { ISession } from 'interfaces/session-interfaces';
import { InitialAvatar } from 'common-components/avatars';
import ActivityGroupsSummaryTag from 'common-components/activity-groups/popover/ActivityGroupsSummaryTag';

interface IShiftSlotRowProps {
  shiftSlot: IShiftSlot;
  actions: IShiftSlotActions;
  // checkAll: boolean;
  timezone: timeZone;
  session: ISession;
  isSelected: boolean;
  onSelect: (v) => void;
  onDeselect: (v) => void;
  isHighlighted: boolean;
}

interface IShiftSlotRowState {
  isChecked: boolean;
}

export class ShiftSlotRow extends React.Component<IShiftSlotRowProps, IShiftSlotRowState> {
  // state = { isChecked: false };

  onAssignTo = () => {
    const { shiftSlot, actions } = this.props;
    const { onAssignWorker } = actions;
    onAssignWorker({ shiftSlot });
  };

  onCheck = () => {
    const { isSelected, shiftSlot, onSelect, onDeselect } = this.props;
    if (isSelected) {
      onDeselect(shiftSlot);
    } else {
      onSelect(shiftSlot);
    }
  };

  renderLinkAction = ({ shiftSlotStatus }: { shiftSlotStatus: ShiftSlotStatus }) => {
    const { actions, shiftSlot, timezone } = this.props;

    let linkTitle = null;
    let linkAction = null;

    switch (shiftSlotStatus) {
      case ShiftSlotStatus.PENDING:
        linkTitle = 'Confirm...';
        linkAction = actions.onConfirmSlot;
        break;

      case ShiftSlotStatus.CHECKED_IN:
        linkTitle = 'Check out...';
        linkAction = actions.onFinishShift;
        break;

      case ShiftSlotStatus.DECLINED:
        linkTitle = 'Re-assign...';
        linkAction = actions.onAssignWorker;
        break;

      case ShiftSlotStatus.CHECKED_OUT:
        linkTitle = 'Approve...';
        linkAction = actions.onApproveSlot;
        break;

      case ShiftSlotStatus.ON_LEAVE:
        linkTitle = 'Approve...';
        linkAction = actions.onApproveLeave;
        break;
    }

    return (
      <>
        {linkAction !== null && linkTitle !== null && (
          <HyperlinkButton fontSize="regular" onClick={() => linkAction({ shiftSlot })}>
            {linkTitle}
          </HyperlinkButton>
        )}
        {!linkAction && !linkTitle && shiftSlot.activityGroups.length > 0 && (
          <ActivityGroupsSummaryTag activityGroups={shiftSlot.activityGroups} timezone={timezone} />
        )}
      </>
    );
  };

  renderWarning = ({ shiftSlot }) => {
    const warningTypes = shiftSlot.shiftWarnings; // disabled for now

    const hasWarning = !_.isEmpty(warningTypes);

    if (hasWarning) {
      return _.map(warningTypes, (warning) => <WarningTag warningType={warning.shiftSlotErrorType} />);
    } else {
      return <></>;
    }
  };

  render() {
    const hasBulkActions = true;
    const { shiftSlot, actions, timezone, session } = this.props;
    const isSessionScheduledOrInProgress =
      session &&
      (session.sessionStatus === GroupServiceSessionStatus.SCHEDULED ||
        session.sessionStatus === GroupServiceSessionStatus.INPROGRESS);

    const {
      shiftSlotStatus,
      shiftStartDateTime,
      shiftEndDateTime,
      workerCheckedInDateTime,
      workerCheckedOutDateTime,
      firstName,
      lastName,
      attachmentUrl,
      shiftCustomerAssignments,
    } = shiftSlot;

    const containerStyle =
      shiftSlotStatus === 'UNASSIGNED' ? 'border-dashed bg-quaternary' : 'border-standard-gray bg-white';

    const timeSlotActualHours = moment(workerCheckedOutDateTime).diff(moment(workerCheckedInDateTime), 'hour');
    const timeSlotActualMinutes = moment(workerCheckedOutDateTime).diff(moment(workerCheckedInDateTime), 'minute') % 60;

    return (
      <Row type="flex" style={{ flexWrap: 'nowrap' }} className="mb-medium select-none">
        {/* Checkbox */}
        <Col
          className="ph-medium cursor-pointer hover-bg-quaternary flex-column justify-center"
          style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.CHECKBOX }}
          onClick={this.onCheck}
        >
          <Checkbox checked={this.props.isSelected} onChange={this.onCheck} />
        </Col>

        {/* Main container */}
        <Col
          className={`bordered rounded-big flex-1 ${containerStyle} ${
            this.props.isHighlighted ? 'border-blue-action border-width-large' : ''
          }`}
        >
          <Row type="flex" style={{ flexWrap: 'nowrap' }} className="align-center line-height-120">
            {/* Scheduled date/time column */}
            <Col className="p-medium " style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.SCHEDULED }}>
              <Text lineHeight={100} size="regular">
                {/*9:30 AM <br /> 3:30 PM*/}
                {moment.tz(shiftStartDateTime, timezone).format('h:mm A')} <br />
                {moment.tz(shiftEndDateTime, timezone).format('h:mm A')} <br />
              </Text>
            </Col>

            {/* Team member column */}
            <Col className="p-medium " style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.TEAM_MEMBER }}>
              <div className="flex-row align-center">
                {shiftSlotStatus === ShiftSlotStatus.UNASSIGNED && (
                  <>
                    <Avatar icon="user" size="default" className="bg-secondary mr-small" shape="square" />
                    <div className="line-height-120">
                      <Paragraph
                        lineHeight={120}
                        color="secondary"
                        size="regular"
                        className="mv-none"
                        ellipsis={{ rows: 1 }}
                      >
                        Not assigned
                      </Paragraph>
                      {isSessionScheduledOrInProgress && (
                        <HyperlinkButton fontSize="regular" onClick={this.onAssignTo}>
                          Assign to...
                        </HyperlinkButton>
                      )}
                    </div>
                  </>
                )}

                {shiftSlotStatus !== ShiftSlotStatus.UNASSIGNED && (
                  <>
                    <div>
                      <Avatar
                        icon="user"
                        size="default"
                        className="bg-secondary mr-small"
                        shape="square"
                        src={attachmentUrl}
                      />
                    </div>
                    <div className="line-height-120 width-full pr-x-large">
                      <Paragraph lineHeight={120} size="regular" className="mv-none" ellipsis={{ rows: 1 }}>
                        {`${firstName || ''} ${lastName || ''}`}
                      </Paragraph>

                      {shiftCustomerAssignments && shiftCustomerAssignments.length > 0 && (
                        <AntPopover
                          content={
                            <div>
                              {_.map(shiftCustomerAssignments, (assignment) => (
                                <div className="flex-row line-height-120 mb-small">
                                  <Avatar
                                    icon="user"
                                    size="small"
                                    className="mr-small"
                                    src={assignment.customerAttachmentUrl}
                                  />
                                  <Text size="regular">
                                    <span className={'text-weight-bold'}>
                                      {assignment.customerFirstName} {assignment.customerLastName}
                                    </span>
                                    <br />
                                    <span>
                                      {moment.tz(assignment.startDateTime, timezone).format('h:mm a')}{' '}
                                      {moment.tz(assignment.endDateTime, timezone).format('h:mm a')}
                                    </span>
                                  </Text>
                                </div>
                              ))}

                              <div className="mt-small">
                                <HyperlinkButton onClick={() => actions.onAssignToCustomer({ shiftSlot })}>
                                  <Icon type={'edit'} /> Edit customer assignments...
                                </HyperlinkButton>
                              </div>
                            </div>
                          }
                          title={<span className="text-weight-bold text-size-regular">Assigned customers</span>}
                          placement="bottom"
                        >
                          <div className="line-height-120 flex-row align-center cursor-help">
                            {_.map(_.slice(shiftCustomerAssignments, 0, 4), (assignment) => {
                              return (
                                <InitialAvatar
                                  firstName={assignment.customerFirstName}
                                  lastName={assignment.customerLastName}
                                  className={'mr-x2-small'}
                                  size={'small'}
                                  weight={'bold'}
                                />
                              );
                            })}
                            {shiftCustomerAssignments && shiftCustomerAssignments.length > 4 && (
                              <Text size={'regular'}>+{shiftCustomerAssignments.length - 4}</Text>
                            )}
                          </div>
                        </AntPopover>
                      )}

                      {this.renderLinkAction({ shiftSlotStatus })}
                    </div>
                  </>
                )}
              </div>
            </Col>

            {/* Status column */}
            <Col className="p-medium " style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.STATUS }}>
              <ShiftStatusTag shiftStatus={shiftSlotStatus} />
            </Col>

            {shiftSlotStatus === ShiftSlotStatus.UNASSIGNED && (
              <>
                {/* Filler column */}
                <Col className="p-medium flex-1">&nbsp;</Col>
              </>
            )}

            {shiftSlotStatus !== ShiftSlotStatus.UNASSIGNED && (
              <>
                {/* Start time */}
                <Col className="p-medium " style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.START }}>
                  {/*9:30 AM*/}
                  <Text lineHeight={100} size="regular">
                    {workerCheckedInDateTime ? moment.tz(workerCheckedInDateTime, timezone).format('h:mm A') : null}
                  </Text>
                </Col>

                {/* End time */}
                <Col className="p-medium " style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.FINISH }}>
                  <Text lineHeight={100} size="regular">
                    {workerCheckedOutDateTime ? moment.tz(workerCheckedOutDateTime, timezone).format('h:mm A') : null}
                  </Text>
                </Col>

                {/* Hours*/}
                <Col className="p-medium " style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.HOURS }}>
                  {workerCheckedInDateTime && workerCheckedOutDateTime && (
                    <Text size="regular" lineHeight={100}>
                      {timeSlotActualHours > 0 && (
                        <>
                          <b>{timeSlotActualHours}</b>
                          <span className="text-color-tertiary">&nbsp;hrs.</span>
                        </>
                      )}
                      {timeSlotActualHours > 0 && timeSlotActualMinutes > 0 && <br />}
                      {timeSlotActualMinutes > 0 && (
                        <>
                          <b>{timeSlotActualMinutes}</b>
                          <span className="text-color-tertiary">&nbsp;min.</span>
                        </>
                      )}
                    </Text>
                  )}
                </Col>

                {/* Warnings */}
                <Col className="p-medium " style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.WARNINGS }}>
                  {this.renderWarning({ shiftSlot })}
                </Col>
              </>
            )}

            {/* Action icons */}
            <Col className="p-medium" style={{ width: SHIFT_SLOT_COLUMN_WIDTHS.ACTION_ICON }}>
              <Popover
                content={<ShiftSlotActionButtons shiftSlot={shiftSlot} actions={actions} />}
                position={'bottom-right'}
              >
                <IconButton
                  icon={'ellipsis'}
                  size="default"
                  iconColor={'blue-action'}
                  color={'white'}
                  bordered={true}
                  className="border-standard-gray"
                />
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

// Warning tag
function WarningTag({ warningType }: { warningType: ShiftWarningType }) {
  return (
    <TextTag
      content={
        <Text lineHeight={100} size="small" color="orange-dark">
          <Icon type="warning" className="text-color-warning-orange mr-x2-small" theme="filled" />
          {_.chain(ShiftWarningType[warningType]).replace('_', ' ').capitalize().value()}
        </Text>
      }
      size="small"
      color="orange"
      theme="light"
    />
  );
}
