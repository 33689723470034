import React, { forwardRef } from 'react';
import { Message } from '@goodhuman-me/components';
import { useTranslation } from 'react-i18next';

import { BUDGET_STATUS } from '../../budget';

import type { BudgetStatus } from '../../budget';
import type { MessageProps } from '@goodhuman-me/components';

export type BudgetStatusMessageProps = {
  status: BudgetStatus;
} & Omit<MessageProps, 'tone'>;

/**
 * @name
 * BudgetStatusMessage
 *
 * @example
 * <BudgetStatusMessage status="ON_TRACK" />
 */
export const BudgetStatusMessage = forwardRef<HTMLElement, BudgetStatusMessageProps>(function BudgetStatusMessage(
  props,
  ref,
) {
  let { t } = useTranslation('', { keyPrefix: 'budgetStatus' });
  let { status, ...otherProps } = props;

  let messageOptions: Record<BudgetStatus, Pick<MessageProps, 'children' | 'tone'>> = {
    [BUDGET_STATUS.OVERBUDGET]: {
      tone: 'critical',
      children: t(BUDGET_STATUS.OVERBUDGET),
    },
    [BUDGET_STATUS.WITHIN_BUDGET]: {
      children: t(BUDGET_STATUS.WITHIN_BUDGET),
    },
    [BUDGET_STATUS.NONE]: {
      children: t(BUDGET_STATUS.NONE),
    },
    [BUDGET_STATUS.SIGNIFICANTLY_AHEAD]: {
      tone: 'critical',
      children: t(BUDGET_STATUS.SIGNIFICANTLY_AHEAD),
    },
    [BUDGET_STATUS.SIGNIFICANTLY_BEHIND]: {
      tone: 'critical',
      children: t(BUDGET_STATUS.SIGNIFICANTLY_BEHIND),
    },
    [BUDGET_STATUS.SLIGHTLY_AHEAD]: {
      tone: 'notice',
      children: t(BUDGET_STATUS.SLIGHTLY_AHEAD),
    },
    [BUDGET_STATUS.SLIGHTLY_BEHIND]: {
      tone: 'notice',
      children: t(BUDGET_STATUS.SLIGHTLY_BEHIND),
    },
    [BUDGET_STATUS.ON_TRACK]: {
      tone: 'positive',
      children: t(BUDGET_STATUS.ON_TRACK),
    },
  } as const;

  return <Message ref={ref} {...otherProps} {...messageOptions[status]} />;
});
