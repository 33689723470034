import { IWorkLeaveType } from 'interfaces/account-interfaces';
import apiClient from 'utilities/api-client';

export type FetchWorkTypeRequest = {
  workTypeCategory?: IWorkLeaveType['category'];
  state?: IWorkLeaveType['state'];
};

export type FetchWorkTypesResponse = {
  status: number;
  data: IWorkLeaveType[];
};

export type CreateWorkTypeRequest = {
  workTypeExternalId?: IWorkLeaveType['workTypeExternalId'] | undefined;
  workTypeCategory: IWorkLeaveType['category'];
} & Pick<IWorkLeaveType, 'name'>;

export type CreateWorkTypeResponse = { status: number; data: IWorkLeaveType };

export type UpdateWorkTypeRequest = {
  name?: IWorkLeaveType['name'] | undefined;
  workTypeExternalId?: IWorkLeaveType['workTypeExternalId'] | undefined;
} & Pick<IWorkLeaveType, 'workTypeId'>;

export type UpdateWorkTypeResponse = { status: number; data: { count: number } };

export type ArchiveWorkTypeRequest = Pick<IWorkLeaveType, 'workTypeId'>;

export type ArchiveWorkTypeResponse = { status: number; data: { count: number } };

export type RestoreWorkTypeRequest = Pick<IWorkLeaveType, 'workTypeId'>;

export type RestoreWorkTypeResponse = { status: number; data: { count: number } };

export async function fetchWorkTypes(request?: FetchWorkTypeRequest): Promise<FetchWorkTypesResponse> {
  return await apiClient.post(`/api/portal/account/work-types/list`, request);
}

export async function createWorkType(request: CreateWorkTypeRequest): Promise<CreateWorkTypeResponse> {
  return await apiClient.post(`/api/portal/account/work-types`, {
    name: request.name,
    workTypeExternalId: request.workTypeExternalId,
    workTypeCategory: request.workTypeCategory,
  });
}

export async function updateWorkType(request: UpdateWorkTypeRequest): Promise<UpdateWorkTypeResponse> {
  return await apiClient.put(`/api/portal/account/work-types/${request.workTypeId}`, {
    name: request.name,
    workTypeExternalId: request.workTypeExternalId,
  });
}

export async function archiveWorkType(request: ArchiveWorkTypeRequest): Promise<ArchiveWorkTypeResponse> {
  return await apiClient.delete(`/api/portal/account/work-types/${request.workTypeId}`);
}

export async function restoreWorkType(request: RestoreWorkTypeRequest): Promise<RestoreWorkTypeResponse> {
  return await apiClient.put(`/api/portal/account/work-types/${request.workTypeId}/restore`);
}
