import { Empty, notification, Row, Input } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FilterSection } from 'common-components/filter';
import { FilterType, NoteVisibleType } from 'utilities/enum-utils';
import _ from 'lodash';
import AddEditNoteModal from 'common-components/notes/AddEditNoteModal';
import DeleteNoteModal from 'common-components/notes/DeleteNoteModal';
import ExportNoteModal from 'common-components/notes/ExportNoteModal';
import { NoteCardItem } from 'common-components/notes/note-card-item';
import { Text, Title } from 'common-components/typography';
import * as H from 'history';
import { ICustomer, ICustomerNotes } from 'interfaces/customer-interfaces';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import EditValueFormModal from 'views/form-builder/components/EditValueFormModal';
import { IElementValue } from 'views/form-builder/shared/form-interface';
import AddIncidentNoteModal from 'views/workflows/trigger/AddIncidentNoteModal';
import CannotEditOrDeleteNoteModal from './CannotEditOrDeleteNoteModal';
import moment from 'moment-timezone';
import { InfiniteScroll } from 'components';

const { Search } = Input;

interface INotesPanelProps {
  customerNotes: ICustomerNotes[];
  customerNotesFilters: typeof state.customersStore.customerNotesFilters;
  portalUser: typeof state.authStore.portalUser;
  selectedCustomerUserId: string;
  selectedCustomer: ICustomer;
  customerNotePage: number;
  customerNotePageSize: number;
  customerNotePageTimeStamp: Date;
  dofetchCustomerNotes: typeof dispatch.customersStore.dofetchCustomerNotes;
  setCustomerNotesFilters: typeof dispatch.customersStore.setCustomerNotesFilters;
  dofetchMoreCustomerNotes: typeof dispatch.customersStore.dofetchMoreCustomerNotes;
  setCustomerNotesPageInfo: typeof dispatch.customersStore.setCustomerNotesPageInfo;
  resetCustomerNotesPageInfo: typeof dispatch.customersStore.resetCustomerNotesPageInfo;
  doFetchBooking: typeof dispatch.bookingsStore.doFetchSingleBooking;
  selectedBookingItem: any;
  history: H.History;
  workflowForm: typeof state.workflowStore.workflowForm;
  doGetWorkflowFormDetail: typeof dispatch.workflowStore.doGetWorkflowFormDetail;
  doUpdateWorkflowForm: typeof dispatch.workflowStore.doUpdateWorkflowForm;
}
interface INotesPanelState {
  editingNoteBookingId: string | null;
  displayNoteModal: boolean;
  isEditNote: boolean;
  editingNote: any;
  editingNoteType: string;
  isDeleteNoteModalOpen: boolean;
  deletingNote: any;
  isExportNoteModalOpen: boolean;
  isCannotEditModalOpen: boolean;
  isEditingNote: boolean;
  noteServiceId: string;
  noteServiceDateTimeId: string;
  isSearching: boolean;
  displayIncidentNodeModal: boolean;
  customerData: object;
  isOpenFormModal: boolean;
}

const availableFilters = [
  FilterType.NOTE_AUTHOR,
  FilterType.DATE_RANGE,
  FilterType.NOTE_TYPE,
  FilterType.IS_INCIDENT,
  FilterType.NOTE_SERVICE,
];

const NoteEmptyPanel = () => (
  <div className='align-center flex-column flex-1 bg-white'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No notes found.
    </Text>
    <Text color='secondary'>All notes under this filter will appear here.</Text>
  </div>
);

class CustomerDetailsNotesPanel extends Component<INotesPanelProps, INotesPanelState> {
  state = {
    editingNoteBookingId: null,
    displayNoteModal: false,
    isEditNote: false,
    editingNote: null,
    editingNoteType: 'GENERAL',
    isDeleteNoteModalOpen: false,
    deletingNote: null,
    isExportNoteModalOpen: false,
    isCannotEditModalOpen: false,
    isEditingNote: false,
    noteServiceId: null,
    noteServiceDateTimeId: null,
    displayIncidentNodeModal: false,
    customerData: {},
    isOpenFormModal: false,
    isSearching: false,
  };

  private _onPressEditNote = (note) => {
    if (!note.serviceDateTimeId) {
      return this._onEditNote(note);
    }

    this.setState({
      isCannotEditModalOpen: true,
      isEditingNote: true,
      noteServiceId: note.serviceId,
      noteServiceDateTimeId: note.serviceDateTimeId,
    });
  };

  private _onPressDeleteNote = async (note) => {
    if (note.attendanceId || (!note.attendanceId && !note.serviceDateTimeId)) {
      this.setState({ isDeleteNoteModalOpen: true, deletingNote: note });
    } else {
      this.setState({
        isCannotEditModalOpen: true,
        isEditingNote: false,
        noteServiceId: note.serviceId,
        noteServiceDateTimeId: note.serviceDateTimeId,
      });
    }
  };

  private _onPressCloseDeleteNoteModal = () => {
    this.setState({ isDeleteNoteModalOpen: false });
  };

  private _onEditNote = (note) => {
    this.setState({
      displayNoteModal: true,
      editingNoteBookingId: note.attendanceId,
      isEditNote: true,
      editingNote: note,
      editingNoteType: note.attendanceId ? 'BOOKING' : 'GENERAL',
    });
  };

  private _onChangeFilter = async (filters: Array<any>, doNotUpdateFilterList: boolean = false) => {
    if (!doNotUpdateFilterList) {
      const { resetCustomerNotesPageInfo, dofetchCustomerNotes, setCustomerNotesFilters } = this.props;
      await resetCustomerNotesPageInfo({});
      await setCustomerNotesFilters(filters);
      await dofetchCustomerNotes({
        customerUserId: this.props.selectedCustomerUserId,
      });
    }
  };

  private _onAddNote = () => {
    this.setState({
      displayNoteModal: true,
      editingNoteBookingId: null,
      isEditNote: false,
      editingNote: null,
      editingNoteType: 'GENERAL',
    });
  };

  private _onClose = () => {
    this.setState({
      editingNoteBookingId: null,
      displayNoteModal: false,
      isEditNote: false,
      editingNote: null,
      // editingNoteType: 'GENERAL',
    });
  };

  private _onCloseCannotEditModal = () => {
    this.setState({
      isCannotEditModalOpen: false,
      isEditingNote: false,
    });
  };

  private _onGoToSession = () => {
    const { history } = this.props;
    const { noteServiceDateTimeId, noteServiceId } = this.state;
    this.setState({
      isCannotEditModalOpen: false,
    });
    history.push(`/group-service/${noteServiceId}/session/details/${noteServiceDateTimeId}`);
  };

  private _fetchMoreCustomerNotes = async () => {
    const {
      dofetchMoreCustomerNotes,
      setCustomerNotesPageInfo,
      customerNotePage,
      customerNotePageSize,
      customerNotePageTimeStamp,
    } = this.props;

    setCustomerNotesPageInfo({
      customerNotePage: customerNotePage + 1,
      customerNotePageSize,
      customerNotePageTimeStamp,
    });

    await dofetchMoreCustomerNotes({
      customerUserId: this.props.selectedCustomerUserId,
    });
  };

  private _onPressAddIncidentNote = () => {
    this.setState({ displayIncidentNodeModal: true });
  };

  private _onPressCloseIncidentNote = () => {
    this.setState({ displayIncidentNodeModal: false });
  };

  private _showFormModal = async (noteItem) => {
    const { doGetWorkflowFormDetail } = this.props;
    await doGetWorkflowFormDetail({ workflowFormId: noteItem.workflowFormId, isFromNote: true });
    this.setState({ isOpenFormModal: true });
  };

  private _closeFormModal = () => {
    this.setState({ isOpenFormModal: false });
  };

  private _onSaveFormModal = async (id: string, elementValues: IElementValue[]) => {
    const { doUpdateWorkflowForm } = this.props;
    await doUpdateWorkflowForm({ workflowFormId: id, isFromNote: true, formData: elementValues });
    this.setState({ isOpenFormModal: false });
    notification.success({
      message: 'Update workflow form success',
      description: 'You have successfully update workflow form',
    });
  };

  private _searchText = async (query: string) => {
    const {
      dofetchCustomerNotes,
      setCustomerNotesFilters,
      customerNotesFilters,
      customerNotePageSize,
      customerNotePageTimeStamp,
      setCustomerNotesPageInfo,
    } = this.props;
    const filterSearchIndex = customerNotesFilters.findIndex(({ search }) => search);
    filterSearchIndex > -1
      ? (customerNotesFilters[filterSearchIndex] = { search: query })
      : customerNotesFilters.push({ search: query });
    setCustomerNotesFilters([...customerNotesFilters]);
    setCustomerNotesPageInfo({
      customerNotePage: 1,
      customerNotePageSize,
      customerNotePageTimeStamp,
    });
    await dofetchCustomerNotes({
      customerUserId: this.props.selectedCustomerUserId,
    });

    this.setState({ isSearching: false });
  };
  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    this._debounceSearch(e.target.value);
  };

  componentDidMount = async () => {
    const { resetCustomerNotesPageInfo } = this.props;
    resetCustomerNotesPageInfo({});
    await this.props.setCustomerNotesFilters([
      { filter: FilterType.DATE_RANGE, values: [], selectionLabel: 'All' },
      { filter: FilterType.IS_INCIDENT, values: [], selectionLabel: 'Both' },
      { filter: FilterType.NOTE_TYPE, values: [], selectionLabel: 'All' },
    ]);
    await this.props.dofetchCustomerNotes({
      customerUserId: this.props.selectedCustomerUserId,
    });
  };

  componentWillUnmount = async () => {
    const { setCustomerNotesPageInfo, customerNotePageSize, customerNotePageTimeStamp } = this.props;
    setCustomerNotesPageInfo({
      customerNotePage: 1,
      customerNotePageSize,
      customerNotePageTimeStamp,
    });
  };

  render() {
    const { isOpenFormModal } = this.state;
    const { selectedCustomer, workflowForm } = this.props;
    const noteVisibleOptions = [
      NoteVisibleType.PORTAL,
      NoteVisibleType.PORTAL_AND_APP,
      NoteVisibleType.PRIVATE_PORTAL_ONLY,
    ];
    return (
      <div>
        <AddEditNoteModal
          bookingId={this.state.editingNoteBookingId}
          isOpen={this.state.displayNoteModal}
          customerUserId={this.props.selectedCustomerUserId}
          onClose={this._onClose}
          editNote={this.state.isEditNote}
          editingNote={this.state.editingNote}
          noteType={this.state.editingNoteType}
          handleSubmitIncidentNote={(noteData) => {
            const customerData = {
              involved: {
                customers:
                  selectedCustomer && selectedCustomer.userId
                    ? [
                        {
                          customerId: selectedCustomer.userId,
                          displayName: `${selectedCustomer.firstName} ${selectedCustomer.lastName}`,
                          avatar: selectedCustomer.attachmentUrl,
                        },
                      ]
                    : [],
                workers: [],
              },
              noteData: noteData.note,
              ...noteData,
            };
            this.setState({ ...this.state, customerData });
            this._onPressAddIncidentNote();
          }}
          noteVisibleOptions={noteVisibleOptions}
        />
        <AddIncidentNoteModal
          isOpen={this.state.displayIncidentNodeModal}
          onClose={this._onPressCloseIncidentNote}
          isManual={false}
          moduleType={this.state.editingNoteType}
          screenData={this.state.customerData}
          timezone={moment.tz.guess()}
        />
        <DeleteNoteModal
          noteItem={this.state.deletingNote}
          isOpen={this.state.isDeleteNoteModalOpen}
          doCloseDeleteNoteModal={this._onPressCloseDeleteNoteModal}
          isCustomerDetailsScreen={true}
          customerUserId={this.props.selectedCustomerUserId}
        />
        <CannotEditOrDeleteNoteModal
          isOpen={this.state.isCannotEditModalOpen}
          isEditingNote={this.state.isEditingNote}
          onClose={this._onCloseCannotEditModal}
          onGoToSession={this._onGoToSession}
        />
        <ExportNoteModal
          isOpen={this.state.isExportNoteModalOpen}
          onClose={() => this.setState({ isExportNoteModalOpen: false })}
        />

        <div className='align-center flex-row justify-start'>
          <div>
            <Search
              placeholder='Search for...'
              onChange={this._onEnterSearchText}
              loading={this.state.isSearching}
              style={{ width: '250px', height: '32px' }}
              allowClear
            />
          </div>

          <div className='width-full flex justify-end'>
            <SecondaryButton
              color={'blue-action'}
              className={'mr-medium'}
              onClick={() => this.setState({ isExportNoteModalOpen: true })}
            >
              Export Notes
            </SecondaryButton>
            <PrimaryButton color={'blue-action'} icon={'plus'} onClick={() => this._onAddNote()}>
              Add Note
            </PrimaryButton>
          </div>
        </div>

        <div>
          <FilterSection
            availableFilters={availableFilters}
            filters={this.props.customerNotesFilters}
            onChangeFilter={this._onChangeFilter}
            displayTimezone={this.props.portalUser.timezone}
          />
        </div>

        {!_.isEmpty(this.props.customerNotes) ? (
          <div className='mt-large'>
            <InfiniteScroll
              hasMore={this.props.customerNotes.length >= this.props.customerNotePage * this.props.customerNotePageSize}
              loadMore={this._fetchMoreCustomerNotes}
            >
              {this.props.customerNotes.map((note) => {
                const isBookingNote = Boolean(note.attendanceId || note.serviceDateTimeId);
                const noteType = isBookingNote ? 'BOOKING' : 'GENERAL';
                const onPressEditNote = isBookingNote
                  ? () => this._onEditNote(note)
                  : () => this._onPressEditNote(note);

                // If the note is general, then this is a deletable note.
                const isNoteDeletable =
                  note.visibleType === NoteVisibleType.PRIVATE_PORTAL_ONLY ||
                  !isBookingNote ||
                  this.props.customerNotes.some((otherNote) => {
                    // Otherwise, this is only a deletable note if another note matches this one's attendance ID or booking ID.
                    if (otherNote.noteId === note.noteId) {
                      // This isn't a different note if they share a note ID.
                      return false;
                    }

                    return (
                      otherNote.attendanceId === note.attendanceId ||
                      otherNote.serviceDateTimeId === note.serviceDateTimeId
                    );
                  });

                return (
                  <NoteCardItem
                    noteItem={note}
                    key={note.noteId}
                    noteType={noteType}
                    serviceName={note.serviceName}
                    onPressEditNote={onPressEditNote}
                    bookingId={note.attendanceId}
                    onPressDeleteNote={() => this._onPressDeleteNote(note)}
                    timezone={this.props.selectedCustomer.timezone}
                    history={this.props.history}
                    showFormModal={this._showFormModal}
                    selectedCustomerUserId={this.props.selectedCustomerUserId}
                    isNoteDeletable={isNoteDeletable}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
        ) : (
          <Row>
            <NoteEmptyPanel />
          </Row>
        )}
        {isOpenFormModal && (
          <EditValueFormModal
            isOpen={isOpenFormModal}
            mode='VIEW'
            formId={workflowForm.workflowFormId}
            formElements={workflowForm.formContent}
            elementValues={workflowForm.formData}
            canEditForm={workflowForm.canEditForm}
            onSave={this._onSaveFormModal}
            onClose={this._closeFormModal}
          />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  customerNotes: state.customersStore.customerNotes,
  portalUser: state.authStore.portalUser,
  customerNotesFilters: state.customersStore.customerNotesFilters,
  selectedCustomerUserId: state.customersStore.selectedCustomerUserId,
  selectedCustomer: state.customersStore.selectedCustomer,
  customerNotePage: state.customersStore.customerNotePage,
  customerNotePageSize: state.customersStore.customerNotePageSize,
  customerNotePageTimeStamp: state.customersStore.customerNotePageTimeStamp,
  selectedBookingItem: state.bookingsStore.selectedBookingItem,
  workflowForm: state.workflowStore.workflowForm,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  dofetchCustomerNotes: dispatch.customersStore.dofetchCustomerNotes,
  setCustomerNotesFilters: dispatch.customersStore.setCustomerNotesFilters,
  setCustomerNotesPageInfo: dispatch.customersStore.setCustomerNotesPageInfo,
  resetCustomerNotesPageInfo: dispatch.customersStore.resetCustomerNotesPageInfo,
  dofetchMoreCustomerNotes: dispatch.customersStore.dofetchMoreCustomerNotes,
  doFetchBooking: dispatch.bookingsStore.doFetchSingleBooking,
  doGetWorkflowFormDetail: dispatch.workflowStore.doGetWorkflowFormDetail,
  doUpdateWorkflowForm: dispatch.workflowStore.doUpdateWorkflowForm,
});

export default connect(mapState, mapDispatch)(CustomerDetailsNotesPanel);
