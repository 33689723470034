import React from 'react';
import { Icon } from '../icon';

import type { IconProps } from '../icon';

export function ChevronDown(props: IconProps) {
  return (
    <Icon {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={15}
        d="M56 88 l 72 80 l 72 -80"
      />
    </Icon>
  );
}

export function ChevronUp(props: IconProps) {
  return (
    <Icon {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="m48 160 80-80 80 80"
      />
    </Icon>
  );
}

export function ChevronLeft(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.68656 3.64645C9.88182 3.84171 9.88182 4.15829 9.68656 4.35355L6.04011 8L9.68656 11.6464C9.88182 11.8417 9.88182 12.1583 9.68656 12.3536C9.4913 12.5488 9.17472 12.5488 8.97945 12.3536L4.97945 8.35355C4.78419 8.15829 4.78419 7.84171 4.97945 7.64645L8.97945 3.64645C9.17472 3.45118 9.4913 3.45118 9.68656 3.64645Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export function ChevronRight(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31344 3.64645C6.5087 3.45118 6.82528 3.45118 7.02055 3.64645L11.0205 7.64645C11.2158 7.84171 11.2158 8.15829 11.0205 8.35355L7.02055 12.3536C6.82528 12.5488 6.5087 12.5488 6.31344 12.3536C6.11818 12.1583 6.11818 11.8417 6.31344 11.6464L9.95989 8L6.31344 4.35355C6.11818 4.15829 6.11818 3.84171 6.31344 3.64645Z"
        fill="currentColor"
      />
    </Icon>
  );
}
