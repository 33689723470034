import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const SliderVertical = forwardRef(function SliderVertical(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.948 3.25H14.052c.899 0 1.648 0 2.242.08.628.084 1.195.27 1.65.725.456.456.642 1.023.726 1.65.08.595.08 1.344.08 2.243v8.104c0 .899 0 1.648-.08 2.242-.084.628-.27 1.195-.725 1.65-.456.456-1.023.642-1.65.726-.595.08-1.345.08-2.243.08H9.948c-.898 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.726-.456-.455-.642-1.022-.726-1.65-.08-.594-.08-1.343-.08-2.242V7.948c0-.898 0-1.648.08-2.242.084-.628.27-1.195.726-1.65.455-.456 1.022-.642 1.65-.726.594-.08 1.344-.08 2.242-.08ZM7.905 4.817c-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788C6.753 6.388 6.75 7.036 6.75 8v8c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.788.3.483.064 1.131.066 2.095.066h4c.964 0 1.612-.002 2.095-.067.461-.062.659-.169.789-.3.13-.13.238-.327.3-.788.064-.483.066-1.131.066-2.095V8c0-.964-.002-1.612-.067-2.095-.061-.461-.169-.659-.3-.789-.13-.13-.327-.237-.788-.3-.483-.064-1.13-.066-2.095-.066h-4c-.964 0-1.612.002-2.095.067ZM2 4.25a.75.75 0 0 1 .75.75v14a.75.75 0 0 1-1.5 0V5A.75.75 0 0 1 2 4.25ZM22.75 5a.75.75 0 0 0-1.5 0v14a.75.75 0 1 0 1.5 0V5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
