import { Icon, Input, Upload } from 'antd';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import React, { Component } from 'react';
import { IWorkflowTemplateStep } from 'src/interfaces/workflow-interfaces';
import { FILE_EXTENSION_INVALID_ERROR_MESSAGE } from '../../utils/constants';
import { validFileExtension } from '../../utils/workflow-utils';

interface Props {
  isOpen: boolean;
  selectedStep: IWorkflowTemplateStep;
  onUpdateStep(step: IWorkflowTemplateStep): void;
  onClose: () => void;
}

interface State {
  attachmentFile: any;
  description: string;
  errorMessage: string;
  isEdit: boolean;
}

class WorkflowTemplateAddEditAttachmentModal extends Component<Props, State> {
  state = {
    attachmentFile: null,
    description: '',
    errorMessage: null,
    isEdit: false,
  };

  private _onCloseModel = () => {
    this.setState({
      attachmentFile: null,
      description: '',
      errorMessage: null,
    });
    this.props.onClose();
  };

  private _validateFile = (file) => {
    const isValidType = validFileExtension(file.name);

    this.setState({ errorMessage: null });
    if (!isValidType) {
      this.setState({
        errorMessage: FILE_EXTENSION_INVALID_ERROR_MESSAGE,
        attachmentFile: null,
      });
      return;
    } else {
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        this.setState({ errorMessage: 'File must be smaller than 25MB!' });
        return;
      } else {
        this.setState({ attachmentFile: file });
        return false;
      }
    }
  };

  private _onAddAttachment = () => {
    const { selectedStep, onUpdateStep, onClose } = this.props;

    if (this.state.attachmentFile) {
      const attachment = {
        file: this.state.attachmentFile,
        name: this.state.attachmentFile.name,
        description: this.state.description.trim(),
      };

      onUpdateStep({ ...selectedStep, attachment });
    }

    onClose();
  };

  private _onChangeDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  componentDidMount() {
    const { selectedStep } = this.props;

    if (selectedStep && selectedStep.attachment) {
      this.setState({
        attachmentFile: selectedStep.attachment,
        description:
          selectedStep.attachment && selectedStep.attachment.description ? selectedStep.attachment.description : '',
        isEdit: true,
      });
    }
  }

  render() {
    const { isOpen } = this.props;
    const { isEdit } = this.state;

    return (
      <ActionModal
        isOpen={isOpen}
        title={`${isEdit ? 'Edit' : 'Add'} external form/attachment`}
        width="medium"
        onClose={this._onCloseModel}
        verticalAlignment="highest"
      >
        <div>
          <FieldLabel text={'ATTACHMENT'} />
        </div>
        <div>
          <div className={'mt-small'}>
            {!this.state.attachmentFile && <Text type={'secondary'}>No attachment</Text>}
            {this.state.attachmentFile && <Text>{this.state.attachmentFile.name}</Text>}
          </div>
          <div className={'mt-small'}>
            <Upload multiple={false} beforeUpload={this._validateFile} showUploadList={false}>
              {!this.state.attachmentFile && (
                <SecondaryButton icon={'upload'} iconPosition={'left'}>
                  Select file...
                </SecondaryButton>
              )}
              {this.state.attachmentFile && (
                <HyperlinkButton>
                  <Icon type={'edit'} /> Select different file...
                </HyperlinkButton>
              )}
            </Upload>
          </div>
        </div>
        {this.state.errorMessage && (
          <div className={'mt-medium'}>
            <Text color={'red'}>{this.state.errorMessage}</Text>
          </div>
        )}

        <div className={'mt-medium'}>
          <FieldLabel text={'DESCRIPTION (OPTIONAL)'} />
          <Input
            maxLength={1000}
            placeholder={'Attachment description here...'}
            onChange={this._onChangeDescription}
            value={this.state.description}
            className={'mt-small'}
          />
        </div>

        <ActionModalFooter className={'mt-x-large'}>
          <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            size="large"
            onClick={this._onAddAttachment}
            disabled={!this.state.attachmentFile || this.state.errorMessage}
          >
            {isEdit ? 'Save' : 'Add attachment'}
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default WorkflowTemplateAddEditAttachmentModal;
