import React, { PureComponent } from 'react';
import _ from 'lodash';
import defaultFields from 'variables/data/defaultBookingListingFields.json';
import { SubTitle } from 'common-components/typography';

export class HeaderFieldItem extends PureComponent<{
  customFields: any;
  topHeight: number;
}> {
  render() {
    const { customFields, topHeight } = this.props;

    const headers = _.isEmpty(customFields) ? defaultFields : customFields;

    return (
      <>
        {!_.isEmpty(headers) &&
          _.map(headers, (header, index) => (
            <React.Fragment key={index}>
              {header.field === 'BOOKING_DATE' && (
                <th
                  key={`header-field-item-booking-date-${header.header.replace(' ', '-')}-${index}`}
                  style={{ top: `${topHeight}px`, width: '16px' }}
                  className="nowrap"
                />
              )}
              <th
                key={`header-field-item-${header.header.replace(' ', '-')}-${index}`}
                style={{ top: `${topHeight}px` }}
                className="nowrap"
              >
                <SubTitle>{header.header}</SubTitle>
              </th>
            </React.Fragment>
          ))}
      </>
    );
  }
}
