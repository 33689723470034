import React from 'react';

import { useSessionTimes } from './hooks/use-session-times';
import { parseInitialRatio } from './hooks/parse-initial-ratio';
import { useRatioFromGroupBooking } from './hooks/use-ratio-from-group-booking';
import { useRatioFromServiceAgreement } from './hooks/use-ratio-from-service-agreement';
import { CustomerRatios } from './customer-ratios';
import { IEditRatiosProps } from './types';

import './style.css';

export const EditRatios = ({
  subscribe,
  sessionTimes: sessionTimesCustom,
  showConfirmButton,
  type,
  groupBookingId,
  initialRatio,
  serviceAgreementPayload,
}: IEditRatiosProps) => {
  const sessionTimes = useSessionTimes(type, sessionTimesCustom);
  const ratioFromInitialRatio = parseInitialRatio(initialRatio);
  const ratioFromServiceAgreement = useRatioFromServiceAgreement(
    sessionTimes,
    serviceAgreementPayload?.serviceId,
    serviceAgreementPayload?.customerUserId,
    serviceAgreementPayload?.serviceDateTimeIds,
  );
  const ratioFromGroupBooking = useRatioFromGroupBooking(sessionTimes, groupBookingId);

  const ratio = type === 'create' ? ratioFromInitialRatio ?? ratioFromServiceAgreement : ratioFromGroupBooking;

  return (
    <CustomerRatios
      subscribe={subscribe}
      sessionTimes={sessionTimes}
      showConfirmButton={showConfirmButton}
      initialRatios={ratio}
      type={type}
    />
  );
};
