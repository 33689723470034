import { Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import ActionModal from 'common-components/modal/ActionModal';
import {
  IGroupServiceActivityGroup,
  IGroupServiceSession,
  IGroupServiceScheduleTimeSlot,
  IGroupServiceTimeSlot,
} from 'interfaces/service-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { IActivityGroupUsers } from 'interfaces/user-interfaces';
import { ActivityGroupMemberType, ActivityGroupModalType } from 'utilities/enum-utils';
import AddMembersModalContent from './components/AddMembersModalContent';
import AddMembersToEmptyGroupModalContent from './components/AddMembersToEmptyGroupModalContent';
import AssignMemberToActivityGroupModalContent from './components/AssignMemberToActivityGroupModalContent';
import CreateModalContent from './components/CreateModalContent';
import RemoveModalContent from './components/RemoveModalContent';
import DuplicateModalContent from './components/DuplicateModalContent';
import EditModalContent from './components/EditModalContent';
import DeleteModalContent from './components/DeleteModalContent';
import AssignToSessionModalContent from './components/AssignToSessionModalContent';
import AssignToScheduleModalContent from './components/AssignToScheduleModalContent';
import { timeZone } from 'interfaces/timezone-type';

interface IActivityGroupsModalProps extends FormComponentProps {
  serviceId: string;
  activityGroup: IGroupServiceActivityGroup;
  selectedUser: IActivityGroupUsers;
  member: IActivityGroupUsers;
  memberType: ActivityGroupMemberType;
  isOpen: boolean;
  modalType: ActivityGroupModalType;
  ignoredAlert?: boolean;
  handleAction: (payload, actionType: ActivityGroupModalType) => Promise<boolean | void>;
  onCloseViewModal: () => void;
  session?: IGroupServiceSession;
  timeSlot?: IGroupServiceScheduleTimeSlot;
  timeZone?: timeZone;
  isEditAddTeamMemberToSessions?: boolean;
  selectedTimeSlot?: IGroupServiceTimeSlot;
}

interface IActivityGroupsModalState {
  isLoading: boolean;
}

const INITIAL_STATE: IActivityGroupsModalState = {
  isLoading: false,
};

class ActivityGroupsModal extends Component<IActivityGroupsModalProps, IActivityGroupsModalState> {
  state = _.cloneDeep(INITIAL_STATE);

  private _generateModalTitle = (modalType: ActivityGroupModalType, memberType: ActivityGroupMemberType): string => {
    let title = '';

    switch (modalType) {
      case ActivityGroupModalType.DUPLICATE:
        title = 'Duplicate activity group';
        break;
      case ActivityGroupModalType.DELETE:
        title = 'Delete activity group';
        break;
      case ActivityGroupModalType.REMOVE:
        title = `Remove ${ActivityGroupMemberType.CUSTOMER === memberType ? 'customer' : 'team member'}`;
        break;
      case ActivityGroupModalType.REMOVE_ALL:
        title = `Remove all ${ActivityGroupMemberType.CUSTOMER === memberType ? 'customers' : 'team members'}`;
        break;
      case ActivityGroupModalType.ADD_CUSTOMER:
        title = 'Add new customer';
        break;
      case ActivityGroupModalType.ADD_TEAM_MEMBER:
        title = 'Add new team members';
        break;
      case ActivityGroupModalType.ADD_BOTH:
        title = 'Add people to activity group';
        break;
      case ActivityGroupModalType.EDIT:
        title = 'Edit activity group';
        break;
      case ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP:
        title = 'Assign customer to activity group';
        break;
      case ActivityGroupModalType.ASSIGN_TEAM_MEMBER_TO_ACTIVITY_GROUP:
        title = 'Assign team member to activity group';
        break;
      case ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SESSION:
        title = 'Edit Session';
        break;
      case ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SCHEDULE:
        title = 'Edit Schedule';
        break;
      default:
        title = 'Create activity group';
        break;
    }
    return title;
  };

  private _renderModalBody = (modalType: ActivityGroupModalType): JSX.Element => {
    switch (modalType) {
      case ActivityGroupModalType.DUPLICATE:
        return <DuplicateModalContent {...this.props} />;
      case ActivityGroupModalType.REMOVE:
      case ActivityGroupModalType.REMOVE_ALL:
        return <RemoveModalContent {...this.props} />;
      case ActivityGroupModalType.DELETE:
        return <DeleteModalContent {...this.props} />;
      case ActivityGroupModalType.ADD_CUSTOMER:
      case ActivityGroupModalType.ADD_TEAM_MEMBER:
        return <AddMembersModalContent {...this.props} />;
      case ActivityGroupModalType.ADD_BOTH:
        return <AddMembersToEmptyGroupModalContent {...this.props} />;
      case ActivityGroupModalType.EDIT:
        return <EditModalContent {...this.props} />;
      case ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP:
      case ActivityGroupModalType.ASSIGN_TEAM_MEMBER_TO_ACTIVITY_GROUP:
        return <AssignMemberToActivityGroupModalContent {...this.props} />;
      case ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SESSION:
        return <AssignToSessionModalContent {...this.props} />;
      case ActivityGroupModalType.ASSIGN_ACTIVITY_GROUP_TO_SCHEDULE:
        return <AssignToScheduleModalContent {...this.props} />;

      default:
        return <CreateModalContent {...this.props} />;
    }
  };

  render() {
    const { isOpen, modalType, memberType, onCloseViewModal } = this.props;

    return (
      <ActionModal
        isOpen={isOpen}
        title={this._generateModalTitle(modalType, memberType)}
        showCloseButton={true}
        onClose={onCloseViewModal}
        width={'large'}
      >
        <div className="anim-slide-left">{this._renderModalBody(modalType)}</div>
      </ActionModal>
    );
  }
}

export default Form.create<IActivityGroupsModalProps>()(ActivityGroupsModal);
