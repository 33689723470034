import { Address } from './types';

export function processFullAddress(address: Address): Address {
  const addressElements = [
    address.streetAddress2,
    address.streetAddress1,
    address.locality,
    address.state,
    address.postcode,
  ].filter((element) => {
    if (!element) {
      return false;
    }

    if (typeof element === 'string' && element.trim() === '') {
      return false;
    }

    return true;
  });

  return {
    ...address,
    fullAddress: addressElements.join(', ').trim(),
  };
}
