import React, { Component } from 'react';
import { Icon, Tooltip } from 'antd';

import _ from 'lodash';
import moment from 'moment-timezone';

import { Popover } from '@blueprintjs/core';
import { connect } from 'react-redux';

import { Text } from 'common-components/typography';
import SessionStatusTag from 'common-components/tags/SessionStatusTag';
import { HyperlinkButton, IconButton, SecondaryButton } from 'common-components/buttons';

import { BookingsForSessionPanel } from 'views/group-services/session-listings/list-view/components/BookingsForSessionPanel';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { GroupServiceSessionStatus, SessionStatus, StaffingSessionStatus } from 'utilities/enum-utils';

import { IGroupServiceSession } from 'interfaces/service-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { timeZone } from 'interfaces/timezone-type';
import TextTag from 'common-components/tags/TextTag';
import TooltipStaffingStatus from 'views/group-services/session-listings/list-view/components/TooltipStaffingStatus';

interface ISessionRowProps {
  session: IGroupServiceSession;
  onNavigate: any;
  doFetchBookingsForSession: any;
  sessionBookings: typeof state.groupServiceStore.sessionBookings;
  timezone: timeZone;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
}

interface ISessionRowState {
  showBookings: boolean;
  isFetching: boolean;
}

class SessionRow extends Component<ISessionRowProps, ISessionRowState> {
  state = { showBookings: false, isFetching: false };

  _navigateToService = () => {
    const { session, onNavigate } = this.props;
    const { serviceId } = session;

    onNavigate(`/group-service/details/${serviceId}`);
    this.props.setSelectedSideNavMenuKeys(['/services']);
  };

  _navigateToSession = () => {
    const { session, onNavigate } = this.props;
    const { serviceId, serviceDateTimeId } = session;

    onNavigate(`/group-service/${serviceId}/session/details/${serviceDateTimeId}`);
    this.props.setSelectedSideNavMenuKeys(['/services']);
  };

  _fetchBookings = async () => {
    const { doFetchBookingsForSession, session } = this.props;

    const { serviceId, serviceDateTimeId } = session;

    if (this.state.showBookings) {
      this.setState({ isFetching: true });
      await doFetchBookingsForSession({ serviceId, serviceDateTimeId });
      this.setState({ isFetching: false });
    }
  };

  _showBookings = () => {
    this.setState({ showBookings: !this.state.showBookings }, this._fetchBookings);
  };

  _formatStaffingStatus = (status: StaffingSessionStatus) => {
    let staffingStatus = '';
    switch (status) {
      case StaffingSessionStatus.MOSTLY_OVERSTAFFED:
        staffingStatus = 'Mostly overstaffed';
        break;
      case StaffingSessionStatus.VERY_OVERSTAFFED:
        staffingStatus = 'Very overstaffed';
        break;
      case StaffingSessionStatus.SLIGHTLY_OVERSTAFFED:
        staffingStatus = 'Slightly overstaffed';
        break;
      case StaffingSessionStatus.UNDERSTAFFED_AND_OVERSTAFFED:
        staffingStatus = 'Understaffed and Overstaffed';
        break;
      case StaffingSessionStatus.VERY_UNDERSTAFFED:
        staffingStatus = 'Very understaffed';
        break;
      case StaffingSessionStatus.MOSTLY_UNDERSTAFFED:
        staffingStatus = 'Mostly understaffed';
        break;
      case StaffingSessionStatus.SLIGHTLY_UNDERSTAFFED:
        staffingStatus = 'Slightly understaffed';
        break;
      case StaffingSessionStatus.OPTIMALLY_STAFFED:
        staffingStatus = 'Optimally staffed';
        break;
      default:
        staffingStatus = 'Optimally staffed';
        break;
    }

    return staffingStatus;
  };

  render() {
    const { session, sessionBookings, onNavigate, timezone } = this.props;

    if (_.isEmpty(session)) {
      return <></>;
    }

    const { serviceDateTimeId } = session;

    // const bookings = sessionBookings.filter((bookings) => bookings.serviceDateTimeId === serviceDateTimeId);
    // const bookings = sessionBookings.filter((bookings) => bookings.serviceDateTimeId === serviceDateTimeId);

    const bookings = _.chain(sessionBookings)
      .filter((bookings) => bookings.serviceDateTimeId === serviceDateTimeId)
      .first()
      .value();

    const {
      serviceName,
      scheduleName,
      startDateTime,
      endDateTime,
      sessionStatus,
      bookedCapacity,
      capacity,
      totalAssignedShiftSlot,
      staffingStatus,
      isSingleStaffingStatus,
      totalTimeOfTimeSlotStaffingStatus,
    } = session;

    const timeDisplayLabel = `${moment.tz(startDateTime, timezone).format('h:mm A')} - ${moment
      .tz(endDateTime, timezone)
      .format('h:mm A')}`;

    let sidelineColor = 'blue-darker';
    let textColor = 'black';

    const isOverdue =
      moment.tz(startDateTime, timezone).isBefore(moment.tz(timezone)) && sessionStatus === SessionStatus.SCHEDULED;
    const isDueToStart =
      moment.tz(startDateTime, timezone).isBefore(moment.tz(timezone).add(15, 'minutes')) &&
      sessionStatus === SessionStatus.SCHEDULED;
    const isDueToFinish =
      moment.tz(endDateTime, timezone).isBefore(moment.tz(timezone).add(15, 'minutes')) &&
      moment.tz(endDateTime, timezone).isAfter(moment.tz(timezone)) &&
      sessionStatus === SessionStatus.INPROGRESS;

    if (isOverdue) {
      sidelineColor = 'bg-rose-lighter';
    } else {
      switch (sessionStatus) {
        case GroupServiceSessionStatus.SCHEDULED:
          sidelineColor = 'bg-green-lighter';
          break;
        case GroupServiceSessionStatus.INPROGRESS:
          sidelineColor = 'bg-orange-lighter';
          break;
        case GroupServiceSessionStatus.COMPLETED:
          sidelineColor = 'bg-blue-lighter';
          break;
        case GroupServiceSessionStatus.CLOSED:
          sidelineColor = 'bg-blue-darker';
          textColor = 'secondary';
          break;
        case GroupServiceSessionStatus.CANCELLED:
          sidelineColor = 'bg-red-light';
          textColor = 'secondary';
          break;
      }
    }

    return (
      <div>
        {/* Display row */}
        <div
          className={`bordered-top bordered-right flex-row align-center line-height-100 ${
            isOverdue ? 'bg-red-lightest' : isDueToStart || isDueToFinish ? 'bg-blue-action-lightest' : ''
          }`}
        >
          <div className={`${sidelineColor} flex-column`} style={{ width: '4px', alignSelf: 'stretch' }} />

          <div className="flex-1 p-medium">
            <HyperlinkButton lineHeight={100} onClick={this._navigateToSession} color={textColor}>
              {timeDisplayLabel}
            </HyperlinkButton>
          </div>
          <div className="flex-1 p-medium line-height-100">
            <HyperlinkButton lineHeight={120} onClick={this._navigateToService} color={textColor}>
              {serviceName} <br />
              <span className="text-size-regular line-height-120 text-weight-light text-color-black break-word">
                {scheduleName}
              </span>
            </HyperlinkButton>
            {/*<Text lineHeight={100}>{serviceName}</Text>*/}
          </div>
          <div className="flex-1 p-medium">
            <SessionStatusTag status={sessionStatus} className={'mv-x-small mr-small'} />{' '}
            {(isOverdue || isDueToStart || isDueToFinish) && (
              <TextTag
                className={'mr-small mv-x-small'}
                content={isOverdue ? 'Overdue' : isDueToStart ? 'Due to start soon' : 'Due to end soon'}
                color={isOverdue ? 'rose' : 'blue-action'}
                theme={'dark'}
                textSize={'x-large'}
                size={'small'}
                weight={'regular'}
              />
            )}
          </div>
          <div className="flex-1 p-medium">
            <div className="flex-column line-height-120">
              <Text lineHeight={120}>
                <b>{bookedCapacity}</b> <span className="text-weight-light">customers</span>
              </Text>
              <Text lineHeight={120} size="regular" color="secondary">
                {bookedCapacity}/{capacity} capacity
              </Text>
            </div>
          </div>
          <div className="flex-1 p-medium">
            <div className="flex-column line-height-120">
              {staffingStatus && (
                <Tooltip
                  placement="top"
                  title={
                    <TooltipStaffingStatus
                      totalTimeOfTimeSlotStaffingStatus={totalTimeOfTimeSlotStaffingStatus}
                      isSingleStaffingStatus={isSingleStaffingStatus}
                    />
                  }
                  className="flex align-center cursor-pointer width-fit-content mb-x-small"
                >
                  <Text size="large">{this._formatStaffingStatus(staffingStatus)}</Text>
                  <Icon type="question-circle" className="ml-x-small text-size-regular" />
                </Tooltip>
              )}
              <Text lineHeight={120}>
                <Text weight="bold">{totalAssignedShiftSlot}</Text>{' '}
                <Text weight="light">team {totalAssignedShiftSlot > 1 ? 'members' : 'member'}</Text>
              </Text>
            </div>
          </div>

          <div className="pv-12 pl-12 pr-large">
            <SecondaryButton
              icon={this.state.showBookings ? 'up' : 'down'}
              iconPosition="right"
              onClick={this._showBookings}
            >
              {this.state.showBookings ? 'Hide' : 'Show'} bookings
            </SecondaryButton>

            <Popover
              position="bottom-right"
              content={
                <ActionMenu>
                  <ActionMenuItem text="View session..." onClick={this._navigateToSession} />
                </ActionMenu>
              }
            >
              <IconButton
                icon={'ellipsis'}
                size="default"
                iconColor={'blue-action'}
                color={'white'}
                bordered={true}
                className="ml-medium border-blue-action"
              />
            </Popover>
          </div>
        </div>

        {/* Booking details */}
        <BookingsForSessionPanel
          session={session}
          isCustomersPanelOpen={this.state.showBookings}
          isFetching={this.state.isFetching}
          sessionBookings={bookings}
          onNavigate={onNavigate}
          timezone={timezone}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  sessionBookings: state.groupServiceStore.sessionBookings,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchBookingsForSession: dispatch.groupServiceStore.doFetchBookingsForSession,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
});

export default connect(mapState, mapDispatch)(SessionRow);
