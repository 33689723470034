import { CurrencyFormatter } from '@goodhuman-me/components';
import { Col, Row } from 'antd';
import { Title, Text, SubTitle } from 'common-components/typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { cloneDeep, isEmpty } from 'lodash';
import React, { createContext, useContext } from 'react';
import { FundingNdisSupportCategory } from 'interfaces/funding-interfaces';
import { ndisHelper } from 'variables/data-helpers';
interface ISupportTypeProps {
  supportTypeName: string;
  children: JSX.Element;
}

const paymentMethodLabel = {
  NDIA: 'NDIA-managed',
  PLAN: 'PLAN-managed',
  SELF: 'SELF-managed',
};

const SupportType = ({ supportTypeName, children }: ISupportTypeProps) => {
  const { getCategory, categoryExists } = useFundingCategoriesContext();

  const supportType = getCategory(supportTypeName);
  const isActivated = categoryExists({ supportType: supportTypeName });

  return (
    <div className="bordered mv-medium">
      <Row
        className={`pv-medium ph-medium width-full flex justify-between bg-tertiary ${
          isActivated ? 'bordered-bottom' : ''
        }`}
      >
        <Col span={12}>
          <Title level={4} className="mb-none" weight="regular">
            {supportTypeName}
          </Title>
        </Col>
        <Col span={12} className="text-align-right">
          {supportType && (
            <Title level={4} className="mb-none">
              Allocated funding: <CurrencyFormatter value={supportType.allocated}></CurrencyFormatter>
            </Title>
          )}
        </Col>
      </Row>
      {isActivated && <div className="pv-medium ph-medium">{children}</div>}
    </div>
  );
};

interface ISupportCategoryProps {
  supportCategory: FundingNdisSupportCategory;
  nameOverride?: string;
  children?: JSX.Element;
}

const SupportCategory = ({ supportCategory, nameOverride, children }: ISupportCategoryProps): JSX.Element => {
  const { getSupportLineItems } = useFundingCategoriesContext();
  const allLineItems = getSupportLineItems(supportCategory.supportType, supportCategory.supportCategoryNumber);

  return (
    <>
      <div className="pv-medium">
        <Row>
          <Col span={12}>
            <SubTitle>Support Category</SubTitle>
          </Col>
          <Col span={6}>
            <SubTitle>Budget</SubTitle>
          </Col>
          <Col span={6}>
            <SubTitle>Payment Method</SubTitle>
          </Col>
        </Row>
        <Row className="flex align-center">
          <Col span={12}>
            <Text>{nameOverride ?? supportCategory.supportCategoryName}</Text>
          </Col>
          <Col span={6}>
            <Text>
              <CurrencyFormatter value={supportCategory.funding}></CurrencyFormatter>
            </Text>
          </Col>
          <Col span={6}>
            <Text>{paymentMethodLabel[supportCategory.paymentMethod] ?? '-'}</Text>
          </Col>
        </Row>
      </div>
      {allLineItems.length > 0 && (
        <Row className="ph-small pb-small bordered-bottom">
          <Col span={10}>
            <SubTitle weight="normal">Support Item</SubTitle>
          </Col>
          <Col span={2}>
            <SubTitle weight="normal">Unit</SubTitle>
          </Col>
          <Col span={5}>
            <SubTitle weight="normal">Price</SubTitle>
          </Col>
          <Col span={3}>
            <SubTitle weight="normal">Quantity</SubTitle>
          </Col>
          <Col span={4} className="text-align-right">
            <SubTitle weight="normal">Total Price</SubTitle>
          </Col>
        </Row>
      )}
      <Row className="ph-small">{children}</Row>
    </>
  );
};

interface ISupportLineItemProps {
  supportLineItem: FundingNdisSupportCategory;
}

const SupportLineItem = ({ supportLineItem }: ISupportLineItemProps): JSX.Element => {
  const lineItem = ndisHelper.getBySupportItemNumber(supportLineItem.supportItemNumber);

  return (
    <Row className="mv-x-small flex align-center">
      <Col span={10}>
        <Text size="small">{lineItem.SupportItem}</Text>
        <br />
        <Text size="small" weight="bold">
          {supportLineItem.supportItemNumber}
        </Text>
      </Col>
      <Col span={2}>
        <Text size="small" weight="bold">
          {lineItem.UnitOfMeasure}
        </Text>
      </Col>
      <Col span={5}>
        <Text size="small">
          {supportLineItem.price != null ? <CurrencyFormatter value={supportLineItem.price}></CurrencyFormatter> : '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text size="small">{supportLineItem.quantity != null ? supportLineItem.quantity : '-'}</Text>
      </Col>
      <Col span={4} className="text-align-right">
        <Text size="small">
          <CurrencyFormatter value={supportLineItem.funding}></CurrencyFormatter>
        </Text>
      </Col>
    </Row>
  );
};

const FundingCategoriesSummary = () => {
  const supportTypes = ndisHelper.supportPurposeTypes.filter((data) => !isEmpty(data));
  const { categoryData } = useFundingCategoriesContext();

  let totalAllocated = 0;
  let totalBudgeted = 0;

  const rowClasses = 'pt-small';

  const renderSupportTypeSummary = (supportType: string) => {
    let allCategories = categoryData?.filter((category) => category.supportType === supportType) ?? [];

    const allocated = allCategories
      .filter((category) => category.allocated)
      .reduce((total, category) => total + category.allocated, 0);
    const budgeted = allCategories
      .filter((category) => category.funding)
      .reduce((total, category) => total + category.funding, 0);

    totalAllocated += allocated;
    totalBudgeted += budgeted;

    return (
      <Row key={supportType} className={rowClasses}>
        <Col span={14}>
          <Text>{`${supportType} allocated funding`}</Text>
        </Col>
        <Col span={5} className="text-align-right">
          <Text>
            <CurrencyFormatter value={allocated}></CurrencyFormatter>
          </Text>
        </Col>
        <Col span={5} className="text-align-right">
          <Text>
            <CurrencyFormatter value={budgeted}></CurrencyFormatter>
          </Text>
        </Col>
      </Row>
    );
  };

  return (
    <div className="bg-tertiary ph-medium pv-medium">
      <Row>
        <Col span={14}></Col>
        <Col span={5} className="text-align-right">
          <Text weight="bold">Allocated</Text>
        </Col>
        <Col span={5} className="text-align-right">
          <Text weight="bold">Budgeted</Text>
        </Col>
      </Row>
      {supportTypes.map((supportType) => renderSupportTypeSummary(supportType))}
      <Row className={rowClasses}>
        <Col span={14}>
          <Title level={4}>Total allocated funding</Title>
        </Col>
        <Col span={5} className="text-align-right">
          <Title level={4}>
            <CurrencyFormatter value={totalAllocated}></CurrencyFormatter>
          </Title>
        </Col>
        <Col span={5} className="text-align-right">
          <Title level={4}>
            <CurrencyFormatter value={totalBudgeted}></CurrencyFormatter>
          </Title>
        </Col>
      </Row>
    </div>
  );
};

interface FundingViewCategoriesProviderProps {
  categoryData: FundingNdisSupportCategory[];
  getCategory: (
    supportType: string,
    supportCategoryNumber?: number,
    supportItemNumber?: string,
  ) => FundingNdisSupportCategory;
  categoryExists: (payload: FundingNdisSupportCategory) => boolean;
  getSupportLineItems: (supportType?: string, supportCategoryNumber?: number) => FundingNdisSupportCategory[];
}

const FundingViewCategoriesPanelContext = createContext<FundingViewCategoriesProviderProps>(null);

const useFundingCategoriesContext = () => useContext(FundingViewCategoriesPanelContext);

interface FundingEditCategoriesPanel2Props {
  categoryData: FundingNdisSupportCategory[];
}

export const FundingViewCategoriesPanel = (props: FundingEditCategoriesPanel2Props): JSX.Element => {
  let { categoryData } = props;

  const getCategory = (
    supportType: string,
    supportCategoryNumber?: number,
    supportItemNumber?: string,
  ): FundingNdisSupportCategory => {
    const category = categoryData?.find((c) => {
      let match = c.supportType === supportType;

      if (supportCategoryNumber) {
        match = match && c.supportCategoryNumber === supportCategoryNumber;
      }

      if (supportItemNumber) {
        match = match && c.supportItemNumber === supportItemNumber;
      }

      return match;
    });

    if (!category) {
      return null;
    }

    return { ...category };
  };

  const categoryExists = (category: FundingNdisSupportCategory): boolean => {
    const index = categoryData.findIndex((c) => {
      let match = c.supportType === category.supportType;

      if (category.supportCategoryNumber) {
        match = match && c.supportCategoryNumber === category.supportCategoryNumber;
      }

      if (category.supportItemNumber) {
        match = match && c.supportItemNumber === category.supportItemNumber;
      }

      return match;
    });
    return index !== -1;
  };

  const supportTypes = ndisHelper.supportPurposeTypes.filter((data) => !isEmpty(data));
  const getSupportCategories = (supportType: string): FundingNdisSupportCategory[] => {
    if (!categoryData) {
      return [];
    }

    let supportCategories = categoryData.filter(
      (category) =>
        category.supportType === supportType && category.supportCategoryNumber && !category.supportItemNumber,
    );

    return cloneDeep(supportCategories);
  };

  const getSupportLineItems = (supportType?: string, supportCategoryNumber?: number): FundingNdisSupportCategory[] => {
    if (!categoryData) {
      return [];
    }

    let supportLineItems = categoryData.filter((category) => category.supportItemNumber);

    if (supportType) {
      supportLineItems = supportLineItems.filter((category) => category.supportType === supportType);
    }

    if (supportCategoryNumber) {
      supportLineItems = supportLineItems.filter(
        (category) => category.supportCategoryNumber === supportCategoryNumber,
      );
    }

    return cloneDeep(supportLineItems);
  };

  return (
    <FundingViewCategoriesPanelContext.Provider
      value={{
        categoryData,
        getCategory,
        categoryExists,
        getSupportLineItems,
      }}
    >
      {supportTypes.map((supportType: string) => {
        const categorySupportType = getCategory(supportType);

        if (categorySupportType == null) {
          return <></>;
        }

        return (
          <SupportType key={supportType} supportTypeName={supportType}>
            <>
              {categorySupportType && supportType === 'Core' && categoryExists({ supportType }) && (
                <SupportCategory
                  key={supportType + 'Category'}
                  supportCategory={categorySupportType}
                  nameOverride="All Core Categories"
                >
                  <>
                    {getSupportLineItems(supportType).map((supportLineItem) => (
                      <SupportLineItem
                        key={supportLineItem.supportItemNumber}
                        supportLineItem={supportLineItem}
                      ></SupportLineItem>
                    ))}
                  </>
                </SupportCategory>
              )}

              {supportType !== 'Core' && (
                <>
                  {getSupportCategories(supportType).map((supportCategory) => (
                    <SupportCategory key={supportCategory.supportCategoryNumber} supportCategory={supportCategory}>
                      <>
                        {getSupportLineItems(supportType, supportCategory.supportCategoryNumber).map(
                          (supportLineItem) => (
                            <SupportLineItem
                              key={supportLineItem.supportItemNumber}
                              supportLineItem={supportLineItem}
                            ></SupportLineItem>
                          ),
                        )}
                      </>
                    </SupportCategory>
                  ))}
                </>
              )}
            </>
          </SupportType>
        );
      })}

      <FundingCategoriesSummary></FundingCategoriesSummary>
    </FundingViewCategoriesPanelContext.Provider>
  );
};

export default FundingViewCategoriesPanel;
