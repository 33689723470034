import React, { Component } from 'react';
import { Col, notification, Radio, Row } from 'antd';
import { Paragraph } from 'common-components/typography';
import { connect } from 'react-redux';
import { PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import { ScheduleType } from 'utilities/enum-utils';

interface ISessionTypeStepPanelProps {
  onNextStep: (stepData?: any) => void;
  updateScheduleType: (scheduleType) => void;
  setNewGroupServiceSchedule: typeof dispatch.servicesStore.setNewGroupServiceSchedule;
  newGroupServiceSchedule: typeof state.servicesStore.newGroupServiceSchedule;
}

interface ISessionTypeStepPanelState {
  scheduleType: ScheduleType | null;
  isScheduleTypeError: boolean;
}

class SessionTypeStepPanel extends Component<ISessionTypeStepPanelProps, ISessionTypeStepPanelState> {
  state = {
    scheduleType: this.props.newGroupServiceSchedule && this.props.newGroupServiceSchedule.scheduleType,
    isScheduleTypeError: false,
  };

  private _selectScheduleType = async (scheduleType) => {
    this.setState({ scheduleType });
    await this.props.setNewGroupServiceSchedule({});
  };

  private _goToNext = async () => {
    const { setNewGroupServiceSchedule, newGroupServiceSchedule } = this.props;
    const { scheduleType } = this.state;

    if (!scheduleType) {
      this.setState({ isScheduleTypeError: true });
    } else {
      try {
        this.props.updateScheduleType(scheduleType);
        await setNewGroupServiceSchedule({
          ...newGroupServiceSchedule,
          scheduleType,
        });
        this.props.onNextStep();
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  render() {
    const { scheduleType } = this.state;

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>We'll guide you through creating sessions for this group service</Title>
              <Paragraph>The next few screens will cover everything that's needed to create sessions.</Paragraph>
              <Paragraph>
                First select if you'd like to create a <b>schedule of sessions</b> or just a single{' '}
                <b>one-off session</b>.
              </Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white rounded-big p-large" style={{ minWidth: '250px' }}>
              <Title level={4}>Select type</Title>
              <Paragraph>What would you like to create?</Paragraph>
              <div
                className={`mb-medium bordered border-standard-gray rounded-big shadow-container cursor-pointer p-medium flex-row ${
                  scheduleType === ScheduleType.ONE_OFF && 'bg-blue-lightest'
                }`}
                onClick={() => this._selectScheduleType(ScheduleType.ONE_OFF)}
              >
                <div className={'pr-medium'}>
                  <Radio checked={scheduleType === ScheduleType.ONE_OFF} value={ScheduleType.ONE_OFF} />
                </div>
                <div>
                  <b>One-off session</b>
                  <br />
                  Create a one-off session that occurs only once.
                </div>
              </div>
              <div
                className={`bordered border-standard-gray rounded-big shadow-box cursor-pointer p-medium flex-row ${
                  scheduleType === ScheduleType.SCHEDULE && 'bg-blue-lightest'
                }`}
                onClick={() => this._selectScheduleType(ScheduleType.SCHEDULE)}
              >
                <div className={'pr-medium'}>
                  <Radio checked={scheduleType === ScheduleType.SCHEDULE} value={ScheduleType.SCHEDULE} />
                </div>
                <div>
                  <b>Schedule</b>
                  <br />
                  Create a series of sessions that spans across a period of dates.
                </div>
              </div>
              {this.state.isScheduleTypeError && (
                <div className={'mt-medium'}>
                  <Paragraph className={'text-color-red-dark'}>Please select a type.</Paragraph>
                </div>
              )}
            </div>
            <div className="pv-medium width-full bg-tertiary" style={{ position: 'sticky', bottom: 0 }}>
              <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                <Col className="bg-transparent" span={24}>
                  <div className="text-align-right pv-medium">
                    <PrimaryButton size="large" onClick={this._goToNext}>
                      Next
                    </PrimaryButton>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  newGroupServiceSchedule: state.servicesStore.newGroupServiceSchedule,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewGroupServiceSchedule: dispatch.servicesStore.setNewGroupServiceSchedule,
});

export default connect(mapState, mapDispatch)(SessionTypeStepPanel);
