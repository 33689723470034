import { Text } from 'common-components/typography';
import React from 'react';
import { FaHashtag } from 'react-icons/fa';

export function InboxItem({ isSelected = false, text = '', count = 0, onClick = () => {} }) {
  let className = 'ph-medium pv-small cursor-pointer flex-row align-center ';

  if (isSelected) {
    className += ' bg-blue-lightest';
  } else {
    className += 'hover-bg-secondary ';
  }

  return (
    <div className="pl-none" onClick={onClick}>
      <div className={className} style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
        <FaHashtag className="text-size-x-small text-color-blue-dark mr-small" />
        {/*<MdInbox className="text-size-x-small text-color-blue-dark mr-small" />*/}
        {/*<Icon type="inbox" />*/}

        <Text
          size="regular"
          color={isSelected ? 'blue-dark' : 'blue'}
          weight={isSelected ? 'regular' : 'regular'}
          ellipsis={true}
          className="flex-1 select-none"
        >
          {text}
        </Text>

        {count > 0 && (
          <span
            style={{ width: '18px', height: '18px', borderRadius: '2px' }}
            className="bg-gold-light text-size-x-small text-align-center text-color-white text-weight-bold ml-small"
          >
            {count}
          </span>
        )}
      </div>
    </div>
  );
}
