import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Fingerprint = forwardRef(function Fingerprint(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.719 1.887A8.75 8.75 0 0 1 20.75 10a.75.75 0 1 1-1.5 0 7.25 7.25 0 0 0-9.969-6.724.75.75 0 1 1-.562-1.39ZM6.224 4.415A.75.75 0 0 1 6.34 5.47 7.216 7.216 0 0 0 4.75 10v4a7.254 7.254 0 0 0 4.984 6.89.75.75 0 1 1-.468 1.424A8.754 8.754 0 0 1 3.25 14v-4c0-2.068.719-3.971 1.92-5.469a.75.75 0 0 1 1.054-.116ZM20 13.25a.75.75 0 0 1 .75.75 8.754 8.754 0 0 1-6.016 8.314.75.75 0 1 1-.468-1.425A7.254 7.254 0 0 0 19.25 14a.75.75 0 0 1 .75-.75Zm-8-4a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0v-4a.75.75 0 0 1 .75-.75Zm0-4A4.75 4.75 0 0 0 7.25 10v4a.75.75 0 0 0 1.5 0v-4A3.25 3.25 0 0 1 12 6.75a.75.75 0 0 0 0-1.5Zm4.114 2.374a.75.75 0 1 0-1.298.752c.276.477.434 1.03.434 1.624v4a3.25 3.25 0 0 1-4.874 2.816.75.75 0 0 0-.751 1.298A4.75 4.75 0 0 0 16.75 14v-4c0-.864-.232-1.676-.637-2.376Z"
        fill="currentColor"
      />
    </Icon>
  );
});
