import { useLayoutEffect, useState } from 'react';

type SetSize = React.Dispatch<React.SetStateAction<ResizeObserverSize | undefined>>;

let observer: ResizeObserver | undefined;
let setStates: WeakMap<Element, SetSize> | undefined;

/**
 * ResizeObserver is more efficient than window.resize listener: https://stackoverflow.com/a/64989430
 * ...And re-using a single one even more so: https://stackoverflow.com/a/51541931
 */
export const useElementSize = (elementRef: React.RefObject<Element>) => {
  const [size, setSize] = useState<ResizeObserverSize>();

  useLayoutEffect(() => {
    const { current: element } = elementRef;
    if (!element) return undefined;

    if (!setStates) setStates = new WeakMap<Element, SetSize>();
    if (!observer)
      observer = new ResizeObserver((entries) => {
        for (const entry of entries) for (const size of entry.borderBoxSize) setStates?.get(entry.target)?.(size);
      });

    setStates.set(element, setSize);
    observer.observe(element);

    return () => {
      setStates?.delete(element);
      observer?.unobserve(element);
    };
  }, [elementRef]);

  return size;
};
