import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Wheelchair = forwardRef(function Wheelchair(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM9.25 4a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM12 8.25a.75.75 0 0 1 .75.75v2.25H18a.75.75 0 0 1 0 1.5h-5.25v2.5H18a.75.75 0 0 1 .75.75v5.25H20a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75v-5.25H12a.75.75 0 0 1-.75-.75V9a.75.75 0 0 1 .75-.75ZM4.75 17A4.25 4.25 0 0 1 9 12.75a.75.75 0 0 0 0-1.5 5.75 5.75 0 1 0 4.6 9.2.75.75 0 1 0-1.2-.9A4.25 4.25 0 0 1 4.75 17Z"
        fill="currentColor"
      />
    </Icon>
  );
});
