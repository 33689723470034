// Primary Owner Permissions
import { Text } from 'common-components/typography';
import { Select } from 'antd';
import React from 'react';

export function PrimaryOwnerPermissionRow({ isTargetUserOwner, onChangeRole }) {
  return (
    <div className="bg-orange-lightest rounded mb-medium p-medium flex-row">
      <div>
        <Text weight="bold" size="large">
          As the Primary owner, you have the ability to assign this person as an Owner or General team member.
        </Text>{' '}
        <br />
        <Text size="regular" color="secondary">
          Owners have full portal access plus the ability to make administration changes.
        </Text>
      </div>
      <div className="ml-large">
        {/*<Input size="large" />*/}
        <Select
          size="large"
          value={isTargetUserOwner ? 'owner' : 'team-member'}
          onChange={onChangeRole}
          style={{ minWidth: '220px' }}
        >
          <Select.Option value="team-member">General Team Member </Select.Option>
          <Select.Option value="owner">Owner </Select.Option>
        </Select>
      </div>
    </div>
  );
}
