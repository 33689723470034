import { Text } from '@good/components';
import { ArrowBottom, ArrowTop, Clock, Maximize2, Minimize } from '@good/icons';
import { Divider } from 'design-components';
import React, { ReactNode, useMemo, useState } from 'react';
import { BarChartData, BillableHoursBarChart } from '../billable-hours/billable-hours';
import { toPercentage } from '../insights-table/insights-table-row';
import { useTranslation } from 'react-i18next';

type InsightsSummaryWidgetProperties = {
  overallUtilisationPercent?: number | null;
  totalHours?: number;
  totalHoursChangedPercent?: number | null;
  billableHours?: number;
  nonBillableHours?: number;
};

type DisplaySummaryWidgetProperties = InsightsSummaryWidgetProperties & {
  chartData: BarChartData;
};

type MinifiedSummaryWidgetProperties = Omit<
  DisplaySummaryWidgetProperties,
  'totalHoursChangedPercent | billableHours | nonBillableHours'
>;

const LabelText: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Text className="block text-b-xs text-gray-dark-1">{children}</Text>
);

type LargeValueTextProperties = {
  value: number | null | undefined;
  render?: (value: number) => JSX.Element | string;
};

const LargeValueText: React.FC<LargeValueTextProperties> = ({ value, render }) => {
  // If no value is given, then return a hyphen as a placeholder.
  if (value === null || value === undefined) {
    return <Text className="text-lg block font-bold">-</Text>;
  }

  return <Text className="text-lg block font-bold">{render ? render(value) : value}</Text>;
};

const TallDivider = () => <div className="h-20 w-[1px] bg-gray-light-1" />;
const ShortDivider = () => <div className="h-10 w-[1px] max-w-[1px] bg-gray-light-1" />;

const MinifiedInsightsSummaryWidget: React.FC<MinifiedSummaryWidgetProperties> = ({
  overallUtilisationPercent,
  totalHours,
  chartData,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'insights.table.widget' });

  return (
    <div className="flex h-fit flex-row items-center space-x-14">
      <div className="flex flex-col items-start justify-center">
        <LabelText>{t('overallUtilisation')}</LabelText>
        <LargeValueText value={overallUtilisationPercent} render={toPercentage} />
      </div>
      <ShortDivider />
      <div>
        <LabelText>{t('totalHoursRecorded')}</LabelText>
        <LargeValueText value={totalHours} />
      </div>
      {chartData.some((bar) => bar.value) && (
        <>
          <ShortDivider />
          <div>
            <LabelText>{t('billableVsNonBillable')}</LabelText>
            <BillableHoursBarChart horizontal={true} data={chartData} />
          </div>
        </>
      )}
    </div>
  );
};

const ExpandedInsightsSummaryWidget: React.FC<DisplaySummaryWidgetProperties> = ({
  overallUtilisationPercent,
  totalHours,
  totalHoursChangedPercent,
  billableHours,
  nonBillableHours,
  chartData,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'insights.table.widget' });
  const showChange = totalHoursChangedPercent !== undefined && totalHoursChangedPercent !== null;

  const [changeDirection, changeColorClass, changeIndicatorIcon]: [string, string, JSX.Element | undefined] =
    useMemo(() => {
      // If we have no change, then we want no 'up' or 'down', default text and no change icon.
      if (
        totalHoursChangedPercent === undefined ||
        totalHoursChangedPercent === null ||
        totalHoursChangedPercent === 0
      ) {
        return ['', 'text-black', undefined];
      }

      // Positive changes should be green with an upwards trending arrow.
      if (totalHoursChangedPercent > 0) {
        return ['up', 'text-green', <ArrowTop key="arrow-label" aria-label="trending up" />];
      }

      // Negative changes should be red with a downwards trending arrow.
      return ['down', 'text-red', <ArrowBottom key="arrow-label" aria-label="trending down" />];
    }, [totalHoursChangedPercent]);

  return (
    <div className="h-100 align-center flex w-full flex-row justify-evenly space-x-8 py-4">
      <div className="align-left flex flex-col">
        <LabelText>{t('overallUtilisation')}</LabelText>
        <LargeValueText value={overallUtilisationPercent} render={toPercentage} />
      </div>
      <TallDivider />
      <div className="flex flex-row">
        <div className="align-center text-color-white mr-3 flex h-12 w-12 justify-center rounded-xl bg-canary-ref fill-white stroke-white">
          <Clock />
        </div>
        <div className="mr-5 flex-col">
          <LabelText>{t('totalHoursRecorded')}</LabelText>
          <LargeValueText value={totalHours} />
        </div>
      </div>
      <div className={`font-base ${changeColorClass} text-center text-b-sm`}>
        {showChange && (
          <>
            {toPercentage(Math.abs(totalHoursChangedPercent))} {changeDirection} {changeIndicatorIcon}
            <br /> {t('fromLastPeriod')}
          </>
        )}
      </div>
      <TallDivider />
      <div className="text-b-sm text-black">
        <div className="flex flex-row justify-between">
          <div className="align-center mr-3 flex flex-row">
            <div className="mr-2 h-3 w-3 rounded-sm bg-lavender-ref" />
            <Text>{t('billable')}</Text>
          </div>
          <Text className="font-bold">{billableHours ?? 0} hours</Text>
        </div>
        <div className="my-2">
          <Divider />
        </div>
        <div className="flex flex-row justify-between">
          <div className="align-center mr-3 flex flex-row">
            <div className="mr-2 h-3 w-3 rounded-sm bg-lilac-ref" />
            <Text>{t('nonBillable')}</Text>
          </div>
          <Text className="font-bold">{nonBillableHours ?? 0} hours</Text>
        </div>
      </div>
      <div className="flex h-16">
        <BillableHoursBarChart data={chartData} />
      </div>
    </div>
  );
};

export const InsightsSummaryWidget: React.FC<InsightsSummaryWidgetProperties> = (
  data: InsightsSummaryWidgetProperties,
) => {
  const { t } = useTranslation('', { keyPrefix: 'insights.table.widget' });
  const [expanded, setExpanded] = useState<boolean>(false);
  const toggleExpanded = () => setExpanded(!expanded);

  const chartData: BarChartData = [
    { label: t('billable'), value: data.billableHours ?? 0, colorClass: 'bg-lavender-ref' },
    { label: t('nonBillable'), value: data.nonBillableHours ?? 0, colorClass: 'bg-lilac-ref' },
  ];

  const toggleButtonIcon = expanded ? <Minimize /> : <Maximize2 />;
  const widgetHeightClass = expanded ? 'h-32' : 'h-20';

  return (
    <div
      className={`${widgetHeightClass} flex w-fit flex-row items-center justify-between rounded-lg border-[1px] border-solid border-base px-8 transition-all`}
    >
      {expanded ? (
        <ExpandedInsightsSummaryWidget chartData={chartData} {...data} />
      ) : (
        <MinifiedInsightsSummaryWidget chartData={chartData} {...data} />
      )}
      <button
        className="align-center ml-6 flex justify-center rounded-lg border-[1px] border-solid border-base p-1 outline-none transition-colors hover:border-ocean"
        onClick={toggleExpanded}
        aria-label="expand-toggle"
      >
        {toggleButtonIcon}
      </button>
    </div>
  );
};
