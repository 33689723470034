import { forwardRef } from 'react';
import { Illustration } from './illustration';
import type { IllustrationProps } from './illustration';
import type { Ref } from 'react';
export const PlayButton = forwardRef(function PlayButton(props: IllustrationProps, ref: Ref<SVGSVGElement>) {
  return (
    <Illustration {...props} ref={ref}>
      <path
        d="M64.16 121.28c31.547 0 57.12-25.573 57.12-57.12 0-31.546-25.573-57.12-57.12-57.12-31.546 0-57.12 25.574-57.12 57.12 0 31.547 25.574 57.12 57.12 57.12Z"
        fill="currentColor"
      />
      <path d="m98.523 64.16-50.227 29v-58l50.227 29Z" fill="#fff" />
    </Illustration>
  );
});
