import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IWorkflowListItem } from 'src/interfaces/workflow-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { defaultWorkflowListingFilterParams, defaultWorkflowListingFilters } from 'views/workflows/utils/constants';
import { formatWorkflowListingFilters } from 'views/workflows/utils/workflow-utils';
import WorkflowListingFilterSection from '../../WorkflowListingFilterSection';
import WorkflowListingItem from '../../WorkflowListingItem';

interface IMyWorkflowsItemPanelProps {
  portalUser: typeof state.authStore.portalUser;
  allApprovalWorkflows: typeof state.workflowStore.allApprovalWorkflows;
  doGetApprovalWorkflowsList: typeof dispatch.workflowStore.doGetApprovalWorkflowsList;
  onClickRow(workflow: IWorkflowListItem): void;
  reloadTime?: number;
}

interface IMyWorkflowsItemPanelState {
  isSearching: boolean;
  isLoading: boolean;
  isLoadingInfiniteScrolling: boolean;
  searchString: string;
  workflowFilters: any;
  page: number;
  pageSize: number;
}

class MyWorkflowsItemPanel extends PureComponent<IMyWorkflowsItemPanelProps, IMyWorkflowsItemPanelState> {
  state = {
    isSearching: false,
    isLoading: false,
    isLoadingInfiniteScrolling: false,
    searchString: '',
    workflowFilters: defaultWorkflowListingFilters,
    page: 1,
    pageSize: 20,
  };

  private _onChangeFilters = async (filters: Array<any>) => {
    this.setState({ workflowFilters: filters, page: 1 });
  };

  private _searchText = async (searchString) => {
    this.setState({ searchString });

    const { workflowFilters } = this.state;

    this.setState({ isSearching: true, isLoading: true });

    await this.props.doGetApprovalWorkflowsList({
      ...defaultWorkflowListingFilterParams,
      searchString,
      ...formatWorkflowListingFilters(workflowFilters),
    });

    this.setState({ isSearching: false, page: 1, isLoading: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onSearchText = (value) => {
    this.setState({ isSearching: true });
    this._debounceSearch(value);
  };

  private _fetchMoreApprovalWorkflows = async () => {
    this.setState({ isLoadingInfiniteScrolling: true });

    const { page, pageSize, searchString, workflowFilters } = this.state;

    const nextPage = page + 1;

    const formattedFilter = formatWorkflowListingFilters(workflowFilters);

    await this.props.doGetApprovalWorkflowsList({
      page: nextPage,
      pageSize,
      searchString: searchString,
      ...formattedFilter,
    });

    this.setState({ page: nextPage, isLoadingInfiniteScrolling: false });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { workflowFilters, searchString } = this.state;
    const { reloadTime } = this.props;

    if (!_.isEqual(prevState.workflowFilters, workflowFilters) || reloadTime !== prevProps.reloadTime) {
      this.setState({ isLoading: true, ...defaultWorkflowListingFilterParams });

      const newFilters = formatWorkflowListingFilters(workflowFilters);

      await this.props.doGetApprovalWorkflowsList({
        ...defaultWorkflowListingFilterParams,
        searchString,
        ...newFilters,
      });

      this.setState({ isLoading: false });
    }
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    const workflowFilters = formatWorkflowListingFilters(defaultWorkflowListingFilters);

    await this.props.doGetApprovalWorkflowsList({ ...defaultWorkflowListingFilterParams, ...workflowFilters });

    this.setState({ isLoading: false });
  };

  render() {
    const { portalUser, allApprovalWorkflows } = this.props;
    const { isSearching, isLoading, workflowFilters, page, pageSize } = this.state;

    return (
      <div className="mt-small">
        <WorkflowListingFilterSection
          isSearching={isSearching}
          timezone={portalUser.timezone}
          onSearchText={this._onSearchText}
          workflowFilters={workflowFilters}
          onChangeFilter={this._onChangeFilters}
        />

        <WorkflowListingItem
          isLoading={isLoading}
          isLoadingInfiniteScrolling={this.state.isLoadingInfiniteScrolling}
          page={page}
          pageSize={pageSize}
          workflows={allApprovalWorkflows}
          timezone={portalUser.timezone}
          onClickRow={this.props.onClickRow}
          onFetchMore={this._fetchMoreApprovalWorkflows}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  allApprovalWorkflows: state.workflowStore.allApprovalWorkflows,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetApprovalWorkflowsList: dispatch.workflowStore.doGetApprovalWorkflowsList,
});

export default connect(mapState, mapDispatch)(MyWorkflowsItemPanel);
