import React from 'react';
import { Box, Inline, Stack, Text } from '@goodhuman-me/components';
import { useProgressBar } from 'react-aria';

import type { AriaProgressBarProps } from 'react-aria';
import type { StackProps } from '@goodhuman-me/components';

export type ProgressBarProps = {
  secondaryColor?: string;
  projectedValue?: number;
} & AriaProgressBarProps &
  StackProps;

/**
 * @name
 * ProgressBar
 */
export function ProgressBar(props: ProgressBarProps): JSX.Element {
  const {
    label,
    maxValue = 100,
    minValue = 0,
    showValueLabel = !!label,
    value = 0,
    color = '$accent',
    secondaryColor,
    projectedValue,
    ...otherProps
  } = props;

  const { progressBarProps, labelProps } = useProgressBar(props);
  const percentage = (value - minValue) / (maxValue - minValue);
  const barWidth = `${Math.round(percentage * 100)}%`;
  const progressCompeted = percentage >= 1;

  const projectedPercentage = (projectedValue - minValue) / (maxValue - minValue);
  const projectedBarWidth = `${Math.round(projectedPercentage * 100)}%`;

  let progressColor = color;
  let projectedColor = null;

  // primary color should always be on the LHS
  if (projectedValue) {
    progressColor = value > projectedValue ? secondaryColor : color;
    projectedColor = value < projectedValue ? null : color;
  }

  return (
    <Stack width="$size2400" {...otherProps} gap="$space150" {...progressBarProps}>
      <Inline justifyContent="space-between" alignItems="center" gap="$medium" flex="auto">
        {label && (
          <Text asChild size="xsmall" {...labelProps}>
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {label}
            </span>
          </Text>
        )}

        {showValueLabel && (
          <Inline justifyContent="flex-end" flex="1 0 auto">
            <Text size="xsmall" asChild>
              <span>{progressBarProps['aria-valuetext']}</span>
            </Text>
          </Inline>
        )}
      </Inline>

      <Box backgroundColor="$body" height="$size100" borderRadius="$medium" position="relative">
        <Box
          width={barWidth}
          maxWidth="100%"
          height="$size100"
          backgroundColor={progressColor}
          overflow="hidden"
          asChild
        >
          <div
            style={{
              borderTopLeftRadius: 'var(--radii-medium)',
              borderBottomLeftRadius: 'var(--radii-medium)',
              borderTopRightRadius: progressCompeted && 'var(--radii-medium)',
              borderBottomRightRadius: progressCompeted && 'var(--radii-medium)',
            }}
          />
        </Box>
        {projectedValue && (
          <Box
            width={projectedBarWidth}
            maxWidth="100%"
            position="absolute"
            backgroundColor={projectedColor}
            left="0"
            right="0"
            top="0"
            bottom="0"
            display="flex"
            justifyContent="end"
            asChild
          >
            <div
              style={{
                borderTopLeftRadius: 'var(--radii-medium)',
                borderBottomLeftRadius: 'var(--radii-medium)',
              }}
            >
              <ProjectedIndicator />
            </div>
          </Box>
        )}
      </Box>
    </Stack>
  );
}

const ProjectedIndicator = () => {
  return (
    <Box marginRight="-0.5em" marginTop="-0.175em">
      <svg width="1em" height="1em" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4.02979" y="1.25" width="2.5" height="7.5" fill="white" />
        <line
          x1="5.27979"
          y1="2.5"
          x2="5.27979"
          y2="7.5"
          stroke="#3D3D3B"
          strokeLinecap="round"
          strokeDasharray="1 2"
        />
        <rect x="4.02979" y="1.25" width="2.5" height="7.5" stroke="white" strokeWidth="1.5" />
        <path
          d="M7.75324 14.5C8.46143 14.5 8.96361 14.0216 9.16796 13.4762C9.37198 12.9317 9.3134 12.245 8.84848 11.731L6.37503 8.99635C5.77643 8.33455 4.78314 8.33455 4.18454 8.99635L1.71109 11.731C1.24617 12.245 1.18759 12.9317 1.39161 13.4762C1.59595 14.0216 2.09814 14.5 2.80633 14.5H7.75324Z"
          fill="#3D3D3B"
          stroke="white"
        />
      </svg>
    </Box>
  );
};
