import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Bookmark = forwardRef(function Bookmark(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.945 2.25H13.055c1.368 0 2.47 0 3.337.117.9.12 1.658.38 2.26.981.602.602.86 1.36.982 2.26.116.867.116 1.97.116 3.337V21a.75.75 0 0 1-1.122.651l-5.015-2.866c-.497-.284-.824-.47-1.092-.59-.252-.113-.398-.142-.52-.142-.124 0-.27.03-.522.142-.268.12-.594.306-1.091.59l-5.016 2.866A.75.75 0 0 1 4.25 21V8.945c0-1.367 0-2.47.117-3.337.12-.9.38-1.658.981-2.26.602-.602 1.36-.86 2.26-.981.867-.117 1.97-.117 3.337-.117ZM7.808 3.853c-.734.099-1.122.28-1.399.556-.277.277-.457.665-.556 1.4C5.752 6.562 5.75 7.564 5.75 9v10.708l3.893-2.225.03-.017c.46-.263.85-.486 1.192-.64.365-.163.727-.273 1.135-.273.408 0 .77.11 1.135.274.342.153.733.376 1.192.639l.03.017 3.893 2.225V9c0-1.435-.002-2.437-.103-3.192-.099-.734-.28-1.122-.556-1.399-.277-.277-.665-.457-1.399-.556-.755-.101-1.757-.103-3.192-.103h-2c-1.435 0-2.436.002-3.192.103Z"
        fill="currentColor"
      />
    </Icon>
  );
});
