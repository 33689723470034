import { differenceInDaysNotRounded } from 'utilities/date-utils';
import { DateRange } from 'views/sil-service/tabs/schedule/components/rostering-daterange-selector';
import { format, getMonth, getYear } from 'date-fns';
import React from 'react';

export const DateRangeToText = ({ dateRange }: { dateRange: DateRange }) => {
  const { start, end } = dateRange;

  const differenceInDays = differenceInDaysNotRounded(end, start);

  if (differenceInDays < 1) {
    return <>{format(start, 'dd MMM yyyy')}</>;
  }

  const hasDifferentYears = getYear(start) !== getYear(end);
  if (hasDifferentYears) {
    return <>{`${format(start, 'dd MMM yyyy')} - ${format(end, 'dd MMM yyyy')}`}</>;
  }

  const hasDifferentMonths = getMonth(start) !== getMonth(end);
  if (hasDifferentMonths) {
    return <>{`${format(start, 'dd MMM')} - ${format(end, 'dd MMM yyyy')}`}</>;
  }

  return <>{`${format(start, 'dd')} - ${format(end, 'dd MMM yyyy')}`}</>;
};
