import { useTranslation } from 'react-i18next';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

import {
  CreateWorkTypeRequest,
  CreateWorkTypeResponse,
  createWorkType,
} from 'stores/queries/work-types/work-type-queries';
import { WorkTypeCategory } from 'utilities/enum-utils';
import { OptimisticCreate } from './optimistic-mutation/optimistic-create';
import { Toast } from './optimistic-mutation/toast';
import { IWorkLeaveType } from 'interfaces/account-interfaces';
import { ClientError } from 'interfaces/common-interface';
import { getNewRelicBrowser } from 'integrations/new-relic-browser';

type Context = {
  name: string;
  optimisticCreate: OptimisticCreate<IWorkLeaveType | CreateWorkTypeRequest>;
};

export function useMutationCreateWorkType(
  workTypeCategory: WorkTypeCategory,
): UseMutationResult<CreateWorkTypeResponse, ClientError, CreateWorkTypeRequest, Context> {
  const queryClient = useQueryClient();
  const { t } = useTranslation('', {
    keyPrefix: `accountManagement.workLeaveTypesPage.notification.create.${workTypeCategory}`,
  });
  const toast = new Toast();

  return useMutation<CreateWorkTypeResponse, ClientError, CreateWorkTypeRequest, Context>({
    mutationFn: createWorkType,
    mutationKey: ['work-types', workTypeCategory],
    onMutate: async (newWorkType) => {
      const name = newWorkType.name;
      const optimisticCreate = new OptimisticCreate<IWorkLeaveType | CreateWorkTypeRequest>(
        queryClient,
        'work-types',
        [workTypeCategory],
        (prevData, item) => [...prevData, { ...item, state: 'ACTIVE', priority: 0 }],
      );

      await optimisticCreate.start(newWorkType);

      return {
        name,
        optimisticCreate,
      };
    },

    onError: (err, _newWorkType, context) => {
      toast.error(t('error'), context?.name, err.meta.message);
      context?.optimisticCreate.rollback();
      const newRelicBrowser = getNewRelicBrowser();
      newRelicBrowser?.noticeError({ ...err, name: err.message }, { location: 'useMutationCreateWorkType' });
    },

    onSuccess: (response, _newWorkType, context) => {
      toast.success(t('success'), context?.name);
      context?.optimisticCreate.finish(response.data);
    },

    // onSettled: () => {},
  });
}
