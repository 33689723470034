import { css } from '../theme';

import type * as Stitches from '@stitches/react';

export const tabContainer = css({
  display: 'flex',
  variants: {
    orientation: {
      horizontal: {
        flexDirection: 'column',
      },
      vertical: {
        flexDirection: 'row',
      },
    },
  },
});

export const tabPane = css({
  variants: {
    orientation: {
      horizontal: {},
      vertical: {
        flexGrow: 1,
      },
    },
  },
});

export const tabLabelList = css({
  display: 'flex',
  gap: '$space200',
  justifyContent: 'left',

  variants: {
    orientation: {
      horizontal: {
        flexDirection: 'row',
      },
      vertical: {
        flexDirection: 'column',
      },
    },
    underline: {
      true: {
        boxShadow: 'inset 0px -1px #E0DEDC',
      },
    },
    spread: {
      hug: {},
      full: {
        '& > div': {
          flexGrow: 1,
          justifyContent: 'center',
        },
      },
    },
  },
});

export const tabLabelWrapper = css({
  display: 'flex',
  alignItems: 'center',
  height: 'fit-content',

  // Reset
  '&:focus': {
    outlineStyle: 'none',
    outlineColor: '$transparent',
  },

  // Default
  $$tabLabelWrapperBorderColor: '$colors-transparent',
  $$tabLabelWrapperBorderBottomWidth: '$borderWidths-border200',

  variants: {
    underline: {
      true: {
        height: '$$tabLabelWrapperHeight',
        borderBottomStyle: '$solid',
        borderBottomColor: '$$tabLabelWrapperBorderColor',
        borderBottomWidth: '$$tabLabelWrapperBorderBottomWidth',
      },
    },
    size: {
      xsmall: {
        $$tabLabelWrapperHeight: '$sizes-size500',
      },
      small: {
        $$tabLabelWrapperHeight: '$sizes-size600',
      },
      medium: {
        $$tabLabelWrapperHeight: '$sizes-size800',
      },
    },
    isActive: {
      true: {
        $$tabLabelWrapperBorderColor: '$colors-ocean',
      },
    },
    isHovered: {
      true: {
        $$tabLabelWrapperBorderColor: '$colors-bodyLight1',
      },
    },
    isPressed: {
      true: {
        $$tabLabelWrapperBorderColor: '$colors-oceanLight2',
      },
    },
  },

  compoundVariants: [
    {
      isActive: true,
      isHovered: true,
      css: {
        $$tabLabelWrapperBorderColor: '$colors-oceanDark1',
      },
    },
    {
      isActive: true,
      isPressed: true,
      css: {
        $$tabLabelWrapperBorderColor: '$colors-oceanDark2',
      },
    },
  ],
});

export type TabLabelWrapperVariants = Stitches.VariantProps<typeof tabLabelWrapper>;

export const tabLabel = css({
  display: 'flex',
  alignItems: 'center',
  gap: '$$tabLabelGap',
  cursor: 'pointer',

  color: '$$tabLabelTextColor',
  backgroundColor: '$$tabLabelBackgroundColor',

  fontSize: '$$tabLabelFontSize',
  fontWeight: '$$tabLabelFontWeight',
  lineHeight: '$$tabLabelLineHeight',

  paddingLeft: '$$tabLabelPaddingX',
  paddingRight: '$$tabLabelPaddingX',
  paddingTop: '$$tabLabelPaddingY',
  paddingBottom: '$$tabLabelPaddingY',

  borderRadius: '$small',

  outlineStyle: '$$tabLabelOutlineStyle',
  outlineColor: '$$tabLabelOutlineColor',
  outlineWidth: '$$tabLabelOutlineWidth',
  outlineOffset: '$$tabLabelOutlineOffset',

  // Reset
  boxSizing: 'border-box',

  // Default
  $$tabLabelTextColor: '$colors-bodyDark1',

  variants: {
    size: {
      xsmall: {
        $$tabLabelGap: '$space-space20',
        $$tabLabelFontWeight: '$fontWeights-normal',
        $$tabLabelFontSize: '$fontSizes-font50',
        $$tabLabelLineHeight: '$lineHeights-leading100',
        $$tabLabelPaddingX: '$space-space100',
        $$tabLabelPaddingY: '$space-space50',
      },
      small: {
        $$tabLabelGap: '$space-space50',
        $$tabLabelFontWeight: '$fontWeights-normal',
        $$tabLabelFontSize: '$fontSizes-font75',
        $$tabLabelLineHeight: '$lineHeights-leading100',
        $$tabLabelPaddingX: '$space-space150',
        $$tabLabelPaddingY: '$space-space100',
      },
      medium: {
        $$tabLabelGap: '$space-space75',
        $$tabLabelFontWeight: '$fontWeights-semibold',
        $$tabLabelFontSize: '$fontSizes-font75',
        $$tabLabelLineHeight: '$lineHeights-leading400',
        $$tabLabelPaddingX: '$space-space200',
        $$tabLabelPaddingY: '$space-space130',
      },
    },
    isActive: {
      true: {
        $$tabLabelTextColor: '$colors-oceanDark1',
        $$tabLabelBackgroundColor: '$colors-white',
      },
    },
    isHovered: {
      true: {
        $$tabLabelTextColor: '$colors-bodyDark2',
        $$tabLabelBackgroundColor: '$colors-bodyLight2',
      },
    },
    isPressed: {
      true: {
        $$tabLabelTextColor: '$color-oceanDark1',
        $$tabLabelBackgroundColor: '$colors-oceanLight1',
      },
    },
    isFocused: {
      true: {
        $$tabLabelTextColor: '$color-ocean',
        $$tabLabelBackgroundColor: '$colors-white',
        $$tabLabelOutlineStyle: 'solid',
        $$tabLabelOutlineColor: '$colors-ocean',
        $$tabLabelOutlineWidth: '1px',
        $$tabLabelOutlineOffset: '-1px',
      },
    },
  },
  compoundVariants: [
    {
      isActive: true,
      isHovered: true,
      css: {
        $$tabLabelTextColor: '$colors-oceanDark1',
        $$tabLabelBackgroundColor: '$colors-oceanLight1',
      },
    },
    {
      isActive: true,
      isPressed: true,
      css: {
        $$tabLabelTextColor: '$colors-oceanDark1',
        $$tabLabelBackgroundColor: '$colors-oceanLight1',
        $$tabLabelOutlineStyle: 'solid',
        $$tabLabelOutlineColor: '$colors-oceanDark1',
        $$tabLabelOutlineWidth: '1.5px',
        $$tabLabelOutlineOffset: '-1.5px',
      },
    },
    {
      isActive: true,
      isFocused: true,
      css: {
        $$tabLabelTextColor: '$colors-oceanDark1',
        $$tabLabelBackgroundColor: '$colors-white',
        $$tabLabelOutlineStyle: 'solid',
        $$tabLabelOutlineColor: '$colors-ocean',
        $$tabLabelOutlineWidth: '2px',
        $$tabLabelOutlineOffset: '-2px',
      },
    },
  ],
});

export type TabLabelVariants = Stitches.VariantProps<typeof tabLabel>;
