import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Sun = forwardRef(function Sun(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V2a.75.75 0 0 1 .75-.75Zm0 17a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75Zm0-9.5a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5ZM7.25 12a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0ZM2 11.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5H2Zm17 0a.75.75 0 1 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3ZM4.4 4.399a.75.75 0 0 1 1.06 0l2.122 2.12A.75.75 0 0 1 6.52 7.582L4.399 5.459a.75.75 0 0 1 0-1.06Zm12.02 12.02a.75.75 0 0 1 1.061 0l2.122 2.122a.75.75 0 1 1-1.061 1.06l-2.122-2.12a.75.75 0 0 1 0-1.062ZM4.4 18.541a.75.75 0 0 0 1.06 1.06l2.122-2.12a.75.75 0 0 0-1.061-1.062l-2.121 2.122ZM16.419 6.52a.75.75 0 1 0 1.061 1.06l2.122-2.12a.75.75 0 1 0-1.061-1.061l-2.122 2.12Z"
        fill="currentColor"
      />
    </Icon>
  );
});
