import { FundingNdisSupportCategory } from 'interfaces/funding-interfaces';
import { NdisSupportTypes } from './enum-utils';

class FundingUtils {
  static getFundingPackageCategoryFromLineItem = (
    supportType: string,
    supportCategoryNumber: number,
    supportItemNumber: string,
    fundingPackage: FundingNdisSupportCategory[],
  ): FundingNdisSupportCategory | null => {
    if (!supportItemNumber || !supportType || !supportCategoryNumber) {
      return null;
    }

    // First check if the item is a Stated Support.
    const fundStatedSupport = fundingPackage?.find((category) => {
      return supportItemNumber === category.supportItemNumber;
    });

    // If not a Stated Support, take the parent Category funding.
    return fundStatedSupport
      ? fundStatedSupport
      : fundingPackage?.find((category) => {
          return (
            supportType.toUpperCase() === NdisSupportTypes.CORE ||
            (supportCategoryNumber === category.supportCategoryNumber &&
              !category.supportItemNumber &&
              Number(category.funding) > 0)
          );
        }) ?? null;
  };

  static doesLineItemFundingCategoryExistInFundingPackage = (
    supportType: string,
    supportCategoryNumber: number,
    supportItemNumber: string,
    fundingPackage: FundingNdisSupportCategory[],
  ): boolean => {
    const fundingPackageCategory = FundingUtils.getFundingPackageCategoryFromLineItem(
      supportType,
      supportCategoryNumber,
      supportItemNumber,
      fundingPackage,
    );

    return fundingPackageCategory ? true : false;
  };
}

export default FundingUtils;
