import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Form, Icon, Input, InputNumber, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';
import _, { isEmpty } from 'lodash';
import { PaymentSourceType } from 'utilities/enum-utils';
import { Switch } from 'antd';
import { Spinner } from '@blueprintjs/core';

interface IAssignNewPrimaryOwnerProps extends FormComponentProps {
  isLoading: boolean;
  isOpen: any;
  onClose: () => void;
  doEditInvoiceConfig: typeof dispatch.companyStore.doEditInvoiceConfig;
  companyFundingAndPayment: typeof state.companyStore.companyFundingAndPayment;
}

interface IAssignNewPrimaryOwnerState {
  title: any;
  isCreated: boolean;
}

class EditInvoiceModal extends Component<IAssignNewPrimaryOwnerProps, IAssignNewPrimaryOwnerState> {
  state = { title: 'Invoice Configuration', isCreated: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Invoice Configuration', isCreated: false });
    this.props.onClose();
  };

  private _validatePrefix = (rule, value, callback) => {
    try {
      const onlyLetters = /^[a-zA-Z]+$/;
      if (isEmpty(value)) {
        throw Error('Please enter at least 1 letter.');
      }
      if (!onlyLetters.test(value)) {
        throw Error('Invoice prefix can only contains letters.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _validateBSB = (rule, value, callback) => {
    const validBSB = /^\d{3}[- ]?\d{3}$/;

    if (isEmpty(value) || !validBSB.test(value)) {
      callback(Error('Please enter a valid BSB number '));
      return;
    }
    callback();
  };

  private _validateAccountNumber = (rule, value, callback) => {
    const validAccountNumber = /^[0-9][0-9- ]*$/;
    if (isEmpty(value) || !validAccountNumber.test(value)) {
      callback(Error('Please enter a valid account number'));
      return;
    }
    callback();
  };

  private _editInvoiceConfig = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        await this.props.doEditInvoiceConfig({
          invoicePrefix: form.getFieldValue('invoicePrefix'),
          invoiceDueDate: form.getFieldValue('invoiceDueDate'),
          invoiceTerms: form.getFieldValue('invoiceTerms') || '',
          isShowBankAccount: form.getFieldValue('isShowBankAccount'),
          accountName: form.getFieldValue('accountName'),
          bsb: form.getFieldValue('bsb'),
          accountNumber: form.getFieldValue('accountNumber'),
        });
        this.setState({
          title: (
            <>
              Invoice successfully edited
              <Icon type="check-circle" className="ml-x-small text-color-green" />
            </>
          ),
          isCreated: true,
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  render() {
    const { isOpen, isLoading, form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    const InvoiceConfig = this.props.companyFundingAndPayment
      ? _.find(
          this.props.companyFundingAndPayment.paymentSources,
          (funding) => funding.paymentSourceType === PaymentSourceType.INVOICE,
        )
      : null;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isCreated ? (
          <>
            <div className="mv-medium anim-slide-left">
              <SubTitle>Invoice prefix</SubTitle>
              <Text size="x-large">{InvoiceConfig ? InvoiceConfig.invoicePrefix : '-'}</Text>
              <div className="mt-large">
                <SubTitle>Invoice due after</SubTitle>
                <Text size="x-large">
                  {InvoiceConfig
                    ? InvoiceConfig.paymentSourceConfiguration.invoiceDueDate +
                      ' DAY' +
                      (InvoiceConfig.paymentSourceConfiguration.invoiceDueDate !== 1 ? 'S' : '')
                    : '-'}
                </Text>
              </div>
              <div className="mt-large">
                <SubTitle>Invoice terms & conditions</SubTitle>
                <Text style={{ whiteSpace: 'pre-line' }}>
                  {InvoiceConfig ? InvoiceConfig.paymentSourceConfiguration.invoiceTerms : '-'}
                </Text>
              </div>
              {InvoiceConfig?.paymentSourceConfiguration.isShowBankAccount && (
                <>
                  <div className="mt-large">
                    <SubTitle>Account Name</SubTitle>
                    <Text size="x-large">{InvoiceConfig.paymentSourceConfiguration.accountName}</Text>
                  </div>
                  <div className="mt-large">
                    <SubTitle>BSB</SubTitle>
                    <Text size="x-large">{InvoiceConfig.paymentSourceConfiguration.bsb}</Text>
                  </div>
                  <div className="mt-large">
                    <SubTitle>Account number</SubTitle>
                    <Text size="x-large">{InvoiceConfig.paymentSourceConfiguration.accountNumber}</Text>
                  </div>
                </>
              )}
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Done
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : !isLoading ? (
          <>
            <div className="anim-slide-left">
              <div className="mb-x-large">
                <Paragraph>Customise aspects of invoices that are generate in GoodHuman.</Paragraph>
                <div className="mt-large">
                  <SubTitle>Invoice prefix</SubTitle>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('invoicePrefix', {
                      initialValue: InvoiceConfig && InvoiceConfig.invoicePrefix,
                      rules: [{ validator: this._validatePrefix }],
                    })(<Input style={{ width: '100px' }} maxLength={5} size="large" />)}
                  </Form.Item>
                </div>
                <div className="mt-large">
                  <SubTitle>Invoice due after</SubTitle>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('invoiceDueDate', {
                      initialValue: InvoiceConfig && InvoiceConfig.paymentSourceConfiguration.invoiceDueDate,
                      rules: [{ required: true, message: 'Please enter a due date in days for the invoices.' }],
                    })(<InputNumber min={1} max={365} size="large" style={{ width: '80px' }} />)}
                    <span className="ml-medium">Days</span>
                  </Form.Item>
                </div>
                <div className="mt-large">
                  <SubTitle>Invoice terms & conditions</SubTitle>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('invoiceTerms', {
                      initialValue: InvoiceConfig && InvoiceConfig.paymentSourceConfiguration.invoiceTerms,
                    })(
                      <TextArea
                        placeholder="Add terms & conditions to your invoices"
                        autoSize={{ minRows: 4, maxRows: 4 }}
                      />,
                    )}
                  </Form.Item>
                </div>
                <div className="mt-large">
                  <SubTitle>Bank Account details</SubTitle>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('isShowBankAccount', {
                      initialValue: InvoiceConfig?.paymentSourceConfiguration.isShowBankAccount,
                    })(
                      <Switch
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                        checked={getFieldValue('isShowBankAccount')}
                      />,
                    )}
                    <span className="ml-small">Add my bank account details</span>
                  </Form.Item>
                </div>
                {getFieldValue('isShowBankAccount') && (
                  <>
                    <div className="mt-large">
                      <SubTitle>Account Name</SubTitle>
                      <Form.Item className="width-full m-none mt-small">
                        {getFieldDecorator('accountName', {
                          initialValue: InvoiceConfig?.paymentSourceConfiguration.accountName ?? '',
                          rules: [
                            {
                              required: true,
                              message: 'Please enter your account name',
                            },
                          ],
                        })(<Input placeholder="Name" size="large" style={{ width: 250 }} />)}
                      </Form.Item>
                    </div>
                    <div className="mt-large">
                      <SubTitle>BSB</SubTitle>
                      <Form.Item className="width-full m-none mt-small">
                        {getFieldDecorator('bsb', {
                          initialValue: InvoiceConfig?.paymentSourceConfiguration.bsb ?? '',
                          rules: [{ validator: this._validateBSB }],
                        })(<Input placeholder="000 000" size="large" style={{ width: 200 }} />)}
                      </Form.Item>
                    </div>
                    <div className="mt-large">
                      <SubTitle>Account number</SubTitle>
                      <Form.Item className="width-full m-none mt-small">
                        {getFieldDecorator('accountNumber', {
                          initialValue: InvoiceConfig?.paymentSourceConfiguration.accountNumber ?? '',
                          rules: [{ validator: this._validateAccountNumber }],
                        })(<Input placeholder="000 000 000" size="large" style={{ width: 200 }} />)}
                      </Form.Item>
                    </div>
                  </>
                )}
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._editInvoiceConfig}>
                  Save changes
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        ) : (
          <div style={{ display: 'flex', minHeight: 300, justifyContent: 'center' }}>
            <Spinner size={100}></Spinner>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPayment: state.companyStore.companyFundingAndPayment,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditInvoiceConfig: dispatch.companyStore.doEditInvoiceConfig,
});

export default connect(mapState, mapDispatch)(Form.create<IAssignNewPrimaryOwnerProps>()(EditInvoiceModal));
