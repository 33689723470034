import { Avatar, Col, notification, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { IGroupServiceActivityGroup, IGroupServiceSession } from 'interfaces/service-interfaces';
import { IActivityGroupUsers } from 'interfaces/user-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { ActivityGroupMemberType, ActivityGroupModalType } from 'utilities/enum-utils';
import SelectActivityGroups from '../../selectors/SelectActivityGroups';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { Stack } from '../../../../common-components/stack';

interface IAssignMemberToActivityGroupModalContentProps {
  serviceId: string;
  modalType: ActivityGroupModalType;
  activityGroup: IGroupServiceActivityGroup;
  selectedUser: IActivityGroupUsers;
  session?: IGroupServiceSession;
  sessionActivityGroups: IGroupServiceActivityGroup[];
  doFetchSessionActivityGroups: typeof dispatch.groupServiceStore.doFetchSessionActivityGroups;
  onCloseViewModal: () => void;
  handleAction: (payload, actionType: ActivityGroupModalType) => Promise<boolean | void>;
  ignoredAlert?: boolean;
}

interface IAssignMemberToActivityGroupModalContentState {
  isLoading: boolean;
  addedActivityGroups: IGroupServiceActivityGroup[];
}

class AssignMemberToActivityGroupModalContent extends Component<
  IAssignMemberToActivityGroupModalContentProps,
  IAssignMemberToActivityGroupModalContentState
> {
  state = {
    isLoading: false,
    addedActivityGroups: [],
  };

  private _handleAlterStateWhenInteractWithSelectedItems = (addedItems: IGroupServiceActivityGroup[]) => {
    this.setState({ addedActivityGroups: addedItems });
  };

  private _handleAssign = async () => {
    const { serviceId, selectedUser, modalType, handleAction, ignoredAlert } = this.props;
    const { addedActivityGroups } = this.state;
    this.setState({ isLoading: true });
    try {
      const memberType =
        modalType === ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP
          ? ActivityGroupMemberType.CUSTOMER
          : ActivityGroupMemberType.TEAM_MEMBER;
      const serviceActivityGroupIds = _.map(addedActivityGroups, (group) => group.serviceActivityGroupId);
      const payload = {
        serviceId,
        userId: selectedUser.userId,
        memberType,
        serviceActivityGroupIds,
        addedActivityGroups: addedActivityGroups,
      };

      await handleAction(payload, modalType);
      if (!ignoredAlert) {
        notification.success({
          message: `${
            modalType === ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP ? 'Customer' : 'Team member'
          } added to activity group`,
          description: `You've succesfully assgined ${
            selectedUser.firstName + ' ' + selectedUser.lastName
          } to activity group(s).`,
        });
      }
      this.props.onCloseViewModal();
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong! Please try again.',
      });
      console.log(e);
    }

    this.setState({ isLoading: false });
  };

  async componentDidMount() {
    const { serviceId, session, doFetchSessionActivityGroups } = this.props;
    if (session && session.serviceDateTimeId) {
      await doFetchSessionActivityGroups({ serviceId, serviceDateTimeId: session.serviceDateTimeId });
    }
  }

  render() {
    const { selectedUser, session, modalType, sessionActivityGroups } = this.props;
    const isRenderSessionCard = !_.isEmpty(session);
    const addedActivityGroupsCount = this.state.addedActivityGroups.length;

    return (
      <div className="width-full">
        <Stack gap="xl">
          <div
            className="bg-quaternary rounded pv-12 ph-medium block"
            style={{ width: 'fit-content', minWidth: '144px' }}
          >
            <div className="mb-x-small">
              <SubTitle size="x-small">
                {modalType === ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP ? 'CUSTOMER' : 'TEAM MEMBER'}
              </SubTitle>
            </div>

            <div>
              <Avatar
                size={32}
                src={selectedUser.attachmentUrl}
                shape={modalType === ActivityGroupModalType.ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP ? 'circle' : 'square'}
              />{' '}
              <Text className="ml-small" color="primary" size="medium">
                {selectedUser.firstName + ' ' + selectedUser.lastName}
              </Text>
            </div>
          </div>

          <SelectActivityGroups
            serviceId={this.props.serviceId}
            modalType={this.props.modalType}
            onInteractWithAddedItems={this._handleAlterStateWhenInteractWithSelectedItems}
            groupSessionActivityGroups={sessionActivityGroups}
            isRenderSessionCard={isRenderSessionCard}
          />
        </Stack>

        <div className="flex justify-end mt-large">
          <SecondaryButton size="large" color="secondary" className="mr-12" onClick={this.props.onCloseViewModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            size="large"
            onClick={this._handleAssign}
            disabled={addedActivityGroupsCount < 1}
            loading={this.state.isLoading}
          >
            Assign to selected group{addedActivityGroupsCount > 1 ? 's' : ''}
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  sessionActivityGroups: state.groupServiceStore.sessionActivityGroups,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSessionActivityGroups: dispatch.groupServiceStore.doFetchSessionActivityGroups,
});

export default connect(mapState, mapDispatch)(AssignMemberToActivityGroupModalContent);
