import React, { HTMLProps, ReactElement, useRef, useState } from 'react';

import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';

import type { ColumnDef, Row, PaginationState, RowSelectionState } from '@tanstack/react-table';
import { Checkbox, CheckboxProps, Space, Table as TableUi, TextInput } from '@good/ui/core';
import { Search } from '@good/icons';

type IReactTableProps<T extends object> = {
  data: T[];
  columns: ColumnDef<T>[];
  renderSubComponent?: (props: { row: Row<T> }) => React.ReactElement;
  pageIndex?: number;
  pageSize?: number;
  pageCount?: number;
  onPaginationChange?: (pagination: PaginationState) => void;
  style?: React.CSSProperties;
  className?: string;
  isSelectable?: boolean;
  isFilterable?: boolean;
  isScrollable?: boolean;
};

export const SilTable = <T extends object>({
  data,
  columns,
  isFilterable,
  isSelectable,
}: IReactTableProps<T>): JSX.Element => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      globalFilter,
      sorting,
    },
    enableRowSelection: isSelectable,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  });

  const icon = <Search />;

  return (
    <>
      {isFilterable && (
        <div style={{ width: 240 }}>
          <TextInput
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            leftSection={icon}
            placeholder='Search'
            size='xs'
          />
          <Space h='md' />
        </div>
      )}
      <div>
        <TableUi verticalSpacing='sm' data-testid='table'>
          <TableUi.Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableUi.Tr key={headerGroup.id} style={{ borderBottomColor: 'var(--brand-body-light-2' }}>
                <TableUi.Th w={20}>
                  {isSelectable && (
                    <IndeterminateCheckbox
                      {...{
                        checked: table.getIsAllRowsSelected(),
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllRowsSelectedHandler(),
                      }}
                    />
                  )}
                </TableUi.Th>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableUi.Th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        fontWeight: '600',
                        color: 'var(--brand-body-dark-2)',
                        width: header.getSize() !== 150 ? header.getSize() : undefined,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                      )}
                    </TableUi.Th>
                  );
                })}
              </TableUi.Tr>
            ))}
          </TableUi.Thead>
          <TableUi.Tbody>
            {table.getRowModel().rows.map((row) => (
              <TableUi.Tr key={row.id} style={{ borderBottomColor: 'var(--brand-body-light-2' }}>
                <TableUi.Td>
                  {isSelectable && (
                    <IndeterminateCheckbox
                      {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                      }}
                    />
                  )}
                </TableUi.Td>
                {row.getVisibleCells().map((cell) => (
                  <TableUi.Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext()) as ReactElement}
                  </TableUi.Td>
                ))}
              </TableUi.Tr>
            ))}
          </TableUi.Tbody>
        </TableUi>
      </div>
    </>
  );
};

function IndeterminateCheckbox({ indeterminate, ...rest }: { indeterminate?: boolean } & CheckboxProps) {
  const ref = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (ref.current && typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return <Checkbox size='sm' ref={ref} {...rest} />;
}
