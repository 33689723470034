/* eslint-disable */
import apiClient from 'utilities/api-client';
import {
  IBatchItem,
  IBatchLineItem,
  IBillingBooking,
  IBillingLineItem,
  IInvoiceItem,
  IBillingReviewInvoice,
} from 'interfaces/booking-interfaces';
import { INotification } from 'interfaces/notification-interfaces';
import _ from 'lodash';
import { IRootState, state } from 'stores/rematch/root-store';
import { IFilter } from 'interfaces/filter-interfaces';

// Interface
interface INotificationsStoreState {
  notificationItems: INotification[];
  notificationItemsFilter: any;
  totalNotificationCount: number;
  // notificationItemsPagination: any;
  dashboardNotificationItems: INotification[];
  // dashboardNotificationItemsPagination: any;
  badgeCount: number;
  messagingBadgeCount: number;
}

const initialState: INotificationsStoreState = {
  notificationItems: [],
  totalNotificationCount: 0,
  notificationItemsFilter: {
    actionType: [],
  },
  // notificationItemsPagination: {
  //   page: 1,
  //   pageSize: 25
  // },
  dashboardNotificationItems: [],
  badgeCount: 0,
  messagingBadgeCount: 0,
};

const notificationsStore = {
  state: initialState,
  reducers: {
    // setNotificationItems: (state, payload) => ({
    //   ...state,
    //   notificationItems: [...state.notificationItems, ...payload]
    // }),
    setNotificationCount: (state, payload) => ({ ...state, totalNotificationCount: payload }),
    setNotificationItems: (state, payload) => ({ ...state, notificationItems: payload }),
    setNotificationItemsFilter: (state, payload) => ({ ...state, notificationItemsFilter: payload }),

    // setNotificationLineItemsPagination: (state, payload) => ({ ...state, notificationLineItemsPagination: payload }),

    setDashboardNotificationItems: (state, payload) => ({
      ...state,
      dashboardNotificationItems: _.unionBy(state.dashboardNotificationItems, payload, 'notificationId'),
    }),

    setBadgeCount: (state, payload) => ({ ...state, badgeCount: payload }),
    setMessagingBadgeCount: (state, payload) => ({ ...state, messagingBadgeCount: payload }),

    removeDashboardNotificationItem: (state, payload) => ({
      ...state,
      dashboardNotificationItems: _.filter(
        state.dashboardNotificationItems,
        (notification) => notification.notificationId !== payload,
      ),
    }),
  },
  effects: (dispatch) => ({
    async doFetchNotificationItems(payload, rootState) {
      const fullUrl = '/api/portal/notification/list';

      const filter: any = {};
      filter.actionType = payload.actionType;
      filter.page = payload.page;
      filter.pageSize = payload.pageSize;

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          dispatch.notificationsStore.setNotificationItems(resp.data.notificationList);
          dispatch.notificationsStore.setNotificationCount(resp.data.totalNotificationCount);
          return resp.data.notificationList;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchDashboardNotificationItems(payload, rootState) {
      const fullUrl = '/api/portal/notification/list';

      const filter: any = {};
      filter.actionType = 'ACTION';
      filter.startDate = payload.startDate;
      filter.page = payload.page;
      filter.pageSize = 20;
      filter.isViewed = false;

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          dispatch.notificationsStore.setDashboardNotificationItems(resp.data.notificationList);

          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doDimissNotification(payload, rootState) {
      const fullUrl = `/api/portal/notification/${payload.notificationId}/dismiss`;

      try {
        const resp = await apiClient.put(fullUrl);
        if (resp.status === 200) {
          dispatch.notificationsStore.removeDashboardNotificationItem(payload.notificationId);
          return true;
        }
      } catch (err) {}
    },

    async doResetNotificationBadgeCount(payload, rootState) {
      const fullUrl = '/api/portal/notification/badge/reset';

      try {
        const resp = await apiClient.put(fullUrl);
        if (resp.status === 200) {
          //TODO need to check against booking status to hide/show
          dispatch.notificationsStore.setBadgeCount(0);
          return true;
        }
      } catch (err) {
        throw err;
      }
    },

    async doGetNotificationBadgeCount(payload, rootState) {
      const fullUrl = '/api/portal/notification/badge';

      try {
        const resp = await apiClient.get(fullUrl);

        const { notificationBadgeCount = 0, messagingBadgeCount = 0 } = resp.data;

        if (resp.status === 200) {
          //TODO need to check against booking status to hide/show
          dispatch.notificationsStore.setBadgeCount(Number(notificationBadgeCount));
          dispatch.notificationsStore.setMessagingBadgeCount(Number(messagingBadgeCount));
          return true;
        }
      } catch (err) {}
    },
  }),
};

export default notificationsStore;
