import React, { Component } from 'react';
import _ from 'lodash';
import { Avatar, Checkbox, Input, Row } from 'antd';
import { connect } from 'react-redux';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { IWorkflowTemplateStep, IWorkflowTemplateStepApprover } from 'interfaces/workflow-interfaces';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { TeamStatus } from 'utilities/enum-utils';
import { Text } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { getWorkersByKeyword } from '../../utils/workflow-utils';

const { Search } = Input;

interface Props {
  isOpen: boolean;
  selectedStep: IWorkflowTemplateStep;
  onClose(): void;
  onUpdateStep(step: IWorkflowTemplateStep): void;
  workerListLite: typeof state.teamStore.workerListLite;
  doFetchWorkerListLite: typeof dispatch.teamStore.doFetchWorkerListLite;
  approvers: IWorkflowTemplateStepApprover[];
  onChange?: () => IWorkflowTemplateStepApprover[];
}

interface State {
  isSearching: boolean;
  isLoading: boolean;
  isSearched: boolean;
  stepApprovers: IWorkflowTemplateStepApprover[];
  currentApprovers: IWorkflowTemplateStepApprover[];
  filteredWorkersList: typeof state.teamStore.workerListLite;
}

class WorkflowTemplateAddTeamMemberModal extends Component<Props, State> {
  state = {
    isLoading: false,
    isSearching: false,
    isSearched: false,
    stepApprovers: [],
    filteredWorkersList: [],
    currentApprovers: [],
  };

  private _searchText = (txt) => {
    const { workerListLite } = this.props;
    const result = getWorkersByKeyword(txt, workerListLite);
    this.setState({ filteredWorkersList: result, isSearching: false, isSearched: true });
  };

  private _debounceSearch = _.debounce(this._searchText, 300);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });

    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _onSelectWorker = (user, selectedIndex) => {
    const selectedApprovers = [...this.state.currentApprovers];

    if (selectedIndex === -1) {
      selectedApprovers.push({
        displayName: `${user.firstName} ${user.lastName}`,
        avatar: user.attachmentUrl,
        approverUserId: user.userId,
      });
    } else {
      selectedApprovers.splice(selectedIndex, 1);
    }

    this.setState({ currentApprovers: [...selectedApprovers] });
  };

  private _onAddApprovers = () => {
    this.props.onChange?.([...this.state.currentApprovers]);
    this.props.onClose();
  };

  private _onClose = () => {
    const { selectedStep, onClose } = this.props;

    this.setState({ isSearched: false, filteredWorkersList: [] });

    if (selectedStep) {
      this.setState({
        stepApprovers: [...selectedStep.stepApprovers],
        currentApprovers: [],
      });
    }

    onClose();
  };

  componentDidMount = async () => {
    const { approvers, doFetchWorkerListLite } = this.props;

    await doFetchWorkerListLite({
      supportWorkerStatus: [TeamStatus.ENABLED],
      hasWorkflowPermission: true,
      hasAppAccess: ['PORTAL'],
    });

    this.setState({
      currentApprovers: [...approvers],
      isSearching: false,
    });
  };

  render() {
    const { isOpen } = this.props;
    const { currentApprovers, filteredWorkersList, isSearching, isSearched } = this.state;
    const selectedUserId = _.map(currentApprovers, 'approverUserId');

    return (
      <ActionModal
        isOpen={isOpen}
        title={'Add approvers'}
        width="large"
        onClose={this._onClose}
        showCloseButton={true}
        verticalAlignment="center"
      >
        <div className="flex-column anim-slide-left mb-medium">
          <Text>
            Please select the team members you want to add as approvers (or viewers) for this step. Only team members
            with workflow permission access can be selected.
          </Text>

          <Text color="secondary" size="regular" className="mv-medium">
            <Text color="secondary" weight="bold" size="regular">
              {selectedUserId.length ? selectedUserId.length : 'No'}
            </Text>
            &nbsp;team member{selectedUserId.length > 1 ? 's' : ''} selected
          </Text>

          <Search
            placeholder="Search for team members"
            size="large"
            onChange={this._onEnterSearchText}
            loading={isSearching}
            style={{ width: '100%' }}
            className="mb-x2-small"
            allowClear
          />

          {isSearched && (
            <div className="overflow-y-scroll bordered shadow-container" style={{ maxHeight: '250px' }}>
              {filteredWorkersList.length ? (
                _.map(filteredWorkersList, (item) => {
                  const selectedIndex = _.indexOf(selectedUserId, item.userId);
                  // const existedSelect = _.indexOf(existedSelectUserId, item.userId) !== -1;

                  return (
                    <Row
                      key={item.userId}
                      className={`${
                        selectedIndex !== -1 && 'bg-blue-lightest'
                      } pv-small ph-medium pl-12 hover-bg-tertiary cursor-pointer flex-row align-center`}
                      onClick={() => this._onSelectWorker(item, selectedIndex)}
                    >
                      <Checkbox checked={selectedIndex !== -1} />
                      <Avatar className="mh-small" icon="user" shape="circle" src={item.attachmentUrl} />
                      <span
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        <Text>{item.firstName + ' ' + item.lastName}</Text>
                      </span>
                    </Row>
                  );
                })
              ) : (
                <div className="text-align-center pv-12">
                  <Text>No team members found</Text>
                </div>
              )}
            </div>
          )}
        </div>

        <ActionModalFooter>
          <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._onAddApprovers}>
            Add
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  workerListLite: state.teamStore.workerListLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchWorkerListLite: dispatch.teamStore.doFetchWorkerListLite,
});

export default connect(mapState, mapDispatch)(WorkflowTemplateAddTeamMemberModal);
