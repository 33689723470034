import { Address } from './types';

export function placeDataToAddress(placeData: {
  geocoderResult: google.maps.GeocoderResult;
  latLng: google.maps.LatLngLiteral;
}): Address {
  const {
    geocoderResult: { address_components, formatted_address },
    latLng,
  } = placeData;

  const address: Address = {
    fullAddress: formatted_address,
    streetAddress1: '',
    streetAddress2: '',
    locality: '',
    state: null,
    postcode: '',
    geoLat: latLng.lat,
    geoLng: latLng.lng,
  };

  address_components.forEach((component) => {
    const { types } = component;

    if (types.includes('subpremise')) {
      address.streetAddress2 = component.long_name;
    }

    if (types.includes('premise')) {
      if (address.streetAddress2) {
        address.streetAddress2 = `${address.streetAddress2} ${component.long_name}`;
      } else {
        address.streetAddress2 = component.long_name;
      }
    }

    if (types.includes('street_number')) {
      address.streetAddress1 = component.long_name;
    }

    if (types.includes('route')) {
      if (address.streetAddress1) {
        address.streetAddress1 = `${address.streetAddress1} ${component.long_name}`;
      } else {
        address.streetAddress1 = component.long_name;
      }
    }

    if (types.includes('locality')) {
      address.locality = component.long_name;
    }

    if (types.includes('administrative_area_level_1')) {
      address.state = component.short_name as Address['state'];
    }

    if (types.includes('postal_code')) {
      address.postcode = component.long_name;
    }
  });

  return address;
}
