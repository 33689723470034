import { css } from '@goodhuman-me/components';

export let toggleButton = css({
  appearance: 'none !important',
  border: 'none',
  borderRadius: '8px',
  padding: '$none',
  width: '$size500',
  height: '$size400',
  margin: '$space20',

  '&[data-state="on"]': {
    backgroundColor: '$accent',
    color: '$white',
  },

  '&[data-state="off"]': {
    backgroundColor: 'transparent',
  },
});
