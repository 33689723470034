import React from 'react';
import { Modal, Text, Space } from '@good/ui/core';
import { useTranslation } from 'react-i18next';

type RemoveModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const RemoveModal = ({ isOpen, onClose, onConfirm }: RemoveModalProps) => {
  const { t } = useTranslation('', { keyPrefix: 'accountManagement.payPeriodPage.removeModal' });
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={t('heading')}
      size='md'
      confirmProps={{
        label: t('confirm'),
        onClick: onConfirm,
        'aria-label': 'Submit',
        color: 'red',
      }}
      cancelProps={{
        label: t('cancel'),
        onClick: onClose,
        'aria-label': 'Cancel',
      }}
    >
      <Text>{t('description1')}</Text>
      <Space h='md' />
      <Text>{t('description2')}</Text>
    </Modal>
  );
};
