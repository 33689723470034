import React, { useState } from 'react';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { Col, notification, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

type IDeleteContactModal = IMapDispatchToProps & {
  isOpen: boolean;
  userContactId: string;
  customerUserId: string;
  onClose: () => void;
};

const DeleteContactModal = ({
  isOpen,
  onClose,
  userContactId,
  doRemoveContact,
  customerUserId,
}: IDeleteContactModal) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteContact = async () => {
    if (userContactId && customerUserId) {
      try {
        setIsLoading(true);
        await doRemoveContact({ userContactId, customerUserId });
        notification.success({
          message: <b>Contact deleted</b>,
          description: <p>You have successfully delete a contact.</p>,
        });
        onClose();
      } catch (e) {
        notification.error({ message: 'Oops, an error has occurred, please try again.' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <ActionModal isOpen={isOpen} onClose={onClose} title="Delete contact">
      <div className="anim-slide-left">
        <Paragraph>Are you sure you want to delete this contact?</Paragraph>
        <div className="mb-small mt-x2-large">
          <Row type="flex" className="justify-end">
            <Col className="mr-large">
              <SecondaryButton size="large" className="mr-medium" onClick={onClose}>
                Cancel
              </SecondaryButton>
            </Col>
            <Col>
              <PrimaryButton size="large" disabled={isLoading} onClick={handleDeleteContact}>
                Delete
              </PrimaryButton>
            </Col>
          </Row>
        </div>
      </div>
    </ActionModal>
  );
};

type IMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doRemoveContact: dispatch.customersStore.doRemoveContact,
});

export default connect(null, mapDispatchToProps)(DeleteContactModal);
