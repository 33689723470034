import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

export function getValidNationalNumber(number: string, region: string): string {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(number, region);

    const isValidateRegionalNumber = phoneNumberUtil.isValidNumberForRegion(phoneNumber, region);
    if (!isValidateRegionalNumber) {
      throw Error('Number is not a validate number');
    }

    const nationalNumber = phoneNumber.getNationalNumberOrDefault();
    if (!nationalNumber) {
      throw Error('Parse number error');
    }

    return String(nationalNumber);
  } catch (e) {
    throw e;
  }
}

export function getE164FormatNumber(number: string, region: string): string {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(number, region);
  const formattedNumber = phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.E164);
  if (!formattedNumber) {
    throw Error('Format number error');
  }
  return formattedNumber;
}

export function getNationalFormatNumber(number: string, region: string): string {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(number, region);
  const formattedNumber = phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
  if (!formattedNumber) {
    throw Error('Format number error');
  }
  return formattedNumber;
}

export function getInternationalFormatNumber(number: string, region: string): string {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(number, region);
  const formattedNumber = phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
  if (!formattedNumber) {
    throw Error('Format number error');
  }
  return formattedNumber;
}

export function getDisplayCountryCode(region: string): string {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const countryCode = phoneNumberUtil.getCountryCodeForRegion(region);
  return `+${countryCode}(${region})`;
}

export function getNationalNumber(number: string, region: string): string {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(number, region);

    const nationalNumber = phoneNumber.getNationalNumberOrDefault();
    if (!nationalNumber) {
      throw Error('Parse number error');
    }

    return String(nationalNumber);
  } catch (e) {
    throw e;
  }
}
