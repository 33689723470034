import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Sunrise = forwardRef(function Sunrise(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.47 1.47a.75.75 0 0 1 1.06 0l3 3a.75.75 0 1 1-1.06 1.06l-1.72-1.72V9a.75.75 0 0 1-1.5 0V3.81L9.53 5.53a.75.75 0 0 1-1.06-1.06l3-3ZM8.837 16.25a3.251 3.251 0 0 1 6.326 0H8.837Zm-1.528 0H2a.75.75 0 0 0 0 1.5h20a.75.75 0 0 0 0-1.5h-5.309a4.751 4.751 0 0 0-9.382 0ZM6 20.25a.75.75 0 0 0 0 1.5h12a.75.75 0 0 0 0-1.5H6ZM4.4 9.399a.75.75 0 0 1 1.06 0l2.122 2.12a.75.75 0 0 1-1.061 1.062l-2.121-2.122a.75.75 0 0 1 0-1.06Zm12.02 2.12a.75.75 0 0 0 1.061 1.062l2.122-2.122A.75.75 0 1 0 18.54 9.4l-2.122 2.12Z"
        fill="currentColor"
      />
    </Icon>
  );
});
