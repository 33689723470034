import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Stack, Forms } from '../ui-components';

import type { HTMLAttributes, PropsWithChildren } from 'react';

type SubHeadingProps = PropsWithChildren<HTMLAttributes<HTMLHeadingElement>>;

function SubHeading(props: SubHeadingProps) {
  let { children, ...otherProps } = props;

  return (
    <h3 className="m-none text-size-large text-weight-bold" {...otherProps}>
      {children}
    </h3>
  );
}

/**
 * @name
 * WorkflowSettingsForm
 *
 * @description
 * Edit a workflow's settings. Must be wrapped in a `<FormProvider>`.
 *
 * @example
 * () => {
 *   let formMethods = useForm({ defaultValues: { allowFlaggin: true }})
 *
 *   return (
 *     <FormProvider {...formMethods}>
 *       <WorkflowSettingsForm />
 *     </FormProvider>
 *   )
 */
export function WorkflowSettingsForm() {
  let { control, register } = useFormContext();

  return (
    <Stack gap="xxl">
      <Stack gap="md">
        <SubHeading>Workflow management</SubHeading>
        <Forms.Checkbox control={control} {...register('allowFlagging')}>
          Allow approvers to flag issues
        </Forms.Checkbox>
      </Stack>

      <Stack gap="md">
        <SubHeading>Notifications</SubHeading>
        <Stack gap="md">
          <Forms.Checkbox control={control} {...register('notifyOnDone')}>
            Notify all approvers when a workflow is completed or closed
          </Forms.Checkbox>

          <Forms.Checkbox control={control} {...register('notifyOnFlagged')}>
            Notify all approvers when an issue is flagged
          </Forms.Checkbox>
        </Stack>
      </Stack>
    </Stack>
  );
}
