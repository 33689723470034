import React from 'react';
import { Box, Heading, Inline, Separator, Stack, Text } from '@goodhuman-me/components';

import { BudgetStatusLabel } from '..';
import { SupportItemTracking } from '../../budget';
import { BudgetStatProps, BudgetStatStack, StatKind } from '../budget-stat-stack';
import { supportCategories } from '../../categories';
import { BilledItemsAccordion, IBilledItemRow } from '../billed-items-accordion';
export interface SupportCategorySummaryProps {
  supportCategoryName: string;
  supportItemNumber: string;
  supportItemTracking: SupportItemTracking;
}

export const SupportCategorySummary = ({
  supportItemNumber,
  supportItemTracking,
}: SupportCategorySummaryProps): JSX.Element => {
  const supportItemBudgeting = supportItemTracking.supportItemBudgeting;
  const supportItemDetails = supportItemTracking.supportItemDetails;

  const { balance, percentOfBudget, projectedBalance, projectedSpend, quoted, spent, status, tracking } =
    supportItemBudgeting;

  const billedItems =
    supportItemTracking.billedItems.map((billedItem: IBilledItemRow) => ({
      ...billedItem,
      linePercent: (billedItem.total / spent) * 100,
    })) ?? [];
  const sumOfBilledItemsInstances = billedItems.reduce(
    (sumOfInstances, billedItem) => sumOfInstances + billedItem.instances,
    0,
  );

  const categoryNumber = supportItemDetails?.supportCategoryNumber;
  const categoryType = supportCategories[categoryNumber]?.type;
  const categoryHeading = supportItemDetails?.description ?? categoryType;

  const leftStatStack: readonly BudgetStatProps[] = [
    {
      label: 'Tracking',
      value: tracking,
      kind: StatKind.Currency,
    },
    {
      label: 'Quoted',
      value: quoted,
      kind: StatKind.Currency,
    },
    {
      label: 'Spent',
      value: spent,
      kind: StatKind.Currency,
    },
    {
      label: 'Balance',
      value: balance,
      kind: StatKind.Currency,
    },
  ] as const;

  const rightStatStack: readonly BudgetStatProps[] = [
    {
      label: 'Projected spend',
      value: projectedSpend,
      kind: StatKind.Currency,
    },
    {
      label: 'Projected balance',
      value: projectedBalance,
      kind: StatKind.Currency,
    },
    {
      label: '% of budget',
      value: percentOfBudget,
      kind: StatKind.Percentage,
    },
    {
      label: 'Instances',
      value: sumOfBilledItemsInstances,
      kind: StatKind.Integer,
    },
  ] as const;

  return (
    <Stack padding="$space200">
      <Inline collapseAt="1024px" gap="$space400">
        <Stack flex="1" gap="$space100">
          <Box
            borderStyle="solid"
            borderWidth="$thin"
            borderRadius="$medium"
            borderColor="$bodyLight1"
            paddingX="$space100"
            paddingY="$space50"
            width="fit-content"
          >
            <Text size="small" color="$muted">
              {supportItemNumber}
            </Text>
          </Box>
          <Heading size="medium" fontWeight="$semi">
            {categoryHeading}
          </Heading>
          <BudgetStatusLabel size="small" status={status} />
        </Stack>
        <Inline flex="1" gap="$medium" collapseAt="390px">
          <BudgetStatStack budgetStats={leftStatStack} />
          <Separator orientation="vertical" thickness="thin" />
          <BudgetStatStack budgetStats={rightStatStack} />
        </Inline>
      </Inline>
      <BilledItemsAccordion billedItems={billedItems} />
    </Stack>
  );
};
