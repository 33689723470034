import { useEffect, useState } from 'react';
import moment from 'moment';

import CommonUtils from 'utilities/common-utils';
import { IBooking } from 'interfaces/booking-interfaces';

const useBookingDuration = (booking: IBooking) => {
  const [duration, setDuration] = useState<string | null>(null);

  useEffect(() => {
    if (booking && booking.startDateTime && booking.endDateTime) {
      const startDateTime = moment(booking.startDateTime);
      const endDateTime = moment(booking.endDateTime);
      setDuration(CommonUtils.formatDurationString(startDateTime, endDateTime));
    }
  }, [booking]);

  return duration;
};

export default useBookingDuration;
