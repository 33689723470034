import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { DatePicker, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { FormComponentProps } from 'antd/es/form';
import moment, { Moment } from 'moment-timezone';

import { SubTitle, Text } from 'common-components/typography';
import TimeInput from 'common-components/time-input/TimeInput';
import { IFormElement, ITime } from 'views/form-builder/shared/form-interface';
import { getPropertyByKey, getRules, getValueFromPropertyByKey } from 'views/form-builder/shared/form-builder-utils';
import { FormElementType, ActionType, IntakeFormElementType } from 'views/form-builder/shared/form-enum';
import './date-time.css';
import { FormModeType } from '../../EditValueFormModal';

type DateTimeType = 'Date & time' | 'Date only' | 'Time only';
type DateConstraint = 'Allow past and future dates' | 'Allow past dates only' | 'Allow future dates only';

enum TypeDateTime {
  DateTime = 'Date & time',
  TimeOnly = 'Time only',
  DateOnly = 'Date only',
}

interface IDateTimeElementProps {
  value?: string;
  fieldTitle?: string;
  caption?: string;
  dateTimeType: DateTimeType;
  constraint: DateConstraint;
  isRequired?: boolean;
  timezone?: string;
  isDateOfBirth?: boolean;
}
export default function DateTimeElement({
  fieldTitle: fieldText,
  caption,
  dateTimeType,
  isRequired,
  timezone,
  isDateOfBirth,
}: IDateTimeElementProps) {
  const fieldTextFormat = (
    <>
      {fieldText}
      {isRequired ? <Text color="red"> *</Text> : ''}
    </>
  );

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bold">{fieldTextFormat}</SubTitle>
      <div className="mb-medium mt-x-small align-center flex-row">
        {dateTimeType === TypeDateTime.DateTime && (
          <>
            <DatePicker size="large" placeholder="Date" className="mr-medium date-picker-form-builder" />
            <TimeInput
              size="large"
              value={moment.tz(timezone)}
              className="text-color-tertiary time-picker-form-builder"
            />
            {!isDateOfBirth && (
              <Text size="medium" color="secondary" className="ml-small mr-small">
                UTC
                {moment.tz(timezone).format('Z')}
              </Text>
            )}
          </>
        )}
        {dateTimeType === TypeDateTime.DateOnly && (
          <>
            <DatePicker size="large" placeholder="Date" className="date-picker-form-builder" />
            {!isDateOfBirth && (
              <Text size="medium" color="secondary" className="ml-small mr-small">
                UTC
                {moment.tz(timezone).format('Z')}
              </Text>
            )}
          </>
        )}
        {dateTimeType === TypeDateTime.TimeOnly && (
          <>
            <TimeInput value={moment.tz(timezone)} className="text-color-tertiary time-picker-form-builder" />
            {!isDateOfBirth && (
              <Text size="medium" color="secondary" className="ml-small mr-small">
                UTC
                {moment.tz(timezone).format('Z')}
              </Text>
            )}
          </>
        )}
      </div>
      <Text color="secondary">{caption}</Text>
    </div>
  );
}

interface IEditDateTimeElementProps extends FormComponentProps {
  value?: any[];
  element: IFormElement;
  setTimes: (time: ITime[]) => void;
  elements: (IFormElement & { timeMoment?: Date | moment.Moment })[];
  timezone?: string;
  formMode?: FormModeType;
  onDateValueChanged?: (newTime: Date) => void; // newTime is the combined date and time entry value
}

const DATE_FORMAT = 'DD-MM-YYYY';

export const EditDateTimeElement = ({
  value = [],
  element,
  form,
  setTimes,
  elements,
  timezone = moment.tz.guess(),
  formMode,
  onDateValueChanged = () => null,
}: IEditDateTimeElementProps) => {
  // The format of the value is a bit weird. Thus this great init.
  const [date, setDate] = useState(moment(value[0] ?? value[1]).toDate());
  const [time, setTime] = useState(moment(value[2] ?? value[1] ?? value[0]).toDate());

  useEffect(() => {
    const joinedTime = new Date();
    joinedTime.setDate(date.getDate());
    joinedTime.setMonth(date.getMonth());
    joinedTime.setFullYear(date.getFullYear());
    joinedTime.setHours(time.getHours());
    joinedTime.setMinutes(time.getMinutes());

    onDateValueChanged(joinedTime);
  }, [date, time]);

  const { id, parentId } = element;
  const constraint: DateConstraint = getValueFromPropertyByKey(element.properties.configuration, 'constraint');
  const caption = getValueFromPropertyByKey(element.properties.appearance, 'captionText');
  const dateTimeType: DateTimeType = getValueFromPropertyByKey(element.properties.configuration, 'dateTimeType');
  const [dateValue, timeValue] = [value[0], value[2] ?? value[1]];

  const fieldProperty = getPropertyByKey(element.properties.general, 'fieldTitle');
  const isDateOfBirth = fieldProperty.fieldType === IntakeFormElementType.GENERAL_INFORMATION_DATE_OF_BIRTH;

  const rules = getRules(element, false);
  const isRequired = getValueFromPropertyByKey(element.properties.general, 'require');
  let fieldText = getValueFromPropertyByKey(element.properties.general, 'fieldTitle');

  const [timeChange, setTimeChange] = useState(timeValue || moment.tz(new Date(), timezone).format('YYYY-MM-DD HH:mm'));

  const { getFieldDecorator, getFieldError } = form;

  const isInvalidDate = Boolean(getFieldError(`${id},value`));

  const errorStyles = _.includes([ActionType.EDIT, ActionType.ADD], formMode) &&
    isInvalidDate && { marginTop: '-18px' };

  useEffect(() => {
    const foundIndex = elements.findIndex((element) => element.id === id);
    const initialTime = moment.tz(new Date(), timezone);

    elements[foundIndex] = {
      ...elements[foundIndex],
      timeMoment: initialTime,
    };
    const formatData = elements
      .filter((element) => element.type === FormElementType.DATE_TIME)
      .map(({ id, timeMoment }) => onFormatTime(id, timeMoment));

    setTimes(formatData);
  }, []);

  if (isRequired) {
    fieldText = (
      <>
        {fieldText}
        <Text color="red"> *</Text>
      </>
    );
  }

  const disabledDate = (current: Moment) => {
    const isPast = constraint === 'Allow past dates only';
    const isFuture = constraint === 'Allow future dates only';
    if (isPast) {
      return current.valueOf() >= Date.now();
    } else if (isFuture) {
      return current.valueOf() < Date.now();
    }
    return false;
  };

  const onFormatTime = (id: string, date: Date | moment.Moment): ITime => {
    const formatStringDate = moment.tz(date, timezone).format('YYYY-MM-DD HH:mm');
    const formatDate = formatStringDate ? moment.tz(formatStringDate, timezone) : moment.tz(timezone);

    return {
      [`${id},time`]: formatDate,
    };
  };

  const onChangeTime = (dateChange: Date) => {
    const foundIndex = elements.findIndex((item) => item.id === id);
    elements[foundIndex] = { ...elements[foundIndex], timeMoment: dateChange };
    const formatData = elements
      .filter((element) => element.type === FormElementType.DATE_TIME)
      .map(({ id, timeMoment }) => onFormatTime(id, timeMoment));

    setTimeChange(dateChange);
    setTimes(formatData);
    setTime(dateChange);
  };

  // Returns moment of date selected in number form
  const onChangeDate = (newDate: Moment | null) => {
    const dateFormatted = moment.tz(newDate, timezone).toDate();
    setDate(dateFormatted);
  };

  return (
    <div className="flex-column width-full">
      <SubTitle weight="bold">{fieldText}</SubTitle>
      <div className="mb-medium mt-x-small  align-center flex-row">
        {(dateTimeType === TypeDateTime.DateTime || dateTimeType === TypeDateTime.DateOnly) && (
          <FormItem className="m-none">
            {getFieldDecorator(`${id},value`, {
              initialValue: dateValue ? moment.tz(dateValue, timezone) : null,
              rules: rules,
            })(
              <DatePicker
                placeholder="Date"
                className="mr-medium date-picker-form-builder"
                format={DATE_FORMAT}
                disabledDate={disabledDate}
                size="large"
                onChange={onChangeDate}
              />,
            )}
          </FormItem>
        )}
        {(dateTimeType === TypeDateTime.DateTime || dateTimeType === TypeDateTime.TimeOnly) && (
          <div className="mr-small" style={errorStyles || { marginTop: '-1px' }}>
            <TimeInput
              size="large"
              className="line-height-150 time-picker-form-builder"
              value={timeChange ? moment.tz(timeChange, timezone) : null}
              onChange={onChangeTime}
            />
          </div>
        )}
        {!isDateOfBirth && (
          <Text size="medium" color="secondary" style={errorStyles}>
            UTC
            {moment.tz(timezone).format('Z')}
          </Text>
        )}
        <FormItem className="m-none">
          {getFieldDecorator(`${id},type`, {
            initialValue: FormElementType.DATE_TIME,
          })(<Input type="hidden" />)}
        </FormItem>

        <FormItem className="m-none">
          {getFieldDecorator([id, 'parentElementId'] as never, {
            initialValue: parentId,
          })(<Input type="hidden" />)}
        </FormItem>
      </div>
      <Text color="secondary">{caption}</Text>
    </div>
  );
};
