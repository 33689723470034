import { Icon, Row } from 'antd';
import React, { Component } from 'react';
import { BookingStatus } from 'utilities/enum-utils';

interface IDisturbanceInfoMessageProps {
  bookingStatus?: string;
}

class DisturbanceInfoMessage extends Component<IDisturbanceInfoMessageProps, any> {
  private _getTextInfoSleepoverDisturbances = (bookingStatus) => {
    switch (bookingStatus) {
      case BookingStatus.ACCEPTED:
      case BookingStatus.CONFIRMED:
        return 'Disturbances can be added once the booking has been started.';
      case BookingStatus.CUSTOMER_CANCELLED:
      case BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE:
      case BookingStatus.CUSTOMER_CANCELLED_WITH_FEE:
      case BookingStatus.BUSINESS_CANCELLED:
        return 'Disturbances cannot be added to cancelled bookings.';
      case BookingStatus.ARCHIVED:
        return 'Disturbances cannot be added to archived bookings.';
      case BookingStatus.COMPLETED:
        return 'Disturbances cannot be edited after a booking has been approved.';
      default:
        return;
    }
  };
  render() {
    const textInfoSleepoverDisturbances = this._getTextInfoSleepoverDisturbances(this.props.bookingStatus);
    return textInfoSleepoverDisturbances ? (
      <Row className={'p-medium mt-x-small bg-tertiary rounded'}>
        <div className="flex p-small">
          <div>
            <Icon type="info-circle" className="text-size-x2-large text-color-info-grey" />
          </div>
          <div className="ml-small pr-x-large">{textInfoSleepoverDisturbances}</div>
        </div>
      </Row>
    ) : null;
  }
}
export default DisturbanceInfoMessage;
