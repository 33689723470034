import { Text } from 'common-components/typography';
import React from 'react';

export function SessionGroupHeader({ dateKey }) {
  return (
    <div className="bg-quaternary bordered-left bordered-top bordered-right border-standard-gray ph-12 pv-12 inline-block rounded-big-top line-height-100">
      <Text size="large" lineHeight={100}>
        {/*Monday, 21 March 2021*/}
        {dateKey}
      </Text>
    </div>
  );
}
