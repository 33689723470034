import React, { useState, useEffect } from 'react';

import { Text } from '@good/components';
import { useSelector } from 'react-redux';
import Validation from 'common-components/alerts/Validation';
import { Warning } from 'common-components/alerts';
import { IRootState } from 'stores/rematch/root-store';

enum ServiceAgreementStatus {
  HAS_FOR_SINGLE_SESSION,
  IS_MISSING_FOR_SOME_SESSIONS,
  HAS_MULTIPLE,
  HAS_NONE,
}

function useServiceAgreementStatus() {
  const groupServiceServiceAgreements = useSelector(
    (state: IRootState) => state.groupServiceStore.groupServiceServiceAgreements,
  );
  const [status, setStatus] = useState<ServiceAgreementStatus>(null);

  useEffect(() => {
    const alertType_ = (() => {
      if (groupServiceServiceAgreements.length === 1 && groupServiceServiceAgreements[0].userServiceAgreementId) {
        return ServiceAgreementStatus.HAS_FOR_SINGLE_SESSION;
      }

      if (
        groupServiceServiceAgreements.length > 1 &&
        groupServiceServiceAgreements.some((group) => !group.userServiceAgreementId)
      ) {
        return ServiceAgreementStatus.IS_MISSING_FOR_SOME_SESSIONS;
      }

      if (
        groupServiceServiceAgreements.length > 1 &&
        groupServiceServiceAgreements.every((group) => group.userServiceAgreementId)
      ) {
        return ServiceAgreementStatus.HAS_MULTIPLE;
      }

      if (groupServiceServiceAgreements.every((group) => !group.userServiceAgreementId)) {
        return ServiceAgreementStatus.HAS_NONE;
      }

      return null;
    })();

    setStatus(alertType_);
  }, [groupServiceServiceAgreements]);

  return status;
}

const CustomerServiceAgreementAlert = () => {
  const serviceAgreementStatus = useServiceAgreementStatus();

  switch (serviceAgreementStatus) {
    case ServiceAgreementStatus.HAS_FOR_SINGLE_SESSION:
      return (
        <Validation
          content={<Text>The customer has a service agreement for the dates of the sessions.</Text>}
          className={'mb-small'}
        />
      );
    case ServiceAgreementStatus.IS_MISSING_FOR_SOME_SESSIONS:
      return (
        <Warning
          content={
            <Text>The customer does not have a service agreement for certain dates of the sessions being added.</Text>
          }
          className={'mb-small'}
        />
      );
    case ServiceAgreementStatus.HAS_MULTIPLE:
      return (
        <Warning
          content={<Text>The customer has multiple service agreements for the dates of the sessions.</Text>}
          className={'mb-small'}
        />
      );
    case ServiceAgreementStatus.HAS_NONE:
      return (
        <Warning
          content={<Text>The customer does not have a service agreement for the dates of the sessions.</Text>}
          className={'mb-small'}
        />
      );
    default:
      return null;
  }
};

export default CustomerServiceAgreementAlert;
