import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Gift = forwardRef(function Gift(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 2.75a1.75 1.75 0 1 1 0 3.5h-2.492l.659-2.196A1.83 1.83 0 0 1 15.42 2.75h.08Zm3.25 1.75c0 .644-.188 1.245-.51 1.75H19a2.75 2.75 0 0 1 .75 5.396v5.406c0 .899 0 1.648-.08 2.242-.084.628-.27 1.195-.726 1.65-.455.456-1.022.642-1.65.726-.594.08-1.344.08-2.242.08H8.948c-.898 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.726-.456-.455-.642-1.022-.726-1.65-.08-.594-.08-1.343-.08-2.242v-5.406A2.751 2.751 0 0 1 5 6.25h.76a3.25 3.25 0 0 1 2.74-5h.08a3.33 3.33 0 0 1 3.19 2.373l.23.767.23-.767a3.33 3.33 0 0 1 3.19-2.373h.08a3.25 3.25 0 0 1 3.25 3.25ZM5 10.25h6.25v-2.5H5a1.25 1.25 0 1 0 0 2.5Zm7.75 0H19a1.25 1.25 0 1 0 0-2.5h-6.25v2.5Zm-7 1.5V17c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.788.3.483.064 1.131.066 2.095.066h2.25v-8.5h-5.5Zm7 0v8.5H15c.964 0 1.612-.002 2.095-.067.461-.062.659-.169.789-.3.13-.13.237-.327.3-.788.064-.483.066-1.131.066-2.095v-5.25h-5.5Zm-1.758-5.5-.659-2.196A1.83 1.83 0 0 0 8.58 2.75H8.5a1.75 1.75 0 1 0 0 3.5h2.492Z"
        fill="currentColor"
      />
    </Icon>
  );
});
