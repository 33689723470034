import { createFormContext } from '@good/ui/form';
import { UpdatePayPeriod } from 'utilities/react-trpc';
import z from 'zod';

export const payPeriodSchema: z.ZodType<Omit<UpdatePayPeriod, 'serviceProviderId'>> = z
  .object({
    payPeriodStartDate: z.date().nullable(),
    payPeriodDuration: z
      .enum(['WEEKLY', 'MONTHLY', 'FORTNIGHTLY', 'TWICEMONTHLY', 'FOURWEEKLY', 'QUARTERLY'])
      .nullable(),
  })
  .refine(
    (data) => {
      return (
        (data.payPeriodStartDate === null && data.payPeriodDuration === null) ||
        (data.payPeriodStartDate !== null && data.payPeriodDuration !== null)
      );
    },
    {
      message: 'The pay period requires a start date and a duration.',
      path: ['payPeriodStartDate', 'payPeriodDuration'],
    },
  );


export type PayPeriodSchema = z.infer<typeof payPeriodSchema>;

export const [PayPeriodFormProvider, usePayPeriodFormContext, usePayPeriodForm] = createFormContext<PayPeriodSchema>();
