import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ListUnordered = forwardRef(function ListUnordered(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm3.25-1A.75.75 0 0 1 8 5.25h12a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 6ZM8 11.25a.75.75 0 0 0 0 1.5h12a.75.75 0 0 0 0-1.5H8Zm0 6a.75.75 0 0 0 0 1.5h12a.75.75 0 0 0 0-1.5H8ZM5 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
