import { css } from '../theme';

import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const reset: CSS = {
  boxSizing: 'border-box',
  outline: 'none',
};

export const inputField: CSS = {};

export const inputFieldVariants = css({
  display: 'flex',

  variants: {
    position: {
      top: {
        flexDirection: 'column',
      },
      side: {
        flexDirection: 'row',
      },
    },
  },
});

export type InputFieldVariants = Stitches.VariantProps<typeof inputFieldVariants>;
