import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IRootState } from 'stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';

function useWorkTypePermissions(): {
  canCreate: boolean;
  canUpdate: boolean;
} {
  const { portalUser } = useSelector(({ authStore }: IRootState) => authStore);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (!portalUser) return;

    setCanCreate(PermissionUtils.validatePermission('CreateWorkType', portalUser?.permissions.permissionRoles));
    setCanUpdate(PermissionUtils.validatePermission('UpdateWorkType', portalUser?.permissions.permissionRoles));
  }, [portalUser]);

  return {
    canCreate,
    canUpdate,
  };
}

export default useWorkTypePermissions;
