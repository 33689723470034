import { useContext } from 'react';
import { mergeProps } from '@react-aria/utils';

import type { Context } from 'react';

/**
 * @name useContextProps
 *
 * @description
 * Merge props with incoming context.
 *
 * @example
 * props = useContextProps(ButtonContext, props)
 */
export function useContextProps<T>(context: Context<T>, props: any) {
  const contextProps = useContext<T>(context) ?? ({} as Context<T>);
  return mergeProps(contextProps, props);
}
