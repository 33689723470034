import React from 'react';
import { Row } from '@tanstack/react-table';
import { startCase, toLower } from 'lodash';
import { format, isValid, parseISO } from 'date-fns';
import { ServiceAgreementTableData } from '../types';

type DateStatuses = 'UPCOMING' | 'ACTIVE' | 'EXPIRED' | 'INVALID';

const formatDate = (startDate: Date, endDate: Date) => {
  if (!isValid(startDate) || !isValid(endDate)) return 'Invalid date';

  const formattedStartDate = format(startDate, 'MMM do');
  const formattedEndDate = format(endDate, 'MMM do yyyy');

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const DateCell = (props: { row: Row<ServiceAgreementTableData> }) => {
  const { row } = props;
  const today = new Date();
  const startDate = parseISO(row.original.startDate);
  const endDate = parseISO(row.original.endDate);

  const dateStatusColors = {
    UPCOMING: '#B25719',
    ACTIVE: '#2B7B66',
    EXPIRED: '#AB2C2C',
    INVALID: '#AB2C2C',
  };

  let dateStatus: DateStatuses;

  if (today < startDate) {
    dateStatus = 'UPCOMING';
  } else if (today > endDate) {
    dateStatus = 'EXPIRED';
  } else if (today >= startDate && today <= endDate) {
    dateStatus = 'ACTIVE';
  } else {
    dateStatus = 'INVALID';
  }

  return (
    <>
      <div className="text-size-regular" style={{ color: `${dateStatusColors[dateStatus]}` }}>
        {startCase(toLower(dateStatus))}
      </div>
      <div className="text-size-x-small" style={{ color: '#706F6D' }}>
        {formatDate(startDate, endDate)}
      </div>
    </>
  );
};
