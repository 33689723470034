import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Link = forwardRef(function Link(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.602 4.399a5.75 5.75 0 0 0-8.132 0l-.707.707a.75.75 0 1 0 1.06 1.06l.707-.707a4.25 4.25 0 0 1 6.01 6.01l-1.685 1.687a3.865 3.865 0 0 1-5.467 0l-.272-.272a.75.75 0 0 0-1.06 1.06l.272.272a5.365 5.365 0 0 0 7.587 0l1.687-1.686a5.75 5.75 0 0 0 0-8.131ZM7.146 10.844a3.865 3.865 0 0 1 5.466 0l.272.272a.75.75 0 0 0 1.06-1.06l-.271-.272a5.365 5.365 0 0 0-7.588 0L4.399 11.47A5.75 5.75 0 1 0 12.53 19.6l.707-.707a.75.75 0 0 0-1.061-1.06l-.707.707a4.25 4.25 0 1 1-6.01-6.01l1.686-1.687Z"
        fill="currentColor"
      />
    </Icon>
  );
});
