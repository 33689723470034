import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Dollar = forwardRef(function Dollar(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 3a.75.75 0 1 0-1.5 0v1.25H9.906c-2.053 0-3.656 1.737-3.656 3.801 0 1.613.984 3.076 2.489 3.602l2.51.88v5.717h-.916c-1.392 0-2.583-1.196-2.583-2.75a.75.75 0 0 0-1.5 0c0 2.312 1.794 4.25 4.083 4.25h.917V21a.75.75 0 0 0 1.5 0v-1.25h1.344c2.053 0 3.656-1.736 3.656-3.8 0-1.614-.984-3.076-2.49-3.603l-2.51-.879V5.75h.917c1.392 0 2.583 1.196 2.583 2.75a.75.75 0 0 0 1.5 0c0-2.312-1.794-4.25-4.083-4.25h-.917V3Zm-1.5 2.75H9.906c-1.157 0-2.156.995-2.156 2.301 0 1.014.617 1.883 1.485 2.187l2.015.705V5.75Zm1.5 7.307v5.193h1.344c1.157 0 2.156-.995 2.156-2.3 0-1.015-.617-1.884-1.485-2.187l-2.015-.706Z"
        fill="currentColor"
      />
    </Icon>
  );
});
