import { Select } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import { ICard } from 'interfaces/subscription-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import {
  EDIT_CARD_FORM_ELEMENT,
  YEAR_INCREASE,
} from 'views/account-management/subscription-management/utils/constants';

interface IEditCardModalProps extends FormComponentProps {
  isOpen: boolean;
  loading?: boolean;
  cardDetail?: ICard;
  onClose?: () => void;
  onUpdate?: (payload) => void;
}

const EditCardModal = (props: IEditCardModalProps) => {
  const { isOpen, onClose, loading, onUpdate, form, cardDetail } = props;

  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);

  const currentTime = moment.tz(new Date(), portalUser?.timezone);

  const currentYear = Number(currentTime.format('YYYY'));
  const currentMonth = Number(currentTime.format('MM'));

  const _validateExpiry = (rule?, value?, callback?) => {
    try {
      const expMonth = form.getFieldValue(EDIT_CARD_FORM_ELEMENT.EXPIRY_MONTH);
      const expYear = form.getFieldValue(EDIT_CARD_FORM_ELEMENT.EXPIRY_YEAR);

      if (currentYear === expYear && expMonth < currentMonth) {
        if (rule.field === EDIT_CARD_FORM_ELEMENT.EXPIRY_MONTH) {
          throw Error('Your card’s expiry month is invalid');
        } else {
          form.setFields({
            [EDIT_CARD_FORM_ELEMENT.EXPIRY_MONTH]: {
              value: expMonth,
              errors: [new Error('Your card’s expiry month is invalid')],
            },
          });
        }
      } else {
        form.setFields({
          [EDIT_CARD_FORM_ELEMENT.EXPIRY_MONTH]: {
            value: expMonth,
            errors: null,
          },
        });
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  const _onSubmit = () => {
    const expMonth = form.getFieldValue(EDIT_CARD_FORM_ELEMENT.EXPIRY_MONTH);
    const expYear = form.getFieldValue(EDIT_CARD_FORM_ELEMENT.EXPIRY_YEAR);
    form.validateFields((error) => {
      if (error) return;
      onUpdate({ expMonth, expYear });
    });
  };

  const { getFieldDecorator } = form;

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      title="Edit card"
      canCloseOutside
      verticalAlignment="center"
      width="medium"
    >
      <div className="mt-medium flex">
        <div>
          <SubTitle color="black">expiry month</SubTitle>
          <Form.Item className="m-none">
            {getFieldDecorator(EDIT_CARD_FORM_ELEMENT.EXPIRY_MONTH, {
              rules: [{ validator: _validateExpiry }],
              initialValue: cardDetail?.expMonth,
            })(
              <Select className="mt-12" size="large" style={{ minWidth: '220px' }}>
                {_.map(_.range(1, 13), (number, key) => {
                  return (
                    <Select.Option key={key} value={number}>
                      <Text size="regular">{number < 10 ? '0' + number : number}</Text>
                    </Select.Option>
                  );
                })}
              </Select>,
            )}
          </Form.Item>
        </div>
        <div className="ml-x-large">
          <SubTitle color="black">expiry year</SubTitle>
          <Form.Item className="m-none color">
            {getFieldDecorator(EDIT_CARD_FORM_ELEMENT.EXPIRY_YEAR, {
              rules: [{ validator: _validateExpiry }],
              initialValue: cardDetail?.expYear,
            })(
              <Select className="mt-12" size="large" style={{ minWidth: '220px', borderColor: 'red' }}>
                {_.map(_.range(currentYear, currentYear + YEAR_INCREASE), (number, key) => {
                  return (
                    <Select.Option key={key} value={number}>
                      <Text size="regular">{number}</Text>
                    </Select.Option>
                  );
                })}
              </Select>,
            )}
          </Form.Item>
        </div>
      </div>
      <ActionModalFooter className="mt-x-large">
        <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          size="large"
          disabled={form.getFieldError(EDIT_CARD_FORM_ELEMENT.EXPIRY_MONTH)}
          onClick={_onSubmit}
          loading={loading}
        >
          Update
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};

export default Form.create<IEditCardModalProps>()(EditCardModal);
