import { Popover2 } from '@blueprintjs/popover2';
import { Col, Icon, Row } from 'antd';
import { SecondaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { ISessionTimeSlot } from 'interfaces/session-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import moment from 'moment';
import React from 'react';
import { state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { StaffingSessionStatus, StaffingStatus } from 'utilities/enum-utils';

interface IStaffingStatusIconProps {
  staffingStatus: StaffingStatus;
}

function StaffingStatusIcon({ staffingStatus }: IStaffingStatusIconProps) {
  let iconType = 'check';
  let bgClass = 'bg-green-lightest';
  let txtClass = 'text-color-green';

  switch (staffingStatus) {
    case StaffingStatus.CORRECTLY_STAFFED:
      iconType = 'check';
      bgClass = 'bg-green-lightest';
      txtClass = 'text-color-green';
      break;
    case StaffingStatus.UNDERSTAFFED:
      iconType = 'arrow-down';
      bgClass = 'bg-orange-lightest';
      txtClass = 'text-color-orange';
      break;
    case StaffingStatus.OVERSTAFFED:
      iconType = 'arrow-up';
      bgClass = 'bg-red-lightest';
      txtClass = 'text-color-red';
      break;
  }

  return (
    <div
      className={`inline-block rounded-full flex-column align-center justify-center text-align-center line-height-135 mr-small ${bgClass}`}
      style={{ height: '24px', width: '24px' }}
    >
      <Icon type={iconType} className={txtClass} style={{ fontSize: '12px' }} />
    </div>
  );
}

interface IStaffingTimeSlotProps {
  staffingStatus: StaffingStatus;
  sessionTimeSlot: ISessionTimeSlot;
  timezone: timeZone;
}

const MAXIMUM_TIME_SLOTS = 3;

function StaffingTimeSlot(props: IStaffingTimeSlotProps) {
  const { staffingStatus, sessionTimeSlot, timezone } = props;
  const { totalMinutes, listTimeSlots } = sessionTimeSlot;
  const formatTimeSlots = listTimeSlots.slice(0, MAXIMUM_TIME_SLOTS);
  let statusText = 'optimally staffed';

  switch (staffingStatus) {
    case StaffingStatus.OVERSTAFFED:
      statusText = 'overstaffed';
      break;
    case StaffingStatus.UNDERSTAFFED:
      statusText = 'understaffed';
      break;
    case StaffingStatus.CORRECTLY_STAFFED:
      statusText = 'optimally staffed';
      break;
  }

  return (
    <Row className="flex align-center mb-12">
      <Col>
        <StaffingStatusIcon staffingStatus={staffingStatus} />
      </Col>
      <Col span={6}>
        <Text size="x-large" lineHeight={120}>
          <b>{CommonUtils.formatDurationFromMinutes(totalMinutes)}</b> {statusText}
        </Text>
      </Col>
      <Col>
        <Text size="regular" color="secondary">
          {formatTimeSlots.map((timeSlot, index) => {
            const startDateMoment = moment.tz(timeSlot.startDateTime, timezone);
            const endDateMoment = moment.tz(timeSlot.endDateTime, timezone);

            if (index < formatTimeSlots.length - 1) {
              return `${startDateMoment.format('h:mm a')} - ${endDateMoment.format('h:mm a')} | `;
            } else {
              return `${startDateMoment.format('h:mm a')} - ${endDateMoment.format('h:mm a')} `;
            }
          })}
        </Text>
        {listTimeSlots.length > MAXIMUM_TIME_SLOTS && (
          <Popover2
            content={
              <div className="p-medium pb-small">
                {listTimeSlots.slice(MAXIMUM_TIME_SLOTS).map((timeSlot) => {
                  const startDateMoment = moment.tz(timeSlot.startDateTime, timezone);
                  const endDateMoment = moment.tz(timeSlot.endDateTime, timezone);

                  return (
                    <div className="text-size-regular text-color-secondary mb-small" key={endDateMoment.toString()}>
                      {startDateMoment.format('h:mm a')} - {endDateMoment.format('h:mm a')}
                    </div>
                  );
                })}
              </div>
            }
            placement="top"
            interactionKind="hover"
          >
            <Text size="regular" weight="bold" color="secondary" className="cursor-pointer">
              and {listTimeSlots.length - MAXIMUM_TIME_SLOTS} more...
            </Text>
          </Popover2>
        )}
      </Col>
    </Row>
  );
}

interface IRecommendedTeamMembersPaneProps {
  recommendedTotalShiftSlot?: number;
  totalAssignedShiftSlot?: number;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  onOpenViewDetailModal: () => void;
}

export function RecommendedTeamMembersPane(props: IRecommendedTeamMembersPaneProps) {
  const { onOpenViewDetailModal, selectedSession } = props;
  const { sessionTimeSlots, timezone } = selectedSession;
  let borderLeftColor = '#0F9960';

  switch (selectedSession.staffingStatus) {
    case StaffingSessionStatus.VERY_OVERSTAFFED:
    case StaffingSessionStatus.MOSTLY_OVERSTAFFED:
    case StaffingSessionStatus.SLIGHTLY_OVERSTAFFED:
    case StaffingSessionStatus.UNDERSTAFFED_AND_OVERSTAFFED:
      borderLeftColor = '#DB3737';
      break;
    case StaffingSessionStatus.VERY_UNDERSTAFFED:
    case StaffingSessionStatus.MOSTLY_UNDERSTAFFED:
    case StaffingSessionStatus.SLIGHTLY_UNDERSTAFFED:
      borderLeftColor = '#D9822B';
      break;
    case StaffingSessionStatus.OPTIMALLY_STAFFED:
      borderLeftColor = '#0F9960';
      break;
    default:
      if (sessionTimeSlots[StaffingStatus.OVERSTAFFED]) {
        borderLeftColor = '#DB3737';
      } else if (sessionTimeSlots[StaffingStatus.UNDERSTAFFED]) {
        borderLeftColor = '#D9822B';
      } else {
        borderLeftColor = '#0F9960';
      }
      break;
  }

  return (
    <div
      className="p-medium rounded shadow-container bg-white flex-1 mb-large line-height-120 bordered-left"
      style={{
        borderLeftWidth: '4px',
        borderLeftColor: borderLeftColor,
      }}
    >
      {/* Counter */}
      <div className="flex align-center">
        <div className="width-full">
          <div className="mb-12">
            <Text size="large" lineHeight={120} weight="bold">
              Staffing overview
            </Text>
          </div>

          {sessionTimeSlots[StaffingStatus.OVERSTAFFED] && (
            <StaffingTimeSlot
              staffingStatus={StaffingStatus.OVERSTAFFED}
              sessionTimeSlot={sessionTimeSlots[StaffingStatus.OVERSTAFFED]}
              timezone={timezone}
            />
          )}

          {sessionTimeSlots[StaffingStatus.UNDERSTAFFED] && (
            <StaffingTimeSlot
              staffingStatus={StaffingStatus.UNDERSTAFFED}
              sessionTimeSlot={sessionTimeSlots[StaffingStatus.UNDERSTAFFED]}
              timezone={timezone}
            />
          )}

          {sessionTimeSlots[StaffingStatus.CORRECTLY_STAFFED] && (
            <StaffingTimeSlot
              staffingStatus={StaffingStatus.CORRECTLY_STAFFED}
              sessionTimeSlot={sessionTimeSlots[StaffingStatus.CORRECTLY_STAFFED]}
              timezone={timezone}
            />
          )}
        </div>

        <SecondaryButton size="large" onClick={onOpenViewDetailModal}>
          View details
        </SecondaryButton>
      </div>
    </div>
  );
}
