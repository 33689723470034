import { IBatch, IData, SupportedFinanceExportConfigStyle } from './constants';

export class ExportBatchFormatMapper {
  public static formatBatches = (
    batches: IBatch[],
    financeStyle: SupportedFinanceExportConfigStyle,
  ): string | IData[] => {
    return this._financeConfigStyleFormatter(batches, financeStyle);
  };

  private static _financeConfigStyleFormatter = (
    batches: IBatch[],
    financeStyle: SupportedFinanceExportConfigStyle,
  ): string | IData[] => {
    switch (financeStyle) {
      case SupportedFinanceExportConfigStyle.MD365:
        return this._md365Format(batches);
      case SupportedFinanceExportConfigStyle.MYOB:
      case SupportedFinanceExportConfigStyle.KNOX_BROOKE_MYOB:
        return this._myobFormat(batches);
      case SupportedFinanceExportConfigStyle.TENCIA:
      case SupportedFinanceExportConfigStyle.WISEFINANCE:
      case SupportedFinanceExportConfigStyle.XERO:
      case SupportedFinanceExportConfigStyle.QUICKBOOKS:
      case SupportedFinanceExportConfigStyle.XERO_FIGHTING_CHANCE:
      case SupportedFinanceExportConfigStyle.CAREVIEW:
        return this._careviewFormat(batches);
      case SupportedFinanceExportConfigStyle.NDIA:
      case SupportedFinanceExportConfigStyle.RECKON:
      case SupportedFinanceExportConfigStyle.GOODHUMAN_ALL:
        return this._batchFormatMapper(batches);
      default:
        return '';
    }
  };
  private static _careviewFormat = (batches: IBatch[]) => {
    const result: IData[] = [];
    batches.forEach((batch) => {
      const data = batch.data as IData[];
      result.push(...data);
    });
    const emptyRow = {
      OrgId: '',
      ClientNDIS: '',
      ClientFullName: '',
      ServiceProviderName: '',
      ServiceProviderABN: '',
      InvoiceNumber: '',
      InvoiceDate: '',
      InvoiceTotal: '',
      InvoiceLineDeliveredDate: '',
      InvoiceLineFromDate: '',
      InvoiceLineToDate: '',
      InvoiceLineServiceSupportItem: '',
      InvoiceLineHours: '',
      InvoiceLineQuantity: '',
      InvoiceLineRate: '',
      InvoiceLineTotal: '',
      InvoiceLineGSTCode: '',
      InvoiceLineClaimType: '',
      InvoiceLineCancellationReason: '',
    };
    return result.concat(emptyRow);
  };

  private static _myobFormat = (batches: IBatch[]) => {
    const result = [];
    batches.forEach((batch) => {
      const strCsv = (batch.data as string).endsWith('\r\n\r\n') ? batch.data : `${batch.data}\r\n\r\n`;
      result.push(strCsv);
    });
    let strCsv = '';
    result.forEach((data: string, index) => {
      if (index === 0) {
        strCsv += data;
      } else {
        const headerIndex = data.indexOf('\n');
        strCsv += data.slice(headerIndex + 1);
      }
    });
    return strCsv;
  };

  private static _batchFormatMapper = (batches: IBatch[]) => {
    const result: IData[] = [];
    batches.forEach((batch) => {
      const data = batch.data as IData[];
      result.push(...data);
    });
    return result;
  };

  private static _md365Format = (batches: IBatch[]) => {
    let result = '';
    batches.forEach((batch, index) => {
      const data = batch.data as string;
      if (index === 0) {
        result += data;
      } else {
        result += `\n ${data}`;
      }
    });
    return result;
  };
}
