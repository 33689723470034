import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Compass = forwardRef(function Compass(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 12a8.25 8.25 0 1 1 16.5 0 8.25 8.25 0 0 1-16.5 0ZM12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.197 6.529a.75.75 0 0 0-.975-.975l-5 2a.75.75 0 0 0-.418.418l-2 5a.75.75 0 0 0 .975.974l5-2a.75.75 0 0 0 .418-.418l2-5Zm-6.35 5.375 1.23-3.077 3.077-1.23-1.23 3.076-3.078 1.23Z"
        fill="currentColor"
      />
    </Icon>
  );
});
