import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import React from 'react';
import { ROSTER_TAB } from 'views/bookings/roster-view/common/roster-common-enums';

export function RosterViewSelector({ selectedTab, onSelectTab }: { onSelectTab: any; selectedTab: string }) {
  const DailyButton = selectedTab === ROSTER_TAB.DAILY ? PrimaryButton : SecondaryButton;
  const WeeklyButton = selectedTab === ROSTER_TAB.WEEKLY ? PrimaryButton : SecondaryButton;

  return (
    <div className="flex-row">
      <DailyButton size="large" className="rounded-left" onClick={() => onSelectTab(ROSTER_TAB.DAILY)}>
        Day
      </DailyButton>

      <WeeklyButton size="large" className="rounded-right" onClick={() => onSelectTab(ROSTER_TAB.WEEKLY)}>
        Week
      </WeeklyButton>
    </div>
  );
}
