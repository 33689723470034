import { useEffect } from 'react';

import { useIsMobile } from 'utilities/react-hooks/use-is-mobile';

/**
 * Custom hook that automatically closes the mobile navbar when the screen is not in mobile mode.
 * @param closeNavbarMobile - Function to close the mobile navbar.
 */
export function useAutoCloseNavbarMobile(closeNavbarMobile: () => void): void {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isMobile) {
      closeNavbarMobile();
    }
  }, [isMobile, closeNavbarMobile]);
}
