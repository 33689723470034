import { useInView } from 'react-intersection-observer';
import React, { useEffect } from 'react';

interface LoaderElementProps {
  onFetch: any;
  hasMore?: boolean;
  isFetching: boolean;
  height?: number;
}

export function LoaderElement({ onFetch = () => {}, isFetching, hasMore = false, height = 64 }: LoaderElementProps) {
  // Whenever this element is visible, fetch more data
  const { ref, inView } = useInView();

  // This effect will trigger whenever the loading element is in view.
  useEffect(() => {
    // If the loading element is in view, and we're not already fetching more, then fetch more.
    if (inView && !isFetching && hasMore) {
      onFetch();
    }
  }, [inView, isFetching, hasMore, onFetch]);

  return (
    <div
      className="bg-quaternary bordered-bottom"
      ref={ref}
      style={{ minHeight: `${height}px`, maxHeight: `${height}px` }}
    >
      &nbsp;
    </div>
  );
}
