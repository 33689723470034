import { forwardRef } from 'react';
import { Illustration } from './illustration';
import type { IllustrationProps } from './illustration';
import type { Ref } from 'react';
export const Quality = forwardRef(function Quality(props: IllustrationProps, ref: Ref<SVGSVGElement>) {
  return (
    <Illustration {...props} ref={ref}>
      <path
        d="M91.031 57.658c.368 16.502.746 33.007 1.095 49.509.062 2.913.024 5.828 0 8.744-.018 2.334-1.273 4.099-3.33 4.766-2.808.911-5.77 1.712-8.696 1.824-10.556.406-21.126.542-31.69.696-2.491.037-4.985-.242-7.476-.407-1.716-.114-2.475-.902-2.382-2.769.583-11.808.224-23.597-1.474-35.3-1.513-10.404-1.465-20.815-.783-31.258.056-.85.176-1.704.365-2.533.768-3.337 2.07-4.418 5.502-4.41 4.06.008 8.142-.106 12.179.236 5.278.449 10.161-1.24 15.155-2.318 1.05-.226 2.23-1.197 2.778-2.153 3.219-5.597 3.203-15.485-3.042-20.525-2.308-1.861-5.907-2.156-8.329-.38-1.777 1.304-3.693 2.6-4.99 4.315-.869 1.147-.97 3.014-1.058 4.578-.19 3.372-.416 3.85-3.81 4.482-1.804.334-3.693.66-5.491.483-3.957-.393-6.067-3.062-6.232-7.044-.245-5.945 2.187-10.435 7.14-13.619 3.203-2.058 5.713-5.188 9.653-6.115 5.968-1.402 11.936-2.166 17.966-.366.34.1.754.111 1.015.316 3.885 3.027 8.225 5.68 9.82 10.745 1.514 4.812 3.182 9.602 4.248 14.516.93 4.286 1.279 8.72 1.582 13.11.248 3.61.051 7.25.051 10.877h.234Zm-30.834 63.858v.093c3.783 0 7.599.303 11.342-.074 4.86-.494 9.853.286 14.59-1.612 2.92-1.171 3.858-2.353 3.765-5.573-.299-10.157-.613-20.313-.81-30.469-.216-11.157-.094-22.325-.538-33.471-.24-6.006-1.09-12.028-2.172-17.95-.8-4.378-2.414-8.608-3.674-12.901-1.43-4.864-5.688-7.148-9.28-10.029-.25-.199-.674-.201-1.023-.255-8.214-1.266-16.135-1.255-22.591 5.149-.197.196-.46.337-.714.465-3.653 1.85-5.957 4.925-7.09 8.69-.906 3.006-1.06 6.133 1.282 8.858 2.006 2.334 4.404 1.28 6.751 1.078 1.996-.173 2.47-1.193 2.52-3.13.049-1.897.486-3.984 1.426-5.595.845-1.447 2.64-2.331 3.96-3.521 3.732-3.364 10.372-3.237 13.678-.042 6.179 5.968 6.275 15.538 2.465 22.038-.677 1.155-2.25 2.211-3.579 2.49-4.931 1.03-9.911 2.029-14.92 2.435-4.239.345-8.545-.165-12.82-.258-2.53-.055-3.531.537-3.664 3.128-.416 8.011-.96 16.048-.704 24.048.23 7.15 1.745 14.254 2.166 21.407.445 7.578.31 15.193.328 22.789.003 1.516.376 2.238 1.988 2.228 5.773-.037 11.547-.013 17.32-.013l-.002-.003Z"
        fill="currentColor"
      />
      <path
        d="M74.88 78.106c1.604 3.067 2.185 6.256 1.252 9.657-1.252 4.57-5.72 8.534-10.508 9.184-5.03.683-10.684-1.97-13.01-6.107-2.56-4.556-2.12-10.251 1.1-14.202 3.555-4.36 10.666-5.826 16.027-3.117 1.396.706 2.264.671 3.325-.42 1.087-1.118 2.411-2.005 3.5-3.122 1.037-1.065 1.898-.71 2.764.085 2.235 2.05 2.15 2.923-.31 4.755-1.364 1.017-2.666 2.114-4.137 3.284l-.003.003Zm4.015-6.194-.503-.314c-5.758 4.556-11.166 9.442-14.508 15.878-2.307.03-3.173-2.846-5.699-1.691 1.81 1.354 3.619 2.71 5.585 4.182 3.842-6.713 8.28-12.453 14.43-16.712.368-.255.471-.887.698-1.343h-.003Z"
        fill="currentColor"
      />
    </Illustration>
  );
});
