import { v4 as uuid } from 'uuid';
import customerSource from 'variables/data/customer-source.json';
import {
  AFFECT_TO_ELEMENT_PROPERTY,
  CAPTION_TEXT_PROPERTY,
  EVENT_PROPERTY,
  FIELD_TITLE_PROPERTY,
  PLACEHOLDER_PROPERTY,
  REPEATABLE_PROPERTY,
  REQUIRED_PROPERTY,
  RULES_PROPERTY,
  TARGET_ELEMENT_PROPERTY,
  TARGET_OPTION_PROPERTY,
} from '../common-field-constants';
import { LabelMultipleElements, LabelOption } from '../common-label-constants';
import { FormElementType, IntakeFormElementType, PropType, UserActionType } from '../form-enum';
import { IFormElement } from '../form-interface';

//#region Requested Services
const REQUESTED_SERVICEDS: IFormElement = {
  id: uuid(),
  name: 'Requested services',
  type: FormElementType.DROPDOWN_MULTI_SELECT,
  label: LabelOption,
  isHideConfig: true,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'What services are you enquiring about?',
        fieldType: IntakeFormElementType.ENQUIRIES_REQUESTED_SERVICES,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY, { ...PLACEHOLDER_PROPERTY, value: 'Select 1 or more services' }],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: [],
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
      {
        key: 'defaultOption',
        name: 'DEFAULT VALUE',
        value: [],
        caption: 'Default selected value for this input field.',
        type: PropType.defaultOption,
      },
    ],
  },
};
//#endregion

//#region Customer Sources

const CUSTOMER_SOURCE_ELEMENT_ID = uuid();
const CUSTOMER_SOURCE_DROPDOWN_ELEMENT_ID = uuid();
const CUSTOMER_SOURCE_TEXT_INPUT_ELEMENT_ID = uuid();

const CUSTOMER_SOURCE_DROPDOWN_ELEMENT: IFormElement = {
  id: CUSTOMER_SOURCE_DROPDOWN_ELEMENT_ID,
  parentId: CUSTOMER_SOURCE_ELEMENT_ID,
  name: 'Customer Source',
  type: FormElementType.SINGLE_CHOICE,
  isHideConfig: true,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'How did you find out about us? ',
        fieldType: IntakeFormElementType.ENQUIRIES_CUSTOMER_SOURCE,
      },
      REQUIRED_PROPERTY,
    ],
    appearance: [CAPTION_TEXT_PROPERTY],
    configuration: [
      {
        key: 'options',
        name: 'OPTIONS',
        value: customerSource.map((source) => ({
          value: source.tagId,
          name: source.tag,
        })),
        caption: 'Option that will be shown for this input field.',
        type: PropType.options,
      },
      {
        key: 'defaultOption',
        name: 'DEFAULT VALUE',
        value: 'No default',
        caption: 'Default selected value for this input field.',
        type: PropType.defaultOption,
      },
    ],
  },
};
const CUSTOMER_SOURCE_INPUT_ELEMENT: IFormElement = {
  id: CUSTOMER_SOURCE_TEXT_INPUT_ELEMENT_ID,
  parentId: CUSTOMER_SOURCE_ELEMENT_ID,
  name: 'Other Source',
  type: FormElementType.SHORT_TEXT,
  properties: {
    general: [
      {
        ...FIELD_TITLE_PROPERTY,
        value: 'Please specify other source',
        fieldType: IntakeFormElementType.ENQUIRIES_OTHER_SOURCE,
      },
      REQUIRED_PROPERTY,
      {
        ...RULES_PROPERTY,
        rules: [{ max: 250, message: 'The maximum length of this field is 250 characters' }],
      },
    ],
    appearance: [{ ...PLACEHOLDER_PROPERTY, value: 'Enter other source' }, CAPTION_TEXT_PROPERTY],
    configuration: [],
  },
};

const CUSTOMER_SOURCE_CONDITIONAL_ELEMENT_ID = uuid();
const CUSTOMER_SOURCE_CONDITIONAL_ELEMENT: IFormElement = {
  id: uuid(),
  parentId: CUSTOMER_SOURCE_ELEMENT_ID,
  name: 'Conditional',
  type: FormElementType.CONDITIONAL,
  properties: {
    general: [],
    appearance: [],
    configuration: [
      {
        ...TARGET_ELEMENT_PROPERTY,
        value: CUSTOMER_SOURCE_DROPDOWN_ELEMENT_ID,
        options: [CUSTOMER_SOURCE_DROPDOWN_ELEMENT],
      },
      {
        ...TARGET_OPTION_PROPERTY,
        value: 10015,
        options: customerSource.map((source) => ({
          value: source.tagId,
          name: source.tag,
        })),
      },
      {
        ...EVENT_PROPERTY,
        value: UserActionType.ON_SELECT,
        options: [UserActionType.ON_SELECT],
      },
      {
        ...AFFECT_TO_ELEMENT_PROPERTY,
        value: [CUSTOMER_SOURCE_TEXT_INPUT_ELEMENT_ID],
        options: [CUSTOMER_SOURCE_INPUT_ELEMENT],
      },
      REPEATABLE_PROPERTY,
    ],
  },
};

const CUSTOMER_SOURCE_BUNDLE_ELEMENT: IFormElement = {
  id: CUSTOMER_SOURCE_ELEMENT_ID,
  name: 'Customer source',
  type: FormElementType.MULTIPLE_ELEMENT,
  label: LabelMultipleElements,
  isHideConfig: true,
  properties: { general: [REQUIRED_PROPERTY], appearance: [], configuration: [] },
  children: [
    CUSTOMER_SOURCE_DROPDOWN_ELEMENT,
    { ...CUSTOMER_SOURCE_CONDITIONAL_ELEMENT, conditionId: CUSTOMER_SOURCE_CONDITIONAL_ELEMENT_ID },
    { ...CUSTOMER_SOURCE_INPUT_ELEMENT, conditionId: CUSTOMER_SOURCE_CONDITIONAL_ELEMENT_ID },
  ],
};
//#endregion

export const ENQUIRIES_ELEMENTS: IFormElement[] = [REQUESTED_SERVICEDS, CUSTOMER_SOURCE_BUNDLE_ELEMENT];
