import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from '@good/components';

import { SilUrlParams, routeDefs, useSilServiceRoutes } from 'views/sil-service/routes';
import { SilNotFoundTab } from './sil-not-found-tab';
import { SilServicePanelPage } from './sil-service-panel-page';

export const SilServiceTabs = () => {
  const { routes } = useSilServiceRoutes();
  const { tabName } = useParams<SilUrlParams>();

  return (
    <Tabs selectedKey={tabName}>
      <TabList aria-label='Service Tabs'>
        {routes.map((route) => {
          const { path } = route;

          return (
            <Tab id={path} key={path} href={path}>
              {route.title}
            </Tab>
          );
        })}
      </TabList>
      <TabPanel id={tabName} className='flex flex-col h-full w-full mt-8 outline-none'>
        <Switch>
          {routeDefs.map(({ path, element }) => {
            if (!path) {
              return null;
            }
            return (
              <Route path={`/service/sil/:serviceId/${path}`} key={path}>
                <SilServicePanelPage element={element} />
              </Route>
            );
          })}
          <Route>
            <SilNotFoundTab />
          </Route>
        </Switch>
      </TabPanel>
    </Tabs>
  );
};
