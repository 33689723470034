import { PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React from 'react';
import { ExportFileConfigType } from '../../utils/account-management-constants';

interface Props {
  isOpen?: boolean;
  isSetDefaultFirstTime?: boolean;
  isConfigPage?: boolean;
  type?: ExportFileConfigType;
  isChangedStyleKey?: boolean;
  onClose?: () => void;
}

const ChangeDefaultSuccessModal = ({
  isOpen,
  onClose,
  isConfigPage,
  isSetDefaultFirstTime,
  type,
  isChangedStyleKey,
}: Props) => {
  const isAccountSystem = type === ExportFileConfigType.ACCOUNT_SYSTEM;
  const actionName = isSetDefaultFirstTime ? (isConfigPage && !isChangedStyleKey ? 'saved' : 'set') : 'changed';

  return (
    <ActionModal
      isOpen={isOpen}
      title={`Your default export has been ${actionName}`}
      width={isSetDefaultFirstTime && !isConfigPage ? 'small' : 590}
      onClose={onClose}
    >
      <Paragraph className="mt-medium">
        Your default {isAccountSystem ? 'finance' : 'timesheet'} export template has been successfully {actionName}.
        Default settings can be managed {isConfigPage && 'here '}in Account &gt; Feature Configuration.
      </Paragraph>
      <ActionModalFooter>
        <PrimaryButton size="large" onClick={onClose}>
          Close
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};

export default ChangeDefaultSuccessModal;
