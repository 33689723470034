import React, { useRef, useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Box, Button, Icons, Separator, Stack } from '@goodhuman-me/components';
import { useTranslation } from 'react-i18next';

import { SupportCategorySummary } from '../../../../../components';

import type { BudgetCategory } from '../../../../../budget';

export type SupportItemsProps = BudgetCategory;

export function SupportItems(props: BudgetCategory): JSX.Element {
  let { name, supportItemTracking } = props;
  let supportItems = Object.entries(supportItemTracking);
  let supportItemsCount = supportItems.length;

  let { t } = useTranslation('', { keyPrefix: 'budget.category.supportItems' });
  let [isOpen, setOpen] = useState(false);
  let collapsibleRef = useRef(null);

  function handleOpenChange() {
    setOpen(!isOpen);
  }

  return (
    <Collapsible.Root defaultOpen={isOpen} open={isOpen} onOpenChange={handleOpenChange} ref={collapsibleRef}>
      {supportItemsCount > 0 && (
        <Stack gap="$space200" marginTop="$space200">
          <Collapsible.Trigger asChild>
            <Button emphasis="quiet" size="small" width="fit-content" alignSelf="flex-start">
              {isOpen ? <Icons.ChevronTop width="1em" /> : <Icons.ChevronBottom width="1em" />}
              {t('toggle', { context: isOpen ? 'open' : 'closed', count: supportItemsCount })}
            </Button>
          </Collapsible.Trigger>

          <Collapsible.Content>
            <Stack gap="$space400">
              {supportItemsCount > 0 && (
                <Box
                  borderStyle="solid"
                  borderWidth="$thick"
                  borderRadius="$medium"
                  borderColor="$bodyLight1"
                  marginBottom="$large"
                >
                  {supportItems.map(([supportItemNumber, supportItemTracking], i) => {
                    let isLastSupportItem = supportItemsCount - 1 === i;

                    return (
                      <Stack key={`${supportItemNumber}_${i}`}>
                        <SupportCategorySummary
                          supportCategoryName={name}
                          supportItemNumber={supportItemNumber}
                          supportItemTracking={supportItemTracking}
                        />
                        {!isLastSupportItem && <Separator orientation="horizontal" thickness="thick" />}
                      </Stack>
                    );
                  })}
                </Box>
              )}
            </Stack>
          </Collapsible.Content>
        </Stack>
      )}
    </Collapsible.Root>
  );
}
