import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import { ICrumb } from 'interfaces/common-interface';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';

import { ProgressBar } from '@blueprintjs/core';
import { Button, Inline } from '@goodhuman-me/components';
import { notification } from 'antd';
import TextField from 'common-components/text-field/TextField';
import { Paragraph, Text, Title } from 'common-components/typography';
import { RiSearchLine } from 'react-icons/ri';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DisplayFormType, FormType } from 'utilities/enum-utils';
import CreateFormNameModal from './components/CreateFormNameModal';
import PreviewFormModal from './components/PreviewFormModal';
import { IFormTemplate } from './shared/form-interface';
import { blankTemplate, incidentTemplate, intakeTemplate } from '../../assets/forms';

interface IFormCardItemProps {
  item: IFormTemplate;
  isBlankTemplate?: boolean;
  onSelectTemplate: (item: IFormTemplate) => void;
  onPreviewTemplate?: (item: IFormTemplate) => void;
}

const FormCardItem = (props: IFormCardItemProps): JSX.Element => {
  const { item, onSelectTemplate, onPreviewTemplate, isBlankTemplate = false } = props;
  const { name, createdBy, description } = item;

  return (
    <FormItemContainer>
      <FormItemDetail className='flex-column' style={{ backgroundImage: `url(${item.image})` }}>
        <CardInner className='card-inner'>
          <Card className='card'>
            <Text size='regular'>{description}</Text>
            <div className='flex-column'>
              {!isBlankTemplate && (
                <Button emphasis='outlined' kind='accent' marginBottom={12} onClick={() => onPreviewTemplate(item)}>
                  Preview template
                </Button>
              )}
              <Button emphasis='filled' kind='accent' onClick={() => onSelectTemplate(item)}>
                Select
              </Button>
            </div>
          </Card>
        </CardInner>
      </FormItemDetail>
      <FormItemInfo>
        <Paragraph
          size='x-large'
          weight='bold'
          className='mb-small whitespace-nowrap overflow-hidden text-overflow-ellipsis'
        >
          {name}
        </Paragraph>
        <Text size='regular' color='secondary'>
          Created by {createdBy || 'GoodHuman'}
        </Text>
      </FormItemInfo>
    </FormItemContainer>
  );
};

const crumbs: ICrumb[] = [
  {
    title: 'Account Management',
    target: '/account/landing',
  },
  {
    title: 'Forms',
    target: '/account/forms',
  },
  {
    title: 'Choose type',
    target: '/account/forms/newform',
  },
  {
    title: 'Choose template',
  },
];

const SelectFormTemplateView = (): JSX.Element => {
  const history = useHistory();
  const params = useParams<{ formType: FormType }>();
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  const formTemplateList = useSelector((state: IRootState) => state.formBuilderStore.formTemplateList);
  const dispatch = useDispatch<IRootDispatch>();

  const [openFormNameModal, setOpenFormNameModal] = useState<boolean>(false);
  const [openPreviewFormModal, setOpenPreviewFormModal] = useState<boolean>(false);
  const [formTemplates, setFormTemplates] = useState<IFormTemplate[]>([]);
  const [selectedFormTemplate, setSelectedFormTemplate] = useState<IFormTemplate>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formType = params.formType.toUpperCase() === FormType.INTAKE ? FormType.INTAKE : FormType.INCIDENT;
  const blankFormTemplate: IFormTemplate = {
    name: 'Blank template',
    description: `Start from scratch with a blank ${formType === FormType.INTAKE ? 'intake' : 'incident'} form`,
    isBlankTemplate: true,
    formTemplateId: '',
    formElements: [],
    image: blankTemplate,
    formType: formType,
  };

  const handleCloseFormModal = () => {
    setOpenFormNameModal(false);
  };

  const handleClosePreviewFormModal = () => {
    setOpenPreviewFormModal(false);
  };

  const handleClickSelectForm = async (item: IFormTemplate) => {
    setOpenFormNameModal(true);
    setSelectedFormTemplate(item);
  };

  const handleClickPreviewForm = async (item: IFormTemplate) => {
    setOpenPreviewFormModal(true);
    setSelectedFormTemplate(item);
  };

  const handleClickCreateForm = async (formName: string) => {
    history.push('/account/forms/create', {
      formTitle: formName,
      formType: formType,
      formElements: JSON.stringify(selectedFormTemplate.formElements),
    });
  };

  const handleFetchFormList = async () => {
    setIsLoading(true);
    try {
      const formResponseList = await dispatch.formBuilderStore.fetchFormTemplateList({ formType: formType });
      const convertFormResponseToFormTemplate: IFormTemplate[] = formResponseList.map((form) => {
        const isIntakeForm = formType === FormType.INTAKE;
        return {
          name: `${DisplayFormType[formType]} template`,
          description: isIntakeForm
            ? 'A comprehensive intake form with industry-standard questions'
            : 'A basic incident report form',
          isBlankTemplate: false,
          image: isIntakeForm ? intakeTemplate : incidentTemplate,
          ...form,
        };
      });
      const newFormTemplateList = convertFormResponseToFormTemplate.concat([blankFormTemplate]);

      dispatch.formBuilderStore.setFormTemplateList(newFormTemplateList);
      setFormTemplates(newFormTemplateList);
    } catch (error) {
      notification.error({
        message: 'Oops, something went wrong! Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchTemplates = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value) {
      setFormTemplates(formTemplateList.filter((item) => item.name.toLowerCase().includes(value.trim().toLowerCase())));
    } else {
      setFormTemplates(formTemplateList);
    }
  };

  useEffect(() => {
    if (!PermissionUtils.validatePermission('AccessToFormBuilder', portalUser.permissions.permissionRoles)) {
      history.push('/access-denied');
    } else {
      handleFetchFormList();
    }
  }, [history, portalUser.permissions.permissionRoles]);

  return (
    <div>
      <CreateFormNameModal
        isOpen={openFormNameModal}
        onClose={handleCloseFormModal}
        onClickCreate={handleClickCreateForm}
      />
      {selectedFormTemplate && (
        <PreviewFormModal
          isOpen={openPreviewFormModal}
          onClose={handleClosePreviewFormModal}
          formContent={{
            elements: selectedFormTemplate.formElements,
            formType: formType,
            title: { formTitle: selectedFormTemplate.name, formDescription: selectedFormTemplate?.description || '' },
          }}
        />
      )}
      <BreadcrumbNav history={history} icon='home' theme='filled' crumbs={crumbs} isBordered={false} />

      <>
        <div className='flex-row justify-between align-center'>
          <div>
            <Title level={3} className='mb-x-small' style={{ color: '#000000' }}>
              Create a new {formType?.toLowerCase()} form
            </Title>
            <Text size='regular' style={{ color: '#706F6D', fontWeight: 500 }}>
              Choose a ready-made template or start from scratch with a blank form.
            </Text>
          </div>
          <Inline gap='$space200'>
            <Button kind='accent' size='large' emphasis='quiet'>
              <Link to='/account/forms/newform'>Back</Link>
            </Button>

            <Button kind='accent' emphasis='outlined' size='large'>
              <Link to='/account/forms'>View forms</Link>
            </Button>
          </Inline>
        </div>

        <TextField
          onChange={handleSearchTemplates}
          placeholder='Search'
          style={{ width: 260 }}
          icon={<RiSearchLine size={16} />}
          containerStyle={{ marginTop: 42, marginBottom: 58 }}
        />

        {isLoading ? (
          <ProgressBar />
        ) : (
          <>
            <div className='flex-row flex-wrap'>
              {formTemplates.map((template) => (
                <FormCardItem
                  key={template.name}
                  item={template}
                  onSelectTemplate={handleClickSelectForm}
                  onPreviewTemplate={handleClickPreviewForm}
                  isBlankTemplate={template.isBlankTemplate}
                />
              ))}
            </div>
            {!formTemplates.length && (
              <div className='flex justify-center'>
                <Text size='x2-large' color='secondary' weight='bold'>
                  No Template Found.
                </Text>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default SelectFormTemplateView;

const FormItemContainer = styled.div({
  width: '320px',
  borderRadius: '8px',
  marginRight: '32px',
  marginBottom: '32px',
  border: '2px solid #F5F4F4',
  ':last-child': {
    marginRight: 0,
  },
  ':hover': {
    '.card-inner .card': {
      opacity: 1,
    },
  },
});

const FormItemDetail = styled.div({
  justifyContent: 'space-between',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  backgroundSize: 'contain',
  backgroundColor: '#F5F4F4',
  height: '340px',
});

const FormItemInfo = styled.div({
  padding: '24px',
  borderTop: '2px solid #F5F4F4',
});

const Card = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  width: '100%',
  height: '100%',
  padding: '24px',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  opacity: 0,
  cursor: 'pointer',
  transition: 'all 300ms',
});

const CardInner = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
  transition: 'transform 0.6s',
  transformStyle: 'preserve-3d',
});
