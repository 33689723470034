import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ArrowBottomLeftCircle = forwardRef(function ArrowBottomLeftCircle(
  props: IconProps,
  ref: Ref<SVGSVGElement>,
) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 12a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0ZM12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25Zm2.47 7.22a.75.75 0 1 1 1.06 1.06l-4.72 4.72h2.69a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 1 1.5 0v2.69l4.72-4.72Z"
        fill="currentColor"
      />
    </Icon>
  );
});
