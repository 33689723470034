import { Checkbox } from 'antd';
import { IBooking } from 'interfaces/booking-interfaces';
import { IFilter } from 'interfaces/filter-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { LegacyRef, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ServiceType } from 'utilities/enum-utils';
import defaultFields from 'variables/data/defaultBookingListingFields.json';
import { FieldItem } from './FieldItem';

type CustomFilterField = {
  header: string;
  field: string;
};

type BookingItemRowProps = {
  bookingItem?: IBooking;
  timezone: timeZone;
  showTimezoneIndicator: boolean;
  customFilterFields?: CustomFilterField[];
  checked?: boolean;
  onCheck?: (bookingId: string | null) => void;
  setFilter?: (filters: IFilter[]) => void;
};

export const BookingItemRow: React.FC<BookingItemRowProps> = ({
  bookingItem,
  timezone,
  showTimezoneIndicator,
  customFilterFields,
  checked,
  onCheck,
  setFilter,
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const onCheckCell: React.MouseEventHandler<HTMLTableCellElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (onCheck) {
      onCheck(bookingItem?.bookingId as string);
    }
  };

  const history = useHistory();
  const { boom479ClickyBookingsLinks } = useFlags();

  const legacyBookingRedirect = (): void => {
    history.push(`/bookings/details/${bookingItem?.bookingId}`);
  };

  const customFields = (customFilterFields?.length as number) > 0 ? customFilterFields : defaultFields;

  // If we're passed in a booking with no ID, something has gone wrong, fallback to rendering nothing.
  if (!bookingItem?.bookingId) {
    return <></>;
  }

  return (
    <tr
      key={`booking-item-row-${bookingItem.bookingId}`}
      className={`${!checked && 'hover-bg-tertiary'} anim-fade-in ${checked ? 'bg-blue-lightest' : 'bg-white'} evenodd`}
      onClick={!boom479ClickyBookingsLinks ? legacyBookingRedirect : undefined}
    >
      {/* Checkbox */}
      {bookingItem.serviceType !== ServiceType.GROUP ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions -- This is only for supporting legacy behaviour.
        <td
          className="fluid-cell check-all pr-none"
          onClick={onCheckCell}
          onMouseEnter={() => checkboxRef.current?.focus()}
          onMouseLeave={() => checkboxRef.current?.blur()}
        >
          <Checkbox checked={checked} ref={checkboxRef as unknown as LegacyRef<Checkbox>} />
        </td>
      ) : (
        <td className="fluid-cell check-all pr-none" />
      )}
      {customFields?.map((fields, index) => (
        <FieldItem
          bookingItem={bookingItem}
          timezone={timezone}
          showTimezoneIndicator={showTimezoneIndicator}
          fieldName={fields.field}
          setFilter={setFilter}
          useFieldLinks={boom479ClickyBookingsLinks}
          key={`booking-item-field-${index}`}
        />
      ))}
    </tr>
  );
};
