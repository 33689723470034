import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const File = forwardRef(function File(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 3.753c-.21-.002-.452-.003-.735-.003H11c-1.435 0-2.437.002-3.192.103-.734.099-1.122.28-1.399.556-.277.277-.457.665-.556 1.4C5.752 6.562 5.75 7.564 5.75 9v6c0 1.435.002 2.436.103 3.192.099.734.28 1.122.556 1.399.277.277.665.457 1.4.556.754.101 1.756.103 3.191.103h2c1.435 0 2.437-.002 3.192-.103.734-.099 1.122-.28 1.399-.556.277-.277.457-.665.556-1.4.101-.755.103-1.756.103-3.191V9.485c0-.283 0-.526-.003-.735h-.299c-.899 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.725-.456-.456-.642-1.023-.726-1.65-.08-.595-.08-1.344-.08-2.243v-.299Zm4.767 3.497c-.174-.332-.5-.673-1.305-1.477l-.485-.485c-.804-.805-1.145-1.131-1.477-1.305V4c0 .964.002 1.612.066 2.095.063.461.17.659.3.789.13.13.328.238.79.3.482.065 1.13.066 2.094.066h.017Zm-2.825-4.715c-.691-.286-1.447-.286-2.535-.285H10.945c-1.367 0-2.47 0-3.337.117-.9.12-1.658.38-2.26.981-.602.602-.86 1.36-.981 2.26-.117.867-.117 1.97-.117 3.337V15.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116H13.055c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337V9.343c0-1.088 0-1.844-.285-2.535-.287-.692-.822-1.226-1.591-1.995l-.101-.1-.485-.486-.101-.1c-.769-.77-1.303-1.305-1.995-1.592Z"
        fill="currentColor"
      />
    </Icon>
  );
});
