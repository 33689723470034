import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { IRootDispatch, IRootState, dispatch } from 'stores/rematch/root-store';
import { Paragraph, Text } from 'common-components/typography';
import { Avatar, Col, Row, Form, Input, notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ICustomer, IGuardiansInfo } from 'interfaces/customer-interfaces';
import { FormComponentProps } from 'antd/es/form';
import moment from 'moment';
import _ from 'lodash';
import CommonUtils from 'utilities/common-utils';

const FormItem = Form.Item;

interface IInviteReinviteCustomerModalProps extends FormComponentProps {
  isInviteReinviteModalOpen: boolean;
  actionType?: 'RE-INVITE';
  selectedCustomer: ICustomer;
  selectedGuardian: IGuardiansInfo;
  isGuardianModal?: boolean;
  closeInviteReinviteModal: () => void;
  doCreateConnectionRequest: typeof dispatch.customersStore.doCreateConnectionRequest;
  doCheckCustomerEmailConflict: typeof dispatch.customersStore.doCheckCustomerEmailConflict;
  doUpdateConnectionRequest: typeof dispatch.customersStore.doUpdateConnectionRequest;
}

interface IInviteReinviteCustomerModalState {
  isLoading: boolean;
  isFoundDuplicate: boolean;
  foundDuplicateUser: any | null;
  activeConnectionRequests: Array<any>;
}
class InviteReinviteCustomerModal extends Component<
  IInviteReinviteCustomerModalProps,
  IInviteReinviteCustomerModalState
> {
  state = {
    isLoading: false,
    isFoundDuplicate: false,
    foundDuplicateUser: null,
    activeConnectionRequests: [],
  };

  private _closeModal = () => {
    this.props.closeInviteReinviteModal();
  };

  private _onClickSend = async () => {
    try {
      const {
        form,
        selectedCustomer,
        doCreateConnectionRequest,
        actionType,
        doCheckCustomerEmailConflict,
        doUpdateConnectionRequest,
        isGuardianModal,
        selectedGuardian,
      } = this.props;
      const { activeConnectionRequests } = this.state;

      let isFormValid = true;

      form.validateFields((err, value) => {
        if (err) isFormValid = false;
      });

      if (isFormValid) {
        const invitationToEmail = form.getFieldValue('invitation-to');
        const firstName = isGuardianModal ? selectedGuardian.firstName : selectedCustomer.firstName;
        const lastName = isGuardianModal ? selectedGuardian.lastName : selectedCustomer.lastName;

        this.setState({ isLoading: true });
        const conflicts = await doCheckCustomerEmailConflict({ email: invitationToEmail });
        this.setState({ isLoading: false });

        if (!_.isEmpty(conflicts)) {
          const foundDuplicateUser = conflicts[0];
          foundDuplicateUser.email = invitationToEmail;
          this.setState({ foundDuplicateUser: foundDuplicateUser });
        } else {
          if (actionType !== 'RE-INVITE') {
            //* For case INVITE
            const customerConnectionRequests = [
              {
                userId: selectedCustomer.userId,
                targetUserId: isGuardianModal ? selectedGuardian.guardianUserId : selectedCustomer.userId,
                email: invitationToEmail,
              },
            ];
            await doCreateConnectionRequest(customerConnectionRequests);
          } else {
            //* For case Re-invite
            const request = {
              userId: selectedCustomer.userId,
              targetUserId: isGuardianModal ? selectedGuardian.guardianUserId : selectedCustomer.userId,
              customerConnectionRequestId:
                !_.isEmpty(activeConnectionRequests) && activeConnectionRequests[0].customerConnectionRequestId,
              email: invitationToEmail,
            };

            await doUpdateConnectionRequest(request);
          }

          notification.open({
            message: <span className={'text-weight-bolder'}>Invitation sent</span>,
            description: (
              <span>
                You have successfully sent{' '}
                <span className={'text-weight-bolder'}>
                  {firstName} {lastName}
                </span>{' '}
                an invitation to the GoodHuman app.
              </span>
            ),
          });
          this._closeModal();
        }
      }
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  private __closeEmailDuplicateModal = () => {
    this.setState({ isFoundDuplicate: false, isLoading: false });
  };

  private _renderEmailDuplicate = () => {
    const foundDuplicateUser = this.state.foundDuplicateUser;
    const title = 'Email duplicate found';
    const description = (
      <div>
        {' '}
        The email entered: <span className={'text-weight-bolder'}>{foundDuplicateUser.email}</span>, belongs to another
        user who is currently using the GoodHuman app:{' '}
      </div>
    );
    const description2 = 'A unique email is required in order to invite a customer to the app.';

    return (
      <ActionModal title={title} isOpen={this.state.isFoundDuplicate} onClose={this.__closeEmailDuplicateModal}>
        <div>
          <Paragraph>{description}</Paragraph>
          <div className="flex-row align-center">
            <Avatar className="mr-medium" size="large" icon="user" src={foundDuplicateUser.attachmentUrl} />
            <div className="flex-column">
              <Text className="line-height-135 text-size-large">
                {foundDuplicateUser.firstName} {foundDuplicateUser.lastName}
              </Text>

              <Text color="secondary" className="text-size-x-small">
                {foundDuplicateUser.email}
              </Text>
            </div>
          </div>
          <Paragraph className="mt-medium">{description2}</Paragraph>
        </div>

        <ActionModalFooter>
          <SecondaryButton onClick={this.__closeEmailDuplicateModal} size="large">
            Go Back
          </SecondaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.foundDuplicateUser !== this.state.foundDuplicateUser) {
      this.setState({ isFoundDuplicate: true });
    }

    if (prevProps.selectedGuardian !== this.props.selectedGuardian) {
      //For RE-INVITE
      const { isGuardianModal, selectedCustomer, selectedGuardian } = this.props;
      let activeConnectionRequests = CommonUtils.getActiveConnectionRequestHelper(selectedCustomer);
      if (isGuardianModal) {
        activeConnectionRequests = _.filter(activeConnectionRequests, (req) => req.email === selectedGuardian.email);
      }
      this.setState({ activeConnectionRequests: activeConnectionRequests });
      //END RE-INVITE
    }
  }

  render() {
    const { isInviteReinviteModalOpen, actionType, selectedCustomer, form, isGuardianModal, selectedGuardian } =
      this.props;
    const { getFieldDecorator } = form;
    const { isLoading, activeConnectionRequests } = this.state;
    const title = actionType === 'RE-INVITE' ? 'Re-send/modify invitation' : 'Invite to app';

    //For form input email
    const formPrefilledEmail =
      actionType === 'RE-INVITE'
        ? activeConnectionRequests.length > 0
          ? activeConnectionRequests[0].email
          : ''
        : isGuardianModal
        ? selectedGuardian.email
        : selectedCustomer.email;
    //END form input email
    const firstName = isGuardianModal ? selectedGuardian.firstName : selectedCustomer.firstName;
    const lastName = isGuardianModal ? selectedGuardian.lastName : selectedCustomer.lastName;
    const attachmentUrl = isGuardianModal ? selectedGuardian.attachmentUrl : selectedCustomer.attachmentUrl;

    if (this.state.isFoundDuplicate) return this._renderEmailDuplicate();
    return (
      <div>
        <ActionModal isOpen={isInviteReinviteModalOpen} title={title} onClose={this._closeModal}>
          <SpinningLoadingActionModel isOpen={isLoading} verticalAlignment={'highest'} />
          <Paragraph>
            {!actionType &&
              !isGuardianModal &&
              'Inviting the customer to the GoodHuman app will allow them to book their own services, manage their own information and view/approve invoices among other things.'}

            {isGuardianModal &&
              'Inviting the legal guardian to the GoodHuman app will allow them to manage the care of the customer they are the legal guardian of.'}
          </Paragraph>
          <div className="mb-medium">
            <Text color="secondary text-uppercase text-weight-bolder" size="regular">
              Customer
            </Text>
          </div>
          <div className="flex-row align-center">
            <Avatar className="mr-medium" size="large" icon="user" src={attachmentUrl} />
            <div>
              {firstName} {lastName}
            </div>
          </div>
          {actionType === 'RE-INVITE' && (
            <div className="mt-medium">
              <div>
                <Text color="secondary" size="small" className="text-uppercase mb-x-small text-weight-bolder">
                  current invitation details
                </Text>
              </div>

              {!_.isEmpty(activeConnectionRequests) ? (
                _.map(activeConnectionRequests, (request, index) => {
                  return (
                    <div key={index} className="flex-column mt-x2-small">
                      <Text>{request.email}</Text>
                      <Text>Sent on {moment(request.createdOn).format('DD/MM/YYYY')}</Text>
                    </div>
                  );
                })
              ) : (
                <Text className="mt-x2-small">There are no active connect requests for this Customer</Text>
              )}
            </div>
          )}
          <div className="mt-x-large">
            <Text color="secondary" size="small" className="text-uppercase mb-x-small text-weight-bolder ">
              send invitation to:
            </Text>
            <FormItem>
              <Row>
                <Col span={11}>
                  {getFieldDecorator('invitation-to', {
                    initialValue: formPrefilledEmail,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please enter a valid email',
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      className="ph-12 pv-small line-height-135 "
                      placeholder="Enter email address"
                    />,
                  )}
                </Col>
              </Row>
            </FormItem>
          </div>

          <ActionModalFooter className={'mt-large '}>
            <Row type={'flex'} className={'justify-end'}>
              <Col className="mr-medium">
                <SecondaryButton size="large" onClick={this._closeModal}>
                  Cancel
                </SecondaryButton>
              </Col>
              <Col>
                <PrimaryButton onClick={this._onClickSend} size="large">
                  {actionType === 'RE-INVITE' ? 'Re-send invite' : 'Send invite'}
                </PrimaryButton>
              </Col>
            </Row>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedCustomer: dispatch.customersStore.setSelectedCustomer,
  doCreateConnectionRequest: dispatch.customersStore.doCreateConnectionRequest,
  doCheckCustomerEmailConflict: dispatch.customersStore.doCheckCustomerEmailConflict,
  doUpdateConnectionRequest: dispatch.customersStore.doUpdateConnectionRequest,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IInviteReinviteCustomerModalProps>()(InviteReinviteCustomerModal));
