import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Export = forwardRef(function Export(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 13.75a.75.75 0 1 0-1.5 0v.5c0 1.19-.001 2.02-.073 2.656-.07.62-.199.962-.392 1.214a2.25 2.25 0 0 1-.415.415c-.252.193-.594.322-1.214.392C17.77 19 16.94 19 15.75 19h-7c-1.19 0-2.02-.001-2.656-.073-.62-.07-.962-.199-1.214-.392a2.25 2.25 0 0 1-.415-.415c-.193-.252-.322-.594-.392-1.214C4 16.27 4 15.44 4 14.25v-.5a.75.75 0 1 0-1.5 0v.545c0 1.135 0 2.05.082 2.779.085.754.266 1.402.693 1.959.2.26.432.492.692.692.556.427 1.205.608 1.96.693.729.082 1.643.082 2.778.082h7.09c1.135 0 2.05 0 2.779-.082.754-.085 1.403-.266 1.959-.693.26-.2.493-.432.692-.692.427-.556.608-1.205.693-1.96.082-.729.082-1.643.082-2.778v-.545ZM12.78 3.22a.75.75 0 0 0-1.06 0l-4 4a.75.75 0 0 0 1.06 1.06l2.72-2.72v8.19a.75.75 0 1 0 1.5 0V5.56l2.72 2.72a.75.75 0 1 0 1.06-1.06l-4-4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
