import type * as Stitches from '@stitches/react';
import { css } from '../theme';

export const iconVariants = css({
  minWidth: '$size200',
  variants: {
    /**
     * Default set of size for icon.
     * Note: While this prop isn't provided, measure can be changed manually by width and height
     */
    size: {
      xsmall: {
        height: '$size150',
        width: '$size150',
      },
      small: {
        height: '$size200',
        width: '$size200',
      },
      medium: {
        height: '$size250',
        width: '$size250',
      },
      large: {
        height: '$size300',
        width: '$size300',
      },
    },
  },
});

export type IconVariants = Stitches.VariantProps<typeof iconVariants>;
