import React from 'react';
import './text-field.css';

interface ITextFieldProps extends React.HTMLProps<HTMLInputElement> {
  icon?: React.ReactNode;
  label?: string;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
}

const TextField = (props: ITextFieldProps): JSX.Element => {
  const { label, containerStyle, icon, containerClass, ...inputProps } = props;

  const customInputStyle = {
    ...inputProps.style,
    paddingLeft: icon ? '40px' : '12px',
    height: '44px',
  };

  const iconStyle = {
    width: customInputStyle.paddingLeft,
    height: customInputStyle.height,
  };

  return (
    <div className={`text-field__container ${containerClass || ''}`} style={containerStyle}>
      {label && <label className="text-field__label">{label}</label>}
      {icon && (
        <span className="text-field__icon" style={iconStyle}>
          {icon}
        </span>
      )}
      <input {...inputProps} className={`text-field__input ${inputProps.className || ''}`} style={customInputStyle} />
    </div>
  );
};

export default TextField;
