import { notification } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { IBillingAccessMember } from 'interfaces/subscription-interfaces';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';
interface Props {
  isOpen: boolean;
  removingAccess: IBillingAccessMember;
  onClose(): void;
  onSuccess(): void;
}

const RemoveBillingAccessModal = (props: Props) => {
  const { isOpen, onClose, onSuccess, removingAccess } = props;
  const dispatch = useDispatch<IRootDispatch>();

  const [isLoading, setIsLoading] = useState(false);

  const _handleRemove = async () => {
    setIsLoading(true);
    const response = await dispatch.subscriptionStore.doRemoveBillingAccess(removingAccess.userId);
    if (response) {
      onSuccess();
      onClose();
    } else {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
    setIsLoading(false);
  };

  if (!removingAccess) {
    return null;
  }

  return (
    <ActionModal
      width="medium"
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Paragraph style={{ fontSize: 20 }} weight="bolder" className="mb-medium">
          Remove {removingAccess.firstName} as a billing contact
        </Paragraph>
      }
    >
      <Paragraph style={{ fontSize: 16 }} className="mb-large">
        Removing this team member as a billing contact will revoke their access to the Subscription Billing & Access
        area of GoodHuman. Select Remove to confirm.
      </Paragraph>
      <ActionModalFooter>
        <GhostButton size="large" onClick={onClose} className="mr-medium">
          Cancel
        </GhostButton>
        <PrimaryButton loading={isLoading} onClick={_handleRemove} color="red" size="large">
          Remove
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};

export default RemoveBillingAccessModal;
