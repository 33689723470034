import React from 'react';
import { Icon, Skeleton } from 'antd';
import { BudgetStatusLabel } from '../../../views/budgets/components/budget-status-label';
import { useCustomerBudgetStatus } from '../../../views/budgets/pages/detail/services';
import { Link } from 'react-router-dom';
import { ReactComponent as ViewLinkIcon } from '../../../../src/assets/Icons/budgeting/view_icon.svg';
import { Inline } from '@goodhuman-me/components';
import { CustomerDetailsTabKey } from '../../../../src/utilities/enum-utils';

export const BudgetStatusColumn = ({ customerId }: { customerId: string }): JSX.Element => {
  const { data, isSuccess, isError, isLoading } = useCustomerBudgetStatus(customerId);

  if (isLoading) return <Skeleton active title={true} paragraph={{ rows: 0 }} />;
  if (isError || !data.activeServiceAgreementId) return <span>-</span>;
  if (isSuccess)
    return (
      <Inline alignItems="center" gap="$small">
        <BudgetStatusLabel status={data.budgetTrackingStatus} size="small" />
        <Link
          to={{ pathname: `/customer/details/${customerId}`, state: { selectedTab: CustomerDetailsTabKey.BUDGETING } }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Icon component={ViewLinkIcon} style={{ fontSize: '22px' }} />
        </Link>
      </Inline>
    );
};
