import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React, { PureComponent } from 'react';

interface Props {
  isOpen: boolean;
  onClose(): void;
  onConfirm(): void;
}

class CancelChangeWorkflowTemplateModal extends PureComponent<Props> {
  render() {
    const { isOpen, onClose, onConfirm } = this.props;

    return (
      <ActionModal isOpen={isOpen} onClose={onClose} title="Cancel changes" verticalAlignment="high">
        <Paragraph>Are you sure you want to save the changes made to this workflow?</Paragraph>
        <Paragraph className="mt-medium">All changes will be lost</Paragraph>

        <div className="mt-x2-large flex justify-end">
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Go back
          </SecondaryButton>
          <PrimaryButton size="large" onClick={onConfirm}>
            Cancel changes
          </PrimaryButton>
        </div>
      </ActionModal>
    );
  }
}

export default CancelChangeWorkflowTemplateModal;
