import { Button, Icon } from 'antd';
import { PORTAL_COLORS } from 'interfaces/common-ui-types';
import React, { HTMLProps } from 'react';

export type AlertSeverity = 'success' | 'info' | 'warning' | 'error';

type AlertTagProps = {
  severity: AlertSeverity;
  text: React.ReactNode;
  onClickView?: () => void;
  onClickIgnore?: React.MouseEventHandler<HTMLElement>;
  className?: string;
} & HTMLProps<HTMLSpanElement>;

type SeverityColorMap = {
  [key in AlertSeverity]: PORTAL_COLORS;
};

const SEVERITY_COLORS: SeverityColorMap = {
  info: 'blue',
  success: 'green',
  warning: 'warning-orange-solid',
  error: 'red',
};

const AlertTag: React.FC<AlertTagProps> = ({ severity, text, onClickView, onClickIgnore, className, ...htmlProps }) => {
  const bgColor: string = SEVERITY_COLORS[severity];

  // We cannot pass in onClick={undefined} to the Icon element due to TS strictness.
  // This means we need to just remove the prop entirely when onClickIgnore is not passed in.
  const iconIgnoreProp = onClickIgnore ? { onClick: onClickIgnore } : {};

  return (
    <span
      className={`pv-x-small align-center ph-medium rounded-big flex bg-${bgColor} text-color-white ${className}`}
      style={{
        display: 'inline-block',
        width: 'fit-content',
        maxWidth: 'auto',
      }}
      {...htmlProps}
    >
      <Icon className="mr-small cursor-pointer" type={`close-circle`} theme="filled" {...iconIgnoreProp} />
      <span className="text-overflow-ellipsis">{text}</span>
      {onClickView && (
        <Button className={`pr-none hover-text-color-lighter text-color-white`} onClick={onClickView} type="link" ghost>
          View details...
        </Button>
      )}
    </span>
  );
};

export default AlertTag;
