import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Loader = forwardRef(function Loader(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.75a.75.75 0 0 1-.75-.75v-3.343a.75.75 0 1 1 1.5 0V21a.75.75 0 0 1-.75.75Zm0-14.657a.75.75 0 0 1-.75-.75V3a.75.75 0 0 1 1.5 0v3.343a.75.75 0 0 1-.75.75Zm-6.894 10.74a.75.75 0 0 0 1.06 1.061l2.365-2.364a.75.75 0 1 0-1.061-1.06l-2.364 2.364ZM15.47 7.47a.75.75 0 0 0 1.06 1.06l2.364-2.364a.75.75 0 1 0-1.06-1.06L15.47 7.47ZM3 11.25a.75.75 0 0 0 0 1.5h3.343a.75.75 0 0 0 0-1.5H3Zm14.657 0a.75.75 0 0 0 0 1.5H21a.75.75 0 0 0 0-1.5h-3.343Zm.177 7.644a.75.75 0 0 0 1.06-1.06L16.53 15.47a.75.75 0 1 0-1.06 1.06l2.364 2.364ZM7.47 8.531A.75.75 0 0 0 8.53 7.47L6.167 5.106a.75.75 0 0 0-1.06 1.06L7.47 8.531Z"
        fill="currentColor"
      />
    </Icon>
  );
});
