import React from 'react';

import _ from 'lodash';

import { Popover } from '@blueprintjs/core';
import { Avatar } from 'antd';

import { Text } from 'common-components/typography';
import { IconButton } from 'common-components/buttons';
import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { IRosterBooking, IRosterCustomer } from 'common-components/roster-control/interfaces/roster-interfaces';

import { BookingStatus } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import { useHistory } from 'react-router-dom';

interface CustomerContentRowProps {
  customer: IRosterCustomer;
  bookings?: IRosterBooking[][];
  // revenueAmount: number;
}

export function CustomersColumn({ customer, bookings }: CustomerContentRowProps) {
  const history = useHistory();
  const { TIMELINE_ROW_HEIGHT } = ROSTER_DAILY_CONFIG;

  const { customerFirstName, customerLastName, customerAvatarUrl, customerId } = customer;

  const goToProfile = (customerId) => {
    history.push(`/customer/details/${customerId}`);
  };

  const customerName = `${customerFirstName} ${customerLastName}`;

  const rowHeight = _.isEmpty(bookings) ? TIMELINE_ROW_HEIGHT : TIMELINE_ROW_HEIGHT * _.size(bookings);

  const revenueAmount = calculateCostsForBookings(bookings);

  return (
    <div
      className="bg-quaternary pv-medium pl-x-large pr-medium flex-row justify-between bordered-right bordered-bottom anim-fade-in-fast"
      style={{ height: rowHeight }}
    >
      <div className="flex-row align-center">
        <div className="mr-12">
          <Avatar src={customerAvatarUrl} size="large" shape="circle" icon="user" />
        </div>
        <div className="line-height-135 flex-column" style={{ width: '180px' }}>
          <div className="whitespace-nowrap overflow-hidden text-overflow-ellipsis">
            <Text lineHeight={120}>{customerName}</Text>
          </div>
          <div className="whitespace-nowrap overflow-hidden text-overflow-ellipsis">
            <Text size="regular" color="secondary" lineHeight={120}>
              {CommonUtils.formatPrice(revenueAmount)}
            </Text>
            {/*<TextTag content="Ready for review" size="small" theme="light" color="violet" className="ml-small" />*/}
          </div>
        </div>
      </div>
      <Popover
        content={
          <ActionMenu>
            <ActionMenuItem text={'View customer profile'} onClick={() => goToProfile(customerId)} />
          </ActionMenu>
        }
        position="bottom-left"
      >
        <IconButton
          icon="ellipsis"
          size="small"
          className="bg-white"
          bordered={true}
          iconColor="secondary"
          onClick={() => {}}
        />
      </Popover>
    </div>
  );
}

function calculateCostsForBookings(bookings) {
  const flattenedBookings = _.chain(bookings)
    .flatten()
    .filter(
      (booking) =>
        !_.includes(booking.bookingStatus, [
          BookingStatus.CUSTOMER_CANCELLED,
          BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE,
          BookingStatus.BUSINESS_CANCELLED,
        ]),
    )
    .value();

  return _.reduce(
    flattenedBookings,
    (acc, booking: IRosterBooking) => {
      const { total } = booking;
      return acc + Number(total);
    },
    0,
  );
}
