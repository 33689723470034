import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import { Form, notification } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, state, IRootState } from '../../../../../src/stores/rematch/root-store';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
interface IEditRemovalDateModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  worker: any;
  doEditRemovalDate: typeof dispatch.teamStore.doEditRemovalDate;
  timezone: typeof state.companyStore.companyDataLite.timezone;
}

interface IEditRemovalDateModalState {
  title: any;
  isLoading: boolean;
}

class EditRemovalDateModal extends Component<IEditRemovalDateModalProps, IEditRemovalDateModalState> {
  state = {
    title: 'Edit removal date',
    isLoading: false,
  };

  private _onCloseModal = () => {
    this.setState({ isLoading: false });
    this.props.onClose();
  };

  private _saveAction = async () => {
    const { form, onClose } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      this.setState({ isLoading: true });

      try {
        const newRemovalDate = moment(form.getFieldValue('removalDate')).format('YYYY/MM/DD');
        const payload = {
          supportWorkerId: this.props.worker.supportWorkerId,
          removedOn: moment.utc(newRemovalDate).toISOString(),
          removeReason: this.props.worker.removeReason,
        };
        await this.props.doEditRemovalDate(payload);
        notification.success({
          message: 'Scheduled removal date changed',
          description: `The date the team member scheduled to be removed has been updated to ${moment
            .tz(newRemovalDate, this.props.timezone)
            .format('DD/MM/YYYY')}`,
        });
        onClose();
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
      this.setState({ isLoading: false });
    }
  };

  private _onChangeRemovalDate = (date) => {
    const { form } = this.props;
    form.setFieldsValue({ removalDate: date });
  };

  render() {
    const { isOpen, worker, form } = this.props;
    const { getFieldDecorator } = form;
    const { isLoading } = this.state;
    const name = worker ? worker.firstName + ' ' + worker.lastName : '';
    const removalDate = worker && worker.removedOn ? worker.removedOn : null;

    return (
      <ActionModal
        title="Edit removal date"
        isOpen={isOpen}
        onClose={this._onCloseModal}
        canCloseOutside={!isLoading}
        width="large"
      >
        <Paragraph>
          <Text weight="bold">{name}</Text> is scheduled to be removed on{' '}
          <Text weight="bold">{moment.tz(removalDate, this.props.timezone).format('DD/MM/YYYY')}</Text>
        </Paragraph>
        <div className="mb-x2-small">
          <FieldLabel text="NEW REMOVAL DATE" />
        </div>
        <Form.Item className="width-full">
          {getFieldDecorator('removalDate', {
            rules: [{ required: true, message: 'Please select removal date' }],
          })(
            <DatePicker
              className="gh-datepicker-plan-management rounded "
              calendarClassName="gh-datepicker-calendar"
              dateFormat="dd/MM/yyyy"
              onChange={this._onChangeRemovalDate}
              placeholderText="Select date..."
              selected={form.getFieldValue('removalDate')}
              minDate={new Date(moment().add(1, 'days').format('YYYY/MM/DD HH:mm'))}
            />,
          )}
        </Form.Item>
        <ActionModalFooter>
          <SecondaryButton size="large" disabled={isLoading} className="mr-medium" onClick={this._onCloseModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" loading={isLoading} onClick={this._saveAction}>
            Save
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  timezone: state.companyStore.companyDataLite ? state.companyStore.companyDataLite.timezone : '',
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditRemovalDate: dispatch.teamStore.doEditRemovalDate,
});

export default connect(mapState, mapDispatch)(Form.create<IEditRemovalDateModalProps>()(EditRemovalDateModal));
