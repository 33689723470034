import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ArrowBottomLeft = forwardRef(function ArrowBottomLeft(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.53 5.47a.75.75 0 0 0-1.06 0L6.75 16.19V8.4a.75.75 0 0 0-1.5 0V18c0 .414.336.75.75.75h9.6a.75.75 0 0 0 0-1.5H7.81L18.53 6.53a.75.75 0 0 0 0-1.06Z"
        fill="currentColor"
      />
    </Icon>
  );
});
