import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';
import { ShiftSlotStatus } from 'utilities/enum-utils';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

interface IUndoShiftModalProps {
  onClose: ({ targetFlag }: { targetFlag: string }, refreshShiftSlots: boolean) => void;
  isOpen: boolean;
  serviceId: string;
  serviceDateTimeId: string;
  supportWorkerAttendanceId?: string;
  shiftSlotStatus?: string;
}

const UndoShiftModal = (props: IUndoShiftModalProps) => {
  const dispatch: IRootDispatch = useDispatch();
  const { onClose, isOpen, serviceId, serviceDateTimeId, supportWorkerAttendanceId, shiftSlotStatus } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canManuallyClose, setCanManuallyClose] = useState<boolean>(true);
  const [isCheckedInStatus, setIsCheckedInStatus] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('Undo shift start');

  const onRevert = async () => {
    setCanManuallyClose(false);
    setIsLoading(true);

    try {
      const payload = { serviceId, serviceDateTimeId, supportWorkerAttendanceId };

      if (isCheckedInStatus) {
        await dispatch.groupServiceStore.doUndoStartShift(payload);
      } else {
        await dispatch.groupServiceStore.doUndoShiftFinish(payload);
      }

      setCanManuallyClose(true);
      onClose({ targetFlag: 'isUndoShiftOpen' }, true);
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseModal = () => {
    onClose({ targetFlag: 'isUndoShiftOpen' }, false);
    setCanManuallyClose(true);
    setIsLoading(false);
  };

  useEffect(() => {
    const checkedInStatus = shiftSlotStatus === ShiftSlotStatus.CHECKED_IN;
    setIsCheckedInStatus(checkedInStatus);
    setTitle(checkedInStatus ? 'Undo shift start' : 'Undo shift finish');
  }, [shiftSlotStatus]);

  return (
    <ActionModal
      isOpen={isOpen}
      title={title}
      width="medium"
      onClose={onCloseModal}
      canCloseOutside={canManuallyClose}
      showCloseButton={canManuallyClose}
      verticalAlignment="highest"
    >
      <div className="flex-column">
        <div className="anim-fade-in" style={{ height: '100%' }}>
          <div className="text-align-left pt-small">
            <Paragraph>
              Do you want to <Text weight="bold">{isCheckedInStatus ? 'Undo Shift Start' : 'Undo Shift Finish'}</Text>?
            </Paragraph>
            <Paragraph>
              This will revert this shift to <Text weight="bold">{isCheckedInStatus ? 'Confirmed' : 'Checked In'}</Text>
              .
            </Paragraph>
            <Paragraph>
              {isCheckedInStatus
                ? 'Any start times recorded by assigned team members will be deleted.'
                : 'Any start times recorded by assigned team members will be deleted along with any additional information.'}
            </Paragraph>
          </div>
          <div className="justify-end align-end">
            <ActionModalFooter>
              <SecondaryButton className="mr-medium" size="large" onClick={onCloseModal} disabled={isLoading}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={onRevert} loading={isLoading}>
                {title}
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default UndoShiftModal;
