import React from 'react';
import { Anchor, Avatar, Text } from '@good/ui/core';
import { ColumnDef } from '@tanstack/react-table';
import { Check, Cross, Paperclip } from '@good/icons';
import { ServiceCustomerDocumentData } from 'utilities/react-trpc';
// import { SilFileActionsDropdown } from '../components/file-actions-dropdown';
import { Link } from 'react-router-dom';

export const customerFileColumns: ColumnDef<ServiceCustomerDocumentData>[] = [
  {
    id: 'customer',
    header: 'CUSTOMER',
    accessorKey: 'displayName',
    sortingFn: 'alphanumeric',
    size: 180,
    cell: (ctx) => {
      const {
        customer: { displayName, avatarUrl },
      } = ctx.row.original;

      return (
        <>
          <div className='flex items-center'>
            <Avatar radius='sm' className='mx-2' size={32} src={avatarUrl ?? ''} />
            <Text size='sm'>{displayName}</Text>
          </div>
        </>
      );
    },
  },
  {
    id: 'documentName',
    header: 'DOCUMENT NAME',
    accessorKey: 'documentName',
    cell: (ctx) => {
      const { documentName, documentUrl, description } = ctx.row.original;

      return (
        <>
          {documentUrl ? (
            <Anchor component={Link} to={documentUrl} target='_blank' size='sm'>
              <Text size='sm'>
                <Paperclip />
                {documentName}
              </Text>
            </Anchor>
          ) : (
            <Text size='sm'>
              <Paperclip />
              {documentName}
            </Text>
          )}
          {description && <Text size='sm'>{description}</Text>}
        </>
      );
    },
  },
  {
    id: 'expiryDate',
    header: 'EXPIRY DATE',
    accessorKey: 'expiryDate',
    size: 124,
    cell: (ctx) => {
      const { expiryDate } = ctx.row.original;

      const formattedExpiryDate = expiryDate ? new Date(expiryDate).toLocaleDateString() : 'No expiry';
      const isExpired = expiryDate ? new Date(expiryDate) < new Date() : false;

      return isExpired ? (
        <>
          <Text size='sm' className='text-critical'>
            {formattedExpiryDate}
          </Text>
          <Text size='sm' className='text-critical'>
            Expired
          </Text>
        </>
      ) : (
        <Text size='sm'>{formattedExpiryDate}</Text>
      );
    },
  },
  {
    id: 'addedBy',
    header: 'ADDED BY',
    accessorKey: 'addedBy',
    size: 136,
    cell: (ctx) => {
      const { addedByUser } = ctx.row.original;
      return <Text size='sm'>{addedByUser.displayName}</Text>;
    },
  },
  {
    id: 'inApp',
    header: 'IN APP',
    accessorKey: 'isViewableOnApp',
    size: 80,
    cell: (ctx) => {
      const { isViewableOnApp } = ctx.row.original;
      return <>{isViewableOnApp ? <Check className='text-positive' /> : <Cross className='text-critical' />}</>;
    },
  },
  // {
  //   id: 'actions',
  //   header: '',
  //   accessorKey: 'documentId',
  //   size: 64,
  //   cell: (ctx) => {
  //     const { documentId } = ctx.row.original;
  //     return (
  //       <>
  //         <SilFileActionsDropdown documentId={documentId} />
  //       </>
  //     );
  //   },
  // },
];
