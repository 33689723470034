import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';

export type WithRouterProps<Params extends { [K in keyof Params]?: string } = {}> = {
  history: ReturnType<typeof useHistory>;
  location: ReturnType<typeof useLocation>;
  navigate: ReturnType<typeof useNavigate>;
  params: Params | Record<string, string>;
};

/**
 * @name
 * withRouter
 *
 * @description
 * Polyfill for React Router's `withRouter` (removed in v6).
 *
 * @see
 * https://reactrouter.com/en/main/start/faq#what-happened-to-withrouter-i-need-it
 *
 * @example
 * type MyComponentProps = { ... } & WithRouterProps
 * const MyComponent = (props: MyComponentProps) => { ... }
 * export default withRouter(MyComponent)
 */
export function withRouter<T extends WithRouterProps>(Component: React.ComponentType<T>) {
  function ComponentWithRouterProps(props: Omit<T, keyof WithRouterProps>) {
    const history = useHistory();
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...{ history, location, navigate, params }} {...(props as T)} />;
  }

  return ComponentWithRouterProps;
}
