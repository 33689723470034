import React, { useEffect } from 'react';
import _ from 'lodash';
import { Divider, Row } from 'antd';

import { Paragraph } from 'common-components/typography';

import {
  Header,
  MultiChoice,
  InlineEditInput,
  Dropdown,
  LongText,
  SingleChoiceElement,
  CheckboxElement,
  CurrencyInput,
  NumberInputElement,
  DateTimeElement,
  AddressLookup,
  PhoneNumber,
  FullName,
} from './form-elements';
import { IntakeFormElementType, FormElementType, PropKey } from '../shared/form-enum';
import { IFormElement, IFormContent } from '../shared/form-interface';
import { AttachmentInput } from './form-elements/AttachmentInput/AttachmentInput';
import { getValueFromPropertyByKey, isRelatedConditionalElement } from '../shared/form-builder-utils';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';

type IFormElementsRenderedProps = {
  formContent: IFormContent;
  timezone: string;
};

/**
 * Renders the form elements
 */
const FormElementsRendered = ({ formContent, timezone }: IFormElementsRenderedProps) => {
  const { elements } = formContent;
  const { formBuilderStore } = useDispatch<IRootDispatch>();
  const { elementsBundleOptional } = useSelector((state: IRootState) => state.formBuilderStore);

  useEffect(() => {
    checkBundleElements();
  }, [elements]);

  const checkBundleElements = () => {
    const { setElementsBundleOptional } = formBuilderStore;
    const bundlesOptional: string[] = [];

    elements.forEach((element) => {
      checkChildrenBundle(bundlesOptional, element);
    });

    setElementsBundleOptional(bundlesOptional);
  };

  const checkChildrenBundle = (bundlesOptional: string[], element: IFormElement) => {
    const { type, children } = element;
    const isRequired = getValueFromPropertyByKey(element.properties.general, PropKey.REQUIRE);

    if (type === FormElementType.MULTIPLE_ELEMENT && !isRequired && children?.length) {
      bundlesOptional.push(...children.map((children) => children.id));
    }

    if (children?.length) {
      children.forEach((item) => {
        checkChildrenBundle(bundlesOptional, item);
      });
    }
  };

  const _renderHeader = (element: IFormElement) => {
    let heading = '';
    let subHeading = '';
    _.forEach(element.properties.appearance, (config) => {
      if (config.key === 'headingText') {
        heading = config.value;
      } else if (config.key === 'subHeadingText') {
        subHeading = config.value || '';
      }
    });
    return (
      <Row className="mt-large">
        <Header headingText={heading} subHeadingText={subHeading} />
      </Row>
    );
  };

  const _renderInlineInput = (element: IFormElement) => {
    let placeholder = '';
    let caption = '';
    let isRequired = false;
    let fieldType = '';

    _.forEach(element.properties.appearance, (config) => {
      if (config.key === 'placeholderText') {
        placeholder = config.value;
      } else if (config.key === 'captionText') {
        caption = config.value;
      }
    });

    let fieldTitle = '';
    _.forEach(element.properties.general, (config) => {
      if (config.key === 'fieldTitle') {
        fieldTitle = config.value;
        fieldType = config?.fieldType || '';
      }
    });

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'require') {
        isRequired = config.value;
      }
    });

    isRequired = isRequired && !elementsBundleOptional.includes(element.id);

    return (
      <Row className="mt-large">
        <InlineEditInput
          fieldTitle={fieldTitle}
          caption={caption}
          placeholder={placeholder}
          isRequired={isRequired}
          fieldType={fieldType}
        />
      </Row>
    );
  };

  const _renderDropdown = (element: IFormElement) => {
    let title = '';
    let caption = '';
    let options = [];
    let defaultValue = [];
    let placeholder = '';
    let isRequired = false;

    _.forEach(element.properties.appearance, (config) => {
      if (config.key === 'captionText') {
        caption = config.value;
      }
    });

    element.properties.appearance.forEach((config) => {
      if (config.key === 'placeholderText') {
        placeholder = config.value;
      }
    });

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'fieldTitle') {
        title = config.value;
      }
    });

    _.forEach(element.properties.configuration, (config) => {
      if (config.key === 'options') {
        options = config.value;
      } else if (config.key === 'defaultOption') {
        defaultValue = config.value;
      }
    });

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'require') {
        isRequired = config.value;
      }
    });

    isRequired = isRequired && !elementsBundleOptional.includes(element.id);

    return (
      <Row className="mt-large">
        <Dropdown
          title={title}
          caption={caption}
          options={options}
          defaultValue={defaultValue}
          placeholder={placeholder}
          isRequired={isRequired}
        />
      </Row>
    );
  };

  const _renderMultiChoices = (element: IFormElement) => {
    let title = '';
    let caption = '';
    let options = [];
    let defaultValue = [];
    let isRequired = false;

    _.forEach(element.properties.appearance, (config) => {
      if (config.key === 'captionText') {
        caption = config.value;
      }
    });

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'fieldTitle') {
        title = config.value;
      }
    });

    _.forEach(element.properties.configuration, (config) => {
      if (config.key === 'options') {
        options = config.value;
      } else if (config.key === 'defaultOption') {
        defaultValue = config.value;
      }
    });

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'require') {
        isRequired = config.value;
      }
    });

    isRequired = isRequired && !elementsBundleOptional.includes(element.id);

    return (
      <Row className="mt-large">
        <MultiChoice
          title={title}
          caption={caption}
          options={options}
          defaultValue={defaultValue}
          isRequired={isRequired}
        />
      </Row>
    );
  };

  const _renderSingleChoice = (element: IFormElement) => {
    let title = '';
    let caption = '';
    let options = [];
    let defaultValue = '';
    let isRequired = false;

    _.forEach(element.properties.appearance, (config) => {
      if (config.key === 'captionText') {
        caption = config.value;
      }
    });

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'fieldTitle') {
        title = config.value;
      }
    });

    _.forEach(element.properties.configuration, (config) => {
      if (config.key === 'options') {
        options = config.value;
      } else if (config.key === 'defaultOption') {
        defaultValue = config.value;
      }
    });

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'require') {
        isRequired = config.value;
      }
    });

    isRequired = isRequired && !elementsBundleOptional.includes(element.id);

    return (
      <Row className="mt-large">
        <SingleChoiceElement
          title={title}
          caption={caption}
          options={options}
          defaultValue={defaultValue}
          isRequired={isRequired}
        />
      </Row>
    );
  };

  const _renderParagraph = (element: IFormElement) => {
    let value = '';

    _.forEach(element.properties.appearance, (config) => {
      if (config.key === 'paragraphText') {
        value = config.value;
      }
    });
    return (
      <Row className="mt-large">
        <Paragraph className="whitespace-pre-line">{value}</Paragraph>
      </Row>
    );
  };

  const _renderDivider = () => {
    return (
      <Row>
        <Divider />
      </Row>
    );
  };

  const _renderLongText = (element: IFormElement) => {
    let placeholder = '';
    let caption = '';
    let isRequired = false;

    _.forEach(element.properties.appearance, (config) => {
      if (config.key === 'placeholderText') {
        placeholder = config.value;
      } else if (config.key === 'captionText') {
        caption = config.value;
      }
    });

    let fieldTitle = '';
    _.forEach(element.properties.general, (config) => {
      if (config.key === 'fieldTitle') {
        fieldTitle = config.value;
      }
    });

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'require') {
        isRequired = config.value;
      }
    });

    isRequired = isRequired && !elementsBundleOptional.includes(element.id);

    return (
      <Row className="mt-large">
        <LongText fieldTitle={fieldTitle} caption={caption} placeholder={placeholder} isRequired={isRequired} />
      </Row>
    );
  };

  const _renderCheckbox = (element: IFormElement) => {
    let title = '';
    let caption = '';
    let defaultValue;
    let text = '';
    let isRequired = false;

    _.forEach(element.properties.appearance, (config) => {
      if (config.key === 'captionText') {
        caption = config.value;
      } else if (config.key === 'checkboxText') {
        text = config.value;
      }
    });

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'fieldTitle') {
        title = config.value;
      }
    });

    _.forEach(element.properties.configuration, (config) => {
      if (config.key === 'checkboxOptions') {
        defaultValue = config.defaultChecked;
      }
    });

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'require') {
        isRequired = config.value;
      }
    });

    isRequired = isRequired && !elementsBundleOptional.includes(element.id);

    return (
      <Row className="mt-large">
        <CheckboxElement
          title={title}
          caption={caption}
          defaultValue={defaultValue}
          text={text}
          isRequired={isRequired}
        />
      </Row>
    );
  };

  const _renderCurrency = (element: IFormElement) => {
    let isRequired = false;

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'require') {
        isRequired = config.value;
      }
    });

    return (
      <Row className="mt-large">
        <CurrencyInput
          fieldTitle={element.properties.general.find((prop) => prop.key === 'fieldTitle').value}
          placeholder={element.properties.appearance.find((prop) => prop.key === 'placeholderText').value}
          caption={element.properties.appearance.find((prop) => prop.key === 'captionText').value}
          value={
            element.properties.configuration.find((prop) => prop.key === 'default').value
              ? element.properties.configuration.find((prop) => prop.key === 'defaultValue').value
              : ''
          }
          isRequired={isRequired}
        />
      </Row>
    );
  };

  const _renderDateTime = (element: IFormElement) => {
    let isRequired = false;
    _.forEach(element.properties.general, (config) => {
      if (config.key === 'require') {
        isRequired = config.value;
      }
    });
    const fieldDateOfBirth = element.properties.general.find((prop) => prop.key === 'fieldTitle');
    const isDateOfBirth = fieldDateOfBirth.fieldType === IntakeFormElementType.GENERAL_INFORMATION_DATE_OF_BIRTH;

    return (
      <Row className="mt-large">
        <DateTimeElement
          timezone={timezone}
          key={element.id}
          fieldTitle={element.properties.general.find((prop) => prop.key === 'fieldTitle').value}
          caption={element.properties.appearance.find((prop) => prop.key === 'captionText').value}
          dateTimeType={element.properties.configuration.find((prop) => prop.key === 'dateTimeType').value}
          constraint={element.properties.configuration.find((prop) => prop.key === 'constraint').value}
          isRequired={isRequired}
          isDateOfBirth={isDateOfBirth}
        />
      </Row>
    );
  };

  const _renderFileUpload = (element: IFormElement) => {
    let isRequired = false;

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'require') {
        isRequired = config.value;
      }
    });

    return (
      <Row className="mt-large">
        <AttachmentInput element={element} isRequired={isRequired} />
      </Row>
    );
  };

  const _renderNumberInput = (element: IFormElement) => {
    let isRequired = false;

    _.forEach(element.properties.general, (config) => {
      if (config.key === 'require') {
        isRequired = config.value;
      }
    });

    return (
      <Row className="mt-large">
        <NumberInputElement
          fieldTitle={element.properties.general.find((prop) => prop.key === 'fieldTitle').value}
          placeholder={element.properties.appearance.find((prop) => prop.key === 'placeholderText').value}
          caption={element.properties.appearance.find((prop) => prop.key === 'captionText').value}
          value={
            element.properties.configuration.find((prop) => prop.key === 'default').value
              ? element.properties.configuration.find((prop) => prop.key === 'defaultValue').value
              : ''
          }
          isRequired={isRequired}
        />
      </Row>
    );
  };

  const renderDropdownMultiSelect = (element: IFormElement) => {
    const isRequired =
      element.properties.general.find((prop) => prop.key === PropKey.REQUIRE).value &&
      !elementsBundleOptional.includes(element.id);

    return (
      <Row className="mt-large">
        <Dropdown
          title={element.properties.general[0].value}
          caption={element.properties.appearance.find((prop) => prop.key === PropKey.CAPTION_TEXT).value}
          options={element.properties.configuration[0].value}
          defaultValue={element.properties.configuration?.[1]?.value || []}
          isRequired={isRequired}
          isMultiSelect={true}
          placeholder={element.properties.appearance.find((prop) => prop.key === PropKey.PLACEHOLDER_TEXT)?.value}
        />
      </Row>
    );
  };

  const renderAddressLookup = (element: IFormElement) => {
    const isRequired =
      element.properties.general.find((prop) => prop.key === PropKey.REQUIRE).value &&
      !elementsBundleOptional.includes(element.id);

    return (
      <Row className="mt-large">
        <AddressLookup
          fieldTitle={element.properties.general.find((prop) => prop.key === PropKey.FIELD_TITLE).value}
          placeholder={element.properties.appearance.find((prop) => prop.key === PropKey.PLACEHOLDER_TEXT).value}
          caption={element.properties.appearance.find((prop) => prop.key === PropKey.CAPTION_TEXT).value}
          isRequired={isRequired}
        />
      </Row>
    );
  };

  const renderPhoneNumber = (element: IFormElement) => {
    const isRequired =
      element.properties.general.find((prop) => prop.key === PropKey.REQUIRE).value &&
      !elementsBundleOptional.includes(element.id);

    return (
      <Row className="mt-large">
        <PhoneNumber
          fieldTitle={element.properties.general.find((prop) => prop.key === PropKey.FIELD_TITLE).value}
          caption={element.properties.appearance.find((prop) => prop.key === PropKey.CAPTION_TEXT).value}
          isRequired={isRequired}
        />
      </Row>
    );
  };

  const renderFirstAndLastName = (element: IFormElement) => {
    const isRequired =
      element.properties.general.find((prop) => prop.key === PropKey.REQUIRE).value &&
      !elementsBundleOptional.includes(element.id);

    return (
      <Row className="mt-large">
        <FullName
          fieldTitleFirstName={element.properties.general[0].value}
          fieldTitleLastName={element.properties.general[1].value}
          placeholderFirstName={element.properties.appearance[0].value}
          placeholderLastName={element.properties.appearance[1].value}
          caption={element.properties.appearance.find((prop) => prop.key === PropKey.CAPTION_TEXT).value}
          isRequired={isRequired}
        />
      </Row>
    );
  };

  const _renderElements = () => {
    const { elements } = formContent;
    if (_.isEmpty(elements)) return null;
    else {
      const newElements = [];
      elements.forEach((element) => {
        if (element.type === FormElementType.MULTIPLE_ELEMENT) {
          newElements.push(...element.children);
        } else {
          newElements.push(element);
        }
      });
      return (
        <>
          {newElements.map((element) => {
            if (isRelatedConditionalElement(elements, element)) {
              // Don't render element if its dependent on a conditional element and evaluates to false
              return null;
            }

            switch (element.type) {
              case FormElementType.HEADER:
                return _renderHeader(element);
              case FormElementType.SHORT_TEXT:
                return _renderInlineInput(element);
              case FormElementType.DROPDOWN:
                return _renderDropdown(element);
              case FormElementType.MULTI_CHOICE:
                return _renderMultiChoices(element);
              case FormElementType.PARAGRAPH:
                return _renderParagraph(element);
              case FormElementType.DIVIDER:
                return _renderDivider();
              case FormElementType.LONG_TEXT:
                return _renderLongText(element);
              case FormElementType.SINGLE_CHOICE:
                return _renderSingleChoice(element);
              case FormElementType.CHECKBOX:
                return _renderCheckbox(element);
              case FormElementType.CURRENCY:
                return _renderCurrency(element);
              case FormElementType.NUMBER:
                return _renderNumberInput(element);
              case FormElementType.DATE_TIME:
                return _renderDateTime(element);
              case FormElementType.FILE_UPLOAD:
                return _renderFileUpload(element);
              case FormElementType.DROPDOWN_MULTI_SELECT:
                return renderDropdownMultiSelect(element);
              case FormElementType.ADDRESS_LOOKUP:
                return renderAddressLookup(element);
              case FormElementType.PHONE_NUMBER:
                return renderPhoneNumber(element);
              case FormElementType.FIRST_LAST_NAME:
                return renderFirstAndLastName(element);
            }
          })}
        </>
      );
    }
  };
  return _renderElements();
};

export default FormElementsRendered;
