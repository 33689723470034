// TODO (Mitch): Replace with DS
import React, { forwardRef, HTMLAttributes, useRef } from 'react';

import { AlertCircle } from '@good/icons';
import { Inline, Stack, Text } from '@goodhuman-me/components';
import { css } from '@goodhuman-me/components';
import { mergeRefs } from '@react-aria/utils';
import { useController, UseControllerProps } from 'react-hook-form';
import { mergeProps, useFocusRing, useHover, useTextField } from 'react-aria';

import type { InputHTMLAttributes, RefObject } from 'react';

const styles = {
  base: css({
    appearance: 'none',
    color: 'black',
    height: '44px',
    width: '100%',
    background: 'white',
    borderWidth: '$border100',
    borderStyle: 'solid',
    borderRadius: '6px',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: '$primary',
    paddingX: '12px',
    outline: 'none !important',
    borderColor: '$body',
    transition: 'all 50ms linear',

    variants: {
      isFocused: {
        true: { borderColor: '$accent' },
        false: { borderColor: '$body' },
      },
      isFocusVisible: {
        true: {
          outlineWidth: '2px !important',
          outlineStyle: 'solid !important',
          outlineOffset: '-1px !important',
        },
      },
      isError: {
        true: {},
        false: {},
      },
    },
    compoundVariants: [
      {
        isFocused: true,
        isError: true,
        css: {
          borderColor: '$red',
        },
      },
      {
        isFocusVisible: true,
        isError: true,
        css: {
          outlineColor: '$red !important',
        },
      },
      {
        isFocusVisible: true,
        isError: false,
        css: {
          outlineColor: '$accent !important',
        },
      },
    ],
  }),
};

type LabelProps = ReturnType<typeof useTextField>['labelProps'];

export function Label(props: LabelProps) {
  const { children, ...otherProps } = props;

  return (
    <Text fontWeight="$bold" fontSize="$small" asChild>
      <label {...otherProps}>{children}</label>
    </Text>
  );
}

export const TextField = forwardRef<HTMLInputElement, {}>(function TextField(props, ref) {
  let { errorMessage, inputRef, label } = props;
  let mergedRef = mergeRefs(ref, inputRef, useRef(null));
  const { focusProps, isFocusVisible, isFocused } = useFocusRing({ ...props, isTextInput: true });
  const { hoverProps, isHovered } = useHover(props);
  let { descriptionProps, errorMessageProps, inputProps, labelProps } = useTextField(
    props,
    mergedRef as RefObject<HTMLInputElement>,
  );
  inputProps = mergeProps(focusProps, hoverProps, inputProps);

  return (
    <Stack gap="$space100">
      <Label {...labelProps}>{label}</Label>

      <input
        {...(inputProps as InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>)}
        ref={mergedRef}
        className={styles.base({
          isHovered,
          isFocused,
          isFocusVisible,
          isError: Boolean(errorMessage),
        })}
      />

      {errorMessage && (
        <Text color="$critical" size="small">
          <Inline alignItems="center" gap="$space50">
            <AlertCircle />
            <span role="alert" {...errorMessageProps}>
              {errorMessage}
            </span>
          </Inline>
        </Text>
      )}
    </Stack>
  );
});

export const TextFormField = forwardRef<HTMLInputElement, UseControllerProps & HTMLAttributes<HTMLInputElement>>(
  function TextFormField(props, ref) {
    const {
      field: { ref: inputRef, name, onBlur, onChange, value },
      fieldState: { error },
    } = useController(props);

    return (
      <TextField
        {...props}
        errorMessage={error?.message}
        inputRef={inputRef}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
        value={value}
      />
    );
  },
);
