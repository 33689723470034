import { css } from '../theme';
import { keyframes } from '@stitches/react';
import type * as Stitches from '@stitches/react';
import type { CSS } from '../theme';

export const reset: CSS = {
  appearance: 'none',
  boxSizing: 'border-box',
  cursor: 'pointer',
  outline: 'none',
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  touchAction: 'none',
  verticalAlign: 'top',
  transition: '$transition150',
};

export const button: CSS = {
  $$buttonBorderRadius: '6px',
  $$buttonGap: '8px',

  gap: '$$buttonGap',
  borderWidth: '$$buttonBorderWidth',
  borderStyle: 'solid',
  borderRadius: '$$buttonBorderRadius',
  fontFamily: '$primary',
};

export const variants = css({
  // Kind
  backgroundColor: '$$buttonBackgroundColor !important',
  borderColor: '$$buttonBorderColor !important',
  color: '$$buttonTextColor !important',

  // Size
  height: '$$buttonHeight',
  paddingLeft: '$$buttonPaddingX',
  paddingRight: '$$buttonPaddingX',
  paddingTop: '$space0',
  paddingBottom: '$space0',
  fontSize: '$$buttonFontSize',
  fontWeight: '$$buttonFontWeight',
  lineHeight: '$$buttonLineHeight',

  variants: {
    kind: {
      accent: {},
      critical: {},
      body: {},
    },
    emphasis: {
      filled: {},
      outlined: {},
      quiet: {},
    },
    onlyIcon: {
      true: {},
    },
    fitContent: {
      true: {
        padding: '$none',
        height: 'fit-content',
      },
    },
    size: {
      xsmall: {
        $$buttonBorderWidth: '1px',
        $$buttonFontSize: '11px',
        $$buttonFontWeight: '700',
        $$buttonHeight: '24px',
        $$buttonLineHeight: '14px',
        $$buttonPaddingX: '8px',
      },
      small: {
        $$buttonBorderWidth: '1.5px',
        $$buttonFontSize: '14px',
        $$buttonFontWeight: '600',
        $$buttonHeight: '32px',
        $$buttonLineHeight: '22px',
        $$buttonPaddingX: '16px',
      },
      medium: {
        $$buttonBorderWidth: '1.5px',
        $$buttonFontSize: '14px',
        $$buttonFontWeight: '700',
        $$buttonHeight: '44px',
        $$buttonLineHeight: '20px',
        $$buttonPaddingX: '18px',
      },
      large: {
        $$buttonBorderWidth: '1.5px',
        $$buttonFontSize: '16px',
        $$buttonFontWeight: '700',
        $$buttonHeight: '56px',
        $$buttonLineHeight: '22px',
        $$buttonPaddingX: '24px',
      },
    },
    isHovered: {
      true: {
        backgroundColor: '$$buttonBackgroundColorHover',
        borderColor: '$$buttonBorderColorHover',
      },
    },
    isPressed: {
      true: {
        backgroundColor: '$$buttonBackgroundColorPressed',
        borderColor: '$$buttonBorderColorPressed',
      },
    },
    isDisabled: {
      true: {
        backgroundColor: '$bodyLight !important',
        borderColor: 'transparent !important',
        color: '$bodyDark2 !important',
        cursor: 'auto',
      },
    },
    isFocused: {
      true: {
        $$buttonOutlineWidth: '2px',
        $$buttonOutlineOffset: '2px',

        outlineStyle: 'solid',
        outlineWidth: '$$buttonOutlineWidth',
        outlineOffset: '$$buttonOutlineOffset',
        outlineColor: '$$buttonOutlineColor',
      },
    },
    isLoading: {
      true: {
        opacity: 0.5,
        cursor: 'wait',
      },
    },
  },
  compoundVariants: [
    {
      onlyIcon: true,
      size: 'xsmall',
      css: {
        $$buttonPaddingX: '$space-space30',
      },
    },
    {
      onlyIcon: true,
      size: 'small',
      css: {
        $$buttonPaddingX: '$space-space85',
      },
    },
    {
      onlyIcon: true,
      size: 'medium',
      css: {
        $$buttonPaddingX: '$space-space150',
      },
    },
    {
      onlyIcon: true,
      size: 'large',
      css: {
        $$buttonPaddingX: '$space-space225',
      },
    },
    {
      kind: 'accent',
      emphasis: 'filled',
      css: {
        $$buttonBackgroundColor: '$colors-accent',
        $$buttonBorderColor: '$colors-accent',
        $$buttonTextColor: '$colors-white',
        $$buttonOutlineColor: '$colors-accent',
        $$buttonBackgroundColorHover: '$colors-accentHover',
        $$buttonBackgroundColorPressed: '$colors-accentPressed',
        $$buttonBorderColorHover: '$colors-accentHover',
        $$buttonBorderColorPressed: '$colors-accentPressed',
      },
    },
    {
      kind: 'accent',
      emphasis: 'outlined',
      css: {
        $$buttonBackgroundColor: '$colors-white',
        $$buttonBorderColor: '$colors-accent',
        $$buttonTextColor: '$colors-accent',
        $$buttonOutlineColor: '$colors-accent',
        $$buttonBackgroundColorHover: '$colors-accentBackgroundSecondary',
        $$buttonBackgroundColorPressed: '$colors-accentBackground',
        $$buttonBorderColorHover: '$colors-accent',
        $$buttonBorderColorPressed: '$colors-accent',
      },
    },
    {
      kind: 'accent',
      emphasis: 'quiet',
      css: {
        $$buttonBackgroundColor: '$colors-transparent',
        $$buttonBorderColor: '$colors-transparent',
        $$buttonTextColor: '$colors-accent',
        $buttonOutlineColor: '$colors-accent',
        $$buttonBackgroundColorHover: '$colors-accentBackgroundSecondary',
        $$buttonBackgroundColorPressed: '$colors-accentBackground',
        $$buttonBorderColorHover: '$colors-transparent',
        $$buttonBorderColorPressed: '$colors-transparent',
      },
    },
    {
      kind: 'body',
      emphasis: 'filled',
      css: {
        $$buttonBackgroundColor: '$colors-bodyLight2',
        $$buttonBorderColor: '$colors-bodyLight1',
        $$buttonTextColor: '$colors-bodyDark2',
        $$buttonOutlineColor: '$colors-accent',
        $$buttonBackgroundColorHover: '$colors-neutralLightBackgroundHover',
        $$buttonBackgroundColorPressed: '$colors-neutralLightBackgroundPressed',
        $$buttonBorderColorHover: '$colors-neutralLightBackgroundHover',
        $$buttonBorderColorPressed: '$colors-neutralLightBackgroundPressed',
      },
    },
    {
      kind: 'body',
      emphasis: 'outlined',
      css: {
        $$buttonBackgroundColor: '$colors-white',
        $$buttonBorderColor: '$colors-bodyLight1',
        $$buttonTextColor: '$colors-bodyDark2',
        $$buttonOutlineColor: '$colors-accent',
        $$buttonBackgroundColorHover: '$colors-neutralLightBackgroundHover',
        $$buttonBackgroundColorPressed: '$colors-neutralLightBackgroundPressed',
        $$buttonBorderColorHover: '$colors-neutralLightBackgroundHover',
        $$buttonBorderColorPressed: '$colors-neutralLightBackgroundPressed',
      },
    },
    {
      kind: 'body',
      emphasis: 'quiet',
      css: {
        $$buttonBackgroundColor: '$colors-transparent',
        $$buttonBorderColor: '$colors-transparent',
        $$buttonTextColor: '$colors-accent',
        $buttonOutlineColor: '$colors-accent',
        $$buttonBackgroundColorHover: '$colors-neutralLightBackgroundHover',
        $$buttonBackgroundColorPressed: '$colors-neutralLightBackgroundPressed',
        $$buttonBorderColorHover: '$colors-neutralLightBackgroundHover',
        $$buttonBorderColorPressed: '$colors-neutralLightBackgroundPressed',
      },
    },
    {
      kind: 'critical',
      emphasis: 'filled',
      css: {
        $$buttonBackgroundColor: '$colors-critical',
        $$buttonBorderColor: '$colors-critical',
        $$buttonTextColor: '$colors-white',
        $$buttonOutlineColor: '$colors-critical',
        $$buttonBackgroundColorHover: '$colors-criticalHover',
        $$buttonBackgroundColorPressed: '$colors-criticalPressed',
        $$buttonBorderColorHover: '$colors-criticalHover',
        $$buttonBorderColorPressed: '$colors-criticalPressed',
      },
    },
    {
      kind: 'critical',
      emphasis: 'outlined',
      css: {
        $$buttonBackgroundColor: '$colors-white',
        $$buttonBorderColor: '$colors-critical',
        $$buttonTextColor: '$colors-critical',
        $$buttonOutlineColor: '$colors-critical',
        $$buttonBackgroundColorHover: '$colors-criticalBackgroundPressed',
        $$buttonBackgroundColorPressed: '$colors-criticalBackgroundHover',
        $$buttonBorderColorHover: '$colors-critical',
        $$buttonBorderColorPressed: '$colors-critical',
      },
    },
    {
      kind: 'critical',
      emphasis: 'quiet',
      css: {
        $$buttonBackgroundColor: '$colors-transparent',
        $$buttonBorderColor: '$colors-transparent',
        $$buttonTextColor: '$colors-critical',
        $buttonOutlineColor: '$colors-critical',
        $$buttonBackgroundColorHover: '$colors-criticalBackgroundPressed',
        $$buttonBackgroundColorPressed: '$colors-criticalBackgroundHover',
        $$buttonBorderColorHover: '$colors-transparent',
        $$buttonBorderColorPressed: '$colors-transparent',
      },
    },
  ],
  defaultVariants: {
    kind: 'accent',
    emphasis: 'outlined',
  },
});

export type ButtonVariants = Stitches.VariantProps<typeof variants>;

// Loading icon

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const loadingIcon: CSS = {
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  animation: `${spin} 1.5s linear infinite;`,
};
