import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Avatar, Form, Icon, notification } from 'antd';
import { Text } from 'common-components/typography';
import { usePrevious } from 'utilities/hook-utilities';
import { useAsync } from 'react-use';

const { Option } = Select;

export type ActivityRecordTeamMemberSelectorProps = {
  form: WrappedFormUtils<Form>;
  selectedWorkerId: string;
  selectedServiceId: string;
  onSelectTeamMember: (selectedWorkerId: string) => void;
  isBlockedTeamMember: boolean;
};

export const ActivityRecordTeamMemberSelector = ({
  form,
  selectedServiceId,
  selectedWorkerId,
  onSelectTeamMember,
  isBlockedTeamMember,
}: ActivityRecordTeamMemberSelectorProps) => {
  const {
    teamStore: { doFetchFilteredWorkerList },
  } = useDispatch<IRootDispatch>();
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);

  const workerList = useAsync(
    async () =>
      doFetchFilteredWorkerList({
        serviceIds: selectedServiceId ? [selectedServiceId] : [],
      }).catch((e) => notification.error({ message: 'Oops! Something went wrong.', description: e.error.type })),
    [selectedServiceId, doFetchFilteredWorkerList],
  );

  const prevLoadingTeamMember = usePrevious(workerList.loading);

  useEffect(() => {
    if (workerList.loading !== prevLoadingTeamMember && !workerList.loading && workerList.value) {
      if (
        !selectedWorkerId &&
        selectedServiceId &&
        _checkWorkerInRoster(workerList.value, portalUser.supportWorkerId)
      ) {
        onSelectTeamMember(portalUser.supportWorkerId);
        form.setFieldsValue({
          teamMember: portalUser.supportWorkerId,
        });
        return;
      }

      if (selectedWorkerId && !_checkWorkerInRoster(workerList.value, selectedWorkerId)) {
        const workerId = selectedWorkerId === portalUser.supportWorkerId ? undefined : selectedWorkerId;
        onSelectTeamMember(workerId);
        form.setFields({
          teamMember: {
            value: workerId,
            errors: selectedWorkerId !== portalUser.supportWorkerId && [
              <div key={1} className="align-center mt-small flex">
                <Icon type="close-circle" theme="filled" className="text-size-regular mr-x-small" />
                <Text type="danger" size="x-small">
                  Team member not in roster for selected service
                </Text>
              </div>,
            ],
          },
        });
        return;
      }

      onSelectTeamMember(selectedWorkerId || undefined);
      form.setFields({
        teamMember: {
          value: selectedWorkerId || undefined,
          errors: null,
        },
      });
    }
  }, [
    workerList,
    prevLoadingTeamMember,
    form,
    onSelectTeamMember,
    portalUser?.supportWorkerId,
    selectedServiceId,
    selectedWorkerId,
  ]);

  const _checkWorkerInRoster = (workerList: typeof workerList.value, supportWorkerId: string): boolean => {
    return workerList.find((w) => w.supportWorkerId === supportWorkerId);
  };

  const _onSelectTeamMember = (selectedWorkerId: string) => {
    form.setFields({
      teamMember: {
        value: selectedWorkerId,
        errors: null,
      },
    });

    onSelectTeamMember(selectedWorkerId);
  };

  return (
    <Form.Item>
      {form.getFieldDecorator('teamMember')(
        <Select
          placeholder="Select team member..."
          className="width-full"
          dropdownClassName="ant-select-dropdown-menu-5-item"
          size="large"
          showSearch
          filterOption
          optionFilterProp="label"
          showArrow={false}
          allowClear
          autoClearSearchValue
          onChange={_onSelectTeamMember}
        >
          {!workerList.loading &&
            workerList.value?.map((w) => (
              <Option key={w.supportWorkerId} value={w.supportWorkerId} label={`${w.firstName} ${w.lastName}`}>
                <Avatar size="small" icon="user" className="mr-medium" src={w.attachmentUrl} />
                <Text>
                  {w.firstName} {w.lastName} {portalUser.supportWorkerId === w.supportWorkerId && '(You)'}
                </Text>
              </Option>
            ))}
        </Select>,
      )}
      {isBlockedTeamMember && (
        <Text size="small" color="red" className="mt-small">
          Support worker is blocked by customer preference.
        </Text>
      )}
    </Form.Item>
  );
};
