import { useQuery } from '@tanstack/react-query';
import { fetchLanguageTags } from 'stores/queries/tags/tag-queries';

export function useFetchTagsLanguages() {
  const { data, remove, refetch, isFetching, isLoading, status, error } = useQuery(
    ['languages'],
    () => fetchLanguageTags(),
    {
      staleTime: Infinity,
      retry: true,
    },
  );

  return { data, remove, refetch, isFetching, isLoading, status, error };
}
