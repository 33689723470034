import React from 'react';
import { Heading, Illustrations, Split, Stack, Text } from '@goodhuman-me/components';
import { Link } from 'react-router-dom';

/**
 * @name
 * NotFound
 *
 * @description
 * 404 page.
 */
export function NotFound() {
  return (
    <Stack
      height="100%"
      maxWidth="calc(1040px - $size600)"
      justifyContent="center"
      marginTop="-64px"
      marginX="auto"
      paddingX="$space300"
    >
      <Split fraction="two-thirds" gap="$space400">
        <Stack justifyContent="center" gap="$space200">
          <Heading asChild size="xlarge">
            <h1>Oops! We{"'"}re only human</h1>
          </Heading>

          <Text>
            We{"'"}re not sure where this page went. Check the URL for proper spelling and capitalisation or head back{' '}
            <Text color="$accent" asChild>
              <Link to="/dashboard">home</Link>
            </Text>
            .
          </Text>
        </Stack>

        <Stack width="$size5000" justifyContent="center">
          <Illustrations.SpiltCoffee />
        </Stack>
      </Split>
    </Stack>
  );
}
