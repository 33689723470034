import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { TmMgmtSearch } from '../tm-mgmt-search';

type TmMgmtInviteSearchParams = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const TmMgmtInviteSearch = ({ isLoading, setIsLoading }: TmMgmtInviteSearchParams) => {
  const { invitesTeamFilter } = useSelector((state: IRootState) => state.teamStore);
  const {
    teamStore: { setInvitesTeamFilter, doFetchInvitations },
  } = useDispatch<IRootDispatch>();

  const searchText = async (txt: string) => {
    setInvitesTeamFilter({ ...invitesTeamFilter, search: txt });
    await doFetchInvitations({});

    setIsLoading(false);
  };

  return (
    <div className="pb-medium flex-row bg-white">
      <TmMgmtSearch
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        defaultSearchText={invitesTeamFilter?.search ?? ''}
        searchText={searchText}
      />
    </div>
  );
};
