import React from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';
import { Text } from 'common-components/typography';

type BillingLineItemOptionMenuProps = {
  onClickDelete: () => void;
  onClickChangeBillable: () => void;
  isBillable: boolean;
};

const BillingLineItemOptionMenu = ({
  onClickDelete,
  onClickChangeBillable,
  isBillable,
}: BillingLineItemOptionMenuProps) => {
  const displayText = `Mark as ${isBillable ? 'non-billable' : 'billable'}`;
  return (
    <Menu>
      <MenuItem text={<Text>{displayText}</Text>} className="hover-bg-green-lightest" onClick={onClickChangeBillable} />
      <MenuItem text={<Text color="red">Delete</Text>} className="hover-bg-green-lightest" onClick={onClickDelete} />
    </Menu>
  );
};

export default BillingLineItemOptionMenu;
