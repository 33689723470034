import type { PropsWithChildren, Ref } from 'react';
import React, { createContext } from 'react';
import type { IconProps } from '..';
import { IconContext } from '../icon/icon-provider';
import { LinkProps } from './link';
import type { LinkVariants } from './link.css';

type LinkElement = Ref<HTMLLinkElement>;
export type LinkProviderProps = LinkProps & {
  linkRef?: LinkElement;
};

export const LinkContext = createContext<LinkProviderProps>({} as LinkProviderProps);

export function LinkProvider(props: LinkProviderProps) {
  const { children, ...otherProps } = props;

  return <LinkContext.Provider value={otherProps}>{children}</LinkContext.Provider>;
}

export type LinkIconProviderProps = PropsWithChildren<IconProps & LinkVariants>;

export function LinkIconProvider(props: LinkIconProviderProps) {
  const { children, size, ...otherProps } = props;
  let height;
  let width;

  switch (size) {
    case 'small':
      height = '$size160';
      width = '$size160';
      break;
    case 'medium':
      height = '$size160';
      width = '$size160';
      break;
    case 'large':
      height = '$size200';
      width = '$size200';
      break;
  }

  const providerProps = { ...otherProps, height, width };

  return <IconContext.Provider value={providerProps}>{children}</IconContext.Provider>;
}
