import React, { useCallback } from 'react';
import clsx from 'clsx';

import { AppShell } from '@good/ui/core';
import { useDisclosure } from '@good/ui/hooks';
import { Navbar } from './navbar';
import { Header } from './header';
import { useFetchCompanyDataLite } from 'utilities/react-hooks/use-fetch-company-data-lite';
import { useUpdatePageTitle } from './use-update-page-title';
import { useIsPrintableScreen } from './use-is-printable-screen';
import { useAutoCloseNavbarMobile } from './use-auto-close-navbar-mobile';
import { useSyncNavbarOpenStateWithStore } from './use-sync-navbar-open-state-with-store';

import './app-container.css';

export const headerLayout = {
  height: 60,
  zIndex: 18,
} as const;
export const navbarLayout = {
  width: 216,
  zIndex: 19,
} as const;

type AppContainerProps = {
  children: React.ReactNode;
};

export const AppContainer = ({ children }: AppContainerProps) => {
  useUpdatePageTitle('GoodHuman');
  useFetchCompanyDataLite();
  const isPrintableScreen = useIsPrintableScreen();
  const [navbarOpenDesktop, { toggle: toggleNavbarOpenDesktop }] = useDisclosure(true);
  const [navbarOpenMobile, { toggle: toggleNavbarOpenMobile, close: closeNavbarMobile }] = useDisclosure(false);
  const closeNavbarMobileCallback = useCallback(() => closeNavbarMobile(), [closeNavbarMobile]);
  useSyncNavbarOpenStateWithStore(navbarOpenDesktop, navbarOpenMobile);
  useAutoCloseNavbarMobile(closeNavbarMobileCallback);

  return (
    <AppShell
      header={{ height: headerLayout.height }}
      navbar={{
        width: navbarLayout.width,
        breakpoint: 'sm',
        collapsed: { mobile: !navbarOpenMobile, desktop: !navbarOpenDesktop },
      }}
      padding={0}
      className={clsx(isPrintableScreen && 'printable-screen', 'h-screen')}
    >
      <AppShell.Header zIndex={headerLayout.zIndex}>
        <Header
          py={16}
          px={{ base: 'md', sm: 'lg', md: 32 }}
          h={headerLayout.height}
          navbarOpenDesktop={navbarOpenDesktop}
          navbarOpenMobile={navbarOpenMobile}
          toggleNavbarOpenDesktop={toggleNavbarOpenDesktop}
          toggleNavbarOpenMobile={toggleNavbarOpenMobile}
        />
      </AppShell.Header>

      <AppShell.Navbar zIndex={navbarLayout.zIndex}>
        <Navbar onLinkSelect={closeNavbarMobileCallback} />
      </AppShell.Navbar>

      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
};
