import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { notification } from 'antd';
import { Paragraph } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../stores/rematch/root-store';
import { TransportAttendanceType } from '../../../../utilities/enum-utils';

interface IWaiveTransportBookingModalProps {
  isOpen: boolean;
  onClose: () => void;
  booking: any;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  doWaiveTransportBooking: typeof dispatch.groupBookingsStore.doWaiveTransportBooking;
  attendanceType: TransportAttendanceType;
}

interface IWaiveTransportBookingModalState {
  step: number;
  canManuallyClose: boolean;
  title: string;
}

class WaiveTransportBookingModal extends Component<IWaiveTransportBookingModalProps, IWaiveTransportBookingModalState> {
  state = { step: 1, canManuallyClose: true, title: 'Waive customer booking' };

  renderView = () => {
    const { step } = this.state;
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>Are you sure you want to waive this customers booking?</Paragraph>
          <Paragraph>This will waive all line items for this booking.</Paragraph>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this.onAccept}>
              Waive
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>You have successfully waived this customers booking.</Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ step: 1, title: 'Waive customer booking' });
    }
  }

  onAccept = async () => {
    const { booking, selectedGroupBookingItem, attendanceType } = this.props;
    try {
      this.setState({ canManuallyClose: false });
      await this.props.doWaiveTransportBooking({
        bookingId: selectedGroupBookingItem.bookingId,
        transportBookingId: booking.attendanceId,
        attendanceType,
      });
      this.setState({ step: 2, canManuallyClose: true, title: 'Booking waived' });
    } catch (e) {
      this.setState({ canManuallyClose: true });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: '' }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doWaiveTransportBooking: dispatch.groupBookingsStore.doWaiveTransportBooking,
});

export default connect(mapState, mapDispatch)(WaiveTransportBookingModal);
