import { BoxStyleProps } from '../theme';

export type Placement = 'bottom' | 'bottom left' | 'bottom right' | 'top left' | 'top right' | 'top center';
export type OffsetSide = { top?: number; bottom?: number; left?: number; right?: number };

export const getOverlayStyles = ({
  placement,
  offset = {},
  triggerPosition,
  boundingElement = '',
  isFullWidth = false,
}: {
  placement: Placement;
  offset?: OffsetSide;
  triggerPosition: DOMRect;
  boundingElement?: string;
  isFullWidth?: boolean;
}): BoxStyleProps => {
  const styles: BoxStyleProps = {
    position: 'fixed',
    minWidth: isFullWidth ? triggerPosition.width : 'unset',
  };

  let container = document.documentElement;
  if (boundingElement) {
    container = document.querySelector(boundingElement);
  }
  switch (placement) {
    case 'top right':
      styles.bottom = container.clientHeight - triggerPosition.top + (offset.top || 0);
      styles.right = container.clientWidth - triggerPosition.right - (offset.right || 0);
      break;
    case 'top left':
      styles.bottom = container.clientHeight - triggerPosition.top + (offset.top || 0);
      styles.left = triggerPosition.left - (offset.left || 0);
      break;
    case 'top center':
      styles.bottom = container.clientHeight - triggerPosition.top + (offset.top || 0);
      styles.left = triggerPosition.left;
      styles.transform = 'translateX(-50%)';
      break;
    case 'bottom right':
      styles.top = triggerPosition.bottom + (offset.bottom || 0);
      styles.right = container.clientWidth - triggerPosition.right;
      break;
    case 'bottom left':
      styles.top = triggerPosition.bottom + (offset.top || 0);
      styles.left = triggerPosition.left;
      break;
    case 'bottom':
      styles.top = triggerPosition.bottom + (offset.bottom || 0);
      styles.right = container.clientWidth - triggerPosition.right - triggerPosition.width / 2;
      break;
    default:
      styles.bottom = container.clientHeight - triggerPosition.top + (offset.top || 0);
      styles.right = container.clientWidth - triggerPosition.right - (offset.right || 0);
  }

  return styles;
};

export const getOverlayArrowStyles = (
  placement: Placement,
  triggerPosition: DOMRect,
  offset: OffsetSide = {},
): BoxStyleProps => {
  const styles: BoxStyleProps = {
    position: 'absolute',
  };
  const arrowWidth = 12;

  switch (placement) {
    case 'top right':
      styles.bottom = 1;
      styles.right = triggerPosition.width / 2 - arrowWidth / 2 + (offset.right || 0);
      styles.transform = 'rotate(180deg) translateY(-100%)';
      break;
    case 'top left':
      styles.bottom = 1;
      styles.left = triggerPosition.width / 2 - arrowWidth / 2 + (offset.left || 0);
      styles.transform = 'rotate(180deg) translateY(-100%)';
      break;
    case 'top center':
      styles.bottom = 1;
      styles.left = `calc(50% + ${triggerPosition.width / 2 - arrowWidth / 2}px)`;
      styles.transform = 'rotate(180deg) translateY(-100%)';
      break;
    default:
      styles.bottom = 1;
      styles.right = triggerPosition.width / 2 - arrowWidth / 2 + (offset.right || 0);
      styles.transform = 'rotate(180deg) translateY(-100%)';
  }

  return styles;
};

export const transformOutOfViewContent = (contentPosition: DOMRect, offset: OffsetSide = {}): string => {
  const overRight = contentPosition.right - document.documentElement.clientWidth;
  const overBottom = contentPosition.bottom - document.documentElement.clientHeight;

  const translateX = overRight > 0 ? -(overRight + (offset.right * 2 || 0)) : 0;
  const translateY = overBottom > 0 ? -(overBottom + (offset.bottom * 2 || 0)) : 0;

  return `translate(${translateX}px,${translateY}px)`;
};

export const flipOutOfViewContent = (
  contentPosition: DOMRect,
  triggerPosition: DOMRect,
  offset: OffsetSide = {},
): string => {
  const overRight = contentPosition.right - document.documentElement.clientWidth;
  const overBottom = contentPosition.bottom - document.documentElement.clientHeight;
  const isFlipLeft = overRight > 0;
  const isFlipTop = overBottom > 0;

  let translateFlipLeft = '';
  let translateFlipTop = '';

  if (isFlipLeft) {
    translateFlipLeft = `translateX(-100%) translateX(-${triggerPosition.width + (offset.right * 2 || 0)}px)`;
  }
  if (isFlipTop) {
    translateFlipTop = `translateY(-100%) translateY(-${triggerPosition.height + (offset.bottom * 2 || 0)}px)`;
  }

  return `${translateFlipLeft} ${translateFlipTop}`;
};
