import React from 'react';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Button } from 'design-components';
import { FieldLabel, Paragraph } from 'common-components/typography';
import { Avatar } from 'antd';
import moment from 'moment-timezone';
import { Text } from '@good/components';
import { TeamMember } from 'interfaces/team-member-interface';

type IPermanentlyRemoveUserModalStep2 = {
  worker: TeamMember;
  isSelectFutureDate: boolean;
  removeReason?: string;
  removalDate: Date;
  onClose: () => void;
};

export const PermanentlyRemoveUserModalStep2 = ({
  worker,
  isSelectFutureDate,
  removalDate,
  removeReason,
  onClose,
}: IPermanentlyRemoveUserModalStep2) => (
  <>
    <div className="mv-medium">
      <div className="mb-small">
        <FieldLabel text="USER" />
      </div>
      <div className="flex-row align-center">
        <Avatar shape="square" src={worker.attachmentUrl} className="mr-medium" />
        <Text size="lg">{worker.firstName + ' ' + worker.lastName}</Text>
        {!isSelectFutureDate && (
          <div className="ml-medium">
            <Text size="md" color="red-dark">
              REMOVED
            </Text>
          </div>
        )}
      </div>
    </div>
    {isSelectFutureDate && (
      <>
        <div className="mb-x2-small">
          <FieldLabel text="SCHEDULED REMOVAL DATE" />
        </div>
        <Paragraph>{moment(removalDate).format('DD/MM/YYYY')}</Paragraph>
      </>
    )}

    <div className="mb-x2-small">
      <FieldLabel text="REASON" />
    </div>

    <Paragraph>{removeReason}</Paragraph>

    <ActionModalFooter>
      <Button emphasis="filled" onClick={onClose}>
        Done
      </Button>
    </ActionModalFooter>
  </>
);
