import { Icon } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionSelectItem from 'common-components/items/ActionSelectItem';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import React from 'react';
import { SubscriptionPermissionType } from 'utilities/enum-utils';
import { SUBSCRIPTION_PERMISSION_LIST } from '../utils/constants';

interface ISubscriptionSectionModalProps {
  isOpen: boolean;
  selectedPermissionType?: SubscriptionPermissionType;
  onClose: () => void;
  onSubmit: () => void;
  onChange: (type: SubscriptionPermissionType) => void;
}

const SubscriptionSectionModal: React.FC<ISubscriptionSectionModalProps> = ({
  isOpen,
  selectedPermissionType,
  onClose,
  onSubmit,
  onChange,
}) => {
  return (
    <ActionModal
      title={
        <>
          <Icon type="folder-open" className="mr-small text-size-x2-large" />
          Subscription billing & access
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      width="medium"
    >
      <div className="mv-medium anim-slide-left">
        <div className="mb-x-large">
          <div className="mb-large">
            <Text size="x-large">Modify permissions</Text>
          </div>

          {SUBSCRIPTION_PERMISSION_LIST.map((permission) => (
            <ActionSelectItem
              key={permission.type}
              selectedAction={selectedPermissionType}
              value={permission.type}
              title={permission.label}
              description={permission.description}
              onSetAction={() => onChange(permission.type)}
            />
          ))}
        </div>

        <ActionModalFooter>
          <SecondaryButton size="large" className="mr-medium" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={onSubmit}>
            Save changes
          </PrimaryButton>
        </ActionModalFooter>
      </div>
    </ActionModal>
  );
};

export default SubscriptionSectionModal;
