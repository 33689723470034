import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const UserSquare = forwardRef(function UserSquare(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.962 1.25h.076c1.837 0 3.267 0 4.41.104 1.16.105 2.098.322 2.927.8a6.75 6.75 0 0 1 2.47 2.471c.48.83.696 1.767.802 2.927.103 1.143.103 2.573.103 4.41v.076c0 1.837 0 3.267-.104 4.41-.105 1.16-.322 2.098-.8 2.927a6.752 6.752 0 0 1-2.471 2.47c-.83.48-1.767.696-2.927.802-1.143.103-2.573.103-4.41.103h-.076c-1.837 0-3.267 0-4.41-.104-1.16-.105-2.098-.322-2.927-.8a6.75 6.75 0 0 1-2.47-2.471c-.48-.83-.696-1.767-.801-2.927-.104-1.143-.104-2.573-.104-4.41v-.076c0-1.837 0-3.267.104-4.41.105-1.16.322-2.098.8-2.927a6.75 6.75 0 0 1 2.471-2.47c.83-.48 1.767-.696 2.927-.801 1.143-.104 2.573-.104 4.41-.104ZM7.688 2.847c-1.057.096-1.75.281-2.313.606a5.25 5.25 0 0 0-1.922 1.922c-.325.563-.51 1.256-.606 2.313C2.751 8.755 2.75 10.117 2.75 12c0 1.883 0 3.245.097 4.312.096 1.057.281 1.75.606 2.313a5.25 5.25 0 0 0 1.922 1.922c.563.325 1.256.51 2.313.606 1.067.096 2.429.097 4.312.097 1.883 0 3.245 0 4.312-.097 1.057-.096 1.75-.281 2.313-.606a5.25 5.25 0 0 0 1.922-1.922c.325-.563.51-1.256.606-2.313.096-1.067.097-2.429.097-4.312 0-1.883 0-3.245-.097-4.312-.096-1.057-.281-1.75-.606-2.313a5.25 5.25 0 0 0-1.922-1.922c-.563-.325-1.256-.51-2.313-.606-1.067-.096-2.429-.097-4.312-.097-1.883 0-3.245 0-4.312.097Zm2.979 10.903a1.917 1.917 0 0 0-1.917 1.917c0 .322.261.583.583.583h5.334a.583.583 0 0 0 .583-.583 1.917 1.917 0 0 0-1.917-1.917h-2.666ZM7.25 15.667a3.417 3.417 0 0 1 3.417-3.417h2.666a3.417 3.417 0 0 1 3.417 3.417c0 1.15-.933 2.083-2.083 2.083H9.333a2.083 2.083 0 0 1-2.083-2.083ZM10.75 8a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM12 5.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});
