import { Item } from '@react-stately/collections';
import { TabListState, useTabListState } from '@react-stately/tabs';
import { Node, Orientation } from '@react-types/shared';
import { AriaTabListProps, AriaTabPanelProps, TabListProps } from '@react-types/tabs';
import React, { useRef } from 'react';
import { mergeProps, useFocusRing, useHover, usePress, useTab, useTabList, useTabPanel } from 'react-aria';
import { compose } from '../theme';
import { TabLabelIconProvider } from './tabs-provider';
import * as styles from './tabs.css';

interface TabProps extends styles.TabLabelVariants, styles.TabLabelWrapperVariants {
  item: Node<unknown>;
  state: TabListState<Record<string, unknown>>;
}

const Tab = (props: TabProps) => {
  const { item, state, size, underline } = props;

  const { key, rendered } = item;
  const ref = useRef();
  const { tabProps, isSelected, isDisabled } = useTab({ key }, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();
  const { isHovered, hoverProps } = useHover({ isDisabled });
  const { isPressed, pressProps } = usePress({ isDisabled });

  const tabLabelProps = mergeProps(tabProps, focusProps, hoverProps, pressProps);
  return (
    <TabLabelIconProvider size={size}>
      <div
        ref={ref}
        {...tabLabelProps}
        className={styles.tabLabelWrapper({
          size,
          underline,
          isActive: isSelected,
          isHovered,
          isFocused: isFocusVisible,
          isPressed,
        })}
      >
        <div
          className={compose(
            styles.tabLabel({ size, underline, isActive: isSelected, isHovered, isFocused: isFocusVisible, isPressed }),
          )}
        >
          {rendered}
        </div>
      </div>
    </TabLabelIconProvider>
  );
};

interface TabPanelProps extends AriaTabPanelProps {
  state: TabListState<Record<string, unknown>>;
  orientation: Orientation;
}

function TabPanel(props: TabPanelProps) {
  const { state, orientation, ...otherProps } = props;
  const ref = useRef();
  const { tabPanelProps } = useTabPanel(otherProps, state, ref);

  return (
    <div ref={ref} {...tabPanelProps} className={styles.tabPane({ orientation })}>
      {state.selectedItem?.props.children}
    </div>
  );
}

export interface TabsProps extends TabListProps<Record<string, unknown>>, AriaTabListProps<Record<string, unknown>> {
  /**
   * Whether tabs align full size
   */
  spread?: 'hug' | 'full';

  /**
   * Size of label
   */
  labelSize?: styles.TabLabelVariants['size'];

  /**
   * Whether tab label group have underline
   */
  underline?: boolean;
}

/**
 *
 * @name
 * Tab
 *
 * @props
 * defaultSelectedKey: React.Key
 * selectedKey: React.Key
 * onSelectionChange?: (key: React.Key) => void
 *
 * @example
 *  <Tabs defaultSelectedKey="1" selectedKey="1" onSelectionChange={(key) => console.log(key)}>
 *    <TabPane key="1">1</TabPane>
 *    <TabPane key="2">2</TabPane>
 *    <TabPane key="3">3</TabPane>
 *  </Tabs>
 */

export const Tabs = (props: TabsProps): JSX.Element => {
  const { orientation = 'horizontal', spread = 'hug', labelSize = 'medium', underline = true } = props;
  const ref = useRef();
  const state = useTabListState(props);
  const { tabListProps } = useTabList(props, state, ref);

  // Force orientation to be 'horizontal' when tab's underline is show. Due to no vertical design of tab's underline
  const forcedOrientation = underline ? 'horizontal' : orientation;

  /**
   * Need below code because current tsconfig file not config loop through React-aria's collections ( relate to 'downlevelIteration' flag )
   * Can use [...state.collection] instead if adding 'downlevelIteration' flag
   */
  const itemList: Node<unknown>[] = [];
  let currentKey = state.collection.getFirstKey();
  while (currentKey) {
    itemList.push(state.collection.getItem(currentKey));
    currentKey = state.collection.getKeyAfter(currentKey);
  }

  return (
    <div className={styles.tabContainer({ orientation: forcedOrientation })}>
      <div
        {...tabListProps}
        className={styles.tabLabelList({ orientation: forcedOrientation, spread, underline })}
        ref={ref}
      >
        {itemList.map((item) => (
          <Tab key={item.key} item={item} state={state} size={labelSize} underline={underline} />
        ))}
      </div>
      <TabPanel key={state.selectedItem?.key} state={state} orientation={forcedOrientation} />
    </div>
  );
};

export const TabPane = Item;
