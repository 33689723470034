import type { CountryCode } from '../types';
import AU from './AU.json';
import US from './US.json';

export type State = {
  name: string;
  abbreviation: string;
};

export const states: Record<CountryCode, State[]> = {
  AU,
  US,
};
