import React, { Component } from 'react';
import { Col, Form, Icon, Row, Tabs, Checkbox } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, Text, Title } from 'common-components/typography';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, state, IRootState } from 'stores/rematch/root-store';
import AddVCPLineItemsModal from 'views/services/listing/components/AddVCPLineItemsModal';
import NDISLineItemGrid from 'common-components/line-items/NDISLineItemGrid';

import NumberInput from 'common-components/inputs/NumberInput';
import { PaymentSources } from 'utilities/enum-utils';
const { TabPane } = Tabs;

interface IVCPSettingsStepPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
  vcpItems: typeof state.servicesStore.vcpItems;
}

interface IVCPSettingsStepPanelState {
  isOpen: boolean;
  serviceBillingItems: any;
  selectedTab: string;
  isWarningTravelPriceBefore: boolean;
  isWarningTravelPriceDuring: boolean;
}

class VCPSettingsStepPanel extends Component<IVCPSettingsStepPanelProps, IVCPSettingsStepPanelState> {
  state = {
    isOpen: false,
    serviceBillingItems: this.props.newService.vcpServiceBillingItems
      ? this.props.newService.vcpServiceBillingItems
      : [],
    selectedTab: 'lineItems',
    isWarningTravelPriceBefore: false,
    isWarningTravelPriceDuring: false,
  };

  private _closeLineItemModal = () => {
    this.setState({ isOpen: false });
  };

  private _openLineItemModal = () => {
    this.setState({ isOpen: true });
  };

  private _saveLineItem = (billingItems) => {
    let newBillingItems = _.clone(this.state.serviceBillingItems);
    _.forEach(billingItems, (lineItem, index) => {
      const isNewLineItemAlreadyExist = _.filter(newBillingItems, (item) => item.groupCode === billingItems.groupCode);
      if (_.isEmpty(isNewLineItemAlreadyExist)) {
        const groupBillingItems = _.filter(this.props.vcpItems, (vcpItem) => vcpItem.groupCode === lineItem.groupCode);
        _.forEach(groupBillingItems, (groupBillingItem) => {
          newBillingItems.push({
            supportItemNumber: groupBillingItem.supportItemNumber,
            supportCategoryNumber: 1,
            price: groupBillingItem.price,
            tax: 0,
            unit: groupBillingItem.unit,
            dateType: groupBillingItem.dateType,
            billingProvider: 'VCP',
            label: groupBillingItem.supportItemName,
            priceControl: groupBillingItem.priceControl,
            priority: index + 1,
            mileagePrice: 0,
            groupCode: groupBillingItem.groupCode,
            groupName: groupBillingItem.groupName,
            customerSupportLevel: '1', //TODO: Decide with product team if customerSupportLevel should still be used for automation.
          });
        });
      }
    });
    newBillingItems = _.filter(newBillingItems, (item) => {
      const isItemRemainInList = _.find(billingItems, (billingItem) => item.groupCode === billingItem.groupCode);
      return !!isItemRemainInList;
    });
    this.setState({ isOpen: false, serviceBillingItems: newBillingItems });
  };

  private _saveBillingLineItems = async () => {
    const { form, onNextStep, setNewService, newService } = this.props;
    const { serviceBillingItems } = this.state;

    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      const isChargeTransportDuringBooking = form.getFieldValue('isChargeTransportDuringBooking');
      const transportPriceDuringBooking = isChargeTransportDuringBooking
        ? form.getFieldValue('transportPriceDuringBooking')
        : 0;

      const newServiceClaimConfig = _.cloneDeep(newService.serviceClaimConfig);
      newServiceClaimConfig.isChargeVcpTransportDuringBooking = isChargeTransportDuringBooking;
      newServiceClaimConfig.vcpClaims.transportPriceDuringBooking = transportPriceDuringBooking;

      await setNewService({
        ...newService,
        vcpServiceBillingItems: serviceBillingItems,
        serviceClaimConfig: newServiceClaimConfig,
      });
      onNextStep();
    }
  };

  private _handleOnDelete = (billingLineItems) => {
    this.setState({ serviceBillingItems: billingLineItems });
  };

  private _handleSequenceChange = (billingLineItems) => {
    this.setState({ serviceBillingItems: billingLineItems });
  };

  private _handleOnPriceChange = (billingLineItems) => {
    this.setState({ serviceBillingItems: _.clone(billingLineItems) });
  };

  private _setSelectedTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  private _onChangeTravelPriceDuring(event) {
    if (event > 0 && event > 1) {
      this.setState({ isWarningTravelPriceDuring: true });
    } else {
      this.setState({ isWarningTravelPriceDuring: false });
    }
  }

  render() {
    const { onPreviousStep, form, newService } = this.props;
    const { serviceBillingItems } = this.state;
    const { getFieldDecorator } = form;

    return (
      <div className="anim-slide-left mb-x-large">
        <AddVCPLineItemsModal
          closeLineItemModal={this._closeLineItemModal}
          isOpen={this.state.isOpen}
          saveLineItem={this._saveLineItem}
          serviceBillingItems={this.state.serviceBillingItems}
        />
        <Title level={2} weight="bolder" className="line-height-100">
          Configure VCP settings
        </Title>

        <Paragraph>Configure the settings for VCP </Paragraph>

        <Tabs type="card" className={'customer-tab'} activeKey={this.state.selectedTab} onChange={this._setSelectedTab}>
          <TabPane key="lineItems" tab="VCP items">
            <Row>
              <Col span={20}>
                <GhostButton icon="plus" onClick={this._openLineItemModal}>
                  Add items
                </GhostButton>
              </Col>
            </Row>

            <Row className={'mt-medium'}>
              <NDISLineItemGrid
                displayMode={'EDIT'}
                isDeletable={false}
                isSequenceAdjustable={false}
                onDelete={this._handleOnDelete}
                onSequenceChange={this._handleSequenceChange}
                isPriceAdjustable={true}
                onPriceChange={this._handleOnPriceChange}
                lineItems={serviceBillingItems}
                onlyShowSpecificPrice={true}
                paymentSourceType={PaymentSources.VCP}
              />
            </Row>
          </TabPane>
          <TabPane key="claims" tab="VCP claims">
            <Row>
              <Col span={24}>
                <div className={'mt-medium mb-large'}>
                  {getFieldDecorator('isChargeTransportDuringBooking', {
                    valuePropName: 'checked',
                    initialValue:
                      newService.serviceClaimConfig && newService.serviceClaimConfig.isChargeVcpTransportDuringBooking
                        ? newService.serviceClaimConfig.isChargeVcpTransportDuringBooking
                        : false,
                  })(
                    <Checkbox>
                      Claim for worker travel <b>during</b> a booking.
                    </Checkbox>,
                  )}
                  <br />
                  {form.getFieldValue('isChargeTransportDuringBooking') && (
                    <Form.Item className={'m-none ml-large'}>
                      <div>
                        {getFieldDecorator('transportPriceDuringBooking', {
                          initialValue:
                            newService.serviceClaimConfig &&
                            newService.serviceClaimConfig.vcpClaims.transportPriceDuringBooking
                              ? newService.serviceClaimConfig.vcpClaims.transportPriceDuringBooking
                              : null,
                          rules: [
                            {
                              required: true,
                              message: 'Please enter a price.',
                            },
                          ],
                        })(
                          <NumberInput
                            min={0}
                            step={0.05}
                            max={9999}
                            onChange={(event) => this._onChangeTravelPriceDuring(event)}
                            className={'ml-x-small'}
                            addonBefore={'$'}
                            style={{ width: '120px' }}
                          />,
                        )}{' '}
                        price per km.
                        <br />
                        {this.state.isWarningTravelPriceDuring && (
                          <div className={'text-color-orange text-size-regular'} style={{ lineHeight: 'initial' }}>
                            The DCJ recommends that cost per km does not exceed $1.00
                          </div>
                        )}
                      </div>
                    </Form.Item>
                  )}
                </div>
              </Col>
            </Row>
          </TabPane>
        </Tabs>

        <div className="flex-row align-center mv-x2-large">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left" className="mr-large">
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this._saveBillingLineItems} icon="right" iconPosition="right">
            Next
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  vcpItems: state.servicesStore.vcpItems,
});

export default connect(mapState, null)(Form.create<IVCPSettingsStepPanelProps>()(VCPSettingsStepPanel));
