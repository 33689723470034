/* eslint-disable */
import React, { PureComponent, ReactNode } from 'react';

import { Text } from '../typography';
import {
  PORTAL_COLORS,
  PORTAL_CURSOR_TYPES,
  PORTAL_FONT_SIZES,
  PORTAL_FONT_WEIGHTS,
  PORTAL_LINE_HEIGHTS,
} from 'interfaces/common-ui-types';

interface HyperlinkButtonProps {
  color?: PORTAL_COLORS;
  hoverColor?: PORTAL_COLORS;
  cursor?: PORTAL_CURSOR_TYPES;
  fontSize?: PORTAL_FONT_SIZES;
  weight?: PORTAL_FONT_WEIGHTS;
  hoverUnderline?: boolean;
  underline?: boolean;
  onClick?: any;
  className?: string;
  style?: any;
  lineHeight?: PORTAL_LINE_HEIGHTS;

  children?: ReactNode;
}

interface HyperlinkButtonState {}

const defaultOnClick = () => console.warn('no event attached to button');

class HyperlinkButton extends PureComponent<HyperlinkButtonProps, HyperlinkButtonState> {
  render() {
    const {
      children,
      cursor = 'cursor-pointer',
      hoverUnderline = true,
      underline = false,
      weight = 'regular',
      onClick = defaultOnClick,
      color = 'blue-action',
      hoverColor = color,
      className = '',
      lineHeight = 135,
      fontSize = 'large',
      ...otherProps
    } = this.props;

    const targetColor = `text-color-${color}`;

    const targetUnderline = underline ? 'text-underline' : '';
    const targetHoverUnderline = hoverUnderline ? 'hover-text-underline' : '';
    const targetTextWeight = `text-weight-${weight}`;
    // const targetFontSize = `text-size-${fontSize}`;
    const targetHoverColor = `hover-text-color-${hoverColor}`;

    const finalClassName = `select-none ${targetColor} ${targetHoverColor} ${cursor} ${targetHoverUnderline} ${targetUnderline} ${targetTextWeight} ${className}`;

    return (
      <Text onClick={onClick} size={fontSize} className={finalClassName} lineHeight={lineHeight} {...otherProps}>
        {children}
      </Text>
    );
  }
}

export default HyperlinkButton;
