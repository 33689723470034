import React, { useState } from 'react';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { reactTrpc } from 'utilities/react-trpc';
import { Loader, Table } from '@good/ui/core';
import { ServiceRow } from './components/service-row';
import { useTranslation } from 'react-i18next';
import { InfiniteScroll } from 'components';
import { useDebouncedValue } from '@good/ui/hooks';
import { FiltersPanel } from './filters-panel';

export const ServicesList = () => {
  const { t } = useTranslation('', { keyPrefix: 'services.listing.table' });
  const portalUser = usePortalUser();

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500);
  const [serviceTypeFilter, setServiceTypeFilter] = useState<string[] | undefined>(undefined);
  const [serviceDepartmentFilter, setServiceDepartmentFilter] = useState<string[] | undefined>(undefined);
  const [serviceStatusFilter, setServiceStatusFilter] = useState<string[] | undefined>(undefined);

  const handleSearchChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const onServiceStatusChange = (status: string[]) => {
    setServiceStatusFilter(status.length ? status : undefined);
  };

  const onServiceDepartmentChange = (department: string[]) => {
    setServiceDepartmentFilter(department.length ? department : undefined);
  };

  const onServiceTypeChange = (type: string[]) => {
    setServiceTypeFilter(type.length ? type : undefined);
  };

  const filters = {
    searchTerm: debouncedSearchTerm,
    departmentIds: serviceDepartmentFilter,
    serviceTypes: serviceTypeFilter,
    status: serviceStatusFilter,
  };

  if (!portalUser) {
    return null;
  }

  const servicesQuery = reactTrpc.services.service.list.useInfiniteQuery(
    {
      limit: 25,
      filters,
      serviceProviderId: portalUser.serviceProviderId,
    },
    { getNextPageParam: (lastPage) => lastPage.nextCursor },
  );

  return (
    <>
      <FiltersPanel
        searchTerm={searchTerm ?? ''}
        onSearchChange={handleSearchChange}
        onServiceTypeChange={onServiceTypeChange}
        onServiceDepartmentChange={onServiceDepartmentChange}
        onServiceStatusChange={onServiceStatusChange}
      />
      <Table.ScrollContainer minWidth={350} data-testid='service-table'>
        <Table verticalSpacing='md' highlightOnHover className='sticky'>
          <Table.Thead className='text-transform: uppercase'>
            <Table.Tr>
              <Table.Th>{t('service')}</Table.Th>
              <Table.Th>{t('status')}</Table.Th>
              <Table.Th>{t('type')}</Table.Th>
              <Table.Th>{t('department')}</Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody className=' overflow-y-auto h-4/5'>
            {servicesQuery.isLoading ? (
              <Table.Tr className='flex flex-col align-center' data-testid='loader'>
                <Loader color='ocean' />
              </Table.Tr>
            ) : (
              <InfiniteScroll
                hasMore={servicesQuery.hasNextPage ?? false}
                loadMore={async () => {
                  await servicesQuery.fetchNextPage();
                }}
                asTableRow
              >
                {servicesQuery.data?.pages[0]?.items.length ? (
                  servicesQuery.data.pages.flatMap(({ items }) =>
                    items.map((item) => <ServiceRow key={item.serviceId} service={item} />),
                  )
                ) : (
                  <Table.Tr>
                    <Table.Td colSpan={4}>{t('empty')}</Table.Td>
                  </Table.Tr>
                )}
              </InfiniteScroll>
            )}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
};
