import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FolderSlash = forwardRef(function FolderSlash(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.53 1.47a.75.75 0 0 1 0 1.06l-2.795 2.796c.081.014.16.031.235.052a3.75 3.75 0 0 1 2.652 2.652c.128.478.128 1.036.128 1.854V14.055c0 1.367 0 2.47-.116 3.337-.122.9-.38 1.658-.982 2.26-.602.601-1.36.86-2.26.981-.867.117-1.97.117-3.337.117H4.31l-1.78 1.78a.75.75 0 1 1-1.06-1.06l20-20a.75.75 0 0 1 1.06 0ZM5.81 19.25H16c1.436 0 2.437-.002 3.192-.103.734-.099 1.122-.28 1.399-.556.277-.277.457-.665.556-1.4.102-.755.103-1.756.103-3.191v-4c0-.978-.006-1.32-.077-1.582a2.25 2.25 0 0 0-1.59-1.591c-.233-.063-.528-.075-1.273-.077l-12.5 12.5ZM9.525 3.885c-.343-.125-.728-.135-1.867-.135H6c-.978 0-1.32.006-1.582.077a2.25 2.25 0 0 0-1.591 1.59c-.07.263-.077.605-.077 1.583v10a.75.75 0 0 1-1.5 0V6.884c0-.818 0-1.376.128-1.855a3.75 3.75 0 0 1 2.651-2.651c.48-.129 1.037-.128 1.855-.128h1.899c.963 0 1.631 0 2.253.224.105.039.209.082.31.13.6.28 1.072.752 1.752 1.433l.09.09L13.53 5.47a.75.75 0 1 1-1.06 1.06l-1.343-1.343c-.806-.805-1.086-1.07-1.416-1.225a2.251 2.251 0 0 0-.187-.077Z"
        fill="currentColor"
      />
    </Icon>
  );
});
