import type { CSS } from '../theme';

export const reset: CSS = {
  listStyle: 'none',
  boxSizing: 'border-box',
  outline: 'none',
  marginBlockStart: '$space0',
  marginBlockEnd: '$space0',
};

export const listBox: CSS = {
  position: 'relative',
  display: 'flex',
  maxHeight: '$size3600',
  width: '100%',
  flexDirection: 'column',
  gap: 'space20',
  padding: '$space100 $space100',
  overflow: 'auto',
  borderRadius: '$xsmall',
};
