import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const PlayCircle = forwardRef(function PlayCircle(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 12a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0ZM12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25Zm1.525 8.199-.049-.033c-.642-.428-1.194-.796-1.652-1.012-.471-.223-1.063-.393-1.65-.078-.587.314-.774.9-.85 1.415-.074.502-.074 1.165-.074 1.937v.645c0 .771 0 1.434.074 1.936.076.515.263 1.101.85 1.415.587.315 1.179.145 1.65-.077.459-.217 1.01-.585 1.652-1.013l.05-.033.394-.263.046-.03c.525-.35.987-.658 1.31-.951.345-.311.671-.726.671-1.307s-.326-.996-.67-1.307c-.324-.293-.786-.6-1.31-.95l-.047-.031-.395-.263Zm-2.67.207s.004-.002.014-.002l-.013.002Zm.328.104a1.049 1.049 0 0 0-.297-.105c-.02.037-.051.124-.078.306-.056.378-.058.928-.058 1.776v.526c0 .848.002 1.398.058 1.776.027.182.059.269.078.306.042-.005.131-.026.297-.105.346-.163.805-.467 1.51-.937l.395-.263c.585-.39.952-.637 1.183-.846a.88.88 0 0 0 .17-.189l.004-.005-.003-.005a.88.88 0 0 0-.17-.19c-.232-.208-.6-.455-1.184-.845l-.395-.263c-.705-.47-1.164-.774-1.51-.937Zm-.327 4.584.013.002c-.01 0-.014-.001-.013-.002Zm.039.016c.006.005.008.009.008.01 0 0-.003-.002-.008-.01Zm0-4.72c.005-.008.008-.01.008-.01 0 .001-.002.005-.008.01Z"
        fill="currentColor"
      />
    </Icon>
  );
});
