import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { AriaOverlayProps, OverlayContainer, useOverlay, usePreventScroll } from '@react-aria/overlays';
import { filterDOMProps, mergeProps } from '@react-aria/utils';
import type { AriaDialogProps } from '@react-types/dialog';
import type { PropsWithChildren } from 'react';
import React, { RefObject, useRef } from 'react';
import type { BaseStyleProps, HTMLProps, CSS } from '..';
import { Box, compose, css, useStyleProps } from '..';
import { ModalFooter, ModalFooterProps } from './footer';
import { ModalHeader, ModalHeaderProps } from './header';
import type { ModalVariants } from './modal.css';
import * as styles from './modal.css';

export type ModalProps = PropsWithChildren<
  AriaOverlayProps &
    AriaDialogProps &
    BaseStyleProps &
    ModalVariants &
    ModalHeaderProps &
    ModalFooterProps &
    HTMLProps<HTMLDivElement> & {
      /**
       * Header content, set as header={null} when you don't need header
       * EX: EX: <Modal {...otherProps} header={<div>Modal Header</div>} ></Modal>
       */
      header?: string | React.ReactNode;

      /**
       * Whether prevent scroll when modal opened
       */
      isLockScroll?: boolean;

      /**
       * Footer content, set as footer={null} when you don't need footer
       * EX: <Modal {...otherProps} footer={<Button onPress={saveHandler}>Save</Button>} ></Modal>
       */
      footer?: React.ReactNode;

      /**
       * Body styles
       */
      bodyStyles?: CSS;

      /**
       * Header styles
       */
      headerStyles?: CSS;

      customCloseButtonStyles?: CSS;
      /**
       * Footer styles
       */
      footerStyles?: CSS;

      /**
       * Whether the modal is submittable. If this is true then the OK button is grayed out.
       */
      isDisabled?: boolean;
    }
>;

/**
 * @name
 * Modal
 *
 * @default
 * closeable: true
 * size: 'small'
 *
 * @description
 * A modal is a dialog box/popup window that is displayed on top of the current page
 *
 * @example
 * <Modal
 *  closeable
 *  header={<p>Modal Header</p>}
 *  onClose={handleCloseModal}
 *  isOpen={isOpen}
 *  footer={
 *      <Button emphasis="outlined" kind="critical">
 *        Save
 *      </Button>
 *    }
 *  >
 *    <div>
 *      Content
 *    </div>
 *  </Modal>
 *
 *  Render without header or footer
 *  <Modal
 *    footer={null}
 *    header={null}
 *  >
 *    <div>Content...</div>
 *  <Modal>
 */
export const Modal = (props: ModalProps) => {
  const {
    size = 'small',
    children,
    isOpen,
    closable = true,
    isLockScroll,
    closeIcon,
    isDismissable = true,
    header,
    footer,
    okText,
    cancelText,
    headerStyles,
    bodyStyles,
    footerStyles,
    onClose,
    onOk,
    onCancel,
    isLoading,
    isDisabled,
    customCloseButtonStyles,
  } = props;

  const ref = useRef(null);
  usePreventScroll({ isDisabled: !isOpen || !isLockScroll });

  const { styleProps, ...otherProps } = useStyleProps(props);
  const { overlayProps, underlayProps } = useOverlay({ ...props, isDismissable }, ref as RefObject<HTMLDivElement>);
  const { dialogProps, titleProps } = useDialog(props, ref as RefObject<HTMLDivElement>);
  const modalProps = mergeProps(overlayProps, dialogProps);

  const headerProps = {
    titleProps,
    headerStyles,
    customCloseButtonStyles,
    closable,
    closeIcon,
    onClose,
  };

  const footerProps = {
    okText,
    cancelText,
    onOk,
    onCancel: onCancel ?? onClose,
    isLoading,
    isDisabled,
    footerStyles,
  };

  if (!isOpen) {
    return null;
  }

  return (
    <OverlayContainer>
      <Box {...underlayProps} {...styles.backdrop}>
        <FocusScope>
          {/* 30% of free vertical space */}
          <div style={{ flexGrow: 1 }} className={css(styles.verticalFreeSpace)()} />
          <div className={compose(css(styles.reset, styles.modalWrapper))}>
            <div
              {...modalProps}
              ref={ref}
              {...filterDOMProps(otherProps)}
              className={compose(
                css(styles.reset, styles.modal, styleProps),
                styles.variants({
                  size,
                }),
              )}
            >
              <ModalHeader {...headerProps}>{header}</ModalHeader>
              <div className={compose(css(styles.reset, styles.modalBody, bodyStyles))}>{children}</div>
              <ModalFooter {...footerProps}>{footer}</ModalFooter>
            </div>
          </div>
          {/* 70% of free vertical space */}
          <div style={{ flexGrow: 2.3 }} className={css(styles.verticalFreeSpace)()} />
        </FocusScope>
      </Box>
    </OverlayContainer>
  );
};
