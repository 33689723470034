import { Form, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { IActivityGroup } from 'src/interfaces/service-interfaces';
import { dispatch, state } from 'stores/rematch/root-store';

interface IAddActivityGroupProps extends FormComponentProps {
  onPreviousStep: (stepData?: any) => void;
  onNextStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
}

const AddActivityGroup = ({ onPreviousStep, onNextStep, setNewService, newService, form }: IAddActivityGroupProps) => {
  const [openAddNewActivityWizardForm, setOpenAddNewActivityWizardForm] = useState<boolean>(false);
  const [id, setId] = useState<number>(newService.activityGroups ? newService.activityGroups.length : 0);
  const [data] = useState(newService.activityGroups ? newService.activityGroups : []);
  const { getFieldDecorator, getFieldValue, validateFields, setFieldsValue } = form;

  useEffect(() => {
    setFieldsValue({
      keys: Array.from(Array(newService.activityGroups ? newService.activityGroups.length : 0).keys()),
    });
  }, []);

  const _onNextStep = async () => {
    await validateFields();

    const formValues = form.getFieldsValue();
    const { keys } = formValues;
    let activityGroups: IActivityGroup[];
    if (formValues.names) {
      const names = _.filter(formValues.names, (name, index) => keys.includes(index));
      const descriptions = _.filter(formValues.descriptions, (description, index) => keys.includes(index));
      const convertToActivityGroupObj: IActivityGroup[] = names.map((e, i) => {
        return {
          name: e,
          description: descriptions[i] || '',
        };
      });

      activityGroups = convertToActivityGroupObj.filter((e) => e);
      await setNewService({
        ...newService,
        activityGroups: activityGroups,
      });
    } else {
      await setNewService({
        ...newService,
      });
    }

    onNextStep();
  };

  const remove = (k) => {
    setId(id - 1);
    // can use data-binding to get
    const keys = getFieldValue('keys');

    // can use data-binding to set
    setFieldsValue({
      keys: keys.filter((key) => key !== k),
    });
    setOpenAddNewActivityWizardForm(false);
  };

  const add = async () => {
    setOpenAddNewActivityWizardForm(true);

    await validateFields();
    setId(id + 1);
    // can use data-binding to get
    const keys = getFieldValue('keys');
    const count: number = _.maxBy(keys) || 0;
    const nextKeys = keys.concat(count + 1);
    // can use data-binding to set
    // important! notify form to detect changes
    setFieldsValue({
      keys: nextKeys,
    });
  };

  getFieldDecorator('keys', { initialValue: [] });
  const keys = getFieldValue('keys');

  const formItems = keys.map((k) => {
    return (
      <div key={k} className="bg-white mb-medium p-medium rounded">
        <div className="mb-12">
          <div className="flex justify-between align-end">
            <SubTitle>GROUP NAME</SubTitle>
            <GhostButton
              color="red"
              className="dynamic-delete-button ml-large"
              paddingSize="none"
              bgNone={true}
              onClick={() => remove(k)}
            >
              Remove
            </GhostButton>
          </div>
          <Form.Item className={'m-none'} key={`name[${k}]`}>
            {getFieldDecorator(`names[${k}]`, {
              initialValue: data[parseInt(k)] && data[parseInt(k)].name,
              rules: [
                {
                  required: true,
                  message: 'Please enter a name for this group',
                },
                {
                  max: 50,
                  message: 'Please enter less than 50 characters',
                },
              ],
            })(<Input type="text" size="default" placeholder="Activity group name..." />)}
          </Form.Item>
        </div>

        <div className="mb-12">
          <SubTitle>DESCRIPTION (optional)</SubTitle>
          <Form.Item className={'m-none mt-x-small'} key={`description[${k}]`}>
            {getFieldDecorator(`descriptions[${k}]`, {
              initialValue: data[parseInt(k)] && data[parseInt(k)].description,
              rules: [
                {
                  max: 1000,
                  message: 'Please enter less than 1000 characters',
                },
              ],
            })(
              <Input.TextArea
                name="description"
                placeholder="Tell people a bit about this group activity..."
                autoSize={{ minRows: 4, maxRows: 4 }}
              />,
            )}
          </Form.Item>
        </div>
      </div>
    );
  });

  return (
    <div className="anim-slide-left mb-x-large">
      <Title level={2} weight="bolder" className="line-height-100">
        Add activity group
      </Title>

      <Paragraph className="mb-large">
        Create activity groups to easily <b>manage seperate groups</b> or <b>activities</b> within a session. You can
        add as many as you like.
      </Paragraph>

      <div>
        <div className="p-medium rounded" style={{ backgroundColor: '#FAFAFA', width: '600px' }}>
          <div className="mb-small">
            {openAddNewActivityWizardForm === false && id < 1 ? (
              <Text size="large">No activity groups.</Text>
            ) : (
              <>
                <SubTitle size="small">ACTIVITY GROUPS ADDED {`(${id})`}</SubTitle>
                <Form className="mt-12">{formItems}</Form>
              </>
            )}
          </div>
          <div className="mv-medium">
            <GhostButton paddingSize="none" icon="plus" size="small" bgNone onClick={add}>
              Add activity group
            </GhostButton>
          </div>
        </div>
      </div>

      <div className="flex-row align-center mv-x2-large">
        <SecondaryButton size="large" onClick={onPreviousStep} icon="left" className="mr-large">
          Previous
        </SecondaryButton>

        <PrimaryButton size="large" onClick={_onNextStep} icon="right" iconPosition="right">
          Next
        </PrimaryButton>
      </div>
    </div>
  );
};

export default Form.create<IAddActivityGroupProps>()(AddActivityGroup);
