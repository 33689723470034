import React, { useState } from 'react';
import { IPermissions } from 'interfaces/account-interfaces';
import { getSubscriptionBillingPermission } from 'views/account-management/utils/permission-utils';
import { PermissionRowBase } from 'views/account-management/add-new-member/panels/permissions-panel/portal/PermissionRowBase';
import SubscriptionSectionModal from 'views/account-management/components/SubscriptionSectionModal';
import { SubscriptionPermissionType, UserPermissionRole } from 'utilities/enum-utils';

interface ISubscriptionPermissionRowProps {
  permissions: IPermissions;
  isPrimaryOwner: boolean;
  isTargetUserPrimaryOwner?: boolean;
  onChangePermissions: (permissions: IPermissions) => void;
}

const SubscriptionPermissionRow: React.FC<ISubscriptionPermissionRowProps> = ({
  permissions,
  isPrimaryOwner,
  isTargetUserPrimaryOwner,
  onChangePermissions,
}) => {
  const currentPermission = getSubscriptionBillingPermission(permissions);

  const [visibleSubscriptionModal, setVisibleSubscriptionModal] = useState(false);
  const [currentPermissionType, setCurrentPermissionType] = useState<SubscriptionPermissionType>(
    currentPermission.type,
  );

  const toggleVisibleSubscriptionModal = () => {
    if (!isPrimaryOwner) return;
    setCurrentPermissionType(currentPermission.type);
    setVisibleSubscriptionModal(!visibleSubscriptionModal);
  };

  const onChangeSubscriptionPermission = () => {
    const otherPermissions = permissions.permissionRoles.filter(
      (role) =>
        role.permissionRole !== UserPermissionRole.SubscriptionBillingAllAccess &&
        role.permissionRole !== UserPermissionRole.SubscriptionBillingViewOnly,
    );
    const newSubscriptionPermission = getSubscriptionBillingPermission(null, currentPermissionType);
    const newPermissions = {
      ...permissions,
      permissionRoles: [
        ...otherPermissions,
        { permissionRole: newSubscriptionPermission.role, serviceDepartmentId: null, serviceId: null },
      ],
    };
    onChangePermissions(newPermissions);
    setVisibleSubscriptionModal(false);
  };

  return (
    <>
      <PermissionRowBase
        iconName="folder-open"
        title="Subscription billing & access"
        description="GoodHuman subscription"
        currentFlag={currentPermission.type}
        actionsDisabled={!isPrimaryOwner || isTargetUserPrimaryOwner}
        isTargetUserPrimaryOwner={isTargetUserPrimaryOwner}
        onClickModify={toggleVisibleSubscriptionModal}
      />

      <SubscriptionSectionModal
        isOpen={visibleSubscriptionModal}
        selectedPermissionType={currentPermissionType}
        onClose={toggleVisibleSubscriptionModal}
        onSubmit={onChangeSubscriptionPermission}
        onChange={(type: SubscriptionPermissionType) => setCurrentPermissionType(type)}
      />
    </>
  );
};

export default SubscriptionPermissionRow;
