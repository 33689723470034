import React from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

interface ICancelFormBuilderModalProps {
  isProceedOpen: boolean;
  onBack: () => void;
  onCancel: () => void;
}

const CancelFormBuilderModal = ({ isProceedOpen, onBack, onCancel }: ICancelFormBuilderModalProps) => {
  const title = 'Cancel form creation';

  return (
    <ActionModal isOpen={isProceedOpen} onClose={onBack} title={title} showCloseButton>
      <div className="mb-medium">
        <Text>Are you sure you want to cancel creating this form?</Text>
      </div>
      <div>
        <Text>Any progress you have made will be lost.</Text>
      </div>
      <ActionModalFooter>
        <SecondaryButton className="mr-medium" size="large" onClick={onBack}>
          Go back
        </SecondaryButton>
        <PrimaryButton color="red" size="large" onClick={onCancel}>
          Cancel
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};
export default CancelFormBuilderModal;
