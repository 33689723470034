import { UseMutationResult } from '@tanstack/react-query';
import { IBooking } from 'interfaces/booking-interfaces';
import moment from 'moment';
import { Schema } from './schema';

import {
  ICancelBookingRequest,
  EditRecurringMode,
  CustomerCancellationCode,
  ICancelGroupBookingRequest,
  ICancelBookingResponse,
  ICancelGroupBookingResponse,
} from 'stores/queries/booking/booking-queries';
import getNumberOfCancelledBookings from './get-number-of-cancelled-bookings';

type Request<T extends boolean> = T extends true ? ICancelGroupBookingRequest : ICancelBookingRequest;
type Response<T extends boolean> = T extends true ? ICancelGroupBookingResponse : ICancelBookingResponse;
type ReturnValues<T extends boolean> = {
  request: Request<T>;
  response: Response<T>;
  shiftEndTimeToPay: moment.Moment;
};

async function cancelBooking<T extends boolean>(
  formValues: Schema,
  booking: IBooking,
  isGroupBooking: T,
  cancelBooking: UseMutationResult<ICancelBookingResponse, unknown, ICancelBookingRequest, unknown>,
  cancelGroupBooking: UseMutationResult<ICancelGroupBookingResponse, unknown, ICancelGroupBookingRequest, unknown>,
  isEditing: boolean,
): Promise<ReturnValues<T>>;
async function cancelBooking(
  formValues: Schema,
  booking: IBooking,
  isGroupBooking: boolean,
  cancelBooking: UseMutationResult<ICancelBookingResponse, unknown, ICancelBookingRequest, unknown>,
  cancelGroupBooking: UseMutationResult<ICancelGroupBookingResponse, unknown, ICancelGroupBookingRequest, unknown>,
  isEditing: boolean,
): Promise<{
  request: ICancelGroupBookingRequest | ICancelBookingRequest;
  response: ICancelGroupBookingResponse | ICancelBookingResponse;
  shiftEndTimeToPay: moment.Moment;
}> {
  if (!booking) {
    throw new Error('No booking selected');
  }

  const {
    cancelledBy,
    cancelRecurringBooking,
    customerCancellationReason,
    otherCancellationReason,
    numberOfRecurringBookingsToDelete,
    shouldChargeCancellationFee,
    cancellationFeeReason,
    shouldPayTeamMember,
    teamMemberPaymentDuration,
    customTeamMemberPaymentDuration,
    customTeamMemberPaymentDurationUnit,
  } = formValues;

  const {
    bookingId,
    bookingRequestId,
    isRecurring,
    startDateTime,
    endDateTime,
    timezone,
    attendanceId,
    serviceDateTimeId,
    serviceId,
  } = booking;

  const startDateTimeMoment = moment(startDateTime);
  const endDateTimeMoment = moment(endDateTime);
  const duration = moment.duration(endDateTimeMoment.diff(startDateTimeMoment)).asHours();

  const editRecurringMode: EditRecurringMode = {
    this: EditRecurringMode.Current,
    all: EditRecurringMode.CurrentAll,
    specified: EditRecurringMode.CurrentNext,
  }[cancelRecurringBooking];

  const cancellationCode: CustomerCancellationCode = {
    transport: 'NSDT',
    health: 'NSDH',
    other: 'NSDO',
    family: 'NSDF',
  }[customerCancellationReason];

  const isCancelledByBusiness = cancelledBy === 'business';

  const numberOfBookingsToCharge = shouldChargeCancellationFee
    ? getNumberOfCancelledBookings(booking, cancelRecurringBooking, numberOfRecurringBookingsToDelete)
    : 0;

  const isCustomPaymentDurationHours = customTeamMemberPaymentDurationUnit === 'hours';
  const customTeamMemberPaymentDurationHours = isCustomPaymentDurationHours
    ? customTeamMemberPaymentDuration
    : customTeamMemberPaymentDuration / 60;

  const shiftHours = teamMemberPaymentDuration === 'entireDuration' ? duration : customTeamMemberPaymentDurationHours;
  const shiftEndTimeToPay = moment.tz(startDateTimeMoment.add(shiftHours, 'hours'), timezone);
  const noShow = isCancelledByBusiness ? false : cancellationFeeReason === 'noShow';
  const cancellationReason = isCancelledByBusiness ? undefined : cancellationCode;

  const request: ICancelBookingRequest = {
    bookingId,
    bookingRequestId,
    cancelBookingIds: [bookingId],
    cancellationReason: cancellationReason?.trimStart(),
    editRecurringMode,
    isBusinessCancel: isCancelledByBusiness,
    isCharge: shouldChargeCancellationFee,
    isCustomerNoShow: noShow,
    isRecurring,
    numberOfBookings: numberOfRecurringBookingsToDelete,
    numberOfBookingsToCharge: numberOfBookingsToCharge,
    reason: otherCancellationReason,
    shiftSlot: {
      isPaidShift: shouldPayTeamMember,
      shiftHours,
    },
    isEditing,
  };

  const groupBookingRequest: ICancelGroupBookingRequest = {
    ...request,
    serviceDateTimeId,
    serviceId,
    cancellationCode,
    isChargeCancellationFee: shouldChargeCancellationFee,
    attendanceIds: [attendanceId],
  };

  let response: ICancelBookingResponse | ICancelGroupBookingResponse;

  if (isGroupBooking) {
    response = await cancelGroupBooking.mutateAsync(groupBookingRequest);
  } else {
    response = await cancelBooking.mutateAsync(request);
  }

  return { request, response, shiftEndTimeToPay };
}

export default cancelBooking;
