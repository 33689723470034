import { z } from 'zod';

import { supportCategories } from './components/support-categories';

import type { BudgetResponse, BudgetTrackingResponse } from './pages/detail/services';

const budgetStatusSchema = z.enum([
  'NONE',
  'ON_TRACK',
  'OVERBUDGET',
  'WITHIN_BUDGET',
  'SIGNIFICANTLY_AHEAD',
  'SIGNIFICANTLY_BEHIND',
  'SLIGHTLY_AHEAD',
  'SLIGHTLY_BEHIND',
]);

export const BUDGET_STATUS = budgetStatusSchema.enum;
export type BudgetStatus = z.infer<typeof budgetStatusSchema>;

export function statusToTone(status: BudgetStatus): 'positive' | 'critical' | 'notice' | 'neutral' {
  switch (status) {
    case BUDGET_STATUS.ON_TRACK:
      return 'positive';
    case BUDGET_STATUS.SIGNIFICANTLY_BEHIND:
    case BUDGET_STATUS.SIGNIFICANTLY_AHEAD:
    case BUDGET_STATUS.OVERBUDGET:
      return 'critical';
    case BUDGET_STATUS.SLIGHTLY_AHEAD:
    case BUDGET_STATUS.SLIGHTLY_BEHIND:
      return 'notice';
    default:
      return 'neutral';
  }
}

type BudgetStatusColors = {
  color: string;
  secondaryColor: string;
};
export const statusToColors = (status: BudgetStatus): BudgetStatusColors => {
  let result = { color: '$lavender', secondaryColor: '$lavenderDark2' };
  switch (status) {
    case BUDGET_STATUS.ON_TRACK:
      result = {
        color: '$green',
        secondaryColor: '$greenDark2',
      };
      break;
    case BUDGET_STATUS.SIGNIFICANTLY_BEHIND:
    case BUDGET_STATUS.SIGNIFICANTLY_AHEAD:
    case BUDGET_STATUS.OVERBUDGET:
      result = {
        color: '$red',
        secondaryColor: '$redDark2',
      };
      break;
    case BUDGET_STATUS.SLIGHTLY_AHEAD:
    case BUDGET_STATUS.SLIGHTLY_BEHIND:
      result = {
        color: '$orange',
        secondaryColor: '$orangeDark2',
      };
      break;
    default:
      break;
  }

  return result;
};

/*******************************************************************
 ** Cadence
 ******************************************************************/

const cadenceSchema = z.object({
  planned: z.number(),
  actual: z.number(),
  ideal: z.number(),
});

export type Cadence = z.infer<typeof cadenceSchema>;

/*******************************************************************
 ** Pace
 ******************************************************************/

const paceSchema = z.object({
  DAY: cadenceSchema,
  WEEK: cadenceSchema,
  MONTH: cadenceSchema,
  YEAR: cadenceSchema,
  QUARTER: cadenceSchema,
});

export type Pace = z.infer<typeof paceSchema>;

/*******************************************************************
 ** TrackingSummary
 ******************************************************************/

const trackingSummarySchema = z.object({
  status: budgetStatusSchema,
  quoted: z.number().positive(),
  spent: z.number().positive(),
  spentPercentage: z.number().positive(),
  balance: z.number(),
  tracking: z.number(),
  projectedSpend: z.number(),
  projectedBalance: z.number(),
});

export type TrackingSummary = z.infer<typeof trackingSummarySchema>;

/*******************************************************************
 ** BilledItem
 ******************************************************************/

// these ORs are necessary as the API is currently returning
// strings but will soon return numbers instead
const billedItemSchema = z.object({
  instances: z.number(),
  quantity: z.number().or(z.string()),
  supportItemNumber: z.number().or(z.string()),
  total: z.number().or(z.string()),
  unitPrice: z.number().or(z.string()),
});

export type BilledItem = z.infer<typeof billedItemSchema>;

/*******************************************************************
 ** Spend
 ******************************************************************/

const spendSchema = z.object({
  cost: z.number(),
  hours: z.number().nullable(),
});

export type BudgetSpend = z.infer<typeof spendSchema>;

/*******************************************************************
 ** PlotData
 ******************************************************************/

const plotDataSchema = z.object({
  bookingBillingItemId: z.string().uuid(),
  cumulativeSpend: spendSchema,
  startDateTime: z.date(),
  endDateTime: z.date(),
  itemSpend: spendSchema,
  plannedSpend: spendSchema,
  trackingDifferencePercentage: z.number(),
});

export type PlotData = z.infer<typeof plotDataSchema>;

/*******************************************************************
 ** SpendGraph
 ******************************************************************/

const spendGraphSchema = z.object({
  interval: z.object({ start: z.date(), end: z.date() }),
  plotData: z.array(plotDataSchema),
  quoteAmount: z.number(),
});

export type SpendGraph = z.infer<typeof spendGraphSchema>;

/*******************************************************************
 ** SupportItemBudgeting
 ******************************************************************/

const supportItemBudgetingSchema = z.object({
  balance: z.number().optional(),
  percentOfBudget: z.number().optional(),
  projectedBalance: z.number().optional(),
  projectedSpend: z.number(),
  quoted: z.number().optional(),
  spent: z.number(),
  status: budgetStatusSchema,
  spentPercentage: z.number().optional(),
  tracking: z.number().optional(),
});

export type SupportItemBudgeting = z.infer<typeof supportItemBudgetingSchema>;

/*******************************************************************
 ** SupportCoordinationTracking
 ******************************************************************/

export const supportCoordinationTrackingSchema = z.object({
  balance: z.number(),
  pace: paceSchema,
  projectedBalance: z.number(),
  projectedSpend: z.number(),
  quoted: z.number(),
  spent: z.number(),
  spentPercentage: z.number().nullable(),
  status: budgetStatusSchema,
  tracking: z.number(),
  unit: z.string(),
});

export type SupportCoordinationTracking = z.infer<typeof supportCoordinationTrackingSchema>;

/*******************************************************************
 ** Price
 ******************************************************************/

const priceSchema = z.object({
  actPrice: z.number(),
  nonRemotePrice: z.number(),
  ntPrice: z.number(),
  remotePrice: z.number(),
  veryRemotePrice: z.number(),
});

export type BudgetPrice = z.infer<typeof priceSchema>;

/*******************************************************************
 ** ServiceAgreementLineItem
 ******************************************************************/

const serviceAgreementLineItemSchema = z.object({
  agreementPrice: z.string(),
  dateType: z.string(),
  description: z.string(),
  groupCode: z.string(),
  groupName: z.string(),
  price: priceSchema,
  priority: z.number(),
  serviceId: z.string(),
  supportCategoryNumber: z.number(),
  supportItemNumber: z.string(),
  unit: z.string(),
});

export type ServiceAgreementLineItem = z.infer<typeof serviceAgreementLineItemSchema>;

/*******************************************************************
 ** SupportItemTracking
 ******************************************************************/

const supportItemTrackingSchema = z.object({
  billedItems: z.array(billedItemSchema),
  futureItems: z.array(billedItemSchema),
  supportItemBudgeting: supportItemBudgetingSchema,
  supportItemDetails: serviceAgreementLineItemSchema,
  supportItemNumber: z.string(),
});

export type SupportItemTracking = z.infer<typeof supportItemTrackingSchema>;

/*******************************************************************
 ** SupportCategory
 ******************************************************************/

const supportCategorySchema = z.object({
  balance: z.number(),
  pace: paceSchema,
  percentOfBudget: z.number(),
  projectedBalance: z.number(),
  projectedSpend: z.number(),
  quoted: z.number(),
  spendGraph: spendGraphSchema,
  spent: z.number(),
  spentPercentage: z.number().nullable(),
  status: budgetStatusSchema,
  supportCategoryName: z.string(),
  supportCategoryNumber: z.number(),
  supportCoordinationTracking: supportCoordinationTrackingSchema.optional(),
  supportItemTracking: z.record(supportItemTrackingSchema),
  supportType: z.string(),
  tracking: z.number(),
});

export type SupportCategory = z.infer<typeof supportCategorySchema>;

/*******************************************************************
 ** CategoryTracking
 ******************************************************************/

const categoryTrackingSchema = z.object({
  balance: z.number(),
  projectedBalance: z.number(),
  projectedSpend: z.number(),
  quoted: z.number(),
  spent: z.number(),
  spentPercentage: z.number().nullable(),
  status: budgetStatusSchema,
  supportCategories: z.array(supportCategorySchema),
  tracking: z.number(),
});

export type CategoryTracking = z.infer<typeof categoryTrackingSchema>;

/*******************************************************************
 ** BudgetTracking
 ******************************************************************/

const budgetTrackingSchema = z.object({
  billedItems: z.record(z.array(billedItemSchema)),
  categoryTracking: categoryTrackingSchema,
  serviceId: z.string().uuid(),
  serviceName: z.string(),
  serviceType: z.enum(['INDIVIDUAL', 'GROUP']),
  trackingSummary: trackingSummarySchema,
  serviceAgreementLineItems: z.array(serviceAgreementLineItemSchema).optional(),
});

export type BudgetTracking = z.infer<typeof budgetTrackingSchema>;

export type BudgetTrackingByService = Record<string, BudgetTracking>;

/*******************************************************************
 ** Customer
 ******************************************************************/

const customerSchema = z.object({
  userId: z.string().uuid(),
});

export type BudgetCustomer = z.infer<typeof customerSchema>;

/*******************************************************************
 ** ServiceAgreement
 ******************************************************************/

const serviceAgreementSchema = z.object({
  id: z.string().uuid(),
  startDate: z.string(),
  endDate: z.string(),
  paymentSourceType: z.enum(['NDIS', 'VCP']),
  status: z.enum(['UPCOMING', 'ACTIVE', 'EXPIRED', 'EXPIRING_SOON']),
  signed: z.discriminatedUnion('signedStatus', [
    z.object({ signedStatus: z.literal('SIGNED'), signedDate: z.date() }),
    z.object({ signedStatus: z.literal('UNSIGNED') }),
  ]),
  termsAndConditions: z.string(),
  versionNumber: z.number(),
});

export type ServiceAgreement = z.infer<typeof serviceAgreementSchema>;

/*******************************************************************
 ** Budget
 ******************************************************************/

const figureSchema = z.object({
  value: z.number(),
  percent: z.union([
    z.number().optional(),
    z
      .nan()
      .optional()
      .transform(() => 0),
  ]),
});

const categorySchema = z.object({
  id: z.number().min(1).max(15),
  name: z.string(),
  type: z.string(),
  status: budgetStatusSchema,
  spendGraph: z.unknown(),
  supportItemTracking: z.unknown(),
  supportCoordinationTracking: supportCoordinationTrackingSchema.optional(),
  actuals: z.object({
    quoted: figureSchema,
    spent: figureSchema,
    balance: figureSchema,
  }),
  projections: z.object({
    balance: figureSchema,
    spend: figureSchema,
    tracking: figureSchema,
  }),
  pace: paceSchema,
  meta: z.object({
    icon: z.unknown(),
    color: z.string(),
  }),
});

export const budgetSchema = z.object({
  budget: z.object({
    balance: figureSchema,
    categories: z.array(categorySchema),
    quoted: figureSchema,
    spent: figureSchema,
  }),

  overview: z.object({
    customer: z.object({
      id: z.string().uuid(),
      avatarUrl: z.optional(z.string().url().or(z.literal('')).or(z.null())),
      name: z.string(),
    }),
    service: z.object({
      id: z.string().uuid(),
      name: z.string(),
      startDate: z.date(),
      endDate: z.date(),
      assignedWorkers: z.array(
        z.object({
          id: z.string().uuid(),
          avatarUrl: z.optional(z.string().url().or(z.literal('')).or(z.null())),
          name: z.string(),
        }),
      ),
    }),
    services: z.array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
      }),
    ),
    budgetStatus: budgetStatusSchema,
  }),
});

export type Budget = z.infer<typeof budgetSchema>;
export type BudgetCategory = Budget['budget']['categories'][0];

// function toServices();

/**
 * @name
 * validateBudgetResponse
 *
 * @description
 * Validates API response against expected schema.
 *
 * @example
 * Promise.then(({ data }) => data).then(validateBudgetResponse)
 */
export function createBudget(serviceId: string, data: BudgetResponse): Budget {
  let selectedService: BudgetTrackingResponse = data.budgetTrackingByService[serviceId];

  let services = Object.keys(data.budgetTrackingByService).map((id) => {
    let { serviceName: name } = data.budgetTrackingByService[id];

    return {
      id,
      name,
    };
  });

  let overview = {
    customer: {
      id: data.customer.userId,
      name: data.customer.displayName,
      avatarUrl: data.customer.avatarUrl,
    },
    service: {
      id: selectedService.serviceId,
      name: selectedService.serviceName,
      startDate: selectedService.startDate,
      endDate: selectedService.endDate,
      assignedWorkers:
        data.assignedWorkers?.map((w) => ({
          id: w.userId,
          name: w.displayName,
          avatarUrl: w.avatarUrl,
        })) ?? [],
    },
    services,
    budgetStatus: selectedService.trackingStatus,
  };

  let categories = selectedService.categoryTracking.supportCategories.map((c) => {
    let selectedCategory = supportCategories[c.supportCategoryNumber];

    return {
      id: c.supportCategoryNumber,
      name: selectedCategory.name,
      type: selectedCategory.type,
      status: c.status,
      spendGraph: c.spendGraph,
      supportItemTracking: c.supportItemTracking,
      supportCoordinationTracking: c.supportCoordinationTracking,
      actuals: {
        balance: {
          value: c.balance,
          percent: (c.balance / c.quoted) * 100,
        },
        quoted: {
          value: c.quoted,
          percent: (c.quoted / selectedService.categoryTracking.quoted) * 100,
        },
        spent: {
          value: c.spent,
          percent: c.spentPercentage ?? 0,
        },
      },
      pace: {
        ...c.pace,
      },
      projections: {
        balance: {
          value: c.projectedBalance,
        },
        spend: {
          value: c.projectedSpend,
        },
        tracking: {
          value: c.tracking,
        },
      },
      meta: {
        icon: selectedCategory.icon,
        color: selectedCategory.color,
      },
    };
  });

  let budget = {
    balance: {
      value: selectedService.categoryTracking.balance,
      percent: (selectedService.categoryTracking.balance / selectedService.categoryTracking.quoted) * 100,
    },
    quoted: {
      value: selectedService.categoryTracking.quoted,
    },
    spent: {
      value: selectedService.categoryTracking.spent,
      percent: (selectedService.categoryTracking.balance / selectedService.categoryTracking.quoted) * 100,
    },
    categories,
  };

  try {
    return budgetSchema.parse({
      budget,
      overview,
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}
