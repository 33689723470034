import { Text } from '@good/components';
import { ChevronDown } from '@good/icons';
import { IServiceListItemLite } from 'interfaces/service-interfaces';
import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

type MultiSelectDropdownProperties = {
  items: IServiceListItemLite[] | undefined;
  selectedIds: string[];
  setSelectedIds: (items: string[]) => void;
};

type MultiSelectDropdownItemProperties = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  labelText: string | JSX.Element;
};

export const MultiSelectDropdownItem: React.FC<MultiSelectDropdownItemProperties> = ({
  onChange,
  checked,
  labelText,
}) => {
  return (
    <label className="align-center flex w-full flex-row justify-between space-x-2 whitespace-nowrap">
      <Text className="select-none">{labelText}</Text>
      <div className="align-center min-w-4 max-w-4 min-h-4 relative flex h-4 max-h-4 w-4 justify-center [&>*]:absolute">
        <input
          type="checkbox"
          onChange={onChange}
          checked={checked}
          className="min-w-4 max-w-4 min-h-4 h-4 max-h-4 w-4 rounded-full border-2 border-neutral transition-colors checked:border-ocean checked:!bg-ocean enabled:hover:border-ocean"
        />
      </div>
    </label>
  );
};

export const MultiSelectDropdown: React.FC<MultiSelectDropdownProperties> = ({
  items,
  selectedIds,
  setSelectedIds,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'insights.table.dropdownSelector' });
  const [open, setOpen] = useState<boolean>(false);
  const [temporaryIds, setTemporaryIds] = useState<string[]>(selectedIds);
  const clickOutsideReference = useRef<HTMLDivElement>(null);

  const isSelectedAll = items?.length === temporaryIds.length;

  const onClose = useCallback(() => {
    // If there have been any unsaved changes, reset them now before closing.
    setTemporaryIds(selectedIds);
    setOpen(false);
  }, [selectedIds]);

  const onApply = () => {
    // Save changes and exit.
    setSelectedIds(temporaryIds);
    setOpen(false);
  };

  const onToggleOpen = () => (open ? onClose() : setOpen(true));

  const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>, itemId: string): void => {
    const { checked } = event.target;

    if (!checked) {
      return setTemporaryIds(temporaryIds.filter((id) => id !== itemId));
    }

    return setTemporaryIds([...temporaryIds, itemId]);
  };

  // If we have already selected all, or if we have no items to begin with, there should be nothing selected.
  // Otherwise, select all items.
  const onBulkSelect = () => setTemporaryIds(isSelectedAll || !items ? [] : items.map((item) => item.serviceId));

  // If we click away from the dropdown menu, we want it to close.
  useClickAway(clickOutsideReference, onClose);

  const servicePreviewText = useMemo(() => {
    if (selectedIds.length === 0) {
      return '';
    }

    if (selectedIds.length === 1 && items) {
      const selectedServiceName = items.find((item) => item.serviceId === selectedIds[0])?.serviceName;
      if (selectedServiceName === undefined) {
        // The user should never really be able to select a service with no name.
        // But hey, who knows?
        return t('servicesCount', { count: 1 });
      }

      return t('selectedService', { service: selectedServiceName });
    }

    return t('servicesCount', { count: selectedIds.length });
  }, [items, selectedIds, t]);

  return (
    <div ref={clickOutsideReference}>
      <div className="relative h-full">
        <button
          onClick={onToggleOpen}
          className="align-center flex h-full w-full cursor-pointer select-none flex-row justify-center space-x-2 rounded-lg border-[1px] border-gray px-5 text-ocean-dark-1 hover:border-ocean-dark-1"
        >
          <Text className="max-w-[250px] truncate">
            {t('header')} {servicePreviewText}
          </Text>
          {open ? (
            <div className="rotate-180 transition-all">
              <ChevronDown />
            </div>
          ) : (
            <div className="transition-all">
              <ChevronDown />
            </div>
          )}
        </button>
        {open && (
          <div className="align-start absolute right-0 top-full z-10 mt-1 flex w-fit max-w-max flex-col space-y-3 rounded-lg border-2 border-neutral-weak bg-white px-4 py-6">
            <button
              onClick={onBulkSelect}
              className="border-none pl-4 text-ocean-dark-1 outline-none transition-colors hover:text-ocean"
            >
              <Text>{isSelectedAll ? t('selectNone') : t('selectAll')}</Text>
            </button>
            <div className="flex max-h-60 flex-col space-y-3 overflow-auto px-4 hover:overflow-scroll">
              {items?.map((item, index) => (
                <MultiSelectDropdownItem
                  key={`${index}-${item.serviceId}`}
                  onChange={(event) => onCheckboxChange(event, item.serviceId)}
                  checked={temporaryIds.includes(item.serviceId)}
                  labelText={item.serviceName}
                />
              ))}
            </div>
            <div className="flex h-full w-full flex-row justify-end space-x-2">
              <button
                onClick={onClose}
                className="rounded-lg border-2 border-neutral-weak px-4 py-2 text-ocean-dark-1 outline-none transition-colors hover:bg-neutral-weak"
              >
                <Text>{t('cancelLabel')}</Text>
              </button>
              <button
                onClick={onApply}
                className="align-center flex flex-row space-x-2 rounded-lg bg-ocean px-4 py-2 text-white transition-colors hover:bg-ocean-dark-1"
              >
                <Text>{t('applyLabel')}</Text>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
