import React, { Component } from 'react';
import { Col, Input, notification, Row } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { ICustomer } from 'src/interfaces/customer-interfaces';
import { ndisNumberRegex } from '@constants/billing/ndis';

interface IEditNdisVcpNumberModalProps {
  closeModal: () => void;
  isOpen: boolean;
  selectedCustomer: ICustomer;
  doUpdateFundingGeneralInfo: typeof dispatch.customersStore.doUpdateFundingGeneralInfo;
  numberType: string;
}

interface IEditNdisVcpNumberModalState {
  isLoading: boolean;
  isCancelVerificationModalOpen: boolean;
  number: string;
  displayInputError: boolean;
}

class EditNdisVcpNumberModal extends Component<IEditNdisVcpNumberModalProps, IEditNdisVcpNumberModalState> {
  state = {
    isLoading: false,
    isCancelVerificationModalOpen: false,
    number: '',
    displayInputError: false,
  };

  private _closeModalCheck = () => {
    const { number } = this.state;
    const { selectedCustomer, closeModal, numberType } = this.props;
    const selectedCustomerNumber =
      numberType === 'ndis'
        ? selectedCustomer.funding.NDISNumber
        : numberType === 'claim'
        ? selectedCustomer.funding.claimNumber
        : numberType === 'ndis'
        ? selectedCustomer.funding.approvalNumber
        : numberType === 'ndis'
        ? selectedCustomer.funding.participantNumber
        : '';
    if (!_.isEmpty(number) && number !== selectedCustomerNumber) {
      this.setState({ isCancelVerificationModalOpen: true });
    } else {
      closeModal();
    }
  };

  private _closeModal = () => {
    this.setState({ isLoading: false, isCancelVerificationModalOpen: false, displayInputError: false });
    this.props.closeModal();
  };

  private _closeCancelVerificationModal = () => {
    this.setState({ isCancelVerificationModalOpen: false });
  };

  private _onChangeNdisVcpNumber = (e) => {
    const { value } = e.target;

    if (this.props.numberType === 'ndis') {
      if (!value.match(ndisNumberRegex)) {
        this.setState({ displayInputError: true });
      } else {
        if (this.state.displayInputError) {
          this.setState({ displayInputError: false });
        }
      }

      this.setState({ number: value });
      return;
    }

    if (!e.target.value.match(new RegExp('^[a-zA-Z0-9_]*$'))) {
      this.setState({ displayInputError: true });
    } else {
      if (this.state.displayInputError) {
        this.setState({ displayInputError: false });
      }
    }
    this.setState({ number: _.trim(e.target.value) });
  };

  private _saveNdisVcpNumber = async () => {
    const { number } = this.state;
    const { selectedCustomer, closeModal, doUpdateFundingGeneralInfo, numberType } = this.props;
    try {
      this.setState({ isLoading: true });
      let notificationMessage = '';

      const payload: any = {
        userId: selectedCustomer.userId,
      };
      if (numberType === 'ndis') {
        payload.NDISNumber = number;
        notificationMessage = 'NDIS number updated successfully.';
      } else if (numberType === 'claim') {
        payload.claimNumber = number;
        notificationMessage = 'Claim number updated successfully.';
      } else if (numberType === 'approval') {
        payload.approvalNumber = number;
        notificationMessage = 'Approval number updated successfully.';
      } else if (numberType === 'participant') {
        notificationMessage = 'Participant number updated successfully.';
        payload.participantNumber = number;
      }
      await doUpdateFundingGeneralInfo(payload);
      this.setState({ isLoading: false });
      notification.success({ message: notificationMessage });
      closeModal();
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  componentDidUpdate(
    prevProps: Readonly<IEditNdisVcpNumberModalProps>,
    prevState: Readonly<IEditNdisVcpNumberModalState>,
    snapshot?: any,
  ): void {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        this.setState({
          number:
            this.props.numberType === 'ndis'
              ? this.props.selectedCustomer.funding.NDISNumber
              : this.props.numberType === 'claim'
              ? this.props.selectedCustomer.funding.claimNumber
              : this.props.numberType === 'approval'
              ? this.props.selectedCustomer.funding.approvalNumber
              : this.props.numberType === 'participant'
              ? this.props.selectedCustomer.funding.participantNumber
              : '',
        });
      }
    }
  }

  render() {
    const { isLoading, isCancelVerificationModalOpen, number, displayInputError } = this.state;

    const numberType =
      this.props.numberType === 'ndis'
        ? 'NDIS Number'
        : this.props.numberType === 'claim'
        ? 'Claim Number'
        : this.props.numberType === 'approval'
        ? 'Approval Number'
        : this.props.numberType === 'participant'
        ? 'Participant Number'
        : '';

    return (
      <div>
        <ActionModal
          isOpen={isCancelVerificationModalOpen}
          onClose={this._closeModal}
          title={'Cancel changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>Your changes hasn't been saved, proceeding will discard it.</Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeCancelVerificationModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._closeModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          title={`Edit ${numberType}`}
          isOpen={this.props.isOpen}
          onClose={this._closeModalCheck}
          width="medium"
        >
          <div className="anim-slide-left">
            <div>
              <SubTitle>{numberType}</SubTitle>
              <Input
                size={'large'}
                style={{ width: '300px' }}
                value={number}
                onChange={this._onChangeNdisVcpNumber}
                placeholder={`Enter ${numberType}...`}
                maxLength={50}
              />
            </div>
            <div>
              {displayInputError && (
                <Text size='regular' color='red-dark' className='mt-small'>
                  {this.props.numberType === 'ndis' ? 'Invalid NDIS Number' : 'Special characters are not allowed'}
                </Text>
              )}
            </div>
            <div className={'mb-small mt-x2-large'}>
              <Row type={'flex'} className={'justify-end'}>
                <Col className="mr-large">
                  <GhostButton size="large" onClick={this._closeModalCheck}>
                    Cancel
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton
                    size="large"
                    disabled={_.isEmpty(number) || displayInputError}
                    loading={isLoading}
                    onClick={this._saveNdisVcpNumber}
                  >
                    Save
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateFundingGeneralInfo: dispatch.customersStore.doUpdateFundingGeneralInfo,
});

const mapState = (state: IRootState) => ({
  // if needed
});

export default connect(mapState, mapDispatch)(EditNdisVcpNumberModal);
