import { Spinner } from '@blueprintjs/core';
import { Checkbox, Col, Form, Icon, message, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { INewActivityRecordData } from 'interfaces/booking-interfaces';
import { ICustomer, ICustomerListItem } from 'interfaces/customer-interfaces';
import { IServiceDetail } from 'interfaces/service-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import firebaseApp from 'stores/firebase-app';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { BookingType, DurationUnit, ServiceDirection, ShiftSlotStatus, ServiceType } from 'utilities/enum-utils';
import { usePrevious } from 'utilities/hook-utilities';
import ActivityRecordTimeSelectSection, {
  ITimeSelectionOnChangeData,
} from '../../components/ActivityRecordTimeSelectSection';
import {
  ActivityRecordCustomerSelector,
  ActivityRecordNoteAndAttachments,
  ActivityRecordServiceSelector,
  ActivityRecordTeamMemberSelector,
} from './activity-record-common';

enum CheckboxType {
  BILLABLE = 'BILLABLE',
  RECORD = 'RECORD',
}

interface ICreateNewActivityRecordModalV2Props extends FormComponentProps {
  isOpen: boolean;
  history: any;
  selectedCustomer?: ICustomerListItem | ICustomer;
  onClose: () => void;
  portalUser: typeof state.authStore.portalUser;
  doGetCustomer: typeof dispatch.customersStore.doGetCustomer;
  doFetchSingleService: typeof dispatch.servicesStore.doFetchSingleService;
  doCreateActivityRecord: typeof dispatch.bookingsStore.doCreateActivityRecord;
  doFetchFilteredBookingList: typeof dispatch.bookingsStore.doFetchFilteredBookingList;
  doCheckServiceAgreementDate: typeof dispatch.bookingsStore.doCheckServiceAgreementDate;
}

interface ILastCreatedActivityRecord {
  bookerUserId: string;
  customerFirstName: string;
  customerLastName: string;
  attachmentUrl: string;
}

const durationTimeBlock = 5;
const initialNewActivityRecordData: INewActivityRecordData = {
  selectedCustomer: null,
  selectedCustomerId: null,
  selectedService: null,
  selectedServiceId: null,
  bookLocation: null,
  paymentSourceType: '',
  note: {
    noteContent: '',
  },
  attachments: [],
  bookingStartDate: moment(),
  bookingEndDate: moment().add(durationTimeBlock, 'minute'),
  durationTimeBlock: durationTimeBlock,
  duration: durationTimeBlock,
  durationUnit: DurationUnit.MINUTE,
  isDuration: true,
  selectedWorkerId: null,
  isApproved: false,
  includeStartEndTime: false,
  useServiceNextTime: false,
  isAllBillingItemNonBillable: false,
};

const CreateNewActivityRecordModalV2 = (props: ICreateNewActivityRecordModalV2Props) => {
  const {
    isOpen,
    onClose,
    form,
    doGetCustomer,
    doFetchSingleService,
    doCreateActivityRecord,
    history,
    doFetchFilteredBookingList,
    doCheckServiceAgreementDate,
    portalUser,
  } = props;
  const [newActivityRecordData, setNewActivityRecordData] =
    useState<INewActivityRecordData>(initialNewActivityRecordData);
  const [loadedSelectedService, setLoadedSelectedService] = useState<IServiceDetail>(null);
  const [isCreatingRecord, setIsCreatingRecord] = useState(false);
  const [isResetCurrentTime, setIsResetCurrentTime] = useState(true);
  const [isResetCurrentAttachment, setIsResetCurrentAttachment] = useState(false);
  const [lastCreatedActivityRecord, setLastCreatedActivityRecord] = useState<ILastCreatedActivityRecord>(null);
  const [isLoadingService, setIsLoadingService] = useState(false);
  const prevLoadingService = usePrevious(isLoadingService);
  const [isLoadingAgreement, setIsLoadingAgreement] = useState(false);
  const [hasValidServiceAgreement, setHasValidServiceAgreement] = useState(false);
  const [isPreFilling, setIsPreFilling] = useState(false);
  const [isCreateAnotherRecord, setIsCreateAnotherRecord] = useState(false);
  const [isBlockedTeamMember, setIsBlockedTeamMember] = useState<boolean>(false);

  const {
    selectedService,
    selectedServiceId,
    selectedCustomerId,
    selectedCustomer,
    bookingStartDate,
    bookingEndDate,
    paymentSourceType,
    useServiceNextTime,
  } = newActivityRecordData;

  const _resetState = (isCreateMoreRecord: boolean) => {
    if (isCreateMoreRecord) {
      setIsResetCurrentTime(true);
      setIsResetCurrentAttachment(true);

      const hasSelectedCustomer = Boolean(props.selectedCustomer?.userId);
      setLastCreatedActivityRecord(null);
      setNewActivityRecordData({
        ...initialNewActivityRecordData,
        useServiceNextTime: useServiceNextTime,
        selectedService: useServiceNextTime ? selectedService : null,
        selectedServiceId: useServiceNextTime ? selectedServiceId : null,
        paymentSourceType: useServiceNextTime ? selectedService.paymentSources[0] : '',
        bookLocation: useServiceNextTime ? _getAddress(selectedCustomer, selectedService) : null,
        selectedCustomer: hasSelectedCustomer ? selectedCustomer : null,
        selectedCustomerId: hasSelectedCustomer ? selectedCustomerId : null,
      });
    } else {
      setNewActivityRecordData(initialNewActivityRecordData);
    }

    setIsLoadingAgreement(false);
    setHasValidServiceAgreement(false);
    setIsBlockedTeamMember(false);
    form.resetFields();
  };

  const _preFillForm = useCallback(async () => {
    setIsPreFilling(true);
    try {
      const payload = {
        page: 1,
        pageSize: 1,
        pageTimestamp: new Date().toISOString(),
        bookingTypes: [BookingType.ACTIVITY_RECORD],
        createdBy: portalUser.userId,
      };
      const data = await doFetchFilteredBookingList(payload);
      const lastCreatedData = data ? data[0] : {};
      setLastCreatedActivityRecord(lastCreatedData);

      const selectedCustomerId = props.selectedCustomer?.userId || lastCreatedData?.bookerUserId || null;

      const selectedCustomer: ICustomer = selectedCustomerId
        ? await doGetCustomer({ userId: selectedCustomerId })
        : null;

      // Trigger useEffect at ActivitRecordTimeInput to reset time
      setIsResetCurrentTime(true);
      let preparePrefillForm: INewActivityRecordData = {
        selectedCustomer,
        selectedCustomerId,
        bookingStartDate: moment(),
        bookingEndDate: moment().add(durationTimeBlock, 'minute'),
      };

      // Trigger set prefill default service for customer
      if (selectedCustomer?.defaultServiceId) {
        const selectedService: IServiceDetail = await doFetchSingleService({
          serviceId: selectedCustomer.defaultServiceId,
        });
        const includeStartEndTime = selectedService.serviceType !== ServiceType.COORDINATION;
        preparePrefillForm = {
          ...preparePrefillForm,
          includeStartEndTime,
          selectedService,
          selectedServiceId: selectedService.serviceId,
          paymentSourceType: selectedService.paymentSources[0],
          bookLocation: _getAddress(selectedCustomer, selectedService),
          useServiceNextTime: true,
          durationTimeBlock: selectedService.defaultActivityRecordIncrement,
          duration: selectedService.defaultActivityRecordIncrement,
          bookingStartDate: moment(),
          bookingEndDate: moment().add(selectedService.defaultActivityRecordIncrement, 'minute'),
        };
      }

      setNewActivityRecordData({
        ...newActivityRecordData,
        ...preparePrefillForm,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
      onClose();
    } finally {
      setIsPreFilling(false);
    }
  }, [doFetchFilteredBookingList, doGetCustomer, newActivityRecordData]);

  const _getAddress = (selectedCustomer: ICustomer, selectedService: IServiceDetail) => {
    if (!selectedService) return null;

    if (selectedService.serviceDirection === ServiceDirection.FIXEDVENUE) return selectedService.locations[0];

    if (!selectedCustomer) return selectedService.companyBusinessAddress;

    return selectedCustomer.addresses.find((a) => a.isPrimary === true) || selectedService.companyBusinessAddress;
  };

  const _onSelectCustomer = async (selectedCustomerId: string) => {
    const { selectedService, bookLocation } = newActivityRecordData;

    const selectedCustomer: ICustomer = selectedCustomerId ? await doGetCustomer({ userId: selectedCustomerId }) : null;

    setNewActivityRecordData({
      ...newActivityRecordData,
      selectedCustomerId,
      selectedCustomer,
      bookLocation: _getAddress(selectedCustomer, selectedService) || bookLocation,
    });
  };

  const _onSelectService = async (selectedServiceId: string) => {
    setIsLoadingService(true);
    setIsResetCurrentTime(false);
    try {
      const prevSelectedService = { ...newActivityRecordData.selectedService };
      const prevUseServiceNextTime = newActivityRecordData.useServiceNextTime;
      const selectedService: IServiceDetail = await doFetchSingleService({ serviceId: selectedServiceId });
      setLoadedSelectedService(selectedService);
      const includeStartEndTime = selectedService.serviceType !== ServiceType.COORDINATION;
      const isCustomerRememberedService =
        newActivityRecordData?.selectedCustomer?.defaultServiceId === selectedServiceId;
      setNewActivityRecordData({
        ...newActivityRecordData,
        includeStartEndTime,
        useServiceNextTime:
          (_.isEmpty(prevSelectedService) && prevUseServiceNextTime && !_.isEmpty(selectedService)) ||
          isCustomerRememberedService,
        durationTimeBlock: selectedService.defaultActivityRecordIncrement,
        duration: selectedService.defaultActivityRecordIncrement,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    } finally {
      setIsResetCurrentTime(true);
      setIsLoadingService(false);
    }
  };

  const _onCheckUseNextTime = (useServiceNextTime: boolean) => {
    setNewActivityRecordData({ ...newActivityRecordData, useServiceNextTime });
  };

  const _onSetNote = (note) => {
    setNewActivityRecordData({ ...newActivityRecordData, note });
  };

  const _onSetAttachments = (attachments: any[]) => {
    setNewActivityRecordData({ ...newActivityRecordData, attachments });
  };

  const _onChangeTimeSelectSection = (data: ITimeSelectionOnChangeData) => {
    const { dateTime, duration, durationUnit, includeStartEndTime } = data;
    const { startDate, endDate } = dateTime;
    setIsResetCurrentTime(false);
    setNewActivityRecordData({
      ...newActivityRecordData,
      bookingStartDate: moment(startDate),
      bookingEndDate: moment(endDate),
      duration: Number(duration),
      durationUnit,
      includeStartEndTime,
    });
  };

  const _onChangeBillable = () => {
    setNewActivityRecordData({
      ...newActivityRecordData,
      isAllBillingItemNonBillable: !newActivityRecordData.isAllBillingItemNonBillable,
    });
  };

  const _onSelectTeamMember = (selectedWorkerId: string) => {
    setNewActivityRecordData({
      ...newActivityRecordData,
      selectedWorkerId,
    });
  };

  const _checkServiceAgreement = useCallback(async () => {
    if (!selectedCustomerId || !selectedServiceId || !selectedService) {
      return;
    }

    const { timezone } = selectedService;

    const startDateTime = moment.tz(moment(bookingStartDate).format('YYYY-MM-DD HH:mm'), timezone).toISOString();
    const endDateTime = moment.tz(moment(bookingEndDate).format('YYYY-MM-DD HH:mm'), timezone).toISOString();

    const payload = {
      startDateTime,
      endDateTime,
      serviceId: selectedServiceId,
      customerUserId: selectedCustomerId,
      paymentSourceType,
    };

    setIsLoadingAgreement(true);
    const data = await doCheckServiceAgreementDate(payload);
    setIsLoadingAgreement(false);

    // has conflict -> has agreement
    setHasValidServiceAgreement(!_.isEmpty(data.conflict));
  }, [
    selectedCustomerId,
    selectedServiceId,
    selectedService,
    bookingStartDate,
    bookingEndDate,
    paymentSourceType,
    doCheckServiceAgreementDate,
  ]);

  const _uploadFiles = async (result) => {
    const { portalUser } = props;

    const { attachments } = newActivityRecordData;
    const { noteId, uploadBucketUrl, documentIds, bookingId } = result;
    const numberOfUploadedFile = documentIds.length;

    try {
      const promises = _.map(attachments, (attachment, index) => {
        const metadata = {
          customMetadata: {
            documentId: documentIds[index],
            serviceProviderId: portalUser.serviceProviderId,
            attendanceId: bookingId,
            noteId: noteId,
          },
        };

        const storageRef = ref(firebaseApp.storage, `${uploadBucketUrl}/${attachment.name}`);
        const uploadFile = uploadBytesResumable(storageRef, attachment, metadata);

        uploadFile.on(
          'state_changed',
          () => undefined,
          (error) => {
            notification.error({ message: 'Upload failed! Please try again.', description: error });
          },
          () => undefined,
        );

        return uploadFile;
      });

      await Promise.all(promises)
        .then(() => {
          notification.success({
            message:
              numberOfUploadedFile === 1
                ? 'Attachment is currently being scanned.'
                : `${numberOfUploadedFile} attachments are currently being scanned.`,
          });
        })
        .catch((err) =>
          notification.error({ message: 'All attachments upload failed! Please try again.', description: err }),
        );

      await new Promise((r) => setTimeout(r, 1000));
    } catch (e) {
      notification.error({ message: 'Upload failed! Please try again.', description: 'Failed to upload files.' });
    }
  };

  const _onCreateNewRecord = () => {
    form.validateFields(async (err) => {
      if (err || form.getFieldError('teamMember')) return;

      const {
        selectedCustomerId,
        selectedServiceId,
        selectedService,
        paymentSourceType,
        bookLocation,
        selectedWorkerId,
        isDuration,
        isApproved,
        durationUnit,
        bookingStartDate,
        bookingEndDate,
        attachments,
        note,
        includeStartEndTime,
        useServiceNextTime,
        isAllBillingItemNonBillable,
      } = newActivityRecordData;

      const isNotHaveNoteAndAttachments = note.noteContent.length === 0 && attachments.length === 0;

      const documents = _.map(attachments, (attachment: File) => ({
        documentName: attachment.name,
        description: `${attachment.size} Bytes`,
      }));

      const payload = {
        customerUserId: selectedCustomerId,
        address: bookLocation,

        serviceId: selectedServiceId,
        paymentSourceType: paymentSourceType,
        userSelectedBillingItems: [],

        documents: [],

        note: isNotHaveNoteAndAttachments ? null : { ...note, documents },

        startDateTime: moment.tz(moment(bookingStartDate).format('YYYY-MM-DD HH:mm'), moment.tz.guess()).toDate(),
        endDateTime: moment.tz(moment(bookingEndDate).format('YYYY-MM-DD HH:mm'), moment.tz.guess()).toDate(),
        isDuration: isDuration,
        unit: durationUnit,

        supportWorkerId: selectedWorkerId,
        shiftSlotStatus: selectedWorkerId ? ShiftSlotStatus.CONFIRMED : ShiftSlotStatus.UNASSIGNED,
        isApproved: isApproved,
        includeStartEndTime: includeStartEndTime,
        useServiceNextTime: useServiceNextTime,
        isAllBillingItemNonBillable: isAllBillingItemNonBillable,
      };

      try {
        setIsCreatingRecord(true);

        if (payload.note) {
          payload.note.visibleType = selectedService ? selectedService.defaultNotePrivacyVisible : 'PORTAL';
        }
        const data = await doCreateActivityRecord(payload);

        if (!_.isEmpty(attachments)) {
          await _uploadFiles(data);
        }

        _onDiscard(isCreateAnotherRecord);

        if (!isCreateAnotherRecord) {
          message.success({
            content: 'Activity record created',
            duration: 5,
          });
          onViewRecordCreated(data.bookingId);
        } else {
          notification.open({
            message: <b>Activity record created</b>,
            description: (
              <HyperlinkButton onClick={() => onViewRecordCreated(data.bookingId)}>View record</HyperlinkButton>
            ),
          });
        }
      } catch (e) {
        if (e.data === "Support worker is blocked under the current customer's carer preference.") {
          setIsBlockedTeamMember(true);
        }
        notification.error({ message: 'Oops, something went wrong, please try again.' });
      } finally {
        setIsCreatingRecord(false);
      }
    });
  };

  const _onDiscard = (isCreateMoreRecord: boolean) => {
    if (isCreatingRecord) return;

    if (!isCreateMoreRecord) {
      onClose();
    }
    _resetState(isCreateMoreRecord);

    setIsResetCurrentTime(false);
    setIsResetCurrentAttachment(false);
  };

  const onChangeCheckBox = (type: CheckboxType) => {
    switch (type) {
      case CheckboxType.RECORD: {
        setIsCreateAnotherRecord(!isCreateAnotherRecord);
        break;
      }
      default: {
        return;
      }
    }
  };

  const onViewRecordCreated = (bookingId: string) => {
    history.push(`/bookings/details/${bookingId}`);
  };

  useEffect(() => {
    _preFillForm();
  }, []);

  useEffect(() => {
    if (!selectedServiceId || !selectedCustomerId) return;
    _checkServiceAgreement();
  }, [selectedServiceId, selectedCustomerId, _checkServiceAgreement]);

  useEffect(() => {
    // handle fetching data from on selected service.
    if (isLoadingService !== prevLoadingService && loadedSelectedService) {
      const { selectedCustomer, bookLocation } = newActivityRecordData;
      setNewActivityRecordData({
        ...newActivityRecordData,
        selectedService: loadedSelectedService,
        selectedServiceId: loadedSelectedService.serviceId,
        paymentSourceType: loadedSelectedService.paymentSources[0],
        bookLocation: _getAddress(selectedCustomer, loadedSelectedService) || bookLocation,
      });
      setLoadedSelectedService(null);
    }
  }, [isLoadingService, prevLoadingService, loadedSelectedService, newActivityRecordData]);

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={() => _onDiscard(false)}
      canCloseOutside={false}
      title={
        !isPreFilling && (
          <Text weight='bold' size='x2-large'>
            New activity record
          </Text>
        )
      }
    >
      {isPreFilling ? (
        <div className='mb-x-large'>
          <Spinner size={120} intent={'primary'} />
        </div>
      ) : (
        <>
          <Form>
            <div className='flex-column'>
              <Col span={12} className='mv-large'>
                <div className='mb-12'>
                  <Text weight='bold'>Customer</Text>
                </div>

                <ActivityRecordCustomerSelector
                  form={form}
                  selectedCustomer={props.selectedCustomer}
                  prefilledCustomer={
                    !_.isEmpty(lastCreatedActivityRecord) && {
                      userId: lastCreatedActivityRecord.bookerUserId,
                      firstName: lastCreatedActivityRecord.customerFirstName,
                      lastName: lastCreatedActivityRecord.customerLastName,
                      attachmentUrl: lastCreatedActivityRecord.attachmentUrl,
                    }
                  }
                  pageSize={6}
                  onChange={_onSelectCustomer}
                />
              </Col>

              <Col span={24} className='mb-large'>
                <div className='mb-12'>
                  <Text weight='bold'>Service provided</Text>
                </div>

                <ActivityRecordServiceSelector
                  form={form}
                  onSelectService={_onSelectService}
                  onCheckUseNextTime={_onCheckUseNextTime}
                  isLoadingServiceAgreement={isLoadingAgreement}
                  hasValidServiceAgreement={hasValidServiceAgreement}
                  newActivityRecordData={newActivityRecordData}
                />

                <Text size='x-small' color='secondary'>
                  {newActivityRecordData.selectedServiceId
                    ? 'Billing line items can be modified after record creation.'
                    : 'Select a Service to view budget details.'}
                </Text>
              </Col>

              <Col span={24} className='mb-large'>
                <ActivityRecordNoteAndAttachments
                  note={newActivityRecordData.note}
                  setNote={_onSetNote}
                  attachments={newActivityRecordData.attachments}
                  setAttachments={_onSetAttachments}
                  isResetCurrentAttachment={isResetCurrentAttachment}
                />
              </Col>

              <Col span={24} className='mb-large'>
                <ActivityRecordTimeSelectSection
                  isResetCurrentTime={isResetCurrentTime}
                  onChange={_onChangeTimeSelectSection}
                  startDate={newActivityRecordData.bookingStartDate}
                  endDate={newActivityRecordData.bookingEndDate}
                  durationTimeBlock={newActivityRecordData.durationTimeBlock}
                  duration={newActivityRecordData.duration}
                  durationUnit={newActivityRecordData.durationUnit}
                  includeStartEndTime={newActivityRecordData.includeStartEndTime}
                  timezone={moment.tz.guess()}
                />
                {newActivityRecordData.duration === 0 && (
                  <Text color='red' className='mt-small'>
                    Duration is required.
                  </Text>
                )}
                <Checkbox
                  className='mt-small'
                  checked={newActivityRecordData.isAllBillingItemNonBillable}
                  onChange={_onChangeBillable}
                >
                  <Text color='secondary'>Mark time as non-billable</Text>
                </Checkbox>
              </Col>

              <Col span={12} className='mb-large'>
                <div className='mb-12'>
                  <Text weight='bold'>Team member</Text> (optional)
                </div>

                <ActivityRecordTeamMemberSelector
                  form={form}
                  selectedWorkerId={newActivityRecordData.selectedWorkerId}
                  selectedServiceId={newActivityRecordData.selectedServiceId}
                  onSelectTeamMember={_onSelectTeamMember}
                  isBlockedTeamMember={isBlockedTeamMember}
                />
              </Col>
            </div>
          </Form>
          <ActionModalFooter className='align-center flex justify-between'>
            <Checkbox checked={isCreateAnotherRecord} onChange={() => onChangeCheckBox(CheckboxType.RECORD)}>
              Create another record
            </Checkbox>
            <div>
              <SecondaryButton size='large' onClick={() => _onDiscard(false)} disabled={isCreatingRecord}>
                Discard
              </SecondaryButton>

              <PrimaryButton
                className='ml-medium'
                size='large'
                onClick={_onCreateNewRecord}
                loading={isCreatingRecord}
                disabled={
                  !newActivityRecordData.selectedCustomerId ||
                  !newActivityRecordData.selectedServiceId ||
                  newActivityRecordData.duration === 0 ||
                  form.getFieldError('teamMember') ||
                  isCreatingRecord
                }
              >
                {isCreatingRecord ? 'Saving...' : 'Create'}
              </PrimaryButton>
            </div>
          </ActionModalFooter>
        </>
      )}
    </ActionModal>
  );
};

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomer: dispatch.customersStore.doGetCustomer,
  doFetchSingleService: dispatch.servicesStore.doFetchSingleService,
  doCreateActivityRecord: dispatch.bookingsStore.doCreateActivityRecord,
  doFetchFilteredBookingList: dispatch.bookingsStore.doFetchFilteredBookingList,
  doCheckServiceAgreementDate: dispatch.bookingsStore.doCheckServiceAgreementDate,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<ICreateNewActivityRecordModalV2Props>()(CreateNewActivityRecordModalV2));
