import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Newspaper = forwardRef(function Newspaper(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.624 2.351a.75.75 0 0 1 .748-.002L6.5 4.136 9.628 2.35a.75.75 0 0 1 .744 0L13.5 4.136l3.128-1.787A.75.75 0 0 1 17.75 3v8.25H21a.75.75 0 0 1 .75.75v7A2.75 2.75 0 0 1 19 21.75H8.945c-1.367 0-2.47 0-3.337-.116-.9-.122-1.658-.38-2.26-.982-.601-.602-.86-1.36-.981-2.26-.117-.867-.117-1.97-.117-3.337V3a.75.75 0 0 1 .374-.649ZM19 20.25c.69 0 1.25-.56 1.25-1.25v-6.25h-2.5V19c0 .69.56 1.25 1.25 1.25Zm-2.45 0H9c-1.435 0-2.436-.002-3.192-.103-.734-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191V4.292l2.378 1.36a.75.75 0 0 0 .744 0L10 3.863l3.128 1.787a.75.75 0 0 0 .744 0l2.378-1.359V19c0 .45.108.875.3 1.25ZM6.25 9A.75.75 0 0 1 7 8.25h4a.75.75 0 0 1 0 1.5H7A.75.75 0 0 1 6.25 9ZM7 12.25a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5H7ZM6.25 17a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
