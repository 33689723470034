import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const FastForward = forwardRef(function FastForward(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m14.476 5.416.05.033 6.394 4.263.046.03c.525.35.987.658 1.31.951.345.311.671.726.671 1.307s-.326.996-.67 1.307c-.324.292-.786.6-1.311.95l-.046.031-6.395 4.263-.05.033c-.641.428-1.193.796-1.651 1.012-.471.223-1.063.392-1.65.078s-.774-.9-.85-1.415c-.074-.502-.074-1.165-.074-1.937V15.402l-4.725 3.15-.05.032c-.641.428-1.193.796-1.651 1.012-.471.223-1.063.392-1.65.078s-.774-.9-.85-1.415c-.074-.502-.074-1.165-.074-1.937V7.677c0-.771 0-1.434.074-1.936.076-.515.263-1.101.85-1.415.587-.315 1.179-.145 1.65.078.458.216 1.01.584 1.652 1.012l.05.033 4.724 3.15V7.677c0-.771 0-1.434.074-1.936.076-.515.263-1.101.85-1.415.587-.315 1.179-.145 1.65.078.458.216 1.01.584 1.652 1.012Zm-2.62.24s.003-.002.012-.002l-.012.002Zm.03 0c.042.004.131.025.297.104.346.163.805.467 1.51.937l6.395 4.263c.585.39.952.637 1.183.846.11.099.154.16.17.189l.004.005-.003.005a.878.878 0 0 1-.171.189c-.231.21-.598.456-1.183.846l-6.395 4.263c-.705.47-1.164.774-1.51.937-.166.079-.255.1-.297.105a1.047 1.047 0 0 1-.078-.306c-.056-.378-.058-.928-.058-1.776V14a.75.75 0 0 0-1.166-.624l-5.89 3.927c-.706.47-1.165.774-1.51.937a1.05 1.05 0 0 1-.298.105 1.05 1.05 0 0 1-.078-.306c-.056-.378-.058-.928-.058-1.776V7.737c0-.848.002-1.398.058-1.776.027-.182.059-.269.078-.306.041.005.131.026.297.105.346.163.805.467 1.51.937l5.89 3.927A.75.75 0 0 0 11.75 10V7.737c0-.848.002-1.398.058-1.776.027-.182.059-.269.078-.306ZM2.903 5.63c0 .001-.002.005-.009.01l.009-.01Zm-.034.024-.013.002s.003-.002.013-.002Zm-.013 12.69.013.002c-.01 0-.014-.001-.013-.002Zm.038.016c.007.005.01.009.009.01l-.009-.01Zm8.962-.016.012.002c-.009 0-.013-.001-.012-.002Zm.038.016c.007.005.01.009.009.01l-.009-.01Zm0-12.72c.006-.008.009-.01.01-.01 0 .001-.003.005-.01.01Z"
        fill="currentColor"
      />
    </Icon>
  );
});
