import React from 'react';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { IRootDispatch, dispatch } from 'stores/rematch/root-store';
import { Paragraph } from 'common-components/typography';
import { Col, Row, notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

interface IDeleteSessionFormModalProps {
  isOpen: boolean;
  serviceDateTimeFormId: string;
  serviceId: string;
  serviceDateTimeId: string;
  onClose: () => void;
  doDeleteSessionForm: typeof dispatch.groupServiceStore.doDeleteSessionForm;
  fetchFormList: () => void;
}
const DeleteSessionFormModal = ({
  isOpen,
  onClose,
  serviceDateTimeFormId,
  serviceId,
  serviceDateTimeId,
  doDeleteSessionForm,
  fetchFormList,
}: IDeleteSessionFormModalProps) => {
  const _onDelete = async () => {
    try {
      await doDeleteSessionForm({
        serviceDateTimeFormId,
        serviceId,
        serviceDateTimeId,
      });
      onClose();

      notification.open({
        message: <span className={'text-weight-bolder'}>Form removed</span>,
        description: <span>You have successfully removed a form from this session.</span>,
      });
      fetchFormList();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  return (
    <>
      <ActionModal isOpen={isOpen} title={'Remove form'} onClose={onClose}>
        <Paragraph>Are you sure you want to remove this form.</Paragraph>
        <Paragraph>This action is permanent.</Paragraph>

        <ActionModalFooter className={'mt-large'}>
          <Row type={'flex'} className={'justify-end'}>
            <Col className="mr-medium">
              <SecondaryButton size="large" onClick={onClose}>
                Cancel
              </SecondaryButton>
            </Col>
            <Col>
              <PrimaryButton onClick={_onDelete} size="large">
                Remove
              </PrimaryButton>
            </Col>
          </Row>
        </ActionModalFooter>
      </ActionModal>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doDeleteSessionForm: dispatch.groupServiceStore.doDeleteSessionForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSessionFormModal);
