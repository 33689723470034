import { Heading, Text } from '@good/components';
import { Info } from '@good/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TooltipInfo from 'views/team-v2/team-details/components/TooltipInfo';
import { InsightsTableResponseRow } from '../../insights';
import { InsightsTableRow, InsightsTableRowSkeleton } from './insights-table-row';

type DataStatus = 'error' | 'idle' | 'loading' | 'success';

type InsightsTableProperties = {
  rows: InsightsTableResponseRow[] | undefined;
  resultsPerPage: number;
  dataStatus: DataStatus;
};

const UtilisationInfoMessage: React.FC = () => {
  const { t } = useTranslation('', { keyPrefix: 'insights.table.utilisationInfoPopup' });

  return (
    <div className="border-px w-72 p-3">
      <Heading className="text-white">{t('heading')}</Heading>
      <Text>{t('bodyParagraphLineOne')}</Text>
      <br />
      <br />
      <Text>{t('bodyParagraphLineTwo')}</Text>
      <br />
      <br />
      <Text>{t('bodyParagraphLineThree')}</Text>
    </div>
  );
};

const UtilisationInfoIcon: React.FC = () => (
  <div className="inline h-4 w-4 text-gray-dark-1">
    <Info />
  </div>
);

const InsightsTableBody: React.FC<InsightsTableProperties> = ({ rows, dataStatus, resultsPerPage }) => {
  if (dataStatus !== 'success') {
    return (
      <>
        {Array.from({ length: resultsPerPage }).map((_, index) => (
          <InsightsTableRowSkeleton key={`row ${index}`} />
        ))}
      </>
    );
  }

  return (
    <tbody className="w-full">
      {rows?.map((row) => (
        <InsightsTableRow key={`row ${row.supportWorkerId}`} dataStatus={dataStatus} {...row} />
      ))}
    </tbody>
  );
};

export const InsightsTable: React.FC<InsightsTableProperties> = (properties) => {
  const { t } = useTranslation('', { keyPrefix: 'insights.table.tableHeaders' });

  return (
    <table className="text-sm w-full table-fixed border-separate border-spacing-y-4 border-none [&>*>tr>td]:border-t-[1px] [&>*>tr>td]:border-base [&>*>tr>td]:pt-4">
      <thead>
        <tr>
          <th className="w-[30%] whitespace-nowrap text-h-xs uppercase">
            <Text>{t('teamMember')}</Text>
          </th>
          <th className="text-h-xs uppercase">
            <Text>{t('contractedHours')}</Text>
          </th>
          <th className="text-h-xs uppercase">
            <Text>{t('billedHours')}</Text>
          </th>
          <th className="text-h-xs uppercase">
            <Text>{t('nonBilledHours')}</Text>
          </th>
          <th className="text-h-xs uppercase">
            <div className="align-center flex flex-row space-x-3">
              <Text>{t('utilisation')}</Text>
              <TooltipInfo
                placement="top right"
                isShow={true}
                message={<UtilisationInfoMessage />}
                content={<UtilisationInfoIcon />}
              />
            </div>
          </th>
        </tr>
      </thead>
      <InsightsTableBody {...properties} />
    </table>
  );
};
