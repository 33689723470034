import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, Text } from 'common-components/typography';
import { notification, Switch, Icon } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { australianFlag } from 'assets/Icons/country-flags';
import _ from 'lodash';
import Utils from 'utilities/Utils';
import { PaymentSourceType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';

interface IEditVCPModalProps {
  isOpen: any;
  onClose: () => void;
  doEditVCPConfig: typeof dispatch.companyStore.doEditVCPConfig;
  companyFundingAndPayment: typeof state.companyStore.companyFundingAndPayment;
}

interface IEditVCPModalState {
  isUpdated: boolean;
  isLoading: boolean;
  isError: boolean;
  isVCPOn: boolean;
}

class EditVCPModal extends Component<IEditVCPModalProps, IEditVCPModalState> {
  state = {
    isUpdated: false,
    isLoading: false,
    isError: false,
    isVCPOn: false,
  };

  private _onCloseModal = async () => {
    if (!this.state.isLoading) {
      await this.props.onClose();
      this.setState({ isUpdated: false, isError: false });
    }
  };

  private _onPressUpdate = async () => {
    const { doEditVCPConfig } = this.props;

    let isError = false;
    this.setState({ isLoading: true });
    try {
      const resp: any = await doEditVCPConfig({ vcpEnabled: this.state.isVCPOn });
      this.setState({ isUpdated: true, isLoading: false });
    } catch (e) {
      if (e.meta.message === 'You cannot disable VCP as a payment method while you have active services using VCP.') {
        this.setState({ isLoading: false, isError: true, isVCPOn: true });
      } else if (
        e.meta.message === 'Service provider has already enabled VCP' ||
        e.meta.message === 'Service provider has already disabled VCP'
      ) {
        this.setState({ isLoading: false });
        this._onCloseModal();
      } else {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
        this.setState({ isLoading: false });
      }
    }
  };
  async componentDidMount() {
    const isVCPEnabled = await PermissionUtils.getIsServiceProviderVCPEnabled();
    this.setState({
      isVCPOn: !!isVCPEnabled,
    });
  }

  async componentDidUpdate(
    prevProps: Readonly<IEditVCPModalProps>,
    prevState: Readonly<IEditVCPModalState>,
    snapshot?: any,
  ) {
    const { companyFundingAndPayment } = this.props;
    if (!_.isEqual(prevProps.companyFundingAndPayment, companyFundingAndPayment)) {
      const isVCPEnabled = await PermissionUtils.getIsServiceProviderVCPEnabled();
      this.setState({
        isVCPOn: !!isVCPEnabled,
      });
    }
  }

  render() {
    const { isOpen } = this.props;
    const { isVCPOn, isLoading, isUpdated, isError } = this.state;
    return (
      <ActionModal
        title={"Vulnerable Children's Program (VCP)"}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="large"
      >
        {isUpdated ? (
          <>
            {isVCPOn && (
              <div className="mv-medium anim-slide-left">
                <Paragraph>You can now create services for and bill customers for VCP.</Paragraph>
              </div>
            )}
            {!isVCPOn && (
              <div className="mv-medium anim-slide-left">
                <Paragraph>You have deactivated the VCP payment of GoodHuman.</Paragraph>
              </div>
            )}
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Done
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : (
          <>
            <div className="anim-slide-left">
              <div className={'mb-x-large'}>
                <img src={australianFlag} height={'8px'} alt={'Australian National Flag'} className={'mr-x-small'} />
                <Text>Australia</Text>
                <Paragraph className={'mt-small'}>
                  The Vulnerable Children's Program is an Australian Government scheme aimed at supporting at-risk
                  children in the community.
                </Paragraph>
                <div className="mt-large flex-row align-center">
                  <Switch
                    className={isVCPOn ? 'bg-green-light' : null}
                    checked={isVCPOn}
                    onChange={(value) => this.setState({ isVCPOn: value })}
                  />
                  <b className="ml-medium">{isVCPOn ? 'ON' : 'OFF'}</b>
                </div>
                {isError && (
                  <div className="mt-small text-color-red-dark">
                    <Icon type="exclamation-circle" theme="filled" className="text-color-red-dark mr-x-small" /> You
                    cannot disable VCP as a payment method while you have active services using VCP.
                  </div>
                )}
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal} disabled={isLoading}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onPressUpdate} disabled={isLoading} loading={isLoading}>
                  Save changes
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPayment: state.companyStore.companyFundingAndPayment,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditVCPConfig: dispatch.companyStore.doEditVCPConfig,
});

export default connect(mapState, mapDispatch)(EditVCPModal);
