import { useState, useEffect } from 'react';

import { WorkLeaveTypeTableOptions } from './WorkLeaveTypesTable';
import useWorkLeaveTypes from './use-work-leave-types';
import { WorkTypeCategory, WorkTypeState } from 'utilities/enum-utils';

function useCount(tableOptions: { [key in WorkTypeCategory]?: WorkLeaveTypeTableOptions }): {
  workTypesCount: number;
  leaveTypesCount: number;
} {
  const [workTypesCount, setWorkTypesCount] = useState(0);
  const [leaveTypesCount, setLeaveTypesCount] = useState(0);
  const { workTypes, leaveTypes } = useWorkLeaveTypes(true);

  useEffect(() => {
    const selectedWorkTypes = workTypes.data?.filter(
      (workType) => tableOptions.WORK_TYPE?.selectedWorkLeaveTypeStates?.[WorkTypeState[workType.state]] ?? true,
    );
    setWorkTypesCount(selectedWorkTypes?.length ?? 0);
  }, [tableOptions.WORK_TYPE?.selectedWorkLeaveTypeStates, workTypes.data]);

  useEffect(() => {
    const selectedLeaveTypes = leaveTypes.data?.filter(
      (workType) => tableOptions.LEAVE_TYPE?.selectedWorkLeaveTypeStates?.[WorkTypeState[workType.state]] ?? true,
    );
    setLeaveTypesCount(selectedLeaveTypes?.length ?? 0);
  }, [leaveTypes.data, tableOptions.LEAVE_TYPE?.selectedWorkLeaveTypeStates]);

  return {
    workTypesCount,
    leaveTypesCount,
  };
}

export default useCount;
