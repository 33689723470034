import React, { PureComponent } from 'react';
import { Button } from 'antd';

const defaultColor = 'blue-action';
const defaultIconColor = 'white';
const defaultShape = 'default';
const defaultSize = 'default';
const defaultIcon = 'menu';

interface IconButtonProps {
  icon: string;
  iconColor?: string;
  onClick?: any;
  size?: 'default' | 'small' | 'large';
  className?: string;
  color?: string;
  disabled?: boolean;
  loading?: boolean;
  bordered?: boolean;
  shape?: 'circle' | 'round' | 'circle-outline' | 'default';
  'data-testid'?: string;
}

class IconButton extends PureComponent<IconButtonProps, any> {
  render() {
    const {
      className = '',
      onClick = () => console.log('button handler not implemented'),
      color = defaultColor,
      size = defaultSize,
      icon = defaultIcon,
      iconColor = defaultIconColor,
      shape = defaultShape,
      bordered = false,
      disabled = false,
      loading = false,
    } = this.props;

    let finalClassName = '';

    const borderedClass = bordered ? 'bordered border-standard-gray ' : 'bordered-none ';

    finalClassName += borderedClass;

    let finalShapeProps: any = { shape };

    if (disabled) {
      // finalClassName += 'bg-secondary bordered-none text-color-tertiary ';
      finalClassName += 'bg-white bordered-none text-color-tertiary ';
    } else {
      finalClassName += 'bg-' + color + ' text-color-' + iconColor + ' ';
    }

    if (loading) {
      finalClassName += 'cursor-wait ';
    }

    finalClassName += className;

    if (shape === 'default') {
      finalShapeProps = {};
    }

    return (
      <Button
        icon={icon}
        className={finalClassName}
        onClick={onClick}
        size={size}
        {...finalShapeProps}
        loading={loading}
        disabled={disabled}
        data-testid={this.props['data-testid']}
      />
    );
  }
}

export default IconButton;
