import { Col } from 'antd';
import React, { Component } from 'react';
import SubTitle from '../typography/SubTitle';

interface IFormColProps {
  leftSpan: number;
  rightSpan?: number;
  label: string;
  isDisplayFlex?: boolean;
  textAlign?: 'left' | 'right' | string;
  columns?: 1 | 2;
  showMarginBottom?: boolean;
  children?: React.ReactNode;
}

class FormCol extends Component<IFormColProps, any> {
  render() {
    const {
      leftSpan,
      rightSpan = 1,
      label,
      isDisplayFlex = false,
      children,
      textAlign = 'left',
      columns = 1,
      showMarginBottom = true,
    } = this.props;

    let labelColClassName = 'pt-small';
    let valueColClassName = 'pt-small';

    if (showMarginBottom) {
      labelColClassName += ' mb-small';
      valueColClassName += ' mb-small';
    }

    if (textAlign === 'left') {
      labelColClassName += ' text-align-left';
    } else {
      labelColClassName += ' text-align-right';
    }

    if (columns == 1) {
      labelColClassName += ' flex';
    } else {
      if (isDisplayFlex) {
        valueColClassName += ' flex';
      }
    }

    return columns == 2 ? (
      <>
        <Col span={leftSpan} className={labelColClassName}>
          <SubTitle>{label}</SubTitle>
        </Col>
        <Col span={rightSpan} className={valueColClassName}>
          {children}
        </Col>
      </>
    ) : (
      <Col span={leftSpan} className={labelColClassName}>
        <SubTitle>{label}</SubTitle>
        {children}
      </Col>
    );
  }
}

export default FormCol;
