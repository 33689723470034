import moment, { Moment } from 'moment-timezone';
import React from 'react';
import { Component } from 'react';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import DatePicker from 'react-datepicker';

import NumberInput from 'common-components/inputs/NumberInput';
import { Checkbox, Form, Row, Select } from 'antd';
import { RecurringBookingPattern } from 'utilities/enum-utils';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Information } from 'common-components/alerts';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import CommonUtils from 'utilities/common-utils';
import { FormComponentProps } from 'antd/es/form';
import {
  IQuotationTimeSlot,
  ISubscriptionQuote,
  ISubscriptionQuoteSelectedService,
} from 'interfaces/customer-interfaces';

interface IAddSubscriptionQuoteModalProps extends FormComponentProps {
  onClose: () => void;
  isOpen: boolean;
  isEdit: boolean;
  quotation?: ISubscriptionQuote;
  selectedService?: ISubscriptionQuoteSelectedService;
  onAddEditQuotation: (quotation) => void;
  paymentSourceType: string;
  doGetQuotation: typeof dispatch.customersStore.doGetQuotation;
  isNewServiceAgreement: boolean;
  userServiceAgreementId: string;
}

interface IAddSubscriptionQuoteModalState {
  step: number;
  title: string;
  subscriptionPrice: number;
  frequency: number;
  startDateTime: Moment;
  endDateTime: Moment;
  isEndDateIncluded: boolean;
  quoteTotal: number;
}

class AddSubscriptionQuoteModal extends Component<IAddSubscriptionQuoteModalProps, IAddSubscriptionQuoteModalState> {
  state = {
    step: 1,
    title: 'Create a subscription quote',
    subscriptionPrice: 0,
    frequency: RecurringBookingPattern.EveryDay,
    startDateTime: moment(this.props.selectedService?.startDate),
    endDateTime: moment(this.props.selectedService?.endDate),
    isEndDateIncluded: false,
    quoteTotal: 0,
  };

  componentDidUpdate(prevProps: Readonly<IAddSubscriptionQuoteModalProps>): void {
    const { quotation, isEdit } = this.props;
    if (
      (prevProps.quotation !== quotation && isEdit && this.props.isOpen) ||
      (!prevProps.isOpen && this.props.isOpen)
    ) {
      const subscriptionPrice = quotation ? quotation.subscriptionPrice : 0;
      const recurringPattern = quotation ? quotation.timeSlots[0].recurringPattern : RecurringBookingPattern.EveryDay;
      const startDateTime = quotation
        ? moment(moment.tz(quotation.timeSlots[0].startDateTime, this.props.selectedService.timezone))
        : moment(moment.tz(moment(this.props.selectedService.startDate), this.props.selectedService.timezone)).startOf(
            'day',
          );
      const endDateTime = quotation
        ? moment(moment.tz(quotation.timeSlots[0].endDateTime, this.props.selectedService.timezone)).startOf('day')
        : moment(moment.tz(moment(this.props.selectedService.endDate), this.props.selectedService.timezone)).startOf(
            'day',
          );

      const quoteAmount = quotation ? quotation.quoteAmount : 0;

      const isEndDateIncluded = quotation ? quotation.isEndDateIncluded : false;

      this.setState({
        subscriptionPrice,
        frequency: recurringPattern,
        startDateTime,
        endDateTime,
        quoteTotal: quoteAmount,
        isEndDateIncluded,
        step: 1,
        title: 'Create a subscription quote',
      });
    }
  }

  private _calculateTotalQuote = (
    subscriptionPrice: number,
    frequency: number,
    startDateTime: Moment,
    endDateTime: Moment,
    isEndDateIncluded: boolean,
  ) => {
    const { selectedService } = this.props;
    let multiplier = 1;
    const momentStartDateTime = moment.tz(startDateTime, selectedService.timezone).startOf('day');
    const isSameDate = moment
      .tz(startDateTime, selectedService.timezone)
      .isSame(moment.tz(endDateTime, selectedService.timezone).startOf('day'));
    const momentEndDateTime =
      isEndDateIncluded || isSameDate
        ? moment.tz(endDateTime, selectedService.timezone).endOf('day')
        : moment.tz(endDateTime, selectedService.timezone).startOf('day');

    switch (frequency) {
      case RecurringBookingPattern.EveryDay: {
        multiplier = momentStartDateTime.diff(momentEndDateTime, 'days', true);
        break;
      }
      case RecurringBookingPattern.EveryWeek: {
        multiplier = momentStartDateTime.diff(momentEndDateTime, 'weeks', true);
        break;
      }
      case RecurringBookingPattern.EveryFortnight: {
        multiplier = momentStartDateTime.diff(momentEndDateTime, 'weeks', true) / 2;
        break;
      }
      case RecurringBookingPattern.EveryFourWeeks: {
        multiplier = momentStartDateTime.diff(momentEndDateTime, 'weeks', true) / 4;
        break;
      }
      case RecurringBookingPattern.Monthly: {
        multiplier = momentStartDateTime.diff(momentEndDateTime, 'months', true);
        break;
      }
    }

    multiplier = Math.ceil(Math.abs(multiplier)) || 1;
    const totalQuote = subscriptionPrice * multiplier;

    return totalQuote;
  };

  private _onChangeSubscriptionPrice = (value) => {
    const { form } = this.props;
    const { setFieldsValue } = form;
    const { frequency, startDateTime, endDateTime, isEndDateIncluded } = this.state;
    const quoteTotal = this._calculateTotalQuote(value, frequency, startDateTime, endDateTime, isEndDateIncluded);
    setFieldsValue({ subscriptionPrice: value });
    this.setState({ subscriptionPrice: value, quoteTotal });
  };

  private _changeRecurringPattern = (value) => {
    const { subscriptionPrice, startDateTime, endDateTime, isEndDateIncluded } = this.state;

    if (this.state.frequency === value) {
      this.setState({ frequency: value });
    } else {
      const quoteTotal = this._calculateTotalQuote(
        subscriptionPrice,
        value,
        startDateTime,
        endDateTime,
        isEndDateIncluded,
      );
      this.setState({ frequency: value, quoteTotal });
    }
  };

  private _onChangeStartDateTime = (value: Date) => {
    const { startDateTime, endDateTime, subscriptionPrice, frequency, isEndDateIncluded } = this.state;
    const { selectedService } = this.props;
    let currentEndDateTime = endDateTime;
    if (currentEndDateTime && moment(value).isAfter(moment(currentEndDateTime))) {
      currentEndDateTime = moment.tz(value, selectedService.timezone).startOf('day');
    }

    if (!moment(currentEndDateTime).isSame(moment(endDateTime)) || !moment(value).isSame(moment(startDateTime))) {
      const quoteTotal = this._calculateTotalQuote(
        subscriptionPrice,
        frequency,
        moment(value),
        currentEndDateTime,
        isEndDateIncluded,
      );
      this.setState({
        startDateTime: moment.tz(value, selectedService.timezone).startOf('day'),
        endDateTime: currentEndDateTime,
        quoteTotal,
      });
    } else {
      this.setState({
        startDateTime: moment.tz(value, selectedService.timezone).startOf('day'),
        endDateTime: currentEndDateTime,
      });
    }
  };

  private _onChangeEndDateTime = (value: Date) => {
    const { subscriptionPrice, frequency, startDateTime, isEndDateIncluded } = this.state;
    const { selectedService } = this.props;
    const quoteTotal = this._calculateTotalQuote(
      subscriptionPrice,
      frequency,
      startDateTime,
      moment(value),
      isEndDateIncluded,
    );
    this.setState({
      endDateTime: moment.tz(value, selectedService.timezone).startOf('day'),
      quoteTotal,
    });
  };

  private _changeIncludeEndDate = (e) => {
    const { frequency, subscriptionPrice, startDateTime, endDateTime } = this.state;
    const isEndDateIncluded = e.target.checked;
    const quoteTotal = this._calculateTotalQuote(
      subscriptionPrice,
      frequency,
      startDateTime,
      endDateTime,
      isEndDateIncluded,
    );
    this.setState({ isEndDateIncluded, quoteTotal });
  };

  private _goToNext = () => {
    const { form } = this.props;
    let hasError = false;
    form.validateFields((err) => {
      if (err) {
        hasError = true;
      }
    });

    if (!hasError) {
      this.setState({ step: 2, title: 'Confirm subscription quote' });
    }
  };

  private _goToPrevious = () => {
    this.setState({ step: 1, title: 'Create a subscription quote' });
  };

  private _onClose = () => {
    this.setState({
      step: 1,
      subscriptionPrice: 0,
      frequency: RecurringBookingPattern.EveryDay,
      startDateTime: moment(this.props.selectedService.startDate),
      endDateTime: moment(this.props.selectedService.endDate),
      isEndDateIncluded: false,
      quoteTotal: 0,
    });
    this.props.onClose();
  };

  private _onSave = () => {
    const { selectedService, onAddEditQuotation } = this.props;
    const { quoteTotal, startDateTime, endDateTime, frequency, subscriptionPrice, isEndDateIncluded } = this.state;

    const timeSlots: IQuotationTimeSlot[] = [
      {
        recurringPattern: frequency,
        startDateTime: moment.tz(moment(startDateTime).format('YYYY-MM-DD HH:mm'), selectedService.timezone).toDate(),
        endDateTime: moment
          .tz(moment(endDateTime).endOf('day').format('YYYY-MM-DD HH:mm'), selectedService.timezone)
          .toDate(),
        recurringTo: moment
          .tz(moment(endDateTime).endOf('day').format('YYYY-MM-DD HH:mm'), selectedService.timezone)
          .toDate(),
      },
    ];

    onAddEditQuotation({
      serviceName: selectedService.serviceName,
      timeSlots,
      serviceId: selectedService.serviceId,
      quoteAmount: quoteTotal,
      isGroupService: false,
      excludePublicHolidays: false,
      isManualQuote: false,
      isSubscriptionQuote: true,
      isEndDateIncluded,
      subscriptionPrice,
      subQuotes: [
        {
          quoteAmount: quoteTotal,
          multiplier: quoteTotal / subscriptionPrice,
        },
      ],
    });

    this._onClose();
  };

  private _validateSubscriptionPrice = (rule, value, callback) => {
    try {
      if (value <= 0) {
        throw Error('Must be greater than 0');
      }
    } catch (e) {
      callback(e);
    }
  };

  render() {
    const { quotation, isOpen, selectedService, form } = this.props;
    const { quoteTotal, title, subscriptionPrice, startDateTime, endDateTime, frequency, isEndDateIncluded, step } =
      this.state;

    const { getFieldDecorator } = form;

    const pickerStartDateTime =
      quotation && selectedService
        ? moment.tz(startDateTime, selectedService.timezone).toDate()
        : moment(moment.tz(startDateTime, selectedService ? selectedService.timezone : '')).toDate();

    const pickerEndDateTime =
      quotation && selectedService
        ? moment.tz(endDateTime, selectedService.timezone).toDate()
        : moment(moment.tz(endDateTime, selectedService ? selectedService.timezone : '')).toDate();

    const quoteTotalRounded = Math.round(quoteTotal * 100) / 100;

    return (
      <ActionModal isOpen={isOpen} width="large" title={title} canCloseOutside={false} onClose={this._onClose}>
        {step === 1 && (
          <>
            <div className="ph-none pv-medium">
              <div className="mb-medium">
                <Row>
                  <SubTitle>SUBSCRIPTION PRICE</SubTitle>
                </Row>
                <Row>
                  <Form.Item className="m-none">
                    {getFieldDecorator('subscriptionPrice', {
                      initialValue: subscriptionPrice,
                      rules: [{ validator: this._validateSubscriptionPrice }],
                    })(
                      <NumberInput
                        onChange={this._onChangeSubscriptionPrice}
                        min={0}
                        max={10000000}
                        className="mr-small"
                        addonBefore="$"
                        style={{ width: '150px' }}
                      />,
                    )}
                  </Form.Item>
                </Row>
              </div>
              <div className="mb-medium width-full">
                <div>
                  <SubTitle>BILLING DATES</SubTitle>
                </div>
                <div className="flex-row align-center">
                  <Select
                    size="large"
                    className="mr-small"
                    placeholder="Select pattern"
                    style={{ width: '200px' }}
                    value={frequency}
                    onChange={this._changeRecurringPattern}
                  >
                    <Select.Option key={0} value={RecurringBookingPattern.EveryDay}>
                      Every day
                    </Select.Option>
                    <Select.Option key={0} value={RecurringBookingPattern.EveryWeek}>
                      Every week
                    </Select.Option>
                    <Select.Option key={0} value={RecurringBookingPattern.EveryFortnight}>
                      Every fortnight
                    </Select.Option>
                    <Select.Option key={0} value={RecurringBookingPattern.EveryFourWeeks}>
                      Every 4 weeks
                    </Select.Option>
                    <Select.Option key={0} value={RecurringBookingPattern.Monthly}>
                      Monthly
                    </Select.Option>
                  </Select>{' '}
                  <Text color="secondary">from</Text>
                  <DatePicker
                    onChange={this._onChangeStartDateTime}
                    className="gh-datepicker rounded mh-small"
                    calendarClassName="gh-datepicker-calendar"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start date"
                    selected={pickerStartDateTime}
                    minDate={moment(selectedService?.startDate).startOf('day').toDate()}
                    maxDate={moment(selectedService?.endDate).endOf('day').toDate()}
                  />
                  <Text color="secondary">till</Text>
                  <DatePicker
                    onChange={this._onChangeEndDateTime}
                    className="gh-datepicker rounded mh-small"
                    calendarClassName="gh-datepicker-calendar"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End date"
                    selected={pickerEndDateTime}
                    minDate={moment(startDateTime ? startDateTime : null).toDate()}
                    maxDate={moment(selectedService?.endDate).endOf('day').toDate()}
                  />
                </div>
              </div>
              <div className="flex-row align-center mb-medium">
                <Checkbox checked={isEndDateIncluded} onClick={this._changeIncludeEndDate}>
                  Include end date in subscription period
                </Checkbox>
              </div>
              <div className="flex-row align-center mb-medium width-3/4">
                <Information
                  content={
                    <Text>
                      <b>This quote assumes the customer will be billed at the start of each subscription period.</b>{' '}
                      <br /> If a customer&#39;s billing dates enter a new subscription period by one day, they will be
                      quoted for the entire period.
                    </Text>
                  }
                ></Information>
              </div>
              <div className="mb-medium">
                <Row>
                  <SubTitle>QUOTE TOTAL</SubTitle>
                </Row>
                <Row>
                  <NumberInput
                    min={0}
                    max={10000000}
                    value={quoteTotalRounded}
                    className="mr-small"
                    addonBefore="$"
                    style={{ width: '150px' }}
                    disabled
                    precision={2}
                  />
                </Row>
              </div>
            </div>
            <ActionModalFooter>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._goToNext} disabled={!selectedService}>
                Next
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 2 && (
          <>
            <div className="ph-none pv-medium">
              <div className="width-4/5">
                <Paragraph>
                  Budget tracking and related warnings for this service will be calculated against this quote total.
                </Paragraph>
              </div>

              <div className="mv-medium">
                <Row>
                  <SubTitle>QUOTE TOTAL</SubTitle>
                </Row>
                <Row>
                  <Text color="primary">
                    {quoteTotal === 0 ? (
                      <>{CommonUtils.formatPrice(quoteTotal)}</>
                    ) : (
                      <>
                        <NumberInput
                          min={0}
                          max={10000000}
                          value={quoteTotal}
                          className="mr-small"
                          addonBefore="$"
                          style={{ width: '150px' }}
                          disabled
                          precision={2}
                          placeholder={quoteTotal === 0 && '0.00'}
                        />
                      </>
                    )}
                  </Text>
                </Row>
              </div>
            </div>

            <ActionModalFooter>
              <SecondaryButton className="mr-medium" size="large" onClick={this._goToPrevious}>
                Back
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onSave} disabled={!selectedService}>
                Save
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetQuotation: dispatch.customersStore.doGetQuotation,
});

export default connect(null, mapDispatch)(Form.create<IAddSubscriptionQuoteModalProps>()(AddSubscriptionQuoteModal));
