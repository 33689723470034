import React from 'react';
import { useTranslation } from 'react-i18next';

import { Controller, useFormContext } from 'react-hook-form';
import { Box, Text, Inline } from '@goodhuman-me/components';

import { Select } from 'design-components';
import CancelBookingFieldError from './CancelBookingFieldError';
import { Schema, SchemaFieldNames, SchemaOptions } from '../schema';
import { IFieldWithOptions } from '../fields';

import CancelBookingSelectLegacy from './legacy/CancelBookingSelect';

export const USE_LEGACY = true;

interface ICancelBookingSelectProps<T extends SchemaOptions> {
  field: IFieldWithOptions<SchemaFieldNames, string | null, T>;
  onClear?: () => void;
  disabled?: boolean;
  popover?: React.ReactNode;
}

const CancelBookingSelect = <T extends SchemaOptions>({
  field,
  onClear,
  disabled,
  popover,
}: ICancelBookingSelectProps<T>) => {
  const keyPrefix = `bookings.modals.cancelBooking.${field.name}`;
  const { t, i18n } = useTranslation('', { keyPrefix });
  const { control } = useFormContext<Schema>();

  return (
    <>
      {i18n.exists(`${keyPrefix}.label`) && (
        <Box paddingBottom="$xsmall">
          <Inline gap="$xxsmall" alignItems="center">
            <Text fontWeight={700}>{t(`label`)}</Text>
            {popover}
          </Inline>
        </Box>
      )}
      <Controller
        name={field.name}
        defaultValue={field.defaultValue}
        control={control}
        rules={field.rules}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
          <>
            <Select
              onSelectionChange={(value) => {
                onChange(value);
                if (value === undefined) {
                  onClear?.();
                }
              }}
              onBlur={onBlur}
              value={value ?? undefined}
              placeholder={t(`placeholder`)}
              className={`width-full ${error ? 'cancel-booking-select__error' : ''}`}
              aria-label={field.name}
              allowClear
              validationState={error ? 'invalid' : 'valid'}
              notFoundContent={null}
              disabled={disabled}
              paddingY="$xsmall"
            >
              {Object.values(field.options).map((option: string) => (
                <Select.Item value={option ?? undefined} key={option} aria-label={option}>
                  {t(`options.${option}`)}
                </Select.Item>
              ))}
            </Select>
            {error?.message && (
              <Box marginTop="$xxsmall">
                <CancelBookingFieldError message={error?.message} />
              </Box>
            )}
          </>
        )}
      />
    </>
  );
};

export default USE_LEGACY ? CancelBookingSelectLegacy : CancelBookingSelect;
