import React from 'react';
import { Text, Link as LinkComponent } from '@good/components';
import { kebabCase } from 'lodash';
import { CsvImportType } from '../schemas';

export const CsvTemplateDownloadLink = ({ importType }: { importType: CsvImportType }) => {
  const csvImportType = kebabCase(importType);

  return (
    <LinkComponent>
      <Text size='sm'>
        <a download={`${csvImportType}-import-template.csv`} href={`/templates/imports/${csvImportType}.csv`}>
          Download template
        </a>
      </Text>
    </LinkComponent>
  );
};
