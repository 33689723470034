import React from 'react';
import { Pill } from '@good/ui/core';
import { useTranslation } from 'react-i18next';

type SilPillProps = {
  status: string | null;
};
export const SilPill = ({ status }: SilPillProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceCustomersTabs' });

  return status === 'ACTIVE' || status === 'EXPIRING SOON' ? (
    <Pill className='bg-positive-weak text-green-dark-2 font-semibold'>{t('active')}</Pill>
  ) : status === 'EXPIRED' ? (
    <Pill className='font-semibold'>{t('archived')}</Pill>
  ) : (
    <Pill className='bg-blue-light-1 text-blue-dark-2 font-semibold'>{t('pending')}</Pill>
  );
};
