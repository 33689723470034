import React from 'react';
import { Avatar, Group, Text, Table, Anchor } from '@good/ui/core';
import { useTranslation } from 'react-i18next';

export type ITeamMember = {
  firstName: string;
  lastName: string;
  attachmentUrl: string | null;
  supportWorkerId: string;
};

type ITeamMemberRowProps = {
  teamMember: ITeamMember;
  onSelect: (teamMember: ITeamMember) => void;
  state: 'ADD' | 'REMOVE';
  selectedTeamMembers?: ITeamMember[];
};

const TeamMemberButton: React.FC<{
  selectedTeamMembers?: ITeamMember[];
  teamMember: ITeamMember;
  state: string;
  onSelect: (teamMember: ITeamMember) => void;
}> = ({ selectedTeamMembers, teamMember, state, onSelect }) => {
  const { t } = useTranslation('', { keyPrefix: 'silService.serviceTeamTabs.modal' });
  if (selectedTeamMembers?.includes(teamMember) && state === 'ADD') {
    return <Text c='dimmed'> Added </Text>;
  }
  if (state === 'REMOVE') {
    return (
      <Anchor c='red' size='sm' onClick={() => onSelect(teamMember)}>
        {t('remove')}
      </Anchor>
    );
  }

  return (
    <Anchor size='sm' onClick={() => onSelect(teamMember)}>
      {t('heading')}
    </Anchor>
  );
}

export const TeamMemberRow = ({
  teamMember,
  onSelect,
  state,
  selectedTeamMembers,
}: ITeamMemberRowProps): JSX.Element => {
  return (
    <>
      <Table.Tr data-testid='team-member-row'>
        <Table.Td>
          <Group align='center' gap='lg'>
            <Avatar src={teamMember.attachmentUrl} radius='sm' />
            <Text size='sm'>{`${teamMember.firstName} ${teamMember.lastName}`}</Text>
          </Group>
        </Table.Td>
        <Table.Td>
          <TeamMemberButton
            selectedTeamMembers={selectedTeamMembers}
            teamMember={teamMember}
            state={state}
            onSelect={onSelect}
          />
        </Table.Td>
      </Table.Tr>
    </>
  );
};
