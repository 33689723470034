import { CloseCircleFilled } from '@ant-design/icons';
import { Avatar, Col, Icon, notification, Row, Select } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { ICustomer, ISupportCoordinator } from 'interfaces/customer-interfaces';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';

const { Option } = Select;

type IAddSupportCoordinatorModal = IStateProps &
  IDispatchProps & {
    selectedCustomer: ICustomer;
    isOpen: boolean;
    closeModal: () => void;
    supportCoordinatorExisted?: ISupportCoordinator[];
  };

const AddSupportCoordinatorModal = (props: IAddSupportCoordinatorModal) => {
  const {
    isOpen,
    closeModal,
    selectedCustomer,
    supportCoordinators,
    supportCoordinatorExisted = [],
    doAssignSupportCoordinator,
  } = props;

  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [supportCoordinatorsSelected, setSupportCoordinatorsSelected] = useState<ISupportCoordinator[]>([]);
  const [searchSupportCoordinators, setSearchSupportCoordinators] = useState(supportCoordinators);
  const [disableSupportCoordinatorSelect] = useState(supportCoordinators.length === 0);
  const supportCoordinatorSelectedIds = supportCoordinatorsSelected.map(
    (supportCoordinator) => supportCoordinator.supportCoordinatorId,
  );
  const supportCoordinatorExistedIds = supportCoordinatorExisted.map(
    (supportCoordinator) => supportCoordinator.supportCoordinatorId,
  );

  const onHandleSelectChange = (supportCoordinatorIds: string[]) => {
    const newSupportCoordinators = supportCoordinatorIds.map((supportCoordinatorId) =>
      supportCoordinators.find(
        (supportCoordinator) => supportCoordinator.supportCoordinatorId === supportCoordinatorId,
      ),
    );

    setSupportCoordinatorsSelected(newSupportCoordinators);
    setError('');
  };

  const onHandleSearch = (text: string) => {
    const newSupportCoordinators = supportCoordinators.filter((supportCoordinator) =>
      supportCoordinator.fullName.toLowerCase().includes(text.toLowerCase()),
    );

    setSearchSupportCoordinators(newSupportCoordinators);
    if (newSupportCoordinators.length === 0) {
      setError('Invalid input');
    } else {
      setError('');
    }
  };

  const onHandleBlur = () => {
    setError('');
    setSearchSupportCoordinators(supportCoordinators);
  };

  const onHandleCloseModal = () => {
    setSearchSupportCoordinators(supportCoordinators);
    setSupportCoordinatorsSelected([]);
    setLoading(false);
    setError('');
    closeModal();
  };

  const onClickAssign = async () => {
    if (supportCoordinatorsSelected.length > 0) {
      const payload = {
        supportCoordinatorIds: supportCoordinatorSelectedIds,
        customerUserId: selectedCustomer.userId,
      };

      try {
        const message = <b>Support Coordinator assigned</b>;

        setLoading(true);
        await doAssignSupportCoordinator(payload);
        notification.open({
          message,
          description: (
            <p>
              You have successfully assigned{' '}
              <b>{supportCoordinatorsSelected.map((supportCoordinator) => supportCoordinator.fullName).join(', ')}</b>{' '}
              as the support coordinator for this customer.
            </p>
          ),
        });
        onHandleCloseModal();
      } catch {
        setLoading(false);
        notification.error({ message: 'Oops, an error has occurred, please try again.' });
      }
    } else {
      setError('Please select a team member');
    }
  };

  const supportCoordinatorSelectPlaceholderText = disableSupportCoordinatorSelect
    ? 'Please assign team members in Service Roster.'
    : 'Select team member...';

  return (
    <ActionModal title='Assign a support coordinator' isOpen={isOpen} onClose={onHandleCloseModal}>
      <Row className='mt-small'>
        <Text weight='bold'>Team member</Text>
      </Row>
      <Row className='mt-small'>
        <div className='flex p-5'>
          <div className='width-3/4 relative'>
            <Select
              className='width-full custom-item-selected'
              autoClearSearchValue={false}
              mode='multiple'
              placeholder={supportCoordinatorSelectPlaceholderText}
              size='large'
              defaultValue={supportCoordinatorSelectedIds}
              filterOption={false}
              onChange={onHandleSelectChange}
              maxTagCount={2}
              removeIcon={<Icon type='close-circle' theme='filled' className='text-size-regular' />}
              onSearch={onHandleSearch}
              onBlur={onHandleBlur}
              dropdownMenuStyle={{ maxHeight: 215 }}
              notFoundContent={null}
              allowClear
              disabled={disableSupportCoordinatorSelect}
            >
              {searchSupportCoordinators.map((supportCoordinator) => {
                const supportCoordinatorId = supportCoordinator.supportCoordinatorId;
                const isSelected = _.includes(supportCoordinatorSelectedIds, supportCoordinatorId);
                const isAlreadyAssigned = _.includes(supportCoordinatorExistedIds, supportCoordinatorId);

                return (
                  <Option
                    style={{
                      background: isSelected ? 'rgba(0, 131, 255, 0.15)' : undefined,
                    }}
                    className='mb-x-small'
                    value={supportCoordinatorId}
                    key={supportCoordinatorId}
                    disabled={isAlreadyAssigned}
                  >
                    <Avatar
                      className='mr-small'
                      size='small'
                      icon='user'
                      src={supportCoordinator.attachmentUrl}
                      shape='square'
                    />
                    <Text weight='regular' className='mr-small' color={isAlreadyAssigned ? 'secondary' : ''}>
                      {supportCoordinator.fullName} {isAlreadyAssigned ? '(Already assigned)' : ''}
                    </Text>
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
      </Row>

      {error && (
        <Row className='mt-small'>
          <Text size='regular' color='red' className='mt-small'>
            <CloseCircleFilled /> {error}
          </Text>
        </Row>
      )}

      <Row type='flex' className='justify-end mt-x-large'>
        <Col className='mr-large'>
          <GhostButton size='large' onClick={onHandleCloseModal}>
            Cancel
          </GhostButton>
        </Col>
        <Col>
          <PrimaryButton
            size='large'
            onClick={onClickAssign}
            loading={loading}
            disabled={disableSupportCoordinatorSelect}
          >
            Assign as support coordinator
          </PrimaryButton>
        </Col>
      </Row>
    </ActionModal>
  );
};

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: IRootState) => ({
  supportCoordinators: state.customersStore.supportCoordinators,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doAssignSupportCoordinator: dispatch.customersStore.doAssignSupportCoordinator,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSupportCoordinatorModal);
