import { createContext, useContext } from 'react';
import { PortalUser } from 'stores/rematch/models/auth-store';

/**
 * Portal user context, for storing the current portal user.
 */
export const PortalUserContext = createContext<PortalUser | null>(null);

/**
 * Pulls the current portal user from the global app container, using the portal user context.
 */
export const usePortalUser = () => useContext(PortalUserContext);
