import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@good/components';
import EmploymentDetails from './employment-details';
import { SupportWorkerAvailability } from './support-worker-availability';

export type SupportWorker = {
  firstName: string;
  lastName: string;
  employeeNumber: string;
  aboutMe: string;
  dateOfBirth: string;
  startDate: string;
  endDate: string;
  gender: string;
  contactNumbers: {
    numberType: string;
    numberCountryCode: string;
    number: string;
    preferredContact: boolean;
    supportWorkerContactNumberId: string;
  }[];
  addresses: {
    state: string;
    geoLat: number;
    geoLng: number;
    country: string;
    locality: string;
    postcode: string;
    isPrimary: boolean;
    fullAddress: {
      streetAddress1: string;
      streetAddress2: string;
      locality: string;
      state: string;
      postcode: string;
      country: string;
    };
    streetAddress1: string;
    streetAddress2: string;
  }[];
  hobbies: number[];
  qualifications: number[];
  specialities: number[];
  religions: number[];
  languages: number[];
  skills: number[];
  email: string;
  roles: string[];
  roleOther: string;
  supportWorkerId: string;
  employmentType: string;
  employmentTypeOther: string;
  contractedHours?: number;
  contractedHoursUnit?: string;
};

type WorkDetailsProps = {
  hasEditPermission: boolean;
  supportWorker: SupportWorker;
};

export const WorkDetailsPanel = (props: WorkDetailsProps) => {
  const { hasEditPermission = false, supportWorker } = props;
  const { t } = useTranslation('', { keyPrefix: 'teamMember.workDetails' });
  const [isEditingEmploymentDetails, setIsEditingEmploymentDetails] = useState(false);
  return (
    <div className="flex flex-col space-y-8">
      <Text className="text-h-lg font-bold" asChild>
        <h2>{t('heading')}</h2>
      </Text>
      <div className="border-b-2 border-gray-light-2 pb-6">
        <EmploymentDetails
          hasEditPermission={hasEditPermission}
          supportWorker={supportWorker}
          isEditing={isEditingEmploymentDetails}
          setEditing={setIsEditingEmploymentDetails}
        />
      </div>
      {/* hide everything else when employment details is being edited */}
      {!isEditingEmploymentDetails && <SupportWorkerAvailability hasEditPermission={hasEditPermission} />}
    </div>
  );
};
