import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Copy = forwardRef(function Copy(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.25h-.055c-1.367 0-2.47 0-3.337.117-.9.12-1.658.38-2.26.981-.601.602-.86 1.36-.981 2.26-.117.867-.117 1.97-.117 3.337V10.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.982 2.26.601.602 1.36.86 2.26.982.714.096 1.59.112 2.641.115.002 1.048.015 1.914.105 2.62.108.852.34 1.574.88 2.165.074.08.151.157.232.23.59.542 1.313.773 2.164.881.822.105 1.861.105 3.15.105h2.274c1.368 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337v-2.273c0-1.29 0-2.33-.104-3.15-.109-.853-.34-1.575-.881-2.165a3.758 3.758 0 0 0-.231-.232c-.59-.54-1.313-.772-2.165-.88-.706-.09-1.572-.103-2.62-.105-.002-1.052-.02-1.927-.115-2.642-.121-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117H8Zm8.75 7.5v1.305c0 1.367 0 2.47-.116 3.337-.121.9-.38 1.658-.982 2.26-.602.602-1.36.86-2.26.982-.867.116-1.97.116-3.337.116H8.75c.003 1.049.015 1.821.093 2.43.088.696.251 1.07.498 1.34.045.048.09.095.14.139.269.247.643.41 1.34.498.716.092 1.66.093 3.013.093H16c1.436 0 2.437-.002 3.192-.103.734-.099 1.122-.28 1.4-.556.276-.277.456-.665.555-1.4.102-.755.103-1.756.103-3.191v-2.167c0-1.353-.001-2.297-.092-3.013-.089-.696-.252-1.07-.499-1.34a2.288 2.288 0 0 0-.139-.139c-.27-.247-.644-.41-1.34-.498-.608-.078-1.381-.09-2.43-.093ZM3.41 3.41c.276-.277.665-.457 1.398-.556C5.564 2.752 6.565 2.75 8 2.75h2c1.436 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4.102.754.103 1.756.103 3.191v2c0 1.435-.001 2.437-.103 3.192-.099.734-.28 1.122-.556 1.399-.277.277-.665.457-1.399.556-.755.101-1.756.103-3.192.103H8c-1.435 0-2.436-.002-3.192-.103-.733-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191V8c0-1.435.002-2.437.103-3.192.099-.734.28-1.122.556-1.399Z"
        fill="currentColor"
      />
    </Icon>
  );
});
