import { ProgressBar } from '@blueprintjs/core';
import { Col, Icon, notification, Row, Skeleton, Tabs } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import DocumentsPanel from 'common-components/documents/DocumentsPanel';
import { Text } from 'common-components/typography';
import * as H from 'history';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link, Redirect } from 'react-router-dom';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import {
  BookingErrorType,
  BookingStatus,
  BookingType,
  EditRecurringMode,
  ServiceRecurringType,
  ServiceType,
  TimezoneSelectorMode,
} from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import BookingFormsPanel from './sections/content-section/tabs-panel/BookingFormsPanel';
import SleepoverShiftBookingModal from '../components/SleepoverShiftBookingModal';
import ActivityRecordOverviewTab from './sections/ActivityRecordOverviewTab';
import { BookingDetailOverviewTabV2 } from './sections/BookingDetailOverviewTabV2';
import BookingActivityLogPanel from './sections/content-section/tabs-panel/BookingActivityLogPanel';
import BookingBillingPanel from './sections/content-section/tabs-panel/BookingBillingPanel';
import BookingNotesPanel from './sections/content-section/tabs-panel/BookingNotesPanel';

const { TabPane } = Tabs;

interface IBookingUrlParams {
  bookingId: string;
}

interface IBookingDetailsViewV2Props extends RouteComponentProps<IBookingUrlParams, {}, { from?: any; refresh?: any }> {
  doFetchBooking: typeof dispatch.bookingsStore.doFetchSingleBooking;
  doFetchService: typeof dispatch.servicesStore.doFetchSingleService;
  doAddNote: typeof dispatch.bookingsStore.doAddNote;
  doEditNote: typeof dispatch.bookingsStore.doEditNote;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  history: H.History;
  portalUser: typeof state.authStore.portalUser;
  bookingDisplayTzMode: typeof state.bookingsStore.bookingDisplayTzMode;
  bookingDisplayTzCustom: typeof state.bookingsStore.bookingDisplayTzCustom;
  setBookingDocuments: typeof dispatch.bookingsStore.setBookingDocuments;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
  doUpdateSleepOverShift: typeof dispatch.bookingsStore.doUpdateSleepOverShift;
}

class BookingDetailsViewV2 extends Component<IBookingDetailsViewV2Props, any> {
  private _sleepoverViewRef = React.createRef() as any;

  state = {
    isLoading: false,
    activeKey: 'overview',
    bookingDisplayTzCustom: this.props.bookingDisplayTzCustom
      ? this.props.bookingDisplayTzCustom
      : this.props.portalUser.timezone,
    mode: this.props.bookingDisplayTzMode ? this.props.bookingDisplayTzMode : TimezoneSelectorMode.BookingTimezone,
    timezone: this.props.portalUser.timezone,
    isSleepoverShiftBookingModalOpen: false,
  };

  private _getDisplayTimezone = () => {
    if (this.props.bookingDisplayTzMode === TimezoneSelectorMode.BookingTimezone) {
      return null;
    } else if (
      this.props.bookingDisplayTzMode === TimezoneSelectorMode.CustomTimezone &&
      this.props.bookingDisplayTzCustom
    ) {
      return this.props.bookingDisplayTzCustom;
    } else {
      return this.props.portalUser.timezone;
    }
  };

  private _onRefresh = async () => {
    const { match, doFetchBooking, doFetchService } = this.props;
    const { params } = match;
    const selectedBookingId = params.bookingId;
    const selectedBookingItem = await doFetchBooking({ bookingId: selectedBookingId });
    await doFetchService({ serviceId: selectedBookingItem.serviceId });
  };

  async componentDidMount() {
    const { match, doFetchBooking, doFetchService } = this.props;
    const { params } = match;
    const selectedBookingId = params.bookingId;
    this.props.setSelectedSideNavMenuKeys(['/bookings']);
    this.setState({ timezone: this._getDisplayTimezone(), isLoading: true });
    const selectedBookingItem = await doFetchBooking({ bookingId: selectedBookingId });
    await doFetchService({ serviceId: selectedBookingItem.serviceId });
    this.setState({ isLoading: false });
  }

  async componentDidUpdate(prevProps) {
    const { match, doFetchBooking } = this.props;
    const previousMatch = prevProps.match;
    const { state }: { state: any } = this.props.location;

    if ((previousMatch && previousMatch.params.bookingId !== match.params.bookingId) || state?.refresh) {
      this.setState({ isLoading: true });

      // lets reset the state here so that the componentDidUpdate will not loop forever
      if (state?.refresh) {
        this.props.history.replace(this.props.location.pathname, {});
      }
      await doFetchBooking({ bookingId: match.params.bookingId });
      this.setState({ isLoading: false });
    }

    if (
      prevProps &&
      prevProps.selectedBookingItem &&
      (prevProps.selectedBookingItem.startDateTime !== this.props.selectedBookingItem.startDateTime ||
        prevProps.selectedBookingItem.endDateTime !== this.props.selectedBookingItem.endDateTime)
    ) {
      await doFetchBooking({ bookingId: match.params.bookingId });
      this.setState({ isLoading: false });
    }
  }

  _navigateTab = (tabKey) => {
    this.setState({ activeKey: tabKey });
  };

  private _onChangeTimezone = async (type, value) => {
    if (type === 'mode') {
      this.setState({
        mode: value,
        timezone:
          value === TimezoneSelectorMode.MyTimezone
            ? this.props.portalUser.timezone
            : value === TimezoneSelectorMode.BookingTimezone
            ? null
            : this.state.bookingDisplayTzCustom
            ? this.state.bookingDisplayTzCustom
            : this.state.timezone,
      });
    } else if (type === 'customTimezone') {
      this.setState({ timezone: value });
    }
  };

  private _openEditSleepoverModal = () => {
    this.setState({ isSleepoverShiftBookingModalOpen: true });
  };

  private _closeEditSleepoverModal = () => {
    this.setState({ isSleepoverShiftBookingModalOpen: false });
  };

  private _onEditSleepoverShift = async ({
    editRecurringMode,
    sleepoverType,
    sleepoverTimeSlots,
    numberOfBookings,
  }) => {
    const { doUpdateSleepOverShift, selectedBookingItem } = this.props;
    const { bookingId, bookingRequestId, timezone, isRecurring } = selectedBookingItem;
    const isValidBookingStatus: boolean = _.includes(
      [BookingStatus.PENDING, BookingStatus.ACCEPTED, BookingStatus.CONFIRMED],
      selectedBookingItem?.status,
    );

    const payload = {
      bookingId,
      bookingRequestId,
      timezone,
      numberOfBookings,
      isRecurring: isRecurring && isValidBookingStatus,
      editRecurringMode,
      sleepoverType,
      sleepoverTimeSlots: sleepoverTimeSlots,
    };
    try {
      await doUpdateSleepOverShift(payload);

      const description = `You have successfully updated the sleepover details of ${
        editRecurringMode === EditRecurringMode.Current
          ? 'this booking.'
          : editRecurringMode === EditRecurringMode.CurrentAll
          ? 'all bookings in this series.'
          : `this booking and the next ${numberOfBookings} ${numberOfBookings > 1 ? 'bookings' : 'booking'}.`
      }`;

      notification.open({
        message: (
          <Text weight='bolder' color='black' size='x-large'>
            Sleepover updated
          </Text>
        ),
        description,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
      console.log(e);
    }
  };

  render() {
    const { history, selectedBookingItem, doAddNote } = this.props;

    if (selectedBookingItem?.serviceType === ServiceType.INDEPENDENT_LIVING) {
      return <Redirect to='/bookings' />;
    }

    let timezoneData = null;
    if (selectedBookingItem) {
      // TimezoneData for display in subSections
      const displayTimezone = this.state.timezone ? this.state.timezone : selectedBookingItem.timezone;
      timezoneData = {
        displayTimezone,
        isTimezoneIndicatorDisplayed:
          this.state.mode === TimezoneSelectorMode.BookingTimezone &&
          moment.tz(this.props.portalUser.timezone).format('Z') !== moment.tz(displayTimezone).format('Z'),
        bookingTimezone: selectedBookingItem.timezone,
      };
    }

    const from = this.props.location?.state?.from as any;

    const isBookingArchived = selectedBookingItem ? selectedBookingItem.status === BookingStatus.ARCHIVED : false;

    const hasEditBookingNotesPermission = selectedBookingItem
      ? PermissionUtils.validatePermission(
          'EditBookingCaseNote',
          this.props.portalUser.permissions.permissionRoles,
          selectedBookingItem.serviceDepartmentId,
          selectedBookingItem.serviceId,
        ) && selectedBookingItem.status !== BookingStatus.ARCHIVED
      : false;

    const sleepoverHasError =
      selectedBookingItem &&
      selectedBookingItem.bookingErrors.length > 0 &&
      _.some(
        selectedBookingItem.bookingErrors,
        (error) =>
          error.bookingErrorType === BookingErrorType.SleepoverConflictBookingTime ||
          error.bookingErrorType === BookingErrorType.SleepoverConflictCheckinoutTime,
      );

    const isCompletionBooking = selectedBookingItem?.status === BookingStatus.COMPLETED;

    return (
      <>
        {this.state.isSleepoverShiftBookingModalOpen && (
          <SleepoverShiftBookingModal
            isOpen={this.state.isSleepoverShiftBookingModalOpen}
            onClose={this._closeEditSleepoverModal}
            onSave={this._onEditSleepoverShift}
            bookingTimezone={this.state.timezone ? this.state.timezone : selectedBookingItem.timezone}
            recurringType={
              selectedBookingItem.isRecurring ? ServiceRecurringType.RECURRING : ServiceRecurringType.ONEOFF
            }
            bookStartDate={
              isCompletionBooking
                ? selectedBookingItem.portalCheckedInDateTime
                  ? selectedBookingItem.portalCheckedInDateTime
                  : selectedBookingItem.workerCheckedInDateTime
                : selectedBookingItem.startDateTime
            }
            bookEndDate={
              isCompletionBooking
                ? selectedBookingItem.portalCheckedOutDateTime
                  ? selectedBookingItem.portalCheckedOutDateTime
                  : selectedBookingItem.workerCheckedOutDateTime
                : selectedBookingItem.endDateTime
            }
            selectedBookingItem={selectedBookingItem}
            sleepoverTimeSlots={selectedBookingItem.sleepoverTimeSlots}
            sleepoverType={selectedBookingItem.sleepoverType}
            isCreatingBooking={false}
          />
        )}
        {/* Top Actions Panel */}
        {/*<BookingTopActions />*/}
        {/* Booking Nav Menu */}
        {this.state.isLoading && (
          <>
            <div className='item-container'>
              <div className='pb-medium'>
                <Text>Fetching booking data...</Text>
              </div>
              <ProgressBar />
            </div>
            <Skeleton loading={this.state.isLoading} active avatar title paragraph />
          </>
        )}
        {!this.state.isLoading && !_.isEmpty(selectedBookingItem) && (
          <>
            <Row>
              {/* Booking Content */}
              <Col sm={24} md={24}>
                {/* Actual Content */}
                <div className='mb-medium'>
                  {!_.isEmpty(from) ? (
                    <Link to={from.url}>
                      <HyperlinkButton>
                        <Icon type='left' /> {from.linkLabel}
                      </HyperlinkButton>
                    </Link>
                  ) : (
                    <Link to='/bookings'>
                      <HyperlinkButton>
                        <Icon type='left' /> Back to Booking List
                      </HyperlinkButton>
                    </Link>
                  )}
                </div>

                {/*<Tabs></Tabs>*/}
                <Tabs renderTabBar={() => <div />} activeKey={this.state.activeKey} type='card' animated={false}>
                  <TabPane key='overview' tab='overview'>
                    {selectedBookingItem.bookingType === BookingType.BOOKING ? (
                      <BookingDetailOverviewTabV2
                        history={history}
                        selectedBookingItem={selectedBookingItem}
                        navigateTab={this._navigateTab}
                        mode={this.state.mode}
                        timezone={this.state.timezone}
                        userTimezone={this.props.portalUser.timezone}
                        bookingDisplayTzCustom={this.state.bookingDisplayTzCustom}
                        onChangeTimezone={this._onChangeTimezone}
                        isBookingArchived={isBookingArchived}
                        openSleepoverShiftModal={this._openEditSleepoverModal}
                        sleepoverViewRef={this._sleepoverViewRef}
                      />
                    ) : (
                      <ActivityRecordOverviewTab
                        history={history}
                        selectedBookingItem={selectedBookingItem}
                        navigateTab={this._navigateTab}
                        mode={this.state.mode}
                        timezone={this.state.timezone}
                        userTimezone={this.props.portalUser.timezone}
                        bookingDisplayTzCustom={this.state.bookingDisplayTzCustom}
                        onChangeTimezone={this._onChangeTimezone}
                        isBookingArchived={isBookingArchived}
                      />
                    )}
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
            <Row className='mb-x2-large'>
              <Col>
                <Tabs type='card'>
                  <TabPane
                    key='billing'
                    tab='Billing'
                    className='bordered ph-x-large pv-large'
                    style={{ marginTop: '-17px' }}
                  >
                    <BookingBillingPanel
                      bookingItem={selectedBookingItem}
                      showSidebar={false}
                      timezoneData={timezoneData}
                      hasEditBillingLineItemPermission={hasEditBookingNotesPermission}
                      isBookingArchived={isBookingArchived}
                      sleepoverHasError={sleepoverHasError}
                      openSleepoverShiftModal={this._openEditSleepoverModal}
                      sleepoverViewRef={this._sleepoverViewRef}
                    />
                  </TabPane>
                  <TabPane
                    key='notes'
                    tab='Notes'
                    className='bordered ph-x-large pv-large'
                    style={{ marginTop: '-2px' }}
                  >
                    <BookingNotesPanel
                      onAddNote={doAddNote}
                      bookingNotes={selectedBookingItem.notes}
                      onRefresh={this._onRefresh}
                      timezoneData={timezoneData}
                      timezone={selectedBookingItem.timezone}
                      hasEditBookingNotesPermission={hasEditBookingNotesPermission}
                      serviceName={selectedBookingItem.serviceName}
                      bookingStartDateTime={selectedBookingItem.startDateTime}
                      selectedBookingItem={selectedBookingItem}
                      bookingId={this.props.match.params.bookingId}
                      isBookingArchived={isBookingArchived}
                      noteType={
                        selectedBookingItem.bookingType === BookingType.ACTIVITY_RECORD
                          ? BookingType.ACTIVITY_RECORD
                          : BookingType.BOOKING
                      }
                    />
                  </TabPane>
                  <TabPane
                    key='attachment'
                    tab='Attachments'
                    className='bordered ph-x-large pv-large'
                    style={{ marginTop: '-2px' }}
                  >
                    <DocumentsPanel
                      selectedItemId={selectedBookingItem.bookingId}
                      itemType={
                        selectedBookingItem.bookingType === BookingType.ACTIVITY_RECORD ? 'activity_record' : 'booking'
                      }
                      itemDocuments={_.map(selectedBookingItem.bookingDocuments, (document) => {
                        if (document.noteId) {
                          return {
                            ...document,
                            noteDetail: _.find(selectedBookingItem.notes, (note) => note.noteId === document.noteId),
                          };
                        } else {
                          return { ...document };
                        }
                      })}
                      setDocuments={this.props.setBookingDocuments}
                      hasEditPermission={hasEditBookingNotesPermission}
                      isDateAddedDisplayed={false}
                      isExpiryDisplayed={false}
                      isSearchable={false}
                      useAttachmentText={true}
                      selectedBookingItem={selectedBookingItem}
                    />
                  </TabPane>

                  <TabPane
                    key='form'
                    tab='Forms'
                    className='bordered ph-x-large pv-large'
                    style={{ marginTop: '-2px' }}
                  >
                    <BookingFormsPanel timezone={selectedBookingItem.timezone} />
                  </TabPane>

                  <TabPane
                    key='activity-log'
                    tab='Activity Logs'
                    className='bordered ph-x-large pv-large'
                    style={{ marginTop: '-2px' }}
                  >
                    <BookingActivityLogPanel
                      bookingHistory={selectedBookingItem.bookingHistories}
                      onRefresh={this._onRefresh}
                      timezoneData={timezoneData}
                      isActivityRecord={selectedBookingItem.bookingType === BookingType.ACTIVITY_RECORD}
                    />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBookingItem: state.bookingsStore.selectedBookingItem,
  portalUser: state.authStore.portalUser,
  bookingDisplayTzMode: state.bookingsStore.bookingDisplayTzMode,
  bookingDisplayTzCustom: state.bookingsStore.bookingDisplayTzCustom,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchBooking: dispatch.bookingsStore.doFetchSingleBooking,
  doFetchService: dispatch.servicesStore.doFetchSingleService,
  doAddNote: dispatch.bookingsStore.doAddNote,
  doEditNote: dispatch.bookingsStore.doEditNote,
  setBookingDocuments: dispatch.bookingsStore.setBookingDocuments,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
  doUpdateSleepOverShift: dispatch.bookingsStore.doUpdateSleepOverShift,
});

export default connect(mapState, mapDispatch)(BookingDetailsViewV2);
