import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ChevronsUp = forwardRef(function ChevronsUp(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.53 6.47a.75.75 0 0 0-1.06 0l-6 6a.75.75 0 1 0 1.06 1.06L12 8.06l5.47 5.47a.75.75 0 1 0 1.06-1.06l-6-6Zm4 10-4-4a.75.75 0 0 0-1.06 0l-4 4a.75.75 0 1 0 1.06 1.06L12 14.06l3.47 3.47a.75.75 0 1 0 1.06-1.06Z"
        fill="currentColor"
      />
    </Icon>
  );
});
