import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { BookingSVG, ErrorSVG, SuccessSVG } from 'assets/UndrawSVG';
import { Paragraph } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Spinner } from '@blueprintjs/core';

class BookingBillingLineItemWaiveActionModel extends Component<any, any> {
  state = { step: 1, canManuallyClose: true };

  renderView = () => {
    const { step } = this.state;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={BookingSVG} alt="BOOKING" style={{ width: '100%', height: '200px' }} />
          </div>

          <div className="text-align-center">
            <Paragraph>Are you sure you want to waive this line item ?</Paragraph>
          </div>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal}>
              Not Now
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this.onAccept}>
              Waive
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Waiving this line Item, won't be long...</Paragraph>
          </div>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            {/*<Undraw name={'confirmed'} primaryColor={Colors.LIGHT_GRAY3} style={{ height: '200px' }} />*/}
            <img src={SuccessSVG} alt="SUCCESS" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Line Item successfully waived.</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              OK
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Something went wrong, Please try again</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Go Back to Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  onAccept = async () => {
    const { onAction } = this.props;
    this.setState({ step: 2, canManuallyClose: false });
    try {
      await onAction();
      this.onComplete();
    } catch (e) {
      this.onError();
    }
  };

  onComplete = () => {
    this.setState({ step: 3, canManuallyClose: true });
  };

  onError = () => {
    this.setState({ step: 4, canManuallyClose: true });
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    // Reset back to step 1
    this.setState({ step: 1 }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title="Waive Line Item"
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>
          <div style={{ minHeight: '300px' }} className="flex-column justify-center">
            {this.renderView()}
          </div>
        </div>
      </ActionModal>
    );
  }
}

export default BookingBillingLineItemWaiveActionModel;
