import { Form, Input, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle } from 'common-components/typography';
import { IWorkflow } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';
import { IWorkflowStep } from '../../../../../../interfaces/workflow-interfaces';

interface Props extends FormComponentProps {
  selectedWorkflow?: IWorkflow;
  activeStep: IWorkflowStep | null;
  loading: boolean;
  isOpen: boolean;
  onClose(): void;
  onSendBack(values: any): void;
}

class SendBackModal extends PureComponent<Props> {
  private _onSendBack = async () => {
    const { form, selectedWorkflow, onSendBack, activeStep } = this.props;

    form.validateFields((errors, values) => {
      if (!errors) {
        onSendBack({
          ...values,
          workflowId: selectedWorkflow.workflowId,
          sendBackFromStep: activeStep.workflowStepId,
          workflowIssueId: selectedWorkflow.issue ? selectedWorkflow.issue.workFlowIssueId : undefined,
        });
      }
    });
  };

  render() {
    const { selectedWorkflow, activeStep, isOpen, loading, onClose, form } = this.props;

    const { getFieldDecorator } = form;

    const stepsOption = activeStep
      ? selectedWorkflow.steps.filter((one) => one.stepNumber < activeStep.stepNumber)
      : [];

    return (
      <ActionModal width="medium" isOpen={isOpen} onClose={onClose} title="Send back" verticalAlignment="center">
        <Paragraph>Please select which step you would like to send this workflow back to.</Paragraph>

        <SubTitle>STEP</SubTitle>
        <Form.Item>
          {getFieldDecorator('sendBackToStep', {
            rules: [{ required: true, message: 'Please select a step' }],
          })(
            <Select placeholder="Select a step" className="width-full">
              {stepsOption.reverse().map((step) => (
                <Select.Option key={step.workflowStepId} value={step.workflowStepId}>
                  Step {step.stepNumber}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>

        <SubTitle>REASON</SubTitle>
        <Form.Item className="mv-none">
          {getFieldDecorator('message', {
            initialValue: '',
            rules: [
              { required: true, whitespace: true, message: 'Please enter a reason' },
              { max: 2500, message: 'Please enter less than 2500 characters.' },
            ],
          })(<Input.TextArea placeholder="Enter the reason for sending this step back" autoSize={{ minRows: 5 }} />)}
        </Form.Item>

        <Row type="flex" justify="end" className="mt-x2-large">
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>

          <PrimaryButton size="large" loading={loading} onClick={this._onSendBack}>
            Send back
          </PrimaryButton>
        </Row>
      </ActionModal>
    );
  }
}

export default Form.create<Props>()(SendBackModal);
