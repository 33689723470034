import React from 'react';
import { Header, Heading, Text, Label , Card } from '@good/components';
import { Avatar } from 'antd';
import { useTranslation } from 'react-i18next';

export const CustomerDetailsCard = (props: {
  attachmentUrl: string;
  displayName: string;
  ndisNumber: string;
  address: string;
}) => {
  const { t } = useTranslation('', { keyPrefix: 'serviceAgreements.service.customerDetailsSummary' });

  return (
    <Card className="w-2/3 border p-8" displayBoxShadow>
      <Header size="sm">
        <Text className="font-semibold">{t('header')}</Text>
      </Header>
      <div className="mt-7 grid grid-cols-6 gap-8">
        <div className="col-span-2 lg:col-span-1" style={{ margin: 'auto' }}>
          <Avatar size={70} icon="user" shape="circle" src={props.attachmentUrl} />
        </div>
        <div className="col-span-4 grid gap-2 lg:col-span-5">
          <Header size="md">
            <Heading>{props.displayName}</Heading>
          </Header>
          <Text size="md" className="flex space-x-2">
            <span>{t('ndisNumber')}</span>
            <Label size="sm" tone="neutral" emphasis="outline">
              {props.ndisNumber}
            </Label>
          </Text>
          <Text size="md" className="text-[#706F6D]">
            {props.address}
          </Text>
        </div>
      </div>
    </Card>
  );
};
