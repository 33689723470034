import { ICustomerBookingAttachments } from 'interfaces/customer-interfaces';
import React, { Component } from 'react';
import * as H from 'history';
import { Text } from '../../../../../common-components/typography';
import { GhostButton, HyperlinkButton } from '../../../../../common-components/buttons';
import { Avatar, Icon } from 'antd';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import TextTag from '../../../../../common-components/tags/TextTag';
import moment from 'moment-timezone';
import { NoteVisibleType, ServiceType } from '../../../../../utilities/enum-utils';
import { Menu, MenuItem } from '@blueprintjs/core';
import { downloadFileFromUrl } from 'utilities/file-utils';

interface ICustomerAttachmentItemProps {
  attachment: ICustomerBookingAttachments;
  history: H.History;
  onEditAttachment: (attachment) => void;
  onDeleteAttachment: (attachment) => void;
  onEditNote: (attachment) => void;
  onDeleteNote: (attachment) => void;
}

interface ICustomerAttachmentItemState {
  readMore: boolean;
  hasTextOverflown: boolean;
  textOffsetHeight: number;
}

class CustomerAttachmentItem extends Component<ICustomerAttachmentItemProps, ICustomerAttachmentItemState> {
  // Using a reference to this element in a few places involving the rendering of notes and overflowing text.
  private rawHttpSelfReference = null;

  state = {
    readMore: false,
    hasTextOverflown: true,
    textOffsetHeight: 0,
  };

  // Checks for if the note body text has overflown, to then hide it behind a 'view more' option.
  private _checkOverflowingNoteText() {
    const reference = this.rawHttpSelfReference;

    // If this is the first time running (there is no state yet), then set the state to the default offset.
    const offset = this.state?.textOffsetHeight ?? reference.offsetHeight;

    const hasTextOverflown = offset < reference.scrollHeight || reference.offsetWidth < reference.scrollWidth;
    this.setState({ hasTextOverflown, textOffsetHeight: offset });
  }

  componentDidMount() {
    this.rawHttpSelfReference = this;
    this._checkOverflowingNoteText();
  }

  componentDidUpdate(prevProps) {
    // If the note body has changed since we last updated, then double-check for overflowing note text.
    if (prevProps.attachment.noteBody !== this.props.attachment.noteBody) {
      this._checkOverflowingNoteText();
      this.setState({ readMore: false });
    }
  }

  private _goToBooking() {
    const { attachment, history } = this.props;
    // If this is a note attachment, then redirect to that notes' attendance.
    // Other-wise redirect to the booking attendance.
    const attendance = attachment.noteId ? attachment.noteAttendanceId : attachment.attendanceId;

    // Check if this is a group service, and redirect to that instead.
    if (attachment.serviceType === ServiceType.GROUP) {
      history.push(`/group-service/${attachment.serviceId}/session/details/${attachment.serviceDateTimeId}`, {
        bookingId: attendance,
      });
      return;
    }

    history.push(`/bookings/details/${attendance}`);
  }

  render() {
    const { attachment, onEditNote, onEditAttachment, onDeleteNote, onDeleteAttachment } = this.props;
    const isNote = attachment.noteId;
    const attachmentType = isNote ? 'note' : 'attachment';

    const onClickEdit = isNote ? onEditNote : onEditAttachment;
    const onClickDelete = isNote ? onDeleteNote : onDeleteAttachment;

    return (
      <div className='bordered ph-medium mb-medium rounded-big bg-white'>
        <div className='pb-medium mt-medium flex-row'>
          {/* Attachment name and status. */}
          <div style={{ minWidth: '25%' }} className='flex-row align-start'>
            <div className='mb-small' key={`${attachment.documentId}-item`}>
              <span title={attachment.documentName}>
                <Text
                  color={
                    attachment.status === 'SCANNING' ? 'secondary' : attachment.status === 'SCANNED' ? 'blue' : 'red'
                  }
                >
                  {attachment.status === 'SCANNED' ? (
                    <HyperlinkButton
                      onClick={() =>
                        downloadFileFromUrl({
                          documentUrl: attachment.documentUrl,
                          documentName: attachment.documentName,
                        })
                      }
                    >
                      <Icon type='download' className='mr-x-small text-color-blue' />
                      {/*
                      TODO: This (and other parts of this component which use text-overflow: ellipsis)
                      TODO: Need to be replaced with the design system's <Text /> component.
                      TODO: It isn't like this as of writing, since the design system version in the workspace doesn't
                      TODO: support overflowing text. If you are a future reader, please fix this!
                      */}
                      <div
                        style={{
                          width: '15rem',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {attachment.documentName}
                      </div>
                    </HyperlinkButton>
                  ) : (
                    <>
                      <Tooltip2
                        content={
                          attachment.status === 'SCANNING'
                            ? 'This document is being scanned.'
                            : 'This document failed to upload.'
                        }
                      >
                        <Icon
                          type='question-circle'
                          className={`text-size-x-large mr-x-small text-color-${
                            attachment.status === 'SCANNING' ? 'blue' : 'red'
                          }`}
                        />
                      </Tooltip2>
                      <div
                        style={{
                          maxWidth: '15rem',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {attachment.documentName}
                      </div>
                    </>
                  )}
                </Text>
              </span>
            </div>
          </div>

          {/* Additional callouts, statuses, service name, booking date/time. */}
          <div className='ml-x2-large width-full rounded'>
            <div className='mb-medium flex-row'>
              <div>
                <TextTag
                  rounded={true}
                  color='tertiary'
                  content={
                    <>
                      <Icon type='calendar' className='mr-x-small' />
                      <Text size='regular' weight='bold' className='mr-small'>
                        {attachment.serviceName}
                      </Text>
                      <Text size='regular' weight='regular' className='mr-small'>
                        {`@ ${moment
                          .tz(attachment.bookingStartDateTime, attachment.bookingTimezone)
                          .format('DD MMM YYYY, hh:mma')}`}
                      </Text>
                    </>
                  }
                />
              </div>

              {/* If linked to a note, render out a status indicating that. */}
              {isNote && (
                <div className='ml-small'>
                  <TextTag
                    rounded={true}
                    color='blue-lightest'
                    content={
                      <div>
                        <Text size='regular' weight='bold'>
                          Linked to note
                        </Text>
                      </div>
                    }
                  />
                </div>
              )}

              {/* Additional status for if the attachment is restricted. */}
              {attachment.visibleType === NoteVisibleType.RESTRICTED_TO_SERVICE && (
                <div className='ml-small'>
                  <TextTag
                    rounded={true}
                    color='blue-lightest'
                    content={
                      <div>
                        <Icon theme='twoTone' twoToneColor='black' type='lock' className='mr-x-small' />
                        <Text size='regular' weight='bold'>
                          Restricted to service
                        </Text>
                      </div>
                    }
                  />
                </div>
              )}
              {attachment.visibleType === NoteVisibleType.PRIVATE_PORTAL_ONLY && (
                <div className='ml-small'>
                  <TextTag
                    rounded={true}
                    color='blue-lightest'
                    content={
                      <div>
                        <Icon theme='twoTone' twoToneColor='black' type='lock' className='mr-x-small' />
                        <Text size='regular' weight='bold'>
                          Private to author
                        </Text>
                      </div>
                    }
                  />
                </div>
              )}
            </div>

            {/* If attached to a note, render out the note text. If not, render out the attachment description. */}
            {isNote ? (
              // Note description
              <div className='mt-large bg-tertiary rounded-big'>
                <div className='p-medium'>
                  <Text size='small' color='secondary' lineHeight={100} className='mb-small'>
                    Notes
                  </Text>
                  <br />
                  <div className='mb-small'>
                    <div
                      ref={(ref) => (this.rawHttpSelfReference = ref)}
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'pre-line',
                        height: this.state.readMore || !this.state.hasTextOverflown ? null : '3.6em',
                      }}
                      className='text-size-large line-height-120'
                    >
                      {attachment.noteBody}
                    </div>
                    {this.state.hasTextOverflown && (
                      <div
                        style={{ color: '#0083FF' }}
                        className='cursor-pointer mt-small'
                        onClick={() => this.setState({ readMore: !this.state.readMore })}
                      >
                        {this.state.readMore ? 'Read less' : 'Read more'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              // Attachment description. We can't render out attachment descriptions on things attached to notes,
              // since for some ungodly reason the description on all note attachments reads '<Size> Bytes'. Don't ask.
              <div
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'pre-line',
                }}
                className='text-size-large line-height-120'
              >
                {attachment.description}
              </div>
            )}

            {/* Note authors and updaters. */}
            <div className='mt-medium'>
              {/* Note updater first. */}
              {attachment.updatedByWorkerFirstName && (
                <div className=' flex-row align-center'>
                  <Avatar src={attachment.updatedByUserImage} alt='avatar' size={15} shape='square' />
                  <Text size='regular' color='secondary' className='mh-small'>
                    Edited By &nbsp;
                    <b>
                      {attachment.updatedByWorkerFirstName} {attachment.updatedByWorkerLastName}
                    </b>
                    &nbsp; on &nbsp;
                    <b>{moment.tz(attachment.updatedOn, attachment.bookingTimezone).format('DD MMM YYYY, hh:mm A')}</b>
                  </Text>
                </div>
              )}

              {/* Note author next. */}
              <div className='flex-row align-center'>
                <Avatar src={attachment.authorImage} alt='avatar' size={15} shape='square' />
                <Text size='regular' color='secondary' className='mh-small'>
                  Added By &nbsp;
                  <b>
                    {attachment.firstName} {attachment.lastName}
                  </b>
                  &nbsp; on &nbsp;
                  <b>{moment.tz(attachment.createdOn, attachment.bookingTimezone).format('DD MMM YYYY, hh:mm A')}</b>
                </Text>
              </div>
            </div>
          </div>
          {/* Finally, the 3-button dropdown menu. */}
          <div className='flex' style={{ flex: 'auto' }}>
            <div>
              {attachment.bookingStatus !== 'ARCHIVED' && (
                <Popover2
                  content={
                    <Menu>
                      <MenuItem
                        className='hover-bg-blue-lightest mv-medium'
                        text='Go to related booking'
                        onClick={() => this._goToBooking()}
                      />
                      <MenuItem
                        className='hover-bg-blue-lightest mv-medium'
                        text={`Edit ${attachmentType}`}
                        onClick={onClickEdit}
                      />
                      <MenuItem
                        className='hover-bg-blue-lightest text-color-red-dark mv-medium'
                        text={`Delete ${attachmentType}`}
                        onClick={onClickDelete}
                      />
                    </Menu>
                  }
                  popoverClassName='mb-medium'
                  position='bottom-left'
                  interactionKind='click'
                >
                  <GhostButton icon='ellipsis' />
                </Popover2>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerAttachmentItem;
