import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox } from 'design-components';
import { colorValues } from 'design-components/theme/tokens';
import CancelBookingFieldError from './CancelBookingFieldError';
import { Schema, SchemaFieldNames } from '../schema';
import { IField } from '../fields';

interface ICancelBookingCheckboxProps {
  field: IField<SchemaFieldNames, boolean>;
  label?: string;
}

const CancelBookingCheckbox = ({ field, label }: ICancelBookingCheckboxProps) => {
  const { control } = useFormContext<Schema>();

  return (
    <>
      <Controller
        name={field.name}
        defaultValue={field.defaultValue}
        control={control}
        rules={field.rules}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
          <>
            <Checkbox
              onChange={onChange}
              onBlur={onBlur}
              isSelected={Boolean(value)}
              aria-label={field.name}
              style={error ? { borderColor: colorValues.redDark1 } : {}}
              ref={ref}
            >
              {label}
            </Checkbox>
            <CancelBookingFieldError message={error?.message} />
          </>
        )}
      />
    </>
  );
};

export default CancelBookingCheckbox;
