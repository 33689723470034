import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Wallet = forwardRef(function Wallet(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.095 3.817c-.483-.065-1.13-.067-2.095-.067H4a1.25 1.25 0 0 0 0 2.5h13.248c-.004-.57-.018-1-.064-1.345-.063-.461-.17-.659-.3-.789-.13-.13-.328-.237-.79-.3ZM4 2.25A2.75 2.75 0 0 0 1.25 5v10.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116h8.11c1.368 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.086-.645.108-1.42.114-2.337A.757.757 0 0 0 22.75 16v-4c0-.018 0-.037-.002-.054-.006-.918-.028-1.693-.114-2.338-.121-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981a10.217 10.217 0 0 0-.643-.064c-.004-.618-.019-1.151-.079-1.597-.084-.628-.27-1.195-.725-1.65-.456-.456-1.023-.642-1.65-.726-.595-.08-1.344-.08-2.243-.08H4Zm17.239 9a13.225 13.225 0 0 0-.092-1.442c-.099-.734-.28-1.122-.556-1.399-.277-.277-.665-.457-1.399-.556-.755-.101-1.756-.103-3.192-.103H4c-.45 0-.875-.108-1.25-.3V15c0 1.435.002 2.436.103 3.192.099.734.28 1.122.556 1.399.277.277.665.457 1.4.556.755.101 1.756.103 3.191.103h8c1.436 0 2.437-.002 3.192-.103.734-.099 1.122-.28 1.4-.556.276-.277.456-.665.555-1.4.054-.4.08-.872.092-1.441H20a2.75 2.75 0 1 1 0-5.5h1.239ZM21.25 15v-2.25H20a1.25 1.25 0 0 0 0 2.5h1.25V15Z"
        fill="currentColor"
      />
    </Icon>
  );
});
