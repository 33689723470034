import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Avatar, Col, Row, Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { SubTitle, Text } from 'common-components/typography';
import ActionModal from 'common-components/modal/ActionModal';
import { Stack } from 'common-components/stack';
import TimeInput from 'common-components/time-input/TimeInput';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ICustomer } from 'interfaces/customer-interfaces';
import {
  IGroupServiceSchedule,
  IGroupServiceScheduleTimeSlot,
  IGroupServiceCustomerRatio,
  IGroupServiceCustomRatio,
} from 'interfaces/service-interfaces';
import CommonUtils from 'utilities/common-utils';

import EditCustomerRatioPanel from './EditCustomerRatioPanel';
import { EditRatios, IEditRatiosSubscribe } from 'views/group-services/components/edit-ratios';

const StyledTimeInput = styled(TimeInput)`
  justify-content: center !important;
  i {
    margin-left: auto !important;
  }
`;

interface IEditScheduleDetailModalProps extends FormComponentProps {
  isModalOpen: boolean;
  selectedCustomer: ICustomer;
  selectedSchedule: IGroupServiceSchedule;
  selectedScheduleTimeSlot: IGroupServiceScheduleTimeSlot;
  defaultRatioValue: string;
  timezone: string;
  onCloseViewModal: () => void;
  onSubmit: (data: { timeSlot: IGroupServiceScheduleTimeSlot; customerRatio: IGroupServiceCustomerRatio }) => void;
}

interface BookingDateTime {
  startDateTime: moment.Moment | Date;
  endDateTime: moment.Moment | Date;
  timezone: string;
}

function EditScheduleDetailModal(props: IEditScheduleDetailModalProps) {
  const {
    isModalOpen,
    selectedCustomer,
    selectedSchedule,
    selectedScheduleTimeSlot,
    defaultRatioValue,
    timezone,
    onCloseViewModal,
    onSubmit,
  } = props;
  const { firstName, lastName, attachmentUrl } = selectedCustomer;
  const { pinc1004NonTimeBoundRatios } = useFlags();

  const [ratiosForm, setRatiosForm] = useState<IEditRatiosSubscribe>(null);
  const customerRatioPanelRef = useRef(null);
  const [customerRatioPanelState, setCustomerRatioPanelState] = useState('');
  const [currentCustomerRatio, setCurrentCustomerRatio] = useState<IGroupServiceCustomerRatio>(
    selectedScheduleTimeSlot.customerRatio || {},
  );
  const [updatedCustomRatio, setUpdatedCustomRatio] = useState<IGroupServiceCustomRatio[]>(
    selectedScheduleTimeSlot.customerRatio?.customRatio || [],
  );
  const [bookingDateTime, setBookingDateTime] = useState<BookingDateTime>({
    startDateTime: selectedScheduleTimeSlot.startDateTime,
    endDateTime: selectedScheduleTimeSlot.endDateTime,
    timezone,
  });
  const { startDateTime, endDateTime, errors } = currentCustomerRatio;

  const onChangeBookingTime = useCallback(
    (value, fieldName: 'bookingStartTime' | 'bookingEndTime') => {
      const roundedDate = CommonUtils.formatCeilingDateTime(value);
      const bookingDateTime_ = {
        startDateTime: fieldName === 'bookingStartTime' ? roundedDate : startDateTime,
        endDateTime: fieldName === 'bookingEndTime' ? roundedDate : endDateTime,
      };

      if (fieldName === 'bookingStartTime') {
        const dateDifference = roundedDate.diff(startDateTime, 'minutes');
        if (bookingDateTime_.endDateTime) {
          bookingDateTime_.endDateTime = moment(bookingDateTime_.endDateTime).add(dateDifference, 'minutes');
        }
      }

      setBookingDateTime((prev) => ({ ...prev, ...bookingDateTime_ }));

      const isErrorEndBeforeStartTime = !moment
        .tz(bookingDateTime_.startDateTime, timezone)
        .isBefore(moment.tz(bookingDateTime_.endDateTime, timezone));
      setCurrentCustomerRatio({
        ...currentCustomerRatio,
        ...bookingDateTime_,
        errors: {
          isStartAfterEndTime: isErrorEndBeforeStartTime,
        },
      });
    },
    [currentCustomerRatio, startDateTime, endDateTime, timezone],
  );

  const validateErrors = useCallback(() => {
    const isErrorEndBeforeStartTime = !moment.tz(startDateTime, timezone).isBefore(moment.tz(endDateTime, timezone));

    const isCustomRatioTimeErrors = customerRatioPanelRef.current?.validateCustomRatios(updatedCustomRatio);
    return isErrorEndBeforeStartTime || isCustomRatioTimeErrors;
  }, [startDateTime, endDateTime, timezone, updatedCustomRatio, customerRatioPanelRef]);

  const submitForm = useCallback(() => {
    let data: { timeSlot: IGroupServiceScheduleTimeSlot; customerRatio: IGroupServiceCustomerRatio };

    if (pinc1004NonTimeBoundRatios) {
      const customerRatio = ratiosForm.getRatio();
      data = {
        timeSlot: selectedScheduleTimeSlot,
        customerRatio: {
          ...customerRatio,
          startDateTime,
          endDateTime,
        },
      };
    } else {
      if (!validateErrors()) {
        const { customerRatio } = selectedScheduleTimeSlot;
        const updatedCustomerRatio = customerRatioPanelRef.current.getCustomerRatio();
        data = {
          timeSlot: selectedScheduleTimeSlot,
          customerRatio: {
            ...customerRatio,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            isCustomRatio: updatedCustomerRatio.isCustomRatio,
            customRatio: updatedCustomerRatio.customRatio,
          },
        };
      } else {
        setCustomerRatioPanelState(uuidv4());
        return;
      }
    }

    onSubmit(data);
    onCloseViewModal();
  }, [
    selectedScheduleTimeSlot,
    startDateTime,
    endDateTime,
    validateErrors,
    onSubmit,
    onCloseViewModal,
    pinc1004NonTimeBoundRatios,
    ratiosForm,
  ]);

  const timeSlotStartDateTime = useMemo(
    () => moment.tz(selectedScheduleTimeSlot.startDateTime, timezone),
    [selectedScheduleTimeSlot.startDateTime, timezone],
  );
  const timeSlotEndDateTime = useMemo(
    () => moment.tz(selectedScheduleTimeSlot.endDateTime, timezone),
    [selectedScheduleTimeSlot.endDateTime, timezone],
  );

  return (
    <ActionModal title="Edit booking time" isOpen={isModalOpen} onClose={onCloseViewModal}>
      <Form>
        <Stack gap="xl">
          <div className="bg-tertiary pv-12 ph-medium rounded">
            <Row>
              <Col span={8}>
                <Text className="text-uppercase mb-small block" weight="bolder" color="secondary" size="small">
                  SELECTED CUSTOMER
                </Text>
                <Avatar size={32} src={attachmentUrl} shape="circle" />{' '}
                <Text className="ml-small" color="primary">
                  {firstName + ' ' + lastName}
                </Text>
              </Col>
              <Col span={10}>
                <Text className="text-uppercase mb-small block" weight="bolder" color="secondary" size="small">
                  SCHEDULE NAME
                </Text>
                <Text>{selectedSchedule.scheduleName}</Text>
              </Col>
              <Col span={6}>
                <Text className="text-uppercase mb-small block" weight="bolder" color="secondary" size="small">
                  SCHEDULE DETAILS
                </Text>
                <Text className="block">
                  {CommonUtils.getRecurringPatternLabel(selectedScheduleTimeSlot.recurringPattern)} -{' '}
                  {timeSlotStartDateTime.format('dddd')}
                </Text>
                <Text color="secondary">
                  {timeSlotStartDateTime.format('hh:mm A')} - {timeSlotEndDateTime.format('hh:mm A')}
                </Text>
              </Col>
            </Row>
          </div>

          <div className="row">
            <Text className="mb-small block" weight="bolder" color="secondary">
              Booking time
            </Text>
            <div className="rounded-big bordered border-secondary">
              <div className="mv-large mh-x-large">
                <div className="flex-row ">
                  <div className="width-half mr-large">
                    <SubTitle>Start time</SubTitle>
                    <StyledTimeInput
                      size="large"
                      className={`mr-small width-full ${errors?.isStartAfterEndTime && 'border-red-dark'}`}
                      value={moment.tz(startDateTime, timezone)}
                      onChange={(value) => onChangeBookingTime(value, 'bookingStartTime')}
                    />
                  </div>
                  <div className="width-half">
                    <SubTitle>End time</SubTitle>
                    <StyledTimeInput
                      size="large"
                      className={`mr-small width-full ${errors?.isStartAfterEndTime && 'border-red-dark'}`}
                      value={moment.tz(endDateTime, timezone)}
                      onChange={(value) => onChangeBookingTime(value, 'bookingEndTime')}
                    />
                  </div>
                </div>
                {errors?.isStartAfterEndTime && <Text color="red">Start time cannot be after end time</Text>}
              </div>
            </div>
          </div>
          <div className="row">
            {pinc1004NonTimeBoundRatios ? (
              <EditRatios
                type="create"
                subscribe={(ratioForm) => setRatiosForm(ratioForm)}
                sessionTimes={bookingDateTime}
                initialRatio={currentCustomerRatio}
              />
            ) : (
              <EditCustomerRatioPanel
                ref={customerRatioPanelRef}
                customerRatio={currentCustomerRatio}
                defaultRatioValue={defaultRatioValue}
                timezone={timezone}
                onChange={(data: IGroupServiceCustomRatio[]) => {
                  setUpdatedCustomRatio(data);
                  setCustomerRatioPanelState(uuidv4());
                }}
              />
            )}
          </div>
          <ActionModalFooter className="flex-row">
            <div className="flex flex-grow"></div>
            <SecondaryButton color="secondary" size="large" onClick={onCloseViewModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              className="ml-medium"
              size="large"
              onClick={submitForm}
              disabled={(customerRatioPanelState && validateErrors()) || ratiosForm?.hasValidationError}
            >
              Save changes
            </PrimaryButton>
          </ActionModalFooter>
        </Stack>
      </Form>
    </ActionModal>
  );
}

export default Form.create<IEditScheduleDetailModalProps>()(EditScheduleDetailModal);
