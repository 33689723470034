import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const ListOrdered = forwardRef(function ListOrdered(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.854 4.339A.75.75 0 0 1 6.25 5v4.25H7a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1 0-1.5h.75V6.401l-.334.223a.75.75 0 0 1-.832-1.248l1.5-1a.75.75 0 0 1 .77-.037ZM11 4.25a.75.75 0 0 0 0 1.5h10a.75.75 0 1 0 0-1.5H11ZM10.25 12a.75.75 0 0 1 .75-.75h10a.75.75 0 1 1 0 1.5H11a.75.75 0 0 1-.75-.75Zm0 7a.75.75 0 0 1 .75-.75h10a.75.75 0 1 1 0 1.5H11a.75.75 0 0 1-.75-.75ZM4 13.25a.75.75 0 0 0 0 1.5h2.25v1H4a.75.75 0 0 0-.75.75V19c0 .414.336.75.75.75h3a.75.75 0 0 0 0-1.5H4.75v-1H7a.75.75 0 0 0 .75-.75V14a.75.75 0 0 0-.75-.75H4Z"
        fill="currentColor"
      />
    </Icon>
  );
});
