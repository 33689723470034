import type { CSS } from '../theme';

export const reset: CSS = {
  boxSizing: 'border-box',
  position: 'relative',
  margin: 0,
  padding: 0,
};

export const inline: CSS = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
};
