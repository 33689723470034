import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const VolumeLow = forwardRef(function VolumeLow(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.04 6.724c.755-.906 1.27-1.522 1.675-1.893.181-.165.295-.24.359-.272.028.065.067.195.1.439.074.544.076 1.347.076 2.526v8.952c0 1.179-.002 1.982-.076 2.527a1.793 1.793 0 0 1-.1.438 1.794 1.794 0 0 1-.359-.272c-.405-.371-.92-.987-1.675-1.893l-.264-.317-.088-.105c-.502-.604-.9-1.083-1.458-1.344-.558-.262-1.18-.26-1.967-.26H9c-.964 0-1.612-.002-2.095-.066-.461-.063-.659-.17-.789-.3-.13-.13-.237-.328-.3-.79-.064-.482-.066-1.13-.066-2.094s.002-1.612.067-2.095c.062-.461.169-.659.3-.789.13-.13.327-.237.788-.3C7.388 8.753 8.036 8.75 9 8.75h.263c.786.001 1.409.002 1.967-.26.557-.26.956-.74 1.458-1.344l.088-.105.264-.317Zm2.1-2.19s-.004.002-.011.002l.01-.002Zm-.094-.028-.007-.008s.003.001.007.008Zm-.007 14.996.007-.008c-.004.007-.007.009-.007.008Zm.09-.038c.007 0 .01.002.01.002l-.01-.002Zm.467-16.347c-.774-.28-1.434.187-1.893.608-.49.448-1.07 1.145-1.776 1.991l-.04.048-.264.317c-.634.761-.813.95-1.03 1.051-.216.101-.475.118-1.466.118h-.179c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725-.456.456-.642 1.023-.726 1.65-.08.595-.08 1.345-.08 2.243v.104c0 .899 0 1.648.08 2.242.084.628.27 1.195.726 1.65.455.456 1.022.642 1.65.726.594.08 1.344.08 2.242.08h.179c.99 0 1.25.017 1.466.118.217.102.396.29 1.03 1.051l.264.317.04.048c.706.847 1.286 1.543 1.776 1.991.46.421 1.12.888 1.893.608.773-.28.98-1.061 1.065-1.679.09-.657.09-1.564.09-2.666V7.462c0-1.102 0-2.009-.09-2.666-.084-.618-.292-1.4-1.065-1.68Zm3.82 6.26a.75.75 0 0 0-.832 1.247c.372.248.666.75.666 1.376 0 .626-.294 1.128-.666 1.376a.75.75 0 0 0 .833 1.248c.823-.55 1.333-1.54 1.333-2.624 0-1.083-.51-2.074-1.333-2.624Z"
        fill="currentColor"
      />
    </Icon>
  );
});
