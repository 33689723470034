import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import FullScreenModal, { FullScreenModalFooter } from 'common-components/modal/FullScreenModal';
import Steps from 'common-components/steps/Steps';
import { ICustomer } from 'interfaces/customer-interfaces';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import FundGeneralInformationPanel from './FundGeneralInformationPanel';
import FundKeyContactsPanel from './FundKeyContactsPanel';
import FundGoalsPanel from './FundGoalsPanel';
import FundSupportCategoryPanel from './FundSupportCategoryPanel';
import FundSummaryPanel from './FundSummaryPanel';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';

interface ICreateNewPackageModalProps {
  selectedCustomer: ICustomer;
  isCreatePackageModalOpen: typeof state.customersStore.selectedCustomerState.isCreatePackageModalOpen;
  doSetFundingData: typeof dispatch.customersStore.doSetFundingData;
  setIsCreatePackageModalOpen: typeof dispatch.customersStore.setIsCreatePackageModalOpen;
}

interface ICreateNewPackageModalState {
  step: number;
  totalStep: number;
}

class CreateNewPackageModal extends Component<ICreateNewPackageModalProps, ICreateNewPackageModalState> {
  state = {
    step: 1,
    totalStep: 4,
  };

  private _onNextStep = async (stepData) => {
    await this.props.doSetFundingData(stepData);
    this.setState({ step: this.state.step + 1 });
  };

  private _onPreviousStep = async (stepData) => {
    await this.props.doSetFundingData(stepData);
    this.setState({ step: this.state.step - 1 });
  };

  private _closeCreatePackageModal = async () => {
    await this.props.doSetFundingData({});
    this.setState({ step: 1 });
    this.props.setIsCreatePackageModalOpen(false);
  };

  private _renderStepContent = () => {
    const { selectedCustomer } = this.props;
    const { step } = this.state;

    if (step === 1) {
      return (
        <FundGeneralInformationPanel
          onNextStep={this._onNextStep}
          closeCreatePackageModal={this._closeCreatePackageModal}
          selectedCustomer={selectedCustomer}
        />
      );
    } else if (step === 2) {
      return (
        <FundSupportCategoryPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          closeCreatePackageModal={this._closeCreatePackageModal}
          selectedCustomer={selectedCustomer}
        />
      );
    } else if (step === 3) {
      return (
        <FundGoalsPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          closeCreatePackageModal={this._closeCreatePackageModal}
          selectedCustomer={selectedCustomer}
        />
      );
    } else if (step === 4) {
      return (
        <FundSummaryPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          closeCreatePackageModal={this._closeCreatePackageModal}
          selectedCustomer={selectedCustomer}
        />
      );
    }
  };

  render() {
    const { step, totalStep } = this.state;
    let bottomText = 'Add General Information';
    if (step === 2) {
      bottomText = 'Add Categories of Support';
    } else if (step === 3) {
      bottomText = 'Add Goals';
    } else if (step === 4) {
      bottomText = 'Create Funding Package';
    } else if (step === 5) {
      bottomText = 'Create Funding Package';
    }

    let steps = [];
    for (let i = 1; i <= totalStep; i++) {
      const stepObj = {};
      stepObj['stepNumber'] = i;
      stepObj['stepLabel'] = i;
      steps.push(stepObj);
    }
    return (
      <FullScreenScrollableModal
        isOpen={this.props.isCreatePackageModalOpen}
        canCloseEsc={false}
        onClose={this._closeCreatePackageModal}
        footerContent={
          <FullScreenModalFooter align={'left'}>
            <Row gutter={0} type="flex" align="middle">
              <Col span={8} />
              <Col span={8}>
                <div className="justify-center flex-row">
                  <Text size="regular" className="mb-medium">
                    <span className="mr-medium text-weight-bold">
                      Step {step} of {totalStep}
                    </span>
                    <span className="">{bottomText}</span>
                  </Text>
                </div>
                <Steps currentStep={step} steps={steps} />
              </Col>
              <Col span={8} />
            </Row>
          </FullScreenModalFooter>
        }
      >
        {this._renderStepContent()}
      </FullScreenScrollableModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  isCreatePackageModalOpen: state.customersStore.selectedCustomerState.isCreatePackageModalOpen,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doSetFundingData: dispatch.customersStore.doSetFundingData,
  setIsCreatePackageModalOpen: dispatch.customersStore.setIsCreatePackageModalOpen,
});

export default connect(mapState, mapDispatch)(CreateNewPackageModal);
