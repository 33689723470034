import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Speaker = forwardRef(function Speaker(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25h-.041c-1.37 0-2.455 0-3.321.088-.888.09-1.629.28-2.277.713a4.75 4.75 0 0 0-1.31 1.31c-.434.648-.623 1.39-.713 2.277-.088.866-.088 1.951-.088 3.32v6.084c0 1.369 0 2.454.088 3.32.09.888.28 1.629.713 2.277.346.519.791.964 1.31 1.31.648.434 1.39.623 2.277.713.866.088 1.951.088 3.32.088h.084c1.369 0 2.454 0 3.32-.088.888-.09 1.629-.28 2.277-.712a4.75 4.75 0 0 0 1.31-1.311c.434-.648.623-1.39.713-2.277.088-.866.088-1.951.088-3.32V8.958c0-1.369 0-2.454-.088-3.32-.09-.888-.28-1.629-.712-2.277a4.749 4.749 0 0 0-1.311-1.31c-.648-.434-1.39-.623-2.277-.713-.866-.088-1.951-.088-3.32-.088H12ZM7.194 3.298c.361-.241.827-.39 1.596-.468.781-.08 1.79-.08 3.21-.08s2.429 0 3.21.08c.77.079 1.235.227 1.596.468.354.237.66.542.896.896.241.361.39.827.468 1.596.08.781.08 1.79.08 3.21v6c0 1.42 0 2.429-.08 3.21-.079.77-.227 1.235-.468 1.596a3.25 3.25 0 0 1-.896.896c-.361.241-.827.39-1.596.468-.781.08-1.79.08-3.21.08s-2.429 0-3.21-.08c-.77-.079-1.235-.227-1.596-.468a3.25 3.25 0 0 1-.896-.896c-.241-.361-.39-.827-.468-1.596-.079-.781-.08-1.79-.08-3.21V9c0-1.42.001-2.429.08-3.21.079-.77.227-1.235.468-1.596a3.25 3.25 0 0 1 .896-.896ZM9.75 9a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM12 5.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5ZM11 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="currentColor"
      />
    </Icon>
  );
});
