import { Avatar, Checkbox, Input, Modal, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text, Title } from 'common-components/typography';
import { InfiniteScroll } from 'components';
import { IWorkflowTemplateStepApprover } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';

const { Search } = Input;

interface Props {
  isOpen: boolean;
  onClose(): void;

  workersSelected?: any;
  onSuccess?: any;

  accountTeamList: typeof state.teamStore.accountTeamList;
  doFetchActiveAccountTeamList: typeof dispatch.teamStore.doFetchActiveAccountTeamList;
}

interface State {
  isSearching: boolean;
  currentWorkersSelected: IWorkflowTemplateStepApprover[];
  isSearched: boolean;
}

class AddTeamMemberModal extends Component<Props, State> {
  state = {
    isSearching: false,
    currentWorkersSelected: [],
    isSearched: false,
  };

  private params = {
    page: 1,
    pageSize: 10,
    pageTimestamp: new Date(),
    search: '',
  };

  private _searchText = async () => {
    const { doFetchActiveAccountTeamList } = this.props;

    this.setState({ isSearching: true, isSearched: true });

    await doFetchActiveAccountTeamList(this.params);

    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 300);

  private _onEnterSearchText = (text: string) => {
    if (text.length >= 3 || text.length === 0) {
      this.params = { ...this.params, page: 1, search: text };
      this._debounceSearch();
    }
  };

  private _fetchMoreWorkers = () => {
    this.params.page++;
    this._searchText();
  };

  private _onSelectWorker = (user, selectedIndex, disabled) => {
    const selectedApprovers = [...this.state.currentWorkersSelected];

    if (disabled) return;

    if (selectedIndex === -1) {
      selectedApprovers.push({
        displayName: `${user.firstName} ${user.lastName}`,
        avatar: user.attachmentUrl,
        workerId: user.userId,
      });
    } else {
      selectedApprovers.splice(selectedIndex, 1);
    }

    this.setState({ currentWorkersSelected: [...selectedApprovers] });
  };

  private _onAddApprovers = () => {
    const { onSuccess } = this.props;

    onSuccess(this.state.currentWorkersSelected);

    this._onClose();
  };

  private _onClose = () => {
    const { onClose, doFetchActiveAccountTeamList } = this.props;

    doFetchActiveAccountTeamList(this.params);
    this.setState({ currentWorkersSelected: [], isSearched: false });

    onClose();
  };

  render() {
    const { isOpen, workersSelected, accountTeamList } = this.props;
    const { currentWorkersSelected, isSearching, isSearched } = this.state;

    const selectedUserId = _.map(currentWorkersSelected, 'workerId');
    const existedSelectUserId = _.map(workersSelected, 'workerId');

    // const newAccountTeamList = accountTeamList.filter((accountTeam) => accountTeam.status === TeamStatus.ENABLED);

    return (
      <Modal
        visible={isOpen}
        title={
          <Title level={4} className='mv-none'>
            Add team members
          </Title>
        }
        width='640px'
        onCancel={this._onClose}
        footer={null}
      >
        <div className='flex-column anim-slide-left mb-medium'>
          <Text>Please select the team members you want to add as involved in this incident.</Text>
          <Text color='secondary' size='regular' className='mv-medium'>
            <Text color='secondary' weight='bold' size='regular'>
              {selectedUserId.length > 0 ? selectedUserId.length : 'No'}
            </Text>
            &nbsp;team {selectedUserId.length > 1 ? 'members' : 'member'} selected
          </Text>
          <Search
            placeholder='Search for team members'
            size='large'
            onChange={(e) => this._onEnterSearchText(e.target.value)}
            loading={isSearching}
            style={{ width: '100%' }}
            className='mb-x2-small'
            allowClear
          />

          {isSearched && (
            <>
              {accountTeamList.length > 0 ? (
                <div
                  className='overflow-y-scroll bordered shadow-container'
                  id='scroll-modal'
                  style={{ maxHeight: '250px', overflowY: 'auto', overflowX: 'hidden' }}
                >
                  <InfiniteScroll
                    hasMore={accountTeamList.length >= this.params.page * this.params.pageSize}
                    loadMore={this._fetchMoreWorkers}
                  >
                    {_.map(accountTeamList, (item) => {
                      const selectedIndex = _.indexOf(selectedUserId, item.userId);
                      const existedSelect = _.indexOf(existedSelectUserId, item.userId) !== -1;

                      return (
                        <Row
                          key={item.userId}
                          className={`${
                            selectedIndex !== -1 && !existedSelect ? 'bg-blue-lightest' : ''
                          } pv-small ph-medium pl-12 hover-bg-tertiary cursor-pointer flex-row align-center`}
                          onClick={() => this._onSelectWorker(item, selectedIndex, existedSelect)}
                        >
                          <Checkbox checked={selectedIndex !== -1} disabled={existedSelect} />
                          <Avatar className='mh-small' icon='user' shape='circle' src={item.attachmentUrl} />
                          <span
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            <Text>{item.firstName + ' ' + item.lastName}</Text>

                            {existedSelect && <Text color='secondary'>&nbsp;(Already selected)</Text>}
                          </span>
                        </Row>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              ) : (
                !isSearching && (
                  <Text className='bordered shadow-container text-align-center pv-12'>No team members found</Text>
                )
              )}
            </>
          )}
        </div>

        <ActionModalFooter>
          <SecondaryButton size='large' className='mr-medium' onClick={this._onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size='large' onClick={this._onAddApprovers} disabled={currentWorkersSelected.length <= 0}>
            Add
          </PrimaryButton>
        </ActionModalFooter>
      </Modal>
    );
  }
}

const mapState = (state: IRootState) => ({
  accountTeamList: state.teamStore.accountTeamList,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchActiveAccountTeamList: dispatch.teamStore.doFetchActiveAccountTeamList,
});

export default connect(mapState, mapDispatch)(AddTeamMemberModal);
