import { IServiceDetailsItem, IServiceListItemLite } from 'interfaces/service-interfaces';
import { FetchServicesLiteRequest } from 'stores/hooks/query-hooks/use-query-fetch-services-lite';
import { postRequest } from '../typed-api-wrapper';
import { apiClient } from 'utilities/api-client';

export const fetchServicesLite = async (request: FetchServicesLiteRequest): Promise<IServiceListItemLite[]> => {
  return await postRequest<IServiceListItemLite[]>({
    path: '/api/portal/services/lite-list',
    data: request,
  });
};

export type GetServiceResponse = {
  status: number;
  data: IServiceDetailsItem;
};
export const getService = async (serviceId: string): Promise<GetServiceResponse> => {
  return await apiClient.get(`/api/portal/services/${serviceId}`);
};
