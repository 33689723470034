import { Form, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { WorkflowTemplateStepApprovalType, WorkflowTemplateStepType } from 'utilities/enum-utils';
import AttachFormModalWrapper from 'views/form-builder/components/AttachFormModal';
import PreviewFormModal from 'views/form-builder/components/PreviewFormModal';
import WorkflowTemplateAddEditAttachmentModal from 'views/workflows/workflow-templates/components/WorkflowTemplateAddEditAttachmentModal';
import WorkflowTemplateAddTeamMemberModal from 'views/workflows/workflow-templates/components/WorkflowTemplateAddTeamMemberModal';
import WorkflowTemplateSelectRoleModal from 'views/workflows/workflow-templates/components/WorkflowTemplateSelectRoleModal';
import { IWorkflowTemplateStep } from '../../../../../../../../interfaces/workflow-interfaces';
import StepItemDetail from '../../../../../components/step-detail/StepItemDetail';

interface Props extends FormComponentProps {
  isOpen: boolean;
  stepDetail: IWorkflowTemplateStep;
  onClose(): void;
  onUpdateStep(values: IWorkflowTemplateStep): void;
  currentForm: typeof state.formBuilderStore.currentForm;
  doGetFormDetailsByVersion: typeof dispatch.formBuilderStore.doGetFormDetailsByVersion;
}

interface State {
  stepDetail: IWorkflowTemplateStep;
  editType: string;
  isNothingChange: boolean;
}

class AddEditStepModal extends PureComponent<Props, State> {
  state = {
    stepDetail: this.props.stepDetail,
    editType: '',
    isNothingChange: true,
  };

  private _onUpdateStep = (stepDetail) => {
    const { stepDetail: stepDetailProp, form } = this.props;

    const currentStepDetail = { ...stepDetail };

    if (stepDetail.type === WorkflowTemplateStepType.STEP) {
      currentStepDetail.name = form.getFieldValue('name');
    }

    if (!_.isEqual(stepDetailProp, currentStepDetail)) {
      this.setState({ isNothingChange: false });
    }
    this.setState({ stepDetail });
  };

  private _onValidateBeforeSaveStep = () => {
    const { form, onUpdateStep } = this.props;

    form.validateFields(async (err, values) => {
      if (!err) {
        const stepDetail = {
          ...this.state.stepDetail,
          invalid:
            this.state.stepDetail.approvalType !== WorkflowTemplateStepApprovalType.ANYONE
              ? !this.state.stepDetail.stepApprovers.length
              : false,
        };

        if (stepDetail.type === WorkflowTemplateStepType.STEP) {
          stepDetail.name = values.name.trim();
        }

        this.setState({ stepDetail });

        if (stepDetail.invalid) {
          return;
        }

        this.setState({ isNothingChange: true });
        onUpdateStep(stepDetail);
      }
    });
  };

  private _onSelectForm = (formId, elementValues, formName, formVersionId) => {
    const { stepDetail } = this.state;

    if (formVersionId) {
      this._onUpdateStep({ ...stepDetail, formId, formName, formVersionId });
    }
  };

  private _onOpenActionModal = async (editType) => {
    if (editType === 'form-preview') {
      await this.props.doGetFormDetailsByVersion({
        formId: this.state.stepDetail.formId,
        versionId: this.state.stepDetail.formVersionId,
        isWorkflowFormVersion: true,
      });
    }
    this.setState({ editType });
  };

  private _onCloseActionModal = () => {
    this.setState({ editType: '' });
  };

  private _renderTitle = () => {
    const { stepDetail } = this.state;

    if (stepDetail) {
      if (stepDetail.type === WorkflowTemplateStepType.TRIGGER) {
        return 'Edit trigger step';
      }

      if (stepDetail.workflowTemplateStepId) {
        return 'Edit step';
      }
    }

    return 'Add new step';
  };

  private _onChangeName = (e) => {
    const value = e.target.value;
    const { stepDetail } = this.props;

    if (!_.isEqual(value, stepDetail && stepDetail.name)) {
      this.setState({ isNothingChange: false });
      return;
    }

    this.setState({ isNothingChange: true });
  };

  private _onCloseModal = () => {
    this.setState({ isNothingChange: true });
    this.props.onClose();
  };

  componentDidUpdate(prevProps: Props) {
    const { stepDetail } = this.props;

    if (!_.isEqual(prevProps.stepDetail, stepDetail)) {
      this.setState({ stepDetail: stepDetail });
    }
  }

  componentDidMount() {
    this.setState({ stepDetail: this.props.stepDetail });
  }

  render() {
    const { isOpen, form, currentForm } = this.props;
    const { stepDetail, editType, isNothingChange } = this.state;
    const { getFieldDecorator } = form;

    const isEditStep = stepDetail && stepDetail.workflowTemplateStepId;

    return (
      <>
        <ActionModal
          isOpen={isOpen}
          onClose={this._onCloseModal}
          title={`${this._renderTitle()}`}
          verticalAlignment="highest"
          enforceFocus={false}
        >
          {stepDetail && stepDetail.workflowTemplateStepId ? (
            <Text>Edit the details for the step in this workflow</Text>
          ) : (
            <Text>Enter the details for this new step</Text>
          )}

          {stepDetail && stepDetail.type === WorkflowTemplateStepType.STEP && (
            <>
              <SubTitle color={'tertiary'} size={'regular'} containerClassName="mt-12">
                Step name
              </SubTitle>

              <Form className="align-center">
                <Form.Item>
                  {getFieldDecorator('name', {
                    initialValue: stepDetail ? stepDetail.name : '',
                    rules: [
                      {
                        required: true,
                        message: 'Please add a step name',
                      },
                      {
                        whitespace: true,
                        message: 'Please add a step name',
                      },
                    ],
                  })(
                    <Input
                      placeholder="Enter step name here..."
                      size="large"
                      style={{ minWidth: '352px' }}
                      maxLength={50}
                      onChange={this._onChangeName}
                    />,
                  )}
                </Form.Item>
              </Form>
            </>
          )}

          {stepDetail && (
            <div className="bordered rounded-big mt-medium">
              <StepItemDetail
                moduleType="view-edit"
                stepDetail={stepDetail}
                onUpdateStep={this._onUpdateStep}
                onSetEditStep={(editType) => this._onOpenActionModal(editType)}
              />
            </div>
          )}

          <ActionModalFooter className={'mt-x-large'}>
            <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size="large"
              onClick={this._onValidateBeforeSaveStep}
              disabled={isNothingChange && isEditStep}
            >
              {stepDetail && stepDetail.workflowTemplateStepId ? 'Save' : 'Add step'}
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>

        <WorkflowTemplateSelectRoleModal
          selectedStep={stepDetail}
          isOpen={editType === WorkflowTemplateStepApprovalType.ROLE}
          onUpdateStep={(values) => this._onUpdateStep(values)}
          onClose={this._onCloseActionModal}
        />

        <WorkflowTemplateAddTeamMemberModal
          selectedStep={stepDetail}
          isOpen={editType === WorkflowTemplateStepApprovalType.TEAM_MEMBER}
          onUpdateStep={(values) => this._onUpdateStep(values)}
          onClose={this._onCloseActionModal}
        />

        {editType === 'attachment' && (
          <WorkflowTemplateAddEditAttachmentModal
            isOpen={editType === 'attachment'}
            selectedStep={stepDetail}
            onUpdateStep={(values) => this._onUpdateStep(values)}
            onClose={this._onCloseActionModal}
          />
        )}

        {editType === 'form' && (
          <AttachFormModalWrapper
            isOpen={editType === 'form'}
            onClose={this._onCloseActionModal}
            onSave={this._onSelectForm}
            isAddedFormOnly={true}
          />
        )}

        {editType === 'form-preview' && (
          <PreviewFormModal
            formContent={currentForm}
            isOpen={editType === 'form-preview'}
            onClose={this._onCloseActionModal}
          />
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  currentForm: state.formBuilderStore.currentForm,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetFormDetailsByVersion: dispatch.formBuilderStore.doGetFormDetailsByVersion,
});

export default connect(mapState, mapDispatch)(Form.create<Props>()(AddEditStepModal));
