import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Bug = forwardRef(function Bug(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25A3.75 3.75 0 0 0 8.25 6v.387a6.781 6.781 0 0 0-2.262 2.54l-.7-.7C4.378 7.317 4.079 7 3.92 6.618c-.158-.381-.17-.816-.17-2.103V4a.75.75 0 0 0-1.5 0v.515l-.001.142c0 1.088 0 1.844.286 2.535.286.692.82 1.226 1.59 1.995l.101.1 1.194 1.195A6.772 6.772 0 0 0 5.251 12v3c0 .222.01.441.03.658l-1.054 1.054-.1.101c-.77.769-1.305 1.303-1.591 1.995-.287.691-.286 1.447-.286 2.535V22a.75.75 0 0 0 1.5 0v-.515c0-1.287.013-1.722.171-2.103.158-.382.456-.699 1.367-1.609l.393-.394a6.753 6.753 0 0 0 12.638 0l.393.394c.91.91 1.209 1.227 1.367 1.609.158.381.171.816.171 2.103V22a.75.75 0 0 0 1.5 0v-.657c0-1.088.001-1.844-.285-2.535-.287-.692-.822-1.226-1.591-1.995l-.1-.1-1.056-1.055A6.84 6.84 0 0 0 18.75 15v-3c0-.522-.06-1.03-.171-1.518l1.194-1.194.1-.101c.77-.769 1.305-1.303 1.592-1.995.286-.691.286-1.447.285-2.535V4a.75.75 0 0 0-1.5 0v.515c0 1.287-.013 1.722-.171 2.103-.158.382-.456.699-1.367 1.609l-.7.7a6.78 6.78 0 0 0-2.262-2.54V6A3.75 3.75 0 0 0 12 2.25Zm2.219 3.373a2.25 2.25 0 0 0-4.437 0A6.74 6.74 0 0 1 12 5.25a6.74 6.74 0 0 1 2.219.373ZM12.75 15v5.197A5.251 5.251 0 0 0 17.25 15v-3a5.25 5.25 0 1 0-10.5 0v3a5.251 5.251 0 0 0 4.5 5.197V15a.75.75 0 0 1 1.5 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
