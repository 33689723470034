import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Selection = forwardRef(function Selection(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.462 2.25H6.5v1.5c-.718 0-1.2 0-1.567.038-.355.036-.519.1-.627.173a1.25 1.25 0 0 0-.345.345c-.073.108-.136.272-.173.627-.037.367-.038.85-.038 1.567a.75.75 0 1 1-1.5 0v-.038c0-.67 0-1.229.046-1.681.048-.473.153-.913.418-1.309.2-.3.458-.558.758-.759.396-.264.836-.369 1.31-.417.451-.046 1.01-.046 1.68-.046Zm12.605 1.538c-.367-.037-.849-.038-1.567-.038a.75.75 0 0 1 0-1.5h.038c.67 0 1.229 0 1.681.046.474.048.913.153 1.309.417.3.201.558.459.759.76.264.395.369.835.417 1.308.046.452.046 1.011.046 1.68V6.5h-1.5c0-.718 0-1.2-.038-1.567-.036-.355-.1-.519-.172-.627a1.25 1.25 0 0 0-.345-.345c-.109-.073-.273-.137-.628-.173ZM9.25 3a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5h-4A.75.75 0 0 1 9.25 3ZM3 9.25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0v-4A.75.75 0 0 1 3 9.25Zm18 0a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0v-4a.75.75 0 0 1 .75-.75Zm0 7.5a.75.75 0 0 1 .75.75v.038c0 .67 0 1.229-.046 1.681-.048.473-.153.913-.417 1.309-.201.3-.459.558-.76.759-.395.264-.834.369-1.308.417-.452.046-1.011.046-1.68.046H17.5v-1.5c.718 0 1.2 0 1.567-.038.355-.036.52-.1.628-.173a1.25 1.25 0 0 0 .345-.345c.072-.108.136-.272.172-.627.037-.367.038-.85.038-1.567a.75.75 0 0 1 .75-.75Zm-18.75.788V17.5h1.5c0 .718.001 1.2.038 1.567.037.355.1.519.173.628.091.136.208.253.345.344.108.073.272.137.627.173.367.037.85.038 1.567.038a.75.75 0 0 1 0 1.5h-.038c-.67 0-1.229 0-1.68-.046-.474-.048-.914-.153-1.31-.418-.3-.2-.558-.458-.758-.758-.265-.396-.37-.835-.418-1.309-.046-.452-.046-1.011-.046-1.68Zm7 3.462a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});
