import { Button, Text } from '@good/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IRootState, state } from 'stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';
import UnavailableTimeModal from '../../availability/components/Unavailability/UnavailableTimeModal';
import { MemberAvailabilities } from '../availability';
import UnavailabilityRequests from './scheduled-unavailability/unavailability-requests';
import { UpcomingUnavailability } from './scheduled-unavailability/upcoming-unavailability';

type ScheduledUnavailabilityProps = {
  memberAvailabilities: MemberAvailabilities;
  portalUser: typeof state.authStore.portalUser;
  hasEditPermission: boolean;
};
const ScheduledUnavailability = ({
  memberAvailabilities,
  portalUser,
  hasEditPermission,
}: ScheduledUnavailabilityProps) => {
  const [showModal, setModal] = useState(false);
  const { t } = useTranslation('', { keyPrefix: 'teamMember.workDetails.scheduledUnavailability' });

  return (
    <>
      <UnavailableTimeModal
        isOpen={showModal}
        onClose={() => setModal(false)}
        selectedItem={null}
        timezone={memberAvailabilities.userTimezone}
      />

      <div className="flex flex-col space-y-4">
        <div className="flex w-full flex-row justify-between space-x-3">
          <div className="flex flex-col">
            <Text size="md" className="pb-1 pt-1 font-bold" asChild>
              <h4>{t('label')}</h4>
            </Text>
            <Text size="md" className="font-light text-neutral" asChild>
              <p>{t('description')}</p>
            </Text>
          </div>
          <div className="pb-4">
            {hasEditPermission && (
              <Button onClick={() => setModal(true)} size="sm" tone="accent" emphasis="outline">
                {t('addUnavailabilityButtonLabel')}
              </Button>
            )}
          </div>
        </div>

        <div className="flex flex-row justify-between space-x-8 rounded-2xl bg-gray-light-2 p-4">
          <div className="basis-1/2">
            <UpcomingUnavailability unavailableTimes={memberAvailabilities.unavailableTimes} />
          </div>

          {PermissionUtils.validatePermission('ViewTeamMemberPermissions', portalUser.permissions.permissionRoles) && (
            <div className="flex basis-1/2 flex-col justify-center rounded-2xl bg-white">
              <UnavailabilityRequests />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default connect(
  (state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    memberAvailabilities: state.teamStore.memberAvailabilities,
  }),
  null,
)(ScheduledUnavailability);
