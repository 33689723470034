import { forwardRef } from 'react';
import { Icon } from './icon';
import type { IconProps } from './icon';
import type { Ref } from 'react';
export const Undo = forwardRef(function Undo(props: IconProps, ref: Ref<SVGSVGElement>) {
  return (
    <Icon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53 2.47a.75.75 0 0 1 0 1.06L4.81 6.25H15a6.75 6.75 0 0 1 0 13.5h-4a.75.75 0 0 1 0-1.5h4a5.25 5.25 0 1 0 0-10.5H4.81l2.72 2.72a.75.75 0 1 1-1.06 1.06l-4-4a.75.75 0 0 1 0-1.06l4-4a.75.75 0 0 1 1.06 0Z"
        fill="currentColor"
      />
    </Icon>
  );
});
