import { Text } from 'common-components/typography';
import * as H from 'history';
import { isEqual } from 'lodash';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from '../../stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';
import SubscriptionStatusCard from './components/SubscriptionStatusCard';
import { Link } from 'react-router-dom';
import { Flag } from 'common-components/utils/launchdarkly/flag';
import { Anchor, Card, Group, Loader, SimpleGrid, Stack } from '@good/ui/core';
import { SubscriptionStatusBadge } from './subscription-management/components/SubscriptionStatusBadge';
import { Trans, useTranslation } from 'react-i18next';
import { ISubscriptionStatusInfo } from 'interfaces/subscription-interfaces';

type AccountNavItemProps = {
  title: ReactNode;
  isComingSoon?: boolean;
  description: ReactNode;
  targetUrl: string;
  disabled?: boolean;
};

function AccountNavItem({ title, isComingSoon, description, targetUrl, disabled }: AccountNavItemProps) {
  const content = (
    <Card withBorder h='100%' className={disabled ? undefined : 'card-item-hover'}>
      <Stack>
        <Text
          size='x2-large'
          color={isComingSoon ? 'black' : 'blue-action'}
          className={!(isComingSoon || disabled) ? 'hover-text-underline' : undefined}
        >
          {title}
        </Text>

        {isComingSoon && (
          <div className='mb-small'>
            <Text size='large'>COMING SOON</Text>
          </div>
        )}

        <Text size='large'>{description}</Text>
      </Stack>
    </Card>
  );

  if (disabled) return content;

  return (
    <Link to={targetUrl} className='hover:no-underline' {...{ disabled: isComingSoon }}>
      {content}
    </Link>
  );
}

const SubscriptionStatusBanner = ({
  subscriptionInfo,
  hasSubscription,
}: { subscriptionInfo: ISubscriptionStatusInfo; hasSubscription: boolean }) => {
  const { t } = useTranslation('', { keyPrefix: 'accountManagement' });

  return (
    <AccountNavItem
      title={
        <Group justify='space-between'>
          {t('subscription.banner.title')}
          {!hasSubscription ? (
            <SubscriptionStatusBadge status='none-payg' />
          ) : !subscriptionInfo ? (
            <Loader size='sm' />
          ) : (
            <SubscriptionStatusBadge
              status={subscriptionInfo.subscriptionStatus}
              daysLeftInTrial={subscriptionInfo.numberOfDaysLeftTrial}
            />
          )}
        </Group>
      }
      description={
        hasSubscription ? (
          t('subscription.banner.description')
        ) : (
          <Trans
            i18nKey='accountManagement.subscription.banner.nonPayAsYouGoDescription'
            components={[
              <Anchor key='support-link' href='mailto:support@goodhuman.me'>
                support@goodhuman.me
              </Anchor>,
            ]}
          />
        )
      }
      targetUrl='/account/subscription-management'
      disabled={!hasSubscription}
    />
  );
};

type IAccountLandingViewProps = {
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
  doGetSubscriptionStatus: typeof dispatch.subscriptionStore.doGetSubscriptionStatus;
  portalUser: typeof state.authStore.portalUser;
  subscriptionStatus: typeof state.subscriptionStore.subscriptionStatus;
  companyDataLite: typeof state.companyStore.companyDataLite;
  history: H.History;
};

type IAccountLandingViewState = {
  loadingSubscriptionStatus: boolean;
};

class AccountLandingView extends React.Component<IAccountLandingViewProps, IAccountLandingViewState> {
  override state = {
    loadingSubscriptionStatus: false,
  };

  getSubscriptionStatus = async () => {
    const { companyDataLite, doGetSubscriptionStatus } = this.props;

    if (companyDataLite?.hasSubscription) {
      this.setState({ loadingSubscriptionStatus: true });
      await doGetSubscriptionStatus();
      this.setState({ loadingSubscriptionStatus: false });
    }
  };

  override componentDidMount() {
    this.getSubscriptionStatus();
    this.props.setSelectedSideNavMenuKeys(['/account/landing']);
  }

  override componentDidUpdate(prevProps: IAccountLandingViewProps): void {
    const { companyDataLite } = this.props;

    if (companyDataLite && !isEqual(prevProps.companyDataLite, companyDataLite)) {
      this.getSubscriptionStatus();
    }
  }

  override render() {
    const { portalUser, subscriptionStatus, companyDataLite } = this.props;
    const { loadingSubscriptionStatus } = this.state;

    const permissionRoles = portalUser?.permissions?.permissionRoles ?? [];
    const hasSubscriptionPermission = PermissionUtils.validatePermission('ViewSubscriptionBilling', permissionRoles);

    // Temporary disable new Branding for service provider that have custom SA template.
    // TODO: Update the custom template to have them use the new branding.
    const excludedServiceProviderForBranding = [
      '1c978487-1c1d-47d6-874a-50523caab398', // Possability
      'dbf783d4-a01b-473c-96f7-2e93b7de2d1d', // TS Collective
    ];

    return (
      <Stack>
        <Flag
          flag='newSubscriptionManagement'
          on={
            <SubscriptionStatusBanner
              subscriptionInfo={subscriptionStatus}
              hasSubscription={!!companyDataLite?.hasSubscription}
            />
          }
          off={
            <SubscriptionStatusCard
              currentScreen='Account'
              subscriptionStatusInfo={subscriptionStatus}
              hasPermission={hasSubscriptionPermission}
              loading={loadingSubscriptionStatus || !companyDataLite}
            />
          }
        />

        <SimpleGrid cols={{ base: 1, md: 2, lg: 3 }}>
          {PermissionUtils.validatePermission('ViewCompanyDetail', permissionRoles) && (
            <AccountNavItem
              title='Company details'
              description='Essential company details including the Primary Owner and trading information.'
              targetUrl='/company'
            />
          )}
          {PermissionUtils.validatePermission('ViewBrandingPage', permissionRoles) && (
            <AccountNavItem
              title='Branding'
              description='Branding assets used in the portal and in generated communications.'
              isComingSoon={Boolean(
                excludedServiceProviderForBranding.find(
                  (serviceProviderId: string) => serviceProviderId === portalUser?.serviceProviderId,
                ),
              )}
              targetUrl='/account/branding'
            />
          )}
          <AccountNavItem
            title='Funding and payment'
            description='Funding sources and customer payment options.'
            targetUrl='/account/funding-and-payment'
          />
          <AccountNavItem
            title='Feature configuration'
            description='GoodHuman functionality and options.'
            targetUrl='/account/features'
          />
          {PermissionUtils.validatePermission('ViewTeamManagement', permissionRoles) && (
            <AccountNavItem
              title='Team management'
              description='Add, deactivate and assign permissions to people in your business.'
              targetUrl='/account/team-management'
            />
          )}
          {PermissionUtils.validatePermission('ViewServiceDepartmentListing', permissionRoles) && (
            <AccountNavItem
              title='Service departments'
              description='Create and configure your businesses service departments.'
              targetUrl='/account/service-department'
            />
          )}
          {PermissionUtils.validatePermission('ExportPlatformData', permissionRoles) && (
            <AccountNavItem
              title='Export data'
              description='Access your company data on your terms.'
              targetUrl='/account/export-data'
            />
          )}
          {PermissionUtils.validatePermission('ImportPlatformData', permissionRoles) && (
            <Flag
              flag='selfServiceCsvImports'
              on={
                <AccountNavItem
                  title='Import data'
                  description='Import your company data.'
                  targetUrl='/account/imports'
                />
              }
            />
          )}
          {PermissionUtils.validatePermission('ViewDebtorManagement', permissionRoles) && (
            <AccountNavItem
              title='Debtor management'
              description='Manage customer debtors'
              targetUrl='/account/debtor-management'
            />
          )}
          {PermissionUtils.validatePermission('AccessToFormBuilder', permissionRoles) && (
            <AccountNavItem
              title='Forms'
              description="View and configure your company's forms to be deployed your workspace."
              targetUrl='/account/forms'
            />
          )}
          {PermissionUtils.validatePermission('ViewAddEditWorkflow', permissionRoles) && (
            <AccountNavItem
              title='Workflow templates'
              description='Manage your businesses workflows.'
              targetUrl='/account/workflows'
            />
          )}
        </SimpleGrid>
      </Stack>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  subscriptionStatus: state.subscriptionStore.subscriptionStatus,
  companyDataLite: state.companyStore.companyDataLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
  doGetSubscriptionStatus: dispatch.subscriptionStore.doGetSubscriptionStatus,
});

export default connect(mapState, mapDispatch)(AccountLandingView);
