import React, { useState, useRef } from 'react';
import { Button } from '@good/components';

import { Popover } from 'design-components/popover';
import { FilterLabel, FilterLabelProps } from './filter-label';
import { FilterCard, FilterCardProps, FilterCardPropsNever } from './filter-card';

/**
 * @name
 * Filter
 *
 * @description
 * A filter component that comes with basic label and filter card with header and footer.
 * The filter can be further customised with custom label or custom card.
 *
 * @example
 * Default filter with no items selected
 * <Filter label="Filter" title="Filter Title">
 *    <Text>Some filter content</Text>
 * </Filter>
 *
 * Default filter with some items selected
 * <Filter label="Filter" selectedLabel="2 Selected" title="Filter Title">
 *    <Text>Some filter content</Text>
 * </Filter>
 *
 * Filter with custom label
 * <Filter customLabel={<MyLabel/>} isSelected={isSelected}/>
 *
 * Filter with custom card
 * <Filter label="Filter" selectedLabel="2 Selected" renderCard={
 *  (onClose) => <MyCard onClose={onClose}/>
 *  }/>
 *
 */

type FilterProps = FilterLabelProps &
  (
    | (Omit<FilterCardProps, 'onClose'> & {
        renderCard?: never;
      })
    | (Omit<FilterCardPropsNever, 'onClose'> & {
        renderCard: (onClose: () => void) => JSX.Element;
      })
  );

export const Filter = ({
  children,
  renderCard,
  customLabel,
  isApplyDisabled,
  isSelected,
  label,
  onApply,
  onClear,
  selectedLabel,
  title,
}: FilterProps): JSX.Element => {
  const [isFilterCardOpen, setIsFilterCardOpen] = useState(false);
  const filterButton = useRef<HTMLButtonElement>(null);
  const isSelected_ = isSelected || selectedLabel !== undefined;

  const onFilterCardClose = () => {
    setIsFilterCardOpen(false);
  };

  return (
    <div>
      <div className="flex">
        <Button
          emphasis={!isSelected_ ? 'outline' : 'quiet'}
          aria-label={`filter by ${label}`}
          onPress={() => setIsFilterCardOpen(!isFilterCardOpen)}
          ref={filterButton}
          className={['border', isSelected_ ? 'border-ocean-light-2 bg-ocean-light-2' : ''].join(' ')}
        >
          {customLabel ? (
            <FilterLabel customLabel={customLabel} isSelected={isSelected} />
          ) : (
            <FilterLabel label={label} selectedLabel={selectedLabel} />
          )}
        </Button>
      </div>
      <Popover
        isOpen={isFilterCardOpen}
        onClose={onFilterCardClose}
        shouldCloseOnInteractOutside={(e) => {
          if (filterButton.current?.contains(e)) return false;
          return true;
        }}
        backgroundColor="transparent"
      >
        {renderCard ? (
          renderCard(() => setIsFilterCardOpen(false))
        ) : (
          <FilterCard
            isApplyDisabled={isApplyDisabled}
            onApply={onApply}
            onClear={onClear}
            onClose={onFilterCardClose}
            title={title}
          >
            {children}
          </FilterCard>
        )}
      </Popover>
    </div>
  );
};
