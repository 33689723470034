import {
  IGroupServiceActivityLog,
  IGroupServiceCustomer,
  IGroupServiceNote,
  IGroupServiceOverview,
  IGroupServiceOverviewWarnings,
  IGroupServiceServiceAgreement,
  IGroupServiceSession,
  IGroupServiceSupportWorkersWithConflicts,
  IGroupServiceTimesheetSession,
  IGroupServiceTimesheetShiftSlot,
  IGroupServiceTimeSlot,
  IPendingShiftSlots,
  IShiftClashConflict,
  IWorkerOutsideAvailability,
  IGroupServiceActivityGroup,
  IGroupServiceUnAssignedMembers,
  ISessionAssignedTeamMembers,
  IActivityGroup,
  IGroupServiceWorkerConflictSession,
} from 'interfaces/service-interfaces';
import {
  IAddSessionFormPayload,
  ICustomerSessionToBeRemoved,
  IServiceDateTimeFormUser,
  ISession,
  ISessionCustomer,
  ISessionCustomerDetail,
  ISessionOverview,
  ISessionSupportWorker,
  ISessionBookingRatio,
} from 'interfaces/session-interfaces';
import { ISessionShiftSlotOverview, IShiftSlot } from 'interfaces/shift-interfaces';
import _ from 'lodash';
import apiClient from 'utilities/api-client';
import asyncDelay from 'utilities/asyncDelay';
import {
  ActivityGroupMemberType,
  BookingStatus,
  GroupServiceSessionStatus,
  ServicePublishStatus,
  TimezoneSelectorMode,
} from 'utilities/enum-utils';
import Utils from '../../../utilities/Utils';
import { IRootState } from '../root-store';
import { StringDecoder } from 'string_decoder';
import { IElementValue } from 'views/form-builder/shared/form-interface';

interface IGroupServiceStoreState {
  selectedGroupService?: IGroupServiceOverview;
  sessions?: IGroupServiceSession[];

  // Added by Jir : bookings per session. Array with serviceDateTimeId as a key for bookings.
  sessionBookings?: [
    {
      serviceDateTimeId: string;
      bookings: ISessionCustomer[];
    },
  ];

  sessionFilters?: any;

  sessionListDisplayTzMode: TimezoneSelectorMode;
  sessionListDisplayTzCustom: any;

  selectedSession?: ISession;
  groupServiceServiceAgreements: IGroupServiceServiceAgreement[];
  sessionCustomerWarnings: any;
  sessionCustomerDetails: ISessionCustomerDetail;
  sessionCustomerCustomerBookings: ISessionCustomer[];
  groupServiceSessionNotes: IGroupServiceNote[];
  groupServiceSessionCustomers: IGroupServiceCustomer[];
  groupServiceOverviewWarnings?: IGroupServiceOverviewWarnings;
  groupServiceSessionActivityLogs: IGroupServiceActivityLog[];
  sessionOverview?: ISessionOverview;
  scheduleTimeSlotId?: string;
  resetSessionCustomers?: boolean;
  sessionShiftSlotOverview: ISessionShiftSlotOverview;
  sessionSupportWorkers: ISessionSupportWorker[];
  sessionBookingRatios: ISessionBookingRatio[];
  shiftSlotActivityLogs: IGroupServiceActivityLog[];
  outsideAvailabilityShiftSlots: IWorkerOutsideAvailability[];
  groupServiceSupportWorkers: IGroupServiceSupportWorkersWithConflicts[];
  groupServiceSessions: IGroupServiceSession[];
  groupServiceTimeSlots: IGroupServiceTimeSlot[];
  timesheetSessionList: IGroupServiceTimesheetSession[];
  timesheetShiftSlotList: IGroupServiceTimesheetShiftSlot[];
  allPendingShiftSlots: IPendingShiftSlots[];
  selectedShiftSlots: IShiftSlot[];
  selectedTabKey: any;
  selectedSessionTabKey: string;
  customerBookingToOpen: { attendanceId: string; selectedTab?: string };
  customerSessionToBeRemoveList: ICustomerSessionToBeRemoved[];
  //for attach form to session
  sessionInvolveUsers: {
    involvedCustomers: IServiceDateTimeFormUser[];
    involvedMembers: IServiceDateTimeFormUser[];
  };
  selectedSessionInvolveUserIds: {
    involvedCustomerIds: string[];
    involvedMemberIds: string[];
  };
  shiftClashConflicts: IShiftClashConflict[];
  groupServiceActivityGroups: IGroupServiceActivityGroup[];
  sessionActivityGroups: IGroupServiceActivityGroup[];
  groupServiceUnAssignedMembers: IGroupServiceUnAssignedMembers;
  activityGroupsList: IActivityGroup[];
  sessionAssignedTeamMembers: ISessionAssignedTeamMembers;
  sessionUnassignedMembers: IGroupServiceUnAssignedMembers;
}

const initialState: IGroupServiceStoreState = {
  selectedGroupService: null,
  groupServiceOverviewWarnings: null,
  groupServiceSessionNotes: null,
  groupServiceSessionCustomers: null,
  groupServiceSessionActivityLogs: [],
  sessions: [],
  sessionBookings: null,
  sessionListDisplayTzMode: TimezoneSelectorMode.MyTimezone,
  sessionListDisplayTzCustom: null,

  sessionFilters: [],
  selectedSession: null,
  groupServiceServiceAgreements: [],
  sessionCustomerWarnings: null,
  sessionCustomerDetails: null,
  sessionCustomerCustomerBookings: [],
  resetSessionCustomers: false,
  sessionShiftSlotOverview: null,
  sessionSupportWorkers: [],
  sessionBookingRatios: [],
  shiftSlotActivityLogs: [],
  groupServiceSupportWorkers: [],
  groupServiceSessions: [],
  groupServiceTimeSlots: [],
  timesheetSessionList: [],
  timesheetShiftSlotList: [],
  allPendingShiftSlots: [],
  selectedShiftSlots: [],
  selectedTabKey: null,
  selectedSessionTabKey: 'OVERVIEW',
  customerBookingToOpen: undefined,
  customerSessionToBeRemoveList: [],
  sessionInvolveUsers: {
    involvedCustomers: null,
    involvedMembers: null,
  },
  selectedSessionInvolveUserIds: {
    involvedCustomerIds: [],
    involvedMemberIds: [],
  },
  shiftClashConflicts: [],
  outsideAvailabilityShiftSlots: [],
  activityGroupsList: [],
  groupServiceActivityGroups: [],
  sessionActivityGroups: [],
  sessionUnassignedMembers: {
    customers: [],
    teamMembers: [],
  },
  groupServiceUnAssignedMembers: {
    customers: [],
    teamMembers: [],
  },
  sessionAssignedTeamMembers: {
    serviceId: '',
    isConfirmed: false,
    supportWorkers: [
      {
        supportWorkerId: '',
        serviceDateTimes: [
          {
            serviceDateTimeId: '',
            startDateTime: null,
            endDateTime: null,
            serviceDateTimeActivityGroupIds: [], // Array<serviceDateTimeActivityGroupId>
          },
        ],
      },
    ],
  },
};

const groupServiceStore = {
  state: { ...initialState },
  reducers: {
    setSessions: (state, payload) => ({ ...state, sessions: payload }),
    // Added by Jir : This is a list of bookings per session; used for session listings.
    setSessionBookings: (state, payload) => ({ ...state, sessionBookings: payload }),

    setSessionFilters: (state, payload) => ({ ...state, sessionFilters: payload }),

    setSessionListDisplayTzMode: (state, payload) => ({ ...state, sessionListDisplayTzMode: payload }),
    setSessionListDisplayTzCustom: (state, payload) => ({ ...state, sessionListDisplayTzCustom: payload }),

    setSelectedSession: (state, payload) => ({ ...state, selectedSession: payload }),
    setGroupServiceServiceAgreements: (state, payload) => ({ ...state, groupServiceServiceAgreements: payload }),
    setGroupServiceSessionNotes: (state, payload) => ({ ...state, groupServiceSessionNotes: payload }),
    setGroupServiceSessionCustomers: (state, payload) => ({ ...state, groupServiceSessionCustomers: payload }),
    setSelectedGroupService: (state, payload) => ({ ...state, selectedGroupService: payload }),
    setGroupServiceOverviewWarnings: (state, payload) => ({ ...state, groupServiceOverviewWarnings: payload }),
    setCustomerBookingToOpen: (state, payload) => ({ ...state, customerBookingToOpen: payload }),
    setCustomerSessionToBeRemoveList: (state, payload) => ({
      ...state,
      customerSessionToBeRemoveList: payload,
    }),
    updateGroupServiceStatus: (state, payload) => {
      return {
        ...state,
        selectedGroupService: {
          ...state.selectedGroupService,
          status: payload.publishStatus,
          updatedOn: new Date(),
        },
      };
    },
    setGroupServiceSessionActivityLogs: (state, payload) => ({ ...state, groupServiceSessionActivityLogs: payload }),
    updateGroupServiceSessionActivityLogs: (state, payload) => {
      const selectedSessionActivityLogs = [...payload, ...state.groupServiceSessionActivityLogs];
      return { ...state, groupServiceSessionActivityLogs: selectedSessionActivityLogs };
    },
    setSessionCustomerWarnings: (state, payload) => ({ ...state, sessionCustomerWarnings: payload }),
    setSessionCustomerDetails: (state, payload) => {
      let customerUserIds = [];
      let numberOfBookingNotConfirmed = 0;
      _.forEach(payload.customers, (customer) => {
        if (customer.status === BookingStatus.ACCEPTED) {
          numberOfBookingNotConfirmed++;
        }
        customerUserIds.push(customer.customerUserId);
      });
      return {
        ...state,
        sessionCustomerDetails: {
          capacity: payload.capacity,
          bookedCapacity: payload.bookedCapacity,
          totalProcessedAmount: Number(payload.totalProcessedAmount),
          fullCustomerList: payload.customers,
          customerUserIds,
          numberOfBookingNotConfirmed,
        },
      };
    },
    setSessionCustomerBookings: (state, payload) => ({ ...state, sessionCustomerCustomerBookings: payload }),
    setSessionOverview: (state, payload) => ({ ...state, sessionOverview: payload }),
    setResetSessionCustomers: (state, payload) => ({ ...state, resetSessionCustomers: payload }),
    updateSelectedGroupServiceDetail: (state, payload) => {
      return {
        ...state,
        selectedGroupService: {
          ...state.selectedGroupService,
          ...payload,
        },
      };
    },
    setSessionsShiftSlotOverview: (state, payload) => ({ ...state, sessionShiftSlotOverview: payload }),
    setSessionSupportWorkers: (state, payload) => ({ ...state, sessionSupportWorkers: payload }),
    setSessionBookingRatios: (state, payload) => ({ ...state, sessionBookingRatios: payload }),
    setShiftSlotActivityLogs: (state, payload) => ({ ...state, shiftSlotActivityLogs: payload }),
    setOutsideAvailabilityShiftSlots: (state, payload) => ({ ...state, outsideAvailabilityShiftSlots: payload }),
    setgroupServiceSupportWorkers: (state, payload) => {
      const supportWorkersWithAssignedSessions = _.map(payload, (worker) => {
        worker.assignedSessions = [];
        return worker;
      });
      return { ...state, groupServiceSupportWorkers: supportWorkersWithAssignedSessions };
    },
    setGroupServiceSessions: (state, payload) => ({ ...state, groupServiceSessions: payload }),
    setGroupServiceTimeSlots: (state, payload) => ({ ...state, groupServiceTimeSlots: payload }),
    setTimesheetSessionList: (state, payload) => ({ ...state, timesheetSessionList: payload }),
    setTimesheetShiftSlotList: (state, payload) => ({ ...state, timesheetShiftSlotList: payload }),
    setAllPendingShiftSlots: (state, payload) => ({ ...state, allPendingShiftSlots: payload }),
    setSelectedShiftSlots: (state, payload) => ({ ...state, selectedShiftSlots: payload }),
    setSelectedTabKey: (state, payload) => ({ ...state, selectedTabKey: payload }),
    setSelectedSessionTabKey: (state, payload) => ({ ...state, selectedSessionTabKey: payload }),
    setSessionInvolveUsers: (state, payload) => ({ ...state, sessionInvolveUsers: payload }),
    setSelectedSessionInvolveUserIds: (state, payload) => ({ ...state, selectedSessionInvolveUserIds: payload }),
    setShiftClashConflicts: (state, payload) => ({ ...state, shiftClashConflicts: payload }),
    setActivityGroupsList: (state, payload) => ({ ...state, activityGroupsList: payload }),
    setGroupServiceActivityGroups: (state, payload) => ({ ...state, groupServiceActivityGroups: payload }),
    setGroupServiceUnAssignedMembers: (state, payload) => ({ ...state, groupServiceUnAssignedMembers: payload }),
    setSessionAssignedTeamMembers: (state, payload) => ({ ...state, sessionAssignedTeamMembers: payload }),
    setSessionActivityGroups: (state, payload) => ({ ...state, sessionActivityGroups: payload }),
    setSessionUnassignedMembers: (state, payload) => ({ ...state, sessionUnassignedMembers: payload }),
  },
  effects: (dispatch) => ({
    async doFetchSessions(payload, rootState) {
      // Edited by Jir : This method will fetch sessions across multiple services.
      const endpoint = `api/portal/group-services/sessions/list`;

      try {
        // TODO Remove
        // await asyncDelay(1000);

        const response = await apiClient.post(endpoint, payload);
        const sessions = _.get(response, 'data.sessions');

        // Do fetch session
        const sessionsToUpdate = payload.page === 1 ? sessions : [...sessions, ...rootState.groupServiceStore.sessions];

        dispatch.groupServiceStore.setSessions(sessionsToUpdate);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchBookingsForSession(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/list`;
      // TODO Remove
      // await asyncDelay(1000);

      const response = await apiClient.post(endpoint, payload);

      const customerBookings = _.get(response, 'data.customers');

      const sessionBooking = {
        serviceDateTimeId: payload.serviceDateTimeId,
        bookings: customerBookings,
      };

      // check if the store has existing entries.
      const storeHasBookings = !_.isEmpty(rootState.groupServiceStore.sessionBookings);

      // Remove the current entry in the booking.
      const filteredSessionBookings = _.filter(
        rootState.groupServiceStore.sessionBookings,
        (sessionBooking) => sessionBooking.serviceDateTimeId !== payload.serviceDateTimeId,
      );

      // Update current store.
      const updatedBooking = storeHasBookings ? [...filteredSessionBookings, sessionBooking] : [sessionBooking];

      dispatch.groupServiceStore.setSessionBookings(updatedBooking);
    },

    async doResetSessions(payload, rootState) {
      dispatch.groupServiceStore.setSessions([]);
    },

    // Note from Jir : This doesn't belong here.
    setSelectedGroupService: (state, payload) => ({ ...state, selectedGroupService: payload }),

    async doFetchGroupServiceOverview(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/overview`;
      const result = await apiClient.get(endpoint, payload);
      dispatch.groupServiceStore.setSelectedGroupService(result.data);
    },

    async doFetchGroupBookingOverviewWarning(payload, rootState) {
      const result = await apiClient.get(`api/portal/group-services/${payload.serviceId}/warnings`);
      dispatch.groupServiceStore.setGroupServiceOverviewWarnings(result.data);
    },

    async doPublishGroupService(payload) {
      const endPoint = `/api/portal/group-services/${payload.serviceId}/publish`;
      await apiClient.put(endPoint, payload);
      dispatch.groupServiceStore.updateGroupServiceStatus({
        serviceId: payload.serviceId,
        publishStatus: ServicePublishStatus.PUBLISHED,
      });
    },

    async doFetchGroupServiceSessionNotes(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/booking-notes/list`;
      const result = await apiClient.post(endpoint, payload);

      if (payload.page === 1) {
        dispatch.groupServiceStore.setGroupServiceSessionNotes(result.data.notes);
      } else {
        const newNotes = [...rootState.groupServiceStore.groupServiceSessionNotes, ...result.data.notes];
        dispatch.groupServiceStore.setGroupServiceSessionNotes(newNotes);
      }
    },

    async doRemoveGroupServiceSessionNote(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/booking-notes/${payload.noteId}`;

      apiClient.delete(endpoint);
      const newNotes = _.filter(rootState.groupServiceStore.groupServiceSessionNotes, (note) => {
        return note.noteId !== payload.noteId;
      });

      dispatch.groupServiceStore.setGroupServiceSessionNotes(newNotes);
    },

    async doAddGroupServiceSessionNote(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/booking-notes`;

      const result = await apiClient.post(endpoint, payload);
      return result.data;
    },

    async doUnpublishGroupService(payload) {
      const endPoint = `/api/portal/group-services/${payload.serviceId}/unpublish`;
      await apiClient.put(endPoint, payload);
      dispatch.groupServiceStore.updateGroupServiceStatus({
        serviceId: payload.serviceId,
        publishStatus: ServicePublishStatus.UNPUBLISHED,
      });
    },

    async doEditGroupServiceSessionNote(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/booking-notes/${payload.noteId}`;
      const result = await apiClient.put(endpoint, payload);
      return result.data;
    },

    async doFetchGroupServiceCustomer(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/customers/list`;
      const response = await apiClient.post(endpoint, payload);
      const newCustomers = [];
      _.map(response.data, (customer) => {
        const newUser = {
          taggedUserId: customer.customerUserId,
          taggedUserFirstName: customer.firstName,
          taggedUserLastName: customer.lastName,
          taggedUserAvatarUrl: customer.customerAvatarUrl,
        };

        newCustomers.push(newUser);
      });

      dispatch.groupServiceStore.setGroupServiceSessionCustomers(newCustomers);
    },

    async doArchiveGroupService(payload, rootState) {
      try {
        await apiClient.post(`/api/portal/group-services/${payload.serviceId}/archive`, payload);
        dispatch.groupServiceStore.updateGroupServiceStatus({
          serviceId: payload.serviceId,
          publishStatus: ServicePublishStatus.ARCHIVED,
        });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchGroupServiceSessionActivityLogs(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/activity-logs`;
      try {
        const response = await apiClient.get(endpoint);
        dispatch.groupServiceStore.setGroupServiceSessionActivityLogs(response.data.sessionHistories);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchSingleSession(payload, rootStore) {
      const result = await apiClient.get(
        `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}`,
      );
      dispatch.groupServiceStore.setSelectedSession(result.data);
    },

    async doFetchGroupServiceServiceAgreements(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/customers/${payload.customerUserId}/sessions/check-service-agreement`;
      const result = await apiClient.post(endPoint, payload);
      dispatch.groupServiceStore.setGroupServiceServiceAgreements(result.data);
    },

    async doFetchGroupServiceIndividualSessionServiceAgreements(payload: {
      serviceId: string;
      customerUserId: string;
      serviceDateTimeIds: string[];
    }) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/customers/${payload.customerUserId}/individual-sessions/check-service-agreement`;
      const result = await apiClient.post(endPoint, payload);
      dispatch.groupServiceStore.setGroupServiceServiceAgreements(result.data);
      return result?.data ?? [];
    },

    async doFetchSessionWarning(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/warnings`;
      const result = await apiClient.get(endPoint, payload);
      dispatch.groupServiceStore.setSessionCustomerWarnings(result.data);
    },

    async doFetchSessionCustomerDetails(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/list`;
      const result = await apiClient.post(endPoint, payload);
      dispatch.groupServiceStore.setSessionCustomerBookings(result.data.customers);
      return result.data.customers ?? [];
    },

    async doFetchSessionCustomerDetailsFull(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/list`;
      const result = await apiClient.post(endPoint, payload);
      dispatch.groupServiceStore.setSessionCustomerBookings(result.data.customers);
      dispatch.groupServiceStore.setSessionCustomerDetails(result.data);
      return result.data ?? {};
    },

    async doFetchSessionOverview(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}`;
      const result = await apiClient.get(endPoint, payload);
      dispatch.groupServiceStore.setSessionCustomerBookings(result.data.customers);
      dispatch.groupServiceStore.setSessionCustomerDetails(result.data);
    },

    async updateSessionCapacity(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/capacity`;
      await apiClient.put(endPoint, payload);
      dispatch.groupServiceStore.setSelectedSession({
        ...rootStore.groupServiceStore.selectedSession,
        capacity: payload.capacity,
      });
      return true;
    },

    async doUpdateSessionLocation(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/schedules/${payload.serviceScheduleId}/sessions/${payload.serviceDateTimeId}/edit-location`;
      const result = await apiClient.put(endPoint, payload);
      dispatch.groupServiceStore.setSelectedSession({
        ...rootStore.groupServiceStore.selectedSession,
        address: payload.location,
      });
      return result.data;
    },

    async doUpdateSessionDates(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/schedules/${payload.serviceScheduleId}/sessions/${payload.serviceDateTimeId}/edit-start-end-time`;
      const result = await apiClient.put(endPoint, payload);
      dispatch.groupServiceStore.setSelectedSession({
        ...rootStore.groupServiceStore.selectedSession,
        startDateTime: payload.startDateTime,
        endDateTime: payload.endDateTime,
      });
      dispatch.groupServiceStore.doFetchBookingsForSession({
        serviceId: payload.serviceId,
        serviceDateTimeId: payload.serviceDateTimeId,
      });
      return result.data;
    },

    async doCancelSession(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/cancel`;
      const result = await apiClient.put(endPoint, payload);
      dispatch.groupServiceStore.setSelectedSession({
        ...rootStore.groupServiceStore.selectedSession,
        sessionStatus: GroupServiceSessionStatus.CANCELLED,
      });

      const newGroupServiceSessions = _.map(rootStore.servicesStore.groupServiceSessions, (session) => {
        return {
          ...session,
          sessionStatus:
            session.serviceDateTimeId === payload.serviceDateTimeId
              ? GroupServiceSessionStatus.CANCELLED
              : session.sessionStatus,
        };
      });

      dispatch.servicesStore.setGroupServiceSessions(newGroupServiceSessions);
      dispatch.groupServiceStore.setGroupServiceSessionActivityLogs(result.data.sessionHistories);
      return true;
    },

    async doRevertSessionStatus(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/status`;
      const result = await apiClient.put(endPoint, payload);

      if (result && result.data) {
        dispatch.groupServiceStore.setSelectedSession({
          ...rootStore.groupServiceStore.selectedSession,
          sessionStatus: payload.sessionStatus,
        });

        const newGroupServiceSessions = _.map(rootStore.servicesStore.groupServiceSessions, (session) => {
          return {
            ...session,
            sessionStatus:
              session.serviceDateTimeId === payload.serviceDateTimeId ? payload.sessionStatus : session.sessionStatus,
          };
        });

        dispatch.servicesStore.setGroupServiceSessions(newGroupServiceSessions);
        dispatch.groupServiceStore.updateGroupServiceSessionActivityLogs(result.data.sessionHistories);
      }
      return true;
    },

    async doCloseSession(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/close`;
      await apiClient.put(endPoint, payload);
      dispatch.groupServiceStore.setSelectedSession({
        ...rootStore.groupServiceStore.selectedSession,
        sessionStatus: GroupServiceSessionStatus.CLOSED,
      });
      return true;
    },

    async doCheckCloseSession(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/check-close`;
      const result = await apiClient.post(endPoint, payload);
      return result.data;
    },

    async doCheckStartValidity(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/start-data`;
      const result = await apiClient.post(endPoint, payload);
      return result && result.data;
    },

    async doStartSession(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/start`;
      const result = await apiClient.put(endPoint, payload);
      dispatch.groupServiceStore.setSelectedSession({
        ...rootStore.groupServiceStore.selectedSession,
        sessionStatus: GroupServiceSessionStatus.INPROGRESS,
        sessionStartDateTime: payload.startDateTime,
      });
      return true;
    },

    async doEndSession(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/finish`;
      const result = await apiClient.put(endPoint, payload);
      dispatch.groupServiceStore.setSelectedSession({
        ...rootStore.groupServiceStore.selectedSession,
        sessionStatus: GroupServiceSessionStatus.COMPLETED,
        sessionEndDateTime: payload.endDateTime,
      });
      return true;
    },

    async doFetchSessionShiftSlotOverview(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/list`;

      const result = await apiClient.post(endpoint, payload);
      dispatch.groupServiceStore.setSessionsShiftSlotOverview(result.data);
    },

    async doFetchSessionBookingRatios(payload: {
      serviceId: string;
      serviceDateTimeId: string;
      attendanceIds: string[];
    }) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/ratios/list`;
      const result = await apiClient.post(endpoint, payload);
      const data = result.data ?? [];
      dispatch.groupServiceStore.setSessionBookingRatios(data);
      return data;
    },

    async doFetchSessionSupportWorkers(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/workers`;

      const result = await apiClient.post(endpoint, payload);
      dispatch.groupServiceStore.setSessionSupportWorkers(result.data);
    },

    // Shift actions
    async doAddShiftSlots(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots`;
      //await asyncDelay(1000);
      await apiClient.post(endpoint, payload);
    },

    async doAssignWorkerToShift(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/assign`;
      await apiClient.put(endpoint, payload);
    },

    async doEditShiftTimes(payload) {
      const apiEndpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/date-time`;
      await apiClient.put(apiEndpoint, payload);
    },

    async doCheckConflictForEditShiftTimes(payload) {
      const apiEndpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/date-time/conflict-check`;
      const result = await apiClient.post(apiEndpoint, payload);
      return result.data;
    },

    async doRemoveShiftSlot(payload) {
      const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots`;
      await apiClient.delete(endpoint, payload);
    },

    async doStartShift(payload) {
      const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/start`;
      await apiClient.put(endpoint, payload);
    },

    async doFinishShift(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/finish`;
      await apiClient.put(endPoint, payload);
    },

    async doMarkShiftAsNoShow(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/no-show`;
      await apiClient.put(endPoint, payload);
    },

    async doMarkShiftAsAttended(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/attended`;
      await apiClient.put(endPoint, payload);
    },

    async doEditShiftStartTime(payload) {
      const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/start-date-time`;
      const requestPayload = {
        startDateTime: payload.startDateTime,
      };
      await apiClient.put(endpoint, requestPayload);
    },

    async doRevokeShiftApproval(payload) {
      // TODO API call here
      // const apiEndpoint = `/api/portal/session/`;
      // const response = await apiClient.get();

      console.log('doRevokeShiftApproval');
      await asyncDelay(1000);
    },

    async doApproveShift(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/approve`;
      await apiClient.put(endPoint, payload);
    },

    async doCompleteAndApproveShift(payload: {
      serviceId: string;
      serviceDateTimeId: string;
      supportWorkerAttendanceId: string;
    }): Promise<boolean> {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/complete-approve`;
      await apiClient.put(endPoint, payload);
      return true;
    },

    async doEditShiftStartEndTime(payload) {
      const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/start-end-date-time`;
      const requestPayload = {
        startDateTime: payload.startDateTime,
        endDateTime: payload.endDateTime,
      };
      const result = await apiClient.put(endpoint, requestPayload);
    },

    async doShiftConfirmSlot(payload) {
      const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots`;
      const result = await apiClient.put(endpoint, payload);
      return result.data;
    },

    async doFetchShiftSlotActivityLogs(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/activity-logs`;
      const result = await apiClient.get(endpoint);
      dispatch.groupServiceStore.setShiftSlotActivityLogs(result.data.shiftSlotHistories);
    },

    async doFetchConflictShiftSlots(payload) {
      const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/check-conflict`;

      const result = await apiClient.post(endpoint, payload);
      dispatch.groupServiceStore.setShiftClashConflicts(result.data.shiftClashConflicts);
      dispatch.groupServiceStore.setOutsideAvailabilityShiftSlots(result.data.workerOutsideAvailability);
    },

    async doFetchShiftClashConflicts(payload, rootState) {
      const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/worker-conflict-check`;
      try {
        const res = await apiClient.post(endpoint, payload);
        dispatch.groupServiceStore.setShiftClashConflicts(res.data.shiftClashConflicts);

        // return for component to check and handle
        return res.data.shiftClashConflicts;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doFetchGroupServiceSessions(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/check-availability`;
      const result = await apiClient.post(endpoint);
      dispatch.groupServiceStore.setGroupServiceSessions(result.data.sessions);
    },

    async doFetchGroupServiceTimeSlots(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/schedules/check-availability`;

      const result = await apiClient.post(endpoint, payload);
      dispatch.groupServiceStore.setGroupServiceTimeSlots(result.data);
    },

    async doFetchGroupServiceWorkerForAssignment(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/search-workers-with-availability-indicator`;
      const result = await apiClient.post(endpoint, payload);
      dispatch.groupServiceStore.setgroupServiceSupportWorkers(result.data);
    },

    async doAddTeamMemberToSession(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/add-team-member-to-session`;

      return await apiClient.put(endpoint, payload);
    },

    async doBatchStartShifts(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/bulk-start`;

      await apiClient.put(endpoint, payload);
    },

    async doFetchGroupServiceTimesheetSessions(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/timesheet`;

      let body = { ...payload };
      if (body && body.filters) {
        // Transform the filters array into objects and remove the empty filters.
        const filters = _.chain(_.filter(body.filters, (filter) => !Utils.isEmpty(filter.values)))
          .keyBy('filter')
          .mapValues('values')
          .value();
        // Merge back the filters into the payload
        body = { ...body, ...filters };
        // Delete the 'filters' props to have a lighter payload.
        delete body.filters;
      }

      const result = await apiClient.post(endpoint, body);

      if (result && result.data) {
        dispatch.groupServiceStore.setTimesheetSessionList(result.data);
        dispatch.groupServiceStore.setTimesheetShiftSlotList([]);
      }
    },

    async doFetchGroupServiceTimesheetShiftSlots(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/timesheet/${payload.sessionId}/shift-slots`;

      dispatch.groupServiceStore.setTimesheetShiftSlotList([]);

      const result = await apiClient.post(endpoint, { shiftSlotStatus: payload.shiftSlotStatus });

      if (result && result.data) {
        dispatch.groupServiceStore.setTimesheetShiftSlotList(result.data.shiftSlots);
      }
    },

    async doEditShiftSlotsStartEndTime(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/timesheet/${payload.sessionId}/edit-start-end-date-time`;

      await apiClient.put(endpoint, { supportWorkerAttendances: payload.shiftSlots });
    },

    async doApproveShiftSlots(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/timesheet/${payload.sessionId}/approve-shift-slot`;

      const body = {
        supportWorkerAttendanceIds: _.map(payload.shiftSlots, (shiftSlot) => shiftSlot.supportWorkerAttendanceId),
      };

      const newAllList = _.filter(
        rootState.groupServiceStore.allPendingShiftSlots,
        (item) =>
          !_.find(
            payload.shiftSlots,
            (payloadItem) => payloadItem.supportWorkerAttendanceId === item.supportWorkerAttendanceId,
          ),
      );

      dispatch.groupServiceStore.setAllPendingShiftSlots(newAllList);

      await apiClient.put(endpoint, body);
    },

    async doRevokeShiftSlotsApproval(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/timesheet/${payload.sessionId}/revoke-shift-slot-approval`;

      const body = {
        supportWorkerAttendanceIds: _.map(payload.shiftSlots, (shiftSlot) => shiftSlot.supportWorkerAttendanceId),
      };

      await apiClient.put(endpoint, body);
    },

    async doBatchFinishShifts(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/bulk-finish`;
      await apiClient.put(endpoint, payload);
    },

    async doGetAllPendingShiftSlots(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/get-all-approved-timesheet`;

      const result = await apiClient.get(endpoint);

      if (result && result.data) {
        dispatch.groupServiceStore.setAllPendingShiftSlots(result.data);
      }
    },

    async doBatchApproveShifts(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/bulk-approve`;

      await apiClient.put(endpoint, payload);
    },

    async doApproveAllPendingShiftSlots(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/approve-all-shift-slot`;

      const body = { supportWorkerAttendanceIds: payload.supportWorkerAttendanceIds };

      await apiClient.put(endpoint, body);
    },

    async doGetCustomerSessionToBeRemoveList(payload, rootState) {
      try {
        const result = await apiClient.post(
          `api/portal/group-bookings/${payload.customerUserId}/schedule/${payload.serviceScheduleId}/not-started/list`,
          payload,
        );
        if (result && result.data) {
          dispatch.groupServiceStore.setCustomerSessionToBeRemoveList(result.data);
        }
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRemoveCustomerFromSchedule(payload, rootState) {
      try {
        await apiClient.delete(
          `/api/portal/group-bookings/${payload.customerUserId}/schedule/${payload.serviceScheduleId}/not-started/delete`,
          payload,
        );
        dispatch.servicesStore.updateGroupServiceSession({
          attendanceIds: payload.attendanceIds,
        });
        dispatch.customersStore.updateGroupServiceCustomerUpcomingCounts({
          attendanceIds: payload.attendanceIds,
          customerUserId: payload.customerUserId,
        });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchSessionFormList(payload, rootState) {
      const { serviceId, serviceDateTimeId } = payload;
      const {
        data: { forms },
      } = await apiClient.get(`api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/forms/list`);
      return forms;
    },

    async doFetchActivityGroup(payload) {
      try {
        const result = await apiClient.get(`/api/portal/group-services/${payload.serviceId}/activity-groups`);
        await dispatch.groupServiceStore.setActivityGroupsList(result.data.activityGroups);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchGroupServiceActivityGroups(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/activity-groups`;
      const result = await apiClient.get(endpoint);
      if (result && result.data) {
        dispatch.groupServiceStore.setGroupServiceActivityGroups(result.data.activityGroups);
        dispatch.groupServiceStore.setGroupServiceUnAssignedMembers(result.data.unassignedMembers);
      }

      return true;
    },

    async doAddCustomerToActivityGroup(payload) {
      try {
        const { customers, serviceId, activityGroupId } = payload;
        const endpoint = `api/portal/group-services/${serviceId}/activity-groups/${activityGroupId}/edit-members/customers`;
        await apiClient.post(endpoint, customers);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doAddActivityGroup({ serviceId, ...payload }, rootState) {
      const endpoint = `/api/portal/group-services/${serviceId}/activity-groups`;
      try {
        const oldPayload = payload;
        payload = {
          ...payload,
          customers: _.map(_.get(payload, 'customers'), (customer) => customer.userId),
          teamMembers: _.map(_.get(payload, 'teamMembers'), (teamMember) => teamMember.userId),
        };
        const response = await apiClient.post(endpoint, payload);
        const newActivityGroup = {
          ...oldPayload,
          ...response.data,
        };
        dispatch.groupServiceStore.setGroupServiceActivityGroups([
          ...rootState.groupServiceStore.groupServiceActivityGroups,
          newActivityGroup,
        ]);
        return newActivityGroup;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditActivityGroup({ serviceId, ...payload }, rootState) {
      const { serviceActivityGroupId } = payload;
      const endpoint = `/api/portal/group-services/${serviceId}/activity-groups/${serviceActivityGroupId}`;
      try {
        payload = {
          ...payload,
          customers: _.map(_.get(payload, 'customers'), (customer) => customer.userId),
          teamMembers: _.map(_.get(payload, 'teamMembers'), (teamMember) => teamMember.userId),
        };
        const response = await apiClient.put(endpoint, payload);
        const editActivityGroup = {
          ...payload,
          ...response.data,
        };
        return editActivityGroup;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doDuplicateActivityGroup({ serviceId, serviceActivityGroupId, ...payload }, rootState) {
      const endpoint = `/api/portal/group-services/${serviceId}/activity-groups/${serviceActivityGroupId}/duplicate`;
      try {
        const response = await apiClient.post(endpoint);
        const newActivityGroup = {
          ...payload,
          ...response.data,
        };
        dispatch.groupServiceStore.setGroupServiceActivityGroups([
          ...rootState.groupServiceStore.groupServiceActivityGroups,
          newActivityGroup,
        ]);
        return newActivityGroup;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditCustomersToActivityGroup(payload) {
      try {
        const { customers, serviceId, activityGroupId } = payload;
        const endpoint = `/api/portal/group-services/${serviceId}/activity-groups/${activityGroupId}/customers`;
        const result = await apiClient.put(endpoint, { customers });
        if (result && result.data) {
          return result.data;
        }
        return false;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditTeamMembersToActivityGroup(payload) {
      try {
        const { teamMembers, serviceId, activityGroupId } = payload;
        const endpoint = `/api/portal/group-services/${serviceId}/activity-groups/${activityGroupId}/team-members`;
        const result = await apiClient.put(endpoint, { teamMembers });
        if (result && result.data) {
          return result.data;
        }
        return false;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRemoveAllMembersFromActivityGroup(payload) {
      const { serviceId, serviceActivityGroupId, memberType } = payload;
      const endpoint = `api/portal/group-services/${serviceId}/activity-groups/${serviceActivityGroupId}/${
        memberType === ActivityGroupMemberType.CUSTOMER ? 'customers' : 'team-members'
      }`;
      const result = await apiClient.put(endpoint, []);

      return true;
    },

    async doRemoveMemberFromActivityGroup(payload) {
      const { serviceId, serviceActivityGroupId, userId, memberType } = payload;
      const endpoint = `api/portal/group-services/${serviceId}/activity-groups/${serviceActivityGroupId}/remove-member`;
      const result = await apiClient.put(endpoint, { userId, memberType });

      return true;
    },

    async doAddMembersToEmptyActivityGroup({ serviceId, activityGroupId, customers, teamMembers }) {
      try {
        const endpoint = `/api/portal/group-services/${serviceId}/activity-groups/${activityGroupId}/members`;
        const result = await apiClient.put(endpoint, { customers, teamMembers });
        if (result && result.data) {
          return result.data;
        }
        return false;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doAssignSpecificMemberToActivityGroups(payload) {
      const { serviceActivityGroupIds, userId, memberType, serviceId } = payload;
      try {
        const endpoint = `/api/portal/group-services/${serviceId}/activity-groups/assign-member`;
        await apiClient.put(endpoint, { serviceActivityGroupIds, userId, memberType });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doDeleteActivityGroup(payload) {
      try {
        const { serviceId, serviceActivityGroupId } = payload;
        const endpoint = `api/portal/group-services/${serviceId}/activity-groups/${serviceActivityGroupId}`;
        await apiClient.delete(endpoint);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchSessionActivityGroups(payload) {
      try {
        const { serviceId, serviceDateTimeId } = payload;
        const endpoint = `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/activity-groups`;
        const response = await apiClient.get(endpoint);
        if (response && response.data) {
          dispatch.groupServiceStore.setSessionActivityGroups(response.data.activityGroups);
          dispatch.groupServiceStore.setSessionUnassignedMembers(response.data.unassignedMembers);
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doAddGroupServiceForm(payload: IAddSessionFormPayload, rootState: IRootState) {
      //todo: double check --nam
      const { serviceId, serviceDateTimeId, ...restPayload } = payload;
      const endpoint = `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/forms`;
      const { data } = await apiClient.post(endpoint, restPayload);
      await dispatch.groupServiceStore.doFetchSingleSession({ serviceId, serviceDateTimeId });
      return data;
    },

    async doDeleteSessionForm(
      payload: { serviceId: string; serviceDateTimeId: string; serviceDateTimeFormId: StringDecoder },
      rootState: IRootState,
    ) {
      const { serviceId, serviceDateTimeId, serviceDateTimeFormId } = payload;
      const { data } = await apiClient.delete(
        `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/forms/${serviceDateTimeFormId}`,
      );
      await dispatch.groupServiceStore.doFetchSingleSession({ serviceId, serviceDateTimeId });
      return data;
    },

    async doEditTeamMemberCustomerForm(payload: {
      serviceId: string;
      serviceDateTimeId: string;
      serviceDateTimeFormId: string;
      involvedCustomerIds: string[];
      involvedMemberIds: string[];
    }) {
      const { serviceId, serviceDateTimeId, serviceDateTimeFormId, ...restPayload } = payload;
      const endPoint = `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/forms/${serviceDateTimeFormId}/customer-members`;
      const { data } = await apiClient.put(endPoint, restPayload);
      await dispatch.groupServiceStore.doFetchSingleSession({ serviceId, serviceDateTimeId });
      return data;
    },

    async doFetchFormDetailByServiceDateTimeFormId(
      payload: { serviceId: string; serviceDateTimeId: string; serviceDateTimeFormId: string },
      rootState: IRootState,
    ) {
      const { serviceId, serviceDateTimeId, serviceDateTimeFormId } = payload;
      const { data } = await apiClient.get(
        `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/forms/${serviceDateTimeFormId}`,
      );
      if (data) {
        const formattedData = {
          formContent: {
            title: { formTitle: data.formName, formDescription: data.formDescription },
            elements: data.formElements,
          },
          formData: data.formData,
        };
        dispatch.formBuilderStore.setCurrentForm(formattedData.formContent);
        return formattedData;
      }
    },

    async doUpdateSessionForm(payload: {
      serviceId: string;
      serviceDateTimeId: string;
      serviceDateTimeFormId: string;
      formData: IElementValue[];
      status: string;
    }) {
      const { serviceId, serviceDateTimeId, serviceDateTimeFormId, formData, status } = payload;
      const endPoint = `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/forms/${serviceDateTimeFormId}/form-data`;
      const { data } = await apiClient.put(endPoint, { formData, status });
      return data;
    },

    async doEditSessionActivityGroup(payload) {
      try {
        const { serviceId, serviceDateTimeId, serviceDateTimeActivityGroupId } = payload;
        const endpoint = `/api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/activity-groups/${serviceDateTimeActivityGroupId}`;
        payload = {
          ...payload,
          customers: _.map(_.get(payload, 'customers'), (customer) => customer.userId),
          teamMembers: _.map(_.get(payload, 'teamMembers'), (teamMember) => teamMember.userId),
        };
        await apiClient.put(endpoint, payload);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doDeleteSessionActivityGroup(payload) {
      try {
        const { serviceId, serviceDateTimeId, serviceDateTimeActivityGroupId } = payload;
        const endpoint = `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/activity-groups/${serviceDateTimeActivityGroupId}`;
        await apiClient.delete(endpoint);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCreateSessionActivityGroup(payload) {
      const { serviceId, serviceDateTimeId, activityGroups } = payload;
      const endpoint = `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/activity-groups${
        activityGroups ? '/apply-templates' : ''
      }`;
      try {
        payload = {
          ...payload,
          customers: _.map(_.get(payload, 'customers'), (customer) => customer.userId),
          teamMembers: _.map(_.get(payload, 'teamMembers'), (teamMember) => teamMember.userId),
        };
        await apiClient.post(endpoint, payload);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditMembersOfASessionActivityGroup(payload) {
      try {
        const { serviceId, serviceDateTimeId, serviceDateTimeActivityGroupId, customers, teamMembers } = payload;
        const endpoint = `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/activity-groups/${serviceDateTimeActivityGroupId}/members`;
        const result = await apiClient.put(endpoint, { customers, teamMembers });
        if (result && result.data) {
          return result.data;
        }
        return false;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doDuplicateSessionActivityGroup(payload) {
      const { serviceId, serviceDateTimeId, serviceDateTimeActivityGroupId } = payload;
      const endpoint = `/api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/activity-groups/${serviceDateTimeActivityGroupId}/duplicate`;
      try {
        await apiClient.post(endpoint);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditCustomerOfASessionActivityGroup(payload) {
      try {
        const { serviceId, serviceDateTimeId, serviceDateTimeActivityGroupId, customers } = payload;
        const endpoint = `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/activity-groups/${serviceDateTimeActivityGroupId}/customers`;
        const result = await apiClient.put(endpoint, { customers });
        if (result && result.data) {
          return result.data;
        }
        return false;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditTeamMembersOfASessionActivityGroup(payload) {
      try {
        const { serviceId, serviceDateTimeId, serviceDateTimeActivityGroupId, teamMembers } = payload;
        const endpoint = `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/activity-groups/${serviceDateTimeActivityGroupId}/team-members`;
        const result = await apiClient.put(endpoint, { teamMembers });
        if (result && result.data) {
          return result.data;
        }
        return false;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRemoveSpecificMemberOfASessionActivityGroup(payload) {
      try {
        const { serviceId, serviceDateTimeId, serviceDateTimeActivityGroupId, userId, memberType } = payload;
        const endpoint = `/api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/activity-groups/${serviceDateTimeActivityGroupId}/remove-member`;
        await apiClient.put(endpoint, { userId, memberType });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRemoveAllMembersFromSessionActivityGroup(payload) {
      try {
        const { serviceId, serviceDateTimeId, serviceDateTimeActivityGroupId, memberType } = payload;
        const endpoint = `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/activity-groups/${serviceDateTimeActivityGroupId}/${
          memberType === ActivityGroupMemberType.CUSTOMER ? 'customers' : 'team-members'
        }`;
        await apiClient.put(endpoint, []);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doAssignSpecificMemberToSessionActivityGroups(payload) {
      const { addedActivityGroups, userId, memberType, serviceId, serviceDateTimeId } = payload;
      const endpoint = `/api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/activity-groups/assign-member`;

      try {
        const serviceDateTimeActivityGroupIds = _.map(
          addedActivityGroups,
          (group) => group.serviceDateTimeActivityGroupId,
        );
        await apiClient.put(endpoint, { serviceDateTimeActivityGroupIds, userId, memberType });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditWorkerShiftTimeConflictCheck(payload: {
      serviceId: string;
      supportWorkerId: string;
      selectedShift: {
        serviceDateTimeId: string;
        shiftStartDateTime: Date | string;
        shiftEndDateTime: Date | string;
      };
      remainingShifts: {
        serviceDateTimeId: string;
        shiftStartDateTime: Date | string;
        shiftEndDateTime: Date | string;
      }[];
      allWorkerConflicts: IGroupServiceWorkerConflictSession[];
    }) {
      const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/edit-worker-shift-time-conflict-check`;
      try {
        const results = await apiClient.post(endpoint, payload);
        return results.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doUndoStartShift(payload) {
      const { serviceId, serviceDateTimeId, supportWorkerAttendanceId } = payload;
      try {
        const endPoint = `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/shift-slots/${supportWorkerAttendanceId}/undo-start`;
        await apiClient.put(endPoint);
      } catch (e) {
        throw e;
      }
    },

    async doUndoShiftFinish(payload) {
      const { serviceId, serviceDateTimeId, supportWorkerAttendanceId } = payload;
      try {
        const endPoint = `api/portal/group-services/${serviceId}/sessions/${serviceDateTimeId}/shift-slots/${supportWorkerAttendanceId}/undo-finish`;
        await apiClient.put(endPoint);
      } catch (e) {
        throw e;
      }
    },

    async doApproveLeaveShiftSlots(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/timesheet/${payload.sessionId}/approve-shift-slot-leave`;

      const { shiftSlots } = payload;
      const { allPendingShiftSlots } = rootState.groupServiceStore;

      const body = {
        supportWorkerAttendanceIds: shiftSlots.map((shiftSlot) => shiftSlot.supportWorkerAttendanceId),
      };

      const newAllList = allPendingShiftSlots.filter(
        (item) =>
          !shiftSlots.find((payloadItem) => payloadItem.supportWorkerAttendanceId === item.supportWorkerAttendanceId),
      );

      dispatch.groupServiceStore.setAllPendingShiftSlots(newAllList);

      await apiClient.put(endpoint, body);
    },
  }),
};

export default groupServiceStore;
