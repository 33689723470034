import { Text } from '@good/components';
import { Home } from '@good/icons';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Skeleton, rem } from '@good/ui/core';

import { useSilServiceContext } from '../sil-service-provider/sil-service-provider';
import { UnpublishServiceModal } from '../unpublish-modal/unpublish-modal';
import { PublishServiceModal } from '../publish-modal/publish-modal';
import { SilServiceHeading } from './sil-service-heading';
import { SilServiceStatusLabel } from './sil-service-status-label';
import { SilServiceDepartmentLabel } from './sil-service-department-label';
import { SilServiceResidentsLabel } from './sil-service-residents-label';
import { SilServiceImage } from './sil-service-image';

const HeaderLabel = ({ children }: { children?: string }) => (
  <Text size='xs' className='font-semibold' asChild>
    <p>{children}</p>
  </Text>
);

export const SilServiceHeader = () => {
  const { service, isLoading } = useSilServiceContext();

  const [isPublishModalOpen, setIsPublishedModalOpen] = useState<boolean>(false);
  const [isUnpublishModalOpen, setIsUnpublishedModalOpen] = useState<boolean>(false);

  const { t } = useTranslation('', { keyPrefix: 'silService.header' });

  const isPublished = service?.status === 'PUBLISHED';
  const isUnpublishedOrDraft = service?.status === 'UNPUBLISHED' || service?.status === 'DRAFT';

  return (
    <>
      <div className='flex-row items-center gap-4 bg-white'>
        <div className='flex items-center gap-4'>
          <SilServiceImage />
          <SilServiceHeading />
        </div>

        <div style={{ width: '0.0625rem', height: '3rem' }} className='flex bg-gray-light-1 m-2'>
          <div></div>
        </div>

        <div className='flex flex-wrap gap-8'>
          <div>
            <HeaderLabel>{t('status.label')}</HeaderLabel>
            <div className='pt-1'>
              <SilServiceStatusLabel />
            </div>
          </div>

          <div>
            <HeaderLabel>{t('type.label')}</HeaderLabel>
            <div className='align-center flex gap-2 pt-1'>
              <Home className='text-h-2xs text-lavender' />
              <Text asChild size='sm'>
                <p>{t('type.serviceType')}</p>
              </Text>
            </div>
          </div>

          <div>
            <HeaderLabel>{t('department.label')}</HeaderLabel>
            <div className='pt-1 text-gray-dark-2'>
              <SilServiceDepartmentLabel />
            </div>
          </div>

          <div>
            <HeaderLabel>{t('residents.label')}</HeaderLabel>
            <div className='pt-1 text-gray-dark-2'>
              <SilServiceResidentsLabel />
            </div>
          </div>
        </div>
        <div className='flex flex-auto justify-end'>
          {isPublished && !isLoading && (
            <Button size='md' variant='outline' color='neutral' onClick={() => setIsUnpublishedModalOpen(true)}>
              {t('actions.unpublish.label')}
            </Button>
          )}
          {isUnpublishedOrDraft && !isLoading && (
            <Button size='md' onClick={() => setIsPublishedModalOpen(true)}>
              {t('actions.publish.label')}
            </Button>
          )}
          {isLoading && (
            <Skeleton visible={isLoading} w={rem(128)}>
              <Button size='md' />
            </Skeleton>
          )}
        </div>
      </div>
      {service && (
        <PublishServiceModal
          service={service}
          isOpen={isPublishModalOpen}
          onClose={() => setIsPublishedModalOpen(false)}
        />
      )}
      {service && (
        <UnpublishServiceModal
          service={service}
          isOpen={isUnpublishModalOpen}
          onClose={() => setIsUnpublishedModalOpen(false)}
        />
      )}
    </>
  );
};
