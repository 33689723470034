import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import CommonUtils from 'utilities/common-utils';

class ViewQuotationManualTotalView extends Component<any, any> {
  render() {
    const { quotation } = this.props;
    return (
      <div className={'p-medium bg-secondary'}>
        <div className={'rounded-big bg-blue-action-lightest'}>
          <Row
            className={'pv-medium bordered-bottom bordered-left bordered-right border-standard-gray'}
            type={'flex'}
            align={'middle'}
          >
            <Col span={1} />
            <Col span={8} className={'pl-medium'}>
              <Text weight={'bold'}>Total</Text>
            </Col>
            <Col span={4} className={'text-align-right'}>
              <Text>{CommonUtils.formatPrice(Number(quotation.quoteAmount))}</Text>
            </Col>
            <Col span={4} className={'text-align-right'}>
              <Text>{CommonUtils.formatPrice(Number(quotation.spentAmount))}</Text>
            </Col>
            <Col span={4} className={'text-align-right'}>
              <Text>{CommonUtils.formatPrice(Number(quotation.quoteAmount) - Number(quotation.spentAmount))}</Text>
            </Col>
            <Col span={3} />
          </Row>
        </div>
      </div>
    );
  }
}

export default ViewQuotationManualTotalView;
