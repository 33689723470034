import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import _ from 'lodash';
import NDISLineItemGrid from 'common-components/line-items/NDISLineItemGrid';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ICustomerScheduleSlotItem } from '../../../../interfaces/customer-interfaces';
import { IRootState, state } from '../../../../stores/rematch/root-store';
import { connect } from 'react-redux';

interface IEditQuotationSelectedBillingItemModelProps {
  onSaveSelection: (items, index) => void;
  onClose: () => void;
  paymentSourceType: string;
  selectedService: any;
  timeSlot: ICustomerScheduleSlotItem;
  isOpen: boolean;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  index: number;
}

interface IEditQuotationSelectedBillingItemModelState {
  selectedLineItems: any[];
}

class EditQuotationSelectedBillingItemModel extends Component<
  IEditQuotationSelectedBillingItemModelProps,
  IEditQuotationSelectedBillingItemModelState
> {
  state = {
    selectedLineItems: [],
  };

  private _onSaveSelection = () => {
    this.props.onSaveSelection(this.state.selectedLineItems, this.props.index);
    this._onClose();
  };

  private _onClose = () => {
    this.setState({ selectedLineItems: [] });
    this.props.onClose();
  };

  private _onClickLineItemCheckbox = (lineItems) => {
    this.setState({ selectedLineItems: _.map(lineItems, (item) => item.supportItemNumber) });
  };

  render() {
    const { selectedService, timeSlot, isOpen, selectedCustomer } = this.props;
    const { selectedLineItems } = this.state;

    let serviceSelectedBillingItems = [];

    if (timeSlot && selectedService) {
      serviceSelectedBillingItems = _.filter(selectedService.lineItems, (item) =>
        _.find(timeSlot.selectedBillingItems, (selected) => item.supportItemNumber === selected),
      );
      if (serviceSelectedBillingItems.length === 0) {
        serviceSelectedBillingItems = selectedService.lineItems;
      }
    }

    return (
      <ActionModal
        isOpen={isOpen}
        title={'Edit the line items to charge for this time slot'}
        width={'x2-large'}
        onClose={this._onClose}
      >
        <Paragraph>
          Please select the line items that you wish to charge this customer for this time slot. The line items
          displayed are the line items selected when you added this service to the customers service agreement.
        </Paragraph>

        <div>
          {timeSlot && (
            <NDISLineItemGrid
              lineItems={selectedService ? selectedService.lineItems : []}
              displayMode={'VIEW'}
              isSelectable={true}
              selectedLineItems={serviceSelectedBillingItems}
              isServiceAgreementLineItems={true}
              onClickLineItemCheckbox={(lineItems) => this._onClickLineItemCheckbox(lineItems)}
              onSequenceChange={() => false}
              mmmGroup={selectedCustomer.mmmGroup ? selectedCustomer.mmmGroup : 0}
              state={selectedCustomer.state ? selectedCustomer.state : 'VIC'}
              noMargin={true}
              paymentSourceType={this.props.paymentSourceType}
            />
          )}
        </div>

        <ActionModalFooter align="right">
          <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            size="large"
            className="mr-medium"
            disabled={_.isEmpty(selectedLineItems)}
            onClick={this._onSaveSelection}
          >
            Save selection
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedCustomer: state.customersStore.selectedCustomer });

export default connect(mapState, null)(EditQuotationSelectedBillingItemModel);
